import React, { useCallback, useContext, useEffect, useState } from 'react';
import api from '../../../../api';
import { RootStoreContext } from '../../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import { IOphalenEventsResult } from '../../../../../../shared/src/api/v2/appEvent';
import { IOphalenRelatiesResultElementV2 } from '../../../../../../shared/src/api/v2/relatie';
import LoadingSpinner from '../../../Gedeeld/LoadingSpinner';
import { format } from 'date-fns';
import { Kleur as EKleur, Kleur, mutatiebronIconMap } from '../../../../bedrijfslogica/constanten';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { result } from 'lodash';
import { EMutatiebron } from '../../../../bedrijfslogica/enums';

interface IProps extends RouteComponentProps {
  relatie: IOphalenRelatiesResultElementV2;
}

const Events = observer((props: IProps) => {
  const { klantkaartStore } = useContext(RootStoreContext);
  const [eventsResult, setEventsResult] = useState<IOphalenEventsResult | null>(null);

  const ophalenEvents = useCallback(async () => {
    if (klantkaartStore.relatie === null) {
      setEventsResult(null);
      return;
    }
    const result = await api.v2.appEvent.ophalenEvents({
      filterSchema: {
        filters: [
          {
            naam: 'REL_IDS',
            data: [props.relatie.RelID],
          },
          { naam: 'NIET_WEERGEVEN_BIJ_RELATIE', data: false },
        ],
      },
      orderSchema: { orders: [{ naam: 'DATUM', richting: 'DESC' }] },
      paginatie: {
        index: 0,
        aantal: 5,
      },
    });

    setEventsResult(result);
  }, [props.relatie.RelID]);

  useEffect(() => {
    ophalenEvents();
  }, [ophalenEvents]);

  if (eventsResult === null || eventsResult.events.length === 0) {
    return null;
  }

  // return (
  //   <span>
  //     <Icon style={{ width: 18, height: 18, fill: Kleur.Grijs }} />
  //   </span>
  // );

  return (
    <div className="pl-3 pr-3 pb-2 d-flex flex-column">
      <span className="font-weight-bold mb-1">
        Events
        <span className="ml-2">
          <a
            href="#"
            style={{ color: Kleur.LichtGrijs }}
            onClick={(ev) => {
              ev.preventDefault();

              props.history.push(`/klant/${props.relatie.RelID}/events`);
            }}
          >
            Zie alle events
          </a>
        </span>
      </span>
      <table>
        <tbody>
          {eventsResult.events.map((event) => {
            const Icon = mutatiebronIconMap[event.Mutatiebron as EMutatiebron];
            return (
              <tr key={event.ID}>
                <td style={{ width: 125 }}>{format(new Date(event.Datum), 'dd-MM-yyyy HH:mm')}</td>
                <td>{event.EventNaam}</td>
                <td>
                  <Icon style={{ width: 15, height: 15, fill: EKleur.Grijs }} />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
});

export default withRouter(Events);
