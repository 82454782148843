import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Root } from './style';
import Tegel from '../../../../../../../../components/tegel/Tegel';
import TegelSelectie from '../../../../../../../../components/tegel/TegelSelectie';
import GegevensLayout, {
  EUitlijning,
} from '../../../../../../../../components/layout/GegevensLayout';
import { EResultType } from '../../../../../../../../stores/CheckStore';
import { RootStoreContext } from '../../../../../../../../stores/RootStore';
import api from '../../../../../../../../api';
import { formatteerBedrag, IProlongatieRichting } from '../../../../../../../../helpers';
import {
  EFunctioneleIcon,
  functioneleIconMap,
  IconDownload,
  IconGelijkAan,
  IconInformatie,
  IconPijlVolgend,
  IconPijlVorig,
  IconTerugdraaien,
  IconUitBedrijf,
  IconVerwijderen,
  IconWijzigen,
} from '../../../../../../../../components/Icons';
import { format } from 'date-fns';
import { contractstatusKleurMap, EContractStatus } from './ContractStatus';
import ActieMenuKnop from '../../../../../../../../components/ActieMenuKnop';
import { Kleur as EKleur, Kleur } from '../../../../../../../../bedrijfslogica/constanten';
import {
  EContractstatus,
  EProductstatus,
  ETransportopdrachtRegelsoort,
} from '../../../../../../../../bedrijfslogica/enums';
import { maandenNaarJaren } from '../../../../../../../../bedrijfslogica/teksten';
import Details from './Details';
import Transport from './Transport';
import Service from './Service';
import Mutatie from './Mutatie';
import Product from './Product';
import Financieel from './Financieel';
import TegelUitklapMenu from '../../../../../../../../components/tegel/TegelUitklapMenu';
import BeeindigenContractDialoog from '../BeeindigenContractDialoog';
import WijzigenContractDialoog from '../WijzigenContractDialoog';
import FiatterenNieuwDialoog from '../FiatterenNieuwDialoog';
import FiatterenOvernameDialoog from '../FiatterenOvernameDialoog';
import RelatieVisualisatie from '../../../../../../../../components/personalia/RelatieVisualisatie';
import { IContract } from '../';
import LoadingSpinner from '../../../../../../../../components/Gedeeld/LoadingSpinner';
import downloadUrl from '../../../../../../../../core/downloadUrl';
import ProductinfoDialoog from '../../../../../../../../components/product/ProductinfoDialoog';
import { IOphalenContractenResultElementV2 } from '../../../../../../../../../../shared/src/api/v2/contract';
import {
  IOphalenFacturenBasisResultElement,
  IRegel as IOphalenFactuurregelsResultElement,
} from '../../../../../../../../../../shared/src/api/v2/factuur';
import { IOphalenProductenResultElementV2 } from '../../../../../../../../../../shared/src/api/v2/product';
import _ from 'lodash';
import ServiceMeldingDialoog from '../../../Service/Meldingen/NieuweMeldingDialoog';
import UitlegTooltip from '../../../../../../../../components/formulier/UitlegTooltip';
import VersturenDocumentatieDialoog from '../../../../../../../../components/product/VersturenDocumentatieDialoog';
import VerticaleScheidingslijn from '../../../../../../../../components/layout/VerticaleScheidingslijn';
import NieuweTransportopdrachtDialoog from '../../../../../../../../components/transport/NieuweOpdrachtDialoog';
import { IOphalenOpdrachtregelsResultElement } from '../../../../../../../../../../shared/src/api/v2/transport/opdracht';
import NieuweVorderingDialoog from '../../../../../../../Facturering/Vorderingen/NieuweVorderingDialoog';
import ContractVisualisatie from '../../../../../../../../components/entiteitVisualisaties/ContractVisualisatie';
import VoorraadTypeInfoDialoog from '../../../../../../../../components/transport/VoorraadTypeInfoDialoog';
import NieuwContractDialoogV2 from '../NieuwContractDialoogV2';
import TransportopdrachtregelVisualisatie from '../../../../../../../../components/entiteitVisualisaties/TransportopdrachtregelVisualisatie';
import FormatteerBedrag from '../../../../../../../../components/MutatieBedrag';
import WijzigenProductDialoog from '../../../../../../../Magazijn/Depot/WijzigenProductDialoog';
import StatusLabel from './StatusLabel';
import { IOphalenVerkoopVoorstellenResultElement } from '../../../../../../../../../../shared/src/api/v2/product/verkoop';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../../../../../models/IRemoteData';
import { IOphalenMeldingenResultElement } from '../../../../../../../../../../shared/src/api/v2/service/melding';
import ServicemeldingVisualisatie from '../../../../../../../../components/entiteitVisualisaties/ServicemeldingVisualisatie';
import ProductmodelInfoDialoog from '../../../../../../../../components/productmodel/ProductmodelInfoDialoog';
import { ETabblad } from '../../../../../../../../components/kaart/entiteiten/Transport';
import KopierenContractDialoog from '../KopierenContractDialoog';
import UitBedrijfDialoog from './Product/UitBedrijfDialoog';
import ProductSelectieDialoog from '../../../../../../../../components/product/ProductSelectieDialoog';

// import WijzigenProductDialoog from '../WijzigenProductDialoog';

const IconVordering = functioneleIconMap[EFunctioneleIcon.Vordering];
const IconKopieren = functioneleIconMap[EFunctioneleIcon.kopieeren];

const ProlongatieVerder = () => (
  <UitlegTooltip inhoud="Verder prolongeren">
    <IconPijlVolgend style={{ width: 18, height: 18, fill: EKleur.Groen }} />
  </UitlegTooltip>
);
const ProlongatieTerug = () => (
  <UitlegTooltip inhoud="Terug prolongeren">
    <IconPijlVorig style={{ width: 18, height: 18, fill: EKleur.Rood }} />
  </UitlegTooltip>
);
const ProlongatieGestopt = () => (
  <span style={{ position: 'relative', top: 2 }}>
    <UitlegTooltip inhoud="Prolongatie gestopt">
      <IconGelijkAan style={{ width: 18, height: 18, fill: EKleur.Grijs }} viewBox="0 0 80 80" />
    </UitlegTooltip>
  </span>
);

export const prolongatieRichting = (params: IProlongatieRichting): JSX.Element => {
  if (params.Tot !== null) {
    return params.Eind === null ? (
      <ProlongatieVerder />
    ) : params.Eind > params.Tot ? (
      <ProlongatieVerder />
    ) : params.Eind < params.Tot ? (
      <ProlongatieTerug />
    ) : (
      <ProlongatieGestopt />
    );
  } else {
    if (params.Start !== null) {
      return params.Eind === null ? (
        <ProlongatieVerder />
      ) : params.Eind < params.Start ? (
        <ProlongatieTerug />
      ) : params.Eind > params.Start ? (
        <ProlongatieVerder />
      ) : (
        <ProlongatieGestopt />
      );
    }
  }
  return <span />;
};

interface IProps {
  contract: IContract;
  geselecteerd: boolean;
  onGeselecteerdChange: (geselecteerd: boolean) => void;
  vernieuwenContracten: () => void;
  relID: number;
}

export interface ISectieProps {
  relID: number;
  contract: IOphalenContractenResultElementV2;
  producten: IOphalenProductenResultElementV2[];
  vorderingen: IOphalenFactuurregelsResultElement[];
  facturen: IOphalenFacturenBasisResultElement[];
  vernieuwenContracten: () => void;
}

enum ESectie {
  Details,
  Service,
  Transport,
  Product,
  Mutatie,
  Financieel,
}

const sectieComponentMapping: { [sectie: number]: React.ComponentType<ISectieProps> } = {
  [ESectie.Details]: Details,
  [ESectie.Service]: Service,
  [ESectie.Transport]: Transport,
  [ESectie.Product]: Product,
  [ESectie.Mutatie]: Mutatie,
  [ESectie.Financieel]: Financieel,
};

export interface IProductmodelinfoDialoogState {
  id: number;
}

export interface IProductSelectieDialoogState {}

export interface IProductinfoDialoogState {
  id: number;
}

export interface IProductWijzigenDialoogState {
  id: number;
}

export interface IDocumentenDialoogState {
  prodID: number;
  relID?: number;
  persID?: number;
}

interface IServicemeldingDialoogState {
  prodID: number;
}

export interface IToevoegenTransportopdrachtDialoogState {
  relID: number;
  cntID: number;
}

interface INieuweVorderingDialoogState {
  cntID: number;
}
interface IKopierenContractDialoogState {
  cntID: number;
}
export interface IVoorraadTypeInfoDialoogState {
  typeID: number;
  magID: number | null;
}

export interface IProductUitBedrijfDialoogState {
  prodID: number;
}

export interface INieuwContractDialoogState {
  contractwissel_CntID?: number;
}

const ContractTegel: React.FC<IProps> = (props) => {
  const { checkStore, klantkaartStore } = useContext(RootStoreContext);

  const { contract, geselecteerd, onGeselecteerdChange, vernieuwenContracten } = props;

  const [
    nieuweVorderingDialoogState,
    setNieuweVorderingDialoogState,
  ] = useState<INieuweVorderingDialoogState | null>(null);

  const [
    productmodelinfoDialoogState,
    setProductmodelinfoDialoogState,
  ] = useState<IProductmodelinfoDialoogState | null>(null);

  const [
    productSelectieDialoogState,
    setProductSelectieDialoogState,
  ] = useState<IProductSelectieDialoogState | null>(null);

  const [
    productinfoDialoogState,
    setProductinfoDialoogState,
  ] = useState<IProductinfoDialoogState | null>(null);

  const [
    productWijzigenDialoogState,
    setProductWijzigenDialoogState,
  ] = useState<IProductWijzigenDialoogState | null>(null);

  const [
    versturenDocumentatieDialoogState,
    setVersturenDocumentatieDialoogState,
  ] = useState<IDocumentenDialoogState | null>(null);

  const [
    toevoegenTransportopdrachtDialoogState,
    setToevoegenTransportopdrachtDialoogState,
  ] = useState<IToevoegenTransportopdrachtDialoogState | null>(null);

  const [
    voorraadTypeInfoDialoogState,
    setVoorraadTypeInfoDialoogState,
  ] = useState<IVoorraadTypeInfoDialoogState | null>(null);

  const [
    kopierenContractDialoogState,
    setKopierenContractDialoogState,
  ] = useState<IKopierenContractDialoogState | null>(null);

  const [
    productUitBedrijfDialoogState,
    setProductUitBedrijfDialoogState,
  ] = useState<IProductUitBedrijfDialoogState | null>(null);

  const {
    CntID,
    // Volgnummer,
    // Basisnummer,
    // productmodel,
    // Ingangsdatum,
    // Einddatum,
    // MinimaleEinddatum,
    // Statusnaam,
    // MinimaleTermijn,
    // locatie,
    // tarief,
    // mutaties,
  } = contract;

  // const [leveropdrachtTonen, setLeveropdrachtTonen] = useState<boolean>(false);
  // const [verkoopvoorstelTonen, setVerkoopvoorstelTonen] = useState<boolean>(false);
  const [restbedragProlongatie, setRestbedragProlongatie] = useState<number | null>(null);
  const [beeindigingDialoogTonen, setBeeindigingDialoogTonen] = useState<boolean>(false);
  const [wijzigenDialoogTonen, setWijzigenDialoogTonen] = useState<boolean>(false);

  const [fiatteringNieuwDialoogTonen, setFiatteringNieuwDialoogTonen] = useState<boolean>(false);
  const [fiatteringOvernameDialoogTonen, setFiatteringOvernameDialoogTonen] = useState<boolean>(
    false,
  );
  const [
    nieuwContractDialoogState,
    setNieuwContractDialoogState,
  ] = useState<INieuwContractDialoogState | null>(null);

  const [producten, setProducten] = useState<IOphalenProductenResultElementV2[]>([]);
  const [vorderingen, setVorderingen] = useState<IOphalenFactuurregelsResultElement[]>([]);
  const [facturen, setFacturen] = useState<IOphalenFacturenBasisResultElement[]>([]);
  const [servicemeldingen, setServicemeldingen] = useState<IOphalenMeldingenResultElement[]>([]);

  const [transportopdrachtregels, setTransportopdrachtregels] = useState<
    IOphalenOpdrachtregelsResultElement[] | null
  >(null);

  const [volgendContract, setVolgendContract] = useState<
    IRemoteData<IOphalenContractenResultElementV2 | null>
  >(createPendingRemoteData);
  const [vorigContract, setVorigContract] = useState<
    IRemoteData<IOphalenContractenResultElementV2 | null>
  >(createPendingRemoteData);
  const [verkoopvoorstel, setVerkoopvoorstel] = useState<
    IRemoteData<IOphalenVerkoopVoorstellenResultElement | null>
  >(createPendingRemoteData());

  const [
    servicemeldingDialoogState,
    setServicemeldingDialoogState,
  ] = useState<IServicemeldingDialoogState | null>(null);

  const ophalenServicemeldingen = useCallback(async () => {
    const result = await api.v2.service.ophalenMeldingen({
      filterSchema: {
        filters: [
          {
            naam: 'CNT_IDS',
            data: contract.CntID,
          },
        ],
      },
    });
    setServicemeldingen(result.meldingen);
  }, [contract.CntID]);

  useEffect(() => {
    ophalenServicemeldingen();
  }, [ophalenServicemeldingen]);

  const ophalenProducten = useCallback(async () => {
    const productenResult = await api.v2.product.ophalenProductenV2({
      filterSchema: {
        filters: [
          {
            naam: 'CNTBASIS_IDS',
            data: contract.basis.CntBasisID,
          },
        ],
      },
    });

    // Producten met status Verhuur komen  bovenaan in de sortering
    const productenGesorteerd = _.orderBy(
      productenResult.producten,
      [
        (x: any) => {
          return x.StatusnaamEnum === EProductstatus.Verhuur ? 0 : 1;
        },
      ],
      ['asc'],
    );

    setProducten(productenGesorteerd);
  }, [contract]);

  useEffect(() => {
    ophalenProducten();
  }, [ophalenProducten]);

  const ophalenTransportregels = useCallback(async () => {
    const transportregelsResult = await api.v2.transport.opdracht.ophalenOpdrachtregels({
      filterSchema: {
        filters: [
          {
            naam: 'CNTBASIS_IDS',
            data: contract.basis.CntBasisID,
          },
        ],
      },
    });
    setTransportopdrachtregels(transportregelsResult.regels);
  }, [contract.basis.CntBasisID]);

  useEffect(() => {
    ophalenTransportregels();
  }, [ophalenTransportregels]);

  const ophalenVerkoopvoorstel = useCallback(async () => {
    const verkoopvoorstellenResult = (
      await api.v2.product.verkoop.ophalenVerkoopvoorstellen({
        filterSchema: {
          filters: [
            {
              naam: 'CNT_IDS',
              data: contract.CntID,
            },
            {
              naam: 'GELDIGTOT_VAN',
              data: new Date(),
            },
          ],
        },
      })
    ).voorstellen;

    setVerkoopvoorstel(
      createReadyRemoteData(
        verkoopvoorstellenResult.length !== 0 ? verkoopvoorstellenResult[0] : null,
      ),
    );
  }, [contract.CntID]);

  useEffect(() => {
    ophalenVerkoopvoorstel();
  }, [ophalenVerkoopvoorstel]);

  const ophalenVorderingen = useCallback(async () => {
    const vorderingenResult = (
      await api.v2.factuur.ophalenFactuurregels({
        filterSchema: {
          filters: [
            {
              naam: 'CNT_IDS',
              data: [contract.CntID],
            },
          ],
        },
      })
    ).regels;

    setVorderingen(vorderingenResult);
  }, [contract.basis.CntBasisID]);

  useEffect(() => {
    ophalenVorderingen();
  }, [ophalenVorderingen]);

  const ophalenFacturen = useCallback(async () => {
    if (vorderingen === null) {
      return;
    }

    const facturenResult = (
      await api.v2.factuur.ophalenFacturenBasis({
        filterSchema: {
          filters: [
            {
              naam: 'IDS',
              data: vorderingen.filter((x) => x.FactID !== null).map((x) => x.FactID),
            },
          ],
        },
      })
    ).facturen;

    setFacturen(facturenResult);
  }, [vorderingen]);

  useEffect(() => {
    ophalenFacturen();
  }, [ophalenFacturen]);

  // Vorig contract
  const ophalenVorigContract = useCallback(async () => {
    if (contract.vorigContract === null) {
      setVorigContract(createReadyRemoteData(null));
      return;
    }
    const contractResult = (
      await api.v2.contract.ophalenContractenV2({
        filterSchema: {
          filters: [
            {
              naam: 'IDS',
              data: contract.vorigContract!.CntID,
            },
          ],
        },
      })
    ).contracten[0];

    setVorigContract(createReadyRemoteData(contractResult));
  }, [contract.vorigContract]);

  useEffect(() => {
    ophalenVorigContract();
  }, [ophalenVorigContract]);

  // Vorig contract
  const ophalenVolgendContract = useCallback(async () => {
    if (contract.volgendContract === null) {
      setVolgendContract(createReadyRemoteData(null));
      return;
    }
    const contractResult = (
      await api.v2.contract.ophalenContractenV2({
        filterSchema: {
          filters: [
            {
              naam: 'IDS',
              data: contract.volgendContract!.CntID,
            },
          ],
        },
      })
    ).contracten[0];

    setVolgendContract(createReadyRemoteData(contractResult));
  }, [contract.volgendContract]);

  useEffect(() => {
    ophalenVolgendContract();
  }, [ophalenVolgendContract]);

  useEffect(() => {
    (async () => {
      if (contract.prolongatie.Tot === null || contract.prolongatie.Eind === null) {
        return;
      }
      const params = {
        datumVan: contract.prolongatie.Tot,
        datumTot: contract.prolongatie.Eind,
        maandhuur: contract.basis.Maandhuur,
        maandhuurActie: contract.basis.actie !== null ? contract.basis.actie.Maandhuur : null,
        datumActieEind: contract.basis.actie !== null ? contract.basis.actie.DatumTot : null,
        gebruikerstoeslag: contract.basis.GebruikerstoeslagTarief, //tarief.gebruikerstoeslag,
      };
      const result = await api.v2.prolongatie.berekenHuurprolongatie(params);

      setRestbedragProlongatie(result.huurProlongatie);
    })();
  }, [contract]);

  // const handleBeeindigenContracten = useCallback(async () => {
  //   const checkData = await api.v2.contract.beeindiging.checkSelectieBeeindigenContracten({
  //     relID: contract.RelID,
  //     cntIDs: [contract.CntID],
  //   });

  //   if (
  //     (
  //       await checkStore.controleren({
  //         checkData,
  //       })
  //     ).type === EResultType.Annuleren
  //   ) {
  //     return;
  //   }

  //   setBeeindigingDialoogTonen(true);
  // }, [contract]);

  const ContractIndicaties = () => {
    if (
      producten === null ||
      transportopdrachtregels === null ||
      verkoopvoorstel.state === ERemoteDataState.Pending ||
      volgendContract.state === ERemoteDataState.Pending ||
      vorigContract.state === ERemoteDataState.Pending
    ) {
      return null;
    }

    const product = producten.length === 1 ? producten[0] : null;

    // Niet afgemelde regels die aan het contract gekoppeld zijn
    const opdrachtregels = transportopdrachtregels.filter(
      (x) => x.contract !== null && x.contract.CntID === contract!.CntID && x.status.Status === 0,
    );

    const leveropdrachten = opdrachtregels.filter(
      (x) => x.soort.NaamEnum === ETransportopdrachtRegelsoort.Levering,
    );
    const retouropdrachten = opdrachtregels.filter(
      (x) => x.soort.NaamEnum === ETransportopdrachtRegelsoort.Retour,
    );

    const leveropdrachtMaken =
      !contract.producten.some((x) => x.CntBasisID === contract.basis.CntBasisID) &&
      contract.status.NaamEnum === EContractStatus.Lopend &&
      !transportopdrachtregels.some(
        (x) =>
          x.contract!.basis.CntBasisID === contract.basis.CntBasisID &&
          x.soort.Code === 'L' &&
          // x.OmruilID === null &&
          x.status.Status === 0, //ERegelstatus.NIET_AFGEMELD,
      );

    const meestRecenteServicemelding = servicemeldingen.filter((x) => !x.Afgehandeld);

    return (
      <div className="d-flex flex-column">
        <ul className="p-0">
          {contract.basis.Verdieping !== null && (
            <li>
              {contract.basis.Verdieping === 0
                ? 'Begane grond'
                : `Verdieping ${
                    contract.basis.Verdieping !== 99
                      ? contract.basis.Verdieping === 0
                        ? ''
                        : ` ${contract.basis.Verdieping}e`
                      : ''
                  }`}
            </li>
          )}
          {leveropdrachten.length !== 0 && (
            <li>
              <span className="d-flex">
                {leveropdrachten.length === 1 ? (
                  <span className="d-flex">
                    Leveropdr.&nbsp;&nbsp;
                    <TransportopdrachtregelVisualisatie trsRegID={leveropdrachten[0].TrsRegID} />
                  </span>
                ) : (
                  'Leveropdrachten'
                )}
              </span>
            </li>
          )}
          {retouropdrachten.length !== 0 && (
            <li>
              <span className="d-flex">
                {retouropdrachten.length === 1 ? (
                  <span className="d-flex">
                    Retouropdr.&nbsp;&nbsp;
                    <TransportopdrachtregelVisualisatie trsRegID={retouropdrachten[0].TrsRegID} />
                  </span>
                ) : (
                  'Retouropdrachten'
                )}
              </span>
            </li>
          )}
          {verkoopvoorstel.data !== null && contract.status.NaamEnum === EContractStatus.Lopend && (
            <li>
              <span className="d-flex">
                <span>Heeft verkoopvoorstel</span>
              </span>
            </li>
          )}
          {contract.AccID === null &&
            contract.DatumBevestigd === null &&
            contract.status.NaamEnum === EContractStatus.Concept && (
              <li style={{ color: EKleur.Rood }}>Wacht op bevestiging</li>
            )}
          {meestRecenteServicemelding.length !== 0 && (
            <>
              <li>
                <span className="d-flex">
                  <ServicemeldingVisualisatie servMeldID={meestRecenteServicemelding[0].ID} />
                </span>
              </li>
            </>
          )}
          {contract.KostenVtb !== null && contract.KostenVtb !== 0 && (
            <li>
              <span className="d-flex">
                <span>Kosten VTB </span> <FormatteerBedrag bedrag={contract.KostenVtb} />
              </span>
            </li>
          )}
          {contract.AangebodenOvernameOp !== null && <li>Aangeboden ter overname</li>}
          {contract.AangebodenOvernameOp !== null && volgendContract.data !== null && (
            <li>
              Overnameverzoek door:{' '}
              <RelatieVisualisatie
                relID={volgendContract.data!.RelID}
                options={{
                  geenLinkToepassen: false,
                }}
              />
            </li>
          )}
          {vorigContract.data !== null &&
            contract.status.NaamEnum === EContractStatus.Concept &&
            contract.Volgnummer > 0 && (
              <li>
                In overname van:{' '}
                <RelatieVisualisatie
                  relID={vorigContract.data!.RelID}
                  options={{
                    geenLinkToepassen: false,
                  }}
                />
              </li>
            )}
          {contract.gewisseldDoorContract !== null && (
            <li>
              <span className="d-flex">
                Wissel&nbsp;Door&nbsp;
                <ContractVisualisatie cntID={contract.gewisseldDoorContract.CntID} />
              </span>
            </li>
          )}
          {volgendContract.data !== null &&
            volgendContract.data!.status.NaamEnum !== EContractStatus.Concept && (
              <li>
                <span className="d-flex">
                  Overgenomen&nbsp;Door&nbsp;
                  <ContractVisualisatie cntID={volgendContract.data.CntID} />
                </span>
              </li>
            )}
          {vorigContract.data !== null &&
            vorigContract.data.status.NaamEnum !== EContractStatus.Concept && (
              <li>
                <span className="d-flex">
                  Overgenomen&nbsp;Van&nbsp;
                  <ContractVisualisatie cntID={vorigContract.data.CntID} />
                </span>
              </li>
            )}
          {contract.gewisseldVoorContract !== null && contract.Volgnummer === 0 && (
            <li>
              <span className="d-flex">
                Wissel&nbsp;Voor&nbsp;
                <ContractVisualisatie cntID={contract.gewisseldVoorContract.CntID} />
              </span>
            </li>
          )}
          {contract.InterneMutatie &&
            (contract.status.NaamEnum === EContractStatus.Beeindigd ? (
              <li>Interne beeindiging</li>
            ) : contract.status.NaamEnum === EContractStatus.Geannuleerd ? (
              <li>Interne Annulering</li>
            ) : (
              <li>Interne mutatie</li>
            ))}
          {product !== null && product.locatie!.LocID !== contract.basis.locatie.LocID && (
            <li>Afw. locatie product</li>
          )}
          {contract.RelRekID !== null && <li>Afwijkende rekening</li>}
          {contract.factuurkenmerk !== null && <li>Fact.ken.: {contract.factuurkenmerk.Naam}</li>}
        </ul>
      </div>
    );
  };

  const verhuurdeProducten = useMemo(() => {
    // Geeft het geplaatste product weer indien aanwezig
    if (producten === null) {
      return null;
    }

    // Is er een Verhuurd (geplaatst) product gekoppeld?
    return producten.filter((x) => x.productstatus.NaamEnum === EProductstatus.Verhuur);
  }, [producten]);

  const productElement = useMemo(() => {
    // Geeft het geplaatste product weer indien aanwezig
    if (producten === null) {
      return;
    }

    // Is er een Verhuurd (geplaatst) product gekoppeld?
    const productenVerhuurd = producten.filter(
      (x) => x.productstatus.NaamEnum === EProductstatus.Verhuur,
    );

    if (productenVerhuurd.length === 0 || productenVerhuurd.length > 1) {
      // return <span>Geen gekoppelde product</span>;
      return <span></span>;
    }
    const product = productenVerhuurd[0];
    return (
      <div className="d-flex flex-column flex-fill">
        <GegevensLayout
          gegevens={[
            {
              label: 'Ref. code',
              waarde: product.Referentiecode ?? 'Geen',
            },
            {
              label: 'Leeftijd',
              waarde: product.leeftijd !== null ? maandenNaarJaren(product.leeftijd) : 'Onbekend',
            },
            null,
          ]}
          labelBreedte={60}
        />
        <div className="flex-fill" />
        <div className="d-flex align-items-center" style={{ marginTop: 2 }}>
          <a
            href="#"
            onClick={(ev) => {
              ev.preventDefault();
              setProductinfoDialoogState({
                id: product.ProdID,
              });
            }}
            className="mr-1"
          >
            Info
          </a>
          <span>&ndash;</span>
          <a
            href="#"
            onClick={(ev) => {
              ev.preventDefault();
              setProductWijzigenDialoogState({
                id: product.ProdID,
              });
            }}
            className="ml-1 mr-1"
          >
            Wijz.
          </a>
          <span>&ndash;</span>
          {/*<VerticaleScheidingslijn className="ml-1" height={12} />*/}
          <a
            href="#"
            className="ml-1 mr-1"
            onClick={(ev) => {
              ev.preventDefault();
              setVersturenDocumentatieDialoogState({ prodID: product.ProdID, relID: props.relID });
            }}
          >
            Doc.
          </a>
          <span>&ndash;</span>
          {/*<VerticaleScheidingslijn className="ml-1" height={12} />*/}
          <a
            href="#"
            className="ml-1 mr-1"
            onClick={(ev) => {
              ev.preventDefault();
              setServicemeldingDialoogState({
                prodID: product.ProdID,
              });
            }}
          >
            Melding
          </a>
          <span>&ndash;</span>
          {/*<VerticaleScheidingslijn className="ml-1" height={12} />*/}
          <a
            href="#"
            className="ml-1"
            onClick={(ev) => {
              setToevoegenTransportopdrachtDialoogState({
                relID: props.contract.RelID,
                cntID: props.contract.CntID,
              });
            }}
          >
            Omruil
          </a>
        </div>

        {/*<div>*/}
        {/*  <span hidden>ProdID: {product.ProdID}</span>*/}
        {/*  <a*/}
        {/*    href="#"*/}
        {/*    className="ml-1"*/}
        {/*    style={{ color: EKleur.LichtBlauw, position: 'relative', bottom: 2 }}*/}
        {/*    onClick={() => {*/}
        {/*      setProductinfoDialoogState({ id: product.ProdID });*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    <IconInformatie style={{ width: 15, height: 15, fill: EKleur.Blauw }} />*/}
        {/*  </a>*/}
        {/*</div>*/}
        {/*<div>*/}
        {/*  <div>*/}
        {/*    <span>*/}
        {/*      {product.Referentiecode !== null ? product.Referentiecode : 'Geen ref.'}*/}
        {/*      &nbsp;&nbsp;&nbsp;&nbsp;*/}
        {/*      {product.leeftijd !== null ? maandenNaarJaren(product.leeftijd) : ''}{' '}*/}
        {/*    </span>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
    );
  }, [producten]);

  const imgSrc = useMemo(() => {
    return contract.basis.productmodel.bestanden.length === 0 ||
      contract.basis.productmodel.bestanden[0] === undefined ||
      contract.basis.productmodel.bestanden[0].bestand === undefined
      ? null
      : contract.basis.productmodel.bestanden[0].bestand.url;
  }, [contract.basis.productmodel]);

  const [uitgeklaptSectieId, setUitgeklaptSectieId] = useState<ESectie | null>(null);

  const SectieComp = useMemo(() => {
    if (uitgeklaptSectieId === null) {
      return null;
    }
    return sectieComponentMapping[uitgeklaptSectieId];
  }, [uitgeklaptSectieId]);

  const prolongatieTeken = useMemo(() => {
    const { Start, Eind, Tot } = contract.prolongatie;

    return prolongatieRichting({
      Start,
      Eind,
      Tot,
    });
  }, [contract.prolongatie]);

  const maandhuur = useMemo(() => {
    let indicatie: string = '';
    let maandhuur: string = '';

    indicatie += contract.basis.gebruikerstoeslag !== 0 ? 'T,' : '';
    // indicatie +=
    //   contract.basis.actie !== null &&
    //   (contract.basis.actie.DatumTot === null ||
    //     contract.basis.actie.DatumTot.toString() > new Date().toISOString()) &&
    //   contract.basis.actie.Maandhuur !== null
    //     ? 'A,'
    //     : '';

    if (contract.basis.actie === null) {
      indicatie += '';
      maandhuur =
        formatteerBedrag(contract.basis.Maandhuur + contract.basis.gebruikerstoeslag) + indicatie;
    } else {
      if (contract.basis.actie.DatumTot === null) {
        indicatie += ' A';
        maandhuur =
          formatteerBedrag(
            (contract.basis.actie.Maandhuur !== null
              ? contract.basis.actie.Maandhuur
              : contract.basis.Maandhuur) + contract.basis.gebruikerstoeslag,
          ) + indicatie;
      } else {
        if (contract.basis.actie.DatumTot.toString() > new Date().toISOString()) {
          indicatie += ' (A)';
          maandhuur =
            formatteerBedrag(
              (contract.basis.actie.Maandhuur !== null
                ? contract.basis.actie.Maandhuur
                : contract.basis.Maandhuur) + contract.basis.gebruikerstoeslag,
            ) + indicatie;
        } else {
          indicatie += '';
          maandhuur =
            formatteerBedrag(contract.basis.Maandhuur + contract.basis.gebruikerstoeslag) +
            indicatie;
        }
      }
    }

    return maandhuur;
  }, [
    contract.basis.Maandhuur,
    contract.basis.gebruikerstoeslag,
    contract.basis.actie,
    // contract.basis.actie.Maandhuur,
  ]);

  const handleVerwijderenContract = useCallback(async () => {
    const checkData = await api.v2.contract.nieuw.checkVerwijderenContracten({
      cntIDs: [CntID],
    });
    const controleResult = await checkStore.controleren({ checkData });
    if (controleResult.type === EResultType.Annuleren) {
      return;
    }

    const resultaat = await checkStore.bevestigen({
      titel: `Contract verwijderen?`,
    });
    if (resultaat.type === EResultType.Annuleren) {
      return;
    }

    props.onGeselecteerdChange(false);
    await api.v2.contract.nieuw.verwijderenContracten({
      cntIDs: [CntID],
    });

    vernieuwenContracten();
  }, [CntID]);

  // const handleFiatteren = useCallback(async () => {
  //   if (contract.Volgnummer === 0) {
  //     setFiatteringNieuwDialoogTonen(true);
  //   } else {
  //     setFiatteringOvernameDialoogTonen(true);
  //   }
  // }, []);

  const handleWijzigen = useCallback(async () => {
    setWijzigenDialoogTonen(true);
  }, []);

  const handleTerugdraaienAanbiedingOvername = useCallback(async () => {
    const checkData = await api.v2.contract.aanbieding.checkTerugdraaienAanbiedenContracten({
      cntIDs: [CntID],
    });
    const controleResult = await checkStore.controleren({ checkData });
    if (controleResult.type === EResultType.Annuleren) {
      return;
    }

    const resultaat = await checkStore.bevestigen({
      titel: `Aanbieding overname verwijderen?`,
    });
    if (resultaat.type === EResultType.Annuleren) {
      return;
    }

    await api.v2.contract.aanbieding.terugdraaienAanbiedenContracten({
      cntIDs: [CntID],
    });

    vernieuwenContracten();
  }, [CntID]);

  const handleTerugdraaienMarkerenContractenVoorWissel = useCallback(async () => {
    // const checkData = await api.v2.contract.wissel.terugdraaienMarkerenContractenVoorWissel({
    //   cntIDs: [CntID],
    // });
    // const controleResult = await checkStore.controleren({ checkData });
    // if (controleResult.type === EResultType.Annuleren) {
    //   return;
    // }

    const resultaat = await checkStore.bevestigen({
      titel: `Markering contractwissel verwijderen?`,
    });
    if (resultaat.type === EResultType.Annuleren) {
      return;
    }

    await api.v2.contract.wissel.terugdraaienMarkerenContractenVoorWissel({
      cntIDs: [CntID],
    });

    vernieuwenContracten();
  }, [CntID]);

  const handleAnnuleringTerugdraaien = useCallback(async () => {
    const checkData = await api.v2.contract.annulering.checkTerugdraaienAnnulerenContracten({
      cntIDs: [contract.CntID],
    });
    if (
      (
        await checkStore.controleren({
          checkData,
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    if (
      (
        await checkStore.bevestigen({
          titel: 'Annulering terugdraaien?',
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    await api.v2.contract.annulering.terugdraaienAnnulerenContracten({
      cntIDs: [contract.CntID],
    });
    vernieuwenContracten();
  }, []);

  // const handleContractwissel = useCallback(async (cntID: number) => {
  //   setNieuwContractDialoogState(null);

  //   vernieuwenContracten();

  //   const paramsLevering = {
  //     soort: 'L',
  //     cntIDs: [cntID],
  //     opties: {
  //       aansluitenTrekschakelaar: null,
  //     },
  //     trsDienstID: null,
  //     bezoekdatum: null,
  //   };
  //   const checkDataLevering = await api.v2.transport.opdracht.nieuw.checkToevoegenStandaardOpdrachten(
  //     paramsLevering,
  //   );
  //   if (
  //     (
  //       await checkStore.controleren({
  //         checkData: checkDataLevering,
  //       })
  //     ).type === EResultType.Annuleren
  //   ) {
  //     return;
  //   }
  //   await api.v2.transport.opdracht.nieuw.toevoegenStandaardOpdrachten(paramsLevering);

  //   const paramsRetour = {
  //     soort: 'R',
  //     cntIDs: [contract.CntID],
  //     opties: {
  //       aansluitenTrekschakelaar: null,
  //     },
  //     trsDienstID: null,
  //     bezoekdatum: null,
  //   };
  //   const checkDataRetour = await api.v2.transport.opdracht.nieuw.checkToevoegenStandaardOpdrachten(
  //     paramsRetour,
  //   );
  //   if (
  //     (
  //       await checkStore.controleren({
  //         checkData: checkDataRetour,
  //       })
  //     ).type === EResultType.Annuleren
  //   ) {
  //     return;
  //   }

  //   await api.v2.transport.opdracht.nieuw.toevoegenStandaardOpdrachten(paramsRetour);
  // }, []);

  const handleTerugdraaienBeeindiging = useCallback(async () => {
    const params = { cntIDs: [CntID], relID: props.relID };
    const checkData = await api.v2.contract.beeindiging.checkTerugdraaienBeeindigingContracten(
      params,
    );
    const controleResult = await checkStore.controleren({ checkData });
    if (controleResult.type === EResultType.Annuleren) {
      return;
    }

    const resultaat = await checkStore.bevestigen({
      inhoud: (
        <span>
          Beeindiging contract(en) terugdraaien?
          <br />
          Let op: Eventuele vorderingen en/of retouropdrachten moeten handmatig verwijderd worden.
        </span>
      ),
    });
    if (resultaat.type === EResultType.Annuleren) {
      return;
    }

    await api.v2.contract.beeindiging.terugdraaienBeeindigingContracten(params);

    vernieuwenContracten();
  }, [CntID]);

  const handleTerugdraaienFiatteringContract = useCallback(async () => {
    const params = { cntIDs: [CntID], relID: props.relID };
    const checkData = await api.v2.contract.nieuw.checkHerstellenFiatterenContracten(params);
    const checkResult = await checkStore.controleren({
      checkData,
    });
    if (checkResult.type === EResultType.Annuleren) {
      return;
    }

    if (
      (
        await checkStore.bevestigen({
          inhoud: 'Fiattering contract terugdraaien?',
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    await api.v2.contract.nieuw.herstellenFiatterenContracten(params);

    vernieuwenContracten();
  }, [CntID]);

  const [bezigMetContractDownloaden, setBezigMetContractDownloaden] = useState(false);

  return (
    <Root>
      <Tegel
        // linkerKolom={}
        rootProps={{
          style: {
            // borderLeft: `5px solid ${
            //   contractstatusKleurMap[contract.status.NaamEnum as EContractstatus]
            // }`,
            // borderTopLeftRadius: 0,
            // borderBottomLeftRadius: 0,
            // boxSizing: 'content-box',
            paddingLeft: 0,
            paddingTop: 0,
            paddingBottom: 0,
          },
        }}
      >
        <div className="d-flex">
          <StatusLabel
            naam={contract.status.Naam.toUpperCase()}
            kleur={contractstatusKleurMap[contract.status.NaamEnum as EContractstatus]}
          />
          {/*<div*/}
          {/*  style={{*/}
          {/*    backgroundColor: contractstatusKleurMap[contract.status.NaamEnum as EContractstatus],*/}
          {/*    width: 25,*/}
          {/*    // maxWidth: 10,*/}
          {/*    // minHeight: '100%',*/}
          {/*    color: Kleur.Wit,*/}
          {/*    textOrientation: 'mixed',*/}
          {/*    writingMode: 'vertical-rl',*/}
          {/*    letterSpacing: 2.5,*/}
          {/*    fontSize: '0.8rem',*/}
          {/*  }}*/}
          {/*  className="d-flex align-items-center justify-content-center font-weight-bold"*/}
          {/*>*/}
          {/*  {contract.status.Naam.toUpperCase()}*/}
          {/*</div>*/}
          <div className="flex-fill d-flex" style={{ padding: 10 }}>
            <TegelSelectie
              geselecteerd={geselecteerd}
              onGeselecteerdChange={onGeselecteerdChange}
            />
            <div className="flex-fill">
              <div className="d-flex align-items-start">
                <div
                  className="d-flex flex-column justify-content-center"
                  style={{
                    height: '100%',
                    minWidth: 80,
                  }}
                >
                  {/*<div style={{ width: 125 }} className="d-flex justify-content-start">*/}
                  {/*  <ContractStatus*/}
                  {/*    status={contract.StatusnaamEnum as EContractStatus}*/}
                  {/*    naam={contract.Statusnaam}*/}
                  {/*  />*/}
                  {/*</div>*/}
                  {imgSrc !== null ? (
                    <img
                      style={{
                        objectFit: 'contain',
                        objectPosition: 'left',
                        width: 60,
                        height: 80,
                        marginLeft: 5,
                      }}
                      src={imgSrc}
                      alt="Afbeelding niet in te laden"
                    />
                  ) : (
                    <div
                      style={{
                        width: 60,
                        height: 80,
                        marginLeft: 5,
                      }}
                      className="d-flex align-items-center justify-content-center"
                    >
                      <span className="text-muted text-center" style={{ fontSize: 11 }}>
                        Geen afbeelding beschikbaar
                      </span>
                    </div>
                  )}
                </div>

                <div className="d-flex flex-fill pb-1">
                  <div className="d-flex flex-column">
                    <div className="font-weight-bold d-flex justify-content-between align-items-center">
                      <div className="d-flex justify-content-between align-items-center">
                        <div>
                          <span hidden>CntID: {contract.CntID}</span>
                          <span>
                            {contract.basis.Basisnummer}.{contract.Volgnummer}{' '}
                            {contract.basis.productmodel.Modelnaam}
                            {/* <span style={{ color: EKleur.Grijs }} className="ml-2">
                      ({productmodel.Modelcode})
                    </span> */}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      className="d-flex"
                      style={{
                        paddingTop: `2px`,
                        width: `100%`,
                      }}
                    >
                      <div style={{ width: 200 }}>
                        <GegevensLayout
                          gegevens={[
                            {
                              label: 'Basisdatum',
                              waarde: format(new Date(contract.IngangsdatumBasis), 'dd-MM-yyyy'),
                            },
                            {
                              label: 'Ingangsdatum',
                              waarde: format(new Date(contract.Ingangsdatum), 'dd-MM-yyyy'),
                            },
                            {
                              label: 'Einddatum',
                              waarde:
                                contract.Einddatum === null ? (
                                  new Date(contract.minimaleEinddatum) > new Date() ? (
                                    <span
                                      style={{
                                        color: EKleur.LichtGrijs,
                                      }}
                                    >
                                      {format(new Date(contract.minimaleEinddatum), 'dd-MM-yyyy')}
                                    </span>
                                  ) : (
                                    <span
                                      style={{
                                        color: EKleur.Grijs,
                                      }}
                                    >
                                      Opzegbaar
                                    </span>
                                  )
                                ) : (
                                  <span>{format(new Date(contract.Einddatum!), 'dd-MM-yyyy')}</span>
                                ),
                            },
                            {
                              label: 'Geprol. tot',
                              waarde: (
                                <span className="d-flex align-items-center justify-content-center">
                                  {contract.prolongatie.Ophouden ? (
                                    <span
                                      style={{
                                        color: Kleur.Rood,
                                      }}
                                    >
                                      Opgehouden
                                    </span>
                                  ) : contract.prolongatie.Tot !== null ? (
                                    format(new Date(contract.prolongatie.Tot), 'dd-MM-yyyy')
                                  ) : (
                                    '-'
                                  )}
                                  <span className="ml-1">{prolongatieTeken}</span>
                                </span>
                              ),
                            },
                            // {
                            //   label: 'Geprolongeerd',
                            //   waarde: `${
                            //     contract.prolongatie.Ophouden
                            //       ? 'OPGEHOUDEN'
                            //       : contract.prolongatie.Tot !== null
                            //       ? format(new Date(contract.prolongatie.Tot), 'dd-MM-yyyy')
                            //       : 'Nog niet'
                            //   } ${prolongatieTeken}`,
                            // },
                          ]}
                        />
                      </div>

                      <div className="ml-2" style={{ width: 185 }}>
                        <GegevensLayout
                          gegevens={[
                            {
                              label: 'Modelcode',
                              waarde: (
                                <div>
                                  <span
                                    style={{
                                      color: !contract.basis.productmodel.Actief
                                        ? EKleur.DonkerRood
                                        : undefined,
                                    }}
                                  >
                                    {contract.basis.productmodel.Modelcode}{' '}
                                  </span>
                                  <a
                                    href="#"
                                    className="ml-1"
                                    style={{
                                      color: Kleur.LichtBlauw,
                                      position: 'relative',
                                      bottom: 2,
                                    }}
                                    onClick={() =>
                                      setProductmodelinfoDialoogState({
                                        id: contract.basis.productmodel.ProdModID,
                                      })
                                    }
                                  >
                                    <IconInformatie
                                      style={{ width: 15, height: 15, fill: Kleur.Blauw }}
                                    />
                                  </a>
                                </div>
                              ),
                            },
                            // {
                            //   label: 'Modelcode',
                            //   waarde: `${contract.basis.productmodel.Modelcode}`,
                            // },
                            {
                              label: 'Huur',
                              waarde: `${maandhuur}`,
                            },
                            {
                              label: 'Abon.',
                              waarde: `${maandenNaarJaren(contract.basis.MinimaleTermijn)}`,
                            },
                            {
                              label: 'Gebruikers',
                              waarde: contract.basis.AantalGebruikers.toString(),
                            },
                            // {
                            //   label: 'Prol. tot',
                            //   waarde: (
                            //     <span>
                            //       {contract.prolongatie.Ophouden
                            //         ? 'Opgehouden'
                            //         : contract.prolongatie.Tot !== null
                            //         ? format(new Date(contract.prolongatie.Tot), 'dd-MM-yyyy')
                            //         : 'Nog niet'}
                            //       <span
                            //         className="ml-2"
                            //         style={{
                            //           position: 'relative',
                            //           top: -2,
                            //         }}
                            //       >
                            //         {prolongatieTeken}
                            //       </span>
                            //     </span>
                            //   ),
                            // },
                          ]}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-fill ml-2">
                    {/*<VerticaleScheidingslijn />*/}
                    <div
                      className="d-flex flex-column flex-fill ml-2"
                      style={{ minWidth: 250, maxWidth: 250 }}
                    >
                      <span className="ml-2">
                        {(() => {
                          if (verhuurdeProducten === null) {
                            return <span />;
                          }
                          if (verhuurdeProducten.length === 0) {
                            if (
                              contract.status.NaamEnum === EContractstatus.Beeindigd &&
                              contract.VerkoopProduct
                            ) {
                              return <span>Beeindiging met verkoop</span>;
                            }

                            // Primair type
                            const typeID =
                              contract.basis.productmodel.producttypen.length > 0
                                ? contract.basis.productmodel.producttypen[0].TypeID
                                : null;

                            const typenaam =
                              contract.basis.productmodel.producttypen.length > 0
                                ? contract.basis.productmodel.producttypen[0]
                                : null;
                            const melding =
                              typenaam !== null
                                ? `${typenaam.Merknaam} ${typenaam.Typenaam}`
                                : null;

                            if (contract.status.NaamEnum === EContractstatus.Concept) {
                              return (
                                <div>
                                  {melding !== null && (
                                    <div>
                                      <div className="mt-0">
                                        <b>Primair type:</b>
                                        <br />
                                        {melding}
                                      </div>
                                      {typeID !== null && (
                                        <div>
                                          <a
                                            href="#"
                                            onClick={() => {
                                              setVoorraadTypeInfoDialoogState({
                                                typeID,
                                                magID: null,
                                              });
                                            }}
                                          >
                                            <span>Voorraadinfo</span>
                                          </a>
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </div>
                              );
                            }

                            if (contract.status.NaamEnum === EContractstatus.Lopend) {
                              return (
                                <div>
                                  <div>Er is geen gekoppeld product</div>
                                  {melding !== null && (
                                    <div className="mt-0">
                                      Primair type:
                                      <br />
                                      {melding}
                                    </div>
                                  )}
                                </div>
                              );
                            }

                            return <span></span>;
                          }

                          if (verhuurdeProducten.length > 1) {
                            return (
                              <span>
                                Er zijn meer dan 1 producten
                                <br />
                                Zie daarvoor de productsectie
                              </span>
                            );
                          }
                          const product = verhuurdeProducten[0];

                          return (
                            <span className="font-weight-bold">
                              {/* <span className="mr-2">
                                {product.producttype.ProductsoortnaamKort}
                              </span> */}
                              {product.producttype.Merknaam}&nbsp;
                              {product.producttype.Typenaam}
                            </span>
                          );
                        })()}
                      </span>

                      <div className="d-flex">
                        <div className="ml-2 d-flex flex-column flex-fill">{productElement}</div>
                      </div>
                    </div>
                    <div className="d-flex flex-fill ml-2">
                      {/*<VerticaleScheidingslijn />*/}
                      <div>
                        <ContractIndicaties />
                      </div>

                      <div className="flex-fill" />

                      <div className="ml-4">
                        <span className="d-flex align-items-center mr-1">
                          <a
                            href="#"
                            onClick={async () => {
                              handleWijzigen();
                            }}
                          >
                            <IconWijzigen
                              style={{
                                width: 15,
                                height: 15,
                                fill: EKleur.Grijs,
                              }}
                            />
                          </a>
                        </span>
                      </div>
                      <ActieMenuKnop
                        acties={[
                          // {
                          //   text: 'Contractwissel',
                          //   onClick: () =>
                          //     setNieuwContractDialoogState({ contractwissel_CntID: contract.CntID }),
                          // },
                          // {
                          //   text: 'Leveropdracht',
                          //   onClick: () => setLeveropdrachtTonen(true),
                          // },
                          {
                            text: 'Nieuwe vordering',
                            onClick: () =>
                              setNieuweVorderingDialoogState({ cntID: contract.CntID }),
                            icon: (
                              <IconVordering
                                style={{
                                  width: 15,
                                  height: 15,
                                }}
                              />
                            ),
                          },
                          {
                            text: 'Downloaden contract',
                            onClick: async () => {
                              setBezigMetContractDownloaden(true);
                              const result = await api.v2.contract.genereerContractPDF({
                                cntID: props.contract.CntID,
                              });
                              await downloadUrl(
                                result.bestand.url,
                                `${props.contract.basis.Basisnummer}.${props.contract.Volgnummer}.pdf`,
                              );
                              setBezigMetContractDownloaden(false);
                            },
                            icon: bezigMetContractDownloaden ? (
                              <LoadingSpinner grootte="12px" dikte="2px" />
                            ) : (
                              <IconDownload
                                style={{
                                  width: 13,
                                  height: 13,
                                }}
                              />
                            ),
                            disabled: bezigMetContractDownloaden,
                          },
                          {
                            text: 'Kopieren contract',
                            onClick: () => {
                              setKopierenContractDialoogState({ cntID: contract.CntID });
                            },
                            icon: (
                              <IconKopieren style={{ width: 15, height: 15, fill: EKleur.Grijs }} />
                            ),
                          },
                          {
                            text: 'Product koppelen aan contract',
                            onClick: () => {
                              setProductSelectieDialoogState({});
                            },
                            // icon: (
                            //   <IconUitBedrijf
                            //     style={{ width: 15, height: 15, fill: EKleur.Grijs }}
                            //   />
                            // ),
                          },
                          {
                            text: 'Product ontkoppelen van contract',
                            disabled:
                              contract.producten.filter(
                                (x) => x.productstatus.NaamEnum === EProductstatus.Verhuur,
                              ).length !== 1,
                            onClick: async () => {
                              // Hier kan alleen het product ontkoppelt worden als het 1 Verhuur-product betreft
                              const prodID =
                                contract.producten.filter(
                                  (x) => x.productstatus.NaamEnum === EProductstatus.Verhuur,
                                )[0].ProdID ?? null;

                              const params = { prodIDs: [prodID] };

                              const checkData = await api.v2.contract.product.checkOntkoppelenProducten(
                                params,
                              );
                              if (
                                (await checkStore.controleren({ checkData })).type ===
                                EResultType.Annuleren
                              ) {
                                return;
                              }

                              if (
                                (
                                  await checkStore.bevestigen({
                                    inhoud: (
                                      <span>
                                        Wil je het product ontkoppelen van het contract?
                                        <br />
                                        <br />
                                        Het product kom daarna in het standaard voorraadmagazijn en
                                        krijgt de status Geparkeerd.
                                      </span>
                                    ),
                                  })
                                ).type === EResultType.Annuleren
                              ) {
                                return;
                              }

                              await api.v2.contract.product.ontkoppelenProducten(params);

                              props.vernieuwenContracten();
                            },
                            // icon: (
                            //   <IconUitBedrijf
                            //     style={{ width: 15, height: 15, fill: EKleur.Grijs }}
                            //   />
                            // ),
                          },

                          {
                            text: 'Product Uit bedrijf markeren',
                            onClick: () => {
                              setProductUitBedrijfDialoogState({
                                prodID: contract.producten.filter(
                                  (x) => x.productstatus.NaamEnum === EProductstatus.Verhuur,
                                )[0].ProdID,
                              });
                            },
                            disabled:
                              contract.producten.filter(
                                (x) => x.productstatus.NaamEnum === EProductstatus.Verhuur,
                              ).length !== 1,
                            icon: (
                              <IconUitBedrijf
                                style={{ width: 15, height: 15, fill: EKleur.Grijs }}
                              />
                            ),
                          },
                          {
                            text: 'Terugdraaien markering contractwissel',
                            onClick: () => {
                              handleTerugdraaienMarkerenContractenVoorWissel();
                            },
                            icon: (
                              <IconTerugdraaien
                                style={{
                                  width: 15,
                                  height: 15,
                                }}
                              />
                            ),
                            disabled:
                              contract.indicaties.AangebodenContractwissel !== null ? false : true,
                            // icon: <IcoonVerwijderen style={{ width: 15, height: 15 }} />,
                          },
                          {
                            text: 'Terugdraaien aanbieding overname',
                            onClick: () => {
                              handleTerugdraaienAanbiedingOvername();
                            },
                            icon: (
                              <IconTerugdraaien
                                style={{
                                  width: 15,
                                  height: 15,
                                }}
                              />
                            ),
                            disabled:
                              contract.indicaties.AangebodenOvername !== null ? false : true,
                            // icon: <IcoonVerwijderen style={{ width: 15, height: 15 }} />,
                          },
                          {
                            text: 'Terugdraaien annulering',
                            onClick: () => {
                              handleAnnuleringTerugdraaien();
                            },
                            icon: (
                              <IconTerugdraaien
                                style={{
                                  width: 15,
                                  height: 15,
                                }}
                              />
                            ),

                            disabled:
                              contract.status.NaamEnum === EContractStatus.Geannuleerd
                                ? false
                                : true,
                            // icon: <IcoonVerwijderen style={{ width: 15, height: 15 }} />,
                          },
                          {
                            text: 'Terugdraaien beeindiging',
                            onClick: () => {
                              handleTerugdraaienBeeindiging();
                            },
                            icon: (
                              <IconTerugdraaien
                                style={{
                                  width: 15,
                                  height: 15,
                                }}
                              />
                            ),

                            disabled:
                              contract.status.NaamEnum === EContractStatus.Beeindigd ? false : true,
                            // icon: <IcoonVerwijderen style={{ width: 15, height: 15 }} />,
                          },
                          {
                            text: 'Terugdraaien fiatteren nieuw contract',
                            onClick: () => {
                              handleTerugdraaienFiatteringContract();
                            },
                            icon: (
                              <IconTerugdraaien
                                style={{
                                  width: 15,
                                  height: 15,
                                }}
                              />
                            ),

                            // disabled:
                            //   contract.status.NaamEnum === EContractStatus.Lopend ? false : true,
                          },
                        ]}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <TegelUitklapMenu<ESectie>
                  uitgeklaptId={uitgeklaptSectieId}
                  onUitgeklaptIdChange={(id) => setUitgeklaptSectieId(id)}
                  secties={[
                    {
                      id: ESectie.Details,
                      label: 'Details',
                    },
                    {
                      id: ESectie.Product,
                      label: 'Product',
                    },
                    {
                      id: ESectie.Service,
                      label: 'Service',
                    },
                    {
                      id: ESectie.Transport,
                      label: 'Transport',
                    },
                    {
                      id: ESectie.Financieel,
                      label: 'Facturen',
                    },
                    // {
                    //   id: ESectie.Mutatie,
                    //   label: 'Mutatie',
                    // },
                  ]}
                />
              </div>
            </div>
          </div>
        </div>

        {/* {verkoopvoorstelTonen && (
          <VerkoopVoorstelDialoog
            open
            prodID={producten[0].ProdID}
            relID={contract.RelID}
            onSuccess={() => setVerkoopvoorstelTonen(false)}
            onAnnuleren={() => setVerkoopvoorstelTonen(false)}
          />
        )} */}
        {/* {leveropdrachtTonen && (
          <LeveropdrachtDialoog
            open
            cntID={contract.CntID}
            relID={contract.RelID}
            onSuccess={() => {
              setLeveropdrachtTonen(false);
              vernieuwenContracten();
            }}
            onAnnuleren={() => setLeveropdrachtTonen(false)}
          />
        )} */}
        {beeindigingDialoogTonen && (
          <BeeindigenContractDialoog
            open
            relID={contract.RelID}
            cntIDs={[contract.CntID]}
            onSuccess={() => setBeeindigingDialoogTonen(false)}
            onAnnuleren={() => setBeeindigingDialoogTonen(false)}
          />
        )}
        {fiatteringNieuwDialoogTonen && (
          <FiatterenNieuwDialoog
            open
            relID={contract.RelID}
            cntIDs={[contract.CntID]}
            onSuccess={() => setFiatteringNieuwDialoogTonen(false)}
            onAnnuleren={() => setFiatteringNieuwDialoogTonen(false)}
          />
        )}
        {fiatteringOvernameDialoogTonen && (
          <FiatterenOvernameDialoog
            open
            relID={contract.RelID}
            cntIDs={[contract.CntID]}
            onSuccess={() => setFiatteringOvernameDialoogTonen(false)}
            onAnnuleren={() => setFiatteringOvernameDialoogTonen(false)}
          />
        )}
        {wijzigenDialoogTonen && (
          <WijzigenContractDialoog
            open
            relID={contract.RelID}
            cntID={contract.CntID}
            onSuccess={() => {
              setWijzigenDialoogTonen(false);
              vernieuwenContracten();
              klantkaartStore.ophalenRelatie(contract.RelID);
            }}
            onAnnuleren={() => setWijzigenDialoogTonen(false)}
          />
        )}

        {nieuwContractDialoogState !== null && (
          <NieuwContractDialoogV2
            open
            onSuccess={(result) => {
              vernieuwenContracten();
              setNieuwContractDialoogState(null);
            }}
            onAnnuleren={() => setNieuwContractDialoogState(null)}
            wisselcontract_CntID={nieuwContractDialoogState.contractwissel_CntID}
            relID={contract.RelID}
            // prodModID={}
          />
        )}

        {productinfoDialoogState !== null && (
          <ProductinfoDialoog
            open
            id={productinfoDialoogState.id}
            // tabblad={ProductinfoETabblad.Service}
            onSuccess={() => {
              setProductinfoDialoogState(null);
            }}
            onAnnuleren={() => setProductinfoDialoogState(null)}
          />
        )}

        {productWijzigenDialoogState !== null && (
          <WijzigenProductDialoog
            open
            prodID={productWijzigenDialoogState.id}
            onSuccess={() => {
              props.vernieuwenContracten();
              setProductWijzigenDialoogState(null);
            }}
            onAnnuleren={() => {
              setProductWijzigenDialoogState(null);
            }}
          />
        )}

        {servicemeldingDialoogState !== null && (
          <ServiceMeldingDialoog
            open
            prodID={servicemeldingDialoogState.prodID}
            cntID={contract.CntID}
            relID={props.relID}
            // locID={servicemeldingDialoogState.locID}
            onSuccess={async () => {
              props.vernieuwenContracten();
              setServicemeldingDialoogState(null);
            }}
            onAnnuleren={() => setServicemeldingDialoogState(null)}
          />
        )}
        {versturenDocumentatieDialoogState !== null && (
          <VersturenDocumentatieDialoog
            open
            prodID={versturenDocumentatieDialoogState.prodID}
            relID={versturenDocumentatieDialoogState.relID}
            onSuccess={() => {
              props.vernieuwenContracten();
              setVersturenDocumentatieDialoogState(null);
            }}
            onAnnuleren={() => {
              setVersturenDocumentatieDialoogState(null);
            }}
          />
        )}
        {SectieComp !== null && (
          <>
            <SectieComp
              contract={contract}
              producten={producten}
              vorderingen={vorderingen}
              facturen={facturen}
              vernieuwenContracten={() => vernieuwenContracten()}
              relID={props.relID}
            />
          </>
        )}
        {toevoegenTransportopdrachtDialoogState !== null && (
          <NieuweTransportopdrachtDialoog
            open
            relID={props.contract.RelID}
            contractID={props.contract.CntID}
            // soortOpdracht={2}
            leverProduct={null}
            onSuccess={() => {
              {
                setToevoegenTransportopdrachtDialoogState(null);
                props.vernieuwenContracten();
              }
            }}
            onAnnuleren={() => setToevoegenTransportopdrachtDialoogState(null)}
          />
        )}
        {nieuweVorderingDialoogState !== null && (
          <NieuweVorderingDialoog
            open
            relID={props.contract.RelID}
            cntID={nieuweVorderingDialoogState.cntID}
            onSuccess={() => {
              {
                setNieuweVorderingDialoogState(null);
                props.vernieuwenContracten();
              }
            }}
            onAnnuleren={() => setNieuweVorderingDialoogState(null)}
          />
        )}
        {voorraadTypeInfoDialoogState !== null && (
          <VoorraadTypeInfoDialoog
            open
            typeID={voorraadTypeInfoDialoogState.typeID}
            magID={voorraadTypeInfoDialoogState.magID || 4}
            onSuccess={() => setVoorraadTypeInfoDialoogState(null)}
            onAnnuleren={() => setVoorraadTypeInfoDialoogState(null)}
          />
        )}
        {productmodelinfoDialoogState !== null && (
          <ProductmodelInfoDialoog
            open
            onSuccess={() => setProductmodelinfoDialoogState(null)}
            onAnnuleren={() => setProductmodelinfoDialoogState(null)}
            prodModID={productmodelinfoDialoogState.id}
          />
        )}
        {productSelectieDialoogState !== null && (
          <ProductSelectieDialoog
            open
            onSuccess={async (result) => {
              const params = { prodID: result.prodID, cntBasisID: props.contract.basis.CntBasisID };
              const checkData = await api.v2.contract.product.checkKoppelenProduct(params);
              const checkResult = await checkStore.controleren({ checkData });

              if (checkResult.type === EResultType.Annuleren) {
                return;
              }

              if (
                (
                  await checkStore.bevestigen({
                    inhoud: 'Wil je het geselecteerde product koppelen aan het contract?',
                  })
                ).type === EResultType.Annuleren
              ) {
                return;
              }

              await api.v2.contract.product.koppelenProduct(params);
              props.vernieuwenContracten();
              setProductSelectieDialoogState(null);
            }}
            onAnnuleren={() => setProductSelectieDialoogState(null)}
          />
        )}
        {kopierenContractDialoogState !== null && (
          <KopierenContractDialoog
            open
            cntID={kopierenContractDialoogState.cntID}
            onSuccess={() => {
              setKopierenContractDialoogState(null);
              props.vernieuwenContracten();
            }}
            onAnnuleren={() => setKopierenContractDialoogState(null)}
          />
        )}
        {productUitBedrijfDialoogState !== null && (
          <UitBedrijfDialoog
            open
            prodIDs={[productUitBedrijfDialoogState.prodID]}
            onSuccess={() => {
              setProductUitBedrijfDialoogState(null);
              props.vernieuwenContracten();
            }}
            onAnnuleren={() => setProductUitBedrijfDialoogState(null)}
          />
        )}
      </Tegel>
    </Root>
  );
};

export default ContractTegel;
