import React, { useContext, useEffect, useMemo, useState, useCallback } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import useUrlState from '../../../../core/useUrlState';
import api from '../../../../api';
import { addMonths } from 'date-fns';
import _ from 'lodash';
import MenuLayout from '../../../../components/MenuLayout';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import WeergaveKeuze, { IWeergave } from '../../../../components/WeergaveKeuze';
import { IconGrid, IconInkoop } from '../../../../components/Icons';
import { IOphalenContractenResultElementV2 } from '../../../../../../shared/src/api/v2/contract';

interface IProps extends RouteComponentProps {}

export enum EWeergave {
  Regels,
  Gegroepeerd,
}

interface IUrlState {
  weergave: EWeergave;
  typeSelectie: number[];
  modelSelectie: number[];
}

const defaultUrlState: IUrlState = {
  weergave: EWeergave.Gegroepeerd,
  typeSelectie: [],
  modelSelectie: [],
};

export interface IRegel extends IOphalenContractenResultElementV2 {}

const Premiums: React.FC<IProps> = observer((props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);

  const [contracten, setContracten] = useState<IRegel[] | null>(null);

  const ophalenContracten = useCallback(async () => {
    const peildatum = addMonths(new Date(), -2);
    const contracten = (
      await api.v2.contract.ophalenContractenV2({
        filterSchema: {
          filters: [
            { naam: 'IS_BASIS', data: true },
            { naam: 'CONTRACTDATUM_VANAF', data: peildatum },
          ],
        },
        orderSchema: {
          orders: [
            {
              naam: 'INGANGSDATUM',
              richting: 'DESC',
            },
            {
              naam: 'BASISNUMMER',
              richting: 'DESC',
            },
          ],
        },
      })
    ).contracten;

    setContracten(contracten);
  }, []);

  useEffect(() => {
    ophalenContracten();
  }, [ophalenContracten]);

  const weergaven = useMemo<Array<IWeergave<EWeergave>>>(
    () => [
      {
        key: EWeergave.Gegroepeerd,
        naam: 'Gegroepeerd',
        icon: <IconGrid style={{ width: 18, height: 18, fill: Kleur.Grijs }} />,
      },
      {
        key: EWeergave.Regels,
        naam: 'Regels',
        icon: <IconInkoop style={{ width: 16, height: 16, fill: Kleur.Grijs }} />,
      },
    ],
    [],
  );

  return (
    <div className="d-flex">
      <MenuLayout
        menu={
          <div>
            <div className="d-flex align-items-center">
              <WeergaveKeuze
                weergave={urlState.weergave}
                onChange={(x) => setUrlStateSync('weergave', x)}
                weergaven={weergaven}
              />
            </div>
          </div>
        }
        body={<span></span>}
      />
    </div>
  );
});

export default withRouter(Premiums);
