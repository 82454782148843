import React, { useContext, useMemo } from 'react';
import { IProps } from '@tinymce/tinymce-react/lib/cjs/main/ts/components/Editor';
import { IContext, OpdrachtContext } from '../index';
import VeldWeergave from '../../../formulier/VeldWeergave';
import LoadingSpinner from '../../../Gedeeld/LoadingSpinner';
import { format } from 'date-fns';
import GegevensLayout from '../../../layout/GegevensLayout';
import TelefoonComponent from '../../../communicatie/TelefoonComponent';
import EmailComponent from '../../../communicatie/EmailComponent';
import _ from 'lodash';
import { ETransportopdrachtRegelsoort } from '../../../../bedrijfslogica/enums';
import { RootStoreContext } from '../../../../stores/RootStore';
import { Kleur as EKleur } from '../../../../bedrijfslogica/constanten';
import { formatteerAdresV2, formatteerPersoonNaam } from '../../../../helpers';
import { EOpdrachtStatus } from '../../../../paginas/Transport/Opdrachten';
import {
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { IOphalenOpdrachteventsResultElement } from '../../../../../../shared/src/api/v2/transport/opdracht';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import nameOf from '../../../../core/nameOf';
import { IOphalenGekoppeldeTransportopdrachtenResultElement } from '../../../../../../shared/src/api/v2/inkoopfactuur/transport';
import FormatteerBedrag from '../../../MutatieBedrag';
import InkoopfactuurVisualisatie from '../../../entiteitVisualisaties/InkoopfactuurVisualisatie';
import BestandVisualisatie from '../../../entiteitVisualisaties/BestandVisualisatie';
import DownloadKnop from '../../../DownloadKnop';

const OpdrachtTab: React.FC<IProps> = (props) => {
  const { opdracht, regels, premiums, gekoppeldeInkoopfacturen } = useContext<IContext>(
    OpdrachtContext,
  );

  const { instellingStore } = useContext(RootStoreContext);

  const totaalBedrag = useMemo<number>(() => {
    return _.sum(gekoppeldeInkoopfacturen?.map((x) => x.BedragTeFactureren));
  }, [gekoppeldeInkoopfacturen]);

  const kolommenFacturen = useMemo<
    TypedColumn<IOphalenGekoppeldeTransportopdrachtenResultElement>[]
  >(
    () => [
      {
        name: '__Factuur' as any,
        title: 'Fact.nr.',
      },
      {
        name: 'BedragTeFactureren',
        title: 'Bedrag (excl)',
      },
      // {
      //   name: 'Opdrachtnummer',
      //   title: 'Opdrachtnummer',
      // },
      {
        name: 'Zendingreferentie',
        title: 'Zendingreferentie',
      },
      {
        name: 'Zendingtype',
        title: 'Zendingtype',
      },
    ],
    [],
  );

  const kolombreedtesFacturen = useMemo<
    TypedTableColumnWidthInfo<IOphalenGekoppeldeTransportopdrachtenResultElement>[]
  >(
    () => [
      {
        columnName: '__Factuur' as any,
        width: 125,
      },
      {
        columnName: 'BedragTeFactureren',
        width: 100,
      },
      {
        columnName: 'Opdrachtnummer',
        width: 135,
      },
      {
        columnName: 'Zendingreferentie',
        width: 135,
      },
      {
        columnName: 'Zendingtype',
        width: 135,
      },
    ],
    [],
  );

  if (
    opdracht === null ||
    regels === null ||
    premiums === null ||
    gekoppeldeInkoopfacturen === null
  ) {
    return (
      <div className="flex-fill d-flex align-items-center justify-content-center m-5">
        <LoadingSpinner />
      </div>
    );
  }

  const locatie = opdracht.locatie;

  const regelsMetVerdieping = regels.filter((x) => x.Verdieping !== null).map((x) => x.Verdieping);

  const hoogsteVerdiepingOpdracht = opdracht.HoogsteVerdieping;
  const hoogsteVerdiepingRegels =
    regelsMetVerdieping.length !== 0
      ? _.max(regels.filter((x) => x.Verdieping !== null).map((x) => x.Verdieping))
      : null;

  const verdiepingTekst =
    hoogsteVerdiepingOpdracht !== null
      ? hoogsteVerdiepingOpdracht
      : hoogsteVerdiepingRegels !== null
      ? hoogsteVerdiepingRegels
      : 'Niet opgegeven';

  // Bereken het max. aantal af te voeren apparaten zonder kosten
  const aantalAfvoerZonderKosten = regels.filter(
    (x) =>
      x.regelsoort.NaamEnum === ETransportopdrachtRegelsoort.Levering &&
      ((x.type !== null && !x.type.Bulkproduct) || x.product !== null) &&
      x.OmruilID === null,
  ).length;

  const MaxAantalExtraAfvoerProducten = instellingStore.MaxAantalExtraAfvoerProducten;
  const maxAantalZonderKosten = aantalAfvoerZonderKosten + MaxAantalExtraAfvoerProducten;
  const aantalMetKosten =
    opdracht.AantalAfvoer > maxAantalZonderKosten
      ? opdracht.AantalAfvoer - maxAantalZonderKosten
      : 0;

  const indicatieKostenAfvoer = aantalMetKosten > 0 ? ` waarvan ${aantalMetKosten} met kosten` : '';

  return (
    <>
      <div className="d-flex flex-column pl-3 pr-3 pb-3">
        <div className="row mt-3">
          <div className="col-12">
            <div className="row">
              <div className="col-6">
                <VeldWeergave>
                  <GegevensLayout
                    gegevens={[
                      {
                        label: 'Opdrachtnummer',
                        waarde: `${opdracht.Opdrachtnummer}`,
                      },
                      {
                        label: 'Status',
                        waarde: `${opdracht.opdrachtstatus.Naam}`,
                      },
                      {
                        label: 'Verstuurd',
                        waarde:
                          opdracht.DatumVerstuurd !== null ? (
                            format(new Date(opdracht.DatumVerstuurd), 'dd-MM-yyyy HH:mm')
                          ) : (
                            <span className="d-flex">
                              <span>Nog versturen</span>
                              {opdracht.Ophouden && (
                                <span className="ml-2" style={{ color: EKleur.Rood }}>
                                  (Ophouden)
                                </span>
                              )}
                            </span>
                          ),
                      },
                      {
                        label: 'Documentatie verstuurd',
                        waarde: `${opdracht.DocumentatieVerstuurd !== null ? 'Ja' : 'Nee'}`,
                      },
                    ]}
                  />

                  {/* <div className="col-6">
                      {opdracht.Ophouden ? (
                        <span style={{ color: EKleur.Rood }}>Opdracht is opgehouden</span>
                      ) : (
                        <span></span>
                      )}
                    </div> */}
                </VeldWeergave>
              </div>
              <div className="col-6">
                <VeldWeergave>
                  <GegevensLayout
                    gegevens={[
                      {
                        label: 'Bezoekdatum',
                        waarde: `${
                          opdracht.Bezoekdatum !== null
                            ? format(new Date(opdracht.Bezoekdatum), 'dd-MM-yyyy')
                            : 'Nog opgeven'
                        }`,
                      },
                      {
                        label: 'Indicatie AAA',
                        waarde: `${opdracht.IndicatieAAA ? 'Ja' : 'Nee'}`,
                      },
                      {
                        label: 'Tijden',
                        waarde: `${
                          opdracht.BezoekdatumVan !== null
                            ? format(new Date(opdracht.BezoekdatumVan), 'HH:mm')
                            : ''
                        } - ${
                          opdracht.BezoekdatumTot !== null
                            ? format(new Date(opdracht.BezoekdatumTot), 'HH:mm')
                            : ''
                        }`,
                      },
                      {
                        label: 'Voorkeur',
                        waarde: `${
                          opdracht.VoorkeurBezoektijdVan !== null
                            ? opdracht.VoorkeurBezoektijdVan
                            : ''
                        } - ${
                          opdracht.VoorkeurBezoektijdTot !== null
                            ? opdracht.VoorkeurBezoektijdTot
                            : ''
                        }`,
                      },
                    ]}
                  />
                </VeldWeergave>
              </div>
            </div>
          </div>

          <div className="col-12">
            <div className="row">
              <div className="col-6 mt-3">
                <VeldWeergave>
                  <GegevensLayout
                    gegevens={[
                      {
                        label: 'Contactpersoon',
                        waarde:
                          opdracht.persoon !== null ? (
                            <span>
                              {formatteerPersoonNaam({
                                aanhefKort: opdracht.persoon.geslacht.AanhefKort,
                                achternaam: opdracht.persoon.Achternaam,
                                voorletters: opdracht.persoon.Voorletters,
                                voornaam: opdracht.persoon.Voornaam,
                                voorvoegsel: opdracht.persoon.Voorvoegsel,
                              })}
                            </span>
                          ) : (
                            <span></span>
                          ),
                      },
                      {
                        label: 'Telefoon',
                        waarde:
                          opdracht.Telefoon !== null ? (
                            <TelefoonComponent
                              telefoonNummer={opdracht.Telefoon!}
                              options={{ icoonWeergeven: true }}
                            />
                          ) : (
                            <span></span>
                          ),
                      },
                      {
                        label: 'Email',
                        waarde:
                          opdracht.Email !== null ? (
                            <EmailComponent
                              emailAdres={opdracht.Email}
                              options={{ icoonWeergeven: true }}
                              persID={
                                opdracht.persoon !== null ? opdracht.persoon.PersID : undefined
                              }
                            />
                          ) : (
                            <span></span>
                          ),
                      },
                    ]}
                  />
                </VeldWeergave>
              </div>
              <div className="col-6 mt-3">
                <VeldWeergave>
                  <GegevensLayout
                    gegevens={[
                      {
                        label: 'Planninginfo',
                        waarde: opdracht.InfoPlanning ?? 'Niet opgegeven',
                      },
                      null,
                      null,
                    ]}
                  />
                </VeldWeergave>
              </div>
            </div>
          </div>

          <div className="col-12 mt-3">
            <div className="row">
              <div className="col-6">
                <VeldWeergave>
                  <GegevensLayout
                    gegevens={[
                      {
                        label: 'Adres',
                        waarde: `${formatteerAdresV2({
                          bisnummer: locatie.Bisnummer,
                          straatnaam: locatie.Straatnaam,
                          huisnummer: locatie.Huisnummer,
                          postcode: locatie.Postcode,
                          plaatsnaam: locatie.Plaatsnaam,
                          landnaamKort: locatie.LandnaamKort,
                          landnaamEnum: locatie.LandnaamEnum,
                        })}`,
                      },
                      null,
                    ]}
                  />
                </VeldWeergave>
              </div>
              <div className="col-6">
                <VeldWeergave>
                  <GegevensLayout
                    gegevens={[
                      {
                        label: 'Magazijn',
                        waarde: `${opdracht.magazijn.NaamKort}`,
                      },
                      {
                        label: 'Dienst',
                        waarde: `${opdracht.dienst.NaamIdent}`,
                      },
                    ]}
                  />
                </VeldWeergave>
              </div>
            </div>
          </div>

          <div className="col-6 mt-3">
            <VeldWeergave>
              <GegevensLayout
                gegevens={[
                  {
                    label: 'Hoogste verdieping',
                    waarde: `${verdiepingTekst}`,
                  },
                  {
                    label: 'Aantal afvoer oud',
                    waarde: `${
                      opdracht.AantalAfvoer !== 0 ? opdracht.AantalAfvoer : 'Geen'
                    }${indicatieKostenAfvoer}`,
                  },
                  // {
                  //   label: 'Info planning',
                  //   waarde: `${opdracht.InfoPlanning !== null ? opdracht.InfoPlanning : ''}`,
                  // },
                  {
                    label: 'Bezoekinstructies',
                    waarde: `${
                      opdracht.Bezoekinstructies !== null ? opdracht.Bezoekinstructies : ''
                    }`,
                  },
                ]}
              />
            </VeldWeergave>
          </div>

          <div className="col-6 mt-3">
            <VeldWeergave>
              <GegevensLayout
                gegevens={[
                  {
                    label: 'Telefonisch voorbericht',
                    waarde: `${opdracht.TelefonischVoorberichtBezoek ? 'Ja' : 'Nee'}`,
                  },
                  {
                    label: 'Totale transportkosten (excl)',
                    waarde: <FormatteerBedrag bedrag={totaalBedrag} />,
                  },
                  null,
                ]}
              />
            </VeldWeergave>
          </div>

          {opdracht.GedwongenRetour && (
            <div className="col-6 mt-3" style={{ color: EKleur.Rood }}>
              <VeldWeergave>Gedwongen retour</VeldWeergave>
            </div>
          )}

          <div className="col-12 mt-3">
            <VeldWeergave>
              <GegevensLayout
                gegevens={[
                  {
                    label: 'Notities',
                    waarde: opdracht.Notities ?? 'Niet opgegeven',
                  },
                ]}
              />
            </VeldWeergave>
          </div>

          <div className="mt-3 pl-3 pr-3 pt-2 mb-4">
            <VeldWeergave>
              <div className="p-1 font-weight-bold">Gefactureerde transportkosten</div>
              <div className="mt-1" style={{ backgroundColor: EKleur.Wit }}>
                <GridStyleWrapper maxHeight={200} rowAmount={gekoppeldeInkoopfacturen.length}>
                  <Grid rows={gekoppeldeInkoopfacturen} columns={kolommenFacturen}>
                    <DataTypeProvider
                      for={['__Factuur']}
                      formatterComponent={(formatterProps) => {
                        const rij = formatterProps.row as IOphalenGekoppeldeTransportopdrachtenResultElement;
                        return <InkoopfactuurVisualisatie inkFactID={rij.InkFactID!} />;
                      }}
                    />

                    <DataTypeProvider
                      for={[
                        nameOf<IOphalenGekoppeldeTransportopdrachtenResultElement>(
                          'BedragTeFactureren',
                        ),
                      ]}
                      formatterComponent={(formatterProps) => {
                        return <FormatteerBedrag bedrag={formatterProps.value} />;
                      }}
                    />

                    <VirtualTable
                      columnExtensions={kolombreedtesFacturen}
                      messages={{ noData: 'Geen facturen gevonden' }}
                    />
                    <TableColumnResizing defaultColumnWidths={kolombreedtesFacturen} />

                    <TableHeaderRow />
                  </Grid>
                </GridStyleWrapper>
              </div>
            </VeldWeergave>
          </div>

          <div className="col-12 mb-2">
            <VeldWeergave>
              <GegevensLayout
                gegevens={[
                  {
                    label: 'Overdrachtbestand(en)',
                    waarde:
                      opdracht.overdrachten.length !== 0 ? (
                        <>
                          <div
                            style={{ paddingLeft: 35, paddingRight: 20 }}
                            className="d-flex flex-column"
                          >
                            {opdracht.overdrachten.map((overdracht, i) => (
                              <div className="d-flex align-items-center">
                                <span className="flex-fill">
                                  <BestandVisualisatie bestandID={overdracht.bestand.ID} />
                                </span>
                              </div>
                            ))}
                          </div>
                        </>
                      ) : (
                        <span>Geen</span>
                      ),
                  },
                ]}
              />
            </VeldWeergave>
          </div>
        </div>
      </div>
    </>
  );
};

export default OpdrachtTab;
