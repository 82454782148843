import React, { useContext, useMemo } from 'react';
import urlCriteria from '../urlCriteria';
import Communicatie from '../../../components/kaart/entiteiten/Communicatie';
import EntiteitContainer, {
  IUrlEntiteitMapping,
} from '../../../components/kaart/EntiteitContainer';
import Gegevens from './entiteiten/Leverancier';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { defaultEntiteitRoute, LeverancierContext } from '../index';
import Facturen from './entiteiten/Facturen';
import Overzicht from './entiteiten/Overzicht';
// import Documenten from './entiteiten/Bestanden';
import Inkoopdienst from './entiteiten/Inkoopdienst';
import IRemoteData, { ERemoteDataState } from '../../../models/IRemoteData';
import Transportdienst from './entiteiten/Transportdienst';
import Pendeldienst from './entiteiten/Pendeldienst';
import Magazijndienst from './entiteiten/Magazijndienst';
import Incassodienst from './entiteiten/Incassodienst';
import Smsdienst from './entiteiten/Smsdienst';
import Emaildienst from './entiteiten/Emaildienst';
import Servicedienst from './entiteiten/Servicedienst';
import Reviewdienst from './entiteiten/Reviewdienst';
import Contactpersonen from '../../../components/kaart/entiteiten/Contactpersonen';
import Sponsordienst from './entiteiten/Sponsordienst';
import Afdelingen from '../../../components/kaart/entiteiten/Afdelingen';
import FinancieelOverzicht from './entiteiten/Financieel/Overzicht';
import { IOphalenRelatiesResultElementV2 } from '../../../../../shared/src/api/v2/relatie';
import Boekingen from '../../../components/kaart/entiteiten/Boekingen';
import Bestanden from '../../../components/relatie/Bestanden';
import Rekeningen from '../../../components/kaart/entiteiten/Rekeningen';
import Bankzaken from '../../../components/kaart/entiteiten/Bankzaken';
import Transport from '../../../components/kaart/entiteiten/Transport';
import Creditmanagementdienst from './entiteiten/Creditmanagementdienst';
import Leveringsvoorwaarden from '../../../components/kaart/entiteiten/Leveringsvoorwaarden';

interface IProps extends RouteComponentProps {
  relID: number;
  relatie: IRemoteData<IOphalenRelatiesResultElementV2>;
  verversRelatie: () => Promise<void>;
}

const Entiteiten: React.FC<IProps> = (props) => {
  const {
    inkoopdienst,
    transportdienst,
    pendeldienst,
    magazijndienst,
    incassodienst,
    smsdienst,
    emaildienst,
    servicedienst,
    reviewdienst,
    sponsordienst,
  } = useContext(LeverancierContext);

  const urlEntiteitMapping: IUrlEntiteitMapping[] = useMemo(
    () =>
      [
        inkoopdienst.state === ERemoteDataState.Pending || inkoopdienst.data === null
          ? null
          : {
              criteria: urlCriteria.inkoopdienst,
              comp: Inkoopdienst,
            },
        transportdienst.state === ERemoteDataState.Pending || transportdienst.data === null
          ? null
          : {
              criteria: urlCriteria.transportdienst,
              comp: Transportdienst,
            },
        pendeldienst.state === ERemoteDataState.Pending || pendeldienst.data === null
          ? null
          : {
              criteria: urlCriteria.pendeldienst,
              comp: Pendeldienst,
            },
        magazijndienst.state === ERemoteDataState.Pending || magazijndienst.data === null
          ? null
          : {
              criteria: urlCriteria.magazijndienst,
              comp: Magazijndienst,
            },
        incassodienst.state === ERemoteDataState.Pending || incassodienst.data === null
          ? null
          : {
              criteria: urlCriteria.incassodienst,
              comp: Incassodienst,
            },
        smsdienst.state === ERemoteDataState.Pending || smsdienst.data === null
          ? null
          : {
              criteria: urlCriteria.smsdienst,
              comp: Smsdienst,
            },
        emaildienst.state === ERemoteDataState.Pending || emaildienst.data === null
          ? null
          : {
              criteria: urlCriteria.emaildienst,
              comp: Emaildienst,
            },
        servicedienst.state === ERemoteDataState.Pending || servicedienst.data === null
          ? null
          : {
              criteria: urlCriteria.servicedienst,
              comp: Servicedienst,
            },
        reviewdienst.state === ERemoteDataState.Pending || reviewdienst.data === null
          ? null
          : {
              criteria: urlCriteria.reviewdienst,
              comp: Reviewdienst,
            },
        sponsordienst.state === ERemoteDataState.Pending || sponsordienst.data === null
          ? null
          : {
              criteria: urlCriteria.sponsordienst,
              comp: Sponsordienst,
            },
        {
          criteria: urlCriteria.afdelingen,
          comp: Afdelingen,
        },
        {
          criteria: urlCriteria.leveringsvoorwaarden,
          comp: Leveringsvoorwaarden,
        },
        {
          criteria: urlCriteria.financieel,
          comp: FinancieelOverzicht,
        },
        {
          criteria: urlCriteria.overzicht,
          comp: Overzicht,
        },
        {
          criteria: urlCriteria.leverancier,
          comp: Gegevens,
        },
        {
          criteria: urlCriteria.communicatie,
          comp: Communicatie,
        },
        {
          criteria: urlCriteria.transport,
          comp: Transport,
        },
        {
          criteria: urlCriteria.documenten,
          comp: Bestanden,
        },
        {
          criteria: urlCriteria.facturen,
          comp: Facturen,
        },
        {
          criteria: urlCriteria.rekeningen,
          comp: Rekeningen,
        },
        {
          criteria: urlCriteria.boekingen,
          comp: Boekingen,
        },
        {
          criteria: urlCriteria.bankzaken,
          comp: Bankzaken,
        },
        {
          criteria: urlCriteria.contactpersonen,
          comp: Contactpersonen,
        },
        {
          criteria: urlCriteria.creditmanagementdienst,
          comp: Creditmanagementdienst,
        },
      ].filter((x) => x !== null) as IUrlEntiteitMapping[],
    [
      inkoopdienst,
      transportdienst,
      pendeldienst,
      magazijndienst,
      incassodienst,
      smsdienst,
      reviewdienst,
      sponsordienst,
      servicedienst,
      emaildienst,
    ],
  );

  const dynamischeRoutesBepaald = useMemo(
    () =>
      [
        inkoopdienst.state,
        transportdienst.state,
        pendeldienst.state,
        magazijndienst.state,
        incassodienst.state,
        smsdienst.state,
        emaildienst.state,
        servicedienst.state,
        reviewdienst.state,
        sponsordienst.state,
      ].every((x) => x === ERemoteDataState.Ready),
    [
      inkoopdienst.state,
      transportdienst.state,
      pendeldienst.state,
      magazijndienst.state,
      incassodienst.state,
      smsdienst.state,
      emaildienst.state,
      servicedienst.state,
      reviewdienst.state,
      sponsordienst.state,
    ],
  );

  return (
    <EntiteitContainer
      urlEntiteitMapping={urlEntiteitMapping}
      onEntiteitNietGevonden={() => {
        const newUrl = props.match.url + defaultEntiteitRoute;
        props.history.replace(newUrl);
      }}
      nietGevondenIsLaden={!dynamischeRoutesBepaald}
      relID={props.relID}
      relatie={props.relatie}
      verversRelatie={props.verversRelatie}
    />
  );
};

export default withRouter(Entiteiten);
