import api from '../../../index';
import {
  IToevoegenGeneriekResult,
  ISelectieGeneriekParams,
  ILeegGeneriekResult,
  IOphalenGeneriekParams,
} from '../../../../../../shared/src/api/v2/generiek';

import {
  ISponsorItem,
  ISponsorItemCategorie,
} from '../../../../../../shared/src/api/v2/sponsoringv2/item';

const item = {
  // items

  ophalenItems: async (params: IOphalenGeneriekParams): Promise<ISponsorItem[]> => {
    return await api.post('/v2/sponsoringv2/item/ophalen-items', params);
  },
  toevoegenOfWijzigenItem: async (params: ISponsorItem): Promise<IToevoegenGeneriekResult> => {
    return await api.post('/v2/sponsoringv2/item/toevoegen-of-wijzigen-item', params);
  },
  verwijderenItems: async (params: ISelectieGeneriekParams): Promise<ILeegGeneriekResult> => {
    return await api.post('/v2/sponsoringv2/item/verwijderen-items', params);
  },

  // categorieen

  ophalenCategorieen: async (params: IOphalenGeneriekParams): Promise<ISponsorItemCategorie[]> => {
    return await api.post('/v2/sponsoringv2/item/ophalen-categorieen', params);
  },
  toevoegenOfWijzigenCategorie: async (
    params: ISponsorItemCategorie,
  ): Promise<IToevoegenGeneriekResult> => {
    return await api.post('/v2/sponsoringv2/item/toevoegen-of-wijzigen-categorie', params);
  },
  verwijderenCategorieen: async (params: ISelectieGeneriekParams): Promise<ILeegGeneriekResult> => {
    return await api.post('/v2/sponsoringv2/item/verwijderen-categorieen', params);
  },
};

export default item;
