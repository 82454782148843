import React, { useCallback, useContext, useEffect, useMemo, useState, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import IDialoogProps from '../../../../../core/IDialoogProps';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { Field, FieldProps, Formik, FormikActions, FormikErrors, FormikProps } from 'formik';
import Combobox, { IOptie } from '../../../../../components/formulier/Combobox';
import { RootStoreContext } from '../../../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import api from '../../../../../api';
import { EResultType } from '../../../../../stores/CheckStore';
import * as Yup from 'yup';
import DatumKiezer from '../../../../../components/formulier/DatumKiezer';
import nameof from '../../../../../core/nameOf';
import Dialoog from '../../../../../components/dialogen/Dialoog';
import LoadingSpinner from '../../../../../components/Gedeeld/LoadingSpinner';

interface IProps extends IDialoogProps<null> {}

interface IFormikValues {
  jaar: number;
  maand: number;
}

const veldnamen = {
  jaar: 'Jaar',
  maand: 'Maand',
};

const NieuweAfschrijvingDialoog = observer((props: IProps) => {
  const { onAnnuleren, onSuccess, open, dialoogIndex } = props;

  const { checkStore } = useContext(RootStoreContext);
  const formikRef = useRef<Formik<IFormikValues>>(null);

  const jarenOpties = useMemo<IOptie<number>[] | null>(() => {
    const jarenOpties = [2020, 2021, 2022, 2023];

    const opties = jarenOpties.map((e) => ({
      id: e,
      label: e.toString(),
    }));

    return opties;
  }, []);

  const maandOpties = useMemo<IOptie<number>[] | null>(() => {
    const maandOpties = [1, 2, 3, 5, 6, 7, 8, 9, 10, 11, 12];

    const opties = maandOpties.map((e) => ({
      id: e,
      label: e.toString(),
    }));

    return opties;
  }, []);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      if (
        (
          await checkStore.bevestigen({
            inhoud: `Nieuwe afschrijving maken?`,
          })
        ).type === EResultType.Annuleren
      ) {
        actions.setSubmitting(false);
        return;
      }

      const result = await api.v2.boekhouding.activaregister.afschrijvingen.toevoegenAfschrijving({
        jaar: values.jaar,
        maand: values.maand,
      });

      onSuccess(null);
      actions.setSubmitting(false);
    },
    [onSuccess],
  );

  const initieleWaarden = useMemo<IFormikValues>(() => {
    return {
      jaar: 2021,
      maand: 1,
    };
  }, []);

  const validatieschema = Yup.object().shape({
    jaar: Yup.number().required(),
    maand: Yup.number().required(),
  });

  return (
    <Dialoog index={dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Nieuwe afschrijving</ModalTitle>
      </ModalHeader>
      <Formik<IFormikValues>
        isInitialValid
        enableReinitialize
        onSubmit={handleSubmit}
        initialValues={initieleWaarden}
        //isInitialValid
        render={(formikProps: FormikProps<IFormikValues>) => {
          const { submitForm, isSubmitting, isValid } = formikProps;

          return (
            <>
              <ModalBody>
                <div className="form-group">
                  <div className="row">
                    <div className="row d-flex col-12">
                      <div className="col-4">
                        <label>{veldnamen.jaar}</label>
                        {jarenOpties === null ? (
                          <LoadingSpinner />
                        ) : (
                          <Field
                            name={nameof<IFormikValues>('jaar')}
                            render={(fieldProps: FieldProps<IFormikValues>) => {
                              const { field, form } = fieldProps;

                              if (jarenOpties === null) {
                                return;
                              }
                              return (
                                <Combobox
                                  opties={jarenOpties}
                                  geselecteerd={field.value}
                                  legeOptieTonen={false}
                                  onSelectieChange={(id) => form.setFieldValue(field.name, id)}
                                />
                              );
                            }}
                          />
                        )}
                      </div>
                      <div className="col-4">
                        <label>{veldnamen.maand}</label>
                        {maandOpties === null ? (
                          <LoadingSpinner />
                        ) : (
                          <Field
                            name={nameof<IFormikValues>('maand')}
                            render={(fieldProps: FieldProps<IFormikValues>) => {
                              const { field, form } = fieldProps;

                              if (maandOpties === null) {
                                return;
                              }
                              return (
                                <Combobox
                                  opties={maandOpties}
                                  geselecteerd={field.value}
                                  legeOptieTonen={false}
                                  onSelectieChange={(id) => form.setFieldValue(field.name, id)}
                                />
                              );
                            }}
                          />
                        )}
                      </div>
                    </div>

                    <div className="row col-12 mt-3"></div>
                  </div>
                </div>
              </ModalBody>
              <ModalFooter className="d-flex flex-row justify-content-start">
                <button
                  className="btn btn-primary"
                  onClick={submitForm}
                  style={{ width: 100 }}
                  disabled={!isValid || isSubmitting}
                >
                  Ok
                </button>
                <button
                  className="btn btn-secondary"
                  onClick={onAnnuleren}
                  style={{ width: 100 }}
                  disabled={isSubmitting}
                >
                  Annuleren
                </button>
              </ModalFooter>
            </>
          );
        }}
      />
    </Dialoog>
  );
});

export default NieuweAfschrijvingDialoog;
