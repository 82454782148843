import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import IDialoogProps from '../../../core/IDialoogProps';
import { observer } from 'mobx-react-lite';
import Dialoog from '../../dialogen/Dialoog';
import LoadingSpinner from '../../Gedeeld/LoadingSpinner';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import { Field, FieldProps, Formik, FormikActions, FormikErrors, FormikProps } from 'formik';
import { ModalBody, ModalFooter } from 'react-bootstrap';
import nameof from '../../../core/nameOf';
import ContactSelectie, { EType } from '../../formulier/ContactSelectie';
import FormikVeldFout from '../../formulier/FormikVeldFout';
import RadioKnop from '../../formulier/RadioKnop';
import { EKanaal } from '../../../bedrijfslogica/enums';
import api from '../../../api';
import { RootStoreContext } from '../../../stores/RootStore';
import { EResultType } from '../../../stores/CheckStore';
import * as Yup from 'yup';
import VeldWeergave from '../../formulier/VeldWeergave';

interface IProps extends IDialoogProps<{}> {
  trsOpdIDs: number[];
  persID: number | null;
  relID?: number;
}

interface IFormikValues {
  persoonID: number | null;
  kanaal: string;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  persoonID: 'Persoon',
  kanaal: 'Verzendwijze',
};

const VersturenBevestigingDialoog = observer((props: IProps) => {
  const { onAnnuleren, onSuccess, open } = props;
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const { checkStore } = useContext(RootStoreContext);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      const params = {
        trsOpdIDs: props.trsOpdIDs,
        persID: values.persoonID!,
        kanaal: values.kanaal,
      };

      // const checkData = await api.v2.transport.opdracht.check(params);
      // if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
      //   return;
      // }

      //   await api.v2.transport.opdracht.wijzigenOpdrachtregel(params);

      if (
        (
          await checkStore.bevestigen({
            inhoud: `Bevestiging(en) versturen?`,
          })
        ).type === EResultType.Annuleren
      ) {
        actions.setSubmitting(false);
        return;
      }

      await api.v2.transport.bezoek.versturenBezoekbevestigingen(params);

      onSuccess({});
      actions.setSubmitting(false);
    },
    [onSuccess, props],
  );

  const initieleWaarden = useMemo<IFormikValues | null>(() => {
    return {
      persoonID: props.persID,
      kanaal: EKanaal.Email,
    };
  }, [props.persID]);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        persID: Yup.number(),
      }),
    [],
  );

  return (
    <Dialoog index={props.dialoogIndex || 0}>
      {
        <>
          <ModalHeader>
            <ModalTitle>Versturen bevestiging</ModalTitle>
          </ModalHeader>
          <Formik<IFormikValues>
            onSubmit={handleSubmit}
            initialValues={initieleWaarden!}
            isInitialValid
            validationSchema={validationSchema}
            render={(formikProps: FormikProps<IFormikValues>) => {
              const { submitForm, isSubmitting, values, isValid } = formikProps;
              return (
                <>
                  <ModalBody>
                    <div className="form-group">
                      <div className="row">
                        <div className="col-12 mt-3">
                          <label>{veldnamen.persoonID}</label>
                          <Field
                            name={nameof<IFormikValues>('persoonID')}
                            render={(fieldProps: FieldProps<IFormikValues>) => {
                              const { field, form } = fieldProps;

                              return (
                                <ContactSelectie
                                  value={
                                    field.value === null
                                      ? null
                                      : { persID: field.value, orgID: null }
                                  }
                                  onChange={(value) =>
                                    form.setFieldValue(field.name, value?.persoon?.PersID ?? null)
                                  }
                                  alleenVoorRelIDs={
                                    props.relID !== undefined ? [props.relID] : undefined
                                  }
                                  options={{
                                    bijEnkeleDirectVoorselecteren: true,
                                    types: [EType.Persoon],
                                    // voorselectieRelatieContactpersoonType:
                                    //   EVoorselectieRelatieContactpersoonType.Administratief,
                                  }}
                                />
                              );
                            }}
                          />
                        </div>

                        <div className="col-12 d-flex mt-4">
                          <VeldWeergave>Wordt per email verstuurd</VeldWeergave>
                        </div>

                        {/* <div className="col-12 d-flex mt-4">
                          <label>{veldnamen.kanaal}</label>
                          <Field
                            name="kanaal"
                            render={({ field, form }: FieldProps<IFormikValues>) => {
                              return (
                                <div className="d-flex" style={{ width: 250 }}>
                                  <span className="d-flex align-items-center">
                                    <RadioKnop
                                      aangevinkt={field.value === EKanaal.Email}
                                      onAangevinkt={() =>
                                        form.setFieldValue(field.name, EKanaal.Email)
                                      }
                                    />
                                    Email
                                  </span>
                                  <span className="d-flex align-items-center ml-3">
                                    <RadioKnop
                                      aangevinkt={field.value === EKanaal.SMS}
                                      onAangevinkt={() =>
                                        form.setFieldValue(field.name, EKanaal.SMS)
                                      }
                                    />
                                    SMS
                                  </span>
                                </div>
                              );
                            }}
                          />
                        </div> */}
                      </div>
                    </div>
                  </ModalBody>
                  <ModalFooter className="d-flex justify-content-start">
                    <button
                      className="btn btn-primary"
                      onClick={submitForm}
                      disabled={isSubmitting || !isValid}
                      style={{ width: 100 }}
                    >
                      Ok
                    </button>
                    <button
                      className="btn btn-secondary"
                      onClick={onAnnuleren}
                      style={{ width: 100 }}
                    >
                      Annuleren
                    </button>
                  </ModalFooter>
                </>
              );
            }}
          />
        </>
      }
    </Dialoog>
  );
});

export default VersturenBevestigingDialoog;
