import {
  IAspGebruiker,
  IInstellenGebruikersprofielParams,
  IInstellenGebruikersprofielResult,
  IInstellenStatusParams,
  IInstellenStatusResult,
  IOphalenAspLocatiesParams,
  IOphalenAspLocatiesResult,
  IOphalenGebruikersprofielenParams,
  IOphalenGebruikersprofielenResult,
  IWijzigenGebruikersprofielParams,
  IWijzigenGebruikersprofielResult,
  IOphalenGebruikersParams,
  IOphalenGebruikersResult,
  IOphalenAspGebruikerStatussenParams,
  IOphalenAspGebruikerStatussenResult,
  IOphalenOnlineAspGebrIDsParams,
  IOphalenOnlineAspGebrIDsResult,
  IInstellenWachtwoordParams,
  IInstellenWachtwoordResult,
  IWijzigenGebruikerParams,
  IWijzigenGebruikerResult,
} from '../../../../../shared/src/api/v2/aspGebruiker';

import api from '../../index';
import ICheckData from '../../../../../shared/src/models/ICheckData';
import {
  ISysteemStempelParams,
  ISysteemStempelResult,
} from '../../../../../shared/src/api/v2/gebruiker';
import { IOphalenEmailAccountsParams } from '../../../../../shared/src/api/v2/dienst/email/email';
import { IOphalenEmailAccountsResult } from '../../../../../shared/src/api/v2/communicatie/email';

const aspGebruiker = {
  ophalenMijnGebruiker: async (): Promise<IAspGebruiker> => {
    return await api.post('/v2/asp-gebruiker/ophalen-mijn-gebruiker', {});
  },
  ophalenGebruikersprofielen: async (
    params: IOphalenGebruikersprofielenParams,
  ): Promise<IOphalenGebruikersprofielenResult> => {
    return await api.post('/v2/asp-gebruiker/ophalen-gebruikersprofielen', params);
  },
  ophalenGebruikers: async (
    params: IOphalenGebruikersParams,
  ): Promise<IOphalenGebruikersResult> => {
    return await api.post('/v2/asp-gebruiker/ophalen-gebruikers', params);
  },
  instellenStatus: async (params: IInstellenStatusParams): Promise<IInstellenStatusResult> => {
    return await api.post('/v2/asp-gebruiker/instellen-status', params);
  },
  wijzigenGebruikersprofiel: async (
    params: IWijzigenGebruikersprofielParams,
  ): Promise<IWijzigenGebruikersprofielResult> => {
    return await api.post('/v2/asp-gebruiker/wijzigen-gebruikersprofiel', params);
  },
  checkWijzigenGebruikersprofiel: async (
    params: IWijzigenGebruikersprofielParams,
  ): Promise<ICheckData> => {
    return await api.post('/v2/asp-gebruiker/check-wijzigen-gebruikersprofiel', params);
  },
  instellenGebruikersprofiel: async (
    params: IInstellenGebruikersprofielParams,
  ): Promise<IInstellenGebruikersprofielResult> => {
    return await api.post('/v2/asp-gebruiker/instellen-gebruikersprofiel', params);
  },
  ophalenAspLocaties: async (
    params: IOphalenAspLocatiesParams,
  ): Promise<IOphalenAspLocatiesResult> => {
    return await api.post('/v2/asp-gebruiker/ophalen-asp-locaties', params);
  },
  ophalenAspGebruikerStatussen: async (
    params: IOphalenAspGebruikerStatussenParams,
  ): Promise<IOphalenAspGebruikerStatussenResult> => {
    return await api.post('/v2/asp-gebruiker/ophalen-gebruiker-statussen', params);
  },
  ophalenOnlineAspGebrIDs: async (
    params: IOphalenOnlineAspGebrIDsParams,
  ): Promise<IOphalenOnlineAspGebrIDsResult> => {
    return await api.post('/v2/asp-gebruiker/ophalen-online-asp-gebr-ids', params);
  },
  instellenWachtwoord: async (
    params: IInstellenWachtwoordParams,
  ): Promise<IInstellenWachtwoordResult> => {
    return await api.post('/v2/asp-gebruiker/instellen-wachtwoord', params);
  },
  systeemStempel: async (params: ISysteemStempelParams): Promise<ISysteemStempelResult> => {
    return await api.post('/v2/asp-gebruiker/systeem-stempel', params);
  },
  wijzigenGebruiker: async (
    params: IWijzigenGebruikerParams,
  ): Promise<IWijzigenGebruikerResult> => {
    return await api.post('/v2/asp-gebruiker/wijzigen-gebruiker', params);
  },
};

export default aspGebruiker;
