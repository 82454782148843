import React, { useContext, useMemo } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { LeverancierContext } from '../../..';
import Tabblad, { ITabblad } from '../../../../../components/layout/Tabblad';
import Dienst from './Dienst';

export enum ETabblad {
  Pendelopdrachten,
  Dienst,
}

const pathMap: Record<ETabblad, string> = {
  [ETabblad.Pendelopdrachten]: '/pendelopdrachten',
  [ETabblad.Dienst]: '/dienst',
};

interface IProps extends RouteComponentProps {}

const Pendeldienst: React.FC<IProps> = (props) => {
  const { relID } = useContext(LeverancierContext);

  const tabblad = useMemo<ETabblad | null>(() => {
    const matchKey = Object.keys(pathMap).find((key) => {
      const path = pathMap[Number(key) as ETabblad]!;
      return props.location.pathname.endsWith(path);
    });
    if (matchKey === undefined) {
      const path1 = `/leverancier/${relID}/pendeldienst${pathMap[ETabblad.Dienst]}`;
      props.history.push(path1);
      return null;
    }
    return Number(matchKey) as ETabblad;
  }, [props.location.pathname, relID]);

  const tabbladen = useMemo<ITabblad<ETabblad>[]>(
    () => [
      {
        id: ETabblad.Dienst,
        label: 'Dienst',
        content: Dienst,
      },
      {
        id: ETabblad.Pendelopdrachten,
        label: 'Pendelopdrachten',
        content: () => <span></span>,
      },
    ],
    [],
  );

  return (
    <div className="d-flex flex-column flex-fill flex-fill">
      {tabblad !== null && (
        <Tabblad<ETabblad>
          geselecteerd={tabblad}
          onSelectieChange={(tab) => {
            props.history.push(
              `${props.location.pathname.replace(pathMap[tabblad], '')}${pathMap[tab]}`,
            );
          }}
          tabbladen={tabbladen}
        />
      )}
    </div>
  );
};

export default withRouter(Pendeldienst);
