import { IOphalenWerkkostenRegelingenResultElement } from '../../../../../../../shared/src/api/v2/boekhouding/boeking';
import api from '../../../../../api';

export interface IWerkkostenregelingenProvider {
  provide: () => Promise<IOphalenWerkkostenRegelingenResultElement[]>;
}

export const standaardWerkkostenregelingenProvider: IWerkkostenregelingenProvider = {
  provide: async () => {
    const result = await api.v2.boekhouding.boeking.ophalenWerkkostenRegelingen({});
    return result.regelingen;
  },
};
