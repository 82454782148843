import React, { useState, useEffect, useCallback, useMemo, useContext } from 'react';
import IDialoogProps from '../../../../../core/IDialoogProps';
import Dialoog from '../../../../../components/dialogen/Dialoog';
import { ModalFooter, ModalTitle, ModalBody } from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/ModalHeader';
import { Formik, Field, FieldProps, FormikActions, FormikProps, FormikErrors } from 'formik';
import api from '../../../../../api';
import {
  IOphalenActiesResult,
  IWijzigenActieParams,
} from '../../../../../../../shared/src/api/v2/aanbod/tarieven/acties';
import LoadingSpinner from '../../../../../components/Gedeeld/LoadingSpinner';
import nameof from '../../../../../core/nameOf';
import UitlegTooltip from '../../../../../components/formulier/UitlegTooltip';
import RelatieKoppelComponent from '../../../../../components/RelatieKoppelComponent';
import FormikVeldFout from '../../../../../components/formulier/FormikVeldFout';
import VinkVeld from '../../../../../components/formulier/VinkVeld';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../../../stores/RootStore';
import { EResultType } from '../../../../../stores/CheckStore';
import MeertaligTekstveld from '../../../../../components/formulier/MeertaligTekstveld';
import ITaalTekst from '../../../../../../../shared/src/models/talen/ITaalTekst';
import { IOphalenTekstenResultElement } from '../../../../../../../shared/src/api/v2/tekst';

interface IProps extends IDialoogProps {
  tarActieID: number;
}

interface IFormikValues {
  naam: string;
  actienaam: ITaalTekst[];
  actieinfo: ITaalTekst[];
  relID: number | null;
  actief: boolean;
  isHoofdactie: boolean;
  weergevenOpOverzicht: boolean;
}

const WijzigenActieDialoog: React.FC<IProps> = observer((props) => {
  const { checkStore } = useContext(RootStoreContext);
  const [actie, setActie] = useState<IOphalenActiesResult | null>(null);
  const [teksten, setTeksten] = useState<IOphalenTekstenResultElement[] | null>(null);

  const ophalenActie = useCallback(async () => {
    const resultaat = await api.v2.aanbod.tarieven.acties.ophalenActies({
      filterSchema: { filters: [{ naam: 'IDS', data: [props.tarActieID] }] },
    });
    setActie(resultaat);

    const tekstIDs = [
      ...resultaat.acties
        .filter((x) => x.Actienaam_TekstID !== null)
        .map((x) => x.Actienaam_TekstID),
      ...resultaat.acties
        .filter((x) => x.ActieInfo_TekstID !== null)
        .map((x) => x.ActieInfo_TekstID),
      ...resultaat.acties
        .filter((x) => x.ModelInfo_TekstID !== null)
        .map((x) => x.ModelInfo_TekstID),
    ].filter((x) => x !== null) as number[];

    if (tekstIDs.length === 0) {
      setTeksten([]);
      return;
    }

    const tekstenResult = await api.v2.tekst.ophalenTekstenInAlleTalen({
      tekstIDs,
    });
    setTeksten(tekstenResult.teksten);
  }, [props.tarActieID]);

  const initialValues: IFormikValues | null = useMemo(() => {
    if (actie === null || teksten === null) {
      return null;
    }
    const actienaamTekstIDs = actie.acties.map((x) => x.Actienaam_TekstID);
    const actieinfoTekstIDs = actie.acties.map((x) => x.ActieInfo_TekstID);
    const data = actie.acties[0];
    return {
      naam: data.Naam || '',
      actienaam: teksten
        .filter((x) => actienaamTekstIDs.includes(x.TekstID))
        .map((x) => ({
          tekst: x.Tekst || '',
          taalID: x.TaalID,
          toepassen: x.Toepassen,
        })),
      actieinfo: teksten
        .filter((x) => actieinfoTekstIDs.includes(x.TekstID))
        .map((x) => ({
          tekst: x.Tekst || '',
          taalID: x.TaalID,
          toepassen: x.Toepassen,
        })),
      relID: data.RelID,
      actief: data.Actief,
      isHoofdactie: data.IsHoofdactie,
      weergevenOpOverzicht: data.WeergevenOpOverzicht,
    };
  }, [actie, teksten]);

  useEffect(() => {
    ophalenActie();
  }, [props.tarActieID]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);
      const errors = await actions.validateForm(values);
      if (Object.keys(errors).length > 0) {
        actions.setSubmitting(false);
        return;
      }

      const params: IWijzigenActieParams = {
        tarActieID: props.tarActieID,
        naam: values.naam,
        actienaam: values.actienaam,
        actieinfo: values.actieinfo,
        relID: values.relID,
        actief: values.actief,
        isHoofdactie: values.isHoofdactie,
        weergevenOpOverzicht: values.weergevenOpOverzicht,
      };
      const checkData = await api.v2.aanbod.tarieven.acties.checkWijzigenActie(params);
      if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
        actions.setSubmitting(false);
        return;
      }
      await api.v2.aanbod.tarieven.acties.wijzigenActie(params);

      props.onSuccess(null);
      actions.setSubmitting(false);
    },
    [props.onSuccess],
  );

  const validate = useCallback((values: IFormikValues) => {
    const errors: FormikErrors<IFormikValues> = {};
    return errors;
  }, []);

  return (
    <Dialoog index={props.dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Wijzigen actie</ModalTitle>
      </ModalHeader>

      {initialValues === null ? (
        <LoadingSpinner />
      ) : (
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validate={validate}
          render={(formikProps: FormikProps<IFormikValues>) => {
            const { submitForm, isSubmitting } = formikProps;
            return (
              <>
                <ModalBody>
                  <div className="">
                    <div className="row">
                      <div className="col-12">
                        <label>Naam</label>
                        <Field
                          name={nameof<IFormikValues>('naam')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field } = fieldProps;
                            return (
                              <>
                                <input type="text" {...field} className="form-control" />
                                <FormikVeldFout fieldProps={fieldProps} />
                              </>
                            );
                          }}
                        />
                      </div>
                      <div className="col-12 mt-3">
                        <label>Actienaam</label>
                        <Field
                          name={nameof<IFormikValues>('actienaam')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <>
                                <MeertaligTekstveld
                                  waarden={field.value}
                                  onChange={(x) => form.setFieldValue(field.name, x)}
                                />
                                <FormikVeldFout fieldProps={fieldProps} />
                              </>
                            );
                          }}
                        />
                      </div>
                      <div className="col-12 mt-3">
                        <label>Actieinfo</label>
                        <Field
                          name={nameof<IFormikValues>('actieinfo')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <>
                                <MeertaligTekstveld
                                  waarden={field.value}
                                  onChange={(x) => form.setFieldValue(field.name, x)}
                                />
                                <FormikVeldFout fieldProps={fieldProps} />
                              </>
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <span className="d-flex align-items-center">
                          <span>
                            Alleen voor relatie
                            <span className="ml-2">
                              <UitlegTooltip
                                inhoud={
                                  <span>
                                    Door het koppelen van een relatie is het een tariefafspraak.
                                  </span>
                                }
                              />
                            </span>
                          </span>
                          <span className="ml-3">
                            <Field
                              name={nameof<IFormikValues>('relID')}
                              render={(fieldProps: FieldProps<IFormikValues>) => {
                                const { field, form } = fieldProps;
                                return (
                                  <>
                                    <RelatieKoppelComponent
                                      relID={field.value}
                                      onRelIDChange={(relID) =>
                                        form.setFieldValue(field.name, relID)
                                      }
                                    />
                                    <FormikVeldFout fieldProps={fieldProps} />
                                  </>
                                );
                              }}
                            />
                          </span>
                        </span>
                      </div>

                      {/* <div className="col-12 mt-3">
                        <Field
                          name={nameof<IFormikValues>('isHoofdactie')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <>
                                <span className="d-flex align-items-center">
                                  <VinkVeld
                                    aangevinkt={field.value}
                                    onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                  />
                                  <span className="ml-2">Hoofdactie</span>
                                </span>
                                <FormikVeldFout fieldProps={fieldProps} />
                              </>
                            );
                          }}
                        />
                      </div> */}

                      <div className="col-12 mt-3">
                        <Field
                          name={nameof<IFormikValues>('weergevenOpOverzicht')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <>
                                <span className="d-flex align-items-center">
                                  <VinkVeld
                                    aangevinkt={field.value}
                                    onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                  />
                                  <span className="ml-2">Weergeven op productoverzicht</span>
                                </span>
                                <FormikVeldFout fieldProps={fieldProps} />
                              </>
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <Field
                          name={nameof<IFormikValues>('actief')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <>
                                <span className="d-flex align-items-center">
                                  <VinkVeld
                                    aangevinkt={field.value}
                                    onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                  />
                                  <span className="ml-2">Actief</span>
                                </span>
                                <FormikVeldFout fieldProps={fieldProps} />
                              </>
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </ModalBody>
                <ModalFooter className="d-flex flex-row justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    style={{ width: 100 }}
                    disabled={isSubmitting}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={props.onAnnuleren}
                    style={{ width: 100 }}
                    disabled={isSubmitting}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Dialoog>
  );
});

export default WijzigenActieDialoog;
