import React, { useCallback, useMemo } from 'react';
import MultiComboboxV2, { EnkeleProvider, IRepresentatieProps, Provider } from '../MultiComboboxV2';
import api from '../../../api';
import { formatteerPersoonNaam } from '../../../helpers';
import { ASPKolom, EAspKolomBreedteType } from '../../tabel/ASPTabel/types';
import {
  IOphalenPersonenResult,
  IOphalenPersonenResultElementV2,
} from '../../../../../shared/src/api/v2/persoon/persoon';

interface IProps {
  PersID: number | null;
  RelID: number | null;
  onChange: (PersID: number | null) => void;
}

export type Key = 'naam';

export interface IOverlayState {}

const PersoonSelectie = (props: IProps) => {
  const provider = useMemo<Provider<Key, IOphalenPersonenResultElementV2, IOverlayState>>(
    () => async () => {
      const result = await api.v2.persoon.ophalenPersonen({
        filterSchema: {
          filters: [
            {
              naam: 'REL_IDS',
              data: [props.RelID],
            },
          ],
        },
      });

      console.log(props.RelID);
      console.log(result);

      return {
        items: result.personen,
        totaalAantal: 10000,
      };
    },
    [],
  );
  const enkeleProvider = useMemo<EnkeleProvider<number, IOphalenPersonenResultElementV2>>(
    () => async (id: number) => {
      const result = await api.v2.persoon.ophalenPersonen({
        filterSchema: {
          filters: [
            {
              naam: 'IDS',
              data: [id],
            },
          ],
        },
      });

      return result.personen[0];
    },
    [],
  );
  const keyExtractor = useCallback(
    (item: IOphalenPersonenResultElementV2) => (item == null ? -1 : item.PersID),
    [],
  );
  const representatieComponent = useCallback(
    (reprProps: IRepresentatieProps<IOphalenPersonenResultElementV2>) => {
      const x = reprProps.entiteit;

      if (x == null) {
        return <span></span>;
      }

      return (
        <span>
          {formatteerPersoonNaam({
            voornaam: x.Voornaam,
            voorletters: x.Voorletters,
            voorvoegsel: x.Voorvoegsel,
            achternaam: x.Achternaam,
            aanhefKort: x.geslacht.AanhefKort,
          })}
        </span>
      );
    },
    [],
  );
  const kolommen = useMemo<ASPKolom<Key, IOphalenPersonenResultElementV2>[]>(
    () => [
      {
        key: 'naam',
        label: 'Naam',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 350,
        renderer: (x) => {
          return formatteerPersoonNaam({
            voornaam: x.Voornaam,
            voorletters: x.Voorletters,
            voorvoegsel: x.Voorvoegsel,
            achternaam: x.Achternaam,
            aanhefKort: x.geslacht.AanhefKort,
          });
        },
      },
    ],
    [],
  );

  return (
    <MultiComboboxV2
      provider={provider}
      enkeleProvider={enkeleProvider}
      keyExtractor={keyExtractor}
      waarde={props.PersID}
      onChange={props.onChange}
      representatieComponent={representatieComponent}
      kolommen={kolommen}
    />
  );
};

export default PersoonSelectie;
