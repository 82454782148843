import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import IDialoogProps from '../../../../../../../../core/IDialoogProps';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import Dialoog from '../../../../../../../../components/dialogen/Dialoog';
import { Formik, FormikActions, FormikProps } from 'formik';
import ModalBody from 'react-bootstrap/ModalBody';
import LoadingSpinner from '../../../../../../../../components/Gedeeld/LoadingSpinner';
import Formulier from './Formulier';
import api from '../../../../../../../../api';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../../../../../models/IRemoteData';
import { IOphalenProductenResultElementV2 } from '../../../../../../../../../../shared/src/api/v2/product';
import * as Yup from 'yup';
import teksten from '../../../../../../../../bedrijfslogica/teksten';
import { checkValidatieSchema } from '../../../../../../../../core/validatie';
import { IToevoegenServicemeldingParams } from '../../../../../../../../../../shared/src/api/v2/service/melding';
import { EResultType } from '../../../../../../../../stores/CheckStore';
import { RootStoreContext } from '../../../../../../../../stores/RootStore';
import { IToevoegenOpdrachtParams } from '../../../../../../../../../../shared/src/api/v2/service/opdracht';
import { IOphalenLocatiesResultElement } from '../../../../../../../../../../shared/src/api/v2/locatie/locatie';
import { EMutatiebron, EOpdrachtwijze } from '../../../../../../../../bedrijfslogica/enums';

interface IProps extends IDialoogProps<null> {
  prodID?: number;
  relID?: number;
  locIDs?: number[];
  cntID?: number;
}

export interface IFormikValues {
  prodID: number | null;
  locID: number | null;
  melder_PersID: number | null;
  telefoonnummer: string;
  email: string;
  omschrijving: string;
  opdrachtMaken: boolean;
  opdrachtVersturen: boolean;
  garantie: boolean;
  servDienstID: IRemoteData<number | null>;
  spoed: boolean;
  bevestigingNaarMelder: boolean;
}

export const veldnamen: Record<keyof IFormikValues, string> = {
  prodID: 'Product',
  locID: 'Locatie',
  melder_PersID: 'Melder',
  telefoonnummer: 'Telefoon nummer',
  email: 'Email',
  omschrijving: 'Omschrijving klacht',
  opdrachtMaken: 'Reparatieopdracht maken',
  opdrachtVersturen: 'Direct versturen',
  garantie: 'Garantieclaim',
  servDienstID: 'Servicedienst',
  spoed: 'Spoed',
  bevestigingNaarMelder: 'Bevestiging naar melder sturen',
};

export enum ETablad {
  Melding,
  Opdracht,
}

const NieuweMeldingDialoog: React.FC<IProps> = (props) => {
  const { checkStore } = useContext(RootStoreContext);
  const [tabblad, setTabblad] = useState<ETablad>(ETablad.Melding);

  const [product, setProduct] = useState<IRemoteData<IOphalenProductenResultElementV2 | null>>(
    createPendingRemoteData(),
  );

  const [initialValues, setInitialValues] = useState<IFormikValues | null>(null);

  const bepaalProduct = useCallback(async (prodID: number) => {
    const productenResult = await api.v2.product.ophalenProductenV2({
      filterSchema: { filters: [{ naam: 'IDS', data: [prodID] }] },
    });
    return productenResult.producten[0];
  }, []);

  const bepaalMelderPersoon = useCallback(async (relID: number) => {
    // const result = await api.v2.persoon.ophalenPersonen({
    //   filterSchema: {
    //     filters: [
    //       {
    //         naam: 'REL_IDS',
    //         data: [relID],
    //       },
    //     ],
    //   },
    //   orderSchema: { orders: [{ naam: 'ACHTERNAAM', richting: 'ASC' }] },
    // });

    const personenResult = await api.v2.relatie.ophalenContactpersonen({
      filterSchema: {
        filters: [
          {
            naam: 'REL_IDS',
            data: [relID],
          },
        ],
      },
    });

    const persoon =
      personenResult.contactpersonen.length === 1
        ? personenResult.contactpersonen[0]
        : personenResult.contactpersonen.find((x) => x.IsPersoonPrimair) ?? null;

    return persoon;
  }, []);

  const bepaalTelefoonnummerEnEmail = useCallback(async (persID: number) => {
    const personenResult = await api.v2.persoon.ophalenPersonen({
      filterSchema: { filters: [{ naam: 'IDS', data: [persID] }] },
    });
    const result = personenResult.personen[0];

    return {
      telefoonnummer: result.TelefoonMobiel,
      email: result.Email,
    };
  }, []);

  useEffect(() => {
    (async () => {
      const product = props.prodID === undefined ? null : await bepaalProduct(props.prodID);
      setProduct(createReadyRemoteData(product));
    })();
  }, [props.prodID]);

  const ophalenLocatie = useCallback(async (locID: number) => {
    const locatiesResult = await api.v2.locatie.ophalenLocaties({
      filterSchema: { filters: [{ naam: 'IDS', data: [locID] }] },
    });
    const result = locatiesResult.locaties[0];

    return {
      bezoekinstructies: result.Bezoekinstructies,
    };
  }, []);

  const initialiseerInitialValues = useCallback(
    async (product: IOphalenProductenResultElementV2 | null) => {
      const melderPersoon =
        props.relID === undefined ? null : await bepaalMelderPersoon(props.relID);
      const telefoonnummerEnEmail =
        melderPersoon === null ? null : await bepaalTelefoonnummerEnEmail(melderPersoon.PersID);

      const garantie = (() => {
        if (product === null) {
          return false;
        }
        const leeftijd = product.leeftijd;
        return leeftijd !== null ? leeftijd <= 24 : false;
      })();

      setInitialValues({
        prodID: product === null ? null : product.ProdID,
        // Locatie bestaat altijd op dit product
        locID: product === null ? null : product.locatie!.LocID,
        melder_PersID: melderPersoon === null ? null : melderPersoon.PersID,
        telefoonnummer:
          telefoonnummerEnEmail === null ? '' : telefoonnummerEnEmail.telefoonnummer || '',
        email: telefoonnummerEnEmail === null ? '' : telefoonnummerEnEmail.email || '',
        omschrijving: '',
        opdrachtMaken: false,
        opdrachtVersturen: true,
        garantie,
        servDienstID: createPendingRemoteData(),
        spoed: false,
        bevestigingNaarMelder: true,
      });
    },
    [product, props.relID],
  );

  useEffect(() => {
    if (product.state === ERemoteDataState.Pending) {
      return;
    }

    initialiseerInitialValues(product.data);
  }, [initialiseerInitialValues, product]);

  const basisValidationSchema = useMemo(() => {
    const fields: Partial<Record<keyof IFormikValues, any>> = {
      // email: Yup.string()
      //   .required(teksten.formulier.E_VERPLICHT_VELD({ veldnaam: veldnamen.email }))
      //   .email(teksten.formulier.E_EMAIL_VELD({ veldnaam: veldnamen.email })),
      email: Yup.string().email(teksten.formulier.E_EMAIL_VELD({ veldnaam: veldnamen.email })),
      omschrijving: Yup.string().required(
        teksten.formulier.E_VERPLICHT_VELD({ veldnaam: veldnamen.omschrijving }),
      ),
      telefoonnummer: Yup.string().required(
        teksten.formulier.E_VERPLICHT_VELD({ veldnaam: veldnamen.telefoonnummer }),
      ),
      locID: Yup.number().required(
        teksten.formulier.E_VERPLICHT_VELD({ veldnaam: veldnamen.locID }),
      ),
    };

    return Yup.object().shape(fields);
  }, []);

  const opdrachtValidationSchema = useMemo(() => {
    return Yup.object().shape({
      servDienstID: Yup.object().shape({
        data: Yup.number().required(
          teksten.formulier.E_VERPLICHT_VELD({ veldnaam: veldnamen.servDienstID }),
        ),
      }),
    });
  }, []);

  const validate = useCallback(
    (values: IFormikValues) => {
      const schema = values.opdrachtMaken
        ? basisValidationSchema.concat(opdrachtValidationSchema)
        : basisValidationSchema;

      return checkValidatieSchema(schema, values);
    },
    [basisValidationSchema, opdrachtValidationSchema],
  );

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      if (Object.keys(await actions.validateForm(values)).length > 0) {
        return;
      }

      if (
        (
          await checkStore.bevestigen({
            inhoud: values.opdrachtMaken ? (
              values.opdrachtVersturen ? (
                <span>
                  Storingsmelding vastleggen?
                  <br />
                  De rep.opdracht wordt direct verstuurd
                </span>
              ) : (
                <span>
                  Storingsmelding vastleggen?
                  <br />
                  Er wordt een rep.opdracht vastgelegd
                </span>
              )
            ) : (
              <span>Storingsmelding vastleggen?</span>
            ),
          })
        ).type === EResultType.Annuleren
      ) {
        actions.setSubmitting(false);
        return;
      }

      actions.setSubmitting(true);

      // const productResult = producten!.find((x) => x.ProdID === values.productID);
      // const product = productResult !== undefined ? productResult : null;

      const relID = props.relID !== undefined ? props.relID : null;

      const servicemeldingParams: IToevoegenServicemeldingParams = {
        cntID: props.cntID !== undefined ? props.cntID : null,
        locID: values.locID!,
        melddatum: new Date(),
        mutatiebron: EMutatiebron.Intern,
        accID: null,
        omschrijving: values.omschrijving!,
        prodID: values.prodID!,
        productomschrijving: null,
        relID, // props.relID !== undefined ? props.relID : null,
        email: values.email,
        telefoonnummer: values.telefoonnummer,
        persID: values.melder_PersID,
        referentiecode: product.data !== null ? product.data.Referentiecode : null,
        afbeeldingen: [],
        voorvoegsel: null,
      };

      const checkData = await api.v2.service.checkToevoegenServicemelding(servicemeldingParams);
      if (
        (
          await checkStore.controleren({
            checkData,
          })
        ).type === EResultType.Annuleren
      ) {
        return;
      }

      // Melding vastleggen
      const meldingID = (await api.v2.service.toevoegenServicemelding(servicemeldingParams)).ID;

      // Eventueel koppelen van de persoon aan de relatie
      if (relID !== null && values.melder_PersID !== null) {
        await api.v2.relatie.koppelenContactpersoon({ persID: values.melder_PersID, relID });
      }

      // Optioneel een serviceopdracht vastleggen (en optioneel versturen)
      if (values.opdrachtMaken) {
        const bezoekinstructies = (await ophalenLocatie(values.locID!)).bezoekinstructies;

        const opdrachtParams: IToevoegenOpdrachtParams = {
          servMeldID: meldingID,
          servDienstID: values.servDienstID.data!,
          datumVerstuurd: null,
          omschrijving: values.omschrijving,
          garantieclaim: values.garantie,
          spoed: values.spoed,
          persID: values.melder_PersID,
          email: values.email,
          telefoonnummer: values.telefoonnummer,
          bezoekinstructies,
        };
        const result = await api.v2.service.toevoegenOpdracht(opdrachtParams);

        // Direct versturen
        if (values.opdrachtVersturen) {
          await api.v2.service.versturenServiceOpdrachten({
            ids: [result.ID],
          });
        }

        if (values.bevestigingNaarMelder) {
          await api.v2.service.versturenBevestigingenServiceOpdrachtNaarRelatie({
            ids: [result.ID],
          });
        }
      }

      // Als de opdracht in een portal ingevoerd moet worden dan daarvoor bevestiging vragen en erheen gaan
      if (values.opdrachtMaken && values.opdrachtVersturen) {
        const servicedienst = (
          await api.v2.dienst.service.ophalenDiensten({
            filterSchema: { filters: [{ naam: 'IDS', data: [values.servDienstID.data!] }] },
          })
        ).diensten[0];

        if (
          servicedienst.opdrachtwijze!.NaamEnum === EOpdrachtwijze.Portal &&
          servicedienst.PortalURL !== null
        ) {
          if (
            (
              await checkStore.bevestigen({
                inhoud: (
                  <span>
                    Voor de servicedienst {servicedienst.NaamIdent} moet de opdracht in een Portal
                    ingevoerd worden, wil je daar nu heengaan?
                    <br />
                    <br />
                    Let op: het kan zijn dat je nog moet inloggen.
                  </span>
                ),
              })
            ).type === EResultType.Annuleren
          ) {
            return;
          }
          const url = servicedienst.PortalURL;
          window.open(url, '_blank');
        }
      }

      props.onSuccess(null);
      actions.setSubmitting(false);
    },
    [props.onSuccess],
  );

  return (
    <Dialoog index={props.dialoogIndex || 0} modalProps={{ size: 'lg' }}>
      <ModalHeader>
        <ModalTitle>Servicemelding</ModalTitle>
      </ModalHeader>
      {initialValues === null ? (
        <ModalBody>
          <LoadingSpinner />
        </ModalBody>
      ) : (
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validate={validate}
          render={(formikProps: FormikProps<IFormikValues>) => {
            return (
              <Formulier
                formikProps={formikProps}
                onAnnuleren={props.onAnnuleren}
                tabblad={tabblad}
                onTabbladChange={(x) => setTabblad(x)}
                bepaalTelefoonnummerEnEmail={bepaalTelefoonnummerEnEmail}
                product={product.data!}
                bepaalProduct={bepaalProduct}
                onProductChange={setProduct}
                relID={props.relID === undefined ? null : props.relID}
                cntID={props.cntID === undefined ? null : props.cntID}
                prodID={props.prodID === undefined ? null : props.prodID}
                locIDs={props.locIDs === undefined ? null : props.locIDs}
              />
            );
          }}
        />
      )}
    </Dialoog>
  );
};

export default NieuweMeldingDialoog;
