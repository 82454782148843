import {
  IEnqueteVraagIntern,
  IInstellenEnqueteVraagParams,
} from '../../../../../shared/src/api/v2/enquete';
import {
  IOphalenGeneriekParams,
  ISelectieGeneriekParams,
} from '../../../../../shared/src/api/v2/generiek';
import api from '../../index';

const enquete = {
  ophalenVragen: async (params: IOphalenGeneriekParams): Promise<IEnqueteVraagIntern[]> => {
    return await api.post('v2/enquete/ophalen-vragen', params);
  },
  instellenVraag: async (params: IInstellenEnqueteVraagParams): Promise<any> => {
    return await api.post('v2/enquete/instellen-vraag', params);
  },
  verwijderenVragen: async (params: ISelectieGeneriekParams): Promise<any> => {
    return await api.post('v2/enquete/verwijderen-vragen', params);
  },
};

export default enquete;
