import React from 'react';

interface IProps {
  style?: React.CSSProperties;
}

const Chip: React.FC<IProps> = (props) => {
  return (
    <div
      className="d-flex align-items-center justify-content-center"
      style={{
        padding: '0px 5px',
        border: '1px solid #e1e1e1',
        backgroundColor: '#f6f6f6',
        borderRadius: 8,
        minWidth: 'max-content',
        ...props.style,
      }}
    >
      {props.children}
    </div>
  );
};

export default Chip;
