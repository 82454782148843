import React from 'react';
import { Root } from './style';

interface IProps {
  color?: string;
  backgroundColor?: string;
  size?: string;
  imageSrc?: string;
  fontSize?: string | number;
  letterSpacing?: string | number;
}

const Avatar: React.FC<IProps> = (props) => {
  return (
    <Root
      size={props.size}
      color={props.color}
      backgroundColor={props.backgroundColor}
      imageSrc={props.imageSrc}
      fontSize={props.fontSize}
      letterSpacing={props.letterSpacing}
    >
      {props.children}
    </Root>
  );
};

export default Avatar;
