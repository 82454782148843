import { EFilter } from '..';
import { IWeergaveProps } from '../../../../../components/FilterBalkV2';
import React from 'react';

const ZoektermFilter = (p: IWeergaveProps<any>) => {
  return (
    <span className="d-flex align-items-center" style={{ width: 315 }}>
      <span className="mr-3">Zoekterm</span>
      <input
        placeholder="B.v. waterslot of registration"
        className="form-control"
        value={p.state || ''}
        onChange={(ev) => {
          p.onStateChange(ev.target.value);
        }}
        onKeyUp={(ev) => {
          if (ev.key === 'Enter') {
            console.log(p.state);
            p.onDataChange(p.state);
            p.setFilterIsActief(EFilter.Zoekterm, p.state != '');
            p.toepassen();
          }
        }}
      />
    </span>
  );
};

export default ZoektermFilter;
