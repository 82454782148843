import React from 'react';
import { getIconFile } from '../../../../Icons';

interface IProps {
  width: number;
  height: number;
  mediaType: string;
}

const GeenVisualisatie = (props: IProps) => {
  const w = props.width * (2 / 3);
  const h = props.height * (2 / 3);

  const Svg = getIconFile(props.mediaType);

  return (
    <div
      style={{ width: w, height: h }}
      className="d-flex align-items-center justify-content-center"
    >
      <Svg style={{ width: w * (1 / 2), height: h * (1 / 2) }} />
    </div>
  );
};

export default GeenVisualisatie;
