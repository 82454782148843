import React, { useMemo } from 'react';
import Tabblad, { ITabblad } from '../../../components/layout/Tabblad';
import { matchPath, Redirect, Route, RouteComponentProps } from 'react-router-dom';
import { Root } from '../../Inkoop/Aanbod/style';
import Abonnementen from './Abonnementen';
import Productmodellen from './Productmodellen';
import Productsoorten from './Productsoorten';
import Premiums from './Premiums';
import Alternatieven from './Alternatieven';

export enum ETabblad {
  Productmodellen = 1,
  Abonnementen = 2,
  Productsoorten = 3,
  Premiums = 4,
  Alternatieven = 5,
}

const urlTabbladMap: Record<ETabblad, string> = {
  [ETabblad.Productmodellen]: 'productmodellen',
  [ETabblad.Abonnementen]: 'abonnementen',
  [ETabblad.Productsoorten]: 'productsoorten',
  [ETabblad.Premiums]: 'premiums',
  [ETabblad.Alternatieven]: 'alternatieven',
};

interface IProps extends RouteComponentProps {}

const Producten: React.FC<IProps> = (props) => {
  const tabbladen = useMemo<ITabblad<ETabblad>[]>(
    () => [
      {
        id: ETabblad.Productmodellen,
        label: 'Productmodellen',
        content: Productmodellen,
      },
      {
        id: ETabblad.Abonnementen,
        label: 'Abonnementen',
        content: Abonnementen,
      },
      {
        id: ETabblad.Productsoorten,
        label: 'Productsoorten',
        content: Productsoorten,
      },
      {
        id: ETabblad.Premiums,
        label: 'Premiums',
        content: Premiums,
      },
      {
        id: ETabblad.Alternatieven,
        label: 'Alternatieven',
        content: Alternatieven,
      },
    ],
    [],
  );

  const tabblad = useMemo<ETabblad | null>(() => {
    const paths = Object.keys(urlTabbladMap).map((key) => {
      const tabblad = Number(key) as ETabblad;
      const url = urlTabbladMap[tabblad];
      return {
        tabblad,
        path: `${props.match.path}/${url}`,
      };
    });
    for (const pathObj of paths) {
      const match = matchPath(props.location.pathname, pathObj.path);
      if (match !== null) {
        return pathObj.tabblad;
      }
    }

    return null;
  }, [props.location.pathname]);

  return (
    <>
      <Route
        exact
        path={props.match.path}
        component={() => (
          <Redirect to={`${props.match.path}/${urlTabbladMap[ETabblad.Productmodellen]}`} />
        )}
      />
      {tabblad === null ? (
        <Redirect to={`${props.match.path}/${urlTabbladMap[ETabblad.Productmodellen]}`} />
      ) : (
        <Root>
          <Tabblad
            geselecteerd={tabblad}
            onSelectieChange={(identifier) =>
              props.history.push(`${props.match.path}/${urlTabbladMap[identifier]}`)
            }
            tabbladen={tabbladen}
          />
        </Root>
      )}
    </>
  );
};

export default Producten;
