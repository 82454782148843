import React, { useCallback, useContext, useMemo, useState } from 'react';
import DienstFormulier, {
  IFormikValues as IDienstFormulierFormikValues,
  validationSchema as dienstFormulierValidationSchema,
} from '../../../DienstFormulier';
import { LeverancierContext } from '../../../../index';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import api from '../../../../../../api';
import { EResultType } from '../../../../../../stores/CheckStore';
import { RootStoreContext } from '../../../../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import { IWijzigenDienstParams } from '../../../../../../../../shared/src/api/v2/dienst/sms/sms';
import UitklapFormulier from '../../../../../../components/formulier/UitklapFormulier';
import { Kleur } from '../../../../../../bedrijfslogica/constanten';
import HorizontaleScheidingslijn from '../../../../../../components/layout/HorizontaleScheidingslijn';
import nameof from '../../../../../../core/nameOf';
import NumeriekVeld from '../../../../../../components/formulier/NumeriekVeld';
import FormikVeldFout from '../../../../../../components/formulier/FormikVeldFout';
import TijdVeld, { ITijdVeldWaarde } from '../../../../../../components/formulier/TijdVeld';
import * as Yup from 'yup';

interface IFormikValues extends IDienstFormulierFormikValues {}

export const veldnamen: Partial<Record<keyof IFormikValues, string>> = {};

interface IProps {}

const Dienst: React.FC<IProps> = observer((props) => {
  const { checkStore } = useContext(RootStoreContext);
  const context = useContext(LeverancierContext);
  const smsdienst = context.smsdienst.data!;
  const [dienstUitgeklapt, setDienstUitgeklapt] = useState(false);
  const [smsUitgeklapt, setSmsUitgeklapt] = useState(true);

  const initialValues = useMemo<IFormikValues>(() => {
    return {
      actief: smsdienst.Actief,
      naam: smsdienst.Naam || '',
      naamEnum: smsdienst.NaamEnum ?? '',
      opdWijzeID: smsdienst.OpdWijzeID,
      parameters: smsdienst.Parameters || '',
    };
  }, [smsdienst]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);
      const params: IWijzigenDienstParams = {
        relID: context.relID!,
        parameters: values.parameters === '' ? null : values.parameters,
        opdWijzeID: values.opdWijzeID,
        naam: values.naam,
        naamEnum: values.naamEnum,
        actief: values.actief,

        smsDienstID: smsdienst.SmsDienstID,
      };

      const checkData = await api.v2.dienst.sms.checkWijzigenDienst(params);
      if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
        actions.setSubmitting(false);
        return;
      }

      await api.v2.dienst.sms.wijzigenDienst(params);
      context.onVerversenSmsdienstAangevraagd();
      actions.setSubmitting(false);
    },
    [context.relID, smsdienst, context.onVerversenSmsdienstAangevraagd],
  );

  return (
    <div className="d-flex flex-column flex-fill">
      <Formik
        enableReinitialize
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={dienstFormulierValidationSchema}
        render={(formikProps: FormikProps<IFormikValues>) => {
          const { submitForm, isSubmitting, isValid, resetForm, dirty } = formikProps;
          return (
            <>
              <div className="d-flex flex-column flex-fill p-3">
                <UitklapFormulier
                  label="Dienst"
                  uitgeklapt={dienstUitgeklapt}
                  onUitgeklaptChange={setDienstUitgeklapt}
                  formulier={<DienstFormulier />}
                />
                <div className="p-2" />
                <UitklapFormulier
                  label="SMS"
                  uitgeklapt={smsUitgeklapt}
                  onUitgeklaptChange={setSmsUitgeklapt}
                  formulier={<div className="p-3"></div>}
                />
              </div>
              <HorizontaleScheidingslijn color={Kleur.LichtGrijs} />
              <div className="d-flex p-3">
                <button
                  className="btn btn-sm btn-primary d-flex align-items-center justify-content-center"
                  style={{ width: 100 }}
                  disabled={!isValid || isSubmitting}
                  onClick={submitForm}
                >
                  Ok
                </button>
                <button
                  className="btn btn-sm btn-secondary d-flex align-items-center justify-content-center ml-2"
                  style={{ width: 100 }}
                  disabled={!dirty || isSubmitting}
                  onClick={() => resetForm()}
                >
                  Annuleren
                </button>
              </div>
            </>
          );
        }}
      />
    </div>
  );
});

export default Dienst;
