import {
  IOphalenDienstenParams,
  IOphalenDienstenResult,
  IWijzigenDienstParams,
  IWijzigenDienstResult,
  IToevoegenDienstParams,
  IToevoegenDienstResult,
  IVerwijderenDienstenParams,
  IVerwijderenDienstenResult,
} from '../../../../../../shared/src/api/v2/dienst/magazijn';
import api from '../../../index';
import ICheckData from '../../../../../../shared/src/models/ICheckData';

const magazijn = {
  ophalenDiensten: async (params: IOphalenDienstenParams): Promise<IOphalenDienstenResult> => {
    return await api.post('/v2/dienst/magazijn/ophalen-diensten', params);
  },
  checkWijzigenDienst: async (params: IWijzigenDienstParams): Promise<ICheckData> => {
    return await api.post('v2/dienst/magazijn/check-wijzigen-dienst', params);
  },
  wijzigenDienst: async (params: IWijzigenDienstParams): Promise<IWijzigenDienstResult> => {
    return await api.post('v2/dienst/magazijn/wijzigen-dienst', params);
  },
  checkToevoegenDienst: async (params: IToevoegenDienstParams): Promise<ICheckData> => {
    return await api.post('v2/dienst/magazijn/check-toevoegen-dienst', params);
  },
  toevoegenDienst: async (params: IToevoegenDienstParams): Promise<IToevoegenDienstResult> => {
    return await api.post('v2/dienst/magazijn/toevoegen-dienst', params);
  },
  checkVerwijderenDiensten: async (params: IVerwijderenDienstenParams): Promise<ICheckData> => {
    return await api.post('v2/dienst/magazijn/check-verwijderen-diensten', params);
  },
  verwijderenDiensten: async (
    params: IVerwijderenDienstenParams,
  ): Promise<IVerwijderenDienstenResult> => {
    return await api.post('v2/dienst/magazijn/verwijderen-diensten', params);
  },
};

export default magazijn;
