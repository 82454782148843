import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { differenceInDays } from 'date-fns';
import DagScheider from '../DagScheider';
import { Kleur } from '../../../bedrijfslogica/constanten';
import ResizeObserver from 'react-resize-observer';
import { AutoSizer } from 'react-virtualized';
import useBijGewijzigdEffect from '../../../core/useBijGewijzigdEffect';

export enum ERichting {
  Inkomend,
  Uitgaand,
}

// tslint:disable-next-line:interface-name
export interface Bericht {
  getId(): number | string;
  getDatum(): Date;
  getRichting(): ERichting;
}

export interface IBerichtComponentProps<TBericht extends Bericht> {
  bericht: TBericht;
  containerWidth: number;
}

export interface IComponentInterface {
  scrollNaarEinde: () => boolean;
  controleerScroll: () => void;
}

interface IProps<TBericht extends Bericht> {
  berichten: TBericht[];
  berichtComponent: React.ComponentType<IBerichtComponentProps<TBericht>>;
  onComponentInterfaceChange?: (componentInterface: IComponentInterface) => void;
  containerStyle?: React.CSSProperties;
}

const ConversatieSectie = <TBericht extends Bericht>(props: IProps<TBericht>) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [dimensions, setDimensions] = useState<any | null>(null);
  const [volledigGescrolled, setVolledigGescrolled] = useState(true);

  const scrollNaarEinde = useCallback(() => {
    const contentContainer = contentRef.current;
    if (contentContainer === null) {
      return false;
    }
    contentContainer.scrollTop = contentContainer.scrollHeight;
    return true;
  }, [contentRef.current]);

  useBijGewijzigdEffect(() => {
    if (!volledigGescrolled) {
      return;
    }
    scrollNaarEinde();
  }, [props.berichten]);

  useLayoutEffect(() => {
    scrollNaarEinde();
  }, []);

  const handleScroll = useCallback(() => {
    const element = contentRef.current!;
    const atBottom = element.scrollHeight - element.scrollTop === element.clientHeight;
    if (atBottom !== volledigGescrolled) {
      setVolledigGescrolled(atBottom);
    }
  }, [contentRef.current, volledigGescrolled]);

  const componentInterface = useMemo<IComponentInterface>(() => {
    return {
      scrollNaarEinde,
      controleerScroll: () => {
        if (volledigGescrolled) {
          scrollNaarEinde();
        }
      },
    };
  }, [scrollNaarEinde, volledigGescrolled]);
  useEffect(
    () => props.onComponentInterfaceChange && props.onComponentInterfaceChange(componentInterface),
    [componentInterface],
  );

  return (
    <AutoSizer disableHeight className="flex-fill w-100 h-100 d-flex flex-column">
      {(size) => (
        <div
          className="d-flex flex-fill flex-column p-3"
          style={{
            // backgroundColor: Kleur.HeelLichtGrijs,
            backgroundColor: '#e5ddd5',
            ...props.containerStyle,
          }}
        >
          <ResizeObserver onResize={(x) => setDimensions(x)} />
          <div
            ref={contentRef}
            className="d-flex flex-fill flex-column"
            style={{
              overflowY: 'auto',
              height: dimensions === null ? 1 : dimensions.height - 50,
            }}
            onScroll={handleScroll}
          >
            {props.berichten.map((bericht, i) => {
              const volgendBericht = props.berichten[i + 1];

              const toonDagScheider =
                volgendBericht !== undefined &&
                differenceInDays(volgendBericht.getDatum(), bericht.getDatum()) >= 1;

              return (
                <React.Fragment key={bericht.getId()}>
                  <props.berichtComponent bericht={bericht} containerWidth={size.width} />

                  <div style={{ minHeight: 10 }} />
                  {toonDagScheider && <DagScheider datum={bericht.getDatum()} />}
                </React.Fragment>
              );
            })}
          </div>
        </div>
      )}
    </AutoSizer>
  );
};

export default ConversatieSectie;
