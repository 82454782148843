import React, { useMemo } from 'react';
import { IActieProps } from '../index';
import { RouteComponentProps, withRouter } from 'react-router';
import api from '../../../api';
import { genereerUrlStateQueryParam } from '../../../core/useUrlState';
import { IUrlState as KlantkaartServiceUrlState } from '../../Klant/Klantkaart/Entiteiten/entiteiten/Service/Meldingen';
import { IUrlState as ServiceMeldingenUrlState } from '../../Service/Meldingen';
import ServicemeldingVisualisatie from '../../../components/entiteitVisualisaties/ServicemeldingVisualisatie';

interface IData {
  meldID: number;
}

const SERVICE_MELDING: React.VoidFunctionComponent<IActieProps> = (props: IActieProps) => {
  const { controltaak } = props;
  const data = useMemo(() => JSON.parse(controltaak.Data!) as IData, [controltaak.Data]);

  return <ServicemeldingVisualisatie servMeldID={data.meldID} />;

  // return (
  //   <a
  //     href="#"
  //     onClick={async () => {
  //       const result = await api.v2.service.ophalenMeldingen({
  //         filterSchema: {
  //           filters: [
  //             {
  //               naam: 'IDS',
  //               data: [data.meldID],
  //             },
  //           ],
  //         },
  //       });
  //       const melding = result.meldingen[0];
  //       if (melding.RelID !== null) {
  //         // Relatie beschikbaar
  //         const state = genereerUrlStateQueryParam<KlantkaartServiceUrlState>({
  //           selectie: [data.meldID],
  //           nieuwOpdrachtDialoogTonen: false,
  //           serviceMeldingDialoogTonen: false,
  //         });
  //         props.history.push(`/klant/${melding.RelID}/service?state=${state}`);
  //         return;
  //       }
  //       const state = genereerUrlStateQueryParam<ServiceMeldingenUrlState>({
  //         wijzigenID: null,
  //         selectie: [data.meldID],
  //         productselectieDialoogState: null,
  //       });
  //       props.history.push(`/service/meldingen?state=${state}`);
  //     }}
  //   >
  //     Ga naar melding
  //   </a>
  // );
};

export default SERVICE_MELDING;
