import React, { useContext, useEffect, useMemo, useState, useCallback } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import MenuLayout from '../../../components/MenuLayout';
import LoadingSpinner from '../../../components/Gedeeld/LoadingSpinner';
import useUrlState from '../../../core/useUrlState';
import api from '../../../api';
import ContractStatus, {
  ContractStatusIndicatie,
  EContractStatus,
} from '../../Klant/Klantkaart/Entiteiten/entiteiten/Contracten/Overzicht/ContractTegel/ContractStatus';
import { EProductstatus, ERegelstatusTransport } from '../../../bedrijfslogica/enums';
import { Kleur as EKleur } from '../../../bedrijfslogica/constanten';
import {
  DXTableCheckboxComponent,
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../helpers/dxTableGrid';
import {
  Grid,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  DataTypeProvider,
  EditingState,
  IntegratedSorting,
  SelectionState,
  SortingState,
  VirtualTable as VirtualTableBase,
} from '@devexpress/dx-react-grid';
import RelatieVisualisatie from '../../../components/personalia/RelatieVisualisatie';
import _ from 'lodash';
import { IOphalenVerkoopVoorstellenResultElement } from '../../../../../shared/src/api/v2/product/verkoop';
import { addDays, addMonths, format } from 'date-fns';
import nameof from '../../../core/nameOf';
import { IOphalenRelatiesResultElementV2 } from '../../../../../shared/src/api/v2/relatie';
import FormatteerBedrag from '../../../components/MutatieBedrag';
import FilterBalkV2, {
  IFilter,
  IFilterData,
  maakFilterSchema,
} from '../../../components/FilterBalkV2';
import { IFilterSchemaFilter } from '../../../../../shared/src/models/filter';
import { maandenNaarJaren } from '../../../bedrijfslogica/teksten';
import { IconInformatie, IconSend } from '../../../components/Icons';
import { RootStoreContext } from '../../../stores/RootStore';
import { EResultType } from '../../../stores/CheckStore';
import ProductinfoDialoog from '../../../components/product/ProductinfoDialoog';
import nameOf from '../../../core/nameOf';
import { EHoedanigheid } from '../../../components/personalia/RelatieSelectieDialoog';
import VerkoopvoorstelVisualisatie from '../../../components/entiteitVisualisaties/VerkoopvoorstelVisualisatie';
import { Helmet } from 'react-helmet';

interface IProps extends RouteComponentProps {}

interface IUrlState {
  selectie: number[];
  filterData: IFilterData<EFilter>[];
}

enum EFilter {
  ActueleVoorstellen = 'ACTUELE_VOORSTELLEN',
}

const defaultUrlState: IUrlState = {
  selectie: [],
  filterData: [
    {
      naam: EFilter.ActueleVoorstellen,
      data: true,
      isActief: true,
    },
  ],
};

interface IRegel extends IOphalenVerkoopVoorstellenResultElement {
  weergavenaam: string;
  prodID: number | null;
  retourdatum: Date | string | null;
}

export interface IProductinfoDialoogState {
  id: number;
}

const Verkoopvoorstellen: React.FC<IProps> = observer((props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const { checkStore } = useContext(RootStoreContext);

  const [verkoopvoorstellen, setVerkoopvoorstellen] = useState<IRegel[] | null>(null);
  const [filterSchema, setFilterSchema] = useState(maakFilterSchema(urlState.filterData));
  const [
    productinfoDialoogState,
    setProductinfoDialoogState,
  ] = useState<IProductinfoDialoogState | null>(null);

  const ophalenVerkoopvoorstellen = useCallback(async () => {
    const datumVan = addDays(new Date(), -7);
    // const datumVanActueel = addDays(new Date(), -14);

    const filterActueel = filterSchema.filters!.find((x) => x.naam === EFilter.ActueleVoorstellen);

    const verkoopvoorstellenResult = await api.v2.product.verkoop.ophalenVerkoopvoorstellen({
      filterSchema: {
        filters: [
          filterActueel !== undefined
            ? { naam: 'CONTRACT_DATUM_RETOUR_IS_LEEG', data: true }
            : null,
          filterActueel !== undefined
            ? { naam: 'CONTRACT_BEEINDIGD_MET_VERKOOP', data: false }
            : null,
          filterActueel !== undefined ? { naam: 'STATUS', data: 0 } : null,
          filterActueel !== undefined ? { naam: 'GELDIGTOT_VAN', data: datumVan } : null,
        ].filter((x) => x !== null) as IFilterSchemaFilter[],
      },
      orderSchema: {
        orders: [
          {
            naam: 'GELDIGVAN',
            richting: 'DESC',
          },
          {
            naam: 'DATUM_HERINNERING',
            richting: 'DESC',
          },
        ],
      },
      paginatie: {
        aantal: 100,
        index: 0,
      },
    });
    // Relatiegegevens
    const relIDs: number[] = _.uniq(verkoopvoorstellenResult.voorstellen.map((x) => x.RelID));
    const relatiesResult = await api.v2.relatie.ophalenRelaties({
      filterSchema: { filters: [{ naam: 'IDS', data: relIDs }] },
    });

    // Productgegevens (status Verhuur)
    const prodIDs: number[] = _.uniq(verkoopvoorstellenResult.voorstellen.map((x) => x.ProdID));
    const productenResult = await api.v2.product.ophalenProductenV2({
      filterSchema: {
        filters: [
          { naam: 'IDS', data: prodIDs },
          // { naam: 'PROD_STAT_NAAM_ENUMS', data: [EProductstatus.Verhuur] },
        ],
      },
    });

    // Retouropdrachten
    const retourOpdrachtregelsResult = await api.v2.transport.opdracht.ophalenOpdrachtregelsV2({
      filterSchema: {
        filters: [
          { naam: 'PROD_IDS', data: prodIDs },
          { naam: 'REGELSTATUSSEN', data: [0] },
        ],
      },
    });

    const trsOpdIDs = retourOpdrachtregelsResult.regels.map((x) => x.TrsOpdID);

    const retouropdrachtenResult = (
      await api.v2.transport.opdracht.ophalenOpdrachtenV2({
        filterSchema: {
          filters: [{ naam: 'IDS', data: trsOpdIDs }],
        },
      })
    ).opdrachten;

    const retourOpdrachtregels = retourOpdrachtregelsResult.regels.map((x) => {
      const opdracht = retouropdrachtenResult.find((o) => o.TrsOpdID === x.TrsOpdID)!;
      return { ...x, opdracht };
    });

    const verkoopvoorstellen = verkoopvoorstellenResult.voorstellen.map((voorstel) => {
      const relatie: IOphalenRelatiesResultElementV2 = relatiesResult.relaties.find(
        (x) => x.RelID === voorstel.RelID,
      )!;
      const productVoorVoorstel = productenResult.producten.find(
        (x) => x.ProdID === voorstel.ProdID && x.productstatus.NaamEnum === EProductstatus.Verhuur,
      );
      const product = productVoorVoorstel !== undefined ? productVoorVoorstel : null;
      const prodID = product !== null ? product.ProdID : null;

      const retouropdrachtenVoorVoorstel = retourOpdrachtregels.filter(
        (x) => x.product !== null && x.product.ProdID === voorstel.ProdID,
      );
      const retourOpdracht =
        retouropdrachtenVoorVoorstel.length === 1 ? retouropdrachtenVoorVoorstel[0] : null;
      const retourdatum = retourOpdracht !== null ? retourOpdracht.opdracht.Bezoekdatum : null;

      return { ...voorstel, weergavenaam: relatie.weergavenaam, prodID, retourdatum };
    });

    const verkoopvoorstellenGesorteerd = _.orderBy(
      verkoopvoorstellen,
      ['GeldigVan', 'weergavenaam'],
      ['desc', 'asc'],
    );

    setVerkoopvoorstellen(verkoopvoorstellenGesorteerd);
  }, [filterSchema]);

  useEffect(() => {
    ophalenVerkoopvoorstellen();
  }, [ophalenVerkoopvoorstellen]);

  const handle = useCallback(() => {}, []);

  const keyExtractor = useCallback((row: IRegel) => row.ID, []);

  const kolommen = useMemo<TypedColumn<IRegel>[]>(
    () => [
      {
        name: 'GeldigVan',
        title: 'Datum',
      },
      {
        name: 'GeldigTot',
        title: 'Geldig tot',
      },
      {
        name: 'RelID',
        title: 'Relatie',
        getCellValue: (x) => x.weergavenaam,
      },
      {
        name: '__link' as any,
        title: 'Product',
      },
      {
        name: 'Bedrag',
        title: 'Bedrag',
      },
      {
        name: 'KostenVtb',
        title: 'Kosten VTB',
      },
      {
        name: '__contractnummer' as any,
        title: 'Cnt.nr.',
      },
      {
        name: '__productstatus' as any,
        title: 'Prod.status',
      },
      {
        name: 'ProductsoortnaamKort',
        title: 'Cat.',
      },
      {
        name: 'leeftijd',
        title: 'Lft.',
      },
      {
        name: '__productInfo' as any,
        title: ' ',
      },
      {
        name: 'Status',
        title: 'Status',
      },
      {
        name: '__retourdatum' as any,
        title: 'Ret.opdracht',
        getCellValue: (x: any) => {
          return x.retourdatum !== null ? x.retourdatum : null;
        },
      },
      // {
      //   name: 'NietOpvolgen',
      //   title: 'Opvolgen',
      // },
      {
        name: '__herinnering' as any,
        title: 'Her. verstuurd',
        getCellValue: (x) => {
          return x.DatumHerinnering;
        },
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRegel>[]>(
    () => [
      {
        columnName: 'GeldigVan',
        width: 115,
      },
      {
        columnName: 'RelID',
        width: 240,
      },
      {
        columnName: '__link' as any,
        width: 175,
      },
      {
        columnName: 'GeldigTot',
        width: 115,
      },
      {
        columnName: 'Bedrag',
        width: 115,
      },
      {
        columnName: 'KostenVtb',
        width: 115,
      },
      {
        columnName: '__contractnummer' as any,
        width: 125,
      },
      {
        columnName: '__productstatus' as any,
        width: 125,
      },
      {
        columnName: 'ProductsoortnaamKort',
        width: 80,
      },
      {
        columnName: 'leeftijd',
        width: 80,
      },
      {
        columnName: '__productInfo' as any,
        width: 80,
      },
      {
        columnName: 'Status',
        width: 100,
      },
      {
        columnName: '__retourdatum' as any,
        width: 125,
      },
      {
        columnName: '__herinnering' as any,
        width: 135,
      },
      // {
      //   columnName: 'NietOpvolgen',
      //   width: 100,
      // },
    ],
    [],
  );

  const kolomExtensies: VirtualTableBase.ColumnExtension[] = useMemo(() => {
    return [
      // {
      //   columnName: `Bedrag`,
      //   align: 'right',
      // },
      // {
      //   columnName: `KostenVtb`,
      //   align: 'right',
      // },
    ];
  }, []);

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.ActueleVoorstellen,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return (
            <span className="d-flex align-items-center">
              <span>Alleen actuele voorstellen</span>
            </span>
          );
        },
      },
    ],
    [],
  );

  return (
    <>
      <Helmet>
        <title>Verkoopvoorstellen</title>
      </Helmet>
      <MenuLayout
        menu={
          <>
            <div className="d-flex align-items-center">
              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-2"
                style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                disabled={urlState.selectie.length === 0}
                onClick={async () => {
                  const checkData = await api.v2.product.verkoop.checkVersturenHerinneringen({
                    verkVrstIDs: urlState.selectie,
                  });

                  if (
                    (await checkStore.controleren({ checkData })).type === EResultType.Annuleren
                  ) {
                    return;
                  }
                  if (
                    (
                      await checkStore.bevestigen({
                        inhoud: 'Herinneringen versturen voor de geselecteerde voorstellen?',
                      })
                    ).type === EResultType.Annuleren
                  ) {
                    return;
                  }

                  await api.v2.product.verkoop.versturenHerinneringen({
                    verkVrstIDs: urlState.selectie,
                  });

                  await ophalenVerkoopvoorstellen();
                }}
              >
                <IconSend style={{ width: 16, height: 16, fill: EKleur.Grijs }} />
                <span className="ml-2">Herinnering versturen</span>
              </button>

              <div className="d-flex flex-fill ml-3">
                <FilterBalkV2
                  filters={filters}
                  filterData={urlState.filterData}
                  onFilterDataChange={(x) => setUrlStateSync('filterData', x)}
                  onFilterSchemaChange={(x) => setFilterSchema(x)}
                />
              </div>
            </div>
          </>
        }
        body={
          verkoopvoorstellen === null ? (
            <div className="flex-fill d-flex align-items-center justify-content-center">
              <LoadingSpinner />
            </div>
          ) : (
            <GridStyleWrapper height={'calc(100vh - 150px)'}>
              <Grid rows={verkoopvoorstellen} columns={kolommen} getRowId={keyExtractor}>
                <DataTypeProvider
                  for={[nameof('RelID')]}
                  formatterComponent={(formatterProps) => {
                    const row = formatterProps.row as IRegel;
                    if (row.RelID === null) {
                      return <span />;
                    }
                    return (
                      <RelatieVisualisatie
                        relID={row.RelID}
                        relatieLinkBuilder={(hoedanigheid, relID) =>
                          `/${
                            hoedanigheid === EHoedanigheid.Klant ? 'klant' : 'leverancier'
                          }/${relID}/contracten/verkoop`
                        }
                      />
                    );
                  }}
                />

                <DataTypeProvider
                  for={[nameof('__link')]}
                  formatterComponent={(formatterProps) => {
                    const rij = formatterProps.row as IRegel;
                    return <VerkoopvoorstelVisualisatie verkVrstID={rij.ID} />;
                  }}
                />

                <DataTypeProvider
                  for={[nameof('GeldigVan')]}
                  formatterComponent={(props) => {
                    const datum = format(new Date(props.value), 'dd-MM-yyyy');
                    return <span>{datum}</span>;
                  }}
                />

                <DataTypeProvider
                  for={[nameof('GeldigTot')]}
                  formatterComponent={(props) => {
                    const datum = new Date(props.value);

                    return (
                      <span style={{ color: datum < new Date() ? EKleur.Rood : undefined }}>
                        {format(datum, 'dd-MM-yyyy')}
                      </span>
                    );
                  }}
                />

                <DataTypeProvider
                  for={[nameof('__productstatus')]}
                  formatterComponent={(props) => {
                    const row = props.row as IRegel;
                    return <span>{row.prodID !== null ? 'Verhuur' : ''}</span>;
                  }}
                />

                <DataTypeProvider
                  for={['__contractnummer']}
                  formatterComponent={(props) => {
                    const rij: IRegel = props.row;
                    if (rij.contract === null) {
                      return <span></span>;
                    }
                    return (
                      <div className="d-flex  align-items-center">
                        <ContractStatus
                          status={rij.contract.StatusnaamEnum as EContractStatus}
                          naam={rij.contract.Statusnaam}
                        />

                        {/* <ContractStatusIndicatie
                            status={rij.contract.StatusnaamEnum as EContractStatus}
                          />

                          <div className="ml-2 ">
                            {rij.contract.Basisnummer + '.' + rij.contract.Volgnummer}
                          </div> */}
                      </div>
                    );
                  }}
                />

                <DataTypeProvider
                  for={[nameof<IRegel>('Bedrag')]}
                  formatterComponent={(props) => (
                    <span>
                      <FormatteerBedrag bedrag={props.value} />
                    </span>
                  )}
                />

                <DataTypeProvider
                  for={[nameof<IRegel>('KostenVtb')]}
                  formatterComponent={(props) => {
                    if (props.value === null) {
                      return <span></span>;
                    }
                    return (
                      <span>
                        <FormatteerBedrag bedrag={props.value} />
                      </span>
                    );
                  }}
                />

                <DataTypeProvider
                  for={[nameof<IRegel>('Status')]}
                  formatterComponent={(props) => {
                    if (props.row.VerwerktOp !== null) {
                      return <span>Verwerkt</span>;
                    }

                    if (props.value === 1) {
                      return <span style={{ color: EKleur.Groen }}>Geaccepteerd</span>;
                    }
                    if (props.value === 2) {
                      return <span style={{ color: EKleur.Rood }}>Afgewezen</span>;
                    }
                    return <span>Onbekend</span>;
                  }}
                />

                <DataTypeProvider
                  for={['__retourdatum']}
                  formatterComponent={(props) => {
                    const rij: IRegel = props.row;
                    if (rij.Retourdatum === null) {
                      return <span></span>;
                    }
                    const datum = new Date(rij.Retourdatum);
                    return (
                      <span style={{ color: datum < new Date() ? EKleur.Rood : undefined }}>
                        {format(datum, 'dd-MM-yyyy')}
                      </span>
                    );
                  }}
                />

                <DataTypeProvider
                  for={['__herinnering']}
                  formatterComponent={(props) => {
                    const rij: IRegel = props.row;

                    if (rij.NietOpvolgen) {
                      return <span style={{ color: EKleur.Rood }}>Niet opvolgen</span>;
                    }

                    const datum =
                      rij.DatumHerinnering !== null
                        ? format(new Date(rij.DatumHerinnering), 'dd-MM-yyyy HH:mm')
                        : null;
                    return <span>{datum}</span>;
                  }}
                />

                <DataTypeProvider
                  for={['leeftijd']}
                  formatterComponent={(props) => {
                    return <span>{props.value !== null ? maandenNaarJaren(props.value) : ''}</span>;
                  }}
                />

                <DataTypeProvider
                  for={['__productInfo']}
                  formatterComponent={(formatterProps) => {
                    const rij: IRegel = formatterProps.row;

                    if (rij.prodID === null) {
                      return <span></span>;
                    }

                    return (
                      <a
                        href="#"
                        // style={{ color: Kleur.DonkerGrijs }}
                        onClick={() => {
                          setProductinfoDialoogState({ id: rij.prodID! });
                        }}
                      >
                        <IconInformatie style={{ width: 15, height: 15, fill: EKleur.Blauw }} />
                      </a>
                    );
                  }}
                />

                <DataTypeProvider
                  for={[nameOf<IRegel>('NietOpvolgen')]}
                  formatterComponent={(props) => {
                    return <span>{props.value ? 'Nee' : ''}</span>;
                  }}
                />

                <SortingState defaultSorting={[]} />
                <IntegratedSorting />

                <VirtualTable columnExtensions={kolomExtensies} />
                <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
                <TableHeaderRow showSortingControls />

                <SelectionState
                  selection={urlState.selectie}
                  onSelectionChange={(value) => setUrlStateSync('selectie', value as number[])}
                />
                <TableSelection cellComponent={DXTableCheckboxComponent} />

                {/* <EditingState
                    onCommitChanges={(changes) => {
                      if (changes.deleted === undefined) {
                        return;
                      }
                      const deleted = changes.deleted;
                      const id = deleted[deleted.length - 1];
                      alert(id);
                    }}
                    onEditingRowIdsChange={(rowIds) => {
                      const id = rowIds[rowIds.length - 1] as number;
                      // props.onWijzigenOpdrachtDialoogStateChange({ trsOpdID: id });
                      // alert(id);
                    }}
                  />
                  <TableEditColumn
                    width={35}
                    showEditCommand
                    cellComponent={DXTableEditColumnCellComponent}
                    commandComponent={DXTableEditColumnCommandComponent}
                  /> */}
              </Grid>
            </GridStyleWrapper>
          )
        }
      />
      {productinfoDialoogState !== null && (
        <ProductinfoDialoog
          open
          id={productinfoDialoogState.id}
          onSuccess={() => {
            setProductinfoDialoogState(null);
          }}
          onAnnuleren={() => setProductinfoDialoogState(null)}
        />
      )}
    </>
  );
});

export default Verkoopvoorstellen;
