import { RouteComponentProps, withRouter } from 'react-router';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import useUrlState, { genereerUrlStateQueryParam } from '../../core/useUrlState';
import Tabblad, { ITabblad } from '../../components/layout/Tabblad';
import { IOphalenMagazijnenResultElement } from '../../../../shared/src/api/v2/magazijn';
import { IOphalenProductsoortenResultElement } from '../../../../shared/src/api/v2/product/soort';
import api from '../../api';
import LoadingSpinner from '../../components/Gedeeld/LoadingSpinner';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../stores/RootStore';
import Nieuw from './NieuwTab';
import Gebruikt from './GebruiktTab';
import Mutatie from '../../components/magazijn/Mutaties';

export enum ETabblad {
  Nieuw = 1,
  Gebruikt = 2,
  Mutaties = 3,
}

interface IUrlState {
  magID: number | null;
  prodSrtID: number | null;
  merkID: number | null;
  alleenOpenbaar: boolean;
}

interface IProps extends RouteComponentProps {}

export interface ITabbladProps {
  magID: number;
  onMagIDChange: (value: number) => void;
  prodSrtID: number | null;
  onProdSrtIDChange: (value: number) => void;
  merkID: number | null;
  onMerkIDChange: (value: number) => void;
  alleenOpenbaar: boolean;
  onAlleenOpenbaarChange: (value: boolean) => void;
  magazijnen: IOphalenMagazijnenResultElement[];
  productsoorten: IOphalenProductsoortenResultElement[];
}

const pathMap: Record<ETabblad, string> = {
  [ETabblad.Nieuw]: '/ingepakt',
  [ETabblad.Gebruikt]: '/uitgepakt',
  [ETabblad.Mutaties]: '/mutaties',
};

const Voorraad: React.FC<IProps> = observer((props) => {
  const { instellingStore } = useContext(RootStoreContext);

  const defaultUrlState = useMemo<IUrlState>(
    () => ({
      magID: instellingStore.Voorraad_MagID,
      prodSrtID: instellingStore.ProdSrtID,
      merkID: null,
      alleenOpenbaar: true,
    }),
    [instellingStore.Voorraad_MagID],
  );

  const [urlState, setUrlState, setUrlStateSync] = useUrlState(
    props,
    defaultUrlState,
    'voorraadState',
  );
  useEffect(() => {
    if (urlState.magID !== null) {
      return;
    }
    setUrlStateSync('magID', instellingStore.Voorraad_MagID);
  }, [urlState.magID, setUrlStateSync, instellingStore.Voorraad_MagID]);

  const [magazijnen, setMagazijnen] = useState<IOphalenMagazijnenResultElement[] | null>(null);
  const [productsoorten, setProductsoorten] = useState<
    IOphalenProductsoortenResultElement[] | null
  >(null);

  const tabbladen = useMemo<ITabblad<ETabblad>[]>(
    () => [
      {
        id: ETabblad.Nieuw,
        label: 'Ingepakt',
        content: Nieuw,
      },
      {
        id: ETabblad.Gebruikt,
        label: 'Uitgepakt',
        content: Gebruikt,
      },
      {
        id: ETabblad.Mutaties,
        label: 'Mutaties',
        content: Mutatie,
      },
    ],
    [],
  );

  useEffect(() => {
    (async () => {
      const [magazijnenResult, productsoortenResult] = await Promise.all([
        api.v2.magazijn.ophalenMagazijnen({
          filterSchema: { filters: [{ naam: 'IS_VOORRAAD', data: true }] },
        }),
        api.v2.product.soort.ophalenProductsoorten({ filterSchema: { filters: [] } }),
      ]);

      setMagazijnen(magazijnenResult.magazijnen);
      // @ts-ignore
      setProductsoorten(productsoortenResult);
    })();
  }, []);

  const tabblad = useMemo<ETabblad | null>(() => {
    const matchKey = Object.keys(pathMap).find((key) => {
      const path = pathMap[Number(key) as ETabblad]!;
      return props.location.pathname.endsWith(path);
    });
    if (matchKey === undefined) {
      props.history.push(`${props.match.path}${pathMap[ETabblad.Nieuw]}`);
      return null;
    }
    return Number(matchKey) as ETabblad;
  }, [props.location.pathname]);

  return (
    <div className="d-flex flex-column flex-fill flex-fill">
      {magazijnen === null || productsoorten === null ? (
        <div className="d-flex flex-fill align-items-center justify-content-center">
          <LoadingSpinner />
        </div>
      ) : (
        tabblad !== null && (
          <Tabblad<ETabblad>
            geselecteerd={tabblad}
            onSelectieChange={(tab) => {
              props.history.push(
                `${props.location.pathname.replace(pathMap[tabblad], '')}${
                  pathMap[tab]
                }?voorraadState=${genereerUrlStateQueryParam(urlState)}`,
              );
            }}
            tabbladen={tabbladen}
            options={{
              tabbladComponentProps: {
                magazijnen,
                productsoorten,
                magID: urlState.magID,
                prodSrtID: urlState.prodSrtID,
                onMagIDChange: (x) => setUrlStateSync('magID', x),
                onProdSrtIDChange: (x) => setUrlStateSync('prodSrtID', x),
                merkID: urlState.merkID,
                onMerkIDChange: (x) => setUrlStateSync('merkID', x),
                alleenOpenbaar: urlState.alleenOpenbaar,
                onAlleenOpenbaarChange: (x) => setUrlStateSync('alleenOpenbaar', x),
              } as ITabbladProps,
            }}
          />
        )
      )}
    </div>
  );
});

export default withRouter(Voorraad);
