import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import { RootStoreContext } from '../../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import { IOphalenEventtypenResultElement } from '../../../../../../shared/src/api/v2/appEvent';
import api from '../../../../api';
import MenuLayout from '../../../../components/MenuLayout';
import {
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import {
  Grid,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  DataTypeProvider,
  EditingState,
  IntegratedSorting,
  SortingState,
} from '@devexpress/dx-react-grid';
import nameOf from '../../../../core/nameOf';
import WijzigenDialoog from './WijzigenDialoog';
import FilterBalkV2, {
  IFilter,
  IFilterData,
  IWeergaveProps,
  maakFilterSchema,
} from '../../../../components/FilterBalkV2';
import Combobox from '../../../../components/formulier/Combobox';
import { IFilterSchema } from '../../../../../../shared/src/models/filter';
import useUrlState from '../../../../core/useUrlState';

interface IProps extends RouteComponentProps {}

export interface IRegel extends IOphalenEventtypenResultElement {}

export enum EFilter {
  Notificatie = 'NOTIFICATIE',
}

export interface IWijzigenDialoogState {
  id: number;
}

export interface IUrlState {
  filterData: IFilterData<EFilter>[];
}

export const defaultUrlState: IUrlState = {
  filterData: [
    {
      naam: EFilter.Notificatie,
      data: 1,
      isActief: false,
    },
  ],
};

const Eventtypen: React.FC<IProps> = observer((props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);

  const [eventtypen, setEventtypen] = useState<IOphalenEventtypenResultElement[] | null>(null);

  const [wijzigenDialoogState, setWijzigenDialoogState] = useState<IWijzigenDialoogState | null>(
    null,
  );

  const [filterSchema, setFilterSchema] = useState<IFilterSchema>(
    useMemo(() => maakFilterSchema(urlState.filterData), []),
  );

  const ophalenEventtypen = useCallback(async () => {
    const result = await api.v2.appEvent.ophalenEventtypen({
      filterSchema: {
        filters: [...filterSchema.filters!],
      },
      orderSchema: {
        orders: [
          {
            naam: 'NAAM',
            richting: 'ASC',
          },
        ],
      },
    });

    setEventtypen(result.eventtypen);
  }, [filterSchema.filters!]);

  useEffect(() => {
    ophalenEventtypen();
  }, [ophalenEventtypen]);

  const keyExtractor = useCallback((row: IRegel) => row.ID, []);

  const kolommen = useMemo<TypedColumn<IRegel>[]>(
    () => [
      {
        name: 'Naam',
        title: 'Naam',
      },
      {
        name: 'NaamEnum',
        title: 'NaamEnum',
      },
      {
        name: 'Notificatie',
        title: 'Notificatie',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRegel>[]>(
    () => [
      {
        columnName: 'Naam',
        width: 350,
      },
      {
        columnName: 'NaamEnum',
        width: 400,
      },
      {
        columnName: 'Notificatie',
        width: 160,
      },
    ],
    [],
  );

  const NotificatieFilter = (props: IWeergaveProps<any>) => {
    return (
      <div className="d-flex align-items-center">
        <span className="mr-2">Notificatie</span>
        <Combobox
          geselecteerd={props.data}
          opties={[
            {
              id: 1,
              label: 'Met notificatie',
            },
            {
              id: 2,
              label: 'Geen notificatie',
            },
          ]}
          onSelectieChange={(x) => {
            props.onDataChange(x!);
            props.setIsActief(true);
            props.toepassen();
          }}
        />
      </div>
    );
  };

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.Notificatie,
        altijdWeergevenInBalk: true,
        weergave: NotificatieFilter,
      },
    ],
    [],
  );

  return (
    <>
      {eventtypen === null ? (
        <LoadingSpinner />
      ) : (
        <>
          <MenuLayout
            menu={
              <>
                <div className="mt-2 d-flex align-items-center">
                  <FilterBalkV2
                    filters={filters}
                    filterData={urlState.filterData}
                    onFilterDataChange={(x) => setUrlStateSync('filterData', x)}
                    onFilterSchemaChange={setFilterSchema}
                  />
                </div>
              </>
            }
            body={
              <div className="d-flex">
                <GridStyleWrapper height={'calc(100vh - 100px)'} rowAmount={eventtypen.length}>
                  <Grid columns={kolommen} getRowId={keyExtractor} rows={eventtypen}>
                    <DataTypeProvider
                      for={[nameOf<IRegel>('Notificatie')]}
                      formatterComponent={(formatterProps) => {
                        const rij: IRegel = formatterProps.row;

                        return <span>{formatterProps.value ? 'Ja' : 'Nee'}</span>;
                      }}
                    />

                    <SortingState defaultSorting={[]} />
                    <IntegratedSorting />
                    <VirtualTable />
                    <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
                    <TableHeaderRow showSortingControls />
                    <EditingState
                      onCommitChanges={async (changes) => {
                        if (changes.deleted === undefined) {
                          return;
                        }
                        const deleted = changes.deleted;
                        const id = deleted[deleted.length - 1] as number;
                      }}
                      onEditingRowIdsChange={(rowIds) => {
                        const id = rowIds[rowIds.length - 1] as number;
                        setWijzigenDialoogState({ id });
                      }}
                    />
                    <TableEditColumn
                      width={40}
                      showEditCommand
                      cellComponent={DXTableEditColumnCellComponent}
                      commandComponent={DXTableEditColumnCommandComponent}
                    />
                  </Grid>
                </GridStyleWrapper>
              </div>
            }
          />
          {wijzigenDialoogState !== null && (
            <WijzigenDialoog
              id={wijzigenDialoogState.id}
              open
              onSuccess={() => {
                setWijzigenDialoogState(null);
                ophalenEventtypen();
              }}
              onAnnuleren={() => {
                setWijzigenDialoogState(null);
              }}
            />
          )}
        </>
      )}
    </>
  );
});

export default Eventtypen;
