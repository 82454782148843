import React, { useState, useEffect, useCallback, useMemo, useContext } from 'react';
import api from '../../../../../../../api';
import LoadingSpinner from '../../../../../../../components/Gedeeld/LoadingSpinner';
import { observer } from 'mobx-react-lite';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import useUrlState from '../../../../../../../core/useUrlState';
import { RootStoreContext } from '../../../../../../../stores/RootStore';
import {
  TypedColumn,
  TypedTableColumnWidthInfo,
  GridStyleWrapper,
  DXTableCheckboxComponent,
  DXTableToggleCellComponent,
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
} from '../../../../../../../helpers/dxTableGrid';
import { Kleur } from '../../../../../../../bedrijfslogica/constanten';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  VirtualTable,
  TableEditColumn,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  DataTypeProvider,
  EditingState,
  SelectionState,
  VirtualTable as VirtualTableBase,
} from '@devexpress/dx-react-grid';
import { IconSend } from '../../../../../../../components/Icons';
import { ContractenContext } from '../index';
import { IOphalenContractenResultElementV2 } from '../../../../../../../../../shared/src/api/v2/contract';
import ContractVisualisatie from '../../../../../../../components/entiteitVisualisaties/ContractVisualisatie';
import KeuzeDialoog, {
  EKeuzeType,
  IOptie,
} from '../../../../../../../components/dialogen/KeuzeDialoog';
import { EKanaal } from '../../../../../../../bedrijfslogica/enums';
import { addDays, differenceInDays, format } from 'date-fns';
import { IOphalenRelatiesResultElementV2 } from '../../../../../../../../../shared/src/api/v2/relatie';
import { difference } from 'lodash';

interface IProps extends RouteComponentProps {
  relID: number;
}

interface IVersturenHerinneringDialoogState {}

interface IUrlState {
  selectie: number[];
  versturenHerinneringDialoogState: IVersturenHerinneringDialoogState | null;
}

const defaultUrlState: IUrlState = {
  selectie: [],
  versturenHerinneringDialoogState: null,
};

const geenData = {
  noData: 'Geen aangeboden contracten gevonden',
};

const AangebodenOvername: React.FC<IProps> = observer((props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState<IUrlState>(props, defaultUrlState);
  const { checkStore } = useContext(RootStoreContext);

  const contractenContext = useContext(ContractenContext);
  const [contracten, setContracten] = useState<IOphalenContractenResultElementV2[] | null>(null);
  const [relatie, setRelatie] = useState<IOphalenRelatiesResultElementV2 | null>(null);

  const ophalenContracten = useCallback(async () => {
    const result = (
      await api.v2.contract.ophalenContractenV2({
        filterSchema: {
          filters: [
            {
              naam: 'REL_IDS',
              data: contractenContext.relID,
            },
            {
              naam: 'AANGEBODEN_VOOR_OVERNAME',
              data: true,
            },
          ],
        },
      })
    ).contracten;

    setContracten(result);
  }, []);

  useEffect(() => {
    ophalenContracten();
  }, [ophalenContracten]);

  const ophalenRelatie = useCallback(async () => {
    const relatie = (
      await api.v2.relatie.ophalenRelaties({
        filterSchema: { filters: [{ naam: 'IDS', data: [props.relID] }] },
      })
    ).relaties[0];

    setRelatie(relatie);
  }, [props.relID]);

  useEffect(() => {
    ophalenRelatie();
  }, [ophalenRelatie]);

  const keyExtractor = useCallback((row: IOphalenContractenResultElementV2) => row.CntID, []);

  const kolommen = useMemo<TypedColumn<IOphalenContractenResultElementV2>[]>(
    () => [
      {
        name: '__contractnummer' as any,
        title: 'Cnt.nr.',
      },
      {
        name: '__productmodel' as any,
        title: 'Productmodel',
      },
      {
        name: 'AangebodenOvernameOp',
        title: 'Aangeboden op',
      },
      {
        name: '__overdrachtOpgevolgdOp' as any,
        title: 'Laatst opgevolgd op',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IOphalenContractenResultElementV2>[]>(
    () => [
      {
        columnName: '__contractnummer' as any,
        width: 110,
      },
      {
        columnName: '__productmodel' as any,
        width: 250,
      },
      {
        columnName: 'AangebodenOvernameOp' as any,
        width: 150,
      },
      {
        columnName: '__overdrachtOpgevolgdOp' as any,
        width: 150,
      },
    ],
    [],
  );

  const kanaalOpties = useMemo<IOptie<EKanaal>[]>(() => {
    return [
      {
        id: EKanaal.Email,
        label: 'Email',
      },

      {
        id: EKanaal.SMS,
        label: 'SMS',
      },
    ].filter((x) => x !== null) as IOptie<EKanaal>[];
  }, []);

  return (
    <>
      {contracten === null || relatie === null ? (
        <div className="d-flex flex-fill align-items-center justify-content-center">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          <div
            className="d-flex flex-column p-3"
            style={{
              backgroundColor: Kleur.HeelLichtGrijs,
              borderBottom: `1px solid ${Kleur.LichtGrijs}`,
            }}
          >
            <div className="d-flex">
              <button
                className="btn btn-sm btn-light d-flex align-items-center"
                style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                disabled={urlState.selectie.length !== 0}
                onClick={async () => {
                  if (relatie.OverdrachtOpgevolgdOp !== null) {
                    const dagenGeleden = differenceInDays(
                      new Date(),
                      new Date(relatie.OverdrachtOpgevolgdOp),
                    );
                    if (dagenGeleden < 14) {
                      await checkStore.melden({
                        titel: (
                          <span>
                            Let op, er is minder dan 2 weken geleden al een opvolging geweest
                          </span>
                        ),
                      });
                    }
                  }
                  setUrlStateSync('versturenHerinneringDialoogState', {});
                }}
              >
                <IconSend style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                <span className="ml-2">Versturen herinnering</span>
              </button>
            </div>
          </div>
          <GridStyleWrapper height="calc(100vh - 150px)">
            <Grid columns={kolommen} getRowId={keyExtractor} rows={contracten!}>
              <DataTypeProvider
                for={['__contractnummer']}
                formatterComponent={(formatterProps) => {
                  const rij: IOphalenContractenResultElementV2 = formatterProps.row;
                  return <ContractVisualisatie cntID={rij.CntID} />;
                }}
              />

              <DataTypeProvider
                for={['__productmodel']}
                formatterComponent={(formatterProps) => {
                  const rij: IOphalenContractenResultElementV2 = formatterProps.row;
                  return <span>{rij.basis.productmodel.Modelnaam}</span>;
                }}
              />

              <DataTypeProvider
                for={['AangebodenOvernameOp']}
                formatterComponent={(formatterProps) => {
                  const rij: IOphalenContractenResultElementV2 = formatterProps.row;
                  return <span>{format(new Date(rij.AangebodenOvernameOp!), 'dd-MM-yyyy')}</span>;
                }}
              />

              <DataTypeProvider
                for={['__overdrachtOpgevolgdOp']}
                formatterComponent={(formatterProps) => {
                  const datum =
                    relatie.OverdrachtOpgevolgdOp !== null
                      ? format(new Date(relatie.OverdrachtOpgevolgdOp), 'dd-MM-yyyy')
                      : '';
                  return <span>{datum}</span>;
                }}
              />

              {/* <EditingState
                onAddedRowsChange={() => {
                  alert('Plus');
                }}
                onCommitChanges={() => null}
                onEditingRowIdsChange={(rowIds) => {
                  const id = rowIds[rowIds.length - 1] as number;
                  alert('Wijzigen ' + id);
                  // setWijzigenID(id);
                }}
              /> */}

              {/* <RowDetailState defaultExpandedRowIds={[]} /> */}

              <VirtualTable messages={geenData} />

              {/* <TableEditColumn
                width={35}
                // showAddCommand={true}
                showEditCommand
                cellComponent={DXTableEditColumnCellComponent}
                commandComponent={DXTableEditColumnCommandComponent}
              /> */}

              {/* <SelectionState
                selection={urlState.selectie}
                onSelectionChange={(value) => setUrlStateSync('selectie', value as number[])}
              />
              <TableSelection cellComponent={DXTableCheckboxComponent} /> */}
              <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
              <TableHeaderRow />

              {/* <TableRowDetail
                // contentComponent={}
                toggleCellComponent={DXTableToggleCellComponent}
              /> */}
            </Grid>
          </GridStyleWrapper>
        </>
      )}

      {urlState.versturenHerinneringDialoogState !== null && (
        <KeuzeDialoog
          titel="Herinnering versturen via kanaal"
          type={EKeuzeType.Enkel}
          opties={kanaalOpties}
          open
          onSuccess={async (result) => {
            const params = { relIDs: [props.relID], kanalen: result.keuze };
            await api.v2.contract.aanbieding.versturenHerinnering(params);
            setUrlStateSync('versturenHerinneringDialoogState', null);
          }}
          onAnnuleren={() => setUrlStateSync('versturenHerinneringDialoogState', null)}
          enkelOptions={{
            beginSelectie: EKanaal.SMS,
          }}
        />
      )}
    </>
  );
});

export default withRouter(AangebodenOvername);
