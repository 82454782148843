import React from 'react';
import { Redirect, Route, RouteComponentProps } from 'react-router';
import Producten from './Producten';
import Specificaties from './Specificaties';
import Tarieven from './Tarieven';

interface IProps extends RouteComponentProps<{}> {}

const Aanbod: React.FC<IProps> = (props) => {
  const { match } = props;

  return (
    <div className="d-flex flex-fill flex-column">
      <Route path={`${match.path}/tarieven`} component={Tarieven} />
      <Route path={`${match.path}/producten`} component={Producten} />
      <Route path={`${match.path}/specificaties`} component={Specificaties} />

      <Route
        exact={true}
        path={match.path}
        render={() => <Redirect to={`${match.path}/tarieven`} />}
      />
    </div>
  );
};

export default Aanbod;

// import React from 'react';
// import { Route, RouteComponentProps, NavLink } from 'react-router-dom';
// import PaginaHeader from '../PaginaHeader';
// import { IconLijst } from '../../components/Icons';
// import { Redirect } from 'react-router';
// import Producten from './Producten';
// import Tarieven from './Tarieven';
// import Specificaties from './Specificaties';

// interface IProps extends RouteComponentProps {}

// const Aanbod = (props: IProps) => {
//   const { match } = props;
//   return (
//     <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
//       <PaginaHeader>
//         <ul>
//           <li>
//             <NavLink
//               to={`${match.url}/tarieven`}
//               style={{ paddingLeft: 20, paddingRight: 20 }}
//               className="d-flex align-items-center justify-content-center"
//             >
//               <IconLijst />
//               &nbsp; Tarieven
//             </NavLink>
//           </li>
//           <li>
//             <NavLink
//               to={`${match.url}/producten`}
//               style={{ paddingLeft: 20, paddingRight: 20 }}
//               className="d-flex align-items-center justify-content-center"
//             >
//               <IconLijst />
//               &nbsp; Producten
//             </NavLink>
//           </li>
//           <li>
//             <NavLink
//               to={`${match.url}/specificaties`}
//               style={{ paddingLeft: 20, paddingRight: 20 }}
//               className="d-flex align-items-center justify-content-center"
//             >
//               <IconLijst />
//               &nbsp; Specificaties
//             </NavLink>
//           </li>
//         </ul>
//       </PaginaHeader>

//       <Route path={`${match.path}/tarieven`} component={Tarieven} />
//       <Route path={`${match.path}/producten`} component={Producten} />
//       <Route path={`${match.path}/specificaties`} component={Specificaties} />

//       <Route
//         exact={true}
//         path={match.path}
//         render={() => <Redirect to={`${match.path}/tarieven`} />}
//       />
//     </div>
//   );
// };

// export default Aanbod;
