import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalHeader from 'react-bootstrap/ModalHeader';
import IDialoogProps from '../../../../../../../../core/IDialoogProps';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../../../../../../stores/RootStore';
import { EResultType } from '../../../../../../../../stores/CheckStore';
import Dialoog from '../../../../../../../../components/dialogen/Dialoog';
import api from '../../../../../../../../api';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { Formik, FormikErrors } from 'formik';
import {
  IOphalenContractenResultElementV2,
  IWijzigenContractParams,
} from '../../../../../../../../../../shared/src/api/v2/contract';
import LoadingSpinner from '../../../../../../../../components/Gedeeld/LoadingSpinner';
import Tabblad, { ITabblad } from '../../../../../../../../components/layout/Tabblad';
import TarievenTab from './TarievenTab';
import ContractContext from './Context/ContractContext';
import ProlongatieTab from './ProlongatieTab';
import OverigTab from './Overig';
import ContractTab from './ContractTab';
import { format } from 'date-fns';
import styled from 'styled-components';
import { IOphalenLocatieNiveausResultElement } from '../../../../../../../../../../shared/src/api/v2/locatie/locatie';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../../../../../models/IRemoteData';
import { IOphalenFactuurkenmerkenResult } from '../../../../../../../../../../shared/src/api/v2/factuur';
import { IOphalenRekeningenResultElement } from '../../../../../../../../../../shared/src/api/v2/relatie';
import { IOphalenAanbodResultElement } from '../../../../../../../../../../shared/src/api/v2/aanbod/index';
import { EContractStatus } from '../ContractTegel/ContractStatus';
import {
  IOphalenProductmodellenResult,
  IOphalenProductmodellenResultElement,
} from '../../../../../../../../../../shared/src/api/v2/aanbod/productmodel';
import TechnischeInformatieKnop from '../../../../../../../../components/TechnischeInformatieKnop';

interface IDialoogResult {}

enum ETablad {
  Tarieven,
  Prolongatie,
  Contract,
  Overig,
}

interface IProps extends IDialoogProps<IDialoogResult> {
  relID: number;
  cntID: number;
}

export interface IFormikValues {
  aantalGebruikersTarief: number | null;
  aantalGebruikersMax: number | null;
  datumGeleverd: Date | null;
  datumRetour: Date | null;
  prolongatieOphouden: boolean;
  prolongatieStart: Date | null;
  prolongatieEind: Date | null;
  prolongatieTot: Date | null;
  maandhuur: number;
  maandhuurActie: number | null;
  maandhuurActieAangevinkt: boolean;
  actieduur: number | null;
  eenmaligBedrag: number;
  eenmaligBedragActie: number | null;
  eenmaligBedragAangevinkt: boolean;
  aantalGebruikers: number;
  abonnementID: number;
  ingangsdatum: Date | null;
  einddatum: Date | null;
  verdieping: number | null;
  aansluitenTrekschakelaar: boolean | null;
  contractwissel_CntID: number | null;
  factuurkenmerkKey: number | string | null;
  gebruikerstoeslagTarief: number;
  relRekID: number | null;
  aanvragerPersID: number | null;
  kostenVtbNietToepassen: boolean;
  prodModID: number;
}

export const initialValues: IFormikValues = {
  aantalGebruikersTarief: null,
  aantalGebruikersMax: null,
  datumGeleverd: null,
  datumRetour: null,
  prolongatieOphouden: false,
  prolongatieStart: null,
  prolongatieEind: null,
  prolongatieTot: null,
  maandhuur: 1,
  maandhuurActie: 0,
  maandhuurActieAangevinkt: false,
  actieduur: null,
  eenmaligBedrag: 0,
  eenmaligBedragActie: 0,
  eenmaligBedragAangevinkt: false,
  aantalGebruikers: 0,
  abonnementID: 0,
  ingangsdatum: null,
  einddatum: null,
  verdieping: null,
  aansluitenTrekschakelaar: null,
  contractwissel_CntID: null,
  factuurkenmerkKey: null,
  gebruikerstoeslagTarief: 0,
  relRekID: null,
  aanvragerPersID: null,
  kostenVtbNietToepassen: false,
  prodModID: 0,
};

export const veldnamen: Record<keyof IFormikValues, string> = {
  aantalGebruikersMax: 'Max aantal gebruikers',
  aantalGebruikersTarief: 'Aantal gebruikers voor tarief',
  prolongatieOphouden: 'Prolongatie ophouden',
  datumGeleverd: 'Datum geleverd',
  datumRetour: 'Datum retour',
  prolongatieStart: 'Prolongeren vanaf',
  prolongatieEind: 'Einddatum prolongatie',
  prolongatieTot: 'Er is geprolongerd tot',
  maandhuur: 'Maandhuur',
  maandhuurActie: 'maandhuur actie',
  actieduur: 'Actieduur',
  eenmaligBedrag: 'Eenmalig bedrag',
  eenmaligBedragActie: 'Eenmalig bedrag actie',
  eenmaligBedragAangevinkt: 'Actie?',
  maandhuurActieAangevinkt: 'Actie?',
  aantalGebruikers: 'Aantal gebruikers',
  abonnementID: 'Abonnement',
  ingangsdatum: 'Ingangsdatum',
  einddatum: 'Einddatum',
  verdieping: 'Verdieping',
  aansluitenTrekschakelaar: 'Trekschakelaar',
  contractwissel_CntID: 'Contractwissel voor contract',
  factuurkenmerkKey: 'Factuurkenmerk',
  gebruikerstoeslagTarief: 'Gbr.toesl. p.p.',
  relRekID: 'Afwijkende bankrekening',
  aanvragerPersID: 'Aanvrager',
  kostenVtbNietToepassen: 'Geen kosten VTB in rekening brengen',
  prodModID: 'Productmodel (Alleen wijzigen als je weet wat je doet)',
};

const DialoogWrapper = styled.div`
  .modal-dialog {
    max-width: 550px !important;
    border: 4px red;
  }
`;

const WijzigenContractDialoog: React.FC<IProps> = observer((props) => {
  const { dialoogIndex, onAnnuleren, onSuccess, open, cntID, relID } = props;
  const { checkStore } = useContext(RootStoreContext);
  const [contract, setContract] = useState<IOphalenContractenResultElementV2 | null>(null);
  const [productmodellenTarieven, setProductmodellenTarieven] = useState<
    IOphalenAanbodResultElement[] | null
  >(null);
  const [tabblad, setTabblad] = useState<ETablad>(ETablad.Tarieven);

  const [wisselContractenOpties, setWisselContractenOpties] = useState<
    IOphalenContractenResultElementV2[] | null
  >(null);

  const [rekeningen, setRekeningen] = useState<IOphalenRekeningenResultElement[] | null>(null);

  const handleValidate = useCallback((values: IFormikValues) => {
    const errors: FormikErrors<IFormikValues> = {};

    if (values.maandhuurActieAangevinkt) {
      if (values.maandhuurActie == 0 && (values.actieduur === null || values.actieduur === 0)) {
        errors.actieduur = 'Mag geen 0 zijn';
      }
    }

    return errors;
  }, []);

  const tabbladen = useMemo<ITabblad<ETablad>[]>(
    () => [
      {
        id: ETablad.Tarieven,
        label: 'Tarieven',
        content: TarievenTab,
      },
      {
        id: ETablad.Prolongatie,
        label: 'Prolongatie',
        content: ProlongatieTab,
      },
      {
        id: ETablad.Contract,
        label: 'Contract',
        content: ContractTab,
      },
      {
        id: ETablad.Overig,
        label: 'Overig',
        content: OverigTab,
      },
    ],
    [onSuccess],
  );

  const [locatieNiveaus, setLocatieNiveaus] = useState<
    IOphalenLocatieNiveausResultElement[] | null
  >(null);

  const ophalenLocatieNiveaus = useCallback(async () => {
    const resultaat = await api.v2.locatie.ophalenLocatieNiveaus({
      filterSchema: {},
    });

    setLocatieNiveaus(resultaat.locatieniveaus);
  }, []);

  useEffect(() => {
    ophalenLocatieNiveaus();
  }, [ophalenLocatieNiveaus]);

  const [factuurkenmerken, setFactuurkenmerken] = useState<
    IRemoteData<IOphalenFactuurkenmerkenResult>
  >(createPendingRemoteData());

  const ophalenFactuurkenmerken = useCallback(async () => {
    const result = await api.v2.factuur.ophalenFactuurkenmerken({
      filterSchema: {
        filters: [
          {
            naam: 'REL_IDS',
            data: [props.relID],
          },
        ],
      },
    });

    setFactuurkenmerken(createReadyRemoteData(result));
  }, [props.relID]);

  useEffect(() => {
    ophalenFactuurkenmerken();
  }, [ophalenFactuurkenmerken]);

  const [productmodellen, setProductmodellen] = useState<
    IRemoteData<IOphalenProductmodellenResult>
  >(createPendingRemoteData());

  const ophalenProductmodellen = useCallback(async () => {
    if (contract === null) {
      return;
    }
    const result = await api.v2.product.model.ophalenProductmodellen({
      filterSchema: {
        uitgebreideFilter: {
          or: [
            {
              filter: {
                naam: 'ACTIEF',
                data: true,
              },
            },
            {
              filter: {
                naam: 'IDS',
                data: [contract.basis.productmodel.ProdModID],
              },
            },
          ],
        },
      },
    });

    setProductmodellen(createReadyRemoteData(result));
  }, [contract]);

  useEffect(() => {
    ophalenProductmodellen();
  }, [ophalenProductmodellen]);

  const ophalenContract = useCallback(async () => {
    // const contract = (await api.v2.contract.ophalenContractenBasis({ cntIDs: [cntID] }))[0];

    const contract = (
      await api.v2.contract.ophalenContractenV2({
        filterSchema: { filters: [{ naam: 'IDS', data: [cntID] }] },
      })
    ).contracten[0];

    setContract(contract);

    // const contractenVoorWissel = (
    //   await api.v2.contract.ophalenContractenV2({
    //     filterSchema: {
    //       filters: [
    //         { naam: 'LOC_IDS', data: [contract.basis.locatie.LocID] },
    //         { naam: 'PRODSRT_IDS', data: [contract.basis.productmodel.ProdSrtID] },
    //         { naam: 'STATUS_NAAMENUM', data: ['LOPEND'] },
    //       ],
    //     },
    //   })
    // ).contracten;

    const contractenVoorWissel = (
      await api.v2.contract.ophalenContractenV2({
        filterSchema: {
          filters: [
            { naam: 'REL_IDS', data: [props.relID] },
            // { naam: 'PRODSRT_IDS', data: [contract.basis.productmodel.ProdSrtID] },
            // { naam: 'STATUS_NAAM_ENUMS', data: [EContractStatus.Lopend] },
          ],
        },
      })
    ).contracten;

    const contractenZonderHetVigerendContract = contractenVoorWissel.filter(
      (x) => x.CntID !== cntID,
    );

    setWisselContractenOpties(contractenZonderHetVigerendContract);
  }, [cntID, props.relID]);

  useEffect(() => {
    ophalenContract();
  }, [cntID]);

  const ophalenRekeningen = useCallback(async () => {
    if (contract === null) {
      return null;
    }

    const relatie = (
      await api.v2.relatie.ophalenRelaties({
        filterSchema: { filters: [{ naam: 'IDS', data: [contract.RelID] }] },
      })
    ).relaties[0];

    setRekeningen(relatie.financieel.rekeningen);
  }, [contract]);

  useEffect(() => {
    ophalenRekeningen();
  }, [ophalenRekeningen]);

  useEffect(() => {
    (async () => {
      if (contract === null) {
        return;
      }
      const aanbodResult = await api.v2.aanbod.ophalenAanbod({
        filterSchema: {
          filters: [
            {
              naam: 'REL_ID',
              data: [props.relID],
            },
            { naam: 'PRODMOD_IDS', data: [contract.basis.productmodel.ProdModID] },
          ],
        },
      });

      setProductmodellenTarieven(aanbodResult);
    })();
  }, [props.relID, contract]);

  const initialFormikValues = useMemo<IFormikValues | null>(() => {
    if (contract === null || productmodellenTarieven === null) {
      return null;
    }

    return {
      aantalGebruikersMax: contract.basis.AantalGebruikersMax,
      aantalGebruikersTarief: contract.basis.AantalGebruikersTarief,
      datumGeleverd:
        contract.basis.DatumGeleverd === null ? null : new Date(contract.basis.DatumGeleverd),
      datumRetour:
        contract.basis.DatumRetour === null ? null : new Date(contract.basis.DatumRetour),
      prolongatieOphouden: contract.prolongatie.Ophouden,
      prolongatieEind:
        contract.prolongatie.Eind === null ? null : new Date(contract.prolongatie.Eind),
      prolongatieStart:
        contract.prolongatie.Start === null ? null : new Date(contract.prolongatie.Start),
      prolongatieTot: contract.prolongatie.Tot === null ? null : new Date(contract.prolongatie.Tot),
      maandhuur: contract.basis.Maandhuur,
      maandhuurActie: contract.basis.actie !== null ? contract.basis.actie.Maandhuur : null,
      maandhuurActieAangevinkt:
        contract.basis.actie !== null && contract.basis.actie.Maandhuur !== null ? true : false,
      actieduur: contract.basis.actie !== null ? contract.basis.actie.Actieduur : null,
      eenmaligBedrag: contract.basis.EenmaligBedrag,
      eenmaligBedragActie:
        contract.basis.actie !== null ? contract.basis.actie.EenmaligBedrag : null,
      eenmaligBedragAangevinkt:
        contract.basis.actie !== null && contract.basis.actie.EenmaligBedrag !== null
          ? true
          : false,
      aantalGebruikers: contract.basis.AantalGebruikers,
      abonnementID: contract.basis.abonnement.AbonID,
      ingangsdatum: new Date(contract.Ingangsdatum),
      einddatum: contract.Einddatum !== null ? new Date(contract.Einddatum) : null,
      verdieping:
        contract.basis.locatieniveau !== null ? contract.basis.locatieniveau.Verdieping : null,
      aansluitenTrekschakelaar: contract.basis.AansluitenTrekschakelaar,
      contractwissel_CntID: contract.basis.Contractwissel_CntID,
      factuurkenmerkKey: contract.factuurkenmerk !== null ? contract.factuurkenmerk.ID : null,
      gebruikerstoeslagTarief: contract.basis.GebruikerstoeslagTarief,
      relRekID: contract.rekening !== null ? contract.rekening.RelRekID : null,
      aanvragerPersID: contract.persoon !== null ? contract.persoon.PersID : null,
      kostenVtbNietToepassen: contract.KostenVtbNietToepassen,
      prodModID: contract.basis.productmodel.ProdModID,
    };
  }, [contract, productmodellenTarieven]);

  const handleSubmit = useCallback(
    async (values: IFormikValues) => {
      const params: IWijzigenContractParams = {
        cntID: cntID,
        abonID: values.abonnementID,
        prolongatieOphouden: values.prolongatieOphouden,
        maandhuur: values.maandhuur,
        maandhuurActie: values.maandhuurActieAangevinkt ? values.maandhuurActie : null,
        actieduur: values.maandhuurActieAangevinkt ? values.actieduur : null,
        eenmaligBedrag: values.eenmaligBedrag,
        eenmaligBedragActie:
          values.eenmaligBedragAangevinkt === true ? values.eenmaligBedragActie : null,
        datumGeleverd:
          values.datumGeleverd !== null
            ? format(new Date(values.datumGeleverd), 'yyyy-MM-dd')
            : null,
        datumRetour:
          values.datumRetour !== null ? format(new Date(values.datumRetour), 'yyyy-MM-dd') : null,
        aantalGebruikers: values.aantalGebruikers,
        aantalGebruikersTarief:
          values.aantalGebruikersTarief === null ? 0 : values.aantalGebruikersTarief,
        aantalGebruikersMax: values.aantalGebruikersMax === null ? 0 : values.aantalGebruikersMax,
        datumBevestigd: null,

        prolongatieStart: values.prolongatieStart,
        prolongatieEind: values.prolongatieEind,
        geprolongeerdTot: values.prolongatieTot,
        ProlongatieOphouden: values.prolongatieOphouden,
        ingangsdatum: values.ingangsdatum!,
        einddatum: values.einddatum,
        verdieping: values.verdieping,
        aansluitenTrekschakelaar: values.aansluitenTrekschakelaar,
        contractwissel_CntID: values.contractwissel_CntID,
        factuurkenmerkKey: values.factuurkenmerkKey,
        gebruikerstoeslagTarief: values.gebruikerstoeslagTarief,
        relRekID: values.relRekID,
        aanvragerPersID: values.aanvragerPersID,
        kostenVtbNietToepassen: values.kostenVtbNietToepassen,
        prodModID: values.prodModID,
      };

      const checkData = await api.v2.contract.checkWijzigenContract(params);

      const controleResult = await checkStore.controleren({
        checkData,
      });
      if (controleResult.type === EResultType.Annuleren) {
        return;
      }
      await api.v2.contract.wijzigenContract(params);

      // if (bevestigingSturen) {
      //   await api.v2.contract.versturenBevestigingNieuw({
      //     cntIDs,
      //   });
      // }

      onSuccess({});
    },
    [cntID],
  );

  const [vastgesteldeFactuurkenmerken, setVastgesteldeFactuurkenmerken] = useState<string[]>([]);

  return (
    <DialoogWrapper>
      <Dialoog
        index={dialoogIndex || 0}
        modalProps={{
          size: 'lg',
        }}
      >
        <ModalHeader>
          <ModalTitle>
            Wijzigen contract{' '}
            {contract !== null
              ? contract.basis.Basisnummer +
                '.' +
                contract.Volgnummer +
                ' ' +
                contract.basis.productmodel.Modelnaam
              : ''}
          </ModalTitle>
        </ModalHeader>
        <ContractContext.Provider
          value={{
            contract,
            productmodellenTarieven,
            rekeningen: rekeningen,
            cntID: [cntID],
            locatieNiveaus,
            wisselContractenOpties,
            factuurkenmerken:
              factuurkenmerken.state === ERemoteDataState.Pending
                ? null
                : factuurkenmerken.data!.factuurkenmerken,
            vastgesteldeFactuurkenmerken,
            nieuweFactuurkenmerkVaststellen: (kenmerk) =>
              setVastgesteldeFactuurkenmerken([...vastgesteldeFactuurkenmerken, kenmerk]),
            productmodellen:
              productmodellen.state === ERemoteDataState.Pending
                ? null
                : productmodellen.data!.modellen,
          }}
        >
          {initialFormikValues === null ? (
            <div className="flex-fill d-flex align-items-center justify-content-center p-5">
              <LoadingSpinner />
            </div>
          ) : (
            <>
              <Formik<IFormikValues>
                isInitialValid
                initialValues={initialFormikValues}
                validate={handleValidate}
                onSubmit={handleSubmit}
                render={(FormikProps) => {
                  const { submitForm, isSubmitting, values, errors } = FormikProps;

                  if (contract === null) {
                    return;
                  }

                  return (
                    <>
                      <div style={{ minHeight: `400px` }}>
                        <Tabblad
                          tabbladen={tabbladen}
                          geselecteerd={tabblad}
                          onSelectieChange={(id) => {
                            setTabblad(id);
                          }}
                        />
                      </div>
                      <ModalFooter className="d-flex flex-row justify-content-start">
                        <button
                          className="btn btn-primary"
                          disabled={isSubmitting}
                          onClick={submitForm}
                          style={{ width: 100 }}
                        >
                          Ok
                        </button>
                        <button
                          className="btn btn-secondary"
                          onClick={onAnnuleren}
                          style={{ width: 100 }}
                        >
                          Annuleren
                        </button>
                        <div className="flex-fill" />
                        <TechnischeInformatieKnop
                          dialoogIndex={(props.dialoogIndex ?? 0) + 1}
                          content={() => (
                            <div>
                              CntID: {props.cntID}
                              <br />
                              CntBasisID: {contract.basis.CntBasisID}
                            </div>
                          )}
                        />
                      </ModalFooter>
                    </>
                  );
                }}
              />
            </>
          )}
        </ContractContext.Provider>
      </Dialoog>
    </DialoogWrapper>
  );
});

export default WijzigenContractDialoog;
