import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import * as Yup from 'yup';
import api from '../../../api';
import {
  IOphalenLandenVoorLijstResult,
  IOphalenLandenVoorLijstResultElement,
} from '../../../../../shared/src/api/v2/land';
import Combobox, { IOptie } from '../Combobox';
import LoadingSpinner from '../../Gedeeld/LoadingSpinner';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../stores/RootStore';
import { EResultType } from '../../../stores/CheckStore';
import useBijGewijzigdEffect from '../../../core/useBijGewijzigdEffect';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../models/IRemoteData';

interface IProps {
  values: IValues | null;
  onChange: (values: IValues | null) => void;
  onDataChange?: (data: IData) => void;
}

export interface IValues {
  straatnaam: string;
  huisnummer: number;
  bisnummer: string;
  postcode: string;
  plaatsnaam: string;
  landID: number;
}

export interface IData {
  straatnaam: string;
  huisnummer: string;
  bisnummer: string;
  postcode: string;
  plaatsnaam: string;
  landID: number;
}

const postcodeNL = (postcode: string): string => {
  postcode = postcode.trim().toUpperCase();
  // Geen spatie tussen twee onderdelen
  if (postcode.length === 6) {
    return postcode.slice(0, 4) + ' ' + postcode.slice(4, 6);
  }

  return postcode;
};

const dataToValues = (data: IData, landNaamEnum: string): IValues => {
  let postcode = data.postcode.trim();
  if (landNaamEnum === 'NL') {
    postcode = postcodeNL(postcode);
  }

  return {
    straatnaam: data.straatnaam.trim(),
    huisnummer: Number(data.huisnummer),
    bisnummer: data.bisnummer.trim(),
    postcode,
    plaatsnaam: data.plaatsnaam.trim(),
    landID: data.landID,
  };
};

const valuesToData = (values: IValues): IData => {
  return {
    straatnaam: values.straatnaam,
    huisnummer: values.huisnummer.toString(),
    bisnummer: values.bisnummer,
    postcode: values.postcode,
    plaatsnaam: values.plaatsnaam,
    landID: values.landID,
  };
};

const veldnamen = {
  straatnaam: 'Straatnaam',
  huisnummer: 'Huisnr',
  bisnummer: 'Bisnr',
  postcode: 'Postcode',
  plaatsnaam: 'Plaatsnaam',
  landcode: 'Land',
};

const STANDAARD_LAND_ID = 1;

const AdresFormulier: React.FC<IProps> = observer((props) => {
  const { values, onChange } = props;
  const { checkStore } = useContext(RootStoreContext);

  const [landen, setLanden] = useState<IOphalenLandenVoorLijstResult | null>(null);
  const landenBijLandID = useMemo<{
    [landID: number]: IOphalenLandenVoorLijstResultElement;
  } | null>(
    () =>
      landen === null
        ? null
        : landen.reduce(
            (acc, curr) => ({
              ...acc,
              [curr.LandID]: curr,
            }),
            {},
          ),
    [landen],
  );

  const [data, setData] = useState<IData>(
    useMemo(
      () => ({
        straatnaam: values === null ? '' : values.straatnaam,
        huisnummer: values === null ? '' : values.huisnummer.toString(),
        landID: values === null ? STANDAARD_LAND_ID : values.landID,
        plaatsnaam: values === null ? '' : values.plaatsnaam,
        postcode: values === null ? '' : values.postcode,
        bisnummer: values === null ? '' : values.bisnummer,
      }),
      [],
    ),
  );
  useEffect(() => {
    if (props.onDataChange === undefined) {
      return;
    }
    props.onDataChange(data);
  }, [data, props.onDataChange]);

  const gekozenLand = useMemo<IOphalenLandenVoorLijstResultElement | null>(
    () => (landenBijLandID === null ? null : landenBijLandID[data.landID]!),
    [data.landID, landenBijLandID],
  );

  useEffect(() => {
    (async () => {
      const data = await api.v2.land.ophalen({});
      setLanden(data);
    })();
  }, []);

  const landcodeOpties = useMemo<IOptie<number>[] | null>(() => {
    if (landen === null) {
      return null;
    }

    return landen.map((land) => ({
      id: land.LandID,
      label: land.Naam,
    }));
  }, [landen]);

  const postcodeHuisnummerValidatieSchema = useMemo<IRemoteData<Yup.ObjectSchema>>(() => {
    if (gekozenLand === null) {
      return createPendingRemoteData();
    }

    let postcodeRegex: RegExp;
    switch (gekozenLand.NaamEnum) {
      case 'NL':
        postcodeRegex = /^[1-9][0-9]{3}\s?[a-zA-Z]{2}$/;
        break;
      case 'BE':
        postcodeRegex = /^[1-9][0-9]{3}$/;
        break;
      case 'DE':
        postcodeRegex = /^[1-9][0-9]{4}$/;
        break;
      case 'LU':
        postcodeRegex = /^[1-9][0-9]{3}$/;
    }

    return createReadyRemoteData(
      Yup.object().shape({
        huisnummer: Yup.number()
          .required()
          .moreThan(0, 'Huisnummer moet een getal boven 0 zijn'),
        postcode: Yup.string()
          .required()
          .matches(postcodeRegex!, 'Postcode is niet geldig'),
      }),
    );
  }, [gekozenLand?.NaamEnum]);

  const validatieSchema = useMemo<IRemoteData<Yup.ObjectSchema>>(() => {
    if (postcodeHuisnummerValidatieSchema.state === ERemoteDataState.Pending) {
      return createPendingRemoteData();
    }
    const vs = postcodeHuisnummerValidatieSchema.data!;

    return createReadyRemoteData(
      vs.shape({
        straatnaam: Yup.string().required(),
        // .matches(/^([^0-9]*)$/, 'Straatnaam bevat een getal, was dit als huisnummer bedoelt?')
        // .matches(/^[a-zA-Z -'.]*$/, 'Straatnaam bevat speciale karakters'),
        bisnummer: Yup.string()
          .nullable(true)
          .matches(
            /([a-z]|[A-Z]|\d|-|\s|^$)+$/,
            'Bisnummer mag alleen bestaan uit tekens, cijfers, streepjes en spaties',
          )
          .max(15, 'Bisnummer mag maximaal 15 tekens bevatten'),
        plaatsnaam: Yup.string().required(),
        // .matches(/^([^0-9]*)$/, 'Plaatsnaam bevat een getal')
        // .matches(/^[a-zA-Z -'.]*$/, 'Plaatsnaam bevat speciale karakters'),
      }),
    );
  }, [postcodeHuisnummerValidatieSchema]);

  useBijGewijzigdEffect(() => {
    if (gekozenLand === null || validatieSchema.state === ERemoteDataState.Pending) {
      return;
    }
    (async () => {
      const isValid = await validatieSchema.data!.isValid(data);
      onChange(isValid ? dataToValues(data, gekozenLand!.NaamEnum) : null);
    })();
  }, [JSON.stringify(data), gekozenLand, validatieSchema]);

  useEffect(() => {
    if (values === null) {
      return;
    }
    const newData = valuesToData(values);
    setData(newData);
  }, [JSON.stringify(values)]);

  const initieelRef = useRef(true);
  useEffect(() => {
    if (postcodeHuisnummerValidatieSchema.state === ERemoteDataState.Pending) {
      return;
    }

    if (initieelRef.current) {
      initieelRef.current = false;
      return;
    }
    const params = {
      huisnummer: data.huisnummer.trim(),
      postcode: data.postcode.trim(),
      landcode: data.landID,
    };
    (async () => {
      const isValid = await postcodeHuisnummerValidatieSchema.data!.isValid(params);
      if (!isValid) {
        return;
      }
      const postcodeData = await api.v2.dienst.postcodedata.ophalenPostcodeData({
        huisnummer: Number(params.huisnummer),
        landID: params.landcode,
        postcode: params.postcode,
      });
      if (postcodeData === null) {
        return;
      }
      setData((d) => ({
        ...d,
        plaatsnaam: postcodeData.plaatsnaam,
        straatnaam: postcodeData.straatnaam,
      }));
      // setPlaatsnaam(postcodeData.plaatsnaam);
      // setStraatnaam(postcodeData.straatnaam);
    })();
  }, [data.huisnummer, data.postcode, data.landID, postcodeHuisnummerValidatieSchema]);

  return (
    <div className="form-group">
      <div className="d-flex">
        <div className="flex-fill" />
        <a
          href="#"
          onClick={async () => {
            if (
              (await checkStore.bevestigen({ inhoud: 'Bevestig adres wissen' })).type ===
              EResultType.Annuleren
            ) {
              return;
            }
            setData({
              straatnaam: '',
              huisnummer: '',
              landID: STANDAARD_LAND_ID,
              plaatsnaam: '',
              postcode: '',
              bisnummer: '',
            });
          }}
        >
          Adres wissen
        </a>
      </div>
      <div className="row">
        <div className="col-3">
          <label>{veldnamen.postcode}</label>
          <input
            type="text"
            className="form-control"
            value={data.postcode}
            onChange={(ev) => {
              let value = ev.target.value;
              if (gekozenLand!.NaamEnum === 'NL') {
                // Naar hoofdletters
                value = value.toUpperCase();
              }
              setData((d) => ({ ...d, postcode: value }));
            }}
            onBlur={(ev) => {
              let value = ev.target.value;
              if (gekozenLand!.NaamEnum === 'NL') {
                // Naar hoofdletters
                value = postcodeNL(value);
              }
              if (ev.target.value !== value) {
                setData((d) => ({ ...d, postcode: value }));
              }
            }}
          />
        </div>
        <div className="col-3">
          <label>{veldnamen.huisnummer}</label>
          <input
            type="text"
            className="form-control"
            value={data.huisnummer}
            onChange={(ev) => {
              const val = ev.target.value;
              setData((d) => ({ ...d, huisnummer: val }));
            }}
          />
        </div>
        <div className="col-3">
          <label>{veldnamen.bisnummer}</label>
          <input
            type="text"
            className="form-control"
            value={data.bisnummer}
            onChange={(ev) => {
              const value = ev.target.value;
              setData((d) => ({
                ...d,
                bisnummer: value,
              }));
            }}
          />
        </div>
        <div className="col-3">
          <label>{veldnamen.landcode}</label>
          {landcodeOpties === null ? (
            <LoadingSpinner />
          ) : (
            <Combobox
              geselecteerd={data.landID}
              onSelectieChange={(x) => setData((d) => ({ ...d, landID: x as number }))}
              opties={landcodeOpties}
            />
          )}
        </div>
        <div className="col-6 mt-2">
          <label>{veldnamen.straatnaam}</label>
          <input
            type="text"
            className="form-control"
            value={data.straatnaam}
            onChange={(ev) => {
              const value = ev.target.value;
              setData((d) => ({ ...d, straatnaam: value }));
            }}
          />
        </div>
        <div className="col-6 mt-2">
          <label>{veldnamen.plaatsnaam}</label>
          <input
            type="text"
            className="form-control"
            value={data.plaatsnaam}
            onChange={(ev) => {
              const value = ev.target.value;
              setData((d) => ({ ...d, plaatsnaam: value }));
            }}
          />
        </div>
      </div>
    </div>
  );
});

export default AdresFormulier;
