import { RootStore } from './RootStore';
import api from '../api';
import {
  IBijwerkenSmsContactBroadcastData,
  INieuwSMSContactBroadcastData,
  ISmsBericht,
  ISmsBerichtBroadcastData,
  OphalenContactenResult,
} from '../../../shared/src/api/v2/sms/Sms';
import { action, makeObservable, observable } from 'mobx';
import IPaginatiePositie from '../../../shared/src/models/IPaginatiePositie';

class SMSStore {
  // tslint:disable-next-line:variable-name
  private _rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this._rootStore = rootStore;

    makeObservable(this, {
      contacten: observable,
      contactenVoorZoekterm: observable,
      klantkaartContacten: observable,
      berichten: observable,
      ophalenInitieleContacten: action,
      ophalenBerichtenVoorContact: action,
      handleSmsBericht: action,
    });
  }

  public klantkaartContacten: { [relID: number]: OphalenContactenResult } = {};
  public contacten: OphalenContactenResult | null = null;
  public contactenVoorZoekterm: OphalenContactenResult | null = null;
  public berichten: { [key: string]: ISmsBericht[] } = {};
  private _contactenVorigePaginatie: IPaginatiePositie | null = null;

  public ophalenKlantkaartContacten = async (relID: number) => {
    const result = await api.v2.dienst.sms.ophalenKlantkaartContacten({
      relID,
    });
    this.klantkaartContacten = {
      ...this.klantkaartContacten,
      [relID]: result,
    };
  };

  public ophalenInitieleContacten = async () => {
    const paginatie: IPaginatiePositie = {
      index: 0,
      aantal: 50,
    };
    this._contactenVorigePaginatie = paginatie;
    const result = await api.v2.dienst.sms.ophalenContacten({
      paginatie,
    });
    this.contacten = result;
  };

  public ophalenContactenVoorZoekterm = async (zoekterm: string) => {
    const result = await api.v2.dienst.sms.ophalenContactenVoorZoekterm({
      zoekterm,
    });
    this.contactenVoorZoekterm = result;
  };

  public ophalenVolgendeContacten = async (): Promise<boolean> => {
    if (this._contactenVorigePaginatie === null) {
      throw new Error('Initiele contacten nog niet opgehaald');
    }
    const vorigePaginatie = this._contactenVorigePaginatie!;
    const volgendePaginatie: IPaginatiePositie = {
      index: vorigePaginatie.index + vorigePaginatie.aantal,
      aantal: vorigePaginatie.aantal,
    };
    this._contactenVorigePaginatie = volgendePaginatie;
    const result = await api.v2.dienst.sms.ophalenContacten({
      paginatie: volgendePaginatie,
    });
    this.contacten = [...this.contacten!, ...result];

    return true;
  };

  public ophalenBerichtenVoorContact = async (smsContactID: number) => {
    const result = await api.v2.dienst.sms.ophalenBerichtenVoorContact({
      SmsContactID: smsContactID,
    });
    this.berichten = {
      ...this.berichten,
      [smsContactID]: result.berichten,
    };
  };

  public handleSmsBericht = async (data: ISmsBerichtBroadcastData) => {
    const huidigeBerichten = this.berichten[data.smsContactID];
    if (huidigeBerichten === undefined) {
      // Negeer het bericht, want de datasource moet nog opgehaald worden.
      return;
    }

    this.berichten = {
      ...this.berichten,
      [data.smsContactID]: [...huidigeBerichten, data.bericht],
    };
    if (this.contacten !== null) {
      this.contacten = this.contacten.map((contact) => {
        if (contact.ID === data.smsContactID) {
          return {
            ...contact,
            laatsteBericht: data.bericht,
          };
        }
        return contact;
      });
    }
  };

  public handleNieuwSmsContact = async (data: INieuwSMSContactBroadcastData) => {
    const contacten = this.contacten || [];
    this.contacten = [data.contact, ...contacten];

    let berichten = this.berichten[data.contact.ID];
    if (berichten === undefined) {
      berichten = [...data.berichten];
    } else {
      berichten = [...berichten, ...data.berichten];
    }

    this.berichten = {
      ...this.berichten,
      [data.contact.ID]: berichten,
    };
  };

  public handleSmsContactGewijzigd = async (data: IBijwerkenSmsContactBroadcastData) => {
    if (this.contacten === null || this.contacten.length === 0) {
      return;
    }

    this.contacten = this.contacten.map((contact) => {
      if (contact.ID === data.contact.ID) {
        return data.contact;
      }
      return contact;
    });
  };
}

export default SMSStore;
