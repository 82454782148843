import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { IUitgeklapteRijProps } from '../../../../../components/tabel/ASPTabel/Body/UitgeklapteRij';
import { IBanksaldo } from '../../../../../../../shared/src/api/v2/boekhouding/controle';
import { Kleur } from '../../../../../bedrijfslogica/constanten';
import { IOphalenMutatiesResultElement } from '../../../../../../../shared/src/api/v2/bank/mutaties';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../../models/IRemoteData';
import LoadingSpinner from '../../../../../components/Gedeeld/LoadingSpinner';
import api, { IPaginatiePositie } from '../../../../../api';
import ASPTabel from '../../../../../components/tabel/ASPTabel';
import { ASPKolom, EAspKolomBreedteType } from '../../../../../components/tabel/ASPTabel/types';
import FormatteerBedrag from '../../../../../components/MutatieBedrag';
import TabelInspringBlok from '../../../../../components/layout/TabelInspringBlok';
import { IconVink, IconKruis } from '../../../../../components/Icons';
import { BanksaldiV2Context } from '../index';

export enum EKolom {
  Bedrag,
  Omschrijving,
  Rekeningnaam,
  Betalingskenmerk,
  Geboekt,
}

interface IData {
  mutaties: Record<number, IOphalenMutatiesResultElement>;
  totaalAantal: number;
}

const UitgeklapteRij = (props: IUitgeklapteRijProps<any, IBanksaldo>) => {
  const banksaldiV2Context = useContext(BanksaldiV2Context);
  const dataRef = useRef<IRemoteData<IData>>(createPendingRemoteData());
  const [dataState, setDataState] = useState(dataRef.current);
  const data = useMemo(() => dataState, [dataState]);
  const setData = useCallback((data: IRemoteData<IData>) => {
    dataRef.current = data;
    setDataState(data);
  }, []);
  const ophalenAbortControllerRef = useRef<AbortController | null>(null);
  const ophalenData = useCallback(
    async (paginatie: IPaginatiePositie, uitbreiden: boolean) => {
      if (ophalenAbortControllerRef.current !== null) {
        ophalenAbortControllerRef.current.abort();
      }
      ophalenAbortControllerRef.current = new AbortController();

      const result = await api.v2.bank.mutatie.ophalenMutaties({
        filterSchema: {
          filters: [
            {
              naam: 'MUTATIEDATUM',
              data: props.regel.Mutatiedatum,
            },
            {
              naam: 'BANK_REK_IDS',
              data: [banksaldiV2Context.bankRekID],
            },
          ],
        },
        paginatie,
      });

      const items = result.mutaties.reduce(
        (acc, item, idx) => ({
          ...acc,
          [paginatie.index + idx]: item,
        }),
        uitbreiden ? dataRef.current?.data?.mutaties ?? {} : {},
      );

      setData(
        createReadyRemoteData({
          totaalAantal: result.totaalAantal,
          mutaties: items,
        }),
      );
    },
    [props.regel.Mutatiedatum, banksaldiV2Context.bankRekID],
  );

  useEffect(() => {
    ophalenData({ index: 0, aantal: 50 }, false);
  }, [ophalenData]);

  const handleExtraRijenAangevraagd = useCallback(
    async (paginatie: IPaginatiePositie) => {
      await ophalenData(paginatie, true);
    },
    [ophalenData],
  );

  const keyExtractor = useCallback((item: IOphalenMutatiesResultElement) => item.BankMutID, []);
  const kolommen = useMemo<ASPKolom<EKolom, IOphalenMutatiesResultElement>[]>(
    () => [
      {
        key: EKolom.Bedrag,
        label: 'Bedrag',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 125,
        renderer: (item) => <FormatteerBedrag bedrag={item.Bedrag} />,
      },
      {
        key: EKolom.Omschrijving,
        label: 'Omschrijving',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 600,
        renderer: (item) => item.Omschrijving,
      },
      {
        key: EKolom.Rekeningnaam,
        label: 'Rekeningnaam',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 400,
        renderer: (item) => item.Rekeningnaam,
      },
      {
        key: EKolom.Betalingskenmerk,
        label: 'Betalingskenmerk',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 400,
        renderer: (item) => item.Betalingskenmerk,
      },
      {
        key: EKolom.Geboekt,
        label: 'Geboekt',
        breedteType: EAspKolomBreedteType.Flex,
        flex: 1,
        renderer: (item) =>
          item.Geboekt ? (
            <IconVink style={{ fill: Kleur.LichtGroen, width: 18, height: 18 }} />
          ) : (
            <IconKruis style={{ fill: Kleur.LichtRood, width: 18, height: 18 }} />
          ),
      },
    ],
    [],
  );

  return (
    <div
      style={{ ...props.style, borderTop: `1px solid ${Kleur.LichterGrijs}`, height: 500 }}
      className="d-flex flex-fill flex-row"
    >
      <TabelInspringBlok />
      <div className="d-flex flex-column flex-fill">
        {data.state === ERemoteDataState.Pending ? (
          <div className="d-flex flex-fill align-items-center justify-content-center">
            <LoadingSpinner />
          </div>
        ) : (
          <ASPTabel
            rijen={data.data!.mutaties}
            kolommen={kolommen}
            keyExtractor={keyExtractor}
            totaalAantalRijen={data.data!.totaalAantal}
            onExtraRijenAangevraagd={handleExtraRijenAangevraagd}
          />
        )}
      </div>
    </div>
  );
};

export default UitgeklapteRij;
