import React, { useContext, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { RootStoreContext } from '../../../../stores/RootStore';
import { ETransportopdrachtRegelsoort } from '../../../../bedrijfslogica/enums';
import { IOphalenOpdrachtregelsResultElementV2 } from '../../../../../../shared/src/api/v2/transport/opdracht';

interface IRegelRow extends IOphalenOpdrachtregelsResultElementV2 {}

interface IProps extends RouteComponentProps {
  rij: IRegelRow;
  magID: number;
  setKoppelLocatieproductDialoogState: (params: {
    trsRegID: number;
    locID: number | null;
    cntID: number | null;
  }) => void;
  setKoppelVoorraadDialoogState: (params: {
    trsRegID: number;
    cntID: number | null;
    magID: number;
  }) => void;
  onVerversen: () => void;
}

export interface IKoppelLocatieproductDialoogState {
  trsRegID: number;
  locID: number | null;
  cntID: number | null;
}

export interface IKoppelVoorraadDialoogState {
  trsRegID: number;
  cntID: number | null;
}

const ProductComp: React.FC<IProps> = observer((props) => {
  const { checkStore } = useContext(RootStoreContext);
  const rij = props.rij;

  const linktekst =
    rij.type === null && rij.product === null ? (
      <span>Nog Product/Type koppelen</span>
    ) : rij.type !== null ? (
      <span className="d-flex align-items-center">
        {(rij.type.Merkloos ? '' : rij.type.Merknaam + ' ') + rij.type.Typenaam}{' '}
      </span>
    ) : rij.product !== null ? (
      <span>{rij.product.producttype.Merknaam + ' ' + rij.product.producttype.Typenaam}</span>
    ) : (
      <span></span>
    );

  const cntID = rij.contract !== null ? rij.contract.CntID : null;

  return (
    <>
      <a
        href="#"
        // style={{ color: Kleur.DonkerGrijs }}
        onClick={() => {
          // Levering of Retour
          switch (rij.regelsoort.NaamEnum) {
            case ETransportopdrachtRegelsoort.Retour:
              props.setKoppelLocatieproductDialoogState({
                trsRegID: rij.TrsRegID,
                locID: rij.contract !== null ? rij.contract.basis.locatie.LocID : null,
                cntID,
              });
              break;
            case ETransportopdrachtRegelsoort.Levering:
              props.setKoppelVoorraadDialoogState({ trsRegID: rij.TrsRegID, cntID, magID: 4 });
              break;
          }
        }}
      >
        <span>{linktekst}</span>
      </a>
    </>
  );
});

export default withRouter(ProductComp);
