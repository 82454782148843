import { Field, FieldProps } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { IOphalenOpdrachtwijzenResultElement } from '../../../../../../../shared/src/api/v2/dienst';
import api from '../../../../../api';
import Combobox from '../../../../../components/formulier/Combobox';
import FormikVeldFout from '../../../../../components/formulier/FormikVeldFout';
import NumeriekVeld from '../../../../../components/formulier/NumeriekVeld';
import TijdVeld from '../../../../../components/formulier/TijdVeld';
import LoadingSpinner from '../../../../../components/Gedeeld/LoadingSpinner';
import nameof from '../../../../../core/nameOf';
import { IFormikValues, veldnamen } from '../../Magazijn/MutatieDialoog';

const MagazijnFormulier: React.FC = (props) => {
  const [opdrachtWijzen, setOpdrachtWijzen] = useState<
    IOphalenOpdrachtwijzenResultElement[] | null
  >(null);

  const ophalenOpdrachtwijzen = useCallback(async () => {
    const opdrachtwijzenResult = await api.v2.dienst.ophalenOpdrachtwijzen({
      filterSchema: { filters: [] },
    });

    setOpdrachtWijzen(opdrachtwijzenResult.opdrachtwijzen);
  }, []);

  useEffect(() => {
    ophalenOpdrachtwijzen();
  }, []);

  return (
    <div className="p-3">
      <div className="row">
        <div className="col-12">
          <label>{veldnamen.opdWijzeID}</label>
          <Field
            name={nameof<IFormikValues>('opdWijzeID')}
            render={(fieldProps: FieldProps<IFormikValues>) => {
              const { field, form } = fieldProps;

              if (opdrachtWijzen === null) {
                return <LoadingSpinner />;
              }

              return (
                <div>
                  <Combobox
                    geselecteerd={field.value}
                    opties={opdrachtWijzen.map((opdrachtwijze) => {
                      return {
                        id: opdrachtwijze.OpdWijzeID,
                        label: opdrachtwijze.Naam,
                      };
                    })}
                    onSelectieChange={(x) => form.setFieldValue(field.name, x)}
                  />
                </div>
              );
            }}
          />
        </div>

        <div className="col-12 mt-4">
          <label>{veldnamen.emailInventarisatie}</label>
          <Field
            name={nameof<IFormikValues>('emailInventarisatie')}
            render={(fieldProps: FieldProps) => {
              const { field, form } = fieldProps;
              return (
                <div>
                  <input className="form-control" {...fieldProps.field} />
                  <FormikVeldFout fieldProps={fieldProps} />
                </div>
              );
            }}
          />
        </div>
        <div className="col-12 mt-3">
          <label>{veldnamen.emailAfhaalopdrachten}</label>
          <Field
            name={nameof<IFormikValues>('emailAfhaalopdrachten')}
            render={(fieldProps: FieldProps) => {
              const { field, form } = fieldProps;
              return (
                <div>
                  <input className="form-control" {...fieldProps.field} />
                  <FormikVeldFout fieldProps={fieldProps} />
                </div>
              );
            }}
          />
        </div>
        <div className="col-12 mt-3">
          <label>{veldnamen.emailVooraanmelding}</label>
          <Field
            name={nameof<IFormikValues>('emailVooraanmelding')}
            render={(fieldProps: FieldProps) => {
              const { field, form } = fieldProps;
              return (
                <div>
                  <input className="form-control" {...fieldProps.field} />
                  <FormikVeldFout fieldProps={fieldProps} />
                </div>
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default MagazijnFormulier;
