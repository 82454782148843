import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  ILocatieSelecterenOfOpvoerenDialoogFormikValues,
  LocatieSelecterenOfOpvoerenDialoogContext,
  veldnamen,
} from '../index';
import { Field, FieldProps } from 'formik';
import nameof from '../../../../core/nameOf';
import AdresFormulier, { IData } from '../../../formulier/AdresFormulier';
import HorizontaleScheidingslijn from '../../../layout/HorizontaleScheidingslijn';
import VinkVeld from '../../../formulier/VinkVeld';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../models/IRemoteData';
import { IOphalenLocatiesResult } from '../../../../../../shared/src/api/v2/locatie/locatie';
import api from '../../../../api';
import { IFilterSchemaFilter } from '../../../../../../shared/src/models/filter';
import * as _ from 'lodash';
import { formatteerAdresV2 } from '../../../../helpers';
import { RootStoreContext } from '../../../../stores/RootStore';
import { EResultType } from '../../../../stores/CheckStore';

interface IProps {}

const SelecterenTabblad: React.FC<IProps> = (props) => {
  const { checkStore } = useContext(RootStoreContext);

  const context = useContext(LocatieSelecterenOfOpvoerenDialoogContext);
  const [adresData, setAdresData] = useState<IData | null>(null);

  const [suggestieLocaties, setSuggestieLocaties] = useState<IRemoteData<IOphalenLocatiesResult>>(
    createPendingRemoteData(),
  );

  const ophalenSuggestieLocaties = useCallback(async () => {
    if (adresData === null) {
      setSuggestieLocaties(createPendingRemoteData());
      return;
    }

    const postcode = adresData!.postcode.trim();
    const plaatsnaam = adresData!.plaatsnaam.trim();
    const bisnummer = adresData!.bisnummer.trim();
    const straatnaam = adresData!.straatnaam.trim();
    if (!(postcode.length >= 3 || plaatsnaam.length >= 3 || straatnaam.length >= 3)) {
      setSuggestieLocaties(createPendingRemoteData());
      return;
    }

    const result = await api.v2.locatie.ophalenLocaties({
      filterSchema: {
        filters: [
          postcode.length === 0
            ? null
            : {
                naam: 'POSTCODE_LIKE',
                data: postcode,
              },
          plaatsnaam.length === 0
            ? null
            : {
                naam: 'PLAATSNAAM_LIKE',
                data: plaatsnaam,
              },
          bisnummer.length === 0
            ? null
            : {
                naam: 'BISNUMMER_LIKE',
                data: bisnummer,
              },
          straatnaam.length === 0
            ? null
            : {
                naam: 'STRAATNAAM_LIKE',
                data: straatnaam,
              },
          adresData!.huisnummer.trim().length === 0 || isNaN(Number(adresData!.huisnummer))
            ? null
            : {
                naam: 'HUISNUMMER',
                data: Number(adresData!.huisnummer),
              },
          adresData!.landID === null
            ? null
            : {
                naam: 'LAND_IDS',
                data: [adresData!.landID],
              },
        ].filter((x) => x !== null) as IFilterSchemaFilter[],
      },
      paginatie: {
        index: 0,
        aantal: 5,
      },
    });

    setSuggestieLocaties(createReadyRemoteData(result));
  }, [adresData]);

  const ophalenSuggestiesDebounceFn = useRef<any>(null);
  useEffect(() => {
    if (ophalenSuggestiesDebounceFn.current !== null) {
      ophalenSuggestiesDebounceFn.current.cancel();
    }
    ophalenSuggestiesDebounceFn.current = _.debounce(() => ophalenSuggestieLocaties(), 500);
    ophalenSuggestiesDebounceFn.current();
  }, [ophalenSuggestieLocaties]);

  const isNieuweLocatie = useMemo(
    () =>
      suggestieLocaties.state === ERemoteDataState.Ready &&
      suggestieLocaties.data!.locaties.length === 0,
    [suggestieLocaties],
  );
  const suggestiesWeergeven = useMemo(
    () =>
      suggestieLocaties.state === ERemoteDataState.Ready &&
      suggestieLocaties.data!.locaties.length > 0 &&
      !isNieuweLocatie,
    [isNieuweLocatie, suggestieLocaties],
  );

  return (
    <div className="d-flex flex-column flex-fill p-3">
      <div className="row">
        <div className="col-12">
          <Field
            name={nameof<ILocatieSelecterenOfOpvoerenDialoogFormikValues>('adres')}
            render={(fieldProps: FieldProps<ILocatieSelecterenOfOpvoerenDialoogFormikValues>) => {
              const { field, form } = fieldProps;
              return (
                <AdresFormulier
                  values={field.value}
                  onChange={(x) => form.setFieldValue(field.name, x)}
                  onDataChange={(data) => setAdresData(data)}
                />
              );
            }}
          />
        </div>

        {suggestiesWeergeven && (
          <>
            <div className="col-12 mt-1">
              <HorizontaleScheidingslijn />
            </div>
            <div className="col-12 mt-3">
              <span className="text-muted font-weight-bold" style={{ fontSize: '.95rem' }}>
                Suggesties:
              </span>
            </div>
            <div className="col-12 d-flex align-items-center mt-3">
              <ul>
                {suggestieLocaties.state === ERemoteDataState.Ready &&
                suggestieLocaties.data!.locaties.length > 5 ? (
                  <span>Er zijn te veel suggesties gevonden</span>
                ) : (
                  suggestieLocaties.data!.locaties.map((locatie) => {
                    return (
                      <li key={locatie.LocID}>
                        <a
                          href="#"
                          onClick={async (ev) => {
                            if (
                              (
                                await checkStore.bevestigen({
                                  inhoud: `Locatie wijzigen?`,
                                })
                              ).type === EResultType.Annuleren
                            ) {
                              return;
                            }

                            ev.preventDefault();
                            ev.stopPropagation();

                            context.onSuccess({
                              locID: locatie.LocID,
                            });
                          }}
                        >
                          {formatteerAdresV2({
                            bisnummer: locatie.Bisnummer,
                            huisnummer: locatie.Huisnummer,
                            postcode: locatie.Postcode,
                            straatnaam: locatie.Straatnaam,
                            plaatsnaam: locatie.Plaatsnaam,
                            landnaamKort: locatie.LandnaamKort,
                            landnaamEnum: locatie.LandnaamEnum,
                          })}
                        </a>
                      </li>
                    );
                  })
                )}
              </ul>
            </div>
          </>
        )}
        {isNieuweLocatie && (
          <>
            <div className="col-12 mt-1">
              <HorizontaleScheidingslijn />
            </div>
            <div className="col-12 mt-3">
              <span className="text-muted font-weight-bold" style={{ fontSize: '.95rem' }}>
                Gegevens van nieuwe locatie:
              </span>
            </div>
            <div className="col-12 d-flex align-items-center mt-3">
              <Field
                name="liftAanwezig"
                render={(
                  fieldProps: FieldProps<ILocatieSelecterenOfOpvoerenDialoogFormikValues>,
                ) => {
                  const { field, form } = fieldProps;
                  return (
                    <VinkVeld
                      aangevinkt={field.value}
                      onGewijzigd={(x) => {
                        form.setFieldValue(field.name, x);
                      }}
                    />
                  );
                }}
              />
              <span className="ml-2">{veldnamen.liftAanwezig}</span>
            </div>
            <div className="col-12 mt-2">
              <label>{veldnamen.bezoekinstructies}</label>
              <Field
                name="bezoekinstructies"
                render={(
                  fieldProps: FieldProps<ILocatieSelecterenOfOpvoerenDialoogFormikValues>,
                ) => {
                  const { field, form } = fieldProps;
                  return (
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Past niet door de deur"
                      {...field}
                      // disabled={bestaandeLocatieBepaald.state === ERemoteDataState.Pending}
                    />
                  );
                }}
              />
            </div>

            <div className="col-12 mt-2">
              <label>{veldnamen.bijzonderheden}</label>
              <Field
                name="bijzonderheden"
                render={(
                  fieldProps: FieldProps<ILocatieSelecterenOfOpvoerenDialoogFormikValues>,
                ) => {
                  const { field, form } = fieldProps;
                  return (
                    <textarea
                      rows={6}
                      className="form-control"
                      placeholder="De hond blaft veel"
                      {...field}
                      // disabled={bestaandeLocatieBepaald.state === ERemoteDataState.Pending}
                    />
                  );
                }}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SelecterenTabblad;
