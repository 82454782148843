import { useEffect } from 'react';
import { selectieCheck } from '../helpers/selectie';

export interface IUseSelectieCheckParams<TKey> {
  selectie: TKey[];
  onSelectieChange: (selectie: TKey[]) => void;
  sleutels: TKey[] | null;
}

const useSelectieCheck = <TKey>(params: IUseSelectieCheckParams<TKey>) => {
  useEffect(() => {
    if (params.sleutels === null) {
      // params.onSelectieChange([]);
      return;
    }
    const selectie = selectieCheck(params.sleutels, params.selectie);
    if (selectie !== null) {
      params.onSelectieChange(selectie);
    }
  }, [params.selectie, params.onSelectieChange, params.sleutels]);
};

export default useSelectieCheck;
