import React, { useContext, useEffect, useMemo } from 'react';
import api from '../../../../api';
import { observer } from 'mobx-react-lite';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Kleur as EKleur } from '../../../../bedrijfslogica/constanten';
import { EResultType } from '../../../../stores/CheckStore';
import { RootStoreContext } from '../../../../stores/RootStore';
import { IOphalenOpdrachtregelsResultElementV2 } from '../../../../../../shared/src/api/v2/transport/opdracht';

interface IRegel extends IOphalenOpdrachtregelsResultElementV2 {}

interface IProps extends RouteComponentProps {
  rij: IRegel;
}

const ProductWeergaveComp: React.FC<IProps> = observer((props) => {
  const { checkStore } = useContext(RootStoreContext);

  const rij: IRegel = props.rij;

  // Tekst die aangeeft welk product gekoppeld is/moet worden
  // Bij geen koppeling en Levering wordt het primaire type weergegeven

  let koppeltekst = <span>Nog Product/Type koppelen</span>;

  if (rij.product !== null) {
    // Product
    koppeltekst = (
      <span>{rij.product.producttype.Merknaam + ' ' + rij.product.producttype.Typenaam}</span>
    );
  } else {
    if (rij.type !== null) {
      // Type (bulk en geen bulk)
      koppeltekst = (
        <span className="d-flex align-items-center">
          {(rij.type.Merkloos ? '' : rij.type.Merknaam + ' ') + rij.type.Typenaam}{' '}
        </span>
      );
    } else {
      if (rij.contract !== null) {
        if (rij.regelsoort.NaamEnum === 'LEVERING') {
          const primairType = rij.contract.basis.productmodel.producttypen[0];
          koppeltekst = (
            <span>
              [{primairType.Merknaam} {primairType.Typenaam}]
            </span>
          );
        } else {
          koppeltekst = <span></span>;
        }
      } else {
        koppeltekst = <span></span>;
      }
    }
  }

  return <span>{koppeltekst}</span>;
});

export default withRouter(ProductWeergaveComp);
