import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IUitgeklapteRijProps } from '../../../../tabel/ASPTabel/Body/UitgeklapteRij';
import { ELeveringsvoorwaardenKolom } from '../index';
import {
  ILeveringsvoorwaardenBijlage,
  IOphalenLeveringsvoorwaardenBijlagenResult,
  IOphalenLeveringsvoorwaardenResultElement,
} from '../../../../../../../shared/src/api/v2/relatie/leveringsvoorwaarden';
import useUpload, { IUseUploadParams } from '../../../../../core/useUpload';
import BijlagenContainer, {
  Bestand,
  BestandType,
  EWeergaveFocus,
  IExternBestand,
} from '../../../../BijlagenContainer';
import BijlageKnop, { EOrientatie, ESelectieModus } from '../../../../BijlageKnop';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../../models/IRemoteData';
import api from '../../../../../api';
import { LoadingSpinnerCenter } from '../../../../Gedeeld/LoadingSpinner';
import { IOphalenBestandenResultElement } from '../../../../../../../shared/src/api/v2/bestand/bestand';

interface IData {
  bijlagen: ILeveringsvoorwaardenBijlage[];
  bestanden: IOphalenBestandenResultElement[];
}

const UitgeklapteRijWrapper = (
  props: IUitgeklapteRijProps<
    ELeveringsvoorwaardenKolom,
    IOphalenLeveringsvoorwaardenResultElement
  >,
) => {
  const [data, setData] = useState<IRemoteData<IData>>(createPendingRemoteData());
  const ophalenBijlagen = useCallback(async () => {
    const result = await api.v2.relatie.leveringsvoorwaarden.ophalenLeveringsvoorwaardenBijlagen({
      filterSchema: {
        filters: [
          {
            naam: 'LEVERINGSVOORWAARDEN_IDS',
            data: [props.regel.ID],
          },
        ],
      },
    });

    if (result.bijlagen.length === 0) {
      const data: IData = {
        bijlagen: [],
        bestanden: [],
      };
      setData(createReadyRemoteData(data));
      return;
    }

    const bestandIDs = result.bijlagen.map((bijlage) => bijlage.BestandID);
    const bestandenResult = await api.v2.bestand.ophalenBestanden({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: bestandIDs,
          },
        ],
      },
    });

    const data: IData = {
      bijlagen: result.bijlagen,
      bestanden: bestandenResult.bestanden,
    };

    setData(createReadyRemoteData(data));
  }, [props.regel.ID]);

  useEffect(() => {
    ophalenBijlagen();
  }, [ophalenBijlagen]);

  if (data.state === ERemoteDataState.Pending) {
    return (
      <div
        style={props.style}
        className="d-flex align-items-center flex-row justify-content-center"
      >
        <LoadingSpinnerCenter />
      </div>
    );
  }
  return <UitgeklapteRijInner {...props} data={data.data!} />;
};

interface IInnerProps
  extends IUitgeklapteRijProps<
    ELeveringsvoorwaardenKolom,
    IOphalenLeveringsvoorwaardenResultElement
  > {
  data: IData;
}

const UitgeklapteRijInner = (props: IInnerProps) => {
  const useUploadParams = useMemo<IUseUploadParams>(() => {
    const bestanden = props.data.bestanden.map(
      (bestand): IExternBestand => ({
        type: BestandType.ASPDrive,
        bestand,
      }),
    );

    return {
      automatischUploaden: true,
      initieleBestanden: bestanden,
      onAlleBestandenGeuploaded: async (bestandIDs) => {
        await api.v2.relatie.leveringsvoorwaarden.wijzigenLeveringsvoorwaardenBijlagen({
          leveringsvoorwaardenID: props.regel.ID,
          bestandIDs,
        });
      },
    };
  }, [props.regel.ID, props.data.bestanden]);
  const { isBezigMetUploaden, bestanden, muteerBestanden, uploadProgresses } = useUpload(
    useUploadParams,
  );

  return (
    <div style={props.style} className="d-flex pl-4 pr-4 pt-2 pb-2">
      <BijlageKnop
        onBijgevoegd={(bijlagen) => muteerBestanden((bestanden) => [...bestanden, ...bijlagen])}
        orientatie={EOrientatie.Horizontaal}
        selectieModus={ESelectieModus.Meerdere}
        disabled={isBezigMetUploaden}
      />
      <div className="mt-2" />
      <BijlagenContainer
        bestanden={bestanden}
        uploadProgresses={uploadProgresses}
        onBestandenChange={async (bestanden) => {
          await muteerBestanden((x) => bestanden);
        }}
        weergaveFocus={EWeergaveFocus.Visualisatie}
        bestandenMuterenToegestaan={!isBezigMetUploaden}
        geenBestandenWeergaveComponent={() => <span>Geen bijlagen gekozen</span>}
      />
    </div>
  );
};

export default UitgeklapteRijWrapper;
