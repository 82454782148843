import { IRelatie } from '../models/relatie';
import { format, isEqual } from 'date-fns';
import { HUISMODEL, ORGANISATIE, PERSOON } from '../bedrijfslogica/constanten';
import { addMonths, parseISO, isBefore } from 'date-fns';
import { useMemo } from 'react';
import { IOphalenRelatiesResultElementV2 } from '../../../shared/src/api/v2/relatie';
import UitlegTooltip from '../components/formulier/UitlegTooltip';

export interface IProlongatieRichting {
  Start: Date | string | null;
  Eind: Date | string | null;
  Tot: Date | string | null;
}

interface IFormatteerPersoonNaamParams {
  voornaam: string | null | undefined;
  achternaam: string | undefined;
  aanhefKort: string | undefined;
  voorletters: string | null | undefined;
  voorvoegsel: string | null | undefined;
}

interface IFormatteerRelatieNaamParams extends IFormatteerPersoonNaamParams {
  relatiesoort?: undefined | string | null;
  organisatienaam?: undefined | string | null;
}

interface IFormatteerAdresParams {
  bisnummer?: string | null;
  straatnaam?: string | null;
  huisnummer?: number | null;
  postcode?: string | null;
  plaatsnaam?: string | null;
  landnaamKort?: string | null;
  landnaamEnum?: string | null;
}

export const formatteerPersoonNaam = (params: IFormatteerPersoonNaamParams): string => {
  if (params.achternaam === undefined) {
    return '';
  }
  const voornaam = params.voornaam !== null ? ` (${params.voornaam})` : '';
  const achternaam = params.achternaam;
  const aanhefKort = params.aanhefKort;
  const voorletters = params.voorletters !== null ? ` ${params.voorletters}` : '';
  const voorvoegsel = params.voorvoegsel !== null ? ` ${params.voorvoegsel}` : '';
  return `${achternaam}, ${aanhefKort}${voorletters}${voorvoegsel}${voornaam}`;
};

interface IFormatteerOrganisatieNaamParams {
  naam: string | null;
}

export const formatteerOrganisatieNaam = (params: IFormatteerOrganisatieNaamParams): string => {
  return params.naam || '';
};

export const formatteerRelatieNaam = (params: IFormatteerRelatieNaamParams): string => {
  if (params.relatiesoort === ORGANISATIE) {
    return formatteerOrganisatieNaam({ naam: params.organisatienaam! });
  } else {
    return formatteerPersoonNaam(params);
  }
};

export const formatteerAdresV2 = (params: IFormatteerAdresParams): string => {
  if (params.plaatsnaam === null) {
    return 'Adres niet opgegeven';
  }

  const bisnummer = params.bisnummer || '';
  const straatnaam = params.straatnaam || '';
  const huisnummer = params.huisnummer || '';
  const postcode = params.postcode || '';
  const plaatsnaam = params.plaatsnaam || '';

  let huisnummerBis;
  if (bisnummer !== '') {
    if (bisnummer.charAt(0) === '-') {
      // Met streepje

      huisnummerBis = `${huisnummer}${params.bisnummer}`;
    } else {
      // Met spatie
      huisnummerBis = `${huisnummer} ${bisnummer}`;
    }
  } else {
    huisnummerBis = `${huisnummer}`;
  }

  const land =
    params.landnaamEnum !== undefined && params.landnaamEnum !== 'NL'
      ? `(${params.landnaamKort})`
      : '';
  const adres = `${straatnaam} ${huisnummerBis}, ${postcode} ${plaatsnaam} ${land}`.trim();

  return adres;
};

export const formatteerLand = (relatie: IOphalenRelatiesResultElementV2) => {
  if (!relatie.adres!.LandnaamEnum) {
    return '';
  }
  if (relatie.adres!.LandnaamEnum !== 'NL') {
    return `(${relatie.adres!.LandnaamKort})`;
  }
};

export const formatteerBestandsgrootte = (fileSizeInBytes: number) => {
  let i = -1;
  const byteUnits = ['K', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'];
  while (fileSizeInBytes > 1024) {
    fileSizeInBytes = fileSizeInBytes / 1024;
    i++;
  }

  if (i === -1) {
    return `${fileSizeInBytes} bytes`;
  }

  return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i] + 'B';
};

export const formatteerBedrag = (value: number) => {
  const isNegatief = value < 0;
  const absWaarde = Math.abs(value);
  const euros = Math.floor(absWaarde);
  const centen = Math.round((absWaarde - euros) * 100);

  const eurosMetDuizendtallen = (() => {
    const str = String(euros);
    return str
      .split('')
      .reverse()
      .map((x, i) => {
        if (i !== 0 && i % 3 === 0) {
          return `${x}.`;
        }
        return x;
      })
      .reverse()
      .join('');
  })();

  return `${isNegatief ? '-' : ''}€ ${eurosMetDuizendtallen},${
    centen === 0 ? '-' : String(centen).padStart(2, '0')
  }`;

  // const decimaal = `${Number(bedrag).toFixed(2)}`;
  // const metKomma = decimaal.replace('.', ',');
  // return `€ ${metKomma}`;
};

export const formatteerBoolean = (waarde: boolean) => {
  return waarde ? 'Ja' : 'Nee';
};

export const formatteerBedragZonderEuro = (bedrag: number) => {
  const decimaal = `${Number(bedrag).toFixed(2)}`;
  const metKomma = decimaal.replace('.', ',');
  return `${metKomma}`;
};

export const afgerond = (bedrag: number) => {
  const afgerondBedrag = Math.round(bedrag * 100) / 100;
  return afgerondBedrag;
};

interface IProduct {
  typenaam: string;
  merknaam: string;
  productsoortnaam: string;
  referentieCode: string;
}

// export const formatteerProduct = (product: IProduct) => {
// };

export const berekenToeslag = (
  aantalGebruikers: number,
  AantalGebruikersTarief: number,
  AantalGebruikersMax: number,
) => {
  if (aantalGebruikers > AantalGebruikersTarief && AantalGebruikersTarief !== 0) {
    return (aantalGebruikers - AantalGebruikersTarief) * 2;
  } else {
    return 0;
  }
};

interface IProductmodelIdentificatieParams {
  modelnaam: string | null;
  huismodel: boolean;
  productsoortnaam: string;
  merknaam: string;
}

export const productmodelIdentificatie = (params: IProductmodelIdentificatieParams) => {
  if (params.modelnaam === null) {
    return `${params.huismodel ? HUISMODEL : params.merknaam} ${params.productsoortnaam}`;
  } else {
    return `${params.modelnaam}`;
  }
};

interface IBerekenOpzegbaarParams {
  ingangsdatum?: any;
  minimaletermijn?: any;
  minimaleEinddatum?: Date;
}

export const berekenOpzegbaar = (params: IBerekenOpzegbaarParams): string => {
  let minimaleDatum;
  if (params.minimaleEinddatum) {
    minimaleDatum = params.minimaleEinddatum;
  } else {
    minimaleDatum = addMonths(parseISO(params.ingangsdatum), params.minimaletermijn);
  }
  if (isBefore(minimaleDatum, new Date()) || isEqual(minimaleDatum, new Date())) {
    return 'Opzegbaar';
  } else {
    return format(minimaleDatum, '(dd-MM-yyyy)');
  }
};

export interface IStringOfBijLeegOptions {
  nietTrimmen?: boolean;
}
const defaultStringOBijLeegOptions: IStringOfBijLeegOptions = {
  nietTrimmen: false,
};

/**
 * Pakt een string, als dit een lege tekenreeks is, gebruik de fallback, anders behoudt de
 * doorgegeven string
 * @param value De string om testen
 * @param fallback De terugval waarde
 * @param options Opties om deze functionaliteit te beinvloeden
 */
export const stringOfBijLeeg = <T>(
  value: string,
  fallback: T,
  options?: IStringOfBijLeegOptions,
) => {
  const opt = { ...defaultStringOBijLeegOptions, ...options };
  let x: string = value;
  if (!opt.nietTrimmen) {
    x = x.trim();
  }

  const isEmpty = x === '';
  return isEmpty ? fallback : x;
};
