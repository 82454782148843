import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IFormulierComponentProps, ISoortData } from './index';
import { Field, FieldProps, Formik, FormikErrors } from 'formik';
import api from '../../../../../api';
import MultiCombobox, { IKolom } from '../../../../../components/formulier/MultiCombobox';
import FormikVeldFout from '../../../../../components/formulier/FormikVeldFout';
import nameOf from '../../../../../core/nameOf';
import LoadingSpinner from '../../../../../components/Gedeeld/LoadingSpinner';
import NumeriekVeld from '../../../../../components/formulier/NumeriekVeld';
import InactiefOverlay from '../../../../../components/InactiefOverlay';
import VinkVeld from '../../../../../components/formulier/VinkVeld';
import UitlegTooltip from '../../../../../components/formulier/UitlegTooltip';
import { IOphalenProductsoortenResultElement } from '../../../../../../../shared/src/api/v2/product/soort';
import { IOphalenAbonnementenResultElement } from '../../../../../../../shared/src/api/v2/aanbod/Abonnement';

interface IProps extends IFormulierComponentProps<ISoortData> {
  relID: number | null;
  data: ISoortData | null;
  onChange: (data: ISoortData | null) => void;
}

interface IFormikValues {
  prodSrtID: number | null;
  duurInMaandenActief: boolean;
  duurInMaanden: number | null;
  kortingspercentage: number | null;
  vanafAbonID: number | null;
  heeftActieOpEenmaligBedrag: boolean;
  heeftActieOpMaandhuur: boolean;
}

const SoortFormulier: React.FC<IProps> = (props) => {
  const [productsoorten, setProductsoorten] = useState<
    IOphalenProductsoortenResultElement[] | null
  >(null);
  const [abonnementen, setAbonnementen] = useState<IOphalenAbonnementenResultElement[] | null>(
    null,
  );

  const ophalenProductsoorten = useCallback(async () => {
    const result = await api.v2.product.soort.ophalenProductsoorten({
      filterSchema: {
        filters: [{ naam: 'ACTIEF', data: true }],
      },
    });

    setProductsoorten(result);
  }, []);

  useEffect(() => {
    ophalenProductsoorten();
  }, [ophalenProductsoorten]);

  const ophalenAbonnementen = useCallback(async () => {
    const result = await api.v2.abonnement.ophalenAbonnementen({
      filterSchema: {
        filters: [{ naam: 'ACTIEF', data: true }],
      },
    });

    setAbonnementen(result.abonnementen);
  }, []);

  useEffect(() => {
    ophalenAbonnementen();
  }, [ophalenAbonnementen]);

  const productsoortKolommen = useMemo<IKolom<IOphalenProductsoortenResultElement>[]>(() => {
    return [
      {
        key: 'Naam',
        label: 'Naam',
        breedte: 250,
      },
    ];
  }, []);

  const abonnementKolommen = useMemo<IKolom<IOphalenAbonnementenResultElement>[]>(() => {
    return [
      {
        key: 'Naam',
        label: 'Naam',
        breedte: 100,
      },
      {
        key: 'MinimaleTermijn',
        label: 'Minimale termijn',
        breedte: 125,
      },
    ];
  }, []);

  const initieleWaarden: IFormikValues = useMemo(() => {
    return {
      prodSrtID: null,
      duurInMaandenActief: true,
      duurInMaanden: 1,
      kortingspercentage: 100,
      vanafAbonID: null,
      heeftActieOpEenmaligBedrag: false,
      heeftActieOpMaandhuur: true,
    };
  }, []);

  // if (aanbod === null) {
  //   return (
  //     <div className="d-flex align-items-center justify-content-center flex-fill">
  //       <LoadingSpinner />
  //     </div>
  //   );
  // }

  return (
    <Formik<IFormikValues>
      initialValues={initieleWaarden}
      onSubmit={() => {}}
      validate={(values: IFormikValues) => {
        const errors: FormikErrors<IFormikValues> = {};

        // if (values.prodSrtID === null) {
        //   errors.prodSrtID = 'Veld is verplicht.';
        // }
        if (values.vanafAbonID === null) {
          errors.vanafAbonID = 'Veld is verplicht.';
        }
        if (!values.duurInMaandenActief && values.kortingspercentage === 100) {
          errors.kortingspercentage =
            'Een kortingspercentage van 100% (gratis) is niet mogelijk zonder gedurende looptijd.';
        }

        if (Object.keys(errors).length > 0) {
          props.onChange(null);
        } else {
          const vanafAbonnement = abonnementen!.find((x) => x.AbonID === values.vanafAbonID!)!;

          const abonIDs = abonnementen!
            .filter((abonnement) => abonnement.MinimaleTermijn >= vanafAbonnement.MinimaleTermijn)
            .map((x) => x.AbonID);

          props.onChange({
            prodSrtID: values.prodSrtID!,
            abonIDs,
            heeftActieOpMaandhuur: values.heeftActieOpMaandhuur,
            actieOpMaandhuur: values.heeftActieOpMaandhuur
              ? {
                  duurInMaanden: values.duurInMaanden!,
                  kortingspercentage: values.kortingspercentage!,
                }
              : null,
            heeftActieOpEenmaligBedrag: values.heeftActieOpEenmaligBedrag,
          });
        }

        return errors;
      }}
      render={(formikProps) => {
        const { values } = formikProps;

        return (
          <div className="form-group">
            <div className="row">
              <div className="col-12">
                <label>Productsoort</label>
                <Field
                  name={nameOf<IFormikValues>('prodSrtID')}
                  render={(fieldProps: FieldProps<IFormikValues>) => {
                    const { field, form } = fieldProps;
                    return (
                      <>
                        <MultiCombobox<number, IOphalenProductsoortenResultElement>
                          sleutelExtractor={(row) => row.ProdSrtID}
                          onWaardeChange={(waarde: number | null) => {
                            form.setFieldValue(field.name, waarde);
                          }}
                          representatieFabriek={(row) => `${row.Naam}`}
                          waarde={fieldProps.field.value}
                          opties={productsoorten!}
                          kolommen={productsoortKolommen}
                          options={{
                            geenWaardeBericht: 'Alle productsoorten',
                          }}
                        />
                        <FormikVeldFout fieldProps={fieldProps} />
                      </>
                    );
                  }}
                />
              </div>

              <div className="col-12 mt-3">
                <label>
                  <div className="d-flex">
                    Vanaf abonnement
                    <span className="ml-2">
                      <UitlegTooltip
                        inhoud={
                          <span>
                            Vanaf het geselecteerde abonnement en omhoog (abonnement met een langere
                            looptijd) zal de actie worden toegepast.
                          </span>
                        }
                      />
                    </span>
                  </div>
                </label>
                <Field
                  name={nameOf<IFormikValues>('vanafAbonID')}
                  render={(fieldProps: FieldProps<IFormikValues>) => {
                    const { field, form } = fieldProps;
                    return (
                      <div>
                        <InactiefOverlay
                          // isInactief={form.values.prodSrtID === null}
                          isInactief={false}
                          element={
                            <>
                              <MultiCombobox<number, IOphalenAbonnementenResultElement>
                                sleutelExtractor={(row) => row.AbonID}
                                onWaardeChange={(waarde: number | null) =>
                                  fieldProps.form.setFieldValue(fieldProps.field.name, waarde)
                                }
                                representatieFabriek={(row) => row.Naam}
                                waarde={fieldProps.field.value}
                                opties={abonnementen}
                                kolommen={abonnementKolommen}
                              />
                            </>
                          }
                        />

                        <FormikVeldFout fieldProps={fieldProps} />
                      </div>
                    );
                  }}
                />
              </div>

              <div className="row d-flex col-12">
                <div className="col-6 mt-3">
                  <div>
                    <Field
                      name={nameOf<IFormikValues>('heeftActieOpMaandhuur')}
                      render={(fieldProps: FieldProps<IFormikValues>) => {
                        const { field, form } = fieldProps;

                        return (
                          <>
                            <div className="d-flex align-items-center">
                              <VinkVeld
                                aangevinkt={field.value}
                                onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                              />
                              <span className="ml-2">Actie op de maandhuur</span>
                            </div>
                            <FormikVeldFout fieldProps={fieldProps} />
                          </>
                        );
                      }}
                    />
                  </div>
                  <div className="col-12 mt-3">
                    <label>
                      <div className="d-flex">
                        Duur in maanden
                        <span className="ml-2">
                          <UitlegTooltip
                            inhoud={
                              <span>
                                Bij het niet toepassen van dit veld wordt de actie toegepast voor de
                                gedurende looptijd van het contract.
                              </span>
                            }
                          />
                        </span>
                      </div>
                    </label>
                    <Field
                      name={nameOf<IFormikValues>('duurInMaanden')}
                      render={(fieldProps: FieldProps<IFormikValues>) => {
                        const { field, form } = fieldProps;
                        return (
                          <>
                            <div className="d-flex align-items-center">
                              <VinkVeld
                                aangevinkt={form.values.duurInMaandenActief}
                                onGewijzigd={(aangevinkt) =>
                                  form.setFieldValue(
                                    nameOf<IFormikValues>('duurInMaandenActief'),
                                    aangevinkt,
                                  )
                                }
                              />
                              <span className="ml-2">
                                <InactiefOverlay
                                  isInactief={!form.values.duurInMaandenActief}
                                  element={
                                    <NumeriekVeld
                                      min={1}
                                      max={12}
                                      waarde={field.value}
                                      onChange={(x) => form.setFieldValue(field.name, x)}
                                    />
                                  }
                                />
                              </span>
                            </div>
                            <FormikVeldFout fieldProps={fieldProps} />
                          </>
                        );
                      }}
                    />
                  </div>
                  <div className="col-12 mt-3">
                    <label>Kortingspercentage</label>
                    <Field
                      name={nameOf<IFormikValues>('kortingspercentage')}
                      render={(fieldProps: FieldProps<IFormikValues>) => {
                        const { field, form } = fieldProps;
                        return (
                          <>
                            <NumeriekVeld
                              min={1}
                              max={100}
                              waarde={field.value}
                              onChange={(x) => form.setFieldValue(field.name, x)}
                              inactiefRepresentatie={(input) => {
                                if (field.value === 100) {
                                  return '100% (gratis)';
                                }
                                return `${field.value}%`;
                              }}
                            />
                            <FormikVeldFout fieldProps={fieldProps} />
                          </>
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="d-flex col-6 mt-3">
                  <div>
                    <Field
                      name={nameOf<IFormikValues>('heeftActieOpEenmaligBedrag')}
                      render={(fieldProps: FieldProps<IFormikValues>) => {
                        const { field, form } = fieldProps;

                        return (
                          <>
                            <div className="d-flex align-items-center">
                              <VinkVeld
                                aangevinkt={field.value}
                                onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                              />
                              <span className="ml-2">Geen eenmalig bedrag</span>
                            </div>
                            <FormikVeldFout fieldProps={fieldProps} />
                          </>
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }}
    />
  );
};

export default SoortFormulier;
