import React, { useCallback, useContext, useMemo } from 'react';
import IDialoogProps from '../../../../core/IDialoogProps';
import { Modal, ModalBody, ModalFooter, ModalTitle } from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/ModalHeader';
import Dialoog from '../../../../components/dialogen/Dialoog';
import { Field, FieldProps, Formik, FormikActions, FormikErrors } from 'formik';
import api from '../../../../api';
import { RootStoreContext } from '../../../../stores/RootStore';
import { EResultType } from '../../../../stores/CheckStore';
import nameOf from '../../../../core/nameOf';
import FormikVeldFout from '../../../../components/formulier/FormikVeldFout';
import VinkVeld from '../../../../components/formulier/VinkVeld';
import organisatie from '../../../../api/v2/organisatie';
import { dagDatum } from '../../../../helpers/datum';
import DatumKiezer from '../../../../components/formulier/DatumKiezer';

interface IProps extends IDialoogProps {}

interface IFormikValues {
  datum: Date;
  onderwerp: string | null;
  inhoud: string | null;
  internInitiatief: boolean;
  aanvrager: string | null;
  organisatie: string | null;
  email: string | null;
  telefoonnummer: string | null;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  datum: 'Datum',
  onderwerp: 'Onderwerp',
  inhoud: 'Inhoud',
  internInitiatief: 'Intern initiatief',
  aanvrager: 'Aanvrager',
  organisatie: 'Organisatie',
  email: 'Email',
  telefoonnummer: 'Telefoon',
};

const NieuwVoorstelDialoog: React.FC<IProps> = (props) => {
  const { checkStore } = useContext(RootStoreContext);

  const { onAnnuleren, onSuccess, open } = props;

  const initialValues = useMemo<IFormikValues>(() => {
    return {
      datum: dagDatum(new Date()),
      inhoud: null,
      onderwerp: null,
      internInitiatief: false,
      aanvrager: null,
      organisatie: null,
      email: null,
      telefoonnummer: null,
    };
  }, []);

  const validate = useCallback((values: IFormikValues): FormikErrors<IFormikValues> => {
    const errors: FormikErrors<IFormikValues> = {};

    if (values.onderwerp === null) {
      errors.onderwerp = 'Verplicht';
    }

    return errors;
  }, []);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      const params = {
        datum: values.datum,
        onderwerp: values.onderwerp!,
        inhoud: values.inhoud,
        internInitiatief: values.internInitiatief,
        naamAanvrager: values.aanvrager,
        naamOrganisatie: values.organisatie,
        email: values.email,
        telefoon: values.telefoonnummer,
      };

      const checkData = await api.v2.sponsoring.voorstel.checkToevoegenVoorstel(params);

      const controleResult = await checkStore.controleren({ checkData });
      if (controleResult.type === EResultType.Annuleren) {
        return;
      }

      const resultaat = await checkStore.bevestigen({
        inhoud: `Voorstel toevoegen?`,
      });
      if (resultaat.type === EResultType.Annuleren) {
        return;
      }

      await api.v2.sponsoring.voorstel.toevoegenVoorstel(params);

      props.onSuccess({});
    },
    [],
  );

  return (
    <Dialoog index={props.dialoogIndex || 0} modalProps={{ size: 'lg' }}>
      <ModalHeader>
        <ModalTitle>Nieuw sponsorvoorstel</ModalTitle>
      </ModalHeader>
      <Formik<IFormikValues>
        onSubmit={handleSubmit}
        validate={validate}
        initialValues={initialValues}
        render={(formikProps) => {
          const { submitForm, isSubmitting } = formikProps;
          return (
            <>
              <ModalBody className="p-4">
                <div className="row">
                  <div className="col-6">
                    <label>{veldnamen.datum}</label>
                    <Field
                      name={nameOf<IFormikValues>('datum')}
                      render={(fieldProps: FieldProps<IFormikValues>) => {
                        const { field, form } = fieldProps;
                        return (
                          <>
                            <DatumKiezer
                              waarde={field.value}
                              onGewijzigd={(data) => form.setFieldValue(field.name, data)}
                              determinePreviousValidDate="ONBEGRENST"
                              determineNextValidDate="ONBEGRENST"
                            />
                            <FormikVeldFout fieldProps={fieldProps} />
                          </>
                        );
                      }}
                    />
                  </div>
                  <div className="col-6 d-flex">
                    <Field
                      name={nameOf<IFormikValues>('internInitiatief')}
                      render={(fieldProps: FieldProps<IFormikValues>) => {
                        const { field, form } = fieldProps;
                        return (
                          <>
                            <div className="d-flex align-items-center flex-fill">
                              <VinkVeld
                                aangevinkt={field.value}
                                onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                              />
                              <span className="ml-2">{veldnamen.internInitiatief}</span>
                            </div>
                            <FormikVeldFout fieldProps={fieldProps} />
                          </>
                        );
                      }}
                    />
                  </div>
                  <div className="col-12 mt-3">
                    <label>{veldnamen.onderwerp}</label>
                    <Field
                      name={nameOf<IFormikValues>('onderwerp')}
                      render={(fieldProps: FieldProps<IFormikValues>) => {
                        const { field, form } = fieldProps;
                        return (
                          <div>
                            <input className="form-control" type="text" {...field} />
                            <FormikVeldFout fieldProps={fieldProps} />
                          </div>
                        );
                      }}
                    />
                  </div>
                  <div className="col-12 mt-3">
                    <label>{veldnamen.inhoud}</label>
                    <Field
                      name={nameOf<IFormikValues>('inhoud')}
                      render={(fieldProps: FieldProps<IFormikValues>) => {
                        const { field, form } = fieldProps;
                        return (
                          <div>
                            <textarea className="form-control" {...field} rows={3} />
                            <FormikVeldFout fieldProps={fieldProps} />
                          </div>
                        );
                      }}
                    />
                  </div>
                  <div className="col-6 mt-3">
                    <label>{veldnamen.aanvrager}</label>
                    <Field
                      name={nameOf<IFormikValues>('aanvrager')}
                      render={(fieldProps: FieldProps<IFormikValues>) => {
                        const { field, form } = fieldProps;
                        return (
                          <div>
                            <input className="form-control" type="text" {...field} />
                            <FormikVeldFout fieldProps={fieldProps} />
                          </div>
                        );
                      }}
                    />
                  </div>

                  <div className="col-6 mt-3">
                    <label>{veldnamen.organisatie}</label>
                    <Field
                      name={nameOf<IFormikValues>('organisatie')}
                      render={(fieldProps: FieldProps<IFormikValues>) => {
                        const { field, form } = fieldProps;
                        return (
                          <div>
                            <input className="form-control" type="text" {...field} />
                            <FormikVeldFout fieldProps={fieldProps} />
                          </div>
                        );
                      }}
                    />
                  </div>

                  <div className="col-6 mt-3">
                    <label>{veldnamen.email}</label>
                    <Field
                      name={nameOf<IFormikValues>('email')}
                      render={(fieldProps: FieldProps<IFormikValues>) => {
                        const { field, form } = fieldProps;
                        return (
                          <div>
                            <input className="form-control" type="text" {...field} />
                            <FormikVeldFout fieldProps={fieldProps} />
                          </div>
                        );
                      }}
                    />
                  </div>

                  <div className="col-6 mt-3">
                    <label>{veldnamen.telefoonnummer}</label>
                    <Field
                      name={nameOf<IFormikValues>('telefoonnummer')}
                      render={(fieldProps: FieldProps<IFormikValues>) => {
                        const { field, form } = fieldProps;
                        return (
                          <div>
                            <input className="form-control" type="text" {...field} />
                            <FormikVeldFout fieldProps={fieldProps} />
                          </div>
                        );
                      }}
                    />
                  </div>
                </div>
              </ModalBody>
              <ModalFooter className="d-flex flex-row justify-content-start mt-2">
                <button
                  className="btn btn-primary d-flex align-items-center justify-content-center"
                  onClick={submitForm}
                  style={{ width: 100 }}
                  disabled={isSubmitting}
                >
                  Ok
                </button>
                <button
                  className="btn btn-secondary"
                  onClick={props.onAnnuleren}
                  style={{ width: 100 }}
                  disabled={isSubmitting}
                >
                  Annuleren
                </button>
              </ModalFooter>
            </>
          );
        }}
      />
    </Dialoog>
  );
};

export default NieuwVoorstelDialoog;
