import api from '../../../api';
import {
  IOphalenBtwSoortenParams,
  IOphalenBtwSoortenResult,
  IOphalenBtwTarievenParams,
  IOphalenBtwTarievenResult,
  IToevoegenBtwtariefParams,
  IToevoegenBtwtariefResult,
  IVerwijderenBtwtarievenParams,
  IVerwijderenBtwtarievenResult,
  IWijzigenBtwtariefParams,
  IWijzigenBtwtariefResult,
} from '../../../../../shared/src/api/v2/btw';
import ICheckData from '../../../../../shared/src/models/ICheckData';

const btw = {
  ophalenBtwSoorten: async (
    params: IOphalenBtwSoortenParams,
  ): Promise<IOphalenBtwSoortenResult> => {
    return await api.post('/v2/btw/ophalen-btw-soorten', params);
  },
  ophalenBtwTarieven: async (
    params: IOphalenBtwTarievenParams,
  ): Promise<IOphalenBtwTarievenResult> => {
    return await api.post('/v2/btw/ophalen-btw-tarieven', params);
  },
  checkToevoegenBtwtarief: async (params: IToevoegenBtwtariefParams): Promise<ICheckData> => {
    return await api.post('/v2/btw/check-toevoegen-btw-tarief', params);
  },
  toevoegenBtwtarief: async (
    params: IToevoegenBtwtariefParams,
  ): Promise<IToevoegenBtwtariefResult> => {
    return await api.post('/v2/btw/toevoegen-btw-tarief', params);
  },
  checkWijzigenBtwtarief: async (params: IWijzigenBtwtariefParams): Promise<ICheckData> => {
    return await api.post('/v2/btw/check-wijzigen-btw-tarief', params);
  },
  wijzigenBtwtarief: async (
    params: IWijzigenBtwtariefParams,
  ): Promise<IWijzigenBtwtariefResult> => {
    return await api.post('/v2/btw/wijzigen-btw-tarief', params);
  },
  checkVerwijderenBtwtarieven: async (
    params: IVerwijderenBtwtarievenParams,
  ): Promise<ICheckData> => {
    return await api.post('/v2/btw/check-verwijderen-btw-tarieven', params);
  },
  verwijderenBtwtarieven: async (
    params: IVerwijderenBtwtarievenParams,
  ): Promise<IVerwijderenBtwtarievenResult> => {
    return await api.post('/v2/btw/verwijderen-btw-tarieven', params);
  },
};

export default btw;
