import api from '../../index';
import {
  IOphalenPotentieelOntvangenInkoopfactuurItemsParams,
  IOphalenPotentieelOntvangenInkoopfactuurItemsResult,
  IOphalenPotentieelOntvangenItemsParams,
  IOphalenPotentieelOntvangenItemsResult,
  IOphalenPotentieelOntvangenTelefoonnotitieItemsParams,
  IOphalenPotentieelOntvangenTelefoonnotitieItemsResult,
  IVerwerkenPotentieelOntvangenItemsParams,
  IVerwerkenPotentieelOntvangenItemsResult,
  IHerstellenPotentieelOntvangenItemsParams,
  IHerstellenPotentieelOntvangenItemsResult,
  IAfwijzenPotentieelOntvangenItemsParams,
  IAfwijzenPotentieelOntvangenItemsResult,
  IOphalenPotentieelOntvangenWerkbonItemsParams,
  IOphalenPotentieelOntvangenWerkbonItemsResult,
  IOphalenPotentieelOntvangenTransportopdrachtAfleverbewijsItemsParams,
  IOphalenPotentieelOntvangenTransportopdrachtAfleverbewijsItemsResult,
  IOphalenPotentieelOntvangenTransportregelAfleverbewijsItemsParams,
  IOphalenPotentieelOntvangenTransportregelAfleverbewijsItemsResult,
  IOphalenPotentieelOntvangenInslagReferentieItemsResult,
} from '../../../../../shared/src/api/v2/externeData';
import { IOphalenGeneriekParams } from '../../../../../shared/src/api/v2/generiek';

const externeData = {
  ophalenPotentieelOntvangenItems: async (
    params: IOphalenPotentieelOntvangenItemsParams,
  ): Promise<IOphalenPotentieelOntvangenItemsResult> => {
    return await api.post('/v2/externe-data/ophalen-potentieel-ontvangen-items', params);
  },
  ophalenPotentieelOntvangenTelefoonnotitieItems: async (
    params: IOphalenPotentieelOntvangenTelefoonnotitieItemsParams,
  ): Promise<IOphalenPotentieelOntvangenTelefoonnotitieItemsResult> => {
    return await api.post(
      '/v2/externe-data/ophalen-potentieel-ontvangen-telefoonnotitie-items',
      params,
    );
  },
  ophalenPotentieelOntvangenInkoopfactuurItems: async (
    params: IOphalenPotentieelOntvangenInkoopfactuurItemsParams,
  ): Promise<IOphalenPotentieelOntvangenInkoopfactuurItemsResult> => {
    return await api.post(
      '/v2/externe-data/ophalen-potentieel-ontvangen-inkoopfactuur-items',
      params,
    );
  },
  ophalenPotentieelOntvangenWerkbonItems: async (
    params: IOphalenPotentieelOntvangenWerkbonItemsParams,
  ): Promise<IOphalenPotentieelOntvangenWerkbonItemsResult> => {
    return await api.post('/v2/externe-data/ophalen-potentieel-ontvangen-werkbon-items', params);
  },
  ophalenPotentieelOntvangenInslagReferentieItems: async (
    params: IOphalenGeneriekParams,
  ): Promise<IOphalenPotentieelOntvangenInslagReferentieItemsResult> => {
    return await api.post(
      '/v2/externe-data/ophalen-potentieel-ontvangen-inslag-referentie-items',
      params,
    );
  },
  ophalenPotentieelOntvangenTransportopdrachtAfleverbewijsItems: async (
    params: IOphalenPotentieelOntvangenTransportopdrachtAfleverbewijsItemsParams,
  ): Promise<IOphalenPotentieelOntvangenTransportopdrachtAfleverbewijsItemsResult> => {
    return await api.post(
      '/v2/externe-data/ophalen-potentieel-ontvangen-transportopdracht-afleverbewijs-items',
      params,
    );
  },
  ophalenPotentieelOntvangenTransportregelAfleverbewijsItems: async (
    params: IOphalenPotentieelOntvangenTransportregelAfleverbewijsItemsParams,
  ): Promise<IOphalenPotentieelOntvangenTransportregelAfleverbewijsItemsResult> => {
    return await api.post(
      '/v2/externe-data/ophalen-potentieel-ontvangen-transportregel-afleverbewijs-items',
      params,
    );
  },
  verwerkenPotentieelOntvangenItems: async (
    params: IVerwerkenPotentieelOntvangenItemsParams,
  ): Promise<IVerwerkenPotentieelOntvangenItemsResult> => {
    return await api.post('/v2/externe-data/verwerken-potentieel-ontvangen-items', params);
  },
  afwijzenPotentieelOntvangenItems: async (
    params: IAfwijzenPotentieelOntvangenItemsParams,
  ): Promise<IAfwijzenPotentieelOntvangenItemsResult> => {
    return await api.post('/v2/externe-data/afwijzen-potentieel-ontvangen-items', params);
  },
  herstellenPotentieelOntvangenItems: async (
    params: IHerstellenPotentieelOntvangenItemsParams,
  ): Promise<IHerstellenPotentieelOntvangenItemsResult> => {
    return await api.post('/v2/externe-data/herstellen-potentieel-ontvangen-items', params);
  },
};

export default externeData;
