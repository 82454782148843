import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import TijdVeld, { ITijdVeldWaarde } from '../TijdVeld';

export interface ITijdvak {
  begin: string | null;
  eind: string | null;
}

interface IProps {
  value: ITijdvak | null;
  onChange: (value: ITijdvak | null) => void;
  enkeleToestaan?: boolean;
}

const TijdvakVeld: React.FC<IProps> = (props) => {
  const syncValueRef = useRef<ITijdvak | null>(props.value === null ? null : props.value);

  useEffect(() => {
    if (props.value === null) {
      return;
    }
    syncValueRef.current = props.value;
    setBegin({
      tekst: props.value.begin || '',
      resultaat: props.value.begin,
    });
    setEind({
      tekst: props.value.eind || '',
      resultaat: props.value.eind,
    });
  }, [props.value]);

  const isValidTijdvak = useCallback(
    (tijdvak: ITijdvak) => {
      if (props.enkeleToestaan) {
        if (
          (tijdvak.begin !== null && tijdvak.eind === null) ||
          (tijdvak.begin === null && tijdvak.eind !== null)
        ) {
          return true;
        }
      }
      if (tijdvak.begin === null || tijdvak.eind === null) {
        return false;
      }
      return tijdvak.begin < tijdvak.eind || tijdvak.begin === tijdvak.eind;
    },
    [props.enkeleToestaan],
  );

  const handleChange = useCallback(
    (voorgesteldTijdvak: ITijdvak | null) => {
      syncValueRef.current = voorgesteldTijdvak;
      if (voorgesteldTijdvak === null) {
        props.onChange(null);
        return;
      }
      const newValue = isValidTijdvak(voorgesteldTijdvak) ? voorgesteldTijdvak : null;
      if (JSON.stringify(props.value) !== JSON.stringify(newValue)) {
        props.onChange(newValue);
      }
    },
    [isValidTijdvak, props.value],
  );

  const [begin, setBegin] = useState<ITijdVeldWaarde>(
    props.value === null
      ? {
          tekst: '',
          resultaat: null,
        }
      : {
          tekst: props.value.begin || '',
          resultaat: props.value.begin,
        },
  );
  const [eind, setEind] = useState<ITijdVeldWaarde>(
    props.value === null
      ? {
          tekst: '',
          resultaat: null,
        }
      : {
          tekst: props.value.eind || '',
          resultaat: props.value.eind,
        },
  );

  return (
    <div className="d-flex align-items-center">
      <TijdVeld
        waarde={begin}
        onWaardeChange={(x) => {
          setBegin(x);
          handleChange({
            begin: x.resultaat,
            eind: eind.resultaat,
          } as ITijdvak);
        }}
      />
      &nbsp; - &nbsp;
      <TijdVeld
        waarde={eind}
        onWaardeChange={(x) => {
          setEind(x);
          handleChange({
            begin: begin.resultaat,
            eind: x.resultaat,
          } as ITijdvak);
        }}
      />
    </div>
  );
};

export default TijdvakVeld;
