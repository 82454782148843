import React, { useContext, useMemo, useState } from 'react';
import BijlageKnop, { EOrientatie, ESelectieModus } from '../../../../components/BijlageKnop';
import useUpload from '../../../../core/useUpload';
import { BestandType } from '../../../../components/BijlagenContainer';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import { IconDownload, IconKruis, IconUitvergroten } from '../../../../components/Icons';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import downloadUrl from '../../../../core/downloadUrl';
import UitlegTooltip from '../../../../components/formulier/UitlegTooltip';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../../stores/RootStore';
import { EResultType } from '../../../../stores/CheckStore';
import MediaWeergaveDialoog from '../../../../components/dialogen/MediaWeergaveDialoog';
import { EMediaWeergaveType } from '../../../../components/MediaWeergave';
import { IOphalenBestandenResultElement } from '../../../../../../shared/src/api/v2/bestand/bestand';

interface IProps {
  bestand: IOphalenBestandenResultElement | null;
  onBestIDChange: (id: number | null) => void;
}

const Bijlage: React.FC<IProps> = observer((props) => {
  const { checkStore } = useContext(RootStoreContext);
  const {
    isBezigMetUploaden,
    moetNogUploaden,
    muteerBestanden,
    upload,
    uploadProgresses,
    bestanden,
  } = useUpload(
    useMemo(
      () => ({
        automatischUploaden: true,
        initieleBestanden:
          props.bestand === null
            ? []
            : [
                {
                  type: BestandType.ASPDrive,
                  bestand: props.bestand!,
                },
              ],
        onAlleBestandenGeuploaded: (bestIDs) =>
          props.onBestIDChange(bestIDs.length === 0 ? null : bestIDs[0]),
      }),
      [props.onBestIDChange],
    ),
  );
  const [mediaWeergaveOpen, setMediaWeergaveOpen] = useState(false);

  return (
    <>
      <div className="flex-fill w-100 d-flex align-items-center justify-content-end">
        {isBezigMetUploaden ? (
          <LoadingSpinner />
        ) : props.bestand === null ? (
          <BijlageKnop
            onBijgevoegd={(x) => muteerBestanden(() => x)}
            selectieModus={ESelectieModus.Enkel}
            toegestaneBestandstypes={[
              {
                mediaType: 'application/pdf',
                weergaveNaam: 'PDF',
              },
              {
                mediaType: 'image/*',
                weergaveNaam: 'Afbeelding',
              },
            ]}
          />
        ) : (
          <div className="d-flex align-items-center ml-2">
            <UitlegTooltip inhoud="Mediaweergave">
              <button
                style={{
                  outline: 0,
                  background: 0,
                  border: 0,
                  padding: 0,
                }}
                onClick={() => setMediaWeergaveOpen(true)}
              >
                <IconUitvergroten style={{ width: 19, height: 19, fill: Kleur.Grijs }} />
              </button>
            </UitlegTooltip>
            <UitlegTooltip inhoud="Downloaden">
              <button
                style={{
                  outline: 0,
                  background: 0,
                  border: 0,
                  padding: 0,
                  position: 'relative',
                  top: -1,
                }}
                onClick={async () => {
                  await downloadUrl(props.bestand!.url, props.bestand!.Naam);
                }}
                className="ml-2"
              >
                <IconDownload style={{ width: 15, height: 15, fill: Kleur.Grijs }} />
              </button>
            </UitlegTooltip>
            <UitlegTooltip inhoud="Verwijderen">
              <button
                className="ml-2"
                style={{
                  outline: 0,
                  background: 0,
                  border: 0,
                  padding: 0,
                }}
                onClick={async () => {
                  if (
                    (await checkStore.bevestigen({ inhoud: 'Confirmatie verwijderen bijlage' }))
                      .type === EResultType.Annuleren
                  ) {
                    return;
                  }
                  await props.onBestIDChange(null);
                }}
              >
                <IconKruis style={{ width: 19, height: 19, fill: Kleur.Grijs }} />
              </button>
            </UitlegTooltip>
          </div>
        )}
      </div>
      {mediaWeergaveOpen && (
        <MediaWeergaveDialoog
          current={1}
          mediaWeergaven={
            props.bestand === null
              ? []
              : [
                  {
                    id: 1,
                    src: props.bestand.url,
                    type: props.bestand.MediaType.startsWith('image/')
                      ? EMediaWeergaveType.Afbeelding
                      : EMediaWeergaveType.PDF,
                    title: props.bestand.Naam,
                    mediaType: props.bestand.MediaType,
                  },
                ]
          }
          open
          onSuccess={() => setMediaWeergaveOpen(false)}
          onAnnuleren={() => setMediaWeergaveOpen(false)}
        />
      )}
    </>
  );
});

export default Bijlage;
