import React, { useCallback, useMemo, useState, useEffect } from 'react';
import IDialoogProps from '../../../core/IDialoogProps';
import Dialoog from '../../../components/dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import Modal from 'react-bootstrap/Modal';
import api from '../../../api';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import * as Yup from 'yup';
import teksten from '../../../bedrijfslogica/teksten';
import DatumKiezer from '../../../components/formulier/DatumKiezer';
import { addDays } from 'date-fns/esm';
import { IOphalenOpdrachtEventsVoorToevoegenResult } from '../../../../../shared/src/api/v2/transport/opdracht/uitstaand';
import LoadingSpinner from '../../../components/Gedeeld/LoadingSpinner';
import Combobox, { IOptie } from '../../../components/formulier/Combobox';
import nameof from '../../../core/nameOf';
import { dagDatum } from '../../../helpers/datum';

export interface IDialoogResult {}

interface IProps extends IDialoogProps<IDialoogResult> {
  trsOpdID: number;
}

interface IFormikValues {
  eventDatum: Date | null;
  eventID: number | null;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  eventDatum: 'Eventdatum',
  eventID: 'Event',
};

const OpdrachtEventsDialoog: React.FC<IProps> = (props) => {
  const { children, dialoogIndex, onAnnuleren, onSuccess, open, trsOpdID } = props;

  const [events, setEvents] = useState<IOphalenOpdrachtEventsVoorToevoegenResult | null>(null);

  useEffect(() => {
    if (events !== null) {
      return;
    }
    (async () => {
      const result = await api.v2.transport.ophalenOpdrachtEventsVoorToevoegen({});
      setEvents(result);
    })();
  }, []);

  const eventOpties = useMemo<IOptie<number>[] | null>(() => {
    if (events === null) {
      return null;
    }

    const opties = events.map((e) => ({
      id: e.ID,
      label: e.Naam,
    }));

    return opties;
  }, [events]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      const result = await api.v2.transport.toevoegenOpdrachtEvent({
        trsOpdID,
        id: values.eventID!,
        datum: values.eventDatum!,
      });

      actions.setSubmitting(false);

      onSuccess({});
    },
    [],
  );

  const initialValues = useMemo<IFormikValues>(
    () => ({
      eventDatum: dagDatum(addDays(new Date(), 0)),
      eventID: events !== null ? events[0].ID : null,
    }),
    [events],
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        eventDatum: Yup.date().required(
          teksten.formulier.E_VERPLICHT_VELD({
            veldnaam: veldnamen.eventDatum,
          }),
        ),
        eventID: Yup.number().required(),
      }),
    [],
  );

  return (
    <Dialoog index={dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Events toevoegen</ModalTitle>
      </ModalHeader>
      <Formik<IFormikValues>
        isInitialValid
        enableReinitialize
        onSubmit={handleSubmit}
        initialValues={initialValues}
        //isInitialValid
        render={(formikProps: FormikProps<IFormikValues>) => {
          const { submitForm, isSubmitting, isValid } = formikProps;

          return (
            <>
              <ModalBody>
                <div className="form-group">
                  <div className="row">
                    <div className="col-12">
                      <label>{veldnamen.eventID}</label>
                      {eventOpties === null ? (
                        <LoadingSpinner />
                      ) : (
                        <Field
                          name={nameof<IFormikValues>('eventID')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <Combobox
                                opties={eventOpties!}
                                geselecteerd={field.value}
                                legeOptieTonen={false}
                                onSelectieChange={(id) => form.setFieldValue(field.name, id)}
                              />
                            );
                          }}
                        />
                      )}
                    </div>

                    <div className="col-12 mt-2">
                      <label>{veldnamen.eventDatum}</label>
                      <Field
                        name="eventDatum"
                        render={(fieldProps: FieldProps<IFormikValues>) => {
                          const { field, form } = fieldProps;
                          return (
                            <DatumKiezer
                              waarde={field.value}
                              onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                            />
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
              </ModalBody>
              <ModalFooter className="d-flex flex-row justify-content-start">
                <button
                  className="btn btn-primary"
                  onClick={submitForm}
                  style={{ width: 100 }}
                  disabled={!isValid || isSubmitting}
                >
                  Ok
                </button>
                <button
                  className="btn btn-secondary"
                  onClick={onAnnuleren}
                  style={{ width: 100 }}
                  disabled={isSubmitting}
                >
                  Annuleren
                </button>
              </ModalFooter>
            </>
          );
        }}
      />
    </Dialoog>
  );
};

export default OpdrachtEventsDialoog;
