import React, { useCallback, useEffect, useMemo, useState } from 'react';
import IDialoogProps from '../../../../../core/IDialoogProps';
import Dialoog from '../../../../../components/dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import nameOf from '../../../../../core/nameOf';
import FormikVeldFout from '../../../../../components/formulier/FormikVeldFout';
import Combobox, { IOptie } from '../../../../../components/formulier/Combobox';
import VinkVeld from '../../../../../components/formulier/VinkVeld';
import * as Yup from 'yup';
import teksten from '../../../../../bedrijfslogica/teksten';
import { IOphalenProductsoortenResult } from '../../../../../../../shared/src/api/v2/product/soort';
import api from '../../../../../api';
import Tabblad, { ITabblad } from '../../../../../components/layout/Tabblad';
import { IOphalenProductmerkenResultElement } from '../../../../../../../shared/src/api/v2/product/merk';
import MeertaligTekstveld, {
  MeertaligTekstveldSlugInputComponent,
} from '../../../../../components/formulier/MeertaligTekstveld';
import ITaalTekst from '../../../../../../../shared/src/models/talen/ITaalTekst';
import NumeriekVeld from '../../../../../components/formulier/NumeriekVeld';
import { IOphalenEnergielabelsResult } from '../../../../../../../shared/src/api/v2/product/energielabel';

export enum ETabbladen {
  Model,
  Detail,
  Extern,
}

export enum EAfbeeldingSet {
  Geen = 0,
  Automatisch = 1,
  Model = 2,
  Type = 3,
}

export enum ESpecificatieSet {
  Geen = 0,
  Automatisch = 1,
  Model = 2,
  Type = 3,
}

const afbeeldingSetWeergave: Record<EAfbeeldingSet, string> = {
  [EAfbeeldingSet.Geen]: 'Geen',
  [EAfbeeldingSet.Automatisch]: 'Automatisch',
  [EAfbeeldingSet.Model]: 'Model',
  [EAfbeeldingSet.Type]: 'Type',
};

const specificatieSetWeergave: Record<ESpecificatieSet, string> = {
  [EAfbeeldingSet.Geen]: 'Geen',
  [EAfbeeldingSet.Automatisch]: 'Automatisch',
  [EAfbeeldingSet.Model]: 'Model',
  [EAfbeeldingSet.Type]: 'Type',
};

export interface IDialoogResult {
  prodSrtID: number;
  afbeeldingSet: EAfbeeldingSet;
  specificatieSet: ESpecificatieSet;
  modelnaam: string;
  kenmerk: string;
  actief: boolean;
  openbaarAanbod: boolean;
  huismodel: boolean;
  gebruiktProduct: boolean;
  merkID: number | null;
  modelcode: string;
  modelnaamExtern: ITaalTekst[];
  modelinfoExtern: ITaalTekst[];
  toelichting: ITaalTekst[];
  slug: ITaalTekst[];
  titel: ITaalTekst[];
  metadata: ITaalTekst[];
  aantalGebruikersTarief: number | null;
  aantalGebruikersMax: number | null;
  energielabelID: number | null;
}

export interface IFormikValues {
  prodSrtID: number | null;
  afbeeldingSet: EAfbeeldingSet;
  specificatieSet: ESpecificatieSet;
  modelnaam: string;
  kenmerk: string;
  actief: boolean;
  openbaarAanbod: boolean;
  huismodel: boolean;
  gebruiktProduct: boolean;
  merkID: number | null;
  modelcode: string;
  aantalGebruikersTarief: number | null;
  aantalGebruikersMax: number | null;
  energielabelID: number | null;

  // extern
  modelnaamExtern: ITaalTekst[];
  modelinfoExtern: ITaalTekst[];
  slug: ITaalTekst[];
  titel: ITaalTekst[];
  metadata: ITaalTekst[];
  toelichting: ITaalTekst[];
}

const veldnamen: Record<keyof IFormikValues, string> = {
  prodSrtID: 'Productsoort',
  afbeeldingSet: 'Afbeeldingen via',
  specificatieSet: 'Specificaties via',
  modelnaam: 'Modelnaam (intern gebruik)',
  kenmerk: 'Kenmerk',
  actief: 'Actief',
  openbaarAanbod: 'In openbaar aanbod',
  gebruiktProduct: 'Gebruikt product',
  huismodel: 'Huismodel',
  merkID: 'Merk',
  modelcode: 'Modelcode',
  modelnaamExtern: 'Modelnaam',
  modelinfoExtern: 'Modelinfo',
  slug: 'Slug',
  titel: 'Titel',
  metadata: 'Metadata',
  aantalGebruikersTarief:
    'Max aantal gebruikers voor tarief (0 is zoals opgegeven bij productsoort)',
  aantalGebruikersMax: 'Max aantal gebruikers toegestaan (0 is zoals opgegeven bij productsoort)',
  energielabelID: 'Energielabel',
  toelichting: 'Toelichting',
};

interface IProps extends IDialoogProps<IDialoogResult> {
  initialValues?: IFormikValues;
}

const MuterenDialoog: React.FC<IProps> = (props) => {
  const [
    productsoortenResult,
    setProductsoortenResult,
  ] = useState<IOphalenProductsoortenResult | null>(null);

  const ophalenProductsoorten = useCallback(async () => {
    const result = await api.v2.productsoort.ophalenProductsoorten({
      filterSchema: {
        filters: [],
      },
    });
    setProductsoortenResult(result);
  }, []);

  useEffect(() => {
    ophalenProductsoorten();
  }, [ophalenProductsoorten]);

  const [
    energielabelsResult,
    setEnergielabelsResult,
  ] = useState<IOphalenEnergielabelsResult | null>(null);

  const ophalenEnergielabels = useCallback(async () => {
    const result = await api.v2.product.energielabel.ophalenEnergielabels({
      filterSchema: {
        filters: [],
      },
    });
    setEnergielabelsResult(result);
  }, []);

  useEffect(() => {
    ophalenEnergielabels();
  }, [ophalenEnergielabels]);

  const [merken, setMerken] = useState<IOphalenProductmerkenResultElement[] | null>(null);
  useEffect(() => {
    (async () => {
      const productmerkenResults = await api.v2.product.merk.ophalenProductmerken({
        filterSchema: { filters: [{ naam: 'IS_ACTIEF', data: true }] },
      });
      setMerken(productmerkenResults.productmerken);
    })();
  }, []);

  const [tabblad, setTabblad] = useState<ETabbladen>(ETabbladen.Model);

  const initialValues = useMemo<IFormikValues>(() => {
    if (props.initialValues !== undefined) {
      return props.initialValues;
    }

    return {
      prodSrtID: null,
      afbeeldingSet: EAfbeeldingSet.Automatisch,
      specificatieSet: ESpecificatieSet.Automatisch,
      modelnaam: '',
      kenmerk: '',
      actief: true,
      openbaarAanbod: true,
      huismodel: false,
      gebruiktProduct: false,
      merkID: null,
      modelcode: '',
      modelnaamExtern: [],
      modelinfoExtern: [],
      slug: [],
      titel: [],
      metadata: [],
      aantalGebruikersTarief: 0,
      aantalGebruikersMax: 0,
      energielabelID: 1,
      toelichting: [],
    };
  }, [props.initialValues]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);
      const errors = actions.validateForm(values);
      if (Object.keys(errors).length > 0) {
        return;
      }

      props.onSuccess({
        ...values,
        prodSrtID: values.prodSrtID!,
        modelnaam: values.modelnaam.trim(),
        kenmerk: values.kenmerk.trim(),
      });
      actions.setSubmitting(false);
    },
    [props.onSuccess],
  );

  const afbeeldingSetOpties = useMemo<IOptie<number>[]>(
    () =>
      Object.keys(EAfbeeldingSet)
        .filter((x) => !isNaN(Number(x)))
        .map((x) => {
          const key = Number(x) as EAfbeeldingSet;
          return {
            id: key,
            label: afbeeldingSetWeergave[key],
          };
        }),
    [],
  );

  const specificatieSetOpties = useMemo<IOptie<number>[]>(
    () =>
      Object.keys(EAfbeeldingSet)
        .filter((x) => !isNaN(Number(x)))
        .map((x) => {
          const key = Number(x) as EAfbeeldingSet;
          return {
            id: key,
            label: specificatieSetWeergave[key],
          };
        }),
    [],
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        prodSrtID: Yup.number().required(),
        modelnaam: Yup.string()
          .trim()
          .required(teksten.formulier.E_VERPLICHT_VELD({ veldnaam: veldnamen.modelnaam })),
        modelcode: Yup.string()
          .trim()
          .required(teksten.formulier.E_VERPLICHT_VELD({ veldnaam: veldnamen.modelnaam })),
        // .min(1),
        kenmerk: Yup.string()
          .trim()
          .required(teksten.formulier.E_VERPLICHT_VELD({ veldnaam: veldnamen.kenmerk })),
        // .min(1),
      }),
    [],
  );

  const tabbladen = useMemo<ITabblad<ETabbladen>[]>(
    () => [
      {
        id: ETabbladen.Model,
        label: 'Model',
        content: () => {
          return (
            <div className="p-3" style={{ minHeight: 500 }}>
              <div className="row">
                <div className="col-12">
                  <label>{veldnamen.prodSrtID}</label>
                  <Field
                    name={nameOf<IFormikValues>('prodSrtID')}
                    render={(fieldProps: FieldProps<IFormikValues>) => {
                      if (productsoortenResult === null) {
                        return <span />;
                      }

                      return (
                        <>
                          <Combobox
                            geselecteerd={fieldProps.field.value}
                            onSelectieChange={(id) =>
                              fieldProps.form.setFieldValue(fieldProps.field.name, id)
                            }
                            opties={productsoortenResult.map((x) => ({
                              id: x.ProdSrtID,
                              label: x.Naam,
                            }))}
                          />
                          <FormikVeldFout fieldProps={fieldProps} />
                        </>
                      );
                    }}
                  />
                </div>

                <div className="col-8 mt-3">
                  <label>{veldnamen.modelnaam}</label>
                  <Field
                    name={nameOf<IFormikValues>('modelnaam')}
                    render={(fieldProps: FieldProps<IFormikValues>) => {
                      return (
                        <>
                          <input className="form-control" {...fieldProps.field} />
                          <FormikVeldFout fieldProps={fieldProps} />
                        </>
                      );
                    }}
                  />
                </div>

                <div className="col-4 mt-3">
                  <label>{veldnamen.modelcode}</label>
                  <Field
                    name={nameOf<IFormikValues>('modelcode')}
                    render={(fieldProps: FieldProps<IFormikValues>) => {
                      return (
                        <>
                          <input className="form-control" {...fieldProps.field} />
                          <FormikVeldFout fieldProps={fieldProps} />
                        </>
                      );
                    }}
                  />
                </div>

                <div className="col-12 mt-3">
                  <label>{veldnamen.merkID}</label>
                  <Field
                    name={nameOf<IFormikValues>('merkID')}
                    render={(fieldProps: FieldProps<IFormikValues>) => {
                      if (merken === null) {
                        return <span />;
                      }

                      return (
                        <>
                          <Combobox
                            geselecteerd={fieldProps.field.value}
                            onSelectieChange={(id) =>
                              fieldProps.form.setFieldValue(fieldProps.field.name, id)
                            }
                            opties={merken.map((x) => ({
                              id: x.MerkID,
                              label: x.Merknaam,
                            }))}
                          />
                          <FormikVeldFout fieldProps={fieldProps} />
                        </>
                      );
                    }}
                  />
                </div>

                <div className="col-12 mt-3">
                  <label>{veldnamen.kenmerk}</label>
                  <Field
                    name={nameOf<IFormikValues>('kenmerk')}
                    render={(fieldProps: FieldProps<IFormikValues>) => {
                      return (
                        <>
                          <input className="form-control" {...fieldProps.field} />
                          <FormikVeldFout fieldProps={fieldProps} />
                        </>
                      );
                    }}
                  />
                </div>

                <div className="col-6 mt-3">
                  <label>{veldnamen.energielabelID}</label>
                  <Field
                    name={nameOf<IFormikValues>('energielabelID')}
                    render={(fieldProps: FieldProps<IFormikValues>) => {
                      if (energielabelsResult === null) {
                        return <span />;
                      }

                      return (
                        <>
                          <Combobox
                            geselecteerd={fieldProps.field.value}
                            legeOptieTonen
                            options={{ legeOptieTekst: 'Geen energielabel' }}
                            onSelectieChange={(id) =>
                              fieldProps.form.setFieldValue(fieldProps.field.name, id)
                            }
                            opties={energielabelsResult.energielabels.map((x) => ({
                              id: x.ID,
                              label: x.Naam,
                            }))}
                          />
                          <FormikVeldFout fieldProps={fieldProps} />
                        </>
                      );
                    }}
                  />
                </div>

                <div className="col-12 mt-4">
                  <div className="row">
                    <div className="col-2">
                      <Field
                        name={nameOf<IFormikValues>('huismodel')}
                        render={(fieldProps: FieldProps<IFormikValues>) => {
                          const { field, form } = fieldProps;
                          return (
                            <>
                              <div className="d-flex align-items-center">
                                <VinkVeld
                                  aangevinkt={field.value}
                                  onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                />
                                <span className="ml-2">{veldnamen.huismodel}</span>
                              </div>
                              <FormikVeldFout fieldProps={fieldProps} />
                            </>
                          );
                        }}
                      />
                    </div>

                    <div className="col-3">
                      <Field
                        name={nameOf<IFormikValues>('gebruiktProduct')}
                        render={(fieldProps: FieldProps<IFormikValues>) => {
                          const { field, form } = fieldProps;
                          return (
                            <>
                              <div className="d-flex align-items-center">
                                <VinkVeld
                                  aangevinkt={field.value}
                                  onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                />
                                <span className="ml-2">{veldnamen.gebruiktProduct}</span>
                              </div>
                              <FormikVeldFout fieldProps={fieldProps} />
                            </>
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-6 mt-4">
                  <label>{veldnamen.afbeeldingSet}</label>
                  <Field
                    name={nameOf<IFormikValues>('afbeeldingSet')}
                    render={(fieldProps: FieldProps<IFormikValues>) => {
                      const { field, form } = fieldProps;
                      return (
                        <>
                          <Combobox
                            geselecteerd={field.value}
                            onSelectieChange={(x) => form.setFieldValue(field.name, x)}
                            opties={afbeeldingSetOpties}
                          />
                          <FormikVeldFout fieldProps={fieldProps} />
                        </>
                      );
                    }}
                  />
                </div>

                <div className="col-6 mt-4">
                  <label>{veldnamen.specificatieSet}</label>
                  <Field
                    name={nameOf<IFormikValues>('specificatieSet')}
                    render={(fieldProps: FieldProps<IFormikValues>) => {
                      const { field, form } = fieldProps;
                      return (
                        <>
                          <Combobox
                            geselecteerd={field.value}
                            onSelectieChange={(x) => form.setFieldValue(field.name, x)}
                            opties={specificatieSetOpties}
                          />
                          <FormikVeldFout fieldProps={fieldProps} />
                        </>
                      );
                    }}
                  />
                </div>

                <div className="col-3 mt-3">
                  <Field
                    name={nameOf<IFormikValues>('actief')}
                    render={(fieldProps: FieldProps<IFormikValues>) => {
                      const { field, form } = fieldProps;
                      return (
                        <>
                          <div className="d-flex align-items-center">
                            <VinkVeld
                              aangevinkt={field.value}
                              onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                            />
                            <span className="ml-2">{veldnamen.actief}</span>
                          </div>
                          <FormikVeldFout fieldProps={fieldProps} />
                        </>
                      );
                    }}
                  />
                </div>

                <div className="col-3 mt-3">
                  <Field
                    name={nameOf<IFormikValues>('openbaarAanbod')}
                    render={(fieldProps: FieldProps<IFormikValues>) => {
                      const { field, form } = fieldProps;
                      return (
                        <>
                          <div className="d-flex align-items-center">
                            <VinkVeld
                              aangevinkt={field.value}
                              onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                            />
                            <span className="ml-2">{veldnamen.openbaarAanbod}</span>
                          </div>
                          <FormikVeldFout fieldProps={fieldProps} />
                        </>
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          );
        },
      },
      {
        id: ETabbladen.Detail,
        label: 'Detail',
        content: () => {
          return (
            <div className="p-3" style={{ minHeight: 500 }}>
              <div className="row mt-3">
                <div className="col-5">
                  <label>{veldnamen.aantalGebruikersTarief}</label>
                  <Field
                    name={nameOf<IFormikValues>('aantalGebruikersTarief')}
                    render={(fieldProps: FieldProps<IFormikValues>) => {
                      const { field, form } = fieldProps;
                      return (
                        <NumeriekVeld
                          waarde={field.value}
                          onChange={(x) => form.setFieldValue(field.name, x)}
                          min={0}
                          max={99}
                        />
                      );
                    }}
                  />
                </div>

                <div className="col-5">
                  <label>{veldnamen.aantalGebruikersMax}</label>
                  <Field
                    name={nameOf<IFormikValues>('aantalGebruikersMax')}
                    render={(fieldProps: FieldProps<IFormikValues>) => {
                      const { field, form } = fieldProps;
                      return (
                        <NumeriekVeld
                          waarde={field.value}
                          onChange={(x) => form.setFieldValue(field.name, x)}
                          min={0}
                          max={99}
                        />
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          );
        },
      },
      {
        id: ETabbladen.Extern,
        label: 'Webteksten',
        content: () => {
          return (
            <div className="p-3" style={{ minHeight: 500 }}>
              <div className="row">
                <div className="col-12">
                  <Field
                    name={nameOf<IFormikValues>('modelnaamExtern')}
                    render={({ field, form }: FieldProps) => {
                      return (
                        <>
                          <label>{veldnamen.modelnaamExtern}</label>
                          <MeertaligTekstveld
                            waarden={field.value}
                            onChange={(value) => form.setFieldValue(field.name, value)}
                          />
                        </>
                      );
                    }}
                  />
                </div>

                <div className="col-12 mt-3">
                  <Field
                    name={nameOf<IFormikValues>('modelinfoExtern')}
                    render={({ field, form }: FieldProps) => {
                      return (
                        <>
                          <label>{veldnamen.modelinfoExtern}</label>
                          <MeertaligTekstveld
                            waarden={field.value}
                            onChange={(value) => form.setFieldValue(field.name, value)}
                            inputComponent="multiline"
                            multilineConfig={{
                              rows: 8,
                            }}
                          />
                        </>
                      );
                    }}
                  />
                </div>

                <div className="col-12 mt-3">
                  <Field
                    name={nameOf<IFormikValues>('toelichting')}
                    render={({ field, form }: FieldProps) => {
                      return (
                        <>
                          <label>{veldnamen.toelichting}</label>
                          <MeertaligTekstveld
                            waarden={field.value}
                            onChange={(value) => form.setFieldValue(field.name, value)}
                            inputComponent="multiline"
                            multilineConfig={{
                              rows: 3,
                            }}
                          />
                        </>
                      );
                    }}
                  />
                </div>

                <div className="col-12 mt-3">
                  <Field
                    name={nameOf<IFormikValues>('titel')}
                    render={({ field, form }: FieldProps) => {
                      return (
                        <>
                          <label>{veldnamen.titel}</label>
                          <MeertaligTekstveld
                            waarden={field.value}
                            onChange={(value) => form.setFieldValue(field.name, value)}
                          />
                        </>
                      );
                    }}
                  />
                </div>

                <div className="col-12 mt-3">
                  <Field
                    name={nameOf<IFormikValues>('slug')}
                    render={({ field, form }: FieldProps) => {
                      return (
                        <>
                          <label>{veldnamen.slug}</label>
                          <MeertaligTekstveld
                            waarden={field.value}
                            onChange={(value) => form.setFieldValue(field.name, value)}
                            inputComponent={MeertaligTekstveldSlugInputComponent}
                          />
                        </>
                      );
                    }}
                  />
                </div>

                <div className="col-12 mt-3">
                  <Field
                    name={nameOf<IFormikValues>('metadata')}
                    render={({ field, form }: FieldProps) => {
                      return (
                        <>
                          <label>{veldnamen.metadata}</label>
                          <MeertaligTekstveld
                            waarden={field.value}
                            onChange={(value) => form.setFieldValue(field.name, value)}
                          />
                        </>
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          );
        },
      },
    ],
    [productsoortenResult, merken, energielabelsResult],
  );

  return (
    <Dialoog index={props.dialoogIndex || 0} modalProps={{ size: 'lg' }}>
      <ModalHeader>
        <ModalTitle>Productmodel</ModalTitle>
      </ModalHeader>
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, submitForm }: FormikProps<IFormikValues>) => {
          return (
            <>
              <ModalBody className="p-0">
                <Tabblad<ETabbladen>
                  geselecteerd={tabblad}
                  onSelectieChange={(x) => setTabblad(x)}
                  tabbladen={tabbladen}
                />
              </ModalBody>
              <ModalFooter className="d-flex flex-row justify-content-start">
                <button
                  className="btn btn-primary"
                  onClick={submitForm}
                  style={{ width: 100 }}
                  disabled={isSubmitting}
                >
                  Ok
                </button>
                <button
                  className="btn btn-secondary"
                  onClick={props.onAnnuleren}
                  style={{ width: 100 }}
                  disabled={isSubmitting}
                >
                  Annuleren
                </button>
              </ModalFooter>
            </>
          );
        }}
      </Formik>
    </Dialoog>
  );
};

export default MuterenDialoog;
