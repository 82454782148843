import { makeObservable, observable } from 'mobx';
import { RootStore } from './RootStore';
import {
  IOphalenApiLogsResult,
  IOphalenSysteemInformatieResult,
} from '../../../shared/src/api/v2/technisch';
import api from '../api';
import { IFilterSchema } from '../../../shared/src/models/filter';

class TechnischStore {
  public informatie: IOphalenSysteemInformatieResult | null = null;
  public apiLogs: IOphalenApiLogsResult | null = null;

  // tslint:disable-next-line:variable-name
  private _rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this._rootStore = rootStore;

    makeObservable(this, {
      informatie: observable,
      apiLogs: observable,
    });
  }

  public ophalenInformatie = async () => {
    const result = await api.v2.technisch.ophalenSysteemInformatie({});
    this.informatie = result;
  };

  public ophalenApiLogs = async (filterSchema: IFilterSchema) => {
    this.apiLogs = await api.v2.technisch.ophalenApiLogs({
      paginatie: { index: 0, aantal: 50 },
      filterSchema,
    });
  };
}

export default TechnischStore;
