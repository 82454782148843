import { Formik } from 'formik';
import React, { useCallback, useContext, useState, useMemo, useEffect } from 'react';
import { ModalFooter, ModalTitle } from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/ModalHeader';
import { IOphalenMeldingenResultElement } from '../../../../../shared/src/api/v2/service/melding';
import { IOphalenRelatiesResultElementV2 } from '../../../../../shared/src/api/v2/relatie';
import {
  IInkoopfacturenResultElement,
  IInkoopfactuurVoorProduct,
  IOphalenInkoopfacturenVoorProductenResult,
  IOphalenInkoopfacturenVoorProductenResultElement,
  IOphalenMutatiesReferentiecodeResultElement,
  IOphalenProductenResultElementV2,
} from '../../../../../shared/src/api/v2/product';
import api from '../../../api';
import IDialoogProps from '../../../core/IDialoogProps';
import Dialoog from '../../dialogen/Dialoog';
import LoadingSpinner from '../../Gedeeld/LoadingSpinner';
import Tabblad, { ITabblad } from '../../layout/Tabblad';
import ProductTab from './ProductTab';
import DetailTab from './InkoopVerkoopTab';
import ServiceTab from './ServiceTab';
import TransportTab from './TransportTab';
import DocumentatieTab from './Documentatie';
import AfbeeldingTab from './AfbeeldingTab';
import _ from 'lodash';
import {
  IOphalenOpdrachtenResultElementV2,
  IOphalenOpdrachtregelsResultElementV2,
} from '../../../../../shared/src/api/v2/transport/opdracht';
import { IOphalenOpdrachtenResultElement } from '../../../../../shared/src/api/v2/service/opdracht';
import { IOphalenVerkoopVoorstellenResultElement } from '../../../../../shared/src/api/v2/product/verkoop';
import { IOphalenContractenResultElementV2 } from '../../../../../shared/src/api/v2/contract';
import {
  IOphalenPendelregelsResultElement,
  IOphalenOpdrachtenResultElement as IOphalenPendelopdrachtenResultElement,
} from '../../../../../shared/src/api/v2/pendel';
import { IOphalenRegisterItemsResultElement } from '../../../../../shared/src/api/v2/boekhouding/activaregister/register';
import TechnischeInformatieKnop from '../../TechnischeInformatieKnop';
import { IOphalenWerkbonnenResultElement } from '../../../../../shared/src/api/v2/service/werkbon';
import { IOphalenFacturenResultElement } from '../../../../../shared/src/api/v2/inkoopfactuur';
import { IOphalenMagazijnenResultElement } from '../../../../../shared/src/api/v2/magazijn';
import SelectieSectie from './SelectieSectie';
import InactiefOverlay from '../../InactiefOverlay';

export enum ETablad {
  Product,
  Detail,
  Service,
  Transport,
  Documentatie,
  Afbeelding,
}

interface IProps extends IDialoogProps<{}> {
  id: number | null;
  metSelectie?: boolean;
  tabblad?: ETablad;
}

export interface IRegel extends IOphalenOpdrachtregelsResultElementV2 {
  opdracht: IOphalenOpdrachtenResultElementV2;
}

export interface IContext {
  product: IOphalenProductenResultElementV2 | null;
  inkoopfactuur: IInkoopfacturenResultElement | null;
  servicemeldingen: IOphalenMeldingenResultElement[] | null;
  serviceopdrachten: IOphalenOpdrachtenResultElement[] | null;
  werkbonnen: IOphalenWerkbonnenResultElement[] | null;
  transportopdrachtregels: IRegel[] | null;
  verkoopprijs: number | null;
  relaties: IOphalenRelatiesResultElementV2[] | null;
  verkoopvoorstellen: IOphalenVerkoopVoorstellenResultElement[] | null;
  contracten: IOphalenContractenResultElementV2[] | null;
  pendelregels: IOphalenPendelregelsResultElement[] | null;
  mutatiesReferentiecode: IOphalenMutatiesReferentiecodeResultElement[] | null;
  registeritem: IOphalenRegisterItemsResultElement | null | undefined;
  magazijnen: IOphalenMagazijnenResultElement[] | null;
  // pendelopdrachten: IOphalenPendelopdrachtenResultElement[] | null;
}

export const ProductContext = React.createContext<IContext>({
  product: null,
  inkoopfactuur: null,
  servicemeldingen: null,
  serviceopdrachten: null,
  werkbonnen: null,
  transportopdrachtregels: null,
  verkoopprijs: null,
  relaties: null,
  verkoopvoorstellen: null,
  contracten: null,
  pendelregels: null,
  mutatiesReferentiecode: null,
  registeritem: null,
  magazijnen: null,
  // pendelopdrachten: null,
});

export interface IFormikValues {
  notities: string | null;
}

export interface ITabbladProps {
  onAnnuleren: () => void;
}

export const veldnamen: Record<keyof IFormikValues, string> = {
  notities: 'Notities',
};

export interface IPendelregels extends IOphalenPendelregelsResultElement {
  opdracht: IOphalenPendelopdrachtenResultElement;
}

const ProductinfoDialoog: React.FC<IProps> = (props) => {
  const { dialoogIndex, onAnnuleren, onSuccess } = props;

  const [id, setId] = useState(props.id);
  const metSelectie = props.metSelectie ?? false;
  const [isBezigMetProductOphalen, setIsBezigMetProductOphalen] = useState<boolean>(false);
  const [tabblad, setTabblad] = useState<ETablad>(props.tabblad ?? ETablad.Product);
  const [product, setProduct] = useState<IOphalenProductenResultElementV2 | null>(null);
  const [relaties, setRelaties] = useState<IOphalenRelatiesResultElementV2[] | null>(null);
  const [inkoopfactuur, setInkoopfactuur] = useState<IInkoopfacturenResultElement | null>(null);
  const [verkoopprijs, setVerkoopprijs] = useState<number | null>(null);
  const [servicemeldingen, setServicemeldingen] = useState<IOphalenMeldingenResultElement[] | null>(
    null,
  );
  const [serviceopdrachten, setServiceopdrachten] = useState<
    IOphalenOpdrachtenResultElement[] | null
  >(null);
  const [werkbonnen, setWerkbonnen] = useState<IOphalenWerkbonnenResultElement[] | null>(null);
  const [transportopdrachtregels, setTransportopdrachtregels] = useState<IRegel[] | null>(null);
  const [contracten, setContracten] = useState<IOphalenContractenResultElementV2[] | null>(null);
  const [mutatiesReferentiecode, setMutatiesReferentiecode] = useState<
    IOphalenMutatiesReferentiecodeResultElement[] | null
  >(null);
  const [registeritem, setRegisteritem] = useState<
    IOphalenRegisterItemsResultElement | null | undefined
  >(undefined);

  const [magazijnen, setMagazijnen] = useState<IOphalenMagazijnenResultElement[] | null>(null);

  const tabbladen = useMemo<ITabblad<ETablad, ITabbladProps>[]>(
    () => [
      {
        id: ETablad.Product,
        label: 'Product',
        content: ProductTab,
      },
      {
        id: ETablad.Detail,
        label: 'Inkoop/Verkoop',
        content: DetailTab,
      },
      {
        id: ETablad.Service,
        label: 'Service',
        content: ServiceTab,
      },
      {
        id: ETablad.Transport,
        label: 'Transport',
        content: TransportTab,
      },
      {
        id: ETablad.Documentatie,
        label: 'Documentatie',
        content: DocumentatieTab,
      },
      {
        id: ETablad.Afbeelding,
        label: 'Afbeeldingen',
        content: AfbeeldingTab,
      },
    ],
    [onSuccess],
  );

  const ophalenProduct = useCallback(async () => {
    if (id === null) {
      return;
    }
    setIsBezigMetProductOphalen(true);

    const productenResult = await api.v2.product.ophalenProductenV2({
      filterSchema: { filters: [{ naam: 'IDS', data: [id] }] },
    });
    const product = productenResult.producten[0];

    // Inkoopfactuur voor het product
    let inkoopfactuur: IInkoopfacturenResultElement | null = null;

    const factuurVoorProductResult =
      (
        await api.v2.inkoopfactuur.ophalenFacturenXProducten({
          filterSchema: { filters: [{ naam: 'PROD_IDS', data: [product.ProdID] }] },
        })
      ).data.filter((x) => x.Bedrag > 0)[0] ?? null;

    if (factuurVoorProductResult !== null) {
      const factuurResult = (
        await api.v2.inkoopfactuur.ophalenFacturen({
          filterSchema: { filters: [{ naam: 'IDS', data: [factuurVoorProductResult.InkFactID] }] },
        })
      ).facturen[0];

      inkoopfactuur = { ...factuurResult, BedragInFactuur: factuurVoorProductResult.Bedrag };
    }

    // const factuurVoorProductResult =
    //   (
    //     await api.v2.inkoopfactuur.ophalenFacturenXProducten({
    //       filterSchema: { filters: [{ naam: 'PROD_IDS', data: [product.ProdID] }] },
    //     })
    //   ).data.filter((x) => x.Bedrag > 0)[0] ?? null;

    // const inkoopfactuur =
    //   factuurVoorProductResult !== null
    //     ? (
    //         await api.v2.inkoopfactuur.ophalenFacturen({
    //           filterSchema: {
    //             filters: [{ naam: 'IDS', data: [factuurVoorProductResult.InkFactID] }],
    //           },
    //         })
    //       ).facturen[0]
    //     : null;

    // Verkoopprijs
    const verkoopprijzenResult = await api.v2.product.verkoop.berekenVerkoopbedrag({
      prodIDs: [id],
    });
    const verkoopprijs = verkoopprijzenResult.verkoopbedragen[0].bedrag;

    setProduct(product);
    setInkoopfactuur(inkoopfactuur);
    setVerkoopprijs(verkoopprijs);

    setIsBezigMetProductOphalen(false);
  }, [id]);

  useEffect(() => {
    ophalenProduct();
  }, [ophalenProduct]);

  const ophalenTransportopdrachtregels = useCallback(async () => {
    const regelsResult = (
      await api.v2.transport.opdracht.ophalenOpdrachtregelsV2({
        filterSchema: { filters: [{ naam: 'PROD_IDS', data: [id] }] },
        orderSchema: { orders: [{ naam: 'ID', richting: 'DESC' }] },
      })
    ).regels;
    const trsOpdIDs = regelsResult.map((x) => x.TrsOpdID);
    const opdrachten = (
      await api.v2.transport.opdracht.ophalenOpdrachtenV2({
        filterSchema: { filters: [{ naam: 'IDS', data: trsOpdIDs }] },
      })
    ).opdrachten;

    const regels = regelsResult.map((x) => {
      const opdracht = opdrachten.find((o) => o.TrsOpdID === x.TrsOpdID)!;
      return { ...x, opdracht };
    });

    setTransportopdrachtregels(regels);
  }, [id]);

  useEffect(() => {
    ophalenTransportopdrachtregels();
  }, [ophalenTransportopdrachtregels]);

  const ophalenServicemeldingen = useCallback(async () => {
    const meldingenResult = await api.v2.service.ophalenMeldingen({
      filterSchema: { filters: [{ naam: 'PROD_IDS', data: [id] }] },
    });

    const meldingen = _.orderBy(meldingenResult.meldingen, ['Melddatum'], ['desc']);

    setServicemeldingen(meldingen);
  }, [id]);

  useEffect(() => {
    ophalenServicemeldingen();
  }, [ophalenServicemeldingen]);

  const ophalenContracten = useCallback(async () => {
    if (product === null) {
      return;
    }

    const contractenResult = await api.v2.contract.ophalenContractenV2({
      filterSchema: { filters: [{ naam: 'CNTBASIS_IDS', data: [product.CntBasisID] }] },
    });

    setContracten(contractenResult.contracten);
  }, [product]);

  useEffect(() => {
    ophalenContracten();
  }, [ophalenContracten]);

  const ophalenRelaties = useCallback(async () => {
    if (contracten === null) {
      return;
    }

    const relIDs = _.uniq(contracten.map((x) => x.RelID));

    const relatiesResult = await api.v2.relatie.ophalenRelaties({
      filterSchema: { filters: [{ naam: 'IDS', data: relIDs }] },
    });

    setRelaties(relatiesResult.relaties);
  }, [contracten]);

  useEffect(() => {
    ophalenRelaties();
  }, [ophalenRelaties]);

  const ophalenServiceopdrachten = useCallback(async () => {
    if (servicemeldingen === null) {
      return;
    }
    const opdrachtenResult = (
      await api.v2.service.ophalenOpdrachten({
        filterSchema: {
          filters: [{ naam: 'SERVMELD_IDS', data: servicemeldingen.map((x) => x.ID) }],
        },
      })
    ).opdrachten;

    const opdrachten = _.orderBy(opdrachtenResult, ['DatumVerstuurd'], ['desc']);

    setServiceopdrachten(opdrachten);
  }, [servicemeldingen]);

  useEffect(() => {
    ophalenServiceopdrachten();
  }, [ophalenServiceopdrachten]);

  // const [inkoopfacturenVoorProduct, setInkoopfacturenVoorProduct] = useState<
  //   IOphalenInkoopfacturenVoorProductenResultElement[] | null
  // >(null);

  // const ophalenInkoopfacturenVoorProduct = useCallback(async () => {
  //   if (inkoopfacturenVoorProduct === null) {
  //     return;
  //   }

  //   const inkoopfacturenResult = await api.v2.product.ophalenInkoopfacturenVoorProducten({
  //     prodIDs: [id],
  //   });

  //   setInkoopfacturenVoorProduct(inkoopfacturenResult.facturenVoorProducten);
  // }, [id]);

  // useEffect(() => {
  //   ophalenInkoopfacturenVoorProduct();
  // }, [ophalenInkoopfacturenVoorProduct]);

  const ophalenWerkbonnen = useCallback(async () => {
    if (serviceopdrachten === null) {
      return;
    }

    if (serviceopdrachten.length === 0) {
      setWerkbonnen([]);
      return;
    }

    const werkbonnenResult = (
      await api.v2.service.ophalenWerkbonnen({
        filterSchema: {
          filters: [{ naam: 'SERVOPD_IDS', data: serviceopdrachten.map((x) => x.ServOpdID) }],
        },
      })
    ).werkbonnen;

    const werkbonnen = _.orderBy(werkbonnenResult, ['Bezoekdatum'], ['desc']);

    setWerkbonnen(werkbonnen);
  }, [serviceopdrachten]);

  useEffect(() => {
    ophalenWerkbonnen();
  }, [ophalenWerkbonnen]);

  const [verkoopvoorstellen, setVerkoopvoorstellen] = useState<
    IOphalenVerkoopVoorstellenResultElement[] | null
  >(null);

  const ophalenVerkoopvoorstellen = useCallback(async () => {
    const voorstellenResult = await api.v2.product.verkoop.ophalenVerkoopvoorstellen({
      filterSchema: { filters: [{ naam: 'PROD_IDS', data: [id] }] },
    });

    const voorstellen = _.orderBy(voorstellenResult.voorstellen, ['GeldigVan'], ['desc']);

    setVerkoopvoorstellen(voorstellen);
  }, [id]);

  useEffect(() => {
    ophalenVerkoopvoorstellen();
  }, [ophalenVerkoopvoorstellen]);

  const [pendelregels, setPendelregels] = useState<IPendelregels[] | null>(null);

  const ophalenPendelregels = useCallback(async () => {
    const regelsResult = (
      await api.v2.pendel.ophalenOpdrachtregels({
        filterSchema: {
          filters: [{ naam: 'PROD_IDS', data: [id] }],
        },
      })
    ).regels;
    const penOpdIDs = _.uniq(regelsResult.map((x) => x.PenOpdID));

    const opdrachten = (
      await api.v2.pendel.ophalenOpdrachten({
        filterSchema: {
          filters: [{ naam: 'IDS', data: penOpdIDs }],
        },
      })
    ).opdrachten;

    const regels = regelsResult.map((regel) => {
      const opdracht = opdrachten.find((x) => x.ID === regel.PenOpdID)!;
      return { ...regel, opdracht };
    });

    setPendelregels(regels);
  }, [id]);

  useEffect(() => {
    ophalenPendelregels();
  }, [ophalenPendelregels]);

  useEffect(() => {
    (async () => {
      const mutaties = (
        await api.v2.product.ophalenMutatiesReferentiecode({
          filterSchema: {
            filters: [{ naam: 'PROD_IDS', data: [id] }],
          },
        })
      ).mutaties;
      setMutatiesReferentiecode(mutaties);
    })();
  }, [id]);

  useEffect(() => {
    (async () => {
      const items = (
        await api.v2.boekhouding.activaregister.register.ophalenRegisterItems({
          filterSchema: {
            filters: [{ naam: 'PROD_IDS', data: [id] }],
          },
        })
      ).registerItems;

      const registeritem = items.length === 1 ? items[0] : null;
      setRegisteritem(registeritem);
    })();
  }, [id]);

  const ophalenMagazijnen = useCallback(async () => {
    const magazijnenResult = await api.v2.magazijn.ophalenMagazijnen({});

    setMagazijnen(magazijnenResult.magazijnen);
  }, [id]);

  useEffect(() => {
    ophalenMagazijnen();
  }, [ophalenMagazijnen]);

  const initialFormikValues = useMemo<IFormikValues | null>(() => {
    if (product === null) {
      return null;
    }

    return {
      notities: product.Notities,
    };
  }, [product]);

  // if (registeritem === undefined || pendelregels === null) {
  //   return <LoadingSpinner />;
  // }

  return (
    <Dialoog index={dialoogIndex || 0} modalProps={{ size: 'lg' }}>
      <ModalHeader>
        <ModalTitle>Productinformatie</ModalTitle>
      </ModalHeader>
      <div className="d-flex flex-column flex-fill" style={{ minHeight: `600px` }}>
        {metSelectie && <SelectieSectie onProdIDGekozen={setId} />}
        {id === null ? (
          <div className="d-flex flex-fill align-items-center justify-content-center">
            <span>Voer een zoekopdracht uit om te beginnen.</span>
          </div>
        ) : initialFormikValues === null || registeritem === undefined || pendelregels === null ? (
          <div className="d-flex flex-fill align-items-center justify-content-center">
            <LoadingSpinner />
          </div>
        ) : (
          <>
            <ProductContext.Provider
              value={{
                product,
                inkoopfactuur,
                servicemeldingen,
                serviceopdrachten,
                werkbonnen,
                transportopdrachtregels,
                verkoopprijs,
                relaties,
                verkoopvoorstellen,
                contracten,
                pendelregels,
                mutatiesReferentiecode,
                registeritem,
                magazijnen,
                // pendelopdrachten,
              }}
            >
              <Formik<IFormikValues>
                isInitialValid
                initialValues={initialFormikValues}
                //   validate={handleValidate}
                onSubmit={() => null}
                render={(FormikProps) => {
                  const { submitForm, isSubmitting, values, errors } = FormikProps;
                  return (
                    <>
                      <InactiefOverlay
                        isInactief={isBezigMetProductOphalen}
                        element={
                          <div className="d-flex flex-column flex-fill">
                            <Tabblad
                              tabbladen={tabbladen}
                              geselecteerd={tabblad}
                              onSelectieChange={(id) => {
                                setTabblad(id);
                              }}
                              options={{
                                tabbladComponentProps: {
                                  onAnnuleren: props.onAnnuleren,
                                },
                              }}
                            />
                          </div>
                        }
                        rootClassName="d-flex flex-fill"
                      />
                    </>
                  );
                }}
              />
            </ProductContext.Provider>
          </>
        )}
      </div>

      <ModalFooter className="d-flex flex-row justify-content-start">
        <button className="btn btn-primary" onClick={onAnnuleren} style={{ width: 100 }}>
          Sluiten
        </button>
        <div className="flex-fill" />
        {id !== null && (
          <TechnischeInformatieKnop
            dialoogIndex={(props.dialoogIndex ?? 0) + 1}
            content={() => (
              <div>
                ProdID: {props.id}
                <br />
                TypeID: {product?.producttype.TypeID}
                <br />
                MerkID: {product?.producttype.MerkID}
                <br />
                ProdSrtID: {product?.producttype.ProdSrtID}
                <br />
                LocID: {product?.locatie !== null ? product?.locatie.LocID : null}
              </div>
            )}
          />
        )}
      </ModalFooter>
    </Dialoog>
  );
};

export default ProductinfoDialoog;
