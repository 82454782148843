import styled from 'styled-components';
import { Kleur } from '../../../bedrijfslogica/constanten';

export interface IInputProps {
  disabled?: boolean;
}

export const Input = styled.div<IInputProps>`
  width: 100%;
  min-height: 30px;
  background-color: ${(props) => (props.disabled ? '#dedede' : Kleur.Wit)};
  border: 1px solid ${Kleur.LichtGrijs};
  border-radius: 3px;
  display: flex;
  cursor: ${(props) => (props.disabled ? 'inherit' : 'pointer')};
`;

export const Representatie = styled.div`
  padding: 4px 8px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
