import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { RootStoreContext } from '../../../../stores/RootStore';
import {
  DXTableCheckboxComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
  DXTableToggleCellComponent,
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
} from '../../../../helpers/dxTableGrid';
import {
  IOphalenBatchesResultElement,
  IOphalenSignaleringenBatchesResultElement,
} from '../../../../../../shared/src/api/v2/bank/opdracht/opdracht';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  VirtualTable,
  TableEditColumn,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  Column,
  DataTypeProvider,
  IntegratedSorting,
  RowDetailState,
  SelectionState,
  SortingState,
  VirtualTable as VirtualTableBase,
  EditingState,
} from '@devexpress/dx-react-grid';
import FormatteerBedrag from '../../../../components/MutatieBedrag';
import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import api from '../../../../api';
import * as _ from 'lodash';
import downloadUrl from '../../../../core/downloadUrl';
import RowDetailComp from './RowDetailComp';
import {
  IconDownload,
  IconInformatie,
  IconLock,
  IconSend,
  IconVerwijderen,
} from '../../../../components/Icons';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import { EResultType } from '../../../../stores/CheckStore';
import { EBatchstatus, bepaalStatus, statusNaam } from '../index';
import MenuLayout from '../../../../components/MenuLayout';
import {
  EStoplichtStatus,
  StoplichtIndicatie,
} from '../../../../components/formulier/StoplichtIndicatie';
import { RouteComponentProps, withRouter } from 'react-router';
import useUrlState from '../../../../core/useUrlState';
import WijzigBatchDialoog from './WijzigenBatchDialoog';
import VerwijderBatchDialoog from './VerwijderDialoog';
import FilterBalkV2, {
  IFilter,
  IFilterData,
  maakFilterSchema,
} from '../../../../components/FilterBalkV2';
import BatchInfoDialoog from '../../../../components/bank/BatchInfoDialoog';
import nameOf from '../../../../core/nameOf';
import { Helmet } from 'react-helmet';

interface IProps extends RouteComponentProps {}

enum EFilter {
  IsUitgevoerd = 'IS_UITGEVOERD',
}

enum ExportStatus {
  BatchesExporteren,
  BestandenMaken,
}

const geenData = {
  noData: 'Geen batches',
};

interface IBatchInfoDialoogState {
  bankBatchID: number;
}

interface IUrlState {
  selection: number[];
  uitgeklapt: number[];
  filterData: IFilterData<EFilter>[];
  batchInfoDialoogState: IBatchInfoDialoogState | null;
}
const defaultUrlState: IUrlState = {
  selection: [],
  uitgeklapt: [],
  filterData: [
    {
      naam: EFilter.IsUitgevoerd,
      data: false,
      isActief: true,
    },
  ],
  batchInfoDialoogState: null,
};

export interface IBatchesContext {
  onVerversenAangevraagd: () => void;
}
export const BatchesContext = React.createContext<IBatchesContext>(null as any);

export interface IWijzigBatchDialoogState {
  batchID: number;
}
export interface IVerwijderBatchDialoogState {
  batchIDs: number[];
}

const Batches: React.FC<IProps> = observer((props) => {
  const { checkStore } = useContext(RootStoreContext);
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const [batches, setBatches] = useState<IOphalenBatchesResultElement[] | null>(null);
  const [signaleringenResult, setSignaleringenResult] = useState<
    IOphalenSignaleringenBatchesResultElement[] | null
  >(null);

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.IsUitgevoerd,
        altijdWeergevenInBalk: true,
        actiefMuteerbaar: true,
        weergave: (weergaveProps) => {
          return <span>Alleen niet uitgevoerde batches</span>;
        },
      },
    ],
    [],
  );

  const [filterSchema, setFilterSchema] = useState(maakFilterSchema(urlState.filterData));

  // const [filterSchema, setFilterSchema] = useState(
  //   maakFilterSchema(useMemo(() => [...filterdata], [])),
  // );

  useEffect(() => {
    if (filterSchema === null && urlState.filterData !== null) {
      setFilterSchema(maakFilterSchema(urlState.filterData));
    }
  }, [filterSchema, urlState.filterData]);

  const [
    wijzigBatchDialoogState,
    setWijzigBatchDialoogState,
  ] = useState<IWijzigBatchDialoogState | null>(null);
  const [
    verwijderBatchDialoogState,
    setVerwijderBatchDialoogState,
  ] = useState<IVerwijderBatchDialoogState | null>(null);

  const ophalenBatches = useCallback(async () => {
    const batchesResult = await api.v2.bank.opdracht.ophalenBatches({
      filterSchema: {
        filters: [...filterSchema.filters!],
      },
      orderSchema: { orders: [{ naam: 'BATCHNUMMER', richting: 'DESC' }] },
      paginatie: { index: 0, aantal: 1000 },
    });

    // const result = _.orderBy(
    //   batchesResult,
    //   [
    //     (x: IOphalenBatchesResultElement) => {
    //       return x.UitgevoerdOp === null ? 0 : 1;
    //     },
    //     (x: IOphalenBatchesResultElement) => {
    //       return format(new Date(x.Uitvoerdatum!), 'yyyy-MM-dd');
    //     },
    //     'Batchnummer',
    //   ],
    //   ['asc', 'desc', 'desc'],
    // );

    setBatches(batchesResult);
  }, [filterSchema.filters!]);

  useEffect(() => {
    ophalenBatches();
  }, [ophalenBatches]);

  const ophalenSignaleringen = useCallback(async () => {
    if (batches === null) {
      return;
    }

    const result = (
      await api.v2.bank.opdracht.ophalenSignaleringenBatches({
        batchIDs: batches.filter((x) => !x.IsUitgevoerd).map((x) => x.BatchID),
      })
    ).batches;
    // const result = (
    //   await api.v2.bank.opdracht.ophalenSignaleringenBatches({
    //     batchIDs: batches.map((x) => x.BatchID),
    //   })
    // ).batches;

    setSignaleringenResult(result);
  }, [batches]);

  useEffect(() => {
    ophalenSignaleringen();
  }, [ophalenSignaleringen]);

  const kolommen = useMemo<TypedColumn<IOphalenBatchesResultElement>[]>(() => {
    return [
      {
        name: 'Batchnummer',
        title: 'Nr.',
      },
      {
        name: '__signaleringen' as any,
        title: 'Sign.',
      },
      {
        name: 'Uitvoerdatum',
        title: 'Uitv.datum',
      },
      {
        name: 'DatumAanleveren',
        title: 'Uiterl. aanlev.',
      },
      {
        name: 'RekeningnaamKort',
        title: 'Rek.',
      },
      {
        name: 'Opdrachtsoortnaam',
        title: 'Soort',
      },
      {
        name: 'Bedrag',
        title: 'Bedrag',
      },
      {
        name: 'AantalOpdrachten',
        title: '# Opdr.',
      },
      {
        name: '__status' as any,
        title: 'Status',
        getCellValue: (x) => {
          const status = bepaalStatus(x);
          const statusText = statusNaam[status];
          return statusText;
        },
      },
      {
        name: 'Gesloten' as any,
        title: ' ',
      },
      {
        name: 'UitgevoerdOp',
        title: 'Uitgevoerd',
      },
      {
        name: '__geboekt' as any,
        title: 'Geboekt',
      },
      {
        name: 'VasteOmschrijving',
        title: 'Vaste omschrijving',
      },
      {
        name: '__batchinfo' as any,
        title: ' ',
      },
      {
        name: 'RecordToegevoegd',
        title: 'Geregisteerd',
      },
    ];
  }, []);

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IOphalenBatchesResultElement>[]>(() => {
    return [
      {
        columnName: 'Batchnummer',
        width: 60,
      },
      {
        columnName: 'Uitvoerdatum',
        width: 125,
      },
      {
        columnName: 'DatumAanleveren',
        width: 125,
      },
      {
        columnName: 'RekeningnaamKort',
        width: 90,
      },
      {
        columnName: 'Opdrachtsoortnaam',
        width: 100,
      },
      {
        columnName: 'Bedrag',
        width: 110,
      },
      {
        columnName: 'AantalOpdrachten',
        width: 90,
      },
      {
        columnName: '__signaleringen' as any,
        width: 70,
      },
      {
        columnName: '__status' as any,
        width: 210,
      },
      {
        columnName: 'VasteOmschrijving',
        width: 175,
      },
      {
        columnName: 'UitgevoerdOp',
        width: 135,
      },
      {
        columnName: '__geboekt' as any,
        width: 95,
      },
      {
        columnName: '__batchinfo' as any,
        width: 75,
      },
      {
        columnName: 'Gesloten' as any,
        width: 75,
      },
      {
        columnName: 'RecordToegevoegd',
        width: 135,
      },
    ];
  }, []);

  const kolomExtensies: VirtualTableBase.ColumnExtension[] = useMemo(() => {
    return [
      // {
      //   columnName: 'Bedrag',
      //   align: 'right',
      // },
      // {
      //   columnName: 'AantalOpdrachten',
      //   align: 'right',
      // },
    ];
  }, []);

  const keyExtractor = useCallback((row: IOphalenBatchesResultElement) => row.BatchID, []);

  const handleVerstuurd = useCallback(async () => {
    const params = {
      batchIDs: urlState.selection,
    };
    const checkData = await api.v2.bank.opdracht.checkBevestigenVerstuurd(params);
    if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
      return;
    }

    if (
      (
        await checkStore.bevestigen({
          inhoud: <div>Wil je de geselecteerde batches markeren als Verstuurd bij de bank?</div>,
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    await api.v2.bank.opdracht.bevestigenVerstuurd(params);

    ophalenBatches();
  }, [urlState.selection]);

  const handleBijwerkenIBANWijzigingen = useCallback(async () => {
    // const opdrachtenResult = (
    //   await api.v2.bank.opdracht.ophalenOpdrachten({
    //     filterSchema: {
    //       filters: [
    //         { naam: 'BATCH_IDS', data: urlState.selection },
    //         { naam: 'SOORT', data: 'INCASSO' },
    //       ],
    //     },
    //   })
    // ).opdrachten;
    // const bankOpdIDs = opdrachtenResult
    //   .filter(
    //     (x) => x.batch !== null && x.batch.BestandID === null && x.batch.DatumVerstuurd === null,
    //   )
    //   .map((x) => x.BankOpdID);

    // if (bankOpdIDs.length === 0) {
    //   await checkStore.melden({
    //     titel: 'Er zijn geen incasso-opdrachten gevonden voor deze bewerking',
    //   });
    //   return;
    // }

    // const checkData = await api.v2.bank.opdracht.checkVerwerkOpdrachtenMetGewijzigdeIBAN({
    //   bankOpdIDs,
    // });
    // if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
    //   return;
    // }

    if (
      (
        await checkStore.bevestigen({
          inhoud: <div>Wil je voor de geselecteerde batches de IBAN-wijzigingen bijwerken?</div>,
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    await api.v2.bank.opdracht.verwerkOpdrachtenMetGewijzigdeIBAN({ batchIDs: urlState.selection });

    ophalenBatches();
  }, [urlState.selection]);

  // const handleVerwijderen = useCallback(async () => {
  //   const checkData = await api.v2.bank.opdracht.checkVerwijderenBatches({
  //     batchIDs: urlState.selection,
  //   });
  //   if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
  //     return;
  //   }
  //   if (
  //     (
  //       await checkStore.bevestigen({
  //         titel: `Wil je de ${urlState.selection.length} bankbatches verwijderen?`,
  //       })
  //     ).type === EResultType.Annuleren
  //   ) {
  //     return;
  //   }

  //   await api.v2.bank.opdracht.verwijderenBatches({
  //     batchIDs: urlState.selection,
  //   });

  //   ophalenBatches();
  // }, [urlState.selection]);

  const handleExporteren = useCallback(async () => {
    // if (
    //   (
    //     await checkStore.bevestigen({
    //       titel: `Wil je voor de ${selection.length} batches bestanden maken?`,
    //     })
    //   ).type === EResultType.Annuleren
    // ) {
    //   return;
    // }

    const params = {
      batchIDs: urlState.selection,
    };
    const checkData = await api.v2.bank.opdracht.checkExporterenBatches(params);

    if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
      return;
    }

    if (
      (
        await checkStore.bevestigen({
          inhoud: (
            <span>
              Wil je voor de {urlState.selection.length} geselecteerde batches bestanden downloaden?
            </span>
          ),
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }
    const exportResult = await api.v2.bank.opdracht.exporterenBatches({
      batchIDs: urlState.selection,
    });

    const bestandenResult = await api.v2.bank.opdracht.bestandenMakenVanBatches({
      batches: exportResult,
    });

    const tokens = Object.keys(bestandenResult.bestandMapping);
    const bestanden = (
      await api.v2.bestand.ophalenBestanden({
        filterSchema: { filters: [{ naam: 'TOKENS', data: tokens }] },
      })
    ).bestanden;

    bestanden.forEach((bestand, i) => {
      const token = tokens[i];
      downloadUrl(bestand.url, bestand.Naam);
    });

    await ophalenBatches();
  }, [urlState.selection]);

  const context = useMemo<IBatchesContext>(
    () => ({
      onVerversenAangevraagd: async () => await ophalenBatches(),
    }),
    [ophalenBatches],
  );

  return (
    <>
      <Helmet>
        <title>Bankbatches</title>
      </Helmet>
      <BatchesContext.Provider value={context}>
        <MenuLayout
          menu={
            <div className="d-flex">
              <div className="d-flex align-items-center">
                <button
                  className="btn btn-sm btn-light d-flex align-items-center"
                  style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                  onClick={handleExporteren}
                  disabled={urlState.selection.length === 0}
                >
                  <IconDownload style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                  <span className="ml-2">Bestand maken</span>
                </button>

                <button
                  className="btn btn-sm btn-light d-flex align-items-center ml-3"
                  style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                  onClick={handleVerstuurd}
                  disabled={urlState.selection.length === 0}
                >
                  <IconSend style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                  <span className="ml-2">Markeren Verstuurd</span>
                </button>

                <button
                  className="btn btn-sm btn-light d-flex align-items-center ml-3"
                  style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                  onClick={handleBijwerkenIBANWijzigingen}
                  disabled={urlState.selection.length === 0}
                >
                  {/* <IconSend style={{ width: 16, height: 16, fill: Kleur.Grijs }} /> */}
                  <span className="ml-2">Bijwerken IBAN-wijzigingen</span>
                </button>

                <button
                  className="btn btn-sm btn-light d-flex align-items-center ml-3"
                  style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                  onClick={async () => {
                    const checkData = await api.v2.bank.opdracht.checkVerwijderenBatchesInitieel({
                      batchIDs: [urlState.selection[0]],
                    });
                    if (
                      (await checkStore.controleren({ checkData })).type === EResultType.Annuleren
                    ) {
                      return;
                    }

                    setVerwijderBatchDialoogState({ batchIDs: urlState.selection });
                  }}
                  disabled={urlState.selection.length === 0}
                >
                  <IconVerwijderen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                  <span className="ml-2">Verwijderen</span>
                </button>

                <div className="d-flex flex-fill">
                  <FilterBalkV2
                    filterData={urlState.filterData}
                    filters={filters}
                    onFilterDataChange={(x) => setUrlStateSync('filterData', x)}
                    onFilterSchemaChange={(x) => setFilterSchema(x)}
                  />
                </div>
              </div>
            </div>
          }
          body={
            <div
              style={{
                maxHeight: 'calc(100vh - 150px)',
                minHeight: 'calc(100vh - 150px)',
                overflowY: 'auto',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {batches === null || signaleringenResult === null ? (
                <div className="flex-fill d-flex align-items-center justify-content-center">
                  <LoadingSpinner />
                </div>
              ) : (
                <GridStyleWrapper height="calc(100vh - 150px)">
                  <Grid columns={kolommen as Column[]} rows={batches || []} getRowId={keyExtractor}>
                    <DataTypeProvider
                      for={[nameOf<IOphalenBatchesResultElement>('Bedrag')]}
                      formatterComponent={(props) => {
                        const rij = props.row as IOphalenBatchesResultElement;
                        return (
                          <span>
                            <FormatteerBedrag
                              bedrag={rij.Soort === 1 ? props.value : -props.value}
                            />
                          </span>
                        );
                      }}
                    />

                    <DataTypeProvider
                      for={[nameOf<IOphalenBatchesResultElement>('Uitvoerdatum')]}
                      formatterComponent={(props) => {
                        const date = new Date(props.value);
                        return (
                          <>
                            <span>{format(date, 'dd-MM-yyyy')}</span>
                            {/*<span className="ml-2">{format(date, 'HH:mm')}</span>*/}
                          </>
                        );
                      }}
                    />

                    <DataTypeProvider
                      for={[nameOf<IOphalenBatchesResultElement>('DatumAanleveren')]}
                      formatterComponent={(props) => {
                        const date = new Date(props.value);
                        return (
                          <>
                            <span>{props.value !== null ? format(date, 'dd-MM HH:mm') : ''}</span>
                          </>
                        );
                      }}
                    />

                    <DataTypeProvider
                      for={[nameOf<IOphalenBatchesResultElement>('UitgevoerdOp')]}
                      formatterComponent={(props) => {
                        if (props.value === null) {
                          return <span>Nog niet</span>;
                        }

                        const date = new Date(props.value);
                        return (
                          <>
                            <span>{format(date, 'dd-MM-yyyy')}</span>
                          </>
                        );
                      }}
                    />

                    <DataTypeProvider
                      for={[nameOf<IOphalenBatchesResultElement>('RecordToegevoegd')]}
                      formatterComponent={(formatterProps) => {
                        return (
                          <>
                            <span>
                              {format(new Date(formatterProps.value), 'dd-MM-yyyy HH:mm')}
                            </span>
                          </>
                        );
                      }}
                    />

                    <DataTypeProvider
                      for={[nameOf<IOphalenBatchesResultElement>('AantalOpdrachten')]}
                      formatterComponent={(props) => (
                        <span className="pull-right">{props.value}</span>
                      )}
                    />

                    <DataTypeProvider
                      for={['__signaleringen']}
                      formatterComponent={(props) => {
                        const rij: IOphalenBatchesResultElement = props.row;
                        if (rij.bankmutatie !== null) {
                          return <span></span>;
                        }

                        // TODO Gil
                        const signalering = signaleringenResult.find(
                          (x) => x.BatchID === rij.BatchID,
                        )!;

                        if (signalering === undefined) {
                          return <span></span>;
                        }

                        if (signalering.errors.length !== 0) {
                          // return (
                          //   <div className="d-flex flexfill align-items-center justify-content-center">
                          //     <StoplichtIndicatie status={EStoplichtStatus.Rood} />
                          //     <span className="ml-1" style={{ fontSize: 12 }}>
                          //       ({signalering.errors.length})
                          //     </span>
                          //   </div>
                          // );
                          return <StoplichtIndicatie status={EStoplichtStatus.Rood} />;
                        }
                        if (signalering.warnings.length !== 0) {
                          // return (
                          //   <div className="d-flex flexfill align-items-center ">
                          //     <StoplichtIndicatie status={EStoplichtStatus.Oranje} />
                          //     <span className="ml-1" style={{ fontSize: 12 }}>
                          //       ({signalering.warnings.length})
                          //     </span>
                          //   </div>
                          // );
                          return <StoplichtIndicatie status={EStoplichtStatus.Oranje} />;
                        }

                        return <StoplichtIndicatie status={EStoplichtStatus.Groen} />;
                      }}
                    />

                    <DataTypeProvider
                      for={['__status']}
                      formatterComponent={(props) => {
                        const el = props.row as IOphalenBatchesResultElement;
                        const status = bepaalStatus(el);
                        const statusText = statusNaam[status];
                        if (status === EBatchstatus.BestandMaken) {
                          return <span style={{ color: Kleur.Rood }}>{statusText}</span>;
                        }
                        return <span>{statusText}</span>;
                      }}
                    />

                    <DataTypeProvider
                      for={['__geboekt']}
                      formatterComponent={(formatterProps) => {
                        const rij = formatterProps.row as IOphalenBatchesResultElement;
                        if (rij.bankmutatie === null) {
                          return <span></span>;
                        }
                        const geboekt = rij.bankmutatie.Geboekt;
                        return geboekt ? <span>Ja</span> : <span>Nee</span>;
                      }}
                    />

                    <DataTypeProvider
                      for={['__batchinfo']}
                      formatterComponent={(formatterProps) => {
                        const rij = formatterProps.row as IOphalenBatchesResultElement;

                        return (
                          <a
                            href="#"
                            // style={{ color: Kleur.DonkerGrijs }}
                            onClick={() => {
                              setUrlStateSync('batchInfoDialoogState', {
                                bankBatchID: rij.BatchID,
                              });
                            }}
                          >
                            <IconInformatie style={{ width: 15, height: 15, fill: Kleur.Blauw }} />
                          </a>
                        );
                      }}
                    />

                    <DataTypeProvider
                      for={[nameOf<IOphalenBatchesResultElement>('Gesloten')]}
                      formatterComponent={(props) => {
                        const rij: IOphalenBatchesResultElement = props.row;

                        if (rij.Gesloten) {
                          return <IconLock style={{ width: 16, height: 16, fill: Kleur.Grijs }} />;
                        }

                        return <span></span>;
                      }}
                    />

                    {/* <DataTypeProvider
                  for={['__acties']}
                  formatterComponent={(props) => {
                    const el = props.row as IOphalenBatchesResultElement;
                    const status = bepaalStatus(el);

                    if (status === Status.InlezenBank) {
                      return (
                        <a href="#" onClick={() => handleVerstuurd(el.BatchID)}>
                          Goedkeuren
                        </a>
                        // <button
                        //   className="btn btn-sm btn-light"
                        //   onClick={() => setGoedgekeurdBevestigenBatchID(el.BatchID)}
                        // >
                        //   Goedkeuren
                        // </button>
                      );
                    }

                    return <span />;
                  }}
                /> */}

                    <SortingState defaultSorting={[]} />
                    <IntegratedSorting />

                    {/* <VirtualTable /> */}
                    <VirtualTable messages={geenData} columnExtensions={kolomExtensies} />

                    <TableColumnResizing defaultColumnWidths={kolomBreedtes} />

                    <TableHeaderRow showSortingControls />
                    <EditingState
                      onCommitChanges={async (changes) => {
                        if (changes.deleted === undefined) {
                          return;
                        }
                      }}
                      onEditingRowIdsChange={async (rowIds) => {
                        const id = rowIds[rowIds.length - 1] as number;

                        const checkData = await api.v2.bank.opdracht.checkWijzigenBatchInitieel({
                          batchID: id,
                        });
                        const checkResult = await checkStore.controleren({
                          checkData,
                        });
                        if (checkResult.type === EResultType.Annuleren) {
                          return;
                        }

                        setWijzigBatchDialoogState({ batchID: id });
                      }}
                    />

                    <TableEditColumn
                      width={35}
                      showEditCommand
                      cellComponent={DXTableEditColumnCellComponent}
                      commandComponent={DXTableEditColumnCommandComponent}
                    />

                    <RowDetailState
                      expandedRowIds={urlState.uitgeklapt}
                      onExpandedRowIdsChange={(x) => setUrlStateSync('uitgeklapt', x as number[])}
                    />
                    <TableRowDetail
                      contentComponent={RowDetailComp}
                      toggleCellComponent={DXTableToggleCellComponent}
                    />

                    <SelectionState
                      selection={urlState.selection}
                      onSelectionChange={(selection) =>
                        setUrlStateSync('selection', selection as number[])
                      }
                    />
                    <TableSelection cellComponent={DXTableCheckboxComponent} />

                    {/* <SortingState defaultSorting={[]} />
                <FilteringState
                  filters={filters}
                  onFiltersChange={(filters) => setFilters(filters)}
                />
                <IntegratedFiltering columnExtensions={integratedFilteringColumnExtensions} />
                <IntegratedSorting />
                <SelectionState
                  selection={selection}
                  onSelectionChange={(selection) => setSelection(selection)}
                />
                <RowDetailState defaultExpandedRowIds={[]} />
                <VirtualTable estimatedRowHeight={10} />
                <TableColumnResizing
                  defaultColumnWidths={kolomBreedtes as TableColumnWidthInfo[]}
                />
                <TableSelection cellComponent={DXTableCheckboxComponent} />

                <TableRowDetail contentComponent={RowDetailComp} />

                <TableHeaderRow showSortingControls />
                <Table messages={geenData} /> */}
                  </Grid>
                </GridStyleWrapper>
              )}
            </div>
          }
        />
      </BatchesContext.Provider>

      {wijzigBatchDialoogState !== null && (
        <WijzigBatchDialoog
          open
          batchID={wijzigBatchDialoogState.batchID}
          onSuccess={async () => {
            ophalenBatches();
            setWijzigBatchDialoogState(null);
          }}
          onAnnuleren={() => setWijzigBatchDialoogState(null)}
        />
      )}
      {verwijderBatchDialoogState !== null && (
        <VerwijderBatchDialoog
          open
          batchIDs={verwijderBatchDialoogState.batchIDs}
          onSuccess={() => {
            setUrlStateSync('selection', []);
            ophalenBatches();
            setVerwijderBatchDialoogState(null);
          }}
          onAnnuleren={() => setVerwijderBatchDialoogState(null)}
        />
      )}
      {urlState.batchInfoDialoogState !== null && (
        <BatchInfoDialoog
          open
          bankBatchID={urlState.batchInfoDialoogState.bankBatchID}
          onSuccess={async () => {
            setUrlStateSync('batchInfoDialoogState', null);
          }}
          onAnnuleren={() => setUrlStateSync('batchInfoDialoogState', null)}
        />
      )}
    </>
  );
});

export default withRouter(Batches);
