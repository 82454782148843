import styled from 'styled-components';
import { Kleur } from '../../../../../bedrijfslogica/constanten';

export const Root = styled.div`
  //background-color: rgba(150, 150, 150, 0.95);
  //background-color: #0775b7;
  background-color: #f8f8f8;
  height: 46px;

  input {
    width: 100%;
  }

  .notities-input {
    border: 0;
    outline: 0;
    padding: 3px 3px;
    background: 0;

    ::placeholder {
      font-style: italic;
    }
  }
`;
