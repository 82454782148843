import {
  IWijzigenOpdrachtParams,
  IWijzigenOpdrachtResult,
  IBepaalTransporteurVoorOpdrachtenParams,
  IBepaalTransporteurVoorOpdrachtenResult,
  IVerwijderenBezoektijdenParams,
  IVerwijderenBezoektijdenResult,
  IBepalenOpdrachtenVoorToevoegenPremiumParams,
  IBepalenOpdrachtenVoorToevoegenPremiumResult,
  IOphalenRegelstatussenParams,
  IOphalenRegelstatussenResult,
  IOphalenTransportopdrachtBestandenParams,
  IOphalenTransportopdrachtBestandenResult,
  IBijwerkenBestandskoppelingenVanTransportopdrachtParams,
  IBijwerkenBestandskoppelingenVanTransportopdrachtResult,
  IKoppelenProductVoorTransportregelParams,
  IKoppelenProductVoorTransportregelResult,
  IWijzigenReserveringVoorTransportregelParams,
  IWijzigenReserveringVoorTransportregelResult,
  IToevoegenOpdrachtregelsParams,
  IToevoegenOpdrachtregelsResult,
  IOphalenOpdrachtregelsParamsV2,
  IOphalenOpdrachtregelsResultV2,
  IOphalenOpdrachtenParamsV2,
  IOphalenOpdrachtenResultV2,
  IOphalenOpdrachtstatussenParams,
  IOphalenOpdrachtstatussenResult,
  IOphalenOpdrachtenMetRegelsParams,
  IOphalenOpdrachtenMetRegelsResult,
  IToevoegenOpdrachtenParams,
  IToevoegenOpdrachtenResult,
  IOpgevenPremiumsParams,
  IOpgevenPremiumsResult,
  IOphalenOpdrachteventsParams,
  IOphalenOpdrachteventsResult,
  IOphalenOpdrachteventsoortenParams,
  IOphalenOpdrachteventsoortenResult,
  IVersturenVerzoekDoorgevenReferentiecodesParams,
  IVersturenVerzoekDoorgevenReferentiecodesResult,
  IToevoegenPremiumregelsParams,
  IToevoegenPremiumregelsResult,
  IOphalenIndicatiesPremiumParams,
  IOphalenIndicatiesPremiumResult,
  IKoppelenAfleverbewijsParams,
  IKoppelenAfleverbewijsResult,
  IVersturenDocumentatieNaLeveringParams,
  IVersturenDocumentatieNaLeveringResult,
  IToevoegenProductreferentiesOpgevraagdParams,
  IToevoegenProductreferentiesOpgevraagdResult,
  IOphalenProductreferentiesOpgevraagdParams,
  IOphalenProductreferentiesOpgevraagdResult,
  IVerplaatsenOpdrachtregelsParams,
  IVerplaatsenOpdrachtregelsResult,
  IMarkerenFacturatieBeoordeeldParams,
  IMarkerenFacturatieBeoordeeldResult,
} from '../../../../../../shared/src/api/v2/transport/opdracht';
import {
  IOphalenOpdrachtenParams,
  IOphalenOpdrachtenResult,
  IOphalenOpdrachtregelsParams,
  IOphalenOpdrachtregelsResult,
  IVerwijderenOpdrachtenParams,
  IVerwijderenOpdrachtenResult,
  IVerwijderenOpdrachtregelsParams,
  IVerwijderenOpdrachtregelsResult,
  IWijzigenOpdrachtregelParams,
  IWijzigenOpdrachtregelResult,
  IWijzigenOpdrachtregelVoorraadParams,
  IWijzigenOpdrachtregelVoorraadResult,
  IKopieerenOpdrachtregelsParams,
  IKopieerenOpdrachtregelsResult,
  // IToevoegenPremiumregelsParams,
  // IToevoegenPremiumregelsResult,
  IOphalenRegelsoortenParams,
  IOphalenRegelsoortenResult,
  IOphalenBestandsoverdrachtenParams,
  IOphalenBestandsoverdrachtenResult,
} from '../../../../../../shared/src/api/v2/transport/opdracht';

import ICheckData from '../../../../../../shared/src/models/ICheckData';
import api from '../../../index';
import afgehandeld from './afgehandeld';
import levering from './levering';
import retour from './retour';
import referentie from './referentie';
import uitstaand from './uitstaand';
import omruil from './omruil';
import nieuw from './nieuw';
import premium from './premium';

const opdracht = {
  premium,
  nieuw,
  uitstaand,
  afgehandeld,
  levering,
  retour,
  referentie,
  omruil,
  ophalenOpdrachtevents: async (
    params: IOphalenOpdrachteventsParams,
  ): Promise<IOphalenOpdrachteventsResult> => {
    return await api.post('v2/transport/opdracht/ophalen-opdrachtevents', params);
  },
  versturenVerzoekDoorgevenReferentiecodes: async (
    params: IVersturenVerzoekDoorgevenReferentiecodesParams,
  ): Promise<IVersturenVerzoekDoorgevenReferentiecodesResult> => {
    return await api.post(
      'v2/transport/opdracht/versturen-verzoek-doorgeven-referentiecodes',
      params,
    );
  },
  ophalenOpdrachteventsoorten: async (
    params: IOphalenOpdrachteventsoortenParams,
  ): Promise<IOphalenOpdrachteventsoortenResult> => {
    return await api.post('v2/transport/opdracht/ophalen-opdrachteventsoorten', params);
  },
  ophalenOpdrachtregels: async (
    params: IOphalenOpdrachtregelsParams,
  ): Promise<IOphalenOpdrachtregelsResult> => {
    return await api.post('v2/transport/opdracht/ophalen-opdrachtregels', params);
  },
  ophalenOpdrachtregelsV2: async (
    params: IOphalenOpdrachtregelsParamsV2,
    signal?: AbortSignal,
  ): Promise<IOphalenOpdrachtregelsResultV2> => {
    return await api.post('v2/transport/opdracht/ophalen-opdrachtregels-v2', params, {
      signal,
    });
  },
  checkWijzigenOpdracht: async (params: IWijzigenOpdrachtParams): Promise<ICheckData> => {
    return await api.post('v2/transport/opdracht/check-wijzigen-opdracht', params);
  },
  wijzigenOpdracht: async (params: IWijzigenOpdrachtParams): Promise<IWijzigenOpdrachtResult> => {
    return await api.post('v2/transport/opdracht/wijzigen-opdracht', params);
  },
  bepaalTransporteurVoorOpdrachten: async (
    params: IBepaalTransporteurVoorOpdrachtenParams,
  ): Promise<IBepaalTransporteurVoorOpdrachtenResult> => {
    return await api.post('v2/transport/opdracht/bepaal-transporteur-voor-opdrachten', params);
  },
  checkVerwijderenOpdrachten: async (params: IVerwijderenOpdrachtenParams): Promise<ICheckData> => {
    return await api.post('v2/transport/opdracht/check-verwijderen-opdrachten', params);
  },
  verwijderenOpdrachten: async (
    params: IVerwijderenOpdrachtenParams,
  ): Promise<IVerwijderenOpdrachtenResult> => {
    return await api.post('v2/transport/opdracht/verwijderen-opdrachten', params);
  },
  checkVerwijderenOpdrachtregels: async (
    params: IVerwijderenOpdrachtregelsParams,
  ): Promise<ICheckData> => {
    return await api.post('v2/transport/opdracht/check-verwijderen-opdrachtregels', params);
  },
  verwijderenOpdrachtregels: async (
    params: IVerwijderenOpdrachtregelsParams,
  ): Promise<IVerwijderenOpdrachtregelsResult> => {
    return await api.post('v2/transport/opdracht/verwijderen-opdrachtregels', params);
  },
  ophalenOpdrachten: async (
    params: IOphalenOpdrachtenParams,
  ): Promise<IOphalenOpdrachtenResult> => {
    return await api.post('v2/transport/opdracht/ophalen-opdrachten', params);
  },
  ophalenOpdrachtenMetRegels: async (
    params: IOphalenOpdrachtenMetRegelsParams,
  ): Promise<IOphalenOpdrachtenMetRegelsResult> => {
    return await api.post('v2/transport/opdracht/ophalen-opdrachten-met-regels', params);
  },
  ophalenOpdrachtenV2: async (
    params: IOphalenOpdrachtenParamsV2,
    signal?: AbortSignal,
  ): Promise<IOphalenOpdrachtenResultV2> => {
    return await api.post('v2/transport/opdracht/ophalen-opdrachten-v2', params, {
      signal,
    });
  },
  // checkToevoegenOpdrachtregels: async (
  //   params: IToevoegenOpdrachtregelsParams,
  // ): Promise<ICheckData> => {
  //   return await api.post('v2/transport/opdracht/check-toevoegen-opdrachtregels', params);
  // },
  toevoegenOpdrachtregels: async (
    params: IToevoegenOpdrachtregelsParams,
  ): Promise<IToevoegenOpdrachtregelsResult> => {
    return await api.post('v2/transport/opdracht/toevoegen-opdrachtregels', params);
  },
  verwijderenBezoektijden: async (
    params: IVerwijderenBezoektijdenParams,
  ): Promise<IVerwijderenBezoektijdenResult> => {
    return await api.post('v2/transport/opdracht/verwijderen-bezoektijden', params);
  },
  wijzigenOpdrachtregel: async (
    params: IWijzigenOpdrachtregelParams,
  ): Promise<IWijzigenOpdrachtregelResult> => {
    return await api.post('v2/transport/opdracht/wijzigen-opdrachtregel', params);
  },
  checkWijzigenOpdrachtregelVoorraad: async (
    params: IWijzigenOpdrachtregelVoorraadParams,
  ): Promise<ICheckData> => {
    return await api.post('v2/transport/opdracht/check-wijzigen-opdrachtregel-voorraad', params);
  },
  wijzigenOpdrachtregelVoorraad: async (
    params: IWijzigenOpdrachtregelVoorraadParams,
  ): Promise<IWijzigenOpdrachtregelVoorraadResult> => {
    return await api.post('v2/transport/opdracht/wijzigen-opdrachtregel-voorraad', params);
  },
  kopieerenOpdrachtregels: async (
    params: IKopieerenOpdrachtregelsParams,
  ): Promise<IKopieerenOpdrachtregelsResult> => {
    return await api.post('v2/transport/opdracht/kopieeren-opdrachtregels', params);
  },
  checkToevoegenPremiumregels: async (
    params: IToevoegenPremiumregelsParams,
  ): Promise<ICheckData> => {
    return await api.post('v2/transport/opdracht/check-toevoegen-premiumregels', params);
  },
  toevoegenPremiumregels: async (
    params: IToevoegenPremiumregelsParams,
  ): Promise<IToevoegenPremiumregelsResult> => {
    return await api.post('v2/transport/opdracht/toevoegen-premiumregels', params);
  },
  checkOpgevenPremiums: async (params: IOpgevenPremiumsParams): Promise<ICheckData> => {
    return await api.post('v2/transport/opdracht/check-opgeven-premiums', params);
  },
  opgevenPremiums: async (params: IOpgevenPremiumsParams): Promise<IOpgevenPremiumsResult> => {
    return await api.post('v2/transport/opdracht/opgeven-premiums', params);
  },
  bepalenOpdrachtenVoorToevoegenPremium: async (
    params: IBepalenOpdrachtenVoorToevoegenPremiumParams,
  ): Promise<IBepalenOpdrachtenVoorToevoegenPremiumResult> => {
    return await api.post(
      'v2/transport/opdracht/bepalen-opdrachten-voor-toevoegen-premium',
      params,
    );
  },
  ophalenRegelsoorten: async (
    params: IOphalenRegelsoortenParams,
  ): Promise<IOphalenRegelsoortenResult> => {
    return await api.post('v2/transport/opdracht/ophalen-regelsoorten', params);
  },
  ophalenBestandsoverdrachten: async (
    params: IOphalenBestandsoverdrachtenParams,
  ): Promise<IOphalenBestandsoverdrachtenResult> => {
    return await api.post('v2/transport/opdracht/ophalen-bestandsoverdrachten', params);
  },
  ophalenRegelstatussen: async (
    params: IOphalenRegelstatussenParams,
  ): Promise<IOphalenRegelstatussenResult> => {
    return await api.post('v2/transport/opdracht/ophalen-regelstatussen', params);
  },
  ophalenOpdrachtstatussen: async (
    params: IOphalenOpdrachtstatussenParams,
  ): Promise<IOphalenOpdrachtstatussenResult> => {
    return await api.post('v2/transport/opdracht/ophalen-opdrachtstatussen', params);
  },
  ophalenTransportopdrachtBestanden: async (
    params: IOphalenTransportopdrachtBestandenParams,
  ): Promise<IOphalenTransportopdrachtBestandenResult> => {
    return await api.post('v2/transport/opdracht/ophalen-transportopdracht-bestanden', params);
  },
  bijwerkenBestandskoppelingenVanTransportopdracht: async (
    params: IBijwerkenBestandskoppelingenVanTransportopdrachtParams,
  ): Promise<IBijwerkenBestandskoppelingenVanTransportopdrachtResult> => {
    return await api.post(
      'v2/transport/opdracht/bijwerken-bestandskoppelingen-van-transportopdracht',
      params,
    );
  },
  koppelenProductVoorTransportregel: async (
    params: IKoppelenProductVoorTransportregelParams,
  ): Promise<IKoppelenProductVoorTransportregelResult> => {
    return await api.post('v2/transport/opdracht/koppelen-product-voor-transportregel', params);
  },
  wijzigenReserveringVoorTransportregel: async (
    params: IWijzigenReserveringVoorTransportregelParams,
  ): Promise<IWijzigenReserveringVoorTransportregelResult> => {
    return await api.post('v2/transport/opdracht/wijzigen-reservering-voor-transportregel', params);
  },
  checkBijwerkenBestandskoppelingenVanTransportopdracht: async (
    params: IBijwerkenBestandskoppelingenVanTransportopdrachtParams,
  ): Promise<ICheckData> => {
    return await api.post(
      'v2/transport/opdracht/check-bijwerken-bestandskoppelingen-van-transportopdracht',
      params,
    );
  },
  toevoegenOpdrachten: async (
    params: IToevoegenOpdrachtenParams,
  ): Promise<IToevoegenOpdrachtenResult> => {
    return await api.post('v2/transport/opdracht/toevoegen-opdrachten', params);
  },
  ophalenIndicatiesPremium: async (
    params: IOphalenIndicatiesPremiumParams,
  ): Promise<IOphalenIndicatiesPremiumResult> => {
    return await api.post('v2/transport/opdracht/ophalen-indicaties-premium', params);
  },
  koppelAfleverbewijs: async (
    params: IKoppelenAfleverbewijsParams,
  ): Promise<IKoppelenAfleverbewijsResult> => {
    return await api.post('v2/transport/opdracht/koppelen-afleverbewijs', params);
  },
  versturenDocumentatieNaLevering: async (
    params: IVersturenDocumentatieNaLeveringParams,
  ): Promise<IVersturenDocumentatieNaLeveringResult> => {
    return await api.post('v2/transport/opdracht/versturen-documentatie-na-levering', params);
  },
  toevoegenProductreferentiesOpgevraagd: async (
    params: IToevoegenProductreferentiesOpgevraagdParams,
  ): Promise<IToevoegenProductreferentiesOpgevraagdResult> => {
    return await api.post('v2/transport/opdracht/toevoegen-productreferenties-opgevraagd', params);
  },
  ophalenProductreferentiesOpgevraagd: async (
    params: IOphalenProductreferentiesOpgevraagdParams,
  ): Promise<IOphalenProductreferentiesOpgevraagdResult> => {
    return await api.post('v2/transport/opdracht/ophalen-productreferenties-opgevraagd', params);
  },
  checkVerplaatsenOpdrachtregels: async (
    params: IVerplaatsenOpdrachtregelsParams,
  ): Promise<ICheckData> => {
    return await api.post('v2/transport/opdracht/check-verplaatsen-opdrachtregels', params);
  },
  verplaatsenOpdrachtregels: async (
    params: IVerplaatsenOpdrachtregelsParams,
  ): Promise<IVerplaatsenOpdrachtregelsResult> => {
    return await api.post('v2/transport/opdracht/verplaatsen-opdrachtregels', params);
  },
  markerenFacturatieBeoordeeld: async (
    params: IMarkerenFacturatieBeoordeeldParams,
  ): Promise<IMarkerenFacturatieBeoordeeldResult> => {
    return await api.post('v2/transport/opdracht/markeren-facturatie-beoordeeld', params);
  },
};

export default opdracht;
