import {
  IControleerKredietwaardigheidParams,
  IControleerKredietwaardigheidResult,
  IOphalenKrediettoetsenParams,
  IOphalenKrediettoetsenResult,
} from '../../../../../shared/src/api/v2/kredietwaardigheid';
import api from '../../index';

const kredietwaardigheid = {
  controleerKredietwaardigheid: async (
    params: IControleerKredietwaardigheidParams,
  ): Promise<IControleerKredietwaardigheidResult> => {
    return api.post('v2/kredietwaardigheid/controleer-kredietwaardigheid', params);
  },
  ophalenKrediettoetsen: async (
    params: IOphalenKrediettoetsenParams,
  ): Promise<IOphalenKrediettoetsenResult> => {
    return api.post('v2/kredietwaardigheid/ophalen-krediettoetsen', params);
  },
};

export default kredietwaardigheid;
