import styled from 'styled-components';

export const Root = styled.div`
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.075);
  border: 1px solid #f0eded;
  width: 100%;
  display: flex;
`;

export const Content = styled.div`
  flex: 1;
`;
