import React, { useCallback, useMemo, useState, useContext, useEffect } from 'react';
import Dialoog from '../../dialogen/Dialoog';
import IDialoogProps from '../../../core/IDialoogProps';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import { RootStoreContext } from '../../../stores/RootStore';
import { EResultType } from '../../../stores/CheckStore';
import LoadingSpinner from '../../Gedeeld/LoadingSpinner';
import * as Yup from 'yup';
import RadioKnop from '../../formulier/RadioKnop';
import {
  IOphalenOpdrachtenResultElement,
  IToevoegenOpdrachtregelsParams,
} from '../../../../../shared/src/api/v2/transport/opdracht';
import api from '../../../api';
import { ETransportopdrachtRegelsoort } from '../../../bedrijfslogica/enums';

/**
 * Voegt een transportregel toe aan een opgegeven transportopdracht
 * Keuze tussen een Levering en Retour, er kan geen product opgegeven worden
 */

export interface IDialoogResult {}

interface IProps extends IDialoogProps<IDialoogResult> {
  trsOpdID: number;
}

interface IFormikValues {
  soort: string;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  soort: 'Soort',
};

const ToevoegenRegelsDialoog: React.FC<IProps> = (props) => {
  const { children, dialoogIndex, onAnnuleren, onSuccess, open, trsOpdID } = props;
  const { checkStore } = useContext(RootStoreContext);

  const [opdracht, setOpdracht] = useState<IOphalenOpdrachtenResultElement | null>(null);

  // Haal de gegevens van de opdracht op waaraan de regels toegevoegd gaan worden
  const ophalenOpdracht = useCallback(async () => {
    const result = await api.v2.transport.opdracht.ophalenOpdrachten({
      filterSchema: {
        filters: [{ naam: 'IDS', data: [props.trsOpdID] }],
      },
    });
    const opdracht = result.opdrachten[0];
    setOpdracht(opdracht);
  }, [props.trsOpdID]);

  useEffect(() => {
    ophalenOpdracht();
  }, [ophalenOpdracht]);

  const initialValues = useMemo<IFormikValues | null>(() => {
    return {
      soort: 'R',
    };
  }, []);

  const validationSchema = useMemo(() => {
    const fields: Record<keyof IFormikValues, any> = {
      soort: Yup.string(),
    };
    return Yup.object().shape(fields);
  }, []);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      // TODO GIl

      const params: IToevoegenOpdrachtregelsParams = {
        opdrachten: [
          {
            trsOpdID: props.trsOpdID,
            regels: [
              {
                regelsoortNaamEnum:
                  values.soort === 'L'
                    ? ETransportopdrachtRegelsoort.Levering
                    : ETransportopdrachtRegelsoort.Retour,
              },
            ],
          },
        ],
      };

      await api.v2.transport.opdracht.toevoegenOpdrachtregels(params);

      await ophalenOpdracht();

      onSuccess({});
      actions.setSubmitting(false);
    },
    [props.trsOpdID],
  );

  return (
    <Dialoog index={0}>
      {initialValues === null || opdracht === null ? (
        <LoadingSpinner />
      ) : (
        <Formik<IFormikValues>
          onSubmit={handleSubmit}
          initialValues={initialValues}
          enableReinitialize
          isInitialValid
          validationSchema={validationSchema}
          render={(formikProps: FormikProps<IFormikValues>) => {
            const { submitForm, isSubmitting, isValid } = formikProps;
            return (
              <>
                <ModalHeader>
                  <ModalTitle>Regel toevoegen</ModalTitle>
                </ModalHeader>
                <ModalBody>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-12 d-flex">
                        <label>{veldnamen.soort}</label>
                        <Field
                          name="soort"
                          render={({ field, form }: FieldProps<IFormikValues>) => {
                            return (
                              <div className="d-flex align-items-center" style={{ width: 250 }}>
                                <span className="d-flex ml-3">
                                  <RadioKnop
                                    aangevinkt={field.value === 'L'}
                                    onAangevinkt={() => form.setFieldValue(field.name, 'L')}
                                  />
                                  Levering
                                </span>
                                <span className="d-flex  ml-3">
                                  <RadioKnop
                                    aangevinkt={field.value === 'R'}
                                    onAangevinkt={() => form.setFieldValue(field.name, 'R')}
                                  />
                                  Retour
                                </span>
                              </div>
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </ModalBody>
                <ModalFooter className="d-flex flex-row justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    style={{ width: 100 }}
                    disabled={!isValid || isSubmitting}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={onAnnuleren}
                    style={{ width: 100 }}
                    disabled={isSubmitting}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Dialoog>
  );
};

export default ToevoegenRegelsDialoog;
