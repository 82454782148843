import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { IEntiteitProps } from '../../../../../../components/kaart/EntiteitContainer';
import { RouteComponentProps } from 'react-router';
import useUrlState from '../../../../../../core/useUrlState';
import { withRouter } from 'react-router-dom';
import Tabblad, { IOptions, ITabblad } from '../../../../../../components/layout/Tabblad';
import Beheer from './Beheer';
import Complexen from './Complexen';
import Gebouwen from './Gebouwen';

interface IProps extends IEntiteitProps, RouteComponentProps {}

type TabbladId = 'beheer' | 'gebouwen' | 'complexen';

interface IUrlState {
  tabblad: TabbladId;
}

const defaultUrlState: IUrlState = {
  tabblad: 'beheer',
};

export interface ILocatiesTabbladProps extends IEntiteitProps, RouteComponentProps {}

const Locaties = (props: IProps) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(
    props,
    defaultUrlState,
    'locatiesState',
  );

  const tabbladen = useMemo<ITabblad<TabbladId>[]>(() => {
    return [
      {
        id: 'beheer',
        label: 'Beheer',
        content: Beheer,
      },
      {
        id: 'complexen',
        label: 'Complexen',
        content: Complexen,
      },
      {
        id: 'gebouwen',
        label: 'Gebouwen',
        content: Gebouwen,
      },
    ];
  }, []);

  const options = useMemo<IOptions<TabbladId, ILocatiesTabbladProps>>(() => {
    return {
      tabbladComponentProps: props,
    };
  }, [props]);

  return (
    <Tabblad
      geselecteerd={urlState.tabblad}
      onSelectieChange={(tabblad: TabbladId) => setUrlStateSync('tabblad', tabblad)}
      tabbladen={tabbladen}
      options={options}
    />
  );
};

export default withRouter(Locaties);
