import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  BlockquoteHTMLAttributes,
} from 'react';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import IDialoogProps from '../../../../../../../core/IDialoogProps';
import {
  IOphalenAccountsResultElement,
  IWijzigenAccountParams,
} from '../../../../../../../../../shared/src/api/v2/account/account';
import { RootStoreContext } from '../../../../../../../stores/RootStore';
import Dialoog from '../../../../../../../components/dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import * as Yup from 'yup';
import nameof from '../../../../../../../core/nameOf';
import VinkVeld from '../../../../../../../components/formulier/VinkVeld';
import api from '../../../../../../../api';
import { EResultType } from '../../../../../../../stores/CheckStore';

interface IProps extends IDialoogProps<null> {
  account: IOphalenAccountsResultElement;
}

interface IFormikValues {
  gebruikersnaam: string;
  geblokkeerd: boolean;
  wachtwoord: string;
  nieuwWachtwoord: boolean;
  uitgeschakeld: boolean;
  magPersonaliaWijzigen: boolean;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  gebruikersnaam: 'Gebruikersnaam',
  wachtwoord: 'Wachtwoord',
  geblokkeerd: 'Geblokkeerd',
  nieuwWachtwoord: 'Moet nieuw wachtwoord instellen bij volgende login',
  uitgeschakeld: 'Uitgeschakeld',
  magPersonaliaWijzigen: 'Mag personalia wijzigen via website',
};

const WijzigenAccountDialoog: React.FC<IProps> = (props) => {
  const { open, onSuccess, onAnnuleren, account, dialoogIndex } = props;
  const { checkStore } = useContext(RootStoreContext);

  const initialValues = useMemo<IFormikValues>(
    () => ({
      gebruikersnaam: account.Gebruikersnaam,
      geblokkeerd: account.Geblokkeerd,
      wachtwoord: '',
      nieuwWachtwoord: account.NieuwWachtwoord,
      uitgeschakeld: account.Uitgeschakeld,
      magPersonaliaWijzigen: account.MagPersonaliaWijzigen,
    }),
    [account],
  );

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      const params: IWijzigenAccountParams = {
        accID: account.AccID,
        gebruikersnaam: values.gebruikersnaam,
        geblokkeerd: values.geblokkeerd,
        wachtwoord: values.wachtwoord.length === 0 ? null : values.wachtwoord,
        nieuwWachtwoord: values.nieuwWachtwoord,
        uitgeschakeld: values.uitgeschakeld,
        magPersonaliaWijzigen: values.magPersonaliaWijzigen,
      };

      const checkData = await api.v2.account.checkWijzigenAccount(params);
      const checkResult = await checkStore.controleren({
        checkData,
      });

      if (checkResult.type === EResultType.Annuleren) {
        actions.setSubmitting(false);
        return;
      }

      await api.v2.account.wijzigenAccount(params);

      onSuccess(null);

      actions.setSubmitting(false);
    },
    [account, onSuccess],
  );

  const validationSchema = useCallback(() => {}, []);

  return (
    <Dialoog index={dialoogIndex || 0} modalProps={{ size: 'lg' }}>
      <ModalHeader>
        <ModalTitle>Account wijzigen</ModalTitle>
      </ModalHeader>
      <Formik<IFormikValues>
        onSubmit={handleSubmit}
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema}
        render={(formikProps: FormikProps<IFormikValues>) => {
          const { submitForm, isSubmitting, isValid } = formikProps;

          return (
            <>
              <ModalBody>
                <div className="form-group">
                  <div className="row">
                    <div className="col-8">
                      <label>{veldnamen.gebruikersnaam}</label>
                      <Field
                        name={nameof<IFormikValues>('gebruikersnaam')}
                        render={(fieldProps: FieldProps<IFormikValues>) => {
                          return (
                            <input type="text" className="form-control" {...fieldProps.field} />
                          );
                        }}
                      />
                    </div>

                    <div className="col-8 mt-2">
                      <label>{veldnamen.wachtwoord}</label>
                      <Field
                        name={nameof<IFormikValues>('wachtwoord')}
                        render={(fieldProps: FieldProps<IFormikValues>) => {
                          return (
                            <input type="text" className="form-control" {...fieldProps.field} />
                          );
                        }}
                      />
                    </div>

                    <div className="col-12 mt-3">
                      <div className="d-flex align-items-center">
                        <Field
                          name={nameof<IFormikValues>('nieuwWachtwoord')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <VinkVeld
                                aangevinkt={field.value}
                                onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                              />
                            );
                          }}
                        />
                        <span className="ml-2">{veldnamen.nieuwWachtwoord}</span>
                      </div>
                    </div>

                    <div className="col-12 mt-3">
                      <div className="d-flex align-items-center">
                        <Field
                          name={nameof<IFormikValues>('geblokkeerd')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <VinkVeld
                                aangevinkt={field.value}
                                onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                              />
                            );
                          }}
                        />
                        <span className="ml-2">{veldnamen.geblokkeerd}</span>
                      </div>
                    </div>

                    <div className="col-12 mt-3">
                      <div className="d-flex align-items-center">
                        <Field
                          name={nameof<IFormikValues>('uitgeschakeld')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <VinkVeld
                                aangevinkt={field.value}
                                onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                              />
                            );
                          }}
                        />
                        <span className="ml-2">{veldnamen.uitgeschakeld}</span>
                      </div>
                    </div>

                    <div className="col-12 mt-3">
                      <div className="d-flex align-items-center">
                        <Field
                          name={nameof<IFormikValues>('magPersonaliaWijzigen')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <VinkVeld
                                aangevinkt={field.value}
                                onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                              />
                            );
                          }}
                        />
                        <span className="ml-2">{veldnamen.magPersonaliaWijzigen}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </ModalBody>
              <ModalFooter className="d-flex flex-row justify-content-start">
                <button
                  className="btn btn-primary"
                  onClick={submitForm}
                  style={{ width: 100 }}
                  disabled={isSubmitting || !isValid}
                >
                  Ok
                </button>
                <button className="btn btn-secondary" onClick={onAnnuleren} style={{ width: 100 }}>
                  Annuleren
                </button>
              </ModalFooter>
            </>
          );
        }}
      />
    </Dialoog>
  );
};

export default WijzigenAccountDialoog;
