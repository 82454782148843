import { RootStore } from './RootStore';
import { makeObservable, observable } from 'mobx';
import IRemoteData, { createPendingRemoteData, createReadyRemoteData } from '../models/IRemoteData';
import api from '../api';
import { IOphalenServicedienstenV2ResultElement } from '../../../shared/src/api/v2/service/opdracht';

class ServicedienstStore {
  public servicediensten: Record<number, IRemoteData<IOphalenServicedienstenV2ResultElement>> = {};
  // tslint:disable-next-line:variable-name
  private _rootStore: RootStore;
  private servDienstIDsOphalenQueue: Set<number> = new Set();
  private servicedienstenOphalenTimeout: NodeJS.Timeout | null = null;

  constructor(rootStore: RootStore) {
    this._rootStore = rootStore;

    makeObservable(this, {
      servicediensten: observable,
    });
  }

  public enqueueOphalenServicediensten = async (ids: number[]) => {
    if (ids.length === 0) {
      return;
    }

    const copy = { ...this.servicediensten };

    for (const id of ids) {
      // Als de opdracht al in de store zit, hoeven we hem niet opnieuw op te halen
      if (this.servicediensten[id] !== undefined) {
        continue;
      }
      copy[id] = createPendingRemoteData();

      this.servDienstIDsOphalenQueue.add(id);
    }

    this.servicediensten = copy;

    if (this.servicedienstenOphalenTimeout !== null) {
      clearTimeout(this.servicedienstenOphalenTimeout);
    }
    this.servicedienstenOphalenTimeout = setTimeout(this.ophalenServicedienstenMiddelsQueue, 50);
  };

  private ophalenServicedienstenMiddelsQueue = async () => {
    const ids = Array.from(this.servDienstIDsOphalenQueue);
    this.servDienstIDsOphalenQueue.clear();

    const result = await api.v2.service.ophalenServicedienstenV2({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: ids,
          },
        ],
      },
    });

    const copy = { ...this.servicediensten };

    for (const servicedienst of result.servicediensten) {
      copy[servicedienst.ServDienstID] = createReadyRemoteData(servicedienst);
    }

    this.servicediensten = copy;
  };
}

export default ServicedienstStore;
