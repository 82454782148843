import {
  IOphalenProductmerkenParams,
  OphalenProductmerkenResult,
  IToevoegenParams,
  IToevoegenResult,
  IWijzigenParams,
  IWijzigenResult,
  IVerwijderenParams,
  IVerwijderenResult,
} from '../../../../../shared/src/api/v2/product/merk';
import ICheckData from '../../../../../shared/src/models/ICheckData';
import api from '../../index';

const merk = {
  ophalenProductmerken: async (
    params: IOphalenProductmerkenParams,
  ): Promise<OphalenProductmerkenResult> => {
    return await api.post('v2/product/merk/ophalen-productmerken', params);
  },
  checkToevoegen: async (params: IToevoegenParams): Promise<ICheckData> => {
    return await api.post('v2/product/merk/check-toevoegen', params);
  },
  toevoegen: async (params: IToevoegenParams): Promise<IToevoegenResult> => {
    return await api.post('v2/product/merk/toevoegen', params);
  },
  checkWijzigen: async (params: IWijzigenParams): Promise<ICheckData> => {
    return await api.post('v2/product/merk/check-wijzigen', params);
  },
  wijzigen: async (params: IWijzigenParams): Promise<IWijzigenResult> => {
    return await api.post('v2/product/merk/wijzigen', params);
  },
  checkVerwijderen: async (params: IVerwijderenParams): Promise<ICheckData> => {
    return await api.post('v2/product/merk/check-verwijderen', params);
  },
  verwijderen: async (params: IVerwijderenParams): Promise<IVerwijderenResult> => {
    return await api.post('v2/product/merk/verwijderen', params);
  },
};

export default merk;
