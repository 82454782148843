import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import ASPTabel from '../../../../components/tabel/ASPTabel';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../models/IRemoteData';
import api, { IPaginatiePositie } from '../../../../api';
import { IWebPagina } from '../../../../../../shared/src/api/v2/website/pagina';
import { ASPKolom, EAspKolomBreedteType } from '../../../../components/tabel/ASPTabel/types';
import * as _ from 'lodash';
import { IOphalenTekstenResultElement } from '../../../../../../shared/src/api/v2/tekst';
import Skeleton from 'react-loading-skeleton';
import TableData, { ITableDataProps } from '../../../../components/tabel/ASPTabel/Body/TableData';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import { observer } from 'mobx-react-lite';
import { GlobaleRendererContext } from '../../../../one-off-components/GlobaleRenderer';
import WijzigenDialoog, { EDataType } from './WijzigenDialoog';
import Chip from '../../../../components/Chip';

enum EKolom {
  NaamEnum,
  Titel,
  Metadata,
  Omschrijving,
  Keywords,
  Indexeren,
  Follow,
}

interface IProps {}

interface IData {
  items: Record<number, IWebPagina>;
  totaalAantal: number;
}

const Metadata = observer((props: IProps) => {
  const globaleRenderer = useContext(GlobaleRendererContext);

  const dataRef = useRef<IRemoteData<IData>>(createPendingRemoteData());
  const [dataState, setDataState] = useState<IRemoteData<IData>>(dataRef.current);
  const setData = useCallback((data: IRemoteData<IData>) => {
    dataRef.current = data;
    setDataState(data);
  }, []);

  const tekstenRef = useRef<IRemoteData<Record<number, IRemoteData<IOphalenTekstenResultElement>>>>(
    createPendingRemoteData(),
  );
  const [tekstenState, setTekstenState] = useState<
    IRemoteData<Record<number, IRemoteData<IOphalenTekstenResultElement>>>
  >(tekstenRef.current);
  const setTeksten = useCallback(
    (data: IRemoteData<Record<number, IRemoteData<IOphalenTekstenResultElement>>>) => {
      tekstenRef.current = data;
      setTekstenState(data);
    },
    [],
  );
  const verwerkTekstIDs = useCallback(async (tekstIDs: number[]) => {
    // const tekstIDsNogNietVerwerkt =
    //   tekstenRef.current.state === ERemoteDataState.Pending
    //     ? tekstIDs
    //     : tekstIDs.filter((tekstID) => tekstenRef.current.data![tekstID] === undefined);
    // if (tekstIDsNogNietVerwerkt.length === 0) {
    //   return;
    // }
    setTeksten(
      createReadyRemoteData(
        tekstIDs.reduce(
          (acc, tekstID) => ({
            ...acc,
            [tekstID]: acc[tekstID] ?? createPendingRemoteData(),
          }),
          tekstenRef.current.data ?? {},
        ),
      ),
    );
    const result = await api.v2.tekst.ophalenTeksten({
      taalID: 1,
      tekstIDs,
    });
    setTeksten(
      createReadyRemoteData(
        result.reduce(
          (acc, item) => ({
            ...acc,
            [item.TekstID]: createReadyRemoteData(item),
          }),
          tekstenRef.current.data ?? {},
        ),
      ),
    );
  }, []);

  const ophalenData = useCallback(async (paginatie: IPaginatiePositie, uitbreiden: boolean) => {
    const result = await api.v2.extern.pagina.ophalenWebPaginas({
      paginatie,
      orderSchema: { orders: [{ naam: 'NAAM_ENUM', richting: 'ASC' }] },
    });

    const tekstIDs: number[] = _.uniq(
      result.webPaginas.flatMap((item) =>
        [
          item.Titel_TekstID,
          item.Description_TekstID,
          item.Metadata_TekstID,
          item.Keywords_TekstID,
        ].filter((x) => x !== null),
      ),
    );
    // noinspection ES6MissingAwait
    verwerkTekstIDs(tekstIDs);

    const items = result.webPaginas.reduce(
      (acc, curr, i) => ({
        ...acc,
        [paginatie.index + i]: curr,
      }),
      uitbreiden ? dataRef.current?.data?.items ?? {} : {},
    );

    setData(
      createReadyRemoteData({
        items,
        totaalAantal: result.totaalAantal,
      }),
    );
  }, []);

  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    ophalenData({ index: 0, aantal: 50 }, false);
  }, [ophalenData]);

  const handleExtraRijenAangevraagd = useCallback(
    async (paginatie: IPaginatiePositie) => {
      await ophalenData(paginatie, true);
    },
    [ophalenData],
  );

  const keyExtractor = useCallback((item: IWebPagina) => item.ID, []);

  const tekstRenderer = useCallback(
    (tekstID: number | null) => {
      const tekst: IRemoteData<IOphalenTekstenResultElement | null> =
        tekstID === null
          ? createReadyRemoteData(null)
          : tekstenState.state === ERemoteDataState.Pending
          ? createPendingRemoteData()
          : tekstenState.data![tekstID] ?? createPendingRemoteData();

      if (tekst.state === ERemoteDataState.Pending) {
        return <Skeleton />;
      }
      if (tekst.data === null) {
        return null;
      }
      return (
        <span
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          title={tekst.data?.Tekst ?? undefined}
        >
          {tekst.data!.Tekst}
        </span>
      );
    },
    [tekstenState],
  );

  const kolommen = useMemo<ASPKolom<EKolom, IWebPagina>[]>(
    () => [
      {
        key: EKolom.NaamEnum,
        label: 'NaamEnum',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 350,
        renderer: (item: IWebPagina) => item.NaamEnum,
      },
      {
        key: EKolom.Titel,
        label: 'Titel',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 350,
        renderer: (item: IWebPagina) => {
          if (item.IsSysteemBeheerd) {
            return (
              <span className="font-italic">Deze webpagina wordt beheerd door het systeem</span>
            );
          }
          return tekstRenderer(item.Titel_TekstID);
        },
      },
      {
        key: EKolom.Omschrijving,
        label: 'Omschrijving',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 325,
        renderer: (item: IWebPagina) => {
          if (item.IsSysteemBeheerd) {
            return;
          }
          return tekstRenderer(item.Description_TekstID);
        },
      },
      {
        key: EKolom.Metadata,
        label: 'Metadata',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 325,
        renderer: (item: IWebPagina) => {
          if (item.IsSysteemBeheerd) {
            return;
          }
          return tekstRenderer(item.Metadata_TekstID);
        },
      },
      {
        key: EKolom.Keywords,
        label: 'Trefwoorden',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 350,
        renderer: (item: IWebPagina) => {
          if (item.IsSysteemBeheerd) {
            return;
          }
          const tekst: IRemoteData<IOphalenTekstenResultElement | null> =
            item.Keywords_TekstID === null
              ? createReadyRemoteData(null)
              : tekstenState.state === ERemoteDataState.Pending
              ? createPendingRemoteData()
              : tekstenState.data![item.Keywords_TekstID] ?? createPendingRemoteData();

          if (tekst.state === ERemoteDataState.Pending) {
            return <Skeleton />;
          }
          if (tekst.data === null) {
            return null;
          }

          const t = tekst.data!.Tekst ?? '';
          const trefwoorden = t === '' ? [] : t.split(',');

          return (
            <div
              className="d-flex align-items-center"
              style={{ columnGap: 5, overflowX: 'auto', overflowY: 'hidden' }}
            >
              {trefwoorden.map((trefwoord) => (
                <Chip key={trefwoord}>{trefwoord}</Chip>
              ))}
            </div>
          );
        },
      },
      {
        key: EKolom.Indexeren,
        label: 'Indexeren',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 100,
        renderer: (item: IWebPagina) => {
          if (item.IsSysteemBeheerd) {
            return;
          }
          return item.Indexeren ? 'Ja' : null;
        },
      },
      {
        key: EKolom.Follow,
        label: 'Follow',
        breedteType: EAspKolomBreedteType.Flex,
        flex: 1,
        minimaleVasteBreedte: 80,
        renderer: (item: IWebPagina) => {
          if (item.IsSysteemBeheerd) {
            return;
          }
          return item.Follow ? 'Ja' : null;
        },
      },
    ],
    [tekstRenderer],
  );

  const TdComponent = useCallback((tdProps: ITableDataProps<EKolom, IWebPagina>) => {
    const webpagina = tdProps.row;
    return (
      <TableData
        {...tdProps}
        style={{
          backgroundColor: webpagina?.IsSysteemBeheerd ? Kleur.HeelLichtGeel : undefined,
        }}
      />
    );
  }, []);

  const handleWijzigenRij = useCallback(
    async (webPagina: IWebPagina, idx: number) => {
      const result = await globaleRenderer.render((rp) => (
        <WijzigenDialoog
          data={{
            type: EDataType.Direct,
            webPagina,
          }}
          open
          onSuccess={(x) => rp.destroy(x)}
          onAnnuleren={() => rp.destroy()}
        />
      ));
      if (result === undefined) {
        return;
      }
      await ophalenData({ index: idx, aantal: 1 }, true);
    },
    [globaleRenderer, ophalenData],
  );
  const magRijWijzigen = useCallback((webPagina: IWebPagina) => !webPagina.IsSysteemBeheerd, []);

  return (
    <ASPTabel
      rijen={dataState.data?.items ?? {}}
      kolommen={kolommen}
      keyExtractor={keyExtractor}
      totaalAantalRijen={dataState.data?.totaalAantal ?? 10}
      onExtraRijenAangevraagd={handleExtraRijenAangevraagd}
      tdComponent={TdComponent}
      onWijzigenRij={handleWijzigenRij}
      magRijWijzigen={magRijWijzigen}
    />
  );
});

export default Metadata;
