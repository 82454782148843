import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';
import { DataTypeProvider, EditingState, TableRowDetail } from '@devexpress/dx-react-grid';
import { IActieregel } from '../index';
import AfbeeldingKader from '../../../../../components/weergave/AfbeeldingKader';
import api from '../../../../../api';
import { EResultType } from '../../../../../stores/CheckStore';
import { RootStoreContext } from '../../../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import {
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../../helpers/dxTableGrid';
import {
  Grid,
  Table,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  IActieCode,
  IActiePeriode,
  IWijzigenActiecodesVanActieParams,
} from '../../../../../../../shared/src/api/v2/aanbod/tarieven/acties';
import nameOf from '../../../../../core/nameOf';
import format from 'date-fns/format';
import ActiePeriodenMuterenDialoog, { IFormikValues } from './ActiePeriodeMuterenDialoog';
import ActiecodeMuterenPopupFormulier, {
  IFormikValues as IActiecodeFormikValues,
} from './ActiecodeMuterenPopupFormulier';
import AbonnementActiesTabel from './AbonnementActiesTabel';
import BijlageKnop, { EOrientatie, ESelectieModus } from '../../../../../components/BijlageKnop';
import useUpload from '../../../../../core/useUpload';
import { IconKruis } from '../../../../../components/Icons';
import { Kleur as EKleur } from '../../../../../bedrijfslogica/constanten';

interface IWijzigenPeriodeState {
  tarActiePerID: number;
  initialValues: IFormikValues;
}

interface IWijzigenActiecodeState {
  ID: number;
  initialValues: IActiecodeFormikValues;
}

interface IProps extends TableRowDetail.ContentProps {}

const TabelDetailRegel: React.FC<IProps> = observer((props) => {
  const row: IActieregel = props.row;
  const { checkStore } = useContext(RootStoreContext);
  const [bezig, setBezig] = useState(false);
  const [actiePeriodeToevoegenTonen, setActiePeriodeToevoegenTonen] = useState(false);
  const [actiecodeToevoegenTonen, setActiecodeToevoegenTonen] = useState(false);

  const actiecodesGridRef = useRef(null);

  const afbeeldingSrc = useMemo(() => (row.afbeelding === null ? null : row.afbeelding.url), [
    row.afbeelding,
  ]);

  const handleAfbeeldingInstellen = useCallback(
    async (bestID: number | null) => {
      setBezig(true);

      await api.v2.aanbod.tarieven.acties.bijwerkenAfbeeldingVanActie({
        tarActieID: row.TarActieID,
        bestID,
      });
      row.onVerversenAangevraagd();

      setBezig(false);
    },
    [row.TarActieID, row.onVerversenAangevraagd],
  );

  const periodeKolommen = useMemo<TypedColumn<IActiePeriode>[]>(
    () => [
      {
        name: 'Ingangsdatum',
        title: 'Ingangsdatum',
      },
      {
        name: 'Einddatum',
        title: 'Einddatum',
      },
    ],
    [],
  );

  const periodeKolomBreedtes = useMemo<TypedTableColumnWidthInfo<IActiePeriode>[]>(
    () => [
      {
        columnName: 'Ingangsdatum',
        width: 115,
      },
      {
        columnName: 'Einddatum',
        width: 115,
      },
    ],
    [],
  );

  const periodeKeyExtractor = useCallback((row: IActiePeriode) => row.TarActiePerID, []);
  const actiecodeKolommen = useMemo<TypedColumn<IActieCode>[]>(
    () => [
      {
        name: 'Actiecode',
        title: 'Code',
      },
    ],
    [],
  );

  const actiecodeKolomBreedtes = useMemo<TypedTableColumnWidthInfo<IActieCode>[]>(
    () => [
      {
        columnName: 'Actiecode',
        width: 150,
      },
    ],
    [],
  );

  const actiecodeKeyExtractor = useCallback((row: IActieCode) => row.ID, []);
  const [wijzigenPeriodeState, setWijzigenPeriodeState] = useState<IWijzigenPeriodeState | null>(
    null,
  );
  const [
    wijzigenActiecodeState,
    setWijzigenActiecodeState,
  ] = useState<IWijzigenActiecodeState | null>(null);

  const { muteerBestanden } = useUpload(
    useMemo(
      () => ({
        automatischUploaden: true,
        initieleBestanden: [],
        onAlleBestandenGeuploaded: (bestIDs) => {
          const bestID = bestIDs.length > 0 ? bestIDs[0] : null;
          handleAfbeeldingInstellen(bestID);
        },
      }),
      [],
    ),
  );

  return (
    <>
      <div className="d-flex p-2">
        <div className="d-flex flex-column">
          <div className="d-flex">
            <BijlageKnop
              onBijgevoegd={(bestanden) => muteerBestanden(() => bestanden)}
              orientatie={EOrientatie.Verticaal}
              selectieModus={ESelectieModus.Enkel}
              toegestaneBestandstypes={[
                { weergaveNaam: 'JPEG', mediaType: 'image/jpeg' },
                { weergaveNaam: 'PNG', mediaType: 'image/png' },
                { weergaveNaam: 'SVG', mediaType: 'image/svg+xml' },
              ]}
            />
            <div className="ml-1">
              <AfbeeldingKader
                afbeelding={
                  afbeeldingSrc === null ? null : (
                    <img
                      alt="afbeelding"
                      src={afbeeldingSrc}
                      style={{ width: 225, height: 170, objectFit: 'contain' }}
                    />
                  )
                }
                imgSrc={afbeeldingSrc === null ? undefined : afbeeldingSrc}
                isVergrootbaar={afbeeldingSrc !== null}
                width={300}
                height={225}
                leegInhoud={
                  <span className="text-muted" style={{ fontSize: '1.15rem' }}>
                    Geen afbeelding beschikbaar.
                  </span>
                }
              />
            </div>
          </div>
          <div className="d-flex flex-fill">
            {/*<button*/}
            {/*  className="btn btn-sm btn-light flex-fill m-2 d-flex align-items-center justify-content-center"*/}
            {/*  style={{ border: `1px solid ${Kleur.LichtGrijs}` }}*/}
            {/*  disabled={bezig}*/}
            {/*  onClick={() => setAfbeeldingSelectieDialoogTonen(true)}*/}
            {/*>*/}
            {/*  {row.Afbeelding_BestToken === null ? (*/}
            {/*    <IconToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />*/}
            {/*  ) : (*/}
            {/*    <IconWijzigen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />*/}
            {/*  )}*/}
            {/*  <span className="ml-2">*/}
            {/*    {row.Afbeelding_BestToken === null ? 'Toevoegen' : 'Wijzigen'}*/}
            {/*  </span>*/}
            {/*</button>*/}
            {row.afbeelding !== null && (
              <div className="mt-2 w-100">
                <button
                  className="btn btn-sm btn-light d-flex align-items-center justify-content-center w-100"
                  style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                  disabled={bezig}
                  onClick={async () => {
                    if (
                      (await checkStore.bevestigen({ inhoud: 'Bevestig verwijderen afbeelding' }))
                        .type === EResultType.Annuleren
                    ) {
                      return;
                    }
                    handleAfbeeldingInstellen(null);
                  }}
                >
                  <IconKruis style={{ width: 16, height: 16, fill: EKleur.Grijs }} />
                  <span className="ml-2">Verwijderen</span>
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="flex-fill p-2 d-flex">
          <div style={{ width: 350 }}>
            <GridStyleWrapper maxHeight={500} rowAmount={row.actiePerioden.length}>
              <Grid
                getRowId={periodeKeyExtractor}
                columns={periodeKolommen}
                rows={row.actiePerioden}
              >
                <DataTypeProvider
                  for={[nameOf<IActiePeriode>('Ingangsdatum'), nameOf<IActiePeriode>('Einddatum')]}
                  formatterComponent={(formatterProps) => {
                    const datumStr = formatterProps.value as string | null;
                    if (datumStr === null) {
                      return <span />;
                    }
                    const datum = new Date(datumStr);
                    return <span>{format(new Date(datum), 'dd-MM-yyyy')}</span>;
                  }}
                />
                <EditingState
                  onCommitChanges={async (changes) => {
                    if (changes.deleted !== undefined && changes.deleted.length > 0) {
                      const ids = changes.deleted;

                      const nieuweActiePerioden = row.actiePerioden
                        .filter((actiePeriode) => !ids.includes(actiePeriode.TarActiePerID))
                        .map((x) => ({
                          ingangsdatum: format(new Date(x.Ingangsdatum), 'yyyy-MM-dd'),
                          einddatum:
                            x.Einddatum === null
                              ? null
                              : format(new Date(x.Einddatum), 'yyyy-MM-dd'),
                        }));
                      const checkData = await api.v2.aanbod.tarieven.acties.checkWijzigenActiePeriodenVanActie(
                        {
                          TarActieID: row.TarActieID,
                          perioden: nieuweActiePerioden,
                        },
                      );
                      if (
                        (await checkStore.controleren({ checkData })).type === EResultType.Annuleren
                      ) {
                        return;
                      }

                      const bevestigenResult = await checkStore.bevestigen({
                        inhoud: `Bevestigen verwijderen actie periode${ids.length > 1 ? 'n' : ''}`,
                      });
                      if (bevestigenResult.type === EResultType.Annuleren) {
                        return;
                      }

                      await api.v2.aanbod.tarieven.acties.wijzigenActiePeriodenVanActie({
                        TarActieID: row.TarActieID,
                        perioden: nieuweActiePerioden,
                      });

                      row.onVerversenAangevraagd();
                    }
                  }}
                  onEditingRowIdsChange={(ids) => {
                    const id = ids[ids.length - 1] as number;
                    const periode = row.actiePerioden.find((x) => periodeKeyExtractor(x) === id)!;
                    setWijzigenPeriodeState({
                      tarActiePerID: id,
                      initialValues: {
                        ingangsdatum: new Date(periode.Ingangsdatum),
                        einddatum: periode.Einddatum !== null ? new Date(periode.Einddatum) : null,
                      },
                    });
                  }}
                  onAddedRowsChange={() => setActiePeriodeToevoegenTonen(true)}
                />
                <Table messages={{ noData: 'Geen periode' }} />
                <TableColumnResizing defaultColumnWidths={periodeKolomBreedtes} />
                <TableHeaderRow />
                <TableEditColumn
                  width={65}
                  commandComponent={DXTableEditColumnCommandComponent}
                  cellComponent={DXTableEditColumnCellComponent}
                  showDeleteCommand
                  showEditCommand
                  showAddCommand
                />
              </Grid>
            </GridStyleWrapper>
          </div>
          <div style={{ width: 225 }} className="ml-3">
            <GridStyleWrapper
              maxHeight={500}
              rowAmount={row.actieCodes.length}
              ref={actiecodesGridRef}
            >
              <Grid
                getRowId={actiecodeKeyExtractor}
                columns={actiecodeKolommen}
                rows={row.actieCodes}
              >
                <EditingState
                  onCommitChanges={async (changes) => {
                    if (changes.deleted !== undefined && changes.deleted.length > 0) {
                      const ids = changes.deleted;

                      const nieuweActiecodes = row.actieCodes
                        .filter((actiecode) => !ids.includes(actiecode.ID))
                        .map((x) => x.Actiecode);
                      const params: IWijzigenActiecodesVanActieParams = {
                        tarActieID: row.TarActieID,
                        actiecodes: nieuweActiecodes,
                      };
                      const checkData = await api.v2.aanbod.tarieven.acties.checkWijzigenActiecodesVanActie(
                        params,
                      );

                      if (
                        (await checkStore.controleren({ checkData })).type === EResultType.Annuleren
                      ) {
                        return;
                      }

                      const bevestigenResult = await checkStore.bevestigen({
                        inhoud: `Bevestigen verwijderen actie code${ids.length > 1 ? 's' : ''}`,
                      });
                      if (bevestigenResult.type === EResultType.Annuleren) {
                        return;
                      }

                      await api.v2.aanbod.tarieven.acties.wijzigenActiecodesVanActie(params);

                      row.onVerversenAangevraagd();
                    }
                  }}
                  onEditingRowIdsChange={(ids) => {
                    const id = ids[ids.length - 1] as number;
                    const actiecode = row.actieCodes.find((x) => actiecodeKeyExtractor(x) === id)!;
                    setWijzigenActiecodeState({
                      ID: id,
                      initialValues: {
                        actiecode: actiecode.Actiecode,
                      },
                    });
                  }}
                  onAddedRowsChange={() => setActiecodeToevoegenTonen(true)}
                />
                <Table messages={{ noData: 'Geen codes' }} />
                <TableColumnResizing defaultColumnWidths={actiecodeKolomBreedtes} />
                <TableHeaderRow />
                <TableEditColumn
                  width={65}
                  commandComponent={DXTableEditColumnCommandComponent}
                  cellComponent={DXTableEditColumnCellComponent}
                  showDeleteCommand
                  showEditCommand
                  showAddCommand
                />
              </Grid>
            </GridStyleWrapper>
          </div>
          <div style={{ width: 550 }} className="ml-3">
            <AbonnementActiesTabel abonnementActies={row.abonnementActies} />
          </div>
        </div>
      </div>
      {/*{afbeeldingSelectieDialoogTonen && (*/}
      {/*  <BestandenDragAndDropDialoog*/}
      {/*    open*/}
      {/*    onSuccess={(result) => {*/}
      {/*      const bestand = result.bestanden[0];*/}
      {/*      handleAfbeeldingInstellen(bestand.bestID);*/}
      {/*      setAfbeeldingSelectieDialoogTonen(false);*/}
      {/*    }}*/}
      {/*    onAnnuleren={() => setAfbeeldingSelectieDialoogTonen(false)}*/}
      {/*    selectieModus={EBestandDragAndDropZoneSelectieModus.Enkel}*/}
      {/*    toegestaneBestandstypen={[*/}
      {/*      { weergaveNaam: 'JPEG', mediaType: 'image/jpeg' },*/}
      {/*      { weergaveNaam: 'PNG', mediaType: 'image/png' },*/}
      {/*      { weergaveNaam: 'SVG', mediaType: 'image/svg+xml' },*/}
      {/*    ]}*/}
      {/*  />*/}
      {/*)}*/}
      {actiePeriodeToevoegenTonen && (
        <ActiePeriodenMuterenDialoog
          open
          onSuccess={async (result) => {
            const nieuweActiePerioden = [
              {
                ingangsdatum: format(result.ingangsdatum, 'yyyy-MM-dd'),
                einddatum:
                  result.einddatum === null ? null : format(result.einddatum, 'yyyy-MM-dd'),
              },
              ...row.actiePerioden.map((x) => ({
                ingangsdatum: format(new Date(x.Ingangsdatum), 'yyyy-MM-dd'),
                einddatum:
                  x.Einddatum === null ? null : format(new Date(x.Einddatum), 'yyyy-MM-dd'),
              })),
            ];
            const checkData = await api.v2.aanbod.tarieven.acties.checkWijzigenActiePeriodenVanActie(
              {
                TarActieID: row.TarActieID,
                perioden: nieuweActiePerioden,
              },
            );
            if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
              return;
            }

            await api.v2.aanbod.tarieven.acties.wijzigenActiePeriodenVanActie({
              TarActieID: row.TarActieID,
              perioden: nieuweActiePerioden,
            });

            row.onVerversenAangevraagd();
            setActiePeriodeToevoegenTonen(false);
          }}
          onAnnuleren={() => setActiePeriodeToevoegenTonen(false)}
        />
      )}
      {wijzigenPeriodeState !== null && (
        <ActiePeriodenMuterenDialoog
          open
          initialValues={wijzigenPeriodeState.initialValues}
          onSuccess={async (result) => {
            const nieuweActiePerioden = row.actiePerioden.map((x) => {
              if (x.TarActiePerID === wijzigenPeriodeState!.tarActiePerID) {
                return {
                  ingangsdatum: format(result.ingangsdatum, 'yyyy-MM-dd'),
                  einddatum:
                    result.einddatum === null ? null : format(result.einddatum, 'yyyy-MM-dd'),
                };
              }

              return {
                ingangsdatum: format(new Date(x.Ingangsdatum), 'yyyy-MM-dd'),
                einddatum:
                  x.Einddatum === null ? null : format(new Date(x.Einddatum), 'yyyy-MM-dd'),
              };
            });
            const checkData = await api.v2.aanbod.tarieven.acties.checkWijzigenActiePeriodenVanActie(
              {
                TarActieID: row.TarActieID,
                perioden: nieuweActiePerioden,
              },
            );
            if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
              return;
            }

            await api.v2.aanbod.tarieven.acties.wijzigenActiePeriodenVanActie({
              TarActieID: row.TarActieID,
              perioden: nieuweActiePerioden,
            });

            row.onVerversenAangevraagd();
            setWijzigenPeriodeState(null);
          }}
          onAnnuleren={() => setWijzigenPeriodeState(null)}
        />
      )}
      {actiecodeToevoegenTonen && (
        <ActiecodeMuterenPopupFormulier
          open
          target={actiecodesGridRef.current!}
          onSuccess={async (data) => {
            const params: IWijzigenActiecodesVanActieParams = {
              tarActieID: row.TarActieID,
              actiecodes: [data.actiecode, ...row.actieCodes.map((x) => x.Actiecode)],
            };
            const checkData = await api.v2.aanbod.tarieven.acties.checkWijzigenActiecodesVanActie(
              params,
            );
            if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
              return;
            }
            await api.v2.aanbod.tarieven.acties.wijzigenActiecodesVanActie(params);
            row.onVerversenAangevraagd();

            setActiecodeToevoegenTonen(false);
          }}
          onAnnuleren={() => setActiecodeToevoegenTonen(false)}
          placement="top-start"
        />
      )}
      {wijzigenActiecodeState !== null && (
        <ActiecodeMuterenPopupFormulier
          open
          target={actiecodesGridRef.current!}
          onSuccess={async (data) => {
            const params: IWijzigenActiecodesVanActieParams = {
              tarActieID: row.TarActieID,
              actiecodes: row.actieCodes.map((actiecode) => {
                if (actiecode.ID === wijzigenActiecodeState!.ID) {
                  return data.actiecode;
                }
                return actiecode.Actiecode;
              }),
            };
            const checkData = await api.v2.aanbod.tarieven.acties.checkWijzigenActiecodesVanActie(
              params,
            );
            if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
              return;
            }
            await api.v2.aanbod.tarieven.acties.wijzigenActiecodesVanActie(params);
            row.onVerversenAangevraagd();

            setActiecodeToevoegenTonen(false);
            setWijzigenActiecodeState(null);
          }}
          onAnnuleren={() => setWijzigenActiecodeState(null)}
          placement="top-start"
          initialValues={wijzigenActiecodeState.initialValues}
        />
      )}
    </>
  );
});

export default TabelDetailRegel;
