import React from 'react';
import { FieldProps } from 'formik';

interface IProps<IFormikValues> {
  fieldProps: FieldProps<IFormikValues>;
  // Van toepassing als een error bestaat, maar het veld nog niet voorheen verlaten is
  directTonen?: boolean;
  options?: IFormikVeldFoutOptions;
}

const recursiveSelect = <TItem extends any>(obj: any, keys: string[]): TItem => {
  const item = obj[keys[0]];
  if (typeof item !== 'object') {
    return item;
  }
  if (keys.length > 0) {
    return recursiveSelect(item, keys.slice(1, keys.length));
  }
  return item;
};

interface IFormikVeldFoutOptions {
  objectAlsVeldBeschouwen?: boolean;
}

const FormikVeldFout = <IFormikValues extends {}>(props: IProps<IFormikValues>) => {
  const { field, form } = props.fieldProps;

  const keys = field.name.split('.');
  const touched = props.options?.objectAlsVeldBeschouwen
    ? (form.touched as any)[field.name as any]
    : recursiveSelect<boolean>(form.touched, keys);
  const error = props.options?.objectAlsVeldBeschouwen
    ? (form.errors as any)[field.name as any]
    : recursiveSelect<string | undefined>(form.errors, keys);

  if (error === undefined) {
    return null;
  }

  if (!props.directTonen && !touched) {
    return null;
  }

  return <small className="form-text text-danger">{error}</small>;
};

export default FormikVeldFout;
