import styled from 'styled-components';
import { Kleur } from '../../../bedrijfslogica/constanten';

export const Root = styled.div`
  background-color: ${Kleur.Wit};
  border: 1px solid ${Kleur.LichtGrijs};
  //border-radius: 10px;
  font-size: 13px;
  display: flex;
  align-items: center;
  margin: 5px;
`;

export const Weergave = styled.div`
  padding: 2px 8px;
  flex: 1;
  display: flex;
  align-items: center;
`;

export const ActieContainer = styled.div`
  padding: 0 2px;
  //border-radius: 0 15px 15px 0;
  display: flex;
  align-items: center;
  //background-color: ${Kleur.HeelLichtGrijs};
  width: 50px;
  justify-content: space-evenly;
`;
