import React, { useState, useMemo } from 'react';
import Tabblad, { ITabblad } from '../../../components/layout/Tabblad';
import Sponsoring from './Sponsoring';
import Producten from './Producten';
import Werkbonnen from './Werkbonnen';
import Transport from './Transport';
import Inspanningsverplichting from './Inspanningsverplichting';
import useSegmentTabblad from '../../../hooks/useSegmentTabblad';
import { RouteComponentProps } from 'react-router';
import { Route } from 'react-router-dom';
import Retourproducten from './Retourproducten';

export enum ETabblad {
  Producten,
  Sponsoring,
  Inspanningsverplichting,
  Werkbonnen,
  transport,
  retourProducten,
}

interface IProps extends RouteComponentProps {}

const Koppelen: React.FC<IProps> = (props) => {
  const { tabblad, setSegmentTabblad } = useSegmentTabblad<ETabblad>(
    useMemo(
      () => ({
        props,
        defaultTabblad: ETabblad.Producten,
        pathMap: {
          [ETabblad.Producten]: 'producten',
          [ETabblad.Sponsoring]: 'sponsoring',
          [ETabblad.Inspanningsverplichting]: 'inspanningsverplichting',
          [ETabblad.Werkbonnen]: 'werkbonnen',
          [ETabblad.transport]: 'transport',
          [ETabblad.retourProducten]: 'retourProducten',
        },
      }),
      [props],
    ),
  );

  const tabbladen = useMemo<ITabblad<ETabblad>[]>(
    () => [
      {
        id: ETabblad.Producten,
        label: 'Producten',
        content: Producten,
      },
      {
        id: ETabblad.Werkbonnen,
        label: 'Werkbonnen',
        content: Werkbonnen,
      },
      {
        id: ETabblad.transport,
        label: 'Transportopdrachten',
        content: Transport,
      },
      {
        id: ETabblad.retourProducten,
        label: 'Retourproducten',
        content: Retourproducten,
      },
      {
        id: ETabblad.Sponsoring,
        label: 'Sponsoring',
        content: Sponsoring,
      },
      // {
      //   id: ETabblad.Inspanningsverplichting,
      //   label: 'Inspanningsverplichtingen',
      //   content: Inspanningsverplichting,
      // },
    ],
    [],
  );

  return (
    <div className="flex-fill d-flex flex-column">
      <Tabblad<ETabblad, RouteComponentProps<any>>
        geselecteerd={tabblad}
        onSelectieChange={setSegmentTabblad}
        tabbladen={tabbladen}
        options={{
          tabbladComponentProps: props,
        }}
      />
    </div>
  );
};

export default Koppelen;
