import { RouteComponentProps, withRouter } from 'react-router-dom';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import useUrlState from '../../../../core/useUrlState';
import { RootStoreContext } from '../../../../stores/RootStore';
import api from '../../../../api';
import { IOphalenHuidigeSignaleringenResult } from '../../../../../../shared/src/api/v2/control/signalering';
import { useRealtimeListener } from '../../../../one-off-components/realtime/RealtimeIntegratie';
import LoadingSpinner from '../../../Gedeeld/LoadingSpinner';
import SignaleringTabel, { EKolom } from '../../../signalering/SignaleringTabel';
import { Sorting } from '@devexpress/dx-react-grid';
import FilterBalkV2, { IFilter, IFilterData, maakFilterSchema } from '../../../FilterBalkV2';
import { IFilterSchema } from '../../../../../../shared/src/models/filter';
import GeenVerborgenSignaleringenFilter from './GeenVerborgenSignaleringenFilter';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import { IEntiteitProps } from '../../EntiteitContainer';

interface IProps extends IEntiteitProps, RouteComponentProps {}

enum EFilter {
  GeenVerborgenSignaleringen = 'VERBERGEN',
}
export interface IUrlState {
  signaleringSelectie: number[];
  signaleringSortering: Sorting[];
  filterData: Array<IFilterData<EFilter>>;
}

export const defaultUrlState: IUrlState = {
  signaleringSelectie: [],
  signaleringSortering: [],
  filterData: [
    {
      naam: EFilter.GeenVerborgenSignaleringen,
      data: false,
      isActief: true,
    },
  ],
};

const SignaleringenComp: React.FC<IProps> = observer((props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const { klantkaartStore, checkStore } = useContext(RootStoreContext);

  const [
    signaleringenResult,
    setSignaleringenResult,
  ] = useState<IOphalenHuidigeSignaleringenResult | null>(null);

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.GeenVerborgenSignaleringen,
        altijdWeergevenInBalk: true,
        weergave: GeenVerborgenSignaleringenFilter,
      },
    ],
    [],
  );
  const [filterSchema, setFilterSchema] = useState<IFilterSchema>(
    maakFilterSchema(urlState.filterData),
  );

  const ophalenHuidigeSignaleringen = useCallback(async () => {
    const result = await api.v2.signalering.ophalenHuidigeSignaleringen({
      filterSchema: {
        filters: [
          ...filterSchema.filters!,
          {
            naam: 'REL_IDS_IN',
            data: [props.relID],
          },
        ],
      },
    });
    setSignaleringenResult(result);
  }, [filterSchema, props.relID]);

  useEffect(() => {
    ophalenHuidigeSignaleringen();
  }, [ophalenHuidigeSignaleringen]);

  useRealtimeListener(async (event, data) => {
    if (event === 'SIGNALERINGEN_VERBORGEN' || event === 'SIGNALERINGEN_WEERGEGEVEN') {
      await ophalenHuidigeSignaleringen();
    }
  });

  return (
    <div>
      {signaleringenResult === null ? (
        <LoadingSpinner />
      ) : signaleringenResult.run === null || signaleringenResult.signaleringen === null ? (
        <div>Er zijn geen signaleringen beschikbaar.</div>
      ) : (
        <>
          <div>
            <div
              className="d-flex flex-column p-3"
              style={{
                backgroundColor: Kleur.HeelLichtGrijs,
                borderBottom: `1px solid ${Kleur.LichtGrijs}`,
              }}
            >
              <FilterBalkV2
                filters={filters}
                filterData={urlState.filterData}
                onFilterDataChange={(x) => setUrlStateSync('filterData', x)}
                onFilterSchemaChange={(x) => setFilterSchema(x)}
              />
            </div>
          </div>
          <SignaleringTabel
            selection={urlState.signaleringSelectie}
            onSelectionChange={(x) => setUrlStateSync('signaleringSelectie', x)}
            sortering={urlState.signaleringSortering}
            onSorteringChange={(x) => setUrlStateSync('signaleringSortering', x)}
            signaleringen={signaleringenResult.signaleringen}
            run={signaleringenResult.run}
            kolommen={[EKolom.Type, EKolom.Acties]}
            height={200}
          />
        </>
      )}
    </div>
  );
});

export default withRouter(SignaleringenComp);
