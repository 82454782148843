import React, { useCallback, useMemo } from 'react';
import { DataTypeProvider, TableRowDetail } from '@devexpress/dx-react-grid';
import TabelInspringBlok from '../../../../../../../components/layout/TabelInspringBlok';
import {
  GridStyleWrapper,
  TableRowDetailWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../../../../helpers/dxTableGrid';
import {
  Grid,
  VirtualTable,
  TableColumnResizing,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-bootstrap4';
import { VirtualTable as VirtualTableBase } from '@devexpress/dx-react-grid';
import { format } from 'date-fns';
import FormatteerBedrag from '../../../../../../../components/MutatieBedrag';
import MenuLayout from '../../../../../../../components/MenuLayout';
import { Kleur } from '../../../../../../../bedrijfslogica/constanten';
import {
  IOphalenBezoeksetregelsResultElement,
  IOphalenBezoeksetsResultElement,
} from '../../../../../../../../../shared/src/api/v2/transport/bezoek';
import { IBezoekset } from '..';

const geenData = {
  noData: 'Geen data',
};

const DetailComp = (props: TableRowDetail.ContentProps) => {
  const row: IBezoekset = props.row;
  const regels = useMemo<IOphalenBezoeksetregelsResultElement[]>(() => row.regels, [row]);

  const kolommen = useMemo<TypedColumn<IOphalenBezoeksetregelsResultElement>[]>(
    () => [
      {
        name: '__land' as any,
        title: 'Land',
      },
      {
        name: 'PostcodeVan',
        title: 'PC Van',
      },
      {
        name: 'PostcodeTot',
        title: 'PC Tot',
      },
      {
        name: '__dag1' as any,
        title: 'Ma',
      },
      {
        name: '__dag2' as any,
        title: 'Di',
      },
      {
        name: '__dag3' as any,
        title: 'Wo',
      },
      {
        name: '__dag4' as any,
        title: 'Do',
      },
      {
        name: '__dag5' as any,
        title: 'Vr',
      },
      {
        name: '__dag6' as any,
        title: 'Za',
      },
      {
        name: '__dag7' as any,
        title: 'Zo',
      },
    ],
    [],
  );

  const breedteDag = 60;

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IOphalenBezoeksetregelsResultElement>[]>(
    () => [
      {
        columnName: '__land' as any,
        width: 70,
      },
      {
        columnName: 'PostcodeVan',
        width: 100,
      },
      {
        columnName: 'PostcodeTot',
        width: 100,
      },
      {
        columnName: '__dag1' as any,
        width: breedteDag,
      },
      {
        columnName: '__dag2' as any,
        width: breedteDag,
      },
      {
        columnName: '__dag3' as any,
        width: breedteDag,
      },
      {
        columnName: '__dag4' as any,
        width: breedteDag,
      },
      {
        columnName: '__dag5' as any,
        width: breedteDag,
      },
      {
        columnName: '__dag6' as any,
        width: breedteDag,
      },
      {
        columnName: '__dag7' as any,
        width: breedteDag,
      },
    ],
    [],
  );

  const keyExtractor = useCallback((x: IOphalenBezoeksetregelsResultElement) => x.ID, []);

  const kolomExtensies: VirtualTableBase.ColumnExtension[] = useMemo(() => {
    return [
      {
        columnName: `Bedrag`,
        align: 'right',
      },
    ];
  }, []);

  return (
    <TableRowDetailWrapper>
      <div className="d-flex" style={{ backgroundColor: Kleur.Wit }}>
        <TabelInspringBlok />
        <div className="d-flex">
          <div className="flex-fill">
            <MenuLayout
              // menu={
              //   <div className="d-flex">
              //     <button
              //       className="btn btn-sm btn-light"
              //       style={{
              //         border: `1px solid ${Kleur.LichtGrijs}`,
              //       }}
              //     >
              //       TODO
              //     </button>
              //   </div>
              // }
              body={
                <GridStyleWrapper height={'calc(100vh - 100px)'} rowAmount={regels.length}>
                  <Grid rows={regels} columns={kolommen} getRowId={keyExtractor}>
                    <DataTypeProvider
                      for={['__land']}
                      formatterComponent={(formatterprops) => {
                        const rij: IOphalenBezoeksetregelsResultElement = formatterprops.row;
                        return <span>{rij.land.NaamKort}</span>;
                      }}
                    />

                    <DataTypeProvider
                      for={['__dag1']}
                      formatterComponent={(formatterprops) => {
                        const rij: IOphalenBezoeksetregelsResultElement = formatterprops.row;
                        return <span>{rij.Dag1 ? 'Ja' : 'Nee'}</span>;
                      }}
                    />
                    <DataTypeProvider
                      for={['__dag2']}
                      formatterComponent={(formatterprops) => {
                        const rij: IOphalenBezoeksetregelsResultElement = formatterprops.row;
                        return <span>{rij.Dag2 ? 'Ja' : 'Nee'}</span>;
                      }}
                    />
                    <DataTypeProvider
                      for={['__dag3']}
                      formatterComponent={(formatterprops) => {
                        const rij: IOphalenBezoeksetregelsResultElement = formatterprops.row;
                        return <span>{rij.Dag3 ? 'Ja' : 'Nee'}</span>;
                      }}
                    />
                    <DataTypeProvider
                      for={['__dag4']}
                      formatterComponent={(formatterprops) => {
                        const rij: IOphalenBezoeksetregelsResultElement = formatterprops.row;
                        return <span>{rij.Dag4 ? 'Ja' : 'Nee'}</span>;
                      }}
                    />
                    <DataTypeProvider
                      for={['__dag5']}
                      formatterComponent={(formatterprops) => {
                        const rij: IOphalenBezoeksetregelsResultElement = formatterprops.row;
                        return <span>{rij.Dag5 ? 'Ja' : 'Nee'}</span>;
                      }}
                    />
                    <DataTypeProvider
                      for={['__dag6']}
                      formatterComponent={(formatterprops) => {
                        const rij: IOphalenBezoeksetregelsResultElement = formatterprops.row;
                        return <span>{rij.Dag6 ? 'Ja' : 'Nee'}</span>;
                      }}
                    />
                    <DataTypeProvider
                      for={['__dag7']}
                      formatterComponent={(formatterprops) => {
                        const rij: IOphalenBezoeksetregelsResultElement = formatterprops.row;
                        return <span>{rij.Dag7 ? 'Ja' : 'Nee'}</span>;
                      }}
                    />

                    <VirtualTable columnExtensions={kolomExtensies} messages={geenData} />
                    <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
                    {/* <TableHeaderRow showSortingControls /> */}
                    <TableHeaderRow />
                  </Grid>
                </GridStyleWrapper>
              }
            />
          </div>
        </div>
      </div>
    </TableRowDetailWrapper>
  );
};

export default DetailComp;
