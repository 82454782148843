import React, { useContext } from 'react';
import LoadingSpinner from '../../../../../../../components/Gedeeld/LoadingSpinner';
import MultiSelect from '../../../../../../../components/formulier/MultiSelect';
import { ContractenOverzichtContext } from './index';
import { IWeergaveProps } from '../../../../../../../components/FilterBalkV2';
import VerticaleScheidingslijn from '../../../../../../../components/layout/VerticaleScheidingslijn';

const StatusFilter = (weergaveProps: IWeergaveProps<any>) => {
  const { contractstatussenResult } = useContext(ContractenOverzichtContext);

  return (
    <span className="d-flex align-items-center">
      <span className="mr-3">Status</span>
      {contractstatussenResult === null ? (
        <LoadingSpinner />
      ) : (
        <div className="d-flex align-items-center flex-fill">
          {/*<div className="mr-3">*/}
          {/*  <input type="radio" />*/}
          {/*  <span className="ml-2">Actueel</span>*/}
          {/*</div>*/}
          {/*<VerticaleScheidingslijn height={30} />*/}
          {/*<div className="ml-3 mr-3">*/}
          {/*  <input type="radio" />*/}
          {/*  <span className="ml-2">Alles</span>*/}
          {/*</div>*/}
          {/*<VerticaleScheidingslijn height={30} />*/}
          {/*<div className="ml-3 d-flex align-items-center">*/}
          {/*  <input type="radio" checked />*/}
          {/*  <span className="ml-2">Specifiek</span>*/}
          {/*  <div className="ml-2">*/}
          <MultiSelect
            value={weergaveProps.data}
            onChange={(x) => {
              weergaveProps.onDataChange(x);
              weergaveProps.toepassen();
            }}
            opties={contractstatussenResult.statussen.map((x) => ({
              key: x.NaamEnum,
              weergave: x.Naam,
            }))}
          />
          {/*  </div>*/}
          {/*</div>*/}
        </div>
      )}
    </span>
  );
};

export default StatusFilter;
