import {
  IOphalenDienstenParams,
  IOphalenDienstenResult,
} from '../../../../../shared/src/api/v2/dienst/inkoop';
import ICheckData from '../../../../../shared/src/models/ICheckData';
import api from '../../index';

const dienst = {
  ophalenDiensten: async (params: IOphalenDienstenParams): Promise<IOphalenDienstenResult> => {
    return await api.post('v2/dienst/inkoop/ophalen-diensten', params);
  },
};

export default dienst;
