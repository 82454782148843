import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import useUrlState from '../../../../../../core/useUrlState';
import api from '../../../../../../api';
import {
  DXTableCheckboxComponent,
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
  DXTableToggleCellComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../../../helpers/dxTableGrid';
import {
  DataTypeProvider,
  EditingState,
  IntegratedSorting,
  RowDetailState,
  SelectionState,
  SortingState,
  VirtualTable as VirtualTableBase,
} from '@devexpress/dx-react-grid';
import MenuLayout from '../../../../../../components/MenuLayout';
import LoadingSpinner from '../../../../../../components/Gedeeld/LoadingSpinner';
import {
  Grid,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import nameof from '../../../../../../core/nameOf';
import { format } from 'date-fns';
import FormatteerBedrag from '../../../../../../components/MutatieBedrag';
// import DetailComp from './DetailComp';
import _ from 'lodash';
import { EResultType } from '../../../../../../stores/CheckStore';
import { RootStoreContext } from '../../../../../../stores/RootStore';
import { Kleur } from '../../../../../../bedrijfslogica/constanten';
import { IOphalenGebiedenResultElement } from '../../../../../../../../shared/src/api/v2/dienst/service/gebied';
import { LeverancierContext } from '../../../..';
import { IconToevoegen, IconVerwijderen } from '../../../../../../components/Icons';
import ToevoegenDialoog from './ToevoegenDialoog';
import { IOphalenLandenVoorLijstResult } from '../../../../../../../../shared/src/api/v2/land';

interface IProps extends RouteComponentProps {}

interface IToevoegenDialoogState {
  servDienstID: number;
}

interface IUrlState {
  selectie: number[];
  uitgeklapt: number[];
  toevoegenDialoogState: IToevoegenDialoogState | null;
}

const defaultUrlState: IUrlState = {
  selectie: [],
  uitgeklapt: [],
  toevoegenDialoogState: null,
};

export interface IRegel extends IOphalenGebiedenResultElement {}

const Gebieden: React.FC<IProps> = (props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState<IUrlState>(props, defaultUrlState);
  const { checkStore } = useContext(RootStoreContext);

  const { relID, servicedienst } = useContext(LeverancierContext);

  const [gebieden, setGebieden] = useState<IRegel[] | null>(null);

  const ophalenGebieden = useCallback(async () => {
    const gebiedenResult = (
      await api.v2.dienst.service.gebied.ophalenGebieden({
        filterSchema: {
          filters: [{ naam: 'REL_IDS', data: [relID] }],
        },
      })
    ).gebieden;

    const gebiedenGesorteerd = _.orderBy(gebiedenResult, ['PostcodeVan'], ['asc']);

    setGebieden(gebiedenGesorteerd);
  }, []);

  useEffect(() => {
    ophalenGebieden();
  }, [ophalenGebieden]);

  const [landen, setLanden] = useState<IOphalenLandenVoorLijstResult | null>(null);

  const ophalenLanden = useCallback(async () => {
    const result = await api.v2.land.ophalen({
      filterSchema: {
        filters: [],
      },
    });
    setLanden(result);
  }, []);

  useEffect(() => {
    ophalenLanden();
  }, [ophalenLanden]);

  const keyExtractor = useCallback((rij: IRegel) => rij.ID, []);
  const kolommen = useMemo<TypedColumn<IRegel>[]>(
    () => [
      {
        name: 'PostcodeVan',
        title: 'Postcode Van',
      },
      {
        name: 'PostcodeTot',
        title: 'Postcode Tot',
      },
      {
        name: '__landnaam' as any,
        title: 'Land',
      },
    ],
    [],
  );
  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRegel>[]>(
    () => [
      {
        columnName: 'PostcodeVan',
        width: 130,
      },
      {
        columnName: 'PostcodeTot',
        width: 130,
      },
      {
        columnName: '__landnaam' as any,
        width: 150,
      },
    ],
    [],
  );

  return (
    <>
      <MenuLayout
        menu={
          <div className="d-flex align-items-center">
            <button
              className="btn btn-sm btn-light d-flex align-items-center"
              style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
              onClick={async () => {
                setUrlStateSync('toevoegenDialoogState', { servDienstID: urlState.selectie[0] });
              }}
            >
              <IconToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
              <span className="ml-2">Toevoegen</span>
            </button>

            <button
              className="btn btn-sm btn-light d-flex align-items-center ml-3"
              style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
              disabled={urlState.selectie.length === 0}
              onClick={async () => {
                const params = {
                  filterSchema: { filters: [{ naam: 'IDS', data: urlState.selectie }] },
                };

                // const checkData = await api.v2.dienst.service.gebied.checkVerwijderenGebieden(params);
                // if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
                //   return;
                // }

                const bevestigenResult = await checkStore.bevestigen({
                  inhoud: <span>Geselecteerde gebieden verwijderen?</span>,
                });
                if (bevestigenResult.type === EResultType.Annuleren) {
                  return;
                }

                await api.v2.dienst.service.gebied.verwijderenGebieden(params);

                ophalenGebieden();
              }}
            >
              <IconVerwijderen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
              <span className="ml-2">Verwijderen</span>
            </button>
          </div>
        }
        body={
          gebieden === null || landen === null ? (
            <LoadingSpinner />
          ) : (
            <GridStyleWrapper height={'calc(100vh - 200px)'}>
              <Grid rows={gebieden} columns={kolommen} getRowId={keyExtractor}>
                <DataTypeProvider
                  for={['__landnaam']}
                  formatterComponent={(formatterProps) => {
                    const rij: IRegel = formatterProps.row;
                    const land = landen.find((x) => x.LandID === rij.LandID)!.Naam;
                    return <span>{land}</span>;
                  }}
                />

                <SortingState defaultSorting={[]} />
                <IntegratedSorting />

                <RowDetailState
                  expandedRowIds={urlState.uitgeklapt}
                  onExpandedRowIdsChange={(x) => setUrlStateSync('uitgeklapt', x as number[])}
                />

                <VirtualTable messages={{ noData: 'Geen gebieden opgegeven' }} />
                <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
                <TableHeaderRow showSortingControls />

                <EditingState
                  onCommitChanges={(changes) => {
                    if (changes.deleted === undefined) {
                      return;
                    }
                    const deleted = changes.deleted;
                    const id = deleted[deleted.length - 1];
                    alert(id);
                  }}
                  // onEditingRowIdsChange={(rowIds) => {
                  //   const id = rowIds[rowIds.length - 1] as number;
                  //   props.onWijzigenOpdrachtDialoogStateChange({ trsOpdID: id });
                  //   // alert(id);
                  // }}
                />

                {/* <TableEditColumn
                width={35}
                showEditCommand
                cellComponent={DXTableEditColumnCellComponent}
                commandComponent={DXTableEditColumnCommandComponent}
              /> */}

                {/* <TableRowDetail
                contentComponent={DetailComp}
                toggleCellComponent={DXTableToggleCellComponent}
              /> */}

                <SelectionState
                  selection={urlState.selectie}
                  onSelectionChange={(x) => setUrlStateSync('selectie', x as number[])}
                />
                <TableSelection cellComponent={DXTableCheckboxComponent} />
              </Grid>
            </GridStyleWrapper>
          )
        }
      />
      {urlState.toevoegenDialoogState !== null && (
        <ToevoegenDialoog
          open
          servDienstID={servicedienst.data!.ID}
          onSuccess={async () => {
            setUrlStateSync('toevoegenDialoogState', null);
            ophalenGebieden();
          }}
          onAnnuleren={() => setUrlStateSync('toevoegenDialoogState', null)}
        />
      )}
    </>
  );
};

export default withRouter(Gebieden);
