import React, { useMemo } from 'react';
import Combobox from '../Combobox';

export interface IOptie {
  id: number;
  label: string | JSX.Element;
}

interface IOptions {
  legeOptieTekst?: string;
}

interface IProps {
  enum: object;
  geselecteerd: number | null;
  onSelectieChange?: (id: number | null) => void;
  legeOptieTonen?: boolean;
  disabled?: boolean;
  isWisbaar?: boolean;
  options?: IOptions;
  style?: React.CSSProperties;
  className?: string;
}

const EnumCombobox = (props: IProps) => {
  const opties = useMemo<IOptie[]>(() => {
    const keys = Object.keys(props.enum).filter((key) => isNaN(Number(key)));
    const values = Object.values(props.enum).filter((key) => !isNaN(Number(key)));
    return keys.map((key, index) => {
      return {
        id: values[index],
        label: key,
      };
    });
  }, []);

  return (
    <Combobox
      geselecteerd={props.geselecteerd}
      onSelectieChange={props.onSelectieChange}
      opties={opties}
      legeOptieTonen={props.legeOptieTonen}
      disabled={props.disabled}
      isWisbaar={props.isWisbaar}
      options={props.options}
      style={props.style}
      className={props.className}
    />
  );
};

export default EnumCombobox;
