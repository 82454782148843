import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import IDialoogProps from '../../../../core/IDialoogProps';
import Dialoog from '../../../../components/dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import * as Yup from 'yup';
import { IOphalenDienstenVoorTypeResultElement } from '../../../../../../shared/src/api/v2/inkoop/opdracht/nieuw';
import { IOphalenOpdrachtenResultElement } from '../../../../../../shared/src/api/v2/inkoop/opdracht';
import api from '../../../../api';
import nameof from '../../../../core/nameOf';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../../stores/RootStore';

import MultiCombobox, { IKolom } from '../../../../components/formulier/MultiCombobox';
import NumeriekVeld from '../../../../components/formulier/NumeriekVeld';
import teksten from '../../../../bedrijfslogica/teksten';
import DatumKiezer from '../../../../components/formulier/DatumKiezer';
import { addDays, format } from 'date-fns';
import VinkVeld from '../../../../components/formulier/VinkVeld';

interface IProps extends IDialoogProps<null> {
  inkOpdID: number;
}

interface IFormikValues {
  inkDienstID: number | null;
  aantal: number | null;
  referentie: string;
  datumGewenst: Date | null;
  geenTeLaatMelding: boolean;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  inkDienstID: 'Leverancier',
  aantal: 'Aantal',
  referentie: 'Referentie',
  datumGewenst: 'Gewenste afleverdatum',
  geenTeLaatMelding: 'Geen melding geven bij te late leveringen',
};

/**
 *
 * Dit WijzigenOpdrachtDialoog wordt gebruikt bij opdrachten in tabblad Uitstaand
 * Voor nog niet-verstuurde opdrachten zie WijzigenStatusDialoog
 *
 * */

const WijzigenDialoog: React.FC<IProps> = observer((props) => {
  const { dialoogIndex, onAnnuleren, onSuccess, inkOpdID } = props;
  const { checkStore } = useContext(RootStoreContext);
  const formikRef = useRef<Formik<IFormikValues>>(null);

  const [opdracht, setOpdracht] = useState<IOphalenOpdrachtenResultElement | null>(null);
  const [leveranciers, setLeveranciers] = useState<IOphalenDienstenVoorTypeResultElement[] | null>(
    null,
  );

  const ophalenOpdracht = useCallback(async () => {
    if (inkOpdID === null) {
      return;
    }
    const result = await api.v2.inkoop.opdracht.ophalenOpdrachten({
      filterSchema: { filters: [{ naam: 'IDS', data: [inkOpdID] }] },
    });
    setOpdracht(result.inkoopopdrachten[0]);
  }, [inkOpdID]);

  useEffect(() => {
    ophalenOpdracht();
  }, [inkOpdID]);

  // Leveranciers voor het type
  useEffect(() => {
    (async () => {
      if (opdracht === null) {
        return;
      }
      // const results = await api.v2.inkoop.opdracht.nieuw.ophalenLeveranciers({
      //   typeID: opdracht.product.TypeID,
      // });
      const results = await api.v2.inkoop.opdracht.nieuw.ophalenDienstenVoorType({
        typeID: opdracht.producttype.TypeID,
      });
      const diensten = results.diensten;
      setLeveranciers(diensten);

      // setLeveranciers(results);
    })();
  }, [opdracht]);

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (opdracht === null) {
      return null;
    }
    return {
      inkDienstID: opdracht.dienst.ID,
      aantal: opdracht.Aantal,
      referentie: opdracht.Referentie === null ? '' : opdracht.Referentie,
      datumGewenst: opdracht.DatumGewenst === null ? null : new Date(opdracht.DatumGewenst),
      geenTeLaatMelding: opdracht.GeenTeLaatMelding,
    };
  }, [opdracht]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      const params = {
        inkOpdID,
        inkDienstID: values.inkDienstID!,
        aantal: values.aantal!,
        referentie: values.referentie === '' ? null : values.referentie,
        datumGewenst:
          values.datumGewenst !== null ? format(new Date(values.datumGewenst), 'yyyy-MM-dd') : null,
        geenTeLaatMelding: values.geenTeLaatMelding,
      };

      await api.v2.inkoop.opdracht.nieuw.wijzigenOpdracht(params);

      actions.setSubmitting(false);
      onSuccess(null);
    },
    [],
  );

  const validationSchema = useMemo(() => {
    const fields: Record<keyof IFormikValues, any> = {
      datumGewenst: Yup.date().nullable(),
      referentie: Yup.string(),
      inkDienstID: Yup.number(),
      aantal: Yup.number()
        .min(1, teksten.formulier.E_GETAL_MINIMAAL({ veldnaam: veldnamen.aantal, aantal: 1 }))
        .max(250, teksten.formulier.E_GETAL_MAXIMAAL({ veldnaam: veldnamen.aantal, aantal: 250 })),
      geenTeLaatMelding: Yup.boolean(),
    };
    return Yup.object().shape(fields);
  }, []);

  const leveranciersKolommen = useMemo<IKolom<IOphalenDienstenVoorTypeResultElement>[]>(() => {
    return [
      {
        key: 'LeveranciernaamKort',
        label: 'Leverancier',
        breedte: 200,
      },
      {
        key: 'Prioriteit',
        formatFabriek: (row) => {
          return <span>{row.Prioriteit ? 'Ja' : ''}</span>;
        },
        label: 'Prio.lev.',
        breedte: 100,
      },
      {
        key: 'Inkoopprijs',
        label: 'Ink.prijs',
        breedte: 100,
      },
    ];
  }, []);

  return (
    <Dialoog index={dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Wijzigen inkoopopdracht</ModalTitle>
      </ModalHeader>
      {initialValues === null ? (
        <LoadingSpinner />
      ) : (
        <Formik<IFormikValues>
          ref={formikRef}
          onSubmit={handleSubmit}
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          render={(formikProps: FormikProps<IFormikValues>) => {
            const { submitForm, isSubmitting, isValid } = formikProps;
            return (
              <>
                <ModalBody>
                  <div className="form-group">
                    <div className="row">
                      {leveranciers !== null && (
                        <div className="col-12 mt-2">
                          <label>{veldnamen.inkDienstID}</label>

                          <Field
                            name={nameof<IFormikValues>('inkDienstID')}
                            render={(fieldProps: FieldProps<IFormikValues>) => {
                              const { field, form } = fieldProps;

                              return (
                                <MultiCombobox<number, IOphalenDienstenVoorTypeResultElement>
                                  sleutelExtractor={(row: IOphalenDienstenVoorTypeResultElement) =>
                                    row.ID
                                  }
                                  onWaardeChange={(waarde: number | null) => {
                                    fieldProps.form.setFieldValue(field.name, waarde);
                                  }}
                                  representatieFabriek={(
                                    row: IOphalenDienstenVoorTypeResultElement,
                                  ) =>
                                    `${row.LeveranciernaamKort} ${row.Prioriteit ? ' (prio)' : ''}`
                                  }
                                  waarde={fieldProps.field.value}
                                  opties={
                                    leveranciers !== null
                                      ? leveranciers.map((leverancier) => {
                                          return {
                                            ID: leverancier.ID,
                                            LeveranciernaamKort: leverancier.LeveranciernaamKort,
                                            Prioriteit: leverancier.Prioriteit,
                                            Inkoopprijs: leverancier.Inkoopprijs,
                                            InkoopprijsActie: leverancier.InkoopprijsActie,
                                            ActieMinimaalAantal: leverancier.ActieMinimaalAantal,
                                            DirectLeverbaar: leverancier.DirectLeverbaar,
                                          };
                                        })
                                      : []
                                  }
                                  kolommen={leveranciersKolommen}
                                />
                              );
                            }}
                          />
                        </div>
                      )}

                      <div className="col-3 mt-2">
                        <label>{veldnamen.aantal}</label>
                        <Field
                          name={nameof<IFormikValues>('aantal')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <NumeriekVeld
                                waarde={field.value}
                                onChange={(x) => form.setFieldValue(field.name, x)}
                                min={1}
                                max={250}
                              />
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-2">
                        <label>{veldnamen.referentie}</label>
                        <Field
                          name={nameof<IFormikValues>('referentie')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            return (
                              <input type="text" className="form-control" {...fieldProps.field} />
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <label>{veldnamen.datumGewenst}</label>
                        <Field
                          name="datumGewenst"
                          render={({ field, form }: FieldProps<IFormikValues>) => {
                            const minimaleDatum = addDays(new Date(), -30);
                            const maximaleDatum = addDays(new Date(), +180);
                            return (
                              <div className="d-flex ">
                                <DatumKiezer
                                  onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                  waarde={field.value}
                                  isClearable
                                  determineValidDate={(date) => {
                                    return date >= minimaleDatum && date <= maximaleDatum;
                                  }}
                                  determineNextValidDate={(date) => {
                                    const nieuweDatum = addDays(date, +1);
                                    if (nieuweDatum <= maximaleDatum) {
                                      return nieuweDatum;
                                    }
                                    return null;
                                  }}
                                  determinePreviousValidDate={(date) => {
                                    const nieuweDatum = addDays(date, -1);
                                    if (nieuweDatum >= minimaleDatum) {
                                      return nieuweDatum;
                                    }
                                    return null;
                                  }}
                                />
                              </div>
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <span className="d-flex align-items-center">
                          <Field
                            name="geenTeLaatMelding"
                            render={(x: FieldProps<IFormikValues>) => (
                              <VinkVeld
                                aangevinkt={x.field.value}
                                onGewijzigd={(aangevinkt: boolean) =>
                                  x.form.setFieldValue(x.field.name, aangevinkt)
                                }
                              />
                            )}
                          />
                          <span className="ml-2">{veldnamen.geenTeLaatMelding}</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </ModalBody>

                <ModalFooter className="d-flex flex-row justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    style={{ width: 100 }}
                    disabled={isSubmitting || !isValid}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={onAnnuleren}
                    style={{ width: 100 }}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Dialoog>
  );
});

export default WijzigenDialoog;
