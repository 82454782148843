import React, { RefObject, useMemo } from 'react';
import { Root } from './style';
import Tab from './Tab';
import { Kleur } from '../../../bedrijfslogica/constanten';
import { IconToevoegen } from '../../Icons';
import classNames from 'classnames';
import VerticaleScheidingslijn from '../VerticaleScheidingslijn';

export interface ITabblad<TIdentifier, ITabbladProps = any> {
  id: TIdentifier;
  label: string | JSX.Element;
  content: React.ComponentType<ITabbladProps>;
}

export interface IOptions<TIdentifier, TTabbladCompProps = any> {
  smalleWeergave?: boolean;
  tabbladComponentProps?: TTabbladCompProps | null;
  onTabToevoegenKnopRef?: RefObject<HTMLDivElement>;
  toegepasteTabbladenUitgeslotenTabbladen?: TIdentifier[];
}

export enum EOrientatie {
  Horizontaal,
  Verticaal,
}

interface IProps<TIdentifier, TTabbladCompProps = any> {
  geselecteerd: TIdentifier | null;
  onSelectieChange: (id: TIdentifier) => void;
  tabbladen: ITabblad<TIdentifier>[];
  onTabToevoegen?: () => void;
  onTabVerwijderen?: (id: TIdentifier) => void;
  toegepasteTabbladen?: TIdentifier[];
  onTabToepassenChange?: (id: TIdentifier, toepassen: boolean) => void;
  orientatie?: EOrientatie;
  verticaleBreedte?: number | string;
  options?: IOptions<TIdentifier, TTabbladCompProps>;
  disabledTabladden?: TIdentifier[];
}

const defaultOptions: IOptions<any> = {
  smalleWeergave: false,
  tabbladComponentProps: {},
  onTabToevoegenKnopRef: undefined,
  toegepasteTabbladenUitgeslotenTabbladen: [],
};

const Tabblad = <TIdentifier extends number | string, TTabbladCompProps = any>(
  props: IProps<TIdentifier, TTabbladCompProps>,
) => {
  const options = useMemo(() => ({ ...defaultOptions, ...props.options }), [props.options]);
  const {
    tabbladen,
    geselecteerd,
    onSelectieChange,
    onTabToevoegen,
    orientatie,
    onTabToepassenChange,
    toegepasteTabbladen,
  } = props;

  const geselecteerdTabblad = useMemo<ITabblad<TIdentifier> | null>(() => {
    if (geselecteerd === null) {
      return null;
    }
    return tabbladen.find((tabblad) => tabblad.id === geselecteerd)!;
  }, [tabbladen, geselecteerd]);

  const Comp = useMemo(() => {
    if (geselecteerdTabblad === null) {
      return null;
    }
    return geselecteerdTabblad.content;
  }, [geselecteerdTabblad]);

  return (
    <div
      className={classNames([
        'd-flex flex-fill',
        orientatie !== EOrientatie.Verticaal ? 'flex-column' : null,
      ])}
    >
      <div
        className={classNames([
          orientatie !== EOrientatie.Verticaal ? 'd-flex' : null,
          orientatie === EOrientatie.Verticaal ? 'flex-column' : null,
        ])}
        style={{
          borderBottom: `1px solid ${Kleur.LichtGrijs}`,
          minWidth: props.verticaleBreedte === undefined ? undefined : props.verticaleBreedte,
        }}
      >
        {tabbladen.length > 0 ? (
          tabbladen.map((tabblad, i) => {
            const toegepast =
              toegepasteTabbladen === undefined ||
              options.toegepasteTabbladenUitgeslotenTabbladen!.some((x) => x === tabblad.id)
                ? undefined
                : toegepasteTabbladen.some((id) => id === tabblad.id);

            const disabled =
              props.disabledTabladden === undefined
                ? false
                : props.disabledTabladden.some((id) => id === tabblad.id);

            return (
              <React.Fragment key={tabblad.id}>
                <Tab
                  tabblad={tabblad}
                  geselecteerd={
                    geselecteerdTabblad !== null && tabblad.id === geselecteerdTabblad.id
                  }
                  onClick={() => onSelectieChange(tabblad.id)}
                  smalleWeergave={options.smalleWeergave!}
                  onVerwijderen={
                    props.onTabVerwijderen === undefined
                      ? undefined
                      : () => props.onTabVerwijderen!(tabblad.id)
                  }
                  toegepast={toegepast}
                  onToepassenChange={
                    onTabToepassenChange === undefined
                      ? undefined
                      : (x) => onTabToepassenChange(tabblad.id, x)
                  }
                  disabled={disabled}
                />
                {/*{i !== tabbladen.length - 1 && (*/}
                {/*  <div style={{ height: 30, width: 1, backgroundColor: Kleur.LichtGrijs }} />*/}
                {/*)}*/}
              </React.Fragment>
            );
          })
        ) : (
          <div className="flex-fill" />
        )}
        {onTabToevoegen !== undefined && (
          <div
            ref={options.onTabToevoegenKnopRef}
            className="d-flex align-items-center justify-content-center"
            style={{
              backgroundColor: Kleur.LichtGrijs,
              cursor: 'pointer',
              padding: `${options.smalleWeergave ? 7 : 8}px 15px`,
            }}
            onClick={(ev) => {
              ev.stopPropagation();
              onTabToevoegen();
            }}
          >
            <IconToevoegen style={{ width: 20, height: 20, fill: Kleur.Zwart }} />
            {/*<span className="ml-2" style={{ color: Kleur.Wit }}>*/}
            {/*  Toevoegen*/}
            {/*</span>*/}
          </div>
        )}
      </div>
      {orientatie === EOrientatie.Verticaal && <VerticaleScheidingslijn color={Kleur.LichtGrijs} />}
      <div className="d-flex flex-column flex-fill">
        {Comp === null ? <div /> : <Comp {...options.tabbladComponentProps} />}
      </div>
    </div>
  );
};

export default Tabblad;
