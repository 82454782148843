import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import LoadingSpinner from '../../../components/Gedeeld/LoadingSpinner';
import MenuLayout from '../../../components/MenuLayout';
import useUrlState from '../../../core/useUrlState';
import { IOphalenBeltakenResultElement } from '../../../../../shared/src/api/v2/beltaak/beltaak';
import api from '../../../api';
import {
  ASPKolom,
  EAspKolomBreedteType,
  ESortering,
  IAspKolomSorteringItem,
} from '../../../components/tabel/ASPTabel/types';
import PersoonVisualisatie from '../../../components/personalia/PersoonVisualisatie';
import { format } from 'date-fns';
import RelatieVisualisatie from '../../../components/personalia/RelatieVisualisatie';
import ASPTabel from '../../../components/tabel/ASPTabel';
import Chip from '../../../components/Chip';
import BezigheidKnop from '../../../components/BezigheidKnop';
import { Kleur as EKleur } from '../../../bedrijfslogica/constanten';
import {
  EFunctioneleIcon,
  functioneleIconMap,
  IconHerstel,
  IconToevoegen,
  IconVerwijderen,
} from '../../../components/Icons';
import MarkerenAfgehandeldDialoog from './MarkerenAfgehandeldDialoog';
import { RootStoreContext } from '../../../stores/RootStore';
import { EResultType } from '../../../stores/CheckStore';
import SelectieVak from '../../../components/SelectieVak';
import { Helmet } from 'react-helmet';
import FactuurVisualisatie from '../../../components/entiteitVisualisaties/FactuurVisualisatie';
import FilterBalkV2, {
  IFilter,
  IFilterData,
  maakFilterSchema,
} from '../../../components/FilterBalkV2';
import { IOphalenFacturenBasisResultElement } from '../../../../../shared/src/api/v2/factuur';
import _ from 'lodash';
import FormatteerBedrag from '../../../components/MutatieBedrag';
import { IOphalenBeltaakContextenResultElement } from '../../../../../shared/src/api/v2/beltaak/context/context';
import { EBeltaakContext } from '../../../bedrijfslogica/enums';
import Combobox from '../../../components/formulier/Combobox';
import { IOphalenTelefoonOproepenResult } from '../../../../../shared/src/api/v2/telefonie';
import { nl } from 'date-fns/locale';
import { IFilterSchemaFilter } from '../../../../../shared/src/models/filter';

interface IProps extends RouteComponentProps {}

interface IMarkerenAfgehandeldDialoogState {
  ids: number[];
}

enum EFilter {
  // AlleenNietAfgehandeld = 'IS_AFGEHANDELD',
  ContextID = 'CONTEXT_IDS',
  Status = 'STATUS_AFGEHANDELD',
}

interface IUrlState {
  filterdata: IFilterData<EFilter>[];
  selectie: number[];
  sortering: IAspKolomSorteringItem<EKolom>[];
  markerenAfgehandeldDialoogState: IMarkerenAfgehandeldDialoogState | null;
}

const enum EKolom {
  Persoon,
  Relatie,
  Datum,
  GesprokenOp,
  Contexten,
  Bezigheid,
  isAfgehandeld,
  Facturen,
  TussentijdsVoldaan,
  LaatstGebeld,
}

const IconAfgehandeld = functioneleIconMap[EFunctioneleIcon.Afgehandeld]!;

interface IWrapperData {
  contextID: number;
  contexten: IOphalenBeltaakContextenResultElement[];
}

const BeltakenWrapper = (props: IProps) => {
  const [contexten, setContexten] = useState<IOphalenBeltaakContextenResultElement[] | null>(null);

  const ophalenContexten = useCallback(async () => {
    const contextenResult = await api.v2.beltaak.context.ophalenBeltaakContexten({
      filterSchema: {
        // filters: [{ naam: 'DAGBOEKSOORT_NAAM_ENUMS', data: [params.dagboeksoort] }],
      },
    });

    setContexten(contextenResult.contexten);
  }, []);

  useEffect(() => {
    ophalenContexten();
  }, [ophalenContexten]);

  const contextID = useMemo(() => {
    if (contexten === null) {
      return null;
    }
    return contexten.find((x) => x.NaamEnum === EBeltaakContext.Debiteuren)!.ID;
  }, [contexten]);

  if (contexten === null) {
    return null;
  }

  return <Beltaken {...props} contextID={contextID!} contexten={contexten} />;
};

const Beltaken = (props: IProps & IWrapperData) => {
  const defaultUrlState = useMemo<IUrlState>(() => {
    return {
      filterdata: [
        {
          naam: EFilter.ContextID,
          data: [props.contextID],
          isActief: true,
        },
        // {
        //   naam: EFilter.AlleenNietAfgehandeld,
        //   data: false,
        //   isActief: true,
        // },
        {
          naam: EFilter.Status,
          data: [1],
          isActief: true,
        },
      ],
      selectie: [],
      sortering: [
        {
          key: EKolom.Datum,
          sortering: ESortering.Ascending,
        },
        {
          key: EKolom.Persoon,
          sortering: ESortering.Ascending,
        },
      ],
      markerenAfgehandeldDialoogState: null,
    };
  }, [props.contextID]);

  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const { checkStore } = useContext(RootStoreContext);

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.ContextID,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return (
            <div className="d-flex align-items-center">
              <span className="mr-2">Context</span>
              {props.contexten === null ? (
                <LoadingSpinner />
              ) : (
                <Combobox
                  geselecteerd={weergaveProps.data.length === 0 ? null : weergaveProps.data[0]}
                  onSelectieChange={(x) => {
                    weergaveProps.onDataChange(x);
                    weergaveProps.setIsActief(true);
                    weergaveProps.toepassen();
                  }}
                  opties={props.contexten.map((x) => ({
                    id: x.ID,
                    label: x.Naam,
                  }))}
                />
              )}
            </div>
          );
        },
      },
      // {
      //   naam: EFilter.AlleenNietAfgehandeld,
      //   altijdWeergevenInBalk: true,
      //   weergave: () => <span>Alleen niet-afgehandeld</span>,
      // },
      {
        naam: EFilter.Status,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          const opties = [
            { ID: 1, Naam: 'Niet afgehandeld' },
            { ID: 2, Naam: 'Afgehandeld' },
          ];
          const data: number[] = weergaveProps.data;

          return (
            <span className="d-flex align-items-center">
              <span className="mr-3">Status</span>
              {false === null ? (
                <LoadingSpinner />
              ) : (
                <Combobox
                  geselecteerd={data.length === 0 ? null : data[0]}
                  onSelectieChange={(x) => {
                    const newData = x === null ? [] : [x];

                    weergaveProps.onDataChange(newData);
                    weergaveProps.toepassen();
                  }}
                  opties={opties.map((x) => ({
                    id: x.ID,
                    label: x.Naam,
                  }))}
                />
              )}
            </span>
          );
        },
      },
    ],
    [props.contexten],
  );
  const [filterSchema, setFilterSchema] = useState(maakFilterSchema(urlState.filterdata));

  const [beltaken, setBeltaken] = useState<IOphalenBeltakenResultElement[] | null>(null);
  const [facturen, setFacturen] = useState<IOphalenFacturenBasisResultElement[] | null>(null);
  const [telefoonoproepen, setTelefoonoproepen] = useState<IOphalenTelefoonOproepenResult | null>(
    null,
  );

  const ophalenBeltaken = useCallback(async () => {
    if (filterSchema.filters === undefined) {
      return;
    }

    const statusFilter = filterSchema.filters.find((x) => x.naam === EFilter.Status);

    const beltakenResult = await api.v2.beltaak.ophalenBeltaken({
      filterSchema: {
        filters: [
          ...filterSchema.filters!,
          statusFilter !== undefined
            ? statusFilter.data[0] === 1
              ? { naam: 'IS_AFGEHANDELD', data: false }
              : { naam: 'IS_AFGEHANDELD', data: true }
            : null,
        ].filter((x) => x !== null) as IFilterSchemaFilter[],
      },
    });

    setBeltaken(beltakenResult.beltaken);
  }, [filterSchema]);

  useEffect(() => {
    ophalenBeltaken();
  }, [ophalenBeltaken]);

  const ophalenOproepen = useCallback(async () => {
    if (beltaken === null) {
      return null;
    }

    const persIDs = beltaken.map((x) => x.persoon.PersID);

    const result = await api.v2.telefonie.ophalenTelefoonOproepen({
      filterSchema: {
        filters: [
          { naam: 'PERS_IDS', data: persIDs },
          { naam: 'RICHTING', data: ['U'] },
        ],
      },
    });

    setTelefoonoproepen(result);
  }, [beltaken]);

  useEffect(() => {
    ophalenOproepen();
  }, [ophalenOproepen]);

  const ophalenFacturen = useCallback(async () => {
    if (beltaken === null) {
      return;
    }
    const facturenResult = await api.v2.factuur.ophalenFacturenBasis({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: _.flatten(beltaken.map((x) => x.facturen)).map((x: any) => x.FactID),
          },
        ],
      },
    });

    setFacturen(facturenResult.facturen);
  }, [beltaken]);

  useEffect(() => {
    ophalenFacturen();
  }, [ophalenFacturen]);

  const keyExtractor = useCallback((rij: IOphalenBeltakenResultElement) => rij.ID, []);

  const kolommen = useMemo<ASPKolom<EKolom, IOphalenBeltakenResultElement>[]>(
    () => [
      {
        key: EKolom.Persoon,
        label: 'Persoon',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 250,
        renderer: (rij) => {
          return <PersoonVisualisatie persID={rij.persoon.PersID} />;
        },
      },
      {
        key: EKolom.Datum,
        label: 'Datum',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 150,
        renderer: (rij) => <span>{format(new Date(rij.Datum), 'dd-MM-yyyy HH:mm')}</span>,
      },
      {
        key: EKolom.Contexten,
        label: 'Context(en)',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 225,
        renderer: (rij) => {
          if (rij.beltaakContexten.length === 0) {
            return 'Geen';
          }

          return (
            <div className="d-flex align-items-center" style={{ columnGap: 5, rowGap: 3 }}>
              {rij.beltaakContexten.map((x) => (
                <Chip key={x.ID}>{x.Naam}</Chip>
              ))}
            </div>
          );
        },
      },
      {
        key: EKolom.Facturen,
        label: 'Entiteiten',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 165,
        renderer: (rij) => {
          if (rij.facturen.length === 0) {
            return 'Geen';
          }
          const factID = rij.facturen[0].FactID;
          return <FactuurVisualisatie factID={factID} />;
        },
      },
      {
        key: EKolom.TussentijdsVoldaan,
        label: 'Tussentijds voldaan',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 100,
        renderer: (rij) => {
          if (facturen === null) {
            return <span />;
          }
          const openstaandInBeltaak = _.sum(rij.facturen.map((x) => x.Bedrag));
          const openstaandActueel = _.sum(
            facturen
              .filter((x) => rij.facturen.map((x) => x.FactID).indexOf(x.FactID) !== -1)
              .map((x) => x.Openstaand),
          );
          const verschil = openstaandInBeltaak - openstaandActueel;

          return verschil !== 0 ? <FormatteerBedrag bedrag={verschil} /> : <span>-</span>;
        },
      },
      {
        key: EKolom.Relatie,
        label: 'Relatie',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 225,
        renderer: (rij) => {
          return rij.RelID !== null ? <RelatieVisualisatie relID={rij.RelID} /> : <span />;
        },
      },
      {
        key: EKolom.LaatstGebeld,
        label: 'Laatst gebeld op',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 160,
        renderer: (rij) => {
          if (telefoonoproepen === null) {
            return;
          }

          const laatsteOproep =
            _.orderBy(
              telefoonoproepen.oproepen.filter(
                (x) => x.PersID === rij.persoon.PersID && x.Datum > rij.Datum,
              ),
              'Datum',
              'desc',
            )[0] ?? null;

          return (
            <span>
              {laatsteOproep !== null
                ? format(new Date(laatsteOproep.Datum), 'EEEEEE dd-MM HH:mm', { locale: nl })
                : ''}
            </span>
          );
        },
        vergelijkingswaarde: (rij) => {
          return rij.GesprokenOp;
        },
      },
      {
        key: EKolom.GesprokenOp,
        label: 'Gesproken op',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 150,
        renderer: (rij) => {
          return rij.GesprokenOp !== null ? (
            <span>{format(new Date(rij.GesprokenOp), 'dd-MM-yyyy')}</span>
          ) : (
            <span>
              {rij.IsAfgehandeld
                ? rij.GesprokenOp !== null
                  ? rij.GesprokenOp
                  : 'Niet'
                : 'Nog niet'}
            </span>
          );
        },
      },
      {
        key: EKolom.Bezigheid,
        label: 'Mee bezig',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 110,
        renderer: (rij) => {
          return (
            <BezigheidKnop data={{ enum: 'BELTAAK', beltaakID: rij.ID }} interactieToegestaan />
          );
        },
      },
      {
        key: EKolom.isAfgehandeld,
        label: 'Afgehandeld',
        breedteType: EAspKolomBreedteType.Flex,
        flex: 1,
        renderer: (rij) => {
          return rij.IsAfgehandeld ? 'Ja' : 'Nee';
        },
      },
    ],
    [facturen, telefoonoproepen],
  );

  if (beltaken === null) {
    return (
      <div className="justify-content-center align-items-center d-flex flex-fill">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Beltaken</title>
      </Helmet>
      <MenuLayout
        menu={
          <>
            <div className="d-flex align-items-center">
              <button
                className="btn btn-sm btn-light d-flex align-items-center justify-content-center"
                style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                onClick={async () => {
                  if (
                    (
                      await checkStore.bevestigen({
                        inhoud: 'Beltaken toevoegen?',
                      })
                    ).type === EResultType.Annuleren
                  ) {
                    return;
                  }

                  await api.v2.beltaak.makenBeltaakVoorDebiteuren({});

                  await ophalenBeltaken();
                }}
              >
                <IconToevoegen style={{ width: 16, height: 16, fill: EKleur.Grijs }} />
                <span className="ml-2">Toevoegen beltaken</span>
              </button>

              <button
                className="btn btn-sm btn-light d-flex align-items-center justify-content-center ml-3"
                style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                disabled={urlState.selectie.length === 0}
                onClick={async () => {
                  setUrlStateSync('markerenAfgehandeldDialoogState', {
                    ids: urlState.selectie,
                  });
                }}
              >
                <IconAfgehandeld style={{ width: 16, height: 16, fill: EKleur.Grijs }} />
                <span className="ml-2">Afgehandeld markeren</span>
              </button>

              <button
                className="btn btn-sm btn-light d-flex align-items-center justify-content-center ml-3"
                style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                disabled={urlState.selectie.length === 0}
                onClick={async () => {
                  const params = { ids: urlState.selectie };
                  const checkData = await api.v2.beltaak.checkHerstellenMarkerenAfgehandeld(params);
                  if (
                    (await checkStore.controleren({ checkData })).type === EResultType.Annuleren
                  ) {
                    return;
                  }

                  if (
                    (
                      await checkStore.bevestigen({
                        inhoud: (
                          <span>
                            Wil je de geselecteerde beltaken weer als niet-afgehandeld markeren?
                          </span>
                        ),
                      })
                    ).type === EResultType.Annuleren
                  ) {
                    return;
                  }

                  await api.v2.beltaak.herstellenMarkerenAfgehandeld(params);

                  ophalenBeltaken();
                }}
              >
                <IconHerstel style={{ width: 16, height: 16, fill: EKleur.Grijs }} />
                <span className="ml-2">Ongedaan maken Afgehandeld</span>
              </button>

              <button
                className="btn btn-sm btn-light d-flex align-items-center justify-content-center ml-3"
                style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                disabled={urlState.selectie.length === 0}
                onClick={async () => {
                  const checkData = await api.v2.beltaak.checkVerwijderenBeltaken({
                    ids: urlState.selectie,
                  });

                  if (
                    (await checkStore.controleren({ checkData })).type === EResultType.Annuleren
                  ) {
                    return;
                  }

                  if (
                    (
                      await checkStore.bevestigen({
                        inhoud: (
                          <span>
                            Wil je de geselecteerde beltaken verwijderen?
                            <br />
                            <br />
                            Bij verwijderen zal de beltaak bij een volgende selectie hier weer
                            verschijnen.
                          </span>
                        ),
                      })
                    ).type === EResultType.Annuleren
                  ) {
                    return;
                  }
                  const result = await api.v2.beltaak.verwijderenBeltaken({
                    ids: urlState.selectie,
                  });

                  setUrlStateSync('selectie', []);

                  ophalenBeltaken();
                }}
              >
                <IconVerwijderen style={{ width: 16, height: 16, fill: EKleur.Grijs }} />
                <span className="ml-2">Verwijderen</span>
              </button>

              <div className="d-flex flex-fill ml-3">
                <FilterBalkV2
                  filters={filters}
                  filterData={urlState.filterdata}
                  onFilterDataChange={(x) => setUrlStateSync('filterdata', x)}
                  onFilterSchemaChange={setFilterSchema}
                />
              </div>
            </div>
            <div className="mt-3">
              <SelectieVak
                aantal={urlState.selectie.length}
                totaalAantal={beltaken.length}
                onChange={(alles) => {
                  if (alles) {
                    setUrlStateSync(
                      'selectie',
                      beltaken.map((x) => x.ID),
                    );
                  } else {
                    setUrlStateSync('selectie', []);
                  }
                }}
                entiteitEnkelvoud="beltaak"
                entiteitMeervoud="beltaken"
              />
            </div>
          </>
        }
        body={
          beltaken === null ? (
            <div className="flex-fill d-flex align-items-center justify-content-center">
              <LoadingSpinner />
            </div>
          ) : (
            <ASPTabel
              rijen={beltaken}
              kolommen={kolommen}
              keyExtractor={keyExtractor}
              selectie={urlState.selectie}
              onSelectieChange={(selectie) => setUrlStateSync('selectie', selectie)}
              sortering={urlState.sortering}
              onSorteringChange={(sortering) => setUrlStateSync('sortering', sortering)}
              lokaalSorteren
            />
          )
        }
      />
      {urlState.markerenAfgehandeldDialoogState !== null && (
        <MarkerenAfgehandeldDialoog
          ids={urlState.markerenAfgehandeldDialoogState.ids}
          open
          onSuccess={() => {
            setUrlStateSync('markerenAfgehandeldDialoogState', null);
            setUrlStateSync('selectie', []);
            ophalenBeltaken();
          }}
          onAnnuleren={() => {
            setUrlStateSync('markerenAfgehandeldDialoogState', null);
          }}
        />
      )}
    </>
  );
};

export default withRouter(BeltakenWrapper);
