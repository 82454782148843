import { useCallback, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

interface IUseBezigOutput {
  isBezig: boolean;
  // Alles wat plaats vindt binnen de gegeven functie wordt als bezig beschouwd.
  scopeBezig: (fn: () => PromiseLike<void>) => Promise<void>;
}

interface IScopeRegistratie {
  [id: string]: boolean;
}

/// Deze hook houdt bij of er nog asynchrone acties bezig zijn.
const useBezig = (): IUseBezigOutput => {
  const [scopes, setScopes] = useState<IScopeRegistratie>({});
  const isBezig = useMemo(() => Object.values(scopes).some((x) => x), [scopes]);

  const scopeBezig = useCallback<IUseBezigOutput['scopeBezig']>(async (fn) => {
    const id = uuidv4();
    setScopes((prev) => ({ ...prev, [id]: true }));
    try {
      await fn();
    } finally {
      setScopes((prev) => {
        const { [id]: _, ...rest } = prev;
        return rest;
      });
    }
  }, []);

  return {
    isBezig,
    scopeBezig,
  };
};

export default useBezig;
