import * as React from 'react';
import Modal from 'react-bootstrap/Modal';
import ModalFooter from 'react-bootstrap/ModalFooter';
import ModalBody from 'react-bootstrap/ModalBody';
import { useMemo } from 'react';
import IDialoogProps from '../../../core/IDialoogProps';
import LoadingSpinner from '../../Gedeeld/LoadingSpinner';
import classNames from 'classnames';
import Spinner from 'react-bootstrap/Spinner';
import ModalHeader from 'react-bootstrap/esm/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';

export interface IOkAnnulerenDialoogProps extends IDialoogProps<null> {
  title?: string | JSX.Element;
  body?: string | JSX.Element;
  okLoading?: boolean;
  okTekst?: string;
  annulerenTekst?: string;
}

const defaultProps: Partial<IOkAnnulerenDialoogProps> = {
  body: 'Weet je het zeker?',
};

const OkAnnulerenDialoog: React.FC<IOkAnnulerenDialoogProps> = (props) => {
  const p = useMemo(() => ({ ...defaultProps, ...props }), [props]);

  return (
    <Modal show={p.open}>
      {p.title && (
        <ModalHeader>
          <ModalTitle>{p.title}</ModalTitle>
        </ModalHeader>
      )}

      <ModalBody style={{ padding: '2rem', fontSize: 15 }}>{p.body}</ModalBody>
      <ModalFooter className="d-flex flex-row justify-content-start">
        <button
          className="btn btn-primary d-flex align-items-center justify-content-center"
          onClick={() => p.onSuccess(null)}
          style={{ width: 100 }}
          disabled={p.okLoading}
        >
          {p.okLoading && (
            <span className="mr-2">
              <LoadingSpinner soort="light" grootte="15px" dikte="2px" />
            </span>
          )}
          {props.okTekst ?? 'Ok'}
        </button>
        <button
          className="btn btn-secondary"
          onClick={p.onAnnuleren}
          style={{ width: 100 }}
          disabled={p.okLoading}
        >
          {props.annulerenTekst ?? 'Annuleren'}
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default OkAnnulerenDialoog;
