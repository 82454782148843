import React, { useMemo } from 'react';

export interface IMultilineConfig {
  rows?: number;
}

export interface IInputComponentProps {
  value: string;
  onChange: (value: string) => void;
  style: React.CSSProperties;
  multilineConfig?: IMultilineConfig;
}

interface IProps {
  waarde: string;
  onChange: (waarde: string) => void;
  onBlur?: () => void;
  inputComponent?: 'singleline' | 'multiline' | React.ComponentType<IInputComponentProps>;
  multilineConfig?: IMultilineConfig;
}

const SinglelineInputComponent = (props: IInputComponentProps) => {
  return (
    <input
      {...props}
      type="text"
      className="form-control"
      onChange={(ev) => props.onChange(ev.target.value)}
    />
  );
};

const MultilineInputComponent = (props: IInputComponentProps) => {
  return (
    <textarea
      {...props}
      className="form-control"
      onChange={(ev) => props.onChange(ev.target.value)}
      rows={props.multilineConfig?.rows ?? 3}
    />
  );
};

const TaalVeld: React.FC<IProps> = (props) => {
  const InputComp = useMemo<React.ComponentType<IInputComponentProps>>(() => {
    if (props.inputComponent === 'multiline') {
      return MultilineInputComponent;
    } else if (props.inputComponent !== undefined) {
      return props.inputComponent as React.ComponentType<IInputComponentProps>;
    }
    return SinglelineInputComponent;
  }, [props.inputComponent]);

  return (
    <InputComp
      value={props.waarde}
      onChange={props.onChange}
      style={{ paddingLeft: 30 }}
      multilineConfig={props.multilineConfig}
    />
  );
};

export default TaalVeld;
