import React, { useCallback, useMemo, useRef, useState } from 'react';
import {
  BeginWithSearchingMessageContainer,
  Input,
  InputWrapper,
  LoadingContainer,
  PhonenumberInputContainer,
  Root,
  SuggestionListItem,
  SuggestionsContainer,
} from './style';
import LoadingSpinner from '../../../../../Gedeeld/LoadingSpinner';
import api from '../../../../../../api';
import { formatteerPersoonNaam } from '../../../../../../helpers';
import { IContact, INewContact } from '../../../index';

// tslint:disable-next-line:no-var-requires
import { v4 as uuid } from 'uuid';
import { parseTelefoonNummer } from '../../../../../../bedrijfslogica/communicatie/telefoon';

interface IProps {
  defaultInputValue: string | null;
  onNewContact: (contact: INewContact) => void;
}

interface ISuggestion {
  displayName: string;
  phoneNumber: string;
  persID: number;
}

const OpstellenFormulier: React.FC<IProps> = (props) => {
  const suggestionsFetchTimeout = useRef<number | null>(null);

  const [inputValue, setInputValue] = useState(props.defaultInputValue || '');
  const [suggestions, setSuggestions] = useState<ISuggestion[] | null>(null);

  const handleInputChange = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = ev.target.value;
      setInputValue(newValue);
      setSuggestions(null);

      if (suggestionsFetchTimeout.current !== null) {
        clearTimeout(suggestionsFetchTimeout.current);
      }
      // @ts-ignore
      suggestionsFetchTimeout.current = setTimeout(async () => {
        const result = await api.v2.dienst.sms.ophalenRijbronVoorSmsOpstellen({
          zoekterm: newValue,
        });
        setSuggestions(
          result.suggesties.map((suggestie) => {
            return {
              phoneNumber: suggestie.TelefoonMobiel!,
              persID: suggestie.PersID,
              displayName: formatteerPersoonNaam({
                voorletters: suggestie.Voorletters,
                aanhefKort: suggestie.AanhefKort,
                achternaam: suggestie.Achternaam,
                voornaam: suggestie.Voornaam,
                voorvoegsel: suggestie.Voorvoegsel,
              }),
            };
          }),
        );
      }, 300);
    },
    [setInputValue, suggestionsFetchTimeout.current, setSuggestions],
  );

  const showBeginWithSearchingMessage = useMemo(() => inputValue.trim() === '', [inputValue]);
  const inputIsPhoneNumber = useMemo(() => {
    const nummer = parseTelefoonNummer(inputValue);
    return nummer !== null;
    // return inputValue.match(telefoonNrRegex) !== null;
  }, [inputValue]);

  const handleSuggestionClick = useCallback(
    (suggestion: ISuggestion) => {
      const contact: INewContact = {
        tempId: uuid(),
        phoneNumber: suggestion.phoneNumber,
        persID: suggestion.persID,
      };
      props.onNewContact(contact);
      document.body.click();
    },
    [props.onNewContact],
  );

  const handleOpvoerenClick = useCallback(() => {
    const contact: INewContact = {
      tempId: uuid(),
      phoneNumber: inputValue,
      persID: null,
    };
    props.onNewContact(contact);
    document.body.click();
  }, [inputValue]);

  return (
    <Root>
      <InputWrapper>
        <Input
          type="text"
          placeholder="Zoeken of telefoonnummer opvoeren..."
          value={inputValue}
          onChange={handleInputChange}
        />
      </InputWrapper>
      {showBeginWithSearchingMessage ? (
        <BeginWithSearchingMessageContainer>
          Voer een zoekterm of telefoonnummer in om te starten.
        </BeginWithSearchingMessageContainer>
      ) : inputIsPhoneNumber && suggestions !== null && suggestions.length === 0 ? (
        <PhonenumberInputContainer>
          <button className="btn btn-sm btn-primary" onClick={handleOpvoerenClick}>
            <span className="font-weight-bold">{inputValue}</span>&nbsp;opvoeren
          </button>
        </PhonenumberInputContainer>
      ) : suggestions === null ? (
        <LoadingContainer>
          <LoadingSpinner />
        </LoadingContainer>
      ) : (
        <SuggestionsContainer className="list-group list-group-flush">
          {suggestions.map((suggestion) => {
            return (
              <SuggestionListItem
                key={suggestion.persID}
                className="list-group-item list-group-item-action"
                onClick={(ev) => {
                  ev.stopPropagation();
                  handleSuggestionClick(suggestion);
                }}
              >
                <span className="display-name">{suggestion.displayName}</span>
                <span className="phone-number">{suggestion.phoneNumber}</span>
              </SuggestionListItem>
            );
          })}
        </SuggestionsContainer>
      )}
    </Root>
  );
};

export default OpstellenFormulier;
