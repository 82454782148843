import React, { useCallback, useMemo, useRef, useState } from 'react';
import { LeftPaneContainer, RightPaneContainer, Root } from './style';
import { IconToevoegen } from '../../../../Icons';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import OpstellenFormulier from './OpstellenFormulier';
import { INewContact, IOptions } from '../../index';

// tslint:disable-next-line:no-var-requires
import { v4 as uuid } from 'uuid';
const SEARCH_DELAY = 500;

interface IProps {
  onNewContact: (contact: INewContact) => void;
  onRequestFilter: (search: string | null) => void;
  options: IOptions;
}

const TopBarSection: React.FC<IProps> = (props) => {
  const opstellenVerbergen = useMemo(() => props.options.contacten!.topBar!.opstellenVerbergen, [
    props.options,
  ]);

  const searchTimeoutHandleRef = useRef<number>(null);
  const id = useMemo(() => uuid(), []);
  const popoverContent = (
    <Popover id={id}>
      <OpstellenFormulier defaultInputValue={null} onNewContact={props.onNewContact} />
    </Popover>
  );

  const [search, setSearch] = useState('');
  const handleSearchChange = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      const value = ev.target.value;
      setSearch(value);

      if (value.trim() === '') {
        props.onRequestFilter(null);
        return;
      }

      if (searchTimeoutHandleRef.current !== null) {
        clearTimeout(searchTimeoutHandleRef.current);
      }
      // @ts-ignore
      searchTimeoutHandleRef.current = setTimeout(
        () => props.onRequestFilter(value.trim()),
        SEARCH_DELAY,
      );
    },
    [setSearch, props.onRequestFilter],
  );

  return (
    <Root>
      {!opstellenVerbergen && (
        <LeftPaneContainer>
          <OverlayTrigger
            overlay={popoverContent}
            rootClose
            trigger="click"
            placement="bottom-start"
          >
            <button className="btn btn-sm btn-primary d-flex align-items-center justify-content-center">
              <IconToevoegen style={{ fill: 'white', width: 16 }} />
              &nbsp; Opstellen
            </button>
          </OverlayTrigger>
        </LeftPaneContainer>
      )}
      <RightPaneContainer opstellenVerborgen={Boolean(opstellenVerbergen)}>
        <input
          className="form-control form-control-sm"
          placeholder="Zoeken"
          value={search}
          onChange={handleSearchChange}
        />
      </RightPaneContainer>
    </Root>
  );
};

export default TopBarSection;
