import React, { useCallback, useContext, useEffect, useMemo, useState, useRef } from 'react';
import IDialoogProps from '../../../../../../../../../core/IDialoogProps';
import Dialoog from '../../../../../../../../../components/dialogen/Dialoog';
import ModalFooter from 'react-bootstrap/ModalFooter';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import * as Yup from 'yup';
import nameof from '../../../../../../../../../core/nameOf';
import MultiCombobox, {
  IKolom,
} from '../../../../../../../../../components/formulier/MultiCombobox';
import api from '../../../../../../../../../api';
import { IOphalenMeldingenResultElement } from '../../../../../../../../../../../shared/src/api/v2/service/melding';
import {
  IOphalenServicedienstenResultElement,
  IToevoegenOpdrachtParams,
} from '../../../../../../../../../../../shared/src/api/v2/service/opdracht';
import LoadingSpinner from '../../../../../../../../../components/Gedeeld/LoadingSpinner';
import VinkVeld from '../../../../../../../../../components/formulier/VinkVeld';
import FormikVeldFout from '../../../../../../../../../components/formulier/FormikVeldFout';
import ContactSelectie, {
  EType,
} from '../../../../../../../../../components/formulier/ContactSelectie';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../../../../../../../stores/RootStore';
import { EResultType } from '../../../../../../../../../stores/CheckStore';
import { Kleur } from '../../../../../../../../../bedrijfslogica/constanten';
import { maandenNaarJaren } from '../../../../../../../../../bedrijfslogica/teksten';
import { differenceInMonths } from 'date-fns';
import { size } from 'lodash';
import {
  IOphalenFacturenResultElement,
  IOphalenFacturenXProductenResultElement,
} from '../../../../../../../../../../../shared/src/api/v2/inkoopfactuur';
import TelefoonnummerInput from '../../../../../../../../../components/formulier/TelefoonnummerInput';
import { EOpdrachtwijze } from '../../../../../../../../../bedrijfslogica/enums';

interface IProps extends IDialoogProps<null> {
  servMeldID: number;
  relID?: number;
  cntID: number | null;
}

interface IFormikValues {
  omschrijving: string;
  melder_PersID: number | null;
  email: string;
  telefoonNummer: string;
  servDienstID: number | null;
  opdrachtVersturen: boolean;
  garantie: boolean;
  spoed: boolean;
  meerDiensten: boolean;
  berichtNaarRelatie: boolean;
  bezoekinstructies: string | null;
  inkoopfactuurNietMeesturen: boolean;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  email: 'Email',
  melder_PersID: 'Melder',
  omschrijving: 'Omschrijving',
  telefoonNummer: 'Telefoonnummer',
  servDienstID: 'Dienst',
  opdrachtVersturen: 'Direct opdracht versturen',
  garantie: 'Garantie',
  spoed: 'Spoed',
  meerDiensten: 'Meer diensten weergeven',
  berichtNaarRelatie: 'Bericht naar klant',
  bezoekinstructies: 'Bezoekinstructies',
  inkoopfactuurNietMeesturen: 'Inkoopfactuur niet meesturen',
};

interface IFactuur extends IOphalenFacturenResultElement {
  BedragGekoppeld: number;
}

const NieuwOpdrachtDialoog: React.FC<IProps> = observer((props) => {
  const { checkStore } = useContext(RootStoreContext);
  const { dialoogIndex, onAnnuleren, onSuccess, open, relID } = props;

  const formikRef = useRef<Formik<IFormikValues>>(null);

  const [melding, setMelding] = useState<IOphalenMeldingenResultElement | null>(null);
  const [meerDiensten, setMeerDiensten] = useState<boolean>(false);
  const [servicediensten, setServicediensten] = useState<
    IOphalenServicedienstenResultElement[] | []
  >([]);
  const [garantie, setGarantie] = useState<boolean | null>(null);
  const [inkoopfactuurVoorProduct, setInkoopfactuurVoorProduct] = useState<
    IFactuur | null | undefined
  >(undefined);

  const [hasSubmitted, setHasSubmitted] = useState(false);

  const productInfo = useMemo(() => {
    return melding !== null
      ? melding.product !== null
        ? melding.product.producttype.Productsoortnaam +
          ' ' +
          melding.product.producttype.Merknaam +
          ' ' +
          melding.product.producttype.Typenaam +
          (melding.product.Referentiecode !== null
            ? ', ref. ' + melding.product.Referentiecode
            : '') +
          ' (lft. ' +
          (melding.product.leeftijd !== null ? maandenNaarJaren(melding.product.leeftijd) : '') +
          ')'
        : ''
      : '';
  }, [melding]);

  // const productInfo =
  //   melding !== null
  //     ? melding.product !== null
  //       ? melding.product.producttype.Productsoortnaam +
  //         ' ' +
  //         melding.product.producttype.Merknaam +
  //         ' ' +
  //         melding.product.producttype.Typenaam +
  //         (melding.product.Referentiecode !== null
  //           ? ', ref. ' + melding.product.Referentiecode
  //           : '') +
  //         ' (lft. ' +
  //         (melding.product.leeftijd !== null ? maandenNaarJaren(melding.product.leeftijd) : '') +
  //         ')'
  //       : ''
  //     : '';

  useEffect(() => {
    (async () => {
      if (melding === null || garantie === null) {
        return;
      }

      const merkID = melding.product !== null ? melding.product.producttype.MerkID : null;

      const result = await api.v2.service.ophalenServicediensten({
        garantie: garantie,
        merkID,
        locID: melding.locatie.LocID,
        overigeDienstenWeergeven: meerDiensten,
      });
      const diensten = result.diensten;
      setServicediensten(diensten);

      // Voorvullen van de rijbron van servicediensten met de 1e rij daarvan
      if (diensten.length === 0) {
        return;
      }
      formikRef.current!.setValues({
        ...formikRef.current!.getFormikBag().values,
        servDienstID: diensten[0].ID,
      });
    })();
  }, [melding, garantie, meerDiensten]);

  useEffect(() => {
    (async () => {
      const result = await api.v2.service.ophalenMeldingen({
        filterSchema: { filters: [{ naam: 'IDS', data: [props.servMeldID] }] },
      });

      setMelding(result.meldingen[0]);
    })();
  }, [props.servMeldID]);

  useEffect(() => {
    (async () => {
      if (melding === null) {
        return;
      }

      if (melding.product === null) {
        return setInkoopfactuurVoorProduct(null);
      }

      // Inkoopfacturen die aan het product gekoppeld zijn
      // Daarvan degene met een positief bedrag, dat is de factuur waarmee dit product ingekocht is
      const factuurVoorProductResult =
        (
          await api.v2.inkoopfactuur.ophalenFacturenXProducten({
            filterSchema: { filters: [{ naam: 'PROD_IDS', data: [melding.product.ProdID] }] },
          })
        ).data.filter((x) => x.Bedrag > 0)[0] ?? null;

      if (factuurVoorProductResult === null) {
        setInkoopfactuurVoorProduct(null);
        return;
      }

      const factuurResult = (
        await api.v2.inkoopfactuur.ophalenFacturen({
          filterSchema: { filters: [{ naam: 'IDS', data: [factuurVoorProductResult.InkFactID] }] },
        })
      ).facturen[0];

      const result = { ...factuurResult, BedragGekoppeld: factuurVoorProductResult.Bedrag };

      setInkoopfactuurVoorProduct(result);
    })();
  }, [melding]);

  // useEffect(() => {
  //   (async () => {
  //     const result = await api.v2.service.ophalenProducten({
  //       relID,
  //       cntID,
  //       locID: 1, // TODO hard
  //     });
  //     setProducten(result);
  //   })();
  // }, [relID, cntID]);

  useEffect(() => {
    (async () => {
      if (melding === null || inkoopfactuurVoorProduct === undefined) {
        return;
      }

      // const factuurGarantiedatum =
      //   melding !== null && melding.product !== null
      //     ? (melding.product.inkoopfacturen.length !== 0
      //         ? melding.product.inkoopfacturen[0].Factuurdatum
      //         : null) ||
      //       melding.product.Productdatum ||
      //       melding.product.DatumInBedrijf
      //     : null;

      const factuurGarantiedatum =
        inkoopfactuurVoorProduct !== null
          ? inkoopfactuurVoorProduct.Factuurdatum
          : melding.product !== null
          ? melding.product.Productdatum || melding.product.DatumInBedrijf
          : null;

      const garantie =
        factuurGarantiedatum === null
          ? false
          : differenceInMonths(new Date(), new Date(factuurGarantiedatum)) <= 24;

      setGarantie(garantie);
    })();
  }, [melding, inkoopfactuurVoorProduct]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      const paramsToevoegenOpdracht: IToevoegenOpdrachtParams = {
        servMeldID: props.servMeldID,
        servDienstID: values.servDienstID,
        datumVerstuurd: null,
        omschrijving: values.omschrijving,
        garantieclaim: garantie!,
        spoed: values.spoed,
        persID: values.melder_PersID,
        email: values.email !== '' ? values.email : null,
        telefoonnummer: values.telefoonNummer !== '' ? values.telefoonNummer : null,
        bezoekinstructies: values.bezoekinstructies,
      };

      const checkData = await api.v2.service.checkToevoegenOpdracht(paramsToevoegenOpdracht);
      if (
        (
          await checkStore.controleren({
            checkData,
          })
        ).type === EResultType.Annuleren
      ) {
        return;
      }

      const result = await api.v2.service.toevoegenOpdracht(paramsToevoegenOpdracht);

      // Alleen versturen als de contactpersoon op gegeven is.
      if (values.melder_PersID !== null) {
        if (values.opdrachtVersturen) {
          await api.v2.service.versturenServiceOpdrachten({
            ids: [result.ID],
            inkoopfactuurBijOpdracht: values.inkoopfactuurNietMeesturen ? false : undefined,
          });

          if (values.berichtNaarRelatie) {
            await api.v2.service.versturenBevestigingenServiceOpdrachtNaarRelatie({
              ids: [result.ID],
            });
          }
        }
      }

      // Als de opdracht in een portal ingevoerd moet worden dan daarvoor bevestiging vragen en erheen gaan
      if (values.opdrachtVersturen) {
        const servicedienst = (
          await api.v2.dienst.service.ophalenDiensten({
            filterSchema: { filters: [{ naam: 'IDS', data: [values.servDienstID] }] },
          })
        ).diensten[0];

        if (
          servicedienst.opdrachtwijze!.NaamEnum === EOpdrachtwijze.Portal &&
          servicedienst.PortalURL !== null
        ) {
          if (
            (
              await checkStore.bevestigen({
                inhoud: (
                  <span>
                    Voor de servicedienst {servicedienst.NaamIdent} moet de opdracht in een Portal
                    ingevoerd worden, wil je daar nu heengaan?
                    <br />
                    <br />
                    Let op: het kan zijn dat je nog moet inloggen.
                  </span>
                ),
              })
            ).type === EResultType.Annuleren
          ) {
            return;
          }
          const url = servicedienst.PortalURL;
          window.open(url, '_blank');
        }
      }

      onSuccess(null);
      actions.setSubmitting(false);
    },
    [onSuccess, garantie],
  );

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (melding === null || inkoopfactuurVoorProduct === undefined) {
      return null;
    }

    const factuurGarantiedatum =
      inkoopfactuurVoorProduct !== null
        ? inkoopfactuurVoorProduct.Factuurdatum
        : melding.product !== null
        ? melding.product.Productdatum || melding.product.DatumInBedrijf
        : null;

    // melding !== null && melding.product !== null
    //   ? (melding.product.inkoopfacturen.length !== 0
    //       ? melding.product.inkoopfacturen[0].Factuurdatum
    //       : null) ||
    //     melding.product.Productdatum ||
    //     melding.product.DatumInBedrijf
    //   : null;

    // const factuurGarantiedatum =
    //   melding !== null && melding.product !== null
    //     ? (melding.product.inkoopfacturen.length !== 0
    //         ? melding.product.inkoopfacturen[0].Factuurdatum
    //         : null) ||
    //       melding.product.Productdatum ||
    //       melding.product.DatumInBedrijf
    //     : null;

    const garantie =
      factuurGarantiedatum === null
        ? false
        : differenceInMonths(new Date(), new Date(factuurGarantiedatum)) <= 24;

    return {
      telefoonNummer: melding.Telefoon ?? '',
      garantie,
      spoed: false,
      omschrijving: melding !== null ? melding.Omschrijving : '',
      melder_PersID: melding !== null && melding.persoon !== null ? melding.persoon.PersID : null,
      email: melding.Email ?? '',
      servDienstID: null,
      opdrachtVersturen: true,
      meerDiensten: false,
      berichtNaarRelatie: true,
      bezoekinstructies: melding.locatie.Bezoekinstructies,
      inkoopfactuurNietMeesturen: false,
    };
  }, [melding, inkoopfactuurVoorProduct]);

  // useEffect(() => {
  //   // Automatisch voorvullen van melder gegevens
  //   if (melding === null || melding.persoon === null) {
  //     return;
  //   }
  //   handleTelefoonnummerEnEmailVoorvullen(
  //     melding.persoon.PersID,
  //     formikRef.current!.getFormikBag(),
  //   );
  // }, [melding]);

  const validationSchema = useMemo(() => {
    const fields: Record<keyof IFormikValues, any> = {
      email: Yup.string().email(),
      melder_PersID: Yup.number(),
      omschrijving: Yup.string(),
      telefoonNummer: Yup.string().required(),
      servDienstID: Yup.number(),
      opdrachtVersturen: Yup.boolean(),
      garantie: Yup.boolean,
      spoed: Yup.boolean(),
      meerDiensten: Yup.boolean(),
      berichtNaarRelatie: Yup.boolean,
      bezoekinstructies: Yup.string(),
      inkoopfactuurNietMeesturen: Yup.boolean(),
    };
    return Yup.object().shape(fields);
  }, []);

  const servicedienstenKolommen = useMemo<IKolom<IOphalenServicedienstenResultElement>[]>(
    () => [
      {
        key: 'NaamIdent',
        label: 'Dienst',
        breedte: 200,
      },
      {
        key: 'criterium',
        label: 'Criterium',
        breedte: 200,
      },
      // {
      //   key: 'NaamEnum',
      //   label: 'Enum',
      //   formatFabriek: (row) => {
      //     return <span>{row.relatie!.weergavenaam}</span>;
      //   },
      //   breedte: 250,
      // },
    ],
    [],
  );

  const handleTelefoonnummerEnEmailVoorvullen = useCallback(
    async (persID: number | null, form: FormikProps<IFormikValues>) => {
      if (persID === null) {
        return;
      }

      const personenResult = await api.v2.persoon.ophalenPersonen({
        filterSchema: { filters: [{ naam: 'IDS', data: [persID] }] },
      });
      const result = personenResult.personen[0];

      form.setFieldValue(nameof<IFormikValues>('telefoonNummer'), result.TelefoonMobiel || '');
      form.setFieldValue(nameof<IFormikValues>('email'), result.Email || '');
    },
    [],
  );

  return (
    <Dialoog index={dialoogIndex || 0} modalProps={{ size: 'lg' }}>
      <ModalHeader>
        <ModalTitle>
          Reparatieopdracht voor melding {melding !== null ? 'SM-' + melding.Meldnummer : ''}
        </ModalTitle>
      </ModalHeader>
      {initialValues === null ? (
        <div className="d-flex flex-fill align-items-center justify-content-center">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          <Formik<IFormikValues>
            ref={formikRef}
            isInitialValid
            onSubmit={handleSubmit}
            initialValues={initialValues}
            enableReinitialize
            validationSchema={validationSchema}
            render={(formikProps: FormikProps<IFormikValues>) => {
              const { submitForm, isSubmitting, isValid, values } = formikProps;

              return (
                <>
                  <ModalBody>
                    <div className="form-group">
                      <div className="row">
                        <div className="col-12">
                          <div
                            className="p-2"
                            style={{
                              backgroundColor: Kleur.HeelLichtGrijs,
                              borderRadius: 3,
                            }}
                          >
                            {productInfo}
                          </div>
                        </div>

                        <div className="col-12 mt-3 d-flex align-items-center">
                          <Field
                            name="garantie"
                            render={(fieldProps: FieldProps<IFormikValues>) => (
                              <>
                                <VinkVeld
                                  aangevinkt={fieldProps.field.value}
                                  onGewijzigd={async (x) => {
                                    fieldProps.form.setFieldValue(fieldProps.field.name, x);
                                    setGarantie(x);
                                  }}
                                />
                                <FormikVeldFout
                                  fieldProps={fieldProps}
                                  directTonen={hasSubmitted}
                                />
                              </>
                            )}
                          />
                          <span className="ml-2">{veldnamen.garantie}</span>
                        </div>

                        <div className="col-12 mt-2">
                          <div className="d-flex justify-content-between align-items-center">
                            <label>{veldnamen.servDienstID}</label>
                            <div className="d-flex">
                              <Field
                                name={veldnamen.meerDiensten}
                                render={(fieldProps: FieldProps<IFormikValues>) => (
                                  <>
                                    <VinkVeld
                                      aangevinkt={fieldProps.field.value}
                                      onGewijzigd={async (x) => {
                                        fieldProps.form.setFieldValue(fieldProps.field.name, x);
                                        setMeerDiensten(x);
                                      }}
                                    />
                                    <div className="ml-2">{veldnamen.meerDiensten}</div>
                                    <FormikVeldFout
                                      fieldProps={fieldProps}
                                      directTonen={hasSubmitted}
                                    />
                                  </>
                                )}
                              />
                            </div>
                          </div>
                          <Field
                            name={nameof<IFormikValues>('servDienstID')}
                            render={(fieldProps: FieldProps<IFormikValues>) => {
                              const { field, form } = fieldProps;

                              if (servicediensten === null) {
                                return <LoadingSpinner />;
                              }
                              return (
                                <MultiCombobox<number, IOphalenServicedienstenResultElement>
                                  sleutelExtractor={(x) => x.ID}
                                  representatieFabriek={(x) => `${x.relatie!.weergavenaam}`}
                                  waarde={field.value}
                                  onWaardeChange={(x) => form.setFieldValue(field.name, x)}
                                  opties={servicediensten}
                                  kolommen={servicedienstenKolommen}
                                />
                              );
                            }}
                          />
                        </div>

                        <div className="col-12 mt-3">
                          <label>{veldnamen.omschrijving}</label>
                          <Field
                            name={nameof<IFormikValues>('omschrijving')}
                            render={(fieldProps: FieldProps<IFormikValues>) => {
                              const { field, form } = fieldProps;

                              return <textarea {...field} className="form-control" rows={4} />;
                            }}
                          />
                        </div>

                        <div className="col-12 mt-2">
                          <label>{veldnamen.melder_PersID}</label>
                          <Field
                            name={nameof<IFormikValues>('melder_PersID')}
                            render={(fieldProps: FieldProps<IFormikValues>) => {
                              const { field, form } = fieldProps;

                              return (
                                <ContactSelectie
                                  alleenVoorRelIDs={relID !== undefined ? [relID] : undefined}
                                  value={
                                    field.value === null
                                      ? null
                                      : {
                                          persID: field.value,
                                          orgID: null,
                                        }
                                  }
                                  onChange={async (x) => {
                                    const persID = x?.persoon?.PersID ?? null;
                                    form.setFieldValue(field.name, persID);

                                    await handleTelefoonnummerEnEmailVoorvullen(persID, form);
                                  }}
                                  options={{
                                    types: [EType.Persoon],
                                  }}
                                />
                              );
                            }}
                          />
                        </div>

                        <div className="col-12 mt-2">
                          <label>{veldnamen.telefoonNummer}</label>
                          <Field
                            name={nameof<IFormikValues>('telefoonNummer')}
                            render={(fieldProps: FieldProps<IFormikValues>) => {
                              const { field, form } = fieldProps;

                              return field.value === null ? (
                                <LoadingSpinner />
                              ) : (
                                <TelefoonnummerInput
                                  telefoonnummer={field.value}
                                  onChange={(x) => form.setFieldValue(field.name, x)}
                                  // suggestiesProvider={
                                  //   telefoonnummerInputSuggestiesProvider
                                  // }
                                />
                              );
                            }}
                          />
                        </div>

                        <div className="col-12 mt-3">
                          <label>{veldnamen.email}</label>
                          <Field
                            name={nameof<IFormikValues>('email')}
                            render={(fieldProps: FieldProps<IFormikValues>) => {
                              const { field, form } = fieldProps;

                              return <input {...field} className="form-control" type="text" />;
                            }}
                          />
                        </div>

                        <div className="col-12 mt-3">
                          <label>{veldnamen.bezoekinstructies}</label>
                          <Field
                            name={nameof<IFormikValues>('bezoekinstructies')}
                            render={(fieldProps: FieldProps<IFormikValues>) => {
                              const { field, form } = fieldProps;

                              return <input {...field} className="form-control" type="text" />;
                            }}
                          />
                        </div>

                        <div className="col-12 d-flex align-items-center mt-3">
                          <Field
                            name={nameof<IFormikValues>('spoed')}
                            render={(fieldProps: FieldProps<IFormikValues>) => (
                              <>
                                <VinkVeld
                                  aangevinkt={fieldProps.field.value}
                                  onGewijzigd={(x) =>
                                    fieldProps.form.setFieldValue(fieldProps.field.name, x)
                                  }
                                />
                                <FormikVeldFout
                                  fieldProps={fieldProps}
                                  directTonen={hasSubmitted}
                                />
                              </>
                            )}
                          />
                          <span className="ml-2">{veldnamen.spoed}</span>
                        </div>

                        <div className="col-12 mt-3">
                          <div className="row">
                            <div className="col-4 d-flex align-items-center">
                              <Field
                                name="opdrachtVersturen"
                                render={(fieldProps: FieldProps<IFormikValues>) => (
                                  <>
                                    <VinkVeld
                                      aangevinkt={fieldProps.field.value}
                                      onGewijzigd={(x) =>
                                        fieldProps.form.setFieldValue(fieldProps.field.name, x)
                                      }
                                      disabled={values.melder_PersID === null}
                                    />
                                    <FormikVeldFout
                                      fieldProps={fieldProps}
                                      directTonen={hasSubmitted}
                                    />
                                  </>
                                )}
                              />
                              <span className="ml-2">{veldnamen.opdrachtVersturen}</span>
                            </div>
                            {values.opdrachtVersturen && (
                              <div className="col-4 d-flex align-items-center">
                                <Field
                                  name={nameof<IFormikValues>('berichtNaarRelatie')}
                                  render={(fieldProps: FieldProps<IFormikValues>) => (
                                    <>
                                      <VinkVeld
                                        aangevinkt={fieldProps.field.value}
                                        onGewijzigd={(x) =>
                                          fieldProps.form.setFieldValue(fieldProps.field.name, x)
                                        }
                                        disabled={values.melder_PersID === null}
                                      />
                                      <FormikVeldFout
                                        fieldProps={fieldProps}
                                        directTonen={hasSubmitted}
                                      />
                                    </>
                                  )}
                                />
                                <span className="ml-2">{veldnamen.berichtNaarRelatie}</span>
                              </div>
                            )}
                            <div className="col-4 d-flex align-items-center">
                              <Field
                                name={nameof<IFormikValues>('inkoopfactuurNietMeesturen')}
                                render={(fieldProps: FieldProps<IFormikValues>) => (
                                  <>
                                    <VinkVeld
                                      aangevinkt={fieldProps.field.value}
                                      onGewijzigd={(x) =>
                                        fieldProps.form.setFieldValue(fieldProps.field.name, x)
                                      }
                                    />
                                    <FormikVeldFout
                                      fieldProps={fieldProps}
                                      directTonen={hasSubmitted}
                                    />
                                  </>
                                )}
                              />
                              <span className="ml-2">{veldnamen.inkoopfactuurNietMeesturen}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ModalBody>
                  <ModalFooter className="d-flex flex-row justify-content-start">
                    <button
                      className="btn btn-primary"
                      onClick={submitForm}
                      style={{ width: 100 }}
                      disabled={isSubmitting || !isValid}
                    >
                      Ok
                    </button>
                    <button
                      className="btn btn-secondary"
                      onClick={onAnnuleren}
                      style={{ width: 100 }}
                    >
                      Annuleren
                    </button>
                  </ModalFooter>
                </>
              );
            }}
          />
        </>
      )}
    </Dialoog>
  );
});

export default NieuwOpdrachtDialoog;
