import React from 'react';
import VinkVeld from '../formulier/VinkVeld';
import SelectieLabel from '../weergave/SelectieLabel';

interface IProps {
  aantal: number | null;
  totaalAantal: number | null;
  onChange: (allesGeselecteerd: boolean) => void;
  entiteitEnkelvoud?: string;
  entiteitMeervoud?: string;
  disabled?: boolean;
}

const SelectieVak: React.FC<IProps> = (props) => {
  const { aantal, totaalAantal, entiteitEnkelvoud, entiteitMeervoud, onChange } = props;
  return (
    <div className="d-flex">
      <span>
        <VinkVeld
          aangevinkt={aantal === 0 ? false : aantal === totaalAantal ? true : 'onbestemd'}
          onGewijzigd={() => {
            if (aantal === totaalAantal) {
              onChange(false);
            } else {
              onChange(true);
            }
          }}
          disabled={props.disabled}
        />
      </span>

      <span className="ml-2" style={{ marginTop: 1 }}>
        <SelectieLabel
          aantal={aantal}
          totaalAantal={totaalAantal}
          options={{
            entiteitEnkelvoud,
            entiteitMeervoud,
          }}
        />
      </span>
    </div>
  );
};

export default SelectieVak;
