import api from '../index';
import {
  IPrepareerUploadParams,
  PrepareerUploadResult,
  IVerifieerUploadParams,
  VerifieerUploadResult,
  IOphalenBestandenParams,
  IOphalenBestandenResult,
  IOphalenBestandenResultElement,
  IOphalenBestandenEnMappenParams,
  IOphalenBestandenEnMappenResult,
  IOphalenBestandsmappenParams,
  IOphalenBestandsmappenResult,
  IOphalenBestandsmappenboomParams,
  IOphalenBestandsmappenboomResult,
  IVerwijderenBestandenResult,
  IVerwijderenBestandenParams,
  IVerwijderenBestandsmappenResult,
  IVerwijderenBestandsmappenParams,
  IHernoemenBestandParams,
  IHernoemenBestandResult,
  IHernoemenBestandsmapParams,
  IHernoemenBestandsmapResult,
  IOphalenOpslagdienstenParams,
  IOphalenOpslagdienstenResult,
  IToevoegenBestandsmapResult,
  IToevoegenBestandsmapParams,
  IBepalenPositieBinnenBestandenEnMappenVoorBestandsmapParams,
  IBepalenPositieBinnenBestandenEnMappenVoorBestandsmapResult,
  IVerplaatsBestandNaarBestandsmapParams,
  IVerplaatsBestandNaarBestandsmapResult,
  IVerplaatsBestandsmapNaarBestandsmapParams,
  IVerplaatsBestandsmapNaarBestandsmapResult,
  IOphalenBovenliggendeBestandsmappenVoorBestandsmappenParams,
  IOphalenBovenliggendeBestandsmappenVoorBestandsmappenResult,
  IOphalenBovenliggendeBestandsmappenVoorBestandenParams,
  IOphalenBovenliggendeBestandsmappenVoorBestandenResult,
  IBepalenBestandsreferentiesResult,
  IBepalenBestandsreferentiesParams,
  IWijzigenKorteBeschrijvingParams,
  IWijzigenKorteBeschrijvingResult,
} from '../../../../shared/src/api/v2/bestand/bestand';
import ILokaalBestand from '../../models/ILokaalBestand';
import axios from 'axios';
import ICheckData from '../../../../shared/src/models/ICheckData';
import label from './bestand/label';
import md5 from 'md5';
import MD5 from 'crypto-js/md5';
import Base64Encoder from 'crypto-js/enc-base64';

const bestand = {
  label,
  upload: async (
    lokaalBestand: ILokaalBestand,
    onProgress?: (percentage: number) => void,
  ): Promise<IOphalenBestandenResultElement> => {
    const data = await new Promise<string>((res, rej) => {
      const fileReader = new FileReader();
      fileReader.onload = (ev) => {
        if (ev.target === null) {
          rej(new Error('FileReader result is null'));
          return;
        }
        res(ev.target.result as string);
      };
      fileReader.onerror = (ev) => {
        console.log(ev);
        rej(new Error('FileReader error'));
      };
      fileReader.readAsBinaryString(lokaalBestand.file);
    });
    const md5Data = MD5(data);
    const base64md5 = md5Data.toString(Base64Encoder);
    const hashMD5 = md5(data);

    const mediaTypeStr =
      lokaalBestand.mediaType === undefined ||
      lokaalBestand.mediaType === null ||
      lokaalBestand.mediaType.length === 0
        ? 'application/octet-stream'
        : lokaalBestand.mediaType;

    const mediaTypeOphalenOfToevoegenResult = await api.v2.mediaType.ophalenMediaTypeBijMediaTypeOfToevoegen(
      {
        mediaType: mediaTypeStr,
      },
    );
    const mediaType = mediaTypeOphalenOfToevoegenResult.mediaType;

    const prepareerUploadResult = await api.v2.bestand.prepareerUpload({
      bestandsnaam: lokaalBestand.naam,
      grootte: lokaalBestand.grootte,
      mediaTypeID: mediaType.ID,
      hashMD5,
      base64MD5: base64md5,
    });

    if (prepareerUploadResult.type === 'BESTAAND_BESTAND') {
      console.log('Bestand bestaat al, hergebruiken');
      return prepareerUploadResult.bestand;
    } else if (prepareerUploadResult.type === 'UPLOAD') {
      const axiosInstance = axios.create();
      const axiosFn =
        prepareerUploadResult.httpMethod === 'POST' ? axiosInstance.post : axiosInstance.put;
      await axiosFn(prepareerUploadResult.uploadUrl, lokaalBestand.file!, {
        headers: {
          ...prepareerUploadResult.uploadHeaders,
        },
        onUploadProgress:
          onProgress === undefined
            ? undefined
            : (progressEvent) => {
                // console.log(progressEvent);
                const percentage = (progressEvent.loaded * 100) / progressEvent.total;
                onProgress(isNaN(percentage) ? 100 : percentage);
              },
        // onDownloadProgress:
        //   onProgress === undefined
        //     ? undefined
        //     : (progressEvent) => {
        //         console.log(progressEvent);
        //         const value = 70 + (progressEvent.loaded * 30) / progressEvent.total;
        //         onProgress(value > 100 ? 100 : value);
        //       },
      });

      const verifieerUploadResult = await api.v2.bestand.verifieerUpload({
        geprepareerdUploadVerificatieID: prepareerUploadResult.geprepareerdUploadVerificatieID,
      });
      if (verifieerUploadResult.type === 'MISLUKT') {
        throw new Error('Upload mislukt');
      }

      return verifieerUploadResult.data!;
    }

    throw new Error(`Onbekend type: ${prepareerUploadResult}`);
  },
  prepareerUpload: async (params: IPrepareerUploadParams): Promise<PrepareerUploadResult> => {
    return await api.post('/v2/bestand-v2/prepareer-upload', params);
  },
  verifieerUpload: async (params: IVerifieerUploadParams): Promise<VerifieerUploadResult> => {
    return await api.post('/v2/bestand-v2/verifieer-upload', params);
  },
  ophalenBestanden: async (params: IOphalenBestandenParams): Promise<IOphalenBestandenResult> => {
    return await api.post('/v2/bestand-v2/ophalen-bestanden', params);
  },
  ophalenBestandsmappen: async (
    params: IOphalenBestandsmappenParams,
  ): Promise<IOphalenBestandsmappenResult> => {
    return await api.post('/v2/bestand-v2/ophalen-bestandsmappen', params);
  },
  ophalenBestandenEnMappen: async (
    params: IOphalenBestandenEnMappenParams,
  ): Promise<IOphalenBestandenEnMappenResult> => {
    return await api.post('/v2/bestand-v2/ophalen-bestanden-en-mappen', params);
  },
  ophalenBestandsmappenboom: async (
    params: IOphalenBestandsmappenboomParams,
  ): Promise<IOphalenBestandsmappenboomResult> => {
    return await api.post('/v2/bestand-v2/ophalen-bestandsmappenboom', params);
  },
  verwijderenBestanden: async (
    params: IVerwijderenBestandenParams,
  ): Promise<IVerwijderenBestandenResult> => {
    return await api.post('/v2/bestand-v2/verwijderen-bestanden', params);
  },
  checkVerwijderenBestanden: async (params: IVerwijderenBestandenParams): Promise<ICheckData> => {
    return await api.post('/v2/bestand-v2/check-verwijderen-bestanden', params);
  },
  verwijderenBestandsmappen: async (
    params: IVerwijderenBestandsmappenParams,
  ): Promise<IVerwijderenBestandsmappenResult> => {
    return await api.post('/v2/bestand-v2/verwijderen-bestandsmappen', params);
  },
  checkVerwijderenBestandsmappen: async (
    params: IVerwijderenBestandsmappenParams,
  ): Promise<ICheckData> => {
    return await api.post('/v2/bestand-v2/check-verwijderen-bestandsmappen', params);
  },
  hernoemenBestand: async (params: IHernoemenBestandParams): Promise<IHernoemenBestandResult> => {
    return await api.post('/v2/bestand-v2/hernoemen-bestand', params);
  },
  checkHernoemenBestand: async (params: IHernoemenBestandParams): Promise<ICheckData> => {
    return await api.post('/v2/bestand-v2/check-hernoemen-bestand', params);
  },
  hernoemenBestandsmap: async (
    params: IHernoemenBestandsmapParams,
  ): Promise<IHernoemenBestandsmapResult> => {
    return await api.post('/v2/bestand-v2/hernoemen-bestandsmap', params);
  },
  checkHernoemenBestandsmap: async (params: IHernoemenBestandsmapParams): Promise<ICheckData> => {
    return await api.post('/v2/bestand-v2/check-hernoemen-bestandsmap', params);
  },
  ophalenOpslagdiensten: async (
    params: IOphalenOpslagdienstenParams,
  ): Promise<IOphalenOpslagdienstenResult> => {
    return await api.post('/v2/bestand-v2/ophalen-opslagdiensten', params);
  },
  checkToevoegenBestandsmap: async (params: IToevoegenBestandsmapParams): Promise<ICheckData> => {
    return await api.post('/v2/bestand-v2/check-toevoegen-bestandsmap', params);
  },
  toevoegenBestandsmap: async (
    params: IToevoegenBestandsmapParams,
  ): Promise<IToevoegenBestandsmapResult> => {
    return await api.post('/v2/bestand-v2/toevoegen-bestandsmap', params);
  },
  bepalenPositieBinnenBestandenEnMappenVoorBestandsmap: async (
    params: IBepalenPositieBinnenBestandenEnMappenVoorBestandsmapParams,
  ): Promise<IBepalenPositieBinnenBestandenEnMappenVoorBestandsmapResult> => {
    return await api.post(
      '/v2/bestand-v2/bepalen-positie-binnen-bestanden-en-mappen-voor-bestandsmap',
      params,
    );
  },
  checkVerplaatsBestandNaarBestandsmap: async (
    params: IVerplaatsBestandNaarBestandsmapParams,
  ): Promise<ICheckData> => {
    return await api.post('/v2/bestand-v2/check-verplaats-bestand-naar-bestandsmap', params);
  },
  verplaatsBestandNaarBestandsmap: async (
    params: IVerplaatsBestandNaarBestandsmapParams,
  ): Promise<IVerplaatsBestandNaarBestandsmapResult> => {
    return await api.post('/v2/bestand-v2/verplaats-bestand-naar-bestandsmap', params);
  },
  checkVerplaatsBestandsmapNaarBestandsmap: async (
    params: IVerplaatsBestandsmapNaarBestandsmapParams,
  ): Promise<ICheckData> => {
    return await api.post('/v2/bestand-v2/check-verplaats-bestandsmap-naar-bestandsmap', params);
  },
  verplaatsBestandsmapNaarBestandsmap: async (
    params: IVerplaatsBestandsmapNaarBestandsmapParams,
  ): Promise<IVerplaatsBestandsmapNaarBestandsmapResult> => {
    return await api.post('/v2/bestand-v2/verplaats-bestandsmap-naar-bestandsmap', params);
  },
  ophalenBovenliggendeBestandsmappenVoorBestandsmappen: async (
    params: IOphalenBovenliggendeBestandsmappenVoorBestandsmappenParams,
  ): Promise<IOphalenBovenliggendeBestandsmappenVoorBestandsmappenResult> => {
    return await api.post(
      '/v2/bestand-v2/ophalen-bovenliggende-bestandsmappen-voor-bestandsmappen',
      params,
    );
  },
  ophalenBovenliggendeBestandsmappenVoorBestanden: async (
    params: IOphalenBovenliggendeBestandsmappenVoorBestandenParams,
  ): Promise<IOphalenBovenliggendeBestandsmappenVoorBestandenResult> => {
    return await api.post(
      '/v2/bestand-v2/ophalen-bovenliggende-bestandsmappen-voor-bestanden',
      params,
    );
  },
  bepalenBestandsreferenties: async (
    params: IBepalenBestandsreferentiesParams,
  ): Promise<IBepalenBestandsreferentiesResult> => {
    return await api.post('/v2/bestand-v2/bepalen-bestandsreferenties', params);
  },
  wijzigenKorteBeschrijving: async (
    params: IWijzigenKorteBeschrijvingParams,
  ): Promise<IWijzigenKorteBeschrijvingResult> => {
    return await api.post('/v2/bestand-v2/wijzigen-korte-beschrijving', params);
  },
};

export default bestand;
