import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';

import styled from 'styled-components';
import { IRelatieIdentiteit } from '../../../../types';
import { Kleur } from '../../../../../../../bedrijfslogica/constanten';
import { IconContact } from '../../../../../../Icons';
import IRemoteData, {
  createPendingRemoteData,
  ERemoteDataState,
} from '../../../../../../../models/IRemoteData';
import { IOphalenRelatiesResultElementV2 } from '../../../../../../../../../shared/src/api/v2/relatie';
import Skeleton from 'react-loading-skeleton';
import useBijGewijzigdEffect from '../../../../../../../core/useBijGewijzigdEffect';
import { RouterContext } from '../../../../../../../routers/RouterContextProvider';
import InfoCard from '../../../../../../personalia/RelatieVisualisatie/InfoCard';
import { OverlayTrigger } from 'react-bootstrap';

const Root = styled.div`
  display: flex;
  align-items: center;
  background-color: #ffc3ff;
  padding: 1px 5px;
  border-radius: 5px;
  height: 20px;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: #ff9bff;
  }
`;

interface IProps {
  identiteit: IRelatieIdentiteit;
  relatiesCache: Record<number, IRemoteData<IOphalenRelatiesResultElementV2>>;
  onHerberekenenGrootte: () => void;
}

const RelatieIdentiteit = (props: IProps) => {
  const { navigeerKlantkaartNaarRelID } = useContext(RouterContext);

  const relatie = useMemo<IRemoteData<IOphalenRelatiesResultElementV2>>(() => {
    const entry = props.relatiesCache[props.identiteit.relID];
    return entry ?? createPendingRemoteData();
  }, [props.identiteit.relID, props.relatiesCache[props.identiteit.relID]]);

  useBijGewijzigdEffect(() => {
    props.onHerberekenenGrootte();
  }, [relatie]);

  const [showOverlay, setShowOverlay] = useState(false);
  const showOverlayTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const handleMouseEnterOverlay = useCallback(() => {
    if (showOverlayTimeoutRef.current !== null) {
      clearTimeout(showOverlayTimeoutRef.current);
    }
    showOverlayTimeoutRef.current = setTimeout(() => setShowOverlay(true), 800);
  }, []);
  const handleMouseLeaveOverlay = useCallback(() => {
    if (showOverlayTimeoutRef.current !== null) {
      clearTimeout(showOverlayTimeoutRef.current);
    }
    showOverlayTimeoutRef.current = setTimeout(() => setShowOverlay(false), 400);
  }, []);
  const renderOverlay = useCallback(
    (p: any) => {
      return (
        <div
          id={`relatie-visualisatie-overlay-${props.identiteit.relID}`}
          {...p}
          onMouseEnter={handleMouseEnterOverlay}
          onMouseLeave={handleMouseLeaveOverlay}
          style={{
            ...p.style,
            zIndex: 99999,
            border: `1px solid ${
              relatie.state === ERemoteDataState.Pending
                ? Kleur.Grijs
                : relatie.data!.hoedanigheid!.NaamEnum === 'LEVERANCIER'
                ? Kleur.Leverancier
                : Kleur.Klant
            }`,
            borderRadius: 5,
          }}
        >
          <InfoCard relID={props.identiteit.relID} onSluiten={() => setShowOverlay(false)} />
        </div>
      );
    },
    [props.identiteit.relID, handleMouseEnterOverlay, handleMouseLeaveOverlay, relatie],
  );

  const infoCardOffset = useMemo<[number, number]>(() => {
    return [0, 5];
  }, []);

  return (
    <OverlayTrigger
      trigger="hover"
      // placement="bottom-start"
      placement="auto-start"
      overlay={renderOverlay}
      show={showOverlay}
      popperConfig={
        {
          modifiers: {
            name: 'offset',
            options: {
              offset: infoCardOffset,
            },
          },
        } as any
      }
    >
      <Root
        onClick={async (ev) => {
          ev.stopPropagation();
          await navigeerKlantkaartNaarRelID(props.identiteit.relID);
        }}
        onMouseEnter={() => {
          handleMouseEnterOverlay();
        }}
        onMouseLeave={() => {
          handleMouseLeaveOverlay();
        }}
      >
        <IconContact style={{ fill: Kleur.DonkerGrijs, width: 13, height: 13, marginRight: 5 }} />
        {relatie.state === ERemoteDataState.Pending ? (
          <Skeleton width={100} />
        ) : (
          <span style={{ color: Kleur.DonkerGrijs, fontSize: 12 }}>
            {relatie.data!.weergavenaam}
          </span>
        )}
      </Root>
    </OverlayTrigger>
  );
};

export default RelatieIdentiteit;
