import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import IDialoogProps from '../../../../../../core/IDialoogProps';
import Dialoog from '../../../../../../components/dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { Formik, FormikActions, FormikProps } from 'formik';
import * as Yup from 'yup';
import api from '../../../../../../api';
import { format } from 'date-fns';
import LoadingSpinner from '../../../../../../components/Gedeeld/LoadingSpinner';
import ModalBody from 'react-bootstrap/ModalBody';
import { RootStoreContext } from '../../../../../../stores/RootStore';
import {
  IOphalenFacturenResultElement,
  IOphalenNogTeKoppelenProductenResultElement,
} from '../../../../../../../../shared/src/api/v2/inkoopfactuur';
import VeldWeergave from '../../../../../../components/formulier/VeldWeergave';
import GegevensLayout from '../../../../../../components/layout/GegevensLayout';
import {
  DXTableCheckboxComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../../../helpers/dxTableGrid';
import {
  Grid,
  VirtualTable,
  TableColumnResizing,
  TableHeaderRow,
  TableSelection,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  DataTypeProvider,
  IntegratedSorting,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import FormatteerBedrag from '../../../../../../components/MutatieBedrag';
import RelatieVisualisatie from '../../../../../../components/personalia/RelatieVisualisatie';
import useUrlState from '../../../../../../core/useUrlState';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IFilterSchemaFilter } from '../../../../../../../../shared/src/models/filter';
import _ from 'lodash';
import Gegroepeerd from './Gegroepeerd';
import { IOphalenProductenResultElementV2 } from '../../../../../../../../shared/src/api/v2/product';
import PerProduct from './PerProduct';
import WeergaveKeuze, { IWeergave } from '../../../../../../components/WeergaveKeuze';
import {
  EFunctioneleIcon,
  functioneleIconMap,
  IconGrid,
  IconInkoop,
} from '../../../../../../components/Icons';
import { Kleur as EKleur } from '../../../../../../bedrijfslogica/constanten';
import FilterBalkV2, {
  IFilter,
  IFilterData,
  maakFilterSchema,
} from '../../../../../../components/FilterBalkV2';
import ZoektermFilter from './ZoektermFilter';

export interface IDialoogResult {
  prodIDs: number[];
}

interface IProps extends IDialoogProps<IDialoogResult>, RouteComponentProps {
  factuur: IOphalenFacturenResultElement | null;
  relID?: number;
}

export enum EWeergave {
  Gegroepeerd,
  PerProduct,
}

enum EFilter {
  Zoekterm = 'ZOEKTERM',
}

interface IUrlState {
  weergave: EWeergave;
  filterData: IFilterData<EFilter>[];
}

const defaultUrlState: IUrlState = {
  weergave: EWeergave.Gegroepeerd,
  filterData: [
    {
      naam: EFilter.Zoekterm,
      data: '',
      isActief: false,
    },
  ],
};

export interface IFormikValues {}

export interface IRow {
  DatumInBedrijf: Date;
  Merknaam: string;
  Typenaam: string;
  InkoopopdrachtReferentie: string | null;
}

const IconWitgoedApparaat = functioneleIconMap[EFunctioneleIcon.WitgoedApparaat];

const SelecterenProductenDialoog: React.FC<IProps> = (props) => {
  const urlStateKey = 'productenSelectie';
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState, urlStateKey);

  const [filterSchema, setFilterSchema] = useState(maakFilterSchema(urlState.filterData));

  const { factuur, children, dialoogIndex, onAnnuleren, onSuccess, open } = props;
  const [selectie, setSelectie] = useState<number[]>([]);
  const { checkStore } = useContext(RootStoreContext);

  // De producten gegroepeerd
  const [productenGegroepeerd, setProductenGegroepeerd] = useState<
    IOphalenNogTeKoppelenProductenResultElement[] | null
  >(null);
  const [prodIDs, setProdIDs] = useState<number[]>([]);

  // De producten per stuk
  const [producten, setProducten] = useState<IOphalenProductenResultElementV2[] | null>(null);

  useEffect(() => {
    (async () => {
      const productenGegroepeerdResult = await api.v2.inkoopfactuur.ophalenNogTeKoppelenProducten({
        filterSchema: {
          filters: [
            ...filterSchema.filters!,
            factuur !== null ? { naam: 'REL_IDS', data: [factuur.RelID] } : null,
          ].filter((x) => x !== null) as IFilterSchemaFilter[],
        },
      });

      const productenGegroepeerd = productenGegroepeerdResult.producten.map((p) => {
        return {
          TypeID: p.TypeID,
          InkOpdID: p.InkOpdID,
          DatumInBedrijf: p.DatumInBedrijf,
          Typenaam: p.Typenaam,
          Merknaam: p.Merknaam,
          Inkoopreferentie: p.Inkoopreferentie,
          ProductsoortnaamKort: p.ProductsoortnaamKort,
          RelID: p.RelID,
          Aantal: p.Aantal,
        };
      });

      const productenGesorteerd = _.orderBy(
        productenGegroepeerd,
        ['DatumInBedrijf', 'Inkoopreferentie'],
        ['asc', 'asc'],
      );

      setProductenGegroepeerd(productenGesorteerd);
      setProdIDs(productenGegroepeerdResult.prodIDs);

      const productenResult = await api.v2.product.ophalenProductenV2({
        filterSchema: { filters: [{ naam: 'IDS', data: productenGegroepeerdResult.prodIDs }] },
      });
      setProducten(productenResult.producten);
    })();
  }, [factuur, filterSchema.filters]);

  const initialValues = useMemo<IFormikValues | null>(() => {
    return {};
  }, []);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      if (urlState.weergave === EWeergave.Gegroepeerd) {
        const selectiecriteria = productenGegroepeerd![selectie[0]];

        // Bepaal de prodIDs voor de gegeven selectie
        const selectieProducten = await api.v2.inkoopfactuur.ophalenSelectieNogTeKoppelenProducten({
          prodIDs,
          typeID: selectiecriteria.TypeID,
          inkOpdID: selectiecriteria.InkOpdID,
          datumInBedrijf: selectiecriteria.DatumInBedrijf,
        });

        onSuccess(selectieProducten);
      } else {
        const prodIDs = producten!
          .filter((x, i) => selectie.indexOf(i) !== -1)
          .map((x) => x.ProdID);
        onSuccess({ prodIDs });
      }

      actions.setSubmitting(false);
    },
    [onSuccess, productenGegroepeerd, prodIDs, selectie],
  );

  const weergaven = useMemo<Array<IWeergave<EWeergave>>>(
    () => [
      {
        key: EWeergave.Gegroepeerd,
        naam: 'Gegroepeerd',
        icon: <IconGrid style={{ width: 18, height: 18, fill: EKleur.Grijs }} />,
      },
      {
        key: EWeergave.PerProduct,
        naam: 'Per product',
        icon: <IconWitgoedApparaat style={{ width: 16, height: 16, fill: EKleur.Grijs }} />,
      },
    ],
    [],
  );

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.Zoekterm,
        altijdWeergevenInBalk: true,
        weergave: ZoektermFilter,
      },
    ],
    [],
  );

  return (
    <Dialoog index={dialoogIndex || 0} modalProps={{ size: 'xl' }}>
      <ModalHeader>
        <ModalTitle>Selecteren te koppelen producten</ModalTitle>
      </ModalHeader>

      {initialValues === null || productenGegroepeerd === null || producten === null ? (
        <LoadingSpinner />
      ) : (
        <Formik<IFormikValues>
          onSubmit={handleSubmit}
          initialValues={initialValues}
          // enableReinitialize
          // validationSchema={validationSchema}
          render={(formikProps: FormikProps<IFormikValues>) => {
            const { submitForm, isSubmitting, isValid, values } = formikProps;

            return (
              <>
                <ModalBody>
                  <div className="form-group">
                    <div className="row">
                      {factuur !== null && (
                        <div className="col-12">
                          <VeldWeergave>
                            <GegevensLayout
                              gegevens={[
                                {
                                  label: 'Fact.nr.',
                                  waarde: `${factuur.Factuurnummer}`,
                                },
                                {
                                  label: 'Factuurdatum',
                                  waarde: `${format(new Date(factuur.Factuurdatum), 'dd-MM-yyyy')}`,
                                },
                                {
                                  label: 'Crediteur',
                                  waarde: (
                                    <span>
                                      <RelatieVisualisatie relID={factuur.RelID} />
                                    </span>
                                  ),
                                },
                                {
                                  label: 'Onderwerp',
                                  waarde: `${factuur.Onderwerp}`,
                                },

                                // {
                                //   label: 'Bedrag ex btw',
                                //   waarde: (
                                //     <MutatieBedrag bedrag={factuur.Bedrag - factuur.BedragBtw} />
                                //   ),
                                // },
                              ]}
                            />
                          </VeldWeergave>

                          <div className="mt-3">
                            <WeergaveKeuze
                              weergave={urlState.weergave}
                              onChange={(x) => setUrlStateSync('weergave', x)}
                              weergaven={weergaven}
                            />
                          </div>

                          <div className="mt-2">
                            <FilterBalkV2
                              filters={filters}
                              filterData={urlState.filterData}
                              onFilterDataChange={(x) => setUrlStateSync('filterData', x)}
                              onFilterSchemaChange={(x) => setFilterSchema(x)}
                            />
                          </div>
                        </div>
                      )}

                      <div className="col-12 mt-3">
                        <div className="d-flex flex-column flex-fill mt-2">
                          <>
                            {urlState.weergave === EWeergave.Gegroepeerd ? (
                              <Gegroepeerd
                                producten={productenGegroepeerd}
                                selectie={selectie}
                                onSelectieChange={(x) => {
                                  return setSelectie(x as number[]);
                                }}
                              />
                            ) : (
                              <PerProduct
                                producten={producten}
                                selectie={selectie}
                                onSelectieChange={(x) => {
                                  return setSelectie(x as number[]);
                                }}
                              />
                            )}
                          </>
                        </div>
                        {/* </div> */}
                      </div>
                      <div className="col-12">
                        <span></span>
                      </div>
                    </div>
                  </div>
                </ModalBody>
                <ModalFooter className="d-flex flex-row justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    style={{ width: 100 }}
                    disabled={
                      (urlState.weergave === EWeergave.Gegroepeerd && selectie.length !== 1) ||
                      (urlState.weergave === EWeergave.PerProduct && selectie.length === 0) ||
                      isSubmitting
                    }
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={() => {
                      onAnnuleren();
                    }}
                    style={{ width: 100 }}
                    disabled={isSubmitting}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Dialoog>
  );
};

export default withRouter(SelecterenProductenDialoog);
