import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import IDialoogProps from '../../../../../core/IDialoogProps';
import Dialoog from '../../../../../components/dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import * as Yup from 'yup';
import api from '../../../../../api';
import LoadingSpinner from '../../../../../components/Gedeeld/LoadingSpinner';
import Tabblad, { ITabblad } from '../../../../../components/layout/Tabblad';
import ModalBody from 'react-bootstrap/ModalBody';
import { RootStoreContext } from '../../../../../stores/RootStore';
import { EResultType } from '../../../../../stores/CheckStore';
import nameof from '../../../../../core/nameOf';
import {
  IOphalenFacturenResultElement,
  IOphalenGekoppeldeProductenResultElement,
} from '../../../../../../../shared/src/api/v2/inkoopfactuur';
import ProductenTab from './ProductenTab';
import {
  DXTableCheckboxComponent,
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../../helpers/dxTableGrid';
import {
  Grid,
  Table,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  TableSelection,
} from '@devexpress/dx-react-grid-bootstrap4';
import VeldWeergave from '../../../../../components/formulier/VeldWeergave';
import GegevensLayout from '../../../../../components/layout/GegevensLayout';
import { format } from 'date-fns';
import FormatteerBedrag, {
  StandaardMutatieBedragOpmaker,
} from '../../../../../components/MutatieBedrag';
import {
  DataTypeProvider,
  EditingState,
  IntegratedSorting,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import SelecterenProductenDialoog from './SelecterenProductenDialoog';
import { Kleur } from '../../../../../bedrijfslogica/constanten';
import { IconBetaling, IconToevoegen, IconVerwijderen } from '../../../../../components/Icons';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import useUrlState from '../../../../../core/useUrlState';
import RelatieVisualisatie from '../../../../../components/personalia/RelatieVisualisatie';
import _ from 'lodash';
import WijzigenInkoopprijsDialoog from './WijzigenInkoopprijsDialoog';
import nameOf from '../../../../../core/nameOf';
import { indexOf } from 'lodash';
import SelectieVak from '../../../../../components/SelectieVak';
import { EGrootboekrekening } from '../../../../../bedrijfslogica/enums';
import {
  IOphalenBoekingenResultElement,
  IOphalenBoekingRegelsResultElement,
} from '../../../../../../../shared/src/api/v2/boekhouding/boeking';
import { IOphalenProductenResultElementV2 } from '../../../../../../../shared/src/api/v2/product';

export interface IDialoogResult {}

interface IProps extends IDialoogProps<IDialoogResult>, RouteComponentProps {
  inkFactID: number;
}

export interface IFormikValues {
  gekoppeld: boolean;
}

export enum ETabblad {
  Product,
}

export interface IKoppelenProductenDialoogContext {
  factuur: IOphalenFacturenResultElement | null;
}
export const KoppelProductenDialoogContext = React.createContext<IKoppelenProductenDialoogContext>(
  null as any,
);

export interface ISelecterenProductenDialoogState {
  factuur: IOphalenFacturenResultElement;
}

export interface IWijzigenInkoopprijsDialoogState {
  inkoopprijs: number;
}

export interface IRow {
  // ProdID: number;
  Bedrag: number | null;
  InkFactID: number;
  product: IOphalenProductenResultElementV2;
  // Merknaam: string;
  // Typenaam: string;
  // InkoopopdrachtReferentie: string | null;
  // DatumInBedrijf: Date;
}

export interface IUrlState {
  selectie: number[];
}
const defaultUrlState: IUrlState = {
  selectie: [],
};

export interface IBoeking extends IOphalenBoekingRegelsResultElement {
  boeking: IOphalenBoekingenResultElement;
}

const KoppelProductenDialoog: React.FC<IProps> = (props) => {
  const { children, dialoogIndex, onAnnuleren, onSuccess, open } = props;
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);

  const { checkStore } = useContext(RootStoreContext);

  const [tabblad, setTabblad] = useState<ETabblad>(ETabblad.Product);
  const [factuur, setFactuur] = useState<IOphalenFacturenResultElement | null>(null);
  const [producten, setProducten] = useState<IRow[] | null>(null);
  const [bedragGekoppeld, setBedragGekoppeld] = useState<number>(0);
  const [bedragGeboekt100, setBedragGeboekt100] = useState<number | null>(null);
  const [boekingen, setBoekingen] = useState<IBoeking[] | null>(null);

  const [
    selecterenProductenDialoogState,
    setSelecterenProductenDialoogState,
  ] = useState<ISelecterenProductenDialoogState | null>(null);

  const [
    wijzigenInkoopprijsDialoogState,
    setWijzigenInkoopprijsDialoogState,
  ] = useState<IWijzigenInkoopprijsDialoogState | null>(null);

  // Inkoopfactuur
  useEffect(() => {
    (async () => {
      const facturenResultaat = await api.v2.inkoopfactuur.ophalenFacturen({
        filterSchema: { filters: [{ naam: 'IDS', data: [props.inkFactID] }] },
      });
      const factuur = facturenResultaat.facturen[0];

      setFactuur(factuur);
    })();
  }, [props.inkFactID]);

  // Boekingen voor deze factuur
  useEffect(() => {
    (async () => {
      const boekingregels = (
        await api.v2.boeking.ophalenBoekingregels({
          filterSchema: {
            filters: [{ naam: 'INKFACT_IDS', data: [props.inkFactID] }],
          },
        })
      ).regels;
      const boekingIDs = boekingregels.map((x) => x.BoekingID);
      const boekingenResult = (
        await api.v2.boeking.ophalenBoekingen({
          filterSchema: {
            filters: [{ naam: 'IDS', data: boekingIDs }],
          },
        })
      ).boekingen;

      const boekingen = boekingregels.map((x) => {
        const boeking = boekingenResult.find((b) => b.ID === x.BoekingID)!;
        return { ...x, boeking };
      });

      setBoekingen(boekingen);
    })();
  }, [props.inkFactID]);

  // Bedrag geboekt op grootboek 100 voor deze factuur
  useEffect(() => {
    (async () => {
      if (boekingen === null) {
        return;
      }

      const boekingenVoorFactuur = boekingen.filter(
        (x) =>
          x.InkFactID === props.inkFactID &&
          (x.dbRekening.Nummer === EGrootboekrekening.Machines ||
            x.crRekening.Nummer === EGrootboekrekening.Machines),
      );
      const bedrag100: number = Number(
        _.sum(
          boekingenVoorFactuur.map((x) =>
            x.crRekening.NaamEnum === 'MACHINES'
              ? -(x.Bedrag - (x.BtwBedrag || 0))
              : x.Bedrag - (x.BtwBedrag || 0),
          ),
        ).toFixed(2),
      );

      setBedragGeboekt100(bedrag100);
    })();
  }, [boekingen]);

  // Haal de gekoppelde producten voor deze factuur op
  const ophalenProducten = useCallback(async () => {
    const producten = (
      await api.v2.inkoopfactuur.ophalenGekoppeldeProducten({
        inkFactIDs: [props.inkFactID],
      })
    ).producten;

    setProducten(producten);

    const bedragGekoppeld = _.sum(producten.map((x) => x.Bedrag || 0)).toFixed(2);

    setBedragGekoppeld(bedragGekoppeld);
  }, [props.inkFactID]);

  useEffect(() => {
    ophalenProducten();
  }, [ophalenProducten]);

  const tabbladen = useMemo<ITabblad<ETabblad>[]>(
    () => [
      {
        id: ETabblad.Product,
        label: 'Producten',
        content: ProductenTab,
      },
    ],
    [],
  );

  const initialValues = useMemo<IFormikValues | null>(() => {
    return {
      gekoppeld: false,
    };
  }, []);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      setUrlStateSync('selectie', []);
      onSuccess({});

      actions.setSubmitting(false);
    },
    [onSuccess],
  );

  const koppelenProducten = useCallback(
    async (prodIDs: number[]) => {
      const productenResult = (
        await api.v2.product.ophalenProductenV2({
          filterSchema: { filters: [{ naam: 'IDS', data: prodIDs }] },
        })
      ).producten;

      const producten = productenResult.map((x) => {
        const bedrag =
          x.Inkoopprijs !== null
            ? x.DatumUitBedrijf === null
              ? x.Inkoopprijs
              : -x.Inkoopprijs
            : null;
        return {
          prodID: x.ProdID,
          bedrag,
        };
      });
      const params = { inkFactID: props.inkFactID, producten };

      await api.v2.inkoopfactuur.toevoegenGekoppeldeProducten(params);
      setUrlStateSync('selectie', []);
      ophalenProducten();
    },
    [onSuccess],
  );

  const verwijderenProducten = useCallback(
    async (prodIDs: number[]) => {
      if (
        (
          await checkStore.bevestigen({
            inhoud: 'Gekoppelde producten verwijderen?',
          })
        ).type === EResultType.Annuleren
      ) {
        return;
      }

      const params = { inkFactID: props.inkFactID, prodIDs };

      await api.v2.inkoopfactuur.verwijderenGekoppeldeProducten(params);
      setUrlStateSync('selectie', []);
      ophalenProducten();
    },
    [onSuccess],
  );

  const wijzigenInkoopprijs = useCallback(
    async (prodIDs: number[]) => {
      if (producten === null) {
        return;
      }

      // Bepaal welke inkoopprijs voorgevuld moet gaan worden
      const bedragen = _.uniq(
        producten
          .filter((x) => urlState.selectie.indexOf(x.product.ProdID) !== -1)
          .map((x) => x.Bedrag),
      );
      const inkoopprijs = bedragen.length === 1 ? bedragen[0] : 0;

      setWijzigenInkoopprijsDialoogState({ inkoopprijs });
    },
    [onSuccess, producten],
  );

  const validationSchema = useMemo(() => {
    const fields: Record<keyof IFormikValues, any> = {
      gekoppeld: Yup.date().nullable(),
    };
    return Yup.object().shape(fields);
  }, []);

  const context = useMemo<IKoppelenProductenDialoogContext>(() => {
    return {
      factuur,
    };
  }, [factuur]);

  const kolommenProducten = useMemo<TypedColumn<IRow>[]>(
    () => [
      {
        name: '__merknaam' as any,
        title: 'Merk',
      },
      {
        name: '__typenaam' as any,
        title: 'Type',
      },
      {
        name: '__inkoopopdrachtReferentie' as any,
        title: 'Ink.ref.',
      },
      {
        name: '__datumInBedrijf' as any,
        title: 'In bedrijf',
      },
      {
        name: 'Bedrag',
        title: 'Bedrag',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRow>[]>(
    () => [
      {
        columnName: '__merknaam' as any,
        width: 85,
      },
      {
        columnName: '__typenaam' as any,
        width: 175,
      },
      {
        columnName: '__inkoopopdrachtReferentie' as any,
        width: 100,
      },
      {
        columnName: '__datumInBedrijf' as any,
        width: 100,
      },
      {
        columnName: 'Bedrag',
        width: 150,
      },
    ],
    [],
  );

  return (
    <KoppelProductenDialoogContext.Provider value={context}>
      <Dialoog index={dialoogIndex || 0} modalProps={{ size: 'lg' }}>
        <ModalHeader>
          <ModalTitle>Koppelen producten</ModalTitle>
        </ModalHeader>

        {initialValues === null || factuur === null || producten === null ? (
          <LoadingSpinner />
        ) : (
          <Formik<IFormikValues>
            onSubmit={handleSubmit}
            initialValues={initialValues}
            validationSchema={validationSchema}
            render={(formikProps: FormikProps<IFormikValues>) => {
              const { submitForm, isSubmitting, isValid, values } = formikProps;

              return (
                <>
                  {/* <Tabblad
                    onSelectieChange={(x) => setTabblad(x)}
                    tabbladen={tabbladen}
                    geselecteerd={tabblad}
                  /> */}
                  <ModalBody>
                    <div className="form-group">
                      <div className="row">
                        <div className="col-12">
                          <VeldWeergave>
                            <div className="d-flex">
                              <div style={{ width: 375 }}>
                                <GegevensLayout
                                  gegevens={[
                                    {
                                      label: 'Fact.nr.',
                                      waarde: `${factuur.Factuurnummer}`,
                                    },
                                    {
                                      label: 'Factuurdatum',
                                      waarde: `${format(
                                        new Date(factuur.Factuurdatum),
                                        'dd-MM-yyyy',
                                      )}`,
                                    },
                                    {
                                      label: 'Fact.bedrag',
                                      waarde: <FormatteerBedrag bedrag={factuur.Bedrag} />,
                                    },
                                    {
                                      label: 'Fact.bedrag Excl. btw',
                                      waarde: (
                                        <FormatteerBedrag
                                          bedrag={factuur.Bedrag - factuur.BedragBtw}
                                        />
                                      ),
                                    },
                                  ]}
                                />
                              </div>

                              <div style={{ width: 225 }}>
                                <GegevensLayout
                                  gegevens={[
                                    {
                                      label: 'Geboekt grb. 100',
                                      waarde:
                                        bedragGeboekt100 !== null ? (
                                          <FormatteerBedrag bedrag={bedragGeboekt100} />
                                        ) : (
                                          <span></span>
                                        ),
                                    },

                                    {
                                      label: 'Bedrag gekoppeld',
                                      waarde: <FormatteerBedrag bedrag={bedragGekoppeld} />,
                                    },
                                    {
                                      label: 'Nog te kop. bedrag',
                                      waarde:
                                        bedragGeboekt100 !== null ? (
                                          bedragGeboekt100 - bedragGekoppeld === 0 ? (
                                            <FormatteerBedrag
                                              bedrag={bedragGeboekt100 - bedragGekoppeld}
                                              opmaakComponent={(opmaakProps) => {
                                                return (
                                                  <StandaardMutatieBedragOpmaker
                                                    {...opmaakProps}
                                                    color={Kleur.Groen}
                                                  />
                                                );
                                              }}
                                            />
                                          ) : (
                                            <FormatteerBedrag
                                              bedrag={bedragGeboekt100 - bedragGekoppeld}
                                              opmaakComponent={(opmaakProps) => {
                                                return (
                                                  <StandaardMutatieBedragOpmaker
                                                    {...opmaakProps}
                                                    color={Kleur.Rood}
                                                  />
                                                );
                                              }}
                                            />
                                          )
                                        ) : (
                                          <span></span>
                                        ),
                                    },
                                    {
                                      label: 'Aantal gekop. prod.',
                                      waarde: `${producten.length}`,
                                    },
                                  ]}
                                />
                              </div>
                            </div>

                            <div style={{ width: 500 }} className="mt-3">
                              <GegevensLayout
                                gegevens={[
                                  {
                                    label: 'Crediteur',
                                    waarde: (
                                      <span>
                                        <RelatieVisualisatie relID={factuur.RelID} />
                                      </span>
                                    ),
                                  },
                                  {
                                    label: 'Onderwerp',
                                    waarde: `${factuur.Onderwerp}`,
                                  },
                                ]}
                              />
                            </div>
                          </VeldWeergave>
                        </div>

                        <div className="col-12 mt-3">
                          <div className="d-flex">
                            <button
                              className="btn btn-sm btn-light d-flex align-items-center"
                              disabled={!true}
                              style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                              onClick={() => setSelecterenProductenDialoogState({ factuur })}
                            >
                              <IconToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                              <span className="ml-2">Toevoegen</span>
                            </button>

                            <button
                              className="btn btn-sm btn-light d-flex align-items-center ml-3"
                              disabled={urlState.selectie.length === 0}
                              style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                              onClick={() => verwijderenProducten(urlState.selectie)}
                            >
                              <IconVerwijderen
                                style={{ width: 16, height: 16, fill: Kleur.Grijs }}
                              />
                              <span className="ml-2">Verwijderen</span>
                            </button>

                            <button
                              className="btn btn-sm btn-light d-flex align-items-center ml-3"
                              disabled={urlState.selectie.length === 0}
                              style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                              onClick={() => wijzigenInkoopprijs(urlState.selectie)}
                            >
                              <IconBetaling style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                              <span className="ml-2">Wijzigen bedrag</span>
                            </button>

                            {bedragGeboekt100 !== null && (
                              <button
                                className="btn btn-sm btn-light d-flex align-items-center ml-3"
                                disabled={urlState.selectie.length === 0}
                                style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                                onClick={async () => {
                                  const restbedrag = bedragGeboekt100 - bedragGekoppeld;
                                  if (
                                    (
                                      await checkStore.bevestigen({
                                        inhoud: (
                                          <div>
                                            Het geboekte bedrag{' '}
                                            <FormatteerBedrag bedrag={bedragGeboekt100} /> verdelen
                                            over de geselecteerde producten?
                                          </div>
                                        ),
                                      })
                                    ).type === EResultType.Annuleren
                                  ) {
                                    return;
                                  }
                                  await api.v2.inkoopfactuur.verdelenRestbedrag({
                                    inkFactID: props.inkFactID,
                                    prodIDs: producten.map((x) => x.product!.ProdID), //urlState.selectie,
                                    restbedrag: bedragGeboekt100,
                                  });
                                  ophalenProducten();
                                  return;
                                }}
                              >
                                <IconBetaling
                                  style={{ width: 16, height: 16, fill: Kleur.Grijs }}
                                />
                                <span className="ml-2">Bedrag aut. verdelen</span>
                              </button>
                            )}
                          </div>

                          <div className="mt-3">
                            <SelectieVak
                              aantal={urlState.selectie.length}
                              totaalAantal={producten.length}
                              onChange={(alles) => {
                                if (alles) {
                                  setUrlStateSync(
                                    'selectie',
                                    producten.map((x) => x.product.ProdID),
                                  );
                                } else {
                                  setUrlStateSync('selectie', []);
                                }
                              }}
                            />
                          </div>
                        </div>

                        <div className="col-12 mt-3">
                          <div className="d-flex flex-column flex-fill mt-2">
                            <>
                              <GridStyleWrapper maxHeight={500} rowAmount={producten.length}>
                                <Grid
                                  getRowId={(x) => x.product.ProdID}
                                  rows={producten}
                                  columns={kolommenProducten}
                                >
                                  <DataTypeProvider
                                    for={['__merknaam']}
                                    formatterComponent={(formatterProps) => {
                                      const rij: IRow = formatterProps.row;
                                      return <span>{rij.product.producttype.Merknaam}</span>;
                                    }}
                                  />

                                  <DataTypeProvider
                                    for={['__typenaam']}
                                    formatterComponent={(formatterProps) => {
                                      const rij: IRow = formatterProps.row;
                                      return <span>{rij.product.producttype.Typenaam}</span>;
                                    }}
                                  />

                                  <DataTypeProvider
                                    for={['__inkoopopdrachtReferentie']}
                                    formatterComponent={(formatterProps) => {
                                      const rij: IRow = formatterProps.row;
                                      return (
                                        <span>
                                          {rij.product.inkoopopdracht !== null
                                            ? rij.product.inkoopopdracht.Referentie
                                            : null}
                                        </span>
                                      );
                                    }}
                                  />

                                  <DataTypeProvider
                                    for={['__datumInBedrijf']}
                                    formatterComponent={(formatterProps) => {
                                      const rij: IRow = formatterProps.row;
                                      return (
                                        <span>
                                          {format(
                                            new Date(rij.product.DatumInBedrijf),
                                            'dd-MM-yyyy',
                                          )}
                                        </span>
                                      );
                                    }}
                                  />

                                  <DataTypeProvider
                                    for={[nameOf('Bedrag')]}
                                    formatterComponent={(formatterProps) => {
                                      const rij: IRow = formatterProps.row;
                                      if (rij.Bedrag === null) {
                                        return <span>-</span>;
                                      }
                                      return <FormatteerBedrag bedrag={rij.Bedrag} />;
                                    }}
                                  />

                                  <DataTypeProvider
                                    for={[nameOf('DatumInBedrijf')]}
                                    formatterComponent={(formatterProps) => {
                                      return (
                                        <span>
                                          {format(new Date(formatterProps.value), 'dd-MM-yyyy')}
                                        </span>
                                      );
                                    }}
                                  />

                                  <EditingState
                                    onAddedRowsChange={() =>
                                      setSelecterenProductenDialoogState({ factuur })
                                    }
                                    onCommitChanges={async (changes) => {
                                      if (changes.deleted === undefined) {
                                        return;
                                      }
                                      const deleted = changes.deleted;
                                      const id = deleted[deleted.length - 1] as number;
                                      alert(id);
                                    }}
                                    onEditingRowIdsChange={(rowIds) => {
                                      const id = rowIds[rowIds.length - 1] as number;
                                    }}
                                  />

                                  <SortingState defaultSorting={[]} />
                                  <IntegratedSorting />
                                  <Table messages={{ noData: 'Geen gekoppelde producten' }} />
                                  <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
                                  {/* <TableEditColumn
                                    width={35}
                                    // showAddCommand
                                    showEditCommand
                                    // showDeleteCommand
                                    cellComponent={DXTableEditColumnCellComponent}
                                    commandComponent={DXTableEditColumnCommandComponent}
                                  /> */}
                                  <TableHeaderRow showSortingControls />

                                  <SelectionState
                                    selection={urlState.selectie}
                                    onSelectionChange={(x) =>
                                      setUrlStateSync('selectie', x as number[])
                                    }
                                  />
                                  <TableSelection cellComponent={DXTableCheckboxComponent} />
                                </Grid>
                              </GridStyleWrapper>
                            </>
                          </div>
                          {/* </div> */}
                        </div>
                        <div className="col-12">
                          <span></span>
                        </div>
                      </div>
                    </div>
                  </ModalBody>

                  <ModalFooter className="d-flex flex-row justify-content-start">
                    {/* <button
                      className="btn btn-primary"
                      onClick={submitForm}
                      style={{ width: 100 }}
                      disabled={!isValid || isSubmitting}
                    >
                      Ok
                    </button> */}
                    <button
                      className="btn btn-primary"
                      onClick={onAnnuleren}
                      style={{ width: 100 }}
                      disabled={isSubmitting}
                    >
                      Sluiten
                    </button>
                  </ModalFooter>
                </>
              );
            }}
          />
        )}
      </Dialoog>
      {selecterenProductenDialoogState !== null && (
        <SelecterenProductenDialoog
          open
          factuur={factuur}
          onSuccess={(data) => {
            koppelenProducten(data.prodIDs);
            setSelecterenProductenDialoogState(null);
          }}
          onAnnuleren={() => {
            setSelecterenProductenDialoogState(null);
          }}
        />
      )}
      {wijzigenInkoopprijsDialoogState !== null && (
        <WijzigenInkoopprijsDialoog
          open
          inkoopprijs={wijzigenInkoopprijsDialoogState.inkoopprijs}
          onSuccess={async (data) => {
            await api.v2.inkoopfactuur.wijzigenInkoopprijs({
              inkFactID: props.inkFactID,
              prodIDs: urlState.selectie,
              bedrag: data.inkoopprijs,
            });
            setWijzigenInkoopprijsDialoogState(null);
            ophalenProducten();
          }}
          onAnnuleren={() => {
            setWijzigenInkoopprijsDialoogState(null);
          }}
        />
      )}
    </KoppelProductenDialoogContext.Provider>
  );
};

export default withRouter(KoppelProductenDialoog);
