import React from 'react';
import { Redirect, Route, RouteComponentProps } from 'react-router';
import Eventtypen from './Eventtypen';
import Logs from './Logs';
import Signaleringstypen from './Signaleringstypen';

interface IProps extends RouteComponentProps<{}> {}

const Technisch = (props: IProps) => {
  const { match } = props;
  return (
    <>
      <Route path={`${match.path}/logs`} component={Logs} />
      <Route path={`${match.path}/eventtypen`} component={Eventtypen} />
      <Route path={`${match.path}/signaleringstypen`} component={Signaleringstypen} />

      <Route exact path={match.path} render={() => <Redirect to={`${match.path}/logs`} />} />
    </>
  );
};

export default Technisch;
