import api from '../../..';
import {
  IOphalenDossierParams,
  IOphalenDossierResult,
  IOphalenIncassozakenParams,
  IOphalenIncassozakenResult,
  IVerwijderenDossierParams,
  IVerwijderenDossierResult,
  INieuwDossierParams,
  INieuwDossierResult,
  IWijzigenDossierParams,
  IWijzigenDossierResult,
  IOphalenDossierStatussenParams,
  IOphalenDossierStatussenResult,
  IOphalenResultaatStatussenParams,
  IOphalenResultaatStatussenResult,
  IOphalenFacturenParams,
  IOphalenFacturenResult,
  IOphalenIncassoDienstenParams,
  IOphalenIncassoDienstenResult,
  IVersturenIncassoOpdrachtenParams,
  IVersturenIncassoOpdrachtenResult,
  IToevoegenIncassozaakParams,
  IToevoegenIncassozaakResult,
  ICheckSelectieToevoegenIncassozaakParams,
  IWijzigenDossiersstatusParams,
  IWijzigenDossiersstatusResult,
  IToevoegenRechtzaakVoorIncassozaakParams,
  IToevoegenRechtzaakVoorIncassozaakResult,
  IToevoegenHerincassozaakVoorIncassozaakParams,
  IToevoegenHerincassozaakVoorIncassozaakResult,
} from '../../../../../../shared/src/api/v2/debiteur/incassozaak';
import ICheckData from '../../../../../../shared/src/models/ICheckData';

const zaak = {
  ophalenIncassozaken: async (
    params: IOphalenIncassozakenParams,
  ): Promise<IOphalenIncassozakenResult> => {
    return await api.post('/v2/debiteur/incassozaak/ophalen-incassozaken', params);
  },
  checkVerwijderenDossier: async (params: IVerwijderenDossierParams): Promise<ICheckData> => {
    return await api.post('/v2/debiteur/incassozaak/check-verwijderen-dossier', params);
  },
  verwijderenDossier: async (
    params: IVerwijderenDossierParams,
  ): Promise<IVerwijderenDossierResult> => {
    return await api.post('/v2/debiteur/incassozaak/verwijderen-dossier', params);
  },
  checkNieuwDossier: async (params: INieuwDossierParams): Promise<ICheckData> => {
    return await api.post('/v2/debiteur/incassozaak/check-nieuw-dossier', params);
  },
  nieuwDossier: async (params: INieuwDossierParams): Promise<INieuwDossierResult> => {
    return await api.post('/v2/debiteur/incassozaak/nieuw-dossier', params);
  },
  wijzigenDossiersstatus: async (
    params: IWijzigenDossiersstatusParams,
  ): Promise<IWijzigenDossiersstatusResult> => {
    return await api.post('/v2/debiteur/incassozaak/wijzigen-dossiersstatus', params);
  },
  wijzigenDossier: async (params: IWijzigenDossierParams): Promise<IWijzigenDossierResult> => {
    return await api.post('/v2/debiteur/incassozaak/wijzigen-dossier', params);
  },
  ophalenDossierStatussen: async (
    params: IOphalenDossierStatussenParams,
  ): Promise<IOphalenDossierStatussenResult> => {
    return await api.post('/v2/debiteur/incassozaak/ophalen-dossier-statussen', params);
  },
  ophalenResultaatStatussen: async (
    params: IOphalenResultaatStatussenParams,
  ): Promise<IOphalenResultaatStatussenResult> => {
    return await api.post('/v2/debiteur/incassozaak/ophalen-dossier-resultaat-statussen', params);
  },
  ophalenFacturen: async (params: IOphalenFacturenParams): Promise<IOphalenFacturenResult> => {
    return await api.post('/v2/debiteur/incassozaak/ophalen-facturen', params);
  },
  checkVersturenIncassoOpdrachten: async (
    params: IVersturenIncassoOpdrachtenParams,
  ): Promise<ICheckData> => {
    return await api.post('/v2/debiteur/incassozaak/check-versturen-incasso-opdrachten', params);
  },
  versturenIncassoOpdrachten: async (
    params: IVersturenIncassoOpdrachtenParams,
  ): Promise<IVersturenIncassoOpdrachtenResult> => {
    return await api.post('/v2/debiteur/incassozaak/versturen-incasso-opdrachten', params);
  },
  checkSelectieToevoegenIncassozaak: async (
    params: ICheckSelectieToevoegenIncassozaakParams,
  ): Promise<ICheckData> => {
    return await api.post('/v2/debiteur/incassozaak/check-selectie-toevoegen-incassozaak', params);
  },
  toevoegenIncassozaak: async (
    params: IToevoegenIncassozaakParams,
  ): Promise<IToevoegenIncassozaakResult> => {
    return await api.post('/v2/debiteur/incassozaak/toevoegen-incassozaak', params);
  },
  checkToevoegenRechtzaakVoorIncassozaak: async (
    params: IToevoegenRechtzaakVoorIncassozaakParams,
  ): Promise<ICheckData> => {
    return await api.post(
      '/v2/debiteur/incassozaak/check-toevoegen-rechtzaak-voor-incassozaak',
      params,
    );
  },
  toevoegenRechtzaakVoorIncassozaak: async (
    params: IToevoegenRechtzaakVoorIncassozaakParams,
  ): Promise<IToevoegenRechtzaakVoorIncassozaakResult> => {
    return await api.post('/v2/debiteur/incassozaak/toevoegen-rechtzaak-voor-incassozaak', params);
  },
  checkToevoegenHerincassozaakVoorIncassozaak: async (
    params: IToevoegenHerincassozaakVoorIncassozaakParams,
  ): Promise<ICheckData> => {
    return await api.post(
      '/v2/debiteur/incassozaak/check-toevoegen-herincassozaak-voor-incassozaak',
      params,
    );
  },
  toevoegenHerincassozaakVoorIncassozaak: async (
    params: IToevoegenHerincassozaakVoorIncassozaakParams,
  ): Promise<IToevoegenHerincassozaakVoorIncassozaakResult> => {
    return await api.post(
      '/v2/debiteur/incassozaak/toevoegen-herincassozaak-voor-incassozaak',
      params,
    );
  },
};

export default zaak;
