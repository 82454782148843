import React, { useMemo, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Tabblad, { ITabblad } from '../../../../../../components/layout/Tabblad';
import Uitnodigingen from './Uitnodigingen';
import Beoordelingen from './Beoordelingen';
import { IEntiteitProps } from '../../../../../../components/kaart/EntiteitContainer';

export enum ETabblad {
  Uitnodigingen,
  Beoordelingen,
}

const pathMap: Record<ETabblad, string> = {
  [ETabblad.Uitnodigingen]: '/uitnodigingen',
  [ETabblad.Beoordelingen]: '/beoordelingenen',
};

interface IProps extends IEntiteitProps, RouteComponentProps {
  relID: number;
}

const Reviews: React.FC<IProps> = (props) => {
  // const [tabblad, setTabblad] = useState<ETabblad>(ETabblad.Uitnodigingen);

  // const tabblad = Number(0) as ETabblad;
  // const tabblad = useMemo<ETabblad | null>(() => {
  //   const matchKey = Object.keys(pathMap).find((key) => {
  //     const path = pathMap[Number(key) as ETabblad]!;
  //     return props.location.pathname.endsWith(path);
  //   });
  //   if (matchKey === undefined) {
  //     props.history.push(`${props.match.path}${pathMap[ETabblad.Uitnodigingen]}`);
  //     return null;
  //   }
  //   return Number(matchKey) as ETabblad;
  // }, [props.location.pathname]);

  const tabblad = useMemo<ETabblad | null>(() => {
    const matchKey = Object.keys(pathMap).find((key) => {
      const path = pathMap[Number(key) as ETabblad]!;
      return props.location.pathname.endsWith(path);
    });
    if (matchKey === undefined) {
      props.history.push(`${props.match.path}${pathMap[ETabblad.Uitnodigingen]}`);
      return null;
    }
    return Number(matchKey) as ETabblad;
  }, [props.location.pathname]);

  const tabbladen = useMemo<ITabblad<ETabblad>[]>(
    () => [
      {
        id: ETabblad.Uitnodigingen,
        label: 'Uitnodigingen',
        content: Uitnodigingen,
      },
      {
        id: ETabblad.Beoordelingen,
        label: 'Beoordelingen',
        content: Beoordelingen,
      },
    ],
    [],
  );

  return (
    <div className="d-flex flex-column flex-fill flex-fill">
      {tabblad !== null && (
        <Tabblad<ETabblad>
          geselecteerd={tabblad}
          onSelectieChange={(tab) => {
            props.history.push(
              `${props.location.pathname.replace(pathMap[tabblad], '')}${pathMap[tab]}`,
            );
          }}
          tabbladen={tabbladen}
          options={{
            tabbladComponentProps: {
              relID: props.relID,
            },
          }}
        />
      )}
    </div>
  );
};

export default withRouter(Reviews);
