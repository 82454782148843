import React, { useState, useCallback, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { IRapportageDetailProps } from '../../index';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../../../models/IRemoteData';
import {
  ILijstActivaregisterResult,
  ILijstActivaregisterResultElement,
} from '../../../../../../../../shared/src/api/v2/boekhouding/rapportage';
import api from '../../../../../../api';
import {
  ASPKolom,
  EAspKolomBreedteType,
  ESortering,
  IAspKolomSorteringItem,
} from '../../../../../../components/tabel/ASPTabel/types';
import FormatteerBedrag from '../../../../../../components/MutatieBedrag';
import { format } from 'date-fns';
import LoadingSpinner from '../../../../../../components/Gedeeld/LoadingSpinner';
import ASPTabel from '../../../../../../components/tabel/ASPTabel';

export enum EKolom {
  Productsoort,
  Merk,
  Type,
  DatumGeactiveerd,
  Aanschafwaarde,
  Boekstuknummers,
  RecordToegevoegd,
}

const Root = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const LijstActivaregister = (props: IRapportageDetailProps) => {
  const [lijstActivaregister, setLijstActivaregister] = useState<
    IRemoteData<ILijstActivaregisterResult>
  >(createPendingRemoteData());
  const ophalenLijstActivaregister = useCallback(async () => {
    const result = await api.v2.boekhouding.rapportage.lijstActivaregister({
      jaar: props.jaar,
      maandVem: props.maandVem,
      maandTem: props.maandTem,
    });
    setLijstActivaregister(createReadyRemoteData(result));
  }, [props.jaar, props.maandVem, props.maandTem]);
  useEffect(() => {
    ophalenLijstActivaregister();
  }, [ophalenLijstActivaregister]);

  const keyExtractor = useCallback((regel: ILijstActivaregisterResultElement) => regel.id, []);
  const kolommen = useMemo<ASPKolom<EKolom, ILijstActivaregisterResultElement>[]>(
    () => [
      {
        key: EKolom.Merk,
        label: 'Merk',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 150,
        renderer: (rij) => rij.merknaam,
      },
      {
        key: EKolom.Type,
        label: 'Type',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 175,
        renderer: (rij) => rij.typenaam,
      },
      {
        key: EKolom.Productsoort,
        label: 'Productsoort',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 200,
        renderer: (rij) => rij.productsoortnaam,
      },
      {
        key: EKolom.DatumGeactiveerd,
        label: 'Datum geactiveerd',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 200,
        renderer: (rij) => format(new Date(rij.datumGeactiveerd), 'dd-MM-yyyy'),
        vergelijkingswaarde: (rij) => rij.datumGeactiveerd,
      },
      {
        key: EKolom.Aanschafwaarde,
        label: 'Aanschafwaarde',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 150,
        renderer: (rij) => <FormatteerBedrag bedrag={rij.aanschafwaarde} />,
      },
      {
        key: EKolom.Boekstuknummers,
        label: 'Boekstuknummers',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 135,
        renderer: (rij) => rij.boekstuknummers.join(', '),
      },
      {
        key: EKolom.RecordToegevoegd,
        label: 'Vastgelegd op',
        breedteType: EAspKolomBreedteType.Flex,
        flex: 1,
        renderer: (rij) => format(new Date(rij.recordToegevoegd), 'dd-MM-yyyy HH:mm:ss'),
      },
    ],
    [],
  );

  const [sortering, setSortering] = useState<IAspKolomSorteringItem<EKolom>[]>([
    {
      key: EKolom.DatumGeactiveerd,
      sortering: ESortering.Ascending,
    },
    {
      key: EKolom.RecordToegevoegd,
      sortering: ESortering.Ascending,
    },
    // {
    //   key: EKolom.Merk,
    //   sortering: ESortering.Ascending,
    // },
    // {
    //   key: EKolom.Type,
    //   sortering: ESortering.Ascending,
    // },
  ]);

  return (
    <Root>
      {lijstActivaregister.state === ERemoteDataState.Pending ? (
        <div className="flex-fill d-flex align-items-center justify-content-center">
          <LoadingSpinner />
        </div>
      ) : (
        <ASPTabel
          rijen={lijstActivaregister.data!.regels}
          kolommen={kolommen}
          keyExtractor={keyExtractor}
          sortering={sortering}
          onSorteringChange={setSortering}
          lokaalSorteren
        />
      )}
    </Root>
  );
};

export default LijstActivaregister;
