import React from 'react';
import TableData from '../TableData';
import VinkVeld from '../../../../formulier/VinkVeld';

export interface ISelectieTableDataProps<TRow> {
  row?: TRow;
  isGefocust?: boolean;
  isGehovered?: boolean;
  heeftUitgeklapteRijOnderZich: boolean;
  isGeselecteerd: boolean;
  onIsGeselecteerdChange: (isGeselecteerd: boolean) => void;
  magSelecteren: boolean;
  rowIndex: number;
}

const StandaardSelectieTableData = <TRow extends any>(props: ISelectieTableDataProps<TRow>) => {
  return (
    <TableData
      tableKey={null}
      heeftUitgeklapteRijOnderZich={props.heeftUitgeklapteRijOnderZich}
      style={{ padding: 0, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      isGehovered={props.isGehovered}
      isGefocust={props.isGefocust}
      rowIndex={props.rowIndex}
    >
      <VinkVeld
        aangevinkt={props.isGeselecteerd}
        onGewijzigd={() => props.onIsGeselecteerdChange(!props.isGeselecteerd)}
        disabled={!props.magSelecteren}
      />
    </TableData>
  );
};

export default StandaardSelectieTableData;
