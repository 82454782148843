import {
  IExporterenParams,
  IExporterenResult,
} from '../../../../../../shared/src/api/v2/magazijn/export';
import api from '../../../index';

const exportD = {
  exporteren: async (params: IExporterenParams): Promise<IExporterenResult> => {
    return await api.post('v2/magazijn/export/exporteren', params);
  },
};

export default exportD;
