import React, { useContext, useMemo } from 'react';
import { IProps } from '@tinymce/tinymce-react/lib/cjs/main/ts/components/Editor';
import { IContext, BetalingsregelingContext } from '../index';
import VeldWeergave from '../../../formulier/VeldWeergave';
import LoadingSpinner from '../../../Gedeeld/LoadingSpinner';
import { format } from 'date-fns';
import GegevensLayout from '../../../layout/GegevensLayout';
import FormatteerBedrag from '../../../MutatieBedrag';
import {
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import {
  Grid,
  TableHeaderRow,
  VirtualTable,
  Table,
  TableColumnResizing,
} from '@devexpress/dx-react-grid-bootstrap4';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { IOphalenOpdrachtenResultElement } from '../../../../../../shared/src/api/v2/bank/opdracht/opdracht';

const BoekingTab: React.FC<IProps> = (props) => {
  const context = useContext<IContext>(BetalingsregelingContext);

  const bankopdrachten = context.bankopdrachten!;

  const kolommenBankopdrachten = useMemo<TypedColumn<IOphalenOpdrachtenResultElement>[]>(
    () => [
      {
        name: 'Soort',
        title: 'Soort',
      },
      {
        name: 'Bedrag',
        title: 'Bedrag',
      },
      {
        name: 'Uitvoerdatum',
        title: 'Uitv.datum',
      },
      {
        name: 'IBAN',
        title: 'IBAN',
      },
      // {
      //   name: 'Rekeningnaam',
      //   title: 'Rekeningnaam',
      // },
      {
        name: '__status' as any,
        title: 'Status',
      },
      // {
      //   name: '__batch' as any,
      //   title: 'Batch',
      // },
      // {
      //   name: '__stornodatum' as any,
      //   title: 'Stornodatum',
      // },
      // {
      //   name: '__stornocode' as any,
      //   title: 'Code',
      // },
      // {
      //   name: '__stornoreden' as any,
      //   title: 'Naam',
      // },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IOphalenOpdrachtenResultElement>[]>(
    () => [
      {
        columnName: 'Soort',
        width: 70,
      },
      {
        columnName: '__batch' as any,
        width: 70,
      },
      {
        columnName: 'Bedrag',
        width: 85,
      },
      {
        columnName: 'Uitvoerdatum',
        width: 100,
      },
      {
        columnName: 'IBAN',
        width: 175,
      },
      {
        columnName: 'Rekeningnaam',
        width: 115,
      },
      {
        columnName: '__status',
        width: 200,
      },
      {
        columnName: '__stornodatum' as any,
        width: 120,
      },
      {
        columnName: '__stornocode' as any,
        width: 80,
      },
      {
        columnName: '__stornoreden' as any,
        width: 275,
      },
    ],
    [],
  );

  return (
    <>
      {false ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="form-group"></div>
          <div className="row">
            <div className="col-12 d-flex">
              <div className="d-flex flex-column flex-fill mt-3">
                <div className="col-12">
                  <GridStyleWrapper maxHeight={500} rowAmount={bankopdrachten.length}>
                    <Grid rows={bankopdrachten} columns={kolommenBankopdrachten}>
                      <DataTypeProvider
                        for={['Soort']}
                        formatterComponent={(formatterProps) => {
                          const rij: IOphalenOpdrachtenResultElement = formatterProps.row;

                          return <span>{rij.Bedrag > 0 ? 'Inc.' : 'Bet.'}</span>;
                        }}
                      />

                      <DataTypeProvider
                        for={['__batch']}
                        formatterComponent={(formatterProps) => {
                          const rij: IOphalenOpdrachtenResultElement = formatterProps.row;
                          return rij.batch !== null ? (
                            <span>{rij.batch.Batchnummer}</span>
                          ) : (
                            <span></span>
                          );
                        }}
                      />

                      <DataTypeProvider
                        for={['Bedrag']}
                        formatterComponent={(formatterProps) => {
                          return <FormatteerBedrag bedrag={formatterProps.value} />;
                        }}
                      />

                      <DataTypeProvider
                        for={['Uitvoerdatum']}
                        formatterComponent={(formatterProps) => (
                          <span>
                            {formatterProps.value === null
                              ? null
                              : format(new Date(formatterProps.value), 'dd-MM-yyyy')}
                          </span>
                        )}
                      />

                      <DataTypeProvider
                        for={['__status']}
                        formatterComponent={(formatterProps) => {
                          const rij: IOphalenOpdrachtenResultElement = formatterProps.row;
                          if (rij.batch === null) {
                            return <span>Nog verzamelen</span>;
                          }

                          if (rij.batch.UitgevoerdOp !== null) {
                            return (
                              <span>
                                Uitgevoerd op{' '}
                                {format(new Date(rij.batch.UitgevoerdOp), 'dd-MM-yyyy')}
                              </span>
                            );
                          }

                          if (rij.batch.DatumVerstuurd !== null) {
                            return (
                              <span>
                                Verstuurd op{' '}
                                {format(new Date(rij.batch.DatumVerstuurd!), 'dd-MM-yyyy')}
                              </span>
                            );
                          }
                          return <span>Nog versturen</span>;
                        }}
                      />

                      <DataTypeProvider
                        for={['__stornodatum']}
                        formatterComponent={(props) => {
                          const rij: IOphalenOpdrachtenResultElement = props.row;
                          if (rij.stornomutatie === null) {
                            return <span></span>;
                          }
                          const stornodatum = format(
                            new Date(rij.stornomutatie.Mutatiedatum),
                            'dd-MM-yyyy',
                          );
                          return <span>{stornodatum}</span>;
                        }}
                      />

                      <DataTypeProvider
                        for={['__stornocode']}
                        formatterComponent={(props) => {
                          const rij: IOphalenOpdrachtenResultElement = props.row;
                          if (rij.stornomutatie === null) {
                            return <span></span>;
                          }
                          return <span>{rij.stornomutatie.stornoreden!.Code}</span>;
                        }}
                      />

                      <DataTypeProvider
                        for={['__stornoreden']}
                        formatterComponent={(props) => {
                          const rij: IOphalenOpdrachtenResultElement = props.row;
                          if (
                            rij.stornomutatie === null ||
                            rij.stornomutatie.stornoreden === null
                          ) {
                            return <span></span>;
                          }
                          return <span>{rij.stornomutatie.stornoreden!.Naam}</span>;
                        }}
                      />

                      <VirtualTable
                        // columnExtensions={kolomExtensiesBankopdrachten}
                        messages={{ noData: 'Geen bankopdrachten' }}
                      />
                      <TableColumnResizing defaultColumnWidths={kolomBreedtes} />

                      <TableHeaderRow />
                    </Grid>
                  </GridStyleWrapper>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default BoekingTab;
