import api from '../../index';
import {
  IOphalenOpdrachtwijzenParams,
  IOphalenOpdrachtwijzenResult,
  IOphalenOpdrachtwijzenVoorDienstParams,
  IOphalenOpdrachtwijzenVoorDienstResult,
  IOphalenDiensttypenParams,
  IOphalenDiensttypenResult,
} from '../../../../../shared/src/api/v2/dienst';
import whatsapp from './whatsapp';
import email from './email';
import sms from './sms';
import postcodedata from './postcodedata';
import pendel from './pendel';
import inkoop from './inkoop';
import transport from './transport';
import incasso from './incasso';
import service from './service';
import review from './review';
import magazijn from './magazijn';
import sponsoring from './sponsoring';
import creditmanagement from './creditmanagement';

const dienst = {
  sponsoring,
  magazijn,
  review,
  incasso,
  pendel,
  whatsapp,
  email,
  sms,
  postcodedata,
  inkoop,
  transport,
  service,
  creditmanagement,

  ophalenOpdrachtwijzen: async (
    params: IOphalenOpdrachtwijzenParams,
  ): Promise<IOphalenOpdrachtwijzenResult> => {
    return await api.post('v2/dienst/ophalen-opdrachtwijzen', params);
  },
  ophalenOpdrachtwijzenVoorDienst: async (
    params: IOphalenOpdrachtwijzenVoorDienstParams,
  ): Promise<IOphalenOpdrachtwijzenVoorDienstResult> => {
    return await api.post('v2/dienst/ophalen-opdrachtwijzen-voor-dienst', params);
  },
  ophalenDiensttypen: async (
    params: IOphalenDiensttypenParams,
  ): Promise<IOphalenDiensttypenResult> => {
    return await api.post('v2/dienst/ophalen-diensttypen', params);
  },
};

export default dienst;
