import api from '../../../index';
import {
  IInlezenTransportOpdrachtenParams,
  IInlezenTransportOpdrachtenResult,
  IOphalenGekoppeldeTransportopdrachtenParams,
  IOphalenGekoppeldeTransportopdrachtenResult,
} from '../../../../../../shared/src/api/v2/inkoopfactuur/transport';

const transport = {
  inlezenTransportopdrachten: async (
    params: IInlezenTransportOpdrachtenParams,
  ): Promise<IInlezenTransportOpdrachtenResult> => {
    return await api.post('/v2/inkoopfactuur/transport/inlezen-transportopdrachten', params);
  },
  ophalenGekoppeldeTransportopdrachten: async (
    params: IOphalenGekoppeldeTransportopdrachtenParams,
  ): Promise<IOphalenGekoppeldeTransportopdrachtenResult> => {
    return await api.post(
      '/v2/inkoopfactuur/transport/ophalen-gekoppelde-transportopdrachten',
      params,
    );
  },
};

export default transport;
