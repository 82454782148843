import React from 'react';
import LoadingSpinner from '../Gedeeld/LoadingSpinner';
import styled from 'styled-components';

const Root = styled.div`
  position: relative;
`;

interface IProps {
  isBezig: boolean;
  element: JSX.Element;
  rootStyle?: React.CSSProperties;
  isBezigStyle?: React.CSSProperties;
}

const IsBezigOverlay = (props: IProps) => {
  return (
    <Root style={props.rootStyle}>
      {props.element}
      {props.isBezig && (
        <div
          style={{
            zIndex: 500,
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            cursor: `not-allowed`,
            backgroundColor: 'rgba(50, 50, 50, 0.2)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            ...props.isBezigStyle,
          }}
        >
          <LoadingSpinner />
        </div>
      )}
    </Root>
  );
};

export default IsBezigOverlay;
