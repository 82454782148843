import { RouteComponentProps, withRouter } from 'react-router';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import useUrlState from '../../../core/useUrlState';
import Tabblad, { ITabblad } from '../../../components/layout/Tabblad';
import Producten from './Producten';
import Productmerken from './Productmerken';
import { Root } from './style';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../stores/RootStore';
import { Route, Redirect, matchPath } from 'react-router-dom';

export enum ETabblad {
  Producten = 1,
  Productmerken = 2,
}

interface IUrlState {
  tabblad: ETabblad;
}

const defaultUrlState: IUrlState = {
  tabblad: ETabblad.Producten,
};

const urlTabbladMap: Record<ETabblad, string> = {
  [ETabblad.Producten]: 'producten',
  [ETabblad.Productmerken]: 'productmerken',
};

interface IProps extends RouteComponentProps {}

export interface ITabbladProps {}

const Aanbod: React.FC<IProps> = observer((props) => {
  const { instellingStore } = useContext(RootStoreContext);

  const tabbladen = useMemo<ITabblad<ETabblad>[]>(
    () => [
      {
        id: ETabblad.Producten,
        label: 'Producten',
        content: Producten,
      },
      {
        id: ETabblad.Productmerken,
        label: 'Productmerken',
        content: Productmerken,
      },
    ],
    [],
  );

  const tabblad = useMemo<ETabblad | null>(() => {
    const paths = Object.keys(urlTabbladMap).map((key) => {
      const tabblad = Number(key) as ETabblad;
      const url = urlTabbladMap[tabblad];
      return {
        tabblad,
        path: `${props.match.path}/${url}`,
      };
    });
    for (const pathObj of paths) {
      const match = matchPath(props.location.pathname, pathObj.path);
      if (match !== null) {
        return pathObj.tabblad;
      }
    }

    return null;
  }, [props.location.pathname]);

  return (
    <>
      <Route
        exact
        path={props.match.path}
        component={() => (
          <Redirect to={`${props.match.path}/${urlTabbladMap[ETabblad.Producten]}`} />
        )}
      />
      {tabblad === null ? (
        <Redirect to={`${props.match.path}/${urlTabbladMap[ETabblad.Producten]}`} />
      ) : (
        <Root>
          <Tabblad
            geselecteerd={tabblad}
            onSelectieChange={(identifier) =>
              props.history.push(`${props.match.path}/${urlTabbladMap[identifier]}`)
            }
            tabbladen={tabbladen}
          />
        </Root>
      )}
    </>
  );
});

export default Aanbod;
