import { RootStore } from './RootStore';
import { action, makeObservable, observable } from 'mobx';

class RealtimeStore {
  public isConnected: boolean = false;
  public negerenNietVerbonden: boolean = false;
  // tslint:disable-next-line:variable-name
  private _rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this._rootStore = rootStore;

    makeObservable(this, {
      setConnected: action,
      isConnected: observable,
      negerenNietVerbonden: observable,
    });
  }

  public setConnected = (connected: boolean) => {
    this.isConnected = connected;
  };

  public setNegerenNietVerbonden = (negeren: boolean) => {
    this.negerenNietVerbonden = negeren;
  };
}

export default RealtimeStore;
