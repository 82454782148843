import React, { useCallback, useContext, useMemo, useState } from 'react';
import DienstFormulier, {
  IFormikValues as IDienstFormulierFormikValues,
  validationSchema as dienstFormulierValidationSchema,
} from '../../../DienstFormulier';
import { LeverancierContext } from '../../../../index';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import api from '../../../../../../api';
import { EResultType } from '../../../../../../stores/CheckStore';
import { RootStoreContext } from '../../../../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import { IWijzigenDienstParams } from '../../../../../../../../shared/src/api/v2/dienst/email/email';
import UitklapFormulier from '../../../../../../components/formulier/UitklapFormulier';
import { Kleur } from '../../../../../../bedrijfslogica/constanten';
import HorizontaleScheidingslijn from '../../../../../../components/layout/HorizontaleScheidingslijn';
import * as Yup from 'yup';

interface IFormikValues extends IDienstFormulierFormikValues {}

export const veldnamen: Partial<Record<keyof IFormikValues, string>> = {};

interface IProps {}

const Dienst: React.FC<IProps> = observer((props) => {
  const { checkStore } = useContext(RootStoreContext);
  const context = useContext(LeverancierContext);
  const emaildienst = context.emaildienst.data!;
  const [dienstUitgeklapt, setDienstUitgeklapt] = useState(false);
  const [emailUitgeklapt, setEmailUitgeklapt] = useState(true);

  const initialValues = useMemo<IFormikValues>(() => {
    return {
      actief: emaildienst.Actief,
      naam: emaildienst.Naam || '',
      naamEnum: emaildienst.NaamEnum ?? '',
      opdWijzeID: emaildienst.OpdWijzeID,
      parameters: emaildienst.Parameters || '',
    };
  }, [emaildienst]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);
      const params: IWijzigenDienstParams = {
        relID: context.relID!,
        parameters: values.parameters === '' ? null : values.parameters,
        opdWijzeID: values.opdWijzeID,
        naam: values.naam,
        naamEnum: values.naamEnum,
        actief: values.actief,

        emailDienstID: emaildienst.EmailDienstID,
      };

      const checkData = await api.v2.dienst.email.checkWijzigenDienst(params);
      if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
        actions.setSubmitting(false);
        return;
      }

      await api.v2.dienst.email.wijzigenDienst(params);
      context.onVerversenSmsdienstAangevraagd();
      actions.setSubmitting(false);
    },
    [context.relID, emaildienst, context.onVerversenSmsdienstAangevraagd],
  );

  const validationSchema = useMemo(
    () => dienstFormulierValidationSchema,
    // dienstFormulierValidationSchema.concat(
    //   Yup.object().shape({
    //     tijdUiterlijkOpdracht: Yup.object().shape({
    //       resultaat: Yup.string().required(),
    //     }),
    //     emailOpdracht: Yup.string().required(),
    //     emailOpdrachtCC: Yup.string().required(),
    //     levertermijn: Yup.number().required(),
    //   }),
    // ),
    [],
  );

  return (
    <div className="d-flex flex-column flex-fill">
      <Formik
        enableReinitialize
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
        render={(formikProps: FormikProps<IFormikValues>) => {
          const { submitForm, isSubmitting, isValid, resetForm, dirty } = formikProps;
          return (
            <>
              <div className="d-flex flex-column flex-fill p-3">
                <UitklapFormulier
                  label="Dienst"
                  uitgeklapt={dienstUitgeklapt}
                  onUitgeklaptChange={setDienstUitgeklapt}
                  formulier={<DienstFormulier />}
                />
                <div className="p-2" />
                <UitklapFormulier
                  label="Email"
                  uitgeklapt={emailUitgeklapt}
                  onUitgeklaptChange={setEmailUitgeklapt}
                  formulier={<div className="p-3"></div>}
                />
              </div>
              <HorizontaleScheidingslijn color={Kleur.LichtGrijs} />
              <div className="d-flex p-3">
                <button
                  className="btn btn-sm btn-primary d-flex align-items-center justify-content-center"
                  style={{ width: 100 }}
                  disabled={!isValid || isSubmitting}
                  onClick={submitForm}
                >
                  Ok
                </button>
                <button
                  className="btn btn-sm btn-secondary d-flex align-items-center justify-content-center ml-2"
                  style={{ width: 100 }}
                  disabled={!dirty || isSubmitting}
                  onClick={() => resetForm()}
                >
                  Annuleren
                </button>
              </div>
            </>
          );
        }}
      />
    </div>
  );
});

export default Dienst;
