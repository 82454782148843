import {
  IBevestigenNotificatieParams,
  IBevestigenNotificatieResult,
  IOphalenNotificatieGroepenParams,
  IOphalenNotificatieGroepenResult,
  IOphalenNotificatiesParams,
  IOphalenNotificatiesResult,
} from '../../../../shared/src/api/v2/notificaties/notificaties';
import api from '../index';

const notificatie = {
  ophalenNotificaties: async (
    params: IOphalenNotificatiesParams,
  ): Promise<IOphalenNotificatiesResult> => {
    return await api.post('v2/notificatie/ophalen-notificaties', params);
  },
  ophalenNotificatieGroepen: async (
    params: IOphalenNotificatieGroepenParams,
  ): Promise<IOphalenNotificatieGroepenResult> => {
    return await api.post('v2/notificatie/ophalen-notificatie-groepen', params);
  },
  bevestigenNotificatie: async (
    params: IBevestigenNotificatieParams,
  ): Promise<IBevestigenNotificatieResult> => {
    return await api.post('v2/notificatie/bevestigen-notificatie', params);
  },
};

export default notificatie;
