import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import IDialoogProps from '../../../../../../../../../core/IDialoogProps';
import Dialoog from '../../../../../../../../../components/dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import LoadingSpinner from '../../../../../../../../../components/Gedeeld/LoadingSpinner';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import * as Yup from 'yup';
import api from '../../../../../../../../../api';
import nameOf from '../../../../../../../../../core/nameOf';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../../../../../../../stores/RootStore';
import {
  IOphalenComplexenResultElement,
  IWijzigenComplexParams,
} from '../../../../../../../../../../../shared/src/api/v2/relatie/complex';
import { EResultType } from '../../../../../../../../../stores/CheckStore';

interface IProps extends IDialoogProps<null> {
  id: number;
}

interface IFormikValues {
  naam: string;
  omschrijving: string;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  naam: 'Naam',
  omschrijving: 'Omschrijving',
};

const WijzigenDialoog: React.FC<IProps> = observer((props) => {
  const { dialoogIndex, onAnnuleren, onSuccess } = props;
  const { checkStore } = useContext(RootStoreContext);
  const formikRef = useRef<Formik<IFormikValues>>(null);

  const [complex, setComplex] = useState<IOphalenComplexenResultElement | null>(null);

  const ophalenComplex = useCallback(async () => {
    const result = await api.v2.relatie.complex.ophalenComplexen({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: [props.id],
          },
        ],
      },
    });

    setComplex(result.complexen[0]);
  }, [props.id]);

  useEffect(() => {
    ophalenComplex();
  }, [ophalenComplex]);

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (complex === null) {
      return null;
    }
    return {
      naam: complex.Naam,
      omschrijving: complex.Omschrijving ?? '',
    };
  }, [complex]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      if (complex === null) {
        return;
      }
      actions.setSubmitting(true);

      const params: IWijzigenComplexParams = {
        id: props.id,
        naam: values.naam,
        omschrijving: values.omschrijving !== '' ? values.omschrijving : null,
      };

      const checkData = await api.v2.relatie.complex.checkWijzigenComplex(params);

      const controlerenResult = await checkStore.controleren({
        checkData,
      });
      if (controlerenResult.type === EResultType.Annuleren) {
        return;
      }

      await api.v2.relatie.complex.wijzigenComplex(params);

      actions.setSubmitting(false);
      onSuccess(null);
    },
    [complex],
  );

  const validationSchema = useMemo(() => {
    const fields: Partial<Record<keyof IFormikValues, any>> = {
      naam: Yup.string().required(),
    };
    return Yup.object().shape(fields);
  }, []);

  return (
    <Dialoog index={dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Wijzigen complex</ModalTitle>
      </ModalHeader>
      {initialValues === null ? (
        <div className="d-flex flex-fill align-items-center justify-content-center p-5">
          <LoadingSpinner />
        </div>
      ) : (
        <Formik<IFormikValues>
          ref={formikRef}
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
          render={(formikProps: FormikProps<IFormikValues>) => {
            const { submitForm, isSubmitting, isValid } = formikProps;
            return (
              <>
                <ModalBody>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-12">
                        <label>{veldnamen.naam}</label>
                        <Field
                          name={nameOf<IFormikValues>('naam')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            return (
                              <input type="text" className="form-control" {...fieldProps.field} />
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <label>{veldnamen.omschrijving}</label>
                        <Field
                          name={nameOf<IFormikValues>('omschrijving')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            return (
                              <input type="text" className="form-control" {...fieldProps.field} />
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </ModalBody>

                <ModalFooter className="d-flex flex-row justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    style={{
                      width: 100,
                    }}
                    disabled={isSubmitting || !isValid}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={onAnnuleren}
                    style={{
                      width: 100,
                    }}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Dialoog>
  );
});

export default WijzigenDialoog;
