import api from '../..';
// import {
//   IOphalenFacturenParams,
//   IOphalenFacturenResult,
//   ICheckVerwijderenFacturenParams,
//   ICheckVerwijderenFacturenResult,
//   IVerwijderenFacturenParams,
//   IVerwijderenFacturenResult,
// } from '../../../../../shared/src/api/v2/debiteur/factuur/factuur';
import {
  IOphalenBetaaldagenParams,
  IOphalenBetaaldagenResult,
  IOphalenDebiteurenParams,
  IOphalenDebiteurenResult,
  IToevoegenRekeningParams,
  IToevoegenRekeningResult,
  IVerwijderenRekeningParams,
  IVerwijderenRekeningResult,
  IWijzigenRekeningParams,
  IWijzigenRekeningResult,
  IOphalenFactuurniveausResult,
  IOphalenFinancieleInformatieParams,
  IOphalenFinancieleInformatieResult,
  IBepaalStufidatumParams,
  IBepaalStufidatumResult,
  IOphalenDebiteurenBetaalgedragParams,
  IOphalenDebiteurenBetaalgedragResult,
} from '../../../../../shared/src/api/v2/debiteur';
import { OphalenBetaalWijzenResult } from '../../../../../shared/src/api/v2/debiteur/betaalwijzen';
import ICheckData from '../../../../../shared/src/models/ICheckData';
import incassozaak from './incassozaak';
import herincassozaak from './herincassozaak';
import rechtzaak from './rechtzaak';
import betalingsregeling from './betalingsregeling';
import overzicht from './overzicht';
import stornering from './stornering';
import verrekening from './verrekening';
import boeking from './boeking';

const debiteur = {
  boeking,
  incassozaak,
  herincassozaak,
  rechtzaak,
  betalingsregeling,
  overzicht,
  stornering,
  verrekening,
  wijzigenRekening: async (params: IWijzigenRekeningParams): Promise<IWijzigenRekeningResult> => {
    return await api.post('/v2/debiteur/verwijderen-rekening');
  },

  checkVerwijderenRekening: async (params: IVerwijderenRekeningParams): Promise<ICheckData> => {
    return await api.post('/v2/debiteur/check-verwijderen-rekening');
  },
  verwijderenRekening: async (
    params: IVerwijderenRekeningParams,
  ): Promise<IVerwijderenRekeningResult> => {
    return await api.post('/v2/debiteur/verwijderen-rekening');
  },
  toevoegenRekening: async (
    params: IToevoegenRekeningParams,
  ): Promise<IToevoegenRekeningResult> => {
    return await api.post('/v2/debiteur/toevoegen-rekening');
  },
  ophalenBetaaldagen: async (
    params: IOphalenBetaaldagenParams,
  ): Promise<IOphalenBetaaldagenResult> => {
    return await api.post('/v2/debiteur/ophalen-betaaldagen');
  },
  ophalenBetaalWijzen: async (): Promise<OphalenBetaalWijzenResult> => {
    return await api.post('/v2/debiteur/ophalen-betaalwijzen');
  },
  ophalenProlongatieTermijnen: async (): Promise<number[]> => {
    return await api.post('/v2/debiteur/ophalen-prolongatie-termijnen');
  },
  ophalenDebiteuren: async (
    params: IOphalenDebiteurenParams,
  ): Promise<IOphalenDebiteurenResult> => {
    const results = await api.post('/v2/debiteur/ophalen-debiteuren', params);
    return results;
  },
  ophalenFactuurniveaus: async (): Promise<IOphalenFactuurniveausResult> => {
    return await api.post('/v2/debiteur/ophalen-factuurniveaus');
  },
  ophalenFinancieleInformatie: async (
    params: IOphalenFinancieleInformatieParams,
  ): Promise<IOphalenFinancieleInformatieResult> => {
    return await api.post('/v2/debiteur/ophalen-financiele-informatie', params);
  },
  bepaalStufidatum: async (params: IBepaalStufidatumParams): Promise<IBepaalStufidatumResult> => {
    return await api.post('/v2/debiteur/bepaal-stufidatum', params);
  },
  ophalenDebiteurenBetaalgedrag: async (
    params: IOphalenDebiteurenBetaalgedragParams,
  ): Promise<IOphalenDebiteurenBetaalgedragResult> => {
    return await api.post('/v2/debiteur/ophalen-debiteuren-betaalgedrag', params);
  },
};

export default debiteur;
