import { EVerkennerItemType } from './TabelWeergave/types';
import * as React from 'react';
import CheckStore, { IBevestigenResult } from '../../stores/CheckStore';
import { IMapVerkennerItem, IVerkennerItem } from './types';

export const verkennerItemTypeNaarNaamEnumMap: Record<EVerkennerItemType, 'M' | 'B'> = {
  [EVerkennerItemType.Map]: 'M',
  [EVerkennerItemType.Bestand]: 'B',
};

export const naamEnumNaarVerkennerItemTypeMap: Record<'M' | 'B', EVerkennerItemType> = {
  M: EVerkennerItemType.Map,
  B: EVerkennerItemType.Bestand,
};

export const naarVerkennerItemId = (id: number, type: EVerkennerItemType) => {
  const typeNaamEnum = verkennerItemTypeNaarNaamEnumMap[type];
  return `${typeNaamEnum}-${id}`;
};

export const vanVerkennerItemId = (id: string) => {
  const [typeNaamEnum, idStr] = id.split('-') as ['M' | 'B', string];
  const type = naamEnumNaarVerkennerItemTypeMap[typeNaamEnum];
  return {
    id: parseInt(idStr, 10),
    type,
  };
};

export const toonBevestigVerplaatsenVerkennerItemNaarMap = async (
  checkStore: CheckStore,
  verkennerItem: IVerkennerItem,
  naarMap: IMapVerkennerItem | null,
): Promise<IBevestigenResult> => {
  const droppedItemType = verkennerItem.type === EVerkennerItemType.Map ? 'map' : 'bestand';
  const droppedItemNaam =
    verkennerItem.type === EVerkennerItemType.Map
      ? verkennerItem.map.Naam
      : verkennerItem.bestand.Naam;

  return await checkStore.bevestigen({
    inhoud: (
      <div>
        Bevestig verplaatsen&nbsp;{droppedItemType}&nbsp;
        <strong>{droppedItemNaam}</strong>&nbsp;naar&nbsp;
        <strong>{naarMap?.map.Naam ?? 'Drive'}</strong>
      </div>
    ),
  });
};
