import React, { useCallback, useContext, useEffect, useMemo, useState, useRef } from 'react';
import IDialoogProps from '../../../../../core/IDialoogProps';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { Field, FieldProps, Formik, FormikActions, FormikErrors, FormikProps } from 'formik';
import { RootStoreContext } from '../../../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import api from '../../../../../api';
import { EResultType } from '../../../../../stores/CheckStore';
import * as Yup from 'yup';
import DatumKiezer from '../../../../../components/formulier/DatumKiezer';
import nameof from '../../../../../core/nameOf';
import { addDays, format } from 'date-fns';
import BedragInput from '../../../../../components/formulier/BedragInput';
import RadioKnop from '../../../../../components/formulier/RadioKnop';
import {
  IOphalenRekeningenResultElement,
  IOphalenRelatiesResultElementV2,
} from '../../../../../../../shared/src/api/v2/relatie';
import { dagDatum } from '../../../../../helpers/datum';
import MultiCombobox, { IKolom } from '../../../../../components/formulier/MultiCombobox';
import LoadingSpinner from '../../../../../components/Gedeeld/LoadingSpinner';
import Dialoog from '../../../../../components/dialogen/Dialoog';
import {
  IOpdracht,
  IToevoegenOpdrachtenParams,
} from '../../../../../../../shared/src/api/v2/bank/opdracht/opdracht';

// import { emptyString } from 'react-select/src/utils';

interface IFormikValues {
  uitvoerdatum: Date | null;
  bedrag: number;
  soort: number;
  omschrijving: string | null;
  betalingskenmerk: string | null;
  relRekID: number | null;
}

interface IProps extends IDialoogProps<null> {
  relID?: number;
}

const veldnamen = {
  uitvoerdatum: 'Uitvoerdatum',
  bedrag: 'Bedrag',
  soort: 'Soort',
  omschrijving: 'Omschrijving',
  betalingskenmerk: 'Betalingskenmerk',
  relRekID: 'Rekening',
};

const NieuwBankopdrachtDialoog = observer((props: IProps) => {
  const { onAnnuleren, onSuccess, open, dialoogIndex } = props;

  const { checkStore } = useContext(RootStoreContext);
  const formikRef = useRef<Formik<IFormikValues>>(null);

  const [relatie, setRelatie] = useState<IOphalenRelatiesResultElementV2 | null>(null);

  const ophalenRelatie = useCallback(async () => {
    if (props.relID === undefined) {
      return;
    }
    const { relaties } = await api.v2.relatie.ophalenRelaties({
      filterSchema: { filters: [{ naam: 'IDS', data: [props.relID] }] },
    });

    setRelatie(relaties[0]);
  }, [props.relID]);

  useEffect(() => {
    ophalenRelatie();
  }, [props.relID]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      if (relatie === null) {
        return;
      }

      if (
        values.soort === 2 &&
        (
          await checkStore.bevestigen({
            inhoud: `Let op: je gaat een betaling aanbieden, is dit akkoord?`,
          })
        ).type === EResultType.Annuleren
      ) {
        actions.setSubmitting(false);
        return;
      }

      let opdrachten: IOpdracht[] = [];

      const rekening = relatie.financieel.rekeningen.find((x) => x.RelRekID === values.relRekID)!;

      opdrachten.push({
        bedrag: values.soort === 1 ? values.bedrag : -values.bedrag,
        iban: rekening.IBAN,
        rekeningnaam: rekening.Rekeningnaam,
        omschrijving: values.omschrijving!,
        betalingskenmerk: null,
        relID: props.relID,
        uitvoerdatum: values.uitvoerdatum,
        mandaat: values.soort === 1 ? relatie.Relatienummer : undefined,
      });

      const toevoegenParams: IToevoegenOpdrachtenParams = {
        bankRekID: null,
        uitvoerdatum: null,
        groep: 'D',
        opdrachten,
      };

      const result = await api.v2.bank.opdracht.toevoegenOpdrachten(toevoegenParams);

      onSuccess(null);
      actions.setSubmitting(false);
    },
    [relatie, onSuccess],
  );

  const initieleWaarden = useMemo<IFormikValues | null>(() => {
    if (relatie === null) {
      return null;
    }
    const rekening =
      relatie.financieel.rekeningen.find(
        (x) => x.RelRekID === relatie.financieel.StandaardRekening_RelRekID && !x.NietGebruiken,
      ) || null;

    return {
      uitvoerdatum: dagDatum(addDays(new Date(), 1)),
      bedrag: 0,
      soort: 2,
      omschrijving: '',
      betalingskenmerk: '',
      relRekID: rekening !== null ? rekening.RelRekID : null,
    };
  }, [relatie]);

  const validatieschema = useMemo(() => {
    const fields: Record<keyof IFormikValues, any> = {
      uitvoerdatum: Yup.string().required(),
      bedrag: Yup.number()
        .required()
        .min(0.01),
      soort: Yup.number().required(),
      omschrijving: Yup.string(),
      betalingskenmerk: Yup.string().notRequired(),
      relRekID: Yup.number().required(),
    };
    return Yup.object().shape(fields);
  }, []);

  const rekeningKolommen = useMemo<IKolom<IOphalenRekeningenResultElement>[]>(() => {
    return [
      {
        key: 'IBAN',
        label: 'IBAN',
        breedte: 200,
      },
      {
        key: 'Rekeningnaam' as any,
        label: 'Naam',
        breedte: 200,
      },
    ];
  }, []);

  return (
    <Dialoog index={dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Bankopdracht maken</ModalTitle>
      </ModalHeader>
      {initieleWaarden === null || relatie === null ? (
        <LoadingSpinner />
      ) : (
        <>
          <Formik<IFormikValues>
            ref={formikRef}
            onSubmit={handleSubmit}
            initialValues={initieleWaarden}
            validationSchema={validatieschema}
            validateOnChange
            validateOnBlur
            render={(formikProps: FormikProps<IFormikValues>) => {
              const { submitForm, isSubmitting, values, isValid } = formikProps;
              return (
                <>
                  <ModalBody>
                    <div className="form-group">
                      <div className="row">
                        <div className="col-12 mt-3">
                          <Field
                            name="soort"
                            render={({ field, form }: FieldProps<IFormikValues>) => {
                              return (
                                <div className="d-flex">
                                  <span className="d-flex align-items-center">
                                    <RadioKnop
                                      aangevinkt={field.value === 2}
                                      onAangevinkt={() => form.setFieldValue(field.name, 2)}
                                    />
                                    <span className="ml-2">Betaling</span>
                                  </span>
                                  <span className="d-flex align-items-center ml-3">
                                    <RadioKnop
                                      aangevinkt={field.value === 1}
                                      onAangevinkt={() => form.setFieldValue(field.name, 1)}
                                    />
                                    <span className="ml-2">Incasso</span>
                                  </span>
                                </div>
                              );
                            }}
                          />
                        </div>

                        <div className="col-12 mt-3">
                          <div className="d-flex align-items-center">
                            <div>
                              <label>{veldnamen.bedrag}</label>
                              <Field
                                name={nameof<IFormikValues>('bedrag')}
                                render={(fieldProps: FieldProps<IFormikValues>) => {
                                  const { field, form } = fieldProps;
                                  return (
                                    <BedragInput
                                      value={field.value || 0}
                                      onChange={(x) => {
                                        form.setFieldValue(field.name, x);
                                      }}
                                      min={0}
                                    />
                                  );
                                }}
                              />
                            </div>

                            <div className="ml-3">
                              <label>{veldnamen.uitvoerdatum}</label>
                              <Field
                                name={nameof('uitvoerdatum')}
                                render={({ field, form }: FieldProps<IFormikValues>) => {
                                  const minimaleDatum = addDays(new Date(), -1);
                                  const maximaleDatum = addDays(new Date(), +30);

                                  return (
                                    <div className="d-flex align-items-center">
                                      <DatumKiezer
                                        onGewijzigd={(x) => {
                                          form.setFieldValue(field.name, x);
                                        }}
                                        waarde={field.value}
                                        determineValidDate={(date) => {
                                          return date >= minimaleDatum && date <= maximaleDatum;
                                        }}
                                        determineNextValidDate={(date) => {
                                          const nieuweDatum = addDays(date, +1);
                                          if (nieuweDatum <= maximaleDatum) {
                                            return nieuweDatum;
                                          }
                                          return null;
                                        }}
                                        determinePreviousValidDate={(date) => {
                                          const nieuweDatum = addDays(date, -1);
                                          if (nieuweDatum >= minimaleDatum) {
                                            return nieuweDatum;
                                          }
                                          return null;
                                        }}
                                      />
                                    </div>
                                  );
                                }}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-12 mt-3">
                          <label>{veldnamen.relRekID}</label>
                          <Field
                            name={nameof<IFormikValues>('relRekID')}
                            render={(fieldProps: FieldProps<IFormikValues>) => {
                              const { field, form } = fieldProps;
                              return (
                                <>
                                  <MultiCombobox<number, IOphalenRekeningenResultElement>
                                    sleutelExtractor={(row) => row.RelRekID}
                                    onWaardeChange={(waarde: number | null) => {
                                      form.setFieldValue(field.name, waarde);
                                    }}
                                    representatieFabriek={(rij) =>
                                      `${rij.IBAN} - ${rij.Rekeningnaam}`
                                    }
                                    waarde={field.value}
                                    opties={relatie.financieel.rekeningen.filter(
                                      (x) => !x.NietGebruiken,
                                    )}
                                    kolommen={rekeningKolommen}
                                  />
                                </>
                              );
                            }}
                          />
                        </div>

                        <div className="col-12 mt-2">
                          <label>{veldnamen.omschrijving}</label>
                          <Field
                            name={nameof<IFormikValues>('omschrijving')}
                            render={(fieldProps: FieldProps<IFormikValues>) => {
                              const { field, form } = fieldProps;

                              return <textarea {...field} className="form-control" rows={3} />;
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </ModalBody>
                  <ModalFooter className="d-flex justify-content-start">
                    <button
                      className="btn btn-primary"
                      onClick={submitForm}
                      disabled={isSubmitting || !isValid}
                      style={{ width: 100 }}
                    >
                      Ok
                    </button>
                    <button
                      className="btn btn-secondary"
                      onClick={onAnnuleren}
                      disabled={isSubmitting}
                      style={{ width: 100 }}
                    >
                      Annuleren
                    </button>
                  </ModalFooter>
                </>
              );
            }}
          />
        </>
      )}
    </Dialoog>
  );
});

export default NieuwBankopdrachtDialoog;
