import React, { useCallback, useEffect, useRef } from 'react';
import api from '../../api';

interface IProps {
  aspGebrID: number;
}

const Activiteitsmonitor = (props: IProps): JSX.Element => {
  const opslaanActiviteit = useCallback(async () => {
    await api.v2.activiteitsmonitor.opslaanActiviteit({
      aspGebrID: props.aspGebrID,
    });
  }, [props.aspGebrID]);

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  useEffect(() => {
    const handleActiviteit = () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(opslaanActiviteit, 1000);
    };

    const mousemoveListener = (event: MouseEvent) => {
      handleActiviteit();
    };
    document.addEventListener('mousemove', mousemoveListener);

    const visibilitychangeListener = () => {
      if (document.visibilityState === 'visible') {
        handleActiviteit();
      }
    };
    document.addEventListener('visibilitychange', visibilitychangeListener);

    const focusListener = () => {
      handleActiviteit();
    };

    window.addEventListener('focus', focusListener);

    const keydownListener = () => {
      handleActiviteit();
    };
    document.addEventListener('keydown', keydownListener);

    return () => {
      document.removeEventListener('keydown', keydownListener);
      window.removeEventListener('focus', focusListener);
      document.removeEventListener('visibilitychange', visibilitychangeListener);
      document.removeEventListener('mousemove', mousemoveListener);
    };
  }, [opslaanActiviteit]);

  return <React.Fragment />;
};

export default Activiteitsmonitor;
