import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import Zoeken, { EThema } from '../../../components/formulier/Zoeken';
import { RootStoreContext } from '../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import { Kleur } from '../../../bedrijfslogica/constanten';
import NavigatieItem from './NavigatieItem';
import InactiefOverlay from '../../../components/InactiefOverlay';
import {
  alleNavigatieItemsEnkelNiveau,
  entiteiten,
  INavigatieItem,
  INavigerenParams,
  NavigatieItemKey,
  navigatieItems,
  parentNavigatieItemBijKey,
} from '../shared';
import { RouteComponentProps, withRouter } from 'react-router-dom';

const Root = styled.div`
  min-width: 450px;
  max-width: 450px;
  height: 100vh;
  border-right: 1px solid ${Kleur.HeelLichtGrijs};
  display: flex;
  flex-direction: column;
`;

const ZoekenWrapper = styled.div`
  background: rgb(200, 200, 200);
  border-radius: 3px;
  margin: 20px;
`;

const NavigatieContainer = styled.div`
  flex: 1 1 auto;
  min-height: 0px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  margin-top: 5px;
`;

interface IProps extends RouteComponentProps {}

const bepaalPad = (key: NavigatieItemKey): INavigatieItem[] => {
  const fn = (k: NavigatieItemKey, acc: INavigatieItem[]): INavigatieItem[] => {
    const navItem = parentNavigatieItemBijKey[k];
    if (navItem === undefined) {
      return acc;
    }
    acc.unshift(navItem);
    return fn(navItem.key, acc);
  };

  return fn(key, []);
};

const Links = observer((props: IProps) => {
  const rootStore = useContext(RootStoreContext);
  const { zoekStore } = rootStore;
  const [isFocused, setIsFocused] = useState(false);

  const zoekenRef = useRef<HTMLInputElement>(null);
  const [zoekterm, setZoekterm] = useState('');

  useEffect(() => {
    if (!zoekStore.isOpen) {
      return;
    }
    zoekenRef.current!.focus();
  }, [zoekStore.isOpen]);

  // useEffect(() => {
  //   if (isBezig || isFocused || zoekterm.length > 0) {
  //     return;
  //   }
  //   zoekStore.sluiten();
  // }, [isBezig, isFocused, zoekterm]);

  const { term, losseTermen } = useMemo(() => {
    const z = zoekterm.trim().toLowerCase();
    const losseTermen = z.split(' ');

    return {
      term: z,
      losseTermen,
    };
  }, [zoekterm]);
  const termen = useMemo(() => [term, ...losseTermen], [term, losseTermen]);

  const navigatieItemsGefiltered = useMemo(() => {
    if (term.length === 0) {
      return Object.values(alleNavigatieItemsEnkelNiveau).filter((x) => x.navigeren !== undefined);
    }

    return Object.values(alleNavigatieItemsEnkelNiveau).filter((x) => {
      if (x.navigeren === undefined) {
        return false;
      }

      const pad = bepaalPad(x.key);

      const lowercaseNamen = [x.naam.toLowerCase(), ...pad.map((x) => x.naam.toLowerCase())];

      return (
        lowercaseNamen.some((n) => n.includes(term)) ||
        losseTermen.every((t) => lowercaseNamen.some((n) => n.includes(t)))
      );
    });
  }, [term, losseTermen]);

  // const handleEnter = useCallback(async () => {
  //   const query = zoekterm.trim();
  //   if (query.length === 0) {
  //     return;
  //   }
  //
  //   await zoekStore.zoek(query);
  // }, [zoekterm]);

  const navigerenNaarNavigatieItem = useCallback(
    async (navigatieItem: INavigatieItem) => {
      zoekStore.setIsBezig(true);

      setZoekterm('');
      const params: INavigerenParams = {
        routeProps: props,
        rootStore,
      };
      // Navigeren functie bestaat hier altijd anders
      // wordt deze niet weergegeven als navigatie optie
      await navigatieItem.navigeren!(params);
      zoekStore.sluiten();

      zoekStore.setIsBezig(false);
    },
    [zoekStore.setIsBezig, setZoekterm, props, rootStore],
  );

  const handleEnter = useCallback(async () => {
    if (navigatieItemsGefiltered.length !== 1) {
      return;
    }
    const navigatieItem = navigatieItemsGefiltered[0];
    await navigerenNaarNavigatieItem(navigatieItem);
  }, [navigatieItemsGefiltered, navigerenNaarNavigatieItem]);

  const zoekenDebounceTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const handleInputChange = useCallback(
    (waarde: string) => {
      setZoekterm(waarde);

      if (zoekenDebounceTimeoutRef.current !== null) {
        clearTimeout(zoekenDebounceTimeoutRef.current);
      }
      zoekenDebounceTimeoutRef.current = setTimeout(async () => {
        await zoekStore.zoek(waarde);
      }, 500);
    },
    [setZoekterm],
  );

  return (
    <Root>
      <ZoekenWrapper>
        <InactiefOverlay
          isInactief={zoekStore.isAanHetZoeken}
          element={
            <Zoeken
              ref={zoekenRef}
              input={zoekterm}
              onInputChange={handleInputChange}
              thema={EThema.Donker}
              autoFocus
              placeholder="Zoeken..."
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              onEnter={handleEnter}
            />
          }
        />
      </ZoekenWrapper>
      <NavigatieContainer tabIndex={-1}>
        {navigatieItemsGefiltered.map((navigatieItem, i, arr) => (
          <div
            key={navigatieItem.key}
            style={{
              borderBottom: i === arr.length - 1 ? undefined : `1px solid ${Kleur.LichtGrijs}`,
            }}
          >
            <NavigatieItem
              navigatieItem={navigatieItem}
              termen={termen}
              onClick={async () => {
                await navigerenNaarNavigatieItem(navigatieItem);
              }}
            />
          </div>
        ))}
      </NavigatieContainer>
    </Root>
  );
});

export default withRouter(Links);
