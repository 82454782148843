import React, { useCallback, useMemo, useRef, useState } from 'react';
import TekstPrecisieSelectie, { ETekstPrecisie } from '../../../TekstPrecisieSelectie';
import useBijGewijzigdEffect from '../../../../../core/useBijGewijzigdEffect';
import api from '../../../../../api';
import { IFilterSchemaFilter } from '../../../../../../../shared/src/models/filter';
import { ITekstPrecisieData } from '../../../../../../../shared/src/api/sql';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../../models/IRemoteData';
import {
  IOphalenGrootboekenResult,
  IOphalenGrootboekenResultElement,
} from '../../../../../../../shared/src/api/v2/boekhouding/boeking/grootboek';
import ASPTabel from '../../../../tabel/ASPTabel';
import { ASPKolom, EAspKolomBreedteType } from '../../../../tabel/ASPTabel/types';
import { ITabbladProps } from '../index';
import TableData, { ITableDataProps } from '../../../../tabel/ASPTabel/Body/TableData';

enum ETabelKolom {
  Nummer,
  Naam,
  Omschrijving,
  KiesActie,
}

interface IProps extends ITabbladProps {}

const SelecterenTabblad = (props: IProps) => {
  const [resultaat, setResultaat] = useState<IRemoteData<IOphalenGrootboekenResult>>(
    createPendingRemoteData(),
  );

  const [nummer, setNummer] = useState('');
  const [nummerTekstprecisie, setNummerTekstprecisie] = useState(ETekstPrecisie.StartMet);

  const [naam, setNaam] = useState('');
  const [naamTekstprecisie, setNaamTekstprecisie] = useState(ETekstPrecisie.Ongeveer);

  const [omschrijving, setOmschrijving] = useState('');
  const [omschrijvingTekstprecisie, setOmschrijvingTekstprecisie] = useState(
    ETekstPrecisie.Ongeveer,
  );

  const zoekTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const zoeken = useCallback(async () => {
    const nummerFilter = nummer.trim();
    const naamFilter = naam.trim();
    const omschrijvingFilter = omschrijving.trim();

    const result = await api.v2.boeking.grootboek.ophalenGrootboeken({
      filterSchema: {
        filters: [
          { naam: 'IS_ACTIEF', data: true },
          nummerFilter.length === 0
            ? null
            : {
                naam: 'NUMMER_TEKST_UITGEBREID',
                data: {
                  waarde: nummerFilter,
                  precisie: nummerTekstprecisie,
                } as ITekstPrecisieData,
              },
          naamFilter.length === 0
            ? null
            : {
                naam: 'NAAM_UITGEBREID',
                data: {
                  waarde: naamFilter,
                  precisie: naamTekstprecisie,
                } as ITekstPrecisieData,
              },
          omschrijvingFilter.length === 0
            ? null
            : {
                naam: 'OMSCHRIJVING_UITGEBREID',
                data: {
                  waarde: omschrijvingFilter,
                  precisie: omschrijvingTekstprecisie,
                } as ITekstPrecisieData,
              },
        ].filter((x) => x !== null) as IFilterSchemaFilter[],
      },
    });

    setResultaat(createReadyRemoteData(result));
  }, [
    nummer,
    nummerTekstprecisie,
    naam,
    naamTekstprecisie,
    omschrijving,
    omschrijvingTekstprecisie,
  ]);

  useBijGewijzigdEffect(() => {
    if (zoekTimeoutRef.current) {
      clearTimeout(zoekTimeoutRef.current);
    }
    zoekTimeoutRef.current = setTimeout(zoeken, 500);
  }, [zoeken]);

  const tabelRijen = useMemo<Record<number, IOphalenGrootboekenResultElement> | null>(() => {
    if (resultaat.data === null) {
      return null;
    }

    return resultaat.data.grootboeken.reduce((acc, grootboek, i) => {
      return {
        ...acc,
        [i]: grootboek,
      };
    }, {});
  }, [resultaat.data]);
  const tabelKolommen = useMemo<Array<ASPKolom<ETabelKolom, IOphalenGrootboekenResultElement>>>(
    () => [
      {
        key: ETabelKolom.Nummer,
        label: 'Nummer',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 100,
        renderer: (row) => row.Nummer.toString(),
      },
      {
        key: ETabelKolom.Naam,
        label: 'Naam',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 250,
        renderer: (row) => row.Naam,
      },
      {
        key: ETabelKolom.Omschrijving,
        label: 'Omschrijving',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 350,
        renderer: (row) => row.Omschrijving,
      },
      {
        key: ETabelKolom.KiesActie,
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 95,
        renderer: (row) => (
          <a
            href="#"
            onClick={(ev) => {
              ev.preventDefault();
              props.onGrootboekIDGeselecteerd(row.ID);
            }}
          >
            Kies
          </a>
        ),
      },
    ],
    [props.onGrootboekIDGeselecteerd],
  );

  const TdComponent = useMemo<
    React.ComponentType<ITableDataProps<ETabelKolom, IOphalenGrootboekenResultElement>>
  >(
    () => (rowProps) => {
      return (
        <TableData
          {...rowProps}
          onDoubleClick={
            rowProps.row === undefined
              ? undefined
              : (ev) => {
                  props.onGrootboekIDGeselecteerd(rowProps.row!.ID);
                }
          }
          style={rowProps.row === undefined ? undefined : { cursor: 'pointer' }}
        >
          {rowProps.children}
        </TableData>
      );
    },
    [props.onGrootboekIDGeselecteerd],
  );

  return (
    <div className="d-flex flex-fill flex-column">
      <div className="p-3">
        <div className="row">
          <div className="col-3">
            <label className="d-flex align-items-center justify-content-between">
              <span>Nummer</span>
              <TekstPrecisieSelectie
                precisie={nummerTekstprecisie}
                onPrecisieChange={(precisie) => setNummerTekstprecisie(precisie)}
              />
            </label>
            <input
              className="form-control"
              value={nummer}
              onChange={(ev) => {
                const value = ev.target.value;
                setNummer(value);
              }}
            />
          </div>

          <div className="col-4">
            <label className="d-flex align-items-center justify-content-between">
              <span>Naam</span>
              <TekstPrecisieSelectie
                precisie={naamTekstprecisie}
                onPrecisieChange={(precisie) => setNaamTekstprecisie(precisie)}
              />
            </label>
            <input
              className="form-control"
              value={naam}
              onChange={(ev) => {
                const value = ev.target.value;
                setNaam(value);
              }}
            />
          </div>

          <div className="col-5">
            <label className="d-flex align-items-center justify-content-between">
              <span>Omschrijving</span>
              <TekstPrecisieSelectie
                precisie={omschrijvingTekstprecisie}
                onPrecisieChange={(precisie) => setOmschrijvingTekstprecisie(precisie)}
              />
            </label>
            <input
              className="form-control"
              value={omschrijving}
              onChange={(ev) => {
                const value = ev.target.value;
                setOmschrijving(value);
              }}
            />
          </div>
        </div>
      </div>

      {resultaat.state === ERemoteDataState.Ready && (
        <div style={{ height: 500 }} className="d-flex flex-column">
          <ASPTabel
            keyExtractor={(row) => row.ID}
            rijen={tabelRijen!}
            kolommen={tabelKolommen}
            totaalAantalRijen={resultaat.data!.grootboeken.length}
            tdComponent={TdComponent}
            lokaalSorteren
          />
        </div>
      )}
    </div>
  );
};
export default SelecterenTabblad;
