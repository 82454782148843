import React from 'react';
import Combobox from '../../../../components/formulier/Combobox';
import { VergelijkingspuntWaardeVoorMuteren } from '../../../../../../shared/src/api/v2/concurrent/vergelijkingspunt';

interface IProps {
  waarde: VergelijkingspuntWaardeVoorMuteren;
  onChange: (waarde: VergelijkingspuntWaardeVoorMuteren) => void;
}

const WaardeTypeSelectie = (props: IProps) => {
  const type = props.waarde.type;

  return (
    <Combobox<VergelijkingspuntWaardeVoorMuteren['type']>
      geselecteerd={type}
      onSelectieChange={(optie) => {
        const type = optie!;

        let waarde: VergelijkingspuntWaardeVoorMuteren;
        switch (type) {
          case 'niet_van_toepassing':
            waarde = {
              type: 'niet_van_toepassing',
            };
            break;
          case 'getal':
            waarde = {
              type: 'getal',
              waarde: 0,
            };
            break;
          case 'tekst':
            waarde = {
              type: 'tekst',
              tekstID: null,
              taalTeksten: [],
            };
            break;
          case 'ja_nee':
            waarde = {
              type: 'ja_nee',
              waarde: true,
            };
            break;
          case 'bedrag':
            waarde = {
              type: 'bedrag',
              waarde: 0,
            };
            break;
        }

        props.onChange(waarde);
      }}
      opties={[
        {
          id: 'ja_nee',
          label: 'Ja / Nee',
        },
        {
          id: 'tekst',
          label: 'Tekst',
        },
        {
          id: 'bedrag',
          label: 'Bedrag',
        },
        {
          id: 'getal',
          label: 'Getal',
        },
        {
          id: 'niet_van_toepassing',
          label: 'Niet van toepassing',
        },
      ]}
    />
  );
};

export default WaardeTypeSelectie;
