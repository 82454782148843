import styled from 'styled-components';
import { Kleur } from '../../../bedrijfslogica/constanten';

export const Root = styled.div`
  background-color: ${Kleur.Wit};
  border: 1px solid ${Kleur.LichtGrijs};
  //border-radius: 10px;
  font-size: 13px;
  display: flex;
  align-items: center;
  margin: 5px;
  padding: 2px 2px;
`;

export const Weergave = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
`;

export const ActieContainer = styled.div`
  margin-left: 3px;
  //border-radius: 0 15px 15px 0;
  display: flex;
  align-items: center;
  //background-color: ${Kleur.HeelLichtGrijs};
`;
