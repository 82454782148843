import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import IDialoogProps from '../../../../../../../core/IDialoogProps';
import { observer } from 'mobx-react-lite';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import { Field, FieldProps, Formik, FormikActions, FormikErrors, FormikProps } from 'formik';
import { ModalBody, ModalFooter } from 'react-bootstrap';
import nameof from '../../../../../../../core/nameOf';
import api from '../../../../../../../api';
import { RootStoreContext } from '../../../../../../../stores/RootStore';
import { EResultType } from '../../../../../../../stores/CheckStore';
import LoadingSpinner from '../../../../../../../components/Gedeeld/LoadingSpinner';
import Dialoog from '../../../../../../../components/dialogen/Dialoog';
import VinkVeld from '../../../../../../../components/formulier/VinkVeld';

interface IProps extends IDialoogProps<{}> {
  relID: number;
  prodIDs: number[];
}

interface IFormikValues {
  directVersturen: boolean;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  directVersturen: 'Het productadvies direct versturen',
};

const ToevoegenAdviesDialoog = observer((props: IProps) => {
  const { onAnnuleren, onSuccess, open } = props;
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const { checkStore } = useContext(RootStoreContext);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      if (
        (
          await checkStore.bevestigen({
            inhoud: `Adviesbericht toevoegen?`,
          })
        ).type === EResultType.Annuleren
      ) {
        actions.setSubmitting(false);
        return;
      }

      const params = { relID: props.relID, prodIDs: props.prodIDs };

      const result = await api.v2.product.advies.toevoegenProductAdvies(params);

      if (values.directVersturen) {
        await api.v2.product.advies.versturenProductAdviezen({
          ids: [result.ID],
        });
      }

      onSuccess({});
      actions.setSubmitting(false);
    },
    [onSuccess, props],
  );

  const initieleWaarden = useMemo<IFormikValues | null>(() => {
    return {
      directVersturen: false,
    };
  }, []);

  return (
    <Dialoog index={props.dialoogIndex || 0}>
      {false ? (
        <LoadingSpinner />
      ) : (
        <>
          <ModalHeader>
            <ModalTitle>Toevoegen productadvies</ModalTitle>
          </ModalHeader>
          <Formik<IFormikValues>
            onSubmit={handleSubmit}
            initialValues={initieleWaarden!}
            isInitialValid
            // validationSchema={validationSchema}
            render={(formikProps: FormikProps<IFormikValues>) => {
              const { submitForm, isSubmitting, values, isValid } = formikProps;
              return (
                <>
                  <ModalBody>
                    <div className="form-group">
                      <div className="row">
                        <div className="col-12 d-flex">
                          <Field
                            name={nameof<IFormikValues>('directVersturen')}
                            render={(fieldProps: FieldProps<IFormikValues>) => {
                              const { field, form } = fieldProps;
                              return (
                                <VinkVeld
                                  aangevinkt={field.value}
                                  onGewijzigd={(x) => {
                                    form.setFieldValue(field.name, x);
                                  }}
                                />
                              );
                            }}
                          />
                          <label className="ml-3">{veldnamen.directVersturen}</label>
                        </div>
                      </div>
                    </div>
                  </ModalBody>
                  <ModalFooter className="d-flex justify-content-start">
                    <button
                      className="btn btn-primary"
                      onClick={submitForm}
                      disabled={isSubmitting || !isValid}
                      style={{ width: 100 }}
                    >
                      Ok
                    </button>
                    <button
                      className="btn btn-secondary"
                      onClick={onAnnuleren}
                      style={{ width: 100 }}
                    >
                      Annuleren
                    </button>
                  </ModalFooter>
                </>
              );
            }}
          />
        </>
      )}
    </Dialoog>
  );
});

export default ToevoegenAdviesDialoog;
