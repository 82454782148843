import * as React from 'react';
import { IOphalenPermissiesResultElement } from '../../../../../../../shared/src/api/v2/autorisatie/permissie';
import { IOphalenPermissieXRolsResultElement } from '../../../../../../../shared/src/api/v2/autorisatie/permissieXRol';
import PermissieItem from './PermissieItem';
import { useContext, useMemo } from 'react';
import { EResultType } from '../../../../../stores/CheckStore';
import { RootStoreContext } from '../../../../../stores/RootStore';
import { Kleur } from '../../../../../bedrijfslogica/constanten';

interface IProps {
  permissies: IOphalenPermissiesResultElement[];
  permissieXRols: IOphalenPermissieXRolsResultElement[];
  onGemuteerd: (permissieIDs: number[]) => void;
}

const PermissieWeergave = (props: IProps) => {
  const { checkStore } = useContext(RootStoreContext);

  const permissieGegevenMap = useMemo<Record<number, boolean>>(() => {
    return props.permissies.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.ID]: props.permissieXRols.findIndex((x) => x.PermissieID === curr.ID) !== -1,
      }),
      {},
    );
  }, [props.permissies, props.permissieXRols]);

  return (
    <div className="d-flex flex-column flex-fill p-3">
      <div className="d-flex justify-content-between">
        <h4>Permissies</h4>
        <a
          href="#"
          onClick={async (ev) => {
            ev.preventDefault();

            if (
              (await checkStore.bevestigen({ inhoud: 'Uitpoetsen bevestigen?' })).type ===
              EResultType.Annuleren
            ) {
              return;
            }

            props.onGemuteerd([]);
          }}
        >
          Alles uitpoetsen
        </a>
      </div>
      <div className="d-flex flex-column flex-fill mt-3 pl-2 pr-2" style={{ rowGap: 15 }}>
        {props.permissies.map((permissie, i) => {
          const permissieGegeven = permissieGegevenMap[permissie.ID];
          return (
            <div
              key={permissie.ID}
              style={
                i === props.permissies.length - 1
                  ? undefined
                  : {
                      borderBottom: `2px solid ${Kleur.LichtGrijs}`,
                      paddingBottom: permissie.Omschrijving === null ? 15 : 10,
                    }
              }
            >
              <PermissieItem
                naam={permissie.Naam}
                omschrijving={permissie.Omschrijving ?? undefined}
                gegeven={permissieGegeven}
                onGegevenChange={(gegeven) => {
                  if (gegeven) {
                    props.onGemuteerd([
                      ...props.permissieXRols.map((x) => x.PermissieID),
                      permissie.ID,
                    ]);
                  } else {
                    props.onGemuteerd(
                      props.permissieXRols
                        .map((x) => x.PermissieID)
                        .filter((x) => x !== permissie.ID),
                    );
                  }
                }}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PermissieWeergave;
