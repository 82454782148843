import React, { useCallback, useMemo, useState, useEffect, useContext, useRef } from 'react';
import { Formik, Field, FieldProps, FormikErrors, FormikActions } from 'formik';
import IDialoogProps from '../../../core/IDialoogProps';
import Tabblad, { ITabblad } from '../../layout/Tabblad';
import FactuurTab from './FactuurTab';
import Dialoog from '../../dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import LoadingSpinner from '../../Gedeeld/LoadingSpinner';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalFooter from 'react-bootstrap/ModalFooter';
import styled from 'styled-components';
import api from '../../../api';
import { IOphalenBetaalwijzenResultElement } from '../../../../../shared/src/api/v2/betaalwijze';
import {
  IOphalenFacturenResultElement,
  IWijzigenInkoopfactuurParams,
} from '../../../../../shared/src/api/v2/inkoopfactuur';
import { FactuurContext, FormulierContext, IFormulierContext } from './Context';
import { EResultType } from '../../../stores/CheckStore';
import { RootStoreContext } from '../../../stores/RootStore';
import { dagDatum } from '../../../helpers/datum';
import { AppContext } from '../../../App';
import PDFWeergever from '../../PDFWeergever';

interface IProps extends IDialoogProps<IDialoogResult> {
  inkFactID: number;
}

interface IDialoogResult {}

export interface IFormikValues {
  betWijzeID: number | null;
  koppelenProducten: boolean;
  productenGekoppeld: boolean;
  koppelenSponsorcontracten: boolean;
  sponsorcontractenGekoppeld: boolean;
  koppelenWerkbonnen: boolean;
  werkbonnenGekoppeld: boolean;
  koppelenTransportopdrachten: boolean;
  transportopdrachtenGekoppeld: boolean;
  koppelenRetourProducten: boolean;
  retourProductenGekoppeld: boolean;
  aanhouden: boolean;
  notities: string | null;
  gefiatteerd: boolean;
  geboekt: boolean;
  bedrag: number;
  bedragKorting: number;
  bedragBtw: Number;
  factuurdatum: Date;
  vervaldatum: Date;
  vervaldatumKorting: Date | null;
  credit: boolean;
  factuurnummer: string;
  factuurreferentie: string | null;
  onderwerp: string | null;
}

export const veldnamen: Record<keyof IFormikValues, string> = {
  betWijzeID: 'Betaalwijze',
  koppelenProducten: 'Producten koppelen',
  productenGekoppeld: 'Volledig gekoppeld',
  koppelenSponsorcontracten: 'Sponsorcnt. koppelen',
  sponsorcontractenGekoppeld: 'Volledig gekoppeld',
  koppelenWerkbonnen: 'Werkbonnen koppelen',
  werkbonnenGekoppeld: 'Volledig gekoppeld',
  koppelenTransportopdrachten: 'Transportopdr. koppelen',
  transportopdrachtenGekoppeld: 'Volledig gekoppeld',
  koppelenRetourProducten: 'Ret.producten koppelen',
  retourProductenGekoppeld: 'Volledig gekoppeld',
  aanhouden: 'Betaling aanhouden',
  notities: 'Notities',
  gefiatteerd: 'Gefiatteerd',
  geboekt: 'Geboekt',
  bedrag: 'Factuurbedrag',
  bedragKorting: 'Kortingsbedrag',
  bedragBtw: 'Btw-Bedrag',
  factuurdatum: 'Factuurdatum',
  vervaldatum: 'Vervaldatum',
  vervaldatumKorting: 'Datum voor korting',
  credit: 'Betreft een Credit-factuur',
  factuurnummer: 'Factuurnummer',
  factuurreferentie: 'Factuurreferentie',
  onderwerp: 'Onderwerp',
};

enum ETablad {
  Factuur,
  Detail,
}

const DialoogWrapper = styled.div`
  .modal-dialog {
    max-width: 550px !important;
    border: 4px red;
  }
`;

const WijzigenFactuurDialoog: React.FC<IProps> = (props) => {
  const { onAnnuleren, onSuccess, open, dialoogIndex } = props;
  const { checkStore } = useContext(RootStoreContext);
  const { setGlobalCssValue } = useContext(AppContext);
  const [tabblad, setTabblad] = useState<ETablad>(ETablad.Factuur);
  const [betaalwijzen, setBetaalwijzen] = useState<IOphalenBetaalwijzenResultElement[]>([]);
  const [factuur, setFactuur] = useState<IOphalenFacturenResultElement | null>(null);

  const tabbladen = useMemo<ITabblad<ETablad>[]>(
    () => [
      {
        id: ETablad.Factuur,
        label: 'Factuur',
        content: FactuurTab,
      },
      // {
      //   id: ETablad.Detail,
      //   label: 'Detail',
      //   content: () => <span></span>,
      // },
    ],
    [onSuccess],
  );

  useEffect(() => {
    (async () => {
      const result = await api.v2.betaalwijze.ophalenBetaalwijzen({
        filterSchema: { filters: [] },
      });

      setBetaalwijzen(result.betaalwijzen);
    })();
  }, []);

  const ophalenFacturen = useCallback(async () => {
    const facturenResultaat = await api.v2.inkoopfactuur.ophalenFacturen({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: [props.inkFactID],
          },
        ],
      },
    });
    const factuur = facturenResultaat.facturen[0];

    setFactuur(factuur);
  }, [props.inkFactID]);

  useEffect(() => {
    ophalenFacturen();
  }, [ophalenFacturen]);

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (factuur === null) {
      return null;
    }

    return {
      betWijzeID: factuur.betaalwijze !== null ? factuur.betaalwijze.BetWijzeID : null,
      koppelenProducten: factuur.KoppelenProducten,
      productenGekoppeld: factuur.KoppelenProducten ? factuur.ProductenGekoppeld! : false,
      koppelenSponsorcontracten: factuur.KoppelenSponsorcontracten,
      sponsorcontractenGekoppeld: factuur.KoppelenSponsorcontracten
        ? factuur.SponsorcontractenGekoppeld!
        : false,
      koppelenWerkbonnen: factuur.KoppelenWerkbonnen,
      werkbonnenGekoppeld: factuur.KoppelenWerkbonnen ? factuur.WerkbonnenGekoppeld! : false,
      koppelenTransportopdrachten: factuur.KoppelenTransportopdrachten,
      transportopdrachtenGekoppeld: factuur.KoppelenTransportopdrachten
        ? factuur.TransportopdrachtenGekoppeld!
        : false,
      koppelenRetourProducten: factuur.KoppelenRetourProducten,
      retourProductenGekoppeld: factuur.KoppelenRetourProducten
        ? factuur.RetourProductenGekoppeld!
        : false,
      aanhouden: factuur.Aanhouden,
      notities: factuur.Notities,
      gefiatteerd: factuur.Gefiatteerd,
      geboekt: factuur.Geboekt,
      bedrag: Math.abs(factuur.Bedrag),
      bedragBtw: Math.abs(factuur.BedragBtw),
      bedragKorting: Math.abs(factuur.BedragKorting),
      factuurdatum: dagDatum(new Date(factuur.Factuurdatum)),
      vervaldatum: dagDatum(new Date(factuur.Vervaldatum)),
      vervaldatumKorting:
        factuur.VervaldatumKorting !== null ? dagDatum(new Date(factuur.VervaldatumKorting)) : null,
      credit: factuur.Bedrag < 0,
      factuurnummer: factuur.Factuurnummer,
      factuurreferentie: factuur.Factuurreferentie,
      onderwerp: factuur.Onderwerp,
    };
  }, [factuur]);

  const handleValidate = useCallback((values: IFormikValues) => {
    const errors: FormikErrors<IFormikValues> = {};

    if (values.factuurnummer === '') {
      errors.factuurnummer = `Factuurnummer is verplicht`;
    }
    if (values.betWijzeID === null) {
      errors.betWijzeID = `Betaalwijze is verplicht`;
    }

    return errors;
  }, []);

  const handleSubmit = useCallback(async (values, actions: FormikActions<IFormikValues>) => {
    actions.setSubmitting(true);

    const params: IWijzigenInkoopfactuurParams = {
      inkFactID: props.inkFactID,
      betWijzeID: values.betWijzeID,
      koppelenProducten: values.koppelenProducten,
      productenGekoppeld: values.koppelenProducten ? values.productenGekoppeld : null,
      koppelenSponsorcontracten: values.koppelenSponsorcontracten,
      sponsorcontractenGekoppeld: values.koppelenSponsorcontracten
        ? values.sponsorcontractenGekoppeld
        : null,
      koppelenWerkbonnen: values.koppelenWerkbonnen,
      werkbonnenGekoppeld: values.koppelenWerkbonnen ? values.werkbonnenGekoppeld : null,
      koppelenTransportopdrachten: values.koppelenTransportopdrachten,
      transportopdrachtenGekoppeld: values.koppelenTransportopdrachten
        ? values.transportopdrachtenGekoppeld
        : null,
      koppelenRetourProducten: values.koppelenRetourProducten,
      retourProductenGekoppeld: values.koppelenRetourProducten
        ? values.retourProductenGekoppeld
        : null,
      aanhouden: values.aanhouden,
      notities: values.notities,
      gefiatteerd: values.gefiatteerd,
      geboekt: values.geboekt,
      bedrag: values.bedrag,
      bedragBtw: values.bedragBtw,
      bedragKorting: values.bedragKorting,
      factuurdatum: values.factuurdatum,
      vervaldatum: values.vervaldatum,
      vervaldatumKorting: values.vervaldatumKorting,
      credit: values.credit,
      factuurnummer: values.factuurnummer,
      factuurreferentie: values.factuurreferentie,
      onderwerp: values.onderwerp,
    };

    const checkData = await api.v2.inkoopfactuur.checkWijzigenInkoopfactuur(params);
    const controleResult = await checkStore.controleren({
      checkData,
    });
    if (controleResult.type === EResultType.Annuleren) {
      actions.setSubmitting(false);
      return;
    }
    // if (
    //   (await checkStore.bevestigen({ titel: 'Factuur vastleggen?' })).type === EResultType.Annuleren
    // ) {
    //   return;
    // }
    const result = await api.v2.inkoopfactuur.wijzigenInkoopfactuur(params);

    onSuccess({});
    actions.setSubmitting(false);
  }, []);

  const dialogClassName = useMemo(() => 'wijzigen-factuur-dialoog', []);
  useEffect(() => {
    setGlobalCssValue(
      dialogClassName,
      `
      .${dialogClassName} {
        ${factuur === null || factuur.bestanden.length === 0 ? '' : 'max-width: 1380px !important;'}
      }
    `,
    );
  }, [factuur?.bestanden.length]);

  return (
    <DialoogWrapper>
      <Dialoog
        index={dialoogIndex || 0}
        modalProps={{
          size: 'lg',
          dialogClassName: `wijzigen-factuur-dialoog`,
        }}
      >
        <ModalHeader>
          <ModalTitle>Wijzigen inkoopfactuur</ModalTitle>
        </ModalHeader>
        <FactuurContext.Provider
          value={{
            factuur,
            betaalwijzen,
          }}
        >
          {initialValues === null ? (
            <div className="flex-fill d-flex align-items-center justify-content-center m-5">
              <LoadingSpinner />
            </div>
          ) : (
            <>
              <Formik<IFormikValues>
                // isInitialValid
                initialValues={initialValues}
                validate={handleValidate}
                onSubmit={handleSubmit}
                render={(FormikProps) => {
                  const { submitForm, isSubmitting, errors, values, setFieldValue } = FormikProps;

                  const context: IFormulierContext = {
                    values,
                    setFieldValue,
                  };

                  return (
                    <>
                      <FormulierContext.Provider value={context}>
                        <div className="d-flex">
                          <div
                            style={{
                              minHeight: `400px`,
                            }}
                          >
                            <Tabblad
                              tabbladen={tabbladen}
                              geselecteerd={tabblad}
                              onSelectieChange={(id) => {
                                setTabblad(id);
                              }}
                            />
                          </div>
                          {factuur !== null && factuur.bestanden[0] !== undefined && (
                            <div
                              className="d-flex flex-column"
                              style={{
                                maxWidth: 850,
                                minWidth: 850,
                                minHeight: 500,
                                maxHeight: 800,
                              }}
                            >
                              <PDFWeergever url={factuur.bestanden[0].url} />
                            </div>
                          )}
                        </div>
                        <ModalFooter className="d-flex flex-row justify-content-start">
                          <button
                            className="btn btn-primary"
                            disabled={isSubmitting}
                            onClick={submitForm}
                            style={{ width: 100 }}
                          >
                            Ok
                          </button>
                          <button
                            className="btn btn-secondary"
                            onClick={onAnnuleren}
                            style={{ width: 100 }}
                          >
                            Annuleren
                          </button>
                        </ModalFooter>
                      </FormulierContext.Provider>
                    </>
                  );
                }}
              />
            </>
          )}
        </FactuurContext.Provider>
      </Dialoog>
    </DialoogWrapper>
  );
};

export default WijzigenFactuurDialoog;
