import React, { useCallback, useMemo } from 'react';
// import { Root } from './Opdrachten/style';
import { RouteComponentProps, withRouter } from 'react-router';
import MenuLayout from '../../../../components/MenuLayout';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import useUrlState from '../../../../core/useUrlState';
import api from '../../../../api';
import { IOphalenRelatiesResultElementV1 } from '../../../../../../shared/src/api/v2/relatie';
import { IOphalenMutatiesResultElement } from '../../../../../../shared/src/api/v2/relatie/mutatie';
import {
  DXTableToggleCellComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import { DataTypeProvider, RowDetailState } from '@devexpress/dx-react-grid';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  TableRowDetail,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import { addMonths, format } from 'date-fns';
import nameof from '../../../../core/nameOf';

import { IOphalenContractenResultElementV2 } from '../../../../../../shared/src/api/v2/contract';
import { Kleur, mutatiebronIconMap } from '../../../../bedrijfslogica/constanten';
import RelatieVisualisatie from '../../../../components/personalia/RelatieVisualisatie';
import ContractVisualisatie from '../../../../components/entiteitVisualisaties/ContractVisualisatie';
import { EContractStatus } from '../../../Klant/Klantkaart/Entiteiten/entiteiten/Contracten/Overzicht/ContractTegel/ContractStatus';
import contract from '../../../../api/v2/contract';
import { EMutatiebron } from '../../../../bedrijfslogica/enums';

export interface IRegel extends IOphalenContractenResultElementV2 {}

interface IProps {
  contracten: IRegel[];
}

const Contracten: React.FC<IProps> = (props) => {
  //   const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);

  const contracten = props.contracten;

  const keyExtractor = useCallback((row: IRegel) => row.CntID, []);

  const kolommen = useMemo<TypedColumn<IRegel>[]>(
    () => [
      {
        name: '__contractnummer' as any,
        title: 'Cnt.nr.',
      },
      {
        name: '__mutatiebron' as any,
        title: 'Bron',
      },
      {
        name: 'Contractdatum',
        title: 'Contractdatum',
      },
      {
        name: 'Ingangsdatum',
        title: 'Ingangsdatum',
      },
      {
        name: 'Einddatum',
        title: 'Einddatum',
      },
      {
        name: '__contractstatus' as any,
        title: 'Status',
      },
      {
        name: '__relatie' as any,
        title: 'Relatie',
      },
      {
        name: '__modelnaam' as any,
        title: 'Model',
      },
      {
        name: '__abonnement' as any,
        title: 'Abonnement',
      },
      {
        name: '__actie' as any,
        title: 'Actie',
      },
      {
        name: '__aantalGebruikers' as any,
        title: 'Atl. gebr.',
      },
      {
        name: '__isWissel' as any,
        title: 'Wissel',
      },
      {
        name: '__mutatiereden' as any,
        title: 'Mutatiereden',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRegel>[]>(
    () => [
      {
        columnName: '__contractnummer' as any,
        width: 85,
      },
      {
        columnName: '__mutatiebron' as any,
        width: 60,
      },
      {
        columnName: 'Contractdatum',
        width: 125,
      },
      {
        columnName: 'Ingangsdatum',
        width: 125,
      },
      {
        columnName: 'Einddatum',
        width: 125,
      },
      {
        columnName: '__relatie' as any,
        width: 250,
      },
      {
        columnName: '__modelnaam' as any,
        width: 250,
      },
      {
        columnName: '__aantalGebruikers' as any,
        width: 90,
      },
      {
        columnName: '__contractstatus' as any,
        width: 100,
      },
      {
        columnName: '__abonnement' as any,
        width: 115,
      },
      {
        columnName: '__mutatiereden' as any,
        width: 250,
      },
      {
        columnName: '__actie' as any,
        width: 75,
      },
      {
        columnName: '__isWissel' as any,
        width: 75,
      },
    ],
    [],
  );

  return (
    <>
      {contracten === null ? (
        <LoadingSpinner />
      ) : (
        <MenuLayout
          // menu={<span></span>}
          body={
            <GridStyleWrapper height="calc(100vh - 150px)">
              <Grid rows={contracten} columns={kolommen} getRowId={keyExtractor}>
                <DataTypeProvider
                  for={[
                    nameof<IRegel>('Contractdatum'),
                    nameof<IRegel>('Ingangsdatum'),
                    nameof<IRegel>('Einddatum'),
                  ]}
                  formatterComponent={(formatterProps) => {
                    if (formatterProps.value === null) {
                      return <span></span>;
                    }
                    return <span>{format(new Date(formatterProps.value), 'dd-MM-yyyy')}</span>;
                  }}
                />

                <DataTypeProvider
                  for={['__contractnummer']}
                  formatterComponent={(formatterProps) => {
                    const rij: IRegel = formatterProps.row;

                    return (
                      // <div className="d-flex  align-items-center">
                      //   <div>{rij.basis.Basisnummer + '.' + rij.Volgnummer}</div>
                      // </div>
                      <ContractVisualisatie cntID={rij.CntID} />
                    );
                  }}
                />

                <DataTypeProvider
                  for={['__abonnement']}
                  formatterComponent={(formatterProps) => {
                    const rij: IRegel = formatterProps.row;
                    return <span>{rij.basis.abonnement.NaamKort}</span>;
                  }}
                />

                <DataTypeProvider
                  for={['__actie']}
                  formatterComponent={(formatterProps) => {
                    const rij: IRegel = formatterProps.row;
                    if (rij.basis.actie === null) {
                      return <span></span>;
                    }
                    return <span>Ja</span>;
                  }}
                />

                <DataTypeProvider
                  for={['__isWissel']}
                  formatterComponent={(formatterProps) => {
                    const rij: IRegel = formatterProps.row;
                    if (rij.basis.Contractwissel_CntID !== null) {
                      return <span>Ja</span>;
                    }
                    return <span></span>;
                  }}
                />

                <DataTypeProvider
                  for={['__aantalGebruikers']}
                  formatterComponent={(formatterProps) => {
                    const rij: IRegel = formatterProps.row;
                    return <span>{rij.basis.AantalGebruikers}</span>;
                  }}
                />

                <DataTypeProvider
                  for={['__mutatiereden']}
                  formatterComponent={(formatterProps) => {
                    const rij: IRegel = formatterProps.row;
                    let reden;

                    if (rij.status.NaamEnum === EContractStatus.Geannuleerd) {
                      reden = rij.redenAnnulering !== null ? rij.redenAnnulering.Naam : null;
                    } else {
                      if (rij.status.NaamEnum === EContractStatus.Afgekeurd) {
                        reden = rij.redenAfkeuring !== null ? rij.redenAfkeuring.Naam : null;
                      } else {
                        if (rij.status.NaamEnum === EContractStatus.Beeindigd) {
                          reden = rij.redenBeeindiging !== null ? rij.redenBeeindiging.Naam : null;
                        } else {
                          if (rij.status.NaamEnum === EContractStatus.Overgenomen) {
                            reden = rij.redenOverdracht !== null ? rij.redenOverdracht.Naam : null;
                          }
                        }
                      }
                    }

                    return <span>{reden !== null ? reden : 'Niet opgegeven'}</span>;
                  }}
                />

                {/* <DataTypeProvider
                  for={[nameof<IRegel>('AccID')]}
                  formatterComponent={(formatterProps) => {
                    const rij = formatterProps.row as IRegel;
                    return <span>{rij.AccID !== null ? 'Ja' : ''}</span>;
                  }}
                /> */}

                <DataTypeProvider
                  for={['__mutatiebron']}
                  formatterComponent={(formatterProps) => {
                    const rij: IRegel = formatterProps.row;

                    const bron = rij.AccID !== null ? EMutatiebron.Website : EMutatiebron.Intern;
                    const Icon = mutatiebronIconMap[bron];

                    return (
                      <span>
                        <span>
                          <Icon style={{ width: 18, height: 18, fill: Kleur.Grijs }} />
                        </span>
                      </span>
                    );
                  }}
                />

                <DataTypeProvider
                  for={['__relatie']}
                  formatterComponent={(formatterProps) => {
                    const rij = formatterProps.row as IRegel;
                    return <RelatieVisualisatie relID={rij.RelID} />;
                  }}
                />

                <DataTypeProvider
                  for={['__modelnaam']}
                  formatterComponent={(formatterProps) => {
                    const rij: IRegel = formatterProps.row;

                    return (
                      <div className="d-flex  align-items-center">
                        <div>{rij.basis.productmodel.Modelnaam}</div>
                      </div>
                    );
                  }}
                />

                <DataTypeProvider
                  for={['__contractstatus']}
                  formatterComponent={(formatterProps) => {
                    const rij: IRegel = formatterProps.row;

                    return (
                      <div className="d-flex  align-items-center">
                        <div>{rij.status.Naam}</div>
                      </div>
                    );
                  }}
                />

                <VirtualTable
                  messages={{
                    noData: 'Geen contracten',
                  }}
                />
                <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
                <TableHeaderRow />

                <RowDetailState defaultExpandedRowIds={[]} />
                {/* <TableRowDetail
                  toggleCellComponent={DXTableToggleCellComponent}
                  contentComponent={DetailComp}
                /> */}
              </Grid>
            </GridStyleWrapper>
          }
        />
      )}
    </>
  );
};

export default Contracten;
