import IEmailGeadresseerde from '../../../../shared/src/models/email/IEmailGeadresseerde';

export enum EFilter {
  SjabloonCategorieIds = 'SJAB_CAT_IDS',
  HeeftSjabloonCommunicatiekanaalIds = 'HEEFT_COM_KAN_IDS',
  HeeftTaalIds = 'HEEFT_TAAL_IDS',
  VoldoetAanSjabloonContextIds = 'VOLDOET_AAN_SJAB_CTX_IDS',
}

export enum ETaalSelectieType {
  Opgegeven,
  AutomatischBepalenViaContexten,
}

export interface IOpgegevenTaalSelectie {
  type: ETaalSelectieType.Opgegeven;
  taalID: number;
  isMuteerbaar: boolean;
}

export interface IAutomatischEenduidigBepalenViaContextenTaalSelectie {
  type: ETaalSelectieType.AutomatischBepalenViaContexten;
  isMuteerbaar: boolean;
}

export type TaalSelectie =
  | IOpgegevenTaalSelectie
  | IAutomatischEenduidigBepalenViaContextenTaalSelectie;

export enum ECommunicatiekanaalSelectieType {
  Opgegeven,
}

export interface IOpgegevenCommunicatiekanaalSelectie {
  type: ECommunicatiekanaalSelectieType.Opgegeven;
  kanaalID: number | null;
  isMuteerbaar: boolean;
  magVoorvullen: boolean;
}

export type CommunicatiekanaalSelectie = IOpgegevenCommunicatiekanaalSelectie;

export enum EOpgegevenContextSelectieType {
  NaamEnum,
  SjabCtxID,
}

export interface INaamEnumOpgegevenContextSelectie {
  type: EOpgegevenContextSelectieType.NaamEnum;
  naamEnum: string;
}

export interface ISjabCtxIDOpgegevenContextSelectie {
  type: EOpgegevenContextSelectieType.SjabCtxID;
  sjabCtxID: number;
}

export type OpgegevenContextSelectie =
  | INaamEnumOpgegevenContextSelectie
  | ISjabCtxIDOpgegevenContextSelectie;

export interface IOpgegevenContext {
  selectie: OpgegevenContextSelectie;
  alias?: string;
  data: any;
}

export enum ESjablooncontextNaamEnum {
  Persoon = 'PERSOON',
  Contract = 'CONTRACT',
  Relatie = 'RELATIE',
}

export interface ITaalViaOpgegevenContextenBepalerParams {
  opgegevenContexten: IOpgegevenContext[];
}

export enum ETaalViaOpgegevenContextenBepalerOutputType {
  Bepaald,
  NietBepaald,
}

export interface ITaalViaOpgegevenContextenBepalerBepaaldOutput {
  type: ETaalViaOpgegevenContextenBepalerOutputType.Bepaald;
  taalID: number;
  eenduidigBepaald: boolean;
  // middels: IOpgegevenContext[];
}

export interface ITaalViaOpgegevenContextenBepalerNietBepaaldOutput {
  type: ETaalViaOpgegevenContextenBepalerOutputType.NietBepaald;
}

export type TaalViaOpgegevenContextenBepalerOutput =
  | ITaalViaOpgegevenContextenBepalerBepaaldOutput
  | ITaalViaOpgegevenContextenBepalerNietBepaaldOutput;

export interface ITaalViaOpgegevenContextenBepaler {
  bepaal: (
    params: ITaalViaOpgegevenContextenBepalerParams,
  ) => Promise<TaalViaOpgegevenContextenBepalerOutput>;
}

export enum ESjabloonOplosserModus {
  Genereer,
  Verstuur,
  Selecteer,
}

export interface ISjabloonOplosserGenereerModus {
  type: ESjabloonOplosserModus.Genereer;
}

export enum EVerstuurModusKanaal {
  Email,
  WhatsApp,
  SMS,
}

export interface IEmailVerstuurModus {
  aan: IEmailGeadresseerde[];
  cc: IEmailGeadresseerde[];
  bcc: IEmailGeadresseerde[];
  bijlagen: Array<{ bestandID: number }>;
  contextRelIDs: number[];
}

export interface IWhatsAppVerstuurModus {
  whatsAppSesID: number;
}

export enum ESMSVerstuurModusType {
  Telefoonnummer,
  Contact,
}

export interface ISMSVerstuurModusTelefoonnummer {
  type: ESMSVerstuurModusType.Telefoonnummer;
  persID: number;
  telefoonnummer: string;
}

export interface ISMSVerstuurModusContact {
  type: ESMSVerstuurModusType.Contact;
  smsContactID: number;
}

export type SMSVerstuurModus = ISMSVerstuurModusTelefoonnummer | ISMSVerstuurModusContact;

export type SjabloonOplosserVerstuurModusKanalen = Partial<{
  [EVerstuurModusKanaal.Email]: IEmailVerstuurModus;
  [EVerstuurModusKanaal.WhatsApp]: IWhatsAppVerstuurModus;
  [EVerstuurModusKanaal.SMS]: SMSVerstuurModus;
}>;

export interface ISjabloonOplosserVerstuurModus {
  type: ESjabloonOplosserModus.Verstuur;
  kanalen: SjabloonOplosserVerstuurModusKanalen;
}

interface ISjabloonOplosserSelecteerModus {
  type: ESjabloonOplosserModus.Selecteer;
}

export type SjabloonOplosserModus =
  | ISjabloonOplosserGenereerModus
  | ISjabloonOplosserVerstuurModus
  | ISjabloonOplosserSelecteerModus;
