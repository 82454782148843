import React, { useContext, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import { RootStoreContext } from '../../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import Placeholder from './Placeholder';
import Data from './Data';
import { IOphalenContractenResultElementV2 } from '../../../../../../shared/src/api/v2/contract';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../models/IRemoteData';
import { IOphalenRelatiesResultElementV2 } from '../../../../../../shared/src/api/v2/relatie';

interface IProps {
  cntID: number;
}

const Root = styled.div`
  background: ${Kleur.Wit};
  border: 1px solid ${Kleur.LichtGrijs};
  border-radius: 4px;
  padding: 12px 16px;
  flex: 1;
  width: 650px;
`;

const InfoCard = observer((props: IProps) => {
  const { contractenStoreV2, relatieStore } = useContext(RootStoreContext);
  const contract = useMemo<IOphalenContractenResultElementV2 | null>(() => {
    const pers = contractenStoreV2.contracten[props.cntID];
    if (pers === undefined) {
      return null;
    }
    return pers;
  }, [contractenStoreV2.contracten, props.cntID]);
  useEffect(() => {
    if (contract !== null) {
      return;
    }
    contractenStoreV2.enqueueOphalenContracten([props.cntID]);
  }, [contract, props.cntID]);

  const relatie = useMemo<IRemoteData<IOphalenRelatiesResultElementV2 | null>>(() => {
    if (contract === null) {
      return createPendingRemoteData();
    }
    const relatie = relatieStore.relaties[contract.RelID];
    if (relatie === undefined) {
      return createReadyRemoteData(null);
    }
    return createReadyRemoteData(relatie);
  }, [contract, relatieStore.relaties]);
  useEffect(() => {
    if (contract === null) {
      return;
    }
    if (relatie.state === ERemoteDataState.Pending || relatie.data !== null) {
      return;
    }
    relatieStore.enqueueRelatieOphalen(contract.RelID);
  }, [contract, relatie]);

  return (
    <Root onClick={(ev) => ev.stopPropagation()}>
      {contract === null || relatie.state === ERemoteDataState.Pending || relatie.data === null ? (
        <Placeholder />
      ) : (
        <Data contract={contract} relatie={relatie.data!} />
      )}
    </Root>
  );
});

export default InfoCard;
