import { IOphalenFacturenDossiersResultElement } from '../../../shared/src/api/v2/factuur';

export const sorteerFactuurnummer = (factuurnummer: string) => {
  /**
   * Omdat de factuurnummers in het nieuwe systeem een cijfer erbij krijgen gaan sortering niet zoals gewenst
   * Oude nummers: 21.45342
   * Nieuwe nummers: 21.045342
   * Deze functie zet de oude nummers om naar het formaat van de nieuwe nummers
   */

  const nummer =
    factuurnummer.length === 8
      ? factuurnummer.substring(0, 3) + '0' + factuurnummer.slice(-5)
      : factuurnummer;
  // console.log(nummer);
  const result = nummer;
  return result;
};

export const dossierIndicaties = (dossiers: IOphalenFacturenDossiersResultElement) => {
  let info: string = '';

  const indicatieBetalingsregeling =
    dossiers.betalingsregelingen.filter((x) => !x.Afgehandeld).length > 0;
  const indicatieIncassozaak = dossiers.incassozaken.filter((x) => !x.Afgehandeld).length > 0;
  const indicatieHerincassozaak = dossiers.herincassozaken.filter((x) => !x.Afgehandeld).length > 0;
  const indicatieRechtzaak = dossiers.rechtzaken.filter((x) => !x.Afgehandeld).length > 0;

  info += indicatieBetalingsregeling ? 'BR ' : '';
  info += indicatieIncassozaak ? 'IZ ' : '';
  info += indicatieHerincassozaak ? 'HI ' : '';
  info += indicatieRechtzaak ? 'RZ ' : '';

  const result = info;

  return result;
};
