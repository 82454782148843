import React, { useContext, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import { RootStoreContext } from '../../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import { IOphalenFacturenBasisResultElement } from '../../../../../../shared/src/api/v2/factuur';
import { ERemoteDataState } from '../../../../models/IRemoteData';
import Placeholder from '../InfoCard/Placeholder';
import Data from '../InfoCard/Data';

interface IProps {
  factID: number; // bijv 'persID', nog hernoemen
}

const Root = styled.div`
  background: ${Kleur.Wit};
  border: 1px solid ${Kleur.LichtGrijs};
  border-radius: 4px;
  padding: 12px 16px;
  flex: 1;
`;

const InfoCard = observer((props: IProps) => {
  const { factuurStore } = useContext(RootStoreContext);
  const factuur = useMemo<IOphalenFacturenBasisResultElement | null>(() => {
    if (factuurStore.facturen.state === ERemoteDataState.Pending) {
      return null;
    }
    const fact = factuurStore.facturen.data![props.factID];
    if (fact === undefined) {
      return null;
    }

    return fact;
  }, [factuurStore.facturen, props.factID]);
  useEffect(() => {
    if (factuur !== null) {
      return;
    }
    factuurStore.enqueueOphalenFacturen([props.factID]);
  }, [factuur, props.factID]);

  return (
    <Root onClick={(ev) => ev.stopPropagation()}>
      {factuur === null ? <Placeholder /> : <Data factuur={factuur} />}
    </Root>
  );
});

export default InfoCard;
