import React, { useCallback, useContext, useEffect } from 'react';
import Tegel from '../../../../../../components/tegel/Tegel';
import { RootStoreContext } from '../../../../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import LoadingSpinner from '../../../../../../components/Gedeeld/LoadingSpinner';
import ElementSpacer from '../../../../../../components/layout/ElementSpacer';
import { formatteerPersoonNaam } from '../../../../../../helpers';
import Avatar from '../../../../../../components/personalia/Avatar';
import { ActiesIcon } from '../../../../../../components/Icons';
import { Kleur } from '../../../../../../bedrijfslogica/constanten';
import api from '../../../../../../api';
import AccountTegel from './AccountTegel';
import { IEntiteitProps } from '../../../../../../components/kaart/EntiteitContainer';

interface IProps extends IEntiteitProps {}

const Accounts: React.FC<IProps> = observer((props) => {
  const { accountStore, klantkaartStore } = useContext(RootStoreContext);

  const ophalenAccounts = useCallback(() => {
    accountStore.ophalenAccounts({
      filterSchema: {
        filters: [{ naam: 'REL_IDS', data: [props.relID] }],
      },
    });
  }, [props.relID]);
  useEffect(() => {
    ophalenAccounts();
  }, [ophalenAccounts]);

  return (
    <div className="p-4" style={{ width: '100%' }}>
      {accountStore.accounts === null ? (
        <LoadingSpinner />
      ) : accountStore.accounts.length !== 0 ? (
        <div className="row">
          {/*<ElementSpacer*/}
          {/*  wrapperFactory={() => <div className="col-6" style={{ marginBottom: 10 }} />}*/}
          {/*  lastWrapperFactory={() => <div className="col-6" />}*/}
          {/*>*/}
          {accountStore.accounts.map((account) => {
            return (
              <div key={account.AccID} className="col-12 mb-3">
                <AccountTegel account={account} onVerversenAangevraagd={() => ophalenAccounts()} />
              </div>
            );
          })}
          {/*</ElementSpacer>*/}
        </div>
      ) : (
        <div>
          <h5 className="mt-2" style={{ textAlign: 'center' }}>
            Er zijn geen accounts aanwezig.
          </h5>
          <p style={{ textAlign: 'center' }}>
            Voeg een account toe door bij de persoon menu optie Account toevoegen te kiezen.
          </p>
        </div>
      )}
    </div>
  );
});

export default Accounts;
