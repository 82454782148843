import React, { useState, useEffect, useCallback, useMemo, useContext } from 'react';
import api from '../../../../api';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import useUrlState from '../../../../core/useUrlState';
import {
  ASPKolom,
  EAspKolomBreedteType,
  ESortering,
  IAspKolomSorteringItem,
} from '../../../../components/tabel/ASPTabel/types';
import MenuLayout from '../../../../components/MenuLayout';
import ASPTabel from '../../../../components/tabel/ASPTabel';
import { format } from 'date-fns';
import { IOphalenTransportregelsResultElement as IInventarisatieTransportregel } from '../../../../../../shared/src/api/v2/magazijn/inventarisatie';
import { ITabbladComponentProps } from '..';
import _ from 'lodash';
import { IOphalenProducttypenResultElement } from '../../../../../../shared/src/api/v2/product/type';
import { IOphalenProductenResultElementV2 } from '../../../../../../shared/src/api/v2/product';
import { EKoppelenProductResultType } from '../../../Transport/Opdrachten/reservering/KoppelenProductDialoog';
import { Kleur as EKleur } from '../../../../bedrijfslogica/constanten';

interface IProps extends ITabbladComponentProps, RouteComponentProps {}

enum EKolom {
  Bezoekdatum,
  Producttype,
  Productmerk,
  Referentiecode,
  Nieuw,
  Status,
  IsDefinitief,
  Locatie,
  SoortOpdrachtregel,
}

interface IUrlState {
  selectie: number[];
  sortering: IAspKolomSorteringItem<EKolom>[];
}

const defaultUrlState: IUrlState = {
  selectie: [],
  sortering: [
    { key: EKolom.Bezoekdatum, sortering: ESortering.Ascending },
    {
      key: EKolom.Productmerk,
      sortering: ESortering.Ascending,
    },
    {
      key: EKolom.Producttype,
      sortering: ESortering.Ascending,
    },
  ],
};

interface IRegel extends IInventarisatieTransportregel {
  soort: string;
}

const Transport: React.FC<IProps> = (props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);

  const [regels, setRegels] = useState<IRegel[] | null>(null);
  const [producttypen, setProducttypen] = useState<IOphalenProducttypenResultElement[] | null>(
    null,
  );
  const [producten, setProducten] = useState<IOphalenProductenResultElementV2[] | null>(null);

  const ophalenRegels = useCallback(async () => {
    // Regels zoasls gedefinieerd bij de inventarisatie
    const regelsResult = (
      await api.v2.magazijn.inventarisatie.transport.ophalenTransportregels({
        filterSchema: { filters: [{ naam: 'MAGINV_IDS', data: [props.inventarisatieID] }] },
        // orderSchema: { orders: [{ naam: 'BEZOEKDATUM', richting: 'ASC' }] },
      })
    ).transportregels;

    // Oorspronkelijke transportopdrachtregels
    const opdrachtregelsResult = (
      await api.v2.transport.opdracht.ophalenOpdrachtregelsV2({
        filterSchema: { filters: [{ naam: 'IDS', data: regelsResult.map((x) => x.TrsRegID) }] },
      })
    ).regels;

    const regels = regelsResult.map((regel) => {
      const opdrachtregel = opdrachtregelsResult.find((x) => x.TrsRegID === regel.TrsRegID)!;
      const soort = opdrachtregel.regelsoort.NaamKort;
      return { ...regel, soort };
    });

    setRegels(regels);
  }, []);

  useEffect(() => {
    ophalenRegels();
  }, [ophalenRegels]);

  const ophalenProducttypen = useCallback(async () => {
    if (regels === null) {
      return;
    }

    const result = await api.v2.product.type.ophalenProducttypen({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: _.uniq(regels.filter((x) => x.TypeID !== null).map((x) => x.TypeID)),
          },
        ],
      },
    });

    setProducttypen(result.producttypen);
  }, [regels]);

  useEffect(() => {
    ophalenProducttypen();
  }, [ophalenProducttypen]);

  const ophalenProducten = useCallback(async () => {
    if (regels === null) {
      return;
    }

    const result = await api.v2.product.ophalenProductenV2({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: _.uniq(regels.filter((x) => x.ProdID !== null).map((x) => x.ProdID)),
          },
        ],
      },
    });

    setProducten(result.producten);
  }, [regels]);

  useEffect(() => {
    ophalenProducten();
  }, [ophalenProducten]);

  const keyExtractor = useCallback((item: IRegel) => item.ID, []);

  const kolommen = useMemo<ASPKolom<EKolom, IRegel>[]>(
    () => [
      {
        key: EKolom.Bezoekdatum,
        label: 'Bezoekdatum',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 125,
        renderer: (item: IRegel) => {
          return <span>{format(new Date(item.Bezoekdatum), 'dd-MM-yyyy')}</span>;
        },
      },
      {
        key: EKolom.Productmerk,
        label: 'Merk',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 100,
        renderer: (item: IRegel) => {
          if (producttypen === null || producten === null) {
            return <span />;
          }
          if (item.TypeID !== null) {
            const producttype = producttypen.find((x) => x.TypeID === item.TypeID)!;
            return <span>{producttype.Merknaam}</span>;
          }
          if (item.ProdID !== null) {
            const product = producten.find((x) => x.ProdID === item.ProdID)!;
            return <span>{product.producttype.Merknaam}</span>;
          }
          return <span />;
        },
        vergelijkingswaarde: (item) => {
          if (producttypen === null || producten === null) {
            return null;
          }
          if (item.TypeID !== null) {
            const producttype = producttypen.find((x) => x.TypeID === item.TypeID)!;
            return producttype.Merknaam;
          }
          if (item.ProdID !== null) {
            const product = producten.find((x) => x.ProdID === item.ProdID)!;
            return product.producttype.Merknaam;
          }
          return null;
        },
      },
      {
        key: EKolom.Producttype,
        label: 'Type',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 175,
        renderer: (item: IRegel) => {
          if (producttypen === null || producten === null) {
            return <span />;
          }
          if (item.TypeID !== null) {
            const producttype = producttypen.find((x) => x.TypeID === item.TypeID)!;
            return <span>{producttype.Typenaam}</span>;
          }
          if (item.ProdID !== null) {
            const product = producten.find((x) => x.ProdID === item.ProdID)!;
            return <span>{product.producttype.Typenaam}</span>;
          }
          return <span />;
        },
        vergelijkingswaarde: (item) => {
          if (producttypen === null || producten === null) {
            return null;
          }
          if (item.TypeID !== null) {
            const producttype = producttypen.find((x) => x.TypeID === item.TypeID)!;
            return producttype.Typenaam;
          }
          if (item.ProdID !== null) {
            const product = producten.find((x) => x.ProdID === item.ProdID)!;
            return product.producttype.Typenaam;
          }
          return null;
        },
      },
      {
        key: EKolom.Nieuw,
        label: 'N/G',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 75,
        renderer: (item: IRegel) => {
          return <span>{item.Nieuw ? 'N' : 'G'}</span>;
        },
      },
      {
        key: EKolom.Referentiecode,
        label: 'Referentiecode',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 125,
        renderer: (item: IRegel) => {
          return <span>{item.Referentiecode !== null ? item.Referentiecode : ''}</span>;
        },
      },
      {
        key: EKolom.SoortOpdrachtregel,
        label: 'Srt.',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 80,
        renderer: (item: IRegel) => {
          return <span>{item.soort}</span>;
        },
        vergelijkingswaarde: (item) => {
          return item.soort;
        },
      },
      {
        key: EKolom.Status,
        label: 'Status',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 125,
        renderer: (item: IRegel) => {
          const statusTekst =
            item.Status === 0
              ? 'Niet afgemeld'
              : item.Status === 1
              ? 'Uitgevoerd'
              : item.Status === 2
              ? 'Mislukt'
              : 'Geannuleerd';
          return <span>{statusTekst}</span>;
        },
        vergelijkingswaarde: (item) => {
          return item.Status;
        },
      },
      {
        key: EKolom.Locatie,
        label: 'Locatieadres',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 125,
        renderer: (item: IRegel) => {
          return <span></span>;
        },
      },
    ],
    [producttypen, producten],
  );

  return (
    <>
      <MenuLayout
        body={
          <div className="d-flex flex-column flex-fill">
            {regels === null || producttypen === null ? (
              <div className="flex-fill d-flex align-items-center justify-content-center">
                <LoadingSpinner />
              </div>
            ) : (
              <div className="d-flex flex-column flex-fill">
                <ASPTabel
                  rijen={regels}
                  kolommen={kolommen}
                  keyExtractor={keyExtractor}
                  selectie={urlState.selectie}
                  onSelectieChange={(selectie) => setUrlStateSync('selectie', selectie)}
                  sortering={urlState.sortering}
                  onSorteringChange={(sortering) => setUrlStateSync('sortering', sortering)}
                  // onWijzigenRij={async (x) => {}}
                  // onVerwijderenRij={async (x) => {
                  //   await new Promise((resolve) => setTimeout(resolve, 3000));
                  // }}
                  lokaalSorteren
                />
              </div>
            )}
          </div>
        }
      />
    </>
  );
};

export default withRouter(Transport);
