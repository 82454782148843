import React, { useMemo } from 'react';
import { IRegelCompProps } from '../index';
import RegelBasis from '../RegelBasis';
import { ERegelboekstukType, IRegelboekstukBetalingsregeling } from '../../types';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../../../models/IRemoteData';
import Skeleton from 'react-loading-skeleton';
import FormatteerBedrag from '../../../../../MutatieBedrag';
import styled from 'styled-components';
import { IconPriorityHigh, IconVink } from '../../../../../Icons';
import { Kleur } from '../../../../../../bedrijfslogica/constanten';
import { IOphalenBetalingsregelingenResultElement } from '../../../../../../../../shared/src/api/v2/debiteur/betalingsregeling';

const Omschrijving = styled.div`
  color: #b4b4b4;
  font-size: 15px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const BetalingsregelingRegel = (props: IRegelCompProps) => {
  const betRglID = (props.regel.regelboekstuk as IRegelboekstukBetalingsregeling).betRglID;
  const betalingsregeling = useMemo<IRemoteData<IOphalenBetalingsregelingenResultElement>>(() => {
    const entry = props.betalingsregelingenCache[betRglID];
    if (entry === undefined) {
      return createPendingRemoteData();
    }
    return createReadyRemoteData(entry);
  }, [betRglID, props.betalingsregelingenCache]);

  const reedsVoldaan = useMemo<IRemoteData<number>>(() => {
    if (props.boekstuksaldi.state === ERemoteDataState.Pending) {
      return createPendingRemoteData();
    }
    const boekstuksaldo = props.boekstuksaldi.data!.find(
      (x) => x.type === ERegelboekstukType.Betalingsregeling && x.betRglID === betRglID,
    );
    if (boekstuksaldo === undefined) {
      return createPendingRemoteData();
    }
    return createReadyRemoteData(boekstuksaldo.saldo);
  }, [props.boekstuksaldi, betRglID]);

  const nogVoldoen = useMemo<IRemoteData<number>>(() => {
    if (
      reedsVoldaan.state === ERemoteDataState.Pending ||
      betalingsregeling.state === ERemoteDataState.Pending
    ) {
      return createPendingRemoteData();
    }
    return createReadyRemoteData(betalingsregeling.data!.Totaalbedrag - reedsVoldaan.data!);
  }, [reedsVoldaan, betalingsregeling]);

  if (betalingsregeling.state === ERemoteDataState.Pending) {
    return <Skeleton height={100} />;
  }

  return (
    <RegelBasis
      isGeselecteerd={props.isGeselecteerd}
      onGeselecteerd={props.onGeselecteerd}
      height={130}
    >
      <div className="d-flex align-items-center">
        <h5 style={{ marginBottom: 0 }}>Betalingsregeling</h5>
        <span
          className="ml-3"
          style={{
            position: 'relative',
            top: 1,
          }}
        >
          {betalingsregeling.data!.Referentie}
        </span>
        <div className="flex-fill" />
        <span>{betalingsregeling.data!.StatusNaam}</span>
      </div>
      <div className="flex-fill mt-1">
        <span>
          <FormatteerBedrag bedrag={betalingsregeling.data!.Totaalbedrag} fontSize={21} />
        </span>
      </div>
      <table style={{ width: '100%' }}>
        <tbody>
          <tr>
            <td style={{ minWidth: 100 }}>
              <span>Termijnbedrag:</span>
            </td>
            <td style={{ minWidth: 100 }}>
              <FormatteerBedrag bedrag={betalingsregeling.data!.Termijnbedrag} />
            </td>
            <td style={{ minWidth: 100 }}>
              <span>Reeds voldaan:</span>
            </td>
            <td style={{ minWidth: 100 }}>
              <FormatteerBedrag bedrag={reedsVoldaan.data!} />
            </td>
            <td style={{ width: '99%' }}>
              {/*<div className="d-flex align-items-center justify-content-end">*/}
              {/*  <span className="mr-2">X:</span>*/}
              {/*  <span>{format(new Date(inkoopfactuur.data!.Factuurdatum), 'dd-MM-yyyy')}</span>*/}

              {/*  {props.controleerBoekdatum && (*/}
              {/*    <span className="ml-2">*/}
              {/*        {props.boekdatum.state === ERemoteDataState.Pending ||*/}
              {/*        props.boekdatum.data === null ? (*/}
              {/*          <Skeleton width={18} />*/}
              {/*        ) : dagDatum(props.boekdatum.data!).getTime() ===*/}
              {/*        dagDatum(new Date(inkoopfactuur.data!.Factuurdatum)).getTime() ? (*/}
              {/*          <span*/}
              {/*            title="De boekdatum is gelijk aan de factuurdatum"*/}
              {/*            style={{*/}
              {/*              position: 'relative',*/}
              {/*              top: -2,*/}
              {/*            }}*/}
              {/*          >*/}
              {/*            <IconVink*/}
              {/*              style={{*/}
              {/*                width: 18,*/}
              {/*                height: 18,*/}
              {/*                fill: Kleur.Groen,*/}
              {/*              }}*/}
              {/*            />*/}
              {/*          </span>*/}
              {/*        ) : (*/}
              {/*          <span*/}
              {/*            title="De boekdatum is niet gelijk aan de factuurdatum"*/}
              {/*            style={{*/}
              {/*              position: 'relative',*/}
              {/*              top: -2,*/}
              {/*            }}*/}
              {/*          >*/}
              {/*            <IconPriorityHigh*/}
              {/*              style={{*/}
              {/*                width: 18,*/}
              {/*                height: 18,*/}
              {/*                fill: Kleur.Rood,*/}
              {/*              }}*/}
              {/*            />*/}
              {/*          </span>*/}
              {/*        )}*/}
              {/*      </span>*/}
              {/*  )}*/}
              {/*</div>*/}
            </td>
          </tr>
          <tr>
            <td style={{ minWidth: 100 }}>
              <span>Laatste termijn:</span>
            </td>
            <td style={{ minWidth: 100 }}>
              <FormatteerBedrag
                bedrag={
                  betalingsregeling.data!.Totaalbedrag -
                  (betalingsregeling.data!.AantalTermijnen - 1) *
                    betalingsregeling.data!.Termijnbedrag
                }
              />
            </td>
            <td style={{ minWidth: 100 }}>
              <span>Nog voldoen:</span>
            </td>
            <td style={{ minWidth: 100 }}>
              <span className="d-flex align-items-center">
                <FormatteerBedrag bedrag={nogVoldoen.data!} />
                {nogVoldoen.data! === 0 ? (
                  <IconVink
                    className="ml-2"
                    style={{
                      width: 18,
                      height: 18,
                      fill: Kleur.Groen,
                    }}
                  />
                ) : (
                  <IconPriorityHigh
                    className="ml-2"
                    style={{
                      width: 18,
                      height: 18,
                      fill: Kleur.Rood,
                    }}
                  />
                )}
              </span>
            </td>
            <td style={{ width: '99%' }}>
              <div className="d-flex align-items-center justify-content-end">
                <span className="mr-2">Aantal termijnen:</span>
                <span>{betalingsregeling.data!.AantalTermijnen}</span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </RegelBasis>
  );
};

export default BetalingsregelingRegel;
