import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import IDialoogProps from '../../../../core/IDialoogProps';
import Dialoog from '../../../../components/dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import * as Yup from 'yup';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../../stores/RootStore';
import Combobox from '../../../../components/formulier/Combobox';
import nameof from '../../../../core/nameOf';
import { IOphalenOpdrachtwijzenResultElement } from '../../../../../../shared/src/api/v2/dienst';
import { IOphalenOpdrachtenResultElement } from '../../../../../../shared/src/api/v2/pendel';
import { IOphalenDienstenResultElement } from '../../../../../../shared/src/api/v2/dienst/pendel';
import api from '../../../../api';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import { EResultType } from '../../../../stores/CheckStore';
import _ from 'lodash';

interface IDialoogResult {}

interface IProps extends IDialoogProps<IDialoogResult> {
  penOpdID: number;
}

interface IFormikValues {
  opdrachtwijze: number | null;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  opdrachtwijze: 'Opdrachtwijze',
};

const VersturenDialoog: React.FC<IProps> = observer((props) => {
  const { children, dialoogIndex, onAnnuleren, onSuccess, open, penOpdID } = props;
  const { checkStore } = useContext(RootStoreContext);
  const [opdrachtwijzen, setOpdrachtwijzen] = useState<
    IOphalenOpdrachtwijzenResultElement[] | null
  >(null);

  const [dienst, setDienst] = useState<IOphalenDienstenResultElement | null>(null);

  useEffect(() => {
    (async () => {
      const result = await api.v2.pendel.ophalenOpdrachten({
        filterSchema: { filters: [{ naam: 'IDS', data: [penOpdID] }] },
      });
      const opdrachten: IOphalenOpdrachtenResultElement[] = result.opdrachten;
      const penDienstIDs = _.uniq(opdrachten.map((x) => x.dienst!.ID));
      const penDienstID = penDienstIDs[0];

      const dienstenResult = await api.v2.dienst.pendel.ophalenDiensten({
        filterSchema: { filters: [{ naam: 'IDS', data: [penDienstID] }] },
      });

      setDienst(dienstenResult.diensten[0]);
    })();
  }, [penOpdID]);

  useEffect(() => {
    if (dienst === null) {
      return;
    }

    (async () => {
      const result = await api.v2.dienst.ophalenOpdrachtwijzenVoorDienst({
        filterSchema: {
          filters: [{ naam: 'PENDIENST_IDS', data: [dienst.ID] }],
        },
      });

      setOpdrachtwijzen(result.opdrachtwijzen);
    })();
  }, [dienst]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      const params = { penOpdID: props.penOpdID, opdWijzeID: values.opdrachtwijze! };

      const checkData = await api.v2.pendel.checkVersturenOpdracht(params);
      const checkResult = await checkStore.controleren({
        checkData,
      });
      if (checkResult.type === EResultType.Annuleren) {
        onAnnuleren();
        return;
      }

      await api.v2.pendel.versturenOpdracht(params);

      props.onSuccess({});
      actions.setSubmitting(false);
    },
    [],
  );

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (dienst === null) {
      return null;
    }

    return {
      opdrachtwijze: dienst !== null ? dienst.OpdWijzeID! : null,
    };
  }, [dienst]);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        opdrachtwijze: Yup.number().required(),
      }),
    [],
  );

  return (
    <Dialoog index={dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Versturen opdracht</ModalTitle>
      </ModalHeader>
      {initialValues === null ? (
        <LoadingSpinner />
      ) : (
        <Formik<IFormikValues>
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
          // enableReinitialize
          isInitialValid
          render={(formikProps: FormikProps<IFormikValues>) => {
            const { submitForm, isValid, isSubmitting } = formikProps;

            return (
              <>
                <ModalBody>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-12 d-flex flex-column">
                        <label>Transporteur</label>
                        <div
                          className="font-weight-bold flex-fill"
                          style={{
                            border: `1px solid ${Kleur.LichtGrijs}`,
                            padding: '4px 8px',
                            borderRadius: 3,
                          }}
                        >
                          {dienst === null ? <LoadingSpinner /> : dienst.relatie!.weergavenaam}
                        </div>
                      </div>

                      <div className="col-12 mt-2">
                        <label>{veldnamen.opdrachtwijze}</label>
                        <Field
                          name={nameof<IFormikValues>('opdrachtwijze')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            if (opdrachtwijzen === null) {
                              return <LoadingSpinner />;
                            }

                            return (
                              <Combobox
                                geselecteerd={field.value}
                                opties={opdrachtwijzen!.map((opdrachtwijze) => {
                                  return {
                                    id: opdrachtwijze.OpdWijzeID,
                                    label: opdrachtwijze.Naam,
                                  };
                                })}
                                onSelectieChange={(x) => form.setFieldValue(field.name, x)}
                              />
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </ModalBody>
                <ModalFooter className="d-flex flex-row justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    style={{ width: 100 }}
                    disabled={!isValid || isSubmitting}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={onAnnuleren}
                    style={{ width: 100 }}
                    disabled={isSubmitting}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Dialoog>
  );
});

export default VersturenDialoog;
