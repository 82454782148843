import React, { useMemo } from 'react';
// import { Root } from './Opdrachten/style';
import { RouteComponentProps, withRouter } from 'react-router';
import Tabblad, { ITabblad } from '../../components/layout/Tabblad';
import Procedures from './Procedures';
import Krediettoetsen from './Krediettoetsen';
import DubieuzeAdressen from './DubieuzeAdressen';

export enum ETabblad {
  Procedures = 1,
  Krediettoetsen = 2,
  DubieuzeAdressen = 3,
}

const pathMap: Record<ETabblad, string> = {
  [ETabblad.Procedures]: '/procedures',
  [ETabblad.Krediettoetsen]: '/krediettoetsen',
  [ETabblad.DubieuzeAdressen]: '/dubieuze-adressen',
};

interface IProps extends RouteComponentProps {}

const Acceptatie: React.FC<IProps> = (props) => {
  const tabblad = useMemo<ETabblad | null>(() => {
    const matchKey = Object.keys(pathMap).find((key) => {
      const path = pathMap[Number(key) as ETabblad]!;
      return props.location.pathname.endsWith(path);
    });
    if (matchKey === undefined) {
      props.history.push(`${props.match.path}${pathMap[ETabblad.Procedures]}`);
      return null;
    }
    return Number(matchKey) as ETabblad;
  }, [props.location.pathname]);

  const tabbladen = useMemo<ITabblad<ETabblad>[]>(
    () => [
      {
        id: ETabblad.Procedures,
        label: 'Procedures',
        content: Procedures,
      },
      {
        id: ETabblad.Krediettoetsen,
        label: 'Krediettoetsen',
        content: Krediettoetsen,
      },
      {
        id: ETabblad.DubieuzeAdressen,
        label: 'Dubieuze adressen',
        content: DubieuzeAdressen,
      },
    ],
    [],
  );

  return (
    <>
      {tabblad !== null && (
        <Tabblad<ETabblad>
          geselecteerd={tabblad}
          onSelectieChange={(tab) => {
            props.history.push(`${props.match.path}${pathMap[tab]}`);
          }}
          tabbladen={tabbladen}
        />
      )}
    </>
  );
};

export default withRouter(Acceptatie);
