import React, { HTMLProps, useContext, useMemo } from 'react';
import styled from 'styled-components';
import { RootStoreContext } from '../../../../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import ASPGebruikerVisualisatie, {
  EModus,
} from '../../../../../ASPGebruiker/ASPGebruikerVisualisatie';

const Root = styled.div`
  border: 1px solid #e2e2e2;
  background: white;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
`;

const GebruikerKnop = styled.button`
  display: flex;
  align-items: center;
  padding: 8px 12px;
  font-size: 14px;
  background-color: transparent;
  outline: none;
  transition: background-color 0.2s ease-in-out;
  border: none;
  width: 100%;

  &:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const ASPGebruikerVisualisatieNaamComponent = (props: HTMLProps<HTMLSpanElement>) => (
  <span {...props} style={{ ...props.style, fontSize: 13, marginLeft: 10 }} />
);

interface IProps {
  uitsluitenAspGebrIDs: number[];
  onGebruikerGekozen: (aspGebrID: number) => void;
}

const ToekennenAanLijst = observer((props: IProps) => {
  const { gebruikerStore } = useContext(RootStoreContext);

  const gebruikers = useMemo(
    () =>
      gebruikerStore.gebruikers === null
        ? null
        : gebruikerStore.gebruikers.filter(
            (gebr) => !props.uitsluitenAspGebrIDs.includes(gebr.AspGebrID),
          ),
    [props.uitsluitenAspGebrIDs, gebruikerStore.gebruikers],
  );

  if (gebruikers === null) {
    return null;
  }

  return (
    <Root>
      {gebruikers.map((gebruiker) => (
        <GebruikerKnop
          key={gebruiker.AspGebrID}
          onClick={() => props.onGebruikerGekozen(gebruiker.AspGebrID)}
        >
          <ASPGebruikerVisualisatie
            aspGebrID={gebruiker.AspGebrID}
            modus={EModus.Volledig}
            avatarGrootte={24}
            naamComponent={ASPGebruikerVisualisatieNaamComponent}
          />
        </GebruikerKnop>
      ))}
    </Root>
  );
});

export default ToekennenAanLijst;
