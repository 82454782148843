import React from 'react';
import { Kleur } from '../../bedrijfslogica/constanten';

interface IProps {
  width: number | string;
}

const IndentatieVlak: React.FC<IProps> = (props) => {
  return (
    <div style={{ backgroundColor: Kleur.LichtGrijs, minWidth: props.width, width: props.width }} />
  );
};

export default IndentatieVlak;
