import React from 'react';
import { ITabbladProps } from '..';
import Reserveringen from '../../../../components/voorraad/Reserveringen';

interface IProps extends ITabbladProps {}

interface IUrlState {
  selectie: number[];
}
const defaultUrlState: IUrlState = {
  selectie: [],
};

const ReserveringTab: React.FC<IProps> = (props) => {
  return <Reserveringen />;
};

export default ReserveringTab;
