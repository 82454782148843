import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import IDialoogProps from '../../../../../core/IDialoogProps';
import Dialoog from '../../../../../components/dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import LoadingSpinner from '../../../../../components/Gedeeld/LoadingSpinner';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import * as Yup from 'yup';
import api from '../../../../../api';
import nameOf from '../../../../../core/nameOf';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../../../stores/RootStore';

import NumeriekVeld from '../../../../../components/formulier/NumeriekVeld';
import VinkVeld from '../../../../../components/formulier/VinkVeld';
import { EResultType } from '../../../../../stores/CheckStore';

interface IProps extends IDialoogProps<null> {}

interface IFormikValues {
  naam: string;
  naamKort: string;
  actief: boolean;
  naamEnum: string | null;
  aantalGebruikersTarief: number;
  aantalGebruikersMax: number;
  leeftijdGrens: number | null;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  naam: 'Naam',
  naamKort: 'Naam kort',
  actief: 'Actief',
  naamEnum: 'Naam enum',
  aantalGebruikersTarief: 'Atl. gebr. tarief',
  aantalGebruikersMax: 'Atl. gebr. max',
  leeftijdGrens: 'Lft.grens',
};

const WijzigenDialoog: React.FC<IProps> = observer((props) => {
  const { dialoogIndex, onAnnuleren, onSuccess } = props;
  const { checkStore } = useContext(RootStoreContext);
  const formikRef = useRef<Formik<IFormikValues>>(null);

  const initialValues = useMemo<IFormikValues>(() => {
    return {
      naam: '',
      naamKort: '',
      actief: true,
      naamEnum: '',
      aantalGebruikersTarief: 0,
      aantalGebruikersMax: 0,
      leeftijdGrens: 0,
    };
  }, []);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      const params = {
        naam: values.naam,
        naamKort: values.naamKort,
        actief: values.actief,
        naamEnum: values.naamEnum,
        aantalGebruikersTarief: values.aantalGebruikersTarief,
        aantalGebruikersMax: values.aantalGebruikersMax,
        leeftijdGrens: values.leeftijdGrens === 0 ? null : values.leeftijdGrens,
      };

      const checkData = await api.v2.product.soort.checkToevoegenProductsoort(params);

      const controlerenResult = await checkStore.controleren({
        checkData,
      });
      if (controlerenResult.type === EResultType.Annuleren) {
        return;
      }

      await api.v2.product.soort.toevoegenProductsoort(params);

      actions.setSubmitting(false);
      onSuccess(null);
    },
    [],
  );

  const validationSchema = useMemo(() => {
    const fields: Record<keyof IFormikValues, any> = {
      naam: Yup.string().required(),
      naamKort: Yup.string().required(),
      actief: Yup.boolean(),
      naamEnum: Yup.string(),
      aantalGebruikersTarief: Yup.number(),
      aantalGebruikersMax: Yup.number(),
      leeftijdGrens: Yup.number(),
    };
    return Yup.object().shape(fields);
  }, []);

  return (
    <Dialoog index={dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Toevoegen productsoort</ModalTitle>
      </ModalHeader>
      {initialValues === null ? (
        <LoadingSpinner />
      ) : (
        <Formik<IFormikValues>
          ref={formikRef}
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
          render={(formikProps: FormikProps<IFormikValues>) => {
            const { submitForm, isSubmitting, isValid } = formikProps;
            return (
              <>
                <ModalBody>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-12">
                        <label>{veldnamen.naam}</label>
                        <Field
                          name={nameOf<IFormikValues>('naam')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            return (
                              <input type="text" className="form-control" {...fieldProps.field} />
                            );
                          }}
                        />
                      </div>

                      <div className="col-6 mt-3">
                        <label>{veldnamen.naamKort}</label>
                        <Field
                          name={nameOf<IFormikValues>('naamKort')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            return (
                              <input type="text" className="form-control" {...fieldProps.field} />
                            );
                          }}
                        />
                      </div>

                      <div className="col-6 mt-3">
                        <label>{veldnamen.naamEnum}</label>
                        <Field
                          name={nameOf<IFormikValues>('naamEnum')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            return (
                              <input type="text" className="form-control" {...fieldProps.field} />
                            );
                          }}
                        />
                      </div>

                      <div className="row col-12 mt-3 d-flex">
                        <div className="col-4">
                          <label>{veldnamen.aantalGebruikersTarief}</label>
                          <Field
                            name={nameOf<IFormikValues>('aantalGebruikersTarief')}
                            render={(fieldProps: FieldProps<IFormikValues>) => {
                              const { field, form } = fieldProps;
                              return (
                                <div>
                                  <NumeriekVeld
                                    waarde={field.value}
                                    onChange={(x) => form.setFieldValue(field.name, x)}
                                    min={0}
                                    max={99}
                                    inactiefRepresentatie={(x) => {
                                      if (field.value === 0) {
                                        return 'Onbegrensd';
                                      }
                                      return `${field.value}`;
                                    }}
                                  />
                                  {/* <FormikVeldFout fieldProps={fieldProps} /> */}
                                </div>
                              );
                            }}
                          />
                        </div>

                        <div className="col-4">
                          <label>{veldnamen.aantalGebruikersMax}</label>
                          <Field
                            name={nameOf<IFormikValues>('aantalGebruikersMax')}
                            render={(fieldProps: FieldProps<IFormikValues>) => {
                              const { field, form } = fieldProps;
                              return (
                                <div>
                                  <NumeriekVeld
                                    waarde={field.value}
                                    onChange={(x) => form.setFieldValue(field.name, x)}
                                    min={0}
                                    max={99}
                                    inactiefRepresentatie={(x) => {
                                      if (field.value === 0) {
                                        return 'Onbegrensd';
                                      }
                                      return `${field.value}`;
                                    }}
                                  />
                                  {/* <FormikVeldFout fieldProps={fieldProps} /> */}
                                </div>
                              );
                            }}
                          />
                        </div>
                      </div>

                      <div className="row col-12 mt-3 d-flex">
                        <div className="col-4">
                          <label>{veldnamen.leeftijdGrens}</label>
                          <Field
                            name={nameOf<IFormikValues>('leeftijdGrens')}
                            render={(fieldProps: FieldProps<IFormikValues>) => {
                              const { field, form } = fieldProps;
                              return (
                                <div>
                                  <NumeriekVeld
                                    waarde={field.value}
                                    onChange={(x) => form.setFieldValue(field.name, x)}
                                    min={0}
                                    max={240}
                                    inactiefRepresentatie={(x) => {
                                      if (field.value === 0) {
                                        return 'Geen';
                                      }
                                      return `${field.value}`;
                                    }}
                                  />
                                  {/* <FormikVeldFout fieldProps={fieldProps} /> */}
                                </div>
                              );
                            }}
                          />
                        </div>
                      </div>

                      <div className="col-12 mt-3">
                        <span className="d-flex align-items-center">
                          <Field
                            name="actief"
                            render={(x: FieldProps<IFormikValues>) => (
                              <VinkVeld
                                aangevinkt={x.field.value}
                                onGewijzigd={(aangevinkt: boolean) =>
                                  x.form.setFieldValue(x.field.name, aangevinkt)
                                }
                              />
                            )}
                          />
                          <span className="ml-2">{veldnamen.actief}</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </ModalBody>

                <ModalFooter className="d-flex flex-row justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    style={{ width: 100 }}
                    disabled={isSubmitting || !isValid}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={onAnnuleren}
                    style={{ width: 100 }}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Dialoog>
  );
});

export default WijzigenDialoog;
