import React, { useCallback, useMemo, useState, useEffect, useContext } from 'react';
import IDialoogProps from '../../../../core/IDialoogProps';
import Dialoog from '../../../../components/dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import api from '../../../../api';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import FormikVeldFout from '../../../../components/formulier/FormikVeldFout';
import { RootStoreContext } from '../../../../stores/RootStore';
import { EResultType } from '../../../../stores/CheckStore';
import nameOf from '../../../../core/nameOf';
import VinkVeld from '../../../../components/formulier/VinkVeld';
import { ERegelstatusTransport } from '../../../../bedrijfslogica/enums';

export interface IDialoogResult {}

interface IProps extends IDialoogProps<IDialoogResult> {
  trsRegIDs: number[];
}

interface IFormikValues {
  berichtSturen: boolean;
  berichtinhoud: string | null;
  kopieNaarPlanning: boolean;
}

const veldnamen: Record<keyof IFormikValues, string | null> = {
  berichtSturen: 'Bericht naar transporteur sturen',
  berichtinhoud: 'Toelichting bij het annuleringsbericht',
  kopieNaarPlanning: 'Kopie van de regels naar nieuwe opdracht in planning',
};

const AnnulerenDialoog: React.FC<IProps> = (props) => {
  const { children, dialoogIndex, onAnnuleren, onSuccess, open, trsRegIDs } = props;
  const { checkStore } = useContext(RootStoreContext);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      if (
        true &&
        (
          await checkStore.bevestigen({
            inhoud: (
              <>
                <span>Geselecteerde regels annuleren?</span>

                {!values.kopieNaarPlanning && (
                  <span>
                    <br />
                    Let op: je hebt gekozen voor GEEN kopie van de regels naar planning
                  </span>
                )}

                {values.berichtSturen && (
                  <span>
                    <br />
                    Er zal direct een bericht verstuurd worden
                  </span>
                )}
              </>
            ),
          })
        ).type === EResultType.Annuleren
      ) {
        actions.setSubmitting(false);
        return;
      }

      // TrsRegIDs aanvullen met status
      const regels = props.trsRegIDs.map((x) => {
        return {
          trsRegID: x,
          status: ERegelstatusTransport.GEANNULEERD,
          referentiecode: null,
        };
      });

      const params = {
        regels,
        afmelddatum: new Date(),
        markeerAfgehandeld: false,
      };

      const checkData = await api.v2.transport.checkAfmeldenOpdrachtregels(params);
      const checkResult = await checkStore.controleren({
        checkData,
      });
      if (checkResult.type === EResultType.Annuleren) {
        actions.setSubmitting(false);
        return;
      }

      await api.v2.transport.afmeldenOpdrachtregels(params);

      if (values.berichtSturen) {
        await api.v2.transport.opdracht.uitstaand.versturenAnnuleringsberichtTransporteur({
          trsRegIDs,
          berichtinhoud: values.berichtSturen ? values.berichtinhoud : null,
        });
      }

      if (values.kopieNaarPlanning) {
        await api.v2.transport.opdracht.kopieerenOpdrachtregels({
          trsOpdID: null,
          trsRegIDs: props.trsRegIDs,
          reserveringMeenemen: false,
        });
      }

      actions.setSubmitting(false);

      onSuccess({});
    },
    [],
  );

  const initialValues = useMemo<IFormikValues>(() => {
    return {
      berichtSturen: false,
      berichtinhoud: null,
      kopieNaarPlanning: false,
    };
  }, []);

  return (
    <Dialoog index={dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Annuleren opdrachtregel(s)</ModalTitle>
      </ModalHeader>

      <Formik<IFormikValues>
        isInitialValid
        onSubmit={handleSubmit}
        initialValues={initialValues}
        render={(formikProps: FormikProps<IFormikValues>) => {
          const { submitForm, isSubmitting, isValid } = formikProps;

          return (
            <>
              <ModalBody>
                <div className="form-group">
                  <div className="col-12 mt-3">
                    <div className="d-flex align-items-center">
                      <Field
                        name={nameOf<IFormikValues>('berichtSturen')}
                        render={(fieldProps: FieldProps<IFormikValues>) => {
                          const { field, form } = fieldProps;
                          return (
                            <VinkVeld
                              aangevinkt={field.value}
                              onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                            />
                          );
                        }}
                      />
                      <span className="ml-2">{veldnamen.berichtSturen}</span>
                    </div>
                  </div>
                  <div className="col-12 mt-4">
                    <label>{veldnamen.berichtinhoud}</label>
                    <Field
                      name={nameOf<IFormikValues>('berichtinhoud')}
                      render={(fieldProps: FieldProps<IFormikValues>) => {
                        const { field, form } = fieldProps;

                        return (
                          <>
                            <textarea
                              {...field}
                              className="form-control"
                              rows={2}
                              disabled={!form.values.berichtSturen}
                            />
                            <FormikVeldFout fieldProps={fieldProps} />
                          </>
                        );
                      }}
                    />
                  </div>

                  <div className="col-12 d-flex align-items-center mt-3">
                    <Field
                      name={nameOf<IFormikValues>('kopieNaarPlanning')}
                      render={(fieldProps: FieldProps<IFormikValues>) => {
                        const { field, form } = fieldProps;

                        return (
                          <VinkVeld
                            aangevinkt={field.value}
                            onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                          />
                        );
                      }}
                    />
                    <span className="ml-2">{veldnamen.kopieNaarPlanning}</span>
                  </div>
                </div>
              </ModalBody>
              <ModalFooter className="d-flex flex-row justify-content-start">
                <button
                  className="btn btn-primary"
                  onClick={submitForm}
                  style={{ width: 100 }}
                  disabled={!isValid || isSubmitting}
                >
                  Ok
                </button>
                <button
                  className="btn btn-secondary"
                  onClick={onAnnuleren}
                  style={{ width: 100 }}
                  disabled={isSubmitting}
                >
                  Annuleren
                </button>
              </ModalFooter>
            </>
          );
        }}
      />
    </Dialoog>
  );
};

export default AnnulerenDialoog;
