import { useCallback, useEffect, useState } from 'react';
import { IOphalenProductsoortenResultElement } from '../../../../../../../../../shared/src/api/v2/product/soort';
import api from '../../../../../../../api';
import Producten from '.';
import React from 'react';
import LoadingSpinner from '../../../../../../../components/Gedeeld/LoadingSpinner';

interface IProps {}

const ProductenDataWrapper = (props: IProps) => {
  const [productsoorten, setProductsoorten] = useState<
    IOphalenProductsoortenResultElement[] | null
  >(null);

  const ophalenProductsoorten = useCallback(async () => {
    const result = await api.v2.product.soort.ophalenProductsoorten({
      filterSchema: { filters: [] },
    });

    setProductsoorten(result);
  }, []);

  useEffect(() => {
    ophalenProductsoorten();
  }, [ophalenProductsoorten]);

  if (productsoorten === null) {
    return (
      <div className="d-flex flex-fill align-items-center justify-content-center">
        <LoadingSpinner />
      </div>
    );
  }

  return <Producten productsoorten={productsoorten} />;
};

export default ProductenDataWrapper;
