import { format } from 'date-fns';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { IOphalenBlogitemsInternResultElement } from '../../../../../../shared/src/api/v2/blog';
import { IOphalenTekstenResultElement } from '../../../../../../shared/src/api/v2/tekst';
import api from '../../../../api';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import FilterBalkV2, {
  IFilter,
  IFilterData,
  maakFilterSchema,
} from '../../../../components/FilterBalkV2';
import { IconToevoegen, IconVerwijderen } from '../../../../components/Icons';
import MenuLayout from '../../../../components/MenuLayout';
import ASPTabel from '../../../../components/tabel/ASPTabel';
import TableData, { ITableDataProps } from '../../../../components/tabel/ASPTabel/Body/TableData';
import { ASPKolom, EAspKolomBreedteType } from '../../../../components/tabel/ASPTabel/types';
import useUrlState from '../../../../core/useUrlState';
import { EResultType } from '../../../../stores/CheckStore';
import { RootStoreContext } from '../../../../stores/RootStore';
import ToevoegenDialoog from './ToevoegenDialoog';
import WijzigenDialoog from './WijzigenDialoog';
import ZoektermFilter from './ZoektermFilter';

type Kolom = 'datum' | 'titel' | 'inhoud' | 'trefwoorden' | 'slug' | 'categorie' | 'bestand';

export enum EFilter {
  Zoekterm = 'ZOEKTERM',
}

interface IProps extends RouteComponentProps {}

interface IToevoegenDialoogState {}
interface IWijzigenDialoogState {
  id: number;
}
interface IUrlState {
  selectie: number[];
  filterdata: IFilterData<EFilter>[];
  toevoegenDialoogState: IToevoegenDialoogState | null;
  wijzigenDialoogState: IWijzigenDialoogState | null;
}

const defaultUrlState: IUrlState = {
  selectie: [],
  filterdata: [
    {
      naam: EFilter.Zoekterm,
      data: '',
      isActief: false,
    },
  ],
  toevoegenDialoogState: null,
  wijzigenDialoogState: null,
};

const Item: React.FC<IProps> = (props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const { checkStore, instellingStore } = useContext(RootStoreContext);

  const [blogitems, setBlogitems] = useState<IOphalenBlogitemsInternResultElement[] | null>(null);
  const [teksten, setTeksten] = useState<IOphalenTekstenResultElement[] | null>(null);

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.Zoekterm,
        altijdWeergevenInBalk: true,
        weergave: ZoektermFilter,
      },
    ],
    [],
  );

  const [filterschema, setFilterSchema] = useState(maakFilterSchema(urlState.filterdata));

  const handleToevoegen = useCallback(async () => {
    setUrlStateSync('toevoegenDialoogState', {});
  }, []);

  const handleVerwijderen = useCallback(async () => {
    const params = { IDs: urlState.selectie };

    const checkData = await api.v2.blog.checkVerwijderenBlogitem(params);
    if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
      return;
    }

    if (
      (
        await checkStore.bevestigen({
          inhoud: <span>Geselecteerde items verwijderen?</span>,
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    await api.v2.blog.verwijderenBlogitem(params);
    setUrlStateSync('selectie', []);

    await ophalenBlogitem();
  }, [urlState.selectie]);

  const ophalenBlogitem = useCallback(async () => {
    const resultBlogitem = await api.v2.blog.ophalenBlogitem({
      filterschema,
      orderschema: {
        orders: [
          {
            naam: 'DATUM',
            richting: 'DESC',
          },
        ],
      },
    });

    setBlogitems(resultBlogitem.blogitems);
  }, [filterschema.filters]);

  useEffect(() => {
    ophalenBlogitem();
  }, [ophalenBlogitem]);

  const ophalenTeksten = useCallback(async () => {
    if (blogitems === null) {
      return;
    }

    const tekstIDs = [
      ...blogitems.map((x) => x.Titel_TekstID).filter((x) => x !== null),
      ...blogitems.map((x) => x.Inhoud_TekstID).filter((x) => x !== null),
      ...blogitems.map((x) => x.Trefwoorden_TekstID).filter((x) => x !== null),
      ...blogitems.map((x) => x.Slug_TekstID).filter((x) => x !== null),
      ...blogitems
        .filter((x) => x.CategorieNaam_TekstID != null)
        .map((x) => x.CategorieNaam_TekstID!)
        .filter((x) => x !== null),
    ];

    const resultTeksten = await api.v2.tekst.ophalenTekstenInAlleTalen({
      tekstIDs,
    });

    setTeksten(resultTeksten.teksten);
  }, [blogitems]);

  useEffect(() => {
    ophalenTeksten();
  }, [ophalenTeksten, blogitems]);

  const keyExtractor = useCallback((row: IOphalenBlogitemsInternResultElement) => row.ID, []);

  const kolommen = useMemo<ASPKolom<Kolom, IOphalenBlogitemsInternResultElement>[]>(
    () => [
      {
        key: 'datum',
        label: 'Publicatiedatum',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 160,
        renderer: (rij) => {
          return <span>{format(new Date(rij.Datum), 'dd-MM-yyyy HH:mm')}</span>;
        },
      },
      {
        key: 'titel',
        label: 'Titel',
        breedteType: EAspKolomBreedteType.Flex,
        flex: 0.8,
        renderer: (rij) => {
          if (teksten === null) {
            return;
          }
          const tekst = teksten.find((x) => x.TekstID === rij.Titel_TekstID && x.TaalID === 1)!;
          return tekst !== undefined ? tekst.Tekst : '';
        },
      },
      {
        key: 'trefwoorden',
        label: 'Trefwoorden',
        breedteType: EAspKolomBreedteType.Flex,
        flex: 1.2,
        renderer: (rij) => {
          if (teksten === null) {
            return;
          }
          const tekst = teksten.find(
            (x) => x.TekstID === rij.Trefwoorden_TekstID && x.TaalID === 1,
          )!;
          return tekst !== undefined ? tekst.Tekst : '';
        },
      },
      {
        key: 'slug',
        label: 'Slug',
        breedteType: EAspKolomBreedteType.Flex,
        flex: 1.2,
        renderer: (rij) => {
          if (teksten === null) {
            return;
          }
          const tekst = teksten.find((x) => x.TekstID === rij.Slug_TekstID && x.TaalID === 1)!;
          return tekst !== undefined ? tekst.Tekst : '';
        },
      },
      {
        key: 'bestand',
        label: 'Bestand',
        breedteType: EAspKolomBreedteType.Flex,
        flex: 0.5,
        renderer: (rij) => {
          return rij.BestandID === null ? (
            <span style={{ color: Kleur.Rood }}>Nee</span>
          ) : (
            <span>Ja</span>
          );
        },
      },
      {
        key: 'categorie',
        label: 'Categorie',
        breedteType: EAspKolomBreedteType.Flex,
        flex: 1,
        renderer: (rij) => {
          if (teksten === null) {
            return;
          }
          const tekst = teksten.find(
            (x) => x.TekstID === rij.CategorieNaam_TekstID && x.TaalID === 1,
          )!;
          return tekst !== undefined ? tekst.Tekst : '';
        },
      },
    ],
    [teksten],
  );

  return (
    <>
      <Helmet>
        <title>Website Blog Items</title>
      </Helmet>
      <MenuLayout
        menu={
          <div className="d-flex align-items-center">
            <button
              className="btn btn-sm btn-light d-flex align-items-center"
              style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
              disabled={false}
              onClick={() => handleToevoegen()}
            >
              <IconToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
              <span className="ml-2">Toevoegen</span>
            </button>
            <button
              className="btn btn-sm btn-light d-flex align-items-center ml-3"
              style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
              disabled={urlState.selectie.length === 0}
              onClick={() => handleVerwijderen()}
            >
              <IconVerwijderen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
              <span className="ml-2">Verwijderen</span>
            </button>
            <div className="d-flex flex-fill ml-3">
              <FilterBalkV2
                filters={filters}
                filterData={urlState.filterdata}
                onFilterDataChange={(x) => {
                  setUrlStateSync('filterdata', x);
                }}
                onFilterSchemaChange={(x) => {
                  setFilterSchema(x);
                }}
              />
            </div>
          </div>
        }
        body={
          blogitems == null ? (
            <span></span>
          ) : (
            <ASPTabel
              rijen={blogitems!}
              kolommen={kolommen}
              keyExtractor={keyExtractor}
              selectie={urlState.selectie}
              onSelectieChange={(selectie) => setUrlStateSync('selectie', selectie)}
              onWijzigenRij={async (rij) => {
                setUrlStateSync('wijzigenDialoogState', { id: rij.ID });
              }}
              tdComponent={TdComponent}
            />
          )
        }
      />
      {urlState.toevoegenDialoogState !== null && (
        <ToevoegenDialoog
          open
          onSuccess={async () => {
            setUrlStateSync('toevoegenDialoogState', null);
            await ophalenBlogitem();
          }}
          onAnnuleren={() => setUrlStateSync('toevoegenDialoogState', null)}
        />
      )}

      {urlState.wijzigenDialoogState !== null && (
        <WijzigenDialoog
          open
          id={urlState.wijzigenDialoogState.id}
          onSuccess={async () => {
            setUrlStateSync('wijzigenDialoogState', null);
            await ophalenBlogitem();
          }}
          onAnnuleren={() => setUrlStateSync('wijzigenDialoogState', null)}
        />
      )}
    </>
  );
};

const TdComponent = (props: ITableDataProps<Kolom, IOphalenBlogitemsInternResultElement>) => {
  return (
    <TableData
      {...props}
      style={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
        display: 'block',
        paddingTop: '8px',
      }}
    />
  );
};

export default withRouter(Item);
