import React from 'react';
import { Redirect, Route, RouteComponentProps } from 'react-router';
import PaginaHeader from '../PaginaHeader';
import { CommunicatieNavigatie } from '../Communicatie/styles';
import { NavLink } from 'react-router-dom';
import { EyeIcon, IconBoekhouding, IconLijst } from '../../components/Icons';
import Lijst from './Lijst';
import Visualisatie from './Visualisatie';
import TaakEvents from './TaakEvents';

interface IProps extends RouteComponentProps<{}> {}

const Takenplanner: React.FC<IProps> = (props) => {
  const { match } = props;
  return (
    <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
      <PaginaHeader>
        <CommunicatieNavigatie>
          <li>
            <NavLink
              to={`${match.url}/lijst`}
              style={{ paddingLeft: 20, paddingRight: 20 }}
              className="d-flex align-items-center justify-content-center"
            >
              <IconLijst />
              &nbsp; Lijst
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`${match.url}/taak-events`}
              style={{ paddingLeft: 20, paddingRight: 20 }}
              className="d-flex align-items-center justify-content-center"
            >
              <EyeIcon />
              &nbsp; Taak Events
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`${match.url}/visualisatie`}
              style={{ paddingLeft: 20, paddingRight: 20 }}
              className="d-flex align-items-center justify-content-center"
            >
              <EyeIcon />
              &nbsp; Visualisatie
            </NavLink>
          </li>
        </CommunicatieNavigatie>
      </PaginaHeader>

      <Route path={`${match.path}/lijst`} component={Lijst} />
      <Route path={`${match.path}/visualisatie`} component={Visualisatie} />
      <Route path={`${match.path}/taak-events`} component={TaakEvents} />

      <Route
        exact={true}
        path={match.path}
        render={() => <Redirect to={`${match.path}/lijst`} />}
      />
    </div>
  );
};

export default Takenplanner;
