import { IOphalenDagboekenResultElement } from '../../../../../../../shared/src/api/v2/boekhouding/boeking/dagboek';

export interface IDagboekVoorselectieProvider {
  provide: (dagboeken: IOphalenDagboekenResultElement[]) => Promise<number>;
}

export const standaardDagboekVoorselectieProvider: IDagboekVoorselectieProvider = {
  provide: async (dagboeken: IOphalenDagboekenResultElement[]) => {
    // if (dagboeken.length === 0) {
    //   return null;
    // }
    return dagboeken[0].ID;
  },
};
