import { format } from 'date-fns';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { IOphalenInventarisatiesResultElement } from '../../../../../../shared/src/api/v2/magazijn/inventarisatie';
import api from '../../../../api';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import GegevensLayout from '../../../../components/layout/GegevensLayout';
import { ITabbladComponentProps } from '../index';
import useGebruikersStempel, { IPlaatsStempelOutput } from '../../../../core/useGebruikersStempel';
import useBijGewijzigdEffect from '../../../../core/useBijGewijzigdEffect';

interface IProps extends ITabbladComponentProps {}

const OverzichtWrapper = (props: IProps) => {
  const [inventarisatie, setInventarisatie] = useState<IOphalenInventarisatiesResultElement | null>(
    null,
  );

  const ophalenInventarisatie = useCallback(async () => {
    const result = await api.v2.magazijn.inventarisatie.ophalenInventarisaties({
      filterSchema: {
        filters: [{ naam: 'IDS', data: [props.inventarisatieID] }],
      },
    });

    setInventarisatie(result.inventarisaties[0]);
  }, [props.inventarisatieID]);

  useEffect(() => {
    ophalenInventarisatie();
  }, [ophalenInventarisatie]);

  if (inventarisatie === null) {
    return (
      <div className="d-flex flex-fill align-items-center justify-content-center">
        <LoadingSpinner />
      </div>
    );
  }

  return <Overzicht {...props} inventarisatie={inventarisatie} />;
};

const Overzicht = (props: IProps & { inventarisatie: IOphalenInventarisatiesResultElement }) => {
  const { inventarisatie } = props;
  const [notities, setNotities] = useState<string>(inventarisatie.Notities ?? '');
  useBijGewijzigdEffect(() => {
    setNotities(inventarisatie.Notities ?? '');
  }, [inventarisatie.ID]);

  const [stempelOutput, setStempelOutput] = useState<IPlaatsStempelOutput | null>(null);
  const notitiesRef = useRef<HTMLTextAreaElement>(null);

  const { plaatsStempel, bezigMetStempelen } = useGebruikersStempel(
    useMemo(
      () => ({
        inputRef: notitiesRef,
        text: notities,
        onTextChange: setNotities,
      }),
      [notities, setNotities],
    ),
  );
  useBijGewijzigdEffect(() => {
    if (bezigMetStempelen) {
      return;
    }

    const trimmedNotities = notities.trim();

    api.v2.magazijn.inventarisatie.wijzigenInventarisatie({
      id: inventarisatie.ID,
      notities: trimmedNotities === '' ? null : trimmedNotities,
    });
  }, [bezigMetStempelen]);

  return (
    <div className="p-3">
      {/* <VeldWeergave> */}
      <GegevensLayout
        gegevens={[
          {
            label: 'Registratiedatum',
            waarde: format(new Date(inventarisatie.Registratiedatum), 'dd-MM-yyyy HH:mm'),
          },
          {
            label: 'Datum afgehandeld',
            waarde:
              inventarisatie.DatumAfgehandeld === null
                ? 'Nog niet afgehandeld'
                : format(new Date(inventarisatie.DatumAfgehandeld), 'dd-MM-yyyy HH:mm'),
          },
          null,
          {
            label: 'Totaal aantal intern',
            waarde: _.sum(inventarisatie.regels.map((x) => x.AantalIntern)).toString(),
          },
          {
            label: 'Totaal aantal extern',
            waarde: _.sum(inventarisatie.regels.map((x) => x.AantalExtern)).toString(),
          },
          {
            label: 'Aantal nog definitief',
            waarde: inventarisatie.regels.filter((x) => !x.IsDefinitief).length.toString(),
          },
        ]}
      />
      <div className="d-flex align-items-center mt-3">
        <label>Notities</label>
        <div className="flex-fill" />
        {stempelOutput === null ? (
          <a
            href="#"
            onClick={(ev) => {
              ev.preventDefault();
              const output = plaatsStempel();
              setStempelOutput(output);
            }}
          >
            Stempel
          </a>
        ) : (
          <>
            <a
              href="#"
              onClick={(ev) => {
                ev.preventDefault();

                stempelOutput!.herstellen();
                setStempelOutput(null);
              }}
            >
              Herstellen
            </a>
            <a
              className="ml-2"
              href="#"
              onClick={(ev) => {
                ev.preventDefault();

                stempelOutput!.doorvoeren();
                setStempelOutput(null);
              }}
            >
              Doorvoeren
            </a>
          </>
        )}
      </div>
      <textarea
        ref={notitiesRef}
        rows={6}
        className="form-control"
        disabled={!bezigMetStempelen}
        value={notities}
        onChange={(ev) => setNotities(ev.target.value)}
      />
    </div>
  );
};

export default OverzichtWrapper;
