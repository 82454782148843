import { IWeergaveProps } from '../../../../../components/FilterBalkV2';
import React, { useRef } from 'react';
import { EFilter } from '..';

const ZoektermFilter = (p: IWeergaveProps<any>) => {
  return (
    <span className="d-flex align-items-center" style={{ width: 300 }}>
      <span className="mr-3">Zoekterm</span>
      <input
        placeholder="B.v. Omschrijving, IBAN, Rek.naam of Bet.kenmerk"
        className="form-control"
        value={p.state || ''}
        onChange={(ev) => p.onStateChange(ev.target.value)}
        onKeyUp={(ev) => {
          if (ev.key === 'Enter') {
            p.onDataChange(p.state);
            p.setFilterIsActief(EFilter.Zoekterm, true);
            p.toepassen();
          }
        }}
        autoFocus
      />
    </span>
  );
};

export default ZoektermFilter;
