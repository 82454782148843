import React, { useMemo } from 'react';
import { ActiesIcon } from '../Icons';
import { Kleur } from '../../bedrijfslogica/constanten';
import Dropdown from 'react-bootstrap/Dropdown';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger, { OverlayTriggerProps } from 'react-bootstrap/OverlayTrigger';

import { v4 as makeUuid } from 'uuid';

interface IProps {
  acties: Array<IActie | 'scheiding'>;
  iconStyle?: React.CSSProperties;
  icon?: JSX.Element;
  iconBreedte?: number;
  overlayTriggerProps?: Partial<Omit<OverlayTriggerProps, 'ref'>>;
  style?: React.CSSProperties;
  className?: string;
}

export interface IActie {
  icon?: JSX.Element;
  text: JSX.Element | string;
  onClick: () => void;
  disabled?: boolean;
  title?: string;
}

const ActieMenuKnop: React.FC<IProps> = (props) => {
  const { acties, iconStyle, iconBreedte } = props;
  const id = useMemo(() => makeUuid(), []);

  // Een van de acties heeft een icon
  const ruimteVoorIconsBehouden = useMemo(
    () =>
      acties.some((actie) => {
        return actie !== 'scheiding' && actie.icon !== undefined;
      }),
    [acties],
  );

  return (
    <div onClick={(ev) => ev.stopPropagation()} style={props.style} className={props.className}>
      <OverlayTrigger
        overlay={
          <Popover id={id}>
            <Dropdown>
              <div style={{ marginTop: 5, marginBottom: 5 }}>
                {acties.map((actie, i) => {
                  if (actie === 'scheiding') {
                    return (
                      <Dropdown.Divider
                        key={i}
                        style={{
                          margin: '.3rem 0',
                          borderTop: `1px solid ${Kleur.BeetjeDonkerGrijs}`,
                        }}
                      />
                    );
                  }

                  return (
                    <Dropdown.Item
                      key={i}
                      onClick={(ev: any) => {
                        if (actie.disabled) {
                          return;
                        }
                        ev.stopPropagation();
                        document.body.click();
                        actie.onClick();
                      }}
                      style={{
                        padding: '3px 10px',
                        display: 'flex',
                        alignItems: 'center',
                        cursor: actie.disabled ? 'not-allowed' : 'pointer',
                      }}
                    >
                      {ruimteVoorIconsBehouden && (
                        <span style={{ minWidth: iconBreedte || 18 }}>
                          {actie.icon !== undefined && actie.icon}
                        </span>
                      )}
                      <span className="ml-2" title={actie.title}>
                        {actie.text}
                      </span>
                    </Dropdown.Item>
                  );
                })}
              </div>
            </Dropdown>
          </Popover>
        }
        rootClose
        trigger="click"
        placement="auto"
        {...props.overlayTriggerProps}
      >
        <span style={{ cursor: 'pointer' }}>
          {props.icon || (
            <ActiesIcon style={{ fill: Kleur.Grijs, width: 18, height: 18, ...iconStyle }} />
          )}
        </span>
      </OverlayTrigger>
    </div>
  );
};

export default ActieMenuKnop;
