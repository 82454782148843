import React, { useCallback, useMemo } from 'react';
import { IActieProps } from '../index';
import { ICommunicatieTelefoonUrlState } from '../../Communicatie/Telefoon';
import { ERichting } from '../../../components/communicatie/TelefoonHistorie';
import IRemoteData, { createPendingRemoteData } from '../../../models/IRemoteData';
import { genereerUrlStateQueryParam } from '../../../core/useUrlState';

interface IData {
  telOprID: number;
}

const TELEFOONOPROEP_HERINNERING_TOEKENNEN_GESPREKSCONTEXTEN: React.VoidFunctionComponent<IActieProps> = (
  props: IActieProps,
) => {
  const data = useMemo<IData>(() => {
    return JSON.parse(props.controltaak.Data!);
  }, [props.controltaak.Data]);

  const handleClick = useCallback(
    (ev: React.MouseEvent) => {
      ev.preventDefault();

      const urlState: ICommunicatieTelefoonUrlState = {
        laatsteVanGebruikerSelectie: null,
        richting: ERichting.Beide,
        vanGebruiker: 'IEDEREEN',
        vigerend: data.telOprID,
      };
      const url = `/communicatie/telefoon?state=${genereerUrlStateQueryParam(urlState)}`;
      props.history.push(url);
    },
    [data.telOprID, props.history.push],
  );

  return (
    <div>
      <a href="#" onClick={handleClick}>
        Naar telefoonoproep
      </a>
    </div>
  );
};

export default TELEFOONOPROEP_HERINNERING_TOEKENNEN_GESPREKSCONTEXTEN;
