import {
  IInitToevoegenRetouropdrachtParams,
  IInitToevoegenRetouropdrachtResult,
  IToevoegenRetouropdrachtParams,
  IToevoegenRetouropdrachtResult,
} from '../../../../../../shared/src/api/v2/transport/opdracht/retour';
import ICheckData from '../../../../../../shared/src/models/ICheckData';
import api from '../../../index';

const retour = {
  initToevoegenRetouropdracht: async (
    params: IInitToevoegenRetouropdrachtParams,
  ): Promise<IInitToevoegenRetouropdrachtResult> => {
    return await api.post('v2/transport/opdracht/retour/init-toevoegen-retouropdracht', params);
  },
  toevoegenRetouropdracht: async (
    params: IToevoegenRetouropdrachtParams,
  ): Promise<IToevoegenRetouropdrachtResult> => {
    return await api.post('v2/transport/opdracht/retour/toevoegen-retouropdracht', params);
  },
};

export default retour;
