import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import IDialoogProps from '../../../../core/IDialoogProps';
import Dialoog from '../../../../components/dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../models/IRemoteData';
import api from '../../../../api';
import nameOf from '../../../../core/nameOf';
import * as Yup from 'yup';
import { IOphalenAfdelingenResultElement } from '../../../../../../shared/src/api/v2/relatie/afdeling';
import ProducttypeSelectie from '../../../../components/formulier/ProducttypeSelectie';
import { IFilterSchemaFilter } from '../../../../../../shared/src/models/filter';
import DatumKiezer from '../../../../components/formulier/DatumKiezer';
import VinkVeld from '../../../../components/formulier/VinkVeld';
import { RootStoreContext } from '../../../../stores/RootStore';
import { EResultType } from '../../../../stores/CheckStore';
import nieuw from '../../../../api/v2/contract/nieuw';
import LocatieSelectie from '../../../../components/formulier/LocatieSelectie';

export interface IDialoogResult {
  // afdeling: IOphalenAfdelingenResultElement;
}

interface IFormikValues {
  referentiecode: string | null;
  serienummer: string | null;
  notities: string | null;
  typeID: number | null;
  productdatum: Date | string | null;
  nieuw: boolean;
  defect: boolean;
  locID: number | null;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  referentiecode: 'Ref.code',
  serienummer: 'Serienummer',
  notities: 'Notities',
  typeID: 'Type',
  productdatum: 'Productdatum',
  defect: 'Defect',
  nieuw: 'Product is nieuw',
  locID: 'Locatie',
};

interface IProps extends IDialoogProps<IDialoogResult> {}

const ToevoegenDialoog = (props: IProps) => {
  const { checkStore } = useContext(RootStoreContext);

  const [initialValues, setInitialValues] = useState<IRemoteData<IFormikValues>>(
    createPendingRemoteData(),
  );

  useEffect(() => {
    (async () => {
      const instellingen = await api.v2.instelling.ophalenInstellingen({});
      const magazijn = (
        await api.v2.magazijn.ophalenMagazijnen({
          filterSchema: { filters: [{ naam: 'IDS', data: [instellingen.Voorraad_MagID] }] },
        })
      ).magazijnen[0];

      setInitialValues(
        createReadyRemoteData({
          referentiecode: '',
          serienummer: null,
          notities: '',
          typeID: null,
          productdatum: null,
          nieuw: false,
          defect: false,
          locID: magazijn.LocID,
        }),
      );
    })();
  }, []);

  // useEffect(() => {
  //   const instellingen = await api.v2.instelling.ophalenInstellingen({});

  //   setInitialValues(
  //     createReadyRemoteData({
  //       referentiecode: '',
  //       serienummer: null,
  //       notities: '',
  //       typeID: null,
  //       productdatum: null,
  //       nieuw: false,
  //       defect: false,
  //       locID: null,
  //     }),
  //   );
  // }, []);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      const params = {
        typeID: values.typeID!,
        // locID: null,
        // cntBasisID: null,
        referentiecode: values.referentiecode !== '' ? values.referentiecode : null,
        notities: values.notities !== '' ? values.notities : null,
        inkOpdID: null,
        inkoopPrijs: 0,
        productdatum: values.productdatum,
        nieuw: values.nieuw,
        defect: values.defect,
      };

      const checkData = await api.v2.product.checkToevoegenProduct(params);
      if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
        actions.setSubmitting(false);
        return;
      }

      const result = await checkStore.bevestigen({
        inhoud: (
          <span>
            Product vastleggen?
            <br />
            <br />
            Het product krijgt de status <b>Geparkeerd</b> en zal onder het tabje Geparkeerd
            verschijnen. Daar kun je eventueel de gegevens aanvullen en/of aanpassen.
          </span>
        ),
      });

      if (result.type === EResultType.Annuleren) {
        actions.setSubmitting(false);
        return;
      }

      const toevoegenResult = await api.v2.product.toevoegenProduct(params);

      props.onSuccess({});

      actions.setSubmitting(false);
    },
    [],
  );

  const validationSchema = useMemo(() => {
    const fields: Partial<Record<keyof IFormikValues, any>> = {
      typeID: Yup.number().required(),
    };
    return Yup.object().shape(fields);
  }, []);

  return (
    <Dialoog index={props.dialoogIndex ?? 0}>
      <ModalHeader>
        <ModalTitle>Toevoegen product</ModalTitle>
      </ModalHeader>
      {initialValues.state === ERemoteDataState.Pending ? (
        <ModalBody>
          <LoadingSpinner />
        </ModalBody>
      ) : (
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialValues.data!}
          validationSchema={validationSchema}
          render={(formikProps) => <ToevoegenInner {...props} formikProps={formikProps} />}
        />
      )}
    </Dialoog>
  );
};

const ToevoegenInner = (props: IProps & { formikProps: FormikProps<IFormikValues> }) => {
  const formikProps = props.formikProps;
  const { isSubmitting, submitForm } = formikProps;

  const producttypeSelectieResolve = useCallback(async () => {
    return await api.v2.product.type.ophalenProducttypen({
      // filterSchema: {
      //   filters: [
      //     // props.prodSrtID === null
      //     //   ? null
      //     //   : {
      //     //       naam: 'PRODSRT_IDS',
      //     //       data: [props.prodSrtID],
      //     //     },
      //     // props.merkID === null
      //     //   ? null
      //     //   : {
      //     //       naam: 'MERK_IDS',
      //     //       data: [props.merkID],
      //     //     },
      //     // props.uitgeslotenTypeIDs.length === 0
      //     //   ? null
      //     //   : {
      //     //       naam: 'IDS',
      //     //       data: props.uitgeslotenTypeIDs,
      //     //       inverteren: true,
      //     //     },
      //   ].filter((x) => x !== null) as IFilterSchemaFilter[],
      // },
    });
  }, []);

  return (
    <>
      <ModalBody>
        <div>
          <label>{veldnamen.locID}</label>
          <Field
            name={nameOf<IFormikValues>('locID')}
            render={(fieldProps: FieldProps<IFormikValues>) => {
              const { field, form } = fieldProps;

              return (
                <LocatieSelectie
                  locID={fieldProps.field.value}
                  onChange={(locID) => {
                    form.setFieldValue(field.name, locID);
                  }}
                  options={{
                    bijEnkeleDirectVoorselecteren: true,
                  }}
                />
              );
            }}
          />
        </div>

        <div className="mt-3">
          <label>Type/merk</label>
          <Field
            name={nameOf<IFormikValues>('typeID')}
            render={(fieldProps: FieldProps<IFormikValues>) => {
              const { field, form } = fieldProps;
              return (
                <>
                  <ProducttypeSelectie
                    typeID={field.value}
                    onChange={(id) => form.setFieldValue(field.name, id)}
                    resolve={producttypeSelectieResolve}
                  />
                  {/* <FormikVeldFout fieldProps={fieldProps} /> */}
                </>
              );
            }}
          />
        </div>

        <div className="d-flex align-items-center mt-3">
          <Field
            name={nameOf<IFormikValues>('nieuw')}
            render={(fieldProps: FieldProps<IFormikValues>) => {
              const { field, form } = fieldProps;
              return (
                <VinkVeld
                  aangevinkt={field.value}
                  onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                />
              );
            }}
          />
          <span className="ml-2">{veldnamen.nieuw}</span>
        </div>

        <div className="mt-3">
          <label>Productdatum (zo goed mogelijk inschatten, bepaalt de leeftijd)</label>
          <Field
            name={nameOf<IFormikValues>('productdatum')}
            render={({ field, form }: FieldProps<IFormikValues>) => {
              return (
                <div className="d-flex ">
                  <DatumKiezer
                    onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                    waarde={field.value}
                    determineValidDate={() => {
                      return true;
                    }}
                    determinePreviousValidDate="ONBEGRENST"
                    determineNextValidDate="ONBEGRENST"
                    isClearable
                  />
                </div>
              );
            }}
          />
        </div>

        <div className="mt-3">
          <label>Referentiecode</label>
          <Field
            name={nameOf<IFormikValues>('referentiecode')}
            render={(fieldProps: FieldProps<IFormikValues>) => {
              const { field } = fieldProps;
              return <input {...field} className="form-control" type="text" />;
            }}
          />
        </div>

        <div className="mt-3">
          <label>Serienummer</label>
          <Field
            name={nameOf<IFormikValues>('serienummer')}
            render={(fieldProps: FieldProps<IFormikValues>) => {
              const { field } = fieldProps;
              return <input {...field} className="form-control" type="text" />;
            }}
          />
        </div>

        <div className="d-flex align-items-center mt-3">
          <Field
            name={nameOf<IFormikValues>('defect')}
            render={(fieldProps: FieldProps<IFormikValues>) => {
              const { field, form } = fieldProps;
              return (
                <VinkVeld
                  aangevinkt={field.value}
                  onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                />
              );
            }}
          />
          <span className="ml-2">{veldnamen.defect}</span>
        </div>

        <div className="mt-3">
          <label>Notities</label>
          <Field
            name={nameOf<IFormikValues>('notities')}
            render={(fieldProps: FieldProps<IFormikValues>) => {
              const { field, form } = fieldProps;
              return <textarea {...field} className="form-control" rows={2} />;
            }}
          />
        </div>
      </ModalBody>
      <ModalFooter className="d-flex flex-row justify-content-start">
        <button
          className="btn btn-primary d-flex align-items-center justify-content-center"
          onClick={submitForm}
          style={{ width: 100 }}
          disabled={isSubmitting || !formikProps.isValid}
        >
          {isSubmitting && (
            <span className="mr-2">
              <LoadingSpinner soort="light" grootte="15px" dikte="2px" />
            </span>
          )}
          Ok
        </button>
        <button
          className="btn btn-secondary"
          onClick={props.onAnnuleren}
          style={{ width: 100 }}
          disabled={isSubmitting}
        >
          Annuleren
        </button>
      </ModalFooter>
    </>
  );
};

export default ToevoegenDialoog;
