import api from '../../../index';
import {
  IMuterenPermissieXRolParams,
  IMuterenPermissieXRolResult,
  IOphalenPermissieXRolsParams,
  IOphalenPermissieXRolsResult,
} from '../../../../../../shared/src/api/v2/autorisatie/permissieXRol/index';

const permissie = {
  ophalenPermissieXRols: async (
    params: IOphalenPermissieXRolsParams,
  ): Promise<IOphalenPermissieXRolsResult> => {
    return await api.post('/v2/autorisatie/permissie-x-rol/ophalen-permissie-x-rols', params);
  },
  muterenPermissieXRol: async (
    params: IMuterenPermissieXRolParams,
  ): Promise<IMuterenPermissieXRolResult> => {
    return await api.post('/v2/autorisatie/permissie-x-rol/muteren-permissie-x-rol', params);
  },
};

export default permissie;
