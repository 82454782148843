import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import IDialoogProps from '../../../../../../../../core/IDialoogProps';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { Field, FieldProps, Formik, FormikActions, FormikErrors, FormikProps } from 'formik';
import Combobox, { IOptie } from '../../../../../../../../components/formulier/Combobox';
import { RootStoreContext } from '../../../../../../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import VinkVeld from '../../../../../../../../components/formulier/VinkVeld';
import api from '../../../../../../../../api';
import { EResultType } from '../../../../../../../../stores/CheckStore';
import * as Yup from 'yup';
import teksten from '../../../../../../../../bedrijfslogica/teksten';
import FormikVeldFout from '../../../../../../../../components/formulier/FormikVeldFout';
import ContactSelectie, {
  EType,
} from '../../../../../../../../components/formulier/ContactSelectie';
import { IOphalenRedenenAnnuleringResultElement } from '../../../../../../../../../../shared/src/api/v2/contract/annulering';
import Dialoog from '../../../../../../../../components/dialogen/Dialoog';
import UitlegTooltip from '../../../../../../../../components/formulier/UitlegTooltip';
import { EKanaal, EMutatiebron } from '../../../../../../../../bedrijfslogica/enums';
import HorizontaleScheidingslijn from '../../../../../../../../components/layout/HorizontaleScheidingslijn';
import InactiefOverlay from '../../../../../../../../components/InactiefOverlay';

interface IProps extends IDialoogProps<{}> {
  relID: number;
  cntIDs: number[];
  cntRdAnnID?: number;
  persID?: number;
}

interface IFormikValues {
  cntRdAnnID: number | null;
  persID: number | null;
  bevestigingSturen: boolean;
  directVersturen: boolean;
  interneMutatie: boolean;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  cntRdAnnID: 'Reden',
  persID: 'Persoon',
  bevestigingSturen: 'Bevestiging sturen',
  directVersturen: 'Direct versturen',
  interneMutatie: 'Interne mutatie',
};

const AnnulerenContractDialoog = observer((props: IProps) => {
  const { onAnnuleren, onSuccess, open, relID, cntIDs } = props;
  const { checkStore } = useContext(RootStoreContext);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [redenen, setRedenen] = useState<IOphalenRedenenAnnuleringResultElement[] | null>(null);

  // const [cntRdAnnID, setCntRdAnnID] = useState<number | null>(
  //   props.cntRdAnnID ? props.cntRdAnnID : null,
  // );
  const [persID, setPersID] = useState<number | null>(props.persID ?? null);

  useEffect(() => {
    (async () => {
      const result = await api.v2.contract.annulering.ophalenRedenenAnnulering({});
      setRedenen(result);
    })();
  }, []);

  // useEffect(() => {
  //   if (contractBeeindigingStore.redenenBeeindiging !== null) {
  //     return;
  //   }
  //
  //   contractBeeindigingStore.ophalenRedenenBeeindiging();
  // }, [contractBeeindigingStore.redenenBeeindiging]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      setHasSubmitted(true);

      // Controle stap validatie (voor foutmeldingen na OK)
      const errors = await actions.validateForm(values);
      const hasError = Object.keys(errors).some((key) => (errors as any)[key] !== null);
      if (hasError) {
        console.error(errors);
        actions.setSubmitting(false);
        return;
      }

      // De check-api wordt al aangeroepen bij openen van het modaal

      const checkData = await api.v2.contract.annulering.checkAnnulerenContracten({
        relID,
        cntIDs,
        annuleringsdatum: null,
        interneMutatie: values.interneMutatie,
      });

      if (
        (
          await checkStore.controleren({
            checkData,
          })
        ).type === EResultType.Annuleren
      ) {
        actions.setSubmitting(false);
        return;
      }

      if (
        (
          await checkStore.bevestigen({
            inhoud: `Contracten annuleren?`,
          })
        ).type === EResultType.Annuleren
      ) {
        actions.setSubmitting(false);
        return;
      }

      await api.v2.contract.annulering.annulerenContracten({
        annuleringsdatum: null,
        cntIDs,
        cntRdAnnID: values.cntRdAnnID!,
        mutatiebron: EMutatiebron.Intern,
        persID: values.persID!,
        relID: props.relID,
        interneMutatie: values.interneMutatie,
      });

      if (!values.interneMutatie) {
        if (values.bevestigingSturen) {
          await api.v2.contract.annulering.versturenBevestigingAnnulering({
            relID: props.relID,
            cntIDs,
            persID: values.persID!,
            taalID: null,
            kanalen: [EKanaal.Email],
          });
        }
      }

      await onSuccess({});
      actions.setSubmitting(false);
    },
    [onSuccess, relID, cntIDs, setHasSubmitted],
  );

  const redenenOpties = useMemo<IOptie<number>[] | null>(() => {
    if (redenen === null) {
      return null;
    }

    return redenen.map(
      (reden): IOptie<number> => ({
        id: reden.CntRdAnnID,
        label: reden.Naam,
      }),
    );
  }, [redenen]);

  const initieleWaarden = useMemo<IFormikValues>(() => {
    return {
      cntRdAnnID: null,
      persID,
      bevestigingSturen: true,
      directVersturen: true,
      interneMutatie: false,
    };
  }, [persID]);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        cntRdAnnID: Yup.number().required(
          teksten.formulier.E_VERPLICHT_VELD({ veldnaam: veldnamen.cntRdAnnID }),
        ),
        persID: Yup.number().nullable(true),
        // .required(teksten.formulier.E_VERPLICHT_VELD({ veldnaam: veldnamen.persID })),
      }),
    [],
  );

  return (
    <Dialoog index={props.dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Annuleren contracten</ModalTitle>
      </ModalHeader>
      <Formik<IFormikValues>
        onSubmit={handleSubmit}
        initialValues={initieleWaarden}
        validationSchema={validationSchema}
        render={(formikProps: FormikProps<IFormikValues>) => {
          const { submitForm, isSubmitting, values, isValid } = formikProps;
          return (
            <>
              <ModalBody>
                <div className="form-group">
                  <div className="row">
                    <div className="col-12 d-flex align-items-center">
                      <Field
                        name="interneMutatie"
                        render={(fieldProps: FieldProps<IFormikValues>) => (
                          <>
                            <VinkVeld
                              aangevinkt={fieldProps.field.value}
                              onGewijzigd={(x) =>
                                fieldProps.form.setFieldValue(fieldProps.field.name, x)
                              }
                            />
                            <FormikVeldFout fieldProps={fieldProps} directTonen={hasSubmitted} />
                          </>
                        )}
                      />
                      <span className="ml-2 mr-2">{veldnamen.interneMutatie}</span>
                      <UitlegTooltip inhoud="Intern wil zeggen dat de mutatie op ons initiatief gedaan is" />
                    </div>

                    <div className="col-12 mt-3">
                      <label>{veldnamen.cntRdAnnID}</label>
                      <Field
                        name="cntRdAnnID"
                        render={(fieldProps: FieldProps<IFormikValues>) => (
                          <>
                            <Combobox
                              geselecteerd={fieldProps.field.value}
                              opties={redenenOpties === null ? [] : redenenOpties}
                              onSelectieChange={(id) => {
                                fieldProps.form.setFieldValue(fieldProps.field.name, id);
                              }}
                              legeOptieTonen
                              options={{
                                legeOptieTekst: 'Geen reden',
                              }}
                            />
                            <FormikVeldFout fieldProps={fieldProps} directTonen={hasSubmitted} />
                          </>
                        )}
                      />
                    </div>

                    {!values.interneMutatie && (
                      <>
                        <div className="w-100 d-flex align-items-center justify-content-center mt-4">
                          <HorizontaleScheidingslijn width="93%" />
                        </div>

                        <div className="col-12 mt-3">
                          <div className="d-flex">
                            <Field
                              name="bevestigingSturen"
                              render={(fieldProps: FieldProps<IFormikValues>) => (
                                <>
                                  <div className="mr-2" style={{ marginTop: 5 }}>
                                    <VinkVeld
                                      aangevinkt={fieldProps.field.value}
                                      onGewijzigd={(x) =>
                                        fieldProps.form.setFieldValue(fieldProps.field.name, x)
                                      }
                                    />
                                  </div>
                                  <InactiefOverlay
                                    isInactief={!fieldProps.field.value}
                                    rootStyle={{ width: '100%' }}
                                    element={
                                      <div className="flex-fill d-flex flex-column p-2">
                                        <label>Bevestiging sturen naar</label>
                                        <Field
                                          name="persID"
                                          render={(fieldProps: FieldProps<IFormikValues>) => {
                                            const { field, form } = fieldProps;
                                            return (
                                              <>
                                                <ContactSelectie
                                                  alleenVoorRelIDs={[relID]}
                                                  value={
                                                    field.value === null
                                                      ? null
                                                      : {
                                                          persID: field.value,
                                                          orgID: null,
                                                        }
                                                  }
                                                  onChange={(x) =>
                                                    form.setFieldValue(
                                                      field.name,
                                                      x?.persoon?.PersID ?? null,
                                                    )
                                                  }
                                                  options={{
                                                    types: [EType.Persoon],
                                                    bijEnkeleDirectVoorselecteren: true,
                                                  }}
                                                />
                                                <FormikVeldFout
                                                  fieldProps={fieldProps}
                                                  directTonen={hasSubmitted}
                                                />
                                              </>
                                            );
                                          }}
                                        />

                                        <div className="d-flex align-items-center mt-3">
                                          <Field
                                            name="directVersturen"
                                            render={(fieldProps: FieldProps<IFormikValues>) => (
                                              <>
                                                <VinkVeld
                                                  aangevinkt={fieldProps.field.value}
                                                  onGewijzigd={(x) =>
                                                    fieldProps.form.setFieldValue(
                                                      fieldProps.field.name,
                                                      x,
                                                    )
                                                  }
                                                />
                                                <span className="ml-2">
                                                  {veldnamen.directVersturen}
                                                </span>
                                              </>
                                            )}
                                          />
                                        </div>
                                      </div>
                                    }
                                  />
                                </>
                              )}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </ModalBody>
              <ModalFooter className="d-flex justify-content-start">
                <button
                  className="btn btn-primary"
                  onClick={submitForm}
                  disabled={isSubmitting || !isValid}
                  // disabled={isSubmitting}
                  style={{ width: 100 }}
                >
                  Ok
                </button>
                <button className="btn btn-secondary" onClick={onAnnuleren} style={{ width: 100 }}>
                  Annuleren
                </button>
              </ModalFooter>
            </>
          );
        }}
      />
    </Dialoog>
  );
});

export default AnnulerenContractDialoog;
