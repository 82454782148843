import React, { useMemo } from 'react';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import { formatteerAdresV2, formatteerRelatieNaam } from '../../../../helpers';
import { IconOrganisatie, IconPersoon } from '../../../Icons';
import { ISelecterenRelatiesResultElement } from '../../../../../../shared/src/api/v2/relatie/Selectie';
import { Root } from './style';
import _ from 'lodash';

interface IProps {
  relatie: ISelecterenRelatiesResultElement;
  onClick: () => void;
}

const Zoekresultaat = (props: IProps) => {
  const relatie = props.relatie;
  const icon = useMemo(() => {
    switch (relatie.Relatiesoort) {
      case 'P':
        return <IconPersoon style={{ width: 22, height: 22, fill: Kleur.Grijs }} />;
      case 'O':
        return <IconOrganisatie style={{ width: 22, height: 22, fill: Kleur.Grijs }} />;
    }
    throw new Error();
  }, [relatie.Relatiesoort]);

  const naam = useMemo(() => {
    switch (relatie.Relatiesoort) {
      case 'P':
        return formatteerRelatieNaam({
          achternaam: relatie.persoon!.Achternaam,
          aanhefKort: relatie.persoon!.geslacht.AanhefKort,
          voorletters: relatie.persoon!.Voorletters,
          voornaam: relatie.persoon!.Voornaam,
          voorvoegsel: relatie.persoon!.Voorvoegsel,
        });
      case 'O':
        return relatie.organisatie!.Naam;
    }
    throw new Error();
  }, [relatie.Relatiesoort, relatie.persoon, relatie.organisatie]);

  const contractenOpsomming = useMemo(() => {
    const maxAantalWeerTeGevenModelcodes = 5;
    const modelcodes = _.uniq(relatie.contracten.map((x) => x.Modelcode));

    const modelcodesWeerTeGeven =
      modelcodes.length > maxAantalWeerTeGevenModelcodes
        ? modelcodes.slice(0, maxAantalWeerTeGevenModelcodes)
        : modelcodes;

    const opsomming =
      modelcodes.length !== 0 ? (
        <span>
          {modelcodesWeerTeGeven.join(', ')}
          {modelcodes.length > modelcodesWeerTeGeven.length ? '...' : ''}
        </span>
      ) : null;

    return opsomming;
  }, [relatie]);

  return (
    <Root onClick={props.onClick}>
      <span className="d-flex align-items-center mb-1">
        {icon}
        <span className="font-weight-bold ml-2">{naam}</span>
        {relatie.NietGebruiken && (
          <span className="ml-2" style={{ color: Kleur.Rood }}>
            Niet gebruiken
          </span>
        )}
      </span>

      {relatie.adres !== null && (
        <span>
          {formatteerAdresV2({
            bisnummer: relatie.adres.Bisnummer,
            straatnaam: relatie.adres.Straatnaam,
            huisnummer: relatie.adres.Huisnummer,
            postcode: relatie.adres.Postcode,
            plaatsnaam: relatie.adres.Plaatsnaam,
            landnaamKort: relatie.adres.LandnaamKort,
            landnaamEnum: relatie.adres.LandnaamEnum,
          })}
        </span>
      )}

      <div className="d-flex align-items-center">
        <span className="relatienr">{relatie.Relatienummer}</span>
        <span className="ml-2" style={{ color: Kleur.Groen }}>
          {relatie.contracten.length === 0
            ? ''
            : relatie.contracten.length === 1
            ? '1 Lopend contract'
            : `${relatie.contracten.length} Lopende contracten`}
        </span>
      </div>

      <div className="d-flex align-items-center">{contractenOpsomming}</div>
    </Root>
  );
};

export default Zoekresultaat;
