import ICheckData from '../../../../../../shared/src/models/ICheckData';
import api from '../../../index';
import {
  IToevoegenOpdrachtenParams,
  IToevoegenOpdrachtenResult,
  IToevoegenStandaardLeveropdrachtenParams,
  IToevoegenStandaardLeveropdrachtenResult,
  // IToevoegenRegelParams,
  // IToevoegenRegelResult,
  // IToevoegenStandaardOpdrachtenParams,
  // IToevoegenStandaardOpdrachtenResult,
  // IToevoegenMontageregelParams,
  // IToevoegenMontageregelResult,
} from '../../../../../../shared/src/api/v2/transport/opdracht/nieuw';

const nieuw = {
  checkToevoegenOpdrachten: async (params: IToevoegenOpdrachtenParams): Promise<ICheckData> => {
    return await api.post('v2/transport/opdracht/nieuw/check-toevoegen-opdrachten', params);
  },
  toevoegenOpdrachten: async (
    params: IToevoegenOpdrachtenParams,
  ): Promise<IToevoegenOpdrachtenResult> => {
    return await api.post('v2/transport/opdracht/nieuw/toevoegen-opdrachten', params);
  },
  checkToevoegenStandaardLeveropdrachten: async (
    params: IToevoegenStandaardLeveropdrachtenParams,
  ): Promise<ICheckData> => {
    return await api.post(
      'v2/transport/opdracht/nieuw/check-toevoegen-standaard-leveropdrachten',
      params,
    );
  },
  toevoegenStandaardLeveropdrachten: async (
    params: IToevoegenStandaardLeveropdrachtenParams,
  ): Promise<IToevoegenStandaardLeveropdrachtenResult> => {
    return await api.post(
      'v2/transport/opdracht/nieuw/toevoegen-standaard-leveropdrachten',
      params,
    );
  },
  // checkToevoegenRegel: async (params: IToevoegenRegelParams): Promise<ICheckData> => {
  //   return await api.post('v2/transport/opdracht/nieuw/check-toevoegen-regel', params);
  // },
  // toevoegenRegel: async (params: IToevoegenRegelParams): Promise<IToevoegenRegelResult> => {
  //   return await api.post('v2/transport/opdracht/nieuw/toevoegen-regel', params);
  // },
  // checkToevoegenStandaardOpdrachten: async (
  //   params: IToevoegenStandaardOpdrachtenParams,
  // ): Promise<ICheckData> => {
  //   return await api.post(
  //     'v2/transport/opdracht/nieuw/check-toevoegen-standaard-opdrachten',
  //     params,
  //   );
  // },
  // toevoegenStandaardOpdrachten: async (
  //   params: IToevoegenStandaardOpdrachtenParams,
  // ): Promise<IToevoegenStandaardOpdrachtenResult> => {
  //   return await api.post('v2/transport/opdracht/nieuw/toevoegen-standaard-opdrachten', params);
  // },
  // toevoegenMontageregel: async (
  //   params: IToevoegenMontageregelParams,
  // ): Promise<IToevoegenMontageregelResult> => {
  //   return await api.post('v2/transport/opdracht/nieuw/toevoegen-montageregel', params);
  // },
};

export default nieuw;
