import { observer } from 'mobx-react-lite';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IEntiteitProps } from '../../../../../../../../components/kaart/EntiteitContainer';
import {
  ASPKolom,
  EAspKolomBreedteType,
  ESortering,
} from '../../../../../../../../components/tabel/ASPTabel/types';
import MenuLayout from '../../../../../../../../components/MenuLayout';
import api from '../../../../../../../../api';
import LoadingSpinner from '../../../../../../../../components/Gedeeld/LoadingSpinner';
import ASPTabel from '../../../../../../../../components/tabel/ASPTabel';
import useUrlState from '../../../../../../../../core/useUrlState';
import { GlobaleRendererContext } from '../../../../../../../../one-off-components/GlobaleRenderer';
import Dialoog from '../../../../../../../../components/dialogen/Dialoog';
import { RootStoreContext } from '../../../../../../../../stores/RootStore';
import { EResultType } from '../../../../../../../../stores/CheckStore';
import { Kleur } from '../../../../../../../../bedrijfslogica/constanten';
import { IconToevoegen } from '../../../../../../../../components/Icons';
import { IOphalenComplexenResultElement } from '../../../../../../../../../../shared/src/api/v2/relatie/complex';
import WijzigenDialoog from './WijzigenDialoog';
import ToevoegenDialoog from './ToevoegenDialoog';

interface IProps extends RouteComponentProps {
  relID: number;
}

interface IWijzigenDialoogState {
  id: number;
}
interface IToevoegenDialoogState {}

export interface IUrlState {
  selectie: number[];
  wijzigenDialoogState: IWijzigenDialoogState | null;
  toevoegenDialoogState: IToevoegenDialoogState | null;
}

const defaultUrlState: IUrlState = {
  selectie: [],
  wijzigenDialoogState: null,
  toevoegenDialoogState: null,
};

enum EKolom {
  Naam,
  Omschrijving,
}

const Beheer = (props: IProps) => {
  const { checkStore } = useContext(RootStoreContext);

  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);

  const [complexen, setComplexen] = useState<IOphalenComplexenResultElement[] | null>(null);

  const ophalenComplexen = useCallback(async () => {
    const complexen = (
      await api.v2.relatie.complex.ophalenComplexen({
        filterSchema: { filters: [{ naam: 'REL_IDS', data: [props.relID] }] },
      })
    ).complexen;

    setComplexen(complexen);
  }, [props.relID]);

  useEffect(() => {
    ophalenComplexen();
  }, [ophalenComplexen]);

  const keyExtractor = useCallback((rij: IOphalenComplexenResultElement) => rij.ID, []);

  const kolommen = useMemo<ASPKolom<EKolom, IOphalenComplexenResultElement>[]>(
    () => [
      {
        key: EKolom.Naam,
        label: 'Naam',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 200,
        renderer: (rij) => rij.Naam,
      },
      {
        key: EKolom.Omschrijving,
        label: 'Omschrijving',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 200,
        renderer: (rij) => rij.Omschrijving,
      },
    ],
    [],
  );

  return (
    <>
      <MenuLayout
        menu={
          <div className="d-flex">
            <button
              className="btn btn-sm btn-light"
              style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
              onClick={() => setUrlStateSync('toevoegenDialoogState', {})}
            >
              <IconToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
              <span className="ml-2">Toevoegen complex</span>
            </button>
            {/* <div className="d-flex flex-fill ml-3"></div> */}
          </div>
        }
        body={
          <div className="d-flex flex-column flex-fill">
            {complexen === null ? (
              <div className="flex-fill d-flex align-items-center justify-content-center">
                <LoadingSpinner />
              </div>
            ) : (
              <div className="d-flex flex-column flex-fill">
                <ASPTabel
                  rijen={complexen}
                  kolommen={kolommen}
                  keyExtractor={keyExtractor}
                  // selectie={urlState.selectie}
                  // onSelectieChange={(selectie) => setUrlStateSync('selectie', selectie)}
                  // sortering={urlState.sortering}
                  // onSorteringChange={(sortering) => setUrlStateSync('sortering', sortering)}
                  onWijzigenRij={async (x) => {
                    setUrlStateSync('wijzigenDialoogState', {
                      id: x.ID,
                    });
                  }}
                  onVerwijderenRij={async (x) => {
                    const params = { ids: [x.ID] };

                    await api.v2.relatie.complex.verwijderenComplexen(params);

                    ophalenComplexen();
                  }}
                  verwijderenRijConfirmatie={async (x, verwijderen) => {
                    const params = { ids: [x.ID] };

                    const checkData = await api.v2.relatie.complex.checkVerwijderenComplexen(
                      params,
                    );
                    if (
                      (await checkStore.controleren({ checkData })).type === EResultType.Annuleren
                    ) {
                      return;
                    }

                    if (
                      (
                        await checkStore.bevestigen({
                          inhoud: <span>Wil je dit complex verwijderen?</span>,
                        })
                      ).type === EResultType.Annuleren
                    ) {
                      return;
                    }

                    await verwijderen();
                  }}
                />
              </div>
            )}
          </div>
        }
      />
      {urlState.wijzigenDialoogState !== null && (
        <WijzigenDialoog
          open
          id={urlState.wijzigenDialoogState.id}
          onSuccess={() => {
            ophalenComplexen();
            setUrlStateSync('wijzigenDialoogState', null);
          }}
          onAnnuleren={() => [setUrlStateSync('wijzigenDialoogState', null)]}
        />
      )}
      {urlState.toevoegenDialoogState !== null && (
        <ToevoegenDialoog
          open
          onSuccess={() => {
            ophalenComplexen();
            setUrlStateSync('toevoegenDialoogState', null);
          }}
          onAnnuleren={() => [setUrlStateSync('toevoegenDialoogState', null)]}
        />
      )}
    </>
  );
};

export default withRouter(Beheer);
