import {
  IOphalenRedenenAfkeuringParams,
  IOphalenRedenenAfkeuringResult,
  IAfkeurenContractenParams,
  IAfkeurenContractenResult,
  ISelectieAfkeurenContractenParams,
  ITerugdraaienAfkeurenContractenParams,
  ITerugdraaienAfkeurenContractenResult,
} from '../../../../../shared/src/api/v2/contract/afkeuring';
import api from '../..';
import ICheckData from '../../../../../shared/src/models/ICheckData';

const afkeuring = {
  checkSelectieAfkeurenContracten: async (
    params: ISelectieAfkeurenContractenParams,
  ): Promise<ICheckData> => {
    return await api.post('/v2/contract/afkeuring/check-selectie-afkeuren-contracten', params);
  },
  checkAfkeurenContracten: async (params: IAfkeurenContractenParams): Promise<ICheckData> => {
    return await api.post('/v2/contract/afkeuring/check-afkeuren-contracten', params);
  },
  afkeurenContracten: async (
    params: IAfkeurenContractenParams,
  ): Promise<IAfkeurenContractenResult> => {
    return await api.post('/v2/contract/afkeuring/afkeuren-contracten', params);
  },
  terugdraaienAfkeurenContracten: async (
    params: ITerugdraaienAfkeurenContractenParams,
  ): Promise<ITerugdraaienAfkeurenContractenResult> => {
    return await api.post('/v2/contract/afkeuring/terugdraaien-afkeuren-contracten', params);
  },
  checkTerugdraaienAfkeurenContracten: async (
    params: ITerugdraaienAfkeurenContractenParams,
  ): Promise<ICheckData> => {
    return await api.post('/v2/contract/afkeuring/check-terugdraaien-afkeuren-contracten', params);
  },
  ophalenRedenenAfkeuring: async (
    params: IOphalenRedenenAfkeuringParams,
  ): Promise<IOphalenRedenenAfkeuringResult> => {
    return await api.post('/v2/contract/afkeuring/ophalen-redenen-afkeuring', params);
  },
};

export default afkeuring;
