import React, { useMemo } from 'react';
import { matchPath, Redirect, Route, RouteComponentProps } from 'react-router-dom';
import Tabblad, { ITabblad } from '../../../components/layout/Tabblad';
// import Inkoop from './Inkoop';
// import Service from './Service';
// import Pendel from './Pendel';
// import Magazijn from './Magazijn';
import Incasso from './Incasso';

interface IProps extends RouteComponentProps<{}> {}

export enum ETabblad {
  // Inkoop = 1,
  // Service = 2,
  // Transport = 3,
  // Pendel = 4,
  // Magazijn = 5,
  Incasso = 6,
}

const urlTabbladMap: Record<ETabblad, string> = {
  // [ETabblad.Inkoop]: 'inkoop',
  // [ETabblad.Service]: 'service',
  // [ETabblad.Pendel]: 'pendel',
  // [ETabblad.Magazijn]: 'magazijn',
  [ETabblad.Incasso]: 'incasso',
};

const Diensten: React.FC<IProps> = (props) => {
  const tabbladen = useMemo<ITabblad<ETabblad>[]>(
    () => [
      // {
      //   id: ETabblad.Inkoop,
      //   label: 'Inkoop',
      //   content: Inkoop,
      // },
      // {
      //   id: ETabblad.Service,
      //   label: 'Service',
      //   content: Service,
      // },
      // {
      //   id: ETabblad.Transport,
      //   label: 'Transport',
      //   content: Transport,
      // },
      // {
      //   id: ETabblad.Pendel,
      //   label: 'Pendel',
      //   content: Pendel,
      // },
      // {
      //   id: ETabblad.Magazijn,
      //   label: 'Magazijn',
      //   content: Magazijn,
      // },
      // {
      //   id: ETabblad.Incasso,
      //   label: 'Incasso',
      //   content: Incasso,
      // },
    ],
    [],
  );

  const tabblad = useMemo<ETabblad | null>(() => {
    const paths = Object.keys(urlTabbladMap).map((key) => {
      const tabblad = Number(key) as ETabblad;
      const url = urlTabbladMap[tabblad];
      return {
        tabblad,
        path: `${props.match.path}/${url}`,
      };
    });
    for (const pathObj of paths) {
      const match = matchPath(props.location.pathname, pathObj.path);
      if (match !== null) {
        return pathObj.tabblad;
      }
    }

    return null;
  }, [props.location.pathname]);

  return (
    <>
      <Route
        exact
        path={props.match.path}
        component={() => <Redirect to={`${props.match.path}/${urlTabbladMap[ETabblad.Incasso]}`} />}
      />
      {tabblad === null ? (
        <Redirect to={`${props.match.path}/${urlTabbladMap[ETabblad.Incasso]}`} />
      ) : (
        <>
          <Tabblad
            geselecteerd={tabblad}
            onSelectieChange={(identifier) =>
              props.history.push(`${props.match.path}/${urlTabbladMap[identifier]}`)
            }
            tabbladen={tabbladen}
          />
        </>
      )}
    </>
  );
};

export default Diensten;
