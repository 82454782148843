import { Field, FieldProps, Formik, FormikActions, FormikErrors, FormikProps } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import * as Yup from 'yup';
import api from '../../../../../api';
import teksten from '../../../../../bedrijfslogica/teksten';
import Dialoog from '../../../../../components/dialogen/Dialoog';
import MeertaligTekstveld from '../../../../../components/formulier/MeertaligTekstveld';
import IDialoogProps from '../../../../../core/IDialoogProps';
import nameof from '../../../../../core/nameOf';
import { EResultType } from '../../../../../stores/CheckStore';
import { RootStoreContext } from '../../../../../stores/RootStore';
import NumeriekVeld from '../../../../../components/formulier/NumeriekVeld';
import LoadingSpinner from '../../../../../components/Gedeeld/LoadingSpinner';
import Combobox from '../../../../../components/formulier/Combobox';
import { EBtwsoort } from '../../../../../bedrijfslogica/enums';
import { IOphalenBtwSoortenResultElement } from '../../../../../../../shared/src/api/v2/btw';
import {
  IOphalenWerkkostenRegelingenResult,
  IOphalenWerkkostenRegelingenResultElement,
} from '../../../../../../../shared/src/api/v2/boekhouding/boeking';

interface IDialoogResult {}

interface IProps extends IDialoogProps<IDialoogResult> {}

interface IFormikValues {
  nummer: number;
  naam: string;
  soort: number;
  btwSrtID: number | null;
  wkrRglID: number | null;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  nummer: 'Nummer',
  naam: 'Naam',
  soort: 'Soort',
  btwSrtID: 'BTW Soort',
  wkrRglID: 'Werkkostenregeling',
};

const groetboekSoorten = [
  {
    id: 0,
    label: 'A',
  },
  {
    id: 1,
    label: 'P',
  },
  {
    id: 2,
    label: 'B',
  },
  {
    id: 3,
    label: 'L',
  },
];

const ToevoegenDialoog: React.FC<IProps> = observer((props) => {
  const { children, dialoogIndex, onAnnuleren, onSuccess } = props;
  const { checkStore } = useContext(RootStoreContext);

  const [btwsoorten, setBtwsoorten] = useState<IOphalenBtwSoortenResultElement[] | null>(null);

  useEffect(() => {
    (async () => {
      const result = (await api.v2.btw.ophalenBtwSoorten({})).btwSoorten;

      setBtwsoorten(result);
    })();
  }, []);

  const [werkkostenregelingen, setWerkkostenregelingen] = useState<
    IOphalenWerkkostenRegelingenResultElement[] | null
  >(null);
  const ophalenWerkkostenRegelingen = useCallback(async () => {
    const result = await api.v2.boeking.ophalenWerkkostenRegelingen({});
    setWerkkostenregelingen(result.regelingen);
  }, []);
  useEffect(() => {
    ophalenWerkkostenRegelingen();
  }, [ophalenWerkkostenRegelingen]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      const params = {
        nummer: values.nummer,
        naam: values.naam,
        soort: groetboekSoorten[values.soort].label,
        btwSrtID: values.btwSrtID,
        wkrRglID: values.wkrRglID,
      };

      const checkData = await api.v2.boekhouding.boeking.grootboek.checkToevoegenGrootboek(params);
      const checkResult = await checkStore.controleren({
        checkData,
      });

      if (checkResult.type === EResultType.Annuleren) {
        actions.setSubmitting(false);
        return;
      }
      console.log(params);

      await api.v2.boekhouding.boeking.grootboek.toevoegenGrootboek(params);
      props.onSuccess({});
      actions.setSubmitting(false);
    },
    [],
  );

  const initialValues = useMemo<IFormikValues>(() => {
    return {
      nummer: 0,
      naam: '',
      soort: 0,
      btwSrtID: null,
      wkrRglID: null,
    };
  }, []);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        ID: Yup.string().required(),
      }),
    [],
  );

  const validate = useCallback((values: IFormikValues) => {
    const errors: FormikErrors<IFormikValues> = {};
    if (values.naam.length === 0) {
      errors.naam = teksten.formulier.E_VERPLICHT_VELD({ veldnaam: 'Naam' }) as any;
    }
    return errors;
  }, []);

  return (
    <Dialoog index={dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Toevoegen Grootboek</ModalTitle>
      </ModalHeader>
      {initialValues !== null && (
        <Formik<IFormikValues>
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validate={validate}
          enableReinitialize
          render={(formikProps: FormikProps<IFormikValues>) => {
            const { submitForm, isValid, isSubmitting } = formikProps;

            return (
              <>
                <ModalBody>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-12 mt-3">
                        <label>Nummer</label>
                        <Field
                          name={nameof<IFormikValues>('nummer')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            return (
                              <NumeriekVeld
                                waarde={fieldProps.field.value}
                                onChange={(x) =>
                                  fieldProps.form.setFieldValue(fieldProps.field.name, x)
                                }
                                min={0}
                              />
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <label>Naam</label>
                        <Field
                          name={nameof<IFormikValues>('naam')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            return (
                              <input type="text" className="form-control" {...fieldProps.field} />
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <label>Soort</label>
                        <Field
                          name={nameof<IFormikValues>('soort')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <Combobox
                                geselecteerd={field.value}
                                opties={groetboekSoorten}
                                onSelectieChange={(x) => form.setFieldValue(field.name, x)}
                                isWisbaar={false}
                                legeOptieTonen={false}
                              />
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <label>{veldnamen.btwSrtID}</label>
                        <Field
                          name={nameof<IFormikValues>('btwSrtID')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return btwsoorten === null ? (
                              <LoadingSpinner />
                            ) : (
                              <Combobox
                                geselecteerd={field.value}
                                opties={btwsoorten.map((btwsoort) => {
                                  return {
                                    id: btwsoort.ID,
                                    label: btwsoort.Naam,
                                  };
                                })}
                                onSelectieChange={(x) => form.setFieldValue(field.name, x)}
                              />
                            );
                          }}
                        />
                      </div>
                      <div className="col-12 mt-3">
                        <label>{veldnamen.wkrRglID}</label>
                        <Field
                          name={nameof<IFormikValues>('wkrRglID')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return werkkostenregelingen === null ? (
                              <LoadingSpinner />
                            ) : (
                              <Combobox
                                geselecteerd={field.value}
                                opties={werkkostenregelingen.map((regeling) => {
                                  return {
                                    id: regeling.ID,
                                    label: regeling.Naam,
                                  };
                                })}
                                onSelectieChange={(x) => form.setFieldValue(field.name, x)}
                              />
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </ModalBody>

                <ModalFooter className="d-flex flex-row justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    style={{ width: 100 }}
                    disabled={!isValid || isSubmitting}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={onAnnuleren}
                    style={{ width: 100 }}
                    disabled={isSubmitting}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Dialoog>
  );
});

export default ToevoegenDialoog;
