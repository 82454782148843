import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Root } from './style';
import { observer } from 'mobx-react-lite';
import Dropdown from 'react-bootstrap/Dropdown';
import { ActiesIcon, IconOrganisatie, IconPersoon } from '../Icons';
import { Kleur } from '../../bedrijfslogica/constanten';
import LoadingSpinner from '../Gedeeld/LoadingSpinner';
import PersoonVisualisatie, {
  IProps as IPersoonVisualisatieProps,
} from '../personalia/PersoonVisualisatie';
import OrganisatieVisualisatie, {
  IProps as IOrganisatieVisualisatieProps,
} from '../personalia/OrganisatieVisualisatie';
import ContactSelecterenDialoogV2 from '../ContactSelecterenDialoogV2';
import { RootStoreContext } from '../../stores/RootStore';
import { EResultType } from '../../stores/CheckStore';
import api from '../../api';

import { v4 as uuid } from 'uuid';
import PersoonSelectieDialoog, { ETabblad } from '../dialogen/PersoonSelectieDialoog';
import { IFormValues } from '../formulier/PersoonFormulier';
import { GlobaleRendererContext } from '../../one-off-components/GlobaleRenderer';

interface IOngekoppeldComponentProps {
  showKoppelDialoog: () => void;
}

interface IProps {
  persID: number | null;
  orgID: number | null;
  onChange: (persID: number | null, orgID: number | null) => void;
  iconStyle?: React.CSSProperties;
  textStyle?: React.CSSProperties;
  actiesIconStyle?: React.CSSProperties;
  ladenComponent?: React.ComponentType;
  ongekoppeldComponent?: React.ComponentType<IOngekoppeldComponentProps>;

  persoonDefaultFormValues?: Partial<IFormValues>;
  persoonVisualisatieProps?: Partial<IPersoonVisualisatieProps>;
  organisatieVisualisatieProps?: Partial<IOrganisatieVisualisatieProps>;
}

const ContactKoppelComponent: React.FC<IProps> = observer((props) => {
  const id = useMemo(() => uuid(), []);
  const { checkStore } = useContext(RootStoreContext);
  const globaleRenderContext = useContext(GlobaleRendererContext);

  const [persoonSelectieDialoogTabblad, setPersoonSelectieDialoogTabblad] = useState<ETabblad>(
    ETabblad.NieuwOfSelecteren,
  );

  const weergave = useMemo<JSX.Element | null>(() => {
    if (props.persID !== null) {
      return (
        <span className="d-flex align-items-center justify-content-center">
          <IconPersoon style={{ fill: '#808080', width: 17, height: 17, ...props.iconStyle }} />

          <span className="ml-2 d-flex align-items-center" style={{ position: 'relative', top: 1 }}>
            <PersoonVisualisatie
              persID={props.persID}
              naamComponent={(x) => <span style={props.textStyle}>{x.children}</span>}
              {...props.persoonVisualisatieProps}
            />
          </span>
        </span>
      );
    } else if (props.orgID !== null) {
      return (
        <span className="d-flex align-items-center justify-content-center">
          <IconOrganisatie style={{ fill: '#808080', width: 17, height: 17, ...props.iconStyle }} />

          <span className="ml-2 d-flex align-items-center" style={{ position: 'relative', top: 1 }}>
            <OrganisatieVisualisatie
              orgID={props.orgID}
              naamComponent={(x) => (
                <span
                  style={{ ...props.textStyle, cursor: 'pointer' }}
                  onClick={() => x.navigeerNaarRelatie()}
                >
                  {x.children}
                </span>
              )}
              {...props.organisatieVisualisatieProps}
            />
          </span>
        </span>
      );
    }

    return null;
  }, [
    props.persID,
    props.orgID,
    props.persoonVisualisatieProps,
    props.organisatieVisualisatieProps,
  ]);

  const OngekoppeldComp = useMemo<React.ComponentType<IOngekoppeldComponentProps>>(
    () =>
      props.ongekoppeldComponent ??
      ((p) => (
        <a
          href="#"
          onClick={(ev) => {
            ev.preventDefault();

            p.showKoppelDialoog();
          }}
        >
          Contact koppelen
        </a>
      )),
    [props.ongekoppeldComponent],
  );

  const toonPersoonSelectieDialoog = useCallback(async () => {
    await globaleRenderContext.render((renderProps) => (
      <PersoonSelectieDialoog
        tabblad={persoonSelectieDialoogTabblad}
        onTabbladChange={(x) => setPersoonSelectieDialoogTabblad(x)}
        open
        onSuccess={(result) => {
          renderProps.destroy();
          props.onChange(result.persID, null);
        }}
        onAnnuleren={() => renderProps.destroy()}
        defaultFormValues={props.persoonDefaultFormValues}
      />
    ));
  }, [
    globaleRenderContext.render,
    persoonSelectieDialoogTabblad,
    setPersoonSelectieDialoogTabblad,
    props.onChange,
    props.persoonDefaultFormValues,
  ]);

  return (
    <Root>
      <div className="gekoppeld-container" onClick={(ev) => ev.stopPropagation()}>
        {weergave === null ? (
          <div className="ongekoppeld-container">
            <OngekoppeldComp
              showKoppelDialoog={async () => {
                await toonPersoonSelectieDialoog();
              }}
            />
          </div>
        ) : (
          weergave
        )}
        <div className="flex-fill" />
        <Dropdown>
          <Dropdown.Toggle variant="light" id={id}>
            <ActiesIcon
              style={{
                fill: Kleur.Grijs,
                width: 16,
                height: 16,
                marginLeft: 5,
                ...props.actiesIconStyle,
              }}
            />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              onClick={async () => {
                await globaleRenderContext.render((renderProps) => (
                  <ContactSelecterenDialoogV2
                    open
                    onSuccess={async (res) => {
                      const contact = res.contact;
                      if (contact.persoon !== null) {
                        await api.v2.persoon.bijwerkenLijstRecent({
                          persID: contact.persoon.PersID,
                        });
                      }
                      props.onChange(
                        contact.persoon?.PersID ?? null,
                        contact.organisatie?.OrgID ?? null,
                      );
                      renderProps.destroy();
                    }}
                    onAnnuleren={() => renderProps.destroy()}
                  />
                ));
              }}
            >
              Koppelen
            </Dropdown.Item>
            <Dropdown.Item
              onClick={async () => {
                await toonPersoonSelectieDialoog();
              }}
            >
              Opvoeren persoon
            </Dropdown.Item>
            <Dropdown.Item onClick={() => alert('TODO')}>Opvoeren organisatie</Dropdown.Item>
            <Dropdown.Item
              onClick={async () => {
                if (
                  (await checkStore.bevestigen({ inhoud: 'Bevestig ontkoppelen contact' })).type ===
                  EResultType.Annuleren
                ) {
                  return;
                }
                props.onChange(null, null);
              }}
              disabled={!weergave}
            >
              Ontkoppelen
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </Root>
  );
});

export default ContactKoppelComponent;
