import React, { useCallback, useContext, useState, useMemo, useEffect } from 'react';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalHeader from 'react-bootstrap/ModalHeader';
import IDialoogProps from '../../../../../core/IDialoogProps';
import { observer } from 'mobx-react-lite';
import VinkVeld from '../../../../../components/formulier/VinkVeld';
import { RootStoreContext } from '../../../../../stores/RootStore';
import { EResultType } from '../../../../../stores/CheckStore';
import Dialoog from '../../../../../components/dialogen/Dialoog';
import api from '../../../../../api';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import ContactSelectie from '../../../../../components/formulier/ContactSelectie';
import { IOphalenOpdrachtregelsResultElement } from '../../../../../../../shared/src/api/v2/transport/opdracht';
import LoadingSpinner from '../../../../../components/Gedeeld/LoadingSpinner';
import BedragInput from '../../../../../components/formulier/BedragInput';
import nameof from '../../../../../core/nameOf';

interface IDialoogResult {}

interface IProps extends IDialoogProps<IDialoogResult> {
  trsRegID: number;
}

interface IFormikValues {
  geenReferentiecode: boolean;
  referentiecode: string;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  geenReferentiecode: 'Er hoeft geen ref.code opgeven te worden',
  referentiecode: 'Referentiecode',
};

const WijzigenReferentieDialoog: React.FC<IProps> = observer((props) => {
  const { dialoogIndex, onAnnuleren, onSuccess, open } = props;
  const { checkStore } = useContext(RootStoreContext);
  const [regel, setRegel] = useState<IOphalenOpdrachtregelsResultElement | null>(null);

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (regel === null) {
      return null;
    }
    return {
      geenReferentiecode: regel.GeenReferentiecode,
      referentiecode: regel.product!.Referentiecode === null ? '' : regel.product!.Referentiecode,
    };
  }, [regel]);

  const ophalenRegel = useCallback(async () => {
    const result = await api.v2.transport.opdracht.ophalenOpdrachtregels({
      filterSchema: { filters: [{ naam: 'IDS', data: [props.trsRegID] }] },
    });

    setRegel(result.regels[0]);
  }, [props.trsRegID]);

  useEffect(() => {
    ophalenRegel();
  }, [ophalenRegel]);

  const handleOk = useCallback(
    async (values: IFormikValues) => {
      const params = {
        trsRegID: props.trsRegID,
        geenReferentiecode: values.geenReferentiecode,
        referentiecode: values.referentiecode === '' ? null : values.referentiecode,
      };
      const checkData = await api.v2.transport.opdracht.referentie.checkWijzigenReferentie(params);
      if (
        (
          await checkStore.controleren({
            checkData,
          })
        ).type === EResultType.Annuleren
      ) {
        return;
      }

      const result = await api.v2.transport.opdracht.referentie.wijzigenReferentie(params);

      onSuccess({});
    },
    [props.trsRegID],
  );

  return (
    <Dialoog index={dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Referentiecode</ModalTitle>
      </ModalHeader>

      {initialValues === null ? (
        <div className="flex-fill d-flex align-items-center justify-content-center">
          <LoadingSpinner />
        </div>
      ) : (
        <Formik<IFormikValues>
          isInitialValid
          onSubmit={handleOk}
          initialValues={initialValues}
          render={(FormikProps) => {
            const { submitForm, isSubmitting, values } = FormikProps;
            return (
              <>
                <ModalBody>
                  <div>
                    <div className="d-flex">
                      <div className="d-flex align-items-center justify-content-center">
                        <Field
                          name="geenReferentiecode"
                          render={(x: FieldProps<IFormikValues>) => (
                            <VinkVeld
                              aangevinkt={x.field.value}
                              onGewijzigd={(aangevinkt: boolean) =>
                                x.form.setFieldValue(x.field.name, aangevinkt)
                              }
                            />
                          )}
                        />
                        <span className="ml-3">{veldnamen.geenReferentiecode}</span>
                      </div>
                    </div>

                    {!values.geenReferentiecode && (
                      <div className="row col-4 mt-3 mb-3">
                        <label>{veldnamen.referentiecode}</label>
                        <Field
                          name={nameof<IFormikValues>('referentiecode')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            return <input {...field} className="form-control" type="text" />;
                          }}
                        />
                      </div>
                    )}
                  </div>
                </ModalBody>
                <ModalFooter className="d-flex flex-row justify-content-start">
                  <button className="btn btn-primary" onClick={submitForm} style={{ width: 100 }}>
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={onAnnuleren}
                    style={{ width: 100 }}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Dialoog>
  );
});

export default WijzigenReferentieDialoog;
