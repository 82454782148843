import api from '..';
import {
  IOphalenGerelateerdeLocatiesParams,
  IOphalenGerelateerdeLocatiesResult,
  IToevoegenLocatieParams,
  IToevoegenLocatieResult,
  IOphalenLocatiesVanRelatieCorrespondentieadresParams,
  IOphalenLocatiesVanRelatieCorrespondentieadresResult,
  IBepalenLocatieIsAanwezigParams,
  BepalenLocatieIsAanwezigResult,
  IMuterenLocatieParams,
  IMuterenLocatieResult,
  IOphalenLocatieNiveausParams,
  IOphalenLocatieNiveausResult,
  ICorrigerenLocatieParams,
  ICorrigerenLocatieResult,
  IOphalenLocatiesParams,
  IOphalenLocatiesResult,
  IOphalenInterneLocatiesParams,
  IOphalenInterneLocatiesResult,
  IToevoegenInterneLocatiesParams,
  IToevoegenInterneLocatiesResult,
  IVerwijderenInterneLocatiesParams,
  IVerwijderenInterneLocatiesResult,
} from '../../../../shared/src/api/v2/locatie/locatie';
import ICheckData from '../../../../shared/src/models/ICheckData';

const locatie = {
  ophalenLocaties: async (params: IOphalenLocatiesParams) => {
    return await api.post<IOphalenLocatiesResult>('/v2/locatie/ophalen-locaties', params);
  },

  ophalenGerelateerdeLocaties: async (
    params: IOphalenGerelateerdeLocatiesParams,
  ): Promise<IOphalenGerelateerdeLocatiesResult> => {
    return await api.post('/v2/locatie/ophalen-gerelateerde-locaties', params);
  },
  toevoegenLocatie: async (params: IToevoegenLocatieParams): Promise<IToevoegenLocatieResult> => {
    return await api.post('/v2/locatie/toevoegen-locatie', params);
  },
  // ophalenLocaties: async (params: IOphalenLocatiesParams): Promise<IOphalenLocatiesResult> => {
  //   return await api.post('v2/locatie/ophalen-locaties', params);
  // },
  // ophalenLocatiesV2: async (
  //   params: IOphalenLocatiesV2Params,
  // ): Promise<IOphalenLocatiesV2Result> => {
  //   return await api.post('v2/locatie/ophalen-locaties-v2', params);
  // },
  ophalenLocatiesVanRelatieCorrespondentieadres: async (
    params: IOphalenLocatiesVanRelatieCorrespondentieadresParams,
  ): Promise<IOphalenLocatiesVanRelatieCorrespondentieadresResult> => {
    return await api.post('v2/locatie/ophalen-locaties-van-relatie-correspondentieadres', params);
  },
  bepalenLocatieIsAanwezig: async (
    params: IBepalenLocatieIsAanwezigParams,
  ): Promise<BepalenLocatieIsAanwezigResult> => {
    return await api.post('v2/locatie/bepalen-locatie-is-aanwezig', params);
  },
  corrigerenLocatie: async (
    params: ICorrigerenLocatieParams,
  ): Promise<ICorrigerenLocatieResult> => {
    return await api.post('v2/locatie/corrigeren-locatie', params);
  },
  muterenLocatie: async (params: IMuterenLocatieParams): Promise<IMuterenLocatieResult> => {
    return await api.post('v2/locatie/muteren-locatie', params);
  },
  ophalenLocatieNiveaus: async (
    params: IOphalenLocatieNiveausParams,
  ): Promise<IOphalenLocatieNiveausResult> => {
    return await api.post('v2/locatie/ophalen-locatie-niveaus', params);
  },
  ophalenInterneLocaties: async (
    params: IOphalenInterneLocatiesParams,
  ): Promise<IOphalenInterneLocatiesResult> => {
    return await api.post('v2/locatie/ophalen-interne-locaties', params);
  },
  checkToevoegenInterneLocaties: async (
    params: IToevoegenInterneLocatiesParams,
  ): Promise<ICheckData> => {
    return await api.post('v2/locatie/check-toevoegen-interne-locaties', params);
  },
  toevoegenInterneLocaties: async (
    params: IToevoegenInterneLocatiesParams,
  ): Promise<IToevoegenInterneLocatiesResult> => {
    return await api.post('v2/locatie/toevoegen-interne-locaties', params);
  },
  checkVerwijderenInterneLocaties: async (
    params: IVerwijderenInterneLocatiesParams,
  ): Promise<ICheckData> => {
    return await api.post('v2/locatie/check-verwijderen-interne-locaties', params);
  },
  verwijderenInterneLocaties: async (
    params: IVerwijderenInterneLocatiesParams,
  ): Promise<IVerwijderenInterneLocatiesResult> => {
    return await api.post('v2/locatie/verwijderen-interne-locaties', params);
  },
};

export default locatie;
