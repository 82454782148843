import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../models/IRemoteData';
import {
  IMemoGewijzigdData,
  IOphalenMemosResultElement,
  IMemoToegevoegdData,
  IMemoVerwijderdData,
} from '../../../shared/src/api/v2/memo';
import { makeObservable, observable } from 'mobx';
import api from '../api';

class MemoStore {
  public memos: IRemoteData<IOphalenMemosResultElement[]> = createPendingRemoteData();
  public memoIDsInVerwerking: number[] = [];
  private debounceOpslaanWijzigMemo: Record<number, number> = {};

  constructor() {
    makeObservable(this, {
      memos: observable,
      memoIDsInVerwerking: observable,
    });
  }

  public ophalenMemos = async () => {
    const result = await api.v2.memo.ophalenMemos({
      orderSchema: {
        orders: [
          {
            naam: 'RECORDGEWIJZIGD',
            richting: 'DESC',
          },
        ],
      },
    });
    this.memos = createReadyRemoteData(result.memos);
  };

  public wijzigMemo = (id: number, data: Omit<IOphalenMemosResultElement, 'ID'>) => {
    if (this.memos.state === ERemoteDataState.Pending) {
      throw new Error();
    }
    this.memos = createReadyRemoteData(
      this.memos.data!.map((memo) => {
        if (memo.ID === id) {
          return {
            ...data,
            ID: id,
          };
        }
        return memo;
      }),
    );

    const debounceEntry = this.debounceOpslaanWijzigMemo[id];
    if (debounceEntry !== undefined) {
      clearTimeout(debounceEntry);
    }

    this.debounceOpslaanWijzigMemo[id] = setTimeout(async () => {
      // @ts-ignore
      delete this.debounceOpslaanWijzigMemo[id];

      this.memoIDsInVerwerking = [...this.memoIDsInVerwerking, id];
      await api.v2.memo.wijzigenMemo({
        id,
        inhoud: data.Inhoud,
        relID: data.RelID,
        aspGebrID: data.AspGebrID,
      });
      this.memoIDsInVerwerking = this.memoIDsInVerwerking.filter((x) => x !== id);
    }, 5000) as any;
  };

  public verwijderMemo = async (id: number) => {
    if (this.memos.state === ERemoteDataState.Pending) {
      throw new Error();
    }
    const debounceEntry = this.debounceOpslaanWijzigMemo[id];
    if (debounceEntry !== undefined) {
      clearTimeout(debounceEntry);
      delete this.debounceOpslaanWijzigMemo[id];
    }
    this.memos = createReadyRemoteData(this.memos.data!.filter((x) => x.ID !== id));

    this.memoIDsInVerwerking = [...this.memoIDsInVerwerking, id];
    await api.v2.memo.verwijderenMemo({
      id,
    });
    this.memoIDsInVerwerking = this.memoIDsInVerwerking.filter((x) => x !== id);
  };

  public handleMemoToegevoegdEvent = (data: IMemoToegevoegdData) => {
    if (this.memos.state === ERemoteDataState.Pending) {
      return;
    }

    this.memos = createReadyRemoteData([data.memo, ...this.memos.data!]);
  };
  public handleMemoGewijzigdEvent = (data: IMemoGewijzigdData) => {
    if (this.memos.state === ERemoteDataState.Pending) {
      return;
    }
    this.memos = createReadyRemoteData(
      this.memos.data!.map((memo) => {
        if (data.memo.ID === memo.ID) {
          return data.memo;
        }
        return memo;
      }),
    );
  };
  public handleMemoVerwijderdEvent = (data: IMemoVerwijderdData) => {
    if (this.memos.state === ERemoteDataState.Pending) {
      return;
    }
    this.memos = createReadyRemoteData(this.memos.data!.filter((x) => x.ID !== data.memo.ID));
  };
}

export default MemoStore;
