import {
  IOphalenWebReroutesParams,
  IOphalenWebReroutesResult,
  IVerwijderenWebReroutesParams,
  IVerwijderenWebReroutesResult,
  IWijzigenWebRerouteParams,
  IWijzigenWebRerouteResult,
} from '../../../../../../shared/src/api/v2/technisch/webReroutes';
import api from '../../../index';

const webReroute = {
  ophalenWebReroutes: async (
    params: IOphalenWebReroutesParams,
  ): Promise<IOphalenWebReroutesResult> => {
    return await api.post('/v2/technisch/web-reroute/ophalen-web-reroutes', params);
  },
  verwijderenWebReroutes: async (
    params: IVerwijderenWebReroutesParams,
  ): Promise<IVerwijderenWebReroutesResult> => {
    return await api.post('/v2/technisch/web-reroute/verwijderen-web-reroutes', params);
  },
  wijzigenWebReroute: async (
    params: IWijzigenWebRerouteParams,
  ): Promise<IWijzigenWebRerouteResult> => {
    return await api.post('/v2/technisch/web-reroute/wijzigen-web-reroute', params);
  },
};

export default webReroute;
