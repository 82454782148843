import { RootStore } from './RootStore';
import { IOphalenContractenResultElementV2 } from '../../../shared/src/api/v2/contract';
import { makeObservable, observable } from 'mobx';
import api from '../api';

class ContractenStoreV2 {
  // tslint:disable-next-line:variable-name
  private _rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this._rootStore = rootStore;

    makeObservable(this, {
      contracten: observable,
      contractenOphaalQueue: observable,
    });
  }

  public contracten: Record<number, IOphalenContractenResultElementV2> = {};
  public contractenOphaalQueue: number[] = [];
  // tslint:disable-next-line:variable-name
  private _queueTimeout: number | null = null;

  public enqueueOphalenContracten = async (cntIDs: number[]) => {
    this.contractenOphaalQueue = [...this.contractenOphaalQueue, ...cntIDs];
    if (this._queueTimeout !== null) {
      clearTimeout(this._queueTimeout);
    }
    // @ts-ignore
    this._queueTimeout = setTimeout(this.ophalenContracten, 300);
  };

  private ophalenContracten = async () => {
    if (this.contractenOphaalQueue.length === 0) {
      return;
    }

    const queue = this.contractenOphaalQueue;
    // const result = await api.v2.contract.ophalenContractenBasis({
    //   cntIDs: queue,
    // });

    const result = (
      await api.v2.contract.ophalenContractenV2({
        filterSchema: { filters: [{ naam: 'IDS', data: queue }] },
      })
    ).contracten;

    const contracten = { ...this.contracten };
    result.forEach((contract) => {
      contracten[contract.CntID] = contract;
    });
    this.contracten = contracten;
    this.contractenOphaalQueue = [];
  };
}

export default ContractenStoreV2;
