import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import api from '../../../api';
import {
  IOphalenTelefoonoproepContextenResult,
  IOphalenTelefoonoproepContextenResultElement,
} from '../../../../../shared/src/api/v2/telefonie';
import { ASPKolom, EAspKolomBreedteType } from '../../../components/tabel/ASPTabel/types';
import LoadingSpinner from '../../../components/Gedeeld/LoadingSpinner';
import MenuLayout from '../../../components/MenuLayout';
import { IconToevoegen } from '../../../components/Icons';
import { Kleur as EKleur } from '../../../bedrijfslogica/constanten';
import useUrlState from '../../../core/useUrlState';
import ASPTabel from '../../../components/tabel/ASPTabel';
import { RootStoreContext } from '../../../stores/RootStore';
import { EResultType } from '../../../stores/CheckStore';
import MutatieDialoog from './MutatieDialoog';

interface IProps extends RouteComponentProps {
  id: number;
}

enum ETabelKolom {
  Context,
}

interface IWijzigenDialoogState {
  id: number;
}

interface IUrlState {
  selectie: number[];
  wijzigenDialoogState: IWijzigenDialoogState | null;
  // toevoegenDialoogState: IToevoegenDialoogState | null;
}

const defaultUrlState: IUrlState = {
  selectie: [],
  wijzigenDialoogState: null,
  // toevoegenDialoogState: null,
};

const TelefoonoproepContexten: React.FC<IProps> = (props) => {
  const { checkStore } = useContext(RootStoreContext);

  const [contexten, setContexten] = useState<IOphalenTelefoonoproepContextenResultElement[] | null>(
    null,
  );
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);

  const ophalenContexten = useCallback(async () => {
    if (props.id === null) {
      return;
    }

    const result = await api.v2.telefonie.ophalenTelefoonoproepContexten({
      filterSchema: {
        // filters: [{ naam: 'IDS', data: [props.id] }],
      },
      //   orderSchema: { orders: [{ naam: '', richting: 'DESC' }] },
    });
    setContexten(result.contexten);
  }, []);

  useEffect(() => {
    ophalenContexten();
  }, [ophalenContexten]);

  const tabelKolommen = useMemo<
    Array<ASPKolom<ETabelKolom, IOphalenTelefoonoproepContextenResultElement>>
  >(
    () => [
      {
        key: ETabelKolom.Context,
        label: 'Naam',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 250,
        renderer: (rij) => rij.Context,
      },
    ],
    [],
  );

  const handleWijzigenRij = useCallback(
    async (rij: IOphalenTelefoonoproepContextenResultElement, idx: number) => {
      setUrlStateSync('wijzigenDialoogState', {
        id: rij.ID,
      });
    },
    [],
  );

  return (
    <>
      {contexten === null ? (
        <div className="d-flex flex-column flex-fill align-items-center justify-content-center">
          <LoadingSpinner />
        </div>
      ) : (
        <MenuLayout
          //   menu={
          //     <div className="d-flex">
          //       <button
          //         className="btn btn-sm btn-light d-flex align-items-center"
          //         style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
          //         disabled={false}
          //         onClick={() => {
          //           //   setUrlStateSync('toevoegenDialoogState', {});
          //         }}
          //       >
          //         <IconToevoegen style={{ width: 16, height: 16, fill: EKleur.Grijs }} />
          //         <span className="ml-2">Toevoegen</span>
          //       </button>
          //       {/* <button
          //         className="btn btn-sm btn-light d-flex align-items-center ml-3"
          //         style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
          //         disabled={urlState.selectie.length === 0}
          //         onClick={async () => {
          //           const params = { ids: urlState.selectie };

          //           //   if (
          //           //     (
          //           //       await checkStore.bevestigen({
          //           //         inhoud: <span>Geselecteerde feestdagen verwijderen?</span>,
          //           //       })
          //           //     ).type === EResultType.Annuleren
          //           //   ) {
          //           //     return;
          //           //   }
          //           //   await api.v2.stambeheer.feestdag.verwijderenFeestdagen(params);
          //           //   ophalenFeestdagen();
          //         }}
          //       >
          //         <IconToevoegen style={{ width: 16, height: 16, fill: EKleur.Grijs }} />
          //         <span className="ml-2">Verwijderen</span>
          //       </button> */}
          //     </div>
          //   }
          body={
            <>
              <ASPTabel
                keyExtractor={(rij) => rij.ID}
                rijen={contexten}
                kolommen={tabelKolommen}
                selectie={urlState.selectie}
                onSelectieChange={(selectie) => setUrlStateSync('selectie', selectie)}
                onWijzigenRij={handleWijzigenRij}
                onVerwijderenRij={async (x) => {
                  const params = {
                    ids: [x.ID],
                  };
                  await api.v2.telefonie.verwijderenTelefoonoproepContexten(params);
                  await ophalenContexten();
                }}
                verwijderenRijConfirmatie={async (x, verwijderen) => {
                  const params = {
                    ids: [x.ID],
                  };

                  const checkData = await api.v2.telefonie.checkVerwijderenTelefoonoproepContexten(
                    params,
                  );

                  const checkResult = await checkStore.controleren({
                    checkData,
                  });
                  if (checkResult.type === EResultType.Annuleren) {
                    return;
                  }

                  if (
                    (
                      await checkStore.bevestigen({
                        inhoud: 'Bevestig verwijderen context',
                      })
                    ).type === EResultType.Annuleren
                  ) {
                    return;
                  }

                  await verwijderen();
                }}
                lokaalSorteren
              />
            </>
          }
        />
      )}

      {/* {urlState.toevoegenDialoogState !== null && (
        <MutatieDialoog
          open
          id={null}
          onSuccess={() => {
            ophalenFeestdagen();
            setUrlStateSync('toevoegenDialoogState', null);
          }}
          onAnnuleren={() => setUrlStateSync('toevoegenDialoogState', null)}
        />
      )} */}
      {urlState.wijzigenDialoogState !== null && (
        <MutatieDialoog
          open
          id={urlState.wijzigenDialoogState.id}
          onSuccess={() => {
            ophalenContexten();
            setUrlStateSync('wijzigenDialoogState', null);
          }}
          onAnnuleren={() => setUrlStateSync('wijzigenDialoogState', null)}
        />
      )}
    </>
  );
};

export default TelefoonoproepContexten;
