import { Field, FieldProps, Formik, FormikActions, FormikErrors, FormikProps } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ModalBody, ModalFooter, ModalTitle } from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/ModalHeader';
import { IOphalenProductsoortenResult } from '../../../../../../../shared/src/api/v2/product/soort';
import api from '../../../../../api';
import Dialoog from '../../../../../components/dialogen/Dialoog';
import BedragInput from '../../../../../components/formulier/BedragInput';
import Combobox from '../../../../../components/formulier/Combobox';
import FormikVeldFout from '../../../../../components/formulier/FormikVeldFout';
import VinkVeld from '../../../../../components/formulier/VinkVeld';
import LoadingSpinner from '../../../../../components/Gedeeld/LoadingSpinner';
import IDialoogProps from '../../../../../core/IDialoogProps';
import nameof from '../../../../../core/nameOf';
import { RootStoreContext } from '../../../../../stores/RootStore';
import MeertaligTekstveld from '../../../../../components/formulier/MeertaligTekstveld';
import ITaalTekst from '../../../../../../../shared/src/models/talen/ITaalTekst';
import { IOphalenProducttypenResultElement } from '../../../../../../../shared/src/api/v2/inkoop/opdracht/nieuw';
import {
  IOphalenVerkoopmodellenResultElement,
  IWijzigenVerkoopmodelParams,
} from '../../../../../../../shared/src/api/v2/aanbod/verkoop';
import _ from 'lodash';
import MultiCombobox, { IKolom } from '../../../../../components/formulier/MultiCombobox';

interface IProps extends IDialoogProps {
  verkoopmodelID: number;
}

interface IFormikValues {
  prodSrtID: number | null;
  typeID: number | null;
  interneNaam: string;
  naam: ITaalTekst[];
  omschrijving: ITaalTekst[];
  prijs: number;
  actief: boolean;
  naamTekstID: number;
  omschrijvingTekstID: number;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  actief: 'Actief',
  interneNaam: 'Interne Naam',
  naam: 'Naam',
  omschrijving: 'Omschrijving',
  prodSrtID: 'Productsoort',
  typeID: 'Producttype',
  prijs: 'Prijs',
  naamTekstID: 'Naam tekstid',
  omschrijvingTekstID: 'Omschrijving tekstid',
};

const WijzigenDialoog: React.FC<IProps> = observer((props) => {
  const { checkStore } = useContext(RootStoreContext);
  const { verkoopmodelID, onAnnuleren, onSuccess } = props;

  const [verkoopModel, setVerkoopModel] = useState<IOphalenVerkoopmodellenResultElement | null>(
    null,
  );
  const [prodSrtID, setProdSrtID] = useState<number | null>(null);
  const [productsoorten, setProductsoorten] = useState<IOphalenProductsoortenResult | null>(null);
  const [naamTeksten, setNaamTeksten] = useState<ITaalTekst[] | null>(null);
  const [omschrijvingTeksten, setOmschrijvingTeksten] = useState<ITaalTekst[] | null>(null);

  useEffect(() => {
    if (verkoopModel === null) {
      return;
    }
    (async () => {
      const tekstenResult = await api.v2.tekst.ophalenTekstenInAlleTalen({
        tekstIDs: [verkoopModel.Naam_TekstID],
      });
      const taalTeksten = tekstenResult.teksten.map(
        (x): ITaalTekst => ({
          taalID: x.TaalID,
          tekst: x.Tekst || '',
          toepassen: x.Toepassen,
        }),
      );
      setNaamTeksten(taalTeksten);
    })();
  }, [verkoopModel === null ? null : verkoopModel.Naam_TekstID]);

  useEffect(() => {
    if (verkoopModel === null) {
      return;
    }
    (async () => {
      const tekstenResult = await api.v2.tekst.ophalenTekstenInAlleTalen({
        tekstIDs: [verkoopModel.Omschrijving_TekstID],
      });
      const taalTeksten = tekstenResult.teksten.map(
        (x): ITaalTekst => ({
          taalID: x.TaalID,
          tekst: x.Tekst || '',
          toepassen: x.Toepassen,
        }),
      );
      setOmschrijvingTeksten(taalTeksten);
    })();
  }, [verkoopModel === null ? null : verkoopModel.Omschrijving_TekstID]);

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (verkoopModel === null || naamTeksten === null || omschrijvingTeksten === null) {
      return null;
    }

    return {
      actief: verkoopModel.Actief,
      interneNaam: verkoopModel.Naam,
      naam: naamTeksten,
      omschrijving: omschrijvingTeksten,
      prijs: verkoopModel.Prijs,
      prodSrtID: verkoopModel.ProdSrtID,
      typeID: verkoopModel.TypeID,
      naamTekstID: verkoopModel.Naam_TekstID,
      omschrijvingTekstID: verkoopModel.Omschrijving_TekstID,
    };
  }, [verkoopModel, naamTeksten, omschrijvingTeksten]);

  const [producttypen, setProducttypen] = useState<IOphalenProducttypenResultElement[] | null>(
    null,
  );

  const ophalenVerkoopModellen = useCallback(async () => {
    const result = await api.v2.aanbod.verkoop.ophalenVerkoopmodellen({
      filterSchema: { filters: [{ naam: 'IDS', data: [verkoopmodelID] }] },
    });
    setVerkoopModel(result.verkoopmodellen[0]);
  }, [verkoopmodelID]);

  useEffect(() => {
    ophalenVerkoopModellen();
  }, []);

  // Rijbron met producttypen
  useEffect(() => {
    (async () => {
      const results = await api.v2.inkoop.opdracht.nieuw.ophalenProducttypen({ prodSrtID });
      const producttypen = _.orderBy(results, ['Merknaam', 'Typenaam'], ['asc', 'asc']);
      setProducttypen(producttypen);
    })();
  }, [prodSrtID]);

  const ophalenProductSoorten = useCallback(async () => {
    const result = await api.v2.product.soort.ophalenProductsoorten({
      filterSchema: {
        filters: [],
      },
    });

    setProductsoorten(result);
  }, []);

  useEffect(() => {
    ophalenProductSoorten();
  }, []);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      const params: IWijzigenVerkoopmodelParams = {
        verkModID: verkoopmodelID,
        prodSrtID: values.prodSrtID === null ? 0 : values.prodSrtID,
        typeID: values.typeID === null ? 0 : values.typeID,
        naam: values.interneNaam,
        naam_TekstID: values.naamTekstID,
        naamTeksten: values.naam,
        omschrijving_TekstID: values.omschrijvingTekstID,
        omschrijvingTeksten: values.omschrijving,
        prijs: values.prijs,
        actief: values.actief,
        afbeeldingen: verkoopModel!.afbeeldingen.map((x) => ({
          bestandID: x.bestand.ID,
          sortNr: x.SortNr,
        })),
      };

      await api.v2.aanbod.verkoop.wijzigenVerkoopmodel(params);

      onSuccess({});
    },
    [props.onSuccess, verkoopModel],
  );

  const handleValidate = useCallback((values: IFormikValues) => {
    const errors: FormikErrors<IFormikValues> = {};

    if (values.interneNaam.trim() === '') {
      errors.interneNaam = `Naam is verplicht`;
    }

    if (values.prodSrtID === null) {
      errors.prodSrtID = `Productsoort is verplicht`;
    }

    return errors;
  }, []);

  const eigenschapKolommen = useMemo<IKolom<IOphalenProducttypenResultElement>[]>(
    () => [
      {
        key: 'Merknaam',
        label: 'Merk',
        breedte: 100,
      },
      {
        key: 'Typenaam',
        label: 'Type',
        breedte: 150,
      },
      {
        key: 'Kenmerk',
        label: 'Kenmerk',
        breedte: 250,
      },
      {
        key: 'ProductsoortnaamKort',
        label: 'Cat.',
        breedte: 80,
      },
    ],
    [],
  );

  if (verkoopModel === null) {
    return null;
  }

  return (
    <Dialoog index={1}>
      <ModalHeader>
        <ModalTitle>Wijzigen {verkoopModel !== null && verkoopModel.Naam}</ModalTitle>
      </ModalHeader>

      {initialValues !== null && (
        <Formik<IFormikValues>
          validate={handleValidate}
          onSubmit={handleSubmit}
          initialValues={initialValues}
          render={(formikProps: FormikProps<IFormikValues>) => {
            const { submitForm, values } = formikProps;
            return (
              <>
                <ModalBody>
                  <Field
                    name={nameof<IFormikValues>('interneNaam')}
                    render={(fieldProps: FieldProps<IFormikValues>) => {
                      const { field, form } = fieldProps;

                      return <></>;
                    }}
                  />
                  <div className="row">
                    <div className="col-9">
                      <label>{veldnamen.interneNaam}</label>
                      <Field
                        name={nameof<IFormikValues>('interneNaam')}
                        render={(fieldProps: FieldProps<IFormikValues>) => {
                          const { field, form } = fieldProps;

                          return (
                            <>
                              <input {...field} className="form-control" type="text" />
                              <FormikVeldFout fieldProps={fieldProps} />
                            </>
                          );
                        }}
                      />
                    </div>
                    <div className="col-3">
                      <label>{veldnamen.prijs}</label>
                      <Field
                        name={nameof<IFormikValues>('prijs')}
                        render={(x: FieldProps<IFormikValues>) => {
                          return (
                            <div>
                              <BedragInput
                                value={x.field.value}
                                onChange={(bedrag) => {
                                  x.form.setFieldValue(x.field.name, bedrag);
                                }}
                              />
                            </div>
                          );
                        }}
                      />
                    </div>
                  </div>
                  <div className="row pt-2">
                    <div className="col-12 mt-3">
                      <Field
                        name={nameof<IFormikValues>('prodSrtID')}
                        render={(fieldProps: FieldProps<IFormikValues>) => {
                          const { field, form } = fieldProps;

                          if (productsoorten === null) {
                            return <LoadingSpinner />;
                          }

                          const opties = productsoorten.map((x) => {
                            return {
                              id: x.ProdSrtID,
                              label: x.Naam,
                            };
                          });

                          const waarde = field.value === null ? null : field.value;

                          return (
                            <>
                              <label>{veldnamen.prodSrtID}</label>
                              <Combobox
                                geselecteerd={waarde}
                                legeOptieTonen
                                onSelectieChange={(x) => {
                                  setProdSrtID(field.value);
                                  form.setFieldValue(field.name, x);
                                }}
                                opties={opties}
                              />
                              <FormikVeldFout fieldProps={fieldProps} />
                            </>
                          );
                        }}
                      />
                    </div>

                    <div className="col-12 mt-3">
                      <label>{veldnamen.typeID}</label>
                      <Field
                        name={nameof<IFormikValues>('typeID')}
                        render={(fieldProps: FieldProps<IFormikValues>) => {
                          const { field, form } = fieldProps;

                          if (producttypen === null) {
                            return <LoadingSpinner />;
                          }

                          const opties = producttypen.map((x) => {
                            return {
                              id: x.TypeID,
                              label: `${x.Typenaam} (${x.TypeID})`,
                            };
                          });

                          const rij = producttypen.filter((type) => type.TypeID === field.value)[0];

                          /* TODO waarde nog invullen in combobox */
                          // const waarde = field.value === null ? null : field.value;

                          return (
                            <>
                              <MultiCombobox
                                kolommen={eigenschapKolommen}
                                waarde={rij !== undefined ? field.value : null}
                                // legeOptieTonen
                                onWaardeChange={(x) => form.setFieldValue(field.name, x)}
                                opties={producttypen}
                                representatieFabriek={(x) => (
                                  <span>
                                    {x.Merknaam} - {x.Typenaam}
                                  </span>
                                )}
                                sleutelExtractor={(x) => x.TypeID}
                              />
                              <FormikVeldFout fieldProps={fieldProps} />
                            </>
                          );
                        }}
                      />
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-12">
                      <label>{veldnamen.naam}</label>
                      <Field
                        name={nameof<IFormikValues>('naam')}
                        render={({ field, form }: FieldProps<IFormikValues>) => {
                          return (
                            <MeertaligTekstveld
                              waarden={field.value}
                              onChange={(x) => form.setFieldValue(field.name, x)}
                            />
                          );
                        }}
                      />
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-12">
                      <label>{veldnamen.omschrijving}</label>
                      <Field
                        name={nameof<IFormikValues>('omschrijving')}
                        render={({ field, form }: FieldProps<IFormikValues>) => {
                          return (
                            <MeertaligTekstveld
                              waarden={field.value}
                              onChange={(x) => form.setFieldValue(field.name, x)}
                            />
                          );
                        }}
                      />
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-4">
                      <Field
                        name={nameof<IFormikValues>('actief')}
                        render={({ field, form }: FieldProps<IFormikValues>) => {
                          return (
                            <div className="d-flex align-items-baseline">
                              <div style={{ position: `relative` }} className="mr-2">
                                <VinkVeld
                                  aangevinkt={field.value}
                                  onGewijzigd={(aangevinkt: boolean) =>
                                    form.setFieldValue(field.name, aangevinkt)
                                  }
                                />
                              </div>
                              <span>{veldnamen.actief}</span>
                            </div>
                          );
                        }}
                      />
                    </div>
                  </div>
                </ModalBody>
                <ModalFooter className="d-flex flex-row justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    style={{ width: 100 }}
                    disabled={false}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={onAnnuleren}
                    style={{ width: 100 }}
                    disabled={false}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Dialoog>
  );
});

export default WijzigenDialoog;
