import { addMonths, differenceInMonths, format } from 'date-fns';
import * as React from 'react';
import { IOphalenInformatieVoorOverzichtService } from '../../../../../../../../../shared/src/api/v2/relatie';
import TegelBasis from '../../../../../../../components/tegel/TegelBasis';
import { genereerUrlStateQueryParam } from '../../../../../../../core/useUrlState';
import NavigatiePijl from '../NavigatiePijl';
import Tabel from '../Tabel';
import {
  IUrlState as IServiceUrlState,
  defaultUrlState as defaultServiceUrlState,
} from '../../Service/Meldingen';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { Kleur as EKleur } from '../../../../../../../bedrijfslogica/constanten';
import _ from 'lodash';

interface IProps extends RouteComponentProps {
  relID: number;
  data: IOphalenInformatieVoorOverzichtService;
}

const Servicetegel = (props: IProps) => {
  const meldingen = props.data.meldingen; // Alle events voor de relatie

  const maxMaandenGeleden = 12; // Eventueel de tekst ook aanpassen
  const ondergrensDatum = addMonths(new Date(), -maxMaandenGeleden);
  const meldingenWeergeven = meldingen
    .filter((x) => new Date(x.melddatum) > ondergrensDatum)
    .filter((x, i) => i < 3); // Max 3

  // const datumMeestRecenteMelding =
  //   props.data.meldingen.length !== 0 ? _.max(props.data.meldingen.map((x) => x.melddatum)) : null;
  // const maandenGeleden = differenceInMonths(new Date(), new Date(datumMeestRecenteMelding));
  // const grensVoorWeergave = 12; // Eventueel de tekst ook aanpassen

  return (
    <TegelBasis
      titel={
        <>
          <span>Servicemeldingen</span>
        </>
      }
      subtitel={<span>Meest recente</span>}
    >
      <div>
        {meldingenWeergeven.length > 0 ? (
          <table>
            <tbody>
              {meldingenWeergeven.map((melding) => {
                return (
                  <tr key={melding.id}>
                    <td style={{ width: 100, verticalAlign: 'top' }}>
                      {format(new Date(melding.melddatum), 'dd-MM-yyyy')}
                    </td>
                    <td style={{ width: 175, verticalAlign: 'top' }}>{melding.adres}</td>
                    <td style={{ width: 500, verticalAlign: 'top', paddingRight: '20px' }}>
                      {melding.omschrijving}
                    </td>
                    <td style={{ width: 100, verticalAlign: 'top' }}>
                      {melding.afgehandeld ? 'Afgehandeld' : 'Lopend'}
                    </td>
                    <td style={{ verticalAlign: 'top' }}>
                      <NavigatiePijl
                        relID={props.relID}
                        pathWithStateBuilder={() => {
                          const state = genereerUrlStateQueryParam<IServiceUrlState>({
                            ...defaultServiceUrlState,
                            selectie: [melding.id],
                          });
                          return `service/melding?state=${state}`;
                        }}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : meldingen.length === 0 ? (
          <span>Er zijn nog geen servicemeldingen gedaan</span>
        ) : (
          <span>De meest recente servicemelding is meer dan een jaar geleden gedaan</span>
        )}

        {/* {props.data.totaalAantalMeldingen > props.data.meldingen.length && (
          <div className="mt-2">
            <a
              href="#"
              onClick={(ev) => {
                ev.preventDefault();

                props.history.push(`/klant/${props.relID}/service/meldingen`);
              }}
            >
              Er zijn nog {props.data.totaalAantalMeldingen - props.data.meldingen.length}
              &nbsp;andere meldingen...
            </a>
          </div>
        )} */}
      </div>
    </TegelBasis>
  );
};

export default withRouter(Servicetegel);
