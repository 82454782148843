import api from '../../../..';
import {
  IOphalenDagboekSoortenParams,
  IOphalenDagboekSoortenResult,
  IOphalenDagboekenParams,
  IOphalenDagboekenResult,
} from '../../../../../../../shared/src/api/v2/boekhouding/boeking/dagboek';
import ICheckData from '../../../../../../../shared/src/models/ICheckData';

const dagboek = {
  ophalenDagboeken: async (params: IOphalenDagboekenParams): Promise<IOphalenDagboekenResult> => {
    return await api.post('/v2/boeking/dagboek/ophalen-dagboeken', params);
  },
  ophalenDagboekSoorten: async (
    params: IOphalenDagboekSoortenParams,
  ): Promise<IOphalenDagboekSoortenResult> => {
    return await api.post('/v2/boeking/dagboek/ophalen-dagboek-soorten', params);
  },
};

export default dagboek;
