import React, { useContext, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import { RootStoreContext } from '../../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import Placeholder from './Placeholder';
import Data from './Data';
import IRemoteData, { ERemoteDataState } from '../../../../models/IRemoteData';
import { IOphalenOpdrachtenResultElement } from '../../../../../../shared/src/api/v2/bank/opdracht/opdracht';

interface IProps {
  bankOpdID: number; // bijv 'persID', nog hernoemen
}

const Root = styled.div`
  background: ${Kleur.Wit};
  border: 1px solid ${Kleur.LichtGrijs};
  border-radius: 4px;
  padding: 12px 16px;
  flex: 1;
  width: 500px;
`;

const InfoCard = observer((props: IProps) => {
  const { bankOpdrachtenStore } = useContext(RootStoreContext);
  const opdracht = useMemo<IRemoteData<IOphalenOpdrachtenResultElement> | null>(() => {
    return bankOpdrachtenStore.opdrachten[props.bankOpdID] ?? null;
  }, [bankOpdrachtenStore.opdrachten, props.bankOpdID]);
  useEffect(() => {
    if (opdracht !== null) {
      return;
    }
    bankOpdrachtenStore.enqueueOphalenOpdrachten([props.bankOpdID]);
  }, [opdracht, props.bankOpdID]);

  return (
    <Root onClick={(ev) => ev.stopPropagation()}>
      {opdracht === null || opdracht.state === ERemoteDataState.Pending ? (
        <Placeholder />
      ) : (
        <Data opdracht={opdracht.data!} />
      )}
    </Root>
  );
});

export default InfoCard;
