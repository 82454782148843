import React, { useMemo, useState, useCallback, useEffect, useContext } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import api from '../../../api';
import {
  IOphalenBezoektijdenVoorLijstResultElement,
  IOphalenBezoektijdenResultElement,
} from '../../../../../shared/src/api/v2/transport/bezoek';
import {
  DXTableCheckboxComponent,
  DXTableToggleCellComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../helpers/dxTableGrid';
import {
  VirtualTable,
  Grid,
  TableHeaderRow,
  TableColumnResizing,
  TableSelection,
  TableRowDetail,
} from '@devexpress/dx-react-grid-bootstrap4';
import { DataTypeProvider, RowDetailState, SelectionState } from '@devexpress/dx-react-grid';
import { format } from 'date-fns';
import LoadingSpinner from '../../../components/Gedeeld/LoadingSpinner';
import { Kleur } from '../../../bedrijfslogica/constanten';
import useUrlState from '../../../core/useUrlState';
import RelatieVisualisatie from '../../../components/personalia/RelatieVisualisatie';
import {
  IconSend,
  IconVerwijderen,
  IconVlag,
  IconKlok,
  IconWebsite,
} from '../../../components/Icons';
import BezoektijdenDialoog from '../../../components/transport/UitstaandTabel/BezoektijdenDialoog';
import { EResultType } from '../../../stores/CheckStore';
import { RootStoreContext } from '../../../stores/RootStore';
import _ from 'lodash';
import {
  IOphalenOpdrachtenResultElementV2,
  IOphalenOpdrachtregelsResultElementV2,
} from '../../../../../shared/src/api/v2/transport/opdracht';
import RelatiesVisualisaties from '../../../components/personalia/RelatiesVisualisaties';
import { EHoedanigheid } from '../../../components/personalia/RelatieSelectieDialoog';
import { Helmet } from 'react-helmet';

interface IProps extends RouteComponentProps {}

interface IUrlState {
  selectie: number[];
}

const defaultUrlState: IUrlState = {
  selectie: [],
};

export interface IRow extends IOphalenBezoektijdenResultElement {}

const Bezoektijden: React.FC<IProps> = (props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const { checkStore, instellingStore } = useContext(RootStoreContext);

  const [bezoektijdenDialoogTonen, setBezoektijdenDialoogTonen] = useState(false);

  const [bezoektijden, setBezoektijden] = useState<IOphalenBezoektijdenResultElement[] | null>(
    null,
  );

  const [opdrachten, setOpdrachten] = useState<IOphalenOpdrachtenResultElementV2[] | null>(null);
  const [opdrachtregels, setOpdrachtregels] = useState<
    IOphalenOpdrachtregelsResultElementV2[] | null
  >(null);

  const ophalenBezoektijden = useCallback(async () => {
    const bezoekdatum = null; //format(new Date(), 'yyyy-MM-dd');

    const result = await api.v2.transport.bezoek.ophalenBezoektijden({
      filterSchema: { filters: [{ naam: 'BEZOEKDATUM', data: bezoekdatum }] },
      // orderSchema: { orders: [{ naam: '', richting: '' }] },
    });

    setBezoektijden(result.bezoektijden);
  }, []);

  useEffect(() => {
    ophalenBezoektijden();
  }, [ophalenBezoektijden]);

  const ophalenOpdrachten = useCallback(async () => {
    if (bezoektijden === null) {
      return;
    }
    const result = (
      await api.v2.transport.opdracht.ophalenOpdrachtenV2({
        filterSchema: {
          filters: [{ naam: 'IDS', data: _.uniq(bezoektijden.map((x) => x.TrsOpdID)) }],
        },
      })
    ).opdrachten;

    setOpdrachten(result);
  }, [bezoektijden]);

  useEffect(() => {
    ophalenOpdrachten();
  }, [ophalenOpdrachten]);

  const ophalenOpdrachtregels = useCallback(async () => {
    if (opdrachten === null) {
      return;
    }
    const result = (
      await api.v2.transport.opdracht.ophalenOpdrachtregelsV2({
        filterSchema: {
          filters: [{ naam: 'TRSOPD_IDS', data: _.uniq(opdrachten.map((x) => x.TrsOpdID)) }],
        },
      })
    ).regels;

    setOpdrachtregels(result);
  }, [opdrachten]);

  useEffect(() => {
    ophalenOpdrachtregels();
  }, [ophalenOpdrachtregels]);

  const handleVerwijderenOpdrachten = useCallback(async () => {
    if (
      (
        await checkStore.bevestigen({
          inhoud: 'Geselecteerde bezoektijden verwijderen?',
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }
    await api.v2.transport.bezoek.verwijderenBezoektijden({
      IDs: urlState.selectie,
    });

    ophalenBezoektijden();
  }, [urlState.selectie]);

  // Relevante velden eruittrekken
  const rows = useMemo<IRow[] | null>(() => {
    if (bezoektijden === null) {
      return null;
    }
    return bezoektijden.map((bezoektijd) => ({
      ...bezoektijd,
    }));
  }, [bezoektijden]);

  const kolommen = useMemo<TypedColumn<IRow>[]>(
    () => [
      {
        name: '__peildatum' as any,
        title: 'Peildatum',
      },
      {
        name: '__opdrachtnummer' as any,
        title: 'Opd.nr',
      },
      {
        name: '__relaties' as any,
        title: 'Relatie(s)',
      },
      // {
      //   name: '__locatie' as any,
      //   title: 'Locatie',
      // },
      {
        name: '__postcode' as any,
        title: 'Postcode',
      },
      {
        name: '__huisnummer' as any,
        title: 'Huisnr',
      },
      {
        name: '__bisnummer' as any,
        title: 'Bisnr',
      },
      {
        name: '__bezoekdatum' as any,
        title: 'Bezoekdatum',
      },
      {
        name: 'BezoekdatumVan',
        title: 'Van',
      },
      {
        name: 'BezoekdatumTot',
        title: 'Tot',
      },
      {
        name: '__resultaat',
        title: ' ',
      },
    ],
    [],
  );

  const keyExtractor = useCallback((row: IRow) => row.ID, []);

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRow>[]>(
    () => [
      {
        columnName: '__peildatum' as any,
        width: 150,
      },
      {
        columnName: '__opdrachtnummer' as any,
        width: 85,
      },
      {
        columnName: '__bezoekdatum' as any,
        width: 125,
      },
      // {
      //   columnName: '__locatie' as any,
      //   width: 200,
      // },
      {
        columnName: '__postcode' as any,
        width: 85,
      },
      {
        columnName: '__huisnummer' as any,
        width: 65,
      },
      {
        columnName: '__bisnummer' as any,
        width: 65,
      },
      {
        columnName: '__relaties' as any,
        width: 300,
      },
      {
        columnName: 'BezoekdatumVan',
        width: 75,
      },
      {
        columnName: 'BezoekdatumTot',
        width: 75,
      },
      {
        columnName: '__resultaat' as any,
        width: 200,
      },
    ],
    [],
  );

  return (
    <>
      <Helmet>
        <title>Bezoektijden</title>
      </Helmet>
      <div>
        <div
          className="d-flex flex-column p-3"
          style={{
            backgroundColor: Kleur.HeelLichtGrijs,
            borderBottom: `1px solid ${Kleur.LichtGrijs}`,
          }}
        >
          <div className="d-flex">
            <button
              className="btn btn-sm btn-light d-flex align-items-center"
              style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
              onClick={() => setBezoektijdenDialoogTonen(true)}
            >
              <IconKlok style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
              <span className="ml-2">Bezoektijden ophalen</span>
            </button>

            <button
              className="btn btn-sm btn-light d-flex align-items-center ml-2"
              style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
              disabled={urlState.selectie.length === 0}
              onClick={() => {
                handleVerwijderenOpdrachten();
              }}
            >
              <IconVerwijderen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
              <span className="ml-2">Verwijderen</span>
            </button>

            <button
              className="btn btn-sm btn-light d-flex align-items-center ml-2"
              style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
              disabled={true}
              onClick={() => {}}
            >
              <IconWebsite style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
              <span className="ml-2">Zoek op website</span>
            </button>
          </div>
        </div>
        {rows === null || opdrachten === null || opdrachtregels === null ? (
          <div className="flex-fill d-flex align-items-center justify-content-center">
            <LoadingSpinner />
          </div>
        ) : (
          <GridStyleWrapper height={'calc(100vh - 100px)'}>
            <Grid rows={rows} columns={kolommen} getRowId={keyExtractor}>
              <DataTypeProvider
                for={['__opdrachtnummer']}
                formatterComponent={(formatterProps) => {
                  const rij = formatterProps.row as IRow;
                  const opdracht = opdrachten.find((x) => x.TrsOpdID === rij.TrsOpdID)!;
                  return <span>{opdracht.Opdrachtnummer}</span>;
                }}
              />

              <DataTypeProvider
                for={['__relaties']}
                formatterComponent={(formatterProps) => {
                  const rij = formatterProps.row as IRow;

                  const relIDs: number[] = _.uniq(
                    opdrachtregels
                      .filter((x) => x.TrsOpdID === rij.TrsOpdID)
                      .filter((x) => x.RelID !== null)
                      .map((x) => x.RelID),
                  );
                  if (relIDs.length === 0) {
                    return <span />;
                  }
                  return (
                    <RelatiesVisualisaties
                      relIDs={relIDs}
                      relatieLinkBuilder={(hoedanigheid, relID) =>
                        `/${
                          hoedanigheid === EHoedanigheid.Klant ? 'klant' : 'leverancier'
                        }/${relID}/transport/opdracht`
                      }
                    />
                  );
                }}
              />

              <DataTypeProvider
                for={['__resultaat']}
                formatterComponent={(formatterProps) => {
                  const row = formatterProps.row as IRow;
                  return (
                    <span>
                      {row.BezoekdatumVan === null ? (
                        <span style={{ color: Kleur.Rood }}>Geen tijden gevonden</span>
                      ) : (
                        ''
                      )}
                    </span>
                  );
                }}
              />

              <DataTypeProvider
                for={['__locatie']}
                formatterComponent={(formatterProps) => {
                  const row = formatterProps.row as IRow;
                  return <span>{row.locatie.Plaatsnaam}</span>;
                }}
              />

              <DataTypeProvider
                for={['__postcode']}
                formatterComponent={(formatterProps) => {
                  const row = formatterProps.row as IRow;
                  return <span>{row.locatie.Postcode}</span>;
                }}
              />

              <DataTypeProvider
                for={['__huisnummer']}
                formatterComponent={(formatterProps) => {
                  const row = formatterProps.row as IRow;
                  return <span>{row.locatie.Huisnummer}</span>;
                }}
              />
              <DataTypeProvider
                for={['__bisnummer']}
                formatterComponent={(formatterProps) => {
                  const row = formatterProps.row as IRow;
                  return <span>{row.locatie.Bisnummer !== null ? row.locatie.Bisnummer : ''}</span>;
                }}
              />

              <DataTypeProvider
                for={['__bezoekdatum']}
                formatterComponent={(formatterProps) => {
                  const row = formatterProps.row as IRow;
                  return (
                    <span>
                      {row.Bezoekdatum === null
                        ? ''
                        : format(new Date(row.Bezoekdatum), 'dd-MM-yyyy')}
                    </span>
                  );
                }}
              />

              <DataTypeProvider
                for={['BezoekdatumVan', 'BezoekdatumTot']}
                formatterComponent={(formatterProps) => {
                  return (
                    <span>
                      {formatterProps.value === null
                        ? ''
                        : format(new Date(formatterProps.value), 'HH:mm')}
                    </span>
                  );
                }}
              />

              <DataTypeProvider
                for={['__peildatum']}
                formatterComponent={(formatterProps) => {
                  const row = formatterProps.row as IRow;
                  return (
                    <span>
                      {row.Peildatum === null
                        ? ''
                        : format(new Date(row.Peildatum), 'dd-MM-yyyy HH:mm')}
                    </span>
                  );
                }}
              />

              <SelectionState
                selection={urlState.selectie}
                onSelectionChange={(value) => setUrlStateSync('selectie', value as number[])}
              />
              {/* <RowDetailState
                expandedRowIds={urlState.uitgeklapteOpdrachten}
                onExpandedRowIdsChange={(value) =>
                  setUrlStateSync('uitgeklapteOpdrachten', value as number[])
                }
              /> */}

              <VirtualTable />
              <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
              <TableHeaderRow />
              {/* <TableRowDetail
                toggleCellComponent={DXTableToggleCellComponent}
                contentComponent={Regels}
              /> */}
              <TableSelection cellComponent={DXTableCheckboxComponent} />
            </Grid>
          </GridStyleWrapper>
        )}
      </div>

      {bezoektijdenDialoogTonen && (
        <BezoektijdenDialoog
          open
          onSuccess={() => {
            setBezoektijdenDialoogTonen(false);
            ophalenBezoektijden();
          }}
          onAnnuleren={() => setBezoektijdenDialoogTonen(false)}
        />
      )}
    </>
  );
};

export default Bezoektijden;
