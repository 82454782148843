import React, { useContext } from 'react';
import api from '../../../../../api';
import {
  ECommunicatiekanaalSelectieType,
  EOpgegevenContextSelectieType,
  ESjabloonOplosserModus,
  ESMSVerstuurModusType,
  ETaalSelectieType,
  EVerstuurModusKanaal,
  IEmailVerstuurModus,
  IWhatsAppVerstuurModus,
  SjabloonOplosserVerstuurModusKanalen,
  SMSVerstuurModus,
} from '../../../../SjabloonOplosser/types';
import SjabloonOplosser, { ISjabloonOplosserOutput } from '../../../../SjabloonOplosser';
import { IconSjablonen } from '../../../../Icons';
import { Kleur } from '../../../../../bedrijfslogica/constanten';
import { IOphalenRelatiesResultElementV2 } from '../../../../../../../shared/src/api/v2/relatie';
import { GlobaleRendererContext } from '../../../../../one-off-components/GlobaleRenderer';

interface IProps {
  relID: number;
  relatie: IOphalenRelatiesResultElementV2;
  style?: React.CSSProperties;
}

const SjabloonOplosserOpenenKnop = (props: IProps) => {
  const globaleRenderer = useContext(GlobaleRendererContext);
  const { relID, relatie } = props;

  return (
    <button
      style={{
        outline: 0,
        border: 0,
        background: 0,
        ...props.style,
      }}
      className="d-flex flex-column align-items-center mt-2"
      onClick={async () => {
        const chatsessiesResult = await api.v2.dienst.whatsapp.ophalenChatSessiesV2({
          filterSchema: {
            filters: [
              {
                naam: 'PERS_IDS',
                data: [relatie.persoon!.PersID],
              },
            ],
          },
          paginatie: {
            aantal: 1,
            index: 0,
          },
        });
        const whatsappVerstuurModusKanaal: IWhatsAppVerstuurModus | null =
          chatsessiesResult.sessies.length === 0
            ? null
            : {
                whatsAppSesID: chatsessiesResult.sessies[0].ID,
              };
        const smsVerstuurModusKanaal: SMSVerstuurModus | null =
          relatie.persoon!.TelefoonMobiel === null
            ? null
            : {
                type: ESMSVerstuurModusType.Telefoonnummer,
                persID: relatie.persoon!.PersID,
                telefoonnummer: relatie.persoon!.TelefoonMobiel,
              };
        const emailVerstuurModusKanaal: IEmailVerstuurModus | null =
          relatie.persoon!.Email === null
            ? null
            : ({
                aan: [
                  {
                    persID: relatie.persoon!.PersID,
                    emailAdres: relatie.persoon!.Email,
                  },
                ],
                cc: [],
                bcc: [],
                bijlagen: [],
                contextRelIDs: [props.relID],
              } as IEmailVerstuurModus);

        const kanalen: SjabloonOplosserVerstuurModusKanalen = {
          [EVerstuurModusKanaal.Email]: emailVerstuurModusKanaal ?? undefined,
          [EVerstuurModusKanaal.SMS]: smsVerstuurModusKanaal ?? undefined,
          [EVerstuurModusKanaal.WhatsApp]: whatsappVerstuurModusKanaal ?? undefined,
        };

        await globaleRenderer.render<ISjabloonOplosserOutput>((renderProps) => (
          <SjabloonOplosser
            taalSelectie={{
              type: ETaalSelectieType.AutomatischBepalenViaContexten,
              isMuteerbaar: false,
            }}
            communicatiekanaalSelectie={{
              type: ECommunicatiekanaalSelectieType.Opgegeven,
              kanaalID: null,
              isMuteerbaar: true,
              magVoorvullen: true,
            }}
            modus={{
              type: ESjabloonOplosserModus.Verstuur,
              kanalen,
            }}
            open
            onSuccess={(result) => renderProps.destroy(result)}
            onAnnuleren={() => renderProps.destroy()}
            defaultOpgegevenContexten={[
              {
                selectie: {
                  type: EOpgegevenContextSelectieType.NaamEnum,
                  naamEnum: 'PERSOON',
                },
                data: { persID: relatie.persoon!.PersID },
              },
              {
                selectie: {
                  type: EOpgegevenContextSelectieType.NaamEnum,
                  naamEnum: 'RELATIE',
                },
                data: { relID: props.relID },
              },
            ]}
          />
        ));
      }}
    >
      <IconSjablonen style={{ fill: Kleur.Grijs, width: 16, height: 16 }} />
    </button>
  );
};

export default SjabloonOplosserOpenenKnop;
