import React, { useContext, useMemo } from 'react';
import IDialoogProps from '../../../../../core/IDialoogProps';
import Dialoog from '../../../../../components/dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import { Field, FieldProps, Formik, FormikErrors } from 'formik';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import ModelFormulier from './ModelFormulier';
import SoortFormulier from './SoortFormulier';
import Tabblad, { ITabblad } from '../../../../../components/layout/Tabblad';
import nameOf from '../../../../../core/nameOf';
import RelatieKoppelComponent from '../../../../../components/RelatieKoppelComponent';
import UitlegTooltip from '../../../../../components/formulier/UitlegTooltip';
import HorizontaleScheidingslijn from '../../../../../components/layout/HorizontaleScheidingslijn';
import api from '../../../../../api';
import {
  IToevoegenActieCombiActieData,
  IToevoegenActieModelData,
  IToevoegenActieParams,
  IToevoegenActieSoortData,
} from '../../../../../../../shared/src/api/v2/aanbod/tarieven/acties';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../../../stores/RootStore';
import { EResultType } from '../../../../../stores/CheckStore';
import ITaalTekst from '../../../../../../../shared/src/models/talen/ITaalTekst';
import MeertaligTekstveld from '../../../../../components/formulier/MeertaligTekstveld';
import FormikVeldFout from '../../../../../components/formulier/FormikVeldFout';
import { size } from 'lodash';

export interface IResult {}

interface IProps extends IDialoogProps<IResult> {
  initieelActieType?: EActieType;
  initieelRelID?: number;
}

export enum EActieType {
  Model = 'MODEL',
  Soort = 'SOORT',
  CombiActie = 'COMBI_ACTIE',
}

export interface IModelData {
  prodModID: number;
  abonIDs: number[];
  heeftActieOpMaandhuur: boolean;
  actieOpMaandhuur: { duurInMaanden: number; kortingspercentage: number } | null;
  heeftActieOpEenmaligBedrag: boolean;
}

export interface ISoortData {
  prodSrtID: number;
  abonIDs: number[];
  heeftActieOpMaandhuur: boolean;
  actieOpMaandhuur: { duurInMaanden: number; kortingspercentage: number } | null;
  heeftActieOpEenmaligBedrag: boolean;
}

export interface ICombiActieData {}

interface IFormikValues {
  naam: string;
  actienaam: ITaalTekst[];
  actieinfo: ITaalTekst[];
  relID: number | null;
  type: EActieType;
  datas: Partial<Record<EActieType, any>>;
}

export interface IFormulierComponentProps<TData> {
  relID: number | null;
  data: TData | null;
  onChange: (value: TData | null) => void;
}

const ToevoegenActieDialoog: React.FC<IProps> = observer((props) => {
  const { checkStore } = useContext(RootStoreContext);
  const {
    children,
    dialoogIndex,
    onAnnuleren,
    onSuccess,
    open,
    initieelActieType,
    initieelRelID,
  } = props;

  const formulierComponenten: Record<
    EActieType,
    React.ComponentType<IFormulierComponentProps<any>>
  > = useMemo(() => {
    const wrapper = (
      Comp: React.ComponentType<IFormulierComponentProps<any>>,
    ): React.ComponentType<IFormulierComponentProps<any>> => {
      return (p) => (
        <div className="p-3">
          <Comp {...p} />
        </div>
      );
    };

    return {
      [EActieType.Model]: wrapper(ModelFormulier),
      [EActieType.Soort]: wrapper(SoortFormulier),
      [EActieType.CombiActie]: wrapper(() => null),
    };
  }, []);

  const initialValues: IFormikValues = useMemo(() => {
    return {
      naam: '',
      actienaam: [],
      actieinfo: [],
      relID: initieelRelID === undefined ? null : initieelRelID,
      type: initieelActieType === undefined ? EActieType.Model : initieelActieType,
      datas: {},
    };
  }, [initieelActieType, initieelRelID]);

  const tabbladen = useMemo<ITabblad<EActieType, IFormulierComponentProps<any>>[]>(
    () => [
      {
        id: EActieType.Model,
        label: 'Model',
        content: formulierComponenten[EActieType.Model],
      },
      {
        id: EActieType.Soort,
        label: 'Soort',
        content: formulierComponenten[EActieType.Soort],
      },
      // {
      //   id: EActieType.CombiActie,
      //   label: 'Combi-actie',
      //   content: formulierComponenten[EActieType.CombiActie],
      // },
    ],
    [formulierComponenten],
  );

  return (
    <Dialoog index={dialoogIndex || 0} modalProps={{ size: 'lg' }}>
      <ModalHeader>
        <ModalTitle>Toevoegen actie</ModalTitle>
      </ModalHeader>
      <Formik<IFormikValues>
        initialValues={initialValues}
        onSubmit={async (values, actions) => {
          const { setSubmitting } = actions;
          setSubmitting(true);

          let toevoegenDataParams: Partial<IToevoegenActieParams>;
          switch (values.type) {
            case EActieType.Model: {
              const data = values.datas[EActieType.Model] as IModelData;
              const modelData: IToevoegenActieModelData = {
                prodModID: data.prodModID,
                abonIDs: data.abonIDs,
                actieOpMaandhuur: data.heeftActieOpMaandhuur
                  ? {
                      duurInMaanden: data.actieOpMaandhuur!.duurInMaanden!,
                      kortingspercentage: data.actieOpMaandhuur!.kortingspercentage!,
                    }
                  : null,
                eenmaligBedrag: data.heeftActieOpEenmaligBedrag ? 0 : null,
              };
              toevoegenDataParams = {
                modelData,
              };
              break;
            }
            case EActieType.Soort: {
              const data = values.datas[EActieType.Soort] as ISoortData;
              const soortData: IToevoegenActieSoortData = {
                prodSrtID: data.prodSrtID,
                abonIDs: data.abonIDs,
                actieOpMaandhuur: data.heeftActieOpMaandhuur
                  ? {
                      duurInMaanden: data.actieOpMaandhuur!.duurInMaanden!,
                      kortingspercentage: data.actieOpMaandhuur!.kortingspercentage!,
                    }
                  : null,
                eenmaligBedrag: data.heeftActieOpEenmaligBedrag ? 0 : null,
              };
              toevoegenDataParams = {
                soortData,
              };
              break;
            }
            case EActieType.CombiActie: {
              const data = values.datas[EActieType.CombiActie] as ICombiActieData;
              const combiActieData: IToevoegenActieCombiActieData = {};
              toevoegenDataParams = {
                combiActieData,
              };
              break;
            }
            default:
              throw new Error('Niet geimplementeerd');
          }

          const toevoegenParams: IToevoegenActieParams = {
            ...toevoegenDataParams,
            naam: values.naam,
            actienaam: values.actienaam,
            actieinfo: values.actieinfo,
            relID: values.relID,
            type: values.type as any,
          };
          const checkData = await api.v2.aanbod.tarieven.acties.checkToevoegenActie(
            toevoegenParams,
          );
          if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
            return;
          }

          await api.v2.aanbod.tarieven.acties.toevoegenActie(toevoegenParams);

          onSuccess({});
          setSubmitting(false);
        }}
        validate={(values) => {
          const errors: FormikErrors<IFormikValues> = {};
          const data = values.datas[values.type];
          if (values.naam.trim().length === 0) {
            errors.naam = 'Naam is vereist.';
          }
          if (data === undefined || data === null) {
            errors.datas = 'Formulier is vereist.' as any;
          }
          return errors;
        }}
        validateOnChange
        render={(formikProps) => {
          const { submitForm, isSubmitting, isValid, values, setFieldValue } = formikProps;
          const formulierProps: IFormulierComponentProps<any> = {
            data: values.datas[values.type],
            onChange: (data) => {
              const newDatas = {
                ...values.datas,
                [values.type]: data,
              };
              setFieldValue(nameOf('datas'), newDatas);
            },
            relID: values.relID,
          };

          return (
            <>
              <ModalBody className="p-0">
                <div className="p-3">
                  <div className="row">
                    <div className="col-12">
                      <label>Naam</label>
                      <Field
                        name={nameOf<IFormikValues>('naam')}
                        render={(fieldProps: FieldProps<IFormikValues>) => {
                          const { field, form } = fieldProps;
                          return (
                            <input
                              className="form-control"
                              type="text"
                              {...field}
                              onBlur={(ev) => {
                                field.onBlur(ev);

                                form.setFieldValue(field.name, field.value.trim());
                              }}
                            />
                          );
                        }}
                      />
                    </div>

                    <div className="col-12 mt-3">
                      <label>Actienaam</label>
                      <Field
                        name={nameOf<IFormikValues>('actienaam')}
                        render={(fieldProps: FieldProps<IFormikValues>) => {
                          const { field, form } = fieldProps;
                          return (
                            <>
                              <MeertaligTekstveld
                                waarden={field.value}
                                onChange={(x) => form.setFieldValue(field.name, x)}
                              />
                              <FormikVeldFout fieldProps={fieldProps} />
                            </>
                          );
                        }}
                      />
                    </div>
                    <div className="col-12 mt-3">
                      <label>Actieinfo</label>
                      <Field
                        name={nameOf<IFormikValues>('actieinfo')}
                        render={(fieldProps: FieldProps<IFormikValues>) => {
                          const { field, form } = fieldProps;
                          return (
                            <>
                              <MeertaligTekstveld
                                waarden={field.value}
                                onChange={(x) => form.setFieldValue(field.name, x)}
                              />
                              <FormikVeldFout fieldProps={fieldProps} />
                            </>
                          );
                        }}
                      />
                    </div>

                    <div className="col-12 mt-3">
                      <span className="d-flex align-items-center">
                        <span className="d-flex">
                          Alleen voor relatie
                          <span className="ml-2">
                            <UitlegTooltip
                              inhoud={
                                <span>
                                  Door het koppelen van een relatie is het een tariefafspraak.
                                </span>
                              }
                            />
                          </span>
                        </span>
                        <span className="ml-3">
                          <Field
                            name={nameOf<IFormikValues>('relID')}
                            render={(fieldProps: FieldProps<IFormikValues>) => {
                              const { field, form } = fieldProps;
                              return (
                                <RelatieKoppelComponent
                                  relID={field.value}
                                  onRelIDChange={(relID) => form.setFieldValue(field.name, relID)}
                                />
                              );
                            }}
                          />
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
                <HorizontaleScheidingslijn />
                <Tabblad<EActieType, IFormulierComponentProps<any>>
                  geselecteerd={values.type}
                  onSelectieChange={(value) => setFieldValue(nameOf<IFormikValues>('type'), value)}
                  tabbladen={tabbladen}
                  options={{
                    tabbladComponentProps: formulierProps,
                  }}
                />
              </ModalBody>
              <ModalFooter className="d-flex flex-row justify-content-start">
                <button
                  className="btn btn-primary"
                  onClick={submitForm}
                  style={{ width: 100 }}
                  disabled={isSubmitting || !isValid}
                >
                  Ok
                </button>
                <button
                  className="btn btn-secondary"
                  onClick={onAnnuleren}
                  style={{ width: 100 }}
                  disabled={isSubmitting}
                >
                  Annuleren
                </button>
              </ModalFooter>
            </>
          );
        }}
      />
    </Dialoog>
  );
});

export default ToevoegenActieDialoog;
