import * as React from 'react';
import { useCallback, useContext, useMemo, useState } from 'react';
import styled from 'styled-components';
import { colors } from '../../../globalstyles/variables';
import DatePicker, { registerLocale } from 'react-datepicker';
import { dagDatum, isDagDatum } from '../../../helpers/datum';
import { addDays, format, getISOWeek } from 'date-fns';
import { Kleur } from '../../../bedrijfslogica/constanten';
import { IconInklappen, IconKruis, IconUitklappen } from '../../Icons';
import nl from 'date-fns/locale/nl';
import InactiefOverlay from '../../InactiefOverlay';
import { standaardDetermineValidDateProps } from './helpers';
import { RootStoreContext } from '../../../stores/RootStore';

registerLocale('nl', nl);

interface IDatumKiezerStyleWrapperProps {
  clearable: boolean;
  width: number;
}

export const DatumKiezerStyleWrapper = styled.div<IDatumKiezerStyleWrapperProps>`
  font-family: inherit;
  font-size: 0.8rem;
  background-color: #fff;
  display: flex;
  align-items: center;
  border-radius: 0.3rem;
  position: relative;
  width: ${(props) => (props.clearable ? props.width : props.width - 20)}px;

  .react-datepicker-wrapper {
    flex: 1;
  }
  .react-datepicker__header {
    background-color: white;
    border-bottom: 1px solid ${colors.borderGrey};
  }
  .react-datepicker__today-button {
    background-color: white;
    border-top: 1px solid ${colors.borderGrey};
  }
  .react-datepicker-popper {
    .react-datepicker {
      border: 1px solid ${colors.borderGrey};
    }
  }
`;

export interface IDetermineValidDateProps {
  determineValidDate?: (date: Date) => boolean;
  determinePreviousValidDate?: 'STANDAARD' | 'ONBEGRENST' | ((date: Date) => Date | null);
  determineNextValidDate?: 'STANDAARD' | 'ONBEGRENST' | ((date: Date) => Date | null);
}

interface IProps extends IDetermineValidDateProps {
  waarde: Date | null;
  onGewijzigd: (datum: Date | null) => void;
  handleBlur?: any;
  isClearable?: boolean;
  placeholder?: string;
  dateFormat?: string;
  width?: number;
  disabled?: boolean;
  geenDagDatum?: boolean;
  ondergrensDagen?: number;
  bovengrensDagen?: number;
  className?: string;
}

const DatumKiezer = ({
  waarde,
  onGewijzigd,
  handleBlur,
  isClearable,
  placeholder,
  dateFormat,
  width,
  disabled,
  ...props
}: IProps) => {
  if (waarde !== null && !props.geenDagDatum && !isDagDatum(waarde)) {
    throw new Error('Datum is niet in dag datum formaat: ' + format(waarde, 'dd-MM-yyyy hh:mm'));
  }
  const { checkStore } = useContext(RootStoreContext);

  const [vorigeTextWaarde, setVorigeTextWaarde] = useState(
    waarde === null ? '' : format(waarde, 'dd-MM-yyyy'),
  );

  const { determineValidDate, determineNextValidDate, determinePreviousValidDate } = useMemo<{
    determineValidDate: (date: Date) => boolean;
    determinePreviousValidDate: (date: Date) => Date | null;
    determineNextValidDate: (date: Date) => Date | null;
  }>(() => {
    return {
      determineValidDate:
        props.determineValidDate || standaardDetermineValidDateProps.determineValidDate!,
      determineNextValidDate:
        props.determineNextValidDate === 'STANDAARD'
          ? (datum) => {
              if (props.bovengrensDagen !== undefined) {
                return null;
              }
              const volgendeDatum = addDays(datum, +1);
              const maxDatum = addDays(new Date(), props.bovengrensDagen!);
              return volgendeDatum > maxDatum ? volgendeDatum : null;
            }
          : props.determineNextValidDate === 'ONBEGRENST'
          ? (standaardDetermineValidDateProps.determineNextValidDate as (date: Date) => Date | null)
          : props.determineNextValidDate !== undefined
          ? props.determineNextValidDate
          : () => null,
      determinePreviousValidDate:
        props.determinePreviousValidDate === 'STANDAARD'
          ? (datum) => {
              if (props.ondergrensDagen !== undefined) {
                return null;
              }
              const volgendeDatum = addDays(datum, -1);
              const minDatum = addDays(new Date(), props.ondergrensDagen!);
              return volgendeDatum > minDatum ? volgendeDatum : null;
            }
          : props.determinePreviousValidDate === 'ONBEGRENST'
          ? (standaardDetermineValidDateProps.determinePreviousValidDate as (
              date: Date,
            ) => Date | null)
          : props.determinePreviousValidDate !== undefined
          ? props.determinePreviousValidDate
          : () => null,
    };
  }, [
    props.determineValidDate,
    props.determineNextValidDate,
    props.determinePreviousValidDate,
    props.ondergrensDagen,
  ]);

  const handleVerhogen = useCallback(() => {
    const datum = dagDatum(determineNextValidDate!(waarde!)!);
    onGewijzigd(datum);
  }, [waarde, onGewijzigd, determineNextValidDate]);

  const handleVerlagen = useCallback(() => {
    const datum = dagDatum(determinePreviousValidDate!(waarde!)!);
    onGewijzigd(datum);
  }, [waarde, onGewijzigd, determinePreviousValidDate]);

  width = width === undefined ? 160 : width;

  const determineValidDateWrapper = useCallback(
    (date: Date) => {
      if (determineValidDate === undefined) {
        throw new Error();
      }

      const d = dagDatum(date);
      return determineValidDate(d);
    },
    [determineValidDate],
  );

  const magVerhogen = useMemo(() => {
    if (determineNextValidDate === undefined || waarde === null) {
      return false;
    }
    return determineNextValidDate(waarde) !== null;
  }, [determineNextValidDate, waarde]);

  const magVerlagen = useMemo(() => {
    if (determinePreviousValidDate === undefined || waarde === null) {
      return false;
    }
    return determinePreviousValidDate(waarde) !== null;
  }, [determinePreviousValidDate, waarde]);

  // const magVerhogen = useMemo(
  //   () =>
  //     waarde !== null &&
  //     determineValidDate !== undefined &&
  //     !determineValidDateWrapper(addDays(waarde, 1)),
  //   [determineValidDate, determineValidDateWrapper, waarde],
  // );

  // const magVerlagen = useMemo(
  //   () =>
  //     waarde !== null &&
  //     determineValidDate !== undefined &&
  //     !determineValidDateWrapper(addDays(waarde, -1)),
  //   [determineValidDate, determineValidDateWrapper, waarde],
  // );

  const vandaagKnopTonen = useMemo(() => {
    const vandaag = new Date();
    return determineValidDateWrapper(vandaag);
  }, [determineValidDateWrapper]);

  return (
    <div style={{ width: 'max-content' }} className={props.className}>
      <InactiefOverlay
        isInactief={disabled || false}
        element={
          <DatumKiezerStyleWrapper clearable={Boolean(isClearable)} width={width}>
            <DatePicker
              showWeekNumbers
              formatWeekNumber={(date) => getISOWeek(date)}
              todayButton={vandaagKnopTonen ? 'Vandaag' : undefined}
              selected={waarde}
              onChange={async (x) => {
                if (x === null) {
                  onGewijzigd(null);
                  return;
                }
                onGewijzigd(dagDatum(x));
              }}
              filterDate={determineValidDate === undefined ? undefined : determineValidDateWrapper}
              onBlur={handleBlur}
              locale="nl"
              dateFormat={dateFormat || 'dd-MM-yyyy EEEEEE'}
              className="form-control"
              // isClearable={isClearable}
              placeholderText={placeholder}
              customInput={
                <CustomInput
                  vorigeTextWaarde={vorigeTextWaarde}
                  onTextWaardeBekend={setVorigeTextWaarde}
                />
              }
            />
            {waarde !== null && (
              <>
                {isClearable && (
                  <button
                    style={{
                      position: 'absolute',
                      right: 20,
                      height: '100%',
                      outline: 0,
                      background: 0,
                      border: 0,
                    }}
                    className="d-flex flex-column justify-content-center align-items-center"
                    onClick={() => onGewijzigd(null)}
                  >
                    <IconKruis
                      style={{
                        fill: Kleur.Grijs,
                        width: 20,
                        height: 20,
                        position: 'relative',
                        top: 1,
                      }}
                    />
                  </button>
                )}
                <div style={{ width: 20 }} />
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    height: '100%',
                    width: 20,
                  }}
                  className="d-flex flex-column"
                >
                  <button
                    className="btn btn-sm btn-light flex-fill p-0 pl-1 pr-1 d-flex align-items-center justify-content-center"
                    style={{
                      borderRadius: 0,
                      border: `1px solid ${Kleur.LichtGrijs}`,
                    }}
                    onClick={handleVerhogen}
                    disabled={!magVerhogen}
                  >
                    <IconInklappen style={{ width: 12, height: 12, fill: Kleur.Grijs }} />
                  </button>
                  <button
                    className="btn btn-sm btn-light flex-fill p-0 pl-1 pr-1 d-flex align-items-center justify-content-center"
                    style={{
                      borderRadius: 0,
                      border: `1px solid ${Kleur.LichtGrijs}`,
                    }}
                    onClick={handleVerlagen}
                    disabled={!magVerlagen}
                  >
                    <IconUitklappen style={{ width: 12, height: 12, fill: Kleur.Grijs }} />
                  </button>
                </div>
              </>
            )}
          </DatumKiezerStyleWrapper>
        }
      />
    </div>
  );
};

interface ICustomInputProps extends React.HTMLProps<HTMLInputElement> {
  vorigeTextWaarde: string;
  onTextWaardeBekend: (textWaarde: string) => void;
}

/*
 * Assisteert bij het invoeren van een datum.
 *
 * Moet voldoen aan het formaat dd-MM-yyyy.
 * Als er een getal wordt ingevoerd waar een hyphen zou moeten staan,
 * dan wordt deze automatisch geprefixed met een -.
 *
 * Als een hyphen wordt ingevoerd op de plaats waar een getal zou kunnen staan,
 * dan wordt het voorstaande getal gepad met 0en.
 *
 * Als het ingevoerde getal te groot is, dan wordt deze automatisch verkleint naar
 * het maximum.
 *
 * Als de invoer geen geldige invoer is (geen getal of hyphens aan het verwachte formaat),
 * dan wordt de vorige invoer behouden.
 * */
const CustomInput = (props: ICustomInputProps) => {
  // null = number
  // - = hyphen
  const mask = useMemo(() => [null, null, '-', null, null, '-', null, null, null, null], []);
  const voldoetAanMask = useCallback(
    (input: string): boolean => {
      if (input.length > mask.length) {
        return false;
      }

      return input.split('').every((char, index) => {
        if (mask[index] === null) {
          const num = Number(char);
          return !isNaN(num);
        }

        return char === mask[index];
      });
    },
    [mask],
  );

  return (
    <input
      {...props}
      onChange={(ev) => {
        const oldVal = props.vorigeTextWaarde;
        const val = ev.target.value;

        // console.log(`CustomInput: ${oldVal} -> ${val}`);
        let mutatieStatus: 'custom' | 'toegevoegd' | 'verwijderd' = 'custom';
        let mutatieKarakter: string | null = null;
        if (val.length === oldVal.length + 1) {
          // Vorige waarde is korter dan nieuwe waarde.
          // Controleren of er iets is toegevoegd.
          if (val.startsWith(oldVal)) {
            mutatieStatus = 'toegevoegd';
            mutatieKarakter = val.substr(oldVal.length, 1);
          }
        } else if (val.length === oldVal.length - 1) {
          // Vorige waarde is langer dan nieuwe waarde.
          // Controleren of er iets is verwijderd.
          const oldValueWithoutLast = oldVal.slice(0, -1);
          if (oldValueWithoutLast === val) {
            mutatieStatus = 'verwijderd';
            mutatieKarakter = oldVal.substr(val.length, 1);
          }
        }
        // console.log(mutatieStatus, mutatieKarakter);

        let newValue: string = val;

        const getDagStr = (input: string) => {
          return Number(input.slice(0, 2))
            .toString()
            .padStart(2, '0');
        };
        const getMaandStr = (input: string) => {
          return Number(input.slice(3, 5))
            .toString()
            .padStart(2, '0');
        };
        const getJaarStr = (input: string) => {
          return input.slice(6);
        };

        // Als we toevoegen of verwijderen, kunnen we er van uit gaan dat de vorige waarde een
        // geldige situatie is.
        if (mutatieStatus === 'custom') {
          // Als de mutatiestatus onbekend is, dan betekent dat deze mutatie bijv. geplakt is.
          // Hierdoor kunnen we dus niet intelligent karakters toevoegen of andere slimme dingen doen.
          // We kijken gewoon of de invoer geldig is, en zo niet weigeren we deze invoer.
          // newValue = val;
        } else if (mutatieStatus === 'toegevoegd') {
          // Situatie:
          // "01-1" + "-" -> "01-1-"
          // moet worden
          // "01-1" + "-" -> "01-01-"
          // if (mutatieKarakter === '-') {
          // }
          if (val.length === 2 && mutatieKarakter === '-') {
            newValue = `${getDagStr(oldVal)}-`;
          } else if (val.length === 3 && mutatieKarakter !== '-') {
            newValue = `${getDagStr(val)}-${mutatieKarakter}`;
          } else if (val.length === 5 && mutatieKarakter === '-') {
            newValue = `${getDagStr(oldVal)}-${getMaandStr(oldVal)}-`;
          } else if (val.length === 6 && mutatieKarakter !== '-') {
            newValue = `${getDagStr(val)}-${getMaandStr(val)}-${mutatieKarakter}`;
          }
          // else if (val.length === 6 && mutatieKarakter !== '-') {
          //
          //
          // }
        } else if (mutatieStatus === 'verwijderd') {
          // newValue = val;
        }

        if (!voldoetAanMask(newValue)) {
          return;
        }

        // const [dagStr, maandStr, jaarStr] = newValue.split('-');
        // const dag = dagStr === undefined ? null : Number(dagStr);
        // const maand = maandStr === undefined ? null : Number(maandStr);
        // const jaar = jaarStr === undefined ? null : Number(jaarStr);

        // if (maand !== null && maand > 12) {
        //   return;
        // }
        // if (jaar !== null && jaar < 1970) {
        //   return;
        // }
        // if (dag)

        ev.target.value = newValue;

        props.onTextWaardeBekend(newValue);
        props.onChange?.(ev);

        // const trimmedValue = ev.target.value.trim();
        // // Vervang / met -
        // const replaced = trimmedValue.replace(/\//g, '-');
        // // const geenDubbeleHyphens = replaced
        // //   .split('-')
        // //   .filter((x) => x !== '')
        // //   .join('-');
        // //
        // // let newValue = '';
        // // let dagAantal = 0;
        // // const maxDagAantal = 2;
        // // let maandAantal = 0;
        // // const maxMaandAantal = 2;
        // // let jaarAantal = 0;
        // // const maxJaarAantal = 4;
        // if (replaced === props.vorigeTextWaarde) {
        //   return;
        // }
        //
        // for (const char of replaced) {
        //   // Alleen cijfers en -
        //   if (char !== '-' && !/[0-9]/.test(char)) {
        //     return;
        //   }
        // }
        //
        // const isToegevoegdAanWaarde = props.vorigeTextWaarde.length < replaced.length;
        // let erIsEenHyphenToegevoegd = false;
        // if (isToegevoegdAanWaarde) {
        //   const laatste = replaced.substr(replaced.length - 1, 1);
        //   if (laatste === '-') {
        //     erIsEenHyphenToegevoegd = true;
        //   }
        // }
        //
        // let [dag, maand, jaar] = replaced.split('-').filter((x) => x !== '');
        // dag = dag ?? '';
        // maand = maand ?? '';
        // jaar = jaar ?? '';
        //
        // if (dag.length > 2 || maand.length > 2 || jaar.length > 4) {
        //   return;
        // }
        //
        // // const dagHeeftJuisteLengte = dag.length === 1 || dag.length === 2;
        // // const maandHeeftJuisteLengte = maand.length === 1 || maand.length === 2;
        // // const jaarHeeftJuisteLengte = jaar.length === 2 || jaar.length === 4;
        // //
        // // if (dagHeeftJuisteLengte) {
        // // }
        //
        // let dagStr = dag;
        // if (dag.length === 1 && maand.length !== 0) {
        //   const d = Number(dag);
        //   if (d < 1 || d > 31) {
        //     return;
        //   }
        //   dagStr = d.toString().padStart(2, '0');
        // }
        // let maandStr = maand;
        // if (maand.length === 1 && jaar.length !== 0) {
        //   const m = Number(maand);
        //   if (m < 1 || m > 12) {
        //     return;
        //   }
        //   maandStr = m.toString().padStart(2, '0');
        // }
        // let jaarStr = jaar;
        // if (jaar.length === 2) {
        //   const j = Number(jaar);
        //   if (j < 0 || j > 99) {
        //     return;
        //   }
        //   jaarStr = j.toString().padStart(4, '0');
        // }
        //
        // let newValue = '';
        // if (dagStr.length > 0) {
        //   newValue += dagStr;
        // }
        // if (maandStr.length > 0) {
        //   newValue += '-' + maandStr;
        // }
        // if (jaarStr.length > 0) {
        //   newValue += '-' + jaarStr;
        // }
        //
        // if (erIsEenHyphenToegevoegd) {
        //   newValue += '-';
        // }
        //
        // ev.target.value = newValue;
        //
        // props.onTextWaardeBekend(newValue);
        // props.onChange?.(ev);
      }}
    />
  );
};

export default DatumKiezer;
