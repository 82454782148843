import React, { useContext, useEffect, useMemo, useState, useCallback } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import useUrlState from '../../../../core/useUrlState';
import api from '../../../../api';
import { addMonths } from 'date-fns';
import _ from 'lodash';
import { IOphalenContractenResultElementV2 } from '../../../../../../shared/src/api/v2/contract';
import Tabel from '../ContractenTabel';
import { EContractStatus } from '../../../Klant/Klantkaart/Entiteiten/entiteiten/Contracten/Overzicht/ContractTegel/ContractStatus';

interface IProps extends RouteComponentProps {}

interface IUrlState {}

const defaultUrlState: IUrlState = {};

export interface IRegel extends IOphalenContractenResultElementV2 {}

const Geannuleerd: React.FC<IProps> = observer((props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);

  const [contracten, setContracten] = useState<IRegel[] | null>(null);

  const ophalenContracten = useCallback(async () => {
    const peildatum = addMonths(new Date(), -2);
    const contracten = (
      await api.v2.contract.ophalenContractenV2({
        filterSchema: {
          filters: [
            { naam: 'STATUS_NAAM_ENUMS', data: [EContractStatus.Geannuleerd] },
            { naam: 'EINDDATUM_VANAF', data: peildatum },
          ],
        },
        orderSchema: {
          orders: [
            {
              naam: 'EINDDATUM',
              richting: 'DESC',
            },
            {
              naam: 'BASISNUMMER',
              richting: 'DESC',
            },
            {
              naam: 'VOLGNUMMER',
              richting: 'DESC',
            },
          ],
        },
      })
    ).contracten;
    setContracten(contracten);
  }, []);

  useEffect(() => {
    ophalenContracten();
  }, [ophalenContracten]);

  return <>{contracten === null ? <LoadingSpinner /> : <Tabel contracten={contracten} />}</>;
});

export default withRouter(Geannuleerd);
