import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import api from '../../../api';
import { EDagboeksoort } from '../../../bedrijfslogica/enums';
import _ from 'lodash';
import {
  IOphalenBoekingenResultElement,
  IOphalenBoekingRegelsResultElement,
} from '../../../../../shared/src/api/v2/boekhouding/boeking';
import { IFilterSchema } from '../../../../../shared/src/models/filter';
import {
  IOphalenDagboekenResultElement,
  IOphalenDagboekenResult,
} from '../../../../../shared/src/api/v2/boekhouding/boeking/dagboek';
import { createRowCache, RowCache } from '@devexpress/dx-react-grid';

export interface IBoekingregel extends IOphalenBoekingRegelsResultElement {
  boeking: IOphalenBoekingenResultElement;
  dagboek: IOphalenDagboekenResultElement;
}

export interface IUseBoekingregelsOutput {
  regels: IBoekingregel[] | null;
  ophalenBoekingregels: (index: number, aantal: number, gebruikCache: boolean) => Promise<void>;
  totaalBoekingsregels: number | null;
  bezigMetOphalen: boolean;
  huidigeBoekingsregelsPaginatieIndex: number;
}

const useBoekingregels = (
  dagbooksoort: EDagboeksoort,
  filterSchema: IFilterSchema | null,
): IUseBoekingregelsOutput => {
  const rowCache = useMemo(() => createRowCache(50), [filterSchema]);

  const [dagboekenResult, setDagboekenResult] = useState<IOphalenDagboekenResult | null>(null);
  const ophalenDagboeken = useCallback(async () => {
    const result = await api.v2.boeking.dagboek.ophalenDagboeken({});
    setDagboekenResult(result);
  }, []);
  useEffect(() => {
    ophalenDagboeken();
  }, [ophalenDagboeken]);

  const [huidigeBoekingsregelsPaginatieIndex, setHuidigeBoekingsregelsPaginatieIndex] = useState(0);
  const [totaalBoekingsregels, setTotaalBoekingsregels] = useState<number | null>(null);
  const [bezigMetOphalen, setBezigMetOphalen] = useState(false);
  const [regels, setRegels] = useState<IBoekingregel[] | null>(null);
  const ophalenBoekingsregelsAbortControllerRef = useRef<AbortController | null>(null);
  const ophalenBoekingregels = useCallback(
    async (index = 0, aantal = 500, gebruikCache = true) => {
      ophalenBoekingsregelsAbortControllerRef.current?.abort();
      ophalenBoekingsregelsAbortControllerRef.current = new AbortController();

      if (filterSchema === null || dagboekenResult === null) {
        return;
      }
      setBezigMetOphalen(true);

      if (gebruikCache) {
        const cached = rowCache.getRows(index, aantal);
        if (cached.length === aantal) {
          setHuidigeBoekingsregelsPaginatieIndex(index);
          setBezigMetOphalen(false);
          return;
        }
      }

      const regelsResult = await api.v2.boeking.ophalenBoekingregels(
        {
          filterSchema: {
            filters: [
              ...filterSchema.filters!,
              { naam: 'DAGBOEK_SOORT_NAAMENUMS', data: [dagbooksoort] },
            ],
          },
          orderSchema: {
            orders: [
              {
                naam: 'DAGBOEKNUMMER',
                richting: 'ASC',
              },
              {
                naam: 'BOEKNUMMER',
                richting: 'DESC',
              },
              {
                naam: 'REGELNUMMER',
                richting: 'ASC',
              },
              // {
              //   naam: 'BOEKDATUM',
              //   richting: 'DESC',
              // },
            ],
          },
          paginatie: {
            aantal,
            index,
          },
        },
        ophalenBoekingsregelsAbortControllerRef.current!.signal,
      );
      const boekingIDs = _.uniq(regelsResult.regels.map((x) => x.BoekingID));

      const boekingenResult = await api.v2.boeking.ophalenBoekingen(
        {
          filterSchema: {
            filters: [{ naam: 'IDS', data: boekingIDs }],
          },
          // paginatie: { index: 0, aantal: 500 },
        },
        ophalenBoekingsregelsAbortControllerRef.current!.signal,
      );
      const boekingen = boekingenResult.boekingen;

      // Map de regels met de journaalposten result
      const regels: IBoekingregel[] = regelsResult.regels.map((regel) => {
        const boeking = boekingen.find((x) => x.ID === regel.BoekingID)!;
        const dagboek = dagboekenResult.dagboeken.find((x) => x.ID === boeking.DagboekID)!;
        return { ...regel, boeking, dagboek };
      });

      rowCache.setRows(index, regels);

      setRegels(rowCache.getRows(index, aantal));
      setTotaalBoekingsregels(regelsResult.totaal);
      setHuidigeBoekingsregelsPaginatieIndex(index);
      setBezigMetOphalen(false);
    },
    [filterSchema, dagboekenResult],
  );

  useEffect(() => {
    ophalenBoekingregels();
  }, [ophalenBoekingregels]);

  return {
    regels,
    ophalenBoekingregels,
    totaalBoekingsregels,
    bezigMetOphalen,
    huidigeBoekingsregelsPaginatieIndex,
  };
};

export default useBoekingregels;
