import React, { useCallback, useContext, useMemo } from 'react';
import {
  Grid,
  Table,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  DXTableCheckboxComponent,
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import MenuLayout from '../../../../components/MenuLayout';
import TabelInspringBlok from '../../../../components/layout/TabelInspringBlok';
import useUrlState from '../../../../core/useUrlState';
import { RouteComponentProps, withRouter } from 'react-router';
import ASPTabel from '../../../../components/tabel/ASPTabel';
import { ASPKolom, EAspKolomBreedteType } from '../../../../components/tabel/ASPTabel/types';
import { IOphalenMedewerkerVoorwaardenResultElement } from '../../../../../../shared/src/api/v2/medewerker';
import { format } from 'date-fns';
import { Kleur as EKleur } from '../../../../bedrijfslogica/constanten';
import ToevoegenVoorwaardeDialoog from './ToevoegenVoorwaardeDialoog';
import {
  DataTypeProvider,
  EditingState,
  IntegratedSorting,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import nameOf from '../../../../core/nameOf';
import api from '../../../../api';
import { RootStoreContext } from '../../../../stores/RootStore';
import { EResultType } from '../../../../stores/CheckStore';
import { IContext, MedewerkerContext } from '..';

interface IProps extends TableRowDetail.ContentProps, RouteComponentProps {}

export interface IToevoegenVoorwaardeDialoogState {
  mdwID: number;
}

export interface IUrlState {
  toevoegenVoorwaardeDialoogState: IToevoegenVoorwaardeDialoogState | null;
  selectie: number[];
}
const defaultUrlState: IUrlState = {
  toevoegenVoorwaardeDialoogState: null,
  selectie: [],
};

const urlStateKey = 'voorwaardenDetail';

enum EKolom {
  Mutatiedatum,
}

const RowDetailComp = (props: IProps) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState, urlStateKey);
  const { checkStore } = useContext(RootStoreContext);

  const { onVerversenAangevraagd } = useContext<IContext>(MedewerkerContext);

  const voorwaarden: IOphalenMedewerkerVoorwaardenResultElement[] = props.row.voorwaarden;

  const keyExtractor = useCallback((row: IOphalenMedewerkerVoorwaardenResultElement) => row.ID, []);

  const kolommen = useMemo<TypedColumn<IOphalenMedewerkerVoorwaardenResultElement>[]>(
    () => [
      {
        name: 'Mutatiedatum',
        title: 'Mutatiedatum',
      },
      {
        name: 'StandaardUren',
        title: 'Uren/week',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<
    TypedTableColumnWidthInfo<IOphalenMedewerkerVoorwaardenResultElement>[]
  >(
    () => [
      {
        columnName: 'Mutatiedatum',
        width: 140,
      },
      {
        columnName: 'StandaardUren',
        width: 115,
      },
    ],
    [],
  );

  return (
    <>
      <div className="d-flex flex-column flex-fill">
        {voorwaarden === null ? (
          <div className="d-flex flex-fill align-items-center justify-content-center">
            <LoadingSpinner />
          </div>
        ) : (
          <MenuLayout
            menu={
              <>
                <div className="d-flex flex-fill align-items-center">
                  <button
                    className="btn btn-sm btn-light d-flex align-items-center"
                    style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                    onClick={async () =>
                      setUrlStateSync('toevoegenVoorwaardeDialoogState', { mdwID: props.row.MdwID })
                    }
                  >
                    {/* <IconToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} /> */}
                    <span className="ml-2">Toevoegen</span>
                  </button>
                  <button
                    className="btn btn-sm btn-light d-flex align-items-center ml-3"
                    style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                    disabled={urlState.selectie.length === 0}
                    onClick={async () => {
                      const params = { ids: urlState.selectie };
                      const checkData = await api.v2.medewerker.checkVerwijderenMedewerkerVoorwaardes(
                        params,
                      );

                      if (
                        (
                          await checkStore.controleren({
                            checkData,
                          })
                        ).type === EResultType.Annuleren
                      ) {
                        return;
                      }

                      if (
                        (
                          await checkStore.bevestigen({
                            inhoud: <span>Wil je de geselecteerde voorwaarden verwijderen?</span>,
                          })
                        ).type === EResultType.Annuleren
                      ) {
                        return;
                      }

                      await api.v2.medewerker.verwijderenMedewerkerVoorwaardes(params);
                      onVerversenAangevraagd();
                    }}
                  >
                    {/* <IconToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} /> */}
                    <span className="ml-2">Verwijderen</span>
                  </button>
                </div>
              </>
            }
            body={
              <div className="d-flex">
                <TabelInspringBlok />
                <GridStyleWrapper maxHeight={500} rowAmount={voorwaarden.length}>
                  <Grid rows={voorwaarden} columns={kolommen} getRowId={keyExtractor}>
                    <DataTypeProvider
                      for={[nameOf<IOphalenMedewerkerVoorwaardenResultElement>('Mutatiedatum')]}
                      formatterComponent={(formatterProps) => {
                        return <span>{format(new Date(formatterProps.value), 'dd-MM-yyyy')}</span>;
                      }}
                    />

                    <EditingState
                      onCommitChanges={async (changes) => {
                        if (changes.deleted === undefined) {
                          return;
                        }
                        const deleted = changes.deleted;
                        const id = deleted[deleted.length - 1] as number;
                      }}
                      onEditingRowIdsChange={(rowIds) => {
                        const id = rowIds[rowIds.length - 1] as number;
                      }}
                    />

                    <VirtualTable
                      messages={{
                        noData: 'Geen voorwaarden aanwezig',
                      }}
                    />
                    <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
                    {/* <TableEditColumn
                      width={35}
                      showEditCommand
                      // showDeleteCommand
                      cellComponent={DXTableEditColumnCellComponent}
                      commandComponent={DXTableEditColumnCommandComponent}
                    /> */}
                    <SortingState defaultSorting={[]} />
                    <IntegratedSorting />
                    <TableHeaderRow showSortingControls />

                    <SelectionState
                      selection={urlState.selectie}
                      onSelectionChange={(x) => setUrlStateSync('selectie', x as number[])}
                    />
                    <TableSelection cellComponent={DXTableCheckboxComponent} />
                  </Grid>
                </GridStyleWrapper>
              </div>
            }
          />
        )}

        {urlState.toevoegenVoorwaardeDialoogState !== null && (
          <ToevoegenVoorwaardeDialoog
            open
            mdwID={urlState.toevoegenVoorwaardeDialoogState.mdwID}
            onSuccess={() => {
              onVerversenAangevraagd();
              setUrlStateSync('toevoegenVoorwaardeDialoogState', null);
            }}
            onAnnuleren={() => {
              setUrlStateSync('toevoegenVoorwaardeDialoogState', null);
            }}
          />
        )}
      </div>
    </>
  );
};

export default withRouter(RowDetailComp);
