import {
  IOphalenFacturenParams,
  IOphalenFacturenResult,
  IWijzigenBestandenParams,
  IWijzigenBestandenResult,
  IWijzigenInkoopfacturenResult,
  IToevoegenInkoopfactuurParams,
  IToevoegenInkoopfactuurResult,
  IWijzigenInkoopfactuurParams,
  IVerwijderenInkoopfacturenParams,
  IVerwijderenInkoopfacturenResult,
  IOphalenFactuurSuggestiesParams,
  IOphalenFactuurSuggestiesResult,
  IFiatterenInkoopfacturenParams,
  IFiatterenInkoopfacturenResult,
  IBetaalopdrachtInkoopfacturenParams,
  IBetaalopdrachtInkoopfacturenResult,
  ICheckWijzigenInkoopfactuurToegestaanParams,
  ICheckSelectieVerrekenenInkoopfacturenParams,
  IBepalenVerrekeningsbedragInkoopfacturenParams,
  IBepalenVerrekeningsbedragInkoopfacturenResult,
  IVerwerkenInkoopfactuurverrekeningParams,
  IVerwerkenInkoopfactuurverrekeningResult,
  IOphalenGekoppeldeProductenParams,
  IOphalenGekoppeldeProductenResult,
  // IMuterenGekoppeldeProductenParams,
  // IMuterenGekoppeldeProductenResult,
  IOphalenNogTeKoppelenProductenParams,
  IOphalenNogTeKoppelenProductenResult,
  IOphalenSelectieNogTeKoppelenProductenParams,
  IOphalenSelectieNogTeKoppelenProductenResult,
  IToevoegenGekoppeldeProductenParams,
  IToevoegenGekoppeldeProductenResult,
  IVerwijderenGekoppeldeProductenParams,
  IVerwijderenGekoppeldeProductenResult,
  IWijzigenInkoopprijsParams,
  IWijzigenInkoopprijsResult,
  IVerdelenRestbedragParams,
  IVerdelenRestbedragResult,
  IOphalenPotentieleInkoopfactuurBestandenParams,
  IOphalenPotentieleInkoopfactuurBestandenResult,
  IOphalenPotentieleInkoopfactuurBestandGroepenParams,
  IOphalenPotentieleInkoopfactuurBestandGroepenResult,
  IWijzigenVerwerkingsstatusParams,
  IWijzigenVerwerkingsstatusResult,
  IOphalenPotentieleInkoopfactuurBestandDatasParams,
  IOphalenPotentieleInkoopfactuurBestandDatasResult,
  ISelectieBetaalopdrachtInkoopfacturenParams,
  IOphalenSponsorcontractenVoorFacturenParams,
  IOphalenSponsorcontractenVoorFacturenResult,
  IWijzigenBedragGekoppeldSponsorcontractParams,
  IWijzigenBedragGekoppeldSponsorcontractResult,
  IOphalenFacturenXSponsorcontractenParams,
  IOphalenFacturenXSponsorcontractenResult,
  IOphalenFacturenXProductenParams,
  IOphalenFacturenXProductenResult,
} from '../../../../../shared/src/api/v2/inkoopfactuur';
import api from '../..';
import ICheckData from '../../../../../shared/src/models/ICheckData';
import werkbon from './werkbon';
import transport from './transport';

const factuur = {
  werkbon,
  transport,
  ophalenFacturen: async (params: IOphalenFacturenParams): Promise<IOphalenFacturenResult> => {
    return await api.post('/v2/inkoopfactuur/ophalen-facturen', params);
  },
  wijzigenBestanden: async (
    params: IWijzigenBestandenParams,
  ): Promise<IWijzigenBestandenResult> => {
    return await api.post('/v2/inkoopfactuur/wijzigen-bestanden', params);
  },
  checkToevoegenInkoopfactuur: async (
    params: IToevoegenInkoopfactuurParams,
  ): Promise<ICheckData> => {
    return await api.post('/v2/inkoopfactuur/check-toevoegen-inkoopfactuur', params);
  },
  toevoegenInkoopfactuur: async (
    params: IToevoegenInkoopfactuurParams,
  ): Promise<IToevoegenInkoopfactuurResult> => {
    return await api.post('/v2/inkoopfactuur/toevoegen-inkoopfactuur', params);
  },
  checkWijzigenInkoopfactuurToegestaan: async (
    params: ICheckWijzigenInkoopfactuurToegestaanParams,
  ): Promise<ICheckData> => {
    return await api.post('/v2/inkoopfactuur/check-wijzigen-inkoopfactuur-toegestaan', params);
  },
  checkWijzigenInkoopfactuur: async (params: IWijzigenInkoopfactuurParams): Promise<ICheckData> => {
    return await api.post('/v2/inkoopfactuur/check-wijzigen-inkoopfactuur', params);
  },
  wijzigenInkoopfactuur: async (
    params: IWijzigenInkoopfactuurParams,
  ): Promise<IWijzigenInkoopfacturenResult> => {
    return await api.post('/v2/inkoopfactuur/wijzigen-inkoopfactuur', params);
  },
  checkVerwijderenInkoopfacturen: async (
    params: IVerwijderenInkoopfacturenParams,
  ): Promise<ICheckData> => {
    return await api.post('/v2/inkoopfactuur/check-verwijderen-inkoopfacturen', params);
  },
  verwijderenInkoopfacturen: async (
    params: IVerwijderenInkoopfacturenParams,
  ): Promise<IVerwijderenInkoopfacturenResult> => {
    return await api.post('/v2/inkoopfactuur/verwijderen-inkoopfacturen', params);
  },
  ophalenFactuurSuggesties: async (
    params: IOphalenFactuurSuggestiesParams,
  ): Promise<IOphalenFactuurSuggestiesResult> => {
    return await api.post('/v2/inkoopfactuur/ophalen-factuur-suggesties', params);
  },
  checkFiatterenInkoopfacturen: async (
    params: IFiatterenInkoopfacturenParams,
  ): Promise<ICheckData> => {
    return await api.post('/v2/inkoopfactuur/check-fiatteren-inkoopfacturen', params);
  },
  fiatterenInkoopfacturen: async (
    params: IFiatterenInkoopfacturenParams,
  ): Promise<IFiatterenInkoopfacturenResult> => {
    return await api.post('/v2/inkoopfactuur/fiatteren-inkoopfacturen', params);
  },
  checkBetaalopdrachtInkoopfacturen: async (
    params: IBetaalopdrachtInkoopfacturenParams,
  ): Promise<ICheckData> => {
    return await api.post('/v2/inkoopfactuur/check-betaalopdracht-inkoopfacturen', params);
  },
  betaalopdrachtInkoopfacturen: async (
    params: IBetaalopdrachtInkoopfacturenParams,
  ): Promise<IBetaalopdrachtInkoopfacturenResult> => {
    return await api.post('/v2/inkoopfactuur/betaalopdracht-inkoopfacturen', params);
  },
  checkSelectieVerrekenenInkoopfacturen: async (
    params: ICheckSelectieVerrekenenInkoopfacturenParams,
  ): Promise<ICheckData> => {
    return await api.post('/v2/inkoopfactuur/check-selectie-verrekenen-inkoopfacturen', params);
  },
  bepalenVerrekeningsbedragInkoopfacturen: async (
    params: IBepalenVerrekeningsbedragInkoopfacturenParams,
  ): Promise<IBepalenVerrekeningsbedragInkoopfacturenResult> => {
    return await api.post('/v2/inkoopfactuur/bepalen-verrekeningsbedrag-inkoopfacturen', params);
  },
  verwerkenInkoopfactuurverrekening: async (
    params: IVerwerkenInkoopfactuurverrekeningParams,
  ): Promise<IVerwerkenInkoopfactuurverrekeningResult> => {
    return await api.post('/v2/inkoopfactuur/verwerken-inkoopfactuurverrekening', params);
  },
  ophalenGekoppeldeProducten: async (
    params: IOphalenGekoppeldeProductenParams,
  ): Promise<IOphalenGekoppeldeProductenResult> => {
    return await api.post('/v2/inkoopfactuur/ophalen-gekoppelde-producten', params);
  },
  // muterenGekoppeldeProducten: async (
  //   params: IMuterenGekoppeldeProductenParams,
  // ): Promise<IMuterenGekoppeldeProductenResult> => {
  //   return await api.post('/v2/inkoopfactuur/muteren-gekoppeld-producten', params);
  // },
  ophalenNogTeKoppelenProducten: async (
    params: IOphalenNogTeKoppelenProductenParams,
  ): Promise<IOphalenNogTeKoppelenProductenResult> => {
    return await api.post('/v2/inkoopfactuur/ophalen-nog-te-koppelen-producten', params);
  },
  ophalenSelectieNogTeKoppelenProducten: async (
    params: IOphalenSelectieNogTeKoppelenProductenParams,
  ): Promise<IOphalenSelectieNogTeKoppelenProductenResult> => {
    return await api.post('/v2/inkoopfactuur/ophalen-selectie-nog-te-koppelen-producten', params);
  },
  toevoegenGekoppeldeProducten: async (
    params: IToevoegenGekoppeldeProductenParams,
  ): Promise<IToevoegenGekoppeldeProductenResult> => {
    return await api.post('/v2/inkoopfactuur/toevoegen-gekoppelde-producten', params);
  },
  verwijderenGekoppeldeProducten: async (
    params: IVerwijderenGekoppeldeProductenParams,
  ): Promise<IVerwijderenGekoppeldeProductenResult> => {
    return await api.post('/v2/inkoopfactuur/verwijderen-gekoppelde-producten', params);
  },
  wijzigenInkoopprijs: async (
    params: IWijzigenInkoopprijsParams,
  ): Promise<IWijzigenInkoopprijsResult> => {
    return await api.post('/v2/inkoopfactuur/wijzigen-inkoopprijs', params);
  },
  verdelenRestbedrag: async (
    params: IVerdelenRestbedragParams,
  ): Promise<IVerdelenRestbedragResult> => {
    return await api.post('/v2/inkoopfactuur/verdelen-restbedrag', params);
  },
  ophalenPotentieleInkoopfactuurBestanden: async (
    params: IOphalenPotentieleInkoopfactuurBestandenParams,
  ): Promise<IOphalenPotentieleInkoopfactuurBestandenResult> => {
    return await api.post('/v2/inkoopfactuur/ophalen-potentiele-inkoopfactuur-bestanden', params);
  },
  ophalenPotentieleInkoopfactuurBestandGroepen: async (
    params: IOphalenPotentieleInkoopfactuurBestandGroepenParams,
  ): Promise<IOphalenPotentieleInkoopfactuurBestandGroepenResult> => {
    return await api.post(
      '/v2/inkoopfactuur/ophalen-potentiele-inkoopfactuur-bestand-groepen',
      params,
    );
  },
  wijzigenVerwerkingsstatus: async (
    params: IWijzigenVerwerkingsstatusParams,
  ): Promise<IWijzigenVerwerkingsstatusResult> => {
    return await api.post('/v2/inkoopfactuur/wijzigen-verwerkingsstatus', params);
  },
  ophalenPotentieleInkoopfactuurBestandDatas: async (
    params: IOphalenPotentieleInkoopfactuurBestandDatasParams,
  ): Promise<IOphalenPotentieleInkoopfactuurBestandDatasResult> => {
    return await api.post(
      '/v2/inkoopfactuur/ophalen-potentiele-inkoopfactuur-bestand-datas',
      params,
    );
  },
  checkSelectieBetaalopdrachtInkoopfacturen: async (
    params: ISelectieBetaalopdrachtInkoopfacturenParams,
  ): Promise<ICheckData> => {
    return await api.post('/v2/inkoopfactuur/check-selectie-betaalopdracht-inkoopfacturen', params);
  },
  ophalenSponsorcontractenVoorFacturen: async (
    params: IOphalenSponsorcontractenVoorFacturenParams,
  ): Promise<IOphalenSponsorcontractenVoorFacturenResult> => {
    return await api.post('/v2/inkoopfactuur/ophalen-sponsorcontracten-voor-facturen', params);
  },
  wijzigenBedragGekoppeldSponsorcontract: async (
    params: IWijzigenBedragGekoppeldSponsorcontractParams,
  ): Promise<IWijzigenBedragGekoppeldSponsorcontractResult> => {
    return await api.post('/v2/inkoopfactuur/wijzigen-bedrag-gekoppeld-sponsorcontract', params);
  },
  ophalenFacturenXSponsorcontracten: async (
    params: IOphalenFacturenXSponsorcontractenParams,
  ): Promise<IOphalenFacturenXSponsorcontractenResult> => {
    return await api.post('/v2/inkoopfactuur/ophalen-facturen-x-sponsorcontracten', params);
  },
  ophalenFacturenXProducten: async (
    params: IOphalenFacturenXProductenParams,
  ): Promise<IOphalenFacturenXProductenResult> => {
    return await api.post('/v2/inkoopfactuur/ophalen-facturen-x-producten', params);
  },
};

export default factuur;
