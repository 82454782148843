import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import LoadingSpinner from '../../../components/Gedeeld/LoadingSpinner';
import { RootStoreContext } from '../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import useUrlState from '../../../core/useUrlState';
import {
  IAspGebruiker,
  IAspGebruikersprofiel,
  IOphalenAspGebruikerStatussenResultElement,
} from '../../../../../shared/src/api/v2/aspGebruiker';
import api from '../../../api';
import {
  DXTableCheckboxComponent,
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
  DXTableToggleCellComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../helpers/dxTableGrid';
import MenuLayout from '../../../components/MenuLayout';
import {
  Grid,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  DataTypeProvider,
  EditingState,
  RowDetailState,
  SelectionState,
} from '@devexpress/dx-react-grid';
import { IOphalenMedewerkersResultElement } from '../../../../../shared/src/api/v2/medewerker';
import { Kleur } from '../../../bedrijfslogica/constanten';
import { IconToevoegen, IconVerwijderen } from '../../../components/Icons';
import DetailComp from './DetailComp';
import InstellenWachtwoordDialoog from './InstellenWachtwoordDialoog';
import nameOf from '../../../core/nameOf';
import { format } from 'date-fns';
import WijzigenGebruikerDialoog from './WijzigenGebruikerDialoog';

interface IProps extends RouteComponentProps {}

export interface IInstellenWachtwoordDialoogState {
  aspGebrID: number;
}
export interface IWijzigenGebruikerDialoogState {
  aspGebrID: number;
}

interface IUrlState {
  selectie: number[];
  instellenWachtwoordDialoogState: IInstellenWachtwoordDialoogState | null;
  wijzigenGebruikerDialoogState: IWijzigenGebruikerDialoogState | null;
}

const defaultUrlState: IUrlState = {
  selectie: [],
  instellenWachtwoordDialoogState: null,
  wijzigenGebruikerDialoogState: null,
};

export interface IRegel extends IAspGebruiker {
  profiel: IAspGebruikersprofiel | null;
}

const Beheer: React.FC<IProps> = observer((props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);

  const [gebruikers, setGebruikers] = useState<IRegel[] | null>(null);

  const ophalenGebruikers = useCallback(async () => {
    const gebruikersResult = (
      await api.v2.aspGebruiker.ophalenGebruikers({
        filterSchema: {
          filters: [],
        },
      })
    ).gebruikers;

    const persIDs = gebruikersResult.filter((x) => x.PersID !== null).map((x) => x.PersID);

    const statussenResult = (await api.v2.aspGebruiker.ophalenAspGebruikerStatussen({})).statussen;

    // const mdwIDs = gebruikersResult.filter((x) => x.MdwID !== null).map((x) => x.MdwID);
    const aspGebrProfielIDs = gebruikersResult
      .filter((x) => x.AspGebrProfielID !== null)
      .map((x) => x.AspGebrProfielID!);

    // const medewerkersResult = (
    //   await api.v2.medewerker.ophalenMedewerkers({
    //     filterSchema: { filters: [{ naam: 'PERS_IDS', data: persIDs }] },
    //   })
    // ).medewerkers;

    const profielenResult = (
      await api.v2.aspGebruiker.ophalenGebruikersprofielen({
        filterSchema: { filters: [{ naam: 'IDS', data: aspGebrProfielIDs }] },
      })
    ).profielen;

    const gebruikers = gebruikersResult.map((gebruiker) => {
      // const medewerker =
      //   gebruiker.MdwID !== null
      //     ? medewerkersResult.find(
      //         (x) => x.persoon !== null && x.persoon.PersID === gebruiker.MdwID,
      //       )!
      //     : null;

      const profiel =
        gebruiker.AspGebrProfielID !== null
          ? profielenResult.find((x) => x.ID === gebruiker.AspGebrProfielID)!
          : null;

      return { ...gebruiker, profiel };
    });

    setGebruikers(gebruikers);
  }, []);

  const keyExtractor = useCallback((row: IRegel) => row.AspGebrID, []);

  const kolommen = useMemo<TypedColumn<IRegel>[]>(
    () => [
      {
        name: 'GebruikersNaam',
        title: 'Gebruikersnaam',
      },
      {
        name: 'NaamKort',
        title: 'Korte naam',
      },
      {
        name: 'ApiSleutel',
        title: 'Api-sleutel',
      },
      {
        name: '__standaardStatus' as any,
        title: 'Standaard status',
      },
      // {
      //   name: '__medewerker' as any,
      //   title: 'Medewerker',
      // },
      {
        name: '__profiel' as any,
        title: 'Standaard profiel',
      },
      {
        name: 'RecordGewijzigd',
        title: 'Laatst gewijzigd',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRegel>[]>(
    () => [
      {
        columnName: 'GebruikersNaam',
        width: 125,
      },
      {
        columnName: 'NaamKort',
        width: 95,
      },
      {
        columnName: 'ApiSleutel',
        width: 300,
      },
      {
        columnName: '__standaardStatus' as any,
        width: 150,
      },
      // {
      //   columnName: '__medewerker' as any,
      //   width: 150,
      // },
      {
        columnName: '__profiel' as any,
        width: 150,
      },
      {
        columnName: 'RecordGewijzigd',
        width: 135,
      },
    ],
    [],
  );

  useEffect(() => {
    ophalenGebruikers();
  }, [ophalenGebruikers]);

  // useEffect(() => {
  //   ophalenMedewerkers();
  // }, [ophalenMedewerkers]);

  return (
    <>
      {gebruikers === null ? (
        <div className="d-flex flex-column flex-fill align-items-center justify-content-center">
          <LoadingSpinner />
        </div>
      ) : (
        <MenuLayout
          menu={
            <div className="d-flex">
              <button
                className="btn btn-sm btn-light d-flex align-items-center"
                style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                disabled={false}
                onClick={() => null}
              >
                <IconToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                <span className="ml-2">Nieuw</span>
              </button>
              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-3"
                style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                disabled={urlState.selectie.length !== 1}
                onClick={() =>
                  setUrlStateSync('instellenWachtwoordDialoogState', {
                    aspGebrID: urlState.selectie[0],
                  })
                }
              >
                {/* <IconVerwijderen style={{ width: 16, height: 16, fill: Kleur.Grijs }} /> */}
                <span className="ml-2">Wachtwoord instellen</span>
              </button>
              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-3"
                style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                disabled={urlState.selectie.length === 0}
                onClick={() => null}
              >
                <IconVerwijderen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                <span className="ml-2">Verwijderen</span>
              </button>
            </div>
          }
          body={
            <GridStyleWrapper height="calc(100vh - 150px)">
              <Grid rows={gebruikers} columns={kolommen} getRowId={keyExtractor}>
                <DataTypeProvider
                  for={[nameOf<IRegel>('RecordGewijzigd')]}
                  formatterComponent={(fmtProps) => {
                    const row: IRegel = fmtProps.row;
                    return (
                      <span>
                        {row.RecordGewijzigd !== null
                          ? format(new Date(row.RecordGewijzigd), 'dd-MM-yyyy HH:mm')
                          : null}
                      </span>
                    );
                  }}
                />

                {/* <DataTypeProvider
                  for={['__medewerker']}
                  formatterComponent={(formatterProps) => {
                    const rij = formatterProps.row as IRegel;

                    if (rij.medewerker === null) {
                      return <span></span>;
                    }
                    if (rij.medewerker.persoon === null) {
                      return <span></span>;
                    }
                    return <span>{rij.medewerker.persoon.Achternaam}</span>;
                  }}
                /> */}

                <DataTypeProvider
                  for={['__profiel']}
                  formatterComponent={(formatterProps) => {
                    const rij = formatterProps.row as IRegel;

                    if (rij.profiel === null) {
                      return <span></span>;
                    }
                    return <span>{rij.profiel.Naam}</span>;
                  }}
                />

                <EditingState
                  onCommitChanges={async (changes) => {
                    if (changes.deleted === undefined) {
                      return;
                    }
                    const deleted = changes.deleted;
                    const id = deleted[deleted.length - 1] as number;
                  }}
                  onEditingRowIdsChange={(rowIds) => {
                    const id = rowIds[rowIds.length - 1] as number;
                    setUrlStateSync('wijzigenGebruikerDialoogState', { aspGebrID: id });
                  }}
                />

                <VirtualTable
                  messages={{
                    noData: 'Geen gebruikers',
                  }}
                />
                <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
                <TableEditColumn
                  width={35}
                  showEditCommand
                  // showDeleteCommand
                  cellComponent={DXTableEditColumnCellComponent}
                  commandComponent={DXTableEditColumnCommandComponent}
                />
                <TableHeaderRow />
                {/* <RowDetailState defaultExpandedRowIds={[]} />
                <TableRowDetail
                  toggleCellComponent={DXTableToggleCellComponent}
                  contentComponent={DetailComp}
                /> */}
                <SelectionState
                  selection={urlState.selectie}
                  onSelectionChange={(x) => setUrlStateSync('selectie', x as number[])}
                />
                <TableSelection cellComponent={DXTableCheckboxComponent} />
              </Grid>
            </GridStyleWrapper>
          }
        />
      )}
      {urlState.instellenWachtwoordDialoogState !== null && (
        <InstellenWachtwoordDialoog
          open
          aspGebrID={urlState.instellenWachtwoordDialoogState.aspGebrID}
          onSuccess={() => setUrlStateSync('instellenWachtwoordDialoogState', null)}
          onAnnuleren={() => setUrlStateSync('instellenWachtwoordDialoogState', null)}
        />
      )}
      {urlState.wijzigenGebruikerDialoogState !== null && (
        <WijzigenGebruikerDialoog
          open
          aspGebrID={urlState.wijzigenGebruikerDialoogState.aspGebrID}
          onSuccess={() => {
            ophalenGebruikers();
            setUrlStateSync('wijzigenGebruikerDialoogState', null);
          }}
          onAnnuleren={() => setUrlStateSync('wijzigenGebruikerDialoogState', null)}
        />
      )}
    </>
  );
});

export default Beheer;
