import React, { useCallback } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { observer } from 'mobx-react-lite';
import api from '../../../api';
import useUrlState from '../../../core/useUrlState';
import RunTabelV2, {
  ERunTabelV2Kolom,
  IData,
  IDataProviderOutput,
  IDataProviderParams,
} from '../../../components/prolongatie/RunTabelV2';
import { Helmet } from 'react-helmet';
import { ESortering, IAspKolomSorteringItem } from '../../../components/tabel/ASPTabel/types';

interface IProps extends RouteComponentProps {}

interface IUrlState {
  selectie: number[];
  regelsSelectie: number[];
  uitgeklapt: number[];
  sortering: IAspKolomSorteringItem<ERunTabelV2Kolom>[];
}

const defaultUrlState: IUrlState = {
  selectie: [],
  regelsSelectie: [],
  uitgeklapt: [],
  sortering: [],
};

const Prolongaties: React.FC<IProps> = observer((props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState<IUrlState>(props, defaultUrlState);

  const dataProvider = useCallback(async (params: IDataProviderParams): Promise<
    IDataProviderOutput
  > => {
    const runsResult = await api.v2.prolongatie.ophalenProlongatieRunsV2({
      filterSchema: params.filterSchema,
      paginatie: params.paginatie,
      orderSchema: {
        orders: [
          {
            naam: 'DATUM_RUN',
            richting: 'DESC',
          },
        ],
      },
    });
    const afgeleideDatasResult =
      runsResult.prolongatieRuns.length === 0
        ? null
        : await api.v2.prolongatie.ophalenProlongatieRunAfgeleideDatas({
            prolRunIDs: runsResult.prolongatieRuns.map((run) => run.ID),
          });

    const afgeleideDatas =
      afgeleideDatasResult === null
        ? params.basis?.afgeleideDatas ?? {}
        : {
            ...params.basis?.afgeleideDatas,
            ...afgeleideDatasResult.afgeleideDatas,
          };

    const runs = runsResult.prolongatieRuns.reduce(
      (acc, run, i) => ({
        ...acc,
        [params.paginatie.index + i]: run,
      }),
      params.basis?.runs ?? {},
    );

    const data: IData = {
      totaalAantalRuns: runsResult.totaalAantal,
      runs,
      afgeleideDatas,
    };

    return {
      data,
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Prolongaties</title>
      </Helmet>
      <RunTabelV2
        dataProvider={dataProvider}
        selectie={urlState.selectie}
        onSelectieChange={(x) => setUrlStateSync('selectie', x)}
        uitgeklapt={urlState.uitgeklapt}
        onUitgeklaptChange={(x) => setUrlStateSync('uitgeklapt', x)}
        sortering={urlState.sortering}
        onSorteringChange={(x) => setUrlStateSync('sortering', x)}
      />
    </>
  );
});

export default withRouter(Prolongaties);
