import {
  IOphalenCrediteurenParams,
  IOphalenCrediteurenResult,
} from '../../../../../shared/src/api/v2/crediteur';
import api from '../../index';
import ICheckData from '../../../../../shared/src/models/ICheckData';

const crediteur = {
  ophalenCrediteuren: async (
    params: IOphalenCrediteurenParams,
  ): Promise<IOphalenCrediteurenResult> => {
    return await api.post('/v2/crediteur/ophalen-crediteuren', params);
  },
};

export default crediteur;
