import React, { useCallback, useMemo } from 'react';
import MultiComboboxV2MetZoeken, {
  IMultiComboboxV2MetZoekenOverlayState,
} from '../../../formulier/MultiComboboxV2/MetZoeken';
import { IOphalenProducttypenInMutatiesResultElement } from '../../../../../../shared/src/api/v2/voorraad';
import { ASPKolom, EAspKolomBreedteType } from '../../../tabel/ASPTabel/types';
import { EnkeleProvider, IRepresentatieProps, Provider } from '../../../formulier/MultiComboboxV2';
import api from '../../../../api';
import { IFilterSchemaFilter } from '../../../../../../shared/src/models/filter';

type Kolom = 'typenaam' | 'merknaam' | 'productsoortnaam';

interface IProps {
  typeID: number | null;
  onTypeIDChange: (value: number | null) => void;
}

const ProducttypeFilterCombobox = (props: IProps) => {
  const provider = useMemo<
    Provider<
      Kolom,
      IOphalenProducttypenInMutatiesResultElement,
      IMultiComboboxV2MetZoekenOverlayState
    >
  >(
    () => async (params) => {
      const result = await api.v2.voorraad.ophalenProducttypenInMutaties({
        filterSchema: {
          filters: [
            params.overlayContainerState.zoekterm.length > 0
              ? { naam: 'ZOEKTERM', data: params.overlayContainerState.zoekterm }
              : null,
          ].filter((x) => x !== null) as IFilterSchemaFilter[],
        },
        orderSchema: {
          orders: [
            {
              naam: 'TYPE_NAAM',
              richting: 'ASC',
            },
            {
              naam: 'MERK_NAAM',
              richting: 'ASC',
            },
          ],
        },
      });

      const items = result.producttypen.reduce<
        Record<number, IOphalenProducttypenInMutatiesResultElement>
      >((acc, curr, i) => {
        acc[params.paginatie.index + i] = curr;
        return acc;
      }, params.huidigeBron);

      return {
        items,
        totaalAantal: result.totaalAantal,
      };
    },
    [],
  );
  const enkeleProvider = useMemo<
    EnkeleProvider<number, IOphalenProducttypenInMutatiesResultElement>
  >(
    () => async (id) => {
      const result = await api.v2.voorraad.ophalenProducttypenInMutaties({
        filterSchema: {
          filters: [{ naam: 'IDS', data: [id] }],
        },
      });
      return result.producttypen[0];
    },
    [],
  );
  const keyExtractor = useCallback(
    (rij: IOphalenProducttypenInMutatiesResultElement) => rij.TypeID,
    [],
  );
  const kolommen = useMemo<ASPKolom<Kolom, IOphalenProducttypenInMutatiesResultElement>[]>(
    () => [
      {
        key: 'typenaam',
        label: 'Type',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 175,
        renderer: (rij) => rij.Typenaam,
      },
      {
        key: 'merknaam',
        label: 'Merk',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 125,
        renderer: (rij) => rij.Merknaam,
      },
      {
        key: 'productsoortnaam',
        label: 'Productsoort',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 200,
        renderer: (rij) => rij.Productsoortnaam,
      },
    ],
    [],
  );

  return (
    <MultiComboboxV2MetZoeken
      provider={provider}
      enkeleProvider={enkeleProvider}
      keyExtractor={keyExtractor}
      waarde={props.typeID}
      onChange={props.onTypeIDChange}
      representatieComponent={Representatie}
      kolommen={kolommen}
      overlayOptions={{
        height: 500,
      }}
    />
  );
};

const Representatie = (props: IRepresentatieProps<IOphalenProducttypenInMutatiesResultElement>) => {
  return (
    <span>
      {props.entiteit.Typenaam} - {props.entiteit.Merknaam}
    </span>
  );
};

export default ProducttypeFilterCombobox;
