import React, { HTMLProps, useCallback, useContext, useMemo, useState } from 'react';
import ZoekVeld, { IZoekVeldInputComponentProps } from '../../ZoekVeld';
import {
  IOphalenLijstRecentResultElement,
  ISelecterenRelatiesResultElement,
} from '../../../../../shared/src/api/v2/relatie/Selectie';
import api from '../../../api';
import {
  IconDatumreeks,
  IconKlok,
  IconKruis,
  IconTarget,
  IconToevoegen,
  IconUitklappen,
} from '../../Icons';
import { Kleur } from '../../../bedrijfslogica/constanten';
import RelatieSelectieDialoog, {
  EHoedanigheid,
  ETabblad,
  IRelatieSelectieDialoogResult,
} from '../../personalia/RelatieSelectieDialoog';
import NieuweKlantDialoog from './NieuweKlantDialoog';
import Zoekresultaat from './ZoekResultaat';
import { EKaartType } from '../root';
import { RootStoreContext } from '../../../stores/RootStore';
import { EResultType } from '../../../stores/CheckStore';
import NieuweLeverancierDialoog from './NieuweLeverancierDialoog';
import BezoekdagenInfoDialoog from '../../transport/BezoekdagenInfoDialoog';
import styled from 'styled-components';
// import BezoekdagenInfoDialoog from '../../Transport/BezoekdagenInfoDialoog';

interface IProps {
  zoekterm: string;
  onZoektermChange: (zoekterm: string) => void;
  navigeerNaarRelID: (relID: number) => Promise<void>;
  navigeerNaarVorigeRelatie: () => Promise<void>;
  recenteReferenties: IOphalenLijstRecentResultElement[] | null;
  type: EKaartType;
  relID: number | null;
  hoedanigheid: 'KLANT' | 'LEVERANCIER';
  onVerversenAangevraagd: () => void;
}

interface IRelatieSelectieDialoogState {
  tabblad: ETabblad;
}
interface IBezoekdagenInfoDialoogState {
  postcode?: string;
}

const ZoekveldInput = styled.input`
  flex: 1;
  border: 0;
  outline: 0;
  background: ${Kleur.HeelLichtGrijs};
  padding: 18px 20px;

  ::-webkit-input-placeholder {
    font-style: italic;
  }
  :-moz-placeholder {
    font-style: italic;
  }
  ::-moz-placeholder {
    font-style: italic;
  }
  :-ms-input-placeholder {
    font-style: italic;
  }
`;

const ControlKnoppen = styled.div`
  display: flex;

  button {
    background: ${Kleur.Wit};
    &:hover {
      background: ${Kleur.HeelLichtGrijs};
    }
  }
`;

const Control = (props: IProps) => {
  const { contractenStore, klantkaartStore, checkStore } = useContext(RootStoreContext);

  const [isAanHetLaden, setIsAanHetLaden] = useState(false);
  const [
    relatieSelectieDialoogState,
    setRelatieSelectieDialoogState,
  ] = useState<IRelatieSelectieDialoogState | null>(null);
  const [
    bezoekdagenInfoDialoogState,
    setBezoekdagenInfoDialoogState,
  ] = useState<IBezoekdagenInfoDialoogState | null>(null);

  const [modalNieuweRelatieIsOpen, setModalNieuweRelatie] = useState(false);
  const handleVorigeClick = useCallback(async () => {
    setIsAanHetLaden(true);
    await props.navigeerNaarVorigeRelatie();
    setIsAanHetLaden(false);
  }, [setIsAanHetLaden, props.navigeerNaarVorigeRelatie]);

  const handleRelatieSelectieDialoogSuccess = useCallback(
    (result: any) => {
      props.navigeerNaarRelID(result.relID);
      setRelatieSelectieDialoogState(null);
    },
    [setRelatieSelectieDialoogState, props.navigeerNaarRelID],
  );

  const handleNieuweRelatieSuccess = useCallback(
    (dialoogResult: any) => {
      setModalNieuweRelatie(false);
      props.navigeerNaarRelID(dialoogResult.relID);
    },
    [props.navigeerNaarRelID],
  );

  const handleProductdocumentatieVersturen = useCallback(async () => {
    if (props.relID === null) {
      return;
    }
    // const params = { relID, cntIDs: selectie };
    // const checkData = await api.v2.contract.nieuw.checkVersturenBevestigingsverzoek(params);
    // if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
    //   return;
    // }

    if (
      (
        await checkStore.bevestigen({
          inhoud: <span>Productdocumentatie sturen?</span>,
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    await api.v2.service.versturenProductdocumentatie({ relID: props.relID });
  }, [props.relID]);

  const zoekVeldInputComponent = useMemo<
    React.ForwardRefExoticComponent<IZoekVeldInputComponentProps>
  >(
    () =>
      React.forwardRef((props: IZoekVeldInputComponentProps, ref) => {
        const value = (props.value ?? '') as string;
        return (
          <div
            className="d-flex align-items-center"
            style={{
              background: Kleur.HeelLichtGrijs,
            }}
          >
            <ZoekveldInput
              {...(props as any)}
              ref={ref}
              className=""
              placeholder="Zoek hier bijv. op rel.nr, naam, email, postcode, ref.nr, factuurnr ..."
            />
            {value.length > 1 && (
              <div className="ml-2 mr-2">
                <button
                  style={{ background: 0, border: 0, outline: 0 }}
                  onClick={(ev) => {
                    ev.preventDefault();
                    props.onValueChange('');
                  }}
                >
                  <IconKruis style={{ fill: Kleur.Grijs, width: 18, height: 18 }} />
                </button>
              </div>
            )}
          </div>
        );
      }) as any,
    [],
  );

  return (
    <>
      <div className="d-flex flex-column">
        <ControlKnoppen>
          <button
            className="d-flex align-items-center justify-content-center flex-fill p-2"
            onClick={() => setRelatieSelectieDialoogState({ tabblad: ETabblad.Selecteren })}
            style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
          >
            <IconTarget style={{ height: 15, width: 15, fill: Kleur.Grijs }} />
            <span className="ml-2" style={{ marginBottom: 1 }}>
              Selecteren
            </span>
          </button>
          <button
            className="d-flex align-items-center justify-content-center flex-fill p-2"
            onClick={() => setRelatieSelectieDialoogState({ tabblad: ETabblad.Recent })}
            style={{
              border: `1px solid ${Kleur.LichtGrijs}`,
              borderLeft: 0,
            }}
          >
            <IconKlok style={{ height: 17, width: 17, fill: Kleur.Grijs }} />

            <span className="ml-2" style={{ marginBottom: 1 }}>
              Recent
            </span>
          </button>
          <button
            className="d-flex align-items-center justify-content-center flex-fill p-2"
            onClick={handleVorigeClick}
            disabled={
              props.recenteReferenties === null ||
              props.recenteReferenties.length < 2 ||
              isAanHetLaden
            }
            style={{
              border: `1px solid ${Kleur.LichtGrijs}`,
              borderLeft: 0,
            }}
          >
            <IconUitklappen
              style={{
                height: 16,
                width: 16,
                fill: Kleur.Grijs,
                transform: 'rotate(90deg)',
              }}
            />
            <IconUitklappen
              style={{
                height: 16,
                width: 16,
                fill: Kleur.Grijs,
                transform: 'rotate(-90deg)',
                marginLeft: -8,
              }}
            />
            <span className="ml-1" style={{ marginBottom: 1 }}>
              Vorige
            </span>
          </button>
          <button
            className="d-flex align-items-center justify-content-center flex-fill p-2"
            onClick={() => setModalNieuweRelatie(true)}
            disabled={isAanHetLaden}
            style={{
              border: `1px solid ${Kleur.LichtGrijs}`,
              borderLeft: 0,
              borderRight: 0,
            }}
          >
            <IconToevoegen style={{ height: 18, width: 18, fill: Kleur.Grijs }} />
            <span className="ml-2" style={{ marginBottom: 1 }}>
              Nieuw
            </span>
          </button>
        </ControlKnoppen>

        <ZoekVeld<ISelecterenRelatiesResultElement>
          term={props.zoekterm}
          onTermChange={props.onZoektermChange}
          onZoekenAangevraagd={async () => {
            const resultaat = await api.v2.relatieZoeken.zoeken({
              zoekterm: props.zoekterm,
              paginatie: {
                index: 0,
                aantal: 30,
              },
              relatieFilterSchema: {
                filters: [
                  {
                    naam: 'HOEDANIGHEID_NAAM_ENUMS',
                    data: [props.type === EKaartType.Klant ? 'KLANT' : 'LEVERANCIER'],
                  },
                ],
              },
            });
            return {
              items: resultaat.relaties,
              totaalAantal: resultaat.totaalAantal,
            };
          }}
          resultaatComponent={(resultaatProps) => (
            <Zoekresultaat
              relatie={resultaatProps.item}
              onClick={async () => {
                props.onZoektermChange('');
                await props.navigeerNaarRelID(resultaatProps.item.RelID);
              }}
            />
          )}
          breedteResultaat={405}
          inputComponent={zoekVeldInputComponent}
        />

        {/* <div className="mr-2" style={{ marginLeft: 1 }}>
          <VerticaleScheidingslijn height={42} />
        </div> */}

        {/*<div className="d-flex p-2 pl-0 pr-0">*/}
        {/*  <BezigheidKnop*/}
        {/*    interactieToegestaan={true}*/}
        {/*    data={{*/}
        {/*      enum: 'RELATIE',*/}
        {/*      relID: props.relID!,*/}
        {/*    }}*/}
        {/*  />*/}
        {/*</div>*/}

        {/*<button*/}
        {/*  className="btn btn-light d-flex align-items-center btn-sm ml-2"*/}
        {/*  onClick={() => setTaakToevoegenDialoogState({})}*/}
        {/*>*/}
        {/*  <IconToevoegen style={{ height: 16, width: 16, fill: Kleur.Grijs }} />*/}
        {/*  <span className="ml-2">Taak toevoegen</span>*/}
        {/*</button>*/}
      </div>
      {/*
        <div className="ml-3 mr-1">
          <VerticaleScheidingslijn height={42} />
        </div>

        <button
          className="btn btn-light d-flex align-items-center btn-sm ml-2"
          onClick={() => setBezoekdagenInfoDialoogState({})}
        >
          <IconDatumreeks style={{ height: 15, width: 15, fill: Kleur.Grijs }} />
          <span className="ml-2">Bezoekdagen Info</span>
        </button> */}

      {/* <button
          className="btn btn-light d-flex align-items-center btn-sm ml-2"
          onClick={() => handleProductdocumentatieVersturen()}
        >
          <IcoonTarget style={{ height: 15, width: 15, fill: Kleur.Grijs }} />
          <span className="ml-2">Productdocumentatie versturen</span>
        </button> */}
      {relatieSelectieDialoogState !== null && (
        <RelatieSelectieDialoog
          open
          onSuccess={handleRelatieSelectieDialoogSuccess}
          onAnnuleren={() => setRelatieSelectieDialoogState(null)}
          koppelOpties={{
            hoedanigheid:
              props.type === EKaartType.Klant ? EHoedanigheid.Klant : EHoedanigheid.Leverancier,
          }}
          defaultTabblad={relatieSelectieDialoogState.tabblad}
        />
      )}
      {modalNieuweRelatieIsOpen &&
        (props.hoedanigheid === 'KLANT' ? (
          <NieuweKlantDialoog
            open
            onAnnuleren={() => setModalNieuweRelatie(false)}
            onSuccess={handleNieuweRelatieSuccess}
          />
        ) : (
          <NieuweLeverancierDialoog
            open
            onSuccess={handleNieuweRelatieSuccess}
            onAnnuleren={() => setModalNieuweRelatie(false)}
          />
        ))}
      {bezoekdagenInfoDialoogState !== null && (
        <BezoekdagenInfoDialoog
          postcode={bezoekdagenInfoDialoogState.postcode}
          open
          onSuccess={async (result) => {
            setBezoekdagenInfoDialoogState(null);
          }}
          onAnnuleren={() => setBezoekdagenInfoDialoogState(null)}
        />
      )}
    </>
  );
};

export default Control;
