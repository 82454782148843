import React, { useMemo } from 'react';
import styled from 'styled-components';
import { IconChevronRight, IconPersoon, IconSMS } from '../../../../Icons';
import { Kleur } from '../../../../../bedrijfslogica/constanten';
import { useWhatsappV2Store } from '../../store';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../../models/IRemoteData';
import LoadingSpinner from '../../../../Gedeeld/LoadingSpinner';
import ChatsessieItem from '../ChatsessieItem';
import { ZoekenFocus } from '../../helpers';
import { IWhatsappBericht, IWhatsappChatsessie } from '../../../../../api/services/v1/whatsapp';
import BerichtItem from './BerichtItem';
import ChatsFocusWeergave from './ChatsFocusWeergave';
import BerichtFocusWeergave from './BerichtFocusWeergave';
import { EWhatsappTabblad } from '../../types';

const Root = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const FocusSelectieContainer = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

const FocusSelectieButton = styled.button`
  display: flex;
  align-items: center;
  transition: background-color 0.2s ease-in-out;
  background-color: transparent;
  font-size: 14px;
  font-weight: bold;
  padding: 8px 16px;
  width: 100%;
  border: none;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }

  &:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  &:disabled {
    background-color: rgba(0, 0, 0, 0.1);
    cursor: not-allowed;
  }
`;

const ResultatenContainer = styled.div<{ heeftFocusSelectie: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: ${(props) => (props.heeftFocusSelectie ? 0 : 76)}px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
`;

const CategorieKop = styled.h2`
  font-size: 21px;
  font-weight: bold;
  margin-left: 20px;
  margin-top: 20px;
`;

interface IProps {
  zoekterm: string;
  onZoektermChange: (zoekterm: string) => void;
  onChatsessieGekozen: (chatsessie: IWhatsappChatsessie) => void;
  onBerichtGekozen: (bericht: IWhatsappBericht, chatsessie: IWhatsappChatsessie) => void;
  onTabbladChange: (tabblad: EWhatsappTabblad) => void;
  zoekenFocus: ZoekenFocus | null;
  onZoekenFocusChange: (zoekenFocus: ZoekenFocus | null) => void;
}

const ZoekenWeergave = (props: IProps) => {
  const store = useWhatsappV2Store();

  const chatsTotaalAantal = useMemo<IRemoteData<number | null>>(() => {
    if (store.zoekResultaat.state === ERemoteDataState.Pending) {
      return createPendingRemoteData();
    }

    if (store.zoekResultaat.data === null) {
      return createReadyRemoteData(null);
    }
    if (store.zoekResultaat.data!.chatsessiesData.state === ERemoteDataState.Pending) {
      return createPendingRemoteData();
    }

    return createReadyRemoteData(store.zoekResultaat.data!.chatsessiesData.data!.totaalAantal);
  }, [store.zoekResultaat]);

  const berichtenTotaalAantal = useMemo<IRemoteData<number | null>>(() => {
    if (store.zoekResultaat.state === ERemoteDataState.Pending) {
      return createPendingRemoteData();
    }

    if (store.zoekResultaat.data === null) {
      return createReadyRemoteData(null);
    }
    if (store.zoekResultaat.data!.berichtenData.state === ERemoteDataState.Pending) {
      return createPendingRemoteData();
    }

    return createReadyRemoteData(store.zoekResultaat.data!.berichtenData.data!.totaalAantal);
  }, [store.zoekResultaat]);

  const heeftFocusSelectie = props.zoekenFocus !== null;

  if (heeftFocusSelectie) {
    switch (props.zoekenFocus!) {
      case 'chats':
        return (
          <Root>
            <ChatsFocusWeergave onChatsessieGekozen={props.onChatsessieGekozen} />
          </Root>
        );
      case 'berichten':
        return (
          <Root>
            <BerichtFocusWeergave onBerichtGekozen={props.onBerichtGekozen} />
          </Root>
        );
    }
  }

  return (
    <Root>
      {!heeftFocusSelectie && (
        <FocusSelectieContainer>
          <FocusSelectieButton
            title="Alleen binnen chats zoeken"
            onClick={() => {
              props.onZoekenFocusChange('chats');
            }}
            disabled={props.zoekterm.length === 0}
          >
            <IconPersoon
              style={{
                fill: Kleur.Grijs,
                width: 18,
                height: 18,
              }}
            />
            <span className="ml-3 flex-fill d-flex justify-content-start">Chats</span>
            {chatsTotaalAantal.state === ERemoteDataState.Ready && chatsTotaalAantal.data !== null && (
              <span className="mr-2" style={{ color: Kleur.ZwembadBlauw, fontSize: 13 }}>
                {chatsTotaalAantal.data!}
              </span>
            )}
            <IconChevronRight
              style={{
                fill: Kleur.Grijs,
                width: 21,
                height: 21,
              }}
            />
          </FocusSelectieButton>
          <FocusSelectieButton
            title="Alleen binnen berichten zoeken"
            onClick={() => props.onZoekenFocusChange('berichten')}
            disabled={props.zoekterm.length === 0}
          >
            <IconSMS
              style={{
                fill: Kleur.Grijs,
                width: 18,
                height: 18,
              }}
            />
            <span className="ml-3 flex-fill d-flex justify-content-start">Berichten</span>
            {berichtenTotaalAantal.state === ERemoteDataState.Ready &&
              berichtenTotaalAantal.data !== null && (
                <span className="mr-2" style={{ color: Kleur.ZwembadBlauw, fontSize: 13 }}>
                  {berichtenTotaalAantal.data!}
                </span>
              )}
            <IconChevronRight
              style={{
                fill: Kleur.Grijs,
                width: 21,
                height: 21,
              }}
            />
          </FocusSelectieButton>
        </FocusSelectieContainer>
      )}
      {store.zoekResultaat.state === ERemoteDataState.Ready && store.zoekResultaat.data === null ? (
        <div className="d-flex flex-fill align-items-center justify-content-center">
          <span className="text-muted">Start met zoeken om resultaten weer te geven</span>
        </div>
      ) : store.zoekResultaat.state === ERemoteDataState.Pending ? (
        <div className="d-flex flex-fill align-items-center justify-content-center">
          <LoadingSpinner />
        </div>
      ) : (
        <ResultatenContainer heeftFocusSelectie={heeftFocusSelectie}>
          {store.zoekResultaat.data!.chatsessiesData.state === ERemoteDataState.Pending ? (
            <LoadingSpinner />
          ) : store.zoekResultaat.data!.chatsessiesData.data!.totaalAantal > 0 ? (
            <>
              <CategorieKop>Chats</CategorieKop>
              <div>
                {Object.keys(store.zoekResultaat.data!.chatsessiesData.data!.chatsessieIDBijIndex)
                  .slice(0, 3)
                  .map((key) => {
                    const index = parseInt(key, 10);
                    const chatsessieIDData = store.zoekResultaat.data!.chatsessiesData.data!
                      .chatsessieIDBijIndex[index];
                    if (
                      chatsessieIDData === undefined ||
                      chatsessieIDData.state === ERemoteDataState.Pending
                    ) {
                      return null;
                    }
                    const chatsessie = store.chatsessieBijID.data![chatsessieIDData.data!];

                    return (
                      <ChatsessieItem
                        key={chatsessie.id}
                        chatsessie={chatsessie}
                        actief={false}
                        onClick={() => {
                          props.onChatsessieGekozen(chatsessie);
                        }}
                        tabblad={EWhatsappTabblad.Archief}
                        onTabbladChange={props.onTabbladChange}
                        onChatsessieGekozen={() => {
                          props.onChatsessieGekozen(chatsessie);
                        }}
                        isZoekenWeergave
                      />
                    );
                  })}
                {chatsTotaalAantal.state === ERemoteDataState.Ready &&
                  chatsTotaalAantal.data !== null &&
                  chatsTotaalAantal.data > 3 && (
                    <div className="pl-4 pt-2">
                      <a
                        href="#"
                        onClick={(ev) => {
                          ev.preventDefault();
                          props.onZoekenFocusChange('chats');
                        }}
                      >
                        Alle {chatsTotaalAantal.data!} chats weergeven
                      </a>
                    </div>
                  )}
              </div>
            </>
          ) : null}
          {store.zoekResultaat.data!.berichtenData.state === ERemoteDataState.Pending ? (
            <LoadingSpinner />
          ) : store.zoekResultaat.data!.berichtenData.data!.totaalAantal > 0 ? (
            <>
              <CategorieKop>Berichten</CategorieKop>
              <div>
                {Object.keys(store.zoekResultaat.data!.berichtenData.data!.berichtIDBijIndex).map(
                  (key) => {
                    const index = parseInt(key, 10);
                    const berichtIDData = store.zoekResultaat.data!.berichtenData.data!
                      .berichtIDBijIndex[index];
                    if (
                      berichtIDData === undefined ||
                      berichtIDData.state === ERemoteDataState.Pending ||
                      store.chatsessieBijID.state === ERemoteDataState.Pending
                    ) {
                      return null;
                    }
                    const bericht = store.berichtBijID[berichtIDData.data!];
                    const chatsessie = store.chatsessieBijID.data![bericht.whatsappChatsessieId];

                    return (
                      <BerichtItem
                        key={bericht.id}
                        bericht={bericht}
                        chatsessie={chatsessie}
                        onClick={() => {
                          props.onBerichtGekozen(bericht, chatsessie);
                        }}
                      />
                    );
                  },
                )}

                {berichtenTotaalAantal.state === ERemoteDataState.Ready &&
                  berichtenTotaalAantal.data !== null &&
                  berichtenTotaalAantal.data > 30 && (
                    <div className="pl-4 pt-2 pb-2">
                      <a
                        href="#"
                        onClick={(ev) => {
                          ev.preventDefault();
                          props.onZoekenFocusChange('berichten');
                        }}
                      >
                        Alle {berichtenTotaalAantal.data!} berichten weergeven
                      </a>
                    </div>
                  )}
              </div>
            </>
          ) : null}
        </ResultatenContainer>
      )}
    </Root>
  );
};

export default ZoekenWeergave;
