import React from 'react';
import { RouteComponentProps } from 'react-router';

interface IProps extends RouteComponentProps {}

const Voorkeuren = (props: IProps) => {
  return <div />;
};

export default Voorkeuren;
