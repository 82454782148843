import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Root } from './style';
import { TextInput } from '../style';
import {
  IconKruis,
  IconVerwijderen,
  IconActiesHorizontaal,
  IconToevoegen,
  IconPersoon,
  IconOrganisatie,
  IconWijzigen,
} from '../../../../Icons';
import IEmailGeadresseerde from '../../../../../../../shared/src/models/email/IEmailGeadresseerde';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Overlay from 'react-bootstrap/Overlay';
import api from '../../../../../api';
import {
  IOphalenGeadresseerdenSuggestiesResult,
  IOphalenGeadresseerdenSuggestiesResultPersoon,
  IOphalenGeadresseerdenSuggestiesResultOrganisatie,
} from '../../../../../../../shared/src/api/v2/dienst/email/email';
import LoadingSpinner from '../../../../Gedeeld/LoadingSpinner';
import email from '../../../../../api/v2/dienst/email';
import {
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../../helpers/dxTableGrid';
import {
  Grid,
  VirtualTable,
  TableHeaderRow,
  TableColumnResizing,
} from '@devexpress/dx-react-grid-bootstrap4';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { Kleur } from '../../../../../bedrijfslogica/constanten';
import PersoonVisualisatie from '../../../../personalia/PersoonVisualisatie';
import RelatieVisualisatie from '../../../../personalia/RelatieVisualisatie';
import RelatieSelectieDialoog from '../../../../personalia/RelatieSelectieDialoog';
import Dialoog from '../../../../dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import Modal from 'react-bootstrap/Modal';
import { IOphalenRelatiesVanPersoonResultElement } from '../../../../../../../shared/src/api/v2/persoon/persoon';
import OrganisatieVisualisatie from '../../../../personalia/OrganisatieVisualisatie';
import { v4 as uuid } from 'uuid';
const emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

interface IProps {
  value: IEmailGeadresseerde[];
  onChange: (value: IEmailGeadresseerde[]) => void;
  text?: string;
  onTextChange?: (text: string) => void;
}

const KEY_CODE_ENTER = 13;
const KEY_CODE_BACKSPACE = 8;

interface IEmailSuggestie {
  key: string;
  persoonSuggestie: IOphalenGeadresseerdenSuggestiesResultPersoon | null;
  organisatieSuggestie: IOphalenGeadresseerdenSuggestiesResultOrganisatie | null;
}

interface IRelatieSelectieResult {
  relID: number;
}

interface IRelatieSelectieParams {
  resolver: (result: IRelatieSelectieResult | null) => void;
  relaties: IOphalenRelatiesVanPersoonResultElement[];
}

const GeadresseerdeInput: React.FC<IProps> = (props) => {
  const id = useMemo(() => uuid(), []);
  const rootRef = useRef<HTMLDivElement>(null);
  const textInputRef = useRef<HTMLInputElement>(null);
  const [textState, setTextState] = useState('');
  const text = useMemo(() => {
    return props.text ?? textState;
  }, [props.text, textState]);
  const setText = useCallback(
    (t: string) => {
      if (props.onTextChange !== undefined) {
        props.onTextChange(t);
        return;
      }
      setTextState(t);
    },
    [props.onTextChange, setTextState],
  );
  const suggestiesOphalenTimeout = useRef<number | null>(null);
  const textInputHasPressedBackspaceBefore = useRef(false);
  const [showSuggestiesOverlay, setShowSuggestiesOverlay] = useState(false);
  const [suggesties, setSuggesties] = useState<IOphalenGeadresseerdenSuggestiesResult | null>(null);
  const [koppelGeadresseerdeEmailAdres, setKoppelGeadresseerdeEmailAdres] = useState<string | null>(
    null,
  );
  const [
    meerdereRelatiesVoorPersoonSelectieParams,
    setMeerdereRelatiesVoorPersoonSelectieParams,
  ] = useState<IRelatieSelectieParams | null>(null);

  const ophalenSuggesties = useCallback(
    async (keyword: string) => {
      const result = await api.v2.email.ophalenGeadresseerdenSuggesties({
        keyword,
      });
      setSuggesties(result);
    },
    [setSuggesties],
  );

  const emailSuggesties: IEmailSuggestie[] | null = useMemo(() => {
    if (suggesties === null) {
      return null;
    }

    return [
      ...suggesties.organisatieSuggesties.map((organisatieSuggestie) => ({
        organisatieSuggestie,
        persoonSuggestie: null,
        key: `O_${organisatieSuggestie.OrgID}`,
      })),
      ...suggesties.persoonSuggesties.map((persoonSuggestie) => ({
        organisatieSuggestie: null,
        persoonSuggestie,
        key: `P_${persoonSuggestie.PersID}`,
      })),
    ];
  }, [suggesties]);

  const handleTextInputChange = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      setText(ev.target.value);
      const trimmedValue = ev.target.value.trim();
      if (trimmedValue !== '') {
        setShowSuggestiesOverlay(true);
        if (suggestiesOphalenTimeout.current !== null) {
          clearTimeout(suggestiesOphalenTimeout.current);
        }
        setSuggesties(null);
        // @ts-ignore
        suggestiesOphalenTimeout.current = setTimeout(() => ophalenSuggesties(trimmedValue), 300);
      }
    },
    [setText, setShowSuggestiesOverlay, suggestiesOphalenTimeout.current, setSuggesties],
  );

  const handleTextInputKeyDown = useCallback(
    (ev: React.KeyboardEvent<HTMLInputElement>) => {
      const val = text.trim();
      // Als de gebruiker 2x backspace doet op een bestaande geadresseerde, dan uit de lijst verwijderen
      if (ev.keyCode === KEY_CODE_BACKSPACE && val === '' && props.value.length > 0) {
        if (textInputHasPressedBackspaceBefore.current) {
          textInputHasPressedBackspaceBefore.current = false;
          props.onChange(props.value.slice(0, props.value.length - 1));
        } else {
          textInputHasPressedBackspaceBefore.current = true;
        }
      } else if (ev.keyCode === KEY_CODE_ENTER) {
        if (val.match(emailRegex) === null) {
          // Geen geldig mailadres ingevoerd
          return;
        }
        if (props.value.some((x) => x.emailAdres === val)) {
          alert('Email addres komt al voor');
          return;
        }
        props.onChange([
          ...props.value,
          {
            emailAdres: text,
            persID: null,
            orgID: null,
          },
        ]);
        setText('');
      } else {
        textInputHasPressedBackspaceBefore.current = false;
      }
      setShowSuggestiesOverlay(false);
    },
    [
      text,
      setText,
      props.onChange,
      props.value,
      setShowSuggestiesOverlay,
      textInputHasPressedBackspaceBefore.current,
    ],
  );

  const handleTextInputFocus = useCallback(() => {
    setShowSuggestiesOverlay(text.trim() !== '');
  }, [text, setShowSuggestiesOverlay]);

  const handleTextInputBlur = useCallback(() => {
    const isEmail = emailRegex.test(text);
    if (!isEmail) {
      return;
    }
    props.onChange([
      ...props.value,
      {
        emailAdres: text,
        persID: null,
        orgID: null,
      },
    ]);
    setText('');
    setShowSuggestiesOverlay(false);
  }, [setShowSuggestiesOverlay, text, props.value, props.onChange, setText]);

  const handleGeadresseerdeVerwijderen = useCallback(
    (geadresseerde: IEmailGeadresseerde) => {
      document.body.click();
      props.onChange(props.value.filter((x) => x.emailAdres !== geadresseerde.emailAdres));
    },
    [props.value, props.onChange],
  );

  const handleSuggestieClick = useCallback(
    async (suggestie: IEmailSuggestie) => {
      document.body.click();
      setShowSuggestiesOverlay(false);
      setText('');
      setSuggesties(null);
      if (suggestie.persoonSuggestie !== null) {
        props.onChange([
          ...props.value,
          {
            emailAdres: suggestie.persoonSuggestie.Email,
            persID: suggestie.persoonSuggestie.PersID,
            orgID: null,
          },
        ]);
      } else {
        props.onChange([
          ...props.value,
          {
            emailAdres: suggestie.organisatieSuggestie!.Email,
            persID: null,
            orgID: suggestie.organisatieSuggestie!.OrgID,
          },
        ]);
      }
    },
    [setText, setSuggesties, setShowSuggestiesOverlay, props.value, props.onChange],
  );

  const emailSuggestieKolommen: TypedColumn<IEmailSuggestie>[] = useMemo(
    () => [
      {
        name: '__relatieSoort' as any,
        title: ' ',
      },
      {
        name: '__visualisatie' as any,
        title: ' ',
      },
      {
        name: '__email' as any,
        title: 'Email',
      },
    ],
    [],
  );

  const emailSuggestieKolomGrootten: TypedTableColumnWidthInfo<IEmailSuggestie>[] = useMemo(
    () => [
      {
        columnName: '__relatieSoort' as any,
        width: 40,
      },
      {
        columnName: '__visualisatie' as any,
        width: 300,
      },
      {
        columnName: '__email' as any,
        width: 300,
      },
    ],
    [],
  );

  const emailSuggestieKeyExtractor = useCallback((row: IEmailSuggestie) => row.key, []);

  const suggestiesOverlay = (
    <Popover
      id={`suggesties-overlay-${id}`}
      style={{
        minHeight: 0,
        maxHeight: 500,
        overflowY: 'hidden',
        maxWidth: 650,
        width: 650,
        display: 'flex',
      }}
    >
      {emailSuggesties === null ? (
        <div
          className="d-flex align-items-center justify-content-center flex-fill"
          style={{ height: 300 }}
        >
          <LoadingSpinner />
        </div>
      ) : emailSuggesties.length === 0 ? (
        <div
          className="d-flex align-items-center justify-content-center flex-fill"
          style={{ height: 300 }}
        >
          <span>Er zijn geen suggesties gevonden.</span>
        </div>
      ) : (
        <div onClick={(ev) => ev.stopPropagation()}>
          <GridStyleWrapper maxHeight={500} rowAmount={emailSuggesties.length}>
            <Grid
              rows={emailSuggesties}
              columns={emailSuggestieKolommen}
              getRowId={emailSuggestieKeyExtractor}
            >
              <DataTypeProvider
                for={['__relatieSoort']}
                formatterComponent={(formatterProps) => {
                  const row: IEmailSuggestie = formatterProps.row;
                  const Icon = row.persoonSuggestie !== null ? IconPersoon : IconOrganisatie;
                  return (
                    <span className="ml-2">
                      <Icon style={{ width: 18, height: 18, fill: Kleur.Grijs }} />
                    </span>
                  );
                }}
              />
              <DataTypeProvider
                for={['__email']}
                formatterComponent={(formatterProps) => {
                  const row: IEmailSuggestie = formatterProps.row;
                  if (row.persoonSuggestie !== null) {
                    return <span>{row.persoonSuggestie.Email}</span>;
                  }
                  return <span>{row.organisatieSuggestie!.Email}</span>;
                }}
              />
              <DataTypeProvider
                for={['__visualisatie']}
                formatterComponent={(formatterProps) => {
                  const row: IEmailSuggestie = formatterProps.row;
                  if (row.persoonSuggestie !== null) {
                    return (
                      <span>
                        <PersoonVisualisatie
                          persID={row.persoonSuggestie.PersID}
                          options={{ geenLinkToepassen: true }}
                        />
                      </span>
                    );
                  }
                  return (
                    <RelatieVisualisatie
                      relID={row.organisatieSuggestie!.RelID}
                      options={{ geenLinkToepassen: true }}
                    />
                  );
                }}
              />

              <VirtualTable
                rowComponent={(rowProps) => {
                  const row: IEmailSuggestie = rowProps.row;
                  return (
                    <tr
                      onClick={(ev) => {
                        ev.stopPropagation();
                        handleSuggestieClick(row);
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      {rowProps.children}
                    </tr>
                  );
                }}
              />
              <TableColumnResizing defaultColumnWidths={emailSuggestieKolomGrootten} />
            </Grid>
          </GridStyleWrapper>
        </div>
      )}
    </Popover>
  );

  return (
    <>
      <Root ref={rootRef}>
        <div className="geadresseerden">
          {props.value.map((geadresseerde) => {
            const actiesOverlay = (
              <Popover id={`geadresseerde-acties-${id}-${geadresseerde.emailAdres}`}>
                <div
                  className="list-group list-group-flush"
                  style={{
                    margin: '-.5rem -.75rem',
                    cursor: 'pointer',
                    border: `1px solid ${Kleur.LichtGrijs}`,
                  }}
                >
                  {geadresseerde.persID !== null || geadresseerde.orgID !== null ? (
                    <>
                      <div
                        className="list-group-item list-group-item-action d-flex align-items-center"
                        onClick={() => {
                          document.body.click();
                          setKoppelGeadresseerdeEmailAdres(geadresseerde.emailAdres);
                        }}
                      >
                        <IconWijzigen style={{ height: 16 }} />
                        <span className="ml-2">Andere relatie koppelen</span>
                      </div>
                      <div
                        className="list-group-item list-group-item-action d-flex align-items-center"
                        onClick={() => {
                          document.body.click();
                          props.onChange(
                            props.value.map((item) => {
                              if (
                                item.orgID === geadresseerde.orgID &&
                                item.persID === geadresseerde.persID
                              ) {
                                return {
                                  ...geadresseerde,
                                  relID: null,
                                  persID: null,
                                };
                              }

                              return item;
                            }),
                          );
                        }}
                      >
                        <IconKruis style={{ height: 16 }} />
                        <span className="ml-2">Relatie ontkoppelen</span>
                      </div>
                    </>
                  ) : (
                    <div
                      className="list-group-item list-group-item-action d-flex align-items-center"
                      onClick={() => {
                        document.body.click();
                        setKoppelGeadresseerdeEmailAdres(geadresseerde.emailAdres);
                      }}
                    >
                      <IconToevoegen style={{ height: 16 }} />
                      <span className="ml-2">Relatie koppelen</span>
                    </div>
                  )}
                  <div
                    className="list-group-item list-group-item-action d-flex align-items-center"
                    onClick={() => handleGeadresseerdeVerwijderen(geadresseerde)}
                  >
                    <IconVerwijderen style={{ height: 16 }} />
                    <span className="ml-2">Verwijderen</span>
                  </div>
                </div>
              </Popover>
            );

            return (
              <span key={geadresseerde.emailAdres} className="geadresseerde">
                {geadresseerde.persID !== null ? (
                  <span className="d-flex align-items-center">
                    <span>
                      <PersoonVisualisatie
                        persID={geadresseerde.persID!}
                        // relID={geadresseerde.relID === null ? undefined : geadresseerde.relID}
                      />
                    </span>
                    <span className="ml-2 text-muted">{geadresseerde.emailAdres}</span>
                  </span>
                ) : geadresseerde.orgID !== null ? (
                  <span className="d-flex align-items-center">
                    <span>
                      <OrganisatieVisualisatie orgID={geadresseerde.orgID!} />
                    </span>
                    <span className="ml-2 text-muted">{geadresseerde.emailAdres}</span>
                  </span>
                ) : (
                  <span>{geadresseerde.emailAdres}</span>
                )}
                <OverlayTrigger
                  overlay={actiesOverlay}
                  trigger="click"
                  placement="bottom"
                  rootClose
                >
                  <button>
                    <IconActiesHorizontaal style={{ width: 15, fill: '#737373' }} />
                  </button>
                </OverlayTrigger>
              </span>
            );
          })}
          <TextInput
            ref={textInputRef}
            value={text}
            onChange={handleTextInputChange}
            onKeyDown={handleTextInputKeyDown}
            onFocus={handleTextInputFocus}
            onBlur={handleTextInputBlur}
          />
        </div>
        <Overlay placement="bottom-start" show={showSuggestiesOverlay} target={rootRef.current!}>
          {suggestiesOverlay}
        </Overlay>
      </Root>
      {koppelGeadresseerdeEmailAdres !== null && (
        <RelatieSelectieDialoog
          open
          onSuccess={(result) => {
            props.onChange(
              props.value.map((item) => {
                if (item.emailAdres === koppelGeadresseerdeEmailAdres) {
                  return {
                    ...item,
                    relID: result.relID,
                    persID: null,
                  };
                }

                return item;
              }),
            );
            setKoppelGeadresseerdeEmailAdres(null);
          }}
          onAnnuleren={() => setKoppelGeadresseerdeEmailAdres(null)}
        />
      )}
      {meerdereRelatiesVoorPersoonSelectieParams !== null && (
        <Dialoog index={0}>
          <div>
            <ModalTitle>Kies een relatie</ModalTitle>
          </div>
          <ModalBody>
            <p>Er zijn meerdere relaties gevonden van dit persoon.</p>
            <div className="d-flex flex-column">
              {meerdereRelatiesVoorPersoonSelectieParams.relaties.map((relatie) => (
                <a
                  href="#"
                  onClick={() => {
                    meerdereRelatiesVoorPersoonSelectieParams!.resolver({
                      relID: relatie.RelID,
                    });
                    setMeerdereRelatiesVoorPersoonSelectieParams(null);
                  }}
                >
                  <RelatieVisualisatie
                    relID={relatie.RelID}
                    options={{ geenLinkToepassen: true }}
                  />
                </a>
              ))}
            </div>
          </ModalBody>
          <ModalFooter className="d-flex flex-row justify-content-start">
            <button
              className="btn btn-secondary"
              onClick={() => {
                meerdereRelatiesVoorPersoonSelectieParams!.resolver(null);
                setMeerdereRelatiesVoorPersoonSelectieParams(null);
              }}
              style={{ width: 100 }}
            >
              Annuleren
            </button>
          </ModalFooter>
        </Dialoog>
      )}
    </>
  );
};

export default GeadresseerdeInput;
