import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import { IOphalenTekstenInAlleTalenResult } from '../../../../../../../shared/src/api/v2/tekst';
import { IWebPagina } from '../../../../../../../shared/src/api/v2/website/pagina';
import ITaalTekst from '../../../../../../../shared/src/models/talen/ITaalTekst';
import api from '../../../../../api';
import LoadingSpinner from '../../../../../components/Gedeeld/LoadingSpinner';
import Dialoog from '../../../../../components/dialogen/Dialoog';
import MeertaligTekstveld from '../../../../../components/formulier/MeertaligTekstveld';
import { IInputComponentProps } from '../../../../../components/formulier/MeertaligTekstveld/TaalVeld';
import MultiSelect from '../../../../../components/formulier/MultiSelect';
import VinkVeld from '../../../../../components/formulier/VinkVeld';
import IDialoogProps from '../../../../../core/IDialoogProps';
import nameof from '../../../../../core/nameOf';
import IRemoteData, {
  ERemoteDataState,
  createPendingRemoteData,
  createReadyRemoteData,
} from '../../../../../models/IRemoteData';

interface IFormikValues {
  titel: ITaalTekst[];
  metadata: ITaalTekst[];
  descriptions: ITaalTekst[];
  keywords: ITaalTekst[];
  indexeren: boolean;
  follow: boolean;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  titel: 'Titel',
  descriptions: 'Omschrijvingen',
  metadata: 'Metadata',
  keywords: 'Trefwoorden',
  indexeren: 'Indexeren',
  follow: 'Follow',
};

export enum EDataType {
  Direct,
  ID,
}

export interface IWebPaginaData {
  type: EDataType.Direct;
  webPagina: IWebPagina;
}

export interface IWebPagIDData {
  type: EDataType.ID;
  webPagID: number;
}

export type Data = IWebPaginaData | IWebPagIDData;

interface IProps extends IDialoogProps<null> {
  data: Data;
}

const WijzigenDialoog = (props: IProps) => {
  const [webPagina, setWebPagina] = useState<IRemoteData<IWebPagina>>(createPendingRemoteData());
  const ophalenWebPagina = useCallback(async (id: number) => {
    const result = await api.v2.extern.pagina.ophalenWebPaginas({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: [id],
          },
        ],
      },
    });

    const webPagina = result.webPaginas[0];
    setWebPagina(createReadyRemoteData(webPagina));
  }, []);
  useEffect(() => {
    switch (props.data.type) {
      case EDataType.Direct:
        setWebPagina(createReadyRemoteData(props.data.webPagina));
        break;
      case EDataType.ID:
        // noinspection JSIgnoredPromiseFromCall
        ophalenWebPagina(props.data.webPagID);
        break;
    }
  }, [props.data, ophalenWebPagina]);

  const [teksten, setTeksten] = useState<IRemoteData<IOphalenTekstenInAlleTalenResult>>(
    createPendingRemoteData(),
  );
  const ophalenTeksten = useCallback(async () => {
    if (webPagina.state === ERemoteDataState.Pending) {
      return;
    }

    const tekstIDs = [
      webPagina.data?.Titel_TekstID ?? null,
      webPagina.data?.Metadata_TekstID ?? null,
      webPagina.data?.Description_TekstID ?? null,
      webPagina.data?.Keywords_TekstID ?? null,
    ].filter((id) => id !== null) as number[];
    if (tekstIDs.length === 0) {
      setTeksten(createReadyRemoteData({ teksten: [] }));
      return;
    }
    const result = await api.v2.tekst.ophalenTekstenInAlleTalen({
      tekstIDs,
    });
    setTeksten(createReadyRemoteData(result));
  }, [webPagina]);
  useEffect(() => {
    ophalenTeksten();
  }, [ophalenTeksten]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      await api.v2.extern.pagina.wijzigenWebPagina({
        ID: webPagina.data!.ID,
        titelTekstID: webPagina.data!.Titel_TekstID,
        titel: values.titel,
        descriptionTekstID: webPagina.data!.Description_TekstID,
        descriptions: values.descriptions,
        metadataTekstID: webPagina.data!.Metadata_TekstID,
        metadata: values.metadata,
        keywordsTekstID: webPagina.data!.Keywords_TekstID,
        keywords: values.keywords,
        follow: values.follow,
        indexeren: values.indexeren,
      });

      props.onSuccess(null);

      actions.setSubmitting(false);
    },
    [props.onSuccess, webPagina],
  );

  const initialValues = useMemo<IRemoteData<IFormikValues>>(() => {
    if (
      webPagina.state === ERemoteDataState.Pending ||
      teksten.state === ERemoteDataState.Pending
    ) {
      return createPendingRemoteData();
    }
    const w = webPagina.data!;

    return createReadyRemoteData({
      titel:
        w.Titel_TekstID === null
          ? []
          : teksten
              .data!.teksten.filter((x) => x.TekstID === w.Titel_TekstID)
              .map((x) => ({
                taalID: x.TaalID,
                tekst: x.Tekst ?? '',
                toepassen: x.Toepassen,
              })),
      descriptions:
        w.Description_TekstID === null
          ? []
          : teksten
              .data!.teksten.filter((x) => x.TekstID === w.Description_TekstID)
              .map((x) => ({
                taalID: x.TaalID,
                tekst: x.Tekst ?? '',
                toepassen: x.Toepassen,
              })),
      metadata:
        w.Metadata_TekstID === null
          ? []
          : teksten
              .data!.teksten.filter((x) => x.TekstID === w.Metadata_TekstID)
              .map((x) => ({
                taalID: x.TaalID,
                tekst: x.Tekst ?? '',
                toepassen: x.Toepassen,
              })),
      keywords:
        w.Keywords_TekstID === null
          ? []
          : teksten
              .data!.teksten.filter((x) => x.TekstID === w.Keywords_TekstID)
              .map((x) => ({
                taalID: x.TaalID,
                tekst: x.Tekst ?? '',
                toepassen: x.Toepassen,
              })),
      indexeren: w.Indexeren,
      follow: w.Follow,
    });
  }, [webPagina, teksten]);

  return (
    <Dialoog index={props.dialoogIndex ?? 0}>
      <ModalHeader>
        <ModalTitle>Wijzigen webpagina</ModalTitle>
      </ModalHeader>
      {initialValues.state === ERemoteDataState.Pending ? (
        <ModalBody>
          <LoadingSpinner />
        </ModalBody>
      ) : (
        <Formik<IFormikValues>
          onSubmit={handleSubmit}
          initialValues={initialValues.data!}
          render={(formikProps) => <Formulier {...props} formikProps={formikProps} />}
        />
      )}
    </Dialoog>
  );
};

const Formulier = (props: IProps & { formikProps: FormikProps<IFormikValues> }) => {
  const { isSubmitting, isValid, submitForm } = props.formikProps;

  return (
    <>
      <ModalBody>
        <div className="form-group">
          <div>
            <label>{veldnamen.titel}</label>
            <Field
              name={nameof<IFormikValues>('titel')}
              render={(fieldProps: FieldProps<IFormikValues>) => (
                <MeertaligTekstveld
                  waarden={fieldProps.field.value}
                  onChange={(x) => fieldProps.form.setFieldValue(fieldProps.field.name, x)}
                />
              )}
            />
          </div>

          <div className="mt-3">
            <label>{veldnamen.descriptions}</label>
            <Field
              name={nameof<IFormikValues>('descriptions')}
              render={(fieldProps: FieldProps<IFormikValues>) => (
                <MeertaligTekstveld
                  waarden={fieldProps.field.value}
                  onChange={(x) => fieldProps.form.setFieldValue(fieldProps.field.name, x)}
                  inputComponent="multiline"
                  multilineConfig={{
                    rows: 3,
                  }}
                />
              )}
            />
          </div>

          <div className="mt-3">
            <label>{veldnamen.metadata}</label>
            <Field
              name={nameof<IFormikValues>('metadata')}
              render={(fieldProps: FieldProps<IFormikValues>) => (
                <MeertaligTekstveld
                  waarden={fieldProps.field.value}
                  onChange={(x) => fieldProps.form.setFieldValue(fieldProps.field.name, x)}
                />
              )}
            />
          </div>

          <div className="mt-3">
            <label>{veldnamen.keywords}</label>
            <Field
              name={nameof<IFormikValues>('keywords')}
              render={(fieldProps: FieldProps<IFormikValues>) => (
                <MeertaligTekstveld
                  waarden={fieldProps.field.value}
                  onChange={(x) => fieldProps.form.setFieldValue(fieldProps.field.name, x)}
                  inputComponent={KeywordsInputComponent}
                />
              )}
            />
          </div>

          <div className="mt-3">
            <Field
              name={nameof<IFormikValues>('indexeren')}
              render={(fieldProps: FieldProps<IFormikValues>) => (
                <div className="d-flex align-items-center">
                  <VinkVeld
                    aangevinkt={fieldProps.field.value}
                    onGewijzigd={(x) => fieldProps.form.setFieldValue(fieldProps.field.name, x)}
                  />
                  <span className="ml-3">{veldnamen.indexeren}</span>
                </div>
              )}
            />
          </div>

          <div className="mt-3">
            <Field
              name={nameof<IFormikValues>('follow')}
              render={(fieldProps: FieldProps<IFormikValues>) => (
                <div className="d-flex align-items-center">
                  <VinkVeld
                    aangevinkt={fieldProps.field.value}
                    onGewijzigd={(x) => fieldProps.form.setFieldValue(fieldProps.field.name, x)}
                  />
                  <span className="ml-3">{veldnamen.follow}</span>
                </div>
              )}
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter className="d-flex flex-row justify-content-start">
        <button
          className="btn btn-primary d-flex align-items-center justify-content-center"
          onClick={submitForm}
          style={{ width: 100 }}
          disabled={isSubmitting || !isValid}
        >
          Ok
        </button>
        <button
          className="btn btn-secondary"
          onClick={props.onAnnuleren}
          style={{ width: 100 }}
          disabled={isSubmitting}
        >
          Annuleren
        </button>
      </ModalFooter>
    </>
  );
};

const KeywordsInputComponent = (props: IInputComponentProps) => {
  const value = useMemo(() => {
    const trimmedValue = props.value.trim();
    if (trimmedValue === '') {
      return [];
    }
    return trimmedValue
      .split(',')
      .map((x) => x.trim())
      .filter((x) => x !== '');
  }, [props.value]);
  const opties = useMemo(() => value.map((x) => ({ key: x, weergave: x })), [value]);
  const handleChange = useCallback(
    (keys: string[]) => {
      const str = keys.join(',');
      props.onChange(str);
    },
    [props.onChange],
  );
  const handleToegevoegd = useCallback(
    (str: string) => {
      const start = props.value.length === 0 ? '' : props.value + ',';
      props.onChange(start + str);
    },
    [props.onChange],
  );

  return (
    <div style={{ paddingLeft: 35, minHeight: 33.5 }} className="d-flex align-items-center">
      <div className="flex-fill w-100">
        <MultiSelect
          value={value}
          onChange={handleChange}
          opties={opties}
          isVerwijderbaar
          onToegevoegd={handleToegevoegd}
          placeholder="Voeg trefwoorden toe"
        />
      </div>
    </div>
  );
};

export default WijzigenDialoog;
