import React, { useCallback, useEffect, useMemo, useState } from 'react';
import MenuLayout from '../../../components/MenuLayout';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../models/IRemoteData';
import {
  IOphalenPotentieleInkoopfactuurBestandenResult,
  IOphalenPotentieleInkoopfactuurBestandGroepenResult,
  IOphalenPotentieleInkoopfactuurBestandGroepenResultElement,
} from '../../../../../shared/src/api/v2/inkoopfactuur';
import api from '../../../api';
import { AutoSizer } from 'react-virtualized';
import {
  DXTableToggleCellComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../helpers/dxTableGrid';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  TableRowDetail,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import LoadingSpinner from '../../../components/Gedeeld/LoadingSpinner';
import { DataTypeProvider, RowDetailState } from '@devexpress/dx-react-grid';
import RowDetail from './RowDetail';
import nameof from '../../../core/nameOf';
import RelatieVisualisatie from '../../../components/personalia/RelatieVisualisatie';
import { format } from 'date-fns';
import EmailBerichtVisualisatie from '../../../components/entiteitVisualisaties/EmailBerichtVisualisatie';

export interface INogTeVerwerkenInkoopfacturenContext {
  potentieleInkoopfactuurBestandGroepenResult: IOphalenPotentieleInkoopfactuurBestandGroepenResult;
  potentieleInkoopfactuurBestandenResult: IOphalenPotentieleInkoopfactuurBestandenResult;
  onVerversenAangevraagd: () => Promise<void>;
}
export const NogTeVerwerkenInkoopfacturenContext = React.createContext<
  INogTeVerwerkenInkoopfacturenContext
>(null as any);

interface IProps {}

const NogTeVerwerkenInkoopfacturen: React.FC<IProps> = (props) => {
  const [
    potentieleInkoopfactuurBestandGroepenResult,
    setPotentieleInkoopfactuurBestandGroepenResult,
  ] = useState<IRemoteData<IOphalenPotentieleInkoopfactuurBestandGroepenResult>>(
    createPendingRemoteData(),
  );
  const ophalenPotentieleInkoopfactuurBestandGroepen = useCallback(async () => {
    const result = await api.v2.inkoopfactuur.ophalenPotentieleInkoopfactuurBestandGroepen({});
    setPotentieleInkoopfactuurBestandGroepenResult(createReadyRemoteData(result));
  }, []);
  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    ophalenPotentieleInkoopfactuurBestandGroepen();
  }, [ophalenPotentieleInkoopfactuurBestandGroepen]);

  const [
    potentieleInkoopfactuurBestandenResult,
    setPotentieleInkoopfactuurBestandenResult,
  ] = useState<IRemoteData<IOphalenPotentieleInkoopfactuurBestandenResult>>(
    createPendingRemoteData(),
  );
  const ophalenPotentieleInkoopfactuurBestanden = useCallback(async () => {
    const result = await api.v2.inkoopfactuur.ophalenPotentieleInkoopfactuurBestanden({});
    setPotentieleInkoopfactuurBestandenResult(createReadyRemoteData(result));
  }, []);
  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    ophalenPotentieleInkoopfactuurBestanden();
  }, [ophalenPotentieleInkoopfactuurBestanden]);

  const keyExtractor = useCallback(
    (gegroepeerdElement: IOphalenPotentieleInkoopfactuurBestandGroepenResultElement) =>
      gegroepeerdElement.ID,
    [],
  );
  const kolommen = useMemo<
    TypedColumn<IOphalenPotentieleInkoopfactuurBestandGroepenResultElement>[]
  >(
    () => [
      {
        name: '__communicatiekanaal' as any,
        title: 'Communicatiekanaal',
      },
      {
        name: 'RelID',
        title: 'Relatie',
      },
      {
        name: 'RecordToegevoegd',
        title: 'Datum',
      },
    ],
    [],
  );
  const kolomBreedtes = useMemo<
    TypedTableColumnWidthInfo<IOphalenPotentieleInkoopfactuurBestandGroepenResultElement>[]
  >(
    () => [
      {
        columnName: '__communicatiekanaal' as any,
        width: 150,
      },
      {
        columnName: 'RelID',
        width: 250,
      },
      {
        columnName: 'RecordToegevoegd',
        width: 150,
      },
    ],
    [],
  );

  return (
    <MenuLayout
      menu={<div />}
      body={
        <div className="flex-fill d-flex flex-column">
          <AutoSizer className="flex-column d-flex flex-fill w-100">
            {({ height }) => {
              if (
                potentieleInkoopfactuurBestandGroepenResult.state === ERemoteDataState.Pending ||
                potentieleInkoopfactuurBestandenResult.state === ERemoteDataState.Pending
              ) {
                return (
                  <div className="flex-fill d-flex align-items-center justify-content-center">
                    <LoadingSpinner />
                  </div>
                );
              }

              const contextValue: INogTeVerwerkenInkoopfacturenContext = {
                potentieleInkoopfactuurBestandenResult: potentieleInkoopfactuurBestandenResult.data!,
                potentieleInkoopfactuurBestandGroepenResult: potentieleInkoopfactuurBestandGroepenResult.data!,
                onVerversenAangevraagd: async () => {
                  await ophalenPotentieleInkoopfactuurBestandGroepen();
                  await ophalenPotentieleInkoopfactuurBestanden();
                },
              };

              return (
                <NogTeVerwerkenInkoopfacturenContext.Provider value={contextValue}>
                  <GridStyleWrapper height={height}>
                    <Grid
                      rows={potentieleInkoopfactuurBestandGroepenResult.data!.elementen}
                      columns={kolommen}
                      getRowId={keyExtractor}
                    >
                      <DataTypeProvider
                        for={['__communicatiekanaal']}
                        formatterComponent={(formatterProps) => {
                          const row: IOphalenPotentieleInkoopfactuurBestandGroepenResultElement =
                            formatterProps.row;

                          if (row.EmailBerID !== null) {
                            return <EmailBerichtVisualisatie emailBerID={row.EmailBerID} />;
                            // return (
                            //   <a
                            //     href="#"
                            //     onClick={(ev) => {
                            //       ev.preventDefault();
                            //     }}
                            //   >
                            //     Email
                            //   </a>
                            // );
                          }
                          throw new Error('Communicatiekanaal kon niet worden bepaald');
                        }}
                      />

                      <DataTypeProvider
                        for={[
                          nameof<IOphalenPotentieleInkoopfactuurBestandGroepenResultElement>(
                            'RelID',
                          ),
                        ]}
                        formatterComponent={(formatterProps) => {
                          const row: IOphalenPotentieleInkoopfactuurBestandGroepenResultElement =
                            formatterProps.row;

                          if (row.RelID === null) {
                            return <span />;
                          }

                          return <RelatieVisualisatie relID={row.RelID} />;
                        }}
                      />

                      <DataTypeProvider
                        for={[
                          nameof<IOphalenPotentieleInkoopfactuurBestandGroepenResultElement>(
                            'RecordToegevoegd',
                          ),
                        ]}
                        formatterComponent={(formatterProps) => {
                          const row: IOphalenPotentieleInkoopfactuurBestandGroepenResultElement =
                            formatterProps.row;

                          const date = new Date(row.RecordToegevoegd);
                          return <span>{format(date, 'dd-MM-yyyy HH:mm:ss')}</span>;
                        }}
                      />

                      <RowDetailState defaultExpandedRowIds={[]} />

                      <VirtualTable />
                      <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
                      <TableHeaderRow />

                      <TableRowDetail
                        contentComponent={RowDetail}
                        toggleCellComponent={DXTableToggleCellComponent}
                      />
                    </Grid>
                  </GridStyleWrapper>
                </NogTeVerwerkenInkoopfacturenContext.Provider>
              );
            }}
          </AutoSizer>
        </div>
      }
    />
  );
};

export default NogTeVerwerkenInkoopfacturen;
