import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import IDialoogProps from '../../../../../core/IDialoogProps';
import Dialoog from '../../../../../components/dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalFooter from 'react-bootstrap/ModalFooter';
import ModalBody from 'react-bootstrap/ModalBody';
import Tabblad, { ITabblad } from '../../../../../components/layout/Tabblad';
import api from '../../../../../api';
import LoadingSpinner from '../../../../../components/Gedeeld/LoadingSpinner';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../../../stores/RootStore';
import MultiCombobox, { IKolom } from '../../../../../components/formulier/MultiCombobox';
import { Kleur as EKleur } from '../../../../../bedrijfslogica/constanten';
import VinkVeld from '../../../../../components/formulier/VinkVeld';
import Nieuw from '../KoppelenProductDialoog/VoorraadTabblad/NieuwTab';
import Gebruikt from '../KoppelenProductDialoog/VoorraadTabblad/GebruiktTab';
import Bulk from '../KoppelenProductDialoog/VoorraadTabblad/BulkTab';
import { IOphalenContractenResultElementV2 } from '../../../../../../../shared/src/api/v2/contract';
import { IOphalenMagazijnenResultElement } from '../../../../../../../shared/src/api/v2/magazijn';
import { IOphalenProductsoortenResultElement } from '../../../../../../../shared/src/api/v2/product/soort';
import { IOphalenProductmodellenResultElement } from '../../../../../../../shared/src/api/v2/aanbod/productmodel';
import { IOphalenVerkoopmodellenResultElement } from '../../../../../../../shared/src/api/v2/aanbod/verkoop';
import { Field, FieldProps } from 'formik';
import RadioKnop from '../../../../../components/formulier/RadioKnop';
import { IFilterSchemaFilter } from '../../../../../../../shared/src/models/filter';

export enum ETabblad {
  Ingepakt = 1,
  Uitgepakt = 2,
  Bulk = 3,
}

export interface ITabbladProps {
  productmodel: IOphalenProductmodellenResultElement | null;
  magazijnen: IOphalenMagazijnenResultElement[];
  productsoorten: IOphalenProductsoortenResultElement[];
  magID: number;
  onMagIDChange: (value: number) => void;
  onProdSrtIDChange: (value: number) => void;
  prodID: number | null;
  onProdIDChange: (value: number | null) => void;
  typeID: number | null;
  onTypeIDChange: (value: number | null) => void;
  verkModID: number | null;
  onVerkModIDChange: (value: number | null) => void;
  alleenVoorMerk: boolean;
  metVrijeVoorraad: boolean;
  prodModID: number | null;
  bulkproducten: IOphalenVerkoopmodellenResultElement[] | null;
  productmodelSelectie: boolean;
  prodSrtID: number | null;
  zoekterm: string;
}

export interface IData {
  tabblad: ETabblad;
  typeID: number | null;
  bulkTypeID: number | null;
  prodID: number | null;
}

interface IProps extends IDialoogProps<IData> {
  cntID?: number;
  prodModID?: number;
  tabblad?: ETabblad;
}

/* Selecteren van een nieuw of gebruikt product in voorraad om te koppelen aan een leveropdracht */

const KoppelVoorraadDialoog: React.FC<IProps> = observer((props) => {
  const { instellingStore } = useContext(RootStoreContext);
  const { children, dialoogIndex, onAnnuleren, onSuccess, open } = props;

  const [magID, setMagID] = useState(instellingStore.MagID!);
  const [prodModID, setProdModID] = useState<number | null>(null);
  const [productmodel, setProductmodel] = useState<IOphalenProductmodellenResultElement | null>(
    null,
  );
  // const [contract, setContract] = useState<IOphalenContractenResultElement | null>(null);

  const [magazijnen, setMagazijnen] = useState<IOphalenMagazijnenResultElement[] | null>(null);
  const [bulkproducten, setBulkproducten] = useState<IOphalenVerkoopmodellenResultElement[] | null>(
    null,
  );

  const [productmodellen, setProductmodellen] = useState<
    IOphalenProductmodellenResultElement[] | null
  >(null);
  const [productsoorten, setProductsoorten] = useState<
    IOphalenProductsoortenResultElement[] | null
  >(null);
  const [prodSrtID, setProdSrtID] = useState<number | null>(null);
  const [typeID, setTypeID] = useState<number | null>(null);
  const [bulkTypeID, setBulkTypeID] = useState<number | null>(null);
  const [verkModID, setVerkModID] = useState<number | null>(null);

  const [prodID, setProdID] = useState<number | null>(null);
  const [alleenVoorMerk, setAlleenVoorMerk] = useState<boolean>(false);
  const [alleenMetVrijeVoorraad, setAlleenMetVrijeVoorraad] = useState<boolean>(true);
  const [productmodelSelectie, setProductmodelSelectie] = useState<boolean>(true);
  const [contract, setContract] = useState<IOphalenContractenResultElementV2 | null | undefined>(
    undefined,
  );
  const [zoekterm, setZoekterm] = useState<string>('');

  useEffect(() => {
    (async () => {
      if (props.cntID === undefined) {
        setContract(null);
        return;
      }

      const contract = (
        await api.v2.contract.ophalenContractenV2({
          filterSchema: { filters: [{ naam: 'IDS', data: [props.cntID] }] },
        })
      ).contracten[0];

      setContract(contract);
    })();
  }, [props.cntID]);

  // Rijbron bulkproducten
  useEffect(() => {
    (async () => {
      const result = (
        await api.v2.aanbod.verkoop.ophalenVerkoopmodellen({
          filterSchema: {
            filters: [
              {
                naam: 'ACTIEF',
                data: true,
              },
              zoekterm !== null
                ? {
                    naam: 'ZOEKTERM',
                    data: zoekterm,
                  }
                : null,
            ].filter((x) => x !== null) as IFilterSchemaFilter[],
          },
        })
      ).verkoopmodellen;

      setBulkproducten(result);
    })();
  }, [zoekterm]);

  // Rijbron productmodellen
  useEffect(() => {
    (async () => {
      if (contract === undefined) {
        return;
      }
      const productmodellen = (
        await api.v2.product.model.ophalenProductmodellen({
          filterSchema: {
            uitgebreideFilter: {
              or: [
                {
                  filter: {
                    naam: 'ACTIEF',
                    data: true,
                  },
                },
                {
                  filter: {
                    naam: 'IDS',
                    data: contract !== null ? [contract.basis.productmodel.ProdModID] : [],
                  },
                },
              ],
            },
          },
        })
      ).modellen;

      setProductmodellen(productmodellen);
    })();
  }, [contract]);

  // Initiele waarde voor het productmodel bepalen
  useEffect(() => {
    (async () => {
      if (productmodellen === null) {
        return;
      }

      // Via ProdModID
      if (props.prodModID !== undefined) {
        const productmodel = productmodellen.find((x) => x.ProdModID === props.prodModID)!;
        setProductmodel(productmodel);
        setProdModID(productmodel.ProdModID);
        return;
      }

      // Via CntID
      if (props.cntID !== undefined) {
        const contractenResult = (
          await api.v2.contract.ophalenContractenV2({
            filterSchema: { filters: [{ naam: 'IDS', data: [props.cntID] }] },
          })
        ).contracten;
        const contract = contractenResult[0];

        const productmodel = productmodellen.find(
          (x) => x.ProdModID === contract.basis.productmodel.ProdModID,
        )!;

        if (productmodel !== undefined) {
          setProductmodel(productmodel);
          setProdModID(productmodel.ProdModID);
          return;
        }

        setProductmodel(null);
        setProdModID(null);
        return;
      }

      // Anders de 1e uit de rijbron nemen
      const productmodel = productmodellen[0];
      setProductmodel(productmodel);
      setProdModID(productmodel.ProdModID);

      // setAlleenVoorMerk(productmodel.Huismodel ? false : true);
      setAlleenVoorMerk(true);
    })();
  }, [props.prodModID, props.cntID, productmodellen]);

  useEffect(() => {
    (async () => {
      const [magazijnenResult, productsoortenResult] = await Promise.all([
        api.v2.magazijn.ophalenMagazijnen({
          filterSchema: { filters: [{ naam: 'IS_VOORRAAD', data: true }] },
        }),
        api.v2.product.soort.ophalenProductsoorten({ filterSchema: { filters: [] } }),
      ]);

      setMagazijnen(magazijnenResult.magazijnen);
      setProductsoorten(productsoortenResult);
    })();
  }, []);

  useEffect(() => {
    if (productsoorten === null || prodSrtID !== null) {
      return;
    }

    setProdSrtID(productsoorten[0].ProdSrtID);
  }, [productsoorten, prodSrtID]);

  const productmodellenKolommen = useMemo<IKolom<IOphalenProductmodellenResultElement>[]>(
    () => [
      {
        key: 'Modelcode',
        label: 'Modelcode',
        breedte: 125,
      },
      {
        key: 'Modelnaam',
        label: 'Naam',
        breedte: 200,
      },
      {
        key: 'Kenmerk',
        label: 'Kenmerk',
        breedte: 200,
      },
    ],
    [],
  );

  const productsoortenKolommen = useMemo<IKolom<IOphalenProductsoortenResultElement>[]>(
    () => [
      {
        key: 'Naam',
        label: 'Naam',
        breedte: 125,
      },
    ],
    [],
  );

  const tabbladen = useMemo<ITabblad<ETabblad>[]>(
    () => [
      {
        id: ETabblad.Ingepakt,
        label: 'Ingepakt',
        content: Nieuw,
      },
      {
        id: ETabblad.Uitgepakt,
        label: 'Uitgepakt',
        content: Gebruikt,
      },
      {
        id: ETabblad.Bulk,
        label: 'Bulk',
        content: Bulk,
      },
    ],
    [],
  );

  const [tabblad, setTabblad] = useState<ETabblad>(props.tabblad ?? ETabblad.Ingepakt);

  const handleOkClick = useCallback(async () => {
    if (bulkproducten === null) {
      return;
    }

    // Zet VerkModID om in een TypeID
    const bulkTypeID =
      tabblad === ETabblad.Bulk
        ? bulkproducten.find((x) => x.VerkModID === verkModID)!.TypeID
        : null;

    const data = {
      tabblad,
      typeID,
      bulkTypeID,
      prodID,
    };
    onSuccess(data);
  }, [onSuccess, prodID, tabblad, typeID, verkModID]);

  return (
    <Dialoog
      index={dialoogIndex || 0}
      modalProps={{
        size: 'lg',
      }}
    >
      {magazijnen === null || bulkproducten === null || contract === undefined ? (
        <LoadingSpinner />
      ) : (
        <>
          <ModalHeader>
            <ModalTitle>Koppel voorraadproduct</ModalTitle>
          </ModalHeader>
          <ModalBody className="p-0">
            <div className="row mt-2 ml-3 mb-3 mr-3">
              <div className="col-12 d-flex mt-3">
                <div className="d-flex align-items-center">
                  <RadioKnop
                    aangevinkt={productmodelSelectie}
                    onAangevinkt={() => setProductmodelSelectie(true)}
                  />
                  <span className="ml-2">Model</span>
                  <div className="ml-2">
                    <MultiCombobox<number, IOphalenProductmodellenResultElement>
                      sleutelExtractor={(x) => x.ProdModID}
                      waarde={prodModID}
                      onWaardeChange={(value: number | null) => {
                        if (value !== null) {
                          const productmodel = productmodellen!.find((x) => x.ProdModID === value)!;
                          setProductmodel(productmodel);
                        }
                        setProdModID(value);
                      }}
                      representatieFabriek={(entiteit) => {
                        return entiteit.Actief ? (
                          <span>{entiteit.Modelcode}</span>
                        ) : (
                          <span style={{ color: EKleur.Rood }}>
                            {entiteit.Modelcode} (niet actief)
                          </span>
                        );
                      }}
                      opties={productmodellen}
                      kolommen={productmodellenKolommen}
                    />
                  </div>
                </div>

                <div className="d-flex align-items-center ml-3">
                  <RadioKnop
                    aangevinkt={!productmodelSelectie}
                    onAangevinkt={() => setProductmodelSelectie(false)}
                  />
                  <span className="ml-2">Soort</span>
                  <div className="ml-2">
                    <MultiCombobox<number, IOphalenProductsoortenResultElement>
                      sleutelExtractor={(x) => x.ProdSrtID}
                      waarde={prodSrtID}
                      onWaardeChange={(value: number | null) => {
                        // if (value !== null) {
                        //   const productsoort = productsoorten!.find((x) => x.ProdSrtID === value)!;
                        //   setProductsoort(productsoort);
                        // }
                        setProdSrtID(value);
                      }}
                      representatieFabriek={(entiteit) => entiteit.Naam}
                      opties={productsoorten}
                      kolommen={productsoortenKolommen}
                    />
                  </div>
                </div>
                <div className="col-4 d-flex align-items-center">
                  <span className="ml-1">Zoekterm</span>
                  <input
                    type="text"
                    className="form-control ml-2"
                    value={zoekterm}
                    onChange={(ev) => {
                      return setZoekterm(ev.target.value);
                    }}
                    placeholder="Deel type, kenmerk of ref.nr"
                  />
                </div>
              </div>

              <div className="row col-12 d-flex mt-3">
                {/* <div className="col-3">
                  <label>Productmodel</label>
                  <MultiCombobox<number, IOphalenProductmodellenResultElement>
                    sleutelExtractor={(x) => x.ProdModID}
                    waarde={prodModID}
                    onWaardeChange={(value: number | null) => {
                      if (value !== null) {
                        const productmodel = productmodellen!.find((x) => x.ProdModID === value)!;
                        setProductmodel(productmodel);
                      }
                      setProdModID(value);
                    }}
                    representatieFabriek={(entiteit) => entiteit.Modelcode}
                    opties={productmodellen}
                    kolommen={productmodellenKolommen}
                  />
                </div> */}

                <div className="col-3">
                  <label>Magazijn</label>
                  <MultiCombobox<number, IOphalenMagazijnenResultElement>
                    sleutelExtractor={(x) => x.MagID}
                    representatieFabriek={(x) => x.NaamKort}
                    waarde={magID}
                    onWaardeChange={(x) => setMagID(x!)}
                    opties={magazijnen}
                    kolommen={[
                      {
                        key: 'NaamKort',
                        label: 'Naam',
                        breedte: 125,
                      },
                      // {
                      //   key: 'PlaatsNaam',
                      //   label: 'Plaats',
                      //   breedte: 200,
                      // },
                    ]}
                  />
                </div>

                <div className="col-3 d-flex align-items-center mt-3">
                  <VinkVeld
                    // disabled={productmodel === null || productmodel.Huismodel}
                    disabled={productmodel === null}
                    aangevinkt={alleenMetVrijeVoorraad}
                    onGewijzigd={(x) => {
                      setAlleenMetVrijeVoorraad(x);
                    }}
                  />
                  <span className="ml-1">Met vrije voorraad</span>
                </div>

                <div className="col-3 d-flex align-items-center mt-3">
                  <VinkVeld
                    disabled={productmodel === null || productmodel.Huismodel}
                    aangevinkt={alleenVoorMerk}
                    onGewijzigd={(x) => {
                      setAlleenVoorMerk(x);
                    }}
                  />
                  <span className="ml-1">Alleen voor merk</span>
                </div>
              </div>
            </div>

            <Tabblad<ETabblad, ITabbladProps>
              tabbladen={tabbladen}
              geselecteerd={tabblad}
              onSelectieChange={(x) => setTabblad(x)}
              options={{
                tabbladComponentProps: {
                  bulkproducten,
                  productmodel,
                  productsoorten: productsoorten !== null ? productsoorten : [],
                  magazijnen,
                  magID,
                  onMagIDChange: (x) => setMagID(x),
                  onProdSrtIDChange: (x) => setProdSrtID(x),
                  onProdIDChange: (x) => setProdID(x),
                  onTypeIDChange: (x) => setTypeID(x),
                  onVerkModIDChange: (x) => setVerkModID(x),
                  prodID,
                  typeID,
                  verkModID,
                  alleenVoorMerk,
                  metVrijeVoorraad: alleenMetVrijeVoorraad,
                  prodModID,
                  productmodelSelectie,
                  prodSrtID,
                  zoekterm,
                },
              }}
            />
          </ModalBody>
        </>
      )}

      <ModalFooter className="d-flex flex-row justify-content-start">
        <button
          className="btn btn-primary"
          onClick={() => handleOkClick()}
          style={{ width: 100 }}
          disabled={typeID === null && prodID === null && verkModID === null}
        >
          Ok
        </button>
        <button className="btn btn-secondary" onClick={onAnnuleren} style={{ width: 100 }}>
          Annuleren
        </button>
      </ModalFooter>
    </Dialoog>
  );
});

export default KoppelVoorraadDialoog;
