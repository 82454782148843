import React, { useContext, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../stores/RootStore';
import { Root } from './style';
import { DefaultChatUserIcon } from '../../components/Icons';
import Combobox from '../../components/formulier/Combobox';
import { IOphalenBedrijfslocatiesResult } from '../../../../shared/src/api/v2/bedrijf';
import api from '../../api';
import LoadingSpinner from '../../components/Gedeeld/LoadingSpinner';

interface IProps {}

const ProfielOverlay: React.FC<IProps> = observer((props) => {
  const { profielStore, gebruikerStore } = useContext(RootStoreContext);

  const [locaties, setLocaties] = useState<IOphalenBedrijfslocatiesResult | null>(null);

  useEffect(() => {
    if (gebruikerStore.gebruiker === null) {
      return;
    }
    (async () => {
      setLocaties(await api.v2.bedrijf.ophalenBedrijfslocaties({}));
    })();
  }, [gebruikerStore.gebruiker]);

  const containerClassnames = useMemo(
    () =>
      classNames([
        'container',
        profielStore.overlayState.zichtbaar && 'container-zichtbaar',
        'd-flex flex-fill flex-column',
      ]),
    [profielStore.overlayState],
  );

  if (gebruikerStore.gebruiker === null) {
    return null;
  }

  return (
    <Root onClick={(ev) => ev.stopPropagation()}>
      <div className={containerClassnames}>
        <div className="d-flex justify-content-center mt-5">
          <DefaultChatUserIcon height={100} width={100} />
        </div>
        <div className="d-flex justify-content-center mt-2">
          <span style={{ fontSize: '1.5rem' }}>{gebruikerStore.gebruiker!.GebruikersNaam}</span>
        </div>
        <div className="mt-3">
          <div className="form-group">
            <label>Locatie:</label>
            {locaties === null ? (
              <LoadingSpinner />
            ) : (
              <Combobox
                geselecteerd={gebruikerStore.gebruiker.BedrLocID}
                isWisbaar
                onSelectieChange={async (locID) => {
                  await api.v2.bedrijf.wijzigenLocatieVoorAspGebr({
                    aspGebrID: gebruikerStore.gebruiker!.AspGebrID,
                    bedrLocID: locID,
                  });
                }}
                opties={locaties.locaties.map((locatie) => {
                  return {
                    id: locatie.ID,
                    label: locatie.Naam,
                  };
                })}
              />
            )}
          </div>
        </div>
      </div>
    </Root>
  );
});

export default ProfielOverlay;
