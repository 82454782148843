import React, { useCallback, useContext, useEffect, useState } from 'react';
import MenuLayout from '../../../../../../../components/MenuLayout';
import AanmaningenTabel from '../../../../../../../components/debiteuren/AanmaningenTabel';
import { IOphalenAanmaningenResultElement } from '../../../../../../../../../shared/src/api/v2/aanmaning';

import useUrlState from '../../../../../../../core/useUrlState';
import api from '../../../../../../../api';
import { observer } from 'mobx-react-lite';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { RootStoreContext } from '../../../../../../../stores/RootStore';
import _ from 'lodash';

export interface IUrlState {
  selectie: number[];
  // wijzigenBetalingsregelingDialoogState: IWijzigenBetalingsregelingDialoogState | null;
}

export const defaultUrlState: IUrlState = {
  selectie: [],
  // wijzigenBetalingsregelingDialoogState: null,
};

interface IProps extends RouteComponentProps {}

const Aanmaningen: React.FC<IProps> = observer((props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const { klantkaartStore } = useContext(RootStoreContext);

  const [aanmaningen, setAanmaningen] = useState<IOphalenAanmaningenResultElement[] | null>(null);

  const ophalenAanmaningen = useCallback(async () => {
    if (klantkaartStore.relatie === null) {
      setAanmaningen(null);
      return;
    }

    const aanmaningenResult = await api.v2.aanmaning.ophalenAanmaningen({
      filterSchema: {
        filters: [
          {
            naam: 'REL_IDS',
            data: [klantkaartStore.relatie.RelID],
          },
        ],
      },
    });
    const aanmaningenGesorteerd = _.orderBy(
      aanmaningenResult.aanmaningen,
      ['Aanmaningdatum'],
      ['desc'],
    );

    setAanmaningen(aanmaningenGesorteerd);
  }, [klantkaartStore.relatie === null ? null : klantkaartStore.relatie.RelID]);

  useEffect(() => {
    ophalenAanmaningen();
  }, [ophalenAanmaningen]);

  return (
    <MenuLayout
      // menu={<div className="d-flex">TODO</div>}
      body={
        <div>
          <AanmaningenTabel
            aanmaningen={aanmaningen}
            selectie={urlState.selectie}
            onSelectieChange={(x) => setUrlStateSync('selectie', x)}
            onVerversenAangevraagd={async () => await ophalenAanmaningen()}
            relID={klantkaartStore.relatie!.RelID}
          />
        </div>
      }
    />
  );
});

export default withRouter(Aanmaningen);
