import React, { useMemo, useEffect, useState, useContext } from 'react';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import { IOphalenRelatiesResultElementV2 } from '../../../../../../shared/src/api/v2/relatie';
import RelatieVisualisatie from '../../../personalia/RelatieVisualisatie';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

interface IProps extends RouteComponentProps {
  relatie: IOphalenRelatiesResultElementV2;
}

const TopInfo: React.FC<IProps> = observer((props) => {
  const { relatie } = props;

  return (
    <>
      <div
        style={{
          backgroundColor: Kleur.Geel,
          color: Kleur.BeetjeDonkerGrijs,
        }}
        className="d-flex flex-column p-2 pl-3 pr-4"
      >
        {relatie.Geblokkeerd && <span>RELATIE IS GEBLOKKEERD</span>}
        {relatie.Vervangende_RelID && (
          <span>
            <div>NIET GEBRUIKEN - Deze relatie is vervangen door:</div>
            <a
              href="#"
              onClick={(ev) => {
                props.history.push(`/klant/${relatie.Vervangende_RelID!}/klant`);
              }}
            >
              <RelatieVisualisatie relID={relatie.Vervangende_RelID!} />
            </a>
          </span>
        )}
        {relatie.NietGebruiken && relatie.Vervangende_RelID === null && (
          <span>DEZE RELATIE NIET GEBRUIKEN</span>
        )}

        {relatie.Vertegenwoordiger_RelID !== null && (
          <span className="d-flex">
            <span>Vertegenwoordigd door&nbsp;</span>
            <span>
              <RelatieVisualisatie relID={relatie.Vertegenwoordiger_RelID!} />
            </span>
          </span>
        )}
      </div>
    </>
  );
});

export default withRouter(TopInfo);
