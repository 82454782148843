import {
  IOphalenAfdelingenParams,
  IOphalenAfdelingenResult,
  IToevoegenAfdelingParams,
  IToevoegenAfdelingResult,
  IWijzigenAfdelingParams,
  IWijzigenAfdelingResult,
  IVerwijderenAfdelingenParams,
  IVerwijderenAfdelingenResult,
} from '../../../../../shared/src/api/v2/relatie/afdeling';
import ICheckData from '../../../../../shared/src/models/ICheckData';
import api from '../../index';

const afdeling = {
  ophalenAfdelingen: async (
    params: IOphalenAfdelingenParams,
  ): Promise<IOphalenAfdelingenResult> => {
    return await api.post('v2/relatie/afdeling/ophalen-afdelingen', params);
  },
  toevoegenAfdeling: async (
    params: IToevoegenAfdelingParams,
  ): Promise<IToevoegenAfdelingResult> => {
    return await api.post('v2/relatie/afdeling/toevoegen-afdeling', params);
  },
  wijzigenAfdeling: async (params: IWijzigenAfdelingParams): Promise<IWijzigenAfdelingResult> => {
    return await api.post('v2/relatie/afdeling/wijzigen-afdeling', params);
  },
  checkVerwijderenAfdelingen: async (params: IVerwijderenAfdelingenParams): Promise<ICheckData> => {
    return await api.post('v2/relatie/afdeling/check-verwijderen-afdelingen', params);
  },
  verwijderenAfdelingen: async (
    params: IVerwijderenAfdelingenParams,
  ): Promise<IVerwijderenAfdelingenResult> => {
    return await api.post('v2/relatie/afdeling/verwijderen-afdelingen', params);
  },
};

export default afdeling;
