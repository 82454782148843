import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Root } from './styles';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../stores/RootStore';
import LoadingSpinner from '../../components/Gedeeld/LoadingSpinner';
import useUrlState, { getUrlStateFromURLSearchParams } from '../../core/useUrlState';
import {
  IControltaakType,
  IOphalenControltaakToewijzingenResult,
  IOphalenControltaakTypesResult,
  IOphalenControlTakenResultElement,
  IOphalenControlTakenToewijzingFilterData,
} from '../../../../shared/src/api/v2/control/control';
import {
  DXTableCheckboxComponent,
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
  DXTableToggleCellComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../helpers/dxTableGrid';
import {
  Grid,
  VirtualTable,
  TableHeaderRow,
  TableColumnResizing,
  TableRowDetail,
  TableEditColumn,
  TableSelection,
} from '@devexpress/dx-react-grid-bootstrap4';
import MenuLayout from '../../components/MenuLayout';
import {
  DataTypeProvider,
  EditingState,
  IntegratedSorting,
  RowDetailState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import nameOf from '../../core/nameOf';
import RelatieVisualisatie from '../../components/personalia/RelatieVisualisatie';
import { Kleur as EKleur } from '../../bedrijfslogica/constanten';
import {
  EFunctioneleIcon,
  functioneleIconMap,
  IconCancel,
  IconContactEmail,
  IconContactTelefoon,
  IconControl,
  IconEvent,
  IconInformatie,
  IconKeyboard,
  IconKlok,
  IconKruis,
  IconPhoneMissedIcon,
  IconRelatieRegistratie,
  IconRepeat,
  IconService,
  IconToevoegen,
  IconUitBedrijf,
  IconUpdate,
  IconWhatsapp,
  IconWijzigen,
} from '../../components/Icons';
import { format } from 'date-fns';
import BezigheidKnop from '../../components/BezigheidKnop';
import api from '../../api';
import { EResultType } from '../../stores/CheckStore';
import CONTACTVERZOEK from './acties/CONTACTVERZOEK';
import SERVICE_MELDING from './acties/SERVICE_MELDING';
import { useRealtimeListener } from '../../one-off-components/realtime/RealtimeIntegratie';
import FilterBalkV2, {
  IFilterData,
  IFilter,
  maakFilterSchema,
} from '../../components/FilterBalkV2';
import { IFilterSchema } from '../../../../shared/src/models/filter';
import Combobox from '../../components/formulier/Combobox';
import ASPGebruikerVisualisatie from '../../components/ASPGebruiker/ASPGebruikerVisualisatie';
import Chip from '../../components/Chip';
import UitlegTooltip from '../../components/formulier/UitlegTooltip';
import ASPGebruikerSelectieDialoog from '../../components/dialogen/ASPGebruikerSelectieDialoog';
import HandmatigControltaakToevoegenDialoog from '../../components/control/HandmatigControltaakToevoegenDialoog';
import OpvolgdatumWeergave from './OpvolgdatumWeergave';
import ControltaakWijzigenDialoog from '../../components/control/ControltaakWijzigenDialoog';
import _ from 'lodash';
import { values } from 'lodash';
import { nl } from 'date-fns/locale';
import POTENTIEEL_ONTVANGEN_INKOOPFACTUUR from './acties/POTENTIEEL_ONTVANGEN_INKOOPFACTUUR';
import INKOOPLEVERING_TE_LAAT from './acties/INKOOPLEVERING_TE_LAAT';
import TRANSPORT_BEZOEKTIJDEN_OPGEHAALD from './acties/TRANSPORT_BEZOEKTIJDEN_OPGEHAALD';
import BESTELLING_ONVERWERKT from './acties/BESTELLING_ONVERWERKT';
import BESTELLING_GEBLOKKEERD from './acties/BESTELLING_GEBLOKKEERD';
import SIGNALERINGEN_VERSCHIL_MET_VORIGE_KEER from './acties/SIGNALERINGEN_VERSCHIL_MET_VORIGE_KEER';
import AANBIEDEN_CONTRACT from './acties/AANBIEDEN_CONTRACT';
import BEZOEKTIJD_NIET_GEHAALD from './acties/BEZOEKTIJD_NIET_GEHAALD';
import MIJN_GEGEVENS_WIJZIGING from './acties/MIJN_GEGEVENS_WIJZIGING';
import { Helmet } from 'react-helmet';
import TELEFOONOPROEP_GEMIST from './acties/TELEFOONOPROEP_GEMIST';
import TELEFOONOPROEP_HERINNERING_TOEKENNEN_GESPREKSCONTEXTEN from './acties/TELEFOONOPROEP_HERINNERING_TOEKENNEN_GESPREKSCONTEXTEN';
import WEBBETALING_MET_AFWIJKENDE_IBAN from './acties/WEBBETALING_MET_AFWIJKENDE_IBAN';
import WEBBETALING_MBT_BETALINGSREGELING from './acties/WEBBETALING_MBT_BETALINGSREGELING';
import SERVICEMELDING_TERUGKOPPELING_NIET_VERHOLPEN from './acties/SERVICEMELDING_TERUGKOPPELING_NIET_VERHOLPEN';
import FEEDBACK_WEBSITE_MET_REACTIEVERZOEK from './acties/FEEDBACK_WEBSITE_MET_REACTIEVERZOEK';
import WERKBONNEN_ONTVANGEN from './acties/WERKBONNEN_ONTVANGEN';
import TERUGBELVERZOEK_OPVOLGEN from './acties/TERUGBELVERZOEK_OPVOLGEN';
import SMS_CONTACT_PERSOON_CONFLICT from './acties/SMS_CONTACT_PERSOON_CONFLICT';

const IconCommunicatie = functioneleIconMap[EFunctioneleIcon.Communicatie];
const IconHogePio = functioneleIconMap[EFunctioneleIcon.HogePrioriteit];
const IconLagePrio = functioneleIconMap[EFunctioneleIcon.LagePrioriteit];
const IconTelefoonoproepGemist = functioneleIconMap[EFunctioneleIcon.telefoonoproepGemist];
const IconBestelling = functioneleIconMap[EFunctioneleIcon.Bestelling];
const IconActiverenAccount = functioneleIconMap[EFunctioneleIcon.ActiverenAccount];
const IconVerkoopvoorstel = functioneleIconMap[EFunctioneleIcon.Verkoopvoorstel];
const IconAnnulerenVersturen = functioneleIconMap[EFunctioneleIcon.AnnulerenVersturen];

export interface IHandmatigControlttaakToevoegenDialoogState {}
export interface IControlttaakWijzigenDialoogState {
  id: number;
}

export interface IToevoegenControltaakToewijzingDialoogState {
  controltaakID: number;
  uitgeslotenAspGebrIDs?: number[];
}

enum EFilter {
  Afgehandeld = 'AFGEHANDELD',
  ControltaakTypeIDs = 'CONTROLTAAK_TYPE_IDS',
  Toewijzing = 'TOEWIJZING',
  ToekomstigeVerbergen = 'GEEN_TOEKOMSTIGE_TAKEN',
}

export interface IUrlState {
  selectie: number[];
  uitgeklapt: number[];
  filterdata: IFilterData<EFilter>[];
  handmatigControlttaakToevoegenDialoogState: IHandmatigControlttaakToevoegenDialoogState | null;
  toevoegenControltaakToewijzingDialoogState: IToevoegenControltaakToewijzingDialoogState | null;
  controlttaakWijzigenDialoogState: IControlttaakWijzigenDialoogState | null;
}

export const bepaalDefaultUrlState = async (mijAspGebrID: number): Promise<IUrlState> => {
  const result = await api.v2.control.ophalenControltaakTypes({
    filterSchema: {
      filters: [
        {
          naam: 'NAAM_ENUMS',
          data: ['HANDMATIGE_TAAK'],
        },
      ],
    },
  });
  const initieelControltaakTypeID = result.types[0].ID;

  return {
    selectie: [],
    uitgeklapt: [],
    filterdata: [
      {
        naam: EFilter.ControltaakTypeIDs,
        data: [initieelControltaakTypeID],
        isActief: false,
      },
      {
        naam: EFilter.Afgehandeld,
        data: false,
        isActief: true,
      },
      {
        naam: EFilter.Toewijzing,
        data: {
          code: 'MIJ_EN_NIET_TOEGEWEZEN',
          mijAspGebrID,
        } as IOphalenControlTakenToewijzingFilterData,
        isActief: true,
      },
      {
        naam: EFilter.ToekomstigeVerbergen,
        data: true,
        isActief: true,
      },
    ],
    handmatigControlttaakToevoegenDialoogState: null,
    toevoegenControltaakToewijzingDialoogState: null,
    controlttaakWijzigenDialoogState: null,
  };
};

// Icons voor taken
const weergaveCompIconMap: Record<string, React.ComponentType<any>> = {
  ALGEMEEN: () => <IconControl style={{ fill: EKleur.Grijs, width: 18, height: 18 }} />,
  WHATSAPP_INBOUND_MESSAGE: () => (
    <IconWhatsapp style={{ fill: EKleur.Grijs, width: 18, height: 18 }} />
  ),
  SERVICE_MELDING: () => <IconService style={{ fill: EKleur.Grijs, width: 18, height: 18 }} />,
  TRANSPORT_BEZOEKTIJDEN_OPGEHAALD: () => (
    <IconKlok style={{ fill: EKleur.Grijs, width: 18, height: 18 }} />
  ),
  BEEINDIGEN_CONTRACT: () => <IconCancel style={{ fill: EKleur.Grijs, width: 18, height: 18 }} />,
  AANBIEDEN_CONTRACT: () => <IconControl style={{ fill: EKleur.Grijs, width: 18, height: 18 }} />,
  OVERNAME_CONTRACT: () => <IconRepeat style={{ fill: EKleur.Grijs, width: 18, height: 18 }} />,
  ANNULEREN_CONTRACT: () => <IconCancel style={{ fill: EKleur.Grijs, width: 18, height: 18 }} />,
  ANNULEREN_CONTACTVERZOEK: () => (
    <IconCancel style={{ fill: EKleur.Grijs, width: 18, height: 18 }} />
  ),
  MIJN_GEGEVENS_WIJZIGING: () => (
    <IconKeyboard style={{ fill: EKleur.Grijs, width: 18, height: 18 }} />
  ),
  RELATIE_REGISTRATIE: () => (
    <IconRelatieRegistratie style={{ fill: EKleur.Grijs, width: 18, height: 18 }} />
  ),
  HANDMATIGE_TAAK: () => <IconEvent style={{ fill: EKleur.Grijs, width: 18, height: 18 }} />,

  // Afhankelijk van de context
  CONTACTVERZOEK: () => <IconCommunicatie style={{ fill: EKleur.Grijs, width: 18, height: 18 }} />,
  CONTACTVERZOEK_TELEFOON: () => (
    <IconContactTelefoon style={{ fill: EKleur.Grijs, width: 18, height: 18 }} />
  ),
  CONTACTVERZOEK_EMAIL: () => (
    <IconContactEmail style={{ fill: EKleur.Grijs, width: 18, height: 18 }} />
  ),
  TELEFOONOPROEP_GEMIST: () => (
    <IconTelefoonoproepGemist style={{ fill: EKleur.Grijs, width: 18, height: 18 }} />
  ),
  BESTELLING: () => <IconBestelling style={{ fill: EKleur.Grijs, width: 18, height: 18 }} />,
  ACTIVEREN_ACCOUNT: () => (
    <IconActiverenAccount style={{ fill: EKleur.Grijs, width: 18, height: 18 }} />
  ),
  VERKOOPVOORSTEL_GEACCEPTEERD: () => (
    <IconVerkoopvoorstel style={{ fill: EKleur.Grijs, width: 18, height: 18 }} />
  ),
  NIET_VERSTUURDE_INKOOPOPDRACHTEN: () => (
    <IconAnnulerenVersturen style={{ fill: EKleur.Grijs, width: 18, height: 18 }} />
  ),
  BEZOEKINFO_AANGEPAST: () => <IconUpdate style={{ fill: EKleur.Grijs, width: 18, height: 18 }} />,
  TELEFOONOPROEP_HERINNERING_TOEKENNEN_GESPREKSCONTEXTEN: () => (
    <IconInformatie style={{ fill: EKleur.Grijs, width: 18, height: 18 }} />
  ),
  WEBBETALING_MET_AFWIJKENDE_IBAN: () => (
    <IconUpdate style={{ fill: EKleur.Grijs, width: 18, height: 18 }} />
  ),
  WEBBETALING_MBT_BETALINGSREGELING: () => (
    <IconUpdate style={{ fill: EKleur.Grijs, width: 18, height: 18 }} />
  ),
  SERVICEMELDING_TERUGKOPPELING_NIET_VERHOLPEN: () => (
    <IconCancel style={{ fill: EKleur.Grijs, width: 18, height: 18 }} />
  ),
  FEEDBACK_WEBSITE_MET_REACTIEVERZOEK: () => (
    <IconCancel style={{ fill: EKleur.Grijs, width: 18, height: 18 }} />
  ),
  WERKBONNEN_ONTVANGEN: () => <IconCancel style={{ fill: EKleur.Grijs, width: 18, height: 18 }} />,
  TERUGBELVERZOEK_OPVOLGEN: () => (
    <IconUitBedrijf style={{ fill: EKleur.Grijs, width: 18, height: 18 }} />
  ),
  SMS_CONTACT_PERSOON_CONFLICT: () => (
    <IconKruis style={{ fill: EKleur.Grijs, width: 18, height: 18 }} />
  ),
};

export interface IActieProps extends RouteComponentProps {
  controltaak: IOphalenControlTakenResultElement;
}

// Acties
const actiesMap: Record<string, React.VoidFunctionComponent<IActieProps>> = {
  ALGEMEEN: (x) => <span>{x.controltaak.Inhoud}</span>,
  CONTACTVERZOEK,
  SERVICE_MELDING,
  SERVICEMELDING_TERUGKOPPELING_NIET_VERHOLPEN,
  POTENTIEEL_ONTVANGEN_INKOOPFACTUUR,
  INKOOPLEVERING_TE_LAAT,
  TRANSPORT_BEZOEKTIJDEN_OPGEHAALD,
  BESTELLING_ONVERWERKT,
  BESTELLING_GEBLOKKEERD,
  SIGNALERINGEN_VERSCHIL_MET_VORIGE_KEER,
  AANBIEDEN_CONTRACT,
  BEZOEKTIJD_NIET_GEHAALD,
  MIJN_GEGEVENS_WIJZIGING,
  TELEFOONOPROEP_GEMIST,
  TELEFOONOPROEP_HERINNERING_TOEKENNEN_GESPREKSCONTEXTEN,
  WEBBETALING_MET_AFWIJKENDE_IBAN,
  WEBBETALING_MBT_BETALINGSREGELING,
  // FEEDBACK_WEBSITE_MET_REACTIEVERZOEK,
  WERKBONNEN_ONTVANGEN,
  TERUGBELVERZOEK_OPVOLGEN,
  SMS_CONTACT_PERSOON_CONFLICT,
};

interface IProps extends RouteComponentProps {
  initieelUrlState: IUrlState;
}

const Taken: React.FC<IProps> = observer((props) => {
  const { checkStore, gebruikerStore } = useContext(RootStoreContext);

  const [urlState, setUrlState, setUrlStateSync] = useUrlState(
    props,
    useMemo(() => props.initieelUrlState, []),
  );

  const [controlTaken, setControlTaken] = useState<IOphalenControlTakenResultElement[] | null>(
    null,
  );
  const [controltaakTypes, setControltaakTypes] = useState<IControltaakType[] | null>(null);
  const [
    controltaakToewijzingen,
    setControltaakToewijzingen,
  ] = useState<IOphalenControltaakToewijzingenResult | null>(null);

  const ophalenControltaakTypes = useCallback(async () => {
    const result = (await api.v2.control.ophalenControltaakTypes({})).types;

    const resultGesorteerd = _.orderBy(
      result,
      [
        (x: any) => {
          return x.Naam;
        },
      ],
      ['asc'],
    );

    setControltaakTypes(resultGesorteerd);
  }, []);
  useEffect(() => {
    ophalenControltaakTypes();
  }, [ophalenControltaakTypes]);

  const ophalenControltaakToewijzingen = useCallback(async () => {
    if (controlTaken === null) {
      setControltaakToewijzingen(null);
      return;
    }
    const controltaakIDs = controlTaken.map((x) => x.ID);
    const result = await api.v2.control.ophalenControltaakToewijzingen({
      filterSchema: {
        filters: [
          {
            naam: 'CONTROLTAAK_IDS',
            data: controltaakIDs,
          },
        ],
      },
    });
    setControltaakToewijzingen(result);
  }, [controlTaken]);
  useEffect(() => {
    ophalenControltaakToewijzingen();
  }, [ophalenControltaakToewijzingen]);

  // Contactverzoeken ophalen ivm benodigde data bij weergeven van contactverzoek-taken
  const [contactverzoekTaken, setContactverzoekTaken] = useState<
    { ID: number; Terugbellen: boolean }[] | null
  >(null);

  useEffect(() => {
    (async () => {
      if (controlTaken === null) {
        return;
      }

      // De taken met de bijbehorende JSON-data
      const takenResult = controlTaken
        .filter((x) => x.ControlTaakTypeNaamEnum === 'CONTACTVERZOEK')
        .map((x) => {
          return {
            ID: x.ID,
            ContactverzoekID: JSON.parse(x.Data!).id,
          };
        });
      const verzoekIDs = takenResult.map((x) => x.ContactverzoekID);
      const contactverzoeken = (
        await api.v2.contactverzoek.ophalenContactverzoeken({
          filterSchema: {
            filters: [{ naam: 'IDS', data: verzoekIDs }],
          },
        })
      ).contactverzoeken;

      const contactverzoekTaken = takenResult.map((x) => {
        const verzoek = contactverzoeken.find((c) => c.ID === x.ContactverzoekID)!;
        return { ID: x.ID, Terugbellen: verzoek.Terugbellen };
      });

      setContactverzoekTaken(contactverzoekTaken);
    })();
  }, [controlTaken]);

  // const keyExtractor = useCallback((row: IOphalenControlTakenResultElement) => row.ID, []);
  const kolommen = useMemo<TypedColumn<IOphalenControlTakenResultElement>[]>(
    () => [
      {
        name: 'RecordToegevoegd',
        title: 'Datum',
      },
      {
        name: 'ControlTaakTypeNaamEnum' as any,
        title: ' ',
      },
      {
        name: 'Titel',
        title: 'Titel',
      },
      // {
      //   name: 'Inhoud',
      //   title: 'Inhoud',
      // },
      {
        name: 'RelID',
        title: 'Relatie',
      },
      {
        name: '__actie' as any,
        title: 'Actie/Inhoud',
      },
      {
        name: '__toewijzingen',
        title: 'Toewijzingen',
      },
      {
        name: '__opvolgen' as any,
        title: 'Opvolgen',
      },
      {
        name: 'Urgentie',
        title: 'Urg.',
      },
      {
        name: '__bezigheid' as any,
        title: ' ',
      },
      {
        name: 'Afgehandeld_Datum',
        title: ' ',
      },
      {
        name: '__actieLopend' as any,
        title: ' ',
      },
    ],
    [],
  );
  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IOphalenControlTakenResultElement>[]>(
    () => [
      {
        columnName: 'ControlTaakTypeNaamEnum' as any,
        width: 55,
      },
      {
        columnName: 'Urgentie',
        width: 70,
      },
      {
        columnName: 'RecordToegevoegd',
        width: 110,
      },
      {
        columnName: 'Titel',
        width: 250,
      },
      {
        columnName: 'RelID',
        width: 200,
      },
      {
        columnName: '__toewijzingen',
        width: 120,
      },
      {
        columnName: '__opvolgen' as any,
        width: 200,
      },
      {
        columnName: '__actie',
        width: 425,
      },
      {
        columnName: 'Inhoud',
        width: 260,
      },
      {
        columnName: '__bezigheid',
        width: 110,
      },
      {
        columnName: 'Afgehandeld_Datum',
        width: 135,
      },
      {
        columnName: '__actieLopend' as any,
        width: 110,
      },
    ],
    [],
  );

  const keyExtractor = useCallback((item: IOphalenControlTakenResultElement) => item.ID, []);

  const [filterSchema, setFilterSchema] = useState<IFilterSchema>(
    maakFilterSchema(urlState.filterdata),
  );
  const ophalenControltaken = useCallback(async () => {
    const result = await api.v2.control.ophalenControlTaken({
      paginatie: {
        index: 0,
        aantal: 1000,
      },
      filterSchema: {
        filters: [...filterSchema.filters!, { naam: 'ACTIEF', data: true }],
      },
    });
    setControlTaken(result.taken);
  }, [filterSchema]);
  useEffect(() => {
    ophalenControltaken();
  }, [ophalenControltaken]);

  useRealtimeListener((ev) => {
    if (ev === 'CONTROL_TAAK_NIEUW' || ev === 'CONTROL_TAKEN_AFGEHANDELD') {
      ophalenControltaken();
    }
  });

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.Afgehandeld,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return (
            <div className="d-flex align-items-center">
              <span>Lopend</span>
            </div>
          );
        },
      },
      {
        naam: EFilter.ControltaakTypeIDs,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return (
            <div className="d-flex align-items-center">
              <span>Type</span>
              <span className="ml-3">
                {controltaakTypes === null ? (
                  <LoadingSpinner />
                ) : (
                  <Combobox
                    geselecteerd={weergaveProps.data.length === 0 ? null : weergaveProps.data[0]}
                    onSelectieChange={(x) => {
                      weergaveProps.onDataChange(x === null ? [] : [x]);
                      weergaveProps.setIsActief(true);
                      weergaveProps.toepassen();
                    }}
                    opties={controltaakTypes.map((type) => ({
                      id: type.ID,
                      label: type.Naam,
                    }))}
                  />
                )}
              </span>
            </div>
          );
        },
      },
      {
        naam: EFilter.Toewijzing,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          const data: IOphalenControlTakenToewijzingFilterData = weergaveProps.data;
          return (
            <div className="d-flex align-items-center">
              <span>Toewijzing</span>
              <span className="ml-3">
                <Combobox
                  geselecteerd={data.code}
                  onSelectieChange={(x) => {
                    weergaveProps.onDataChange({
                      ...data,
                      code: x!,
                    });
                    weergaveProps.toepassen();
                  }}
                  opties={[
                    {
                      id: 'MIJ_EN_NIET_TOEGEWEZEN',
                      label: 'Van mij en niet toegewezen',
                    },
                    {
                      id: 'MIJ',
                      label: 'Van mij',
                    },
                    {
                      id: 'NIET_TOEGEWEZEN',
                      label: 'Niet toegewezen',
                    },
                    {
                      id: 'TOEGEWEZEN_AAN_ANDEREN',
                      label: 'Toegewezen aan anderen',
                    },
                  ]}
                />
              </span>
            </div>
          );
        },
      },
      {
        naam: EFilter.ToekomstigeVerbergen,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return (
            <div className="d-flex align-items-center">
              <span>Opvolgdatum meer dan 3 dagen in toekomst verbergen</span>
            </div>
          );
        },
      },
    ],
    [controltaakTypes],
  );

  return (
    <>
      <Helmet>
        <title>Taken</title>
      </Helmet>
      <Root>
        <MenuLayout
          menu={
            <div className="d-flex flex-column">
              <div className="d-flex align-items-center">
                <button
                  className="btn btn-sm btn-light d-flex align-items-center"
                  style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                  onClick={() => setUrlStateSync('handmatigControlttaakToevoegenDialoogState', {})}
                >
                  <IconToevoegen style={{ fill: EKleur.Grijs, width: 16, height: 16 }} />
                  <span className="ml-2">Handmatig toevoegen</span>
                </button>
                <button
                  className="btn btn-sm btn-light d-flex align-items-center ml-3"
                  style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                  disabled={
                    !urlState.filterdata.filter((x: any) => x.naam === EFilter.Afgehandeld)[0]
                      .isActief
                  }
                  onClick={async () => {
                    if (
                      (
                        await checkStore.bevestigen({
                          inhoud: 'Geselecteerde taken als Afgehandeld markeren?',
                        })
                      ).type === EResultType.Annuleren
                    ) {
                      return;
                    }

                    await api.v2.control.afhandelenControlTaken({
                      ids: urlState.selectie,
                    });
                    await ophalenControltaken();
                  }}
                >
                  <IconToevoegen style={{ fill: EKleur.Grijs, width: 16, height: 16 }} />
                  <span className="ml-2">Afgehandeld markeren</span>
                </button>
              </div>
              <div className="mt-2">
                <FilterBalkV2
                  filterData={urlState.filterdata}
                  filters={filters}
                  onFilterSchemaChange={setFilterSchema}
                  onFilterDataChange={(data) => setUrlStateSync('filterdata', data)}
                />
              </div>
            </div>
          }
          body={
            controlTaken === null ||
            controltaakToewijzingen === null ||
            contactverzoekTaken === null ? (
              <div className="flex-fill d-flex align-items-center justify-content-center">
                <LoadingSpinner />
              </div>
            ) : (
              <GridStyleWrapper height="calc(100vh - 100px)">
                <Grid getRowId={keyExtractor} columns={kolommen} rows={controlTaken}>
                  <DataTypeProvider
                    for={[nameOf<IOphalenControlTakenResultElement>('ControlTaakTypeNaamEnum')]}
                    formatterComponent={(formatterProps) => {
                      const row: IOphalenControlTakenResultElement = formatterProps.row;

                      if (row.ControlTaakTypeNaamEnum === 'CONTACTVERZOEK') {
                        const verzoek = contactverzoekTaken.find((x) => x.ID === row.ID)!;
                        if (verzoek === undefined) {
                          return <span />;
                        }

                        const Comp = verzoek.Terugbellen
                          ? weergaveCompIconMap.CONTACTVERZOEK_TELEFOON
                          : weergaveCompIconMap.CONTACTVERZOEK_EMAIL;

                        return <Comp />;
                      }

                      const Comp =
                        weergaveCompIconMap[row.ControlTaakTypeNaamEnum] ||
                        weergaveCompIconMap.ALGEMEEN!;

                      return <Comp />;

                      // return (
                      //   <span className="d-flex">
                      //     <Comp />
                      //     <span className="ml-2">
                      //       <IconLagePrio style={{ width: 16, height: 16, fill: EKleur.Grijs }} />
                      //     </span>
                      //   </span>
                      // );
                    }}
                  />
                  <DataTypeProvider
                    for={[nameOf<IOphalenControlTakenResultElement>('Urgentie')]}
                    formatterComponent={(formatterProps) => {
                      const row: IOphalenControlTakenResultElement = formatterProps.row;
                      if (row.Urgentie === 0) {
                        return <span></span>;
                      }
                      if (row.Urgentie === -1) {
                        return (
                          <IconLagePrio style={{ width: 16, height: 16, fill: EKleur.Grijs }} />
                        );
                      }
                      return <IconHogePio style={{ width: 16, height: 16, fill: EKleur.Rood }} />;
                    }}
                  />

                  <DataTypeProvider
                    for={[nameOf<IOphalenControlTakenResultElement>('RecordToegevoegd')]}
                    formatterComponent={(formatterProps) => {
                      const row: IOphalenControlTakenResultElement = formatterProps.row;
                      const d = new Date(row.RecordToegevoegd);
                      return <span>{format(d, 'dd-MM HH:mm EEEEEE', { locale: nl })}</span>;
                    }}
                  />

                  <DataTypeProvider
                    for={[nameOf<IOphalenControlTakenResultElement>('RelID')]}
                    formatterComponent={(formatterProps) => {
                      const row: IOphalenControlTakenResultElement = formatterProps.row;
                      if (row.RelID === null) {
                        return <span />;
                      }
                      return <RelatieVisualisatie relID={row.RelID} />;
                    }}
                  />

                  <DataTypeProvider
                    for={['__toewijzingen']}
                    formatterComponent={(formatterProps) => {
                      const row: IOphalenControlTakenResultElement = formatterProps.row;
                      const toewijzingen = controltaakToewijzingen!.toewijzingen.filter(
                        (toewijzing) => toewijzing.ControlTaakID === row.ID,
                      );

                      return (
                        <div
                          className="d-flex align-items-center"
                          style={{ minWidth: 250, minHeight: 23 }}
                        >
                          {toewijzingen.map((toewijzing, i) => {
                            return (
                              <>
                                <Chip key={toewijzing.ID}>
                                  <ASPGebruikerVisualisatie aspGebrID={toewijzing.AspGebrID} />
                                  <button
                                    className="ml-1"
                                    style={{
                                      outline: 0,
                                      border: 0,
                                      background: 0,
                                      padding: 0,
                                      position: 'relative',
                                      top: -1,
                                    }}
                                    onClick={async () => {
                                      if (
                                        (
                                          await checkStore.bevestigen({
                                            inhoud: 'Confirmatie verwijderen toewijzing',
                                            asynchroneActieNaBevestigingFn: async () => {
                                              await api.v2.control.muterenControltaakToewijzingen({
                                                controlTaakID: toewijzing.ControlTaakID,
                                                aspGebrIDs: toewijzingen
                                                  .filter((x) => x.ID !== toewijzing.ID)
                                                  .map((x) => x.AspGebrID),
                                              });

                                              // await ophalenControltaakToewijzingen();
                                              await ophalenControltaken();
                                            },
                                          })
                                        ).type === EResultType.Annuleren
                                      ) {
                                        return;
                                      }
                                    }}
                                  >
                                    <IconKruis
                                      style={{ fill: EKleur.Grijs, width: 14, height: 14 }}
                                    />
                                  </button>
                                </Chip>
                                {i !== toewijzingen.length - 1 && <span>&nbsp;</span>}
                              </>
                            );
                          })}
                          <UitlegTooltip inhoud="Toewijzing toevoegen">
                            <button
                              className="ml-1"
                              style={{
                                outline: 0,
                                border: 0,
                                background: 0,
                                padding: 0,
                                position: 'relative',
                                top: -1,
                              }}
                              onClick={() =>
                                setUrlStateSync('toevoegenControltaakToewijzingDialoogState', {
                                  controltaakID: row.ID,
                                  uitgeslotenAspGebrIDs: toewijzingen.map((x) => x.AspGebrID),
                                })
                              }
                            >
                              <IconToevoegen
                                style={{ fill: EKleur.Grijs, width: 16, height: 16 }}
                              />
                            </button>
                          </UitlegTooltip>
                        </div>
                      );
                    }}
                  />

                  <DataTypeProvider
                    for={['__opvolgen']}
                    formatterComponent={(formatterProps) => {
                      const row: IOphalenControlTakenResultElement = formatterProps.row;

                      if (row.Opvolgdatum !== null && row.UiterlijkOp === null) {
                        return (
                          <span>
                            <OpvolgdatumWeergave datum={new Date(row.Opvolgdatum)} />
                          </span>
                        );
                      }

                      if (row.Opvolgdatum === null && row.UiterlijkOp !== null) {
                        return (
                          <span>
                            Voor <OpvolgdatumWeergave datum={new Date(row.UiterlijkOp)} />
                          </span>
                        );
                      }

                      if (row.Opvolgdatum !== null && row.UiterlijkOp !== null) {
                        return (
                          <span>
                            <OpvolgdatumWeergave datum={new Date(row.Opvolgdatum)} /> -{' '}
                            {format(new Date(row.UiterlijkOp), 'HH:mm')}
                          </span>
                        );
                      }

                      return <span />;
                    }}
                  />

                  <DataTypeProvider
                    for={['__actie']}
                    formatterComponent={(formatterProps) => {
                      const row: IOphalenControlTakenResultElement = formatterProps.row;

                      const Comp = actiesMap[row.ControlTaakTypeNaamEnum] || actiesMap.ALGEMEEN!;
                      const WithRouterComp = withRouter(Comp);
                      return <WithRouterComp controltaak={row} />;
                    }}
                  />

                  <DataTypeProvider
                    for={['__bezigheid']}
                    formatterComponent={(formatterProps) => {
                      const row: IOphalenControlTakenResultElement = formatterProps.row;
                      return (
                        <BezigheidKnop
                          interactieToegestaan={true}
                          data={{ enum: 'CONTROL_TAAK', id: row.ID }}
                        />
                      );
                    }}
                  />

                  <DataTypeProvider
                    for={[nameOf<IOphalenControlTakenResultElement>('Afgehandeld_Datum')]}
                    formatterComponent={(formatterProps) => {
                      const row: IOphalenControlTakenResultElement = formatterProps.row;
                      if (row.Afgehandeld_Datum === null) {
                        return (
                          <button
                            className="btn btn-sm btn-light"
                            style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                            onClick={async () => {
                              if (
                                (
                                  await checkStore.bevestigen({
                                    inhoud: 'Deze controltaak als afgehandeld markeren?',
                                  })
                                ).type === EResultType.Annuleren
                              ) {
                                return;
                              }
                              await api.v2.control.afhandelenControlTaken({
                                ids: [row.ID],
                              });
                              await ophalenControltaken();
                            }}
                          >
                            Afgehandeld
                          </button>
                        );
                      }
                      const d = new Date(row.Afgehandeld_Datum);
                      return <span>{format(d, 'dd-MM-yyyy HH:mm')}</span>;
                    }}
                  />

                  <DataTypeProvider
                    for={['__actieLopend']}
                    formatterComponent={(formatterProps) => {
                      const row: IOphalenControlTakenResultElement = formatterProps.row;
                      if (row.Afgehandeld_Datum !== null) {
                        const d = row.Afgehandeld_Datum;
                        return (
                          <div className="d-flex">
                            <span className="d-flex align-items-center ml-3">
                              <a
                                href="#"
                                onClick={async () => {
                                  if (
                                    (
                                      await checkStore.bevestigen({
                                        inhoud: 'Deze controltaak weer als Lopend markeren?',
                                      })
                                    ).type === EResultType.Annuleren
                                  ) {
                                    return;
                                  }
                                  await api.v2.control.herstelAfhandelenControlTaken({
                                    ids: [row.ID],
                                  });
                                  await ophalenControltaken();
                                }}
                              >
                                Lopend maken
                              </a>
                            </span>
                          </div>
                        );
                      }
                      return <span></span>;
                    }}
                  />

                  <EditingState
                    onCommitChanges={async (changes) => {
                      if (changes.deleted === undefined) {
                        return;
                      }
                      const deleted = changes.deleted;
                      const id = deleted[deleted.length - 1] as number;

                      const checkData = await api.v2.control.checkVerwijderenTaken({
                        ids: [id],
                      });
                      if (
                        (await checkStore.controleren({ checkData })).type === EResultType.Annuleren
                      ) {
                        return;
                      }

                      if (
                        (
                          await checkStore.bevestigen({
                            inhoud: `Wil je deze taak verwijderen?`,
                          })
                        ).type === EResultType.Annuleren
                      ) {
                        return;
                      }
                      const result = await api.v2.control.verwijderenTaken({
                        ids: [id],
                      });
                      ophalenControltaken();
                    }}
                    onEditingRowIdsChange={async (rowIds) => {
                      const id = rowIds[rowIds.length - 1] as number;

                      const params = { id };
                      const checkData = await api.v2.control.checkSelectieWijzigenTaak({
                        id,
                      });
                      if (
                        (await checkStore.controleren({ checkData })).type === EResultType.Annuleren
                      ) {
                        return;
                      }

                      setUrlStateSync('controlttaakWijzigenDialoogState', params);
                    }}
                  />

                  <RowDetailState
                    expandedRowIds={urlState.uitgeklapt}
                    onExpandedRowIdsChange={(x) => setUrlStateSync('uitgeklapt', x as number[])}
                  />

                  <SortingState defaultSorting={[]} />
                  <IntegratedSorting />

                  <VirtualTable messages={{ noData: 'Geen taken aanwezig' }} />
                  <TableColumnResizing defaultColumnWidths={kolomBreedtes} />

                  <TableEditColumn
                    width={60}
                    showDeleteCommand
                    showEditCommand
                    cellComponent={DXTableEditColumnCellComponent}
                    commandComponent={DXTableEditColumnCommandComponent}
                  />
                  {/* <TableRowDetail
                    contentComponent={Detailregel}
                    toggleCellComponent={DXTableToggleCellComponent}
                  /> */}
                  <TableHeaderRow showSortingControls />

                  <SelectionState
                    selection={urlState.selectie}
                    onSelectionChange={(x) => setUrlStateSync('selectie', x as number[])}
                  />
                  <TableSelection cellComponent={DXTableCheckboxComponent} />
                </Grid>
              </GridStyleWrapper>
            )
          }
        />
      </Root>
      {urlState.handmatigControlttaakToevoegenDialoogState !== null && (
        <HandmatigControltaakToevoegenDialoog
          open
          aspGebrID={gebruikerStore.gebruiker!.AspGebrID}
          onSuccess={async (result) => {
            await ophalenControltaken();
            setUrlStateSync('handmatigControlttaakToevoegenDialoogState', null);
          }}
          onAnnuleren={() => setUrlStateSync('handmatigControlttaakToevoegenDialoogState', null)}
        />
      )}
      {urlState.toevoegenControltaakToewijzingDialoogState !== null && (
        <ASPGebruikerSelectieDialoog
          open
          uitgeslotenAspGebrIDs={
            urlState.toevoegenControltaakToewijzingDialoogState.uitgeslotenAspGebrIDs
          }
          onSuccess={async (result) => {
            const controltaakID = urlState.toevoegenControltaakToewijzingDialoogState!
              .controltaakID;
            const toewijzingen = controltaakToewijzingen!.toewijzingen.filter(
              (toewijzing) => toewijzing.ControlTaakID === controltaakID,
            );
            await api.v2.control.muterenControltaakToewijzingen({
              controlTaakID: controltaakID,
              aspGebrIDs: [...toewijzingen.map((x) => x.AspGebrID), result.aspGebrID],
            });
            await ophalenControltaakToewijzingen();
            setUrlStateSync('toevoegenControltaakToewijzingDialoogState', null);
          }}
          onAnnuleren={() => setUrlStateSync('toevoegenControltaakToewijzingDialoogState', null)}
        />
      )}
      {urlState.controlttaakWijzigenDialoogState !== null && (
        <ControltaakWijzigenDialoog
          open
          id={urlState.controlttaakWijzigenDialoogState.id}
          onSuccess={async (result) => {
            await ophalenControltaken();
            setUrlStateSync('controlttaakWijzigenDialoogState', null);
          }}
          onAnnuleren={() => setUrlStateSync('controlttaakWijzigenDialoogState', null)}
        />
      )}
    </>
  );
});

const TakenComp = withRouter(Taken);

const TakenWrapper: React.ComponentType<Pick<
  IProps,
  Exclude<keyof IProps, 'initieelUrlState'>
>> = observer((props) => {
  const { gebruikerStore } = useContext(RootStoreContext);
  const search = props.location.search;
  const urlSearchParams = useMemo(() => new URLSearchParams(search), [search]);
  const getStateResult = useMemo(
    () => getUrlStateFromURLSearchParams<IUrlState>(urlSearchParams),
    [],
  );
  const [urlState, setUrlState] = useState<IUrlState | null>(getStateResult);

  const bepaalUrlState = useCallback(async () => {
    const result = await bepaalDefaultUrlState(gebruikerStore.gebruiker!.AspGebrID);
    setUrlState(result);
  }, [gebruikerStore.gebruiker]);

  useEffect(() => {
    if (urlState !== null) {
      return;
    }

    bepaalUrlState();
  }, [urlState, bepaalUrlState]);

  if (urlState === null) {
    return (
      <div className="d-flex flex-fill align-items-center justify-content-center">
        <LoadingSpinner />
      </div>
    );
  }

  return <TakenComp {...props} initieelUrlState={urlState} />;
});

export default TakenWrapper;
