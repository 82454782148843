import React, { useCallback, useMemo, useState, useContext, useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import useUrlState from '../../../../core/useUrlState';

import api from '../../../../api';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import { IOphalenOpdrachtenResultElementV2 } from '../../../../../../shared/src/api/v2/transport/opdracht';
import {
  DXTableCheckboxComponent,
  DXTableToggleCellComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
} from '../../../../helpers/dxTableGrid';
import {
  VirtualTable,
  Grid,
  TableHeaderRow,
  TableColumnResizing,
  TableSelection,
  TableEditColumn,
  TableRowDetail,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  DataTypeProvider,
  SelectionState,
  EditingState,
  SortingState,
  IntegratedSorting,
  RowDetailState,
} from '@devexpress/dx-react-grid';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import { addDays, format } from 'date-fns';
import FilterBalkV2, {
  IFilter,
  IFilterData,
  maakFilterSchema,
} from '../../../../components/FilterBalkV2';
import _ from 'lodash';
import { ERegelstatusTransport } from '../../../../bedrijfslogica/enums';
import { IOphalenOpdrachtregelsResultElementV2 } from '../../../../../../shared/src/api/v2/transport/opdracht';
import { RootStoreContext } from '../../../../stores/RootStore';
import { Helmet } from 'react-helmet';
import { IOphalenLocatiesResultElement } from '../../../../../../shared/src/api/v2/locatie/locatie';
import DetailComp from './OpdrachtenDetailComp';
import { IOphalenGekoppeldeTransportopdrachtenResultElement } from '../../../../../../shared/src/api/v2/inkoopfactuur/transport';
import RelatiesVisualisaties from '../../../../components/personalia/RelatiesVisualisaties';
import { EHoedanigheid } from '../../../../components/personalia/RelatieSelectieDialoog';
import { IconVlag } from '../../../../components/Icons';
import { EResultType } from '../../../../stores/CheckStore';

interface IProps extends RouteComponentProps {}

enum EFilter {
  MetMeerDan1Bezoeken = 'MET_MEER_DAN_1_BEZOEKEN',
}

interface IUrlState {
  selectie: number[];
  filterData: IFilterData<EFilter>[];
}

const defaultUrlState: IUrlState = {
  selectie: [],
  filterData: [
    {
      naam: EFilter.MetMeerDan1Bezoeken,
      data: true,
      isActief: true,
    },
  ],
};

export interface IRegel extends IOphalenLocatiesResultElement {
  maximaleBezoekdatum: Date | string;
  bezoekdatums: string;
  opdrachtregels: IOphalenOpdrachtregelsResultElementV2[];
  opdrachten: IOphalenOpdrachtenResultElementV2[];
  gekoppeldeFacturen: IOphalenGekoppeldeTransportopdrachtenResultElement[];
}

const Locaties: React.FC<IProps> = (props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const { checkStore } = useContext(RootStoreContext);

  const [locaties, setLocaties] = useState<IRegel[] | null>(null);
  const [opdrachten, setOpdrachten] = useState<IOphalenOpdrachtenResultElementV2[] | null>(null);
  const [opdrachtregels, setOpdrachtregels] = useState<
    IOphalenOpdrachtregelsResultElementV2[] | null
  >(null);
  const [gekoppeldeFacturen, setGekoppeldeFacturen] = useState<
    IOphalenGekoppeldeTransportopdrachtenResultElement[] | null
  >(null);

  // const [wijzigenRegelID, setWijzigenRegelID] = useState<number | null>(null);

  const [filterSchema, setFilterSchema] = useState(maakFilterSchema(urlState.filterData));

  const ophalenLocaties = useCallback(async () => {
    if (opdrachten === null || opdrachtregels === null || gekoppeldeFacturen === null) {
      return;
    }

    const locatiesResult = await api.v2.locatie.ophalenLocaties({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: opdrachten.map((x) => x.locatie.LocID),
          },
        ],
      },
    });

    const locatiesBasisResult = locatiesResult.locaties.map((x) => {
      const opdrachtenVoorLocatie = opdrachten.filter((y) => y.locatie.LocID === x.LocID);
      const maximaleBezoekdatum = _.max(opdrachtenVoorLocatie.map((y) => y.Bezoekdatum));
      const bezoekdatums = opdrachtenVoorLocatie.map((y) => y.Bezoekdatum).join(', ');

      const trsOpdIDs = opdrachtenVoorLocatie.map((y) => y.TrsOpdID);
      const gekoppeldeFacturenVoorLocatie = gekoppeldeFacturen.filter(
        (y) => trsOpdIDs.indexOf(y.TrsOpdID!) !== -1,
      );

      const opdrachtregelsVoorLocatie = opdrachtregels.filter(
        (y) => trsOpdIDs.indexOf(y.TrsOpdID) !== -1,
      );

      return {
        ...x,
        maximaleBezoekdatum,
        bezoekdatums,
        opdrachtregels: opdrachtregelsVoorLocatie,
        opdrachten: opdrachtenVoorLocatie,
        gekoppeldeFacturen: gekoppeldeFacturenVoorLocatie,
      };
    });

    // Filter de locaties waarvoor alle opdrachten reeds beoordeeld zijn voor de facturatie
    const locatiesBasis = locatiesBasisResult.filter((x) => {
      return x.opdrachten.some((x) => x.FacturatieBeoordeeldOp === null);
    });

    const metMeerDan1Bezoeken =
      filterSchema.filters !== undefined &&
      filterSchema.filters.find((x) => x.naam === EFilter.MetMeerDan1Bezoeken) !== undefined;

    const locaties = metMeerDan1Bezoeken
      ? locatiesBasis.filter((x) => x.opdrachten.length > 1)
      : locatiesBasis;

    const locatiesGesorteerd = _.orderBy(
      locaties,
      ['maximaleBezoekdatum', 'Straatnaam', 'Huisnummer', 'Bisnummer'],
      ['desc', 'asc', 'asc', 'asc'],
    );

    setLocaties(locatiesGesorteerd);
  }, [opdrachten, opdrachtregels, gekoppeldeFacturen, filterSchema.filters]);

  useEffect(() => {
    ophalenLocaties();
  }, [ophalenLocaties]);

  const ophalenRegels = useCallback(async () => {
    // Max x dagen terug
    const dagenTerug = 60;
    const statusdatumVanaf = format(addDays(new Date(), -dagenTerug), 'yyyy-MM-dd');

    const opdrachtregelsResult = await api.v2.transport.opdracht.ophalenOpdrachtregelsV2({
      filterSchema: {
        filters: [
          // ...filterSchema.filters!,
          { naam: 'REGELSTATUSSEN', data: [ERegelstatusTransport.UITGEVOERD] },
          { naam: 'STATUSDATUM_VANAF', data: statusdatumVanaf },
          // { naam: 'REGELSOORTEN', data: [ETransportopdrachtRegelsoort.Levering] },
          // { naam: 'UITGEVOERD_PRODUCT_IS_GEVULD', data: true },
        ],
      },
      // orderSchema: {
      //   orders: [
      //     {
      //       naam: 'REGELNUMMER',
      //       richting: 'ASC',
      //     },
      //   ],
      // },
    });

    // const regelsGesorteerd = _.orderBy(regels, ['Statusdatum'], ['desc']);

    setOpdrachtregels(opdrachtregelsResult.regels);
  }, []);

  useEffect(() => {
    ophalenRegels();
  }, [ophalenRegels]);

  const ophalenOpdrachten = useCallback(async () => {
    if (opdrachtregels === null) {
      return;
    }
    const opdrachtenResult = await api.v2.transport.opdracht.ophalenOpdrachtenV2({
      filterSchema: {
        filters: [
          { naam: 'IDS', data: opdrachtregels.map((x) => x.TrsOpdID) },
          // { naam: 'FACTURATIE_BEOORDEELD', data: false },
        ],
      },
    });

    const opdrachten = opdrachtenResult.opdrachten;

    setOpdrachten(opdrachten);
  }, [opdrachtregels]);

  useEffect(() => {
    ophalenOpdrachten();
  }, [ophalenOpdrachten]);

  const ophalenGekoppeldeFacturen = useCallback(async () => {
    if (opdrachten === null) {
      return;
    }
    const facturenResult = await api.v2.inkoopfactuur.transport.ophalenGekoppeldeTransportopdrachten(
      {
        filterSchema: {
          filters: [{ naam: 'TRSOPD_IDS', data: opdrachten.map((x) => x.TrsOpdID) }],
        },
      },
    );
    setGekoppeldeFacturen(facturenResult.gekoppeldeTransportopdrachten);
  }, [opdrachten]);

  useEffect(() => {
    ophalenGekoppeldeFacturen();
  }, [ophalenGekoppeldeFacturen]);

  const keyExtractor = useCallback((row: IRegel) => row.LocID, []);
  const kolommen = useMemo<TypedColumn<IRegel>[]>(
    () => [
      // {
      //   name: '__opdrachtnummerMetregelnummer' as any,
      //   title: 'Opd.nr/rg',
      //   getCellValue: (x) => {
      //     return x.Opdrachtnummer + x.Regelnummer;
      //   },
      // },
      {
        name: '__straatnaam' as any,
        title: 'Locatie',
        getCellValue: (x) => {
          return (
            x.Straatnaam +
            ' ' +
            x.Huisnummer +
            (x.Bisnummer !== null ? ' ' + x.Bisnummer : '') +
            ', ' +
            x.Plaatsnaam
          );
        },
      },
      {
        name: '__relaties' as any,
        title: 'Relatie(s)',
        // getCellValue: (x) => { return x.
      },
      {
        name: '__aantalBezoeken' as any,
        title: '# Bezoeken',
        // getCellValue: (x) => { return x.
      },
      {
        name: '__meestRecenteBezoekdatum' as any,
        title: 'Laatste bezoek',
        getCellValue: (x) => {
          return x.maximaleBezoekdatum === null ? '' : new Date(x.maximaleBezoekdatum);
        },
      },
      {
        name: '__eerdereBezoeken' as any,
        title: 'Eerdere bezoeken',
      },
    ],
    [],
  );
  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRegel>[]>(
    () => [
      // {
      //   columnName: '__opdrachtnummerMetregelnummer' as any,
      //   width: 100,
      // },
      {
        columnName: '__straatnaam' as any,
        width: 350,
      },
      {
        columnName: '__meestRecenteBezoekdatum' as any,
        width: 175,
      },
      {
        columnName: '__eerdereBezoeken' as any,
        width: 300,
      },
      {
        columnName: '__relaties' as any,
        width: 350,
      },
      {
        columnName: '__aantalBezoeken' as any,
        width: 125,
      },
    ],
    [],
  );

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.MetMeerDan1Bezoeken,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return (
            <span className="d-flex align-items-center">
              <span>Met meer dan 1 bezoek</span>
            </span>
          );
        },
      },
    ],
    [],
  );

  return (
    <>
      <Helmet>
        <title>Transportopdrachten per locatie</title>
      </Helmet>

      <div
        className="d-flex flex-column p-3"
        style={{
          backgroundColor: Kleur.HeelLichtGrijs,
          borderBottom: `1px solid ${Kleur.LichtGrijs}`,
        }}
      >
        <div className="d-flex align-items-center">
          <button
            className="btn btn-sm btn-light d-flex align-items-center"
            style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
            disabled={urlState.selectie.length === 0}
            onClick={async () => {
              if (
                (
                  await checkStore.bevestigen({
                    inhoud:
                      'Wil je de geselecteerde locaties/opdrachten markeren als Beoordeeld op facturatie?',
                  })
                ).type === EResultType.Annuleren
              ) {
                return;
              }

              const trsOpdIDs = opdrachten!
                .filter((x) => urlState.selectie.indexOf(x.locatie.LocID) !== -1)
                .map((x) => x.TrsOpdID);
              await api.v2.transport.opdracht.markerenFacturatieBeoordeeld({
                trsOpdIDs,
              });

              setUrlStateSync('selectie', []);
              await ophalenOpdrachten();
              await ophalenLocaties();
            }}
          >
            <IconVlag style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
            <span className="ml-2">Markeren facturatie beoordeeld</span>
          </button>
          <div className="d-flex flex-fill ml-3">
            <FilterBalkV2
              filters={filters}
              filterData={urlState.filterData}
              onFilterDataChange={(x) => setUrlStateSync('filterData', x)}
              onFilterSchemaChange={(x) => setFilterSchema(x)}
            />
          </div>
        </div>
      </div>
      {locaties === null || opdrachtregels === null || opdrachten === null ? (
        <div className="d-flex flex-fill align-items-center justify-content-center">
          <LoadingSpinner />
        </div>
      ) : (
        <GridStyleWrapper height={'calc(100vh - 150px)'}>
          <Grid rows={locaties} columns={kolommen} getRowId={keyExtractor}>
            <DataTypeProvider
              for={['__straatnaam']}
              formatterComponent={(formatterProps) => {
                const rij: IRegel = formatterProps.row;
                const adres =
                  rij.Straatnaam +
                  ' ' +
                  rij.Huisnummer +
                  (rij.Bisnummer !== null ? ' ' + rij.Bisnummer : '') +
                  ', ' +
                  rij.Plaatsnaam;
                return <span>{adres}</span>;
              }}
            />

            <DataTypeProvider
              for={['__aantalBezoeken']}
              formatterComponent={(formatterProps) => {
                const rij: IRegel = formatterProps.row;

                return <span>{rij.opdrachten.length}</span>;
              }}
            />

            <DataTypeProvider
              for={['__meestRecenteBezoekdatum']}
              formatterComponent={(formatterProps) => {
                const rij: IRegel = formatterProps.row;

                return (
                  <span>
                    {rij.maximaleBezoekdatum === null
                      ? ''
                      : format(new Date(rij.maximaleBezoekdatum), 'dd-MM-yyyy')}
                  </span>
                );
              }}
            />

            <DataTypeProvider
              for={['__eerdereBezoeken']}
              formatterComponent={(formatterProps) => {
                const rij: IRegel = formatterProps.row;

                const maximaleBezoekdatum = _.max(rij.opdrachten.map((y) => y.Bezoekdatum));

                const bezoekdatums = rij.opdrachten
                  .filter((x) => x.Bezoekdatum !== maximaleBezoekdatum)
                  .map((y) => format(new Date(y.Bezoekdatum!), 'dd-MM-yyyy'))
                  .join(', ');

                return <span>{bezoekdatums}</span>;
              }}
            />

            <DataTypeProvider
              for={['__relaties']}
              formatterComponent={(formatterProps) => {
                const rij: IRegel = formatterProps.row;

                const relIDs: number[] = _.uniq(
                  rij.opdrachtregels.filter((x) => x.RelID !== null).map((x) => x.RelID),
                );

                // return <span>{relIDs.length}</span>;

                if (relIDs.length === 0) {
                  return <span />;
                }
                return (
                  <RelatiesVisualisaties
                    relIDs={relIDs}
                    relatieLinkBuilder={(hoedanigheid, relID) =>
                      `/${
                        hoedanigheid === EHoedanigheid.Klant ? 'klant' : 'leverancier'
                      }/${relID}/transport/opdracht`
                    }
                  />
                );
              }}
            />

            <SelectionState
              selection={urlState.selectie}
              onSelectionChange={(value) => setUrlStateSync('selectie', value as number[])}
            />
            <SortingState defaultSorting={[]} />
            <IntegratedSorting />
            <VirtualTable
              messages={{ noData: 'Er zijn locaties gevonden voor de ingestelde filters' }}
            />
            <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
            <TableHeaderRow showSortingControls />

            <RowDetailState defaultExpandedRowIds={[]} />

            <TableRowDetail
              contentComponent={DetailComp}
              toggleCellComponent={DXTableToggleCellComponent}
            />

            <EditingState
              onCommitChanges={(changes) => {
                if (changes.deleted === undefined) {
                  return;
                }
                const deleted = changes.deleted;
                const id = deleted[deleted.length - 1];
                alert(id);
              }}
              // onEditingRowIdsChange={(rowIds) => {
              //   const id = rowIds[rowIds.length - 1] as number;
              //   setWijzigenRegelID(id);
              // }}
            />
            <TableEditColumn
              width={35}
              showEditCommand
              cellComponent={DXTableEditColumnCellComponent}
              commandComponent={DXTableEditColumnCommandComponent}
            />

            <TableSelection cellComponent={DXTableCheckboxComponent} />
          </Grid>
        </GridStyleWrapper>
      )}

      {/* {wijzigenRegelID !== null && (
        <WijzigenReferentieDialoog
          open
          trsRegID={wijzigenRegelID}
          onSuccess={() => {
            setWijzigenRegelID(null);
            ophalenRegels();
          }}
          onAnnuleren={() => setWijzigenRegelID(null)}
        />
      )} */}
    </>
  );
};

export default withRouter(Locaties);
