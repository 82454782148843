import permissie from './permissie';
import rol from './rol';
import permissieXRol from './permissieXRols';
import gebruikers from './rolXAspGebrs';

const autorisatie = {
  permissie,
  rol,
  permissieXRol,
  gebruikers,
};

export default autorisatie;
