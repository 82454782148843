import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import React, { useCallback, useEffect, useMemo, useState, useContext } from 'react';
import ModalHeader from 'react-bootstrap/ModalHeader';
import Dialoog from '../../../../../components/dialogen/Dialoog';
import LoadingSpinner from '../../../../../components/Gedeeld/LoadingSpinner';
import IDialoogProps from '../../../../../core/IDialoogProps';
import { ModalBody, ModalFooter, ModalTitle } from 'react-bootstrap';
import nameof from '../../../../../core/nameOf';
import NumeriekVeld from '../../../../../components/formulier/NumeriekVeld';
import api from '../../../../../api';
import { IOphalenMutatiesResultElement } from '../../../../../../../shared/src/api/v2/magazijn/mutatie';
import { IOphalenVoorraadinfoResultElement } from '../../../../../../../shared/src/api/v2/voorraad';
import { Kleur as EKleur } from '../../../../../bedrijfslogica/constanten';
import { RootStoreContext } from '../../../../../stores/RootStore';
import { EResultType } from '../../../../../stores/CheckStore';

interface IProps extends IDialoogProps<null> {
  id: number;
}

interface IFormikValues {
  aantal: number;
}
const veldnamen: Record<keyof IFormikValues, string> = {
  aantal: 'Aantal minder ingeslagen',
};

const CorrectieDialoog: React.FC<IProps> = (props) => {
  const { dialoogIndex, onAnnuleren, onSuccess } = props;
  const { checkStore } = useContext(RootStoreContext);

  const initialValues = useMemo<IFormikValues>(() => {
    return {
      aantal: 1,
    };
  }, []);

  const [inslagmutatie, setInslagmutatie] = useState<IOphalenMutatiesResultElement | null>(null);
  const [voorraadinfo, setVoorraadinfo] = useState<IOphalenVoorraadinfoResultElement | null>(null);

  const ophalenInslagmutatie = useCallback(async () => {
    // Inslagmutatie ophalen om de voorraadinfo te bepalen
    const inslagmutatie = (
      await api.v2.magazijn.mutatie.ophalenMutaties({
        filterSchema: { filters: [{ naam: 'IDS', data: [props.id] }] },
      })
    ).mutaties[0];

    setInslagmutatie(inslagmutatie);
  }, [props.id]);

  useEffect(() => {
    ophalenInslagmutatie();
  }, [ophalenInslagmutatie]);

  const ophalenVoorraadInfo = useCallback(async () => {
    // Voorraadinfo om te bepalen of de vrije voorraad na correctie negatief wordt
    if (inslagmutatie === null) {
      return;
    }

    const voorraadinfo = (
      await api.v2.voorraad.ophalenVoorraadinfo({
        magID: inslagmutatie.MagID,
        typeIDs: [inslagmutatie.producttype!.TypeID],
      })
    ).voorraad[0];

    setVoorraadinfo(voorraadinfo);
  }, [inslagmutatie]);

  useEffect(() => {
    ophalenVoorraadInfo();
  }, [ophalenVoorraadInfo]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      if (
        (
          await checkStore.bevestigen({
            inhoud: 'Correctie doorvoeren?',
          })
        ).type === EResultType.Annuleren
      ) {
        actions.setSubmitting(false);
        return;
      }

      const params = {
        id: props.id,
        aantal: values.aantal!,
      };

      const result = await api.v2.inkoop.opdracht.uitstaand.corrigerenInslagMutaties(params);
      if (result.aantal === 0) {
        alert('Er zijn geen producten gevonden om de correctie toe te passen');
      }

      actions.setSubmitting(false);
      onSuccess(null);
    },
    [],
  );

  return (
    <Dialoog index={dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Correctie inslag</ModalTitle>
      </ModalHeader>

      {voorraadinfo === null ? (
        <LoadingSpinner />
      ) : (
        <Formik<IFormikValues>
          onSubmit={handleSubmit}
          enableReinitialize
          initialValues={initialValues}
          isInitialValid
          //   validationSchema={validationSchema}
          render={(formikProps: FormikProps<IFormikValues>) => {
            const { submitForm, isSubmitting, isValid, values } = formikProps;
            return (
              <>
                <ModalBody>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-12">
                        <div className="row d-flex align-items-end">
                          <div className="col-6">
                            <label>{veldnamen.aantal}</label>
                            <Field
                              name={nameof<IFormikValues>('aantal')}
                              render={(fieldProps: FieldProps<IFormikValues>) => {
                                const { field, form } = fieldProps;
                                return (
                                  <NumeriekVeld
                                    waarde={field.value}
                                    onChange={(x) => form.setFieldValue(field.name, x)}
                                    min={1}
                                    max={100}
                                  />
                                );
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-12 mt-3">
                        {voorraadinfo.aantalVrij - values.aantal < 0 ? (
                          <span style={{ color: EKleur.Rood }}>
                            Bij dit aantal ontstaat er een voorraadtekort van{' '}
                            {values.aantal - voorraadinfo.aantalVrij}.
                          </span>
                        ) : (
                          <span style={{ color: EKleur.Groen }}>
                            Bij dit aantal ontstaat er geen voorraadtekort.
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </ModalBody>
                <ModalFooter className="d-flex flex-row justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    style={{ width: 100 }}
                    disabled={isSubmitting || !isValid}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={onAnnuleren}
                    style={{ width: 100 }}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Dialoog>
  );
};

export default CorrectieDialoog;
