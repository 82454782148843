import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import api from '../../../../../../../api';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../../../../../stores/RootStore';
import { EResultType } from '../../../../../../../stores/CheckStore';
import LoadingSpinner from '../../../../../../../components/Gedeeld/LoadingSpinner';
import MenuLayout from '../../../../../../../components/MenuLayout';
import { RouteComponentProps, withRouter } from 'react-router';
import useUrlState from '../../../../../../../core/useUrlState';
import SelectieVak from '../../../../../../../components/SelectieVak';
import { IOphalenVerkoopVoorstellenResultElement } from '../../../../../../../../../shared/src/api/v2/product/verkoop';
import { Kleur } from '../../../../../../../bedrijfslogica/constanten';
import {
  IconInformatie,
  IconSend,
  IconToevoegen,
  IconVerwijderen,
  IconVlag,
} from '../../../../../../../components/Icons';
import {
  DXTableCheckboxComponent,
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../../../../helpers/dxTableGrid';
import {
  Grid,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  DataTypeProvider,
  EditingState,
  SelectionState,
  VirtualTable as VirtualTableBase,
} from '@devexpress/dx-react-grid';
import FormatteerBedrag from '../../../../../../../components/MutatieBedrag';
import nameOf from '../../../../../../../core/nameOf';
import { format } from 'date-fns';
import { maandenNaarJaren } from '../../../../../../../bedrijfslogica/teksten';
import VerwerkenDialoog from './VerwerkenDialoog';
import ProductinfoDialoog from '../../../../../../../components/product/ProductinfoDialoog';
import WijzigenVerkoopvoorstelDialoog from '../../../../../../../components/verkoopvoorstel/WijzigenVerkoopvoorstelDialoog';
import _ from 'lodash';
import KeuzeDialoog, {
  EKeuzeType,
  IOptie,
} from '../../../../../../../components/dialogen/KeuzeDialoog';
import { EKanaal } from '../../../../../../../bedrijfslogica/enums';
import WijzigenStatusDialoog from '../../../../../../../components/verkoopvoorstel/WijzigenStatusDialoog';
import ContractVisualisatie from '../../../../../../../components/entiteitVisualisaties/ContractVisualisatie';
import VersturenVoorstelDialoog from './VersturenVoorstelDialoog';
import VerkoopvoorstelInfoDialoog from '../../../../../../../components/verkoopvoorstel/VerkoopvoorstelInfoDialoog';

interface IProps extends RouteComponentProps {
  relID: number;
}

export interface IVerwerkenDialoogState {
  open: boolean;
}

export interface IProductinfoDialoogState {
  id: number;
}

export interface IVersturenDialoogState {
  open: boolean;
}
export interface IWijzigenVerkoopvoorstelDialoogState {
  id: number;
}

export interface IVersturenVoorstelDialoogState {
  id: number;
}

export interface IWijzigenStatusDialoogState {
  ids: number[];
}

export interface IVerkoopvoorstelInfoDialoogState {
  id: number;
}

export interface IUrlState {
  selectie: number[];
  verwerkenDialoogState: IVerwerkenDialoogState | null;
  wijzigenStatusDialoogState: IWijzigenStatusDialoogState | null;
  verkoopvoorstelInfoDialoogState: IVerkoopvoorstelInfoDialoogState | null;
}

export const defaultUrlState: IUrlState = {
  selectie: [],
  verwerkenDialoogState: null,
  wijzigenStatusDialoogState: null,
  verkoopvoorstelInfoDialoogState: null,
};

const OverzichtComp: React.FC<IProps> = observer((props) => {
  const { klantkaartStore, checkStore } = useContext(RootStoreContext);
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);

  const [voorstellen, setVoorstellen] = useState<IOphalenVerkoopVoorstellenResultElement[] | null>(
    null,
  );
  const [
    productinfoDialoogState,
    setProductinfoDialoogState,
  ] = useState<IProductinfoDialoogState | null>(null);
  const [
    wijzigenVerkoopvoorstelDialoogState,
    setWijzigenVerkoopvoorstelDialoogState,
  ] = useState<IWijzigenVerkoopvoorstelDialoogState | null>(null);

  const [
    versturenVoorstelDialoogState,
    setVersturenVoorstelDialoogState,
  ] = useState<IVersturenVoorstelDialoogState | null>(null);

  // const [versturenDialoogState, setVersturenDialoogState] = useState<IVersturenDialoogState | null>(
  //   null,
  // );

  const relID = props.relID;

  const ophalenVerkoopvoorstellen = useCallback(async () => {
    const { voorstellen } = await api.v2.product.verkoop.ophalenVerkoopvoorstellen({
      filterSchema: { filters: [{ naam: 'REL_IDS', data: [relID] }] },
    });

    const voorstellenGesorteerd = _.orderBy(voorstellen, ['GeldigVan', 'ID'], ['desc', 'desc']);
    setVoorstellen(voorstellenGesorteerd);
  }, [relID]);

  useEffect(() => {
    ophalenVerkoopvoorstellen();
  }, [ophalenVerkoopvoorstellen]);

  const handleMakenVoorstellen = useCallback(async () => {
    const resultaat = await checkStore.bevestigen({
      inhoud: (
        <span>
          Verkoopvoorstel(len) maken ?<br />
          <br />
          Let op: dit geldt voor alle producten van deze relatie met de status Verhuur. Bij het
          Contractoverzicht kun je dit desgewenst voor specifieke producten doen.
        </span>
      ),
    });
    if (resultaat.type === EResultType.Annuleren) {
      return;
    }

    const result = await api.v2.product.verkoop.selecterenVerkoopProducten({
      peildatum: null,
      filterSchema: { filters: [{ naam: 'REL_IDS', data: [relID] }] },
    });

    await api.v2.product.verkoop.makenVerkoopVoorstellen({
      relID,
      persID: null,
      prodIDs: result.prodIDs,
      voorsteldatum: null,
    });

    ophalenVerkoopvoorstellen();
  }, [urlState.selectie]);

  const handleVerwijderen = useCallback(async () => {
    const params = { ids: urlState.selectie };
    const checkData = await api.v2.product.verkoop.checkVerwijderenVerkoopVoorstellen(params);
    const checkResult = await checkStore.controleren({ checkData });
    if (checkResult.type === EResultType.Annuleren) {
      return;
    }

    const resultaat = await checkStore.bevestigen({
      inhoud: `Verkoopvoorstellen verwijderen ?`,
    });
    if (resultaat.type === EResultType.Annuleren) {
      return;
    }

    await api.v2.product.verkoop.verwijderenVerkoopVoorstellen(params);

    setUrlStateSync('selectie', []);

    ophalenVerkoopvoorstellen();
  }, [urlState.selectie]);

  const handleVersturen = useCallback(
    async (kanaal: EKanaal, sjabID: number) => {
      const params = {
        relID,
        persID: null,
        sjabID,
        kanalen: [kanaal],
      };
      const checkData = await api.v2.product.verkoop.checkVersturenVerkoopVoorstellen(params);
      const checkResult = await checkStore.controleren({ checkData });
      if (checkResult.type === EResultType.Annuleren) {
        return;
      }

      const resultaat = await checkStore.bevestigen({
        inhoud: `Verkoopvoorstellen versturen?`,
      });
      if (resultaat.type === EResultType.Annuleren) {
        return;
      }

      await api.v2.product.verkoop.versturenVerkoopVoorstellen(params);

      setUrlStateSync('selectie', []);

      ophalenVerkoopvoorstellen();
    },
    [urlState.selectie],
  );

  const sleutelExtractor = useCallback(
    (row: IOphalenVerkoopVoorstellenResultElement) => row.ID,
    [],
  );
  const kolommen = useMemo<TypedColumn<IOphalenVerkoopVoorstellenResultElement>[]>(
    () => [
      {
        name: 'Merknaam',
        title: 'Merk',
      },
      {
        name: 'Typenaam',
        title: 'Type',
      },
      {
        name: 'ProductsoortnaamKort',
        title: 'Srt.',
      },
      // {
      //   name: 'Referentiecode',
      //   title: 'Ref.code',
      // },
      {
        name: 'leeftijd',
        title: 'Lft.',
      },
      {
        name: '__product' as any,
        title: ' ',
      },
      {
        name: '__contractnummer' as any,
        title: 'Contract',
      },
      {
        name: 'Bedrag',
        title: 'Bedrag',
      },
      {
        name: 'KostenVtb',
        title: 'Kosten VTB',
      },
      {
        name: '__totaalBedrag' as any,
        title: 'Totaal',
      },
      {
        name: '__infoVerkoopvoorstel' as any,
        title: ' ',
      },
      // {
      //   name: 'GeldigVan',
      //   title: 'Datum',
      // },
      {
        name: 'GeldigTot',
        title: 'Geldig tot',
      },
      {
        name: 'Status',
        title: 'Status',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<
    TypedTableColumnWidthInfo<IOphalenVerkoopVoorstellenResultElement>[]
  >(
    () => [
      {
        columnName: 'ProductsoortnaamKort',
        width: 50,
      },
      {
        columnName: 'Merknaam',
        width: 90,
      },
      {
        columnName: 'Typenaam',
        width: 135,
      },
      // {
      //   columnName: 'Referentiecode',
      //   width: 75,
      // },
      {
        columnName: 'leeftijd',
        width: 75,
      },
      {
        columnName: '__product' as any,
        width: 50,
      },
      {
        columnName: '__contractnummer' as any,
        width: 80,
      },
      {
        columnName: 'Bedrag',
        width: 80,
      },
      {
        columnName: 'KostenVtb',
        width: 90,
      },
      {
        columnName: '__totaalBedrag' as any,
        width: 100,
      },
      // {
      //   columnName: 'GeldigVan',
      //   width: 90,
      // },
      {
        columnName: 'GeldigTot',
        width: 90,
      },
      {
        columnName: 'Status',
        width: 120,
      },
      {
        columnName: '__infoVerkoopvoorstel' as any,
        width: 50,
      },
    ],
    [],
  );

  const kolomExtensies: VirtualTableBase.ColumnExtension[] = useMemo(() => {
    return [
      // { columnName: 'Bedrag', align: 'right' },
      // { columnName: '__totaalBedrag', align: 'right' },
    ];
  }, []);

  const geenData = {
    noData: 'Geen verkoopvoorstellen aanwezig',
  };

  const kanaalOpties = useMemo<IOptie<EKanaal>[]>(() => {
    return [
      {
        id: EKanaal.Email,
        label: 'Email',
      },

      {
        id: EKanaal.SMS,
        label: 'SMS',
      },
    ].filter((x) => x !== null) as IOptie<EKanaal>[];
  }, []);

  return (
    <>
      {voorstellen === null ? (
        <LoadingSpinner />
      ) : (
        <MenuLayout
          menu={
            <>
              <div className="mt-2 d-flex align-items-center">
                <button
                  className="btn btn-sm btn-light"
                  style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                  // disabled={urlState.selectie.length === 0}
                  onClick={() => setVersturenVoorstelDialoogState({ id: urlState.selectie[0] })}
                >
                  <IconSend style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                  Versturen voorstel
                </button>
                <button
                  className="btn btn-sm btn-light ml-3"
                  style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                  disabled={urlState.selectie.length === 0}
                  onClick={async () => {
                    const params = {
                      ids: urlState.selectie,
                      status: 0, // dummie
                    };

                    const checkData = await api.v2.product.verkoop.checkWijzigenStatusVerkoopvoorstellen(
                      params,
                    );
                    if (
                      (await checkStore.controleren({ checkData })).type === EResultType.Annuleren
                    ) {
                      return;
                    }

                    return setUrlStateSync('wijzigenStatusDialoogState', {
                      ids: urlState.selectie,
                    });
                  }}
                >
                  {/* <IconVerwijderen style={{ width: 16, height: 16, fill: Kleur.Grijs }} /> */}
                  Status wijzigen
                </button>
                <button
                  className="btn btn-sm btn-light ml-3"
                  style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                  disabled={urlState.selectie.length === 0}
                  onClick={async () => handleVerwijderen()}
                >
                  <IconVerwijderen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                  Verwijderen
                </button>
                <button
                  className="btn btn-sm btn-light ml-3"
                  style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                  disabled={urlState.selectie.length === 0}
                  onClick={async () => {
                    const params = {
                      relID: props.relID,
                      ids: urlState.selectie,
                    };

                    const checkData = await api.v2.product.verkoop.checkVerwerkenVerkoopVoorstellen(
                      params,
                    );
                    if (
                      (await checkStore.controleren({ checkData })).type === EResultType.Annuleren
                    ) {
                      return;
                    }

                    return setUrlStateSync('verwerkenDialoogState', { open: true });
                  }}
                >
                  <IconVlag style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                  Verwerken
                </button>
                <button
                  className="btn btn-sm btn-light ml-3"
                  style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                  disabled={urlState.selectie.length === 0}
                  onClick={async () => {
                    const params = {
                      ids: urlState.selectie,
                    };

                    const checkData = await api.v2.product.verkoop.checkVersturenBevestigingVerkoopvoorstellen(
                      params,
                    );
                    if (
                      (await checkStore.controleren({ checkData })).type === EResultType.Annuleren
                    ) {
                      return;
                    }

                    if (
                      (
                        await checkStore.bevestigen({
                          inhoud: <span>Wil je een bevestiging van de aankoop versturen?</span>,
                        })
                      ).type === EResultType.Annuleren
                    ) {
                      return;
                    }

                    await api.v2.product.verkoop.versturenBevestigingVerkoopvoorstellen(params);

                    return;
                  }}
                >
                  <IconSend style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                  Bevestiging sturen
                </button>
                {/* <button
                  className="btn btn-sm btn-light ml-2"
                  style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                  // disabled={selectie.length === 0}
                  onClick={() => handleMakenVoorstellen()}
                >
                  <IconToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                  Voorstel maken voor alle lopende contracten
                </button> */}
              </div>

              <div className="mt-3">
                <SelectieVak
                  aantal={urlState.selectie.length}
                  totaalAantal={voorstellen.length}
                  onChange={(alles) => {
                    if (alles) {
                      setUrlStateSync(
                        'selectie',
                        voorstellen.map((x) => x.ID),
                      );
                    } else {
                      setUrlStateSync('selectie', []);
                    }
                  }}
                  entiteitEnkelvoud="voorstel"
                  entiteitMeervoud="voorstellen"
                />
              </div>
            </>
          }
          body={
            <>
              <GridStyleWrapper height="calc(100vh - 150px)">
                <Grid getRowId={sleutelExtractor} columns={kolommen} rows={voorstellen}>
                  <DataTypeProvider
                    for={['__product']}
                    formatterComponent={(props) => {
                      return (
                        <a
                          href="#"
                          // style={{ color: Kleur.DonkerGrijs }}
                          onClick={() => {
                            setProductinfoDialoogState({ id: props.row.ProdID });
                          }}
                        >
                          <IconInformatie style={{ width: 15, height: 15, fill: Kleur.Blauw }} />
                        </a>
                      );
                    }}
                  />

                  <DataTypeProvider
                    for={['__infoVerkoopvoorstel']}
                    formatterComponent={(props) => {
                      return (
                        <a
                          href="#"
                          // style={{ color: Kleur.DonkerGrijs }}
                          onClick={() => {
                            setUrlStateSync('verkoopvoorstelInfoDialoogState', {
                              id: props.row.ID,
                            });
                          }}
                        >
                          <IconInformatie style={{ width: 15, height: 15, fill: Kleur.Blauw }} />
                        </a>
                      );
                    }}
                  />

                  <DataTypeProvider
                    for={['Status']}
                    formatterComponent={(props) => {
                      if (props.row.VerwerktOp !== null) {
                        return <span>Verwerkt</span>;
                      }

                      if (props.value === 1) {
                        return <span style={{ color: Kleur.Groen }}>Geaccepteerd</span>;
                      }
                      if (props.value === 2) {
                        return <span style={{ color: Kleur.Rood }}>Afgewezen</span>;
                      }
                      return <span>Onbekend</span>;
                    }}
                  />

                  <DataTypeProvider
                    for={['leeftijd']}
                    formatterComponent={(props) => {
                      return <span>{maandenNaarJaren(props.value)}</span>;
                    }}
                  />

                  <DataTypeProvider
                    for={['Bedrag']}
                    formatterComponent={(props) => {
                      return <FormatteerBedrag bedrag={props.value} />;
                    }}
                  />

                  <DataTypeProvider
                    for={['KostenVtb']}
                    formatterComponent={(props) => {
                      const rij: IOphalenVerkoopVoorstellenResultElement = props.row;

                      if (props.value === null) {
                        return <span></span>;
                      }
                      return (
                        <span className="d-flex">
                          {rij.KostenVtbNietBerekenen ? '(' : ''}
                          <FormatteerBedrag bedrag={props.value} />
                          {rij.KostenVtbNietBerekenen ? ')' : ''}
                        </span>
                      );
                    }}
                  />

                  <DataTypeProvider
                    for={['__totaalBedrag']}
                    formatterComponent={(props) => {
                      const rij: IOphalenVerkoopVoorstellenResultElement = props.row;

                      return (
                        <FormatteerBedrag
                          bedrag={
                            rij.Bedrag +
                            (rij.KostenVtb !== null && !rij.KostenVtbNietBerekenen
                              ? rij.KostenVtb
                              : 0)
                          }
                        />
                      );
                    }}
                  />

                  <DataTypeProvider
                    for={[nameOf<IOphalenVerkoopVoorstellenResultElement>('GeldigVan')]}
                    formatterComponent={(formatterProps) => {
                      const rij: IOphalenVerkoopVoorstellenResultElement = formatterProps.row;
                      return <span>{format(new Date(rij.GeldigVan), 'dd-MM-yyyy')}</span>;
                    }}
                  />

                  <DataTypeProvider
                    for={[nameOf<IOphalenVerkoopVoorstellenResultElement>('GeldigTot')]}
                    formatterComponent={(formatterProps) => {
                      const rij: IOphalenVerkoopVoorstellenResultElement = formatterProps.row;
                      if (new Date(rij.GeldigTot) < new Date()) {
                        return (
                          <span style={{ color: Kleur.Rood }}>
                            {format(new Date(rij.GeldigTot), 'dd-MM-yyyy')}
                          </span>
                        );
                      }
                      return <span>{format(new Date(rij.GeldigTot), 'dd-MM-yyyy')}</span>;
                    }}
                  />

                  <SelectionState
                    selection={urlState.selectie}
                    onSelectionChange={(x) => {
                      return setUrlStateSync('selectie', x as number[]);
                    }}
                  />

                  <DataTypeProvider
                    for={['__contractnummer']}
                    formatterComponent={(formatterProps) => {
                      const rij: IOphalenVerkoopVoorstellenResultElement = formatterProps.row;

                      const contract = rij.contract;

                      if (contract === null) {
                        return <span></span>;
                      }

                      return <ContractVisualisatie cntID={contract.CntID} />;
                    }}
                  />

                  <VirtualTable messages={geenData} columnExtensions={kolomExtensies} />

                  <EditingState
                    onCommitChanges={async (changes) => {
                      if (changes.deleted === undefined) {
                        return;
                      }
                      const deleted = changes.deleted;
                      const id = deleted[deleted.length - 1] as number;
                      // alert(id);
                    }}
                    onEditingRowIdsChange={(rowIds) => {
                      const id = rowIds[rowIds.length - 1] as number;
                      // alert(id);
                      setWijzigenVerkoopvoorstelDialoogState({ id });
                    }}
                  />

                  <TableEditColumn
                    width={35}
                    showEditCommand
                    // showDeleteCommand
                    cellComponent={DXTableEditColumnCellComponent}
                    commandComponent={DXTableEditColumnCommandComponent}
                  />
                  <TableColumnResizing columnWidths={kolomBreedtes} />
                  <TableHeaderRow />
                  <TableSelection cellComponent={DXTableCheckboxComponent} />
                </Grid>
              </GridStyleWrapper>
            </>
          }
        />
      )}

      {urlState.verwerkenDialoogState !== null && (
        <VerwerkenDialoog
          open
          relID={relID}
          persID={null}
          verkVrstIDs={urlState.selectie}
          onSuccess={() => {
            ophalenVerkoopvoorstellen();
            setUrlStateSync('verwerkenDialoogState', null);
          }}
          onAnnuleren={() => setUrlStateSync('verwerkenDialoogState', null)}
        />
      )}
      {productinfoDialoogState !== null && (
        <ProductinfoDialoog
          open
          id={productinfoDialoogState.id}
          onSuccess={() => {
            setProductinfoDialoogState(null);
          }}
          onAnnuleren={() => setProductinfoDialoogState(null)}
        />
      )}
      {wijzigenVerkoopvoorstelDialoogState !== null && (
        <WijzigenVerkoopvoorstelDialoog
          open
          id={wijzigenVerkoopvoorstelDialoogState.id}
          onSuccess={() => {
            ophalenVerkoopvoorstellen();
            setWijzigenVerkoopvoorstelDialoogState(null);
          }}
          onAnnuleren={() => setWijzigenVerkoopvoorstelDialoogState(null)}
        />
      )}
      {versturenVoorstelDialoogState !== null && (
        <VersturenVoorstelDialoog
          open
          id={versturenVoorstelDialoogState.id}
          onSuccess={(x) => {
            handleVersturen(x.verzendwijze, x.sjabID);
            ophalenVerkoopvoorstellen();
            setVersturenVoorstelDialoogState(null);
          }}
          onAnnuleren={() => setVersturenVoorstelDialoogState(null)}
        />
      )}
      {urlState.wijzigenStatusDialoogState !== null && (
        <WijzigenStatusDialoog
          open
          ids={urlState.wijzigenStatusDialoogState.ids}
          onSuccess={() => {
            ophalenVerkoopvoorstellen();
            setUrlStateSync('wijzigenStatusDialoogState', null);
          }}
          onAnnuleren={() => setUrlStateSync('wijzigenStatusDialoogState', null)}
        />
      )}
      {urlState.verkoopvoorstelInfoDialoogState !== null && (
        <VerkoopvoorstelInfoDialoog
          open
          id={urlState.verkoopvoorstelInfoDialoogState.id}
          onSuccess={() => {}}
          onAnnuleren={() => setUrlStateSync('verkoopvoorstelInfoDialoogState', null)}
        />
      )}
    </>
  );
});

const Overzicht = withRouter(OverzichtComp);
export default Overzicht;
