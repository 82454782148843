import React, { useMemo } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { genereerUrlStateQueryParam } from '../../../core/useUrlState';
import { IUrlState } from '../../Communicatie/Contactverzoek';
import { IActieProps } from '../index';

interface IData {
  werkbonnenIDs: number[];
}

const WERKBONNEN_ONTVANGEN: React.VoidFunctionComponent<IActieProps> = (props: IActieProps) => {
  const data = useMemo(() => JSON.parse(props.controltaak.Data!) as IData, [
    props.controltaak.Data,
  ]);

  return (
    <a
      href="#"
      onClick={() => {
        props.history.push(`/service/werkbonnen`);
      }}
    >
      Naar de werkbonnen
    </a>
  );
};

export default WERKBONNEN_ONTVANGEN;
