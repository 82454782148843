import { ERegelboekstukType, ERegelIDType, ERegelstate, Regel } from './types';
import { v4 as uuidv4 } from 'uuid';
import { IOphalenFacturenResultElement } from '../../../../../../shared/src/api/v2/inkoopfactuur';
import { IOphalenGrootboekenResultElement } from '../../../../../../shared/src/api/v2/boekhouding/boeking/grootboek';
import { IOphalenDagboekSoortenResultElement } from '../../../../../../shared/src/api/v2/boekhouding/boeking/dagboek';
import { IOphalenRelatiesResultElementV2 } from '../../../../../../shared/src/api/v2/relatie';
import { IOphalenFacturenBasisResultElement } from '../../../../../../shared/src/api/v2/factuur';
import { EDagboeksoort } from '../../../../bedrijfslogica/enums';

export const maakNieuweRegel = (): Regel => ({
  id: {
    type: ERegelIDType.Lokaal,
    waarde: uuidv4(),
  },
  state: ERegelstate.Muteren,
  relID: null,
  btwSoortID: null,
  grootboekID: null,
  omschrijving: null,
  regelboekstuk: null,
  werkkostenregelingID: null,
  storno: false,
  bedrag: 0,
});

export interface IMaakInkoopfactuurRegelParams {
  base: Partial<Regel>;
  inkoopfactuur: IOphalenFacturenResultElement;
  grootboek: IOphalenGrootboekenResultElement;
  dagboekSoort: IOphalenDagboekSoortenResultElement;
  relatie: IOphalenRelatiesResultElementV2;
}

export const maakInkoopfactuurRegel = (params: IMaakInkoopfactuurRegelParams): Regel => {
  const { base, inkoopfactuur, dagboekSoort, grootboek, relatie } = params;

  let bedrag;
  if (dagboekSoort.NaamEnum === 'INKOOP' || dagboekSoort.NaamEnum === 'VERKOOP') {
    bedrag = -inkoopfactuur.Bedrag;
  } else {
    // Memoriaal of Bank
    bedrag =
      grootboek.Soort === 'A' || grootboek.Soort === 'L'
        ? -inkoopfactuur.Bedrag
        : inkoopfactuur.Bedrag;
  }

  const storno = dagboekSoort.NaamEnum === 'INKOOP' && inkoopfactuur.Bedrag < 0;

  const btwSoortID = bepaalBTWSoortIDVoorRegel({
    grootboek,
    dagboekSoort,
    relatie,
  });

  return {
    id: base.id ?? {
      type: ERegelIDType.Lokaal,
      waarde: uuidv4(),
    },
    state: base.state ?? ERegelstate.Weergave,
    relID: inkoopfactuur.RelID,
    btwSoortID,
    grootboekID: grootboek.ID,
    omschrijving: null,
    regelboekstuk: {
      type: ERegelboekstukType.Inkoopfactuur,
      inkFactID: inkoopfactuur.InkFactID,
    },
    werkkostenregelingID: null,
    storno,
    bedrag,
  };
};

export interface IMaakFactuurRegelParams {
  base: Partial<Regel>;
  factuur: IOphalenFacturenBasisResultElement;
  grootboek: IOphalenGrootboekenResultElement;
  dagboekSoort: IOphalenDagboekSoortenResultElement;
  relatie: IOphalenRelatiesResultElementV2;
}

export const maakFactuurRegel = (params: IMaakFactuurRegelParams): Regel => {
  const { base, factuur, dagboekSoort, grootboek, relatie } = params;

  let bedrag;
  if (dagboekSoort.NaamEnum === 'INKOOP' || dagboekSoort.NaamEnum === 'VERKOOP') {
    bedrag = factuur.Bedrag;
  } else {
    // Memoriaal of Bank
    bedrag = grootboek.Soort === 'A' || grootboek.Soort === 'L' ? -factuur.Bedrag : factuur.Bedrag;
  }

  const storno = dagboekSoort.NaamEnum === 'VERKOOP' && factuur.Bedrag < 0;

  const btwSoortID = bepaalBTWSoortIDVoorRegel({
    grootboek,
    dagboekSoort,
    relatie,
  });

  return {
    id: base.id ?? {
      type: ERegelIDType.Lokaal,
      waarde: uuidv4(),
    },
    state: base.state ?? ERegelstate.Weergave,
    relID: factuur.RelID,
    btwSoortID,
    grootboekID: grootboek.ID,
    omschrijving: null,
    regelboekstuk: {
      type: ERegelboekstukType.Factuur,
      factID: factuur.FactID,
    },
    werkkostenregelingID: null,
    storno,
    bedrag,
  };
};

export interface IBepaalBTWSoortIDVoorRegelParams {
  // regel: Partial<Regel>;
  dagboekSoort: IOphalenDagboekSoortenResultElement;
  grootboek?: IOphalenGrootboekenResultElement;
  relatie?: IOphalenRelatiesResultElementV2;
}

export const bepaalBTWSoortIDVoorRegel = (
  params: IBepaalBTWSoortIDVoorRegelParams,
): number | null => {
  const { dagboekSoort, grootboek, relatie } = params;

  if (dagboekSoort.NaamEnum === EDagboeksoort.MEMORIAAL) {
    return null;
  }

  const standaard = () => {
    if (dagboekSoort.NaamEnum === EDagboeksoort.BANK) {
      if (grootboek?.Soort === 'A' || grootboek?.Soort === 'P') {
        return dagboekSoort.BtwSrtID;
      }
    }

    return grootboek?.BtwSrtID ?? dagboekSoort.BtwSrtID;
  };

  if (dagboekSoort.NaamEnum === EDagboeksoort.BANK) {
    return relatie?.crediteur?.BtwSrtBank_BtwSrtID ?? standaard();
  } else if (dagboekSoort.NaamEnum === EDagboeksoort.INKOOP) {
    if (relatie === undefined) {
      return null;
    }
    return relatie.crediteur?.BtwSrtInkoop_BtwSrtID ?? standaard();
  }

  return standaard();
};
