import React, { useState, useEffect, useMemo, useRef, useCallback, useContext } from 'react';
import BestandDragAndDropZone, {
  IBestandDragAndZoneBestand,
} from '../../../../../../components/BestandDragAndDropZone';
import { Kleur } from '../../../../../../bedrijfslogica/constanten';
import InactiefOverlay from '../../../../../../components/InactiefOverlay';
import LoadingSpinner from '../../../../../../components/Gedeeld/LoadingSpinner';
import api from '../../../../../../api';
import { InspectorNodeRenderer, ObjectInspector } from 'react-inspector';
import { SjabloonRowDetailContext } from '../../index';
import { SjablonenContext } from '../../../index';
import {
  IOphalenVoorbeeldContextDatasResult,
  IResolveRawParams,
} from '../../../../../../../../shared/src/api/v2/Sjabloon/sjabloon';
import { ObjectLabel } from 'react-inspector';
import UitlegTooltip from '../../../../../../components/formulier/UitlegTooltip';
import { IconDownload, IconUitvergroten } from '../../../../../../components/Icons';
import CodeBewerker from '../../../../../../components/formulier/CodeBewerker';
import ElementUitvergrotenWrapper from '../../../../../../components/ElementUitvergrotenWrapper';
import downloadUrl from '../../../../../../core/downloadUrl';

export interface ITaalFormulierTabbladProps {
  taalID: number;
  comKanNaamEnum: string;
  comKanID: number;
  sjabBasisID: number | null;
  formValues: IFormValues;
  onChange: (values: IFormValues) => void;
}

export interface IFormValues {
  onderwerp: string;
  inhoud: string;
  toepassen: boolean;
  bestanden: IBestandDragAndZoneBestand[];
  systeemDataContext: string;
}

interface IVoorbeeldWeergaveState {
  error?: string;
  output?: string;
}

const bepaalVoorbeeldWeergave = async (
  input: string,
  comKanID: number,
  sjabBasisID: number | null,
  taalID: number,
  contexten: IResolveRawParams['contexten'],
): Promise<IVoorbeeldWeergaveState> => {
  try {
    if (sjabBasisID !== null) {
      const result = await api.v2.sjabloon.resolveSjabloonBasis({
        sjabBasisID,
        inhoud: input,
      });
      input = result.output;
    }

    const resolveSubstitutieResult = await api.v2.sjabloon.resolveSubstitutie({
      comKanID,
      input,
      taalID,
    });
    const resolveRawResult = await api.v2.sjabloon.resolveRaw({
      data: resolveSubstitutieResult.output,
      contexten,
    });
    if (resolveRawResult.error !== undefined) {
      return {
        error: resolveRawResult.error,
      };
    }
    return {
      output: resolveRawResult.output,
    };
  } catch (err) {
    return {
      error: err.message,
    };
  }
};

interface IProps extends ITaalFormulierTabbladProps {}

const TaalFormulier: React.FC<IProps> = (props) => {
  const { sjabloon } = useContext(SjabloonRowDetailContext)!;
  const { contexten } = useContext(SjablonenContext)!;

  const [
    voorbeeldContextDatasResult,
    setVoorbeeldContextDatasResult,
  ] = useState<IOphalenVoorbeeldContextDatasResult | null>(null);
  useEffect(() => {
    (async () => {
      const result = await api.v2.sjabloon.ophalenVoorbeeldContextdatas({
        sjabID: sjabloon.sjabloon.ID,
        taalID: props.taalID,
        contextBepaalData: sjabloon.sjabloon.sjabCtxs.map((sjabCtx) => {
          const ctx = contexten.find((ctx) => ctx.SjabCtxID === sjabCtx.sjabCtxID)!;
          return {
            alias: sjabCtx.alias ?? undefined,
            naamEnum: ctx.NaamEnum,
          };
        }),
      });
      setVoorbeeldContextDatasResult(result);
    })();
  }, [
    JSON.stringify(sjabloon.sjabloon.sjabCtxs),
    contexten,
    sjabloon.sjabloon.ID,
    sjabloon.sjabloon.IsSysteemSjabloon,
  ]);

  const onderwerpTonen = useMemo(() => {
    return props.comKanNaamEnum === 'EMAIL';
  }, [props.comKanNaamEnum]);

  const bestandenTonen = useMemo(() => {
    return props.comKanNaamEnum === 'EMAIL' || props.comKanNaamEnum === 'WHATSAPP';
  }, [props.comKanNaamEnum]);

  const systeemSjabloonDataContextData = useMemo(() => {
    try {
      return JSON.parse(props.formValues.systeemDataContext);
    } catch (_err) {
      return null;
    }
  }, [props.formValues.systeemDataContext]);

  const contextenData = useMemo(() => {
    if (voorbeeldContextDatasResult === null) {
      return null;
    }
    if (systeemSjabloonDataContextData === null) {
      return voorbeeldContextDatasResult.contexten;
    }
    return {
      ...voorbeeldContextDatasResult.contexten,
      data: systeemSjabloonDataContextData,
    };
  }, [voorbeeldContextDatasResult, systeemSjabloonDataContextData]);

  const isHTML = useMemo(
    () => props.comKanNaamEnum === 'EMAIL' || props.comKanNaamEnum === 'BESTAND_HTML',
    [props.comKanNaamEnum],
  );

  const [
    voorbeeldWeergaveInhoudState,
    setVoorbeeldWeergaveInhoudState,
  ] = useState<IVoorbeeldWeergaveState | null>(null);
  const bepaalVoorbeeldWeergaveInhoudTimeoutRef = useRef<number>(null);
  const [
    voorbeeldWeergaveOnderwerpState,
    setVoorbeeldWeergaveOnderwerpState,
  ] = useState<IVoorbeeldWeergaveState | null>(null);
  const bepaalVoorbeeldWeergaveOnderwerpTimeoutRef = useRef<number>(null);

  const bepaalVoorbeeldWeergaveInhoud = useCallback(() => {
    setVoorbeeldWeergaveInhoudState(null);

    if (bepaalVoorbeeldWeergaveInhoudTimeoutRef.current !== null) {
      clearTimeout(bepaalVoorbeeldWeergaveInhoudTimeoutRef.current);
      // @ts-ignore
      bepaalVoorbeeldWeergaveInhoudTimeoutRef.current = null;
    }
    if (contextenData === null) {
      return;
    }
    // @ts-ignore
    bepaalVoorbeeldWeergaveInhoudTimeoutRef.current = setTimeout(async () => {
      const state = await bepaalVoorbeeldWeergave(
        props.formValues.inhoud,
        props.comKanID,
        props.sjabBasisID,
        props.taalID,
        contextenData,
      );
      setVoorbeeldWeergaveInhoudState(state);
    }, 500);
  }, [props.formValues.inhoud, contextenData, props.taalID, props.comKanID, props.sjabBasisID]);

  useEffect(() => {
    bepaalVoorbeeldWeergaveInhoud();
  }, [bepaalVoorbeeldWeergaveInhoud]);

  const bepaalVoorbeeldWeergaveOnderwerp = useCallback(() => {
    setVoorbeeldWeergaveOnderwerpState(null);

    if (bepaalVoorbeeldWeergaveOnderwerpTimeoutRef.current !== null) {
      clearTimeout(bepaalVoorbeeldWeergaveOnderwerpTimeoutRef.current);
      // @ts-ignore
      bepaalVoorbeeldWeergaveOnderwerpTimeoutRef.current = null;
    }
    if (contextenData === null || !onderwerpTonen) {
      return;
    }
    // @ts-ignore
    bepaalVoorbeeldWeergaveOnderwerpTimeoutRef.current = setTimeout(async () => {
      const state = await bepaalVoorbeeldWeergave(
        props.formValues.onderwerp,
        props.comKanID,
        null,
        props.taalID,
        contextenData,
      );
      setVoorbeeldWeergaveOnderwerpState(state);
    }, 500);
  }, [onderwerpTonen, props.formValues.onderwerp, contextenData, props.taalID, props.comKanID]);

  useEffect(() => {
    bepaalVoorbeeldWeergaveOnderwerp();
  }, [bepaalVoorbeeldWeergaveOnderwerp]);

  const contextNodeRenderer = useCallback<InspectorNodeRenderer>(
    ({ depth, name, data, isNonenumerable, expanded }) =>
      depth === 0 ? null : ( // <span>contexten</span>
        // <ObjectRootLabel name={name} data={data} />
        <span
          onClick={() => alert(name)}
          style={{ cursor: 'pointer', fontSize: 13, marginBottom: 3 }}
        >
          <ObjectLabel name={name} data={data} isNonenumerable={isNonenumerable} />
        </span>
      ),
    [],
  );

  const [inhoudUitvergroten, setInhoudUitvergroten] = useState(false);
  const [voorbeeldWeergaveUitvergroten, setVoorbeeldWeergaveUitvergroten] = useState(false);

  const [isBezigMetAfdrukken, setIsBezigMetAfdrukken] = useState(false);
  const handleAfdrukkenNaarPDF = useCallback(async () => {
    setIsBezigMetAfdrukken(true);
    const afdrukkenResult = await api.v2.pdf.afdrukken({
      inhoud: voorbeeldWeergaveInhoudState?.output!,
      naam: `sjabloon-${sjabloon.sjabloon.Naam}-${new Date().toISOString()}.pdf`,
    });
    await downloadUrl(afdrukkenResult.bestand.url, afdrukkenResult.bestand.Naam);
    setIsBezigMetAfdrukken(false);
  }, [voorbeeldWeergaveInhoudState, sjabloon]);

  return (
    <InactiefOverlay
      isInactief={!props.formValues.toepassen}
      onClick={() => {
        props.onChange({
          ...props.formValues,
          toepassen: true,
        });
      }}
      element={
        <div className="form-group p-3 pl-4 pr-4">
          <div className="row">
            <div className="col-6">
              <div className="row">
                {onderwerpTonen && (
                  <div className="col-12 mb-2">
                    <label>Onderwerp</label>
                    <input
                      value={props.formValues.onderwerp}
                      onChange={(ev) =>
                        props.onChange({
                          ...props.formValues,
                          onderwerp: ev.target.value,
                        })
                      }
                      type="text"
                      className="form-control"
                      // style={{
                      //   backgroundColor: props.formValues.toepassen ? Kleur.Wit : Kleur.LichtGrijs,
                      // }}
                    />
                  </div>
                )}
                <div className="col-12">
                  <div className="d-flex mb-1">
                    <span>Inhoud</span>
                    <div className="flex-fill" />
                    <button
                      style={{
                        border: `1px solid ${Kleur.LichtGrijs}`,
                        position: 'relative',
                        top: -3,
                      }}
                      className="btn btn-light btn-sm d-flex align-items-center"
                      onClick={() => setInhoudUitvergroten(true)}
                    >
                      <IconUitvergroten style={{ fill: Kleur.Zwart, width: 18, height: 18 }} />
                      <span className="ml-2">Uitvergroten</span>
                    </button>
                  </div>
                  <ElementUitvergrotenWrapper
                    actief={inhoudUitvergroten}
                    onSluiten={() => setInhoudUitvergroten(false)}
                  >
                    <CodeBewerker
                      height={inhoudUitvergroten ? undefined : 350}
                      language="html"
                      code={props.formValues.inhoud}
                      onCodeChange={(code) => {
                        props.onChange({
                          ...props.formValues,
                          inhoud: code,
                        });
                      }}
                    />
                  </ElementUitvergrotenWrapper>
                </div>
              </div>
            </div>
            <div className="col-6">
              {sjabloon.sjabloon.IsSysteemSjabloon && systeemSjabloonDataContextData === null ? (
                <div
                  className="d-flex flex-fill align-items-center justify-content-center h-100 w-100"
                  style={{
                    backgroundColor: Kleur.Rood,
                    fontSize: 16,
                    color: Kleur.Wit,
                  }}
                >
                  Systeem sjabloon context data bevat fouten.
                </div>
              ) : (
                <>
                  {onderwerpTonen && (
                    <div>
                      <label>&nbsp;</label>
                      <div
                        style={{
                          cursor: 'not-allowed',
                          padding: '6px 8px',
                        }}
                      >
                        {voorbeeldWeergaveOnderwerpState === null ? (
                          <span style={{ fontStyle: 'italic' }}>Bezig...</span>
                        ) : voorbeeldWeergaveOnderwerpState.error !== undefined ? (
                          <span style={{ color: Kleur.Rood }}>
                            {voorbeeldWeergaveOnderwerpState.error}
                          </span>
                        ) : (
                          voorbeeldWeergaveOnderwerpState.output
                        )}
                      </div>
                    </div>
                  )}
                  <div className="d-flex mb-1">
                    <div className="flex-fill" />
                    <button
                      style={{
                        border: `1px solid ${Kleur.LichtGrijs}`,
                        position: 'relative',
                        top: -3,
                      }}
                      className="btn btn-light btn-sm d-flex align-items-center"
                      onClick={handleAfdrukkenNaarPDF}
                      disabled={
                        isBezigMetAfdrukken ||
                        voorbeeldWeergaveInhoudState === null ||
                        voorbeeldWeergaveInhoudState.output === undefined
                      }
                    >
                      <IconDownload style={{ fill: Kleur.Zwart, width: 14, height: 14 }} />
                      <span className="ml-2">Afdrukken naar PDF</span>
                    </button>
                    <button
                      style={{
                        border: `1px solid ${Kleur.LichtGrijs}`,
                        position: 'relative',
                        top: -3,
                      }}
                      className="btn btn-light btn-sm d-flex align-items-center ml-2"
                      onClick={() => setVoorbeeldWeergaveUitvergroten(true)}
                    >
                      <IconUitvergroten style={{ fill: Kleur.Zwart, width: 18, height: 18 }} />
                      <span className="ml-2">Uitvergroten</span>
                    </button>
                  </div>
                  <div
                    className="d-flex flex-column justify-content-center align-items-center"
                    style={{ height: 350, width: '100%', backgroundColor: 'white' }}
                  >
                    <ElementUitvergrotenWrapper
                      actief={voorbeeldWeergaveUitvergroten}
                      onSluiten={() => setVoorbeeldWeergaveUitvergroten(false)}
                    >
                      {voorbeeldWeergaveInhoudState === null ? (
                        <LoadingSpinner />
                      ) : voorbeeldWeergaveInhoudState.error !== undefined ? (
                        <div
                          className="flex-fill"
                          style={{
                            color: 'red',
                            padding: '10px 15px',
                          }}
                        >
                          {voorbeeldWeergaveInhoudState.error}
                        </div>
                      ) : isHTML ? (
                        <iframe
                          className="flex-fill"
                          srcDoc={voorbeeldWeergaveInhoudState.output}
                          style={{ border: 0, height: '100%', width: '100%' }}
                        />
                      ) : (
                        <textarea
                          className="flex-fill"
                          style={{
                            border: 0,
                            height: '100%',
                            width: '100%',
                            background: 'white',
                            color: 'black',
                            padding: '10px 15px',
                            cursor: 'not-allowed',
                          }}
                          value={voorbeeldWeergaveInhoudState.output}
                          disabled
                        />
                      )}
                    </ElementUitvergrotenWrapper>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-6">
              <div>
                <label>Contexten</label>
                <div
                  style={{
                    background: 'white',
                    padding: 10,
                  }}
                >
                  {contextenData === null ? (
                    <LoadingSpinner />
                  ) : (
                    <ObjectInspector
                      data={contextenData}
                      expandLevel={1}
                      nodeRenderer={contextNodeRenderer}
                    />
                  )}
                </div>
              </div>

              {sjabloon.sjabloon.IsSysteemSjabloon && (
                <div className="mt-2">
                  <span className="d-flex align-items-center">
                    <span className="mr-1">Systeemsjabloon data context</span>
                    <UitlegTooltip
                      inhoud={
                        <div>
                          <b>Let op!:</b> Alleen voor het testen van het sjabloon.
                          <p>
                            Hier heb je de mogelijk om zelf d.m.v. JSON-formaat de data context toe
                            te kennen, zodat er ook de mogelijkheid is om systeem sjablonen te
                            kunnen testen.
                          </p>
                          <p>
                            Deze data wordt normaal gesproken programmatisch ingevuld door het
                            systeem.
                          </p>
                        </div>
                      }
                    />
                  </span>
                  <div
                    style={{
                      background: 'white',
                      padding: 10,
                    }}
                    className="mt-2"
                  >
                    <div style={{ backgroundColor: Kleur.Wit }}>
                      <CodeBewerker
                        height={150}
                        language="json"
                        code={props.formValues.systeemDataContext}
                        onCodeChange={(code) => {
                          props.onChange({
                            ...props.formValues,
                            systeemDataContext: code,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="col-6">
              {bestandenTonen && (
                <div className="mb-2">
                  <label>Bestanden</label>
                  <div className="d-flex flex-fill">
                    <BestandDragAndDropZone
                      bestanden={props.formValues.bestanden}
                      onBestandenChange={(x) =>
                        props.onChange({
                          ...props.formValues,
                          bestanden: x,
                        })
                      }
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      }
    />
  );
};

export default TaalFormulier;
