interface IVeldnaamParams {
  veldnaam: string;
}

interface IAantalParams {
  aantal: number;
}

interface IFormulierEMinimaalAantalKaraktersBevatten extends IVeldnaamParams, IAantalParams {}

// tslint:disable-next-line:no-empty-interface
interface IFormulierEVerplichtVeldParams extends IVeldnaamParams {}

// tslint:disable-next-line:no-empty-interface
interface IFormulierEAfgerondGetalParams extends IVeldnaamParams {}

interface IFormulierEGetalMinimaalParams extends IVeldnaamParams, IAantalParams {}
interface IFormulierEGetalMaximaalParams extends IVeldnaamParams, IAantalParams {}

interface IFormulierEEmailVeldParams extends IVeldnaamParams {}

interface IFormulierETekstMaximaal extends IVeldnaamParams, IAantalParams {}

interface IFormulierEVoldoetNietAanFormaat extends IVeldnaamParams {
  /**
   * e.g. 'J.'
   */
  voorbeeldFormaat?: string;
}

export const aantalNaarLeesbareTekens = (aantal: number) => {
  switch (aantal) {
    case 1:
      return 'één';
    case 2:
      return 'twee';
    case 3:
      return 'drie';
    case 4:
      return 'vier';
    case 5:
      return 'vijf';
    case 6:
      return 'zes';
    case 7:
      return 'zeven';
    case 8:
      return 'acht';
    case 9:
      return 'negen';
    default:
      return String(aantal);
  }
};

export const maandenNaarJaren = (maanden: number) => {
  let duur: string = '';
  if (maanden <= 12) {
    duur = maanden + ' m';
  } else {
    const jaren = Math.trunc(maanden / 12);
    const restMaanden = maanden % (jaren * 12);
    duur = jaren + ' jr' + (restMaanden > 0 ? ' ' + restMaanden + ' m' : '');
  }

  return duur;
};

export const urenNaarDagen = (uren: number) => {
  let result: string = '';
  if (uren <= 8) {
    result = (uren + (uren === 1 ? ' uur' : ' uren')).replace('.', ',');
  } else {
    const dagen = Math.trunc(uren / 8);
    const restUren = uren % (dagen * 8);
    result = (
      dagen +
      (dagen === 1 ? ' dag' : ' dgn') +
      (restUren > 0 ? ' ' + restUren + ' u' : '')
    ).replace('.', ',');
  }

  return result;
};

const teksten = {
  formulier: {
    E_MINIMAAL_AANTAL_KARAKTERS_BEVATTEN: (params: IFormulierEMinimaalAantalKaraktersBevatten) => {
      if (params.aantal < 1) {
        throw new Error('Aantal moet minimaal 1 of hoger zijn');
      }

      const aantalTekst = aantalNaarLeesbareTekens(params.aantal);

      return `${params.veldnaam} moet minimaal ${aantalTekst} karakter${
        params.aantal > 1 ? 's' : ''
      } bevatten.`;
    },
    E_VERPLICHT_VELD: (params: IFormulierEVerplichtVeldParams) =>
      `${params.veldnaam} is een verplicht veld.`,
    E_AFGEROND_GETAL: (params: IFormulierEAfgerondGetalParams) =>
      `${params.veldnaam} moet een afrond getal zijn.`,
    E_GETAL_MINIMAAL: (params: IFormulierEGetalMinimaalParams) => {
      const aantalTekst = aantalNaarLeesbareTekens(params.aantal);
      return `${params.veldnaam} kan alleen ${aantalTekst} of groter zijn.`;
    },
    E_GETAL_MAXIMAAL: (params: IFormulierEGetalMaximaalParams) => {
      const aantalTekst = aantalNaarLeesbareTekens(params.aantal);
      return `${params.veldnaam} kan alleen ${aantalTekst} of kleiner zijn.`;
    },
    E_EMAIL_VELD: (params: IFormulierEEmailVeldParams) => `${params.veldnaam} moet een email zijn.`,
    E_TEKST_MAXIMAAL: (params: IFormulierETekstMaximaal) =>
      `${params.veldnaam} mag maximaal ${aantalNaarLeesbareTekens(
        params.aantal,
      )} karakters bevatten.`,
    E_VOLDOET_NIET_AAN_FORMAAT: (params: IFormulierEVoldoetNietAanFormaat) =>
      `${params.veldnaam} voldoet niet aan het formaat${
        params.voorbeeldFormaat === undefined ? '' : ` (e.g. '${params.voorbeeldFormaat}')`
      }.`,
    E_GEEN_GETAL: (params: IVeldnaamParams) => `${params.veldnaam} is geen getal.`,
  },
};

export default teksten;
