import * as React from 'react';
import TegelBasis from '../../../../../../../components/tegel/TegelBasis';
import Tabel from '../Tabel';
import NavigatiePijl from '../NavigatiePijl';
import FormatteerBedrag from '../../../../../../../components/MutatieBedrag';
import { RouteComponentProps, withRouter } from 'react-router';
import { IOphalenInformatieVoorOverzichtBetalingsregeling } from '../../../../../../../../../shared/src/api/v2/relatie';
import { format } from 'date-fns';
import { genereerUrlStateQueryParam } from '../../../../../../../core/useUrlState';
import {
  IUrlState as IBetalingsregelingUrlState,
  defaultUrlState as defaultBetalingsregelingUrlState,
} from '../../Debiteur/Betalingsregeling';

interface IProps extends RouteComponentProps {
  relID: number;
  data: IOphalenInformatieVoorOverzichtBetalingsregeling;
}

const Betalingsregelingtegel = (props: IProps) => {
  return (
    <TegelBasis titel="Betalingsregeling">
      <div>
        <table>
          <tbody>
            {props.data.regelingen.map((regeling) => {
              return (
                <tr key={regeling.id}>
                  <td style={{ width: 100, verticalAlign: 'top' }}>
                    {format(new Date(regeling.datum), 'dd-MM-yyyy')}
                  </td>
                  <td style={{ width: 90, verticalAlign: 'top' }}>
                    <FormatteerBedrag bedrag={regeling.totaalbedrag} />
                  </td>
                  <td style={{ width: 100, verticalAlign: 'top' }}>
                    {regeling.aantalTermijnen} termijnen
                  </td>

                  <td style={{ verticalAlign: 'top' }}>
                    <NavigatiePijl
                      relID={props.relID}
                      pathWithStateBuilder={() => {
                        const state = genereerUrlStateQueryParam<IBetalingsregelingUrlState>({
                          ...defaultBetalingsregelingUrlState,
                          selectie: [regeling.id],
                        });
                        return `debiteur/betalingsregelingen?state=${state}`;
                      }}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        {props.data.totaalAantalRegelingen > props.data.regelingen.length && (
          <div className="mt-2">
            <a
              href="#"
              onClick={(ev) => {
                ev.preventDefault();

                props.history.push(`/klant/${props.relID}/debiteur/betalingsregelingen`);
              }}
            >
              Er zijn nog {props.data.totaalAantalRegelingen - props.data.regelingen.length}
              &nbsp;andere regelingen...
            </a>
          </div>
        )}
      </div>
    </TegelBasis>
  );
};

export default withRouter(Betalingsregelingtegel);
