import React, { useCallback, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Kleur } from '../../../bedrijfslogica/constanten';
import { OverlayTrigger } from 'react-bootstrap';
import InfoCard from './InfoCard';
import {
  IBulkVerzendBerichtTijdvak,
  IBulkVerzendBerichtTijdvakDag,
} from '../../../../../shared/src/api/v2/bulkVerzending';
import { createReadyRemoteData } from '../../../models/IRemoteData';

export interface IBulkBerichtTijdvakVisualisatieData {
  tijdvak: IBulkVerzendBerichtTijdvak;
  dagen: IBulkVerzendBerichtTijdvakDag[];
}

interface IProps {
  tijdvakID: number;
  data: IBulkBerichtTijdvakVisualisatieData;
}

const BulkBerichtTijdvakVisualisatie: React.FC<IProps> = observer((props) => {
  const [isHovered, setIsHovered] = useState(false);

  const [showInfoCardOverlay, setShowInfoCardOverlay] = useState(false);
  const showInfoCardOverlayTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const handleMouseEnterInfoCardOverlay = useCallback(() => {
    if (showInfoCardOverlayTimeoutRef.current !== null) {
      clearTimeout(showInfoCardOverlayTimeoutRef.current);
    }
    showInfoCardOverlayTimeoutRef.current = setTimeout(() => setShowInfoCardOverlay(true), 800);
  }, []);
  const handleMouseLeaveInfoCardOverlay = useCallback(() => {
    if (showInfoCardOverlayTimeoutRef.current !== null) {
      clearTimeout(showInfoCardOverlayTimeoutRef.current);
    }
    showInfoCardOverlayTimeoutRef.current = setTimeout(() => setShowInfoCardOverlay(false), 400);
  }, []);
  const renderInfoCardOverlay = useCallback(
    (p: any) => {
      const data = createReadyRemoteData(props.data);
      return (
        <div
          id={`bulk-bericht-tijdvak-overlay-${props.tijdvakID}`}
          {...p}
          onMouseEnter={handleMouseEnterInfoCardOverlay}
          onMouseLeave={handleMouseLeaveInfoCardOverlay}
          style={{
            ...p.style,
            zIndex: 99999,
            border: `1px solid ${Kleur.LichtGrijs}`,
            borderRadius: 5,
          }}
        >
          <InfoCard data={data} />
        </div>
      );
    },
    [props.data, handleMouseEnterInfoCardOverlay, handleMouseLeaveInfoCardOverlay],
  );

  return (
    <OverlayTrigger
      trigger="hover"
      placement="auto-start"
      overlay={renderInfoCardOverlay}
      show={showInfoCardOverlay}
      popperConfig={
        {
          modifiers: {
            name: 'offset',
            options: {
              offset: [0, 5],
            },
          },
        } as any
      }
    >
      <span
        className="d-flex align-items-center"
        onMouseEnter={() => {
          setIsHovered(true);
          handleMouseEnterInfoCardOverlay();
        }}
        onMouseLeave={() => {
          setIsHovered(false);
          handleMouseLeaveInfoCardOverlay();
        }}
      >
        <>
          {/*<a*/}
          {/*  href="#"*/}
          {/*  className="d-flex align-items-center"*/}
          {/*  onClick={(ev) => {*/}
          {/*    ev.preventDefault();*/}

          {/*    const urlState: IFacturenOverzichtUrlState = {*/}
          {/*      ...facturenOverzichtDefaultUrlState,*/}
          {/*      selectie: [factuur.FactID],*/}
          {/*      uitgeklapteFacturen: [factuur.FactID],*/}
          {/*    };*/}
          {/*    const query = genereerUrlStateQueryParam(urlState);*/}
          {/*    // props.history.push(`/klant/${factuur.RelID}/facturen/overzicht?state=${query}`);*/}

          {/*    props.history.push(`/klant/${factuur.RelID}/facturen/overzicht?state=${query}`);*/}
          {/*  }}*/}
          {/*>*/}
          <span>{props.data.tijdvak.Naam}</span>
          {/*</a>*/}
          {/*{isHovered && (*/}
          {/*  <IconOpenInNew*/}
          {/*    style={{*/}
          {/*      width: 16,*/}
          {/*      height: 16,*/}
          {/*      marginLeft: 5,*/}
          {/*      marginTop: 3,*/}
          {/*      cursor: 'pointer',*/}
          {/*      fill: Kleur.Grijs,*/}
          {/*    }}*/}
          {/*    onClick={(ev) => {*/}
          {/*      if (link.state === ERemoteDataState.Pending) {*/}
          {/*        return;*/}
          {/*      }*/}

          {/*      window.open(link.data!, '_blank');*/}
          {/*    }}*/}
          {/*  />*/}
          {/*)}*/}
        </>
      </span>
    </OverlayTrigger>
  );
});

export default BulkBerichtTijdvakVisualisatie;
