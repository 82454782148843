import React, { useEffect, useState, useCallback } from 'react';
import { IFormikValues, veldnamen } from '..';
import ModalBody from 'react-bootstrap/ModalBody';
import { Field, FieldProps } from 'formik';
import nameOf from '../../../../core/nameOf';
import VinkVeld from '../../../formulier/VinkVeld';
import { IOphalenLocatieNiveausResultElement } from '../../../../../../shared/src/api/v2/locatie/locatie';
import api from '../../../../api';
import TijdvakVeld from '../../../formulier/TijdvakVeld';

const BezoektijdTab: React.FC = (props) => {
  const [verdiepingen, setVerdiepingen] = useState<IOphalenLocatieNiveausResultElement[] | null>(
    null,
  );

  const ophalenVerdiepingen = useCallback(async () => {
    const resultaat = await api.v2.locatie.ophalenLocatieNiveaus({
      filterSchema: {},
    });

    setVerdiepingen(resultaat.locatieniveaus);
  }, []);

  useEffect(() => {
    ophalenVerdiepingen();
  }, []);

  return (
    <ModalBody>
      <div style={{ minHeight: `400px` }}>
        <div className="row">
          {/* <div className="col-6">
            <label>{veldnamen.bezoekDatum}</label>
            <Field
              name={nameof<IFormikValues>('bezoekDatum')}
              render={(fieldProps: FieldProps<IFormikValues>) => {
                const { field, form } = fieldProps;
                return (
                  <DatumKiezer
                    waarde={field.value}
                    onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                    isClearable
                  />
                );
              }}
            />
          </div> */}
          <div className="col-6">
            <label>{veldnamen.tijdvak}</label>
            <Field
              name={nameOf<IFormikValues>('tijdvak')}
              render={(fieldProps: FieldProps<IFormikValues>) => {
                const { field, form } = fieldProps;
                return (
                  <TijdvakVeld
                    value={field.value}
                    onChange={(x) => {
                      form.setFieldValue(field.name, x);
                    }}
                    enkeleToestaan
                  />
                );
              }}
            />
          </div>
        </div>

        <div className="row pt-4">
          <div className="col-12 d-flex align-items-center">
            <Field
              name={nameOf<IFormikValues>('bezoektijdenActueel')}
              render={(fieldProps: FieldProps<IFormikValues>) => {
                const { field, form } = fieldProps;
                return (
                  <VinkVeld
                    aangevinkt={field.value}
                    onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                  />
                );
              }}
            />
            <span className="ml-2">{veldnamen.bezoektijdenActueel}</span>
          </div>
        </div>

        <div className="row pt-4">
          <div className="col-12 d-flex align-items-center">
            <Field
              name={nameOf<IFormikValues>('bezoektijdenNietAutomatischBijwerken')}
              render={(fieldProps: FieldProps<IFormikValues>) => {
                const { field, form } = fieldProps;
                return (
                  <VinkVeld
                    aangevinkt={field.value}
                    onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                  />
                );
              }}
            />
            <span className="ml-2">{veldnamen.bezoektijdenNietAutomatischBijwerken}</span>
          </div>
        </div>

        <div className="row pt-4">
          <div className="col-12 d-flex align-items-center">
            <Field
              name={nameOf<IFormikValues>('bezoektijdenNietVersturen')}
              render={(fieldProps: FieldProps<IFormikValues>) => {
                const { field, form } = fieldProps;
                return (
                  <VinkVeld
                    aangevinkt={field.value}
                    onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                  />
                );
              }}
            />
            <span className="ml-2">{veldnamen.bezoektijdenNietVersturen}</span>
          </div>
        </div>

        {/* <div className="row pt-4">
          <div className="col-6 d-flex align-items-center">
            <Field
              name={nameof<IFormikValues>('indicatieAAA')}
              render={(fieldProps: FieldProps<IFormikValues>) => {
                const { field, form } = fieldProps;
                return (
                  <VinkVeld
                    aangevinkt={field.value}
                    onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                  />
                );
              }}
            />
            <span className="ml-2">{veldnamen.indicatieAAA}</span>
          </div>
        </div> */}
      </div>
    </ModalBody>
  );
};

export default BezoektijdTab;
