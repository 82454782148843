import React, { useCallback, useMemo, useState } from 'react';
import { DataTypeProvider, TableRowDetail } from '@devexpress/dx-react-grid';
import TabelInspringBlok from '../../../layout/TabelInspringBlok';
import {
  IOphalenAanmaningenResultElement,
  IFactuur,
} from '../../../../../../shared/src/api/v2/aanmaning';
import {
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import {
  Grid,
  Table,
  TableColumnResizing,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-bootstrap4';
import { VirtualTable as VirtualTableBase } from '@devexpress/dx-react-grid';
import { format } from 'date-fns';
import FormatteerBedrag from '../../../MutatieBedrag';
import api from '../../../../api';
import { EFunctioneleIcon, functioneleIconMap, IconDownload, IconWijzigen } from '../../../Icons';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import LoadingSpinner from '../../../Gedeeld/LoadingSpinner';
import FactuurinfoDialoog from '../../../factuur/FactuurinfoDialoog';

export interface IFactuurinfoDialoogState {
  factID: number;
}

const IconFactuurInfo = functioneleIconMap[EFunctioneleIcon.FactuurInfo];

const DetailComp = (props: TableRowDetail.ContentProps) => {
  const row: IOphalenAanmaningenResultElement = props.row;

  const [bezigMetDownloadenFactIDs, setBezigMetDownloadenFactIDs] = useState<number[]>([]);

  const [
    factuurinfoDialoogState,
    setFactuurinfoDialoogState,
  ] = useState<IFactuurinfoDialoogState | null>(null);

  const DownloadFactuur = (props: { factID: number }) => {
    const disabled = bezigMetDownloadenFactIDs.some((x) => x === props.factID);
    return (
      <span
        style={{ cursor: disabled ? 'inherit' : 'pointer' }}
        onClick={
          disabled
            ? undefined
            : async () => {
                setBezigMetDownloadenFactIDs([...bezigMetDownloadenFactIDs, props.factID]);

                const result = await api.v2.factuur.genereerFactuurPDF({
                  factID: props.factID,
                });
                const downloadLink = result.bestand.url;
                window.open(downloadLink, '_blank');

                setBezigMetDownloadenFactIDs((state) => state.filter((x) => x !== props.factID));
              }
        }
        className="d-flex align-items-center"
      >
        <IconDownload
          style={{
            width: 17,
            height: 17,
            fill: disabled ? Kleur.Grijs : Kleur.Blauw,
            marginLeft: 15,
          }}
        />

        {disabled && (
          <span className="ml-2">
            <LoadingSpinner />
          </span>
        )}
      </span>
    );
  };

  const kolommen = useMemo<TypedColumn<IFactuur>[]>(
    () => [
      {
        name: 'Factuurnummer',
        title: 'Fact.nr.',
      },
      {
        name: 'Factuurdatum',
        title: 'Factuurdatum',
      },
      {
        name: 'Vervaldatum',
        title: 'Vervaldatum',
      },
      {
        name: '__factuurInfo' as any,
        title: ' ',
      },
      {
        name: 'BedragInAanmaning',
        title: 'Bedrag',
      },
      {
        name: '__kosten' as any,
        title: 'Kosten',
      },
      {
        name: 'aantalKeerAangemaand',
        title: '# Aanm.',
      },
      {
        name: '__downloadActie' as any,
        title: ' ',
      },
    ],
    [],
  );
  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IFactuur>[]>(
    () => [
      {
        columnName: 'Factuurnummer',
        width: 100,
      },
      {
        columnName: '__factuurInfo' as any,
        width: 40,
      },
      {
        columnName: 'Factuurdatum',
        title: 'Datum',
        width: 110,
      },
      {
        columnName: 'Vervaldatum',
        title: 'Vervaldatum',
        width: 110,
      },
      {
        columnName: 'BedragInAanmaning',
        width: 100,
      },
      {
        columnName: '__kosten' as any,
        width: 100,
      },
      {
        columnName: 'aantalKeerAangemaand',
        width: 80,
      },
      {
        columnName: '__downloadActie' as any,
        width: 50,
      },
    ],
    [],
  );

  const keyExtractor = useCallback((factuur: IFactuur) => factuur.FactID, []);

  const kolomExtensies: VirtualTableBase.ColumnExtension[] = useMemo(() => {
    return [
      {
        columnName: `BedragInAanmaning`,
        align: 'right',
      },
      {
        columnName: '__kosten' as any,
        align: 'right',
      },
    ];
  }, []);

  return (
    <>
      <div className="d-flex">
        <TabelInspringBlok />
        <GridStyleWrapper maxHeight={500} rowAmount={row.facturen.length}>
          <Grid rows={row.facturen} columns={kolommen} getRowId={keyExtractor}>
            <DataTypeProvider
              for={['Factuurdatum']}
              formatterComponent={(formatterProps) => {
                const rij: IFactuur = formatterProps.row;
                return <span>{format(new Date(rij.Factuurdatum), 'dd-MM-yyyy')}</span>;
              }}
            />

            <DataTypeProvider
              for={['Vervaldatum']}
              formatterComponent={(formatterProps) => {
                const rij: IFactuur = formatterProps.row;
                return <span>{format(new Date(rij.Vervaldatum), 'dd-MM-yyyy')}</span>;
              }}
            />

            <DataTypeProvider
              for={['BedragInAanmaning']}
              formatterComponent={(formatterProps) => {
                const rij: IFactuur = formatterProps.row;
                return <FormatteerBedrag bedrag={rij.BedragInAanmaning} rechtsUitlijnen />;
              }}
            />

            <DataTypeProvider
              for={['__kosten']}
              formatterComponent={(formatterProps) => {
                const rij: IFactuur = formatterProps.row;
                if (rij.KostenAanmaning === 0) {
                  return <span></span>;
                }
                return <FormatteerBedrag bedrag={rij.KostenAanmaning} rechtsUitlijnen />;
              }}
            />

            <DataTypeProvider
              for={['__factuurInfo']}
              formatterComponent={(formatterProps) => {
                const rij: IFactuur = formatterProps.row;
                return (
                  <a
                    href="#"
                    className="ml-1"
                    style={{ color: Kleur.LichtBlauw, position: 'relative', bottom: 2 }}
                    onClick={() => {
                      setFactuurinfoDialoogState({ factID: rij.FactID });
                    }}
                  >
                    <IconFactuurInfo style={{ width: 15, height: 15, fill: Kleur.Blauw }} />
                  </a>
                );
              }}
            />

            <DataTypeProvider
              for={['__downloadActie']}
              formatterComponent={(formatterProps) => {
                const rij: IFactuur = formatterProps.row;
                return <DownloadFactuur factID={rij.FactID} />;
              }}
            />

            <Table columnExtensions={kolomExtensies} />
            <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
            {/* <TableHeaderRow showSortingControls /> */}
            <TableHeaderRow />
          </Grid>
        </GridStyleWrapper>
      </div>
      {factuurinfoDialoogState !== null && (
        <FactuurinfoDialoog
          open
          factID={factuurinfoDialoogState.factID}
          onSuccess={() => {
            setFactuurinfoDialoogState(null);
          }}
          onAnnuleren={() => setFactuurinfoDialoogState(null)}
        />
      )}
    </>
  );
};

export default DetailComp;
