import React from 'react';
import { IWeergaveProps } from '../../../../../../components/FilterBalkV2';
import MultiSelect from '../../../../../../components/formulier/MultiSelect';

const OpdrachtStatusFilter = (props: IWeergaveProps<any>) => {
  return (
    <div className="d-flex align-items-center">
      <span>Opdrachtstatus</span>
      <div className="ml-2">
        <MultiSelect
          value={props.data}
          onChange={(x) => {
            props.onDataChange(x);
            props.toepassen();
          }}
          opties={[
            {
              key: 1,
              weergave: 'Planning',
            },
            {
              key: 2,
              weergave: 'Uitstaand',
            },
            {
              key: 3,
              weergave: 'Afgehandeld',
            },
          ]}
        />
      </div>
    </div>
  );
};

export default OpdrachtStatusFilter;
