import React, { useContext, useEffect, useMemo, useState, useCallback } from 'react';

import { RouteComponentProps } from 'react-router-dom';
import { ASPKolom, EAspKolomBreedteType } from '../../../../components/tabel/ASPTabel/types';
import MenuLayout from '../../../../components/MenuLayout';
import { IconToevoegen } from '../../../../components/Icons';
import ASPTabel from '../../../../components/tabel/ASPTabel';
import { IOphalenFacturenResultElement } from '../../../../../../shared/src/api/v2/inkoopfactuur';
import api from '../../../../api';
import { format } from 'date-fns';
import FormatteerBedrag from '../../../../components/MutatieBedrag';
import RelatieVisualisatie from '../../../../components/personalia/RelatieVisualisatie';

interface IProps extends RouteComponentProps {}

enum EKolom {
  Factuurdatum,
  Factuurnummer,
  Bedrag,
  Onderwerp,
  Crediteur,
}

interface IRegel extends IOphalenFacturenResultElement {}

const Retourproducten: React.FC<IProps> = (props) => {
  const [facturen, setFacturen] = useState<IRegel[] | null>(null);

  const ophalenFacturen = useCallback(async () => {
    const facturenResult = await api.v2.inkoopfactuur.ophalenFacturen({
      filterSchema: { filters: [{ naam: 'KOPPELEN_RETOURPRODUCTEN', data: true }] },
    });
    setFacturen(facturenResult.facturen);
  }, []);

  useEffect(() => {
    ophalenFacturen();
  }, [ophalenFacturen]);

  const keyExtractor = useCallback((rij: IRegel) => rij.InkFactID, []);

  const kolommen = useMemo<ASPKolom<EKolom, IRegel>[]>(
    () => [
      {
        key: EKolom.Factuurnummer,
        label: 'Factuurnummer',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 135,
        renderer: (rij: IRegel) => rij.Factuurnummer,
      },
      {
        key: EKolom.Factuurdatum,
        label: 'Datum',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 135,
        renderer: (rij: IRegel) => format(new Date(rij.Factuurdatum), 'dd-MM-yyyy'),
      },
      {
        key: EKolom.Crediteur,
        label: 'Crediteur',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 135,
        renderer: (rij: IRegel) => <RelatieVisualisatie relID={rij.RelID} />,
      },
      {
        key: EKolom.Bedrag,
        label: 'Bedrag (incl.)',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 145,
        renderer: (rij: IRegel) => <FormatteerBedrag bedrag={rij.Bedrag} />,
      },
      {
        key: EKolom.Onderwerp,
        label: 'Onderwerp',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 200,
        renderer: (rij: IRegel) => rij.Onderwerp,
      },
    ],
    [],
  );

  const rijen = useMemo<Record<number, IRegel>>(() => {
    return facturen === null
      ? {}
      : facturen.reduce((acc, curr, i) => {
          return {
            ...acc,
            [i]: curr,
          };
        }, {});
  }, [facturen]);

  return (
    <MenuLayout
      menu={
        <div className="d-flex align-items-center">
          {/* <button
            className="btn btn-sm btn-light d-flex align-items-center"
            style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
            onClick={handleToevoegen ?? undefined}
            disabled={handleToevoegen === null}
          >
            <IconToevoegen style={{ fill: Kleur.Grijs, width: 18, height: 18 }} />
            <span className="ml-2">Toevoegen</span>
          </button> */}
        </div>
      }
      body={
        <ASPTabel
          rijen={rijen}
          kolommen={kolommen}
          keyExtractor={keyExtractor}
          // onWijzigenRij={handleWijzigenRij ?? undefined}
          // onVerwijderenRij={handleVerwijderenRij}
        />
      }
    />
  );
};

export default Retourproducten;
