import { IOphalenBoekingenResultElement } from '../../../../../../../shared/src/api/v2/boekhouding/boeking';
import api from '../../../../../api';

export interface IBoekingProvider {
  provide: (boekingID: number | null) => Promise<IOphalenBoekingenResultElement | null>;
}

export const standaardBoekingProvider: IBoekingProvider = {
  provide: async (boekingID: number | null) => {
    if (boekingID === null) {
      return null;
    }

    const result = await api.v2.boeking.ophalenBoekingen({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: [boekingID],
          },
        ],
      },
    });

    return result.boekingen[0];
  },
};
