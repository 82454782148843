import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import IDialoogProps from '../../../../../core/IDialoogProps';
import Dialoog from '../../../../../components/dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import * as Yup from 'yup';
import api from '../../../../../api';
import LoadingSpinner from '../../../../../components/Gedeeld/LoadingSpinner';
import Tabblad, { ITabblad } from '../../../../../components/layout/Tabblad';
import ModalBody from 'react-bootstrap/ModalBody';
import { RootStoreContext } from '../../../../../stores/RootStore';
import { EResultType } from '../../../../../stores/CheckStore';
import { IOphalenFacturenResultElement } from '../../../../../../../shared/src/api/v2/inkoopfactuur';
import {
  DXTableCheckboxComponent,
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../../helpers/dxTableGrid';
import {
  Grid,
  Table,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  TableSelection,
} from '@devexpress/dx-react-grid-bootstrap4';
import VeldWeergave from '../../../../../components/formulier/VeldWeergave';
import GegevensLayout from '../../../../../components/layout/GegevensLayout';
import { format } from 'date-fns';
import FormatteerBedrag, {
  StandaardMutatieBedragOpmaker,
} from '../../../../../components/MutatieBedrag';
import {
  DataTypeProvider,
  EditingState,
  IntegratedSorting,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import SelecterenWerkbonnenDialoog from './SelecterenWerkbonnenDialoog';
import { Kleur } from '../../../../../bedrijfslogica/constanten';
import { IconBetaling, IconToevoegen, IconVerwijderen } from '../../../../../components/Icons';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import useUrlState from '../../../../../core/useUrlState';
import RelatieVisualisatie from '../../../../../components/personalia/RelatieVisualisatie';
import _ from 'lodash';
import nameOf from '../../../../../core/nameOf';
import SelectieVak from '../../../../../components/SelectieVak';
import { IOphalenGekoppeldeWerkbonnenResultElement } from '../../../../../../../shared/src/api/v2/inkoopfactuur/werkbon';
import { IOphalenOpdrachtenResultElement } from '../../../../../../../shared/src/api/v2/service/opdracht';

export interface IDialoogResult {}

interface IProps extends IDialoogProps<IDialoogResult>, RouteComponentProps {
  inkFactID: number;
}

export interface IFormikValues {
  gekoppeld: boolean;
}

export interface IKoppelenProductenDialoogContext {
  factuur: IOphalenFacturenResultElement | null;
}

export const KoppelProductenDialoogContext = React.createContext<IKoppelenProductenDialoogContext>(
  null as any,
);

export interface ISelecterenProductenDialoogState {
  factuur: IOphalenFacturenResultElement;
}

export interface IWijzigenInkoopprijsDialoogState {
  inkoopprijs: number;
}

export interface IUrlState {
  selectie: number[];
}

const defaultUrlState: IUrlState = {
  selectie: [],
};

export interface IRegel extends IOphalenGekoppeldeWerkbonnenResultElement {
  serviceopdracht: IOphalenOpdrachtenResultElement | null;
}

const KoppelWerkbonnenDialoog: React.FC<IProps> = (props) => {
  const { children, dialoogIndex, onAnnuleren, onSuccess, open } = props;
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);

  const { checkStore } = useContext(RootStoreContext);

  const [factuur, setFactuur] = useState<IOphalenFacturenResultElement | null>(null);
  const [werkbonnen, setWerkbonnen] = useState<IRegel[] | null>(null);

  const [
    selecterenProductenDialoogState,
    setSelecterenProductenDialoogState,
  ] = useState<ISelecterenProductenDialoogState | null>(null);

  // Inkoopfactuur
  useEffect(() => {
    (async () => {
      const facturenResultaat = await api.v2.inkoopfactuur.ophalenFacturen({
        filterSchema: { filters: [{ naam: 'IDS', data: [props.inkFactID] }] },
      });
      const factuur = facturenResultaat.facturen[0];

      setFactuur(factuur);
    })();
  }, [props.inkFactID]);

  const ophalenWerkbonnen = useCallback(async () => {
    const werkbonnenResultaat = await api.v2.inkoopfactuur.werkbon.ophalenGekoppeldeWerkbonnen({
      inkFactIDs: [props.inkFactID],
    });

    const servOpdIDs = _.uniq(werkbonnenResultaat.werkbonnen.map((x: any) => x.werkbon.ServOpdID));

    const opdrachtenResult = await api.v2.service.ophalenOpdrachten({
      filterSchema: {
        filters: [{ naam: 'IDS', data: servOpdIDs }],
      },
    });

    const werkbonnen = werkbonnenResultaat.werkbonnen.map((x: any) => {
      const serviceopdracht = opdrachtenResult.opdrachten.find(
        (y) => y.ServOpdID === x.werkbon.ServOpdID,
      );
      return { ...x, serviceopdracht };
    });

    setWerkbonnen(werkbonnen);
  }, [props.inkFactID]);

  useEffect(() => {
    ophalenWerkbonnen();
  }, [ophalenWerkbonnen]);

  const initialValues = useMemo<IFormikValues | null>(() => {
    return {
      gekoppeld: false,
    };
  }, []);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      setUrlStateSync('selectie', []);
      onSuccess({});

      actions.setSubmitting(false);
    },
    [onSuccess],
  );

  const koppelenWerkbonnen = useCallback(
    async (ids: number[]) => {
      const params = {
        inkFactID: props.inkFactID,
        werkbonnen: ids.map((x) => {
          return { werkbonID: x };
        }),
      };

      // const productenResult = (
      //   await api.v2.product.ophalenProductenV2({
      //     filterSchema: { filters: [{ naam: 'IDS', data: prodIDs }] },
      //   })
      // ).producten;

      // const producten = productenResult.map((x) => {
      //   const bedrag =
      //     x.Inkoopprijs !== null
      //       ? x.DatumUitBedrijf === null
      //         ? x.Inkoopprijs
      //         : -x.Inkoopprijs
      //       : null;
      //   return {
      //     prodID: x.ProdID,
      //     bedrag,
      //   };
      // });
      // const params = { inkFactID: props.inkFactID, producten };

      await api.v2.inkoopfactuur.werkbon.toevoegenGekoppeldeWerkbonnen(params);

      setUrlStateSync('selectie', []);

      ophalenWerkbonnen();
    },
    [onSuccess],
  );

  const verwijderenWerkbonnen = useCallback(
    async (ids: number[]) => {
      if (
        (
          await checkStore.bevestigen({
            inhoud: 'Gekoppelde werkbonnen verwijderen?',
          })
        ).type === EResultType.Annuleren
      ) {
        return;
      }

      const params = { ids };

      await api.v2.inkoopfactuur.werkbon.verwijderenGekoppeldeWerkbonnen(params);
      setUrlStateSync('selectie', []);

      ophalenWerkbonnen();
    },
    [onSuccess],
  );

  const validationSchema = useMemo(() => {
    const fields: Record<keyof IFormikValues, any> = {
      gekoppeld: Yup.date().nullable(),
    };
    return Yup.object().shape(fields);
  }, []);

  const context = useMemo<IKoppelenProductenDialoogContext>(() => {
    return {
      factuur,
    };
  }, [factuur]);

  const kolommenWerkbonnen = useMemo<TypedColumn<IRegel>[]>(
    () => [
      {
        name: '__opdrachtnummer' as any,
        title: 'Opd.nr.',
      },
      {
        name: '__bezoekdatum' as any,
        title: 'Bez.datum',
      },
      {
        name: '__opdrachtdatum' as any,
        title: 'Opd.datum',
      },
      {
        name: '__servicedienst' as any,
        title: 'Serv.dienst',
      },
      {
        name: '__postcode' as any,
        title: 'Postcode',
      },
      {
        name: '__huisnummer' as any,
        title: 'Huisnr.',
      },
      {
        name: '__straatnaam' as any,
        title: 'Straatnaam',
      },
      {
        name: 'Bedrag',
        title: 'Kosten',
      },
      {
        name: '__uitvoerendeServicedienst' as any,
        title: 'Uitgev. door',
      },
      // {
      //   name: 'Werkzaamheden' as any,
      //   title: 'Werkzaamheden',
      // },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRegel>[]>(
    () => [
      {
        columnName: '__bezoekdatum' as any,
        width: 110,
      },
      {
        columnName: '__opdrachtdatum' as any,
        width: 110,
      },
      {
        columnName: '__servicedienst' as any,
        width: 150,
      },
      {
        columnName: '__uitvoerendeServicedienst' as any,
        width: 135,
      },
      {
        columnName: 'Werkzaamheden',
        width: 350,
      },
      {
        columnName: '__postcode' as any,
        width: 100,
      },
      {
        columnName: '__huisnummer' as any,
        width: 90,
      },
      {
        columnName: '__straatnaam' as any,
        width: 150,
      },
      {
        columnName: 'Bedrag',
        width: 100,
      },
      {
        columnName: '__opdrachtnummer' as any,
        width: 90,
      },
    ],
    [],
  );

  const nogTeKoppelenBedrag = useMemo(() => {
    if (factuur === null || werkbonnen === null) {
      return null;
    }
    return factuur.Bedrag - factuur.BedragBtw - _.sum(werkbonnen.map((x) => x.Bedrag));
  }, [factuur, werkbonnen]);

  return (
    <KoppelProductenDialoogContext.Provider value={context}>
      <Dialoog index={dialoogIndex || 0} modalProps={{ size: 'xl' }}>
        <ModalHeader>
          <ModalTitle>Koppelen werkbonnen</ModalTitle>
        </ModalHeader>

        {initialValues === null ||
        factuur === null ||
        werkbonnen === null ||
        nogTeKoppelenBedrag === null ? (
          <div className="d-flex flex-column flex-fill align-items-center justify-content-center">
            <LoadingSpinner />
          </div>
        ) : (
          <Formik<IFormikValues>
            onSubmit={handleSubmit}
            initialValues={initialValues}
            validationSchema={validationSchema}
            render={(formikProps: FormikProps<IFormikValues>) => {
              const { submitForm, isSubmitting, isValid, values } = formikProps;

              return (
                <>
                  {/* <Tabblad
                    onSelectieChange={(x) => setTabblad(x)}
                    tabbladen={tabbladen}
                    geselecteerd={tabblad}
                  /> */}
                  <ModalBody style={{ height: 600 }}>
                    <div className="form-group">
                      <div className="row">
                        <div className="col-12">
                          <VeldWeergave>
                            <div className="d-flex">
                              <div style={{ width: 375 }}>
                                <GegevensLayout
                                  gegevens={[
                                    {
                                      label: 'Fact.nr.',
                                      waarde: `${factuur.Factuurnummer}`,
                                    },
                                    {
                                      label: 'Factuurdatum',
                                      waarde: `${format(
                                        new Date(factuur.Factuurdatum),
                                        'dd-MM-yyyy',
                                      )}`,
                                    },
                                    {
                                      label: 'Fact.bedrag',
                                      waarde: <FormatteerBedrag bedrag={factuur.Bedrag} />,
                                    },
                                    {
                                      label: 'Fact.bedrag Excl. btw',
                                      waarde: (
                                        <FormatteerBedrag
                                          bedrag={factuur.Bedrag - factuur.BedragBtw}
                                        />
                                      ),
                                    },
                                  ]}
                                />
                              </div>

                              <div style={{ width: 350 }}>
                                <GegevensLayout
                                  gegevens={[
                                    {
                                      label: 'Aantal gekoppelde werkbonnen',
                                      waarde: `${werkbonnen.length}`,
                                    },
                                    {
                                      label: 'Totaal gekoppeld bedrag',
                                      waarde: (
                                        <FormatteerBedrag
                                          bedrag={_.sum(werkbonnen.map((x) => x.Bedrag))}
                                        />
                                      ),
                                    },
                                    {
                                      label: 'Nog te koppelen bedrag',
                                      waarde:
                                        nogTeKoppelenBedrag !== 0 ? (
                                          <FormatteerBedrag
                                            bedrag={nogTeKoppelenBedrag}
                                            opmaakComponent={(opmaakProps) => {
                                              return (
                                                <StandaardMutatieBedragOpmaker
                                                  {...opmaakProps}
                                                  color={Kleur.Rood}
                                                />
                                              );
                                            }}
                                          />
                                        ) : (
                                          <FormatteerBedrag
                                            bedrag={nogTeKoppelenBedrag}
                                            opmaakComponent={(opmaakProps) => {
                                              return (
                                                <StandaardMutatieBedragOpmaker
                                                  {...opmaakProps}
                                                  color={Kleur.Groen}
                                                />
                                              );
                                            }}
                                          />
                                        ),
                                    },
                                    // {
                                    //   label: 'Nog te koppelen bedrag',
                                    //   waarde:

                                    // },

                                    // {
                                    //   label: 'Nog te kop. bedrag',
                                    //   waarde:
                                    //     bedragGeboekt100 !== null ? (
                                    //       bedragGeboekt100 - bedragGekoppeld === 0 ? (
                                    //         <FormatteerBedrag
                                    //           bedrag={bedragGeboekt100 - bedragGekoppeld}
                                    //           opmaakComponent={(opmaakProps) => {
                                    //             return (
                                    //               <StandaardMutatieBedragOpmaker
                                    //                 {...opmaakProps}
                                    //                 color={Kleur.Groen}
                                    //               />
                                    //             );
                                    //           }}
                                    //         />
                                    //       ) : (
                                    //         <FormatteerBedrag
                                    //           bedrag={bedragGeboekt100 - bedragGekoppeld}
                                    //           opmaakComponent={(opmaakProps) => {
                                    //             return (
                                    //               <StandaardMutatieBedragOpmaker
                                    //                 {...opmaakProps}
                                    //                 color={Kleur.Rood}
                                    //               />
                                    //             );
                                    //           }}
                                    //         />
                                    //       )
                                    //     ) : (
                                    //       <span></span>
                                    //     ),
                                    // },
                                  ]}
                                />
                              </div>
                            </div>

                            <div style={{ width: 500 }} className="mt-3">
                              <GegevensLayout
                                gegevens={[
                                  {
                                    label: 'Crediteur',
                                    waarde: (
                                      <span>
                                        <RelatieVisualisatie relID={factuur.RelID} />
                                      </span>
                                    ),
                                  },
                                  {
                                    label: 'Onderwerp',
                                    waarde: `${factuur.Onderwerp}`,
                                  },
                                ]}
                              />
                            </div>
                          </VeldWeergave>
                        </div>

                        <div className="col-12 mt-3">
                          <div className="d-flex">
                            <button
                              className="btn btn-sm btn-light d-flex align-items-center"
                              disabled={!true}
                              style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                              onClick={() => setSelecterenProductenDialoogState({ factuur })}
                            >
                              <IconToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                              <span className="ml-2">Toevoegen</span>
                            </button>

                            <button
                              className="btn btn-sm btn-light d-flex align-items-center ml-3"
                              disabled={urlState.selectie.length === 0}
                              style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                              onClick={() => verwijderenWerkbonnen(urlState.selectie)}
                            >
                              <IconVerwijderen
                                style={{ width: 16, height: 16, fill: Kleur.Grijs }}
                              />
                              <span className="ml-2">Verwijderen</span>
                            </button>
                          </div>

                          <div className="mt-3">
                            <SelectieVak
                              aantal={urlState.selectie.length}
                              totaalAantal={werkbonnen.length}
                              onChange={(alles) => {
                                if (alles) {
                                  setUrlStateSync(
                                    'selectie',
                                    werkbonnen.map((x) => x.ID),
                                  );
                                } else {
                                  setUrlStateSync('selectie', []);
                                }
                              }}
                            />
                          </div>
                        </div>

                        <div className="col-12 mt-3">
                          <div className="d-flex flex-column flex-fill mt-2">
                            <>
                              <GridStyleWrapper maxHeight={500} rowAmount={werkbonnen.length}>
                                <Grid
                                  getRowId={(x) => x.ID}
                                  rows={werkbonnen}
                                  columns={kolommenWerkbonnen}
                                >
                                  <DataTypeProvider
                                    for={['__bezoekdatum']}
                                    formatterComponent={(formatterProps) => {
                                      const rij: IRegel = formatterProps.row;
                                      return (
                                        <span>
                                          {rij.werkbon.Bezoekdatum !== null
                                            ? format(
                                                new Date(rij.werkbon.Bezoekdatum),
                                                'dd-MM-yyyy',
                                              )
                                            : ''}
                                        </span>
                                      );
                                    }}
                                  />

                                  <DataTypeProvider
                                    for={['__opdrachtdatum']}
                                    formatterComponent={(formatterProps) => {
                                      const rij: IRegel = formatterProps.row;

                                      if (rij.serviceopdracht === null) {
                                        return <span></span>;
                                      }

                                      return (
                                        <span>
                                          {rij.serviceopdracht.Opdrachtdatum !== null
                                            ? format(
                                                new Date(rij.serviceopdracht.Opdrachtdatum),
                                                'dd-MM-yyyy',
                                              )
                                            : ''}
                                        </span>
                                      );
                                    }}
                                  />

                                  <DataTypeProvider
                                    for={['__opdrachtnummer']}
                                    formatterComponent={(formatterProps) => {
                                      const rij: IRegel = formatterProps.row;

                                      if (rij.serviceopdracht === null) {
                                        return <span></span>;
                                      }

                                      const melding = rij.serviceopdracht.melding;

                                      return (
                                        <span>
                                          {melding.Meldnummer}-{rij.serviceopdracht.Volgnummer}
                                        </span>
                                      );
                                    }}
                                  />

                                  <DataTypeProvider
                                    for={['__servicedienst']}
                                    formatterComponent={(formatterProps) => {
                                      const rij: IRegel = formatterProps.row;

                                      if (rij.serviceopdracht === null) {
                                        return <span></span>;
                                      }

                                      return (
                                        <span>
                                          {rij.serviceopdracht.dienst.relatie?.organisatie?.Naam}
                                        </span>
                                      );
                                    }}
                                  />

                                  <DataTypeProvider
                                    for={['__postcode']}
                                    formatterComponent={(formatterProps) => {
                                      const rij: IRegel = formatterProps.row;

                                      if (rij.serviceopdracht === null) {
                                        return <span></span>;
                                      }

                                      return (
                                        <span>{rij.serviceopdracht.melding.locatie.Postcode}</span>
                                      );
                                    }}
                                  />

                                  <DataTypeProvider
                                    for={['__huisnummer']}
                                    formatterComponent={(formatterProps) => {
                                      const rij: IRegel = formatterProps.row;

                                      if (rij.serviceopdracht === null) {
                                        return <span></span>;
                                      }

                                      return (
                                        <span>
                                          {rij.serviceopdracht.melding.locatie.Huisnummer}{' '}
                                          {rij.serviceopdracht.melding.locatie.Bisnummer}
                                        </span>
                                      );
                                    }}
                                  />

                                  <DataTypeProvider
                                    for={['__straatnaam']}
                                    formatterComponent={(formatterProps) => {
                                      const rij: IRegel = formatterProps.row;

                                      if (rij.serviceopdracht === null) {
                                        return <span></span>;
                                      }

                                      return (
                                        <span>
                                          {rij.serviceopdracht.melding.locatie.Straatnaam}
                                        </span>
                                      );
                                    }}
                                  />

                                  <DataTypeProvider
                                    for={['__uitvoerendeServicedienst']}
                                    formatterComponent={(formatterProps) => {
                                      const rij: IRegel = formatterProps.row;

                                      if (rij.serviceopdracht === null) {
                                        return <span></span>;
                                      }

                                      if (rij.serviceopdracht.uitvoerendeDienst === null) {
                                        return <span></span>;
                                      }

                                      return (
                                        <span>
                                          {
                                            rij.serviceopdracht.uitvoerendeDienst.relatie
                                              ?.organisatie?.Naam
                                          }
                                        </span>
                                      );
                                    }}
                                  />

                                  <DataTypeProvider
                                    for={[nameOf<IRegel>('Bedrag')]}
                                    formatterComponent={(formatterProps) => {
                                      const rij: IRegel = formatterProps.row;
                                      return <FormatteerBedrag bedrag={formatterProps.value} />;
                                    }}
                                  />

                                  <EditingState
                                    onAddedRowsChange={() =>
                                      setSelecterenProductenDialoogState({ factuur })
                                    }
                                    onCommitChanges={async (changes) => {
                                      if (changes.deleted === undefined) {
                                        return;
                                      }
                                      const deleted = changes.deleted;
                                      const id = deleted[deleted.length - 1] as number;
                                      alert(id);
                                    }}
                                    onEditingRowIdsChange={(rowIds) => {
                                      const id = rowIds[rowIds.length - 1] as number;
                                    }}
                                  />

                                  <SortingState defaultSorting={[]} />
                                  <IntegratedSorting />
                                  <Table messages={{ noData: 'Geen gekoppelde werkbonnen' }} />
                                  <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
                                  {/* <TableEditColumn
                                    width={35}
                                    // showAddCommand
                                    showEditCommand
                                    // showDeleteCommand
                                    cellComponent={DXTableEditColumnCellComponent}
                                    commandComponent={DXTableEditColumnCommandComponent}
                                  /> */}
                                  <TableHeaderRow showSortingControls />

                                  <SelectionState
                                    selection={urlState.selectie}
                                    onSelectionChange={(x) =>
                                      setUrlStateSync('selectie', x as number[])
                                    }
                                  />
                                  <TableSelection cellComponent={DXTableCheckboxComponent} />
                                </Grid>
                              </GridStyleWrapper>
                            </>
                          </div>
                          {/* </div> */}
                        </div>
                        <div className="col-12">
                          <span></span>
                        </div>
                      </div>
                    </div>
                  </ModalBody>

                  <ModalFooter className="d-flex flex-row justify-content-start">
                    {/* <button
                      className="btn btn-primary"
                      onClick={submitForm}
                      style={{ width: 100 }}
                      disabled={!isValid || isSubmitting}
                    >
                      Ok
                    </button> */}
                    <button
                      className="btn btn-primary"
                      onClick={onAnnuleren}
                      style={{ width: 100 }}
                      disabled={isSubmitting}
                    >
                      Sluiten
                    </button>
                  </ModalFooter>
                </>
              );
            }}
          />
        )}
      </Dialoog>
      {selecterenProductenDialoogState !== null && (
        <SelecterenWerkbonnenDialoog
          open
          relID={factuur!.RelID}
          factuur={factuur}
          onSuccess={(data) => {
            koppelenWerkbonnen(data.ids);
            setSelecterenProductenDialoogState(null);
            ophalenWerkbonnen();
          }}
          onAnnuleren={() => {
            setSelecterenProductenDialoogState(null);
          }}
          dialoogIndex={(props.dialoogIndex ?? 0) + 1}
        />
      )}
    </KoppelProductenDialoogContext.Provider>
  );
};

export default withRouter(KoppelWerkbonnenDialoog);
