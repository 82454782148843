import React from 'react';
import { IOphalenAbonnementenVoorLijstResultElement } from '../../../../../../../../shared/src/api/v2/aanbod/Abonnement';
import BedragInput from '../../../../../../components/formulier/BedragInput';

interface IProps {
  abonnement: IOphalenAbonnementenVoorLijstResultElement;
  maandHuur: number;
  onMaandHuurChange: (value: number) => void;
  eenmaligBedrag: number;
  onEenmaligBedragChange: (value: number) => void;
}

const AbonnementTariefFormulier: React.FC<IProps> = (props) => {
  const {
    abonnement,
    eenmaligBedrag,
    onEenmaligBedragChange,
    onMaandHuurChange,
    maandHuur,
  } = props;

  return (
    // <Tegel>
    <div className="d-flex flex-column pl-2 pr-2 p-1">
      <div className="d-flex align-items-center">
        <div>
          <span className="font-weight-bold">{abonnement.Naam}</span>
        </div>
        <div className="ml-4">
          <span>Huur</span>
        </div>
        <div style={{ flex: 1 }} className="ml-2">
          <BedragInput value={maandHuur} onChange={onMaandHuurChange} min={0} />
        </div>
        <div className="ml-3">
          <span>Eenmalig</span>
        </div>
        <div style={{ flex: 1 }} className="ml-2">
          <BedragInput value={eenmaligBedrag} onChange={onEenmaligBedragChange} min={0} />
        </div>
      </div>
    </div>
    // </Tegel>
  );
};

export default AbonnementTariefFormulier;
