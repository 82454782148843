import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import IDialoogProps from '../../../../core/IDialoogProps';
import Dialoog from '../../../../components/dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import * as Yup from 'yup';
import api from '../../../../api';
import nameOf from '../../../../core/nameOf';
import nameof from '../../../../core/nameOf';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../../stores/RootStore';
import {
  IOphalenDocumentenResultElement,
  IWijzigenDocumentParams,
} from '../../../../../../shared/src/api/v2/product/documentatie';
import TalenSelectie from '../../../../components/formulier/TalenSelectie';
import MultiSelect, { IOptie } from '../../../../components/formulier/MultiSelect';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
  mapRemoteData,
} from '../../../../models/IRemoteData';
import { IOphalenProductsoortenResult } from '../../../../../../shared/src/api/v2/product/soort';
import Skeleton from 'react-loading-skeleton';
import VinkVeld from '../../../../components/formulier/VinkVeld';

interface IProps extends IDialoogProps<null> {
  id: number;
}

interface IFormikValues {
  naam: string;
  naamEnum: string;
  taalIDs: number[];
  prodSrtIDs: number[];
  meesturenNaLevering: boolean;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  naam: 'Naam',
  naamEnum: 'Naam enum',
  taalIDs: 'Talen',
  prodSrtIDs: 'Productsoorten',
  meesturenNaLevering: 'Meesturen na levering',
};

const WijzigenDocumentDialoog: React.FC<IProps> = observer((props) => {
  const { dialoogIndex, onAnnuleren, onSuccess } = props;
  const { checkStore } = useContext(RootStoreContext);
  const formikRef = useRef<Formik<IFormikValues>>(null);

  const [document, setDocument] = useState<IOphalenDocumentenResultElement | null>(null);

  const ophalenDocument = useCallback(async () => {
    const result = (
      await api.v2.product.documentatie.ophalenDocumenten({
        filterSchema: { filters: [{ naam: 'IDS', data: [props.id] }] },
      })
    ).documenten;

    setDocument(result[0]);
  }, [props.id]);

  useEffect(() => {
    ophalenDocument();
  }, [ophalenDocument]);

  const [productsoortenResult, setProductsoortenResult] = useState<
    IRemoteData<IOphalenProductsoortenResult>
  >(createPendingRemoteData());
  const ophalenProductsoorten = useCallback(async () => {
    const result = await api.v2.product.soort.ophalenProductsoorten({});
    setProductsoortenResult(createReadyRemoteData(result));
  }, []);
  useEffect(() => {
    ophalenProductsoorten();
  }, [ophalenProductsoorten]);

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (document === null) {
      return null;
    }

    return {
      naam: document.Naam,
      naamEnum: document.NaamEnum ?? '',
      taalIDs: document.talen.map((x) => x.TaalID),
      prodSrtIDs: document.productsoorten.map((x) => x.ProdSrtID),
      meesturenNaLevering: document.MeesturenNaLevering,
    };
  }, [document]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      if (document === null) {
        return;
      }
      actions.setSubmitting(true);

      const params: IWijzigenDocumentParams = {
        id: props.id,
        naam: values.naam,
        naamEnum: values.naamEnum,
        prodDocSrtID: document.documentsoort.ID,
        documentURL: document.DocumentURL,
        taalIDs: values.taalIDs,
        documentBestandID: document.bestand?.ID ?? null,
        prodSrtIDs: values.prodSrtIDs,
        meesturenNaLevering: values.meesturenNaLevering,
      };

      await api.v2.product.documentatie.wijzigenDocument(params);

      await onSuccess(null);
      actions.setSubmitting(false);
    },
    [document],
  );

  const validationSchema = useMemo(() => {
    const fields: Record<keyof IFormikValues, any> = {
      naam: Yup.string().required(),
      naamEnum: Yup.string(),
      taalIDs: Yup.array().of(Yup.number()),
      prodSrtIDs: Yup.array().of(Yup.number()),
      meesturenNaLevering: Yup.boolean(),
    };
    return Yup.object().shape(fields);
  }, []);

  const prodSrtOpties = useMemo<IRemoteData<IOptie<number>[]>>(() => {
    return mapRemoteData<IOphalenProductsoortenResult, IOptie<number>[]>(
      productsoortenResult,
      (x) =>
        x.map((y) => ({
          key: y.ProdSrtID,
          weergave: y.Naam,
        })),
    );
  }, [productsoortenResult]);

  return (
    <Dialoog index={dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Wijzigen document</ModalTitle>
      </ModalHeader>
      {initialValues === null ? (
        <LoadingSpinner />
      ) : (
        <Formik<IFormikValues>
          ref={formikRef}
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
          render={(formikProps: FormikProps<IFormikValues>) => {
            const { submitForm, isSubmitting, isValid } = formikProps;
            return (
              <>
                <ModalBody>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-12">
                        <label>{veldnamen.naam}</label>
                        <Field
                          name={nameOf<IFormikValues>('naam')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            return (
                              <input type="text" className="form-control" {...fieldProps.field} />
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <label>{veldnamen.naamEnum}</label>
                        <Field
                          name={nameOf<IFormikValues>('naamEnum')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            return (
                              <input type="text" className="form-control" {...fieldProps.field} />
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <label>{veldnamen.taalIDs}</label>
                        <Field
                          name={nameof<IFormikValues>('taalIDs')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            return (
                              <>
                                <TalenSelectie
                                  taalIDs={field.value}
                                  onChange={(taalIDs) => form.setFieldValue('taalIDs', taalIDs)}
                                />
                                {/* <FormikVeldFout fieldProps={fieldProps} /> */}
                              </>
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <label>{veldnamen.prodSrtIDs}</label>
                        <Field
                          name={nameof<IFormikValues>('prodSrtIDs')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            if (prodSrtOpties.state === ERemoteDataState.Pending) {
                              return <Skeleton />;
                            }

                            return (
                              <>
                                <MultiSelect
                                  value={field.value}
                                  onChange={(x) => form.setFieldValue(field.name, x)}
                                  opties={prodSrtOpties.data!}
                                />
                                {/* <FormikVeldFout fieldProps={fieldProps} /> */}
                              </>
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 d-flex align-items-center mt-3">
                        <Field
                          name={nameof<IFormikValues>('meesturenNaLevering')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <VinkVeld
                                aangevinkt={field.value}
                                onGewijzigd={(x) => {
                                  form.setFieldValue(field.name, x);
                                }}
                              />
                            );
                          }}
                        />
                        <span className="ml-2">{veldnamen.meesturenNaLevering}</span>
                      </div>

                      {/* <div className="col-12 mt-2">
                        <label>{veldnamen.naamKort}</label>
                        <Field
                          name={nameOf<IFormikValues>('naamKort')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            return <textarea {...field} className="form-control" rows={2} />;
                          }}
                        />
                      </div> */}
                    </div>
                  </div>
                </ModalBody>

                <ModalFooter className="d-flex flex-row justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    style={{ width: 100 }}
                    disabled={isSubmitting || !isValid}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={onAnnuleren}
                    style={{ width: 100 }}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Dialoog>
  );
});

export default WijzigenDocumentDialoog;
