import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { ISponsorContract } from '../../../../../shared/src/api/v2/sponsoringv2/contract';
import api from '../../../api';
import { Kleur } from '../../../bedrijfslogica/constanten';
import { IFilterData } from '../../../components/FilterBalkV2';
import { IconToevoegen, IconVerwijderen } from '../../../components/Icons';
import MenuLayout from '../../../components/MenuLayout';
import ASPTabel from '../../../components/tabel/ASPTabel';
import TableData, { ITableDataProps } from '../../../components/tabel/ASPTabel/Body/TableData';
import { ASPKolom, EAspKolomBreedteType } from '../../../components/tabel/ASPTabel/types';
import useUrlState from '../../../core/useUrlState';
import { EResultType } from '../../../stores/CheckStore';
import { RootStoreContext } from '../../../stores/RootStore';
import MuterenDialoog from './MuterenDialoog';
import { ISponsorDienst } from '../../../../../shared/src/api/v2/sponsoringv2/event';
import UitgeklapteRij from './UitgeklapteRij';
import { format } from 'date-fns';

export type Kolom =
  | 'SponDienstID'
  | 'IsDefinitief'
  | 'Bedrag'
  | 'Onderwerp'
  | 'RegistratieDatum'
  | 'ContractDatum';

export enum EFilter {
  Zoekterm = 'ZOEKTERM',
}

interface IProps extends RouteComponentProps {
  dienstID?: number;
}

interface IToevoegenDialoogState {}
interface IWijzigenDialoogState {
  id: number;
}
interface IUrlState {
  selectie: number[];
  filterdata: IFilterData<EFilter>[];
  toevoegenDialoogState: IToevoegenDialoogState | null;
  muterenDialoogState: IWijzigenDialoogState | null;
  uitgeklapt: number[];
}

const defaultUrlState: IUrlState = {
  selectie: [],
  filterdata: [],
  toevoegenDialoogState: null,
  muterenDialoogState: null,
  uitgeklapt: [],
};

const Item: React.FC<IProps> = (props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const { checkStore } = useContext(RootStoreContext);

  // rijen
  const [rijen, setRijen] = useState<ISponsorContract[] | null>(null);
  const ophalenRijen = async () => {
    const filterSchema = !props.dienstID
      ? undefined
      : { filters: [{ naam: 'DIENST_IDS', data: [props.dienstID] }] };
    const results = await api.v2.sponsoringv2.contract.ophalenContracten({ filterSchema });
    setRijen(results);
  };

  useEffect(() => {
    ophalenRijen();
  }, []);

  // diensten
  const [diensten, setDiensten] = useState<ISponsorDienst[] | null>(null);
  const ophalenDiensten = async () => {
    if (rijen == null) return;
    const ids = rijen.map((r) => r.SponDienstID);

    const results = await api.v2.dienst.sponsoring.ophalenDiensten({
      filterSchema: {
        filters: [{ naam: 'IDS', data: ids }],
      },
    });

    setDiensten(results.diensten);
  };

  useEffect(() => {
    ophalenDiensten();
  }, [rijen]);

  const handleToevoegen = useCallback(async () => {
    setUrlStateSync('muterenDialoogState', { id: null });
  }, []);

  const handleVerwijderen = useCallback(async () => {
    const params = { ids: urlState.selectie };
    if (
      (
        await checkStore.bevestigen({
          inhoud: <span>Geselecteerde items verwijderen?</span>,
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    await api.v2.sponsoringv2.contract.verwijderenContracten(params);
    setUrlStateSync('selectie', []);

    await ophalenRijen();
  }, [urlState.selectie]);

  const keyExtractor = useCallback((row: ISponsorContract) => (row.ID == null ? 0 : row.ID), [
    rijen,
  ]);

  const kolommen = useMemo<ASPKolom<Kolom, ISponsorContract>[]>(
    () => [
      {
        key: 'RegistratieDatum',
        label: 'Registratiedatum',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 300,
        renderer: (x) => <span>{format(new Date(x.Registratiedatum!), 'dd-MM-yyyy')}</span>,
      },
      {
        key: 'ContractDatum',
        label: 'ContractDatum',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 300,
        renderer: (x) => <span>{format(new Date(x.Contractdatum!), 'dd-MM-yyyy')}</span>,
      },
      {
        key: 'SponDienstID',
        label: 'Sponsordienst',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 300,
        renderer: (x) => (
          <span>{diensten?.find((d) => d.ID == x.SponDienstID)?.Naam ?? '...'}</span>
        ),
      },
      {
        key: 'Onderwerp',
        label: 'Onderwerp',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 300,
        renderer: (x) => x.Onderwerp,
      },
      {
        key: 'IsDefinitief',
        label: 'IsDefinitief',
        breedteType: EAspKolomBreedteType.Flex,
        flex: 1,
        renderer: (x) => (x.IsDefinitief ? 'definitief' : 'niet definitief'),
      },
    ],
    [diensten, rijen],
  );

  return (
    <>
      <Helmet>
        <title>Sponsorcontracten</title>
      </Helmet>
      <MenuLayout
        menu={
          <div className="d-flex align-items-center">
            <button
              className="btn btn-sm btn-light d-flex align-items-center"
              style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
              disabled={false}
              onClick={() => handleToevoegen()}
            >
              <IconToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
              <span className="ml-2">Toevoegen</span>
            </button>
            <button
              className="btn btn-sm btn-light d-flex align-items-center ml-3"
              style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
              disabled={urlState.selectie.length === 0}
              onClick={() => handleVerwijderen()}
            >
              <IconVerwijderen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
              <span className="ml-2">Verwijderen</span>
            </button>
          </div>
        }
        body={
          rijen == null ? (
            <span></span>
          ) : (
            <ASPTabel
              rijen={rijen}
              kolommen={kolommen}
              keyExtractor={keyExtractor}
              selectie={urlState.selectie}
              onSelectieChange={(selectie) => setUrlStateSync('selectie', selectie)}
              onWijzigenRij={async (rij) => {
                setUrlStateSync('muterenDialoogState', { id: rij.ID });
              }}
              tdComponent={TdComponent}
              // uitklapbare rij
              uitgeklapt={urlState.uitgeklapt}
              onUitgeklaptChange={(value) => setUrlStateSync('uitgeklapt', value)}
              uitgeklapteRijComponent={UitgeklapteRij}
              rijHoogte={40}
              uitgeklapteRijHoogte={(rij) => 300}
              magRijUitklappen={(rij) => true}
            />
          )
        }
      />
      {urlState.muterenDialoogState !== null && (
        <MuterenDialoog
          open
          id={urlState.muterenDialoogState.id}
          dienstID={props.dienstID}
          onSuccess={async () => {
            setUrlStateSync('muterenDialoogState', null);
            await ophalenRijen();
          }}
          onAnnuleren={() => setUrlStateSync('muterenDialoogState', null)}
        />
      )}
    </>
  );
};

const TdComponent = (props: ITableDataProps<Kolom, ISponsorContract>) => {
  return (
    <TableData
      {...props}
      style={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
        display: 'block',
        paddingTop: '8px',
      }}
    />
  );
};

export default withRouter(Item);
