import styled from 'styled-components';
import { colors } from '../../globalstyles/variables';
import { Kleur } from '../../bedrijfslogica/constanten';

export const StyledPaginaHeader = styled.header`
  width: 100%;
  background-color: white;
  box-shadow: rgba(82, 63, 105, 0.1) 0 0 40px 0;
  border-bottom: 1px solid ${Kleur.LichtGrijs};
  display: flex;
  /* height: 5vh; */
  align-items: center;
  /* padding-left: 0.6rem; */
  //padding: 0.6rem 1rem;
  justify-content: left;
  ul {
    margin: 0;
    padding: 0;
    height: 100%;

    .active {
      transition: background-color 0.3s ease;
      background-color: ${Kleur.DoorschijnendLichtBlauw};
      // padding-bottom: 8px;
      border-bottom: 1px solid ${Kleur.HeelLichtBlauw};
    }
  }
  ul > li {
    display: inline-block;
    height: 100%;
  }
  a {
    //border-radius: 4px;
    padding: 0.5rem;
    //margin-right: 20px;
    transition: background-color 0.3s ease;
    height: 100%;
    display: flex;
    align-items: center;
    svg {
      margin-right: 5px;
      fill: ${colors.primaryBlue};
    }
    &:hover {
      transition: background-color 0.3s ease;
      background-color: ${colors.activeNavBlue};
      text-decoration: none;
    }
  }
`;

export const Main = styled.div`
  flex: 1;
  display: flex;
  height: 100%;
`;

export const UserContainer = styled.div`
  margin-left: 10px;
  margin-right: 15px;
  font-size: 15px;
  cursor: pointer;
`;
