import React, {
  ForwardRefExoticComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import * as _ from 'lodash';
import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';
import LoadingSpinner from '../../Gedeeld/LoadingSpinner';

export interface IInputComponentProps extends React.HTMLProps<HTMLInputElement> {}

export interface IExposeData {
  forceerResolveSuggesties: () => void;
}

interface IProps {
  waarde: string;
  onChange: (waarde: string) => void;
  suggestiesResolver: (waarde: string) => Promise<string[] | null>;
  onSuggestieGekozen?: (waarde: string) => void;
  inputComponent?: React.ForwardRefRenderFunction<any, IInputComponentProps>;
  suggestiesResolveDebounceTimeoutMs?: number;
  onExpose?: (data: IExposeData) => void;
  bijGeenSuggestiesMeldingWeergeven?: boolean;
  autoFocus?: boolean;
}

const DefaultInputComponent: React.ForwardRefRenderFunction<any, IInputComponentProps> = (
  props,
  ref,
) => {
  return <input ref={ref} type="text" className="form-control" {...props} />;
};

const defaultSuggestiesResolveDebounceTimeoutMs = 500;

const TekstSuggestieVeld: React.FC<IProps> = (props) => {
  const [suggesties, setSuggesties] = useState<string[] | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [focused, setFocused] = useState(false);
  const suggestiesResolveDebounceCancelFn = useRef<(() => void) | null>(null);

  const InputComponent = useMemo<React.ForwardRefExoticComponent<any>>(
    () =>
      React.forwardRef(
        props.inputComponent === undefined ? DefaultInputComponent : props.inputComponent,
      ),
    [props.inputComponent],
  );

  const resolveSuggestiesWithDebounce = useCallback(() => {
    if (suggestiesResolveDebounceCancelFn.current !== null) {
      suggestiesResolveDebounceCancelFn.current();
      suggestiesResolveDebounceCancelFn.current = null;
    }

    const debounced = _.debounce(async () => {
      setSuggesties(null);
      const suggesties = await props.suggestiesResolver(props.waarde);
      setSuggesties(suggesties);
    }, props.suggestiesResolveDebounceTimeoutMs || defaultSuggestiesResolveDebounceTimeoutMs);

    suggestiesResolveDebounceCancelFn.current = debounced.cancel;
    debounced();
  }, [props.waarde, props.suggestiesResolver]);

  useEffect(() => {
    resolveSuggestiesWithDebounce();
  }, [props.waarde, resolveSuggestiesWithDebounce]);

  const handleInputChange = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      const waarde = ev.target.value;
      props.onChange(waarde);
    },
    [props.onChange],
  );

  const expose = useMemo<IExposeData>(
    () => ({
      forceerResolveSuggesties: resolveSuggestiesWithDebounce,
    }),
    [resolveSuggestiesWithDebounce],
  );
  useEffect(() => {
    if (props.onExpose !== undefined) {
      props.onExpose(expose);
    }
  }, [props.onExpose, expose]);

  return (
    <>
      <InputComponent
        ref={inputRef}
        value={props.waarde}
        onChange={handleInputChange}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        autoFocus={props.autoFocus}
      />
      {suggesties !== null &&
      suggesties.length === 0 &&
      !props.bijGeenSuggestiesMeldingWeergeven ? null : (
        <Overlay
          show={focused}
          target={inputRef.current!}
          placement="bottom-start"
          onExit={() => {
            console.log('debug: Suggestie exit');
          }}
        >
          <Popover
            id="tekst-suggestieveld-popover"
            style={{
              maxHeight: 300,
              overflowY: 'auto',
              maxWidth: 999999,
            }}
          >
            {suggesties === null ? (
              <div className="d-flex flex-fill align-items-center justify-content-center p-3 pl-4 pr-4">
                <LoadingSpinner />
              </div>
            ) : suggesties.length === 0 ? (
              <div className="d-flex flex-fill align-items-center justify-content-center p-2 pl-4 pr-4">
                <span>Geen suggesties.</span>
              </div>
            ) : (
              <ul className="list-group">
                {suggesties.map((suggestie, i) => (
                  <li
                    key={i}
                    className="list-group-item"
                    style={{ cursor: 'pointer', padding: 5 }}
                    onClick={() => {
                      console.log('debug: Suggestie click');

                      props.onChange(suggestie);
                      if (props.onSuggestieGekozen !== undefined) {
                        props.onSuggestieGekozen(suggestie);
                      }
                    }}
                  >
                    {suggestie}
                  </li>
                ))}
              </ul>
            )}
          </Popover>
        </Overlay>
      )}
    </>
  );
};

export default TekstSuggestieVeld;
