import React, { useCallback, useMemo } from 'react';
import IDialoogProps from '../../../../../../core/IDialoogProps';
import Dialoog from '../../../../../../components/dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import { Field, FieldProps, Formik, FormikActions, FormikErrors } from 'formik';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import nameOf from '../../../../../../core/nameOf';
import DatumKiezer from '../../../../../../components/formulier/DatumKiezer';
import teksten from '../../../../../../bedrijfslogica/teksten';
import { dagDatum } from '../../../../../../helpers/datum';

export interface IResult {
  ingangsdatum: Date;
  einddatum: Date | null;
}
export interface IFormikValues {
  ingangsdatum: Date | null;
  einddatum: Date | null;
}

interface IProps extends IDialoogProps<IResult> {
  initialValues?: IFormikValues;
}

const ActiePeriodenMuterenDialoog: React.FC<IProps> = (props) => {
  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      props.onSuccess({
        ingangsdatum: values.ingangsdatum!,
        einddatum: values.einddatum,
      });
      actions.setSubmitting(false);
    },
    [props.onSuccess],
  );

  const initialValues = useMemo<IFormikValues>(() => {
    if (props.initialValues !== undefined) {
      return props.initialValues;
    }

    return {
      ingangsdatum: dagDatum(new Date()),
      einddatum: null,
    };
  }, [props.initialValues]);

  const validate = useCallback((values: IFormikValues) => {
    const errors: FormikErrors<IFormikValues> = {};

    if (values.ingangsdatum === null) {
      errors.ingangsdatum = teksten.formulier.E_VERPLICHT_VELD({ veldnaam: 'Ingangsdatum' });
    }

    if (
      values.ingangsdatum !== null &&
      values.einddatum !== null &&
      values.einddatum < values.ingangsdatum
    ) {
      errors.einddatum = 'Einddatum moet na ingangsdatum liggen';
    }

    return errors;
  }, []);

  return (
    <Dialoog index={props.dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Actie periode</ModalTitle>
      </ModalHeader>
      <Formik<IFormikValues>
        isInitialValid
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validate={validate}
        render={(formikProps) => {
          const { submitForm, isValid, isSubmitting } = formikProps;
          return (
            <>
              <ModalBody>
                <div className="form-group">
                  <div className="row">
                    <div className="col-4">
                      <label>Ingangsdatum</label>
                      <Field
                        name={nameOf<IFormikValues>('ingangsdatum')}
                        render={(fieldProps: FieldProps<IFormikValues>) => {
                          const { field, form } = fieldProps;
                          return (
                            <DatumKiezer
                              waarde={field.value}
                              onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                              determineNextValidDate="ONBEGRENST"
                              determinePreviousValidDate="ONBEGRENST"
                            />
                          );
                        }}
                      />
                    </div>
                    <div className="col-4">
                      <label>Einddatum</label>
                      <Field
                        name={nameOf<IFormikValues>('einddatum')}
                        render={(fieldProps: FieldProps<IFormikValues>) => {
                          const { field, form } = fieldProps;
                          return (
                            <DatumKiezer
                              waarde={field.value}
                              onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                              determineNextValidDate="ONBEGRENST"
                              determinePreviousValidDate="ONBEGRENST"
                              isClearable
                            />
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
              </ModalBody>
              <ModalFooter className="d-flex flex-row justify-content-start">
                <button
                  className="btn btn-primary"
                  onClick={submitForm}
                  style={{ width: 100 }}
                  disabled={isSubmitting || !isValid}
                >
                  Ok
                </button>
                <button
                  className="btn btn-secondary"
                  onClick={props.onAnnuleren}
                  style={{ width: 100 }}
                  disabled={isSubmitting}
                >
                  Annuleren
                </button>
              </ModalFooter>
            </>
          );
        }}
      />
    </Dialoog>
  );
};

export default ActiePeriodenMuterenDialoog;
