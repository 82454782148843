import React, { useRef, useState } from 'react';
import { IconSend, IconUitklappen } from '../../../../Icons';
import { Kleur } from '../../../../../bedrijfslogica/constanten';
import Overlay from 'react-bootstrap/Overlay';

export enum EUitstelling {
  Direct,
  EenMinuut,
  DrieMinuten,
  VijfMinuten,
}

const uitstellingTekst: Record<EUitstelling, string> = {
  [EUitstelling.Direct]: 'Direct',
  [EUitstelling.EenMinuut]: '+1 min',
  [EUitstelling.DrieMinuten]: '+3 min',
  [EUitstelling.VijfMinuten]: '+5 min',
};

interface IProps {
  disabled?: boolean;
  onVersturen: () => void;
  uitstelling: EUitstelling;
  onUitstellingChange: (uitstelling: EUitstelling) => void;
}

const VersturenKnop = (props: IProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const uitstellingKnopRef = useRef<HTMLButtonElement>(null);
  const [uitstellingSelectieOpen, setUitstellingSelectieOpen] = useState(false);

  return (
    <>
      <div className="d-flex align-items-center" ref={containerRef}>
        <button
          className="btn btn-light btn-sm ml-2 d-flex align-items-center"
          disabled={props.disabled}
          onClick={props.onVersturen}
          style={{
            border: `1px solid ${Kleur.LichtGrijs}`,
            borderRight: 0,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
          }}
        >
          <IconSend
            style={{
              fill: Kleur.Grijs,
              width: 15,
              height: 15,
              position: 'relative',
              top: 1,
            }}
          />
          <span className="ml-2">Versturen</span>
        </button>
        <button
          ref={uitstellingKnopRef}
          title="Hier mee kun je instellen wanneer de email werkelijk verstuurd zal worden"
          className="btn btn-light btn-sm d-flex align-items-center"
          disabled={props.disabled}
          style={{
            border: `1px solid ${Kleur.LichtGrijs}`,
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            paddingRight: 3,
          }}
          onClick={() => setUitstellingSelectieOpen((curr) => !curr)}
        >
          <div className="d-flex align-items-center">
            <span>{uitstellingTekst[props.uitstelling]}</span>
            <IconUitklappen
              style={{ width: 18, height: 18, fill: Kleur.Grijs, position: 'relative', top: 2 }}
            />
          </div>
        </button>
      </div>
      <Overlay
        target={uitstellingKnopRef.current!}
        show={uitstellingSelectieOpen}
        onHide={() => setUitstellingSelectieOpen(false)}
        rootClose
      >
        {({ placement, arrowProps, show, popper, ...overlayProps }) => (
          <div
            {...overlayProps}
            style={{
              position: 'absolute',
              borderRadius: 3,
              border: '1px solid #e2e2e2',
              zIndex: 99999,
              ...overlayProps.style,
            }}
          >
            <div className="list-group list-group-flush">
              {Object.keys(uitstellingTekst).map((key) => {
                const uitstelling = Number(key) as EUitstelling;

                return (
                  <div
                    key={uitstelling}
                    className="list-group-item list-group-item-action"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      props.onUitstellingChange(uitstelling);
                      setUitstellingSelectieOpen(false);
                    }}
                  >
                    {uitstellingTekst[uitstelling]}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </Overlay>
    </>
  );
};

export default VersturenKnop;
