import {
  ICheckToevoegenTariefsetParams,
  ICheckToevoegenTariefsetResult,
  IOphalenTariefsetsParams,
  IOphalenTariefsetsResult,
  IToevoegenTariefsetParams,
  IToevoegenTariefsetResult,
  IWijzigenTariefsetParams,
  IWijzigenTariefsetResult,
  IVerwijderenTariefsetsParams,
  IVerwijderenTariefsetsResult,
} from '../../../../../../shared/src/api/v2/aanbod/tarieven';
import ICheckData from '../../../../../../shared/src/models/ICheckData';
import api from '../../../index';
import acties from './acties';
import overige from './overige';

const tarieven = {
  acties,
  overige,
  ophalenTariefsets: async (
    params: IOphalenTariefsetsParams,
  ): Promise<IOphalenTariefsetsResult> => {
    return api.post('v2/aanbod/tarieven/ophalen-tariefsets', params);
  },
  toevoegenTariefset: async (
    params: IToevoegenTariefsetParams,
  ): Promise<IToevoegenTariefsetResult> => {
    return api.post('v2/aanbod/tarieven/toevoegen-tariefset', params);
  },
  checkToevoegenTariefset: async (
    params: ICheckToevoegenTariefsetParams,
  ): Promise<ICheckToevoegenTariefsetResult> => {
    return api.post('v2/aanbod/tarieven/check-toevoegen-tariefset', params);
  },
  checkWijzigenTariefset: async (params: IWijzigenTariefsetParams): Promise<ICheckData> => {
    return api.post('v2/aanbod/tarieven/check-wijzigen-tariefset', params);
  },
  wijzigenTariefset: async (
    params: IWijzigenTariefsetParams,
  ): Promise<IWijzigenTariefsetResult> => {
    return api.post('v2/aanbod/tarieven/wijzigen-tariefset', params);
  },
  checkVerwijderenTariefsets: async (params: IVerwijderenTariefsetsParams): Promise<ICheckData> => {
    return api.post('v2/aanbod/tarieven/check-verwijderen-tariefsets', params);
  },
  verwijderenTariefsets: async (
    params: IVerwijderenTariefsetsParams,
  ): Promise<IVerwijderenTariefsetsResult> => {
    return api.post('v2/aanbod/tarieven/verwijderen-tariefsets', params);
  },
};

export default tarieven;
