import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import MenuLayout from '../../../../../../../components/MenuLayout';
import { IWijzigenIncassozaakDialoogState } from '../../../../../../../components/debiteuren/IncassozakenTabel';
import useUrlState from '../../../../../../../core/useUrlState';
import api from '../../../../../../../api';
import { observer } from 'mobx-react-lite';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { RootStoreContext } from '../../../../../../../stores/RootStore';
import { IOphalenHerincassozakenResultElement } from '../../../../../../../../../shared/src/api/v2/debiteur/herincassozaak';
import LoadingSpinner from '../../../../../../../components/Gedeeld/LoadingSpinner';
import ASPTabel from '../../../../../../../components/tabel/ASPTabel';
import {
  ASPKolom,
  EAspKolomBreedteType,
} from '../../../../../../../components/tabel/ASPTabel/types';
import { format } from 'date-fns';
import UitgeklapteRij from './UitgeklapteRij';
import FormatteerBedrag from '../../../../../../../components/MutatieBedrag';
import { Kleur as EKleur } from '../../../../../../../bedrijfslogica/constanten';
import { EResultType } from '../../../../../../../stores/CheckStore';

export interface IUrlState {
  selectie: number[];
  uitgeklapt: number[];
  wijzigenIncassozaakDialoogState: IWijzigenIncassozaakDialoogState | null;
}

export const defaultUrlState: IUrlState = {
  selectie: [],
  uitgeklapt: [],
  wijzigenIncassozaakDialoogState: null,
};

interface IProps extends RouteComponentProps {}

export enum EKolom {
  Hoofdsom,
  DossierDatum,
  Zaaknummer,
  Incassodienst,
  Notities,
  Afgehandeld,
}

const Herincassozaken: React.FC<IProps> = observer((props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const { checkStore } = useContext(RootStoreContext);

  const { klantkaartStore } = useContext(RootStoreContext);

  const [herincassozaken, setHerincassozaken] = useState<
    IOphalenHerincassozakenResultElement[] | null
  >(null);

  const ophalenHerincassozaken = useCallback(async () => {
    if (klantkaartStore.relatie === null) {
      setHerincassozaken(null);
      return;
    }

    const herincassozakenResult = (
      await api.v2.debiteur.herincassozaak.ophalenHerincassozaken({
        filterSchema: {
          filters: [
            {
              naam: 'REL_IDS',
              data: [klantkaartStore.relatie.RelID],
            },
          ],
        },
        orderSchema: { orders: [{ naam: 'ID', richting: 'DESC' }] },
      })
    ).herincassozaken;

    setHerincassozaken(herincassozakenResult);
  }, [klantkaartStore.relatie === null ? null : klantkaartStore.relatie.RelID]);

  useEffect(() => {
    ophalenHerincassozaken();
  }, [ophalenHerincassozaken]);

  const herincassozakenRijen = useMemo(() => {
    if (herincassozaken === null) {
      return null;
    }

    const herincassozakenRijen = herincassozaken.reduce((acc, herincassozaak, idx) => {
      return {
        ...acc,
        [idx]: herincassozaak,
      };
    }, {});

    return herincassozakenRijen;
  }, [herincassozaken]);

  const keyExtractor = useCallback((item: IOphalenHerincassozakenResultElement) => item.ID, []);

  const kolommen = useMemo<ASPKolom<EKolom, IOphalenHerincassozakenResultElement>[]>(
    () => [
      {
        key: EKolom.DossierDatum,
        label: 'Datum',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 125,
        renderer: (item: IOphalenHerincassozakenResultElement) => {
          return item.DossierDatum === null
            ? null
            : format(new Date(item.DossierDatum), 'dd-MM-yyyy');
        },
      },
      {
        key: EKolom.Zaaknummer,
        label: 'Zaaknr.',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 90,
        renderer: (item: IOphalenHerincassozakenResultElement) => {
          return item.Zaaknummer;
        },
      },
      {
        key: EKolom.Hoofdsom,
        label: 'Hoofdsom',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 100,
        renderer: (item: IOphalenHerincassozakenResultElement) => {
          return <FormatteerBedrag bedrag={item.Hoofdsom} />;
        },
      },
      {
        key: EKolom.Incassodienst,
        label: 'Incassodienst',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 200,
        renderer: (item: IOphalenHerincassozakenResultElement) => {
          return <span>{item.incassodienst.relatie?.organisatie?.Naam}</span>;
        },
      },
      {
        key: EKolom.Notities,
        label: 'Notities',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 350,
        renderer: (item: IOphalenHerincassozakenResultElement) => {
          return item.Notities;
        },
      },
      {
        key: EKolom.Afgehandeld,
        label: 'Afg.',
        breedteType: EAspKolomBreedteType.Flex,
        flex: 1,
        renderer: (item: IOphalenHerincassozakenResultElement) => {
          return item.Afgehandeld ? 'Ja' : 'Nee';
        },
      },
    ],
    [],
  );

  return (
    <>
      <MenuLayout
        menu={
          <div className="d-flex">
            <button
              className="btn btn-sm btn-light d-flex align-items-center"
              disabled={urlState.selectie.length !== 1}
              style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
              onClick={async () => {
                const params = {
                  herincZaakIDs: urlState.selectie,
                };

                const checkData = await api.v2.debiteur.herincassozaak.checkVersturenHerincassozaken(
                  params,
                );
                if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
                  return;
                }

                if (
                  (
                    await checkStore.bevestigen({
                      inhoud: `Wil je de opdracht versturen?`,
                    })
                  ).type === EResultType.Annuleren
                ) {
                  return;
                }

                await api.v2.debiteur.herincassozaak.versturenHerincassozaken(params);

                ophalenHerincassozaken();
              }}
            >
              {/* <IconV style={{ width: 16, height: 16, fill: Kleur.Grijs }} /> */}
              <span className="ml-2">Versturen opdracht</span>
            </button>

            <button
              className="btn btn-sm btn-light d-flex align-items-center ml-3"
              disabled={urlState.selectie.length === 0}
              style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
              onClick={async () => {
                const params = { ids: urlState.selectie };
                const checkData = await api.v2.debiteur.herincassozaak.checkVerwijderenHerincassozaken(
                  params,
                );
                if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
                  return;
                }
                if (
                  (
                    await checkStore.bevestigen({
                      inhoud: (
                        <span>
                          Wil je de geselecteerde herincassozaken verwijderen?
                          <br />
                          Let op: dit kan niet teruggedraaid worden.
                        </span>
                      ),
                    })
                  ).type === EResultType.Annuleren
                ) {
                  return;
                }

                await api.v2.debiteur.herincassozaak.verwijderenHerincassozaken(params);

                ophalenHerincassozaken();
              }}
            >
              {/* <IconV style={{ width: 16, height: 16, fill: Kleur.Grijs }} /> */}
              <span className="ml-2">Verwijderen</span>
            </button>
          </div>
        }
        body={
          <div className="d-flex flex-column flex-fill">
            {herincassozakenRijen === null ? (
              <div className="flex-fill d-flex align-items-center justify-content-center">
                <LoadingSpinner />
              </div>
            ) : (
              <div className="d-flex flex-column flex-fill">
                <ASPTabel
                  rijen={herincassozakenRijen}
                  kolommen={kolommen}
                  keyExtractor={keyExtractor}
                  selectie={urlState.selectie}
                  onSelectieChange={(selectie) => setUrlStateSync('selectie', selectie)}
                  // sortering={urlState.sortering}
                  // onSorteringChange={(sortering) => setUrlStateSync('sortering', sortering)}
                  // onWijzigenRij={async (x) => {}}
                  // onVerwijderenRij={async (x) => {
                  //   await new Promise((resolve) => setTimeout(resolve, 3000));
                  // }}
                  // lokaalSorteren
                  uitgeklapteRijComponent={UitgeklapteRij}
                  uitgeklapt={urlState.uitgeklapt}
                  onUitgeklaptChange={(uitgeklapt) => setUrlStateSync('uitgeklapt', uitgeklapt)}
                  // Hoogte zelf muteren...
                  uitgeklapteRijHoogte={240}
                />
              </div>
            )}
          </div>
        }
      />
    </>
  );
});

export default withRouter(Herincassozaken);
