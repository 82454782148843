import { makeObservable, observable } from 'mobx';
import api from '../api';
import IRemoteData, { createPendingRemoteData, createReadyRemoteData } from '../models/IRemoteData';
import { IZoekResult } from '../../../shared/src/api/v2/zoek';

class ZoekStore {
  public isOpen: boolean = false;
  public isBezig: boolean = false;
  public zoekResult: IRemoteData<IZoekResult> = createPendingRemoteData();
  public isAanHetZoeken: boolean = false;

  constructor() {
    makeObservable(this, {
      isOpen: observable,
      isBezig: observable,
      zoekResult: observable,
      isAanHetZoeken: observable,
    });
  }

  public open = () => {
    this.isOpen = true;
  };

  public sluiten = () => {
    this.isOpen = false;
  };

  public zoek = async (query: string) => {
    this.isAanHetZoeken = true;
    const result = await api.v2.zoek.zoek({
      query,
    });
    this.zoekResult = createReadyRemoteData(result);
    this.isAanHetZoeken = false;
  };

  public setIsBezig = (isBezig: boolean) => {
    this.isBezig = isBezig;
  };
}

export default ZoekStore;
