import api from '../..';
import {
  IVersturenOpdrachtenResult,
  IVersturenOpdrachtenParams,
  ISelecterenOpdrachtenVoorBezoektijdenParams,
  ISelecterenOpdrachtenVoorBezoektijdenResult,
  ICheckSelectieVersturenOpdrachtenParams,
  IMuterenBestandenOpdrachtParams,
  IMuterenBestandenOpdrachtResult,
} from '../../../../../shared/src/api/v2/transport/opdracht';

import {
  IOphalenOpdrachtEventsVoorToevoegenParams,
  IOphalenOpdrachtEventsVoorToevoegenResult,
  IToevoegenOpdrachtEventParams,
  IToevoegenOpdrachtEventResult,
  IAfmeldenOpdrachtregelsParams,
  IAfmeldenOpdrachtregelsResult,
  IHerstellenAfmeldenOpdrachtregelsParams,
  IHerstellenAfmeldenOpdrachtregelsResult,
} from '../../../../../shared/src/api/v2/transport/opdracht/uitstaand';
import {
  IBijwerkenBezoektijdenParams,
  IBijwerkenBezoektijdenResult,
} from '../../../../../shared/src/api/v2/transport/bezoek';

import ICheckData from '../../../../../shared/src/models/ICheckData';
import opdracht from './opdracht';
import voorraad from './voorraad';
import reservering from './reservering';
import bezoek from './bezoek';
import dienst from '../dienst';

const transport = {
  opdracht,
  reservering,
  voorraad,
  bezoek,
  dienst,
  checkBijwerkenBezoektijden: async (params: IBijwerkenBezoektijdenParams): Promise<ICheckData> => {
    return await api.post('v2/transport/bezoek/check-bijwerken-bezoektijden', params);
  },
  bijwerkenBezoektijden: async (
    params: IBijwerkenBezoektijdenParams,
  ): Promise<IBijwerkenBezoektijdenResult> => {
    return await api.post('v2/transport/bezoek/bijwerken-bezoektijden', params);
  },
  versturenOpdrachten: async (
    params: IVersturenOpdrachtenParams,
  ): Promise<IVersturenOpdrachtenResult> => {
    return await api.post('v2/transport/opdracht/versturen-opdrachten', params);
  },
  checkSelectieVersturenOpdrachten: async (
    params: ICheckSelectieVersturenOpdrachtenParams,
  ): Promise<ICheckData> => {
    return await api.post('v2/transport/opdracht/check-selectie-versturen-opdrachten', params);
  },
  checkVersturenOpdrachten: async (params: IVersturenOpdrachtenParams): Promise<ICheckData> => {
    return await api.post('v2/transport/opdracht/check-versturen-opdrachten', params);
  },
  selecterenOpdrachtenVoorBezoektijden: async (
    params: ISelecterenOpdrachtenVoorBezoektijdenParams,
  ): Promise<ISelecterenOpdrachtenVoorBezoektijdenResult> => {
    return await api.post('v2/transport/opdracht/selecteren-opdrachten-voor-bezoektijden', params);
  },
  checkAfmeldenOpdrachtregels: async (
    params: IAfmeldenOpdrachtregelsParams,
  ): Promise<ICheckData> => {
    return await api.post('v2/transport/opdracht/uitstaand/check-afmelden-opdrachtregels', params);
  },
  afmeldenOpdrachtregels: async (
    params: IAfmeldenOpdrachtregelsParams,
  ): Promise<IAfmeldenOpdrachtregelsResult> => {
    return await api.post('v2/transport/opdracht/uitstaand/afmelden-opdrachtregels', params);
  },
  herstellenAfmeldenOpdrachtregels: async (
    params: IHerstellenAfmeldenOpdrachtregelsParams,
  ): Promise<IHerstellenAfmeldenOpdrachtregelsResult> => {
    return await api.post(
      'v2/transport/opdracht/uitstaand/herstellen-afmelden-opdrachtregels',
      params,
    );
  },
  ophalenOpdrachtEventsVoorToevoegen: async (
    params: IOphalenOpdrachtEventsVoorToevoegenParams,
  ): Promise<IOphalenOpdrachtEventsVoorToevoegenResult> => {
    return await api.post(
      'v2/transport/opdracht/uitstaand/ophalen-opdracht-events-voor-toevoegen',
      params,
    );
  },
  toevoegenOpdrachtEvent: async (
    params: IToevoegenOpdrachtEventParams,
  ): Promise<IToevoegenOpdrachtEventResult> => {
    return await api.post('v2/transport/opdracht/uitstaand/toevoegen-opdracht-event', params);
  },

  taakVersturenBezoekHerinneringen: async (): Promise<void> => {
    return await api.post('v2/transport/opdracht/uitstaand/taak-versturen-bezoek-herinneringen');
  },
  muterenBestandenOpdracht: async (
    params: IMuterenBestandenOpdrachtParams,
  ): Promise<IMuterenBestandenOpdrachtResult> => {
    return await api.post('v2/transport/opdracht/muteren-bestanden-opdracht', params);
  },
};

export default transport;
