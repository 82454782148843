import React, { useContext, useMemo } from 'react';
import { IProps } from '@tinymce/tinymce-react/lib/cjs/main/ts/components/Editor';
import { IContext, BatchContext } from '../index';
import VeldWeergave from '../../../formulier/VeldWeergave';
import LoadingSpinner from '../../../Gedeeld/LoadingSpinner';
import { format } from 'date-fns';
import GegevensLayout from '../../../layout/GegevensLayout';
import FormatteerBedrag from '../../../MutatieBedrag';
import { GridStyleWrapper, TypedColumn } from '../../../../helpers/dxTableGrid';
import { Grid, Table, TableHeaderRow, VirtualTable } from '@devexpress/dx-react-grid-bootstrap4';
import { Kleur as EKleur } from '../../../../bedrijfslogica/constanten';
import { IOphalenOpdrachtenResultElement } from '../../../../../../shared/src/api/v2/bank/opdracht/opdracht';
import { DataTypeProvider, IntegratedSorting, SortingState } from '@devexpress/dx-react-grid';
import RelatieVisualisatie from '../../../personalia/RelatieVisualisatie';

const OpdrachtenTab: React.FC<IProps> = (props) => {
  const { batch, opdrachten } = useContext<IContext>(BatchContext);

  const kolommen = useMemo<TypedColumn<IOphalenOpdrachtenResultElement>[]>(
    () => [
      {
        name: 'Bedrag',
        title: 'Bedrag',
      },
      {
        name: '__relatie' as any,
        title: 'Relatie',
        getCellValue: (rij: IOphalenOpdrachtenResultElement) => {
          return rij.relatie !== null ? rij.relatie.weergavenaam : '';
        },
      },
      {
        name: 'Omschrijving',
        title: 'Omschrijving',
      },
      {
        name: '__wijzigingIBAN' as any,
        title: 'Wijz.',
        getCellValue: (rij: IOphalenOpdrachtenResultElement) => {
          return rij.WijzigingIBAN !== null ? 'Ja' : '';
        },
      },
      {
        name: 'IBAN',
        title: 'IBAN',
      },
      {
        name: 'Rekeningnaam',
        title: 'Rekeningnaam',
      },
      {
        name: 'Betalingskenmerk',
        title: 'Betalingskenmerk',
      },
    ],
    [],
  );

  const columnExtensions = useMemo<Table.ColumnExtension[]>(
    () => [
      {
        columnName: 'Bedrag',
        width: 100,
      },
      {
        columnName: 'Omschrijving',
        width: 150,
      },
      {
        columnName: '__relatie' as any,
        width: 250,
      },
      {
        columnName: '__wijzigingIBAN' as any,
        width: 70,
      },
      {
        columnName: 'IBAN',
        width: 200,
      },
      {
        columnName: 'Rekeningnaam',
        width: 200,
      },
      {
        columnName: 'Betalingskenmerk',
        width: 200,
      },
    ],
    [],
  );

  if (opdrachten === null) {
    return <LoadingSpinner />;
  }

  return (
    <div className="d-flex flex-column">
      <div className="mt-2  pt-2 mb-4">
        <div className="mt-2 pl-3 pr-3 pt-2 mb-4">
          <VeldWeergave>
            <div className="p-1 font-weight-bold">Bankopdrachten</div>
            <div className="mt-1" style={{ backgroundColor: EKleur.Wit }}>
              <GridStyleWrapper maxHeight={400} rowAmount={opdrachten.length}>
                <Grid rows={opdrachten} columns={kolommen}>
                  <DataTypeProvider
                    for={['Bedrag']}
                    formatterComponent={(formatterProps) => (
                      <FormatteerBedrag bedrag={formatterProps.value} />
                    )}
                  />

                  <DataTypeProvider
                    for={['__relatie']}
                    formatterComponent={(formatterProps) => {
                      const rij: IOphalenOpdrachtenResultElement = formatterProps.row;
                      return (
                        <RelatieVisualisatie
                          relID={rij.RelID!}
                          options={{ geenLinkToepassen: false }}
                        />
                      );
                    }}
                  />

                  <DataTypeProvider
                    for={['__wijzigingIBAN']}
                    formatterComponent={(formatterProps) => {
                      const rij: IOphalenOpdrachtenResultElement = formatterProps.row;
                      return <span>{rij.WijzigingIBAN !== null ? 'Ja' : ''}</span>;
                    }}
                  />

                  <SortingState defaultSorting={[]} />
                  <IntegratedSorting />
                  <VirtualTable
                    columnExtensions={columnExtensions}
                    messages={{ noData: 'Geen opdrachten' }}
                  />
                  <TableHeaderRow showSortingControls />
                </Grid>
              </GridStyleWrapper>
            </div>
          </VeldWeergave>
        </div>
      </div>
    </div>
  );
};

export default OpdrachtenTab;
