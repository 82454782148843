import * as React from 'react';
import { useCallback, useContext, useMemo } from 'react';
import { RootStoreContext } from '../../../../stores/RootStore';
import api from '../../../../api';
import useUpload from '../../../../core/useUpload';
import BijlagenContainer, {
  BestandType,
  EWeergaveFocus,
} from '../../../../components/BijlagenContainer';
import BijlageKnop from '../../../../components/BijlageKnop';
import { IRow, MeldingenContext } from '../index';
import { DataTypeProvider, EditingState, TableRowDetail } from '@devexpress/dx-react-grid';
import HorizontaleScheidingslijn from '../../../../components/layout/HorizontaleScheidingslijn';
import { IOphalenOpdrachtenResultElement } from '../../../../../../shared/src/api/v2/service/opdracht';
import {
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
  DXTableToggleCellComponent,
  GridStyleWrapper,
  TableRowDetailWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import { formatteerPersoonNaam } from '../../../../helpers';
import { format } from 'date-fns';
import {
  Grid,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import TabelInspringBlok from '../../../../components/layout/TabelInspringBlok';
import { Kleur as EKleur, mutatiebronIconMap } from '../../../../bedrijfslogica/constanten';
import GegevensLayout from '../../../../components/layout/GegevensLayout';
import VerticaleScheidingslijn from '../../../../components/layout/VerticaleScheidingslijn';
import { EMutatiebron } from '../../../../bedrijfslogica/enums';

type Props = TableRowDetail.ContentProps;

interface IReparatieopdracht extends IOphalenOpdrachtenResultElement {}

const RowDetailComp: React.FunctionComponent<Props> = (props) => {
  const rij = props.row as IRow;
  const { checkStore } = useContext(RootStoreContext);

  const Icon = mutatiebronIconMap[rij.Mutatiebron as EMutatiebron];

  const { onVerversenAangevraagd } = useContext(MeldingenContext);
  const handleAlleBestandenGeuploaded = useCallback(
    async (bestandIDs: number[]) => {
      await api.v2.service.muterenAfbeeldingenMelding({
        meldingID: rij.ID,
        bestIDs: bestandIDs,
      });

      onVerversenAangevraagd();
    },
    [rij.ID, onVerversenAangevraagd],
  );

  const {
    upload,
    uploadProgresses,
    bestanden,
    muteerBestanden,
    moetNogUploaden,
    isBezigMetUploaden,
  } = useUpload(
    useMemo(
      () => ({
        automatischUploaden: true,
        initieleBestanden: rij.bijlagen.map((x) => ({
          type: BestandType.ASPDrive,
          bestand: x.bestand,
        })),
        onAlleBestandenGeuploaded: handleAlleBestandenGeuploaded,
      }),
      [rij.bijlagen, handleAlleBestandenGeuploaded],
    ),
  );
  const handleBestandenBijgevoegd = useCallback(
    (bijlagen) => muteerBestanden((bestanden) => [...bestanden, ...bijlagen]),
    [muteerBestanden],
  );
  const handleBestandenChange = useCallback((bijlagen) => muteerBestanden((_) => bijlagen), [
    muteerBestanden,
  ]);

  const keyExtractor = useCallback((row: IReparatieopdracht) => row.ServOpdID, []);

  const kolommen = useMemo<TypedColumn<IReparatieopdracht>[]>(
    () => [
      {
        name: 'DatumVerstuurd' as any,
        title: 'Verstuurd',
        getCellValue: (x) => {
          const datum =
            x.DatumVerstuurd !== null ? x.DatumVerstuurd : new Date('2099-01-01').toISOString();
          return datum;
        },
      },
      {
        name: '__versturenActie',
        title: ' ',
      },
      {
        name: '__status' as any,
        title: 'Status',
      },
      {
        name: 'LeveranciernaamKort',
        title: 'Servicebedrijf',
      },
      {
        name: 'Garantieclaim',
        title: 'Gar.',
      },
      {
        name: 'Spoed',
        title: 'Spoed',
      },
      {
        name: '__persoon' as any,
        title: 'Contactpersoon',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IReparatieopdracht>[]>(
    () => [
      {
        columnName: 'DatumVerstuurd',
        width: 135,
      },
      {
        columnName: '__versturenActie' as any,
        width: 100,
      },
      {
        columnName: '__status',
        width: 100,
      },
      {
        columnName: 'Garantieclaim',
        width: 50,
      },
      {
        columnName: 'Spoed',
        width: 60,
      },
      {
        columnName: 'LeveranciernaamKort',
        width: 125,
      },
      {
        columnName: '__persoon' as any,
        width: 200,
      },
    ],
    [],
  );

  return (
    <TableRowDetailWrapper>
      <>
        <div className="d-flex">
          <TabelInspringBlok />{' '}
          <div
            style={{
              backgroundColor: EKleur.Wit,
            }}
            className="d-flex flex-column"
          >
            <div className="d-flex">
              <div className="p-2 pl-3 mt-2">
                <GegevensLayout
                  gegevens={[
                    {
                      label: 'Voornaam',
                      waarde: rij.Voornaam,
                    },
                    {
                      label: 'Achternaam',
                      waarde: rij.Achternaam,
                    },
                    {
                      label: 'Telefoon',
                      waarde: rij.Telefoon,
                    },
                    {
                      label: 'Email',
                      waarde: rij.Email,
                    },
                  ]}
                />
              </div>
              <div className="p-2 pl-3 mt-2">
                <VerticaleScheidingslijn />
              </div>
              <div className="p-2 pl-3 mt-2">
                <GegevensLayout
                  gegevens={[
                    {
                      label: 'Locatie',
                      waarde:
                        rij.locatie.Straatnaam +
                        ' ' +
                        rij.locatie.Huisnummer +
                        (rij.locatie.Bisnummer !== null ? ' ' + rij.locatie.Bisnummer : '') +
                        ', ' +
                        rij.locatie.Plaatsnaam,
                    },
                    {
                      label: 'Referentiecode',
                      waarde: rij.Referentiecode,
                    },
                    {
                      label: 'Bron',
                      waarde: (
                        <span>
                          <Icon style={{ width: 15, height: 15, fill: EKleur.Grijs }} />
                        </span>
                      ),
                    },
                    {
                      label: 'Ingelogd',
                      waarde:
                        rij.Mutatiebron !== EMutatiebron.Intern
                          ? rij.AccID !== null
                            ? 'Ja'
                            : 'Nee'
                          : '-',
                    },
                  ]}
                />
              </div>
              <div className="p-2 pl-3 mt-2">
                <VerticaleScheidingslijn />
              </div>
              <div className="p-2 pl-3 mt-2">
                <GegevensLayout
                  gegevens={[
                    {
                      label: 'Product',
                      waarde: rij.Productomschrijving,
                    },
                    {
                      label: 'Categorie',
                      waarde: rij.productsoort !== null ? rij.productsoort.Naam : null,
                    },
                  ]}
                />
              </div>
              <div className="p-2 pl-3 mt-2">
                <VerticaleScheidingslijn />
              </div>
              <div className="p-2 pl-3 mt-2" style={{ width: 450 }}>
                {rij.Omschrijving}
              </div>
              <div className="p-2 pl-3 mt-2">
                <VerticaleScheidingslijn />
              </div>
              <div>
                <h5 className="p-2 pl-3 mt-2">Afbeeldingen</h5>
                <div className="pl-3">
                  <BijlageKnop
                    onBijgevoegd={handleBestandenBijgevoegd}
                    toegestaneBestandstypes={[
                      {
                        weergaveNaam: 'Afbeelding',
                        mediaType: 'image/*',
                      },
                    ]}
                    disabled={isBezigMetUploaden}
                  />
                  {bestanden !== null && bestanden.length > 0 && (
                    <div className="mt-2 flex-fill d-flex flex-column mb-3">
                      <BijlagenContainer
                        bestanden={bestanden}
                        uploadProgresses={uploadProgresses}
                        onBestandenChange={handleBestandenChange}
                        bestandenMuterenToegestaan={!isBezigMetUploaden}
                        weergaveFocus={EWeergaveFocus.Visualisatie}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <h5 className="p-2 pl-3 mt-2">Serviceopdrachten</h5>

            <GridStyleWrapper maxHeight={500} rowAmount={rij.opdrachten.length}>
              <Grid getRowId={keyExtractor} rows={rij.opdrachten} columns={kolommen}>
                {/* <DataTypeProvider
            for={['__versturenActie']}
            formatterComponent={(formatterProps) => {
              const row: IReparatieopdracht = formatterProps.row!;

              return (
                <a href="#" onClick={() => handleOpdrachtVersturen(row.ServOpdID)}>
                  Versturen
                </a>

                // <button
                //   className="btn btn-sm btn-light"
                //   style={{ width: 100 }}
                //   // onClick={async (row.ID) => handleOpdrachtVersturen(row.ID)}
                //   onClick={() => handleOpdrachtVersturen(row.ID)}
                // >
                //   Versturen
                // </button>
              );
            }}
          /> */}

                <DataTypeProvider
                  for={['__persoon']}
                  formatterComponent={(formatterProps) => {
                    const row: IReparatieopdracht = formatterProps.row!;

                    const persoon =
                      row.contactpersoon !== null
                        ? formatteerPersoonNaam({
                            voorletters: row.contactpersoon.Voorletters,
                            voorvoegsel: row.contactpersoon.Voorvoegsel,
                            aanhefKort: row.contactpersoon.geslacht.AanhefKort,
                            voornaam: row.contactpersoon.Voornaam,
                            achternaam: row.contactpersoon.Achternaam,
                          })
                        : '?';

                    return <span>{persoon}</span>;
                  }}
                />

                <DataTypeProvider
                  for={['__status']}
                  formatterComponent={(formatterProps) => {
                    const row: IReparatieopdracht = formatterProps.row!;
                    return <span>{row.status.Naam}</span>;
                  }}
                />

                <DataTypeProvider
                  for={['DatumVerstuurd']}
                  formatterComponent={(formatterProps) => {
                    const row: IReparatieopdracht = formatterProps.row!;

                    return (
                      <span>
                        {row.DatumVerstuurd === null ? (
                          <span>Nog versturen</span>
                        ) : (
                          // <a href="#" onClick={() => handleOpdrachtVersturen(row.ID)}>
                          //   Nog versturen
                          // </a>
                          format(new Date(row.DatumVerstuurd), 'dd-MM-yyyy HH:mm')
                        )}
                      </span>
                    );
                  }}
                />

                <DataTypeProvider
                  for={['Garantieclaim']}
                  formatterComponent={(formatterProps) => {
                    const row: IReparatieopdracht = formatterProps.row!;

                    return <span>{row.Garantieclaim ? 'Ja' : 'Nee'}</span>;
                  }}
                />

                <DataTypeProvider
                  for={['Spoed']}
                  formatterComponent={(formatterProps) => {
                    const row: IReparatieopdracht = formatterProps.row!;

                    return <span>{row.Spoed ? 'Ja' : 'Nee'}</span>;
                  }}
                />

                <DataTypeProvider
                  for={['LeveranciernaamKort']}
                  formatterComponent={(formatterProps) => {
                    const row: IReparatieopdracht = formatterProps.row!;

                    return <span>{row.dienst.relatie!.weergavenaam}</span>;
                  }}
                />

                <VirtualTable />
                <TableColumnResizing defaultColumnWidths={kolomBreedtes} />

                <EditingState
                  // onAddedRowsChange={() => {
                  //   return setReparatieopdrachtDialoogState({ servMeldID: props.melding.ID });
                  // }}
                  onEditingRowIdsChange={(x) => {
                    const id = x[x.length - 1] as number;
                    // setWijzigenReparatieopdrachtDialoogState({ servMeldID: id });
                  }}
                  onCommitChanges={(changes) => {
                    if (changes.deleted === undefined) {
                      return;
                    }
                    const deleted = changes.deleted;
                    const id = deleted[deleted.length - 1] as number;
                    // handleVerwijderen(id);
                  }}
                />

                <TableEditColumn
                  width={60}
                  // showAddCommand
                  showDeleteCommand
                  showEditCommand
                  cellComponent={DXTableEditColumnCellComponent}
                  commandComponent={DXTableEditColumnCommandComponent}
                />

                <TableHeaderRow />
              </Grid>
            </GridStyleWrapper>
          </div>
        </div>
      </>
    </TableRowDetailWrapper>
  );

  // return (
  //   <div className="d-flex flex-column p-2 pl-3 pr-3">
  //     {/* <h5>Omschrijving</h5>
  //     <div className="mb-2">{row.Omschrijving}</div>
  //     <HorizontaleScheidingslijn /> */}
  //     <h5 className="mt-2">Afbeeldingen</h5>
  //     <div>
  //       <BijlageKnop
  //         onBijgevoegd={handleBestandenBijgevoegd}
  //         toegestaneBestandstypes={[
  //           {
  //             weergaveNaam: 'Afbeelding',
  //             mediaType: 'image/*',
  //           },
  //         ]}
  //         disabled={isBezigMetUploaden}
  //       />
  //       {bestanden !== null && bestanden.length > 0 && (
  //         <div className="mt-2">
  //           <BijlagenContainer
  //             bestanden={bestanden}
  //             uploadProgresses={uploadProgresses}
  //             onBestandenChange={handleBestandenChange}
  //             bestandenMuterenToegestaan={!isBezigMetUploaden}
  //             weergaveFocus={EWeergaveFocus.Visualisatie}
  //           />
  //         </div>
  //       )}
  //     </div>
  //     <div>

  //     </div>
  //   </div>
  // );
};

export default RowDetailComp;
