import React from 'react';
import { Kleur } from '../../../bedrijfslogica/constanten';

interface IProps {
  style?: React.CSSProperties;
}

const VeldWeergave: React.FC<IProps> = (props) => {
  return (
    <div
      style={{
        backgroundColor: Kleur.HeelLichtGrijs,
        padding: '4px 8px',
        borderRadius: '3px',
        ...props.style,
      }}
    >
      {props.children}
    </div>
  );
};

export default VeldWeergave;
