import React from 'react';

interface IProps {}

const GeenPreviewBeschikbaar = (props: IProps) => {
  return (
    <div className="d-flex flex-column flex-fill">
      <div className="d-flex flex-column flex-fill align-items-center justify-content-center">
        Geen preview beschikbaar
      </div>
    </div>
  );
};

export default GeenPreviewBeschikbaar;
