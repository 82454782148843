import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import VeldWeergave from '../../formulier/VeldWeergave';
import LoadingSpinner from '../../Gedeeld/LoadingSpinner';
import { format } from 'date-fns';
import {
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../helpers/dxTableGrid';
import { Kleur } from '../../../bedrijfslogica/constanten';
import { Grid, TableHeaderRow, VirtualTable } from '@devexpress/dx-react-grid-bootstrap4';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import _ from 'lodash';
import RelatieVisualisatie from '../../personalia/RelatieVisualisatie';
import {
  IOphalenOpdrachtenResultElementV2,
  IOphalenOpdrachtregelsResultElementV2,
} from '../../../../../shared/src/api/v2/transport/opdracht';
import api from '../../../api';
import Skeleton from 'react-loading-skeleton';

export interface IRegel extends IOphalenOpdrachtregelsResultElementV2 {}

interface IProps {
  regels: IRegel[];
}

const TransportopdrachtregelsComp: React.FC<IProps> = (props) => {
  const [opdrachten, setOpdrachten] = useState<IOphalenOpdrachtenResultElementV2[] | null>(null);
  const ophalenOpdrachten = useCallback(async () => {
    const opdrachten = (
      await api.v2.transport.opdracht.ophalenOpdrachtenV2({
        filterSchema: {
          filters: [{ naam: 'IDS', data: _.uniq(props.regels.map((x) => x.TrsOpdID)) }],
        },
      })
    ).opdrachten;

    setOpdrachten(opdrachten);
  }, [props.regels]);

  useEffect(() => {
    ophalenOpdrachten();
  }, [ophalenOpdrachten]);

  const kolommen = useMemo<TypedColumn<IRegel>[]>(
    () => [
      {
        name: '__opdrachtnummer' as any,
        title: 'Opd.nr.',
      },
      {
        name: '__uitvoerdatum' as any,
        title: 'Datum',
      },
      {
        name: '__soort' as any,
        title: 'L/R',
      },
      {
        name: '__status' as any,
        title: 'Status',
      },
      {
        name: '__relatie' as any,
        title: 'Relatie',
      },
      {
        name: '__adres' as any,
        title: 'Plaats',
      },
    ],
    [],
  );

  const kolombreedtes = useMemo<TypedTableColumnWidthInfo<IRegel>[]>(
    () => [
      {
        columnName: '__opdrachtnummer' as any,
        width: 80,
      },
      {
        columnName: '__uitvoerdatum' as any,
        width: 110,
      },
      {
        columnName: '__soort' as any,
        width: 60,
      },
      {
        columnName: '__status' as any,
        width: 100,
      },
      {
        columnName: '__relatie' as any,
        width: 200,
      },
      {
        columnName: '__adres' as any,
        width: 175,
      },
    ],
    [],
  );

  if (opdrachten === null) {
    return <LoadingSpinner />;
  }

  return (
    <div className="d-flex flex-column">
      <div className="mt-2 pl-3 pr-3 pt-2 mb-4">
        <VeldWeergave>
          <div className="p-1 font-weight-bold">Transportopdrachten</div>
          <div className="mt-1" style={{ backgroundColor: Kleur.Wit }}>
            <GridStyleWrapper maxHeight={400} rowAmount={props.regels.length}>
              <Grid rows={props.regels} columns={kolommen}>
                <DataTypeProvider
                  for={['__opdrachtnummer']}
                  formatterComponent={(formatterProps) => {
                    const rij: IRegel = formatterProps.row;
                    const opdracht = opdrachten.find((x) => x.TrsOpdID === rij.TrsOpdID);

                    if (opdracht === undefined) {
                      return <Skeleton width="100%" />;
                    }

                    return (
                      <span>
                        {opdracht.Opdrachtnummer}/{rij.Regelnummer}
                      </span>
                    );
                  }}
                />

                <DataTypeProvider
                  for={['__uitvoerdatum']}
                  formatterComponent={(formatterProps) => {
                    const rij: IRegel = formatterProps.row;
                    if (rij.status.Statusdatum === null) {
                      return <span />;
                    }
                    return <span>{format(new Date(rij.status.Statusdatum), 'dd-MM-yyyy')}</span>;
                  }}
                />

                <DataTypeProvider
                  for={['__soort']}
                  formatterComponent={(formatterProps) => {
                    const rij: IRegel = formatterProps.row;
                    return <span>{rij.regelsoort.NaamKort}</span>;
                  }}
                />

                <DataTypeProvider
                  for={['__status']}
                  formatterComponent={(formatterProps) => {
                    const rij: IRegel = formatterProps.row;
                    return <span>{rij.status.Naam}</span>;
                  }}
                />

                <DataTypeProvider
                  for={['__adres']}
                  formatterComponent={(formatterProps) => {
                    const rij: IRegel = formatterProps.row;
                    const opdracht = opdrachten.find((x) => x.TrsOpdID === rij.TrsOpdID);

                    if (opdracht === undefined) {
                      return <Skeleton width="100%" />;
                    }
                    const locatie = opdracht.locatie;
                    const adres = // locatie.Plaatsnaam +
                      // ', ' +
                      (
                        locatie.Straatnaam +
                        ' ' +
                        locatie.Huisnummer +
                        ' ' +
                        (locatie.Bisnummer !== null ? locatie.Bisnummer : '')
                      ).trim();

                    return <span>{adres}</span>;
                  }}
                />

                <DataTypeProvider
                  for={['__relatie']}
                  formatterComponent={(formatterProps) => {
                    const rij: IRegel = formatterProps.row;
                    if (rij.RelID === null) {
                      return <span></span>;
                    }
                    return (
                      <RelatieVisualisatie
                        relID={rij.RelID}
                        // options={{ geenLinkToepassen: true }}
                      />
                    );
                  }}
                />

                <VirtualTable
                  columnExtensions={kolombreedtes}
                  messages={{ noData: 'Geen bezoektijden bekend' }}
                />
                <TableHeaderRow />
              </Grid>
            </GridStyleWrapper>
          </div>
        </VeldWeergave>
      </div>
    </div>
  );
};

export default TransportopdrachtregelsComp;
