import React, { useMemo } from 'react';
import filesize from 'filesize';
import styled from 'styled-components';
import { Bestand, BestandType } from '../../../../../BijlagenContainer';
import { getIconFile, IconKruis } from '../../../../../Icons';
import Knop from '../../../Knop';
import { Kleur } from '../../../../../../bedrijfslogica/constanten';
import { IWhatsappChatsessie } from '../../../../../../api/services/v1/whatsapp';
import { useWhatsappV2Store } from '../../../store';

const Root = styled.div`
  display: flex;
  flex-direction: column;

  border-top: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
`;

const MainContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  padding: 6px 10px 3px 23px;
`;

const Progressbar = styled.div<{ progress: number }>`
  height: 3px;
  width: ${(props) => props.progress}%;
  background-color: ${Kleur.SelectieBlauw};
  transition: width 0.2s ease-in-out;
`;

interface IBijlageData {
  mediaType: string;
  naam: string;
  grootte: number | null;
  url: string;
}

interface IProps {
  chatsessie: IWhatsappChatsessie;
  bijlage: Bestand;
  onBijlageChange: (bijlage: Bestand | null) => void;
}

const BijlageVak = (props: IProps) => {
  const store = useWhatsappV2Store();
  const isBezig = useMemo<boolean>(() => {
    return Boolean(store.berichtVersturenState[props.chatsessie.id]);
  }, [props.chatsessie.id, store.berichtVersturenState]);
  const progress = useMemo(() => {
    const bvs = store.berichtVersturenState[props.chatsessie.id];
    if (bvs) {
      return bvs.bijlageUploadProgress ?? 0;
    }
    return 0;
  }, [props.chatsessie.id, store.berichtVersturenState]);

  const data = useMemo<IBijlageData>(() => {
    switch (props.bijlage.type) {
      case BestandType.ASPDrive: {
        return {
          naam: props.bijlage.bestand.Naam,
          url: props.bijlage.bestand.url,
          grootte: props.bijlage.bestand.Grootte,
          mediaType: props.bijlage.bestand.MediaType,
        };
      }
      case BestandType.Lokaal: {
        return {
          naam: props.bijlage.naam,
          url: props.bijlage.url,
          grootte: props.bijlage.grootte,
          mediaType: props.bijlage.mediaType,
        };
      }
    }
  }, [props.bijlage]);
  const Icon = getIconFile(data.mediaType);

  return (
    <Root>
      <MainContainer>
        <Icon style={{ width: 32, height: 32 }} />
        <div className="ml-3 flex-fill d-flex flex-column">
          <a href={data.url} target="_blank">
            {data.naam}
          </a>
          <span className="text-muted" style={{ fontSize: 12 }}>
            {data.grootte === null
              ? 'Grootte onbekend'
              : filesize(data.grootte, { base: 2, standard: 'jedec' })}
          </span>
        </div>
        <Knop onClick={() => props.onBijlageChange(null)} disabled={isBezig}>
          <IconKruis style={{ fill: Kleur.Grijs, width: 24, height: 24 }} />
        </Knop>
      </MainContainer>
      <Progressbar progress={progress} />
    </Root>
  );
};

export default BijlageVak;
