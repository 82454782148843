import React, { useCallback, useContext, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import useUrlState from '../../../../../../core/useUrlState';
import { RootStoreContext } from '../../../../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import { IEntiteitProps } from '../../../../../../components/kaart/EntiteitContainer';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../../../models/IRemoteData';
import { IOphalenInformatieVoorOverzichtResult } from '../../../../../../../../shared/src/api/v2/relatie';
import api from '../../../../../../api';
import Header from './Header';
import LoadingSpinner from '../../../../../../components/Gedeeld/LoadingSpinner';
import styled from 'styled-components';
import HorizontaleScheidingslijn from '../../../../../../components/layout/HorizontaleScheidingslijn';
import Servicetegel from './Servicetegel';
import Transporttegel from './Transporttegel';
import Contracttegel from './Contracttegel';
import Factuurtegel from './Factuurtegel';
import Betalingsregelingtegel from './Betalingsregelingtegel';
import Verkoopvoorsteltegel from './Verkoopvoorsteltegel';
import Incassozaaktegel from './Incassozaaktegel';
import Bankopdrachttegel from './Bankopdrachttegel';
import Acceptatieproceduretegel from './Acceptatieproceduretegel';
import Accounttegel from './Accounttegel';
import Contactverzoektegel from './Contactverzoektegel';
import Bestellingtegel from './Bestellingtegel';
import KwkClaimtegel from './KwkClaimtegel';

export const OverzichtTitel = styled.h3``;

interface IUrlState {}

const defaultUrlState: IUrlState = {};

interface IProps extends IEntiteitProps, RouteComponentProps {}

export interface IHeaderdata {
  oudsteContractdatum: Date | string | null | undefined;
  aantalLopendeContracten: number;
  aantalContracten: number;
}

const Overzicht: React.FC<IProps> = observer((props) => {
  const { klantkaartStore } = useContext(RootStoreContext);
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);

  const [informatie, setInformatie] = useState<IRemoteData<IOphalenInformatieVoorOverzichtResult>>(
    createPendingRemoteData(),
  );
  const ophalenInformatie = useCallback(async () => {
    const result = await api.v2.relatie.ophalenInformatieVoorOverzicht({
      relID: props.relID,
    });

    setInformatie(createReadyRemoteData(result));
  }, [props.relID]);
  useEffect(() => {
    ophalenInformatie();
  }, [ophalenInformatie]);

  return (
    <div className="d-flex flex-fill flex-column">
      {informatie.state === ERemoteDataState.Pending ? (
        <div className="d-flex flex-fill justify-content-center align-items-center">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          <Header informatie={informatie.data!} relID={props.relID} />

          <HorizontaleScheidingslijn />
          <div className="p-3">
            <div className="row">
              {/* {informatie.data!.event !== null && ( */}
              {/* <div className="col-6">
                <Eventtegel relID={props.relID} data={informatie.data!.event} />
              </div> */}
              {/* )} */}

              {informatie.data!.acceptatie !== null && (
                <div className="col-6">
                  <Acceptatieproceduretegel
                    data={informatie.data!.acceptatie}
                    relID={props.relID}
                  />
                </div>
              )}

              {informatie.data!.account !== null && (
                <div className="col-6">
                  <Accounttegel relID={props.relID} data={informatie.data!.account} />
                </div>
              )}

              {informatie.data!.contactverzoek !== null && (
                <div className="col-6">
                  <Contactverzoektegel data={informatie.data!.contactverzoek} relID={props.relID} />
                </div>
              )}

              {informatie.data!.bestelling !== null && (
                <div className="col-12">
                  <Bestellingtegel data={informatie.data!.bestelling} relID={props.relID} />
                </div>
              )}

              {informatie.data!.kwkClaim !== null && (
                <div className="col-6">
                  <KwkClaimtegel relID={props.relID} data={informatie.data!.kwkClaim} />
                </div>
              )}

              {/* {informatie.data!.contract !== null && ( */}
              <div className="col-12">
                <Contracttegel relID={props.relID} data={informatie.data!.contract} />
              </div>
              {/* )} */}

              {informatie.data!.transport !== null && (
                <div className="col-12">
                  <Transporttegel relID={props.relID} data={informatie.data!.transport} />
                </div>
              )}

              {informatie.data!.service !== null && (
                <div className="col-12">
                  <Servicetegel relID={props.relID} data={informatie.data!.service} />
                </div>
              )}

              {informatie.data!.incassozaak !== null && (
                <div className="col-6">
                  <Incassozaaktegel relID={props.relID} data={informatie.data!.incassozaak} />
                </div>
              )}

              {informatie.data!.betalingsregeling !== null && (
                <div className="col-6">
                  <Betalingsregelingtegel
                    relID={props.relID}
                    data={informatie.data!.betalingsregeling}
                  />
                </div>
              )}

              {informatie.data!.factuur !== null && (
                <div className="col-12">
                  <Factuurtegel relID={props.relID} data={informatie.data!.factuur} />
                </div>
              )}
              {informatie.data!.bankopdracht !== null && (
                <div className="col-12">
                  <Bankopdrachttegel relID={props.relID} data={informatie.data!.bankopdracht} />
                </div>
              )}
              {informatie.data!.verkoopvoorstel !== null && (
                <div className="col-6">
                  <Verkoopvoorsteltegel
                    relID={props.relID}
                    data={informatie.data!.verkoopvoorstel}
                  />
                </div>
              )}

              {/*<div className="col-6">*/}
              {/*  <Rechtzaaktegel />*/}
              {/*</div>*/}
            </div>
          </div>
        </>
      )}
    </div>
  );

  //
  //     {/*  <div className="col-3">*/}
  //     {/*    <TegelBasis titel="Omzetten">*/}
  //     {/*      {contracten === null ? (*/}
  //     {/*        <LoadingSpinner />*/}
  //     {/*      ) : (*/}
  //     {/*        <div className="pl-2 pr-2">*/}
  //     {/*          <div style={{ justifyContent: 'space-between' }}>*/}
  //     {/*            <div className="flex-fill d-flex">*/}
  //     {/*              {informatie.state === ERemoteDataState.Pending ? (*/}
  //     {/*                <LoadingSpinner />*/}
  //     {/*              ) : informatie.data?.omzetten.length !== 0 ? (*/}
  //     {/*                <table>*/}
  //     {/*                  <thead>*/}
  //     {/*                    <tr>*/}
  //     {/*                      <td style={{ width: 80 }}>Jaar</td>*/}
  //     {/*                      <td style={{ width: 125 }}>Omzet</td>*/}
  //     {/*                    </tr>*/}
  //     {/*                  </thead>*/}
  //
  //     {/*                  <tbody>*/}
  //     {/*                    {informatie.data?.omzetten.map((omzet: any, i) => (*/}
  //     {/*                      <tr>*/}
  //     {/*                        <td>{omzet.Jaar}</td>*/}
  //     {/*                        <td>*/}
  //     {/*                          <MutatieBedrag bedrag={omzet.Bedrag} />*/}
  //     {/*                        </td>*/}
  //     {/*                      </tr>*/}
  //     {/*                    ))}*/}
  //     {/*                  </tbody>*/}
  //     {/*                </table>*/}
  //     {/*              ) : (*/}
  //     {/*                <span>Geen omzet</span>*/}
  //     {/*              )}*/}
  //     {/*            </div>*/}
  //     {/*          </div>*/}
  //     {/*        </div>*/}
  //     {/*      )}*/}
  //     {/*    </TegelBasis>*/}
  //     {/*  </div>*/}
  //
  //     {/*  /!* <div className="col-3">*/}
  //     {/*    <div className="pt-2 pb-2 h-100">*/}
  //     {/*      <Tegel rootProps={{ style: { height: '100%' } }}>*/}
  //     {/*        <EntiteitHeading>Omzetten</EntiteitHeading>*/}
  //     {/*        <div className="flex-fill d-flex justify-content-center  align-items-center">*/}
  //     {/*          {informatie.state === ERemoteDataState.Pending ? (*/}
  //     {/*            <LoadingSpinner />*/}
  //     {/*          ) : informatie.data?.omzetten.length !== 0 ? (*/}
  //     {/*            <table>*/}
  //     {/*              <thead>*/}
  //     {/*                <tr>*/}
  //     {/*                  <td style={{ width: 80 }}>Jaar</td>*/}
  //     {/*                  <td style={{ width: 125 }}>Omzet</td>*/}
  //     {/*                </tr>*/}
  //     {/*              </thead>*/}
  //
  //     {/*              <tbody>*/}
  //     {/*                {informatie.data?.omzetten.map((omzet: any, i) => (*/}
  //     {/*                  <tr>*/}
  //     {/*                    <td>{omzet.Jaar}</td>*/}
  //     {/*                    <td>*/}
  //     {/*                      <MutatieBedrag bedrag={omzet.Bedrag} />*/}
  //     {/*                    </td>*/}
  //     {/*                  </tr>*/}
  //     {/*                ))}*/}
  //     {/*              </tbody>*/}
  //     {/*            </table>*/}
  //     {/*          ) : (*/}
  //     {/*            <span>Geen omzet</span>*/}
  //     {/*          )}*/}
  //     {/*        </div>*/}
  //     {/*      </Tegel>*/}
  //     {/*    </div>*/}
  //     {/*  </div> *!/*/}
  //
  //     {/*  /!* <div className="col-4">*/}
  //     {/*    <div className="pt-2 pb-2 h-100">*/}
  //     {/*      <Tegel rootProps={{ style: { height: '100%' } }}>*/}
  //     {/*        <EntiteitHeading>Financieel</EntiteitHeading>*/}
  //     {/*        <div className="flex-fill d-flex justify-content-center  align-items-center">*/}
  //     {/*          {informatie.state === ERemoteDataState.Pending ? (*/}
  //     {/*            <LoadingSpinner />*/}
  //     {/*          ) : (*/}
  //     {/*            <>*/}
  //     {/*              <div style={{ width: 300 }}>*/}
  //     {/*                <GegevensLayout*/}
  //     {/*                  gegevens={[*/}
  //     {/*                    {*/}
  //     {/*                      label: 'Factuurniveau',*/}
  //     {/*                      waarde: `${informatie.data!.relatie.debiteur?.FactuurNiveau}`,*/}
  //     {/*                    },*/}
  //     {/*                    {*/}
  //     {/*                      label: 'Factuurkenmerk',*/}
  //     {/*                      waarde: `${informatie.data!.relatie.debiteur?.FactuurKenmerk}`,*/}
  //     {/*                    },*/}
  //
  //     {/*                    null,*/}
  //     {/*                  ]}*/}
  //     {/*                />*/}
  //     {/*              </div>*/}
  //     {/*            </>*/}
  //     {/*          )}*/}
  //     {/*        </div>*/}
  //     {/*      </Tegel>*/}
  //     {/*    </div>*/}
  //     {/*  </div> *!/*/}
  //     {/*  /!**/}
  //     {/*  <div className="col-4">*/}
  //     {/*    <div className="pt-2 pb-2 h-100">*/}
  //     {/*      <Tegel rootProps={{ style: { height: '100%' } }}>*/}
  //     {/*        <EntiteitHeading>Omzetten</EntiteitHeading>*/}
  //     {/*        <div className="flex-fill d-flex justify-content-center  align-items-center">*/}
  //     {/*          {informatie.state === ERemoteDataState.Pending ? (*/}
  //     {/*            <LoadingSpinner />*/}
  //     {/*          ) : informatie.data?.omzetten.length !== 0 ? (*/}
  //     {/*            <table>*/}
  //     {/*              <thead>*/}
  //     {/*                <tr>*/}
  //     {/*                  <td style={{ width: 80 }}>Jaar</td>*/}
  //     {/*                  <td style={{ width: 125 }}>Omzet</td>*/}
  //     {/*                </tr>*/}
  //     {/*              </thead>*/}
  //
  //     {/*              <tbody>*/}
  //     {/*                {informatie.data?.omzetten.map((omzet: any, i) => (*/}
  //     {/*                  <tr>*/}
  //     {/*                    <td>{omzet.Jaar}</td>*/}
  //     {/*                    <td>*/}
  //     {/*                      <MutatieBedrag bedrag={omzet.Bedrag} />*/}
  //     {/*                    </td>*/}
  //     {/*                  </tr>*/}
  //     {/*                ))}*/}
  //     {/*              </tbody>*/}
  //     {/*            </table>*/}
  //     {/*          ) : (*/}
  //     {/*            <span>Geen omzet</span>*/}
  //     {/*          )}*/}
  //     {/*        </div>*/}
  //     {/*      </Tegel>*/}
  //     {/*    </div>*/}
  //     {/*  </div> *!/*/}
  //
  //     {/*  /!* <div className="col-4">*/}
  //     {/*    <div className="pt-2 pb-2 h-100">*/}
  //     {/*      <Tegel rootProps={{ style: { height: '100%' } }}>*/}
  //     {/*        <EntiteitHeading>Werkbonkosten</EntiteitHeading>*/}
  //     {/*        <div className="flex-fill d-flex justify-content-center  align-items-center">*/}
  //     {/*          {informatie.state === ERemoteDataState.Pending ? (*/}
  //     {/*            <LoadingSpinner />*/}
  //     {/*          ) : informatie.data?.werkbonkosten.length !== 0 ? (*/}
  //     {/*            <table>*/}
  //     {/*              <thead>*/}
  //     {/*                <tr>*/}
  //     {/*                  <td style={{ width: 80 }}>Jaar</td>*/}
  //     {/*                  <td style={{ width: 125 }}>Kosten</td>*/}
  //     {/*                </tr>*/}
  //     {/*              </thead>*/}
  //
  //     {/*              <tbody>*/}
  //     {/*                {informatie.data?.werkbonkosten.map(*/}
  //     {/*                  (omzet: any, i) =>*/}
  //     {/*                    i < 5 && (*/}
  //     {/*                      <tr>*/}
  //     {/*                        <td>{omzet.Jaar}</td>*/}
  //     {/*                        <td>*/}
  //     {/*                          <MutatieBedrag bedrag={omzet.Bedrag} />*/}
  //     {/*                        </td>*/}
  //     {/*                      </tr>*/}
  //     {/*                    ),*/}
  //     {/*                )}*/}
  //     {/*              </tbody>*/}
  //     {/*            </table>*/}
  //     {/*          ) : (*/}
  //     {/*            <span>Geen kosten</span>*/}
  //     {/*          )}*/}
  //     {/*        </div>*/}
  //     {/*      </Tegel>*/}
  //     {/*    </div>*/}
  //     {/*  </div> *!/*/}
  //
  //     {/*  /!* <div className="col-8">*/}
  //     {/*    <div className="pt-2 pb-2 h-100">*/}
  //     {/*      <Tegel rootProps={{ style: { height: '100%' } }}>*/}
  //     {/*        <EntiteitHeading>TODO</EntiteitHeading>*/}
  //     {/*      </Tegel>*/}
  //     {/*    </div>*/}
  //     {/*  </div> *!/*/}
  //     {/*</div>*/}
  //   </div>
  // );
});

export default withRouter(Overzicht);
