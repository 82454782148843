import * as React from 'react';
import { useCallback, useContext, useMemo, useState } from 'react';
import IDialoogProps from '../../../../../core/IDialoogProps';
import Dialoog from '../../../../../components/dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import { ModalBody } from 'react-bootstrap';
import ModalFooter from 'react-bootstrap/ModalFooter';
import LoadingSpinner from '../../../../../components/Gedeeld/LoadingSpinner';
import Tabblad, { IOptions, ITabblad } from '../../../../../components/layout/Tabblad';
import LocatieTabblad from './LocatieTabblad';
import VoorraadTabblad, { EVoorraadTabblad } from './VoorraadTabblad';
import { ETabblad } from '../KoppelVoorraadProductDialoog';
import api from '../../../../../api';
import { EResultType } from '../../../../../stores/CheckStore';
import { RootStoreContext } from '../../../../../stores/RootStore';

export enum EKoppelenProductResultType {
  Locatie,
  Voorraad,
}

export interface ITabbladProps {
  locID?: number;
  onLocIDChange: (locID?: number) => void;
  relID?: number;
  cntID?: number;
  prodModID?: number;
  locatieProdID: number | null;
  onLocatieProdIDChange: (prodID: number | null) => void;
  prodID: number | null;
  onProdIDChange: (prodID: number | null) => void;
  typeID: number | null;
  onTypeIDChange: (typeID: number | null) => void;
  bulkVerkModID: number | null;
  onBulkVerkModIDChange: (typeID: number | null) => void;
  voorraadTabblad: EVoorraadTabblad;
  onVoorraadTabbladChange: (voorraadTabblad: EVoorraadTabblad) => void;
}

export interface ILocatieResult {
  type: EKoppelenProductResultType;
  prodID: number;
}

export interface IVoorraadResult {
  type: EKoppelenProductResultType;
  typeID?: number;
  prodID?: number;
  tabblad?: EVoorraadTabblad;
}

export interface IDialoogResult {
  result: ILocatieResult | IVoorraadResult;
}

export enum EKoppelenProductDialoogTabblad {
  Locatie,
  Voorraad,
}

export interface IProps extends IDialoogProps<IDialoogResult> {
  locID?: number;
  relID?: number;
  cntID?: number;
  prodModID?: number;
  tabblad?: EKoppelenProductDialoogTabblad;
  voorraadTabblad?: EVoorraadTabblad;
}

const KoppelenProductDialoog = (props: IProps) => {
  const [tabblad, setTabblad] = useState(props.tabblad ?? EKoppelenProductDialoogTabblad.Locatie);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const { checkStore } = useContext(RootStoreContext);

  const [locID, setLocID] = useState<number | undefined>(props.locID);

  const [locatieProdID, setLocatieProdID] = useState<number | null>(null);
  const [voorraadProdID, setVoorraadProdID] = useState<number | null>(null);
  const [voorraadTypeID, setVoorraadTypeID] = useState<number | null>(null);
  const [voorraadBulkVerkModID, setVoorraadBulkVerkModID] = useState<number | null>(null);
  const [voorraadTabblad, setVoorraadTabblad] = useState<EVoorraadTabblad>(
    props.voorraadTabblad ?? EVoorraadTabblad.Uitgepakt,
  );

  const tabbladen = useMemo<ITabblad<EKoppelenProductDialoogTabblad, ITabbladProps>[]>(
    () => [
      {
        id: EKoppelenProductDialoogTabblad.Voorraad,
        label: 'Voorraad',
        content: VoorraadTabblad,
      },
      {
        id: EKoppelenProductDialoogTabblad.Locatie,
        label: 'Locatie',
        content: LocatieTabblad,
      },
    ],
    [],
  );

  const tabbladOptions = useMemo<IOptions<EKoppelenProductDialoogTabblad, ITabbladProps>>(
    () => ({
      tabbladComponentProps: {
        relID: props.relID,
        cntID: props.cntID,
        prodModID: props.prodModID,
        locID,
        onLocIDChange: setLocID,
        locatieProdID,
        onLocatieProdIDChange: setLocatieProdID,
        prodID: voorraadProdID,
        onProdIDChange: setVoorraadProdID,
        typeID: voorraadTypeID,
        onTypeIDChange: setVoorraadTypeID,
        bulkVerkModID: voorraadBulkVerkModID,
        onBulkVerkModIDChange: setVoorraadBulkVerkModID,
        voorraadTabblad,
        onVoorraadTabbladChange: setVoorraadTabblad,
      },
    }),
    [
      props.relID,
      locID,
      setLocID,
      props.cntID,
      props.prodModID,
      setVoorraadProdID,
      setLocatieProdID,
      setVoorraadBulkVerkModID,
      setVoorraadTypeID,
      voorraadProdID,
      locatieProdID,
      voorraadTypeID,
      voorraadBulkVerkModID,
      voorraadTabblad,
      setVoorraadTabblad,
    ],
  );

  const handleOkClick = useCallback(async () => {
    setIsSubmitting(true);

    // if (
    //   (
    //     await checkStore.bevestigen({
    //       titel: `Product koppelen?`,
    //     })
    //   ).type === EResultType.Annuleren
    // ) {
    //   setIsSubmitting(false);
    //   return;
    // }

    switch (tabblad) {
      case EKoppelenProductDialoogTabblad.Locatie: {
        props.onSuccess({
          result: {
            type: EKoppelenProductResultType.Locatie,
            prodID: locatieProdID!,
          },
        });

        setIsSubmitting(false);
        return;
      }
      case EKoppelenProductDialoogTabblad.Voorraad: {
        let typeID = voorraadTypeID ?? undefined;
        if (voorraadTabblad === EVoorraadTabblad.Bulk) {
          const result = await api.v2.aanbod.verkoop.ophalenVerkoopmodellen({
            filterSchema: {
              filters: [
                {
                  naam: 'IDS',
                  data: [voorraadBulkVerkModID!],
                },
              ],
            },
          });
          // Zet VerkModID om in een TypeID
          typeID = result.verkoopmodellen[0].TypeID;
        }

        props.onSuccess({
          result: {
            type: EKoppelenProductResultType.Voorraad,
            tabblad: voorraadTabblad,
            prodID: voorraadProdID ?? undefined,
            typeID,
          },
        });

        setIsSubmitting(false);
        return;
      }
    }
  }, [
    tabblad,
    setIsSubmitting,
    locatieProdID,
    voorraadProdID,
    voorraadTypeID,
    voorraadBulkVerkModID,
    voorraadTabblad,
  ]);

  const isGeldigeSelectie = useMemo(() => {
    switch (tabblad) {
      case EKoppelenProductDialoogTabblad.Locatie: {
        return locatieProdID !== null;
      }
      case EKoppelenProductDialoogTabblad.Voorraad: {
        switch (voorraadTabblad) {
          case EVoorraadTabblad.Ingepakt: {
            return voorraadTypeID !== null;
          }
          case EVoorraadTabblad.Uitgepakt: {
            return voorraadProdID !== null;
          }
          case EVoorraadTabblad.Bulk: {
            return voorraadBulkVerkModID !== null;
          }
        }
      }
    }
  }, [
    tabblad,
    locatieProdID,
    voorraadProdID,
    voorraadTypeID,
    voorraadBulkVerkModID,
    voorraadTabblad,
  ]);

  return (
    <Dialoog index={props.dialoogIndex ?? 0} modalProps={{ size: 'xl' }}>
      <ModalHeader>
        <ModalTitle>Koppelen product</ModalTitle>
      </ModalHeader>
      <ModalBody className="p-0">
        <Tabblad
          geselecteerd={tabblad}
          onSelectieChange={setTabblad}
          tabbladen={tabbladen}
          options={tabbladOptions}
        />
      </ModalBody>
      <ModalFooter className="d-flex flex-row justify-content-start">
        <button
          className="btn btn-primary d-flex align-items-center justify-content-center"
          onClick={handleOkClick}
          style={{ width: 100 }}
          disabled={isSubmitting || !isGeldigeSelectie}
        >
          {isSubmitting && (
            <span className="mr-2">
              <LoadingSpinner soort="light" grootte="15px" dikte="2px" />
            </span>
          )}
          Ok
        </button>
        <button
          className="btn btn-secondary"
          onClick={props.onAnnuleren}
          style={{ width: 100 }}
          disabled={isSubmitting}
        >
          Annuleren
        </button>
      </ModalFooter>
    </Dialoog>
  );
};

export default KoppelenProductDialoog;
