import React, { useMemo } from 'react';
import { Kleur } from '../../../bedrijfslogica/constanten';
import { aantalNaarLeesbareTekens } from '../../../bedrijfslogica/teksten';
import { Root } from './style';

interface IOptions {
  entiteitEnkelvoud?: string;
  entiteitMeervoud?: string;
}

interface IProps {
  aantal: number | null;
  totaalAantal: number | null;
  options?: IOptions;
}

const defaultOptions: IOptions = {
  entiteitEnkelvoud: 'item',
  entiteitMeervoud: 'items',
};

const SelectieLabel: React.FC<IProps> = (props) => {
  const { aantal, totaalAantal } = props;

  const options = useMemo(() => ({ ...defaultOptions, ...props.options }), [props.options]);
  const { entiteitMeervoud, entiteitEnkelvoud } = options;

  // const aantalTekst = useMemo(() => aantalNaarLeesbareTekens(aantal), [aantal]);
  // const aantalTotaalTekst = useMemo(() => aantalNaarLeesbareTekens(totaalAantal), [totaalAantal]);

  return (
    // <Root style={{ color: Kleur.LichtBlauw }}>
    //   {aantal === 0
    //     ? 'Nog geen selectie gemaakt'
    //     : `${aantalTekst} ${aantal === 1 ? entiteitEnkelvoud : entiteitMeervoud} geselecteerd`}
    // </Root>

    <Root style={{ color: Kleur.LichtBlauw }}>
      {aantal === null ? '?' : aantal} / {totaalAantal === null ? '?' : totaalAantal}
    </Root>
  );
};

export default SelectieLabel;
