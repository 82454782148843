import { DataTypeProvider, IntegratedSorting, SortingState } from '@devexpress/dx-react-grid';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import { format } from 'date-fns';
import _ from 'lodash';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { ServiceopdrachtSelectieDialoogContext } from '..';
import { ITekstPrecisieData } from '../../../../../../shared/src/api/sql';
import { IOphalenOpdrachtenResultElement } from '../../../../../../shared/src/api/v2/service/opdracht';
import { IFilterSchemaFilter } from '../../../../../../shared/src/models/filter';
import api from '../../../../api';
import nameOf from '../../../../core/nameOf';
import {
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../models/IRemoteData';
import TekstPrecisieSelectie, { ETekstPrecisie } from '../../../formulier/TekstPrecisieSelectie';
import VinkVeld from '../../../formulier/VinkVeld';
import LoadingSpinner from '../../../Gedeeld/LoadingSpinner';

export interface IFormikValues {
  referentiecode: string;
  referentiecodeTekstprecisie: ETekstPrecisie;
  postcode: string;
  typenaam: string;
  typenaamTekstprecisie: ETekstPrecisie;
  alleenNietAfgehandeld: boolean;
  opdrachtnummer: string;
  opdrachtnummerTekstprecisie: ETekstPrecisie;
}

interface IProps {}

const maxAantalResultaten = 100;

const SelecterenTabblad: React.FC<IProps> = (props) => {
  const { koppelOpties, selecterenValues, setSelecterenValues, onGekozen } = useContext(
    ServiceopdrachtSelectieDialoogContext,
  );

  const referentieCodeInputRef = useRef<HTMLInputElement>(null);
  const opdrachtnummmerInputRef = useRef<HTMLInputElement>(null);

  const v = selecterenValues;
  const setV = setSelecterenValues;

  const [suggesties, setSuggesties] = useState<
    IRemoteData<
      | {
          type: 'RESULTAAT';
          result: IOphalenOpdrachtenResultElement[];
        }
      | {
          type: 'TE_VEEL_RESULTATEN';
          aantalNumber: number;
        }
      | {
          type: 'WACHTEN_OP_ACTIE';
        }
    >
  >(
    createReadyRemoteData({
      type: 'WACHTEN_OP_ACTIE',
    }),
  );

  const ophalenSuggestiesAbortController = useRef<AbortController | null>(null);
  const ophalenSuggesties = useCallback(async () => {
    if (ophalenSuggestiesAbortController.current !== null) {
      ophalenSuggestiesAbortController.current.abort();
      ophalenSuggestiesAbortController.current = null;
    }

    const referentiecode = v.referentiecode.trim();
    const postcode = v.postcode.trim();
    const typenaam = v.typenaam.trim();
    const opdrachtnummer = v.opdrachtnummer.trim();
    const alleenNietAfgehandeld = v.alleenNietAfgehandeld;

    const filters: Array<IFilterSchemaFilter | null> = [
      // referentiecode.length === 0
      //   ? null
      //   : {
      //       naam: 'REFERENTIECODES',
      //       data: {
      //         waarde: referentiecode,
      //         precisie: v.referentiecodeTekstprecisie,
      //       } as ITekstPrecisieData,
      //     },
      referentiecode.length === 0
        ? null
        : {
            naam: 'REFERENTIECODES',
            data: [referentiecode],
          },
      postcode.length === 0
        ? null
        : {
            naam: 'POSTCODES',
            data: [postcode],
          },
      typenaam.length === 0
        ? null
        : {
            naam: 'TYPENAAM_UITGEBREID',
            data: {
              waarde: typenaam,
              precisie: v.typenaamTekstprecisie,
            } as ITekstPrecisieData,
          },
      opdrachtnummer.length === 0
        ? null
        : {
            naam: 'OPDRACHTNUMMER_UITGEBREID',
            data: {
              waarde: opdrachtnummer,
              precisie: v.opdrachtnummerTekstprecisie,
            } as ITekstPrecisieData,
          },
      {
        naam: 'IS_AFGEHANDELD',
        data: !alleenNietAfgehandeld,
      },
    ];

    const f = filters.filter((x) => x !== null) as IFilterSchemaFilter[];
    if (f.length === 1) {
      setSuggesties(
        createReadyRemoteData({
          type: 'WACHTEN_OP_ACTIE',
        }),
      );
      return;
    }
    setSuggesties(createPendingRemoteData());

    ophalenSuggestiesAbortController.current = new AbortController();

    const opdrachtenResult = await api.v2.service.ophalenOpdrachten(
      {
        filterSchema: {
          filters: f,
        },
        orderSchema: { orders: [{ naam: 'DATUM_VERSTUURD', richting: 'DESC' }] },
        paginatie: {
          index: 0,
          aantal: maxAantalResultaten,
        },
      },
      ophalenSuggestiesAbortController.current!.signal,
    );

    if (opdrachtenResult.totaalAantal > maxAantalResultaten) {
      setSuggesties(
        createReadyRemoteData({
          type: 'TE_VEEL_RESULTATEN',
          aantalNumber: opdrachtenResult.totaalAantal,
        }),
      );
      return;
    }

    setSuggesties(
      createReadyRemoteData({
        type: 'RESULTAAT',
        result: opdrachtenResult.opdrachten,
      }),
    );
  }, [v]);

  const debouncedOphalenSuggesties = useRef<any>(null);

  useEffect(() => {
    if (debouncedOphalenSuggesties.current !== null) {
      debouncedOphalenSuggesties.current.cancel();
      debouncedOphalenSuggesties.current = null;
    }
    debouncedOphalenSuggesties.current = _.debounce(ophalenSuggesties, 500);
    debouncedOphalenSuggesties.current();
  }, [v]);

  const sleutelExtractor = useCallback((row: IOphalenOpdrachtenResultElement) => row.ServOpdID, []);

  const kolommen = useMemo<TypedColumn<IOphalenOpdrachtenResultElement>[]>(
    () => [
      {
        name: '__opdrachtnummer' as any,
        title: 'Opd.nr.',
      },
      {
        name: 'DatumVerstuurd',
        title: 'Datum',
      },
      {
        name: '__locatie' as any,
        title: 'Locatie',
      },
      {
        name: '__kies_actie' as any,
        title: ' ',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IOphalenOpdrachtenResultElement>[]>(
    () => [
      { columnName: '__opdrachtnummer' as any, width: 90 },
      { columnName: 'DatumVerstuurd' as any, width: 125 },
      { columnName: '__locatie' as any, width: 200 },
      {
        columnName: '__kies_actie' as any,
        width: 40,
      },
    ],
    [],
  );

  useEffect(() => {
    referentieCodeInputRef.current!.focus();
  }, []);

  return (
    <>
      <div className="flex-fill d-flex flex-column position-relative">
        <div className="p-3">
          <div className="row">
            <div className="col-4">
              <label className="d-flex align-items-center justify-content-between">
                <span>Opdrachtnummer</span>
                <TekstPrecisieSelectie
                  precisie={v.opdrachtnummerTekstprecisie}
                  onPrecisieChange={(precisie) => {
                    setV((x) => ({
                      ...x,
                      opdrachtnummerTekstprecisie: precisie,
                    }));
                  }}
                />
              </label>
              <input
                ref={opdrachtnummmerInputRef}
                className="form-control"
                value={v.opdrachtnummer}
                onChange={(ev) => {
                  const value = ev.target.value;
                  setV((x) => ({
                    ...x,
                    opdrachtnummer: value,
                  }));
                }}
              />
            </div>

            <div className="col-4">
              <label className="d-flex align-items-center justify-content-between">
                <span>Ref.code</span>
                <TekstPrecisieSelectie
                  precisie={v.referentiecodeTekstprecisie}
                  onPrecisieChange={(precisie) => {
                    setV((x) => ({
                      ...x,
                      referentiecodeTekstprecisie: precisie,
                    }));
                  }}
                />
              </label>
              <input
                ref={referentieCodeInputRef}
                className="form-control"
                value={v.referentiecode}
                onChange={(ev) => {
                  const value = ev.target.value;
                  setV((x) => ({
                    ...x,
                    referentiecode: value,
                  }));
                }}
              />
            </div>

            <div className="col-4">
              <label className="d-flex align-items-center justify-content-between">
                <span>Typenaam</span>
                <TekstPrecisieSelectie
                  precisie={v.typenaamTekstprecisie}
                  onPrecisieChange={(precisie) => {
                    setV((x) => ({
                      ...x,
                      typenaamTekstprecisie: precisie,
                    }));
                  }}
                />
              </label>
              <input
                // ref={postcodeRxef}
                className="form-control"
                value={v.typenaam}
                onChange={(ev) => {
                  const value = ev.target.value;
                  setV((x) => ({
                    ...x,
                    typenaam: value,
                  }));
                }}
              />
            </div>

            <div className="col-4 mt-3">
              <label>Postcode</label>
              <input
                // ref={postcodeRef}
                className="form-control"
                value={v.postcode}
                onChange={(ev) => {
                  const value = ev.target.value;
                  setV((x) => ({
                    ...x,
                    postcode: value,
                  }));
                }}
              />
            </div>

            <div className="col-12 mt-3">
              <div className="d-flex align-items-center">
                <VinkVeld
                  aangevinkt={v.alleenNietAfgehandeld}
                  onGewijzigd={(aangevinkt: boolean) =>
                    setV((x) => ({
                      ...x,
                      alleenNietAfgehandeld: aangevinkt,
                    }))
                  }
                />
                <span className="ml-2">Alleen niet afgehandelde opdrachten</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        {suggesties.state === ERemoteDataState.Pending ? (
          <div className="p-2 d-flex align-items-center justify-content-center">
            <LoadingSpinner />
          </div>
        ) : suggesties.data!.type === 'WACHTEN_OP_ACTIE' ? (
          <div className="p-2 d-flex flex-column align-items-center justify-content-center pb-3">
            <div>Voer een of meer selectiecriteria in</div>
          </div>
        ) : suggesties.data!.type === 'TE_VEEL_RESULTATEN' ? (
          <div className="p-2 d-flex flex-column align-items-center justify-content-center">
            <div className="font-weight-bold">
              {(suggesties.data! as any).aantalNumber} resultaten
            </div>
            <div>Vernauw / wijzig de selectiecriteria om resultaten weer te geven</div>
          </div>
        ) : suggesties.data!.type === 'RESULTAAT' ? (
          <div className="d-flex flex-column align-items-center justify-content-center">
            <span style={{ fontSize: 12 }} className="text-muted w-100 p-1 pl-3 pr-3">
              {(suggesties.data! as any).result.length} resultaten
            </span>
            <GridStyleWrapper
              maxHeight={500}
              rowAmount={
                ((suggesties.data! as any).result as IOphalenOpdrachtenResultElement[]).length
              }
            >
              <Grid
                getRowId={sleutelExtractor}
                columns={kolommen}
                rows={(suggesties.data! as any).result as IOphalenOpdrachtenResultElement[]}
              >
                <DataTypeProvider
                  for={['__opdrachtnummer']}
                  formatterComponent={(formatterProps) => {
                    const rij: IOphalenOpdrachtenResultElement = formatterProps.row;
                    return (
                      <span>
                        {rij.melding.Meldnummer} {rij.Volgnummer}
                      </span>
                    );
                  }}
                />

                <DataTypeProvider
                  for={['__locatie']}
                  formatterComponent={(formatterProps) => {
                    const rij: IOphalenOpdrachtenResultElement = formatterProps.row;
                    return (
                      <span>
                        {rij.melding.locatie.Straatnaam} {rij.melding.locatie.Huisnummer}{' '}
                        {rij.melding.locatie.Bisnummer}
                      </span>
                    );
                  }}
                />

                {/* <DataTypeProvider
                  for={['__merknaam']}
                  formatterComponent={(formatterProps) => {
                    const rij: IOphalenProductenResultElementV2 = formatterProps.row;
                    return <span>{rij.producttype.Merknaam}</span>;
                  }}
                />

                <DataTypeProvider
                  for={['__typenaam']}
                  formatterComponent={(formatterProps) => {
                    const rij: IOphalenProductenResultElementV2 = formatterProps.row;
                    return <span>{rij.producttype.Typenaam}</span>;
                  }}
                /> */}

                <DataTypeProvider
                  for={[nameOf<IOphalenOpdrachtenResultElement>('DatumVerstuurd')]}
                  formatterComponent={(formatterProps) => {
                    if (formatterProps.value === null) {
                      return <span></span>;
                    }
                    return <span>{format(new Date(formatterProps.value), 'dd-MM-yyyy')}</span>;
                  }}
                />

                {/* <DataTypeProvider
                  for={['__locatie']}
                  formatterComponent={(formatterProps) => {
                    const rij: IOphalenProductenResultElementV2 = formatterProps.row;
                    if (rij.locatie === null) {
                      return <span></span>;
                    }

                    const locatie =
                      rij.locatie.Straatnaam +
                      ' ' +
                      rij.locatie.Huisnummer +
                      (rij.locatie.Bisnummer !== null ? ' ' + rij.locatie.Bisnummer : '') +
                      ', ' +
                      rij.locatie.Plaatsnaam;
                    return <span>{locatie}</span>;
                  }}
                /> */}

                <DataTypeProvider
                  for={['__kies_actie']}
                  formatterComponent={(formatterProps) => {
                    const rij: IOphalenOpdrachtenResultElement = formatterProps.row;
                    return (
                      <a
                        href="#"
                        onClick={(ev) => {
                          ev.stopPropagation();
                          onGekozen(rij.ServOpdID);
                        }}
                      >
                        Kies
                      </a>
                    );
                  }}
                />

                <SortingState defaultSorting={[]} />
                <IntegratedSorting />

                <VirtualTable
                  rowComponent={(rowProps) => {
                    const rij: IOphalenOpdrachtenResultElement = rowProps.row;
                    return (
                      <tr
                        key={rowProps.tableRow.key}
                        style={{ height: rowProps.tableRow.height, cursor: 'pointer' }}
                        onDoubleClick={() => onGekozen(rij.ServOpdID)}
                      >
                        {rowProps.children}
                      </tr>
                    );
                  }}
                />

                <TableColumnResizing columnWidths={kolomBreedtes} />
                <TableHeaderRow showSortingControls />
              </Grid>
            </GridStyleWrapper>
          </div>
        ) : (
          <div>Niet geimplementeerd</div>
        )}
      </div>
    </>
  );
};

export default SelecterenTabblad;
