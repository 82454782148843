import { makeObservable, observable } from 'mobx';
import IRemoteData, { createPendingRemoteData, createReadyRemoteData } from '../models/IRemoteData';
import { IOphalenMeldingenResultElement } from '../../../shared/src/api/v2/service/melding';
import * as _ from 'lodash';
import api from '../api';

class ServicemeldingStore {
  public servicemeldingen: IRemoteData<
    Record<number, IOphalenMeldingenResultElement>
  > = createPendingRemoteData();
  private ophalenServicemeldingenQueue: number[] = [];
  private ophalenServicemeldingenQueueDebounceHandle: number | null = 0;

  constructor() {
    makeObservable(this, {
      servicemeldingen: observable,
    });
  }

  public enqueueOphalenServicemeldingen = (servMeldIDs: number[]) => {
    if (this.ophalenServicemeldingenQueueDebounceHandle !== null) {
      clearTimeout(this.ophalenServicemeldingenQueueDebounceHandle);
    }
    this.ophalenServicemeldingenQueue = _.uniq([
      ...this.ophalenServicemeldingenQueue,
      ...servMeldIDs,
    ]);
    this.ophalenServicemeldingenQueueDebounceHandle = setTimeout(
      this.ophalenQueuedServicemeldingen,
      100,
    ) as any;
  };
  private ophalenQueuedServicemeldingen = async () => {
    this.ophalenServicemeldingenQueueDebounceHandle = null;
    const servMeldIDs = this.ophalenServicemeldingenQueue;

    if (servMeldIDs.length === 0) {
      return;
    }

    this.ophalenServicemeldingenQueue = [];

    const result = await api.v2.service.ophalenMeldingen({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: servMeldIDs,
          },
        ],
      },
    });
    this.servicemeldingen = createReadyRemoteData(
      result.meldingen.reduce((acc, curr) => {
        return {
          ...acc,
          [curr.ID]: curr,
        };
      }, this.servicemeldingen.data ?? {}),
    );
  };
}

export default ServicemeldingStore;
