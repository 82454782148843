import React from 'react';
import Dialoog from '../../dialogen/Dialoog';
import IDialoogProps from '../../../core/IDialoogProps';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import { IOphalenBestandenResultElement } from '../../../../../shared/src/api/v2/bestand/bestand';
import ModalFooter from 'react-bootstrap/ModalFooter';
import GegevensLayout from '../../layout/GegevensLayout';
import KopieerNaarKlembord from '../../KopieerNaarKlembord';

interface IProps extends IDialoogProps {
  bestand: IOphalenBestandenResultElement;
}

const BestandsinformatieDialoog = (props: IProps) => {
  return (
    <Dialoog index={props.dialoogIndex ?? 0} modalProps={{ size: 'lg' }}>
      <ModalHeader>
        <ModalTitle>Bestandsinformatie</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <GegevensLayout
          gegevens={[
            {
              label: 'Bestandsreferentie',
              waarde: (
                <div className="d-flex align-items-center">
                  <span className="mr-2">{props.bestand.Token}</span>
                  <KopieerNaarKlembord waarde={props.bestand.Token} />
                </div>
              ),
            },
          ]}
        />
      </ModalBody>
      <ModalFooter className="d-flex flex-row justify-content-start">
        <button
          className="btn btn-primary d-flex align-items-center justify-content-center"
          onClick={() => props.onSuccess(null)}
          style={{ width: 100 }}
        >
          Sluiten
        </button>
      </ModalFooter>
    </Dialoog>
  );
};

export default BestandsinformatieDialoog;
