import * as React from 'react';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import IDialoogProps from '../../../../../core/IDialoogProps';
import Modal from 'react-bootstrap/Modal';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import api from '../../../../../api';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalHeader from 'react-bootstrap/ModalHeader';
import LoadingSpinner from '../../../../../components/Gedeeld/LoadingSpinner';
import { RootStoreContext } from '../../../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import { EResultType } from '../../../../../stores/CheckStore';
import { Formik, FormikProps, Field, FieldProps } from 'formik';
import { format, addDays } from 'date-fns';
import nameOf from '../../../../../core/nameOf';
import VinkVeld from '../../../../../components/formulier/VinkVeld';
import Combobox from '../../../../../components/formulier/Combobox';
import teksten from '../../../../../bedrijfslogica/teksten';
import * as Yup from 'yup';
import { IOphalenEventtypenResultElement } from '../../../../../../../shared/src/api/v2/appEvent';

interface IProps extends IDialoogProps {
  id: number;
}

export interface IFormikValues {
  naam: string;
  notificatie: boolean;
}

const veldnamen = {
  naam: 'Naam',
  notificatie: 'Notificatie',
};

const WijzigenDialoog: React.FC<IProps> = observer((props) => {
  const { checkStore } = useContext(RootStoreContext);

  const [eventtype, setEventtype] = useState<IOphalenEventtypenResultElement | null>(null);

  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

  const ophalenEventtype = useCallback(async () => {
    const eventtype = (
      await api.v2.appEvent.ophalenEventtypen({
        filterSchema: { filters: [{ naam: 'IDS', data: [props.id] }] },
      })
    ).eventtypen[0];
    setEventtype(eventtype);
  }, [props.id]);

  useEffect(() => {
    ophalenEventtype();
  }, [ophalenEventtype]);

  const initieleWaarden = useMemo<IFormikValues | null>(() => {
    if (eventtype === null) {
      return null;
    }

    return {
      naam: eventtype.Naam,
      notificatie: eventtype.Notificatie,
    };
  }, [eventtype]);

  const handleWijzigen = useCallback(
    async (values: IFormikValues) => {
      if (eventtype === null) {
        return null;
      }

      setIsSubmitting(true);

      const params = {
        id: props.id,
        naam: values.naam,
        notificatie: values.notificatie,
      };

      const checkData = await api.v2.appEvent.chekcWijzigenEventtype(params);

      if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
        setIsSubmitting(false);
        return;
      }

      await api.v2.appEvent.wijzigenEventtype(params);

      setIsSubmitting(false);

      setEventtype(null);
      props.onSuccess({});
    },
    [setIsSubmitting, props.onSuccess, eventtype],
  );

  const handleAnnuleren = useCallback(() => {
    props.onAnnuleren();
    setEventtype(null);
  }, [props.onAnnuleren, setEventtype]);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        naam: Yup.string().required(
          teksten.formulier.E_VERPLICHT_VELD({
            veldnaam: veldnamen.naam,
          }),
        ),
      }),
    [],
  );

  return (
    <Modal show={props.open}>
      <ModalHeader>
        <ModalTitle>Wijzigen eventtype</ModalTitle>
      </ModalHeader>
      {initieleWaarden === null || eventtype === null ? (
        <LoadingSpinner />
      ) : (
        <Formik<IFormikValues>
          isInitialValid
          onSubmit={(values) => handleWijzigen(values)}
          initialValues={initieleWaarden}
          validationSchema={validationSchema}
          validateOnChange
          validateOnBlur
          render={(formikProps: FormikProps<IFormikValues>) => {
            const { submitForm, isSubmitting, values, isValid } = formikProps;
            return (
              <>
                <ModalBody>
                  <div className="form-group">
                    <div className="row">
                      <div className="row col-12 mt-3">
                        <div className="col-8">
                          <label>{veldnamen.naam}</label>
                          <Field
                            name={nameOf<IFormikValues>('naam')}
                            render={(fieldProps: FieldProps<IFormikValues>) => {
                              const { field, form } = fieldProps;
                              return <input {...field} className="form-control" type="text" />;
                            }}
                          />
                        </div>
                      </div>

                      <div className="col-12 d-flex align-items-center mt-3">
                        <Field
                          name={nameOf<IFormikValues>('notificatie')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <VinkVeld
                                aangevinkt={field.value}
                                onGewijzigd={(x) => {
                                  form.setFieldValue(field.name, x);
                                }}
                              />
                            );
                          }}
                        />
                        <span className="ml-2">{veldnamen.notificatie}</span>
                      </div>
                    </div>
                  </div>
                </ModalBody>
                <ModalFooter className="d-flex justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    disabled={isSubmitting || !isValid}
                    style={{ width: 100 }}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={handleAnnuleren}
                    disabled={isSubmitting || !isValid}
                    style={{ width: 100 }}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Modal>
  );
});

export default WijzigenDialoog;
