import React, { useCallback, useContext, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import useUrlState from '../../../../../../core/useUrlState';
import { RootStoreContext } from '../../../../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import { IEntiteitProps } from '../../../../../../components/kaart/EntiteitContainer';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../../../models/IRemoteData';
import api from '../../../../../../api';
import LoadingSpinner from '../../../../../../components/Gedeeld/LoadingSpinner';
import styled from 'styled-components';
import { IOphalenSaldiGrootboekrekeningenResultElement } from '../../../../../../../../shared/src/api/v2/boekhouding/boeking';
import SaldiTegel from './SaldiTegel';

export const OverzichtTitel = styled.h3``;

interface IUrlState {}

const defaultUrlState: IUrlState = {};

interface IProps extends IEntiteitProps, RouteComponentProps {}

export interface IHeaderdata {
  oudsteContractdatum: Date | string | null | undefined;
  aantalLopendeContracten: number;
  aantalContracten: number;
}

const Overzicht: React.FC<IProps> = observer((props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const [saldi, setSaldi] = useState<IRemoteData<IOphalenSaldiGrootboekrekeningenResultElement[]>>(
    createPendingRemoteData(),
  );

  const ophalenSaldiGrootboekrekeningen = useCallback(async () => {
    const result = await api.v2.boekhouding.boeking.ophalenSaldiGrootboekrekeningen({
      filterSchema: { filters: [{ naam: 'REL_IDS', data: [props.relID] }] },
    });
    setSaldi(createReadyRemoteData(result.saldi));
  }, [props.relID]);

  useEffect(() => {
    ophalenSaldiGrootboekrekeningen();
  }, [ophalenSaldiGrootboekrekeningen]);

  return (
    <div className="d-flex flex-fill flex-column">
      {saldi.state === ERemoteDataState.Pending ? (
        <div className="d-flex flex-fill justify-content-center align-items-center">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          {/* <Header informatie={informatie.data!} relID={props.relID} /> */}

          {/* <HorizontaleScheidingslijn /> */}
          <div className="p-3">
            <div className="row">
              {/* {informatie.data!.event !== null && ( */}
              <div className="col-6">
                <SaldiTegel data={saldi.data!} />
              </div>
              {/* )} */}
            </div>
          </div>
        </>
      )}
    </div>
  );
});

export default withRouter(Overzicht);
