import api from '../../..';
import {
  IOphalenVoorkeurgebiedenParams,
  IOphalenVoorkeurgebiedenResult,
  IToevoegenVoorkeurgebiedParams,
  IToevoegenVoorkeurgebiedResult,
  IVerwijderenVoorkeurgebiedenParams,
  IVerwijderenVoorkeurgebiedenResult,
  IWijzigenVoorkeurgebiedParams,
  IWijzigenVoorkeurgebiedResult,
} from '../../../../../../shared/src/api/v2/service/voorkeurgebied';
import ICheckData from '../../../../../../shared/src/models/ICheckData';

const voorkeurgebied = {
  ophalenVoorkeurgebieden: async (
    params: IOphalenVoorkeurgebiedenParams,
  ): Promise<IOphalenVoorkeurgebiedenResult> => {
    return await api.post('v2/service/voorkeurgebied/ophalen-voorkeurgebieden', params);
  },
  checkToevoegenVoorkeurgebied: async (
    params: IToevoegenVoorkeurgebiedParams,
  ): Promise<ICheckData> => {
    return await api.post('v2/service/voorkeurgebied/check-toevoegen-voorkeurgebied', params);
  },
  toevoegenVoorkeurgebied: async (
    params: IToevoegenVoorkeurgebiedParams,
  ): Promise<IToevoegenVoorkeurgebiedResult> => {
    return await api.post('v2/service/voorkeurgebied/toevoegen-voorkeurgebied', params);
  },
  checkWijzigenVoorkeurgebied: async (
    params: IWijzigenVoorkeurgebiedParams,
  ): Promise<ICheckData> => {
    return await api.post('v2/service/voorkeurgebied/check-wijzigen-voorkeurgebied', params);
  },
  wijzigenVoorkeurgebied: async (
    params: IWijzigenVoorkeurgebiedParams,
  ): Promise<IWijzigenVoorkeurgebiedResult> => {
    return await api.post('v2/service/voorkeurgebied/wijzigen-voorkeurgebied', params);
  },
  verwijderenVoorkeurgebieden: async (
    params: IVerwijderenVoorkeurgebiedenParams,
  ): Promise<IVerwijderenVoorkeurgebiedenResult> => {
    return await api.post('v2/service/voorkeurgebied/verwijderen-voorkeurgebieden', params);
  },
};

export default voorkeurgebied;
