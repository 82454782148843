import api from '../../index';
import aanbod from './Aanbod';
import dienst from './Dienst';
import opdracht from './opdracht';
import parameters from './parameters';
import retour from './retour';

const inkoop = {
  retour,
  parameters,
  aanbod,
  dienst,
  opdracht,
};

export default inkoop;
