import React from 'react';
import { IPotentieelOntvangenTelefoonnotitieItem } from '../../../../../../shared/src/api/v2/externeData';
import { IRegelBase } from '../..';

interface IProps {
  regel: IRegelBase;
  item: IPotentieelOntvangenTelefoonnotitieItem;
}

const TelefoonnnotitieWeergave = (props: IProps) => {
  return (
    <div
      style={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
      }}
      title={props.item.Inhoud ?? undefined}
    >
      {props.item.Inhoud}
    </div>
  );
};

export default TelefoonnnotitieWeergave;
