import * as React from 'react';
import { useMemo } from 'react';
import { Kolom } from '../index';
import { IConcurrentProduct } from '../../../../../../../shared/src/api/v2/concurrent';
import TabelInspringBlok from '../../../../../components/layout/TabelInspringBlok';
import { IUitgeklapteRijProps } from '../../../../../components/tabel/ASPTabel/Body/UitgeklapteRij';
import { TableRowDetailWrapper } from '../../../../../helpers/dxTableGrid';
import GekoppeldeProducten from './GekoppeldeModellen';

const UitgeklapteRij = (props: IUitgeklapteRijProps<Kolom, IConcurrentProduct>) => {
  const row = props.regel;

  const detail = useMemo(() => {
    return (
      <>
        <GekoppeldeProducten koppelBasisID={props.regel.ID!}></GekoppeldeProducten>
      </>
    );
  }, [row]);

  return (
    <TableRowDetailWrapper>
      <div className="d-flex flex-fill">
        <TabelInspringBlok />
        {detail}
      </div>
    </TableRowDetailWrapper>
  );
};

export default UitgeklapteRij;
