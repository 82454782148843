import api from '../../index';
import {
  IInitToevoegenContractParams,
  IInitToevoegenContractResult,
  ICheckVerwijderenContractenParams,
  IVerwijderenContractenParams,
  IVerwijderenContractenResult,
  IFiatterenContractenParams,
  IFiatterenContractenResult,
  ICheckVersturenBevestigingNieuwParams,
  IVersturenBevestigingsverzoekParams,
  IVersturenBevestigingsverzoekResult,
  IBepalenContractenVoorBevestigingParams,
  IBepalenContractenVoorBevestigingResult,
  IVersturenFiatteringsberichtParams,
  IVersturenFiatteringsberichtResult,
} from '../../../../../shared/src/api/v2/contract/nieuw/nieuw';
import ICheckData from '../../../../../shared/src/models/ICheckData';

export const nieuw = {
  initToevoegenContract: async (
    params: IInitToevoegenContractParams,
  ): Promise<IInitToevoegenContractResult> => {
    return await api.post('v2/contract/nieuw/init-toevoegen-contract', params);
  },
  checkVerwijderenContracten: async (
    params: ICheckVerwijderenContractenParams,
  ): Promise<ICheckData> => {
    return await api.post('/v2/contract/nieuw/check-verwijderen-contracten', params);
  },
  verwijderenContracten: async (
    params: IVerwijderenContractenParams,
  ): Promise<IVerwijderenContractenResult> => {
    return await api.post('/v2/contract/nieuw/verwijderen-contracten', params);
  },
  checkFiatterenContracten: async (params: IFiatterenContractenParams): Promise<ICheckData> => {
    return await api.post('/v2/contract/nieuw/check-fiatteren-contracten', params);
  },
  fiatterenContracten: async (
    params: IFiatterenContractenParams,
  ): Promise<IFiatterenContractenResult> => {
    return await api.post('/v2/contract/nieuw/fiatteren-contracten', params);
  },
  checkVersturenBevestigingNieuw: async (
    params: ICheckVersturenBevestigingNieuwParams,
  ): Promise<ICheckData> => {
    return await api.post('/v2/contract/nieuw/check-versturen-bevestiging-nieuw', params);
  },
  checkVersturenBevestigingsverzoek: async (
    params: IVersturenBevestigingsverzoekParams,
  ): Promise<ICheckData> => {
    return await api.post('/v2/contract/nieuw/check-versturen-bevestigingsverzoek', params);
  },
  versturenBevestigingsverzoek: async (
    params: IVersturenBevestigingsverzoekParams,
  ): Promise<IVersturenBevestigingsverzoekResult> => {
    return await api.post('/v2/contract/nieuw/versturen-bevestigingsverzoek', params);
  },
  bepalenContractenVoorBevestiging: async (
    params: IBepalenContractenVoorBevestigingParams,
  ): Promise<IBepalenContractenVoorBevestigingResult> => {
    return await api.post('/v2/contract/nieuw/bepalen-contracten-voor-bevestiging', params);
  },
  checkVersturenFiatteringsbericht: async (
    params: IVersturenFiatteringsberichtParams,
  ): Promise<ICheckData> => {
    return await api.post('/v2/contract/nieuw/check-versturen-fiatteringsbericht', params);
  },
  versturenFiatteringsbericht: async (
    params: IVersturenFiatteringsberichtParams,
  ): Promise<IVersturenFiatteringsberichtResult> => {
    return await api.post('/v2/contract/nieuw/versturen-fiatteringsbericht', params);
  },
  checkHerstellenFiatterenContracten: async (
    params: IFiatterenContractenParams,
  ): Promise<ICheckData> => {
    return await api.post('/v2/contract/nieuw/check-herstellen-fiatteren-contracten', params);
  },
  herstellenFiatterenContracten: async (
    params: IFiatterenContractenParams,
  ): Promise<IFiatterenContractenResult> => {
    return await api.post('/v2/contract/nieuw/herstellen-fiatteren-contracten', params);
  },
};

export default nieuw;
