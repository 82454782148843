import api from '../../../index';
import {
  IOphalenInventarisatiesParams,
  IOphalenInventarisatiesResult,
  INieuweInventarisatieParams,
  INieuweInventarisatieResult,
  IVersturenVoorraadBestandParams,
  IVersturenVoorraadBestandResult,
  IVerwijderenInventarisatiesParams,
  IVerwijderenInventarisatiesResult,
  IWijzigenInventarisatieParams,
  IWijzigenInventarisatieResult,
  IToevoegenInventarisatieParams,
  IToevoegenInventarisatieResult,
  ISamenvoegenInventarisatiesParams,
  ISamenvoegenInventarisatiesResult,
  IToevoegenDocumentParams,
  IToevoegenDocumentResult,
  IOphalenInventarisatieregelsParams,
  IOphalenInventarisatieregelsResult,
  IWijzigenInventarisatieregelParams,
  IWijzigenInventarisatieregelResult,
  IToevoegenGerelateerdeDataParams,
  IToevoegenGerelateerdeDataResult,
  IOphalenTransportregelsParams,
  IOphalenTransportregelsResult,
  IMarkerenDefinitiefParams,
  IMarkerenDefinitiefResult,
  IMarkerenNietDefinitiefParams,
  IMarkerenNietDefinitiefResult,
  IWijzigenAantalAanwezigParams,
  IWijzigenAantalAanwezigResult,
  IDoorvoerenCorrectiesParams,
  IDoorvoerenCorrectiesResult,
  IToevoegenInterneInventarisatieParams,
  IToevoegenInterneInventarisatieResult,
  IToevoegenExterneInventarisatieParams,
  IToevoegenExterneInventarisatieResult,
  IBijwerkenAantallenExternParams,
  IBijwerkenAantallenExternResult,
  IVoorvullenAantalAanwezigParams,
  IVoorvullenAantalAanwezigResult,
  IOphalenInventarisatieInfoParams,
  IOphalenInventarisatieInfoResult,
  IVersturenVerschillijstParams,
  IVersturenVerschillijstResult,
} from '../../../../../../shared/src/api/v2/magazijn/inventarisatie';
import ICheckData from '../../../../../../shared/src/models/ICheckData';
import lijst from './lijst';
import transport from './transport';

const inventarisatie = {
  lijst,
  transport,
  ophalenInventarisaties: async (
    params: IOphalenInventarisatiesParams,
  ): Promise<IOphalenInventarisatiesResult> => {
    return await api.post('/v2/magazijn/inventarisatie/ophalen-inventarisaties', params);
  },
  nieuweInventarisatie: async (
    params: INieuweInventarisatieParams,
  ): Promise<INieuweInventarisatieResult> => {
    return await api.post('/v2/magazijn/inventarisatie/nieuwe-inventarisatie', params);
  },
  versturenVoorraadBestand: async (
    params: IVersturenVoorraadBestandParams,
  ): Promise<IVersturenVoorraadBestandResult> => {
    return await api.post('/v2/magazijn/inventarisatie/versturen-voorraad-bestand', params);
  },
  checkVerwijderenInventarisaties: async (
    params: IVerwijderenInventarisatiesParams,
  ): Promise<ICheckData> => {
    return await api.post('/v2/magazijn/inventarisatie/check-verwijderen-inventarisaties', params);
  },
  verwijderenInventarisaties: async (
    params: IVerwijderenInventarisatiesParams,
  ): Promise<IVerwijderenInventarisatiesResult> => {
    return await api.post('/v2/magazijn/inventarisatie/verwijderen-inventarisaties', params);
  },
  checkWijzigenInventarisatie: async (
    params: IWijzigenInventarisatieParams,
  ): Promise<IWijzigenInventarisatieResult> => {
    return await api.post('/v2/magazijn/inventarisatie/check-wijzigen-inventarisatie', params);
  },
  wijzigenInventarisatie: async (
    params: IWijzigenInventarisatieParams,
  ): Promise<IWijzigenInventarisatieResult> => {
    return await api.post('/v2/magazijn/inventarisatie/wijzigen-inventarisatie', params);
  },
  toevoegenInventarisatie: async (
    params: IToevoegenInventarisatieParams,
  ): Promise<IToevoegenInventarisatieResult> => {
    return await api.post('/v2/magazijn/inventarisatie/toevoegen-inventarisatie', params);
  },
  samenvoegenInventarisaties: async (
    params: ISamenvoegenInventarisatiesParams,
  ): Promise<ISamenvoegenInventarisatiesResult> => {
    return await api.post('/v2/magazijn/inventarisatie/samenvoegen-inventarisaties', params);
  },

  toevoegenInterneInventarisatie: async (
    params: IToevoegenInterneInventarisatieParams,
  ): Promise<IToevoegenInterneInventarisatieResult> => {
    return await api.post('/v2/magazijn/inventarisatie/toevoegen-interne-inventarisatie', params);
  },
  checkToevoegenExterneInventarisatie: async (
    params: IToevoegenExterneInventarisatieParams,
  ): Promise<ICheckData> => {
    return await api.post(
      '/v2/magazijn/inventarisatie/check-toevoegen-externe-inventarisatie',
      params,
    );
  },
  toevoegenExterneInventarisatie: async (
    params: IToevoegenExterneInventarisatieParams,
  ): Promise<IToevoegenExterneInventarisatieResult> => {
    return await api.post('/v2/magazijn/inventarisatie/toevoegen-externe-inventarisatie', params);
  },
  bijwerkenAantallenExtern: async (
    params: IBijwerkenAantallenExternParams,
  ): Promise<IBijwerkenAantallenExternResult> => {
    return await api.post('/v2/magazijn/inventarisatie/bijwerken-aantallen-extern', params);
  },
  voorvullenAantalAanwezig: async (
    params: IVoorvullenAantalAanwezigParams,
  ): Promise<IVoorvullenAantalAanwezigResult> => {
    return await api.post('/v2/magazijn/inventarisatie/voorvullen-aantal-extern', params);
  },
  ophalenInventarisatieInfo: async (
    params: IOphalenInventarisatieInfoParams,
  ): Promise<IOphalenInventarisatieInfoResult> => {
    return await api.post('/v2/magazijn/inventarisatie/ophalen-inventarisatie-info', params);
  },
  toevoegenDocument: async (
    params: IToevoegenDocumentParams,
  ): Promise<IToevoegenDocumentResult> => {
    return await api.post('/v2/magazijn/inventarisatie/toevoegen-document', params);
  },
  ophalenInventarisatieregels: async (
    params: IOphalenInventarisatieregelsParams,
  ): Promise<IOphalenInventarisatieregelsResult> => {
    return await api.post('/v2/magazijn/inventarisatie/ophalen-inventarisatieregels', params);
  },
  wijzigenInventarisatieregel: async (
    params: IWijzigenInventarisatieregelParams,
  ): Promise<IWijzigenInventarisatieregelResult> => {
    return await api.post('/v2/magazijn/inventarisatie/wijzigen-inventarisatieregel', params);
  },
  toevoegenGerelateerdeData: async (
    params: IToevoegenGerelateerdeDataParams,
  ): Promise<IToevoegenGerelateerdeDataResult> => {
    return await api.post('/v2/magazijn/inventarisatie/toevoegen-gerelateerde-data', params);
  },

  checkMarkerenDefinitief: async (params: IMarkerenDefinitiefParams): Promise<ICheckData> => {
    return await api.post('/v2/magazijn/inventarisatie/check-markeren-definitief', params);
  },
  markerenDefinitief: async (
    params: IMarkerenDefinitiefParams,
  ): Promise<IMarkerenDefinitiefResult> => {
    return await api.post('/v2/magazijn/inventarisatie/markeren-definitief', params);
  },
  checkMarkerenNietDefinitief: async (
    params: IMarkerenNietDefinitiefParams,
  ): Promise<ICheckData> => {
    return await api.post('/v2/magazijn/inventarisatie/check-markeren-niet-definitief', params);
  },
  markerenNietDefinitief: async (
    params: IMarkerenNietDefinitiefParams,
  ): Promise<IMarkerenNietDefinitiefResult> => {
    return await api.post('/v2/magazijn/inventarisatie/markeren-niet-definitief', params);
  },
  wijzigenAantalAanwezig: async (
    params: IWijzigenAantalAanwezigParams,
  ): Promise<IWijzigenAantalAanwezigResult> => {
    return await api.post('/v2/magazijn/inventarisatie/wijzigen-aantal-aanwezig', params);
  },
  checkDoorvoerenCorrecties: async (params: IDoorvoerenCorrectiesParams): Promise<ICheckData> => {
    return await api.post('/v2/magazijn/inventarisatie/check-doorvoeren-correcties', params);
  },
  doorvoerenCorrecties: async (
    params: IDoorvoerenCorrectiesParams,
  ): Promise<IDoorvoerenCorrectiesResult> => {
    return await api.post('/v2/magazijn/inventarisatie/doorvoeren-correcties', params);
  },
  versturenVerschillijst: async (
    params: IVersturenVerschillijstParams,
  ): Promise<IVersturenVerschillijstResult> => {
    return await api.post('/v2/magazijn/inventarisatie/versturen-verschillijst', params);
  },
};

export default inventarisatie;
