import React, { useMemo } from 'react';
// import { Root } from './Opdrachten/style';
import { RouteComponentProps, withRouter } from 'react-router';
import Tabblad, { ITabblad } from '../../../components/layout/Tabblad';
import Nieuw from './Nieuw';
import Overname from './Overname';
import Beeindigd from './Beeindigd';
import Geannuleerd from './Geannuleerd';

export enum ETabblad {
  Nieuw = 1,
  Overname = 2,
  Beeindigd = 3,
  Geannuleerd = 4,
}

const pathMap: Record<ETabblad, string> = {
  [ETabblad.Nieuw]: '/nieuw',
  [ETabblad.Overname]: '/overname',
  [ETabblad.Beeindigd]: '/beeindigd',
  [ETabblad.Geannuleerd]: '/geannuleerd',
};

interface IProps extends RouteComponentProps {}

const Opdrachten: React.FC<IProps> = (props) => {
  const tabblad = useMemo<ETabblad | null>(() => {
    const matchKey = Object.keys(pathMap).find((key) => {
      const path = pathMap[Number(key) as ETabblad]!;
      return props.location.pathname.endsWith(path);
    });
    if (matchKey === undefined) {
      props.history.push(`${props.match.path}${pathMap[ETabblad.Nieuw]}`);
      return null;
    }
    return Number(matchKey) as ETabblad;
  }, [props.location.pathname]);

  const tabbladen = useMemo<ITabblad<ETabblad>[]>(
    () => [
      {
        id: ETabblad.Nieuw,
        label: 'Nieuw',
        content: Nieuw,
      },
      {
        id: ETabblad.Overname,
        label: 'Overname',
        content: Overname,
      },
      {
        id: ETabblad.Beeindigd,
        label: 'Beeindigd',
        content: Beeindigd,
      },
      {
        id: ETabblad.Geannuleerd,
        label: 'Geannuleerd',
        content: Geannuleerd,
      },
    ],
    [],
  );

  return (
    <>
      {tabblad !== null && (
        <Tabblad<ETabblad>
          geselecteerd={tabblad}
          onSelectieChange={(tab) => {
            props.history.push(`${props.match.path}${pathMap[tab]}`);
          }}
          tabbladen={tabbladen}
        />
      )}
    </>
  );
};

export default withRouter(Opdrachten);
