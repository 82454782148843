import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Kleur } from '../../bedrijfslogica/constanten';
import { ITabbladProps } from './index';
import LoadingSpinner from '../../components/Gedeeld/LoadingSpinner';
import { IOphalenVoorraadGebruiktResultElement } from '../../../../shared/src/api/v2/inkoop/voorraad';
import api from '../../api';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import MultiCombobox from '../../components/formulier/MultiCombobox';
import { IOphalenMagazijnenResultElement } from '../../../../shared/src/api/v2/magazijn';
import VerticaleScheidingslijn from '../../components/layout/VerticaleScheidingslijn';
import { IOphalenProductsoortenResultElement } from '../../../../shared/src/api/v2/product/soort';
import {
  DXTableCheckboxComponent,
  DXTableToggleCellComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../helpers/dxTableGrid';
import {
  DataTypeProvider,
  IntegratedSorting,
  RowDetailState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import useUrlState from '../../core/useUrlState';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ProductinfoDialoog from '../../components/product/ProductinfoDialoog';
import { IconInformatie, IconWijzigen } from '../../components/Icons';
import RelatieVisualisatie from '../../components/personalia/RelatieVisualisatie';
import { format } from 'date-fns';
import _ from 'lodash';
import { Helmet } from 'react-helmet';
import nameOf from '../../core/nameOf';
import WijzigenProductDialoog from '../Magazijn/Depot/WijzigenProductDialoog';
interface IUrlState {
  selectie: number[];
}

const defaultUrlState: IUrlState = {
  selectie: [],
};

interface IProps extends ITabbladProps, RouteComponentProps {}

const geenData = {
  noData: 'Geen voorraad gevonden voor de selectie',
};

export interface IProductinfoDialoogState {
  id: number;
}

export interface IWijzigenProductDialoogStateState {
  id: number;
}

const Gebruikt: React.FC<IProps> = (props) => {
  const { magazijnen, productsoorten, magID, onMagIDChange, prodSrtID, onProdSrtIDChange } = props;
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);

  const [
    productinfoDialoogState,
    setProductinfoDialoogState,
  ] = useState<IProductinfoDialoogState | null>(null);

  const [
    wijzigenProductDialoogState,
    setWijzigenProductDialoogState,
  ] = useState<IWijzigenProductDialoogStateState | null>(null);

  const [voorraadGebruikt, setVoorraadGebruikt] = useState<
    IOphalenVoorraadGebruiktResultElement[] | null
  >(null);

  const keyExtractor = useCallback((row: IOphalenVoorraadGebruiktResultElement) => row.ProdID, []);

  // useEffect(() => {
  //   (async () => {
  //     const producten = await api.v2.voorraad.ophalenVoorraadGebruikt({
  //       magID,
  //       prodSrtID,
  //       vrijeVoorraad: null,
  //     });

  //     const productenGesorteerd = _.orderBy(producten, ['Merknaam', 'Typenaam'], ['asc', 'asc']);

  //     setVoorraadGebruikt(productenGesorteerd);
  //   })();
  // }, [magID, prodSrtID]);

  const ophalenVoorraadGebruikt = useCallback(async () => {
    const producten = await api.v2.voorraad.ophalenVoorraadGebruikt({
      magID,
      prodSrtID,
      vrijeVoorraad: null,
    });

    const productenGesorteerd = _.orderBy(producten, ['Merknaam', 'Typenaam'], ['asc', 'asc']);

    setVoorraadGebruikt(productenGesorteerd);
  }, [magID, prodSrtID]);

  useEffect(() => {
    ophalenVoorraadGebruikt();
  }, [ophalenVoorraadGebruikt]);

  const kolommen = useMemo<TypedColumn<IOphalenVoorraadGebruiktResultElement>[]>(
    () => [
      {
        name: 'Merknaam',
        title: 'Merk',
      },
      {
        name: 'Typenaam',
        title: 'Type',
      },
      {
        name: 'ProductsoortnaamKort',
        title: 'Soort',
      },
      {
        name: 'Kenmerk',
        title: 'Kenmerk',
      },
      {
        name: 'Referentiecode',
        title: 'Referentie',
      },
      {
        name: 'Nieuw',
        title: 'N/G',
      },
      {
        name: 'leeftijd',
        title: 'Leeftijd',
      },
      {
        name: '__reservering' as any,
        title: 'Reservering',
      },
      {
        name: '__infoProduct' as any,
        title: ' ',
      },
      {
        name: '__wijzigenProduct' as any,
        title: ' ',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IOphalenVoorraadGebruiktResultElement>[]>(
    () => [
      {
        columnName: 'Typenaam',
        width: 200,
      },
      {
        columnName: 'Merknaam',
        width: 125,
      },
      {
        columnName: 'ProductsoortnaamKort',
        width: 85,
      },
      {
        columnName: 'Kenmerk',
        width: 200,
      },
      {
        columnName: 'Referentiecode',
        width: 125,
      },
      {
        columnName: 'Nieuw',
        width: 85,
      },
      {
        columnName: 'leeftijd',
        width: 100,
      },
      {
        columnName: '__reservering' as any,
        width: 300,
      },
      {
        columnName: '__infoProduct' as any,
        width: 65,
      },
      {
        columnName: '__wijzigenProduct' as any,
        width: 65,
      },
    ],
    [],
  );

  return (
    <>
      <Helmet>
        <title>Voorraad Uitgepakt</title>
      </Helmet>
      <div>
        <div
          className="d-flex flex-column p-3"
          style={{
            backgroundColor: Kleur.HeelLichtGrijs,
            borderBottom: `1px solid ${Kleur.LichtGrijs}`,
          }}
        >
          <div className="d-flex">
            <div style={{ width: 125 }}>
              <MultiCombobox<number, IOphalenMagazijnenResultElement>
                sleutelExtractor={(x) => x.MagID}
                representatieFabriek={(x) => x.NaamKort}
                waarde={magID}
                onWaardeChange={(x) => onMagIDChange(x!)}
                opties={magazijnen}
                kolommen={[
                  {
                    key: 'NaamKort',
                    label: 'Naam',
                    breedte: 125,
                  },
                  // {
                  //   key: 'PlaatsNaam',
                  //   label: 'Plaats',
                  //   breedte: 200,
                  // },
                ]}
              />
            </div>

            <div style={{ width: 250 }} className="ml-2">
              <MultiCombobox<number, IOphalenProductsoortenResultElement>
                sleutelExtractor={(x) => x.ProdSrtID}
                representatieFabriek={(x) => x.Naam}
                waarde={prodSrtID}
                onWaardeChange={(x) => onProdSrtIDChange(x!)}
                opties={productsoorten}
                isWisbaar
                kolommen={[
                  {
                    key: 'Naam',
                    label: 'Naam',
                    breedte: 250,
                  },
                ]}
                options={{
                  geenWaardeBericht: 'Alle productsoorten',
                }}
              />
            </div>

            <div className="pl-3 pr-3">
              <VerticaleScheidingslijn />
            </div>

            <div className="mt-1">
              Aantal: {voorraadGebruikt !== null ? voorraadGebruikt.length : ''}
            </div>
          </div>
        </div>

        <div
          style={{
            backgroundColor: Kleur.Wit,
          }}
        >
          {voorraadGebruikt === null ? (
            <div className="d-flex flex-fill align-items-center justify-content-center">
              <LoadingSpinner />
            </div>
          ) : (
            <GridStyleWrapper height="calc(100vh - 150px)">
              <Grid columns={kolommen} getRowId={keyExtractor} rows={voorraadGebruikt!}>
                <DataTypeProvider
                  for={['__infoProduct']}
                  formatterComponent={(props) => {
                    return (
                      <a
                        href="#"
                        // style={{ color: Kleur.DonkerGrijs }}
                        onClick={() => {
                          setProductinfoDialoogState({ id: props.row.ProdID });
                        }}
                      >
                        <IconInformatie style={{ width: 15, height: 15, fill: Kleur.Blauw }} />
                      </a>
                    );
                  }}
                />

                <DataTypeProvider
                  for={['__wijzigenProduct']}
                  formatterComponent={(props) => {
                    return (
                      <a
                        href="#"
                        // style={{ color: Kleur.DonkerGrijs }}
                        onClick={() => {
                          setWijzigenProductDialoogState({ id: props.row.ProdID });
                        }}
                      >
                        <IconWijzigen style={{ width: 15, height: 15, fill: Kleur.Grijs }} />
                      </a>
                    );
                  }}
                />

                <DataTypeProvider
                  for={['__reservering']}
                  formatterComponent={(formatterProps) => {
                    const rij: IOphalenVoorraadGebruiktResultElement = formatterProps.row;
                    if (rij.reservering === null) {
                      return <span />;
                    }

                    return (
                      <div className="d-flex align-items-center">
                        <RelatieVisualisatie relID={rij.reservering.RelID!} />
                        <span className="ml-2" style={{ position: 'relative', top: 1 }}>
                          {rij.reservering.Bezoekdatum === null
                            ? null
                            : `(${format(new Date(rij.reservering.Bezoekdatum), 'dd-MM-yyyy')})`}
                        </span>
                      </div>
                    );
                  }}
                />

                <DataTypeProvider
                  for={[nameOf<IOphalenVoorraadGebruiktResultElement>('Nieuw')]}
                  formatterComponent={(formatterProps) => {
                    return <span>{formatterProps.value ? 'N' : 'G'}</span>;
                  }}
                />

                <SortingState defaultSorting={[]} />
                <IntegratedSorting />

                <VirtualTable messages={geenData} />
                <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
                <RowDetailState defaultExpandedRowIds={[]} />
                <TableHeaderRow showSortingControls />
                {/* <TableRowDetail toggleCellComponent={DXTableToggleCellComponent} /> */}
                <SelectionState
                  selection={urlState.selectie}
                  onSelectionChange={(x) => setUrlStateSync('selectie', x as number[])}
                />

                <TableSelection cellComponent={DXTableCheckboxComponent} />
              </Grid>
            </GridStyleWrapper>
          )}
        </div>
      </div>

      {productinfoDialoogState !== null && (
        <ProductinfoDialoog
          open
          id={productinfoDialoogState.id}
          onSuccess={() => {
            setProductinfoDialoogState(null);
          }}
          onAnnuleren={() => setProductinfoDialoogState(null)}
        />
      )}
      {wijzigenProductDialoogState !== null && (
        <WijzigenProductDialoog
          open
          prodID={wijzigenProductDialoogState.id}
          onSuccess={() => {
            ophalenVoorraadGebruikt();
            setWijzigenProductDialoogState(null);
          }}
          onAnnuleren={() => setWijzigenProductDialoogState(null)}
        />
      )}
    </>
  );
};

export default withRouter(Gebruikt);
