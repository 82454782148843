import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import { ITabbladProps } from '..';
import { IconToevoegen, IconVerwijderen } from '../../../../components/Icons';
import {
  IOphalenAfbeeldingenVanProducttypeResultElement,
  IOphalenProducttypenInkoopResultElement,
} from '../../../../../../shared/src/api/v2/inkoop/aanbod';
import api from '../../../../api';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  VirtualTable,
  TableEditColumn,
} from '@devexpress/dx-react-grid-bootstrap4';
import LeveranciersComp from './DetailComp';
import MultiCombobox, { IKolom } from '../../../../components/formulier/MultiCombobox';
import { IOphalenProductsoortenResultElement } from '../../../../../../shared/src/api/v2/product/soort';
import {
  DXTableCheckboxComponent,
  DXTableToggleCellComponent,
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import {
  RowDetailState,
  SelectionState,
  EditingState,
  DataTypeProvider,
  SortingState,
  IntegratedSorting,
} from '@devexpress/dx-react-grid';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import NieuwProductDialoog from '../NieuwProductDialoog';
import WijzigProductDialoog from '../WijzigProductDialoog';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import useUrlState from '../../../../core/useUrlState';
import FilterBalkV2, {
  IFilter,
  IFilterData,
  maakFilterSchema,
} from '../../../../components/FilterBalkV2';
import { IFilterSchema } from '../../../../../../shared/src/models/filter';
import { format } from 'date-fns';
import { IOphalenDienstenResultElement } from '../../../../../../shared/src/api/v2/dienst/inkoop';
import { IOphalenProductmerkenResultElement } from '../../../../../../shared/src/api/v2/product/merk';
import { RootStoreContext } from '../../../../stores/RootStore';
import { EResultType } from '../../../../stores/CheckStore';
import FormatteerBedrag from '../../../../components/MutatieBedrag';
import RelatieVisualisatie from '../../../../components/personalia/RelatieVisualisatie';
import { Helmet } from 'react-helmet';
import {
  IOphalenDocumentenResult,
  IOphalenDocumentenResultElement,
} from '../../../../../../shared/src/api/v2/product/documentatie';
import { EProductdocumentatiesoort } from '../../../../bedrijfslogica/enums';

interface IProps extends ITabbladProps, RouteComponentProps {}

enum EFilter {
  MetAfbeeldingen = 'MET_AFBEELDINGEN',
  InkoopToegestaan = 'INKOOP_TOEGESTAAN',
  Inkoopdienst = 'INKDIENST_IDS',
  Productsoort = 'PRODSRT_IDS',
  Merken = 'MERK_IDS',
}
export interface IUrlState {
  selectie: number[];
  filterData: IFilterData<EFilter>[];
}

export const defaultUrlState: IUrlState = {
  selectie: [],
  filterData: [
    {
      naam: EFilter.MetAfbeeldingen,
      data: true,
      isActief: false,
    },
    {
      naam: EFilter.InkoopToegestaan,
      data: true,
      isActief: true,
    },
    {
      naam: EFilter.Inkoopdienst,
      data: null,
      isActief: false,
    },
    {
      naam: EFilter.Productsoort,
      data: null,
      isActief: false,
    },
    {
      naam: EFilter.Merken,
      data: null,
      isActief: false,
    },
  ],
};

interface IWijzigProductDialoogState {
  typeID: number;
}

interface IRegel extends IOphalenProducttypenInkoopResultElement {
  bestanden: IOphalenAfbeeldingenVanProducttypeResultElement[];
  documenten: IOphalenDocumentenResultElement[];
}

export interface IProductContext {
  verversenProduct: () => void;
}
export const ProductContext = React.createContext<IProductContext>(null as any);

const Producten: React.FC<IProps> = (props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState<IUrlState>(props, defaultUrlState);
  const { checkStore } = useContext(RootStoreContext);

  const [inkoopdiensten, setInkoopdiensten] = useState<IOphalenDienstenResultElement[] | null>(
    null,
  );

  const [producten, setProducten] = useState<IRegel[] | null>(null);
  const [productsoorten, setProductsoorten] = useState<
    IOphalenProductsoortenResultElement[] | null
  >(null);
  const [productmerken, setProductmerken] = useState<IOphalenProductmerkenResultElement[] | null>(
    null,
  );
  const [prodSrtID, setProdSrtID] = useState<number | null>(null);
  const [nieuwProductDialoogOpen, setNieuwProductDialoogOpen] = useState<boolean>(false);
  const [
    wijzigProductDialoogState,
    setWijzigProductDialoogState,
  ] = useState<IWijzigProductDialoogState | null>(null); //

  const [filterSchema, setFilterSchema] = useState<IFilterSchema | null>(
    maakFilterSchema(urlState.filterData),
  );

  const keyExtractor = useCallback((row: IRegel) => row.TypeID, []);

  const ophalenProducten = useCallback(async () => {
    if (filterSchema === null) {
      setProducten(null);
      return null;
    }
    const producttypenResult = await api.v2.inkoop.aanbod.ophalenProductenInAanbod({
      filterSchema: {
        filters: [...filterSchema.filters!],
      },
    });
    const typeIDs = producttypenResult.map((x) => x.TypeID);

    const afbeeldingenResult = await api.v2.inkoop.aanbod.ophalenAfbeeldingenVanProducttype({
      typeIDs,
    });

    const documentsoorten = await api.v2.product.documentatie.ophalenDocumentsoorten({});
    const documentsoortGebruiksaanwijzing = documentsoorten.soorten.find(
      (x) => x.NaamEnum === EProductdocumentatiesoort.Gebruiksaanwijzing,
    )!;

    // documentatie (Handleidingen)
    const documentenResult = await api.v2.product.documentatie.ophalenDocumenten({
      filterSchema: {
        filters: [
          { naam: 'TYPE_IDS', data: typeIDs },
          { naam: 'PRODDOCSRT_IDS', data: [documentsoortGebruiksaanwijzing.ID] },
        ],
      },
    });

    const result = producttypenResult.map((type) => {
      const bestanden = afbeeldingenResult.bestanden.filter((x) => x.TypeID === type.TypeID);
      const documenten = documentenResult.documenten.filter(
        (x) => x.producttypen.map((x) => x.TypeID).indexOf(type.TypeID) !== -1,
      );
      return { ...type, bestanden, documenten };
    });

    setProducten(result);
  }, [prodSrtID, filterSchema]);

  useEffect(() => {
    ophalenProducten();
  }, [ophalenProducten]);

  const productContext = useMemo<IProductContext>(() => {
    return {
      verversenProduct: () => ophalenProducten(),
    };
  }, [ophalenProducten]);

  useEffect(() => {
    (async () => {
      const [productsoortenResult] = await Promise.all([
        api.v2.product.soort.ophalenProductsoorten({
          filterSchema: { filters: [] },
        }),
      ]);

      setProductsoorten(productsoortenResult);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const productmerkenResult = (
        await api.v2.product.merk.ophalenProductmerken({
          // filterSchema: { filters: [] },
        })
      ).productmerken;

      setProductmerken(productmerkenResult);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const inkoopdienstenResult = await api.v2.dienst.inkoop.ophalenDiensten({
        // filterSchema: { filters: [] },
      });

      setInkoopdiensten(inkoopdienstenResult.diensten);
    })();
  }, []);

  const handleVerwijderen = useCallback(async () => {
    const checkData = await api.v2.inkoop.aanbod.checkVerwijderenInkoopproducten({
      typeIDs: urlState.selectie,
    });
    const checkResult = await checkStore.controleren({
      checkData,
    });
    if (checkResult.type === EResultType.Annuleren) {
      return;
    }

    if (
      (
        await checkStore.bevestigen({
          inhoud: `Wil je de geselecteerde ${urlState.selectie.length} inkoopproducten verwijderen?`,
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    await api.v2.inkoop.aanbod.verwijderenInkoopproducten({
      typeIDs: urlState.selectie,
    });

    setUrlStateSync('selectie', []);
    ophalenProducten();
  }, [urlState.selectie]);

  const kolommen = useMemo<TypedColumn<IRegel>[]>(
    () => [
      {
        name: 'Merknaam',
        title: 'Merk',
      },
      {
        name: 'Typenaam',
        title: 'Type',
      },
      {
        name: 'ProductsoortnaamKort',
        title: 'Soort',
      },
      {
        name: 'Kenmerk',
        title: 'Kenmerk',
      },
      {
        name: '__gebruiksaanwijzing' as any,
        title: 'Doc.',
        getCellValue: (x) => x.documenten.length !== 0,
      },
      {
        name: '__aantalAfbeeldingen' as any,
        title: '# Afb.',
        getCellValue: (x) => x.bestanden.length,
      },
      {
        name: 'InkoopToegestaan',
        title: 'Inkoop',
      },
      {
        name: '__leveranciers' as any,
        title: 'Leverancier',
        getCellValue: (x) => {
          const prioDienst = x.diensten.find((x) => x.isPrio) ?? null;
          return prioDienst !== null ? prioDienst.relatie!.weergavenaam : null;
        },
      },
      {
        name: '__inkoopprijs' as any,
        title: 'Ink.prijs',
        getCellValue: (x) => {
          const prioDienst = x.diensten.find((x) => x.isPrio) ?? null;

          if (prioDienst === null || prioDienst.Inkoopprijs === null) {
            return 0;
          }
          return prioDienst.Inkoopprijs;
        },
      },
      {
        name: '__heeftActie' as any,
        title: 'Actie',
        getCellValue: (x) => {
          return x.diensten.some((x) => x.InkoopprijsActie !== null) ? 1 : 0;
        },
      },
      {
        name: 'Notities',
        title: 'Notities',
      },
      {
        name: 'RecordGewijzigd' as any,
        title: 'Gewijzigd',
        getCellValue: (x) => {
          return x.RecordGewijzigd !== null ? x.RecordGewijzigd : null;
        },
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRegel>[]>(
    () => [
      {
        columnName: 'Typenaam',
        width: 200,
      },
      {
        columnName: 'Merknaam',
        width: 110,
      },
      {
        columnName: 'ProductsoortnaamKort',
        width: 85,
      },
      {
        columnName: 'Kenmerk',
        width: 250,
      },
      {
        columnName: 'InkoopToegestaan',
        width: 110,
      },
      {
        columnName: '__leveranciers' as any,
        width: 175,
      },
      {
        columnName: '__inkoopprijs' as any,
        width: 100,
      },
      {
        columnName: '__heeftActie' as any,
        width: 100,
      },
      {
        columnName: '__gebruiksaanwijzing' as any,
        width: 90,
      },
      {
        columnName: '__aantalAfbeeldingen' as any,
        width: 90,
      },
      {
        columnName: 'Notities',
        width: 125,
      },
      {
        columnName: 'RecordGewijzigd' as any,
        width: 135,
      },
    ],
    [],
  );

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.InkoopToegestaan,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return <span>Alleen inkoopwaardig</span>;
        },
      },
      {
        naam: EFilter.MetAfbeeldingen,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return <span>Alleen met afbeeldingen</span>;
        },
      },
      {
        naam: EFilter.Productsoort,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return (
            <span className="d-flex align-items-center">
              <span>Productsoort</span>
              <span className="ml-2">
                <MultiCombobox<number, IOphalenProductsoortenResultElement>
                  sleutelExtractor={(row) => row.ProdSrtID}
                  waarde={weergaveProps.data === null ? null : weergaveProps.data[0]}
                  onWaardeChange={(value: number | null) => {
                    weergaveProps.onDataChange(value === null ? null : [value]);
                    weergaveProps.setIsActief(true);
                    weergaveProps.toepassen();
                  }}
                  representatieFabriek={(entiteit) => entiteit.Naam}
                  opties={productsoorten}
                  kolommen={productsoortenKolommen}
                />
              </span>
            </span>
          );
        },
      },
      {
        naam: EFilter.Inkoopdienst,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return (
            <span className="d-flex align-items-center">
              <span>Leverancier</span>
              <span className="ml-2">
                <MultiCombobox<number, IOphalenDienstenResultElement>
                  sleutelExtractor={(row) => row.ID}
                  waarde={weergaveProps.data === null ? null : weergaveProps.data[0]}
                  onWaardeChange={(value: number | null) => {
                    weergaveProps.onDataChange(value === null ? null : [value]);
                    weergaveProps.setIsActief(true);
                    weergaveProps.toepassen();
                  }}
                  representatieFabriek={(entiteit) => entiteit.relatie!.organisatie!.Naam}
                  opties={inkoopdiensten}
                  kolommen={inkoopdienstenKolommen}
                />
              </span>
            </span>
          );
        },
      },
      {
        naam: EFilter.Merken,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return (
            <span className="d-flex align-items-center">
              <span>Merken</span>
              <span className="ml-2">
                <MultiCombobox<number, IOphalenProductmerkenResultElement>
                  sleutelExtractor={(row) => row.MerkID}
                  waarde={weergaveProps.data === null ? null : weergaveProps.data[0]}
                  onWaardeChange={(value: number | null) => {
                    weergaveProps.onDataChange(value === null ? null : [value]);
                    weergaveProps.setIsActief(true);
                    weergaveProps.toepassen();
                  }}
                  representatieFabriek={(x) => x.Merknaam}
                  opties={productmerken}
                  kolommen={productmerkenKolommen}
                />
              </span>
            </span>
          );
        },
      },
    ],
    [inkoopdiensten, productmerken, productsoorten],
  );

  const inkoopdienstenKolommen = useMemo<IKolom<IOphalenDienstenResultElement>[]>(
    () => [
      {
        key: '__naam' as any,
        label: 'Naam',
        breedte: 125,
        formatFabriek: (rij) => rij.relatie!.organisatie!.Naam,
      },
    ],
    [],
  );

  const productsoortenKolommen = useMemo<IKolom<IOphalenProductsoortenResultElement>[]>(
    () => [
      {
        key: 'Naam',
        label: 'Naam',
        breedte: 125,
      },
    ],
    [],
  );

  const productmerkenKolommen = useMemo<IKolom<IOphalenProductmerkenResultElement>[]>(
    () => [
      {
        key: 'Merknaam',
        label: 'Naam',
        breedte: 125,
      },
    ],
    [],
  );

  return (
    <ProductContext.Provider value={productContext}>
      <>
        <Helmet>
          <title>Aanbod leverancier</title>
        </Helmet>
        <div>
          <div
            className="d-flex flex-column p-3"
            style={{
              backgroundColor: Kleur.HeelLichtGrijs,
              borderBottom: `1px solid ${Kleur.LichtGrijs}`,
            }}
          >
            <div className="d-flex align-items-center">
              <button
                className="btn btn-sm btn-light d-flex align-items-center"
                style={{
                  border: `1px solid ${Kleur.LichtGrijs}`,
                }}
                onClick={() => setNieuwProductDialoogOpen(true)}
              >
                <IconToevoegen
                  style={{
                    width: 16,
                    height: 16,
                    fill: Kleur.Grijs,
                  }}
                />
                <span className="ml-2">Nieuw product</span>
              </button>

              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-3"
                style={{
                  border: `1px solid ${Kleur.LichtGrijs}`,
                }}
                disabled={urlState.selectie.length === 0}
                onClick={async () => {
                  await handleVerwijderen();
                }}
              >
                <IconVerwijderen
                  style={{
                    width: 16,
                    height: 16,
                    fill: Kleur.Grijs,
                  }}
                />
                <span className="ml-2">Verwijderen</span>
              </button>

              <div className="ml-2 flex-fill">
                <FilterBalkV2
                  filters={filters}
                  filterData={urlState.filterData}
                  onFilterDataChange={(value) => setUrlStateSync('filterData', value)}
                  onFilterSchemaChange={(schema) => setFilterSchema(schema)}
                />
              </div>
            </div>
          </div>

          <div
            style={{
              backgroundColor: Kleur.Wit,
            }}
          >
            {producten === null || inkoopdiensten === null ? (
              <div className="d-flex flex-column flex-fill align-items-center justify-content-center mt-10">
                <LoadingSpinner />
              </div>
            ) : (
              <GridStyleWrapper height="calc(100vh - 150px)">
                <Grid columns={kolommen} getRowId={keyExtractor} rows={producten}>
                  <DataTypeProvider
                    for={['__leveranciers']}
                    formatterComponent={(fmtProps) => {
                      const row: IRegel = fmtProps.row;

                      const prioDienst = row.diensten.find((x) => x.isPrio) ?? null;

                      if (prioDienst === null) {
                        return <span />;
                      }
                      return (
                        <span className="d-flex">
                          <RelatieVisualisatie
                            relID={prioDienst!.RelID}
                            options={{ geenLinkToepassen: false }}
                          />
                          {/* {prioDienst.relatie!.weergavenaam}{' '} */}
                          {row.diensten.length > 1 ? (
                            <span style={{ color: Kleur.Blauw }} className="ml-2">
                              (primair)
                            </span>
                          ) : (
                            ''
                          )}
                        </span>
                      );
                    }}
                  />

                  <DataTypeProvider
                    for={['__inkoopprijs']}
                    formatterComponent={(fmtProps) => {
                      const rij: IRegel = fmtProps.row;

                      const prioDienst = rij.diensten.find((x) => x.isPrio) ?? null;

                      if (prioDienst === null) {
                        return <span />;
                      }
                      if (prioDienst.Inkoopprijs === null) {
                        return <span>?</span>;
                      }
                      return <FormatteerBedrag bedrag={prioDienst.Inkoopprijs} />;
                    }}
                  />

                  <DataTypeProvider
                    for={['__gebruiksaanwijzing']}
                    formatterComponent={(fmtProps) => {
                      const row: IRegel = fmtProps.row;
                      return <span>{row.documenten.length !== 0 ? 'Ja' : 'Nee'}</span>;
                    }}
                  />

                  <DataTypeProvider
                    for={['__aantalAfbeeldingen']}
                    formatterComponent={(fmtProps) => {
                      const row: IRegel = fmtProps.row;
                      return <span>{row.bestanden.length !== 0 ? row.bestanden.length : ''}</span>;
                    }}
                  />

                  <DataTypeProvider
                    for={['__heeftActie']}
                    formatterComponent={(fmtProps) => {
                      const rij: IRegel = fmtProps.row;
                      return (
                        <span>
                          {rij.diensten.some((x) => x.InkoopprijsActie !== null) ? 'Ja' : ''}
                        </span>
                      );
                    }}
                  />

                  <DataTypeProvider
                    for={['InkoopToegestaan']}
                    formatterComponent={(fmtProps) => {
                      return <span>{fmtProps.value ? 'Ja' : 'Nee'}</span>;
                    }}
                  />

                  <DataTypeProvider
                    for={['ProductsoortnaamKort']}
                    formatterComponent={(fmtProps) => {
                      const row: IRegel = fmtProps.row;

                      const naam = row.ProdSrtID !== 1 ? row.ProductsoortnaamKort : '';

                      return <span>{fmtProps.value}</span>;
                    }}
                  />

                  <DataTypeProvider
                    for={['RecordGewijzigd']}
                    formatterComponent={(fmtProps) => {
                      const row: IRegel = fmtProps.row;
                      return (
                        <span>
                          {row.RecordGewijzigd !== null
                            ? format(new Date(row.RecordGewijzigd), 'dd-MM-yyyy HH:mm')
                            : null}
                        </span>
                      );
                    }}
                  />

                  <SelectionState
                    selection={urlState.selectie}
                    onSelectionChange={(x) => setUrlStateSync('selectie', x as number[])}
                  />

                  <SortingState defaultSorting={[]} />
                  <IntegratedSorting />

                  <VirtualTable />
                  <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
                  <RowDetailState defaultExpandedRowIds={[]} />

                  <TableHeaderRow showSortingControls />
                  <EditingState
                    onCommitChanges={() => null}
                    onEditingRowIdsChange={(rowIds) => {
                      const id = rowIds[rowIds.length - 1] as number;
                      setWijzigProductDialoogState({
                        typeID: id,
                      });
                    }}
                  />

                  <TableEditColumn
                    width={35}
                    showEditCommand
                    cellComponent={DXTableEditColumnCellComponent}
                    commandComponent={DXTableEditColumnCommandComponent}
                  />
                  <TableRowDetail
                    contentComponent={LeveranciersComp}
                    toggleCellComponent={DXTableToggleCellComponent}
                  />

                  <TableSelection cellComponent={DXTableCheckboxComponent} />

                  {/* <TableRowDetail toggleCellComponent={DXTableToggleCellComponent} /> */}
                </Grid>
              </GridStyleWrapper>
            )}
          </div>
        </div>
        {nieuwProductDialoogOpen && (
          <NieuwProductDialoog
            prodSrtID={prodSrtID}
            onSuccess={async () => {
              setNieuwProductDialoogOpen(false);
              await ophalenProducten();
            }}
            onAnnuleren={() => {
              setNieuwProductDialoogOpen(false);
            }}
            open
          />
        )}
        {wijzigProductDialoogState && (
          <WijzigProductDialoog
            typeID={wijzigProductDialoogState.typeID}
            onSuccess={async () => {
              setWijzigProductDialoogState(null);
              await ophalenProducten();
            }}
            onAnnuleren={() => setWijzigProductDialoogState(null)}
            open
          />
        )}
      </>
    </ProductContext.Provider>
  );
};

export default withRouter(Producten);
