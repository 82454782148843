import { EOpgegevenContextSelectieType, EVerstuurModusKanaal, IOpgegevenContext } from './types';

export const opgegevenContextKeyExtractor = (
  opgegevenContext: IOpgegevenContext,
): number | string => {
  let start: number | string;

  switch (opgegevenContext.selectie.type) {
    case EOpgegevenContextSelectieType.NaamEnum: {
      start = opgegevenContext.selectie.naamEnum;
      break;
    }
    case EOpgegevenContextSelectieType.SjabCtxID: {
      start = opgegevenContext.selectie.sjabCtxID;
      break;
    }
  }

  return `${start}-${opgegevenContext.alias}`;
};

export const communicatiekanaalNaamEnumNaarVerstuurModusKanaal = (
  communicatiekanaalNaamEnum: string,
): EVerstuurModusKanaal | null => {
  switch (communicatiekanaalNaamEnum) {
    case 'EMAIL':
      return EVerstuurModusKanaal.Email;
    case 'SMS':
      return EVerstuurModusKanaal.SMS;
    case 'WHATSAPP':
      return EVerstuurModusKanaal.WhatsApp;
  }
  return null;
};
