import React from 'react';
import { IFormikValues, veldnamen } from '..';
import { Field, FieldProps } from 'formik';
import nameOf from '../../../../../../../../core/nameOf';
import BijlageKnop, { ESelectieModus } from '../../../../../../../../components/BijlageKnop';
import { Bestand, BestandType } from '../../../../../../../../components/BijlagenContainer';
import { Kleur } from '../../../../../../../../bedrijfslogica/constanten';

const MediaTabblad = () => {
  return (
    <div className="row p-3">
      <div className="col-12">
        <Field
          name={nameOf<IFormikValues>('bannerLinks')}
          render={(fieldProps: FieldProps<IFormikValues>) => {
            const { field, form } = fieldProps;

            const bestand = field.value as Bestand | null;
            const url =
              bestand !== null
                ? bestand.type === BestandType.Lokaal
                  ? bestand.url
                  : bestand.bestand.url
                : null;

            return (
              <div className="d-flex flex-column">
                <div className="d-flex align-items-center">
                  <h5 className="flex-fill mb-0">{veldnamen.bannerLinks}</h5>
                  <BijlageKnop
                    selectieModus={ESelectieModus.Enkel}
                    onBijgevoegd={(bestanden) => {
                      const [bestand] = bestanden;
                      form.setFieldValue(field.name, bestand);
                    }}
                    toegestaneBestandstypes={[
                      {
                        weergaveNaam: 'JPG',
                        mediaType: 'image/jpeg',
                      },
                      {
                        weergaveNaam: 'PNG',
                        mediaType: 'image/png',
                      },
                    ]}
                  />
                </div>

                {url !== null ? (
                  <img
                    src={url}
                    style={{ width: '100%', height: 300, objectFit: 'cover', marginTop: 10 }}
                    alt="Afbeelding kan niet worden geladen"
                  />
                ) : (
                  <div
                    style={{
                      width: '100%',
                      height: 300,
                      marginTop: 10,
                      backgroundColor: Kleur.LichtGrijs,
                    }}
                  />
                )}
              </div>
            );
          }}
        />
      </div>

      <div className="col-12 mt-3">
        <Field
          name={nameOf<IFormikValues>('bannerRechts')}
          render={(fieldProps: FieldProps<IFormikValues>) => {
            const { field, form } = fieldProps;

            const bestand = field.value as Bestand | null;
            const url =
              bestand !== null
                ? bestand.type === BestandType.Lokaal
                  ? bestand.url
                  : bestand.bestand.url
                : null;

            return (
              <div className="d-flex flex-column">
                <div className="d-flex align-items-center">
                  <h5 className="flex-fill mb-0">{veldnamen.bannerRechts}</h5>
                  <BijlageKnop
                    selectieModus={ESelectieModus.Enkel}
                    onBijgevoegd={(bestanden) => {
                      const [bestand] = bestanden;
                      form.setFieldValue(field.name, bestand);
                    }}
                    toegestaneBestandstypes={[
                      {
                        weergaveNaam: 'JPG',
                        mediaType: 'image/jpeg',
                      },
                      {
                        weergaveNaam: 'PNG',
                        mediaType: 'image/png',
                      },
                    ]}
                  />
                </div>

                {url !== null ? (
                  <img
                    src={url}
                    style={{ width: '100%', height: 300, objectFit: 'cover', marginTop: 10 }}
                    alt="Afbeelding kan niet worden geladen"
                  />
                ) : (
                  <div
                    style={{
                      width: '100%',
                      height: 300,
                      marginTop: 10,
                      backgroundColor: Kleur.LichtGrijs,
                    }}
                  />
                )}
              </div>
            );
          }}
        />
      </div>
    </div>
  );
};

export default MediaTabblad;
