import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { withRouter } from 'react-router';
import api from '../../../../api';
import useUrlState from '../../../../core/useUrlState';
import { IOphalenFacturenResultElement } from '../../../../../../shared/src/api/v2/inkoopfactuur';
import _ from 'lodash';
import {
  DXCommandComponent,
  DXTableCheckboxComponent,
  DXTableToggleCellComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import {
  DataTypeProvider,
  EditingState,
  IntegratedSorting,
  RowDetailState,
  SelectionState,
  SortingState,
  VirtualTable as VirtualTableBase,
} from '@devexpress/dx-react-grid';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import {
  Grid,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  IconAttachment,
  IconInformatie,
  IconKruis,
  IconToevoegen,
  IconVerwijderen,
  IconVink,
} from '../../../../components/Icons';
import { format } from 'date-fns';
import FormatteerBedrag from '../../../../components/MutatieBedrag';
import RowDetailComp from './RowDetailComp';
import { Kleur as EKleur } from '../../../../bedrijfslogica/constanten';
import MenuLayout from '../../../../components/MenuLayout';
import { RootStoreContext } from '../../../../stores/RootStore';
import { EResultType } from '../../../../stores/CheckStore';
import RelatieVisualisatie from '../../../../components/personalia/RelatieVisualisatie';
import NieuweFactuurDialoog from './NieuweFactuurDialoogV2';
import WijzigenFactuurDialoog from '../../../../components/inkoopfactuur/WijzigenFactuurDialoog';
import { EDagboeksoort } from '../../../../bedrijfslogica/enums';
import {
  IOphalenBoekingenResultElement,
  IOphalenBoekingRegelsResultElement,
} from '../../../../../../shared/src/api/v2/boekhouding/boeking';
import { IOphalenRelatiesResultElementV2 } from '../../../../../../shared/src/api/v2/relatie';
import nameOf from '../../../../core/nameOf';
import { EHoedanigheid } from '../../../../components/personalia/RelatieSelectieDialoog';
import FactuurinfoDialoog from '../../../../components/inkoopfactuur/FactuurinfoDialoog';
import BoekingDialoogV3 from '../../../../components/boekhouding/boeking/BoekingDialoogV3';
import { IDagboekenProvider } from '../../../../components/boekhouding/boeking/BoekingDialoogV3/providers/dagboeken';
import {
  IRegelsProvider,
  standaardRegelsProvider,
} from '../../../../components/boekhouding/boeking/BoekingDialoogV3/providers/regels';
import { IBoekdatumProvider } from '../../../../components/boekhouding/boeking/BoekingDialoogV3/providers/boekdatum';
import {
  maakInkoopfactuurRegel,
  maakNieuweRegel,
} from '../../../../components/boekhouding/boeking/BoekingDialoogV3/helpers';
import { dagDatum } from '../../../../helpers/datum';
import { ERegelstate } from '../../../../components/boekhouding/boeking/BoekingDialoogV3/types';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../models/IRemoteData';
import Skeleton from 'react-loading-skeleton';
import { GlobaleRendererContext } from '../../../../one-off-components/GlobaleRenderer';
import SelecteerBoekingVoorBoestukDialoog, {
  EBoekstukType,
  IDialoogOutput,
} from '../../../../components/boekhouding/boeking/SelecteerBoekingVoorBoestukDialoog';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';

interface IProps extends RouteComponentProps {}

interface IBoekingDialoogState {
  boekingID: number | null;
  inkFactID: number;
}

interface IBoekingdialoogProviders {
  dagboeken: IDagboekenProvider;
  regels: IRegelsProvider;
  boekdatum: IBoekdatumProvider;
}

export interface INieuweFactuurDialoogState {
  open: boolean;
}

export interface IWijzigenFactuurDialoogState {
  inkFactID: number;
}

export interface IFactuurinfoDialoogState {
  inkFactID: number;
}

export interface IUrlState {
  selectie: number[];
  uitgeklapt: number[];
  nieuweFactuurDialoogState: INieuweFactuurDialoogState | null;
  wijzigenFactuurDialoogState: IWijzigenFactuurDialoogState | null;
  factuurinfoDialoogState: IFactuurinfoDialoogState | null;
}

const defaultUrlState: IUrlState = {
  selectie: [],
  uitgeklapt: [],
  nieuweFactuurDialoogState: null,
  wijzigenFactuurDialoogState: null,
  factuurinfoDialoogState: null,
};

export interface IBoeking extends IOphalenBoekingenResultElement {
  regels: IOphalenBoekingRegelsResultElement[];
}

export interface IRow extends IOphalenFacturenResultElement {
  boeking: IBoeking | null;
  relatie: IOphalenRelatiesResultElementV2;
  duplicaatGevonden: boolean;
}

export interface IInboekenContext {
  onRequestRefresh: () => Promise<void>;
}

export const InboekenContext = React.createContext<IInboekenContext>(null as any);

const geenData = {
  noData: 'Geen inkoopfacturen gevonden',
};

interface IZoekgemeldTrProps {
  nietKleuren?: boolean;
}

//#dddadc

const ZoekgemeldTr = styled.tr<IZoekgemeldTrProps>`
  td {
    ${(props) =>
      props.nietKleuren
        ? ''
        : `
      background: #f7d0b2 ;
    `}
  }
`;

const Inboeken: React.FC<IProps> = observer((props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const { checkStore } = useContext(RootStoreContext);
  const globaleRenderer = useContext(GlobaleRendererContext);

  const [facturen, setFacturen] = useState<IRow[] | null>(null);
  const [boekingdialoogState, setBoekingdialoogState] = useState<IBoekingDialoogState | null>(null);

  const [boekingsregels, setBoekingsregels] = useState<
    IRemoteData<IOphalenBoekingRegelsResultElement[]>
  >(createPendingRemoteData());

  const ophalenBoekingsregels = useCallback(async () => {
    if (facturen === null) {
      setBoekingsregels(createPendingRemoteData());
      return;
    }
    const result = await api.v2.boeking.ophalenBoekingregels({
      filterSchema: {
        filters: [
          {
            naam: 'INKFACT_IDS',
            data: facturen.map((x) => x.InkFactID),
          },
          {
            naam: 'DAGBOEK_SOORT_NAAMENUMS',
            data: ['INKOOP'],
          },
        ],
      },
    });
    setBoekingsregels(createReadyRemoteData(result.regels));
  }, [facturen]);

  useEffect(() => {
    if (facturen === null) {
      return;
    }

    ophalenBoekingsregels();
  }, [
    useMemo(() => {
      if (facturen === null) {
        return null;
      }

      return JSON.stringify(facturen.map((x) => x.InkFactID));
    }, [facturen]),
  ]);

  const boekingsregelsBijInkFactID = useMemo<
    IRemoteData<Record<number, IOphalenBoekingRegelsResultElement[]>>
  >(() => {
    if (boekingsregels.state === ERemoteDataState.Pending) {
      return createPendingRemoteData();
    }

    const data = boekingsregels.data!.reduce<Record<number, IOphalenBoekingRegelsResultElement[]>>(
      (acc, boekingsregel) => ({
        ...acc,
        [boekingsregel.InkFactID!]: [...(acc[boekingsregel.InkFactID!] ?? []), boekingsregel],
      }),
      {},
    );

    return createReadyRemoteData(data);
  }, [boekingsregels]);

  const ophalenFacturen = useCallback(async () => {
    const facturenResult = await api.v2.inkoopfactuur.ophalenFacturen({
      filterSchema: {
        uitgebreideFilter: {
          or: [
            {
              filter: {
                naam: 'IS_GEBOEKT',
                data: false,
              },
            },
            {
              filter: {
                naam: 'IS_GEFIATTEERD',
                data: false,
              },
            },
          ],
        },
      },
      orderSchema: {
        orders: [
          {
            naam: 'RECORD_TOEGEVOEGD',
            richting: 'DESC',
          },
        ],
      },
      paginatie: {
        index: 0,
        aantal: 150,
      },
    });

    if (facturenResult.facturen.length === 0) {
      setFacturen([]);
      return;
    }
    const inkFactIDs = facturenResult.facturen.map((x) => x.InkFactID);
    const relIDs = _.uniq(facturenResult.facturen.map((x) => x.RelID));

    // Haal potentiele duplicaat facturen op

    const duplicaatfacturenResult = await api.v2.inkoopfactuur.ophalenFacturen({
      filterSchema: {
        filters: [
          { naam: 'REL_IDS', data: relIDs },
          // { naam: 'IDS', data: inkFactIDs, inverteren: true },
        ],
      },
    });

    // const facturenGesorteerd: IOphalenFacturenResultElement[] = _.orderBy(
    //   facturenResult.facturen,
    //   ['Factuurdatum'],
    //   ['desc'],
    // );

    const boekingen = (
      await api.v2.boeking.ophalenBoekingen({
        filterSchema: {
          filters: [
            {
              naam: 'BOEKSTUK_INK_FACT_IDS',
              data: inkFactIDs,
            },
          ],
        },
      })
    ).boekingen;

    const boekingRegels =
      boekingen.length === 0
        ? []
        : (
            await api.v2.boeking.ophalenBoekingregels({
              filterSchema: {
                filters: [
                  {
                    naam: 'BOEKING_IDS',
                    data: boekingen.map((x) => x.ID),
                  },
                ],
              },
            })
          ).regels;

    // Ophalen relatie-gegevens

    const relatiesResult = await api.v2.relatie.ophalenRelaties({
      filterSchema: {
        filters: [{ naam: 'IDS', data: relIDs }],
      },
    });

    const facturen = facturenResult.facturen.map((x) => {
      const boeking = boekingen.find((boeking) => false) ?? null;

      const regels =
        boeking === null ? null : boekingRegels.filter((regel) => regel.BoekingID === boeking.ID);

      const relatie = relatiesResult.relaties.find((r) => r.RelID === x.RelID)!;

      const duplicaatGevonden =
        duplicaatfacturenResult.facturen.find(
          (d) =>
            d.RelID === x.RelID &&
            d.InkFactID !== x.InkFactID &&
            d.Factuurnummer === x.Factuurnummer,
        ) !== undefined;

      return {
        ...x,
        relatie,
        boeking:
          boeking === null
            ? null
            : {
                ...boeking!,
                regels: regels!,
              },
        duplicaatGevonden,
      };
    });

    setFacturen(facturen);
  }, []);

  useEffect(() => {
    ophalenFacturen();
  }, [ophalenFacturen]);

  const handleFiatteren = useCallback(async () => {
    const params = {
      inkFactIDs: urlState.selectie,
    };
    const checkData = await api.v2.inkoopfactuur.checkFiatterenInkoopfacturen(params);
    if (
      (
        await checkStore.controleren({
          checkData,
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }
    if (
      (
        await checkStore.bevestigen({
          inhoud: 'De geselecteerde inkoopfacturen fiatteren?',
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    await api.v2.inkoopfactuur.fiatterenInkoopfacturen(params);
    ophalenFacturen();
  }, [urlState.selectie]);

  const handleVerwijderen = useCallback(async () => {
    const params = {
      inkFactIDs: urlState.selectie,
    };
    const checkData = await api.v2.inkoopfactuur.checkVerwijderenInkoopfacturen(params);
    if (
      (
        await checkStore.controleren({
          checkData,
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }
    if (
      (
        await checkStore.bevestigen({
          inhoud: 'De geselecteerde inkoopfacturen verwijderen?',
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    await api.v2.inkoopfactuur.verwijderenInkoopfacturen(params);
    ophalenFacturen();
  }, [urlState.selectie]);

  const keyExtractor = useCallback((row: IRow) => row.InkFactID, []);
  const kolommen = useMemo<TypedColumn<IRow>[]>(
    () => [
      {
        name: 'Factuurdatum',
        title: 'Fact.datum',
      },
      {
        name: '__factuurnummer' as any,
        title: 'Fact.nr.',
      },
      {
        name: 'Bedrag',
        title: 'Bedrag',
      },
      {
        name: '__infoFactuur' as any,
        title: ' ',
      },
      // {
      //   name: 'Openstaand',
      //   title: 'Openstaand',
      // },
      {
        name: 'Onderwerp',
        title: 'Onderwerp',
      },
      {
        name: '__relatie' as any,
        title: 'Naam',
        getCellValue: (x) => x.relatie.weergavenaam,
      },
      // {
      //   name: '__betaalwijze' as any,
      //   title: 'Betaalwijze',
      // },
      {
        name: '__heeftBestanden' as any,
        title: 'Bijl.',
      },
      {
        name: 'Gefiatteerd',
        title: 'Gefiat.',
      },
      {
        name: 'Geboekt',
        title: 'Geboekt',
      },
      {
        name: '__boeking',
        title: 'Boeking',
      },
      {
        name: 'Aanhouden',
        title: 'Aanh.',
      },
      {
        name: 'RecordToegevoegd',
        title: 'Geregistreerd',
        getCellValue: (x) => x.RecordToegevoegd,
      },
    ],
    [],
  );
  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRow>[]>(
    () => [
      {
        columnName: 'Factuurdatum',
        width: 110,
      },
      {
        columnName: '__factuurnummer' as any,
        width: 165,
      },
      {
        columnName: '__infoFactuur' as any,
        width: 60,
      },
      {
        columnName: '__relatie' as any,
        width: 175,
      },
      {
        columnName: 'Bedrag',
        width: 100,
      },
      // {
      //   columnName: 'Openstaand',
      //   width: 100,
      // },
      {
        columnName: 'Onderwerp',
        width: 250,
      },
      // {
      //   columnName: '__betaalwijze' as any,
      //   width: 150,
      // },
      {
        columnName: '__heeftBestanden' as any,
        width: 80,
      },
      {
        columnName: 'Gefiatteerd',
        width: 90,
      },
      {
        columnName: 'Geboekt',
        width: 95,
      },
      {
        columnName: '__boeking',
        width: 125,
      },
      {
        columnName: 'Aanhouden',
        width: 90,
      },
      {
        columnName: 'RecordToegevoegd',
        width: 135,
      },
    ],
    [],
  );

  const kolomExtensies: VirtualTableBase.ColumnExtension[] = useMemo(() => {
    return [
      // {
      //   columnName: `Bedrag`,
      //   align: 'right',
      // },
      // {
      //   columnName: `Openstaand`,
      //   align: 'right',
      // },
    ];
  }, []);

  const boekingdialoogProviders = useMemo<IBoekingdialoogProviders | null>(() => {
    if (boekingdialoogState === null) {
      return null;
    }

    return {
      boekdatum: {
        provide: async () => {
          const result = await api.v2.inkoopfactuur.ophalenFacturen({
            filterSchema: {
              filters: [
                {
                  naam: 'IDS',
                  data: [boekingdialoogState.inkFactID],
                },
              ],
            },
          });
          const inkoopfactuur = result.facturen[0];

          return dagDatum(new Date(inkoopfactuur.Factuurdatum));
        },
      },
      dagboeken: {
        provide: async () => {
          const result = await api.v2.boeking.dagboek.ophalenDagboeken({
            filterSchema: {
              filters: [
                {
                  naam: 'DAGBOEKSOORT_NAAM_ENUMS',
                  data: [EDagboeksoort.INKOOP],
                },
              ],
            },
          });
          return result.dagboeken;
        },
      },
      regels: {
        provide: async (boekingID, dagboek) => {
          if (boekingID === null) {
            const [grootboekenResult, inkoopfacturenResult] = await Promise.all([
              api.v2.boeking.grootboek.ophalenGrootboeken({
                filterSchema: {
                  filters: [
                    {
                      naam: 'NAAM_ENUMS',
                      data: ['CREDITEUREN'],
                    },
                  ],
                },
              }),
              api.v2.inkoopfactuur.ophalenFacturen({
                filterSchema: {
                  filters: [
                    {
                      naam: 'IDS',
                      data: [boekingdialoogState.inkFactID],
                    },
                  ],
                },
              }),
            ]);

            const inkoopfactuur = inkoopfacturenResult.facturen[0];
            const grootboek = grootboekenResult.grootboeken[0];

            const relatiesResult = await api.v2.relatie.ophalenRelaties({
              filterSchema: {
                filters: [
                  {
                    naam: 'IDS',
                    data: [inkoopfactuur.RelID],
                  },
                ],
              },
            });

            const relatie = relatiesResult.relaties[0];

            const regel = maakInkoopfactuurRegel({
              base: {
                state: ERegelstate.Weergave,
              },
              inkoopfactuur,
              grootboek,
              dagboekSoort: dagboek.dagboekSoort,
              relatie,
            });

            return [
              regel,
              {
                ...maakNieuweRegel(),
                bedrag: -regel.bedrag,
              },
            ];
          }

          return await standaardRegelsProvider.provide(boekingID, dagboek);
        },
      },
    };
  }, [boekingdialoogState]);

  const contextValue = useMemo<IInboekenContext>(
    () => ({
      onRequestRefresh: async () => await ophalenFacturen(),
    }),
    [ophalenFacturen],
  );

  return (
    <InboekenContext.Provider value={contextValue}>
      <Helmet>
        <title>Inboeken inkoopfacturen</title>
      </Helmet>
      <MenuLayout
        menu={
          <div className="d-flex">
            <button
              className="btn btn-sm btn-light d-flex align-items-center"
              style={{
                border: `1px solid ${EKleur.LichtGrijs}`,
              }}
              onClick={() => setUrlStateSync('nieuweFactuurDialoogState', { open: true })}
            >
              <IconToevoegen
                style={{
                  width: 16,
                  height: 16,
                  fill: EKleur.Grijs,
                }}
              />
              <span className="ml-2">Nieuwe inkoopfactuur</span>
            </button>
            <button
              className="btn btn-sm btn-light d-flex align-items-center ml-3"
              style={{
                border: `1px solid ${EKleur.LichtGrijs}`,
              }}
              disabled={urlState.selectie.length === 0}
              onClick={() => handleFiatteren()}
            >
              <IconVink
                style={{
                  width: 16,
                  height: 16,
                  fill: EKleur.Grijs,
                }}
              />
              <span className="ml-2">Fiatteren</span>
            </button>
            <button
              className="btn btn-sm btn-light d-flex align-items-center ml-3"
              style={{
                border: `1px solid ${EKleur.LichtGrijs}`,
              }}
              disabled={urlState.selectie.length === 0}
              onClick={() => handleVerwijderen()}
            >
              <IconVerwijderen
                style={{
                  width: 16,
                  height: 16,
                  fill: EKleur.Grijs,
                }}
              />
              <span className="ml-2">Verwijderen</span>
            </button>
          </div>
        }
        body={
          <>
            {facturen === null ? (
              <div className="d-flex flex-fill align-items-center justify-content-center">
                <LoadingSpinner />
              </div>
            ) : (
              <GridStyleWrapper height="calc(100vh - 125px)">
                <Grid columns={kolommen} rows={facturen || []} getRowId={keyExtractor}>
                  <DataTypeProvider
                    for={['__heeftBestanden']}
                    formatterComponent={(formatterProps) => {
                      const rij: IRow = formatterProps.row;
                      const heeftBestanden = rij.bestanden.length > 0 ? true : false;
                      return (
                        <span>
                          {heeftBestanden ? (
                            <IconAttachment
                              style={{
                                width: 20,
                                height: 20,
                                fill: EKleur.Grijs,
                              }}
                            />
                          ) : null}
                        </span>
                      );
                    }}
                  />

                  <DataTypeProvider
                    for={['__factuurnummer']}
                    formatterComponent={(props) => {
                      const rij: IRow = props.row;

                      return (
                        <span>
                          {rij.Factuurnummer}
                          {rij.duplicaatGevonden ? ' [Dubbel ?]' : ''}
                        </span>
                      );
                    }}
                  />

                  <DataTypeProvider
                    for={['Factuurdatum']}
                    formatterComponent={(props) => {
                      const rij: IRow = props.row;
                      const datum = format(new Date(rij.Factuurdatum), 'dd-MM-yyyy');
                      return <span>{datum}</span>;
                    }}
                  />

                  <DataTypeProvider
                    for={['__infoFactuur']}
                    formatterComponent={(props) => {
                      return (
                        <>
                          <a
                            href="#"
                            className="ml-1"
                            style={{
                              color: EKleur.LichtBlauw,
                              position: 'relative',
                              bottom: 2,
                            }}
                            onClick={() => {
                              setUrlStateSync('factuurinfoDialoogState', {
                                inkFactID: props.row.InkFactID,
                              });
                            }}
                          >
                            <IconInformatie
                              style={{
                                width: 15,
                                height: 15,
                                fill: EKleur.Blauw,
                              }}
                            />
                          </a>
                        </>
                      );
                    }}
                  />

                  <DataTypeProvider
                    for={['__relatie']}
                    formatterComponent={(formatterProps) => {
                      const rij = formatterProps.row as IRow;
                      return (
                        <RelatieVisualisatie
                          relID={rij.RelID}
                          relatieLinkBuilder={(hoedanigheid, relID) =>
                            `/${
                              hoedanigheid === EHoedanigheid.Klant ? 'klant' : 'leverancier'
                            }/${relID}/facturen/overzicht`
                          }
                        />
                      );
                    }}
                  />

                  <DataTypeProvider
                    for={['__betaalwijze']}
                    formatterComponent={(props) => {
                      const rij: IRow = props.row;
                      if (rij.Verrekenen) {
                        return <span>Verrekenen</span>;
                      }
                      const naam = rij.betaalwijze !== null ? rij.betaalwijze.Naam : '';
                      return <span>{naam}</span>;
                    }}
                  />

                  <DataTypeProvider
                    for={['Bedrag']}
                    formatterComponent={(props) => <FormatteerBedrag bedrag={props.value} />}
                  />

                  <DataTypeProvider
                    for={['Openstaand']}
                    formatterComponent={(props) => <FormatteerBedrag bedrag={props.value} />}
                  />

                  <DataTypeProvider
                    for={['Gefiatteerd']}
                    formatterComponent={(props) => {
                      return (
                        <span>
                          {props.value ? (
                            <IconVink
                              style={{
                                width: 20,
                                height: 20,
                                fill: EKleur.Groen,
                              }}
                            />
                          ) : (
                            <IconKruis
                              style={{
                                width: 20,
                                height: 20,
                                fill: EKleur.Rood,
                              }}
                            />
                          )}
                        </span>
                      );
                    }}
                  />

                  <DataTypeProvider
                    for={['Geboekt']}
                    formatterComponent={(formatterProps) => {
                      return formatterProps.value ? (
                        <IconVink
                          style={{
                            width: 20,
                            height: 20,
                            fill: EKleur.Groen,
                          }}
                        />
                      ) : (
                        <IconKruis
                          style={{
                            width: 20,
                            height: 20,
                            fill: EKleur.Rood,
                          }}
                        />
                      );
                    }}
                  />

                  <DataTypeProvider
                    for={['__boeking']}
                    formatterComponent={(props) => {
                      const rij: IRow = props.row;

                      if (boekingsregelsBijInkFactID.state === ERemoteDataState.Pending) {
                        return <Skeleton />;
                      }
                      const br = boekingsregelsBijInkFactID.data![rij.InkFactID] ?? [];
                      const boekingIDs = br.map((x) => x.BoekingID);

                      if (boekingIDs.length === 0) {
                        return (
                          <>
                            <a
                              href="#"
                              className="ml-1"
                              style={{
                                color: EKleur.LichtBlauw,
                                position: 'relative',
                                bottom: 2,
                              }}
                              onClick={() => {
                                setBoekingdialoogState({
                                  boekingID: null,
                                  inkFactID: rij.InkFactID,
                                });
                              }}
                            >
                              Nieuw
                            </a>
                          </>
                        );
                      }

                      return (
                        <>
                          <a
                            href="#"
                            className="ml-1"
                            style={{
                              color: EKleur.LichtBlauw,
                              position: 'relative',
                              bottom: 2,
                            }}
                            onClick={async () => {
                              if (boekingIDs.length === 1) {
                                const boekingID = boekingIDs[0];
                                setBoekingdialoogState({
                                  boekingID,
                                  inkFactID: rij.InkFactID,
                                });
                                return;
                              }

                              const boekingenResult = await api.v2.boeking.ophalenBoekingen({
                                filterSchema: {
                                  filters: [
                                    {
                                      naam: 'IDS',
                                      data: boekingIDs,
                                    },
                                  ],
                                },
                              });

                              const selectieResult = await globaleRenderer.render<IDialoogOutput | null>(
                                (renderProps) => (
                                  <SelecteerBoekingVoorBoestukDialoog
                                    open
                                    boekingen={boekingenResult.boekingen}
                                    boekstuk={{
                                      type: EBoekstukType.Inkoop,
                                      inkFactID: rij.InkFactID,
                                    }}
                                    onSuccess={(result) => renderProps.destroy(result)}
                                    onAnnuleren={() => renderProps.destroy(null)}
                                  />
                                ),
                              );

                              if (selectieResult === null) {
                                return;
                              }

                              setBoekingdialoogState({
                                boekingID: selectieResult!.boekingID,
                                inkFactID: rij.InkFactID,
                              });
                            }}
                          >
                            Wijzigen
                          </a>
                        </>
                      );
                    }}
                  />

                  <DataTypeProvider
                    for={['Aanhouden']}
                    formatterComponent={(formatterProps) => {
                      return (
                        <span
                          style={{
                            color: EKleur.Rood,
                          }}
                        >
                          {formatterProps.value ? 'Ja' : ''}
                        </span>
                      );
                    }}
                  />

                  <DataTypeProvider
                    for={[nameOf<IRow>('RecordToegevoegd')]}
                    formatterComponent={(formatterProps) => {
                      const datum = new Date(formatterProps.value);
                      return <span>{format(datum, 'dd-MM-yyyy HH:mm')}</span>;
                    }}
                  />

                  <RowDetailState
                    expandedRowIds={urlState.uitgeklapt}
                    onExpandedRowIdsChange={(x) => setUrlStateSync('uitgeklapt', x as number[])}
                  />
                  <SortingState defaultSorting={[]} />
                  <IntegratedSorting />

                  <VirtualTable
                    estimatedRowHeight={43}
                    columnExtensions={kolomExtensies}
                    messages={geenData}
                    rowComponent={(rowProps) => {
                      const rij: IRow = rowProps.row;

                      if (rij.duplicaatGevonden) {
                        return <ZoekgemeldTr nietKleuren={false}>{rowProps.children}</ZoekgemeldTr>;
                      }

                      return <tr>{rowProps.children}</tr>;
                    }}
                  />

                  <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
                  <TableHeaderRow showSortingControls />

                  <EditingState
                    onAddedRowsChange={() => {}}
                    onEditingRowIdsChange={(x) => {
                      const id = x[x.length - 1] as number;
                      setUrlStateSync('wijzigenFactuurDialoogState', { inkFactID: id });
                    }}
                    onCommitChanges={() => null}
                  />
                  <TableEditColumn
                    width={65}
                    // showAddCommand={true}
                    showEditCommand
                    // showDeleteCommand
                    commandComponent={DXCommandComponent}
                  />

                  <TableRowDetail
                    contentComponent={RowDetailComp}
                    toggleCellComponent={DXTableToggleCellComponent}
                  />

                  <SelectionState
                    selection={urlState.selectie}
                    onSelectionChange={(x) => setUrlStateSync('selectie', x as number[])}
                  />
                  <TableSelection cellComponent={DXTableCheckboxComponent} />
                </Grid>
              </GridStyleWrapper>
            )}

            {urlState.nieuweFactuurDialoogState !== null && (
              <NieuweFactuurDialoog
                open
                onSuccess={() => {
                  ophalenFacturen();
                  setUrlStateSync('nieuweFactuurDialoogState', null);
                }}
                onAnnuleren={() => setUrlStateSync('nieuweFactuurDialoogState', null)}
              />
            )}
            {urlState.wijzigenFactuurDialoogState !== null && (
              <WijzigenFactuurDialoog
                open
                inkFactID={urlState.wijzigenFactuurDialoogState.inkFactID}
                onSuccess={() => {
                  setUrlStateSync('wijzigenFactuurDialoogState', null);
                  ophalenFacturen();
                }}
                onAnnuleren={() => setUrlStateSync('wijzigenFactuurDialoogState', null)}
              />
            )}
            {/*{boekingdialoogState !== null && (*/}
            {/*  <BoekingdialoogV2*/}
            {/*    open*/}
            {/*    onSuccess={async () => {*/}
            {/*      setBoekingdialoogState(null);*/}
            {/*      ophalenFacturen();*/}
            {/*    }}*/}
            {/*    onAnnuleren={() => setBoekingdialoogState(null)}*/}
            {/*    boekingID={boekingdialoogState.boekingID}*/}
            {/*    // dagboekenProvider={props.boekingdialoogDagboekenProvider}*/}
            {/*    // dagboekVoorselectieKiezer={props.boekingdialoogDagboekVoorselectieKiezer}*/}
            {/*    boekstukVoorselectieKiezer={boekingdialoogBoekstukVoorselectieKiezer!}*/}
            {/*    // regelsVoorvullenProvider={boekingdialoogRegelsVoorvullenProvider!}*/}
            {/*  />*/}
            {/*)}*/}
            {boekingdialoogState !== null && boekingdialoogProviders !== null && (
              <BoekingDialoogV3
                open
                onSuccess={async () => {
                  setBoekingdialoogState(null);
                  ophalenFacturen();
                  ophalenBoekingsregels();
                }}
                onAnnuleren={() => setBoekingdialoogState(null)}
                boekingID={boekingdialoogState.boekingID ?? undefined}
                dagboekenProvider={boekingdialoogProviders.dagboeken}
                regelsProvider={boekingdialoogProviders.regels}
                boekdatumProvider={
                  boekingdialoogState.boekingID === null
                    ? boekingdialoogProviders.boekdatum
                    : undefined
                }
              />
            )}
            {urlState.factuurinfoDialoogState !== null && (
              <FactuurinfoDialoog
                open
                inkFactID={urlState.factuurinfoDialoogState.inkFactID}
                onSuccess={() => {
                  setUrlStateSync('factuurinfoDialoogState', null);
                }}
                onAnnuleren={() => setUrlStateSync('factuurinfoDialoogState', null)}
              />
            )}
          </>
        }
      />
    </InboekenContext.Provider>
  );
});

export default withRouter(Inboeken);
