import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IContentProps } from '../../shared';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../models/IRemoteData';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import NavigerenPijl from '../../Rechts/NavigerenPijl';
import api from '../../../../api';
import {
  IOphalenBestandsmappenResult,
  IOphalenBestandsmappenResultElement,
} from '../../../../../../shared/src/api/v2/bestand/bestand';
import { IconMap } from '../../../../components/Icons';
import { Kleur } from '../../../../bedrijfslogica/constanten';

const BestandsmapContent = (props: IContentProps) => {
  const [bestandsmappenResult, setBestandsmappenResult] = useState<
    IRemoteData<IOphalenBestandsmappenResult>
  >(createPendingRemoteData());
  const ophalenBestandsmappen = useCallback(async () => {
    const bovenliggendeBestandsmappenResult = await api.v2.bestand.ophalenBovenliggendeBestandsmappenVoorBestandsmappen(
      {
        bestandsmapIDs: props.ids,
      },
    );
    const bestandsmappenResult = await api.v2.bestand.ophalenBestandsmappen({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: bovenliggendeBestandsmappenResult.bestandsmapIDs,
          },
        ],
      },
    });
    setBestandsmappenResult(createReadyRemoteData(bestandsmappenResult));
  }, [JSON.stringify(props.ids)]);

  useEffect(() => {
    ophalenBestandsmappen();
  }, [ophalenBestandsmappen]);

  const bestandsmappenMap = useMemo<
    IRemoteData<{ [bestandsmapID: number]: IOphalenBestandsmappenResultElement }>
  >(
    () =>
      bestandsmappenResult.state === ERemoteDataState.Pending
        ? createPendingRemoteData()
        : createReadyRemoteData(
            bestandsmappenResult.data!.mappen.reduce(
              (acc, curr) => ({
                ...acc,
                [curr.ID]: curr,
              }),
              {},
            ),
          ),
    [bestandsmappenResult],
  );

  const handleNavigeren = useCallback(() => {}, []);

  return (
    <div style={{ paddingLeft: 35, paddingRight: 20 }} className="d-flex flex-column">
      {bestandsmappenResult.state === ERemoteDataState.Pending ? (
        <div className="d-flex flex-fill align-items-center justify-content-center">
          <LoadingSpinner />
        </div>
      ) : (
        props.ids.map((id) => {
          const bestandsmap = bestandsmappenMap.data![id];

          const recursiefPadBepalen = (
            bestandsmapID: number | null,
            curr?: number[],
          ): number[] | null => {
            if (bestandsmapID === null) {
              return curr ?? [];
            }
            const bestandsmap = bestandsmappenMap.data![bestandsmapID] ?? null;
            if (bestandsmap === null) {
              return null;
            }
            const boom = recursiefPadBepalen(bestandsmap.Bovenliggende_BestandsmapID);
            if (boom === null) {
              return null;
            }
            return [...boom, bestandsmapID];
          };
          const padArr: number[] | null = recursiefPadBepalen(
            bestandsmap.Bovenliggende_BestandsmapID,
          );
          const pad =
            padArr === null
              ? null
              : padArr.map((id) => bestandsmappenMap.data![id]!.Naam).join('/');

          return (
            <div key={bestandsmap.ID} className="p-2 d-flex align-items-center">
              <IconMap style={{ width: 24, height: 24, fill: Kleur.ZwembadBlauw }} />
              <div className="flex-fill ml-3 d-flex flex-column">
                <span>{bestandsmap.Naam}</span>
                <span className="text-muted">/{pad}</span>
              </div>
              <NavigerenPijl onClick={handleNavigeren} />
            </div>
          );
        })
      )}
    </div>
  );
};

export default BestandsmapContent;
