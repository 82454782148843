import {
  IOphalenGebiedenParams,
  IOphalenGebiedenResult,
  IToevoegenGebiedenParams,
  IToevoegenGebiedenResult,
  IVerwijderenGebiedenParams,
  IVerwijderenGebiedenResult,
} from '../../../../../../../shared/src/api/v2/dienst/service/gebied';
import api from '../../../../index';
import ICheckData from '../../../../../../../shared/src/models/ICheckData';

const gebied = {
  ophalenGebieden: async (params: IOphalenGebiedenParams): Promise<IOphalenGebiedenResult> => {
    return await api.post('v2/dienst/service/gebied/ophalen-gebieden', params);
  },
  checkToevoegenGebieden: async (params: IToevoegenGebiedenParams): Promise<ICheckData> => {
    return await api.post('v2/dienst/service/gebied/check-toevoegen-gebieden', params);
  },
  toevoegenGebieden: async (
    params: IToevoegenGebiedenParams,
  ): Promise<IToevoegenGebiedenResult> => {
    return await api.post('v2/dienst/service/gebied/toevoegen-gebieden', params);
  },
  verwijderenGebieden: async (
    params: IVerwijderenGebiedenParams,
  ): Promise<IVerwijderenGebiedenResult> => {
    return await api.post('v2/dienst/service/gebied/verwijderen-gebieden', params);
  },
};

export default gebied;
