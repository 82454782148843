import React from 'react';
import { IWeergaveProps } from '../../../../FilterBalkV2';
import { FilterSoort } from '../index';

const HeeftSignaleringenFilter = (props: IWeergaveProps<FilterSoort>) => {
  return (
    <div className="d-flex align-items-center">
      <span>Heeft signaleringen</span>
    </div>
  );
};

export default HeeftSignaleringenFilter;
