import * as React from 'react';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import IDialoogProps from '../../../../core/IDialoogProps';
import Modal from 'react-bootstrap/Modal';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import api from '../../../../api';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalHeader from 'react-bootstrap/ModalHeader';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import { RootStoreContext } from '../../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import { EResultType } from '../../../../stores/CheckStore';
import { Formik, FormikProps, Field, FieldProps, FormikActions } from 'formik';
import nameOf from '../../../../core/nameOf';
import VinkVeld from '../../../../components/formulier/VinkVeld';
import * as Yup from 'yup';
import { IOphalenBeltakenResultElement } from '../../../../../../shared/src/api/v2/beltaak/beltaak';
import DatumKiezer from '../../../../components/formulier/DatumKiezer';
import { addDays } from 'date-fns';
import { dagDatum } from '../../../../helpers/datum';
import RadioKnop from '../../../../components/formulier/RadioKnop';

interface IProps extends IDialoogProps {
  ids: number[];
}

export interface IFormikValues {
  contactGehad: EContactGehad;
  gesprokenOp: Date | string | null;
  berichtDebiteurenVersturen: boolean;
}

const veldnamen = {
  contactGehad: 'Er is contact geweest',
  gesprokenOp: 'Gesproken op',
  berichtDebiteurenVersturen: 'Contactverzoek per SMS versturen',
};

enum EContactGehad {
  JA = 'JA',
  NEE = 'NEE',
}

const MarkerenAfgehandeldDialoog: React.FC<IProps> = observer((props) => {
  const { checkStore } = useContext(RootStoreContext);

  const [beltaken, setBeltaken] = useState<IOphalenBeltakenResultElement[] | null>(null);

  const ophalenBeltaken = useCallback(async () => {
    const beltakenResult = await api.v2.beltaak.ophalenBeltaken({
      filterSchema: { filters: [{ naam: 'IDS', data: props.ids }] },
    });

    setBeltaken(beltakenResult.beltaken);
  }, [props.ids]);

  useEffect(() => {
    ophalenBeltaken();
  }, [ophalenBeltaken]);

  const initieleWaarden = useMemo<IFormikValues | null>(() => {
    if (beltaken === null) {
      return null;
    }

    return {
      contactGehad: EContactGehad.NEE,
      gesprokenOp: dagDatum(new Date()),
      berichtDebiteurenVersturen: false,
    };
  }, [beltaken]);

  const handleWijzigen = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      if (beltaken === null) {
        return null;
      }

      actions.setSubmitting(true);

      const params = {
        ids: props.ids,
        gesprokenOp: values.contactGehad === EContactGehad.JA ? values.gesprokenOp : null,
        isAfgehandeld: true,
      };

      const checkData = await api.v2.beltaak.checkMarkerenAfgehandeld(params);

      if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
        actions.setSubmitting(false);
        return;
      }

      if (
        (
          await checkStore.bevestigen({
            inhoud: (
              <span>
                Geselecteerde beltaken als Afgehandeld markeren?
                {values.contactGehad === EContactGehad.NEE && (
                  <span>
                    <br />
                    (Er is GEEN contact geweest)
                  </span>
                )}
                {values.berichtDebiteurenVersturen && (
                  <span>
                    <br />
                    <br />
                    Let op: je hebt aangegeven dat er direct een SMS gestuurd moet worden
                  </span>
                )}
              </span>
            ),
          })
        ).type === EResultType.Annuleren
      ) {
        actions.setSubmitting(false);
        return;
      }

      // const checkData = await api.v2.appEvent.chekcWijzigenEventtype(params);

      // if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
      //   setIsSubmitting(false);
      //   return;
      // }

      await api.v2.beltaak.markerenAfgehandeld(params);

      if (!values.contactGehad && values.berichtDebiteurenVersturen) {
        await api.v2.beltaak.versturenContactpogingDebiteuren({ ids: props.ids });
      }

      actions.setSubmitting(false);

      setBeltaken(null);
      props.onSuccess({});
    },
    [props.onSuccess, beltaken],
  );

  const handleAnnuleren = useCallback(() => {
    props.onAnnuleren();
    setBeltaken(null);
  }, [props.onAnnuleren, setBeltaken]);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        contactGehad: Yup.string(),
      }),
    [],
  );

  return (
    <Modal show={props.open}>
      <ModalHeader>
        <ModalTitle>Markeren afgehandeld</ModalTitle>
      </ModalHeader>
      {initieleWaarden === null || beltaken === null ? (
        <LoadingSpinner />
      ) : (
        <Formik<IFormikValues>
          isInitialValid
          onSubmit={handleWijzigen}
          initialValues={initieleWaarden}
          validationSchema={validationSchema}
          validateOnChange
          validateOnBlur
          render={(formikProps: FormikProps<IFormikValues>) => {
            const { submitForm, isSubmitting, values, isValid } = formikProps;
            return (
              <>
                <ModalBody>
                  <div className="form-group">
                    <div className="row">
                      {/* <div className="col-12 d-flex align-items-center">
                        <Field
                          name={nameOf<IFormikValues>('contactGehad')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <VinkVeld
                                aangevinkt={field.value}
                                onGewijzigd={(x) => {
                                  form.setFieldValue(field.name, x);
                                }}
                              />
                            );
                          }}
                        />
                        <span className="ml-2">{veldnamen.contactGehad}</span>
                      </div> */}

                      <div className="col-12 d-flex align-items-center">
                        <Field
                          name={nameOf<IFormikValues>('contactGehad')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <>
                                <div className="d-flex" style={{ width: 450 }}>
                                  <span className="d-flex align-items-center">
                                    <RadioKnop
                                      aangevinkt={field.value === EContactGehad.NEE}
                                      onAangevinkt={() =>
                                        form.setFieldValue(field.name, EContactGehad.NEE)
                                      }
                                    />
                                    Geen contact gehad
                                  </span>
                                  <span className="d-flex align-items-center ml-5">
                                    <RadioKnop
                                      aangevinkt={field.value === EContactGehad.JA}
                                      onAangevinkt={() =>
                                        form.setFieldValue(field.name, EContactGehad.JA)
                                      }
                                    />
                                    Wel contact gehad
                                  </span>
                                </div>
                              </>
                            );
                          }}
                        />
                      </div>

                      {values.contactGehad === EContactGehad.JA && (
                        <div className="col-12 mt-3">
                          <label>{veldnamen.gesprokenOp}</label>
                          <Field
                            name={nameOf<IFormikValues>('gesprokenOp')}
                            render={(fieldProps: FieldProps<IFormikValues>) => {
                              const { field, form } = fieldProps;

                              return (
                                <DatumKiezer
                                  disabled={!values.contactGehad}
                                  waarde={field.value}
                                  onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                  determineValidDate={(datum) => {
                                    return true;
                                  }}
                                  determinePreviousValidDate={(date) => {
                                    return addDays(date, -1);
                                  }}
                                  determineNextValidDate={(date) => {
                                    const newDate = addDays(date, +1);
                                    return addDays(date, +1);
                                  }}
                                  isClearable
                                  placeholder={'Leeg is geen contact'}
                                />
                              );
                            }}
                          />
                        </div>
                      )}

                      {/* {values.contactGehad === EContactGehad.NEE && (
                        <div className="col-12 d-flex align-items-center mt-4">
                          <Field
                            name={nameOf<IFormikValues>('berichtDebiteurenVersturen')}
                            render={(fieldProps: FieldProps<IFormikValues>) => {
                              const { field, form } = fieldProps;
                              return (
                                <VinkVeld
                                  aangevinkt={field.value}
                                  onGewijzigd={(x) => {
                                    form.setFieldValue(field.name, x);
                                  }}
                                />
                              );
                            }}
                          />
                          <span className="ml-2">{veldnamen.berichtDebiteurenVersturen}</span>
                        </div>
                      )} */}
                    </div>
                  </div>
                </ModalBody>
                <ModalFooter className="d-flex justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    disabled={isSubmitting || !isValid}
                    style={{ width: 100 }}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={handleAnnuleren}
                    disabled={isSubmitting || !isValid}
                    style={{ width: 100 }}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Modal>
  );
});

export default MarkerenAfgehandeldDialoog;
