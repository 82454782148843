import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import useUrlState from '../../../../core/useUrlState';
import api from '../../../../api';
import {
  DXTableCheckboxComponent,
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import {
  Grid,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  TableSelection,
  VirtualTable,
  TableColumnVisibility,
} from '@devexpress/dx-react-grid-bootstrap4';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import { IOphalenTariefsetsResultElement } from '../../../../../../shared/src/api/v2/aanbod/tarieven';
import {
  DataTypeProvider,
  EditingState,
  SelectionState,
  VirtualTable as VirtualTableBase,
} from '@devexpress/dx-react-grid';
import { IOphalenAbonnementenVoorLijstResult } from '../../../../../../shared/src/api/v2/aanbod/Abonnement';
import FormatteerBedrag from '../../../../components/MutatieBedrag';
import nameof from '../../../../core/nameOf';
import { format } from 'date-fns';
import FilterBalkV2, {
  IFilterData,
  IFilter,
  maakFilterSchema,
} from '../../../../components/FilterBalkV2';
import { IFilterSchema } from '../../../../../../shared/src/models/filter';
import DatumKiezer from '../../../../components/formulier/DatumKiezer';
import SelectieVak from '../../../../components/SelectieVak';
import { Kleur as EKleur } from '../../../../bedrijfslogica/constanten';
import { IconInformatie, IconToevoegen } from '../../../../components/Icons';
import TariefToevoegenDialoog from './TariefToevoegenDialoog';
import MultiCombobox, { IKolom } from '../../../../components/formulier/MultiCombobox';
import {
  IOphalenProductmodellenResult,
  IOphalenProductmodellenResultElement,
} from '../../../../../../shared/src/api/v2/aanbod/productmodel';
import { dagDatum, datumsZijnOpDezelfdeDag } from '../../../../helpers/datum';
import * as _ from 'lodash';
import { RootStoreContext } from '../../../../stores/RootStore';
import { EResultType } from '../../../../stores/CheckStore';
import MenuLayout from '../../../../components/MenuLayout';
import { IOphalenProductsoortenResultElement } from '../../../../../../shared/src/api/v2/product/soort';
import ProductmodelInfoDialoog from '../../../../components/productmodel/ProductmodelInfoDialoog';
import TariefWijzigenDialoog from './TariefWijzigenDialoog';
import { Helmet } from 'react-helmet';

enum EFilter {
  Peildatum = 'PEILDATUM',
  Productmodel = 'PRODMOD_IDS',
  Productsoort = 'PRODSRT_IDS',
}

interface IToevoegenDialoogTonenData {
  prodModID?: number;
}
interface IWijzigenDialoogTonenData {
  tarNomID: number;
}
interface IProductmodelInfoDialoogState {
  prodModID: number;
}

interface IUrlState {
  selectie: number[];
  filterData: IFilterData<EFilter>[];
  toevoegenDialoogTonen: IToevoegenDialoogTonenData | null;
  wijzigenDialoogTonen: IWijzigenDialoogTonenData | null;
  productmodelInfoDialoogState: IProductmodelInfoDialoogState | null;
}

const defaultUrlState: IUrlState = {
  selectie: [],
  toevoegenDialoogTonen: null,
  wijzigenDialoogTonen: null,
  productmodelInfoDialoogState: null,
  filterData: [
    {
      naam: EFilter.Peildatum,
      data: dagDatum(new Date()),
      isActief: true,
    },
    {
      naam: EFilter.Productmodel,
      data: null,
      isActief: false,
    },
    {
      naam: EFilter.Productsoort,
      data: null,
      isActief: false,
    },
  ],
};

interface IProductsoort {
  naam: string;
}

// interface IModel {
//   prodModID: number;
//   naam: string;
//   code: string;
// }

interface ITarief {
  abonID: number;
  maandhuur: number;
  eenmaligBedrag: number;
}

interface IRow {
  tarNomID: number;
  prodSrtID: number;
  productsoort: IProductsoort;
  model: IOphalenProductmodellenResultElement;
  kenmerk: string;
  ingangsdatum: Date;
  einddatum: Date | null;
  tarieven: ITarief[];
}

interface IProps extends RouteComponentProps {}

interface ITariefset extends IOphalenTariefsetsResultElement {
  productmodel: IOphalenProductmodellenResultElement;
}

const Tarieven: React.FC<IProps> = (props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const { checkStore } = useContext(RootStoreContext);

  const [tarieven, setTarieven] = useState<ITariefset[] | null>(null);
  const [eenmaligeBedragenWeergeven, setEenmaligeBedragenWeergeven] = useState<boolean>(false);
  const [abonnementen, setAbonnementen] = useState<IOphalenAbonnementenVoorLijstResult | null>(
    null,
  );
  const [
    productmodellenOpties,
    setProductmodellenOpties,
  ] = useState<IOphalenProductmodellenResult | null>(null);
  const [productsoortenOpties, setProductsoortenOpties] = useState<
    IOphalenProductsoortenResultElement[] | null
  >(null);

  // const [productmodellen, setProductmodellen] = useState<
  //   IOphalenProductmodellenResultElement[] | null
  // >(null);

  const ophalenProductmodellenOpties = useCallback(async () => {
    const result = await api.v2.aanbod.productmodel.ophalenProductmodellen({
      filterSchema: { filters: [{ naam: 'ACTIEF', data: true }] },
    });
    setProductmodellenOpties(result);
  }, []);

  useEffect(() => {
    ophalenProductmodellenOpties();
  }, [ophalenProductmodellenOpties]);

  const ophalenProductsoortenOpties = useCallback(async () => {
    const result = await api.v2.productsoort.ophalenProductsoorten({
      filterSchema: { filters: [{ naam: 'ACTIEF', data: true }] },
    });

    setProductsoortenOpties(result);
  }, []);

  useEffect(() => {
    ophalenProductsoortenOpties();
  }, [ophalenProductsoortenOpties]);

  const [filterSchema, setFilterSchema] = useState<IFilterSchema>(
    maakFilterSchema(urlState.filterData),
  );

  const ophalenTarieven = useCallback(async () => {
    if (filterSchema === null) {
      setTarieven(null);
      return;
    }

    const tarievenResult = await api.v2.aanbod.tarieven.ophalenTariefsets({
      filterSchema,
    });

    const prodModIDs = _.uniq(tarievenResult.tariefsets.map((x) => x.ProdModID));
    const productmodellenResult = await api.v2.aanbod.productmodel.ophalenProductmodellen({
      filterSchema: { filters: [{ naam: 'IDS', data: prodModIDs }] },
    });

    const result = tarievenResult.tariefsets.map((x: IOphalenTariefsetsResultElement) => {
      const productmodel = productmodellenResult.modellen.find((m) => m.ProdModID === x.ProdModID);
      return { ...x, productmodel };
    });

    const resultGesorteerd = _.orderBy(
      result,
      [
        (x: ITariefset) => {
          return x.productmodel.Modelcode;
        },
        (x: ITariefset) => {
          return x.Ingangsdatum;
        },
      ],
      ['asc', 'desc'],
    );

    setTarieven(resultGesorteerd);
  }, [filterSchema]);

  // const ophalenProductmodellen = useCallback(async () => {
  //   if (tarieven === null) {
  //     return;
  //   }
  //   if (tarieven.length === 0) {
  //     setProductmodellen([]);
  //     return;
  //   }
  //   const prodModIDs = _.uniq(tarieven.map((x) => x.ProdModID));
  //   const result = await api.v2.aanbod.productmodel.ophalenProductmodellen({
  //     filterSchema: { filters: [{ naam: 'IDS', data: prodModIDs }] },
  //   });
  //   setProductmodellen(result.modellen);
  // }, [tarieven]);

  // useEffect(() => {
  //   ophalenProductmodellen();
  // }, [ophalenProductmodellen]);

  const ophalenAbonnementen = useCallback(async () => {
    const result = await api.v2.abonnement.ophalenAbonnementenVoorLijst({ actief: null });
    setAbonnementen(result);
  }, []);

  useEffect(() => {
    ophalenTarieven();
  }, [ophalenTarieven]);

  useEffect(() => {
    ophalenAbonnementen();
  }, [ophalenAbonnementen]);

  const handleVerwijderen = useCallback(
    async (id: number) => {
      const checkParams = { tarNomIDs: [id] };
      const checkData = await api.v2.aanbod.tarieven.checkVerwijderenTariefsets(checkParams);

      if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
        return;
      }
      if (
        (
          await checkStore.bevestigen({
            inhoud: `Wil je de tariefset verwijderen?`,
          })
        ).type === EResultType.Annuleren
      ) {
        return;
      }
      await api.v2.aanbod.tarieven.verwijderenTariefsets(checkParams);

      setUrlStateSync('selectie', []);

      ophalenTarieven();
    },
    [ophalenTarieven],
  );

  const productmodellenKolommen = useMemo<IKolom<IOphalenProductmodellenResultElement>[]>(
    () => [
      {
        key: 'Modelcode',
        label: 'Modelcode',
        breedte: 125,
      },
      {
        key: 'Modelnaam',
        label: 'Naam',
        breedte: 200,
      },
      // {
      //   key: 'Actief',
      //   label: 'Actief',
      //   breedte: 90,
      // },
      {
        key: 'Kenmerk',
        label: 'Kenmerk',
        breedte: 200,
      },
    ],
    [],
  );

  const productsoortenKolommen = useMemo<IKolom<IOphalenProductsoortenResultElement>[]>(
    () => [
      {
        key: 'Naam',
        label: 'Naam',
        breedte: 125,
      },
      // {
      //   key: 'Actief',
      //   label: 'Actief',
      //   breedte: 90,
      // },
      // {
      //   key: 'Kenmerk',
      //   label: 'Kenmerk',
      //   breedte: 200,
      // },
    ],
    [],
  );

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.Peildatum,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          const datum = new Date(weergaveProps.data);
          return (
            <span className="d-flex align-items-center">
              <span>Peildatum</span>
              <span className="ml-2">
                <DatumKiezer
                  waarde={datum}
                  onGewijzigd={(datum) => {
                    weergaveProps.onDataChange(datum === null ? null : datum.toISOString());
                    weergaveProps.toepassen();
                  }}
                />
              </span>
              {!datumsZijnOpDezelfdeDag(new Date(), datum) && (
                <span className="ml-2">
                  <a
                    href="#"
                    onClick={() => {
                      weergaveProps.onDataChange(dagDatum(new Date()));
                      weergaveProps.toepassen();
                    }}
                  >
                    Vandaag
                  </a>
                </span>
              )}
            </span>
          );
        },
      },
      {
        naam: EFilter.Productsoort,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return (
            <span className="d-flex align-items-center">
              <span>Productsoort</span>
              <span className="ml-2">
                {productsoortenOpties === null ? (
                  <LoadingSpinner />
                ) : (
                  <MultiCombobox<number, IOphalenProductsoortenResultElement>
                    sleutelExtractor={(row) => row.ProdSrtID}
                    waarde={weergaveProps.data === null ? null : weergaveProps.data[0]}
                    onWaardeChange={(value: number | null) => {
                      weergaveProps.onDataChange(value === null ? null : [value]);
                      weergaveProps.setIsActief(true);
                      weergaveProps.toepassen();
                    }}
                    representatieFabriek={(entiteit) => entiteit.Naam}
                    opties={productsoortenOpties}
                    kolommen={productsoortenKolommen}
                  />
                )}
              </span>
            </span>
          );
        },
      },
      {
        naam: EFilter.Productmodel,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return (
            <span className="d-flex align-items-center">
              <span>Productmodel</span>
              <span className="ml-2">
                {productmodellenOpties === null ? (
                  <LoadingSpinner />
                ) : (
                  <MultiCombobox<number, IOphalenProductmodellenResultElement>
                    sleutelExtractor={(row) => row.ProdModID}
                    waarde={weergaveProps.data === null ? null : weergaveProps.data[0]}
                    onWaardeChange={(value: number | null) => {
                      weergaveProps.onDataChange(value === null ? null : [value]);
                      weergaveProps.setIsActief(true);
                      weergaveProps.toepassen();
                    }}
                    representatieFabriek={(entiteit) => entiteit.Modelcode}
                    opties={productmodellenOpties!.modellen}
                    kolommen={productmodellenKolommen}
                  />
                )}
              </span>
            </span>
          );
        },
      },
    ],
    [productmodellenOpties, productsoortenOpties],
  );

  const rijen = useMemo<IRow[] | null>(() => {
    if (tarieven === null) {
      return null;
    }
    return tarieven.map((tariefset) => {
      return {
        tarNomID: tariefset.TarNomID,
        prodSrtID: tariefset.ProdSrtID,
        productsoort: {
          naam: tariefset.productsoort.Naam,
        },
        model: tariefset.productmodel,
        // model: {
        //   prodModID: tariefset.ProdModID,
        //   naam: tariefset.Modelnaam,
        //   code: tariefset.Modelcode,
        // },
        ingangsdatum: new Date(tariefset.Ingangsdatum),
        einddatum: tariefset.Einddatum === null ? null : new Date(tariefset.Einddatum),
        kenmerk: tariefset.Kenmerk,
        tarieven: tariefset.tarieven.map((tarief) => {
          return {
            abonID: tarief.AbonID,
            maandhuur: tarief.Maandhuur,
            eenmaligBedrag: tarief.EenmaligBedrag,
          };
        }),
      };
    });
  }, [tarieven]);

  const keyExtractor = useCallback((row: IRow) => row.tarNomID, []);

  const kolommen = useMemo<TypedColumn<IRow>[] | null>(() => {
    if (abonnementen === null) {
      return null;
    }

    return [
      {
        name: '__model_code' as any,
        title: 'Modelcode',
      },
      {
        name: '__model_naam' as any,
        title: 'Modelnaam',
      },
      {
        name: '__productmodelinfo' as any,
        title: ' ',
      },
      {
        name: '__actief' as any,
        title: 'Actief',
      },
      {
        name: 'ingangsdatum',
        title: 'Ingangsdatum',
      },
      {
        name: 'einddatum',
        title: 'Geldig tot',
      },
      ...abonnementen.map((abonnement) => {
        return {
          name: `__abon_${abonnement.AbonID}` as any,
          title: abonnement.NaamKort,
        };
      }),
      {
        name: '__acties',
        title: ' ',
      },
    ];
  }, [abonnementen]);

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRow>[] | null>(() => {
    if (abonnementen === null) {
      return null;
    }

    return [
      {
        columnName: '__model_code' as any,
        width: 150,
      },
      {
        columnName: '__model_naam' as any,
        width: 250,
      },
      {
        columnName: '__productmodelinfo' as any,
        width: 80,
      },
      {
        columnName: '__actief' as any,
        width: 90,
      },
      {
        columnName: 'ingangsdatum',
        width: 120,
      },
      {
        columnName: 'einddatum',
        width: 125,
      },
      ...abonnementen.map((abonnement) => {
        return {
          columnName: `__abon_${abonnement.AbonID}` as any,
          width: 100,
        };
      }),
      {
        columnName: '__acties',
        width: 200,
      },
    ];
  }, [abonnementen]);

  const verstopteKolommen = useMemo<string[] | null>(() => {
    if (tarieven === null || abonnementen === null) {
      return null;
    }

    const abonIDs = [
      ...new Set(
        tarieven.map((tariefset) => tariefset.tarieven.map((tarief) => tarief.AbonID)).flat(),
      ),
    ];

    return abonnementen
      .filter((abonnement) => abonIDs.indexOf(abonnement.AbonID) === -1)
      .sort((a, b) => a.SortNr - b.SortNr)
      .map((x) => `__abon_${x.AbonID}`);
  }, [tarieven, abonnementen]);

  const tabelKolomExtensies: VirtualTableBase.ColumnExtension[] | null = useMemo(() => {
    if (abonnementen === null) {
      return null;
    }

    return [
      ...abonnementen.map(
        (abonnement): VirtualTable.ColumnExtension => {
          return {
            columnName: `__abon_${abonnement.AbonID}` as any,
            // align: 'right',
          };
        },
      ),
    ];
  }, [abonnementen]);

  const isAanHetLaden = useMemo(() => {
    return (
      rijen === null ||
      abonnementen === null ||
      // benodigdeAbonnementen === null ||
      kolommen === null ||
      kolomBreedtes === null ||
      tabelKolomExtensies === null
    );
  }, [rijen, abonnementen, kolommen, kolomBreedtes, tabelKolomExtensies]);

  return isAanHetLaden ? (
    <div className="flex-fill d-flex align-items-center justify-content-center">
      <LoadingSpinner />
    </div>
  ) : (
    <>
      <Helmet>
        <title>Verhuurtarieven</title>
      </Helmet>
      <MenuLayout
        menu={
          <>
            <div>
              <div className="d-flex align-items-center">
                <button
                  className="btn btn-sm btn-light d-flex align-items-center"
                  style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                  onClick={() => setUrlStateSync('toevoegenDialoogTonen', {})}
                >
                  <span>
                    <IconToevoegen style={{ width: 16, height: 16, fill: EKleur.Grijs }} />
                  </span>
                  <span className="ml-2">Toevoegen</span>
                </button>

                <div className="d-flex flex-fill ml-3">
                  <FilterBalkV2
                    filters={filters}
                    filterData={urlState.filterData}
                    onFilterDataChange={(value) => {
                      setUrlStateSync('filterData', value);
                    }}
                    onFilterSchemaChange={(schema) => setFilterSchema(schema)}
                  />
                </div>
              </div>
              <div className="d-flex mt-2">
                <div className="flex-fill d-flex">
                  <SelectieVak
                    totaalAantal={rijen!.length}
                    aantal={urlState.selectie.length}
                    entiteitEnkelvoud="tarief"
                    entiteitMeervoud="tarieven"
                    onChange={(allesGeselecteerd) => {
                      if (allesGeselecteerd) {
                        setUrlStateSync('selectie', rijen!.map(keyExtractor));
                      } else {
                        setUrlStateSync('selectie', []);
                      }
                    }}
                  />
                  <span className="ml-2">Totaal {rijen!.length} tarieven</span>
                </div>
                <div>
                  <a
                    href="#"
                    onClick={async () => {
                      setEenmaligeBedragenWeergeven(!eenmaligeBedragenWeergeven);
                    }}
                  >
                    {eenmaligeBedragenWeergeven
                      ? 'Geen eenmalige tarieven weergeven'
                      : 'Eenmalige tarieven weergeven'}
                  </a>
                </div>
              </div>
            </div>
          </>
        }
        body={
          <>
            <div>
              <GridStyleWrapper height="calc(100vh - 250px)">
                <Grid getRowId={keyExtractor} rows={rijen!} columns={kolommen!}>
                  <DataTypeProvider
                    for={['__model_code']}
                    formatterComponent={(formatterProps) => {
                      const rij: IRow = formatterProps.row;
                      return <span>{rij.model.Modelcode}</span>;
                    }}
                  />
                  <DataTypeProvider
                    for={['__model_naam']}
                    formatterComponent={(formatterProps) => {
                      const rij: IRow = formatterProps.row;
                      return <span>{rij.model.Modelnaam}</span>;
                    }}
                  />
                  <DataTypeProvider
                    for={['__actief']}
                    formatterComponent={(formatterProps) => {
                      const rij: IRow = formatterProps.row;

                      return rij.model.Actief ? (
                        <span style={{ color: EKleur.Groen }}>Ja</span>
                      ) : (
                        <span style={{ color: EKleur.Rood }}>Nee</span>
                      );
                    }}
                  />
                  <DataTypeProvider
                    for={[nameof<IRow>('ingangsdatum')]}
                    formatterComponent={(formatterProps) => {
                      const rij: IRow = formatterProps.row;
                      if (rij.ingangsdatum === null) {
                        return <span />;
                      }
                      return <span>{format(rij.ingangsdatum, 'dd-MM-yyyy')}</span>;
                    }}
                  />
                  <DataTypeProvider
                    for={[nameof<IRow>('einddatum')]}
                    formatterComponent={(formatterProps) => {
                      const rij: IRow = formatterProps.row;
                      if (rij.einddatum === null) {
                        return <span />;
                      }
                      return <span>{format(rij.einddatum, 'dd-MM-yyyy')}</span>;
                    }}
                  />

                  {abonnementen!.map((abonnement) => {
                    return (
                      <DataTypeProvider
                        key={abonnement.AbonID}
                        for={[`__abon_${abonnement.AbonID}`]}
                        formatterComponent={(formatterProps) => {
                          const row: IRow = formatterProps.row;
                          const tarief = row.tarieven.find(
                            (tarief) => tarief.abonID === abonnement.AbonID,
                          );
                          if (tarief === undefined) {
                            return <span />;
                          }
                          return (
                            <span>
                              <FormatteerBedrag bedrag={tarief.maandhuur} />
                              {eenmaligeBedragenWeergeven && (
                                <FormatteerBedrag bedrag={tarief.eenmaligBedrag} />
                              )}
                            </span>
                          );
                        }}
                      />
                    );
                  })}

                  <DataTypeProvider
                    for={['__acties']}
                    formatterComponent={(formatterProps) => {
                      const row: IRow = formatterProps.row;
                      return (
                        <span className="ml-2">
                          <a
                            href="#"
                            onClick={() =>
                              setUrlStateSync('toevoegenDialoogTonen', {
                                prodModID: row.model.ProdModID,
                              })
                            }
                          >
                            Toevoegen
                          </a>
                        </span>
                      );
                    }}
                  />

                  <DataTypeProvider
                    for={['__productmodelinfo']}
                    formatterComponent={(formatterProps) => {
                      const rij: IRow = formatterProps.row;
                      return (
                        <a
                          href="#"
                          onClick={async () =>
                            setUrlStateSync('productmodelInfoDialoogState', {
                              prodModID: rij.model.ProdModID,
                            })
                          }
                        >
                          <IconInformatie style={{ width: 15, height: 15, fill: EKleur.Blauw }} />
                        </a>
                      );
                    }}
                  />

                  <SelectionState
                    selection={urlState.selectie}
                    onSelectionChange={(value) => setUrlStateSync('selectie', value as number[])}
                  />
                  <EditingState
                    onAddedRowsChange={() => {}}
                    onCommitChanges={(changes) => {
                      if (changes.deleted === undefined) {
                        return;
                      }
                      const deleted = changes.deleted;
                      const id = deleted[deleted.length - 1] as number;
                      handleVerwijderen(id);
                    }}
                    onEditingRowIdsChange={(rowIds) => {
                      const id = rowIds[rowIds.length - 1] as number;
                      setUrlStateSync('wijzigenDialoogTonen', { tarNomID: id });
                    }}
                  />

                  {/* <TableColumnResizing defaultColumnWidths={kolomBreedtes} /> */}

                  <VirtualTable columnExtensions={tabelKolomExtensies || undefined} />
                  <TableEditColumn
                    width={65}
                    showEditCommand
                    // showAddCommand
                    showDeleteCommand
                    cellComponent={DXTableEditColumnCellComponent}
                    commandComponent={DXTableEditColumnCommandComponent}
                  />
                  <TableColumnResizing defaultColumnWidths={kolomBreedtes!} />
                  <TableHeaderRow />
                  <TableColumnVisibility defaultHiddenColumnNames={verstopteKolommen || []} />
                  <TableSelection cellComponent={DXTableCheckboxComponent} />
                </Grid>
              </GridStyleWrapper>
            </div>
            {urlState.toevoegenDialoogTonen !== null && (
              <TariefToevoegenDialoog
                open
                onSuccess={async () => {
                  setUrlStateSync('toevoegenDialoogTonen', null);
                  await ophalenTarieven();
                }}
                onAnnuleren={() => setUrlStateSync('toevoegenDialoogTonen', null)}
                prodModID={urlState.toevoegenDialoogTonen.prodModID}
              />
            )}
            {urlState.wijzigenDialoogTonen !== null && (
              <TariefWijzigenDialoog
                open
                onSuccess={async () => {
                  setUrlStateSync('wijzigenDialoogTonen', null);
                  await ophalenTarieven();
                }}
                onAnnuleren={() => setUrlStateSync('wijzigenDialoogTonen', null)}
                tarNomID={urlState.wijzigenDialoogTonen.tarNomID}
              />
            )}
            {urlState.productmodelInfoDialoogState !== null && (
              <ProductmodelInfoDialoog
                open
                prodModID={urlState.productmodelInfoDialoogState.prodModID}
                onSuccess={() => setUrlStateSync('productmodelInfoDialoogState', null)}
                onAnnuleren={() => setUrlStateSync('productmodelInfoDialoogState', null)}
              />
            )}
          </>
        }
      />
    </>
  );
};

export default withRouter(Tarieven);
