import React from 'react';
import ContractVisualisatie from '../../../../../entiteitVisualisaties/ContractVisualisatie';

interface IProps {
  data: { cntID: number };
}

const ContractInhoud = (props: IProps) => {
  return <ContractVisualisatie cntID={props.data.cntID} />;
};

export default ContractInhoud;
