import React, { useMemo } from 'react';
import Modal, { ModalProps } from 'react-bootstrap/Modal';
import Draggable from 'react-draggable';
import { ModalDialog } from 'react-bootstrap';
import { useSnelkoppeling } from '../../../one-off-components/SnelkoppelingAfhandelaar';
import { observer } from 'mobx-react-lite';

const DraggableModalDialog: React.FC = (props) => (
  <Draggable handle=".modal-header" cancel=".modal-title">
    <ModalDialog {...props} />
  </Draggable>
);

interface IProps {
  index: number;
  modalProps?: ModalProps;
  onBackdropClick?: (ev: React.MouseEvent<HTMLDivElement>) => void;
  onEscape?: () => void;
}

const Dialoog: React.FC<IProps> = observer((props) => {
  useSnelkoppeling(
    useMemo(
      () => ({
        activatietoets: 'escape',
        stuurtoetsen: {},
        handler: () => props.onEscape?.(),
      }),
      [props.onEscape],
    ),
  );

  return (
    <>
      <div
        className="fade modal-backdrop show"
        style={{ zIndex: 1000 + props.index * 2 }}
        onClick={(ev) => {
          ev.stopPropagation();
          if (props.onBackdropClick) {
            props.onBackdropClick(ev);
          }
        }}
      />
      <Modal
        dialogAs={DraggableModalDialog}
        show
        style={{ zIndex: 1000 + props.index * 2 + 1 }}
        backdrop={false}
        {...props.modalProps}
        enforceFocus={false}
        onClick={(ev: React.MouseEvent<HTMLDivElement>) => {
          ev.stopPropagation();
          // Sluiten van popovers rootclose
          document.body.click();
        }}
      >
        {props.children}
      </Modal>
    </>
  );
});

export default Dialoog;
