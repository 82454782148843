import React, { useMemo, useState } from 'react';
import MultiComboboxV2, {
  EnkeleProvider,
  IRepresentatieProps,
  Provider,
  IOverlayOptions,
} from '../MultiComboboxV2';
import { IOphalenRelatiesResultElementV2 } from '../../../../../shared/src/api/v2/relatie';
import api from '../../../api';
import { ASPKolom, EAspKolomBreedteType } from '../../tabel/ASPTabel/types';
import { formatteerRelatieNaam } from '../../../helpers';
import { Kleur } from '../../../bedrijfslogica/constanten';
import { IconZoeken } from '../../Icons';
import RelatieSelectieDialoog from '../../personalia/RelatieSelectieDialoog';

export enum ERelatieSelectieKolom {
  Naam,
  Relatienummer,
}

const enkeleProvider: EnkeleProvider<number, IOphalenRelatiesResultElementV2> = async (id) => {
  const result = await api.v2.relatie.ophalenRelaties({
    filterSchema: {
      filters: [
        {
          naam: 'IDS',
          data: [id],
        },
      ],
    },
  });

  return result.relaties[0];
};

const StandaardRepresentatieComponent: React.ComponentType<IRepresentatieProps<
  IOphalenRelatiesResultElementV2
>> = (props) => {
  return <span>{props.entiteit.weergavenaam}</span>;
};

interface ISelectieDialoogState {}

interface IProps<TOverlayContainerState> {
  relID: number | null;
  onChange: (relID: number | null) => void;
  provider: Provider<
    ERelatieSelectieKolom,
    IOphalenRelatiesResultElementV2,
    TOverlayContainerState
  >;
  overlayOptions?: IOverlayOptions<TOverlayContainerState, IOphalenRelatiesResultElementV2, number>;
  representatieComponent?: React.ComponentType<
    IRepresentatieProps<IOphalenRelatiesResultElementV2>
  >;
  disabled?: boolean;
  wisbaar?: boolean;
  dialoogIndex?: number;
}

const RelatieSelectieV2 = <TOverlayContainerState extends any>(
  props: IProps<TOverlayContainerState>,
) => {
  const [selectieDialoogState, setSelectieDialoogState] = useState<ISelectieDialoogState | null>(
    null,
  );

  const keyExtractor = useMemo(
    () => (row: IOphalenRelatiesResultElementV2) => row?.RelID ?? -1,
    [],
  );
  const representatieComponent = useMemo(
    () => props.representatieComponent ?? StandaardRepresentatieComponent,
    [props.representatieComponent],
  );
  const kolommen = useMemo<ASPKolom<ERelatieSelectieKolom, IOphalenRelatiesResultElementV2>[]>(
    () => [
      {
        key: ERelatieSelectieKolom.Naam,
        label: 'Naam',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 400,
        renderer: (row) => row.weergavenaam,
      },
      {
        key: ERelatieSelectieKolom.Relatienummer,
        label: 'Relatienummer',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 125,
        renderer: (row) => row.Relatienummer,
      },
    ],
    [],
  );

  return (
    <>
      <div className="d-flex">
        <MultiComboboxV2
          provider={props.provider}
          enkeleProvider={enkeleProvider}
          keyExtractor={keyExtractor}
          waarde={props.relID}
          onChange={props.onChange}
          representatieComponent={representatieComponent}
          kolommen={kolommen}
          disabled={props.disabled}
          wisbaar={props.wisbaar}
          overlayOptions={props.overlayOptions}
        />
        <button
          style={{
            // border: 0,
            background: 0,
            outline: 0,
            border: `1px solid ${Kleur.LichtGrijs}`,
            borderTopRightRadius: 5,
            borderBottomRightRadius: 5,
            borderLeft: 0,
            width: 25,
            backgroundColor: props.disabled ? 'rgb(197, 197, 197)' : Kleur.HeelLichtGrijs,
          }}
          className="flex-fill d-flex align-items-center justify-content-center"
          onClick={() => setSelectieDialoogState({})}
        >
          <IconZoeken
            style={{ fill: Kleur.Grijs, width: 16, height: 16, position: 'relative', top: 1 }}
            className="flex-fill"
          />
        </button>
      </div>

      {selectieDialoogState !== null && (
        <RelatieSelectieDialoog
          open
          onSuccess={(result) => {
            setSelectieDialoogState(null);

            props.onChange(result.relID);
          }}
          onAnnuleren={() => setSelectieDialoogState(null)}
          dialoogIndex={props.dialoogIndex}
        />
      )}
    </>
  );
};

export default RelatieSelectieV2;
