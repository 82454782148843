import * as React from 'react';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import IDialoogProps from '../../../../../../../core/IDialoogProps';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import api from '../../../../../../../api';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalHeader from 'react-bootstrap/ModalHeader';
import LoadingSpinner from '../../../../../../../components/Gedeeld/LoadingSpinner';
import { RootStoreContext } from '../../../../../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import { EResultType } from '../../../../../../../stores/CheckStore';
import Dialoog from '../../../../../../../components/dialogen/Dialoog';
import { Field, FieldProps, Formik } from 'formik';
import FormikVeldFout from '../../../../../../../components/formulier/FormikVeldFout';
import nameOf from '../../../../../../../core/nameOf';
import BedragInput from '../../../../../../../components/formulier/BedragInput';
import { IOphalenTariefActieAbonnementenResultElement } from '../../../../../../../../../shared/src/api/v2/aanbod/tarieven/acties';
import Combobox from '../../../../../../../components/formulier/Combobox';
import VinkVeld from '../../../../../../../components/formulier/VinkVeld';

interface IProps extends IDialoogProps {
  id: number;
}

const veldnamen = {
  eenmaligBedragVanToepassing: 'Actie op eenmalig bedrag',
  eenmaligBedrag: 'Eenmalig bedrag',
  maandhuur: 'Maandhuur',
  actieduur: 'Actieduur',
  kortingspercentage: 'Kortingspercentage',
};

interface IFormikValues {
  eenmaligBedragVanToepassing: boolean;
  eenmaligBedrag: number | null;
  maandhuur: number | null;
  actieduur: number | null;
  kortingspercentage: number | null;
}

const WijzigenDialoog: React.FC<IProps> = observer((props) => {
  const { open, onSuccess, onAnnuleren } = props;

  const { checkStore } = useContext(RootStoreContext);

  const [
    tariefactieAbonnement,
    setTariefactieAbonnement,
  ] = React.useState<IOphalenTariefActieAbonnementenResultElement | null>(null);
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

  const handleSubmit = useCallback(
    async (values: IFormikValues) => {
      setIsSubmitting(true);

      const params = {
        id: props.id,
        eenmaligBedrag: values.eenmaligBedragVanToepassing ? values.eenmaligBedrag : null,
        maandhuur:
          values.actieduur !== -1 && values.kortingspercentage === 0 ? values.maandhuur : null,
        actieduur: values.actieduur !== -1 ? values.actieduur : null,
        kortingspercentage:
          values.actieduur !== -1 && values.kortingspercentage !== 0
            ? values.kortingspercentage
            : null,
      };

      const checkData = await api.v2.aanbod.tarieven.acties.checkWijzigenActietarievenAbonnement(
        params,
      );
      const checkResultaat = await checkStore.controleren({ checkData });
      if (checkResultaat.type === EResultType.Annuleren) {
        setIsSubmitting(false);
        return;
      }

      await api.v2.aanbod.tarieven.acties.wijzigenActietarievenAbonnement(params);

      setIsSubmitting(false);
      props.onSuccess({});
    },
    [setIsSubmitting, props.onSuccess],
  );

  const handleAnnulerenClick = useCallback(() => {
    props.onAnnuleren();
  }, [props.onAnnuleren, setTariefactieAbonnement]);

  const initieleWaarden = useMemo<IFormikValues | null>(() => {
    if (tariefactieAbonnement === null) {
      return null;
    }

    return {
      eenmaligBedragVanToepassing: tariefactieAbonnement.EenmaligBedrag !== null,
      eenmaligBedrag:
        tariefactieAbonnement.EenmaligBedrag !== null ? tariefactieAbonnement.EenmaligBedrag : 0,
      maandhuur: tariefactieAbonnement.Maandhuur !== null ? tariefactieAbonnement.Maandhuur : 0,
      actieduur: tariefactieAbonnement.Actieduur !== null ? tariefactieAbonnement.Actieduur : -1,
      kortingspercentage:
        tariefactieAbonnement.Kortingspercentage !== null
          ? tariefactieAbonnement.Kortingspercentage
          : 0,
    };
  }, [tariefactieAbonnement]);

  useEffect(() => {
    if (props.id === null) {
      return;
    }

    const fn = async () => {
      const resultaat = await api.v2.aanbod.tarieven.acties.ophalenTariefActieAbonnementen({
        filterSchema: { filters: [{ naam: 'IDS', data: [props.id!] }] },
      });

      setTariefactieAbonnement(resultaat.tariefActieAbonnementen[0]);
    };

    fn();
  }, [props.id]);

  return (
    <Dialoog index={props.dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Wijzigen actietarieven op abonnementniveau</ModalTitle>
      </ModalHeader>
      {initieleWaarden === null ? (
        <LoadingSpinner />
      ) : (
        <Formik<IFormikValues>
          isInitialValid
          // validationSchema={validationSchema}
          onSubmit={handleSubmit}
          initialValues={initieleWaarden}
          render={(formikProps) => {
            const { submitForm, isSubmitting, values, isValid } = formikProps;

            return (
              <>
                <ModalBody>
                  {values === null ? (
                    <LoadingSpinner />
                  ) : (
                    <div className="form-group">
                      <div className="row">
                        <div className="col-12">
                          <label>{veldnamen.actieduur}</label>
                          <Field
                            name={nameOf<IFormikValues>('actieduur')}
                            render={(fieldProps: FieldProps<IFormikValues>) => {
                              const { field, form } = fieldProps;

                              const optiesActieduur = [
                                { id: -1, naam: 'Geen actie' },
                                { id: 0, naam: 'Looptijd' },
                                { id: 1, naam: '1 maand' },
                                { id: 2, naam: '2 maanden' },
                                { id: 3, naam: '3 maanden' },
                                { id: 4, naam: '4 maanden' },
                                { id: 5, naam: '5 maanden' },
                                { id: 6, naam: '6 maanden' },
                              ];

                              return (
                                <>
                                  <Combobox
                                    legeOptieTonen
                                    geselecteerd={field.value}
                                    onSelectieChange={(x) => form.setFieldValue(field.name, x)}
                                    opties={optiesActieduur.map((x) => {
                                      return {
                                        id: x.id,
                                        label: x.naam,
                                      };
                                    })}
                                  />
                                  <FormikVeldFout fieldProps={fieldProps} />
                                </>
                              );
                            }}
                          />
                        </div>

                        <div className="col-12 mt-3">
                          <label>{veldnamen.kortingspercentage}</label>
                          <Field
                            name={nameOf<IFormikValues>('kortingspercentage')}
                            render={(x: FieldProps<IFormikValues>) => (
                              <>
                                <input
                                  type="number"
                                  className="form-control"
                                  {...x.field}
                                  min={0}
                                />
                                <FormikVeldFout fieldProps={x} />
                              </>
                            )}
                          />
                        </div>

                        {values.kortingspercentage === 0 && (
                          <div className="col-12 mt-3">
                            <label>{veldnamen.maandhuur}</label>
                            <Field
                              disabled={values.actieduur === -1}
                              name={nameOf<IFormikValues>('maandhuur')}
                              render={(fieldProps: FieldProps<IFormikValues>) => {
                                const { field, form } = fieldProps;

                                return (
                                  <>
                                    <div>
                                      <BedragInput
                                        value={field.value}
                                        onChange={(x) => {
                                          form.setFieldValue(field.name, x);
                                        }}
                                      />
                                    </div>
                                    <FormikVeldFout fieldProps={fieldProps} />
                                  </>
                                );
                              }}
                            />
                          </div>
                        )}

                        <div className="col-12 mt-4">
                          <Field
                            name={nameOf<IFormikValues>('eenmaligBedragVanToepassing')}
                            render={(fieldProps: FieldProps<IFormikValues>) => {
                              const { field, form } = fieldProps;

                              return (
                                <>
                                  <div className="d-flex align-items-center">
                                    <VinkVeld
                                      aangevinkt={field.value}
                                      onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                    />
                                    <span className="ml-2">
                                      {veldnamen.eenmaligBedragVanToepassing}
                                    </span>
                                  </div>
                                  <FormikVeldFout fieldProps={fieldProps} />
                                </>
                              );
                            }}
                          />
                        </div>

                        {values.eenmaligBedragVanToepassing && (
                          <div className="col-12 mt-3">
                            <label>{veldnamen.eenmaligBedrag}</label>
                            <Field
                              disabled
                              name={nameOf<IFormikValues>('eenmaligBedrag')}
                              render={(fieldProps: FieldProps<IFormikValues>) => {
                                const { field, form } = fieldProps;

                                return (
                                  <>
                                    <div>
                                      <BedragInput
                                        value={field.value}
                                        onChange={(x) => {
                                          form.setFieldValue(field.name, x);
                                        }}
                                      />
                                    </div>
                                    <FormikVeldFout fieldProps={fieldProps} />
                                  </>
                                );
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </ModalBody>
                <ModalFooter className="d-flex flex-row justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    style={{ width: 100 }}
                    disabled={isSubmitting || !isValid || values === null}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={onAnnuleren}
                    style={{ width: 100 }}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Dialoog>
  );
});

export default WijzigenDialoog;
