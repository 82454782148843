import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  IOphalenUitnodigingAanleidingenResultElement,
  IOphalenVoorstelUitnodigingenResultElement,
} from '../../../../../shared/src/api/v2/review/klant';
import MenuLayout from '../../../components/MenuLayout';
import {
  DXTableCheckboxComponent,
  DXTableToggleCellComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../helpers/dxTableGrid';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  DataTypeProvider,
  IntegratedSorting,
  RowDetailState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import LoadingSpinner from '../../../components/Gedeeld/LoadingSpinner';
import api from '../../../api';
import useUrlState from '../../../core/useUrlState';
import _ from 'lodash';
import {
  IconBetaling,
  IconInformatie,
  IconKruis,
  IconVerwijderen,
  IconVink,
} from '../../../components/Icons';
import { Kleur } from '../../../bedrijfslogica/constanten';
import { RootStoreContext } from '../../../stores/RootStore';
import { EResultType } from '../../../stores/CheckStore';
import PersoonVisualisatie from '../../../components/personalia/PersoonVisualisatie';
import { EReviewUitnodigingAanleiding } from '../../../bedrijfslogica/enums';
import RelatieVisualisatie from '../../../components/personalia/RelatieVisualisatie';
import SelectieVak from '../../../components/SelectieVak';
import { format } from 'date-fns';
import { IOphalenRelatiesResultElementV2 } from '../../../../../shared/src/api/v2/relatie';
import { IFilterSchema } from '../../../../../shared/src/models/filter';
import FilterBalkV2, {
  IFilter,
  IFilterData,
  maakFilterSchema,
} from '../../../components/FilterBalkV2';
import Combobox from '../../../components/formulier/Combobox';
import nameOf from '../../../core/nameOf';
import RelatieInfoDialoog from '../../../components/relatie/MutatieTabel/RelatieInfoDialoog';
import UitlegTooltip from '../../../components/formulier/UitlegTooltip';
import { Helmet } from 'react-helmet';

interface IProps extends RouteComponentProps {}

enum EFilter {
  Aanleidingen = 'AANLEIDINGEN',
  Handmatig = 'HANDMATIG',
}

export interface IRelatieInfoDialoogState {
  id: number;
}

interface IUrlState {
  selectie: number[];
  filterData: IFilterData<EFilter>[];
  relatieInfoDialoogState: IRelatieInfoDialoogState | null;
}

const defaultUrlState: IUrlState = {
  selectie: [],
  filterData: [
    {
      naam: EFilter.Aanleidingen,
      isActief: false,
      data: [5], // TODO Dynamisch maken (is nu een ID)
    },
    {
      naam: EFilter.Handmatig,
      isActief: false,
      data: true,
    },
  ],
  relatieInfoDialoogState: null,
};

export interface IRegel extends IOphalenVoorstelUitnodigingenResultElement {
  relatie: IOphalenRelatiesResultElementV2 | null;
}

const Voorstel: React.FC<IProps> = (props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const [filterSchema, setFilterSchema] = useState<IFilterSchema | null>(
    urlState.filterData === null ? null : maakFilterSchema(urlState.filterData),
  );

  const [aanleidingen, setAanleidingen] = useState<
    IOphalenUitnodigingAanleidingenResultElement[] | null
  >(null);

  const ophalenAanleidingen = useCallback(async () => {
    const aanleidingen = (await api.v2.review.ophalenUitnodigingAanleidingen({})).aanleidingen;

    setAanleidingen(aanleidingen);
  }, []);

  useEffect(() => {
    ophalenAanleidingen();
  }, [ophalenAanleidingen]);

  const { checkStore } = useContext(RootStoreContext);

  const [uitnodigingen, setUitnodigingen] = useState<IRegel[] | null>(null);

  const ophalenUitnodigingen = useCallback(async () => {
    if (filterSchema === null) {
      return null;
    }

    const uitnodigingen = (
      await api.v2.review.ophalenVoorstelUitnodigingen({
        filterSchema: {
          filters: [
            ...filterSchema.filters!,
            { naam: 'STATUSSEN', data: [0] },
            { naam: 'UITNODIGEN', data: true },
          ],
        },
      })
    ).uitnodigingen;

    const relIDs = _.uniq(uitnodigingen.map((x) => x.RelID));
    const relatiesResult = (
      await api.v2.relatie.ophalenRelaties({
        filterSchema: {
          filters: [
            {
              naam: 'IDS',
              data: relIDs,
            },
          ],
        },
      })
    ).relaties;

    const uitnodigingenMetRelatie = uitnodigingen.map((x) => {
      return { ...x, relatie: relatiesResult.find((r) => r.RelID === x.RelID) || null };
    });

    const uitnodigingenGesorteerd = _.orderBy(
      uitnodigingenMetRelatie,
      [
        (x: IOphalenVoorstelUitnodigingenResultElement) => {
          return x.DatumAanleiding;
        },
        (x: IOphalenVoorstelUitnodigingenResultElement) => {
          return x.aanleiding !== null ? x.aanleiding.SortNr : 0;
        },
      ],
      ['asc', 'asc'],
    );

    setUitnodigingen(uitnodigingenGesorteerd);
  }, [filterSchema]);

  useEffect(() => {
    ophalenUitnodigingen();
  }, [ophalenUitnodigingen]);

  const handleVerwijderen = useCallback(async () => {
    // const checkData = await api.v2.review.checkVerwijderenBeoordelingen({
    //   ids: urlState.selectie,
    // });
    // const checkResult = await checkStore.controleren({
    //   checkData,
    // });
    // if (checkResult.type === EResultType.Annuleren) {
    //   return;
    // }

    if (
      (
        await checkStore.bevestigen({
          inhoud: 'Uitnodigingen verwijderen?',
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    await api.v2.review.verwijderenVoorstelUitnodigingen({
      ids: urlState.selectie,
    });

    setUrlStateSync('selectie', []);

    ophalenUitnodigingen();
  }, [urlState.selectie]);

  const keyExtractor = useCallback((row: IOphalenVoorstelUitnodigingenResultElement) => row.ID, []);

  const kolommen = useMemo<TypedColumn<IOphalenVoorstelUitnodigingenResultElement>[]>(
    () => [
      {
        name: '__relatie' as any,
        title: 'Relatie',
      },
      {
        name: '__persoon' as any,
        title: 'Persoon',
        // getCellValue: (x) => {
        //   return x.
        // },
      },
      {
        name: '__isRelatie' as any,
        title: 'Is relatie',
      },
      {
        name: '__relatieInfo' as any,
        title: ' ',
      },
      {
        name: '__aanleiding' as any,
        title: 'Aanleiding',
        getCellValue: (x) => (x.aanleiding !== null ? x.aanleiding.Naam : ''),
      },
      {
        name: '__datum' as any,
        title: 'Datum',
        getCellValue: (x) => (x.DatumAanleiding !== null ? x.DatumAanleiding : ''),
      },
      {
        name: 'Handmatig',
        title: 'Hand.',
      },
      {
        name: '__notities' as any,
        title: 'Notities',
      },
      {
        name: '__voorsteldatum' as any,
        title: 'Datum voorstel',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<
    TypedTableColumnWidthInfo<IOphalenVoorstelUitnodigingenResultElement>[]
  >(
    () => [
      {
        columnName: '__persoon' as any,
        width: 250,
      },
      {
        columnName: '__relatieInfo' as any,
        width: 65,
      },
      {
        columnName: '__relatie' as any,
        width: 250,
      },
      {
        columnName: '__isRelatie' as any,
        width: 110,
      },
      {
        columnName: '__aanleiding' as any,
        width: 125,
      },
      {
        columnName: 'Handmatig',
        width: 80,
      },
      {
        columnName: '__datum' as any,
        width: 125,
      },
      {
        columnName: '__notities' as any,
        width: 550,
      },
      {
        columnName: '__voorsteldatum' as any,
        width: 135,
      },
    ],
    [],
  );

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.Aanleidingen,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          if (aanleidingen === null) {
            return null;
          }
          const data: number[] = weergaveProps.data;

          return (
            <div className="d-flex align-items-center">
              <span className="mr-2">Aanleiding</span>
              <Combobox
                geselecteerd={data.length === 0 ? null : data[0]}
                onSelectieChange={(x) => {
                  const newData = x === null ? [] : [x];
                  weergaveProps.onDataChange(newData);
                  weergaveProps.setIsActief(true);
                  weergaveProps.toepassen();
                }}
                opties={aanleidingen.map((x) => {
                  return { id: x.ID, label: x.Naam! };
                })}
              />
            </div>
          );
        },
      },
      {
        naam: EFilter.Handmatig,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return <span>Handmatig toegevoegd</span>;
        },
      },
    ],
    [aanleidingen],
  );

  if (uitnodigingen === null) {
    return (
      <div className="flex-fill d-flex align-items-center justify-content-center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Review Voorstel</title>
      </Helmet>
      <MenuLayout
        menu={
          <>
            <div className="d-flex flex-column">
              <div className="d-flex align-items-center">
                <button
                  className="btn btn-sm btn-light d-flex align-items-center ml-2"
                  style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                  onClick={async () => {
                    if (
                      (
                        await checkStore.bevestigen({
                          inhoud: `Voorstel toevoegen?`,
                        })
                      ).type === EResultType.Annuleren
                    ) {
                      return;
                    }

                    await api.v2.review.makenVoorstelUitnodigingen({
                      aanleidingen: [
                        EReviewUitnodigingAanleiding.Bestelling,
                        EReviewUitnodigingAanleiding.Omruiling,
                        EReviewUitnodigingAanleiding.Servicebezoek,
                        EReviewUitnodigingAanleiding.GeenKlantMeer,
                      ],
                    });

                    await ophalenUitnodigingen();
                  }}
                >
                  {/* <IconVoorstel style={{ width: 16, height: 16, fill: Kleur.Grijs }} /> */}
                  <span className="ml-2">Toevoegen voorstel</span>
                </button>
                <button
                  className="btn btn-sm btn-light d-flex align-items-center ml-2"
                  style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                  disabled={urlState.selectie.length === 0}
                  onClick={async () => handleVerwijderen()}
                >
                  <IconVerwijderen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                  <span className="ml-2">Verwijderen</span>
                </button>
                <button
                  className="btn btn-sm btn-light d-flex align-items-center ml-2"
                  style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                  disabled={urlState.selectie.length === 0}
                  onClick={async () => {
                    if (
                      (
                        await checkStore.bevestigen({
                          inhoud: (
                            <span>
                              Geselecteerde voorstellen afwijzen?
                              <br />
                              <br />
                              Er zal hiervoor dan geen nieuw voorstel meer worden gemaakt. Als dat
                              niet de bedoeling is dien je te kiezen voor Verwijderen i.p.v.
                              Afwijzen.
                            </span>
                          ),
                        })
                      ).type === EResultType.Annuleren
                    ) {
                      return;
                    }

                    await api.v2.review.afwijzenVoorstelUitnodigingen({ ids: urlState.selectie });
                    ophalenUitnodigingen();
                    setUrlStateSync('selectie', []);
                  }}
                >
                  <IconKruis style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                  <span className="ml-2">Afwijzen</span>
                </button>
                <button
                  className="btn btn-sm btn-light d-flex align-items-center ml-2"
                  style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                  disabled={urlState.selectie.length === 0}
                  onClick={async () => {
                    if (
                      (
                        await checkStore.bevestigen({
                          inhoud: `Geselecteerde voorstellen klaarzetten voor uitnodigen?`,
                        })
                      ).type === EResultType.Annuleren
                    ) {
                      return;
                    }

                    await api.v2.review.goedkeurenVoorstelUitnodigingen({ ids: urlState.selectie });
                    ophalenUitnodigingen();
                    setUrlStateSync('selectie', []);
                  }}
                >
                  <IconVink style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                  <span className="ml-2">Goedkeuren</span>
                </button>
                <div className="d-flex flex-fill ml-3">
                  <FilterBalkV2
                    filters={filters}
                    filterData={urlState.filterData}
                    onFilterDataChange={(x) => setUrlStateSync('filterData', x)}
                    onFilterSchemaChange={(x) => setFilterSchema(x)}
                  />
                </div>
              </div>
            </div>
            <div className="mt-3">
              <SelectieVak
                aantal={urlState.selectie.length}
                totaalAantal={uitnodigingen.length}
                onChange={(alles) => {
                  if (alles) {
                    setUrlStateSync(
                      'selectie',
                      uitnodigingen.map((x) => x.ID),
                    );
                  } else {
                    setUrlStateSync('selectie', []);
                  }
                }}
              />
            </div>
          </>
        }
        body={
          <GridStyleWrapper rowAmount={uitnodigingen.length} height={'calc(100vh - 150px)'}>
            <Grid rows={uitnodigingen} columns={kolommen} getRowId={keyExtractor}>
              <DataTypeProvider
                for={['__persoon']}
                formatterComponent={(formatterProps) => {
                  const rij: IRegel = formatterProps.row;
                  if (rij.PersID === null) {
                    return <span></span>;
                  }
                  return <PersoonVisualisatie persID={rij.PersID} />;
                }}
              />

              <DataTypeProvider
                for={['__relatie']}
                formatterComponent={(formatterProps) => {
                  const rij = formatterProps.row as IRegel;

                  if (rij.relatie === null) {
                    return <span></span>;
                  }

                  // Primaire persoon
                  const persID = rij.relatie.persoon!.PersID;
                  if (rij.PersID !== persID) {
                    return <RelatieVisualisatie relID={rij.RelID} />;
                  }

                  // return <span></span>;

                  return <RelatieVisualisatie relID={rij.RelID} />;
                }}
              />

              <DataTypeProvider
                for={['__aanleiding']}
                formatterComponent={(formatterProps) => {
                  const rij = formatterProps.row as IOphalenVoorstelUitnodigingenResultElement;
                  return <span>{rij.aanleiding?.Naam}</span>;
                }}
              />

              <DataTypeProvider
                for={['__isRelatie']}
                formatterComponent={(formatterProps) => {
                  const rij = formatterProps.row as IRegel;

                  if (rij.relatie?.PersoonPrimair_PersID !== rij.PersID) {
                    return <span>Nee</span>;
                  }

                  return <span></span>;
                }}
              />

              <DataTypeProvider
                for={[nameOf<IRegel>('Handmatig')]}
                formatterComponent={(formatterProps) => {
                  const rij = formatterProps.row as IOphalenVoorstelUitnodigingenResultElement;
                  return <span>{rij.Handmatig ? 'Ja' : ''}</span>;
                }}
              />

              <DataTypeProvider
                for={['__datum']}
                formatterComponent={(props) => {
                  const rij: IRegel = props.row;
                  if (rij.DatumAanleiding === null) {
                    return <span></span>;
                  }
                  return <span>{format(new Date(rij.DatumAanleiding), 'dd-MM-yyyy')}</span>;
                }}
              />

              <DataTypeProvider
                for={['__notities']}
                formatterComponent={(formatterProps) => {
                  const rij: IRegel = formatterProps.row;

                  const notities =
                    rij.relatie === null || rij.relatie.Notities === null ? (
                      ''
                    ) : (
                      <div
                        style={{
                          maxHeight: 400,
                          overflowY: 'hidden',
                        }}
                      >
                        {rij.relatie.Notities.split('\n').map((part) => (
                          <>
                            <span>{part}</span>
                            <br />
                          </>
                        ))}
                      </div>
                    );

                  return (
                    <UitlegTooltip inhoud={notities}>
                      <span>{rij.relatie !== null ? rij.relatie.Notities : ''}</span>
                    </UitlegTooltip>
                  );
                }}
              />

              <DataTypeProvider
                for={['__relatieInfo']}
                formatterComponent={(formatterProps) => {
                  const rij: IRegel = formatterProps.row;

                  return (
                    <div className="mb-1">
                      <a
                        href="#"
                        // style={{ color: Kleur.DonkerGrijs }}
                        onClick={() => {
                          setUrlStateSync('relatieInfoDialoogState', { id: rij.RelID });
                        }}
                      >
                        <IconInformatie style={{ width: 15, height: 15, fill: Kleur.Blauw }} />
                      </a>
                    </div>
                  );
                }}
              />

              <DataTypeProvider
                for={['__voorsteldatum']}
                formatterComponent={(formatterProps) => {
                  const rij: IRegel = formatterProps.row;
                  return <span>{format(new Date(rij.Voorsteldatum), 'dd-MM-yyyy HH:mm')}</span>;
                }}
              />

              <RowDetailState defaultExpandedRowIds={[]} />

              <SortingState defaultSorting={[]} />
              <IntegratedSorting />

              <VirtualTable messages={{ noData: 'Geen voorstel uitnodigingen' }} />
              <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
              <TableHeaderRow showSortingControls />

              <SelectionState
                selection={urlState.selectie}
                onSelectionChange={(x) => setUrlStateSync('selectie', x as number[])}
              />
              <TableSelection cellComponent={DXTableCheckboxComponent} />
            </Grid>
          </GridStyleWrapper>
        }
      />

      {urlState.relatieInfoDialoogState !== null && (
        <RelatieInfoDialoog
          open
          relID={urlState.relatieInfoDialoogState.id}
          onSuccess={() => setUrlStateSync('relatieInfoDialoogState', null)}
          onAnnuleren={() => setUrlStateSync('relatieInfoDialoogState', null)}
        />
      )}
    </>
  );
};

export default withRouter(Voorstel);
