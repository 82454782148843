import { RootStore } from './RootStore';
import { makeObservable, observable } from 'mobx';
import {
  IOphalenBatchesResultElement,
  IOphalenOpdrachtenResultElement,
} from '../../../shared/src/api/v2/bank/opdracht/opdracht';
import IRemoteData, { createPendingRemoteData, createReadyRemoteData } from '../models/IRemoteData';
import api from '../api';

class BankOpdrachtenStore {
  public opdrachten: Record<number, IRemoteData<IOphalenOpdrachtenResultElement>> = {};
  public batches: Record<number, IRemoteData<IOphalenBatchesResultElement>> = {};
  // tslint:disable-next-line:variable-name
  private _rootStore: RootStore;
  private opdrachtIDsOphalenQueue: Set<number> = new Set();
  private opdrachtOphalenTimeout: NodeJS.Timeout | null = null;

  constructor(rootStore: RootStore) {
    this._rootStore = rootStore;

    makeObservable(this, {
      opdrachten: observable,
      batches: observable,
    });
  }

  public enqueueOphalenOpdrachten = async (ids: number[]) => {
    if (ids.length === 0) {
      return;
    }

    const opdrachtenCopy = { ...this.opdrachten };

    for (const id of ids) {
      // Als de opdracht al in de store zit, hoeven we hem niet opnieuw op te halen
      if (this.opdrachten[id] !== undefined) {
        continue;
      }
      opdrachtenCopy[id] = createPendingRemoteData();

      this.opdrachtIDsOphalenQueue.add(id);
    }

    this.opdrachten = opdrachtenCopy;

    if (this.opdrachtOphalenTimeout !== null) {
      clearTimeout(this.opdrachtOphalenTimeout);
    }
    this.opdrachtOphalenTimeout = setTimeout(this.ophalenOpdrachtenMiddelsQueue, 50);
  };

  private ophalenOpdrachtenMiddelsQueue = async () => {
    const ids = Array.from(this.opdrachtIDsOphalenQueue);
    this.opdrachtIDsOphalenQueue.clear();

    const result = await api.v2.bank.opdracht.ophalenOpdrachten({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: ids,
          },
        ],
      },
    });

    const opdrachtenCopy = { ...this.opdrachten };

    for (const opdracht of result.opdrachten) {
      opdrachtenCopy[opdracht.BankOpdID] = createReadyRemoteData(opdracht);
    }

    this.opdrachten = opdrachtenCopy;
  };
}

export default BankOpdrachtenStore;
