import styled from 'styled-components';
import { Kleur } from '../../../bedrijfslogica/constanten';

interface IRootProps {
  isInkomend: boolean;
}

export const Wrapper = styled.div`
  width: 100%;
  //flex: 1;
`;

export const Root = styled.div<IRootProps>`
  max-width: 60%;
  background-color: ${(props) => (props.isInkomend ? '#fff' : '#fff')};
  // color: ${(props) => (props.isInkomend ? 'whitesmoke' : 'inherit')};
  color: rgb(38,38,38);
  float: ${(props) => (props.isInkomend ? 'left' : 'right')};
  clear: both;
  padding: 3px 10px;
  width: fit-content;
  border-radius: 7.5px;
  box-shadow: 0 1px 0.5px rgba(0, 0, 0, 0.13);
  display: flex;
  align-items: flex-end;
  flex: 1;
  height: 100%;
`;

export const BerichtInhoud = styled.p`
  margin: 0;
  padding: 5px 0;
  white-space: pre-wrap;
  word-wrap: break-word;
  min-height: 38px;
  display: flex;
  align-items: center;
`;
export const BerichtTijd = styled.p`
  margin: 0 0 0 5px;
  padding: 0;
  font-size: 11px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  min-width: 65px;
  color: ${Kleur.Grijs};
`;
