import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import IDialoogProps from '../../../../core/IDialoogProps';
import Dialoog from '../../../../components/dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import * as Yup from 'yup';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../../stores/RootStore';
import nameof from '../../../../core/nameOf';
import api from '../../../../api';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import { EResultType } from '../../../../stores/CheckStore';
import { IOphalenAfbeeldingenResultElement } from '../../../../../../shared/src/api/v2/website/afbeelding';
import ITaalTekst from '../../../../../../shared/src/models/talen/ITaalTekst';
import MeertaligTekstveld from '../../../../components/formulier/MeertaligTekstveld';
import {
  IOphalenWebtekstenResultElement,
  IWijzigenWebtekstParams,
} from '../../../../../../shared/src/api/v2/webtekst';
import { IOphalenTekstenInAlleTalenResult } from '../../../../../../shared/src/api/v2/tekst';
import IRemoteData, {
  ERemoteDataState,
  createPendingRemoteData,
  createReadyRemoteData,
} from '../../../../models/IRemoteData';

interface IDialoogResult {}

interface IProps extends IDialoogProps<IDialoogResult> {
  id: number;
}

interface IFormikValues {
  naamEnum: string;
  tekst: ITaalTekst[];
}

const veldnamen: Record<keyof IFormikValues, string> = {
  naamEnum: 'NaamEnum',
  tekst: 'Tekst',
};

const WijzigenDialoog: React.FC<IProps> = observer((props) => {
  const { children, dialoogIndex, onAnnuleren, onSuccess } = props;
  const { checkStore } = useContext(RootStoreContext);
  const formikRef = useRef<Formik<IFormikValues>>(null);

  const [webtekst, setWebtekst] = useState<IOphalenWebtekstenResultElement | null>(null);

  const ophalenwebtekst = useCallback(async () => {
    console.log('ophalen');
    const result = await api.v2.webtekst.ophalenWebteksten({
      filterSchema: { filters: [{ naam: 'IDS', data: [props.id] }] },
    });
    console.log(result.webteksten[0]);

    setWebtekst(result.webteksten[0]);
  }, [props.id]);

  useEffect(() => {
    ophalenwebtekst();
  }, [ophalenwebtekst]);

  const [teksten, setTeksten] = useState<IRemoteData<IOphalenTekstenInAlleTalenResult>>(
    createPendingRemoteData(),
  );

  const ophalenTeksten = useCallback(async () => {
    if (webtekst === null) {
      if (teksten.state === ERemoteDataState.Ready) {
        setTeksten(createPendingRemoteData());
      }
      return;
    }

    const tekstIDs = [webtekst.Tekst_TekstID].filter((x) => x !== null) as number[];

    const result = await api.v2.tekst.ophalenTekstenInAlleTalen({
      tekstIDs,
    });
    setTeksten(createReadyRemoteData(result));
  }, [webtekst]);
  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    ophalenTeksten();
  }, [ophalenTeksten]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      if (webtekst === null) {
        return;
      }

      actions.setSubmitting(true);

      const params: IWijzigenWebtekstParams = {
        WebTekstID: webtekst.WebTekstID,
        NaamEnum: values.naamEnum,
        Tekst: {
          tekstID: webtekst.Tekst_TekstID,
          taalTeksten: values.tekst,
        },
      };

      const checkData = await api.v2.extern.webtekst.checkWijzigenWebtekst(params);
      const checkResult = await checkStore.controleren({
        checkData,
      });
      if (checkResult.type === EResultType.Annuleren) {
        onAnnuleren();
        return;
      }

      await api.v2.extern.webtekst.wijzigenWebtekst(params);

      actions.setSubmitting(false);
      props.onSuccess({});
    },
    [webtekst],
  );

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (webtekst === null || teksten.state === ERemoteDataState.Pending) {
      return null;
    }
    const tesktTeksten =
      webtekst.Tekst_TekstID === null
        ? null
        : teksten.data!.teksten.filter((x) => x.TekstID === webtekst.Tekst_TekstID);

    return {
      naamEnum: webtekst.NaamEnum,
      tekst:
        tesktTeksten === null
          ? []
          : tesktTeksten.map((x) => ({
              tekst: x.Tekst ?? '',
              taalID: x.TaalID,
              toepassen: x.Toepassen,
            })),
    };
  }, [webtekst, teksten]);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        naamEnum: Yup.string().required(),
      }),
    [],
  );

  return (
    <Dialoog index={dialoogIndex || 0} modalProps={{ size: 'lg' }}>
      <ModalHeader>
        <ModalTitle>Wijzigen webtekst</ModalTitle>
      </ModalHeader>
      {initialValues === null ? (
        <LoadingSpinner />
      ) : (
        <Formik<IFormikValues>
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize
          isInitialValid
          render={(formikProps: FormikProps<IFormikValues>) => {
            const { submitForm, isValid, isSubmitting } = formikProps;

            return (
              <>
                <ModalBody>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-12 mt-3">
                        <label>NaamEnum</label>
                        <Field
                          name={nameof<IFormikValues>('naamEnum')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field } = fieldProps;

                            return (
                              <>
                                <input type="text" className="form-control" {...field} />
                                {/* <FormikVeldFout fieldProps={fieldProps} /> */}
                              </>
                            );
                          }}
                        />
                      </div>
                      <div className="col-12 mt-3">
                        <label>Tekst</label>
                        <Field
                          name={nameof<IFormikValues>('tekst')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            return (
                              <MeertaligTekstveld
                                waarden={fieldProps.field.value}
                                onChange={(x) =>
                                  fieldProps.form.setFieldValue(fieldProps.field.name, x)
                                }
                                defaultUitgeklapt
                                inputComponent={'multiline'}
                                multilineConfig={{ rows: 3 }}
                              />
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </ModalBody>

                <ModalFooter className="d-flex flex-row justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    style={{ width: 100 }}
                    disabled={!isValid || isSubmitting}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={onAnnuleren}
                    style={{ width: 100 }}
                    disabled={isSubmitting}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Dialoog>
  );
});

export default WijzigenDialoog;
