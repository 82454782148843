import api from '../../../index';
import {
  IOphalenAfhaalOpdrachtenParams,
  IOphalenAfhaalOpdrachtenResult,
  IToevoegenAfhaalOpdrachtenParams,
  IToevoegenAfhaalOpdrachtenResult,
  IVerwijderenAfhaalOpdrachtenParams,
  IVerwijderenAfhaalOpdrachtenResult,
  IBijwerkenAfhaalopdrachtenParams,
  IBijwerkenAfhaalopdrachtenResult,
  IVersturenAfhaalOpdrachtenParams,
  IVersturenAfhaalOpdrachtenResult,
  IGenererenAfhaalOpdrachtenParams,
  IGenererenAfhaalOpdrachtenResult,
} from '../../../../../../shared/src/api/v2/magazijn/afhaal';
import ICheckData from '../../../../../../shared/src/models/ICheckData';

const afhaal = {
  ophalenAfhaalOpdrachten: async (
    params: IOphalenAfhaalOpdrachtenParams,
  ): Promise<IOphalenAfhaalOpdrachtenResult> => {
    return await api.post('v2/magazijn/afhaal/ophalen-afhaal-opdrachten', params);
  },
  toevoegenAfhaalOpdrachten: async (
    params: IToevoegenAfhaalOpdrachtenParams,
  ): Promise<IToevoegenAfhaalOpdrachtenResult> => {
    return await api.post('v2/magazijn/afhaal/toevoegen-afhaal-opdrachten', params);
  },
  checkBijwerkenAfhaalopdracht: async (
    params: IBijwerkenAfhaalopdrachtenParams,
  ): Promise<ICheckData> => {
    return await api.post('v2/magazijn/afhaal/check-bijwerken-afhaalopdracht', params);
  },
  bijwerkenAfhaalopdracht: async (
    params: IBijwerkenAfhaalopdrachtenParams,
  ): Promise<IBijwerkenAfhaalopdrachtenResult> => {
    return await api.post('v2/magazijn/afhaal/bijwerken-afhaalopdracht', params);
  },
  checkVerwijderenAfhaalOpdrachten: async (
    params: IVerwijderenAfhaalOpdrachtenParams,
  ): Promise<ICheckData> => {
    return await api.post('v2/magazijn/afhaal/check-verwijderen-afhaal-opdrachten', params);
  },
  verwijderenAfhaalOpdrachten: async (
    params: IVerwijderenAfhaalOpdrachtenParams,
  ): Promise<IVerwijderenAfhaalOpdrachtenResult> => {
    return await api.post('v2/magazijn/afhaal/verwijderen-afhaal-opdrachten', params);
  },
  checkVersturenAfhaalOpdrachten: async (
    params: IVersturenAfhaalOpdrachtenParams,
  ): Promise<ICheckData> => {
    return await api.post('v2/magazijn/afhaal/check-versturen-afhaal-opdrachten', params);
  },
  versturenAfhaalOpdrachten: async (
    params: IVersturenAfhaalOpdrachtenParams,
  ): Promise<IVersturenAfhaalOpdrachtenResult> => {
    return await api.post('v2/magazijn/afhaal/versturen-afhaal-opdrachten', params);
  },
  genererenAfhaalOpdrachten: async (
    params: IGenererenAfhaalOpdrachtenParams,
  ): Promise<IGenererenAfhaalOpdrachtenResult> => {
    return await api.post('v2/magazijn/afhaal/genereren-afhaal-opdrachten', params);
  },
};

export default afhaal;
