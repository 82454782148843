import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ERunTabelV2Kolom, RunTabelV2Context } from '..';
import {
  IProlongatieContract,
  IProlongatieContractAfgeleideData,
  IProlongatieRunV2,
  IVerwijderenProlongatiesParams,
} from '../../../../../../shared/src/api/v2/prolongatie';
import { IUitgeklapteRijProps } from '../../../tabel/ASPTabel/Body/UitgeklapteRij';
import TabelInspringBlok from '../../../layout/TabelInspringBlok';
import ASPTabel from '../../../tabel/ASPTabel';
import {
  ASPKolom,
  EAspKolomBreedteType,
  ESortering,
  ESorteringModus,
  IAspKolomSorteringItem,
} from '../../../tabel/ASPTabel/types';
import FormatteerBedrag from '../../../MutatieBedrag';
import { Kleur as EKleur, Kleur } from '../../../../bedrijfslogica/constanten';
import ContractVisualisatie from '../../../entiteitVisualisaties/ContractVisualisatie';
import RelatieVisualisatie from '../../../personalia/RelatieVisualisatie';
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  format,
} from 'date-fns';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../models/IRemoteData';
import api, { IPaginatiePositie } from '../../../../api';
import LoadingSpinner from '../../../Gedeeld/LoadingSpinner';
import MenuLayout from '../../../MenuLayout';
import { IconPijlVolgend, IconVerwijderen } from '../../../Icons';
import { EResultType } from '../../../../stores/CheckStore';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../../stores/RootStore';
import { GlobaleRendererContext } from '../../../../one-off-components/GlobaleRenderer';
import { IFilterSchema } from '../../../../../../shared/src/models/filter';
import FilterBalkV2, { IFilter, IFilterData, maakFilterSchema } from '../../../FilterBalkV2';
import ContractDetail from './ContractDetail';
import GegevensLayout from '../../../layout/GegevensLayout';
import { formatteerBoolean } from '../../../../helpers';
import Skeleton from 'react-loading-skeleton';
import { EStoplichtStatus, StoplichtIndicatie } from '../../../formulier/StoplichtIndicatie';
import HeeftSignaleringenFilter from './HeeftSignaleringenFilter';
import { IOrderSchemaOrder } from '../../../../../../shared/src/models/order';
import { IVerwerkenProlongatiesParams } from '../../../../../../shared/src/api/v2/prolongatieV2';
import VerwerkenRunDialoog from '../VerwerkenRunDialoog';

interface IData {
  contracten: Record<number, IProlongatieContract>;
  afgeleideDatas: Record<number, IProlongatieContractAfgeleideData>;
  totaalAantal: number;
}

export interface IProlongatieContractenDataProviderParams {
  run: IProlongatieRunV2;
  paginatie: IPaginatiePositie;
  filterSchema?: IFilterSchema;
  sortering: IAspKolomSorteringItem<EProlongatieContractKolom>[];
  currentData: IData | null;
}

export interface IProlongatieContractenDataProviderOutput {
  data: IData;
}

export type ProlongatieContractenDataProvider = (
  params: IProlongatieContractenDataProviderParams,
) => Promise<IProlongatieContractenDataProviderOutput>;

export interface IProlongatieContractContext {
  data: IData;
}
export const ProlongatieContractContext = React.createContext<IProlongatieContractContext>(null!);

const standaardDataProvider = async (
  params: IProlongatieContractenDataProviderParams,
): Promise<IProlongatieContractenDataProviderOutput> => {
  const orders: IOrderSchemaOrder[] = [];
  for (const sortering of params.sortering) {
    switch (sortering.key) {
      case EProlongatieContractKolom.Contractnummer:
        orders.push({
          naam: 'CONTRACTNUMMER',
          richting: sortering.sortering === ESortering.Ascending ? 'ASC' : 'DESC',
        });
        break;
      case EProlongatieContractKolom.Relatie:
        orders.push({
          naam: 'RELATIE_NAAM',
          richting: sortering.sortering === ESortering.Ascending ? 'ASC' : 'DESC',
        });
        break;
      case EProlongatieContractKolom.TotaalProlongatie:
        orders.push({
          naam: 'VORDERINGEN_TOTAALBEDRAG',
          richting: sortering.sortering === ESortering.Ascending ? 'ASC' : 'DESC',
        });
        break;
      case EProlongatieContractKolom.Van:
        orders.push({
          naam: 'DATUM_VAN',
          richting: sortering.sortering === ESortering.Ascending ? 'ASC' : 'DESC',
        });
        break;
      case EProlongatieContractKolom.Tot:
        orders.push({
          naam: 'DATUM_TOT',
          richting: sortering.sortering === ESortering.Ascending ? 'ASC' : 'DESC',
        });
        break;
      default:
        throw new Error(`Sortering niet ondersteund voor kolom ${sortering.key}`);
    }
  }

  const result = await api.v2.prolongatie.ophalenProlongatieContracten({
    filterSchema: {
      filters: [
        ...(params.filterSchema?.filters ?? []),
        {
          naam: 'PROLONGATIE_RUN_IDS',
          data: [params.run.ID],
        },
      ],
    },
    paginatie: params.paginatie,
    orderSchema: {
      orders,
    },
  });

  const prolCntIDs = result.contracten.map((contract) => contract.ID);
  const afgeleideDatasResult = await api.v2.prolongatie.ophalenProlongatieContractAfgeleideDatas({
    prolCntIDs,
  });

  const nieuweData: IData = {
    totaalAantal: result.totaalAantal,
    contracten: result.contracten.reduce(
      (acc, contract, i) => ({
        ...acc,
        [params.paginatie.index + i]: contract,
      }),
      params.currentData?.contracten ?? {},
    ),
    afgeleideDatas: {
      ...params.currentData?.afgeleideDatas,
      ...afgeleideDatasResult.afgeleideDatas,
    },
  };

  return {
    data: nieuweData,
  };
};

enum EProlongatieContractKolom {
  Contractnummer,
  Signalering,
  Relatie,
  Van,
  Tot,
  Betaaldag,
  TotaalProlongatie,
  TotaalHuur,
  TotaalActie,
  TotaalGebruikerstoeslag,
  TotaalServicetoeslag,
}

export type FilterSoort = 'HEEFT_SIGNALERINGEN';

function berekenDuurTussen2Datums(startDatum: Date, eindDatum: Date): string {
  const dagen = differenceInDays(eindDatum, startDatum);
  const uren = differenceInHours(eindDatum, startDatum) % 24;
  const minuten = differenceInMinutes(eindDatum, startDatum) % 60;
  const seconden = differenceInSeconds(eindDatum, startDatum) % 60;

  return `${minuten !== 0 ? `${minuten} minuten,` : ``} ${seconden} seconden`;
}

// Voorbeeld van gebruik:
// berekenDuurMetDateFns('2024-05-10T08:30:00', '2024-05-11T12:45:00');

const ProlongatieDetail = observer(
  (props: IUitgeklapteRijProps<ERunTabelV2Kolom, IProlongatieRunV2>) => {
    const runTabelV2Context = useContext(RunTabelV2Context);
    const { checkStore } = useContext(RootStoreContext);
    const globaleRenderer = useContext(GlobaleRendererContext);
    const [data, _setData] = useState<IRemoteData<IData>>(createPendingRemoteData());
    const dataRef = React.useRef(data);
    const setData = (data: IRemoteData<IData>) => {
      dataRef.current = data;
      _setData(data);
    };

    const [filterData, setFilterData] = useState<IFilterData<FilterSoort>[]>([
      {
        naam: 'HEEFT_SIGNALERINGEN',
        data: true,
        isActief: false,
      },
    ]);
    const [filterSchema, setFilterSchema] = useState<IFilterSchema>(maakFilterSchema(filterData));

    const dataProvider = useMemo(() => {
      return runTabelV2Context.prolongatieContractenDataProvider ?? standaardDataProvider;
    }, [runTabelV2Context.prolongatieContractenDataProvider]);

    const [sortering, setSortering] = useState<IAspKolomSorteringItem<EProlongatieContractKolom>[]>(
      [
        {
          key: EProlongatieContractKolom.Relatie,
          sortering: ESortering.Ascending,
        },
      ],
    );

    const ophalenData = useCallback(
      async (paginatie: IPaginatiePositie, uitbreiden: boolean) => {
        if (!uitbreiden) {
          setData(createPendingRemoteData());
        }

        const result = await dataProvider({
          paginatie,
          run: props.regel,
          currentData: uitbreiden ? dataRef.current!.data : null,
          filterSchema,
          sortering,
        });

        const newData: IData = {
          ...dataRef.current!.data,
          totaalAantal: result.data.totaalAantal,
          contracten: {
            ...dataRef.current!.data?.contracten,
            ...result.data.contracten,
          },
          afgeleideDatas: {
            ...dataRef.current!.data?.afgeleideDatas,
            ...result.data.afgeleideDatas,
          },
        };
        setData(createReadyRemoteData(newData));
      },
      [props.regel, dataProvider, filterSchema, sortering],
    );

    useEffect(() => {
      // noinspection JSIgnoredPromiseFromCall
      ophalenData({ index: 0, aantal: 50 }, false);
    }, [ophalenData]);

    const handleExtraRijenAangevraagd = useCallback(
      async (paginatie: IPaginatiePositie) => {
        await ophalenData(paginatie, true);
      },
      [ophalenData],
    );

    const keyExtractor = useCallback((contract: IProlongatieContract) => contract.ID, []);

    const kolommen = useMemo<ASPKolom<EProlongatieContractKolom, IProlongatieContract>[]>(
      () =>
        [
          {
            key: EProlongatieContractKolom.Contractnummer,
            label: 'Cnt.nr.',
            breedteType: EAspKolomBreedteType.Vast,
            vasteBreedte: 130,
            renderer: (contract: IProlongatieContract) => (
              <ContractVisualisatie cntID={contract.CntID} />
            ),
          },
          {
            key: EProlongatieContractKolom.Signalering,
            label: 'Sign.',
            breedteType: EAspKolomBreedteType.Vast,
            vasteBreedte: 65,
            renderer: (contract: IProlongatieContract) => {
              const afgeleideData = data.data!.afgeleideDatas[contract.ID];
              if (afgeleideData === undefined) {
                return <Skeleton />;
              }

              if (afgeleideData.signaleringen.length === 0) {
                return <StoplichtIndicatie status={EStoplichtStatus.Groen} />;
              }

              if (afgeleideData.signaleringen.some((x) => x.niveau === 'error')) {
                return <StoplichtIndicatie status={EStoplichtStatus.Rood} />;
              }

              return <StoplichtIndicatie status={EStoplichtStatus.Oranje} />;
            },
          },
          runTabelV2Context.relatieKolomVerstoppen
            ? null
            : {
                key: EProlongatieContractKolom.Relatie,
                label: 'Relatie',
                breedteType: EAspKolomBreedteType.Vast,
                vasteBreedte: 300,
                renderer: (contract: IProlongatieContract) => {
                  return contract.contract_RelID === null ? null : (
                    <RelatieVisualisatie relID={contract.contract_RelID} />
                  );
                },
              },
          {
            key: EProlongatieContractKolom.Van,
            label: 'Van',
            breedteType: EAspKolomBreedteType.Vast,
            vasteBreedte: 125,
            renderer: (contract: IProlongatieContract) =>
              format(new Date(contract.DatumVan), 'dd-MM-yyyy'),
          },
          {
            key: EProlongatieContractKolom.Tot,
            label: 'Tot',
            breedteType: EAspKolomBreedteType.Vast,
            vasteBreedte: 125,
            renderer: (contract: IProlongatieContract) =>
              format(new Date(contract.DatumTot), 'dd-MM-yyyy'),
          },
          {
            key: EProlongatieContractKolom.TotaalProlongatie,
            label: 'Tot. prol.',
            breedteType: EAspKolomBreedteType.Vast,
            vasteBreedte: 90,
            renderer: (contract: IProlongatieContract) => {
              const afgeleideData = data.data!.afgeleideDatas[contract.ID];
              if (afgeleideData === undefined) {
                return <Skeleton />;
              }
              const totaal = afgeleideData.totaalProlongatie;
              // if (totaal === 0) {
              //   return '- -';
              // }

              return <FormatteerBedrag bedrag={totaal} />;
            },
          },
          {
            key: EProlongatieContractKolom.TotaalHuur,
            label: 'Tot. Huur',
            breedteType: EAspKolomBreedteType.Vast,
            vasteBreedte: 90,
            renderer: (contract: IProlongatieContract) => {
              const afgeleideData = data.data!.afgeleideDatas[contract.ID];
              if (afgeleideData === undefined) {
                return <Skeleton />;
              }
              const totaal = afgeleideData.totaalHuur;
              if (totaal === 0) {
                return '- -';
              }

              return <FormatteerBedrag bedrag={totaal} style={{ opacity: 0.6 }} />;
            },
          },
          {
            key: EProlongatieContractKolom.TotaalActie,
            label: 'Tot. Actie',
            breedteType: EAspKolomBreedteType.Vast,
            vasteBreedte: 90,
            renderer: (contract: IProlongatieContract) => {
              const afgeleideData = data.data!.afgeleideDatas[contract.ID];
              if (afgeleideData === undefined) {
                return <Skeleton />;
              }
              const totaal = afgeleideData.totaalActie;
              if (totaal === 0) {
                return '- -';
              }

              return <FormatteerBedrag bedrag={totaal} style={{ opacity: 0.6 }} />;
            },
          },
          {
            key: EProlongatieContractKolom.TotaalGebruikerstoeslag,
            label: 'Tot. Gebr.toesl.',
            breedteType: EAspKolomBreedteType.Vast,
            vasteBreedte: 125,
            renderer: (contract: IProlongatieContract) => {
              const afgeleideData = data.data!.afgeleideDatas[contract.ID];
              if (afgeleideData === undefined) {
                return <Skeleton />;
              }
              const totaal = afgeleideData.totaalGebruikerstoeslag;
              if (totaal === 0) {
                return '- -';
              }
              return <FormatteerBedrag bedrag={totaal} style={{ opacity: 0.6 }} />;
            },
          },
          {
            key: EProlongatieContractKolom.TotaalServicetoeslag,
            label: 'Tot. Serv.toesl.',
            breedteType: EAspKolomBreedteType.Flex,
            flex: 1,
            renderer: (contract: IProlongatieContract) => {
              const afgeleideData = data.data!.afgeleideDatas[contract.ID];
              if (afgeleideData === undefined) {
                return <Skeleton />;
              }
              const totaal = afgeleideData.totaalServicetoeslag;
              if (totaal === 0) {
                return '- -';
              }
              return <FormatteerBedrag bedrag={totaal} style={{ opacity: 0.6 }} />;
            },
          },
          {
            key: EProlongatieContractKolom.Betaaldag,
            label: 'Betaaldag',
            breedteType: EAspKolomBreedteType.Vast,
            vasteBreedte: 125,
            renderer: (contract: IProlongatieContract) => contract.Betaaldag ?? '- -',
          },
        ].filter((x) => x !== null) as ASPKolom<EProlongatieContractKolom, IProlongatieContract>[],
      [data],
    );

    const [isBezig, setIsBezig] = useState(false);
    const [selectie, setSelectie] = useState<number[]>([]);

    const handleVerwijderen = useCallback(async () => {
      setIsBezig(true);
      const params: IVerwijderenProlongatiesParams = {
        prolCntIDs: selectie,
      };
      const checkData = await api.v2.prolongatie.checkVerwijderenProlongaties(params);
      if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
        setIsBezig(false);
        return;
      }

      await api.v2.prolongatie.verwijderenProlongaties(params);

      setSelectie([]);

      await ophalenData({ index: 0, aantal: 50 }, false);
      await runTabelV2Context.prolongatierunAfgeleideDataHerbepalen(props.regel.ID);

      setIsBezig(false);
    }, [setIsBezig, ophalenData, selectie, setSelectie, runTabelV2Context]);

    const filters = useMemo<IFilter<FilterSoort>[]>(
      () => [
        {
          naam: 'HEEFT_SIGNALERINGEN',
          altijdWeergevenInBalk: true,
          weergave: HeeftSignaleringenFilter,
        },
      ],
      [],
    );

    const magVerwerken = useMemo(() => {
      if (isBezig || selectie.length === 0 || data.state === ERemoteDataState.Pending) {
        return false;
      }

      // const selectieBevatContractenMetFoutSignalering = selectie
      //   .map((prolCntID) => data.data!.contracten[prolCntID])
      //   .some((contract) => contract.signaleringen.some((sig) => sig.niveau === 'fout'));
      // if (selectieBevatContractenMetFoutSignalering) {
      //   return false;
      // }

      return true;
    }, [isBezig, selectie, data]);

    const [uitgeklapt, setUitgeklapt] = useState<number[]>([]);

    const prolongatieContractContext = useMemo<IProlongatieContractContext>(() => {
      return {
        data: data.data!,
      };
    }, [data]);

    const bepaalUitgeklapteRijHoogte = useCallback(
      (contract: IProlongatieContract) => {
        const basisHoogte = 135;
        const signaleringBasishoogte = 60;

        if (data.state === ERemoteDataState.Ready) {
          const afgeleideData = data.data!.afgeleideDatas[contract.ID];
          if (afgeleideData !== undefined && afgeleideData.signaleringen.length > 0) {
            return basisHoogte + signaleringBasishoogte + 30 * afgeleideData.signaleringen.length;
          }
        }

        return basisHoogte;
      },
      [data],
    );

    return (
      <div className="d-flex flex-fill">
        <TabelInspringBlok />
        <div
          className="d-flex flex-column flex-fill"
          style={{ borderTop: `1px solid ${Kleur.LichtGrijs}` }}
        >
          <MenuLayout
            menu={
              <div className="d-flex flex-column">
                <div className="d-flex">
                  <div>
                    <GegevensLayout
                      labelBreedte={200}
                      gegevens={[
                        {
                          label: 'Facturen maken',
                          waarde:
                            props.regel.FacturenMaken === null
                              ? '- -'
                              : formatteerBoolean(props.regel.FacturenMaken),
                        },
                        {
                          label: 'Factuurdatum',
                          waarde:
                            props.regel.Factuurdatum === null
                              ? '- -'
                              : format(new Date(props.regel.Factuurdatum), 'dd-MM-yyyy'),
                        },
                        {
                          label: 'Bankopdrachten maken',
                          waarde:
                            props.regel.BankopdrachtenMaken === null
                              ? '- -'
                              : formatteerBoolean(props.regel.BankopdrachtenMaken),
                        },
                        {
                          label: 'Uitvoerdatum bankopdrachten',
                          waarde:
                            props.regel.UitvoerdatumBankopdrachten === null
                              ? '- -'
                              : format(
                                  new Date(props.regel.UitvoerdatumBankopdrachten),
                                  'dd-MM-yyyy',
                                ),
                        },
                        {
                          label: 'Batches maken',
                          waarde:
                            props.regel.BatchesMaken === null
                              ? '- -'
                              : formatteerBoolean(props.regel.BatchesMaken),
                        },
                      ]}
                    />
                  </div>
                  <div className="ml-5">
                    <GegevensLayout
                      labelBreedte={150}
                      gegevens={[
                        {
                          label: 'Is verwerkt',
                          waarde: formatteerBoolean(props.regel.IsVerwerkt),
                        },
                        {
                          label: 'Gepland verwerken',
                          waarde:
                            props.regel.GeplandVerwerken === null ? (
                              '- -'
                            ) : (
                              <span
                                style={{
                                  color: props.regel.GeplandVerwerken ? Kleur.Rood : undefined,
                                }}
                              >
                                {formatteerBoolean(props.regel.GeplandVerwerken)}
                              </span>
                            ),
                        },
                        {
                          label: 'Start verwerking op',
                          waarde:
                            props.regel.StartVerwerkingOp === null
                              ? '- -'
                              : format(
                                  new Date(props.regel.StartVerwerkingOp),
                                  'dd-MM-yyyy HH:mm:ss',
                                ),
                        },
                        {
                          label: 'Stop verwerking op',
                          waarde:
                            props.regel.StopVerwerkingOp === null
                              ? '- -'
                              : format(
                                  new Date(props.regel.StopVerwerkingOp),
                                  'dd-MM-yyyy HH:mm:ss',
                                ),
                        },
                        {
                          label: 'Duur verwerking',
                          waarde:
                            props.regel.StartVerwerkingOp === null ||
                            props.regel.StopVerwerkingOp === null
                              ? '- -'
                              : berekenDuurTussen2Datums(
                                  new Date(props.regel.StartVerwerkingOp),
                                  new Date(props.regel.StopVerwerkingOp),
                                ),
                        },
                      ]}
                    />
                  </div>

                  {/* <div className="ml-3 d-flex align-items-center">
                    <button
                      className="btn btn-sm btn-light d-flex align-items-center ml-3"
                      style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                      disabled={isBezig || runTabelV2Context.permissieMagRunVerwerken === false}
                      onClick={async (ev) => {
                        setIsBezig(true);

                        const params: IVerwerkenProlongatiesParams = {
                          relID: runTabelV2Context.relID ?? null,
                          prolongatieRunIDs: [props.regel.ID],
                        };

                        const checkData = await api.v2.prolongatieV2.checkVerwerkenProlongaties(
                          params,
                        );
                        if (
                          (await checkStore.controleren({ checkData })).type ===
                          EResultType.Annuleren
                        ) {
                          setIsBezig(false);
                          return;
                        }

                        const isSucccess = await globaleRenderer.render((renderProps) => (
                          <VerwerkenRunDialoog
                            open
                            onSuccess={() => renderProps.destroy(true)}
                            onAnnuleren={() => renderProps.destroy(false)}
                            relID={params.relID}
                            prolongatieRunIDs={params.prolongatieRunIDs}
                          />
                        ));

                        if (!isSucccess) {
                          setIsBezig(false);
                          return;
                        }

                        await runTabelV2Context.verversenData();

                        // const runID = props.selectie[0];
                        // const verwerkt = await globaleRenderer.render<boolean>((renderProps) => (
                        //   <ConfigurerenDialoog
                        //     prolRunID={runID}
                        //     open
                        //     onSuccess={(result) => renderProps.destroy(true)}
                        //     onAnnuleren={() => renderProps.destroy(false)}
                        //   />
                        // ));
                        // if (verwerkt) {
                        //   props.onSelectieChange([]);
                        //   await bepalenData({ index: 0, aantal: paginatieAantal }, false);
                        // }
                        setIsBezig(false);
                      }}
                      title={
                        runTabelV2Context.permissieMagRunVerwerken === false
                          ? 'Je hebt geen rechten om een run te verwerken'
                          : undefined
                      }
                    >
                      <IconPijlVolgend style={{ fill: EKleur.Grijs, width: 16, height: 16 }} />
                      <span className="ml-2">Verwerken run</span>
                    </button>
                  </div> */}
                </div>

                <div className="d-flex align-items-center flex-fill mt-3">
                  {/*<button*/}
                  {/*  className="btn btn-sm btn-light d-flex align-items-center justify-content-center"*/}
                  {/*  style={{ border: `1px solid ${Kleur.LichtGrijs}`, minWidth: 120 }}*/}
                  {/*  disabled={!magVerwerken}*/}
                  {/*  onClick={async (ev) => {*/}
                  {/*    setIsBezig(true);*/}
                  {/*    const verwerkt = await globaleRenderer.render<boolean>((renderProps) => (*/}
                  {/*      <VerwerkenDialoog*/}
                  {/*        prolRunID={props.regel.ID}*/}
                  {/*        prolCntIDs={selectie}*/}
                  {/*        open*/}
                  {/*        onSuccess={(result) => renderProps.destroy(true)}*/}
                  {/*        onAnnuleren={() => renderProps.destroy(false)}*/}
                  {/*      />*/}
                  {/*    ));*/}
                  {/*    if (verwerkt) {*/}
                  {/*      await ophalenData({ index: 0, aantal: 50 }, false);*/}
                  {/*    }*/}
                  {/*    setIsBezig(false);*/}
                  {/*  }}*/}
                  {/*>*/}
                  {/*  <IconPijlVolgend style={{ fill: Kleur.Grijs, width: 16, height: 16 }} />*/}
                  {/*  <span className="ml-2">Verwerken</span>*/}
                  {/*</button>*/}

                  <button
                    className="btn btn-sm btn-light d-flex align-items-center"
                    style={{ border: `1px solid ${EKleur.LichtGrijs}`, minWidth: '125px' }}
                    disabled={
                      isBezig ||
                      runTabelV2Context.permissieMagRunVerwerken === false ||
                      selectie.length !== 0
                    }
                    onClick={async (ev) => {
                      setIsBezig(true);

                      const params: IVerwerkenProlongatiesParams = {
                        relID: runTabelV2Context.relID ?? null,
                        prolongatieRunIDs: [props.regel.ID],
                      };

                      const checkData = await api.v2.prolongatieV2.checkVerwerkenProlongaties(
                        params,
                      );
                      if (
                        (await checkStore.controleren({ checkData })).type === EResultType.Annuleren
                      ) {
                        setIsBezig(false);
                        return;
                      }

                      const isSucccess = await globaleRenderer.render((renderProps) => (
                        <VerwerkenRunDialoog
                          open
                          onSuccess={() => renderProps.destroy(true)}
                          onAnnuleren={() => renderProps.destroy(false)}
                          relID={params.relID}
                          prolongatieRunIDs={params.prolongatieRunIDs}
                        />
                      ));

                      if (!isSucccess) {
                        setIsBezig(false);
                        return;
                      }

                      await runTabelV2Context.verversenData();

                      // const runID = props.selectie[0];
                      // const verwerkt = await globaleRenderer.render<boolean>((renderProps) => (
                      //   <ConfigurerenDialoog
                      //     prolRunID={runID}
                      //     open
                      //     onSuccess={(result) => renderProps.destroy(true)}
                      //     onAnnuleren={() => renderProps.destroy(false)}
                      //   />
                      // ));
                      // if (verwerkt) {
                      //   props.onSelectieChange([]);
                      //   await bepalenData({ index: 0, aantal: paginatieAantal }, false);
                      // }
                      setIsBezig(false);
                    }}
                    title={
                      runTabelV2Context.permissieMagRunVerwerken === false
                        ? 'Je hebt geen rechten om een run te verwerken'
                        : undefined
                    }
                  >
                    <IconPijlVolgend style={{ fill: EKleur.Grijs, width: 16, height: 16 }} />
                    <span className="ml-2">Verwerken run</span>
                  </button>

                  <button
                    className="btn btn-sm btn-light d-flex align-items-center justify-content-center ml-3"
                    style={{ border: `1px solid ${Kleur.LichtGrijs}`, minWidth: 120 }}
                    disabled={isBezig || selectie.length === 0}
                    onClick={async (ev) => {
                      if (
                        (
                          await checkStore.bevestigen({
                            inhoud: 'Bevestig verwijderen contract(en)',
                          })
                        ).type === EResultType.Annuleren
                      ) {
                        return;
                      }

                      await handleVerwijderen();
                    }}
                  >
                    <IconVerwijderen style={{ fill: Kleur.Grijs, width: 16, height: 16 }} />
                    <span className="ml-2">Verwijderen</span>
                  </button>

                  <FilterBalkV2
                    filters={filters}
                    filterData={filterData}
                    onFilterDataChange={setFilterData}
                    onFilterSchemaChange={setFilterSchema}
                    style={{ marginLeft: 25 }}
                  />
                </div>
              </div>
            }
            body={
              <div className="d-flex flex-fill flex-column">
                {data.state === ERemoteDataState.Pending ? (
                  <div className="d-flex flex-fill align-items-center justify-content-center">
                    <LoadingSpinner />
                  </div>
                ) : (
                  <ProlongatieContractContext.Provider value={prolongatieContractContext}>
                    <ASPTabel
                      rijen={data.data!.contracten}
                      kolommen={kolommen}
                      keyExtractor={keyExtractor}
                      totaalAantalRijen={data.data!.totaalAantal}
                      onExtraRijenAangevraagd={handleExtraRijenAangevraagd}
                      selectie={selectie}
                      onSelectieChange={setSelectie}
                      sortering={sortering}
                      onSorteringChange={setSortering}
                      sorteringOpties={{
                        modus: ESorteringModus.Whitelist,
                        kolomOpties: {
                          [EProlongatieContractKolom.Contractnummer]: {
                            magSorteren: true,
                          },
                          [EProlongatieContractKolom.Relatie]: {
                            magSorteren: true,
                          },
                          [EProlongatieContractKolom.TotaalProlongatie]: {
                            magSorteren: true,
                          },
                          [EProlongatieContractKolom.Van]: {
                            magSorteren: true,
                          },
                          [EProlongatieContractKolom.Tot]: {
                            magSorteren: true,
                          },
                        },
                      }}
                      uitgeklapteRijComponent={ContractDetail}
                      uitgeklapteRijHoogte={bepaalUitgeklapteRijHoogte}
                      uitgeklapt={uitgeklapt}
                      onUitgeklaptChange={setUitgeklapt}
                    />
                  </ProlongatieContractContext.Provider>
                )}
              </div>
            }
          />
        </div>
      </div>
    );
  },
);

export default ProlongatieDetail;
