import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
  GridStyleWrapper,
} from '../../../../../../helpers/dxTableGrid';
import {
  Grid,
  Table,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  DataTypeProvider,
  EditingState,
  IntegratedSorting,
  SortingState,
} from '@devexpress/dx-react-grid';
import nameof from '../../../../../../core/nameOf';
import DragHandle from '../../../../../../components/tabel/DragHandle';
import { arrayMove, SortableContainer, SortableElement } from 'react-sortable-hoc';
import { IOphalenProducttypenResultElement } from '../../../../../../../../shared/src/api/v2/product/type';
import { IOphalenLeveranciersVoorProductResultElement } from '../../../../../../../../shared/src/api/v2/inkoop/aanbod';
import { IOphalenRelatiesResultElementV2 } from '../../../../../../../../shared/src/api/v2/relatie';
import ToevoegenDialoog from './ToevoegenDialoog';
import { observer } from 'mobx-react-lite';
import BooleanWeergave from '../../../../../../components/tabel/BooleanWeergave';
import { RootStoreContext } from '../../../../../../stores/RootStore';
import api from '../../../../../../api';
import { EResultType } from '../../../../../../stores/CheckStore';
import { DetailRegelContext, IProducttype } from '../index';
import FormatteerBedrag from '../../../../../../components/MutatieBedrag';
import _ from 'lodash';
import { Kleur } from '../../../../../../bedrijfslogica/constanten';
import LoadingSpinner from '../../../../../../components/Gedeeld/LoadingSpinner';

interface IProps {}

interface IToevoegenDialoogState {}

const ProducttypesField: React.FC<IProps> = observer((props) => {
  const { checkStore } = useContext(RootStoreContext);
  const {
    values,
    producttypeSortering,
    onProducttypeSorteringChange,
    setFieldValue,
    prodSrtID,
    merkID,
  } = useContext(DetailRegelContext);

  // Producttype gegevens
  const [producttypenResult, setProducttypen] = useState<
    IOphalenProducttypenResultElement[] | null
  >(null);

  // Inkoopgegevens
  const [inkoopgegevens, setInkoopgegevens] = useState<
    IOphalenLeveranciersVoorProductResultElement[] | null
  >(null);

  // Relaties (leveranciers)
  const [relaties, setRelaties] = useState<IOphalenRelatiesResultElementV2[] | null>(null);

  const ophalenProducttypen = useCallback(
    async (prodTypeIDs: number[]) => {
      const result = await api.v2.product.type.ophalenProducttypen({
        filterSchema: {
          filters: [
            {
              naam: 'IDS',
              data: prodTypeIDs,
            },
          ],
        },
      });

      setProducttypen(result.producttypen);
    },
    [setProducttypen],
  );

  useEffect(() => {
    ophalenProducttypen(values.producttypes.map((x) => x.prodTypeID));
  }, [JSON.stringify(values.producttypes.map((x) => x.prodTypeID).sort())]);

  // Per type de inkoopgegevens (inkoopprijs) ophalen
  const ophalenInkoopgegevens = useCallback(async () => {
    if (producttypenResult === null) {
      return;
    }

    const result = await api.v2.inkoop.aanbod.ophalenLeveranciersVoorProduct({
      filterSchema: {
        filters: [
          {
            naam: 'TYPE_IDS',
            data: producttypenResult.map((x) => x.TypeID),
          },
        ],
      },
    });

    setInkoopgegevens(result.inkoopdiensttypen);
  }, [producttypenResult]);

  useEffect(() => {
    ophalenInkoopgegevens();
  }, [ophalenInkoopgegevens]);

  const ophalenRelaties = useCallback(async () => {
    if (inkoopgegevens === null) {
      return;
    }

    const relIDs = _.uniq(inkoopgegevens.map((x) => x.RelID));
    const relatiesResult = await api.v2.relatie.ophalenRelaties({
      filterSchema: { filters: [{ naam: 'IDS', data: relIDs }] },
    });
    const relaties = relatiesResult.relaties;

    setRelaties(relaties);
  }, [inkoopgegevens]);

  useEffect(() => {
    ophalenRelaties();
  }, [ophalenRelaties]);

  const [
    producttypeToevoegenDialoogState,
    setProducttypeToevoegenDialoogState,
  ] = useState<IToevoegenDialoogState | null>(null);

  const uitgeslotenTypeIDsToevoegenDialoog = useMemo(
    () => values.producttypes.map((x) => x.prodTypeID),
    [values.producttypes],
  );

  if (inkoopgegevens === null || relaties === null || producttypenResult === null) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <GridStyleWrapper maxHeight={400} rowAmount={values.producttypes.length}>
        <Grid
          rows={values.producttypes}
          getRowId={(row) => row.prodTypeID}
          columns={[
            {
              name: '__dragHandle' as any,
              title: ' ',
            },
            {
              name: 'prioNr',
              title: 'Prio.',
            },
            {
              name: '__merkNaam',
              title: 'Merk',
            },
            {
              name: '__typeNaam',
              title: 'Type',
            },
            {
              name: '__productsoortnaamKort' as any,
              title: 'Cat.',
            },
            {
              name: 'Kenmerk',
              title: 'Kenmerk',
            },
            {
              name: '__inkoopwaardig',
              title: 'Ink. toestaan',
            },
            {
              name: '__leverancier',
              title: 'Leverancier',
            },
            {
              name: '__inkoopprijs',
              title: 'Ink.prijs',
            },
          ]}
        >
          <DataTypeProvider
            for={['__dragHandle']}
            formatterComponent={(formatterProps) => {
              if (
                producttypeSortering.length !== 1 ||
                producttypeSortering.findIndex(
                  (x) => x.columnName === nameof<IProducttype>('prioNr') && x.direction === 'asc',
                ) === -1
              ) {
                return <span />;
              }

              return <DragHandle />;
            }}
          />
          <DataTypeProvider
            for={['__typeNaam']}
            formatterComponent={(formatterProps) => {
              const row: IProducttype = formatterProps.row;

              if (producttypenResult === null) {
                return <span />;
              }
              const type = producttypenResult.find((x) => x.TypeID === row.prodTypeID);
              if (type === undefined) {
                return <span />;
              }

              return <span>{type.Typenaam}</span>;
            }}
          />
          <DataTypeProvider
            for={['__merkNaam']}
            formatterComponent={(formatterProps) => {
              const row: IProducttype = formatterProps.row;
              if (producttypenResult === null) {
                return <span />;
              }
              const type = producttypenResult.find((x) => x.TypeID === row.prodTypeID);
              if (type === undefined) {
                return <span />;
              }

              return <span>{type.Merknaam}</span>;
            }}
          />

          <DataTypeProvider
            for={['Kenmerk']}
            formatterComponent={(formatterProps) => {
              const row: IProducttype = formatterProps.row;
              if (producttypenResult === null) {
                return <span />;
              }
              const type = producttypenResult.find((x) => x.TypeID === row.prodTypeID);
              if (type === undefined) {
                return <span />;
              }

              return <span>{type.Kenmerk}</span>;
            }}
          />

          <DataTypeProvider
            for={['__productsoortnaamKort']}
            formatterComponent={(formatterProps) => {
              const row: IProducttype = formatterProps.row;

              if (producttypenResult === null) {
                return <span />;
              }

              const type = producttypenResult.find((x) => x.TypeID === row.prodTypeID)!;

              return (
                <span style={{ color: type.ProdSrtID !== prodSrtID ? Kleur.Rood : undefined }}>
                  {type.ProductsoortnaamKort}
                </span>
              );
            }}
          />

          <DataTypeProvider
            for={['__leverancier']}
            formatterComponent={(formatterProps) => {
              const row: IProducttype = formatterProps.row;

              if (inkoopgegevens.length === 0 || relaties.length === 0) {
                return <span />;
              }
              const inkoop = inkoopgegevens.find((x) => x.TypeID === row.prodTypeID);
              if (inkoop === undefined) {
                return <span />;
              }

              if (inkoop.RelID === null) {
                return <span />;
              }
              const relatie = relaties.find((x) => x.RelID === inkoop.RelID) ?? null;

              if (relatie === null) {
                return <span></span>;
              }
              return <span>{relatie.weergavenaam}</span>;
            }}
          />

          <DataTypeProvider
            for={['__inkoopprijs']}
            formatterComponent={(formatterProps) => {
              const row: IProducttype = formatterProps.row;
              if (inkoopgegevens === null) {
                return <span />;
              }
              const inkoop = inkoopgegevens.find((x) => x.TypeID === row.prodTypeID);
              if (inkoop === undefined) {
                return <span />;
              }

              if (inkoop.Inkoopprijs === null) {
                return <span></span>;
              }

              return (
                <div className="d-flex">
                  <div>
                    <FormatteerBedrag bedrag={inkoop.Inkoopprijs} />
                  </div>
                  {inkoop.InkoopprijsActie !== null && (
                    <div className="ml-2">
                      <div className="d-flex">
                        <span> (</span>
                        <FormatteerBedrag bedrag={inkoop.InkoopprijsActie} />
                        <span> )</span>
                      </div>
                    </div>
                  )}
                </div>
              );
            }}
          />

          <DataTypeProvider
            for={['__inkoopwaardig']}
            formatterComponent={(formatterProps) => {
              const row: IProducttype = formatterProps.row;
              if (producttypenResult === null) {
                return <span />;
              }
              const type = producttypenResult.find((x) => x.TypeID === row.prodTypeID);
              if (type === undefined) {
                return <span />;
              }

              return (
                <span style={{ color: type.InkoopToegestaan ? Kleur.Groen : Kleur.Rood }}>
                  <BooleanWeergave waarde={type.InkoopToegestaan} />
                </span>
              );
            }}
          />
          <SortingState
            sorting={producttypeSortering}
            onSortingChange={(x) => onProducttypeSorteringChange(x)}
          />
          <EditingState
            onCommitChanges={async (changes) => {
              if (changes.deleted !== undefined && changes.deleted.length > 0) {
                const bevestigenResult = await checkStore.bevestigen({
                  inhoud: 'Bevestig verwijderen producttype uit lijst',
                });
                if (bevestigenResult.type === EResultType.Annuleren) {
                  return;
                }

                const newProducttypen = values.producttypes.filter(
                  (x) => !changes.deleted!.includes(x.prodTypeID),
                );
                setFieldValue('producttypes', newProducttypen);
              }
            }}
            onAddedRowsChange={() => setProducttypeToevoegenDialoogState({})}
          />
          <IntegratedSorting />
          <Table
            messages={{ noData: 'Geen gekoppelde producttypen' }}
            rowComponent={({ row, ...restProps }) => {
              const TableRow = SortableElement(Table.Row);
              const r: IProducttype = row;
              return (
                <TableRow
                  {...restProps}
                  row={r}
                  index={values.producttypes.findIndex(
                    (type: IProducttype) => type.prodTypeID === r.prodTypeID,
                  )}
                />
              );
            }}
            bodyComponent={({ row, ...restProps }: any) => {
              const TableBody = SortableContainer(Table.TableBody);
              return (
                <TableBody
                  {...restProps}
                  onSortEnd={async (x) => {
                    const nieuweProducttype = { ...values.producttypes[x.newIndex]! };
                    const oudeProducttype = { ...values.producttypes[x.oldIndex] };

                    let newProducttypen = [...values.producttypes];
                    newProducttypen[x.oldIndex].prioNr = nieuweProducttype.prioNr;
                    newProducttypen[x.newIndex].prioNr = oudeProducttype.prioNr;
                    newProducttypen = arrayMove(newProducttypen, x.oldIndex, x.newIndex);

                    setFieldValue('producttypes', newProducttypen);
                  }}
                  useDragHandle
                />
              );
            }}
          />
          <TableColumnResizing
            defaultColumnWidths={[
              {
                columnName: '__dragHandle',
                width: 50,
              },
              {
                columnName: 'prioNr',
                width: 75,
              },
              {
                columnName: '__merkNaam',
                width: 125,
              },
              {
                columnName: '__productsoortnaamKort' as any,
                width: 70,
              },
              {
                columnName: '__typeNaam',
                width: 175,
              },
              {
                columnName: 'Kenmerk',
                width: 225,
              },
              {
                columnName: '__inkoopwaardig',
                width: 125,
              },
              {
                columnName: '__leverancier',
                width: 150,
              },
              {
                columnName: '__inkoopprijs',
                width: 150,
              },
            ]}
          />
          <TableHeaderRow showSortingControls />

          <TableEditColumn
            width={35}
            commandComponent={DXTableEditColumnCommandComponent}
            cellComponent={DXTableEditColumnCellComponent}
            showDeleteCommand
            showAddCommand
          />
        </Grid>
      </GridStyleWrapper>
      {producttypeToevoegenDialoogState !== null && (
        <ToevoegenDialoog
          open
          onSuccess={async (result) => {
            setProducttypeToevoegenDialoogState(null);
            const prioNr =
              values.producttypes.length !== 0
                ? Math.max(...values.producttypes.map((x) => x.prioNr)) + 1
                : 0;
            const newProducttypen = [
              ...values.producttypes,
              {
                prodTypeID: result.typeID,

                prioNr,
              },
            ];

            await ophalenProducttypen(newProducttypen.map((x) => x.prodTypeID));
            setFieldValue('producttypes', newProducttypen);
          }}
          onAnnuleren={() => setProducttypeToevoegenDialoogState(null)}
          prodSrtID={prodSrtID}
          merkID={merkID}
          uitgeslotenTypeIDs={uitgeslotenTypeIDsToevoegenDialoog}
        />
      )}
    </>
  );
});

export default ProducttypesField;
