import api from '../../../index';
import {
  IOphalenComplexenParams,
  IOphalenComplexenResult,
  IToevoegenComplexParams,
  IToevoegenComplexResult,
  IVerwijderenComplexenParams,
  IVerwijderenComplexenResult,
  IWijzigenComplexParams,
  IWijzigenComplexResult,
} from '../../../../../../shared/src/api/v2/relatie/complex';
import ICheckData from '../../../../../../shared/src/models/ICheckData';

const complex = {
  ophalenComplexen: async (params: IOphalenComplexenParams): Promise<IOphalenComplexenResult> => {
    return await api.post('v2/relatie/complex/ophalen-complexen', params);
  },
  toevoegenComplex: async (params: IToevoegenComplexParams): Promise<IToevoegenComplexResult> => {
    return await api.post('v2/relatie/complex/toevoegen-complex', params);
  },
  checkWijzigenComplex: async (params: IWijzigenComplexParams): Promise<ICheckData> => {
    return await api.post('v2/relatie/complex/check-wijzigen-complex', params);
  },
  wijzigenComplex: async (params: IWijzigenComplexParams): Promise<IWijzigenComplexResult> => {
    return await api.post('v2/relatie/complex/wijzigen-complex', params);
  },
  checkVerwijderenComplexen: async (params: IVerwijderenComplexenParams): Promise<ICheckData> => {
    return await api.post('v2/relatie/complex/check-verwijderen-complexen', params);
  },
  verwijderenComplexen: async (
    params: IVerwijderenComplexenParams,
  ): Promise<IVerwijderenComplexenResult> => {
    return await api.post('v2/relatie/complex/verwijderen-complexen', params);
  },
};

export default complex;
