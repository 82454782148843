import React, { useCallback, useMemo } from 'react';
import { IUitgeklapteRijProps } from '../../../../../../../../components/tabel/ASPTabel/Body/UitgeklapteRij';
import { EKolom } from '../index';
import { IOphalenHerincassozakenResultElement } from '../../../../../../../../../../shared/src/api/v2/debiteur/herincassozaak';
import TabelInspringBlok from '../../../../../../../../components/layout/TabelInspringBlok';
import { IOphalenFacturenResultElement } from '../../../../../../../../../../shared/src/api/v2/debiteur/herincassozaak';
import {
  ASPKolom,
  EAspKolomBreedteType,
} from '../../../../../../../../components/tabel/ASPTabel/types';
import MenuLayout from '../../../../../../../../components/MenuLayout';
import LoadingSpinner from '../../../../../../../../components/Gedeeld/LoadingSpinner';
import ASPTabel from '../../../../../../../../components/tabel/ASPTabel';
import { format } from 'date-fns';
import FormatteerBedrag from '../../../../../../../../components/MutatieBedrag';
import useUrlState from '../../../../../../../../core/useUrlState';
import { RouteComponentProps, withRouter } from 'react-router-dom';

export enum EKolomDetail {
  Factuurnummer,
  Factuurdatum,
  BedragInZaak,
  DatumToegevoegdAanZaak,
  DatumVerstuurd,
}

const UitgeklapteRij = (
  props: IUitgeklapteRijProps<EKolom, IOphalenHerincassozakenResultElement>,
) => {
  const facturen = props.regel.facturen;

  const keyExtractor = useCallback((row: IOphalenFacturenResultElement) => row.ID, []);

  const kolommen = useMemo<ASPKolom<EKolomDetail, IOphalenFacturenResultElement>[]>(
    () => [
      {
        key: EKolomDetail.Factuurnummer,
        label: 'Factuurnummer',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 135,
        renderer: (item: IOphalenFacturenResultElement) => {
          return item.Factuurnummer;
        },
      },
      {
        key: EKolomDetail.Factuurdatum,
        label: 'Factuurdatum',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 135,
        renderer: (item: IOphalenFacturenResultElement) => {
          return format(new Date(item.Factuurdatum), 'dd-MM-yyyy');
        },
      },
      {
        key: EKolomDetail.BedragInZaak,
        label: 'Bedrag in zaak',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 135,
        renderer: (item: IOphalenFacturenResultElement) => {
          return <FormatteerBedrag bedrag={item.BedragInZaak} />;
        },
      },
      {
        key: EKolomDetail.DatumToegevoegdAanZaak,
        label: 'Toegevoegd op',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 150,
        renderer: (item: IOphalenFacturenResultElement) => {
          return item.DatumToegevoegdAanZaak !== null
            ? format(new Date(item.DatumToegevoegdAanZaak), 'dd-MM-yyyy HH:mm')
            : null;
        },
      },
      {
        key: EKolomDetail.DatumVerstuurd,
        label: 'Verstuurd op',
        breedteType: EAspKolomBreedteType.Flex,
        flex: 1,
        renderer: (item: IOphalenFacturenResultElement) => {
          return item.DatumVerstuurd !== null
            ? format(new Date(item.DatumVerstuurd), 'dd-MM-yyyy HH:mm')
            : 'Niet verstuurd';
        },
      },
    ],
    [],
  );

  return (
    <div className="d-flex flex-fill">
      <TabelInspringBlok />
      <MenuLayout
        body={
          <div className="d-flex flex-column flex-fill">
            {facturen === null ? (
              <div className="flex-fill d-flex align-items-center justify-content-center">
                <LoadingSpinner />
              </div>
            ) : (
              <div className="d-flex flex-column flex-fill">
                <ASPTabel
                  rijen={facturen}
                  kolommen={kolommen}
                  keyExtractor={keyExtractor}
                  // selectie={urlState.selectie}
                  // onSelectieChange={(selectie) => setUrlStateSync('selectie', selectie)}
                />
              </div>
            )}
          </div>
        }
      />
    </div>
  );
};

export default UitgeklapteRij;
