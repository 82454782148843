import { Field, FieldProps, Formik, FormikActions, FormikErrors, FormikProps } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import { ISponsorEvent } from '../../../../../../shared/src/api/v2/sponsoringv2/event';
import api from '../../../../api';
import teksten from '../../../../bedrijfslogica/teksten';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import Dialoog from '../../../../components/dialogen/Dialoog';
import DatumKiezer from '../../../../components/formulier/DatumKiezer';
import SponsorCommissieSelectie from '../../../../components/formulier/SponsorCommissieSelectie';
import SponsorDienstSelectie from '../../../../components/formulier/SponsorDienstSelectie';
import VinkVeld from '../../../../components/formulier/VinkVeld';
import IDialoogProps from '../../../../core/IDialoogProps';
import nameOf from '../../../../core/nameOf';
import {
  dagDatum,
  dagDatumOfNu,
  dagDatumOfNull,
  dagDatumOfUndefined,
} from '../../../../helpers/datum';
import { addDays } from 'date-fns';

interface IDialoogResult {}

interface IProps extends IDialoogProps<IDialoogResult> {
  id: number | null;
  dienstID: number | null | undefined;
}

interface IFormikValues {
  sponDienstID: number | null;
  registratiedatum: Date;
  onderwerp: string;
  isDefinitief: boolean;
  vrijgevenVoorBetalen: boolean;

  verzoekID?: number;
  commissieID?: number;
  omschrijving?: string;
  notities?: string;

  startdatum?: Date | null;
  einddatum?: Date | null;
}

const MuterenDialoog: React.FC<IProps> = observer((props) => {
  const { dialoogIndex, onAnnuleren } = props;
  const [item, setItem] = useState<ISponsorEvent | undefined>(undefined);

  const isNieuwItem = (): boolean => {
    return props.id == null;
  };

  const ophalenItem = useCallback(async () => {
    const results = await api.v2.sponsoringv2.event.ophalenEvents({});

    setItem(results.find((r) => r.ID == props.id));
  }, [props.id]);

  useEffect(() => {
    ophalenItem();
  }, [ophalenItem]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      if (values.sponDienstID === null) return;

      let event: ISponsorEvent = {
        ID: item?.ID,
        SponDienstID: values.sponDienstID,
        Registratiedatum: values.registratiedatum,
        IsDefinitief: values.isDefinitief,

        Onderwerp: values.onderwerp ?? '',

        CommissieID: values.commissieID,
        VerzoekID: item?.VerzoekID,
        Omschrijving: values.omschrijving,
        Notities: values.notities,

        Startdatum: values.startdatum ?? undefined,
        Einddatum: values.einddatum ?? undefined,

        VrijgevenVoorBetalen: values.vrijgevenVoorBetalen,
      };

      await api.v2.sponsoringv2.event.toevoegenOfWijzigenEvent(event);

      actions.setSubmitting(false);
      props.onSuccess({});
    },
    [item],
  );

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (item == undefined) {
      return {
        sponDienstID: props.dienstID ?? null,
        registratiedatum: dagDatum(new Date()),
        isDefinitief: false,
        onderwerp: '',

        verzoekID: undefined,
        commissieID: undefined,
        omschrijving: undefined,
        notities: undefined,

        startdatum: null,
        einddatum: null,

        vrijgevenVoorBetalen: false,
      };
    }

    return {
      sponDienstID: item.SponDienstID,
      registratiedatum: dagDatumOfNu(new Date(item.Registratiedatum)),
      isDefinitief: false,
      onderwerp: item.Onderwerp ?? '',

      verzoekID: item.ID,
      commissieID: item.CommissieID,
      omschrijving: item.Omschrijving,
      notities: item.Notities,

      startdatum: dagDatumOfNull(item.Startdatum),
      einddatum: dagDatumOfNull(item.Einddatum),

      vrijgevenVoorBetalen: item.VrijgevenVoorBetalen,
    };
  }, [item]);

  const handleValidate = useCallback((values: IFormikValues) => {
    const errors: FormikErrors<IFormikValues> = {};
    if (!values.sponDienstID) {
      errors.sponDienstID = teksten.formulier.E_VERPLICHT_VELD({ veldnaam: 'Sponsordienst' });
    }
    if (!values.onderwerp && !values.omschrijving) {
      errors.onderwerp = 'Een onderwerp of omschrijving is verplicht';
      errors.omschrijving = 'Een onderwerp of omschrijving is verplicht';
    }
    return errors;
  }, []);

  return (
    <Dialoog index={dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>{isNieuwItem() ? 'Toevoegen' : 'Wijzigen'} event</ModalTitle>
      </ModalHeader>
      {initialValues === null ? (
        <LoadingSpinner />
      ) : (
        <Formik<IFormikValues>
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validate={handleValidate}
          enableReinitialize
          isInitialValid={!isNieuwItem()}
          render={(formikProps: FormikProps<IFormikValues>) => {
            const { submitForm, isValid, isSubmitting } = formikProps;

            return (
              <>
                <ModalBody>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-12 mt-3">
                        <label>Sponsordienst</label>
                        <Field
                          name={nameOf<IFormikValues>('sponDienstID')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <>
                                <SponsorDienstSelectie
                                  id={field.value}
                                  onChange={(value) => form.setFieldValue(field.name, value)}
                                ></SponsorDienstSelectie>
                              </>
                            );
                          }}
                        />
                      </div>

                      {!isNieuwItem() && (
                        <div className="col-12 mt-3">
                          <label>Commissie</label>
                          <Field
                            name={nameOf<IFormikValues>('commissieID')}
                            render={(fieldProps: FieldProps<IFormikValues>) => {
                              const { field, form } = fieldProps;

                              return (
                                <>
                                  <SponsorCommissieSelectie
                                    id={field.value}
                                    dienstID={form.values?.sponDienstID ?? -1}
                                    onChange={(value) => form.setFieldValue(field.name, value)}
                                  ></SponsorCommissieSelectie>
                                </>
                              );
                            }}
                          />
                        </div>
                      )}

                      <div className="col-12 mt-3">
                        <label>Onderwerp</label>
                        <Field
                          name={nameOf<IFormikValues>('onderwerp')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field } = fieldProps;

                            return (
                              <>
                                <input type="text" className="form-control" {...field} />
                              </>
                            );
                          }}
                        />
                      </div>
                      <div className="col-12 mt-3">
                        <label>Omschrijving</label>
                        <Field
                          name={nameOf<IFormikValues>('omschrijving')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field } = fieldProps;

                            return (
                              <>
                                <textarea {...field} className="form-control" rows={3} />
                              </>
                            );
                          }}
                        />
                      </div>

                      {!isNieuwItem() && (
                        <div className="col-12 mt-3">
                          <label>Notities</label>
                          <Field
                            name={nameOf<IFormikValues>('notities')}
                            render={(fieldProps: FieldProps<IFormikValues>) => {
                              const { field } = fieldProps;

                              return (
                                <>
                                  <textarea {...field} className="form-control" rows={4} />
                                </>
                              );
                            }}
                          />
                        </div>
                      )}

                      <div className="col-12 d-flex mt-3">
                        <div className="col-4">
                          <label>Startdatum</label>
                          <Field
                            name={nameOf<IFormikValues>('startdatum')}
                            render={(fieldProps: FieldProps<IFormikValues>) => {
                              const { field, form } = fieldProps;

                              return (
                                <DatumKiezer
                                  waarde={field.value}
                                  onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                  determinePreviousValidDate={(date) => {
                                    return addDays(date, -1);
                                  }}
                                  determineNextValidDate={(date) => {
                                    return addDays(date, +1);
                                  }}
                                  isClearable
                                ></DatumKiezer>
                              );
                            }}
                          />
                        </div>

                        <div className="col-4 mr-3">
                          <label>Einddatum</label>
                          <Field
                            name={nameOf<IFormikValues>('einddatum')}
                            render={(fieldProps: FieldProps<IFormikValues>) => {
                              const { field, form } = fieldProps;

                              return (
                                <DatumKiezer
                                  waarde={field.value}
                                  onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                  determinePreviousValidDate={(date) => {
                                    return addDays(date, -1);
                                  }}
                                  determineNextValidDate={(date) => {
                                    return addDays(date, +1);
                                  }}
                                  isClearable
                                ></DatumKiezer>
                              );
                            }}
                          />
                        </div>
                      </div>

                      <div className="col-12 mt-3">
                        <Field
                          name={nameOf<IFormikValues>('isDefinitief')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            return (
                              <>
                                <div className="d-flex align-items-center">
                                  <VinkVeld
                                    aangevinkt={field.value}
                                    onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                  />
                                  <span className="ml-2">Definitief</span>
                                </div>
                              </>
                            );
                          }}
                        />
                      </div>

                      {!isNieuwItem() && (
                        <div className="col-12 mt-3">
                          <Field
                            name={nameOf<IFormikValues>('vrijgevenVoorBetalen')}
                            render={(fieldProps: FieldProps<IFormikValues>) => {
                              const { field, form } = fieldProps;

                              return (
                                <>
                                  <div className="d-flex align-items-center">
                                    <VinkVeld
                                      aangevinkt={field.value}
                                      onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                    />
                                    <span className="ml-2">Vrijgeven voor betalen</span>
                                  </div>
                                </>
                              );
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </ModalBody>

                <ModalFooter className="d-flex flex-row justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    style={{ width: 100 }}
                    disabled={!isValid || isSubmitting}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={onAnnuleren}
                    style={{ width: 100 }}
                    disabled={isSubmitting}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Dialoog>
  );
});

export default MuterenDialoog;
