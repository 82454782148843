import React, { useCallback, useMemo, useState, useEffect, useContext } from 'react';
import IDialoogProps from '../../../core/IDialoogProps';
import Dialoog from '../../../components/dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import Modal from 'react-bootstrap/Modal';
import api from '../../../api';
import { Field, FieldProps, Formik, FormikActions, FormikErrors, FormikProps } from 'formik';
import * as Yup from 'yup';
import teksten from '../../../bedrijfslogica/teksten';
import DatumKiezer from '../../../components/formulier/DatumKiezer';
import { format } from 'date-fns';
import { addDays } from 'date-fns/esm';
import FormikVeldFout from '../../../components/formulier/FormikVeldFout';
import RadioKnop from '../../../components/formulier/RadioKnop';
import { RootStoreContext } from '../../../stores/RootStore';
import { EResultType } from '../../../stores/CheckStore';
import LoadingSpinner from '../../../components/Gedeeld/LoadingSpinner';
import { dagDatum } from '../../../helpers/datum';
import VeldWeergave from '../../formulier/VeldWeergave';
import { IOphalenOpdrachtenResultElement } from '../../../../../shared/src/api/v2/transport/opdracht';
import nameOf from '../../../core/nameOf';
import VinkVeld from '../../formulier/VinkVeld';
import { EOpdrachtStatus } from '../../../paginas/Transport/Opdrachten';
import Combobox, { IOptie } from '../../formulier/Combobox';
import { IOphalenOpdrachtEventsVoorToevoegenResult } from '../../../../../shared/src/api/v2/transport/opdracht/uitstaand';

export enum Regelstatus {
  NIET_AFGEMELD = 0,
  UITGEVOERD = 1,
  MISLUKT = 2,
  GEANNULEERD = 3,
}

enum EStatus {
  Mislukt,
  Geslaagd,
}

export interface IDialoogResult {}

interface IProps extends IDialoogProps<IDialoogResult> {
  trsOpdID: number;
  trsRegIDs: number[];
}

interface IFormikValues {
  afmeldDatum: Date | null;
  referentiecode: string | null;
  status: EStatus;
  kopieNaarPlanning: boolean;
  eventID: number | null;
}

const veldnamen: Record<keyof IFormikValues, string | null> = {
  afmeldDatum: 'Afmelddatum',
  referentiecode: 'Referentiecode',
  status: 'Status',
  kopieNaarPlanning: 'Kopie van de regels naar nieuwe opdracht in planning',
  eventID: 'Reden waarom mislukt',
};

const AfmeldenDialoog: React.FC<IProps> = (props) => {
  const { children, dialoogIndex, onAnnuleren, onSuccess, open } = props;
  const { checkStore } = useContext(RootStoreContext);

  const [hasSubmitted, setHasSubmitted] = useState(false);
  // const [trsRegIDVoorReferentie, setTrsRegIDVoorReferentie] = useState<number | null>(null);
  const [afmelddatum, setAfmelddatum] = useState<Date | null>(null);
  const [referentieOpgeven, setReferentieOpgeven] = useState<'J' | 'N' | '' | null>(null);
  const [opdracht, setOpdracht] = useState<IOphalenOpdrachtenResultElement | null>(null);

  useEffect(() => {
    (async () => {
      const opdracht = (
        await api.v2.transport.opdracht.ophalenOpdrachten({
          filterSchema: { filters: [{ naam: 'IDS', data: [props.trsOpdID] }] },
        })
      ).opdrachten[0];

      setOpdracht(opdracht);
    })();
  }, [props.trsOpdID]);

  useEffect(() => {
    (async () => {
      if (opdracht === null) {
        return;
      }

      // Bepaal of er precies 1 levering-regel is. Zo ja dan kan er een referentiecode opgegeven worden

      const regelsResult = await api.v2.transport.opdracht.ophalenOpdrachtregels({
        filterSchema: { filters: [{ naam: 'IDS', data: [props.trsRegIDs] }] },
      });
      const regels = regelsResult.regels;
      // const trsOpdID = regels.map((x) => x.TrsOpdID)[0];

      const datum = opdracht.Bezoekdatum != null ? new Date(opdracht.Bezoekdatum) : new Date();
      setAfmelddatum(dagDatum(datum));

      const regelsLevering = regels
        .filter((x) => x.soort.Code === 'L' && x.type !== null && !x.type.Bulkproduct)
        .map((x) => x.TrsRegID);
      const trsRegID = regelsLevering.length === 1 ? regelsLevering[0] : null;

      // setTrsRegIDVoorReferentie(trsRegID);

      setReferentieOpgeven(
        regelsLevering.length === 1 ? 'J' : regelsLevering.length > 0 ? 'N' : '',
      );
    })();
  }, [props.trsOpdID, props.trsRegIDs, opdracht]);

  const [events, setEvents] = useState<IOphalenOpdrachtEventsVoorToevoegenResult | null>(null);

  useEffect(() => {
    if (events !== null) {
      return;
    }
    (async () => {
      const result = await api.v2.transport.ophalenOpdrachtEventsVoorToevoegen({});
      setEvents(result);
    })();
  }, []);

  const eventOpties = useMemo<IOptie<number>[] | null>(() => {
    if (events === null) {
      return null;
    }

    const opties = events.map((e) => ({
      id: e.ID,
      label: e.Naam,
    }));

    return opties;
  }, [events]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      setHasSubmitted(true);

      actions.setSubmitting(true);

      // Referentiecode geldig?
      if (referentieOpgeven && values.referentiecode !== null) {
        const checkData = await api.v2.transport.opdracht.uitstaand.checkReferentiecodesBijAfmelden(
          { referentiecodes: [values.referentiecode] },
        );
        const checkResult = await checkStore.controleren({
          checkData,
        });
        if (checkResult.type === EResultType.Annuleren) {
          actions.setSubmitting(false);
          return;
        }
      }

      // TrsRegIDs aanvullen met status en eventuele Referentiecode
      const regels = props.trsRegIDs.map((x) => {
        const referentiecode = referentieOpgeven ? values.referentiecode : null;

        return {
          trsRegID: x,
          status: values.status === EStatus.Geslaagd ? Regelstatus.UITGEVOERD : Regelstatus.MISLUKT,
          referentiecode,
        };
      });

      const params = {
        // trsOpdID: props.trsOpdID,
        regels,
        afmelddatum: values.afmeldDatum!,
        markeerAfgehandeld: false,
      };

      const checkData = await api.v2.transport.checkAfmeldenOpdrachtregels(params);
      const checkResult = await checkStore.controleren({
        checkData,
      });
      if (checkResult.type === EResultType.Annuleren) {
        actions.setSubmitting(false);
        return;
      }

      if (
        (
          await checkStore.bevestigen({
            inhoud: (
              <span>
                Opdracht afmelden?
                {referentieOpgeven && values.referentiecode === null && (
                  <span>
                    <br />
                    <br />
                    Er is geen Referentiecode opgegeven, je kunt dit eventueel later nog doen bij
                    'Ontbrekende referenties'.
                  </span>
                )}
              </span>
            ),
          })
        ).type === EResultType.Annuleren
      ) {
        setHasSubmitted(false);
        actions.setSubmitting(false);
        return;
      }

      await api.v2.transport.afmeldenOpdrachtregels(params);

      if (values.kopieNaarPlanning) {
        const opdrachten = (
          await api.v2.transport.opdracht.ophalenOpdrachtenV2({
            filterSchema: {
              filters: [
                { naam: 'LOC_IDS', data: [opdracht!.locatie.LocID] },
                { naam: 'STATUSSEN', data: [EOpdrachtStatus.Planning] },
                { naam: 'BEZOEKDATUM', data: null },
              ],
            },
          })
        ).opdrachten;

        const trsOpdID = opdrachten.length === 1 ? opdrachten[0].TrsOpdID : null;

        await api.v2.transport.opdracht.kopieerenOpdrachtregels({
          trsOpdID,
          trsRegIDs: props.trsRegIDs,
          reserveringMeenemen: false,
        });
      }

      actions.setSubmitting(false);

      setHasSubmitted(false);

      onSuccess({});
    },
    [setHasSubmitted, props.trsRegIDs, referentieOpgeven],
  );

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (afmelddatum === null) {
      return null;
    }

    return {
      afmeldDatum: afmelddatum,
      referentiecode: null,
      status: EStatus.Geslaagd,
      kopieNaarPlanning: false,
      eventID: null,
    };
  }, [afmelddatum]);

  // const validationSchema = useMemo(
  //   () =>
  //     Yup.object().shape({
  //       bezoekDatum: Yup.date().required(
  //         teksten.formulier.E_VERPLICHT_VELD({
  //           veldnaam: veldnamen.afmeldDatum!,
  //         }),
  //       ),
  //       referentiecode: Yup.string().notRequired(),
  //     }),
  //   [],
  // );

  return (
    <Dialoog index={dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Afmelden opdracht</ModalTitle>
      </ModalHeader>
      {initialValues === null || opdracht === null || eventOpties === null ? (
        <div className="d-flex flex-fill justify-content-center align-items-center p-4">
          <LoadingSpinner />
        </div>
      ) : (
        <Formik<IFormikValues>
          isInitialValid
          enableReinitialize
          onSubmit={handleSubmit}
          initialValues={initialValues}
          // validationSchema={validationSchema}
          render={(formikProps: FormikProps<IFormikValues>) => {
            const { submitForm, isSubmitting, isValid, values } = formikProps;

            return (
              <>
                <ModalBody>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-12 d-flex align-items-center">
                        <span className="mr-3">Resultaat</span>
                        <Field
                          name="status"
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <>
                                <div className="d-flex align-items-center">
                                  <span>
                                    <RadioKnop
                                      // className="ml-3"
                                      onAangevinkt={() =>
                                        form.setFieldValue(field.name, EStatus.Geslaagd)
                                      }
                                      aangevinkt={field.value === EStatus.Geslaagd}
                                    />
                                    <span>Geslaagd</span>
                                  </span>
                                  <span className="ml-2">
                                    <RadioKnop
                                      // className="ml-3"
                                      onAangevinkt={() =>
                                        form.setFieldValue(field.name, EStatus.Mislukt)
                                      }
                                      aangevinkt={field.value === EStatus.Mislukt}
                                    />
                                    <span>Mislukt</span>
                                  </span>
                                </div>
                              </>
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <label>{veldnamen.afmeldDatum}</label>
                        <Field
                          name="afmeldDatum"
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            const minDate = addDays(new Date(), -14);
                            const maxDate = addDays(new Date(), +2);

                            return (
                              <DatumKiezer
                                waarde={field.value}
                                onGewijzigd={(x) => {
                                  form.setFieldValue(field.name, x);
                                }}
                                determineValidDate={(date) => {
                                  return date >= minDate && date < maxDate;
                                }}
                                determineNextValidDate={(date) => {
                                  const newDate = addDays(date, +1);
                                  if (newDate < maxDate) {
                                    return newDate;
                                  }
                                  return null;
                                }}
                                determinePreviousValidDate={(date) => {
                                  const newDate = addDays(date, -1);
                                  if (newDate >= minDate) {
                                    return newDate;
                                  }
                                  return null;
                                }}
                              />
                            );
                          }}
                        />
                      </div>

                      {/* {values.status === EStatus.Geslaagd && ( */}
                      <>
                        {referentieOpgeven === 'J' ? (
                          <div className="col-4 mt-3">
                            <label>{veldnamen.referentiecode}</label>
                            <Field
                              name="referentiecode"
                              render={(x: FieldProps<IFormikValues>) => {
                                return (
                                  <>
                                    <input className="form-control" {...x.field} />
                                    <FormikVeldFout fieldProps={x} directTonen={hasSubmitted} />
                                  </>
                                );
                              }}
                            />
                          </div>
                        ) : referentieOpgeven === 'N' ? (
                          <div className="col-12 mt-3">
                            <VeldWeergave>
                              <span>
                                Er kan geen referentiecode opgegeven worden omdat er meer dan één
                                opgegeven moet worden.
                                <br />
                                <br />
                                Het is aan te bevelen om het afmelden niet hier maar op regelniveau
                                uit te voeren.
                              </span>
                            </VeldWeergave>
                          </div>
                        ) : (
                          <span></span>
                        )}
                      </>
                      {/* )} */}

                      {values.status === EStatus.Mislukt && (
                        <>
                          {/* <div className="col-12 mt-3">
                            <label>{veldnamen.eventID}</label>
                            <Field
                              name={nameOf<IFormikValues>('eventID')}
                              render={(fieldProps: FieldProps<IFormikValues>) => {
                                const { field, form } = fieldProps;
                                return (
                                  <Combobox
                                    opties={eventOpties}
                                    geselecteerd={field.value}
                                    legeOptieTonen={false}
                                    onSelectieChange={(id) => form.setFieldValue(field.name, id)}
                                  />
                                );
                              }}
                            />
                          </div> */}

                          <div className="col-12 d-flex align-items-center mt-3">
                            <Field
                              name={nameOf<IFormikValues>('kopieNaarPlanning')}
                              render={(fieldProps: FieldProps<IFormikValues>) => {
                                const { field, form } = fieldProps;

                                return (
                                  <VinkVeld
                                    aangevinkt={field.value}
                                    onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                  />
                                );
                              }}
                            />
                            <span className="ml-2">{veldnamen.kopieNaarPlanning}</span>
                          </div>
                        </>
                      )}

                      {opdracht !== null && opdracht.Notities !== null && (
                        <div className="col-12 mt-3">
                          <span>Notities bij opdracht:</span>
                          <VeldWeergave>
                            <span>{opdracht.Notities}</span>
                          </VeldWeergave>
                        </div>
                      )}
                    </div>
                  </div>
                </ModalBody>
                <ModalFooter className="d-flex flex-row justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    style={{ width: 100 }}
                    disabled={!isValid || isSubmitting}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={onAnnuleren}
                    style={{ width: 100 }}
                    disabled={isSubmitting}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Dialoog>
  );
};

export default AfmeldenDialoog;
