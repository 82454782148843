import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { Kleur } from '../../../../../bedrijfslogica/constanten';
import { IconInbox, IconInklappen, IconUitklappen } from '../../../../Icons';
import { IOphalenEmailAccountsResultElement } from '../../../../../../../shared/src/api/v2/dienst/email/email';
import { useEmailWerkbladV2Store } from '../../store';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../../models/IRemoteData';
import { IMailMap } from '../../../../../../../shared/src/api/v2/email-v2';
import LoadingSpinner from '../../../../Gedeeld/LoadingSpinner';
import { UitgeklaptMappenState } from '../index';
import Map from '../Map';

const Root = styled.div`
  display: flex;
  flex-direction: column;
`;

const Vlak = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px 15px;
  border-bottom: 1px solid ${Kleur.HeelLichtGrijs};

  &:hover {
    background-color: ${Kleur.HeelLichtGrijs};
  }
`;

const UitgeklaptVlak = styled.div`
  display: flex;
  flex-direction: column;
  border-left: 30px solid ${Kleur.LichtGrijs};
  border-bottom: 1px solid ${Kleur.HeelLichtGrijs};
`;

interface IProps {
  account: IOphalenEmailAccountsResultElement;
  isUitgeklapt: boolean;
  onUitgeklaptChange: (uitgeklapt: boolean) => void;
  mailMapID: number | null;
  onMailMapIDChange: (mailMapID: number | null) => void;
  uitgeklaptMappenState: UitgeklaptMappenState;
  onUitgeklaptMappenStateChange: (uitgeklaptState: UitgeklaptMappenState) => void;
}

const Account = (props: IProps) => {
  const emailWerkbladV2Store = useEmailWerkbladV2Store();

  const mailMappen = useMemo<IRemoteData<IMailMap[]>>(() => {
    if (emailWerkbladV2Store.mailMappenBijIndex.state === ERemoteDataState.Pending) {
      return createPendingRemoteData();
    }
    return createReadyRemoteData(
      Object.values(
        emailWerkbladV2Store.mailMappenBijIndex.data! as Record<number, IMailMap>,
      ).filter((map) => map.EmailGebrID === props.account.EmailGebrID),
    );
  }, [emailWerkbladV2Store.mailMappenBijIndex, props.account]);

  const recursiefMappenRendereren = useCallback(
    (mappen: IMailMap[]) => {
      return (
        <UitgeklaptVlak>
          {mappen.map((map) => {
            const isUitgeklapt = props.uitgeklaptMappenState[map.ID];
            const onderliggendeMappen = mailMappen.data!.filter(
              (x) => x.Bovenliggende_MailMapID === map.ID,
            );
            const kanUitklappen = onderliggendeMappen.length > 0;

            return (
              <>
                <Map
                  key={map.ID}
                  naam={map.Weergavenaam}
                  icon={IconInbox}
                  isActief={props.mailMapID === map.ID}
                  onClick={() => props.onMailMapIDChange(map.ID)}
                  isUitgeklapt={isUitgeklapt}
                  onUitgeklaptChange={(uitgeklapt) =>
                    props.onUitgeklaptMappenStateChange({
                      ...props.uitgeklaptMappenState,
                      [map.ID]: uitgeklapt,
                    })
                  }
                  kanUitklappen={kanUitklappen}
                />
                {isUitgeklapt && recursiefMappenRendereren(onderliggendeMappen)}
              </>
            );
          })}
        </UitgeklaptVlak>
      );
    },
    [
      mailMappen,
      props.uitgeklaptMappenState,
      props.onUitgeklaptMappenStateChange,
      props.mailMapID,
      props.onMailMapIDChange,
    ],
  );

  return (
    <Root>
      <Vlak
        onClick={(ev) => {
          ev.stopPropagation();
          props.onUitgeklaptChange(!props.isUitgeklapt);
        }}
      >
        <span style={{ marginLeft: 12, fontSize: 14, flex: 1 }}>
          {props.account.WeergaveNaam ?? props.account.GebruikersNaam}
        </span>
        <button
          style={{
            border: 'none',
            backgroundColor: 'transparent',
            outline: 0,
          }}
        >
          {props.isUitgeklapt ? (
            <IconInklappen
              style={{ fill: Kleur.Grijs, width: 23, height: 23, position: 'relative' }}
            />
          ) : (
            <IconUitklappen
              style={{ fill: Kleur.Grijs, width: 23, height: 23, position: 'relative' }}
            />
          )}
        </button>
      </Vlak>

      {props.isUitgeklapt && (
        <>
          {mailMappen.state === ERemoteDataState.Pending ? (
            <LoadingSpinner />
          ) : mailMappen.data!.length === 0 ? (
            <span>Account heeft geen mail mappen.</span>
          ) : (
            recursiefMappenRendereren(
              mailMappen.data!.filter((x) => x.Bovenliggende_MailMapID === null),
            )
          )}
        </>
      )}
    </Root>
  );
};

export default Account;
