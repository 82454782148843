import React from 'react';
import { IOphalenCommunicatieItemsResultElement } from '../../../../../../../../../shared/src/api/v2/communicatie';

interface ICommunicatieItemSmsData {
  ID: number;
  SmsDienstID: number | null;
  SmsContact_ID: number | null;
  ExterneReferentie: string | null;
  Datum: Date | string | null;
  Richting: 'I' | 'U';
  Telefoon: string | null;
  Afzender: string | null;
  Inhoud: string | null;
  AspGebrID: number | null;
  BerCtxID: number | null;
  RelID: number | null;
  PersID: number | null;
}

interface IProps {
  item: IOphalenCommunicatieItemsResultElement;
  data: ICommunicatieItemSmsData;
}

const SmsRowDetail = (props: IProps) => {
  return <div className="d-flex flex-fill flex-column pt-2">{props.data.Inhoud}</div>;
};

export default SmsRowDetail;
