import React from 'react';
import styled from 'styled-components';
import Skeleton from 'react-loading-skeleton';

const Root = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

interface IProps {}

const Placeholder = (props: IProps) => {
  return (
    <Root>
      <Skeleton />
    </Root>
  );
};

export default Placeholder;
