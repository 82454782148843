import React, { useEffect, useMemo } from 'react';
import ReactDOM from 'react-dom';

const portalRoot = document.getElementById('portal')!;

interface IProps {}

const Portal: React.FC<IProps> = (props) => {
  const element = useMemo(() => document.createElement('div'), []);

  useEffect(() => {
    portalRoot.appendChild(element);
    return () => portalRoot.removeChild(element) as any;
  }, []);

  return ReactDOM.createPortal(props.children, element);
};

export default Portal;
