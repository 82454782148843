import { DataTypeProvider, Filter } from '@devexpress/dx-react-grid';
import { Grid, Table, TableHeaderRow } from '@devexpress/dx-react-grid-bootstrap4';
import { IProps } from '@tinymce/tinymce-react/lib/cjs/main/ts/components/Editor';
import { format } from 'date-fns';
import React, { useCallback, useContext, useState, useMemo, useEffect } from 'react';
import { BetalingsregelingContext, IContext } from '..';
import { IOphalenBoekingRegelsResultElement } from '../../../../../../shared/src/api/v2/boekhouding/boeking';
import api from '../../../../api';
import { EDagboeksoort } from '../../../../bedrijfslogica/enums';
import nameOf from '../../../../core/nameOf';
import {
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import FactuurVisualisatie from '../../../entiteitVisualisaties/FactuurVisualisatie';
import InkoopfactuurVisualisatie from '../../../entiteitVisualisaties/InkoopfactuurVisualisatie';
import VeldWeergave from '../../../formulier/VeldWeergave';
import LoadingSpinner from '../../../Gedeeld/LoadingSpinner';
import FormatteerBedrag from '../../../MutatieBedrag';
import FilterBalkV2, { IFilter, IFilterData, maakFilterSchema } from '../../../FilterBalkV2';

type FilterType = 'BETRGL_IDS';

const BoekingTab: React.FC<IProps> = (props) => {
  const { betalingsregeling } = useContext<IContext>(BetalingsregelingContext);

  const [filterData, setFilterData] = useState<IFilterData<FilterType>[]>([
    {
      naam: 'BETRGL_IDS',
      data: [betalingsregeling!.ID],
      isActief: true,
    },
  ]);
  const filters = useMemo<IFilter<FilterType>[]>(
    () => [
      {
        naam: 'BETRGL_IDS',
        altijdWeergevenInBalk: true,
        weergave: () => <span>Alleen van betalingsregeling</span>,
      },
    ],
    [],
  );

  const [boekingregels, setBoekingregels] = useState<IOphalenBoekingRegelsResultElement[] | null>(
    null,
  );

  const ophalenBoekingregels = useCallback(async () => {
    const filterSchema = maakFilterSchema(filterData);

    const regelsResult = (
      await api.v2.boeking.ophalenBoekingregels({
        filterSchema: {
          filters: [
            ...(filterSchema.filters ?? []),
            {
              naam: 'REL_IDS',
              data: [betalingsregeling!.RelID],
            },
            {
              naam: 'DAGBOEK_SOORT_NAAMENUMS',
              data: [EDagboeksoort.BANK, EDagboeksoort.MEMORIAAL],
            },
          ],
        },
        orderSchema: {
          orders: [
            { naam: 'BOEKDATUM', richting: 'DESC' },
            { naam: 'BOEKNUMMER', richting: 'DESC' },
            { naam: 'REGELNUMMER', richting: 'DESC' },
          ],
        },
      })
    ).regels;

    setBoekingregels(regelsResult);
  }, [betalingsregeling, JSON.stringify(filterData)]);

  useEffect(() => {
    ophalenBoekingregels();
  }, [ophalenBoekingregels]);

  const keyExtractor = useCallback((row: IOphalenBoekingRegelsResultElement) => row.ID, []);

  const kolommen = useMemo<TypedColumn<IOphalenBoekingRegelsResultElement>[]>(
    () => [
      {
        name: '__datum' as any,
        title: 'Datum',
      },
      {
        name: '__dbRekening' as any,
        title: 'DB',
      },
      {
        name: 'Bedrag',
        title: 'Bedrag',
      },
      {
        name: '__crRekening' as any,
        title: 'CR',
      },
      {
        name: '__factuur' as any,
        title: 'Factuur',
      },
      // {
      //   name: '__bankopdracht' as any,
      //   title: 'Bankopdracht',
      // },
      {
        name: 'Omschrijving',
        title: 'Omschrijving',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IOphalenBoekingRegelsResultElement>[]>(
    () => [
      {
        columnName: '__datum' as any,
        width: 100,
      },
      {
        columnName: 'Bedrag',
        width: 100,
      },
      {
        columnName: '__dbRekening' as any,
        width: 160,
      },
      {
        columnName: '__crRekening' as any,
        width: 160,
      },
      {
        columnName: '__factuur' as any,
        width: 110,
      },
      {
        columnName: '__id' as any,
        width: 100,
      },
      {
        columnName: '__bankopdracht' as any,
        width: 150,
      },
      {
        columnName: 'Omschrijving',
        width: 100,
      },
    ],
    [],
  );

  if (boekingregels === null) {
    return (
      <div className="flex-fill d-flex align-items-center justify-content-center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <>
      <div className="d-flex flex-column">
        <div className="p-2">
          <FilterBalkV2
            filters={filters}
            filterData={filterData}
            onFilterDataChange={setFilterData}
          />
        </div>
        <GridStyleWrapper rowAmount={boekingregels.length} maxHeight={650}>
          <Grid rows={boekingregels} columns={kolommen} getRowId={keyExtractor}>
            <DataTypeProvider
              for={['__datum']}
              formatterComponent={(formatterProps) => {
                const rij: IOphalenBoekingRegelsResultElement = formatterProps.row;
                return <span>{format(new Date(rij.boeking.Boekdatum), 'dd-MM-yyyy')}</span>;
              }}
            />

            <DataTypeProvider
              for={[nameOf('Bedrag')]}
              formatterComponent={(formatterProps) => {
                const rij: IOphalenBoekingRegelsResultElement = formatterProps.row;
                return <FormatteerBedrag bedrag={rij.Bedrag} />;
              }}
            />

            <DataTypeProvider
              for={['__dbRekening']}
              formatterComponent={(formatterProps) => {
                const rij: IOphalenBoekingRegelsResultElement = formatterProps.row;
                return (
                  <span>
                    {rij.dbRekening.Nummer} - {rij.dbRekening.Naam}
                  </span>
                );
              }}
            />

            <DataTypeProvider
              for={['__crRekening']}
              formatterComponent={(formatterProps) => {
                const rij: IOphalenBoekingRegelsResultElement = formatterProps.row;

                return (
                  <span>
                    {rij.crRekening.Nummer} - {rij.crRekening.Naam}
                  </span>
                );
              }}
            />

            <DataTypeProvider
              for={['__factuur']}
              formatterComponent={(formatterProps) => {
                const rij: IOphalenBoekingRegelsResultElement = formatterProps.row;

                if (rij.FactID !== null) {
                  return <FactuurVisualisatie factID={rij.FactID} />;
                }
                if (rij.InkFactID !== null) {
                  return <InkoopfactuurVisualisatie inkFactID={rij.InkFactID} />;
                }
                return <span></span>;
              }}
            />

            <Table columnExtensions={kolomBreedtes} />

            {/* <VirtualTable
            messages={{
              noData: 'Geen documenten gevonden',
            }}
          /> */}
            {/* <TableColumnResizing defaultColumnWidths={kolomBreedtes} /> */}
            <TableHeaderRow />
          </Grid>
        </GridStyleWrapper>
      </div>
    </>
  );
};

export default BoekingTab;
