import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import FilterBalkV2, {
  IFilter,
  IFilterData,
  maakFilterSchema,
} from '../../../../components/FilterBalkV2';
import { RootStoreContext } from '../../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import MenuLayout from '../../../../components/MenuLayout';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import MultiSelect from '../../../../components/formulier/MultiSelect';
import useUrlState from '../../../../core/useUrlState';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import { IFilterSchema } from '../../../../../../shared/src/models/filter';
import { RouteComponentProps, withRouter } from 'react-router';
import { format } from 'date-fns';
import {
  IOphalenMutatiebronnenResult,
  IOphalenSysteemomgevingenResult,
} from '../../../../../../shared/src/api/v2/applicatie';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../models/IRemoteData';
import api, { IPaginatiePositie } from '../../../../api';
import ASPTabel from '../../../../components/tabel/ASPTabel';
import {
  ASPKolom,
  EAspKolomBreedteType,
  ESortering,
  IAspKolomSorteringItem,
} from '../../../../components/tabel/ASPTabel/types';
import UitgeklapteRij from './UitgeklapteRij';
import { IOrderSchemaOrder } from '../../../../../../shared/src/models/order';
import DatumKiezer from '../../../../components/formulier/DatumKiezer';
import { dagDatum } from '../../../../helpers/datum';
import { Helmet } from 'react-helmet';
import { EMutatiebron } from '../../../../bedrijfslogica/enums';
import MutatiebronVisualisatie from '../../../../components/MutatiebronVisualisatie';

interface IProps extends RouteComponentProps {}

export interface IRow {
  ID: number;
  Niveau: string;
  Mutatiebron: number;
  Datum: Date;
  Bericht: string;
  CodeRef: string | null;
  StackTrace: string | null;
  AspGebrID: number | null;
  AspGebruikersnaam: string | null;
  SysOmgID: number | null;
  SysOmgNaam: string | null;
  Data: string | null;
}

enum EFilter {
  FoutNiveau = 'FOUT_NIVEAU',
  BerichtBevat = 'BERICHT_BEVAT',
  Mutatiebronnen = 'MUTATIEBRONNEN',
  SysOmgIDs = 'SYS_OMG_IDS',
  DatumVanaf = 'DATUM_VANAF',
  DatumTem = 'DATUM_TEM',
}

export enum EKolom {
  Datum,
  SysOmgNaam,
  Mutatiebron,
  Niveau,
  AspGebruikersnaam,
  Bericht,
}

interface IBerichtBevatState {
  moetNogToepassen: boolean;
}

interface IUrlState {
  selection: number[];
  expanded: number[];
  filterDatas: IFilterData<EFilter>[];
  uitgeklapt: number[];
  sortering: IAspKolomSorteringItem<EKolom>[];
}

const defaultUrlState: IUrlState = {
  selection: [],
  expanded: [],
  uitgeklapt: [],
  filterDatas: [
    {
      naam: EFilter.FoutNiveau,
      isActief: true,
      data: ['error', 'warn'],
    },
    {
      naam: EFilter.BerichtBevat,
      isActief: false,
      data: '',
      state: {
        moetNogToepassen: false,
      } as IBerichtBevatState,
    },
    {
      naam: EFilter.Mutatiebronnen,
      isActief: false,
      data: [],
    },
    {
      naam: EFilter.SysOmgIDs,
      isActief: false,
      data: [],
    },
    {
      naam: EFilter.DatumVanaf,
      isActief: true,
      data: dagDatum(new Date()),
    },
    {
      naam: EFilter.DatumTem,
      isActief: true,
      data: dagDatum(new Date()),
    },
  ],
  sortering: [
    {
      key: EKolom.Datum,
      sortering: ESortering.Descending,
    },
  ],
};

const niveauKleurMap: Record<string, string> = {
  error: Kleur.Rood,
  warn: Kleur.DonkerGeel,
  info: Kleur.Groen,
  trace: Kleur.Blauw,
  debug: Kleur.Zwart,
};

interface IData {
  logs: Record<number, IRow>;
  totaalAantal: number;
}

const Logs: React.FC<IProps> = observer((props) => {
  const { technischStore } = useContext(RootStoreContext);
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const [filterSchema, setFilterSchema] = useState<IFilterSchema>(
    maakFilterSchema(urlState.filterDatas),
  );

  const [systeemsomgevingenResult, setSysteemomgevingenResult] = useState<
    IRemoteData<IOphalenSysteemomgevingenResult>
  >(createPendingRemoteData());
  const ophalenSysteemomgevingen = useCallback(async () => {
    const result = await api.v2.applicatie.ophalenSysteemomgevingen({});
    setSysteemomgevingenResult(createReadyRemoteData(result));
  }, []);
  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    ophalenSysteemomgevingen();
  }, [ophalenSysteemomgevingen]);

  const [mutatiebronnenResult, setMutatiebronnenResult] = useState<
    IRemoteData<IOphalenMutatiebronnenResult>
  >(createPendingRemoteData());
  const ophalenMutatiebronnen = useCallback(async () => {
    const result = await api.v2.applicatie.ophalenMutatiebronnen({});
    setMutatiebronnenResult(createReadyRemoteData(result));
  }, []);
  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    ophalenMutatiebronnen();
  }, [ophalenMutatiebronnen]);

  const [data, setData] = useState<IRemoteData<IData>>(createPendingRemoteData());
  const bepaalData = useCallback(
    async (paginatie: IPaginatiePositie, uitbreiden = false) => {
      const result = await api.v2.technisch.ophalenApiLogs({
        filterSchema,
        paginatie,
        orderSchema: {
          orders: urlState.sortering
            .map((item) => {
              switch (item.key) {
                case EKolom.Datum:
                  return {
                    naam: 'DATUM',
                    richting: item.sortering,
                  };
                case EKolom.SysOmgNaam:
                  return {
                    naam: 'SYS_OMG_NAAM',
                    richting: item.sortering,
                  };
                case EKolom.Mutatiebron:
                  return {
                    naam: 'MUTATIEBRON',
                    richting: item.sortering,
                  };
                case EKolom.Niveau:
                  return {
                    naam: 'NIVEAU',
                    richting: item.sortering,
                  };
                case EKolom.AspGebruikersnaam:
                  return {
                    naam: 'ASP_GEBRUIKERSNAAM',
                    richting: item.sortering,
                  };
                case EKolom.Bericht:
                  return {
                    naam: 'BERICHT',
                    richting: item.sortering,
                  };
                default:
                  return null;
              }
            })
            .filter((x) => x !== null) as IOrderSchemaOrder[],
        },
      });
      setData((curr) => {
        const base = uitbreiden ? curr.data?.logs ?? {} : {};
        return createReadyRemoteData({
          totaalAantal: result.totaalAantal,
          logs: result.logs.reduce(
            (acc, log, i) => ({
              ...acc,
              [i + paginatie.index]: {
                ...log,
                Datum: new Date(log.Datum),
              },
            }),
            base,
          ),
        });
      });
    },
    [setData, filterSchema, JSON.stringify(urlState.sortering)],
  );

  useEffect(() => {
    // Initieel en als het filterschema/orderschema verandert
    bepaalData({ index: 0, aantal: 50 });
  }, [bepaalData]);

  const keyExtractor = useCallback((row: IRow) => row.ID, []);

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.FoutNiveau,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return (
            <span className="d-flex align-items-center">
              Alleen deze niveaus weergeven
              <div className="ml-2">
                <MultiSelect
                  value={weergaveProps.data}
                  onChange={(x) => {
                    weergaveProps.onDataChange(x);
                    weergaveProps.toepassen();
                  }}
                  isMulti
                  isVerwijderbaar={false}
                  opties={[
                    {
                      key: 'error',
                      weergave: 'Error',
                    },
                    {
                      key: 'warn',
                      weergave: 'Warning',
                    },
                    {
                      key: 'info',
                      weergave: 'Info',
                    },
                    {
                      key: 'debug',
                      weergave: 'Debug',
                    },
                    {
                      key: 'trace',
                      weergave: 'Trace',
                    },
                  ]}
                />
              </div>
            </span>
          );
        },
      },
      {
        naam: EFilter.BerichtBevat,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          const state: IBerichtBevatState = weergaveProps.state;
          return (
            <span className="d-flex align-items-center">
              Bericht bevat
              <div className="ml-2">
                <input
                  className="form-control input-sm"
                  value={weergaveProps.data}
                  onChange={(ev) => {
                    weergaveProps.onDataChange(ev.target.value);
                    weergaveProps.onStateChange({ moetNogToepassen: true } as IBerichtBevatState);
                  }}
                  onKeyUp={(ev) => {
                    // Enter?
                    if (ev.keyCode === 13) {
                      weergaveProps.toepassen();
                      weergaveProps.onStateChange({
                        moetNogToepassen: false,
                      } as IBerichtBevatState);
                    }
                  }}
                />
              </div>
              {state.moetNogToepassen && (
                <button
                  className="ml-2 btn btn-sm btn-light"
                  style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                  onClick={() => {
                    weergaveProps.toepassen();
                    weergaveProps.onStateChange({
                      moetNogToepassen: false,
                    } as IBerichtBevatState);
                  }}
                >
                  Toepassen
                </button>
              )}
            </span>
          );
        },
      },
      {
        naam: EFilter.Mutatiebronnen,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return (
            <span className="d-flex align-items-center">
              Bronnen
              <span className="ml-2">
                {mutatiebronnenResult.state === ERemoteDataState.Pending ? (
                  <LoadingSpinner />
                ) : (
                  <MultiSelect
                    value={weergaveProps.data}
                    onChange={(x) => {
                      weergaveProps.onDataChange(x);
                      weergaveProps.toepassen();
                    }}
                    isMulti
                    isVerwijderbaar={false}
                    opties={mutatiebronnenResult.data!.mutatiebronnen.map((mutatiebron) => ({
                      key: mutatiebron.Mutatiebron,
                      weergave: mutatiebron.Naam,
                    }))}
                  />
                )}
              </span>
            </span>
          );
        },
      },
      {
        naam: EFilter.SysOmgIDs,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return (
            <span className="d-flex align-items-center">
              Omgevingen
              <span className="ml-2">
                {systeemsomgevingenResult.state === ERemoteDataState.Pending ? (
                  <LoadingSpinner />
                ) : (
                  <MultiSelect
                    value={weergaveProps.data}
                    onChange={(x) => {
                      weergaveProps.onDataChange(x);
                      weergaveProps.toepassen();
                    }}
                    isMulti
                    isVerwijderbaar={false}
                    opties={systeemsomgevingenResult.data!.systeemomgevingen.map(
                      (systeemomgeving) => ({
                        key: systeemomgeving.ID,
                        weergave: systeemomgeving.Naam,
                      }),
                    )}
                  />
                )}
              </span>
            </span>
          );
        },
      },
      {
        naam: EFilter.DatumVanaf,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return (
            <span className="d-flex align-items-center">
              <span className="mr-2">Datum vanaf</span>
              <DatumKiezer
                waarde={weergaveProps.data === null ? null : new Date(weergaveProps.data)}
                onGewijzigd={(x) => {
                  weergaveProps.onDataChange(x === null ? null : x.toISOString());
                  weergaveProps.setIsActief(true);
                  weergaveProps.toepassen();
                }}
                determineValidDate={() => {
                  return true;
                }}
                determinePreviousValidDate="ONBEGRENST"
                determineNextValidDate="ONBEGRENST"
              />
            </span>
          );
        },
      },
      {
        naam: EFilter.DatumTem,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return (
            <span className="d-flex align-items-center">
              <span className="mr-2">Datum t/m</span>
              <DatumKiezer
                waarde={weergaveProps.data === null ? null : new Date(weergaveProps.data)}
                onGewijzigd={(x) => {
                  weergaveProps.onDataChange(x === null ? null : x.toISOString());
                  weergaveProps.setIsActief(true);
                  weergaveProps.toepassen();
                }}
                determineValidDate={() => {
                  return true;
                }}
                determinePreviousValidDate="ONBEGRENST"
                determineNextValidDate="ONBEGRENST"
              />
            </span>
          );
        },
      },
    ],
    [mutatiebronnenResult, systeemsomgevingenResult],
  );

  const kolommen = useMemo<ASPKolom<EKolom, IRow>[]>(
    () => [
      {
        key: EKolom.Datum,
        label: 'Datum',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 200,
        renderer: (item: IRow) => {
          return format(item.Datum, 'dd-MM-yyyy HH:mm:SSS');
        },
      },
      {
        key: EKolom.SysOmgNaam,
        label: 'Omgeving',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 200,
        renderer: (item: IRow) => {
          return item.SysOmgNaam;
        },
      },
      {
        key: EKolom.Mutatiebron,
        label: 'Bron',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 110,
        renderer: (item: IRow) => {
          return <MutatiebronVisualisatie mutatiebron={item.Mutatiebron as EMutatiebron} />;
        },
      },
      {
        key: EKolom.Niveau,
        label: 'Niveau',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 150,
        renderer: (item: IRow) => {
          const kleur = niveauKleurMap[item.Niveau];
          return (
            <span style={{ color: kleur, fontSize: '.9rem' }}>{item.Niveau.toUpperCase()}</span>
          );
        },
      },
      {
        key: EKolom.AspGebruikersnaam,
        label: 'Gebruiker',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 175,
        renderer: (item: IRow) => item.AspGebruikersnaam,
      },
      {
        key: EKolom.Bericht,
        label: 'Bericht',
        breedteType: EAspKolomBreedteType.Flex,
        flex: 1,
        renderer: (item: IRow) => (
          <span
            style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
            title={item.Bericht}
          >
            {item.Bericht}
          </span>
        ),
      },
    ],
    [],
  );

  const handleExtraRijenAangevraagd = useCallback(
    async (positie: IPaginatiePositie) => {
      await bepaalData(positie, true);
    },
    [bepaalData],
  );

  return (
    <>
      <Helmet>
        <title>Logs</title>
      </Helmet>
      <MenuLayout
        menu={
          <div>
            <FilterBalkV2
              filters={filters}
              filterData={urlState.filterDatas}
              onFilterDataChange={(datas) => setUrlStateSync('filterDatas', datas)}
              onFilterSchemaChange={(schema) => setFilterSchema(schema)}
            />
          </div>
        }
        body={
          <div className="flex-fill d-flex" style={{ height: '100%', backgroundColor: Kleur.Wit }}>
            {data.state === ERemoteDataState.Pending ? (
              <div className="flex-fill d-flex align-items-center justify-content-center">
                <LoadingSpinner />
              </div>
            ) : (
              <ASPTabel
                rijen={data.data!.logs}
                kolommen={kolommen}
                keyExtractor={keyExtractor}
                uitgeklapt={urlState.uitgeklapt}
                onUitgeklaptChange={(x) => setUrlStateSync('uitgeklapt', x)}
                uitgeklapteRijComponent={UitgeklapteRij}
                uitgeklapteRijHoogte={500}
                onExtraRijenAangevraagd={handleExtraRijenAangevraagd}
                totaalAantalRijen={data.data!.totaalAantal}
                sortering={urlState.sortering}
                onSorteringChange={(x) => setUrlStateSync('sortering', x)}
                leegComponent={(leegProps) => (
                  <div
                    style={{ ...leegProps }}
                    className="d-flex align-items-center justify-content-center"
                  >
                    <h3>Geen logs gevonden voor de ingestelde filters</h3>
                  </div>
                )}
              />
            )}
          </div>
        }
      />
    </>
  );
});

export default withRouter(Logs);
