import {
  IInitToevoegenLeveropdrachtParams,
  IInitToevoegenLeveropdrachtResult,
  IToevoegenLeveropdrachtParams,
  IToevoegenLeveropdrachtResult,
  IOphalenProducttypenParams,
  OphalenProducttypenResult,
} from '../../../../../../shared/src/api/v2/transport/opdracht/levering';
import ICheckData from '../../../../../../shared/src/models/ICheckData';
import api from '../../../index';

const levering = {
  initToevoegenLeveropdracht: async (
    params: IInitToevoegenLeveropdrachtParams,
  ): Promise<IInitToevoegenLeveropdrachtResult> => {
    return await api.post('v2/transport/opdracht/levering/init-toevoegen-leveropdracht', params);
  },
  toevoegenLeveropdracht: async (
    params: IToevoegenLeveropdrachtParams,
  ): Promise<IToevoegenLeveropdrachtResult> => {
    return await api.post('v2/transport/opdracht/levering/toevoegen-leveropdracht', params);
  },
  ophalenProducttypen: async (
    params: IOphalenProducttypenParams,
  ): Promise<OphalenProducttypenResult> => {
    return await api.post('v2/transport/opdracht/levering/ophalen-producttypen', params);
  },
};

export default levering;
