import api from '..';
import {
  IOphalenPostcodeDataParams,
  IOphalenPostcodeDataResult,
} from '../../../../shared/src/api/v2/dienst/postcodedata';
import {
  IOphalenHuisnummerToevoegingenParams,
  IOphalenHuisnummerToevoegingenResult,
} from '../../../../shared/src/api/v2/land';

const adres = {
  ophalenPostcodedata: async (params: IOphalenPostcodeDataParams) => {
    return await api.post<IOphalenPostcodeDataResult>(
      '/v2/dienst/postcodedata/ophalen-postcode-data',
      params,
    );
  },
  ophalenHuisnummerToevoegingen: async (params: IOphalenHuisnummerToevoegingenParams) => {
    return await api.post<IOphalenHuisnummerToevoegingenResult>(
      '/v2/adres/ophalen-huisnummer-toevoegingen',
      params,
    );
  },
};

export default adres;
