import React, { useContext, useEffect, useMemo } from 'react';
import { RouteComponentProps } from 'react-router';
import { RootStoreContext } from '../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import useUrlState from '../../../core/useUrlState';
import { Helmet } from 'react-helmet';
import EmailWerkbladV2 from '../../../components/communicatie/EmailWerkbladV2';

export interface IUrlState {
  emailID: number | null;
  mailMapID: number | null;
  mappenSectieWeergeven: boolean;
  accountsSectieUitgeklaptState: Record<number, boolean>;
  mappenSectieUitgeklaptState: Record<number, boolean>;
}

const defaultUrlState: IUrlState = {
  emailID: null,
  mailMapID: null,
  mappenSectieWeergeven: true,
  accountsSectieUitgeklaptState: {},
  mappenSectieUitgeklaptState: {},
};

interface IProps extends RouteComponentProps<{}> {}

const Email: React.FC<IProps> = observer((props) => {
  const { emailStore } = useContext(RootStoreContext);
  useEffect(() => {
    if (emailStore.emailAccounts !== null) {
      return;
    }
    emailStore.ophalenEmailAccounts();
  }, []);

  const [urlState, setUrlState, setUrlStateSync] = useUrlState<IUrlState>(props, defaultUrlState);

  useEffect(() => {
    if (emailStore.emails !== null) {
      return;
    }
    emailStore.ophalenEmails();
  }, []);

  // const emails = useMemo<EmailsData>(() => {
  //   if (emailStore.emails === null) {
  //     return {};
  //   }
  //
  //   return emailStore.emails.reduce((acc: EmailsData, curr) => {
  //     return {
  //       ...acc,
  //       [curr.EmailGebrID]: [...(acc[curr.EmailGebrID] || []), curr],
  //     };
  //   }, {});
  // }, [emailStore.emails]);

  return (
    <>
      <Helmet>
        <title>Email</title>
      </Helmet>
      <EmailWerkbladV2
        emailBerID={urlState.emailID}
        onEmailBerIDChange={(emailID) => setUrlStateSync('emailID', emailID)}
        mailMapID={urlState.mailMapID}
        onMailMapIDChange={(mailMapID) => setUrlStateSync('mailMapID', mailMapID)}
        accountsSectieUitgeklaptState={urlState.accountsSectieUitgeklaptState}
        onAccountsSectieUitgeklaptStateChange={(accountsSectieUitgeklaptState) =>
          setUrlStateSync('accountsSectieUitgeklaptState', accountsSectieUitgeklaptState)
        }
        mappenSectieUitgeklaptState={urlState.mappenSectieUitgeklaptState}
        onMappenSectieUitgeklaptStateChange={(mappenSectieUitgeklaptState) =>
          setUrlStateSync('mappenSectieUitgeklaptState', mappenSectieUitgeklaptState)
        }
        mappenSectieWeergeven={urlState.mappenSectieWeergeven}
        onMappenSectieWeergevenChange={(mappenSectieWeergeven) =>
          setUrlStateSync('mappenSectieWeergeven', mappenSectieWeergeven)
        }
      />
      {/*<Root>*/}
      {/*  <EmailWerkblad*/}
      {/*    emails={emails}*/}
      {/*    emailAccounts={emailStore.emailAccounts}*/}
      {/*    showMailboxesSection={urlState.showMailboxesSection}*/}
      {/*    onShowMailboxesSectionChange={(value) => {*/}
      {/*      setUrlStateSync('showMailboxesSection', value);*/}
      {/*    }}*/}
      {/*    selectedEmailID={urlState.emailID}*/}
      {/*    onSelectedEmailIDChange={(value) => {*/}
      {/*      setUrlStateSync('emailID', value);*/}
      {/*    }}*/}
      {/*    showOpstellenFormulier={urlState.showOpstellenFormulier}*/}
      {/*    onShowOpstellenFormulierChange={(value) => {*/}
      {/*      setUrlStateSync('showOpstellenFormulier', value);*/}
      {/*    }}*/}
      {/*    activeMailbox={urlState.activeMailbox}*/}
      {/*    onActiveMailboxChange={(value) => {*/}
      {/*      setUrlStateSync('activeMailbox', value);*/}
      {/*    }}*/}
      {/*    mailboxesState={urlState.mailboxesState}*/}
      {/*    onMailboxesStateChange={(value) => {*/}
      {/*      setUrlStateSync('mailboxesState', value);*/}
      {/*    }}*/}
      {/*    opstellenFormulierDefaultFromEmailGebrID={urlState.defaultFromEmailGebrID}*/}
      {/*    opstellenFormulierDefaultBody={urlState.defaultBody || ''}*/}
      {/*    opstellenFormulierDefaultRecipients={urlState.defaultRecipients || []}*/}
      {/*    opstellenFormulierDefaultSubject={urlState.defaultSubject || ''}*/}
      {/*    opstellenFormulierDefaultCC={urlState.defaultCC || []}*/}
      {/*    opstellenFormulierDefaultBCC={urlState.defaultBCC || []}*/}
      {/*    opstellenFormulierDefaultBijlagen={[]}*/}
      {/*    opstellenFormulierDefaultSjabBasisID={urlState.defaultSjabBasisID}*/}
      {/*    opstellenFormulierDefaultTaalID={urlState.defaultTaalID}*/}
      {/*    opstellenFormulierDefaultContextRelIDs={urlState.defaultContextRelIDs || []}*/}
      {/*    opstellenFormulierDefaultUitstelling={urlState.defaultUitstelling}*/}
      {/*  />*/}
      {/*</Root>*/}
    </>
  );
});

export default Email;
