import React, { useContext, useMemo } from 'react';
import Avatar from '../../../../components/personalia/Avatar';
import { IOphalenGebruikersResultElement } from '../../../../../../shared/src/api/v2/gebruiker';
import { IOphalenPersonenResultElementV2 } from '../../../../../../shared/src/api/v2/persoon/persoon';
import {
  EGebruikersStatus,
  gebruikersStatusKleurMap,
  gebruikersStatusTekstMap,
} from '../../../../bedrijfslogica/constanten';
import { IAspGebruikersprofiel } from '../../../../../../shared/src/api/v2/aspGebruiker';
import { observer } from 'mobx-react-lite';

interface IProps {
  gebruiker: IOphalenGebruikersResultElement;
  persoon?: IOphalenPersonenResultElementV2;
  profiel?: IAspGebruikersprofiel;
}

const GebruikerRij: React.FC<IProps> = observer((props) => {
  const gebruikerStatus = (props.gebruiker.HandmatigeStatus ??
    props.gebruiker.AutoStatus) as EGebruikersStatus;

  return (
    <div className="d-flex align-items-center p-2 pl-3 pr-3">
      <div>
        {props.persoon?.profielAfbeelding ?? false ? (
          <Avatar size="36px" imageSrc={props.persoon!.profielAfbeelding!.url} />
        ) : (
          <Avatar size="36px">{props.gebruiker.NaamKort.slice(0, 1)}</Avatar>
        )}
      </div>
      <div className="flex-fill">
        <span className="ml-3">{props.gebruiker.GebruikersNaam}</span>
      </div>
      <div>
        <div className="d-flex align-items-center" style={{ fontSize: 13 }}>
          <span style={{ marginRight: 10 }}>
            <div
              style={{
                width: 11,
                height: 11,
                backgroundColor: gebruikersStatusKleurMap[gebruikerStatus],
                borderRadius: '50%',
              }}
            />
          </span>
          <span className="text-muted">{gebruikersStatusTekstMap[gebruikerStatus]}</span>
        </div>
        <div className="d-flex align-items-center">
          <div className="flex-fill" />
          <div className="d-flex align-items-center" style={{ fontSize: 12 }}>
            {props.profiel?.Naam ?? 'Laden...'}
          </div>
        </div>
      </div>
    </div>
  );
});

export default GebruikerRij;
