import React, { useContext, useEffect, useMemo, useState, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import Tabblad, { ITabblad } from '../../../components/layout/Tabblad';
import { matchPath, Redirect, Route, RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router';
import Overzicht from './Overzicht';
import Inboeken from './Inboeken';
import Betalen from './Betalen';

interface IProps extends RouteComponentProps {}

export enum ETabblad {
  Inboeken = 1,
  Betalen = 2,
  Overzicht = 3,
}

const urlTabbladMap: Record<ETabblad, string> = {
  [ETabblad.Inboeken]: 'inboeken',
  [ETabblad.Betalen]: 'betalen',
  [ETabblad.Overzicht]: 'overzicht',
};

const Inkoopfacturen: React.FC<IProps> = observer((props) => {
  const tabbladen = useMemo<ITabblad<ETabblad>[]>(
    () => [
      {
        id: ETabblad.Inboeken,
        label: 'Inboeken',
        content: Inboeken,
      },
      {
        id: ETabblad.Betalen,
        label: 'Betalen',
        content: Betalen,
      },
      {
        id: ETabblad.Overzicht,
        label: 'Overzicht',
        content: Overzicht,
      },
    ],
    [],
  );

  const tabblad = useMemo<ETabblad | null>(() => {
    const paths = Object.keys(urlTabbladMap).map((key) => {
      const tabblad = Number(key) as ETabblad;
      const url = urlTabbladMap[tabblad];
      return {
        tabblad,
        path: `${props.match.path}/${url}`,
      };
    });
    for (const pathObj of paths) {
      const match = matchPath(props.location.pathname, pathObj.path);
      if (match !== null) {
        return pathObj.tabblad;
      }
    }

    return null;
  }, [props.location.pathname]);

  return (
    <>
      <Route
        exact
        path={props.match.path}
        component={() => (
          <Redirect to={`${props.match.path}/${urlTabbladMap[ETabblad.Inboeken]}`} />
        )}
      />
      {tabblad === null ? (
        <Redirect to={`${props.match.path}/${urlTabbladMap[ETabblad.Inboeken]}`} />
      ) : (
        <>
          <Tabblad
            geselecteerd={tabblad}
            onSelectieChange={(identifier) =>
              props.history.push(`${props.match.path}/${urlTabbladMap[identifier]}`)
            }
            tabbladen={tabbladen}
          />
        </>
      )}
    </>
  );
});

export default withRouter(Inkoopfacturen);
