import {
  IArchiverenChatsessieParams,
  IArchiverenChatsessieResult,
  IInstellenRelatieParams,
  IInstellenRelatieResult,
  IOphalenChatBerichtenParams,
  IOphalenChatBerichtenResult,
  IOphalenChatsessiesParams,
  IOphalenChatsessiesV2Params,
  IOphalenChatsessiesV2Result,
  IOppakkenGearchiveerdChatsessieParams,
  IOppakkenGearchiveerdChatsessieResult,
  IOppakkenNieuwChatsessieParams,
  IOppakkenNieuwChatsessieResult,
  IVerstuurBerichtParams,
  IVerstuurBerichtResult,
  OphalenChatsessiesResult,
  IHerstellenChatsessieNaarNieuwParams,
  IHerstellenChatsessieNaarNieuwResult,
  IBepalenMagBerichtVersturenParams,
  IBepalenMagBerichtVersturenResult,
  IConversatieVoortzettenAanvragenParams,
  IConversatieVoortzettenAanvragenResult,
} from '../../../../../shared/src/api/v2/dienst/whatsapp/whatsapp';
import api from '../../index';

const whatsapp = {
  ophalenChatSessies: async (
    data: IOphalenChatsessiesParams,
  ): Promise<OphalenChatsessiesResult> => {
    return await api.post('v2/dienst/whatsapp/ophalen-chatsessies', data);
  },
  ophalenChatSessiesV2: async (
    data: IOphalenChatsessiesV2Params,
  ): Promise<IOphalenChatsessiesV2Result> => {
    return await api.post('v2/dienst/whatsapp/ophalen-chatsessies-v2', data);
  },
  ophalenChatBerichten: async (
    params: IOphalenChatBerichtenParams,
  ): Promise<IOphalenChatBerichtenResult> => {
    return await api.post('v2/dienst/whatsapp/ophalen-berichten', params);
  },
  verstuurBericht: async (params: IVerstuurBerichtParams): Promise<IVerstuurBerichtResult> => {
    return await api.post('v2/dienst/whatsapp/verstuur-bericht', params);
  },
  oppakkenNieuwChatsessie: async (
    params: IOppakkenNieuwChatsessieParams,
  ): Promise<IOppakkenNieuwChatsessieResult> => {
    return await api.post('v2/dienst/whatsapp/oppakken-nieuw-chatsessie', params);
  },
  oppakkenGearchiveerdChatsessie: async (
    params: IOppakkenGearchiveerdChatsessieParams,
  ): Promise<IOppakkenGearchiveerdChatsessieResult> => {
    return await api.post('v2/dienst/whatsapp/oppakken-gearchiveerd-chatsessie', params);
  },
  archiverenChatsessie: async (
    params: IArchiverenChatsessieParams,
  ): Promise<IArchiverenChatsessieResult> => {
    return await api.post('v2/dienst/whatsapp/archiveren-chatsessie', params);
  },
  herstellenChatsessieNaarNieuw: async (
    params: IHerstellenChatsessieNaarNieuwParams,
  ): Promise<IHerstellenChatsessieNaarNieuwResult> => {
    return await api.post('v2/dienst/whatsapp/herstellen-chatsessie-naar-nieuw', params);
  },
  instellenRelatie: async (params: IInstellenRelatieParams): Promise<IInstellenRelatieResult> => {
    return await api.post('v2/dienst/whatsapp/instellen-relatie', params);
  },
  bepalenMagBerichtVersturen: async (
    params: IBepalenMagBerichtVersturenParams,
  ): Promise<IBepalenMagBerichtVersturenResult> => {
    return await api.post('v2/dienst/whatsapp/bepalen-mag-bericht-versturen', params);
  },
  conversatieVoortzettenAanvragen: async (
    params: IConversatieVoortzettenAanvragenParams,
  ): Promise<IConversatieVoortzettenAanvragenResult> => {
    return await api.post('v2/dienst/whatsapp/conversatie-voortzetten-aanvragen', params);
  },
};

export default whatsapp;
