import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  TableSelection,
  TableEditColumn,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import { IOphalenDienstenResultElement } from '../../../../../../shared/src/api/v2/dienst/pendel';
import api from '../../../../api';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import Dialoog from '../../../../components/dialogen/Dialoog';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import { IconToevoegen, IconVerwijderen } from '../../../../components/Icons';
import {
  DXCommandComponent,
  DXTableCheckboxComponent,
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import {
  DataTypeProvider,
  EditingState,
  IntegratedSorting,
  RowDetailState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import RelatieVisualisatie from '../../../../components/personalia/RelatieVisualisatie';
import PersoonVisualisatie from '../../../../components/personalia/PersoonVisualisatie';
import MutatieDialoog from './MutatieDialoog';
import { RootStoreContext } from '../../../../stores/RootStore';
import { EResultType } from '../../../../stores/CheckStore';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import useUrlState from '../../../../core/useUrlState';
import FilterBalkV2, {
  IFilter,
  IFilterData,
  maakFilterSchema,
} from '../../../../components/FilterBalkV2';
import { IFilterSchema } from '../../../../../../shared/src/models/filter';
import { IOphalenOpdrachtwijzenResultElement } from '../../../../../../shared/src/api/v2/dienst';
import MenuLayout from '../../../../components/MenuLayout';
import { Helmet } from 'react-helmet';

interface IProps extends RouteComponentProps {}

export enum EFilter {
  isActief = 'IS_ACTIEF',
}

interface IWijzigenServiceDialoogState {
  id: number | null;
}

interface INieuwServiceDialoogState {}

interface IUrlState {
  wijzigenServiceDialoogState: IWijzigenServiceDialoogState | null;
  nieuwServiceDialoogState: INieuwServiceDialoogState | null;
  selectie: number[];
  filterData: IFilterData<EFilter>[];
}

const defaultUrlState: IUrlState = {
  wijzigenServiceDialoogState: null,
  nieuwServiceDialoogState: null,
  selectie: [],
  filterData: [
    {
      naam: EFilter.isActief,
      data: true,
      isActief: true,
    },
  ],
};

export interface IRow extends IOphalenDienstenResultElement {}

const Pendel: React.FC<IProps> = (props) => {
  const [pendel, setPendel] = useState<IRow[] | null>(null);
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);

  const { checkStore } = useContext(RootStoreContext);

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.isActief,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return (
            <div className="d-flex align-items-center">
              <span>Alleen actieve</span>
            </div>
          );
        },
      },
    ],
    [],
  );

  const [filterSchema, setFilterSchema] = useState<IFilterSchema>(
    useMemo(() => maakFilterSchema(urlState.filterData), []),
  );

  const ophalenDiensten = useCallback(async () => {
    const resultaat = await api.v2.dienst.pendel.ophalenDiensten({
      filterSchema: {
        filters: [...filterSchema.filters!],
      },
    });

    setPendel(resultaat.diensten);
  }, [filterSchema, setPendel]);

  useEffect(() => {
    (async () => {
      ophalenDiensten();
    })();
  }, [ophalenDiensten]);

  const [opdrachtWijzen, setOpdrachtWijzen] = useState<
    IOphalenOpdrachtwijzenResultElement[] | null
  >(null);

  const ophalenOpdrachtwijzen = useCallback(async () => {
    const opdrachtwijzenResult = await api.v2.dienst.ophalenOpdrachtwijzen({
      filterSchema: { filters: [] },
    });

    setOpdrachtWijzen(opdrachtwijzenResult.opdrachtwijzen);
  }, []);

  useEffect(() => {
    ophalenOpdrachtwijzen();
  }, [ophalenOpdrachtwijzen]);

  const keyExtractor = useCallback((row: IRow) => row.ID, []);

  const kolommen = useMemo<TypedColumn<IRow>[]>(
    () => [
      {
        name: '__relatie' as any,
        title: 'Relatie',
        getCellValue: (x) => (x.relatie !== null ? x.relatie!.weergavenaam : null),
      },
      {
        name: '__opdrachtwijze' as any,
        title: 'Opdrachtwijze',
        getCellValue: (x) => {
          if (opdrachtWijzen === null) {
            return '';
          }
          const opdrachtwijze = opdrachtWijzen.find((y) => y.OpdWijzeID === x.OpdWijzeID)!.Naam;
          return opdrachtwijze;
        },
      },
      {
        name: 'EmailOpdracht',
        title: 'Email tbv opdrachten',
      },
      {
        name: 'EmailOpdrachtCC',
        title: 'Email CC tbv opdrachten',
      },
      {
        name: '__actief' as any,
        title: 'Actief',
        getCellValue: (x) => {
          return x.Actief;
        },
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRow>[]>(
    () => [
      {
        columnName: '__opdrachtwijze' as any,
        width: 150,
      },
      {
        columnName: '__actief' as any,
        width: 90,
      },
      {
        columnName: '__relatie' as any,
        width: 225,
      },
      {
        columnName: 'EmailOpdracht' as any,
        width: 250,
      },
      {
        columnName: 'EmailOpdrachtCC' as any,
        width: 250,
      },
    ],
    [],
  );

  const handleVerwijderen = useCallback(async () => {
    if (!urlState.selectie.length) {
      return;
    }

    const checkData = await api.v2.dienst.pendel.checkVerwijderenDiensten({
      ids: urlState.selectie,
    });

    const controlerenResult = await checkStore.controleren({
      checkData,
    });
    if (controlerenResult.type === EResultType.Annuleren) {
      return;
    }

    if (
      (
        await checkStore.bevestigen({
          inhoud: 'Wil je de geselecteerde pendeldiensten verwijderen?',
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    await api.v2.dienst.pendel.verwijderenDiensten({
      ids: urlState.selectie,
    });

    setUrlStateSync('selectie', []);
    ophalenDiensten();
  }, [urlState.selectie]);

  return (
    <>
      <Helmet>
        <title>Pendeldiensten</title>
      </Helmet>
      <MenuLayout
        menu={
          <>
            <div className="d-flex align-items-center">
              <button
                className="btn btn-sm btn-light d-flex align-items-center"
                style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                onClick={() => {
                  setUrlStateSync('nieuwServiceDialoogState', {});
                }}
              >
                <IconToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                <span className="ml-2">Nieuwe pendeldienst</span>
              </button>
              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-3"
                style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                disabled={urlState.selectie.length === 0}
                onClick={handleVerwijderen}
              >
                <IconVerwijderen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                <span className="ml-2">Verwijderen</span>
              </button>
              <div className="d-flex flex-fill ml-3">
                <FilterBalkV2
                  filters={filters}
                  filterData={urlState.filterData}
                  onFilterDataChange={(x) => setUrlStateSync('filterData', x)}
                  onFilterSchemaChange={(x) => setFilterSchema(x)}
                />
              </div>
            </div>
          </>
        }
        body={
          <>
            {pendel === null ? (
              <LoadingSpinner />
            ) : (
              <div className="bg-white" style={{ height: 'calc(100vh - 40px)' }}>
                <GridStyleWrapper height="calc(100vh - 150px)">
                  <Grid rows={pendel} columns={kolommen} getRowId={keyExtractor}>
                    <DataTypeProvider
                      for={['__relatie']}
                      formatterComponent={(formatterProps) => {
                        const rij: IOphalenDienstenResultElement = formatterProps.row;
                        return (
                          <span className="d-flex">
                            <RelatieVisualisatie relID={formatterProps.row.RelID} />
                            {rij.Naam !== null && <span>&nbsp;({rij.Naam}) </span>}
                          </span>
                        );
                      }}
                    />

                    <DataTypeProvider
                      for={['__opdrachtwijze']}
                      formatterComponent={(props) => {
                        if (opdrachtWijzen === null || props.row.OpdWijzeID === null) {
                          return <span></span>;
                        }

                        const opdrachtwijze = opdrachtWijzen.find(
                          (y) => y.OpdWijzeID === props.row.OpdWijzeID,
                        )!.Naam;

                        return <span>{opdrachtwijze}</span>;
                      }}
                    />

                    <DataTypeProvider
                      for={['__actief']}
                      formatterComponent={(props) => <span>{props.row.Actief ? 'Ja' : 'Nee'}</span>}
                    />

                    <DataTypeProvider
                      for={['Levertermijn']}
                      formatterComponent={(props) => (
                        <span>{props.value === 99 ? '' : props.value}</span>
                      )}
                    />

                    <DataTypeProvider
                      for={['TijdUiterlijkOpdracht']}
                      formatterComponent={(props) => (
                        <span>{props.row.Levertermijn !== 99 ? props.value : ''}</span>
                      )}
                    />

                    <RowDetailState defaultExpandedRowIds={[]} />

                    <SortingState defaultSorting={[]} />
                    <IntegratedSorting />

                    <VirtualTable />
                    <TableColumnResizing defaultColumnWidths={kolomBreedtes} />

                    <EditingState
                      onCommitChanges={async (changes) => {}}
                      onEditingRowIdsChange={(rowIds) => {
                        const id = rowIds[rowIds.length - 1] as number;
                        setUrlStateSync('wijzigenServiceDialoogState', {
                          id,
                        });
                      }}
                    />

                    <TableEditColumn
                      width={35}
                      showEditCommand
                      cellComponent={DXTableEditColumnCellComponent}
                      commandComponent={DXTableEditColumnCommandComponent}
                    />
                    <TableHeaderRow showSortingControls />
                    <SelectionState
                      selection={urlState.selectie}
                      onSelectionChange={(x) => {
                        setUrlStateSync('selectie', x as number[]);
                      }}
                    />
                    <TableSelection cellComponent={DXTableCheckboxComponent} />
                  </Grid>
                </GridStyleWrapper>
              </div>
            )}
            {urlState.wijzigenServiceDialoogState !== null && (
              <MutatieDialoog
                onSuccess={async () => {
                  setUrlStateSync('wijzigenServiceDialoogState', null);
                  await ophalenDiensten();
                }}
                id={urlState.wijzigenServiceDialoogState.id}
                onAnnuleren={() => setUrlStateSync('wijzigenServiceDialoogState', null)}
                open={true}
              />
            )}
            {urlState.nieuwServiceDialoogState && (
              <MutatieDialoog
                onSuccess={async () => {
                  setUrlStateSync('nieuwServiceDialoogState', null);
                  await ophalenDiensten();
                }}
                id={null}
                onAnnuleren={() => setUrlStateSync('nieuwServiceDialoogState', null)}
                open={true}
              />
            )}
          </>
        }
      />
    </>
  );
};

export default withRouter(Pendel);
