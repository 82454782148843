import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import IDialoogProps from '../../../core/IDialoogProps';
import Dialoog from '../../dialogen/Dialoog';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import styled from 'styled-components';
import { Kleur } from '../../../bedrijfslogica/constanten';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
} from '../../../models/IRemoteData';
import { AutoSizer } from 'react-virtualized';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import BerichtItem from '../../communicatie/BerichtItem';
import { ESjabloonOplosserModus, SjabloonOplosserModus } from '../types';

const BodyRoot = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

const DocumentContainer = styled.iframe`
  background-color: ${Kleur.Wit};
  //margin: 0 20px 20px 20px;
  //padding: 20px;
  width: calc(100% - 40px);
  margin-left: 20px;
  border: 0;
`;

const EmailContainer = styled.iframe`
  background-color: ${Kleur.Wit};
  //margin: 0 20px 20px 20px;
  //padding: 20px;
  width: calc(100% - 40px);
  border: 0;
`;

export enum EPreviewModus {
  Chatbubbel,
  Document,
  Email,
}

export enum EPreviewDialoogOutputType {
  DirectKiezen,
  Opstellen,
  Versturen,
  Selecteren,
}

export interface IPreviewDialoogOutput {
  type: EPreviewDialoogOutputType;
}

interface IProps extends IDialoogProps<IPreviewDialoogOutput> {
  modus: EPreviewModus;
  onderwerp: string | null;
  inhoud: string | null;
  sjabloonOplosserModus: SjabloonOplosserModus;
}

const PreviewDialoog = (props: IProps) => {
  const bodyRootRef = useRef<HTMLDivElement>(null);
  const [containerHeight, setContainerHeight] = useState<IRemoteData<number>>(
    createPendingRemoteData(),
  );
  useEffect(() => {
    setContainerHeight(createPendingRemoteData());
  }, [props.inhoud]);

  const handleLoad = useCallback(
    (ev: React.SyntheticEvent) => {
      const frame = ev.target as HTMLIFrameElement;
      const height = frame.contentWindow!.document.documentElement.scrollHeight + 40;
      setContainerHeight(createReadyRemoteData(height));
    },
    [setContainerHeight],
  );

  const body = useMemo(() => {
    if (props.modus === EPreviewModus.Chatbubbel) {
      return (
        <div
          style={{
            backgroundColor: Kleur.LichtGrijs,
          }}
          className="d-flex flex-fill flex-column align-items-center pt-3 pr-3 pb-3"
        >
          <BerichtItem
            isInkomend={false}
            datum={new Date()}
            // rootStyle={rootStyle}
            // extras={extras ?? undefined}
          >
            {props.inhoud!}
          </BerichtItem>
        </div>
      );
    }

    return (
      <AutoSizer className="flex-fill">
        {({ height, width }) => {
          switch (props.modus) {
            case EPreviewModus.Document: {
              return (
                <div
                  style={{
                    maxHeight: height,
                    width,
                    overflowY: 'auto',
                    backgroundColor: Kleur.LichtGrijs,
                  }}
                >
                  <DocumentContainer
                    srcDoc={props.inhoud!}
                    onLoad={handleLoad}
                    style={{
                      height: containerHeight.data ?? undefined,
                    }}
                    scrolling="no"
                  />
                </div>
              );
            }
            case EPreviewModus.Email: {
              return (
                <div style={{ maxHeight: height, width, overflowY: 'auto' }}>
                  <EmailContainer
                    srcDoc={props.inhoud!}
                    onLoad={handleLoad}
                    style={{
                      height: containerHeight.data ?? undefined,
                    }}
                    scrolling="no"
                  />
                </div>
              );
            }
          }
        }}
      </AutoSizer>
    );
  }, [props.modus, props.onderwerp, props.inhoud, containerHeight, handleLoad]);

  return (
    <Dialoog index={props.dialoogIndex ?? 0} modalProps={{ size: 'xl' }}>
      <ModalHeader>
        <ModalTitle>Sjabloon Preview</ModalTitle>
      </ModalHeader>
      <ModalBody
        className="p-0 d-flex flex-fill flex-column"
        style={{ height: props.modus !== EPreviewModus.Chatbubbel ? 800 : undefined }}
      >
        {props.onderwerp !== null && (
          <div className="p-3 pl-4 pr-4" style={{ borderBottom: `1px solid ${Kleur.LichtGrijs}` }}>
            <h5 style={{ marginBottom: 0 }}>{props.onderwerp}</h5>
          </div>
        )}
        <BodyRoot ref={bodyRootRef}>{body}</BodyRoot>
      </ModalBody>
      <ModalFooter className="d-flex flex-row justify-content-start">
        <button className="btn btn-secondary" onClick={props.onAnnuleren} style={{ width: 100 }}>
          Sluiten
        </button>

        <div className="flex-fill" />

        {props.sjabloonOplosserModus.type === ESjabloonOplosserModus.Genereer ? (
          <button
            className="btn btn-primary"
            onClick={() => props.onSuccess({ type: EPreviewDialoogOutputType.DirectKiezen })}
          >
            Direct kiezen
          </button>
        ) : props.sjabloonOplosserModus.type === ESjabloonOplosserModus.Verstuur ? (
          <div className="d-flex align-items-center">
            <button
              className="btn btn-primary"
              onClick={() => props.onSuccess({ type: EPreviewDialoogOutputType.Opstellen })}
            >
              Opstellen
            </button>
            <button
              className="btn btn-primary ml-2"
              onClick={() => props.onSuccess({ type: EPreviewDialoogOutputType.Versturen })}
            >
              Versturen
            </button>
          </div>
        ) : (
          <button
            className="btn btn-primary"
            onClick={() => props.onSuccess({ type: EPreviewDialoogOutputType.Selecteren })}
          >
            Selecteren
          </button>
        )}
      </ModalFooter>
    </Dialoog>
  );
};

export default PreviewDialoog;
