import React, { useState, useEffect, useCallback, useMemo, useContext, useRef } from 'react';
import MenuLayout from '../../../../components/MenuLayout';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import useUrlState from '../../../../core/useUrlState';
import MultiCombobox, { IKolom } from '../../../../components/formulier/MultiCombobox';
import {
  IOphalenProductmodellenResult,
  IOphalenProductmodellenResultElement,
} from '../../../../../../shared/src/api/v2/aanbod/productmodel';
import {
  IOphalenProducttypenResult,
  IOphalenProducttypenResultElement,
} from '../../../../../../shared/src/api/v2/product/type';
import api from '../../../../api';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import { IFilterSchemaFilter } from '../../../../../../shared/src/models/filter';
import {
  IOphalenEigenschapProductenResult,
  IOphalenEigenschapProductEntiteitenResult,
  IEigenschapProductEntiteit,
  IKopierenProductEigenschappenNaarProductParams,
} from '../../../../../../shared/src/api/v2/aanbod/index';
import {
  DXTableCheckboxComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
  DXTableToggleCellComponent,
  DXCommandComponent,
} from '../../../../helpers/dxTableGrid';
import {
  Grid,
  VirtualTable,
  TableHeaderRow,
  TableColumnResizing,
  TableSelection,
  TableRowDetail,
  TableEditColumn,
} from '@devexpress/dx-react-grid-bootstrap4';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../../stores/RootStore';
import {
  DataTypeProvider,
  EditingState,
  RowDetailState,
  SelectionState,
} from '@devexpress/dx-react-grid';
import TekstVisualisatie from '../../../../components/TekstVisualisatie';
import { IGetTekstOutput } from '../../../../stores/TekstStore';
import RowDetailComp from './RowDetailComp';
import { IOphalenTalenResult } from '../../../../../../shared/src/api/v2/taal';
import MuterenDialoog from './MuterenDialoog';
import { EResultType } from '../../../../stores/CheckStore';
import SelectieVak from '../../../../components/SelectieVak';
import { IconPijlVolgend } from '../../../../components/Icons';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';
import ITaalTekst from '../../../../../../shared/src/models/talen/ITaalTekst';
import { Helmet } from 'react-helmet';

interface IWijzigenTonenState {
  entID: number;
  eigProdID: number;
  waarden: ITaalTekst[];
}

interface IUrlState {
  prodModID: number | null;
  prodTypeID: number | null;
  selectie: number[];
  uitgeklapt: number[];
  toevoegenTonen: boolean;
  wijzigenTonenState: IWijzigenTonenState | null;
}

const defaultUrlState: IUrlState = {
  prodModID: null,
  prodTypeID: null,
  selectie: [],
  uitgeklapt: [],
  toevoegenTonen: false,
  wijzigenTonenState: null,
};

enum EWaardeTekst {
  Ja = '^Ja',
  Nee = '^Nee',
}

const waardeTekstTransformatieMap: Record<EWaardeTekst, (tekst: string) => JSX.Element | null> = {
  [EWaardeTekst.Ja]: () => <span>Ja</span>,
  [EWaardeTekst.Nee]: () => <span>Nee</span>,
};

interface IProps extends RouteComponentProps {}

interface IKopierenProducteigenschappenNaarProductState {
  prodModID: number | null;
  prodTypeID: number | null;
}

const Producten: React.FC<IProps> = observer((props) => {
  const { tekstStore, checkStore } = useContext(RootStoreContext);
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const [
    productmodellenResult,
    setProductmodellen,
  ] = useState<IOphalenProductmodellenResult | null>(null);
  const [producttypenResult, setProducttypen] = useState<IOphalenProducttypenResult | null>(null);
  // const [eigenschapProductEntiteiten, setSpecificaties] = useState<IOphalenEigenschapProductEntiteitenResult | null>(null);
  const [talen, setTalen] = useState<IOphalenTalenResult | null>(null);

  useEffect(() => {
    (async () => {
      const result = await api.v2.taal.ophalen({ filterSchema: { filters: [] } });
      setTalen(result);
    })();
  }, []);

  const ophalenProductmodellen = useCallback(async () => {
    const result = await api.v2.aanbod.productmodel.ophalenProductmodellen({
      filterSchema: {
        filters: [
          {
            naam: 'IN_AANBOD',
            data: true,
          },
        ],
      },
    });
    setProductmodellen(result);
  }, []);

  const ophalenProducttypen = useCallback(async () => {
    const result = await api.v2.product.type.ophalenProducttypen({
      filterSchema: {
        filters: [
          {
            naam: 'INKOOP_TOEGESTAAN',
            data: true,
          },
        ],
      },
    });
    setProducttypen(result);
  }, []);

  useEffect(() => {
    ophalenProductmodellen();
  }, [ophalenProductmodellen]);

  useEffect(() => {
    ophalenProducttypen();
  }, [ophalenProducttypen]);

  const productmodelKolommen = useMemo<IKolom<IOphalenProductmodellenResultElement>[]>(
    () => [
      {
        key: 'Modelcode',
        label: 'Code',
        breedte: 100,
      },
      {
        key: 'Modelnaam',
        label: 'Naam',
        breedte: 250,
      },
    ],
    [],
  );

  const producttypenKolommen = useMemo<IKolom<IOphalenProducttypenResultElement>[]>(
    () => [
      {
        key: 'Merknaam',
        label: 'Merk',
        breedte: 125,
      },
      {
        key: 'Typenaam',
        label: 'Type',
        breedte: 150,
      },
    ],
    [],
  );

  const [
    eigenschapProductEntiteitenResult,
    setEigenschapProductEntiteitenResult,
  ] = useState<IOphalenEigenschapProductEntiteitenResult | null>(null);

  const [
    eigenschapProductenResult,
    setEigenschapProductenResult,
  ] = useState<IOphalenEigenschapProductenResult | null>(null);

  const ophalenSpecificaties = useCallback(async () => {
    if (urlState.prodModID === null && urlState.prodTypeID === null) {
      setEigenschapProductEntiteitenResult(null);
      setEigenschapProductenResult(null);
      return;
    }
    const eigenschapProductEntiteitenResult = await api.v2.aanbod.specificatie.ophalenEigenschapProductEntiteiten(
      {
        filterSchema: {
          filters: [
            urlState.prodModID === null
              ? null
              : {
                  naam: 'PROD_MOD_IDS',
                  data: [urlState.prodModID],
                },
            urlState.prodTypeID === null
              ? null
              : {
                  naam: 'PROD_TYPE_IDS',
                  data: [urlState.prodTypeID],
                },
          ].filter((x) => x !== null) as IFilterSchemaFilter[],
        },
      },
    );

    const eigProdIDs = eigenschapProductEntiteitenResult.entiteiten.map((x) => x.EigProd_ID);
    const eigenschapProductenResult = await api.v2.aanbod.specificatie.ophalenEigenschapProducten({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: eigProdIDs,
          },
        ],
      },
    });

    setEigenschapProductenResult(eigenschapProductenResult);
    setEigenschapProductEntiteitenResult(eigenschapProductEntiteitenResult);
  }, [urlState.prodModID, urlState.prodTypeID]);

  useEffect(() => {
    ophalenSpecificaties();
  }, [ophalenSpecificaties]);

  const keyExtractor = useCallback((row: IEigenschapProductEntiteit) => row.ID, []);
  const kolommen = useMemo<TypedColumn<IEigenschapProductEntiteit>[]>(
    () => [
      {
        name: '__code' as any,
        title: 'Code',
      },
      {
        name: '__eenheid' as any,
        title: 'Eenheid',
      },
      {
        name: '__naam' as any,
        title: 'Naam',
      },
      {
        name: '__waarde' as any,
        title: 'Waarde',
      },
    ],
    [],
  );
  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IEigenschapProductEntiteit>[]>(
    () => [
      {
        columnName: '__code' as any,
        width: 80,
      },
      {
        columnName: '__eenheid' as any,
        width: 100,
      },
      {
        columnName: '__naam' as any,
        width: 350,
      },
      {
        columnName: '__waarde' as any,
        width: 350,
      },
    ],
    [],
  );

  const naamVisualisatieFabriek = useCallback((item: IGetTekstOutput) => {
    if (item.data === undefined) {
      return null;
    }

    const tekst = item.data.tekst;
    if (tekst === null) {
      return null;
    }

    return <span>{tekst}</span>;
  }, []);

  const waardeVisualisatieFabriek = useCallback((item: IGetTekstOutput) => {
    if (item.data === undefined) {
      return null;
    }

    const tekst = item.data.tekst;
    if (tekst === null) {
      return null;
    }

    const transformatieFn = waardeTekstTransformatieMap[tekst as EWaardeTekst];
    if (transformatieFn === undefined) {
      return <span>{tekst}</span>;
    }
    return transformatieFn(tekst);
  }, []);

  const kopierenProducteigenschappenNaarProductButtonRef = useRef<HTMLButtonElement>(null);
  const [
    kopierenState,
    setKopierenState,
  ] = useState<IKopierenProducteigenschappenNaarProductState | null>(null);
  const handleKopieren = useCallback(async () => {
    const params: IKopierenProductEigenschappenNaarProductParams = {
      eigProdEntIDs: urlState.selectie,
      prodModID: kopierenState!.prodModID === null ? undefined : kopierenState!.prodModID,
      prodTypeID: kopierenState!.prodTypeID === null ? undefined : kopierenState!.prodTypeID,
    };

    const checkData = await api.v2.aanbod.specificatie.checkKopierenProductEigenschappenNaarProduct(
      params,
    );

    if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
      return;
    }
    setKopierenState(null);
    await api.v2.aanbod.specificatie.kopierenProductEigenschappenNaarProduct(params);

    // Selectie resetten en navigeren
    setUrlStateSync('selectie', []);
    setUrlStateSync('prodModID', kopierenState!.prodModID);
    setUrlStateSync('prodTypeID', kopierenState!.prodTypeID);
  }, [kopierenState, urlState.selectie]);

  if (talen === null) {
    return (
      <div className="flex-fill d-flex align-items-center justify-content-center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Productspecificaties</title>
      </Helmet>
      <MenuLayout
        menu={
          <div className="d-flex flex-column">
            <div className="d-flex align-items-center">
              <span className="mr-2">Productmodel</span>
              {productmodellenResult === null ? (
                <LoadingSpinner />
              ) : (
                <div style={{ width: 350 }}>
                  <MultiCombobox<number, IOphalenProductmodellenResultElement>
                    kolommen={productmodelKolommen}
                    sleutelExtractor={(x) => x.ProdModID}
                    representatieFabriek={(x) => `${x.Modelcode} - ${x.Modelnaam}`}
                    waarde={urlState.prodModID}
                    onWaardeChange={(x) => {
                      setKopierenState(null);
                      setUrlStateSync('prodModID', x);
                      setUrlStateSync('prodTypeID', null);
                      setUrlStateSync('selectie', []);
                    }}
                    opties={productmodellenResult.modellen}
                  />
                </div>
              )}

              <span className="ml-5 mr-2">Producttype</span>

              {producttypenResult === null ? (
                <LoadingSpinner />
              ) : (
                <div style={{ width: 350 }}>
                  <MultiCombobox<number, IOphalenProducttypenResultElement>
                    kolommen={producttypenKolommen}
                    sleutelExtractor={(x) => x.TypeID}
                    representatieFabriek={(x) => `${x.Merknaam} - ${x.Typenaam}`}
                    waarde={urlState.prodTypeID}
                    onWaardeChange={(x) => {
                      setKopierenState(null);
                      setUrlStateSync('prodModID', null);
                      setUrlStateSync('prodTypeID', x);
                      setUrlStateSync('selectie', []);
                    }}
                    opties={producttypenResult.producttypen}
                  />
                </div>
              )}
            </div>
            <div className="mt-2 d-flex align-items-center">
              <SelectieVak
                aantal={urlState.selectie.length}
                totaalAantal={
                  eigenschapProductEntiteitenResult === null
                    ? null
                    : eigenschapProductEntiteitenResult.entiteiten.length
                }
                onChange={(alles) => {
                  if (alles) {
                    if (eigenschapProductEntiteitenResult === null) {
                      return;
                    }
                    setUrlStateSync(
                      'selectie',
                      eigenschapProductEntiteitenResult.entiteiten.map((x) => x.ID),
                    );
                  } else {
                    setUrlStateSync('selectie', []);
                  }
                }}
                entiteitEnkelvoud="specificatie"
                entiteitMeervoud="specificaties"
              />

              <div className="flex-fill" />

              <Overlay
                show={kopierenState !== null}
                // rootClose
                // onHide={() => setKopierenProducteigenschappenNaarProductTonen(false)}
                target={kopierenProducteigenschappenNaarProductButtonRef.current!}
                placement="bottom-end"
              >
                <Popover
                  id="kopieren-producteigenschappen-naar-product-selectie"
                  style={{ minWidth: 375 }}
                >
                  <div className="p-3">
                    <div className="row">
                      <div className="col-12">
                        <label>Productmodel</label>
                        {productmodellenResult === null ? (
                          <LoadingSpinner />
                        ) : (
                          <div style={{ width: 350 }}>
                            <MultiCombobox<number, IOphalenProductmodellenResultElement>
                              kolommen={productmodelKolommen}
                              sleutelExtractor={(x) => x.ProdModID}
                              representatieFabriek={(x) => `${x.Modelcode} - ${x.Modelnaam}`}
                              waarde={kopierenState === null ? null : kopierenState!.prodModID}
                              onWaardeChange={(x) => {
                                setKopierenState((curr) => ({
                                  ...curr,
                                  prodModID: x,
                                  prodTypeID: null,
                                }));
                              }}
                              opties={productmodellenResult.modellen.filter(
                                (x) => x.ProdModID !== urlState.prodModID,
                              )}
                            />
                          </div>
                        )}
                      </div>

                      <div className="col-12 mt-2">
                        <label>Producttype</label>
                        {producttypenResult === null ? (
                          <LoadingSpinner />
                        ) : (
                          <div style={{ width: 350 }}>
                            <MultiCombobox<number, IOphalenProducttypenResultElement>
                              kolommen={producttypenKolommen}
                              sleutelExtractor={(x) => x.TypeID}
                              representatieFabriek={(x) => `${x.Merknaam} - ${x.Typenaam}`}
                              waarde={kopierenState === null ? null : kopierenState!.prodTypeID}
                              onWaardeChange={(x) => {
                                setKopierenState((curr) => ({
                                  ...curr,
                                  prodModID: null,
                                  prodTypeID: x,
                                }));
                              }}
                              opties={producttypenResult.producttypen.filter(
                                (x) => x.TypeID !== urlState.prodTypeID,
                              )}
                            />
                          </div>
                        )}
                      </div>

                      <div className="col-12 mt-3">
                        <div className="d-flex align-items-center">
                          <button
                            className="btn btn-sm btn-primary d-flex align-items-center justify-content-center"
                            style={{ width: 100 }}
                            disabled={
                              kopierenState === null ||
                              (kopierenState.prodTypeID === null &&
                                kopierenState.prodModID === null)
                            }
                            onClick={handleKopieren}
                          >
                            Kopiëren
                          </button>
                          <button
                            className="btn btn-sm btn-secondary d-flex align-items-center justify-content-center ml-2"
                            style={{ width: 100 }}
                            onClick={() => setKopierenState(null)}
                          >
                            Annuleren
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Popover>
              </Overlay>
              <button
                ref={kopierenProducteigenschappenNaarProductButtonRef}
                className="btn btn-sm btn-light d-flex align-items-center justify-content-center"
                style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                disabled={urlState.selectie.length === 0}
                onClick={() =>
                  setKopierenState({
                    prodModID: null,
                    prodTypeID: null,
                  })
                }
              >
                <span>
                  <IconPijlVolgend
                    style={{ fill: Kleur.Grijs, width: 16, height: 16, marginBottom: 1 }}
                  />
                </span>
                <span className="ml-2">Kopiëren producteigenschappen naar product</span>
              </button>
            </div>
          </div>
        }
        body={
          <div className="d-flex flex-fill">
            {urlState.prodModID === null && urlState.prodTypeID === null ? (
              <div className="flex-fill d-flex align-items-center justify-content-center">
                <span>Kies een productmodel of producttype om specificaties weer te geven.</span>
              </div>
            ) : eigenschapProductEntiteitenResult === null || eigenschapProductenResult === null ? (
              <div className="flex-fill d-flex align-items-center justify-content-center">
                <LoadingSpinner />
              </div>
            ) : (
              <div className="flex-fill d-flex flex-column">
                <GridStyleWrapper height="calc(100vh - 155px)">
                  <Grid
                    getRowId={keyExtractor}
                    columns={kolommen}
                    rows={eigenschapProductEntiteitenResult.entiteiten}
                  >
                    <DataTypeProvider
                      for={['__code']}
                      formatterComponent={(formatterProps) => {
                        const row: IEigenschapProductEntiteit = formatterProps.row;
                        if (eigenschapProductenResult === null) {
                          return <span />;
                        }
                        const product = eigenschapProductenResult!.producten.find(
                          (x) => x.ID === row.EigProd_ID,
                        );
                        if (product === undefined) {
                          return <span />;
                        }
                        return <span>{product.Code}</span>;
                      }}
                    />
                    <DataTypeProvider
                      for={['__naam']}
                      formatterComponent={(formatterProps) => {
                        const row: IEigenschapProductEntiteit = formatterProps.row;
                        if (eigenschapProductenResult === null) {
                          return <span />;
                        }
                        const product = eigenschapProductenResult!.producten.find(
                          (x) => x.ID === row.EigProd_ID,
                        );
                        if (product === undefined) {
                          return <span />;
                        }
                        return (
                          <div className="d-flex flex-column">
                            {talen!.map((taal) => {
                              return (
                                <div key={taal.TaalID}>
                                  <span>{taal.NaamKort}</span>
                                  <span className="ml-2">
                                    <TekstVisualisatie
                                      tekstID={product.Naam_TekstID}
                                      visualisatieFabriek={naamVisualisatieFabriek}
                                      gewensteTaalID={taal.TaalID}
                                    />
                                  </span>
                                </div>
                              );
                            })}
                          </div>
                        );
                      }}
                    />
                    <DataTypeProvider
                      for={['__waarde']}
                      formatterComponent={(formatterProps) => {
                        const row: IEigenschapProductEntiteit = formatterProps.row;

                        return (
                          <div className="d-flex flex-column">
                            {talen!.map((taal) => {
                              return (
                                <div key={taal.TaalID}>
                                  <span>
                                    <TekstVisualisatie
                                      tekstID={row.Waarde_TekstID}
                                      visualisatieFabriek={waardeVisualisatieFabriek}
                                      gewensteTaalID={taal.TaalID}
                                    />
                                  </span>
                                </div>
                              );
                            })}
                          </div>
                        );
                      }}
                    />

                    <DataTypeProvider
                      for={['__eenheid']}
                      formatterComponent={(formatterProps) => {
                        const row: IEigenschapProductEntiteit = formatterProps.row;
                        if (eigenschapProductenResult === null) {
                          return <span />;
                        }
                        const product = eigenschapProductenResult!.producten.find(
                          (x) => x.ID === row.EigProd_ID,
                        );
                        if (product === undefined) {
                          return <span />;
                        }
                        return (
                          <div className="d-flex flex-column">
                            {talen!.map((taal) => {
                              return (
                                <div key={taal.TaalID}>
                                  <span className="ml-2">
                                    <TekstVisualisatie
                                      tekstID={product.Eenheid_TekstID}
                                      visualisatieFabriek={naamVisualisatieFabriek}
                                      gewensteTaalID={taal.TaalID}
                                    />
                                  </span>
                                </div>
                              );
                            })}
                          </div>
                        );
                      }}
                    />

                    <SelectionState
                      selection={urlState.selectie}
                      onSelectionChange={(x) => setUrlStateSync('selectie', x as number[])}
                    />
                    <RowDetailState
                      expandedRowIds={urlState.uitgeklapt}
                      onExpandedRowIdsChange={(x) => setUrlStateSync('uitgeklapt', x as number[])}
                    />
                    <EditingState
                      onCommitChanges={async (changeset) => {
                        if (changeset.deleted !== undefined && changeset.deleted.length > 0) {
                          if (
                            (
                              await checkStore.bevestigen({
                                inhoud: 'Verwijderen?',
                              })
                            ).type === EResultType.Annuleren
                          ) {
                            return;
                          }
                          const ids = changeset.deleted as number[];
                          await api.v2.aanbod.specificatie.verwijderenEigenschapProductEntiteiten({
                            IDs: ids,
                          });
                          await ophalenSpecificaties();
                        }
                      }}
                      onAddedRowsChange={() => setUrlStateSync('toevoegenTonen', true)}
                      onEditingRowIdsChange={(ids) => {
                        const id = ids[ids.length - 1]! as number;
                        const ent = eigenschapProductEntiteitenResult!.entiteiten.find(
                          (x) => x.ID === id,
                        )!;
                        const teksten = tekstStore.teksten[ent.Waarde_TekstID] || {};
                        const waarden = Object.keys(teksten).map((x) => {
                          const taalID = Number(x);
                          return {
                            taalID,
                            tekst: teksten[taalID],
                            toepassen: false,
                          };
                        });

                        setUrlStateSync('wijzigenTonenState', {
                          entID: ent.ID,
                          eigProdID: ent.EigProd_ID,
                          waarden,
                        });
                      }}
                    />
                    <VirtualTable />
                    <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
                    <TableHeaderRow />
                    <TableEditColumn
                      width={65}
                      showEditCommand
                      showDeleteCommand
                      showAddCommand
                      commandComponent={DXCommandComponent}
                    />
                    <TableRowDetail
                      toggleCellComponent={DXTableToggleCellComponent}
                      rowComponent={RowDetailComp}
                    />
                    <TableSelection cellComponent={DXTableCheckboxComponent} />
                  </Grid>
                </GridStyleWrapper>
              </div>
            )}
          </div>
        }
      />
      {urlState.toevoegenTonen && eigenschapProductenResult !== null && (
        <MuterenDialoog
          open
          onSuccess={async (data) => {
            setUrlStateSync('toevoegenTonen', false);

            await api.v2.aanbod.specificatie.toevoegenEigenschapProductEntiteit({
              eigProd_ID: data.eigProdID,
              prodModID: urlState.prodModID,
              typeID: urlState.prodTypeID,
              waarden: Object.keys(data.waarden)
                .map((taalIDstr) => Number(taalIDstr))
                .filter((taalID) => data.waarden[taalID] !== undefined)
                .reduce((acc, taalID) => {
                  return {
                    ...acc,
                    [taalID]: data.waarden[taalID]!,
                  };
                }, {}),
            });
            await ophalenSpecificaties();
          }}
          onAnnuleren={() => setUrlStateSync('toevoegenTonen', false)}
          uitgeslotenEigProdIDs={eigenschapProductenResult.producten.map((x) => x.ID)}
        />
      )}
      {urlState.wijzigenTonenState !== null && eigenschapProductenResult !== null && (
        <MuterenDialoog
          open
          onSuccess={async (data) => {
            setUrlStateSync('wijzigenTonenState', null);

            await api.v2.aanbod.specificatie.wijzigenEigenschapProductEntiteit({
              entID: urlState.wijzigenTonenState!.entID,
              eigProd_ID: data.eigProdID,
              prodModID: urlState.prodModID,
              typeID: urlState.prodTypeID,
              waarden: data.waarden,
              // waarden: Object.keys(data.waarden)
              //   .map((taalIDstr) => Number(taalIDstr))
              //   .filter((taalID) => data.waarden[taalID] !== undefined)
              //   .reduce((acc, taalID) => {
              //     return {
              //       ...acc,
              //       [taalID]: data.waarden[taalID]!,
              //     };
              //   }, {}),
            });
            await ophalenSpecificaties();
          }}
          onAnnuleren={() => setUrlStateSync('wijzigenTonenState', null)}
          initialValues={{
            eigProdID: urlState.wijzigenTonenState.eigProdID,
            waarden: urlState.wijzigenTonenState.waarden,
          }}
          uitgeslotenEigProdIDs={eigenschapProductenResult.producten
            .map((x) => x.ID)
            .filter((x) => x !== urlState.wijzigenTonenState!.eigProdID)}
        />
      )}
    </>
  );
});

export default withRouter(Producten);
