import _ from 'lodash';
import { reverseKeyExtractor } from '..';
import { IOphalenProductenResultElementV2 } from '../../../../../../shared/src/api/v2/product';
import { IOphalenProducttypenResultElement } from '../../../../../../shared/src/api/v2/product/type';
import { IFilterSchema, IFilterSchemaFilter } from '../../../../../../shared/src/models/filter';
import api from '../../../../api';

export interface IBepaalProductrijenParams {
  filterSchema: IFilterSchema;
  ingepaktGroeperen?: boolean;
}

export interface IRow {
  uitgepakt?: IOphalenProductenResultElementV2;
  ingepakt?: {
    product?: IOphalenProductenResultElementV2;
    type: IOphalenProducttypenResultElement;
    aantalGegroepeerd?: number;
  };
}

export const bepaalProductrijen = async (params: IBepaalProductrijenParams): Promise<IRow[]> => {
  const productenResult = await api.v2.product.ophalenProductenV2({
    filterSchema: params.filterSchema,
    orderSchema: { orders: [{ naam: 'DATUM_UIT_BEDRIJF', richting: 'DESC' }] },
  });

  const typeIDs = _.uniq(productenResult.producten.map((x) => x.producttype.TypeID));
  const producttypenResult = await api.v2.product.type.ophalenProducttypen({
    filterSchema: { filters: [{ naam: 'IDS', data: typeIDs }] },
  });

  /**
   * Raap alle producten bij elkaar, als ingepakt gegroepeerd moet worden, dan maar van elk
   * type deze oppakken en het aantal optellen
   */
  const result = productenResult.producten.reduce<IRow[]>((acc, product) => {
    const isIngepakt = product.Nieuw && product.Referentiecode === null;
    if (isIngepakt) {
      if (params.ingepaktGroeperen) {
        const ingepaktTypeIndex = acc.findIndex(
          (row) =>
            row.ingepakt !== undefined && row.ingepakt.type.TypeID === product.producttype.TypeID,
        );
        if (ingepaktTypeIndex !== -1) {
          const ingepakt = acc[ingepaktTypeIndex]!.ingepakt!;
          acc[ingepaktTypeIndex] = {
            ingepakt: {
              ...ingepakt,
              aantalGegroepeerd: ingepakt.aantalGegroepeerd! + 1,
            },
          };
          return acc;
        }
      }

      const type = producttypenResult.producttypen.find(
        (type) => type.TypeID === product.producttype.TypeID,
      )!;
      return [
        ...acc,
        {
          ingepakt: {
            type,
            product: params.ingepaktGroeperen ? undefined : product,
            aantalGegroepeerd: params.ingepaktGroeperen ? 1 : undefined,
          },
        },
      ];
    }
    return [
      ...acc,
      {
        uitgepakt: product,
      },
    ];
  }, []);
  return result;
};

// export const ophalenProducten = async (params: IOphalenProductParams) => {
//   const nieuwGroeperen = params.nieuwGroeperen !== undefined ? params.nieuwGroeperen : false;
//
//   const result = await api.v2.product.ophalenProducten({
//     filterSchema: params.filterSchema,
//   });
//
//   // Gebruikt
//   const productenGebruikt: IRow[] = result.producten
//     .filter((x) => !x.Nieuw || x.Referentiecode !== null)
//     .map((x) => {
//       return { zonderReferentie: x.Referentiecode === null, aantal: 1, product: { ...x } };
//     });
//
//   // Nieuw
//   const productenNieuwResult = _.groupBy(
//     result.producten.filter((x) => x.Nieuw && x.Referentiecode === null),
//     (x: any) => {
//       return x.TypeID;
//     },
//   );
//   // Producttypen
//   const typeIDs = _.uniq(result.producten.map((x: any) => x.TypeID));
//   const producttypenResult = (
//     await api.v2.product.type.ophalenProducttypen({
//       filterSchema: { filters: [{ naam: 'IDS', data: typeIDs }] },
//     })
//   ).producttypen;
//
//   const productenNieuwGegroepeerd = Object.keys(productenNieuwResult).map((key) => ({
//     TypeID: Number(key),
//     producten: productenNieuwResult[key] as IOphalenProductenResultElement[],
//   }));
//
//   const productenNieuw: IRow[] = nieuwGroeperen
//     ? productenNieuwGegroepeerd.map((x: any) => {
//         const producttype = producttypenResult.find((t) => t.TypeID === x.TypeID)!;
//         return { zonderReferentie: true, aantal: x.producten.length, product: producttype };
//       })
//     : result.producten
//         .filter((x) => x.Referentiecode === null)
//         .map((x) => {
//           return { zonderReferentie: x.Referentiecode === null, aantal: 1, product: { ...x } };
//         });
//
//   const producten: IRow[] = _.flatten([productenGebruikt, productenNieuw]);
//
//   const prodIDs: number[] = producten
//     .filter((x: any) => x.Referentiecode !== null)
//     .map((x: any) => {
//       // const product = !x.nieuw ? (x.product as IOphalenProductResult) : (x.product as any);
//       return x.product.ProdID;
//     });
//
//   // Pendelinfo erbij halen
//   const pendelregelsResult = await api.v2.pendel.ophalenOpdrachtregels({
//     filterSchema: {
//       filters: [
//         { naam: 'PROD_IDS', data: prodIDs },
//         { naam: 'OPDRACHT_AFGEHANDELD', data: false },
//       ],
//     },
//   });
//   const pendelregels = pendelregelsResult.regels;
//   //   setPendelregels(pendelregels);
//
//   const productenGesorteerd: IRow[] = _.orderBy(
//     producten,
//     [
//       (x: any) => {
//         return x.product.Merknaam;
//       },
//       (x: any) => {
//         return x.product.Typenaam;
//       },
//       (x: any) => {
//         return x.nieuw;
//       },
//       (x: any) => {
//         return !x.nieuw ? x.product.Referentiecode : '';
//       },
//     ],
//     ['asc', 'asc', 'asc', 'asc'],
//   );
//
//   return productenGesorteerd;
// };

export const heeftSelectieMetEnkelTypeEntiteit = (selectie: string[]) => {
  const aantalKeys = selectie.map(reverseKeyExtractor).reduce(
    (acc, curr) => {
      if (curr.prodID !== undefined) {
        return {
          ...acc,
          prodIDs: acc.prodIDs + 1,
        };
      }
      return {
        ...acc,
        typeIDs: acc.typeIDs + 1,
      };
    },
    {
      prodIDs: 0,
      typeIDs: 0,
    },
  );
  // Er mag maar een soort tegelijkertijd geselecteerd worden, en er moet er minimaal een worden
  // geselecteerd
  return (
    (aantalKeys.prodIDs > 0 && aantalKeys.typeIDs === 0) ||
    (aantalKeys.typeIDs > 0 && aantalKeys.prodIDs === 0)
  );
};

// Pendelregels (niet afgehandeld) voor de opgegeven prodIDs
export const pendelregelsResult = async (prodIDs: number[]) => {
  const pendelregels = await api.v2.pendel.ophalenOpdrachtregels({
    filterSchema: {
      filters: [
        { naam: 'PROD_IDS', data: prodIDs },
        { naam: 'OPDRACHT_AFGEHANDELD', data: false },
      ],
    },
  });
  return pendelregels;
};

// Retouraanmeldingen (niet afgehandeld) voor de opgegeven prodIDs
export const aanmeldingenResult = async (prodIDs: number[]) => {
  const retouraanmeldingen = await api.v2.inkoop.retour.ophalenAanmeldingen({
    filterSchema: {
      filters: [
        { naam: 'PROD_IDS', data: prodIDs },
        // { naam: 'IS_AFGEHANDELD', data: false },
      ],
    },
  });
  return retouraanmeldingen;
};
