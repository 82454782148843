import { makeObservable, observable } from 'mobx';
import IRemoteData, { createPendingRemoteData, createReadyRemoteData } from '../models/IRemoteData';
import { IOphalenBetalingsregelingenResultElement } from '../../../shared/src/api/v2/debiteur/betalingsregeling';
import * as _ from 'lodash';
import api from '../api';

class BetalingsregelingStore {
  public betalingsregelingen: IRemoteData<
    Record<number, IOphalenBetalingsregelingenResultElement>
  > = createPendingRemoteData();
  private ophalenBetalingsregelingenQueue: number[] = [];
  private ophalenBetalingsregelingenQueueDebounceHandle: number | null = 0;

  constructor() {
    makeObservable(this, {
      betalingsregelingen: observable,
    });
  }

  public enqueueOphalenBetalingsregelingen = (betRglIDs: number[]) => {
    if (this.ophalenBetalingsregelingenQueueDebounceHandle !== null) {
      clearTimeout(this.ophalenBetalingsregelingenQueueDebounceHandle);
    }
    this.ophalenBetalingsregelingenQueue = _.uniq([
      ...this.ophalenBetalingsregelingenQueue,
      ...betRglIDs,
    ]);
    this.ophalenBetalingsregelingenQueueDebounceHandle = setTimeout(
      this.ophalenQueuedBetalingsregelingen,
      100,
    ) as any;
  };
  private ophalenQueuedBetalingsregelingen = async () => {
    this.ophalenBetalingsregelingenQueueDebounceHandle = null;
    const betRglIDs = this.ophalenBetalingsregelingenQueue;

    if (betRglIDs.length === 0) {
      return;
    }

    this.ophalenBetalingsregelingenQueue = [];

    const result = await api.v2.debiteur.betalingsregeling.ophalenBetalingsregelingen({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: betRglIDs,
          },
        ],
      },
    });
    this.betalingsregelingen = createReadyRemoteData(
      result.betalingsregelingen.reduce((acc, curr) => {
        return {
          ...acc,
          [curr.ID]: curr,
        };
      }, this.betalingsregelingen.data ?? {}),
    );
  };
}

export default BetalingsregelingStore;
