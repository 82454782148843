import { Field, FieldProps } from 'formik';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IOphalenOpdrachtwijzenResultElement } from '../../../../../../../shared/src/api/v2/dienst';
import api from '../../../../../api';
import Combobox from '../../../../../components/formulier/Combobox';
import FormikVeldFout from '../../../../../components/formulier/FormikVeldFout';
import NumeriekVeld from '../../../../../components/formulier/NumeriekVeld';
import TijdVeld from '../../../../../components/formulier/TijdVeld';
import LoadingSpinner from '../../../../../components/Gedeeld/LoadingSpinner';
import nameof from '../../../../../core/nameOf';
import { IFormikValues, veldnamen } from '../../Transport/MutatieDialoog';
import { IOphalenMagazijnenResultElement } from '../../../../../../../shared/src/api/v2/magazijn';
import MultiCombobox, { IKolom } from '../../../../../components/formulier/MultiCombobox';

const TransportFormulier: React.FC = (props) => {
  const [opdrachtWijzen, setOpdrachtWijzen] = useState<
    IOphalenOpdrachtwijzenResultElement[] | null
  >(null);

  const [magazijnen, setMagazijnen] = useState<IOphalenMagazijnenResultElement[] | null>(null);

  const ophalenOpdrachtwijzen = useCallback(async () => {
    const opdrachtwijzenResult = await api.v2.dienst.ophalenOpdrachtwijzen({
      filterSchema: { filters: [] },
    });

    setOpdrachtWijzen(opdrachtwijzenResult.opdrachtwijzen);
  }, []);

  useEffect(() => {
    ophalenOpdrachtwijzen();
  }, []);

  // Alle voorraadmagazijnen ophalen
  const ophalenMagazijnen = useCallback(async () => {
    const result = await api.v2.magazijn.ophalenMagazijnen({
      filterSchema: {
        filters: [{ naam: 'IS_VOORRAAD', data: true }],
      },
    });

    setMagazijnen(result.magazijnen);
  }, []);

  useEffect(() => {
    ophalenMagazijnen();
  }, [ophalenMagazijnen]);

  const magazijnKolommen = useMemo<IKolom<IOphalenMagazijnenResultElement>[]>(() => {
    return [
      {
        key: 'NaamKort',
        label: 'Naam',
        breedte: 200,
      },
    ];
  }, []);

  return (
    <div className="p-3">
      <div className="row mb-3">
        <div className="col-12">
          <label>{veldnamen.magID}</label>
          <Field
            name={nameof<IFormikValues>('magID')}
            render={(fieldProps: FieldProps<IFormikValues>) => {
              const { field, form } = fieldProps;

              if (magazijnen === null) {
                return <LoadingSpinner />;
              }

              return (
                <div>
                  <Combobox
                    geselecteerd={field.value}
                    opties={magazijnen.map((magazijn) => {
                      return {
                        id: magazijn.MagID,
                        label: magazijn.NaamKort,
                      };
                    })}
                    onSelectieChange={(x) => form.setFieldValue(field.name, x)}
                  />
                </div>
              );
            }}
          />
        </div>

        <div className="col-12 mt-3">
          <label>{veldnamen.opdWijzeID}</label>
          <Field
            name={nameof<IFormikValues>('opdWijzeID')}
            render={(fieldProps: FieldProps<IFormikValues>) => {
              const { field, form } = fieldProps;

              if (opdrachtWijzen === null) {
                return <LoadingSpinner />;
              }

              return (
                <div>
                  <Combobox
                    geselecteerd={field.value}
                    opties={opdrachtWijzen.map((opdrachtwijze) => {
                      return {
                        id: opdrachtwijze.OpdWijzeID,
                        label: opdrachtwijze.Naam,
                      };
                    })}
                    onSelectieChange={(x) => form.setFieldValue(field.name, x)}
                  />
                </div>
              );
            }}
          />
        </div>

        <div className="col-12 mt-3">
          <label>{veldnamen.emailOpdracht}</label>
          <Field
            name={nameof<IFormikValues>('emailOpdracht')}
            render={(fieldProps: FieldProps) => {
              const { field, form } = fieldProps;
              return (
                <div>
                  <input className="form-control" {...fieldProps.field} />
                  <FormikVeldFout fieldProps={fieldProps} />
                </div>
              );
            }}
          />
        </div>

        <div className="col-12 mt-3">
          <label>{veldnamen.emailOpdrachtCC}</label>
          <Field
            name={nameof<IFormikValues>('emailOpdrachtCC')}
            render={(fieldProps: FieldProps) => {
              const { field, form } = fieldProps;
              return (
                <div>
                  <input className="form-control" {...fieldProps.field} />
                  <FormikVeldFout fieldProps={fieldProps} />
                </div>
              );
            }}
          />
        </div>

        <div className="row col-12 mt-3">
          <div className="col-6">
            <label>{veldnamen.levertermijn}</label>
            <Field
              name={nameof<IFormikValues>('levertermijn')}
              render={(fieldProps: FieldProps<IFormikValues>) => {
                const { field, form } = fieldProps;
                return (
                  <div>
                    <NumeriekVeld
                      waarde={field.value}
                      onChange={(x) => form.setFieldValue(field.name, x)}
                      min={0}
                      max={99}
                    />
                    <FormikVeldFout fieldProps={fieldProps} />
                  </div>
                );
              }}
            />
          </div>

          <div className="col-6">
            <label>{veldnamen.tijdUiterlijkOpdracht}</label>
            <Field
              name={nameof<IFormikValues>('tijdUiterlijkOpdracht')}
              render={(fieldProps: FieldProps) => {
                const { field, form } = fieldProps;

                return (
                  <div>
                    <TijdVeld
                      waarde={field.value}
                      onWaardeChange={(x) => form.setFieldValue(field.name, x)}
                    />
                    <FormikVeldFout fieldProps={fieldProps} />
                  </div>
                );
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransportFormulier;
