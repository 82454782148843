import React, { useCallback, useMemo } from 'react';
import { IUitgeklapteInhoudProps } from '../..';
import {
  IPotentieelOntvangenInslagReferentieItem,
  IPotentieelOntvangenInslagReferentieItemRegel,
} from '../../../../../../shared/src/api/v2/externeData';
import { ASPKolom, EAspKolomBreedteType } from '../../../../components/tabel/ASPTabel/types';
import ASPTabel from '../../../../components/tabel/ASPTabel';
import ProducttypeVisualisatie from '../../../../components/entiteitVisualisaties/ProducttypeVisualisatie';

interface IRegel extends IPotentieelOntvangenInslagReferentieItemRegel {
  nummer: number;
}

enum EKolom {
  Regelnummer,
  Typenaam,
  InkOpdRef,
  Referentiecode,
  Koppelbaar,
  // Product,
}

const InslagReferentieUitgeklapteInhoud = <TRow extends any>(
  props: IUitgeklapteInhoudProps<TRow, IPotentieelOntvangenInslagReferentieItem>,
) => {
  const kolommen = useMemo<ASPKolom<EKolom, IRegel>[]>(
    () => [
      {
        key: EKolom.Regelnummer,
        label: '#',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 65,
        renderer: (item) => <span>{item.nummer}</span>,
      },
      {
        key: EKolom.Typenaam,
        label: 'Typenaam',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 225,
        renderer: (item) => {
          if (item.TypeID === null) {
            return;
          }
          return <ProducttypeVisualisatie typeID={item.TypeID!} />;
        },
      },
      {
        key: EKolom.InkOpdRef,
        label: 'Inkoopreferentie',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 150,
        renderer: (item) => <span>{item.InkOpdRef}</span>,
      },
      {
        key: EKolom.Referentiecode,
        label: 'Referentiecode',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 135,
        renderer: (item) => <span>{item.Referentiecode ?? ''}</span>,
      },
      {
        key: EKolom.Koppelbaar,
        label: 'Koppelbaar',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 110,
        renderer: (item) => <span>{item.GekoppeldAan_ProdID !== null ? 'Ja' : 'Nee'}</span>,
      },

      // {
      //   key: EKolom.Product,
      //   label: 'Product',
      //   breedteType: EAspKolomBreedteType.Vast,
      //   vasteBreedte: 225,
      //   renderer: (item) => {
      //     if (item.GekoppeldAan_ProdID === null) {
      //       return;
      //     }
      //     return <ProductVisualisatie typeID={item.GekoppeldAan_ProdID} />;
      //   },
      // },
    ],
    [],
  );

  const regels = props.item.Regels.map((regel, i): IRegel => ({ ...regel, nummer: i + 1 }));

  const keyExtractor = useCallback((row: IRegel) => row.nummer, []);

  return (
    <div style={{ minHeight: 200 }} className="d-flex flex-fill w-100">
      <ASPTabel rijen={regels} kolommen={kolommen} keyExtractor={keyExtractor} />
    </div>
  );

  // return (
  //   <div className="d-flex flex-column flex-fill">
  //     {props.item.Regels.map((regel) => {
  //       return (
  //         <div style={{ display: 'inline-block', height: 40 }}>
  //           {regel.Typenaam} {regel.Referentiecode} -{' '}
  //           {regel.GekoppeldAan_ProdID
  //             ? `Te koppelen aan product ${regel.GekoppeldAan_ProdID}`
  //             : 'Geen koppelbaar product gevonden'}
  //         </div>
  //       );
  //     })}
  //   </div>
  // );
};

export default InslagReferentieUitgeklapteInhoud;
