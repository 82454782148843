import * as React from 'react';
import { useContext, useMemo } from 'react';
import { Kleur } from '../../../bedrijfslogica/constanten';
import FormTaalSelectie from '../../formulier/TaalSelectie';
import ContextWeergave from './ContextWeergave';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
  mapRemoteData,
} from '../../../models/IRemoteData';
import Skeleton from 'react-loading-skeleton';
import VinkVeld from '../../formulier/VinkVeld';
import UitlegTooltip from '../../formulier/UitlegTooltip';
import { ISjabloonContext } from '../../../../../shared/src/api/v2/Sjabloon/sjabloon';
import LoadingSpinner from '../../Gedeeld/LoadingSpinner';
import {
  CommunicatiekanaalSelectie,
  ECommunicatiekanaalSelectieType,
  EOpgegevenContextSelectieType,
  ETaalSelectieType,
  IOpgegevenContext,
  TaalSelectie,
  TaalViaOpgegevenContextenBepalerOutput,
  ETaalViaOpgegevenContextenBepalerOutputType,
  SjabloonOplosserModus,
  ESjabloonOplosserModus,
  ISjabloonOplosserVerstuurModus,
} from '../types';
import {
  opgegevenContextKeyExtractor,
  communicatiekanaalNaamEnumNaarVerstuurModusKanaal,
} from '../helpers';
import { GlobaleRendererContext } from '../../../one-off-components/GlobaleRenderer';
import OpvoerenDialoog, { IOpvoerenOutput } from './OpvoerenDialoog';
import { IconChevronRight, IconToevoegen } from '../../Icons';
import Combobox, { IOptie } from '../../formulier/Combobox';
import { ICommunicatiekanaal } from '../../../../../shared/src/api/v2/bericht/Bericht';
import styled from 'styled-components';
import VerticaleScheidingslijn from '../../layout/VerticaleScheidingslijn';

const HeaderKnop = styled.button`
  outline: 0;
  border: 0;
  background: 0;
  border-radius: 5px;
  padding: 5px;
  transition: background 0.2s ease-in-out;

  &:hover {
    background: ${Kleur.LichtGrijs};
  }
`;

interface IProps {
  dialoogIndex: number;
  taalID: IRemoteData<number | null>;
  onTaalIDChange: (taalID: number | null) => void;
  comKanID: IRemoteData<number | null>;
  onComKanIDChange: (kanaalID: number | null) => void;
  genereerbaarAangestuurdeSelectie: boolean;
  onGenereerbaarAangestuurdeSelectieChange: (genereerbaarAangestuurdeSelectie: boolean) => void;
  magGenereerbaarAangestuurdeSelectieMuteren?: boolean;
  opgegevenContexten: IOpgegevenContext[];
  onOpgegevenContextenChange: (opgegevenContexten: IOpgegevenContext[]) => void;
  contexten: IRemoteData<ISjabloonContext[]>;
  magOpgegevenContextenMuteren: boolean;
  communicatiekanalen: IRemoteData<ICommunicatiekanaal[]>;
  isBezig: boolean;
  taalSelectie: TaalSelectie;
  communicatiekanaalSelectie: CommunicatiekanaalSelectie;
  taalViaOpgegevenContextenBepalerOutput: IRemoteData<TaalViaOpgegevenContextenBepalerOutput | null>;
  modus: SjabloonOplosserModus;
  inklappen: () => void;
}

const LinkerDeel = (props: IProps) => {
  const globalRenderer = useContext(GlobaleRendererContext);

  const contextenVoorOpgegevenContexten = useMemo<IRemoteData<ISjabloonContext[]>>(
    () =>
      mapRemoteData(props.contexten, (contexten) => {
        return props.opgegevenContexten.map((opgegevenContext) => {
          return contexten.find((context) => {
            switch (opgegevenContext.selectie.type) {
              case EOpgegevenContextSelectieType.NaamEnum:
                return context.NaamEnum === opgegevenContext.selectie.naamEnum;
              case EOpgegevenContextSelectieType.SjabCtxID:
                return context.SjabCtxID === opgegevenContext.selectie.sjabCtxID;
            }
          })!;
        });
      }),
    [props.opgegevenContexten, props.contexten],
  );

  const handleToevoegenClick = useMemo(
    () =>
      mapRemoteData(props.contexten, (contexten) => async () => {
        const output = await globalRenderer.render<IOpvoerenOutput | null>((renderProps) => (
          <OpvoerenDialoog
            dialoogIndex={props.dialoogIndex + 1}
            contexten={contexten}
            opgegevenContexten={props.opgegevenContexten}
            open
            onSuccess={(output) => renderProps.destroy(output)}
            onAnnuleren={() => renderProps.destroy(null)}
          />
        ));

        if (output === null) {
          return;
        }
        props.onOpgegevenContextenChange([...props.opgegevenContexten, output!.opgegevenContext]);
      }),
    [
      props.dialoogIndex,
      props.contexten,
      props.opgegevenContexten,
      props.onOpgegevenContextenChange,
    ],
  );

  const kanalenOpties = useMemo<IRemoteData<IOptie<number>[]>>(() => {
    if (props.communicatiekanalen.state === ERemoteDataState.Pending) {
      return createPendingRemoteData();
    }
    let bron: ICommunicatiekanaal[];
    switch (props.modus.type) {
      case ESjabloonOplosserModus.Selecteer:
        bron = props.communicatiekanalen.data!;
        break;
      case ESjabloonOplosserModus.Genereer:
        bron = props.communicatiekanalen.data!;
        break;
      case ESjabloonOplosserModus.Verstuur:
        const verstuurmodus = props.modus as ISjabloonOplosserVerstuurModus;
        bron = props.communicatiekanalen.data!.filter((x) => {
          const verstuurModusKanaal = communicatiekanaalNaamEnumNaarVerstuurModusKanaal(x.NaamEnum);
          if (verstuurModusKanaal === null) {
            return false;
          }
          return verstuurmodus.kanalen[verstuurModusKanaal] !== undefined;
        });
        break;
    }

    const opties = bron.map(
      (communicatiekanaal): IOptie<number> => {
        return {
          id: communicatiekanaal.ComKanID,
          label: communicatiekanaal.Naam,
        };
      },
    );
    return createReadyRemoteData(opties);
  }, [props.communicatiekanalen, props.modus]);

  return (
    <div
      style={{
        width: 350,
        borderRight: `1px solid ${Kleur.LichtGrijs}`,
      }}
      className="d-flex flex-column"
    >
      <div
        className="d-flex align-items-center"
        style={{
          padding: '12px 10px 12px 15px',
        }}
      >
        <h5 className="flex-fill m-0">Contexten</h5>
        {handleToevoegenClick.state === ERemoteDataState.Ready &&
          props.magOpgegevenContextenMuteren && (
            <div className="mr-2 d-flex align-items-center">
              <HeaderKnop
                title="Handmatig context toevoegen"
                onClick={handleToevoegenClick.data!}
                disabled={props.isBezig}
              >
                <IconToevoegen
                  style={{
                    fill: props.isBezig ? Kleur.LichtGrijs : Kleur.Grijs,
                    width: 24,
                    height: 24,
                  }}
                />
              </HeaderKnop>
              <VerticaleScheidingslijn className="ml-2" height={28} />
            </div>
          )}
        <HeaderKnop title="Inklappen" onClick={props.inklappen} disabled={props.isBezig}>
          <IconChevronRight
            style={{
              fill: props.isBezig ? Kleur.LichtGrijs : Kleur.Grijs,
              width: 25,
              height: 25,
              transform: 'rotate(180deg)',
            }}
          />
        </HeaderKnop>
      </div>
      {props.opgegevenContexten.length === 0 ? (
        <div className="flex-fill d-flex flex-column align-items-center justify-content-center">
          <span>Er zijn nog geen opgegeven contexten.</span>
        </div>
      ) : contextenVoorOpgegevenContexten.state === ERemoteDataState.Pending ? (
        <div className="flex-fill d-flex flex-column align-items-center justify-content-center">
          <LoadingSpinner />
        </div>
      ) : (
        <div
          className="flex-fill d-flex flex-column"
          style={{
            padding: '12px 15px 12px 15px',
            overflowY: 'auto',
            backgroundColor: Kleur.HeelLichtGrijs,
            borderTop: `1px solid ${Kleur.LichtGrijs}`,
            borderBottom: `1px solid ${Kleur.LichtGrijs}`,
          }}
        >
          {props.opgegevenContexten.map((opgegevenContext, i) => {
            const key = opgegevenContextKeyExtractor(opgegevenContext);
            const context = contextenVoorOpgegevenContexten.data![i]!;
            return (
              <div key={key} className={'mb-3'}>
                <ContextWeergave
                  opgegevenContext={opgegevenContext}
                  context={context}
                  onVerwijderen={() => {
                    const nieuweContexten = [
                      ...props.opgegevenContexten.slice(0, i),
                      ...props.opgegevenContexten.slice(i + 1),
                    ];
                    props.onOpgegevenContextenChange(nieuweContexten);
                  }}
                  magOpgegevenContextenMuteren={props.magOpgegevenContextenMuteren}
                  isBezig={props.isBezig}
                />
              </div>
            );
          })}
        </div>
      )}

      <div>
        <h5
          style={{
            padding: '10px 15px 0 15px',
          }}
          className="m-0"
        >
          Taal
        </h5>
        <div
          className="d-flex flex-column"
          style={{
            padding: '12px 15px',
          }}
        >
          {props.taalID.state === ERemoteDataState.Pending ? (
            <Skeleton width={315} height={30} />
          ) : (
            <FormTaalSelectie
              taalID={props.taalID.data}
              onChange={props.onTaalIDChange}
              disabled={
                props.isBezig ||
                (props.taalSelectie.type === ETaalSelectieType.Opgegeven &&
                  !props.taalSelectie.isMuteerbaar) ||
                (props.taalSelectie.type === ETaalSelectieType.AutomatischBepalenViaContexten &&
                  !props.taalSelectie.isMuteerbaar)
              }
            />
          )}
          {props.taalSelectie.type === ETaalSelectieType.AutomatischBepalenViaContexten ? (
            props.taalViaOpgegevenContextenBepalerOutput.state === ERemoteDataState.Pending ? (
              <span className="d-flex align-items-center mt-2 text-muted">
                Taal wordt automatisch bepalen via contexten...
              </span>
            ) : props.taalViaOpgegevenContextenBepalerOutput.data !== null &&
              props.taalViaOpgegevenContextenBepalerOutput.data!.type ===
                ETaalViaOpgegevenContextenBepalerOutputType.NietBepaald ? (
              <span className="d-flex align-items-center mt-2 text-muted">
                Taal kon niet worden automatisch bepaald via contexten.
              </span>
            ) : props.taalViaOpgegevenContextenBepalerOutput.data !== null &&
              props.taalViaOpgegevenContextenBepalerOutput.data!.type ===
                ETaalViaOpgegevenContextenBepalerOutputType.Bepaald ? (
              <span className="d-flex align-items-center mt-2 text-muted">
                Taal automatisch
                {props.taalViaOpgegevenContextenBepalerOutput.data!.eenduidigBepaald ? (
                  <span>&nbsp;eenduidig&nbsp;</span>
                ) : (
                  <span>&nbsp;</span>
                )}
                bepaald via contexten.
              </span>
            ) : null
          ) : props.taalSelectie.type === ETaalSelectieType.Opgegeven &&
            !props.taalSelectie.isMuteerbaar ? (
            <span className="d-flex align-items-center mt-2 text-muted">
              Taal is vanaf buitenaf vastgezet.
            </span>
          ) : null}
        </div>
      </div>

      <div
        style={{
          borderTop: `1px solid ${Kleur.LichtGrijs}`,
        }}
      >
        <h5
          style={{
            padding: '10px 15px 0 15px',
          }}
          className="m-0"
        >
          Kanaal
        </h5>
        <div
          className="d-flex flex-column"
          style={{
            padding: '12px 15px',
          }}
        >
          {props.comKanID.state === ERemoteDataState.Pending ||
          kanalenOpties.state === ERemoteDataState.Pending ? (
            <Skeleton width={315} height={30} />
          ) : (
            <Combobox
              geselecteerd={props.comKanID.data!}
              opties={kanalenOpties.data!}
              onSelectieChange={props.onComKanIDChange}
              disabled={
                props.isBezig ||
                (props.communicatiekanaalSelectie.type ===
                  ECommunicatiekanaalSelectieType.Opgegeven &&
                  !props.communicatiekanaalSelectie.isMuteerbaar)
              }
              isWisbaar
            />
          )}
          {props.communicatiekanaalSelectie.type === ECommunicatiekanaalSelectieType.Opgegeven &&
          !props.communicatiekanaalSelectie.isMuteerbaar ? (
            <span className="d-flex align-items-center mt-2 text-muted">
              Kanaal is vanaf buitenaf vastgezet.
            </span>
          ) : null}
        </div>
      </div>

      <div
        className="d-flex align-items-center"
        style={{
          borderTop: `1px solid ${Kleur.LichtGrijs}`,
          padding: '12px 15px',
        }}
      >
        <VinkVeld
          aangevinkt={props.genereerbaarAangestuurdeSelectie}
          onGewijzigd={props.onGenereerbaarAangestuurdeSelectieChange}
          disabled={props.isBezig || props.magGenereerbaarAangestuurdeSelectieMuteren === false}
        />
        <UitlegTooltip
          inhoud={
            <div>
              <p>
                Dit wil zeggen dat de gegevens die links worden gevuld gebruikt zullen worden om
                sommige selectie elementen in de lijst weergave aan te sturen.
              </p>
              <p>
                Door deze optie uit te vinken, heb je meer controle over de lijstweergave, maar kan
                het mogelijk sjablonen weergeven die je niet kunt omzetten met de opgegeven
                informatie.
              </p>
            </div>
          }
        >
          <span className="ml-2">Genereerbaar aangestuurde selectie</span>
        </UitlegTooltip>
      </div>
    </div>
  );
};

export default LinkerDeel;
