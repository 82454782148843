import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import IDialoogProps from '../../../../core/IDialoogProps';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { Field, FieldProps, Formik, FormikActions, FormikErrors, FormikProps } from 'formik';
import Combobox, { IOptie } from '../../../../components/formulier/Combobox';
import { RootStoreContext } from '../../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import api from '../../../../api';
import { EResultType } from '../../../../stores/CheckStore';
import * as Yup from 'yup';
import teksten from '../../../../bedrijfslogica/teksten';
import FormikVeldFout from '../../../../components/formulier/FormikVeldFout';
import { IOphalenMagazijnenResultElement } from '../../../../../../shared/src/api/v2/magazijn';
import { IOphalenDienstenResultElement } from '../../../../../../shared/src/api/v2/dienst/pendel';
import Dialoog from '../../../../components/dialogen/Dialoog';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import { IOphalenOpdrachtenResultElement } from '../../../../../../shared/src/api/v2/pendel';
import VinkVeld from '../../../../components/formulier/VinkVeld';
import { format } from 'date-fns';
import moment from 'moment';
import { Kleur as EKleur } from '../../../../bedrijfslogica/constanten';

interface IProps extends IDialoogProps<{}> {
  prodIDs: number[];
  initieelDienstID: number | null;
  initieelLadenMagID: number;
  initieelLossenMagID: number | null;
}

interface IFormikValues {
  toevoegenAanBestaandeOpdracht: boolean;
  opdrachtID: number | null;
  magazijnLossenID: number;
  dienstID: number;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  toevoegenAanBestaandeOpdracht: 'Toevoegen aan bestaande opdracht',
  opdrachtID: 'Opdracht',
  magazijnLossenID: 'Naar magazijn',
  dienstID: 'Pendeldienst',
};

const ToevoegenAanPendelDialoog = observer((props: IProps) => {
  const { onAnnuleren, onSuccess, open } = props;
  const { checkStore } = useContext(RootStoreContext);
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const [magazijnen, setMagazijnen] = useState<IOphalenMagazijnenResultElement[] | null>(null);
  const [initieelLossenMagID, setInitieelLossenMagID] = useState<number | null>(
    props.initieelLossenMagID,
  );

  const [diensten, setDiensten] = useState<IOphalenDienstenResultElement[] | null>(null);
  const [initieelDienstID, setInitieelDienstID] = useState<number | null>(props.initieelDienstID);

  const [opdrachten, setOpdrachten] = useState<IOphalenOpdrachtenResultElement[] | null>(null);
  // Magazijnen
  useEffect(() => {
    (async () => {
      const result = await api.v2.magazijn.ophalenMagazijnen({
        filterSchema: {
          // filters: [
          //   {
          //     naam: 'IS_ACTIEF',
          //     data: true,
          //   },
          // ],
          uitgebreideFilter: {
            and: [
              {
                filter: {
                  naam: 'IS_ACTIEF',
                  data: true,
                },
              },
              {
                or: [
                  {
                    filter: {
                      naam: 'IS_REVISIE',
                      data: true,
                    },
                  },
                  {
                    filter: {
                      naam: 'IS_VOORRAAD',
                      data: true,
                    },
                  },
                ],
              },
            ],
          },

          // TODO: Tijdelijk weggehaald, uitzoeken waarom deze wordt uitgesloten + dat hiervan geen entry in de rijbron is.
          // filters: [{ naam: 'IS_ACTIEF', data: true }],
          // uitgebreideFilter: {
          //   or: [
          //     {
          //       filter: {
          //         naam: 'IS_REVISIE',
          //         data: true,
          //       },
          //     },
          //     {
          //       filter: {
          //         naam: 'IS_VOORRAAD',
          //         data: true,
          //       },
          //     },
          //   ],
          // },
        },
      });
      setMagazijnen(result.magazijnen);
    })();
  }, [props.initieelLadenMagID]);

  // Pendeldiensten
  useEffect(() => {
    (async () => {
      const result = await api.v2.dienst.pendel.ophalenDiensten({
        filterSchema: { filters: [{ naam: 'IS_ACTIEF', data: true }] },
      });
      setDiensten(result.diensten);
    })();
  }, []);

  // Initiele waarde voor de magazijnen
  useEffect(() => {
    if (magazijnen === null) {
      return;
    }

    if (initieelLossenMagID !== null) {
      setInitieelLossenMagID(initieelLossenMagID);
    } else {
      setInitieelLossenMagID(magazijnen[0].MagID);
    }
  }, [magazijnen, initieelLossenMagID]);

  // Initiele waarde voor de pendeldienst
  useEffect(() => {
    if (diensten === null) {
      return;
    }

    if (initieelDienstID !== null) {
      setInitieelDienstID(initieelDienstID);
    } else {
      setInitieelDienstID(diensten[0].ID);
    }
  }, [diensten, initieelDienstID]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      setHasSubmitted(true);

      actions.setSubmitting(true);

      if (opdrachten === null) {
        setHasSubmitted(false);
        actions.setSubmitting(false);
        return;
      }

      // Bepaal of het product aan een bestaande of nieuwe opdracht toegevoegd moet worden
      const opdracht = opdrachten.find((o) => o.ID == values.opdrachtID);

      const bestaandeOpdracht = values.toevoegenAanBestaandeOpdracht;
      // zet mogelijk bestaande opdracht ID
      const penOpdID = !bestaandeOpdracht || opdracht == null ? null : opdracht.ID;

      // of maak anders een nieuwe opdracht aan
      const opdrachtNieuw = bestaandeOpdracht
        ? null
        : {
            dienstID: values.dienstID,
            laden_MagID: props.initieelLadenMagID,
            lossen_MagID: values.magazijnLossenID,
          };

      const params = {
        prodIDs: props.prodIDs,
        penOpdID: penOpdID,
        opdrachtNieuw,
      };

      const checkData = await api.v2.pendel.checkToevoegenProductenAanPendel(params);
      if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
        actions.setSubmitting(false);
        return;
      }

      if (bestaandeOpdracht && opdracht?.DatumVerstuurd !== null) {
        if (
          (
            await checkStore.bevestigen({
              inhoud: `Opdracht ${opdracht?.Opdrachtnummer} is al verstuurd. Weet je zeker dat je nog iets aan deze opdracht wil toevoegen?`,
            })
          ).type === EResultType.Annuleren
        ) {
          actions.setSubmitting(false);
          return;
        }
      }

      await api.v2.pendel.toevoegenProductenAanPendel(params);

      onSuccess({});
      actions.setSubmitting(false);
    },
    [
      onSuccess,
      setHasSubmitted,
      props.prodIDs,
      props.initieelLadenMagID,
      initieelDienstID,
      opdrachten,
    ],
  );

  const magazijnenOpties = useMemo<IOptie<number>[] | null>(() => {
    if (magazijnen === null) {
      return null;
    }

    return magazijnen.map(
      (magazijn): IOptie<number> => ({
        id: magazijn.MagID,
        label: magazijn.NaamKort,
      }),
    );
  }, [magazijnen]);

  const dienstenOpties = useMemo<IOptie<number>[] | null>(() => {
    if (diensten === null) {
      return null;
    }

    return diensten.map(
      (dienst): IOptie<number> => ({
        id: dienst.ID,
        label: dienst.relatie!.weergavenaam,
      }),
    );
  }, [diensten]);

  const initieleWaarden = useMemo<IFormikValues | null>(() => {
    if (initieelDienstID === null || initieelLossenMagID === null || opdrachten === null) {
      return null;
    }

    return {
      toevoegenAanBestaandeOpdracht: opdrachten.length !== 0,
      opdrachtID: opdrachten.length !== 0 ? opdrachten[0].ID : null,
      magazijnLossenID: initieelLossenMagID,
      dienstID: initieelDienstID,
    };
  }, [initieelDienstID, initieelLossenMagID, opdrachten]);

  const validationSchema = useMemo(() => {
    return Yup.object().shape({});
  }, [teksten, veldnamen]);

  // Opdrachten
  const ophalenBestaandeOpdrachten = useCallback(async () => {
    // Bepalen aan welke opdracht dit product toegevoegd kan worden

    const result = await api.v2.pendel.ophalenOpdrachten({
      filterSchema: {
        filters: [
          { naam: 'IS_AFGEHANDELD', data: false },
          { naam: 'IS_GESLOTEN', data: false },
          { naam: 'MAG_LADEN', data: [props.initieelLadenMagID] },
        ],
      },
      orderSchema: { orders: [{ naam: 'OPDRACHTNUMMER', richting: 'DESC' }] },
    });
    setOpdrachten(result.opdrachten.filter((o) => o.regels.length < o.MaxAantalProducten));
  }, [props.initieelLadenMagID]);

  useEffect(() => {
    ophalenBestaandeOpdrachten();
  }, [ophalenBestaandeOpdrachten]);

  if (
    initieleWaarden === null ||
    magazijnenOpties === null ||
    dienstenOpties === null ||
    initieelLossenMagID === null ||
    initieelDienstID === null ||
    opdrachten === null
  ) {
    return (
      <div className="flex-fill d-flex align-items-center justify-content-center m-5">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <Dialoog index={props.dialoogIndex || 0}>
      <>
        <ModalHeader>
          <ModalTitle>Toevoegen aan pendel</ModalTitle>
        </ModalHeader>
        {
          <Formik<IFormikValues>
            onSubmit={handleSubmit}
            initialValues={initieleWaarden}
            isInitialValid
            validationSchema={validationSchema}
            render={(formikProps: FormikProps<IFormikValues>) => {
              const { submitForm, isSubmitting, values, isValid } = formikProps;
              return (
                <>
                  <ModalBody>
                    <div className="form-group">
                      <div className="row">
                        {opdrachten.length !== 0 && (
                          <div className="col-12 d-flex">
                            <Field
                              name="toevoegenAanBestaandeOpdracht"
                              render={(fieldProps: FieldProps<IFormikValues>) => {
                                const { field, form } = fieldProps;
                                return (
                                  <VinkVeld
                                    aangevinkt={field.value}
                                    onGewijzigd={(x) => {
                                      form.setFieldValue(field.name, x);
                                    }}
                                    disabled={opdrachten.length === 0}
                                  />
                                );
                              }}
                            />
                            <label>
                              {veldnamen.toevoegenAanBestaandeOpdracht} ({opdrachten.length})
                            </label>
                          </div>
                        )}

                        {values.toevoegenAanBestaandeOpdracht ? (
                          <div className="col-12 mt-3">
                            <label>{veldnamen.opdrachtID}</label>
                            <Field
                              name="opdrachtID"
                              bestaande
                              render={(fieldProps: FieldProps<IFormikValues>) => {
                                const { field, form } = fieldProps;
                                return (
                                  <>
                                    <Combobox
                                      geselecteerd={field.value}
                                      opties={
                                        opdrachten == null
                                          ? []
                                          : opdrachten.map((opdracht) => {
                                              const naamGedeelte =
                                                opdracht.dienst?.relatie?.weergavenaam == null
                                                  ? ''
                                                  : `door ${opdracht.dienst?.relatie?.weergavenaam}`;

                                              const datumGedeelte =
                                                opdracht.DatumGepland == null
                                                  ? ''
                                                  : `op ${format(
                                                      moment(opdracht.DatumGepland).toDate(),
                                                      'dd-MM-yyyy',
                                                    )}`;

                                              const vanGedeelte = `van ${opdracht.magazijn.laden.NaamKort}`;
                                              const naarGedeelte = `naar ${opdracht.magazijn.lossen.NaamKort}`;

                                              return {
                                                id: opdracht.ID,
                                                label: `${opdracht.Opdrachtnummer} ${vanGedeelte} ${naarGedeelte} ${naamGedeelte} ${datumGedeelte}`,
                                              };
                                            })
                                      }
                                      onSelectieChange={(id) => {
                                        form.setFieldValue(field.name, id);
                                      }}
                                      options={{
                                        legeOptieTekst:
                                          opdrachten != null && opdrachten.length == 0
                                            ? 'Geen bestaande opdrachten gevonden'
                                            : 'Selecteer een opdracht',
                                      }}
                                    />
                                    <FormikVeldFout
                                      fieldProps={fieldProps}
                                      directTonen={hasSubmitted}
                                    />
                                  </>
                                );
                              }}
                            />
                          </div>
                        ) : (
                          <>
                            {/* <div className="col-12 mt-3">
                              <h5>Nieuwe Opdracht</h5>
                            </div> */}
                            <div className="col-12 mt-3">
                              <label>{veldnamen.magazijnLossenID}</label>
                              <Field
                                name="magazijnLossenID"
                                render={(fieldProps: FieldProps<IFormikValues>) => {
                                  const { field, form } = fieldProps;
                                  return (
                                    <>
                                      <Combobox
                                        geselecteerd={field.value}
                                        opties={magazijnenOpties === null ? [] : magazijnenOpties}
                                        onSelectieChange={(id) => {
                                          form.setFieldValue(field.name, id);
                                        }}
                                        legeOptieTonen
                                        options={{
                                          legeOptieTekst: 'Geen magazijn',
                                        }}
                                      />
                                      <FormikVeldFout
                                        fieldProps={fieldProps}
                                        directTonen={hasSubmitted}
                                      />
                                    </>
                                  );
                                }}
                              />
                            </div>
                            <div className="col-12 mt-3">
                              <label>{veldnamen.dienstID}</label>
                              <Field
                                name="dienstID"
                                render={(fieldProps: FieldProps<IFormikValues>) => {
                                  const { field, form } = fieldProps;
                                  return (
                                    <>
                                      <Combobox
                                        geselecteerd={field.value}
                                        opties={dienstenOpties === null ? [] : dienstenOpties}
                                        onSelectieChange={(id) => {
                                          form.setFieldValue(field.name, id);
                                        }}
                                        legeOptieTonen
                                        options={{
                                          legeOptieTekst: 'Geen dienst',
                                        }}
                                      />
                                      <FormikVeldFout
                                        fieldProps={fieldProps}
                                        directTonen={hasSubmitted}
                                      />
                                    </>
                                  );
                                }}
                              />
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </ModalBody>
                  <ModalFooter className="d-flex justify-content-start">
                    <button
                      className="btn btn-primary"
                      onClick={submitForm}
                      disabled={
                        isSubmitting ||
                        !isValid ||
                        (values.toevoegenAanBestaandeOpdracht && values.opdrachtID === null)
                      }
                      style={{ width: 100 }}
                    >
                      Ok
                    </button>
                    <button
                      className="btn btn-secondary"
                      onClick={onAnnuleren}
                      style={{ width: 100 }}
                    >
                      Annuleren
                    </button>
                  </ModalFooter>
                </>
              );
            }}
          />
        }
      </>
    </Dialoog>
  );
});

export default ToevoegenAanPendelDialoog;
