import { EFilter } from '..';
import { IWeergaveProps } from '../../../../../components/FilterBalkV2';
import React from 'react';
import LoadingSpinner from '../../../../../components/Gedeeld/LoadingSpinner';
import Combobox from '../../../../../components/formulier/Combobox';
import { IOphalenProductsoortenResult } from '../../../../../../../shared/src/api/v2/product/soort';

interface IProps extends IWeergaveProps<any> {
  productsoorten: IOphalenProductsoortenResult;
}

const ProductsoortFilter = (props: IProps) => {
  return (
    <div className="d-flex align-items-center">
      <span>Categorie</span>
      <span className="ml-3">
        {props.productsoorten === null ? (
          <LoadingSpinner />
        ) : (
          <Combobox
            geselecteerd={props.data}
            onSelectieChange={(x) => {
              props.onDataChange(x!);
              props.setIsActief(true);
              props.toepassen();
            }}
            opties={props.productsoorten.map((soort: any) => ({
              id: soort.ProdSrtID,
              label: soort.Naam,
            }))}
          />
        )}
      </span>
    </div>
  );
};

export default ProductsoortFilter;
