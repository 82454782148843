import React, { useContext, useEffect, useMemo, useState, useCallback } from 'react';
import useUrlState from '../../../core/useUrlState';
import { RouteComponentProps, withRouter } from 'react-router';
import { observer } from 'mobx-react-lite';
import api from '../../../api';
import { RootStoreContext } from '../../../stores/RootStore';
import LoadingSpinner from '../../../components/Gedeeld/LoadingSpinner';
import {
  IOphalenBetalingsregelingenResultElement,
  IOphalenStatussenResult,
} from '../../../../../shared/src/api/v2/debiteur/betalingsregeling';
import { Kleur } from '../../../bedrijfslogica/constanten';
import FilterBalkV2, {
  IFilter,
  IFilterData,
  maakFilterSchema,
} from '../../../components/FilterBalkV2';
import { IFilterSchema } from '../../../../../shared/src/models/filter';
import MultiSelect, { IOptie } from '../../../components/formulier/MultiSelect';
import BetalingsregelingTabel, {
  IBetalingsregeling,
  IBetalingsregelingInfoDialoogState,
  IBoekingInfoDialoogState,
  IWijzigenBetalingsregelingDialoogState,
} from '../../../components/debiteuren/BetalingsregelingenTabel';
import VerticaleScheidingslijn from '../../../components/layout/VerticaleScheidingslijn';
import { Helmet } from 'react-helmet';
import { IOphalenBoekingRegelsResultElement } from '../../../../../shared/src/api/v2/boekhouding/boeking';

enum EFilter {
  Status = 'STATUS_NAAM_ENUMS',
}

interface IProps extends RouteComponentProps {}

interface IUrlState {
  selectie: number[];
  filterData: IFilterData<EFilter>[];
  wijzigenBetalingsregelingDialoogState: IWijzigenBetalingsregelingDialoogState | null;
  boekingInfoDialoogState: IBoekingInfoDialoogState | null;
  betalingsregelingInfoDialoogState: IBetalingsregelingInfoDialoogState | null;
}

const defaultUrlState: IUrlState = {
  selectie: [],
  filterData: [
    {
      naam: EFilter.Status,
      data: ['LOPEND'],
      isActief: true,
    },
  ],
  wijzigenBetalingsregelingDialoogState: null,
  boekingInfoDialoogState: null,
  betalingsregelingInfoDialoogState: null,
};

const Betalingsregelingen: React.FC<IProps> = observer((props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);

  const [betalingsregelingen, setBetalingsregelingen] = useState<IBetalingsregeling[] | null>(null);

  const [filterSchema, setFilterSchema] = useState<IFilterSchema>(
    maakFilterSchema(urlState.filterData),
  );

  const [statussenResult, setStatussen] = useState<IOphalenStatussenResult | null>(null);
  const ophalenStatussen = useCallback(async () => {
    const result = await api.v2.debiteur.betalingsregeling.ophalenStatussen({});
    setStatussen(result);
  }, []);

  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    ophalenStatussen();
  }, []);

  const ophalenBetalingsregelingen = useCallback(async () => {
    const regelingenResult = await api.v2.debiteur.betalingsregeling.ophalenBetalingsregelingen({
      filterSchema,
    });

    const betRglIDs = regelingenResult.betalingsregelingen.map((x) => x.ID);

    const boekingsregelsResult = await api.v2.boekhouding.boeking.ophalenBoekingregels({
      filterSchema: { filters: [{ naam: 'BETRGL_IDS', data: betRglIDs }] },
    });

    const betalingsregelingen = regelingenResult.betalingsregelingen.map((x) => {
      const boekingsregels = boekingsregelsResult.regels.filter((r) => r.BetRglID === x.ID);
      return { ...x, boekingsregels };
    });

    setBetalingsregelingen(betalingsregelingen);
  }, [filterSchema]);

  useEffect(() => {
    ophalenBetalingsregelingen();
  }, [ophalenBetalingsregelingen]);

  const filters: IFilter<EFilter>[] = useMemo(
    () => [
      {
        naam: EFilter.Status,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return (
            <span className="d-flex align-items-center">
              <span className="mr-3">Status</span>
              {statussenResult === null ? (
                <LoadingSpinner />
              ) : (
                <MultiSelect
                  value={weergaveProps.data}
                  onChange={(data) => {
                    weergaveProps.onDataChange(data);
                    weergaveProps.toepassen();
                  }}
                  opties={statussenResult.statussen.map(
                    (status): IOptie<string> => {
                      return { key: status.NaamEnum, weergave: status.Naam };
                    },
                  )}
                  isVerwijderbaar={false}
                />
              )}
            </span>
          );
        },
      },
    ],
    [statussenResult],
  );

  return (
    <>
      <Helmet>
        <title>Betalingsregelingen</title>
      </Helmet>
      <BetalingsregelingTabel
        betalingsregelingen={betalingsregelingen}
        selectie={urlState.selectie}
        onSelectieChange={(x) => setUrlStateSync('selectie', x)}
        wijzigenBetalingsregelingDialoogState={urlState.wijzigenBetalingsregelingDialoogState}
        onWijzigenBetalingsregelingDialoogStateChange={(x) =>
          setUrlStateSync('wijzigenBetalingsregelingDialoogState', x)
        }
        boekingInfoDialoogState={urlState.boekingInfoDialoogState}
        onWijzigenBoekingInfoDialoogState={(x) => setUrlStateSync('boekingInfoDialoogState', x)}
        betalingsregelingInfoDialoogState={urlState.betalingsregelingInfoDialoogState}
        onWijzigenBetalingsregelingInfoDialoogState={(x) =>
          setUrlStateSync('betalingsregelingInfoDialoogState', x)
        }
        onVerversenAangevraagd={async () => await ophalenBetalingsregelingen()}
        onRelatieClick={(relID) => {
          props.history.push(`/klant/${relID}/debiteur/betalingsregelingen`);
        }}
        menuComponent={(p) => {
          return (
            <div className="d-flex flex-fill">
              <div className="d-flex align-items-center">{p.children}</div>
              <VerticaleScheidingslijn className="ml-4 mr-2" />

              <FilterBalkV2
                filters={filters}
                filterData={urlState.filterData}
                onFilterDataChange={(x) => setUrlStateSync('filterData', x)}
                onFilterSchemaChange={(x) => setFilterSchema(x)}
                style={{ width: 'inherit', flex: 1 }}
              />
            </div>
          );
        }}
      />
    </>
  );
});

export default withRouter(Betalingsregelingen);
