import React from 'react';
import { IBepaalTekstOvereenkomingOutput } from '../../helpers/tekst';
import { Kleur } from '../../bedrijfslogica/constanten';

interface IProps {
  sleutel: number;
  tekst: string;
  output: IBepaalTekstOvereenkomingOutput;
}

const TekstOvereenkomingWeergave = (props: IProps) => {
  const { sleutel, tekst, output } = props;
  const idx = output.overeenkomingIndexMap[sleutel];
  if (idx === undefined) {
    return <span>{tekst}</span>;
  }
  const overeenkoming = output.overeenkomingen[idx];

  const spans = tekst.split('').map((char, charIndex) => {
    if (overeenkoming[charIndex]) {
      return <span>{char}</span>;
    }
    return (
      <span style={{ color: Kleur.Rood }} className="font-weight-bold">
        {char}
      </span>
    );
  });

  return <div className="d-flex align-items-center">{spans}</div>;
};

export default TekstOvereenkomingWeergave;
