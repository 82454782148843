import styled from 'styled-components';
import { Kleur } from '../../../bedrijfslogica/constanten';

interface IRootProps {
  backgroundImageSrc?: string;
}

export const Root = styled.div<IRootProps>`
  //margin: 2px 5px;

  height: 100%;
  width: 215px;
  border: 1px solid #e2e2e2;
  padding: 2px 10px 0;
  position: relative;
  text-align: left;
  ${(props) => {
    if (props.backgroundImageSrc) {
      return `
          background:linear-gradient(0deg, rgba(255, 255, 255, 0.85), rgba(255, 255, 255, 0.85)), url(${props.backgroundImageSrc});
          background-size:cover;
          background-position: center;
      `;
    }
    return `background-color: white;`;
  }}

  &:hover {
    // background-color: ${Kleur.LichtGrijs};
  }
`;
