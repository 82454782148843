import React, { useCallback, useMemo } from 'react';
import ASPTabel from '../../../tabel/ASPTabel';
import { IOphalenContactpersonenResultElement } from '../../../../../../shared/src/api/v2/relatie/contactpersoon';
import { ASPKolom, EAspKolomBreedteType } from '../../../tabel/ASPTabel/types';
import { formatteerPersoonNaam } from '../../../../helpers';
import VinkVeld from '../../../formulier/VinkVeld';
import { IPersoon } from '../index';

interface IProps {
  personen: IOphalenContactpersonenResultElement[];
  personenVanLocatieMap: { [relPersID: number]: IPersoon };
  selectie: number[];
  onSelectieChange: (selectie: number[]) => void;
  onVerwijderen: (persoon: IOphalenContactpersonenResultElement) => Promise<void>;
  onPersonenGewijzigd: (personen: IPersoon[]) => void;
}

type Kolom = 'naam' | 'service' | 'transport';

const PersonenVanLocatieTabel = (props: IProps): JSX.Element => {
  const keyExtractor = useCallback(
    (persoon: IOphalenContactpersonenResultElement) => persoon.ID,
    [],
  );
  const kolommen = useMemo<ASPKolom<Kolom, IOphalenContactpersonenResultElement>[]>(
    () => [
      {
        key: 'naam',
        label: 'Naam',
        breedteType: EAspKolomBreedteType.Flex,
        flex: 1,
        renderer: (persoon) =>
          formatteerPersoonNaam({
            aanhefKort: persoon.geslacht.AanhefKort,
            achternaam: persoon.Achternaam,
            voorletters: persoon.Voorletters,
            voornaam: persoon.Voornaam,
            voorvoegsel: persoon.Voorvoegsel,
          }),
      },
      {
        key: 'service',
        label: 'Service',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 100,
        renderer: (persoon) => {
          const persoonVanLocatie = props.personenVanLocatieMap[persoon.ID];
          return (
            <VinkVeld
              aangevinkt={persoonVanLocatie.service}
              onGewijzigd={() => {
                const personen = Object.values(props.personenVanLocatieMap).map((p) => {
                  if (p.relPersID === persoon.ID) {
                    return {
                      ...p,
                      service: !p.service,
                    };
                  }
                  return p;
                });
                props.onPersonenGewijzigd(personen);
              }}
            />
          );
        },
      },
      {
        key: 'transport',
        label: 'Transport',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 100,
        renderer: (persoon) => {
          const persoonVanLocatie = props.personenVanLocatieMap[persoon.ID];
          return (
            <VinkVeld
              aangevinkt={persoonVanLocatie.transport}
              onGewijzigd={() => {
                const personen = Object.values(props.personenVanLocatieMap).map((p) => {
                  if (p.relPersID === persoon.ID) {
                    return {
                      ...p,
                      transport: !p.transport,
                    };
                  }
                  return p;
                });
                props.onPersonenGewijzigd(personen);
              }}
            />
          );
        },
      },
    ],
    [props.personenVanLocatieMap, props.onPersonenGewijzigd],
  );

  return (
    <ASPTabel
      rijen={props.personen}
      kolommen={kolommen}
      keyExtractor={keyExtractor}
      selectie={props.selectie}
      onSelectieChange={props.onSelectieChange}
      onVerwijderenRij={props.onVerwijderen}
      verwijderenRijConfirmatie={null}
    />
  );
};

export default PersonenVanLocatieTabel;
