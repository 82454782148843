import api from '../../../..';
import {
  IOphalenBudgetcodesParams,
  IOphalenBudgetcodesResult,
} from '../../../../../../../shared/src/api/v2/rubriek/budgetcode';

const budgetcode = {
  ophalenBudgetcodes: async (
    params: IOphalenBudgetcodesParams,
  ): Promise<IOphalenBudgetcodesResult> => {
    return await api.post('/v2/factuur/rubriek/budgetcode/ophalen-budgetcodes', params);
  },
};

export default budgetcode;
