import React from 'react';
import { IEmailBijlageBestand } from '../../../../../../../../shared/src/api/v2/communicatie/email';
import styled from 'styled-components';
import BijlageItem from './BijlageItem';
import { IEmailBerBest } from '../../../../../../../../shared/src/api/v2/email-v2';

const Root = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 5px;
`;

interface IProps {
  bijlagen: IEmailBerBest[];
}

const Bijlagen = (props: IProps) => {
  return (
    <Root>
      {props.bijlagen.map((bijlage) => {
        return (
          <div key={bijlage.ID} style={{ margin: 3, maxWidth: '100%' }}>
            <BijlageItem bijlage={bijlage} />
          </div>
        );
      })}
    </Root>
  );
};

export default Bijlagen;
