import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  Column,
  DataTypeProvider,
  SelectionState,
  TableColumnWidthInfo,
} from '@devexpress/dx-react-grid';
import {
  DXTableCheckboxComponent,
  GridStyleWrapper,
} from '../../../../../../../helpers/dxTableGrid';
import { Kleur } from '../../../../../../../bedrijfslogica/constanten';
import api from '../../../../../../../api';
import { format } from 'date-fns';
import LoadingSpinner from '../../../../../../../components/Gedeeld/LoadingSpinner';
import { IPersoonDuplicaatSuggestieDuplicaat } from '../../../../../../../../../shared/src/api/v2/persoon/duplicaat';
import { IOphalenPersonenResultElementV2 } from '../../../../../../../../../shared/src/api/v2/persoon/persoon';
import PersoonVisualisatie from '../../../../../../../components/personalia/PersoonVisualisatie';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../../../../models/IRemoteData';
import {
  IOphalenContactpersonenResult,
  IOphalenContactpersonenResultElement,
} from '../../../../../../../../../shared/src/api/v2/relatie/contactpersoon';
import RelatieVisualisatie from '../../../../../../../components/personalia/RelatieVisualisatie';

interface IProps {
  duplicaten: IPersoonDuplicaatSuggestieDuplicaat[];
}

const DuplicatenTabel: React.FC<IProps> = (props) => {
  const { duplicaten } = props;

  const persIDs = useMemo(() => duplicaten.map((x) => x.PersID), [duplicaten]);

  const [personen, setPersonen] = useState<IOphalenPersonenResultElementV2[] | null>(null);
  const ophalenPersonen = useCallback(async () => {
    const result = await api.v2.persoon.ophalenPersonen({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: persIDs,
          },
        ],
      },
    });
    setPersonen(result.personen);
  }, [persIDs]);

  useEffect(() => {
    ophalenPersonen();
  }, [ophalenPersonen]);

  const [contactpersonen, setContactpersonen] = useState<
    IRemoteData<IOphalenContactpersonenResult>
  >(createPendingRemoteData());
  const ophalenContactpersonen = useCallback(async () => {
    const result = await api.v2.relatie.ophalenContactpersonen({
      filterSchema: {
        filters: [
          {
            naam: 'PERS_IDS',
            data: persIDs,
          },
        ],
      },
    });
    setContactpersonen(createReadyRemoteData(result));
  }, [persIDs]);
  useEffect(() => {
    ophalenContactpersonen();
  }, [ophalenContactpersonen]);

  const contactpersonenBijPersID = useMemo<
    IRemoteData<{ [persID: number]: IOphalenContactpersonenResultElement[] }>
  >(() => {
    if (contactpersonen.state === ERemoteDataState.Pending) {
      return createPendingRemoteData();
    }
    const result = contactpersonen.data!.contactpersonen.reduce<{
      [persID: number]: IOphalenContactpersonenResultElement[];
    }>(
      (acc, x) => ({
        ...acc,
        [x.PersID]: [...(acc[x.PersID] ?? []), x],
      }),
      {},
    );
    return createReadyRemoteData(result);
  }, [contactpersonen]);

  const keyExtractor = useCallback((row: IPersoonDuplicaatSuggestieDuplicaat) => row.PersID, []);

  const kolommen = useMemo<Column[]>(
    () => [
      // {
      //   name: '__relNr',
      //   title: 'RelNr',
      // },
      // {
      //   name: 'Geblokkeerd',
      //   title: 'Geblok.',
      // },
      {
        name: '__naam',
        title: 'Naam',
      },
      {
        name: '__geboortedatum' as any,
        title: 'Geb.dtm.',
      },
      // {
      //   name: '__aantalContracten',
      //   title: 'Atl. cnt.',
      // },
      // {
      //   name: '__adres',
      //   title: 'Adres',
      // },
      {
        name: 'Overleden',
        title: 'Overleden',
      },
      {
        name: '__relaties',
        title: 'Relaties',
      },
      {
        name: '__recordToegevoegd',
        title: 'Record toegevoegd',
      },
      {
        name: '__notities',
        title: 'Notities',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TableColumnWidthInfo[]>(
    () => [
      // {
      //   columnName: '__relNr',
      //   width: 75,
      // },
      // {
      //   columnName: 'Geblokkeerd',
      //   width: 75,
      // },
      {
        columnName: '__naam',
        width: 250,
      },
      {
        columnName: '__geboortedatum',
        width: 110,
      },
      // {
      //   columnName: '__aantalContracten',
      //   width: 100,
      // },
      // {
      //   columnName: '__adres',
      //   width: 300,
      // },
      {
        columnName: 'Overleden',
        width: 100,
      },
      {
        columnName: '__relaties',
        width: 300,
      },
      {
        columnName: '__recordToegevoegd',
        width: 150,
      },
      {
        columnName: '__notities',
        width: 375,
      },
    ],
    [],
  );

  if (personen === null || contactpersonenBijPersID.state === ERemoteDataState.Pending) {
    return <LoadingSpinner />;
  }

  return (
    <GridStyleWrapper maxHeight={500} rowAmount={duplicaten.length}>
      <Grid rows={duplicaten} columns={kolommen} getRowId={keyExtractor}>
        <DataTypeProvider
          for={['__naam']}
          formatterComponent={(formatterProps) => {
            const row: IPersoonDuplicaatSuggestieDuplicaat = formatterProps.row;
            return (
              <span className="d-flex">
                <PersoonVisualisatie persID={row.PersID} />
              </span>
            );
          }}
        />
        <DataTypeProvider
          for={['__geboortedatum']}
          formatterComponent={(formatterProps) => {
            const row: IPersoonDuplicaatSuggestieDuplicaat = formatterProps.row;
            const persoon = personen!.find((x) => x.PersID === row.PersID)!;
            const datum = persoon.Geboortedatum;

            return (
              <span className="d-flex">
                {datum !== null ? format(new Date(datum), 'dd-MM-yyyy') : ''}
              </span>
            );
          }}
        />
        <DataTypeProvider
          for={['Overleden']}
          formatterComponent={(formatterProps) => {
            const row: IPersoonDuplicaatSuggestieDuplicaat = formatterProps.row;
            const persoon = personen!.find((x) => x.PersID === row.PersID)!;

            return <span className="d-flex">{persoon.Overleden ? 'Ja' : 'Nee'}</span>;
          }}
        />
        <DataTypeProvider
          for={['__relaties']}
          formatterComponent={(formatterProps) => {
            const row: IPersoonDuplicaatSuggestieDuplicaat = formatterProps.row;
            const contactpersonen = contactpersonenBijPersID.data![row.PersID] ?? [];

            return (
              <span className="d-flex align-items-center">
                {contactpersonen
                  .map((contactpersoon) => <RelatieVisualisatie relID={contactpersoon.RelID} />)
                  .map((el, i) => {
                    if (i !== contactpersonen.length - 1) {
                      return <div className="d-flex">{el}&nbsp;–&nbsp;</div>;
                    }
                    return el;
                  })}
              </span>
            );
          }}
        />
        <DataTypeProvider
          for={['__recordToegevoegd']}
          formatterComponent={(formatterProps) => {
            const row: IPersoonDuplicaatSuggestieDuplicaat = formatterProps.row;
            const persoon = personen!.find((x) => x.PersID === row.PersID)!;

            return (
              <span className="d-flex">
                {persoon.RecordToegevoegd === null
                  ? 'Onbekend'
                  : format(new Date(persoon.RecordToegevoegd), 'dd-MM-yyyy HH:mm')}
              </span>
            );
          }}
        />
        <DataTypeProvider
          for={['__notities']}
          formatterComponent={(formatterProps) => {
            const row: IPersoonDuplicaatSuggestieDuplicaat = formatterProps.row;
            const persoon = personen!.find((x) => x.PersID === row.PersID)!;

            return <span className="d-flex">{persoon.Notities}</span>;
          }}
        />
        <VirtualTable />
        <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
        <TableHeaderRow />
      </Grid>
    </GridStyleWrapper>
  );
};

export default DuplicatenTabel;
