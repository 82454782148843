import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import { EditingState, VirtualTable as VirtualTableBase } from '@devexpress/dx-react-grid';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ISectieProps } from '../index';
import {
  IOphalenFacturenBasisResult,
  IOphalenFacturenBasisResultElement,
  IOphalenFactuurregelsResult,
  IRegel as IOphalenFactuurregelsResultElement,
} from '../../../../../../../../../../../../../shared/src/api/v2/factuur';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { format } from 'date-fns';
import _ from 'lodash';
import { EResultType } from '../../../../../../../../../../../stores/CheckStore';
import NieuweVorderingDialoog from '../../../../../../../../../../Facturering/Vorderingen/NieuweVorderingDialoog';
import WijzigenVorderingDialoog from '../../../../../../../../../../Facturering/Vorderingen/WijzigenVorderingDialoog';
import FactuurinfoDialoog from '../../../../../../../../../../../components/factuur/FactuurinfoDialoog';
import { Kleur as EKleur } from '../../../../../../../../../../../bedrijfslogica/constanten';
import {
  EFunctioneleIcon,
  functioneleIconMap,
} from '../../../../../../../../../../../components/Icons';
import { RootStoreContext } from '../../../../../../../../../../../stores/RootStore';
import {
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../../../../../../../../helpers/dxTableGrid';
import nameOf from '../../../../../../../../../../../core/nameOf';
import FormatteerBedrag from '../../../../../../../../../../../components/MutatieBedrag';
import FactuurVisualisatie from '../../../../../../../../../../../components/entiteitVisualisaties/FactuurVisualisatie';
import api from '../../../../../../../../../../../api';
import { IOphalenVorderingenResult } from '../../../../../../../../../../../../../shared/src/api/v2/vordering';
import LoadingSpinner from '../../../../../../../../../../../components/Gedeeld/LoadingSpinner';

interface IProps extends ISectieProps {}

interface IFactuurregel extends IOphalenFactuurregelsResultElement {
  factuur: IOphalenFacturenBasisResultElement | null;
}

interface INieuweVorderingDialoogState {
  cntID: number;
}

interface IWijzigenVorderingDialoogState {
  factRegID: number;
}

interface IFactuurinfoDialoogState {
  factID: number;
}

const IconProductInfo = functioneleIconMap[EFunctioneleIcon.ProductInfo];

const Financieel: React.FC<IProps> = (props) => {
  const { checkStore } = useContext(RootStoreContext);

  const contract = props.contract;

  const [
    factuurregelsResult,
    setFactuurregelsResult,
  ] = useState<IOphalenFactuurregelsResult | null>(null);
  const ophalenFactuurregels = useCallback(async () => {
    const result = await api.v2.factuur.ophalenFactuurregels({
      filterSchema: {
        filters: [
          {
            naam: 'CNT_IDS',
            data: [contract.CntID],
          },
        ],
      },
    });
    setFactuurregelsResult(result);
  }, []);
  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    ophalenFactuurregels();
  }, [ophalenFactuurregels]);

  const [facturen, setFacturen] = useState<IOphalenFacturenBasisResult | null>(null);
  const ophalenFacturen = useCallback(async () => {
    if (factuurregelsResult === null) {
      return;
    }

    const factIDs = _.uniq(factuurregelsResult.regels.map((x) => x.FactID));
    const result = await api.v2.factuur.ophalenFacturenBasis({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: factIDs,
          },
        ],
      },
    });

    setFacturen(result);
  }, [factuurregelsResult]);
  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    ophalenFacturen();
  }, [ophalenFacturen]);

  const [
    nieuweVorderingDialoogState,
    setNieuweVorderingDialoogState,
  ] = useState<INieuweVorderingDialoogState | null>(null);
  const [
    wijzigenVorderingDialoogState,
    setWijzigenVorderingDialoogState,
  ] = useState<IWijzigenVorderingDialoogState | null>(null);
  const [
    factuurinfoDialoogState,
    setFactuurinfoDialoogState,
  ] = useState<IFactuurinfoDialoogState | null>(null);

  // Factuurregels aangevuld met de factuur indien aanwezig
  const factuurregels = useMemo<any[] | null>(() => {
    if (factuurregelsResult === null || facturen === null) {
      return null;
    }

    const vorderingenMetFactuur = factuurregelsResult.regels.map((regel) => {
      //     const factuur = props.facturen.find((f) => f.FactID === x.FactID) ?? null;
      const factuur = facturen.facturen.find((x) => x.FactID === regel.FactID) ?? null;
      return {
        ...regel,
        factuur,
      };
    });
    return _.orderBy(
      vorderingenMetFactuur,
      [(x: any) => (x.factuur !== null ? x.factuur.Factuurdatum : null)],
      ['desc'],
    );
  }, [factuurregelsResult, facturen]);

  const { klantkaartStore } = useContext(RootStoreContext);
  klantkaartStore.ophalenRelatie(contract.RelID);
  const factuurniveau = klantkaartStore.relatie!.debiteur!.FactuurNiveau;

  // Rekening bepalen (Afwijkend of Standaard?)
  let rekening: {
    iban: string;
    rekeningnaam: string;
    afwijkend: boolean;
  } | null = null;
  if (contract.RelRekID !== null) {
    const relRek = props.dataBijID.relatieRekeningenBijID[contract.RelRekID];

    rekening = {
      iban: relRek.IBAN,
      rekeningnaam: relRek.Rekeningnaam,
      afwijkend: true,
    };
  } else {
    const rekeningen = klantkaartStore.relatie!.financieel.rekeningen;
    const standaardRelRekID = klantkaartStore.relatie!.financieel.StandaardRekening_RelRekID;
    const standaardRekening = rekeningen.find((x) => x.RelRekID === standaardRelRekID) ?? null;
    rekening =
      standaardRekening !== null
        ? {
            iban: standaardRekening.IBAN,
            rekeningnaam: standaardRekening.Rekeningnaam,
            afwijkend: false,
          }
        : null;
  }

  const vorderingKolommen = useMemo<TypedColumn<IFactuurregel>[]>(() => {
    return [
      {
        name: '__factuurnummer' as any,
        title: 'Factuur',
      },
      {
        name: '__factuurdatum' as any,
        title: 'Factuurdatum',
      },
      {
        name: '__factuurInfo' as any,
        title: ' ',
      },
      {
        name: 'Omschrijving',
        title: 'Omschrijving',
      },
      {
        name: 'Bedrag',
        title: 'Bedrag in factuur',
      },
      {
        name: 'periodeVan',
        title: 'Periode van',
      },
      {
        name: 'periodeTot',
        title: 'Periode tot',
      },
    ];
  }, []);

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IFactuurregel>[]>(
    () => [
      {
        columnName: 'Bedrag',
        width: 125,
      },
      {
        columnName: 'Omschrijving',
        width: 275,
      },
      {
        columnName: 'periodeVan',
        width: 90,
      },
      {
        columnName: 'periodeTot',
        width: 90,
      },
      {
        columnName: '__factuurnummer' as any,
        width: 100,
      },
      {
        columnName: '__factuurdatum' as any,
        width: 135,
      },
      {
        columnName: '__factuurInfo' as any,
        width: 75,
      },
    ],
    [],
  );
  const kolomExtensies: VirtualTableBase.ColumnExtension[] = useMemo(() => {
    return [
      // {
      //   columnName: `Bedrag`,
      //   align: 'right',
      // },
    ];
  }, []);

  if (factuurregels === null) {
    return (
      <div className="d-flex flex-fill align-items-center justify-content-center p-4">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <>
      <div>
        <GridStyleWrapper maxHeight={200} rowAmount={factuurregels.length}>
          <Grid rows={factuurregels} columns={vorderingKolommen} getRowId={(x) => x.FactRegID}>
            <DataTypeProvider
              for={[nameOf<IFactuurregel>('Bedrag')]}
              formatterComponent={(props) => {
                const rij: IFactuurregel = props.row;
                return <FormatteerBedrag bedrag={rij.Bedrag} />;
              }}
            />
            <DataTypeProvider
              for={[nameOf<IFactuurregel>('periodeVan'), nameOf<IFactuurregel>('periodeTot')]}
              formatterComponent={(formatterProps) => {
                return (
                  <span>
                    {formatterProps.value !== null
                      ? format(new Date(formatterProps.value), 'dd-MM-yyyy')
                      : ''}
                  </span>
                );
              }}
            />
            <DataTypeProvider
              for={['__factuurnummer']}
              formatterComponent={(formatterProps) => {
                const rij: IFactuurregel = formatterProps.row;

                if (rij.factuur === null) {
                  return <span></span>;
                }
                return <FactuurVisualisatie factID={rij.FactID} />;
              }}
            />

            <DataTypeProvider
              for={['__factuurdatum']}
              formatterComponent={(formatterProps) => {
                const rij: any = formatterProps.row;

                if (rij.factuur === null) {
                  return <span></span>;
                }
                return <span>{format(new Date(rij.factuur.Factuurdatum), 'dd-MM-yyyy')}</span>;
              }}
            />

            <DataTypeProvider
              for={['__factuurInfo']}
              formatterComponent={(formatterProps) => {
                const rij: IFactuurregel = formatterProps.row;
                return (
                  <a
                    href="#"
                    className="ml-1"
                    style={{
                      color: EKleur.LichtBlauw,
                      position: 'relative',
                      bottom: 2,
                    }}
                    onClick={() => {
                      setFactuurinfoDialoogState({
                        factID: rij.FactID,
                      });
                    }}
                  >
                    <IconProductInfo
                      style={{
                        width: 15,
                        height: 15,
                        fill: EKleur.Blauw,
                      }}
                    />
                  </a>
                );
              }}
            />

            <VirtualTable columnExtensions={kolomExtensies} />
            <TableColumnResizing defaultColumnWidths={kolomBreedtes} />

            <EditingState
              onAddedRowsChange={() => {
                setNieuweVorderingDialoogState({
                  cntID: props.contract.CntID,
                });
              }}
              onCommitChanges={async (changes) => {
                if (changes.deleted === undefined) {
                  return;
                }
                const deleted = changes.deleted;
                const id = deleted[deleted.length - 1] as number;

                const checkData = await api.v2.vordering.checkVerwijderenVorderingen({
                  factRegIDs: [id],
                });
                if (
                  (
                    await checkStore.controleren({
                      checkData,
                    })
                  ).type === EResultType.Annuleren
                ) {
                  return;
                }
                if (
                  (
                    await checkStore.bevestigen({
                      inhoud: (
                        <span>
                          Wil je de vorderingen verwijderen?
                          <br />
                          <br />
                          Let op: Dit kan niet meer ongedaan gemaakt worden{' '}
                        </span>
                      ),
                    })
                  ).type === EResultType.Annuleren
                ) {
                  return;
                }

                await api.v2.vordering.verwijderenVorderingen({
                  factRegIDs: [id],
                });

                props.vernieuwenContracten();
              }}
              onEditingRowIdsChange={async (rowIds) => {
                const id = rowIds[rowIds.length - 1] as number;

                const checkData = await api.v2.vordering.checkSelectieWijzigenVordering({
                  factRegID: id,
                });
                const checkResult = await checkStore.controleren({
                  checkData,
                });
                if (checkResult.type === EResultType.Annuleren) {
                  return;
                }

                setWijzigenVorderingDialoogState({
                  factRegID: id,
                });
              }}
            />

            {/* <TableEditColumn
              width={65}
              showAddCommand
              showDeleteCommand
              showEditCommand
              cellComponent={DXTableEditColumnCellComponent}
              commandComponent={DXTableEditColumnCommandComponent}
            /> */}

            {/* <RowDetailState defaultExpandedRowIds={[]} /> */}
            {/* <TableRowDetail /> */}
            <TableHeaderRow />
          </Grid>
        </GridStyleWrapper>
      </div>
      {nieuweVorderingDialoogState !== null && (
        <NieuweVorderingDialoog
          open
          relID={props.contract.RelID}
          cntID={nieuweVorderingDialoogState.cntID}
          onSuccess={() => {
            {
              setNieuweVorderingDialoogState(null);
              props.vernieuwenContracten();
            }
          }}
          onAnnuleren={() => setNieuweVorderingDialoogState(null)}
        />
      )}
      {wijzigenVorderingDialoogState !== null && (
        <WijzigenVorderingDialoog
          open
          factRegID={wijzigenVorderingDialoogState.factRegID}
          onAnnuleren={() => setWijzigenVorderingDialoogState(null)}
          onSuccess={() => {
            props.vernieuwenContracten();
            setWijzigenVorderingDialoogState(null);
          }}
        />
      )}
      {factuurinfoDialoogState !== null && (
        <FactuurinfoDialoog
          open
          factID={factuurinfoDialoogState.factID}
          onSuccess={() => {
            setFactuurinfoDialoogState(null);
          }}
          onAnnuleren={() => setFactuurinfoDialoogState(null)}
        />
      )}
    </>
  );
};

export default Financieel;
