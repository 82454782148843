import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ITabbladProps } from '.';
import MultiCombobox from '../../../../../components/formulier/MultiCombobox';
import { Kleur } from '../../../../../bedrijfslogica/constanten';
import {
  DXTableCheckboxComponent,
  DXTableToggleCellComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../../helpers/dxTableGrid';
import LoadingSpinner from '../../../../../components/Gedeeld/LoadingSpinner';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import { DataTypeProvider, RowDetailState, SelectionState } from '@devexpress/dx-react-grid';
import api from '../../../../../api';
import RelatieSelectieCombobox from '../../../../../components/formulier/RelatieSelectie';
import LocatieSelectie from '../../../../../components/formulier/LocatieSelectie';
import { IOphalenProductenResultElementV2 } from '../../../../../../../shared/src/api/v2/product';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../../models/IRemoteData';
import ContractVisualisatie from '../../../../../components/entiteitVisualisaties/ContractVisualisatie';

interface IProps extends ITabbladProps {
  locID?: number;
  relID?: number;
  cntID?: number;
}

export interface IRow extends IOphalenProductenResultElementV2 {}

const Locatie: React.FC<IProps> = (props) => {
  const { onProdIDChange, prodID } = props;

  const [relID, setRelID] = useState<number | null>(props.relID !== undefined ? props.relID : null);
  const [selectieProducten, setSelectieProducten] = useState<IRow[] | null>(null);

  const [locatieGeselecteerd, setLocatieGeselecteerd] = useState<IRemoteData<number | null>>(
    createPendingRemoteData(),
  );

  useEffect(() => {
    (async () => {
      setLocatieGeselecteerd(createReadyRemoteData(props.locID ?? null));
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (locatieGeselecteerd.state === ERemoteDataState.Pending) {
        return;
      }

      if (locatieGeselecteerd.data === null) {
        setSelectieProducten([]);
        return;
      }

      const result = await api.v2.transport.reservering.retour.ophalenSelectieProducten({
        locID: locatieGeselecteerd.data!,
        cntID: null, //props.cntID !== undefined ? props.cntID : null,
      });

      setSelectieProducten(result.producten);
    })();
  }, [locatieGeselecteerd]);

  const kolommen = useMemo<TypedColumn<IRow>[]>(
    () => [
      {
        name: '__merknaam' as any,
        title: 'Merk',
      },
      {
        name: '__typenaam' as any,
        title: 'Type',
      },
      {
        name: '__productsoortnaamKort' as any,
        title: 'Soort',
      },
      {
        name: '__kenmerk' as any,
        title: 'Kenmerk',
      },
      {
        name: 'Referentiecode',
        title: 'Ref.',
      },
      {
        name: '__contract' as any,
        title: 'Contract',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRow>[]>(
    () => [
      {
        columnName: '__typenaam' as any,
        width: 150,
      },
      {
        columnName: '__merknaam' as any,
        width: 100,
      },
      {
        columnName: '__productsoortnaamKort' as any,
        width: 60,
      },
      {
        columnName: '__kenmerk' as any,
        width: 150,
      },
      {
        columnName: 'Referentiecode',
        width: 80,
      },
      {
        columnName: '__contract' as any,
        width: 100,
      },
    ],
    [],
  );

  const keyExtractor = useCallback((row: IRow) => row.ProdID, []);

  if (selectieProducten === null) {
    return <LoadingSpinner />;
  }

  return (
    <div className="p-3">
      <div className="row">
        <div
          className="col-3"
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {/* <label>Modelcode</label>
          <span
            style={{
              border: `1px solid ${Kleur.LichtGrijs}`,
              padding: '4px 8px',
              width: '100%',
              height: '100%',
            }}
          >
            {transportregel.contract !== null ? transportregel.contract.Modelcode : ''}
          </span> */}
        </div>

        <div className="col-12">
          <LocatieSelectie
            locID={locatieGeselecteerd.data}
            onChange={(locID) => setLocatieGeselecteerd(createReadyRemoteData(locID))}
            relIDs={props.relID !== undefined ? [props.relID] : undefined}
            options={{
              bijEnkeleDirectVoorselecteren: true,
            }}
          />
        </div>

        <div className="col-12 mt-3">
          {selectieProducten === null ? (
            <LoadingSpinner />
          ) : (
            <>
              {/* <RelatieSelectieCombobox
                filters={[{ naam: 'IDS', data: [1, 2, 3] }]}
                onChange={(relID) => setRelID(relID)}
                relID={null}
              /> */}

              <GridStyleWrapper maxHeight={300}>
                <Grid columns={kolommen} getRowId={keyExtractor} rows={selectieProducten}>
                  <DataTypeProvider
                    for={['__contract']}
                    formatterComponent={(formatterProps) => {
                      const rij: IRow = formatterProps.row;

                      if (rij.contractActueel === null) {
                        return <span></span>;
                      }
                      return <ContractVisualisatie cntID={rij.contractActueel.CntID} />;
                    }}
                  />

                  <DataTypeProvider
                    for={['__productsoortnaamKort']}
                    formatterComponent={(formatterProps) => {
                      return <span>{formatterProps.row.producttype.ProductsoortnaamKort}</span>;
                    }}
                  />

                  <DataTypeProvider
                    for={['__merknaam']}
                    formatterComponent={(formatterProps) => {
                      return <span>{formatterProps.row.producttype.Merknaam}</span>;
                    }}
                  />
                  <DataTypeProvider
                    for={['__typenaam']}
                    formatterComponent={(formatterProps) => {
                      return <span>{formatterProps.row.producttype.Typenaam}</span>;
                    }}
                  />
                  <DataTypeProvider
                    for={['__kenmerk']}
                    formatterComponent={(formatterProps) => {
                      return <span>{formatterProps.row.producttype.Kenmerk}</span>;
                    }}
                  />

                  <SelectionState
                    selection={prodID === null ? [] : [prodID]}
                    // Maar een element selecteren per keer, dus pakken bij een deselectie, een lege array
                    // of bij een selectie het laatst geselecteerde element als het enige element
                    onSelectionChange={(x) =>
                      onProdIDChange((x.length > 0 ? x[x.length - 1] : null) as number)
                    }
                  />
                  <RowDetailState defaultExpandedRowIds={[]} />
                  <VirtualTable />
                  <TableRowDetail toggleCellComponent={DXTableToggleCellComponent} />
                  <TableSelection cellComponent={DXTableCheckboxComponent} />
                  <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
                  <TableHeaderRow />
                </Grid>
              </GridStyleWrapper>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Locatie;
