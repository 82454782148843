import { makeObservable, observable } from 'mobx';
import api from '../api';
import {
  IOphalenAccountsParams,
  IOphalenAccountsResult,
  IVersturenActivatielinkParams,
  IVersturenWachtwoordVergetenlinkParams,
  IVerwijderenAccountsParams,
  IWijzigenAccountParams,
  IToevoegenAccountParams,
  IAccountToegevoegdBroadcastData,
  IVerwijderenAccountsBroadcastData,
} from '../../../shared/src/api/v2/account/account';
import { RootStore } from './RootStore';

// tslint:disable no-console
class AccountStore {
  public accounts: IOphalenAccountsResult | null = null;
  // tslint:disable-next-line:variable-name
  private _rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this._rootStore = rootStore;

    makeObservable(this, {
      accounts: observable,
    });
  }

  public ophalenAccounts = async (params: IOphalenAccountsParams) => {
    try {
      const resultaat = await api.v2.account.ophalenAccounts(params);
      this.accounts = resultaat;
    } catch (error) {
      console.error(error);
    }
  };

  public actievatieLinkSturen = async (params: IVersturenActivatielinkParams) => {
    try {
      await api.v2.account.versturenActivatielink(params);
    } catch (err) {
      throw err;
    }
  };

  public wachtwoordVergetenLinkSturen = async (params: IVersturenWachtwoordVergetenlinkParams) => {
    try {
      await api.v2.account.versturenWachtwoordVergetenLink(params);
    } catch (err) {
      throw err;
    }
  };

  public verwijderenAccount = async (params: IVerwijderenAccountsParams, relID: number) => {
    try {
      await api.v2.account.verwijderenAccounts(params);
      this.ophalenAccounts({ filterSchema: { filters: [{ naam: 'REL_IDS', data: [relID] }] } });
    } catch (err) {
      throw err;
    }
  };

  public wijzigenAccount = async (params: IWijzigenAccountParams, relID: number) => {
    try {
      const resultaat = await api.v2.account.wijzigenAccount(params);
      this.ophalenAccounts({
        filterSchema: {
          filters: [{ naam: 'REL_IDS', data: [relID] }],
        },
      });
    } catch (err) {
      throw err;
    }
  };

  public toevoegenAccount = async (
    params: IToevoegenAccountParams,
    relID: number,
  ): Promise<string[]> => {
    let errors: string[] = [];
    try {
      const resultaat = await api.v2.account.toevoegenAccount(params);
      if (Object.keys(resultaat).includes('errors')) {
        errors = resultaat.errors;
      } else {
        this.ophalenAccounts({
          filterSchema: {
            filters: [{ naam: 'REL_IDS', data: [relID] }],
          },
        });
      }
    } catch (err) {
      throw err;
    }
    return errors;
  };

  public handleAccountToegevoegd = (data: IAccountToegevoegdBroadcastData) => {
    if (this._rootStore.klantkaartStore.relatie!.RelID !== data.RelID) {
      return;
    }
    this.ophalenAccounts({
      filterSchema: {
        filters: [{ naam: 'REL_IDS', data: [data.RelID] }],
      },
    });
  };

  public handleAccountsVerwijderd = (data: IVerwijderenAccountsBroadcastData) => {
    if (this.accounts === null) {
      return;
    }

    if (this.accounts.some((acc) => data.accIDs.includes(acc.AccID))) {
      this.ophalenAccounts({
        filterSchema: {
          filters: [{ naam: 'REL_IDS', data: [this._rootStore.klantkaartStore.relatie!.RelID] }],
        },
      });
    }
  };
}

export default AccountStore;
