import {
  IOphalenMedewerkersParams,
  IOphalenMedewerkersResult,
  IOphalenMedewerkersVoorwaardenParams,
  IOphalenMedewerkersVoorwaardenResult,
  IToevoegenMedewerkerVoorwaardeParams,
  IToevoegenMedewerkerVoorwaardeResult,
  IVerwijderenMedewerkerVoorwaardesParams,
  IVerwijderenMedewerkerVoorwaardesResult,
  IWijzigenMedewerkerParams,
  IWijzigenMedewerkerResult,
  IWijzigenMedewerkerVoorwaardeParams,
  IWijzigenMedewerkerVoorwaardeResult,
} from '../../../../../shared/src/api/v2/medewerker';
import api from '../../index';
import ICheckData from '../../../../../shared/src/models/ICheckData';
import verlof from './verlof';

const medewerker = {
  verlof,
  ophalenMedewerkers: async (
    params: IOphalenMedewerkersParams,
  ): Promise<IOphalenMedewerkersResult> => {
    return await api.post('/v2/medewerker/ophalen-medewerkers', params);
  },
  ophalenMedewerkerVoorwaarden: async (
    params: IOphalenMedewerkersVoorwaardenParams,
  ): Promise<IOphalenMedewerkersVoorwaardenResult> => {
    return await api.post('/v2/medewerker/ophalen-medewerker-voorwaarden', params);
  },
  checkToevoegenMedewerkerVoorwaarde: async (
    params: IToevoegenMedewerkerVoorwaardeParams,
  ): Promise<ICheckData> => {
    return await api.post('/v2/medewerker/check-toevoegen-medewerker-voorwaarde', params);
  },
  toevoegenMedewerkerVoorwaarde: async (
    params: IToevoegenMedewerkerVoorwaardeParams,
  ): Promise<IToevoegenMedewerkerVoorwaardeResult> => {
    return await api.post('/v2/medewerker/toevoegen-medewerker-voorwaarde', params);
  },
  checkWijzigenMedewerkerVoorwaarde: async (
    params: IWijzigenMedewerkerVoorwaardeParams,
  ): Promise<ICheckData> => {
    return await api.post('/v2/medewerker/check-wijzigen-medewerker-voorwaarde', params);
  },
  wijzigenMedewerkerVoorwaarde: async (
    params: IWijzigenMedewerkerVoorwaardeParams,
  ): Promise<IWijzigenMedewerkerVoorwaardeResult> => {
    return await api.post('/v2/medewerker/wijzigen-medewerker-voorwaarde', params);
  },
  checkVerwijderenMedewerkerVoorwaardes: async (
    params: IVerwijderenMedewerkerVoorwaardesParams,
  ): Promise<ICheckData> => {
    return await api.post('/v2/medewerker/check-verwijderen-medewerker-voorwaardes', params);
  },
  verwijderenMedewerkerVoorwaardes: async (
    params: IVerwijderenMedewerkerVoorwaardesParams,
  ): Promise<IVerwijderenMedewerkerVoorwaardesResult> => {
    return await api.post('/v2/medewerker/verwijderen-medewerker-voorwaardes', params);
  },
  checkWijzigenMedewerker: async (params: IWijzigenMedewerkerParams): Promise<ICheckData> => {
    return await api.post('/v2/medewerker/check-wijzigen-medewerker', params);
  },
  wijzigenMedewerker: async (
    params: IWijzigenMedewerkerParams,
  ): Promise<IWijzigenMedewerkerResult> => {
    return await api.post('/v2/medewerker/wijzigen-medewerker', params);
  },
};

export default medewerker;
