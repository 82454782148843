import React, { useMemo } from 'react';
import Select, { Styles } from 'react-select';
import Creatable from 'react-select/creatable';
import { IOptionType } from '../../Gedeeld/FormulierVelden';
import { Kleur } from '../../../bedrijfslogica/constanten';

export interface IOptie<TKey extends number | string> {
  key: TKey;
  weergave: string | JSX.Element;
}

interface IProps<TKey extends number | string> {
  value: TKey[];
  onChange: (value: TKey[]) => void;
  opties: IOptie<TKey>[];
  isVerwijderbaar?: boolean;
  placeholder?: string;
  isMulti?: boolean;
  onToegevoegd?: (value: string) => void;
  autoFocus?: boolean;
  onBlur?: () => void;
  disabled?: boolean;
}

const MultiSelect = <TKey extends number | string>(props: IProps<TKey>) => {
  const customStyles: Partial<Styles> = useMemo(
    () => ({
      indicatorsContainer: (base) => {
        return {
          borderLeft: `1px solid ${Kleur.LichtGrijs}`,
          // width: 25,
          backgroundColor: Kleur.HeelLichtGrijs,
          // minHeight: '24px',
          display: 'flex',
          alignItems: 'center',
        };
      },
      dropdownIndicator: (base) => {
        return {
          marginRight: '3px',
          marginLeft: '2px',
          marginTop: '-5px',
          color: '#999999',
          width: 18,
          height: 18,
        };
      },
      indicatorSeparator: () => {
        return {
          display: 'none',
        };
      },
      control: (base) => {
        return {
          ...base,
          cursor: 'pointer',
          border: `1px solid ${Kleur.LichtGrijs}`,
          minHeight: '0px',
          alignItems: 'normal',
        };
      },
      valueContainer: (base) => {
        return { ...base, padding: '0px' };
      },
      menu: (base) => {
        return { ...base, zIndex: 99999 };
      },
      option: (base) => {
        return { ...base, cursor: 'pointer' };
      },
      input: (base, state) => {
        return {
          ...base,
          minWidth: props.value.length === 0 ? '85px' : '0px',
          margin: '0px',
          marginLeft: '5px',
          marginRight: '15px',
        };
      },
      placeholder: (base) => {
        return { ...base, marginLeft: '5px', fontSize: '13px' };
      },
      // container: (base) => {
      //   return { ...base };
      // },
      // singleValue: (base) => {
      //   return { ...base, minWidth: 'min-content' };
      // },
      multiValue: (base) => {
        return { ...base, backgroundColor: `rgba(0, 0, 0, 0.035)` };
      },
      multiValueLabel: (base) => {
        return { ...base, padding: '1px', marginRight: '1px' };
      },
      multiValueRemove: (base) => {
        return { ...base, padding: '1px', marginTop: '2px' };
      },
    }),
    [props.value],
  );

  const isMulti = useMemo(() => (props.isMulti === undefined ? true : props.isMulti), [
    props.isMulti,
  ]);

  if (props.onToegevoegd === undefined) {
    return (
      <Select<IOptionType>
        styles={customStyles}
        isMulti={isMulti}
        isClearable={props.isVerwijderbaar || false}
        onChange={(value) => {
          if (!value) {
            props.onChange([]);
            return;
          }
          const keys =
            (value as any).map === undefined
              ? [(value as any).value]
              : ((value as any[]).map((x) => x.value) as TKey[]);
          props.onChange(keys || []);
        }}
        options={props.opties.map((optie) => ({
          label: optie.weergave as any,
          value: optie.key as any,
        }))}
        value={props.value.map((key) => {
          const optie = props.opties.find((x) => x.key === key)!;
          return {
            label: optie.weergave as any,
            value: key as any,
          };
        })}
        placeholder={props.placeholder === undefined ? 'Maak een keuze' : props.placeholder}
        autoFocus={props.autoFocus}
        defaultMenuIsOpen={props.autoFocus}
        onBlur={props.onBlur}
        isDisabled={props.disabled}
      />
    );
  }

  return (
    <Creatable
      styles={customStyles}
      isMulti={isMulti}
      isClearable={props.isVerwijderbaar || false}
      onChange={(value) => {
        if (!value) {
          props.onChange([]);
          return;
        }
        const keys =
          (value as any).map === undefined
            ? [(value as any).value]
            : ((value as any[]).map((x) => x.value) as TKey[]);
        props.onChange(keys || []);
      }}
      options={props.opties.map((optie) => ({
        label: optie.weergave as any,
        value: optie.key as any,
      }))}
      value={props.value.map((key) => {
        const optie = props.opties.find((x) => x.key === key)!;
        return {
          label: optie.weergave as any,
          value: key as any,
        };
      })}
      placeholder={props.placeholder === undefined ? 'Maak een keuze' : props.placeholder}
      onCreateOption={props.onToegevoegd}
      formatCreateLabel={(tekst) => `Voeg '${tekst}' toe als nieuwe optie`}
      autoFocus={props.autoFocus}
      defaultMenuIsOpen={props.autoFocus}
      onBlur={props.onBlur}
      isDisabled={props.disabled}
    />
  );
};

export default MultiSelect;
