import React, { useContext, useMemo } from 'react';
import { IAspGebruikerprofielFormData, InstellingenDialoogFormikContext } from '../../index';
import Combobox, { IOptie } from '../../../../../../components/formulier/Combobox';
import LoadingSpinner from '../../../../../../components/Gedeeld/LoadingSpinner';

export interface IProfielFormProps {
  formData: IAspGebruikerprofielFormData;
  onFormDataChange: (formData: IAspGebruikerprofielFormData) => void;
}

const ProfielForm = (props: IProfielFormProps) => {
  const { formData, onFormDataChange } = props;
  const { aspLocaties, voipAccounts } = useContext(InstellingenDialoogFormikContext);

  const aspLocatiesOpties = useMemo<IOptie<number>[] | null>(
    () =>
      aspLocaties === null
        ? null
        : aspLocaties.map((locatie) => {
            return {
              id: locatie.ID,
              label: locatie.Naam,
            };
          }),
    [aspLocaties],
  );
  const voipAccountsOpties = useMemo<IOptie<number>[] | null>(
    () =>
      voipAccounts === null
        ? null
        : voipAccounts.map((voipAccount) => {
            return {
              id: voipAccount.VoipAccID,
              label: voipAccount.Naam,
            };
          }),
    [voipAccounts],
  );

  return (
    <div className="d-flex flex-fill flex-column">
      <div className="p-3">
        <div className="row">
          <div className="col-12">
            <label>Naam</label>
            <input
              type="text"
              className="form-control"
              value={formData.naam}
              onChange={(ev) =>
                onFormDataChange({
                  ...formData,
                  naam: ev.target.value,
                })
              }
            />
          </div>
          <div className="col-12 mt-3">
            <label>Locatie</label>
            {aspLocatiesOpties === null ? (
              <LoadingSpinner />
            ) : (
              <Combobox
                geselecteerd={formData.aspLocID}
                onSelectieChange={(x) => onFormDataChange({ ...formData, aspLocID: x })}
                opties={aspLocatiesOpties}
              />
            )}
          </div>
          <div className="col-12 mt-3">
            <label>Voip Account</label>
            {voipAccountsOpties === null ? (
              <LoadingSpinner />
            ) : (
              <Combobox
                geselecteerd={formData.voipAccID}
                onSelectieChange={(x) => onFormDataChange({ ...formData, voipAccID: x })}
                opties={voipAccountsOpties}
                isWisbaar
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfielForm;
