import React, { useContext, useMemo, useState } from 'react';
import { IWeergaveProps, OverzichtContext } from '../index';
import GesprekscontextVeld from '../../../../../telefoon/GesprekscontextVeld';
import { mapRemoteData } from '../../../../../../models/IRemoteData';
import TelefoonComponent from '../../../../../communicatie/TelefoonComponent';
import {
  IOproep,
  IOphalenTelefoonoproepXContextResultElement,
} from '../../../../../../../../shared/src/api/v2/telefonie';

const TelefoonOproep = (x: IWeergaveProps) => {
  const {
    contextenResult,
    telefoonoproepenXContextenResult,
    verversenTelefoonoproepXContexten,
    verversenContexten,
  } = useContext(OverzichtContext);

  const oproep: IOproep = x.communicatieItem.data;

  const telefoonoproepXContexten = useMemo(
    () =>
      mapRemoteData(telefoonoproepenXContextenResult, (x) =>
        x.telefoonoproepXContexten.reduce<{
          [telOprID: number]: IOphalenTelefoonoproepXContextResultElement[];
        }>(
          (acc, curr) => ({
            ...acc,
            [curr.TelOprID]: [...(acc[curr.TelOprID] ?? []), curr],
          }),
          {},
        ),
      ),
    [telefoonoproepenXContextenResult],
  );

  return (
    <div className="d-flex align-items-center">
      <div className="text-wrap flex-fill mr-2">
        <GesprekscontextVeld
          oproep={oproep}
          contextenResult={contextenResult}
          telefoonoproepXContexten={telefoonoproepXContexten}
          verversenTelefoonoproepXContexten={verversenTelefoonoproepXContexten}
          verversenContexten={verversenContexten}
        />
      </div>

      <div className="pl-2">
        {oproep.Nummer === null ? (
          <span>Anoniem</span>
        ) : (
          <TelefoonComponent
            telefoonNummer={oproep.Nummer}
            persID={oproep.PersID ?? undefined}
            orgID={oproep.OrgID ?? undefined}
            options={{ icoonWeergeven: false }}
          />
        )}
      </div>
    </div>
  );
};

export default TelefoonOproep;
