import React from 'react';
import { TableRowDetail } from '@devexpress/dx-react-grid-bootstrap4';
import { IRegel } from '../../../../../../shared/src/api/v2/factuur';

interface IProps extends TableRowDetail.ContentProps {}

const RowDetailComp: React.FC<IProps> = (props) => {
  const row: IRegel = props.row;

  return <div>{row.FactRegID}</div>;
};

export default RowDetailComp;
