import React, { useContext, useMemo } from 'react';
import { ITabbladProps, ProductContext } from '../index';
import MediaWeergave, { EMediaWeergaveType, MediaWeergaveItem } from '../../../MediaWeergave';

interface IProps extends ITabbladProps {}

const AfbeeldingTab: React.FC<IProps> = (props) => {
  const { product, inkoopfactuur, servicemeldingen, transportopdrachtregels } = useContext(
    ProductContext,
  );

  const mediaWeergaven = useMemo<MediaWeergaveItem[] | null>(() => {
    return product === null
      ? null
      : product.afbeeldingen.map((x) => ({
          id: x.ID,
          src: x.url,
          title: x.Naam || undefined,
          type: EMediaWeergaveType.Afbeelding as any,
          mediaType: x.MediaType,
        }));
  }, [product]);

  if (mediaWeergaven === null) {
    return null;
  }

  return (
    <div className="d-flex flex-fill flex-column p-2">
      {mediaWeergaven.length === 0 ? (
        <div className="d-flex align-items-center justify-content-center flex-fill">
          <span>Er zijn geen afbeeldingen beschikbaar.</span>
        </div>
      ) : (
        <MediaWeergave
          mediaWeergaven={mediaWeergaven}
          imageStyle={{
            maxHeight: 375,
          }}
        />
      )}
    </div>
  );
};

export default AfbeeldingTab;
