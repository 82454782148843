import { colors } from '../globalstyles/variables';
import { IconKlantApp, IconKlantenservice, IconWebsite } from '../components/Icons';
import { SVGProps } from 'react';
import { EMutatiebron } from './enums';

export const ORGANISATIE = 'O';
export const PERSOON = 'P';
export const CONTRACT_ENKEL = 'ENKEL';
export const CONTRACT_STAPEL = 'STAPEL';
export const HUISMODEL = 'Huismodel';
export const DATUM_FORMAT = 'dd-MM-yyyy';
export const KLANTKAART_DEFAULT_PATH = `/contracten/overzicht`;

export const MAANDEN = {
  nl: [
    'Januari',
    'Februari',
    'Maart',
    'April',
    'Mei',
    'Juni',
    'Juli',
    'Augustus',
    'September',
    'Oktober',
    'November',
    'December',
  ],
};

export const KLEUR_MUTATIEBRON_GRIJS = {
  color: `${colors.textgrey}`,
};

export const KLEUR_MUTATIEBRON_BLAUW = {
  color: `${colors.primaryBlue}`,
};

export const KLEUR_MUTATIEBRON_GROEN = {
  color: `${colors.primaryGreen}`,
  // backgroundColor: `${colors.backgroundLightgreen}`,
};

// export const CONTRACT_AANVRAGEN = 'CONTRACT_AANVRAGEN';
// export const CONTRACT_OVERZICHT = 'CONTRACT_OVERZICHT';

export const mutatiebronIconMap: Record<EMutatiebron, React.ComponentType<SVGProps<any>>> = {
  [EMutatiebron.Intern]: IconKlantenservice,
  [EMutatiebron.GebruikersApp]: IconKlantApp,
  [EMutatiebron.Website]: IconWebsite,
};

export enum Kleur {
  Zwart = '#000000',
  Wit = '#ffffff',
  DonkerWit = '#d8d8d8',
  Rood = 'rgb(230, 72, 87)',
  LichtRood = '#ff87ae',
  DonkerRood = '#b22754',
  Oranje = '#fb8c00',
  LichtOranje = '#ffbd45',
  DonkerOranje = '#c25e00',
  Geel = '#ffd758',
  LichtGeel = '#fff263',
  HeelLichtGeel = '#f1eecc',
  DonkerGeel = '#c49000',
  Groen = '#00c957',
  LichtGroen = '#88d689',
  DonkerGroen = '#338a3e',
  Blauw = 'rgb(34,98,246)',
  ZwembadBlauw = '#4ab3ff',
  LichtBlauw = '#6899e8',
  HeelLichtBlauw = '#7bbcff',
  SuperLichtBlauw = '#d2e4f5',
  DonkerBlauw = '#5865d9',
  DoorschijnendLichtBlauw = 'rgba(110, 148, 255, 0.15)',
  SelectieBlauw = '#97c5ff',
  Bruin = '#8d6e63',
  LichtBruin = '#be9c91',
  DonkerBruin = '#5f4339',
  LichtGrijs = '#e2e2e2',
  LichterGrijs = '#e9e9e9',
  HeelLichtGrijs = '#f8f8f8',
  HeelLichtRood = '#ebc1c1',
  SelectieGrijs = '#f1f1f1',
  TextMuted = 'rgb(202, 202, 202)',
  Grijs = '#888888',
  BeetjeLichtGrijs = '#aaaaaa',
  GrijsScheiding = 'rgb(153, 153, 153)',
  BeetjeDonkerGrijs = 'rgb(70, 70, 70)',
  DonkerGrijs = '#383838',
  Navigatie = '#73027a',
  Paars = '#a64cab',
  DonkerPaars = '#600266',
  LichtPaars = '#d550cc',

  // Functioneel
  Actief = '#e2ebff',
  Hover = 'rgba(50,50,50,0.05)',
  HoverActief = 'rgba(226, 235, 253, 0.65)',

  Klant = '#9a01a4',
  Leverancier = 'rgb(0, 141, 164)',
  Persoon = '#047508',

  MemoGeel = 'rgb(255, 247, 204)',
  MemoGeelDisabled = 'rgb(239 222 149)',

  Interactief = 'rgb(20,0,255)',
}

// Kleuren op niveau van context
export enum Contextkleur {
  Transport = Kleur.Bruin,
  Overdracht = Kleur.Oranje,
  Beeindiging = Kleur.Rood,
  Annulering = Kleur.Geel,
  Verkoop = Kleur.Blauw,
  AanvraagContract = Kleur.Groen,
  Contractwissel = Kleur.LichtGroen,
}

export enum EGebruikersStatus {
  Beschikbaar,
  Afwezig,
  NietStoren,
}

export const gebruikersStatusKleurMap: Record<EGebruikersStatus, string> = {
  [EGebruikersStatus.Beschikbaar]: 'lightgreen',
  [EGebruikersStatus.Afwezig]: Kleur.LichtOranje,
  [EGebruikersStatus.NietStoren]: Kleur.Rood,
};

export const gebruikersStatusTekstMap: Record<EGebruikersStatus, string> = {
  [EGebruikersStatus.Beschikbaar]: 'Beschikbaar',
  [EGebruikersStatus.Afwezig]: 'Afwezig',
  [EGebruikersStatus.NietStoren]: 'Niet storen',
};
