import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ASPKolom, EAspKolomBreedteType } from '../../../../../../components/tabel/ASPTabel/types';
import MenuLayout from '../../../../../../components/MenuLayout';
import api from '../../../../../../api';
import LoadingSpinner from '../../../../../../components/Gedeeld/LoadingSpinner';
import ASPTabel from '../../../../../../components/tabel/ASPTabel';
import useUrlState from '../../../../../../core/useUrlState';
import { IOphalenOpdrachtenResultElement } from '../../../../../../../../shared/src/api/v2/service/opdracht';
import { LeverancierContext } from '../../../..';
import { format } from 'date-fns';
import RelatieVisualisatie from '../../../../../../components/personalia/RelatieVisualisatie';
import ServiceopdrachtVisualisatie from '../../../../../../components/entiteitVisualisaties/ServiceopdrachtVisualisatie';
import OpdrachtInfoDialoog from '../../../../../../components/service/OpdrachtInfoDialoog';
import { Kleur as EKleur } from '../../../../../../bedrijfslogica/constanten';
import { IconInformatie } from '../../../../../../components/Icons';

interface IProps extends RouteComponentProps {
  relID: number;
}

enum EKolom {
  Opdrachtnummer,
  Opdrachtdatum,
  Omschrijving,
  Relatie,
  Productsoort,
  UitvoerendeDienst,
  OpdrachtInfo,
}
export interface IServiceopdrachtInfoDialoogState {
  servOpdID: number;
}

export interface IUrlState {
  selectie: number[];
  serviceopdrachtInfoDialoogState: IServiceopdrachtInfoDialoogState | null;
  // sortering: Sorting[];
}

// const defaultUrlState: IUrlState = {
//   selectie: [],
//   // sortering: [
//   //   {
//   //     columnName: 'Naam',
//   //     direction: 'asc',
//   //   },
//   // ],
// };

const Budgetcodes: React.FC<IProps> = (props) => {
  const defaultUrlState = useMemo<IUrlState>(
    () => ({
      selectie: [],
      serviceopdrachtInfoDialoogState: null,
      // sortering: [
      //   {
      //     key: EKolom.Naam,
      //     sortering: ESortering.Ascending,
      //   },
      // ],
    }),
    [],
  );

  const { relID } = useContext(LeverancierContext);

  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);

  const [serviceopdrachten, setServiceopdrachten] = useState<
    IOphalenOpdrachtenResultElement[] | null
  >(null);

  const ophalenServiceopdrachten = useCallback(async () => {
    const dienstResult = (
      await api.v2.dienst.service.ophalenDiensten({
        filterSchema: { filters: [{ naam: 'REL_IDS', data: [relID] }] },
      })
    ).diensten[0];

    const result = await api.v2.service.ophalenOpdrachten({
      filterSchema: { filters: [{ naam: 'SERVDIENST_IDS', data: [dienstResult.ID] }] },
      paginatie: { aantal: 250, index: 0 },
      orderSchema: { orders: [{ naam: 'DATUM_VERSTUURD', richting: 'DESC' }] },
    });

    setServiceopdrachten(result.opdrachten);
  }, [relID]);

  useEffect(() => {
    ophalenServiceopdrachten();
  }, [ophalenServiceopdrachten]);

  const opdrachtRijen = useMemo(() => {
    if (serviceopdrachten === null) {
      return null;
    }

    const rijen = serviceopdrachten.reduce((acc, rij, idx) => {
      return {
        ...acc,
        [idx]: rij,
      };
    }, {});

    return rijen;
  }, [serviceopdrachten]);

  const keyExtractor = useCallback((item: IOphalenOpdrachtenResultElement) => item.ServOpdID, []);

  const kolommen = useMemo<ASPKolom<EKolom, IOphalenOpdrachtenResultElement>[]>(
    () => [
      {
        key: EKolom.Opdrachtnummer,
        label: 'Opdr.nr',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 125,
        renderer: (item: IOphalenOpdrachtenResultElement) => {
          return <ServiceopdrachtVisualisatie servOpdID={item.ServOpdID} />;
          // return item.melding.Meldnummer + '.' + item.Volgnummer;
        },
      },
      {
        key: EKolom.OpdrachtInfo,
        label: ' ',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 80,
        renderer: (item: IOphalenOpdrachtenResultElement) => {
          return (
            <>
              <a
                href="#"
                className="ml-1"
                style={{ color: EKleur.LichtBlauw, position: 'relative', bottom: 2 }}
                onClick={() => {
                  setUrlStateSync('serviceopdrachtInfoDialoogState', {
                    servOpdID: item.ServOpdID,
                  });
                }}
              >
                <IconInformatie style={{ width: 15, height: 15, fill: EKleur.Blauw }} />
              </a>
            </>
          );
        },
      },
      {
        key: EKolom.Opdrachtdatum,
        label: 'Datum',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 125,
        renderer: (item: IOphalenOpdrachtenResultElement) => {
          return item.DatumVerstuurd !== null
            ? format(new Date(item.DatumVerstuurd), 'dd-MM-yyyy')
            : '';
        },
      },
      {
        key: EKolom.Relatie,
        label: 'Relatie',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 250,
        renderer: (item: IOphalenOpdrachtenResultElement) => {
          return item.melding.RelID !== null ? (
            <RelatieVisualisatie relID={item.melding.RelID} />
          ) : (
            <span></span>
          );
        },
      },
      {
        key: EKolom.Productsoort,
        label: 'Cat.',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 100,
        renderer: (item: IOphalenOpdrachtenResultElement) => {
          return item.melding.product?.producttype.productsoort.NaamKort;
        },
      },
      {
        key: EKolom.Omschrijving,
        label: 'Omschrijving',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 400,
        // flex: 1,
        renderer: (item: IOphalenOpdrachtenResultElement) => {
          return (
            <span
              style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
              title={item.Omschrijving!}
            >
              {item.Omschrijving}
            </span>
          );
        },
      },
      {
        key: EKolom.UitvoerendeDienst,
        label: 'Uitvoerende dienst',
        breedteType: EAspKolomBreedteType.Flex,
        minimaleVasteBreedte: 100,
        flex: 1,
        renderer: (item: IOphalenOpdrachtenResultElement) => {
          if (item.uitvoerendeDienst === null) {
            return null;
          }
          return (
            <span
              style={{
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
              title={item.uitvoerendeDienst.relatie?.organisatie?.Naam}
            >
              {item.uitvoerendeDienst.relatie?.organisatie?.Naam}
            </span>
          );
        },
      },
    ],
    [],
  );

  return (
    <>
      <MenuLayout
        body={
          <>
            <div className="d-flex flex-column flex-fill">
              {opdrachtRijen === null ? (
                <div className="flex-fill d-flex align-items-center justify-content-center">
                  <LoadingSpinner />
                </div>
              ) : (
                <div className="d-flex flex-column flex-fill">
                  <ASPTabel
                    rijen={opdrachtRijen}
                    kolommen={kolommen}
                    keyExtractor={keyExtractor}
                    // selectie={urlState.selectie}
                    // onSelectieChange={(selectie) => setUrlStateSync('selectie', selectie)}
                    // sortering={urlState.sortering}
                    // onSorteringChange={(sortering) => setUrlStateSync('sortering', sortering)}
                    // onWijzigenRij={async (x) => {}}
                    // onVerwijderenRij={async (x) => {
                    //   await new Promise((resolve) => setTimeout(resolve, 3000));
                    // }}
                    // lokaalSorteren
                  />
                </div>
              )}
            </div>
            {urlState.serviceopdrachtInfoDialoogState !== null && (
              <OpdrachtInfoDialoog
                id={urlState.serviceopdrachtInfoDialoogState.servOpdID}
                open
                onSuccess={() => setUrlStateSync('serviceopdrachtInfoDialoogState', null)}
                onAnnuleren={() => setUrlStateSync('serviceopdrachtInfoDialoogState', null)}
              />
            )}
          </>
        }
      />
    </>
  );
};

export default withRouter(Budgetcodes);
