import React, { useCallback, useMemo } from 'react';
import { IOphalenBoekingenResultElement } from '../../../../../../shared/src/api/v2/boekhouding/boeking';
import IDialoogProps from '../../../../core/IDialoogProps';
import Dialoog from '../../../dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ASPTabel from '../../../tabel/ASPTabel';
import { ASPKolom, EAspKolomBreedteType } from '../../../tabel/ASPTabel/types';
import { format } from 'date-fns';
import { ModalFooter } from 'react-bootstrap';
import { Kleur } from '../../../../bedrijfslogica/constanten';

export enum EBoekstukType {
  Inkoop,
  Verkoop,
  Bank,
}

export interface IInkoopBoekstuk {
  type: EBoekstukType.Inkoop;
  inkFactID: number;
}

export interface IVerkoopBoekstuk {
  type: EBoekstukType.Verkoop;
  factID: number;
}

export interface IBankBoekstuk {
  type: EBoekstukType.Bank;
  bankMutID: number;
}

export type Boeknummer = IInkoopBoekstuk | IVerkoopBoekstuk | IBankBoekstuk;

enum EKolom {
  Dagboek,
  Boeknummer,
  Datum,
  IsDefinitief,
  Kies,
}

export interface IDialoogOutput {
  boekingID: number;
}

interface IProps extends IDialoogProps<IDialoogOutput> {
  boekstuk: Boeknummer;
  boekingen: IOphalenBoekingenResultElement[];
}

const SelecteerBoekingVoorBoestukDialoog = (props: IProps) => {
  const rijen = useMemo(
    () =>
      props.boekingen.reduce(
        (acc, curr, i) => ({
          ...acc,
          [i]: curr,
        }),
        {},
      ),
    [props.boekingen],
  );
  const keyExtractor = useCallback((rij: IOphalenBoekingenResultElement) => rij.ID, []);
  const kolommen = useMemo<ASPKolom<EKolom, IOphalenBoekingenResultElement>[]>(
    () => [
      {
        key: EKolom.Dagboek,
        label: 'Dagboek',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 120,
        renderer: (x) => x.dagboek.Naam,
      },
      {
        key: EKolom.Boeknummer,
        label: 'Boeknummer',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 100,
        renderer: (x) => x.Boeknummer,
      },
      {
        key: EKolom.Datum,
        label: 'Datum',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 125,
        renderer: (x) => format(new Date(x.Boekdatum), 'dd-MM-yyyy'),
      },
      {
        key: EKolom.IsDefinitief,
        label: 'Definitief',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 80,
        renderer: (x) => (x.Definitief ? 'Ja' : 'Nee'),
      },
      {
        key: EKolom.Kies,
        breedteType: EAspKolomBreedteType.Flex,
        flex: 1,
        renderer: (x) => (
          <a
            href="#"
            onClick={(ev) => {
              ev.stopPropagation();
              props.onSuccess({ boekingID: x.ID });
            }}
          >
            Kies
          </a>
        ),
      },
    ],
    [props.onSuccess],
  );

  return (
    <Dialoog index={props.dialoogIndex ?? 0}>
      <ModalHeader>
        <ModalTitle>Selecteer boeking voor boekstuk</ModalTitle>
      </ModalHeader>
      <ModalBody className="p-0">
        <div className="p-2 pl-3 pr-3 pt-3">
          <p>Voor het gekozen boekstuk zijn meerdere boekingen gevonden.</p>
          <p>Selecteer een van deze hieronder:</p>
        </div>
        <div
          className="d-flex flex-fill w-100"
          style={{ height: 300, borderTop: `1px solid ${Kleur.LichtGrijs}` }}
        >
          <ASPTabel rijen={rijen} kolommen={kolommen} keyExtractor={keyExtractor} />
        </div>
      </ModalBody>
      <ModalFooter className="d-flex flex-row justify-content-start">
        <button className="btn btn-secondary" onClick={props.onAnnuleren} style={{ width: 100 }}>
          Annuleren
        </button>
      </ModalFooter>
    </Dialoog>
  );
};

export default SelecteerBoekingVoorBoestukDialoog;
