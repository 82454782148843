import React, { useCallback, useEffect, useMemo, useState } from 'react';
import IDialoogProps from '../../../core/IDialoogProps';
import Dialoog from '../Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { Formik, FormikActions, FormikProps } from 'formik';
import PersoonFormulier, { IFormValues } from '../../formulier/PersoonFormulier';
import { IOphalenPersonenResultElementV2 } from '../../../../../shared/src/api/v2/persoon/persoon';
import api from '../../../api';
import LoadingSpinner from '../../Gedeeld/LoadingSpinner';
import Modal from 'react-bootstrap/Modal';
import { format } from 'date-fns';
import { dagDatum } from '../../../helpers/datum';

interface IDialoogResult {}

interface IProps extends IDialoogProps<IDialoogResult> {
  persID: number;
}

interface IFormikValues extends IFormValues {}

const PersoonWijzigenDialoog: React.FC<IProps> = (props) => {
  const { children, dialoogIndex, onAnnuleren, onSuccess, open, persID } = props;
  const [persoon, setPersoon] = useState<IOphalenPersonenResultElementV2 | null>(null);
  const [formulierValid, setFormulierValid] = useState(false);

  useEffect(() => {
    (async () => {
      // const pers = await api.v2.persoon.ophalenPersoon({
      //   persID,
      // });

      const result = await api.v2.persoon.ophalenPersonen({
        filterSchema: { filters: [{ naam: 'IDS', data: [persID!] }] },
      });
      const persoon = result.personen[0];

      setPersoon(persoon);
    })();
  }, [persID]);

  const initieleWaarden = useMemo<IFormikValues | null>(() => {
    if (persoon === null) {
      return null;
    }

    return {
      taalID: persoon.taal.TaalID,
      geslacht: persoon.geslacht.Geslacht,
      achternaam: persoon.Achternaam,
      email: persoon.Email || '',
      telefoon: persoon.TelefoonMobiel ?? null,
      telefoonExtra: persoon.TelefoonExtra ?? null,
      voorletters: persoon.Voorletters || '',
      voornaam: persoon.Voornaam || '',
      voorvoegsel: persoon.Voorvoegsel || '',
      overleden: persoon.Overleden,
      geboortedatum:
        persoon.Geboortedatum === null ? null : dagDatum(new Date(persoon.Geboortedatum)),
      geboorteplaats: persoon.Geboorteplaats || '',
      aanheftype: persoon.Aanheftype,
      notities: persoon.Notities || '',
    };
  }, [persoon]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      const {
        achternaam,
        email,
        geslacht,
        taalID,
        telefoon,
        telefoonExtra,
        voorletters,
        voornaam,
        voorvoegsel,
        geboortedatum,
        geboorteplaats,
        overleden,
        aanheftype,
        notities,
      } = values;
      await api.v2.persoon.wijzigenPersoon({
        persID,
        achternaam,
        email,
        geslacht: geslacht!,
        taalID: taalID!,
        voorletters,
        voornaam,
        voorvoegsel,
        telefoonMobiel: telefoon,
        telefoonExtra: telefoonExtra === telefoon ? null : telefoonExtra, // Geen dubbele telefoonnummers
        geboortedatum,
        geboorteplaats,
        overleden,
        aanheftype,
        notities,
      });

      await onSuccess({});
      actions.setSubmitting(false);
    },
    [onSuccess],
  );

  return (
    <Dialoog index={dialoogIndex || 0} modalProps={{ size: 'lg' }}>
      <ModalHeader>
        <ModalTitle>Wijzigen persoon</ModalTitle>
      </ModalHeader>
      {persoon === null ? (
        <>
          <LoadingSpinner />
        </>
      ) : (
        <>
          <Formik<IFormikValues>
            enableReinitialize
            initialValues={initieleWaarden as any}
            onSubmit={handleSubmit}
            render={(formikProps: FormikProps<IFormikValues>) => {
              const { submitForm, isSubmitting, isValid } = formikProps;

              return (
                <>
                  <ModalBody>
                    <PersoonFormulier
                      values={formikProps.values}
                      onChange={(values) => formikProps.setValues(values)}
                      onIsValidChange={(valid) => setFormulierValid(valid)}
                    />
                  </ModalBody>
                  <ModalFooter className="d-flex flex-row justify-content-start">
                    <button
                      className="btn btn-primary"
                      onClick={submitForm}
                      style={{ width: 100 }}
                      disabled={isSubmitting || !isValid || !formulierValid}
                    >
                      Ok
                    </button>
                    <button
                      className="btn btn-secondary"
                      onClick={onAnnuleren}
                      style={{ width: 100 }}
                      disabled={isSubmitting}
                    >
                      Annuleren
                    </button>
                  </ModalFooter>
                </>
              );
            }}
          />
        </>
      )}
    </Dialoog>
  );
};

export default PersoonWijzigenDialoog;
