import React, { useCallback, useContext, useEffect, useState } from 'react';
import MenuLayout from '../../../../../../../components/MenuLayout';
import BetalingsregelingTabel, {
  EKolomnaam,
  IBetalingsregeling,
  IBetalingsregelingInfoDialoogState,
  IBoekingInfoDialoogState,
  IWijzigenBetalingsregelingDialoogState,
} from '../../../../../../../components/debiteuren/BetalingsregelingenTabel';
import useUrlState from '../../../../../../../core/useUrlState';
import api from '../../../../../../../api';
import { observer } from 'mobx-react-lite';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { RootStoreContext } from '../../../../../../../stores/RootStore';

export interface IUrlState {
  selectie: number[];
  wijzigenBetalingsregelingDialoogState: IWijzigenBetalingsregelingDialoogState | null;
  boekingInfoDialoogState: IBoekingInfoDialoogState | null;
  betalingsregelingInfoDialoogState: IBetalingsregelingInfoDialoogState | null;
}

export const defaultUrlState: IUrlState = {
  selectie: [],
  wijzigenBetalingsregelingDialoogState: null,
  boekingInfoDialoogState: null,
  betalingsregelingInfoDialoogState: null,
};

interface IProps extends RouteComponentProps {}

const Betalingsregeling: React.FC<IProps> = observer((props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const { klantkaartStore } = useContext(RootStoreContext);

  const [betalingsregelingen, setBetalingsregelingen] = useState<IBetalingsregeling[] | null>(null);

  const ophalenBetalingsregelingen = useCallback(async () => {
    if (klantkaartStore.relatie === null) {
      setBetalingsregelingen(null);
      return;
    }

    const regelingenResult = await api.v2.debiteur.betalingsregeling.ophalenBetalingsregelingen({
      filterSchema: {
        filters: [
          {
            naam: 'REL_IDS',
            data: [klantkaartStore.relatie.RelID],
          },
        ],
      },
    });

    const betRglIDs = regelingenResult.betalingsregelingen.map((x) => x.ID);

    const boekingsregelsResult = await api.v2.boekhouding.boeking.ophalenBoekingregels({
      filterSchema: {
        filters: [{ naam: 'BETRGL_IDS', data: betRglIDs }],
      },
    });

    const betalingsregelingen = regelingenResult.betalingsregelingen.map((x) => {
      const boekingsregels = boekingsregelsResult.regels.filter((r) => r.BetRglID === x.ID);
      return { ...x, boekingsregels };
    });

    setBetalingsregelingen(betalingsregelingen);
  }, [klantkaartStore.relatie === null ? null : klantkaartStore.relatie.RelID]);

  useEffect(() => {
    ophalenBetalingsregelingen();
  }, [ophalenBetalingsregelingen]);

  return (
    <BetalingsregelingTabel
      betalingsregelingen={betalingsregelingen}
      selectie={urlState.selectie}
      onSelectieChange={(x) => setUrlStateSync('selectie', x)}
      wijzigenBetalingsregelingDialoogState={urlState.wijzigenBetalingsregelingDialoogState}
      onWijzigenBetalingsregelingDialoogStateChange={(x) =>
        setUrlStateSync('wijzigenBetalingsregelingDialoogState', x)
      }
      boekingInfoDialoogState={urlState.boekingInfoDialoogState}
      onWijzigenBoekingInfoDialoogState={(x) => setUrlStateSync('boekingInfoDialoogState', x)}
      betalingsregelingInfoDialoogState={urlState.betalingsregelingInfoDialoogState}
      onWijzigenBetalingsregelingInfoDialoogState={(x) =>
        setUrlStateSync('betalingsregelingInfoDialoogState', x)
      }
      onVerversenAangevraagd={async () => await ophalenBetalingsregelingen()}
      nietWeerTeGevenKolommen={[EKolomnaam.TeBoeken, EKolomnaam.Relatie, EKolomnaam.ReedsVoldaan]}
    />
  );
});

export default withRouter(Betalingsregeling);
