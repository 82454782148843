import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import MenuLayout from '../../../../../../../components/MenuLayout';
import api from '../../../../../../../api';
import { IEntiteitProps } from '../../../../../../../components/kaart/EntiteitContainer';
import { Kleur } from '../../../../../../../bedrijfslogica/constanten';
import { IconKruis, IconToevoegen } from '../../../../../../../components/Icons';
import {
  DXTableCheckboxComponent,
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../../../../helpers/dxTableGrid';
import {
  Grid,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import LoadingSpinner from '../../../../../../../components/Gedeeld/LoadingSpinner';
import {
  IOphalenInterneLocatiesResultElement,
  IOphalenLocatieResult,
  IOphalenLocatiesResultElement,
  IToevoegenInterneLocatiesParams,
  IVerwijderenInterneLocatiesParams,
} from '../../../../../../../../../shared/src/api/v2/locatie/locatie';
import {
  DataTypeProvider,
  EditingState,
  SelectionState,
  Table as TableBase,
} from '@devexpress/dx-react-grid';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
  mapRemoteData,
} from '../../../../../../../models/IRemoteData';
import Chip from '../../../../../../../components/Chip';
import { EResultType } from '../../../../../../../stores/CheckStore';
import MultiSelect from '../../../../../../../components/formulier/MultiSelect';
import UitlegTooltip from '../../../../../../../components/formulier/UitlegTooltip';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../../../../../stores/RootStore';
import { AutoSizer } from 'react-virtualized';
import useUrlState from '../../../../../../../core/useUrlState';
import { withRouter } from 'react-router';
import nameOf from '../../../../../../../core/nameOf';
import { IOphalenContractenResultElementV2 } from '../../../../../../../../../shared/src/api/v2/contract';
import { GlobaleRendererContext } from '../../../../../../../one-off-components/GlobaleRenderer';
import WijzigenDialoog from './WijzigenDialoog';
import { IOphalenLocatiesResultLocatie } from '../../../../../../../../../shared/src/api/v2/relatie/locatie';
import { IOphalenComplexenResultElement } from '../../../../../../../../../shared/src/api/v2/relatie/complex';
import { ILocatiesTabbladProps } from '../index';

const CellComponent = (props: TableBase.DataCellProps) => {
  const isInterneLocatiesKolom = props.column.name === '__interneLocaties';
  return (
    <td style={{ overflow: isInterneLocatiesKolom ? 'visible' : undefined }}>{props.children}</td>
  );
};

interface IProps extends ILocatiesTabbladProps {}

interface IUrlState {
  selectie: number[];
}

const defaultUrlState: IUrlState = {
  selectie: [],
};

interface IData {
  relatieLocaties: IOphalenLocatiesResultLocatie[];
  locaties: IOphalenLocatiesResultElement[];
  contracten: IOphalenContractenResultElementV2[];
  interneLocaties: IOphalenInterneLocatiesResultElement[];
  relatieComplexen: IOphalenComplexenResultElement[];
}

const Locaties: React.FC<IProps> = observer((props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const { checkStore } = useContext(RootStoreContext);
  const globaleRenderer = useContext(GlobaleRendererContext);
  const [data, setData] = useState<IRemoteData<IData>>(createPendingRemoteData());

  const ophalenData = useCallback(async () => {
    const relatieLocatiesResult = await api.v2.relatie.locatie.ophalenLocaties({
      filterSchema: {
        filters: [
          {
            naam: 'REL_IDS',
            data: [props.relID],
          },
        ],
      },
    });

    const bepaalLocaties = async () => {
      const locatiesResult = await api.v2.locatie.ophalenLocaties({
        filterSchema: {
          filters: [
            {
              naam: 'IDS',
              data: relatieLocatiesResult.locaties.map((x) => x.LocID),
            },
          ],
        },
      });

      return locatiesResult.locaties;
    };

    const bepaalRelatieComplexen = async () => {
      const result = await api.v2.relatie.complex.ophalenComplexen({
        filterSchema: {
          filters: [
            {
              naam: 'REL_IDS',
              data: [props.relID],
            },
          ],
        },
      });

      return result.complexen;
    };

    const [locaties, relatieComplexen] = await Promise.all([
      bepaalLocaties(),
      bepaalRelatieComplexen(),
    ]);

    const locIDs = locaties.map((x) => x.LocID);

    const bepaalContracten = async () => {
      if (locIDs.length === 0) {
        return [];
      }

      const result = await api.v2.contract.ophalenContractenV2({
        filterSchema: {
          filters: [
            {
              naam: 'REL_IDS',
              data: [props.relID],
            },
            {
              naam: 'LOC_IDS',
              data: locIDs,
            },
          ],
        },
      });

      return result.contracten;
    };

    const bepaalInterneLocaties = async () => {
      if (locIDs.length === 0) {
        return [];
      }

      const interneLocatiesResult = await api.v2.locatie.ophalenInterneLocaties({
        filterSchema: {
          filters: [
            {
              naam: 'LOC_IDS',
              data: locIDs,
            },
          ],
        },
      });

      return interneLocatiesResult.interneLocaties;
    };

    const [contracten, interneLocaties] = (await Promise.all([
      bepaalContracten(),
      bepaalInterneLocaties(),
    ])) as [IOphalenContractenResultElementV2[], IOphalenInterneLocatiesResultElement[]];

    setData(
      createReadyRemoteData({
        relatieLocaties: relatieLocatiesResult.locaties,
        locaties,
        relatieComplexen,
        contracten,
        interneLocaties,
      }),
    );
  }, [props.relID]);

  useEffect(() => {
    ophalenData();
  }, [ophalenData]);

  const relatieLocatieBijLocID = useMemo<
    IRemoteData<{ [locID: number]: IOphalenLocatiesResultLocatie }>
  >(
    () =>
      mapRemoteData(data, (data) =>
        data.relatieLocaties.reduce<{ [locID: number]: IOphalenLocatiesResultLocatie }>(
          (acc, curr) => {
            acc[curr.LocID] = curr;
            return acc;
          },
          {},
        ),
      ),
    [data],
  );

  const relatieComplexBijID = useMemo<
    IRemoteData<{ [id: number]: IOphalenComplexenResultElement }>
  >(
    () =>
      mapRemoteData(data, (data) =>
        data.relatieComplexen.reduce<{ [id: number]: IOphalenComplexenResultElement }>(
          (acc, curr) => {
            acc[curr.ID] = curr;
            return acc;
          },
          {},
        ),
      ),
    [data],
  );

  const handleAutomatischKoppelenLocaties = useCallback(async () => {
    const result = await api.v2.relatie.locatie.koppelenLocatiesVoorRelaties({
      relIDs: [props.relID],
    });
    await ophalenData();
  }, [props.relID, ophalenData]);

  const handleOntkoppelenLocaties = useCallback(async (locIDs: number[]) => {
    if (
      (
        await checkStore.bevestigen({
          inhoud: `Geselecteerde locaties ontkoppelen van de relatie?`,
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }
    const result = await api.v2.relatie.locatie.ontkoppelenLocatiesVoorRelaties({
      relID: props.relID,
      locIDs,
    });

    setUrlStateSync('selectie', []);
    await ophalenData();
  }, []);

  const keyExtractor = useCallback((row: IOphalenLocatieResult) => row.LocID, []);
  const kolommen = useMemo<TypedColumn<IOphalenLocatiesResultElement>[]>(
    () => [
      // {
      //   name: '__adres' as any,
      //   title: 'Adres',
      // },
      {
        name: '__straatHuisnummer' as any,
        title: 'Adres',
      },
      {
        name: 'Postcode',
        title: 'Postcode',
      },
      {
        name: 'Plaatsnaam',
        title: 'Plaatsnaam',
      },
      {
        name: '__aantalContracten' as any,
        title: 'Atl. cnt.',
        // getCellValue: (x) => {
        //   if (contracten === null) {
        //     return;
        //   }
        //   const cnts = contracten.filter((c) => c.basis.locatie.LocID === x.LocID);
        //   const aantal = cnts.length;
        //
        //   return <span>{aantal}</span>;
        // },
      },
      {
        name: '__complex' as any,
        title: 'Complex',
      },
      {
        name: '__interneLocaties' as any,
        title: 'Interne locaties',
      },
    ],
    [],
  );
  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IOphalenLocatiesResultElement>[]>(
    () => [
      // {
      //   columnName: '__adres' as any,
      //   width: 350,
      // },
      {
        columnName: '__straatHuisnummer' as any,
        width: 300,
      },
      {
        columnName: 'Postcode',
        width: 90,
      },
      {
        columnName: 'Plaatsnaam',
        width: 135,
      },
      {
        columnName: '__aantalContracten' as any,
        width: 90,
      },
      {
        columnName: '__complex' as any,
        width: 150,
      },
      {
        columnName: '__interneLocaties' as any,
        width: 500,
      },
    ],
    [],
  );

  const [toevoegendeInterneLocaties, setToevoegendeInterneLocaties] = useState<
    Record<number, boolean>
  >({});

  const handleWijzigenLocatieClick = useCallback(
    async (locID: number) => {
      const relLoc = data.data!.relatieLocaties.find((x) => x.LocID === locID)!;

      const output = await globaleRenderer.render((renderProps) => (
        <WijzigenDialoog
          relLocID={relLoc.ID}
          open
          onSuccess={() => renderProps.destroy(null)}
          onAnnuleren={() => renderProps.destroy()}
        />
      ));

      if (output === undefined) {
        return;
      }
      await ophalenData();
    },
    [data, ophalenData],
  );

  return (
    <MenuLayout
      menu={
        <div className="d-flex align-items-center">
          <button
            className="btn btn-sm btn-light d-flex align-items-center"
            style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
            onClick={handleAutomatischKoppelenLocaties}
          >
            <IconToevoegen style={{ fill: Kleur.Grijs, width: 16, height: 16 }} />
            <span className="ml-2">Automatisch koppelen locaties</span>
          </button>

          <button
            className="btn btn-sm btn-light d-flex align-items-center ml-3"
            style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
            disabled={urlState.selectie.length === 0}
            onClick={() => handleOntkoppelenLocaties(urlState.selectie)}
          >
            {/* <IconToevoegen style={{ fill: Kleur.Grijs, width: 16, height: 16 }} /> */}
            <span className="ml-2">Ontkoppelen locaties</span>
          </button>
        </div>
      }
      body={
        data.state === ERemoteDataState.Pending ? (
          <div className="flex-fill d-flex align-items-center justify-content-center">
            <LoadingSpinner />
          </div>
        ) : (
          <AutoSizer className="d-flex flex-column flex-fill w-100 h-100">
            {({ height, width }) => {
              return (
                <GridStyleWrapper height={height} style={{ maxWidth: width, overflowX: 'auto' }}>
                  <Grid getRowId={keyExtractor} columns={kolommen} rows={data.data!.locaties}>
                    {/* <DataTypeProvider
                      for={['__adres']}
                      formatterComponent={(formatterProps) => {
                        const row: IOphalenLocatieResult = formatterProps.row;
                        return (
                          <span>
                            {formatteerAdresV2({
                              huisnummer: row.Huisnummer,
                              bisnummer: row.Bisnummer,
                              landnaamEnum: row.LandnaamEnum,
                              landnaamKort: row.LandnaamKort,
                              plaatsnaam: row.Plaatsnaam,
                              straatnaam: row.Straatnaam,
                              postcode: row.Postcode,
                            })}
                          </span>
                        );
                      }}
                    /> */}

                    <DataTypeProvider
                      for={['__straatHuisnummer']}
                      formatterComponent={(formatterProps) => {
                        const rij: IOphalenLocatieResult = formatterProps.row;
                        const straatHuisnummer = (
                          rij.Straatnaam +
                          ' ' +
                          rij.Huisnummer +
                          ' ' +
                          (rij.Bisnummer || '')
                        ).trim();

                        return <span>{straatHuisnummer}</span>;
                      }}
                    />

                    <DataTypeProvider
                      for={[nameOf<IOphalenLocatiesResultElement>('Plaatsnaam')]}
                      formatterComponent={(formatterProps) => {
                        return <span>{formatterProps.value}</span>;
                      }}
                    />

                    <DataTypeProvider
                      for={[nameOf<IOphalenLocatiesResultElement>('Postcode')]}
                      formatterComponent={(formatterProps) => {
                        return <span>{formatterProps.value}</span>;
                      }}
                    />

                    <DataTypeProvider
                      for={['__aantalContracten']}
                      formatterComponent={(formatterProps) => {
                        const rij: IOphalenLocatieResult = formatterProps.row;

                        const cnts = data.data!.contracten.filter(
                          (c) => c.basis.locatie.LocID === rij.LocID,
                        );
                        const aantal = cnts.length;
                        return <span>{aantal}</span>;
                      }}
                    />

                    <DataTypeProvider
                      for={['__complex']}
                      formatterComponent={(formatterProps) => {
                        const rij: IOphalenLocatieResult = formatterProps.row;
                        const relLoc = relatieLocatieBijLocID.data![rij.LocID];
                        const complex =
                          relLoc.RelatieComplexID === null
                            ? null
                            : relatieComplexBijID.data![relLoc.RelatieComplexID];

                        return <span>{complex?.Naam}</span>;
                      }}
                    />

                    <DataTypeProvider
                      for={['__interneLocaties']}
                      formatterComponent={(formatterProps) => {
                        const row: IOphalenLocatieResult = formatterProps.row;

                        const interneLocaties = data.data!.interneLocaties.filter(
                          (x) => x.LocID === row.LocID,
                        );

                        return (
                          <div
                            className="d-flex align-items-center"
                            style={{
                              minWidth: 500,
                              maxWidth: 500,
                              minHeight: 23,
                              overflowY: 'hidden',
                              overflowX: 'auto',
                            }}
                          >
                            {interneLocaties.map((interneLocatie, i) => {
                              return (
                                <>
                                  <Chip key={interneLocatie.ID}>
                                    <span style={{ fontSize: 11 }}>{interneLocatie.Naam}</span>
                                    <button
                                      className="ml-1"
                                      style={{
                                        outline: 0,
                                        border: 0,
                                        background: 0,
                                        padding: 0,
                                        position: 'relative',
                                        top: -1,
                                      }}
                                      onClick={async () => {
                                        const params: IVerwijderenInterneLocatiesParams = {
                                          ids: [interneLocatie.ID],
                                        };
                                        const checkData = await api.v2.locatie.checkVerwijderenInterneLocaties(
                                          params,
                                        );
                                        if (
                                          (await checkStore.controleren({ checkData })).type ===
                                          EResultType.Annuleren
                                        ) {
                                          return;
                                        }
                                        if (
                                          (
                                            await checkStore.bevestigen({
                                              inhoud: 'Confirmatie verwijderen context',
                                              asynchroneActieNaBevestigingFn: async () => {
                                                await api.v2.locatie.verwijderenInterneLocaties(
                                                  params,
                                                );
                                                await ophalenData();
                                              },
                                            })
                                          ).type === EResultType.Annuleren
                                        ) {
                                          return;
                                        }
                                      }}
                                    >
                                      <IconKruis
                                        style={{ fill: Kleur.Grijs, width: 14, height: 14 }}
                                      />
                                    </button>
                                  </Chip>
                                  {i !== data.data!.interneLocaties.length - 1 && (
                                    <span>&nbsp;</span>
                                  )}
                                </>
                              );
                            })}
                            {toevoegendeInterneLocaties[row.LocID] ? (
                              <div className="ml-1" style={{ minWidth: 135 }}>
                                <MultiSelect
                                  value={[]}
                                  isMulti={false}
                                  opties={[]}
                                  onChange={async ([contextID]) => {
                                    setToevoegendeInterneLocaties((curr) => ({
                                      ...curr,
                                      [row.LocID]: false,
                                    }));
                                  }}
                                  onToegevoegd={async (naam) => {
                                    const params: IToevoegenInterneLocatiesParams = {
                                      locID: row.LocID,
                                      namen: [naam],
                                    };
                                    const checkData = await api.v2.locatie.checkToevoegenInterneLocaties(
                                      params,
                                    );
                                    if (
                                      (await checkStore.controleren({ checkData })).type ===
                                      EResultType.Annuleren
                                    ) {
                                      return;
                                    }
                                    setToevoegendeInterneLocaties((curr) => ({
                                      ...curr,
                                      [row.LocID]: false,
                                    }));

                                    await api.v2.locatie.toevoegenInterneLocaties(params);
                                    await ophalenData();
                                  }}
                                  autoFocus
                                  onBlur={() => {
                                    setToevoegendeInterneLocaties((curr) => ({
                                      ...curr,
                                      [row.LocID]: false,
                                    }));
                                  }}
                                />
                              </div>
                            ) : (
                              <UitlegTooltip inhoud="Interne locatie toevoegen">
                                <button
                                  className="ml-1"
                                  style={{
                                    outline: 0,
                                    border: 0,
                                    background: 0,
                                    padding: 0,
                                    position: 'relative',
                                    top: -1,
                                  }}
                                  onClick={() => {
                                    setToevoegendeInterneLocaties((curr) => ({
                                      ...curr,
                                      [row.LocID]: true,
                                    }));
                                  }}
                                >
                                  <IconToevoegen
                                    style={{ fill: Kleur.Grijs, width: 16, height: 16 }}
                                  />
                                </button>
                              </UitlegTooltip>
                            )}
                          </div>
                        );
                      }}
                    />

                    <SelectionState
                      selection={urlState.selectie}
                      onSelectionChange={(x) => setUrlStateSync('selectie', x as number[])}
                    />
                    <EditingState
                      onEditingRowIdsChange={(rowIds) => {
                        const id = rowIds[rowIds.length - 1] as number;
                        handleWijzigenLocatieClick(id);
                      }}
                      onCommitChanges={async (changes) => {
                        if (changes.deleted === undefined) {
                          return;
                        }
                        const deleted = changes.deleted;
                        const id = deleted[deleted.length - 1] as number;
                      }}
                    />

                    <VirtualTable
                      cellComponent={CellComponent}
                      messages={{ noData: 'Geen locaties' }}
                    />
                    <TableEditColumn
                      width={35}
                      showEditCommand
                      cellComponent={DXTableEditColumnCellComponent}
                      commandComponent={DXTableEditColumnCommandComponent}
                    />
                    <TableSelection cellComponent={DXTableCheckboxComponent} />
                    <TableColumnResizing defaultColumnWidths={kolomBreedtes} />

                    <TableHeaderRow />
                  </Grid>
                </GridStyleWrapper>
              );
            }}
          </AutoSizer>
        )
      }
    />
  );
});

export default withRouter(Locaties);
