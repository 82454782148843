import React, { useCallback, useEffect, useMemo, useState } from 'react';
import IDialoogProps from '../../../core/IDialoogProps';
import Modal from 'react-bootstrap/Modal';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import RadioKnop from '../../formulier/RadioKnop';
import VinkVeld from '../../formulier/VinkVeld';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';

export enum EKeuzeType {
  Enkel,
  Meer,
}

export interface IEnkelOptions<TId> {
  // Bepaald de eerst geselecteerde optie, of anders het bovenste element in de lijst.
  beginSelectie?: TId;
}

export interface IMeerOptions<TId> {
  beginSelectie?: TId[];
  minimaalSelectie?: number;
  maximaalSelectie?: number;
}

/**
 * De keuze is afhankelijk van het keuze type, bij enkel is het eerste element in de array van
 * toepassing.
 */
export interface ISelectieResult<TId> {
  keuze: TId[];
}

export interface IOptie<TId> {
  id: TId;
  label: string | JSX.Element;
}

interface IProps<TId> extends IDialoogProps<ISelectieResult<TId>> {
  type: EKeuzeType;
  opties: IOptie<TId>[];
  enkelOptions?: IEnkelOptions<TId>;
  meerOptions?: IMeerOptions<TId>;
  titel?: string | JSX.Element;
  body?: string | JSX.Element;
}

const defaultEnkelOptions: IEnkelOptions<any> = {
  beginSelectie: undefined,
};
const defaultMeerOptions: IMeerOptions<any> = {
  beginSelectie: undefined,
  minimaalSelectie: 1,
  maximaalSelectie: undefined,
};

const KeuzeDialoog = <TId extends any>(props: IProps<TId>) => {
  const { type, open, onSuccess, onAnnuleren, opties, titel, body } = props;
  const [isBezig, setIsBezig] = useState(false);

  const enkelOptions = useMemo<IEnkelOptions<TId>>(
    () => ({ ...defaultEnkelOptions, ...props.enkelOptions }),
    [props.enkelOptions],
  );
  const meerOptions = useMemo<IMeerOptions<TId>>(
    () => ({
      ...defaultMeerOptions,
      ...props.meerOptions,
    }),
    [props.meerOptions],
  );

  const [keuze, setKeuze] = useState<TId[]>([]);

  useEffect(() => {
    // Voor selectie bij type enkel
    if (type === EKeuzeType.Meer || keuze.length !== 0) {
      return;
    }
    if (enkelOptions.beginSelectie === undefined) {
      setKeuze([opties[0].id]);
    } else {
      setKeuze([enkelOptions.beginSelectie!]);
    }
  }, [type, enkelOptions]);

  useEffect(() => {
    // Voor selectie bij type meer
    if (type === EKeuzeType.Enkel || keuze.length !== 0) {
      return;
    }
    if (meerOptions.beginSelectie !== undefined) {
      setKeuze(meerOptions.beginSelectie!);
    }
  }, [type, meerOptions.beginSelectie]);

  const handleOkClick = useCallback(async () => {
    setIsBezig(true);

    await onSuccess({
      keuze,
    });

    setIsBezig(false);
  }, [onSuccess, keuze]);

  const magDoorvoeren = useMemo(() => {
    if (type === EKeuzeType.Enkel) {
      return keuze.length === 1;
    } else if (type === EKeuzeType.Meer) {
      const criteriaMinimaalSelectie = keuze.length >= meerOptions.minimaalSelectie!;
      const criteriaMaximaalSelectie =
        meerOptions.maximaalSelectie === undefined
          ? true
          : keuze.length <= meerOptions.maximaalSelectie;

      return criteriaMinimaalSelectie && criteriaMaximaalSelectie;
    }

    return false;
  }, [keuze, enkelOptions, meerOptions, type]);

  return (
    <Modal show={open}>
      {titel !== undefined && (
        <ModalHeader>
          <ModalTitle>{titel}</ModalTitle>
        </ModalHeader>
      )}
      <ModalBody>
        {body !== undefined && <div>{body}</div>}
        {opties.map((optie, i) => {
          return (
            <div key={i} className={`d-flex align-items-center ${i === 0 ? '' : 'mt-2'}`}>
              <span>
                {type === EKeuzeType.Enkel ? (
                  <RadioKnop
                    aangevinkt={keuze.includes(optie.id)}
                    onAangevinkt={() => setKeuze([optie.id])}
                  />
                ) : (
                  <VinkVeld
                    aangevinkt={keuze.includes(optie.id)}
                    onGewijzigd={() => {
                      if (keuze.includes(optie.id)) {
                        setKeuze(keuze.filter((id) => id !== optie.id));
                      } else {
                        setKeuze([...keuze, optie.id]);
                      }
                    }}
                  />
                )}
              </span>
              <span className="ml-2">{optie.label}</span>
            </div>
          );
        })}
      </ModalBody>
      <ModalFooter className="d-flex flex-row justify-content-start">
        <button
          className="btn btn-primary"
          onClick={handleOkClick}
          style={{ width: 100 }}
          disabled={!magDoorvoeren || isBezig}
        >
          Ok
        </button>
        <button
          className="btn btn-secondary"
          onClick={onAnnuleren}
          style={{ width: 100 }}
          disabled={isBezig}
        >
          Annuleren
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default KeuzeDialoog;
