import React from 'react';
import { Kleur } from '../../../../../bedrijfslogica/constanten';
import { EOpdrachtstatusTransport } from '../../../../../bedrijfslogica/enums';

interface IProps {
  naamEnum?: EOpdrachtStatus;
  status?: number;
  naam?: string;
  naamWeergeven?: boolean;
}

export enum EOpdrachtStatus {
  Planning = 'PLANNING',
  Uitstaand = 'UITSTAAND',
  Afgehandeld = 'AFGEHANDELD',
}

export const kleurMap: Record<EOpdrachtStatus, string> = {
  [EOpdrachtStatus.Planning]: Kleur.LichtBlauw,
  [EOpdrachtStatus.Uitstaand]: Kleur.Oranje,
  [EOpdrachtStatus.Afgehandeld]: Kleur.Grijs,
};

const OpdrachtStatus: React.FC<IProps> = (props) => {
  const naamWeergeven = props.naamWeergeven ?? true;
  const naam = naamWeergeven
    ? props.naam !== undefined
      ? props.naam
      : props.status === EOpdrachtstatusTransport.Planning
      ? 'Planning'
      : props.status === EOpdrachtstatusTransport.Uitstaand
      ? 'Uitstaand'
      : props.status === EOpdrachtstatusTransport.Afgehandeld
      ? 'Afgehandeld'
      : ''
    : undefined;

  const status =
    props.status !== undefined
      ? props.status
      : props.naamEnum === EOpdrachtStatus.Planning
      ? 1
      : props.naamEnum === EOpdrachtStatus.Uitstaand
      ? 2
      : 3;

  const naamEnum =
    props.naamEnum !== undefined
      ? props.naamEnum
      : props.status === 1
      ? EOpdrachtStatus.Planning
      : props.status === 2
      ? EOpdrachtStatus.Uitstaand
      : EOpdrachtStatus.Afgehandeld;

  return (
    <div className="d-flex align-items-center">
      <div
        style={{
          width: 12,
          height: 12,
          borderRadius: '50%',
          backgroundColor: kleurMap[naamEnum],
        }}
      />
      {naam !== undefined && <span className="ml-1">{naam}</span>}
    </div>
  );
};

export default OpdrachtStatus;
