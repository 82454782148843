import React, { useContext, useEffect, useMemo } from 'react';
import api from '../../../../api';
import { observer } from 'mobx-react-lite';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Kleur as EKleur } from '../../../../bedrijfslogica/constanten';
import { EResultType } from '../../../../stores/CheckStore';
import { RootStoreContext } from '../../../../stores/RootStore';
import { IOphalenOpdrachtregelsResultElementV2 } from '../../../../../../shared/src/api/v2/transport/opdracht';
import { ERegelstatusTransport } from '../../../../bedrijfslogica/enums';

interface IRegel extends IOphalenOpdrachtregelsResultElementV2 {}

interface IProps extends RouteComponentProps {
  rij: IRegel;
  onVernieuwen: () => void;
}

const AfmeldComp: React.FC<IProps> = observer((props) => {
  const { checkStore } = useContext(RootStoreContext);

  const row: IRegel = props.rij;

  const kleur: EKleur =
    row.status.Status === ERegelstatusTransport.UITGEVOERD
      ? EKleur.Groen
      : row.status.Status === ERegelstatusTransport.MISLUKT
      ? EKleur.Rood
      : row.status.Status === ERegelstatusTransport.GEANNULEERD
      ? EKleur.Oranje
      : EKleur.Blauw;
  //return <span style={{ color: kleur }}>{row.statusnaam}</span>;
  return row.status.Status === ERegelstatusTransport.NIET_AFGEMELD ? (
    // Afmelden
    <a
      href="#"
      // onClick={() => setAfmeldenDialoogTonen(row.trsRegID)}
      onClick={
        () => alert('Je kunt hier niet afmelden, ga daarvoor naar de Transportopdrachten')
        // onAfmeldenOpdrachtDialoogStateChange({
        //   trsOpdID: row.TrsOpdID,
        //   trsRegID: row.TrsRegID,
        // })
      }
      style={{ color: kleur }}
    >
      Afmelden
    </a>
  ) : (
    // Herstel afmelden/geannuleerd
    <a
      href="#"
      onClick={async () => {
        // De status Geannuleerd apart afhandelen
        if (row.status.Status === ERegelstatusTransport.GEANNULEERD) {
          const resultaat = await checkStore.bevestigen({
            inhoud: `Annulering ongedaan maken?`,
          });
          if (resultaat.type === EResultType.Annuleren) {
            return;
          }
          await api.v2.transport.opdracht.uitstaand.herstellenAnnulerenOpdrachtregels({
            trsRegIDs: [row.TrsRegID],
          });
          props.onVernieuwen();
          return;
        }

        // Herstel afmelden
        const resultaat = await checkStore.bevestigen({
          inhoud: `Afmelden ongedaan maken?`,
        });
        if (resultaat.type === EResultType.Annuleren) {
          return;
        }

        await api.v2.transport.herstellenAfmeldenOpdrachtregels({
          trsRegIDs: [row.TrsRegID],
        });

        props.onVernieuwen();
      }}
      style={{ color: kleur }}
    >
      {row.status.Naam}
    </a>
  );
});

export default withRouter(AfmeldComp);
