import React, { useCallback, useMemo } from 'react';
import MultiComboboxV2, { EnkeleProvider, IRepresentatieProps, Provider } from '../MultiComboboxV2';
import { IOphalenFacturenResultElement } from '../../../../../shared/src/api/v2/inkoopfactuur';
import api from '../../../api';
import { ASPKolom, EAspKolomBreedteType } from '../../tabel/ASPTabel/types';
import { format } from 'date-fns';
import FormatteerBedrag from '../../MutatieBedrag';
import RelatieVisualisatie from '../../personalia/RelatieVisualisatie';

export enum EInkoopfactuurSelectieKolom {
  Nummer,
  Datum,
  Bedrag,
  Openstaand,
  Relatie,
}

export const enkeleProvider: EnkeleProvider<number, IOphalenFacturenResultElement> = async (id) => {
  const result = await api.v2.inkoopfactuur.ophalenFacturen({
    filterSchema: {
      filters: [
        {
          naam: 'IDS',
          data: [id],
        },
      ],
    },
  });
  return result.facturen[0];
};

const StandaardRepresentatieComponent: React.ComponentType<IRepresentatieProps<
  IOphalenFacturenResultElement
>> = (props) => {
  return <span>{props.entiteit.Factuurnummer}</span>;
};

interface IProps {
  inkFactID: number | null;
  onChange: (inkFactID: number | null) => void;
  provider: Provider<EInkoopfactuurSelectieKolom, IOphalenFacturenResultElement>;
  representatieComponent?: React.ComponentType<IRepresentatieProps<IOphalenFacturenResultElement>>;
}

const InkoopfactuurSelectieV2 = (props: IProps) => {
  const keyExtractor = useCallback((item: IOphalenFacturenResultElement) => item.InkFactID, []);
  const representatieComponent = useMemo(
    () => props.representatieComponent ?? StandaardRepresentatieComponent,
    [props.representatieComponent],
  );

  const kolommen = useMemo<ASPKolom<EInkoopfactuurSelectieKolom, IOphalenFacturenResultElement>[]>(
    () => [
      {
        key: EInkoopfactuurSelectieKolom.Nummer,
        label: 'Nummer',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 125,
        renderer: (x) =>
          x.Factuurnummer ?? (
            <span title="Niet definitief" className="text-muted">
              Niet def.
            </span>
          ),
      },
      {
        key: EInkoopfactuurSelectieKolom.Datum,
        label: 'Datum',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 125,
        renderer: (x) => format(new Date(x.Factuurdatum), 'dd-MM-yyyy'),
      },
      {
        key: EInkoopfactuurSelectieKolom.Bedrag,
        label: 'Bedrag',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 100,
        renderer: (x) => <FormatteerBedrag bedrag={x.Bedrag} />,
      },
      {
        key: EInkoopfactuurSelectieKolom.Openstaand,
        label: 'Openstaand',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 100,
        renderer: (x) => <FormatteerBedrag bedrag={x.Openstaand} />,
      },
      {
        key: EInkoopfactuurSelectieKolom.Relatie,
        label: 'Relatie',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 350,
        renderer: (x) => <RelatieVisualisatie relID={x.RelID} />,
      },
    ],
    [],
  );

  return (
    <MultiComboboxV2
      provider={props.provider}
      enkeleProvider={enkeleProvider}
      keyExtractor={keyExtractor}
      waarde={props.inkFactID}
      onChange={props.onChange}
      representatieComponent={representatieComponent}
      kolommen={kolommen}
    />
  );
};

export default InkoopfactuurSelectieV2;
