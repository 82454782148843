import {
  IOphalenPremiumsParams,
  IOphalenPremiumsResult,
  IOphalenPremiumsVoorOpdrachtenParams,
  IOphalenPremiumsVoorOpdrachtenResult,
  IToevoegenPremiumParams,
  IToevoegenPremiumResult,
  IVerwijderenPremiumsParams,
  IVerwijderenPremiumsResult,
  IWijzigenPremiumParams,
  IWijzigenPremiumResult,
} from '../../../../../../shared/src/api/v2/transport/opdracht/premium';
import ICheckData from '../../../../../../shared/src/models/ICheckData';
import api from '../../../index';

const premium = {
  ophalenPremiums: async (params: IOphalenPremiumsParams): Promise<IOphalenPremiumsResult> => {
    return await api.post('v2/transport/opdracht/premium/ophalen-premiums', params);
  },
  ophalenPremiumsVoorOpdrachten: async (
    params: IOphalenPremiumsVoorOpdrachtenParams,
  ): Promise<IOphalenPremiumsVoorOpdrachtenResult> => {
    return await api.post('v2/transport/opdracht/premium/ophalen-premiums-voor-opdrachten', params);
  },
  checkToevoegenPremium: async (params: IToevoegenPremiumParams): Promise<ICheckData> => {
    return await api.post('v2/transport/opdracht/premium/check-toevoegen-premium', params);
  },
  toevoegenPremium: async (params: IToevoegenPremiumParams): Promise<IToevoegenPremiumResult> => {
    return await api.post('v2/transport/opdracht/premium/toevoegen-premium', params);
  },
  checkWijzigenPremium: async (params: IWijzigenPremiumParams): Promise<ICheckData> => {
    return await api.post('v2/transport/opdracht/premium/check-wijzigen-premium', params);
  },
  wijzigenPremium: async (params: IWijzigenPremiumParams): Promise<IWijzigenPremiumResult> => {
    return await api.post('v2/transport/opdracht/premium/wijzigen-premium', params);
  },
  checkVerwijderenPremiums: async (params: IVerwijderenPremiumsParams): Promise<ICheckData> => {
    return await api.post('v2/transport/opdracht/premium/check-verwijderen-premiums', params);
  },
  verwijderenPremiums: async (
    params: IVerwijderenPremiumsParams,
  ): Promise<IVerwijderenPremiumsResult> => {
    return await api.post('v2/transport/opdracht/premium/verwijderen-premiums', params);
  },
};

export default premium;
