import api from '..';
import {
  IOphalenMediaTypesV2Params,
  IOphalenMediaTypesV2Result,
  IToevoegenMediaTypeParams,
  IToevoegenMediaTypeResult,
  IOphalenMediaTypeBijMediaTypeOfToevoegenParams,
  IOphalenMediaTypeBijMediaTypeOfToevoegenResult,
} from '../../../../shared/src/api/v2/mediatype';
import ICheckData from '../../../../shared/src/models/ICheckData';

const mediaType = {
  ophalenVoorRijBron: async () => {
    return await api.post('v2/mediatype/ophalen-media-types', {});
  },
  ophalenMediaTypes: async (
    params: IOphalenMediaTypesV2Params,
  ): Promise<IOphalenMediaTypesV2Result> => {
    return await api.post('v2/mediatype/ophalen-media-types', params);
  },
  checkToevoegenMediaType: async (params: IToevoegenMediaTypeParams): Promise<ICheckData> => {
    return await api.post('v2/mediatype/check-toevoegen-media-type', params);
  },
  toevoegenMediaType: async (
    params: IToevoegenMediaTypeParams,
  ): Promise<IToevoegenMediaTypeResult> => {
    return await api.post('v2/mediatype/toevoegen-media-type', params);
  },
  async ophalenMediaTypeBijMediaTypeOfToevoegen(
    params: IOphalenMediaTypeBijMediaTypeOfToevoegenParams,
  ): Promise<IOphalenMediaTypeBijMediaTypeOfToevoegenResult> {
    return await api.post('v2/mediatype/ophalen-media-type-bij-media-type-of-toevoegen', params);
  },
};

export default mediaType;
