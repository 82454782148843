import React from 'react';
import { ISectieProps } from '../index';
import GegevensLayout from '../../../../../../../../../components/layout/GegevensLayout';
import { format } from 'date-fns';

interface IProps extends ISectieProps {}

const TerugkoppelingSectie = (props: IProps) => {
  return (
    <div className="d-flex flex-column">
      <GegevensLayout
        labelBreedte={100}
        gegevens={[
          {
            label: 'Is verholpen',
            waarde: props.melding.terugkoppeling!.IsVerholpen ? 'Ja' : 'Nee',
          },
          {
            label: 'Toelichting',
            waarde: props.melding.terugkoppeling!.Toelichting ?? 'Geen toelichting gegeven',
          },
          {
            label: 'Datum',
            waarde: format(new Date(props.melding.terugkoppeling!.Datum), 'dd-MM-yyyy HH:mm'),
          },
        ]}
      />
    </div>
  );
};

export default TerugkoppelingSectie;
