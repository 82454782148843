import React from 'react';
import { IWeergaveProps } from '../../../../../../../components/FilterBalkV2';
import UitlegTooltip from '../../../../../../../components/formulier/UitlegTooltip';

const ZoektermFilter = (p: IWeergaveProps<any>) => {
  return (
    <span className="d-flex align-items-center">
      <span className="mr-3">Zoekterm</span>
      <UitlegTooltip inhoud="Je kunt zoeken op Referentiecode, Contractnr, Typenaam, Huisnr, Postcode, Straatnaam, Plaatsnaam of Modelnaam">
        <input
          style={{ minWidth: 175 }}
          placeholder="Bv. Ref.nr, Huisnr, Typenaam of Postcode"
          className="form-control"
          value={p.state || ''}
          onChange={(ev) => {
            p.onStateChange(ev.target.value);
            p.onDataChange(ev.target.value);
          }}
          onKeyUp={(ev) => {
            if (ev.key === 'Enter') {
              p.onDataChange(p.state);
              p.setIsActief(true);
              p.toepassen();
            }
          }}
        />
      </UitlegTooltip>
    </span>
  );
};

export default ZoektermFilter;
