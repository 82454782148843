import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../stores/RootStore';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
  mapRemoteData,
} from '../../../models/IRemoteData';
import api from '../../../api';
import { IRijenGerenderedIndex } from '../../tabel/ASPTabel/Body';
import useBijGewijzigdEffect from '../../../core/useBijGewijzigdEffect';
import Combobox, { IOptie } from '../../formulier/Combobox';
import { INaamComponentProps } from '../../personalia/RelatieVisualisatie';
import { Kleur } from '../../../bedrijfslogica/constanten';
import TelefoonHistorieStore, {
  ICommunicatieOverlayRecentTelefoonEvent,
  ICommunicatieOverlayRecentTelefoonOproep,
} from '../../../stores/TelefoonHistorieStore';
import { ASPKolom, EAspKolomBreedteType, ESorteringModus } from '../../tabel/ASPTabel/types';
import TableData, { ITableDataProps } from '../../tabel/ASPTabel/Body/TableData';
import { IconInkomend, IconSter, IconSterBorder, IconUitgaand, IconWarning } from '../../Icons';
import Skeleton from 'react-loading-skeleton';
import { EResultType } from '../../../stores/CheckStore';
import TelefoonComponent from '../TelefoonComponent';
import ContactKoppelComponent from '../../ContactKoppelComponent';
import RelatieKoppelComponent from '../../RelatieKoppelComponent';
import { format, intervalToDuration } from 'date-fns';
import ASPGebruikerVisualisatie, { EModus } from '../../ASPGebruiker/ASPGebruikerVisualisatie';
import { ETelefooneventBeeindigdReden, ETelefooneventStatus } from '../../../bedrijfslogica/enums';
import GesprekscontextVeld from '../../telefoon/GesprekscontextVeld';
import LoadingSpinner from '../../Gedeeld/LoadingSpinner';
import RadioKnop from '../../formulier/RadioKnop';
import VerticaleScheidingslijn from '../../layout/VerticaleScheidingslijn';
import HorizontaleScheidingslijn from '../../layout/HorizontaleScheidingslijn';
import ASPTabel from '../../tabel/ASPTabel';
import { IOphalenAfdelingenResultElement } from '../../../../../shared/src/api/v2/relatie/afdeling';
import { IOphalenContactpersonenResultElement } from '../../../../../shared/src/api/v2/relatie/contactpersoon';
import { IFilterSchema, IFilterSchemaFilter } from '../../../../../shared/src/models/filter';
import IPaginatiePositie from '../../../../../shared/src/models/IPaginatiePositie';
import VigerendTableData, {
  IVigerendTableDataProps,
} from '../../tabel/ASPTabel/Body/VigerendTableData';
import {
  IOproep,
  IOphalenTelefoonoproepXContextResultElement,
  IOphalenTelefoonPinsResult,
  ITelefoonPin,
} from '../../../../../shared/src/api/v2/telefonie';

export enum ERichting {
  Beide = 'BEIDE',
  Inkomend = 'INKOMEND',
  Uitgaand = 'UITGAAND',
}

enum EKolom {
  Richting,
  TelefoonboekPin,
  Nummer,
  Contact,
  Afdeling,
  InzakeVan,
  Datum,
  NummberBedrijf,
  Gespreksduur,
  Gebruiker,
  Inhoud,
}

interface ITabelData {
  telefoonoproepen: Record<number, IOproep>;
  totaalAantalTelefoonoproepen: number;
  telefoonoproepXContexten: IOphalenTelefoonoproepXContextResultElement[];
  contactpersonen: IOphalenContactpersonenResultElement[];
  afdelingen: IOphalenAfdelingenResultElement[];
}

const achtergrondKleurGemisteOproep = 'rgb(253, 237, 237)';
const achtergrondKleurVoicemailOproep = 'rgb(240, 250, 250)';

const isGemisteOproep = (
  oproep: ICommunicatieOverlayRecentTelefoonOproep,
  events: ICommunicatieOverlayRecentTelefoonEvent[],
) => {
  // Een oproep met gebruiker is per definitie opgenomen
  if (oproep.AspGebrID !== null) {
    return false;
  }

  // Een oproep is gemist als die beëindigd is met in gesprek of niet beantwoord, of als de oproep
  // geslaagd beëindigd is maar geen beantwoord event bevat
  const beeindigdEvent = events.find((x) => x.Status === ETelefooneventStatus.Beeindigd);
  if (beeindigdEvent === undefined) {
    return false;
  }

  if (
    beeindigdEvent.Reden !== null &&
    (beeindigdEvent.Reden === ETelefooneventBeeindigdReden.InGesprek ||
      beeindigdEvent.Reden === ETelefooneventBeeindigdReden.NietBeantwoord)
  ) {
    return true;
  }

  return !events.some((x) => x.Status === ETelefooneventStatus.Beantwoord);
};

interface IProps {
  telefoonHistorieStore: TelefoonHistorieStore;
  richting: ERichting;
  onRichtingChange: (richting: ERichting) => void;
  vanGebruiker: 'IEDEREEN' | number | null;
  laatsteVanGebruikerSelectie: 'IEDEREEN' | number | null;
  onVanGebruikerChange: (vanGebruiker: 'IEDEREEN' | number | null) => void;
  vigerend?: number;
}

const TelefoonHistorie = observer((props: IProps) => {
  const { gebruikerStore, checkStore } = useContext(RootStoreContext);

  useEffect(() => {
    if (props.telefoonHistorieStore.oproepContextenResult.state === ERemoteDataState.Ready) {
      return;
    }
    props.telefoonHistorieStore.ophalenOproepContexten();
  }, []);

  useEffect(() => {
    if (props.telefoonHistorieStore.telefoonnummersData.state === ERemoteDataState.Ready) {
      return;
    }
    props.telefoonHistorieStore.ophalenTelefoonnummers();
  }, []);

  const [telefoonpinsResult, setTelefoonpinsResult] = useState<
    IRemoteData<IOphalenTelefoonPinsResult>
  >(createPendingRemoteData());
  const ophalenTelefoonpins = useCallback(async () => {
    const result = await api.v2.telefonie.ophalenTelefoonPins({
      filterSchema: {
        filters: [
          {
            naam: 'ASP_GEBR_IDS',
            data: [gebruikerStore.gebruiker!.AspGebrID],
          },
        ],
      },
    });
    setTelefoonpinsResult(createReadyRemoteData(result));
  }, [gebruikerStore.gebruiker!.AspGebrID]);
  useEffect(() => {
    ophalenTelefoonpins();
  }, [ophalenTelefoonpins]);

  const telefoonpinsBijTelefoonnummer = useMemo<IRemoteData<Record<string, ITelefoonPin>>>(
    () =>
      mapRemoteData(telefoonpinsResult, (data) =>
        data.telefoonPins.reduce(
          (acc, curr) => ({
            ...acc,
            [curr.Telefoonnummer]: curr,
          }),
          {},
        ),
      ),
    [telefoonpinsResult],
  );

  const [rijenGerenderedIndex, setRijenGerenderedIndex] = useState<IRijenGerenderedIndex | null>(
    null,
  );

  const filterSchema = useMemo<IFilterSchema>(() => {
    return {
      filters: [
        props.vanGebruiker === 'IEDEREEN'
          ? null
          : {
              naam: 'ASP_GEBR_IDS',
              data: [props.vanGebruiker],
            },
        props.richting === ERichting.Beide
          ? null
          : {
              naam: 'RICHTINGEN',
              data: [props.richting === ERichting.Inkomend ? 'I' : 'U'],
            },
      ].filter((x) => x !== null) as IFilterSchemaFilter[],
    };
  }, [props.vanGebruiker, props.richting]);

  // Initieel waar nodig
  useEffect(() => {
    if (props.telefoonHistorieStore.oproepenData.state === ERemoteDataState.Ready) {
      return;
    }
    props.telefoonHistorieStore.ophalenOproepen({
      paginatie: {
        index: 0,
        aantal: 50,
      },
      filterSchema,
      uitbreiden: false,
    });
  }, []);

  // Als filterschema wijzigd
  useBijGewijzigdEffect(() => {
    props.telefoonHistorieStore.ophalenOproepen({
      paginatie: {
        index: rijenGerenderedIndex?.startIndex ?? 0,
        aantal: 50,
      },
      filterSchema,
      uitbreiden: false,
    });
  }, [filterSchema]);

  const verversenTabeldata = useCallback(async () => {
    await props.telefoonHistorieStore.ophalenOproepen({
      paginatie: {
        index: rijenGerenderedIndex?.startIndex ?? 0,
        aantal: 50,
      },
      uitbreiden: false,
      filterSchema,
    });
  }, [props.telefoonHistorieStore.oproepenData, rijenGerenderedIndex, filterSchema]);

  const gebruikersRijbron = useMemo(() => {
    if (gebruikerStore.gebruikers === null) {
      return null;
    }

    return gebruikerStore.gebruikers.filter(
      (gebr) => gebr.AspGebrID !== gebruikerStore.gebruiker!.AspGebrID,
    );
  }, [gebruikerStore.gebruikers, gebruikerStore.gebruiker]);

  const comboboxOpties = useMemo<IOptie<number | 'IEDEREEN'>[] | null>(() => {
    if (gebruikersRijbron === null) {
      return null;
    }

    const opties = [
      {
        id: 'IEDEREEN' as 'IEDEREEN',
        label: 'Iedereen',
      },
      ...gebruikersRijbron.map((gebr) => ({
        id: gebr.AspGebrID,
        label: gebr.NaamKort,
      })),
    ];

    return opties;
  }, [gebruikersRijbron]);

  const persoonOfOrganisatieIsRelatieNaamComponent = useCallback(
    (naamProps: INaamComponentProps) => {
      return (
        <span
          style={{
            color: Kleur.Grijs,
          }}
        >
          {naamProps.naam}
        </span>
      );
    },
    [],
  );

  const keyExtractor = useCallback((row: ICommunicatieOverlayRecentTelefoonOproep) => row.ID, []);

  const kolommen = useMemo<ASPKolom<EKolom, ICommunicatieOverlayRecentTelefoonOproep>[]>(
    () => [
      {
        key: EKolom.Richting,
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 35,
        tdComponent: (tdProps) => {
          return (
            <TableData
              {...tdProps}
              style={{
                padding: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor:
                  props.telefoonHistorieStore.eventsData.state === ERemoteDataState.Ready &&
                  isGemisteOproep(
                    tdProps.row!,
                    props.telefoonHistorieStore.eventsData.data!.events[tdProps.row!.ID] ?? [],
                  )
                    ? achtergrondKleurGemisteOproep
                    : undefined,
              }}
            />
          );
        },
        renderer: (row) => {
          const isUitgaand = row.Richting === 'U';
          return isUitgaand ? (
            <IconUitgaand
              style={{
                width: 18,
                height: 18,
                fill: Kleur.Blauw,
                transform: 'rotate(45deg)',
                position: 'relative',
                top: 1,
              }}
            />
          ) : (
            <IconInkomend
              style={{
                width: 18,
                height: 18,
                fill: Kleur.DonkerGroen,
                transform: 'rotate(45deg)',
                position: 'relative',
                top: 1,
              }}
            />
          );
        },
      },
      {
        key: EKolom.TelefoonboekPin,
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 30,
        tdComponent: (tdProps) => {
          return (
            <TableData
              {...tdProps}
              style={{
                padding: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor:
                  props.telefoonHistorieStore.eventsData.state === ERemoteDataState.Ready &&
                  isGemisteOproep(
                    tdProps.row!,
                    props.telefoonHistorieStore.eventsData.data!.events[tdProps.row!.ID] ?? [],
                  )
                    ? achtergrondKleurGemisteOproep
                    : undefined,
              }}
            />
          );
        },
        renderer: (row) => {
          // Als er geen nummer bekend is kunnen we deze ook niet pinnen
          if (row.Nummer === null) {
            return null;
          }

          const telefoonpin = mapRemoteData(
            telefoonpinsBijTelefoonnummer,
            (data) => data[row.Nummer!] ?? null,
          );

          if (telefoonpin.state === ERemoteDataState.Pending) {
            return (
              <div
                style={{
                  width: 17,
                  height: 17,
                }}
              >
                <Skeleton />
              </div>
            );
          }

          return (
            <button
              style={{
                padding: 0,
                margin: 0,
                background: 0,
                border: 0,
                outline: 0,
                position: 'relative',
                top: -1,
              }}
              onClick={async () => {
                if (telefoonpin.data === null) {
                  const params = {
                    aspGebrID: gebruikerStore.gebruiker!.AspGebrID,
                    telefoonnummer: row.Nummer!,
                    label: null,
                  };
                  const checkData = await api.v2.telefonie.checkToevoegenTelefoonpin(params);
                  if (
                    (await checkStore.controleren({ checkData })).type === EResultType.Annuleren
                  ) {
                    return;
                  }
                  await api.v2.telefonie.toevoegenTelefoonpin(params);
                } else {
                  await api.v2.telefonie.verwijderenTelefoonpins({
                    ids: [telefoonpin.data!.ID],
                  });
                }
                await ophalenTelefoonpins();
              }}
            >
              {telefoonpin.data === null ? (
                <IconSterBorder
                  style={{
                    width: 17,
                    height: 17,
                    fill: Kleur.Grijs,
                  }}
                />
              ) : (
                <IconSter
                  style={{
                    width: 17,
                    height: 17,
                    fill: Kleur.Grijs,
                  }}
                />
              )}
            </button>
          );
        },
      },
      {
        key: EKolom.Nummer,
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 150,
        label: 'Telefoonnummer',
        renderer: (row) => {
          if (row.Nummer === null) {
            return <span>Anoniem</span>;
          }

          return (
            <TelefoonComponent
              telefoonNummer={row.Nummer}
              options={{
                icoonWeergeven: false,
              }}
            />
          );
        },
      },
      {
        key: EKolom.Contact,
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 300,
        label: 'Contact',
        renderer: (row) => {
          const onChange = async (persID: number | null, orgID: number | null) => {
            await api.v2.telefonie.wijzigenTelefoonOproep({
              telOprID: row.ID,
              persID,
              orgID,
            });
          };

          if (row.OrgAfdID !== null) {
            if (props.telefoonHistorieStore.afdelingenData.state === ERemoteDataState.Pending) {
              return <Skeleton />;
            }
            const afdeling = props.telefoonHistorieStore.afdelingenData.data!.afdelingen[
              row.OrgAfdID!
            ];
            if (afdeling === undefined) {
              return <Skeleton />;
            }

            return (
              <div className="d-flex flex-column">
                <ContactKoppelComponent
                  persID={null}
                  orgID={afdeling.OrgID}
                  onChange={onChange}
                  persoonDefaultFormValues={{
                    telefoon: row.Nummer ?? undefined,
                  }}
                />
              </div>
            );
          }

          return (
            <div className="d-flex flex-column">
              <ContactKoppelComponent
                persID={row.PersID}
                orgID={row.OrgID}
                onChange={onChange}
                persoonDefaultFormValues={{
                  telefoon: row.Nummer ?? undefined,
                }}
              />
            </div>
          );
        },
      },
      {
        key: EKolom.Afdeling,
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 125,
        label: 'Afdeling',
        renderer: (row) => {
          if (row.OrgAfdID === null) {
            return '- -';
          }
          if (props.telefoonHistorieStore.afdelingenData.state === ERemoteDataState.Pending) {
            return <Skeleton />;
          }
          const afdeling = props.telefoonHistorieStore.afdelingenData.data!.afdelingen[
            row.OrgAfdID
          ];
          if (afdeling === undefined) {
            return <Skeleton />;
          }

          return afdeling.Naam;
        },
      },
      {
        key: EKolom.InzakeVan,
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 300,
        label: 'Inzake van',
        renderer: (row) => {
          if (props.telefoonHistorieStore.contactpersonenData.state === ERemoteDataState.Pending) {
            return <Skeleton />;
          }
          const contactpersonen = props.telefoonHistorieStore.contactpersonenData.data!;

          // Als het een organisatie is, dan is het altijd zichzelf
          // zo niet, dan kijken of we een persID en relID hebben
          // en kijken of dit deze persoon de relatie is
          const persoonOfOrganisatieIsRelatie =
            row.OrgID !== null
              ? true
              : row.PersID === null || row.Inzake_RelID === null
              ? false
              : Object.values(contactpersonen).find(
                  (x) => x.PersID === row.PersID && x.RelID === row.Inzake_RelID,
                ) !== undefined;

          return (
            <RelatieKoppelComponent
              relID={row.Inzake_RelID}
              onRelIDChange={async (relID) => {
                const result = await api.v2.telefonie.wijzigenTelefoonOproep({
                  telOprID: row.ID,
                  inzake_RelID: relID,
                });
                await verversenTabeldata();
              }}
              relatieNaamComponent={
                persoonOfOrganisatieIsRelatie
                  ? persoonOfOrganisatieIsRelatieNaamComponent
                  : undefined
              }
            />
          );
        },
      },
      {
        key: EKolom.Datum,
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 160,
        label: 'Datum/tijd',
        renderer: (row) => {
          return <span>{format(new Date(row.Datum), 'dd-MM-yyyy HH:mm')}</span>;
        },
      },
      {
        key: EKolom.NummberBedrijf,
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 115,
        label: 'Tel. bedrijf',
        renderer: (row) => {
          if (row.NummerBedrijf_TelNrBedrijfID === null) {
            return <span>- -</span>;
          }
          if (props.telefoonHistorieStore.telefoonnummersData.state === ERemoteDataState.Pending) {
            return <Skeleton />;
          }
          const nummer = props.telefoonHistorieStore.telefoonnummersData.data!.telefoonnummers[
            row.NummerBedrijf_TelNrBedrijfID
          ];
          if (nummer === undefined) {
            return <Skeleton />;
          }

          return (
            <div className="d-flex flex-column">
              <span style={{ fontSize: 13 }}>{nummer.Nummer}</span>
            </div>
          );
        },
      },
      {
        key: EKolom.Gebruiker,
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 100,
        label: 'Gebruiker',
        renderer: (row) => {
          if (row.AspGebrID === null) {
            return null;
          }

          return <ASPGebruikerVisualisatie aspGebrID={row.AspGebrID} modus={EModus.Volledig} />;
        },
      },
      {
        key: EKolom.Gespreksduur,
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 85,
        label: 'Duur',
        renderer: (row) => {
          if (props.telefoonHistorieStore.eventsData.state === ERemoteDataState.Pending) {
            return <Skeleton />;
          }
          const events = props.telefoonHistorieStore.eventsData.data!.events[row.ID] ?? [];

          const beantwoordEvent = events.find((x) => x.Status === ETelefooneventStatus.Beantwoord);
          const beeindigtEvent = events.find((x) => x.Status === ETelefooneventStatus.Beeindigd);
          if (!beantwoordEvent || !beeindigtEvent) {
            return null;
          }
          const duration = intervalToDuration({
            start: new Date(beantwoordEvent.Datum),
            end: new Date(beeindigtEvent.Datum),
          });

          return (
            <div className="d-flex align-items-center">
              <span
                style={{
                  fontSize: 13,
                }}
                className="font-weight-bold"
              >
                {duration.hours !== 0 && `${duration.hours!.toString().padStart(2, '0')}:`}
                {(duration.minutes || 0).toString().padStart(2, '0')}:
                {(duration.seconds || 0).toString().padStart(2, '0')}
              </span>
              {beeindigtEvent.Handmatig && (
                <span
                  className="ml-1"
                  title="Deze oproep is handmatig beeïndigd. De duur van het gesprek kan hierdoor (enorm) afwijken."
                >
                  <IconWarning
                    style={{
                      width: 15,
                      height: 15,
                      fill: Kleur.Rood,
                      position: 'relative',
                      top: -2,
                    }}
                  />
                </span>
              )}
            </div>
          );
        },
      },
      {
        key: EKolom.Inhoud,
        breedteType: EAspKolomBreedteType.Flex,
        flex: 1,
        minimaleVasteBreedte: 250,
        label: 'Gesprekscontext',
        renderer: (row) => {
          return (
            <GesprekscontextVeld
              oproep={row}
              contextenResult={props.telefoonHistorieStore.oproepContextenResult}
              verversenContexten={props.telefoonHistorieStore.ophalenOproepContexten}
              telefoonoproepXContexten={mapRemoteData(
                props.telefoonHistorieStore.telefoonoproepXContextenData,
                (x) => x.mapping,
              )}
              verversenTelefoonoproepXContexten={verversenTabeldata}
            />
          );
        },
      },
    ],
    [
      props.telefoonHistorieStore.eventsData,
      props.telefoonHistorieStore.oproepContextenResult,
      props.telefoonHistorieStore.ophalenOproepContexten,
      props.telefoonHistorieStore.contactpersonenData,
      props.telefoonHistorieStore.afdelingenData,
      props.telefoonHistorieStore.telefoonnummersData,
      props.telefoonHistorieStore.telefoonoproepXContextenData,
      telefoonpinsBijTelefoonnummer,
      persoonOfOrganisatieIsRelatieNaamComponent,
      verversenTabeldata,
    ],
  );

  const TdComponent = useCallback(
    (tdProps: ITableDataProps<EKolom, ICommunicatieOverlayRecentTelefoonOproep>) => {
      if (
        tdProps.row === undefined ||
        props.telefoonHistorieStore.eventsData.state === ERemoteDataState.Pending ||
        !isGemisteOproep(
          tdProps.row!,
          props.telefoonHistorieStore.eventsData.data!.events[tdProps.row!.ID] ?? [],
        )
      ) {
        return <TableData {...tdProps} />;
      }

      return (
        <TableData
          {...tdProps}
          style={{
            backgroundColor: achtergrondKleurGemisteOproep,
          }}
        />
      );
    },
    [props.telefoonHistorieStore.eventsData],
  );

  const VigerendTdComponent = useCallback(
    (tdProps: IVigerendTableDataProps<ICommunicatieOverlayRecentTelefoonOproep>) => {
      if (
        tdProps.row === undefined ||
        props.telefoonHistorieStore.eventsData.state === ERemoteDataState.Pending ||
        !isGemisteOproep(
          tdProps.row!,
          props.telefoonHistorieStore.eventsData.data!.events[tdProps.row!.ID] ?? [],
        )
      ) {
        return <VigerendTableData {...tdProps} />;
      }

      return (
        <VigerendTableData
          {...tdProps}
          style={{
            backgroundColor: achtergrondKleurGemisteOproep,
          }}
        />
      );
    },
    [props.telefoonHistorieStore.eventsData],
  );

  const handleExtraRijenAangevraagd = useCallback(
    async (positie: IPaginatiePositie) => {
      await props.telefoonHistorieStore.ophalenOproepen({
        paginatie: positie,
        uitbreiden: true,
        filterSchema,
      });
    },
    [props.telefoonHistorieStore.ophalenOproepen, filterSchema],
  );

  const vigerend = useMemo(() => {
    return props.vigerend === undefined ? undefined : [props.vigerend];
  }, [props.vigerend]);
  const [scrollNaarIndex, setScrollNaarIndex] = useState<IRemoteData<number | null>>(
    props.vigerend === undefined ? createReadyRemoteData(null) : createPendingRemoteData(),
  );
  useEffect(() => {
    if (
      props.vigerend === undefined &&
      scrollNaarIndex.state === ERemoteDataState.Ready &&
      scrollNaarIndex.data! !== null
    ) {
      setScrollNaarIndex(createReadyRemoteData(null));
      return;
    }
    if (props.vigerend !== undefined) {
      api.v2.telefonie
        .ophalenTelefoonoproepIndexen({
          telOprIDs: [props.vigerend],
          filterSchema,
          customFilters: {
            isBeeindigd: true,
          },
        })
        .then((result) => {
          const idx = result.indexen[props.vigerend!];
          setScrollNaarIndex(createReadyRemoteData(idx));
        });
      return;
    }
  }, [props.vigerend]);
  const isAanHetLaden = useMemo(
    () => gebruikersRijbron === null || scrollNaarIndex.state === ERemoteDataState.Pending,
    [gebruikersRijbron, scrollNaarIndex],
  );

  return (
    <div className="d-flex flex-fill flex-column">
      {isAanHetLaden ? (
        <div className="d-flex flex-fill justify-content-center align-items-center">
          <LoadingSpinner />
        </div>
      ) : (
        <div className="d-flex flex-fill flex-column">
          <div className="d-flex align-items-center p-2 pl-3 pr-3 w-100">
            <div className="d-flex align-items-center">
              <RadioKnop
                aangevinkt={gebruikerStore.gebruiker!.AspGebrID === props.vanGebruiker}
                onAangevinkt={() => props.onVanGebruikerChange(gebruikerStore.gebruiker!.AspGebrID)}
              />
              <span className="ml-2">Van mij</span>
            </div>

            <div className="ml-3">
              <VerticaleScheidingslijn height={25} />
            </div>

            <div className="d-flex align-items-center ml-3">
              <RadioKnop
                aangevinkt={gebruikerStore.gebruiker!.AspGebrID !== props.vanGebruiker}
                onAangevinkt={() => {
                  const gebrID =
                    props.laatsteVanGebruikerSelectie ?? gebruikersRijbron![0].AspGebrID;
                  props.onVanGebruikerChange(gebrID);
                }}
              />
              <span className="ml-2">Anders</span>

              <span className="ml-3" style={{ width: 100 }}>
                {comboboxOpties === null ? (
                  <LoadingSpinner />
                ) : (
                  <Combobox<'IEDEREEN' | number>
                    geselecteerd={
                      props.vanGebruiker === gebruikerStore.gebruiker!.AspGebrID
                        ? props.laatsteVanGebruikerSelectie ?? gebruikersRijbron![0].AspGebrID
                        : props.vanGebruiker
                    }
                    opties={comboboxOpties}
                    onSelectieChange={(id) => props.onVanGebruikerChange(id)}
                    legeOptieTonen={false}
                  />
                )}
              </span>
            </div>

            <div className="flex-fill" />
            <div className="d-flex align-items-center">
              <span>Richting</span>

              <div className="ml-2">
                <Combobox
                  geselecteerd={props.richting}
                  onSelectieChange={(x) => props.onRichtingChange(x!)}
                  opties={[
                    {
                      id: ERichting.Beide,
                      label: 'Beide',
                    },
                    {
                      id: ERichting.Inkomend,
                      label: 'Inkomend',
                    },
                    {
                      id: ERichting.Uitgaand,
                      label: 'Uitgaand',
                    },
                  ]}
                />
              </div>
            </div>
          </div>

          <HorizontaleScheidingslijn color={Kleur.LichtGrijs} />

          <div className="flex-fill d-flex flex-column">
            {props.telefoonHistorieStore.oproepenData.state === ERemoteDataState.Pending ? (
              <div className="d-flex flex-fill justify-content-center align-items-center">
                <LoadingSpinner />
              </div>
            ) : (
              <ASPTabel
                rijen={props.telefoonHistorieStore.oproepenData.data!.oproepen}
                kolommen={kolommen}
                totaalAantalRijen={props.telefoonHistorieStore.oproepenData.data!.totaalAantal}
                keyExtractor={keyExtractor}
                onExtraRijenAangevraagd={handleExtraRijenAangevraagd}
                // sortering={sortering}
                // onSorteringChange={setSortering}
                onRijenGerendered={setRijenGerenderedIndex}
                sorteringOpties={{
                  modus: ESorteringModus.Whitelist,
                  kolomOpties: {
                    [EKolom.Datum]: {
                      magSorteren: true,
                    },
                  },
                }}
                tdComponent={TdComponent}
                vigerend={vigerend}
                vigerendTdComponent={VigerendTdComponent}
                scrollNaarIndex={scrollNaarIndex.data === null ? undefined : scrollNaarIndex.data!}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
});

export default TelefoonHistorie;
