import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { IBulkBerichtTijdvakVisualisatieData } from '../../index';
import {
  Calendar,
  EventProps,
  momentLocalizer,
  Event,
  EventPropGetter,
  Formats,
} from 'react-big-calendar';
import moment from 'moment/moment';
import * as _ from 'lodash';
import {
  IBulkVerzendBerichtTijdvak,
  IBulkVerzendBerichtTijdvakDag,
} from '../../../../../../../shared/src/api/v2/bulkVerzending';
import { Kleur } from '../../../../../bedrijfslogica/constanten';
import { from } from 'rxjs';

interface ITijdvakEvent extends Event {
  tijdvak: IBulkVerzendBerichtTijdvak;
  dag: IBulkVerzendBerichtTijdvakDag;
}

const Root = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 800px;
  height: 600px;
`;

interface IProps {
  data: IBulkBerichtTijdvakVisualisatieData;
}

const Data = (props: IProps) => {
  const [view, setView] = useState<'week' | 'day'>('week');
  const localizer = useMemo(() => momentLocalizer(moment), []);
  const [fromDate, setFromDate] = useState(new Date());

  // const dateRange = useMemo(() => {
  //   if (view === 'day') {
  //     const vandaagZonderTijd = new Date(fromDate);
  //     vandaagZonderTijd.setMinutes(0);
  //     vandaagZonderTijd.setHours(0);
  //     vandaagZonderTijd.setSeconds(0);
  //
  //     const begin = vandaagZonderTijd;
  //     const eind = new Date(vandaagZonderTijd);
  //     eind.setHours(24);
  //     return {
  //       begin,
  //       eind,
  //     };
  //   } else if (view === 'week') {
  //     const begin = moment(fromDate)
  //       .set('hour', 0)
  //       .set('minute', 0)
  //       .set('second', 0)
  //       .set('weekday', 0)
  //       .add(-1, 'week');
  //     const eind = moment(fromDate)
  //       .set('hour', 24)
  //       .set('minute', 60)
  //       .set('second', 59)
  //       .set('weekday', 7);
  //     return {
  //       begin: begin.toDate(),
  //       eind: eind.toDate(),
  //     };
  //   }
  //
  //   return {
  //     begin: fromDate,
  //     eind: fromDate,
  //   };
  // }, [fromDate, view]);

  const events = useMemo<ITijdvakEvent[]>(() => {
    return props.data.dagen.map((dag) => {
      const weekdagNummer = dag.Dag === 7 ? 0 : dag.Dag;
      const weekdag = moment(fromDate).weekday(weekdagNummer);

      let startMoment = weekdag.clone();
      let eindMoment = weekdag.clone();
      if (dag.Vem === null) {
        startMoment = startMoment
          .set('hour', 0)
          .set('minute', 0)
          .set('second', 0);
      } else {
        const vem = moment(dag.Vem);
        startMoment = startMoment
          .set('hour', vem.hour())
          .set('minute', vem.minute())
          .set('second', 0);
      }

      if (dag.Tot === null) {
        eindMoment = eindMoment
          .set('hour', 23)
          .set('minute', 59)
          .set('second', 0);
      } else {
        const tot = moment(dag.Tot);
        eindMoment = eindMoment
          .set('hour', tot.hour())
          .set('minute', tot.minute())
          .set('second', 0);
      }

      return {
        start: startMoment.toDate(),
        end: eindMoment.toDate(),
        tijdvak: props.data.tijdvak,
        dag,
      };
    });
  }, [props.data, fromDate]);

  const eventPropGetter = useCallback<EventPropGetter<ITijdvakEvent>>(
    (event, start, end, isSelected) => {
      return {
        style: {
          backgroundColor: Kleur.LichtBlauw,
          borderRadius: 2,
          border: `1px solid ${Kleur.DonkerBlauw}`,
          opacity: 0.8,
          color: 'black',
          display: 'block',
        },
      };
    },
    [],
  );

  const formats = useMemo<Formats>(() => {
    return {
      timeGutterFormat: 'HH:mm',
    };
  }, []);

  return (
    <Root>
      <Calendar
        localizer={localizer}
        formats={formats}
        events={events}
        step={60}
        showMultiDayTimes
        views={['week', 'day']}
        view={view}
        onView={(x) => setView(x as typeof view)}
        onRangeChange={(range) => {
          // In onze situatie (week of dag) is range een array, dit kunnen we gebruiken
          // om de events de genereren
          const rangeArray = range as Date[];
          setFromDate(rangeArray[0]);
        }}
        // startAccessor="start"
        // endAccessor="end"
        components={{
          event: EventComp,
        }}
        eventPropGetter={eventPropGetter}
      />
    </Root>
  );
};

interface IEventProps extends EventProps<ITijdvakEvent> {}

const EventComp = (props: IEventProps) => {
  const { event } = props;
  const vem = useMemo(
    () => (event.dag.Vem === null ? null : moment(event.dag.Vem).format('HH:mm')),
    [event.dag.Vem],
  );
  const tot = useMemo(
    () => (event.dag.Tot === null ? null : moment(event.dag.Tot).format('HH:mm')),
    [event.dag.Tot],
  );
  const title = useMemo(() => {
    if (vem === null && tot === null) {
      return 'Hele dag';
    }

    return `${vem ?? '00:00'} - ${tot ?? '24:00'}`;
  }, [vem, tot]);

  return (
    <div
      className="d-flex flex-fill align-items-center justify-content-center"
      style={{ width: '100%', height: '100%' }}
      title={title}
    >
      <span>
        {vem === null && tot === null ? 'Hele dag' : `${vem ?? '00:00'} - ${tot ?? '24:00'}`}
      </span>
    </div>
  );
};

export default Data;
