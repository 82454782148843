import React, { useCallback, useMemo, useState } from 'react';
import { ActionArea, FiltersArea, Root } from './style';
import { IconFilter, IconToevoegen } from '../Icons';
import { Kleur } from '../../bedrijfslogica/constanten';
import FilterItem from './FilterItem';
import {
  EBooleanVergelijking,
  EDataType,
  EDateVergelijking,
  ENumberVergelijking,
  EStringVergelijking,
  FilterFn,
  ICustomFilter,
  IFilter,
  KeyExtractor,
  Omschrijving,
} from './types';
import FilterToevoegenDialoog from './FilterToevoegenDialoog';
import classNames from 'classnames';
import VerticaleScheidingslijn from '../layout/VerticaleScheidingslijn';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import CustomFiltersWeergave from './CustomFiltersWeergave';
import CustomFilterItem from './CustomFilterItem';
import * as _ from 'lodash';
import nameof from '../../core/nameOf';

const executeStringVergelijking = (
  vergelijking: EStringVergelijking,
  entiteitWaarde: string,
  filterWaarde: string,
): boolean => {
  switch (vergelijking) {
    case EStringVergelijking.Gelijk:
      return entiteitWaarde === filterWaarde;
    case EStringVergelijking.ZitIn:
      return entiteitWaarde.includes(filterWaarde);
    case EStringVergelijking.BegintMet:
      return entiteitWaarde.startsWith(filterWaarde);
    case EStringVergelijking.EindigtMet:
      return entiteitWaarde.endsWith(filterWaarde);
  }
  throw new Error('Niet volledig geimplementeerd');
};

const executeNumberVergelijking = (
  vergelijking: ENumberVergelijking,
  entiteitWaarde: number,
  filterWaarde: number,
): boolean => {
  switch (vergelijking) {
    case ENumberVergelijking.Gelijk:
      return entiteitWaarde === filterWaarde;
    case ENumberVergelijking.GroterDan:
      return entiteitWaarde > filterWaarde;
    case ENumberVergelijking.KleinerDan:
      return entiteitWaarde < filterWaarde;
  }
  throw new Error('Niet volledig geimplementeerd');
};

const executeDateVergelijking = (
  vergelijking: EDateVergelijking,
  entiteitWaarde: Date,
  filterWaarde: Date,
): boolean => {
  switch (vergelijking) {
    case EDateVergelijking.Gelijk:
      return entiteitWaarde === filterWaarde;
    case EDateVergelijking.Voor:
      return entiteitWaarde < filterWaarde;
    case EDateVergelijking.Na:
      return entiteitWaarde > filterWaarde;
  }
  throw new Error('Niet volledig geimplementeerd');
};

const executeBooleanVergelijking = (
  vergelijking: EBooleanVergelijking,
  entiteitWaarde: number,
  filterWaarde: number,
): boolean => {
  switch (vergelijking) {
    case EBooleanVergelijking.Gelijk:
      return entiteitWaarde === filterWaarde;
  }
  throw new Error('Niet volledig geimplementeerd');
};

const executeFilter = async <TId, TEntiteit extends {}>(
  keyExtractor: KeyExtractor<TId, TEntiteit>,
  filter: IFilter<TId, TEntiteit>,
  entiteiten: TEntiteit[],
): Promise<TId[]> => {
  return entiteiten
    .filter((ent) => {
      const entiteitWaarde: any = ent[filter.veld];

      switch (filter.vergelijking.type) {
        case EDataType.String:
          return executeStringVergelijking(
            filter.vergelijking.vergelijking,
            entiteitWaarde,
            filter.waarde,
          );
        case EDataType.Number:
          return executeNumberVergelijking(
            filter.vergelijking.vergelijking,
            entiteitWaarde,
            filter.waarde,
          );
        case EDataType.Date:
          return executeDateVergelijking(
            filter.vergelijking.vergelijking,
            entiteitWaarde,
            filter.waarde,
          );
        case EDataType.Boolean:
          return executeBooleanVergelijking(
            filter.vergelijking.vergelijking,
            entiteitWaarde,
            filter.waarde,
          );
      }
      throw new Error('Niet volledig geimplementeerd');
    })
    .map(keyExtractor);
};

export const genereerFilterFunctie = <TId, TEntiteit extends {}, TCustomFilterId = number>(
  keyExtractor: KeyExtractor<TId, TEntiteit>,
  filters: IFilter<TId, TEntiteit>[],
  mogelijkeCustomFilters: ICustomFilter<TCustomFilterId, TId, TEntiteit>[] = [],
  customFilters: TCustomFilterId[] = [],
): FilterFn<TId, TEntiteit> => {
  return async (entiteiten) => {
    // Ids die voldoen aan een van de opgegeven filters
    let entities = entiteiten;
    // const filterResults: Array<Array<TId>> = [];
    for (const filterId of customFilters) {
      const filter = mogelijkeCustomFilters.find((x) => x.id === filterId)!;
      const ids = await filter.filter(entities);
      entities = entities.filter((ent) => ids.includes(keyExtractor(ent)));
    }
    for (const filter of filters) {
      const ids = await executeFilter(keyExtractor, filter, entities);
      entities = entities.filter((ent) => ids.includes(keyExtractor(ent)));
    }
    return entities.map(keyExtractor);
  };
};

interface IProps<TId, TEntiteit, TCustomFilterId> {
  omschrijving: Omschrijving<TEntiteit>;
  keyExtractor: KeyExtractor<TId, TEntiteit>;
  filters: IFilter<TId, TEntiteit>[];
  onFiltersChange: (filters: IFilter<TId, TEntiteit>[]) => void;
  mogelijkeCustomFilters?: ICustomFilter<TCustomFilterId, TId, TEntiteit>[];
  customFilters?: TCustomFilterId[];
  onCustomFiltersChange?: (filters: TCustomFilterId[]) => void;
  opvoerenNietWeergeven?: boolean;
}

const FilterBalk = <TId, TEntiteit extends {}, TCustomFilterId = number>(
  props: IProps<TId, TEntiteit, TCustomFilterId>,
) => {
  const {
    filters,
    mogelijkeCustomFilters,
    onCustomFiltersChange,
    omschrijving,
    onFiltersChange,
    customFilters,
  } = props;
  const [filterToevoegenTonen, setFilterToevoegenTonen] = useState(false);

  const handleFilterToevoegenClick = useCallback(() => setFilterToevoegenTonen(true), [
    setFilterToevoegenTonen,
  ]);

  const weergegevenCustomFilters = useMemo<Array<TCustomFilterId> | null>(() => {
    if (mogelijkeCustomFilters === undefined || customFilters === undefined) {
      return null;
    }
    const altijdGetoondeFilters = mogelijkeCustomFilters
      .filter((filter) => filter.altijdWeergevenInBalk)
      .map((x) => x.id);
    return _.uniq([...altijdGetoondeFilters, ...customFilters]);
  }, [mogelijkeCustomFilters, customFilters]);

  return (
    <>
      <Root>
        <FiltersArea>
          {weergegevenCustomFilters !== null &&
            weergegevenCustomFilters.map((filterId, i) => {
              const filter = mogelijkeCustomFilters!.find((x) => x.id === filterId)!;

              return (
                <div key={i} className={i === 0 ? '' : 'ml-1'}>
                  <CustomFilterItem<TCustomFilterId, TId, TEntiteit>
                    filter={filter}
                    isActief={
                      filter.altijdWeergevenInBalk ? customFilters!.includes(filterId) : undefined
                    }
                    onActiefChange={
                      filter.altijdWeergevenInBalk
                        ? (actief) => {
                            if (onCustomFiltersChange === undefined) {
                              return;
                            }
                            if (!actief) {
                              onCustomFiltersChange(customFilters!.filter((x) => x !== filterId));
                            } else {
                              onCustomFiltersChange([...customFilters!, filterId]);
                            }
                          }
                        : undefined
                    }
                    onVerwijderenClick={
                      !filter.altijdWeergevenInBalk
                        ? () => {
                            if (onCustomFiltersChange !== undefined) {
                              onCustomFiltersChange(customFilters!.filter((x) => x !== filterId));
                            }
                          }
                        : undefined
                    }
                  />
                </div>
              );
            })}
          {filters.map((filter, i) => {
            return (
              <div key={i} className={i === 0 ? '' : 'ml-1'}>
                <FilterItem<TId, TEntiteit>
                  omschrijving={omschrijving}
                  filter={filter}
                  onVerwijderenClick={() => {
                    const newFilters = [
                      ...filters.slice(0, i),
                      ...filters.slice(i + 1, filters.length),
                    ];
                    onFiltersChange(newFilters);
                  }}
                />
              </div>
            );
          })}
        </FiltersArea>
        <div>
          <VerticaleScheidingslijn />
        </div>
        <div className="ml-2 pt-2 pb-2">
          <ActionArea>
            <OverlayTrigger
              overlay={
                <Popover id="filterbalkCustomFilters">
                  {mogelijkeCustomFilters !== undefined && (
                    <CustomFiltersWeergave
                      customFilters={mogelijkeCustomFilters.filter((mf) => {
                        // Bij altijd weergeven niet als optie laten zien
                        if (mf.altijdWeergevenInBalk) {
                          return false;
                        }
                        if (customFilters === undefined || customFilters.length === 0) {
                          return true;
                        }
                        // Laat alleen filters zijn die nog niet actief zijn
                        return customFilters.findIndex((cf) => cf === mf.id) === -1;
                      })}
                      onCustomFilterGekozen={(filter) => {
                        if (onCustomFiltersChange === undefined) {
                          return;
                        }
                        // Close popover
                        document.body.click();
                        onCustomFiltersChange([...(customFilters || []), filter.id]);
                      }}
                    />
                  )}
                </Popover>
              }
              trigger="click"
              rootClose
              placement="bottom"
            >
              <button
                className={classNames(['btn btn-sm btn-light d-flex align-items-center mr-2'])}
                // onClick={handleFilterToevoegenClick}
                disabled={
                  mogelijkeCustomFilters === undefined || mogelijkeCustomFilters.length === 0
                }
                style={{ paddingTop: 0, paddingBottom: 0 }}
              >
                <IconFilter
                  style={{
                    width: 18,
                    height: 18,
                    fill: Kleur.Grijs,
                    marginTop: 1,
                    margin: '4px 3px',
                  }}
                />
                <span className="ml-2">Snel</span>
              </button>
            </OverlayTrigger>
            {!props.opvoerenNietWeergeven && (
              <button
                className={classNames(['btn btn-sm btn-light d-flex align-items-center mr-2'])}
                onClick={handleFilterToevoegenClick}
              >
                <IconToevoegen style={{ width: 18, height: 18, fill: Kleur.Grijs, marginTop: 1 }} />
                <span className="ml-1">Opvoeren</span>
              </button>
            )}
          </ActionArea>
        </div>
      </Root>
      {filterToevoegenTonen && (
        <FilterToevoegenDialoog<TId, TEntiteit>
          omschrijving={omschrijving}
          open
          onSuccess={(result) => {
            onFiltersChange([...filters, result.filter]);
            setFilterToevoegenTonen(false);
          }}
          onAnnuleren={() => setFilterToevoegenTonen(false)}
        />
      )}
    </>
  );
};

export default FilterBalk;
