import React, { useContext, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import { IOphalenRelatiesResultElementV2 } from '../../../../../../shared/src/api/v2/relatie';
import { RootStoreContext } from '../../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import Placeholder from './Placeholder';
import Data from './Data';

interface IProps {
  relID: number;
  onSluiten: () => void;
}

const Root = styled.div`
  background: ${Kleur.Wit};
  border: 1px solid ${Kleur.LichtGrijs};
  border-radius: 4px;
  padding: 12px 16px;
  flex: 1;
  width: 500px;
`;

const InfoCard = observer((props: IProps) => {
  const { relatieStore } = useContext(RootStoreContext);
  const relatie = useMemo<IOphalenRelatiesResultElementV2 | null>(() => {
    const rel = relatieStore.relaties[props.relID];
    if (rel === undefined) {
      return null;
    }
    return rel;
  }, [relatieStore.relaties, props.relID]);
  useEffect(() => {
    if (relatie !== null) {
      return;
    }
    relatieStore.enqueueRelatieOphalen(props.relID);
  }, [relatie, props.relID]);

  return (
    <Root onClick={(ev) => ev.stopPropagation()}>
      {relatie === null ? <Placeholder /> : <Data relatie={relatie} onSluiten={props.onSluiten} />}
    </Root>
  );
});

export default InfoCard;
