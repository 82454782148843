import { makeObservable, observable } from 'mobx';
import IRemoteData, { createPendingRemoteData, createReadyRemoteData } from '../models/IRemoteData';
import { IOphalenFacturenBasisResultElement } from '../../../shared/src/api/v2/factuur';
import * as _ from 'lodash';
import api from '../api';

class FactuurStore {
  public facturen: IRemoteData<
    Record<number, IOphalenFacturenBasisResultElement>
  > = createPendingRemoteData();
  private ophalenFacturenQueue: number[] = [];
  private ophalenFacturenQueueDebounceHandle: number | null = 0;

  constructor() {
    makeObservable(this, {
      facturen: observable,
    });
  }

  public enqueueOphalenFacturen = (factIDs: number[]) => {
    if (this.ophalenFacturenQueueDebounceHandle !== null) {
      clearTimeout(this.ophalenFacturenQueueDebounceHandle);
    }
    this.ophalenFacturenQueue = _.uniq([...this.ophalenFacturenQueue, ...factIDs]);
    this.ophalenFacturenQueueDebounceHandle = setTimeout(this.ophalenQueuedFacturen, 100) as any;
  };
  private ophalenQueuedFacturen = async () => {
    this.ophalenFacturenQueueDebounceHandle = null;
    const factIDs = this.ophalenFacturenQueue;

    if (factIDs.length === 0) {
      return;
    }

    this.ophalenFacturenQueue = [];

    const result = await api.v2.factuur.ophalenFacturenBasis({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: factIDs,
          },
        ],
      },
    });
    this.facturen = createReadyRemoteData(
      result.facturen.reduce((acc, curr) => {
        return {
          ...acc,
          [curr.FactID]: curr,
        };
      }, this.facturen.data ?? {}),
    );
  };
}

export default FactuurStore;
