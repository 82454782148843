import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { IOphalenNotificatiesResultElement } from '../../../../../shared/src/api/v2/notificaties/notificaties';
import { Container, Root } from './style';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../stores/RootStore';
import { addHours, formatDistance, format } from 'date-fns';
import locale from 'date-fns/locale/nl';
import { RouteComponentProps, withRouter } from 'react-router';
import {
  IconBellen,
  IconCheck,
  IconControl,
  IconEmail,
  IconApi,
  functioneleIconMap,
  EFunctioneleIcon,
} from '../../../components/Icons';
import api from '../../../api';
import { Kleur } from '../../../bedrijfslogica/constanten';
import { bepaalDefaultUrlState, IUrlState } from '../../../paginas/Taken';
import { genereerUrlStateQueryParam } from '../../../core/useUrlState';

const IconCommunicatie = functioneleIconMap[EFunctioneleIcon.Communicatie];

interface IProps extends RouteComponentProps {
  notificatie: IOphalenNotificatiesResultElement;
  signaleringModus?: boolean;
}

const groepIconCompMap: Record<string, React.ComponentType> = {
  SYSTEEM: () => <IconApi style={{ width: 18, height: 18, fill: Kleur.Grijs }} />,
  TELEFOON: () => <IconBellen style={{ width: 18, height: 18, fill: Kleur.Grijs }} />,
  EMAIL: () => <IconEmail style={{ width: 18, height: 18, fill: Kleur.Grijs }} />,
  COMMUNICATIE: () => <IconCommunicatie style={{ width: 18, height: 18, fill: Kleur.Grijs }} />,
};

const NotificatieItem: React.FC<IProps> = observer((props) => {
  const { notificatieStore, gebruikerStore } = useContext(RootStoreContext);
  const [now, setNow] = useState(new Date());
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    const id = setInterval(() => {
      setNow(new Date());
    }, 5000);

    return () => clearInterval(id);
  }, []);

  const notificatieBevestigd = useMemo(() => {
    const entry = props.notificatie.aspGebrs.find(
      (entry) => entry.AspGebrID === gebruikerStore.gebruiker!.AspGebrID,
    )!;
    return entry.DatumBevestigd !== null;
  }, [props.notificatie.aspGebrs, gebruikerStore.gebruiker]);

  const notificatieGroep = useMemo(() => {
    if (notificatieStore.notificatieGroepen === null) {
      return null;
    }

    return notificatieStore.notificatieGroepen.find(
      (x) => x.ID === props.notificatie.NotificatieGroepID,
    );
  }, [props.notificatie.NotificatieGroepID, notificatieStore.notificatieGroepen]);

  const handleBevestigenClick = useCallback(
    async (ev: React.MouseEvent<any>) => {
      await api.v2.notificatie.bevestigenNotificatie({
        notificatieID: props.notificatie.ID,
        aspGebrID: gebruikerStore.gebruiker!.AspGebrID,
      });
    },
    [props.notificatie.ID, gebruikerStore.gebruiker],
  );

  const datum = useMemo(() => new Date(props.notificatie.Datum), [props.notificatie.Datum]);
  const IconComp = useMemo(() => groepIconCompMap[props.notificatie.NotificatieGroepNaamEnum], [
    props.notificatie.NotificatieGroepNaamEnum,
  ]);

  if (notificatieGroep === null) {
    return null;
  }

  return (
    <Root
      bevestigd={notificatieBevestigd}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <div className="group-container">
        <IconComp />
      </div>
      <Container>
        <div className="flex-fill d-flex flex-column ml-4">
          <div className="title-container">
            <div className="title">{props.notificatie.Titel}</div>
          </div>
          {props.notificatie.Inhoud && <div className="body">{props.notificatie.Inhoud}</div>}
          <div className="datum">
            {/* Laat tijd relatief zien, en als het langer dan een uur terug is dan gewoon datum + tijd */}
            {addHours(datum, 1) > new Date()
              ? formatDistance(datum, now, {
                  addSuffix: true,
                  locale,
                })
              : format(datum, 'dd-MM-yyyy HH:mm')}
          </div>
        </div>
        {!props.signaleringModus && (
          <div>
            <div className="actie-container d-flex flex-column">
              <button
                onClick={handleBevestigenClick}
                disabled={notificatieBevestigd}
                className="btn btn-sm btn-light d-flex align-items-center"
                style={{
                  border: `1px solid ${notificatieBevestigd ? Kleur.Groen : Kleur.LichtGrijs}`,
                  backgroundColor: notificatieBevestigd ? Kleur.LichtGroen : undefined,
                }}
                title="Bevestig gelezen"
              >
                <IconCheck
                  style={{
                    width: 16,
                    height: 16,
                    fill: notificatieBevestigd ? Kleur.Zwart : 'gray',
                  }}
                />
              </button>
              {props.notificatie.controlTaakIDs.length > 0 && (
                <button
                  className="btn btn-sm btn-light d-flex align-items-center mt-2"
                  style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                  title="Naar control taak"
                  onClick={async () => {
                    const defaultUrlState = await bepaalDefaultUrlState(
                      gebruikerStore.gebruiker!.AspGebrID,
                    );
                    const state = genereerUrlStateQueryParam<IUrlState>({
                      ...defaultUrlState,
                      uitgeklapt: props.notificatie.controlTaakIDs,
                    });
                    props.history.push(`/taken?state=${state}`);
                  }}
                >
                  <IconControl style={{ width: 16, height: 16, fill: 'gray' }} />
                </button>
              )}
              <div className="flex-fill" />
              {props.notificatie.Actienaam !== null && (
                <button
                  className="btn btn-sm btn-primary pl-4 pr-4 mt-2"
                  onClick={() => {
                    notificatieStore.setOverlayZichtbaar(false);
                    props.history.push(props.notificatie.ActieURL!);
                  }}
                >
                  {props.notificatie.Actienaam}
                </button>
              )}
            </div>
          </div>
        )}
      </Container>
    </Root>
  );
});

export default withRouter(NotificatieItem);
