import {
  IOphalenReviewsParams,
  IOphalenReviewsResult,
  IOphalenUitnodigingenParams,
  IOphalenUitnodigingenResult,
  IOphalenVoorstellenParams,
  IOphalenVoorstellenResult,
  IVersturenUitnodigingenParams,
  IVersturenUitnodigingenResult,
  IVerwijderenUitnodigingenParams,
  IVerwijderenUitnodigingenResult,
  IMakenExportbestandParams,
  IMakenExportbestandResult,
  IToggleFiatStatusParams,
  IToggleFiatStatusResult,
  IToevoegenUitnodigingenParams,
  IToevoegenUitnodigingenResult,
  IBelonenBeoordelingenParams,
  IInlezenBeoordelingenParams,
  IInlezenBeoordelingenResult,
  IVerwijderenBeoordelingenParams,
  IVerwijderenBeoordelingenResult,
  IWijzigenBeoordelingResult,
  IWijzigenBeoordelingParams,
  IMakenVoorstelUitnodigingenParams,
  IMakenVoorstelUitnodigingenResult,
  IOphalenVoorstelUitnodigingenParams,
  IOphalenVoorstelUitnodigingenResult,
  IVerwijderenVoorstelUitnodigingenParams,
  IVerwijderenVoorstelUitnodigingenResult,
  IToevoegenVoorstelUitnodigingenParams,
  IToevoegenVoorstelUitnodigingenResult,
  IAfwijzenVoorstelUitnodigingenParams,
  IAfwijzenVoorstelUitnodigingenResult,
  IGoedkeurenVoorstelUitnodigingenParams,
  IGoedkeurenVoorstelUitnodigingenResult,
  IOphalenUitnodigingAanleidingenParams,
  IOphalenUitnodigingAanleidingenResult,
  IToggleNietOpWebsiteParams,
  IToggleNietOpWebsiteResult,
} from '../../../../../shared/src/api/v2/review/klant';
import ICheckData from '../../../../../shared/src/models/ICheckData';
import api from '../../index';

const review = {
  ophalenReviews: async (params: IOphalenReviewsParams): Promise<IOphalenReviewsResult> => {
    return await api.post('/v2/review/klant/ophalen-reviews', params);
  },
  ophalenUitnodigingen: async (
    params: IOphalenUitnodigingenParams,
  ): Promise<IOphalenUitnodigingenResult> => {
    return await api.post('/v2/review/klant/ophalen-uitnodigingen', params);
  },
  // ophalenVoorstellen: async (
  //   params: IOphalenVoorstellenParams,
  // ): Promise<IOphalenVoorstellenResult> => {
  //   return await api.post('/v2/review/klant/ophalen-voorstellen', params);
  // },
  checkVersturenUitnodigingen: async (
    params: IVersturenUitnodigingenParams,
  ): Promise<ICheckData> => {
    return await api.post('/v2/review/klant/check-versturen-uitnodigingen', params);
  },
  versturenUitnodigingen: async (
    params: IVersturenUitnodigingenParams,
  ): Promise<IVersturenUitnodigingenResult> => {
    return await api.post('/v2/review/klant/versturen-uitnodigingen', params);
  },
  checkVerwijderenUitnodigingen: async (
    params: IVerwijderenUitnodigingenParams,
  ): Promise<ICheckData> => {
    return await api.post('/v2/review/klant/check-verwijderen-uitnodigingen', params);
  },
  verwijderenUitnodigingen: async (
    params: IVerwijderenUitnodigingenParams,
  ): Promise<IVerwijderenUitnodigingenResult> => {
    return await api.post('/v2/review/klant/verwijderen-uitnodigingen', params);
  },
  makenExportbestand: async (
    params: IMakenExportbestandParams,
  ): Promise<IMakenExportbestandResult> => {
    return await api.post('/v2/review/klant/maken-exportbestand', params);
  },
  toggleFiatStatus: async (params: IToggleFiatStatusParams): Promise<IToggleFiatStatusResult> => {
    return await api.post('/v2/review/klant/toggle-fiatstatus', params);
  },
  checkToevoegenUitnodigingen: async (
    params: IToevoegenUitnodigingenParams,
  ): Promise<ICheckData> => {
    return await api.post('/v2/review/klant/check-toevoegen-uitnodigingen', params);
  },
  toevoegenUitnodigingen: async (
    params: IToevoegenUitnodigingenParams,
  ): Promise<IToevoegenUitnodigingenResult> => {
    return await api.post('/v2/review/klant/toevoegen-uitnodigingen', params);
  },
  toevoegenVoorstelUitnodigingen: async (
    params: IToevoegenVoorstelUitnodigingenParams,
  ): Promise<IToevoegenVoorstelUitnodigingenResult> => {
    return await api.post('/v2/review/klant/toevoegen-voorstel-uitnodigingen', params);
  },
  inlezenBeoordelingen: async (
    params: IInlezenBeoordelingenParams,
  ): Promise<IInlezenBeoordelingenResult> => {
    return await api.post('/v2/review/klant/inlezen-beoordelingen', params);
  },
  checkBelonenBeoordelingen: async (params: IBelonenBeoordelingenParams): Promise<ICheckData> => {
    return await api.post('/v2/review/klant/check-belonen-beoordelingen', params);
  },
  belonenBeoordelingen: async (
    params: IBelonenBeoordelingenParams,
  ): Promise<IBelonenBeoordelingenParams> => {
    return await api.post('/v2/review/klant/belonen-beoordelingen', params);
  },
  checkVerwijderenBeoordelingen: async (
    params: IVerwijderenBeoordelingenParams,
  ): Promise<ICheckData> => {
    return await api.post('/v2/review/klant/check-verwijderen-beoordelingen', params);
  },
  verwijderenBeoordelingen: async (
    params: IVerwijderenBeoordelingenParams,
  ): Promise<IVerwijderenBeoordelingenResult> => {
    return await api.post('/v2/review/klant/verwijderen-beoordelingen', params);
  },
  wijzigenBeoordeling: async (
    params: IWijzigenBeoordelingParams,
  ): Promise<IWijzigenBeoordelingResult> => {
    return await api.post('/v2/review/klant/wijzigen-beoordeling', params);
  },
  checkMakenVoorstelUitnodigingen: async (
    params: IMakenVoorstelUitnodigingenParams,
  ): Promise<ICheckData> => {
    return await api.post('/v2/review/klant/check-maken-voorstel-uitnodigingen', params);
  },
  makenVoorstelUitnodigingen: async (
    params: IMakenVoorstelUitnodigingenParams,
  ): Promise<IMakenVoorstelUitnodigingenResult> => {
    return await api.post('/v2/review/klant/maken-voorstel-uitnodigingen', params);
  },
  ophalenVoorstelUitnodigingen: async (
    params: IOphalenVoorstelUitnodigingenParams,
  ): Promise<IOphalenVoorstelUitnodigingenResult> => {
    return await api.post('/v2/review/klant/ophalen-voorstel-uitnodigingen', params);
  },
  verwijderenVoorstelUitnodigingen: async (
    params: IVerwijderenVoorstelUitnodigingenParams,
  ): Promise<IVerwijderenVoorstelUitnodigingenResult> => {
    return await api.post('/v2/review/klant/verwijderen-voorstel-uitnodigingen', params);
  },
  afwijzenVoorstelUitnodigingen: async (
    params: IAfwijzenVoorstelUitnodigingenParams,
  ): Promise<IAfwijzenVoorstelUitnodigingenResult> => {
    return await api.post('/v2/review/klant/afwijzen-voorstel-uitnodigingen', params);
  },
  goedkeurenVoorstelUitnodigingen: async (
    params: IGoedkeurenVoorstelUitnodigingenParams,
  ): Promise<IGoedkeurenVoorstelUitnodigingenResult> => {
    return await api.post('/v2/review/klant/goedkeuren-voorstel-uitnodigingen', params);
  },
  ophalenUitnodigingAanleidingen: async (
    params: IOphalenUitnodigingAanleidingenParams,
  ): Promise<IOphalenUitnodigingAanleidingenResult> => {
    return await api.post('/v2/review/klant/ophalen-uitnodiging-aanleidingen', params);
  },
  toggleNietOpWebsite: async (
    params: IToggleNietOpWebsiteParams,
  ): Promise<IToggleNietOpWebsiteResult> => {
    return await api.post('/v2/review/klant/toggle-niet-op-website', params);
  },
};

export default review;
