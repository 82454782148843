import React, { useMemo } from 'react';
import styled from 'styled-components';
import IRemoteData, { ERemoteDataState } from '../../../../../../models/IRemoteData';
import Skeleton from 'react-loading-skeleton';
import { format } from 'date-fns';

const Root = styled.div`
  display: flex;
`;

const GeenPreviewBeschikbaar = styled.span`
  flex: 1;
  font-size: 13px;
  color: #7d7d7d;
  font-style: italic;
`;

const PreviewTekst = styled.span`
  flex: 1;
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #7d7d7d;
  margin-top: 3px;
`;

const TijdTekst = styled.span`
  font-size: 11px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 16px;
`;

interface IProps {
  preview: IRemoteData<string | null>;
  datum: IRemoteData<Date>;
}

const Preview = (props: IProps) => {
  const tijd = useMemo(() => {
    if (props.datum.state === ERemoteDataState.Pending) {
      return <Skeleton width={60} style={{ marginLeft: 16 }} />;
    }
    const tijd = format(props.datum.data!, 'HH:mm');
    return <TijdTekst>{tijd}</TijdTekst>;
  }, [props.datum]);

  if (props.preview.state === ERemoteDataState.Pending) {
    return (
      <Root>
        <Skeleton width={360} />
      </Root>
    );
  }

  if (props.preview.data === null) {
    return (
      <Root>
        <GeenPreviewBeschikbaar>Geen preview beschikbaar</GeenPreviewBeschikbaar>
      </Root>
    );
  }

  return (
    <Root>
      <PreviewTekst>{props.preview.data}</PreviewTekst>
      {tijd}
    </Root>
  );
};

export default Preview;
