import React, { useMemo } from 'react';
import { ActieContainer, Root, Weergave } from './style';
import ActieMenuKnop from '../../ActieMenuKnop';
import VerticaleScheidingslijn from '../../layout/VerticaleScheidingslijn';
import {
  EBooleanVergelijking,
  EDataType,
  EDateVergelijking,
  ENumberVergelijking,
  EStringVergelijking,
  IFilter,
  IVeldOmschrijving,
  Omschrijving,
} from '../types';
import VinkVeld from '../../formulier/VinkVeld';
import { format } from 'date-fns';
import { IconVerwijderen, IconWijzigen } from '../../Icons';
import { Kleur } from '../../../bedrijfslogica/constanten';

interface IProps<TId, TEntiteit extends {}> {
  omschrijving: Omschrijving<TEntiteit>;
  filter: IFilter<TId, TEntiteit>;
  onVerwijderenClick: () => void;
}

interface IWeergaveProps<TId, TEntiteit extends {}> {
  veldOmschrijving: IVeldOmschrijving<TEntiteit>;
  filter: IFilter<TId, TEntiteit>;
}

const stringVergelijkingWeergaven: Record<
  EStringVergelijking,
  React.ComponentType<IWeergaveProps<any, any>>
> = {
  [EStringVergelijking.Gelijk]: ({ veldOmschrijving, filter }) => (
    <>
      <span>{veldOmschrijving.weergaveNaam}</span>
      <span className="ml-1 mr-1">gelijk aan</span>
      <span>
        <b>"{filter.waarde}"</b>
      </span>
    </>
  ),
  [EStringVergelijking.ZitIn]: ({ veldOmschrijving, filter }) => (
    <>
      <span>
        <b>"{filter.waarde}"</b>
      </span>
      <span className="ml-1 mr-1">zit in</span>
      <span>{veldOmschrijving.weergaveNaam}</span>
    </>
  ),
  [EStringVergelijking.BegintMet]: ({ veldOmschrijving, filter }) => (
    <>
      <span>{veldOmschrijving.weergaveNaam}</span>
      <span className="ml-1 mr-1">begint met</span>
      <span>
        <b>"{filter.waarde}"</b>
      </span>
    </>
  ),
  [EStringVergelijking.EindigtMet]: ({ veldOmschrijving, filter }) => (
    <>
      <span>{veldOmschrijving.weergaveNaam}</span>
      <span className="ml-1 mr-1">eindigt met</span>
      <span>
        <b>"{filter.waarde}"</b>
      </span>
    </>
  ),
};

const numberVergelijkingWeergaven: Record<
  ENumberVergelijking,
  React.ComponentType<IWeergaveProps<any, any>>
> = {
  [ENumberVergelijking.Gelijk]: ({ veldOmschrijving, filter }) => (
    <>
      <span>{veldOmschrijving.weergaveNaam}</span>
      <span className="ml-1 mr-1">gelijk aan</span>
      <span>
        <b>{filter.waarde}</b>
      </span>
    </>
  ),
  [ENumberVergelijking.GroterDan]: ({ veldOmschrijving, filter }) => (
    <>
      <span>{veldOmschrijving.weergaveNaam}</span>
      <span className="ml-1 mr-1">groter dan</span>
      <span>
        <b>{filter.waarde}</b>
      </span>
    </>
  ),
  [ENumberVergelijking.KleinerDan]: ({ veldOmschrijving, filter }) => (
    <>
      <span>{veldOmschrijving.weergaveNaam}</span>
      <span className="ml-1 mr-1">kleiner dan</span>
      <span>
        <b>{filter.waarde}</b>
      </span>
    </>
  ),
};

const dateVergelijkingWeergaven: Record<
  EDateVergelijking,
  React.ComponentType<IWeergaveProps<any, any>>
> = {
  [EDateVergelijking.Gelijk]: ({ veldOmschrijving, filter }) => (
    <>
      <span>{veldOmschrijving.weergaveNaam}</span>
      <span className="ml-1 mr-1">gelijk aan</span>
      <span>
        <b>{format(filter.waarde, 'dd-MM-yyyy HH:mm')}</b>
      </span>
    </>
  ),
  [EDateVergelijking.Voor]: ({ veldOmschrijving, filter }) => (
    <>
      <span>{veldOmschrijving.weergaveNaam}</span>
      <span className="ml-1 mr-1">voor</span>
      <span>
        <b>{format(filter.waarde, 'dd-MM-yyyy HH:mm')}</b>
      </span>
    </>
  ),
  [EDateVergelijking.Na]: ({ veldOmschrijving, filter }) => (
    <>
      <span>{veldOmschrijving.weergaveNaam}</span>
      <span className="ml-1 mr-1">na</span>
      <span>
        <b>{format(filter.waarde, 'dd-MM-yyyy HH:mm')}</b>
      </span>
    </>
  ),
};

const booleanVergelijkingWeergaven: Record<
  EBooleanVergelijking,
  React.ComponentType<IWeergaveProps<any, any>>
> = {
  [ENumberVergelijking.Gelijk]: ({ veldOmschrijving, filter }) => (
    <>
      <span>{veldOmschrijving.weergaveNaam}</span>
      <span className="ml-1 mr-1">gelijk aan</span>
      <VinkVeld aangevinkt={filter.waarde} />
    </>
  ),
};

const FilterItem = <TId, TEntiteit extends {}>(props: IProps<TId, TEntiteit>) => {
  const { filter, omschrijving, onVerwijderenClick } = props;
  const veldOmschrijving: IVeldOmschrijving<TEntiteit> = useMemo(() => {
    return omschrijving[filter.veld]!;
  }, [filter, omschrijving]);

  const VergelijkingsWeergave: React.ComponentType<IWeergaveProps<TId, TEntiteit>> = useMemo(() => {
    switch (veldOmschrijving.dataType) {
      case EDataType.String:
        return stringVergelijkingWeergaven[filter.vergelijking.vergelijking];
      case EDataType.Number:
        return numberVergelijkingWeergaven[filter.vergelijking.vergelijking as ENumberVergelijking];
      case EDataType.Date:
        return dateVergelijkingWeergaven[filter.vergelijking.vergelijking as EDateVergelijking];
      case EDataType.Boolean:
        return booleanVergelijkingWeergaven[
          filter.vergelijking.vergelijking as EBooleanVergelijking
        ];
    }
    throw new Error('Niet volledig geimplementeerd');
  }, [filter.vergelijking.vergelijking, veldOmschrijving]);

  return (
    <Root>
      <Weergave>
        <VergelijkingsWeergave veldOmschrijving={veldOmschrijving} filter={filter} />
      </Weergave>
      <VerticaleScheidingslijn height={27} />
      <ActieContainer>
        <a href="#" onClick={() => alert('TODO')}>
          <IconWijzigen style={{ fill: Kleur.Grijs, width: 16, height: 16 }} />
        </a>
        <a href="#" onClick={onVerwijderenClick}>
          <IconVerwijderen style={{ fill: Kleur.Grijs, width: 16, height: 16 }} />
        </a>
        {/*<ActieMenuKnop*/}
        {/*  acties={[*/}
        {/*    {*/}
        {/*      text: 'Aanpassen',*/}
        {/*      onClick: () => null,*/}
        {/*    },*/}
        {/*    {*/}
        {/*      text: 'Verwijderen',*/}
        {/*      onClick: onVerwijderenClick,*/}
        {/*    },*/}
        {/*  ]}*/}
        {/*/>*/}
      </ActieContainer>
    </Root>
  );
};

export default FilterItem;
