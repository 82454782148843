import {
  IOphalenOverigeTarievenParams,
  IOphalenOverigeTarievenResult,
  IToevoegenTariefParams,
  IToevoegenTariefResult,
  IVerwijderenTarievenParams,
  IVerwijderenTarievenResult,
  IWijzigenTariefParams,
  IWijzigenTariefResult,
} from '../../../../../../../shared/src/api/v2/aanbod/tarieven/overige';
import ICheckData from '../../../../../../../shared/src/models/ICheckData';
import api from '../../../../index';

const tarieven = {
  ophalenOverigeTarieven: async (
    params: IOphalenOverigeTarievenParams,
  ): Promise<IOphalenOverigeTarievenResult> => {
    return api.post('v2/aanbod/tarieven/overige/ophalen-overige-tarieven', params);
  },
  checkWijzigenTarief: async (params: IWijzigenTariefParams): Promise<ICheckData> => {
    return api.post('v2/aanbod/tarieven/overige/check-wijzigen-tarief', params);
  },
  wijzigentarief: async (params: IWijzigenTariefParams): Promise<IWijzigenTariefResult> => {
    return api.post('v2/aanbod/tarieven/overige/wijzigen-overige-tarief', params);
  },
  checkVerwijderenTarieven: async (params: IVerwijderenTarievenParams): Promise<ICheckData> => {
    return api.post('v2/aanbod/tarieven/overige/check-verwijderen-overige-tarieven', params);
  },
  verwijderenTarieven: async (
    params: IVerwijderenTarievenParams,
  ): Promise<IVerwijderenTarievenResult> => {
    return api.post('v2/aanbod/tarieven/overige/verwijderen-overige-tarieven', params);
  },
  checkToevoegenTarief: async (params: IToevoegenTariefParams): Promise<ICheckData> => {
    return api.post('v2/aanbod/tarieven/overige/check-toevoegen-tarief', params);
  },
  toevoegenTarief: async (params: IToevoegenTariefParams): Promise<IToevoegenTariefResult> => {
    return api.post('v2/aanbod/tarieven/overige/toevoegen-tarief', params);
  },
};

export default tarieven;
