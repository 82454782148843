import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../models/IRemoteData';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import VerticaleScheidingslijn from '../../../../components/layout/VerticaleScheidingslijn';
import { IOphalenRollenResultElement } from '../../../../../../shared/src/api/v2/autorisatie/rol';
import {
  IOphalenPermissiesResult,
  IOphalenPermissiesResultElement,
} from '../../../../../../shared/src/api/v2/autorisatie/permissie';
import { IOphalenPermissieXRolsResult } from '../../../../../../shared/src/api/v2/autorisatie/permissieXRol';
import api from '../../../../api';
import PermissieWeergave from './PermissieWeergave';
import GebruikerWeergave from './GebruikerWeergave';
import { IOphalenRolXAspGebrsResult } from '../../../../../../shared/src/api/v2/autorisatie/rolXAspGebr';
import RolGegevensWeergave from './RolGegevensWeergave';

interface IRolWeergaveProps {
  rol: IOphalenRollenResultElement;
  permissies: IOphalenPermissiesResultElement[];
  onVerversenAangevraagd: () => Promise<void>;
  onActieveRolIDChange: (id: number | null) => void;
}

const RolWeergave = (props: IRolWeergaveProps) => {
  const [permissieXRolResult, setPermissieXRolResult] = useState<
    IRemoteData<IOphalenPermissieXRolsResult>
  >(createPendingRemoteData());
  const ophalenPermissieXRols = useCallback(async () => {
    const result = await api.v2.autorisatie.permissieXRol.ophalenPermissieXRols({
      filterSchema: {
        filters: [
          {
            naam: 'PERM_ROL_IDS',
            data: [props.rol.ID],
          },
        ],
      },
    });
    setPermissieXRolResult(createReadyRemoteData(result));
  }, [props.rol.ID]);
  useEffect(() => {
    ophalenPermissieXRols();
  }, [ophalenPermissieXRols]);

  const [gebruikersResult, setGebruikersResult] = useState<IRemoteData<IOphalenRolXAspGebrsResult>>(
    createPendingRemoteData(),
  );
  const ophalenGebruikers = useCallback(async () => {
    const result = await api.v2.autorisatie.gebruikers.ophalenRolXGebruikers({
      filterSchema: {
        filters: [
          {
            naam: 'PERM_ROL_IDS',
            data: [props.rol.ID],
          },
        ],
      },
    });
    setGebruikersResult(createReadyRemoteData(result));
  }, [props.rol.ID]);
  useEffect(() => {
    ophalenGebruikers();
  }, [ophalenGebruikers]);

  const handleRolGegevensGemuteerd = useCallback(async () => {
    await props.onVerversenAangevraagd();
  }, [props.onVerversenAangevraagd]);

  const handlePermissiesGemuteerd = useCallback(
    async (permissieIDs) => {
      await api.v2.autorisatie.permissieXRol.muterenPermissieXRol({
        permRolID: props.rol.ID,
        permissieIDs,
      });
      await ophalenPermissieXRols();
    },
    [ophalenPermissieXRols],
  );

  const handleGebruikersGemuteerd = useCallback(
    async (gebruikerIDs) => {
      await api.v2.autorisatie.gebruikers.muterenRolXAspGebr({
        permRolID: props.rol.ID,
        aspGebrIDs: gebruikerIDs,
      });
      await ophalenGebruikers();
    },
    [ophalenGebruikers],
  );

  const handleRolVerwijderd = useCallback(async () => {
    props.onActieveRolIDChange(null);
    await props.onVerversenAangevraagd();
  }, [props.onActieveRolIDChange]);

  return (
    <div className="d-flex flex-fill flex-column">
      {permissieXRolResult.state === ERemoteDataState.Pending ||
      gebruikersResult.state === ERemoteDataState.Pending ? (
        <div className="d-flex flex-fill align-items-center justify-content-center">
          <LoadingSpinner />
        </div>
      ) : (
        <div className="d-flex flex-fill">
          <RolGegevensWeergave
            rol={props.rol}
            onGemuteerd={handleRolGegevensGemuteerd}
            onVerwijderd={handleRolVerwijderd}
          />
          <VerticaleScheidingslijn />
          <PermissieWeergave
            permissies={props.permissies}
            permissieXRols={permissieXRolResult.data!.permissies}
            onGemuteerd={handlePermissiesGemuteerd}
          />
          <VerticaleScheidingslijn />
          <GebruikerWeergave
            onGemuteerd={handleGebruikersGemuteerd}
            rolXAspGebrs={gebruikersResult.data!.gebruikers}
          />
        </div>
      )}
    </div>
  );
};

export default RolWeergave;
