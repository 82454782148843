import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import IDialoogProps from '../../../../../../../../core/IDialoogProps';
import Dialoog from '../../../../../../../../components/dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import * as Yup from 'yup';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../../../../../../stores/RootStore';
import Combobox, { IOptie } from '../../../../../../../../components/formulier/Combobox';
import nameOf from '../../../../../../../../core/nameOf';
import api from '../../../../../../../../api';
import LoadingSpinner from '../../../../../../../../components/Gedeeld/LoadingSpinner';
import { EResultType } from '../../../../../../../../stores/CheckStore';
import _ from 'lodash';
import { IOphalenSjablonenResult } from '../../../../../../../../../../shared/src/api/v2/Sjabloon/sjabloon';
import { ESjablooncode } from '../../../../../../../../bedrijfslogica/enums';

interface IDialoogResult {}

interface IProps extends IDialoogProps<IDialoogResult> {
  aprDocID: number;
}

interface IFormikValues {
  sjabID: number | null;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  sjabID: 'Sjabloon',
};

const VersturenVerzoekDocumentenDialoog: React.FC<IProps> = observer((props) => {
  const { children, dialoogIndex, onAnnuleren, onSuccess, open, aprDocID: penOpdID } = props;
  const { checkStore } = useContext(RootStoreContext);

  const [sjablonen, setSjablonen] = useState<IOphalenSjablonenResult | null>(null);

  const bijlageSjabloonOpties = useMemo<IOptie<number>[] | null>(() => {
    if (sjablonen === null) {
      return null;
    }

    const opties = sjablonen.sjablonen.map((x) => {
      return { id: x.ID, label: x.Naam };
    });

    return opties;
  }, [sjablonen]);

  useEffect(() => {
    (async () => {
      const result = await api.v2.sjabloon.ophalenSjablonen({
        filterSchema: {
          filters: [
            {
              naam: 'NAAM_ENUMS',
              data: [
                ESjablooncode.VerzoekDocumentenAccepatie,
                ESjablooncode.Afwijzing1Gebruiker,
                ESjablooncode.AfwijzingMeerGebruikers,
                ESjablooncode.VerzoekDocumentenAlleenGebruik,
              ],
            },
          ],
        },
        orderSchema: { orders: [{ naam: 'NAAM_ENUM', richting: 'ASC' }] },
      });

      setSjablonen(result);
    })();
  }, []);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      if (sjablonen === null) {
        return;
      }

      actions.setSubmitting(true);

      if (
        (
          await checkStore.bevestigen({
            inhoud: `Bericht versturen?`,
          })
        ).type === EResultType.Annuleren
      ) {
        actions.setSubmitting(false);
        return;
      }

      const params = {
        id: props.aprDocID,
        sjabloonNaamEnum: sjablonen!.sjablonen.find((x) => x.ID === values.sjabID)!.NaamEnum!,
      };

      await api.v2.relatie.acceptatie.versturenVerzoekDocumenten(params);

      props.onSuccess({});
      actions.setSubmitting(false);
    },
    [sjablonen],
  );

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (sjablonen === null) {
      return null;
    }

    return {
      sjabID: sjablonen.sjablonen.find(
        (x) => x.NaamEnum === ESjablooncode.VerzoekDocumentenAccepatie,
      )!.ID,
    };
  }, [sjablonen]);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        sjabID: Yup.number().required(),
      }),
    [],
  );

  return (
    <Dialoog index={dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Versturen bericht</ModalTitle>
      </ModalHeader>
      {initialValues === null || bijlageSjabloonOpties === null ? (
        <LoadingSpinner />
      ) : (
        <Formik<IFormikValues>
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
          // enableReinitialize
          isInitialValid
          render={(formikProps: FormikProps<IFormikValues>) => {
            const { submitForm, isValid, isSubmitting } = formikProps;

            return (
              <>
                <ModalBody>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-12 mt-3">
                        <label>{veldnamen.sjabID}</label>
                        <Field
                          name={nameOf<IFormikValues>('sjabID')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            if (bijlageSjabloonOpties === null) {
                              return <LoadingSpinner />;
                            }

                            return (
                              <Combobox
                                geselecteerd={field.value}
                                opties={bijlageSjabloonOpties.map((x) => {
                                  return {
                                    id: x.id,
                                    label: x.label,
                                  };
                                })}
                                onSelectieChange={(x) => form.setFieldValue(field.name, x)}
                              />
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </ModalBody>
                <ModalFooter className="d-flex flex-row justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    style={{ width: 100 }}
                    disabled={!isValid || isSubmitting}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={onAnnuleren}
                    style={{ width: 100 }}
                    disabled={isSubmitting}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Dialoog>
  );
});

export default VersturenVerzoekDocumentenDialoog;
