import React, { useContext, useMemo, useState } from 'react';
import { IProps } from '@tinymce/tinymce-react/lib/cjs/main/ts/components/Editor';
import { IContext, FactuurContext } from '../index';
import VeldWeergave from '../../../formulier/VeldWeergave';
import LoadingSpinner from '../../../Gedeeld/LoadingSpinner';
import { format } from 'date-fns';
import GegevensLayout from '../../../layout/GegevensLayout';
import FormatteerBedrag from '../../../MutatieBedrag';
import RelatieVisualisatie from '../../../personalia/RelatieVisualisatie';
import CrediteurInfoDialoog from '../../../crediteur/CrediteurinfoDialoog';
import { IconInformatie } from '../../../Icons';
import { Kleur as EKleur } from '../../../../bedrijfslogica/constanten';
import _ from 'lodash';
import InkoopfactuurVisualisatie from '../../../entiteitVisualisaties/InkoopfactuurVisualisatie';

interface ICrediteurinfoDialoogState {
  relID: number;
}

interface IBoekingInfoDialoogState {
  boekingID: number;
}

const FactuurTab: React.FC<IProps> = (props) => {
  const { factuur, relatie } = useContext<IContext>(FactuurContext);

  const crediteur = relatie!.crediteur!;

  const [
    crediteurinfoDialoogState,
    setCrediteurinfoDialoogState,
  ] = useState<ICrediteurinfoDialoogState | null>(null);

  const standaardrekening =
    relatie!.financieel.rekeningen.length !== 0
      ? relatie!.financieel.rekeningen.find(
          (r) => r.RelRekID === relatie!.financieel.StandaardRekening_RelRekID,
        ) ?? null
      : null;

  // const kolommenAanmaningen = useMemo<TypedColumn<IOphalenAanmaningenResultElement>[]>(
  //   () => [
  //     {
  //       name: 'Aanmaningdatum',
  //       title: 'Datum',
  //     },
  //     {
  //       name: 'Vervaldatum',
  //       title: 'Vervaldatum',
  //     },
  //     {
  //       name: 'Definitief',
  //       title: 'Def.',
  //     },
  //     {
  //       name: '__bedragInAanmaning' as any,
  //       title: 'Bedrag',
  //     },
  //     {
  //       name: '__kostenAanmaning' as any,
  //       title: 'Kosten',
  //     },
  //     {
  //       name: 'Bedrag',
  //       title: 'Tot. aanm.',
  //     },
  //   ],
  //   [],
  // );

  // const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IOphalenAanmaningenResultElement>[]>(
  //   () => [
  //     {
  //       columnName: 'Aanmaningdatum',
  //       width: 90,
  //     },
  //     {
  //       columnName: 'Vervaldatum',
  //       width: 90,
  //     },
  //     {
  //       columnName: 'Definitief',
  //       width: 65,
  //     },
  //     {
  //       columnName: '__bedragInAanmaning' as any,
  //       width: 85,
  //     },
  //     {
  //       columnName: '__kostenAanmaning' as any,
  //       width: 85,
  //     },
  //     {
  //       columnName: 'Bedrag',
  //       width: 110,
  //     },
  //   ],
  //   [],
  // );

  return (
    <>
      <div className="d-flex flex-column flex-fill">
        {factuur === null || relatie === null ? (
          <div className="d-flex flex-fill align-items-center justify-content-center">
            <LoadingSpinner />
          </div>
        ) : (
          <>
            <div className="form-group"></div>
            <div className="row">
              <div className="col-12 d-flex mt-2">
                <div className="col-6">
                  {/* <label></label> */}
                  <VeldWeergave>
                    <GegevensLayout
                      gegevens={[
                        {
                          label: 'Factuurnr',
                          waarde: <InkoopfactuurVisualisatie inkFactID={factuur.InkFactID} />,
                        },
                        {
                          label: 'Boekstuknr',
                          waarde: (
                            <span>
                              {factuur.Boekstuknummer}{' '}
                              <span style={{ color: EKleur.LichtGrijs }}>
                                -{format(new Date(factuur.Factuurdatum), 'yyyy')}
                              </span>
                            </span>
                          ),
                        },
                        {
                          label: 'Factuurreferentie',
                          waarde: `${factuur.Factuurreferentie ?? ''}`,
                        },
                        {
                          label: 'Fact.datum',
                          waarde: `${format(new Date(factuur.Factuurdatum), 'dd-MM-yyyy')}`,
                        },
                        {
                          label: 'Vervaldatum',
                          waarde: `${format(new Date(factuur.Vervaldatum), 'dd-MM-yyyy')}`,
                        },
                        {
                          label: 'Vervaldatum korting',
                          waarde: (
                            <span>
                              {factuur.VervaldatumKorting !== null
                                ? format(new Date(factuur.VervaldatumKorting), 'dd-MM-yyyy')
                                : '-'}
                            </span>
                          ),
                        },
                      ]}
                    />
                  </VeldWeergave>
                </div>

                <div className="col-6">
                  {/* <label></label> */}
                  <VeldWeergave>
                    <GegevensLayout
                      gegevens={[
                        {
                          label: 'Bedrag',
                          waarde: <FormatteerBedrag bedrag={factuur.Bedrag} />,
                        },
                        {
                          label: 'Openstaand',
                          waarde: factuur.Geboekt ? (
                            <FormatteerBedrag bedrag={factuur.Openstaand} />
                          ) : (
                            <span style={{ color: EKleur.Rood }}>Niet def. ingeboekt</span>
                          ),
                        },
                        {
                          label: 'Btw-bedrag',
                          waarde: <FormatteerBedrag bedrag={factuur.BedragBtw} />,
                        },
                        {
                          label: 'Kortingbedrag',
                          waarde: <FormatteerBedrag bedrag={factuur.BedragKorting} />,
                        },
                        null,
                        null,
                      ]}
                    />
                  </VeldWeergave>
                </div>
              </div>

              <div className="col-12 d-flex mt-2">
                <div className="col-12">
                  {/* <label></label> */}
                  <VeldWeergave>
                    <GegevensLayout
                      gegevens={[
                        {
                          label: 'Onderwerp',
                          waarde: factuur.Onderwerp !== null ? factuur.Onderwerp : '',
                        },
                        {
                          label: 'Betaalwijze',
                          waarde: factuur.betaalwijze !== null ? factuur.betaalwijze.Naam : null,
                        },
                        {
                          label: 'Ophouden',
                          waarde: factuur.Aanhouden ? 'Ja' : 'Nee',
                        },
                        {
                          label: 'Gefiatteerd',
                          waarde: factuur.Gefiatteerd ? 'Ja' : 'Nee',
                        },
                      ]}
                    />
                  </VeldWeergave>
                </div>
              </div>

              <div className="col-12 d-flex mt-3">
                <div className="col-6">
                  {/* <label></label> */}
                  <VeldWeergave>
                    <span className="d-flex">
                      <b>Crediteur</b>
                      <span className="ml-2">
                        <a
                          href="#"
                          // style={{ color: Kleur.DonkerGrijs }}
                          onClick={() => {
                            setCrediteurinfoDialoogState({ relID: relatie.RelID });
                          }}
                        >
                          <IconInformatie style={{ width: 15, height: 15, fill: EKleur.Blauw }} />
                        </a>
                      </span>
                    </span>
                    <GegevensLayout
                      gegevens={[
                        {
                          label: 'Naam',
                          waarde: (
                            <div className="d-flex">
                              {' '}
                              <RelatieVisualisatie
                                relID={relatie.RelID}
                                options={{ geenLinkToepassen: false }}
                              />
                            </div>
                          ),
                        },
                        {
                          label: 'Betalingskorting',
                          waarde: (
                            <span>
                              {relatie.crediteur!.BetalingstermijnKorting !== null ? 'Ja' : 'Nee'}
                            </span>
                          ),
                        },
                        {
                          label: 'Termijn',
                          waarde: (
                            <span>
                              {crediteur.BetalingstermijnKorting !== null
                                ? crediteur.BetalingstermijnKorting.toString() + ' dagen'
                                : '-'}
                            </span>
                          ),
                        },

                        {
                          label: 'Percentage',
                          waarde: (
                            <span>
                              {relatie.crediteur!.BetalingstermijnKorting !== null
                                ? crediteur.KortingsPercentage.toString() + '%'
                                : '-'}
                            </span>
                          ),
                        },
                        null,
                        {
                          label: 'IBAN',
                          waarde: standaardrekening !== null ? standaardrekening.IBAN : '',
                        },
                        {
                          label: 'Rekeningnaam',
                          waarde: standaardrekening !== null ? standaardrekening.Rekeningnaam : '',
                        },
                      ]}
                    />
                  </VeldWeergave>
                </div>

                <div className="col-6">
                  <VeldWeergave>
                    <GegevensLayout
                      gegevens={[
                        {
                          label: 'Producten koppelen',
                          waarde: factuur.KoppelenProducten ? 'Ja' : 'Nee',
                        },
                        {
                          label: 'Producten gekoppeld',
                          waarde: factuur.KoppelenProducten
                            ? factuur.ProductenGekoppeld
                              ? 'Ja'
                              : 'Nee'
                            : '-',
                        },
                        {
                          label: 'Sponsorcontracten koppelen',
                          waarde: factuur.KoppelenSponsorcontracten ? 'Ja' : 'Nee',
                        },
                        {
                          label: 'Sponsorcontracten gekoppeld',
                          waarde: factuur.KoppelenSponsorcontracten
                            ? factuur.SponsorcontractenGekoppeld
                              ? 'Ja'
                              : 'Nee'
                            : '-',
                        },
                        null,
                        null,
                        null,
                        null,
                      ]}
                    ></GegevensLayout>
                  </VeldWeergave>
                </div>
              </div>

              {factuur.Notities !== null && (
                <div className="col-12 d-flex mt-3">
                  <div className="col-12">
                    {/* <label></label> */}
                    <VeldWeergave>{factuur.Notities}</VeldWeergave>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
      {crediteurinfoDialoogState && (
        <CrediteurInfoDialoog
          open
          relID={crediteurinfoDialoogState.relID}
          onSuccess={() => null}
          onAnnuleren={() => setCrediteurinfoDialoogState(null)}
        />
      )}
    </>
  );
};

export default FactuurTab;
