import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import api from '../../../api';
import LoadingSpinner from '../../../components/Gedeeld/LoadingSpinner';
import useUrlState, { genereerUrlStateQueryParam } from '../../../core/useUrlState';
import { RootStoreContext } from '../../../stores/RootStore';
import { IOphalenAfbeeldingenResultElement } from '../../../../../shared/src/api/v2/website/afbeelding';
import MenuLayout from '../../../components/MenuLayout';
import {
  DXTableCheckboxComponent,
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../helpers/dxTableGrid';
import {
  Grid,
  Table,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  TableSelection,
} from '@devexpress/dx-react-grid-bootstrap4';
import _ from 'lodash';
import { Kleur } from '../../../bedrijfslogica/constanten';
import { IconToevoegen, IconVerwijderen } from '../../../components/Icons';
import { DataTypeProvider, EditingState, SelectionState } from '@devexpress/dx-react-grid';
import nameof from '../../../core/nameOf';
import { EResultType } from '../../../stores/CheckStore';
import ToevoegenDialoog from './ToevoegenDialoog';
import WijzigenDialoog from './WijzigenDialoog';
import AfbeeldingKolomWeergave from './AfbeeldingKolomWeergave';
import { Helmet } from 'react-helmet';
import { defaultAspDriveUrlState, IAspDriveUrlState } from '../../ASPDrive';

interface IProps extends RouteComponentProps {}

interface IToevoegenDialoogState {}
interface IWijzigenDialoogState {
  id: number;
}
interface IUrlState {
  selectie: number[];
  uitgeklapt: number[];
  toevoegenDialoogState: IToevoegenDialoogState | null;
  wijzigenDialoogState: IWijzigenDialoogState | null;
}

const defaultUrlState: IUrlState = {
  selectie: [],
  uitgeklapt: [],
  toevoegenDialoogState: null,
  wijzigenDialoogState: null,
};

const Afbeeldingen: React.FC<IProps> = (props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const { checkStore } = useContext(RootStoreContext);

  const [afbeeldingen, setAfbeeldingen] = useState<IOphalenAfbeeldingenResultElement[] | null>(
    null,
  );

  const handleToevoegen = useCallback(async () => {
    // alert('Toevoegen');
    setUrlStateSync('toevoegenDialoogState', {});
  }, []);

  const handleVerwijderen = useCallback(async () => {
    const params = { IDs: urlState.selectie };
    if (
      (
        await checkStore.bevestigen({
          titel: <span>Geselecteerde afbeeldingen verwijderen?</span>,
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    await api.v2.extern.afbeelding.verwijderenAfbeeldingen(params);
    await ophalenAfbeeldingen();
  }, [urlState.selectie]);

  const ophalenAfbeeldingen = useCallback(async () => {
    const result = await api.v2.extern.afbeelding.ophalenAfbeeldingen({
      filterSchema: { filters: [] },
    });
    const afbeeldingen = _.orderBy(result.afbeeldingen, ['NaamEnum'], ['asc']);

    setAfbeeldingen(afbeeldingen);
  }, []);

  useEffect(() => {
    ophalenAfbeeldingen();
  }, [ophalenAfbeeldingen]);

  const keyExtractor = useCallback((row: IOphalenAfbeeldingenResultElement) => row.ID, []);
  const kolommen = useMemo<TypedColumn<IOphalenAfbeeldingenResultElement>[]>(
    () => [
      {
        name: 'NaamEnum',
        title: 'NaamEnum',
      },
      {
        name: '__afbeelding' as any,
        title: 'Afbeelding',
      },
      {
        name: 'Naam',
        title: 'Naam',
      },
      {
        name: '__naar_bestand' as any,
        title: ' ',
      },
    ],
    [],
  );
  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IOphalenAfbeeldingenResultElement>[]>(
    () => [
      {
        columnName: 'NaamEnum',
        width: 300,
      },
      {
        columnName: 'Naam',
        width: 400,
      },
      {
        columnName: '__afbeelding' as any,
        width: 400,
      },
      {
        columnName: '__naar_bestand' as any,
        width: 100,
      },
    ],
    [],
  );

  return (
    <>
      <Helmet>
        <title>Website Afbeeldingen</title>
      </Helmet>
      <MenuLayout
        menu={
          <div className="d-flex">
            <button
              className="btn btn-sm btn-light d-flex align-items-center"
              style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
              disabled={false}
              onClick={() => handleToevoegen()}
            >
              <IconToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
              <span className="ml-2">Toevoegen</span>
            </button>
            <button
              className="btn btn-sm btn-light d-flex align-items-center ml-3"
              style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
              disabled={afbeeldingen === null || afbeeldingen.length === 0}
              onClick={() => handleVerwijderen()}
            >
              <IconVerwijderen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
              <span className="ml-2">Verwijderen</span>
            </button>
          </div>
        }
        body={
          <div className="d-flex flex-fill flex-column" style={{ width: '100%', height: '100%' }}>
            {afbeeldingen === null ? (
              <LoadingSpinner />
            ) : (
              <GridStyleWrapper height="calc(100vh - 100px)">
                <Grid getRowId={keyExtractor} rows={afbeeldingen} columns={kolommen}>
                  <DataTypeProvider
                    for={[nameof<IOphalenAfbeeldingenResultElement>('NaamEnum')]}
                    formatterComponent={(formatterProps) => <span>{formatterProps.value}</span>}
                  />
                  <DataTypeProvider
                    for={[nameof<IOphalenAfbeeldingenResultElement>('Naam')]}
                    formatterComponent={(formatterProps) => <span>{formatterProps.value}</span>}
                  />
                  <DataTypeProvider
                    for={['__afbeelding']}
                    formatterComponent={(formatterProps) => {
                      const row: IOphalenAfbeeldingenResultElement = formatterProps.row;
                      return <AfbeeldingKolomWeergave afbeelding={row} />;
                    }}
                  />
                  <DataTypeProvider
                    for={['__naar_bestand']}
                    formatterComponent={(formatterProps) => {
                      const row: IOphalenAfbeeldingenResultElement = formatterProps.row;

                      if (row.bestand === null) {
                        return null;
                      }

                      return (
                        <a
                          href="#"
                          onClick={(ev) => {
                            ev.preventDefault();

                            const state = genereerUrlStateQueryParam<IAspDriveUrlState>({
                              ...defaultAspDriveUrlState,
                              gefocustItemId: `B-${row.bestand!.ID}`,
                              bestandsmapID: row.bestand!.BestandsmapID,
                            });
                            props.history.push(`/asp-drive?state=${state}`);
                          }}
                        >
                          Naar bestand
                        </a>
                      );
                    }}
                  />

                  <EditingState
                    onCommitChanges={async (changes) => {
                      if (changes.deleted !== undefined && changes.deleted.length > 0) {
                        const ids = changes.deleted as number[];
                      }
                    }}
                    onEditingRowIdsChange={(rowIds) => {
                      const id = rowIds[rowIds.length - 1] as number;
                      setUrlStateSync('wijzigenDialoogState', { id });
                    }}
                  />
                  <Table />
                  <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
                  <TableHeaderRow />

                  <TableEditColumn
                    width={35}
                    commandComponent={DXTableEditColumnCommandComponent}
                    cellComponent={DXTableEditColumnCellComponent}
                    showEditCommand
                  />
                  <SelectionState
                    selection={urlState.selectie}
                    onSelectionChange={(x) => setUrlStateSync('selectie', x as number[])}
                  />
                  <TableSelection cellComponent={DXTableCheckboxComponent} />
                </Grid>
              </GridStyleWrapper>
            )}
          </div>
        }
      />
      {urlState.toevoegenDialoogState !== null && (
        <ToevoegenDialoog
          open
          onSuccess={async () => setUrlStateSync('toevoegenDialoogState', null)}
          onAnnuleren={() => setUrlStateSync('toevoegenDialoogState', null)}
        />
      )}

      {urlState.wijzigenDialoogState !== null && (
        <WijzigenDialoog
          open
          id={urlState.wijzigenDialoogState.id}
          onSuccess={async () => {
            setUrlStateSync('wijzigenDialoogState', null);
            ophalenAfbeeldingen();
          }}
          onAnnuleren={() => setUrlStateSync('wijzigenDialoogState', null)}
        />
      )}
    </>
  );
};

export default Afbeeldingen;
