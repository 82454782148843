import React, { useCallback, useContext, useMemo } from 'react';
import { IProps } from '@tinymce/tinymce-react/lib/cjs/main/ts/components/Editor';
import { IContext, InfoContext } from '../index';
import ASPTabel from '../../../../../components/tabel/ASPTabel';
import { IInventarisatieInfoInternRegel } from '../../../../../../../shared/src/api/v2/magazijn/inventarisatie';
import { ASPKolom, EAspKolomBreedteType } from '../../../../../components/tabel/ASPTabel/types';
import { Kleur as EKleur } from '../../../../../bedrijfslogica/constanten';
import { EProductstatus } from '../../../../../bedrijfslogica/enums';

interface IProducttype {
  PrioNr: number;
  Merknaam: string;
  Typenaam: string;
  Kenmerk: string | null;
  InkoopToegestaan: boolean;
}

const InternTab: React.FC<IProps> = (props) => {
  const { inventarisatieInfo, productstatussenResult } = useContext<IContext>(InfoContext);

  const keyExtractor = useCallback((item: IInventarisatieInfoInternRegel) => item.TypeID, []);
  const kolommen = useMemo<ASPKolom<string, IInventarisatieInfoInternRegel>[]>(
    () => [
      {
        key: 'Nieuw',
        label: 'N/G',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 50,
        renderer: (item) => (item.Nieuw ? 'N' : 'G'),
      },
      {
        key: 'Referentiecode',
        label: 'Ref.code',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 100,
        renderer: (item) => item.Referentiecode,
      },
      ...productstatussenResult.data!.statussen.map(
        (status): ASPKolom<string, IInventarisatieInfoInternRegel> => ({
          key: `PS-${status.ProdStatID}`,
          label: () => {
            return (
              <span
                style={{
                  color: status.NaamEnum === EProductstatus.Voorraad ? EKleur.Blauw : undefined,
                }}
              >
                {status.NaamKort}
              </span>
            );
          },
          breedteType: EAspKolomBreedteType.Vast,
          vasteBreedte: 60,
          renderer: (item) => {
            // if (item.Zoekgemeld !== null || item.ProdStatID !== status.ProdStatID) {
            //   return null;
            // }
            if (item.Zoekgemeld || item.ProdStatID !== status.ProdStatID) {
              return null;
            }

            if (status.NaamEnum === EProductstatus.Voorraad) {
              return (
                <span
                  style={{
                    color: EKleur.Blauw,
                  }}
                >
                  {item.Aantal}
                </span>
              );
            }

            return item.Aantal;
          },
        }),
      ),
      {
        key: 'Zoekgemeld',
        label: 'Zoekgemeld',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 90,
        renderer: (item) => {
          if (!item.Zoekgemeld) {
            return null;
          }

          return item.Aantal;
        },
      },
    ],
    [productstatussenResult],
  );

  return (
    <div className="flex-fill d-flex flex-column">
      <ASPTabel
        rijen={inventarisatieInfo!.intern.regels}
        kolommen={kolommen}
        keyExtractor={keyExtractor}
      />
    </div>
  );
};

export default InternTab;
