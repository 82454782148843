import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import IDialoogProps from '../../../../core/IDialoogProps';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import api from '../../../../api';
import nameOf from '../../../../core/nameOf';
import Combobox from '../../../../components/formulier/Combobox';
import Dialoog from '../../../../components/dialogen/Dialoog';
import * as Yup from 'yup';
import { RootStoreContext } from '../../../../stores/RootStore';
import { EResultType } from '../../../../stores/CheckStore';

interface IProps extends IDialoogProps<null> {}

export interface IFormikValues {
  postcode: string;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  postcode: 'Postcode',
};

const ToevoegenDialoog: React.FC<IProps> = (props) => {
  const { onAnnuleren, onSuccess, open, dialoogIndex } = props;
  const { checkStore } = useContext(RootStoreContext);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      const params = {
        postcode: values.postcode,
      };

      const checkData = await api.v2.relatie.acceptatie.checkToevoegenDubieusAdres(params);
      const checkResult = await checkStore.controleren({
        checkData,
      });
      if (checkResult.type === EResultType.Annuleren) {
        actions.setSubmitting(false);
        return;
      }

      if (
        (
          await checkStore.bevestigen({
            inhoud: <span>Adres toevoegen?</span>,
          })
        ).type === EResultType.Annuleren
      ) {
        actions.setSubmitting(false);
        return;
      }

      await api.v2.relatie.acceptatie.toevoegenDubieusAdres(params);

      onSuccess(null);
      actions.setSubmitting(false);
    },
    [],
  );

  const initialValues = useMemo<IFormikValues>(() => {
    return {
      postcode: '',
    };
  }, []);

  const validationSchema = useMemo(() => {
    const fields: Partial<Record<keyof IFormikValues, any>> = {
      postcode: Yup.string().required(),
    };

    return Yup.object().shape(fields);
  }, []);

  // const postcodeRef = useRef<HTMLInputElement>(null);
  // useEffect(() => {
  //   setTimeout(() => {
  //     postcodeRef.current!.focus();
  //   }, 50);
  // }, []);

  return (
    <Dialoog index={dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Toevoegen dubieus adres</ModalTitle>
      </ModalHeader>

      {initialValues === null ? (
        <div className="d-flex flex-fill align-items-center justify-content-center">
          <LoadingSpinner />
        </div>
      ) : (
        <Formik<IFormikValues>
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          render={(formikProps: FormikProps<IFormikValues | null>) => {
            const { submitForm, isSubmitting, isValid, values } = formikProps;
            return (
              <>
                <ModalBody>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-4">
                        <label>{veldnamen.postcode}</label>
                        <Field
                          name={nameOf<IFormikValues>('postcode')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { form, field } = fieldProps;

                            return (
                              <>
                                <input
                                  // ref={postcodeRef}
                                  type="text"
                                  className="form-control"
                                  {...field}
                                  onChange={(ev) => {
                                    const newValues = {
                                      ...form.values,
                                      [field.name]: ev.target.value,
                                    };
                                    form.setValues(newValues);
                                    handleSubmit(newValues, form);
                                  }}
                                  placeholder="b.v. 3581"
                                />
                                {/* <FormikVeldFout fieldProps={fieldProps} /> */}
                              </>
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </ModalBody>
                <ModalFooter className="d-flex justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    disabled={isSubmitting || !isValid}
                    style={{ width: 100 }}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={onAnnuleren}
                    style={{ width: 100 }}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Dialoog>
  );
};

export default ToevoegenDialoog;
