import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useMemo } from 'react';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import * as Yup from 'yup';
import { ISponsorEventPersoon } from '../../../../../../../../shared/src/api/v2/sponsoringv2/event';
import api from '../../../../../../api';
import LoadingSpinner from '../../../../../../components/Gedeeld/LoadingSpinner';
import Dialoog from '../../../../../../components/dialogen/Dialoog';
import SponsorItemSelectie from '../../../../../../components/formulier/SponsorItemSelectie';
import IDialoogProps from '../../../../../../core/IDialoogProps';
import nameOf from '../../../../../../core/nameOf';
import ContactpersoonSelectie, {
  IOverlayState,
  Key,
} from '../../../../../../components/formulier/ContactpersoonSelectie';
import { IOphalenContactpersonenResultElement } from '../../../../../../../../shared/src/api/v2/relatie/contactpersoon';
import { Provider } from '../../../../../../components/formulier/MultiComboboxV2';
import ContactKoppelComponent from '../../../../../../components/ContactKoppelComponent';

interface IDialoogResult {}

interface IProps extends IDialoogProps<IDialoogResult> {
  koppelBasisID: number;
  relID: number;
}

interface IFormikValues {
  relPersID: number | null;
}

const KoppelenDialoog: React.FC<IProps> = observer((props) => {
  const { dialoogIndex, onAnnuleren } = props;

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      const persoonResult = await api.v2.persoon.ophalenPersonen({
        filterSchema: {
          filters: [
            {
              naam: 'REL_IDS',
              data: values.relPersID,
            },
          ],
        },
      });

      let params: ISponsorEventPersoon = {
        EventID: props.koppelBasisID,
        PersID: persoonResult.personen[0].PersID,
      };

      await api.v2.sponsoringv2.event.toevoegenPersoon(params);

      actions.setSubmitting(false);
      props.onSuccess({});
    },
    [],
  );

  const initialValues = useMemo<IFormikValues | null>(() => {
    return {
      relPersID: null,
    };
  }, []);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        relPersID: Yup.number()
          .required()
          .moreThan(0),
      }),
    [],
  );

  const provider = useMemo<Provider<Key, IOphalenContactpersonenResultElement, IOverlayState>>(
    () => async (params) => {
      const result = await api.v2.relatie.ophalenContactpersonen({
        paginatie: params.paginatie,
        filterSchema: {
          filters: [
            {
              naam: 'REL_IDS',
              data: [props.relID],
            },
          ],
        },
      });

      const items = result.contactpersonen.reduce(
        (acc, curr, i) => ({
          ...acc,
          [i + params.paginatie.index]: curr,
        }),
        {},
      );

      return {
        totaalAantal: result.totaalAantal,
        items,
      };
    },
    [props.relID],
  );

  return (
    <Dialoog index={dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Koppelen persoon</ModalTitle>
      </ModalHeader>
      {initialValues === null ? (
        <LoadingSpinner />
      ) : (
        <Formik<IFormikValues>
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize
          isInitialValid
          render={(formikProps: FormikProps<IFormikValues>) => {
            const { submitForm, isValid, isSubmitting } = formikProps;

            return (
              <>
                <ModalBody>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-12 mt-3">
                        <label>Persoon</label>
                        <Field
                          name={nameOf<IFormikValues>('relPersID')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              // <ContactKoppelComponent
                              //   persID={field.value}
                              //   onChange={(x) => form.setFieldValue(field.name, x)}
                              //   orgID={props.orgID}
                              // />
                              <ContactpersoonSelectie
                                relPersID={field.value}
                                onChange={(x) => {
                                  form.setFieldValue(field.name, x);
                                }}
                                provider={provider}
                              />
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </ModalBody>

                <ModalFooter className="d-flex flex-row justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    style={{ width: 100 }}
                    disabled={!isValid || isSubmitting}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={onAnnuleren}
                    style={{ width: 100 }}
                    disabled={isSubmitting}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Dialoog>
  );
});

export default KoppelenDialoog;
