import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import MenuLayout from '../../../../../../../components/MenuLayout';
import { ILocatiesTabbladProps } from '../index';

interface IProps extends ILocatiesTabbladProps {}

const Gebouwen = (props: IProps) => {
  return <MenuLayout menu={<></>} body={<></>} />;
};

export default Gebouwen;
