import React from 'react';
import { IPotentieelOntvangenTransportopdrachtAfleverbewijsItem } from '../../../../../../shared/src/api/v2/externeData';
import { IRegelBase } from '../..';
import { formatteerAdresV2 } from '../../../../helpers';

interface IProps {
  regel: IRegelBase;
  item: IPotentieelOntvangenTransportopdrachtAfleverbewijsItem;
}

export const formatteerTypeOpdracht = (
  type: 'LEVERING' | 'LEVERING_OMRUIL' | 'RETOUR' | 'RETOUR_OMRUIL',
) => {
  switch (type) {
    case 'LEVERING':
      return 'Levering';
    case 'LEVERING_OMRUIL':
      return 'Levering omruil';
    case 'RETOUR':
      return 'Retour';
    case 'RETOUR_OMRUIL':
      return 'Retour omruil';
  }
};

const TransportopdrachtAfleverbewijsWeergave = (props: IProps) => {
  return (
    <div
      className="flex-fill d-flex align-items-center"
      style={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
      }}
    >
      <span className="mr-2">
        {formatteerAdresV2({
          bisnummer: props.item.Bisnummer,
          huisnummer: Number(props.item.Huisnummer),
          postcode: props.item.Postcode,
          straatnaam: props.item.Straatnaam,
          plaatsnaam: props.item.Plaatsnaam,
        })}
      </span>
      &ndash;
      <span className="ml-2 mr-2">{formatteerTypeOpdracht(props.item.TypeOpdracht)}</span>
      &ndash;
      <span className="ml-2">
        (aankomst: {props.item.Aankomsttijd} &ndash; vertrek: {props.item.Aankomstvertrek})
      </span>
    </div>
  );
};

export default TransportopdrachtAfleverbewijsWeergave;
