import React, { useMemo } from 'react';

import styled from 'styled-components';
import { IOrganisatieIdentiteit } from '../../../../types';
import { IconOrganisatie } from '../../../../../../Icons';
import { Kleur } from '../../../../../../../bedrijfslogica/constanten';
import IRemoteData, {
  createPendingRemoteData,
  ERemoteDataState,
} from '../../../../../../../models/IRemoteData';
import { IOphalenOrganisatiesResultElement } from '../../../../../../../../../shared/src/api/v2/organisatie/organisatie';
import useBijGewijzigdEffect from '../../../../../../../core/useBijGewijzigdEffect';
import Skeleton from 'react-loading-skeleton';

const Root = styled.div`
  display: flex;
  align-items: center;
  background-color: rgb(255, 234, 208);
  padding: 1px 5px;
  border-radius: 5px;
  height: 20px;
`;

interface IProps {
  identiteit: IOrganisatieIdentiteit;
  organisatiesCache: Record<number, IRemoteData<IOphalenOrganisatiesResultElement>>;
  onHerberekenenGrootte: () => void;
}

const OrganisatieIdentiteit = (props: IProps) => {
  const organisatie = useMemo<IRemoteData<IOphalenOrganisatiesResultElement>>(() => {
    const entry = props.organisatiesCache[props.identiteit.orgID];
    return entry ?? createPendingRemoteData();
  }, [props.identiteit.orgID, props.organisatiesCache[props.identiteit.orgID]]);

  useBijGewijzigdEffect(() => {
    props.onHerberekenenGrootte();
  }, [organisatie]);

  return (
    <Root>
      <IconOrganisatie style={{ fill: Kleur.DonkerGrijs, width: 13, height: 13, marginRight: 5 }} />
      {organisatie.state === ERemoteDataState.Pending ? (
        <Skeleton width={100} />
      ) : (
        <span style={{ color: Kleur.DonkerGrijs, fontSize: 12 }}>{organisatie.data!.Naam}</span>
      )}
    </Root>
  );
};

export default OrganisatieIdentiteit;
