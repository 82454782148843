import {
  IOphalenBulkberichtvoorkeurenParams,
  IOphalenBulkberichtenvoorkeurenResult,
  IToevoegenBulkberichtvoorkeurParams,
  IToevoegenBulkberichtvoorkeurResult,
  IWijzigenBulkberichtvoorkeurParams,
  IWijzigenBulkberichtvoorkeurResult,
  IVerwijderenBulkberichtvoorkeurenParams,
  IVerwijderenBulkberichtvoorkeurenResult,
} from '../../../../../../shared/src/api/v2/bulkbericht/voorkeur';
import api from '../../../index';
import ICheckData from '../../../../../../shared/src/models/ICheckData';

const voorkeur = {
  ophalenBulkberichtVoorkeuren: async (
    params: IOphalenBulkberichtvoorkeurenParams,
  ): Promise<IOphalenBulkberichtenvoorkeurenResult> => {
    return await api.post('/v2/bulkbericht/voorkeur/ophalen-bulkberichtvoorkeuren', params);
  },
  checkToevoegenBulkberichtvoorkeur: async (
    params: IToevoegenBulkberichtvoorkeurParams,
  ): Promise<ICheckData> => {
    return await api.post('/v2/bulkbericht/voorkeur/check-toevoegen-bulkberichtvoorkeur', params);
  },
  toevoegenBulkberichtvoorkeur: async (
    params: IToevoegenBulkberichtvoorkeurParams,
  ): Promise<IToevoegenBulkberichtvoorkeurResult> => {
    return await api.post('/v2/bulkbericht/voorkeur/toevoegen-bulkberichtvoorkeur', params);
  },
  checkWijzigenBulkberichtvoorkeur: async (
    params: IWijzigenBulkberichtvoorkeurParams,
  ): Promise<ICheckData> => {
    return await api.post('/v2/bulkbericht/voorkeur/check-wijzigen-bulkberichtvoorkeur', params);
  },
  wijzigenBulkberichtvoorkeur: async (
    params: IWijzigenBulkberichtvoorkeurParams,
  ): Promise<IWijzigenBulkberichtvoorkeurResult> => {
    return await api.post('/v2/bulkbericht/voorkeur/wijzigen-bulkberichtvoorkeur', params);
  },
  checkVerwijderenBulkberichtvoorkeuren: async (
    params: IVerwijderenBulkberichtvoorkeurenParams,
  ): Promise<ICheckData> => {
    return await api.post(
      '/v2/bulkbericht/voorkeur/check-verwijderen-bulkberichtvoorkeuren',
      params,
    );
  },
  verwijderenBulkberichtvoorkeuren: async (
    params: IVerwijderenBulkberichtvoorkeurenParams,
  ): Promise<IVerwijderenBulkberichtvoorkeurenResult> => {
    return await api.post('/v2/bulkbericht/voorkeur/verwijderen-bulkberichtvoorkeuren', params);
  },
};

export default voorkeur;
