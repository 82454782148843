import React from 'react';
import styled from 'styled-components';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { IOphalenContractenResultElementV2 } from '../../../../../../../shared/src/api/v2/contract';
import GegevensLayout from '../../../../layout/GegevensLayout';
import { format } from 'date-fns';
import { IOphalenRelatiesResultElementV2 } from '../../../../../../../shared/src/api/v2/relatie';
import RelatieVisualisatie from '../../../../personalia/RelatieVisualisatie';
import ContractVisualisatie from '../..';
import { Kleur as EKleur } from '../../../../../bedrijfslogica/constanten';

const Root = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

interface IProps extends RouteComponentProps {
  contract: IOphalenContractenResultElementV2;
  relatie: IOphalenRelatiesResultElementV2;
}

const Data = (props: IProps) => {
  return (
    <Root>
      <h4>
        {props.contract.basis.Basisnummer}.{props.contract.Volgnummer}
      </h4>
      <div className="row col-12 d-flex">
        <div className="col-7">
          <GegevensLayout
            gegevens={[
              {
                label: 'Ingangsdatum',
                waarde: format(new Date(props.contract.Ingangsdatum), 'dd-MM-yyyy'),
              },
              {
                label: 'Einddatum',
                waarde:
                  props.contract.Einddatum !== null
                    ? format(new Date(props.contract.Einddatum), 'dd-MM-yyyy')
                    : '',
              },

              {
                label: 'Modelcode',
                waarde: props.contract.basis.productmodel.Modelcode,
              },
              {
                label: 'Modelnaam',
                waarde: props.contract.basis.productmodel.Modelnaam,
              },
              {
                label: 'Abonnement',
                waarde: props.contract.basis.abonnement.MinimaleTermijn + ' maanden',
              },
              {
                label: 'Verdieping',
                waarde: props.contract.basis.Verdieping,
              },
              null,
              {
                label: 'Relatienr',
                waarde: props.relatie.Relatienummer,
              },
              {
                label: 'Relatie',
                waarde: <RelatieVisualisatie relID={props.relatie.RelID} />,
              },
              {
                label: 'Geblokkeerd',
                waarde: props.relatie.Geblokkeerd ? (
                  <span style={{ color: EKleur.Rood }}>Ja</span>
                ) : (
                  <span>Nee</span>
                ),
              },
            ]}
            labelBreedte={115}
          />
        </div>
        <div className="col-5">
          <GegevensLayout
            gegevens={[
              {
                label: 'Wissel voor',
                waarde:
                  props.contract.basis.Contractwissel_CntID !== null ? (
                    <ContractVisualisatie cntID={props.contract.basis.Contractwissel_CntID} />
                  ) : (
                    <span>-</span>
                  ),
              },
              {
                label: 'Gewisseld door',
                waarde:
                  props.contract.ContractwisselDoor_CntID !== null ? (
                    <ContractVisualisatie cntID={props.contract.ContractwisselDoor_CntID} />
                  ) : (
                    <span>-</span>
                  ),
              },
              {
                label: 'Aantal gebr.',
                waarde: props.contract.basis.AantalGebruikers,
              },
              {
                label: 'Max. voor tarief',
                waarde: props.contract.basis.AantalGebruikersTarief,
              },
              {
                label: 'Max. aantal gebr.',
                waarde: props.contract.basis.AantalGebruikersMax,
              },
              {
                label: 'Trekschakelaar',
                waarde: props.contract.basis.AansluitenTrekschakelaar ? 'Ja' : 'Nee',
              },
              null,
              {
                label: 'Prolongatie start',
                waarde:
                  props.contract.prolongatie.Start !== null
                    ? format(new Date(props.contract.prolongatie.Start), 'dd-MM-yyyy')
                    : '',
              },
              {
                label: 'Geprol. tot',
                waarde:
                  props.contract.prolongatie.Tot !== null
                    ? format(new Date(props.contract.prolongatie.Tot), 'dd-MM-yyyy')
                    : '',
              },
              {
                label: 'Prolongatie eind',
                waarde:
                  props.contract.prolongatie.Eind !== null
                    ? format(new Date(props.contract.prolongatie.Eind), 'dd-MM-yyyy')
                    : '',
              },
            ]}
            labelBreedte={125}
          />
        </div>
      </div>
    </Root>
  );
};

export default withRouter(Data);
