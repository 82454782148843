import React from 'react';
import LoadingSpinner from '../../../../../../../components/Gedeeld/LoadingSpinner';
import { IWeergaveProps } from '../../../../../../../components/FilterBalkV2';
import Combobox from '../../../../../../../components/formulier/Combobox';
import { useOverzichtV2Store } from './store';
import { ERemoteDataState } from '../../../../../../../models/IRemoteData';
import { IFactuurKenmerk } from '../../../../../../../../../shared/src/api/v2/relatie/contract';

const FactuurkenmerkFilter = (weergaveProps: IWeergaveProps<any>) => {
  const store = useOverzichtV2Store();

  return (
    <span className="d-flex align-items-center">
      <span className="mr-3">Fact.ken.</span>
      {store.data.state === ERemoteDataState.Pending ? (
        <LoadingSpinner />
      ) : (
        <div className="d-flex align-items-center flex-fill">
          <Combobox
            geselecteerd={weergaveProps.data.length === 0 ? null : weergaveProps.data[0]}
            opties={store.data.data!.result.factuurKenmerken.map((x: IFactuurKenmerk) => {
              return { id: x.ID, label: x.Kenmerk };
            })}
            onSelectieChange={(x) => {
              weergaveProps.onDataChange(x === null ? [] : [x]);
              weergaveProps.setIsActief(true);
              weergaveProps.toepassen();
            }}
            style={{ minWidth: 175 }}
          />
        </div>
      )}
    </span>
  );
};

export default FactuurkenmerkFilter;
