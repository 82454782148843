import React, { useMemo } from 'react';
import FormatteerBedrag from '../../../../../MutatieBedrag';
import { IOphalenBtwTarievenResultElement } from '../../../../../../../../shared/src/api/v2/btw';

interface IProps {
  bedrag: number;
  btwSoortID: number | null;
  btwTarieven: IOphalenBtwTarievenResultElement[];
  peildatum: Date;
}

const BtwBedrag = (props: IProps) => {
  const btwTarief = useMemo(() => {
    if (props.btwSoortID === undefined || props.btwSoortID === null) {
      return null;
    }
    return props.btwTarieven.find((x) => {
      return (
        x.btwSoort.ID === props.btwSoortID &&
        props.peildatum >= new Date(x.Ingangsdatum) &&
        (x.Einddatum === null || props.peildatum < new Date(x.Einddatum))
      );
    })!;
  }, [props.btwSoortID, props.btwTarieven]);

  const bedragExBtw = useMemo(() => {
    if (btwTarief === null) {
      return null;
    }
    const factor = btwTarief.Percentage / 100 + 1;
    return props.bedrag - props.bedrag / factor;
  }, [props.bedrag, btwTarief]);

  if (bedragExBtw === null) {
    return <span>-&nbsp;-</span>;
  }

  return <FormatteerBedrag bedrag={bedragExBtw} />;
};

export default BtwBedrag;
