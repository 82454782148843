import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { observer } from 'mobx-react-lite';
import MenuLayout from '../../../../components/MenuLayout';
import useUrlState from '../../../../core/useUrlState';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import api from '../../../../api';
import ASPTabel from '../../../../components/tabel/ASPTabel';
import {
  ASPKolom,
  EAspKolomBreedteType,
  ESortering,
  IAspKolomSorteringItem,
} from '../../../../components/tabel/ASPTabel/types';
import { IOphalenBtwTarievenResultElement } from '../../../../../../shared/src/api/v2/btw';
import { format } from 'date-fns';
import { Helmet } from 'react-helmet';
import { IconToevoegen } from '../../../../components/Icons';
import { Kleur as EKleur } from '../../../../bedrijfslogica/constanten';
import ToevoegenDialoog from './ToevoegenDialoog';
import { RootStoreContext } from '../../../../stores/RootStore';
import { EResultType } from '../../../../stores/CheckStore';
import { withRouter } from 'react-router-dom';
import WijzigenDialoog from './WijzigenDialoog';
import _ from 'lodash';

enum EKolom {
  BtwSoortCode,
  BtwSoortNaam,
  Ingangsdatum,
  Einddatum,
  Percentage,
}

export interface IExporterenDialoogState {}

export interface IToevoegenDialoogState {}

export interface IWijzigenDialoogState {
  id: number;
}

export interface IUrlState {
  // exporterenDialoogState: IExporterenDialoogState | null;
  selectie: number[];
  sortering: IAspKolomSorteringItem<EKolom>[];
  toevoegenDialoogState: IToevoegenDialoogState | null;
  wijzigenDialoogState: IWijzigenDialoogState | null;
}

interface IProps extends RouteComponentProps {}

const BtwTarieven: React.FC<IProps> = observer((props) => {
  const { checkStore } = useContext(RootStoreContext);

  const defaultUrlState = useMemo<IUrlState>(
    () => ({
      exporterenDialoogState: null,
      selectie: [],
      sortering: [
        {
          key: EKolom.BtwSoortCode,
          sortering: ESortering.Ascending,
        },
        {
          key: EKolom.Ingangsdatum,
          sortering: ESortering.Ascending,
        },
      ],
      toevoegenDialoogState: null,
      wijzigenDialoogState: null,
    }),
    [],
  );
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);

  const [btwTarieven, setBtwTarieven] = useState<IOphalenBtwTarievenResultElement[] | null>(null);

  const ophalenBtwTarieven = useCallback(async () => {
    const result = await api.v2.btw.ophalenBtwTarieven({
      filterSchema: {
        filters: [],
      },
      orderSchema: {
        orders: [
          { naam: 'ID', richting: 'ASC' },
          { naam: 'INGANGSDATUM', richting: 'ASC' },
          { naam: 'EINDDATUM', richting: 'ASC' },
        ],
      },
    });

    // const gesorteerdResult = _.orderBy(
    //   result.btwTarieven,
    //   [
    //     (x: IOphalenBtwTarievenResultElement) => x.btwSoort.SortNr,
    //     (x: IOphalenBtwTarievenResultElement) => x.Ingangsdatum,
    //     (x: IOphalenBtwTarievenResultElement) => x.Einddatum,
    //   ],
    //   ['asc', 'asc', 'asc'],
    // );

    setBtwTarieven(result.btwTarieven);
    // setBtwTarieven(gesorteerdResult.btwTarieven);
  }, []);

  useEffect(() => {
    ophalenBtwTarieven();
  }, [ophalenBtwTarieven]);

  const keyExtractor = useCallback((item: IOphalenBtwTarievenResultElement) => item.ID, []);

  const kolommen = useMemo<ASPKolom<EKolom, IOphalenBtwTarievenResultElement>[]>(
    () => [
      {
        key: EKolom.BtwSoortCode,
        label: 'Code',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 85,
        renderer: (item: IOphalenBtwTarievenResultElement) => item.btwSoort.Code,
      },
      {
        key: EKolom.BtwSoortNaam,
        label: 'Naam',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 185,
        renderer: (item: IOphalenBtwTarievenResultElement) => item.btwSoort.Naam,
      },
      {
        key: EKolom.Ingangsdatum,
        label: 'Ingangsdatum',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 125,
        renderer: (item: IOphalenBtwTarievenResultElement) =>
          format(new Date(item.Ingangsdatum), 'dd-MM-yyyy'),
        vergelijkingswaarde: (item: IOphalenBtwTarievenResultElement) => item.Ingangsdatum,
      },
      {
        key: EKolom.Einddatum,
        label: 'Einddatum',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 125,
        renderer: (item: IOphalenBtwTarievenResultElement) =>
          item.Einddatum !== null ? format(new Date(item.Einddatum), 'dd-MM-yyyy') : '',
        vergelijkingswaarde: (item: IOphalenBtwTarievenResultElement) => item.Einddatum,
      },
      {
        key: EKolom.Percentage,
        label: 'Percentage',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 125,
        renderer: (item: IOphalenBtwTarievenResultElement) => item.Percentage,
      },
    ],
    [],
  );

  return (
    <>
      <Helmet>
        <title>Btw-tarieven</title>
      </Helmet>
      <MenuLayout
        menu={
          <div className="d-flex">
            <button
              className="btn btn-sm btn-light d-flex align-items-center ml-2"
              style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
              onClick={() => {
                return setUrlStateSync('toevoegenDialoogState', {});
              }}
            >
              <IconToevoegen style={{ width: 16, height: 16, fill: EKleur.Grijs }} />
              <span className="ml-2">Toevoegen</span>
            </button>
          </div>
        }
        body={
          <div className="d-flex flex-column flex-fill">
            {btwTarieven === null ? (
              <div className="flex-fill d-flex align-items-center justify-content-center">
                <LoadingSpinner />
              </div>
            ) : (
              <div className="d-flex flex-column flex-fill">
                <ASPTabel
                  rijen={btwTarieven}
                  kolommen={kolommen}
                  keyExtractor={keyExtractor}
                  selectie={urlState.selectie}
                  onSelectieChange={(selectie) => setUrlStateSync('selectie', selectie)}
                  sortering={urlState.sortering}
                  onSorteringChange={(sortering) => setUrlStateSync('sortering', sortering)}
                  onWijzigenRij={async (x) => {
                    setUrlStateSync('wijzigenDialoogState', { id: x.ID });
                  }}
                  onVerwijderenRij={async (x) => {
                    const params = {
                      ids: [x.ID],
                    };
                    await api.v2.btw.verwijderenBtwtarieven(params);
                    await ophalenBtwTarieven();
                  }}
                  verwijderenRijConfirmatie={async (x, verwijderen) => {
                    const params = {
                      ids: [x.ID],
                    };

                    const checkData = await api.v2.btw.checkVerwijderenBtwtarieven(params);

                    const checkResult = await checkStore.controleren({
                      checkData,
                    });
                    if (checkResult.type === EResultType.Annuleren) {
                      return;
                    }

                    if (
                      (
                        await checkStore.bevestigen({
                          inhoud: 'Bevestig verwijderen btw-tarief',
                        })
                      ).type === EResultType.Annuleren
                    ) {
                      return;
                    }

                    await verwijderen();
                  }}
                  lokaalSorteren
                />
              </div>
            )}
          </div>
        }
      />
      {urlState.toevoegenDialoogState !== null && (
        <ToevoegenDialoog
          open
          onSuccess={() => {
            ophalenBtwTarieven();
            setUrlStateSync('toevoegenDialoogState', null);
          }}
          onAnnuleren={() => {
            setUrlStateSync('toevoegenDialoogState', null);
          }}
        />
      )}
      {urlState.wijzigenDialoogState !== null && (
        <WijzigenDialoog
          open
          id={urlState.wijzigenDialoogState.id}
          onSuccess={() => {
            ophalenBtwTarieven();
            setUrlStateSync('wijzigenDialoogState', null);
          }}
          onAnnuleren={() => {
            setUrlStateSync('wijzigenDialoogState', null);
          }}
        />
      )}
    </>
  );
});

export default withRouter(BtwTarieven);
