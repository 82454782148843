import api from '../../../..';
import {
  IOphalenFacturenParams,
  IOphalenFacturenResult,
} from '../../../../../../../shared/src/api/v2/boekhouding/activaregister/facturen';
import ICheckData from '../../../../../../../shared/src/models/ICheckData';

const facturen = {
  ophalenFacturen: async (params: IOphalenFacturenParams): Promise<IOphalenFacturenResult> => {
    return await api.post('/v2/boekhouding/activaregister/facturen/ophalen-facturen', params);
  },
};

export default facturen;
