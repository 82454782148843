import React, { useContext, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import LoadingSpinner from '../../../components/Gedeeld/LoadingSpinner';
import GegevensLayout from '../../../components/layout/GegevensLayout';
import { RootStoreContext } from '../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import {
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../helpers/dxTableGrid';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableColumnResizing,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import api from '../../../api';
import { IOphalenEventsResultElement } from '../../../../../shared/src/api/v2/appEvent';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { format } from 'date-fns';
import RelatieVisualisatie from '../../../components/personalia/RelatieVisualisatie';
import { Kleur as EKleur, mutatiebronIconMap } from '../../../bedrijfslogica/constanten';
import { EMutatiebron } from '../../../bedrijfslogica/enums';

interface IProps extends RouteComponentProps {}

export interface IRow extends IOphalenEventsResultElement {}

const Events: React.FC<IProps> = observer((props) => {
  const kolommen = useMemo(() => {
    return [
      {
        name: 'Datum',
        title: 'Datum',
      },
      {
        name: 'EventNaam',
        title: 'Naam',
      },
      {
        name: 'EventnaamEnum',
        title: 'NaamEnum',
      },
      {
        name: '__mutatiebron' as any,
        title: 'Mutatiebron',
      },
      {
        name: '__relatie' as any,
        title: 'Relatie',
      },
      {
        name: 'Data',
        title: 'Data',
      },
    ];
  }, []);

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRow>[]>(
    () => [
      {
        columnName: 'EventNaam',
        width: 250,
      },
      {
        columnName: 'EventnaamEnum',
        width: 275,
      },
      {
        columnName: '__mutatiebron' as any,
        width: 100,
      },
      {
        columnName: '__relatie' as any,
        width: 250,
      },
      {
        columnName: 'Data',
        width: 475,
      },
      {
        columnName: 'Datum',
        width: 200,
      },
    ],
    [],
  );

  const [events, setEvents] = useState<IRow[] | null>(null);

  useEffect(() => {
    (async () => {
      const data = await api.v2.appEvent.ophalenEvents({
        filterSchema: { filters: [] },
        orderSchema: { orders: [{ naam: 'DATUM', richting: 'DESC' }] },
      });
      setEvents(data.events);
    })();
  }, []);

  return (
    <>
      <div className="bg-white" style={{ height: 'calc(100vh - 40px)' }}>
        {events === null ? (
          <div className="d-flex flex-fill align-items-center justify-content-center p-4">
            <LoadingSpinner />
          </div>
        ) : (
          <GridStyleWrapper height={`calc(100vh - 40px)`}>
            <Grid columns={kolommen} rows={events}>
              <DataTypeProvider
                for={['__relatie']}
                formatterComponent={(props) => {
                  return <RelatieVisualisatie relID={props.row.RelID} />;
                }}
              />

              <DataTypeProvider
                for={['Datum']}
                formatterComponent={(props) => {
                  const datum =
                    props.value !== null
                      ? format(new Date(props.value), 'dd-MM-yyyy HH:mm:ss')
                      : '';
                  return <span>{datum}</span>;
                }}
              />

              {/* <DataTypeProvider
                for={['__mutatiebron']}
                formatterComponent={(props) => {
                  const rij: IOphalenEventsResultElement = props.row;
                  return <span>{rij.Mutatiebron}</span>;
                }}
              /> */}

              <DataTypeProvider
                for={['__mutatiebron']}
                formatterComponent={(formatterProps) => {
                  const row: IRow = formatterProps.row;
                  const Icon = mutatiebronIconMap[row.Mutatiebron as EMutatiebron];
                  return (
                    <span>
                      <Icon style={{ width: 18, height: 18, fill: EKleur.Grijs }} />
                    </span>
                  );
                }}
              />

              <VirtualTable />
              <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
              <TableHeaderRow />
            </Grid>
          </GridStyleWrapper>
        )}
      </div>
    </>
  );
});

export default Events;
