import { makeObservable, observable } from 'mobx';
import { IOphalenLocatieResult } from '../../../shared/src/api/v2/locatie/locatie';
import api from '../api';
import { RootStore } from './RootStore';

// tslint:disable no-console
class LocatieStore {
  public locaties: Record<number, IOphalenLocatieResult> = {};

  public locatiesOphaalQueue: number[] = [];
  // tslint:disable-next-line:variable-name
  private _locIDQueueTimeout: number | null = null;
  // tslint:disable-next-line:variable-name
  private _rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this._rootStore = rootStore;

    makeObservable(this, {
      locaties: observable,
      locatiesOphaalQueue: observable,
    });
  }

  private enqueueOphalenLocaties = (locIDs: number[]) => {
    this.locatiesOphaalQueue = [...this.locatiesOphaalQueue, ...locIDs];
    if (this._locIDQueueTimeout !== null) {
      clearTimeout(this._locIDQueueTimeout);
    }
    // @ts-ignore
    this._locIDQueueTimeout = setTimeout(this.requestOphalenLocaties, 300);
  };

  private requestOphalenLocaties = async () => {
    const queue = this.locatiesOphaalQueue;
    const result = await api.v2.locatie.ophalenLocaties({
      paginatie: {
        index: 0,
        aantal: 100,
      },
      filterSchema: { filters: [{ naam: 'IDS', data: queue }] },
    });
    const locaties = { ...this.locaties };
    result.locaties.forEach((loc) => {
      locaties[loc.LocID] = loc;
    });
    this.locaties = locaties;
    this.locatiesOphaalQueue = [];
  };

  public ophalenLocaties = async (locIDs: number[]) => {
    const opgehaaldeLocIDs = Object.keys(this.locaties).map((x) => Number(x));
    const huidigeQueueLocIDs = this.locatiesOphaalQueue;

    const uitgeslotenLocIDs: number[] = [...opgehaaldeLocIDs, ...new Set(huidigeQueueLocIDs)];
    // LocIDs die nog niet voorkomen in de uitgesloten lijst
    const actieVereisteLocIDs = locIDs.filter(
      (locID) => uitgeslotenLocIDs.findIndex((x) => x === locID) === -1,
    );
    if (actieVereisteLocIDs.length === 0) {
      return;
    }

    this.enqueueOphalenLocaties(actieVereisteLocIDs);
  };
}

export default LocatieStore;
