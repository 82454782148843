import { format, addMonths, startOfMonth } from 'date-fns';
import { nl } from 'date-fns/locale';
import moment from 'moment';

export const verwijderTijdzone = (datum: Date): string => {
  return datum.toString().replace('Z', '');
};

export const berekenMinimaleDatumOvernemen = (): Date => {
  const volgendeMaand = addMonths(new Date(), 1);
  const eersteVanVolgendeMaand = startOfMonth(volgendeMaand);
  const datumGeformatteerd = format(eersteVanVolgendeMaand, 'yyyy-MM-dd');
  return new Date(datumGeformatteerd);
};

export const verwijderTijd = (datum: Date) => {
  return new Date(datum.getFullYear(), datum.getMonth(), datum.getDate(), 0, 0, 0, 0);
};

export const vandaagZonderTijd = () => verwijderTijd(new Date());

export const formatteerDatum = (date: Date, formaat: string) => {
  return format(date, formaat, {
    locale: nl,
  });
};

// export const datumsZijnOpDezelfdeDag = (first: Date, second: Date) =>
//   first.getFullYear() === second.getFullYear() &&
//   first.getMonth() === second.getMonth() &&
//   first.getDate() === second.getDate();
export const datumsZijnOpDezelfdeDag = (first: Date, second: Date) => {
  const a = dagDatum(first);
  const b = dagDatum(second);
  return a.toISOString() === b.toISOString();
};

export const isDagDatum = (waarde: Date): boolean => {
  const isoFormaat = waarde.toISOString();
  return isoFormaat.endsWith('T00:00:00.000Z');
};

// new Date() -> 2020-09-20T00:00:00.000Z

/**
 * Normaliseert een datum in UTC vorm
 * @param waarde
 */
export const dagDatum = (waarde: Date): Date => {
  // Is de gekozen datum al gekozen op een UTC tijd?
  if (isDagDatum(waarde)) {
    return waarde;
  }
  // Datum niet gekozen op UTC tijd, corrigeren met lokale tijdzone offset
  // const utcOffsetMinute = moment().utcOffset();
  // const date = moment(waarde)
  //   .add(utcOffsetMinute, 'minutes')
  //   .utc()
  //   .toDate();
  const jaar = waarde.getFullYear();
  const maand = (waarde.getMonth() + 1).toString().padStart(2, '0');
  const dag = waarde
    .getDate()
    .toString()
    .padStart(2, '0');
  const x = `${jaar}-${maand}-${dag}T00:00:00.000Z`;
  return new Date(x);
  // return date;
};

export const dagDatumOfNu = (waarde: Date | string | undefined | null): Date => {
  return dagDatum(waarde == undefined ? new Date() : new Date(waarde));
};

export const dagDatumOfNull = (waarde: Date | string | undefined | null): Date | null => {
  return waarde ? dagDatum(new Date(waarde)) : null;
};

export const dagDatumOfUndefined = (waarde: Date | string | undefined | null): Date | undefined => {
  return waarde ? dagDatum(new Date(waarde)) : undefined;
};
