import React, { useCallback, useContext, useMemo, useState } from 'react';
import { IProps } from '@tinymce/tinymce-react/lib/cjs/main/ts/components/Editor';
import { BoekingContext, IContext } from '../index';
import LoadingSpinner from '../../../../Gedeeld/LoadingSpinner';
import { format } from 'date-fns';
import GegevensLayout from '../../../../layout/GegevensLayout';
import FormatteerBedrag from '../../../../MutatieBedrag';
import VeldWeergave from '../../../../formulier/VeldWeergave';
import FactuurVisualisatie from '../../../../entiteitVisualisaties/FactuurVisualisatie';
import InkoopfactuurVisualisatie from '../../../../entiteitVisualisaties/InkoopfactuurVisualisatie';
import FactuurinfoDialoog from '../../../../factuur/FactuurinfoDialoog';
import RelatieVisualisatie from '../../../../personalia/RelatieVisualisatie';
import { IconInformatie } from '../../../../Icons';
import { Kleur as EKleur } from '../../../../../bedrijfslogica/constanten';
import { FactuurinfoDialoog as InkoopfactuurinfoDialoog } from '../../../../inkoopfactuur/FactuurinfoDialoog';

export interface IFactuurinfoDialoogState {
  factID: number;
}
export interface IInkoopfactuurinfoDialoogState {
  inkFactID: number;
}

const BoekingenTab: React.FC<IProps> = (props) => {
  const { boekingregels, inkoopfacturen, facturen, boekingregelID } = useContext<IContext>(
    BoekingContext,
  );

  const [
    factuurinfoDialoogState,
    setFactuurinfoDialoogState,
  ] = useState<IFactuurinfoDialoogState | null>(null);

  const [
    inkoopfactuurinfoDialoogState,
    setInkoopfactuurinfoDialoogState,
  ] = useState<IInkoopfactuurinfoDialoogState | null>(null);

  if (boekingregels === null || facturen === null || inkoopfacturen === null) {
    return <LoadingSpinner />;
  }

  const regel = boekingregels.find((x) => x.ID === boekingregelID)!;

  return (
    <>
      <div className="d-flex flex-column">
        <div className="mt-2 pl-3 pr-3 pt-2">
          <div className="d-flex">
            <div style={{ flex: 1 }}>
              <VeldWeergave>
                <GegevensLayout
                  gegevens={[
                    { label: 'Regel', waarde: regel.Regelnummer.toString() },
                    { label: 'Bedrag', waarde: <FormatteerBedrag bedrag={regel.Bedrag} /> },
                    { label: 'Storno', waarde: regel.Storno ? 'Ja' : 'Nee' },
                    null,
                    {
                      label: 'Btw-code',
                      waarde: regel.btwsoort !== null ? regel.btwsoort.Code.toString() : '',
                    },
                    {
                      label: 'Btw-percentage',
                      waarde:
                        regel.BtwPercentage !== null ? regel.BtwPercentage.toString() + '%' : '',
                    },
                    {
                      label: 'Btw-bedrag',
                      waarde:
                        regel.BtwBedrag !== null ? (
                          <FormatteerBedrag bedrag={regel.BtwBedrag} />
                        ) : (
                          ''
                        ),
                    },
                  ]}
                />
              </VeldWeergave>
            </div>
            <div style={{ minWidth: 20 }} />
            <div style={{ flex: 1 }}>
              <VeldWeergave>
                <GegevensLayout
                  gegevens={[
                    {
                      label: 'Relatie',
                      waarde:
                        regel.RelID !== null ? <RelatieVisualisatie relID={regel.RelID} /> : '-',
                    },
                    {
                      label: 'Inkoopfactuur',
                      waarde: (
                        <div className="d-flex">
                          {regel.InkFactID !== null ? (
                            <span className="d-flex">
                              {' '}
                              <InkoopfactuurVisualisatie inkFactID={regel.InkFactID} />
                              <span className="ml-2">
                                {' '}
                                <a
                                  href="#"
                                  onClick={() => {
                                    setInkoopfactuurinfoDialoogState({
                                      inkFactID: regel.InkFactID!,
                                    });
                                  }}
                                >
                                  <IconInformatie
                                    style={{ width: 15, height: 15, fill: EKleur.Blauw }}
                                  />
                                </a>
                              </span>
                            </span>
                          ) : (
                            '-'
                          )}
                        </div>
                      ),
                    },
                    {
                      label: 'Verkoopfactuur',
                      waarde: (
                        <div className="d-flex">
                          {regel.FactID !== null ? (
                            <span className="d-flex">
                              {' '}
                              <FactuurVisualisatie factID={regel.FactID} />
                              <span className="ml-2">
                                {' '}
                                <a
                                  href="#"
                                  onClick={() => {
                                    setFactuurinfoDialoogState({
                                      factID: regel.FactID!,
                                    });
                                  }}
                                >
                                  <IconInformatie
                                    style={{ width: 15, height: 15, fill: EKleur.Blauw }}
                                  />
                                </a>
                              </span>
                            </span>
                          ) : (
                            '-'
                          )}
                        </div>
                      ),
                    },
                    null,
                    {
                      label: 'WKR-regeling',
                      waarde:
                        regel.werkkostenregeling !== null ? regel.werkkostenregeling.Naam : '-',
                    },
                    null,
                    null,
                  ]}
                />
              </VeldWeergave>
            </div>
          </div>
        </div>
        <div className="mt-2 pl-3 pr-3 pt-2">
          {' '}
          <VeldWeergave>
            <GegevensLayout
              gegevens={[
                {
                  label: 'Debiteren',
                  waarde:
                    regel.dbRekening.Nummer +
                    ' - ' +
                    regel.dbRekening.Naam +
                    ' (' +
                    regel.dbRekening.Soort +
                    ')',
                },
                {
                  label: 'Crediteren',
                  waarde:
                    regel.crRekening.Nummer +
                    ' - ' +
                    regel.crRekening.Naam +
                    ' (' +
                    regel.crRekening.Soort +
                    ')',
                },
              ]}
            />
          </VeldWeergave>
        </div>
        <div className="mt-2 pl-3 pr-3 pt-2">
          {' '}
          <VeldWeergave>
            <GegevensLayout
              gegevens={[
                { label: 'Omschrijving', waarde: regel.Omschrijving },
                {
                  label: 'Bij factuur',
                  waarde:
                    regel.InkFactID !== null
                      ? inkoopfacturen.find((x) => x.InkFactID === regel.InkFactID)!.Onderwerp
                      : null,
                },
              ]}
            />
          </VeldWeergave>
        </div>
      </div>
      {factuurinfoDialoogState !== null && (
        <FactuurinfoDialoog
          open
          factID={factuurinfoDialoogState.factID}
          onSuccess={() => {
            setFactuurinfoDialoogState(null);
          }}
          onAnnuleren={() => setFactuurinfoDialoogState(null)}
        />
      )}
      {inkoopfactuurinfoDialoogState !== null && (
        <InkoopfactuurinfoDialoog
          open
          inkFactID={inkoopfactuurinfoDialoogState.inkFactID}
          onSuccess={() => {
            setInkoopfactuurinfoDialoogState(null);
          }}
          onAnnuleren={() => setInkoopfactuurinfoDialoogState(null)}
        />
      )}
    </>
  );
};

export default BoekingenTab;
