import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import api from '../../../../../../../api';
import LoadingSpinner from '../../../../../../../components/Gedeeld/LoadingSpinner';
import { observer } from 'mobx-react-lite';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import useUrlState from '../../../../../../../core/useUrlState';
import { RootStoreContext } from '../../../../../../../stores/RootStore';
import {
  DXTableCheckboxComponent,
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
  DXTableToggleCellComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../../../../helpers/dxTableGrid';
import { Kleur } from '../../../../../../../bedrijfslogica/constanten';
import {
  Grid,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  DataTypeProvider,
  EditingState,
  IntegratedSorting,
  RowDetailState,
  SelectionState,
  SortingState,
  VirtualTable as VirtualTableBase,
} from '@devexpress/dx-react-grid';
import { addMonths, format } from 'date-fns';
import FormatteerBedrag from '../../../../../../../components/MutatieBedrag';
import { EResultType } from '../../../../../../../stores/CheckStore';
import { IconSend, IconToevoegen, IconVerwijderen } from '../../../../../../../components/Icons';
import * as _ from 'lodash';
import MenuLayout from '../../../../../../../components/MenuLayout';
import FilterBalkV2, {
  IFilter,
  IFilterData,
  maakFilterSchema,
} from '../../../../../../../components/FilterBalkV2';
import { IOphalenProductenResultElementV2 } from '../../../../../../../../../shared/src/api/v2/product';
import { maandenNaarJaren } from '../../../../../../../bedrijfslogica/teksten';
import ToevoegenAdviesDialoog from '../ToevoegenAdviesDialoog';
import { EContractstatus, EProductstatus } from '../../../../../../../bedrijfslogica/enums';
import { IOphalenContractenResultElementV2 } from '../../../../../../../../../shared/src/api/v2/contract';
import ContractVisualisatie from '../../../../../../../components/entiteitVisualisaties/ContractVisualisatie';

interface IProps extends RouteComponentProps {
  relID: number;
}

enum EFilter {
  LeeftijdgrensBereikt = 'LEEFTIJDGRENS_BEREIKT',
}

export interface IToevoegenDialoogState {
  prodIDs: number[];
}

interface IUrlState {
  selectie: number[];
  filterdata: IFilterData<EFilter>[];
  toevoegenDialoogState: IToevoegenDialoogState | null;
}

const defaultUrlState: IUrlState = {
  selectie: [],
  filterdata: [
    {
      naam: EFilter.LeeftijdgrensBereikt,
      isActief: true,
      data: true,
    },
  ],
  toevoegenDialoogState: null,
};

const geenData = {
  noData: 'Geen adviezen aanwezig',
};

export interface IRegel extends IOphalenProductenResultElementV2 {
  CntID: number;
}

const AdviezenComp: React.FC<IProps> = observer((props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState<IUrlState>(props, defaultUrlState);
  const { klantkaartStore, checkStore } = useContext(RootStoreContext);

  const [contracten, setContracten] = useState<IOphalenContractenResultElementV2[] | null>(null);

  const ophalenContracten = useCallback(async () => {
    // const peildatum = addMonths(new Date(), -2);

    const contracten = (
      await api.v2.contract.ophalenContractenV2({
        filterSchema: {
          filters: [
            { naam: 'REL_IDS', data: [props.relID] },
            { naam: 'STATUS_NAAM_ENUMS', data: [EContractstatus.Lopend] },
          ],
        },
      })
    ).contracten;
    setContracten(contracten);
  }, []);

  useEffect(() => {
    ophalenContracten();
  }, [ophalenContracten]);

  const [producten, setProducten] = useState<IRegel[] | null>(null);

  const ophalenProducten = useCallback(async () => {
    if (contracten === null) {
      return;
    }

    if (contracten.length === 0) {
      setProducten([]);
      return;
    }

    const cntBasisIDs = contracten.map((x) => x.basis.CntBasisID);

    const productenResult = await api.v2.product.ophalenProductenV2({
      filterSchema: {
        filters: [
          ...filterSchema.filters!,
          {
            naam: 'CNTBASIS_IDS',
            data: [cntBasisIDs],
          },
          {
            naam: 'PROD_STAT_NAAM_ENUMS',
            data: [EProductstatus.Verhuur],
          },
        ],
      },
      orderSchema: {
        orders: [
          {
            naam: 'DATUM_IN_BEDRIJF',
            richting: 'ASC',
          },
        ],
      },
    });

    const producten = productenResult.producten.map((x) => {
      return { ...x, CntID: contracten.find((c) => c.basis.CntBasisID === x.CntBasisID)!.CntID };
    });

    setProducten(producten);
  }, [contracten, props.relID, JSON.stringify(urlState.filterdata)]);

  useEffect(() => {
    ophalenProducten();
  }, [ophalenProducten]);

  // Mogelijk te kiezen jaren
  const jaren = useMemo(() => {
    let jaren: number[] = [];

    let jaar: number = 1;

    let i = 1;
    while (i <= 10) {
      jaren.push(jaar);
      jaar += 1;
      i++;
    }

    return jaren;
  }, []);

  const keyExtractor = useCallback((row: IRegel) => row.ProdID, []);

  const kolommen = useMemo<TypedColumn<IRegel>[]>(
    () => [
      {
        name: '__merknaam' as any,
        title: 'Merk',
      },
      {
        name: '__typenaam' as any,
        title: 'Type',
      },
      {
        name: '__productsoortnaamKort' as any,
        title: 'Cat.',
      },
      {
        name: 'Referentiecode',
        title: 'Ref.code',
      },
      {
        name: '__leeftijd' as any,
        title: 'Leeftijd',
        getCellValue: (x) => {
          return x.leeftijd;
        },
      },
      {
        name: '__contractnummer' as any,
        title: 'Contract',
      },
      {
        name: '__locatie' as any,
        title: 'Locatie',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRegel>[]>(
    () => [
      {
        columnName: '__merknaam' as any,
        width: 115,
      },
      {
        columnName: '__typenaam' as any,
        width: 150,
      },
      {
        columnName: '__productsoortnaamKort' as any,
        width: 80,
      },
      {
        columnName: 'Referentiecode',
        width: 110,
      },
      {
        columnName: '__leeftijd' as any,
        width: 100,
      },
      {
        columnName: '__contractnummer' as any,
        width: 100,
      },
      {
        columnName: '__locatie' as any,
        width: 300,
      },
    ],
    [],
  );

  const kolomExtensies: VirtualTableBase.ColumnExtension[] = useMemo(() => {
    return [
      {
        columnName: 'Bedrag',
        align: 'right',
      },
    ];
  }, []);

  const handleVerversenAangevraagd = useCallback(async () => {
    await ophalenProducten();
  }, [ophalenProducten]);

  const [filterSchema, setFilterSchema] = useState(maakFilterSchema(urlState.filterdata));

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.LeeftijdgrensBereikt,
        altijdWeergevenInBalk: true,
        actiefMuteerbaar: true,
        weergave: (weergaveProps) => {
          return <span>Leeftijdgrens bereikt</span>;
        },
      },
    ],
    [],
  );

  return (
    <>
      <MenuLayout
        menu={
          <div className="d-flex flex-column">
            <div className="d-flex">
              <div className="d-flex align-items-center">
                <button
                  className="btn btn-sm btn-light d-flex align-items-center"
                  style={{
                    border: `1px solid ${Kleur.LichtGrijs}`,
                  }}
                  disabled={urlState.selectie.length === 0}
                  onClick={async () => {
                    const params = { relID: props.relID, prodIDs: urlState.selectie };
                    const checkData = await api.v2.product.advies.checkToevoegenProductAdvies(
                      params,
                    );
                    if (
                      (await checkStore.controleren({ checkData })).type === EResultType.Annuleren
                    ) {
                      return;
                    }

                    return setUrlStateSync('toevoegenDialoogState', {
                      prodIDs: urlState.selectie,
                    });
                  }}
                >
                  <IconToevoegen
                    style={{
                      width: 16,
                      height: 16,
                      fill: Kleur.Grijs,
                    }}
                  />
                  <span className="ml-2">Nieuw advies</span>
                </button>
              </div>

              <div className="ml-3 flex-fill">
                <FilterBalkV2
                  filters={filters}
                  filterData={urlState.filterdata}
                  onFilterDataChange={(x) => setUrlStateSync('filterdata', x)}
                  onFilterSchemaChange={setFilterSchema}
                />
              </div>
            </div>
          </div>
        }
        body={
          producten === null ? (
            <div className="d-flex flex-fill justify-content-center align-items-center">
              <LoadingSpinner />
            </div>
          ) : (
            <>
              <GridStyleWrapper height="calc(100vh - 150px)">
                <Grid columns={kolommen} getRowId={keyExtractor} rows={producten!}>
                  <DataTypeProvider
                    for={['__leeftijd']}
                    formatterComponent={(formatterProps) => {
                      const rij: IRegel = formatterProps.row;

                      if (rij.leeftijd === null) {
                        return <span></span>;
                      }
                      return <span>{maandenNaarJaren(rij.leeftijd)}</span>;
                    }}
                  />

                  <DataTypeProvider
                    for={['__contractnummer']}
                    formatterComponent={(formatterProps) => {
                      const rij: IRegel = formatterProps.row;
                      return <ContractVisualisatie cntID={rij.CntID} />;
                    }}
                  />

                  <DataTypeProvider
                    for={['__merknaam']}
                    formatterComponent={(formatterProps) => {
                      const rij: IRegel = formatterProps.row;
                      return <span>{rij.producttype.Merknaam}</span>;
                    }}
                  />

                  <DataTypeProvider
                    for={['__typenaam']}
                    formatterComponent={(formatterProps) => {
                      const rij: IRegel = formatterProps.row;
                      return <span>{rij.producttype.Typenaam}</span>;
                    }}
                  />

                  <DataTypeProvider
                    for={['__productsoortnaamKort']}
                    formatterComponent={(formatterProps) => {
                      const rij: IRegel = formatterProps.row;
                      return <span>{rij.producttype.ProductsoortnaamKort}</span>;
                    }}
                  />

                  <DataTypeProvider
                    for={['__locatie']}
                    formatterComponent={(formatterProps) => {
                      const rij: IRegel = formatterProps.row;
                      if (rij.locatie === null) {
                        return <span></span>;
                      }

                      const locatie =
                        rij.locatie.Straatnaam +
                        ' ' +
                        rij.locatie.Huisnummer +
                        (rij.locatie.Bisnummer !== null ? ' ' + rij.locatie.Bisnummer : '') +
                        ', ' +
                        rij.locatie.Plaatsnaam;
                      return <span>{locatie}</span>;
                    }}
                  />

                  <SortingState defaultSorting={[]} />
                  <IntegratedSorting />

                  <VirtualTable messages={geenData} columnExtensions={kolomExtensies} />

                  <RowDetailState defaultExpandedRowIds={[]} />

                  {/* <EditingState
                      onAddedRowsChange={() => {}}
                      onCommitChanges={() => null}
                      onEditingRowIdsChange={(rowIds) => {
                        const id = rowIds[rowIds.length - 1] as number;
                      }}
                    /> */}

                  {/* <TableEditColumn
                      width={65}
                      // showAddCommand={true}
                      showEditCommand
                      showDeleteCommand
                      cellComponent={DXTableEditColumnCellComponent}
                      commandComponent={DXTableEditColumnCommandComponent}
                    /> */}

                  <SelectionState
                    selection={urlState.selectie}
                    onSelectionChange={(value) => setUrlStateSync('selectie', value as number[])}
                  />
                  {/* <TableRowDetail
                      contentComponent={RowDetailComponent}
                      toggleCellComponent={DXTableToggleCellComponent}
                    /> */}
                  <TableSelection cellComponent={DXTableCheckboxComponent} />

                  <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
                  <TableHeaderRow showSortingControls />
                </Grid>
              </GridStyleWrapper>
            </>
          )
        }
      />

      {urlState.toevoegenDialoogState && (
        <ToevoegenAdviesDialoog
          open
          relID={props.relID}
          prodIDs={urlState.toevoegenDialoogState.prodIDs}
          onSuccess={(x) => {
            setUrlStateSync('toevoegenDialoogState', null);
            ophalenProducten();

            props.history.push(`/klant/${props.relID}/productadvies/adviezen`);
          }}
          onAnnuleren={() => setUrlStateSync('toevoegenDialoogState', null)}
        />
      )}
    </>
  );
});

export default withRouter(AdviezenComp);
