import React, { useMemo } from 'react';
import { IOphalenBestandenResultElement } from '../../../../shared/src/api/v2/bestand/bestand';
import useUpload from '../../core/useUpload';
import BijlagenContainer, { BestandType, EWeergaveFocus } from '../BijlagenContainer';
import BijlageKnop, { EOrientatie, ESelectieModus } from '../BijlageKnop';

interface IProps {
  bijlagen: IOphalenBestandenResultElement[];
  onBestandIDsChange: (ids: number[]) => Promise<void>;
  selectiemodus?: ESelectieModus;
}

const BijlagenKolomWeergave = (props: IProps) => {
  const { isBezigMetUploaden, uploadProgresses, muteerBestanden, bestanden } = useUpload(
    useMemo(
      () => ({
        automatischUploaden: true,
        onAlleBestandenGeuploaded: props.onBestandIDsChange,
        initieleBestanden: props.bijlagen.map((x) => ({
          type: BestandType.ASPDrive,
          bestand: x,
        })),
      }),
      [props.bijlagen, props.onBestandIDsChange],
    ),
  );

  return (
    <div className="d-flex align-items-center">
      <BijlagenContainer
        bestanden={bestanden}
        uploadProgresses={uploadProgresses}
        onBestandenChange={async (bestanden) => {
          await muteerBestanden((x) => bestanden);
        }}
        weergaveFocus={EWeergaveFocus.Visualisatie}
        bestandenMuterenToegestaan={!isBezigMetUploaden}
        geenBestandenWeergaveComponent={() => (
          <span>Geen bijlage{props.selectiemodus === ESelectieModus.Enkel ? '' : 'n'} gekozen</span>
        )}
      />
      <div className="flex-fill" />
      <BijlageKnop
        onBijgevoegd={(bijlagen) => muteerBestanden((bestanden) => [bijlagen[0]])}
        orientatie={EOrientatie.Horizontaal}
        selectieModus={props.selectiemodus}
        disabled={isBezigMetUploaden}
      />
    </div>
  );
};

export default BijlagenKolomWeergave;
