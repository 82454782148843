import React, { useContext, useEffect, useMemo, useState, useCallback } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import MenuLayout from '../../../components/MenuLayout';
import LoadingSpinner from '../../../components/Gedeeld/LoadingSpinner';
import useUrlState from '../../../core/useUrlState';
import api from '../../../api';
import {
  IOphalenRelatiesResultElementV1,
  IOphalenRelatiesResultElementV2,
} from '../../../../../shared/src/api/v2/relatie';
import { IOphalenMutatiesResultElement } from '../../../../../shared/src/api/v2/relatie/mutatie';
import {
  DXTableToggleCellComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../helpers/dxTableGrid';
import { DataTypeProvider, RowDetailState } from '@devexpress/dx-react-grid';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  TableRowDetail,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import { addMonths, format } from 'date-fns';
import nameof from '../../../core/nameOf';
import { Kleur, mutatiebronIconMap } from '../../../bedrijfslogica/constanten';
import DetailComp from '../../Klant/Klantkaart/Entiteiten/entiteiten/Klant/Mutaties';
import _ from 'lodash';
import RelatieVisualisatie from '../../../components/personalia/RelatieVisualisatie';
import { EHoedanigheid } from '../../../components/personalia/RelatieSelectieDialoog';
import { EMutatiebron } from '../../../bedrijfslogica/enums';

interface IProps extends RouteComponentProps {}

interface IUrlState {}

const defaultUrlState: IUrlState = {};

interface IRegel extends IOphalenMutatiesResultElement {
  weergavenaam: string;
}

const Klantmutaties: React.FC<IProps> = observer((props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);

  const [mutaties, setMutaties] = useState<IOphalenMutatiesResultElement[] | null>(null);

  const ophalenMutaties = useCallback(async () => {
    const datumVan = addMonths(new Date(), -6); // Laatste 6 maanden

    const mutatiesResult = await api.v2.relatie.ophalenMutaties({
      filterSchema: {
        filters: [{ naam: 'DATUM_VAN', data: datumVan }].filter((x) => x !== null),
      },
    });

    const relIDs: number[] = _.uniq(mutatiesResult.mutaties.map((x) => x.RelID));

    const relatiesResult = await api.v2.relatie.ophalenRelaties({
      filterSchema: { filters: [{ naam: 'IDS', data: relIDs }] },
    });

    const mutaties = mutatiesResult.mutaties.map((mutatie) => {
      const relatie: IOphalenRelatiesResultElementV2 = relatiesResult.relaties.find(
        (x) => x.RelID === mutatie.RelID,
      )!;
      return { ...mutatie, weergavenaam: relatie.weergavenaam };
    });

    const mutatiesGesorteerd = _.orderBy(mutaties, ['Datum'], ['desc']);

    setMutaties(mutatiesGesorteerd);
  }, []);

  useEffect(() => {
    ophalenMutaties();
  }, [ophalenMutaties]);

  const keyExtractor = useCallback((row: IRegel) => row.ID, []);

  const kolommen = useMemo<TypedColumn<IRegel>[]>(
    () => [
      {
        name: 'Datum',
        title: 'Datum',
      },
      {
        name: 'RelID',
        title: 'Relatie',
      },
      {
        name: 'Mutatiebron',
        title: 'Bron',
      },
      {
        name: 'EventsoortNaam',
        title: 'Soort',
      },
      {
        name: 'IsHerstelling',
        title: 'Type',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRegel>[]>(
    () => [
      {
        columnName: 'Datum',
        width: 150,
      },
      {
        columnName: 'RelID',
        width: 250,
      },
      {
        columnName: 'EventsoortNaam',
        width: 180,
      },
      {
        columnName: 'Mutatiebron',
        width: 100,
      },
      {
        columnName: 'IsHerstelling',
        width: 110,
      },
    ],
    [],
  );

  return (
    <>
      {mutaties === null ? (
        <LoadingSpinner />
      ) : (
        <MenuLayout
          menu={<span></span>}
          body={
            <GridStyleWrapper height="calc(100vh - 150px)">
              <Grid rows={mutaties} columns={kolommen} getRowId={keyExtractor}>
                <DataTypeProvider
                  for={[nameof('RelID')]}
                  formatterComponent={(formatterProps) => {
                    const row = formatterProps.row as IRegel;
                    if (row.RelID === null) {
                      return <span />;
                    }
                    return (
                      <RelatieVisualisatie
                        relID={row.RelID}
                        relatieLinkBuilder={(hoedanigheid, relID) =>
                          `/klant/${relID}/klant/mutaties`
                        }
                      />
                    );
                  }}
                />

                <DataTypeProvider
                  for={[nameof<IRegel>('Datum')]}
                  formatterComponent={(formatterProps) => {
                    const row = formatterProps.row as IRegel;
                    return (
                      <span>
                        {row.Datum === null ? '' : format(new Date(row.Datum), 'dd-MM-yyyy HH:mm')}
                      </span>
                    );
                  }}
                />

                <DataTypeProvider
                  for={[nameof<IRegel>('Mutatiebron')]}
                  formatterComponent={(formatterProps) => {
                    const row: IRegel = formatterProps.row;
                    const Icon = mutatiebronIconMap[row.Mutatiebron as EMutatiebron];
                    return (
                      <span>
                        <Icon style={{ width: 18, height: 18, fill: Kleur.Grijs }} />
                      </span>
                    );
                  }}
                />

                <DataTypeProvider
                  for={['IsHerstelling']}
                  formatterComponent={(formatterProps) => {
                    const row: IRegel = formatterProps.row;
                    return <span>{row.IsHerstelling ? 'Herstelling' : ''}</span>;
                  }}
                />

                <VirtualTable
                  messages={{
                    noData: 'Geen mutaties',
                  }}
                />
                <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
                <TableHeaderRow />

                <RowDetailState defaultExpandedRowIds={[]} />
                {/* <TableRowDetail
                  toggleCellComponent={DXTableToggleCellComponent}
                  contentComponent={DetailComp}
                /> */}
              </Grid>
            </GridStyleWrapper>
          }
        />
      )}
    </>
  );
});

export default withRouter(Klantmutaties);
