import React from 'react';
import { Redirect, Route, RouteComponentProps } from 'react-router';
import RelatieDuplicaten from './Relatie';
import PersoonDuplicaten from './Persoon';

interface IProps extends RouteComponentProps<{}> {}

const Aanbod: React.FC<IProps> = (props) => {
  const { match } = props;
  return (
    <div className="d-flex flex-fill flex-column">
      <Route path={`${match.path}/relatie`} component={RelatieDuplicaten} />
      <Route path={`${match.path}/persoon`} component={PersoonDuplicaten} />

      <Route
        exact={true}
        path={match.path}
        render={() => <Redirect to={`${match.path}/duplicaten`} />}
      />
    </div>
  );
};

export default Aanbod;
