import React from 'react';
import { IDataFormulierProps } from '../index';

const ContractDataFormulier = (props: IDataFormulierProps<{ cntID: number | null }>) => {
  return (
    <div className="mt-3">
      <label>Contract</label>
    </div>
  );
};

export default ContractDataFormulier;
