import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { LeverancierContext } from '../../../..';
import { IDienstAfwezigheid } from '../../../../../../../../shared/src/api/v2/dienst/service/afwezigheid';
import api from '../../../../../../api';
import LoadingSpinner from '../../../../../../components/Gedeeld/LoadingSpinner';
import MenuLayout from '../../../../../../components/MenuLayout';
import ASPTabel from '../../../../../../components/tabel/ASPTabel';
import TableData, {
  ITableDataProps,
} from '../../../../../../components/tabel/ASPTabel/Body/TableData';
import { ASPKolom, EAspKolomBreedteType } from '../../../../../../components/tabel/ASPTabel/types';
import useUrlState from '../../../../../../core/useUrlState';
import IRemoteData, {
  ERemoteDataState,
  createPendingRemoteData,
  createReadyRemoteData,
} from '../../../../../../models/IRemoteData';
import WijzigenDialoog from './WijzigenDialoog';
import ToevoegenDialoog from './ToevoegenDialoog';
import { RootStoreContext } from '../../../../../../stores/RootStore';
import { EResultType } from '../../../../../../stores/CheckStore';
import { Kleur } from '../../../../../../bedrijfslogica/constanten';
import { IconToevoegen, IconVerwijderen } from '../../../../../../components/Icons';
import moment from 'moment';

type Kolom = 'afwezigVan' | 'afwezigTem';

interface IProps extends RouteComponentProps {}

interface IToevoegenDialoogState {}
interface IWijzigenDialoogState {
  id: number;
}

interface IUrlState {
  selectie: number[];
  toevoegenDialoogState: IToevoegenDialoogState | null;
  wijzigenDialoogState: IWijzigenDialoogState | null;
}

const defaultUrlState: IUrlState = {
  selectie: [],
  toevoegenDialoogState: null,
  wijzigenDialoogState: null,
};

const Afwezigheid: React.FC<IProps> = (props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const { checkStore, instellingStore } = useContext(RootStoreContext);

  const context = useContext(LeverancierContext);
  const servicedienst = context.servicedienst.data!;

  const [afwezigheden, setAfwezigheden] = useState<IRemoteData<IDienstAfwezigheid[]>>(
    createPendingRemoteData(),
  );

  const ophalenAfwezigheden = useCallback(async () => {
    let filterSchema = {
      filters: [{ naam: 'DIENST_IDS', data: [servicedienst.ID] }],
    };

    const result = await api.v2.dienst.service.afwezigheid.ophalenDienstAfwezigheden({
      filterSchema,
    });

    setAfwezigheden(createReadyRemoteData(result.afwezigheden));
  }, []);

  useEffect(() => {
    ophalenAfwezigheden();
  }, [ophalenAfwezigheden]);

  const handleToevoegen = useCallback(async () => {
    setUrlStateSync('toevoegenDialoogState', {});
  }, []);

  const handleVerwijderen = useCallback(async () => {
    const params = { webTekstIDs: urlState.selectie };
    if (
      (
        await checkStore.bevestigen({
          inhoud: <span>Geselecteerde teksten verwijderen?</span>,
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    const result = await api.v2.dienst.service.afwezigheid.verwijderenDienstAfwezigheid({
      IDs: urlState.selectie,
    });

    setUrlStateSync('selectie', []);

    await ophalenAfwezigheden();
  }, [urlState.selectie]);

  const keyExtractor = useCallback((row: IDienstAfwezigheid) => row.ID, []);

  const kolommen = useMemo<ASPKolom<Kolom, IDienstAfwezigheid>[]>(
    () => [
      {
        key: 'afwezigVan',
        label: 'Afwezig Van',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 150,
        renderer: (rij) => {
          return moment(new Date(rij.AfwezigVan))
            .locale('nl')
            .format('DD-MM-yyyy');
        },
      },
      {
        key: 'afwezigTem',
        label: 'Afwezig T/m',
        breedteType: EAspKolomBreedteType.Flex,
        flex: 1,
        renderer: (rij) => {
          return moment(new Date(rij.AfwezigTem))
            .locale('nl')
            .format('DD-MM-yyyy');
        },
      },
    ],
    [afwezigheden],
  );

  return (
    <>
      <Helmet>
        <title>Periodes van afwezigheid</title>
      </Helmet>
      <MenuLayout
        menu={
          <div className="d-flex align-items-center">
            <button
              className="btn btn-sm btn-light d-flex align-items-center"
              style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
              disabled={false}
              onClick={() => handleToevoegen()}
            >
              <IconToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
              <span className="ml-2">Toevoegen</span>
            </button>
            <button
              className="btn btn-sm btn-light d-flex align-items-center ml-3"
              style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
              disabled={urlState.selectie.length === 0}
              onClick={() => handleVerwijderen()}
            >
              <IconVerwijderen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
              <span className="ml-2">Verwijderen</span>
            </button>
          </div>
        }
        body={
          <div
            className="d-flex flex-fill flex-column select-none"
            style={{ width: '100%', height: '100%' }}
          >
            {afwezigheden.state == ERemoteDataState.Pending || afwezigheden.data == null ? (
              <LoadingSpinner />
            ) : (
              <ASPTabel
                rijen={afwezigheden.data!}
                kolommen={kolommen}
                keyExtractor={keyExtractor}
                onWijzigenRij={async (rij) => {
                  setUrlStateSync('wijzigenDialoogState', { id: rij.ID });
                }}
                tdComponent={TdComponent}
                selectie={urlState.selectie}
                onSelectieChange={(selectie) => setUrlStateSync('selectie', selectie)}
                lokaalSorteren={true}
              />
            )}
          </div>
        }
      />
      {urlState.toevoegenDialoogState !== null && (
        <ToevoegenDialoog
          open
          dienstID={servicedienst.ID}
          onSuccess={async () => {
            setUrlStateSync('toevoegenDialoogState', null);
            ophalenAfwezigheden();
          }}
          onAnnuleren={() => setUrlStateSync('toevoegenDialoogState', null)}
        />
      )}
      {urlState.wijzigenDialoogState !== null && (
        <WijzigenDialoog
          open
          id={urlState.wijzigenDialoogState.id}
          dienstID={servicedienst.ID}
          onSuccess={async () => {
            setUrlStateSync('wijzigenDialoogState', null);
            ophalenAfwezigheden();
          }}
          onAnnuleren={() => setUrlStateSync('wijzigenDialoogState', null)}
        />
      )}
    </>
  );
};

const TdComponent = (props: ITableDataProps<Kolom, IDienstAfwezigheid>) => {
  return (
    <TableData
      {...props}
      style={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
        display: 'block',
        paddingTop: '8px',
      }}
    />
  );
};

export default withRouter(Afwezigheid);
