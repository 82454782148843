import {
  IOphalenContactenParams,
  IOphalenContactenResult,
} from '../../../../shared/src/api/v2/contact/contact';
import api from '../index';

const contact = {
  ophalenContacten: async (params: IOphalenContactenParams): Promise<IOphalenContactenResult> => {
    return await api.post('/v2/contact/ophalen-contacten', params);
  },
};

export default contact;
