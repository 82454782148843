import {
  IOphalenMaatwerkselectiesParams,
  IOphalenMaatwerkselectiesResult,
} from '../../../../../../shared/src/api/v2/bulkbericht/maatwerkselectie';
import api from '../../../index';

const maatwerkselectie = {
  ophalenMaatwerkselecties: async (
    params: IOphalenMaatwerkselectiesParams,
  ): Promise<IOphalenMaatwerkselectiesResult> => {
    return await api.post('/v2/bulkbericht/maatwerkselectie/ophalen-maatwerkselecties', params);
  },
};

export default maatwerkselectie;
