import React, { useContext, useEffect, useMemo, useState, useCallback } from 'react';
import useUrlState from '../../../core/useUrlState';
import { RouteComponentProps, withRouter } from 'react-router';
import { observer } from 'mobx-react-lite';
import api from '../../../api';
import { RootStoreContext } from '../../../stores/RootStore';
import LoadingSpinner from '../../../components/Gedeeld/LoadingSpinner';
import {
  IOphalenDossierStatussenResult,
  IOphalenIncassozakenResultElement,
} from '../../../../../shared/src/api/v2/debiteur/incassozaak';
import { Kleur } from '../../../bedrijfslogica/constanten';
import FilterBalkV2, {
  IFilter,
  IFilterData,
  maakFilterSchema,
} from '../../../components/FilterBalkV2';
import { IFilterSchema } from '../../../../../shared/src/models/filter';
import MultiSelect, { IOptie } from '../../../components/formulier/MultiSelect';
import IncassozakenTabel, {
  IWijzigenIncassozaakDialoogState,
} from '../../../components/debiteuren/IncassozakenTabel';
import SelectieVak from '../../../components/SelectieVak';
import { IconSend } from '../../../components/Icons';
import { EIncassozaakStatus } from '../../../bedrijfslogica/enums';
import { EResultType } from '../../../stores/CheckStore';
import { Helmet } from 'react-helmet';

enum EFilter {
  Status = 'STATUS_NAAM_ENUMS',
  Afgehandeld = 'IS_AFGEHANDELD',
}

interface IProps extends RouteComponentProps {}

interface IUrlState {
  selectie: number[];
  filterData: IFilterData<EFilter>[];
  wijzigenIncassozaakDialoogState: IWijzigenIncassozaakDialoogState | null;
}

const defaultUrlState: IUrlState = {
  selectie: [],
  filterData: [
    {
      naam: EFilter.Status,
      data: ['CONCEPT', 'UITHANDEN'],
      isActief: true,
    },
    {
      naam: EFilter.Afgehandeld,
      isActief: false,
      data: false,
    },
  ],
  wijzigenIncassozaakDialoogState: null,
};

export interface IIncassozaakContext {
  zaakstatussen: IOphalenDossierStatussenResult | null;
}
export const IncassozaakContext = React.createContext<IIncassozaakContext>(null as any);

const Incassozaken: React.FC<IProps> = observer((props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const { checkStore } = useContext(RootStoreContext);

  const [incassozaken, setIncassozaken] = useState<IOphalenIncassozakenResultElement[] | null>(
    null,
  );

  const [filterSchema, setFilterSchema] = useState<IFilterSchema>(
    maakFilterSchema(urlState.filterData),
  );

  const ophalenIncassozaken = useCallback(async () => {
    const incassozakenResult = await api.v2.debiteur.incassozaak.ophalenIncassozaken({
      filterSchema,
    });
    const incassozaken = incassozakenResult.incassozaken;
    setIncassozaken(incassozaken);
  }, [filterSchema]);

  useEffect(() => {
    ophalenIncassozaken();
  }, [ophalenIncassozaken]);

  const [statussenResult, setStatussen] = useState<IOphalenDossierStatussenResult | null>(null);
  const ophalenStatussen = useCallback(async () => {
    const result = await api.v2.debiteur.incassozaak.ophalenDossierStatussen({});
    setStatussen(result);
  }, []);

  useEffect(() => {
    ophalenStatussen();
  }, [ophalenStatussen]);

  const filters: IFilter<EFilter>[] = useMemo(
    () => [
      {
        naam: EFilter.Status,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return (
            <span className="d-flex align-items-center">
              <span className="mr-3">Status</span>
              {statussenResult === null ? (
                <LoadingSpinner />
              ) : (
                <MultiSelect
                  value={weergaveProps.data}
                  onChange={(x) => {
                    weergaveProps.onDataChange(x);
                    weergaveProps.toepassen();
                  }}
                  opties={statussenResult.statussen.map((x) => ({
                    key: x.NaamEnum!,
                    weergave: x.Naam,
                  }))}
                />
              )}
            </span>
          );
        },
      },
      {
        naam: EFilter.Afgehandeld,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return <span>Alleen niet-afgehandeld</span>;
        },
      },
    ],
    [statussenResult],
  );

  return (
    <>
      <div
        className="d-flex flex-column p-3"
        style={{
          backgroundColor: Kleur.HeelLichtGrijs,
          borderBottom: `1px solid ${Kleur.LichtGrijs}`,
        }}
      >
        <div className="d-flex align-items-center">
          <button
            className="btn btn-sm btn-light d-flex align-items-center"
            disabled={urlState.selectie.length === 0}
            style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
            onClick={async () => {
              const params = { ids: urlState.selectie };

              const checkData = await api.v2.debiteur.incassozaak.checkVersturenIncassoOpdrachten(
                params,
              );

              if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
                return;
              }

              if (
                (
                  await checkStore.bevestigen({
                    inhoud: 'Opdracht(en) versturen naar de incassodienst?',
                  })
                ).type === EResultType.Annuleren
              ) {
                return;
              }

              await api.v2.debiteur.incassozaak.versturenIncassoOpdrachten(params);

              await api.v2.debiteur.incassozaak.wijzigenDossiersstatus({
                ids: [urlState.selectie[0]],
                status: EIncassozaakStatus.Uithanden,
              });
            }}
          >
            <IconSend style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
            <span className="ml-2">Versturen opdracht</span>
          </button>

          <button
            className="btn btn-sm btn-light d-flex align-items-center ml-3"
            disabled={urlState.selectie.length === 0}
            style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
            onClick={async () => {
              if (
                (
                  await checkStore.bevestigen({
                    inhoud: (
                      <span>
                        Wil je een herincassozaken maken voor de geselecteerde incassozaken?
                        <br />
                        De herincassozaken dienen daarna nog zelf verstuurd te worden.
                        <br />
                        <br />
                        De incassozaken worden daarbij direct als Afgehandeld gemarkeerd.
                      </span>
                    ),
                  })
                ).type === EResultType.Annuleren
              ) {
                return;
              }

              const params = { ids: urlState.selectie };

              await api.v2.debiteur.incassozaak.toevoegenHerincassozaakVoorIncassozaak(params);

              setUrlStateSync('selectie', []);

              ophalenIncassozaken();
            }}
          >
            <IconSend style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
            <span className="ml-2">Naar Herincassozaak</span>
          </button>

          <div className="d-flex flex-fill ml-2">
            <FilterBalkV2
              filters={filters}
              filterData={urlState.filterData}
              onFilterDataChange={(data) => setUrlStateSync('filterData', data)}
              onFilterSchemaChange={(schema) => setFilterSchema(schema)}
            />
          </div>
        </div>

        {incassozaken !== null && (
          <div className="mt-2 d-flex align-items-center">
            <SelectieVak
              totaalAantal={incassozaken.length}
              aantal={urlState.selectie.length}
              onChange={(allesGeselecteerd) => {
                if (allesGeselecteerd) {
                  setUrlStateSync(
                    'selectie',
                    incassozaken.map((x) => x.ID),
                  );
                } else {
                  setUrlStateSync('selectie', []);
                }
              }}
            />
          </div>
        )}
      </div>
      <Helmet>
        <title>Incassozaken</title>
      </Helmet>
      <IncassozakenTabel
        incassozaken={incassozaken}
        selectie={urlState.selectie}
        onSelectieChange={(x) => setUrlStateSync('selectie', x)}
        wijzigenIncassozaakDialoogState={urlState.wijzigenIncassozaakDialoogState}
        onWijzigenIncassozaakDialoogStateChange={(x) =>
          setUrlStateSync('wijzigenIncassozaakDialoogState', x)
        }
        onVerversenAangevraagd={async () => await ophalenIncassozaken()}
        onRelatieClick={(relID) => {
          props.history.push(`/klant/${relID}/debiteur/incassozaken`);
        }}
      />
    </>
  );
});

export default withRouter(Incassozaken);
