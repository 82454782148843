import React from 'react';
import styled from 'styled-components';
import { Kleur } from '../../../../../bedrijfslogica/constanten';
import ASPGebruikerVisualisatie, {
  EModus,
} from '../../../../ASPGebruiker/ASPGebruikerVisualisatie';

const Root = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const MemoTextarea = styled.textarea`
  ::-webkit-input-placeholder {
    font-style: italic;
    color: ${Kleur.Grijs};
  }

  :-moz-placeholder {
    font-style: italic;
    color: ${Kleur.Grijs};
  }

  ::-moz-placeholder {
    font-style: italic;
    color: ${Kleur.Grijs};
  }

  :-ms-input-placeholder {
    font-style: italic;
    color: ${Kleur.Grijs};
  }

  :disabled {
    background-color: ${Kleur.MemoGeelDisabled};
  }

  outline: 0;
  padding: 5px 10px 10px 10px;
  border: 1px solid ${Kleur.LichtGrijs};
  font-size: 13px;
  width: 100%;
  margin-bottom: 5px;
`;

const GebruikerContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  .van-text {
    font-size: 12px;
    color: ${Kleur.Grijs};
    margin-right: 8px;
  }
`;

interface IProps {
  inhoud: string | null;
  aspGebrID: number;
}

const MemoVanAnder: React.FC<IProps> = (props) => {
  return (
    <Root>
      <MemoTextarea
        disabled
        value={props.inhoud ?? ''}
        rows={2}
        placeholder="Memo heeft geen inhoud"
      />
      <GebruikerContainer>
        <span className="van-text">van</span>
        <ASPGebruikerVisualisatie aspGebrID={props.aspGebrID} modus={EModus.Volledig} />
      </GebruikerContainer>
    </Root>
  );
};

export default MemoVanAnder;
