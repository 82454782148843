import React, { useCallback, useMemo, useState } from 'react';
import { DataTypeProvider, TableRowDetail } from '@devexpress/dx-react-grid';
import TabelInspringBlok from '../../../layout/TabelInspringBlok';
import {
  IFactuur,
  IOphalenBetalingsregelingenResultElement,
} from '../../../../../../shared/src/api/v2/debiteur/betalingsregeling';
import {
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import {
  Grid,
  Table,
  TableColumnResizing,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-bootstrap4';
import { VirtualTable as VirtualTableBase } from '@devexpress/dx-react-grid';
import { format } from 'date-fns';
import FormatteerBedrag from '../../../MutatieBedrag';
import nameOf from '../../../../core/nameOf';
import DownloadKnop from '../../../DownloadKnop';
import api from '../../../../api';
import { IconInformatie } from '../../../Icons';
import { Kleur as EKleur } from '../../../../bedrijfslogica/constanten';
import FactuurinfoDialoog from '../../../factuur/FactuurinfoDialoog';

export interface IFactuurinfoDialoogState {
  factID: number;
}

const DetailComp = (props: TableRowDetail.ContentProps) => {
  const row: IOphalenBetalingsregelingenResultElement = props.row;

  const [
    factuurinfoDialoogState,
    setFactuurinfoDialoogState,
  ] = useState<IFactuurinfoDialoogState | null>(null);

  const kolommen = useMemo<TypedColumn<IFactuur>[]>(
    () => [
      {
        name: 'Factuurnummer',
        title: 'Fact.nr.',
      },
      {
        name: 'Factuurdatum',
        title: 'Datum',
      },
      {
        name: 'Vervaldatum',
        title: 'Vervaldatum',
      },
      {
        name: 'BedragInRegeling',
        title: 'Bedrag in reg.',
      },
      {
        name: 'Openstaand',
        title: 'Openstaand',
      },
      {
        name: '__infoFactuur' as any,
        title: ' ',
      },
      {
        name: '__downloadActie' as any,
        title: ' ',
      },
    ],
    [],
  );
  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IFactuur>[]>(
    () => [
      {
        columnName: 'Factuurnummer',
        width: 100,
      },
      {
        columnName: 'Factuurdatum',
        width: 100,
      },
      {
        columnName: 'Vervaldatum',
        width: 100,
      },
      {
        columnName: 'BedragInRegeling',
        width: 130,
      },
      {
        columnName: 'Openstaand',
        width: 100,
      },
      {
        columnName: '__infoFactuur' as any,
        width: 60,
      },
      {
        columnName: '__downloadActie' as any,
        width: 80,
      },
    ],
    [],
  );

  const keyExtractor = useCallback((factuur: IFactuur) => factuur.FactID, []);

  const kolomExtensies: VirtualTableBase.ColumnExtension[] = useMemo(() => {
    return [
      // {
      //   columnName: `Bedrag`,
      //   align: 'right',
      // },
      // {
      //   columnName: `Openstaand`,
      //   align: 'right',
      // },
    ];
  }, []);

  return (
    <>
      <div className="d-flex">
        <TabelInspringBlok />
        <GridStyleWrapper maxHeight={500} rowAmount={row.facturen.length}>
          <Grid rows={row.facturen} columns={kolommen} getRowId={keyExtractor}>
            <DataTypeProvider
              for={[nameOf<IFactuur>('Factuurdatum'), nameOf<IFactuur>('Vervaldatum')]}
              formatterComponent={(props) => {
                const rij: IFactuur = props.row;
                return <span>{format(new Date(rij.Factuurdatum), 'dd-MM-yyyy')}</span>;
              }}
            />

            <DataTypeProvider
              for={[nameOf<IFactuur>('BedragInRegeling')]}
              formatterComponent={(props) => {
                return <FormatteerBedrag bedrag={props.value} />;
              }}
            />

            <DataTypeProvider
              for={[nameOf<IFactuur>('Openstaand')]}
              formatterComponent={(props) => {
                return <FormatteerBedrag bedrag={props.value} />;
              }}
            />

            <DataTypeProvider
              for={['__downloadActie']}
              formatterComponent={(props) => {
                const rij: IFactuur = props.row;
                const factID = rij.FactID;

                return (
                  <DownloadKnop
                    onDownloadAangevraagd={async () => {
                      const result = await api.v2.factuur.genereerFactuurPDF({
                        factID,
                      });
                      const downloadLink = result.bestand.url;
                      window.open(downloadLink, '_blank');
                    }}
                  />
                );
              }}
            />

            <DataTypeProvider
              for={['__infoFactuur']}
              formatterComponent={(props) => {
                return (
                  <>
                    <a
                      href="#"
                      className="ml-1"
                      style={{ color: EKleur.LichtBlauw, position: 'relative', bottom: 2 }}
                      onClick={() => {
                        setFactuurinfoDialoogState({ factID: props.row.FactID });
                      }}
                    >
                      <IconInformatie style={{ width: 15, height: 15, fill: EKleur.Blauw }} />
                    </a>
                  </>
                );
              }}
            />

            <Table columnExtensions={kolomExtensies} />
            <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
            {/* <TableHeaderRow showSortingControls /> */}
            <TableHeaderRow />
          </Grid>
        </GridStyleWrapper>
      </div>
      {factuurinfoDialoogState !== null && (
        <FactuurinfoDialoog
          open
          factID={factuurinfoDialoogState.factID}
          onSuccess={() => {
            setFactuurinfoDialoogState(null);
          }}
          onAnnuleren={() => setFactuurinfoDialoogState(null)}
        />
      )}
    </>
  );
};

export default DetailComp;
