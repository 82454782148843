import * as React from 'react';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  TableRowDetail,
  VirtualTable,
  TableEditColumn,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  IOphalenBatchesResultElement,
  IOphalenOpdrachtenResultElement,
} from '../../../../../../../shared/src/api/v2/bank/opdracht/opdracht';
import { useEffect, useState, useContext } from 'react';
import api from '../../../../../api';
import LoadingSpinner from '../../../../../components/Gedeeld/LoadingSpinner';
import { useMemo } from 'react';
import {
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
  TableRowDetailWrapper,
  DXTableToggleCellComponent,
} from '../../../../../helpers/dxTableGrid';
import { useCallback } from 'react';
import {
  Column,
  DataTypeProvider,
  IntegratedSorting,
  SortingState,
  TableColumnWidthInfo,
  EditingState,
  VirtualTable as VirtualTableBase,
  RowDetailState,
} from '@devexpress/dx-react-grid';
import FormatteerBedrag from '../../../../../components/MutatieBedrag';
import { RootStoreContext } from '../../../../../stores/RootStore';
import { EResultType } from '../../../../../stores/CheckStore';
import RelatieVisualisatie from '../../../../../components/personalia/RelatieVisualisatie';
import TabelInspringBlok from '../../../../../components/layout/TabelInspringBlok';
import { BatchesContext } from '../index';
import { IOphalenMedewerkersResultElement } from '../../../../../../../shared/src/api/v2/medewerker';
import MenuLayout from '../../../../../components/MenuLayout';
import GegevensLayout from '../../../../../components/layout/GegevensLayout';
import DownloadKnop from '../../../../../components/DownloadKnop';
import DetailOpdracht from '../../Opdrachten/DetailComp';
import nameOf from '../../../../../core/nameOf';
import { BankopdrachtContext, IContext, IRegel } from '../../Opdrachten';
import {
  EStoplichtStatus,
  StoplichtIndicatie,
} from '../../../../../components/formulier/StoplichtIndicatie';
import WijzigOpdrachtDialoog from './WijzigOpdrachtDialoog';
import { useHeeftAutorisatie } from '../../../../../helpers/heeftAutorisatie';

type Props = TableRowDetail.ContentProps;

export interface IWijzigenOpdrachtDialoogState {
  id: number;
}

const RowDetailComp: React.FunctionComponent<Props> = (props) => {
  const row = props.row as IOphalenBatchesResultElement;
  const { onVerversenAangevraagd } = useContext(BatchesContext);
  const { checkStore } = useContext(RootStoreContext);

  const [opdrachten, setOpdrachten] = useState<IRegel[] | null>(null);
  const [medewerkers, setMedewerkers] = useState<IOphalenMedewerkersResultElement[] | null>(null);
  const [uitgeklapt, setUitgeklapt] = useState<number[]>([]);

  const [
    wijzigenOpdrachtDialoogState,
    setWijzigenOpdrachtDialoogState,
  ] = useState<IWijzigenOpdrachtDialoogState | null>(null);

  const ophalenOpdrachten = useCallback(async () => {
    // if (opdrachten !== null) {
    //   return;
    // }
    //
    const opdrachtenResult = await api.v2.bank.opdracht.ophalenOpdrachten({
      filterSchema: { filters: [{ naam: 'BATCH_IDS', data: [row.BatchID] }] },
    });

    const signaleringenResult = (
      await api.v2.bank.opdracht.ophalenSignaleringenOpdrachten({
        bankOpdIDs: opdrachtenResult.opdrachten.map((x) => x.BankOpdID),
      })
    ).opdrachten;

    const opdrachten = opdrachtenResult.opdrachten.map((x) => {
      const signalering = signaleringenResult.find((s) => s.BankOpdID === x.BankOpdID)!;
      return { ...x, errors: signalering.errors, warnings: signalering.warnings };
    });

    setOpdrachten(opdrachten);

    // Haal medewerkersinfo erbij
    const medewerkers = (
      await api.v2.medewerker.ophalenMedewerkers({
        filterSchema: {
          filters: [
            {
              naam: 'IDS',
              data: opdrachtenResult.opdrachten.filter((x) => x.MdwID !== null).map((x) => x.MdwID),
            },
          ],
        },
      })
    ).medewerkers;

    setMedewerkers(medewerkers);
  }, [row.BatchID]);

  useEffect(() => {
    ophalenOpdrachten();
  }, [ophalenOpdrachten]);

  const handleVerwijderen = useCallback(
    async (bankOpdID: number) => {
      const checkData = await api.v2.bank.opdracht.checkVerwijderenBatchOpdrachten({
        batchID: row.BatchID,
        opdIDs: [bankOpdID],
      });
      if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
        return;
      }
      if (
        (
          await checkStore.bevestigen({
            inhoud: `Wil je de bankopdracht uit de batch verwijderen?`,
          })
        ).type === EResultType.Annuleren
      ) {
        return;
      }

      await api.v2.bank.opdracht.verwijderenBatchOpdrachten({
        batchID: row.BatchID,
        opdIDs: [bankOpdID],
      });

      await ophalenOpdrachten();
      onVerversenAangevraagd();
    },
    [row.BatchID, onVerversenAangevraagd],
  );

  const kolommen = useMemo<TypedColumn<IRegel>[]>(() => {
    return [
      {
        name: '__signaleringen' as any,
        title: 'Sign.',
        getCellValue: (x) => x.errors.length + x.warnings.length,
      },
      {
        name: '__rekeninghouder' as any,
        title: 'Relatie',
        getCellValue: (rij) => {
          if (rij.RelID !== null) {
            return rij.relatie!.weergavenaam;
          }
          if (rij.MdwID !== null) {
            return medewerkers!.find((x) => x.MdwID === rij.MdwID)!.persoon!.Achternaam;
          }
          return '';
        },
      },
      {
        name: 'Bedrag',
        title: 'Bedrag',
      },
      {
        name: 'Betalingskenmerk',
        title: 'Betalingskenmerk',
      },
      {
        name: 'IBAN',
        title: 'IBAN',
      },
      {
        name: 'OudeIBAN',
        title: 'Wijz. IBAN',
      },
      {
        name: 'Rekeningnaam',
        title: 'Rekeningnaam',
      },
      {
        name: 'Omschrijving',
        title: 'Omschrijving',
      },
    ];
  }, [medewerkers]);

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRegel>[]>(() => {
    return [
      {
        columnName: '__signaleringen' as any,
        width: 70,
      },
      {
        columnName: '__rekeninghouder' as any,
        width: 200,
      },
      {
        columnName: 'Bedrag',
        width: 115,
      },
      {
        columnName: 'Betalingskenmerk',
        width: 150,
      },
      {
        columnName: 'Omschrijving',
        width: 450,
      },
      {
        columnName: 'IBAN',
        width: 200,
      },
      {
        columnName: 'OudeIBAN',
        width: 120,
      },
      {
        columnName: 'Rekeningnaam',
        width: 250,
      },
    ];
  }, []);

  const keyExtractor = useCallback((row: IRegel) => row.BankOpdID, []);

  const kolomExtensies: VirtualTableBase.ColumnExtension[] = useMemo(() => {
    return [
      // {
      //   columnName: 'Bedrag',
      //   align: 'right',
      // },
    ];
  }, []);

  const bankopdrachtContext = useMemo<IContext>(
    () => ({
      onVerversenAangevraagd,
    }),
    [onVerversenAangevraagd],
  );

  const isGeautoriseerdVoorAfgeschermd = useHeeftAutorisatie([
    'FINANCIEEL_BANKZAKEN_BATCHES_AFGESCHERMD_WEERGEVEN',
  ]);

  if (!isGeautoriseerdVoorAfgeschermd && row.IsAfgeschermd) {
    return (
      <div className="p-3">
        Je hebt geen permissie om deze afgeschermde informatie weer te geven.
      </div>
    );
  }

  return (
    <BankopdrachtContext.Provider value={bankopdrachtContext}>
      <TableRowDetailWrapper>
        <div className="d-flex">
          <TabelInspringBlok />
          <MenuLayout
            menu={
              <div className="mt-2">
                <GegevensLayout
                  gegevens={[
                    {
                      label: 'Bestandsnaam',
                      waarde:
                        row.bestand === null ? (
                          <span></span>
                        ) : (
                          <div className="d-flex">
                            <span>{row.bestand.Naam}</span>
                            <DownloadKnop
                              onDownloadAangevraagd={async () => {
                                const downloadLink = row.bestand!.url;
                                window.open(downloadLink, '_blank');
                              }}
                            />
                          </div>
                        ),
                    },
                  ]}
                />
              </div>
            }
            body={
              <>
                {opdrachten === null || medewerkers === null ? (
                  <LoadingSpinner />
                ) : (
                  <GridStyleWrapper
                    maxHeight={1000}
                    rowAmount={(opdrachten || []).length}
                    // extraHeight={uitgeklapt.length * 250}
                    // height={1000}
                  >
                    <Grid
                      columns={kolommen as Column[]}
                      rows={opdrachten || []}
                      getRowId={keyExtractor}
                    >
                      <DataTypeProvider
                        for={['__signaleringen']}
                        formatterComponent={(props) => {
                          const rij: IRegel = props.row;

                          if (rij.errors.length !== 0) {
                            return <StoplichtIndicatie status={EStoplichtStatus.Rood} />;
                          }
                          if (rij.warnings.length !== 0) {
                            return <StoplichtIndicatie status={EStoplichtStatus.Oranje} />;
                          }

                          return <StoplichtIndicatie status={EStoplichtStatus.Groen} />;
                        }}
                      />

                      <DataTypeProvider
                        for={[nameOf<IOphalenOpdrachtenResultElement>('OudeIBAN')]}
                        formatterComponent={(props) => {
                          const rij: IOphalenOpdrachtenResultElement = props.row;
                          return <span>{props.value != null ? 'Ja' : ''}</span>;
                        }}
                      />

                      <DataTypeProvider
                        for={['__rekeninghouder']}
                        formatterComponent={(props) => {
                          const rij: IOphalenOpdrachtenResultElement = props.row;

                          if (rij.RelID !== null) {
                            return <RelatieVisualisatie relID={rij.RelID} />;
                          }
                          if (rij.MdwID !== null) {
                            return (
                              <span>
                                {
                                  medewerkers!.find((x) => x.MdwID === rij.MdwID)!.persoon!
                                    .Achternaam
                                }
                              </span>
                            );
                          }

                          return <span></span>;
                        }}
                      />

                      <DataTypeProvider
                        for={['Bedrag']}
                        formatterComponent={(props) => (
                          <span>
                            <FormatteerBedrag bedrag={props.value} />
                          </span>
                        )}
                      />

                      <SortingState defaultSorting={[]} />
                      <IntegratedSorting />

                      <RowDetailState
                        expandedRowIds={uitgeklapt}
                        onExpandedRowIdsChange={(x) => setUitgeklapt(x as number[])}
                      />

                      <VirtualTable
                        estimatedRowHeight={50}
                        // messages={geenData}
                        columnExtensions={kolomExtensies}
                      />

                      <TableColumnResizing
                        defaultColumnWidths={kolomBreedtes as TableColumnWidthInfo[]}
                      />

                      <TableHeaderRow showSortingControls />

                      <EditingState
                        onCommitChanges={async (changes) => {
                          if (changes.deleted === undefined) {
                            return;
                          }
                          const deleted = changes.deleted;
                          const id = deleted[deleted.length - 1] as number;
                          await handleVerwijderen(id);
                        }}
                        onEditingRowIdsChange={(rowIds) => {
                          const id = rowIds[rowIds.length - 1] as number;
                          setWijzigenOpdrachtDialoogState({ id });
                        }}
                      />

                      <TableEditColumn
                        width={65}
                        showDeleteCommand
                        showEditCommand
                        cellComponent={DXTableEditColumnCellComponent}
                        commandComponent={DXTableEditColumnCommandComponent}
                      />

                      <TableRowDetail
                        contentComponent={DetailOpdracht}
                        toggleCellComponent={DXTableToggleCellComponent}
                      />
                    </Grid>
                  </GridStyleWrapper>
                )}
              </>
            }
          />
          {wijzigenOpdrachtDialoogState !== null && (
            <WijzigOpdrachtDialoog
              open
              bankOpdID={wijzigenOpdrachtDialoogState.id}
              onSuccess={() => {
                ophalenOpdrachten();
                setWijzigenOpdrachtDialoogState(null);
              }}
              onAnnuleren={() => setWijzigenOpdrachtDialoogState(null)}
            />
          )}
        </div>
      </TableRowDetailWrapper>
    </BankopdrachtContext.Provider>
  );
};

export default RowDetailComp;
