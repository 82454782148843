import React, { useCallback, useEffect, useMemo, useState } from 'react';
import IDialoogProps from '../../../../core/IDialoogProps';
import Dialoog from '../../../../components/dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { Field, FieldProps, Formik, FormikActions, FormikErrors, FormikProps } from 'formik';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../models/IRemoteData';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import nameOf from '../../../../core/nameOf';
import MeertaligTekstveld, {
  MeertaligTekstveldSlugInputComponent,
} from '../../../../components/formulier/MeertaligTekstveld';
import ITaalTekst from '../../../../../../shared/src/models/talen/ITaalTekst';
import { IProductsoortLandingspagina } from '../../../../../../shared/src/api/v2/product/soort/landingspagina';
import api from '../../../../api';
import { IOphalenBestandenResultElement } from '../../../../../../shared/src/api/v2/bestand/bestand';
import { LoadingSpinnerCenter } from '../../../../components/Gedeeld/LoadingSpinner';
import { IOphalenTekstenResultElement } from '../../../../../../shared/src/api/v2/tekst';
import FormikVeldFout from '../../../../components/formulier/FormikVeldFout';
import BijlageKiezer from '../../../../components/formulier/BijlageKiezer';

interface IWijzigenData {
  landingspagina: IProductsoortLandingspagina;
  tekstenBijTekstID: Record<number, IOphalenTekstenResultElement[]>;
  afbeeldingBestand: IOphalenBestandenResultElement | null;
}

interface IProps extends IDialoogProps {
  id?: number;
}

interface IFormValues {
  urlSegment: ITaalTekst[];
  plaatsnaam: ITaalTekst[];
  specialisatie: ITaalTekst[];
  afbeeldingBestandID: number | null;
}

const MuterenDialoog = (props: IProps) => {
  const [wijzigenData, setWijzigenData] = useState<IRemoteData<IWijzigenData | null>>(
    props.id === undefined ? createReadyRemoteData(null) : createPendingRemoteData,
  );

  const bepalenWijzigenData = useCallback(async () => {
    if (props.id === undefined) {
      if (wijzigenData.state === ERemoteDataState.Pending || wijzigenData.data !== null) {
        setWijzigenData(createReadyRemoteData(null));
        return;
      }
      return;
    }

    const landingspaginasResult = await api.v2.product.soort.landingspagina.ophalenLandingspaginas({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: [props.id],
          },
        ],
      },
    });
    if (landingspaginasResult.landingspaginas.length === 0) {
      throw new Error(`Landingspagina met ID ${props.id} niet gevonden`);
    }
    const landingspagina = landingspaginasResult.landingspaginas[0];

    const tekstIDs = [
      landingspagina.Plaatsnaam_TekstID,
      landingspagina.Specialisatie_TekstID,
      landingspagina.UrlSegment_TekstID,
    ].filter((x) => x !== null) as number[];

    const [tekstenResult, bestandenResult] = await Promise.all([
      tekstIDs.length === 0
        ? null
        : api.v2.tekst.ophalenTekstenInAlleTalen({
            tekstIDs,
          }),
      landingspagina.Afbeelding_BestandID === null
        ? null
        : api.v2.bestand.ophalenBestanden({
            filterSchema: {
              filters: [
                {
                  naam: 'IDS',
                  data: [landingspagina.Afbeelding_BestandID],
                },
              ],
            },
          }),
    ]);

    const tekstenBijTekstID =
      tekstenResult === null
        ? {}
        : tekstenResult.teksten.reduce<IWijzigenData['tekstenBijTekstID']>((acc, x) => {
            const arr = acc[x.TekstID] ?? [];
            arr.push(x);
            acc[x.TekstID] = arr;
            return acc;
          }, {});

    const afbeeldingBestand = bestandenResult === null ? null : bestandenResult.bestanden[0];

    const nieuweWijzigenData: IWijzigenData = {
      landingspagina,
      tekstenBijTekstID,
      afbeeldingBestand,
    };

    setWijzigenData(createReadyRemoteData(nieuweWijzigenData));
  }, [props.id]);

  useEffect(() => {
    bepalenWijzigenData();
  }, [bepalenWijzigenData]);

  const initialValues = useMemo<IRemoteData<IFormValues>>(() => {
    if (wijzigenData.state === ERemoteDataState.Pending) {
      return createPendingRemoteData();
    }
    if (wijzigenData.data === null) {
      return createReadyRemoteData({
        urlSegment: [],
        plaatsnaam: [],
        specialisatie: [],
        afbeeldingBestandID: null,
      });
    }
    const data = wijzigenData.data;

    const transformeerTeksten = (teksten: IOphalenTekstenResultElement[]): ITaalTekst[] => {
      return teksten.map((tekst) => ({
        taalID: tekst.TaalID,
        toepassen: false,
        tekst: tekst.Tekst ?? '',
      }));
    };

    const urlSegmentTeksten = data.tekstenBijTekstID[data.landingspagina.UrlSegment_TekstID];
    const plaatsnaamTeksten = data.tekstenBijTekstID[data.landingspagina.Plaatsnaam_TekstID];
    const specialisatieTeksten =
      data.landingspagina.Specialisatie_TekstID === null
        ? []
        : data.tekstenBijTekstID[data.landingspagina.Specialisatie_TekstID];

    return createReadyRemoteData({
      urlSegment: transformeerTeksten(urlSegmentTeksten),
      plaatsnaam: transformeerTeksten(plaatsnaamTeksten),
      specialisatie: transformeerTeksten(specialisatieTeksten),
      afbeeldingBestandID: data.afbeeldingBestand?.ID ?? null,
    });
  }, [wijzigenData]);

  const valideer = useCallback((values: IFormValues): FormikErrors<IFormValues> => {
    if (
      values.urlSegment.length === 0 ||
      values.urlSegment.some((item) => item.toepassen && item.tekst.trim().length === 0)
    ) {
      return { urlSegment: 'Url segment is verplicht' as any };
    }
    if (
      values.plaatsnaam.length === 0 ||
      values.plaatsnaam.some((item) => item.tekst.trim().length === 0)
    ) {
      return { plaatsnaam: 'Plaatsnaam is verplicht' as any };
    }
    return {};
  }, []);

  const handleSubmit = useCallback(
    async (values: IFormValues, actions: FormikActions<IFormValues>) => {
      const errors = await actions.validateForm(values);
      if (Object.keys(errors).length > 0) {
        return;
      }
      await api.v2.product.soort.landingspagina.muterenLandingspagina({
        id: props.id ?? null,
        plaatsnaamTekstID: wijzigenData.data?.landingspagina.Plaatsnaam_TekstID ?? null,
        plaatsnaam: values.plaatsnaam,
        specialisatieTekstID: wijzigenData.data?.landingspagina.Specialisatie_TekstID ?? null,
        specialisatie: values.specialisatie,
        urlSegmentTekstID: wijzigenData.data?.landingspagina.UrlSegment_TekstID ?? null,
        urlSegment: values.urlSegment,
        afbeeldingBestandID: values.afbeeldingBestandID,
      });

      props.onSuccess(null);
    },
    [props.id, wijzigenData],
  );

  const renderForm = useCallback(
    (formikProps: FormikProps<IFormValues>) => {
      return <Formulier {...props} formikProps={formikProps} wijzigenData={wijzigenData.data} />;
    },
    [props, wijzigenData.data],
  );

  return (
    <Dialoog index={props.dialoogIndex ?? 0}>
      <ModalHeader>
        <ModalTitle>Landingspagina {props.id === undefined ? 'toevoegen' : 'wijzigen'}</ModalTitle>
      </ModalHeader>
      {initialValues.state === ERemoteDataState.Pending ? (
        <ModalBody>
          <LoadingSpinnerCenter />
        </ModalBody>
      ) : (
        <Formik
          initialValues={initialValues.data!}
          onSubmit={handleSubmit}
          render={renderForm}
          validate={valideer}
        />
      )}
    </Dialoog>
  );
};

interface IFormulierProps extends IProps {
  formikProps: FormikProps<IFormValues>;
  wijzigenData: IWijzigenData | null;
}

const Formulier = (props: IFormulierProps) => {
  const { formikProps, onAnnuleren, wijzigenData } = props;
  const { values, submitForm, isValid, isSubmitting, errors } = formikProps;

  const [afbeeldingBestand, setAfbeeldingBestand] = useState<IOphalenBestandenResultElement | null>(
    wijzigenData?.afbeeldingBestand ?? null,
  );
  const bepalenAfbeeldingBestand = useCallback(async () => {
    if (values.afbeeldingBestandID === null) {
      if (afbeeldingBestand !== null) {
        setAfbeeldingBestand(null);
      }
      return;
    }

    if (afbeeldingBestand !== null && afbeeldingBestand.ID === values.afbeeldingBestandID) {
      return;
    }

    const bestandenResult = await api.v2.bestand.ophalenBestanden({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: [values.afbeeldingBestandID],
          },
        ],
      },
    });
    if (bestandenResult.bestanden.length === 0) {
      throw new Error(`Geen bestand gevonden voor ID ${values.afbeeldingBestandID}`);
    }
    setAfbeeldingBestand(bestandenResult.bestanden[0]);
  }, [values.afbeeldingBestandID]);

  useEffect(() => {
    bepalenAfbeeldingBestand();
  }, [bepalenAfbeeldingBestand]);

  return (
    <>
      <ModalBody>
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <label>Url segment</label>
              <Field
                name={nameOf<IFormValues>('urlSegment')}
                render={(fieldProps: FieldProps<IFormValues>) => {
                  const { field, form } = fieldProps;

                  return (
                    <>
                      <MeertaligTekstveld
                        waarden={field.value}
                        onChange={(value) => form.setFieldValue(field.name, value)}
                        inputComponent={MeertaligTekstveldSlugInputComponent}
                      />
                      <FormikVeldFout
                        fieldProps={fieldProps}
                        options={{ objectAlsVeldBeschouwen: true }}
                      />
                    </>
                  );
                }}
              />
            </div>
          </div>

          <div className="col-12">
            <div className="form-group">
              <label>Plaatsnaam</label>
              <Field
                name={nameOf<IFormValues>('plaatsnaam')}
                render={(fieldProps: FieldProps<IFormValues>) => {
                  const { field, form } = fieldProps;

                  return (
                    <>
                      <MeertaligTekstveld
                        waarden={field.value}
                        onChange={(value) => form.setFieldValue(field.name, value)}
                      />
                      <FormikVeldFout
                        fieldProps={fieldProps}
                        options={{
                          objectAlsVeldBeschouwen: true,
                        }}
                      />
                    </>
                  );
                }}
              />
            </div>
          </div>

          <div className="col-12">
            <div className="form-group">
              <label>Specialisatie</label>
              <Field
                name={nameOf<IFormValues>('specialisatie')}
                render={(fieldProps: FieldProps<IFormValues>) => {
                  const { field, form } = fieldProps;

                  return (
                    <>
                      <MeertaligTekstveld
                        waarden={field.value}
                        onChange={(value) => form.setFieldValue(field.name, value)}
                        multilineConfig={{
                          rows: 5,
                        }}
                        inputComponent="multiline"
                      />
                      <FormikVeldFout
                        fieldProps={fieldProps}
                        options={{
                          objectAlsVeldBeschouwen: true,
                        }}
                      />
                    </>
                  );
                }}
              />
            </div>
          </div>

          <div className="col-12">
            <div className="form-group">
              <label>Afbeelding</label>
              <Field
                name={nameOf<IFormValues>('afbeeldingBestandID')}
                render={(fieldProps: FieldProps<IFormValues>) => {
                  const { field, form } = fieldProps;

                  return (
                    <>
                      <BijlageKiezer
                        bestand={afbeeldingBestand ?? undefined}
                        onBestIDChange={(bestID) => form.setFieldValue(field.name, bestID)}
                      />
                      <FormikVeldFout
                        fieldProps={fieldProps}
                        options={{
                          objectAlsVeldBeschouwen: true,
                        }}
                      />
                    </>
                  );
                }}
              />
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          className="btn btn-sm btn-primary"
          style={{ width: 100 }}
          onClick={submitForm}
          disabled={isSubmitting}
        >
          Ok
        </button>
        <button
          className="btn btn-sm btn-light"
          style={{ border: `1px solid ${Kleur.Grijs}`, width: 100 }}
          onClick={onAnnuleren}
          disabled={isSubmitting}
        >
          Annuleren
        </button>
      </ModalFooter>
    </>
  );
};

export default MuterenDialoog;
