import api from '../..';
import {
  IOphalenRelatiesMetAangebodenContractenParams,
  IOphalenRelatiesMetAangebodenContractenResultElement,
  IOphalenAangebodenContractenParams,
  IOphalenAangebodenContractenResult,
  // IOvernemenContractenParams,
  // IOvernemenContractenResult,
  IOphalenMutatiesOvernemenParams,
  IOphalenMutatiesOvernemenResult,
} from '../../../../../shared/src/api/v2/contract/overdracht';
import {
  IVersturenBevestigingOudeHuurderParams,
  IVersturenBevestigingOudeHuurderResult,
  IVersturenBevestigingNieuweHuurderParams,
  IVersturenBevestigingNieuweHuurderResult,
  IFiatterenContractenParams,
  IFiatterenContractenResult,
  IVerwijderenOvernameContractenParams,
  IVerwijderenOvernameContractenResult,
  IOvernemenContractenParams,
  IOvernemenContractenResult,
  ICheckOvernemenContractenParams,
} from '../../../../../shared/src/api/v2/contract/overname/overname';
import ICheckData from '../../../../../shared/src/models/ICheckData';

const overdracht = {
  ophalenRelatiesMetAangebodenContracten: async (
    params: IOphalenRelatiesMetAangebodenContractenParams,
  ): Promise<IOphalenRelatiesMetAangebodenContractenResultElement[]> => {
    return await api.post(
      '/v2/contract/overdracht/ophalen-relaties-met-aangeboden-contracten',
      params,
    );
  },
  ophalenAangebodenContractenVoorRelatie: async (
    params: IOphalenAangebodenContractenParams,
  ): Promise<IOphalenAangebodenContractenResult> => {
    return await api.post(
      '/v2/contract/overdracht/ophalen-aangeboden-contracten-voor-relatie',
      params,
    );
  },
  checkOvernemenContracten: async (
    params: ICheckOvernemenContractenParams,
  ): Promise<ICheckData> => {
    return await api.post('/v2/contract/overname/check-overnemen-contracten', params);
  },
  overnemenContracten: async (
    params: IOvernemenContractenParams,
  ): Promise<IOvernemenContractenResult> => {
    return await api.post('/v2/contract/overname/overnemen-contracten', params);
  },
  checkVerwijderenOvernameContracten: async (
    params: IVerwijderenOvernameContractenParams,
  ): Promise<ICheckData> => {
    return await api.post('/v2/contract/overname/check-verwijderen-overname-contracten', params);
  },
  verwijderenOvernameContracten: async (
    params: IVerwijderenOvernameContractenParams,
  ): Promise<IVerwijderenOvernameContractenResult> => {
    return await api.post('/v2/contract/overname/verwijderen-overname-contracten', params);
  },

  versturenBevestigingOudeHuurder: async (
    params: IVersturenBevestigingOudeHuurderParams,
  ): Promise<IVersturenBevestigingOudeHuurderResult> => {
    return await api.post('/v2/contract/overname/versturen-bevestiging-huidige-huurder', params);
  },
  versturenBevestigingNieuweHuurder: async (
    params: IVersturenBevestigingNieuweHuurderParams,
  ): Promise<IVersturenBevestigingNieuweHuurderResult> => {
    return await api.post('/v2/contract/overname/versturen-bevestiging-nieuwe-huurder', params);
  },
  checkFiatterenContracten: async (params: IFiatterenContractenParams): Promise<ICheckData> => {
    return await api.post('/v2/contract/overname/check-fiatteren-contracten', params);
  },
  fiatterenContracten: async (
    params: IFiatterenContractenParams,
  ): Promise<IFiatterenContractenResult> => {
    return await api.post('/v2/contract/overname/fiatteren-contracten', params);
  },
};

export default overdracht;
