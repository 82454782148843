import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import IDialoogProps from '../../../../../../../../core/IDialoogProps';
import Dialoog from '../../../../../../../../components/dialogen/Dialoog';
import ModalFooter from 'react-bootstrap/ModalFooter';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import * as Yup from 'yup';
import nameof from '../../../../../../../../core/nameOf';
import LocatieSelectie from '../../../../../../../../components/formulier/LocatieSelectie';
import MultiCombobox, { IKolom } from '../../../../../../../../components/formulier/MultiCombobox';
import api from '../../../../../../../../api';
import {
  IOphalenProductenResultElement,
  IToevoegenServicemeldingParams,
} from '../../../../../../../../../../shared/src/api/v2/service/melding';
import LoadingSpinner from '../../../../../../../../components/Gedeeld/LoadingSpinner';
import { formatteerAdresV2 } from '../../../../../../../../helpers';
import ContactSelectie from '../../../../../../../../components/formulier/ContactSelectie';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../../../../../../stores/RootStore';
import { EResultType } from '../../../../../../../../stores/CheckStore';
import TaalSelectie from '../../../../../../../../components/formulier/TaalSelectie';
import TelefoonnummerInput from '../../../../../../../../components/formulier/TelefoonnummerInput';
import { EMutatiebron } from '../../../../../../../../bedrijfslogica/enums';

interface IProps extends IDialoogProps<null> {
  relID: number;
  persID: number;
}

interface IFormikValues {
  naam: string;
  email: string;
  telefoonNummer: string;
  taalID: number | null;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  naam: 'Naam',
  email: 'Email',
  telefoonNummer: 'Telefoonnummer',
  taalID: 'Taal',
};

const VersturenDialoog: React.FC<IProps> = observer((props) => {
  const { instellingStore, checkStore } = useContext(RootStoreContext);
  const { dialoogIndex, onAnnuleren, onSuccess, open, relID } = props;

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      if (
        (await checkStore.bevestigen({ inhoud: `Uitnodiging versturen?` })).type ===
        EResultType.Annuleren
      ) {
        return;
      }

      const result = await api.v2.contract.aanbieding.toevoegenUitnodiging({
        relID: props.relID,
        persID: props.persID,
        email: values.email !== '' ? values.email : null,
        telefoonnummer: values.telefoonNummer != '' ? values.telefoonNummer : null,
        taalID: values.taalID!,
        mutatiebron: EMutatiebron.Intern,
        naam: values.naam,
      });

      await api.v2.contract.aanbieding.versturenUitnodiging({ id: result.ID });

      onSuccess(null);
      actions.setSubmitting(false);
    },
    [props.relID],
  );

  const initialValues = useMemo<IFormikValues>(() => {
    return {
      naam: '',
      email: '',
      telefoonNummer: '',
      taalID: instellingStore.TaalID,
    };
  }, []);

  const validationSchema = useMemo(() => {
    const fields: Record<keyof IFormikValues, any> = {
      naam: Yup.string(),
      email: Yup.string().email(),
      telefoonNummer: Yup.string(),
      taalID: Yup.number(),
    };
    return Yup.object().shape(fields);
  }, []);

  return (
    <Dialoog index={dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Versturen uitnodiging Overname</ModalTitle>
      </ModalHeader>

      <Formik<IFormikValues>
        onSubmit={handleSubmit}
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema}
        render={(formikProps: FormikProps<IFormikValues>) => {
          const { submitForm, isSubmitting, isValid } = formikProps;

          return (
            <>
              <ModalBody>
                <div className="form-group">
                  <div className="row">
                    <div className="col-12 mt-2">
                      <label>{veldnamen.naam}</label>
                      <Field
                        name={nameof<IFormikValues>('naam')}
                        render={(fieldProps: FieldProps<IFormikValues>) => {
                          const { field, form } = fieldProps;

                          return <input {...field} className="form-control" type="text" />;
                        }}
                      />
                    </div>

                    <div className="col-12 mt-2">
                      <label>{veldnamen.email}</label>
                      <Field
                        name={nameof<IFormikValues>('email')}
                        render={(fieldProps: FieldProps<IFormikValues>) => {
                          const { field, form } = fieldProps;

                          return <input {...field} className="form-control" type="text" />;
                        }}
                      />
                    </div>

                    <div className="col-12 mt-2">
                      <label>{veldnamen.telefoonNummer}</label>
                      <Field
                        name={nameof<IFormikValues>('telefoonNummer')}
                        render={(fieldProps: FieldProps<IFormikValues>) => {
                          const { field, form } = fieldProps;

                          return field.value === null ? (
                            <LoadingSpinner />
                          ) : (
                            <TelefoonnummerInput
                              telefoonnummer={field.value}
                              onChange={(x) => form.setFieldValue(field.name, x)}
                              // suggestiesProvider={
                              //   telefoonnummerInputSuggestiesProvider
                              // }
                            />
                          );

                          // return <input {...field} className="form-control" type="text" />;
                        }}
                      />
                    </div>

                    <div className="col-12 mt-2">
                      <label>{veldnamen.taalID}</label>
                      <Field
                        name={nameof<IFormikValues>('taalID')}
                        render={(fieldProps: FieldProps<IFormikValues>) => {
                          const { field, form } = fieldProps;

                          return (
                            <TaalSelectie
                              taalID={field.value}
                              onChange={(value) => form.setFieldValue(fieldProps.field.name, value)}
                              options={{ standaardVoorselecteren: true }}
                            />
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
              </ModalBody>
              <ModalFooter className="d-flex flex-row justify-content-start">
                <button
                  className="btn btn-primary"
                  onClick={submitForm}
                  style={{ width: 100 }}
                  disabled={isSubmitting || !isValid}
                >
                  Ok
                </button>
                <button className="btn btn-secondary" onClick={onAnnuleren} style={{ width: 100 }}>
                  Annuleren
                </button>
              </ModalFooter>
            </>
          );
        }}
      />
    </Dialoog>
  );
});

export default VersturenDialoog;
