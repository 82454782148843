import React, { useCallback, useMemo, useEffect, useState } from 'react';
import IDialoogProps from '../../../../../core/IDialoogProps';
import Dialoog from '../../../../../components/dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import { FormikActions, Formik, FormikProps, FormikErrors, Field, FieldProps } from 'formik';
import { ModalBody } from 'react-bootstrap';
import ModalFooter from 'react-bootstrap/ModalFooter';
import teksten from '../../../../../bedrijfslogica/teksten';
import nameof from '../../../../../core/nameOf';
import FormikVeldFout from '../../../../../components/formulier/FormikVeldFout';
import api from '../../../../../api';
import {
  IEigenschapProduct,
  IOphalenEigenschapProductenResult,
} from '../../../../../../../shared/src/api/v2/aanbod/index';
import MultiCombobox, { IKolom } from '../../../../../components/formulier/MultiCombobox';
import TekstVisualisatie from '../../../../../components/TekstVisualisatie';
import MeertaligTekstveld from '../../../../../components/formulier/MeertaligTekstveld';
import ITaalTekst from '../../../../../../../shared/src/models/talen/ITaalTekst';

export interface IFormikValues {
  eigProdID: number | null;
  waarden: ITaalTekst[];
}

export interface IDialoogOutput {
  eigProdID: number;
  waarden: ITaalTekst[];
}

interface IProps extends IDialoogProps<IDialoogOutput> {
  initialValues?: IFormikValues;
  uitgeslotenEigProdIDs: number[];
}

const MuterenDialoog: React.FC<IProps> = (props) => {
  const [
    eigenschapProductenResult,
    setEigenschapProductenResult,
  ] = useState<IOphalenEigenschapProductenResult | null>(null);
  const ophalenEigenschapProducten = useCallback(async () => {
    const result = await api.v2.aanbod.specificatie.ophalenEigenschapProducten({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: props.uitgeslotenEigProdIDs,
            inverteren: true,
          },
        ],
      },
    });
    setEigenschapProductenResult(result);
  }, [props.uitgeslotenEigProdIDs]);

  useEffect(() => {
    ophalenEigenschapProducten();
  }, [ophalenEigenschapProducten]);

  const eigenschapKolommen = useMemo<IKolom<IEigenschapProduct>[]>(
    () => [
      {
        key: 'Code',
        label: 'Code',
        breedte: 60,
      },
      {
        key: 'Naam_TekstID',
        label: 'Naam',
        breedte: 250,
        formatFabriek: (x) => <TekstVisualisatie tekstID={x.Naam_TekstID} />,
      },
      {
        key: 'Eenheid_TekstID',
        label: 'Eenheid',
        breedte: 150,
        formatFabriek: (x) => <TekstVisualisatie tekstID={x.Eenheid_TekstID} />,
      },
    ],
    [],
  );

  const initialValues = useMemo(() => {
    if (props.initialValues !== undefined) {
      return props.initialValues;
    }

    return {
      eigProdID: null,
      waarden: [],
    };
  }, [props.initialValues]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);
      const errors = await actions.validateForm(values);
      if (Object.keys(errors).length > 0) {
        actions.setSubmitting(false);
        return;
      }

      props.onSuccess({
        eigProdID: values.eigProdID!,
        waarden: values.waarden!,
      });
      actions.setSubmitting(false);
    },
    [props.onSuccess],
  );

  const validate = useCallback((values: IFormikValues) => {
    const errors: FormikErrors<IFormikValues> = {};
    if (values.eigProdID === null) {
      errors.eigProdID = teksten.formulier.E_VERPLICHT_VELD({ veldnaam: 'Eigenschap' });
    }
    if (values.waarden === null) {
      errors.waarden = teksten.formulier.E_VERPLICHT_VELD({ veldnaam: 'Waarden' }) as any;
    }
    return errors;
  }, []);

  return (
    <Dialoog index={props.dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Product specificatie</ModalTitle>
      </ModalHeader>
      <Formik<IFormikValues>
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validate={validate}
        render={(formikProps: FormikProps<IFormikValues>) => {
          const { isSubmitting, submitForm, values } = formikProps;
          if (eigenschapProductenResult === null) {
            return null;
          }
          let gekozenEigenschapProduct: IEigenschapProduct | null = null;
          if (values.eigProdID !== null) {
            gekozenEigenschapProduct = eigenschapProductenResult!.producten.find(
              (x) => x.ID === values.eigProdID,
            )!;
          }
          return (
            <>
              <ModalBody>
                <div className="form-group">
                  <div className="row">
                    <div className="col-12">
                      <label>Eigenschap</label>
                      <Field
                        name={nameof<IFormikValues>('eigProdID')}
                        render={(fieldProps: FieldProps<IFormikValues>) => {
                          const { field, form } = fieldProps;
                          return (
                            <>
                              <MultiCombobox
                                kolommen={eigenschapKolommen}
                                opties={eigenschapProductenResult!.producten}
                                waarde={field.value}
                                onWaardeChange={(x) => form.setFieldValue(field.name, x)}
                                representatieFabriek={(x) => (
                                  <span>
                                    <span>{x.Code}</span>
                                    &nbsp;-&nbsp;
                                    <TekstVisualisatie tekstID={x.Naam_TekstID} />
                                  </span>
                                )}
                                sleutelExtractor={(x) => x.ID}
                              />
                              <FormikVeldFout fieldProps={fieldProps} />
                            </>
                          );
                        }}
                      />
                    </div>

                    <div className="col-12 mt-3">
                      <label>Waarden</label>
                      <Field
                        name={nameof<IFormikValues>('waarden')}
                        render={(fieldProps: FieldProps<IFormikValues>) => {
                          const { field, form } = fieldProps;
                          let taalVeldPostfix: JSX.Element | null = null;
                          if (gekozenEigenschapProduct !== null) {
                            taalVeldPostfix = (
                              <span className="ml-3">
                                <TekstVisualisatie
                                  tekstID={gekozenEigenschapProduct.Eenheid_TekstID}
                                />
                              </span>
                            );
                          }
                          return (
                            <>
                              <MeertaligTekstveld
                                waarden={field.value}
                                onChange={(x) => form.setFieldValue(field.name, x)}
                                taalVeldPostfix={taalVeldPostfix || undefined}
                              />
                              <FormikVeldFout fieldProps={fieldProps} />
                            </>
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
              </ModalBody>
              <ModalFooter className="d-flex flex-row justify-content-start">
                <button
                  className="btn btn-primary"
                  onClick={submitForm}
                  style={{ width: 100 }}
                  disabled={isSubmitting}
                >
                  Ok
                </button>
                <button
                  className="btn btn-secondary"
                  onClick={props.onAnnuleren}
                  style={{ width: 100 }}
                  disabled={isSubmitting}
                >
                  Annuleren
                </button>
              </ModalFooter>
            </>
          );
        }}
      />
    </Dialoog>
  );
};

export default MuterenDialoog;
