import {
  IToevoegenProductAdviesParams,
  IToevoegenProductAdviesResult,
  IVerwijderenProductAdviezenParams,
  IVerwijderenProductAdviezenResult,
  IVersturenProductAdviesParams,
  IVersturenProductAdviesResult,
  IOphalenProductAdviezenParams,
  IOphalenProductAdviezenResult,
  IVersturenProductAdviezenParams,
  IVersturenProductAdviezenResult,
} from '../../../../../shared/src/api/v2/product/advies';
import ICheckData from '../../../../../shared/src/models/ICheckData';
import api from '../../index';

const advies = {
  ophalenProductadviezen: async (
    params: IOphalenProductAdviezenParams,
  ): Promise<IOphalenProductAdviezenResult> => {
    return await api.post('v2/product/advies/ophalen-adviezen', params);
  },
  checkToevoegenProductAdvies: async (
    params: IToevoegenProductAdviesParams,
  ): Promise<ICheckData> => {
    return await api.post('v2/product/advies/check-toevoegen-advies', params);
  },
  toevoegenProductAdvies: async (
    params: IToevoegenProductAdviesParams,
  ): Promise<IToevoegenProductAdviesResult> => {
    return await api.post('v2/product/advies/toevoegen-advies', params);
  },
  checkVerwijderenProductAdviezen: async (
    params: IVerwijderenProductAdviezenParams,
  ): Promise<ICheckData> => {
    return await api.post('v2/product/advies/check-verwijderen-adviezen', params);
  },
  verwijderenProductAdviezen: async (
    params: IVerwijderenProductAdviezenParams,
  ): Promise<IVerwijderenProductAdviezenResult> => {
    return await api.post('v2/product/advies/verwijderen-adviezen', params);
  },
  versturenProductAdviezen: async (
    params: IVersturenProductAdviezenParams,
  ): Promise<IVersturenProductAdviezenResult> => {
    return await api.post('v2/product/advies/versturen-adviezen', params);
  },
};

export default advies;
