import React, { useCallback, useMemo, useRef, useState } from 'react';
import ASPTabel from '../../../../tabel/ASPTabel';
import { ASPKolom, EAspKolomBreedteType } from '../../../../tabel/ASPTabel/types';
import {
  ERegelboekstukType,
  ERegelfocusbaarOp,
  ERegelstate,
  IMuterenRegel,
  IRegelboekstukBankmutatie,
  IRegelboekstukBetalingsregeling,
  IRegelboekstukFactuur,
  IRegelboekstukInkoopfactuur,
  IRegelfocusbaar,
  IWeergaveRegel,
  Regel,
} from '../types';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../../models/IRemoteData';
import StandaardVerwijderenTableData, {
  IVerwijderenTableDataProps,
} from '../../../../tabel/ASPTabel/Body/VerwijderenTableData';
import StandaardWijzigenTableData, {
  IWijzigenTableDataProps,
} from '../../../../tabel/ASPTabel/Body/WijzigenTableData';
import TableData from '../../../../tabel/ASPTabel/Body/TableData';
import GrootboekSelectie from '../../../../formulier/GrootboekSelectie';
import { IOphalenGrootboekenResultElement } from '../../../../../../../shared/src/api/v2/boekhouding/boeking/grootboek';
import { EDagboeksoort, ERelatiehoedanigheid } from '../../../../../bedrijfslogica/enums';
import {
  IOphalenDagboekenResultElement,
  IOphalenDagboekSoortenResultElement,
} from '../../../../../../../shared/src/api/v2/boekhouding/boeking/dagboek';
import Skeleton from 'react-loading-skeleton';
import RelatieVisualisatie from '../../../../personalia/RelatieVisualisatie';
import InkoopfactuurVisualisatie from '../../../../entiteitVisualisaties/InkoopfactuurVisualisatie';
import FactuurVisualisatie from '../../../../entiteitVisualisaties/FactuurVisualisatie';
import FormatteerBedrag from '../../../../MutatieBedrag';
import {
  IOphalenBtwSoortenResultElement,
  IOphalenBtwTarievenResultElement,
} from '../../../../../../../shared/src/api/v2/btw';
import BtwBedrag from './BtwBedrag';
import { IOphalenFacturenBasisResultElement } from '../../../../../../../shared/src/api/v2/factuur';
import { IOphalenFacturenResultElement } from '../../../../../../../shared/src/api/v2/inkoopfactuur';
import { IOphalenMutatiesResultElement } from '../../../../../../../shared/src/api/v2/bank/mutaties';
import { IOphalenWerkkostenRegelingenResultElement } from '../../../../../../../shared/src/api/v2/boekhouding/boeking';
import { IOphalenHoedanighedenResultElement } from '../../../../../../../shared/src/api/v2/relatie/hoedanigheid';
import api from '../../../../../api';
import { IOphalenRelatiesResultElementV2 } from '../../../../../../../shared/src/api/v2/relatie';
import InactiefOverlay from '../../../../InactiefOverlay';
import BedragInput from '../../../../formulier/BedragInput';
import VinkVeld from '../../../../formulier/VinkVeld';
import MultiCombobox from '../../../../formulier/MultiCombobox';
import Combobox from '../../../../formulier/Combobox';
import {
  bepaalBTWSoortIDVoorRegel,
  maakFactuurRegel,
  maakInkoopfactuurRegel,
  maakNieuweRegel,
} from '../helpers';
import BankmutatieMuteren from './BankmutatieMuteren';
import { IRegelModificatieNaOpslaanProvider } from '../providers/regelModificatieNaOpslaan';
import { ILeegComponentProps } from '../../../../tabel/ASPTabel/Body';
import FactuurMuteren from './FactuurMuteren';
import RelatieMuteren from './RelatieMuteren';
import InkoopfactuurMuteren from './InkoopfactuurMuteren';
import BetalingsregelingMuteren from './BetalingsregelingMuteren';
import { IOphalenBetalingsregelingenResultElement } from '../../../../../../../shared/src/api/v2/debiteur/betalingsregeling';

const pasRelatiegegevensToeOpMuterendeRegel = (
  regel: IMuterenRegel,
  relatie: IOphalenRelatiesResultElementV2,
  dagboekSoort: IOphalenDagboekSoortenResultElement,
  grootboek: IOphalenGrootboekenResultElement,
): IMuterenRegel => {
  const btwSoortID = bepaalBTWSoortIDVoorRegel({
    dagboekSoort,
    grootboek,
    relatie,
  });

  return {
    ...regel,
    relID: relatie?.RelID ?? null,
    btwSoortID: btwSoortID ?? regel.btwSoortID,
  };
};

enum EKolom {
  Grootboek,
  GrootboeknaamOfDebiteurOfCrediteur,
  Boekstuk,
  Bedrag,
  Storno,
  BtwCode,
  BtwBedrag,
  Werkkostenregeling,
  Omschrijving,
}

const LeegComponent = (props: ILeegComponentProps) => {
  return (
    <div
      style={{ width: props.width, height: props.height }}
      className="d-flex align-items-center justify-content-center"
    >
      Voeg een boekingsregel toe door op de toevoegen knop te klikken
    </div>
  );
};

interface IProps {
  isDefinitief: IRemoteData<boolean>;
  regels: IRemoteData<Regel[]>;
  onRegelsChange: (regels: Regel[]) => void;
  grootboeken: IRemoteData<IOphalenGrootboekenResultElement[]>;
  dagboeken: IRemoteData<IOphalenDagboekenResultElement[]>;
  dagboek: IRemoteData<IOphalenDagboekenResultElement>;
  btwSoorten: IRemoteData<IOphalenBtwSoortenResultElement[]>;
  btwTarieven: IRemoteData<IOphalenBtwTarievenResultElement[]>;
  werkkostenregelingen: IRemoteData<IOphalenWerkkostenRegelingenResultElement[]>;
  hoedanigheden: IRemoteData<IOphalenHoedanighedenResultElement[]>;
  nogTeBoeken: IRemoteData<number>;
  facturenCache: Record<number, IOphalenFacturenBasisResultElement>;
  inkoopfacturenCache: Record<number, IOphalenFacturenResultElement>;
  bankmutatiesCache: Record<number, IOphalenMutatiesResultElement>;
  betalingsregelingenCache: Record<number, IOphalenBetalingsregelingenResultElement>;
  regelModificatieNaOpslaanProvider: IRegelModificatieNaOpslaanProvider;
  boekdatum: IRemoteData<Date | null>;
}

const Regels = (props: IProps) => {
  const [isBezigMetToepassenRelatiegegevens, setBezigMetVerwerken] = useState(false);
  const [regelfocusbaar, setRegelfocusbaar] = useState<IRegelfocusbaar | null>(null);
  const regelsMuterenAnnulerenCache = useRef<Record<number | string, IWeergaveRegel>>({});

  const onRegelChange = useCallback(
    (rij: Regel) => {
      props.onRegelsChange(props.regels.data!.map((regel) => (regel.id === rij.id ? rij : regel)));
    },
    [props.regels, props.onRegelsChange],
  );

  const grootboekenBijID = useMemo<
    IRemoteData<Record<number, IOphalenGrootboekenResultElement>>
  >(() => {
    if (props.grootboeken.state === ERemoteDataState.Pending) {
      return createPendingRemoteData();
    }
    return createReadyRemoteData(
      props.grootboeken.data!.reduce(
        (acc, curr) => ({
          ...acc,
          [curr.ID]: curr,
        }),
        {},
      ),
    );
  }, [props.grootboeken]);

  const btwSoortenBijID = useMemo<
    IRemoteData<Record<number, IOphalenBtwSoortenResultElement>>
  >(() => {
    if (props.btwSoorten.state === ERemoteDataState.Pending) {
      return createPendingRemoteData();
    }
    return createReadyRemoteData(
      props.btwSoorten.data!.reduce(
        (acc, curr) => ({
          ...acc,
          [curr.ID]: curr,
        }),
        {},
      ),
    );
  }, [props.btwSoorten]);

  const werkkostenregelingenBijID = useMemo<
    IRemoteData<Record<number, IOphalenWerkkostenRegelingenResultElement>>
  >(() => {
    if (props.werkkostenregelingen.state === ERemoteDataState.Pending) {
      return createPendingRemoteData();
    }
    return createReadyRemoteData(
      props.werkkostenregelingen.data!.reduce(
        (acc, curr) => ({
          ...acc,
          [curr.ID]: curr,
        }),
        {},
      ),
    );
  }, [props.werkkostenregelingen]);

  const hoedanighedenBijID = useMemo<
    IRemoteData<Record<number, IOphalenHoedanighedenResultElement>>
  >(() => {
    if (props.hoedanigheden.state === ERemoteDataState.Pending) {
      return createPendingRemoteData();
    }
    return createReadyRemoteData(
      props.hoedanigheden.data!.reduce(
        (acc, curr) => ({
          ...acc,
          [curr.ID]: curr,
        }),
        {},
      ),
    );
  }, [props.hoedanigheden]);

  const isRelatieHoedanigheid = useCallback((hoedanigheid: IOphalenHoedanighedenResultElement) => {
    return hoedanigheid.NaamEnum === 'KLANT' || hoedanigheid.NaamEnum === 'LEVERANCIER';
  }, []);

  const grootboekSelectieGrootboekenProvider = useMemo(() => async () => props.grootboeken.data!, [
    props.grootboeken,
  ]);

  const bepaalFactuur = useCallback(
    async (factID: number) => {
      const cachedFactuur = props.facturenCache[factID];
      if (cachedFactuur !== undefined) {
        return cachedFactuur;
      }

      const result = await api.v2.factuur.ophalenFacturenBasis({
        filterSchema: {
          filters: [
            {
              naam: 'IDS',
              data: [factID],
            },
          ],
        },
      });
      return result.facturen[0];
    },
    [props.facturenCache],
  );

  const bepaalInkoopfactuur = useCallback(
    async (inkFactID: number) => {
      const cachedInkoopfactuur = props.inkoopfacturenCache[inkFactID];
      if (cachedInkoopfactuur !== undefined) {
        return cachedInkoopfactuur;
      }

      const result = await api.v2.inkoopfactuur.ophalenFacturen({
        filterSchema: {
          filters: [
            {
              naam: 'IDS',
              data: [inkFactID],
            },
          ],
        },
      });
      return result.facturen[0];
    },
    [props.inkoopfacturenCache],
  );

  const bepaalBankmutatie = useCallback(
    async (bankMutID: number) => {
      const cachedBankmutatie = props.bankmutatiesCache[bankMutID];
      if (cachedBankmutatie !== undefined) {
        return cachedBankmutatie;
      }

      const result = await api.v2.bank.mutatie.ophalenMutaties({
        filterSchema: {
          filters: [
            {
              naam: 'IDS',
              data: [bankMutID],
            },
          ],
        },
      });
      return result.mutaties[0];
    },
    [props.bankmutatiesCache],
  );

  const bepaalBetalingsregeling = useCallback(
    async (betRglID: number) => {
      const cachedBetalingsregeling = props.betalingsregelingenCache[betRglID];
      if (cachedBetalingsregeling !== undefined) {
        return cachedBetalingsregeling;
      }

      const result = await api.v2.debiteur.betalingsregeling.ophalenBetalingsregelingen({
        filterSchema: {
          filters: [
            {
              naam: 'IDS',
              data: [betRglID],
            },
          ],
        },
      });
      return result.betalingsregelingen[0];
    },
    [props.betalingsregelingenCache],
  );

  const eenduidigBepaaldeRelID = useMemo<IRemoteData<number | null>>(() => {
    if (props.regels.state === ERemoteDataState.Pending) {
      return createPendingRemoteData();
    }
    const regels = props.regels.data!;
    const eersteRegelMetRelID = regels.find((regel) => regel.relID !== null);
    if (eersteRegelMetRelID === undefined) {
      return createReadyRemoteData(null);
    }

    const alleRegelsMetRelIDHetzelfde = regels
      .filter((x) => x.relID !== null)
      .every((regel) => regel.relID === eersteRegelMetRelID.relID);
    return createReadyRemoteData(alleRegelsMetRelIDHetzelfde ? eersteRegelMetRelID.relID : null);
  }, [props.regels]);

  const keyExtractor = useMemo(() => (regel: Regel) => regel.id.waarde, []);
  const kolommen = useMemo<ASPKolom<EKolom, Regel>[]>(
    () => [
      {
        key: EKolom.Grootboek,
        label: 'Grootboek',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 125,
        renderer: (regel) => {
          switch (regel.state) {
            case ERegelstate.Weergave: {
              if (grootboekenBijID.state === ERemoteDataState.Pending) {
                return <Skeleton />;
              }
              const grootboek = grootboekenBijID.data![regel.grootboekID]!;
              return grootboek?.Nummer;
            }
            case ERegelstate.Muteren: {
              if (
                grootboekenBijID.state === ERemoteDataState.Pending ||
                props.dagboek.state === ERemoteDataState.Pending ||
                props.dagboek.data === null
              ) {
                return <Skeleton />;
              }

              const autoFocus =
                regelfocusbaar !== null &&
                regelfocusbaar.op === ERegelfocusbaarOp.Grootboek &&
                regelfocusbaar.regelID.waarde === regel.id.waarde;

              return (
                <GrootboekSelectie
                  grootboekID={regel.grootboekID}
                  onChange={(grootboekID) => {
                    const grootboek =
                      grootboekID === null ? null : grootboekenBijID.data![grootboekID]!;

                    // Btwsoort alleen toepassen als het dagboeksoort inkoop of verkoop is
                    let btwSoortID: number | null = props.dagboek.data!.dagboekSoort.BtwSrtID;
                    if (
                      props.dagboek.data!.dagboekSoort.NaamEnum === EDagboeksoort.INKOOP ||
                      props.dagboek.data!.dagboekSoort.NaamEnum === EDagboeksoort.VERKOOP
                    ) {
                      btwSoortID = grootboek?.BtwSrtID ?? props.dagboek.data!.dagboekSoort.BtwSrtID;
                    }

                    // Als het grootboek ID wijzigt dan deze waarden uitpoetsen, ze zijn of niet
                    // relevant meer of de gegevens kloppen niet
                    onRegelChange({
                      ...regel,
                      grootboekID,
                      // bedrag: props.nogTeBoeken,
                      relID: null,
                      storno: false,
                      omschrijving: null,
                      werkkostenregelingID: grootboek?.werkkostenRegeling?.ID ?? null,
                      regelboekstuk: null,
                      btwSoortID,
                    });
                  }}
                  grootboekenProvider={grootboekSelectieGrootboekenProvider}
                  autoFocus={autoFocus}
                  relID={eenduidigBepaaldeRelID.data ?? undefined}
                />
              );
            }
          }
        },
      },
      {
        key: EKolom.GrootboeknaamOfDebiteurOfCrediteur,
        label: 'Grootboeknaam / Debiteur / Crediteur',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 350,
        renderer: (regel) => {
          switch (regel.state) {
            case ERegelstate.Weergave: {
              if (regel.relID !== null) {
                return <RelatieVisualisatie relID={regel.relID} />;
              }
              if (grootboekenBijID.state === ERemoteDataState.Pending) {
                return <Skeleton />;
              }
              const grootboek = grootboekenBijID.data![regel.grootboekID]!;
              return grootboek.Naam;
            }
            case ERegelstate.Muteren: {
              if (
                grootboekenBijID.state === ERemoteDataState.Pending ||
                hoedanighedenBijID.state === ERemoteDataState.Pending ||
                props.dagboek.state === ERemoteDataState.Pending ||
                regel.grootboekID === null
              ) {
                return <Skeleton />;
              }
              const grootboek = grootboekenBijID.data![regel.grootboekID]!;
              const isRelatieRegel =
                grootboek.RelatieHoedanigheidID === null
                  ? false
                  : isRelatieHoedanigheid(
                      hoedanighedenBijID.data![grootboek.RelatieHoedanigheidID],
                    );

              if (isRelatieRegel) {
                return (
                  <RelatieMuteren
                    relID={regel.relID}
                    onRelIDChange={async (relID) => {
                      let nieuweRegel: IMuterenRegel = {
                        ...regel,
                        relID,
                        bedrag: 0,
                        storno: false,
                        omschrijving: null,
                        werkkostenregelingID: null,
                        btwSoortID: null,
                        regelboekstuk: null,
                      };
                      if (relID !== null) {
                        setBezigMetVerwerken(true);
                        const result = await api.v2.relatie.ophalenRelaties({
                          filterSchema: {
                            filters: [
                              {
                                naam: 'IDS',
                                data: [relID],
                              },
                            ],
                          },
                        });
                        const relatie = result.relaties[0];
                        nieuweRegel = pasRelatiegegevensToeOpMuterendeRegel(
                          nieuweRegel,
                          relatie,
                          props.dagboek.data!.dagboekSoort,
                          grootboek,
                        );
                        setBezigMetVerwerken(false);
                      }
                      onRegelChange(nieuweRegel);
                    }}
                    regel={regel}
                    grootboek={grootboek}
                    wisbaar
                  />
                );
              }

              return grootboek.Naam;
            }
          }
        },
      },
      {
        key: EKolom.Boekstuk,
        label: 'Boekstuk',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 225,
        renderer: (regel) => {
          switch (regel.state) {
            case ERegelstate.Weergave: {
              if (regel.regelboekstuk === null) {
                return '- -';
              }

              switch (regel.regelboekstuk.type) {
                case ERegelboekstukType.Factuur: {
                  return <FactuurVisualisatie factID={regel.regelboekstuk.factID} />;
                }
                case ERegelboekstukType.Inkoopfactuur: {
                  return <InkoopfactuurVisualisatie inkFactID={regel.regelboekstuk.inkFactID} />;
                }
                case ERegelboekstukType.Bankmutatie: {
                  return 'Bankmutatie';
                }
                case ERegelboekstukType.Betalingsregeling: {
                  return 'Betalingsregeling';
                }
              }
            }
            case ERegelstate.Muteren: {
              if (
                props.dagboeken.state === ERemoteDataState.Pending ||
                grootboekenBijID.state === ERemoteDataState.Pending ||
                hoedanighedenBijID.state === ERemoteDataState.Pending ||
                props.dagboek.state === ERemoteDataState.Pending ||
                regel.grootboekID === null
              ) {
                return <Skeleton />;
              }
              let hoedanigheid: IOphalenHoedanighedenResultElement | null = null;
              const grootboek = grootboekenBijID.data![regel.grootboekID]!;
              if (grootboek.RelatieHoedanigheidID !== null) {
                hoedanigheid = hoedanighedenBijID.data![grootboek.RelatieHoedanigheidID];
              }
              const isRelatieRegel =
                hoedanigheid === null ? false : isRelatieHoedanigheid(hoedanigheid);

              if (grootboek.NaamEnum === 'DEBITEUREN_BETALINGSREGELING') {
                const betRegID =
                  regel.regelboekstuk === null
                    ? null
                    : (regel.regelboekstuk as IRegelboekstukBetalingsregeling).betRglID;
                return (
                  <BetalingsregelingMuteren
                    regel={regel}
                    betRglID={betRegID}
                    onBetRglIDChange={async (betRglID) => {
                      let nieuweRegel: IMuterenRegel = {
                        ...regel,
                        regelboekstuk:
                          betRglID === null
                            ? null
                            : {
                                type: ERegelboekstukType.Betalingsregeling,
                                betRglID,
                              },
                      };
                      onRegelChange(nieuweRegel);
                      if (betRglID === null) {
                        return;
                      }
                      setBezigMetVerwerken(true);

                      const betalingsregeling = await bepaalBetalingsregeling(betRglID);

                      nieuweRegel = {
                        ...nieuweRegel,
                        relID: betalingsregeling.RelID,
                        bedrag: -betalingsregeling.Termijnbedrag,
                      };
                      onRegelChange(nieuweRegel);

                      setBezigMetVerwerken(false);
                    }}
                  />
                );
              }

              if (isRelatieRegel && hoedanigheid !== null) {
                switch (hoedanigheid.NaamEnum as ERelatiehoedanigheid) {
                  case ERelatiehoedanigheid.Klant: {
                    const factID =
                      regel.regelboekstuk === null
                        ? null
                        : (regel.regelboekstuk as IRegelboekstukFactuur).factID;

                    return (
                      <FactuurMuteren
                        regel={regel}
                        factID={factID}
                        onFactIDChange={async (factID) => {
                          let nieuweRegel: IMuterenRegel = {
                            ...regel,
                            regelboekstuk:
                              factID === null
                                ? null
                                : {
                                    type: ERegelboekstukType.Factuur,
                                    factID,
                                  },
                          };
                          onRegelChange(nieuweRegel);
                          if (factID === null) {
                            return;
                          }
                          setBezigMetVerwerken(true);

                          const factuur = await bepaalFactuur(factID);
                          const relatie = (
                            await api.v2.relatie.ophalenRelaties({
                              filterSchema: {
                                filters: [
                                  {
                                    naam: 'IDS',
                                    data: [factuur.RelID],
                                  },
                                ],
                              },
                            })
                          ).relaties[0];

                          nieuweRegel = maakFactuurRegel({
                            base: nieuweRegel,
                            factuur,
                            dagboekSoort: props.dagboek.data!.dagboekSoort,
                            grootboek,
                            relatie,
                          }) as IMuterenRegel;
                          onRegelChange(nieuweRegel);
                          setBezigMetVerwerken(false);
                        }}
                      />
                    );
                  }
                  case ERelatiehoedanigheid.Leverancier: {
                    const inkFactID =
                      regel.regelboekstuk === null
                        ? null
                        : (regel.regelboekstuk as IRegelboekstukInkoopfactuur).inkFactID;

                    return (
                      <InkoopfactuurMuteren
                        inkFactID={inkFactID}
                        onInkFactIDChange={async (inkFactID) => {
                          let nieuweRegel: IMuterenRegel = {
                            ...regel,
                            regelboekstuk:
                              inkFactID === null
                                ? null
                                : {
                                    inkFactID,
                                    type: ERegelboekstukType.Inkoopfactuur,
                                  },
                          };
                          onRegelChange(nieuweRegel);
                          if (inkFactID === null) {
                            return;
                          }
                          setBezigMetVerwerken(true);
                          const inkoopfactuur = await bepaalInkoopfactuur(inkFactID);
                          const relatie = (
                            await api.v2.relatie.ophalenRelaties({
                              filterSchema: {
                                filters: [
                                  {
                                    naam: 'IDS',
                                    data: [inkoopfactuur.RelID],
                                  },
                                ],
                              },
                            })
                          ).relaties[0];
                          nieuweRegel = maakInkoopfactuurRegel({
                            base: nieuweRegel,
                            inkoopfactuur,
                            relatie,
                            grootboek,
                            dagboekSoort: props.dagboek.data!.dagboekSoort,
                          }) as IMuterenRegel;
                          onRegelChange(nieuweRegel);
                          setBezigMetVerwerken(false);
                        }}
                        regel={regel}
                      />
                    );
                  }
                }
              }

              // const tegenrekeningDagboek = useMemo(() => {
              //   return (
              //     props.dagboeken.find(
              //       (dagboek) => dagboek.Tegenrekening_GrbRekID === props.regel.grootboekID,
              //     ) ?? null
              //   );
              // }, [props.regel.grootboekID, props.dagboeken]);

              const tegenrekeningDagboek =
                props.dagboeken.data!.find(
                  (x) => x.Tegenrekening_GrbRekID === regel.grootboekID!,
                ) ?? null;

              if (tegenrekeningDagboek !== null) {
                const bankMutID =
                  regel.regelboekstuk === null
                    ? null
                    : (regel.regelboekstuk as IRegelboekstukBankmutatie).bankMutID;

                return (
                  <BankmutatieMuteren
                    regel={regel}
                    tegenrekeningDagboek={tegenrekeningDagboek}
                    bankMutID={bankMutID}
                    bepaalBankmutatie={bepaalBankmutatie}
                    onRijChange={onRegelChange}
                    setBezigMetVerwerken={setBezigMetVerwerken}
                  />
                );
              }

              return null;
            }
          }
        },
      },
      {
        key: EKolom.Bedrag,
        label: 'Bedrag',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 125,
        renderer: (regel) => {
          switch (regel.state) {
            case ERegelstate.Weergave: {
              return <FormatteerBedrag bedrag={regel.bedrag} />;
            }
            case ERegelstate.Muteren: {
              const autoFocus =
                regelfocusbaar !== null &&
                regelfocusbaar.op === ERegelfocusbaarOp.Bedrag &&
                regelfocusbaar.regelID.waarde === regel.id.waarde;
              return (
                <BedragInput
                  value={regel.bedrag}
                  onChange={(bedrag) => {
                    onRegelChange({ ...regel, bedrag });
                  }}
                  inputStyle={{ width: 95 }}
                  autoFocus={autoFocus}
                />
              );
            }
          }
        },
      },
      {
        key: EKolom.Storno,
        label: 'Storno',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 80,
        renderer: (regel) => {
          switch (regel.state) {
            case ERegelstate.Weergave: {
              return regel.storno ? 'Ja' : 'Nee';
            }
            case ERegelstate.Muteren: {
              return (
                <VinkVeld
                  aangevinkt={regel.storno}
                  onGewijzigd={(storno) => onRegelChange({ ...regel, storno })}
                />
              );
            }
          }
        },
      },
      {
        key: EKolom.BtwCode,
        label: 'BTW-code',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 100,
        renderer: (regel) => {
          if (
            props.btwSoorten.state === ERemoteDataState.Pending ||
            btwSoortenBijID.state === ERemoteDataState.Pending
          ) {
            return <Skeleton />;
          }

          switch (regel.state) {
            case ERegelstate.Weergave: {
              if (regel.btwSoortID === null) {
                return '- -';
              }
              const btwSoort = btwSoortenBijID.data![regel.btwSoortID];
              return btwSoort.Code;
            }
            case ERegelstate.Muteren: {
              return (
                <MultiCombobox<number, IOphalenBtwSoortenResultElement>
                  sleutelExtractor={(btwSoort) => btwSoort.ID}
                  representatieFabriek={(btwSoort) => btwSoort.Code}
                  waarde={regel.btwSoortID}
                  onWaardeChange={(btwSoortID) => onRegelChange({ ...regel, btwSoortID })}
                  opties={props.btwSoorten.data!}
                  kolommen={[
                    {
                      key: 'Code',
                      label: 'Code',
                      breedte: 70,
                    },
                    {
                      key: 'Naam',
                      label: 'Naam',
                      breedte: 150,
                    },
                  ]}
                  options={{
                    geenWaardeBericht: 'Kies',
                  }}
                  isWisbaar
                />
              );
            }
          }
        },
      },
      {
        key: EKolom.BtwBedrag,
        label: 'BTW-bedrag',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 120,
        renderer: (regel) => {
          if (
            props.btwTarieven.state === ERemoteDataState.Pending ||
            props.boekdatum.state === ERemoteDataState.Pending
          ) {
            return <Skeleton />;
          }

          let peildatum = props.boekdatum.data!;
          if (regel.regelboekstuk !== null) {
            switch (regel.regelboekstuk.type) {
              case ERegelboekstukType.Factuur: {
                const factuur = props.facturenCache[regel.regelboekstuk.factID];
                if (factuur === undefined) {
                  return <Skeleton />;
                }
                peildatum = new Date(factuur.Factuurdatum);
                break;
              }
              case ERegelboekstukType.Inkoopfactuur: {
                const inkoopfactuur = props.inkoopfacturenCache[regel.regelboekstuk.inkFactID];
                if (inkoopfactuur === undefined) {
                  return <Skeleton />;
                }
                peildatum = new Date(inkoopfactuur.Factuurdatum);
                break;
              }
              case ERegelboekstukType.Bankmutatie: {
                const bankmutatie = props.bankmutatiesCache[regel.regelboekstuk.bankMutID];
                if (bankmutatie === undefined) {
                  return <Skeleton />;
                }
                peildatum = new Date(bankmutatie.Mutatiedatum);
                break;
              }
            }
          }

          return (
            <BtwBedrag
              bedrag={regel.bedrag}
              btwSoortID={regel.btwSoortID}
              btwTarieven={props.btwTarieven.data!}
              peildatum={peildatum}
            />
          );
        },
      },
      {
        key: EKolom.Werkkostenregeling,
        label: 'Werkkostenregeling',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 150,
        renderer: (regel) => {
          switch (regel.state) {
            case ERegelstate.Weergave: {
              if (regel.werkkostenregelingID === null) {
                return '- -';
              }
              if (werkkostenregelingenBijID.state === ERemoteDataState.Pending) {
                return <Skeleton />;
              }
              const werkkostenregeling = werkkostenregelingenBijID.data![
                regel.werkkostenregelingID
              ];
              return werkkostenregeling.Naam;
            }
            case ERegelstate.Muteren: {
              if (props.werkkostenregelingen.state === ERemoteDataState.Pending) {
                return <Skeleton />;
              }

              return (
                <Combobox<number>
                  geselecteerd={regel.werkkostenregelingID}
                  opties={props.werkkostenregelingen.data!.map((regeling) => ({
                    id: regeling.ID,
                    label: regeling.Naam,
                  }))}
                  onSelectieChange={(werkkostenregelingID) =>
                    onRegelChange({
                      ...regel,
                      werkkostenregelingID,
                    })
                  }
                  legeOptieTonen
                  options={{
                    legeOptieTekst: 'Kies',
                  }}
                />
              );
            }
          }
        },
      },
      {
        key: EKolom.Omschrijving,
        label: 'Omschrijving',
        breedteType: EAspKolomBreedteType.Flex,
        flex: 1,
        renderer: (regel) => {
          switch (regel.state) {
            case ERegelstate.Weergave: {
              if (regel.omschrijving !== null) {
                return regel.omschrijving;
              }

              if (regel.regelboekstuk !== null) {
                const spanStyle: React.CSSProperties = {
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  color: '#b4b4b4',
                };

                switch (regel.regelboekstuk.type) {
                  case ERegelboekstukType.Factuur: {
                    const factuur = props.facturenCache[regel.regelboekstuk.factID];
                    if (factuur === undefined) {
                      return <Skeleton />;
                    }
                    if (factuur.Onderwerp !== null) {
                      return (
                        <span style={spanStyle} title={factuur.Onderwerp}>
                          {factuur.Onderwerp}
                        </span>
                      );
                    }
                    break;
                  }
                  case ERegelboekstukType.Inkoopfactuur: {
                    const inkoopfactuur = props.inkoopfacturenCache[regel.regelboekstuk.inkFactID];
                    if (inkoopfactuur === undefined) {
                      return <Skeleton />;
                    }
                    if (inkoopfactuur.Onderwerp !== null) {
                      return (
                        <span style={spanStyle} title={inkoopfactuur.Onderwerp}>
                          {inkoopfactuur.Onderwerp}
                        </span>
                      );
                    }
                    break;
                  }
                  case ERegelboekstukType.Bankmutatie:
                    const bankmutatie = props.bankmutatiesCache[regel.regelboekstuk.bankMutID];
                    if (bankmutatie === undefined) {
                      return <Skeleton />;
                    }
                    if (bankmutatie.Omschrijving !== null) {
                      return (
                        <span style={spanStyle} title={bankmutatie.Omschrijving}>
                          {bankmutatie.Omschrijving}
                        </span>
                      );
                    }
                    break;
                }
              }

              return '- -';
            }
            case ERegelstate.Muteren: {
              const autoFocus =
                regelfocusbaar !== null &&
                regelfocusbaar.op === ERegelfocusbaarOp.Omschrijving &&
                regelfocusbaar.regelID.waarde === regel.id.waarde;
              return (
                <input
                  type="text"
                  className="form-control"
                  value={regel.omschrijving ?? ''}
                  autoFocus={autoFocus}
                  onChange={(ev) => {
                    const value = ev.target.value;
                    const nieuweOmschrijving = value === '' ? null : value;
                    onRegelChange({
                      ...regel,
                      omschrijving: nieuweOmschrijving,
                    });
                  }}
                />
              );
            }
          }
        },
      },
    ],
    [
      props.dagboek,
      props.grootboeken,
      props.dagboeken,
      grootboekenBijID,
      grootboekSelectieGrootboekenProvider,
      btwSoortenBijID,
      werkkostenregelingenBijID,
      hoedanighedenBijID,
      isRelatieHoedanigheid,
      props.btwTarieven,
      props.facturenCache,
      props.inkoopfacturenCache,
      props.bankmutatiesCache,
      onRegelChange,
      setBezigMetVerwerken,
      bepaalFactuur,
      bepaalInkoopfactuur,
      bepaalBankmutatie,
      regelfocusbaar,
      eenduidigBepaaldeRelID,
    ],
  );

  const magMuterendeRegelOpslaan = useCallback((regel: IMuterenRegel) => {
    return regel.grootboekID !== null;
  }, []);

  const verwijderenTdComponent = useCallback(
    (p: IVerwijderenTableDataProps<Regel>) => {
      if (p.row !== undefined && p.row.state === ERegelstate.Muteren) {
        const inhoud = magMuterendeRegelOpslaan(p.row) ? (
          <a
            href="#"
            onClick={async (ev) => {
              setBezigMetVerwerken(true);

              const omschrijving =
                p.row!.omschrijving?.trim().length === 0 ? null : p.row!.omschrijving;
              const nieuweRegel = {
                ...p.row!,
                state: ERegelstate.Weergave,
                omschrijving,
                grootboekID: p.row!.grootboekID!,
              } as IWeergaveRegel;

              const nieuweRegels = props.regels.data!.map((regel) =>
                regel.id === nieuweRegel.id ? nieuweRegel : regel,
              );
              const result = await props.regelModificatieNaOpslaanProvider.provide({
                opgeslagenRegel: nieuweRegel,
                regels: nieuweRegels,
              });
              props.onRegelsChange(result.regels);
              if (result.regelfocusbaar !== undefined) {
                setRegelfocusbaar(result.regelfocusbaar);
              }

              setBezigMetVerwerken(false);
            }}
          >
            Ok
          </a>
        ) : (
          <span>Ok</span>
        );

        return (
          <TableData
            tableKey={null}
            row={p.row}
            isGefocust={p.isGefocust}
            isGehovered={p.isGehovered}
            heeftUitgeklapteRijOnderZich={p.heeftUitgeklapteRijOnderZich}
            rowIndex={p.rowIndex}
          >
            {inhoud}
          </TableData>
        );
      }
      return <StandaardVerwijderenTableData {...p} />;
    },
    [
      onRegelChange,
      magMuterendeRegelOpslaan,
      setRegelfocusbaar,
      props.onRegelsChange,
      props.regels,
    ],
  );

  const wijzigenTdComponent = useCallback(
    (p: IWijzigenTableDataProps<Regel>) => {
      if (p.row !== undefined && p.row.state === ERegelstate.Muteren) {
        return (
          <TableData
            tableKey={null}
            row={p.row}
            isGefocust={p.isGefocust}
            isGehovered={p.isGehovered}
            heeftUitgeklapteRijOnderZich={p.heeftUitgeklapteRijOnderZich}
            rowIndex={p.rowIndex}
          >
            <a
              href="#"
              onClick={() => {
                const cachedRegel = regelsMuterenAnnulerenCache.current[p.row!.id.waarde];
                if (cachedRegel === undefined) {
                  props.onRegelsChange(
                    props.regels.data!.filter((r) => r.id.waarde !== p.row!.id.waarde),
                  );
                  return;
                }
                onRegelChange(cachedRegel);
              }}
            >
              Annuleren
            </a>
          </TableData>
        );
      }
      return <StandaardWijzigenTableData {...p} />;
    },
    [onRegelChange, props.onRegelsChange, props.regels],
  );

  const handleToevoegenRij = useMemo(() => {
    if (
      props.regels.state === ERemoteDataState.Pending ||
      props.nogTeBoeken.state === ERemoteDataState.Pending
    ) {
      return null;
    }

    const regel = maakNieuweRegel();
    regel.bedrag = -props.nogTeBoeken.data!;

    return () => {
      props.onRegelsChange([...props.regels.data!, regel]);
    };
  }, [props.regels, props.onRegelsChange, props.nogTeBoeken]);

  const interactieToegestaan = useMemo(
    () =>
      !isBezigMetToepassenRelatiegegevens &&
      props.isDefinitief.state === ERemoteDataState.Ready &&
      !props.isDefinitief.data!,
    [isBezigMetToepassenRelatiegegevens, props.isDefinitief],
  );

  return (
    <InactiefOverlay
      rootStyle={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
      isInactief={!interactieToegestaan}
      element={
        <ASPTabel
          rijen={props.regels.data ?? {}}
          kolommen={kolommen}
          keyExtractor={keyExtractor}
          totaalAantalRijen={props.regels.data?.length ?? 6}
          onToevoegenRij={handleToevoegenRij ?? undefined}
          onWijzigenRij={async (rij) => {
            regelsMuterenAnnulerenCache.current = {
              ...regelsMuterenAnnulerenCache.current,
              [rij.id.waarde]: rij as IWeergaveRegel,
            };
            onRegelChange({
              ...rij,
              state: ERegelstate.Muteren,
            });
          }}
          onVerwijderenRij={async (rij) => {
            props.onRegelsChange(props.regels.data!.filter((r) => r.id.waarde !== rij.id.waarde));
          }}
          verwijderenRijConfirmatie={null}
          verwijderenTdComponent={verwijderenTdComponent}
          wijzigenTdComponent={wijzigenTdComponent}
          verwijderenKolomBreedte={50}
          wijzigenKolomBreedte={90}
          leegComponent={LeegComponent}
        />
      }
    />
  );
};

export default Regels;
