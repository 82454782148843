import {
  IOphalenBetaalwijzenParams,
  IOphalenBetaalwijzenResult,
} from '../../../../../shared/src/api/v2/betaalwijze';
import api from '../../index';
import ICheckData from '../../../../../shared/src/models/ICheckData';

const betaalwijze = {
  ophalenBetaalwijzen: async (
    params: IOphalenBetaalwijzenParams,
  ): Promise<IOphalenBetaalwijzenResult> => {
    return await api.post('/v2/betaalwijze/ophalen-betaalwijzen', params);
  },
};

export default betaalwijze;
