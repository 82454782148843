import React from 'react';
import { IUitgeklapteInhoudProps } from '../..';
import GegevensLayout from '../../../../components/layout/GegevensLayout';
import { IPotentieelOntvangenWerkbonItem } from '../../../../../../shared/src/api/v2/externeData';
import RelatieVisualisatie from '../../../../components/personalia/RelatieVisualisatie';
import { format } from 'date-fns';
import FormatteerBedrag from '../../../../components/MutatieBedrag';
import ServicedienstVisualisatie from '../../../../components/entiteitVisualisaties/ServicedienstVisualisatie';
import ServiceopdrachtVisualisatie from '../../../../components/entiteitVisualisaties/ServiceopdrachtVisualisatie';
import BestandVisualisatie from '../../../../components/entiteitVisualisaties/BestandVisualisatie';

const WerkbonUitgeklapteInhoud = <TRow extends any>(
  props: IUitgeklapteInhoudProps<TRow, IPotentieelOntvangenWerkbonItem>,
) => {
  return (
    <GegevensLayout
      labelBreedte={200}
      gegevens={[
        {
          label: 'Bron bestand',
          waarde:
            props.item.Bron_BestandID === null ? null : (
              <BestandVisualisatie bestandID={props.item.Bron_BestandID} />
            ),
        },
        {
          label: 'Info bestand',
          waarde:
            props.item.Info_BestandID === null ? null : (
              <BestandVisualisatie bestandID={props.item.Info_BestandID} />
            ),
        },
        null,
        {
          label: 'Relatie',
          waarde:
            props.item.RelID === null ? null : <RelatieVisualisatie relID={props.item.RelID} />,
        },
        {
          label: 'Bezoekdatum',
          waarde:
            props.item.Bezoekdatum === null
              ? null
              : format(new Date(props.item.Bezoekdatum), 'dd-MM-yyyy'),
        },
        {
          label: 'Opdrachtnummer extern',
          waarde: props.item.OpdrachtnummerExtern === null ? null : props.item.OpdrachtnummerExtern,
        },
        {
          label: 'Werkzaamheden',
          waarde: props.item.Werkzaamheden === null ? null : props.item.Werkzaamheden,
        },
        {
          label: 'Serienummer',
          waarde: props.item.Serienummer === null ? null : props.item.Serienummer,
        },
        {
          label: 'Postcode',
          waarde: props.item.Postcode === null ? null : props.item.Postcode,
        },
        {
          label: 'Huisnummer',
          waarde: props.item.Huisnummer === null ? null : props.item.Huisnummer,
        },
        {
          label: 'Bisnummer',
          waarde: props.item.Bisnummer === null ? null : props.item.Bisnummer,
        },
        {
          label: 'Kosten totaal',
          waarde:
            props.item.KostenTotaal === null ? null : (
              <FormatteerBedrag bedrag={props.item.KostenTotaal} />
            ),
        },
        {
          label: 'Referentiecode',
          waarde: props.item.Referentiecode === null ? null : props.item.Referentiecode,
        },
        {
          label: 'Opdrachtnummer',
          waarde: props.item.Opdrachtnummer === null ? null : props.item.Opdrachtnummer,
        },
        {
          label: 'Naam monteur',
          waarde: props.item.NaamMonteur === null ? null : props.item.NaamMonteur,
        },
        {
          label: 'Servicedienst',
          waarde:
            props.item.ServDienstID === null ? null : (
              <ServicedienstVisualisatie servDienstID={props.item.ServDienstID} />
            ),
        },
        {
          label: 'Serviceopdracht',
          waarde:
            props.item.ServOpdID === null ? null : (
              <ServiceopdrachtVisualisatie servOpdID={props.item.ServOpdID} />
            ),
        },
        {
          label: 'Merknaam',
          waarde: props.item.Merknaam === null ? null : props.item.Merknaam,
        },
        {
          label: 'Typenaam',
          waarde: props.item.Typenaam === null ? null : props.item.Typenaam,
        },
      ]}
    />
  );
};

export default WerkbonUitgeklapteInhoud;
