import { DataTypeProvider, IntegratedSorting, SortingState } from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableColumnResizing,
  TableHeaderRow,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import { format } from 'date-fns';
import _ from 'lodash';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { ProductSelectieDialoogContext } from '..';
import { ITekstPrecisieData } from '../../../../../../shared/src/api/sql';
import {
  IOphalenLijstRecentResultElement,
  IOphalenProductenResultElementV2,
} from '../../../../../../shared/src/api/v2/product';
import { IFilterSchemaFilter } from '../../../../../../shared/src/models/filter';
import api from '../../../../api';
import { maandenNaarJaren } from '../../../../bedrijfslogica/teksten';
import nameOf from '../../../../core/nameOf';
import {
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../models/IRemoteData';
import TekstPrecisieSelectie, { ETekstPrecisie } from '../../../formulier/TekstPrecisieSelectie';
import VinkVeld from '../../../formulier/VinkVeld';
import LoadingSpinner from '../../../Gedeeld/LoadingSpinner';

export interface IFormikValues {
  referentiecode: string;
  referentiecodeTekstprecisie: ETekstPrecisie;
  postcode: string;
  typenaam: string;
  typenaamTekstprecisie: ETekstPrecisie;
  ookProductenUitBedrijf: boolean;
  notities: string;
  notitiesTekstprecisie: ETekstPrecisie;
}

interface IProps {}

const maxAantalResultaten = 25;

const SelecterenTabblad: React.FC<IProps> = (props) => {
  const { koppelOpties, selecterenValues, setSelecterenValues, onGekozen } = useContext(
    ProductSelectieDialoogContext,
  );

  // const [producten, setProducten] = useState<IOphalenProductenResultElementV2[] | null>(null);
  const [lijstRecent, setLijstRecent] = useState<IOphalenLijstRecentResultElement[] | null>(null);

  const ophalenProducten = useCallback(async () => {
    const result = await api.v2.product.ophalenLijstRecent({
      aspGebrID: null,
      filterSchema: {
        filters: [],
      },
      paginatie: { index: 0, aantal: 100 },
      // orderSchema: { orders: [{ naam: 'RECORD_GEWIJZIGD', richting: 'DESC' }] },
    });
    setLijstRecent(result.producten);
  }, []);

  useEffect(() => {
    ophalenProducten();
  }, [ophalenProducten]);

  const sleutelExtractor = useCallback((row: IOphalenLijstRecentResultElement) => row.ID, []);

  const kolommen = useMemo<TypedColumn<IOphalenLijstRecentResultElement>[]>(
    () => [
      {
        name: 'Merknaam' as any,
        title: 'Merk',
      },
      {
        name: 'Typenaam' as any,
        title: 'Type',
      },
      {
        name: 'Referentiecode',
        title: 'Ref.code',
      },
      // {
      //   name: 'DatumInBedrijf',
      //   title: 'In bedrijf',
      // },
      // {
      //   name: 'DatumUitBedrijf',
      //   title: 'Uit bedrijf',
      // },
      // {
      //   name: 'Notities',
      //   title: 'Notities',
      // },
      // {
      //   name: 'RecordGewijzigd',
      //   title: 'Gewijzigd op',
      // },
      {
        name: '__kies_actie',
        title: ' ',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IOphalenLijstRecentResultElement>[]>(
    () => [
      { columnName: 'Merknaam' as any, width: 90 },
      { columnName: 'Typenaam' as any, width: 165 },
      {
        columnName: 'Referentiecode',
        width: 95,
      },
      // {
      //   columnName: 'DatumInBedrijf',
      //   width: 105,
      // },
      // {
      //   columnName: 'DatumUitBedrijf',
      //   width: 105,
      // },
      // {
      //   columnName: 'Notities',
      //   width: 250,
      // },
      // {
      //   columnName: 'RecordGewijzigd',
      //   width: 150,
      // },
      {
        columnName: '__kies_actie' as any,
        width: 80,
      },
    ],
    [],
  );

  return (
    <div className="d-flex flex-fill flex-column align-items-center justify-content-center p-4">
      {lijstRecent === null ? (
        <LoadingSpinner />
      ) : (
        <GridStyleWrapper rowAmount={lijstRecent.length} maxHeight={650}>
          <Grid rows={lijstRecent} columns={kolommen} getRowId={sleutelExtractor}>
            <DataTypeProvider
              for={['Merknaam']}
              formatterComponent={(props) => {
                const rij: IOphalenLijstRecentResultElement = props.row;
                return <span>{rij.Merknaam}</span>;
              }}
            />

            <DataTypeProvider
              for={['Typenaam']}
              formatterComponent={(props) => {
                const rij: IOphalenLijstRecentResultElement = props.row;
                return <span>{rij.Typenaam}</span>;
              }}
            />

            {/* <DataTypeProvider
              for={['__leeftijd']}
              formatterComponent={(props) => {
                const rij: IOphalenProductenResultElementV2 = props.row;
                if (rij.leeftijd === null) {
                  return <span></span>;
                }
                return <span>{maandenNaarJaren(rij.leeftijd)}</span>;
              }}
            />

            <DataTypeProvider
              for={['DatumInBedrijf']}
              formatterComponent={(formatterProps) => {
                const rij: IOphalenProductenResultElementV2 = formatterProps.row;
                return <span>{format(new Date(rij.DatumInBedrijf), 'dd-MM-yyyy')}</span>;
              }}
            />

            <DataTypeProvider
              for={['DatumUitBedrijf']}
              formatterComponent={(formatterProps) => {
                const rij: IOphalenProductenResultElementV2 = formatterProps.row;
                if (rij.DatumInBedrijf === null) {
                  return <span></span>;
                }
                return <span>{format(new Date(rij.DatumInBedrijf), 'dd-MM-yyyy')}</span>;
              }}
            />

            <DataTypeProvider
              for={['RecordGewijzigd']}
              formatterComponent={(formatterProps) => {
                // const rij: IOphalenProductenResultElementV2 = formatterProps.row;
                return <span>{format(new Date(formatterProps.value), 'dd-MM-yyyy HH:mm')}</span>;
              }}
            /> */}

            <DataTypeProvider
              for={['__kies_actie']}
              formatterComponent={(formatterProps) => {
                const rij: IOphalenLijstRecentResultElement = formatterProps.row;

                return (
                  <a
                    href="#"
                    onClick={(ev) => {
                      ev.stopPropagation();
                      onGekozen(rij.ProdID);
                    }}
                  >
                    Kies
                  </a>
                );
              }}
            />

            <Table
              messages={{
                noData: 'Geen resultaten',
              }}
            />
            <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
            <TableHeaderRow />
          </Grid>
        </GridStyleWrapper>
      )}
    </div>
  );
};

export default SelecterenTabblad;
