import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';
import { IWeergaveProps } from '../../../../../components/FilterBalkV2';
import { BerichtenFilter } from '../index';
import MultiComboboxV2MetZoeken, {
  IMultiComboboxV2MetZoekenOverlayState,
} from '../../../../../components/formulier/MultiComboboxV2/MetZoeken';
import { IRepresentatieProps, Provider } from '../../../../../components/formulier/MultiComboboxV2';
import { ASPKolom, EAspKolomBreedteType } from '../../../../../components/tabel/ASPTabel/types';
import { IBulkbericht } from '../../../../../../../shared/src/api/v2/bulkbericht';
import api from '../../../../../api';
import { IFilterSchemaFilter } from '../../../../../../../shared/src/models/filter';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../../models/IRemoteData';
import { ISjabloon } from '../../../../../../../shared/src/api/v2/Sjabloon/sjabloon';
import Skeleton from 'react-loading-skeleton';
import VerticaleScheidingslijn from '../../../../../components/layout/VerticaleScheidingslijn';

interface IData {
  sjablonenBijSjabID: { [sjabID: number]: ISjabloon };
}

interface IBulkberichtFilterContext {
  data: IRemoteData<IData>;
}

const BulkberichtFilterContext = React.createContext<IBulkberichtFilterContext>(null!);

type Kolom = 'nummer' | 'sjabloon';

const BulkberichtFilter = (props: IWeergaveProps<BerichtenFilter>) => {
  const [data, setData] = useState<IRemoteData<IData>>(createPendingRemoteData());
  const dataRef = useRef(data);
  const setDataRef = useCallback((data: IRemoteData<IData>) => {
    dataRef.current = data;
    setData(data);
  }, []);

  const provider = useMemo<Provider<Kolom, IBulkbericht, IMultiComboboxV2MetZoekenOverlayState>>(
    () => async (params) => {
      const zoekterm = params.overlayContainerState.zoekterm.trim();
      const heeftZoekterm = zoekterm.length > 0;

      const result = await api.v2.bulkbericht.ophalenBulkberichten({
        filterSchema: {
          filters: [
            heeftZoekterm
              ? {
                  naam: 'ZOEKTERM',
                  data: zoekterm,
                }
              : null,
          ].filter((x) => x !== null) as IFilterSchemaFilter[],
        },
        paginatie: params.paginatie,
        orderSchema: {
          orders: [
            {
              naam: 'ID',
              richting: 'DESC',
            },
          ],
        },
      });

      const items = result.items.reduce((acc, curr, i) => {
        const index = params.paginatie.index + i;
        acc[index] = curr;
        return acc;
      }, params.huidigeBron);

      let sjablonenBijSjabID = dataRef.current.data?.sjablonenBijSjabID ?? {};

      const sjabIDs = result.items.map((item) => item.SjabID);
      const missendeSjabIDs = sjabIDs.filter((sjabID) => {
        if (dataRef.current.state === ERemoteDataState.Pending) {
          return true;
        }

        return dataRef.current.data!.sjablonenBijSjabID[sjabID] === undefined;
      });
      if (missendeSjabIDs.length > 0) {
        const result = await api.v2.sjabloon.ophalenSjablonen({
          filterSchema: {
            filters: [
              {
                naam: 'IDS',
                data: missendeSjabIDs,
              },
            ],
          },
        });
        sjablonenBijSjabID = result.sjablonen.reduce<IData['sjablonenBijSjabID']>((acc, curr) => {
          acc[curr.ID] = curr;
          return acc;
        }, sjablonenBijSjabID);
      }

      setDataRef(
        createReadyRemoteData({
          sjablonenBijSjabID,
        }),
      );

      return {
        items,
        totaalAantal: result.totaalAantal,
      };
    },
    [],
  );
  const enkeleProvider = useMemo(
    () => async (id: number) => {
      const result = await api.v2.bulkbericht.ophalenBulkberichten({
        filterSchema: {
          filters: [
            {
              naam: 'IDS',
              data: [id],
            },
          ],
        },
      });
      return result.items[0];
    },
    [],
  );
  const keyExtractor = useCallback((item: IBulkbericht) => item.ID, []);

  const handleChange = useCallback(
    (id: number | null) => {
      props.onDataChange(id);
      props.setIsActief(id !== null);
      props.toepassen();
    },
    [props.onDataChange, props.setIsActief, props.toepassen],
  );

  const kolommen = useMemo<ASPKolom<Kolom, IBulkbericht>[]>(
    () => [
      {
        key: 'nummer',
        label: 'Nummer',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 100,
        renderer: (item) => item.Nummer,
      },
      {
        key: 'sjabloon',
        label: 'Sjabloon',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 350,
        renderer: (item) => {
          if (data.state === ERemoteDataState.Pending) {
            return <Skeleton />;
          }
          const sjabloon = data.data!.sjablonenBijSjabID[item.SjabID];
          return sjabloon.Naam;
        },
      },
    ],
    [data],
  );

  const context = useMemo<IBulkberichtFilterContext>(() => ({ data }), [data]);

  return (
    <BulkberichtFilterContext.Provider value={context}>
      <div className="d-flex align-items-center">
        <span className="mr-2">Bulkbericht</span>
        <MultiComboboxV2MetZoeken
          provider={provider}
          enkeleProvider={enkeleProvider}
          keyExtractor={keyExtractor}
          waarde={props.data}
          onChange={handleChange}
          representatieComponent={Representatie}
          kolommen={kolommen}
          wisbaar
        />
      </div>
    </BulkberichtFilterContext.Provider>
  );
};

const Representatie = (props: IRepresentatieProps<IBulkbericht>) => {
  const context = useContext(BulkberichtFilterContext);
  if (context.data.state === ERemoteDataState.Pending) {
    return <Skeleton />;
  }
  const sjabloon = context.data.data!.sjablonenBijSjabID[props.entiteit.SjabID];

  return (
    <div className="d-flex">
      <span>{props.entiteit.Nummer}</span>
      <VerticaleScheidingslijn className="ml-3 mr-3" />
      <span>{sjabloon.Naam}</span>
    </div>
  );
};

export default BulkberichtFilter;
