import React, { useCallback, useContext, useMemo, useState, useEffect } from 'react';
import IDialoogProps from '../../../../core/IDialoogProps';
import { IRow } from '../index';
import Dialoog from '../../../../components/dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import { Formik, FormikActions, FormikProps, FormikErrors, Field, FieldProps } from 'formik';
import {
  ISjabloonContext,
  IWijzigenSjabloonParams,
  ISjabloonBasis,
  ISjabloonCategorie,
} from '../../../../../../shared/src/api/v2/Sjabloon/sjabloon';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../../stores/RootStore';
import api from '../../../../api';
import { EResultType } from '../../../../stores/CheckStore';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import nameOf from '../../../../core/nameOf';
import FormikVeldFout from '../../../../components/formulier/FormikVeldFout';
import teksten from '../../../../bedrijfslogica/teksten';
import VinkVeld from '../../../../components/formulier/VinkVeld';
import Combobox, { IOptie } from '../../../../components/formulier/Combobox';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import UitlegTooltip from '../../../../components/formulier/UitlegTooltip';
import SjabCtxsVeld, { ISjabCtxFormValue } from './SjabCtxsVeld';

interface IFormikValues {
  isConcept: boolean;
  isSysteemSjabloon: boolean;
  isHoofdSjabloon: boolean;
  naam: string;
  omschrijving: string;
  naamEnum: string;
  code: string;
  trefwoord: string;
  sjabCatID: number | null;
  sjabCtxs: ISjabCtxFormValue[];
}

const veldnamen: Record<keyof IFormikValues, string> = {
  isConcept: 'Concept',
  isSysteemSjabloon: 'Systeem sjabloon',
  isHoofdSjabloon: 'Hoofd sjabloon',
  naam: 'Naam',
  omschrijving: 'Omschrijving',
  naamEnum: 'Naam Enum',
  code: 'Code',
  trefwoord: 'Trefwoord',
  sjabCatID: 'Categorie',
  sjabCtxs: 'Contexten',
};

interface IProps extends IDialoogProps {
  row: IRow;
}

const WijzigenDialoog: React.FC<IProps> = observer((props) => {
  const { checkStore } = useContext(RootStoreContext);

  const [categorieen, setCategorieen] = useState<ISjabloonCategorie[] | null>(null);
  const [basissen, setBasissen] = useState<ISjabloonBasis[] | null>(null);
  const [contexten, setContexten] = useState<ISjabloonContext[] | null>(null);

  useEffect(() => {
    (async () => {
      const result = await api.v2.sjabloon.ophalenSjabloonCategorieen({
        filterSchema: {
          filters: [],
        },
      });
      setCategorieen(result.categorieen);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const result = await api.v2.sjabloon.ophalenSjabloonBasissen({
        filterSchema: {
          filters: [],
        },
      });
      setBasissen(result.basissen);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const result = await api.v2.sjabloon.ophalenSjabloonContexten({
        filterSchema: {
          filters: [],
        },
      });
      setContexten(result.contexten);
    })();
  }, []);

  const categorieOpties = useMemo<IOptie<number>[] | null>(() => {
    if (categorieen === null) {
      return null;
    }

    return categorieen.map((categorie) => ({
      id: categorie.SjabCatID,
      label: categorie.Naam,
    }));
  }, [categorieen]);

  const basisOpties = useMemo<IOptie<number>[] | null>(() => {
    if (basissen === null) {
      return null;
    }

    return basissen.map((basis) => ({
      id: basis.ID,
      label: basis.Naam,
    }));
  }, [basissen]);

  const initialValues = useMemo<IFormikValues>(() => {
    const sjab = props.row.sjabloon;
    return {
      code: sjab.Code || '',
      isConcept: sjab.IsConcept,
      isSysteemSjabloon: sjab.IsSysteemSjabloon,
      isHoofdSjabloon: sjab.IsHoofdSjabloon,
      naam: sjab.Naam,
      omschrijving: sjab.Omschrijving || '',
      naamEnum: sjab.NaamEnum || '',
      sjabCatID: sjab.SjabCatID,
      sjabCtxs: sjab.sjabCtxs as any,
      trefwoord: sjab.Trefwoord || '',
    };
  }, [props.row]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      let naamEnum: string | null = values.naamEnum.trim();
      if (naamEnum.length === 0) {
        naamEnum = null;
      }
      let code: string | null = values.code.trim();
      if (code.length === 0) {
        code = null;
      }
      let trefwoord: string | null = values.trefwoord.trim();
      if (trefwoord.length === 0) {
        trefwoord = null;
      }

      const params: IWijzigenSjabloonParams = {
        sjabID: props.row.sjabloon.ID,
        isConcept: values.isConcept,
        isSysteemSjabloon: values.isSysteemSjabloon,
        isHoofdSjabloon: values.isHoofdSjabloon,
        naam: values.naam.trim(),
        omschrijving: values.omschrijving.trim() !== '' ? values.omschrijving.trim() : null,
        naamEnum,
        code,
        trefwoord,
        sjabCatID: values.sjabCatID,
        sjabCtxs: values.sjabCtxs,
        kanalen: props.row.kanalen.map((kanaal) => {
          return {
            comKanID: kanaal.communicatiekanaal.ComKanID,
            sjabBasisID: kanaal.kanaal.SjabBasisID,
            inhouden: kanaal.inhouden.map((inhoud) => ({
              onderwerp: inhoud.inhoud.Onderwerp,
              inhoud: inhoud.inhoud.Inhoud,
              taalID: inhoud.taal.TaalID,
              toepassen: inhoud.inhoud.Toepassen,
              bestanden: inhoud.bestanden.map((x) => ({ bestID: x.ID })),
              systeemDataContext: inhoud.inhoud.SysteemDataContext,
            })),
          };
        }),
      };
      const checkData = await api.v2.sjabloon.checkWijzigenSjabloon(params);
      if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
        actions.setSubmitting(false);
        return;
      }

      await api.v2.sjabloon.wijzigenSjabloon(params);
      props.onSuccess(null);

      actions.setSubmitting(false);
    },
    [props.onSuccess],
  );

  const validateForm = useCallback((values: IFormikValues) => {
    const errors: FormikErrors<IFormikValues> = {};

    if (values.naam.trim().length === 0) {
      errors.naam = teksten.formulier.E_VERPLICHT_VELD({ veldnaam: veldnamen.naam });
    }

    return errors;
  }, []);

  return (
    <Dialoog index={props.dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Wijzigen sjabloon</ModalTitle>
      </ModalHeader>
      <Formik<IFormikValues>
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validate={validateForm}
        render={(formikProps: FormikProps<IFormikValues>) => {
          const { submitForm, isSubmitting, isValid } = formikProps;

          return (
            <>
              <ModalBody>
                <div className="form-group">
                  <div className="row">
                    <div className="col-12">
                      <label>{veldnamen.naam}</label>
                      <Field
                        name={nameOf<IFormikValues>('naam')}
                        render={(fieldProps: FieldProps<IFormikValues>) => {
                          const { field, form } = fieldProps;
                          return (
                            <div>
                              <input className="form-control" type="text" {...field} />
                              <FormikVeldFout fieldProps={fieldProps} />
                            </div>
                          );
                        }}
                      />
                    </div>

                    <div className="col-12 mt-2">
                      <label>{veldnamen.omschrijving}</label>
                      <Field
                        name={nameOf<IFormikValues>('omschrijving')}
                        render={(fieldProps: FieldProps<IFormikValues>) => {
                          const { field, form } = fieldProps;
                          return (
                            <div>
                              <textarea className="form-control" {...field} rows={2} />
                              <FormikVeldFout fieldProps={fieldProps} />
                            </div>
                          );
                        }}
                      />
                    </div>

                    <div className="col-12 mt-2">
                      <span className="d-flex">
                        <label>{veldnamen.naamEnum}</label>
                        <span className="ml-2">
                          <UitlegTooltip inhoud="Ter referentie in de applicatie code" />
                        </span>
                      </span>
                      <Field
                        name={nameOf<IFormikValues>('naamEnum')}
                        render={(fieldProps: FieldProps<IFormikValues>) => {
                          const { field, form } = fieldProps;
                          return (
                            <div>
                              <input className="form-control" type="text" {...field} />
                              <FormikVeldFout fieldProps={fieldProps} />
                            </div>
                          );
                        }}
                      />
                    </div>

                    <div className="col-12 mt-2">
                      <label>{veldnamen.code}</label>
                      <Field
                        name={nameOf<IFormikValues>('code')}
                        render={(fieldProps: FieldProps<IFormikValues>) => {
                          const { field, form } = fieldProps;
                          return (
                            <div>
                              <input className="form-control" type="text" {...field} />
                              <FormikVeldFout fieldProps={fieldProps} />
                            </div>
                          );
                        }}
                      />
                    </div>

                    <div className="col-12 mt-2">
                      <span className="d-flex">
                        <label>{veldnamen.trefwoord}</label>
                        <span className="ml-2">
                          <UitlegTooltip inhoud="Wordt gebruikt bij het zoeken naar dit sjabloon, e.g. 'Woonpunt, factuur'" />
                        </span>
                      </span>
                      <Field
                        name={nameOf<IFormikValues>('trefwoord')}
                        render={(fieldProps: FieldProps<IFormikValues>) => {
                          const { field, form } = fieldProps;
                          return (
                            <div>
                              <input className="form-control" type="text" {...field} />
                              <FormikVeldFout fieldProps={fieldProps} />
                            </div>
                          );
                        }}
                      />
                    </div>

                    <div className="col-12 mt-2">
                      <label>{veldnamen.sjabCatID}</label>
                      <Field
                        name={nameOf<IFormikValues>('sjabCatID')}
                        render={(fieldProps: FieldProps<IFormikValues>) => {
                          const { field, form } = fieldProps;
                          if (categorieOpties === null) {
                            return <LoadingSpinner />;
                          }
                          return (
                            <div>
                              <Combobox
                                geselecteerd={field.value}
                                opties={categorieOpties}
                                isWisbaar
                                options={{ legeOptieTekst: 'Geen categorie' }}
                                onSelectieChange={(x) => form.setFieldValue(field.name, x)}
                              />
                              <FormikVeldFout fieldProps={fieldProps} />
                            </div>
                          );
                        }}
                      />
                    </div>

                    <div className="col-12 mt-2">
                      <span className="d-flex">
                        <label>{veldnamen.sjabCtxs}</label>
                        <span className="ml-2">
                          <UitlegTooltip
                            inhoud={
                              <div>
                                <p>
                                  Contexten zijn informatie die ter beschikking is wordt binnen het
                                  sjabloon zodra je deze toevoegd.
                                </p>
                                <p>
                                  Er mag maar een keer hetzelfde context type worden toegevoegd, als
                                  je meerdere keren hetzelfde context type wilt gebruiken, kan je
                                  deze hernoemen naar een andere naam binnen het sjabloon
                                  (aliassen).
                                </p>
                              </div>
                            }
                          />
                        </span>
                      </span>
                      <Field
                        name={nameOf<IFormikValues>('sjabCtxs')}
                        render={(fieldProps: FieldProps<IFormikValues>) => {
                          const { field, form } = fieldProps;
                          if (contexten === null) {
                            return <LoadingSpinner />;
                          }
                          return (
                            <div>
                              <SjabCtxsVeld
                                value={field.value}
                                onChange={(x) => form.setFieldValue(field.name, x)}
                                contexten={contexten}
                              />
                              {/*<MultiSelect*/}
                              {/*  value={field.value}*/}
                              {/*  onChange={(x) => form.setFieldValue(field.name, x)}*/}
                              {/*  opties={contextOpties}*/}
                              {/*  isVerwijderbaar={false}*/}
                              {/*  placeholder="Geen contexten"*/}
                              {/*/>*/}
                              <FormikVeldFout fieldProps={fieldProps} />
                            </div>
                          );
                        }}
                      />
                    </div>

                    <div className="col-12 mt-3 d-flex align-items-center justify-content-between">
                      <Field
                        name={nameOf<IFormikValues>('isConcept')}
                        render={(fieldProps: FieldProps<IFormikValues>) => {
                          const { field, form } = fieldProps;
                          return (
                            <div>
                              <div className="d-flex align-items-center">
                                <VinkVeld
                                  aangevinkt={field.value}
                                  onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                />
                                <span className="ml-2">{veldnamen.isConcept}</span>
                              </div>
                              <FormikVeldFout fieldProps={fieldProps} />
                            </div>
                          );
                        }}
                      />

                      <Field
                        name={nameOf<IFormikValues>('isSysteemSjabloon')}
                        render={(fieldProps: FieldProps<IFormikValues>) => {
                          const { field, form } = fieldProps;
                          return (
                            <div>
                              <div className="d-flex align-items-center">
                                <VinkVeld
                                  aangevinkt={field.value}
                                  onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                />
                                <span className="ml-2">{veldnamen.isSysteemSjabloon}</span>
                                <span className="ml-2">
                                  <UitlegTooltip
                                    inhoud={
                                      <div>
                                        <span>
                                          Bepaald of een sjabloon alleen via het systeem gebruikt
                                          kan worden (via code) of ook via dynamische omgevingen
                                          (zoals de sjabloonknoppen bij communicatie).
                                        </span>
                                        <br />
                                        <br />
                                        <span>
                                          Bij een systeem sjabloon krijg je toegang tot een speciale
                                          context 'data' die informatie via code in vrije vorm kan
                                          ontvangen.
                                        </span>
                                      </div>
                                    }
                                  />
                                </span>
                              </div>
                              <FormikVeldFout fieldProps={fieldProps} />
                            </div>
                          );
                        }}
                      />

                      <Field
                        name={nameOf<IFormikValues>('isHoofdSjabloon')}
                        render={(fieldProps: FieldProps<IFormikValues>) => {
                          const { field, form } = fieldProps;
                          return (
                            <div>
                              <div className="d-flex align-items-center">
                                <VinkVeld
                                  aangevinkt={field.value}
                                  onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                />
                                <span className="ml-2">{veldnamen.isHoofdSjabloon}</span>
                                <span className="ml-2">
                                  <UitlegTooltip
                                    inhoud={
                                      <div>
                                        <span>
                                          De meeste sjablonen zijn hoofdsjablonen, dit zijn
                                          sjablonen die je kunt gebruiken overal binnen de
                                          applicatie.
                                        </span>
                                        <br />
                                        <span>
                                          Bij bepaalde sjablonen is dit echter niet gewenst, en
                                          moeten deze alleen maar benaderd kunnen worden via andere
                                          sjablonen, zoals een afsluitregel.
                                        </span>
                                        <br />
                                        <span>
                                          Voor dit soort sjablonen moet je aangeven dat het geen
                                          hoofdsjabloon is, maar een onderliggend sjabloon.
                                        </span>
                                      </div>
                                    }
                                  />
                                </span>
                              </div>
                              <FormikVeldFout fieldProps={fieldProps} />
                            </div>
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
              </ModalBody>
              <ModalFooter className="d-flex flex-row justify-content-start">
                <button
                  className="btn btn-primary"
                  onClick={submitForm}
                  style={{ width: 100 }}
                  disabled={isSubmitting || !isValid}
                >
                  Ok
                </button>
                <button
                  className="btn btn-secondary"
                  onClick={props.onAnnuleren}
                  style={{ width: 100 }}
                  disabled={isSubmitting}
                >
                  Annuleren
                </button>
              </ModalFooter>
            </>
          );
        }}
      />
    </Dialoog>
  );
});

export default WijzigenDialoog;
