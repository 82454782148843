import React from 'react';
import {
  IOphalenOpdrachtenResultElement,
  IOphalenOpdrachtstatussenResultElement,
  IOphalenServicedienstenResultElement,
} from '../../../../../../../shared/src/api/v2/service/opdracht';

export interface IContext {
  opdracht: IOphalenOpdrachtenResultElement | null;
  id: number | null;
  opdrachtstatussen: IOphalenOpdrachtstatussenResultElement[] | null;
  servicediensten: IOphalenServicedienstenResultElement[] | null;
}

const OpdrachtContext = React.createContext<IContext>({
  opdracht: null,
  id: null,
  opdrachtstatussen: null,
  servicediensten: null,
});

export default OpdrachtContext;
