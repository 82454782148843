import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { CommunicatieOverlayContext } from '../../../../index';
import { RootStoreContext } from '../../../../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import ASPTabel from '../../../../../../components/tabel/ASPTabel';
import MenuLayout from '../../../../../../components/MenuLayout';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../../../models/IRemoteData';
import { ASPKolom, EAspKolomBreedteType } from '../../../../../../components/tabel/ASPTabel/types';
import api from '../../../../../../api';
import PersoonVisualisatie from '../../../../../../components/personalia/PersoonVisualisatie';
import OrganisatieVisualisatie from '../../../../../../components/personalia/OrganisatieVisualisatie';
import { IOphalenPersonenResultElementV2 } from '../../../../../../../../shared/src/api/v2/persoon/persoon';
import { IOphalenOrganisatiesResultElement } from '../../../../../../../../shared/src/api/v2/organisatie/organisatie';
import { IOphalenAfdelingenResultElement } from '../../../../../../../../shared/src/api/v2/relatie/afdeling';
import TelefoonComponent from '../../../../../../components/communicatie/TelefoonComponent';
import { Kleur } from '../../../../../../bedrijfslogica/constanten';
import { IconToevoegen, IconWijzigen } from '../../../../../../components/Icons';
import { Overlay } from 'react-bootstrap';
import ContactSelecterenDialoogV2, {
  IDialoogResult as IContactSelecterenDialoogResult,
} from '../../../../../../components/ContactSelecterenDialoogV2';
import { GlobaleRendererContext } from '../../../../../GlobaleRenderer';
import OrganisatieAfdelingSelectieDialoog, {
  IDialoogResult as IOrganisatieAfdelingSelectieDialoogResult,
} from '../../../../../../components/dialogen/OrganisatieAfdelingSelectieDialoog';
import { EResultType } from '../../../../../../stores/CheckStore';
import HandmatigOpvoerenDialoog, {
  IDialoogResult as IHandmatigOpvoerenDialoogResult,
} from './HandmatigOpvoerenDialoog';
import * as _ from 'lodash';
import { ITelefoonPin } from '../../../../../../../../shared/src/api/v2/telefonie';

enum EKolom {
  Contact,
  Afdeling,
  Telefoonnummer,
  Label,
}

interface IData {
  telefoonPins: ITelefoonPin[];
  personen: Record<string, IOphalenPersonenResultElementV2[]>;
  organisaties: Record<string, IOphalenOrganisatiesResultElement[]>;
  afdelingen: Record<string, IOphalenAfdelingenResultElement[]>;
}

interface IProps {}

const Telefoonboek: React.FC<IProps> = observer((props) => {
  const { telefoonContext, height } = useContext(CommunicatieOverlayContext);
  const globaleRenderer = useContext(GlobaleRendererContext);
  const { gebruikerStore, checkStore } = useContext(RootStoreContext);

  const toevoegenButton = useRef<HTMLButtonElement>(null);
  const [toevoegenOverlayTonen, setToevoegenOverlayTonen] = useState(false);

  const [data, setData] = useState<IRemoteData<IData>>(createPendingRemoteData());
  const bepalenData = useCallback(async () => {
    const telefoonPinsResult = await api.v2.telefonie.ophalenTelefoonPins({
      filterSchema: {
        filters: [
          {
            naam: 'ASP_GEBR_IDS',
            data: [gebruikerStore.gebruiker!.AspGebrID],
          },
        ],
      },
    });

    if (telefoonPinsResult.telefoonPins.length === 0) {
      setData(
        createReadyRemoteData({
          telefoonPins: [],
          personen: {},
          organisaties: {},
          afdelingen: {},
        }),
      );
      return;
    }

    const telefoonnummers = telefoonPinsResult.telefoonPins.map(
      (x: ITelefoonPin) => x.Telefoonnummer,
    );

    const [personen, organisaties, afdelingen] = await Promise.all([
      (async () => {
        const result = await api.v2.persoon.ophalenPersonen({
          filterSchema: {
            uitgebreideFilter: {
              or: [
                {
                  filter: {
                    naam: 'TELEFOON_MOBIELS',
                    data: telefoonnummers,
                  },
                },
                {
                  filter: {
                    naam: 'TELEFOON_EXTRAS',
                    data: telefoonnummers,
                  },
                },
              ],
            },
          },
        });
        return result.personen;
      })(),
      (async () => {
        const result = await api.v2.organisatie.ophalenOrganisaties({
          filterSchema: {
            filters: [
              {
                naam: 'TELEFOONNUMMERS',
                data: telefoonnummers,
              },
            ],
          },
        });
        return result.organisaties;
      })(),
      (async () => {
        const result = await api.v2.relatie.afdeling.ophalenAfdelingen({
          filterSchema: {
            filters: [
              {
                naam: 'TELEFOONS',
                data: telefoonnummers,
              },
            ],
          },
        });
        return result.afdelingen;
      })(),
    ]);

    setData(
      createReadyRemoteData({
        telefoonPins: telefoonPinsResult.telefoonPins,
        personen: personen.reduce<Record<string, IOphalenPersonenResultElementV2[]>>(
          (acc, curr) => {
            const telefoonnummer = curr.TelefoonMobiel ?? curr.TelefoonExtra!;
            return {
              ...acc,
              [telefoonnummer]: [...(acc[telefoonnummer] ?? []), curr],
            };
          },
          {},
        ),
        organisaties: organisaties.reduce<Record<string, IOphalenOrganisatiesResultElement[]>>(
          (acc, curr) => ({
            ...acc,
            [curr.Telefoonnummer!]: [...(acc[curr.Telefoonnummer!] ?? []), curr],
          }),
          {},
        ),
        afdelingen: afdelingen.reduce<Record<string, IOphalenAfdelingenResultElement[]>>(
          (acc, curr) => ({
            ...acc,
            [curr.Telefoon!]: [...(acc[curr.Telefoon!] ?? []), curr],
          }),
          {},
        ),
      }),
    );
  }, [gebruikerStore.gebruiker!.AspGebrID]);

  useEffect(() => {
    bepalenData();
  }, [bepalenData]);

  const keyExtractor = useCallback((row: ITelefoonPin) => row.ID, []);
  const kolommen = useMemo<ASPKolom<EKolom, ITelefoonPin>[]>(
    () => [
      {
        key: EKolom.Telefoonnummer,
        label: 'Telefoonnummer',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 150,
        renderer: (row: ITelefoonPin) => {
          return (
            <TelefoonComponent
              telefoonNummer={row.Telefoonnummer}
              options={{ icoonWeergeven: false }}
            />
          );
        },
      },
      {
        key: EKolom.Contact,
        label: 'Contact(en)',
        breedteType: EAspKolomBreedteType.Flex,
        flex: 1,
        renderer: (row: ITelefoonPin) => {
          const personen = data.data!.personen[row.Telefoonnummer] ?? null;
          const organisaties = data.data!.organisaties[row.Telefoonnummer] ?? null;
          const afdelingen = data.data!.afdelingen[row.Telefoonnummer] ?? null;

          const persIDs = personen === null ? [] : personen.map((x) => x.PersID);
          const orgIDs: number[] = _.uniq([
            ...(organisaties === null ? [] : organisaties.map((x) => x.OrgID)),
            ...(afdelingen === null ? [] : afdelingen.map((x) => x.OrgID)),
          ]);

          if (persIDs.length === 0 && orgIDs.length === 0) {
            return '- -';
          }

          const items = [
            ...persIDs.map((x) => ({ persID: x, orgID: null })),
            ...orgIDs.map((x) => ({ persID: null, orgID: x })),
          ];

          return (
            <div className="d-flex align-items-center">
              {items.reduce<JSX.Element[]>((acc, curr, i) => {
                const el =
                  curr.persID !== null ? (
                    <PersoonVisualisatie persID={curr.persID} />
                  ) : (
                    <OrganisatieVisualisatie orgID={curr.orgID} />
                  );

                if (i === 0) {
                  return [el];
                }
                if (items.length === i - 1) {
                  return [...acc, el];
                }
                return [...acc, <span key={i}>,&nbsp;</span>, el];
              }, [])}
            </div>
          );
        },
      },
      {
        key: EKolom.Afdeling,
        label: 'Afdeling',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 200,
        renderer: (row: ITelefoonPin) => {
          const afdelingen = data.data!.afdelingen[row.Telefoonnummer] ?? null;
          if (afdelingen === null || afdelingen.length === 0) {
            return '- -';
          }
          return afdelingen.reduce(
            (acc, curr, i) => acc + `${curr.Naam}${i === afdelingen.length - 1 ? '' : ', '}`,
            '',
          );
        },
      },
      {
        key: EKolom.Label,
        label: 'Label',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 250,
        renderer: (row: ITelefoonPin) => row.Label ?? '- -',
      },
    ],
    [data.data],
  );

  const rijen = useMemo(() => {
    if (data.state === ERemoteDataState.Pending) {
      return {};
    }
    return data.data!.telefoonPins.reduce((acc, curr, idx) => {
      return {
        ...acc,
        [idx]: curr,
      };
    }, {});
  }, [data]);

  const handleVerwijderenRij = useCallback(
    async (rij: ITelefoonPin) => {
      await api.v2.telefonie.verwijderenTelefoonpins({
        ids: [rij.ID],
      });
      await bepalenData();
    },
    [bepalenData],
  );

  return (
    <MenuLayout
      menu={
        <div className="d-flex align-items-center">
          <button
            ref={toevoegenButton}
            className="btn btn-sm btn-ligh d-flex align-items-center"
            style={{
              border: `1px solid ${Kleur.LichtGrijs}`,
              background: Kleur.Wit,
            }}
            onClick={() => setToevoegenOverlayTonen((curr) => !curr)}
          >
            <IconToevoegen style={{ width: 18, height: 18, fill: Kleur.Grijs }} />
            <span className="ml-1">Toevoegen</span>
          </button>
          <Overlay
            target={toevoegenButton.current}
            show={toevoegenOverlayTonen}
            onHide={() => setToevoegenOverlayTonen(false)}
            rootClose
            placement="bottom-start"
          >
            {({ placement, arrowProps, show, popper, ...props }) => (
              <div
                {...props}
                style={{
                  position: 'absolute',
                  backgroundColor: Kleur.Wit,
                  // color: Kleur.Grijs,
                  borderRadius: 3,
                  zIndex: 500,
                  ...props.style,
                }}
              >
                <ul className="list-group">
                  <li
                    className="list-group-item d-flex align-items-center"
                    style={{ cursor: 'pointer' }}
                    onClick={async (ev) => {
                      setToevoegenOverlayTonen(false);

                      const result = await globaleRenderer.render<IContactSelecterenDialoogResult>(
                        (renderProps) => (
                          <ContactSelecterenDialoogV2
                            open
                            onSuccess={(result) => {
                              renderProps.destroy(result);
                            }}
                            onAnnuleren={() => renderProps.destroy()}
                          />
                        ),
                      );
                      if (result === undefined) {
                        return;
                      }

                      const telefoonnummer =
                        result.contact.persoon?.TelefoonMobiel ??
                        result.contact.persoon?.TelefoonExtra ??
                        result.contact.organisatie?.Telefoonnummer ??
                        null;

                      if (telefoonnummer === null) {
                        await checkStore.melden({
                          titel: 'Het gekozen contact heeft geen gekoppeld telefoonnummer.',
                        });
                        return;
                      }
                      const params = {
                        aspGebrID: gebruikerStore.gebruiker!.AspGebrID,
                        telefoonnummer,
                        label: null,
                      };

                      if (
                        (
                          await checkStore.controleren({
                            checkData: await api.v2.telefonie.checkToevoegenTelefoonpin(params),
                          })
                        ).type === EResultType.Annuleren
                      ) {
                        return;
                      }

                      await api.v2.telefonie.toevoegenTelefoonpin(params);

                      await bepalenData();
                    }}
                  >
                    <IconToevoegen style={{ fill: Kleur.Grijs, width: 18, height: 18 }} />
                    <span className="ml-2">Persoon of organisatie</span>
                  </li>
                  <li
                    className="list-group-item d-flex align-items-center"
                    style={{ cursor: 'pointer' }}
                    onClick={async (ev) => {
                      setToevoegenOverlayTonen(false);

                      const result = await globaleRenderer.render<
                        IOrganisatieAfdelingSelectieDialoogResult
                      >((renderProps) => (
                        <OrganisatieAfdelingSelectieDialoog
                          open
                          onSuccess={(result) => {
                            renderProps.destroy(result);
                          }}
                          onAnnuleren={() => renderProps.destroy()}
                        />
                      ));
                      if (result === undefined) {
                        return;
                      }

                      if (result.afdeling.Telefoon === null) {
                        await checkStore.melden({
                          titel: 'De gekozen afdeling heeft geen gekoppeld telefoonnummer.',
                        });
                        return;
                      }

                      const params = {
                        aspGebrID: gebruikerStore.gebruiker!.AspGebrID,
                        telefoonnummer: result.afdeling.Telefoon,
                        label: null,
                      };
                      if (
                        (
                          await checkStore.controleren({
                            checkData: await api.v2.telefonie.checkToevoegenTelefoonpin(params),
                          })
                        ).type === EResultType.Annuleren
                      ) {
                        return;
                      }

                      await api.v2.telefonie.toevoegenTelefoonpin(params);
                      await bepalenData();
                    }}
                  >
                    <IconToevoegen style={{ fill: Kleur.Grijs, width: 18, height: 18 }} />
                    <span className="ml-2">Afdeling (van een organisatie)</span>
                  </li>
                  <li
                    className="list-group-item d-flex align-items-center"
                    style={{ cursor: 'pointer' }}
                    onClick={async (ev) => {
                      setToevoegenOverlayTonen(false);

                      const result = await globaleRenderer.render<IHandmatigOpvoerenDialoogResult>(
                        (renderProps) => (
                          <HandmatigOpvoerenDialoog
                            open
                            onSuccess={(result) => {
                              renderProps.destroy(result);
                            }}
                            onAnnuleren={() => renderProps.destroy()}
                          />
                        ),
                      );
                      if (result === undefined) {
                        return;
                      }
                      const params = {
                        aspGebrID: gebruikerStore.gebruiker!.AspGebrID,
                        telefoonnummer: result.telefoonnummer,
                        label: result.label,
                      };
                      if (
                        (
                          await checkStore.controleren({
                            checkData: await api.v2.telefonie.checkToevoegenTelefoonpin(params),
                          })
                        ).type === EResultType.Annuleren
                      ) {
                        return;
                      }

                      await api.v2.telefonie.toevoegenTelefoonpin(params);
                      await bepalenData();
                    }}
                  >
                    <IconWijzigen style={{ fill: Kleur.Grijs, width: 15, height: 18 }} />
                    <span className="ml-2">Handmatig opvoeren</span>
                  </li>
                </ul>
              </div>
            )}
          </Overlay>
        </div>
      }
      body={
        <ASPTabel
          rijen={rijen}
          kolommen={kolommen}
          keyExtractor={keyExtractor}
          totaalAantalRijen={
            data.state === ERemoteDataState.Pending ? 10 : Object.keys(rijen).length
          }
          onVerwijderenRij={handleVerwijderenRij}
        />
      }
    />
  );
});

export default Telefoonboek;
