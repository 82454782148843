import React from 'react';
import PDFWeergever from '../../../../../../PDFWeergever';
import { IPDFPreviewItem } from '../../index';

interface IProps {
  previewItem: IPDFPreviewItem;
}

const PDFPreview = (props: IProps) => {
  return <PDFWeergever url={props.previewItem.bestand.url} />;
};

export default PDFPreview;
