import React, { useContext, useEffect, useMemo } from 'react';
import { RootStoreContext } from '../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import { ERemoteDataState } from '../../../models/IRemoteData';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  IUrlState as IOpdrachtenOverzichtUrlState,
  defaultUrlState as opdrachtenOverzichtDefaultUrlState,
} from '../../../paginas/Klant/Klantkaart/Entiteiten/entiteiten/Service/Opdrachten';
import { genereerUrlStateQueryParam } from '../../../core/useUrlState';
import { EFunctioneleIcon, functioneleIconMap } from '../../Icons';
import { Kleur as EKleur } from '../../../bedrijfslogica/constanten';

interface IProps extends RouteComponentProps {
  servOpdID: number;
}

const Icon = functioneleIconMap[EFunctioneleIcon.Serviceopdracht];

const ServiceopdrachtVisualisatie: React.FC<IProps> = observer((props) => {
  const { serviceopdrachtStore } = useContext(RootStoreContext);

  useEffect(() => {
    if (
      serviceopdrachtStore.serviceopdrachten.state === ERemoteDataState.Pending ||
      serviceopdrachtStore.serviceopdrachten.data![props.servOpdID] === undefined
    ) {
      serviceopdrachtStore.enqueueOphalenServiceopdrachten([props.servOpdID]);
    }
  }, [props.servOpdID]);

  const opdracht = useMemo(() => {
    if (
      serviceopdrachtStore.serviceopdrachten.state === ERemoteDataState.Pending ||
      serviceopdrachtStore.serviceopdrachten.data![props.servOpdID] === undefined
    ) {
      return null;
    }
    return serviceopdrachtStore.serviceopdrachten.data![props.servOpdID]!;
  }, [props.servOpdID, serviceopdrachtStore.serviceopdrachten]);

  if (opdracht === null) {
    return <span>Laden...</span>;
  }

  return (
    <a
      href="#"
      className="d-flex align-items-center"
      onClick={(ev) => {
        ev.preventDefault();

        if (opdracht.melding.RelID !== null) {
          const urlState: IOpdrachtenOverzichtUrlState = {
            ...opdrachtenOverzichtDefaultUrlState,
            selectie: [opdracht.ServOpdID],
          };
          const query = genereerUrlStateQueryParam(urlState);
          props.history.push(`/klant/${opdracht.melding.RelID}/service/opdracht?state=${query}`);
        }
      }}
    >
      <span
        className="d-flex align-items-center"
        title={`Serviceopdracht ${opdracht.melding.Meldnummer}-${opdracht.Volgnummer}`}
      >
        <Icon style={{ width: 13, height: 13, fill: EKleur.Grijs }} />
        <span className="ml-2">
          {opdracht.melding.Meldnummer}-{opdracht.Volgnummer}
        </span>
      </span>
    </a>
  );
});

export default withRouter(ServiceopdrachtVisualisatie);
