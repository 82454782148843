import React from 'react';
import { ITabblad } from '../index';
import { Root } from './style';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import { IconKruis } from '../../../Icons';
import classNames from 'classnames';
import VinkVeld from '../../../formulier/VinkVeld';

interface IProps<TIdentifier> {
  geselecteerd: boolean;
  onClick?: () => void;
  tabblad: ITabblad<TIdentifier>;
  smalleWeergave: boolean;
  onVerwijderen?: () => void;
  toegepast?: boolean;
  onToepassenChange?: (toepassen: boolean) => void;
  disabled?: boolean;
}

const Tab = <TIdentifier extends string | number>(props: IProps<TIdentifier>) => {
  const { tabblad, geselecteerd, onClick, smalleWeergave, onToepassenChange, toegepast } = props;
  const { content, id, label } = tabblad;
  return (
    <Root
      onClick={props.disabled ? undefined : onClick}
      selected={geselecteerd}
      disabled={props.disabled}
    >
      <div
        className="d-flex justify-content-center align-items-center flex-fill"
        style={{ padding: `${smalleWeergave ? 3 : 8}px 10px`, width: '100%' }}
      >
        {toegepast !== undefined && (
          <div onClick={(ev) => ev.stopPropagation()}>
            <VinkVeld
              aangevinkt={toegepast}
              onGewijzigd={
                onToepassenChange === undefined ? undefined : (x) => onToepassenChange(x)
              }
            />
          </div>
        )}
        <span className={classNames([props.onVerwijderen !== undefined && 'ml-2'])}>{label}</span>
        {props.onVerwijderen !== undefined && (
          <>
            <span className="flex-fill" />
            <div
              className="dx-g-bs4-cursor-pointer p-1"
              onClick={(ev) => {
                ev.stopPropagation();
                props.onVerwijderen!();
              }}
            >
              <IconKruis style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
            </div>
          </>
        )}
      </div>
      <div
        style={{
          backgroundColor: geselecteerd ? `${Kleur.SelectieBlauw}` : 'inherit',
          height: 1,
          width: '100%',
        }}
      />
    </Root>
  );
};

export default Tab;
