import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import DetailSectie from './DetailSectie';
import LijstSectie, { IExposeData } from './LijstSectie';
import { Kleur } from '../../../bedrijfslogica/constanten';
import api, { IPaginatiePositie } from '../../../api';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../models/IRemoteData';
import { IEmailsData } from './types';
import { IOphalenPersonenResultElementV2 } from '../../../../../shared/src/api/v2/persoon/persoon';
import { IOphalenOrganisatiesResultElement } from '../../../../../shared/src/api/v2/organisatie/organisatie';
import {
  IOphalenRelatiesResultElementV2,
  IOphalenRelPersResultElement,
} from '../../../../../shared/src/api/v2/relatie';
import * as _ from 'lodash';
import IEmailGeadresseerde from '../../../../../shared/src/models/email/IEmailGeadresseerde';
import { useRealtimeListener } from '../../../one-off-components/realtime/RealtimeIntegratie';
import { IOphalenEmailAccountsResultElement } from '../../../../../shared/src/api/v2/dienst/email/email';
import ActieBalk from './ActieBalk';
import { useEmailWerkbladV2Store } from './store';
import MappenSectieV2 from './MappenSectieV2';
import {
  IEmail,
  IEmailBijgewerktMessageData,
  IEmailNieuwMessageData,
  IEmailRelatieContextGemuteerdMessageData,
  IEmailVerwijderdMessageData,
  IMailMap,
} from '../../../../../shared/src/api/v2/email-v2';
import { RootStoreContext } from '../../../stores/RootStore';

const Root = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: ${Kleur.HeelLichtGrijs};
`;

const MainContainer = styled.div`
  flex: 1;
  display: flex;
  position: relative;
`;

const BATCH_SIZE = 50;

interface IProps {
  emailBerID: number | null;
  onEmailBerIDChange: (emailID: number | null) => void;
  mailMapID: number | null;
  onMailMapIDChange: (mailMapID: number | null) => void;
  accountsSectieUitgeklaptState: Record<number, boolean>;
  onAccountsSectieUitgeklaptStateChange: (
    accountsSectieUitgeklaptState: Record<number, boolean>,
  ) => void;
  mappenSectieUitgeklaptState: Record<number, boolean>;
  onMappenSectieUitgeklaptStateChange: (
    mappenSectieUitgeklaptState: Record<number, boolean>,
  ) => void;
  mappenSectieWeergeven: boolean;
  onMappenSectieWeergevenChange: (mappenSectieWeergeven: boolean) => void;
}

interface IBepalenEmailBerichtenParams {
  paginatie: IPaginatiePositie;
  uitbreiden: boolean;
}

const EmailWerkbladV2 = (props: IProps) => {
  const { gebruikerStore } = useContext(RootStoreContext);
  const emailWerkbladV2Store = useEmailWerkbladV2Store();

  useEffect(() => {
    if (emailWerkbladV2Store.emailDienstenBijIndex.state === ERemoteDataState.Pending) {
      emailWerkbladV2Store.ophalenEmailDiensten();
    }
    if (emailWerkbladV2Store.mailMappenBijIndex.state === ERemoteDataState.Pending) {
      emailWerkbladV2Store.ophalenMailMappen();
    }
    if (emailWerkbladV2Store.emailAccountsBijIndex.state === ERemoteDataState.Pending) {
      emailWerkbladV2Store.ophalenEmailAccounts();
    }
    if (emailWerkbladV2Store.emailGebrXAspGebrsVoorMij.state === ERemoteDataState.Pending) {
      emailWerkbladV2Store.ophalenEmailGebrXAspGebrsVoorMij(gebruikerStore.gebruiker!.AspGebrID);
    }
  }, []);

  const lijstSectieExposeDataRef = useRef<IExposeData | null>(null);
  const emailsDataRef = useRef<IRemoteData<IEmailsData | null>>(createPendingRemoteData());
  const [emailsDataState, setEmailsDataState] = useState<IRemoteData<IEmailsData | null>>(
    emailsDataRef.current,
  );

  const setEmailsData = useCallback(
    (emailsData: IRemoteData<IEmailsData | null>) => {
      emailsDataRef.current = emailsData;
      setEmailsDataState(emailsData);
    },
    [setEmailsDataState],
  );

  const personenCacheRef = useRef<Record<number, IRemoteData<IOphalenPersonenResultElementV2>>>({});
  const [personenCacheState, setPersonenCacheState] = useState<
    Record<number, IRemoteData<IOphalenPersonenResultElementV2>>
  >(personenCacheRef.current);
  const setPersonenCache = useCallback(
    (personenCache: Record<number, IRemoteData<IOphalenPersonenResultElementV2>>) => {
      personenCacheRef.current = personenCache;
      setPersonenCacheState(personenCache);
    },
    [],
  );

  const organisatiesCacheRef = useRef<
    Record<number, IRemoteData<IOphalenOrganisatiesResultElement>>
  >({});
  const [organisatiesCacheState, setOrganisatiesCacheState] = useState<
    Record<number, IRemoteData<IOphalenOrganisatiesResultElement>>
  >(organisatiesCacheRef.current);
  const setOrganisatiesCache = useCallback(
    (organisatiesCache: Record<number, IRemoteData<IOphalenOrganisatiesResultElement>>) => {
      organisatiesCacheRef.current = organisatiesCache;
      setOrganisatiesCacheState(organisatiesCache);
    },
    [],
  );

  const relatiesCacheRef = useRef<Record<number, IRemoteData<IOphalenRelatiesResultElementV2>>>({});
  const [relatiesCacheState, setRelatiesCacheState] = useState<
    Record<number, IRemoteData<IOphalenRelatiesResultElementV2>>
  >(relatiesCacheRef.current);
  const setRelatiesCache = useCallback(
    (relatiesCache: Record<number, IRemoteData<IOphalenRelatiesResultElementV2>>) => {
      relatiesCacheRef.current = relatiesCache;
      setRelatiesCacheState(relatiesCache);
    },
    [setRelatiesCacheState],
  );

  const relPersCacheRef = useRef<Record<number, IRemoteData<IOphalenRelPersResultElement[]>>>({});
  const [relPersCacheState, setRelPersCacheState] = useState<
    Record<number, IRemoteData<IOphalenRelPersResultElement[]>>
  >(relPersCacheRef.current);
  const setRelPersCache = useCallback(
    (relPersCache: Record<number, IRemoteData<IOphalenRelPersResultElement[]>>) => {
      relPersCacheRef.current = relPersCache;
      setRelPersCacheState(relPersCache);
    },
    [],
  );

  const bepaalRelIDs = useCallback(async (relIDs: number[]) => {
    const relIDsNogNietInCache = relIDs.filter(
      (relID) => relatiesCacheRef.current[relID] === undefined,
    );
    setRelatiesCache(
      relIDsNogNietInCache.reduce(
        (acc, relID) => ({
          ...acc,
          [relID]: createPendingRemoteData(),
        }),
        relatiesCacheRef.current,
      ),
    );
    const result = await api.v2.relatie.ophalenRelaties({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: relIDs,
          },
        ],
      },
    });
    setRelatiesCache(
      relIDsNogNietInCache.reduce((acc, relID) => {
        const relatie = result.relaties.find((x) => x.RelID === relID)!;
        return {
          ...acc,
          [relID]: createReadyRemoteData(relatie),
        };
      }, relatiesCacheRef.current),
    );
  }, []);

  const bepaalPersIDs = useCallback(async (persIDs: number[]) => {
    const persIDsNogNietInCache = persIDs.filter(
      (persID) => personenCacheRef.current[persID] === undefined,
    );
    setPersonenCache(
      persIDsNogNietInCache.reduce(
        (acc, persID) => ({
          ...acc,
          [persID]: createPendingRemoteData(),
        }),
        personenCacheRef.current,
      ),
    );
    const result = await api.v2.persoon.ophalenPersonen({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: persIDs,
          },
        ],
      },
    });
    setPersonenCache(
      persIDsNogNietInCache.reduce((acc, persID) => {
        const persoon = result.personen.find((x) => x.PersID === persID)!;
        return {
          ...acc,
          [persID]: createReadyRemoteData(persoon),
        };
      }, personenCacheRef.current),
    );
  }, []);

  const bepaalRelPers = useCallback(
    async (persIDs: number[]) => {
      const persIDsNogNietInCache = persIDs.filter(
        (persID) => relPersCacheRef.current[persID] === undefined,
      );
      setRelPersCache(
        persIDsNogNietInCache.reduce(
          (acc, persID) => ({
            ...acc,
            [persID]: createPendingRemoteData(),
          }),
          relPersCacheRef.current,
        ),
      );
      const result = await api.v2.relatie.ophalenRelPers({
        filterSchema: {
          filters: [
            {
              naam: 'PERS_IDS',
              data: persIDs,
            },
          ],
        },
      });

      const relIDsNogNietInCache = _.uniq(result.relPers.map((x) => x.RelID)).filter(
        (relID: number) => relatiesCacheRef.current[relID] === undefined,
      );
      if (relIDsNogNietInCache.length > 0) {
        // noinspection ES6MissingAwait
        bepaalRelIDs(relIDsNogNietInCache);
      }

      setRelPersCache(
        persIDsNogNietInCache.reduce((acc, persID) => {
          const relPers = result.relPers.filter((x) => x.PersID === persID);
          return {
            ...acc,
            [persID]: createReadyRemoteData(relPers),
          };
        }, relPersCacheRef.current),
      );
    },
    [bepaalRelIDs],
  );

  const bepaalOrgIDs = useCallback(async (orgIDs: number[]) => {
    const orgIDsNogNietInCache = orgIDs.filter(
      (orgID) => organisatiesCacheRef.current[orgID] === undefined,
    );
    setOrganisatiesCache(
      orgIDsNogNietInCache.reduce(
        (acc, orgID) => ({
          ...acc,
          [orgID]: createPendingRemoteData(),
        }),
        organisatiesCacheRef.current,
      ),
    );
    const result = await api.v2.organisatie.ophalenOrganisaties({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: orgIDs,
          },
        ],
      },
    });
    setOrganisatiesCache(
      orgIDsNogNietInCache.reduce((acc, orgID) => {
        const organisatie = result.organisaties.find((x) => x.OrgID === orgID)!;
        return {
          ...acc,
          [orgID]: createReadyRemoteData(organisatie),
        };
      }, organisatiesCacheRef.current),
    );
  }, []);

  const bepalenEmailBerichten = useCallback(
    async (params: IBepalenEmailBerichtenParams) => {
      if (props.mailMapID === null) {
        setEmailsData(createReadyRemoteData(null));
        return;
      }

      setEmailsData(
        createReadyRemoteData({
          emails: new Array(params.paginatie.aantal).fill(null).reduce(
            (acc, _, i) => {
              const curr = acc[params.paginatie.index + i] ?? null;
              const val = curr === null ? createPendingRemoteData() : curr;
              return {
                ...acc,
                [params.paginatie.index + i]: val,
              };
            },
            params.uitbreiden ? emailsDataRef.current.data?.emails ?? {} : {},
          ),
          totaalAantal: emailsDataRef.current.data?.totaalAantal ?? 999999,
        }),
      );

      const result = await api.v2.emailV2.ophalenEmails({
        filterSchema: {
          filters: [
            {
              naam: 'MAIL_MAP_IDS',
              data: [props.mailMapID],
            },
            {
              naam: 'IS_GEARCHIVEERD',
              data: false,
            },
          ],
        },
        paginatie: params.paginatie,
        orderSchema: {
          orders: [
            {
              naam: 'DATUM',
              richting: 'DESC',
            },
          ],
        },
      });
      const relIDs = _.uniq(result.emails.flatMap((x) => x.relCtxXEmailBers.map((x) => x.RelID)));
      if (relIDs.length > 0) {
        // noinspection ES6MissingAwait
        bepaalRelIDs(relIDs);
      }

      const emailGeadresserden = result.emails.flatMap((x) =>
        [x.van, ...x.aan, ...x.cc, ...x.bcc].map(
          (x): IEmailGeadresseerde => ({
            emailAdres: x.Email,
            orgID: x.OrgID,
            persID: x.PersID,
          }),
        ),
      );
      const persIDs = _.uniq(
        emailGeadresserden
          .filter((x) => x.persID !== null && x.persID !== undefined)
          .map((x) => x.persID),
      );
      if (persIDs.length > 0) {
        // noinspection ES6MissingAwait
        bepaalPersIDs(persIDs);
        // noinspection ES6MissingAwait
        bepaalRelPers(persIDs);
      }
      const orgIDs = _.uniq(
        emailGeadresserden
          .filter((x) => x.orgID !== null && x.orgID !== undefined)
          .map((x) => x.orgID),
      );
      if (orgIDs.length > 0) {
        // noinspection ES6MissingAwait
        bepaalOrgIDs(orgIDs);
      }

      setEmailsData(
        createReadyRemoteData({
          emails: result.emails.reduce(
            (acc, curr, i) => ({
              ...acc,
              [params.paginatie.index + i]: createReadyRemoteData(curr),
            }),
            params.uitbreiden ? emailsDataRef.current.data?.emails ?? {} : {},
          ),
          totaalAantal: result.totaalAantal,
        }),
      );

      if (lijstSectieExposeDataRef.current !== null) {
        lijstSectieExposeDataRef.current?.onEmailDataIndexRangeGewijzigd({
          range: {
            startIndex: params.paginatie.index,
            stopIndex: params.paginatie.index + params.paginatie.aantal,
          },
        });
      }
    },
    [setEmailsData, props.mailMapID],
  );
  useEffect(() => {
    bepalenEmailBerichten({
      paginatie: {
        index: 0,
        aantal: BATCH_SIZE,
      },
      uitbreiden: false,
    });
  }, [bepalenEmailBerichten]);

  // TODO: Dit moet nog anders
  useRealtimeListener(async (naamEnum, data) => {
    if (
      emailsDataRef.current.state === ERemoteDataState.Pending ||
      emailsDataRef.current.data === null
    ) {
      return;
    }

    // Bijwerken context relIDs
    if (naamEnum === 'EMAIL_RELATIE_CONTEXT_GEMUTEERD') {
      const d = data as IEmailRelatieContextGemuteerdMessageData;
      const emailIdx = Object.values(emailsDataRef.current.data?.emails ?? {}).findIndex(
        (x) => x.state === ERemoteDataState.Ready && x.data!.ID === d.emailBerID,
      );
      const email = emailsDataRef.current.data?.emails?.[emailIdx];

      // Heeft deze email en relIDs komen niet overeen
      if (
        email !== undefined &&
        email.state === ERemoteDataState.Ready &&
        (email.data!.relCtxXEmailBers.length !== d.relCtxXEmailBers.length ||
          d.relCtxXEmailBers.some(
            (y) => !email.data!.relCtxXEmailBers.some((x) => x.RelID === y.RelID),
          ))
      ) {
        // noinspection JSIgnoredPromiseFromCall
        bepaalRelIDs(d.relCtxXEmailBers.map((x) => x.RelID));

        setEmailsData(
          createReadyRemoteData({
            ...emailsDataRef.current.data!,
            emails: {
              ...emailsDataRef.current.data!.emails,
              [emailIdx]: createReadyRemoteData({
                ...email.data!,
                relCtxXEmailBers: d.relCtxXEmailBers,
              } as IEmail),
            },
          }),
        );
      }
    } else if (naamEnum === 'EMAIL_NIEUW') {
      const d = data as IEmailNieuwMessageData;
      const emailResult = await api.v2.emailV2.ophalenEmails({
        filterSchema: {
          filters: [
            {
              naam: 'IDS',
              data: [d.id],
            },
          ],
        },
      });
      const email = emailResult.emails[0];
      if (email === undefined) {
        console.log(`Nieuwe email niet gevonden met id ${d.id}`);
        return;
      }
      if (props.mailMapID !== email.MailMapID) {
        // Deze email behoort niet tot de huidige map dus negeren
        console.log('Nieuwe email maar behoort niet tot de huidige map');
        return;
      }
      const nieuweEmails = [
        createReadyRemoteData(email),
        ...Object.values(emailsDataRef.current.data!.emails),
      ].reduce<Record<number, IRemoteData<IEmail>>>(
        (acc, curr, i) => ({
          ...acc,
          [i]: curr,
        }),
        {},
      );
      const nieuweEmailsData: IEmailsData = {
        emails: nieuweEmails,
        totaalAantal: emailsDataRef.current.data!.totaalAantal + 1,
      };
      setEmailsData(createReadyRemoteData(nieuweEmailsData));
    } else if (naamEnum === 'EMAIL_BIJGEWERKT') {
      const d = data as IEmailBijgewerktMessageData;
      const emailResult = await api.v2.emailV2.ophalenEmails({
        filterSchema: {
          filters: [
            {
              naam: 'IDS',
              data: [d.id],
            },
          ],
        },
      });
      const email = emailResult.emails[0];
      if (email === undefined) {
        console.log(`Bijgewerkte email niet gevonden met id ${d.id}`);
        return;
      }
      if (props.mailMapID !== email.MailMapID) {
        // Deze email behoort niet tot de huidige map dus negeren
        console.log('Bijgewerkte email maar behoort niet tot de huidige map');
        return;
      }
      const emailIdx = Object.values(emailsDataRef.current.data?.emails ?? {}).findIndex(
        (x) => x.state === ERemoteDataState.Ready && x.data!.ID === email.ID,
      );
      if (emailIdx === -1) {
        console.log(`Bijgewerkte email niet gevonden in huidige emails data met id ${email.ID}`);
        return;
      }
      const nieuweEmails = { ...emailsDataRef.current.data!.emails };
      nieuweEmails[emailIdx] = createReadyRemoteData(email);
      const nieuweEmailsData: IEmailsData = {
        emails: nieuweEmails,
        totaalAantal: emailsDataRef.current.data!.totaalAantal,
      };
      setEmailsData(createReadyRemoteData(nieuweEmailsData));
    } else if (naamEnum === 'EMAIL_VERWIJDERD') {
      const d = data as IEmailVerwijderdMessageData;
      const emailIdx = Object.values(emailsDataRef.current.data?.emails ?? {}).findIndex(
        (x) => x.state === ERemoteDataState.Ready && x.data!.ID === d.id,
      );
      if (emailIdx === -1) {
        console.log('Verwijderde email is niet onderdeel van huidige emails data, negeren');
        return;
      }
      const huidigeEmails = Object.values(emailsDataRef.current.data!.emails);
      const huidigeEmailsZonderVerwijderdeEmail = huidigeEmails.filter((emailRemoteData) => {
        return !(
          emailRemoteData.state === ERemoteDataState.Ready && emailRemoteData.data!.ID === d.id
        );
      });
      const nieuweEmails = huidigeEmailsZonderVerwijderdeEmail.reduce<
        Record<number, IRemoteData<IEmail>>
      >(
        (acc, curr, i) => ({
          ...acc,
          [i]: curr,
        }),
        {},
      );
      const heeftEmailUitLijstGehaald =
        huidigeEmails.length !== huidigeEmailsZonderVerwijderdeEmail.length;
      console.log('heeftEmailUitLijstGehaald', heeftEmailUitLijstGehaald);
      const nieuweEmailsData: IEmailsData = {
        emails: nieuweEmails,
        totaalAantal:
          emailsDataRef.current.data!.totaalAantal - (heeftEmailUitLijstGehaald ? 1 : 0),
      };
      setEmailsData(createReadyRemoteData(nieuweEmailsData));
    }
  });

  const handlePaginatieDataAangevraagd = useCallback(
    async (index: number, aantal: number) => {
      bepalenEmailBerichten({
        paginatie: {
          index,
          aantal,
        },
        uitbreiden: true,
      });
    },
    [bepalenEmailBerichten],
  );

  const ophalenEnkeleEmailBerIDRef = useRef<null | number>(null);
  const [cachedEmail, setCachedEmail] = useState<IEmail | null>(null);
  const email = useMemo<IRemoteData<IEmail | null>>(() => {
    if (props.emailBerID === null) {
      return createReadyRemoteData(null);
    }
    if (emailsDataState.state === ERemoteDataState.Pending || emailsDataState.data === null) {
      return createPendingRemoteData();
    }
    const value =
      Object.values(emailsDataState.data!.emails).find(
        (x) => x.state === ERemoteDataState.Ready && x.data!.ID === props.emailBerID,
      ) ?? null;
    if (value === null) {
      if (ophalenEnkeleEmailBerIDRef.current === null) {
        ophalenEnkeleEmailBerIDRef.current = props.emailBerID;
        api.v2.emailV2
          .ophalenEmails({
            filterSchema: {
              filters: [
                {
                  naam: 'IDS',
                  data: [props.emailBerID],
                },
              ],
            },
          })
          .then((result) => {
            const email = result.emails[0];
            setCachedEmail(email);
            ophalenEnkeleEmailBerIDRef.current = null;
          });
      }
      if (cachedEmail !== null && cachedEmail.ID === props.emailBerID) {
        return createReadyRemoteData(cachedEmail);
      }
      return createPendingRemoteData();
    }
    return createReadyRemoteData(value.data!);
  }, [props.emailBerID, emailsDataState, cachedEmail]);
  useEffect(() => {
    if (
      email.state === ERemoteDataState.Pending ||
      email.data === null ||
      email.data!.ID !== props.emailBerID
    ) {
      return;
    }
    setCachedEmail(email.data);
  }, [email]);

  const mailMap = useMemo<IRemoteData<IMailMap | null>>(() => {
    if (props.mailMapID === null) {
      return createReadyRemoteData(null);
    }
    if (emailWerkbladV2Store.mailMappenBijID.state === ERemoteDataState.Pending) {
      return createPendingRemoteData();
    }
    const x = emailWerkbladV2Store.mailMappenBijID.data![props.mailMapID]!;
    return createReadyRemoteData(x);
  }, [emailWerkbladV2Store.mailMappenBijID, props.mailMapID]);
  const emailAccount = useMemo<IRemoteData<IOphalenEmailAccountsResultElement | null>>(() => {
    if (
      mailMap.state === ERemoteDataState.Pending ||
      emailWerkbladV2Store.emailAccountsBijID.state === ERemoteDataState.Pending
    ) {
      return createPendingRemoteData();
    }
    if (mailMap.data === null) {
      return createReadyRemoteData(null);
    }
    const x = emailWerkbladV2Store.emailAccountsBijID.data![mailMap.data!.EmailGebrID]!;
    return createReadyRemoteData(x);
  }, [emailWerkbladV2Store.emailAccountsBijID, mailMap]);

  return (
    <Root>
      <ActieBalk
        mappenSectieWeergeven={props.mappenSectieWeergeven}
        onMappenSectieWeergevenChange={props.onMappenSectieWeergevenChange}
      />
      <MainContainer>
        {props.mappenSectieWeergeven && (
          <MappenSectieV2
            mailMapID={props.mailMapID}
            onMailMapIDChange={props.onMailMapIDChange}
            uitgeklaptAccountsState={props.accountsSectieUitgeklaptState}
            onUitgeklaptAccountsStateChange={props.onAccountsSectieUitgeklaptStateChange}
            uitgeklaptMappenState={props.mappenSectieUitgeklaptState}
            onUitgeklaptMappenStateChange={props.onMappenSectieUitgeklaptStateChange}
          />
        )}
        <LijstSectie
          batchSize={BATCH_SIZE}
          emailsData={emailsDataState}
          onPaginatieDataAangevraagd={handlePaginatieDataAangevraagd}
          emailBerID={props.emailBerID}
          onEmailBerIDChange={props.onEmailBerIDChange}
          onExposeData={(exposeData) => (lijstSectieExposeDataRef.current = exposeData)}
          relatiesCache={relatiesCacheState}
          personenCache={personenCacheState}
          organisatiesCache={organisatiesCacheState}
          relPersCache={relPersCacheState}
          mappenSectieWeergeven={props.mappenSectieWeergeven}
          emailAccount={emailAccount}
        />
        <DetailSectie
          email={email}
          relatiesCache={relatiesCacheState}
          personenCache={personenCacheState}
          organisatiesCache={organisatiesCacheState}
          relPersCache={relPersCacheState}
          emailAccount={emailAccount.data!}
        />
      </MainContainer>
    </Root>
  );
};

export default EmailWerkbladV2;
