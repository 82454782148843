import { Field, FieldProps, Formik, FormikActions } from 'formik';
import React, { useCallback, useContext, useMemo } from 'react';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import api from '../../../api';
import IDialoogProps from '../../../core/IDialoogProps';
import { EResultType } from '../../../stores/CheckStore';
import { RootStoreContext } from '../../../stores/RootStore';
import Dialoog from '../../dialogen/Dialoog';
import RadioKnop from '../../formulier/RadioKnop';
import { EVerzendwijzeBetaallink } from '../../../bedrijfslogica/enums';
import {
  IGenereerBetaallinkFacturenSjabloonParams,
  IVersturenBetaallinkFacturenParams,
} from '../../../../../shared/src/api/v2/factuur';
import nameOf from '../../../core/nameOf';
import VinkVeld from '../../formulier/VinkVeld';
import { setCommunicatieOverlayState } from '../../../one-off-components/CommunicatieOverlay';
import { ECommunicatieTabblad } from '../../../one-off-components/CommunicatieOverlay/MenuHandle';
import { BestandType } from '../../BijlagenContainer';
import { EUitstelling } from '../../communicatie/EmailWerkblad/OpstellenFormulier/VersturenKnop';

interface IProps extends IDialoogProps<null> {
  relID: number;
  persID?: number;
  factIDs: number[];
  standaardVerzendwijze?: EVerzendwijzeBetaallink;
}

interface IFormikValues {
  verzendwijze: EVerzendwijzeBetaallink;
  aanpassenVoorVerzenden: boolean;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  verzendwijze: 'Verzendwijze',
  aanpassenVoorVerzenden: 'Aanpassen voor verzenden',
};

const VersturenDialoog: React.FC<IProps> = (props) => {
  const { checkStore } = useContext(RootStoreContext);

  const initialValues = useMemo<IFormikValues>(() => {
    return {
      verzendwijze:
        props.standaardVerzendwijze !== undefined
          ? props.standaardVerzendwijze
          : EVerzendwijzeBetaallink.Email,
      aanpassenVoorVerzenden: true,
    };
  }, []);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);
      const verzendwijze = values.verzendwijze === EVerzendwijzeBetaallink.Email ? 'email' : 'sms';

      if (values.aanpassenVoorVerzenden) {
        const params: IGenereerBetaallinkFacturenSjabloonParams = {
          relID: props.relID,
          persID: props.persID,
          factIDs: props.factIDs,
          verzendwijze,
          basisNietToepassen: true,
        };

        const checkData = await api.v2.factuur.checkGenererenBetaallinkFacturenSjabloon(params);
        if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
          actions.setSubmitting(false);
          return;
        }
        const result = await api.v2.factuur.genereerBetaallinkFacturenSjabloon(params);
        const { resolveResult, persoon } = result;

        switch (values.verzendwijze) {
          case EVerzendwijzeBetaallink.Email:
            setCommunicatieOverlayState!((prevState) => ({
              ...prevState,
              geselecteerdTabblad: ECommunicatieTabblad.Email,
              emailContext: {
                ...prevState.emailContext,
                formulier: {
                  recipients: [
                    {
                      persID: persoon.PersID,
                      // Email bestaat hier, wordt gecontroleerd in de check
                      emailAdres: persoon.Email!,
                    },
                  ],
                  CC: [],
                  BCC: [],
                  subject: resolveResult.onderwerp ?? '',
                  body: resolveResult.inhoud ?? '',
                  bijlagen: resolveResult.bestanden.map((x) => ({
                    type: BestandType.ASPDrive,
                    bestand: x,
                  })),
                  contextRelIDs: [props.relID],
                  taalID: resolveResult.taalID,
                  sjabBasisID: resolveResult.sjabBasisID,
                  emailBerID: null,
                  fromEmailGebrID: prevState.emailContext.formulier?.fromEmailGebrID ?? null,
                  uitstelling: prevState.emailContext.formulier?.uitstelling ?? EUitstelling.Direct,
                },
              },
            }));
            break;
          case EVerzendwijzeBetaallink.SMS:
            setCommunicatieOverlayState!((prevState) => ({
              ...prevState,
              geselecteerdTabblad: ECommunicatieTabblad.Sms,
              smsContext: {
                ...prevState.smsContext,
                // TelefoonMobiel bestaat hier, wordt gecontroleerd in de check
                telefoonnummer: persoon.TelefoonMobiel,
                persID: persoon.PersID,
                bericht: resolveResult.inhoud ?? '',
              },
            }));
            break;
        }

        actions.setSubmitting(false);
        props.onSuccess(null);
        return;
      }

      const params: IVersturenBetaallinkFacturenParams = {
        relID: props.relID,
        persID: props.persID,
        factIDs: props.factIDs,
        verzendwijze,
      };

      const checkData = await api.v2.factuur.checkVersturenBetaallinkFacturen(params);
      if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
        actions.setSubmitting(false);
        return;
      }
      if (
        (
          await checkStore.bevestigen({
            inhoud: <span>Geselecteerde facturen versturen?</span>,
          })
        ).type === EResultType.Annuleren
      ) {
        actions.setSubmitting(false);
        return;
      }

      await api.v2.factuur.versturenBetaallinkFacturen(params);

      actions.setSubmitting(false);
      props.onSuccess(null);
    },
    [props.factIDs, props.onSuccess, props.relID, props.persID],
  );

  return (
    <>
      <Dialoog index={props.dialoogIndex || 0}>
        <ModalHeader>
          <ModalTitle>Betaallink versturen</ModalTitle>
        </ModalHeader>

        <>
          <Formik<IFormikValues>
            // ref={formikRef}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            isInitialValid
            render={(formikProps) => {
              return (
                <>
                  <ModalBody>
                    <div className="row">
                      <div className="col-12">
                        <label>{veldnamen.verzendwijze}</label>

                        <Field
                          name={nameOf<IFormikValues>('verzendwijze')}
                          render={({ field, form }: FieldProps<IFormikValues>) => {
                            return (
                              <div className="d-flex flex-column col-12">
                                <span className="d-flex align-items-center">
                                  <RadioKnop
                                    aangevinkt={field.value === EVerzendwijzeBetaallink.Email}
                                    onAangevinkt={() =>
                                      form.setFieldValue(field.name, EVerzendwijzeBetaallink.Email)
                                    }
                                  />
                                  <span className="ml-2">Email</span>
                                </span>
                                <span className="d-flex align-items-center mt-2">
                                  <RadioKnop
                                    aangevinkt={field.value === EVerzendwijzeBetaallink.SMS}
                                    onAangevinkt={() =>
                                      form.setFieldValue(field.name, EVerzendwijzeBetaallink.SMS)
                                    }
                                  />
                                  <span className="ml-2">SMS</span>
                                </span>
                              </div>
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <Field
                          name={nameOf<IFormikValues>('aanpassenVoorVerzenden')}
                          render={({ field, form }: FieldProps<IFormikValues>) => {
                            return (
                              <div className="d-flex align-items-center">
                                <VinkVeld
                                  aangevinkt={field.value}
                                  onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                />
                                <span className="ml-2">{veldnamen.aanpassenVoorVerzenden}</span>
                              </div>
                            );
                          }}
                        />
                      </div>
                    </div>
                  </ModalBody>

                  <ModalFooter className="d-flex flex-row justify-content-start">
                    <button
                      className="btn btn-primary"
                      onClick={formikProps.submitForm}
                      style={{ width: 100 }}
                      disabled={!formikProps.isValid || formikProps.isSubmitting}
                    >
                      Ok
                    </button>
                    <button
                      className="btn btn-secondary"
                      onClick={props.onAnnuleren}
                      style={{ width: 100 }}
                      disabled={formikProps.isSubmitting}
                    >
                      Annuleren
                    </button>
                  </ModalFooter>
                </>
              );
            }}
          />
        </>
      </Dialoog>
    </>
  );
};

export default VersturenDialoog;
