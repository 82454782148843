import React, { useMemo } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import Tabblad, { ITabblad } from '../../../components/layout/Tabblad';
import Winnaars from './Winnaars';
import NieuweKlanten from './NieuweKlanten';

export enum ETabblad {
  Winnaars = 1,
  NieuweKlanten = 2,
}

const pathMap: Record<ETabblad, string> = {
  [ETabblad.Winnaars]: '/winnaars',
  [ETabblad.NieuweKlanten]: '/nieuwe-klanten',
};

interface IProps extends RouteComponentProps {}

const Winweek: React.FC<IProps> = (props) => {
  const tabblad = useMemo<ETabblad | null>(() => {
    const matchKey = Object.keys(pathMap).find((key) => {
      const path = pathMap[Number(key) as ETabblad]!;
      return props.location.pathname.endsWith(path);
    });
    if (matchKey === undefined) {
      props.history.push(`${props.match.path}${pathMap[ETabblad.Winnaars]}`);
      return null;
    }
    return Number(matchKey) as ETabblad;
  }, [props.location.pathname]);

  const tabbladen = useMemo<ITabblad<ETabblad>[]>(
    () => [
      {
        id: ETabblad.Winnaars,
        label: 'Winnaars',
        content: Winnaars,
      },
      {
        id: ETabblad.NieuweKlanten,
        label: 'Nieuwe klanten',
        content: NieuweKlanten,
      },
    ],
    [],
  );

  return (
    <>
      {tabblad !== null && (
        <Tabblad<ETabblad>
          geselecteerd={tabblad}
          onSelectieChange={(tab) => {
            props.history.push(`${props.match.path}${pathMap[tab]}`);
          }}
          tabbladen={tabbladen}
        />
      )}
    </>
  );
};

export default withRouter(Winweek);
