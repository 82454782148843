import styled, { css } from 'styled-components';
import { IconKruis } from '../../Icons';

export interface BoxProps {
  isActive: boolean;
}
export const Box = styled.div<BoxProps>`
  display: flex;
  flex: 1;
  flex-direction: column;
  //justify-content: center;
  padding: 5px;
  text-align: center;
  background: ${(props) => (props.isActive ? '#e4efff' : 'inherit')};
  color: #666666;

  &:hover {
    //cursor: pointer;
  }
`;

export const DropTargetElement = styled.div`
  user-select: none;
  //border: 1px dashed #d2d2d2;
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 2px;
`;

export const IconContainer = styled.div`
  //margin-bottom: 20px;
`;

export const SContainer = styled.div`
  display: flex;
  flex: 1;
  //justify-content: center;
  //flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`;

export const SItem = styled.div<{ selected: boolean }>`
  width: 75px;
  height: 62px;
  margin: 10px;
  ${(props) =>
    props.selected &&
    css`
      background-color: rgb(230, 230, 230);
    `}
`;

export const SItemDeleteButton = styled.button`
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  position: relative;
  //margin-left: 15px;
  margin: 0 0 -20px;
  left: 8px;
  bottom: 8px;
  outline: none;
  border: 1px solid #bfbfbf;
  background: white;

  svg {
    position: relative;
    left: -1px;
  }
`;

export const SItemLocalFileIndicator = styled.div`
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  position: relative;
  margin: 0 0 -25px 60px;
  bottom: 12px;
  outline: none;
  //border: 1px solid #bfbfbf;
  background: #21d0ff;
  color: white;
`;

export const SItemIcon = styled.div`
  //margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SItemImageContainer = styled.div`
  box-sizing: border-box;
  width: 30px;
  height: 30px;
  display: flex;
  //flex: 1;
  //align-items: center;
  //justify-content: center;
  border: 1px solid #bfbfbf;
  padding: 2px;
  background-color: white;
`;

export const SItemImage = styled.div`
  box-sizing: border-box;
  flex: 1;
  width: 100%;
  height: 100%;
  border: 1px solid #bfbfbf;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

export const SItemLabel = styled.p`
  margin: 5px;
  font-size: 14px;
  line-height: 12px;
`;

export const SUploadProgressWrapper = styled.div`
  margin-left: 12px;
  width: 50px;
  background-color: lightgray;
`;

export const SUploadProgress = styled.div<{ progress: number }>`
  width: ${(props) => Math.floor((props.progress / 100) * 80)}px;
  height: 2px;
  background-color: #2e8ce6;
`;

export const ImportSourceContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  &:hover {
    background-color: #f1f3f6;
  }
`;
