import React, { useMemo } from 'react';
import Tabblad, { ITabblad } from '../../../components/layout/Tabblad';
import { matchPath, Route } from 'react-router-dom';
import { Redirect, RouteComponentProps } from 'react-router';
import styled from 'styled-components';
import Vergelijkingspunten from './Vergelijkingspunten';
import Concurrentgegevens from './Concurrentgegevens';
import Voetnoten from './Voetnoten';

type Tabblad = 'vergelijkingspunten' | 'concurrentgegevens' | 'voetnoten';

const urlTabbladMap: Record<Tabblad, string> = {
  vergelijkingspunten: 'vergelijkingspunten',
  concurrentgegevens: 'concurrentgegevens',
  voetnoten: 'voetnoten',
};

const Root = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const Vergelijken = (props: RouteComponentProps) => {
  const tabbladen = useMemo<ITabblad<Tabblad, RouteComponentProps>[]>(
    () => [
      {
        id: 'vergelijkingspunten',
        label: 'Vergelijkingspunten',
        content: Vergelijkingspunten,
      },
      {
        id: 'concurrentgegevens',
        label: 'Concurrentgegevens',
        content: Concurrentgegevens,
      },
      {
        id: 'voetnoten',
        label: 'Voetnoten',
        content: Voetnoten,
      },
    ],
    [],
  );

  const tabblad = useMemo<Tabblad | null>(() => {
    const paths = Object.keys(urlTabbladMap).map((key) => {
      const tabblad = key as Tabblad;
      const url = urlTabbladMap[tabblad];
      return {
        tabblad,
        path: `${props.match.path}/${url}`,
      };
    });
    for (const pathObj of paths) {
      const match = matchPath(props.location.pathname, pathObj.path);
      if (match !== null) {
        return pathObj.tabblad;
      }
    }

    return null;
  }, [props.location.pathname]);

  return (
    <>
      <Route
        exact
        path={props.match.path}
        component={() => (
          <Redirect to={`${props.match.path}/${urlTabbladMap.vergelijkingspunten}`} />
        )}
      />
      {tabblad === null ? (
        <Redirect to={`${props.match.path}/${urlTabbladMap.vergelijkingspunten}`} />
      ) : (
        <Root>
          <Tabblad
            geselecteerd={tabblad}
            onSelectieChange={(identifier) =>
              props.history.push(`${props.match.path}/${urlTabbladMap[identifier]}`)
            }
            tabbladen={tabbladen}
            options={{
              tabbladComponentProps: props,
            }}
          />
        </Root>
      )}
    </>
  );
};

export default Vergelijken;
