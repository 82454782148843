import React, { useContext, useMemo } from 'react';
import { ISignalering } from '../../../../../shared/src/api/v2/control/signalering';
import RelatieVisualisatie from '../../../components/personalia/RelatieVisualisatie';
import { TableRowDetail } from '@devexpress/dx-react-grid-bootstrap4';
import GegevensLayout from '../../../components/layout/GegevensLayout';
import { SignaleringstabelContext } from '../../../components/signalering/SignaleringTabel';
import LaatstGesignaleerdWeergave from '../../../components/signalering/SignaleringTabel/LaatstGesignaleerdWeergave';

interface IProps extends TableRowDetail.ContentProps {}

interface ISignaleringProps {
  signalering: ISignalering;
}

const weergaveMap: Record<string, React.ComponentType<ISignaleringProps>> = {
  ALTIJD_NIET_AANMANEN: (props) => {
    const { signalering } = props;
    const { relID } = JSON.parse(signalering.Data);
    return (
      <div className="d-flex align-items-center">
        {/* <span>Betreft relatie</span> */}
        <span className="ml-2">
          <RelatieVisualisatie relID={relID} />
        </span>
      </div>
    );
  },
  PROLONGATIE_NA_BEEINDIGING: () => <span>TODO</span>,
  LOPEND_CONTRACT_ZONDER_LEVEROPDRACHT: () => <span>TODO</span>,
  LOPEND_CONTRACT_ZONDER_GEKOPPELD_PRODUCT: () => <span>TODO</span>,
  PROLONGATIE_LANG_GELEDEN: () => <span>TODO</span>,
  AFWIJKENDE_LOCATIE_PRODUCT_VS_CONTRACT: () => <span>TODO</span>,
  HUISNUMMER_IN_STRAATNAAM: () => <span>TODO</span>,
  VOORVOEGSEL_IN_ACHTERNAAM: () => <span>TODO</span>,
};

const RowDetailContent: React.FC<IProps> = (props) => {
  const { run } = useContext(SignaleringstabelContext);

  const { row } = props;
  const signalering: ISignalering = row;
  const Comp = useMemo<React.ComponentType<ISignaleringProps> | null>(() => {
    return weergaveMap[signalering.TypeNaamEnum] || null;
  }, [signalering.TypeNaamEnum]);

  if (Comp === null) {
    return <div>Geen weergave beschikbaar voor deze signaleringstype</div>;
  }

  return (
    <div>
      <div className="p-2">
        <GegevensLayout
          gegevens={[
            {
              label: 'Relatie',
              waarde:
                signalering.RelID === null ? (
                  'n.v.t'
                ) : (
                  <RelatieVisualisatie relID={signalering.RelID} />
                ),
            },
            {
              label: 'Laatst gesignaleerd',
              waarde: (
                <LaatstGesignaleerdWeergave
                  datum={new Date(signalering.LaatstGesignaleerd)}
                  runDatum={new Date(run.Datum)}
                />
              ),
            },
          ]}
        />
      </div>
      <div className="p-2">
        <Comp signalering={signalering} />
      </div>
    </div>
  );
};

export default RowDetailContent;
