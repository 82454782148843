import {
  DataTypeProvider,
  EditingState,
  SelectionState,
  TableRowDetail,
} from '@devexpress/dx-react-grid';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { RootStoreContext } from '../../../../stores/RootStore';
import api from '../../../../api';
import { EResultType } from '../../../../stores/CheckStore';
import {
  DXTableCheckboxComponent,
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import { colors } from '../../../../globalstyles/variables';
import {
  Grid,
  Table,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  TableSelection,
} from '@devexpress/dx-react-grid-bootstrap4';
import RelatieVisualisatie from '../../../personalia/RelatieVisualisatie';
import { IOpdracht, AfgehandeldTabelContext } from '../index';
import useUrlState from '../../../../core/useUrlState';
import {
  ContractStatusIndicatie,
  EContractStatus,
} from '../../../../paginas/Klant/Klantkaart/Entiteiten/entiteiten/Contracten/Overzicht/ContractTegel/ContractStatus';
import { format } from 'date-fns';
import { IOphalenOpdrachtregelsResultElementV2 } from '../../../../../../shared/src/api/v2/transport/opdracht';
import ProductWeergaveComp from '../../../kolomveld/transportopdrachtregel/ProductWeergaveComp';
import NieuwOfGebruiktComp from '../../../kolomveld/transportopdrachtregel/NieuwOfGebruiktComp';
import ReferentieProductComp from '../../../kolomveld/transportopdrachtregel/ReferentieProductComp';
import BijlagenContainer, { BestandType } from '../../../BijlagenContainer';
import useUpload from '../../../../core/useUpload';

interface IRegelRow extends IOphalenOpdrachtregelsResultElementV2 {}

export interface IAfmeldenOpdrachtDialoogState {
  trsRegID: number;
}
export interface IAnnulerenOpdrachtregelsState {
  trsRegIDs: number[];
}

const Regels = (props: TableRowDetail.ContentProps) => {
  const row = props.row as IOpdracht; // row = Opdracht + regels
  const { checkStore } = useContext(RootStoreContext);
  const uitstaandTabelContext = useContext(AfgehandeldTabelContext);

  const [reserverenProductDialoogTonen, setReserverenProductDialoogTonen] = useState<number | null>(
    null,
  );

  const [trsRegID, setTrsRegID] = useState<number | null>(null);

  // Map de velden van de meegegeven regels naar de regels van het grid
  const gridData = useMemo<IRegelRow[]>(() => row.regels.map((regel) => ({ ...regel })), [
    row.regels,
  ]);

  // Koppelt het geselecteerde type (Nieuw) of product (Gebruikt) aan de transportregel
  const reserverenProduct = useCallback(
    async (soort: number, typeID: number | null, prodID: number | null) => {
      await api.v2.transport.reservering.levering.koppelenProduct({
        trsRegID: trsRegID!,
        soortReservering: soort === 1 ? 'T' : 'P',
        typeID,
        prodID,
      });
    },
    [trsRegID],
  );

  // Koppelt het geselecteerde product aan de transportregel
  const koppelenProduct = useCallback(
    async (prodID: number) => {
      if (reserverenProductDialoogTonen === null) {
        return;
      }
      const trsRegID = reserverenProductDialoogTonen;

      await api.v2.transport.reservering.retour.reserverenProduct({
        trsRegID,
        prodID,
      });
    },
    [reserverenProductDialoogTonen],
  );

  const handlKoppelenOmruil = useCallback(
    async (trsRegIDs: number[]) => {
      await api.v2.transport.opdracht.omruil.koppelenOmruil({
        regels: [{ trsRegID: trsRegIDs[0], koppelAan_TrsRegID: trsRegIDs[1] }],
      });

      uitstaandTabelContext.onRequestRefresh();
    },
    [uitstaandTabelContext.onRequestRefresh],
  );

  // const handlVerwijderenOpdrachtregels = useCallback(
  //   async (trsRegIDs: number[]) => {
  //     const bevestigenResult = await checkStore.bevestigen({
  //       titel: 'Geselecteerde regels verwijderen?<br>Dit kan niet ongedaan gemaakt worden',
  //     });
  //     if (bevestigenResult.type === EResultType.Annuleren) {
  //       return;
  //     }

  //     await api.v2.transport.opdracht.verwijderenOpdrachtregels({
  //       trsRegIDs,
  //     });

  //     uitstaandTabelContext.onRequestRefresh();
  //   },
  //   [uitstaandTabelContext.onRequestRefresh],
  // );

  const handleVerwijderen = useCallback(
    async (regel: IRegelRow) => {
      const bevestigenResult = await checkStore.bevestigen({
        inhoud: 'Koppeling verwijderen?',
      });
      if (bevestigenResult.type === EResultType.Annuleren) {
        return;
      }

      // Afhandeling hangt af de regelsoort
      if (regel.regelsoort.Code === 'L') {
        const checkData = await api.v2.transport.reservering.levering.checkVerwijderenProductReservering(
          {
            trsRegIDs: [regel.TrsRegID],
          },
        );
        const controleerResult = await checkStore.controleren({
          checkData,
        });
        if (controleerResult.type === EResultType.Annuleren) {
          return;
        }

        await api.v2.transport.reservering.levering.verwijderenProductReservering({
          trsRegIDs: [regel.TrsRegID],
        });
      } else {
        const checkData = await api.v2.transport.reservering.levering.checkVerwijderenProductReservering(
          {
            trsRegIDs: [regel.TrsRegID],
          },
        );
        const controleerResult = await checkStore.controleren({
          checkData,
        });
        if (controleerResult.type === EResultType.Annuleren) {
          return;
        }
        await api.v2.transport.reservering.retour.verwijderenProductReservering({
          trsRegIDs: [regel.TrsRegID],
        });
      }

      uitstaandTabelContext.onRequestRefresh();
    },
    [uitstaandTabelContext.onRequestRefresh],
  );

  const keyExtractor = useCallback((row: IRegelRow) => row.TrsRegID, []);

  const kolommen = useMemo<TypedColumn<IRegelRow>[]>(
    () => [
      // {
      //   name: 'Regelnummer',
      //   title: 'Rg',
      // },
      {
        name: '__soort' as any,
        title: 'Srt.',
      },
      {
        name: 'Aantal' as any,
        title: '#',
      },
      {
        name: '__product' as any,
        title: 'Merk/type',
      },
      {
        name: '__productsoort' as any,
        title: 'Cat.',
      },
      {
        name: '__nieuw' as any,
        title: 'N/G',
      },
      {
        name: '__referentie' as any,
        title: 'Ref.',
      },
      {
        name: '__contractnummer' as any,
        title: 'Cnt.nr',
      },
      {
        name: 'relatienaam',
        title: 'Relatienaam',
      },
      {
        name: '__afmeldenOpdracht',
        title: 'Status',
      },
      {
        name: 'Statusdatum',
        title: 'Datum',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRegelRow>[]>(
    () => [
      // {
      //   columnName: 'Regelnummer',
      //   width: 35,
      // },
      {
        columnName: '__soort' as any,
        width: 60,
      },
      {
        columnName: 'Aantal',
        width: 30,
      },
      {
        columnName: '__product' as any,
        width: 200,
      },
      {
        columnName: '__productsoort' as any,
        width: 60,
      },
      {
        columnName: '__referentie' as any,
        width: 100,
      },

      {
        columnName: '__nieuw' as any,
        width: 50,
      },

      // {
      //   columnName: '__retourstatus' as any,
      //   width: 50,
      // },
      // {
      //   columnName: '__koppelActie' as any,
      //   width: 85,
      // },
      // {
      //   columnName: '__ontkoppelActie' as any,
      //   width: 50,
      // },
      // {
      //   columnName: 'modelCode',
      //   width: 110,
      // },
      {
        columnName: '__contractnummer' as any,
        width: 100,
      },
      // {
      //   columnName: 'relatienummer',
      //   width: 75,
      // },
      {
        columnName: 'relatienaam',
        width: 250,
      },
      // {
      //   columnName: '__actieMenu' as any,
      //   width: 100,
      // },
      {
        columnName: '__afmeldenOpdracht',
        width: 125,
      },
      {
        columnName: 'Statusdatum',
        width: 125,
      },
    ],
    [],
  );

  const { isBezigMetUploaden, muteerBestanden, uploadProgresses, bestanden } = useUpload(
    useMemo(
      () => ({
        automatischUploaden: true,
        initieleBestanden: row.bestanden.map((x) => ({
          type: BestandType.ASPDrive as BestandType.ASPDrive,
          bestand: x,
        })),
        onAlleBestandenGeuploaded: async (bestandIDs: number[]) => {
          await api.v2.transport.muterenBestandenOpdracht({
            trsOpdID: row.TrsOpdID,
            bestIDs: bestandIDs,
          });
        },
      }),
      [row.bestanden, row.TrsOpdID],
    ),
  );

  return (
    <div style={{ backgroundColor: Kleur.LichtGrijs }}>
      <div className="flex-fill ml-5" style={{ backgroundColor: colors.backgroundGrey }}>
        <>
          {/* <div
            className="d-flex flex-column p-3"
            style={{
              backgroundColor: Kleur.HeelLichtGrijs,
              borderBottom: `1px solid ${Kleur.LichtGrijs}`,
            }}
          >
            <div className="d-flex">
            </div>
          </div> */}

          {bestanden !== null && bestanden.length > 0 && (
            <div className="p-2">
              <BijlagenContainer
                bestanden={bestanden}
                uploadProgresses={uploadProgresses}
                onBestandenChange={(x) => muteerBestanden((_) => x)}
                bestandenMuterenToegestaan={!isBezigMetUploaden}
              />
            </div>
          )}

          <GridStyleWrapper maxHeight={500} rowAmount={gridData.length}>
            <Grid rows={gridData} getRowId={keyExtractor} columns={kolommen}>
              <DataTypeProvider
                for={['__soort']}
                formatterComponent={(props) => {
                  const rij: IRegelRow = props.row;
                  return (
                    <>
                      <span hidden>TrsRegID: {rij.TrsRegID}</span>
                      <span>
                        {rij.regelsoort.Code} {rij.OmruilID !== null ? '/O' : ''}
                      </span>
                      {/* <span>{rij.indicaties.isVermoedelijkOmruil ? ' (/O?)' : ''}</span> */}
                    </>
                  );
                }}
              />

              <DataTypeProvider
                for={['__product']}
                formatterComponent={(formatterProps) => {
                  const rij: IRegelRow = formatterProps.row;
                  return <ProductWeergaveComp rij={rij} />;
                }}
              />

              <DataTypeProvider
                for={['XXX__product']}
                formatterComponent={(props) => {
                  const rij: IRegelRow = props.row;
                  const product =
                    rij.type !== null ? (
                      <span>
                        {(rij.type.Merkloos ? '' : rij.type.Merknaam + ' ') + rij.type.Typenaam}
                      </span>
                    ) : rij.product !== null ? (
                      <span>
                        {rij.product.producttype.Merknaam + ' ' + rij.product.producttype.Typenaam}
                      </span>
                    ) : (
                      <span>{rij.regelsoort.Code === 'L' ? '' : ''}</span>
                    );

                  return (
                    <a
                      href="#"
                      style={{ color: Kleur.DonkerGrijs }}
                      onClick={() => {
                        rij.type !== null ? alert('Producttypeinfo') : alert('Productinfo');
                      }}
                    >
                      <span>{product}</span>
                    </a>
                  );
                }}
              />

              <DataTypeProvider
                for={['__productsoort']}
                formatterComponent={(props) => {
                  const rij: IRegelRow = props.row;
                  const product =
                    rij.type !== null ? (
                      <span>{rij.type.ProductsoortnaamKort}</span>
                    ) : rij.product !== null ? (
                      <span>{rij.product.producttype.ProductsoortnaamKort}</span>
                    ) : null;
                  return <span>{product}</span>;
                }}
              />

              <DataTypeProvider
                for={['__nieuw']}
                formatterComponent={(formatterProps) => {
                  const rij: IRegelRow = formatterProps.row;
                  return <NieuwOfGebruiktComp rij={rij} />;
                }}
              />

              <DataTypeProvider
                for={['__referentie']}
                formatterComponent={(formatterProps) => {
                  const rij: IRegelRow = formatterProps.row;
                  return <ReferentieProductComp rij={rij} />;
                }}
              />

              <DataTypeProvider
                for={['__contractnummer']}
                formatterComponent={(props) => {
                  const rij: IRegelRow = props.row;
                  if (rij.contract === null) {
                    return <span></span>;
                  }
                  return (
                    <div className="d-flex  align-items-center">
                      <ContractStatusIndicatie
                        status={rij.contract.status.NaamEnum as EContractStatus}
                      />

                      <div className="ml-2 ">
                        {rij.contract.basis.Basisnummer + '.' + rij.contract.Volgnummer}
                      </div>
                    </div>
                  );
                }}
              />

              <DataTypeProvider
                for={['__retourstatus']}
                formatterComponent={(formatterProps) => {
                  const rij: IRegelRow = formatterProps.row;
                  if (rij.product === null || rij.product.retourstatus === null) {
                    return <span></span>;
                  }

                  return <span>{rij.product.retourstatus.Naam}</span>;
                }}
              />

              <DataTypeProvider
                for={['relatienaam']}
                formatterComponent={(p) => {
                  const rij: IRegelRow = p.row;
                  if (rij.RelID !== null) {
                    return <RelatieVisualisatie relID={rij.RelID} />;
                  } else {
                    return <span />;
                  }
                }}
              />

              <DataTypeProvider
                for={['__afmeldenOpdracht']}
                formatterComponent={(props) => {
                  const row: IRegelRow = props.row;
                  const kleur: Kleur =
                    row.status.Status === 1
                      ? Kleur.Groen
                      : row.status.Status === 2
                      ? Kleur.Rood
                      : row.status.Status === 3
                      ? Kleur.Oranje
                      : Kleur.Blauw;
                  return <span style={{ color: kleur }}>{row.status.Naam}</span>;
                  // return row.Status === 0 ? (
                  //   <a
                  //     href="#"
                  //     // onClick={() => setAfmeldenDialoogTonen(row.trsRegID)}
                  //     onClick={() =>
                  //       onAfmeldenOpdrachtDialoogStateChange({ trsRegID: row.TrsRegID })
                  //     }
                  //     style={{ color: kleur }}
                  //   >
                  //     Afmelden
                  //   </a>
                  // ) : (
                  //   <a
                  //     href="#"
                  //     onClick={async () => {
                  //       const resultaat = await checkStore.bevestigen({
                  //         titel: `Afmelden ongedaan maken?`,
                  //       });
                  //       if (resultaat.type === EResultType.Annuleren) {
                  //         return;
                  //       }

                  //       await api.v2.transport.opdracht.uitstaand.herstellenAfmeldenOpdrachten({
                  //         trsRegIDs: [row.TrsRegID],
                  //       });

                  //       uitstaandTabelContext.onRequestRefresh();
                  //     }}
                  //     style={{ color: kleur }}
                  //   >
                  //     {row.Statusnaam}
                  //   </a>
                  // );
                }}
              />

              <DataTypeProvider
                for={['Statusdatum']}
                formatterComponent={(props) => {
                  const row: IRegelRow = props.row;
                  const datum =
                    row.status.Statusdatum !== null
                      ? format(new Date(row.status.Statusdatum), 'dd-MM-yyyy')
                      : '';
                  return <span>{datum}</span>;
                }}
              />

              <SelectionState
                selection={uitstaandTabelContext.regelsSelectie}
                onSelectionChange={(x) =>
                  uitstaandTabelContext.onRegelsSelectieChange(x as number[])
                }
              />
              <EditingState
                onCommitChanges={(changes) => {
                  if (changes.deleted === undefined) {
                    return;
                  }
                  const deleted = changes.deleted;
                  const id = deleted[deleted.length - 1];
                }}
                onEditingRowIdsChange={(rowIds) => {
                  const id = rowIds[rowIds.length - 1] as number;
                }}
              />
              <Table />
              <TableColumnResizing defaultColumnWidths={kolomBreedtes as any} />
              <TableEditColumn
                width={35}
                showEditCommand
                // showAddCommand
                // showDeleteCommand
                cellComponent={DXTableEditColumnCellComponent}
                commandComponent={DXTableEditColumnCommandComponent}
              />
              <TableSelection cellComponent={DXTableCheckboxComponent} />
              <TableHeaderRow />
            </Grid>
          </GridStyleWrapper>
        </>
      </div>
    </div>
  );
};

export default Regels;
