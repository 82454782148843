import React, { useContext, useMemo, useState } from 'react';
import { Root } from './style';
import { IconActiesHorizontaal } from '../Icons';
import Dropdown from 'react-bootstrap/Dropdown';
import RelatieVisualisatie, {
  IOptions as RelatieVisualisatieOptions,
  INaamComponentProps,
} from '../personalia/RelatieVisualisatie';
import RelatieSelectieDialoog, { IKoppelOpties } from '../personalia/RelatieSelectieDialoog';
import { IFilterSchema } from '../../../../shared/src/models/filter';
import ActieMenuKnop, { IActie } from '../ActieMenuKnop';
import { RootStoreContext } from '../../stores/RootStore';
import { EResultType } from '../../stores/CheckStore';
// tslint:disable-next-line:no-var-requires
import { v4 as uuid } from 'uuid';
import { IOphalenRelatiesResultElementV2 } from '../../../../shared/src/api/v2/relatie';
import { formatteerRelatieNaam } from '../../helpers';

interface IOptions {
  ongekoppeldWeergave?: React.ComponentType;
}

interface IProps {
  relID: number | null;
  onRelIDChange: (relID: number | null) => void;
  koppelOpties?: IKoppelOpties;
  filterSchema?: IFilterSchema;
  relatieVisualisatieOptions?: RelatieVisualisatieOptions;
  relatieNaamComponent?: React.ComponentType<INaamComponentProps>;
  options?: IOptions;
  onRelatieInformatieGedeeld?: (relatie: IOphalenRelatiesResultElementV2) => void;
}

const RelatieKoppelComponent: React.FC<IProps> = (props) => {
  const { checkStore, klantkaartStore } = useContext(RootStoreContext);

  const id = useMemo(() => uuid(), []);
  const [toonKoppelModal, setToonKoppelModal] = useState(false);

  const options = useMemo(
    () => ({
      ongekoppeldWeergave: () => <span>Relatie koppelen</span>,
      ...props.options,
    }),
    [props.options],
  );

  const OngekoppeldWeergave = options.ongekoppeldWeergave;

  const relatieKoppelenActie = useMemo<IActie | null>(() => {
    return klantkaartStore.relatie === null
      ? null
      : {
          text: (
            <span>
              Koppel&nbsp;
              <span className="font-weight-bold">
                {formatteerRelatieNaam({
                  relatiesoort: klantkaartStore.relatie.Relatiesoort,
                  voornaam:
                    klantkaartStore.relatie.Relatiesoort === 'P'
                      ? klantkaartStore.relatie.persoon!.Voornaam
                      : undefined,
                  achternaam:
                    klantkaartStore.relatie.Relatiesoort === 'P'
                      ? klantkaartStore.relatie.persoon!.Achternaam
                      : undefined,
                  aanhefKort:
                    klantkaartStore.relatie.Relatiesoort === 'P'
                      ? klantkaartStore.relatie.persoon!.geslacht.AanhefKort
                      : undefined,
                  voorletters:
                    klantkaartStore.relatie.Relatiesoort === 'P'
                      ? klantkaartStore.relatie.persoon!.Voorletters
                      : undefined,
                  voorvoegsel:
                    klantkaartStore.relatie.Relatiesoort === 'P'
                      ? klantkaartStore.relatie.persoon!.Voorvoegsel
                      : undefined,
                  organisatienaam:
                    klantkaartStore.relatie.Relatiesoort === 'O'
                      ? klantkaartStore.relatie.organisatie!.Naam
                      : undefined,
                })}
              </span>
            </span>
          ),
          onClick: () => {
            props.onRelIDChange(klantkaartStore.relatie!.RelID);
            if (props.onRelatieInformatieGedeeld !== undefined) {
              props.onRelatieInformatieGedeeld(klantkaartStore.relatie!);
            }
          },
        };
  }, [klantkaartStore.relatie, props.onRelIDChange, props.onRelatieInformatieGedeeld]);

  return (
    <>
      <Root>
        {props.relID === null ? (
          <div className="ongekoppeld-container">
            <a
              href="#"
              onClick={(ev) => {
                ev.preventDefault();

                setToonKoppelModal(true);
              }}
            >
              <OngekoppeldWeergave />
            </a>

            <div className="ml-1">
              <ActieMenuKnop
                iconStyle={{
                  width: 16,
                  height: 16,
                }}
                acties={relatieKoppelenActie === null ? [] : [relatieKoppelenActie]}
              />
            </div>
          </div>
        ) : (
          <div
            className="gekoppeld-container d-flex align-items-center flex-fill"
            onClick={(ev) => ev.stopPropagation()}
          >
            <span className="flex-fill d-flex align-items-center">
              <RelatieVisualisatie
                relID={props.relID}
                options={props.relatieVisualisatieOptions}
                naamComponent={props.relatieNaamComponent}
              />
            </span>
            <div className="ml-1">
              <ActieMenuKnop
                iconStyle={{
                  width: 16,
                  height: 16,
                }}
                acties={
                  [
                    relatieKoppelenActie,
                    {
                      text: 'Andere relatie koppelen',
                      onClick: () => setToonKoppelModal(true),
                    },
                    {
                      text: 'Ontkoppelen',
                      onClick: async () => {
                        if (
                          (
                            await checkStore.bevestigen({
                              inhoud: 'Relatie ontkoppelen?',
                            })
                          ).type === EResultType.Annuleren
                        ) {
                          return;
                        }
                        return props.onRelIDChange(null);
                      },
                    },
                  ].filter((x) => x !== null) as IActie[]
                }
              />
            </div>
          </div>
        )}
      </Root>
      {toonKoppelModal && (
        <RelatieSelectieDialoog
          open
          onSuccess={(result) => {
            props.onRelIDChange(result.relID);
            if (props.onRelatieInformatieGedeeld !== undefined) {
              props.onRelatieInformatieGedeeld(result.relatie);
            }
            setToonKoppelModal(false);
          }}
          onAnnuleren={() => setToonKoppelModal(false)}
          koppelOpties={props.koppelOpties}
        />
      )}
    </>
  );
};

export default RelatieKoppelComponent;
