import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import LoadingSpinner from '../../../Gedeeld/LoadingSpinner';
import Tabblad, { ITabblad } from '../../../layout/Tabblad';
import ServicemeldingenTabblad from '../ServicemeldingenTabblad';
import OrganisatiesTabblad from '../OrganisatiesTabblad';
import DebiteurenTabblad from '../DebiteurenTabblad';
import CrediteurenTabblad from '../CrediteurenTabblad';
import SponsorTabblad from '../SponsorTabblad';

interface IProps {}

export enum ETabblad {
  Organisaties,
  Sponsors,
  Servicemeldingen,
  Debiteuren,
  Crediteuren,
}

const SpeciaalTabblad: React.FC<IProps> = observer((props) => {
  const [tabblad, setTabblad] = useState<ETabblad>(ETabblad.Organisaties);

  const tabbladen = useMemo<ITabblad<ETabblad>[]>(
    () => [
      {
        id: ETabblad.Organisaties,
        label: 'Klant-organisaties',
        content: OrganisatiesTabblad,
      },
      {
        id: ETabblad.Sponsors,
        label: 'Sponsorrelaties',
        content: SponsorTabblad,
      },
      {
        id: ETabblad.Servicemeldingen,
        label: 'Servicemeldingen',
        content: ServicemeldingenTabblad,
      },
      {
        id: ETabblad.Debiteuren,
        label: 'Debiteuren',
        content: DebiteurenTabblad,
      },
      {
        id: ETabblad.Crediteuren,
        label: 'Crediteuren',
        content: CrediteurenTabblad,
      },
    ],
    [],
  );
  return (
    <Tabblad
      geselecteerd={tabblad}
      onSelectieChange={(id) => setTabblad(id)}
      tabbladen={tabbladen}
    />
  );
});

export default SpeciaalTabblad;
