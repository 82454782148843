import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import MenuLayout from '../../../../components/MenuLayout';
import SelectieVak from '../../../../components/SelectieVak';
import useUrlState from '../../../../core/useUrlState';
import {
  IEigenschapGroep,
  IOphalenEigenschapGroepenResult,
  IToevoegenEigenschapGroepParams,
  IVerwijderenEigenschapGroepenParams,
  IWijzigenEigenschapGroepParams,
} from '../../../../../../shared/src/api/v2/aanbod/index';
import api from '../../../../api';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import {
  DXCommandComponent,
  DXTableRowSortableRowComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import {
  Grid,
  TableEditColumn,
  TableHeaderRow,
  VirtualTable,
  Table,
  TableColumnResizing,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  DataTypeProvider,
  EditingState,
  SortingState,
  Sorting,
  IntegratedSorting,
} from '@devexpress/dx-react-grid';
import nameof from '../../../../core/nameOf';
import TekstVisualisatie from '../../../../components/TekstVisualisatie';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../../stores/RootStore';
import { EResultType } from '../../../../stores/CheckStore';
import MuterenDialoog, { IFormikValues } from './MuterenDialoog';
import { SortableContainer, SortableHandle, SortableElement, arrayMove } from 'react-sortable-hoc';
import { IconDragHandle, IconDragIndicator } from '../../../../components/Icons';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import DragHandle from '../../../../components/tabel/DragHandle';
import { Helmet } from 'react-helmet';

interface IWijzigenState {
  eigGrpID: number;
  initialValues: IFormikValues;
}

interface IUrlState {
  selectie: number[];
  sortering: Sorting[];
  toevoegenState: boolean;
  wijzigenState: IWijzigenState | null;
}
const defaultUrlState: IUrlState = {
  selectie: [],
  sortering: [
    {
      columnName: nameof<IEigenschapGroep>('SortNr'),
      direction: 'asc',
    },
  ],
  toevoegenState: false,
  wijzigenState: null,
};

interface IProps extends RouteComponentProps {}

const Groepen: React.FC<IProps> = observer((props) => {
  const { checkStore, tekstStore } = useContext(RootStoreContext);
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const [
    eigenschapGroepenResult,
    setEigenschapGroepenResult,
  ] = useState<IOphalenEigenschapGroepenResult | null>(null);
  const ophalenEigenschapGroepen = useCallback(async () => {
    const result = await api.v2.aanbod.specificatie.ophalenEigenschapGroepen({
      filterSchema: {
        filters: [],
      },
    });
    setEigenschapGroepenResult(result);
  }, []);
  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    ophalenEigenschapGroepen();
  }, []);

  const keyExtractor = useCallback((row: IEigenschapGroep) => row.ID, []);
  const kolommen = useMemo<TypedColumn<IEigenschapGroep>[]>(
    () => [
      {
        name: '__dragHandle' as any,
        title: ' ',
      },
      {
        name: 'Naam_TekstID',
        title: 'Naam',
      },
      {
        name: 'SortNr',
        title: 'Sort nr',
      },
    ],
    [],
  );
  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IEigenschapGroep>[]>(
    () => [
      {
        columnName: '__dragHandle' as any,
        width: 50,
      },
      {
        columnName: 'Naam_TekstID',
        width: 350,
      },
      {
        columnName: 'SortNr',
        width: 100,
      },
    ],
    [urlState.sortering],
  );

  return (
    <>
      <Helmet>
        <title>Specificaties Groepen</title>
      </Helmet>
      <div className="d-flex flex-column flex-fill">
        <MenuLayout
          menu={
            <div>
              {/*<SelectieVak*/}
              {/*  aantal={urlState.selectie.length}*/}
              {/*  totaalAantal={}*/}
              {/*  onChange={(alles) => {*/}
              {/*    if (alles) {*/}
              {/*    } else {*/}
              {/*      setUrlStateSync('selectie', []);*/}
              {/*    }*/}
              {/*  }}*/}
              {/*  entiteitEnkelvoud="groep"*/}
              {/*  entiteitMeervoud="groepen"*/}
              {/*/>*/}
            </div>
          }
          body={
            <div className="d-flex flex-column flex-fill justify-content-center align-items-center">
              {eigenschapGroepenResult === null ? (
                <LoadingSpinner />
              ) : (
                <GridStyleWrapper height="calc(100vh - 122px)">
                  <Grid
                    getRowId={keyExtractor}
                    rows={eigenschapGroepenResult.groepen}
                    columns={kolommen}
                  >
                    <DataTypeProvider
                      for={['__dragHandle']}
                      formatterComponent={(formatterProps) => {
                        if (
                          urlState.sortering.length !== 1 ||
                          urlState.sortering.findIndex(
                            (x) =>
                              x.columnName === nameof<IEigenschapGroep>('SortNr') &&
                              x.direction === 'asc',
                          ) === -1
                        ) {
                          return <span />;
                        }

                        return <DragHandle />;
                      }}
                    />
                    <DataTypeProvider
                      for={[nameof<IEigenschapGroep>('Naam_TekstID')]}
                      formatterComponent={(formatterProps) => {
                        const tekstID: number = formatterProps.value;
                        return <TekstVisualisatie tekstID={tekstID} />;
                      }}
                    />

                    <SortingState
                      sorting={urlState.sortering}
                      onSortingChange={(x) => setUrlStateSync('sortering', x)}
                    />

                    <EditingState
                      onCommitChanges={async (changeset) => {
                        if (changeset.deleted !== undefined && changeset.deleted.length > 0) {
                          const params: IVerwijderenEigenschapGroepenParams = {
                            eigGrpIDs: changeset.deleted as number[],
                          };

                          const checkData = await api.v2.aanbod.specificatie.checkVerwijderenEigenschapGroepen(
                            params,
                          );
                          if (
                            (await checkStore.controleren({ checkData })).type ===
                            EResultType.Annuleren
                          ) {
                            return;
                          }
                          if (
                            (await checkStore.bevestigen({ inhoud: 'Verwijderen?' })).type ===
                            EResultType.Annuleren
                          ) {
                            return;
                          }
                          await api.v2.aanbod.specificatie.verwijderenEigenschapGroepen(params);
                        }
                      }}
                      onAddedRowsChange={() => setUrlStateSync('toevoegenState', true)}
                      onEditingRowIdsChange={async (ids) => {
                        const id = ids[ids.length - 1] as number;
                        const groep = eigenschapGroepenResult!.groepen.find(
                          (x) => keyExtractor(x) === id,
                        )!;

                        const tekstenResult = await api.v2.tekst.ophalenTekstenInAlleTalen({
                          tekstIDs: [groep.Naam_TekstID],
                        });
                        const naam = tekstenResult.teksten.map((x) => {
                          return {
                            taalID: x.TaalID,
                            tekst: x.Tekst || '',
                            toepassen: false,
                          };
                        }, {});
                        setUrlStateSync('wijzigenState', {
                          eigGrpID: id,
                          initialValues: {
                            naamEnum: groep.NaamEnum || '',
                            naam,
                          },
                        });
                      }}
                    />

                    <IntegratedSorting />

                    <Table
                      rowComponent={({ row, ...restProps }) => {
                        const TableRow = SortableElement(Table.Row);
                        return (
                          <TableRow
                            {...restProps}
                            row={row}
                            index={eigenschapGroepenResult!.groepen.indexOf(row)}
                          />
                        );
                      }}
                      bodyComponent={({ row, ...restProps }: any) => {
                        const TableBody = SortableContainer(Table.TableBody);
                        return (
                          <TableBody
                            {...restProps}
                            onSortEnd={async (x) => {
                              const oudeGroep = eigenschapGroepenResult!.groepen[x.oldIndex]!;
                              const nieuweGroep = eigenschapGroepenResult!.groepen[x.newIndex]!;

                              // Lokaal wijzigingen direct reflecteren
                              setEigenschapGroepenResult({
                                groepen: arrayMove(
                                  eigenschapGroepenResult!.groepen.map((groep, i) => {
                                    if (i === x.oldIndex) {
                                      return {
                                        ...groep,
                                        SortNr: nieuweGroep.SortNr,
                                      };
                                    } else if (i === x.newIndex) {
                                      return {
                                        ...groep,
                                        SortNr: eigenschapGroepenResult!.groepen[x.oldIndex].SortNr,
                                      };
                                    }

                                    return groep;
                                  }),
                                  x.oldIndex,
                                  x.newIndex,
                                ),
                              });
                              await api.v2.aanbod.specificatie.sorterenEigenschapGroepen({
                                oudEigGrpID: oudeGroep.ID,
                                nieuwEigGrpID: nieuweGroep.ID,
                              });
                              await ophalenEigenschapGroepen();
                            }}
                            useDragHandle
                          />
                        );
                      }}
                    />
                    <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
                    <TableHeaderRow showSortingControls />
                    <TableEditColumn
                      width={65}
                      showAddCommand={true}
                      showEditCommand
                      showDeleteCommand
                      commandComponent={DXCommandComponent}
                    />
                  </Grid>
                </GridStyleWrapper>
              )}
            </div>
          }
        />
      </div>
      {urlState.toevoegenState && (
        <MuterenDialoog
          open
          onSuccess={async (data) => {
            const params: IToevoegenEigenschapGroepParams = {
              naam: data.naam,
              naamEnum: data.naamEnum,
            };
            const checkData = await api.v2.aanbod.specificatie.checkToevoegenEigenschapGroep(
              params,
            );
            if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
              return;
            }
            await api.v2.aanbod.specificatie.toevoegenEigenschapGroep(params);
            await ophalenEigenschapGroepen();
            setUrlStateSync('toevoegenState', false);
          }}
          onAnnuleren={() => setUrlStateSync('toevoegenState', false)}
        />
      )}
      {urlState.wijzigenState !== null && (
        <MuterenDialoog
          open
          onSuccess={async (data) => {
            const params: IWijzigenEigenschapGroepParams = {
              eigGrpID: urlState.wijzigenState!.eigGrpID,
              naam: data.naam,
              naamEnum: data.naamEnum,
            };
            const checkData = await api.v2.aanbod.specificatie.checkWijzigenEigenschapGroep(params);
            if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
              return;
            }
            await api.v2.aanbod.specificatie.wijzigenEigenschapGroep(params);
            await ophalenEigenschapGroepen();
            setUrlStateSync('wijzigenState', null);
          }}
          onAnnuleren={() => setUrlStateSync('wijzigenState', null)}
          initialValues={urlState.wijzigenState.initialValues}
        />
      )}
    </>
  );
});

export default withRouter(Groepen);
