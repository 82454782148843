import React, { useMemo } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { genereerUrlStateQueryParam } from '../../../core/useUrlState';
import { IUrlState } from '../../Monitoring/Winkelwagens';
import { IActieProps } from '../index';

interface IData {
  cartID: number;
}

const BESTELLING_ONVERWERKT: React.VoidFunctionComponent<IActieProps> = (props: IActieProps) => {
  const data = useMemo(() => JSON.parse(props.controltaak.Data!) as IData, [
    props.controltaak.Data,
  ]);
  return (
    <a
      href="#"
      onClick={() => {
        const state = genereerUrlStateQueryParam<IUrlState>({
          selectie: [data.cartID],
          uitgeklapt: [],
          filterData: [],
        });
        props.history.push(`/opvolging/winkelwagens?state=${state}`);
      }}
    >
      Naar winkelwagens
    </a>
  );
};

export default BESTELLING_ONVERWERKT;
