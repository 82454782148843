import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { IEntiteitProps } from '../../EntiteitContainer';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../../stores/RootStore';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../models/IRemoteData';
import {
  IOphalenContactpersonenResultElement,
  IOphalenRelatieBetrekkingenResultElement,
} from '../../../../../../shared/src/api/v2/relatie/contactpersoon';
import api from '../../../../api';
import LoadingSpinner, { LoadingSpinnerCenter } from '../../../Gedeeld/LoadingSpinner';
import MenuLayout from '../../../MenuLayout';
import { IconToevoegen } from '../../../Icons';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import useUrlState from '../../../../core/useUrlState';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import PersoonSelectieDialoog, { ETabblad } from '../../../dialogen/PersoonSelectieDialoog';
import { IOphalenAccountsResultElement } from '../../../../../../shared/src/api/v2/account/account';
import FilterBalkV2, { IFilter, IFilterData, maakFilterSchema } from '../../../FilterBalkV2';
import ZoektermFilter from './ZoektermFilter';
import ContactpersonenTabel, { IContactpersonenTabelData } from './ContactpersonenTabel';

interface IProps extends IEntiteitProps, RouteComponentProps {}

interface IToevoegenContactpersoonDialoogState {
  tabblad?: ETabblad;
}

interface IPersoonWijzigenDialoogState {
  persID: number;
}

interface IKredietwaardigheidDialoogState {
  persID: number;
}

interface IToevoegenAccountDialoogState {
  persID: number;
}

interface IWijzigenRelatieGerelateerdDialoogState {
  persID: number;
}

interface IPersooninfoDialoogState {
  persID: number;
}

export enum EFilter {
  Actief = 'IS_ACTIEF',
  Zoekterm = 'ZOEKTERM',
}

interface IUrlState {
  toevoegenContactpersoonDialoogState: IToevoegenContactpersoonDialoogState | null;
  // persoonWijzigenDialoogState: IPersoonWijzigenDialoogState | null;
  // kredietwaardigheidDialoogState: IKredietwaardigheidDialoogState | null;
  // toevoegenAccountDialoogState: IToevoegenAccountDialoogState | null;
  // wijzigenRelatieGerelateerdDialoogState: IWijzigenRelatieGerelateerdDialoogState | null;
  // persooninfoDialoogState: IPersooninfoDialoogState | null;
  filterData: IFilterData<EFilter>[];
}
const defaultUrlState: IUrlState = {
  toevoegenContactpersoonDialoogState: null,
  // persoonWijzigenDialoogState: null,
  // kredietwaardigheidDialoogState: null,
  // toevoegenAccountDialoogState: null,
  // wijzigenRelatieGerelateerdDialoogState: null,
  // persooninfoDialoogState: null,
  filterData: [
    {
      naam: EFilter.Actief,
      data: true,
      isActief: true,
    },
    {
      naam: EFilter.Zoekterm,
      data: '',
      isActief: false,
    },
  ],
};

interface IPersoon extends IOphalenContactpersonenResultElement {
  account: IOphalenAccountsResultElement | null;
  isRelatieVoor_RelIDs: number[];
}

const Contactpersonen: React.FC<IProps> = observer((props) => {
  const { klantkaartStore } = useContext(RootStoreContext);
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);

  const [personen, setPersonen] = useState<IRemoteData<IPersoon[]>>(createPendingRemoteData());

  const ophalenPersonenAbortControllerRef = useRef<AbortController | null>(null);
  const ophalenPersonen = useCallback(async () => {
    if (ophalenPersonenAbortControllerRef.current !== null) {
      ophalenPersonenAbortControllerRef.current!.abort();
    }
    ophalenPersonenAbortControllerRef.current = new AbortController();

    const filterSchema = maakFilterSchema(urlState.filterData);

    const contactpersonenResult = await api.v2.relatie.ophalenContactpersonen(
      {
        filterSchema: {
          filters: [
            ...(filterSchema.filters ?? []),
            {
              naam: 'REL_IDS',
              data: [props.relID],
            },
          ],
        },
      },
      ophalenPersonenAbortControllerRef.current!.signal,
    );

    const personen = contactpersonenResult.contactpersonen;

    const persIDs = personen.map((x) => x.PersID);

    // Haal de contactpersonen op via de PersID om te bepalen of ze een relatie zijn anders dan bij deze relatie
    const personenDieEenRelatieZijnresult = await api.v2.relatie.ophalenContactpersonen(
      {
        filterSchema: {
          filters: [
            {
              naam: 'PERS_IDS',
              data: persIDs,
            },
            { naam: 'IS_RELATIE', data: true },
          ],
        },
      },
      ophalenPersonenAbortControllerRef.current!.signal,
    );

    const accounts = await api.v2.account.ophalenAccounts(
      {
        filterSchema: {
          filters: [
            { naam: 'REL_IDS', data: [klantkaartStore.relatie!.RelID] },
            { naam: 'PERS_IDS', data: personen.map((x) => x.PersID) },
          ],
        },
      },
      ophalenPersonenAbortControllerRef.current!.signal,
    );

    const contactpersonen = personen.map((persoon) => {
      // tslint:disable-next-line:variable-name
      const isRelatieVoor_RelIDs = personenDieEenRelatieZijnresult.contactpersonen
        .filter((x) => x.PersID === persoon.PersID && x.RelID !== props.relID)
        .map((x) => x.RelID);
      return {
        ...persoon,
        account: accounts.find((x) => x.PersID === persoon.PersID) ?? null,
        isRelatieVoor_RelIDs,
      };
    });

    setPersonen(createReadyRemoteData(contactpersonen));
  }, [props.relID, JSON.stringify(urlState.filterData)]);

  useEffect(() => {
    ophalenPersonen();
  }, [ophalenPersonen]);

  const relatieBetrekkingIDs = useMemo<IRemoteData<number[]>>(() => {
    if (personen.state === ERemoteDataState.Pending) {
      return createPendingRemoteData();
    }
    const ids = personen
      .data!.map((x) => x.RelatieBetrekkingID)
      .filter((x) => x !== null) as number[];
    return createReadyRemoteData(ids);
  }, [personen]);

  const [relatieBetrekkingen, setRelatieBetrekkingen] = useState<
    IRemoteData<Record<number, IOphalenRelatieBetrekkingenResultElement>>
  >(createPendingRemoteData());
  const bepalenRelatieBetrekkingen = useCallback(async () => {
    if (relatieBetrekkingIDs.state === ERemoteDataState.Pending) {
      return;
    }
    if (relatieBetrekkingIDs.data!.length === 0) {
      setRelatieBetrekkingen(createReadyRemoteData({}));
      return;
    }
    const result = await api.v2.relatie.ophalenRelatieBetrekkingen({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: relatieBetrekkingIDs.data!,
          },
        ],
      },
    });
    const relatieBetrekkingen = result.betrekkingen.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.ID]: curr,
      }),
      {},
    );
    setRelatieBetrekkingen(createReadyRemoteData(relatieBetrekkingen));
  }, [JSON.stringify(relatieBetrekkingIDs)]);
  useEffect(() => {
    bepalenRelatieBetrekkingen();
  }, [bepalenRelatieBetrekkingen]);

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.Actief,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => <span>Actief</span>,
      },
      {
        naam: EFilter.Zoekterm,
        altijdWeergevenInBalk: true,
        weergave: ZoektermFilter,
      },
    ],
    [],
  );

  const data = useMemo<IRemoteData<IContactpersonenTabelData>>(() => {
    if (
      personen.state === ERemoteDataState.Pending ||
      relatieBetrekkingen.state === ERemoteDataState.Pending
    ) {
      return createPendingRemoteData();
    }

    const talenBijID = personen.data!.reduce<IContactpersonenTabelData['talenBijID']>(
      (acc, curr) => {
        if (acc[curr.taal.TaalID] === undefined) {
          acc[curr.taal.TaalID] = {
            naamKort: curr.taal.TaalKort,
          };
        }
        return acc;
      },
      {},
    );
    const relatieBetrekkingenBijID = Object.keys(relatieBetrekkingen.data!).reduce<
      IContactpersonenTabelData['relatieBetrekkingenBijID']
    >((acc, curr) => {
      const key = parseInt(curr, 10);
      const relatiebetrekking = relatieBetrekkingen.data![key];
      acc[key] = {
        Naam: relatiebetrekking.Naam,
      };
      return acc;
    }, {});

    const data: IContactpersonenTabelData = {
      contactpersonen: personen.data!.map((persoon) => ({
        id: persoon.ID,
        achternaam: persoon.Achternaam,
        email: persoon.Email,
        inactiefOp: persoon.InactiefOp,
        isPersoonPrimair: persoon.IsPersoonPrimair,
        persID: persoon.PersID,
        isRelatieVoor_RelIDs: persoon.isRelatieVoor_RelIDs,
        notities: persoon.Notities,
        recordGewijzigd: persoon.RecordGewijzigd,
        relatieBetrekkingID: persoon.RelatieBetrekkingID,
        taalID: persoon.taal.TaalID,
        tekenbevoegd: persoon.Tekenbevoegd,
        telefoonMobiel: persoon.TelefoonMobiel,
        whatsAppSesID: persoon.WhatsAppSesID,
      })),
      relatieBetrekkingenBijID,
      talenBijID,
    };

    return createReadyRemoteData(data);
  }, [personen, relatieBetrekkingen]);

  return (
    <>
      <div className="d-flex flex-fill" style={{ width: '100%' }}>
        {personen.state === ERemoteDataState.Pending ? (
          <div className="d-flex flex-fill align-items-center justify-content-center">
            <LoadingSpinner />
          </div>
        ) : (
          <div className="d-flex flex-fill">
            <MenuLayout
              menu={
                <div className="d-flex align-items-center">
                  <button
                    className="btn btn-sm btn-light d-flex align-items-center ml-2"
                    style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                    onClick={() => setUrlStateSync('toevoegenContactpersoonDialoogState', {})}
                  >
                    <IconToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                    <span className="ml-2">Toevoegen</span>
                  </button>

                  <FilterBalkV2
                    filters={filters}
                    filterData={urlState.filterData}
                    onFilterDataChange={(x) => setUrlStateSync('filterData', x)}
                    style={{ marginLeft: 20 }}
                  />
                </div>
              }
              body={
                data.state === ERemoteDataState.Pending ? (
                  <LoadingSpinnerCenter />
                ) : (
                  <ContactpersonenTabel
                    relID={props.relID}
                    kolommen={[
                      'naam',
                      'indicaties',
                      'persoon_info',
                      'taal',
                      'telefoon_mobiel',
                      'communicatie',
                      'acties',
                      'notities',
                      'betrekking',
                      'laatst_gewijzigd',
                    ]}
                    verversen={ophalenPersonen}
                    data={data.data!}
                    acties={[
                      'wijzigen',
                      'account_toevoegen',
                      'krediettoets',
                      'ontkoppelen',
                      'wijzigen_relatie',
                    ]}
                  />
                )
              }
            />
          </div>
        )}
      </div>
      {urlState.toevoegenContactpersoonDialoogState !== null && (
        <PersoonSelectieDialoog
          tabblad={
            urlState.toevoegenContactpersoonDialoogState.tabblad || ETabblad.NieuwOfSelecteren
          }
          onTabbladChange={(tabblad) =>
            setUrlStateSync('toevoegenContactpersoonDialoogState', { tabblad })
          }
          open
          onSuccess={async (data) => {
            await api.v2.relatie.koppelenContactpersoon({
              persID: data.persID,
              relID: props.relID,
            });
            await api.v2.persoon.bijwerkenLijstRecent({
              persID: data.persID,
            });

            await ophalenPersonen();
            setUrlStateSync('toevoegenContactpersoonDialoogState', null);
          }}
          onAnnuleren={() => {
            setUrlStateSync('toevoegenContactpersoonDialoogState', null);
          }}
        />
      )}
    </>
  );
});

export default withRouter(Contactpersonen);
