import React, { useCallback, useContext, useEffect, useMemo, useState, useRef } from 'react';
import IDialoogProps from '../../../core/IDialoogProps';
import Dialoog from '../../dialogen/Dialoog';
import ModalFooter from 'react-bootstrap/ModalFooter';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import * as Yup from 'yup';
import nameOf from '../../../core/nameOf';
import LocatieSelectie from '../../formulier/LocatieSelectie';
import api from '../../../api';
import {
  IOphalenProductenResultElement,
  IOphalenMeldingenResultElement,
  IOphalenMeldingstatussenResultElement,
} from '../../../../../shared/src/api/v2/service/melding';
import LoadingSpinner from '../../Gedeeld/LoadingSpinner';
import VinkVeld from '../../formulier/VinkVeld';
import FormikVeldFout from '../../formulier/FormikVeldFout';
import ContactSelectie, { EType } from '../../formulier/ContactSelectie';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../stores/RootStore';
import { Kleur } from '../../../bedrijfslogica/constanten';
import { maandenNaarJaren } from '../../../bedrijfslogica/teksten';
import TelefoonnummerInput, {
  genereerRecenteOproepenSuggestiesProvider,
  ISuggestiesProvider,
} from '../../formulier/TelefoonnummerInput';
import Combobox from '../../formulier/Combobox';
import { IOphalenContractenResultElementV2 } from '../../../../../shared/src/api/v2/contract';
import MultiCombobox, { IKolom } from '../../formulier/MultiCombobox';
import { format } from 'date-fns';
import { IFilterSchemaFilter } from '../../../../../shared/src/models/filter';
import RelatieVisualisatie from '../../personalia/RelatieVisualisatie';

interface IProps extends IDialoogProps<null> {
  servMeldID: number;
}

interface IFormikValues {
  omschrijving: string;
  melder_PersID: number | null;
  email: string;
  telefoonNummer: string;
  locID: number | null;
  status: number;
  afgehandeld: boolean;
  cntID: number | null;
  meerContractenWeergeven: boolean;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  omschrijving: 'Omschrijving',
  melder_PersID: 'Melder',
  email: 'Email',
  telefoonNummer: 'Telefoonnummer',
  locID: 'Locatie',
  status: 'Status melding',
  afgehandeld: 'Afgehandeld',
  cntID: 'Contract',
  meerContractenWeergeven: 'Meer contracten weergeven',
};

const WijzigenMeldingDialoog: React.FC<IProps> = observer((props) => {
  const { dialoogIndex, onAnnuleren, onSuccess, open } = props;
  const formikRef = useRef<Formik<IFormikValues>>(null);
  const { gebruikerStore } = useContext(RootStoreContext);

  const [producten, setProducten] = useState<IOphalenProductenResultElement[] | null>(null);
  const [melding, setMelding] = useState<IOphalenMeldingenResultElement | null>(null);
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const [gerelateerdeContracten, setGerelateerdeContracten] = useState<
    IOphalenContractenResultElementV2[] | null
  >(null);

  // Haal de melding op
  useEffect(() => {
    (async () => {
      const result = await api.v2.service.ophalenMeldingen({
        filterSchema: {
          filters: [
            {
              naam: 'IDS',
              data: [props.servMeldID],
            },
          ],
        },
      });
      setMelding(result.meldingen[0]);
    })();
  }, [props.servMeldID]);

  // Productidentificatie
  const productInfo =
    melding !== null
      ? melding.product !== null
        ? melding.product.producttype.Productsoortnaam +
          ' ' +
          melding.product.producttype.Typenaam +
          ' ' +
          melding.product.producttype.Merknaam +
          (melding.product.Referentiecode !== null
            ? ', ref. ' + melding.product.Referentiecode
            : '') +
          ' (lft. ' +
          (melding.product.leeftijd !== null ? maandenNaarJaren(melding.product.leeftijd) : '') +
          ')'
        : null
      : null;

  // Contracten van de relatie
  useEffect(() => {
    (async () => {
      if (melding === null) {
        return;
      }
      const contractenVanRelatie = (
        await api.v2.contract.ophalenContractenV2({
          filterSchema: {
            filters: [{ naam: 'LOC_IDS', data: [melding.locatie.LocID] }].filter(
              (x) => x !== null,
            ) as IFilterSchemaFilter[],
          },
        })
      ).contracten;

      setGerelateerdeContracten(contractenVanRelatie);
    })();
  }, [melding]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);
      const params = {
        id: props.servMeldID,
        omschrijving: values.omschrijving,
        persID: values.melder_PersID,
        email: values.email,
        telefoon: values.telefoonNummer,
        telefoonAlternatief: null,
        locID: values.locID,
        servMeldStatID: values.status,
        afgehandeld: values.afgehandeld,
        cntID: values.cntID,
      };

      await api.v2.service.wijzigenMelding(params);

      onSuccess(null);
      actions.setSubmitting(false);
    },
    [onSuccess],
  );

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (melding === null) {
      return null;
    }

    return {
      omschrijving: melding.Omschrijving,
      melder_PersID: melding.persoon !== null ? melding.persoon.PersID : null,
      email: melding.Email !== null ? melding.Email : '',
      telefoonNummer: melding.Telefoon !== null ? melding.Telefoon : '',
      locID: melding.locatie !== null ? melding.locatie.LocID : null,
      status: melding.status.ID,
      afgehandeld: melding.Afgehandeld,
      cntID: melding.contract !== null ? melding.contract.CntID : null,
      meerContractenWeergeven: false,
    };
  }, [melding]);

  // useEffect(() => {
  //   // Automatisch voorvullen van melder gegevens
  //   if (melding === null || melding.persoon === null) {
  //     return;
  //   }
  //   handleTelefoonnummerEnEmailVoorvullen(
  //     melding.persoon.PersID,
  //     formikRef.current!.getFormikBag(),
  //   );
  // }, [melding]);

  const contractwisselKolommen = useMemo<IKolom<IOphalenContractenResultElementV2>[]>(
    () => [
      {
        key: '__Contractnummer' as any,
        label: 'Cnt.nr',
        breedte: 75,
        formatFabriek: (rij) => rij.basis.Basisnummer + '.' + rij.Volgnummer,
      },
      {
        key: 'Modelcode' as any,
        label: 'Model',
        breedte: 110,
        formatFabriek: (rij) => rij.basis.productmodel.Modelcode,
      },
      {
        key: '__Ingangsdatum' as any,
        label: 'Ing.datum',
        breedte: 100,
        formatFabriek: (rij) => {
          const datum = format(new Date(rij.Ingangsdatum), 'dd-MM-yyyy');
          return datum;
        },
      },
      {
        key: '__Einddatum' as any,
        label: 'Einddatum',
        breedte: 100,
        formatFabriek: (rij) => {
          const datum =
            rij.Einddatum !== null ? format(new Date(rij.Einddatum), 'dd-MM-yyyy') : null;
          return datum;
        },
      },
      {
        key: '__relatie' as any,
        label: 'Relatie',
        breedte: 300,
        formatFabriek: (rij) => (
          <RelatieVisualisatie relID={rij.RelID} options={{ geenLinkToepassen: true }} />
        ),
      },
    ],
    [],
  );

  const validationSchema = useMemo(() => {
    const fields: Record<keyof IFormikValues, any> = {
      omschrijving: Yup.string().required(),
      melder_PersID: Yup.number().nullable(),
      email: Yup.string(),
      telefoonNummer: Yup.string(),
      locID: Yup.number(),
      status: Yup.number(),
      afgehandeld: Yup.boolean(),
      cntID: Yup.number().nullable(),
      meerContractenWeergeven: Yup.boolean(),
    };
    return Yup.object().shape(fields);
  }, []);

  const handleTelefoonnummerEnEmailVoorvullen = useCallback(
    async (persID: number | null, form: FormikProps<IFormikValues>) => {
      if (persID === null) {
        return;
      }

      // const result = await api.v2.persoon.ophalenPersoon({
      //   persID,
      // });
      const result = await api.v2.persoon.ophalenPersonen({
        filterSchema: {
          filters: [
            {
              naam: 'IDS',
              data: [persID],
            },
          ],
        },
      });
      const persoon = result.personen[0];

      form.setFieldValue(nameOf<IFormikValues>('telefoonNummer'), persoon.TelefoonMobiel || '');
      form.setFieldValue(nameOf<IFormikValues>('email'), persoon.Email || '');
    },
    [],
  );

  const [
    telefoonnummerInputSuggestiesProvider,
    setTelefoonnummerInputSuggestiesProvider,
  ] = useState<ISuggestiesProvider | null>(null);

  useEffect(() => {
    if (gebruikerStore.gebruiker === null) {
      return;
    }
    genereerRecenteOproepenSuggestiesProvider(gebruikerStore.gebruiker.AspGebrID).then((provider) =>
      setTelefoonnummerInputSuggestiesProvider(provider),
    );
  }, [gebruikerStore.gebruiker]);

  const [statussen, setStatussen] = useState<IOphalenMeldingstatussenResultElement[] | null>(null);
  const ophalenStatussen = useCallback(async () => {
    const result = await api.v2.service.ophalenMeldingstatussen({});
    setStatussen(result.statussen);
  }, []);

  useEffect(() => {
    ophalenStatussen();
  }, []);

  return (
    <Dialoog
      index={dialoogIndex || 0}
      modalProps={{
        size: 'lg',
      }}
    >
      <ModalHeader>
        <ModalTitle>
          Wijzigen servicemelding {melding !== null ? melding.Meldnummer : ''}
        </ModalTitle>
      </ModalHeader>
      {melding === null || gerelateerdeContracten === null || initialValues === null ? (
        <div className="d-flex flex-fill align-items-center justify-content-center p-4">
          <LoadingSpinner />
        </div>
      ) : (
        <Formik<IFormikValues>
          ref={formikRef}
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
          render={(formikProps: FormikProps<IFormikValues>) => {
            const { submitForm, isSubmitting, isValid } = formikProps;

            return (
              <>
                <ModalBody>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-12">
                        <div
                          className="p-2"
                          style={{
                            backgroundColor: Kleur.HeelLichtGrijs,
                            borderRadius: 3,
                          }}
                        >
                          {productInfo !== null ? productInfo : <span>Geen product opgegeven</span>}
                        </div>
                      </div>

                      <div className="col-12 mt-3">
                        <label>{veldnamen.locID}</label>
                        <Field
                          name={nameOf<IFormikValues>('locID')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <LocatieSelectie
                                relIDs={melding.RelID !== null ? [melding.RelID] : undefined}
                                locID={field.value}
                                onChange={(x) => form.setFieldValue(field.name, x)}
                              />
                            );
                          }}
                        />
                      </div>

                      <div className="row col-12 d-flex mt-3">
                        <div className="col-6">
                          <label>{veldnamen.cntID}</label>
                          <Field
                            name={nameOf<IFormikValues>('cntID')}
                            render={(fieldProps: FieldProps<IFormikValues>) => {
                              const { field, form } = fieldProps;
                              return (
                                <MultiCombobox<number, IOphalenContractenResultElementV2>
                                  sleutelExtractor={(row) => row.CntID}
                                  representatieFabriek={(row) => {
                                    // const minimaleDatum = addMonths(
                                    //   new Date(row.IngangsdatumBasis),
                                    //   row.basis.MinimaleTermijn,
                                    // );
                                    // const opzegbaar =
                                    //   minimaleDatum > new Date()
                                    //     ? 'opzegbaar per ' + format(minimaleDatum, 'dd-MM-yyyy')
                                    //     : 'opzegbaar';

                                    return (
                                      row.basis.Basisnummer +
                                      '.' +
                                      row.Volgnummer +
                                      ' - ' +
                                      row.basis.productmodel.Modelcode
                                    );
                                  }}
                                  waarde={fieldProps.field.value}
                                  onWaardeChange={(x) => {
                                    return form.setFieldValue(field.name, x);
                                  }}
                                  opties={gerelateerdeContracten}
                                  kolommen={contractwisselKolommen}
                                  isWisbaar
                                  options={{
                                    geenWaardeBericht: 'Kies optioneel een contract',
                                  }}
                                />
                              );
                            }}
                          />
                        </div>

                        {/* <div className="col-4 mt-3">
                          <label></label>
                          <Field
                            name={nameOf<IFormikValues>('meerContractenWeergeven')}
                            render={(fieldProps: FieldProps<IFormikValues>) => {
                              const { field, form } = fieldProps;
                              return (
                                <>
                                  <div className="d-flex align-items-center ml-3">
                                    <VinkVeld
                                      aangevinkt={field.value}
                                      onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                    />
                                    <span className="ml-2">
                                      {veldnamen.meerContractenWeergeven}
                                    </span>
                                  </div>
                                </>
                              );
                            }}
                          />
                        </div> */}
                      </div>

                      <div className="col-12 mt-3">
                        <label>{veldnamen.omschrijving}</label>
                        <Field
                          name={nameOf<IFormikValues>('omschrijving')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            return <textarea {...field} className="form-control" rows={4} />;
                          }}
                        />
                      </div>

                      <div className="col-12 row d-flex mt-3">
                        <div className="col-6">
                          <div>
                            <label>{veldnamen.melder_PersID}</label>
                            <Field
                              name={nameOf<IFormikValues>('melder_PersID')}
                              render={(fieldProps: FieldProps<IFormikValues>) => {
                                const { field, form } = fieldProps;

                                return (
                                  <ContactSelectie
                                    isWisbaar
                                    alleenVoorRelIDs={
                                      melding.RelID !== null ? [melding.RelID] : undefined
                                    }
                                    value={
                                      field.value === null
                                        ? null
                                        : {
                                            persID: field.value,
                                            orgID: null,
                                          }
                                    }
                                    onChange={async (x) => {
                                      const persID = x?.persoon?.PersID ?? null;
                                      form.setFieldValue(field.name, persID);
                                      await handleTelefoonnummerEnEmailVoorvullen(persID, form);
                                    }}
                                    options={{
                                      types: [EType.Persoon],
                                      persoonSelectieDialoogDefaultFormValues: {
                                        telefoon: form.values.telefoonNummer,
                                        email: form.values.email,
                                        voornaam: melding.Voornaam ?? '',
                                        achternaam: melding.Achternaam ?? '',
                                      },
                                    }}
                                  />
                                );
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-6">
                          <label>{veldnamen.telefoonNummer}</label>
                          <Field
                            name={nameOf<IFormikValues>('telefoonNummer')}
                            render={(fieldProps: FieldProps<IFormikValues>) => {
                              const { field, form } = fieldProps;

                              return telefoonnummerInputSuggestiesProvider === null ? (
                                <LoadingSpinner />
                              ) : (
                                <TelefoonnummerInput
                                  telefoonnummer={field.value}
                                  onChange={(x) => form.setFieldValue(field.name, x)}
                                  suggestiesProvider={telefoonnummerInputSuggestiesProvider}
                                />
                              );
                            }}
                          />
                        </div>
                      </div>

                      <div className="col-12 mt-3">
                        <label>{veldnamen.email}</label>
                        <Field
                          name={nameOf<IFormikValues>('email')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            return <input {...field} className="form-control" type="text" />;
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3"></div>

                      <div className="col-6">
                        <label>{veldnamen.status}</label>
                        <Field
                          name={nameOf<IFormikValues>('status')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            if (statussen === null) {
                              return <LoadingSpinner />;
                            }

                            return (
                              <Combobox
                                geselecteerd={field.value}
                                opties={statussen.map((x) => {
                                  return {
                                    id: x.ID,
                                    label: x.Naam,
                                  };
                                })}
                                onSelectieChange={(x) => form.setFieldValue(field.name, x)}
                              />
                            );
                          }}
                        />
                      </div>

                      <div className="col-6">
                        <label></label>
                        <Field
                          name={nameOf<IFormikValues>('afgehandeld')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            return (
                              <>
                                <div className="d-flex align-items-center">
                                  <VinkVeld
                                    aangevinkt={field.value}
                                    onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                  />
                                  <span className="ml-2">Afgehandeld</span>
                                </div>
                                <FormikVeldFout fieldProps={fieldProps} />
                              </>
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </ModalBody>
                <ModalFooter className="d-flex flex-row justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    style={{
                      width: 100,
                    }}
                    disabled={isSubmitting || !isValid}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={onAnnuleren}
                    style={{
                      width: 100,
                    }}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Dialoog>
  );
});

export default WijzigenMeldingDialoog;
