export enum ERemoteDataState {
  Pending,
  Ready,
}

export default interface IRemoteData<T> {
  state: ERemoteDataState;
  data: T | null;
}

export const createPendingRemoteData = <T>(): IRemoteData<T> => {
  return {
    state: ERemoteDataState.Pending,
    data: null,
  };
};

export const createReadyRemoteData = <T>(data: T): IRemoteData<T> => {
  return {
    state: ERemoteDataState.Ready,
    data,
  };
};

export const mapRemoteData = <T, U>(data: IRemoteData<T>, map: (data: T) => U): IRemoteData<U> => {
  if (data.state === ERemoteDataState.Pending) {
    return createPendingRemoteData();
  }
  return createReadyRemoteData(map(data.data!));
};
