import React from 'react';
import { IRow, EKolom } from '../index';
import { Kleur } from '../../../../../bedrijfslogica/constanten';
import { IUitgeklapteRijProps } from '../../../../../components/tabel/ASPTabel/Body/UitgeklapteRij';

const UitgeklapteRij = (props: IUitgeklapteRijProps<EKolom, IRow>) => {
  return (
    <div
      className="p-3 d-flex flex-fill flex-column"
      style={{ backgroundColor: Kleur.HeelLichtGrijs }}
    >
      <div>{props.regel.Bericht}</div>
      {props.regel.Data !== null && (
        <div style={{ backgroundColor: Kleur.LichtGrijs }} className="p-2 mt-2">
          <div className="font-weight-bold">DATA</div>
          <span style={{ whiteSpace: 'pre-line' }} className="mt-4">
            {props.regel.Data}
          </span>
        </div>
      )}
      {props.regel.StackTrace !== null && (
        <div style={{ backgroundColor: Kleur.LichtGrijs }} className="p-2 mt-2">
          <div className="font-weight-bold">STACK TRACE</div>
          <span style={{ whiteSpace: 'pre-line' }} className="mt-4">
            {props.regel.StackTrace}
          </span>
        </div>
      )}
      {props.regel.CodeRef !== null && (
        <div style={{ backgroundColor: Kleur.LichtGrijs }} className="p-2 mt-2">
          <div className="font-weight-bold">CODE REF</div>
          <span style={{ whiteSpace: 'pre-line' }} className="mt-4">
            {props.regel.CodeRef}
          </span>
        </div>
      )}
    </div>
  );
};

export default UitgeklapteRij;
