import React, { useState, useEffect, useCallback, useMemo, useContext } from 'react';
import api from '../../../../../../../api';
import LoadingSpinner from '../../../../../../../components/Gedeeld/LoadingSpinner';
import { observer } from 'mobx-react-lite';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import useUrlState from '../../../../../../../core/useUrlState';
import { IOphalenProductenResultElementV2 } from '../../../../../../../../../shared/src/api/v2/product';
import { RootStoreContext } from '../../../../../../../stores/RootStore';
import {
  TypedColumn,
  TypedTableColumnWidthInfo,
  GridStyleWrapper,
  DXTableCheckboxComponent,
  DXTableToggleCellComponent,
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
} from '../../../../../../../helpers/dxTableGrid';
import { Kleur } from '../../../../../../../bedrijfslogica/constanten';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  VirtualTable,
  TableEditColumn,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  DataTypeProvider,
  EditingState,
  IntegratedSorting,
  RowDetailState,
  SelectionState,
  SortingState,
  VirtualTable as VirtualTableBase,
} from '@devexpress/dx-react-grid';
import { IOphalenProductsoortenResultElement } from '../../../../../../../../../shared/src/api/v2/product/soort';
import FormatteerBedrag from '../../../../../../../components/MutatieBedrag';
import SelectieDialoog from '../../../../../../../components/dialogen/SelectieDialoog';
import { IOphalenVerkoopmodellenResultElement } from '../../../../../../../../../shared/src/api/v2/aanbod/verkoop';
import VinkVeld from '../../../../../../../components/formulier/VinkVeld';
import { url } from 'inspector';
import {
  IFilterSchema,
  IFilterSchemaFilter,
} from '../../../../../../../../../shared/src/models/filter';
import { maandenNaarJaren } from '../../../../../../../bedrijfslogica/teksten';
import FilterBalkV2, {
  IFilter,
  IFilterData,
  maakFilterSchema,
} from '../../../../../../../components/FilterBalkV2';
import MultiCombobox, { IKolom } from '../../../../../../../components/formulier/MultiCombobox';
import { ContractenContext } from '../index';
import ContractVisualisatie from '../../../../../../../components/entiteitVisualisaties/ContractVisualisatie';
import {
  IOphalenContractenResultElement,
  IOphalenContractenResultElementV2,
} from '../../../../../../../../../shared/src/api/v2/contract';
import ProductinfoDialoog from '../../../../../../../components/product/ProductinfoDialoog';
import { IconInformatie } from '../../../../../../../components/Icons';

enum EFilter {
  Productsoort = 'PRODSRT_IDS',
}

interface IProps extends RouteComponentProps {
  productsoorten: IOphalenProductsoortenResultElement[];
}

interface IAccessoireToevoegenDialoogState {
  actief: boolean;
}
interface IProductinfoDialoogState {
  prodID: number;
}

interface IUrlState {
  selectie: number[];
  accessoireToevoegenDialoogState: IAccessoireToevoegenDialoogState | null;
  filterData: IFilterData<EFilter>[];
  productinfoDialoogState: IProductinfoDialoogState | null;
}

const geenData = {
  noData: 'Geen producten gevonden',
};

const Producten: React.FC<IProps> = observer((props) => {
  const defaultUrlState = useMemo<IUrlState>(
    () => ({
      selectie: [],
      accessoireToevoegenDialoogState: null,
      filterData: [
        {
          naam: EFilter.Productsoort,
          data: props.productsoorten.length === 0 ? [] : [props.productsoorten[0].ProdSrtID],
          isActief: false,
        },
      ],
      productinfoDialoogState: null,
    }),
    [],
  );

  const [urlState, setUrlState, setUrlStateSync] = useUrlState<IUrlState>(props, defaultUrlState);
  const { klantkaartStore, checkStore } = useContext(RootStoreContext);
  const contractenContext = useContext(ContractenContext);
  const [producten, setProducten] = useState<IOphalenProductenResultElementV2[] | null>(null);
  const [contracten, setContracten] = useState<IOphalenContractenResultElementV2[] | null>(null);

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.Productsoort,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return (
            <span className="d-flex align-items-center">
              <span>Productsoort</span>
              <span className="ml-2">
                <MultiCombobox<number, IOphalenProductsoortenResultElement>
                  sleutelExtractor={(row) => row.ProdSrtID}
                  waarde={weergaveProps.data === null ? null : weergaveProps.data[0]}
                  onWaardeChange={(value: number | null) => {
                    weergaveProps.onDataChange(value === null ? null : [value]);
                    weergaveProps.setIsActief(true);
                    weergaveProps.toepassen();
                  }}
                  representatieFabriek={(entiteit) => entiteit.Naam}
                  opties={props.productsoorten}
                  kolommen={productsoortenKolommen}
                />
              </span>
            </span>
          );
        },
      },
    ],
    [props.productsoorten],
  );
  const [filterSchema, setFilterSchema] = useState<IFilterSchema>(
    maakFilterSchema(urlState.filterData),
  );

  const ophalenProducten = useCallback(async () => {
    const productenResult = await api.v2.product.ophalenProductenV2({
      filterSchema: {
        filters: [
          ...filterSchema.filters!,
          {
            naam: 'GEKOPPELD_AAN_CONTRACTEN_VAN_RELIDS',
            data: [contractenContext.relID],
          },
        ],
      },
    });

    setProducten(productenResult.producten);
  }, [contractenContext.relID, filterSchema]);

  useEffect(() => {
    ophalenProducten();
  }, [ophalenProducten]);

  const ophalenContracten = useCallback(async () => {
    if (producten === null) {
      return;
    }

    // const contractenResult = (
    //   await api.v2.contract.ophalenContractenV2({
    //     filterSchema: {
    //       filters: [
    //         {
    //           naam: 'CNTBASIS_IDS',
    //           data: producten.map((x) => x.CntBasisID),
    //         },
    //       ],
    //     },
    //   })
    // ).contracten;

    const contractenResult = (
      await api.v2.contract.ophalenContractenV2({
        filterSchema: {
          filters: [
            {
              naam: 'GEKOPPELD_AAN_PRODUCTEN',
              data: producten.map((x) => x.ProdID),
            },
          ],
        },
      })
    ).contracten;

    setContracten(contractenResult);
  }, [producten]);

  useEffect(() => {
    ophalenContracten();
  }, [ophalenContracten]);

  const handleToevoegen = useCallback(async () => {
    setUrlStateSync('accessoireToevoegenDialoogState', {
      actief: true,
    });
  }, [setUrlStateSync]);

  const handleVerwijderen = useCallback(async () => {
    // const checkData = await api.v2.verkoop.checkVerwijderenVerkoopopdrachten({
    //   IDs: urlState.selectie,
    // });
    // if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
    //   return;
    // }

    // if (
    //   (
    //     await checkStore.bevestigen({
    //       titel: (
    //         <span>
    //           Wil je de opdrachten verwijderen?
    //           <br />
    //           <br />
    //           Let op: Dit kan niet meer ongedaan gemaakt worden{' '}
    //         </span>
    //       ),
    //     })
    //   ).type === EResultType.Annuleren
    // ) {
    //   return;
    // }

    // await api.v2.verkoop.verwijderenVerkoopopdrachten({
    //   IDs: urlState.selectie,
    // });

    setUrlStateSync('selectie', []);

    await ophalenProducten();
  }, [urlState.selectie]);

  const keyExtractor = useCallback((row: IOphalenProductenResultElementV2) => row.ProdID, []);

  const kolommen = useMemo<TypedColumn<IOphalenProductenResultElementV2>[]>(
    () => [
      {
        name: '__merknaam' as any,
        title: 'Merk',
        getCellValue: (x) => x.producttype.Merknaam,
      },
      {
        name: '__typenaam' as any,
        title: 'Type',
        getCellValue: (x) => x.producttype.Typenaam,
      },
      {
        name: '__productsoortnaam' as any,
        title: 'Cat.',
        getCellValue: (x) => x.producttype.Productsoortnaam,
      },
      {
        name: 'Referentiecode',
        title: 'Ref.code',
      },
      {
        name: 'leeftijd',
        title: 'Lft.',
      },
      {
        name: '__contractnummer' as any,
        title: 'Contract',
        getCellValue: (x) => {
          if (contracten === null) {
            return;
          }
          const contract = contracten.find((c) => c.basis.CntBasisID === x.CntBasisID)!;
          return contract.basis.Basisnummer + contract.Volgnummer;
        },
      },
      {
        name: '__afwijkendeLocatie' as any,
        title: 'Afw. locatie',
      },
      {
        name: '__productinfo' as any,
        title: ' ',
      },
    ],
    [contracten],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IOphalenProductenResultElementV2>[]>(
    () => [
      {
        columnName: '__merknaam' as any,
        width: 110,
      },
      {
        columnName: '__typenaam' as any,
        width: 150,
      },
      {
        columnName: '__productsoortnaam' as any,
        width: 150,
      },
      {
        columnName: 'Referentiecode',
        width: 100,
      },
      {
        columnName: 'leeftijd',
        width: 100,
      },
      {
        columnName: '__contractnummer' as any,
        width: 100,
      },
      {
        columnName: '__afwijkendeLocatie' as any,
        width: 150,
      },
      {
        columnName: '__productinfo' as any,
        width: 75,
      },
    ],
    [],
  );

  const tabelKolomExtensies: VirtualTableBase.ColumnExtension[] = useMemo(() => {
    return [
      // {
      //   columnName: `Prijs`,
      //   align: 'right',
      // },
    ];
  }, []);

  // Kolommen van filter Productsoorten
  const productsoortenKolommen = useMemo<IKolom<IOphalenProductsoortenResultElement>[]>(
    () => [
      {
        key: 'Naam',
        label: 'Naam',
        breedte: 125,
      },
    ],
    [],
  );

  return (
    <>
      {producten === null || contracten === null ? (
        <LoadingSpinner />
      ) : (
        <>
          <div
            className="d-flex flex-column p-3"
            style={{
              backgroundColor: Kleur.HeelLichtGrijs,
              borderBottom: `1px solid ${Kleur.LichtGrijs}`,
            }}
          >
            <FilterBalkV2
              filters={filters}
              filterData={urlState.filterData}
              onFilterDataChange={(value) => {
                setUrlStateSync('filterData', value);
              }}
              onFilterSchemaChange={(schema) => setFilterSchema(schema)}
            />

            <div className="d-flex">
              {/* <button
                className="btn btn-sm btn-light d-flex align-items-center"
                style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                onClick={() => handleToevoegen()}
              >
                <IconToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                <span className="ml-2">Knop</span>
              </button> */}
            </div>
          </div>
          <GridStyleWrapper height="calc(100vh - 150px)">
            <Grid columns={kolommen} getRowId={keyExtractor} rows={producten!}>
              <DataTypeProvider
                for={['__productsoortnaam']}
                formatterComponent={(formatterProps) => {
                  const rij: IOphalenProductenResultElementV2 = formatterProps.row;
                  return <span>{rij.producttype.Productsoortnaam}</span>;
                }}
              />
              <DataTypeProvider
                for={['__typenaam']}
                formatterComponent={(formatterProps) => {
                  const rij: IOphalenProductenResultElementV2 = formatterProps.row;
                  return <span>{rij.producttype.Typenaam}</span>;
                }}
              />
              <DataTypeProvider
                for={['__merknaam']}
                formatterComponent={(formatterProps) => {
                  const rij: IOphalenProductenResultElementV2 = formatterProps.row;
                  return <span>{rij.producttype.Merknaam}</span>;
                }}
              />

              <DataTypeProvider
                for={['leeftijd']}
                formatterComponent={(formatterProps) => {
                  return (
                    <span>
                      {formatterProps.value !== null ? maandenNaarJaren(formatterProps.value) : ''}
                    </span>
                  );
                }}
              />

              <DataTypeProvider
                for={['__contractnummer']}
                formatterComponent={(formatterProps) => {
                  const rij: IOphalenProductenResultElementV2 = formatterProps.row;

                  const contract = contracten.find((x) => x.basis.CntBasisID === rij.CntBasisID);
                  if (contract === undefined) {
                    return <span></span>;
                  }
                  return <ContractVisualisatie cntID={contract.CntID} />;
                }}
              />

              <DataTypeProvider
                for={['__afwijkendeLocatie']}
                formatterComponent={(formatterProps) => {
                  const rij: IOphalenProductenResultElementV2 = formatterProps.row;

                  if (contracten === null) {
                    return <span></span>;
                  }

                  const contractLocID = contracten.find(
                    (x) => x.basis.CntBasisID === rij.CntBasisID,
                  )!.basis.locatie.LocID;

                  return <span>{rij.locatie!.LocID !== contractLocID ? 'Ja' : ''}</span>;
                }}
              />

              <DataTypeProvider
                for={['__productinfo']}
                formatterComponent={(formatterProps) => {
                  const rij: IOphalenProductenResultElementV2 = formatterProps.row;

                  return (
                    <div className="mb-1">
                      <a
                        href="#"
                        // style={{ color: Kleur.DonkerGrijs }}
                        onClick={() => {
                          setUrlStateSync('productinfoDialoogState', {
                            prodID: rij.ProdID,
                          });
                        }}
                      >
                        <IconInformatie style={{ width: 17, height: 17, fill: Kleur.Blauw }} />
                      </a>
                    </div>
                  );
                }}
              />

              {/* <DataTypeProvider
                for={[nameof<IOphalenVerkopenResultElement>('Verkoopdatum')]}
                formatterComponent={(props) => {
                  return <span>{format(new Date(props.value), 'dd-MM-yyyy HH:mm')}</span>;
                }}
              />

              <DataTypeProvider
                for={[nameof<IOphalenVerkopenResultElement>('Prijs')]}
                formatterComponent={(props) => <MutatieBedrag bedrag={props.value} />}
              /> */}

              <EditingState
                onAddedRowsChange={() => {
                  alert('Plus');
                }}
                onCommitChanges={() => null}
                onEditingRowIdsChange={(rowIds) => {
                  const id = rowIds[rowIds.length - 1] as number;
                  alert('Wijzigen ' + id);
                  // setWijzigenID(id);
                }}
              />

              {/* <RowDetailState defaultExpandedRowIds={[]} /> */}

              <SortingState defaultSorting={[]} />
              <IntegratedSorting />
              <VirtualTable messages={geenData} columnExtensions={tabelKolomExtensies} />

              <TableEditColumn
                width={35}
                // showAddCommand={true}
                // showEditCommand
                cellComponent={DXTableEditColumnCellComponent}
                commandComponent={DXTableEditColumnCommandComponent}
              />

              <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
              <TableHeaderRow showSortingControls />

              {/* <RowDetailState defaultExpandedRowIds={[]} />
              <TableRowDetail
                contentComponent={() => <span>Joehoe</span>}
                toggleCellComponent={DXTableToggleCellComponent}
              /> */}

              {/* <SelectionState
                selection={urlState.selectie}
                onSelectionChange={(value) => setUrlStateSync('selectie', value as number[])}
              />
              <TableSelection cellComponent={DXTableCheckboxComponent} /> */}
            </Grid>
          </GridStyleWrapper>

          {/* <WijzigenVorderingDialoog
            factRegID={wijzigenID!}
            open={wijzigenID !== null}
            onAnnuleren={() => setWijzigenID(null)}
            onSuccess={() => {
              setWijzigenID(null);
              ophalenVorderingen();
            }}
          /> */}
        </>
      )}
      {urlState.productinfoDialoogState !== null && (
        <ProductinfoDialoog
          open
          id={urlState.productinfoDialoogState.prodID}
          onSuccess={() => {
            setUrlStateSync('productinfoDialoogState', null);
          }}
          onAnnuleren={() => setUrlStateSync('productinfoDialoogState', null)}
        />
      )}
      {urlState.accessoireToevoegenDialoogState !== null && (
        <SelectieDialoog<IOphalenVerkoopmodellenResultElement>
          titel="Selecteer accessoire"
          resolveOpties={async () => {
            const result = await api.v2.aanbod.verkoop.ophalenVerkoopmodellen({
              filterSchema: {
                filters: [
                  urlState.accessoireToevoegenDialoogState!.actief
                    ? {
                        naam: 'ACTIEF',
                        data: true,
                      }
                    : null,
                ].filter((x) => x !== null) as IFilterSchemaFilter[],
              },
            });
            return result.verkoopmodellen;
          }}
          keyExtractor={(x) => x.VerkModID}
          kolommen={[
            {
              key: 'Naam',
              label: 'Naam',
              breedte: 300,
            },
            {
              key: 'Prijs',
              label: 'Prijs',
              breedte: 100,
              formatFabriek: (ent) => <FormatteerBedrag bedrag={ent.Prijs} />,
            },
          ]}
          filterSectie={() => {
            return (
              <div className="p-3 pl-4 pr-4">
                <div className="d-flex align-items-center">
                  <VinkVeld
                    aangevinkt={urlState.accessoireToevoegenDialoogState!.actief}
                    onGewijzigd={(x) =>
                      setUrlStateSync('accessoireToevoegenDialoogState', {
                        ...urlState.accessoireToevoegenDialoogState!,
                        actief: x,
                      })
                    }
                  />
                  <span className="ml-2">Actief</span>
                </div>
              </div>
            );
          }}
          open
          onSuccess={async (result) => {
            await api.v2.verkoop.toevoegenVerkoopopdrachten({
              relID: contractenContext.relID,
              verkModIDs: [result.entiteit.VerkModID],
              aantal: 1,
              mutatiebron: 1,
            });
            ophalenProducten();
            setUrlStateSync('accessoireToevoegenDialoogState', null);
          }}
          onAnnuleren={() => setUrlStateSync('accessoireToevoegenDialoogState', null)}
        />
      )}
    </>
  );
});

export default withRouter(Producten);
