import api from '../../../../index';
import ICheckData from '../../../../../../../shared/src/models/ICheckData';
import {
  IOphalenDienstAfwezighedenParams,
  IOphalenDienstAfwezighedenResult,
  IToevoegenDienstAfwezigheidParams,
  IToevoegenDienstAfwezigheidResult,
  IWijzigenDienstAfwezigheidParams,
  IWijzigenDienstAfwezigheidResult,
  IVerwijderenDienstAfwezighedenParams,
  IVerwijderenDienstAfwezigheidResult,
} from '../../../../../../../shared/src/api/v2/dienst/service/afwezigheid';
const afwezigheid = {
  ophalenDienstAfwezigheden: async (
    params: IOphalenDienstAfwezighedenParams,
  ): Promise<IOphalenDienstAfwezighedenResult> => {
    return await api.post('v2/dienst/service/afwezigheid/ophalen-dienst-afwezigheid', params);
  },

  checkToevoegenDienstAfwezigheid: async (
    params: IToevoegenDienstAfwezigheidParams,
  ): Promise<ICheckData> => {
    return await api.post(
      'v2/dienst/service/afwezigheid/check-toevoegen-dienst-afwezigheid',
      params,
    );
  },
  toevoegenDienstAfwezigheid: async (
    params: IToevoegenDienstAfwezigheidParams,
  ): Promise<IToevoegenDienstAfwezigheidResult> => {
    return await api.post('v2/dienst/service/afwezigheid/toevoegen-dienst-afwezigheid', params);
  },

  checkWijzigenDienstAfwezigheid: async (
    params: IWijzigenDienstAfwezigheidParams,
  ): Promise<ICheckData> => {
    return await api.post(
      'v2/dienst/service/afwezigheid/check-wijzigen-dienst-afwezigheid',
      params,
    );
  },
  wijzigenDienstAfwezigheid: async (
    params: IWijzigenDienstAfwezigheidParams,
  ): Promise<IWijzigenDienstAfwezigheidResult> => {
    return await api.post('v2/dienst/service/afwezigheid/wijzigen-dienst-afwezigheid', params);
  },

  verwijderenDienstAfwezigheid: async (
    params: IVerwijderenDienstAfwezighedenParams,
  ): Promise<IVerwijderenDienstAfwezigheidResult> => {
    return await api.post('v2/dienst/service/afwezigheid/verwijderen-dienst-afwezigheid', params);
  },
};

export default afwezigheid;
