import React, { useMemo } from 'react';
import { IWeergaveProps } from '../../../../../../components/FilterBalkV2';
import ProducttypeSelectie from '../../../../../../components/formulier/ProducttypeSelectie';
import { IOphalenProducttypenResult } from '../../../../../../../../shared/src/api/v2/product/type';
import api from '../../../../../../api';
import IRemoteData, { ERemoteDataState } from '../../../../../../models/IRemoteData';
import InactiefOverlay from '../../../../../../components/InactiefOverlay';

interface IProps extends IWeergaveProps<any> {
  typeIDs: IRemoteData<number[]>;
}

const ProducttypeFilter = (props: IProps) => {
  const resolve = useMemo<() => Promise<IOphalenProducttypenResult>>(
    () =>
      props.typeIDs.state === ERemoteDataState.Pending
        ? async () => {
            return {
              producttypen: [],
            };
          }
        : async () => {
            return await api.v2.product.type.ophalenProducttypen({
              filterSchema: {
                filters: [
                  {
                    naam: 'IDS',
                    data: props.typeIDs.data,
                  },
                ],
              },
            });
          },
    [props.typeIDs],
  );

  return (
    <div className="d-flex align-items-center">
      <span className="mr-2">Producttype</span>
      <InactiefOverlay
        isInactief={props.typeIDs.state === ERemoteDataState.Pending}
        element={
          <ProducttypeSelectie
            typeID={props.data.length === 0 ? null : props.data[0]}
            onChange={(x) => {
              const newData = x === null ? [] : [x];

              props.onDataChange(newData);
              props.setIsActief(true);
              props.toepassen();
            }}
            resolve={resolve}
          />
        }
      />
    </div>
  );
};

export default ProducttypeFilter;
