import {
  IOphalenVoetnotenParams,
  IOphalenVoetnotenResult,
  IOphalenVergelijkingspuntXVoetnotenParams,
  IOphalenVergelijkingspuntXVoetnotenResult,
  IToevoegenVoetnootParams,
  IToevoegenVoetnootResult,
  IWijzigenVoetnootParams,
  IWijzigenVoetnootResult,
  IVerwijderenVoetnootParams,
  IVerwijderenVoetnootResult,
  IOphalenVergelijkingspuntVoetnootXConcurrentiesParams,
  IOphalenVergelijkingspuntVoetnootXConcurrentiesResult,
} from '../../../../../../../shared/src/api/v2/concurrent/vergelijkingspunt/voetnoot';
import api from '../../../../index';
import ICheckData from '../../../../../../../shared/src/models/ICheckData';
import {
  IOphalenVergelijkingspuntXConcurrentiesParams,
  IOphalenVergelijkingspuntXConcurrentiesResult,
} from '../../../../../../../shared/src/api/v2/concurrent/vergelijkingspunt';

const voetnoot = {
  ophalenVoetnoten: async (params: IOphalenVoetnotenParams): Promise<IOphalenVoetnotenResult> => {
    return await api.post('/v2/concurrentie/vergelijkingspunt/voetnoot/ophalen-voetnoten', params);
  },
  checkToevoegenVoetnoot: async (params: IToevoegenVoetnootParams): Promise<ICheckData> => {
    return await api.post(
      '/v2/concurrentie/vergelijkingspunt/voetnoot/check-toevoegen-voetnoot',
      params,
    );
  },
  toevoegenVoetnoot: async (
    params: IToevoegenVoetnootParams,
  ): Promise<IToevoegenVoetnootResult> => {
    return await api.post('/v2/concurrentie/vergelijkingspunt/voetnoot/toevoegen-voetnoot', params);
  },
  checkWijzigenVoetnoot: async (params: IWijzigenVoetnootParams): Promise<ICheckData> => {
    return await api.post(
      '/v2/concurrentie/vergelijkingspunt/voetnoot/check-wijzigen-voetnoot',
      params,
    );
  },
  wijzigenVoetnoot: async (params: IWijzigenVoetnootParams): Promise<IWijzigenVoetnootResult> => {
    return await api.post('/v2/concurrentie/vergelijkingspunt/voetnoot/wijzigen-voetnoot', params);
  },
  checkVerwijderenVoetnoot: async (params: IVerwijderenVoetnootParams): Promise<ICheckData> => {
    return await api.post(
      '/v2/concurrentie/vergelijkingspunt/voetnoot/check-verwijderen-voetnoot',
      params,
    );
  },
  verwijderenVoetnoot: async (
    params: IVerwijderenVoetnootParams,
  ): Promise<IVerwijderenVoetnootResult> => {
    return await api.post(
      '/v2/concurrentie/vergelijkingspunt/voetnoot/verwijderen-voetnoot',
      params,
    );
  },
  ophalenVergelijkingspuntXVoetnoten: async (
    params: IOphalenVergelijkingspuntXVoetnotenParams,
  ): Promise<IOphalenVergelijkingspuntXVoetnotenResult> => {
    return await api.post(
      '/v2/concurrentie/vergelijkingspunt/voetnoot/ophalen-vergelijkingspunt-x-voetnoten',
      params,
    );
  },
  ophalenVergelijkingspuntVoetnootXConcurrenties: async (
    params: IOphalenVergelijkingspuntVoetnootXConcurrentiesParams,
  ): Promise<IOphalenVergelijkingspuntVoetnootXConcurrentiesResult> => {
    return await api.post(
      '/v2/concurrentie/vergelijkingspunt/voetnoot/ophalen-vergelijkingspunt-voetnoot-x-concurrenties',
      params,
    );
  },
};

export default voetnoot;
