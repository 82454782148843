import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Kleur } from '../../../bedrijfslogica/constanten';
import { IconZoeken } from '../../Icons';
import { IOphalenGrootboekenResultElement } from '../../../../../shared/src/api/v2/boekhouding/boeking/grootboek';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../models/IRemoteData';
import GrootboekSelectieDialoog from './GrootboekSelectieDialoog';
import TekstSuggestieVeld, { IInputComponentProps } from '../TekstSuggestieVeld';
import Skeleton from 'react-loading-skeleton';

const InputComponent: React.ForwardRefRenderFunction<any, IInputComponentProps> = (props, ref) => {
  return (
    <input
      ref={ref}
      {...props}
      className="form-control"
      onChange={(ev) => {
        const value = ev.target.value;
        if (value.length > 4) {
          return;
        }
        const num = Number(value);
        if (isNaN(num) || num < 0) {
          return;
        }
        props.onChange?.(ev);
      }}
    />
  );
};

interface ISelectieDialoogState {}

interface IProps {
  grootboekID: number | null;
  onChange: (grootboekID: number | null) => void;
  grootboekenProvider: () => Promise<IOphalenGrootboekenResultElement[]>;
  disabled?: boolean;
  autoFocus?: boolean;
  relID?: number;
}

const GrootboekSelectie = (props: IProps) => {
  const [tekst, setTekst] = useState<string | null>(null);
  const grootboekIDChangeRef = useRef<number | null>(props.grootboekID);
  const [grootboeken, setGrootboeken] = useState<IRemoteData<IOphalenGrootboekenResultElement[]>>(
    createPendingRemoteData(),
  );

  useEffect(() => {
    props.grootboekenProvider().then((grootboeken) => {
      setGrootboeken(createReadyRemoteData(grootboeken));

      if (props.grootboekID === null) {
        setTekst('');
        return;
      }
      const grootboek = grootboeken.find((x) => x.ID === props.grootboekID)!;
      const t = String(grootboek.Nummer);
      if (tekst !== t) {
        setTekst(t);
      }
    });
  }, [props.grootboekenProvider]);

  useEffect(() => {
    if (grootboeken.state === ERemoteDataState.Pending) {
      return;
    }
    if (props.grootboekID === grootboekIDChangeRef.current) {
      return;
    }
    if (props.grootboekID === null) {
      setTekst('');
      return;
    }
    const grootboek = grootboeken.data!.find((x) => x.ID === props.grootboekID);
    if (grootboek === undefined) {
      throw new Error(`In principe on onmogelijke situatie`);
    }
    const t = String(grootboek.Nummer);
    setTekst(t);
  }, [grootboeken, props.grootboekID]);

  useEffect(() => {
    if (grootboeken.state === ERemoteDataState.Pending || tekst === null) {
      return;
    }
    const grootboek = grootboeken.data!.find((x) => x.Nummer === Number(tekst));
    const grootboekID = grootboek?.ID ?? null;

    if (props.grootboekID !== grootboekID) {
      grootboekIDChangeRef.current = grootboekID;
      props.onChange(grootboekID);
    }
  }, [tekst, grootboeken, props.onChange, props.grootboekID]);

  const [selectieDialoogState, setSelectieDialoogState] = useState<ISelectieDialoogState | null>(
    null,
  );

  const suggestiesResolver = useCallback(
    async (waarde: string): Promise<string[] | null> => {
      if (grootboeken.state === ERemoteDataState.Pending) {
        return null;
      }
      return grootboeken.data!.map((x) => String(x.Nummer)).filter((x) => x.includes(waarde));
    },
    [grootboeken],
  );

  if (grootboeken.state === ERemoteDataState.Pending) {
    return null;
  }

  return (
    <>
      <div className="d-flex">
        {tekst === null ? (
          <Skeleton />
        ) : (
          <TekstSuggestieVeld
            waarde={tekst}
            onChange={setTekst}
            suggestiesResolver={suggestiesResolver}
            inputComponent={InputComponent}
            suggestiesResolveDebounceTimeoutMs={1}
            autoFocus={props.autoFocus}
          />
        )}
        {/*<MultiCombobox<number, IOphalenGrootboekenResultElement>*/}
        {/*  sleutelExtractor={(grootboek) => grootboek.ID}*/}
        {/*  representatieFabriek={(grootboek) => grootboek.Nummer}*/}
        {/*  waarde={props.grootboekID}*/}
        {/*  onWaardeChange={props.onChange}*/}
        {/*  opties={grootboeken.data!}*/}
        {/*  kolommen={[*/}
        {/*    {*/}
        {/*      key: 'Nummer',*/}
        {/*      label: 'Nummer',*/}
        {/*      breedte: 80,*/}
        {/*    },*/}
        {/*    {*/}
        {/*      key: 'Naam',*/}
        {/*      label: 'Naam',*/}
        {/*      breedte: 250,*/}
        {/*    },*/}
        {/*  ]}*/}
        {/*  options={{*/}
        {/*    geenWaardeBericht: 'Kies',*/}
        {/*  }}*/}
        {/*  disabled={props.disabled}*/}
        {/*/>*/}
        <button
          style={{
            // border: 0,
            background: 0,
            outline: 0,
            border: `1px solid ${Kleur.LichtGrijs}`,
            borderTopRightRadius: 5,
            borderBottomRightRadius: 5,
            borderLeft: 0,
            width: 25,
            backgroundColor: props.disabled ? 'rgb(197, 197, 197)' : Kleur.HeelLichtGrijs,
          }}
          className="flex-fill d-flex align-items-center justify-content-center"
          onClick={() => setSelectieDialoogState({})}
        >
          <IconZoeken
            style={{
              fill: Kleur.Grijs,
              width: 16,
              height: 16,
              position: 'relative',
              top: 1,
            }}
            className="flex-fill"
          />
        </button>
      </div>
      {selectieDialoogState !== null && (
        <GrootboekSelectieDialoog
          open
          onSuccess={(output) => {
            setSelectieDialoogState(null);
            props.onChange(output.grootboekID);
          }}
          onAnnuleren={() => setSelectieDialoogState(null)}
          relID={props.relID}
        />
      )}
    </>
  );
};

export default GrootboekSelectie;
