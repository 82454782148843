import { makeObservable, observable } from 'mobx';
import * as _ from 'lodash';
import api from '../api';
import { IOphalenOpdrachtregelsResultElementV2 } from '../../../shared/src/api/v2/transport/opdracht';

class TransportopdrachtregelStore {
  public transportopdrachtregels: Record<number, IOphalenOpdrachtregelsResultElementV2> = {};
  // tslint:disable-next-line:variable-name
  private _trsOpdRegIDsQueue: number[] = [];
  // tslint:disable-next-line:variable-name
  private _trsOpdRegIdsTimeout: number | null = null;

  constructor() {
    makeObservable(this, {
      transportopdrachtregels: observable,
    });
  }

  public enqueueTransportopdrachtregels = (trsOpdRegIDs: number[]) => {
    if (trsOpdRegIDs.length === 0) {
      return;
    }

    this._trsOpdRegIDsQueue = _.uniq([...this._trsOpdRegIDsQueue, ...trsOpdRegIDs]);
    if (this._trsOpdRegIdsTimeout !== null) {
      clearTimeout(this._trsOpdRegIdsTimeout);
    }
    this._trsOpdRegIdsTimeout = setTimeout(this.ladenTransportopdrachregels, 100) as any;
  };

  private ladenTransportopdrachregels = async () => {
    const trsOpdRegIDs: number[] = [...this._trsOpdRegIDsQueue];
    this._trsOpdRegIDsQueue = [];

    const result = await api.v2.transport.opdracht.ophalenOpdrachtregelsV2({
      filterSchema: {
        filters: [{ naam: 'IDS', data: trsOpdRegIDs }],
      },
    });
    this.transportopdrachtregels = result.regels.reduce((acc, curr) => {
      return {
        ...acc,
        [curr.TrsRegID]: curr,
      };
    }, this.transportopdrachtregels);
  };
}

export default TransportopdrachtregelStore;
