import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import IDialoogProps from '../../../../../core/IDialoogProps';
import Dialoog from '../../../../../components/dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import * as Yup from 'yup';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../../../stores/RootStore';
import nameof from '../../../../../core/nameOf';
import api from '../../../../../api';
import LoadingSpinner from '../../../../../components/Gedeeld/LoadingSpinner';
import { EResultType } from '../../../../../stores/CheckStore';
import { IOphalenAfbeeldingenResultElement } from '../../../../../../../shared/src/api/v2/website/afbeelding';
import ITaalTekst from '../../../../../../../shared/src/models/talen/ITaalTekst';
import MeertaligTekstveld from '../../../../../components/formulier/MeertaligTekstveld';
import {
  IOphalenFaqResultElement,
  IWijzigenFaqParams,
} from '../../../../../../../shared/src/api/v2/faq';
import {
  IOphalenTekstenInAlleTalenResult,
  IOphalenTekstenResultElement,
} from '../../../../../../../shared/src/api/v2/tekst';
import IRemoteData, {
  ERemoteDataState,
  createPendingRemoteData,
  createReadyRemoteData,
} from '../../../../../models/IRemoteData';
import { IOphalenFaqGroepResultElement } from '../../../../../../../shared/src/api/v2/faqGroep';
import MultiSelect from '../../../../../components/formulier/MultiSelect';
import { IOphalenProductsoortenResultElement } from '../../../../../../../shared/src/api/v2/product/soort';
import Productsoorten from '../../../../Aanbod/Producten/Productsoorten';

interface IDialoogResult {}

interface IProps extends IDialoogProps<IDialoogResult> {
  id: number;
}

interface IFormikValues {
  ID: number;
  vraag: ITaalTekst[];
  antwoord: ITaalTekst[];
  productSoorten: number[];
}

const veldnamen: Record<keyof IFormikValues, string> = {
  ID: 'ID',
  vraag: 'Vraag',
  antwoord: 'Antwoord',
  productSoorten: 'ProductSoorten',
};

const WijzigenDialoog: React.FC<IProps> = observer((props) => {
  const { children, dialoogIndex, onAnnuleren, onSuccess } = props;
  const { checkStore } = useContext(RootStoreContext);
  const formikRef = useRef<Formik<IFormikValues>>(null);

  const [faq, setFaq] = useState<IOphalenFaqResultElement | null>(null);

  const [teksten, setTeksten] = useState<IRemoteData<IOphalenTekstenInAlleTalenResult>>(
    createPendingRemoteData(),
  );
  const [faqGroepen, setFaqGroepen] = useState<IOphalenFaqGroepResultElement[] | null>(null);
  const [groepTeksten, setGroepTeksten] = useState<IOphalenTekstenResultElement[] | null>(null);
  const [productSoorten, setProductSoorten] = useState<
    IOphalenProductsoortenResultElement[] | null
  >(null);

  const ophalenfaq = useCallback(async () => {
    const result = await api.v2.faq.ophalenFaq({
      filterSchema: { filters: [{ naam: 'IDS', data: [props.id] }] },
    });
    console.log(result.faq[0]);

    setFaq(result.faq[0]);
  }, [props.id]);

  useEffect(() => {
    ophalenfaq();
  }, [ophalenfaq]);

  const ophalenTeksten = useCallback(async () => {
    if (faq === null) {
      if (teksten.state === ERemoteDataState.Ready) {
        setTeksten(createPendingRemoteData());
      }
      return;
    }

    const tekstIDs = [faq.Antwoord_TekstID, faq.Vraag_TekstID].filter(
      (x) => x !== null,
    ) as number[];

    const result = await api.v2.tekst.ophalenTekstenInAlleTalen({
      tekstIDs,
    });

    setTeksten(createReadyRemoteData(result));
  }, [faq, teksten.state]);

  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    ophalenTeksten();
  }, [ophalenTeksten]);

  const ophalenfaqGroepen = useCallback(async () => {
    const result = await api.v2.faqGroep.ophalenFaqGroepen({});
    console.log(result.faqGroepen[0]);

    setFaqGroepen(result.faqGroepen);
  }, []);

  useEffect(() => {
    ophalenfaqGroepen();
  }, []);

  const ophalenGroepTeksten = useCallback(async () => {
    if (faqGroepen === null) {
      return;
    }
    console.log('ophalen-tekst');
    const tekstIDs = [...faqGroepen.map((x) => x.Naam_TekstID).filter((x) => x !== null)];

    const resultTeksten = await api.v2.tekst.ophalenTekstenInAlleTalen({
      tekstIDs,
    });

    setGroepTeksten(resultTeksten.teksten);
  }, [faqGroepen]);

  useEffect(() => {
    ophalenGroepTeksten();
  }, [ophalenGroepTeksten]);

  const ophalenGroepOpties = useCallback(() => {
    if (groepTeksten === null || faqGroepen == null) return [];
    const result = faqGroepen.map((groep) => {
      const naamTekst = groepTeksten.find((x) => x.TekstID === groep.Naam_TekstID);
      return {
        key: groep.ID,
        weergave: naamTekst == null || naamTekst.Tekst == null ? '' : naamTekst.Tekst,
      };
    });
    return result;
  }, [groepTeksten]);

  const ophalenProductSoorten = useCallback(async () => {
    const results = await api.v2.productsoort.ophalenProductsoorten({});
    setProductSoorten(results);
  }, []);

  useEffect(() => {
    ophalenProductSoorten();
  }, [ophalenProductSoorten]);

  const ophalenProductSoortOpties = useCallback(() => {
    if (productSoorten === null) return [];
    const result =
      productSoorten == null
        ? []
        : productSoorten
            .map((soort) => {
              return {
                key: soort.ProdSrtID,
                weergave: soort == null || soort.NaamEnum == null ? '' : soort.NaamEnum,
              };
            })
            .filter((optie) => optie.weergave != '');
    return result;
  }, [groepTeksten, productSoorten]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      if (faq === null) {
        return;
      }

      actions.setSubmitting(true);

      const params: IWijzigenFaqParams = {
        ID: faq.ID,
        Vraag: {
          tekstID: faq.Vraag_TekstID,
          taalTeksten: values.vraag,
        },
        Antwoord: {
          tekstID: faq.Antwoord_TekstID,
          taalTeksten: values.antwoord,
        },
        ProdSrt_IDs: values.productSoorten,
      };

      const checkData = await api.v2.faq.checkWijzigenFaq(params);
      const checkResult = await checkStore.controleren({
        checkData,
      });
      if (checkResult.type === EResultType.Annuleren) {
        onAnnuleren();
        return;
      }

      await api.v2.faq.wijzigenFaq(params);

      actions.setSubmitting(false);
      props.onSuccess({});
    },
    [faq],
  );

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (faq === null || teksten.state === ERemoteDataState.Pending) {
      return null;
    }
    const vraagTeksten =
      faq.Vraag_TekstID === null
        ? null
        : teksten.data!.teksten.filter((x) => x.TekstID === faq.Vraag_TekstID);

    const antwoordTeksten =
      faq.Antwoord_TekstID === null
        ? null
        : teksten.data!.teksten.filter((x) => x.TekstID === faq.Antwoord_TekstID);

    return {
      ID: faq.ID,
      vraag:
        vraagTeksten === null
          ? []
          : vraagTeksten.map((x) => ({
              tekst: x.Tekst ?? '',
              taalID: x.TaalID,
              toepassen: x.Toepassen,
            })),
      antwoord:
        antwoordTeksten === null
          ? []
          : antwoordTeksten.map((x) => ({
              tekst: x.Tekst ?? '',
              taalID: x.TaalID,
              toepassen: x.Toepassen,
            })),
      groepen: faq.Groep_IDs,
      productSoorten: faq.ProdSrt_IDs,
    };
  }, [faq, teksten]);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        ID: Yup.string().required(),
      }),
    [],
  );

  return (
    <Dialoog index={dialoogIndex || 0} modalProps={{ size: 'lg' }}>
      <ModalHeader>
        <ModalTitle>Wijzigen Faq Vraag / Antwoord</ModalTitle>
      </ModalHeader>
      {initialValues === null ? (
        <LoadingSpinner />
      ) : (
        <Formik<IFormikValues>
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize
          isInitialValid
          render={(formikProps: FormikProps<IFormikValues>) => {
            const { submitForm, isValid, isSubmitting } = formikProps;

            return (
              <>
                <ModalBody>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-12 mt-3">
                        <label>Vraag</label>
                        <Field
                          name={nameof<IFormikValues>('vraag')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            return (
                              <MeertaligTekstveld
                                waarden={fieldProps.field.value}
                                onChange={(x) =>
                                  fieldProps.form.setFieldValue(fieldProps.field.name, x)
                                }
                                defaultUitgeklapt
                                inputComponent={'multiline'}
                                multilineConfig={{ rows: 3 }}
                              />
                            );
                          }}
                        />
                      </div>
                      <div className="col-12 mt-3">
                        <label>Antwoord</label>
                        <Field
                          name={nameof<IFormikValues>('antwoord')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            return (
                              <MeertaligTekstveld
                                waarden={
                                  fieldProps.field.value != null ? fieldProps.field.value : []
                                }
                                onChange={(x) =>
                                  fieldProps.form.setFieldValue(fieldProps.field.name, x)
                                }
                                defaultUitgeklapt
                                inputComponent={'multiline'}
                                multilineConfig={{ rows: 3 }}
                              />
                            );
                          }}
                        />
                      </div>
                      <div className="col-12 mt-3">
                        <label>Gekoppelde Productsoorten</label>
                        <Field
                          name={nameof<IFormikValues>('productSoorten')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            return (
                              <MultiSelect
                                value={fieldProps.field.value}
                                onChange={(x) => {
                                  fieldProps.form.setFieldValue(fieldProps.field.name, x);
                                }}
                                isMulti
                                isVerwijderbaar={false}
                                opties={ophalenProductSoortOpties()}
                              />
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </ModalBody>

                <ModalFooter className="d-flex flex-row justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    style={{ width: 100 }}
                    disabled={!isValid || isSubmitting}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={onAnnuleren}
                    style={{ width: 100 }}
                    disabled={isSubmitting}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Dialoog>
  );
});

export default WijzigenDialoog;
