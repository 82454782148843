import {
  IToevoegenWinkelwagenParams,
  IToevoegenWinkelwagenResult,
  IVerwijderenWinkelwagensParams,
  IVerwijderenWinkelwagensResult,
  IVerwerkenWinkelwagensParams,
  IVerwerkenWinkelwagensResult,
  IOphalenWinkelwagensParams,
  IOphalenWinkelwagensResult,
  IMakenTransportopdrachtenVoorWinkelwagensParams,
  IMakenTransportopdrachtenVoorWinkelwagensResult,
} from '../../../../../shared/src/api/v2/winkelwagen';
import ICheckData from '../../../../../shared/src/models/ICheckData';
import api from '../../index';

const winkelwagen = {
  checkToevoegenWinkelwagen: async (params: IToevoegenWinkelwagenParams): Promise<ICheckData> => {
    return await api.post('/v2/winkelwagen/check-toevoegen-winkelwagen', params);
  },
  toevoegenWinkelwagen: async (
    params: IToevoegenWinkelwagenParams,
  ): Promise<IToevoegenWinkelwagenResult> => {
    return await api.post('/v2/winkelwagen/toevoegen-winkelwagen', params);
  },
  checkVerwijderenWinkelwagen: async (
    params: IVerwijderenWinkelwagensParams,
  ): Promise<ICheckData> => {
    return await api.post('/v2/winkelwagen/check-verwijderen-winkelwagens', params);
  },
  verwijderenWinkelwagen: async (
    params: IVerwijderenWinkelwagensParams,
  ): Promise<IVerwijderenWinkelwagensResult> => {
    return await api.post('/v2/winkelwagen/verwijderen-winkelwagens', params);
  },
  verwerkWinkelwagens: async (
    params: IVerwerkenWinkelwagensParams,
  ): Promise<IVerwerkenWinkelwagensResult> => {
    return await api.post('/v2/winkelwagen/verwerken-winkelwagens', params);
  },
  ophalenWinkelwagens: async (
    params: IOphalenWinkelwagensParams,
  ): Promise<IOphalenWinkelwagensResult> => {
    return await api.post('/v2/winkelwagen/ophalen-winkelwagens', params);
  },
  makenTransportopdrachtenVoorWinkelwagens: async (
    params: IMakenTransportopdrachtenVoorWinkelwagensParams,
  ): Promise<IMakenTransportopdrachtenVoorWinkelwagensResult> => {
    return await api.post('/v2/winkelwagen/maken-transportopdrachten-voor-winkelwagens', params);
  },
};

export default winkelwagen;
