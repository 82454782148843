import React, { useMemo } from 'react';
import { IActieProps } from '../index';
import { ISmsContactPersoonConflictEventData } from '../../../../../shared/src/api/v2/event';
import PersoonVisualisatie from '../../../components/personalia/PersoonVisualisatie';
import { setCommunicatieOverlayState } from '../../../one-off-components/CommunicatieOverlay';
import { ECommunicatieTabblad } from '../../../one-off-components/CommunicatieOverlay/MenuHandle';
import api from '../../../api';

const SMS_CONTACT_PERSOON_CONFLICT: React.VoidFunctionComponent<IActieProps> = (
  props: IActieProps,
) => {
  const data = useMemo<ISmsContactPersoonConflictEventData>(
    () => JSON.parse(props.controltaak.Data!),
    [props.controltaak.Data],
  );

  return (
    <span>
      <PersoonVisualisatie persID={data.metHetzelfdeTelefoonnummer_PersID} />
      &nbsp;
      <span>heeft hetzelfde telefoonnummer als dit</span>
      &nbsp;
      <a
        href="#"
        onClick={async (ev) => {
          ev.preventDefault();

          const contactenResult = await api.v2.dienst.sms.ophalenContactenV2({
            filterSchema: {
              filters: [
                {
                  naam: 'IDS',
                  data: [data.gekoppeldIsAanEenAnderPersoon_ContactID],
                },
              ],
            },
          });
          const [contact] = contactenResult.contacten;

          setCommunicatieOverlayState!((prev) => ({
            ...prev,
            geselecteerdTabblad: ECommunicatieTabblad.Sms,
            smsContext: {
              ...prev.smsContext,
              telefoonnummer: contact.Telefoonnummer,
            },
          }));
        }}
      >
        sms-contact
      </a>
    </span>
  );
};

export default SMS_CONTACT_PERSOON_CONFLICT;
