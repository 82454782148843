import React, { useCallback, useEffect, useMemo, useState, useContext } from 'react';
import useUrlState from '../../../../core/useUrlState';
import {
  DataTypeProvider,
  EditingState,
  IntegratedSorting,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  DXCommandComponent,
  DXTableCheckboxComponent,
  DXTableToggleCellComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import {
  IOphalenOpdrachtenResultElement,
  IProduct,
} from '../../../../../../shared/src/api/v2/pendel';
import { format } from 'date-fns';
import api from '../../../../api';
import { maandenNaarJaren } from '../../../../bedrijfslogica/teksten';
import WijzigenProductDialoog from '../../Depot/WijzigenProductDialoog';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import { Kleur as EKleur } from '../../../../bedrijfslogica/constanten';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { RootStoreContext } from '../../../../stores/RootStore';
import { EResultType } from '../../../../stores/CheckStore';
import {
  EFunctioneleIcon,
  functioneleIconMap,
  IconInformatie,
  IconVerwijderen,
  IconVlag,
} from '../../../../components/Icons';
import IndentatieVlak from '../../../../components/IndentatieVlak';
import { PendelsContext } from '../index';
import SelectieVak from '../../../../components/SelectieVak';
import ProductinfoDialoog from '../../../../components/product/ProductinfoDialoog';
import UitlegTooltip from '../../../../components/formulier/UitlegTooltip';
import BijlageKnop, { EBronModus, ESelectieModus } from '../../../../components/BijlageKnop';
import BijlagenContainer, { BestandType } from '../../../../components/BijlagenContainer';
import useUpload from '../../../../core/useUpload';

interface IProps extends TableRowDetail.ContentProps, RouteComponentProps {}

interface IUrlState {
  selectie: number[];
}

const defaultUrlState: IUrlState = {
  selectie: [],
};

interface IRegel extends IProduct {}

export interface IProductinfoDialoogState {
  id: number;
}

const IconAfhaalopdracht = functioneleIconMap[EFunctioneleIcon.AfhaalOpdracht];

const OpdrachtDetailComp: React.FC<IProps> = (props) => {
  const row = props.row as IOphalenOpdrachtenResultElement;

  // const { onVerversenAangevraagd, afhaalopdrachten } = useContext(PendelsContext)!;
  const { onVerversenAangevraagd } = useContext(PendelsContext)!;

  const stateKey = useMemo(() => `detailState-${row.ID}`, [row.ID]);

  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState, stateKey);
  const { checkStore, instellingStore } = useContext(RootStoreContext);

  const [wijzigenProdID, setWijzigenProdID] = useState<number | null>(null);
  const [
    productinfoDialoogState,
    setProductinfoDialoogState,
  ] = useState<IProductinfoDialoogState | null>(null);

  const productregels = useMemo<IRegel[]>(() => {
    return row.regels.map((regel) => {
      return {
        ...regel,
      };
    });
  }, [row.regels]);

  const handleVerwijderen = useCallback(async () => {
    const params = { IDs: urlState.selectie };
    const checkData = await api.v2.pendel.checkVerwijderenRegels(params);
    if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
      return;
    }

    if (
      (
        await checkStore.bevestigen({
          inhoud: `Verwijderen regels?`,
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    await api.v2.pendel.verwijderenRegels(params);
    setUrlStateSync('selectie', []);
    onVerversenAangevraagd();
  }, [urlState.selectie, onVerversenAangevraagd]);

  const handleAfmelden = useCallback(async () => {
    const params = { penOpdID: props.row.ID, ids: urlState.selectie };

    const checkData = await api.v2.pendel.checkAfmeldenRegels(params);
    if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
      return;
    }

    if (
      (
        await checkStore.bevestigen({
          inhoud: `Regels afmelden?`,
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    await api.v2.pendel.afmeldenRegels(params);
    // setUrlStateSync('selectie', []);
    onVerversenAangevraagd();
  }, [urlState.selectie, onVerversenAangevraagd]);

  const handleHerstellenAfmelden = useCallback(async () => {
    const params = { penOpdID: props.row.ID, ids: urlState.selectie };

    const checkData = await api.v2.pendel.checkHerstellenAfmeldenRegels(params);
    if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
      return;
    }

    if (
      (
        await checkStore.bevestigen({
          inhoud: `Het afmelden voor de geselecteerde regels herstellen?`,
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    await api.v2.pendel.herstellenAfmeldenRegels(params);
    // setUrlStateSync('selectie', []);
    onVerversenAangevraagd();
  }, [urlState.selectie, onVerversenAangevraagd]);

  const handleAfhaalopdrachtenGenereren = useCallback(
    async (prodIDs, magID: number, penDienstID: number, afhaaldatum: Date | string) => {
      if (
        (
          await checkStore.bevestigen({
            inhoud: `Afhaalopdrachten genereren voor de geselecteerde producten?`,
          })
        ).type === EResultType.Annuleren
      ) {
        return;
      }

      const params = {
        prodIDs,
        magID,
        penDienstID,
        afhaaldatum,
      };

      await api.v2.magazijn.afhaal.genererenAfhaalOpdrachten(params);

      // setUrlStateSync('selectie', []);

      await onVerversenAangevraagd();
    },
    [urlState.selectie, onVerversenAangevraagd],
  );

  const keyExtractor = useCallback((row: IRegel) => row.ID, []);

  const kolommen = useMemo<TypedColumn<IRegel>[]>(
    () => [
      {
        name: '__merknaam' as any,
        title: 'Merk',
        getCellValue: (x) => x.product.producttype.Merknaam,
      },
      {
        name: '__typenaam' as any,
        title: 'Type',
        getCellValue: (x) => x.product.producttype.Typenaam,
      },
      {
        name: '__productsoortnaamKort' as any,
        title: 'Cat.',
        getCellValue: (x) => x.product.producttype.ProductsoortnaamKort,
      },
      {
        name: '__referentiecode' as any,
        title: 'Ref.code',
        getCellValue: (x) => x.product.Referentiecode,
      },
      {
        name: '__nieuw' as any,
        title: (
          <UitlegTooltip inhoud={'N(ieuw) of G(ebruikt) product'}>
            <span>N/G</span>
          </UitlegTooltip>
        ) as any,
      },
      {
        name: '__product' as any,
        title: ' ',
      },
      // {
      //   name: '__leeftijd' as any,
      //   title: 'Lft.',
      // },
      {
        name: '__magazijnLocatie' as any,
        title: 'Huidige loc.',
      },
      {
        name: '__productstatus' as any,
        title: 'Prod.status',
      },
      {
        name: '__retourstatus' as any,
        title: (
          <UitlegTooltip inhoud={'Retourstatus van het retour te halen product'}>
            <span>Doel</span>
          </UitlegTooltip>
        ) as any,
        getCellValue: (x) => {
          return x.product.retourstatus !== null ? x.product.retourstatus.Naam : '';
        },
      },
      {
        name: 'Uitgevoerd',
        title: 'Uitgevoerd',
      },
      // {
      //   name: '__inAfhaalopdracht' as any,
      //   title: 'In afh.opd.',
      //   getCellValue: (x) => {
      //     const afhaalopdracht = afhaalopdrachten!.find(
      //       (p) => p.product.ProdID === x.product.ProdID,
      //     );
      //     return afhaalopdracht !== undefined ? 1 : 0;
      //   },
      // },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRegel>[]>(
    () => [
      {
        columnName: '__merknaam' as any,
        width: 100,
      },
      {
        columnName: '__typenaam' as any,
        width: 175,
      },
      {
        columnName: '__productsoortnaamKort' as any,
        width: 85,
      },
      {
        columnName: '__referentiecode' as any,
        width: 100,
      },
      {
        columnName: '__nieuw' as any,
        width: 50,
      },
      {
        columnName: '__leeftijd' as any,
        width: 85,
      },
      {
        columnName: '__retourstatus' as any,
        width: 125,
      },
      {
        columnName: '__product' as any,
        width: 50,
      },
      {
        columnName: '__magazijnLocatie' as any,
        width: 125,
      },
      {
        columnName: 'Uitgevoerd',
        width: 115,
      },
      {
        columnName: '__productstatus' as any,
        width: 110,
      },
      {
        columnName: '__inAfhaalopdracht' as any,
        width: 110,
      },
    ],
    [],
  );

  const { isBezigMetUploaden, muteerBestanden, uploadProgresses, bestanden } = useUpload(
    useMemo(
      () => ({
        automatischUploaden: true,
        initieleBestanden: row.bestandskoppelingen.map((x) => ({
          type: BestandType.ASPDrive,
          bestand: x.bestand,
        })),
        onAlleBestandenGeuploaded: async (bestandIDs) => {
          await api.v2.pendel.muterenBestandenOpdracht({
            penOpdID: row.ID,
            bestandIDs,
          });
          await onVerversenAangevraagd();
        },
      }),
      [row.bestandskoppelingen, row.ID, onVerversenAangevraagd],
    ),
  );

  return (
    <div className="d-flex">
      <IndentatieVlak width={40} />
      <div className="flex-fill">
        <div>
          <div
            className="d-flex flex-column p-3"
            style={{
              backgroundColor: EKleur.HeelLichtGrijs,
              borderBottom: `1px solid ${EKleur.LichtGrijs}`,
            }}
          >
            <div className="d-flex">
              <button
                className="btn btn-sm btn-light d-flex align-items-center"
                style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                disabled={urlState.selectie.length === 0}
                onClick={handleVerwijderen}
              >
                <IconVerwijderen style={{ width: 16, height: 16, fill: EKleur.Grijs }} />
                <span className="ml-2">Verwijderen</span>
              </button>

              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-3"
                style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                disabled={urlState.selectie.length === 0}
                onClick={async () => {
                  // Vertaal de geselecteerde regels (ID) naar de bijbehorende ProdIDs
                  const regelsGeselecteerd = row.regels.filter(
                    (x) => urlState.selectie.indexOf(x.ID) !== -1,
                  );
                  const prodIDs = regelsGeselecteerd.map((x) => x.product.ProdID);

                  if (
                    (
                      await checkStore.bevestigen({
                        inhoud: 'Geselecteerde producten Zoekmelden?',
                      })
                    ).type === EResultType.Annuleren
                  ) {
                    return;
                  }

                  await api.v2.retourverwerking.zoekMeldenProducten({
                    magID: row.magazijn.laden.MagID,
                    prodIDs,
                  });

                  onVerversenAangevraagd();
                }}
              >
                {/* <IcoonSend style={{ width: 16, height: 16, fill: Kleur.Grijs }} /> */}
                <span className="ml-2">Zoekgeraakt</span>
              </button>

              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-3"
                style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                disabled={urlState.selectie.length === 0}
                onClick={handleAfmelden}
              >
                <IconVlag style={{ width: 16, height: 16, fill: EKleur.Grijs }} />
                <span className="ml-2">Afmelden regels</span>
              </button>

              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-3"
                style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                disabled={urlState.selectie.length === 0}
                onClick={handleHerstellenAfmelden}
              >
                {/* <IconVlag style={{ width: 16, height: 16, fill: Kleur.Grijs }} /> */}
                <span className="ml-2">Herstellen afmelden</span>
              </button>

              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-3"
                style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                disabled={urlState.selectie.length === 0}
                onClick={async () => {
                  if (row.DatumGepland === null) {
                    await checkStore.melden({
                      titel: 'Er dient een datum gepland te zijn opgegeven',
                    });
                    return;
                  }
                  const prodIDs = row.regels
                    .filter((x) => urlState.selectie.indexOf(x.ID) !== -1)
                    .map((x) => x.product.ProdID);

                  handleAfhaalopdrachtenGenereren(
                    prodIDs,
                    row.magazijn.laden.MagID,
                    row.dienst!.ID,
                    row.DatumGepland,
                  );
                }}
              >
                <IconAfhaalopdracht style={{ width: 16, height: 16, fill: EKleur.Grijs }} />
                <span className="ml-2">Afhaalopdrachten bijwerken/genereren</span>
              </button>

              <div className="flex-fill" />
              <BijlageKnop
                selectieModus={ESelectieModus.Meerdere}
                onBijgevoegd={(bijlagen) =>
                  muteerBestanden((bestanden) => [...bestanden, ...bijlagen])
                }
                bronModus={EBronModus.Alle}
                disabled={isBezigMetUploaden}
              />
            </div>

            <div className="mt-2 d-flex align-items-center">
              {row.regels !== null && (
                <>
                  <SelectieVak
                    totaalAantal={row.regels.length}
                    aantal={urlState.selectie.length}
                    entiteitEnkelvoud="opdracht"
                    entiteitMeervoud="opdrachten"
                    onChange={(allesGeselecteerd) => {
                      if (allesGeselecteerd) {
                        setUrlStateSync(
                          'selectie',
                          row.regels!.map((x) => x.ID),
                        );
                      } else {
                        setUrlStateSync('selectie', []);
                      }
                    }}
                  />
                  {/* <span className="ml-2">Totaal {row.regels.length} regels</span> */}
                </>
              )}
            </div>
          </div>
        </div>

        <div>
          {bestanden !== null && bestanden.length > 0 && (
            <div className="p-2">
              <BijlagenContainer
                bestanden={bestanden}
                uploadProgresses={uploadProgresses}
                onBestandenChange={(x) => muteerBestanden((_) => x)}
                bestandenMuterenToegestaan={!isBezigMetUploaden}
              />
            </div>
          )}
        </div>

        <GridStyleWrapper maxHeight={550} rowAmount={productregels.length}>
          <Grid rows={productregels} columns={kolommen} getRowId={keyExtractor}>
            <DataTypeProvider
              for={['__merknaam']}
              formatterComponent={(formatterProps) => {
                const rij: IRegel = formatterProps.row;
                return <span>{rij.product.producttype.Merknaam}</span>;
              }}
            />
            <DataTypeProvider
              for={['__typenaam']}
              formatterComponent={(formatterProps) => {
                const rij: IRegel = formatterProps.row;
                return <span>{rij.product.producttype.Typenaam}</span>;
              }}
            />
            <DataTypeProvider
              for={['__productsoortnaamKort']}
              formatterComponent={(formatterProps) => {
                const rij: IRegel = formatterProps.row;
                return <span>{rij.product.producttype.ProductsoortnaamKort}</span>;
              }}
            />
            <DataTypeProvider
              for={['__leeftijd']}
              formatterComponent={(formatterProps) => {
                const leeftijd =
                  formatterProps.row.leeftijd !== null
                    ? maandenNaarJaren(formatterProps.row.leeftijd)
                    : '';
                return <span>{leeftijd}</span>;
              }}
            />

            <DataTypeProvider
              for={['__magazijnLocatie']}
              formatterComponent={(formatterProps) => {
                const rij: IRegel = formatterProps.row;
                const product = rij.product;

                const locatie =
                  product.DatumUitBedrijf === null
                    ? rij.magazijn !== null
                      ? rij.magazijn.NaamKort
                      : 'Extern'
                    : 'Uit bedrijf';

                return <span>{locatie}</span>;
              }}
            />

            <DataTypeProvider
              for={['__retourstatus']}
              formatterComponent={(formatterProps) => {
                const rij: IRegel = formatterProps.row;
                const doelstatus =
                  rij.product.retourstatus !== null ? rij.product.retourstatus.Naam : '';
                return <span>{doelstatus}</span>;
              }}
            />
            <DataTypeProvider
              for={['__nieuw']}
              formatterComponent={(formatterProps) => {
                const rij: IRegel = formatterProps.row;
                return <span>{rij.product.Nieuw ? 'N' : 'G'}</span>;
              }}
            />
            <DataTypeProvider
              for={['__referentiecode']}
              formatterComponent={(formatterProps) => {
                const rij: IRegel = formatterProps.row;
                return <span>{rij.product.Referentiecode}</span>;
              }}
            />
            <DataTypeProvider
              for={['Uitgevoerd']}
              formatterComponent={(formatterProps) => {
                return formatterProps.value ? (
                  <span style={{ color: EKleur.Groen }}>Ja</span>
                ) : (
                  <span>Nee</span>
                );
              }}
            />

            <DataTypeProvider
              for={['__productstatus']}
              formatterComponent={(formatterProps) => {
                const rij: IRegel = formatterProps.row;
                return (
                  <span>
                    {rij.product.productstatus.Naam}
                    {rij.product.ZoekGemeld !== null ? ' (zoek)' : ''}
                  </span>
                );
              }}
            />

            <DataTypeProvider
              for={['__product']}
              formatterComponent={(formatterProps) => {
                const rij: IRegel = formatterProps.row;

                return (
                  <a
                    href="#"
                    // style={{ color: Kleur.DonkerGrijs }}
                    onClick={() => {
                      setProductinfoDialoogState({ id: rij.product.ProdID });
                    }}
                  >
                    <IconInformatie style={{ width: 15, height: 15, fill: EKleur.Blauw }} />
                  </a>
                );
              }}
            />

            {/* <DataTypeProvider
              for={['__inAfhaalopdracht']}
              formatterComponent={(formatterProps) => {
                const rij: IRegel = formatterProps.row;

                const afhaalopdracht = afhaalopdrachten!.find(
                  (x) => x.product.ProdID === rij.product.ProdID,
                );
                return (
                  <span>
                    {afhaalopdracht !== undefined ? (
                      <IconAfhaalopdracht style={{ width: 15, height: 15, fill: EKleur.Grijs }} />
                    ) : (
                      <span></span>
                    )}
                  </span>
                );
              }}
            /> */}

            <SortingState defaultSorting={[]} />
            <IntegratedSorting />

            <VirtualTable messages={{ noData: 'Geen opdrachtregels voor het ingestelde filter' }} />
            <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
            <TableHeaderRow showSortingControls />

            <EditingState
              onAddedRowsChange={() => {}}
              onEditingRowIdsChange={(x) => {
                const id = x[x.length - 1] as number;
                const rij = productregels.find((y) => keyExtractor(y) === id)!;
                setWijzigenProdID(rij.product.ProdID);
              }}
              onCommitChanges={() => null}
            />
            <TableEditColumn width={35} showEditCommand commandComponent={DXCommandComponent} />

            <SelectionState
              selection={urlState.selectie}
              onSelectionChange={(value) => setUrlStateSync('selectie', value as number[])}
            />
            <TableSelection cellComponent={DXTableCheckboxComponent} />
          </Grid>
        </GridStyleWrapper>

        {wijzigenProdID !== null && (
          <WijzigenProductDialoog
            open
            prodID={wijzigenProdID}
            onSuccess={() => {
              setWijzigenProdID(null);
              onVerversenAangevraagd();
            }}
            onAnnuleren={() => {
              setWijzigenProdID(null);
            }}
          />
        )}

        {productinfoDialoogState !== null && (
          <ProductinfoDialoog
            open
            id={productinfoDialoogState.id}
            onSuccess={() => {
              setProductinfoDialoogState(null);
            }}
            onAnnuleren={() => setProductinfoDialoogState(null)}
          />
        )}
      </div>
    </div>
  );
};

export default withRouter(OpdrachtDetailComp);
