import { useCallback, useEffect, useMemo } from 'react';
import { RouteComponentProps } from 'react-router';

interface IUseSegmentTabbladParams<TTabblad extends keyof any> {
  props: RouteComponentProps<any>;
  pathMap: Record<TTabblad, string>;
  defaultTabblad: TTabblad;
  segmentIndex?: number;
  automatischDefaultTabbladSelecteren?: boolean;
}

interface IUseSegmentTabbladOutput<TTabblad extends keyof any> {
  tabblad: TTabblad | null;
  setSegmentTabblad: (tabblad: TTabblad | null) => void;
}

const useSegmentTabblad = <TTabblad extends keyof any>(
  params: IUseSegmentTabbladParams<TTabblad>,
): IUseSegmentTabbladOutput<TTabblad> => {
  const segmentString = useMemo(
    () => params.props.location.pathname.replace(params.props.match.path, ''),
    [params.props.location.pathname, params.props.match.path],
  );

  const setSegmentTabblad = useCallback(
    (tabblad: TTabblad | null) => {
      const segmentSplitZonderEigenSegment = segmentString
        .split('/')
        .slice(0, (params.segmentIndex ?? 0) + 1)
        .filter((x) => x !== '');
      const segmentStringZonderEigenSegment =
        segmentSplitZonderEigenSegment.length === 0
          ? null
          : segmentSplitZonderEigenSegment.join('/');
      const url = `${params.props.match.path}/${
        segmentStringZonderEigenSegment === null ? '' : `${segmentSplitZonderEigenSegment}/`
      }${params.pathMap[tabblad ?? params.defaultTabblad]}`;

      params.props.history.push(url);
    },
    [params.props],
  );

  const relevantSegment = useMemo(() => {
    const segmentSplit = segmentString.split('/').filter((x) => x !== '');
    if (segmentSplit.length > (params.segmentIndex ?? 0)) {
      return segmentSplit[params.segmentIndex ?? 0];
    }
    return null;
  }, [segmentString]);

  useEffect(() => {
    if ((params.automatischDefaultTabbladSelecteren ?? true) && relevantSegment === null) {
      setSegmentTabblad(params.defaultTabblad);
    }
  }, [relevantSegment, params.automatischDefaultTabbladSelecteren, params.defaultTabblad]);

  const tabblad = useMemo<TTabblad | null>(() => {
    if (relevantSegment === null) {
      return null;
    }

    const matchKey = Object.keys(params.pathMap).find((key) => {
      const path = params.pathMap[Number(key) as TTabblad]!;
      return path === relevantSegment;
    });
    if (matchKey === undefined) {
      setSegmentTabblad(params.defaultTabblad);
      return null;
    }
    return Number(matchKey) as TTabblad;
  }, [relevantSegment]);

  return useMemo(
    () => ({
      tabblad,
      setSegmentTabblad,
    }),
    [tabblad],
  );
};

export default useSegmentTabblad;
