import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import IDialoogProps from '../../../../../../../../core/IDialoogProps';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { Field, FieldProps, Formik, FormikActions, FormikErrors, FormikProps } from 'formik';
import Combobox, { IOptie } from '../../../../../../../../components/formulier/Combobox';
import { RootStoreContext } from '../../../../../../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import VinkVeld from '../../../../../../../../components/formulier/VinkVeld';
import api from '../../../../../../../../api';
import { EResultType } from '../../../../../../../../stores/CheckStore';
import * as Yup from 'yup';
import teksten from '../../../../../../../../bedrijfslogica/teksten';
import FormikVeldFout from '../../../../../../../../components/formulier/FormikVeldFout';
import Dialoog from '../../../../../../../../components/dialogen/Dialoog';
import _ from 'lodash';
import LoadingSpinner from '../../../../../../../../components/Gedeeld/LoadingSpinner';
import { IOphalenProductmodellenResultElement } from '../../../../../../../../../../shared/src/api/v2/aanbod/productmodel';
import nameOf from '../../../../../../../../core/nameOf';
import MultiCombobox, { IKolom } from '../../../../../../../../components/formulier/MultiCombobox';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../../../../../models/IRemoteData';
import {
  IOphalenAanbodResultElement,
  IOphalenAanbodResultElementTariefElement,
} from '../../../../../../../../../../shared/src/api/v2/aanbod/index';
import { formatteerBedrag } from '../../../../../../../../helpers';
import FormatteerBedrag from '../../../../../../../../components/MutatieBedrag';

interface IProps extends IDialoogProps<{}> {
  relID: number;
  cntIDs: number[];
}

interface IFormikValues {
  prodModID: number | null;
  abonID: number | null;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  prodModID: 'Productmodel',
  abonID: 'Abonnement',
};

const ModelEnAbonnementWijzigenDialoog = observer((props: IProps) => {
  const { onAnnuleren, onSuccess, open, relID, cntIDs } = props;
  const { checkStore } = useContext(RootStoreContext);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const formikRef = useRef<Formik<IFormikValues>>(null);
  // const [redenen, setRedenen] = useState<IOphalenRedenenAfkeuringResultElement[] | null>(null);
  const [prodModID, setProdModID] = useState<IRemoteData<number | null>>(createPendingRemoteData());
  const [abonID, setAbonID] = useState<number | null>(null);
  const [productmodellen, setProductmodellen] = useState<
    IOphalenProductmodellenResultElement[] | null
  >(null);
  const [productmodellenTarieven, setProductmodellenTarieven] = useState<
    IOphalenAanbodResultElement[] | null
  >(null);

  useEffect(() => {
    (async () => {
      // Haal de contacten op om te bepalen welk model en abonnement voorgevuld gaat worden
      const contracten = (
        await api.v2.contract.ophalenContractenV2({
          filterSchema: { filters: [{ naam: 'IDS', data: [props.cntIDs] }] },
        })
      ).contracten;

      const prodModIDs = _.uniq(contracten.map((x) => x.basis.productmodel.ProdModID));
      const prodModID = prodModIDs.length === 1 ? prodModIDs[0] : null;
      setProdModID(createReadyRemoteData(prodModID));
    })();
  }, [props.cntIDs]);

  // Ophalen alle actieve productmodellen
  useEffect(() => {
    (async () => {
      const results = (
        await api.v2.product.model.ophalenProductmodellen({
          filterSchema: {
            filters: [
              {
                naam: 'ACTIEF',
                data: true,
              },
            ],
          },
          orderSchema: {
            orders: [{ naam: 'MERKNAAM', richting: 'ASC' }],
          },
        })
      ).modellen;
      // const productmodellen = _.orderBy(results, ['Merknaam', 'Typenaam'], ['asc', 'asc']);
      setProductmodellen(results);
    })();
  }, []);

  // Ophalen tarieven
  useEffect(() => {
    (async () => {
      const aanbodResult = await api.v2.aanbod.ophalenAanbod({
        filterSchema: {
          filters: [
            {
              naam: 'REL_ID',
              data: [props.relID],
            },
          ],
        },
      });

      setProductmodellenTarieven(aanbodResult);
    })();
  }, [props.relID]);

  // useEffect(() => {
  //   (async () => {
  //     const redenenResult = await api.v2.contract.afkeuring.ophalenRedenenAfkeuring({});

  //     setRedenen(redenenResult.redenen);
  //   })();
  // }, []);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      // setHasSubmitted(true);

      // // Controle stap validatie (voor foutmeldingen na OK)
      // const errors = await actions.validateForm(values);
      // const hasError = Object.keys(errors).some((key) => (errors as any)[key] !== null);
      // if (hasError) {
      //   console.error(errors);
      //   return;
      // }

      actions.setSubmitting(true);

      // const params = {
      //   relID,
      //   cntIDs,
      //   cntRdAfkID: values.cntRdAfkID!,
      // };
      // const checkData = await api.v2.contract.afkeuring.checkAfkeurenContracten(params);

      // if (
      //   (
      //     await checkStore.controleren({
      //       checkData,
      //     })
      //   ).type === EResultType.Annuleren
      // ) {
      //   actions.setSubmitting(false);
      //   return;
      // }

      // await api.v2.contract.afkeuring.afkeurenContracten(params);

      onSuccess({});

      actions.setSubmitting(false);
    },
    [onSuccess, relID, cntIDs, setHasSubmitted],
  );

  // const redenenOpties = useMemo<IOptie<number>[] | null>(() => {
  //   if (redenen === null) {
  //     return null;
  //   }

  //   return redenen.map(
  //     (reden): IOptie<number> => ({
  //       id: reden.CntRdAfkID,
  //       label: reden.Naam,
  //     }),
  //   );
  // }, [redenen]);

  const initieleWaarden = useMemo<IFormikValues | null>(() => {
    if (prodModID.state === ERemoteDataState.Pending) {
      return null;
    }

    return {
      prodModID: prodModID.data,
      abonID,
    };
  }, [prodModID.state, prodModID.data]);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        prodModID: Yup.number().required(
          teksten.formulier.E_VERPLICHT_VELD({ veldnaam: veldnamen.prodModID }),
        ),
        abonID: Yup.number().required(
          teksten.formulier.E_VERPLICHT_VELD({ veldnaam: veldnamen.abonID }),
        ),
      }),
    [],
  );

  return (
    <Dialoog index={props.dialoogIndex || 0}>
      {initieleWaarden === null ? (
        <LoadingSpinner />
      ) : (
        <>
          <ModalHeader>
            <ModalTitle>Productmodel/abonnement wijzigen</ModalTitle>
          </ModalHeader>
          <Formik<IFormikValues>
            onSubmit={handleSubmit}
            initialValues={initieleWaarden}
            isInitialValid
            // validationSchema={validationSchema}
            render={(formikProps: FormikProps<IFormikValues>) => {
              if (productmodellen === null || productmodellenTarieven === null) {
                return <LoadingSpinner />;
              }

              return (
                <Formulier
                  formikProps={formikProps}
                  onAnnuleren={onAnnuleren}
                  productmodellen={productmodellen}
                  productmodellenTarieven={productmodellenTarieven}
                />
              );
            }}
          />
        </>
      )}
    </Dialoog>
  );
});

interface IFormulierProps {
  formikProps: FormikProps<IFormikValues>;
  onAnnuleren: () => void;
  productmodellen: IOphalenProductmodellenResultElement[];
  productmodellenTarieven: IOphalenAanbodResultElement[];
}

const Formulier = (props: IFormulierProps) => {
  const { formikProps, onAnnuleren, productmodellen, productmodellenTarieven } = props;
  const { submitForm, isSubmitting, isValid, values, setFieldValue } = formikProps;

  const [abonnementOpties, setAbonnementOpties] = useState<
    IOphalenAanbodResultElementTariefElement[] | null
  >(null);

  useEffect(() => {
    (async () => {
      const tarievenResult =
        values.prodModID !== null
          ? productmodellenTarieven.find((model) => model.ProdModID === values.prodModID)
          : null;

      if (tarievenResult === undefined || tarievenResult === null) {
        setAbonnementOpties([]);
        setFieldValue('abonID', null);
        return;
      }

      const abonnementOpties = tarievenResult.tarieven;
      const abonID = abonnementOpties.length !== 0 ? abonnementOpties[0].AbonID : null;

      setAbonnementOpties(abonnementOpties);
      setFieldValue('abonID', abonID);
    })();
  }, [values.prodModID]);

  const productmodelKolommen = useMemo<IKolom<IOphalenProductmodellenResultElement>[]>(() => {
    return [
      {
        key: 'Modelnaam',
        label: 'Modelnaam',
        breedte: 200,
      },
      {
        key: 'Modelcode',
        label: 'Code',
        breedte: 125,
      },
      {
        key: 'Kenmerk',
        label: 'Kenmerk',
        breedte: 250,
      },
    ];
  }, []);

  const abonnementKolommen = useMemo<IKolom<IOphalenAanbodResultElementTariefElement>[]>(() => {
    return [
      {
        key: 'Naam',
        label: 'Naam',
        breedte: 80,
      },
      {
        key: 'MinimaleTermijn',
        label: 'Min. termijn',
        breedte: 100,
      },
      {
        key: 'Maandhuur',
        label: 'Maandhuur',
        breedte: 100,
        formatFabriek: (entiteit) => <FormatteerBedrag bedrag={entiteit.Maandhuur} />,
      },
      {
        key: 'MaandhuurActie',
        label: 'Actie',
        breedte: 100,
        formatFabriek: (entiteit) => {
          const bedrag =
            entiteit.MaandhuurActie !== null ? (
              <FormatteerBedrag bedrag={entiteit.MaandhuurActie} />
            ) : (
              ''
            );
          return bedrag;
        },
      },
      {
        key: 'Actieduur',
        label: 'Actieduur',
        breedte: 100,
        formatFabriek: (entiteit) => {
          if (entiteit.Actieduur === null) {
            return <span></span>;
          }
          return entiteit.Actieduur === 0 ? 'Looptijd' : entiteit.Actieduur;
        },
      },
    ];
  }, []);

  return (
    <>
      <ModalBody>
        <div className="form-group">
          <div className="row">
            <div className="col-12">
              <label>{veldnamen.prodModID}</label>
              <Field
                name={nameOf<IFormikValues>('prodModID')}
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;

                  return (
                    <MultiCombobox<number, any>
                      sleutelExtractor={(rij: IOphalenProductmodellenResultElement) =>
                        rij.ProdModID
                      }
                      onWaardeChange={(waarde: number | null) => {
                        form.setFieldValue(field.name, waarde);
                      }}
                      representatieFabriek={(rij: IOphalenProductmodellenResultElement) =>
                        `${rij.Modelcode} - ${rij.Modelnaam}`
                      }
                      waarde={field.value}
                      opties={productmodellen.map((x) => {
                        return {
                          ProdModID: x.ProdModID,
                          Modelcode: x.Modelcode,
                          Modelnaam: x.Modelnaam,
                          Kenmerk: x.Kenmerk,
                        };
                      })}
                      kolommen={productmodelKolommen}
                    />
                  );
                }}
              />
            </div>

            <div className="col-6 mt-2">
              <label>{veldnamen.abonID}</label>
              <Field
                name="abonID"
                render={(fieldProps: FieldProps<IFormikValues>) => (
                  <MultiCombobox<number, IOphalenAanbodResultElementTariefElement>
                    sleutelExtractor={(row) => row.AbonID}
                    onWaardeChange={(waarde: number | null) =>
                      fieldProps.form.setFieldValue(fieldProps.field.name, waarde)
                    }
                    representatieFabriek={(row) =>
                      row.Naam + ' - ' + formatteerBedrag(row.Maandhuur)
                    }
                    waarde={fieldProps.field.value}
                    opties={abonnementOpties}
                    kolommen={abonnementKolommen}
                    // Er moet eerst een product model gekozen worden voordat er een
                    // abonnement kan worden geselecteerd
                    disabled={values.prodModID === null}
                  />
                )}
              />
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter className="d-flex justify-content-start">
        <button
          className="btn btn-primary"
          onClick={submitForm}
          disabled={isSubmitting || !isValid}
          // disabled={isSubmitting}
          style={{ width: 100 }}
        >
          Ok
        </button>
        <button className="btn btn-secondary" onClick={onAnnuleren} style={{ width: 100 }}>
          Annuleren
        </button>
      </ModalFooter>
    </>
  );
};

export default ModelEnAbonnementWijzigenDialoog;
