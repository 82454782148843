import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import IDialoogProps from '../../../../core/IDialoogProps';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import Combobox, { IOptie } from '../../../../components/formulier/Combobox';
import { RootStoreContext } from '../../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import api from '../../../../api';
import { EResultType } from '../../../../stores/CheckStore';
import * as Yup from 'yup';
import teksten from '../../../../bedrijfslogica/teksten';
import FormikVeldFout from '../../../../components/formulier/FormikVeldFout';
import {
  IOphalenProductenResultElementV2,
  IOphalenProductstatussenResultElement,
} from '../../../../../../shared/src/api/v2/product';
import Dialoog from '../../../../components/dialogen/Dialoog';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import nameOf from '../../../../core/nameOf';
import VinkVeld from '../../../../components/formulier/VinkVeld';
import useGebruikersStempel, { IPlaatsStempelOutput } from '../../../../core/useGebruikersStempel';
import Skeleton from 'react-loading-skeleton';
import LocatieSelectie from '../../../../components/formulier/LocatieSelectie';

interface IProps extends IDialoogProps<{}> {
  prodID: number;
}

interface IFormikValues {
  notities: string | null;
  retourstatus_ProdStatID: number | null;
  serienummer: string | null;
  referentiecode: string | null;
  defect: boolean;
  nieuw: boolean;
  locID: number | null;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  notities: 'Notities',
  retourstatus_ProdStatID: 'Doelstatus',
  serienummer: 'Serienummer',
  referentiecode: 'Ref.code',
  defect: 'Defect',
  nieuw: 'Product is nieuw',
  locID: 'Locatie',
};

const WijzigenProductDialoog = observer((props: IProps) => {
  const { onAnnuleren, onSuccess, open } = props;
  const { checkStore } = useContext(RootStoreContext);
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const [product, setProduct] = useState<IOphalenProductenResultElementV2 | null>(null);
  const [statussen, setStatussen] = useState<IOphalenProductstatussenResultElement[] | null>(null);
  const [retourstatusID, setRetourstatusID] = useState<number | null>(null);

  useEffect(() => {
    (async () => {
      const result = await api.v2.product.ophalenProductenV2({
        filterSchema: { filters: [{ naam: 'IDS', data: [props.prodID] }] },
      });
      setProduct(result.producten[0]);
    })();
  }, [props.prodID]);

  // Rijbron Retourstatussen (doelstatus)
  useEffect(() => {
    (async () => {
      const result = await api.v2.product.ophalenProductstatussen({
        filterSchema: {
          filters: [
            { naam: 'ENUMS', data: ['VOORRAAD', 'REVISIE', 'AFVOER', 'RETLEV', 'RETOUREN'] },
          ],
        },
      });
      setStatussen(result.statussen);
    })();
  }, []);

  // Converteer naar formaat tbv combobox
  const statussenOpties = useMemo<IOptie<number>[] | null>(() => {
    if (statussen === null) {
      return null;
    }

    return statussen.map(
      (status): IOptie<number> => ({
        id: status.ProdStatID,
        label: status.Naam,
      }),
    );
  }, [statussen]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      const params = {
        prodID: props.prodID,
        notities: values.notities,
        retourstatus_ProdStatID: values.retourstatus_ProdStatID,
        serienummer: values.serienummer,
        referentiecode: values.referentiecode,
        defect: values.defect,
        nieuw: values.nieuw,
        locID: values.locID,
      };
      const checkData = await api.v2.product.checkWijzigenProduct(params);
      if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
        return;
      }

      await api.v2.product.wijzigenProduct(params);

      onSuccess({});
      actions.setSubmitting(false);
    },
    [onSuccess],
  );

  const initieleWaarden = useMemo<IFormikValues | null>(() => {
    if (product === null) {
      return null;
    }

    return {
      notities: product.Notities,
      retourstatus_ProdStatID:
        product.retourstatus !== null ? product.retourstatus.ProdStatID : null,
      serienummer: product.Serienummer,
      referentiecode: product.Referentiecode,
      defect: product.Defect,
      nieuw: product.Nieuw,
      locID: product.locatie !== null ? product.locatie.LocID : null,
    };
  }, [product]);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        notities: Yup.string().required(
          teksten.formulier.E_VERPLICHT_VELD({ veldnaam: veldnamen.notities }),
        ),
      }),
    [],
  );

  return (
    <Dialoog index={props.dialoogIndex || 0}>
      {product === null ? (
        <LoadingSpinner />
      ) : (
        <>
          <ModalHeader>
            <ModalTitle>Wijzigen product</ModalTitle>
          </ModalHeader>
          <Formik<IFormikValues>
            onSubmit={handleSubmit}
            initialValues={initieleWaarden!}
            isInitialValid
            // validationSchema={validationSchema}
            render={(formikProps: FormikProps<IFormikValues>) => {
              const { submitForm, isSubmitting, values, isValid, setFieldValue } = formikProps;
              return (
                <Formulier
                  isSubmitting={isSubmitting}
                  values={values}
                  isValid={isValid}
                  onAnnuleren={onAnnuleren}
                  submitForm={submitForm}
                  statussenOpties={statussenOpties}
                  product={product}
                  hasSubmitted={hasSubmitted}
                  setFieldValue={setFieldValue}
                />
              );
            }}
          />
        </>
      )}
    </Dialoog>
  );
});

interface IFormulierProps {
  values: IFormikValues;
  statussenOpties: IOptie<number>[] | null;
  product: IOphalenProductenResultElementV2;
  hasSubmitted: boolean;
  submitForm: () => void;
  isSubmitting: boolean;
  isValid: boolean;
  onAnnuleren: () => void;
  setFieldValue: (key: keyof IFormikValues, value: any) => void;
}

const Formulier = (props: IFormulierProps) => {
  const {
    isSubmitting,
    values,
    isValid,
    onAnnuleren,
    submitForm,
    statussenOpties,
    product,
    hasSubmitted,
    setFieldValue,
  } = props;

  const notitiesRef = useRef<HTMLTextAreaElement>(null);

  const { plaatsStempel, bezigMetStempelen } = useGebruikersStempel(
    useMemo(
      () => ({
        text: values.notities ?? '',
        inputRef: notitiesRef,
        onTextChange: (x) => setFieldValue(nameOf<IFormikValues>('notities'), x),
      }),
      [values.notities, setFieldValue],
    ),
  );
  const plaatsStempelOutputRef = useRef<IPlaatsStempelOutput | null>(null);

  return (
    <>
      <ModalBody>
        <div className="form-group">
          <div className="row">
            <div className="col-12">
              <label>{veldnamen.locID}</label>
              <Field
                name="locID"
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;

                  const relIDs =
                    product.contractActueel !== null ? [product.contractActueel.RelID] : [];

                  return (
                    <LocatieSelectie
                      locID={fieldProps.field.value}
                      onChange={(locID) => {
                        form.setFieldValue(field.name, locID);
                      }}
                      relIDs={relIDs}
                      options={{
                        bijEnkeleDirectVoorselecteren: true,
                      }}
                    />
                  );
                }}
              />
            </div>

            <div className="col-12 mt-3">
              <div className="d-flex align-items-center">
                <label>{veldnamen.notities}</label>
                <div className="flex-fill" />
                {bezigMetStempelen ? (
                  <>
                    <a
                      href="#"
                      onClick={(ev) => {
                        ev.preventDefault();
                        plaatsStempelOutputRef.current!.herstellen();
                        plaatsStempelOutputRef.current = null;
                      }}
                    >
                      Herstellen
                    </a>
                    <a
                      href="#"
                      onClick={(ev) => {
                        ev.preventDefault();
                        plaatsStempelOutputRef.current!.doorvoeren();
                        plaatsStempelOutputRef.current = null;
                      }}
                      className="ml-2"
                    >
                      Doorvoeren
                    </a>
                  </>
                ) : (
                  <a
                    href="#"
                    onClick={(ev) => {
                      ev.preventDefault();

                      plaatsStempelOutputRef.current = plaatsStempel();
                    }}
                  >
                    Stempelen
                  </a>
                )}
              </div>
              <Field
                name={nameOf<IFormikValues>('notities')}
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;
                  return (
                    <textarea
                      ref={notitiesRef}
                      {...field}
                      className="form-control"
                      rows={3}
                      autoFocus
                    />
                  );
                }}
              />
            </div>

            <div className="col-12 mt-2">
              <label>{veldnamen.retourstatus_ProdStatID}</label>
              <Field
                name="retourstatus_ProdStatID"
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;

                  if (statussenOpties === null) {
                    return <Skeleton />;
                  }

                  return (
                    <>
                      <Combobox
                        geselecteerd={field.value}
                        opties={statussenOpties}
                        onSelectieChange={(id) => {
                          fieldProps.form.setFieldValue(fieldProps.field.name, id);
                        }}
                        legeOptieTonen
                        options={{
                          legeOptieTekst: 'Geen status',
                        }}
                      />
                      <FormikVeldFout fieldProps={fieldProps} directTonen={hasSubmitted} />
                    </>
                  );
                }}
              />
            </div>

            <div className="col-12 mt-2">
              <label>{veldnamen.serienummer}</label>
              <Field
                name={nameOf<IFormikValues>('serienummer')}
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;

                  return <input {...field} className="form-control" type="text" />;
                }}
              />
            </div>

            <div className="col-12 mt-2">
              <label>{veldnamen.referentiecode}</label>
              <Field
                name={nameOf<IFormikValues>('referentiecode')}
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;

                  return <input {...field} className="form-control" type="text" />;
                }}
              />
            </div>

            <div className="col-12 d-flex align-items-center pt-4">
              <Field
                name={nameOf<IFormikValues>('nieuw')}
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;
                  return (
                    <VinkVeld
                      aangevinkt={field.value}
                      onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                    />
                  );
                }}
              />
              <span className="ml-2">{veldnamen.nieuw}</span>
            </div>

            <div className="col-6 d-flex align-items-center pt-4">
              <Field
                name={nameOf<IFormikValues>('defect')}
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;
                  return (
                    <VinkVeld
                      aangevinkt={field.value}
                      onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                    />
                  );
                }}
              />
              <span className="ml-2">{veldnamen.defect}</span>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter className="d-flex justify-content-start">
        <button
          className="btn btn-primary"
          onClick={submitForm}
          disabled={isSubmitting || !isValid}
          style={{ width: 100 }}
        >
          Ok
        </button>
        <button className="btn btn-secondary" onClick={onAnnuleren} style={{ width: 100 }}>
          Annuleren
        </button>
      </ModalFooter>
    </>
  );
};

export default WijzigenProductDialoog;
