import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import ModalFooter from 'react-bootstrap/ModalFooter';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import { IOphalenDienstenResultElement } from '../../../../../../../shared/src/api/v2/dienst/pendel';
import api from '../../../../../api';
import LoadingSpinner from '../../../../../components/Gedeeld/LoadingSpinner';
import Tabblad, { ITabblad } from '../../../../../components/layout/Tabblad';
import IDialoogProps from '../../../../../core/IDialoogProps';
import { EResultType } from '../../../../../stores/CheckStore';
import { RootStoreContext } from '../../../../../stores/RootStore';
import GedeeldFormulier from '../../Formulieren/GedeeldFormulier';
import { IOphalenOpdrachtwijzenResultElement } from '../../../../../../../shared/src/api/v2/dienst';
import Dialoog from '../../../../../components/dialogen/Dialoog';
import PendelFormulier from '../../Formulieren/PendelFormulier';
import * as Yup from 'yup';
import teksten from '../../../../../bedrijfslogica/teksten';

interface IProps extends IDialoogProps<null> {
  id: number | null;
}

export interface IFormikValues {
  actief: boolean;
  naam: string | null;
  naamEnum: string;
  opdWijzeID: number | null;
  parameters: string | null;
  relID: number | null;
  emailOpdracht: string | null;
  emailOpdrachtCC: string | null;
}

enum ETablad {
  Algemeen,
  Pendel,
}

export const veldnamen: Record<keyof IFormikValues, string> = {
  actief: 'Actief',
  naam: 'Naam',
  naamEnum: 'NaamEnum',
  opdWijzeID: 'Standaard opdrachtwijze',
  parameters: 'Parameters',
  relID: 'Relatie',
  emailOpdracht: 'Email tbv opdrachten',
  emailOpdrachtCC: 'Email CC tbv opdrachten',
};

const MutatieDialoog: React.FC<IProps> = (props) => {
  const { onAnnuleren, onSuccess, open, dialoogIndex, id } = props;
  const { checkStore } = useContext(RootStoreContext);

  const modalTitel = id !== null ? `Wijzigen` : `Toevoegen`;
  const [tabblad, setTabblad] = useState<ETablad>(ETablad.Algemeen);
  const [serviceDienst, setServiceDienst] = useState<IOphalenDienstenResultElement | null>(null);

  const tabbladen = useMemo<ITabblad<ETablad>[]>(
    () => [
      {
        id: ETablad.Algemeen,
        label: 'Algemeen',
        content: GedeeldFormulier,
      },
      {
        id: ETablad.Pendel,
        label: 'Pendel',
        content: PendelFormulier,
      },
    ],
    [onSuccess],
  );

  const ophalenDienst = async () => {
    const resultaat = await api.v2.dienst.pendel.ophalenDiensten({
      filterSchema: { filters: [{ naam: 'IDS', data: [id] }] },
    });

    setServiceDienst(resultaat.diensten[0]);
  };

  useEffect(() => {
    if (id !== null) {
      console.log(`haal dienst op...`);
      ophalenDienst();
    } else {
      console.log(`haal GEEN dienst op...`);
    }
  }, [id]);

  const [opdrachtwijzen, setOpdrachtwijzen] = useState<
    IOphalenOpdrachtwijzenResultElement[] | null
  >(null);

  useEffect(() => {
    (async () => {
      const opdrachtwijzen = (
        await api.v2.dienst.ophalenOpdrachtwijzen({
          filterSchema: { filters: [] },
        })
      ).opdrachtwijzen;

      setOpdrachtwijzen(opdrachtwijzen);
    })();
  }, []);

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (opdrachtwijzen === null) {
      return null;
    }

    if (serviceDienst === null) {
      return {
        actief: true,
        naam: '',
        naamEnum: '',
        opdWijzeID: opdrachtwijzen.find((x) => x.NaamEnum === 'OVERIG')!.OpdWijzeID!,
        parameters: '',
        relID: null,
        emailOpdracht: '',
        emailOpdrachtCC: '',
      };
    }

    return {
      actief: serviceDienst.Actief,
      naam: serviceDienst.Naam,
      naamEnum: serviceDienst.NaamEnum ?? '',
      opdWijzeID: serviceDienst.OpdWijzeID!,
      parameters: serviceDienst.Parameters,
      relID: serviceDienst.RelID,
      emailOpdracht: serviceDienst.EmailOpdracht,
      emailOpdrachtCC: serviceDienst.EmailOpdrachtCC,
    };
  }, [serviceDienst, opdrachtwijzen]);

  const validatieschema = Yup.object().shape({
    relID: Yup.number()
      .required(teksten.formulier.E_VERPLICHT_VELD({ veldnaam: 'Relatie' }))
      .nullable(),
    // naam: Yup.string().required(teksten.formulier.E_VERPLICHT_VELD({ veldnaam: 'Naam' })),
  });

  const handleSubmit = useCallback(
    async (values, actions: FormikActions<IFormikValues>) => {
      if (props.id === null) {
        // Nieuwe dienst

        const params = {
          relID: values.relID,
          parameters: values.parameters === '' ? null : values.parameters,
          opdWijzeID: values.opdWijzeID,
          naam: values.naam,
          naamEnum: values.naamEnum,
          actief: values.actief,
          emailOpdracht: values.emailOpdracht === '' ? null : values.emailOpdracht,
          emailOpdrachtCC: values.emailOpdrachtCC === '' ? null : values.emailOpdrachtCC,
        };

        const checkData = await api.v2.dienst.pendel.checkToevoegenDienst(params);
        if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
          return;
        }
        const resultaat = await api.v2.dienst.pendel.toevoegenDienst(params);
      } else {
        // Wijzigen dienst
        const params = {
          id: props.id,
          relID: values.relID,
          parameters: values.parameters === '' ? null : values.parameters,
          opdWijzeID: values.opdWijzeID,
          naam: values.naam,
          naamEnum: values.naamEnum,
          actief: values.actief,
          emailOpdracht: values.emailOpdracht === '' ? null : values.emailOpdracht,
          emailOpdrachtCC: values.emailOpdrachtCC === '' ? null : values.emailOpdrachtCC,
        };

        const checkData = await api.v2.dienst.pendel.checkWijzigenDienst(params);
        if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
          return;
        }

        const resultaat = await api.v2.dienst.pendel.wijzigenDienst(params);
      }

      props.onSuccess(null);
    },
    [props.onSuccess],
  );

  return (
    <Dialoog index={dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>{modalTitel} pendeldienst</ModalTitle>
      </ModalHeader>
      <div>
        {/* <code>{JSON.stringify(inkoopDienst)}</code> */}
        {/* <code>{JSON.stringify(initialValues)}</code> */}
        {initialValues === null ? (
          <LoadingSpinner />
        ) : (
          <Formik
            enableReinitialize
            onSubmit={handleSubmit}
            initialValues={initialValues}
            validationSchema={validatieschema}
            render={(formikProps: FormikProps<IFormikValues>) => {
              const { submitForm, isSubmitting, isValid, values } = formikProps;
              return (
                <>
                  <Tabblad
                    tabbladen={tabbladen}
                    geselecteerd={tabblad}
                    onSelectieChange={(id) => {
                      setTabblad(id);
                    }}
                  />
                  <ModalFooter className="d-flex justify-content-start mt-2">
                    <button
                      className="btn btn-primary"
                      onClick={submitForm}
                      style={{ width: 100 }}
                      disabled={false}
                    >
                      Ok
                    </button>
                    <button
                      className="btn btn-secondary"
                      style={{ width: 100 }}
                      onClick={onAnnuleren}
                    >
                      Annuleren
                    </button>
                  </ModalFooter>
                </>
              );
            }}
          />
        )}
      </div>
    </Dialoog>
  );
};

export default MutatieDialoog;
