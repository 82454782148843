import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import IDialoogProps from '../../../core/IDialoogProps';
import Dialoog from '../../dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import nameOf from '../../../core/nameOf';
import * as Yup from 'yup';
import teksten from '../../../bedrijfslogica/teksten';
import api from '../../../api';
import DatumEnTijdKiezer from '../../formulier/DatumEnTijdKiezer';
import { IOphalenControlTakenResultElement } from '../../../../../shared/src/api/v2/control/control';
import LoadingSpinner from '../../Gedeeld/LoadingSpinner';
import Combobox from '../../formulier/Combobox';

interface IDialoogResult {}

interface IFormikValues {
  opvolgdatum: Date | null;
  uiterlijkOp: Date | null;
  notities: string;
  urgentie: number;
}

interface IProps extends IDialoogProps<IDialoogResult> {
  id: number;
}

const HandmatigControltaakToevoegenDialoog: React.FC<IProps> = (props) => {
  const [taak, setTaak] = useState<IOphalenControlTakenResultElement | null>(null);

  const ophalenTaak = useCallback(async () => {
    const taak = (
      await api.v2.control.ophalenControlTaken({
        filterSchema: { filters: [{ naam: 'IDS', data: [props.id] }] },
      })
    ).taken[0];

    setTaak(taak);
  }, [props.id]);

  useEffect(() => {
    ophalenTaak();
  }, [ophalenTaak]);

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (taak === null) {
      return null;
    }
    return {
      opvolgdatum: taak.Opvolgdatum !== null ? new Date(taak.Opvolgdatum) : null,
      uiterlijkOp: taak.UiterlijkOp !== null ? new Date(taak.UiterlijkOp) : null,
      notities: taak.Inhoud,
      urgentie: taak.Urgentie,
    };
  }, [taak]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      await api.v2.control.wijzigenTaak({
        id: props.id,
        opvolgdatum: values.opvolgdatum,
        uiterlijkOp: values.uiterlijkOp,
        inhoud: values.notities,
        urgentie: values.urgentie,
      });

      await props.onSuccess({});
      actions.setSubmitting(false);
    },
    [props.onSuccess],
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        [nameOf<IFormikValues>('notities')]: Yup.string(),
      }),
    [],
  );

  const notitiesRef = useRef<HTMLTextAreaElement>(null);
  useEffect(() => {
    notitiesRef.current?.focus();
  }, []);

  if (initialValues === null) {
    return <LoadingSpinner />;
  }

  return (
    <Dialoog index={props.dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Controltaak wijzigen</ModalTitle>
      </ModalHeader>
      <Formik<IFormikValues>
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
        render={(formikProps: FormikProps<IFormikValues>) => {
          const { isValid, isSubmitting, submitForm } = formikProps;

          return (
            <>
              <ModalBody>
                <div className="row">
                  <div className="col-6 mt-3">
                    <label>Opvolgdatum</label>
                    <Field
                      name={nameOf<IFormikValues>('opvolgdatum')}
                      render={(fieldProps: FieldProps<IFormikValues>) => {
                        const { field, form } = fieldProps;

                        return (
                          <DatumEnTijdKiezer
                            waarde={field.value}
                            onChange={(x) => form.setFieldValue(field.name, x)}
                            isWisbaar
                            determineNextValidDate="ONBEGRENST"
                            determinePreviousValidDate="ONBEGRENST"
                          />
                        );
                      }}
                    />
                  </div>

                  <div className="col-6 mt-3">
                    <label>Uiterlijk op</label>
                    <Field
                      name={nameOf<IFormikValues>('uiterlijkOp')}
                      render={(fieldProps: FieldProps<IFormikValues>) => {
                        const { field, form } = fieldProps;

                        return (
                          <DatumEnTijdKiezer
                            waarde={field.value}
                            onChange={(x) => form.setFieldValue(field.name, x)}
                            isWisbaar
                            determineNextValidDate="ONBEGRENST"
                            determinePreviousValidDate="ONBEGRENST"
                          />
                        );
                      }}
                    />
                  </div>

                  <div className="col-12 mt-3">
                    <label>Urgentie</label>
                    <Field
                      name={nameOf<IFormikValues>('urgentie')}
                      render={(fieldProps: FieldProps<IFormikValues>) => {
                        const { field, form } = fieldProps;

                        const redenen = [
                          { id: -1, label: 'Laag' },
                          { id: 0, label: 'Normaal' },
                          { id: 1, label: 'Hoog' },
                        ];

                        return (
                          <>
                            <Combobox
                              geselecteerd={field.value}
                              opties={redenen}
                              onSelectieChange={(x) => form.setFieldValue(field.name, x)}
                            />
                          </>
                        );
                      }}
                    />
                  </div>

                  <div className="col-12 mt-3 mb-3">
                    <label>Notities</label>
                    <Field
                      name={nameOf<IFormikValues>('notities')}
                      render={(fieldProps: FieldProps<IFormikValues>) => {
                        const { field, form } = fieldProps;

                        return (
                          <textarea
                            {...field}
                            className="form-control"
                            rows={4}
                            ref={notitiesRef}
                          />
                        );
                      }}
                    />
                  </div>
                </div>
              </ModalBody>
              <ModalFooter className="d-flex flex-row justify-content-start">
                <button
                  className="btn btn-primary d-flex align-items-center justify-content-center"
                  onClick={submitForm}
                  style={{ width: 100 }}
                  disabled={isSubmitting || !isValid}
                >
                  Ok
                </button>
                <button
                  className="btn btn-secondary"
                  onClick={props.onAnnuleren}
                  style={{ width: 100 }}
                  disabled={isSubmitting}
                >
                  Annuleren
                </button>
              </ModalFooter>
            </>
          );
        }}
      />
    </Dialoog>
  );
};

export default HandmatigControltaakToevoegenDialoog;
