import React, { PropsWithChildren, useContext, useEffect, useMemo, useRef, useState } from 'react';
import MultiCombobox, { IKolom } from '../MultiCombobox';
import { formatteerRelatieNaam } from '../../../helpers';
import api from '../../../api';
import { IOphalenRelatiesResultElementV2 } from '../../../../../shared/src/api/v2/relatie';
// import LocatieMutatieDialoog, { ELocatieMutatieDialoogType } from './LocatieMutatieDialoog';
import { IFilterSchemaFilter } from '../../../../../shared/src/models/filter';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../stores/RootStore';

export interface IInitieleRelatiesProvider {
  provide: () => Promise<IRow[]>;
}

interface IProps {
  relID: number | null;
  filters?: IFilterSchemaFilter[];
  onChange: (relID: number | null) => void;
  onRelatieInformatieGedeeld?: (relatie: IOphalenRelatiesResultElementV2 | null) => void;
  isWisbaar?: boolean;
  disabled?: boolean;
  options?: IOptions;
  initieleRelatiesProvider?: IInitieleRelatiesProvider;
}

interface IOptions {
  bijEnkeleDirectVoorselecteren?: boolean;
  geenWaardeBericht?: JSX.Element | string;
  representatieFabriek?: (row: IRow) => JSX.Element | string | null;
}

export const defaultRepresentatieFabriek: IOptions['representatieFabriek'] = (row) => {
  const rel: IRow = row;
  if (rel === null) {
    return null;
  }

  return rel.Naam + ' - ' + rel.Relatienummer;
};

const defaultOptions: IOptions = {
  bijEnkeleDirectVoorselecteren: false,
  geenWaardeBericht: 'Geen relatie',
  representatieFabriek: defaultRepresentatieFabriek,
};

export interface IRow extends IOphalenRelatiesResultElementV2 {
  Naam: string;
}

interface IRelatieSelectieContext {
  zoektermInput: string;
  onZoektermInputChange: (zoektermInput: string) => void;
}

const RelatieSelectieContext = React.createContext<IRelatieSelectieContext>(null as any);

const PopoverComp = (props: PropsWithChildren<any>) => {
  const { zoektermInput, onZoektermInputChange } = useContext(RelatieSelectieContext);

  return (
    <div className="d-flex flex-column">
      <div className="d-flex align-items-center p-2">
        <input
          className="flex-fill form-control"
          placeholder="Zoeken..."
          value={zoektermInput}
          onChange={(ev) => onZoektermInputChange(ev.target.value)}
          autoFocus
        />
      </div>

      {props.children}
    </div>
  );
};

export const relatieSelectieRelatieToRow = (x: IOphalenRelatiesResultElementV2): IRow => {
  const params = {
    relatiesoort: x.Relatiesoort,
    voornaam: x.Relatiesoort === 'P' ? x.persoon!.Voornaam : undefined,
    achternaam: x.Relatiesoort === 'P' ? x.persoon!.Achternaam : undefined,
    aanhefKort: x.Relatiesoort === 'P' ? x.persoon!.geslacht.AanhefKort : undefined,
    voorletters: x.Relatiesoort === 'P' ? x.persoon!.Voorletters : undefined,
    voorvoegsel: x.Relatiesoort === 'P' ? x.persoon!.Voorvoegsel : undefined,
    organisatienaam: x.Relatiesoort === 'O' ? x.organisatie!.Naam : undefined,
  };

  const naam = formatteerRelatieNaam(params);

  return {
    ...x,
    Naam: naam,
  };
};

const RelatieSelectie: React.FC<IProps> = observer((props) => {
  const { gebruikerStore } = useContext(RootStoreContext);
  const options = useMemo(() => ({ ...defaultOptions, ...props.options }), [props.options]);

  const defaultInitieleRelatiesProvider = useMemo<IInitieleRelatiesProvider>(
    () => ({
      provide: async () => {
        if (gebruikerStore.gebruiker === null) {
          return [];
        }

        const relatieFilterSchema = { filters: props.filters ?? [] };
        const relatieselectieRecentResult = await api.v2.selectie.ophalenRelatieselectieRecent({
          aspGebrID: gebruikerStore.gebruiker.AspGebrID,
          relatieFilterSchema,
          paginatie: {
            index: 0,
            aantal: 20,
          },
        });
        if (relatieselectieRecentResult.relaties.length === 0) {
          return [];
        }

        const relatiesResult = await api.v2.relatie.ophalenRelaties({
          filterSchema: {
            filters: [
              {
                naam: 'IDS',
                data: relatieselectieRecentResult.relaties.map((x) => x.RelID),
              },
            ],
          },
        });

        return relatiesResult.relaties.map(relatieSelectieRelatieToRow);
      },
    }),
    [gebruikerStore.gebruiker],
  );

  const initieleRelatiesProvider = useMemo(
    () => props.initieleRelatiesProvider ?? defaultInitieleRelatiesProvider,
    [props.initieleRelatiesProvider, defaultInitieleRelatiesProvider],
  );
  const { onChange, relID } = props;
  const [relaties, setRelaties] = useState<IRow[] | null>(null);
  const [zoekterm, setZoekterm] = useState<string | null>(null);
  const [zoektermInput, setZoektermInput] = useState('');

  useEffect(() => {
    (async () => {
      const trimmedZoekterm = zoekterm?.trim() || null;
      if (trimmedZoekterm === null) {
        const rows = await initieleRelatiesProvider.provide();
        setRelaties(rows);
        return;
      }

      // const relatiesResult = await api.v2.relatie.ophalenRelaties({
      //   filterSchema: { filters: [{ naam: 'IDS', data: relIDs }] },
      // });

      const filters: IFilterSchemaFilter[] = [
        {
          naam: 'ZOEKTERM',
          data: trimmedZoekterm,
        },
      ];

      const relatiesResult = await api.v2.relatie.ophalenRelaties({
        filterSchema: { filters: [...filters, ...(props.filters || [])] },
        paginatie: {
          index: 0,
          aantal: 20,
        },
      });

      const relaties = relatiesResult.relaties.map(relatieSelectieRelatieToRow);
      setRelaties(relaties);
    })();
  }, [JSON.stringify(props.filters), zoekterm]);

  const kolommen = useMemo<IKolom<IRow>[]>(() => {
    return [
      {
        key: 'Naam',
        label: 'Naam',
        breedte: 250,
      },
      {
        key: 'Relatienummer',
        label: 'Rel.nr.',
        breedte: 100,
      },
    ];
  }, []);

  const zoektermDebounceCancelFn = useRef<(() => void) | null>(null);
  useEffect(() => {
    if (zoektermDebounceCancelFn.current !== null) {
      zoektermDebounceCancelFn.current();
      zoektermDebounceCancelFn.current = null;
    }

    const trimmedZoekterm = zoektermInput.trim();
    const debounced = _.debounce(
      () => setZoekterm(trimmedZoekterm.length === 0 ? null : trimmedZoekterm),
      500,
    );

    zoektermDebounceCancelFn.current = debounced.cancel;
    debounced();
  }, [zoektermInput]);

  const context = useMemo<IRelatieSelectieContext>(() => {
    return {
      zoektermInput,
      onZoektermInputChange: setZoektermInput,
    };
  }, [zoektermInput, setZoektermInput]);

  return (
    <RelatieSelectieContext.Provider value={context}>
      <MultiCombobox<number, IRow>
        sleutelExtractor={(row) => row.RelID}
        representatieFabriek={options.representatieFabriek!}
        waarde={relID}
        onWaardeChange={(x) => {
          onChange(x);
          if (props.onRelatieInformatieGedeeld !== undefined) {
            if (x !== null) {
              const relatie = relaties!.find((rel) => rel.RelID === x)!;
              props.onRelatieInformatieGedeeld(relatie);
            } else {
              props.onRelatieInformatieGedeeld(null);
            }
          }
        }}
        kolommen={kolommen}
        opties={relaties}
        options={{
          entiteitBepaler: async (id) => {
            const result = await api.v2.relatie.ophalenRelaties({
              filterSchema: {
                filters: [
                  {
                    naam: 'IDS',
                    data: [id],
                  },
                ],
              },
            });
            return relatieSelectieRelatieToRow(result.relaties[0]);
          },
          geenWaardeBericht: options.geenWaardeBericht,
          popoverComp: PopoverComp,
        }}
        isWisbaar={props.isWisbaar}
        disabled={props.disabled}
      />

      {/* {nieuweLocatieDialoogTonen && (
        <LocatieMutatieDialoog
          type={ELocatieMutatieDialoogType.Nieuw}
          open
          onSuccess={(result) => {
            onChange(result.locID);
            setNieuweLocatieDialoogTonen(false);
          }}
          onAnnuleren={() => setNieuweLocatieDialoogTonen(false)}
          dialoogIndex={1}
        />
      )}

      {wijzigenLocatieDialoogTonen !== null && (
        <LocatieMutatieDialoog
          type={ELocatieMutatieDialoogType.Wijzigen}
          open
          onSuccess={(result) => {
            onChange(result.locID);
            setWijzgenLocatieDialoogTonen(null);
          }}
          onAnnuleren={() => setWijzgenLocatieDialoogTonen(null)}
          dialoogIndex={1}
          locID={wijzigenLocatieDialoogTonen}
        />
      )} */}
    </RelatieSelectieContext.Provider>
  );
});

export default RelatieSelectie;
