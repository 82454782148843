import api from '../../../index';
import ICheckData from '../../../../../../shared/src/models/ICheckData';
import {
  IBelonenWinnaarParams,
  IBelonenWinnaarResult,
  IInstellenWinnaarParams,
  IInstellenWinnaarResult,
  IMuterenWinweekWinnaarParams,
  IOphalenNieuweKlantenParams,
  IOphalenNieuweKlantenResult,
  IOphalenWinweekWinnaarParams,
  IVersturenWinberichtParams,
  IVersturenWinberichtResult,
  IVerwijderenWinweekWinnaarParams,
  IWinweekWinnaar,
} from '../../../../../../shared/src/api/v2/klantactie/winweek';
import {
  ILeegGeneriekResult,
  IMuterenGeneriekResult,
} from '../../../../../../shared/src/api/v2/generiek';

const winweek = {
  ophalenWinnaars: async (params: IOphalenWinweekWinnaarParams): Promise<IWinweekWinnaar[]> => {
    return await api.post('/v2/klantactie/winweek/ophalen-winnaars', params);
  },
  muterenWinnaar: async (params: IMuterenWinweekWinnaarParams): Promise<IMuterenGeneriekResult> => {
    return await api.post('/v2/klantactie/winweek/muteren-winnaar', params);
  },
  verwijderenWinnaars: async (
    params: IVerwijderenWinweekWinnaarParams,
  ): Promise<ILeegGeneriekResult> => {
    return await api.post('/v2/klantactie/winweek/verwijderen-winnaars', params);
  },
  ophalenNieuweKlanten: async (
    params: IOphalenNieuweKlantenParams,
  ): Promise<IOphalenNieuweKlantenResult> => {
    return await api.post('/v2/klantactie/winweek/ophalen-nieuwe-klanten', params);
  },
  versturenWinbericht: async (
    params: IVersturenWinberichtParams,
  ): Promise<IVersturenWinberichtResult> => {
    return await api.post('/v2/klantactie/winweek/versturen-winbericht', params);
  },
  checkInstellenWinnaar: async (params: IInstellenWinnaarParams): Promise<ICheckData> => {
    return await api.post('/v2/klantactie/winweek/check-instellen-winnaar', params);
  },
  instellenWinnaar: async (params: IInstellenWinnaarParams): Promise<IInstellenWinnaarResult> => {
    return await api.post('/v2/klantactie/winweek/instellen-winnaar', params);
  },
  checkBelonenWinnaar: async (params: IBelonenWinnaarParams): Promise<ICheckData> => {
    return await api.post('/v2/klantactie/winweek/check-belonen-winnaar', params);
  },
  belonenWinnaar: async (params: IBelonenWinnaarParams): Promise<IBelonenWinnaarResult> => {
    return await api.post('/v2/klantactie/winweek/belonen-winnaar', params);
  },
};

export default winweek;
