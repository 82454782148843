import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import styled from 'styled-components';

export interface IProps {
  soort?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark';
  grootte?: string;
  dikte?: string;
  style?: React.CSSProperties;
}

interface IContainerProps {
  grootte?: string;
  dikte?: string;
}

const Container = styled.div<IContainerProps>`
  .spinner-border {
    ${(props) =>
      props.grootte === undefined
        ? ''
        : `
      width: ${props.grootte} !important;
      height: ${props.grootte} !important;
    `}
    ${(props) =>
      props.dikte === undefined
        ? ''
        : `
       border-left-width: ${props.dikte} !important;
       border-right-width: ${props.dikte} !important;
       border-top-width: ${props.dikte} !important;
       border-bottom-width: ${props.dikte} !important;
    `}
  }
`;

const LoadingSpinner = (props: IProps) => {
  return (
    <Container style={props.style} grootte={props.grootte} dikte={props.dikte}>
      <Spinner animation="border" variant={props.soort || 'primary'} />
    </Container>
  );
};

export const LoadingSpinnerCenter = (props: IProps) => {
  return (
    <div className="d-flex align-items-center justify-content-center">
      <LoadingSpinner {...props} />
    </div>
  );
};

export default LoadingSpinner;
