import styled from 'styled-components';

export const Root = styled.div`
  min-height: 21px;
  font-size: 13px;
  display: flex;
  align-items: center;
  min-width: 0;

  .ongekoppeld-container {
  }

  .gekoppeld-container {
    display: flex;
    align-items: center;
    flex: 1;

    .dropdown {
      .dropdown-toggle {
        padding: 0;
        background-color: transparent;
        border: 0;
        margin-left: 5px;
        padding-right: 5px;

        &:after {
          display: none;
        }
      }
    }
  }
`;

export const OverlayLink = styled.span`
  cursor: pointer;
  color: #4c8fff;
  &:hover {
    text-decoration: underline;
  }
`;
