import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { ModalBody, ModalFooter, ModalTitle } from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/ModalHeader';
import IDialoogProps from '../../../../core/IDialoogProps';
import Dialoog from '../../../../components/dialogen/Dialoog';
import { Field, FieldProps, Formik, FormikActions, FormikProps, yupToFormErrors } from 'formik';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import DatumKiezer from '../../../../components/formulier/DatumKiezer';
import MultiCombobox, { IKolom } from '../../../../components/formulier/MultiCombobox';
import NumeriekVeld from '../../../../components/formulier/NumeriekVeld';
import nameof from '../../../../core/nameOf';
import api from '../../../../api';
import { RootStoreContext } from '../../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import * as Yup from 'yup';
import ICheckData from '../../../../../../shared/src/models/ICheckData';
import { IOphalenMagazijnenResultElement } from '../../../../../../shared/src/api/v2/magazijn';
import { addDays, format } from 'date-fns';
import { dagDatum, isDagDatum } from '../../../../helpers/datum';
import { EResultType } from '../../../../stores/CheckStore';
import VeldWeergave from '../../../../components/formulier/VeldWeergave';
import VinkVeld from '../../../../components/formulier/VinkVeld';

export interface IDialoogResult {
  ID: number;
}

interface IProps extends IDialoogProps<IDialoogResult> {}

interface IFormikValues {
  peildatum: Date | null;
  magID: number;
  internLaden: boolean;
}
const veldnamen: Record<keyof IFormikValues, string> = {
  peildatum: 'Peildatum',
  magID: 'Magazijn',
  internLaden: 'Intern laden',
};

const ToevoegenInventarisatieDialoog: React.FC<IProps> = (props) => {
  const { dialoogIndex, onAnnuleren, onSuccess } = props;
  const { checkStore } = useContext(RootStoreContext);
  const { instellingStore } = useContext(RootStoreContext);

  const [magazijnen, setMagazijnen] = useState<IOphalenMagazijnenResultElement[] | null>(null);
  const [peildatum, setPeildatum] = useState<Date | null>(null);

  const ophalenMagazijnen = useCallback(async () => {
    const result = await api.v2.magazijn.ophalenMagazijnen({
      filterSchema: { filters: [] },
    });
    setMagazijnen(result.magazijnen);
  }, []);

  useEffect(() => {
    ophalenMagazijnen();
  }, []);

  useEffect(() => {
    (async () => {
      // Bepaal de eerst komende werkdag om voor te vullen
      const werkdagen = await api.v2.datum.ophalenWerkdagen({});
      const peildatum =
        werkdagen[0] === format(new Date(), 'yyyy-MM-dd') ? werkdagen[1] : werkdagen[0];

      setPeildatum(new Date(peildatum));
    })();
  }, []);

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (peildatum === null) {
      return null;
    }
    return {
      peildatum: dagDatum(new Date()),
      magID: instellingStore.Voorraad_MagID!,
      internLaden: true,
    };
  }, [peildatum]);

  const validationSchema = useMemo(() => {
    const fields: Record<keyof IFormikValues, any> = {
      peildatum: Yup.date().required(),
      magID: Yup.number().required(),
      internLaden: Yup.boolean().required(),
    };
    return Yup.object().shape(fields);
  }, []);

  const magazijnKolommen = useMemo<IKolom<IOphalenMagazijnenResultElement>[]>(() => {
    return [
      {
        key: 'NaamKort',
        label: 'Naam',
        breedte: 200,
      },
      {
        key: 'Plaatsnaam' as any,
        label: 'Plaats',
        breedte: 150,
      },
    ];
  }, []);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      // if (
      //   (
      //     await checkStore.bevestigen({
      //       inhoud: `Inventarisatie maken?`,
      //     })
      //   ).type === EResultType.Annuleren
      // ) {
      //   return;
      // }

      actions.setSubmitting(true);

      const params = {
        magID: values.magID,
        peildatum: new Date(values.peildatum!),
      };

      const result = await api.v2.magazijn.inventarisatie.toevoegenInventarisatie(params);
      const ID = result.ID;

      if (values.internLaden) {
        const result = await api.v2.magazijn.inventarisatie.lijst.intern.ladenInterneInventarisatie(
          { id: ID },
        );

        await api.v2.magazijn.inventarisatie.toevoegenInterneInventarisatie({ magInvID: ID });
      }

      // Gerelateerde data (zoals b.v. de uitstaande transportopdrachten) toevoegen
      await api.v2.magazijn.inventarisatie.toevoegenGerelateerdeData({ magInvID: ID });

      actions.setSubmitting(false);
      onSuccess({
        ID,
      });
    },
    [],
  );

  return (
    <Dialoog index={dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Toevoegen inventarisatie</ModalTitle>
      </ModalHeader>

      {initialValues === null || magazijnen === null ? (
        <div className="d-flex flex-fill align-items-center justify-content-center">
          <LoadingSpinner />
        </div>
      ) : (
        <Formik<IFormikValues>
          onSubmit={handleSubmit}
          initialValues={initialValues}
          isInitialValid
          validationSchema={validationSchema}
          render={(formikProps: FormikProps<IFormikValues>) => {
            const { submitForm, isSubmitting, isValid } = formikProps;
            return (
              <>
                <ModalBody>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-12 mt-2">
                        <label>{veldnamen.magID}</label>

                        <Field
                          name={nameof<IFormikValues>('magID')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            if (magazijnen === null) {
                              return <LoadingSpinner />;
                            }

                            return (
                              <MultiCombobox<number, any>
                                sleutelExtractor={(row: IOphalenMagazijnenResultElement) =>
                                  row.MagID
                                }
                                onWaardeChange={(waarde: number | null) => {
                                  fieldProps.form.setFieldValue(field.name, waarde);
                                }}
                                representatieFabriek={(row: IOphalenMagazijnenResultElement) =>
                                  `${row.NaamKort}`
                                }
                                waarde={fieldProps.field.value}
                                opties={magazijnen.map((x) => {
                                  return {
                                    MagID: x.MagID,
                                    NaamKort: x.NaamKort,
                                    Plaatsnaam: x.locatie.Plaatsnaam,
                                  };
                                })}
                                kolommen={magazijnKolommen}
                              />
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <label>{veldnamen.peildatum}</label>
                        <Field
                          name={nameof<IFormikValues>('peildatum')}
                          render={({ field, form }: FieldProps<IFormikValues>) => {
                            const minimaleDatum = addDays(new Date(), -5);
                            const maximaleDatum = addDays(new Date(), +5);
                            return (
                              <div className="d-flex ">
                                <DatumKiezer
                                  onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                  waarde={field.value}
                                  determineValidDate={(date) => {
                                    return date >= minimaleDatum && date <= maximaleDatum;
                                  }}
                                  determineNextValidDate={(date) => {
                                    const nieuweDatum = addDays(date, +1);
                                    if (nieuweDatum <= maximaleDatum) {
                                      return nieuweDatum;
                                    }
                                    return null;
                                  }}
                                  determinePreviousValidDate={(date) => {
                                    const nieuweDatum = addDays(date, -1);
                                    if (nieuweDatum >= minimaleDatum) {
                                      return nieuweDatum;
                                    }
                                    return null;
                                  }}
                                  isClearable
                                />
                              </div>
                            );
                          }}
                        />
                      </div>

                      {/* <div className="col-12 mt-4">
                        <Field
                          name={nameof<IFormikValues>('internLaden')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            return (
                              <>
                                <div className="d-flex align-items-center">
                                  <VinkVeld
                                    aangevinkt={field.value}
                                    onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                  />
                                  <span className="ml-2">Intern laden</span>
                                </div>
                              </>
                            );
                          }}
                        />
                      </div> */}
                    </div>
                  </div>
                </ModalBody>
                <ModalFooter className="d-flex flex-row justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    style={{ width: 100 }}
                    disabled={isSubmitting || !isValid}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={onAnnuleren}
                    style={{ width: 100 }}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Dialoog>
  );
};

export default ToevoegenInventarisatieDialoog;
