import api from '..';
import {
  IOphalenStandaardtaalParams,
  IOphalenStandaardtaalResult,
  IOphalenTalenParams,
  IOphalenTalenResult,
} from '../../../../shared/src/api/v2/taal';

const taal = {
  ophalen: async (params: IOphalenTalenParams) => {
    return await api.post<IOphalenTalenResult>('v2/taal/ophalen-talen', params);
  },
  // ophalenStandaardtaal: async (params: IOphalenStandaardtaalParams) => {
  //   return await api.post<IOphalenStandaardtaalResult>('v2/taal/ophalen-standaardtaal', params);
  // },
};

export default taal;
