import {
  IOphalenVerlofMutatiesParams,
  IOphalenVerlofMutatiesResult,
  IOphalenVerlofMutatieSoortenParams,
  IOphalenVerlofMutatieSoortenResult,
  IToevoegenMutatieParams,
  IToevoegenMutatieResult,
  IVerwijderenMutatiesParams,
  IVerwijderenMutatiesResult,
  IToekennenVakantiedagenParams,
  IToekennenVakantiedagenResult,
  IOverhevelenSaldoVorigJaarParams,
  IOverhevelenSaldoVorigJaarResult,
  IWijzigenVerlofmutatieParams,
  IWijzigenVerlofmutatieResult,
} from '../../../../../../shared/src/api/v2/medewerker/verlof';
import api from '../../../index';
import ICheckData from '../../../../../../shared/src/models/ICheckData';
import {
  IVersturenVerlofoverzichtParams,
  IVersturenVerlofoverzichtResult,
} from '../../../../../../shared/src/api/v2/medewerker/verlof';

const verlof = {
  ophalenVerlofMutaties: async (
    params: IOphalenVerlofMutatiesParams,
  ): Promise<IOphalenVerlofMutatiesResult> => {
    return await api.post('/v2/medewerker/verlof/ophalen-verlof-mutaties', params);
  },
  ophalenVerlofMutatieSoorten: async (
    params: IOphalenVerlofMutatieSoortenParams,
  ): Promise<IOphalenVerlofMutatieSoortenResult> => {
    return await api.post('/v2/medewerker/verlof/ophalen-verlof-mutatie-soorten', params);
  },
  checkToevoegenMutatie: async (params: IToevoegenMutatieParams): Promise<ICheckData> => {
    return await api.post('/v2/medewerker/verlof/check-toevoegen-mutatie', params);
  },
  toevoegenMutatie: async (params: IToevoegenMutatieParams): Promise<IToevoegenMutatieResult> => {
    return await api.post('/v2/medewerker/verlof/toevoegen-mutatie', params);
  },
  checkVerwijderenMutaties: async (params: IVerwijderenMutatiesParams): Promise<ICheckData> => {
    return await api.post('/v2/medewerker/verlof/check-verwijderen-mutaties', params);
  },
  verwijderenMutaties: async (
    params: IVerwijderenMutatiesParams,
  ): Promise<IVerwijderenMutatiesResult> => {
    return await api.post('/v2/medewerker/verlof/verwijderen-mutaties', params);
  },
  checkToekennenVakantiedagen: async (
    params: IToekennenVakantiedagenParams,
  ): Promise<ICheckData> => {
    return await api.post('/v2/medewerker/verlof/check-toekennen-vakantiedagen', params);
  },
  toekennenVakantiedagen: async (
    params: IToekennenVakantiedagenParams,
  ): Promise<IToekennenVakantiedagenResult> => {
    return await api.post('/v2/medewerker/verlof/toekennen-vakantiedagen', params);
  },
  checkOverhevelenSaldoVorigJaar: async (
    params: IOverhevelenSaldoVorigJaarParams,
  ): Promise<ICheckData> => {
    return await api.post('/v2/medewerker/verlof/check-overhevelen-saldo-vorig-jaar', params);
  },
  overhevelenSaldoVorigJaar: async (
    params: IOverhevelenSaldoVorigJaarParams,
  ): Promise<IOverhevelenSaldoVorigJaarResult> => {
    return await api.post('/v2/medewerker/verlof/overhevelen-saldo-vorig-jaar', params);
  },
  versturenVerlofoverzicht: async (
    params: IVersturenVerlofoverzichtParams,
  ): Promise<IVersturenVerlofoverzichtResult> => {
    return await api.post('/v2/medewerker/verlof/versturen-verlofoverzicht', params);
  },
  wijzigenVerlofmutatie: async (
    params: IWijzigenVerlofmutatieParams,
  ): Promise<IWijzigenVerlofmutatieResult> => {
    return await api.post('/v2/medewerker/verlof/wijzigen-verlofmutatie', params);
  },
};

export default verlof;
