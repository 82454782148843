import {
  DataTypeProvider,
  IntegratedSorting,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { IOphalenPersonenResultElementV2 } from '../../../../../../../shared/src/api/v2/persoon/persoon';
import { ISponsorEventPersoon } from '../../../../../../../shared/src/api/v2/sponsoringv2/event';
import api from '../../../../../api';
import { Kleur } from '../../../../../bedrijfslogica/constanten';
import LoadingSpinner from '../../../../../components/Gedeeld/LoadingSpinner';
import { IconLink, IconVerwijderen } from '../../../../../components/Icons';
import MenuLayout from '../../../../../components/MenuLayout';
import nameOf from '../../../../../core/nameOf';
import {
  DXTableCheckboxComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../../helpers/dxTableGrid';
import { EResultType } from '../../../../../stores/CheckStore';
import { RootStoreContext } from '../../../../../stores/RootStore';
import KoppelenDialoog from './KoppelenDialoog';

interface IProps {
  koppelBasisID: number;
}

interface IKoppelenDialoogState {
  koppelBasisID: number;
  relID: number;
}

const Item: React.FC<IProps> = (props) => {
  const { checkStore } = useContext(RootStoreContext);

  const [rijen, setRijen] = useState<ISponsorEventPersoon[] | null>(null);
  const [relID, setRelID] = useState<number | undefined>(undefined);
  const [koppelbareData, setKoppelbareData] = useState<IOphalenPersonenResultElementV2[] | null>(
    null,
  );
  const [koppelenDialoogState, setKoppelenDialoogState] = useState<IKoppelenDialoogState | null>(
    null,
  );
  const [selectieState, setSelectieState] = useState<number[]>([]);

  const ophalenMetadata = async () => {
    const events = await api.v2.sponsoringv2.event.ophalenEvents({
      filterSchema: {
        filters: [{ naam: 'IDS', data: [props.koppelBasisID] }],
      },
    });
    const event = events[0];

    const dienstenResult = await api.v2.dienst.sponsoring.ophalenDiensten({
      filterSchema: {
        filters: [{ naam: 'IDS', data: [event.SponDienstID] }],
      },
    });
    const dienst = dienstenResult.diensten[0];

    const relatieResult = await api.v2.relatie.ophalenRelaties({
      filterSchema: {
        filters: [{ naam: 'IDS', data: [dienst.RelID] }],
      },
    });
    const relatie = relatieResult.relaties[0];
    setRelID(relatie.organisatie?.RelID);
  };

  useEffect(() => {
    ophalenMetadata();
  }, []);

  const ophalenRijen = async () => {
    const results = await api.v2.sponsoringv2.event.ophalenPersonen({
      filterSchema: {
        filters: [{ naam: 'EVENT_IDS', data: [props.koppelBasisID] }],
      },
    });

    setRijen(results);
  };

  useEffect(() => {
    ophalenRijen();
  }, []);

  const ophalenKoppelbareData = async () => {
    if (rijen == null) return;

    const persIDs = rijen.map((r) => r.PersID);

    const result = await api.v2.persoon.ophalenPersonen({
      filterSchema: { filters: [{ naam: 'IDS', data: persIDs }] },
    });

    setKoppelbareData(result.personen);
  };

  useEffect(() => {
    ophalenKoppelbareData();
  }, [rijen]);

  const handleKoppelen = useCallback(async () => {
    setKoppelenDialoogState({ koppelBasisID: props.koppelBasisID, relID: relID! });
  }, []);

  const handleVerwijderen = useCallback(async () => {
    const params = { ids: selectieState };
    if (
      (
        await checkStore.bevestigen({
          inhoud: <span>Geselecteerde items ontkoppelen?</span>,
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    await api.v2.sponsoringv2.event.verwijderenPersonen(params);
    setSelectieState([]);

    await ophalenRijen();
  }, [selectieState]);

  const gekoppeldeData = (id: number) => {
    let result = koppelbareData?.find((d) => d.PersID == id);
    return result;
  };

  const keyExtractor = useCallback((row: ISponsorEventPersoon) => (row.ID == null ? 0 : row.ID), [
    rijen,
  ]);

  const kolommen = useMemo<TypedColumn<ISponsorEventPersoon>[]>(
    () => [
      {
        name: 'PersID',
        title: 'Persoon',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<ISponsorEventPersoon>[]>(
    () => [
      {
        columnName: 'PersID',
        width: 400,
      },
    ],
    [],
  );

  return (
    <>
      <MenuLayout
        menu={
          <div className="d-flex align-items-center">
            <span style={{ marginRight: 10 }}>Gekoppelde personen</span>
            <button
              className="btn btn-sm btn-light d-flex align-items-center"
              style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
              disabled={false}
              onClick={() => handleKoppelen()}
            >
              <IconLink style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
              <span className="ml-2">Nieuw persoon koppelen</span>
            </button>
            <button
              className="btn btn-sm btn-light d-flex align-items-center ml-3"
              style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
              disabled={selectieState.length == 0}
              onClick={() => handleVerwijderen()}
            >
              <IconVerwijderen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
              <span className="ml-2">Ontkoppelen</span>
            </button>
          </div>
        }
        body={
          <div className="d-flex flex-fill flex-column" style={{ width: '100%', height: '100%' }}>
            {rijen === null ? (
              <LoadingSpinner />
            ) : (
              <GridStyleWrapper height="200px">
                <Grid getRowId={keyExtractor} rows={rijen} columns={kolommen}>
                  <DataTypeProvider
                    for={[nameOf<ISponsorEventPersoon>('PersID')]}
                    formatterComponent={(formatterProps) => (
                      <span>
                        {`${gekoppeldeData(formatterProps.value)?.Voornaam} ${
                          gekoppeldeData(formatterProps.value)?.Achternaam
                        }`}
                      </span>
                    )}
                  />
                  <SortingState defaultSorting={[]} />
                  <IntegratedSorting />

                  <VirtualTable messages={{ noData: 'Geen items aanwezig' }} />
                  <TableColumnResizing defaultColumnWidths={kolomBreedtes} />

                  <TableHeaderRow />
                  <SelectionState
                    selection={selectieState}
                    onSelectionChange={(x) => setSelectieState(x as number[])}
                  />
                  <TableSelection cellComponent={DXTableCheckboxComponent} />
                </Grid>
              </GridStyleWrapper>
            )}
          </div>
        }
      />
      {koppelenDialoogState !== null && (
        <KoppelenDialoog
          open
          koppelBasisID={koppelenDialoogState.koppelBasisID}
          relID={koppelenDialoogState.relID}
          onSuccess={async () => {
            setKoppelenDialoogState(null);
            await ophalenRijen();
          }}
          onAnnuleren={() => setKoppelenDialoogState(null)}
        />
      )}
    </>
  );
};

export default Item;
