import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ITabbladProps, titelFunctieNietMogelijk } from '..';
import api from '../../../../api';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import useUrlState from '../../../../core/useUrlState';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import * as _ from 'lodash';
import { EResultType } from '../../../../stores/CheckStore';
import { RootStoreContext } from '../../../../stores/RootStore';
import { IFilterSchema, IFilterSchemaFilter } from '../../../../../../shared/src/models/filter';
import SelectieVak from '../../../../components/SelectieVak';
import FilterBalkV2, {
  IFilter,
  IFilterData,
  maakFilterSchema,
} from '../../../../components/FilterBalkV2';
import {
  IconDuimOmhoog,
  IconGroepWeergave,
  IconLijnWeergave,
  IconSpecialeMap,
  IconTransport,
  IconUitBedrijf,
  IconZoekgemeld,
} from '../../../../components/Icons';
import {
  IOphalenProductenResultElementV2,
  IOphalenProductstatussenResultElement,
} from '../../../../../../shared/src/api/v2/product';
import {
  IRow,
  bepaalProductrijen as ophalenProductenBasis,
  pendelregelsResult,
  heeftSelectieMetEnkelTypeEntiteit,
} from '../Context';
import WijzigenProductstatusDialoog from '../WijzigenProductstatusDialoog';
import ProductenTabel, {
  EKolomnaam,
  keyExtractor,
} from '../../../../components/magazijn/ProductenTabel';
import Combobox, { IOptie } from '../../../../components/formulier/Combobox';
import ToevoegenAanPendelDialoog from '../ToevoegenAanPendelDialoog';
import ProductstaatFilter from '../algemeneFilters/ProductstaatFilter';
import UitBedrijfDialoog from '../UitBedrijfDialoog';
import { EProductstatus, ERedenUitBedrijf } from '../../../../bedrijfslogica/enums';
import { IOphalenProducttypenResultElement } from '../../../../../../shared/src/api/v2/product/type';
import { reverseKeyExtractor } from '..';
import { IOphalenPendelregelsResultElement as IOphalenOpdrachtregelsResultElement } from '../../../../../../shared/src/api/v2/pendel';
import WeergaveKeuze from '../../../../components/WeergaveKeuze';
import useBijGewijzigdEffect from '../../../../core/useBijGewijzigdEffect';
import md5 from 'md5';
import Menu from 'react-select/src/components/Menu';
import MenuLayout from '../../../../components/MenuLayout';
import { Helmet } from 'react-helmet';
import { MagazijnKolom } from '../../../../../../shared/src/api/v2/magazijn/export';

interface IProps extends ITabbladProps, RouteComponentProps {}

export enum EFilter {
  // Doelstatus = 'DOELSTATUS_PROD_STAT_IDS',
  // NietInPendelopdracht = 'NIET_IN_PENDELOPDRACHT',
  Productstaat = 'PRODUCTSTAAT',
}
interface IUrlState {
  selectie: string[];
  filterData: IFilterData<EFilter>[];
}

const defaultUrlState: IUrlState = {
  selectie: [],
  filterData: [
    // {
    //   naam: EFilter.Doelstatus,
    //   data: [],
    //   isActief: false,
    // },
    // {
    //   naam: EFilter.NietInPendelopdracht,
    //   data: true,
    //   isActief: true,
    // },
    {
      naam: EFilter.Productstaat,
      data: 'NIEUW',
      isActief: false,
    },
  ],
};

interface IWijzigenProductstatusDialoogState {
  prodStatID: number;
  prodIDs?: number[];
  producttype?: { typeID: number; aantal: number; prodStatID: number };
}
interface IToevoegenAanPendelDialoogState {
  prodIDs: number[];
}
interface IUitBedrijfDialoogState {
  prodIDs: number[];
}

const productenGroeperen = false;
const extraWeerTeGevenkolommen: string[] = [
  EKolomnaam.ZoekGemeld,
  EKolomnaam.Statusnaam,
  EKolomnaam.RecordGewijzigd,
];

export const zoekgemeldMagazijnKolommen: MagazijnKolom[] = [
  'zoekGemeld',
  'status',
  'recordGewijzigd',
];

const Zoekgemeld: React.FC<IProps> = (props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const { checkStore, instellingStore } = useContext(RootStoreContext);

  useEffect(() => {
    const prodIDs = urlState.selectie
      .filter((x) => x.startsWith('P'))
      .map((x) => Number(x.slice(2)));

    props.onSelectieChange(prodIDs);
  }, [JSON.stringify(urlState.selectie)]);

  const [retourenProdStatID, setRetourenProdStatID] = useState<number | null>(null);

  useEffect(() => {
    (async () => {
      const statussenResult = await api.v2.product.ophalenProductstatussen({
        filterSchema: { filters: [{ naam: 'ENUMS', data: [EProductstatus.Retouren] }] },
      });
      setRetourenProdStatID(statussenResult.statussen[0].ProdStatID);
    })();
  }, []);

  const [producten, setProducten] = useState<IRow[] | null>(null);
  const [
    wijzigenProductstatusDialoogState,
    setWijzigenProductstatusDialoogState,
  ] = useState<IWijzigenProductstatusDialoogState | null>(null);
  const [pendelregels, setPendelregels] = useState<IOphalenOpdrachtregelsResultElement[] | null>(
    null,
  );
  const [
    toevoegenAanPendelDialoog,
    setToevoegenAanPendelDialoog,
  ] = useState<IToevoegenAanPendelDialoogState | null>(null);
  const [
    uitBedrijfDialoogState,
    setUitBedrijfDialoogState,
  ] = useState<IUitBedrijfDialoogState | null>(null);

  const [productstatussen, setProductstatussen] = useState<
    IOphalenProductstatussenResultElement[] | null
  >(null);

  useEffect(() => {
    (async () => {
      const statussenResult = await api.v2.product.ophalenProductstatussen({
        filterSchema: { filters: [] },
      });
      setProductstatussen(statussenResult.statussen);
    })();
  }, []);

  // Tbv filter
  const doelstatusComboboxOpties = useMemo<IOptie<number>[] | null>(() => {
    if (productstatussen === null) {
      return null;
    }
    return productstatussen.map((x) => ({
      id: x.ProdStatID,
      label: x.Naam,
    }));
  }, [productstatussen]);

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      // {
      //   naam: EFilter.Doelstatus,
      //   altijdWeergevenInBalk: true,
      //   weergave: (weergaveProps) => {
      //     const ids = weergaveProps.data as number[];
      //     const geselecteerd = ids.length === 0 ? null : ids[0];
      //     return (
      //       <div className="d-flex align-items-center">
      //         <span className="mr-2">Doelstatus:</span>
      //         {doelstatusComboboxOpties === null ? (
      //           <LoadingSpinner />
      //         ) : (
      //           <Combobox
      //             geselecteerd={geselecteerd}
      //             onSelectieChange={(x) => {
      //               const newValue = x === null ? [] : [x];
      //               weergaveProps.onDataChange(newValue);
      //               weergaveProps.toepassen();
      //             }}
      //             legeOptieTonen
      //             opties={doelstatusComboboxOpties}
      //             options={{
      //               legeOptieTekst: 'Zonder doelstatus',
      //             }}
      //           />
      //         )}
      //       </div>
      //     );
      //   },
      // },
      // {
      //   naam: EFilter.NietInPendelopdracht,
      //   altijdWeergevenInBalk: true,
      //   weergave: (weergaveProps) => {
      //     return (
      //       <div className="d-flex align-items-center">
      //         <span>Niet in een pendelopdracht</span>
      //       </div>
      //     );
      //   },
      // },
      {
        naam: EFilter.Productstaat,
        altijdWeergevenInBalk: true,
        weergave: ProductstaatFilter,
      },
    ],
    [doelstatusComboboxOpties],
  );

  const [filterSchema, setFilterSchema] = useState<IFilterSchema>(
    useMemo(() => maakFilterSchema(urlState.filterData), []),
  );
  useBijGewijzigdEffect(() => {
    setFilterSchema(maakFilterSchema(urlState.filterData));
  }, [md5(JSON.stringify(urlState.filterData))]);

  const ophalenProducten = useCallback(async () => {
    if (retourenProdStatID === null) {
      return;
    }
    const filterSchemaBasis = {
      filters: [
        ...filterSchema.filters!,
        props.magID === null ? null : { naam: 'MAG_IDS', data: [props.magID] },
        { naam: 'IS_ZOEK', data: true },
        { naam: 'IS_UITBEDRIJF', data: false },
        // { naam: 'IS_NIEUW', data: false },
      ].filter((x) => x !== null) as IFilterSchemaFilter[],
    };
    const producten = await ophalenProductenBasis({
      filterSchema: filterSchemaBasis,
      // magID: props.magID!,
      ingepaktGroeperen: productenGroeperen,
    });

    // const productenGesorteerd = _.orderBy(
    //   producten,
    //   [
    //     (x: IRow) => {
    //       return !x.zonderReferentie || !productenGroeperen
    //         ? (x.uitgepakt as IOphalenProductResult).Merknaam
    //         : (x.uitgepakt as IOphalenProducttypenResultElement).Merknaam;
    //     },
    //     (x: IRow) => {
    //       return !x.zonderReferentie || !productenGroeperen
    //         ? (x.uitgepakt as IOphalenProductResult).Typenaam
    //         : (x.uitgepakt as IOphalenProducttypenResultElement).Typenaam;
    //     },
    //     (x: IRow) => {
    //       return !x.zonderReferentie || !productenGroeperen
    //         ? (x.uitgepakt as IOphalenProductResult).Referentiecode
    //         : null;
    //     },
    //   ],
    //   ['asc', 'asc', 'asc'],
    // );

    const pendelregels = !productenGroeperen
      ? (
          await pendelregelsResult(
            producten
              .filter((x) => x.uitgepakt !== undefined)
              .map((x) => (x.uitgepakt as IOphalenProductenResultElementV2).ProdID),
          )
        ).regels
      : null;
    setPendelregels(pendelregels);

    setProducten(producten);
  }, [props.magID, retourenProdStatID, filterSchema]);

  useEffect(() => {
    ophalenProducten();
  }, [ophalenProducten]);

  const bepaalRetourstatusProdStatID = useCallback(() => {
    if (producten === null) {
      return null;
    }

    const keys = urlState.selectie.map(reverseKeyExtractor);
    const prodIDs = keys.filter((x) => x.prodID !== undefined).map((x) => x.prodID) as number[];
    const typeIDs = keys.filter((x) => x.typeID !== undefined).map((x) => x.typeID) as number[];

    if (typeIDs.length !== 0) {
      return null;
    }

    const prodStatIDs = producten
      .filter(
        (x) => prodIDs.indexOf((x.uitgepakt as IOphalenProductenResultElementV2).ProdID) !== -1,
      )
      .map((x) => (x.uitgepakt as IOphalenProductenResultElementV2).retourstatus!.ProdStatID);

    const uniqueProdStatIDs = _.uniq(prodStatIDs);
    if (uniqueProdStatIDs.length === 1) {
      // Er bestaat een eenduidig prodStatID over de gehele selectie
      return uniqueProdStatIDs[0];
    } else {
      return null;
    }
  }, [producten, urlState.selectie, retourenProdStatID]);

  if (productstatussen === null || producten === null) {
    return (
      <div className="flex-fill d-flex align-items-center justify-content-center p-4">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Zoek</title>
      </Helmet>
      <MenuLayout
        menu={
          <>
            <div className="d-flex align-items-center">
              <button
                className="btn btn-sm btn-light d-flex align-items-center"
                style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                disabled={urlState.selectie.length === 0}
                onClick={async () => {
                  const keys = urlState.selectie.map(reverseKeyExtractor);
                  const prodIDs = keys
                    .filter((x) => x.prodID !== undefined)
                    .map((x) => x.prodID) as number[];
                  const typeIDs = keys
                    .filter((x) => x.typeID !== undefined)
                    .map((x) => x.typeID) as number[];

                  if (typeIDs.length !== 0) {
                    await checkStore.melden({
                      titel: titelFunctieNietMogelijk,
                    });
                    return;
                  }

                  setUitBedrijfDialoogState({ prodIDs });
                }}
              >
                <IconUitBedrijf style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                <span className="ml-2">Uit bedrijf</span>
              </button>

              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-3"
                style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                disabled={urlState.selectie.length === 0}
                onClick={async () => {
                  const keys = urlState.selectie.map(reverseKeyExtractor);
                  const prodIDs = keys
                    .filter((x) => x.prodID !== undefined)
                    .map((x) => x.prodID) as number[];
                  const typeIDs = keys
                    .filter((x) => x.typeID !== undefined)
                    .map((x) => x.typeID) as number[];

                  if (typeIDs.length !== 0) {
                    await checkStore.melden({
                      titel: titelFunctieNietMogelijk,
                    });
                    return;
                  }

                  if (
                    (
                      await checkStore.bevestigen({
                        inhoud: 'Geselecteerde producten als gevonden aanmerken?',
                      })
                    ).type === EResultType.Annuleren
                  ) {
                    return;
                  }

                  if (prodIDs.length !== 0) {
                    await api.v2.retourverwerking.gevondenMeldenProducten({
                      magID: props.magID!,
                      prodIDs,
                    });
                    setUrlStateSync('selectie', []);
                    ophalenProducten();
                  }
                  return;
                }}
              >
                <IconDuimOmhoog style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                <span className="ml-2">Gevonden</span>
              </button>

              <div className="d-flex flex-fill ml-3">
                <FilterBalkV2
                  filters={filters}
                  filterData={urlState.filterData}
                  onFilterDataChange={(x) => setUrlStateSync('filterData', x)}
                  // onFilterSchemaChange={(x) => setFilterSchema(x)}
                />
              </div>
            </div>

            <div className="mt-2">
              <>
                <SelectieVak
                  totaalAantal={producten.length}
                  aantal={urlState.selectie.length}
                  entiteitEnkelvoud="product"
                  entiteitMeervoud="producten"
                  onChange={(allesGeselecteerd) => {
                    if (allesGeselecteerd) {
                      setUrlStateSync('selectie', producten.map(keyExtractor));
                    } else {
                      setUrlStateSync('selectie', []);
                    }
                  }}
                />
              </>
            </div>
          </>
        }
        body={
          <>
            <ProductenTabel
              producten={producten}
              selectie={urlState.selectie}
              onSelectieChange={(value: string[]) => setUrlStateSync('selectie', value)}
              onRequestRefresh={() => ophalenProducten()}
              pendelregels={pendelregels !== null ? pendelregels : undefined}
              extraWeerTeGevenkolommen={extraWeerTeGevenkolommen}
              keyExtractorMetTypeGebruiken={productenGroeperen}
              zoekgemeldNietKleuren
            />
          </>
        }
      />

      {wijzigenProductstatusDialoogState !== null && (
        <WijzigenProductstatusDialoog
          open
          magID={props.magID!}
          prodIDs={
            wijzigenProductstatusDialoogState.prodIDs !== undefined
              ? wijzigenProductstatusDialoogState.prodIDs
              : undefined
          }
          producttype={
            wijzigenProductstatusDialoogState.producttype !== undefined
              ? {
                  typeID: wijzigenProductstatusDialoogState.producttype.typeID,
                  aantal: wijzigenProductstatusDialoogState.producttype.aantal,
                  prodStatID: wijzigenProductstatusDialoogState.producttype.prodStatID,
                }
              : undefined
          }
          onSuccess={async () => {
            setUrlStateSync('selectie', []);
            setWijzigenProductstatusDialoogState(null);
            await ophalenProducten();
          }}
          onAnnuleren={() => {
            setWijzigenProductstatusDialoogState(null);
          }}
          naamEnums={[
            EProductstatus.Retouren,
            EProductstatus.Revisie,
            EProductstatus.Afvoer,
            EProductstatus.RetLev,
            EProductstatus.Parkeren,
            EProductstatus.Voorraad,
          ]}
          initieelProdStatID={wijzigenProductstatusDialoogState.prodStatID}
        />
      )}

      {toevoegenAanPendelDialoog !== null && (
        <ToevoegenAanPendelDialoog
          open
          prodIDs={toevoegenAanPendelDialoog.prodIDs}
          initieelLadenMagID={props.magID!}
          initieelLossenMagID={instellingStore.Revisie_MagID}
          initieelDienstID={null}
          onSuccess={() => {
            ophalenProducten();
            setToevoegenAanPendelDialoog(null);
          }}
          onAnnuleren={() => {
            setToevoegenAanPendelDialoog(null);
          }}
        />
      )}

      {uitBedrijfDialoogState !== null && (
        <UitBedrijfDialoog
          open
          magID={props.magID!}
          prodIDs={uitBedrijfDialoogState.prodIDs}
          redenUB_NaamEnum={ERedenUitBedrijf.Zoekgeraakt}
          onSuccess={() => {
            setUitBedrijfDialoogState(null);
            setUrlStateSync('selectie', []);
            ophalenProducten();
          }}
          onAnnuleren={() => {
            setUitBedrijfDialoogState(null);
          }}
        />
      )}
    </>
  );
};

export default withRouter(Zoekgemeld);
