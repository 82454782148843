import React, { useContext, useMemo } from 'react';
import { IProps } from '@tinymce/tinymce-react/lib/cjs/main/ts/components/Editor';
import { Field, FieldProps } from 'formik';
import VinkVeld from '../../../../../components/formulier/VinkVeld';
import { IFormikValues, veldnamen } from '../index';
import nameof from '../../../../../core/nameOf';
import FormikVeldFout from '../../../../../components/formulier/FormikVeldFout';
import Combobox from '../../../../../components/formulier/Combobox';
import OpdrachtContext from '../Context/OpdrachtContext';
import ContactSelectie, { EType } from '../../../../../components/formulier/ContactSelectie';
import MultiCombobox, { IKolom } from '../../../../../components/formulier/MultiCombobox';
import LoadingSpinner from '../../../../../components/Gedeeld/LoadingSpinner';
import TelefoonnummerInput from '../../../../../components/formulier/TelefoonnummerInput';
import nameOf from '../../../../../core/nameOf';
import { IOphalenDienstenResultElement } from '../../../../../../../shared/src/api/v2/dienst/service';

const OpdrachtTab: React.FC<IProps> = (props) => {
  const { opdrachtstatussen, opdracht, servicediensten } = useContext(OpdrachtContext);

  const servicedienstenKolommen = useMemo<IKolom<IOphalenDienstenResultElement>[]>(
    () => [
      {
        key: 'Naam',
        label: 'Naam',
        breedte: 150,
      },
    ],
    [],
  );

  return (
    <>
      <div className="p-3">
        <div className="form-group">
          <div className="row mb-3">
            <div className="col-12">
              <label>{veldnamen.omschrijving}</label>
              <Field
                name={nameof<IFormikValues>('omschrijving')}
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;

                  return (
                    <>
                      <textarea {...field} className="form-control" rows={4} />
                      <FormikVeldFout fieldProps={fieldProps} />
                    </>
                  );
                }}
              />
            </div>

            <div className="col-12 mt-3">
              <div>
                <label>{veldnamen.persID}</label>
                <Field
                  name={nameof<IFormikValues>('persID')}
                  render={(fieldProps: FieldProps<IFormikValues>) => {
                    const { field, form } = fieldProps;

                    return (
                      <ContactSelectie
                        alleenVoorRelIDs={
                          opdracht!.melding.RelID !== null ? [opdracht!.melding.RelID] : undefined
                        }
                        value={
                          field.value === null
                            ? null
                            : {
                                persID: field.value,
                                orgID: null,
                              }
                        }
                        onChange={async (x) => {
                          const persID = x?.persoon?.PersID ?? null;
                          form.setFieldValue(field.name, persID);

                          if (x === null) {
                            form.setFieldValue(nameof<IFormikValues>('telefoon'), '');
                            form.setFieldValue(nameof<IFormikValues>('email'), '');
                            return;
                          }

                          const persoon = x.persoon!;

                          form.setFieldValue(
                            nameof<IFormikValues>('telefoon'),
                            persoon.TelefoonMobiel || '',
                          );
                          form.setFieldValue(nameof<IFormikValues>('email'), persoon.Email || '');
                        }}
                        options={{
                          types: [EType.Persoon],
                        }}
                        isWisbaar
                      />
                    );
                  }}
                />
              </div>
            </div>

            <div className="col-12 mt-2">
              <label>{veldnamen.telefoon}</label>
              <Field
                name={nameof<IFormikValues>('telefoon')}
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;

                  return (
                    <TelefoonnummerInput
                      telefoonnummer={field.value}
                      onChange={(x) => form.setFieldValue(field.name, x)}
                      // suggestiesProvider={
                      //   telefoonnummerInputSuggestiesProvider
                      // }
                    />
                  );

                  // return <input {...field} className="form-control" type="text" />;
                }}
              />
            </div>

            <div className="col-12 mt-2">
              <label>{veldnamen.email}</label>
              <Field
                name={nameof<IFormikValues>('email')}
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;

                  return <input {...field} className="form-control" type="text" />;
                }}
              />
            </div>

            <div className="col-12 mt-3 d-flex align-items-center">
              <Field
                name={nameof<IFormikValues>('garantieclaim')}
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;
                  return (
                    <VinkVeld
                      aangevinkt={field.value}
                      onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                    />
                  );
                }}
              />
              <span className="ml-2">{veldnamen.garantieclaim}</span>
            </div>

            <div className="col-12 mt-3 d-flex align-items-center">
              <Field
                name={nameof<IFormikValues>('spoed')}
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;
                  return (
                    <VinkVeld
                      aangevinkt={field.value}
                      onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                    />
                  );
                }}
              />
              <span className="ml-2">{veldnamen.spoed}</span>
            </div>

            <div className="col-12 mt-3">
              <div className="d-flex justify-content-between align-items-center">
                <label>{veldnamen.uitvoerendeServicedienstID}</label>
              </div>
              <Field
                name={nameOf<IFormikValues>('uitvoerendeServicedienstID')}
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;

                  if (servicediensten === null) {
                    return <LoadingSpinner />;
                  }
                  return (
                    <MultiCombobox<number, any>
                      sleutelExtractor={(x) => x.ID}
                      representatieFabriek={(x) => `${x.Naam}`}
                      waarde={field.value}
                      onWaardeChange={(x) => form.setFieldValue(field.name, x)}
                      // opties={props.servicediensten}
                      kolommen={servicedienstenKolommen}
                      opties={servicediensten.map((x) => {
                        return { ID: x.ID, Naam: x.relatie!.weergavenaam };
                      })}
                      options={{ geenWaardeBericht: 'Kies een servicedienst' }}
                      isWisbaar={true}
                    />
                  );
                }}
              />
            </div>

            <div className="col-12 mt-3">
              <label>{veldnamen.status}</label>
              <Field
                name={nameof<IFormikValues>('status')}
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;

                  if (opdrachtstatussen === null) {
                    return;
                  }

                  return (
                    <>
                      <Combobox
                        geselecteerd={field.value}
                        opties={opdrachtstatussen.map((x) => {
                          return { id: x.Status, label: x.Naam };
                        })}
                        onSelectieChange={(x) => form.setFieldValue(field.name, x)}
                      />
                    </>
                  );
                }}
              />
            </div>

            {/* <div className="col-12 mt-3">
              <div className="d-flex justify-content-between align-items-center">
                <label>{veldnamen.uitvoerendeServicedienstID}</label>
                <div className="d-flex">
                  <Field
                    name={veldnamen.meerDiensten}
                    render={(fieldProps: FieldProps<IFormikValues>) => (
                      <>
                        <VinkVeld
                          aangevinkt={fieldProps.field.value}
                          onGewijzigd={async (x) => {
                            fieldProps.form.setFieldValue(fieldProps.field.name, x);
                            setMeerDiensten(x);
                          }}
                        />
                        <div className="ml-2">{veldnamen.meerDiensten}</div>
                        <FormikVeldFout fieldProps={fieldProps} directTonen={hasSubmitted} />
                      </>
                    )}
                  />
                </div>
              </div>
              <Field
                name={nameof<IFormikValues>('uitvoerendeServicedienstID')}
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;

                  if (servicediensten === null) {
                    return <LoadingSpinner />;
                  }
                  return (
                    <MultiCombobox<number, IOphalenServicedienstenResultElement>
                      sleutelExtractor={(x) => x.ID}
                      representatieFabriek={(x) => `${x.relatie!.weergavenaam}`}
                      waarde={field.value}
                      onWaardeChange={(x) => form.setFieldValue(field.name, x)}
                      opties={servicediensten}
                      kolommen={servicedienstenKolommen}
                    />
                  );
                }}
              />
            </div> */}

            <div className="col-12 mt-3 d-flex align-items-center">
              <Field
                name={nameof<IFormikValues>('afgehandeld')}
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;
                  return (
                    <VinkVeld
                      aangevinkt={field.value}
                      onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                    />
                  );
                }}
              />
              <span className="ml-2">{veldnamen.afgehandeld}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OpdrachtTab;
