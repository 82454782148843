import React from 'react';
import styled from 'styled-components';
import { Kleur } from '../../../../../../bedrijfslogica/constanten';

const Root = styled.div<{ height?: number }>`
  height: ${(props) => props.height ?? 150}px;
  background-color: ${Kleur.HeelLichtGrijs};
  border: 2px solid ${Kleur.LichtGrijs};
  border-radius: 4px;
  transition: border 150ms ease-in-out, background-color 150ms ease-in-out;
  padding: 10px 15px;
  display: flex;
  flex-direction: column;

  &.geselecteerd {
    border: 2px solid ${Kleur.SelectieBlauw};
    background-color: #eaf2fa;
  }
`;

interface IProps {
  isGeselecteerd: boolean;
  onGeselecteerd: () => void;
  height?: number;
}

const RegelBasis: React.FC<IProps> = (props) => {
  return (
    <Root
      className={props.isGeselecteerd ? 'geselecteerd' : ''}
      onClick={() => props.onGeselecteerd()}
      height={props.height}
    >
      {props.children}
    </Root>
  );
};

export default RegelBasis;
