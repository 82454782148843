import React, { useCallback, useState } from 'react';
import IDialoogProps from '../../core/IDialoogProps';
import BestandDragAndDropZone, {
  EBestandDragAndDropZoneBronModus,
  EBestandDragAndDropZoneSelectieModus,
  IBestandDragAndZoneBestand,
  IUploadVoortgangVanLokaleBestanden,
} from '../BestandDragAndDropZone';
import Dialoog from '../dialogen/Dialoog';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import api from '../../api';
import { IOphalenBestandenResultElement } from '../../../../shared/src/api/v2/bestand/bestand';

export interface IBestandenDragAndDropDialoogResult {
  bestanden: IOphalenBestandenResultElement[];
}

interface IProps extends IDialoogProps<IBestandenDragAndDropDialoogResult> {
  selectieModus?: EBestandDragAndDropZoneSelectieModus;
  bronModus?: EBestandDragAndDropZoneBronModus;
  toegestaneBestandstypen?: {
    weergaveNaam: string;
    mediaType: string;
  }[];
}

const BestandenDragAndDropDialoog = (props: IProps) => {
  const [bestanden, setBestanden] = useState<IBestandDragAndZoneBestand[]>([]);
  const [uploaden, setUploaden] = useState(false);
  const [uploadProgresses, setUploadProgresses] = useState<IUploadVoortgangVanLokaleBestanden>({});

  const handleOkClick = useCallback(async () => {
    setUploaden(true);

    const results: IOphalenBestandenResultElement[] = await Promise.all(
      bestanden.map(async (bestand) => {
        if (bestand.lokaal !== undefined) {
          const lokaalBestand = bestand.lokaal!;
          return await api.v2.bestand.upload(
            {
              naam: lokaalBestand.naam,
              mediaType: lokaalBestand.mediaType,
              url: lokaalBestand.url,
              file: lokaalBestand.file,
              grootte: lokaalBestand.grootte,
            },
            (percentage) => {
              setUploadProgresses({
                ...uploadProgresses,
                [lokaalBestand.url]: percentage,
              });
            },
          );
        } else {
          return bestand.aspDrive!;
        }
      }),
    );

    await props.onSuccess({
      bestanden: results,
    });
    setUploaden(false);
  }, [props.onSuccess, setUploaden, bestanden, setUploadProgresses, uploadProgresses]);

  return (
    <Dialoog index={props.dialoogIndex || 0}>
      <ModalBody>
        <BestandDragAndDropZone
          selectieModus={
            props.selectieModus !== undefined
              ? props.selectieModus
              : EBestandDragAndDropZoneSelectieModus.Meerdere
          }
          bronModus={
            props.bronModus !== undefined ? props.bronModus : EBestandDragAndDropZoneBronModus.Alle
          }
          toegestaneBestandstypes={props.toegestaneBestandstypen}
          onBestandenChange={(value) => {
            setBestanden(value);
          }}
          bestanden={bestanden}
          uploadVoortgangen={uploadProgresses}
        />
      </ModalBody>
      <ModalFooter className="d-flex flex-row justify-content-start">
        <button
          className="btn btn-primary"
          onClick={handleOkClick}
          style={{ width: 100 }}
          disabled={uploaden || bestanden.length === 0}
        >
          Ok
        </button>
        <button
          className="btn btn-secondary"
          onClick={props.onAnnuleren}
          style={{ width: 100 }}
          disabled={uploaden}
        >
          Annuleren
        </button>
      </ModalFooter>
    </Dialoog>
  );
};

export default BestandenDragAndDropDialoog;
