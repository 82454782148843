import * as React from 'react';
import TegelBasis from '../../../../../../../components/tegel/TegelBasis';
import NavigatiePijl from '../NavigatiePijl';
import { IOphalenInformatieVoorOverzichtAccount } from '../../../../../../../../../shared/src/api/v2/relatie';
import { Kleur as EKleur } from '../../../../../../../bedrijfslogica/constanten';

interface IProps {
  relID: number;
  data: IOphalenInformatieVoorOverzichtAccount;
}

const Accounttegel = (props: IProps) => {
  return (
    <TegelBasis titel="Account">
      <div>
        <div className="d-flex align-items-center">
          {/* <span>Account {props.data.gebruikersnaam} dient nog te worden geactiveerd</span> */}
          <span style={{ color: EKleur.Rood }}>Account dient nog geactiveerd te worden</span>
          <span className="ml-2">
            <NavigatiePijl relID={props.relID} pathWithStateBuilder={() => 'accounts'} />
          </span>
        </div>
      </div>
    </TegelBasis>
  );
};

export default Accounttegel;
