import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { observer } from 'mobx-react-lite';
import MenuLayout from '../../../../components/MenuLayout';
import useUrlState from '../../../../core/useUrlState';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import api from '../../../../api';
import ASPTabel from '../../../../components/tabel/ASPTabel';
import {
  ASPKolom,
  EAspKolomBreedteType,
  ESortering,
  IAspKolomSorteringItem,
} from '../../../../components/tabel/ASPTabel/types';
import { IOphalenDagboekenResultElement } from '../../../../../../shared/src/api/v2/boekhouding/boeking/dagboek';
import { IOphalenGrootboekenResultElement } from '../../../../../../shared/src/api/v2/boekhouding/boeking/grootboek';
import _ from 'lodash';
import { queryByDisplayValue } from '@testing-library/react';
import { Helmet } from 'react-helmet';

enum EKolom {
  Nummer,
  Naam,
  NaamKort,
  Boeknummer,
  Dagboeksoort,
  TegenRekening,
}

export interface IExporterenDialoogState {}

export interface IUrlState {
  // exporterenDialoogState: IExporterenDialoogState | null;
  selectie: number[];
  sortering: IAspKolomSorteringItem<EKolom>[];
}

interface IProps extends RouteComponentProps {}

interface IDagboek extends IOphalenDagboekenResultElement {
  grootboekrekening: IOphalenGrootboekenResultElement | null;
}

const Dagboeken: React.FC<IProps> = observer((props) => {
  const defaultUrlState = useMemo<IUrlState>(
    () => ({
      exporterenDialoogState: null,
      selectie: [],
      sortering: [
        {
          key: EKolom.Nummer,
          sortering: ESortering.Ascending,
        },
      ],
    }),
    [],
  );
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);

  const [dagboeken, setDagboeken] = useState<IDagboek[] | null>(null);

  const ophalenDagboeken = useCallback(async () => {
    const result = await api.v2.boekhouding.boeking.dagboek.ophalenDagboeken({
      filterSchema: {
        filters: [],
      },
    });

    const grbRekIDs = _.uniq(
      result.dagboeken
        .filter((x) => x.Tegenrekening_GrbRekID !== null)
        .map((x) => x.Tegenrekening_GrbRekID),
    );
    const grootboekrekeningen = await (
      await api.v2.boekhouding.boeking.grootboek.ophalenGrootboeken({})
    ).grootboeken;

    const dagboeken = result.dagboeken.map((x) => {
      const grootboekrekening =
        x.Tegenrekening_GrbRekID !== null
          ? grootboekrekeningen.find((g) => g.ID === x.Tegenrekening_GrbRekID)!
          : null;
      return { ...x, grootboekrekening };
    });

    setDagboeken(dagboeken);
  }, []);

  useEffect(() => {
    ophalenDagboeken();
  }, [ophalenDagboeken]);

  const keyExtractor = useCallback((item: IDagboek) => item.ID, []);

  const kolommen = useMemo<ASPKolom<EKolom, IDagboek>[]>(
    () => [
      {
        key: EKolom.Nummer,
        label: 'Nummer',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 90,
        renderer: (item: IDagboek) => item.Nummer,
      },
      {
        key: EKolom.Naam,
        label: 'Naam',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 250,
        renderer: (item: IDagboek) => item.Naam,
      },
      {
        key: EKolom.NaamKort,
        label: 'Korte naam',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 160,
        renderer: (item: IDagboek) => item.NaamKort,
      },
      {
        key: EKolom.Boeknummer,
        label: 'Boeknummer',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 110,
        renderer: (item: IDagboek) => item.Boeknummer,
      },
      {
        key: EKolom.Dagboeksoort,
        label: 'Soort',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 125,
        renderer: (item: IDagboek) => item.dagboekSoort.Naam,
      },
      {
        key: EKolom.TegenRekening,
        label: 'Tegenrekening',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 275,
        renderer: (item: IDagboek) =>
          item.grootboekrekening != null
            ? item.grootboekrekening.Nummer + ' - ' + item.grootboekrekening.Naam
            : '',
      },
    ],
    [],
  );

  return (
    <>
      <Helmet>
        <title>Dagboeken</title>
      </Helmet>
      <MenuLayout
        body={
          <div className="d-flex flex-column flex-fill">
            {dagboeken === null ? (
              <div className="flex-fill d-flex align-items-center justify-content-center">
                <LoadingSpinner />
              </div>
            ) : (
              <div className="d-flex flex-column flex-fill">
                <ASPTabel
                  rijen={dagboeken}
                  kolommen={kolommen}
                  keyExtractor={keyExtractor}
                  selectie={urlState.selectie}
                  onSelectieChange={(selectie) => setUrlStateSync('selectie', selectie)}
                  sortering={urlState.sortering}
                  onSorteringChange={(sortering) => setUrlStateSync('sortering', sortering)}
                  onWijzigenRij={async (grootboek) => {
                    alert('Nog implementeren');
                  }}
                  onVerwijderenRij={async (grootboek) => {
                    alert('Nog implementeren');
                    // await new Promise((resolve) => setTimeout(resolve, 3000));
                  }}
                  lokaalSorteren
                />
              </div>
            )}
          </div>
        }
      />
    </>
  );
});

export default withRouter(Dagboeken);
