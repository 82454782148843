import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';
import api from '../../../api';
import { formatteerOrganisatieNaam, formatteerPersoonNaam } from '../../../helpers';
import { IconOrganisatie, IconPersoon, IconToevoegen } from '../../Icons';
import { Kleur } from '../../../bedrijfslogica/constanten';
import VerticaleScheidingslijn from '../../layout/VerticaleScheidingslijn';
import VinkVeld from '../VinkVeld';
import PersoonSelectieDialoog, { ETabblad } from '../../dialogen/PersoonSelectieDialoog';
import PersoonWijzigenDialoog from '../../dialogen/PersoonWijzigenDialoog';
import { IFilterSchemaFilter } from '../../../../../shared/src/models/filter';
import { IOphalenContactenResultElement } from '../../../../../shared/src/api/v2/relatie';
import MultiComboboxV2, {
  EnkeleProvider,
  IOverlayContainerProps,
  IOverlayOptions,
  IRepresentatieProps,
  Provider,
} from '../MultiComboboxV2';
import { ASPKolom, EAspKolomBreedteType } from '../../tabel/ASPTabel/types';
import Zoeken from '../Zoeken';
import useBijGewijzigdEffect from '../../../core/useBijGewijzigdEffect';
import { ERemoteDataState } from '../../../models/IRemoteData';
import { IFormValues } from '../PersoonFormulier';

export enum EType {
  Persoon = 'P',
  Organisatie = 'O',
}

export interface IValue {
  persID: number | null;
  orgID: number | null;
}

interface IProps {
  value: IValue | null;
  onChange: (value: IOphalenContactenResultElement | null) => void;
  /**
   * Als het relID is opgegeven dan wordt er de mogelijkheid gegeven om alleen personen/organisaties weer te geven
   * die bij deze relatie horen, voor het eenvoudig selecteren van een persoon/organisatie
   */
  alleenVoorRelIDs?: number[];
  options?: IOptions;
  isWisbaar?: boolean;
  disabled?: boolean;
}

interface IOptions {
  types?: EType[];
  bijEnkeleDirectVoorselecteren?: boolean;
  persoonSelectieDialoogDefaultFormValues?: Partial<IFormValues>;
  // voorselecterenMetTekenbevoegde?: boolean;
  // voorselecterenMetPrimairePersoon?: boolean;
}

const defaultOptions: IOptions = {
  types: [EType.Persoon, EType.Organisatie],
  bijEnkeleDirectVoorselecteren: false,
  persoonSelectieDialoogDefaultFormValues: undefined,
  // voorselecterenMetTekenbevoegde: false,
  // voorselecterenMetPrimairePersoon: false,
};

interface IContactSelectieContext {
  alleenVanRelIDs?: number[];
  onToevoegenPersoonClick: () => void;
  onToevoegenOrganisatieClick: () => void;
  options: IOptions;
}
const ContactSelectieContext = React.createContext<IContactSelectieContext>(null as any);

const matchValueWithContact = (value: IValue, el: IOphalenContactenResultElement): boolean => {
  if (value === null) {
    return false;
  }
  if (value.persID !== null && el.persoon !== null) {
    return value.persID === el.persoon.PersID;
  }
  if (value.orgID !== null && el.organisatie !== null) {
    return value.orgID === el.organisatie.OrgID;
  }

  return false;
};

const contactenResultElementToId = (el: IOphalenContactenResultElement): string => {
  if (el.persoon !== null) {
    return `P${el.persoon.PersID}`;
  } else {
    return `O${el.organisatie!.OrgID}`;
  }
};
const valueToId = (value: IValue): string => {
  if (value.persID !== null) {
    return `P${value.persID}`;
  } else {
    return `O${value.orgID}`;
  }
};
const idToValue = (id: string): IValue => {
  const type = id[0];
  const idNummer = Number(id.substring(1));

  if (type === 'P') {
    return {
      persID: idNummer,
      orgID: null,
    };
  } else {
    return {
      persID: null,
      orgID: idNummer,
    };
  }
};

const enkeleProvider: EnkeleProvider<string, IOphalenContactenResultElement> = async (id) => {
  const value = idToValue(id);
  const result = await api.v2.relatie.ophalenContacten({
    filterSchema: {
      filters: [
        {
          naam: 'VALUES',
          data: [value],
        },
      ],
    },
  });
  return result.contacten[0];
};

enum EKolom {
  Type,
  Naam,
  Email,
  Telefoon,
}

interface IOverlayContainerState {
  zoekterm: string;
  alleenVanRelatieTonen: boolean;
}

const OverlayContainer = (
  overlayProps: IOverlayContainerProps<
    IOverlayContainerState,
    IOphalenContactenResultElement,
    string
  >,
) => {
  const {
    alleenVanRelIDs,
    options,
    onToevoegenPersoonClick,
    onToevoegenOrganisatieClick,
  } = useContext(ContactSelectieContext);
  const rijbronHerbepalenDebounce = useRef<NodeJS.Timeout | null>(null);

  useBijGewijzigdEffect(() => {
    if (rijbronHerbepalenDebounce.current !== null) {
      clearTimeout(rijbronHerbepalenDebounce.current);
      rijbronHerbepalenDebounce.current = null;
    }

    rijbronHerbepalenDebounce.current = setTimeout(async () => {
      await overlayProps.rijbronHerbepalen();
    }, 500);
  }, [overlayProps.state.zoekterm]);

  useBijGewijzigdEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    overlayProps.rijbronHerbepalen();
  }, [overlayProps.state.alleenVanRelatieTonen]);

  return (
    <div className="d-flex flex-fill flex-column">
      <div
        className="d-flex align-items-center p-2"
        style={{ borderBottom: `1px solid ${Kleur.LichtGrijs}` }}
        onClick={(ev) => ev.stopPropagation()}
      >
        <Zoeken
          input={overlayProps.state.zoekterm}
          onInputChange={(value) =>
            overlayProps.onStateChange((prev) => ({
              ...prev,
              zoekterm: value,
            }))
          }
          placeholder="Zoeken..."
          rootStyle={{ flex: 1 }}
          autoFocus
        />

        {alleenVanRelIDs !== undefined && (
          <>
            <VerticaleScheidingslijn className="ml-3 mr-2" height={25} />
            <div className="d-flex align-items-center">
              <VinkVeld
                aangevinkt={overlayProps.state.alleenVanRelatieTonen}
                onGewijzigd={(x) =>
                  overlayProps.onStateChange((prev) => ({
                    ...prev,
                    alleenVanRelatieTonen: x,
                  }))
                }
              />
              {/*{alleenVanRelIDs.length === 1 ? (*/}
              <span className="ml-1" style={{ minWidth: 'max-content' }}>
                Alleen van relatie tonen
              </span>
            </div>
          </>
        )}

        <VerticaleScheidingslijn className="ml-3 mr-3" height={25} />

        {options.types!.findIndex((x) => x === EType.Persoon) !== -1 && (
          <button
            className="btn btn-light btn-sm d-flex align-items-center"
            style={{
              border: `1px solid ${Kleur.LichtGrijs}`,
            }}
            onClick={() => {
              onToevoegenPersoonClick();
              document.body.click();
            }}
          >
            <IconPersoon style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
            <IconToevoegen
              style={{
                width: 11,
                height: 11,
                fill: Kleur.Grijs,

                marginRight: -8,
                top: -2,
                right: 5,
                position: 'relative',
              }}
            />
            <span className="ml-2">Opvoeren</span>
          </button>
        )}

        {options.types!.length > 1 && <div className="p-1" />}

        {options.types!.findIndex((x) => x === EType.Organisatie) !== -1 && (
          <button
            className="btn btn-primary btn-sm d-flex"
            onClick={() => {
              onToevoegenOrganisatieClick();
              document.body.click();
            }}
          >
            <IconOrganisatie style={{ width: 20, height: 20, fill: 'white' }} />
            <IconToevoegen style={{ width: 16, height: 16, fill: 'white' }} />
          </button>
        )}
      </div>
      <div className="flex-fill w-100 h-100 d-flex flex-column">
        {overlayProps.data.state === ERemoteDataState.Pending ||
        overlayProps.data.data!.totaalAantal < 250 ? (
          overlayProps.children
        ) : (
          <div className="d-flex flex-fill flex-column align-items-center justify-content-center">
            <span>Gebruik een zoekterm of de selectie te vernauwen</span>
          </div>
        )}
      </div>
    </div>
  );
};

const ContactSelectie: React.FC<IProps> = (props) => {
  const options = useMemo(() => ({ ...defaultOptions, ...props.options }), [props.options]);
  if (options.types!.length === 0) {
    throw new Error('Er moet minstens een type worden opgegeven');
  }
  if (props.value !== null && props.value.persID === null && props.value.orgID === null) {
    throw new Error(
      'Value is niet null maar allebei de waarden wel, deze situatie mag niet voorkomen',
    );
  }

  const { onChange, value, alleenVoorRelIDs } = props;
  const [persoonSelectieDialoogTabblad, setPersoonSelectieDialoogTabblad] = useState<ETabblad>(
    ETabblad.NieuwOfSelecteren,
  );

  const [nieuwPersoonDialoogTonen, setNieuwPersoonDialoogTonen] = useState<boolean>(false);
  const [nieuwOrganisatieDialoogTonen, setNieuwOrganisatieDialoogTonen] = useState<boolean>(false);
  const [wijzigenPersoonDialoogTonen, setWijzigenPersoonDialoogTonen] = useState<number | null>(
    null,
  );
  const [alleenVoorRelatieGekozenRelID, setAlleenVoorRelatieGekozenRelID] = useState<number | null>(
    null,
  );

  const provider = useMemo<
    Provider<EKolom, IOphalenContactenResultElement, IOverlayContainerState>
  >(
    () => async (params) => {
      const filters: IFilterSchemaFilter[] = [];
      if (options.types!.length === 1) {
        filters.push({
          naam: 'TYPE',
          data: options.types![0],
        });
      }

      if (params.overlayContainerState.zoekterm !== null) {
        filters.push({
          naam: 'ZOEKTERM',
          data: params.overlayContainerState.zoekterm,
        });
      }
      /** Als de relID gevuld is, dan wordt de rijbron bepaald door de relatie, mits het vinkveld is
       * aangevinkt.
       */
      if (alleenVoorRelIDs !== undefined && params.overlayContainerState.alleenVanRelatieTonen) {
        let relIDs = alleenVoorRelIDs;
        if (alleenVoorRelatieGekozenRelID !== null) {
          relIDs = [alleenVoorRelatieGekozenRelID];
        }
        filters.push({
          naam: 'REL_IDS',
          data: relIDs,
        });
      }

      const result = await api.v2.relatie.ophalenContacten({
        filterSchema: {
          filters,
        },
        paginatie: {
          index: params.paginatie.index,
          aantal: params.paginatie.aantal,
        },
      });

      const items = result.contacten.reduce(
        (acc, curr, i) => ({
          ...acc,
          [params.paginatie.index + i]: curr,
        }),
        params.huidigeBron,
      );

      return {
        items,
        totaalAantal: result.totaalAantal,
      };
    },
    [
      JSON.stringify(options.types),
      JSON.stringify(alleenVoorRelIDs),
      alleenVoorRelatieGekozenRelID,
    ],
  );

  const kolommen = useMemo<ASPKolom<EKolom, IOphalenContactenResultElement>[]>(
    () =>
      [
        options.types!.length > 1
          ? {
              key: EKolom.Type,
              breedteType: EAspKolomBreedteType.Vast,
              vasteBreedte: 25,
              renderer: (contact: IOphalenContactenResultElement) => {
                if (contact.persoon !== null) {
                  return <IconPersoon style={{ fill: Kleur.Grijs, width: 16, height: 16 }} />;
                }
                return <IconOrganisatie style={{ fill: Kleur.Grijs, width: 16, height: 16 }} />;
              },
            }
          : null,
        {
          key: EKolom.Naam,
          label: 'Naam',
          breedteType: EAspKolomBreedteType.Vast,
          vasteBreedte: 250,
          renderer: (contact: IOphalenContactenResultElement) => {
            if (contact.persoon !== null) {
              const pers = contact.persoon!;
              return formatteerPersoonNaam({
                voorvoegsel: pers.Voorvoegsel,
                voornaam: pers.Voornaam,
                voorletters: pers.Voorletters,
                achternaam: pers.Achternaam,
                aanhefKort: pers.geslacht.AanhefKort,
              });
            }

            const org = contact.organisatie!;
            return formatteerOrganisatieNaam({
              naam: org.Naam,
            });
          },
        },
        {
          key: EKolom.Email,
          label: 'Email',
          breedteType: EAspKolomBreedteType.Vast,
          vasteBreedte: 200,
          renderer: (contact: IOphalenContactenResultElement) => {
            if (contact.persoon !== null) {
              const pers = contact.persoon!;
              return pers.Email;
            }

            const org = contact.organisatie!;
            return org.Email;
          },
        },
        {
          key: EKolom.Telefoon,
          label: 'Telefoon',
          breedteType: EAspKolomBreedteType.Vast,
          vasteBreedte: 200,
          renderer: (contact: IOphalenContactenResultElement) => {
            if (contact.persoon !== null) {
              const pers = contact.persoon!;
              return pers.TelefoonMobiel;
            }

            const org = contact.organisatie!;
            return org.Telefoonnummer;
          },
        },
      ].filter((x) => x !== null) as ASPKolom<EKolom, IOphalenContactenResultElement>[],
    [options.types],
  );

  const representatieComponent = useMemo<
    React.ComponentType<IRepresentatieProps<IOphalenContactenResultElement>>
  >(
    () => (reprProps) => {
      const cnt = reprProps.entiteit;
      if (cnt.persoon !== null) {
        const pers = cnt.persoon;
        return (
          <div className="d-flex flex-fill align-items-center">
            <IconPersoon style={{ fill: Kleur.Grijs, width: 16, height: 16 }} />
            <span className="ml-2">
              {formatteerPersoonNaam({
                voorvoegsel: pers.Voorvoegsel,
                voornaam: pers.Voornaam,
                voorletters: pers.Voorletters,
                achternaam: pers.Achternaam,
                aanhefKort: '', // contact.geslacht.AanhefKort,
              })}
            </span>
          </div>
        );
      }

      const org = cnt.organisatie!;
      return (
        <div className="d-flex flex-fill align-items-center">
          <IconOrganisatie style={{ fill: Kleur.Grijs, width: 16, height: 16 }} />
          <span className="ml-2">
            {formatteerOrganisatieNaam({
              naam: org.Naam,
            })}
          </span>
        </div>
      );
    },
    [],
  );

  const handleChange = useCallback(
    async (id: string | null) => {
      if (id === null) {
        onChange(null);
        return;
      }
      const contact = await enkeleProvider(id);
      onChange(contact);
    },
    [idToValue, onChange],
  );

  const geenWaardeBerichtComponent = useMemo<React.ComponentType>(
    () => () => {
      let geenWaardeBericht = 'Geen contact';
      if (options.types!.length === 1 && options.types![0] === EType.Persoon) {
        geenWaardeBericht = 'Geen persoon';
      } else if (options.types!.length === 1 && options.types![0] === EType.Organisatie) {
        geenWaardeBericht = 'Geen organisatie';
      }

      return <span>{geenWaardeBericht}</span>;
    },
    [options.types],
  );

  const overlayOptions = useMemo<
    Partial<IOverlayOptions<IOverlayContainerState, IOphalenContactenResultElement, string>>
  >(
    () => ({
      overlayContainer: {
        initialState: {
          zoekterm: '',
          alleenVanRelatieTonen: true,
        },
        overlayContainerComponent: OverlayContainer,
      },
    }),
    [
      setNieuwPersoonDialoogTonen,
      setNieuwOrganisatieDialoogTonen,
      props.alleenVoorRelIDs,
      options.types,
    ],
  );

  const context = useMemo<IContactSelectieContext>(
    () => ({
      alleenVanRelIDs: props.alleenVoorRelIDs,
      options,
      onToevoegenOrganisatieClick: () => setNieuwOrganisatieDialoogTonen(true),
      onToevoegenPersoonClick: () => setNieuwPersoonDialoogTonen(true),
    }),
    [props.alleenVoorRelIDs, options, setNieuwOrganisatieDialoogTonen, setNieuwPersoonDialoogTonen],
  );

  return (
    <ContactSelectieContext.Provider value={context}>
      <MultiComboboxV2<string, EKolom, IOphalenContactenResultElement, IOverlayContainerState>
        provider={provider}
        enkeleProvider={enkeleProvider}
        keyExtractor={(row) => contactenResultElementToId(row)}
        waarde={value === null ? null : valueToId(value)}
        onChange={handleChange}
        representatieComponent={representatieComponent}
        kolommen={kolommen}
        wisbaar={props.isWisbaar}
        disabled={props.disabled}
        geenWaardeRepresentatieComponent={geenWaardeBerichtComponent}
        overlayOptions={overlayOptions}
      />
      {nieuwPersoonDialoogTonen && (
        <PersoonSelectieDialoog
          tabblad={persoonSelectieDialoogTabblad}
          onTabbladChange={(x) => setPersoonSelectieDialoogTabblad(x)}
          open
          onSuccess={async (result) => {
            const contactenResult = await api.v2.relatie.ophalenContacten({
              filterSchema: {
                filters: [
                  {
                    naam: 'VALUES',
                    data: [
                      {
                        persID: result.persID,
                        orgID: null,
                      },
                    ],
                  },
                ],
              },
            });
            const contact = contactenResult.contacten[0];

            onChange(contact);
            setNieuwPersoonDialoogTonen(false);
          }}
          onAnnuleren={() => setNieuwPersoonDialoogTonen(false)}
          dialoogIndex={1}
          defaultFormValues={options.persoonSelectieDialoogDefaultFormValues}
        />
      )}

      {wijzigenPersoonDialoogTonen !== null && (
        <PersoonWijzigenDialoog
          open
          onSuccess={() => {
            setWijzigenPersoonDialoogTonen(null);
          }}
          onAnnuleren={() => {
            setWijzigenPersoonDialoogTonen(null);
          }}
          dialoogIndex={1}
          persID={wijzigenPersoonDialoogTonen}
        />
      )}
    </ContactSelectieContext.Provider>
  );
};

export default ContactSelectie;
