import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import LoadingSpinner from '../../../Gedeeld/LoadingSpinner';
import {
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import { DataTypeProvider, IntegratedSorting, SortingState } from '@devexpress/dx-react-grid';
import api from '../../../../api';
import { formatteerRelatieNaam } from '../../../../helpers';
import { RelatieSelectieDialoogContext } from '../index';
import { IFilterSchemaFilter } from '../../../../../../shared/src/models/filter';
import { IOphalenRelatiesResultElementV2 } from '../../../../../../shared/src/api/v2/relatie';
import _ from 'lodash';
import { IOphalenMeldingenResultElement } from '../../../../../../shared/src/api/v2/service/melding';
import { format } from 'date-fns';
import { Kleur as EKleur } from '../../../../bedrijfslogica/constanten';
import { IconOrganisatie, IconPersoon } from '../../../Icons';

interface IProps {}

interface IRelatieMetMelding extends IOphalenRelatiesResultElementV2 {
  melding: IOphalenMeldingenResultElement;
}

type TEntiteit = IRelatieMetMelding;

const ServicemeldingenTabblad: React.FC<IProps> = observer((props) => {
  const { onGekozen, onHoedanigheidChange } = useContext(RelatieSelectieDialoogContext);

  const [meldingen, setMeldingen] = useState<IOphalenMeldingenResultElement[] | null>(null);
  const [relaties, setRelaties] = useState<IRelatieMetMelding[] | null>(null);

  useEffect(() => {
    (async () => {
      const meldingenResult = await api.v2.service.ophalenMeldingen({
        filterSchema: {
          filters: [{ naam: 'RELID_IS_LEEG', data: false }].filter(
            (x) => x !== null,
          ) as IFilterSchemaFilter[],
        },
        orderSchema: { orders: [{ naam: 'MELDDATUM', richting: 'DESC' }] },
        paginatie: {
          index: 0,
          aantal: 75,
        },
      });

      setMeldingen(meldingenResult.meldingen);
    })();
  }, []);

  const ophalenRelaties = useCallback(async () => {
    if (meldingen === null) {
      return;
    }
    const result = await api.v2.relatie.ophalenRelaties({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: _.uniq(meldingen.map((x) => x.RelID)),
          },
        ],
      },
    });

    const relatiesMetMelding = result.relaties.map((relatie) => {
      const meldingenVoorRelatie = meldingen.filter((x) => x.RelID === relatie.RelID);
      const resultGesorteerd = _.orderBy(meldingenVoorRelatie, ['Melddatum'], ['desc']);
      const melding = resultGesorteerd[0];
      return { ...relatie, melding };
    });

    const relatiesMetMeldingGesorteerd = _.orderBy(
      relatiesMetMelding,
      [(x: any) => x.melding.Melddatum],
      ['desc'],
    );

    setRelaties(relatiesMetMeldingGesorteerd);
  }, [meldingen]);

  useEffect(() => {
    ophalenRelaties();
  }, [ophalenRelaties]);

  const sleutelExtractor = useCallback((row: IRelatieMetMelding) => row.RelID, []);
  const kolommen = useMemo<TypedColumn<IRelatieMetMelding>[]>(
    () => [
      {
        name: 'Relatiesoort' as any,
        title: ' ',
      },
      {
        name: '__naam' as any,
        title: 'Naam',
        getCellValue: (rij) => {
          if (relaties === null) {
            return;
          }
          const relatie = relaties.find((x) => x.RelID === rij.RelID)!;

          return formatteerRelatieNaam({
            voorvoegsel: relatie.persoon!.Voorvoegsel,
            voornaam: relatie.persoon!.Voornaam,
            voorletters: relatie.persoon!.Voorletters,
            achternaam: relatie.persoon!.Achternaam || undefined,
            aanhefKort: relatie.persoon!.geslacht.AanhefKort || undefined,
            organisatienaam: relatie.organisatie !== null ? relatie.organisatie.NaamKort : null,
            relatiesoort: relatie.Relatiesoort,
          });
        },
      },
      {
        name: '__melddatum' as any,
        title: 'Melddatum',
      },
      {
        name: '__straathuisnr' as any,
        title: 'Straatnaam',
      },
      {
        name: '__plaatsnaam' as any,
        title: 'Plaats',
      },
      // {
      //   name: '__melder' as any,
      //   title: 'Melder',
      // },
      {
        name: '__kies_actie',
        title: ' ',
      },
    ],
    [relaties],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRelatieMetMelding>[]>(
    () => [
      {
        columnName: '__naam' as any,
        width: 225,
      },
      {
        columnName: 'Relatiesoort' as any,
        width: 40,
      },
      {
        columnName: '__melddatum' as any,
        width: 115,
      },
      {
        columnName: '__straathuisnr' as any,
        width: 200,
      },
      {
        columnName: '__plaatsnaam' as any,
        width: 125,
      },
      // {
      //   columnName: '__melder' as any,
      //   width: 200,
      // },
      {
        columnName: '__kies_actie' as any,
        width: 80,
      },
    ],
    [],
  );

  return (
    <div className="flex-fill d-flex flex-column mt-2">
      {/* <div className="d-flex">
        <div className="d-flex align-items-center p-2 pl-4 pr-4">
          <div className="d-flex align-items-center" onClick={() => null}>
            <span>
              <VinkVeld
                aangevinkt={alleenMetLopendeContracten}
                onGewijzigd={(x) => setAlleenMetLopendeContracten(x)}
              />
            </span>
            <span className="ml-2" style={{ cursor: 'pointer' }}>
              Alleen met lopende contracten
            </span>
          </div>
        </div>
      </div> */}

      <div className="p-0 mt-2">
        {relaties === null ? (
          <div className="d-flex flex-fill align-items-center justify-content-center">
            <LoadingSpinner />
          </div>
        ) : (
          <GridStyleWrapper maxHeight={500} rowAmount={relaties.length}>
            <Grid getRowId={sleutelExtractor} columns={kolommen as any} rows={relaties}>
              <DataTypeProvider
                for={['Relatiesoort']}
                formatterComponent={(formatterProps) => {
                  const rij = formatterProps.row as IRelatieMetMelding;

                  const iconStyle: React.CSSProperties = {
                    width: 18,
                    height: 18,
                    fill: EKleur.Grijs,
                  };

                  return (
                    <span className="ml-2">
                      {rij.Relatiesoort === 'P' ? (
                        <IconPersoon style={iconStyle} />
                      ) : (
                        <IconOrganisatie style={iconStyle} />
                      )}
                    </span>
                  );
                }}
              />

              <DataTypeProvider
                for={['__melddatum']}
                formatterComponent={(formatterProps) => {
                  const rij = formatterProps.row as IRelatieMetMelding;

                  return <span>{format(new Date(rij.melding.Melddatum), 'dd-MM HH:mm')}</span>;
                }}
              />

              <DataTypeProvider
                for={['__straathuisnr']}
                formatterComponent={(formatterProps) => {
                  const rij = formatterProps.row as IRelatieMetMelding;
                  const adres = rij.melding.locatie;
                  const straatnaam =
                    adres.Straatnaam + ' ' + adres.Huisnummer + ' ' + (adres.Bisnummer || '');
                  return <span>{straatnaam}</span>;
                }}
              />

              <DataTypeProvider
                for={['__plaatsnaam']}
                formatterComponent={(formatterProps) => {
                  const rij = formatterProps.row as IRelatieMetMelding;
                  const adres = rij.melding.locatie;
                  return <span>{adres.Plaatsnaam}</span>;
                }}
              />

              <DataTypeProvider
                for={['__melder']}
                formatterComponent={(formatterProps) => {
                  const rij = formatterProps.row as IRelatieMetMelding;
                  const naam = rij.persoon !== null ? rij.persoon.Achternaam : '';
                  return <span>{naam}</span>;
                }}
              />

              <DataTypeProvider
                for={['__kies_actie']}
                formatterComponent={(formatterProps) => {
                  const entiteit = formatterProps.row as TEntiteit;
                  return (
                    <a
                      href="#"
                      onClick={(ev) => {
                        ev.stopPropagation();
                        onGekozen(entiteit.RelID);
                      }}
                    >
                      Kies
                    </a>
                  );
                }}
              />

              <SortingState defaultSorting={[]} />
              <IntegratedSorting />
              <VirtualTable
                rowComponent={(rowProps) => (
                  <tr
                    // className=""
                    style={{
                      cursor: 'pointer',
                    }}
                    onDoubleClick={() => {
                      const entiteit: TEntiteit = rowProps.row;
                      onGekozen(entiteit.RelID);
                    }}
                  >
                    {rowProps.children}
                  </tr>
                )}
              />

              <TableColumnResizing columnWidths={kolomBreedtes} />
              <TableHeaderRow showSortingControls />
            </Grid>
          </GridStyleWrapper>
        )}
      </div>
    </div>
  );
});

export default ServicemeldingenTabblad;
