import React, { useCallback, useEffect, useMemo, useState, useContext } from 'react';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import { ITabbladProps } from '../../Aanbod';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  DXTableCheckboxComponent,
  DXTableToggleCellComponent,
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import useUrlState from '../../../../core/useUrlState';
import api from '../../../../api';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  VirtualTable,
  TableEditColumn,
  Table,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  RowDetailState,
  SelectionState,
  EditingState,
  DataTypeProvider,
  SortingState,
  IntegratedSorting,
} from '@devexpress/dx-react-grid';
import { IconVerwijderen, IconToevoegen } from '../../../../components/Icons';
import { RootStoreContext } from '../../../../stores/RootStore';
import _ from 'lodash';
import DetailComp from '../DetailComp';
import { IOphalenTypenParametersResultElement } from '../../../../../../shared/src/api/v2/inkoop/parameters';
import { IOphalenProducttypenResultElement } from '../../../../../../shared/src/api/v2/product/type';
import { EResultType } from '../../../../stores/CheckStore';
import WijzigenParametersDialoog from './WijzigenParametersDialoog';
import ToevoegenParametersDialoog from './ToevoegenParametersDialoog';
import SelectieVak from '../../../../components/SelectieVak';
import nameof from '../../../../core/nameOf';
import { IOphalenInstellingenResult } from '../../../../../../shared/src/api/v2/instelling';
import FilterBalkV2, {
  IFilter,
  IFilterData,
  maakFilterSchema,
} from '../../../../components/FilterBalkV2';
import MenuLayout from '../../../../components/MenuLayout';
import { Helmet } from 'react-helmet';

interface IProps extends ITabbladProps, RouteComponentProps {}

export interface IToevoegenParametersDialoogState {}
export interface IWijzigenParametersDialoogState {
  id: number;
}

enum EFilter {
  IsActief = 'IS_ACTIEF',
}
interface IUrlState {
  selectie: number[];
  toevoegenDialoogState: IToevoegenParametersDialoogState | null;
  wijzigenDialoogState: IWijzigenParametersDialoogState | null;
  filterdata: IFilterData<EFilter>[];
}

const defaultUrlState: IUrlState = {
  selectie: [],
  toevoegenDialoogState: null,
  wijzigenDialoogState: null,
  filterdata: [
    {
      naam: EFilter.IsActief,
      data: true,
      isActief: true,
    },
  ],
};

const geenData = {
  noData: 'Geen parameters',
};

export interface IRegel extends IOphalenTypenParametersResultElement {
  producttype: IOphalenProducttypenResultElement;
}

const ParametersTab: React.FC<IProps> = (props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState<IUrlState>(props, defaultUrlState);

  const { checkStore, instellingStore } = useContext(RootStoreContext);
  const [instellingen, setInstellingen] = useState<IOphalenInstellingenResult | null>(null);

  const [typenParameters, setTypenParameters] = useState<IRegel[] | null>(null);

  const [filterSchema, setFilterSchema] = useState(maakFilterSchema(urlState.filterdata));

  const keyExtractor = useCallback((row: IRegel) => row.ID, []);

  useEffect(() => {
    (async () => {
      const instellingen = await api.v2.instelling.ophalenInstellingen({});
      setInstellingen(instellingen);
    })();
  }, []);

  const ophalenTypenParameters = useCallback(async () => {
    if (instellingen === null) {
      return;
    }
    const parametersResult = (
      await api.v2.inkoop.parameters.ophalenTypenParameters({
        filterSchema: {
          filters: [
            ...filterSchema.filters!,
            { naam: 'MAG_IDS', data: [instellingen!.Voorraad_MagID] },
          ],
        },
      })
    ).typenParameters;

    const typeIDs = _.uniq(parametersResult.map((x) => x.TypeID));
    const producttypen = (
      await api.v2.product.type.ophalenProducttypen({
        filterSchema: { filters: [{ naam: 'IDS', data: typeIDs }] },
      })
    ).producttypen;

    const result = parametersResult.map((x) => {
      const producttype = producttypen.find((t) => t.TypeID === x.TypeID)!;
      return { ...x, producttype };
    });

    setTypenParameters(result);
  }, [instellingen, filterSchema]);

  useEffect(() => {
    ophalenTypenParameters();
  }, [ophalenTypenParameters]);

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.IsActief,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return (
            <div className="d-flex align-items-center">
              <span>Alleen actieve parameters</span>
            </div>
          );
        },
      },
    ],
    [],
  );

  const kolommen = useMemo<TypedColumn<IRegel>[]>(
    () => [
      {
        name: '__merknaam' as any,
        title: 'Merk',
      },
      {
        name: '__typenaam' as any,
        title: 'Type',
      },
      {
        name: 'ProductsoortnaamKort',
        title: 'Cat.',
      },
      {
        name: 'AantalMinimaal',
        title: 'Ondergrens',
      },
      {
        name: 'AanvullenTot',
        title: 'Aanvullen tot',
      },
      {
        name: 'Actief',
        title: 'Actief',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRegel>[]>(
    () => [
      {
        columnName: '__merknaam' as any,
        width: 135,
      },
      {
        columnName: '__typenaam' as any,
        width: 225,
      },
      {
        columnName: 'ProductsoortnaamKort',
        width: 90,
      },
      {
        columnName: 'AantalMinimaal',
        width: 115,
      },
      {
        columnName: 'AanvullenTot',
        width: 135,
      },
      {
        columnName: 'Actief',
        width: 90,
      },
    ],
    [],
  );

  return (
    <>
      <Helmet>
        <title>Inkoopparameters</title>
      </Helmet>
      <MenuLayout
        menu={
          <div className="d-flex flex-fill align-items-center">
            <button
              className="btn btn-sm btn-light d-flex align-items-center"
              style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
              onClick={async () => {
                setUrlStateSync('toevoegenDialoogState', {});
              }}
            >
              <IconToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
              <span className="ml-2">Toevoegen</span>
            </button>
            <button
              className="btn btn-sm btn-light d-flex align-items-center ml-2"
              style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
              disabled={urlState.selectie.length === 0}
              onClick={async () => {
                if (
                  (
                    await checkStore.bevestigen({
                      inhoud: 'Regels verwijderen?',
                    })
                  ).type === EResultType.Annuleren
                ) {
                  return;
                }

                await api.v2.inkoop.parameters.verwijderenTypenParameters({
                  ids: urlState.selectie,
                });

                setUrlStateSync('selectie', []);
                await ophalenTypenParameters();
              }}
            >
              <IconVerwijderen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
              <span className="ml-2">Verwijderen</span>
            </button>

            <div className="d-flex flex-fill">
              <FilterBalkV2
                filters={filters}
                filterData={urlState.filterdata}
                onFilterDataChange={(x) => setUrlStateSync('filterdata', x)}
                onFilterSchemaChange={setFilterSchema}
              />
            </div>
          </div>
        }
        body={
          <>
            {typenParameters === null ? (
              <LoadingSpinner />
            ) : (
              <GridStyleWrapper height="calc(100vh - 150px)">
                <Grid columns={kolommen} getRowId={keyExtractor} rows={typenParameters}>
                  <DataTypeProvider
                    for={['__merknaam']}
                    formatterComponent={(formatterProps) => {
                      const rij: IRegel = formatterProps.row;
                      return <span>{rij.producttype.Merknaam}</span>;
                    }}
                  />

                  <DataTypeProvider
                    for={['__typenaam']}
                    formatterComponent={(formatterProps) => {
                      const rij: IRegel = formatterProps.row;
                      return <span>{rij.producttype.Typenaam}</span>;
                    }}
                  />

                  <DataTypeProvider
                    for={['ProductsoortnaamKort']}
                    formatterComponent={(formatterProps) => {
                      const rij: IRegel = formatterProps.row;
                      return <span>{rij.producttype.ProductsoortnaamKort}</span>;
                    }}
                  />

                  <DataTypeProvider
                    for={[nameof<IRegel>('Actief')]}
                    formatterComponent={(formatterProps) => {
                      return <span>{formatterProps.value ? 'Ja' : 'Nee'}</span>;
                    }}
                  />

                  <EditingState
                    onCommitChanges={async (changes) => {
                      if (changes.deleted === undefined) {
                        return;
                      }
                    }}
                    onEditingRowIdsChange={(rowIds) => {
                      const id = rowIds[rowIds.length - 1] as number;
                      setUrlStateSync('wijzigenDialoogState', { id });
                    }}
                  />

                  <SelectionState
                    selection={urlState.selectie}
                    onSelectionChange={(x) => setUrlStateSync('selectie', x as number[])}
                  />
                  <SortingState defaultSorting={[]} />
                  <IntegratedSorting />
                  <VirtualTable messages={geenData} />

                  <TableEditColumn
                    width={35}
                    showEditCommand
                    cellComponent={DXTableEditColumnCellComponent}
                    commandComponent={DXTableEditColumnCommandComponent}
                  />

                  <RowDetailState defaultExpandedRowIds={[]} />
                  <TableRowDetail
                    contentComponent={(props) => <DetailComp {...props} zonderCorrigeren />}
                    toggleCellComponent={DXTableToggleCellComponent}
                  />
                  <TableSelection cellComponent={DXTableCheckboxComponent} />
                  <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
                  <TableHeaderRow showSortingControls />
                  {/* <TableRowDetail contentComponent={opdrachtDetailComp} /> */}
                </Grid>
              </GridStyleWrapper>
            )}

            {urlState.toevoegenDialoogState !== null && (
              <ToevoegenParametersDialoog
                open
                onSuccess={async () => {
                  await ophalenTypenParameters();
                  setUrlStateSync('toevoegenDialoogState', null);
                }}
                onAnnuleren={() => setUrlStateSync('toevoegenDialoogState', null)}
              />
            )}
            {urlState.wijzigenDialoogState !== null && (
              <WijzigenParametersDialoog
                open
                id={urlState.wijzigenDialoogState.id}
                onSuccess={() => {
                  setUrlStateSync('wijzigenDialoogState', null);
                  ophalenTypenParameters();
                }}
                onAnnuleren={() => setUrlStateSync('wijzigenDialoogState', null)}
              />
            )}
          </>
        }
      />
    </>
  );
};

export default withRouter(ParametersTab);
