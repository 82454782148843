import React, { useMemo } from 'react';
import Tabblad, { ITabblad } from '../../../../../../components/layout/Tabblad';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IEntiteitProps } from '../../../../../../components/kaart/EntiteitContainer';
import Relaties from './VertegenwoordigdDoor';
import Vertegenwoordigden from './Vertegenwoordigden';

interface IProps extends IEntiteitProps, RouteComponentProps {}

export enum ETabblad {
  VertegenwoordigdDoor = 1,
  Vertegenwoordigden = 2,
}

const pathMap: Record<ETabblad, string> = {
  [ETabblad.VertegenwoordigdDoor]: '/relaties',
  [ETabblad.Vertegenwoordigden]: '/vertegenwoordigden',
};

const Vertegenwoordiging: React.FC<IProps> = (props) => {
  const tabblad = useMemo<ETabblad | null>(() => {
    const matchKey = Object.keys(pathMap).find((key) => {
      const path = pathMap[Number(key) as ETabblad]!;
      return props.location.pathname.endsWith(path);
    });
    if (matchKey === undefined) {
      props.history.push(`${props.match.path}${pathMap[ETabblad.VertegenwoordigdDoor]}`);
      return null;
    }
    return Number(matchKey) as ETabblad;
  }, [props.location.pathname]);

  const tabbladen = useMemo<ITabblad<ETabblad>[]>(
    () => [
      {
        id: ETabblad.VertegenwoordigdDoor,
        label: 'Vertegenwoordigd door',
        content: Relaties,
      },
      {
        id: ETabblad.Vertegenwoordigden,
        label: 'Vertegenwoordigden',
        content: Vertegenwoordigden,
      },
    ],
    [],
  );

  return (
    <div className="d-flex flex-column flex-fill flex-fill">
      <div>
        {tabblad !== null && (
          <Tabblad<ETabblad>
            geselecteerd={tabblad}
            onSelectieChange={(tab) => {
              props.history.push(
                `${props.location.pathname.replace(pathMap[tabblad], '')}${pathMap[tab]}`,
              );
            }}
            tabbladen={tabbladen}
            options={{
              tabbladComponentProps: {
                relID: props.relID,
              },
            }}
          />
        )}
      </div>
    </div>
  );
};

export default withRouter(Vertegenwoordiging);
