import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import LoadingSpinner from '../../../Gedeeld/LoadingSpinner';
import {
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import { DataTypeProvider, IntegratedSorting, SortingState } from '@devexpress/dx-react-grid';
import api from '../../../../api';
import { formatteerRelatieNaam } from '../../../../helpers';
import { RelatieSelectieDialoogContext } from '../index';
import { IFilterSchemaFilter } from '../../../../../../shared/src/models/filter';
import { IOphalenRelatiesResultElementV2 } from '../../../../../../shared/src/api/v2/relatie';
import _ from 'lodash';
import { Kleur as EKleur } from '../../../../bedrijfslogica/constanten';
import { IconOrganisatie, IconPersoon } from '../../../Icons';
import { IOphalenFacturenResultElement } from '../../../../../../shared/src/api/v2/inkoopfactuur';
import RelatieVisualisatie from '../../RelatieVisualisatie';
import FormatteerBedrag from '../../../MutatieBedrag';

interface IProps {}

type TEntiteit = IOphalenRelatiesResultElementV2;

const CrediteurenTabblad: React.FC<IProps> = observer((props) => {
  const { onGekozen, onHoedanigheidChange } = useContext(RelatieSelectieDialoogContext);

  const [openstaandeFacturen, setOpenstaandeFacturen] = useState<
    IOphalenFacturenResultElement[] | null
  >(null);
  const [relaties, setRelaties] = useState<IOphalenRelatiesResultElementV2[] | null>(null);

  const ophalenOpenstaandeFacturen = useCallback(async () => {
    const result = await api.v2.inkoopfactuur.ophalenFacturen({
      filterSchema: {
        filters: [
          {
            naam: 'IS_OPENSTAAND',
            data: true,
          },
        ],
      },
    });

    setOpenstaandeFacturen(result.facturen);
  }, []);

  useEffect(() => {
    ophalenOpenstaandeFacturen();
  }, [ophalenOpenstaandeFacturen]);

  const ophalenRelaties = useCallback(async () => {
    if (openstaandeFacturen === null) {
      return;
    }

    const result = await api.v2.relatie.ophalenRelaties({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: _.uniq(openstaandeFacturen.map((x) => x.RelID)),
          },
        ],
      },
    });

    setRelaties(result.relaties);
  }, [openstaandeFacturen]);

  useEffect(() => {
    ophalenRelaties();
  }, [ophalenRelaties]);

  const sleutelExtractor = useCallback((row: IOphalenRelatiesResultElementV2) => row.RelID, []);
  const kolommen = useMemo<TypedColumn<IOphalenRelatiesResultElementV2>[]>(
    () => [
      {
        name: 'Relatiesoort' as any,
        title: ' ',
      },
      {
        name: '__relatie' as any,
        title: 'Naam',
        getCellValue: (rij, columnName) => {
          return rij.weergavenaam;
        },
      },
      {
        name: 'adres',
        title: 'Adres',
        getCellValue: (row, columnName) => {
          const adres = row.adres;
          if (adres === null) {
            return '';
          }
          return (
            adres.Straatnaam +
            ' ' +
            adres.Huisnummer +
            (adres.Bisnummer !== null ? ' ' + adres.Bisnummer : '') +
            ', ' +
            adres.Plaatsnaam
          ).trim();
        },
      },
      {
        name: 'Relatienummer',
        title: 'Rel.nr.',
      },
      {
        name: '__openstaand' as any,
        title: 'Openst.',
        getCellValue: (rij) => {
          return _.sum(
            openstaandeFacturen!.filter((x) => x.RelID === rij.RelID).map((x) => x.Openstaand),
          );
        },
      },
      {
        name: '__kies_actie',
        title: ' ',
      },
    ],
    [relaties],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IOphalenRelatiesResultElementV2>[]>(
    () => [
      {
        columnName: 'Relatiesoort' as any,
        width: 40,
      },
      {
        columnName: '__relatie' as any,
        width: 225,
      },
      {
        columnName: 'adres',
        width: 250,
      },
      {
        columnName: 'Relatienummer',
        width: 80,
      },
      {
        columnName: '__openstaand' as any,
        width: 100,
      },
      {
        columnName: '__kies_actie' as any,
        width: 80,
      },
    ],
    [],
  );

  return (
    <div className="flex-fill d-flex flex-column mt-2">
      {/* <div className="d-flex">
        <div className="d-flex align-items-center p-2 pl-4 pr-4">
          <div className="d-flex align-items-center" onClick={() => null}>
            <span>
              <VinkVeld
                aangevinkt={alleenMetLopendeContracten}
                onGewijzigd={(x) => setAlleenMetLopendeContracten(x)}
              />
            </span>
            <span className="ml-2" style={{ cursor: 'pointer' }}>
              Alleen met lopende contracten
            </span>
          </div>
        </div>
      </div> */}

      <div className="p-0 mt-2">
        {relaties === null || openstaandeFacturen === null ? (
          <div className="d-flex flex-fill align-items-center justify-content-center">
            <LoadingSpinner />
          </div>
        ) : (
          <GridStyleWrapper maxHeight={500} rowAmount={relaties.length}>
            <Grid getRowId={sleutelExtractor} columns={kolommen as any} rows={relaties}>
              <DataTypeProvider
                for={['__relatie']}
                formatterComponent={(formatterProps) => {
                  const rij: IOphalenRelatiesResultElementV2 = formatterProps.row;
                  return (
                    <RelatieVisualisatie relID={rij.RelID} options={{ geenLinkToepassen: true }} />
                  );
                }}
              />

              <DataTypeProvider
                for={['Relatiesoort']}
                formatterComponent={(formatterProps) => {
                  const rij = formatterProps.row as IOphalenRelatiesResultElementV2;

                  const iconStyle: React.CSSProperties = {
                    width: 18,
                    height: 18,
                    fill: EKleur.Grijs,
                  };

                  return (
                    <span className="ml-2">
                      {rij.Relatiesoort === 'P' ? (
                        <IconPersoon style={iconStyle} />
                      ) : (
                        <IconOrganisatie style={iconStyle} />
                      )}
                    </span>
                  );
                }}
              />

              <DataTypeProvider
                for={['__kies_actie']}
                formatterComponent={(formatterProps) => {
                  const entiteit = formatterProps.row as TEntiteit;
                  return (
                    <a
                      href="#"
                      onClick={(ev) => {
                        ev.stopPropagation();
                        onGekozen(entiteit.RelID);
                      }}
                    >
                      Kies
                    </a>
                  );
                }}
              />

              <DataTypeProvider
                for={['__openstaand']}
                formatterComponent={(formatterProps) => {
                  const rij: IOphalenRelatiesResultElementV2 = formatterProps.row;
                  const openstaand = _.sum(
                    openstaandeFacturen
                      .filter((x) => x.RelID === rij.RelID)
                      .map((x) => x.Openstaand),
                  );
                  return <FormatteerBedrag bedrag={openstaand} />;
                }}
              />

              <SortingState defaultSorting={[]} />
              <IntegratedSorting />
              <VirtualTable
                rowComponent={(rowProps) => (
                  <tr
                    // className=""
                    style={{
                      cursor: 'pointer',
                    }}
                    onDoubleClick={() => {
                      const entiteit: TEntiteit = rowProps.row;
                      onGekozen(entiteit.RelID);
                    }}
                  >
                    {rowProps.children}
                  </tr>
                )}
              />

              <TableColumnResizing columnWidths={kolomBreedtes} />
              <TableHeaderRow showSortingControls />
            </Grid>
          </GridStyleWrapper>
        )}
      </div>
    </div>
  );
});

export default CrediteurenTabblad;
