import { format } from 'date-fns';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { IOphalenInformatieVoorOverzichtTransport } from '../../../../../../../../../shared/src/api/v2/relatie';
import TegelBasis from '../../../../../../../components/tegel/TegelBasis';
import NavigatiePijl from '../NavigatiePijl';
import { genereerUrlStateQueryParam } from '../../../../../../../core/useUrlState';
import {
  IUrlState as ITransportUrlState,
  defaultUrlState as defaultTransportUrlState,
} from '../../../../../../../components/kaart/entiteiten/Transport/Opdrachten';
import OpdrachtStatus from '../../../../../../../components/kaart/entiteiten/Transport/OpdrachtStatus';

interface IProps extends RouteComponentProps {
  relID: number;
  data: IOphalenInformatieVoorOverzichtTransport;
}

const Transporttegel = (props: IProps) => {
  const opdrachtregels = props.data.transportopdrachtregels;

  const TabelRegels = () => {
    return (
      <>
        <table>
          <tbody>
            {opdrachtregels.map((regel) => {
              const adres =
                regel.Straatnaam + ' ' + regel.Huisnummer + ' ' + (regel.Bisnummer || '');
              return (
                <tr key={regel.TrsRegID}>
                  <td style={{ width: 40 }}>
                    <OpdrachtStatus status={regel.Opdrachtstatus} naamWeergeven={false} />
                  </td>
                  <td style={{ width: 75 }}>
                    {regel.Opdrachtnummer}/{regel.Regelnummer}
                  </td>
                  <td style={{ width: 100 }}>
                    {regel.Bezoekdatum !== null
                      ? format(new Date(regel.Bezoekdatum), 'dd-MM-yyyy')
                      : 'Z.s.m.'}
                  </td>
                  <td
                    style={{
                      width: 185,
                      maxWidth: 185,
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                    }}
                    title={adres}
                  >
                    {adres}
                  </td>
                  <td style={{ width: 70 }}>
                    {regel.SoortNaamKort}
                    {regel.OmruilID !== null ? '/O' : ''}
                  </td>
                  {regel.producttype !== null ? (
                    <td style={{ width: 80 }}>{regel.producttype.SoortnaamKort}</td>
                  ) : regel.ProdID !== null ? (
                    <td style={{ width: 80 }}>{regel.product!.SoortnaamKort}</td>
                  ) : (
                    <span></span>
                  )}
                  {regel.producttype !== null ? (
                    <td style={{ width: 225 }}>
                      {regel.producttype.Merknaam} {regel.producttype.Typenaam}
                    </td>
                  ) : regel.ProdID !== null ? (
                    <td style={{ width: 225 }}>
                      {regel.product!.Merknaam} {regel.product!.Typenaam}
                    </td>
                  ) : (
                    <span></span>
                  )}
                  {regel.TypeID === null && regel.product !== null ? (
                    <td style={{ width: 100 }}>
                      {regel.product.Referentiecode !== null
                        ? 'Ref. ' + regel.product.Referentiecode
                        : ''}
                    </td>
                  ) : (
                    <span></span>
                  )}

                  <td style={{ verticalAlign: 'top' }}>
                    <NavigatiePijl
                      relID={props.relID}
                      pathWithStateBuilder={() => {
                        const urlState: ITransportUrlState = {
                          ...defaultTransportUrlState,
                          uitgeklapteOpdrachten: [regel!.TrsOpdID],
                          opdrachtenStates: {
                            [regel!.TrsOpdID]: {
                              afmeldenDialoogState: null,
                              annulerenOpdrachtregelsDialoogState: null,
                              toevoegenMontageregelDialoogState: null,
                              voorraadInfoDialoogState: null,
                              koppelenVoorraadProductDialoogState: null,
                              wijzigenRegelDialoogState: null,
                              regelsSelectie: [regel!.TrsRegID],
                              productinfoDialoogState: null,
                              verplaatsenRegelsDialoogState: null,
                            },
                          },
                          // regelsSelectie: [regel.TrsRegID],
                        };
                        const state = genereerUrlStateQueryParam(urlState);
                        return `transport/opdrachten/opdracht?state=${state}`;
                      }}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {props.data.totaalAantalOpdrachten > props.data.opdrachten.length && (
          <div className="mt-2">
            <a
              href="#"
              onClick={(ev) => {
                ev.preventDefault();

                props.history.push(`/klant/${props.relID}/transport/opdrachten`);
              }}
            >
              Er zijn nog {props.data.totaalAantalOpdrachten - props.data.opdrachten.length}
              &nbsp;andere opdrachten...
            </a>
          </div>
        )}
      </>
    );
  };

  const TabelOpdrachten = () => {
    return (
      <>
        <table>
          <tbody>
            {props.data.opdrachten.map((opdracht) => {
              return (
                <tr key={opdracht.trsOpdID}>
                  <td style={{ width: 100 }}>
                    {opdracht.bezoekdatum !== null
                      ? format(new Date(opdracht.bezoekdatum), 'dd-MM-yyyy')
                      : 'Z.s.m.'}
                  </td>
                  <td style={{ width: 250 }}>{opdracht.adres}</td>
                  <td style={{ width: 90 }}>
                    <OpdrachtStatus status={opdracht.opdrachtstatus} naamWeergeven={true} />
                  </td>
                  <td style={{ verticalAlign: 'top' }}>
                    <NavigatiePijl
                      relID={props.relID}
                      pathWithStateBuilder={() => {
                        const state = genereerUrlStateQueryParam<ITransportUrlState>({
                          ...defaultTransportUrlState,
                          opdrachtenSelectie: [opdracht.trsOpdID],
                        });
                        return `transport/opdrachten/opdracht?state=${state}`;
                      }}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {props.data.totaalAantalOpdrachten > props.data.opdrachten.length && (
          <div className="mt-2">
            <a
              href="#"
              onClick={(ev) => {
                ev.preventDefault();

                props.history.push(`/klant/${props.relID}/transport/opdrachten`);
              }}
            >
              Er zijn nog {props.data.totaalAantalOpdrachten - props.data.opdrachten.length}
              &nbsp;andere opdrachten...
            </a>
          </div>
        )}
      </>
    );
  };

  return (
    <TegelBasis titel="Transport">
      <div className="pl-2 pr-2 pb-2">
        {opdrachtregels.length < 10 ? <TabelRegels /> : <TabelOpdrachten />}
      </div>
    </TegelBasis>
  );
};

export default withRouter(Transporttegel);
