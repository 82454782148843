import styled from 'styled-components';
import { Kleur } from '../../../../../bedrijfslogica/constanten';

interface IRootProps {
  backgroundImageSrc?: string;
}

export const Root = styled.div<IRootProps>`
  display: flex;

  //margin: 2px 5px;
  width: 215px;
  border: 1px solid #e2e2e2;
  height: 165px;
  position: relative;
  ${(props) => {
    return `
        background: url(${props.backgroundImageSrc});
        background-size:cover;
        background-position: center;
    `;
  }}

  &:hover {
    // background-color: ${Kleur.LichtGrijs};
  }
`;
