import * as React from 'react';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import IDialoogProps from '../../../core/IDialoogProps';
import Modal from 'react-bootstrap/Modal';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import api from '../../../api';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalHeader from 'react-bootstrap/ModalHeader';
import LoadingSpinner from '../../../components/Gedeeld/LoadingSpinner';
import { RootStoreContext } from '../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import { Formik, FormikProps, Field, FieldProps, FormikActions } from 'formik';
import DatumKiezer from '../../../components/formulier/DatumKiezer';
import { format, addDays } from 'date-fns';
import nameof from '../../../core/nameOf';
import VinkVeld from '../../../components/formulier/VinkVeld';
import Combobox from '../../../components/formulier/Combobox';
import teksten from '../../../bedrijfslogica/teksten';
import * as Yup from 'yup';
import { IOphalenVerkoopVoorstellenResultElement } from '../../../../../shared/src/api/v2/product/verkoop';
import { dagDatum } from '../../../helpers/datum';
import BedragInput from '../../formulier/BedragInput';
import FormikVeldFout from '../../formulier/FormikVeldFout';
import { EResultType } from '../../../stores/CheckStore';
import Dialoog from '../../dialogen/Dialoog';

interface IProps extends IDialoogProps {
  id: number;
}

export interface IFormikValues {
  geldigVan: Date;
  geldigTot: Date;
  bedrag: number;
  nietOpvolgen: boolean;
  aanleiding: number;
  kostenVtbNietBerekenen: boolean;
}

const veldnamen = {
  geldigVan: 'Geldig van',
  geldigTot: 'Geldig tot',
  bedrag: 'Bedrag',
  nietOpvolgen: 'Geen herinneringen sturen',
  aanleiding: 'Aanleiding',
  kostenVtbNietBerekenen: 'Kosten VTB niet in rekening brengen',
};

const WijzigenVerkoopvoorstelDialoog: React.FC<IProps> = observer((props) => {
  const { dialoogIndex, onAnnuleren, onSuccess, open } = props;

  const { checkStore } = useContext(RootStoreContext);

  const [voorstel, setVoorstel] = useState<IOphalenVerkoopVoorstellenResultElement | null>(null);

  const aanleidingOpties = [
    { Aanleiding: 0, Naam: 'Op verzoek van klant' },
    { Aanleiding: 1, Naam: 'Beeindiging contract' },
  ];

  const ophalenVerkoopvoorstel = useCallback(async () => {
    const result = (
      await api.v2.product.verkoop.ophalenVerkoopvoorstellen({
        filterSchema: { filters: [{ naam: 'IDS', data: [props.id] }] },
      })
    ).voorstellen[0];

    setVoorstel(result);
  }, []);

  useEffect(() => {
    ophalenVerkoopvoorstel();
  }, [ophalenVerkoopvoorstel]);

  const initieleWaarden = useMemo<IFormikValues | null>(() => {
    if (voorstel === null) {
      return null;
    }

    return {
      geldigVan: dagDatum(new Date(voorstel.GeldigVan)),
      geldigTot: dagDatum(new Date(voorstel.GeldigTot)),
      bedrag: voorstel.Bedrag,
      nietOpvolgen: voorstel.NietOpvolgen,
      aanleiding: voorstel.Aanleiding,
      kostenVtbNietBerekenen: voorstel.KostenVtbNietBerekenen,
    };
  }, [voorstel]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      if (voorstel === null) {
        return null;
      }

      actions.setSubmitting(true);

      const params = {
        id: props.id,
        geldigVan: values.geldigVan,
        geldigTot: values.geldigTot,
        bedrag: values.bedrag,
        nietOpvolgen: values.nietOpvolgen,
        aanleiding: values.aanleiding,
        kostenVtbNietBerekenen: values.kostenVtbNietBerekenen,
      };

      const checkData = await api.v2.product.verkoop.checkWijzigenVerkoopvoorstel(params);

      if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
        actions.setSubmitting(false);
        return;
      }

      await api.v2.product.verkoop.wijzigenVerkoopvoorstel(params);

      setVoorstel(null);
      props.onSuccess({});
      actions.setSubmitting(false);
    },
    [props.onSuccess, voorstel],
  );

  const handleAnnuleren = useCallback(() => {
    props.onAnnuleren();
    setVoorstel(null);
  }, [props.onAnnuleren, setVoorstel]);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        geldigVan: Yup.string().required(
          teksten.formulier.E_VERPLICHT_VELD({
            veldnaam: veldnamen.geldigVan,
          }),
        ),
        geldigTot: Yup.string().required(
          teksten.formulier.E_VERPLICHT_VELD({
            veldnaam: veldnamen.geldigTot,
          }),
        ),
      }),
    [],
  );

  return (
    <Dialoog index={dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Wijzigen verkoopvoorstel</ModalTitle>
      </ModalHeader>
      {voorstel === null ? (
        <LoadingSpinner />
      ) : (
        <Formik<IFormikValues>
          // ref={formikRef}
          isInitialValid
          enableReinitialize
          onSubmit={handleSubmit}
          initialValues={initieleWaarden!}
          validationSchema={validationSchema}
          validateOnChange
          validateOnBlur
          render={(formikProps: FormikProps<IFormikValues>) => {
            const { submitForm, isSubmitting, values, isValid } = formikProps;
            return (
              <>
                <ModalBody>
                  <div className="form-group">
                    <div className="row">
                      <div className="d-flex col-12 mt-3 pl-0">
                        <div className="col-4 ">
                          <label>{veldnamen.geldigVan}</label>
                          <Field
                            name={nameof('geldigVan')}
                            render={({ field, form }: FieldProps<IFormikValues>) => {
                              return (
                                <div className="d-flex">
                                  <DatumKiezer
                                    onGewijzigd={(x) => {
                                      form.setFieldValue(field.name, x);
                                    }}
                                    waarde={field.value}
                                    determineValidDate={(date) => {
                                      return (
                                        date >= addDays(new Date(), -60) &&
                                        date < addDays(new Date(), 65)
                                      );
                                    }}
                                    determinePreviousValidDate="ONBEGRENST"
                                    determineNextValidDate="ONBEGRENST"

                                    // determinePreviousValidDate={(date) => {
                                    //   const minDate = addDays(new Date(), -1);
                                    //   const newDate = addDays(date, -1);
                                    //   if (newDate >= minDate) {
                                    //     return newDate;
                                    //   }
                                    //   return null;
                                    // }}
                                  />
                                </div>
                              );
                            }}
                          />
                        </div>

                        <div className="col-4">
                          <label>{veldnamen.geldigTot}</label>
                          <Field
                            name={nameof('geldigTot')}
                            render={({ field, form }: FieldProps<IFormikValues>) => {
                              return (
                                <div className="d-flex align-items-center">
                                  <DatumKiezer
                                    onGewijzigd={(x) => {
                                      form.setFieldValue(field.name, x);
                                    }}
                                    waarde={field.value}
                                    determineValidDate={(date) => {
                                      return (
                                        date >= addDays(new Date(), -60) &&
                                        date < addDays(new Date(), 65)
                                      );
                                    }}
                                    determinePreviousValidDate="ONBEGRENST"
                                    determineNextValidDate="ONBEGRENST"
                                    // determinePreviousValidDate={(date) => {
                                    //   const minDate = addDays(new Date(), -1);
                                    //   const newDate = addDays(date, -1);
                                    //   if (newDate >= minDate) {
                                    //     return newDate;
                                    //   }
                                    //   return null;
                                    // }}
                                  />
                                </div>
                              );
                            }}
                          />
                        </div>
                      </div>

                      <div className="col-12 mt-3 pl-0">
                        <div className="col-4">
                          <label>{veldnamen.bedrag}</label>
                          <Field
                            name={nameof<IFormikValues>('bedrag')}
                            render={(x: FieldProps<IFormikValues>) => {
                              return (
                                <>
                                  <div>
                                    <BedragInput
                                      value={x.field.value}
                                      onChange={(bedrag) => {
                                        x.form.setFieldValue(x.field.name, bedrag);
                                      }}
                                    />
                                  </div>
                                  <FormikVeldFout fieldProps={x} />
                                </>
                              );
                            }}
                          />
                        </div>
                      </div>

                      <div className="col-12 mt-3">
                        <label>{veldnamen.aanleiding}</label>
                        <Field
                          name={nameof<IFormikValues>('aanleiding')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            return (
                              <Combobox
                                geselecteerd={field.value}
                                opties={aanleidingOpties.map((x) => {
                                  return {
                                    id: x.Aanleiding,
                                    label: x.Naam,
                                  };
                                })}
                                onSelectieChange={(x) => form.setFieldValue(field.name, x)}
                              />
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3 d-flex align-items-center">
                        <Field
                          name={nameof<IFormikValues>('nietOpvolgen')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <VinkVeld
                                aangevinkt={field.value}
                                onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                              />
                            );
                          }}
                        />
                        <span className="ml-2">{veldnamen.nietOpvolgen}</span>
                      </div>

                      <div className="col-12 mt-3 d-flex align-items-center">
                        <Field
                          name={nameof<IFormikValues>('kostenVtbNietBerekenen')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <VinkVeld
                                aangevinkt={field.value}
                                onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                              />
                            );
                          }}
                        />
                        <span className="ml-2">{veldnamen.kostenVtbNietBerekenen}</span>
                      </div>
                    </div>
                  </div>
                </ModalBody>

                <ModalFooter className="d-flex justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    disabled={isSubmitting || !isValid}
                    style={{ width: 100 }}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={handleAnnuleren}
                    disabled={isSubmitting || !isValid}
                    style={{ width: 100 }}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Dialoog>
  );
});

export default WijzigenVerkoopvoorstelDialoog;
