import { makeObservable, observable } from 'mobx';
import { RootStore } from './RootStore';
import api from '../api';
import * as _ from 'lodash';
import { ITekstGewijzigdMessageData } from '../../../shared/src/api/v2/tekst';

export type TekstID = number;
export type TaalID = number;
export type Teksten = Record<TekstID, Record<TaalID, string>>;

export enum EGetTekstStatus {
  Onbepaald = 'ONBEPAALD',
  GeplandVoorOphalen = 'GEPLAND_VOOR_OPHALEN',
  NietGevonden = 'NIET_GEVONDEN',
  Data = 'DATA',
}

export interface ITekst {
  taalID: number;
  tekst: string | null;
}

export interface IGetTekstOutput {
  status: EGetTekstStatus;
  data?: ITekst;
}

export interface ITekstenVerzamelItem {
  gewensteTaalID?: number;
  tekstID: number;
}

export enum EOphaalStatus {
  Gepland,
  Opgehaald,
}

class TekstStore {
  public teksten: Teksten = {};
  public tekstenOphaalQueue: Record<TekstID, Record<TaalID, EOphaalStatus>> = {};
  private rootStore: RootStore;
  private tekstenOphaalQueueTimeout: number | null = null;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    makeObservable(this, {
      teksten: observable,
      tekstenOphaalQueue: observable,
    });
  }

  public getTekst = (tekstID: TekstID, gewensteTaalID?: TaalID): IGetTekstOutput => {
    const tekstItem = this.teksten[tekstID];
    if (tekstItem === undefined) {
      if (this.tekstenOphaalQueue[tekstID] !== undefined) {
        return {
          status: EGetTekstStatus.GeplandVoorOphalen,
        };
      }
      return {
        status: EGetTekstStatus.Onbepaald,
      };
    }
    const standaardTaalID = this.rootStore.instellingStore.TaalID!;

    let taalID = gewensteTaalID === undefined ? standaardTaalID : gewensteTaalID;
    let taalItem = tekstItem[taalID];
    if (taalItem === undefined) {
      const tekstOphaalItem = this.tekstenOphaalQueue[tekstID];
      if (tekstOphaalItem !== undefined && tekstOphaalItem[taalID] !== undefined) {
        return {
          status: EGetTekstStatus.GeplandVoorOphalen,
        };
      }
      // Standaard taal moet altijd bestaan?
      taalID = standaardTaalID;
      taalItem = tekstItem[standaardTaalID]!;
    }
    return {
      status: EGetTekstStatus.Data,
      data: {
        taalID,
        tekst: taalItem,
      },
    };
  };

  public tekstenVerzamelen = (verzamelItems: ITekstenVerzamelItem[]) => {
    const ophaalQueueCopy = { ...this.tekstenOphaalQueue };

    for (const item of verzamelItems) {
      const taalID =
        item.gewensteTaalID === undefined
          ? this.rootStore.instellingStore.TaalID!
          : item.gewensteTaalID;

      if (
        this.teksten[item.tekstID] !== undefined &&
        this.teksten[item.tekstID][taalID] !== undefined
      ) {
        // Al opgehaald
        continue;
      }

      const bestaandTekstQueueItem = ophaalQueueCopy[item.tekstID];
      if (bestaandTekstQueueItem === undefined) {
        ophaalQueueCopy[item.tekstID] = {
          [taalID]: EOphaalStatus.Gepland,
        };
      } else {
        ophaalQueueCopy[item.tekstID] = {
          ...bestaandTekstQueueItem,
          [taalID]: EOphaalStatus.Gepland,
        };
      }
    }

    this.tekstenOphaalQueue = ophaalQueueCopy;

    if (this.tekstenOphaalQueueTimeout !== null) {
      clearTimeout(this.tekstenOphaalQueueTimeout);
    }
    // @ts-ignore
    this.tekstenOphaalQueueTimeout = setTimeout(this.ophalenVoorTekstVerzamelItems, 300);
  };

  public handleTekstGewijzigd = (data: ITekstGewijzigdMessageData) => {
    const taalData = data.taalTeksten.reduce((acc, curr) => {
      return {
        ...acc,
        [curr.taalID]: curr.tekst,
      };
    }, {});

    this.teksten = {
      ...this.teksten,
      [data.tekstID]: taalData,
    };
  };

  private ophalenVoorTekstVerzamelItems = async () => {
    const tekstenOphaalQueueTekstIDs = Object.keys(this.tekstenOphaalQueue).map((x) => Number(x));
    const tekstIDsMetVerwachteTaalIDs = tekstenOphaalQueueTekstIDs.map((tekstID) => {
      const tekstItem = this.tekstenOphaalQueue[tekstID]!;
      return {
        tekstID,
        taalIDs: Object.keys(tekstItem)
          .map((x) => Number(x))
          .filter((taalID) => tekstItem[taalID] !== EOphaalStatus.Opgehaald),
      };
    });

    const taalIDs: number[] = _.uniq(tekstIDsMetVerwachteTaalIDs.map((x) => x.taalIDs).flat());

    const results = await Promise.all(
      taalIDs.map(async (taalID) => {
        const tekstIDs = tekstIDsMetVerwachteTaalIDs
          .filter((x) => x.taalIDs.includes(taalID))
          .map((x) => x.tekstID);
        return await api.v2.tekst.ophalenTeksten({
          taalID,
          tekstIDs,
        });
      }),
    );

    const ophaalQueueCopy = { ...this.tekstenOphaalQueue };
    const tekstenCopy = { ...this.teksten };
    for (const result of results) {
      for (const item of results) {
        for (const x of item) {
          ophaalQueueCopy[x.TekstID] = {
            ...ophaalQueueCopy[x.TekstID],
            [x.TaalID]: EOphaalStatus.Opgehaald,
          };
          tekstenCopy[x.TekstID] = {
            ...tekstenCopy[x.TekstID],
            [x.TaalID]: x.Tekst === null ? '' : x.Tekst,
          };
        }
      }
    }
    this.tekstenOphaalQueue = ophaalQueueCopy;
    this.teksten = tekstenCopy;
  };
}

export default TekstStore;
