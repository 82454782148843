import React, { useContext, useMemo } from 'react';
import { DashboardContext, TegelBasis } from '../index';
import { ERemoteDataState } from '../../../../../models/IRemoteData';
import LoadingSpinner from '../../../../../components/Gedeeld/LoadingSpinner';
import {
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../../helpers/dxTableGrid';
import {
  Grid,
  VirtualTable,
  TableHeaderRow,
  TableColumnResizing,
} from '@devexpress/dx-react-grid-bootstrap4';
import * as _ from 'lodash';
import { IOphalenDebiteurenResultElement } from '../../../../../../../shared/src/api/v2/debiteur';

interface IProps {}

export interface IRegel extends IOphalenDebiteurenResultElement {}

const TopDebiteuren: React.FC<IProps> = (props) => {
  const context = useContext(DashboardContext);

  const topDebiteuren: IRegel[] = _.flatten(context.topDebiteuren.data!);

  const kolommen = useMemo<TypedColumn<IRegel>[]>(
    () => [
      {
        name: 'DebID',
        title: 'DEB_ID',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRegel>[]>(
    () => [
      {
        columnName: 'DebID',
        width: 90,
      },
    ],
    [],
  );

  return (
    true && (
      <TegelBasis titel="Top 5 debiteuren">
        {context.inkoopopdrachten.state === ERemoteDataState.Pending ? (
          <div className="d-flex flex-fill justify-content-center align-items-center">
            <LoadingSpinner />
          </div>
        ) : (
          <div>
            <GridStyleWrapper height={150}>
              <Grid rows={topDebiteuren!} columns={kolommen}>
                {/* <DataTypeProvider
                  for={['__magazijn']}
                  formatterComponent={(formatterProps) => {
                    const rij: IRegel = formatterProps.row;
                    return <span>{rij.magazijn.NaamKort}</span>;
                  }}
                /> */}

                <VirtualTable messages={{ noData: 'Er zijn geen debiteuren gevonden' }} />
                <TableColumnResizing defaultColumnWidths={kolomBreedtes} />

                <TableHeaderRow />
              </Grid>
            </GridStyleWrapper>
          </div>
        )}
      </TegelBasis>
    )
  );
};

export default TopDebiteuren;
