import {
  ICheckReferentieIsAanwezigParams,
  IWijzigenReferentieParams,
  IWijzigenReferentieResult,
} from '../../../../../../shared/src/api/v2/transport/opdracht/referentie';
import ICheckData from '../../../../../../shared/src/models/ICheckData';
import api from '../../../index';

const referentie = {
  // checkReferentieIsAanwezig: async (
  //   params: ICheckReferentieIsAanwezigParams,
  // ): Promise<ICheckData> => {
  //   return await api.post('v2/transport/opdracht/referenties/check-referentie-is-aanwezig', params);
  // },
  checkWijzigenReferentie: async (params: IWijzigenReferentieParams): Promise<ICheckData> => {
    return await api.post('v2/transport/opdracht/referenties/check-wijzigen-referentie', params);
  },
  wijzigenReferentie: async (
    params: IWijzigenReferentieParams,
  ): Promise<IWijzigenReferentieResult> => {
    return await api.post('v2/transport/opdracht/referenties/wijzigen-referentie', params);
  },
};

export default referentie;
