import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import IDialoogProps from '../../../../core/IDialoogProps';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import { RootStoreContext } from '../../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import VinkVeld from '../../../formulier/VinkVeld';
import api from '../../../../api';
import { EResultType } from '../../../../stores/CheckStore';
import * as Yup from 'yup';
import DatumKiezer from '../../../formulier/DatumKiezer';
import nameOf from '../../../../core/nameOf';
import { IOphalenRelatiesResultElementV2 } from '../../../../../../shared/src/api/v2/relatie';
import { EBetaalwijze } from '../../../../bedrijfslogica/enums';
import { achtergrondProcesAfwachten } from '../../../../core/achtergrondProces';
import { RealtimeContext } from '../../../../one-off-components/realtime/RealtimeIntegratie';
import {
  IProlongatieRunV2,
  IVerwerkenVoorstelProlongatiesParams,
  IVerwerkenVoorstelProlongatiesResult,
} from '../../../../../../shared/src/api/v2/prolongatie';
import { IMakenFactuurVoorstelResult } from '../../../../../../shared/src/api/v2/vordering';
import { dagDatum } from '../../../../helpers/datum';
import Dialoog from '../../../dialogen/Dialoog';
import { addDays, getMonth, getYear } from 'date-fns';
import VeldWeergave from '../../../formulier/VeldWeergave';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../models/IRemoteData';
import { IMakenBankopdrachtenResult } from '../../../../../../shared/src/api/v2/factuur';

interface IFormikValues {
  directFacturen: boolean;
  directBankopdrachten: boolean;
  directBatchMaken: boolean;
  factuurdatum: Date | null;
  uitvoerdatum: Date | null;
  geplandVerwerken: boolean;
}

interface IProps extends IDialoogProps<null> {
  prolRunID: number;
  prolCntIDs?: number[];
  relID?: number;
}

const veldnamen = {
  directFacturen: 'Direct facturen maken',
  directBankopdrachten: 'Direct bankopdrachten maken',
  directBatchMaken: 'Batch maken',
  factuurdatum: 'Factuurdatum',
  uitvoerdatum: 'Uitvoerdatum',
  geplandVerwerken: 'Gepland verwerken',
};

const ConfigurerenDialoog = observer((props: IProps) => {
  const { onAnnuleren, onSuccess, open, relID } = props;

  const { checkStore } = useContext(RootStoreContext);
  const realtimeContext = useContext(RealtimeContext);
  const formikRef = useRef<Formik<IFormikValues>>(null);

  const [relatie, setRelatie] = useState<IOphalenRelatiesResultElementV2 | null>(null);
  const [prolongatierun, setProlongatierun] = useState<IRemoteData<IProlongatieRunV2>>(
    createPendingRemoteData(),
  );

  // const [stufidatum, setStufidatum] = useState<Date | null>(null);

  // useEffect(() => {
  //   (async () => {
  //     if (prolongatierun.state === ERemoteDataState.Pending) {
  //       return null;
  //     }

  //     const stufidatum = await api.v2.debiteur.bepaalStufidatum({
  //       betaaldag: prolongatierun.data!.Betaaldag,
  //       maand: getMonth(new Date()) + 1,
  //       jaar: getYear(new Date()),
  //     });

  //     setStufidatum(stufidatum.datum as Date);
  //   })();
  // }, [prolongatierun.data?.Betaaldag]);

  useEffect(() => {
    (async () => {
      if (props.relID === undefined) {
        return;
      }
      const result = await api.v2.relatie.ophalenRelaties({
        filterSchema: { filters: [{ naam: 'IDS', data: [props.relID] }] },
      });
      setRelatie(result.relaties[0]);
    })();
  }, [props.relID]);

  useEffect(() => {
    (async () => {
      const result = await api.v2.prolongatie.ophalenProlongatieRunsV2({
        filterSchema: { filters: [{ naam: 'IDS', data: [props.prolRunID] }] },
      });
      const prolongatieRun = result.prolongatieRuns[0];
      setProlongatierun(createReadyRemoteData(prolongatieRun));
    })();
  }, [props.prolRunID]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      if (prolongatierun.data === null) {
        return;
      }
      const prolRun = prolongatierun.data!;

      actions.setSubmitting(true);

      const metDirectFacturenMaken = (value: any): any | null => {
        if (values.directFacturen) {
          return value;
        }
        return null;
      };

      const metDirectBankopdrachtenMaken = (value: any): any | null => {
        if (values.directBankopdrachten) {
          return value;
        }
        return null;
      };

      await api.v2.prolongatie.wijzigenProlongatieRun({
        id: prolRun.ID,
        geplandVerwerken: values.geplandVerwerken,
        facturenMaken: values.directFacturen,
        bankopdrachtenMaken: metDirectFacturenMaken(values.directBankopdrachten),
        factuurdatum: metDirectFacturenMaken(values.factuurdatum),
        uitvoerdatumBankopdrachten: metDirectFacturenMaken(values.uitvoerdatum),
        batchesMaken: metDirectBankopdrachtenMaken(metDirectFacturenMaken(values.directBatchMaken)),
      });

      // const vorderingenParams: IVerwerkenVoorstelProlongatiesParams = {
      //   prolRunID: props.prolRunID,
      //   prolCntIDs: props.prolCntIDs,
      // };
      //
      // // Deze check wordt ook al uitgevoerd wanneer de run geselecteerd wordt
      // const checkData = await api.v2.prolongatie.checkVerwerkenVoorstelProlongaties(
      //   vorderingenParams,
      // );
      //
      // const checkResult = await checkStore.controleren({
      //   checkData,
      // });
      // if (checkResult.type === EResultType.Annuleren) {
      //   actions.setSubmitting(false);
      //   return;
      // }

      // TODO Gilbert
      // Bepaal de uitvoerdatum voor de opdrachten
      // const uitvoerdatum = format(
      //   new Date(
      //     new Date().getFullYear(),
      //     new Date().getMonth() + (prolongatierun.Betaaldag! === 1 ? 1 : 0),
      //     prolongatierun.Betaaldag!,
      //   ),
      //   'yyyy-MM-dd',
      // );

      // if (
      //   (
      //     await checkStore.bevestigen({
      //       inhoud: (
      //         <span>
      //           Prolongatierun verwerken?
      //           <br />
      //           Dit kan - bij alle opties aangevinkt - soms meer dan +/- 15 minuten duren
      //           {/* {values.directBankopdrachten && (
      //             <>
      //               <br />
      //               De bankopdrachten krijgen als uitvoerdatum{' '}
      //               {format(new Date(values.uitvoerdatum), 'dd-MM-yyyy')}.
      //             </>
      //           )} */}
      //           {!values.directFacturen && (
      //             <>
      //               <br />
      //               Er worden alleen vorderingen gemaakt, de facturen dienen daarna nog zelf gemaakt
      //               te worden. Als dit niet de bedoeling is annuleer dan en vink 'Direct facturen
      //               maken' aan.
      //             </>
      //           )}
      //           <br />
      //           <br />
      //           Als de prolongatie een groot aantal posten heeft dan kan dit even duren.
      //         </span>
      //       ),
      //     })
      //   ).type === EResultType.Annuleren
      // ) {
      //   actions.setSubmitting(false);
      //   return;
      // }
      // console.log('>>>>prol1 verwerkenVoorstelProlongatiesAchtergrondProces>>>>>');
      // // Vorderingen maken voor het prolongatievoorstel
      // const vorderingenAchtergrondProces = await api.v2.prolongatie.verwerkenVoorstelProlongatiesAchtergrondProces(
      //   vorderingenParams,
      // );
      //
      // console.log('>>>>prol2 achtergrondProcesAfwachten>>>>>');
      // const vorderingenAchtergrondProcesResult = await achtergrondProcesAfwachten<
      //   IVerwerkenVoorstelProlongatiesResult
      // >(vorderingenAchtergrondProces.id, realtimeContext);
      //
      // console.log('>>>>prol2 eind>>>>>');
      //
      // if (vorderingenAchtergrondProcesResult.type !== 'DATA') {
      //   // Timeout of error
      //   alert('ERROR');
      //   return;
      // }
      //
      // const vorderingen = vorderingenAchtergrondProcesResult.data!;
      //
      // if (values.directFacturen) {
      //   console.log('>>>>prol3 makenFactuurVoorstelAchtergrondProces>>>>>');
      //
      //   // Facturen maken
      //   const facturenParams = {
      //     factRegIDs: vorderingen.IDs,
      //     factuurdatum: values.factuurdatum,
      //     definitief: true,
      //   };
      //
      //   const facturenAchtergrondProces = await api.v2.vordering.makenFactuurVoorstelAchtergrondProces(
      //     facturenParams,
      //   );
      //
      //   console.log('>>>>prol3 eind>>>>>');
      //
      //   console.log('>>>>prol4 achtergrondProcesAfwachten>>>>>');
      //
      //   const facturenAchtergrondProcesResult = await achtergrondProcesAfwachten<
      //     IMakenFactuurVoorstelResult
      //   >(facturenAchtergrondProces.id, realtimeContext);
      //
      //   console.log('>>>>prol4 eind>>>>>');
      //
      //   if (facturenAchtergrondProcesResult.type !== 'DATA') {
      //     // Timeout of error
      //     alert('ERROR');
      //     return;
      //   }
      //
      //   const facturen = facturenAchtergrondProcesResult.data!;
      //   const factIDs = facturen.factIDs;
      //
      //   console.log('>>>>prol5 factids bekend>>>>>');
      //
      //   // Definitief maken
      //
      //   // const definitiefAchtergrondProces = await api.v2.factuur.definitiefMakenAchtergrondProces({
      //   //   factIDs,
      //   // });
      //   // const definitiefAchtergrondProcesResult = await achtergrondProcesAfwachten<
      //   //   IDefinitiefMakenResult
      //   // >(definitiefAchtergrondProces.id, realtimeContext);
      //   // if (definitiefAchtergrondProcesResult.type !== 'DATA') {
      //   //   // Timeout of error
      //   //   alert('ERROR');
      //   //   return;
      //   // }
      //
      //   if (
      //     (props.relID === undefined ||
      //       relatie!.debiteur!.betaalwijze.NaamEnum === EBetaalwijze.Incasso) &&
      //     values.directBankopdrachten
      //   ) {
      //     // Maak bankopdrachten
      //     console.log('>>>>prol6 ophalenFacturenBasis>>>>>');
      //
      //     const { facturen } = await api.v2.factuur.ophalenFacturenBasis({
      //       filterSchema: {
      //         filters: [
      //           {
      //             naam: 'IDS',
      //             data: factIDs,
      //           },
      //           {
      //             naam: 'BETAALWIJZE_NAANENUMS',
      //             data: [EBetaalwijze.Incasso, EBetaalwijze.Verzamelbetaling],
      //           },
      //           // { naam: 'HEEFT_GEEN_BANKOPDRACHT', data: true },
      //         ],
      //       },
      //     });
      //
      //     console.log('>>>>prol6 eind>>>>>');
      //
      //     if (facturen.length !== 0) {
      //       const params = {
      //         factIDs: facturen.map((x) => x.FactID),
      //         uitvoerdatum: values.uitvoerdatum,
      //       };
      //
      //       const bankopdrachtenAchtergrondProces = await api.v2.factuur.makenBankopdrachtenAchtergrondProces(
      //         params,
      //       );
      //
      //       const bankopdrachtenAchtergrondProcesResult = await achtergrondProcesAfwachten<
      //         IMakenBankopdrachtenResult
      //       >(bankopdrachtenAchtergrondProces.id, realtimeContext);
      //
      //       // const opdrachtenResult = await api.v2.factuur.makenBankopdrachten({
      //       //   factIDs: facturen.map((x) => x.FactID),
      //       //   uitvoerdatum: values.uitvoerdatum,
      //       // });
      //
      //       console.log('>>>>prol7 eind>>>>>');
      //
      //       if (values.directBatchMaken) {
      //         console.log('>>>>prol8 verzamelenOpdrachten>>>>>');
      //
      //         // const result = await api.v2.bank.opdracht.verzamelenOpdrachten({
      //         //   opdIDs: opdrachtenResult.bankopdrachten.map((x) => x.BankOpdID),
      //         // });
      //         const verzamelenOpdrachtenAchtergrondProces = await api.v2.bank.opdracht.verzamelenOpdrachtenAchtergrondProces(
      //           {
      //             opdIDs: bankopdrachtenAchtergrondProcesResult.data!.bankopdrachten.map(
      //               (x) => x.BankOpdID,
      //             ),
      //           },
      //         );
      //         const verzamelenOpdrachtenAchtergrondProcesResult = await achtergrondProcesAfwachten<
      //           IMakenBankopdrachtenResult
      //         >(verzamelenOpdrachtenAchtergrondProces.id, realtimeContext);
      //
      //         console.log('>>>>prol8 eind>>>>>');
      //       }
      //     }
      //   }
      // }
      // console.log('>>>>prol99>>>>>');

      onSuccess(null);
      actions.setSubmitting(false);
    },
    [onSuccess, props.relID, props.prolCntIDs, relatie, prolongatierun],
  );

  const initieleWaarden = useMemo<IFormikValues | null>(() => {
    if (prolongatierun.state === ERemoteDataState.Pending) {
      return null;
    }
    const datumEersteVanDeMaand = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1);

    const factuurdatum =
      prolongatierun.data!.Factuurdatum !== null
        ? dagDatum(new Date(prolongatierun.data!.Factuurdatum))
        : null;

    const uitvoerdatum =
      prolongatierun.data!.UitvoerdatumBankopdrachten !== null
        ? dagDatum(new Date(prolongatierun.data!.UitvoerdatumBankopdrachten))
        : null;

    return {
      directFacturen: prolongatierun.data!.FacturenMaken ?? false,
      directBankopdrachten: prolongatierun.data!.BankopdrachtenMaken ?? false,
      directBatchMaken: prolongatierun.data!.BatchesMaken ?? false,
      geplandVerwerken: prolongatierun.data!.GeplandVerwerken,
      factuurdatum,
      uitvoerdatum,
    };
  }, [relatie, prolongatierun, props.relID]);

  // const validatieschema = Yup.object().shape({
  //   directFacturen: Yup.date().required(),
  // });

  if (initieleWaarden === null || (props.relID !== undefined && relatie === null)) {
    return null;
  }

  return (
    <Dialoog index={props.dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>
          Configureren{' '}
          {props.prolCntIDs === undefined ? 'prolongatierun' : 'prolongatie contract(en)'}
        </ModalTitle>
      </ModalHeader>
      <Formik<IFormikValues>
        ref={formikRef}
        isInitialValid
        enableReinitialize
        onSubmit={handleSubmit}
        initialValues={initieleWaarden}
        // validationSchema={validatieschema}
        validateOnChange
        validateOnBlur
        render={(formikProps: FormikProps<IFormikValues>) => {
          const { submitForm, isSubmitting, values, isValid } = formikProps;
          return (
            <>
              <ModalBody>
                <div className="form-group">
                  <div className="row">
                    <div className="col-12">
                      <VeldWeergave>
                        Prolongatierun is voor betaaldag&nbsp;
                        {prolongatierun.data!.Betaaldag === -1
                          ? 'Stufidatum'
                          : `${prolongatierun.data!.Betaaldag}`}
                      </VeldWeergave>
                    </div>
                    <div className="col-12 mt-4">
                      <span className="d-flex align-items-center">
                        <Field
                          name={nameOf<IFormikValues>('geplandVerwerken')}
                          render={(fieldProps: FieldProps) => {
                            const { field, form } = fieldProps;
                            return (
                              <VinkVeld
                                aangevinkt={field.value}
                                onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                              />
                            );
                          }}
                        />
                        <span className="ml-2">{veldnamen.geplandVerwerken}</span>
                      </span>
                    </div>

                    <div className="col-12 mt-4">
                      <span className="d-flex align-items-center">
                        <Field
                          name={nameOf<IFormikValues>('directFacturen')}
                          render={(fieldProps: FieldProps) => {
                            const { field, form } = fieldProps;
                            return (
                              <VinkVeld
                                aangevinkt={field.value}
                                onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                              />
                            );
                          }}
                        />
                        <span className="ml-2">{veldnamen.directFacturen}</span>
                      </span>
                    </div>

                    {values.directFacturen && (
                      <div className="col-12 mt-3 ml-4">
                        <label>{veldnamen.factuurdatum}</label>
                        <Field
                          name={nameOf<IFormikValues>('factuurdatum')}
                          render={({ field, form }: FieldProps<IFormikValues>) => {
                            const minimaleDatum = addDays(new Date(), -62);
                            const maximaleDatum = addDays(new Date(), +32);

                            return (
                              <div className="d-flex ">
                                <DatumKiezer
                                  onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                  waarde={field.value}
                                  determineValidDate={(date) => {
                                    return date >= minimaleDatum && date <= maximaleDatum;
                                  }}
                                  determineNextValidDate={(date) => {
                                    const nieuweDatum = addDays(date, +1);
                                    if (nieuweDatum <= maximaleDatum) {
                                      return nieuweDatum;
                                    }
                                    return null;
                                  }}
                                  determinePreviousValidDate={(date) => {
                                    const nieuweDatum = addDays(date, -1);
                                    if (nieuweDatum >= minimaleDatum) {
                                      return nieuweDatum;
                                    }
                                    return null;
                                  }}
                                />
                              </div>
                            );
                          }}
                        />
                      </div>
                    )}
                  </div>

                  {values.directFacturen &&
                    (props.relID === undefined ||
                      relatie!.debiteur!.betaalwijze.NaamEnum === EBetaalwijze.Incasso) && (
                      <div className="row">
                        <div className="col-6 mt-4">
                          <span className="d-flex align-items-center">
                            <Field
                              name={nameOf<IFormikValues>('directBankopdrachten')}
                              render={(fieldProps: FieldProps) => {
                                const { field, form } = fieldProps;
                                return (
                                  <VinkVeld
                                    aangevinkt={field.value}
                                    onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                  />
                                );
                              }}
                            />
                            <span className="ml-2">{veldnamen.directBankopdrachten}</span>
                          </span>
                        </div>

                        <div className="col-12 mt-3 ml-4">
                          <label>{veldnamen.uitvoerdatum}</label>
                          <Field
                            name={nameOf<IFormikValues>('uitvoerdatum')}
                            render={({ field, form }: FieldProps<IFormikValues>) => {
                              const minimaleDatum = addDays(new Date(), -1);
                              const maximaleDatum = addDays(new Date(), +32);

                              return (
                                <div className="d-flex ">
                                  <DatumKiezer
                                    onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                    waarde={field.value}
                                    determineValidDate={(date) => {
                                      return date >= minimaleDatum && date <= maximaleDatum;
                                    }}
                                    determineNextValidDate={(date) => {
                                      const nieuweDatum = addDays(date, +1);
                                      if (nieuweDatum <= maximaleDatum) {
                                        return nieuweDatum;
                                      }
                                      return null;
                                    }}
                                    determinePreviousValidDate={(date) => {
                                      const nieuweDatum = addDays(date, -1);
                                      if (nieuweDatum >= minimaleDatum) {
                                        return nieuweDatum;
                                      }
                                      return null;
                                    }}
                                  />
                                </div>
                              );
                            }}
                          />
                        </div>

                        {values.directBankopdrachten && (
                          <div className="col-6 mt-3 ml-3">
                            <span className="d-flex align-items-center">
                              <Field
                                name={nameOf<IFormikValues>('directBatchMaken')}
                                render={(fieldProps: FieldProps) => {
                                  const { field, form } = fieldProps;
                                  return (
                                    <VinkVeld
                                      aangevinkt={field.value}
                                      onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                    />
                                  );
                                }}
                              />
                              <span className="ml-2">{veldnamen.directBatchMaken}</span>
                            </span>
                          </div>
                        )}
                      </div>
                    )}
                </div>
              </ModalBody>
              <ModalFooter className="d-flex justify-content-start">
                <button
                  className="btn btn-primary"
                  onClick={submitForm}
                  disabled={isSubmitting || !isValid}
                  style={{ width: 100 }}
                >
                  Ok
                </button>
                <button
                  className="btn btn-secondary"
                  onClick={onAnnuleren}
                  disabled={isSubmitting}
                  style={{ width: 100 }}
                >
                  Annuleren
                </button>
              </ModalFooter>
            </>
          );
        }}
      />
    </Dialoog>
  );
});

export default ConfigurerenDialoog;
