import {
  DataTypeProvider,
  IntegratedSorting,
  SortingState,
  EditingState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-bootstrap4';
import _ from 'lodash';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';
import {
  IFaqGroepVraag,
  IGroepSorterenParams,
  IOphalenFaqResultElement,
  IOphalenGroepVragenParams,
  IOphalenGroepVragenResults,
} from '../../../../../../shared/src/api/v2/faq';
import { ISorterenParams } from '../../../../../../shared/src/api/v2/sorteren';
import { IOphalenTekstenResultElement } from '../../../../../../shared/src/api/v2/tekst';
import api from '../../../../api';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import DragHandle from '../../../../components/tabel/DragHandle';
import {
  DXCommandComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import Combobox from '../../../../components/formulier/Combobox';

interface IProps {
  Groep_ID: number;
  onSorteringVeranderd: (sortering: IGroepSorterenParams) => void;
  onVragenOphalen: (params: IOphalenGroepVragenParams) => Promise<IOphalenGroepVragenResults>;
}

interface IToevoegenState {}

const VraagSortering: React.FC<IProps> = (props) => {
  const [toevoegenState, setToevoegenState] = useState<IToevoegenState | null>(null);
  const [vragen, setVragen] = useState<IFaqGroepVraag[] | null>(null);
  const [faq, setFaq] = useState<IOphalenFaqResultElement[] | null>(null);
  const [teksten, setTeksten] = useState<IOphalenTekstenResultElement[] | null>(null);

  const ophalenPaginaVragen = useCallback(async () => {
    console.log('ophalzen');
    const results = await props.onVragenOphalen({ Groep_ID: props.Groep_ID });
    console.log(results);
    const gesorteerd = _.orderBy(results.vragen, ['SortNr'], ['asc']);
    setVragen(gesorteerd);
  }, []);

  useEffect(() => {
    ophalenPaginaVragen();
  }, [ophalenPaginaVragen]);

  const ophalenFaq = useCallback(async () => {
    const resultFaq = await api.v2.faq.ophalenFaq({});
    setFaq(resultFaq.faq);
    console.log(faq);
  }, []);

  useEffect(() => {
    ophalenFaq();
  }, [ophalenFaq]);

  const handleSorteringUpdate = () => {
    if (vragen == null) {
      return;
    }

    props.onSorteringVeranderd({
      Groep_ID: props.Groep_ID,
      elementen: vragen.map((g, i) => {
        return { ID: g.ID, SortNr: i + 1 };
      }),
    });
  };

  useEffect(() => {
    handleSorteringUpdate();
  }, [vragen]);

  const ophalenFaqOpties = useCallback(() => {
    if (faq == null || teksten == null || vragen == null) return [];

    return faq
      .filter((f) => vragen.find((v) => v.ID == f.ID) == null)
      .map((f) => {
        const tekst = teksten.find((x) => x.TekstID === f.Vraag_TekstID && x.TaalID === 1)!;
        return {
          id: f.ID,
          label: tekst?.Tekst != null ? tekst.Tekst : '',
        };
      });
  }, [teksten, vragen]);

  const ophalenTeksten = useCallback(async () => {
    if (vragen === null || faq === null) {
      return;
    }
    const tekstIDs = [
      ...vragen.map((x) => x.Vraag_TekstID).filter((x) => x !== null),
      ...faq.map((x) => x.Vraag_TekstID).filter((x) => x !== null),
    ];
    const resultTeksten = await api.v2.tekst.ophalenTekstenInAlleTalen({
      tekstIDs,
    });
    setTeksten(resultTeksten.teksten);
  }, [vragen, faq]);

  useEffect(() => {
    ophalenTeksten();
  }, [ophalenTeksten]);

  const handleVraagToevoegen = (id: number | null) => {
    if (vragen === null || faq === null || id === null) {
      return;
    }
    const faqVraag = faq.find((f) => f.ID == id);
    if (faqVraag == null) {
      return;
    }

    const nextSortNr = vragen.length === 0 ? 1 : Math.max(...vragen.map((v) => v.SortNr)) + 1;
    const vraag: IFaqGroepVraag = {
      ID: faqVraag.ID,
      Vraag_TekstID: faqVraag.Vraag_TekstID,
      SortNr: nextSortNr,
    };

    setVragen([...vragen, vraag]);
  };

  const handleVraagVerwijderen = (id: number) => {
    if (vragen == null) return;

    const index = vragen.findIndex((v) => v.ID == id);
    const nieuweVragen = [...vragen];

    nieuweVragen.splice(index, 1);
    nieuweVragen.forEach((v, i) => {
      v.SortNr = i + 1;
    });

    setVragen(nieuweVragen);
  };

  const keyExtractor = useCallback((row: IFaqGroepVraag) => row.ID, []);
  const kolommen = useMemo<TypedColumn<IFaqGroepVraag>[]>(
    () => [
      {
        name: '__dragHandle' as any,
        title: ' ',
      },
      {
        name: 'SortNr',
        title: 'Sort.',
      },
      {
        name: 'Vraag' as any,
        title: 'Vraag',
        getCellValue: (rij) => {
          if (teksten === null) {
            return;
          }
          const tekst = teksten.find((x) => x.TekstID === rij.Vraag_TekstID && x.TaalID === 1)!;
          return tekst !== undefined ? tekst.Tekst : '';
        },
      },
    ],
    [teksten],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IFaqGroepVraag>[]>(
    () => [
      {
        columnName: '__dragHandle',
        width: 50,
      },
      {
        columnName: 'SortNr',
        width: 75,
      },
      {
        columnName: 'Vraag' as any,
        width: 300,
      },
    ],
    [],
  );

  return (
    <>
      {vragen === null ? (
        <LoadingSpinner />
      ) : (
        <GridStyleWrapper>
          <Grid getRowId={keyExtractor} rows={vragen} columns={kolommen}>
            <DataTypeProvider
              for={['__dragHandle']}
              formatterComponent={(formatterProps) => {
                return <DragHandle />;
              }}
            />

            <SortingState defaultSorting={[]} />
            <IntegratedSorting />

            <Table
              messages={{ noData: 'Geen vragen aanwezig' }}
              rowComponent={({ row, ...restProps }) => {
                const TableRow = SortableElement(Table.Row);
                const r: IFaqGroepVraag = row;
                return r.SortNr === 999 ? (
                  <div />
                ) : (
                  <TableRow
                    {...restProps}
                    row={r}
                    index={vragen.findIndex((obj: IFaqGroepVraag) => obj.ID === r.ID)}
                  />
                );
              }}
              bodyComponent={({ row, ...restProps }: any) => {
                const TableBody = SortableContainer(Table.TableBody);
                return (
                  <TableBody
                    {...restProps}
                    style={{ 'user-select': 'none' }}
                    onSortEnd={async (x) => {
                      let nieuweSortering = [...vragen];
                      nieuweSortering = arrayMove(nieuweSortering, x.oldIndex, x.newIndex);
                      nieuweSortering.forEach((g, i) => {
                        g.SortNr = i + 1;
                      });
                      setVragen(nieuweSortering);
                    }}
                    useDragHandle
                  />
                );
              }}
            />

            <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
            <EditingState
              onAddedRowsChange={() => {
                if (toevoegenState === null) setToevoegenState({});
                else setToevoegenState(null);
              }}
              onCommitChanges={async (changes) => {
                if (changes.deleted === undefined) {
                  return;
                }
                const deleted = changes.deleted;
                const id = deleted[deleted.length - 1] as number;
                await handleVraagVerwijderen(id);
              }}
            />
            <TableHeaderRow />
            <TableEditColumn
              width={65}
              showDeleteCommand
              showAddCommand
              commandComponent={DXCommandComponent}
            />
          </Grid>
        </GridStyleWrapper>
      )}
      <br />
      {toevoegenState !== null && (
        <Combobox
          opties={ophalenFaqOpties()}
          legeOptieTonen={false}
          isWisbaar={false}
          onSelectieChange={(id) => {
            handleVraagToevoegen(id);
            setToevoegenState(null);
          }}
          geselecteerd={null}
        />
      )}
    </>
  );
};

export default VraagSortering;
