import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import * as Yup from 'yup';
import { IConcurrent, IConcurrentV2 } from '../../../../../../../shared/src/api/v2/concurrent';
import api from '../../../../../api';
import LoadingSpinner from '../../../../../components/Gedeeld/LoadingSpinner';
import Dialoog from '../../../../../components/dialogen/Dialoog';
import IDialoogProps from '../../../../../core/IDialoogProps';
import nameOf from '../../../../../core/nameOf';
import VinkVeld from '../../../../../components/formulier/VinkVeld';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../../models/IRemoteData';

interface IDialoogResult {}

interface IProps extends IDialoogProps<IDialoogResult> {
  id: number | null;
}

interface IFormikValues {
  naamEnum: string;
  naam: string;
  websiteUrl: string;
  inVergelijker: boolean;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  naamEnum: 'Naam enum',
  naam: 'Naam',
  websiteUrl: 'Website url',
  inVergelijker: 'In vergelijker',
};

const WijzigenDialoog: React.FC<IProps> = observer((props) => {
  const { dialoogIndex, onAnnuleren } = props;
  const [concurrent, setConcurrent] = useState<IRemoteData<IConcurrentV2 | null>>(
    props.id === null ? createReadyRemoteData(null) : createPendingRemoteData(),
  );

  const ophalenConcurrent = useCallback(async () => {
    const result = await api.v2.concurrentie.ophalenConcurrentenV2({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: [props.id],
          },
        ],
      },
    });
    const concurrent = result.concurrenten[0];

    setConcurrent(createReadyRemoteData(concurrent));
  }, [props.id]);

  useEffect(() => {
    ophalenConcurrent();
  }, [ophalenConcurrent]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      const params: IConcurrent = {
        ID: concurrent.data!.ID,
        NaamEnum: values.naamEnum,
        Naam: values.naam,
        WebsiteURL: values.websiteUrl,
        InVergelijker: values.inVergelijker,
      };

      if (concurrent.data?.ID === null || concurrent.data?.ID === undefined) {
        // bij nieuw toevoegen - voor nu twee standaard aanbod-delen erbij
        params.AanbodDelen = [{ Naam: 'particulier' }, { Naam: 'zakelijk' }];
      }

      await api.v2.concurrentie.toevoegenOfWijzigenConcurrent(params);

      actions.setSubmitting(false);
      props.onSuccess({});
    },
    [concurrent],
  );

  const initialValues = useMemo<IRemoteData<IFormikValues>>(() => {
    if (concurrent.state === ERemoteDataState.Pending) {
      return createPendingRemoteData();
    }
    if (concurrent.data === null) {
      return createReadyRemoteData({
        naamEnum: '',
        naam: '',
        websiteUrl: '',
        inVergelijker: true,
      });
    }

    const con = concurrent.data!;
    const values: IFormikValues = {
      naamEnum: con.NaamEnum ?? '',
      naam: con.Naam,
      websiteUrl: con.WebsiteURL ?? '',
      inVergelijker: con.InVergelijker,
    };

    return createReadyRemoteData(values);
  }, [concurrent]);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        naamEnum: Yup.string().required(),
      }),
    [],
  );

  return (
    <Dialoog index={dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>{props.id === null ? 'Toevoegen' : 'Wijzigen'} concurrent</ModalTitle>
      </ModalHeader>
      {initialValues.state === ERemoteDataState.Pending ? (
        <LoadingSpinner />
      ) : (
        <Formik<IFormikValues>
          onSubmit={handleSubmit}
          initialValues={initialValues.data!}
          validationSchema={validationSchema}
          enableReinitialize
          isInitialValid
          render={(formikProps: FormikProps<IFormikValues>) => {
            const { submitForm, isValid, isSubmitting } = formikProps;

            return (
              <>
                <ModalBody>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-12 mt-3">
                        <label>{veldnamen.naam}</label>
                        <Field
                          name={nameOf<IFormikValues>('naam')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field } = fieldProps;

                            return (
                              <>
                                <input type="text" className="form-control" {...field} />
                              </>
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <label>{veldnamen.naamEnum}</label>
                        <Field
                          name={nameOf<IFormikValues>('naamEnum')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field } = fieldProps;

                            return (
                              <>
                                <input type="text" className="form-control" {...field} />
                              </>
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <label>{veldnamen.websiteUrl}</label>
                        <Field
                          name={nameOf<IFormikValues>('websiteUrl')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field } = fieldProps;

                            return (
                              <>
                                <input type="text" className="form-control" {...field} />
                              </>
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <Field
                          name={nameOf<IFormikValues>('inVergelijker')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            return (
                              <div className="d-flex align-items-center">
                                <VinkVeld
                                  aangevinkt={field.value}
                                  onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                />
                                <span className="ml-2">{veldnamen.inVergelijker}</span>
                              </div>
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </ModalBody>

                <ModalFooter className="d-flex flex-row justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    style={{ width: 100 }}
                    disabled={!isValid || isSubmitting}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={onAnnuleren}
                    style={{ width: 100 }}
                    disabled={isSubmitting}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Dialoog>
  );
});

export default WijzigenDialoog;
