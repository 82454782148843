import React, { useCallback, useContext, useEffect, useMemo, useState, useRef } from 'react';
import IDialoogProps from '../../../../../core/IDialoogProps';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { Field, FieldProps, Formik, FormikActions, FormikErrors, FormikProps } from 'formik';
import { RootStoreContext } from '../../../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import VinkVeld from '../../../../../components/formulier/VinkVeld';
import api from '../../../../../api';
import { EResultType } from '../../../../../stores/CheckStore';
import * as Yup from 'yup';
import DatumKiezer from '../../../../../components/formulier/DatumKiezer';
import nameOf from '../../../../../core/nameOf';
import { addDays, format } from 'date-fns';
import { IOphalenOpdrachtenResultElement } from '../../../../../../../shared/src/api/v2/bank/opdracht/opdracht';
import { dagDatum } from '../../../../../helpers/datum';
import Dialoog from '../../../../../components/dialogen/Dialoog';
import LoadingSpinner from '../../../../../components/Gedeeld/LoadingSpinner';
import BankrekeningSelectieCombobox from '../../../../../components/formulier/BankrekeningSelectieCombobox';
import { IFilterSchemaFilter } from '../../../../../../../shared/src/models/filter';
import BedragInput from '../../../../../components/formulier/BedragInput';

interface IProps extends IDialoogProps<null> {
  bankOpdID: number;
}

interface IFormikValues {
  bedrag: number;
  uitvoerdatum: Date | null;
  bankRekID: number;
  omschrijving: string;
  betalingskenmerk: string;
  vrijgeven: boolean;
  stornoberichtVersturen: boolean;
  mandaat: string | null;
  mandaatdatum: Date | string | null;
}

const veldnamen = {
  bedrag: 'Bedrag',
  uitvoerdatum: 'Uitvoerdatum',
  bankRekID: 'Bedrijfsbankrekening',
  omschrijving: 'Omschrijving',
  betalingskenmerk: 'Betalingskenmerk',
  vrijgeven: 'Opdracht ophouden',
  stornoberichtVersturen: 'Stornobericht versturen',
  mandaat: 'Mandaat',
  mandaatdatum: 'Datum mandaat',
};

const WijzigOpdrachtDialoog = observer((props: IProps) => {
  const { dialoogIndex, onAnnuleren, onSuccess } = props;
  const { checkStore } = useContext(RootStoreContext);

  const [opdracht, setOpdracht] = useState<IOphalenOpdrachtenResultElement | null>(null);
  const [bankRekID, setBankRekID] = useState<number | null>(null);

  const ophalenOpdracht = useCallback(async () => {
    const opdrachtenResult = await api.v2.bank.opdracht.ophalenOpdrachten({
      filterSchema: { filters: [{ naam: 'IDS', data: [props.bankOpdID] }] },
    });
    const opdracht = opdrachtenResult.opdrachten[0];
    setOpdracht(opdracht);
  }, [props.bankOpdID]);

  useEffect(() => {
    ophalenOpdracht();
  }, [ophalenOpdracht]);

  const initieleWaarden = useMemo<IFormikValues | null>(() => {
    if (opdracht === null) {
      return null;
    }

    return {
      bedrag: opdracht.Bedrag < 0 ? -opdracht.Bedrag : opdracht.Bedrag,
      uitvoerdatum:
        opdracht.Uitvoerdatum !== null ? dagDatum(new Date(opdracht.Uitvoerdatum)) : null,
      bankRekID: opdracht.BankRekID,
      omschrijving: opdracht.Omschrijving !== null ? opdracht.Omschrijving : '',
      betalingskenmerk: opdracht.Betalingskenmerk !== null ? opdracht.Betalingskenmerk : '',
      vrijgeven: opdracht.Vrijgeven,
      stornoberichtVersturen: opdracht.StornoberichtVersturen,
      mandaat: opdracht.Mandaat,
      mandaatdatum:
        opdracht.MandaatDatum !== null
          ? dagDatum(new Date(opdracht.MandaatDatum))
          : opdracht.MandaatDatum,
    };
  }, [opdracht]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      const params = {
        bedrag: opdracht!.Bedrag < 0 ? -values.bedrag : values.bedrag,
        bankOpdID: props.bankOpdID,
        uitvoerdatum: values.uitvoerdatum,
        bankRekID: values.bankRekID,
        omschrijving: values.omschrijving !== '' ? values.omschrijving : null,
        betalingskenmerk: values.betalingskenmerk !== '' ? values.betalingskenmerk : null,
        vrijgeven: values.vrijgeven,
        stornoberichtVersturen: values.stornoberichtVersturen,
        wijzigingIBAN: opdracht!.WijzigingIBAN,
        mandaat: values.mandaat,
        mandaatdatum: values.mandaatdatum,
      };

      const checkData = await api.v2.bank.opdracht.checkWijzigenOpdracht(params);
      if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
        actions.setSubmitting(false);
        return;
      }

      const resultaat = await checkStore.bevestigen({
        inhoud: `Wijzigingen vastleggen?`,
      });
      if (resultaat.type === EResultType.Annuleren) {
        return;
      }

      const result = await api.v2.bank.opdracht.wijzigenOpdracht(params);

      onSuccess(null);

      actions.setSubmitting(false);
    },
    [onSuccess, opdracht, props.bankOpdID],
  );

  return (
    <Dialoog index={dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>
          Wijzigen{' '}
          {opdracht === null ? '' : opdracht?.Bedrag > 0 ? 'Incasso-opdracht' : 'Betaalopdracht'}
        </ModalTitle>
      </ModalHeader>

      {initieleWaarden === null ? (
        <div className="flex-fill d-flex align-items-center justify-content-center">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          <Formik<IFormikValues>
            isInitialValid
            initialValues={initieleWaarden}
            //   validate={handleValidate}
            onSubmit={handleSubmit}
            render={(FormikProps) => {
              const { submitForm, isSubmitting, values, errors } = FormikProps;
              return (
                <>
                  <ModalBody>
                    <div className="row">
                      <div className="row d-flex col-12">
                        <div className="col-6">
                          <div className="d-flex align-items-center">
                            <div>
                              <label>{veldnamen.bedrag}</label>
                              <Field
                                name={nameOf<IFormikValues>('bedrag')}
                                render={(fieldProps: FieldProps<IFormikValues>) => {
                                  const { field, form } = fieldProps;
                                  return (
                                    <BedragInput
                                      value={field.value || 0}
                                      onChange={(x) => {
                                        form.setFieldValue(field.name, x);
                                      }}
                                      min={0}
                                    />
                                  );
                                }}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-6">
                          <label>{veldnamen.uitvoerdatum}</label>
                          <Field
                            name={nameOf('uitvoerdatum')}
                            render={({ field, form }: FieldProps<IFormikValues>) => {
                              const minimaleDatum = addDays(new Date(), -1);
                              const maximaleDatum = addDays(new Date(), +365);

                              return (
                                <div className="d-flex align-items-center">
                                  <DatumKiezer
                                    onGewijzigd={(x) => {
                                      form.setFieldValue(field.name, x);
                                    }}
                                    waarde={field.value}
                                    determineValidDate={(date) => {
                                      return date >= minimaleDatum && date <= maximaleDatum;
                                    }}
                                    determineNextValidDate={(date) => {
                                      const nieuweDatum = addDays(date, +1);
                                      if (nieuweDatum <= maximaleDatum) {
                                        return nieuweDatum;
                                      }
                                      return null;
                                    }}
                                    determinePreviousValidDate={(date) => {
                                      const nieuweDatum = addDays(date, -1);
                                      if (nieuweDatum >= minimaleDatum) {
                                        return nieuweDatum;
                                      }
                                      return null;
                                    }}
                                  />
                                </div>
                              );
                            }}
                          />
                        </div>
                      </div>

                      <div className="col-12 mt-3">
                        <label>{veldnamen.omschrijving}</label>
                        <Field
                          name={nameOf<IFormikValues>('omschrijving')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            return <textarea {...field} className="form-control" rows={3} />;
                          }}
                        />
                      </div>

                      <div className="col-12 mt-2">
                        <label>{veldnamen.betalingskenmerk}</label>
                        <Field
                          name={nameOf<IFormikValues>('betalingskenmerk')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            return <textarea {...field} className="form-control" rows={1} />;
                          }}
                        />
                      </div>

                      <div className="col-6 mt-2">
                        <label>{veldnamen.mandaat}</label>
                        <Field
                          name={nameOf<IFormikValues>('mandaat')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            return <textarea {...field} className="form-control" rows={1} />;
                          }}
                        />
                      </div>

                      <div className="col-6 mt-2">
                        <label>{veldnamen.mandaatdatum}</label>
                        <Field
                          name={nameOf('mandaatdatum')}
                          render={({ field, form }: FieldProps<IFormikValues>) => {
                            const maximaleDatum = new Date();

                            return (
                              <div className="d-flex align-items-center">
                                <DatumKiezer
                                  onGewijzigd={(x) => {
                                    form.setFieldValue(field.name, x);
                                  }}
                                  waarde={field.value}
                                  determineValidDate={(date) => {
                                    return date <= maximaleDatum;
                                  }}
                                  determinePreviousValidDate={(date) => {
                                    const nieuweDatum = addDays(date, -1);
                                    return nieuweDatum;
                                  }}
                                  determineNextValidDate={(date) => {
                                    const nieuweDatum = addDays(date, +1);
                                    if (nieuweDatum <= maximaleDatum) {
                                      return nieuweDatum;
                                    }
                                    return null;
                                  }}
                                  isClearable
                                />
                              </div>
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-2">
                        <label className="mt-1">{veldnamen.bankRekID}</label>
                        <Field
                          name="bankRekID"
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            return (
                              <>
                                <BankrekeningSelectieCombobox
                                  filters={
                                    [
                                      { naam: 'IS_ACTIEF', data: true },
                                      opdracht!.Bedrag > 0
                                        ? { naam: 'INCASSO_CONTRACT', data: true }
                                        : null,
                                    ].filter((x) => x !== null) as IFilterSchemaFilter[]
                                  }
                                  bankRekID={fieldProps.field.value}
                                  onChange={(x) => {
                                    setBankRekID(x);
                                    return fieldProps.form.setFieldValue(fieldProps.field.name, x);
                                  }}
                                />
                              </>
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <span className="d-flex align-items-center">
                          <Field
                            name={nameOf<IFormikValues>('stornoberichtVersturen')}
                            render={(fieldProps: FieldProps) => {
                              const { field, form } = fieldProps;
                              return (
                                <VinkVeld
                                  aangevinkt={field.value}
                                  onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                />
                              );
                            }}
                          />
                          <span className="ml-2">{veldnamen.stornoberichtVersturen}</span>
                        </span>
                      </div>

                      <div className="col-12 mt-3 mb-2">
                        <span className="d-flex align-items-center">
                          <Field
                            name={nameOf<IFormikValues>('vrijgeven')}
                            render={(fieldProps: FieldProps) => {
                              const { field, form } = fieldProps;
                              return (
                                <VinkVeld
                                  aangevinkt={field.value}
                                  onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                />
                              );
                            }}
                          />
                          <span className="ml-2">{veldnamen.vrijgeven}</span>
                        </span>
                      </div>
                    </div>
                  </ModalBody>
                  <ModalFooter className="d-flex flex-row justify-content-start">
                    <button
                      className="btn btn-primary"
                      disabled={isSubmitting}
                      onClick={submitForm}
                      style={{ width: 100 }}
                    >
                      Ok
                    </button>
                    <button
                      className="btn btn-secondary"
                      onClick={onAnnuleren}
                      style={{ width: 100 }}
                    >
                      Annuleren
                    </button>
                  </ModalFooter>
                </>
              );
            }}
          />
        </>
      )}
    </Dialoog>
  );
});

export default WijzigOpdrachtDialoog;
