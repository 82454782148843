import styled from 'styled-components';
import { Kleur } from '../../../bedrijfslogica/constanten';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  width: 475px;
  height: 100%;
  max-height: 100vh;
  overflow: hidden;

  background-color: ${Kleur.Wit};
  flex-flow: column;
`;
