import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../stores/RootStore';
import CheckDialoog from './CheckDialoog';
import OkAnnulerenDialoog, {
  IOkAnnulerenDialoogProps,
} from '../../components/dialogen/OkAnnulerenDialoog';
import { EResultType } from '../../stores/CheckStore';
import OkDialoog from '../../components/dialogen/OkDialoog';

interface IProps {}

const CheckDialoogRenderer: React.FC<IProps> = observer((props) => {
  const { checkStore } = useContext(RootStoreContext);

  return (
    <>
      {Object.keys(checkStore.controlerenChecks).map((id, i) => {
        const check = checkStore.controlerenChecks[id];
        let index = i;
        if (check.params.options !== undefined) {
          const opt = check.params.options!;
          if (opt.dialoogIndex !== undefined) {
            index = i + opt.dialoogIndex;
          }
        }

        return (
          <CheckDialoog
            key={id}
            data={check.params}
            resolve={check.resolve}
            id={id}
            index={index}
          />
        );
      })}
      {Object.keys(checkStore.bevestigenChecks).map((id, i) => {
        const check = checkStore.bevestigenChecks[id];

        const tekstenProps: Partial<IOkAnnulerenDialoogProps> =
          check.params.weergavemodus === undefined || check.params.weergavemodus === 'ok_annuleren'
            ? {
                okTekst: 'Ok',
                annulerenTekst: 'Annuleren',
              }
            : {
                okTekst: 'Ja',
                annulerenTekst: 'Nee',
              };

        return (
          <div key={id}>
            <OkAnnulerenDialoog
              open
              {...tekstenProps}
              title={check.params.titel}
              body={check.params.inhoud}
              dialoogIndex={i}
              okLoading={check.data && check.data.bezigMetAsynchroonBevestigen}
              onSuccess={async () => {
                if (check.params.asynchroneActieNaBevestigingFn !== undefined) {
                  const nieuweChecks = {
                    ...checkStore.bevestigenChecks,
                  };
                  nieuweChecks[id] = {
                    ...check,
                    data: {
                      ...check.data,
                      bezigMetAsynchroonBevestigen: true,
                    },
                  };
                  checkStore.bevestigenChecks = nieuweChecks;
                  await check.params.asynchroneActieNaBevestigingFn();
                }
                const nieuweChecks = {
                  ...checkStore.bevestigenChecks,
                };
                delete nieuweChecks[id];
                checkStore.bevestigenChecks = nieuweChecks;
                check.resolve({
                  type: EResultType.Ok,
                });
              }}
              onAnnuleren={() => {
                const nieuweChecks = {
                  ...checkStore.bevestigenChecks,
                };
                delete nieuweChecks[id];
                checkStore.bevestigenChecks = nieuweChecks;
                check.resolve({
                  type: EResultType.Annuleren,
                });
              }}
            />
          </div>
        );
      })}
      {Object.keys(checkStore.meldenDatas).map((id, i) => {
        const meldData = checkStore.meldenDatas[id];

        return (
          <div key={id}>
            <OkDialoog
              open
              body={meldData.params.titel}
              dialoogIndex={i}
              onSuccess={() => {
                const nieuweMelddatas = {
                  ...checkStore.meldenDatas,
                };
                delete nieuweMelddatas[id];
                checkStore.meldenDatas = nieuweMelddatas;
                meldData.resolve();
              }}
            />
          </div>
        );
      })}
    </>
  );
});

export default CheckDialoogRenderer;
