import {
  IToevoegenAbonnementParams,
  IVerwijderenAbonnementenParams,
  ICheckVerwijderenAbonnementenParams,
  IWijzigenAbonnementParams,
  ICheckToevoegenAbonnementParams,
  ICheckWijzigenAbonnementParams,
  IHerordenenLijstResult,
  IHerordenenLijstParams,
  IOphalenAbonnementenParams,
  IOphalenAbonnementenResult,
} from '../../../../../shared/src/api/v2/aanbod/Abonnement';
import {
  IOphalenAbonnementenVoorRijbronParams,
  IOphalenAbonnementenVoorRijbronResult,
} from '../../../../../shared/src/api/v2/aanbod/index';

import api from '../../../api';

const abonnement = {
  ophalenAbonnementenVoorLijst: async (params: { actief: boolean | null }) => {
    return await api.post('v2/abonnement/ophalen-abonnementen-voor-lijst', params);
  },
  toevoegenAbonnement: async (params: IToevoegenAbonnementParams) => {
    return await api.post('v2/abonnement/toevoegen-abonnement', params);
  },
  checkToevoegenAbonnement: async (params: ICheckToevoegenAbonnementParams) => {
    return await api.post('v2/abonnement/check-toevoegen-abonnement', params);
  },
  checkWijzigenAbonnement: async (params: ICheckWijzigenAbonnementParams) => {
    return await api.post('v2/abonnement/check-wijzigen-abonnement', params);
  },
  verwijderenAbonnement: async (params: IVerwijderenAbonnementenParams) => {
    return await api.post('v2/abonnement/verwijderen-abonnementen', params);
  },
  verwijderenAbonnementCheck: async (params: ICheckVerwijderenAbonnementenParams) => {
    return await api.post('v2/abonnement/check-verwijderen-abonnementen', params);
  },
  ophalenAbonnementen: async (params: IOphalenAbonnementenParams) => {
    return await api.post<IOphalenAbonnementenResult>('v2/abonnement/ophalen-abonnementen', params);
  },
  // ophalenAbonnement: async (params: IOphalenAbonnementParams) => {
  //   return await api.post<IOphalenAbonnementResult>('v2/abonnement/ophalen-abonnement', params);
  // },
  wijzigenAbonnement: async (params: IWijzigenAbonnementParams) => {
    return await api.post('v2/abonnement/wijzigen-abonnement', params);
  },
  herordenenLijst: async (params: IHerordenenLijstParams) => {
    return await api.post<IHerordenenLijstResult>('v2/abonnement/herordenen-lijst', params);
  },

  ophalenAbonnementenVoorRijbron: async (params: IOphalenAbonnementenVoorRijbronParams) => {
    return await api.post<IOphalenAbonnementenVoorRijbronResult>(
      '/v2/aanbod/ophalen-abonnementen-voor-rijbron',
      params,
    );
  },
};

export default abonnement;
