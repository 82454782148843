import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import MenuLayout from '../../components/MenuLayout';
import ASPTabel from '../../components/tabel/ASPTabel';
import {
  ASPKolom,
  EAspKolomBreedteType,
  ESortering,
  ESorteringModus,
  IAspKolomSorteringItem,
} from '../../components/tabel/ASPTabel/types';
import { ILeegComponentProps } from '../../components/tabel/ASPTabel/Body';
import { Kleur } from '../../bedrijfslogica/constanten';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../models/IRemoteData';
import LoadingSpinner from '../../components/Gedeeld/LoadingSpinner';
import api from '../../api';
import {
  IPotentieelOntvangenInkoopfactuurItem,
  IPotentieelOntvangenInslagReferentieItem,
  IPotentieelOntvangenItem,
  IPotentieelOntvangenTelefoonnotitieItem,
  IPotentieelOntvangenTransportopdrachtAfleverbewijsItem,
  IPotentieelOntvangenWerkbonItem,
} from '../../../../shared/src/api/v2/externeData';
import { format } from 'date-fns';
import TelefoonnotitieWeergave from './weergaven/TelefoonnotitieWeergave';
import { IconEmail, IconKruis, IconTerugdraaien, IconWhatsapp } from '../../components/Icons';
import { IUitgeklapteRijProps } from '../../components/tabel/ASPTabel/Body/UitgeklapteRij';
import TabelInspringBlok from '../../components/layout/TabelInspringBlok';
import useUrlState from '../../core/useUrlState';
import InkoopfactuurWeergave from './weergaven/InkoopfactuurWeergave';
import TelefoonnotitieUitgeklapteInhoud from './uitgeklapteInhouden/TelefoonnotitieUitgeklapteInhoud';
import InkoopfactuurUitgeklapteInhoud from './uitgeklapteInhouden/InkoopfactuurUitgeklapteInhoud';
import FilterBalkV2, {
  IFilter,
  IFilterData,
  maakFilterSchema,
} from '../../components/FilterBalkV2';
import { EPotentieelOntvangenItemStatus } from '../../bedrijfslogica/enums';
import MultiSelect from '../../components/formulier/MultiSelect';
import { GlobaleRendererContext } from '../../one-off-components/GlobaleRenderer';
import { observer } from 'mobx-react-lite';
import RelatieSelectieDialoog, {
  IRelatieSelectieDialoogResult,
} from '../../components/personalia/RelatieSelectieDialoog';
import WerkbonWeergave from './weergaven/WerkbonWeergave';
import { IOrderSchemaOrder } from '../../../../shared/src/models/order';
import TransportopdrachtAfleverbewijsWeergave from './weergaven/TransportopdrachtAfleverbewijsWeergave';
import TransportopdrachtAfleverbewijsUitgeklapteInhoud from './uitgeklapteInhouden/TransportopdrachtAfleverbewijsUitgeklapteInhoud';
import TransportopdrachtSelectieDialoog, {
  ITransportopdrachtSelectieDialoogResult,
} from '../../components/transport/TransportopdrachtSelectieDialoog';
import IPaginatiePositie from '../../../../shared/src/models/IPaginatiePositie';
import ActieMenuKnop from '../../components/ActieMenuKnop';
import NieuweFactuurDialoogV2, {
  IDialoogResult,
} from '../Crediteuren/Inkoopfacturen/Inboeken/NieuweFactuurDialoogV2';
import WerkbonMutatieDialoog from '../../components/service/WerkbonMutatieDialoog';
import { IOphalenOpdrachtenResultElement } from '../../../../shared/src/api/v2/service/opdracht';
import { IOphalenMeldingenResultElement } from '../../../../shared/src/api/v2/service/melding';
import WerkbonUitgeklapteInhoud from './uitgeklapteInhouden/WerkbonUitgeklapteInhoud';
import InslagReferentieUitgeklapteInhoud from './uitgeklapteInhouden/InslagReferentieUitgeklapteInhoud';
import { RootStoreContext } from '../../stores/RootStore';
import { EResultType } from '../../stores/CheckStore';
import _ from 'lodash';

enum EKolom {
  Datum,
  Type,
  Communicatieitem,
  Status,
  Weergave,
  Acties,
}

enum ECommunicatiekanaal {
  Email,
  Whatsapp,
}

enum ERegelType {
  Telefoonnotitie = 'TELEFOONNOTITIE',
  Inkoopfactuur = 'INKOOPFACTUUR',
  Werkbon = 'WERKBON',
  InslagReferentie = 'INSLAG_REFERENTIE',
  TransportopdrachtAfleverbewijs = 'TRANSPORTOPDRACHT_AFLEVERBEWIJS',
}

interface ITelefoonnotitieRegel {
  type: ERegelType.Telefoonnotitie;
  telefoonnotitieItem: IPotentieelOntvangenTelefoonnotitieItem;
}

interface IInkoopfactuurRegel {
  type: ERegelType.Inkoopfactuur;
  inkoopfactuurItem: IPotentieelOntvangenInkoopfactuurItem;
}

interface IWerkbonRegel {
  type: ERegelType.Werkbon;
  werkbonItem: IPotentieelOntvangenWerkbonItem;
}

interface IInslagReferentieRegel {
  type: ERegelType.InslagReferentie;
  inslagReferentieItem: IPotentieelOntvangenInslagReferentieItem;
}

interface ITransportopdrachtAfleverbewijsRegel {
  type: ERegelType.TransportopdrachtAfleverbewijs;
  transportopdrachtAfleverbewijsItem: IPotentieelOntvangenTransportopdrachtAfleverbewijsItem;
}

export interface IRegelBase {
  item: IPotentieelOntvangenItem;
  communicatiekanaal: ECommunicatiekanaal;
  datum: Date;
  status: EPotentieelOntvangenItemStatus;
}

type Regel = IRegelBase &
  (
    | ITelefoonnotitieRegel
    | IInkoopfactuurRegel
    | IWerkbonRegel
    | IInslagReferentieRegel
    | ITransportopdrachtAfleverbewijsRegel
  );

interface IRegelsData {
  regels: Record<number, Regel>;
  totaalAantal: number;
}

const LeegComponent = (props: ILeegComponentProps) => {
  return (
    <div
      style={{ width: props.width, height: props.height }}
      className="d-flex align-items-center justify-content-center"
    >
      <h3>Er zijn geen items beschikbaar</h3>
    </div>
  );
};

export interface IUitgeklapteInhoudProps<TRow, TItem> {
  regel: TRow;
  item: TItem;
}

const UitgeklapteRij = (props: IUitgeklapteRijProps<EKolom, Regel>) => {
  let inhoud: JSX.Element | undefined;

  switch (props.regel.type) {
    case ERegelType.Inkoopfactuur: {
      inhoud = (
        <InkoopfactuurUitgeklapteInhoud regel={props.regel} item={props.regel.inkoopfactuurItem} />
      );
      break;
    }
    case ERegelType.Telefoonnotitie: {
      inhoud = (
        <TelefoonnotitieUitgeklapteInhoud
          regel={props.regel}
          item={props.regel.telefoonnotitieItem}
        />
      );
      break;
    }
    case ERegelType.InslagReferentie: {
      inhoud = (
        <InslagReferentieUitgeklapteInhoud
          regel={props.regel}
          item={props.regel.inslagReferentieItem}
        />
      );
      break;
    }
    case ERegelType.TransportopdrachtAfleverbewijs: {
      inhoud = (
        <TransportopdrachtAfleverbewijsUitgeklapteInhoud
          regel={props.regel}
          item={props.regel.transportopdrachtAfleverbewijsItem}
        />
      );
      break;
    }
    case ERegelType.Werkbon: {
      inhoud = <WerkbonUitgeklapteInhoud regel={props.regel} item={props.regel.werkbonItem} />;
      break;
    }
    default: {
      inhoud = (
        <div className="flex-fill d-flex align-items-center justify-content-center">
          <h4>Geen informatie beschikbaar.</h4>
        </div>
      );
      break;
    }
  }

  return (
    <div style={{ ...props.style, flexDirection: 'row' }}>
      <TabelInspringBlok />
      <div className="d-flex flex-column flex-fill p-3 pl-4 pr-4">{inhoud}</div>
    </div>
  );
};

enum EFilter {
  Statussen = 'STATUSSEN',
  Type = 'TYPEN',
}

interface IUrlState {
  uitgeklapt: number[];
  filterData: IFilterData<EFilter>[];
  sortering: IAspKolomSorteringItem<EKolom>[];
}

interface IProps extends RouteComponentProps {}

const ExterneData = observer((props: IProps) => {
  const globaleRenderer = useContext(GlobaleRendererContext);
  const defaultUrlState = useMemo<IUrlState>(
    () => ({
      uitgeklapt: [],
      filterData: [
        {
          naam: EFilter.Statussen,
          data: [EPotentieelOntvangenItemStatus.Beoordelen],
          isActief: true,
        },
        {
          naam: EFilter.Type,
          data: [ERegelType.Inkoopfactuur],
          isActief: false,
        },
      ],
      sortering: [
        {
          key: EKolom.Datum,
          sortering: ESortering.Descending,
        },
      ],
    }),
    [],
  );
  const [urlState, , setUrlStateSync] = useUrlState(props, defaultUrlState);
  const [aanHetVerwerken, setAanHetVerwerken] = useState(false);
  const { checkStore } = useContext(RootStoreContext);

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.Statussen,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => (
          <div className="d-flex align-items-center">
            <span className="mr-2">Status</span>
            <MultiSelect
              value={weergaveProps.data}
              onChange={(data) => {
                weergaveProps.onDataChange(data);
                weergaveProps.setIsActief(true);
                weergaveProps.toepassen();
              }}
              opties={[
                {
                  key: EPotentieelOntvangenItemStatus.Beoordelen,
                  weergave: 'Beoordelen',
                },
                {
                  key: EPotentieelOntvangenItemStatus.AutomatischVerwerkt,
                  weergave: 'Automatisch verwerkt',
                },
                {
                  key: EPotentieelOntvangenItemStatus.HandmatigVerwerkt,
                  weergave: 'Handmatig verwerkt',
                },
                {
                  key: EPotentieelOntvangenItemStatus.Afgewezen,
                  weergave: 'Afgewezen',
                },
              ]}
              isMulti
            />
          </div>
        ),
      },
      {
        naam: EFilter.Type,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => (
          <div className="d-flex align-items-center">
            <span className="mr-2">Type</span>
            <MultiSelect
              value={weergaveProps.data}
              onChange={(data) => {
                weergaveProps.onDataChange(data);
                weergaveProps.setIsActief(true);
                weergaveProps.toepassen();
              }}
              opties={[
                {
                  key: ERegelType.Werkbon,
                  weergave: 'Werkbon',
                },
                {
                  key: ERegelType.Inkoopfactuur,
                  weergave: 'Inkoopfactuur',
                },
                {
                  key: ERegelType.Telefoonnotitie,
                  weergave: 'Telefoonnotitie',
                },
                {
                  key: ERegelType.InslagReferentie,
                  weergave: 'InslagReferentie',
                },
                {
                  key: ERegelType.TransportopdrachtAfleverbewijs,
                  weergave: 'Transportopdracht afleverbewijs',
                },
              ]}
              isMulti
            />
          </div>
        ),
      },
    ],
    [],
  );
  const filterSchema = useMemo(() => maakFilterSchema(urlState.filterData), [
    JSON.stringify(urlState.filterData),
  ]);

  const regelsDataRef = useRef<IRemoteData<IRegelsData>>(createPendingRemoteData());
  const [regelsDataState, setRegelsDataState] = useState<IRemoteData<IRegelsData>>(
    regelsDataRef.current,
  );
  const regelsData = useMemo(() => regelsDataState, [regelsDataState]);
  const setRegelsData = useCallback(
    (data: IRemoteData<IRegelsData>) => {
      regelsDataRef.current = data;
      setRegelsDataState(data);
    },
    [setRegelsDataState],
  );

  const bepalenRegelsData = useCallback(
    async (paginatie: IPaginatiePositie, uitbreiden: boolean) => {
      const potentieelOntvangenItemsResult = await api.v2.externeData.ophalenPotentieelOntvangenItems(
        {
          paginatie,
          filterSchema,
          orderSchema: {
            orders: urlState.sortering
              .map((item) => {
                switch (item.key) {
                  case EKolom.Datum:
                    return {
                      naam: 'DATUM',
                      richting: item.sortering === ESortering.Ascending ? 'ASC' : 'DESC',
                    };
                }

                return null;
              })
              .filter((item) => item !== null) as IOrderSchemaOrder[],
          },
        },
      );
      const potentieelOntvItemIDs = potentieelOntvangenItemsResult.items.map((item) => item.ID);

      const [
        telefoonnotitieResult,
        inkoopfactuurResult,
        werkbonResult,
        inslagReferentieResult,
        transportopdrachtAfleverbewijsResult,
      ] = await Promise.all([
        api.v2.externeData.ophalenPotentieelOntvangenTelefoonnotitieItems({
          filterSchema: {
            filters: [
              {
                naam: 'POTENTIEEL_ONTV_ITEM_IDS',
                data: potentieelOntvItemIDs,
              },
            ],
          },
        }),
        api.v2.externeData.ophalenPotentieelOntvangenInkoopfactuurItems({
          filterSchema: {
            filters: [
              {
                naam: 'POTENTIEEL_ONTV_ITEM_IDS',
                data: potentieelOntvItemIDs,
              },
            ],
          },
        }),
        api.v2.externeData.ophalenPotentieelOntvangenWerkbonItems({
          filterSchema: {
            filters: [
              {
                naam: 'POTENTIEEL_ONTV_ITEM_IDS',
                data: potentieelOntvItemIDs,
              },
            ],
          },
        }),
        api.v2.externeData.ophalenPotentieelOntvangenInslagReferentieItems({
          filterSchema: {
            filters: [
              {
                naam: 'POTENTIEEL_ONTV_ITEM_IDS',
                data: potentieelOntvItemIDs,
              },
            ],
          },
        }),
        api.v2.externeData.ophalenPotentieelOntvangenTransportopdrachtAfleverbewijsItems({
          filterSchema: {
            filters: [
              {
                naam: 'POTENTIEEL_ONTV_ITEM_IDS',
                data: potentieelOntvItemIDs,
              },
            ],
          },
        }),
      ]);

      const telefoonnotitieItemsViaPotentieelOntvItemID: Record<
        number,
        IPotentieelOntvangenTelefoonnotitieItem
      > = telefoonnotitieResult.items.reduce((acc, curr) => {
        return {
          ...acc,
          [curr.PotentieelOntvItemID]: curr,
        };
      }, {});

      const inkoopfactuurItemsViaPotentieelOntvItemID: Record<
        number,
        IPotentieelOntvangenInkoopfactuurItem
      > = inkoopfactuurResult.items.reduce((acc, curr) => {
        return {
          ...acc,
          [curr.PotentieelOntvItemID]: curr,
        };
      }, {});

      const werkbonItemsViaPotentieelOntvItemID: Record<
        number,
        IPotentieelOntvangenWerkbonItem
      > = werkbonResult.items.reduce((acc, curr) => {
        return {
          ...acc,
          [curr.PotentieelOntvItemID]: curr,
        };
      }, {});

      const inslagReferentieItemsViaPotentieelOntvItemID: Record<
        number,
        IPotentieelOntvangenInslagReferentieItem
      > = inslagReferentieResult.items.reduce((acc, curr) => {
        return {
          ...acc,
          [curr.PotentieelOntvItemID]: curr,
        };
      }, {});

      const transportopdrachtAfleverbewijsItemsViaPotentieelOntvItemID: Record<
        number,
        IPotentieelOntvangenTransportopdrachtAfleverbewijsItem
      > = transportopdrachtAfleverbewijsResult.items.reduce((acc, curr) => {
        return {
          ...acc,
          [curr.PotentieelOntvItemID]: curr,
        };
      }, {});

      const data: IRegelsData = {
        totaalAantal: potentieelOntvangenItemsResult.totaalAantal,
        regels: potentieelOntvangenItemsResult.items.reduce<Record<number, Regel>>(
          (acc, item, i) => {
            const telefoonnotitieItem = telefoonnotitieItemsViaPotentieelOntvItemID[item.ID];
            const inkoopfactuurItem = inkoopfactuurItemsViaPotentieelOntvItemID[item.ID];
            const werkbonItem = werkbonItemsViaPotentieelOntvItemID[item.ID];
            const inslagReferentieItem = inslagReferentieItemsViaPotentieelOntvItemID[item.ID];
            const transportopdrachtAfleverbewijsItem =
              transportopdrachtAfleverbewijsItemsViaPotentieelOntvItemID[item.ID];

            const communicatiekanaal =
              item.EmailBerID !== null
                ? ECommunicatiekanaal.Email
                : item.WhatsappBerID !== null
                ? ECommunicatiekanaal.Whatsapp
                : null;

            if (communicatiekanaal === null) {
              throw new Error('Communicatiekanaal kon niet worden bepaald');
            }

            let type: ERegelType;
            if (telefoonnotitieItem !== undefined) {
              type = ERegelType.Telefoonnotitie;
            } else if (inkoopfactuurItem !== undefined) {
              type = ERegelType.Inkoopfactuur;
            } else if (werkbonItem !== undefined) {
              type = ERegelType.Werkbon;
            } else if (inslagReferentieItem !== undefined) {
              type = ERegelType.InslagReferentie;
            } else if (transportopdrachtAfleverbewijsItem !== undefined) {
              type = ERegelType.TransportopdrachtAfleverbewijs;
            } else {
              throw new Error('Regeltype kon niet worden bepaald');
            }

            return {
              ...acc,
              [paginatie.index + i]: {
                item,
                communicatiekanaal,
                type,
                datum: new Date(item.Datum),
                telefoonnotitieItem,
                inkoopfactuurItem,
                werkbonItem,
                inslagReferentieItem,
                transportopdrachtAfleverbewijsItem,
                status: item.Status as EPotentieelOntvangenItemStatus,
              },
            };
          },
          uitbreiden ? regelsDataRef.current?.data?.regels ?? {} : {},
        ),
      };

      setRegelsData(createReadyRemoteData(data));
    },
    [filterSchema, JSON.stringify(urlState.sortering)],
  );

  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    bepalenRegelsData(
      {
        index: 0,
        aantal: 50,
      },
      false,
    );
  }, [bepalenRegelsData]);

  enum EVerwerkRegelResult {
    Verwerkt,
    Afgebroken,
  }
  const verwerkRegel = useCallback(
    async (regel: Regel): Promise<EVerwerkRegelResult> => {
      switch (regel.type) {
        case ERegelType.Telefoonnotitie: {
          const not = regel.telefoonnotitieItem;
          const result = await globaleRenderer.render<IRelatieSelectieDialoogResult>(
            (renderProps) => (
              <RelatieSelectieDialoog
                open
                onSuccess={async (result) => {
                  renderProps.destroy(result);
                }}
                onAnnuleren={() => renderProps.destroy()}
                koppelOpties={{
                  selecterenSuggesties: {
                    telefoonnummer: not.Telefoonnummer ?? undefined,
                    voornaam: not.Voornaam ?? undefined,
                    naam: not.Achternaam ?? undefined,
                    straatnaam: not.Straatnaam ?? undefined,
                    woonplaats: not.Plaatsnaam ?? undefined,
                    huisnummer: not.Huisnummer ?? undefined,
                    postcode: not.Postcode ?? undefined,
                    email: not.Email ?? undefined,
                  },
                }}
              />
            ),
          );

          if (result === undefined) {
            return EVerwerkRegelResult.Afgebroken;
          }

          await api.v2.telefoonnotitie.verwerkenPotentieelOntvangenItemTelefoonnotitie({
            potentieelOntvItemTelefoonnotitieID: regel.telefoonnotitieItem.ID,
            relID: result.relID,
          });

          break;
        }
        case ERegelType.Inkoopfactuur: {
          const inkoopfactuur = regel.inkoopfactuurItem;

          const credit = inkoopfactuur.Factuurbedrag != null && inkoopfactuur.Factuurbedrag < 0;
          const absoluutBedrag =
            inkoopfactuur.Factuurbedrag == null ? undefined : Math.abs(inkoopfactuur.Factuurbedrag);
          const absoluteBTW =
            inkoopfactuur.BtwBedrag == null ? undefined : Math.abs(inkoopfactuur.BtwBedrag);

          const result = await globaleRenderer.render<IDialoogResult>((renderProps) => (
            <NieuweFactuurDialoogV2
              open
              onSuccess={(data) => renderProps.destroy(data)}
              onAnnuleren={() => renderProps.destroy()}
              bedragInclusiefBtw={absoluutBedrag}
              inkoopfactuurBestandID={inkoopfactuur.BestandID}
              btwBedrag={absoluteBTW}
              factuurdatum={
                inkoopfactuur.Factuurdatum === null
                  ? undefined
                  : new Date(inkoopfactuur.Factuurdatum)
              }
              factuurnummer={inkoopfactuur.Factuurnummer ?? undefined}
              vervaldatum={
                inkoopfactuur.Vervaldatum === null ? undefined : new Date(inkoopfactuur.Vervaldatum)
              }
              relID={inkoopfactuur.RelID ?? undefined}
              credit={credit}
              btwNietAutomatischHerberekenen={true}
            />
          ));
          if (result === undefined) {
            return EVerwerkRegelResult.Afgebroken;
          }
          break;
        }
        case ERegelType.TransportopdrachtAfleverbewijs: {
          const item = regel.transportopdrachtAfleverbewijsItem;
          const result = await globaleRenderer.render<ITransportopdrachtSelectieDialoogResult>(
            (renderProps) => (
              <TransportopdrachtSelectieDialoog
                open
                onSuccess={async (result) => {
                  renderProps.destroy(result);
                }}
                onAnnuleren={() => renderProps.destroy()}
              />
            ),
          );

          if (result === undefined) {
            return EVerwerkRegelResult.Afgebroken;
          }

          await api.v2.transport.opdracht.koppelAfleverbewijs({
            trsOpdID: result.trsOpdID,
            bestandID: item.Bron_BestandID!,
          });

          break;
        }
        case ERegelType.InslagReferentie: {
          const aantal = regel.inslagReferentieItem.Regels.filter((r) => r.GekoppeldAan_ProdID)
            .length;
          const totaal = regel.inslagReferentieItem.Regels.length;
          if (
            (
              await checkStore.bevestigen({
                inhoud: `Dit zal ${aantal} van de ${totaal} referentiecodes aan producten koppelen. 
                Weet je zeker dat je deze inslagreferentie wil verwerken?`,
              })
            ).type === EResultType.Annuleren
          ) {
            return EVerwerkRegelResult.Afgebroken;
          }
          await api.v2.product.koppelenInslagReferentie(regel.inslagReferentieItem);
          break;
        }
        case ERegelType.Werkbon: {
          const item = regel.werkbonItem;

          let serviceopdracht: IOphalenOpdrachtenResultElement | null = null;
          if (item.ServOpdID !== null) {
            const serviceopdrachtenResult = await api.v2.service.ophalenOpdrachten({
              filterSchema: {
                filters: [
                  {
                    naam: 'IDS',
                    data: [item.ServOpdID],
                  },
                ],
              },
            });
            serviceopdracht = serviceopdrachtenResult.opdrachten[0];
          }

          const result = await globaleRenderer.render<boolean>((renderProps) => (
            <WerkbonMutatieDialoog
              open
              onSuccess={(data) => renderProps.destroy(true)}
              onAnnuleren={() => renderProps.destroy(false)}
              serviceopdracht={serviceopdracht}
              werkbonID={null}
              bezoekdatum={item.Bezoekdatum === null ? undefined : new Date(item.Bezoekdatum)}
              kostenTotaal={item.KostenTotaal ?? undefined}
              werkzaamheden={item.Werkzaamheden ?? undefined}
              servDienstID={item.ServDienstID ?? undefined}
              opdrachtnummer={item.Opdrachtnummer ?? undefined}
              referentiecode={item.Referentiecode ?? undefined}
              postcode={item.Postcode ?? undefined}
              merknaam={item.Merknaam ?? undefined}
              typenaam={item.Typenaam ?? undefined}
              bronBestandID={item.Bron_BestandID}
            />
          ));

          if (result === undefined || !result) {
            return EVerwerkRegelResult.Afgebroken;
          }

          break;
        }
      }

      return EVerwerkRegelResult.Verwerkt;
    },
    [globaleRenderer],
  );

  const kolommen = useMemo<ASPKolom<EKolom, Regel>[]>(
    () => [
      {
        key: EKolom.Datum,
        label: 'Datum',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 150,
        renderer: (regel) => format(regel.datum, 'dd-MM-yyyy HH:mm'),
      },
      {
        key: EKolom.Type,
        label: 'Type',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 250,
        renderer: (regel) => {
          switch (regel.type) {
            case ERegelType.Inkoopfactuur: {
              return 'Inkoopfactuur';
            }
            case ERegelType.Telefoonnotitie: {
              return 'Telefoonnotitie';
            }
            case ERegelType.Werkbon: {
              return 'Werkbon';
            }
            case ERegelType.InslagReferentie: {
              return 'Inslag referentie';
            }
            case ERegelType.TransportopdrachtAfleverbewijs: {
              return 'Transportopdracht afleverbewijs';
            }
          }
        },
      },
      {
        key: EKolom.Communicatieitem,
        label: 'Communicatie item',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 200,
        renderer: (regel) => {
          switch (regel.communicatiekanaal) {
            case ECommunicatiekanaal.Email: {
              return (
                <div className="d-flex align-items-center">
                  <IconEmail
                    style={{
                      width: 17,
                      height: 17,
                      fill: Kleur.Grijs,
                    }}
                  />
                  <span className="ml-2">Email</span>
                </div>
              );
            }
            case ECommunicatiekanaal.Whatsapp: {
              return (
                <div className="d-flex align-items-center">
                  <IconWhatsapp
                    style={{
                      width: 17,
                      height: 17,
                      fill: Kleur.Grijs,
                    }}
                  />
                  <span className="ml-2">WhatsApp</span>
                </div>
              );
            }
          }
        },
      },
      {
        key: EKolom.Status,
        label: 'Status',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 200,
        renderer: (regel) => {
          switch (regel.status) {
            case EPotentieelOntvangenItemStatus.Beoordelen:
              return 'Beoordelen';
            case EPotentieelOntvangenItemStatus.AutomatischVerwerkt:
              return 'Automatisch verwerkt';
            case EPotentieelOntvangenItemStatus.HandmatigVerwerkt:
              return 'Handmatig verwerkt';
            case EPotentieelOntvangenItemStatus.Afgewezen:
              return 'Afgewezen';
          }
        },
      },
      {
        key: EKolom.Weergave,
        label: 'Weergave',
        breedteType: EAspKolomBreedteType.Flex,
        flex: 1,
        renderer: (regel) => {
          switch (regel.type) {
            case ERegelType.Inkoopfactuur: {
              return <InkoopfactuurWeergave regel={regel} item={regel.inkoopfactuurItem} />;
            }
            case ERegelType.Telefoonnotitie: {
              return <TelefoonnotitieWeergave regel={regel} item={regel.telefoonnotitieItem} />;
            }
            case ERegelType.Werkbon: {
              return <WerkbonWeergave regel={regel} item={regel.werkbonItem} />;
            }
            case ERegelType.InslagReferentie: {
              const inkOpdRefs = _.uniq(
                regel.inslagReferentieItem.Regels.map((x) => x.InkOpdRef),
              ).join(', ');

              const aantalRegels = regel.inslagReferentieItem.Regels?.length;
              const aantalKoppelbaar = regel.inslagReferentieItem.Regels.filter(
                (r) => r.GekoppeldAan_ProdID !== null,
              ).length;
              const allesKoppelbaar = aantalRegels === aantalKoppelbaar;

              return regel.inslagReferentieItem.Regels == null ? (
                'Geen productregels gevonden'
              ) : (
                <span>
                  {inkOpdRefs} - {regel.inslagReferentieItem.Regels[0].Typenaam} - {aantalRegels}{' '}
                  regels -{' '}
                  {allesKoppelbaar ? (
                    <span style={{ color: Kleur.Blauw }}>Alles koppelbaar</span>
                  ) : (
                    `${aantalKoppelbaar} koppelbare producten`
                  )}
                </span>
              );
            }
            case ERegelType.TransportopdrachtAfleverbewijs: {
              return (
                <TransportopdrachtAfleverbewijsWeergave
                  regel={regel}
                  item={regel.transportopdrachtAfleverbewijsItem}
                />
              );
            }
          }
        },
      },
      {
        key: EKolom.Acties,
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 300,
        renderer: (regel) => {
          return (
            <div className="d-flex align-items-center justify-content-end">
              {regel.status === EPotentieelOntvangenItemStatus.Beoordelen ? (
                <div className="d-flex align-items-center">
                  <button
                    className="btn btn-sm btn-light"
                    style={{
                      border: `1px solid ${Kleur.LichtGrijs}`,
                      width: 100,
                    }}
                    onClick={async (ev) => {
                      ev.preventDefault();

                      setAanHetVerwerken(true);
                      const result = await verwerkRegel(regel);
                      if (result === EVerwerkRegelResult.Afgebroken) {
                        setAanHetVerwerken(false);
                        return;
                      }

                      const ids = [regel.item.ID];
                      await api.v2.externeData.verwerkenPotentieelOntvangenItems({
                        ids,
                      });
                      await bepalenRegelsData(
                        {
                          index: 0,
                          aantal: 50,
                        },
                        false,
                      );

                      setAanHetVerwerken(false);
                    }}
                    disabled={aanHetVerwerken}
                  >
                    Verwerken
                  </button>

                  <ActieMenuKnop
                    className="ml-2"
                    acties={[
                      {
                        icon: (
                          <IconKruis
                            style={{
                              fill: Kleur.Grijs,
                              width: 16,
                              height: 16,
                            }}
                          />
                        ),
                        text: 'Afwijzen',
                        onClick: async () => {
                          const ids = [regel.item.ID];
                          await api.v2.externeData.afwijzenPotentieelOntvangenItems({
                            ids,
                          });
                          await bepalenRegelsData(
                            {
                              index: 0,
                              aantal: 50,
                            },
                            false,
                          );
                        },
                        disabled: aanHetVerwerken,
                      },
                    ]}
                  />
                </div>
              ) : regel.status === EPotentieelOntvangenItemStatus.Afgewezen ? (
                <ActieMenuKnop
                  acties={[
                    {
                      icon: (
                        <IconTerugdraaien
                          style={{
                            fill: Kleur.Grijs,
                            width: 16,
                            height: 16,
                          }}
                        />
                      ),
                      text: 'Terug naar beoordelen',
                      onClick: async () => {
                        const ids = [regel.item.ID];
                        await api.v2.externeData.herstellenPotentieelOntvangenItems({
                          ids,
                        });
                        await bepalenRegelsData(
                          {
                            index: 0,
                            aantal: 50,
                          },
                          false,
                        );
                      },
                      disabled: aanHetVerwerken,
                    },
                  ]}
                />
              ) : null}
            </div>
          );
        },
      },
    ],
    [bepalenRegelsData, aanHetVerwerken],
  );

  const bepaalUitgeklapteRijHoogte = useCallback((regel: Regel) => {
    switch (regel.type) {
      case ERegelType.Telefoonnotitie:
        return 200;
      case ERegelType.Inkoopfactuur:
        return 800;
      case ERegelType.Werkbon:
        return 425;
      case ERegelType.InslagReferentie:
        return 400;
      case ERegelType.TransportopdrachtAfleverbewijs:
        return 350;
    }

    throw new Error('Niet geimplementeerd voor regel type (rijhoogte niet bepaald)');
  }, []);

  const handleExtraRijenAangevraagd = useCallback(
    async (positie: IPaginatiePositie) => {
      await bepalenRegelsData(positie, true);
    },
    [bepalenRegelsData],
  );

  return (
    <div className="d-flex flex-fill flex-column" style={{ backgroundColor: Kleur.Wit }}>
      <MenuLayout
        menu={
          <div className="flex-fill d-flex align-items-center">
            <FilterBalkV2
              filters={filters}
              filterData={urlState.filterData}
              onFilterDataChange={(x) => setUrlStateSync('filterData', x)}
            />
          </div>
        }
        body={
          regelsData.state === ERemoteDataState.Pending ? (
            <div className="flex-fill d-flex align-items-center justify-content-center">
              <LoadingSpinner />
            </div>
          ) : (
            <ASPTabel
              keyExtractor={(regel) => regel.item.ID}
              rijen={regelsData.data!.regels}
              kolommen={kolommen}
              totaalAantalRijen={regelsData.data!.totaalAantal}
              leegComponent={LeegComponent}
              uitgeklapt={urlState.uitgeklapt}
              onUitgeklaptChange={(x) => setUrlStateSync('uitgeklapt', x)}
              uitgeklapteRijComponent={UitgeklapteRij}
              uitgeklapteRijHoogte={bepaalUitgeklapteRijHoogte}
              sortering={urlState.sortering}
              onSorteringChange={(x) => setUrlStateSync('sortering', x)}
              onExtraRijenAangevraagd={handleExtraRijenAangevraagd}
              sorteringOpties={{
                modus: ESorteringModus.Blacklist,
                kolomOpties: {
                  [EKolom.Type]: {
                    magSorteren: false,
                  },
                },
              }}
            />
          )
        }
      />
    </div>
  );
});

export default ExterneData;
