import {
  IToevoegenContracttariefParams,
  IToevoegenContracttariefResult,
  IVerwijderenContracttarievenParams,
  IVerwijderenContracttarievenResult,
} from '../../../../../shared/src/api/v2/contract/tarief';
import ICheckData from '../../../../../shared/src/models/ICheckData';
import api from '../../index';

const tarief = {
  checkToevoegenContracttarief: async (
    params: IToevoegenContracttariefParams,
  ): Promise<ICheckData> => {
    return await api.post('/v2/contract/tarief/check-toevoegen-contracttarief', params);
  },
  toevoegenContracttarief: async (
    params: IToevoegenContracttariefParams,
  ): Promise<IToevoegenContracttariefResult> => {
    return await api.post('/v2/contract/tarief/toevoegen-contracttarief', params);
  },
  verwijderenContracttarieven: async (
    params: IVerwijderenContracttarievenParams,
  ): Promise<IVerwijderenContracttarievenResult> => {
    return await api.post('/v2/contract/tarief/verwijderen-contracttarieven', params);
  },
};

export default tarief;
