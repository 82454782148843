import { Formik, FormikActions, FormikProps } from 'formik';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import ModalFooter from 'react-bootstrap/ModalFooter';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import { IOphalenDienstenResultElement } from '../../../../../../../shared/src/api/v2/dienst/inkoop';
import api from '../../../../../api';
import LoadingSpinner from '../../../../../components/Gedeeld/LoadingSpinner';
import Tabblad, { ITabblad } from '../../../../../components/layout/Tabblad';
import IDialoogProps from '../../../../../core/IDialoogProps';
import { EResultType } from '../../../../../stores/CheckStore';
import { RootStoreContext } from '../../../../../stores/RootStore';
import GedeeldFormulier from '../../Formulieren/GedeeldFormulier';
import InkoopFormulier from '../../Formulieren/InkoopFormulier';
import { ITijdVeldWaarde } from '../../../../../components/formulier/TijdVeld';
import * as Yup from 'yup';
import teksten from '../../../../../bedrijfslogica/teksten';
import Dialoog from '../../../../../components/dialogen/Dialoog';
import { Helmet } from 'react-helmet';

interface IProps extends IDialoogProps<null> {
  id: number | null;
}

export interface IFormikValues {
  actief: boolean;
  naam: string | null;
  naamEnum: string;
  opdWijzeID: number | null;
  parameters: string | null;
  relID: number | null;
  tijdUiterlijkOpdracht: ITijdVeldWaarde;
  emailOpdracht: string | null;
  emailOpdrachtCC: string | null;
  levertermijn: number | null;
  koppelenProductenAanFactuur: boolean;
  emailRetouraanmelding: string | null;
}

enum ETablad {
  Algemeen,
  Inkoop,
}

export const veldnamen: Record<keyof IFormikValues, string> = {
  actief: 'Actief',
  naam: 'Naam',
  naamEnum: 'Codereferentie',
  opdWijzeID: 'Standaard opdrachtwijze',
  parameters: 'Parameters',
  relID: 'Relatie',
  tijdUiterlijkOpdracht: 'Uiterlijke tijd opdracht',
  emailOpdracht: 'Email tbv opdrachten',
  emailOpdrachtCC: 'Email CC tbv opdrachten',
  levertermijn: 'Levertermijn',
  koppelenProductenAanFactuur: 'Producten koppelen aan factuur',
  emailRetouraanmelding: 'Email tbv retouraanmeldingen',
};

const MutatieDialoog: React.FC<IProps> = (props) => {
  const { onAnnuleren, onSuccess, open, id, dialoogIndex } = props;

  const { checkStore } = useContext(RootStoreContext);

  const modalTitel = id !== null ? `Wijzigen` : `Toevoegen`;
  const [tabblad, setTabblad] = useState<ETablad>(ETablad.Algemeen);
  const [inkoopDienst, setInkoopDienst] = useState<IOphalenDienstenResultElement | null>(null);

  const tabbladen = useMemo<ITabblad<ETablad>[]>(
    () => [
      {
        id: ETablad.Algemeen,
        label: 'Algemeen',
        content: GedeeldFormulier,
      },
      {
        id: ETablad.Inkoop,
        label: 'Inkoop',
        content: InkoopFormulier,
      },
    ],
    [onSuccess],
  );

  const ophalenDienst = async () => {
    const resultaat = await api.v2.dienst.inkoop.ophalenDiensten({
      filterSchema: { filters: [{ naam: 'IDS', data: [id] }] },
    });
    setInkoopDienst(resultaat.diensten[0]);
  };

  useEffect(() => {
    if (id !== null) {
      console.log(`haal dienst op...`);
      ophalenDienst();
    } else {
      console.log(`haal GEEN dienst op...`);
    }
  }, [id]);

  const initialValues = useMemo<IFormikValues>(() => {
    if (inkoopDienst === null) {
      return {
        actief: true,
        naam: '',
        naamEnum: '',
        opdWijzeID: null,
        parameters: '',
        relID: null,
        tijdUiterlijkOpdracht: {
          tekst: '',
          resultaat: null,
        },
        emailOpdracht: '',
        emailOpdrachtCC: '',
        levertermijn: 0,
        koppelenProductenAanFactuur: true,
        emailRetouraanmelding: '',
      };
    }

    return {
      actief: inkoopDienst.Actief,
      naam: inkoopDienst.Naam,
      naamEnum: inkoopDienst.NaamEnum ?? '',
      opdWijzeID: inkoopDienst.OpdWijzeID ?? null,
      parameters: inkoopDienst.Parameters,
      relID: inkoopDienst.RelID,
      tijdUiterlijkOpdracht:
        inkoopDienst.TijdUiterlijkOpdracht === null
          ? {
              tekst: '',
              resultaat: null,
            }
          : {
              tekst: inkoopDienst.TijdUiterlijkOpdracht,
              resultaat: inkoopDienst.TijdUiterlijkOpdracht,
            },
      emailOpdracht: inkoopDienst.EmailOpdracht,
      emailOpdrachtCC: inkoopDienst.EmailOpdrachtCC,
      levertermijn: inkoopDienst.Levertermijn,
      koppelenProductenAanFactuur: inkoopDienst.KoppelenProductenAanFactuur,
      emailRetouraanmelding:
        inkoopDienst.EmailRetouraanmelding === null ? '' : inkoopDienst.EmailRetouraanmelding,
    };
  }, [inkoopDienst]);

  const validatieschema = Yup.object().shape({
    relID: Yup.number()
      .required(teksten.formulier.E_VERPLICHT_VELD({ veldnaam: 'Relatie' }))
      .nullable(),
    // naam: Yup.string().required(teksten.formulier.E_VERPLICHT_VELD({ veldnaam: 'Naam' })),
  });

  const handleSubmit = useCallback(
    async (values, actions: FormikActions<IFormikValues>) => {
      const params = {
        relID: values.relID,
        parameters: values.parameters === '' ? null : values.parameters,
        opdWijzeID: values.opdWijzeID,
        naam: values.naam,
        naamEnum: values.naamEnum,
        actief: values.actief,

        levertermijn: values.levertermijn,
        emailOpdracht: values.emailOpdracht === '' ? null : values.emailOpdracht,
        emailOpdrachtCC: values.emailOpdrachtCC === '' ? null : values.emailOpdrachtCC,
        tijdUiterlijkOpdracht:
          values.tijdUiterlijkOpdracht.tekst === '' ? '23:59' : values.tijdUiterlijkOpdracht.tekst,
      };

      if (props.id === null) {
        // Nieuwe dienst
        const checkData = await api.v2.dienst.inkoop.checkToevoegenDienst(params);
        if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
          return;
        }
        const resultaat = await api.v2.dienst.inkoop.toevoegenDienst(params);
      } else {
        // Wijzigen dienst
        const params = {
          inkDienstID: props.id,
          relID: values.relID,
          parameters: values.parameters === '' ? null : values.parameters,
          opdWijzeID: values.opdWijzeID,
          naam: values.naam,
          naamEnum: values.naamEnum,
          actief: values.actief,
          levertermijn: values.levertermijn,
          emailOpdracht: values.emailOpdracht === '' ? null : values.emailOpdracht,
          emailOpdrachtCC: values.emailOpdrachtCC === '' ? null : values.emailOpdrachtCC,
          tijdUiterlijkOpdracht: values.tijdUiterlijkOpdracht.tekst,
          koppelenProductenAanFactuur: values.koppelenProductenAanFactuur,
          emailRetouraanmelding:
            values.emailRetouraanmelding === '' ? null : values.emailRetouraanmelding,
        };

        const checkData = await api.v2.dienst.inkoop.checkWijzigenDienst(params);
        if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
          return;
        }

        const resultaat = await api.v2.dienst.inkoop.wijzigenDienst(params);
      }

      props.onSuccess(null);
    },
    [props.onSuccess],
  );

  return (
    <>
      <Dialoog index={dialoogIndex || 0} modalProps={{ size: 'lg' }}>
        <ModalHeader>
          <ModalTitle>{modalTitel} inkoopdienst</ModalTitle>
        </ModalHeader>
        <div>
          {/* <code>{JSON.stringify(inkoopDienst)}</code> */}
          {/* <code>{JSON.stringify(initialValues)}</code> */}
          {initialValues === null ? (
            <LoadingSpinner />
          ) : (
            <Formik
              enableReinitialize
              onSubmit={handleSubmit}
              initialValues={initialValues}
              validationSchema={validatieschema}
              render={(formikProps: FormikProps<IFormikValues>) => {
                const { submitForm, isSubmitting, isValid, values } = formikProps;
                return (
                  <>
                    <Tabblad
                      tabbladen={tabbladen}
                      geselecteerd={tabblad}
                      onSelectieChange={(id) => {
                        setTabblad(id);
                      }}
                    />
                    <ModalFooter className="d-flex justify-content-start mt-2">
                      <button
                        className="btn btn-primary"
                        onClick={submitForm}
                        style={{ width: 100 }}
                        disabled={false}
                      >
                        Ok
                      </button>
                      <button
                        className="btn btn-secondary"
                        style={{ width: 100 }}
                        onClick={onAnnuleren}
                      >
                        Annuleren
                      </button>
                    </ModalFooter>
                  </>
                );
              }}
            />
          )}
        </div>
      </Dialoog>
    </>
  );
};

export default MutatieDialoog;
