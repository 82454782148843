import React, { useEffect, useMemo, useState } from 'react';
import { IUitgeklapteInhoudProps } from '../..';
import GegevensLayout, { EUitlijning } from '../../../../components/layout/GegevensLayout';
import { IPotentieelOntvangenInkoopfactuurItem } from '../../../../../../shared/src/api/v2/externeData';
import { format } from 'date-fns';
import FormatteerBedrag from '../../../../components/MutatieBedrag';
import RelatieVisualisatie from '../../../../components/personalia/RelatieVisualisatie';
import PDFWeergever from '../../../../components/PDFWeergever';
import { IOphalenBestandenResultElement } from '../../../../../../shared/src/api/v2/bestand/bestand';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../models/IRemoteData';
import api from '../../../../api';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import { useHeeftAutorisatie } from '../../../../helpers/heeftAutorisatie';

const InkoopfactuurUitgeklapteInhoud = <TRow extends any>(
  props: IUitgeklapteInhoudProps<TRow, IPotentieelOntvangenInkoopfactuurItem>,
) => {
  const heeftAutorisatie = useHeeftAutorisatie(useMemo(() => ['EXTERNE_DATA_INKOOPFACTUREN'], []));

  const [bestand, setBestand] = useState<IRemoteData<IOphalenBestandenResultElement>>(
    createPendingRemoteData(),
  );

  useEffect(() => {
    api.v2.bestand
      .ophalenBestanden({
        filterSchema: {
          filters: [
            {
              naam: 'IDS',
              data: [props.item.BestandID],
            },
          ],
        },
      })
      .then((result) => setBestand(createReadyRemoteData(result.bestanden[0])));
  }, [props.item.BestandID]);

  if (!heeftAutorisatie) {
    return null;
  }

  return (
    <div className="d-flex flex-column flex-fill">
      <div className="d-flex flex-fill">
        <div style={{ width: 350 }}>
          <GegevensLayout
            gegevens={[
              {
                label: 'Relatie',
                waarde:
                  props.item.RelID === null ? null : (
                    <RelatieVisualisatie relID={props.item.RelID} />
                  ),
              },
              {
                label: 'Factuurnummer',
                waarde: props.item.Factuurnummer,
              },
              {
                label: 'Factuurdatum',
                waarde:
                  props.item.Factuurdatum === null
                    ? null
                    : format(new Date(props.item.Factuurdatum), 'dd-MM-yyyy'),
              },
              {
                label: 'Vervaldatum',
                waarde:
                  props.item.Vervaldatum === null
                    ? null
                    : format(new Date(props.item.Vervaldatum), 'dd-MM-yyyy'),
              },
              {
                label: 'Factuurbedrag',
                waarde:
                  props.item.Factuurbedrag === null ? null : (
                    <FormatteerBedrag bedrag={props.item.Factuurbedrag} />
                  ),
                uitlijning: EUitlijning.Rechts,
              },
              {
                label: 'BTW-bedrag',
                waarde:
                  props.item.BtwBedrag === null ? null : (
                    <FormatteerBedrag bedrag={props.item.BtwBedrag} />
                  ),
                uitlijning: EUitlijning.Rechts,
              },
            ]}
          />
        </div>

        <div className="flex-fill" />

        <div
          className="d-flex justify-content-center"
          style={{ backgroundColor: Kleur.Grijs, width: 850 }}
        >
          {bestand.state === ERemoteDataState.Pending ? (
            <div className="d-flex flex-fill align-items-center justify-content-center">
              <LoadingSpinner />
            </div>
          ) : (
            <PDFWeergever url={bestand.data!.url} />
          )}
        </div>
      </div>
    </div>
  );
};

export default InkoopfactuurUitgeklapteInhoud;
