import React, {
  CSSProperties,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Grid, Index, IndexRange, InfiniteLoader } from 'react-virtualized';
import { GridCellProps, GridCellRangeRenderer, ScrollParams } from 'react-virtualized/dist/es/Grid';
import { IPaginatiePositie } from '../../../../api';
import { ASPKolom, IAspKolomSorteringItem } from '../types';
import TableData, { ITableDataProps } from './TableData';
import Skeleton from 'react-loading-skeleton';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import StandaardUitgeklapteRij, { IUitgeklapteRijProps } from './UitgeklapteRij';
import { KolomRenderMap } from '../Header';
import StandaardUitklapTableData, { IUitklapTableDataProps } from './UitklapTableData';
import StandaardSelectieTableData, { ISelectieTableDataProps } from './SelectieTableData';
import useBijGewijzigdEffect from '../../../../core/useBijGewijzigdEffect';
import StandaardVerwijderenTableData, { IVerwijderenTableDataProps } from './VerwijderenTableData';
import StandaardWijzigenTableData, { IWijzigenTableDataProps } from './WijzigenTableData';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../../stores/RootStore';
import { EResultType } from '../../../../stores/CheckStore';
import StandaardVigerendTableData, { IVigerendTableDataProps } from './VigerendTableData';
import height from 'dom-helpers/query/height';
import width from 'dom-helpers/query/width';
import { SortableContainer, SortableContainerProps, SortableElement } from 'react-sortable-hoc';
import StandaardDragHandleTableData, { IDragHandleTableDataProps } from './DragHandleTableData';

export type IBodyProps = React.PropsWithChildren<{
  width: number;
  height: number;
}>;

const defaultBodyComponent = (bodyProps: IBodyProps) => (
  <div style={{ width: bodyProps.width, height: bodyProps.height }}>{bodyProps.children}</div>
);

export const STANDAARD_ASP_RIJ_HOOGTE = 40;

interface IRenderGridParams {
  ref?: (node: Grid) => void;
  onRowsRendered?: (index: { startIndex: number; stopIndex: number }) => void;
}

export interface ILeegComponentProps {
  width: number;
  height: number;
}

export const StandaardLeegComponent = (props: ILeegComponentProps) => {
  return (
    <div
      style={{ width: props.width, height: props.height }}
      className="d-flex align-items-center justify-content-center"
    >
      <span>Er zijn geen resultaten.</span>
    </div>
  );
};

interface IRenderMapItem<TRow> {
  index: number;
  row: TRow;
}

export interface IRijenGerenderedIndex {
  startIndex: number;
  stopIndex: number;
}

export interface IOnRijenHerordenenParams<TRow> {
  oud: {
    index: number;
    rij: TRow;
  };
  nieuw: {
    index: number;
    rij: TRow;
  };
}

interface IProps<TId extends keyof any, TKey extends keyof any, TRow> {
  width: number;
  height: number;
  scrollLeft: number;
  totaalAantalRijen: number;
  meerRijenBatchGrootte?: number;
  kolommen: ASPKolom<TKey, TRow>[];
  kolomRenderMap: KolomRenderMap<TKey, TRow>;
  rijen: Record<number, TRow>;
  rijHoogte?: ((rij: TRow) => number) | number;
  onScroll: (params: ScrollParams) => any;
  onExtraRijenAangevraagd?: (positie: IPaginatiePositie) => Promise<void>;
  onRijenGerendered?: (index: IRijenGerenderedIndex) => void;
  tdComponent?: React.ComponentType<ITableDataProps<TKey, TRow>>;
  selectieTdComponent?: React.ComponentType<ISelectieTableDataProps<TRow>>;
  vigerendTdComponent?: React.ComponentType<IVigerendTableDataProps<TRow>>;
  uitklapTdComponent?: React.ComponentType<IUitklapTableDataProps<TRow>>;
  dragHandleTdComponent?: React.ComponentType<IDragHandleTableDataProps<TRow>>;
  verwijderenTdComponent?: React.ComponentType<IVerwijderenTableDataProps<TRow>>;
  wijzigenTdComponent?: React.ComponentType<IWijzigenTableDataProps<TRow>>;
  leegComponent?: React.ComponentType<ILeegComponentProps>;
  getColumnWidth: (index: Index) => number;
  uitgeklapt?: TId[];
  onUitgeklaptChange?: (uitgeklapt: TId[]) => void;
  keyExtractor: (rij: TRow) => TId;
  uitgeklapteRijComponent?: React.ComponentType<IUitgeklapteRijProps<TKey, TRow>>;
  uitgeklapteRijHoogte?: ((rij: TRow) => number) | number;
  selectie?: TId[];
  onSelectieChange?: (uitgeklapt: TId[]) => void;
  magRijSelecteren?: (rij: TRow) => boolean;
  vigerend?: TId[];
  gefocustRij?: TId;
  ongefocustRijChange?: (focusRij: TId) => void;
  onWijzigenRij?: (rij: TRow, index: number) => Promise<void>;
  onVerwijderenRij?: (rij: TRow) => Promise<void>;
  sortering?: IAspKolomSorteringItem<TKey>[];
  bodyComponent?: React.ComponentType<IBodyProps>;
  magRijVerwijderen?: (rij: TRow) => boolean;
  magRijWijzigen?: (rij: TRow) => boolean;
  verwijderenRijConfirmatie?:
    | ((rij: TRow, verwijderen: () => Promise<void>) => Promise<void>)
    | null;
  magRijUitklappen?: (rij: TRow) => boolean;
  scrollNaarIndex?: number;
  onRijenHerordenen?: (params: IOnRijenHerordenenParams<TRow>) => void;
}

const Row = ({ children, style }: any) => <div style={style}>{children}</div>;
const SortableRow = SortableElement(Row);
const SortableGrid = SortableContainer(Grid, { withRef: true });

const Body = observer(
  <TId extends keyof any, TKey extends keyof any, TRow>(props: IProps<TId, TKey, TRow>) => {
    const { checkStore, toetsenbordStore } = useContext(RootStoreContext);
    const gridRef = useRef<Grid>();

    const [laatstGeselecteerdeRijRowIndex, setLaatstGeselecteerdeRijRowIndex] = useState<
      number | null
    >(null);
    useEffect(() => {
      if (!toetsenbordStore.shift) {
        setLaatstGeselecteerdeRijRowIndex(null);
      }
    }, [toetsenbordStore.shift]);

    const [hoveredRijIndex, setHoveredRijIndex] = useState<number | null>(null);

    const initialRowLoadedCache = useMemo<Record<number, boolean>>(() => {
      return Object.keys(props.rijen).reduce<Record<number, boolean>>((acc, key) => {
        const k = Number(key);
        return {
          ...acc,
          [k]: true,
        };
      }, {});
    }, []);
    const rowLoadedCache = useRef<Record<number, boolean>>(initialRowLoadedCache);
    const isRowLoaded = useCallback(
      (index: Index) => {
        return (
          rowLoadedCache.current[index.index] ?? props.rijen[index.index] !== undefined ?? false
        );
        // return props.rijen[index.index] !== undefined;
      },
      [props.rijen],
    );
    const loadMoreRows = useCallback(
      async (range: IndexRange) => {
        const index = range.startIndex;
        const aantal = range.stopIndex - range.startIndex + 1;

        for (let i = index; i < index + aantal; i++) {
          if (rowLoadedCache.current[i]) {
            continue;
          }
          rowLoadedCache.current[i] = true;
        }

        await props.onExtraRijenAangevraagd?.({
          index,
          aantal,
        });
      },
      [props.onExtraRijenAangevraagd],
    );

    // Nodig voor uitgeklapte rijen
    const renderMap = useMemo<Record<number, IRenderMapItem<TRow> | 'uitgeklapt'>>(() => {
      if (props.uitgeklapt === undefined) {
        return Object.keys(props.rijen).reduce((acc, key) => {
          const k = Number(key);
          return {
            ...acc,
            [k]: {
              row: props.rijen[k],
              index: k,
            },
          };
        }, {});
      }

      let aantalUitgeklaptGehad = 0;
      const map: Record<number, IRenderMapItem<TRow> | 'uitgeklapt'> = {};
      for (let i = 0; i < props.totaalAantalRijen + props.uitgeklapt.length; i++) {
        const effectieveIdx = i - aantalUitgeklaptGehad;
        if (map[i - 1] === 'uitgeklapt') {
          map[i] = {
            row: props.rijen[effectieveIdx],
            index: effectieveIdx,
          };
          continue;
        }

        const rijErboven = props.rijen[effectieveIdx - 1];

        if (rijErboven !== undefined) {
          const rijErbovenId = props.keyExtractor(rijErboven);
          if (props.uitgeklapt.includes(rijErbovenId)) {
            map[i] = 'uitgeklapt';
            aantalUitgeklaptGehad++;
            continue;
          }
        }

        map[i] = {
          row: props.rijen[effectieveIdx],
          index: effectieveIdx,
        };
      }
      return map;
    }, [props.rijen, props.totaalAantalRijen, props.uitgeklapt]);

    const totaleBreedteMetAlleKolommen = useMemo(
      () =>
        Object.keys(props.kolomRenderMap).reduce((acc, curr) => {
          const key = Number(curr);
          const kolom = props.kolomRenderMap[key];
          return acc + kolom.breedte;
        }, 0),
      [props.kolomRenderMap],
    );

    const cellRenderer = useCallback(
      (gridCellProps: GridCellProps) => {
        const kolomRenderItem = props.kolomRenderMap[gridCellProps.columnIndex];
        const renderMapItem = renderMap[gridCellProps.rowIndex];

        const volgendeRij = renderMap[gridCellProps.rowIndex + 1];
        const heeftUitgeklapteRijOnderZich = volgendeRij === 'uitgeklapt';

        if (renderMapItem === 'uitgeklapt') {
          if (gridCellProps.columnIndex === 0) {
            const UitgeklapteRijComp = props.uitgeklapteRijComponent ?? StandaardUitgeklapteRij;
            const vorigRenderItem = renderMap[gridCellProps.rowIndex - 1] as IRenderMapItem<TRow>;
            const vorigeRij = vorigRenderItem.row;

            return (
              <div
                key={gridCellProps.key}
                style={{
                  ...gridCellProps.style,
                  left: 0,
                  width: totaleBreedteMetAlleKolommen,
                  display: 'flex',
                  flexDirection: 'column',
                  overflow: 'auto',
                  wordBreak: 'break-word',
                  borderBottom: `1px solid ${Kleur.LichtGrijs}`,
                }}
              >
                <UitgeklapteRijComp
                  style={{
                    // padding: '0px 15px',
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    height: '100%',
                  }}
                  regel={vorigeRij}
                />
              </div>
            );
          }
          return null;
        }

        const rij = renderMapItem?.row;

        const isGehovered = hoveredRijIndex === gridCellProps.rowIndex;
        const isGefocust =
          rij === undefined ? false : props.gefocustRij === props.keyExtractor(rij);

        const handleMouseEnter = (ev: React.MouseEvent<HTMLDivElement>) => {
          setHoveredRijIndex(gridCellProps.rowIndex);
        };
        const handleMouseLeave = (ev: React.MouseEvent<HTMLDivElement>) => {
          setHoveredRijIndex(null);
        };
        const handleMouseUp = (ev: React.MouseEvent<HTMLDivElement>) => {
          if (rij !== undefined && props.ongefocustRijChange !== undefined) {
            const id = props.keyExtractor(rij);
            props.ongefocustRijChange(id);
          }
        };

        const wrapper = (content: JSX.Element) => {
          return (
            <div
              key={gridCellProps.key}
              style={gridCellProps.style}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onMouseUp={handleMouseUp}
            >
              {content}
            </div>
          );
        };

        if (kolomRenderItem.kolom === 'padding') {
          const TdComponent = props.tdComponent ?? TableData;

          return wrapper(
            <TdComponent
              row={rij}
              tableKey={null}
              isGehovered={isGehovered}
              isGefocust={isGefocust}
              style={{
                padding: 0,
              }}
              heeftUitgeklapteRijOnderZich={heeftUitgeklapteRijOnderZich}
              rowIndex={renderMapItem?.index ?? -1}
            />,
          );
        }

        if (kolomRenderItem.kolom === 'toevoegen') {
          const TdComponent = props.tdComponent ?? TableData;

          return wrapper(
            <TdComponent
              row={rij}
              tableKey={null}
              isGehovered={isGehovered}
              isGefocust={isGefocust}
              style={{
                padding: 0,
              }}
              heeftUitgeklapteRijOnderZich={heeftUitgeklapteRijOnderZich}
              rowIndex={renderMapItem?.index ?? -1}
            />,
          );
        }

        if (rij === undefined) {
          const TdComponent = props.tdComponent ?? TableData;

          return wrapper(
            <TdComponent
              row={rij}
              tableKey={null}
              isGehovered={isGehovered}
              isGefocust={isGefocust}
              heeftUitgeklapteRijOnderZich={heeftUitgeklapteRijOnderZich}
              rowIndex={renderMapItem?.index ?? -1}
            >
              <Skeleton />
            </TdComponent>,
          );
        }

        if (kolomRenderItem.kolom === 'uitklap') {
          const UitklapTdComponent = props.uitklapTdComponent ?? StandaardUitklapTableData;

          const magRijUitklappen =
            props.magRijUitklappen === undefined ? true : props.magRijUitklappen(rij);

          if (!magRijUitklappen) {
            const TdComponent = props.tdComponent ?? TableData;

            return wrapper(
              <TdComponent
                row={rij}
                tableKey={null}
                isGehovered={isGehovered}
                isGefocust={isGefocust}
                heeftUitgeklapteRijOnderZich={heeftUitgeklapteRijOnderZich}
                rowIndex={renderMapItem?.index ?? -1}
              />,
            );
          }

          return wrapper(
            <UitklapTdComponent
              row={rij}
              heeftUitgeklapteRijOnderZich={heeftUitgeklapteRijOnderZich}
              onUitgeklaptChange={(uitgeklapt) => {
                const rijKey = props.keyExtractor(rij);
                if (uitgeklapt) {
                  props.onUitgeklaptChange?.([...(props.uitgeklapt ?? []), rijKey]);
                } else {
                  props.onUitgeklaptChange?.(
                    (props.uitgeklapt ?? []).filter((id) => id !== rijKey),
                  );
                }
              }}
              isGehovered={isGehovered}
              isGefocust={isGefocust}
            />,
          );
        }

        if (kolomRenderItem.kolom === 'drag_handle') {
          const DragHandleTdComponent = props.dragHandleTdComponent ?? StandaardDragHandleTableData;

          return wrapper(
            <DragHandleTdComponent row={rij} isGehovered={isGehovered} isGefocust={isGefocust} />,
          );
        }

        if (kolomRenderItem.kolom === 'vigerend') {
          const isVigerend =
            props.vigerend === undefined ? false : props.vigerend.includes(props.keyExtractor(rij));

          const VigerendTdComponent = props.vigerendTdComponent ?? StandaardVigerendTableData;

          return wrapper(
            <VigerendTdComponent
              row={rij}
              isVigerend={isVigerend}
              heeftUitgeklapteRijOnderZich={heeftUitgeklapteRijOnderZich}
              isGehovered={isGehovered}
              isGefocust={isGefocust}
              rowIndex={renderMapItem?.index ?? -1}
            />,
          );
        }

        if (kolomRenderItem.kolom === 'selectie') {
          const isGeselecteerd =
            props.selectie === undefined ? false : props.selectie.includes(props.keyExtractor(rij));
          const magSelecteren =
            props.magRijSelecteren === undefined ? true : props.magRijSelecteren(rij);

          const SelectieTdComponent = props.selectieTdComponent ?? StandaardSelectieTableData;

          return wrapper(
            <SelectieTdComponent
              row={rij}
              magSelecteren={magSelecteren}
              isGeselecteerd={isGeselecteerd}
              heeftUitgeklapteRijOnderZich={heeftUitgeklapteRijOnderZich}
              onIsGeselecteerdChange={(isGeselecteerd) => {
                const rijKey = props.keyExtractor(rij);

                // Multivinken met shift
                if (toetsenbordStore.shift) {
                  if (laatstGeselecteerdeRijRowIndex !== null) {
                    // Alles tussen laatstGeselecteerdeRij en rij selecteren
                    const [laagsteIndex, hoogsteIndex] = [
                      laatstGeselecteerdeRijRowIndex,
                      gridCellProps.rowIndex,
                    ].sort((a, b) => a - b);
                    const rijKeys = [];
                    for (let i = laagsteIndex; i <= hoogsteIndex; i++) {
                      const rij = props.rijen[i];
                      if (rij === undefined) {
                        continue;
                      }
                      if (props.magRijSelecteren?.(rij) ?? true) {
                        rijKeys.push(props.keyExtractor(rij));
                      }
                    }
                    props.onSelectieChange?.([...(props.selectie ?? []), ...rijKeys]);
                  } else {
                    // Simpel aanvinken degene die je nu selecteert
                    props.onSelectieChange?.([...(props.selectie ?? []), rijKey]);
                  }
                  setLaatstGeselecteerdeRijRowIndex(gridCellProps.rowIndex);
                  return;
                }

                // Simpel uitvinken en aanvinken enkel
                if (isGeselecteerd) {
                  props.onSelectieChange?.([...(props.selectie ?? []), rijKey]);
                } else {
                  props.onSelectieChange?.((props.selectie ?? []).filter((id) => id !== rijKey));
                }
              }}
              isGehovered={isGehovered}
              isGefocust={isGefocust}
              rowIndex={renderMapItem?.index ?? -1}
            />,
          );
        }

        if (kolomRenderItem.kolom === 'verwijderen') {
          const VerwijderenTdComponent =
            props.verwijderenTdComponent ?? StandaardVerwijderenTableData;

          const magRijVerwijderen = props.magRijVerwijderen?.(rij) ?? true;

          if (!magRijVerwijderen) {
            const TdComponent = props.tdComponent ?? TableData;

            return wrapper(
              <TdComponent
                row={rij}
                tableKey={null}
                isGehovered={isGehovered}
                isGefocust={isGefocust}
                style={{
                  padding: 0,
                }}
                heeftUitgeklapteRijOnderZich={heeftUitgeklapteRijOnderZich}
                rowIndex={renderMapItem?.index ?? -1}
              />,
            );
          }

          return wrapper(
            <VerwijderenTdComponent
              row={rij}
              heeftUitgeklapteRijOnderZich={heeftUitgeklapteRijOnderZich}
              onVerwijderenRij={async () => {
                if (props.verwijderenRijConfirmatie !== null) {
                  if (props.verwijderenRijConfirmatie === undefined) {
                    if (
                      (
                        await checkStore.bevestigen({
                          titel: 'Confirmatie verwijderen',
                          inhoud: 'Bevestig het verwijderen van de rij',
                          asynchroneActieNaBevestigingFn: async () => {
                            await props.onVerwijderenRij?.(rij);
                          },
                        })
                      ).type === EResultType.Annuleren
                    ) {
                      return;
                    }
                  } else {
                    await props.verwijderenRijConfirmatie(rij, async () => {
                      await props.onVerwijderenRij?.(rij);
                      // Automatisch selectie bijwerken om de verwijderd rij uit de selectie te halen
                      if (props.selectie !== undefined && props.onSelectieChange !== undefined) {
                        if (props.selectie.indexOf(props.keyExtractor(rij)) !== -1) {
                          props.onSelectieChange(
                            props.selectie.filter((id) => id !== props.keyExtractor(rij)),
                          );
                        }
                      }
                    });
                  }

                  return;
                }

                await props.onVerwijderenRij?.(rij);
              }}
              isGehovered={isGehovered}
              isGefocust={isGefocust}
              rowIndex={renderMapItem?.index ?? -1}
            />,
          );
        }

        if (kolomRenderItem.kolom === 'wijzigen') {
          const WijzigenTdComponent = props.wijzigenTdComponent ?? StandaardWijzigenTableData;

          const magRijWijzigen = props.magRijWijzigen?.(rij) ?? true;

          if (!magRijWijzigen) {
            const TdComponent = props.tdComponent ?? TableData;

            return wrapper(
              <TdComponent
                row={rij}
                tableKey={null}
                isGehovered={isGehovered}
                isGefocust={isGefocust}
                style={{
                  padding: 0,
                }}
                heeftUitgeklapteRijOnderZich={heeftUitgeklapteRijOnderZich}
                rowIndex={renderMapItem?.index ?? -1}
              />,
            );
          }

          return wrapper(
            <WijzigenTdComponent
              row={rij}
              heeftUitgeklapteRijOnderZich={heeftUitgeklapteRijOnderZich}
              onWijzigenRij={() => props.onWijzigenRij?.(rij, renderMapItem?.index ?? -1)}
              isGehovered={isGehovered}
              isGefocust={isGefocust}
              rowIndex={renderMapItem?.index ?? -1}
            />,
          );
        }

        const TdComponent = kolomRenderItem.kolom.tdComponent ?? props.tdComponent ?? TableData;

        const inhoud = kolomRenderItem.kolom.renderer?.(rij) ?? null;

        return wrapper(
          <TdComponent
            row={rij}
            tableKey={kolomRenderItem.kolom.key}
            heeftUitgeklapteRijOnderZich={heeftUitgeklapteRijOnderZich}
            isGehovered={isGehovered}
            isGefocust={isGefocust}
            rowIndex={renderMapItem?.index ?? -1}
          >
            {inhoud}
          </TdComponent>,
        );
      },
      [
        props.rijen,
        props.kolomRenderMap,
        renderMap,
        props.tdComponent,
        props.selectie,
        props.onSelectieChange,
        props.uitgeklapt,
        props.onUitgeklaptChange,
        hoveredRijIndex,
        setHoveredRijIndex,
        props.gefocustRij,
        props.ongefocustRijChange,
        props.magRijSelecteren,
        toetsenbordStore.shift,
        laatstGeselecteerdeRijRowIndex,
        setLaatstGeselecteerdeRijRowIndex,
        totaleBreedteMetAlleKolommen,
      ],
    );

    const rowHeight = useCallback(
      (index) => {
        const renderMapItem = renderMap[index.index];
        if (renderMapItem === 'uitgeklapt') {
          if (props.uitgeklapteRijHoogte === undefined) {
            return STANDAARD_ASP_RIJ_HOOGTE;
          }
          if (typeof props.uitgeklapteRijHoogte === 'number') {
            return props.uitgeklapteRijHoogte;
          }
          const renderItemErboven = renderMap[index.index - 1] as IRenderMapItem<TRow>;
          return props.uitgeklapteRijHoogte(renderItemErboven.row);
        }

        if (props.rijHoogte === undefined) {
          return STANDAARD_ASP_RIJ_HOOGTE;
        }
        if (typeof props.rijHoogte === 'number') {
          return props.rijHoogte;
        }
        return props.rijHoogte(renderMapItem?.row);
      },
      [renderMap, props.rijHoogte, props.uitgeklapteRijHoogte],
    );

    const cellRangeRenderer = useCallback<GridCellRangeRenderer>(
      (cellRangeRendererProps) => {
        const {
          cellCache,
          cellRenderer,
          columnSizeAndPositionManager,
          columnStartIndex,
          columnStopIndex,
          deferredMeasurementCache,
          horizontalOffsetAdjustment,
          isScrolling,
          isScrollingOptOut,
          parent, // Grid (or List or Table)
          rowSizeAndPositionManager,
          rowStartIndex,
          rowStopIndex,
          styleCache,
          verticalOffsetAdjustment,
          visibleColumnIndices,
          visibleRowIndices,
        } = cellRangeRendererProps;
        const renderedRows: React.ReactNode[] = [];

        // Browsers have native size limits for elements (eg Chrome 33M pixels, IE 1.5M pixes).
        // User cannot scroll beyond these size limitations.
        // In order to work around this, ScalingCellSizeAndPositionManager compresses offsets.
        // We should never cache styles for compressed offsets though as this can lead to bugs.
        // See issue #576 for more.
        const areOffsetsAdjusted =
          columnSizeAndPositionManager.areOffsetsAdjusted() ||
          rowSizeAndPositionManager.areOffsetsAdjusted();

        const canCacheStyle = !isScrolling && !areOffsetsAdjusted;
        const shouldUseCache =
          (isScrollingOptOut || isScrolling) &&
          !horizontalOffsetAdjustment &&
          !verticalOffsetAdjustment;

        let renderedCells: React.ReactNode[] = [];

        for (let rowIndex = rowStartIndex; rowIndex <= rowStopIndex; rowIndex++) {
          const renderRow = (isUitgeklapt: boolean) => {
            let top: number;
            let height: number;
            if (isUitgeklapt) {
              const hoofdRijIndex = rowIndex - 1;
              const hoofdRijSAPD = rowSizeAndPositionManager.getSizeAndPositionOfCell(
                hoofdRijIndex,
              );
              top = hoofdRijSAPD.offset + verticalOffsetAdjustment;
              height = sizeAndPositionData.size + hoofdRijSAPD.size;
            } else {
              top = sizeAndPositionData.offset + verticalOffsetAdjustment;
              height = sizeAndPositionData.size;
            }

            const RowComponent = props.onRijenHerordenen === undefined ? Row : SortableRow;
            const renderedRow = (
              <RowComponent
                key={rowIndex}
                style={{
                  position: 'absolute',
                  top,
                  height,
                  right: 0,
                  left: 0,
                }}
                index={rowIndex}
              >
                {renderedCells}
              </RowComponent>
            );

            renderedRows.push(renderedRow);
            renderedCells = [];
          };

          const renderCell = (key: string, cellRendererParams: GridCellProps) => {
            let renderedCell;

            // Avoid re-creating cells while scrolling.
            // This can lead to the same cell being created many times and can cause performance issues for "heavy" cells.
            // If a scroll is in progress-cache and reuse cells.
            // This cache will be thrown away once scrolling completes.
            // However, if we are scaling scroll positions and sizes, we should also avoid caching.
            // This is because the offset changes slightly as scroll position changes and caching leads to stale values.
            // For more info refer to issue #395
            //
            // If isScrollingOptOut is specified, we always cache cells.
            // For more info refer to issue #1028
            if (shouldUseCache) {
              if (!cellCache[key]) {
                // @ts-ignore
                cellCache[key] = cellRenderer(cellRendererParams);
              }

              renderedCell = cellCache[key];

              // If the user is no longer scrolling, don't cache cells.
              // This makes dynamic cell content difficult for users and would also lead to a heavier memory footprint.
            } else {
              // @ts-ignore
              renderedCell = cellRenderer(cellRendererParams);
            }

            if (renderedCell == null || renderedCell === false) {
              return;
            }

            if (process.env.NODE_ENV !== 'production') {
              // warnAboutMissingStyle(parent, renderedCell);
            }

            if (!renderedCell.props.role) {
              renderedCell = React.cloneElement(renderedCell, { role: 'gridcell' });
            }

            renderedCells.push(renderedCell);
          };

          const renderMapItem = renderMap[rowIndex];
          const sizeAndPositionData = rowSizeAndPositionManager.getSizeAndPositionOfCell(rowIndex);

          if (renderMapItem === 'uitgeklapt') {
            const key = `${rowIndex}-uitgeklapt`;
            let style: CSSProperties;
            const hoofdRijIndex = rowIndex - 1;
            const hoofdRijSAPD = rowSizeAndPositionManager.getSizeAndPositionOfCell(hoofdRijIndex);

            // Cache style objects so shallow-compare doesn't re-render unnecessarily.
            if (canCacheStyle && styleCache[key]) {
              style = styleCache[key];
            } else {
              // In deferred mode, cells will be initially rendered before we know their size.
              // Don't interfere with CellMeasurer's measurements by setting an invalid size.
              if (deferredMeasurementCache && !deferredMeasurementCache.has(rowIndex, 0)) {
                // Position not-yet-measured cells at top/left 0,0,
                // And give them width/height of 'auto' so they can grow larger than the parent Grid if necessary.
                // Positioning them further to the right/bottom influences their measured size.
                style = {
                  height: 'auto',
                  left: 0,
                  position: 'absolute',
                  top: 0,
                  width: 'auto',
                };
              } else {
                style = {
                  height: sizeAndPositionData.size,
                  // left: columnDatum.offset + horizontalOffsetAdjustment,
                  left: 0,
                  position: 'absolute',
                  top: hoofdRijSAPD.size, // sizeAndPositionData.offset + verticalOffsetAdjustment,
                  // width: columnDatum.size,
                  // width: props.width,
                };

                // @ts-ignore
                styleCache[key] = style;
              }
            }

            const cellRendererParams: GridCellProps = {
              columnIndex: 0,
              isScrolling,
              isVisible: true,
              key,
              parent,
              rowIndex,
              style,
            };

            renderCell(key, cellRendererParams);
            renderRow(true);
            continue;
          }

          for (let columnIndex = columnStartIndex; columnIndex <= columnStopIndex; columnIndex++) {
            // const kolomRenderItem = props.kolomRenderMap[columnIndex];
            const isVisible =
              columnIndex >= visibleColumnIndices.start &&
              columnIndex <= visibleColumnIndices.stop &&
              rowIndex >= visibleRowIndices.start &&
              rowIndex <= visibleRowIndices.stop;

            const columnDatum = columnSizeAndPositionManager.getSizeAndPositionOfCell(columnIndex);
            const key = `${rowIndex}-${columnIndex}`;
            let style: CSSProperties;

            // Cache style objects so shallow-compare doesn't re-render unnecessarily.
            if (canCacheStyle && styleCache[key]) {
              style = styleCache[key];
            } else {
              // In deferred mode, cells will be initially rendered before we know their size.
              // Don't interfere with CellMeasurer's measurements by setting an invalid size.
              if (
                deferredMeasurementCache &&
                !deferredMeasurementCache.has(rowIndex, columnIndex)
              ) {
                // Position not-yet-measured cells at top/left 0,0,
                // And give them width/height of 'auto' so they can grow larger than the parent Grid if necessary.
                // Positioning them further to the right/bottom influences their measured size.
                style = {
                  height: 'auto',
                  left: 0,
                  position: 'absolute',
                  top: 0,
                  width: 'auto',
                };
              } else {
                style = {
                  height: sizeAndPositionData.size,
                  left: columnDatum.offset + horizontalOffsetAdjustment,
                  position: 'absolute',
                  // top: sizeAndPositionData.offset + verticalOffsetAdjustment,
                  width: columnDatum.size,
                };

                // @ts-ignore
                styleCache[key] = style;
              }
            }

            const cellRendererParams: GridCellProps = {
              columnIndex,
              isScrolling,
              isVisible,
              key,
              parent,
              rowIndex,
              style,
            };

            renderCell(key, cellRendererParams);
          }

          // Aangezien wij voor de uitgeklapte rijen een soort row misbruiken, moeten we hier alleen
          // een row renderen als de volgende rij niet uitgeklapt is
          const volgendRenderMapItemIndex = rowIndex + 1;
          if (volgendRenderMapItemIndex < rowStopIndex + 1) {
            const volgendRenderMapItem = renderMap[volgendRenderMapItemIndex];
            if (volgendRenderMapItem === 'uitgeklapt') {
              continue;
            }
          }

          renderRow(false);
        }

        return renderedRows;
      },
      [renderMap, props.onRijenHerordenen],
    );

    const renderGrid = useCallback(
      (params: IRenderGridParams) => {
        const GridComponent = props.onRijenHerordenen === undefined ? Grid : SortableGrid;

        const extraSortableProps: SortableContainerProps =
          props.onRijenHerordenen === undefined
            ? {}
            : {
                useDragHandle: true,
                onSortStart: () => {
                  document.body.classList.add('grabbing');
                },
                onSortEnd: ({ oldIndex, newIndex }) => {
                  const oudeRij = props.rijen[oldIndex];
                  const nieuweRij = props.rijen[newIndex];
                  props.onRijenHerordenen!({
                    oud: {
                      index: oldIndex,
                      rij: oudeRij,
                    },
                    nieuw: {
                      index: newIndex,
                      rij: nieuweRij,
                    },
                  });
                  document.body.classList.remove('grabbing');
                },
              };

        return (
          <BodyComponent width={props.width} height={props.height}>
            <GridComponent
              ref={(ref) => {
                if (!ref) {
                  params.ref?.(ref);
                  return;
                }

                if (props.onRijenHerordenen === undefined) {
                  params.ref?.(ref as Grid);
                  return;
                }
                const gridRef = (ref as any).getWrappedInstance();
                params.ref?.(gridRef);
              }}
              cellRenderer={cellRenderer}
              columnCount={Object.keys(props.kolomRenderMap).length}
              columnWidth={props.getColumnWidth}
              height={props.height}
              rowCount={props.totaalAantalRijen + (props.uitgeklapt?.length ?? 0)}
              // rowCount={props.totaalAantalRijen}
              rowHeight={rowHeight}
              width={props.width}
              onScroll={props.onScroll}
              onSectionRendered={({ rowStartIndex, rowStopIndex }) => {
                const index = {
                  startIndex: rowStartIndex,
                  stopIndex: rowStopIndex,
                };
                params.onRowsRendered?.(index);
              }}
              containerStyle={{
                overflow: 'visible',
              }}
              overscanRowCount={30}
              scrollToAlignment="start"
              cellRangeRenderer={cellRangeRenderer}
              {...extraSortableProps}
            />
          </BodyComponent>
        );
      },
      [
        cellRenderer,
        cellRangeRenderer,
        props.getColumnWidth,
        props.kolommen,
        props.height,
        props.totaalAantalRijen,
        props.width,
        props.onScroll,
        rowHeight,
        props.bodyComponent,
        props.scrollNaarIndex,
        props.onRijenHerordenen,
      ],
    );

    useBijGewijzigdEffect(() => {
      // Kolom render map is aangepast, het is dus mogelijk dat groottes veranderd zijn
      gridRef.current?.forceUpdate();
      gridRef.current?.recomputeGridSize();
    }, [props.kolomRenderMap]);

    const laatsteScrollNaarIndexToegepast = useRef<number | null>(null);
    const scrollNaarIndex = useCallback((index: number) => {
      laatsteScrollNaarIndexToegepast.current = index;
      console.log('scroll naar index', index);

      // Timeout nodig anders is de offset nog niet berekend
      setTimeout(() => {
        gridRef.current!.scrollToCell({
          rowIndex: index,
          columnIndex: 0,
        });
      }, 1);
    }, []);

    // Scrollen naar index zodra deze wijzigd
    useBijGewijzigdEffect(() => {
      if (gridRef.current === null || props.scrollNaarIndex === undefined) {
        return;
      }
      scrollNaarIndex(props.scrollNaarIndex);
    }, [props.scrollNaarIndex]);

    const handleGridRefBeschikbaar = useCallback(() => {
      if (props.scrollNaarIndex === undefined || laatsteScrollNaarIndexToegepast.current !== null) {
        return;
      }
      scrollNaarIndex(props.scrollNaarIndex);
    }, [props.scrollNaarIndex]);

    const BodyComponent = useMemo(() => props.bodyComponent ?? defaultBodyComponent, [
      props.bodyComponent,
    ]);

    if (props.totaalAantalRijen === 0) {
      const Comp = props.leegComponent ?? StandaardLeegComponent;
      return <Comp width={props.width} height={props.height} />;
    }

    let content = null;

    if (props.onExtraRijenAangevraagd) {
      content = (
        <InfiniteLoader
          loadMoreRows={loadMoreRows}
          isRowLoaded={isRowLoaded}
          rowCount={props.totaalAantalRijen}
          minimumBatchSize={props.meerRijenBatchGrootte ?? 50}
        >
          {(infiniteLoaderProps) =>
            renderGrid({
              ref: (node) => {
                infiniteLoaderProps.registerChild(node);
                gridRef.current = node;
                handleGridRefBeschikbaar();
              },
              onRowsRendered: (index) => {
                infiniteLoaderProps.onRowsRendered(index);
                props.onRijenGerendered?.(index);
              },
            })
          }
        </InfiniteLoader>
      );
    } else {
      content = renderGrid({
        ref: (node) => {
          gridRef.current = node;
          handleGridRefBeschikbaar();
        },
        onRowsRendered: props.onRijenGerendered,
      });
    }

    return (
      <BodyComponent width={props.width} height={props.height}>
        {content}
      </BodyComponent>
    );
  },
);

export default Body;
