import {
  IAanbiedenContractenParams,
  IAanbiedenContractenResult,
  IVersturenUitnodigingParams,
  IVersturenUitnodigingResult,
  OphalenRedenenAanbiedingResult,
  ITerugdraaienAanbiedenContractenParams,
  ITerugdraaienAanbiedenContractenResult,
  IVersturenOverzichtAangebodenContractenParams,
  IVersturenOverzichtAangebodenContractenResult,
  IOphalenUitnodigingenParams,
  IOphalenUitnodigingenResult,
  IToevoegenUitnodigingParams,
  IToevoegenUitnodigingResult,
  IVersturenHerinneringParams,
  IVersturenHerinneringResult,
} from '../../../../../shared/src/api/v2/contract/aanbieding';
import api from '../../index';
import ICheckData from '../../../../../shared/src/models/ICheckData';
import { IAchtergrondProces } from '../../../../../shared/src/api/v2/achtergrondProces';

const aanbieding = {
  ophalenRedenenAanbieding: async (): Promise<OphalenRedenenAanbiedingResult> => {
    return await api.post('/v2/contract/aanbieding/ophalen-redenen-aanbieding');
  },
  checkAanbiedenContracten: async (params: IAanbiedenContractenParams): Promise<ICheckData> => {
    return await api.post('/v2/contract/aanbieding/check-aanbieden-contracten', params);
  },
  aanbiedenContracten: async (
    params: IAanbiedenContractenParams,
  ): Promise<IAanbiedenContractenResult> => {
    return await api.post('/v2/contract/aanbieding/aanbieden-contracten', params);
  },
  toevoegenUitnodiging: async (
    params: IToevoegenUitnodigingParams,
  ): Promise<IToevoegenUitnodigingResult> => {
    return await api.post('/v2/contract/aanbieding/toevoegen-uitnodiging', params);
  },
  versturenUitnodiging: async (
    params: IVersturenUitnodigingParams,
  ): Promise<IVersturenUitnodigingResult> => {
    return await api.post('/v2/contract/aanbieding/versturen-uitnodiging', params);
  },
  ophalenUitnodigingen: async (
    params: IOphalenUitnodigingenParams,
  ): Promise<IOphalenUitnodigingenResult> => {
    return await api.post('/v2/contract/aanbieding/ophalen-uitnodigingen', params);
  },
  versturenOverzichtAangebodenContracten: async (
    params: IVersturenOverzichtAangebodenContractenParams,
  ): Promise<IVersturenOverzichtAangebodenContractenResult> => {
    return await api.post(
      '/v2/contract/aanbieding/versturen-overzicht-aangeboden-contracten',
      params,
    );
  },
  checkTerugdraaienAanbiedenContracten: async (
    params: ITerugdraaienAanbiedenContractenParams,
  ): Promise<ICheckData> => {
    return await api.post(
      '/v2/contract/aanbieding/check-terugdraaien-aanbieden-contracten',
      params,
    );
  },
  terugdraaienAanbiedenContracten: async (
    params: ITerugdraaienAanbiedenContractenParams,
  ): Promise<ITerugdraaienAanbiedenContractenResult> => {
    return await api.post('/v2/contract/aanbieding/terugdraaien-aanbieden-contracten', params);
  },
  versturenHerinnering: async (
    params: IVersturenHerinneringParams,
  ): Promise<IAchtergrondProces<IVersturenHerinneringResult>> => {
    return await api.post('/v2/contract/aanbieding/versturen-herinnering', params);
  },
};

export default aanbieding;
