import React from 'react';
import { IFormikValues, veldnamen } from '../index';
import { Field, FieldProps } from 'formik';
import nameOf from '../../../../../../core/nameOf';
import NumeriekVeld from '../../../../../../components/formulier/NumeriekVeld';
import VinkVeld from '../../../../../../components/formulier/VinkVeld';

const ProductsoortTab = () => {
  return (
    <div className="form-group p-3">
      <div className="row">
        <div className="col-12">
          <label>{veldnamen.naam}</label>
          <Field
            name={nameOf<IFormikValues>('naam')}
            render={(fieldProps: FieldProps<IFormikValues>) => {
              return <input type="text" className="form-control" {...fieldProps.field} />;
            }}
          />
        </div>

        <div className="col-12 mt-3">
          <label>{veldnamen.naamKort}</label>
          <Field
            name={nameOf<IFormikValues>('naamKort')}
            render={(fieldProps: FieldProps<IFormikValues>) => {
              return <input type="text" className="form-control" {...fieldProps.field} />;
            }}
          />
        </div>

        {/* <div className="col-12 mt-2">
                        <label>{veldnamen.naamKort}</label>
                        <Field
                          name={nameOf<IFormikValues>('naamKort')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            return <textarea {...field} className="form-control" rows={2} />;
                          }}
                        />
                      </div> */}

        <div className="row col-12 mt-3 d-flex">
          <div className="col-4">
            <label>{veldnamen.aantalGebruikersTarief}</label>
            <Field
              name={nameOf<IFormikValues>('aantalGebruikersTarief')}
              render={(fieldProps: FieldProps<IFormikValues>) => {
                const { field, form } = fieldProps;
                return (
                  <div>
                    <NumeriekVeld
                      waarde={field.value}
                      onChange={(x) => form.setFieldValue(field.name, x)}
                      min={0}
                      max={99}
                      inactiefRepresentatie={(x) => {
                        if (field.value === 0) {
                          return 'Onbegrensd';
                        }
                        return `${field.value}`;
                      }}
                    />
                    {/* <FormikVeldFout fieldProps={fieldProps} /> */}
                  </div>
                );
              }}
            />
          </div>

          <div className="col-4">
            <label>{veldnamen.aantalGebruikersMax}</label>
            <Field
              name={nameOf<IFormikValues>('aantalGebruikersMax')}
              render={(fieldProps: FieldProps<IFormikValues>) => {
                const { field, form } = fieldProps;
                return (
                  <div>
                    <NumeriekVeld
                      waarde={field.value}
                      onChange={(x) => form.setFieldValue(field.name, x)}
                      min={0}
                      max={99}
                      inactiefRepresentatie={(x) => {
                        if (field.value === 0) {
                          return 'Onbegrensd';
                        }
                        return `${field.value}`;
                      }}
                    />
                    {/* <FormikVeldFout fieldProps={fieldProps} /> */}
                  </div>
                );
              }}
            />
          </div>
        </div>

        <div className="row col-12 mt-3 d-flex">
          <div className="col-4">
            <label>{veldnamen.leeftijdGrens}</label>
            <Field
              name={nameOf<IFormikValues>('leeftijdGrens')}
              render={(fieldProps: FieldProps<IFormikValues>) => {
                const { field, form } = fieldProps;
                return (
                  <div>
                    <NumeriekVeld
                      waarde={field.value}
                      onChange={(x) => form.setFieldValue(field.name, x)}
                      min={0}
                      max={240}
                      inactiefRepresentatie={(x) => {
                        if (field.value === 0) {
                          return 'Geen';
                        }
                        return `${field.value}`;
                      }}
                    />
                    {/* <FormikVeldFout fieldProps={fieldProps} /> */}
                  </div>
                );
              }}
            />
          </div>
        </div>

        <div className="row col-12 mt-3 d-flex">
          <Field
            name={nameOf<IFormikValues>('standaardVolume')}
            render={(fieldProps: FieldProps<IFormikValues>) => {
              const { field, form } = fieldProps;
              const waarde: number | null = field.value;

              // console.log(waarde);

              const aangevinkt = waarde !== null;
              const onAangevinktGewijzigd = (aangevinkt: boolean) => {
                form.setFieldValue(field.name, aangevinkt ? 0 : null);
                // console.log('onAangevinktGewijzigd');
              };

              const numeriekVeldWaarde: number = waarde ?? 0;

              return (
                <>
                  <div className="col-6 mt-3">
                    <span className="d-flex align-items-center">
                      <VinkVeld aangevinkt={aangevinkt} onGewijzigd={onAangevinktGewijzigd} />
                      <span className="ml-2">{veldnamen.standaardVolume}</span>
                    </span>
                  </div>

                  <div className="col-4 mt-2">
                    <NumeriekVeld
                      waarde={numeriekVeldWaarde}
                      onChange={(x) => {
                        form.setFieldValue(field.name, x);
                        console.log('onChange');
                      }}
                      min={0}
                      inactiefRepresentatie={(x) => {
                        if (field.value === null) {
                          return 'Geen';
                        }
                        return `${field.value}`;
                      }}
                      rootProps={{
                        disabled: !aangevinkt,
                      }}
                    />
                  </div>
                </>
              );
            }}
          />
        </div>

        <div className="col-12 mt-3">
          <span className="d-flex align-items-center">
            <Field
              name="actief"
              render={(x: FieldProps<IFormikValues>) => (
                <VinkVeld
                  aangevinkt={x.field.value}
                  onGewijzigd={(aangevinkt: boolean) =>
                    x.form.setFieldValue(x.field.name, aangevinkt)
                  }
                />
              )}
            />
            <span className="ml-2">{veldnamen.actief}</span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default ProductsoortTab;
