import {
  IOphalenMaterialenParams,
  IOphalenMaterialenResult,
  IToevoegenMateriaalParams,
  IToevoegenMateriaalResult,
  IWijzigenMateriaalParams,
  IWijzigenMateriaalResult,
  IVerwijderenMaterialenParams,
  IVerwijderenMaterialenResult,
} from '../../../../../../shared/src/api/v2/sponsoring/materiaal';
import ICheckData from '../../../../../../shared/src/models/ICheckData';
import api from '../../../index';

const Materiaal = {
  ophalenMaterialen: async (
    params: IOphalenMaterialenParams,
  ): Promise<IOphalenMaterialenResult> => {
    return await api.post('/v2/sponsoring/materiaal/ophalen-materialen', params);
  },
  checkToevoegenMateriaal: async (params: IToevoegenMateriaalParams): Promise<ICheckData> => {
    return await api.post('/v2/sponsoring/materiaal/check-toevoegen-materiaal', params);
  },
  toevoegenMateriaal: async (
    params: IToevoegenMateriaalParams,
  ): Promise<IToevoegenMateriaalResult> => {
    return await api.post('/v2/sponsoring/materiaal/toevoegen-materiaal', params);
  },
  checkWijzigenMateriaal: async (params: IWijzigenMateriaalParams): Promise<ICheckData> => {
    return await api.post('/v2/sponsoring/materiaal/check-wijzigen-materiaal', params);
  },
  wijzigenMateriaal: async (
    params: IWijzigenMateriaalParams,
  ): Promise<IWijzigenMateriaalResult> => {
    return await api.post('/v2/sponsoring/materiaal/wijzigen-materiaal', params);
  },
  checkVerwijderenMaterialen: async (params: IVerwijderenMaterialenParams): Promise<ICheckData> => {
    return await api.post('/v2/sponsoring/materiaal/check-verwijderen-materialen', params);
  },
  verwijderenMaterialen: async (
    params: IVerwijderenMaterialenParams,
  ): Promise<IVerwijderenMaterialenResult> => {
    return await api.post('/v2/sponsoring/materiaal/verwijderen-materialen', params);
  },
};

export default Materiaal;
