import React, { useCallback, useContext, useEffect, useState } from 'react';
import api from '../../../../../api';
import { IconPin } from '../../../../Icons';
import { Kleur as EKleur } from '../../../../../bedrijfslogica/constanten';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../../../stores/RootStore';
import { useRealtimeListener } from '../../../../../one-off-components/realtime/RealtimeIntegratie';
import { IRelatiePinGewijzigdMessageData } from '../../../../../../../shared/src/api/v2/relatie/Selectie';

enum EPinStatus {
  Ongepind,
  Gepind,
}

interface IProps {
  relID: number;
}

const PinKnop = observer((props: IProps) => {
  const { gebruikerStore } = useContext(RootStoreContext);
  const { relID } = props;

  const [pinStatus, setPinStatus] = useState<EPinStatus | null>(null);
  const pinStatusBepalen = useCallback(async () => {
    const result = await api.v2.relatieZoeken.ophalenGepindeRelaties({
      filterSchema: {
        filters: [
          {
            naam: 'REL_IDS',
            data: [relID],
          },
          {
            naam: 'ASP_GEBR_IDS',
            data: [gebruikerStore.gebruiker!.AspGebrID],
          },
        ],
      },
    });

    setPinStatus(result.relatiePins.length === 0 ? EPinStatus.Ongepind : EPinStatus.Gepind);
  }, [relID]);
  useEffect(() => {
    pinStatusBepalen();
  }, [pinStatusBepalen]);

  // Als de pin ergens wordt gewijzigd, dan direct bijwerken
  useRealtimeListener(async (naamEnum, data) => {
    if (naamEnum === 'RELATIE_PIN_GEWIJZIGD') {
      const d = data as IRelatiePinGewijzigdMessageData;
      if (d.relID !== relID || d.aspGebrID !== gebruikerStore.gebruiker!.AspGebrID) {
        return;
      }
      await pinStatusBepalen();
    }
  });

  return (
    <button
      className="mt-1"
      style={{
        padding: 0,
        margin: 0,
        background: 0,
        border: 0,
        outline: 0,
      }}
      onClick={async () => {
        const status = pinStatus === EPinStatus.Ongepind ? EPinStatus.Gepind : EPinStatus.Ongepind;
        setPinStatus(status);

        await api.v2.relatieZoeken.pinRelatie({
          relID,
          aspGebrID: gebruikerStore.gebruiker!.AspGebrID,
          gepind: status === EPinStatus.Gepind,
        });
      }}
    >
      {pinStatus === null ? (
        <div
          style={{
            width: 17,
            height: 17,
          }}
        />
      ) : pinStatus === EPinStatus.Ongepind ? (
        <IconPin
          style={{
            width: 17,
            height: 17,
            fill: EKleur.LichtGrijs,
          }}
        />
      ) : (
        <IconPin
          style={{
            width: 17,
            height: 17,
            fill: EKleur.Oranje,
            transform: 'rotate(45deg)',
          }}
        />
      )}
    </button>
  );
});

export default PinKnop;
