import React, { useState } from 'react';
import { EFunctioneleIcon, functioneleIconMap } from '../Icons';
import { Kleur } from '../../bedrijfslogica/constanten';
import TechnischeInformatieContentDialoog from './TechnischeInformatieContentDialoog';

interface IProps {
  dialoogIndex?: number;
  content: React.VoidFunctionComponent;
}

const TechnischeInformatieKnop: React.FC<IProps> = (props: IProps) => {
  const Icon = functioneleIconMap[EFunctioneleIcon.TechnisheInformatie];

  const [weergeven, setWeergeven] = useState(false);

  return (
    <>
      <button
        style={{
          padding: '2px 5px',
          border: 0,
          background: 0,
          outline: 0,
        }}
        className="d-flex align-items-center justify-content-center"
        onClick={() => setWeergeven(true)}
      >
        <Icon style={{ fill: Kleur.Grijs, width: 18, height: 18 }} />
      </button>
      {weergeven && (
        <TechnischeInformatieContentDialoog
          dialoogIndex={props.dialoogIndex}
          open
          onSuccess={() => setWeergeven(false)}
          onAnnuleren={() => setWeergeven(false)}
          content={props.content}
        />
      )}
    </>
  );
};

export default TechnischeInformatieKnop;
