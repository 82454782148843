import React, { useCallback, useContext, useEffect, useMemo, useState, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import IDialoogProps from '../../../../../core/IDialoogProps';
import Dialoog from '../../../../../components/dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import * as Yup from 'yup';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import DatumKiezer from '../../../../../components/formulier/DatumKiezer';
import { dagDatum } from '../../../../../helpers/datum';
import { IOphalenBatchesResultElement } from '../../../../../../../shared/src/api/v2/bank/opdracht/opdracht';
import api from '../../../../../api';
import nameOf from '../../../../../core/nameOf';
import VinkVeld from '../../../../../components/formulier/VinkVeld';
import MultiCombobox, { IKolom } from '../../../../../components/formulier/MultiCombobox';
import FormatteerBedrag from '../../../../../components/MutatieBedrag';
import { IOphalenRekeningenResultElement } from '../../../../../../../shared/src/api/v2/bank/bank';
import { IFilterSchemaFilter } from '../../../../../../../shared/src/models/filter';
import { addDays } from 'date-fns';
import { useHeeftAutorisatie } from '../../../../../helpers/heeftAutorisatie';

interface IProps extends IDialoogProps<{}> {
  batchID: number;
}

interface IFormikValues {
  uitvoerdatum: Date;
  nietVerstuurd: boolean;
  vasteOmschrijving: string;
  bankRekID: number;
  gesloten: boolean;
  isAfgeschermd: boolean;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  uitvoerdatum: 'Uitvoerdatum',
  nietVerstuurd: 'Bestand als niet verstuurd markeren',
  vasteOmschrijving: 'Vaste omschrijving (bij elke post)',
  bankRekID: 'Rekening',
  gesloten: 'Geen opdrachten meer toevoegen',
  isAfgeschermd: 'Afgeschermd',
};

const WijzigenBatchDialoog: React.FC<IProps> = observer((props) => {
  const { children, dialoogIndex, onAnnuleren, onSuccess, open } = props;

  const [batch, setBatch] = useState<IOphalenBatchesResultElement | null>(null);
  const [rekeningen, setRekeningen] = useState<IOphalenRekeningenResultElement[] | null>(null);

  // Batch ophalen
  useEffect(() => {
    (async () => {
      const batches = await api.v2.bank.opdracht.ophalenBatches({
        filterSchema: { filters: [{ naam: 'IDS', data: [props.batchID] }] },
      });
      const batch = batches[0];

      setBatch(batch);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (batch === null) {
        return;
      }
      const rekeningen = (
        await api.v2.bank.ophalenRekeningen({
          filterSchema: {
            filters: [
              { naam: 'IS_ACTIEF', data: true },
              batch.Soort === 1 ? { naam: 'INCASSO_CONTRACT', data: true } : null,
            ].filter((x) => x !== null) as IFilterSchemaFilter[],
          },
        })
      ).rekeningen;

      setRekeningen(rekeningen);
    })();
  }, [batch]);

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (batch === null) {
      return null;
    }

    return {
      uitvoerdatum: dagDatum(new Date(batch.Uitvoerdatum)),
      nietVerstuurd: false,
      vasteOmschrijving: batch.VasteOmschrijving !== null ? batch.VasteOmschrijving : '',
      bankRekID: batch.BankRekID,
      gesloten: batch.Gesloten,
      isAfgeschermd: batch.IsAfgeschermd,
    };
  }, [batch]);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        uitvoerdatum: Yup.date().required(),
      }),
    [],
  );

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      const params = {
        batchID: batch!.BatchID,
        uitvoerdatum: values.uitvoerdatum,
        nietVerstuurd: values.nietVerstuurd,
        vasteOmschrijving: values.vasteOmschrijving !== '' ? values.vasteOmschrijving : undefined,
        bankRekID: values.bankRekID,
        gesloten: values.gesloten,
        isAfgeschermd: values.isAfgeschermd,
      };
      const batches = await api.v2.bank.opdracht.wijzigenBatch(params);

      props.onSuccess({});
      actions.setSubmitting(false);
    },
    [batch],
  );

  const rekeningenKolommen = useMemo<IKolom<IOphalenRekeningenResultElement>[]>(
    () => [
      {
        key: 'NaamKort',
        label: 'Naam',
        breedte: 90,
      },
      {
        key: 'IBAN',
        label: 'IBAN',
        breedte: 200,
      },
      {
        key: 'Saldo',
        label: 'Definitief Saldo',
        breedte: 150,
        formatFabriek: (x) => <FormatteerBedrag bedrag={x.Saldo} />,
      },
    ],
    [],
  );

  const isGeautoriseerdVoorAfgeschermd = useHeeftAutorisatie([
    'FINANCIEEL_BANKZAKEN_BATCHES_AFGESCHERMD_WEERGEVEN',
  ]);

  return (
    <Dialoog index={dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Wijzigen batch</ModalTitle>
      </ModalHeader>
      {initialValues !== null && batch !== null && (
        <Formik<IFormikValues>
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
          isInitialValid
          render={(formikProps: FormikProps<IFormikValues>) => {
            const { submitForm, isValid, isSubmitting } = formikProps;

            return (
              <>
                <ModalBody>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-12 mt-2">
                        <label>{veldnamen.uitvoerdatum}</label>
                        <Field
                          name="uitvoerdatum"
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            const minimaleDatum = addDays(new Date(), -1);
                            const maximaleDatum = addDays(new Date(), +30);

                            return (
                              <DatumKiezer
                                waarde={field.value}
                                onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                disabled={batch.DatumVerstuurd !== null}
                                determineValidDate={(datum) => {
                                  return datum >= minimaleDatum && datum < maximaleDatum;
                                }}
                                determinePreviousValidDate={(date) => {
                                  const newDate = addDays(date, -1);
                                  if (newDate > minimaleDatum) {
                                    return newDate;
                                  }
                                  return null;
                                }}
                                determineNextValidDate={(date) => {
                                  const newDate = addDays(date, +1);
                                  if (newDate < maximaleDatum) {
                                    return newDate;
                                  }
                                  return null;
                                }}
                              />
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <label>{veldnamen.vasteOmschrijving}</label>
                        <Field
                          name={nameOf<IFormikValues>('vasteOmschrijving')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <input
                                className="form-control"
                                type="text"
                                {...field}
                                disabled={batch.DatumVerstuurd !== null}
                              />
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <label>{veldnamen.bankRekID}</label>
                        <Field
                          name={nameOf<IFormikValues>('bankRekID')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <>
                                <MultiCombobox<number, IOphalenRekeningenResultElement>
                                  sleutelExtractor={(row) => row.BankRekID}
                                  onWaardeChange={(waarde: number | null) => {
                                    form.setFieldValue(field.name, waarde);
                                  }}
                                  representatieFabriek={(row) => `${row.NaamKort}`}
                                  waarde={fieldProps.field.value}
                                  opties={rekeningen}
                                  kolommen={rekeningenKolommen}
                                />
                              </>
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <Field
                          name={nameOf<IFormikValues>('gesloten')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <div className="d-flex align-items-center">
                                <VinkVeld
                                  aangevinkt={field.value}
                                  onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                />
                                <span className="ml-2">{veldnamen.gesloten}</span>
                              </div>
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <Field
                          name={nameOf<IFormikValues>('isAfgeschermd')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <div className="d-flex align-items-center">
                                <VinkVeld
                                  aangevinkt={field.value}
                                  onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                  disabled={!isGeautoriseerdVoorAfgeschermd}
                                />
                                <span className="ml-2">{veldnamen.isAfgeschermd}</span>
                              </div>
                            );
                          }}
                        />
                      </div>

                      {batch.DatumVerstuurd !== null && (
                        <div className="col-12 mt-3">
                          <div className="d-flex align-items-center">
                            <Field
                              name={nameOf<IFormikValues>('nietVerstuurd')}
                              render={(fieldProps: FieldProps<IFormikValues>) => {
                                const { field, form } = fieldProps;
                                return (
                                  <VinkVeld
                                    aangevinkt={field.value}
                                    onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                  />
                                );
                              }}
                            />
                            <span className="ml-2">{veldnamen.nietVerstuurd}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </ModalBody>

                <ModalFooter className="d-flex flex-row justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    style={{ width: 100 }}
                    disabled={!isValid || isSubmitting}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={onAnnuleren}
                    style={{ width: 100 }}
                    disabled={isSubmitting}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Dialoog>
  );
});

export default WijzigenBatchDialoog;
