import React, { useCallback, useMemo } from 'react';
import { IUitgeklapteRijProps } from '../../../../../../../components/tabel/ASPTabel/Body/UitgeklapteRij';
import { IOpenstaandeDebiteurenResultElement } from '../../../../../../../../../shared/src/api/v2/boekhouding/rapportage';
import { IFactuur } from '../../../../../../../../../shared/src/api/v2/boekhouding/rapportage/data';
import { EOpenstaandeDebiteurenKolom } from '../';
import TabelInspringBlok from '../../../../../../../components/layout/TabelInspringBlok';
import ASPTabel from '../../../../../../../components/tabel/ASPTabel';
import {
  ASPKolom,
  EAspKolomBreedteType,
} from '../../../../../../../components/tabel/ASPTabel/types';
import { format } from 'date-fns';
import FormatteerBedrag from '../../../../../../../components/MutatieBedrag';
import FactuurVisualisatie from '../../../../../../../components/entiteitVisualisaties/FactuurVisualisatie';

enum EKolom {
  Factuurnummer,
  Factuurdatum,
  Vervaldatum,
  Bedrag,
  OpenstaandBedrag,
}

const UitgeklapteRij = (
  props: IUitgeklapteRijProps<EOpenstaandeDebiteurenKolom, IOpenstaandeDebiteurenResultElement>,
) => {
  const keyExtractor = useCallback((regel: IFactuur) => regel.FactID, []);
  const kolommen = useMemo<ASPKolom<EKolom, IFactuur>[]>(
    () => [
      {
        key: EKolom.Factuurnummer,
        label: 'Factuurnummer',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 200,
        renderer: (rij) => <FactuurVisualisatie factID={rij.FactID} />,
      },
      {
        key: EKolom.Factuurdatum,
        label: 'Factuurdatum',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 200,
        renderer: (rij) => format(new Date(rij.Factuurdatum), 'dd-MM-yyyy'),
      },
      {
        key: EKolom.Vervaldatum,
        label: 'Vervaldatum',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 200,
        renderer: (rij) => format(new Date(rij.Vervaldatum), 'dd-MM-yyyy'),
      },
      {
        key: EKolom.Bedrag,
        label: 'Bedrag',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 200,
        renderer: (rij) => <FormatteerBedrag bedrag={rij.Bedrag} />,
      },
      {
        key: EKolom.OpenstaandBedrag,
        label: 'Openstaand bedrag',
        breedteType: EAspKolomBreedteType.Flex,
        flex: 1,
        renderer: (rij) => <FormatteerBedrag bedrag={rij.Openstaand} />,
      },
    ],
    [],
  );

  return (
    <div className="d-flex flex-fill">
      <TabelInspringBlok />
      <div className="d-flex flex-fill">
        <ASPTabel rijen={props.regel.facturen} kolommen={kolommen} keyExtractor={keyExtractor} />
      </div>
    </div>
  );
};

export default UitgeklapteRij;
