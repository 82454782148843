import React, { HTMLProps, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../../models/IRemoteData';
import { IWhatsappTijdvensterAanvraag } from '../../../../../api/services/v1/whatsapp';
import { useWhatsappV2Store } from '../../store';
import api from '../../../../../api';
import { addHours } from 'date-fns';
import ASPGebruikerVisualisatie, {
  EModus,
} from '../../../../ASPGebruiker/ASPGebruikerVisualisatie';
import TijdVerschilMetNu from '../../../../TijdVerschilMetNu';
import { RootStoreContext } from '../../../../../stores/RootStore';
import { EResultType } from '../../../../../stores/CheckStore';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px 12px;

  border-top: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
`;

const Bericht = styled.span`
  font-size: 14px;
  font-weight: bold;
`;

const Button = styled.button`
  margin-top: 15px;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 14px;
  background-color: rgba(0, 0, 0, 0.05);
  outline: none;
  transition: background-color 0.2s ease-in-out;
  border: 1px solid rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }

  &:disabled {
    background-color: rgba(0, 0, 0, 0.2);
    cursor: not-allowed;
  }
`;

const AfwachtenTijdvensterAanvraagContainer = styled.div`
  margin-top: 10px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const AfwachtenTijdvensterAanvraagTijd = styled.span`
  font-weight: bold;
`;

const ASPGebruikerVisualisatieNaamComponent = (props: HTMLProps<HTMLSpanElement>) => (
  <span {...props} style={{ ...props.style, fontSize: 14 }} />
);

interface IProps {
  chatsessieID: number;
}

const TijdvensterVerlopen = (props: IProps) => {
  const { checkStore } = useContext(RootStoreContext);
  const store = useWhatsappV2Store();

  const tijdvensterAanvraagData = useMemo<IRemoteData<IWhatsappTijdvensterAanvraag | null> | null>(() => {
    const laatsteTijdvensterAanvraagData =
      store.laatsteTijdvensterAanvraagBijChatsessieId[props.chatsessieID];
    if (laatsteTijdvensterAanvraagData === undefined) {
      return null;
    }
    if (laatsteTijdvensterAanvraagData.state === ERemoteDataState.Pending) {
      return createPendingRemoteData();
    }
    return createReadyRemoteData(laatsteTijdvensterAanvraagData.data);
  }, [props.chatsessieID, store.laatsteTijdvensterAanvraagBijChatsessieId]);

  useEffect(() => {
    if (tijdvensterAanvraagData !== null) {
      return;
    }
    // noinspection JSIgnoredPromiseFromCall
    store.bepalenLaatsteTijdvensterAanvraagVoorChatsessie(props.chatsessieID);
  }, [props.chatsessieID]);

  const isTijdvensterAanvraagNietVerlopen = useMemo<IRemoteData<boolean>>(() => {
    if (tijdvensterAanvraagData === null || tijdvensterAanvraagData.data === null) {
      return createReadyRemoteData(false);
    }
    if (tijdvensterAanvraagData.state === ERemoteDataState.Pending) {
      return createPendingRemoteData();
    }

    const tijdvensterAanvraag = tijdvensterAanvraagData.data;

    // Als laatste tijdvenster aanvraag korter is dan 1 uur geleden, dan mag je niet opnieuw aanvragen.
    const nu = new Date();
    const laatsteTijdvensterAanvraag = new Date(tijdvensterAanvraag.datum);
    return createReadyRemoteData(addHours(laatsteTijdvensterAanvraag, 1) > nu);
  }, [tijdvensterAanvraagData]);

  const [
    bezigMetAanvragenConversatieVoortzetten,
    setBezigMetAanvragenConversatieVoortzetten,
  ] = useState(false);
  const aanvragenDisabled = useMemo(() => {
    if (
      bezigMetAanvragenConversatieVoortzetten ||
      tijdvensterAanvraagData === null ||
      tijdvensterAanvraagData.state === ERemoteDataState.Pending
    ) {
      return true;
    }

    const tijdvensterAanvraag = tijdvensterAanvraagData.data;
    if (tijdvensterAanvraag === null) {
      return false;
    }

    // Als laatste tijdvenster aanvraag korter is dan 1 uur geleden, dan mag je niet opnieuw aanvragen.
    const nu = new Date();
    const laatsteTijdvensterAanvraag = new Date(tijdvensterAanvraag.datum);
    if (addHours(laatsteTijdvensterAanvraag, 1) > nu) {
      return true;
    }

    return false;
  }, [tijdvensterAanvraagData, bezigMetAanvragenConversatieVoortzetten]);

  const handleAanvragenConversatieVoortzetten = useCallback(async () => {
    if (
      (
        await checkStore.bevestigen({
          inhoud: 'Bevestig aanvraag conversatie voorzetten',
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    setBezigMetAanvragenConversatieVoortzetten(true);
    await api.services.v1.whatsapp.conversatieVoortzettenAanvragen({
      chatsessieId: props.chatsessieID,
    });
    setBezigMetAanvragenConversatieVoortzetten(false);
  }, [props.chatsessieID]);

  return (
    <Root>
      <Bericht>Het tijdvenster om een bericht te kunnen sturen is verlopen.</Bericht>
      <Button disabled={aanvragenDisabled} onClick={handleAanvragenConversatieVoortzetten}>
        Aanvragen conversatie voortzetten
      </Button>
      {isTijdvensterAanvraagNietVerlopen.state === ERemoteDataState.Ready &&
        isTijdvensterAanvraagNietVerlopen.data! && (
          <AfwachtenTijdvensterAanvraagContainer>
            <div className="d-flex align-items-center">
              <span className="mr-2">Er is onlangs een aanvraag gedaan door</span>

              <ASPGebruikerVisualisatie
                aspGebrID={tijdvensterAanvraagData!.data!.aangevraagdDoorAspGebrId}
                modus={EModus.Volledig}
                naamComponent={ASPGebruikerVisualisatieNaamComponent}
                avatarGrootte={21}
              />
              <span className="ml-1">.</span>
            </div>
            <div className="d-flex align-items-center">
              <span className="mr-1">Je dient nog</span>
              <AfwachtenTijdvensterAanvraagTijd>
                <TijdVerschilMetNu
                  datum={addHours(new Date(tijdvensterAanvraagData!.data!.datum), 1)}
                  onGeenTijdVerschil={() => {
                    setTimeout(() => {
                      store.bepalenLaatsteTijdvensterAanvraagVoorChatsessie(props.chatsessieID);
                    }, 1000);
                  }}
                />
              </AfwachtenTijdvensterAanvraagTijd>
              <span className="ml-1">te wachten voor een volgende aanvraag.</span>
            </div>
          </AfwachtenTijdvensterAanvraagContainer>
        )}
    </Root>
  );
};

export default TijdvensterVerlopen;
