import React, { useContext, useEffect, useMemo, useState, useCallback } from 'react';
import useUrlState from '../../../core/useUrlState';
import { RouteComponentProps, withRouter } from 'react-router';
import { observer } from 'mobx-react-lite';
import api from '../../../api';
import {
  TypedColumn,
  TypedTableColumnWidthInfo,
  GridStyleWrapper,
  DXTableToggleCellComponent,
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
} from '../../../helpers/dxTableGrid';
import {
  Grid,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  TableSelection,
  VirtualTable,
  TableRowDetail,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  DataTypeProvider,
  EditingState,
  SelectionState,
  RowDetailState,
  VirtualTable as VirtualTableBase,
  SortingState,
  IntegratedSorting,
} from '@devexpress/dx-react-grid';
import { RootStoreContext } from '../../../stores/RootStore';
import { EResultType } from '../../../stores/CheckStore';
import LoadingSpinner from '../../../components/Gedeeld/LoadingSpinner';
import { IOphalenRechtzakenResultElement } from '../../../../../shared/src/api/v2/debiteur/rechtzaak';
import { Kleur } from '../../../bedrijfslogica/constanten';
import FormatteerBedrag from '../../../components/MutatieBedrag';
import { format } from 'date-fns';
import { DXTableCheckboxComponent } from '../../../helpers/dxTableGrid';
import RelatieVisualisatie from '../../../components/personalia/RelatieVisualisatie';
import { IOphalenDebiteurenMetVerrekeningenResultElement } from '../../../../../shared/src/api/v2/debiteur/verrekening';
import _ from 'lodash';
import { EHoedanigheid } from '../../../components/personalia/RelatieSelectieDialoog';
import FilterBalkV2, {
  IFilter,
  IFilterData,
  maakFilterSchema,
} from '../../../components/FilterBalkV2';
import { IOphalenBoekingRegelsResultElement } from '../../../../../shared/src/api/v2/boekhouding/boeking';
import { Helmet } from 'react-helmet';
import { IOphalenOpdrachtenResultElement } from '../../../../../shared/src/api/v2/bank/opdracht/opdracht';

interface IProps extends RouteComponentProps {}

enum EFilter {
  Verrekenbaar = 'VERREKENBAAR',
  HeeftUitgevoerdeBankopdracht = 'HEEFT_UITGEVOERDE_BANKOPDRACHT',
}

interface IUrlState {
  selectie: number[];
  filterdata: IFilterData<EFilter>[];
}

const defaultUrlState: IUrlState = {
  selectie: [],
  filterdata: [
    {
      naam: EFilter.Verrekenbaar,
      data: true,
      isActief: false,
    },
    {
      naam: EFilter.HeeftUitgevoerdeBankopdracht,
      data: false,
      isActief: true,
    },
  ],
};

const geenData = {
  noData: 'Geen resultaten voor het ingestelde filter',
};

export interface IRegelVerrekening extends IOphalenDebiteurenMetVerrekeningenResultElement {
  bankopdrachten: IOphalenOpdrachtenResultElement[];
}

const Verrekeningen: React.FC<IProps> = observer((props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const { klantkaartStore, checkStore } = useContext(RootStoreContext);
  const [verrekeningen, setVerrekeningen] = useState<IRegelVerrekening[] | null>(null);
  const [wijzigenID, setWijzigenID] = useState<number | null>(null);
  const [boekingsregels, setBoekingsregels] = useState<IOphalenBoekingRegelsResultElement[] | null>(
    null,
  );
  const [filterSchema, setFilterSchema] = useState(maakFilterSchema(urlState.filterdata));

  const ophalenVerrekeningen = useCallback(async () => {
    const result = await api.v2.debiteur.verrekening.ophalenDebiteurenMetVerrekeningen({
      filterSchema,
    });

    // const relIDs = result.debiteuren.map((x) => x.RelID);
    // const relatiesResult = await api.v2.relatie.ophalenRelaties({
    //   filterSchema: { filters: [{ naam: 'IDS', data: relIDs }] },
    // });

    const debiteuren = result.debiteuren.map((debiteur) => {
      // const relatie = relatiesResult.relaties.find((x) => x.RelID === debiteur.RelID)!;
      // const dbFacturen = facturen.filter((x) => x.Openstaand > 0);
      // const crFacturen = facturen.filter((x) => x.Openstaand < 0);
      return { ...debiteur };
    });

    const bankopdrachtenResult = (
      await api.v2.bank.opdracht.ophalenOpdrachten({
        filterSchema: {
          filters: [
            { naam: 'REL_IDS', data: debiteuren.map((x) => x.RelID) },
            { naam: 'IS_UITGEVOERD', data: false },
          ],
        },
      })
    ).opdrachten;

    const debiteurenMetVerrekening = debiteuren.filter((debiteur) => {
      return { ...debiteur };
    });

    const regels = debiteurenMetVerrekening.map((x) => {
      const bankopdrachten: IOphalenOpdrachtenResultElement[] = bankopdrachtenResult.filter(
        (b) => b.RelID === x.RelID,
      );
      return { ...x, bankopdrachten };
    });

    setVerrekeningen(regels);

    // Om de datum van de laatste boeking te bepalen
    const relIDs = debiteuren.map((x) => x.RelID);
    const boekingsregels = (
      await api.v2.boekhouding.boeking.ophalenBoekingregels({
        filterSchema: { filters: [{ naam: 'REL_IDS', data: relIDs }] },
      })
    ).regels;

    setBoekingsregels(boekingsregels);
  }, [JSON.stringify(filterSchema)]);

  useEffect(() => {
    ophalenVerrekeningen();
  }, [ophalenVerrekeningen]);

  const handleVerwijderen = useCallback(async (ID: number) => {
    // const checkData = await api.v2.debiteur.incassozaak.checkVerwijderenDossier({
    //   ID,
    // });
    // if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
    //   return;
    // }
    // if (
    //   (
    //     await checkStore.bevestigen({
    //       titel: `Wil je het dossier verwijderen?`,
    //     })
    //   ).type === EResultType.Annuleren
    // ) {
    //   return;
    // }

    // await api.v2.debiteur.incassozaak.verwijderenDossier({
    //   ID,
    // });

    // setUrlStateSync('selectie', []);

    ophalenVerrekeningen();
  }, []);

  const keyExtractor = useCallback((rij: IOphalenRechtzakenResultElement) => rij.RelID, []);

  const kolommen = useMemo<TypedColumn<IRegelVerrekening>[]>(
    () => [
      {
        name: 'Relatienummer',
        title: 'Rel.nr.',
      },
      {
        name: '__relatie' as any,
        title: 'Relatie',
        getCellValue: (x: IRegelVerrekening) => x.relatie.weergavenaam,
      },
      {
        name: '__dbSaldo' as any,
        title: 'Saldo DB-facturen',
        getCellValue: (x) =>
          _.sum(x.facturen.filter((x) => x.Openstaand > 0).map((x) => x.Openstaand)),
      },
      {
        name: '__crSaldo' as any,
        title: 'Saldo CR-facturen',
        getCellValue: (x) => {
          return _.sum(x.facturen.filter((x) => x.Openstaand < 0).map((x) => x.Openstaand));
        },
      },
      {
        name: '__vuSaldo' as any,
        title: 'Saldo VU',
        getCellValue: (x) => x.vuSaldo,
      },
      {
        name: '__laatsteBoeking' as any,
        title: 'Laatste boeking',
        getCellValue: (relatie) => {
          if (boekingsregels === null) {
            return;
          }
          const boekingsregelsVoorRelatie = boekingsregels.filter((x) => x.RelID === relatie.RelID);
          const boekingsregelsGesorteerd = _.orderBy(
            boekingsregelsVoorRelatie,
            ['Boekdatum'],
            ['desc'],
          );
          const laatsteBoekingsregel =
            boekingsregelsGesorteerd.length !== 0 ? boekingsregelsGesorteerd[0] : null;
          return laatsteBoekingsregel !== null ? new Date(laatsteBoekingsregel.Boekdatum) : null;
        },
      },
      {
        name: '__heeftBankopdracht' as any,
        title: 'Heeft lopende bankopdracht',
        getCellValue: (x) => x.vuSaldo,
      },
    ],
    [boekingsregels],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRegelVerrekening>[]>(
    () => [
      {
        columnName: 'Relatienummer',
        width: 90,
      },
      {
        columnName: '__relatie' as any,
        width: 290,
      },
      {
        columnName: '__dbSaldo' as any,
        width: 160,
      },
      {
        columnName: '__crSaldo' as any,
        width: 160,
      },
      {
        columnName: '__vuSaldo' as any,
        width: 125,
      },
      {
        columnName: '__laatsteBoeking' as any,
        width: 150,
      },
      {
        columnName: '__heeftBankopdracht' as any,
        width: 250,
      },
    ],
    [],
  );

  const kolomExtensies: VirtualTableBase.ColumnExtension[] = useMemo(() => {
    return [
      // {
      //   columnName: `__dbSaldo`,
      //   align: 'right',
      // },
      // {
      //   columnName: `__crSaldo`,
      //   align: 'right',
      // },
      // {
      //   columnName: `__vuSaldo`,
      //   align: 'right',
      // },
    ];
  }, []);

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.Verrekenbaar,
        altijdWeergevenInBalk: true,
        actiefMuteerbaar: true,
        weergave: (weergaveProps) => {
          return <span>Alleen verrekenbare relaties</span>;
        },
      },
      {
        naam: EFilter.HeeftUitgevoerdeBankopdracht,
        altijdWeergevenInBalk: true,
        actiefMuteerbaar: true,
        weergave: (weergaveProps) => {
          return <span>Alleen zonder lopende bankopdracht</span>;
        },
      },
    ],
    [],
  );

  return (
    <>
      <Helmet>
        <title>Verrekeningen Debiteuren</title>
      </Helmet>
      <div
        className="d-flex flex-column p-3"
        style={{
          backgroundColor: Kleur.HeelLichtGrijs,
          borderBottom: `1px solid ${Kleur.LichtGrijs}`,
        }}
      >
        <div className="d-flex">
          {/* <button
            className="btn btn-sm btn-light d-flex align-items-center ml-3"
            disabled={urlState.selectie.length === 0}
            style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
            onClick={() => alert('Nog implementeren')}
          >
            <span className="ml-2">Automatisch verrekenen</span>
          </button> */}

          <div className="flex-fill">
            <FilterBalkV2
              filters={filters}
              filterData={urlState.filterdata}
              onFilterDataChange={(x) => setUrlStateSync('filterdata', x)}
              onFilterSchemaChange={setFilterSchema}
            />
          </div>
        </div>
      </div>

      {verrekeningen === null || boekingsregels === null ? (
        <div className="flex-fill d-flex align-items-center justify-content-center">
          <LoadingSpinner />
        </div>
      ) : (
        <GridStyleWrapper height={'calc(100vh - 100px)'}>
          <Grid rows={verrekeningen} getRowId={keyExtractor} columns={kolommen}>
            <DataTypeProvider
              for={['__relatie']}
              formatterComponent={(props) => (
                <RelatieVisualisatie
                  relID={props.row.RelID}
                  relatieLinkBuilder={(hoedanigheid, relID) =>
                    `/${
                      hoedanigheid === EHoedanigheid.Klant ? 'klant' : 'leverancier'
                    }/${relID}/facturen/overzicht`
                  }
                />
              )}
            />

            <DataTypeProvider
              for={['__dbSaldo']}
              formatterComponent={(props) => {
                const rij: IRegelVerrekening = props.row;
                const dbSaldo = _.sum(
                  rij.facturen.filter((x) => x.Openstaand > 0).map((x) => x.Openstaand),
                );
                return dbSaldo !== 0 ? <FormatteerBedrag bedrag={dbSaldo} /> : <span></span>;
              }}
            />

            <DataTypeProvider
              for={['__crSaldo']}
              formatterComponent={(props) => {
                const rij: IRegelVerrekening = props.row;
                const crSaldo = _.sum(
                  rij.facturen.filter((x) => x.Openstaand < 0).map((x) => x.Openstaand),
                );
                return crSaldo !== 0 ? <FormatteerBedrag bedrag={crSaldo} /> : <span></span>;
              }}
            />

            <DataTypeProvider
              for={['__vuSaldo']}
              formatterComponent={(props) => {
                const rij: IRegelVerrekening = props.row;

                return rij.vuSaldo !== 0 ? (
                  <FormatteerBedrag bedrag={rij.vuSaldo} />
                ) : (
                  <span></span>
                );
              }}
            />

            <DataTypeProvider
              for={['__laatsteBoeking']}
              formatterComponent={(props) => {
                const rij: IRegelVerrekening = props.row;
                const boekingsregelsVoorRelatie = boekingsregels.filter(
                  (x) => x.RelID === rij.RelID,
                );
                const boekingsregelsGesorteerd = _.orderBy(
                  boekingsregelsVoorRelatie,
                  ['Boekdatum'],
                  ['desc'],
                );

                const laatsteBoekingsregel =
                  boekingsregelsGesorteerd.length !== 0 ? boekingsregelsGesorteerd[0] : null;

                return laatsteBoekingsregel !== null ? (
                  <span>{format(new Date(laatsteBoekingsregel.Boekdatum), 'dd-MM-yyyy')}</span>
                ) : (
                  <span></span>
                );
              }}
            />

            <DataTypeProvider
              for={['__heeftBankopdracht']}
              formatterComponent={(props) => {
                const rij: IRegelVerrekening = props.row;

                if (rij.bankopdrachten.length !== 0) {
                  return <span>Ja</span>;
                }

                return <span></span>;
              }}
            />

            <EditingState
              onCommitChanges={async (changes) => {
                if (changes.deleted === undefined) {
                  return;
                }
                const deleted = changes.deleted;
                const id = deleted[deleted.length - 1] as number;
                // alert(id);
                await handleVerwijderen(id);
              }}
              onEditingRowIdsChange={(rowIds) => {
                const id = rowIds[rowIds.length - 1] as number;
                // alert(id);
                setWijzigenID(id);
              }}
            />

            <RowDetailState defaultExpandedRowIds={[]} />

            <SortingState defaultSorting={[]} />
            <IntegratedSorting />

            <VirtualTable columnExtensions={kolomExtensies} messages={geenData} />
            <TableColumnResizing defaultColumnWidths={kolomBreedtes} />

            {/* <TableEditColumn
              width={65}
              showEditCommand
              showDeleteCommand
              cellComponent={DXTableEditColumnCellComponent}
              commandComponent={DXTableEditColumnCommandComponent}
            /> */}
            <TableHeaderRow showSortingControls />
            {/* <TableRowDetail
              contentComponent={DetailComp}
              toggleCellComponent={DXTableToggleCellComponent}
            /> */}
            <SelectionState
              selection={urlState.selectie}
              onSelectionChange={(x) => setUrlStateSync('selectie', x as number[])}
            />
            <TableSelection cellComponent={DXTableCheckboxComponent} />
          </Grid>
        </GridStyleWrapper>
      )}
    </>
  );
});

export default withRouter(Verrekeningen);
