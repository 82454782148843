import React from 'react';
import Tegel from '../Tegel';
import { Kleur as EKleur } from '../../../bedrijfslogica/constanten';
import EntiteitHeading from '../EntiteitHeading';

export interface ITegelBasisProps {
  titel: JSX.Element | string;
  subtitel?: JSX.Element | string;
  navigatiePijl?: JSX.Element;
}

const TegelBasis: React.FC<ITegelBasisProps> = (props) => {
  return (
    <div className="pt-2 pb-2 h-100">
      <Tegel rootProps={{ style: { height: '100%', backgroundColor: EKleur.Wit } }}>
        <EntiteitHeading>
          {props.titel}{' '}
          {props.subtitel !== undefined && (
            <span style={{ color: EKleur.LichtGrijs }} className="ml-2">
              ({props.subtitel})
            </span>
          )}
          {props.navigatiePijl}
        </EntiteitHeading>
        <div className="flex-fill d-flex flex-column w-100 h-100 pl-2 pr-2">{props.children}</div>
      </Tegel>
    </div>
  );
};

export default TegelBasis;
