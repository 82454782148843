import React, { useCallback, useMemo } from 'react';
import { IWeergaveProps } from '../../../../../components/FilterBalkV2';
import { BerichtenFilter } from '../index';
import MultiComboboxV2MetZoeken, {
  IMultiComboboxV2MetZoekenOverlayState,
} from '../../../../../components/formulier/MultiComboboxV2/MetZoeken';
import { IBulkVerzendBericht } from '../../../../../../../shared/src/api/v2/bulkVerzending';
import { IRepresentatieProps, Provider } from '../../../../../components/formulier/MultiComboboxV2';
import { ASPKolom, EAspKolomBreedteType } from '../../../../../components/tabel/ASPTabel/types';

type Kolom = 'status';

const bulkVerzendBerichtStatusNaamMapping: Record<IBulkVerzendBericht['Status'], string> = {
  in_review: 'In review',
  in_afwachting: 'In afwachting',
  in_behandeling: 'In behandeling',
  gelukt: 'Gelukt',
  mislukt: 'Mislukt',
};

const StatusFilter = (props: IWeergaveProps<BerichtenFilter>) => {
  const provider = useMemo<
    Provider<Kolom, IBulkVerzendBericht['Status'], IMultiComboboxV2MetZoekenOverlayState>
  >(
    () => async (params) => {
      const alleStati: IBulkVerzendBericht['Status'][] = [
        'in_review',
        'in_afwachting',
        'in_behandeling',
        'gelukt',
        'mislukt',
      ];

      const zoektermLowercase = params.overlayContainerState.zoekterm.trim().toLowerCase();
      const heeftZoekterm = zoektermLowercase.length > 0;

      const items = alleStati
        .filter((status) => {
          if (!heeftZoekterm) {
            return true;
          }
          const naam = bulkVerzendBerichtStatusNaamMapping[status].toLowerCase();
          return naam.includes(zoektermLowercase);
        })
        .reduce<Record<number, IBulkVerzendBericht['Status']>>((acc, curr, i) => {
          acc[i] = curr;
          return acc;
        }, {});

      return {
        items,
        totaalAantal: Object.keys(items).length,
      };
    },
    [],
  );
  const enkeleProvider = useMemo(
    () => async (status: IBulkVerzendBericht['Status']) => {
      return status;
    },
    [],
  );
  const keyExtractor = useCallback((status: IBulkVerzendBericht['Status']) => status, []);

  const handleChange = useCallback(
    (status: IBulkVerzendBericht['Status'] | null) => {
      props.onDataChange(status);
      props.setIsActief(true);
      props.toepassen();
    },
    [props.onDataChange, props.setIsActief, props.toepassen],
  );

  const kolommen = useMemo<ASPKolom<Kolom, IBulkVerzendBericht['Status']>[]>(
    () => [
      {
        key: 'status',
        label: 'Status',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 200,
        renderer: (status) => bulkVerzendBerichtStatusNaamMapping[status],
      },
    ],
    [],
  );

  return (
    <div className="d-flex align-items-center">
      <span className="mr-2">Status</span>
      <MultiComboboxV2MetZoeken
        provider={provider}
        enkeleProvider={enkeleProvider}
        keyExtractor={keyExtractor}
        waarde={props.data}
        onChange={handleChange}
        representatieComponent={Representatie}
        kolommen={kolommen}
      />
    </div>
  );
};

const Representatie = (props: IRepresentatieProps<IBulkVerzendBericht['Status']>) => {
  return <span>{bulkVerzendBerichtStatusNaamMapping[props.entiteit]}</span>;
};

export default StatusFilter;
