import React from 'react';
import Combobox from '../../../../components/formulier/Combobox';
import {
  IVergelijkingspuntWaardeTekstVoorMuteren,
  VergelijkingspuntWaardeVoorMuteren,
} from '../../../../../../shared/src/api/v2/concurrent/vergelijkingspunt';
import NumeriekVeld from '../../../../components/formulier/NumeriekVeld';
import MeertaligTekstveld from '../../../../components/formulier/MeertaligTekstveld';
import BedragInput from '../../../../components/formulier/BedragInput';

interface IProps {
  waarde: VergelijkingspuntWaardeVoorMuteren;
  onChange: (waarde: VergelijkingspuntWaardeVoorMuteren) => void;
}

const WaardeVeld = (props: IProps) => {
  const type = props.waarde.type;

  switch (type) {
    case 'getal':
      return (
        <NumeriekVeld
          waarde={props.waarde.waarde}
          onChange={(x) => props.onChange({ type: 'getal', waarde: x })}
        />
      );
    case 'tekst':
      const tekstWaarde = props.waarde as IVergelijkingspuntWaardeTekstVoorMuteren;
      return (
        <MeertaligTekstveld
          waarden={tekstWaarde.taalTeksten}
          onChange={(teksten) =>
            props.onChange({
              type: 'tekst',
              tekstID: tekstWaarde.tekstID,
              taalTeksten: teksten,
            })
          }
        />
      );
    case 'ja_nee':
      return (
        <Combobox<boolean>
          geselecteerd={props.waarde.waarde}
          onSelectieChange={(optie) => props.onChange({ type: 'ja_nee', waarde: optie! })}
          opties={[
            {
              id: true,
              label: 'Ja',
            },
            {
              id: false,
              label: 'Nee',
            },
          ]}
        />
      );
    case 'bedrag':
      return (
        <BedragInput
          value={props.waarde.waarde}
          onChange={(value) => props.onChange({ type: 'bedrag', waarde: value })}
        />
      );
  }

  return null;
};

export default WaardeVeld;
