import api from '../../../api';
import klantWerftKlant from './klantWerftKlant';
import winweek from './winweek';

const klantactie = {
  klantWerftKlant,
  winweek,
};

export default klantactie;
