import api from '../../../index';
import ICheckData from '../../../../../../shared/src/models/ICheckData';
import {
  IOphalenBeltaakContextenParams,
  IOphalenBeltaakContextenResult,
} from '../../../../../../shared/src/api/v2/beltaak/context/context';

const context = {
  ophalenBeltaakContexten: async (
    params: IOphalenBeltaakContextenParams,
  ): Promise<IOphalenBeltaakContextenResult> => {
    return await api.post('/v2/beltaak/context/ophalen-beltaak-contexten', params);
  },
};

export default context;
