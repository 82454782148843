import api from '../../index';
import {
  IOpslaanActiviteitParams,
  IOpslaanActiviteitResult,
} from '../../../../../shared/src/api/v2/activiteitsmonitor';

const activiteitsmonitor = {
  opslaanActiviteit: async (
    params: IOpslaanActiviteitParams,
  ): Promise<IOpslaanActiviteitResult> => {
    return await api.post(`/v2/activiteitsmonitor/opslaan-activiteit`, params);
  },
};

export default activiteitsmonitor;
