import React, { useCallback, useContext, useMemo, useState } from 'react';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  TableRowDetail,
  VirtualTable,
  TableEditColumn,
} from '@devexpress/dx-react-grid-bootstrap4';
import { DataTypeProvider, EditingState, RowDetailState } from '@devexpress/dx-react-grid';
import { format } from 'date-fns';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  IUrlState as ITransportPlanningUrlState,
  defaultUrlState as transportPlanningDefaultUrlState,
} from '../../../../../../../../../../../../components/kaart/entiteiten/Transport/Opdrachten';
import {
  IUrlState as ITransportUitstaandUrlState,
  defaultUrlState as transportUitstaandDefaultUrlState,
} from '../../../../../../../../../../../../components/kaart/entiteiten/Transport/Opdrachten';
import {
  IUrlState as ITransportAfgehandeldUrlState,
  defaultUrlState as transportAfgehandeldDefaultUrlState,
} from '../../../../../../../../../../../../components/kaart/entiteiten/Transport/Opdrachten';
import RowDetail from './RowDetail';
import KoppelVoorraadProductDialoog, {
  ETabblad,
} from '../../../../../../../../../../../Transport/Opdrachten/reservering/KoppelVoorraadProductDialoog';
import KoppelLocatieProductDialoog from '../../../../../../../../../../../Transport/Opdrachten/reservering/KoppelLocatieProductDialoog';
import VoorraadIndicatie, {
  EVoorraadStatus,
} from '../../../../../../../../../../../../components/VoorraadIndicatie';
import WijzigenRegelDialoog from '../../../../../../../../../../../../components/transport/WijzigenRegelDialoog';
import WijzigenOpdrachtDialoog from '../../../../../../../../../../../../components/transport/WijzigenOpdrachtDialoog';
import { IOphalenContractenResultElementV2 } from '../../../../../../../../../../../../../../shared/src/api/v2/contract';
import NieuweTransportopdrachtDialoog from '../../../../../../../../../../../../components/transport/NieuweOpdrachtDialoog';
import ProductreserveringDialoog from '../../../../../../../../../../../../components/transport/ProductreserveringDialoog';
import {
  defaultUrlState as transportOpdrachtenDefaultUrlState,
  EFilter as ETransportOpdrachtFilter,
  IUrlState as ITransportOpdrachtUrlState,
} from '../../../../../../../../../../../../components/kaart/entiteiten/Transport/Opdrachten';
import { defaultOpdrachtState } from '../../../../../../../../../../../../components/kaart/entiteiten/Transport/Opdrachten/DetailComp';
import TransportopdrachtregelVisualisatie from '../../../../../../../../../../../../components/entiteitVisualisaties/TransportopdrachtregelVisualisatie';
import {
  IOphalenOpdrachtenResultElementV2,
  IOphalenOpdrachtregelsResultElementV2,
} from '../../../../../../../../../../../../../../shared/src/api/v2/transport/opdracht';
import {
  EOpdrachtstatusTransport,
  ETransportopdrachtRegelsoort,
} from '../../../../../../../../../../../../bedrijfslogica/enums';
import KoppelVoorraadDialoog from '../../../../../../../../../../../Transport/Opdrachten/reservering/KoppelVoorraadProductDialoog';
import { IconButton } from '../../../../../../../../../../../../components/locatie/AdresVisualisatie/style';
import { RootStoreContext } from '../../../../../../../../../../../../stores/RootStore';
import { EResultType } from '../../../../../../../../../../../../stores/CheckStore';
import ReferentieProductComp from '../../../../../../../../../../../../components/kolomveld/transportopdrachtregel/ReferentieProductComp';
import VoorraadTypeInfoDialoog from '../../../../../../../../../../../../components/transport/VoorraadTypeInfoDialoog';
import ProductinfoDialoog from '../../../../../../../../../../../../components/product/ProductinfoDialoog';
import ReserveringProductComp from '../../../../../../../../../../../../components/kolomveld/transportopdrachtregel/ReserveringProductComp';
import NieuwOfGebruiktComp from '../../../../../../../../../../../../components/kolomveld/transportopdrachtregel/NieuwOfGebruiktComp';
import AfmeldComp from '../../../../../../../../../../../../components/kolomveld/transportopdrachtregel/AfmeldComp';
import ProductComp from '../../../../../../../../../../../../components/kolomveld/transportopdrachtregel/ProductComp';
import UitlegTooltip from '../../../../../../../../../../../../components/formulier/UitlegTooltip';
import { genereerUrlStateQueryParam } from '../../../../../../../../../../../../core/useUrlState';
import {
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
  DXTableToggleCellComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../../../../../../../../../helpers/dxTableGrid';
import api from '../../../../../../../../../../../../api';
import {
  IconInformatie,
  IconKruis,
  IconVlag,
  IconZoeken,
} from '../../../../../../../../../../../../components/Icons';
import { Kleur } from '../../../../../../../../../../../../bedrijfslogica/constanten';
import { IContract } from '../../../../../../../../../../../../../../shared/src/api/v2/relatie/contract';

enum EOpdrachtStatus {
  Planning = 1,
  Uitstaand = 2,
  Afgehandeld = 3,
}

const opdrachtstatusRouteMap: Record<EOpdrachtStatus, string> = {
  [EOpdrachtStatus.Planning]: 'planning',
  [EOpdrachtStatus.Uitstaand]: 'uitstaand',
  [EOpdrachtStatus.Afgehandeld]: 'afgehandeld',
};

interface IOpdrachtstatusStateBuilderParams {
  regelsSelectie?: number[];
  opdrachtenSelectie?: number[];
  uitgeklapteOpdrachten?: number[];
}

const opdrachstatusRouteStateBuilderMap: Record<
  EOpdrachtStatus,
  (params: IOpdrachtstatusStateBuilderParams) => string
> = {
  [EOpdrachtStatus.Planning]: (params) =>
    genereerUrlStateQueryParam<ITransportPlanningUrlState>({
      ...transportPlanningDefaultUrlState,
      ...params,
    }),
  [EOpdrachtStatus.Uitstaand]: (params) =>
    genereerUrlStateQueryParam<ITransportUitstaandUrlState>({
      ...transportUitstaandDefaultUrlState,
      ...params,
    }),
  [EOpdrachtStatus.Afgehandeld]: (params) =>
    genereerUrlStateQueryParam<ITransportAfgehandeldUrlState>({
      ...transportAfgehandeldDefaultUrlState,
      ...params,
    }),
};

enum ERegelstatus {
  NietAfgemeld,
  Uitgevoerd,
  Mislukt,
  Geannuleerd,
}

export interface IProductinfoDialoogState {
  id: number;
}

export interface IKoppelVoorraadProductDialoogState {
  trsRegID: number;
  cntID: number;
}

export interface IKoppelLocatieProductDialoogState {
  trsRegID: number;
  locID: number;
  relID: number;
}

export interface IWijzigenRegelDialoogState {
  trsRegID: number;
}

export interface IWijzigenOpdrachtDialoogState {
  trsOpdID: number;
  trsRegID?: number;
}

export interface IToevoegenOpdrachtregelDialoogState {
  cntID: number;
}

export interface IProductreserveringDialoogState {
  trsRegID: number;
}

export interface IVoorraadTypeInfoDialoogState {
  typeID: number;
  magID: number;
}

export interface ITabelContext {
  onVerversenAangevraagd: () => void;
}

export const TabelContext = React.createContext<ITabelContext>(null as any);

export interface IRegel extends IOphalenOpdrachtregelsResultElementV2 {
  opdracht: IOphalenOpdrachtenResultElementV2;
}

interface IProps extends RouteComponentProps {
  contract: IContract;
  regels: IRegel[];
  uitgeklapteRegels: number[];
  onUitgeklapteRegelsChange: (ids: number[]) => void;

  productreserveringDialoogState: IProductreserveringDialoogState | null;
  onProductreserveringDialoogStateChange: (state: IProductreserveringDialoogState | null) => void;

  koppelVoorraadProductDialoogState: IKoppelVoorraadProductDialoogState | null;
  onKoppelVoorraadProductDialoogStateStateChange: (
    state: IKoppelVoorraadProductDialoogState | null,
  ) => void;

  koppelLocatieProductDialoogState: IKoppelLocatieProductDialoogState | null;
  onKoppelLocatieProductDialoogStateChange: (
    state: IKoppelLocatieProductDialoogState | null,
  ) => void;

  toevoegenOpdrachtregelDialoogState: IToevoegenOpdrachtregelDialoogState | null;
  onToevoegenOpdrachtregelDialoogStateChange: (
    state: IToevoegenOpdrachtregelDialoogState | null,
  ) => void;
  wijzigenRegelDialoogState: IWijzigenRegelDialoogState | null;
  onWijzigenRegelDialoogStateChange: (state: IWijzigenRegelDialoogState | null) => void;
  wijzigenOpdrachtDialoogState: IWijzigenOpdrachtDialoogState | null;
  onWijzigenOpdrachtDialoogStateChange: (state: IWijzigenOpdrachtDialoogState | null) => void;

  onVerversenAangevraagd: () => void;
}

const geenData = { noData: 'Geen transportopdrachtregels' };

export interface IKoppelVoorraadDialoogState {
  trsRegID: number;
  cntID: number | null;
  magID: number | null;
}

export interface IKoppelLocatieproductDialoogState {
  trsRegID: number;
  locID: number | null;
  cntID: number | null;
}

const Tabel: React.FC<IProps> = (props) => {
  const { checkStore } = useContext(RootStoreContext);

  const [
    voorraadTypeInfoDialoogState,
    setVoorraadTypeInfoDialoogState,
  ] = useState<IVoorraadTypeInfoDialoogState | null>(null);

  const [
    koppelVoorraadDialoogState,
    setKoppelVoorraadDialoogState,
  ] = useState<IKoppelVoorraadDialoogState | null>(null);

  const [
    koppelLocatieproductDialoog,
    setKoppelLocatieproductDialoogState,
  ] = useState<IKoppelLocatieproductDialoogState | null>(null);

  const [
    productinfoDialoogState,
    setProductinfoDialoogState,
  ] = useState<IProductinfoDialoogState | null>(null);

  const kolommen = useMemo<TypedColumn<IRegel>[]>(
    () => [
      {
        name: 'Soort',
        title: 'Srt',
      },
      {
        name: '__product' as any,
        title: 'Merk/Type',
      },

      {
        name: '__productsoort' as any,
        title: 'Cat',
      },
      {
        name: '__nieuw' as any,
        title: (
          <UitlegTooltip inhoud={'N(ieuw) of G(ebruikt) product'}>
            <span>N/G</span>
          </UitlegTooltip>
        ) as any,
      },
      {
        name: '__referentie' as any,
        title: 'Ref',
      },
      {
        name: '__voorraadReservering' as any,
        title: 'Res.',
      },
      {
        name: '__voorraadInfo' as any,
        title: ' ',
      },
      // {
      //   name: '__bezoekdatum' as any,
      //   title: 'Bezoekdatum',
      // },
      {
        name: '__afmeldenOpdracht' as any,
        title: 'Afmeldstatus',
      },
      {
        name: '__statusdatum' as any,
        title: 'Datum',
      },
      {
        name: '__opdrachtstatus' as any,
        title: ' ',
      },
    ],
    [],
  );
  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRegel>[]>(
    () => [
      {
        columnName: 'Soort',
        width: 60,
      },
      {
        columnName: '__productsoort' as any,
        width: 50,
      },
      {
        columnName: '__product' as any,
        width: 225,
      },
      {
        columnName: '__nieuw' as any,
        width: 50,
      },
      {
        columnName: '__referentie' as any,
        width: 80,
      },
      {
        columnName: '__voorraadReservering' as any,
        width: 50,
      },
      {
        columnName: '__voorraadInfo' as any,
        width: 50,
      },
      // {
      //   columnName: '__bezoekdatum' as any,
      //   width: 115,
      // },
      {
        columnName: '__afmeldenOpdracht' as any,
        width: 100,
      },
      {
        columnName: '__statusdatum' as any,
        width: 115,
      },
      {
        columnName: '__opdrachtstatus' as any,
        width: 115,
      },
    ],
    [],
  );
  const keyExtractor = useCallback((x: IRegel) => x.TrsRegID, []);

  const tabelContext = useMemo<ITabelContext>(() => {
    return {
      onVerversenAangevraagd: props.onVerversenAangevraagd,
    };
  }, [props.onVerversenAangevraagd]);

  return (
    <TabelContext.Provider value={tabelContext}>
      <GridStyleWrapper
        rowAmount={props.regels.length}
        maxHeight={300}
        extraHeight={100 * props.uitgeklapteRegels.length}
      >
        <Grid rows={props.regels} columns={kolommen} getRowId={keyExtractor}>
          <DataTypeProvider
            for={['Soort']}
            formatterComponent={(formatterProps) => {
              const rij: IRegel = formatterProps.row;
              return (
                <>
                  <span hidden>TrsRegID: {rij.TrsRegID}</span>
                  <span>
                    {rij.OmruilID !== null
                      ? rij.regelsoort.NaamEnum === ETransportopdrachtRegelsoort.Levering
                        ? 'L/O'
                        : 'R/O'
                      : rij.regelsoort.NaamEnum === ETransportopdrachtRegelsoort.Levering
                      ? 'Lev'
                      : 'Ret'}
                  </span>
                  {/* <span>{rij.indicaties.isVermoedelijkOmruil ? ' (/O?)' : ''}</span> */}
                </>
              );
            }}
          />

          <DataTypeProvider
            for={['__product']}
            formatterComponent={(formatterProps) => {
              const rij: IRegel = formatterProps.row;

              const cntID = rij.contract !== null ? rij.contract.CntID : null;

              return (
                <ProductComp
                  rij={rij}
                  magID={rij.opdracht.magazijn.MagID}
                  setKoppelLocatieproductDialoogState={() => {
                    setKoppelLocatieproductDialoogState({
                      trsRegID: rij.TrsRegID,
                      locID: rij.contract !== null ? rij.contract.basis.locatie.LocID : null,
                      cntID,
                    });
                  }}
                  setKoppelVoorraadDialoogState={() => {
                    setKoppelVoorraadDialoogState({
                      trsRegID: rij.TrsRegID,
                      cntID,
                      magID: rij.opdracht.magazijn.MagID,
                    });
                  }}
                  onVerversen={props.onVerversenAangevraagd}
                />
              );
            }}
          />

          <DataTypeProvider
            for={['__product1']}
            formatterComponent={(formatterProps) => {
              const rij: IRegel = formatterProps.row;

              // Tekst die aangeeft welk product gekoppeld is/moet worden
              // Bij geen koppeling en Levering wordt het primaire type weergegeven

              let koppeltekst = <span>Nog Product/Type koppelen</span>;

              if (rij.product !== null) {
                // Product
                koppeltekst = (
                  <span>
                    {rij.product.producttype.Merknaam + ' ' + rij.product.producttype.Typenaam}
                  </span>
                );
              } else {
                if (rij.type !== null) {
                  // Type (bulk en geen bulk)
                  koppeltekst = (
                    <span className="d-flex align-items-center">
                      {(rij.type.Merkloos ? '' : rij.type.Merknaam + ' ') + rij.type.Typenaam}{' '}
                    </span>
                  );
                } else {
                  if (rij.contract !== null) {
                    if (rij.regelsoort.NaamEnum === 'LEVERING') {
                      const primairType = rij.contract.basis.productmodel.producttypen[0];
                      koppeltekst = (
                        <span>
                          [{primairType.Merknaam} {primairType.Typenaam}]
                        </span>
                      );
                    } else {
                      koppeltekst = <span></span>;
                    }
                  } else {
                    koppeltekst = <span></span>;
                  }
                }
              }

              const cntID = rij.contract !== null ? rij.contract.CntID : null;

              return (
                <div className="d-flex">
                  <a
                    href="#"
                    // style={{ color: Kleur.DonkerGrijs }}
                    onClick={() => {
                      // Levering of Retour
                      switch (rij.regelsoort.NaamEnum) {
                        case ETransportopdrachtRegelsoort.Retour:
                          setKoppelLocatieproductDialoogState({
                            trsRegID: rij.TrsRegID,
                            locID: rij.contract !== null ? rij.contract.basis.locatie.LocID : null,
                            cntID,
                          });
                          break;
                        case ETransportopdrachtRegelsoort.Levering:
                          setKoppelVoorraadDialoogState({
                            trsRegID: rij.TrsRegID,
                            cntID,
                            magID: rij.opdracht.magazijn.MagID,
                          });
                          break;
                      }
                    }}
                  >
                    <span>{koppeltekst}</span>
                  </a>
                  {(rij.type !== null || rij.product !== null) && (
                    <IconButton
                      className="hover-only ml-2"
                      onClick={async (ev) => {
                        ev.stopPropagation();
                        if (
                          (
                            await checkStore.bevestigen({
                              inhoud: 'Product ontkoppelen?',
                            })
                          ).type === EResultType.Annuleren
                        ) {
                          return;
                        }
                        await api.v2.transport.reservering.levering.verwijderenProductKoppeling({
                          trsRegIDs: [rij.TrsRegID],
                        });
                        props.onVerversenAangevraagd();
                      }}
                    >
                      {' '}
                      <IconKruis
                        style={{
                          width: 18,
                          height: 18,
                          position: 'relative',
                          bottom: 1,
                        }}
                      />
                    </IconButton>
                  )}
                </div>
              );
            }}
          />

          <DataTypeProvider
            for={['__voorraad']}
            formatterComponent={(formatterProps) => {
              const rij: IRegel = formatterProps.row;
              if (!(rij.type !== null && !rij.type.Bulkproduct)) {
                return <span></span>;
              }

              const gereserveerd = rij.type.Gereserveerd !== null && rij.type.Gereserveerd;
              // const voorraad = voorraadInfo!.aantalVrij < 0;

              return (
                <div className="d-flex align-items-center mt-1">
                  <a
                    href="#"
                    onClick={() => {
                      // setVoorraadDialoogState({ id: rij.TrsRegID });
                    }}
                  >
                    <VoorraadIndicatie
                      status={gereserveerd ? EVoorraadStatus.Groen : EVoorraadStatus.Rood}
                    />
                  </a>
                </div>
              );
            }}
          />
          <DataTypeProvider
            for={['__productsoort']}
            formatterComponent={(formatterProps) => {
              const rij: IRegel = formatterProps.row;
              const product =
                rij.type !== null ? (
                  <span>{rij.type.ProductsoortnaamKort}</span>
                ) : rij.product !== null ? (
                  <span>{rij.product.producttype.ProductsoortnaamKort}</span>
                ) : null;
              return <span>{product}</span>;
            }}
          />

          <DataTypeProvider
            for={['__nieuw']}
            formatterComponent={(formatterProps) => {
              const rij: IRegel = formatterProps.row;
              return <NieuwOfGebruiktComp rij={rij} />;
            }}
          />

          <DataTypeProvider
            for={['__referentie']}
            formatterComponent={(formatterProps) => {
              const rij: IRegel = formatterProps.row;
              return <ReferentieProductComp rij={rij} />;
            }}
          />
          <DataTypeProvider
            for={['__voorraadReservering']}
            formatterComponent={(formatterProps) => {
              const rij: IRegel = formatterProps.row;

              return (
                <ReserveringProductComp
                  rij={formatterProps.row}
                  magID={rij.opdracht.magazijn.MagID}
                  onVernieuwen={props.onVerversenAangevraagd}
                />
              );
            }}
          />

          <DataTypeProvider
            for={['__afmeldenOpdracht']}
            formatterComponent={(formatterProps) => {
              const rij: IRegel = formatterProps.row;
              return <AfmeldComp rij={rij} onVernieuwen={props.onVerversenAangevraagd} />;
            }}
          />

          <DataTypeProvider
            for={['__koppelActie']}
            formatterComponent={(formatterProps) => {
              const row: IRegel = formatterProps.row;
              if (row.opdracht.opdrachtstatus.Status !== EOpdrachtstatusTransport.Planning) {
                return <span></span>;
              }
              return row.regelsoort.NaamEnum === ETransportopdrachtRegelsoort.Levering ? (
                <a
                  href="#"
                  onClick={() => {
                    props.onKoppelVoorraadProductDialoogStateStateChange({
                      trsRegID: row.TrsRegID,
                      cntID: row.contract!.CntID,
                    });
                  }}
                >
                  <span
                    style={{
                      color: row.type === null && row.product === null ? Kleur.Blauw : Kleur.Grijs,
                    }}
                  >
                    {/* {row.type !== null || row.product !== null ? 'Wijzigen' : 'Reserveren'} */}
                    <IconZoeken style={{ width: 20, height: 20 }} />
                  </span>
                </a>
              ) : (
                <a
                  href="#"
                  onClick={() =>
                    props.onKoppelLocatieProductDialoogStateChange({
                      trsRegID: row.TrsRegID,
                      locID: row.opdracht.locatie.LocID,
                      relID: row.RelID!,
                    })
                  }
                >
                  <span style={{ color: row.product !== null ? Kleur.Grijs : Kleur.Blauw }}>
                    {/* {row.product !== null ? 'Wijzigen' : 'Koppelen'} */}
                    <IconZoeken style={{ width: 20, height: 20 }} />
                  </span>
                </a>
              );
            }}
          />
          <DataTypeProvider
            for={['__voorraadInfo']}
            formatterComponent={(formatterProps) => {
              const rij: IRegel = formatterProps.row;

              if (rij.type !== null) {
                if (rij.type.Bulkproduct) {
                  // Bulkproduct
                  return <span></span>;
                }
                // Type
                return (
                  <div className="d-flex">
                    <a
                      href="#"
                      onClick={() => {
                        setVoorraadTypeInfoDialoogState({
                          typeID: rij.type!.TypeID,
                          magID: rij.opdracht.magazijn.MagID,
                        });
                      }}
                    >
                      <IconInformatie style={{ width: 15, height: 15, fill: Kleur.Blauw }} />
                    </a>
                  </div>
                );
              }

              if (rij.product !== null) {
                // Product
                return (
                  <a
                    href="#"
                    // style={{ color: Kleur.DonkerGrijs }}
                    onClick={() => {
                      setProductinfoDialoogState({
                        id: rij.product!.ProdID!,
                      });
                    }}
                  >
                    <span>Info</span>
                  </a>
                );
              }

              return <span></span>;
            }}
          />

          <DataTypeProvider
            for={['__bezoekdatum']}
            formatterComponent={(formatterProps) => {
              const rij: IRegel = formatterProps.row;
              const datum = rij.opdracht.Bezoekdatum;

              if (datum === null) {
                return <span></span>;
              }

              return <span>{format(new Date(datum), 'dd-MM-yyyy')}</span>;
            }}
          />

          <DataTypeProvider
            for={['__statusdatum']}
            formatterComponent={(formatterProps) => {
              const rij: IRegel = formatterProps.row;

              // Afhankelijk van de afmeldstatus
              const bezoekdatum = rij.opdracht.Bezoekdatum;

              if (rij.status.Status === 0) {
                return bezoekdatum !== null ? (
                  <span>({format(new Date(bezoekdatum), 'dd-MM-yyyy')})</span>
                ) : (
                  <span>(Nog opgeven)</span>
                );
              }

              const statusdatum = rij.status.Statusdatum as Date;
              return <span>{format(new Date(statusdatum), 'dd-MM-yyyy')}</span>;
            }}
          />

          <DataTypeProvider
            for={['__opdrachtstatus']}
            formatterComponent={(formatterProps) => {
              const rij: IRegel = formatterProps.row;
              return (
                <div className="d-flex">
                  <TransportopdrachtregelVisualisatie trsRegID={rij.TrsRegID} />
                  {rij.opdracht.DatumAfgehandeld !== null && (
                    <span className="ml-2">
                      <IconVlag
                        style={{
                          fill: Kleur.LichtGrijs,
                          width: 16,
                          height: 16,
                          position: 'relative',
                          bottom: 3,
                        }}
                      />
                    </span>
                  )}
                </div>
              );
            }}
          />

          <DataTypeProvider
            for={['__acties']}
            formatterComponent={(formatterProps) => {
              const row: IOphalenOpdrachtregelsResultElementV2 = formatterProps.row;
              const relID = row.RelID!;
              // const opdrachtstatusRoute = opdrachtstatusRouteMap[row.opdracht.Status];
              // const opdrachtstatusRouteState = opdrachstatusRouteStateBuilderMap[
              //   row.opdracht.Status
              // ]({
              //   regelsSelectie: [row.TrsRegID],
              //   opdrachtenSelectie: [row.TrsOpdID],
              //   uitgeklapteOpdrachten: [row.TrsOpdID],
              // });

              return (
                <div
                  onClick={() => {
                    const urlState: ITransportOpdrachtUrlState = {
                      ...transportOpdrachtenDefaultUrlState,
                      uitgeklapteOpdrachten: [row.TrsOpdID],
                      opdrachtenSelectie: [row.TrsOpdID],
                      opdrachtenStates: {
                        [row.TrsOpdID]: {
                          ...defaultOpdrachtState,
                          regelsSelectie: [row.TrsRegID],
                        },
                      },
                      filterData: [
                        ...transportOpdrachtenDefaultUrlState.filterData.filter(
                          (x) => x.naam !== ETransportOpdrachtFilter.OpdrachtStatus,
                        ),
                        {
                          naam: ETransportOpdrachtFilter.OpdrachtStatus,
                          data: [1, 2],
                          isActief: false,
                        },
                      ],
                    };
                    const query = genereerUrlStateQueryParam(urlState);
                    props.history.push(`/klant/${relID}/transport/opdracht?state=${query}`);
                  }}
                >
                  {/* <IconOpenInNew
                    style={{
                      fill: Kleur.Grijs,
                      width: 16,
                      height: 16,
                      position: 'relative',
                      bottom: 3,
                      cursor: 'pointer',
                    }}
                  /> */}
                  <TransportopdrachtregelVisualisatie trsRegID={row.TrsRegID} />
                </div>
              );
            }}
          />
          <EditingState
            onCommitChanges={(changes) => {
              if (changes.deleted === undefined) {
                return;
              }
              const deleted = changes.deleted;
              const id = deleted[deleted.length - 1];
            }}
            onEditingRowIdsChange={(rowIds) => {
              const id = rowIds[rowIds.length - 1] as number;

              const regel = props.regels.find((x) => x.TrsRegID === id)!;
              props.onWijzigenOpdrachtDialoogStateChange({
                trsOpdID: regel.TrsOpdID,
                trsRegID: regel.TrsRegID,
              });
            }}
            onAddedRowsChange={() => {
              props.onToevoegenOpdrachtregelDialoogStateChange({ cntID: props.contract.CntID });
            }}
          />
          <RowDetailState
            expandedRowIds={props.uitgeklapteRegels}
            onExpandedRowIdsChange={(ids) => props.onUitgeklapteRegelsChange(ids as number[])}
          />
          <VirtualTable messages={geenData} />
          <TableColumnResizing defaultColumnWidths={kolomBreedtes as any} />
          <TableEditColumn
            width={35}
            showEditCommand
            showAddCommand={true}
            cellComponent={DXTableEditColumnCellComponent}
            commandComponent={DXTableEditColumnCommandComponent}
          />
          <TableRowDetail
            contentComponent={RowDetail}
            toggleCellComponent={DXTableToggleCellComponent}
          />
          <TableHeaderRow />
        </Grid>
      </GridStyleWrapper>

      {props.productreserveringDialoogState !== null && (
        <ProductreserveringDialoog
          open
          trsRegID={props.productreserveringDialoogState.trsRegID}
          onSuccess={() => {
            props.onProductreserveringDialoogStateChange(null);
            props.onVerversenAangevraagd();
          }}
          onAnnuleren={() => props.onProductreserveringDialoogStateChange(null)}
        />
      )}

      {props.koppelVoorraadProductDialoogState !== null && (
        <KoppelVoorraadProductDialoog
          open
          onSuccess={async (data) => {
            props.onKoppelVoorraadProductDialoogStateStateChange(null);
            await api.v2.transport.reservering.levering.koppelenProduct({
              trsRegID: props.koppelVoorraadProductDialoogState!.trsRegID,
              soortReservering: data.tabblad === ETabblad.Ingepakt ? 'T' : 'P',
              typeID: data.typeID,
              prodID: data.prodID,
            });
            props.onVerversenAangevraagd();
          }}
          onAnnuleren={() => props.onKoppelVoorraadProductDialoogStateStateChange(null)}
          cntID={props.koppelVoorraadProductDialoogState.cntID}
        />
      )}
      {props.koppelLocatieProductDialoogState !== null && (
        <KoppelLocatieProductDialoog
          open
          onSuccess={async (prodID) => {
            await api.v2.transport.reservering.retour.reserverenProduct({
              trsRegID: props.koppelLocatieProductDialoogState!.trsRegID,
              prodID,
            });
            props.onKoppelLocatieProductDialoogStateChange(null);
            props.onVerversenAangevraagd();
          }}
          onAnnuleren={() => props.onKoppelLocatieProductDialoogStateChange(null)}
          locID={props.koppelLocatieProductDialoogState.locID}
          relID={props.koppelLocatieProductDialoogState.relID}
        />
      )}
      {props.wijzigenRegelDialoogState !== null && (
        <WijzigenRegelDialoog
          open
          id={props.wijzigenRegelDialoogState.trsRegID}
          onSuccess={() => {
            props.onWijzigenRegelDialoogStateChange(null);
            props.onVerversenAangevraagd();
          }}
          onAnnuleren={() => props.onWijzigenRegelDialoogStateChange(null)}
        />
      )}
      {props.wijzigenOpdrachtDialoogState !== null && (
        <WijzigenOpdrachtDialoog
          open
          onSuccess={() => {
            props.onWijzigenOpdrachtDialoogStateChange(null);
            props.onVerversenAangevraagd();
          }}
          onAnnuleren={() => props.onWijzigenOpdrachtDialoogStateChange(null)}
          trsOpdID={props.wijzigenOpdrachtDialoogState.trsOpdID}
          trsRegID={props.wijzigenOpdrachtDialoogState.trsRegID}
          contextRelID={props.contract.RelID}
        />
      )}
      {props.toevoegenOpdrachtregelDialoogState !== null && (
        <NieuweTransportopdrachtDialoog
          open
          relID={props.contract.RelID}
          contractID={props.contract.CntID}
          // soortOpdracht={2}
          leverProduct={null}
          onSuccess={() => {
            {
              props.onToevoegenOpdrachtregelDialoogStateChange(null);
              props.onVerversenAangevraagd();
            }
          }}
          onAnnuleren={() => props.onToevoegenOpdrachtregelDialoogStateChange(null)}
        />

        // <ToevoegenOpdrachtregelDialoog
        //   open
        //   cntID={props.toevoegenOpdrachtregelDialoogState.cntID}
        //   locID={12803} // TODO
        //   onSuccess={() => {
        //     props.onToevoegenOpdrachtregelDialoogStateChange(null);
        //     props.onVerversenAangevraagd();
        //   }}
        //   onAnnuleren={() => props.onToevoegenOpdrachtregelDialoogStateChange(null)}
        // />
      )}
      {productinfoDialoogState !== null && (
        <ProductinfoDialoog
          open
          id={productinfoDialoogState.id}
          onSuccess={() => {
            setProductinfoDialoogState(null);
            props.onVerversenAangevraagd();
          }}
          onAnnuleren={() => setProductinfoDialoogState(null)}
        />
      )}
      {voorraadTypeInfoDialoogState !== null && (
        <VoorraadTypeInfoDialoog
          open
          typeID={voorraadTypeInfoDialoogState.typeID}
          magID={voorraadTypeInfoDialoogState.magID}
          onSuccess={() => setVoorraadTypeInfoDialoogState(null)}
          onAnnuleren={() => setVoorraadTypeInfoDialoogState(null)}
        />
      )}
      {koppelVoorraadDialoogState && (
        <KoppelVoorraadDialoog
          open
          cntID={
            koppelVoorraadDialoogState.cntID !== null ? koppelVoorraadDialoogState.cntID : undefined
          }
          onSuccess={async (result) => {
            let type = null;
            let product = null;

            switch (result.tabblad) {
              case ETabblad.Ingepakt:
                // Bepaal of er vrije voorraad is
                const voorraad = (
                  await api.v2.voorraad.ophalenVoorraadinfo({
                    typeIDs: [result.typeID!],
                    magID: koppelVoorraadDialoogState.magID || undefined,
                  })
                ).voorraad[0];

                type = {
                  typeID: result.typeID!,
                  bulkproduct: false,
                  gereserveerd: voorraad.aantalVrij > 0 ? true : false,
                };
                break;
              case ETabblad.Uitgepakt:
                product = {
                  prodID: result.prodID!,
                };
                break;
              case ETabblad.Bulk:
                type = {
                  typeID: result.bulkTypeID!,
                  bulkproduct: true,
                };
                break;
            }

            const params = {
              trsRegID: koppelVoorraadDialoogState.trsRegID,
              type,
              product,
            };

            await api.v2.transport.opdracht.koppelenProductVoorTransportregel(params);

            props.onVerversenAangevraagd();

            setKoppelVoorraadDialoogState(null);
          }}
          onAnnuleren={() => setKoppelVoorraadDialoogState(null)}
        />
      )}
      {koppelLocatieproductDialoog !== null && (
        <KoppelLocatieProductDialoog
          open
          locID={
            koppelLocatieproductDialoog.locID !== null
              ? koppelLocatieproductDialoog.locID
              : undefined
          }
          cntID={
            koppelLocatieproductDialoog.cntID !== null
              ? koppelLocatieproductDialoog.cntID
              : undefined
          }
          onSuccess={async (prodID) => {
            const params = {
              trsRegID: koppelLocatieproductDialoog.trsRegID,
              type: null,
              product: { prodID },
            };
            await api.v2.transport.opdracht.koppelenProductVoorTransportregel(params);
            setKoppelLocatieproductDialoogState(null);
            props.onVerversenAangevraagd();
          }}
          onAnnuleren={() => setKoppelLocatieproductDialoogState(null)}
        />
      )}
    </TabelContext.Provider>
  );
};

export default withRouter(Tabel);
