import React, { useCallback, useMemo } from 'react';
import MultiComboboxV2MetZoeken, {
  IMultiComboboxV2MetZoekenOverlayState,
} from '../../../../../components/formulier/MultiComboboxV2/MetZoeken';
import {
  EnkeleProvider,
  IRepresentatieProps,
  Provider,
} from '../../../../../components/formulier/MultiComboboxV2';
import { IConcurrentV2 } from '../../../../../../../shared/src/api/v2/concurrent';
import api from '../../../../../api';
import { IFilterSchemaFilter } from '../../../../../../../shared/src/models/filter';
import { ITekstPrecisieData } from '../../../../../../../shared/src/api/sql';
import { ASPKolom, EAspKolomBreedteType } from '../../../../../components/tabel/ASPTabel/types';

type Kolom = 'naam';

interface IProps {
  concurrentID: number | null;
  onConcurrentIDChange: (concurrentID: number | null) => void;
}

const ConcurrentSelectie = (props: IProps) => {
  const provider = useMemo<Provider<Kolom, IConcurrentV2, IMultiComboboxV2MetZoekenOverlayState>>(
    () => async (params) => {
      const filters: IFilterSchemaFilter[] = [];

      const trimmedZoekterm = params.overlayContainerState.zoekterm.trim();
      if (trimmedZoekterm.length > 0) {
        filters.push({
          naam: 'NAAM_TEKST_PRECISIE',
          data: {
            precisie: 'ONGEVEER',
            waarde: trimmedZoekterm,
          } as ITekstPrecisieData,
        });
      }

      const result = await api.v2.concurrentie.ophalenConcurrentenV2({
        filterSchema: {
          filters,
        },
        paginatie: params.paginatie,
      });

      const items = result.concurrenten.reduce<{ [index: number]: IConcurrentV2 }>(
        (acc, curr, i) => {
          acc[params.paginatie.index + i] = curr;
          return acc;
        },
        {},
      );

      return {
        items,
        totaalAantal: result.totaalAantal,
      };
    },
    [],
  );

  const enkeleProvider = useMemo<EnkeleProvider<number, IConcurrentV2>>(
    () => async (id) => {
      const result = await api.v2.concurrentie.ophalenConcurrentenV2({
        filterSchema: {
          filters: [
            {
              naam: 'IDS',
              data: [id],
            },
          ],
        },
      });

      return result.concurrenten[0];
    },
    [],
  );

  const keyExtractor = useCallback((concurrent: IConcurrentV2) => concurrent.ID, []);

  const kolommen = useMemo<ASPKolom<Kolom, IConcurrentV2>[]>(
    () => [
      {
        key: 'naam',
        label: 'Naam',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 350,
        renderer: (concurrent) => concurrent.Naam,
      },
    ],
    [],
  );

  return (
    <MultiComboboxV2MetZoeken
      provider={provider}
      enkeleProvider={enkeleProvider}
      keyExtractor={keyExtractor}
      waarde={props.concurrentID}
      onChange={props.onConcurrentIDChange}
      representatieComponent={Representatie}
      kolommen={kolommen}
    />
  );
};

const Representatie = (props: IRepresentatieProps<IConcurrentV2>) => {
  return <span>{props.entiteit.Naam}</span>;
};

export default ConcurrentSelectie;
