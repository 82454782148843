import React, { useState, useEffect, useCallback, useMemo, useContext } from 'react';
import IDialoogProps from '../../../../../core/IDialoogProps';
import Dialoog from '../../../../../components/dialogen/Dialoog';
import { ModalFooter, ModalTitle, ModalBody } from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/ModalHeader';
import { Formik, Field, FieldProps, FormikActions, FormikProps, FormikErrors } from 'formik';
import api from '../../../../../api';
import LoadingSpinner from '../../../../../components/Gedeeld/LoadingSpinner';
import nameOf from '../../../../../core/nameOf';
import FormikVeldFout from '../../../../../components/formulier/FormikVeldFout';
import VinkVeld from '../../../../../components/formulier/VinkVeld';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../../../stores/RootStore';
import { EResultType } from '../../../../../stores/CheckStore';
import MeertaligTekstveld from '../../../../../components/formulier/MeertaligTekstveld';
import ITaalTekst from '../../../../../../../shared/src/models/talen/ITaalTekst';
import { IOphalenTekstenResultElement } from '../../../../../../../shared/src/api/v2/tekst';
import {
  IOphalenOverigeTarievenResultElement,
  IWijzigenTariefParams,
} from '../../../../../../../shared/src/api/v2/aanbod/tarieven/overige';
import BedragInput from '../../../../../components/formulier/BedragInput';
import * as Yup from 'yup';

interface IProps extends IDialoogProps {
  id: number;
}

interface IFormikValues {
  naam: string;
  naamTaal: ITaalTekst[];
  omschrijvingTaal: ITaalTekst[];
  naamEnum: string;
  betreftPercentage: boolean;
  bedrag: number | '';
  percentage: number | '';
  openbaar: boolean;
}

const WijzigenDialoog: React.FC<IProps> = observer((props) => {
  const { checkStore } = useContext(RootStoreContext);
  const [tarief, setTarief] = useState<IOphalenOverigeTarievenResultElement | null>(null);
  const [teksten, setTeksten] = useState<IOphalenTekstenResultElement[] | null>(null);

  const ophalenTarief = useCallback(async () => {
    const tariefResult = await api.v2.aanbod.tarieven.overige.ophalenOverigeTarieven({
      filterSchema: { filters: [{ naam: 'IDS', data: [props.id] }] },
    });
    const tarief = tariefResult[0];

    setTarief(tarief);

    const tekstIDs = [
      ...(tarief.Naam_TekstID !== null ? [tarief.Naam_TekstID] : []),
      ...(tarief.Omschrijving_TekstID !== null ? [tarief.Omschrijving_TekstID] : []),
    ];

    if (tekstIDs.length === 0) {
      setTeksten([]);
      return;
    }

    const tekstenResult = await api.v2.tekst.ophalenTekstenInAlleTalen({
      tekstIDs,
    });

    setTeksten(tekstenResult.teksten);
  }, [props.id]);

  const initialValues: IFormikValues | null = useMemo(() => {
    if (tarief === null || teksten === null) {
      return null;
    }

    return {
      naam: tarief.Naam || '',
      naamEnum: tarief.NaamEnum || '',
      naamTaal: teksten
        .filter((x) => x.TekstID === tarief.Naam_TekstID)
        .map((x) => ({
          tekst: x.Tekst || '',
          taalID: x.TaalID,
          toepassen: x.Toepassen,
        })),
      omschrijvingTaal: teksten
        .filter((x) => x.TekstID === tarief.Omschrijving_TekstID)
        .map((x) => ({
          tekst: x.Tekst || '',
          taalID: x.TaalID,
          toepassen: x.Toepassen,
        })),
      betreftPercentage: tarief.Bedrag !== null ? false : true,
      bedrag: tarief.Bedrag || '',
      percentage: tarief.Percentage || '',
      openbaar: tarief.Openbaar,
    };
  }, [tarief, teksten]);

  useEffect(() => {
    ophalenTarief();
  }, [props.id]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      const params: IWijzigenTariefParams = {
        id: props.id,
        naam: values.naam,
        naamEnum: values.naamEnum,
        naamTaal: values.naamTaal,
        omschrijvingTaal: values.omschrijvingTaal,
        bedrag: values.betreftPercentage ? null : values.bedrag === '' ? 0 : values.bedrag,
        betreftPercentage: values.betreftPercentage,
        percentage: values.betreftPercentage
          ? values.percentage === ''
            ? 0
            : values.percentage
          : null,
        openbaar: values.openbaar,
      };

      const checkData = await api.v2.aanbod.tarieven.overige.checkWijzigenTarief(params);
      if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
        actions.setSubmitting(false);
        return;
      }

      await api.v2.aanbod.tarieven.overige.wijzigentarief(params);

      props.onSuccess(null);
      actions.setSubmitting(false);
    },
    [props.onSuccess],
  );

  // const validate = useCallback((values: IFormikValues) => {
  //   const schema = Yup.object().shape({
  //     naam: Yup.string().required('Naam is verplicht'),
  //     // percentage: Yup.number(),
  //   });
  //   const errors = schema.validateSync(values);
  //   if (Object.keys(errors).length > 0) {
  //     return errors;
  //   }

  //   if (Object.keys(errors).length > 0) {
  //     return errors;
  //   }

  //   // if (values.betreftPercentage && values.percentage > 100) {
  //   //   errors.percentage = 'Percentage mag niet hoger zijn dan 100';
  //   // }

  //   return errors;
  // }, []);

  return (
    <Dialoog index={props.dialoogIndex || 0} modalProps={{ size: 'lg' }}>
      <ModalHeader>
        <ModalTitle>Wijzigen tarief</ModalTitle>
      </ModalHeader>

      {initialValues === null || tarief === null ? (
        <LoadingSpinner />
      ) : (
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialValues}
          // validate={validate}
          render={(formikProps: FormikProps<IFormikValues>) => {
            const { submitForm, isSubmitting, values } = formikProps;
            return (
              <>
                <ModalBody>
                  <div className="">
                    <div className="row">
                      <div className="col-12">
                        <label>Naam</label>
                        <Field
                          name={nameOf<IFormikValues>('naam')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field } = fieldProps;
                            return (
                              <>
                                <input type="text" {...field} className="form-control" />
                                <FormikVeldFout fieldProps={fieldProps} />
                              </>
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <label>NaamEnum</label>
                        <Field
                          name={nameOf<IFormikValues>('naamEnum')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { form, field } = fieldProps;
                            return (
                              <>
                                <input
                                  type="text"
                                  {...field}
                                  className="form-control"
                                  onChange={(ev) =>
                                    form.setFieldValue(field.name, ev.target.value.toUpperCase())
                                  }
                                />
                                <FormikVeldFout fieldProps={fieldProps} />
                              </>
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <label>Naam</label>
                        <Field
                          name={nameOf<IFormikValues>('naamTaal')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <>
                                <MeertaligTekstveld
                                  waarden={field.value}
                                  onChange={(x) => form.setFieldValue(field.name, x)}
                                />
                                <FormikVeldFout fieldProps={fieldProps} />
                              </>
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <label>Omschrijving</label>
                        <Field
                          name={nameOf<IFormikValues>('omschrijvingTaal')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <>
                                <MeertaligTekstveld
                                  waarden={field.value}
                                  onChange={(x) => form.setFieldValue(field.name, x)}
                                />
                                <FormikVeldFout fieldProps={fieldProps} />
                              </>
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <Field
                          name={nameOf<IFormikValues>('betreftPercentage')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <>
                                <span className="d-flex align-items-center">
                                  <VinkVeld
                                    aangevinkt={field.value}
                                    onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                  />
                                  <span className="ml-2">betreftPercentage</span>
                                </span>
                                <FormikVeldFout fieldProps={fieldProps} />
                              </>
                            );
                          }}
                        />
                      </div>

                      {!values.betreftPercentage && (
                        <div className="col-6 mt-3">
                          <label>Bedrag</label>
                          <Field
                            name={nameOf<IFormikValues>('bedrag')}
                            render={(x: FieldProps<IFormikValues>) => {
                              return (
                                <div>
                                  <BedragInput
                                    value={x.field.value}
                                    onChange={(bedrag) => {
                                      x.form.setFieldValue(x.field.name, bedrag);
                                    }}
                                    min={0}
                                  />
                                </div>
                              );
                            }}
                          />
                        </div>
                      )}

                      {values.betreftPercentage && (
                        <div className="col-6 mt-3">
                          <label>Percentage</label>
                          <Field
                            name={nameOf<IFormikValues>('percentage')}
                            render={(x: FieldProps<IFormikValues>) => (
                              <>
                                <input
                                  type="number"
                                  className="form-control"
                                  {...x.field}
                                  min={0}
                                />
                                <FormikVeldFout fieldProps={x} />
                              </>
                            )}
                          />
                        </div>
                      )}

                      <div className="col-12 mt-3">
                        <Field
                          name={nameOf<IFormikValues>('openbaar')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <>
                                <span className="d-flex align-items-center">
                                  <VinkVeld
                                    aangevinkt={field.value}
                                    onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                  />
                                  <span className="ml-2">Openbaar</span>
                                </span>
                                <FormikVeldFout fieldProps={fieldProps} />
                              </>
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </ModalBody>
                <ModalFooter className="d-flex flex-row justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    style={{ width: 100 }}
                    disabled={isSubmitting}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={props.onAnnuleren}
                    style={{ width: 100 }}
                    disabled={isSubmitting}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Dialoog>
  );
});

export default WijzigenDialoog;
