import api from '../../../api';
import {
  IOphalenMemosParams,
  IOphalenMemosResult,
  IOphalenMemolabelsParams,
  IOphalenMemolabelsResult,
  IWijzigenMemoParams,
  IWijzigenMemoResult,
  IVerwijderenMemoResult,
  IVerwijderenMemoParams,
  IToevoegenMemoParams,
  IToevoegenMemoResult,
} from '../../../../../shared/src/api/v2/memo';

const memo = {
  ophalenMemos: async (params: IOphalenMemosParams): Promise<IOphalenMemosResult> => {
    return await api.post('/v2/memo/ophalen-memos', params);
  },
  ophalenMemoLabels: async (
    params: IOphalenMemolabelsParams,
  ): Promise<IOphalenMemolabelsResult> => {
    return await api.post('/v2/memo/ophalen-memo-labels', params);
  },
  wijzigenMemo: async (params: IWijzigenMemoParams): Promise<IWijzigenMemoResult> => {
    return await api.post('/v2/memo/wijzigen-memo', params);
  },
  verwijderenMemo: async (params: IVerwijderenMemoParams): Promise<IVerwijderenMemoResult> => {
    return await api.post('/v2/memo/verwijderen-memo', params);
  },
  toevoegenMemo: async (params: IToevoegenMemoParams): Promise<IToevoegenMemoResult> => {
    return await api.post('/v2/memo/toevoegen-memo', params);
  },
};

export default memo;
