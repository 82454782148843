import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import api from '../../../../../../../../api';
import { Field, FieldProps, Formik, FormikProps } from 'formik';
import IDialoogProps from '../../../../../../../../core/IDialoogProps';
import {
  IOphalenDossierStatussenResultElement,
  IOphalenResultaatStatussenResultElement,
} from '../../../../../../../../../../shared/src/api/v2/debiteur/incassozaak';
import { RootStoreContext } from '../../../../../../../../stores/RootStore';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import nameOf from '../../../../../../../../core/nameOf';
import VinkVeld from '../../../../../../../../components/formulier/VinkVeld';
import LoadingSpinner from '../../../../../../../../components/Gedeeld/LoadingSpinner';
import Dialoog from '../../../../../../../../components/dialogen/Dialoog';
import Combobox from '../../../../../../../../components/formulier/Combobox';
import DatumKiezer from '../../../../../../../../components/formulier/DatumKiezer';
import FormikVeldFout from '../../../../../../../../components/formulier/FormikVeldFout';
import {
  IOphalenRechtzakenResultElement,
  IWijzigenRechtzaakParams,
} from '../../../../../../../../../../shared/src/api/v2/debiteur/rechtzaak';
import { dagDatum } from '../../../../../../../../helpers/datum';

interface IProps extends IDialoogProps<null> {
  id: number;
}

interface IFormikValues {
  notities: string;
  dossierdatum: Date | string;
  // status: number;
  // resultaatStatus: number;
  afgehandeld: boolean;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  notities: 'Notities',
  dossierdatum: 'Dossierdatum',
  // status: 'Dossierstatus',
  // resultaatStatus: 'Resultaat',
  afgehandeld: 'Afgehandeld',
};

const WijzigenRechtzaakDialoog: React.FC<IProps> = (props) => {
  const { open, onSuccess, onAnnuleren, dialoogIndex, id: ID } = props;
  const { klantkaartStore } = useContext(RootStoreContext);

  const [rechtzaak, setRechtzaak] = useState<IOphalenRechtzakenResultElement | null>(null);

  const [dossierStatussen, setDossierStatussen] = useState<
    IOphalenDossierStatussenResultElement[] | null
  >(null);
  const [dossierResultaatStatussen, setDossierResultaatStatussen] = useState<
    IOphalenResultaatStatussenResultElement[] | null
  >(null);

  const ophalenDossierStatussen = useCallback(async () => {
    const { statussen } = await api.v2.debiteur.incassozaak.ophalenDossierStatussen({});
    setDossierStatussen(statussen);
  }, []);

  useEffect(() => {
    ophalenDossierStatussen();
  }, [ophalenDossierStatussen]);

  const ophalenResultaatStatussen = useCallback(async () => {
    const { statussen } = await api.v2.debiteur.incassozaak.ophalenResultaatStatussen({});
    setDossierResultaatStatussen(statussen);
  }, []);

  useEffect(() => {
    ophalenResultaatStatussen();
  }, [ophalenResultaatStatussen]);

  const ophalenRechtzaak = useCallback(async () => {
    const result = await api.v2.debiteur.rechtzaak.ophalenRechtzaken({
      filterSchema: { filters: [{ naam: 'IDS', data: [props.id] }] },
    });

    setRechtzaak(result.rechtzaken[0]);
  }, [props.id]);

  useEffect(() => {
    ophalenRechtzaak();
  }, [props.id]);

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (rechtzaak === null) {
      return null;
    }

    return {
      notities: rechtzaak.Notities === null ? '' : rechtzaak.Notities,
      dossierdatum: dagDatum(new Date(rechtzaak.Dossierdatum)),
      // status: rechtzaak.Status,
      // resultaatStatus: rechtzaak.ResultaatStatus,
      afgehandeld: rechtzaak.Afgehandeld,
    };
  }, [rechtzaak]);

  const handleSubmit = useCallback(async (values: IFormikValues) => {
    // const params: IWijzigenDossierParams = {
    //   id: props.ID,
    //   // notities: values.notities === '' ? null : values.notities,
    //   // zaakdatum: values.zaakdatum!.toISOString(),
    //   // status: values.status,
    //   // resultaatStatus: values.resultaatStatus!,
    //   afgehandeld: values.afgehandeld,
    // };

    const params: IWijzigenRechtzaakParams = {
      id: props.id,
      afgehandeld: values.afgehandeld,
      dossierdatum: values.dossierdatum,
      notities: values.notities === '' ? null : values.notities,
    };

    await api.v2.debiteur.rechtzaak.wijzigenRechtzaak(params);

    onSuccess(null);
  }, []);

  // const validationSchema = Yup.object().shape({
  //   notities: Yup.string(),
  //   dossierDatum: Yup.date().required(),
  //   dossierStatus: Yup.number().required(),
  //   // resultaatStatus: Yup.number().required(),
  // });

  if (rechtzaak === null) {
    return (
      <div className="flex-fill d-flex align-items-center justify-content-center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <Dialoog index={dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Wijzigen Rechtzaak</ModalTitle>
      </ModalHeader>

      <Formik<IFormikValues>
        onSubmit={handleSubmit}
        initialValues={initialValues!}
        isInitialValid
        // validationSchema={validationSchema}
        render={(formikProps: FormikProps<IFormikValues>) => {
          const { submitForm, isSubmitting, isValid } = formikProps;

          return (
            <>
              <ModalBody>
                <div className="form-group">
                  <div className="row">
                    {/* <div className="col-12">
                      <label>{veldnamen.status}</label>

                      <Field
                        name={nameof<IFormikValues>('status')}
                        render={(fieldProps: FieldProps<IFormikValues>) => {
                          const { field, form } = fieldProps;

                          if (dossierStatussen === null) {
                            return <LoadingSpinner />;
                          }

                          return (
                            <Combobox
                              // legeOptieTonen
                              geselecteerd={field.value}
                              onSelectieChange={(x) => form.setFieldValue(field.name, x)}
                              opties={dossierStatussen.map((status) => {
                                return {
                                  id: status.Status,
                                  label: status.Naam,
                                };
                              })}
                            />
                          );
                        }}
                      />
                    </div> */}

                    {/* <div className="col-12 mt-2">
                      <label>{veldnamen.resultaatStatus}</label>

                      <Field
                        name={nameof<IFormikValues>('resultaatStatus')}
                        render={(fieldProps: FieldProps<IFormikValues>) => {
                          const { field, form } = fieldProps;

                          if (dossierResultaatStatussen === null) {
                            return <LoadingSpinner />;
                          }

                          return (
                            <Combobox
                              geselecteerd={field.value}
                              onSelectieChange={(x) => form.setFieldValue(field.name, x)}
                              opties={dossierResultaatStatussen!.map((status) => {
                                return {
                                  id: status.Status,
                                  label: status.Naam,
                                };
                              })}
                            />
                          );
                        }}
                      />
                    </div> */}

                    <div className="col-12 mt-3">
                      <label>{veldnamen.dossierdatum}</label>
                      <Field
                        name={nameOf<IFormikValues>('dossierdatum')}
                        render={({ field, form }: FieldProps<IFormikValues>) => {
                          return (
                            <div className="d-flex">
                              <DatumKiezer
                                onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                waarde={field.value}
                                determineValidDate={() => {
                                  return true;
                                }}
                                determinePreviousValidDate="ONBEGRENST"
                                determineNextValidDate="ONBEGRENST"
                              />
                            </div>
                          );
                        }}
                      />
                    </div>

                    <div className="col-12 mt-3">
                      <label>{veldnamen.notities}</label>
                      <Field
                        name={nameOf<IFormikValues>('notities')}
                        render={(fieldProps: FieldProps<IFormikValues>) => {
                          const { field, form } = fieldProps;
                          return <textarea {...field} className="form-control" rows={3} />;
                        }}
                      />
                    </div>

                    <div className="col-12 mt-3">
                      <Field
                        name={nameOf<IFormikValues>('afgehandeld')}
                        render={(fieldProps: FieldProps<IFormikValues>) => {
                          const { field, form } = fieldProps;

                          return (
                            <>
                              <div className="d-flex align-items-center">
                                <VinkVeld
                                  aangevinkt={field.value}
                                  onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                />
                                <span className="ml-2">Afgehandeld</span>
                              </div>
                              <FormikVeldFout fieldProps={fieldProps} />
                            </>
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
              </ModalBody>
              <ModalFooter className="d-flex flex-row justify-content-start">
                <button
                  className="btn btn-primary"
                  onClick={submitForm}
                  style={{ width: 100 }}
                  disabled={isSubmitting || !isValid}
                >
                  Ok
                </button>
                <button className="btn btn-secondary" onClick={onAnnuleren} style={{ width: 100 }}>
                  Annuleren
                </button>
              </ModalFooter>
            </>
          );
        }}
      />
    </Dialoog>
  );
};

export default WijzigenRechtzaakDialoog;
