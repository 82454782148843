import React, { useMemo } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { genereerUrlStateQueryParam } from '../../../core/useUrlState';
import { IUrlState } from '../../Communicatie/Contactverzoek';
import { IActieProps } from '../index';

interface IData {
  id: number;
}

const SIGNALERINGEN_VERSCHIL_MET_VORIGE_KEER: React.VoidFunctionComponent<IActieProps> = (
  props: IActieProps,
) => {
  const data = useMemo(() => JSON.parse(props.controltaak.Data!) as IData, [
    props.controltaak.Data,
  ]);

  return (
    <a
      href="#"
      onClick={() => {
        props.history.push(`/signaleringen`);
      }}
    >
      Signaleringsoverzicht
    </a>
  );
};

export default SIGNALERINGEN_VERSCHIL_MET_VORIGE_KEER;
