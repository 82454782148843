import React, { useMemo, useState } from 'react';
import IDialoogProps from '../../../../core/IDialoogProps';
import Dialoog from '../../../dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import Tabblad, { ITabblad } from '../../../layout/Tabblad';
import SelecterenTabblad from './SelecterenTabblad';
import MeestGebruiktTabblad from './MeestGebruiktTabblad';
import EerderGebruiktVoorRelatieTabblad from './EerderGebruiktVoorRelatieTabblad';

export enum ETabblad {
  MeestGebruikt,
  Selecteren,
  EerderGebruiktVoorRelatie,
}

export interface ITabbladProps {
  relID?: number;
  onGrootboekIDGeselecteerd: (grootboekID: number) => void;
}

export interface IDialoogOutput {
  grootboekID: number;
}

interface IProps extends IDialoogProps<IDialoogOutput> {
  tabblad?: ETabblad;
  relID?: number;
}

const GrootboekSelectieDialoog = (props: IProps) => {
  const [tabblad, setTabblad] = useState<ETabblad>(
    props.tabblad ?? props.relID === undefined
      ? ETabblad.MeestGebruikt
      : ETabblad.EerderGebruiktVoorRelatie,
  );
  const tabbladen = useMemo<ITabblad<ETabblad, ITabbladProps>[]>(
    () =>
      [
        props.relID === undefined
          ? null
          : {
              id: ETabblad.EerderGebruiktVoorRelatie,
              label: 'Eerder gebruikt voor relatie',
              content: EerderGebruiktVoorRelatieTabblad,
            },
        {
          id: ETabblad.MeestGebruikt,
          label: 'Meest gebruikt',
          content: MeestGebruiktTabblad,
        },
        {
          id: ETabblad.Selecteren,
          label: 'Selecteren',
          content: SelecterenTabblad,
        },
      ].filter((x) => x !== null) as ITabblad<ETabblad, ITabbladProps>[],
    [props.relID],
  );

  return (
    <Dialoog
      modalProps={{ size: 'lg' }}
      index={props.dialoogIndex ?? 0}
      onEscape={props.onAnnuleren}
    >
      <ModalHeader>
        <ModalTitle>Grootboek selecteren</ModalTitle>
      </ModalHeader>
      <ModalBody className="p-0">
        <Tabblad<ETabblad, ITabbladProps>
          geselecteerd={tabblad}
          onSelectieChange={(x) => setTabblad(x)}
          tabbladen={tabbladen}
          options={{
            tabbladComponentProps: {
              onGrootboekIDGeselecteerd: (grootboekID) =>
                props.onSuccess({
                  grootboekID,
                }),
              relID: props.relID,
            },
          }}
        />
      </ModalBody>
      <ModalFooter className="d-flex justify-content-start mt-2">
        <button className="btn btn-secondary" style={{ width: 100 }} onClick={props.onAnnuleren}>
          Annuleren
        </button>
      </ModalFooter>
    </Dialoog>
  );
};

export default GrootboekSelectieDialoog;
