import React, { SVGProps } from 'react';
import styled from 'styled-components';
import { Kleur } from '../../../../../bedrijfslogica/constanten';
import { IconInklappen, IconUitklappen } from '../../../../Icons';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 38px;
  //margin: 5px 10px;
`;

const MapKnop = styled.div<{ actief: boolean }>`
  display: flex;
  flex: 1;
  align-items: center;
  transition: background-color 0.2s ease-in-out;
  cursor: pointer;
  background-color: ${(props) => (props.actief ? Kleur.DoorschijnendLichtBlauw : 'inherit')};
  padding: 7px 15px;
  border-bottom: 1px solid ${Kleur.HeelLichtGrijs};

  &:hover {
    background-color: ${Kleur.HeelLichtGrijs};
  }
`;

interface IProps {
  naam: string;
  icon: React.ComponentType<SVGProps<any>>;
  isActief: boolean;
  isUitgeklapt: boolean;
  onUitgeklaptChange: (uitgeklapt: boolean) => void;
  onClick: () => void;
  kanUitklappen: boolean;
}

const Map = (props: IProps) => {
  const Icon = props.icon;

  return (
    <Root>
      <MapKnop actief={props.isActief} onClick={props.onClick}>
        <Icon style={{ fill: Kleur.Grijs, width: 18, height: 18 }} />
        <span style={{ marginLeft: 12, fontSize: 14, flex: 1 }}>{props.naam}</span>
        {props.kanUitklappen && (
          <button
            style={{
              border: 'none',
              backgroundColor: 'transparent',
              outline: 0,
            }}
            onClick={(ev) => {
              ev.stopPropagation();
              props.onUitgeklaptChange(!props.isUitgeklapt);
            }}
          >
            {props.isUitgeklapt ? (
              <IconInklappen
                style={{ fill: Kleur.Grijs, width: 21, height: 21, position: 'relative' }}
              />
            ) : (
              <IconUitklappen
                style={{ fill: Kleur.Grijs, width: 21, height: 21, position: 'relative' }}
              />
            )}
          </button>
        )}
      </MapKnop>
    </Root>
  );
};

export default Map;
