import React, { useCallback, useContext, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import TegelBasis from '../../../components/tegel/TegelBasis';
import { RootStoreContext } from '../../../stores/RootStore';
import { ASPKolom, EAspKolomBreedteType } from '../../../components/tabel/ASPTabel/types';
import ASPTabel from '../../../components/tabel/ASPTabel';
import ASPGebruikerVisualisatie, {
  EModus,
} from '../../../components/ASPGebruiker/ASPGebruikerVisualisatie';
import { IVoipAccount, IVoipAccountVigerend } from '../../../../../shared/src/api/v2/telefonie';

enum EKolom {
  Naam,
  Intern,
  Gebruiker,
}

interface IProps {}

const VoipAccountsVigerend = observer((props: IProps) => {
  const { voipStore } = useContext(RootStoreContext);

  const voipAccountsBijID = useMemo<Record<number, IVoipAccount> | null>(() => {
    if (voipStore.voipAccounts === null) {
      return null;
    }
    return voipStore.voipAccounts.reduce((acc, curr) => ({ ...acc, [curr.VoipAccID]: curr }), {});
  }, [voipStore.voipAccounts]);

  const keyExtractor = useCallback((row: IVoipAccountVigerend) => row.ID, []);

  const kolommen = useMemo<ASPKolom<EKolom, IVoipAccountVigerend>[]>(
    () => [
      {
        key: EKolom.Gebruiker,
        label: 'Vigerende gebruiker',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 200,
        renderer: (item) => (
          <ASPGebruikerVisualisatie aspGebrID={item.AspGebrID} modus={EModus.Volledig} />
        ),
      },
      {
        key: EKolom.Intern,
        label: 'Nummer',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 100,
        renderer: (row: IVoipAccountVigerend) => {
          const voipAccount = voipAccountsBijID![row.VoipAccID];
          return voipAccount?.TelefoonIntern;
        },
      },
      {
        key: EKolom.Naam,
        label: 'Naam',
        breedteType: EAspKolomBreedteType.Flex,
        flex: 1,
        renderer: (row: IVoipAccountVigerend) => {
          const voipAccount = voipAccountsBijID![row.VoipAccID];
          return voipAccount?.Naam;
        },
      },
    ],
    [voipAccountsBijID],
  );

  const rijen = useMemo(() => {
    if (voipStore.voipAccountsVigerend === null) {
      return null;
    }
    return voipStore.voipAccountsVigerend.reduce(
      (acc, curr, i) => ({
        ...acc,
        [i]: curr,
      }),
      {},
    );
  }, [voipStore.voipAccountsVigerend]);

  return (
    <TegelBasis titel="VOIP-Accounts">
      <div style={{ minHeight: 200 }} className="d-flex flex-fill w-100">
        <ASPTabel
          rijen={rijen ?? {}}
          kolommen={kolommen}
          keyExtractor={keyExtractor}
          totaalAantalRijen={rijen === null ? 5 : undefined}
        />
      </div>
    </TegelBasis>
  );
});

export default VoipAccountsVigerend;
