import * as React from 'react';
import styled from 'styled-components';
import Zoeken from '../../../formulier/Zoeken';
import StandaardWeergave from './StandaardWeergave';
import { useCallback, useEffect, useRef, useState } from 'react';
import ZoekenWeergave from './ZoekenWeergave';
import useCallbackRef from '../../../../helpers/useCallbackRef';
import { useWhatsappV2Store } from '../store';
import { ZoekenFocus } from '../helpers';
import { IconFilter, IconKruis } from '../../../Icons';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import { IWhatsappBericht, IWhatsappChatsessie } from '../../../../api/services/v1/whatsapp';
import api from '../../../../api';
import { EWhatsappTabblad } from '../types';

const Root = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 450px;
  max-width: 450px;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
`;

const Header = styled.div`
  display: flex;
  padding: 12px 16px;
`;

const ZoekenFocusContainer = styled.div`
  display: flex;
`;

const ZoekenFocusWeergave = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: rgba(0, 0, 255, 0.5);
  font-weight: bold;
  color: white;
  padding: 0 0 0 8px;
  margin-right: 8px;
`;

const ZoekenFocusVerwijderenKnop = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 4px 8px;
  height: 100%;
  transition: background-color 0.2s ease-in-out;
  border: none;

  svg {
    fill: white;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }
`;

interface IProps {
  tabblad: EWhatsappTabblad;
  onTabbladChange: (tabblad: EWhatsappTabblad) => void;
  chatsessieID: number | null;
  onChatsessieIDChange: (chatsessieID: number | null) => void;
  zoekterm: string;
  onZoektermChange: (zoekterm: string) => void;
  zoekenFocus: ZoekenFocus | null;
  onZoekenFocusChange: (zoekenFocus: ZoekenFocus | null) => void;
}

const Chats = (props: IProps) => {
  const store = useWhatsappV2Store();
  const zoekenRef = useRef<HTMLInputElement | null>(null);
  const [zoekenFocused, setZoekenFocused] = useState(false);
  const heeftZoekterm = props.zoekterm.length > 0;

  const zoekenTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const zoekenAbortControllerRef = useRef<AbortController | null>(null);
  const handleZoektermInputChange = useCallback(
    (zoekterm: string) => {
      props.onZoektermChange(zoekterm);

      if (zoekenTimeoutRef.current !== null) {
        clearTimeout(zoekenTimeoutRef.current);
      }
      zoekenTimeoutRef.current = setTimeout(async () => {
        if (zoekenAbortControllerRef.current !== null) {
          zoekenAbortControllerRef.current.abort();
        }
        const abortController = new AbortController();
        zoekenAbortControllerRef.current = abortController;

        await store.zoek(zoekterm, abortController.signal);
      }, 500);
    },
    [props.onZoektermChange],
  );

  const zoekenWeergaveWeergeven = zoekenFocused || heeftZoekterm || props.zoekenFocus !== null;
  useEffect(() => {
    if (zoekenWeergaveWeergeven) {
      return;
    }
    if (zoekenTimeoutRef.current !== null) {
      clearTimeout(zoekenTimeoutRef.current);
    }
    zoekenTimeoutRef.current = null;
    if (zoekenAbortControllerRef.current !== null) {
      zoekenAbortControllerRef.current.abort();
    }
    zoekenAbortControllerRef.current = null;
    store.leegZoekResultaat();
  }, [zoekenWeergaveWeergeven]);

  useEffect(() => {
    return () => {
      if (zoekenTimeoutRef.current !== null) {
        clearTimeout(zoekenTimeoutRef.current);
      }
      zoekenTimeoutRef.current = null;
      if (zoekenAbortControllerRef.current !== null) {
        zoekenAbortControllerRef.current.abort();
      }
      zoekenAbortControllerRef.current = null;
    };
  }, []);

  const handleZoekenWeergaveChatsessieGekozen = useCallback(
    async (chatsessie: IWhatsappChatsessie) => {
      let statusNumber;
      let tabblad: EWhatsappTabblad;
      switch (chatsessie.status) {
        case 'nieuw':
          statusNumber = 1;
          tabblad = EWhatsappTabblad.Nieuw;
          break;
        case 'lopend':
          statusNumber = 2;
          tabblad = EWhatsappTabblad.Lopend;
          break;
        case 'afgehandeld':
          statusNumber = 3;
          tabblad = EWhatsappTabblad.Archief;
          break;
      }
      props.onTabbladChange(tabblad);
      props.onChatsessieIDChange(chatsessie.id);
      props.onZoekenFocusChange(null);
      props.onZoektermChange('');
      store.leegZoekResultaat();

      // Bepaal positie in het tabblad van de chatsessie
      const positiesOutput = await api.services.v1.whatsapp.bepalenPositiesVanChatsessies({
        chatsessieIds: [chatsessie.id],
        filters: {
          enkel: {
            kolom: 'status',
            filter: {
              nummeriek: {
                gelijkAan: statusNumber,
              },
            },
          },
        },
        sorteringen: [
          {
            kolom: 'meestRecenteBerichtDatum',
            richting: 'DESC',
          },
        ],
      });

      const positie = positiesOutput.positieBijChatsessieId[chatsessie.id];

      store.setScrollNaarIndexVoorTabblad({
        tabblad,
        index: positie,
      });
    },
    [props.onChatsessieIDChange, props.onTabbladChange],
  );

  const handleZoekenWeergaveBerichtGekozen = useCallback(
    async (bericht: IWhatsappBericht, chatsessie: IWhatsappChatsessie) => {
      await handleZoekenWeergaveChatsessieGekozen(chatsessie);
    },
    [handleZoekenWeergaveChatsessieGekozen],
  );

  return (
    <Root>
      <Header>
        {props.zoekenFocus !== null && (
          <ZoekenFocusContainer>
            <ZoekenFocusWeergave>
              <IconFilter style={{ fill: Kleur.Wit, width: 18, height: 18 }} />
              <span className="ml-2 mr-2">
                {props.zoekenFocus === 'chats' ? 'Chats' : 'Berichten'}
              </span>

              <ZoekenFocusVerwijderenKnop
                onClick={() => {
                  props.onZoekenFocusChange(null);
                  zoekenRef.current?.focus();
                }}
              >
                <IconKruis style={{ width: 18, height: 18 }} />
              </ZoekenFocusVerwijderenKnop>
            </ZoekenFocusWeergave>
          </ZoekenFocusContainer>
        )}
        <Zoeken
          ref={zoekenRef}
          input={props.zoekterm}
          onInputChange={handleZoektermInputChange}
          placeholder="Zoeken..."
          onFocus={() => setZoekenFocused(true)}
          onBlur={() => setZoekenFocused(false)}
        />
      </Header>
      {zoekenWeergaveWeergeven ? (
        <ZoekenWeergave
          zoekterm={props.zoekterm}
          onZoektermChange={props.onZoektermChange}
          onTabbladChange={props.onTabbladChange}
          onChatsessieGekozen={handleZoekenWeergaveChatsessieGekozen}
          onBerichtGekozen={handleZoekenWeergaveBerichtGekozen}
          zoekenFocus={props.zoekenFocus}
          onZoekenFocusChange={(focus) => {
            props.onZoekenFocusChange(focus);
            zoekenRef.current?.focus();
          }}
        />
      ) : (
        <StandaardWeergave
          tabblad={props.tabblad}
          onTabbladChange={props.onTabbladChange}
          chatsessieID={props.chatsessieID}
          onChatsessieIDChange={props.onChatsessieIDChange}
        />
      )}
    </Root>
  );
};

export default Chats;
