import React, { useMemo } from 'react';
import { IOphalenBestandenResultElement } from '../../../../../../shared/src/api/v2/bestand/bestand';
import TekstOverflowCenterEllipsis from '../../../tekst/TekstOverflowCenterEllipsis';
import Afbeelding from './Afbeelding';
import GeenVisualisatie from './GeenVisualisatie';
import PDF from './PDF';

interface IProps {
  bestand: IOphalenBestandenResultElement;
  width: number;
  height: number;
}

const BestandWeergave = (props: IProps) => {
  const visualisatie = useMemo(() => {
    if (props.bestand.MediaType.startsWith('image/')) {
      return (
        <Afbeelding
          src={props.bestand.url}
          width={props.width}
          height={props.height}
          mediaType={props.bestand.MediaType}
        />
      );
    }
    if (props.bestand.MediaType === 'application/pdf') {
      return <PDF src={props.bestand.url} width={props.width} height={props.height} />;
    }

    return (
      <GeenVisualisatie
        width={props.width}
        height={props.height}
        mediaType={props.bestand.MediaType}
      />
    );
  }, [props.bestand]);

  return (
    <>
      {visualisatie}
      <TekstOverflowCenterEllipsis
        tekst={props.bestand.Naam}
        width={props.width * (3 / 4)}
        maxLines={1}
        outerCss={{ marginTop: 3 }}
      />
    </>
  );
};

export default BestandWeergave;
