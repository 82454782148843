import React, { useState, useEffect, useCallback, useMemo, useContext } from 'react';
import api from '../../../../../../../api';
import LoadingSpinner from '../../../../../../../components/Gedeeld/LoadingSpinner';
import { observer } from 'mobx-react-lite';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import useUrlState from '../../../../../../../core/useUrlState';
import { IOphalenVorderingenResultElement } from '../../../../../../../../../shared/src/api/v2/vordering';
import { RootStoreContext } from '../../../../../../../stores/RootStore';
import {
  TypedColumn,
  TypedTableColumnWidthInfo,
  GridStyleWrapper,
  DXTableCheckboxComponent,
  DXTableToggleCellComponent,
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
} from '../../../../../../../helpers/dxTableGrid';
import { Kleur } from '../../../../../../../bedrijfslogica/constanten';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  VirtualTable,
  TableEditColumn,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  DataTypeProvider,
  EditingState,
  SelectionState,
  VirtualTable as VirtualTableBase,
} from '@devexpress/dx-react-grid';
import { format } from 'date-fns';
import FormatteerBedrag from '../../../../../../../components/MutatieBedrag';
import RelatieVisualisatie from '../../../../../../../components/personalia/RelatieVisualisatie';
import { EResultType } from '../../../../../../../stores/CheckStore';
import {
  IconVerwijderen,
  IconOverzicht,
  IconToevoegen,
} from '../../../../../../../components/Icons';
import WijzigenVorderingDialoog from '../../../../../../Facturering/Vorderingen/WijzigenVorderingDialoog';
import FactuurVoorstelDialoog from '../../../../../../Facturering/Vorderingen/FactuurVoorstelDialoog';
import { IOphalenVerkopenResultElement } from '../../../../../../../../../shared/src/api/v2/verkoop/verkoop';
import nameof from '../../../../../../../core/nameOf';
import SelectieDialoog from '../../../../../../../components/dialogen/SelectieDialoog';
import { IOphalenVerkoopmodellenResultElement } from '../../../../../../../../../shared/src/api/v2/aanbod/verkoop';
import VinkVeld from '../../../../../../../components/formulier/VinkVeld';
import { url } from 'inspector';
import { IFilterSchemaFilter } from '../../../../../../../../../shared/src/models/filter';
import { ContractenContext } from '../index';

interface IProps extends RouteComponentProps {
  relID: number;
}

interface IAccessoireToevoegenDialoogState {
  actief: boolean;
}

interface IUrlState {
  selectie: number[];
  accessoireToevoegenDialoogState: IAccessoireToevoegenDialoogState | null;
}

const defaultUrlState: IUrlState = {
  selectie: [],
  accessoireToevoegenDialoogState: null,
};

const geenData = {
  noData: 'Geen opdrachten gevonden',
};

const Accessoires: React.FC<IProps> = observer((props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState<IUrlState>(props, defaultUrlState);
  const { klantkaartStore, checkStore } = useContext(RootStoreContext);
  const contractenContext = useContext(ContractenContext);
  const [opdrachten, setOpdrachten] = useState<IOphalenVerkopenResultElement[] | null>(null);

  const ophalenOpdrachten = useCallback(async () => {
    const { verkopen: opdrachten } = await api.v2.verkoop.ophalenVerkopen({
      filterSchema: {
        filters: [
          {
            naam: 'RELID',
            data: contractenContext.relID,
          },
        ],
      },
    });

    setOpdrachten(opdrachten);
  }, [contractenContext.relID]);

  useEffect(() => {
    ophalenOpdrachten();
  }, [ophalenOpdrachten]);

  const handleToevoegen = useCallback(async () => {
    setUrlStateSync('accessoireToevoegenDialoogState', {
      actief: true,
    });
  }, [setUrlStateSync]);

  const handleVerwijderen = useCallback(async () => {
    const checkData = await api.v2.verkoop.checkVerwijderenVerkoopopdrachten({
      IDs: urlState.selectie,
    });
    if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
      return;
    }

    if (
      (
        await checkStore.bevestigen({
          inhoud: (
            <span>
              Wil je de opdrachten verwijderen?
              <br />
              <br />
              Let op: Dit kan niet meer ongedaan gemaakt worden{' '}
            </span>
          ),
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    await api.v2.verkoop.verwijderenVerkoopopdrachten({
      IDs: urlState.selectie,
    });

    setUrlStateSync('selectie', []);

    await ophalenOpdrachten();
  }, [urlState.selectie]);

  const keyExtractor = useCallback((row: IOphalenVerkopenResultElement) => row.ID, []);

  const kolommen = useMemo<TypedColumn<IOphalenVerkopenResultElement>[]>(
    () => [
      {
        name: 'Verkoopdatum',
        title: 'Koopdatum',
      },
      { name: '__naam' as any, title: 'Naam' },
      { name: 'Aantal', title: 'Aantal' },
      { name: 'Prijs', title: 'Prijs/stuk' },
      { name: '__prijsTotaal' as any, title: 'Totaal' },
      {
        name: 'BevestigingSturen',
        title: 'Bev. sturen',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IOphalenVerkopenResultElement>[]>(
    () => [
      {
        columnName: 'Verkoopdatum',
        width: 150,
      },
      { columnName: 'Prijs', width: 100 },
      { columnName: '__prijsTotaal' as any, width: 100 },
      { columnName: 'Aantal', width: 65 },
      { columnName: '__naam' as any, width: 350 },
      { columnName: 'BevestigingSturen' as any, width: 90 },
    ],
    [],
  );

  const tabelKolomExtensies: VirtualTableBase.ColumnExtension[] = useMemo(() => {
    return [
      {
        columnName: `Prijs`,
        align: 'right',
      },
      {
        columnName: `__prijsTotaal`,
        align: 'right',
      },
      {
        columnName: `Aantal`,
        align: 'right',
      },
    ];
  }, []);

  return (
    <>
      {opdrachten === null ? (
        <LoadingSpinner />
      ) : (
        <>
          <div
            className="d-flex flex-column p-3"
            style={{
              backgroundColor: Kleur.HeelLichtGrijs,
              borderBottom: `1px solid ${Kleur.LichtGrijs}`,
            }}
          >
            <div className="d-flex">
              <button
                className="btn btn-sm btn-light d-flex align-items-center"
                style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                onClick={() => handleToevoegen()}
              >
                <IconToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                <span className="ml-2">Accessoire toevoegen</span>
              </button>

              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-3"
                style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                disabled={urlState.selectie.length === 0}
                onClick={() => handleVerwijderen()}
              >
                <IconVerwijderen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                <span className="ml-2">Verwijderen</span>
              </button>
            </div>
          </div>
          <GridStyleWrapper height="calc(100vh - 150px)">
            <Grid columns={kolommen} getRowId={keyExtractor} rows={opdrachten!}>
              <DataTypeProvider
                for={[nameof<IOphalenVerkopenResultElement>('Verkoopdatum')]}
                formatterComponent={(props) => {
                  return <span>{format(new Date(props.value), 'dd-MM-yyyy HH:mm')}</span>;
                }}
              />

              <DataTypeProvider
                for={[nameof<IOphalenVerkopenResultElement>('Prijs')]}
                formatterComponent={(props) => (
                  <FormatteerBedrag bedrag={props.value} rechtsUitlijnen />
                )}
              />

              <DataTypeProvider
                for={['__naam']}
                formatterComponent={(props) => {
                  const rij: IOphalenVerkopenResultElement = props.row;
                  const naam =
                    rij.product !== null
                      ? rij.product.producttype.Typenaam
                      : rij.verkoopmodel!.Naam;
                  return <span>{naam}</span>;
                }}
              />

              <DataTypeProvider
                for={['__prijsTotaal']}
                formatterComponent={(props) => {
                  const rij: IOphalenVerkopenResultElement = props.row;
                  const bedrag = rij.Aantal * rij.Prijs;
                  return <FormatteerBedrag bedrag={bedrag} rechtsUitlijnen />;
                }}
              />

              <DataTypeProvider
                for={['BevestigingSturen']}
                formatterComponent={(props) => {
                  return <span>{props.value ? 'Ja' : ''}</span>;
                }}
              />

              <EditingState
                onAddedRowsChange={() => {
                  alert('Plus');
                }}
                onCommitChanges={() => null}
                onEditingRowIdsChange={(rowIds) => {
                  const id = rowIds[rowIds.length - 1] as number;
                  alert('Wijzigen ' + id);
                  // setWijzigenID(id);
                }}
              />

              {/* <RowDetailState defaultExpandedRowIds={[]} /> */}

              <VirtualTable messages={geenData} columnExtensions={tabelKolomExtensies} />

              <TableEditColumn
                width={35}
                // showAddCommand={true}
                showEditCommand
                cellComponent={DXTableEditColumnCellComponent}
                commandComponent={DXTableEditColumnCommandComponent}
              />

              <SelectionState
                selection={urlState.selectie}
                onSelectionChange={(value) => setUrlStateSync('selectie', value as number[])}
              />
              <TableSelection cellComponent={DXTableCheckboxComponent} />
              <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
              <TableHeaderRow />

              {/* <TableRowDetail
                // contentComponent={}
                toggleCellComponent={DXTableToggleCellComponent}
              /> */}
            </Grid>
          </GridStyleWrapper>

          {/* <WijzigenVorderingDialoog
            factRegID={wijzigenID!}
            open={wijzigenID !== null}
            onAnnuleren={() => setWijzigenID(null)}
            onSuccess={() => {
              setWijzigenID(null);
              ophalenVorderingen();
            }}
          /> */}
        </>
      )}
      {urlState.accessoireToevoegenDialoogState !== null && (
        <SelectieDialoog<IOphalenVerkoopmodellenResultElement>
          titel="Selecteer accessoire"
          resolveOpties={async () => {
            const result = await api.v2.aanbod.verkoop.ophalenVerkoopmodellen({
              filterSchema: {
                filters: [
                  urlState.accessoireToevoegenDialoogState!.actief
                    ? {
                        naam: 'ACTIEF',
                        data: true,
                      }
                    : null,
                ].filter((x) => x !== null) as IFilterSchemaFilter[],
              },
            });
            return result.verkoopmodellen;
          }}
          keyExtractor={(x) => x.VerkModID}
          kolommen={[
            {
              key: 'Naam',
              label: 'Naam',
              breedte: 300,
            },
            {
              key: 'Prijs',
              label: 'Prijs',
              breedte: 100,
              formatFabriek: (ent) => <FormatteerBedrag bedrag={ent.Prijs} />,
            },
          ]}
          filterSectie={() => {
            return (
              <div className="p-3 pl-4 pr-4">
                <div className="d-flex align-items-center">
                  <VinkVeld
                    aangevinkt={urlState.accessoireToevoegenDialoogState!.actief}
                    onGewijzigd={(x) =>
                      setUrlStateSync('accessoireToevoegenDialoogState', {
                        ...urlState.accessoireToevoegenDialoogState!,
                        actief: x,
                      })
                    }
                  />
                  <span className="ml-2">Actief</span>
                </div>
              </div>
            );
          }}
          open
          onSuccess={async (result) => {
            await api.v2.verkoop.toevoegenVerkoopopdrachten({
              relID: props.relID,
              verkModIDs: [result.entiteit.VerkModID],
              aantal: 1,
              mutatiebron: 1,
            });
            ophalenOpdrachten();
            setUrlStateSync('accessoireToevoegenDialoogState', null);
          }}
          onAnnuleren={() => setUrlStateSync('accessoireToevoegenDialoogState', null)}
        />
      )}
    </>
  );
});

export default withRouter(Accessoires);
