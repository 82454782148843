import React, { useContext, useEffect } from 'react';
import { useMemo } from 'react';
import { RouteComponentProps } from 'react-router';
import { v4 as uuidv4 } from 'uuid';

// const useUrlTabbladMatchTree: Record<string, string> = {};

export const useBepaalUrlTabblad = <ETabblad extends string | number | symbol>(
  props: RouteComponentProps,
  pathMap: Record<ETabblad, string>,
  fallback?: ETabblad,
): ETabblad | null => {
  // const id = useMemo(() => uuidv4(), []);

  // const matchTreeKeys = Object.keys(useUrlTabbladMatchTree).map(
  //   (key) => useUrlTabbladMatchTree[key],
  // );

  const tabblad = useMemo(() => {
    // const otherMatchTreeKeys = Object.keys(useUrlTabbladMatchTree)
    //   .filter((x) => x !== id)
    //   .map((x) => useUrlTabbladMatchTree[x]);
    // let cleanPath = props.location.pathname;
    // otherMatchTreeKeys.forEach((treeKey) => {
    //   cleanPath = cleanPath.replace(treeKey, '');
    // });

    const matchKey = Object.keys(pathMap).find((key) => {
      const path = pathMap[Number(key) as ETabblad]!;
      return props.location.pathname.lastIndexOf(path) !== -1;
    });
    if (matchKey === undefined) {
      if (fallback !== undefined) {
        props.history.push(`${props.location.pathname}${pathMap[fallback]}`);
      }
      return null;
    }
    return Number(matchKey) as ETabblad;
  }, [props.location.pathname]);
  // }, [props.location.pathname, ...matchTreeKeys]);

  // if (tabblad === null) {
  //   delete useUrlTabbladMatchTree[id];
  // } else {
  //   useUrlTabbladMatchTree[id] = pathMap[tabblad];
  // }

  // useEffect(() => {
  //   return () => {
  //     delete useUrlTabbladMatchTree[id];
  //   };
  // }, []);

  // console.log(useUrlTabbladMatchTree);
  return tabblad;
};

export interface IUseUrlTabbladOutput<ETabblad extends string | number | symbol> {
  tabblad: ETabblad | null;
  onChange: (tabblad: ETabblad) => void;
}

const useUrlTabblad = <ETabblad extends string | number | symbol>(
  props: RouteComponentProps,
  pathMap: Record<ETabblad, string>,
  fallback?: ETabblad,
): IUseUrlTabbladOutput<ETabblad> => {
  const tabblad = useBepaalUrlTabblad(props, pathMap, fallback);

  return {
    tabblad,
    onChange: (tab) => {
      if (tabblad === null) {
        // TODO FIX
        throw new Error();
      }
      props.history.push(`${props.location.pathname.replace(pathMap[tabblad], '')}${pathMap[tab]}`);
    },
  };
};

export default useUrlTabblad;
