import React, { useMemo } from 'react';
import Tabblad, { ITabblad } from '../../../../../../components/layout/Tabblad';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Melding from './Meldingen';
import Opdracht from './Opdrachten';
import Werkbon from './Werkbonnen';
import { IEntiteitProps } from '../../../../../../components/kaart/EntiteitContainer';

interface IProps extends IEntiteitProps, RouteComponentProps {}

export enum ETabblad {
  Melding = 1,
  Opdracht = 2,
  Werkbon = 3,
}

const pathMap: Record<ETabblad, string> = {
  [ETabblad.Melding]: '/melding',
  [ETabblad.Opdracht]: '/opdracht',
  [ETabblad.Werkbon]: '/werkbon',
};

export interface IServiceContext {
  relID: number;
}
export const ServiceContext = React.createContext<IServiceContext>(null as any);

const Service: React.FC<IProps> = (props) => {
  const tabblad = useMemo<ETabblad | null>(() => {
    const matchKey = Object.keys(pathMap).find((key) => {
      const path = pathMap[Number(key) as ETabblad]!;
      return props.location.pathname.endsWith(path);
    });
    if (matchKey === undefined) {
      props.history.replace(`${props.location.pathname}${pathMap[ETabblad.Melding]}`);
      return null;
    }
    return Number(matchKey) as ETabblad;
  }, [props.location.pathname]);

  const tabbladen = useMemo<ITabblad<ETabblad>[]>(
    () => [
      {
        id: ETabblad.Melding,
        label: 'Meldingen',
        content: Melding,
      },
      {
        id: ETabblad.Opdracht,
        label: 'Rep.opdrachten',
        content: Opdracht,
      },
      {
        id: ETabblad.Werkbon,
        label: 'Werkbonnen',
        content: Werkbon,
      },
    ],
    [],
  );

  const context = useMemo<IServiceContext>(
    () => ({
      relID: props.relID,
    }),
    [props.relID],
  );

  return (
    <ServiceContext.Provider value={context}>
      <div className="d-flex flex-column flex-fill flex-fill">
        {tabblad !== null && (
          <Tabblad<ETabblad>
            geselecteerd={tabblad}
            onSelectieChange={(tab) => {
              props.history.push(
                `${props.location.pathname.replace(pathMap[tabblad], '')}${pathMap[tab]}`,
              );
            }}
            tabbladen={tabbladen}
          />
        )}
      </div>
    </ServiceContext.Provider>
  );
};

export default withRouter(Service);
