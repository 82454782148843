import api from '../index';
import {
  IOphalenPartnershipsParams,
  IOphalenPartnershipsResult,
  IToevoegenPartnershipParams,
  IVerwijderenPartnershipsParams,
  IVerwijderenPartnershipsResult,
  IWijzigenPartnershipParams,
  IWijzigenPartnershipResult,
} from '../../../../shared/src/api/v2/partnership';
import { IChatSessie } from '../../stores/WhatsappStore';
import ICheckData from '../../../../shared/src/models/ICheckData';

const partnership = {
  ophalenPartnerships: async (params: IOphalenPartnershipsParams) => {
    return await api.post<IOphalenPartnershipsResult>(
      'v2/partnership/ophalen-partnerships',
      params,
    );
  },
  checkToevoegenPartnership: async (params: IToevoegenPartnershipParams) => {
    return await api.post<ICheckData>('v2/partnership/check-toevoegen-partnership', params);
  },
  toevoegenPartnership: async (params: IToevoegenPartnershipParams) => {
    return await api.post<IToevoegenPartnershipParams>(
      'v2/partnership/toevoegen-partnership',
      params,
    );
  },
  checkWijzigenPartnership: async (params: IWijzigenPartnershipParams) => {
    return await api.post<ICheckData>('v2/partnership/check-wijzigen-partnership', params);
  },
  wijzigenPartnership: async (params: IWijzigenPartnershipParams) => {
    return await api.post<IWijzigenPartnershipResult>(
      'v2/partnership/wijzigen-partnership',
      params,
    );
  },
  checkVerwijderenPartnerships: async (params: IVerwijderenPartnershipsParams) => {
    return await api.post<ICheckData>('v2/partnership/check-verwijderen-partnership', params);
  },
  verwijderenPartnerships: async (params: IVerwijderenPartnershipsParams) => {
    return await api.post<IVerwijderenPartnershipsResult>(
      'v2/partnership/verwijderen-partnerships',
      params,
    );
  },
};

export default partnership;
