import styled from 'styled-components';

interface IRootProps {
  disabled: boolean;
}

export const Root = styled.div<IRootProps>`
  cursor: ${(props) => (props.disabled ? 'inherit' : 'pointer')};

  &:hover {
  }
`;
