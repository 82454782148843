import React from 'react';
import { format } from 'date-fns';
import { BerichtInhoud, BerichtTijd, Root, Wrapper } from './style';
import { IconDownload } from '../../Icons';
import DownloadKnop from '../../DownloadKnop';

interface IProps {
  isInkomend: boolean;
  datum: Date;
  wrapperStyle?: React.CSSProperties;
  rootStyle?: React.CSSProperties;
  extras?: JSX.Element;
}

const BerichtItem: React.FC<IProps> = (props) => {
  return (
    <Wrapper style={props.wrapperStyle}>
      <Root isInkomend={props.isInkomend} style={props.rootStyle}>
        <BerichtInhoud>{props.children}</BerichtInhoud>
        <div className="d-flex flex-column">
          {props.extras ?? <div className="flex-fill" />}
          <BerichtTijd>
            <span>{format(props.datum, 'HH:mm')}</span>
            <span>{format(props.datum, 'dd-MM-yyyy')}</span>
          </BerichtTijd>
        </div>
      </Root>
    </Wrapper>
  );
};

export default BerichtItem;
