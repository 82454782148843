import React from 'react';
import TableData from '../TableData';
import { IconVerwijderen } from '../../../../Icons';
import { Kleur } from '../../../../../bedrijfslogica/constanten';

export interface IVerwijderenTableDataProps<TRow> {
  row?: TRow;
  isGefocust?: boolean;
  isGehovered?: boolean;
  heeftUitgeklapteRijOnderZich: boolean;
  onVerwijderenRij: () => void;
  rowIndex: number;
}

const StandaardVerwijderenTableData = <TRow extends any>(
  props: IVerwijderenTableDataProps<TRow>,
) => {
  return (
    <TableData
      tableKey={null}
      heeftUitgeklapteRijOnderZich={props.heeftUitgeklapteRijOnderZich}
      style={{ padding: 0, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      isGehovered={props.isGehovered}
      isGefocust={props.isGefocust}
      rowIndex={props.rowIndex}
    >
      <button
        title="Verwijderen"
        style={{
          background: 0,
          outline: 0,
          border: 0,
        }}
        onClick={(ev) => {
          ev.preventDefault();
          props.onVerwijderenRij();
        }}
      >
        <IconVerwijderen
          style={{
            fill: 'rgb(168, 168, 168)',
            width: 17,
            height: 17,
            position: 'relative',
            bottom: 0.5,
          }}
        />
      </button>
    </TableData>
  );
};

export default StandaardVerwijderenTableData;
