import { Field, FieldProps } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { IOphalenOpdrachtwijzenResultElement } from '../../../../../../../shared/src/api/v2/dienst';
import api from '../../../../../api';
import Combobox from '../../../../../components/formulier/Combobox';
import FormikVeldFout from '../../../../../components/formulier/FormikVeldFout';
import NumeriekVeld from '../../../../../components/formulier/NumeriekVeld';
import TijdVeld from '../../../../../components/formulier/TijdVeld';
import VinkVeld from '../../../../../components/formulier/VinkVeld';
import LoadingSpinner from '../../../../../components/Gedeeld/LoadingSpinner';
import nameOf from '../../../../../core/nameOf';
import { IFormikValues, veldnamen } from '../../Inkoop/MutatieDialoog';

const InkoopFormulier: React.FC = (props) => {
  const [opdrachtWijzen, setOpdrachtWijzen] = useState<
    IOphalenOpdrachtwijzenResultElement[] | null
  >(null);

  const ophalenOpdrachtwijzen = useCallback(async () => {
    const opdrachtwijzenResult = await api.v2.dienst.ophalenOpdrachtwijzen({
      filterSchema: { filters: [] },
    });

    setOpdrachtWijzen(opdrachtwijzenResult.opdrachtwijzen);
  }, []);

  useEffect(() => {
    ophalenOpdrachtwijzen();
  }, []);

  return (
    <div className="p-3">
      <div className="row mb-3">
        <div className="col-12">
          <label>{veldnamen.opdWijzeID}</label>
          <Field
            name={nameOf<IFormikValues>('opdWijzeID')}
            render={(fieldProps: FieldProps<IFormikValues>) => {
              const { field, form } = fieldProps;

              if (opdrachtWijzen === null) {
                return <LoadingSpinner />;
              }

              return (
                <div>
                  <Combobox
                    geselecteerd={field.value}
                    opties={opdrachtWijzen.map((opdrachtwijze) => {
                      return {
                        id: opdrachtwijze.OpdWijzeID,
                        label: opdrachtwijze.Naam,
                      };
                    })}
                    onSelectieChange={(x) => form.setFieldValue(field.name, x)}
                  />
                </div>
              );
            }}
          />
        </div>
        <div className="col-12 mt-3">
          <label>{veldnamen.emailOpdracht}</label>
          <Field
            name={nameOf<IFormikValues>('emailOpdracht')}
            render={(fieldProps: FieldProps) => {
              const { field, form } = fieldProps;
              return (
                <div>
                  <input className="form-control" {...fieldProps.field} />
                  <FormikVeldFout fieldProps={fieldProps} />
                </div>
              );
            }}
          />
        </div>
        <div className="col-12 mt-3">
          <label>{veldnamen.emailOpdrachtCC}</label>
          <Field
            name={nameOf<IFormikValues>('emailOpdrachtCC')}
            render={(fieldProps: FieldProps) => {
              const { field, form } = fieldProps;
              return (
                <div>
                  <input className="form-control" {...fieldProps.field} />
                  <FormikVeldFout fieldProps={fieldProps} />
                </div>
              );
            }}
          />
        </div>

        <div className="col-12 mt-3">
          <label>{veldnamen.emailRetouraanmelding}</label>
          <Field
            name={nameOf<IFormikValues>('emailRetouraanmelding')}
            render={(fieldProps: FieldProps) => {
              const { field, form } = fieldProps;
              return (
                <div>
                  <input
                    className="form-control"
                    {...fieldProps.field}
                    placeholder="Indien leeg dan wordt het emailadres voor de opdrachten aangehouden"
                  />
                  <FormikVeldFout fieldProps={fieldProps} />
                </div>
              );
            }}
          />
        </div>

        <div className="row col-12 mt-3">
          <div className="col-6">
            <label>{veldnamen.levertermijn}</label>
            <Field
              name={nameOf<IFormikValues>('levertermijn')}
              render={(fieldProps: FieldProps<IFormikValues>) => {
                const { field, form } = fieldProps;
                return (
                  <div>
                    <NumeriekVeld
                      waarde={field.value}
                      onChange={(x) => form.setFieldValue(field.name, x)}
                      min={0}
                      max={99}
                    />
                    <FormikVeldFout fieldProps={fieldProps} />
                  </div>
                );
              }}
            />
          </div>
          <div className="col-6">
            <label>{veldnamen.tijdUiterlijkOpdracht}</label>
            <Field
              name={nameOf<IFormikValues>('tijdUiterlijkOpdracht')}
              render={(fieldProps: FieldProps) => {
                const { field, form } = fieldProps;

                return (
                  <div>
                    <TijdVeld
                      waarde={field.value}
                      onWaardeChange={(x) => form.setFieldValue(field.name, x)}
                    />
                    <FormikVeldFout fieldProps={fieldProps} />
                  </div>
                );
              }}
            />
          </div>
        </div>

        <div className="col-12 mt-3">
          <Field
            name={nameOf<IFormikValues>('koppelenProductenAanFactuur')}
            render={(fieldProps: FieldProps<IFormikValues>) => {
              const { field, form } = fieldProps;
              return (
                <div className="d-flex align-items-center">
                  <VinkVeld
                    aangevinkt={field.value}
                    onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                  />
                  <span className="ml-2">{veldnamen.koppelenProductenAanFactuur}</span>
                </div>
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default InkoopFormulier;
