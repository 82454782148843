import { RootStore } from './RootStore';
import { makeObservable, observable } from 'mobx';
import api from '../api';
import {
  IOphalenChatBerichtenResult,
  IOphalenChatBerichtenResultElement,
  IOphalenChatSessiesResultElement,
} from '../../../shared/src/api/v2/dienst/whatsapp/whatsapp';
import IInboundMessageData from '../../../shared/src/realtime-manager/messages/dienst/whatsapp/inboundMessage';
import IWhatsappUpdateChatsessieData from '../../../shared/src/realtime-manager/messages/dienst/whatsapp/updateChatsessie';

export interface IBericht {
  id: number;
  whatsAppDienstID: number;
  inhoud: string | null;
  referentie: string;
  richting: string;
  datum: Date;
  mediaType: string | null;
  mediaTitle: string | null;
  mediaURL: string | null;
  custom: string | null;
}

const fromIOphalenChatBerichtResultElementToIBericht = (
  x: IOphalenChatBerichtenResultElement,
): IBericht => ({
  id: x.ID,
  datum: new Date(x.Datum),
  mediaURL: x.MediaURL,
  mediaTitle: x.MediaTitle,
  mediaType: x.MediaType,
  custom: x.Custom,
  inhoud: x.Inhoud,
  referentie: x.Referentie,
  richting: x.Richting,
  whatsAppDienstID: x.WhatsAppDienstID,
});

const fromIOphalenChatSessiesResultElementToIChatSessie = (
  x: IOphalenChatSessiesResultElement,
  berichten: IBericht[] | null,
): IChatSessie => {
  const ber = fromIOphalenChatBerichtResultElementToIBericht(x.laatsteBericht);
  return {
    ID: x.ID,
    avatarUrl: x.AvatarURL,
    laatsteBericht: ber,
    naam: x.Naam,
    telefoonnummer: x.Telefoonnummer,
    berichten,
    status: x.Status,
    persID: x.PersID,
    relID: x.RelID,
  };
};

export enum EChatSessieStatus {
  Nieuw = 1,
  Lopend,
  Afgehandeld,
}

export interface IChatSessie {
  ID: number;
  telefoonnummer: string;
  naam: string | null;
  avatarUrl: string | null;
  laatsteBericht: IBericht;
  berichten: IBericht[] | null;
  status: EChatSessieStatus;
  relID: number | null;
  persID: number | null;
}

export enum EBerichtType {
  Text,
  Afbeelding,
  Video,
  Audio,
  Bestand,
  Locatie,
  Contact,
  Verwijdered,
}

export interface ILocation {
  latitude: number;
  longitude: number;
  label?: string;
  searchQuery?: string;
  radius?: number;
}

interface IName {
  first_name: string;
  last_name: string;
  middle_name: string;
  name_prefix?: any;
  name_suffix?: any;
  formatted_name: string;
}

interface IOrg {
  company?: any;
  department?: any;
  title?: any;
}

interface IPhone {
  phone: string;
  type: string;
}

interface IContact {
  addresses: any[];
  emails: any[];
  name: IName;
  org: IOrg;
  phones: IPhone[];
  urls: any[];
  ims: any[];
}

export interface IContactObject {
  contacts: IContact[];
}

export const extraheerCustomVanBericht = <TData>(bericht: IBericht) => {
  const custom = bericht.custom!;
  return JSON.parse(custom) as TData;
};

export const getBerichtType = (bericht: IBericht): EBerichtType => {
  const { mediaType, custom } = bericht;
  if (custom !== null && custom !== '{}') {
    if (custom.includes('deletedMessage')) {
      return EBerichtType.Verwijdered;
    }
    if (custom.includes('contact')) {
      return EBerichtType.Contact;
    }

    return EBerichtType.Locatie;
  }

  if (mediaType !== null) {
    if (mediaType.startsWith('image')) {
      return EBerichtType.Afbeelding;
    } else if (mediaType.startsWith('video')) {
      return EBerichtType.Video;
    } else if (mediaType.startsWith('audio')) {
      return EBerichtType.Audio;
    } else {
      return EBerichtType.Bestand;
    }
  }

  return EBerichtType.Text;
};

export interface IChatsessieState {
  tekst: string;
}

class WhatsappStore {
  public rootStore: RootStore;

  public chatSessies: IChatSessie[] | null = null;
  public chatsessieState: Record<number, IChatsessieState> = {};

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    makeObservable(this, {
      chatSessies: observable,
      chatsessieState: observable,
    });
  }

  public ophalenChatSessies = async () => {
    const result = await api.v2.dienst.whatsapp.ophalenChatSessies({});
    this.chatSessies = result.map((chatSessie) => {
      return {
        ID: chatSessie.ID,
        avatarUrl: chatSessie.AvatarURL,
        berichten: null,
        laatsteBericht: fromIOphalenChatBerichtResultElementToIBericht(chatSessie.laatsteBericht),
        naam: chatSessie.Naam,
        telefoonnummer: chatSessie.Telefoonnummer,
        status: chatSessie.Status,
        relID: chatSessie.RelID,
        persID: chatSessie.PersID,
      };
    });
  };

  public ophalenChatBerichten = async (sesID: number) => {
    const result = await api.v2.dienst.whatsapp.ophalenChatBerichten({ sesID });

    this.chatSessies = this.chatSessies!.map((sessie) => {
      if (sessie.ID === sesID) {
        return {
          ...sessie,
          berichten: result.map((x) => fromIOphalenChatBerichtResultElementToIBericht(x)),
        };
      }

      return sessie;
    });
  };

  public handleInkomendBericht = (bericht: IInboundMessageData) => {
    if (this.chatSessies === null) {
      return;
    }

    const bestaandeChatSessie = (this.chatSessies || []).find(
      (sessie) => sessie.ID === bericht.chatSessie.ID,
    );
    const berichten =
      bestaandeChatSessie === undefined || bestaandeChatSessie.berichten === null
        ? null
        : bestaandeChatSessie.berichten;

    const ber = fromIOphalenChatBerichtResultElementToIBericht(bericht.bericht);

    const chatSessie = fromIOphalenChatSessiesResultElementToIChatSessie(
      bericht.chatSessie,
      berichten === null ? null : [...berichten, ber],
    );

    if (bestaandeChatSessie !== undefined) {
      this.chatSessies = (this.chatSessies || []).map(
        (sessie): IChatSessie => {
          if (sessie.ID === chatSessie.ID) {
            return chatSessie;
          }

          return sessie;
        },
      );
    } else {
      this.chatSessies = [chatSessie, ...(this.chatSessies || [])];
    }
  };

  public handleWhatsappUpdateChatsessie = (data: IWhatsappUpdateChatsessieData) => {
    this.chatSessies = (this.chatSessies || [])
      .map(
        (sessie): IChatSessie => {
          if (sessie.ID === data.chatSessie.ID) {
            return fromIOphalenChatSessiesResultElementToIChatSessie(
              data.chatSessie,
              sessie.berichten || null,
            );
          }

          return sessie;
        },
      )
      .sort((left, right) => {
        // Descending
        return right.laatsteBericht.datum.getTime() - left.laatsteBericht.datum.getTime();
        // return left.laatsteBericht.datum.getTime() - right.laatsteBericht.datum.getTime();
      });
  };

  public setChatsessieState(chatsessieID: number, state: IChatsessieState | null) {
    if (state === null) {
      const s = { ...this.chatsessieState };
      delete s[chatsessieID];
      this.chatsessieState = s;
    } else {
      this.chatsessieState = { ...this.chatsessieState, [chatsessieID]: state };
    }
  }
}

export default WhatsappStore;
