import React from 'react';
import { Route, RouteComponentProps, Redirect } from 'react-router-dom';
import Service from './Service';
import Productadvies from './Productadvies';
import Leveringen from './Leveringen';

const Kwaliteitsbeheer = ({ match }: RouteComponentProps) => (
  <>
    <Route
      exact
      path={match.path}
      component={() => <Redirect to={`${match.path}/productadvies`} />}
    />

    <Route path={`${match.path}/productadvies`} component={Productadvies} />
    <Route path={`${match.path}/leveringen`} component={Leveringen} />
    {/* <Route path={`${match.path}/service`} component={Service} /> */}

    <Route exact={true} path={match.path} render={() => <h3>selecteer een subpagina</h3>} />
  </>
);

export default Kwaliteitsbeheer;
