import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import * as Yup from 'yup';
import {
  IOphalenBlogCategoriesInternResultElement,
  IWijzigenBlogCategorieParams,
} from '../../../../../../../shared/src/api/v2/blog';
import { IOphalenTekstenInAlleTalenResult } from '../../../../../../../shared/src/api/v2/tekst';
import ITaalTekst from '../../../../../../../shared/src/models/talen/ITaalTekst';
import api from '../../../../../api';
import LoadingSpinner from '../../../../../components/Gedeeld/LoadingSpinner';
import Dialoog from '../../../../../components/dialogen/Dialoog';
import MeertaligTekstveld from '../../../../../components/formulier/MeertaligTekstveld';
import IDialoogProps from '../../../../../core/IDialoogProps';
import nameof from '../../../../../core/nameOf';
import IRemoteData, {
  ERemoteDataState,
  createPendingRemoteData,
  createReadyRemoteData,
} from '../../../../../models/IRemoteData';
import { EResultType } from '../../../../../stores/CheckStore';
import { RootStoreContext } from '../../../../../stores/RootStore';

interface IDialoogResult {}

interface IProps extends IDialoogProps<IDialoogResult> {
  id: number;
}

interface IFormikValues {
  ID: number;
  naam: ITaalTekst[];
  subtitel: ITaalTekst[];
}

const veldnamen: Record<keyof IFormikValues, string> = {
  ID: 'ID',
  naam: 'Naam',
  subtitel: 'Subtitel',
};

const WijzigenDialoog: React.FC<IProps> = observer((props) => {
  const { children, dialoogIndex, onAnnuleren, onSuccess } = props;
  const { checkStore } = useContext(RootStoreContext);
  const formikRef = useRef<Formik<IFormikValues>>(null);

  const [categorie, setCategorie] = useState<IOphalenBlogCategoriesInternResultElement | null>(
    null,
  );
  const [teksten, setTeksten] = useState<IRemoteData<IOphalenTekstenInAlleTalenResult>>(
    createPendingRemoteData(),
  );

  const ophalen = useCallback(async () => {
    const result = await api.v2.blog.ophalenBlogCategorie({
      filterschema: { filters: [{ naam: 'IDS', data: [props.id] }] },
    });
    setCategorie(result.categorieen[0]);
  }, [props.id]);

  useEffect(() => {
    ophalen();
  }, [ophalen]);

  const ophalenTeksten = useCallback(async () => {
    if (categorie === null) {
      if (teksten.state === ERemoteDataState.Ready) {
        setTeksten(createPendingRemoteData());
      }
      return;
    }

    const tekstIDs = [categorie.Naam_TekstID, categorie.Subtitel_TekstID].filter(
      (x) => x !== null,
    ) as number[];
    const result = await api.v2.tekst.ophalenTekstenInAlleTalen({
      tekstIDs,
    });
    setTeksten(createReadyRemoteData(result));
  }, [categorie]);

  useEffect(() => {
    ophalenTeksten();
  }, [ophalenTeksten]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      if (categorie === null) {
        return;
      }

      actions.setSubmitting(true);

      const params: IWijzigenBlogCategorieParams = {
        ID: categorie.ID,
        Naam: {
          tekstID: categorie.Naam_TekstID,
          taalTeksten: values.naam,
        },
        Subtitel: {
          tekstID: categorie.Subtitel_TekstID,
          taalTeksten: values.subtitel,
        },
      };

      const checkData = await api.v2.blog.checkWijzigenBlogitem(params);
      const checkResult = await checkStore.controleren({
        checkData,
      });
      if (checkResult.type === EResultType.Annuleren) {
        onAnnuleren();
        return;
      }

      await api.v2.blog.wijzigenBlogCategorie(params);

      actions.setSubmitting(false);
      props.onSuccess({});
    },
    [categorie],
  );

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (categorie === null || teksten.state === ERemoteDataState.Pending) {
      return null;
    }
    const naamTeksten =
      categorie.Naam_TekstID === null
        ? null
        : teksten.data!.teksten.filter((x) => x.TekstID === categorie.Naam_TekstID);

    const subtitelTeksten =
      categorie.Subtitel_TekstID === null
        ? null
        : teksten.data!.teksten.filter((x) => x.TekstID === categorie.Subtitel_TekstID);

    return {
      ID: categorie.ID,
      naam:
        naamTeksten === null
          ? []
          : naamTeksten.map((x) => ({
              tekst: x.Tekst ?? '',
              taalID: x.TaalID,
              toepassen: x.Toepassen,
            })),
      subtitel:
        subtitelTeksten === null
          ? []
          : subtitelTeksten.map((x) => ({
              tekst: x.Tekst ?? '',
              taalID: x.TaalID,
              toepassen: x.Toepassen,
            })),
    };
  }, [categorie, teksten]);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        ID: Yup.string().required(),
      }),
    [],
  );

  return (
    <Dialoog index={dialoogIndex || 0} modalProps={{ size: 'lg' }}>
      <ModalHeader>
        <ModalTitle>Wijzigen Blog Categorie</ModalTitle>
      </ModalHeader>
      {initialValues === null ? (
        <LoadingSpinner />
      ) : (
        <Formik<IFormikValues>
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize
          isInitialValid
          render={(formikProps: FormikProps<IFormikValues>) => {
            const { submitForm, isValid, isSubmitting } = formikProps;

            return (
              <>
                <ModalBody>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-12 mt-3">
                        <label>Naam</label>
                        <Field
                          name={nameof<IFormikValues>('naam')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            return (
                              <MeertaligTekstveld
                                waarden={fieldProps.field.value}
                                onChange={(x) =>
                                  fieldProps.form.setFieldValue(fieldProps.field.name, x)
                                }
                                defaultUitgeklapt
                                inputComponent={'multiline'}
                                multilineConfig={{ rows: 1 }}
                              />
                            );
                          }}
                        />
                      </div>
                      <div className="col-12 mt-3">
                        <label>Subtitel</label>
                        <Field
                          name={nameof<IFormikValues>('subtitel')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            return (
                              <MeertaligTekstveld
                                waarden={fieldProps.field.value}
                                onChange={(x) =>
                                  fieldProps.form.setFieldValue(fieldProps.field.name, x)
                                }
                                defaultUitgeklapt
                                inputComponent={'multiline'}
                                multilineConfig={{ rows: 1 }}
                              />
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </ModalBody>

                <ModalFooter className="d-flex flex-row justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    style={{ width: 100 }}
                    disabled={!isValid || isSubmitting}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={onAnnuleren}
                    style={{ width: 100 }}
                    disabled={isSubmitting}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Dialoog>
  );
});

export default WijzigenDialoog;
