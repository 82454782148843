import React, { useState, useCallback, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { IRapportageDetailProps } from '../../index';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../../../models/IRemoteData';
import {
  ILijstActivaregisterResult,
  ILijstActivaregisterResultElement,
  ILijstProductenUitBedrijfResult,
  ILijstProductenUitBedrijfResultElement,
} from '../../../../../../../../shared/src/api/v2/boekhouding/rapportage';
import api from '../../../../../../api';
import { ASPKolom, EAspKolomBreedteType } from '../../../../../../components/tabel/ASPTabel/types';
import FormatteerBedrag from '../../../../../../components/MutatieBedrag';
import { differenceInMonths, format } from 'date-fns';
import LoadingSpinner from '../../../../../../components/Gedeeld/LoadingSpinner';
import ASPTabel from '../../../../../../components/tabel/ASPTabel';
import { difference } from 'lodash';

export enum EKolom {
  Soort,
  Merk,
  Type,
  DatumGeactiveerd,
  Aanschafwaarde,
  DatumUitBedrijf,
  Restwaarde,
  LeeftijdBijUitBedrijf,
  Referentiecode,
  Boekstuknummers,
}

const Root = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const LijstProductenUitBedrijf = (props: IRapportageDetailProps) => {
  const [productenUitBedrijf, setProductenUitBedrijf] = useState<
    IRemoteData<ILijstProductenUitBedrijfResult>
  >(createPendingRemoteData());

  const ophalenLijstProductenUitBedrijf = useCallback(async () => {
    const result = await api.v2.boekhouding.rapportage.lijstProductenUitBedrijf({
      jaar: props.jaar,
      maandVem: props.maandVem,
      maandTem: props.maandTem,
    });
    setProductenUitBedrijf(createReadyRemoteData(result));
  }, [props.jaar, props.maandVem, props.maandTem]);

  useEffect(() => {
    ophalenLijstProductenUitBedrijf();
  }, [ophalenLijstProductenUitBedrijf]);

  const keyExtractor = useCallback((regel: ILijstProductenUitBedrijfResultElement) => regel.id, []);

  const kolommen = useMemo<ASPKolom<EKolom, ILijstProductenUitBedrijfResultElement>[]>(
    () => [
      {
        key: EKolom.Soort,
        label: 'Soort',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 150,
        renderer: (rij) => rij.Productsoortnaam,
      },
      {
        key: EKolom.Merk,
        label: 'Merk',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 110,
        renderer: (rij) => rij.Merknaam,
      },
      {
        key: EKolom.Type,
        label: 'Type',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 170,
        renderer: (rij) => rij.Typenaam,
      },
      {
        key: EKolom.Referentiecode,
        label: 'Ref.code',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 100,
        renderer: (rij) => rij.Referentiecode,
      },
      {
        key: EKolom.DatumGeactiveerd,
        label: 'Geactiveerd op',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 135,
        renderer: (rij) => format(new Date(rij.DatumGeactiveerd), 'dd-MM-yyyy'),
      },
      {
        key: EKolom.Aanschafwaarde,
        label: 'Aanschafwaarde',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 125,
        renderer: (rij) => <FormatteerBedrag bedrag={rij.Aanschafwaarde} />,
      },
      // {
      //   key: EKolom.Restwaarde,
      //   label: 'Restwaarde',
      //   breedteType: EAspKolomBreedteType.Vast,
      //   vasteBreedte: 125,
      //   renderer: (rij) => <FormatteerBedrag bedrag={rij.Restwaarde} />,
      // },
      {
        key: EKolom.DatumUitBedrijf,
        label: 'Uit bedrijf',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 120,
        renderer: (rij) => format(new Date(rij.DatumUitBedrijf), 'dd-MM-yyyy'),
      },
      {
        key: EKolom.LeeftijdBijUitBedrijf,
        label: 'Lft UB (mnd)',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 120,
        renderer: (rij) =>
          differenceInMonths(new Date(rij.DatumUitBedrijf), new Date(rij.DatumGeactiveerd)),
      },
      {
        key: EKolom.Boekstuknummers,
        label: 'Boekstuknummers',
        breedteType: EAspKolomBreedteType.Flex,
        flex: 1,
        renderer: (rij) => {
          const boekstuknummers = rij.facturen
            .map((x) => {
              const jaar = new Date(x.Factuurdatum).getFullYear();

              const boekstuknummer = 10000 * jaar + x.Boekstuknummer;
              return boekstuknummer.toString();
            })
            .join(', ');
          return boekstuknummers;
        },
      },
    ],
    [],
  );

  return (
    <Root>
      {productenUitBedrijf.state === ERemoteDataState.Pending ? (
        <div className="flex-fill d-flex align-items-center justify-content-center">
          <LoadingSpinner />
        </div>
      ) : (
        <ASPTabel
          rijen={productenUitBedrijf.data!.regels}
          kolommen={kolommen}
          keyExtractor={keyExtractor}
        />
      )}
    </Root>
  );
};

export default LijstProductenUitBedrijf;
