import React, { forwardRef, useContext } from 'react';
import { CommunicatieOverlayContext } from '../../index';
import { observer } from 'mobx-react-lite';
import WhatsAppWerkbladV2 from '../../../../components/communicatie/WhatsAppWerkbladV2';

interface IProps {}

const WhatsappTabblad = observer(
  forwardRef<{}, IProps>((props, forwardedRef) => {
    const context = useContext(CommunicatieOverlayContext).whatsappContext;

    return (
      <WhatsAppWerkbladV2
        tabblad={context.tabblad}
        onTabbladChange={context.onTabbladChange}
        chatsessieID={context.chatSessieID}
        onChatsessieIDChange={context.onChatSessieIDChange}
        zoekterm={context.zoekterm}
        onZoektermChange={context.onZoektermChange}
        zoekenFocus={context.zoekenFocus}
        onZoekenFocusChange={context.onZoekenFocusChange}
      />
    );

    // return (
    //   <WhatsAppWerkblad
    //     tabblad={context.tabblad}
    //     onTabbladChange={context.onTabbladChange}
    //     chatSessieID={context.chatSessieID}
    //     onChatSessieIDChange={context.onChatSessieIDChange}
    //     zoekterm={context.zoekterm}
    //     onZoektermChange={context.onZoektermChange}
    //     topOffset={82}
    //   />
    // );
  }),
);

export default WhatsappTabblad;
