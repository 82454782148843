import React, { useCallback } from 'react';

const slugify = (value: string): string | null => {
  const newValue = value.toLowerCase().replace(/ /g, '-');
  if (newValue.match(/[^a-z0-9-]/)) {
    return null;
  }
  return newValue;
};

interface IProps {
  slug: string;
  onChange: (slug: string) => void;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
}

const SlugInput = (props: IProps) => {
  const { slug, onChange } = props;

  const handleChange = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      const value = ev.target.value;
      const newValue = slugify(value);
      if (newValue === null) {
        return;
      }
      onChange(newValue);
    },
    [onChange],
  );

  return (
    <input
      className="form-control"
      type="text"
      value={slug}
      onChange={handleChange}
      {...props.inputProps}
    />
  );
};

interface IMultilineProps {
  slug: string;
  onChange: (slug: string) => void;
  textAreaProps?: React.InputHTMLAttributes<HTMLTextAreaElement>;
}

export const MultilineSlugInput = (props: IMultilineProps) => {
  const { slug, onChange } = props;

  const handleChange = useCallback(
    (ev: React.ChangeEvent<HTMLTextAreaElement>) => {
      const value = ev.target.value;
      const newValue = slugify(value);
      if (newValue === null) {
        return;
      }
      onChange(newValue);
    },
    [onChange],
  );

  return (
    <textarea
      className="form-control"
      value={slug}
      onChange={handleChange}
      {...props.textAreaProps}
    />
  );
};

export default SlugInput;
