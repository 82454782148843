import {
  Grid,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  TableRowDetail,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import { EditingState, VirtualTable as VirtualTableBase } from '@devexpress/dx-react-grid';
import React, { useContext, useMemo, useState } from 'react';
import api from '../../../../../../../../../api';
import { IKolom } from '../../../../../../../../../components/formulier/MultiCombobox';
import GegevensLayout from '../../../../../../../../../components/layout/GegevensLayout';
import VerticaleScheidingslijn from '../../../../../../../../../components/layout/VerticaleScheidingslijn';
import {
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../../../../../../helpers/dxTableGrid';
import { RootStoreContext } from '../../../../../../../../../stores/RootStore';
import { ISectieProps } from '../index';
import {
  IOphalenFacturenBasisResultElement,
  IRegel as IOphalenFactuurregelsResultElement,
} from '../../../../../../../../../../../shared/src/api/v2/factuur';
import RowDetail from '../Transport/Tabel/RowDetail';
import { DataTypeProvider, RowDetailState } from '@devexpress/dx-react-grid';
import nameOf from '../../../../../../../../../core/nameOf';
import FormatteerBedrag from '../../../../../../../../../components/MutatieBedrag';
import { format } from 'date-fns';
import FactuurVisualisatie from '../../../../../../../../../components/entiteitVisualisaties/FactuurVisualisatie';
import _ from 'lodash';
import { EResultType } from '../../../../../../../../../stores/CheckStore';
import NieuweVorderingDialoog from '../../../../../../../../Facturering/Vorderingen/NieuweVorderingDialoog';
import WijzigenVorderingDialoog from '../../../../../../../../Facturering/Vorderingen/WijzigenVorderingDialoog';
import FactuurinfoDialoog from '../../../../../../../../../components/factuur/FactuurinfoDialoog';
import { Kleur as EKleur } from '../../../../../../../../../bedrijfslogica/constanten';
import { EFunctioneleIcon, functioneleIconMap } from '../../../../../../../../../components/Icons';

interface IProps extends ISectieProps {}

interface IFactuurregel extends IOphalenFactuurregelsResultElement {
  factuur: IOphalenFacturenBasisResultElement | null;
}

interface INieuweVorderingDialoogState {
  cntID: number;
}
interface IWijzigenVorderingDialoogState {
  factRegID: number;
}
interface IFactuurinfoDialoogState {
  factID: number;
}

const IconProductInfo = functioneleIconMap[EFunctioneleIcon.ProductInfo];

const Financieel: React.FC<IProps> = (props) => {
  const { checkStore } = useContext(RootStoreContext);

  const contract = props.contract!;
  const vorderingen = props.vorderingen;

  const [
    nieuweVorderingDialoogState,
    setNieuweVorderingDialoogState,
  ] = useState<INieuweVorderingDialoogState | null>(null);
  const [
    wijzigenVorderingDialoogState,
    setWijzigenVorderingDialoogState,
  ] = useState<IWijzigenVorderingDialoogState | null>(null);
  const [
    factuurinfoDialoogState,
    setFactuurinfoDialoogState,
  ] = useState<IFactuurinfoDialoogState | null>(null);

  // Factuurregels aangevuld met de factuur indien aanwezig
  const factuurregels = _.orderBy(
    vorderingen.map((x) => {
      const factuur = props.facturen.find((f) => f.FactID === x.FactID) ?? null;
      return { ...x, factuur };
    }),
    [(x: any) => (x.factuur !== null ? x.factuur.Factuurdatum : null)],
    ['desc'],
  );

  const { klantkaartStore } = useContext(RootStoreContext);
  klantkaartStore.ophalenRelatie(contract.RelID);
  const factuurniveau = klantkaartStore.relatie!.debiteur!.FactuurNiveau;

  // Rekening bepalen (Afwijkend of Standaard?)
  let rekening: { iban: string; rekeningnaam: string; afwijkend: boolean } | null = null;
  if (contract.rekening !== null) {
    rekening = {
      iban: contract.rekening.IBAN,
      rekeningnaam: contract.rekening.Rekeningnaam,
      afwijkend: true,
    };
  } else {
    const rekeningen = klantkaartStore.relatie!.financieel.rekeningen;
    const standaardRelRekID = klantkaartStore.relatie!.financieel.StandaardRekening_RelRekID;
    const standaardRekening = rekeningen.find((x) => x.RelRekID === standaardRelRekID) ?? null;
    rekening =
      standaardRekening !== null
        ? {
            iban: standaardRekening.IBAN,
            rekeningnaam: standaardRekening.Rekeningnaam,
            afwijkend: false,
          }
        : null;
  }

  const vorderingKolommen = useMemo<TypedColumn<IFactuurregel>[]>(() => {
    return [
      {
        name: '__factuurnummer' as any,
        title: 'Factuur',
      },
      {
        name: '__factuurdatum' as any,
        title: 'Factuurdatum',
      },
      {
        name: '__factuurInfo' as any,
        title: ' ',
      },
      {
        name: 'Omschrijving',
        title: 'Omschrijving',
      },
      {
        name: 'Bedrag',
        title: 'Bedrag in factuur',
      },
      {
        name: 'periodeVan',
        title: 'Periode van',
      },
      {
        name: 'periodeTot',
        title: 'Periode tot',
      },
    ];
  }, []);

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IFactuurregel>[]>(
    () => [
      {
        columnName: 'Bedrag',
        width: 125,
      },
      {
        columnName: 'Omschrijving',
        width: 275,
      },
      {
        columnName: 'periodeVan',
        width: 90,
      },
      {
        columnName: 'periodeTot',
        width: 90,
      },
      {
        columnName: '__factuurnummer' as any,
        width: 100,
      },
      {
        columnName: '__factuurdatum' as any,
        width: 135,
      },
      {
        columnName: '__factuurInfo' as any,
        width: 75,
      },
    ],
    [],
  );
  const kolomExtensies: VirtualTableBase.ColumnExtension[] = useMemo(() => {
    return [
      // {
      //   columnName: `Bedrag`,
      //   align: 'right',
      // },
    ];
  }, []);

  return (
    <>
      <div>
        <GridStyleWrapper maxHeight={200} rowAmount={vorderingen.length}>
          <Grid rows={factuurregels} columns={vorderingKolommen} getRowId={(x) => x.FactRegID}>
            <DataTypeProvider
              for={[nameOf<IFactuurregel>('Bedrag')]}
              formatterComponent={(props) => {
                const rij: IFactuurregel = props.row;
                return <FormatteerBedrag bedrag={rij.Bedrag} />;
              }}
            />
            <DataTypeProvider
              for={[nameOf<IFactuurregel>('periodeVan'), nameOf<IFactuurregel>('periodeTot')]}
              formatterComponent={(formatterProps) => {
                return (
                  <span>
                    {formatterProps.value !== null
                      ? format(new Date(formatterProps.value), 'dd-MM-yyyy')
                      : ''}
                  </span>
                );
              }}
            />
            <DataTypeProvider
              for={['__factuurnummer']}
              formatterComponent={(formatterProps) => {
                const rij: IFactuurregel = formatterProps.row;

                if (rij.factuur === null) {
                  return <span></span>;
                }
                return <FactuurVisualisatie factID={rij.FactID} />;
              }}
            />

            <DataTypeProvider
              for={['__factuurdatum']}
              formatterComponent={(formatterProps) => {
                const rij: any = formatterProps.row;

                if (rij.factuur === null) {
                  return <span></span>;
                }
                return <span>{format(new Date(rij.factuur.Factuurdatum), 'dd-MM-yyyy')}</span>;
              }}
            />

            <DataTypeProvider
              for={['__factuurInfo']}
              formatterComponent={(formatterProps) => {
                const rij: IFactuurregel = formatterProps.row;
                return (
                  <a
                    href="#"
                    className="ml-1"
                    style={{ color: EKleur.LichtBlauw, position: 'relative', bottom: 2 }}
                    onClick={() => {
                      setFactuurinfoDialoogState({ factID: rij.FactID });
                    }}
                  >
                    <IconProductInfo style={{ width: 15, height: 15, fill: EKleur.Blauw }} />
                  </a>
                );
              }}
            />

            <VirtualTable columnExtensions={kolomExtensies} />
            <TableColumnResizing defaultColumnWidths={kolomBreedtes} />

            <EditingState
              onAddedRowsChange={() => {
                setNieuweVorderingDialoogState({ cntID: props.contract.CntID });
              }}
              onCommitChanges={async (changes) => {
                if (changes.deleted === undefined) {
                  return;
                }
                const deleted = changes.deleted;
                const id = deleted[deleted.length - 1] as number;

                const checkData = await api.v2.vordering.checkVerwijderenVorderingen({
                  factRegIDs: [id],
                });
                if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
                  return;
                }
                if (
                  (
                    await checkStore.bevestigen({
                      inhoud: (
                        <span>
                          Wil je de vorderingen verwijderen?
                          <br />
                          <br />
                          Let op: Dit kan niet meer ongedaan gemaakt worden{' '}
                        </span>
                      ),
                    })
                  ).type === EResultType.Annuleren
                ) {
                  return;
                }

                await api.v2.vordering.verwijderenVorderingen({
                  factRegIDs: [id],
                });

                props.vernieuwenContracten();
              }}
              onEditingRowIdsChange={async (rowIds) => {
                const id = rowIds[rowIds.length - 1] as number;

                const checkData = await api.v2.vordering.checkSelectieWijzigenVordering({
                  factRegID: id,
                });
                const checkResult = await checkStore.controleren({
                  checkData,
                });
                if (checkResult.type === EResultType.Annuleren) {
                  return;
                }

                setWijzigenVorderingDialoogState({ factRegID: id });
              }}
            />

            {/* <TableEditColumn
              width={65}
              showAddCommand
              showDeleteCommand
              showEditCommand
              cellComponent={DXTableEditColumnCellComponent}
              commandComponent={DXTableEditColumnCommandComponent}
            /> */}

            {/* <RowDetailState defaultExpandedRowIds={[]} /> */}
            {/* <TableRowDetail /> */}
            <TableHeaderRow />
          </Grid>
        </GridStyleWrapper>
      </div>
      {nieuweVorderingDialoogState !== null && (
        <NieuweVorderingDialoog
          open
          relID={props.contract.RelID}
          cntID={nieuweVorderingDialoogState.cntID}
          onSuccess={() => {
            {
              setNieuweVorderingDialoogState(null);
              props.vernieuwenContracten();
            }
          }}
          onAnnuleren={() => setNieuweVorderingDialoogState(null)}
        />
      )}
      {wijzigenVorderingDialoogState !== null && (
        <WijzigenVorderingDialoog
          open
          factRegID={wijzigenVorderingDialoogState.factRegID}
          onAnnuleren={() => setWijzigenVorderingDialoogState(null)}
          onSuccess={() => {
            props.vernieuwenContracten();
            setWijzigenVorderingDialoogState(null);
          }}
        />
      )}
      {factuurinfoDialoogState !== null && (
        <FactuurinfoDialoog
          open
          factID={factuurinfoDialoogState.factID}
          onSuccess={() => {
            setFactuurinfoDialoogState(null);
          }}
          onAnnuleren={() => setFactuurinfoDialoogState(null)}
        />
      )}
    </>
  );
};

export default Financieel;
