import React from 'react';
import styled from 'styled-components';
import { Kleur } from '../../../../bedrijfslogica/constanten';

const Root = styled.div`
  border-radius: 4px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px ${Kleur.LichtGrijs};
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Heading = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 25px;
`;

const Naam = styled.div`
  font-size: 15px;
  flex: 1;
`;

const Aantal = styled.div`
  font-weight: bolder;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Footer = styled.div`
  padding: 10px 25px;
  display: flex;
  justify-content: flex-end;
`;

interface IProps {
  naam: string;
  aantal: number;
  onAllesWeergeven?: () => void;
}

const Entiteit: React.FC<IProps> = (props) => {
  return (
    <Root>
      <Heading>
        <Naam>{props.naam}</Naam>
        <Aantal>{props.aantal}</Aantal>
      </Heading>
      <Body>{props.children}</Body>
      <Footer>{props.onAllesWeergeven !== undefined && <a href="#">Alles weergeven</a>}</Footer>
    </Root>
  );
};

export default Entiteit;
