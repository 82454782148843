import React from 'react';
import { Root } from './style';

interface IProps {
  gegevens: Array<IGegeven | null>;
  labelBreedte?: number | string;
}

export enum EUitlijning {
  Links,
  Rechts,
}

export interface IGegeven {
  label: JSX.Element | string | null;
  waarde: JSX.Element | string | number | null;
  uitlijning?: EUitlijning;
}

const GegevensLayout: React.FC<IProps> = (props) => {
  const { gegevens } = props;

  return (
    <Root>
      {gegevens.map((gegeven, i) => {
        if (gegeven === null) {
          return (
            <tr key={i}>
              <td style={{ width: props.labelBreedte }}>&nbsp;</td>
            </tr>
          );
        }

        return (
          <tr key={i}>
            <td style={{ verticalAlign: 'top', width: props.labelBreedte }}>{gegeven.label}:</td>
            <td
              style={{
                paddingLeft: 10,
                textAlign:
                  gegeven.uitlijning !== undefined && gegeven.uitlijning === EUitlijning.Rechts
                    ? 'right'
                    : 'left',
              }}
            >
              {gegeven.waarde}
            </td>
          </tr>
        );
      })}
    </Root>
  );
};

export default GegevensLayout;
