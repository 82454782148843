import LoadingSpinner from '../../../../../../../components/Gedeeld/LoadingSpinner';
import React, { useCallback, useEffect, useMemo } from 'react';
import { IWeergaveProps } from '../../../../../../../components/FilterBalkV2';
import { ERemoteDataState } from '../../../../../../../models/IRemoteData';
import { useOverzichtV2Store } from './store';
import { ILocatieIntern } from '../../../../../../../../../shared/src/api/v2/relatie/contract';
import MultiComboboxV2, {
  EnkeleProvider,
  IRepresentatieProps,
  Provider,
} from '../../../../../../../components/formulier/MultiComboboxV2';
import {
  ASPKolom,
  EAspKolomBreedteType,
} from '../../../../../../../components/tabel/ASPTabel/types';
import MultiComboboxV2MetZoeken, {
  IMultiComboboxV2MetZoekenOverlayState,
} from '../../../../../../../components/formulier/MultiComboboxV2/MetZoeken';

type Kolom = 'Naam';

const InterneLocatieFilter = (weergaveProps: IWeergaveProps<any>) => {
  const store = useOverzichtV2Store();

  const interneLocatieNamen = weergaveProps.data as string[];
  const interneLocatieNaam = useMemo<string | null>(
    () => (interneLocatieNamen.length === 0 ? null : interneLocatieNamen[0]),
    [interneLocatieNamen],
  );
  const mogelijkeInterneLocatieNamen = useMemo<string[] | null>(() => {
    if (store.data.state === ERemoteDataState.Pending) {
      return null;
    }
    return store.data.data!.result.locatiesIntern.map((x: ILocatieIntern) => x.Naam);
  }, [store.data]);

  useEffect(() => {
    // Gekozen locID bevindt zich niet meer in de mogelijke locIDs (e.g. RelID is veranderd)
    if (
      interneLocatieNaam !== null &&
      mogelijkeInterneLocatieNamen !== null &&
      mogelijkeInterneLocatieNamen.indexOf(interneLocatieNaam) === -1
    ) {
      weergaveProps.onDataChange([]);
      weergaveProps.toepassen();
    }
  }, [interneLocatieNaam, mogelijkeInterneLocatieNamen]);

  return (
    <span className="d-flex align-items-center">
      <span className="mr-2" style={{ minWidth: 95 }}>
        Interne Locatie
      </span>
      {store.data.state === ERemoteDataState.Pending ? (
        <LoadingSpinner />
      ) : (
        <InterneLocatieSelectie
          waarde={interneLocatieNaam}
          onWaardeChange={(x) => {
            const nieuweInterneLocatieNamen = x === null ? [] : [x];
            weergaveProps.onDataChange(nieuweInterneLocatieNamen);
            weergaveProps.setIsActief(true);
            weergaveProps.toepassen();
          }}
          interneLocaties={store.data.data!.result.locatiesIntern}
        />
      )}
    </span>
  );
};

interface IInterneLocatieSelectieProps {
  waarde: string | null;
  onWaardeChange: (waarde: string | null) => void;
  interneLocaties: ILocatieIntern[];
}

const InterneLocatieSelectie = (props: IInterneLocatieSelectieProps) => {
  const provider = useMemo<Provider<Kolom, ILocatieIntern, IMultiComboboxV2MetZoekenOverlayState>>(
    () => async (params) => {
      const items = props.interneLocaties
        .filter((interneLocatie) => {
          const zoekterm = params.overlayContainerState.zoekterm.toLowerCase();
          if (zoekterm.length > 0) {
            return interneLocatie.Naam.toLowerCase().includes(zoekterm);
          }
          return true;
        })
        .reduce<Record<number, ILocatieIntern>>((acc, curr, i) => {
          acc[i] = curr;
          return acc;
        }, {});

      return {
        items,
        totaalAantal: Object.keys(items).length,
      };
    },
    [props.interneLocaties],
  );
  const enkeleProvider = useMemo<EnkeleProvider<string, ILocatieIntern>>(
    () => async (naam) => {
      return props.interneLocaties.find((x) => x.Naam === naam)!;
    },
    [props.interneLocaties],
  );
  const keyExtractor = useCallback((x: ILocatieIntern) => x.Naam, []);

  const kolommen = useMemo<ASPKolom<Kolom, ILocatieIntern>[]>(
    () => [
      {
        key: 'Naam',
        label: 'Naam',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 250,
        renderer: (x) => x.Naam,
      },
    ],
    [],
  );

  return (
    <MultiComboboxV2MetZoeken
      provider={provider}
      enkeleProvider={enkeleProvider}
      keyExtractor={keyExtractor}
      waarde={props.waarde}
      onChange={props.onWaardeChange}
      representatieComponent={InterneLocatieSelectieRepresentatie}
      kolommen={kolommen}
      style={{ minWidth: 175 }}
    />
  );
};

const InterneLocatieSelectieRepresentatie = (props: IRepresentatieProps<ILocatieIntern>) => {
  return <span>{props.entiteit.Naam}</span>;
};

export default InterneLocatieFilter;
