import React, { useContext, useMemo, useState } from 'react';
import VeldWeergave from '../../formulier/VeldWeergave';
import LoadingSpinner from '../../Gedeeld/LoadingSpinner';
import { format } from 'date-fns';
import GegevensLayout from '../../layout/GegevensLayout';
import {
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../helpers/dxTableGrid';
import { Kleur } from '../../../bedrijfslogica/constanten';
import {
  Grid,
  Table,
  TableColumnResizing,
  TableHeaderRow,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import _ from 'lodash';
import { IOphalenVerkoopVoorstellenResultElement } from '../../../../../shared/src/api/v2/product/verkoop';
import nameOf from '../../../core/nameOf';
import FormatteerBedrag from '../../MutatieBedrag';

export interface IRegel extends IOphalenVerkoopVoorstellenResultElement {}

interface IProps {
  verkoopvoorstellen: IRegel[];
}

const VerkoopvoorstellenComp: React.FC<IProps> = (props) => {
  const kolommen = useMemo<TypedColumn<IRegel>[]>(
    () => [
      {
        name: 'GeldigVan',
        title: 'Geldig van',
      },
      {
        name: 'GeldigTot',
        title: 'Geldig tot',
      },
      {
        name: 'Bedrag',
        title: 'Bedrag',
      },
      {
        name: '__status' as any,
        title: 'Status',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRegel>[]>(
    () => [
      {
        columnName: 'GeldigVan',
        width: 110,
      },
      {
        columnName: 'GeldigTot',
        width: 110,
      },
      {
        columnName: 'Bedrag',
        width: 100,
        align: 'right',
      },
      {
        columnName: '__status' as any,
        width: 110,
      },
    ],
    [],
  );

  return (
    <>
      <VeldWeergave>
        <div className="p-1 font-weight-bold">Verkoopvoorstellen</div>
        <div className="mt-1" style={{ backgroundColor: Kleur.Wit }}>
          <GridStyleWrapper maxHeight={400} rowAmount={props.verkoopvoorstellen.length}>
            <Grid rows={props.verkoopvoorstellen} columns={kolommen}>
              <DataTypeProvider
                for={[nameOf<IOphalenVerkoopVoorstellenResultElement>('GeldigVan')]}
                formatterComponent={(formatterProps) => {
                  return (
                    <span>
                      {formatterProps.value === null
                        ? null
                        : format(new Date(formatterProps.value), 'dd-MM-yyyy')}
                    </span>
                  );
                }}
              />

              <DataTypeProvider
                for={[nameOf<IOphalenVerkoopVoorstellenResultElement>('GeldigTot')]}
                formatterComponent={(formatterProps) => {
                  return (
                    <span>
                      {formatterProps.value === null
                        ? null
                        : format(new Date(formatterProps.value), 'dd-MM-yyyy')}
                    </span>
                  );
                }}
              />

              <DataTypeProvider
                for={[nameOf<IOphalenVerkoopVoorstellenResultElement>('Bedrag')]}
                formatterComponent={(formatterProps) => (
                  <span>
                    <FormatteerBedrag bedrag={formatterProps.value} />
                  </span>
                )}
              />

              <DataTypeProvider
                for={['__status']}
                formatterComponent={(formatterProps) => {
                  if (formatterProps.value === 1) {
                    return <span>Geaccepteerd</span>;
                  }
                  if (formatterProps.value === 2) {
                    return <span>Afgewezen</span>;
                  }
                  return <span>Onbekend</span>;
                }}
              />

              <VirtualTable messages={{ noData: 'Geen verkoopvoorstellen bekend' }} />
              <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
              <TableHeaderRow />
            </Grid>
          </GridStyleWrapper>
        </div>
      </VeldWeergave>
    </>
  );
};

export default VerkoopvoorstellenComp;
