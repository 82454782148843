import React from 'react';
import styled from 'styled-components';
import { EIdentiteitType, Identiteit } from '../../../types';
import RelatieIdentiteit from './RelatieIdentiteit';
import PersoonIdentiteit from './PersoonIdentiteit';
import OrganisatieIdentiteit from './OrganisatieIdentiteit';
import EmailGeadresseerdeIdentiteit from './EmailGeadresseerdeIdentiteit';
import IRemoteData, { ERemoteDataState } from '../../../../../../models/IRemoteData';
import Skeleton from 'react-loading-skeleton';
import { IOphalenRelatiesResultElementV2 } from '../../../../../../../../shared/src/api/v2/relatie';
import { IOphalenPersonenResultElementV2 } from '../../../../../../../../shared/src/api/v2/persoon/persoon';
import { IOphalenOrganisatiesResultElement } from '../../../../../../../../shared/src/api/v2/organisatie/organisatie';
import { IOphalenGeslachtenResultElement } from '../../../../../../../../shared/src/api/v2/geslacht';
import { IconAttachment } from '../../../../../Icons';
import { Kleur } from '../../../../../../bedrijfslogica/constanten';

const Root = styled.div`
  display: flex;
  min-height: 26px;
`;

const IdentiteitenContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  color: #222;
`;

const GeenIdentiteitenBekend = styled.span`
  font-style: italic;
`;

interface IProps {
  identeiten: IRemoteData<Identiteit[]>;
  relatiesCache: Record<number, IRemoteData<IOphalenRelatiesResultElementV2>>;
  personenCache: Record<number, IRemoteData<IOphalenPersonenResultElementV2>>;
  organisatiesCache: Record<number, IRemoteData<IOphalenOrganisatiesResultElement>>;
  heeftBijlage: boolean;
  onHerberekenenGrootte: () => void;
}

const Kop = (props: IProps) => {
  if (props.identeiten.state === ERemoteDataState.Pending) {
    return (
      <Root>
        <Skeleton width={175} />
      </Root>
    );
  }

  if (props.identeiten.data!.length === 0) {
    return (
      <Root>
        <GeenIdentiteitenBekend>Geen identiteiten bekend</GeenIdentiteitenBekend>
      </Root>
    );
  }

  return (
    <Root>
      <IdentiteitenContainer>
        {props.identeiten.data!.map((identiteit, i) => {
          let el;
          switch (identiteit.type) {
            case EIdentiteitType.Relatie:
              el = (
                <RelatieIdentiteit
                  identiteit={identiteit}
                  relatiesCache={props.relatiesCache}
                  onHerberekenenGrootte={props.onHerberekenenGrootte}
                />
              );
              break;
            case EIdentiteitType.Persoon:
              el = (
                <PersoonIdentiteit
                  identiteit={identiteit}
                  personenCache={props.personenCache}
                  onHerberekenenGrootte={props.onHerberekenenGrootte}
                />
              );
              break;
            case EIdentiteitType.Organisatie:
              el = (
                <OrganisatieIdentiteit
                  identiteit={identiteit}
                  organisatiesCache={props.organisatiesCache}
                  onHerberekenenGrootte={props.onHerberekenenGrootte}
                />
              );
              break;
            case EIdentiteitType.EmailGeadresseerde:
              el = <EmailGeadresseerdeIdentiteit identiteit={identiteit} />;
              break;
          }

          return (
            <div key={i} style={{ margin: 3, maxWidth: '100%' }}>
              {el}
            </div>
          );
        })}
      </IdentiteitenContainer>
      {props.heeftBijlage && (
        <IconAttachment style={{ fill: Kleur.Grijs, width: 18, height: 18 }} />
      )}
    </Root>
  );
};

export default Kop;
