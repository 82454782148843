import React, { useState, useEffect, useCallback, useContext, useMemo } from 'react';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../models/IRemoteData';
import {
  IOphalenPersonenResultElementV2,
  IOphalenPersonenResult,
  IOphalenPersonenRecentResult,
} from '../../../../../../shared/src/api/v2/persoon/persoon';
import api from '../../../../api';
import { RootStoreContext } from '../../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import LoadingSpinner from '../../../Gedeeld/LoadingSpinner';
import {
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import {
  Grid,
  VirtualTable,
  TableHeaderRow,
  TableColumnResizing,
} from '@devexpress/dx-react-grid-bootstrap4';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { formatteerPersoonNaam } from '../../../../helpers';
import { format } from 'date-fns';
import { PersoonSelectieDialoogContext } from '../index';

interface IProps {}

const RecentContent: React.FC<IProps> = observer((props) => {
  const persoonSelectieDialoogContext = useContext(PersoonSelectieDialoogContext);
  const { gebruikerStore } = useContext(RootStoreContext);

  const [recentResult, setRecentResult] = useState<IOphalenPersonenRecentResult | null>(null);
  const [personen, setPersonen] = useState<IRemoteData<IOphalenPersonenResult>>(
    createPendingRemoteData(),
  );
  const ophalenPersonen = useCallback(async () => {
    if (gebruikerStore.gebruiker === null) {
      setPersonen(createPendingRemoteData());
      return;
    }

    const recentResult = await api.v2.persoon.ophalenPersonenRecent({
      filterSchema: {
        filters: [
          {
            naam: 'ASPGEBR_IDS',
            data: [gebruikerStore.gebruiker!.AspGebrID],
          },
        ],
      },
    });
    setRecentResult(recentResult);

    const personenResult = await api.v2.persoon.ophalenPersonen({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: recentResult.personen.map((x) => x.PersID),
          },
        ],
      },
      // paginatie: {
      //   aantal: 50,
      //   index: 0,
      // },
    });

    const result = {
      personen: recentResult.personen.map((x) => {
        const persoon: IOphalenPersonenResultElementV2 = personenResult.personen.find(
          (p) => p.PersID === x.PersID,
        )!;
        return { ...persoon };
      }),
    };

    setPersonen(createReadyRemoteData(result));
  }, [gebruikerStore.gebruiker]);
  useEffect(() => {
    ophalenPersonen();
  }, [ophalenPersonen]);

  const keyExtractor = useCallback((row: IOphalenPersonenResultElementV2) => row.PersID, []);
  const kolommen = useMemo<TypedColumn<IOphalenPersonenResultElementV2>[]>(
    () => [
      {
        name: '__naam' as any,
        title: 'Naam',
      },
      {
        name: '__datum' as any,
        title: 'Datum',
      },
      {
        name: '__kies_actie',
        title: ' ',
      },
    ],
    [],
  );
  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IOphalenPersonenResultElementV2>[]>(
    () => [
      {
        columnName: '__naam' as any,
        width: 250,
      },
      {
        columnName: '__datum' as any,
        width: 150,
      },
      {
        columnName: '__kies_actie' as any,
        width: 80,
      },
    ],
    [],
  );

  return (
    <div className="d-flex flex-fill flex-column align-items-center justify-content-center">
      {personen.state === ERemoteDataState.Pending ? (
        <LoadingSpinner />
      ) : (
        <GridStyleWrapper rowAmount={personen.data!.personen.length} maxHeight={500}>
          <Grid rows={personen.data!.personen} columns={kolommen} getRowId={keyExtractor}>
            <DataTypeProvider
              for={['__naam']}
              formatterComponent={(formatterProps) => {
                const row: IOphalenPersonenResultElementV2 = formatterProps.row;
                return (
                  <span>
                    {formatteerPersoonNaam({
                      aanhefKort: row.geslacht.AanhefKort,
                      achternaam: row.Achternaam,
                      voorletters: row.Voorletters,
                      voornaam: row.Voornaam,
                      voorvoegsel: row.Voorvoegsel,
                    })}
                  </span>
                );
              }}
            />
            <DataTypeProvider
              for={['__datum']}
              formatterComponent={(formatterProps) => {
                const row: IOphalenPersonenResultElementV2 = formatterProps.row;
                const recent = recentResult!.personen.find((x) => x.PersID === row.PersID)!;
                return <span>{format(new Date(recent.DatumReferentie), 'dd-MM-yyyy HH:mm')}</span>;
              }}
            />

            <DataTypeProvider
              for={['__kies_actie']}
              formatterComponent={(formatterProps) => {
                const entiteit = formatterProps.row as IOphalenPersonenResultElementV2;
                return (
                  <a
                    href="#"
                    onClick={(ev) => {
                      ev.stopPropagation();
                      persoonSelectieDialoogContext.onPersIDChange(entiteit.PersID);
                    }}
                  >
                    Kies
                  </a>
                );
              }}
            />

            <VirtualTable
              rowComponent={(rowProps) => (
                <tr
                  // className=""
                  style={{
                    cursor: 'pointer',
                  }}
                  onDoubleClick={() => {
                    const entiteit: IOphalenPersonenResultElementV2 = rowProps.row;
                    persoonSelectieDialoogContext.onPersIDChange(entiteit.PersID);
                  }}
                >
                  {rowProps.children}
                </tr>
              )}
            />
            <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
            <TableHeaderRow />
          </Grid>
        </GridStyleWrapper>
      )}
    </div>
  );
});

export default RecentContent;
