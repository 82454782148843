import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { withRouter } from 'react-router';

import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  TableRowDetail,
  Toolbar,
  VirtualTable,
  Table,
  TableEditColumn,
  TableSelection,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  DXSearchPanel,
  DXTableCheckboxComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
  DXTableToggleCellComponent,
  DXCommandComponent,
} from '../../../../helpers/dxTableGrid';
import {
  Column,
  DataTypeProvider,
  EditingState,
  IntegratedFiltering,
  IntegratedSorting,
  RowDetailState,
  SearchState,
  SortingState,
  TableColumnWidthInfo,
  VirtualTable as VirtualTableBase,
} from '@devexpress/dx-react-grid';
import api from '../../../../api';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../../stores/RootStore';
import {
  IOphalenOpdrachtenResultElement,
  IOphalenSignaleringenOpdrachtenResultElement,
  IVerzamelenOpdrachtenResult,
} from '../../../../../../shared/src/api/v2/bank/opdracht/opdracht';
import { addDays, format } from 'date-fns';
import FormatteerBedrag from '../../../../components/MutatieBedrag';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import VerzamelModal from './VerzamelModal';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import {
  IconSend,
  IconVerwijderen,
  IconToevoegen,
  IconLock,
  IconUpload,
  functioneleIconMap,
  EFunctioneleIcon,
} from '../../../../components/Icons';
import { EResultType } from '../../../../stores/CheckStore';
import _ from 'lodash';
import RelatieVisualisatie from '../../../../components/personalia/RelatieVisualisatie';
import {
  StoplichtIndicatie,
  EStoplichtStatus,
} from '../../../../components/formulier/StoplichtIndicatie';
import MenuLayout from '../../../../components/MenuLayout';
import { RouteComponentProps } from 'react-router-dom';
import SelectieVak from '../../../../components/SelectieVak';
import WijzigOpdrachtDialoog from './WijzigOpdrachtDialoog';
import useUrlState from '../../../../core/useUrlState';
import { SelectionState } from '@devexpress/dx-react-grid';
import FilterBalkV2, {
  IFilter,
  IFilterData,
  maakFilterSchema,
} from '../../../../components/FilterBalkV2';
import { IFilterSchema } from '../../../../../../shared/src/models/filter';
import { dagDatum } from '../../../../helpers/datum';
import DatumKiezer from '../../../../components/formulier/DatumKiezer';
import Combobox, { IOptie } from '../../../../components/formulier/Combobox';
import BestandenDragAndDropDialoog from '../../../../components/BestandenDragAndDropDialoog';
import { IOphalenMedewerkersResultElement } from '../../../../../../shared/src/api/v2/medewerker';
import RowDetailComponent from './DetailComp';
import { IOphalenRelatiesResultElementV2 } from '../../../../../../shared/src/api/v2/relatie';
import PreviewBatchDialoog from './PreviewBatchDialoog';
import MultiCombobox, { IKolom } from '../../../../components/formulier/MultiCombobox';
import { IOphalenRekeningenResultElement } from '../../../../../../shared/src/api/v2/bank/bank';
import { EBankopdrachtsoort } from '../../../../bedrijfslogica/enums';
import { EHoedanigheid } from '../../../../components/personalia/RelatieSelectieDialoog';
import { Helmet } from 'react-helmet';

interface IProps extends RouteComponentProps {}

export interface IContext {
  onVerversenAangevraagd: () => void;
}
export const BankopdrachtContext = React.createContext<IContext>(null as any);

export interface IWijzigOpdrachtDialoogState {
  bankOpdID: number;
}

export enum EFilter {
  HeeftSignaleringen = 'HEEFT_SIGNALERINGEN',
  UitvoerdatumTotEnMet = 'UITVOERDATUM_TOT_EN_MET_OF_LEEG',
  Groep = 'GROEP',
  RekIDs = 'BANK_REK_IDS',
  Soort = 'SOORT',
  Vrijgeven = 'VRIJGEVEN',
}

enum EGroep {
  Debiteuren = 'D',
  Crediteuren = 'C',
  Medewerkers = 'M',
}

export interface IInlezenSalarisbetalingenDialoogState {}

export interface IPreviewDialoogState {
  bankOpdIDs: number[];
}

interface IUrlState {
  selectie: number[];
  uitgeklapt: number[];
  verzamelModalTonen: boolean;
  wijzigOpdrachtDialoogState: IWijzigOpdrachtDialoogState | null;
  filterData: IFilterData<EFilter>[];
  inlezenSalarisbetalingenDialoogState: IInlezenSalarisbetalingenDialoogState | null;
  previewDialoogState: IPreviewDialoogState | null;
}

const geenData = {
  noData: 'Geen opdrachten',
};

export interface IRegel extends IOphalenOpdrachtenResultElement {
  errors: string[];
  warnings: string[];
}

const IconPreview = functioneleIconMap[EFunctioneleIcon.Preview];

const Opdrachten: React.FC<IProps> = observer((props) => {
  const { checkStore, bankStore, instellingStore } = useContext(RootStoreContext);

  const defaultUrlState = useMemo<IUrlState>(() => {
    return {
      selectie: [],
      uitgeklapt: [],
      verzamelModalTonen: false,
      wijzigOpdrachtDialoogState: null,
      previewDialoogState: null,
      filterData: [
        {
          naam: EFilter.RekIDs,
          data: [instellingStore.BankRekID],
          isActief: true,
        },
        {
          naam: EFilter.HeeftSignaleringen,
          data: true,
          isActief: false,
        },
        {
          naam: EFilter.UitvoerdatumTotEnMet,
          data: dagDatum(addDays(new Date(), 2)).toISOString(),
          isActief: false,
        },
        {
          naam: EFilter.Groep,
          data: EGroep.Debiteuren,
          isActief: false,
        },
        {
          naam: EFilter.Soort,
          data: EBankopdrachtsoort.Incasso,
          isActief: false,
        },
        {
          naam: EFilter.Vrijgeven,
          data: 1,
          isActief: false,
        },
      ],
      inlezenSalarisbetalingenDialoogState: null,
    };
  }, [instellingStore.BankRekID]);

  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const [opdrachten, setOpdrachten] = useState<IRegel[] | null>(null);
  const [medewerkers, setMedewerkers] = useState<IOphalenMedewerkersResultElement[] | null>(null);
  const [relaties, setRelaties] = useState<IOphalenRelatiesResultElementV2[] | null>(null);
  const [signaleringenResult, setSignaleringenResult] = useState<
    IOphalenSignaleringenOpdrachtenResultElement[] | null
  >(null);

  const soortFilterOpties = useMemo<IOptie<EBankopdrachtsoort>[]>(
    () => [
      {
        id: EBankopdrachtsoort.Incasso,
        label: 'Incasso',
      },
      {
        id: EBankopdrachtsoort.Betaling,
        label: 'Betaling',
      },
    ],
    [],
  );

  const vrijgevenFilterOpties = useMemo<IOptie<number>[]>(
    () => [
      {
        id: 1,
        label: 'Ophouden',
      },
      {
        id: 0,
        label: 'Niet ophouden',
      },
    ],
    [],
  );

  const groepFilterOpties = useMemo<IOptie<EGroep>[]>(
    () => [
      {
        id: EGroep.Debiteuren,
        label: 'Debiteuren',
      },
      {
        id: EGroep.Crediteuren,
        label: 'Crediteuren',
      },
      {
        id: EGroep.Medewerkers,
        label: 'Medewerkers',
      },
    ],
    [],
  );

  const rekeningenKolommen = useMemo<IKolom<IOphalenRekeningenResultElement>[]>(
    () => [
      {
        key: 'IBAN',
        label: 'IBAN',
        breedte: 200,
      },
      {
        key: 'Saldo',
        label: 'Definitief Saldo',
        breedte: 150,
        formatFabriek: (x) => <FormatteerBedrag bedrag={x.Saldo} />,
      },
    ],
    [],
  );

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.RekIDs,
        altijdWeergevenInBalk: true,
        actiefMuteerbaar: false,
        weergave: (weergaveProps) => {
          const data: number[] = weergaveProps.data;
          const waarde = data.length === 0 ? null : data[0];

          return (
            <div className="d-flex align-items-center">
              <span>Rekening</span>
              <span className="ml-2">
                {bankStore.rekeningen === null ? (
                  <LoadingSpinner />
                ) : (
                  <MultiCombobox<number, IOphalenRekeningenResultElement>
                    sleutelExtractor={(x) => x.BankRekID}
                    representatieFabriek={(x) => (
                      <div className="d-flex flex-fill align-items-center">
                        <span>{x.IBAN}</span>
                        {/*<span style={{ marginLeft: 15, marginRight: 15 }}>-</span>*/}
                        {/*<span>*/}
                        {/*  <MutatieBedrag bedrag={x.Saldo} />*/}
                        {/*</span>*/}
                      </div>
                    )}
                    waarde={waarde}
                    onWaardeChange={(x) => {
                      weergaveProps.onDataChange(x === null ? [] : [x]);
                      weergaveProps.toepassen();
                    }}
                    opties={bankStore.rekeningen}
                    kolommen={rekeningenKolommen}
                  />
                )}
              </span>
            </div>
          );
        },
      },
      {
        naam: EFilter.UitvoerdatumTotEnMet,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          const waarde = weergaveProps.data === null ? null : new Date(weergaveProps.data);

          const minimaleDatum = addDays(new Date(), -1);
          const maximaleDatum = addDays(new Date(), +30);

          return (
            <div className="d-flex align-items-center">
              <span className="mr-2">Uitvoerdatum t/m:</span>
              <DatumKiezer
                waarde={waarde}
                onGewijzigd={(date) => {
                  weergaveProps.onDataChange(date === null ? null : date.toISOString());
                  weergaveProps.toepassen();
                }}
                determineValidDate={(date) => {
                  return date >= minimaleDatum && date <= maximaleDatum;
                }}
                determineNextValidDate={(date) => {
                  const nieuweDatum = addDays(date, +1);
                  if (nieuweDatum <= maximaleDatum) {
                    return nieuweDatum;
                  }
                  return null;
                }}
                determinePreviousValidDate={(date) => {
                  const nieuweDatum = addDays(date, -1);
                  if (nieuweDatum >= minimaleDatum) {
                    return nieuweDatum;
                  }
                  return null;
                }}
              />
            </div>
          );
        },
      },
      {
        naam: EFilter.Groep,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return (
            <div className="d-flex align-items-center">
              <span className="mr-2">Groep:</span>
              <Combobox
                geselecteerd={weergaveProps.data}
                onSelectieChange={(x) => {
                  weergaveProps.onDataChange(x);
                  weergaveProps.toepassen();
                }}
                opties={groepFilterOpties}
              />
            </div>
          );
        },
      },
      {
        naam: EFilter.HeeftSignaleringen,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return (
            <div className="d-flex align-items-center">
              <span>Alleen met een signalering</span>
            </div>
          );
        },
      },
      {
        naam: EFilter.Soort,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return (
            <div className="d-flex align-items-center">
              <span className="mr-2">Soort:</span>
              <Combobox
                geselecteerd={weergaveProps.data}
                onSelectieChange={(x) => {
                  weergaveProps.onDataChange(x);
                  weergaveProps.toepassen();
                }}
                opties={soortFilterOpties}
              />
            </div>
          );
        },
      },
      {
        naam: EFilter.Vrijgeven,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return (
            <div className="d-flex align-items-center">
              <span className="mr-2"></span>
              <Combobox
                geselecteerd={weergaveProps.data}
                onSelectieChange={(x) => {
                  weergaveProps.onDataChange(x);
                  weergaveProps.toepassen();
                }}
                opties={vrijgevenFilterOpties}
              />
            </div>
          );
        },
      },
    ],
    [
      groepFilterOpties,
      soortFilterOpties,
      vrijgevenFilterOpties,
      bankStore.rekeningen,
      rekeningenKolommen,
    ],
  );

  const [filterSchema, setFilterSchema] = useState<IFilterSchema>(
    useMemo(() => maakFilterSchema(urlState.filterData), []),
  );

  const ophalenOpdrachten = useCallback(async () => {
    const alleenMetSignaleringen =
      filterSchema.filters !== undefined &&
      filterSchema.filters.find((x) => x.naam === EFilter.HeeftSignaleringen) !== undefined;

    const { opdrachten: resultOpdrachten } = await api.v2.bank.opdracht.ophalenOpdrachten({
      filterSchema: {
        filters: [
          ...filterSchema.filters!,
          {
            naam: 'BATCH_IDS',
            data: null, // Niet in een batch zittend
          },
        ],
      },
    });

    const signaleringenResult = (
      await api.v2.bank.opdracht.ophalenSignaleringenOpdrachten({
        bankOpdIDs: resultOpdrachten.map((x) => x.BankOpdID),
      })
    ).opdrachten;

    const opdrachtenBasis = alleenMetSignaleringen
      ? resultOpdrachten.filter(
          (x) =>
            signaleringenResult
              .filter((x) => x.errors.length !== 0 || x.warnings.length !== 0)
              .map((x) => x.BankOpdID)
              .indexOf(x.BankOpdID) !== -1,
        )
      : resultOpdrachten;

    const opdrachten = opdrachtenBasis.map((x) => {
      const signalering = signaleringenResult.find((s) => s.BankOpdID === x.BankOpdID)!;
      return { ...x, errors: signalering.errors, warnings: signalering.warnings };
    });

    const result = _.orderBy(
      opdrachten,
      [
        (x: IOphalenOpdrachtenResultElement) => {
          return format(new Date(x.Uitvoerdatum!), 'yyyy-MM-dd');
        },
        (x: IOphalenOpdrachtenResultElement) => {
          return x.relatie !== null ? x.relatie.weergavenaam : '';
        },
        // 'RekeningnaamKortBedrijf',
        // (x: IOphalenOpdrachtenResultElement) => {
        //   return x.Bedrag > 0 ? 1 : 0;
        // },
      ],
      ['asc', 'asc'],
    );

    // Haal medewerkersinfo erbij
    const mdwIDs = resultOpdrachten.filter((x) => x.MdwID !== null).map((x) => x.MdwID);

    const medewerkers = (
      await api.v2.medewerker.ophalenMedewerkers({
        filterSchema: {
          filters: [
            {
              naam: 'IDS',
              data: mdwIDs,
            },
          ],
        },
      })
    ).medewerkers;

    const relIDs = opdrachten.filter((x) => x.RelID !== null).map((x) => x.RelID);
    const relatiesResult = await api.v2.relatie.ophalenRelaties({
      filterSchema: { filters: [{ naam: 'IDS', data: relIDs }] },
    });
    const relaties = relatiesResult.relaties;

    setOpdrachten(null); // ivm koppeling naar relaties
    setRelaties(relaties);
    setOpdrachten(result);
    setMedewerkers(medewerkers);
  }, [filterSchema]);

  useEffect(() => {
    ophalenOpdrachten();
  }, [ophalenOpdrachten]);

  const context = useMemo<IContext>(
    () => ({
      onVerversenAangevraagd: async () => await ophalenOpdrachten(),
    }),
    [ophalenOpdrachten],
  );

  // const ophalenRelaties = useCallback(async () => {
  //   if (opdrachten === null) {
  //     return;
  //   }

  //   const relIDs = opdrachten.filter((x) => x.RelID !== null).map((x) => x.RelID);
  //   const relatiesResult = await api.v2.relatie.ophalenRelaties({
  //     filterSchema: { filters: [{ naam: 'IDS', data: relIDs }] },
  //   });
  //   const relaties = relatiesResult.relaties;

  //   setRelaties(relaties);
  // }, [opdrachten]);

  // useEffect(() => {
  //   ophalenRelaties();
  // }, [ophalenRelaties]);

  const handleVerzamelen = useCallback(() => {
    setUrlStateSync('verzamelModalTonen', true);
  }, []);

  const handleVerzamelModalSuccess = useCallback(async (result: IVerzamelenOpdrachtenResult) => {
    setUrlStateSync('verzamelModalTonen', false);

    if (result.batchIDs.length !== 0) {
      // await checkStore.melden({
      //   titel: `Er zijn ${result.batchIDs.length} batches gemaakt voor ${result.bankOpdIDs.length} bankopdrachten`,
      // });
      await checkStore.melden({
        titel: `Er zijn ${result.batchIDs.length} batches gemaakt`,
      });
      props.history.push(`/bankzaken/bankopdrachten/batches`);
    } else {
      await checkStore.melden({
        titel: `Er zijn geen bankopdrachten gevonden die aan de selectiecriteria voldoen`,
      });
    }
  }, []);

  const handleVerzamelenGeselecteerd = useCallback(async () => {
    if (opdrachten === null) {
      return;
    }

    const params = { opdIDs: urlState.selectie };
    const checkData = await api.v2.bank.opdracht.checkVerzamelenOpdrachten(params);
    if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
      return;
    }

    if (
      (
        await checkStore.bevestigen({
          inhoud: `Batches maken voor de geselecteerde opdrachten?`,
          asynchroneActieNaBevestigingFn: async () => {
            const result = await api.v2.bank.opdracht.verzamelenOpdrachten(params);
            props.history.push(`/bankzaken/bankopdrachten/batches`);
          },
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    ophalenOpdrachten();
  }, [urlState.selectie, props.history]);

  const ophalenSignaleringen = useCallback(async () => {
    if (opdrachten === null) {
      return;
    }
    const result = (
      await api.v2.bank.opdracht.ophalenSignaleringenOpdrachten({
        bankOpdIDs: opdrachten.map((x) => x.BankOpdID),
      })
    ).opdrachten;

    setSignaleringenResult(result);
  }, [opdrachten]);

  useEffect(() => {
    ophalenSignaleringen();
  }, [ophalenSignaleringen]);

  const handleVerwijderen = useCallback(async () => {
    const checkData = await api.v2.bank.opdracht.checkVerwijderenOpdrachten({
      opdIDs: urlState.selectie,
    });
    if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
      return;
    }
    if (
      (
        await checkStore.bevestigen({
          inhoud: `Wil je de ${urlState.selectie.length} bankopdrachten verwijderen?`,
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    await api.v2.bank.opdracht.verwijderenOpdrachten({
      opdIDs: urlState.selectie,
    });

    setUrlStateSync('selectie', []);
    ophalenOpdrachten();
  }, [urlState.selectie]);

  const keyExtractor = useCallback((row: IOphalenOpdrachtenResultElement) => row.BankOpdID, []);

  const kolommen = useMemo<TypedColumn<IOphalenOpdrachtenResultElement>[]>(() => {
    return [
      {
        name: 'Uitvoerdatum',
        title: 'Uitvoerdatum',
        getCellValue: (x) => x.Uitvoerdatum,
      },
      {
        name: '__signaleringen' as any,
        title: 'Sign.',
      },
      {
        name: '__opgehouden' as any,
        title: 'Opgeh.',
        getCellValue: (x) => (x.Vrijgeven ? 1 : 0),
      },
      {
        name: 'RekeningnaamKortBedrijf',
        title: 'Rek.',
      },
      {
        name: 'Groep',
        title: 'Grp.',
      },
      {
        name: '__SoortBatch' as any,
        title: 'Soort',
      },
      {
        name: 'Bedrag',
        title: 'Bedrag',
      },
      {
        name: '__rekeninghouder' as any,
        title: 'Relatie',
        getCellValue: (x) => {
          if (relaties === null) {
            return null;
          }
          return relaties.find((r) => r.RelID === x.relatie!.RelID)!.weergavenaam;
        },
      },
      // {
      //   name: 'Betalingskenmerk',
      //   title: 'Kenmerk',
      // },
      {
        name: 'Omschrijving',
        title: 'Omschrijving',
      },
      {
        name: 'RecordToegevoegd',
        title: 'Vastgelegd',
      },
    ];
  }, [relaties]);

  const kolomBreedtes = useMemo<
    TypedTableColumnWidthInfo<IOphalenOpdrachtenResultElement>[]
  >(() => {
    return [
      {
        columnName: 'Uitvoerdatum',
        width: 130,
      },
      {
        columnName: '__opgehouden' as any,
        width: 85,
      },
      {
        columnName: '__signaleringen' as any,
        width: 70,
      },
      {
        columnName: 'RekeningnaamKortBedrijf',
        width: 90,
      },
      {
        columnName: '__SoortBatch' as any,
        width: 80,
      },
      {
        columnName: 'Groep',
        width: 70,
      },
      {
        columnName: 'Bedrag',
        width: 100,
      },
      {
        columnName: '__rekeninghouder' as any,
        width: 225,
      },
      // {
      //   columnName: 'Betalingskenmerk',
      //   width: 175,
      // },
      {
        columnName: 'Omschrijving',
        width: 400,
      },
      {
        columnName: 'RecordToegevoegd',
        width: 135,
      },
    ];
  }, []);

  const kolomExtensies: VirtualTableBase.ColumnExtension[] = useMemo(() => {
    return [
      // {
      //   columnName: 'Bedrag',
      //   align: 'right',
      // },
    ];
  }, []);

  // const RowDetailComponent = useCallback(
  //   (props: TableRowDetail.ContentProps) => (
  //     <RowDetailFacturen {...props} onVerversenAangevraagd={() => alert('todo')} />
  //   ),
  //   [],
  // );

  console.log('test');

  return (
    <BankopdrachtContext.Provider value={context}>
      <>
        {opdrachten === null || medewerkers === null ? (
          <div className="flex-fill d-flex align-items-center justify-content-center">
            <LoadingSpinner />
          </div>
        ) : (
          <>
            <Helmet>
              <title>Bankopdrachten</title>
            </Helmet>
            <MenuLayout
              menu={
                <>
                  <div className="d-flex">
                    <div className="d-flex align-items-center">
                      <button
                        className="btn btn-sm btn-light d-flex align-items-center"
                        style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                        disabled={urlState.selectie.length !== 0}
                        onClick={() => {
                          handleVerzamelen();
                        }}
                      >
                        <IconSend style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                        <span className="ml-2">Verzamelen met filter</span>
                      </button>

                      <button
                        className="btn btn-sm btn-light d-flex align-items-center ml-3"
                        style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                        disabled={urlState.selectie.length === 0}
                        onClick={() => {
                          handleVerzamelenGeselecteerd();
                        }}
                      >
                        <IconSend style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                        <span className="ml-2">Verzamelen geselecteerd</span>
                      </button>

                      <button
                        className="btn btn-sm btn-light d-flex align-items-center ml-3"
                        style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                        disabled={urlState.selectie.length === 0}
                        onClick={() => {
                          handleVerwijderen();
                        }}
                      >
                        <IconVerwijderen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                        <span className="ml-2">Verwijderen</span>
                      </button>

                      <button
                        className="btn btn-sm btn-light d-flex align-items-center ml-3"
                        style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                        onClick={() => setUrlStateSync('inlezenSalarisbetalingenDialoogState', {})}
                      >
                        <IconUpload style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                        <span className="ml-2">Loonbetalingen importeren</span>
                      </button>

                      <button
                        className="btn btn-sm btn-light d-flex align-items-center ml-3"
                        style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                        onClick={() =>
                          setUrlStateSync(
                            'previewDialoogState',
                            urlState.selectie.length !== 0 ? { bankOpdIDs: urlState.selectie } : {},
                          )
                        }
                      >
                        <IconPreview style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                        <span className="ml-2">
                          Preview batches {urlState.selectie.length !== 0 ? ' van selectie' : ''}
                        </span>
                      </button>
                    </div>
                  </div>

                  <div className="d-flex flex-fill mt-3">
                    <FilterBalkV2
                      filters={filters}
                      filterData={urlState.filterData}
                      onFilterDataChange={(x) => setUrlStateSync('filterData', x)}
                      onFilterSchemaChange={(x) => setFilterSchema(x)}
                    />
                  </div>

                  <div className="mt-3">
                    <SelectieVak
                      aantal={urlState.selectie.length}
                      totaalAantal={opdrachten!.length}
                      onChange={(alles) => {
                        if (alles) {
                          setUrlStateSync(
                            'selectie',
                            opdrachten.map((x) => x.BankOpdID),
                          );
                        } else {
                          setUrlStateSync('selectie', []);
                        }
                      }}
                      entiteitEnkelvoud="opdracht"
                      entiteitMeervoud="opdrachten"
                    />
                  </div>
                </>
              }
              body={
                <div
                // style={{
                //   maxHeight: 'calc(100vh - 200px)',
                //   overflowY: 'auto',
                // }}
                >
                  <GridStyleWrapper height="calc(100vh - 210px)">
                    <Grid
                      columns={kolommen as Column[]}
                      rows={opdrachten || []}
                      getRowId={keyExtractor}
                    >
                      <DataTypeProvider
                        for={['Bedrag']}
                        formatterComponent={(props) => (
                          <span>
                            <FormatteerBedrag bedrag={props.value} />
                          </span>
                        )}
                      />

                      <DataTypeProvider
                        for={['__opgehouden']}
                        formatterComponent={(props) => {
                          const rij = props.row as IRegel;
                          return <span>{rij.Vrijgeven ? 'Ja' : ''}</span>;
                        }}
                      />

                      <DataTypeProvider
                        for={['__SoortBatch']}
                        formatterComponent={(props) => {
                          return <span>{props.row.Bedrag > 0 ? 'Incasso' : 'Betalen'}</span>;
                        }}
                      />

                      <DataTypeProvider
                        for={['Uitvoerdatum']}
                        formatterComponent={(props) => (
                          <span>
                            {props.value !== null
                              ? format(new Date(props.value), 'dd-MM-yyyy')
                              : 'Z.s.m.'}
                          </span>
                        )}
                      />

                      <DataTypeProvider
                        for={['RecordToegevoegd']}
                        formatterComponent={(props) => (
                          <span>
                            {props.value !== null
                              ? format(new Date(props.value), 'dd-MM-yyyy HH:mm')
                              : ''}
                          </span>
                        )}
                      />

                      <DataTypeProvider
                        for={['__vrijgeven']}
                        formatterComponent={(props) => (
                          <span>
                            {props.row.Vrijgeven && (
                              <IconLock style={{ height: 15, width: 15, fill: Kleur.Grijs }} />
                            )}
                          </span>
                        )}
                      />

                      <DataTypeProvider
                        for={['__rekeninghouder']}
                        formatterComponent={(props) => {
                          const rij: IOphalenOpdrachtenResultElement = props.row;

                          if (rij.RelID !== null) {
                            return (
                              <RelatieVisualisatie
                                relID={rij.RelID}
                                relatieLinkBuilder={(hoedanigheid, relID) =>
                                  `/${
                                    hoedanigheid === EHoedanigheid.Klant ? 'klant' : 'leverancier'
                                  }/${relID}/bankzaken/bankopdrachten`
                                }
                              />
                            );
                          }
                          if (rij.MdwID === null) {
                            return <span />;
                          }
                          const medewerker = medewerkers!.find((x) => x.MdwID === rij.MdwID);
                          if (medewerker === undefined) {
                            return <span />;
                          }
                          return <span>{medewerker.persoon!.Achternaam}</span>;
                        }}
                      />

                      <DataTypeProvider
                        for={['__signaleringen']}
                        formatterComponent={(props) => {
                          const rij: IRegel = props.row;

                          if (rij.errors.length !== 0) {
                            return <StoplichtIndicatie status={EStoplichtStatus.Rood} />;
                          }
                          if (rij.warnings.length !== 0) {
                            return <StoplichtIndicatie status={EStoplichtStatus.Oranje} />;
                          }

                          return <StoplichtIndicatie status={EStoplichtStatus.Groen} />;
                        }}
                      />

                      <SortingState defaultSorting={[]} />
                      <IntegratedSorting />

                      <RowDetailState
                        expandedRowIds={urlState.uitgeklapt}
                        onExpandedRowIdsChange={(x) => setUrlStateSync('uitgeklapt', x as number[])}
                      />
                      <VirtualTable />
                      <VirtualTable messages={geenData} columnExtensions={kolomExtensies} />

                      <TableColumnResizing defaultColumnWidths={kolomBreedtes} />

                      <EditingState
                        onAddedRowsChange={() => {}}
                        onEditingRowIdsChange={async (x) => {
                          const id = x[x.length - 1] as number;
                          setUrlStateSync('wijzigOpdrachtDialoogState', { bankOpdID: id });
                        }}
                        onCommitChanges={() => null}
                      />
                      <TableEditColumn
                        width={35}
                        showEditCommand
                        commandComponent={DXCommandComponent}
                      />

                      <TableRowDetail
                        contentComponent={RowDetailComponent}
                        toggleCellComponent={DXTableToggleCellComponent}
                      />

                      <SelectionState
                        selection={urlState.selectie}
                        onSelectionChange={(value) =>
                          setUrlStateSync('selectie', value as number[])
                        }
                      />
                      <TableSelection cellComponent={DXTableCheckboxComponent} />

                      <TableHeaderRow showSortingControls />
                    </Grid>
                  </GridStyleWrapper>
                </div>
              }
            />

            <VerzamelModal
              open={urlState.verzamelModalTonen}
              onSuccess={handleVerzamelModalSuccess}
              onAnnuleren={() => setUrlStateSync('verzamelModalTonen', false)}
            />

            {urlState.wijzigOpdrachtDialoogState !== null && (
              <WijzigOpdrachtDialoog
                open={true}
                bankOpdID={urlState.wijzigOpdrachtDialoogState.bankOpdID}
                onSuccess={() => {
                  ophalenOpdrachten();
                  setUrlStateSync('wijzigOpdrachtDialoogState', null);
                }}
                onAnnuleren={() => setUrlStateSync('wijzigOpdrachtDialoogState', null)}
              />
            )}
          </>
        )}
        {urlState.inlezenSalarisbetalingenDialoogState !== null && (
          <BestandenDragAndDropDialoog
            open
            onSuccess={async (result) => {
              const x = await api.v2.bank.opdracht.inlezenSalarisbetalingen({
                bestandIDs: result.bestanden.map((x) => x.ID),
              });
              setUrlStateSync('inlezenSalarisbetalingenDialoogState', null);
              ophalenOpdrachten();
              await checkStore.melden({
                titel: 'Er is een batch aangemaakt, het bestand daarvan moet nog verstuurd worden',
              });
              props.history.push(`/bankzaken/bankopdrachten/batches`);
            }}
            onAnnuleren={() => setUrlStateSync('inlezenSalarisbetalingenDialoogState', null)}
          />
        )}
        {urlState.previewDialoogState !== null && (
          <PreviewBatchDialoog
            open
            onSuccess={() => setUrlStateSync('previewDialoogState', null)}
            onAnnuleren={() => setUrlStateSync('previewDialoogState', null)}
            bankOpdIDs={urlState.previewDialoogState.bankOpdIDs}
          />
        )}
      </>
    </BankopdrachtContext.Provider>
  );
});

export default withRouter(Opdrachten);
