import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import Dialoog from '../../../../components/dialogen/Dialoog';
import IDialoogProps from '../../../../core/IDialoogProps';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import { Field, FieldProps, Formik, FormikActions, FormikErrors } from 'formik';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import nameOf from '../../../../core/nameOf';
import FormikVeldFout from '../../../../components/formulier/FormikVeldFout';
import VinkVeld from '../../../../components/formulier/VinkVeld';
import { IOphalenMedewerkersResultElement } from '../../../../../../shared/src/api/v2/medewerker';
import api from '../../../../api';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import { RootStoreContext } from '../../../../stores/RootStore';
import { EResultType } from '../../../../stores/CheckStore';

interface IDialoogResult {}

interface IProps extends IDialoogProps<IDialoogResult> {
  mdwIDs: number[];
}

interface IFormikValues {
  jaar: number;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  jaar: 'Vigerend Jaar',
};

const VerlofoverzichtDialoog: React.FC<IProps> = (props) => {
  const [medewerker, setMedewerker] = useState<IOphalenMedewerkersResultElement | null>(null);
  const { checkStore } = useContext(RootStoreContext);

  const ophalenMedewerker = useCallback(async () => {
    const medewerkersResult = (
      await api.v2.medewerker.ophalenMedewerkers({
        filterSchema: {
          filters: [{ naam: 'IDS', data: props.mdwIDs }],
        },
      })
    ).medewerkers;

    setMedewerker(medewerkersResult[0]);
  }, [props.mdwIDs]);

  useEffect(() => {
    ophalenMedewerker();
  }, [ophalenMedewerker]);

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (medewerker === null) {
      return null;
    }
    return {
      jaar: new Date().getFullYear(),
    };
  }, [medewerker]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);
      const params = { mdwIDs: props.mdwIDs, vigerendJaar: values.jaar };
      await api.v2.medewerker.verlof.versturenVerlofoverzicht(params);
      actions.setSubmitting(false);
      props.onSuccess({});
    },
    [props.mdwIDs],
  );

  if (initialValues === null) {
    return (
      <div className="d-flex flex-fill align-items-center justify-content-center pt-4">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <Dialoog index={props.dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Versturen Verlofoverzicht(en)</ModalTitle>
      </ModalHeader>
      <Formik<IFormikValues>
        onSubmit={handleSubmit}
        initialValues={initialValues}
        render={(formikProps) => {
          const { submitForm, isSubmitting } = formikProps;
          return (
            <>
              <ModalBody>
                <div className="col-12">
                  <label>{veldnamen.jaar}</label>
                  <Field
                    name={nameOf<IFormikValues>('jaar')}
                    render={(fieldProps: FieldProps) => {
                      const { field, form } = fieldProps;
                      return (
                        <div>
                          <input type="number" className="form-control" {...fieldProps.field} />
                          <FormikVeldFout fieldProps={fieldProps} />
                        </div>
                      );
                    }}
                  />
                </div>
              </ModalBody>
              <ModalFooter className="d-flex flex-row justify-content-start">
                <button
                  className="btn btn-primary d-flex align-items-center justify-content-center"
                  onClick={submitForm}
                  style={{ width: 100 }}
                  disabled={isSubmitting}
                >
                  Ok
                </button>
                <button
                  className="btn btn-secondary"
                  onClick={props.onAnnuleren}
                  style={{ width: 100 }}
                  disabled={isSubmitting}
                >
                  Annuleren
                </button>
              </ModalFooter>
            </>
          );
        }}
      />
    </Dialoog>
  );
};

export default VerlofoverzichtDialoog;
