import styled from 'styled-components';
import { colors } from '../../globalstyles/variables';
import { Kleur } from '../../bedrijfslogica/constanten';
import { NavLink } from 'react-router-dom';

// #318c17

export const Navigatie = styled.aside`
    //background-color: ${Kleur.Navigatie};
  background-color: ${
    process.env.REACT_APP_ENV === 'PROD'
      ? Kleur.Navigatie
      : process.env.REACT_APP_ENV === 'ACC'
      ? '#287013'
      : '#02427a'
  }; //colors.navyBlue
  min-width: 210px;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const NavigatieLijstHeader = styled.header`
  text-transform: uppercase;
  padding: 1rem 1rem 0.3rem 1rem;
  color: ${colors.textGreyDark};
`;

export const NavigatieFooter = styled.footer`
  color: ${Kleur.DonkerWit};
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  //padding-top: 5px;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .hoverable {
    .technische-info {
      transition: height 0.3s ease-out, opacity 0.3s ease-out;
      height: 0px;
      opacity: 0%;
      //display: none;
    }

    &:hover {
      .technische-info {
        height: 110px;
        opacity: 100%;
        //display: block;
      }
    }
  }
`;
