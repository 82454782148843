import React, { useCallback, useContext, useMemo, useEffect, useState } from 'react';
import { ISectieProps } from '../index';
import GegevensLayout from '../../../../../../../../../components/layout/GegevensLayout';
import {} from '../../../../../../../../../components/Icons';
import { Kleur } from '../../../../../../../../../bedrijfslogica/constanten';
import ContractStatus, { EContractStatus } from '../ContractStatus';
import { RootStoreContext } from '../../../../../../../../../stores/RootStore';
import api from '../../../../../../../../../api';
import { EResultType } from '../../../../../../../../../stores/CheckStore';
import { formatteerPersoonNaam } from '../../../../../../../../../helpers';
import { IOphalenContractenResultElementV2 } from '../../../../../../../../../../../shared/src/api/v2/contract';
import VerticaleScheidingslijn from '../../../../../../../../../components/layout/VerticaleScheidingslijn';
import HorizontaleScheidingslijn from '../../../../../../../../../components/layout/HorizontaleScheidingslijn';
import RelatieVisualisatie from '../../../../../../../../../components/personalia/RelatieVisualisatie';
import { format } from 'date-fns';
import ContractVisualisatie from '../../../../../../../../../components/entiteitVisualisaties/ContractVisualisatie';

interface IProps extends ISectieProps {}

const Mutaties: React.FC<IProps> = (props) => {
  const contract = props.contract!;

  const { checkStore, contractenStore } = useContext(RootStoreContext);
  const [contractVorig, setContractVorig] = useState<IOphalenContractenResultElementV2 | null>(
    null,
  );
  const [contractVolgend, setContractVolgend] = useState<IOphalenContractenResultElementV2 | null>(
    null,
  );

  const handleRequestRefresh = useCallback(async () => {
    props.vernieuwenContracten();
  }, [props.vernieuwenContracten]);

  useEffect(() => {
    (async () => {
      if (contract.vorigContract === null) {
        return;
      }
      const contractVorig = (
        await api.v2.contract.ophalenContractenV2({
          filterSchema: { filters: [{ naam: 'IDS', data: [contract.vorigContract.CntID] }] },
        })
      ).contracten[0];

      setContractVorig(contractVorig);
    })();
  }, [contract]);

  useEffect(() => {
    (async () => {
      if (contract.volgendContract === null) {
        return;
      }
      const contractVolgend = (
        await api.v2.contract.ophalenContractenV2({
          filterSchema: { filters: [{ naam: 'IDS', data: [contract.volgendContract.CntID] }] },
        })
      ).contracten[0];

      setContractVolgend(contractVolgend);
    })();
  }, [contract]);

  return (
    <>
      {contract.AangebodenOvernameOp === null &&
      contractVorig === null &&
      contractVolgend === null ? (
        <div className="d-flex pt-2 pb-3 pl-4 pr-4">
          <span>Er zijn geen overdrachtgegevens aanwezig</span>
        </div>
      ) : (
        <div>
          {contract.AangebodenOvernameOp !== null && (
            <>
              <GegevensLayout
                gegevens={[
                  {
                    label: 'Aangeboden ter overname op',
                    waarde: `${format(
                      new Date(contract.AangebodenOvernameOp),
                      'dd-MM-yyyy HH:mm',
                    )}`,
                  },
                  {
                    label: 'Reden',
                    waarde: `${contract.redenOverdracht!.Naam}`,
                  },
                ]}
              />
              <div className="mt-3 mb-3">
                <HorizontaleScheidingslijn />
              </div>
            </>
          )}

          <div className="d-flex pt-2 pb-3 pl-4 pr-4">
            {contractVorig !== null && (
              <div style={{ width: 400 }}>
                <div className="mb-1">Overgenomen Van:</div>
                <GegevensLayout
                  gegevens={[
                    {
                      label: 'Contract',
                      waarde: <ContractVisualisatie cntID={contractVorig.CntID} />,
                    },
                    {
                      label: 'Contractant',
                      waarde: <RelatieVisualisatie relID={contractVorig.RelID} />,
                    },
                    {
                      label: 'Reden overdracht',
                      waarde: `${
                        contractVorig.redenOverdracht !== null
                          ? contractVorig.redenOverdracht.Naam
                          : 'Niet bekend'
                      }`,
                    },
                  ]}
                />
              </div>
            )}

            {contractVolgend !== null && contractVolgend.status.NaamEnum !== 'CONCEPT' && (
              <div style={{ width: 400 }}>
                <div className="mb-1">Overgenomen Door:</div>
                <GegevensLayout
                  gegevens={[
                    {
                      label: 'Contract',
                      waarde: <ContractVisualisatie cntID={contractVolgend.CntID} />,
                    },
                    {
                      label: 'Contractant',
                      waarde: <RelatieVisualisatie relID={contractVolgend.RelID} />,
                    },
                    // {
                    //   label: 'Reden overdracht',
                    //   waarde: `${
                    //     contractVolgend.redenOverdracht !== null
                    //       ? contractVolgend.redenOverdracht.Naam
                    //       : 'Niet bekend'
                    //   }`,
                    // },
                  ]}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Mutaties;
