import React, { useContext, useMemo } from 'react';
import { Root } from './style';
import Identiteit from './Identiteit';
import Notitie from './Notitie';
import VasteInfo from './VasteInfo';
import Bijzonderheden from './Bijzonderheden';
import { IOphalenRelatiesResultElementV2 } from '../../../../../shared/src/api/v2/relatie';
import { IOphalenFinancieleInformatieResult } from '../../../../../shared/src/api/v2/debiteur';

import HorizontaleScheidingslijn from '../../layout/HorizontaleScheidingslijn';
import { Kleur } from '../../../bedrijfslogica/constanten';
import { IconPijlVolgend } from '../../Icons';
import { EKaartType } from '../root';
import { RouterContext } from '../../../routers/RouterContextProvider';
import OrganisatieUitbreiding from './OrganisatieUitbreiding';
import TopInfo from './RelatieGebruik';
import Events from './Events';
import Control from '../Control';
import {
  IOphalenLijstRecentResult,
  IOphalenLijstRecentResultElement,
} from '../../../../../shared/src/api/v2/relatie/Selectie';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../stores/RootStore';
import { Helmet } from 'react-helmet';
import { formatteerOrganisatieNaam, formatteerPersoonNaam } from '../../../helpers';

interface IProps {
  relID: number;
  relatie: IOphalenRelatiesResultElementV2;
  debiteurInformatie?: IOphalenFinancieleInformatieResult;
  type: EKaartType;
  onVerversenAangevraagd: () => void;

  zoekterm: string;
  onZoektermChange: (zoekterm: string) => void;
  navigeerNaarRelID: (relID: number) => Promise<void>;
  // navigeerNaarVorigeRelatie: () => Promise<void>;
  recenteReferenties: IOphalenLijstRecentResultElement[] | null;
  hoedanigheid: 'KLANT' | 'LEVERANCIER';
  ophalenRecenteReferenties: () => Promise<IOphalenLijstRecentResult>;
}

const KlantInformatieSectie: React.FC<IProps> = observer((props) => {
  const { relatie, debiteurInformatie, relID } = props;
  const { navigeerLeverancierNaarRelID, navigeerKlantkaartNaarRelID } = useContext(RouterContext);
  const { klantkaartStore } = useContext(RootStoreContext);

  const navigatieWeergave = useMemo(() => {
    if (props.type === EKaartType.Klant && props.relatie.hoedanigheid!.NaamEnum === 'LEVERANCIER') {
      return (
        <div>
          <div className="p-2 pl-3 pr-3">
            <a
              href="#"
              style={{ color: Kleur.Oranje }}
              className="d-flex align-items-center"
              onClick={() => navigeerLeverancierNaarRelID(props.relatie.RelID)}
            >
              <IconPijlVolgend
                style={{ fill: Kleur.Oranje, width: 14, height: 14, marginTop: 1 }}
              />
              <span className="ml-2">Ga naar leverancier</span>
            </a>
          </div>
        </div>
      );
    }

    if (props.type === EKaartType.Leverancier && props.relatie.hoedanigheid!.NaamEnum === 'KLANT') {
      return (
        <div>
          <div className="p-2 pl-3 pr-3">
            <a
              href="#"
              style={{ color: Kleur.Oranje }}
              className="d-flex align-items-center"
              onClick={() => navigeerKlantkaartNaarRelID(props.relatie.RelID)}
            >
              <IconPijlVolgend
                style={{ fill: Kleur.Oranje, width: 14, height: 14, marginTop: 1 }}
              />
              <span className="ml-2">Ga naar klantkaart</span>
            </a>
          </div>
        </div>
      );
    }

    return null;
  }, [props.type, props.relatie, navigeerLeverancierNaarRelID, navigeerKlantkaartNaarRelID]);

  return (
    <Root>
      <Helmet>
        <title>
          ASP |&nbsp;
          {props.relatie!.Relatiesoort === 'P'
            ? formatteerPersoonNaam({
                voornaam: props.relatie!.persoon!.Voornaam,
                achternaam: props.relatie!.persoon!.Achternaam,
                voorletters: props.relatie!.persoon!.Voorletters,
                aanhefKort: props.relatie!.persoon!.geslacht.AanhefKort,
                voorvoegsel: props.relatie!.persoon!.Voorvoegsel,
              })
            : formatteerOrganisatieNaam({
                naam: props.relatie!.organisatie!.Naam,
              })}
        </title>
      </Helmet>

      <Control
        zoekterm={props.zoekterm}
        onZoektermChange={props.onZoektermChange}
        recenteReferenties={props.recenteReferenties}
        navigeerNaarVorigeRelatie={async () => {
          const recenteReferenties = await props.ophalenRecenteReferenties();
          const referentie = recenteReferenties!.relaties[1];
          await props.navigeerNaarRelID(referentie.RelID);
        }}
        navigeerNaarRelID={props.navigeerNaarRelID}
        type={props.type}
        relID={props.relID}
        hoedanigheid={props.hoedanigheid}
        onVerversenAangevraagd={() => klantkaartStore.verversRelatie()}
      />

      <Identiteit
        relID={relID}
        relatie={relatie}
        debiteurInformatie={debiteurInformatie}
        type={props.type}
        onVerversenAangevraagd={props.onVerversenAangevraagd}
      />
      {relatie.organisatie !== null && (
        <>
          <OrganisatieUitbreiding orgID={relatie.organisatie.OrgID} relatie={props.relatie} />
        </>
      )}
      {navigatieWeergave}
      <HorizontaleScheidingslijn color={Kleur.HeelLichtGrijs} />
      {/* {relatie.Vervangende_RelID !== null && (
        <>
          <VervangendeRelatie relatie={relatie} />
          <HorizontaleScheidingslijn color={Kleur.HeelLichtGrijs} />
        </>
      )} */}
      {(relatie.Geblokkeerd ||
        relatie.NietGebruiken ||
        relatie.Vervangende_RelID !== null ||
        relatie.Vertegenwoordiger_RelID !== null) && (
        <>
          <TopInfo relatie={relatie} />
          <HorizontaleScheidingslijn color={Kleur.HeelLichtGrijs} />
        </>
      )}
      <HorizontaleScheidingslijn color={Kleur.HeelLichtGrijs} />
      {relatie.VasteInfo !== null && (
        <>
          <VasteInfo vasteInfo={relatie.VasteInfo} />
          <HorizontaleScheidingslijn color={Kleur.HeelLichtGrijs} />
        </>
      )}
      <Notitie relatie={relatie} />

      <div style={{ flex: 1, overflowY: 'auto', display: 'flex' }}>
        <div style={{ display: 'flex', minHeight: 'min-content', flexDirection: 'column' }}>
          <Events relatie={relatie} />
          {/* <HorizontaleScheidingslijn color={Kleur.HeelLichtGrijs} /> */}
          <Bijzonderheden relatie={relatie} />
          {/*<HorizontaleScheidingslijn color={Kleur.HeelLichtGrijs} />*/}
        </div>
      </div>
    </Root>
  );
});

export default KlantInformatieSectie;
