import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import Dialoog from '../../../../components/dialogen/Dialoog';
import IDialoogProps from '../../../../core/IDialoogProps';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalFooter from 'react-bootstrap/ModalFooter';
import ModalBody from 'react-bootstrap/ModalBody';
import Tabblad, { ITabblad } from '../../../../components/layout/Tabblad';
import GegevensTabblad from './GegevensTabblad';
import ProfielenTabblad from './ProfielenTabblad';
import { Formik, FormikActions, FormikProps } from 'formik';
import { IOphalenPersonenResultElementV2 } from '../../../../../../shared/src/api/v2/persoon/persoon';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../../stores/RootStore';
import api from '../../../../api';
import {
  IAspLocatie,
  IOphalenAspLocatiesResult,
  IOphalenGebruikersprofielenResult,
  IWijzigenGebruikersprofielParams,
} from '../../../../../../shared/src/api/v2/aspGebruiker';
import { EResultType } from '../../../../stores/CheckStore';
import { IOphalenBestandenResultElement } from '../../../../../../shared/src/api/v2/bestand/bestand';
import {
  IVoipAccount,
  IOphalenVoipAccountsResult,
} from '../../../../../../shared/src/api/v2/telefonie';

enum ETabblad {
  Gegevens,
  Profielen,
}

export interface IAspGebruikerprofielFormData {
  // Gebruikt voor het identificeren tabblad frontend
  localID: number | string;
  naam: string;
  aspLocID: number | null;
  voipAccID: number | null;
}

export interface IFormikValues {
  profielAfbeelding: IOphalenBestandenResultElement | null;
  profielen: IAspGebruikerprofielFormData[];
}

export interface IInstellingenDialoogFormikContext {
  formikProps: FormikProps<IFormikValues>;
  aspLocaties: IAspLocatie[] | null;
  voipAccounts: IVoipAccount[] | null;
}

export const InstellingenDialoogFormikContext = React.createContext<
  IInstellingenDialoogFormikContext
>(null as any);

export const veldnamen: Partial<Record<keyof IFormikValues, string>> = {
  profielAfbeelding: 'Profiel afbeelding',
};

interface IProps extends IDialoogProps {}

const InstellingenDialoog: React.FC<IProps> = observer((props) => {
  const { gebruikerStore, checkStore } = useContext(RootStoreContext);
  const [tabblad, setTabblad] = useState<ETabblad>(ETabblad.Gegevens);

  const [persoon, setPersoon] = useState<IOphalenPersonenResultElementV2 | null>(null);
  const [profielenResult, setProfielenResult] = useState<IOphalenGebruikersprofielenResult | null>(
    null,
  );
  const [aspLocatiesResult, setAspLocatiesResult] = useState<IOphalenAspLocatiesResult | null>(
    null,
  );
  const [voipAccountsResult, setVoipAccountsResult] = useState<IOphalenVoipAccountsResult | null>(
    null,
  );

  const ophalenPersoonDmvGebruiker = useCallback(async () => {
    if (gebruikerStore.gebruiker!.PersID === null) {
      setPersoon(null);
    }
    const result = await api.v2.persoon.ophalenPersonen({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: [gebruikerStore.gebruiker!.PersID],
          },
        ],
      },
    });
    const persoon = result.personen[0];
    setPersoon(persoon);
  }, [gebruikerStore.gebruiker!.PersID, setPersoon]);
  useEffect(() => {
    if (gebruikerStore.gebruiker!.PersID === null) {
      setPersoon(null);
      return;
    }

    // noinspection JSIgnoredPromiseFromCall
    ophalenPersoonDmvGebruiker();
  }, [gebruikerStore.gebruiker!.PersID]);

  const ophalenProfielen = useCallback(async () => {
    const result = await api.v2.aspGebruiker.ophalenGebruikersprofielen({
      filterSchema: {
        filters: [
          {
            naam: 'ASP_GEBR_IDS_IN',
            data: [gebruikerStore.gebruiker!.AspGebrID],
          },
        ],
      },
    });
    setProfielenResult(result);
  }, [gebruikerStore.gebruiker!.AspGebrID]);

  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    ophalenProfielen();
  }, [ophalenProfielen]);

  const ophalenAspLocaties = useCallback(async () => {
    const result = await api.v2.aspGebruiker.ophalenAspLocaties({});
    setAspLocatiesResult(result);
  }, []);

  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    ophalenAspLocaties();
  }, [ophalenAspLocaties]);

  const ophalenVoipAccounts = useCallback(async () => {
    const result = await api.v2.telefonie.ophalenVoipAccounts({
      filterSchema: {
        uitgebreideFilter: {
          or: [
            {
              filter: {
                naam: 'ASP_GEBR_ID',
                // Null om voip accounts te selecteren die voor elke gebruiker mogelijk is...
                data: null,
              },
            },
            {
              filter: {
                naam: 'ASP_GEBR_IDS_IN',
                // En die voor onze gebruiker mogelijk zijn
                data: [gebruikerStore.gebruiker!.AspGebrID],
              },
            },
          ],
        },
      },
    });
    setVoipAccountsResult(result);
  }, [gebruikerStore.gebruiker!.AspGebrID]);

  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    ophalenVoipAccounts();
  }, [ophalenVoipAccounts]);

  const tabbladen = useMemo<ITabblad<ETabblad>[]>(
    () => [
      {
        id: ETabblad.Gegevens,
        label: 'Gegevens',
        content: GegevensTabblad,
      },
      {
        id: ETabblad.Profielen,
        label: 'Profielen',
        content: ProfielenTabblad,
      },
    ],
    [],
  );

  const initieleWaarden = useMemo<IFormikValues | null>(() => {
    if (gebruikerStore.gebruiker!.PersID !== null && persoon === null) {
      return null;
    }
    if (profielenResult === null) {
      return null;
    }
    return {
      profielAfbeelding: persoon === null ? null : persoon.profielAfbeelding,
      profielen: profielenResult.profielen.map((profiel) => {
        return {
          localID: profiel.ID,
          naam: profiel.Naam,

          aspLocID: profiel.AspLocID,
          voipAccID: profiel.VoipAccID,
        };
      }),
    };
  }, [gebruikerStore.gebruiker!.PersID, persoon, profielenResult]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      const params: IWijzigenGebruikersprofielParams = {
        profielAfbeeldingBestandID:
          values.profielAfbeelding === null ? null : values.profielAfbeelding.ID,
        profielen: values.profielen.map((x) => {
          return {
            ID: typeof x.localID === 'number' ? x.localID : null,
            Naam: x.naam,
            AspLocID: x.aspLocID,
            VoipAccID: x.voipAccID,
          };
        }),
      };
      const checkData = await api.v2.aspGebruiker.checkWijzigenGebruikersprofiel(params);
      if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
        return;
      }
      const result = await api.v2.aspGebruiker.wijzigenGebruikersprofiel(params);
      props.onSuccess(null);
    },
    [props.onSuccess],
  );

  return (
    <Dialoog
      index={props.dialoogIndex || 0}
      modalProps={{
        size: 'lg',
      }}
    >
      <ModalHeader>
        <ModalTitle>Instellingen</ModalTitle>
      </ModalHeader>

      {initieleWaarden !== null && (
        <Formik<IFormikValues>
          initialValues={initieleWaarden}
          onSubmit={handleSubmit}
          render={(formikProps: FormikProps<IFormikValues>) => {
            const { submitForm, isSubmitting, isValid } = formikProps;
            return (
              <>
                <ModalBody style={{ padding: 0 }}>
                  <InstellingenDialoogFormikContext.Provider
                    value={{
                      formikProps,
                      aspLocaties: aspLocatiesResult === null ? null : aspLocatiesResult.locaties,
                      voipAccounts:
                        voipAccountsResult === null ? null : voipAccountsResult.voipAccounts,
                    }}
                  >
                    <Tabblad<ETabblad>
                      tabbladen={tabbladen}
                      geselecteerd={tabblad}
                      onSelectieChange={(x) => setTabblad(x)}
                    />
                  </InstellingenDialoogFormikContext.Provider>
                </ModalBody>

                <ModalFooter className="d-flex flex-row justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    style={{ width: 100 }}
                    disabled={isSubmitting || !isValid}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={props.onAnnuleren}
                    style={{ width: 100 }}
                    disabled={isSubmitting}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Dialoog>
  );
});

export default InstellingenDialoog;
