import {
  IOphalenOpeningstijdenParams,
  IOphalenOpeningstijdenResult,
} from '../../../../../shared/src/api/v2/bedrijf';
import {
  IOphalenActueleOpeningsinfoParams,
  IOphalenActueleOpeningsinfoResult,
} from '../../../../../shared/src/api/v2/openingstijd';

import api from '../../index';
import ICheckData from '../../../../../shared/src/models/ICheckData';

const openingstijd = {
  ophalenOpenngstijden: async (
    params: IOphalenOpeningstijdenParams,
  ): Promise<IOphalenOpeningstijdenResult> => {
    return await api.post('/v2/openingstijd/ophalen-openingstijden', params);
  },
  ophalenActueleOpeningsinfo: async (
    params: IOphalenActueleOpeningsinfoParams,
  ): Promise<IOphalenActueleOpeningsinfoResult> => {
    return await api.post('/v2/openingstijd/ophalen-actuele-openingsinfo', params);
  },
};

export default openingstijd;
