import React, { useCallback, useContext, useMemo } from 'react';
import {
  DXTableCheckboxComponent,
  DXTableToggleCellComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../helpers/dxTableGrid';
import { Kleur } from '../../../bedrijfslogica/constanten';
import { format } from 'date-fns';
import {
  Grid,
  TableColumnResizing,
  TableGroupRow,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  DataTypeProvider,
  GroupingState,
  IntegratedGrouping,
  IntegratedSorting,
  RowDetailState,
  SelectionState,
  Sorting,
  SortingState,
} from '@devexpress/dx-react-grid';
import RelatieVisualisatie from '../../personalia/RelatieVisualisatie';
import voorbeeldWeergaveMap from '../../../paginas/Signaleringen/voorbeeldWeergaveMap';
import api from '../../../api';
import RowDetailContent from '../../../paginas/Signaleringen/RowDetailContent';
import {
  ISignalering,
  ISignaleringsrun,
} from '../../../../../shared/src/api/v2/control/signalering';
import LaatstGesignaleerdWeergave from './LaatstGesignaleerdWeergave';
import { RootStoreContext } from '../../../stores/RootStore';
import { EResultType } from '../../../stores/CheckStore';
import BezigheidKnop from '../../BezigheidKnop';

export enum EKolom {
  Relatie = 'RelID',
  Type = 'TypeNaam',
  LaatstGesignaleerd = 'LaatstGesignaleerd',
  Entiteiten = '__entiteiten',
  Acties = '__acties',
  Bezigheid = '__bezigheid',
}

interface IProps {
  selection: number[];
  onSelectionChange: (selection: number[]) => void;
  sortering: Array<Sorting>;
  onSorteringChange: (sortering: Array<Sorting>) => void;
  signaleringen: ISignalering[];
  run: ISignaleringsrun;
  kolommen?: EKolom[];
  height?: number | string;
  maxHeight?: number;
}

export interface ISignaleringstabelContext {
  run: ISignaleringsrun;
}

export const SignaleringstabelContext = React.createContext<ISignaleringstabelContext>(null as any);

const geenData = {
  noData: 'Geen signaleringen gevonden',
};

const SignaleringTabel: React.FC<IProps> = (props) => {
  const { checkStore } = useContext(RootStoreContext);

  const keyExtractor = useCallback((row: ISignalering) => row.ID, []);

  const heeftKolom = useCallback(
    (naam: EKolom) =>
      props.kolommen === undefined || props.kolommen.find((x) => x === naam) !== undefined,
    [props.kolommen],
  );

  const kolommen = useMemo<TypedColumn<ISignalering>[]>(
    () =>
      [
        heeftKolom(EKolom.Type)
          ? {
              name: 'TypeNaam',
              title: 'Type',
            }
          : null,
        heeftKolom(EKolom.Entiteiten)
          ? {
              name: '__entiteiten' as any,
              title: 'Entiteit(en)',
            }
          : null,
        heeftKolom(EKolom.Relatie) ? { name: 'RelID', title: 'Relatie' } : null,
        heeftKolom(EKolom.LaatstGesignaleerd)
          ? {
              name: 'LaatstGesignaleerd',
              title: 'Laatst gesignaleerd',
            }
          : null,

        heeftKolom(EKolom.Acties)
          ? {
              name: '__acties' as any,
              title: 'Zichtbaar/Verbergen',
            }
          : null,
        {
          name: '__bezigheid' as any,
          title: 'Bezigheid',
        },
      ].filter((x) => x !== null) as TypedColumn<ISignalering>[],
    [heeftKolom],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<ISignalering>[]>(
    () => [
      {
        columnName: 'RelID',
        width: 250,
      },
      {
        columnName: 'TypeNaam',
        width: 450,
      },
      {
        columnName: 'LaatstGesignaleerd',
        width: 175,
      },
      {
        columnName: '__entiteiten' as any,
        width: 350,
      },
      {
        columnName: '__acties' as any,
        width: 200,
      },
      {
        columnName: '__bezigheid' as any,
        width: 200,
      },
    ],
    [],
  );

  const context = useMemo<ISignaleringstabelContext>(() => {
    return {
      run: props.run,
    };
  }, [props.run]);

  return (
    <SignaleringstabelContext.Provider value={context}>
      <GridStyleWrapper
        rowAmount={props.signaleringen.length}
        maxHeight={props.maxHeight}
        height={props.height}
      >
        <Grid getRowId={keyExtractor} rows={props.signaleringen} columns={kolommen}>
          <DataTypeProvider
            for={['LaatstGesignaleerd']}
            formatterComponent={(formatterProps) => {
              const date = new Date(formatterProps.value);
              const runDatum = new Date(props.run.Datum);

              return <LaatstGesignaleerdWeergave datum={date} runDatum={runDatum} />;
            }}
          />

          <DataTypeProvider
            for={['RelID']}
            formatterComponent={(formatterProps) => {
              const row: ISignalering = formatterProps.row;
              if (row.RelID === null) {
                return <span />;
              }

              return <RelatieVisualisatie relID={row.RelID} />;
            }}
          />
          <DataTypeProvider
            for={['__entiteiten']}
            formatterComponent={(formatterProps) => {
              const row: ISignalering = formatterProps.row;
              const VoorbeeldWeergave = voorbeeldWeergaveMap[row.TypeNaamEnum];
              if (VoorbeeldWeergave === undefined) {
                return <span>Geen voorbeeld weergave beschikbaar</span>;
              }
              return <VoorbeeldWeergave signalering={row} />;
            }}
          />

          <DataTypeProvider
            for={['__acties']}
            formatterComponent={(formatterProps) => {
              const row: ISignalering = formatterProps.row;
              return (
                <span className="d-flex align-items-center">
                  <a
                    href="#"
                    onClick={async () => {
                      if (row.Verbergen) {
                        await api.v2.signalering.weergevenSignaleringen({
                          sigItemIDs: [row.ID],
                        });
                      } else {
                        if (
                          (
                            await checkStore.bevestigen({
                              inhoud: `Wil je de signalering verbergen?`,
                            })
                          ).type === EResultType.Annuleren
                        ) {
                          return;
                        }
                        await api.v2.signalering.verbergenSignaleringen({
                          sigItemIDs: [row.ID],
                        });
                      }
                    }}
                  >
                    {!row.Verbergen ? <span>Verbergen</span> : <span>Zichtbaar maken</span>}
                  </a>
                </span>
              );
            }}
          />

          <DataTypeProvider
            for={['__bezigheid']}
            formatterComponent={(formatterProps) => {
              const row: ISignalering = formatterProps.row;

              return (
                <BezigheidKnop
                  data={{ enum: 'SIGNALERING', signaleringID: row.ID }}
                  interactieToegestaan
                />
              );
            }}
          />

          <SelectionState
            selection={props.selection}
            onSelectionChange={(value) => props.onSelectionChange(value as number[])}
          />

          <SortingState
            sorting={props.sortering}
            onSortingChange={(value) => props.onSorteringChange(value)}
          />
          <GroupingState
            grouping={
              [
                // { columnName: 'TypeNaam' }
              ]
            }
          />
          <IntegratedSorting />
          <IntegratedGrouping />
          <RowDetailState />
          <VirtualTable messages={geenData} />
          <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
          <TableHeaderRow showSortingControls />
          <TableRowDetail
            toggleCellComponent={DXTableToggleCellComponent}
            contentComponent={RowDetailContent}
          />
          <TableSelection cellComponent={DXTableCheckboxComponent} />
          <TableGroupRow />
        </Grid>
      </GridStyleWrapper>
    </SignaleringstabelContext.Provider>
  );
};

export default SignaleringTabel;
