import api from '../../index';
import {
  IOphalenMeldingIndicatiesParams,
  IOphalenMeldingIndicatiesResult,
} from '../../../../../shared/src/api/v2/meldingIndicatie';

const meldingIndicatie = {
  async ophalenMeldingIndicaties(
    params: IOphalenMeldingIndicatiesParams,
  ): Promise<IOphalenMeldingIndicatiesResult> {
    return await api.post('v2/melding-indicatie/ophalen-melding-indicaties', params);
  },
};

export default meldingIndicatie;
