import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import IDialoogProps from '../../../../core/IDialoogProps';
import Dialoog from '../../../dialogen/Dialoog';
import { ModalBody, ModalFooter } from 'react-bootstrap';
import LoadingSpinner from '../../../Gedeeld/LoadingSpinner';
import Boekinggegevens from './Boekinggegevens';
import Boekstukgegevens from './Boekstukgegevens';
import Preview from './Preview';
import Regels from './Regels';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../models/IRemoteData';
import { IOphalenDagboekenResultElement } from '../../../../../../shared/src/api/v2/boekhouding/boeking/dagboek';
import { IBoekdatumProvider, standaardBoekdatumProvider } from './providers/boekdatum';
import { IDagboekenProvider, standaardDagboekenProvider } from './providers/dagboeken';
import {
  IDagboekVoorselectieProvider,
  standaardDagboekVoorselectieProvider,
} from './providers/dagboekVoorselectie';
import {
  IOphalenBoekingenResultElement,
  IOphalenWerkkostenRegelingenResultElement,
  IVastleggenBoekingParams,
  IVastleggenBoekingsregel,
} from '../../../../../../shared/src/api/v2/boekhouding/boeking';
import { IBoekingProvider, standaardBoekingProvider } from './providers/boeking';
import { IOphalenGrootboekenResultElement } from '../../../../../../shared/src/api/v2/boekhouding/boeking/grootboek';
import { IGrootboekenProvider, standaardGrootboekenProvider } from './providers/grootboeken';
import {
  Boekstuksaldo,
  ERegelboekstukType,
  ERegelIDType,
  ERegelstate,
  IRegelboekstukBankmutatie,
  IRegelboekstukBetalingsregeling,
  IRegelboekstukFactuur,
  IRegelboekstukInkoopfactuur,
  IRegelIDExtern,
  IWeergaveRegel,
  Regel,
} from './types';
import { IRegelsProvider, standaardRegelsProvider } from './providers/regels';
import {
  IOphalenBtwSoortenResultElement,
  IOphalenBtwTarievenResultElement,
} from '../../../../../../shared/src/api/v2/btw';
import { IBtwSoortenProvider, standaardBtwSoortenProvider } from './providers/btwSoorten';
import { IBtwTarievenProvider, standaardBtwTarievenProvider } from './providers/btwTarieven';
import { IOphalenFacturenBasisResultElement } from '../../../../../../shared/src/api/v2/factuur';
import { IOphalenFacturenResultElement } from '../../../../../../shared/src/api/v2/inkoopfactuur';
import { IOphalenMutatiesResultElement } from '../../../../../../shared/src/api/v2/bank/mutaties';
import api from '../../../../api';
import {
  IWerkkostenregelingenProvider,
  standaardWerkkostenregelingenProvider,
} from './providers/werkkostenregelingen';
import { IHoedanighedenProvider, standaardHoedanighedenProvider } from './providers/hoedanigheden';
import { IOphalenHoedanighedenResultElement } from '../../../../../../shared/src/api/v2/relatie/hoedanigheid';
import { IIsDefinitiefProvider, standaardIsDefinitiefProvider } from './providers/isDefinitief';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../../stores/RootStore';
import { EResultType } from '../../../../stores/CheckStore';
import { IBoekstuksaldiProvider, standaardBoekstuksaldiProvider } from './providers/boekstuksaldi';
import { GlobaleRendererContext } from '../../../../one-off-components/GlobaleRenderer';
import BoekingAlsnogDefinitiefMakenDialoog, {
  EBoekingAlsnogDefinitiefMakenKeuze,
} from './BoekingAlsnogDefinitiefMakenDialoog';
import {
  IRegelModificatieNaOpslaanProvider,
  standaardRegelModificatieNaOpslaanProvider,
} from './providers/regelModificatieNaOpslaan';
import { IOphalenBetalingsregelingenResultElement } from '../../../../../../shared/src/api/v2/debiteur/betalingsregeling';

interface IDialoogOutput {}

interface IProps extends IDialoogProps<IDialoogOutput> {
  boekingID?: number;
  boekdatumProvider?: IBoekdatumProvider;
  dagboekenProvider?: IDagboekenProvider;
  grootboekenProvider?: IGrootboekenProvider;
  dagboekVoorselectieProvider?: IDagboekVoorselectieProvider;
  boekingProvider?: IBoekingProvider;
  regelsProvider?: IRegelsProvider;
  btwSoortenProvider?: IBtwSoortenProvider;
  btwTarievenProvider?: IBtwTarievenProvider;
  werkkostenregelingenProvider?: IWerkkostenregelingenProvider;
  hoedanighedenProvider?: IHoedanighedenProvider;
  isDefinitiefProvider?: IIsDefinitiefProvider;
  boekstuksaldiProvider?: IBoekstuksaldiProvider;
  regelModificatieNaOpslaanProvider?: IRegelModificatieNaOpslaanProvider;
}

const BoekingDialoogV3 = observer((props: IProps) => {
  const { checkStore } = useContext(RootStoreContext);
  const globaleRenderer = useContext(GlobaleRendererContext);
  const [isAanHetLaden, setIsAanHetLaden] = useState(false);
  const [boekdatum, setBoekdatum] = useState(createPendingRemoteData<Date | null>());
  const [dagboeken, setDagboeken] = useState(
    createPendingRemoteData<IOphalenDagboekenResultElement[]>(),
  );
  const [grootboeken, setGrootboeken] = useState(
    createPendingRemoteData<IOphalenGrootboekenResultElement[]>(),
  );
  const [btwSoorten, setBtwSoorten] = useState(
    createPendingRemoteData<IOphalenBtwSoortenResultElement[]>(),
  );
  const [btwTarieven, setBtwTarieven] = useState(
    createPendingRemoteData<IOphalenBtwTarievenResultElement[]>(),
  );
  const [werkkostenregelingen, setWerkkostenregelingen] = useState(
    createPendingRemoteData<IOphalenWerkkostenRegelingenResultElement[]>(),
  );
  const [hoedanigheden, setHoedanigheden] = useState(
    createPendingRemoteData<IOphalenHoedanighedenResultElement[]>(),
  );
  const [dagboekID, setDagboekID] = useState(createPendingRemoteData<number>());
  const [boeking, setBoeking] = useState(
    createPendingRemoteData<IOphalenBoekingenResultElement | null>(),
  );
  const [regels, setRegels] = useState(createPendingRemoteData<Regel[]>());
  const [boekstuksaldi, setBoekstuksaldi] = useState(createPendingRemoteData<Boekstuksaldo[]>());
  const [isDefinitief, setIsDefinitief] = useState(createPendingRemoteData<boolean>());
  const [geselecteerdRegelID, setGeselecteerdRegelID] = useState<number | string | null>(null);
  const [geselecteerdPreviewItemIDs, setGeselecteerdPreviewItemIDs] = useState<
    Record<number | string, string | null>
  >({});
  const [facturenCache, setFacturenCache] = useState<
    Record<number, IOphalenFacturenBasisResultElement>
  >({});
  const [inkoopfacturenCache, setInkoopfacturenCache] = useState<
    Record<number, IOphalenFacturenResultElement>
  >({});
  const [bankmutatiesCache, setBankmutatiesCache] = useState<
    Record<number, IOphalenMutatiesResultElement>
  >({});
  const [betalingsregelingenCache, setBetalingsregelingenCache] = useState<
    Record<number, IOphalenBetalingsregelingenResultElement>
  >({});

  const boekdatumProvider = useMemo(() => props.boekdatumProvider ?? standaardBoekdatumProvider, [
    props.boekdatumProvider,
  ]);
  useEffect(() => {
    (async () => {
      const boekdatum = await boekdatumProvider.provide();
      setBoekdatum(createReadyRemoteData(boekdatum));
    })();
  }, []);

  const dagboekenProvider = useMemo(() => props.dagboekenProvider ?? standaardDagboekenProvider, [
    props.dagboekenProvider,
  ]);
  const dagboekVoorselectieProvider = useMemo(
    () => props.dagboekVoorselectieProvider ?? standaardDagboekVoorselectieProvider,
    [props.dagboekVoorselectieProvider],
  );
  useEffect(() => {
    (async () => {
      const dagboeken = await dagboekenProvider.provide();
      setDagboeken(createReadyRemoteData(dagboeken));

      const dagboekID = await dagboekVoorselectieProvider.provide(dagboeken);
      setDagboekID(createReadyRemoteData(dagboekID));
    })();
  }, []);

  const grootboekenProvider = useMemo(
    () => props.grootboekenProvider ?? standaardGrootboekenProvider,
    [props.grootboekenProvider],
  );
  useEffect(() => {
    (async () => {
      const grootboeken = await grootboekenProvider.provide();
      setGrootboeken(createReadyRemoteData(grootboeken));
    })();
  }, []);

  const btwSoortenProvider = useMemo(
    () => props.btwSoortenProvider ?? standaardBtwSoortenProvider,
    [props.btwSoortenProvider],
  );
  useEffect(() => {
    (async () => {
      const btwSoorten = await btwSoortenProvider.provide();
      setBtwSoorten(createReadyRemoteData(btwSoorten));
    })();
  }, []);

  const btwTarievenProvider = useMemo(
    () => props.btwTarievenProvider ?? standaardBtwTarievenProvider,
    [props.btwTarievenProvider],
  );
  useEffect(() => {
    (async () => {
      const btwTarieven = await btwTarievenProvider.provide();
      setBtwTarieven(createReadyRemoteData(btwTarieven));
    })();
  }, []);

  const werkkostenregelingenProvider = useMemo(
    () => props.werkkostenregelingenProvider ?? standaardWerkkostenregelingenProvider,
    [props.werkkostenregelingenProvider],
  );
  useEffect(() => {
    (async () => {
      const werkkostenregelingen = await werkkostenregelingenProvider.provide();
      setWerkkostenregelingen(createReadyRemoteData(werkkostenregelingen));
    })();
  }, []);

  const hoedanighedenProvider = useMemo(
    () => props.hoedanighedenProvider ?? standaardHoedanighedenProvider,
    [props.hoedanighedenProvider],
  );
  useEffect(() => {
    (async () => {
      const hoedanigheden = await hoedanighedenProvider.provide();
      setHoedanigheden(createReadyRemoteData(hoedanigheden));
    })();
  }, []);

  const boekingProvider = useMemo(() => props.boekingProvider ?? standaardBoekingProvider, [
    props.boekingProvider,
  ]);
  useEffect(() => {
    (async () => {
      const boeking = await boekingProvider.provide(props.boekingID ?? null);
      setBoeking(createReadyRemoteData(boeking));
    })();
  }, []);

  const dagboek = useMemo<IRemoteData<IOphalenDagboekenResultElement>>(() => {
    if (
      dagboekID.state === ERemoteDataState.Pending ||
      dagboeken.state === ERemoteDataState.Pending
    ) {
      return createPendingRemoteData();
    }
    return createReadyRemoteData(dagboeken.data!.find((dagboek) => dagboek.ID === dagboekID.data)!);
  }, [dagboekID, dagboeken]);

  const regelsProvider = useMemo(() => props.regelsProvider ?? standaardRegelsProvider, [
    props.regelsProvider,
  ]);
  useEffect(() => {
    if (dagboek.state === ERemoteDataState.Pending) {
      return;
    }

    (async () => {
      const regels = await regelsProvider.provide(props.boekingID ?? null, dagboek.data!);
      setRegels(createReadyRemoteData(regels));
    })();
  }, [dagboek]);

  const isDefinitiefProvider = useMemo(
    () => props.isDefinitiefProvider ?? standaardIsDefinitiefProvider,
    [props.isDefinitiefProvider],
  );
  useEffect(() => {
    if (boeking.state === ERemoteDataState.Pending) {
      return;
    }

    (async () => {
      const isDefinitief = await isDefinitiefProvider.provide({
        boeking: boeking.data,
      });
      setIsDefinitief(createReadyRemoteData(isDefinitief));
    })();
  }, [boeking]);

  const boekstuksaldiProvider = useMemo(
    () => props.boekstuksaldiProvider ?? standaardBoekstuksaldiProvider,
    [props.boekstuksaldiProvider],
  );
  useEffect(() => {
    if (regels.state === ERemoteDataState.Pending || regels.data === null) {
      setBoekstuksaldi(createPendingRemoteData());
      return;
    }
    const boekstukken = regels.data
      .filter((x) => x.regelboekstuk !== null)
      .map((x) => x.regelboekstuk!);

    (async () => {
      const result = await boekstuksaldiProvider.provide(boekstukken);
      setBoekstuksaldi(createReadyRemoteData(result));
    })();
  }, [
    useMemo(() => {
      if (regels.state === ERemoteDataState.Pending || regels.data === null) {
        return null;
      }
      return JSON.stringify(
        regels.data.filter((x) => x.regelboekstuk !== null).map((x) => x.regelboekstuk!),
      );
    }, [regels]),
  ]);

  const regelModificatieNaOpslaanProvider = useMemo(
    () => props.regelModificatieNaOpslaanProvider ?? standaardRegelModificatieNaOpslaanProvider,
    [props.regelModificatieNaOpslaanProvider],
  );

  // Bij het veranderen van de regels
  useEffect(() => {
    if (regels.state === ERemoteDataState.Pending || regels.data === null) {
      return;
    }

    if (regels.data.length === 0 && geselecteerdRegelID !== null) {
      setGeselecteerdRegelID(null);
      return;
    }

    const heeftMomenteelEenGeldigeSelectie =
      geselecteerdRegelID !== null && regels.data.some((x) => x.id.waarde === geselecteerdRegelID);
    if (heeftMomenteelEenGeldigeSelectie) {
      return;
    }

    const eersteRegel = regels.data[0];
    setGeselecteerdRegelID(eersteRegel.id.waarde);
  }, [
    useMemo(() => {
      if (regels.state === ERemoteDataState.Pending || regels.data === null) {
        return null;
      }
      return JSON.stringify(regels.data.map((x) => x.id.waarde));
    }, [regels]),
  ]);

  const bezigMetBepalenFactIDs = useRef<Set<number>>(new Set());
  const bepalenFactIDs = useCallback(
    async (factIDs: number[]) => {
      const teBepalenFactIDs = factIDs.filter(
        (factID) =>
          facturenCache[factID] === undefined && !bezigMetBepalenFactIDs.current.has(factID),
      );

      if (teBepalenFactIDs.length === 0) {
        return;
      }

      bezigMetBepalenFactIDs.current = new Set([
        ...bezigMetBepalenFactIDs.current,
        ...teBepalenFactIDs,
      ]);

      const result = await api.v2.factuur.ophalenFacturenBasis({
        filterSchema: {
          filters: [
            {
              naam: 'IDS',
              data: teBepalenFactIDs,
            },
          ],
        },
      });
      setFacturenCache((base) =>
        result.facturen.reduce(
          (acc, curr) => ({
            ...acc,
            [curr.FactID]: curr,
          }),
          base,
        ),
      );
    },
    [facturenCache, setFacturenCache],
  );

  const bezigMetBepalenInkFactIDs = useRef<Set<number>>(new Set());
  const bepalenInkFactIDs = useCallback(
    async (inkFactIDs: number[]) => {
      const teBepalenInkFactIDs = inkFactIDs.filter(
        (inkFactID) =>
          inkoopfacturenCache[inkFactID] === undefined &&
          !bezigMetBepalenInkFactIDs.current.has(inkFactID),
      );
      if (teBepalenInkFactIDs.length === 0) {
        return;
      }

      bezigMetBepalenInkFactIDs.current = new Set([
        ...bezigMetBepalenInkFactIDs.current,
        ...teBepalenInkFactIDs,
      ]);

      const result = await api.v2.inkoopfactuur.ophalenFacturen({
        filterSchema: {
          filters: [
            {
              naam: 'IDS',
              data: teBepalenInkFactIDs,
            },
          ],
        },
      });

      setInkoopfacturenCache((base) =>
        result.facturen.reduce(
          (acc, curr) => ({
            ...acc,
            [curr.InkFactID]: curr,
          }),
          base,
        ),
      );
    },
    [inkoopfacturenCache, setInkoopfacturenCache],
  );

  const bezigMetBepalenBankMutIDs = useRef<Set<number>>(new Set());
  const bepalenBankMutIDs = useCallback(
    async (bankMutIDs: number[]) => {
      const teBepalenBankMutIDs = bankMutIDs.filter(
        (bankMutID) =>
          bankmutatiesCache[bankMutID] === undefined &&
          !bezigMetBepalenBankMutIDs.current.has(bankMutID),
      );

      if (teBepalenBankMutIDs.length === 0) {
        return;
      }

      bezigMetBepalenBankMutIDs.current = new Set([
        ...bezigMetBepalenBankMutIDs.current,
        ...teBepalenBankMutIDs,
      ]);

      const result = await api.v2.bank.mutatie.ophalenMutaties({
        filterSchema: {
          filters: [
            {
              naam: 'IDS',
              data: teBepalenBankMutIDs,
            },
          ],
        },
      });

      setBankmutatiesCache((base) =>
        result.mutaties.reduce(
          (acc, curr) => ({
            ...acc,
            [curr.BankMutID]: curr,
          }),
          base,
        ),
      );
    },
    [bankmutatiesCache, setBankmutatiesCache],
  );

  const bezigMetBepalenBetRglIDs = useRef<Set<number>>(new Set());
  const bepalenBetRglIDs = useCallback(
    async (betRglIDs: number[]) => {
      const teBepalenBetRglIDs = betRglIDs.filter(
        (betRglID) =>
          betalingsregelingenCache[betRglID] === undefined &&
          !bezigMetBepalenBetRglIDs.current.has(betRglID),
      );

      if (teBepalenBetRglIDs.length === 0) {
        return;
      }

      bezigMetBepalenBetRglIDs.current = new Set([
        ...bezigMetBepalenBetRglIDs.current,
        ...teBepalenBetRglIDs,
      ]);

      const result = await api.v2.debiteur.betalingsregeling.ophalenBetalingsregelingen({
        filterSchema: {
          filters: [
            {
              naam: 'IDS',
              data: teBepalenBetRglIDs,
            },
          ],
        },
      });

      setBetalingsregelingenCache((base) =>
        result.betalingsregelingen.reduce(
          (acc, curr) => ({
            ...acc,
            [curr.ID]: curr,
          }),
          base,
        ),
      );
    },
    [betalingsregelingenCache, setBetalingsregelingenCache],
  );

  // Cache van facturen, inkoopfacturen en bankmutaties
  useEffect(() => {
    if (regels.state === ERemoteDataState.Pending) {
      return;
    }
    const factIDs = regels
      .data!.filter(
        (regel) =>
          regel.regelboekstuk !== null && regel.regelboekstuk.type === ERegelboekstukType.Factuur,
      )
      .map((regel) => (regel.regelboekstuk as IRegelboekstukFactuur).factID);

    if (factIDs.length > 0) {
      bepalenFactIDs(factIDs);
    }

    const inkFactIDs = regels
      .data!.filter(
        (regel) =>
          regel.regelboekstuk !== null &&
          regel.regelboekstuk.type === ERegelboekstukType.Inkoopfactuur,
      )
      .map((regel) => (regel.regelboekstuk as IRegelboekstukInkoopfactuur).inkFactID);

    if (inkFactIDs.length > 0) {
      bepalenInkFactIDs(inkFactIDs);
    }

    const bankMutIDs = regels
      .data!.filter(
        (regel) =>
          regel.regelboekstuk !== null &&
          regel.regelboekstuk.type === ERegelboekstukType.Bankmutatie,
      )
      .map((regel) => (regel.regelboekstuk as IRegelboekstukBankmutatie).bankMutID);

    if (bankMutIDs.length > 0) {
      bepalenBankMutIDs(bankMutIDs);
    }

    const betRglIDs = regels
      .data!.filter(
        (regel) =>
          regel.regelboekstuk !== null &&
          regel.regelboekstuk.type === ERegelboekstukType.Betalingsregeling,
      )
      .map((regel) => (regel.regelboekstuk as IRegelboekstukBetalingsregeling).betRglID);

    if (betRglIDs.length > 0) {
      bepalenBetRglIDs(betRglIDs);
    }
  }, [regels]);

  const geselecteerdeRegel = useMemo<IRemoteData<Regel | null>>(() => {
    if (regels.state === ERemoteDataState.Pending) {
      return createPendingRemoteData();
    }
    if (geselecteerdRegelID === null) {
      return createReadyRemoteData(null);
    }
    const regel = regels.data!.find((regel) => regel.id.waarde === geselecteerdRegelID) ?? null;
    return createReadyRemoteData(regel);
  }, [regels, geselecteerdRegelID]);

  const geselecteerdPreviewItemID = useMemo<IRemoteData<string | null>>(() => {
    if (geselecteerdeRegel.state === ERemoteDataState.Pending) {
      return createPendingRemoteData();
    }
    if (geselecteerdeRegel.data === null) {
      return createReadyRemoteData(null);
    }
    const previewItemID = geselecteerdPreviewItemIDs[geselecteerdeRegel.data!.id.waarde] ?? null;
    return createReadyRemoteData(previewItemID);
  }, [geselecteerdeRegel, geselecteerdPreviewItemIDs]);

  const handleGeselecteerdPreviewItemIDChange = useCallback(
    (previewItemID: string | null) => {
      if (
        geselecteerdeRegel.state === ERemoteDataState.Pending ||
        geselecteerdeRegel.data === null
      ) {
        throw new Error(`Er is geen regel gekozen, dit kan niet voorkomen`);
      }
      const geselecteerdeRegelID = geselecteerdeRegel.data!.id.waarde;
      setGeselecteerdPreviewItemIDs((curr) => ({
        ...curr,
        [geselecteerdeRegelID]: previewItemID,
      }));
    },
    [geselecteerdeRegel, setGeselecteerdPreviewItemIDs],
  );

  const nogTeBoeken = useMemo<IRemoteData<number>>(() => {
    if (regels.state === ERemoteDataState.Pending) {
      return createPendingRemoteData();
    }
    const nogTeBoeken =
      Math.round(regels.data!.reduce((acc, curr) => acc + curr.bedrag, 0) * 100) / 100;
    return createReadyRemoteData(nogTeBoeken);
  }, [regels]);

  const isDefinitiefWeergeven = useMemo<IRemoteData<boolean>>(() => {
    if (
      nogTeBoeken.state === ERemoteDataState.Pending ||
      isDefinitief.state === ERemoteDataState.Pending
    ) {
      return createPendingRemoteData();
    }
    return createReadyRemoteData(nogTeBoeken.data! === 0 || isDefinitief.data!);
  }, [nogTeBoeken, isDefinitief]);

  const isDefinitiefMuteerbaar = useMemo<IRemoteData<boolean>>(() => {
    if (
      isDefinitiefWeergeven.state === ERemoteDataState.Pending ||
      isDefinitief.state === ERemoteDataState.Pending ||
      regels.state === ERemoteDataState.Pending
    ) {
      return createPendingRemoteData();
    }
    return createReadyRemoteData(
      isDefinitiefWeergeven.data! &&
        regels.data!.length > 0 &&
        !regels.data!.some((x) => x.state === ERegelstate.Muteren),
    );
  }, [isDefinitiefWeergeven, isDefinitief, regels]);

  const magSubmitten = useMemo(() => {
    if (
      boeking.state === ERemoteDataState.Pending ||
      isDefinitief.state === ERemoteDataState.Pending ||
      grootboeken.state === ERemoteDataState.Pending
    ) {
      return false;
    }
    // Minimaal 1 regel en niet bezig met bewerkingsmodus
    if (
      regels.state === ERemoteDataState.Pending ||
      regels.data!.some((x) => x.state === ERegelstate.Muteren)
    ) {
      return false;
    }
    if (props.boekingID === null && regels.data!.length === 0) {
      return false;
    }
    return !isAanHetLaden;
  }, [isAanHetLaden, regels, boeking, isDefinitief, grootboeken, props.boekingID]);

  const handleSubmit = useCallback(async () => {
    setIsAanHetLaden(true);

    // Als er geen regels opgegeven zijn, maar het wel een bestaande boeking is, dan willen we de boeking verwijderen
    if (props.boekingID !== undefined && regels.data!.length === 0) {
      if (
        (
          await checkStore.bevestigen({
            titel: 'Boeking verwijderen?',
            inhoud: `Je hebt voor deze bestaande boeking geen regels opgeven, daarom zal deze boeking worden verwijderd bij het opslaan.`,
          })
        ).type === EResultType.Annuleren
      ) {
        setIsAanHetLaden(false);
        return;
      }

      const boekingWasDefinitief = boeking.data!.Definitief;
      if (boekingWasDefinitief) {
        const checkData = await api.v2.boeking.checkDefinitiefOngedaanMakenBoekingen({
          ids: [props.boekingID],
        });
        if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
          setIsAanHetLaden(false);
          return;
        }

        await api.v2.boeking.definitiefOngedaanMakenBoekingen({
          ids: [props.boekingID],
        });
      }

      const checkData = await api.v2.boekhouding.boeking.checkVerwijderenBoekingen({
        ids: [props.boekingID],
      });

      if (
        (
          await checkStore.controleren({
            checkData,
          })
        ).type === EResultType.Annuleren
      ) {
        setIsAanHetLaden(false);
        return;
      }

      await api.v2.boekhouding.boeking.verwijderenBoekingen({
        ids: [props.boekingID],
      });

      await props.onSuccess({});

      setIsAanHetLaden(false);
      return;
    }

    // Als het nog te boeken bedrag 0 is, en de boeking dus definitief kan worden gemaakt,
    // maar dit niet door de gebruiker is opgegeven, willen we controleren of de gebruiker
    // dit toch had willen doen
    let boekingDefinitiefMaken = isDefinitief.data!;
    if (nogTeBoeken.data! === 0 && !boekingDefinitiefMaken) {
      const keuze = await globaleRenderer.render<EBoekingAlsnogDefinitiefMakenKeuze | null>(
        (renderProps) => (
          <BoekingAlsnogDefinitiefMakenDialoog
            open
            dialoogIndex={(props.dialoogIndex ?? 0) + 1}
            onSuccess={(result) => renderProps.destroy(result)}
            onAnnuleren={() => renderProps.destroy(null)}
          />
        ),
      );

      if (keuze === null) {
        setIsAanHetLaden(false);
        return;
      }

      if (keuze === EBoekingAlsnogDefinitiefMakenKeuze.Ja) {
        setIsDefinitief(createReadyRemoteData(true));
        boekingDefinitiefMaken = true;
      }
    }

    const moetBoekingDefinitiefOngedaanMaken = boeking.data !== null && boeking.data!.Definitief;
    const boekingDefinitiefGemuteerdDoorGebruiker =
      boeking.data !== null && boeking.data!.Definitief !== boekingDefinitiefMaken;

    // Een bestaande boeking die al definitief is gemarkeerd, waarbij dit nu niet meer het geval
    // moet zijn, dan vragen om bevestiging terugdraaien
    if (moetBoekingDefinitiefOngedaanMaken) {
      if (boekingDefinitiefGemuteerdDoorGebruiker) {
        const result = await checkStore.bevestigen({
          inhoud: 'Bevestigen terugdraaien definitieve boeking',
        });

        if (result.type === EResultType.Annuleren) {
          setIsAanHetLaden(false);
          return;
        }
      }

      const checkData = await api.v2.boeking.checkDefinitiefOngedaanMakenBoekingen({
        ids: [boeking.data!.ID],
      });
      if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
        setIsAanHetLaden(false);
        return;
      }

      await api.v2.boeking.definitiefOngedaanMakenBoekingen({
        ids: [boeking.data!.ID],
      });
    }

    const parseOmschrijving = (input: string | null) => {
      if (input === null) {
        return null;
      }
      const trimmed = input.trim();
      if (trimmed === '') {
        return null;
      }
      return trimmed;
    };

    const splistenGrootboek = grootboeken.data!.find((x) => x.NaamEnum === 'SPLITSEN')!;

    const isPotentieleTegenRegel = (
      potentieleTegenregel: IWeergaveRegel,
      potentieleBasisregel: IWeergaveRegel,
    ): boolean => {
      // Een potentiele tegenregel mag een bankmutatie boekstuk hebben, zolang de andere (basis)regel zelf geen bankmutatieregel is
      if (potentieleTegenregel.regelboekstuk !== null) {
        if (potentieleTegenregel.regelboekstuk.type !== ERegelboekstukType.Bankmutatie) {
          // Deze regel heeft een boekstuk wat geen bankmutatie is
          return false;
        }
        if (
          potentieleBasisregel.regelboekstuk !== null &&
          potentieleBasisregel.regelboekstuk.type === ERegelboekstukType.Bankmutatie
        ) {
          // De andere (basis)regel heeft een bankmutatie boekstuk
          return false;
        }

        // De andere (basis)regel heeft geen boekstuk, of een boekstuk dat geen bankmutatie is
      }

      return (
        potentieleTegenregel.relID === null &&
        potentieleTegenregel.werkkostenregelingID === null &&
        parseOmschrijving(potentieleTegenregel.omschrijving) === null &&
        potentieleTegenregel.btwSoortID === null &&
        !potentieleTegenregel.storno
      );
    };

    let nieuweRegels: IVastleggenBoekingsregel[];
    // Comprimeren, er zijn 2 regels en gezamenlijk is het bedrag 0, dan kunnen we comprimeren
    if (
      regels.data!.length === 2 &&
      regels.data!.reduce((acc, curr) => acc + curr.bedrag, 0) === 0 &&
      (isPotentieleTegenRegel(
        regels.data![0] as IWeergaveRegel,
        regels.data![1] as IWeergaveRegel,
      ) ||
        isPotentieleTegenRegel(
          regels.data![1] as IWeergaveRegel,
          regels.data![0] as IWeergaveRegel,
        ))
    ) {
      // Probeer het externe ID te ontfutselen uit een van de regels
      const id =
        regels.data![0].id.type === ERegelIDType.Extern
          ? (regels.data![0].id as IRegelIDExtern).waarde
          : regels.data![1].id.type === ERegelIDType.Extern
          ? (regels.data![1].id as IRegelIDExtern).waarde
          : null;

      const tegenRegelIdx = isPotentieleTegenRegel(
        regels.data![0] as IWeergaveRegel,
        regels.data![1] as IWeergaveRegel,
      )
        ? 0
        : 1;
      const basisRegelIdx = tegenRegelIdx === 0 ? 1 : 0;
      const basisRegel = regels.data![basisRegelIdx] as IWeergaveRegel;
      const tegenRegel = regels.data![tegenRegelIdx] as IWeergaveRegel;

      nieuweRegels = [
        {
          id,
          debitGrootboekID: basisRegel.grootboekID,
          creditGrootboekID: tegenRegel.grootboekID,
          storno: basisRegel.storno,
          wkrRegID: basisRegel.werkkostenregelingID,
          omschrijving: parseOmschrijving(basisRegel.omschrijving),
          btwSoortID: basisRegel.btwSoortID,
          factID:
            basisRegel.regelboekstuk !== null &&
            basisRegel.regelboekstuk.type === ERegelboekstukType.Factuur
              ? basisRegel.regelboekstuk.factID
              : null,
          inkFactID:
            basisRegel.regelboekstuk !== null &&
            basisRegel.regelboekstuk.type === ERegelboekstukType.Inkoopfactuur
              ? basisRegel.regelboekstuk.inkFactID
              : null,
          bankMutID:
            basisRegel.regelboekstuk !== null &&
            basisRegel.regelboekstuk.type === ERegelboekstukType.Bankmutatie
              ? basisRegel.regelboekstuk.bankMutID
              : tegenRegel.regelboekstuk !== null &&
                tegenRegel.regelboekstuk.type === ERegelboekstukType.Bankmutatie
              ? tegenRegel.regelboekstuk.bankMutID
              : null,
          betRglID:
            basisRegel.regelboekstuk !== null &&
            basisRegel.regelboekstuk.type === ERegelboekstukType.Betalingsregeling
              ? basisRegel.regelboekstuk.betRglID
              : null,
          bedrag: basisRegel.bedrag,
          relID: basisRegel.relID,
        },
      ];
    } else {
      nieuweRegels = regels.data!.map((x) => {
        const weergaveRegel = x as IWeergaveRegel;
        return {
          id: weergaveRegel.id.type === ERegelIDType.Extern ? weergaveRegel.id.waarde : null,
          debitGrootboekID: weergaveRegel.grootboekID,
          creditGrootboekID: splistenGrootboek.ID,
          bedrag: weergaveRegel.bedrag,
          relID: weergaveRegel.relID,
          factID:
            weergaveRegel.regelboekstuk !== null &&
            weergaveRegel.regelboekstuk.type === ERegelboekstukType.Factuur
              ? weergaveRegel.regelboekstuk.factID
              : null,
          inkFactID:
            weergaveRegel.regelboekstuk !== null &&
            weergaveRegel.regelboekstuk.type === ERegelboekstukType.Inkoopfactuur
              ? weergaveRegel.regelboekstuk.inkFactID
              : null,
          bankMutID:
            weergaveRegel.regelboekstuk !== null &&
            weergaveRegel.regelboekstuk.type === ERegelboekstukType.Bankmutatie
              ? weergaveRegel.regelboekstuk.bankMutID
              : null,
          betRglID:
            weergaveRegel.regelboekstuk !== null &&
            weergaveRegel.regelboekstuk.type === ERegelboekstukType.Betalingsregeling
              ? weergaveRegel.regelboekstuk.betRglID
              : null,
          btwSoortID: weergaveRegel.btwSoortID,
          omschrijving: parseOmschrijving(weergaveRegel.omschrijving),
          storno: weergaveRegel.storno,
          wkrRegID: weergaveRegel.werkkostenregelingID,
        };
      });
    }

    const params: IVastleggenBoekingParams = {
      boekingID: boeking.data === null ? null : boeking.data!.ID,
      dagboekID: dagboekID.data!,
      boekdatum: boekdatum.data!,
      isDefinitief: boekingDefinitiefMaken,
      regels: nieuweRegels,
    };
    const checkVastleggenBoekingResult = await api.v2.boeking.checkVastleggenBoeking(params);
    if (
      (
        await checkStore.controleren({
          checkData: checkVastleggenBoekingResult,
        })
      ).type === EResultType.Annuleren
    ) {
      setIsAanHetLaden(false);
      return;
    }

    const result = await api.v2.boeking.vastleggenBoeking(params);

    if (boekingDefinitiefMaken) {
      await api.v2.boeking.definitiefMakenBoekingen({
        ids: [result.boekingID],
      });
    }

    props.onSuccess({});

    setIsAanHetLaden(false);
  }, [
    props.boekingID,
    regels,
    boeking,
    isDefinitief,
    nogTeBoeken,
    grootboeken,
    dagboekID,
    boekdatum,
    props.onSuccess,
    setIsAanHetLaden,
  ]);

  return (
    <Dialoog
      index={props.dialoogIndex || 0}
      modalProps={{
        dialogClassName: 'modal-boekingsdialoog',
      }}
    >
      <ModalBody
        style={{
          padding: 0,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div className="flex-fill d-flex flex-column">
          <div className="flex-fill d-flex">
            <div className="flex-fill d-flex flex-column">
              <div
                className="d-flex flex-column"
                style={{
                  borderRight: `1px solid ${Kleur.LichtGrijs}`,
                  borderBottom: `1px solid ${Kleur.LichtGrijs}`,
                }}
              >
                <Boekinggegevens
                  isDefinitief={isDefinitief}
                  boekdatum={boekdatum}
                  onBoekdatumChange={(x) => setBoekdatum(createReadyRemoteData(x))}
                  dagboeken={dagboeken}
                  grootboeken={grootboeken}
                  geselecteerdDagboekID={dagboekID}
                  onGeselecteerdDagboekChange={(x) => setDagboekID(createReadyRemoteData(x))}
                  boeking={boeking}
                  dagboek={dagboek}
                  isDefinitiefWeergeven={isDefinitiefWeergeven}
                  nogTeBoeken={nogTeBoeken}
                  isDefinitiefMuteerbaar={isDefinitiefMuteerbaar}
                  onIsDefinitiefChange={(x) => setIsDefinitief(x)}
                />
              </div>
              <div
                className="flex-fill d-flex flex-column"
                style={{
                  borderRight: `1px solid ${Kleur.LichtGrijs}`,
                  borderBottom: `1px solid ${Kleur.LichtGrijs}`,
                }}
              >
                <Boekstukgegevens
                  regels={regels}
                  geselecteerdRegelID={geselecteerdRegelID}
                  onGeselecteerdRegelIDChange={setGeselecteerdRegelID}
                  boekstuksaldi={boekstuksaldi}
                  boekdatum={boekdatum}
                  dagboek={dagboek}
                  facturenCache={facturenCache}
                  inkoopfacturenCache={inkoopfacturenCache}
                  bankmutatiesCache={bankmutatiesCache}
                  betalingsregelingenCache={betalingsregelingenCache}
                />
              </div>
            </div>
            <div
              className="d-flex flex-column"
              style={{
                width: 800,
                height: 600,
                borderBottom: `1px solid ${Kleur.LichtGrijs}`,
              }}
            >
              <Preview
                geselecteerdID={geselecteerdPreviewItemID.data}
                onGeselecteerdIDChange={handleGeselecteerdPreviewItemIDChange}
                boekstuk={
                  geselecteerdeRegel.state === ERemoteDataState.Pending ||
                  geselecteerdeRegel.data === null
                    ? null
                    : geselecteerdeRegel.data!.regelboekstuk
                }
                facturenCache={facturenCache}
                inkoopfacturenCache={inkoopfacturenCache}
                bankmutatiesCache={bankmutatiesCache}
                betalingsregelingenCache={betalingsregelingenCache}
              />
            </div>
          </div>
          <div className="d-flex flex-column" style={{ height: 280 }}>
            <Regels
              isDefinitief={isDefinitief}
              regels={regels}
              onRegelsChange={(x) => setRegels(createReadyRemoteData(x))}
              dagboek={dagboek}
              grootboeken={grootboeken}
              dagboeken={dagboeken}
              btwSoorten={btwSoorten}
              btwTarieven={btwTarieven}
              werkkostenregelingen={werkkostenregelingen}
              hoedanigheden={hoedanigheden}
              nogTeBoeken={nogTeBoeken}
              facturenCache={facturenCache}
              inkoopfacturenCache={inkoopfacturenCache}
              bankmutatiesCache={bankmutatiesCache}
              betalingsregelingenCache={betalingsregelingenCache}
              regelModificatieNaOpslaanProvider={regelModificatieNaOpslaanProvider}
              boekdatum={boekdatum}
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter className="d-flex flex-row justify-content-start">
        <button
          className="btn btn-primary d-flex align-items-center justify-content-center"
          style={{ width: 100 }}
          disabled={!magSubmitten}
          onClick={handleSubmit}
        >
          {isAanHetLaden && (
            <span className="mr-2">
              <LoadingSpinner soort="light" grootte="15px" dikte="2px" />
            </span>
          )}
          Ok
        </button>
        <button
          className="btn btn-secondary ml-2"
          onClick={props.onAnnuleren}
          style={{ width: 100 }}
          disabled={isAanHetLaden}
        >
          Annuleren
        </button>
        <div className="flex-fill" />
      </ModalFooter>
    </Dialoog>
  );
});

export default BoekingDialoogV3;
