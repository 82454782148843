import React, { useCallback, useMemo, useContext, useState } from 'react';
import api from '../../../../../../../../../api';
import { ISectieProps } from '../index';
import {
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
  DXTableToggleCellComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../../../../../../helpers/dxTableGrid';
import {
  Grid,
  Table,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  TableRowDetail,
} from '@devexpress/dx-react-grid-bootstrap4';
import { IconInformatie, IconVerwijderen } from '../../../../../../../../../components/Icons';
// import { IReparatieopdracht } from '../../../../../../../../../../shared/src/api/v2/service/melding';
import { IOphalenOpdrachtenResultElement } from '../../../../../../../../../../../shared/src/api/v2/service/opdracht';

import { DataTypeProvider, EditingState, RowDetailState } from '@devexpress/dx-react-grid';
import { format } from 'date-fns';
import ActieMenuKnop from '../../../../../../../../../components/ActieMenuKnop';
import { formatteerPersoonNaam } from '../../../../../../../../../helpers';
import { Kleur } from '../../../../../../../../../bedrijfslogica/constanten';
import { EResultType } from '../../../../../../../../../stores/CheckStore';
import { RootStoreContext } from '../../../../../../../../../stores/RootStore';
import NieuwOpdrachtDialoog from '../../opdracht/NieuwOpdrachtDialoog';
import WijzigenOpdrachtDialoog from '../../../../../../../../Service/Opdrachten/WijzigenDialoog';
import RelatieVisualisatie from '../../../../../../../../../components/personalia/RelatieVisualisatie';
import OpdrachtInfoDialoog from '../../../../../../../../../components/service/OpdrachtInfoDialoog';
// import NieuwOpdrachtDialoog from './opdracht/NieuwOpdrachtDialoog';

interface IProps extends ISectieProps {}

interface IReparatieopdracht extends IOphalenOpdrachtenResultElement {}

const geenData = {
  noData: 'Geen reparatieopdrachten aanwezig',
};

export interface IReparatieopdrachtDialoogState {
  servMeldID: number;
}

export interface IWijzigenReparatieopdrachtDialoogState {
  servMeldID: number;
}

export interface IServiceopdrachtInfoDialoogState {
  servOpdID: number;
}

const RowDetailComp: React.ComponentType<any> = (props) => {
  const row: IReparatieopdracht = props.row;
  return <span>{row.Omschrijving}</span>;
};

const ReparatieopdrachtSectie: React.FC<IProps> = (props) => {
  const { checkStore } = useContext(RootStoreContext);

  const [
    reparatieopdrachtDialoogState,
    setReparatieopdrachtDialoogState,
  ] = useState<IReparatieopdrachtDialoogState | null>(null);
  const [
    wijzigenReparatieopdrachtDialoogState,
    setWijzigenReparatieopdrachtDialoogState,
  ] = useState<IWijzigenReparatieopdrachtDialoogState | null>(null);
  const [
    serviceopdrachtInfo,
    setServiceopdrachtInfo,
  ] = useState<IServiceopdrachtInfoDialoogState | null>(null);

  const opdrachten = props.melding.reparatieopdrachten;

  const keyExtractor = useCallback((row: IReparatieopdracht) => row.ServOpdID, []);

  const kolommen = useMemo<TypedColumn<IReparatieopdracht>[]>(
    () => [
      {
        name: 'DatumVerstuurd' as any,
        title: 'Verstuurd',
        getCellValue: (x) => {
          const datum =
            x.DatumVerstuurd !== null ? x.DatumVerstuurd : new Date('2099-01-01').toISOString();
          return datum;
        },
      },
      {
        name: '__versturenActie',
        title: ' ',
      },
      {
        name: '__status' as any,
        title: 'Status',
      },
      {
        name: '__opdrachtinfo' as any,
        title: ' ',
      },
      {
        name: '__dienst' as any,
        title: 'Servicebedrijf',
      },
      {
        name: 'Garantieclaim',
        title: 'Gar.',
      },
      {
        name: 'Spoed',
        title: 'Spoed',
      },
      {
        name: '__persoon' as any,
        title: 'Contactpersoon',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IReparatieopdracht>[]>(
    () => [
      {
        columnName: 'DatumVerstuurd',
        width: 135,
      },
      {
        columnName: '__versturenActie' as any,
        width: 100,
      },
      {
        columnName: '__status',
        width: 100,
      },
      {
        columnName: '__opdrachtinfo' as any,
        width: 65,
      },
      {
        columnName: 'Garantieclaim',
        width: 50,
      },
      {
        columnName: 'Spoed',
        width: 60,
      },
      {
        columnName: '__dienst',
        width: 125,
      },
      {
        columnName: '__persoon' as any,
        width: 200,
      },
    ],
    [],
  );

  const handleOpdrachtVersturen = async (id: number) => {
    const checkData = await api.v2.service.checkVersturenServiceOpdrachten({
      ids: [id],
    });
    const controleResultaat = await checkStore.controleren({ checkData });
    if (controleResultaat.type === EResultType.Annuleren) {
      return;
    }

    const resultaat = await checkStore.bevestigen({
      inhoud: `Reparatieopdracht versturen ?`,
    });
    if (resultaat.type === EResultType.Annuleren) {
      return;
    }

    await api.v2.service.versturenServiceOpdrachten({
      ids: [id],
    });

    props.onRequestRefresh();
  };

  const handleVerwijderen = useCallback(
    async (id: number) => {
      const resultaat = await checkStore.bevestigen({
        inhoud: `Service-opdracht verwijderen?`,
      });
      if (resultaat.type === EResultType.Annuleren) {
        return;
      }

      const checkData = await api.v2.service.checkVerwijderenServiceOpdracht({
        IDs: [id],
      });
      const controleResult = await checkStore.controleren({
        checkData,
      });
      if (controleResult.type === EResultType.Annuleren) {
        return;
      }

      await api.v2.service.verwijderenServiceOpdracht({
        IDs: [id],
      });

      props.onRequestRefresh();
    },
    [props.melding, props.onRequestRefresh],
  );

  return (
    <>
      <GridStyleWrapper>
        <Grid getRowId={keyExtractor} rows={opdrachten} columns={kolommen}>
          <DataTypeProvider
            for={['__versturenActie']}
            formatterComponent={(formatterProps) => {
              const row: IReparatieopdracht = formatterProps.row!;

              return (
                <a href="#" onClick={() => handleOpdrachtVersturen(row.ServOpdID)}>
                  Versturen
                </a>

                // <button
                //   className="btn btn-sm btn-light"
                //   style={{ width: 100 }}
                //   // onClick={async (row.ID) => handleOpdrachtVersturen(row.ID)}
                //   onClick={() => handleOpdrachtVersturen(row.ID)}
                // >
                //   Versturen
                // </button>
              );
            }}
          />

          <DataTypeProvider
            for={['__persoon']}
            formatterComponent={(formatterProps) => {
              const row: IReparatieopdracht = formatterProps.row!;

              const persoon =
                row.contactpersoon !== null
                  ? formatteerPersoonNaam({
                      voorletters: row.contactpersoon.Voorletters,
                      voorvoegsel: row.contactpersoon.Voorvoegsel,
                      aanhefKort: row.contactpersoon.geslacht.AanhefKort,
                      voornaam: row.contactpersoon.Voornaam,
                      achternaam: row.contactpersoon.Achternaam,
                    })
                  : '?';

              return <span>{persoon}</span>;
            }}
          />

          <DataTypeProvider
            for={['__status']}
            formatterComponent={(formatterProps) => {
              const row: IReparatieopdracht = formatterProps.row!;
              return <span>{row.status.Naam}</span>;
            }}
          />

          <DataTypeProvider
            for={['__opdrachtinfo']}
            formatterComponent={(formatterProps) => {
              const rij: IReparatieopdracht = formatterProps.row!;

              return (
                <div>
                  <a
                    href="#"
                    className="ml-1"
                    style={{ color: Kleur.LichtBlauw }}
                    onClick={() => {
                      setServiceopdrachtInfo({ servOpdID: rij.ServOpdID });
                    }}
                  >
                    <IconInformatie style={{ width: 15, height: 15, fill: Kleur.Blauw }} />
                  </a>
                </div>
              );
            }}
          />

          <DataTypeProvider
            for={['DatumVerstuurd']}
            formatterComponent={(formatterProps) => {
              const row: IReparatieopdracht = formatterProps.row!;

              return (
                <span>
                  {row.DatumVerstuurd === null ? (
                    <span>Nog versturen</span>
                  ) : (
                    // <a href="#" onClick={() => handleOpdrachtVersturen(row.ID)}>
                    //   Nog versturen
                    // </a>
                    format(new Date(row.DatumVerstuurd), 'dd-MM-yyyy HH:mm')
                  )}
                </span>
              );
            }}
          />

          <DataTypeProvider
            for={['Garantieclaim']}
            formatterComponent={(formatterProps) => {
              const row: IReparatieopdracht = formatterProps.row!;

              return <span>{row.Garantieclaim ? 'Ja' : 'Nee'}</span>;
            }}
          />

          <DataTypeProvider
            for={['Spoed']}
            formatterComponent={(formatterProps) => {
              const row: IReparatieopdracht = formatterProps.row!;

              return <span>{row.Spoed ? 'Ja' : 'Nee'}</span>;
            }}
          />

          <DataTypeProvider
            for={['__dienst']}
            formatterComponent={(formatterProps) => {
              const rij: IReparatieopdracht = formatterProps.row!;

              return (
                <RelatieVisualisatie
                  relID={rij.dienst!.RelID}
                  options={{ geenLinkToepassen: false }}
                />
              );
            }}
          />

          {/* <DataTypeProvider
            for={['LeveranciernaamKort']}
            formatterComponent={(formatterProps) => {
              const row: IReparatieopdracht = formatterProps.row!;

              return <span>{row.dienst.relatie!.weergavenaam}</span>;
            }}
          /> */}

          <RowDetailState defaultExpandedRowIds={[]} />

          <Table messages={geenData} />
          <TableColumnResizing defaultColumnWidths={kolomBreedtes} />

          <EditingState
            // onAddedRowsChange={() => {
            //   return setReparatieopdrachtDialoogState({ servMeldID: props.melding.ID });
            // }}
            onEditingRowIdsChange={(x) => {
              const id = x[x.length - 1] as number;
              setWijzigenReparatieopdrachtDialoogState({ servMeldID: id });
            }}
            onCommitChanges={(changes) => {
              if (changes.deleted === undefined) {
                return;
              }
              const deleted = changes.deleted;
              const id = deleted[deleted.length - 1] as number;
              handleVerwijderen(id);
            }}
          />
          <TableEditColumn
            width={60}
            // showAddCommand
            showDeleteCommand
            showEditCommand
            cellComponent={DXTableEditColumnCellComponent}
            commandComponent={DXTableEditColumnCommandComponent}
          />

          <TableHeaderRow />

          <TableRowDetail
            contentComponent={RowDetailComp}
            toggleCellComponent={DXTableToggleCellComponent}
          />
        </Grid>
      </GridStyleWrapper>

      {reparatieopdrachtDialoogState !== null && (
        <NieuwOpdrachtDialoog
          open
          onSuccess={async () => {
            setReparatieopdrachtDialoogState(null);
            props.onRequestRefresh();
          }}
          onAnnuleren={() => setReparatieopdrachtDialoogState(null)}
          servMeldID={reparatieopdrachtDialoogState.servMeldID}
          cntID={null}
        />
      )}
      {wijzigenReparatieopdrachtDialoogState !== null && (
        <WijzigenOpdrachtDialoog
          open
          id={wijzigenReparatieopdrachtDialoogState.servMeldID}
          onSuccess={async () => {
            setWijzigenReparatieopdrachtDialoogState(null);
            props.onRequestRefresh();
          }}
          onAnnuleren={() => setWijzigenReparatieopdrachtDialoogState(null)}
        />
      )}
      {serviceopdrachtInfo !== null && (
        <OpdrachtInfoDialoog
          open
          id={serviceopdrachtInfo.servOpdID}
          onSuccess={() => setServiceopdrachtInfo(null)}
          onAnnuleren={() => setServiceopdrachtInfo(null)}
        />
      )}
    </>
  );
};

export default ReparatieopdrachtSectie;
