import React, { useContext, useEffect, useMemo, useState, useCallback } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import MenuLayout from '../../../components/MenuLayout';
import LoadingSpinner from '../../../components/Gedeeld/LoadingSpinner';
import useUrlState from '../../../core/useUrlState';
import api from '../../../api';
import { IOphalenContractenResultElementV2 } from '../../../../../shared/src/api/v2/contract';
import { EContractStatus } from '../../../paginas/Klant/Klantkaart/Entiteiten/entiteiten/Contracten/Overzicht/ContractTegel/ContractStatus';
import { Kleur } from '../../../bedrijfslogica/constanten';
import {
  DXTableCheckboxComponent,
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
  DXTableToggleCellComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../helpers/dxTableGrid';
import {
  Grid,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  DataTypeProvider,
  EditingState,
  IntegratedSorting,
  RowDetailState,
  SelectionState,
  SortingState,
  VirtualTable as VirtualTableBase,
} from '@devexpress/dx-react-grid';
import RelatieVisualisatie from '../../../components/personalia/RelatieVisualisatie';
import { format } from 'date-fns';
import _ from 'lodash';
import DetailComp from './DetailComp';
import { RootStoreContext } from '../../../stores/RootStore';
import { EResultType } from '../../../stores/CheckStore';
import KeuzeDialoog, {
  EKeuzeType,
  IOptie,
  ISelectieResult,
} from '../../../components/dialogen/KeuzeDialoog';
import { EKanaal } from '../../../bedrijfslogica/enums';
import { Helmet } from 'react-helmet';
import { achtergrondProcesAfwachten } from '../../../core/achtergrondProces';
import { RealtimeContext } from '../../../one-off-components/realtime/RealtimeIntegratie';

interface IProps extends RouteComponentProps {}

interface IUrlState {
  selectie: number[];
}

const defaultUrlState: IUrlState = { selectie: [] };

export interface IRegel {
  RelID: number;
  Email: string | null;
  Telefoon: string | null;
  AangebodenOp: Date;
  OverdrachtOpgevolgdOp: Date | string | null;
  Overnamecode: string | null;
  Notities: string | null;
  contracten: IOphalenContractenResultElementV2[];
}

export interface IVersturenDialoogState {
  relIDs: number[];
}

const Overdracht: React.FC<IProps> = observer((props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const realtimeContext = useContext(RealtimeContext);
  const { checkStore } = useContext(RootStoreContext);

  const [contracten, setContracten] = useState<IOphalenContractenResultElementV2[] | null>(null);
  const [relaties, setRelaties] = useState<IRegel[] | null>(null);
  const [versturenDialoogState, setVersturenDialoogState] = useState<IVersturenDialoogState | null>(
    null,
  );

  const ophalenContracten = useCallback(async () => {
    const contractenResult = (
      await api.v2.contract.ophalenContractenV2({
        filterSchema: {
          filters: [
            {
              naam: 'AANGEBODEN_VOOR_OVERNAME',
              data: true,
            },
            {
              naam: 'STATUS_NAAMENUM',
              data: [EContractStatus.Lopend],
            },
          ].filter((x) => x !== null),
        },
      })
    ).contracten;

    const contractenGesorteerd = _.orderBy(
      contractenResult,
      ['AangebodenOvernameOp', 'RelID', 'Basisnummer', 'Volgnummer'],
      ['desc', 'asc', 'asc', 'asc'],
    );

    setContracten(contractenGesorteerd);
  }, []);

  useEffect(() => {
    ophalenContracten();
  }, [ophalenContracten]);

  const ophalenRelaties = useCallback(async () => {
    if (contracten === null) {
      return;
    }

    const relIDs: number[] = _.uniq(contracten.map((x) => x.RelID));

    const relatiesResult = (
      await api.v2.relatie.ophalenRelaties({
        filterSchema: {
          filters: [
            {
              naam: 'IDS',
              data: relIDs,
            },
          ],
        },
      })
    ).relaties;
    const relaties = relIDs.map((relID) => {
      const contractenVoorRelatie = contracten.filter((x) => x.RelID === relID);
      const aangebodenOp: Date = _.min(contractenVoorRelatie.map((x) => x.AangebodenOvernameOp));

      const relatie = relatiesResult.find((x) => x.RelID === relID)!;

      return {
        RelID: relID,
        weergavenaam: relatie.weergavenaam,
        Email: relatie.persoon !== null ? relatie.persoon.Email : null,
        Telefoon: relatie.persoon !== null ? relatie.persoon.TelefoonMobiel : null,
        AangebodenOp: aangebodenOp,
        OverdrachtOpgevolgdOp: relatie.OverdrachtOpgevolgdOp,
        Overnamecode: relatie.Overnamecode,
        Notities: relatie.Notities,
        contracten: contractenVoorRelatie,
      };
    });

    const relatiesGesorteerd = _.orderBy(
      relaties,
      [
        (x: any) => {
          return x.AangebodenOp;
        },
        'RelID',
      ],
      ['desc', 'asc'],
    );

    setRelaties(relatiesGesorteerd);
  }, [contracten]);

  useEffect(() => {
    ophalenRelaties();
  }, [ophalenRelaties]);

  const handleVersturen = useCallback(
    async (result: ISelectieResult<EKanaal>) => {
      const params = {
        relIDs: urlState.selectie,
        kanalen: result.keuze,
      };

      const achtergrondProces = await api.v2.contract.aanbieding.versturenHerinnering(params);
      await achtergrondProcesAfwachten(achtergrondProces.id, realtimeContext);

      setUrlStateSync('selectie', []);

      setVersturenDialoogState(null);

      await ophalenContracten();
    },
    [urlState.selectie, realtimeContext],
  );

  // const handleVersturen = useCallback(async () => {
  //   // const params = { urlState.selectie, kanalen: ['EMAIL'] };
  //   //
  //   // alert('Nog implementeren' + relIDs);

  //   // const checkData = await api.v2.factuur.checkVersturenFacturen(params);
  //   // if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
  //   //   return;
  //   // }
  //   // if (
  //   //   (
  //   //     await checkStore.bevestigen({
  //   //       titel: <span>Herinneringen versturen?</span>,
  //   //     })
  //   //   ).type === EResultType.Annuleren
  //   // ) {
  //   //   return;
  //   // }

  //   // await api.v2.contract.aanbieding.versturenHerinnering(params);

  //   // setUrlStateSync('selectie', []);

  //   await ophalenContracten();
  // }, []);

  const keyExtractor = useCallback((row: IRegel) => row.RelID, []);

  const kolommen = useMemo<TypedColumn<IRegel>[]>(
    () => [
      {
        name: '__relatie' as any,
        title: 'Relatie',
        getCellValue: (x: any) => {
          return x.weergavenaam;
        },
      },
      {
        name: 'Overnamecode',
        title: 'Overnamecode',
      },
      // {
      //   name: 'Email',
      //   title: 'Email',
      // },
      // {
      //   name: 'Telefoon',
      //   title: 'Telefoon',
      // },
      {
        name: '__aantalContracten' as any,
        title: '# Cnt.',
        getCellValue: (x: any) => {
          return x.contracten.length;
        },
      },
      {
        name: 'AangebodenOp',
        title: 'Eerst aangeboden',
        getCellValue: (x) => x.AangebodenOp,
      },
      {
        name: 'OverdrachtOpgevolgdOp',
        title: 'Laatst opgevolgd',
      },
      {
        name: 'Notities',
        title: 'Notities',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRegel>[]>(
    () => [
      {
        columnName: '__relatie' as any,
        width: 300,
      },
      // {
      //   columnName: 'Email',
      //   width: 225,
      // },
      {
        columnName: 'Overnamecode',
        width: 175,
      },
      // {
      //   columnName: 'Telefoon',
      //   width: 160,
      // },
      {
        columnName: '__aantalContracten' as any,
        width: 90,
      },
      {
        columnName: 'AangebodenOp',
        width: 150,
      },
      {
        columnName: 'OverdrachtOpgevolgdOp',
        width: 150,
      },
      {
        columnName: 'Notities',
        width: 500,
      },
    ],
    [],
  );

  const kanaalOpties = useMemo<IOptie<EKanaal>[]>(() => {
    return [
      {
        id: EKanaal.Email,
        label: 'Email',
      },

      {
        id: EKanaal.SMS,
        label: 'SMS',
      },
    ].filter((x) => x !== null) as IOptie<EKanaal>[];
  }, []);

  return (
    <>
      <Helmet>
        <title>Overdrachten</title>
      </Helmet>
      <MenuLayout
        menu={
          <>
            <div className="mt-2 d-flex align-items-center">
              <button
                className="btn btn-sm btn-light d-flex align-items-center"
                style={{
                  border: `1px solid ${Kleur.LichtGrijs}`,
                }}
                disabled={urlState.selectie.length === 0}
                onClick={() => {
                  setVersturenDialoogState({
                    relIDs: urlState.selectie,
                  });
                  // handleVersturen(urlState.selectie);
                }}
              >
                <span>
                  {/* <IconVerwijderen style={{ width: 16, height: 16, fill: Kleur.Grijs }} /> */}
                </span>
                <span className="ml-2">Versturen herinneringen</span>
              </button>
              {/* <div className="ml-3">
                Aantal: {relaties !== null ? relaties.length : ''} relaties
              </div> */}
            </div>
          </>
        }
        body={
          relaties === null ? (
            <div className="flex-fill d-flex align-items-center justify-content-center">
              <LoadingSpinner />
            </div>
          ) : (
            <GridStyleWrapper height={'calc(100vh - 150px)'}>
              <Grid rows={relaties} columns={kolommen} getRowId={keyExtractor}>
                <DataTypeProvider
                  for={['__relatie']}
                  formatterComponent={(props) => {
                    return (
                      <RelatieVisualisatie
                        relID={props.row.RelID}
                        relatieLinkBuilder={(hoedanigheid, relID) =>
                          `/klant/${relID}/contracten/aangeboden`
                        }
                      />
                    );
                  }}
                />

                <DataTypeProvider
                  for={['__aantalContracten']}
                  formatterComponent={(props) => {
                    const rij: IRegel = props.row;
                    return <span>{rij.contracten.length}</span>;
                  }}
                />

                <DataTypeProvider
                  for={['AangebodenOp']}
                  formatterComponent={(props) => {
                    const datum = format(new Date(props.value), 'dd-MM-yyyy');
                    return <span>{datum}</span>;
                  }}
                />

                <DataTypeProvider
                  for={['OverdrachtOpgevolgdOp']}
                  formatterComponent={(props) => {
                    if (props.value === null) {
                      return <span></span>;
                    }
                    const datum = format(new Date(props.value), 'dd-MM-yyyy HH:mm');
                    return <span>{datum}</span>;
                  }}
                />

                {/* <RowDetailState defaultExpandedRowIds={[]} /> */}

                <SortingState defaultSorting={[]} />
                <IntegratedSorting />

                <RowDetailState defaultExpandedRowIds={[]} />

                <VirtualTable />
                <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
                <TableHeaderRow showSortingControls />

                <TableRowDetail
                  contentComponent={DetailComp}
                  toggleCellComponent={DXTableToggleCellComponent}
                />

                <SelectionState
                  selection={urlState.selectie}
                  onSelectionChange={(x) => setUrlStateSync('selectie', x as number[])}
                />
                <TableSelection cellComponent={DXTableCheckboxComponent} />
              </Grid>
            </GridStyleWrapper>
          )
        }
      />
      {versturenDialoogState !== null && (
        <KeuzeDialoog
          titel="Herinneringen versturen via"
          type={EKeuzeType.Enkel}
          opties={kanaalOpties}
          open
          onSuccess={handleVersturen}
          onAnnuleren={() => setVersturenDialoogState(null)}
          enkelOptions={{
            beginSelectie: EKanaal.SMS,
          }}
        />
      )}
    </>
  );
});

export default Overdracht;
