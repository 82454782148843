import React from 'react';
import { Route, Link, RouteComponentProps, NavLink, Redirect } from 'react-router-dom';
import PaginaHeader from '../PaginaHeader';
import { EFunctioneleIcon, functioneleIconMap, IconReview } from '../../components/Icons';
import Overzicht from './Overzicht';
import Klantmutaties from './Klantmutaties';
import AccountLogging from './AccountLogging';
import Contracten from './Contracten';
import Transport from './Transport';
import Acceptatie from '../Acceptatie';
import Relaties from './Relaties';
import Website from './Website';
import Contacten from './Contacten';
import Events from './Events';
import Winkelwagens from './Winkelwagens';
import Vertegenwoordigingen from './Vertegenwoordigingen';

interface IProps extends RouteComponentProps {}

const Icon = functioneleIconMap[EFunctioneleIcon.Opvolgen];

const Monitoring = (props: IProps) => {
  const { match } = props;

  return (
    <>
      <Route path={`${match.path}/contacten`} component={Contacten} />
      <Route path={`${match.path}/winkelwagens`} component={Winkelwagens} />
      <Route path={`${match.path}/website`} component={Website} />
      <Route path={`${match.path}/acceptatie`} component={Acceptatie} />
      <Route path={`${match.path}/account-logging`} component={AccountLogging} />
      <Route path={`${match.path}/vertegenwoordigingen`} component={Vertegenwoordigingen} />
      <Route path={`${match.path}/klantmutaties`} component={Klantmutaties} />

      <Route path={`${match.path}/overzicht`} component={Overzicht} />
      <Route path={`${match.path}/contracten`} component={Contracten} />
      <Route path={`${match.path}/transport`} component={Transport} />
      <Route path={`${match.path}/relaties`} component={Relaties} />
      <Route path={`${match.path}/events`} component={Events} />

      <Route
        exact={true}
        path={match.path}
        render={() => <Redirect to={`${match.path}/contracten`} />}
      />
    </>
  );
};

export default Monitoring;
