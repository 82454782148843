import { makeObservable, observable } from 'mobx';
import IRemoteData, { createPendingRemoteData, createReadyRemoteData } from '../models/IRemoteData';
import { IOphalenProducttypenResultElement } from '../../../shared/src/api/v2/product/type';
import * as _ from 'lodash';
import api from '../api';

class ProducttypeStore {
  public producttypen: IRemoteData<
    Record<number, IOphalenProducttypenResultElement>
  > = createPendingRemoteData();
  private ophalenProducttypenQueue: number[] = [];
  private ophalenProducttypenQueueDebounceHandle: number | null = 0;

  constructor() {
    makeObservable(this, {
      producttypen: observable,
    });
  }

  public enqueueOphalenProducttypen = (typeIDs: number[]) => {
    if (this.ophalenProducttypenQueueDebounceHandle !== null) {
      clearTimeout(this.ophalenProducttypenQueueDebounceHandle);
    }
    this.ophalenProducttypenQueue = _.uniq([...this.ophalenProducttypenQueue, ...typeIDs]);
    this.ophalenProducttypenQueueDebounceHandle = setTimeout(
      this.ophalenQueuedProducttypen,
      100,
    ) as any;
  };
  private ophalenQueuedProducttypen = async () => {
    this.ophalenProducttypenQueueDebounceHandle = null;
    const factIDs = this.ophalenProducttypenQueue;

    if (factIDs.length === 0) {
      return;
    }

    this.ophalenProducttypenQueue = [];

    const result = await api.v2.product.type.ophalenProducttypen({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: factIDs,
          },
        ],
      },
    });
    this.producttypen = createReadyRemoteData(
      result.producttypen.reduce((acc, curr) => {
        return {
          ...acc,
          [curr.TypeID]: curr,
        };
      }, this.producttypen.data ?? {}),
    );
  };
}

export default ProducttypeStore;
