import React, { useCallback, useContext, useMemo, useState } from 'react';
import MenuLayout from '../../../../../../components/MenuLayout';
import ASPTabel from '../../../../../../components/tabel/ASPTabel';
import { ASPKolom, EAspKolomBreedteType } from '../../../../../../components/tabel/ASPTabel/types';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../../../../stores/RootStore';
import ASPGebruikerVisualisatie, {
  EModus,
} from '../../../../../../components/ASPGebruiker/ASPGebruikerVisualisatie';
import Skeleton from 'react-loading-skeleton';
import { TelefoonTabbladContext } from '../..';
import { IVoipAccount } from '../../../../../../../../shared/src/api/v2/telefonie';

enum EKolom {
  VigerendeGebruiker,
  Nummer,
  Naam,
  BelActie,
}

interface IProps {}

const InternTabblad = observer((props: IProps) => {
  const { gebruikerStore, voipStore } = useContext(RootStoreContext);
  const telefoonTabbladContext = useContext(TelefoonTabbladContext);

  const gebruikerVoipAccountVigerend = useMemo(() => {
    if (gebruikerStore.gebruiker === null || voipStore.voipAccountsVigerend === null) {
      return null;
    }
    return (
      voipStore.voipAccountsVigerend.find(
        (x) => x.AspGebrID === gebruikerStore.gebruiker!.AspGebrID,
      ) ?? null
    );
  }, [gebruikerStore.gebruiker, voipStore.voipAccountsVigerend]);

  const keyExtractor = useCallback((row: IVoipAccount) => row.VoipAccID, []);
  const kolommen = useMemo<ASPKolom<EKolom, IVoipAccount>[]>(
    () => [
      {
        key: EKolom.Nummer,
        label: 'Nummer',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 100,
        renderer: (row: IVoipAccount) => {
          return row.TelefoonIntern;
        },
      },
      {
        key: EKolom.BelActie,
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 75,
        renderer: (row: IVoipAccount) => {
          if (
            (gebruikerVoipAccountVigerend !== null &&
              gebruikerVoipAccountVigerend.VoipAccID === row.VoipAccID) ||
            telefoonTabbladContext.bellenDisabled
          ) {
            return null;
          }

          return (
            <a
              href="#"
              onClick={async (ev) => {
                await telefoonTabbladContext.bellen(row.TelefoonIntern!);
              }}
            >
              Bel
            </a>
          );
        },
      },
      {
        key: EKolom.Naam,
        label: 'Naam',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 200,
        renderer: (row: IVoipAccount) => {
          return row.Naam;
        },
      },
      {
        key: EKolom.VigerendeGebruiker,
        label: 'Vigerende gebruiker',
        breedteType: EAspKolomBreedteType.Flex,
        flex: 1,
        renderer: (item) => {
          if (voipStore.voipAccountsVigerend === null) {
            return <Skeleton />;
          }

          const voipAccountVigerend = voipStore.voipAccountsVigerend.find(
            (x) => x.VoipAccID === item.VoipAccID,
          );

          if (voipAccountVigerend === undefined) {
            return <span>-&nbsp;-</span>;
          }

          return (
            <ASPGebruikerVisualisatie
              aspGebrID={voipAccountVigerend.AspGebrID}
              modus={EModus.Volledig}
            />
          );
        },
      },
    ],
    [
      voipStore.voipAccountsVigerend,
      telefoonTabbladContext.bellenDisabled,
      gebruikerVoipAccountVigerend,
    ],
  );

  const rijen = useMemo(() => {
    if (voipStore.voipAccounts === null) {
      return null;
    }
    return voipStore.voipAccounts.reduce(
      (acc, curr, i) => ({
        ...acc,
        [i]: curr,
      }),
      {},
    );
  }, [voipStore.voipAccounts]);

  return (
    <MenuLayout
      menu={<div />}
      body={
        <ASPTabel
          rijen={rijen ?? {}}
          kolommen={kolommen}
          keyExtractor={keyExtractor}
          totaalAantalRijen={rijen === null ? 5 : undefined}
        />
      }
    />
  );
});

export default InternTabblad;
