import { IRealtimeContext } from '../one-off-components/realtime/RealtimeIntegratie';
import { v4 as uuidv4 } from 'uuid';
import {
  IAchtergrondProcesDataMessageData,
  IAchtergrondProcesProgressieMessageData,
} from '../../../shared/src/api/v2/achtergrondProces';

export const achtergrondProcesAfwachten = async <TResult>(
  id: string,
  realtimeContext: IRealtimeContext,
  onProgressie?: (percentage: number) => void,
): Promise<IAchtergrondProcesDataMessageData<TResult>> => {
  const listenerId = uuidv4();
  let resolvePromise: (value: IAchtergrondProcesDataMessageData<TResult>) => void;

  const promise = new Promise<IAchtergrondProcesDataMessageData<TResult>>((resolve) => {
    resolvePromise = resolve;
  });

  realtimeContext.registerListener(listenerId, (naamEnum: string, data: any) => {
    if (onProgressie !== undefined && naamEnum === 'ACHTERGROND_PROCES_PROGRESSIE') {
      const d: IAchtergrondProcesProgressieMessageData = data;
      if (d.id === id) {
        onProgressie(d.percentage);
      }
    }
    if (naamEnum === 'ACHTERGROND_PROCES_DATA') {
      const d: IAchtergrondProcesDataMessageData<TResult> = data;
      if (d.id === id) {
        resolvePromise(data);
      }
    }
  });

  const result = await promise;
  realtimeContext.disposeListener(listenerId);
  return result;
};
