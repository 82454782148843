import { observer } from 'mobx-react-lite';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IEntiteitProps } from '../../../../../../../components/kaart/EntiteitContainer';
import {
  ASPKolom,
  EAspKolomBreedteType,
  ESortering,
} from '../../../../../../../components/tabel/ASPTabel/types';
import { IOphalenFactuurkenmerkenResultElement } from '../../../../../../../../../shared/src/api/v2/rubriek/factuurkenmerk';
import MenuLayout from '../../../../../../../components/MenuLayout';
import api from '../../../../../../../api';
import LoadingSpinner from '../../../../../../../components/Gedeeld/LoadingSpinner';
import ASPTabel from '../../../../../../../components/tabel/ASPTabel';
import useUrlState from '../../../../../../../core/useUrlState';
import { Sorting } from '@devexpress/dx-react-grid';
import Rubrieken from '..';
import { GlobaleRendererContext } from '../../../../../../../one-off-components/GlobaleRenderer';
import Dialoog from '../../../../../../../components/dialogen/Dialoog';
import MutatieDialoog from './MutatieDialoog';
import { RootStoreContext } from '../../../../../../../stores/RootStore';
import { EResultType } from '../../../../../../../stores/CheckStore';
import { Kleur } from '../../../../../../../bedrijfslogica/constanten';
import { IconToevoegen } from '../../../../../../../components/Icons';

interface IProps extends RouteComponentProps {
  relID: number;
}
enum EKolom {
  Naam,
}

export interface IUrlState {
  selectie: number[];
  // sortering: Sorting[];
}

const Factuurkenmerken: React.FC<IProps> = (props) => {
  const { checkStore } = useContext(RootStoreContext);
  const globaleRenderer = useContext(GlobaleRendererContext);
  const defaultUrlState = useMemo<IUrlState>(
    () => ({
      selectie: [],
      // sortering: [
      //   {
      //     key: EKolom.Naam,
      //     sortering: ESortering.Ascending,
      //   },
      // ],
    }),
    [],
  );

  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);

  const [factuurkenmerken, setFactuurkenmerken] = useState<
    IOphalenFactuurkenmerkenResultElement[] | null
  >(null);

  const ophalenFactuurkenmerken = useCallback(async () => {
    const result = await api.v2.factuur.rubriek.factuurkenmerk.ophalenFactuurkenmerken({
      filterSchema: {
        filters: [
          {
            naam: 'REL_IDS',
            data: [props.relID],
          },
        ],
      },
    });

    setFactuurkenmerken(result.factuurkenmerken);
  }, []);

  useEffect(() => {
    ophalenFactuurkenmerken();
  }, []);

  const FactuurkenmerkenRijen = useMemo(() => {
    if (factuurkenmerken === null) {
      return null;
    }

    const FactuurkenmerkenRijen = factuurkenmerken.reduce((acc, kostenplaats, idx) => {
      return {
        ...acc,
        [idx]: kostenplaats,
      };
    }, {});

    return FactuurkenmerkenRijen;
  }, [factuurkenmerken]);

  const keyExtractor = useCallback((item: IOphalenFactuurkenmerkenResultElement) => item.ID, []);

  const kolommen = useMemo<ASPKolom<EKolom, IOphalenFactuurkenmerkenResultElement>[]>(
    () => [
      {
        key: EKolom.Naam,
        label: 'Naam',
        breedteType: EAspKolomBreedteType.Flex,
        // vasteBreedte: 300,
        flex: 1,
        renderer: (item: IOphalenFactuurkenmerkenResultElement) => {
          return item.Naam;
        },
      },
    ],
    [],
  );

  const handleNieuwFactuurkenmerk = useCallback(async (id) => {
    const result = await globaleRenderer.render<null>((renderProps) => (
      <MutatieDialoog
        id={id}
        relID={props.relID}
        open
        onSuccess={async (result) => {
          renderProps.destroy(result);
          await ophalenFactuurkenmerken();
        }}
        onAnnuleren={() => renderProps.destroy()}
      />
    ));
  }, []);

  const handleWijzigenFactuurkenmerk = useCallback(async (id) => {
    const result = await globaleRenderer.render<null>((renderProps) => (
      <MutatieDialoog
        id={id}
        relID={props.relID}
        open
        onSuccess={async (result) => {
          renderProps.destroy(result);
          await ophalenFactuurkenmerken();
        }}
        onAnnuleren={() => renderProps.destroy()}
      />
    ));
  }, []);

  const handleVerwijderenFactuurkenmerk = useCallback(async (id) => {
    const params = { ids: [id] };
    const checkData = await api.v2.factuur.rubriek.factuurkenmerk.checkVerwijderenFactuurkenmerken(
      params,
    );
    if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
      return;
    }
    const result = await api.v2.factuur.rubriek.factuurkenmerk.verwijderenFactuurkenmerken(params);
    await ophalenFactuurkenmerken();
  }, []);

  return (
    <>
      <MenuLayout
        menu={
          <div className="d-flex">
            <button
              className="btn btn-sm btn-light"
              style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
              onClick={() => handleNieuwFactuurkenmerk(null)}
            >
              <IconToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
              <span className="ml-2">Nieuw factuurkenmerk</span>
            </button>
            <div className="d-flex flex-fill ml-3"></div>
          </div>
        }
        body={
          <div className="d-flex flex-column flex-fill">
            {FactuurkenmerkenRijen === null ? (
              <div className="flex-fill d-flex align-items-center justify-content-center">
                <LoadingSpinner />
              </div>
            ) : (
              <div className="d-flex flex-column flex-fill">
                <ASPTabel
                  rijen={FactuurkenmerkenRijen}
                  kolommen={kolommen}
                  keyExtractor={keyExtractor}
                  // selectie={urlState.selectie}
                  // onSelectieChange={(selectie) => setUrlStateSync('selectie', selectie)}
                  // sortering={urlState.sortering}
                  // onSorteringChange={(sortering) => setUrlStateSync('sortering', sortering)}
                  onWijzigenRij={async (x) => {
                    handleWijzigenFactuurkenmerk(x.ID);
                  }}
                  onVerwijderenRij={async (x) => {
                    handleVerwijderenFactuurkenmerk(x.ID);
                  }}
                />
              </div>
            )}
          </div>
        }
      />
    </>
  );
};

export default withRouter(Factuurkenmerken);
