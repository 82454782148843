import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IOphalenReviewsResultElement } from '../../../../../../shared/src/api/v2/review/klant';
import MenuLayout from '../../../../components/MenuLayout';
import {
  DXTableCheckboxComponent,
  DXTableToggleCellComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  DataTypeProvider,
  IntegratedSorting,
  RowDetailState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import nameOf from '../../../../core/nameOf';
import api from '../../../../api';
import useUrlState from '../../../../core/useUrlState';
import { addMonths, format } from 'date-fns';
import _ from 'lodash';
import { Kleur as EKleur } from '../../../../bedrijfslogica/constanten';
import { RootStoreContext } from '../../../../stores/RootStore';
import DetailComp from './DetailComp';
import { IOphalenAccountsResultElement } from '../../../../../../shared/src/api/v2/account/account';
import RelatieVisualisatie from '../../../../components/personalia/RelatieVisualisatie';
import FilterBalkV2, {
  IFilter,
  IFilterData,
  maakFilterSchema,
} from '../../../../components/FilterBalkV2';
import { IFilterSchema } from '../../../../../../shared/src/models/filter';
import { IOphalenContractenResultElement } from '../../../../../../shared/src/api/v2/contract';
import { Helmet } from 'react-helmet';
import { EContractStatus } from '../../../Klant/Klantkaart/Entiteiten/entiteiten/Contracten/Overzicht/ContractTegel/ContractStatus';

interface IProps extends RouteComponentProps {}

export enum EFilter {
  NogActiveren = 'IS_GEACTIVEERD',
  IsUitgeschakeld = 'IS_UITGESCHAKELD',
}

interface IUrlState {
  selectie: number[];
  filterData: IFilterData<EFilter>[];
}

export interface IInlezenBeoordelingenDialoogState {
  open: boolean;
}

const defaultUrlState: IUrlState = {
  selectie: [],
  filterData: [
    {
      naam: EFilter.NogActiveren,
      data: false,
      isActief: true,
    },
    {
      naam: EFilter.IsUitgeschakeld,
      data: false,
      isActief: true,
    },
  ],
};

const geenData = {
  noData: 'Geen accounts gevonden',
};

interface IRegel extends IOphalenAccountsResultElement {
  contracten: IOphalenContractenResultElement[];
}

const Accounts: React.FC<IProps> = (props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const { checkStore } = useContext(RootStoreContext);

  const [accounts, setAccounts] = useState<IOphalenAccountsResultElement[] | null>(null);

  const [filterSchema, setFilterSchema] = useState<IFilterSchema>(
    useMemo(() => maakFilterSchema(urlState.filterData), []),
  );

  const ophalenAccounts = useCallback(async () => {
    // const peildatum = addMonths(new Date(), -12);

    const accountsResult = await api.v2.account.ophalenAccounts({
      filterSchema: filterSchema,
      orderSchema: {
        orders: [{ naam: 'RECORD_TOEGEVOEGD', richting: 'DESC' }],
      },
      paginatie: { index: 0, aantal: 100 },
    });

    const relIDs = _.uniq(accountsResult.map((x) => x.RelID));
    const contractenResult = await api.v2.contract.ophalenContractenV2({
      filterSchema: {
        filters: [
          { naam: 'REL_IDS', data: relIDs },
          // { naam: 'STATUS_NAAM_ENUMS', data: [EContractStatus.Lopend, EContractStatus.Concept] },
        ],
      },
    });

    const accounts = accountsResult.map((account) => {
      const contracten = contractenResult.contracten.filter((x) => x.RelID === account.RelID);
      return { ...account, contracten };
    });

    setAccounts(accounts);
  }, [filterSchema]);

  useEffect(() => {
    ophalenAccounts();
  }, [ophalenAccounts]);

  const keyExtractor = useCallback((row: IRegel) => row.AccID, []);
  const kolommen = useMemo<TypedColumn<IRegel>[]>(
    () => [
      {
        name: 'Gebruikersnaam',
        title: 'Gebruikersnaam',
      },
      {
        name: '__relatie' as any,
        title: 'Relatie',
      },
      {
        name: '__contracten' as any,
        title: '# Cnt.',
      },
      {
        name: 'RecordToegevoegd',
        title: 'Geregistreerd',
      },
      {
        name: 'Activatiedatum',
        title: 'Geactiveerd op',
      },
      {
        name: 'Uitgeschakeld',
        title: 'Uitgeschakeld',
      },
    ],
    [],
  );
  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRegel>[]>(
    () => [
      {
        columnName: 'Gebruikersnaam',
        width: 250,
      },
      {
        columnName: 'RecordToegevoegd',
        width: 200,
      },
      {
        columnName: '__relatie' as any,
        width: 250,
      },
      {
        columnName: '__contracten' as any,
        width: 100,
      },
      {
        columnName: 'Activatiedatum',
        width: 200,
      },
      {
        columnName: 'Uitgeschakeld',
        width: 150,
      },
    ],
    [],
  );

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.NogActiveren,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return <span>Nog te activeren</span>;
        },
      },
      {
        naam: EFilter.IsUitgeschakeld,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return <span>Niet Uitgeschakeld</span>;
        },
      },
    ],
    [],
  );

  return (
    <>
      <Helmet>
        <title>Accounts nog activeren</title>
      </Helmet>
      <MenuLayout
        menu={
          <>
            <div className="d-flex align-items-center">
              {/* <button
                className="btn btn-sm btn-light d-flex align-items-center ml-2"
                style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                onClick={async () =>
                  setUrlStateSync('inlezenBeoordelingenDialoogState', { open: true })
                }
              >
                <IconUpload style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                <span className="ml-2">Inlezen beoordelingen</span>
              </button> */}

              <div className="d-flex flex-fill">
                <FilterBalkV2
                  filters={filters}
                  filterData={urlState.filterData}
                  onFilterDataChange={(x) => setUrlStateSync('filterData', x)}
                  onFilterSchemaChange={(x) => setFilterSchema(x)}
                />
              </div>
            </div>
          </>
        }
        body={
          accounts === null ? (
            <div className="flex-fill d-flex align-items-center justify-content-center">
              <LoadingSpinner />
            </div>
          ) : (
            <GridStyleWrapper rowAmount={accounts.length} height={'calc(100vh - 150px)'}>
              <Grid rows={accounts} columns={kolommen} getRowId={keyExtractor}>
                <DataTypeProvider
                  for={[
                    nameOf<IOphalenAccountsResultElement>('RecordToegevoegd'),
                    nameOf<IOphalenAccountsResultElement>('Activatiedatum'),
                  ]}
                  formatterComponent={(formatterProps) => (
                    <span>
                      {formatterProps.value !== null
                        ? format(new Date(formatterProps.value), 'dd-MM-yyyy HH:mm')
                        : ''}
                    </span>
                  )}
                />

                <DataTypeProvider
                  for={['__relatie']}
                  formatterComponent={(formatterProps) => {
                    const rij: IRegel = formatterProps.row;
                    const relID = rij.RelID;
                    if (relID === null) {
                      return <span></span>;
                    }
                    return <RelatieVisualisatie relID={relID!} />;
                  }}
                />

                <DataTypeProvider
                  for={['__contracten']}
                  formatterComponent={(formatterProps) => {
                    const rij: IRegel = formatterProps.row;
                    if (rij.contracten.length === 0) {
                      return <span></span>;
                    }
                    return <span>{rij.contracten.length}</span>;
                  }}
                />

                <DataTypeProvider
                  for={[nameOf<IOphalenAccountsResultElement>('Uitgeschakeld')]}
                  formatterComponent={(formatterprops) => {
                    const rij: IRegel = formatterprops.row;
                    return <span>{rij.Uitgeschakeld ? 'Ja' : ''}</span>;
                  }}
                />

                {/* <RowDetailState defaultExpandedRowIds={[]} /> */}

                <SortingState defaultSorting={[]} />
                <IntegratedSorting />

                <VirtualTable />
                <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
                <TableHeaderRow showSortingControls />
                {/* <TableRowDetail
                  contentComponent={DetailComp}
                  toggleCellComponent={DXTableToggleCellComponent}
                /> */}
                <SelectionState
                  selection={urlState.selectie}
                  onSelectionChange={(x) => setUrlStateSync('selectie', x as number[])}
                />
                <TableSelection cellComponent={DXTableCheckboxComponent} />
              </Grid>
            </GridStyleWrapper>
          )
        }
      />
    </>
  );
};

export default withRouter(Accounts);
