import React, { useCallback, useContext, useMemo, useState } from 'react';
import DienstFormulier, {
  IFormikValues as IDienstFormulierFormikValues,
  validationSchema as dienstFormulierValidationSchema,
} from '../../../DienstFormulier';
import { LeverancierContext } from '../../../../index';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import api from '../../../../../../api';
import { EResultType } from '../../../../../../stores/CheckStore';
import { RootStoreContext } from '../../../../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import { IWijzigenDienstParams } from '../../../../../../../../shared/src/api/v2/dienst/review';
import UitklapFormulier from '../../../../../../components/formulier/UitklapFormulier';
import { Kleur } from '../../../../../../bedrijfslogica/constanten';
import HorizontaleScheidingslijn from '../../../../../../components/layout/HorizontaleScheidingslijn';
import nameOf from '../../../../../../core/nameOf';
import NumeriekVeld from '../../../../../../components/formulier/NumeriekVeld';
import FormikVeldFout from '../../../../../../components/formulier/FormikVeldFout';
import TijdVeld, { ITijdVeldWaarde } from '../../../../../../components/formulier/TijdVeld';
import * as Yup from 'yup';
import VinkVeld from '../../../../../../components/formulier/VinkVeld';

interface IFormikValues extends IDienstFormulierFormikValues {
  uitnodigingViaAPI: boolean;
  percentageAanbevelen: number | null;
  beoordeling: number | null;
  websiteURL: string | null;
}

export const veldnamen: Partial<Record<keyof IFormikValues, string>> = {
  uitnodigingViaAPI: 'Uitnodiging via API',
  percentageAanbevelen: 'Percentage aanbevelen (b.v. 98)',
  beoordeling: 'Beoordeling (b.v. 9.2)',
  websiteURL: 'Website',
};

interface IProps {}

const Dienst: React.FC<IProps> = observer((props) => {
  const { checkStore } = useContext(RootStoreContext);
  const context = useContext(LeverancierContext);
  const reviewdienst = context.reviewdienst.data!;
  const [dienstUitgeklapt, setDienstUitgeklapt] = useState(false);
  const [reviewUitgeklapt, setReviewUitgeklapt] = useState(true);

  const initialValues = useMemo<IFormikValues>(() => {
    return {
      actief: reviewdienst.Actief,
      naam: reviewdienst.Naam || '',
      naamEnum: reviewdienst.NaamEnum ?? '',
      opdWijzeID: 1, // TODO
      parameters: reviewdienst.Parameters || '',
      uitnodigingViaAPI: reviewdienst.UitnodigingViaAPI,
      percentageAanbevelen: reviewdienst.PercentageAanbevelen,
      beoordeling: reviewdienst.Beoordeling,
      websiteURL: reviewdienst.WebsiteURL !== null ? reviewdienst.WebsiteURL : '',
    };
  }, [reviewdienst]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);
      const params: IWijzigenDienstParams = {
        relID: context.relID!,
        parameters: values.parameters === '' ? null : values.parameters,
        naam: values.naam,
        naamEnum: values.naamEnum,
        actief: values.actief,
        id: reviewdienst.ID,
        uitnodigingViaAPI: values.uitnodigingViaAPI,
        percentageAanbevelen: values.percentageAanbevelen,
        beoordeling: values.beoordeling,
        websiteURL: values.websiteURL !== '' ? values.websiteURL : null,
      };

      const checkData = await api.v2.dienst.review.checkWijzigenDienst(params);
      if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
        actions.setSubmitting(false);
        return;
      }

      await api.v2.dienst.review.wijzigenDienst(params);
      context.onVerversenReviewdienstAangevraagd();
      actions.setSubmitting(false);
    },
    [context.relID, reviewdienst, context.onVerversenReviewdienstAangevraagd],
  );

  // const validationSchema = useMemo(() => {
  //   return dienstFormulierValidationSchema.concat(
  //     Yup.object().shape({
  //       emailOpdracht: Yup.string(),
  //       // emailOpdrachtCC: Yup.string(),
  //     }),
  //   );
  // }, []);

  return (
    <div className="d-flex flex-column flex-fill">
      <Formik
        enableReinitialize
        onSubmit={handleSubmit}
        initialValues={initialValues}
        // validationSchema={validationSchema}
        render={(formikProps: FormikProps<IFormikValues>) => {
          const { submitForm, isSubmitting, isValid, resetForm, dirty } = formikProps;
          return (
            <>
              <div className="d-flex flex-column flex-fill p-3">
                <UitklapFormulier
                  label="Dienst"
                  uitgeklapt={dienstUitgeklapt}
                  onUitgeklaptChange={setDienstUitgeklapt}
                  formulier={<DienstFormulier />}
                />
                <div className="p-2" />
                <UitklapFormulier
                  label="Review"
                  uitgeklapt={reviewUitgeklapt}
                  onUitgeklaptChange={setReviewUitgeklapt}
                  formulier={
                    <div className="p-3">
                      <div className="row mb-3"></div>

                      <div className="row">
                        <div className="col-12">
                          <label>{veldnamen.websiteURL}</label>
                          <Field
                            name={nameOf<IFormikValues>('websiteURL')}
                            render={(fieldProps: FieldProps) => {
                              const { field, form } = fieldProps;
                              return (
                                <div>
                                  <input className="form-control" {...fieldProps.field} />
                                  <FormikVeldFout fieldProps={fieldProps} />
                                </div>
                              );
                            }}
                          />
                        </div>

                        <div className="col-12 d-flex align-items-center mt-3">
                          <Field
                            name={nameOf<IFormikValues>('uitnodigingViaAPI')}
                            render={(fieldProps: FieldProps<IFormikValues>) => {
                              const { field, form } = fieldProps;
                              return (
                                <VinkVeld
                                  aangevinkt={field.value}
                                  onGewijzigd={(x) => {
                                    form.setFieldValue(field.name, x);
                                  }}
                                />
                              );
                            }}
                          />
                          <span className="ml-2">{veldnamen.uitnodigingViaAPI}</span>
                        </div>

                        <div className="col-3 mt-3">
                          <label>{veldnamen.beoordeling}</label>
                          <Field
                            name={nameOf<IFormikValues>('beoordeling')}
                            render={(fieldProps: FieldProps) => {
                              const { field, form } = fieldProps;
                              return (
                                <div>
                                  <input className="form-control" {...fieldProps.field} />
                                  <FormikVeldFout fieldProps={fieldProps} />
                                </div>
                              );
                            }}
                          />
                        </div>

                        <div className="col-3 mt-3">
                          <label>{veldnamen.percentageAanbevelen}</label>
                          <Field
                            name={nameOf<IFormikValues>('percentageAanbevelen')}
                            render={(fieldProps: FieldProps) => {
                              const { field, form } = fieldProps;
                              return (
                                <div>
                                  <input className="form-control" {...fieldProps.field} />
                                  <FormikVeldFout fieldProps={fieldProps} />
                                </div>
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  }
                />
              </div>
              <HorizontaleScheidingslijn color={Kleur.LichtGrijs} />
              <div className="d-flex p-3">
                <button
                  className="btn btn-sm btn-primary d-flex align-items-center justify-content-center"
                  style={{ width: 100 }}
                  disabled={!isValid || isSubmitting}
                  onClick={submitForm}
                >
                  Ok
                </button>
                <button
                  className="btn btn-sm btn-secondary d-flex align-items-center justify-content-center ml-2"
                  style={{ width: 100 }}
                  disabled={!dirty || isSubmitting}
                  onClick={() => resetForm()}
                >
                  Annuleren
                </button>
              </div>
            </>
          );
        }}
      />
    </div>
  );
});

export default Dienst;
