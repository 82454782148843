import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import IDialoogProps from '../../../../../core/IDialoogProps';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import nameOf from '../../../../../core/nameOf';
import * as Yup from 'yup';
import api from '../../../../../api';
import Dialoog from '../../../../../components/dialogen/Dialoog';
import LoadingSpinner from '../../../../../components/Gedeeld/LoadingSpinner';
import { IOphalenInventarisatieregelsResultElement } from '../../../../../../../shared/src/api/v2/magazijn/inventarisatie';

interface IDialoogResult {}

interface IFormikValues {
  notities: string | null;
}

interface IProps extends IDialoogProps<IDialoogResult> {
  id: number;
}

const WijzigenRegelDialoog: React.FC<IProps> = (props) => {
  const [regel, setRegel] = useState<IOphalenInventarisatieregelsResultElement | null>(null);

  const ophalenRegel = useCallback(async () => {
    const regel = (
      await api.v2.magazijn.inventarisatie.ophalenInventarisatieregels({
        filterSchema: { filters: [{ naam: 'IDS', data: [props.id] }] },
      })
    ).inventarisatieregels[0];

    setRegel(regel);
  }, [props.id]);

  useEffect(() => {
    ophalenRegel();
  }, [ophalenRegel]);

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (regel === null) {
      return null;
    }
    return {
      notities: regel.Notities,
    };
  }, [regel]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      await api.v2.magazijn.inventarisatie.wijzigenInventarisatieregel({
        id: props.id,
        notities: values.notities,
        aantalAanwezig: regel!.AantalAanwezig,
      });

      await props.onSuccess({});
      actions.setSubmitting(false);
    },
    [props.onSuccess, regel],
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        [nameOf<IFormikValues>('notities')]: Yup.string(),
      }),
    [],
  );

  const notitiesRef = useRef<HTMLTextAreaElement>(null);
  useEffect(() => {
    notitiesRef.current?.focus();
  }, []);

  if (initialValues === null) {
    return <LoadingSpinner />;
  }

  return (
    <Dialoog index={props.dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Inventarisatieregel wijzigen</ModalTitle>
      </ModalHeader>
      <Formik<IFormikValues>
        onSubmit={handleSubmit}
        initialValues={initialValues}
        // isInitialValid
        validationSchema={validationSchema}
        render={(formikProps: FormikProps<IFormikValues>) => {
          const { isValid, isSubmitting, submitForm } = formikProps;

          return (
            <>
              <ModalBody>
                <div className="row">
                  <div className="col-12 mt-3">
                    <label>Notities</label>
                    <Field
                      name={nameOf<IFormikValues>('notities')}
                      render={(fieldProps: FieldProps<IFormikValues>) => {
                        const { field, form } = fieldProps;

                        return (
                          <textarea
                            {...field}
                            className="form-control"
                            rows={3}
                            ref={notitiesRef}
                          />
                        );
                      }}
                    />
                  </div>
                </div>
              </ModalBody>
              <ModalFooter className="d-flex flex-row justify-content-start">
                <button
                  className="btn btn-primary d-flex align-items-center justify-content-center"
                  onClick={submitForm}
                  style={{ width: 100 }}
                  disabled={isSubmitting || !isValid}
                >
                  Ok
                </button>
                <button
                  className="btn btn-secondary"
                  onClick={props.onAnnuleren}
                  style={{ width: 100 }}
                  disabled={isSubmitting}
                >
                  Annuleren
                </button>
              </ModalFooter>
            </>
          );
        }}
      />
    </Dialoog>
  );
};

export default WijzigenRegelDialoog;
