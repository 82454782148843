import React, { useContext, useEffect, useMemo, useState, useCallback } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { withRouter } from 'react-router';
import api from '../../../../../api';
import useUrlState from '../../../../../core/useUrlState';
import _ from 'lodash';
import {
  DXCommandComponent,
  DXTableCheckboxComponent,
  DXTableToggleCellComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../../helpers/dxTableGrid';
import {
  Column,
  DataTypeProvider,
  FilteringState,
  IntegratedFiltering,
  IntegratedSorting,
  RowDetailState,
  SearchState,
  SelectionState,
  SortingState,
  TableColumnWidthInfo,
  EditingState,
  VirtualTable as VirtualTableBase,
} from '@devexpress/dx-react-grid';
import LoadingSpinner from '../../../../../components/Gedeeld/LoadingSpinner';
import {
  Grid,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import { format } from 'date-fns';
import FormatteerBedrag from '../../../../../components/MutatieBedrag';
import { Kleur as EKleur, Kleur } from '../../../../../bedrijfslogica/constanten';
import MenuLayout from '../../../../../components/MenuLayout';
import { RootStoreContext } from '../../../../../stores/RootStore';
import RelatieVisualisatie from '../../../../../components/personalia/RelatieVisualisatie';
import WijzigenFactuurDialoog from '../../../../../components/inkoopfactuur/WijzigenFactuurDialoog';
import {
  IOphalenFacturenResultElement,
  IOphalenGekoppeldeProductenResultElement,
  ISponsorcontract,
} from '../../../../../../../shared/src/api/v2/inkoopfactuur';
import { IFilterSchema, IFilterSchemaFilter } from '../../../../../../../shared/src/models/filter';
import DownloadKnop from '../../../../../components/DownloadKnop';
import downloadUrl from '../../../../../core/downloadUrl';
import FilterBalkV2, {
  IFilter,
  IFilterData,
  maakFilterSchema,
} from '../../../../../components/FilterBalkV2';
import {
  IOphalenBoekingenResultElement,
  IOphalenBoekingRegelsResultElement,
} from '../../../../../../../shared/src/api/v2/boekhouding/boeking';
import { AutoSizer } from 'react-virtualized';
import { IconInformatie, IconUitvergroten } from '../../../../../components/Icons';
import MediaWeergaveDialoog from '../../../../../components/dialogen/MediaWeergaveDialoog';
import { mediaTypeNaarMediaWeergaveType } from '../../../../../components/MediaWeergave';
import { EHoedanigheid } from '../../../../../components/personalia/RelatieSelectieDialoog';
import RelatieSelectieUitgebreid from '../../../../../components/formulier/RelatieSelectieUitgebreid';
import {
  IInitieleRelatiesProvider,
  relatieSelectieRelatieToRow,
} from '../../../../../components/formulier/RelatieSelectie';
import KoppelContractenDialoog from './KoppelContractenDialoog';
import FactuurinfoDialoog from '../../../../../components/inkoopfactuur/FactuurinfoDialoog';

interface IProps extends RouteComponentProps {}

export interface IKoppelProductenDialoogState {
  inkFactID: number;
}

export interface IWijzigenFactuurDialoogState {
  inkFactID: number;
}

export interface IBestandenUitvergrotenDialoogState {
  inkFactID: number;
}

enum EFilter {
  RelIDs = 'REL_IDS',
  NogTeKoppelenSponsorcontracten = 'NOG_TE_KOPPELEN_SPONSORCONTRACTEN',
  CreditFacturen = 'IS_CREDIT',
}

export interface IKoppelContractenDialoogState {
  inkFactID: number;
}
export interface IFactuurInfoDialoogState {
  inkFactID: number;
}

export interface IUrlState {
  selectie: number[];
  uitgeklapt: number[];
  koppelContractenDialoogState: IKoppelContractenDialoogState | null;
  factuurInfoDialoogState: IFactuurInfoDialoogState | null;
  wijzigenFactuurDialoogState: IWijzigenFactuurDialoogState | null;
  bestandenUitvergrotenDialoogState: IBestandenUitvergrotenDialoogState | null;
  filterData: IFilterData<EFilter>[];
}

const defaultUrlState: IUrlState = {
  selectie: [],
  uitgeklapt: [],
  koppelContractenDialoogState: null,
  factuurInfoDialoogState: null,
  wijzigenFactuurDialoogState: null,
  bestandenUitvergrotenDialoogState: null,
  filterData: [
    {
      naam: EFilter.RelIDs,
      isActief: false,
      data: [],
    },
    {
      naam: EFilter.NogTeKoppelenSponsorcontracten,
      isActief: false,
      data: true,
    },
    {
      naam: EFilter.CreditFacturen,
      isActief: false,
      data: true,
    },
  ],
};

export interface IRegel extends IOphalenFacturenResultElement {
  sponsorcontracten: ISponsorcontract[];
}

export interface IBoeking extends IOphalenBoekingRegelsResultElement {
  boeking: IOphalenBoekingenResultElement;
}

const geenData = {
  noData: 'Geen inkoopfacturen gevonden',
};

const Facturen: React.FC<IProps> = observer((props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);

  const [filterSchema, setFilterSchema] = useState<IFilterSchema>(
    maakFilterSchema(urlState.filterData),
  );

  const [facturen, setFacturen] = useState<IRegel[] | null>(null);

  const [producten, setProducten] = useState<IOphalenGekoppeldeProductenResultElement[] | null>(
    null,
  );

  // Ophalen van inkoopfacturen die gekoppeld moeten worden

  const ophalenFacturen = useCallback(async () => {
    const facturenResult = await api.v2.inkoopfactuur.ophalenFacturen({
      filterSchema: {
        filters: [
          ...filterSchema.filters!,

          {
            naam: 'KOPPELEN_SPONSORCONTRACTEN',
            data: true,
          },
        ].filter((x) => x !== null) as IFilterSchemaFilter[],
      },
      orderSchema: { orders: [{ naam: 'FACTUURDATUM', richting: 'DESC' }] },
      paginatie: {
        index: 0,
        aantal: 150,
      },
    });
    if (facturenResult.facturen.length === 0) {
      setFacturen([]);
    }
    const inkFactIDs = facturenResult.facturen.map((x) => x.InkFactID);

    const sponsorcontractenResult = (
      await api.v2.inkoopfactuur.ophalenSponsorcontractenVoorFacturen({
        inkFactIDs,
      })
    ).inkoopfacturen;

    const facturen = facturenResult.facturen.map((factuur) => {
      // Haal de gekoppeldse sponsorcontracten op voor deze inkoopfactuur
      const sponsorcontractenVoorFactuur =
        sponsorcontractenResult.find((x) => x.InkFactID === factuur.InkFactID) ?? null;
      const sponsorcontracten =
        sponsorcontractenVoorFactuur !== null ? sponsorcontractenVoorFactuur.sponsorcontracten : [];

      return { ...factuur, sponsorcontracten };
    });

    setFacturen(facturen);
  }, [JSON.stringify(filterSchema)]);

  useEffect(() => {
    ophalenFacturen();
  }, [ophalenFacturen]);

  const handleKoppelProducten = useCallback(async () => {
    alert('Producten');
    const params = { inkFactIDs: urlState.selectie };

    ophalenFacturen();
  }, [urlState.selectie]);

  // Bedrag geboekt op grootboek 100 voor deze factuur
  useEffect(() => {
    (async () => {
      if (facturen === null) {
        return;
      }

      const inkFactIDs = facturen.map((x) => x.InkFactID);

      const boekingenResult = (
        await api.v2.boeking.ophalenBoekingen({
          filterSchema: {
            filters: [
              { naam: 'INKFACT_IDS', data: inkFactIDs },
              { naam: 'GRBREK_NUMMERS', data: [100] },
            ],
          },
        })
      ).boekingen;
    })();
  }, [facturen]);

  useEffect(() => {
    (async () => {
      if (facturen === null) {
        return;
      }

      const inkFactIDs = facturen.map((x) => x.InkFactID);

      const producten = (
        await api.v2.inkoopfactuur.ophalenGekoppeldeProducten({
          inkFactIDs,
        })
      ).producten;

      setProducten(producten);
    })();
  }, [facturen]);

  const [relatieOpties, setRelatieOpties] = useState<{ RelID: number; Naam: string }[] | null>(
    null,
  );

  const ophalenRelatieOpties = useCallback(async () => {
    const diensten = (
      await api.v2.dienst.inkoop.ophalenDiensten({
        filterSchema: { filters: [{ naam: 'IS_ACTIEF', data: true }] },
      })
    ).diensten;

    const relaties = (
      await api.v2.relatie.ophalenRelaties({
        filterSchema: { filters: [{ naam: 'IDS', data: [_.uniq(diensten.map((x) => x.RelID))] }] },
      })
    ).relaties.map((x) => {
      return { RelID: x.RelID, Naam: x.weergavenaam };
    });

    const relatiesGesorteerd: any[] = _.orderBy(relaties, ['Naam'], ['asc']);

    setRelatieOpties(relatiesGesorteerd);
  }, []);

  useEffect(() => {
    ophalenRelatieOpties();
  }, [ophalenRelatieOpties]);

  // useEffect(() => {
  //   if (opties.state === ERemoteDataState.Pending) {
  //     return;
  //   }
  //   bepalenRelatiesOpties();
  // }, [bepalenRelatiesOpties]);

  const initieleRelatiesProvider = useMemo<IInitieleRelatiesProvider>(
    () => ({
      provide: async () => {
        const relatiesResult = await api.v2.relatie.ophalenRelaties({
          filterSchema: {
            filters: [
              {
                naam: 'IS_CREDITEUR',
                data: true,
              },
              {
                naam: 'MET_OPENSTAANDE_FACTUREN',
                data: true,
              },
            ],
          },
        });
        return relatiesResult.relaties.map(relatieSelectieRelatieToRow);
      },
    }),
    [],
  );

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.NogTeKoppelenSponsorcontracten,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return (
            <div className="d-flex align-items-center">
              <span>Alleen nog te koppelen facturen</span>
            </div>
          );
        },
      },
      {
        naam: EFilter.RelIDs,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          // if (relatieOpties === null) {
          //   return null;
          // }
          const data: number[] = weergaveProps.data;

          return (
            <div className="d-flex align-items-center">
              <span className="mr-2">Sponsorrelatie</span>
              <RelatieSelectieUitgebreid
                filters={[{ naam: 'IS_CREDITEUR', data: true }]}
                onChange={(relID) => {
                  const newData = relID === null ? [] : [relID];
                  weergaveProps.onDataChange(newData);
                  weergaveProps.setIsActief(true);
                  weergaveProps.toepassen();
                }}
                relID={data.length === 0 ? null : data[0]}
                initieleRelatiesProvider={initieleRelatiesProvider}
              />
            </div>
          );
        },
      },
      {
        naam: EFilter.CreditFacturen,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return (
            <div className="d-flex align-items-center">
              <span>Alleen Credit-facturen</span>
            </div>
          );
        },
      },
    ],
    [],
  );

  const keyExtractor = useCallback((row: IRegel) => row.InkFactID, []);
  const kolommen = useMemo<TypedColumn<IRegel>[]>(
    () => [
      {
        name: 'Factuurdatum',
        title: 'Fact.datum',
      },
      {
        name: 'Factuurnummer',
        title: 'Fact.nr.',
      },
      {
        name: '__bedragExclBtw' as any,
        title: 'Bedrag (excl.)',
        getCellValue: (x) => x.Bedrag - x.BedragBtw,
      },
      {
        name: '__factuurinfo' as any,
        title: ' ',
      },
      {
        name: '__relatie' as any,
        title: 'Relatie',
      },
      {
        name: 'Onderwerp',
        title: 'Onderwerp',
      },
      {
        name: '__koppelen' as any,
        title: ' ',
      },
      {
        name: '__aantalGekoppeldeContracten' as any,
        title: '# Cnt. ',
        getCellValue: (x) => x.sponsorcontracten.length,
      },
      {
        name: '__bedragNogTeKoppelen' as any,
        title: 'Bedrag nog kop.',
        getCellValue: (x) => {
          const bedragFactuur = x.Bedrag - x.BedragBtw;
          const bedrag = bedragFactuur - _.sum(x.sponsorcontracten.map((x) => x.BedragGekoppeld));
          return bedrag;
        },
      },
      {
        name: 'SponsorcontractenGekoppeld',
        title: 'Volledig gekoppeld',
      },
      {
        name: '__heeftBestanden' as any,
        title: ' ',
      },
    ],
    [],
  );
  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRegel>[]>(
    () => [
      {
        columnName: 'Factuurdatum',
        width: 125,
      },
      {
        columnName: 'Factuurnummer',
        width: 125,
      },
      {
        columnName: '__factuurinfo' as any,
        width: 75,
      },
      {
        columnName: '__relatie' as any,
        width: 150,
      },
      {
        columnName: '__bedragExclBtw' as any,
        width: 130,
      },
      {
        columnName: 'Onderwerp',
        width: 350,
      },
      {
        columnName: '__heeftBestanden' as any,
        width: 80,
      },
      {
        columnName: '__koppelen',
        width: 90,
      },
      {
        columnName: 'SponsorcontractenGekoppeld',
        width: 175,
      },
      {
        columnName: '__bedragNogTeKoppelen' as any,
        width: 150,
      },
      {
        columnName: '__aantalGekoppeldeContracten' as any,
        width: 80,
      },
    ],
    [],
  );

  const kolomExtensies: VirtualTableBase.ColumnExtension[] = useMemo(() => {
    return [
      // {
      //   columnName: `Bedrag`,
      //   align: 'right',
      // },
      // {
      //   columnName: `__nogKoppelen`,
      //   align: 'right',
      // },
    ];
  }, []);

  return (
    <>
      <MenuLayout
        menu={
          <div className="d-flex">
            {/* <button
              className="btn btn-sm btn-light d-flex align-items-center"
              style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
              disabled={urlState.selectie.length !== 1}
              onClick={() => handleKoppelProducten()}
            >
              <span className="ml-2">Producten</span>
            </button> */}

            <div className="d-flex flex-fill">
              <FilterBalkV2
                filters={filters}
                filterData={urlState.filterData}
                onFilterDataChange={(x) => setUrlStateSync('filterData', x)}
                onFilterSchemaChange={(x) => setFilterSchema(x)}
              />
            </div>
          </div>
        }
        body={
          <div className="d-flex flex-column flex-fill">
            {facturen === null || producten === null ? (
              <LoadingSpinner />
            ) : (
              <AutoSizer style={{ flex: 1, height: '100%', width: '100%' }}>
                {(size) => (
                  <GridStyleWrapper height={size.height}>
                    <Grid columns={kolommen} rows={facturen || []} getRowId={keyExtractor}>
                      <DataTypeProvider
                        for={['Factuurdatum']}
                        formatterComponent={(formatterProps) => {
                          const rij: IRegel = formatterProps.row;
                          const datum = format(new Date(rij.Factuurdatum), 'dd-MM-yyyy');
                          return <span>{datum}</span>;
                        }}
                      />

                      <DataTypeProvider
                        for={['__relatie']}
                        formatterComponent={(formatterProps) => {
                          const rij = formatterProps.row as IRegel;
                          return (
                            <RelatieVisualisatie
                              relID={rij.RelID}
                              relatieLinkBuilder={(hoedanigheid, relID) =>
                                `/${
                                  hoedanigheid === EHoedanigheid.Klant ? 'klant' : 'leverancier'
                                }/${relID}/facturen/overzicht`
                              }
                            />
                          );
                        }}
                      />

                      <DataTypeProvider
                        for={['__bedragExclBtw']}
                        formatterComponent={(formatterProps) => {
                          const rij = formatterProps.row as IRegel;
                          const bedrag = rij.Bedrag - rij.BedragBtw;

                          return <FormatteerBedrag bedrag={bedrag} />;
                        }}
                      />

                      <DataTypeProvider
                        for={['__heeftBestanden']}
                        formatterComponent={(formatterProps) => {
                          const rij: IRegel = formatterProps.row;
                          const heeftBestanden = rij.bestanden.length > 0;
                          return (
                            <>
                              {heeftBestanden && (
                                <div className="d-flex align-items-center">
                                  <button
                                    style={{
                                      outline: 0,
                                      background: 0,
                                      border: 0,
                                      padding: 0,
                                      marginTop: 1,
                                    }}
                                    onClick={() => {
                                      setUrlStateSync('bestandenUitvergrotenDialoogState', {
                                        inkFactID: rij.InkFactID,
                                      });
                                    }}
                                  >
                                    <IconUitvergroten
                                      style={{ width: 20, height: 20, fill: Kleur.Blauw }}
                                    />
                                  </button>

                                  <DownloadKnop
                                    onDownloadAangevraagd={async () => {
                                      await downloadUrl(
                                        rij.bestanden[0].url,
                                        rij.bestanden[0].Naam,
                                      );
                                    }}
                                  />
                                </div>
                              )}
                            </>
                          );
                        }}
                      />

                      <DataTypeProvider
                        for={['SponsorcontractenGekoppeld']}
                        formatterComponent={(formatterProps) => {
                          return (
                            <span
                              style={{ color: formatterProps.value ? EKleur.Groen : EKleur.Rood }}
                            >
                              {formatterProps.value ? 'Ja' : 'Nee'}
                            </span>
                          );
                        }}
                      />

                      <DataTypeProvider
                        for={['__koppelen']}
                        formatterComponent={(formatterProps) => {
                          const rij = formatterProps.row as IRegel;
                          return (
                            <>
                              <a
                                href="#"
                                style={{ color: EKleur.Blauw }}
                                onClick={() =>
                                  setUrlStateSync('koppelContractenDialoogState', {
                                    inkFactID: rij.InkFactID,
                                  })
                                }
                              >
                                Koppelen
                              </a>
                            </>
                          );
                        }}
                      />

                      <DataTypeProvider
                        for={['__bedragNogTeKoppelen']}
                        formatterComponent={(formatterProps) => {
                          const rij = formatterProps.row as IRegel;
                          const bedragFactuur = rij.Bedrag - rij.BedragBtw;
                          const bedrag =
                            bedragFactuur -
                            _.sum(rij.sponsorcontracten.map((x) => x.BedragGekoppeld));

                          if (bedrag === 0) {
                            return <span></span>;
                          }
                          return <FormatteerBedrag bedrag={bedrag} />;
                        }}
                      />

                      <DataTypeProvider
                        for={['__aantalGekoppeldeContracten']}
                        formatterComponent={(formatterProps) => {
                          const rij = formatterProps.row as IRegel;
                          return <span>{rij.sponsorcontracten.length}</span>;
                        }}
                      />

                      <DataTypeProvider
                        for={['__factuurinfo']}
                        formatterComponent={(formatterProps) => {
                          const rij = formatterProps.row as IRegel;

                          return (
                            <a
                              href="#"
                              className="ml-1"
                              style={{ color: Kleur.LichtBlauw, position: 'relative', bottom: 2 }}
                              onClick={() => {
                                setUrlStateSync('factuurInfoDialoogState', {
                                  inkFactID: rij.InkFactID,
                                });
                              }}
                            >
                              <IconInformatie
                                style={{ width: 15, height: 15, fill: Kleur.Blauw }}
                              />
                            </a>
                          );
                        }}
                      />

                      <RowDetailState
                        expandedRowIds={urlState.uitgeklapt}
                        onExpandedRowIdsChange={(x) => setUrlStateSync('uitgeklapt', x as number[])}
                      />
                      <SortingState defaultSorting={[]} />
                      <IntegratedSorting />

                      <VirtualTable
                        // estimatedRowHeight={43}
                        columnExtensions={kolomExtensies}
                        messages={geenData}
                      />

                      <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
                      <TableHeaderRow showSortingControls />

                      <EditingState
                        onAddedRowsChange={() => {}}
                        onEditingRowIdsChange={(x) => {
                          const id = x[x.length - 1] as number;
                          setUrlStateSync('wijzigenFactuurDialoogState', { inkFactID: id });
                        }}
                        onCommitChanges={() => null}
                      />
                      <TableEditColumn
                        width={35}
                        showEditCommand
                        commandComponent={DXCommandComponent}
                      />

                      <SelectionState
                        selection={urlState.selectie}
                        onSelectionChange={(x) => setUrlStateSync('selectie', x as number[])}
                      />
                      <TableSelection cellComponent={DXTableCheckboxComponent} />
                    </Grid>
                  </GridStyleWrapper>
                )}
              </AutoSizer>
            )}

            {urlState.koppelContractenDialoogState !== null && (
              <KoppelContractenDialoog
                open
                inkFactID={urlState.koppelContractenDialoogState.inkFactID}
                onSuccess={() => {}}
                onAnnuleren={() => {
                  setUrlStateSync('koppelContractenDialoogState', null);
                  ophalenFacturen();
                }}
              />
            )}

            {urlState.wijzigenFactuurDialoogState !== null && (
              <WijzigenFactuurDialoog
                open
                inkFactID={urlState.wijzigenFactuurDialoogState.inkFactID}
                onSuccess={() => {
                  setUrlStateSync('wijzigenFactuurDialoogState', null);
                  ophalenFacturen();
                }}
                onAnnuleren={() => setUrlStateSync('wijzigenFactuurDialoogState', null)}
              />
            )}
            {urlState.bestandenUitvergrotenDialoogState !== null &&
              facturen !== null &&
              (() => {
                const factuur = facturen.find(
                  (x) => x.InkFactID === urlState.bestandenUitvergrotenDialoogState!.inkFactID,
                )!;
                const mediaWeergaven = factuur.bestanden.map((x) => ({
                  id: x.ID,
                  title: x.Naam,
                  src: x.url,
                  type: mediaTypeNaarMediaWeergaveType(x.MediaType)!,
                  mediaType: x.MediaType,
                }));

                return (
                  <MediaWeergaveDialoog
                    mediaWeergaven={mediaWeergaven}
                    defaultCurrent={mediaWeergaven[0].id}
                    open
                    onSuccess={() => setUrlStateSync('bestandenUitvergrotenDialoogState', null)}
                    onAnnuleren={() => setUrlStateSync('bestandenUitvergrotenDialoogState', null)}
                  />
                );
              })()}
            {urlState.factuurInfoDialoogState !== null && (
              <FactuurinfoDialoog
                open
                inkFactID={urlState.factuurInfoDialoogState.inkFactID}
                onSuccess={() => {
                  setUrlStateSync('factuurInfoDialoogState', null);
                }}
                onAnnuleren={() => setUrlStateSync('factuurInfoDialoogState', null)}
              />
            )}
          </div>
        }
      />
    </>
  );
});

export default withRouter(Facturen);
