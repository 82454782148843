import { Field, FieldProps, Formik } from 'formik';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Dialoog from '../../../../../../../components/dialogen/Dialoog';
import BedragInput from '../../../../../../../components/formulier/BedragInput';
import IDialoogProps from '../../../../../../../core/IDialoogProps';
import nameof from '../../../../../../../core/nameOf';

export interface IDialoogResult {
  inkoopprijs: number | null;
}

interface IProps extends IDialoogProps<IDialoogResult>, RouteComponentProps {
  inkoopprijs: number | null;
}

interface IFormikValues {
  inkoopprijs: number | null;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  inkoopprijs: 'Inkoopprijs',
};

const WijzigenBedragGekoppeldDialoog: React.FC<IProps> = (props) => {
  const initialValues = useMemo<IFormikValues>(() => {
    return {
      inkoopprijs: props.inkoopprijs,
    };
  }, []);

  return (
    <>
      <Dialoog index={props.dialoogIndex || 0}>
        <ModalHeader>
          <ModalTitle>Wijzigen inkoopprijs</ModalTitle>
        </ModalHeader>

        <>
          <Formik<IFormikValues>
            // ref={formikRef}
            initialValues={initialValues}
            onSubmit={(values: IFormikValues) =>
              props.onSuccess({ inkoopprijs: values.inkoopprijs })
            }
            isInitialValid
            render={(formikProps) => {
              return (
                <>
                  <ModalBody>
                    <div className="row">
                      <div className="col-4">
                        <div className="form-group">
                          <label>{veldnamen.inkoopprijs}</label>
                          <Field
                            name={nameof<IFormikValues>('inkoopprijs')}
                            render={({ field, form }: FieldProps<IFormikValues>) => {
                              return (
                                <BedragInput
                                  value={field.value}
                                  onChange={(bedrag) => {
                                    form.setFieldValue(field.name, bedrag);
                                  }}
                                />
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </ModalBody>

                  <ModalFooter className="d-flex flex-row justify-content-start">
                    <button
                      className="btn btn-primary"
                      onClick={formikProps.submitForm}
                      style={{ width: 100 }}
                      disabled={!formikProps.isValid || formikProps.isSubmitting}
                    >
                      Ok
                    </button>
                    <button
                      className="btn btn-secondary"
                      onClick={props.onAnnuleren}
                      style={{ width: 100 }}
                      disabled={formikProps.isSubmitting}
                    >
                      Annuleren
                    </button>
                  </ModalFooter>
                </>
              );
            }}
          />
        </>
      </Dialoog>
    </>
  );
};

export default withRouter(WijzigenBedragGekoppeldDialoog);
