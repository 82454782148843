import IRemoteData, { createPendingRemoteData, createReadyRemoteData } from '../models/IRemoteData';
import { RootStore } from './RootStore';
import { makeObservable, observable } from 'mobx';
import api from '../api';
import { IOphalenContactverzoekenResultElement } from '../../../shared/src/api/v2/contactverzoek';

class ContactverzoekStore {
  public contactverzoeken: Record<number, IRemoteData<IOphalenContactverzoekenResultElement>> = {};
  // tslint:disable-next-line:variable-name
  private _rootStore: RootStore;
  private ctcVerzoekIDsOphalenQueue: Set<number> = new Set();
  private ctcVerzoekOphalenTimeout: NodeJS.Timeout | null = null;

  constructor(rootStore: RootStore) {
    this._rootStore = rootStore;

    makeObservable(this, {
      contactverzoeken: observable,
    });
  }

  public enqueueOphalenContactverzoeken = async (ids: number[]) => {
    if (ids.length === 0) {
      return;
    }

    const contactverzoekenCopy = { ...this.contactverzoeken };

    for (const id of ids) {
      // Als de opdracht al in de store zit, hoeven we hem niet opnieuw op te halen
      if (this.contactverzoeken[id] !== undefined) {
        continue;
      }
      contactverzoekenCopy[id] = createPendingRemoteData();

      this.ctcVerzoekIDsOphalenQueue.add(id);
    }

    this.contactverzoeken = contactverzoekenCopy;

    if (this.ctcVerzoekOphalenTimeout !== null) {
      clearTimeout(this.ctcVerzoekOphalenTimeout);
    }
    this.ctcVerzoekOphalenTimeout = setTimeout(this.ophalenContactverzoekenMiddelsQueue, 50);
  };

  private ophalenContactverzoekenMiddelsQueue = async () => {
    const ids = Array.from(this.ctcVerzoekIDsOphalenQueue);
    this.ctcVerzoekIDsOphalenQueue.clear();

    const result = await api.v2.contactverzoek.ophalenContactverzoeken({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: ids,
          },
        ],
      },
    });

    const contactverzoekenCopy = { ...this.contactverzoeken };

    for (const contactverzoek of result.contactverzoeken) {
      contactverzoekenCopy[contactverzoek.ID] = createReadyRemoteData(contactverzoek);
    }

    this.contactverzoeken = contactverzoekenCopy;
  };
}

export default ContactverzoekStore;
