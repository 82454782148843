import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { TransportContext } from '..';
import {
  Grid,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  DataTypeProvider,
  EditingState,
  RowDetailState,
  SelectionState,
} from '@devexpress/dx-react-grid';
import { format } from 'date-fns';
import _ from 'lodash';
import DetailComp, { IOpdrachtState } from './DetailComp';
import ProducttypeFilter from './ProducttypeFilter';
import {
  IOphalenOpdrachtenResultElementV2,
  IOphalenOpdrachtregelsResultElementV2,
} from '../../../../../../../shared/src/api/v2/transport/opdracht';
import FilterBalkV2, { IFilter, IFilterData, maakFilterSchema } from '../../../../FilterBalkV2';
import { IOphalenRelatiesResultElementV2 } from '../../../../../../../shared/src/api/v2/relatie';
import {
  EFunctioneleIcon,
  functioneleIconMap,
  IconBevestiging,
  IconSend,
  IconToevoegen,
  IconVerwijderen,
  IconVink,
  IconInformatie,
  IconKlok,
} from '../../../../Icons';
import useUrlState from '../../../../../core/useUrlState';
import { RootStoreContext } from '../../../../../stores/RootStore';
import {
  IFilterSchema,
  IFilterSchemaUitgebreideFilter,
} from '../../../../../../../shared/src/models/filter';
import api from '../../../../../api';
import { EOpdrachtstatusTransport } from '../../../../../bedrijfslogica/enums';
import {
  DXCommandComponent,
  DXTableCheckboxComponent,
  DXTableToggleCellComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../../helpers/dxTableGrid';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
} from '../../../../../models/IRemoteData';
import OpdrachtStatusFilter from './OpdrachtStatusFilter';
import useTPBVersturen from '../../../../../hooks/transport/opdracht/useTPBVersturen';
import MenuLayout from '../../../../MenuLayout';
import { Kleur } from '../../../../../bedrijfslogica/constanten';
import { EResultType } from '../../../../../stores/CheckStore';
import KnopLoadingSpinner from '../../../../Gedeeld/LoadingSpinner/KnopLoadingSpinner';
import ActieMenuKnop from '../../../../ActieMenuKnop';
import LoadingSpinner from '../../../../Gedeeld/LoadingSpinner';
import OpdrachtStatus from '../OpdrachtStatus';
import nameof from '../../../../../core/nameOf';
import PlanningStatus from '../../../../transport/PlanningStatus';
import AdresVisualisatie from '../../../../locatie/AdresVisualisatie';
import { formatteerAdresV2 } from '../../../../../helpers';
import VersturenBevestigingDialoog from '../../../../transport/VersturenBevestigingDialoog';
import WijzigenOpdrachtDialoog from '../../../../transport/WijzigenOpdrachtDialoog';
import VersturenDialoog from '../../../../transport/PlanningTabel/VersturenDialoog';
import OpgevenPremiumsDialoog from '../../../../transport/PlanningTabel/OpgevenPremiumsDialoog';
import OpdrachtInfoDialoog from '../../../../transport/OpdrachtInfoDialoog';
import NieuweOpdrachtDialoog from '../../../../transport/NieuweOpdrachtDialoog';
import styled from 'styled-components';

interface IProps extends RouteComponentProps {}

export enum EFilter {
  OpdrachtStatus = 'STATUSSEN',
  Producttype = 'PROD_TYPE_IDS',
}

export interface IVersturenOpdrachtenDialoogState {
  trsOpdIDs: number[];
}
export interface IVersturenBevestigingDialoogState {
  trsOpdIDs: number[];
  persID: number | null;
  relID?: number;
}
export interface IWijzigenOpdrachtDialoogState {
  trsOpdID: number;
}
export interface IBezoektijdenOphalenDialoogState {
  trsOpdIDs: number[];
}

export interface IOpgevenPremiumsDialoogState {
  trsOpdIDs: number[];
}

export interface ITransportopdrachtInfoDialoogState {
  trsOpdID: number;
}

export interface ITransportopdrachtToevoegenDialoogState {
  relID: number;
}

export interface IUrlState {
  opdrachtenSelectie: number[];
  uitgeklapteOpdrachten: number[];
  versturenOpdrachtenDialoogState: IVersturenOpdrachtenDialoogState | null;
  versturenBevestigingDialoogState: IVersturenBevestigingDialoogState | null;
  wijzigenOpdrachtDialoogState: IWijzigenOpdrachtDialoogState | null;
  bezoektijdenOphalenDialoogState: IBezoektijdenOphalenDialoogState | null;
  filterData: Array<IFilterData<EFilter>>;
  opdrachtenStates: Record<number, IOpdrachtState>;
  opgevenPremiumsDialoogState: IOpgevenPremiumsDialoogState | null;
  transportopdrachtInfoDialoogState: ITransportopdrachtInfoDialoogState | null;
  transportopdrachtToevoegenDialoogState: ITransportopdrachtToevoegenDialoogState | null;
}

export const defaultUrlState: IUrlState = {
  opdrachtenSelectie: [],
  uitgeklapteOpdrachten: [],
  versturenOpdrachtenDialoogState: null,
  versturenBevestigingDialoogState: null,
  wijzigenOpdrachtDialoogState: null,
  bezoektijdenOphalenDialoogState: null,
  opgevenPremiumsDialoogState: null,
  transportopdrachtInfoDialoogState: null,
  opdrachtenStates: {},
  filterData: [
    {
      naam: EFilter.OpdrachtStatus,
      data: [1, 2],
      isActief: true,
    },
    {
      naam: EFilter.Producttype,
      data: [],
      isActief: false,
    },
  ],
  transportopdrachtToevoegenDialoogState: null,
};

export interface IRegelMetRelatie extends IOphalenOpdrachtregelsResultElementV2 {
  relatie: IOphalenRelatiesResultElementV2 | null;
}

export interface IRegel extends IOphalenOpdrachtenResultElementV2 {
  regels: IRegelMetRelatie[];
}

export interface ITransportOpdrachtenContext {
  opdrachtenStates: Record<number, IOpdrachtState>;
  onOpdrachtenStatesChange: (opdrachtenStates: Record<number, IOpdrachtState>) => void;
  onRefreshRequest: () => void;
}
export const TransportOpdrachtenContext = React.createContext<ITransportOpdrachtenContext>(
  null as any,
);

const IconPremium = functioneleIconMap[EFunctioneleIcon.Premium];

interface IPrioriteitTrProps {
  kleuren?: boolean;
}

const PrioriteitTr = styled.tr<IPrioriteitTrProps>`
  td {
    ${(props) => (props.kleuren ? `background-color: ${Kleur.HeelLichtGeel} !important;` : '')}
  }
`;

const Opdrachten: React.FC<IProps> = observer((props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const { checkStore } = useContext(RootStoreContext);
  const transportContext = useContext(TransportContext);

  const [transportopdrachten, setTransportopdrachten] = useState<
    IOphalenOpdrachtenResultElementV2[] | null
  >(null);
  const [transportopdrachtregels, setTransportopdrachtregels] = useState<
    IOphalenOpdrachtregelsResultElementV2[] | null
  >(null);
  const [opdrachten, setOpdrachten] = useState<IRegel[] | null>(null);

  const [relaties, setRelaties] = useState<IOphalenRelatiesResultElementV2[] | null>(null);

  const [filterSchema, setFilterSchema] = useState<IFilterSchema>(
    maakFilterSchema(urlState.filterData),
  );

  const ophalenTransportopdrachten = useCallback(async () => {
    // const opdrachtenResult = (
    //   await api.v2.transport.opdracht.ophalenOpdrachtenV2({
    //     filterSchema: {
    //       filters: [...filterSchema.filters!, { naam: 'REL_IDS', data: [transportContext.relID] }],
    //     },
    //     orderSchema: {
    //       orders: [{ naam: 'OPDRACHTNUMMER', richting: 'DESC' }],
    //     },
    //   })
    // ).opdrachten;

    const contracten = (
      await api.v2.contract.ophalenContractenV4({
        filterSchema: { filters: [{ naam: 'REL_IDS', data: [transportContext.relID] }] },
      })
    ).contracten;

    const cntBasisIDs = contracten.map((x) => x.basis.CntBasisID);
    const locIDs = contracten.map((x) => x.basis.LocID);

    const opdrachtenResult = (
      await api.v2.transport.opdracht.ophalenOpdrachtenV2({
        filterSchema: {
          filters: [...filterSchema.filters!],
          uitgebreideFilter: {
            or: [
              {
                filter: {
                  naam: 'REL_IDS',
                  data: [transportContext.relID],
                },
              },
              {
                filter: {
                  naam: 'CNTBASIS_IDS',
                  data: cntBasisIDs,
                },
              },
              // {
              //   filter: {
              //     naam: 'LOC_IDS',
              //     data: locIDs,
              //   },
              // },
            ].filter((x) => x !== null) as IFilterSchemaUitgebreideFilter[],
          },
        },
        orderSchema: {
          orders: [{ naam: 'OPDRACHTNUMMER', richting: 'DESC' }],
        },
      })
    ).opdrachten;

    if (opdrachtenResult.length === 0) {
      setRelaties(null);
      setTransportopdrachten([]);
      return;
    }

    const opdrachten = opdrachtenResult.map((x) => {
      return { ...x };
    });

    setRelaties(null);
    setTransportopdrachten(opdrachten);

    if (opdrachten.length > 0 && urlState.uitgeklapteOpdrachten.length === 0) {
      const plannenEnUitstaandOpdrachten = opdrachten.filter(
        (x) =>
          x.opdrachtstatus.Status === EOpdrachtstatusTransport.Planning ||
          x.opdrachtstatus.Status === EOpdrachtstatusTransport.Uitstaand,
      );
      if (plannenEnUitstaandOpdrachten.length > 0) {
        setUrlStateSync(
          'uitgeklapteOpdrachten',
          plannenEnUitstaandOpdrachten.map((x) => x.TrsOpdID),
        );
        return;
      }
      const eersteOpdracht = opdrachten[0];
      setUrlStateSync('uitgeklapteOpdrachten', [eersteOpdracht.TrsOpdID]);
    }
  }, [transportContext.relID, filterSchema]);

  useEffect(() => {
    ophalenTransportopdrachten();
  }, [ophalenTransportopdrachten]);

  const ophalenOpdrachtregels = useCallback(async () => {
    if (transportopdrachten === null) {
      return;
    }

    const opdrachtregelsResult = (
      await api.v2.transport.opdracht.ophalenOpdrachtregelsV2({
        filterSchema: {
          filters: [{ naam: 'TRSOPD_IDS', data: transportopdrachten.map((x) => x.TrsOpdID) }],
        },
        orderSchema: {
          orders: [
            {
              naam: 'REGELNUMMER',
              richting: 'ASC',
            },
          ],
        },
      })
    ).regels;

    setTransportopdrachtregels(opdrachtregelsResult);
  }, [transportopdrachten]);

  useEffect(() => {
    ophalenOpdrachtregels();
  }, [ophalenOpdrachtregels]);

  const ophalenRelaties = useCallback(async () => {
    if (transportopdrachtregels === null) {
      return;
    }
    // setRelaties(null);

    const relIDs = transportopdrachtregels.filter((x) => x.RelID !== null).map((x) => x.RelID);
    const result = await api.v2.relatie.ophalenRelaties({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: relIDs,
          },
        ],
      },
    });
    setRelaties(result.relaties);
  }, [transportopdrachtregels]);

  useEffect(() => {
    ophalenRelaties();
  }, [ophalenRelaties]);

  // Alle gegevens bij elkaar rapen
  const ophalenOpdrachten = useCallback(async () => {
    if (transportopdrachten === null || transportopdrachtregels === null || relaties === null) {
      return;
    }

    const opdrachten = transportopdrachten.map((x) => {
      const regels = transportopdrachtregels
        .filter((r) => r.TrsOpdID === x.TrsOpdID)
        .map((x) => {
          const relatie = x.RelID !== null ? relaties.find((r) => r.RelID === x.RelID)! : null;
          return { ...x, relatie };
        });
      return { ...x, regels };
    });

    setOpdrachten(opdrachten);
  }, [transportopdrachten, transportopdrachtregels, relaties]);

  useEffect(() => {
    ophalenOpdrachten();
  }, [ophalenOpdrachten]);

  const handleVerwijderen = useCallback(async (trsOpdIDs: number[]) => {
    const checkData = await api.v2.transport.opdracht.checkVerwijderenOpdrachten({
      trsOpdIDs,
    });
    const checkResult = await checkStore.controleren({
      checkData,
    });
    if (checkResult.type === EResultType.Annuleren) {
      return;
    }

    if (
      (
        await checkStore.bevestigen({
          inhoud: (
            <span>
              Geselecteerde transportopdrachten verwijderen?
              <br />
              <br />
              Let op, dit kan niet meer ongedaan gemaakt worden.
            </span>
          ),
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }
    await api.v2.transport.opdracht.verwijderenOpdrachten({
      trsOpdIDs,
    });
    ophalenTransportopdrachten();
  }, []);

  const keyExtractor = useCallback((row: IRegel) => row.TrsOpdID, []);

  const kolommen = useMemo<TypedColumn<IRegel>[]>(
    () => [
      {
        name: 'Opdrachtnummer',
        title: 'Opd.nr',
      },
      {
        name: '__opdrachtInfo' as any,
        title: ' ',
      },
      {
        name: 'Bezoekdatum',
        title: 'Bezoekdatum',
      },
      // {
      //   name: '__tijdvak',
      //   title: 'Tijdvak',
      // },
      // {
      //   name: '__tijdenActueel' as any,
      //   title: ' ',
      // },
      {
        name: 'locatie',
        title: 'Locatie',
        getCellValue: (row) => {
          const locatie =
            row.locatie.Straatnaam +
            ' ' +
            row.locatie.Huisnummer +
            (row.locatie.Bisnummer !== null ? ' ' + row.locatie.Bisnummer : '') +
            ', ' +
            row.locatie.Plaatsnaam;
          return locatie;
        },
      },
      {
        name: '__indicaties' as any,
        title: 'Indicaties',
      },
      // {
      //   name: '__premiums' as any,
      //   title: 'Prem.',
      // },
      {
        name: '__opdrachtStatus' as any,
        title: 'P/U/A',
      },
      // {
      //   name: '__afmeldStatus' as any,
      //   title: 'Afmeldstatus',
      // },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRegel>[]>(
    () => [
      {
        columnName: 'Opdrachtnummer',
        width: 65,
      },
      {
        columnName: '__opdrachtInfo' as any,
        width: 40,
      },
      {
        columnName: 'Bezoekdatum',
        width: 110,
      },
      {
        columnName: '__tijdvak',
        width: 125,
      },
      // {
      //   columnName: '__tijdenActueel' as any,
      //   width: 30,
      // },
      {
        columnName: 'locatie',
        width: 275,
      },
      {
        columnName: '__indicaties' as any,
        width: 200,
      },
      {
        columnName: '__premiums' as any,
        width: 75,
      },
      {
        columnName: '__opdrachtStatus' as any,
        width: 110,
      },
      {
        columnName: '__afmeldStatus' as any,
        width: 115,
      },
    ],
    [],
  );

  const [producttypeFilterTypeIDs, setProducttypeFilterTypeIDs] = useState<IRemoteData<number[]>>(
    createPendingRemoteData(),
  );
  const bepaalProducttypeFilterTypeIDs = useCallback(async () => {
    // Opdrachten hier opnieuw ophalen want de typeIDs moeten wel van de volledige opdrachten
    // zijn en niet afhankelijk zijn van andere filters die de resultaatset beperken
    const opdrachtenResult = await api.v2.transport.opdracht.ophalenOpdrachten({
      filterSchema: {
        filters: [{ naam: 'REL_IDS', data: [transportContext.relID] }],
      },
    });

    if (opdrachtenResult.opdrachten.length === 0) {
      setProducttypeFilterTypeIDs(createReadyRemoteData([]));
      return;
    }

    const trsOpdIDs = opdrachtenResult.opdrachten.map((x) => x.TrsOpdID);

    const regelsResult = (
      await api.v2.transport.opdracht.ophalenOpdrachtregels({
        filterSchema: { filters: [{ naam: 'TRSOPD_IDS', data: trsOpdIDs }] },
        orderSchema: {
          orders: [
            {
              naam: 'REGELNUMMER',
              richting: 'ASC',
            },
          ],
        },
      })
    ).regels;

    const opdrachten = opdrachtenResult.opdrachten.map((x) => {
      const regels = regelsResult.filter((r) => r.TrsOpdID === x.TrsOpdID);
      return { ...x, regels };
    });

    const productTypeIDs = [
      ...new Set(
        opdrachten.flatMap((opdracht) =>
          opdracht.regels
            .map((regel) => (regel.product === null ? null : regel.product.TypeID))
            .filter((prodID) => prodID !== null),
        ),
      ),
    ] as number[];
    const typeIDs = [
      ...new Set([
        ...productTypeIDs,
        ...opdrachten.flatMap((opdracht) =>
          opdracht.regels
            .map((regel) => (regel.type === null ? null : regel.type.TypeID))
            .filter((typeID) => typeID !== null),
        ),
      ]),
    ] as number[];
    setProducttypeFilterTypeIDs(createReadyRemoteData(typeIDs));
  }, [transportContext.relID]);
  useEffect(() => {
    bepaalProducttypeFilterTypeIDs();
  }, [bepaalProducttypeFilterTypeIDs]);

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.OpdrachtStatus,
        altijdWeergevenInBalk: true,
        weergave: OpdrachtStatusFilter,
      },
      {
        naam: EFilter.Producttype,
        altijdWeergevenInBalk: false,
        weergave: (weergaveProps) => (
          <ProducttypeFilter {...weergaveProps} typeIDs={producttypeFilterTypeIDs} />
        ),
      },
    ],
    [producttypeFilterTypeIDs],
  );

  const transportOpdrachtenContextValue = useMemo<ITransportOpdrachtenContext>(
    () => ({
      opdrachtenStates: urlState.opdrachtenStates,
      onOpdrachtenStatesChange: (states) => setUrlStateSync('opdrachtenStates', states),
      onRefreshRequest: ophalenTransportopdrachten,
    }),
    [urlState.opdrachtenStates, setUrlStateSync],
  );

  const { versturenTPB, isBezig: TPBVersturenIsBezig } = useTPBVersturen(
    useMemo(() => {
      const opdrachtenGeselecteerd =
        opdrachten?.filter(
          (opdracht) => urlState.opdrachtenSelectie.indexOf(opdracht.TrsOpdID) !== -1,
        ) ?? null;

      return {
        opdrachten: opdrachtenGeselecteerd,
        contextRelIDs:
          opdrachtenGeselecteerd === null
            ? []
            : _.uniq(
                opdrachtenGeselecteerd
                  .map(
                    (opdracht) =>
                      opdracht.regels
                        .map((regel) => regel.RelID)
                        .filter((x) => x !== null) as number[],
                  )
                  .flatMap((x) => x),
              ),
      };
    }, [opdrachten, urlState.opdrachtenSelectie]),
  );

  return (
    <TransportOpdrachtenContext.Provider value={transportOpdrachtenContextValue}>
      <MenuLayout
        menu={
          <div className="d-flex flex-column">
            <div className="mt-2 d-flex align-items-center">
              <button
                className="btn btn-sm btn-light d-flex align-items-center"
                style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                disabled={urlState.opdrachtenSelectie.length === 0}
                onClick={() => {
                  if (transportopdrachten === null) {
                    return;
                  }
                  const opdracht = transportopdrachten.find(
                    (x) => x.TrsOpdID === urlState.opdrachtenSelectie[0],
                  )!;
                  setUrlStateSync('versturenBevestigingDialoogState', {
                    trsOpdIDs: urlState.opdrachtenSelectie,
                    relID: transportContext.relID,
                    persID: opdracht.persoon?.PersID,
                  });
                }}
              >
                <IconBevestiging style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                <span className="ml-2">Versturen bevestiging</span>
              </button>

              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-2"
                style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                disabled={urlState.opdrachtenSelectie.length === 0}
                onClick={async () => {
                  const checkData = await api.v2.transport.checkSelectieVersturenOpdrachten({
                    trsOpdIDs: urlState.opdrachtenSelectie,
                  });
                  if (
                    (await checkStore.controleren({ checkData })).type === EResultType.Annuleren
                  ) {
                    return;
                  }

                  setUrlStateSync('versturenOpdrachtenDialoogState', {
                    trsOpdIDs: urlState.opdrachtenSelectie,
                  });
                }}
              >
                <IconSend style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                <span className="ml-2">Versturen opdrachten</span>
              </button>

              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-2"
                style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                disabled={urlState.opdrachtenSelectie.length === 0 || TPBVersturenIsBezig}
                onClick={versturenTPB}
              >
                {TPBVersturenIsBezig ? (
                  <KnopLoadingSpinner />
                ) : (
                  <IconSend style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                )}
                <span className="ml-2">TPB versturen</span>
              </button>

              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-2"
                style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                disabled={urlState.opdrachtenSelectie.length === 0}
                onClick={async () => {
                  if (
                    (
                      await checkStore.bevestigen({
                        titel: 'Documentatie versturen?',
                      })
                    ).type === EResultType.Annuleren
                  ) {
                    return;
                  }

                  const result = await api.v2.transport.opdracht.versturenDocumentatieNaLevering({
                    trsOpdIDs: urlState.opdrachtenSelectie,
                  });
                }}
              >
                <IconBevestiging style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                <span className="ml-2">Versturen documentatie</span>
              </button>

              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-2"
                style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                disabled={urlState.opdrachtenSelectie.length === 0}
                onClick={async () => {
                  handleVerwijderen(urlState.opdrachtenSelectie);
                }}
              >
                <IconVerwijderen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                <span className="ml-2">Verwijderen</span>
              </button>

              <div className="flex-fill" />

              <ActieMenuKnop
                acties={[
                  {
                    text: 'Verwijderen opdracht(en)',
                    icon: <IconVerwijderen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />,
                    disabled: urlState.opdrachtenSelectie.length === 0,
                    onClick: async () => {
                      handleVerwijderen(urlState.opdrachtenSelectie);
                    },
                  },
                  {
                    text: 'Opdracht als Afgehandeld markeren',
                    icon: <IconVink style={{ width: 16, height: 16, fill: Kleur.Grijs }} />,
                    disabled: urlState.opdrachtenSelectie.length === 0,
                    onClick: async () => {
                      const trsOpdIDs = urlState.opdrachtenSelectie;

                      const checkData = await api.v2.transport.opdracht.uitstaand.checkMarkerenOpdrachtenAfgehandeld(
                        {
                          trsOpdIDs,
                        },
                      );
                      const checkResult = await checkStore.controleren({
                        checkData,
                      });
                      if (checkResult.type === EResultType.Annuleren) {
                        return;
                      }

                      if (
                        (
                          await checkStore.bevestigen({
                            inhoud: 'Geselecteerde transportopdrachten als Afgehandeld markeren?',
                          })
                        ).type === EResultType.Annuleren
                      ) {
                        return;
                      }

                      await api.v2.transport.opdracht.uitstaand.markerenOpdrachtenAfgehandeld({
                        trsOpdIDs,
                      });

                      ophalenTransportopdrachten();
                    },
                  },
                  {
                    text: 'Opdracht als Uitstaand markeren',
                    // icon: <IconVink style={{ width: 16, height: 16, fill: Kleur.Grijs }} />,
                    disabled: urlState.opdrachtenSelectie.length === 0,
                    onClick: async () => {
                      const params = { trsOpdIDs: urlState.opdrachtenSelectie };

                      const checkData = await api.v2.transport.opdracht.afgehandeld.checkMarkerenOpdrachtenUitstaand(
                        params,
                      );
                      const checkResult = await checkStore.controleren({
                        checkData,
                      });
                      if (checkResult.type === EResultType.Annuleren) {
                        return;
                      }

                      if (
                        (
                          await checkStore.bevestigen({
                            inhoud: (
                              <span>
                                Geselecteerde transportopdracht(en) markeren als Uitstaand?
                                <br />
                                Hiermee komt de opdracht weer onder tabblad Uitstaand te staan.
                              </span>
                            ),
                          })
                        ).type === EResultType.Annuleren
                      ) {
                        return;
                      }

                      await api.v2.transport.opdracht.afgehandeld.markerenOpdrachtenUitstaand(
                        params,
                      );

                      ophalenTransportopdrachten();
                    },
                  },
                  {
                    text: 'Nieuwe opdracht',
                    icon: <IconToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />,
                    onClick: async () => {
                      setUrlStateSync('transportopdrachtToevoegenDialoogState', {
                        relID: transportContext.relID,
                      });
                    },
                  },
                  {
                    text: 'Versturen bezoektijden',
                    disabled: urlState.opdrachtenSelectie.length === 0,
                    onClick: async () => {
                      const checkData = await api.v2.transport.bezoek.checkVersturenBezoekHerinneringen(
                        {
                          trsOpdIDs: urlState.opdrachtenSelectie,
                        },
                      );
                      if (
                        (await checkStore.controleren({ checkData })).type === EResultType.Annuleren
                      ) {
                        return;
                      }

                      if (
                        (
                          await checkStore.bevestigen({
                            inhoud:
                              'Bezoekherinneringen versturen voor de geselecteerde opdrachten?',
                          })
                        ).type === EResultType.Annuleren
                      ) {
                        return;
                      }

                      await api.v2.transport.bezoek.versturenBezoekHerinneringen({
                        trsOpdIDs: urlState.opdrachtenSelectie,
                      });

                      ophalenTransportopdrachten();
                    },
                  },
                  {
                    text: 'Bezoektijden ophalen',
                    disabled: urlState.opdrachtenSelectie.length === 0,
                    onClick: async () => {
                      if (
                        (
                          await checkStore.bevestigen({
                            inhoud: 'Bezoektijden voor de geselecteerde opdrachten ophalen?',
                          })
                        ).type === EResultType.Annuleren
                      ) {
                        return;
                      }

                      const result = await api.v2.transport.bijwerkenBezoektijden({
                        trsOpdIDs: urlState.opdrachtenSelectie,
                      });

                      ophalenTransportopdrachten();
                    },
                  },
                  {
                    text: 'Bezoektijden wissen',
                    disabled: urlState.opdrachtenSelectie.length === 0,
                    onClick: async () => {
                      if (
                        (
                          await checkStore.bevestigen({
                            inhoud: 'Bezoektijden wissen voor de geselecteerde opdrachten?',
                          })
                        ).type === EResultType.Annuleren
                      ) {
                        return;
                      }
                      await api.v2.transport.opdracht.verwijderenBezoektijden({
                        trsOpdIDs: urlState.opdrachtenSelectie,
                      });

                      ophalenTransportopdrachten();
                    },
                  },
                  {
                    text: 'Premiums toevoegen',
                    disabled: urlState.opdrachtenSelectie.length === 0,
                    onClick: async () => {
                      const checkData = await api.v2.transport.opdracht.checkOpgevenPremiums({
                        trsOpdIDs: urlState.opdrachtenSelectie,
                        typeIDs: [], // dummie
                      });
                      const checkResult = await checkStore.controleren({
                        checkData,
                      });
                      if (checkResult.type === EResultType.Annuleren) {
                        return;
                      }

                      setUrlStateSync('opgevenPremiumsDialoogState', {
                        trsOpdIDs: urlState.opdrachtenSelectie,
                      });
                    },
                  },
                ]}
              />
            </div>
            <div className="mt-2">
              <FilterBalkV2
                filters={filters}
                filterData={urlState.filterData}
                onFilterDataChange={(x) => setUrlStateSync('filterData', x)}
                onFilterSchemaChange={setFilterSchema}
              />
            </div>
          </div>
        }
        body={
          opdrachten === null ? (
            <div className="d-flex flex-fill align-items-center justify-content-center">
              <LoadingSpinner />
            </div>
          ) : (
            <GridStyleWrapper height="calc(100vh - 150px)">
              <Grid getRowId={keyExtractor} rows={opdrachten} columns={kolommen}>
                <DataTypeProvider
                  for={['Opdrachtnummer']}
                  formatterComponent={(formatterProps) => {
                    const rij = formatterProps.row as IRegel;

                    const vanRelatie = rij.regels.some((x) => x.RelID === transportContext.relID);
                    return (
                      <span style={{ color: !vanRelatie ? Kleur.LichtGrijs : undefined }}>
                        {rij.Opdrachtnummer}
                      </span>
                    );
                  }}
                />

                <DataTypeProvider
                  for={['__opdrachtStatus']}
                  formatterComponent={(formatterProps) => {
                    const rij = formatterProps.row as IRegel;

                    const naam =
                      rij.opdrachtstatus.Status === EOpdrachtstatusTransport.Planning
                        ? 'Planning'
                        : rij.opdrachtstatus.Status === EOpdrachtstatusTransport.Uitstaand
                        ? 'Uitstaand'
                        : rij.opdrachtstatus.Status === EOpdrachtstatusTransport.Afgehandeld
                        ? 'Afgehandeld'
                        : '';

                    return <OpdrachtStatus status={rij.opdrachtstatus.Status} naam={naam} />;
                  }}
                />

                <DataTypeProvider
                  for={['__opdrachtInfo']}
                  formatterComponent={(formatterProps) => {
                    const rij = formatterProps.row as IRegel;

                    return (
                      <div className="mb-1">
                        <a
                          href="#"
                          // style={{ color: Kleur.DonkerGrijs }}
                          onClick={() => {
                            setUrlStateSync('transportopdrachtInfoDialoogState', {
                              trsOpdID: rij.TrsOpdID,
                            });
                          }}
                        >
                          <IconInformatie style={{ width: 17, height: 17, fill: Kleur.Blauw }} />
                        </a>
                        {rij.Notities !== null ? (
                          <span className="m-1" style={{ color: Kleur.Blauw }}>
                            !
                          </span>
                        ) : (
                          <span></span>
                        )}
                      </div>
                    );
                  }}
                />

                <DataTypeProvider
                  for={[nameof<IRegel>('Bezoekdatum')]}
                  formatterComponent={(formatterProps) => {
                    const rij = formatterProps.row as IRegel;
                    return (
                      <span>
                        {rij.Bezoekdatum === null ? (
                          <span style={{ color: Kleur.Rood }}>Nog opgeven</span>
                        ) : (
                          <span>{format(new Date(rij.Bezoekdatum), 'dd-MM-yyyy')}</span>
                        )}
                      </span>
                    );
                  }}
                />

                <DataTypeProvider
                  for={['__tijdvak']}
                  formatterComponent={(formatterProps) => {
                    const rij = formatterProps.row as IRegel;

                    const tijdvak =
                      rij.BezoekdatumVan !== null || rij.BezoekdatumTot !== null
                        ? (rij.BezoekdatumVan !== null
                            ? format(new Date(rij.BezoekdatumVan), 'HH:mm')
                            : '') +
                          ' - ' +
                          (rij.BezoekdatumTot !== null
                            ? format(new Date(rij.BezoekdatumTot), 'HH:mm')
                            : '')
                        : null;

                    const voorkeurTijdvak =
                      rij.VoorkeurBezoektijdVan !== null || rij.VoorkeurBezoektijdTot !== null
                        ? (rij.VoorkeurBezoektijdVan !== null ? rij.VoorkeurBezoektijdVan : '') +
                          ' - ' +
                          (rij.VoorkeurBezoektijdTot !== null ? rij.VoorkeurBezoektijdTot : '')
                        : null;

                    return (
                      <span>
                        {tijdvak !== null
                          ? tijdvak
                          : voorkeurTijdvak !== null
                          ? '(' + voorkeurTijdvak + ')'
                          : ''}{' '}
                        {rij.BezoektijdenActueel ? (
                          <IconKlok
                            style={{
                              width: 16,
                              height: 16,
                              fill: Kleur.Groen,
                              marginTop: -3,
                              marginLeft: 2,
                            }}
                          />
                        ) : (
                          <span></span>
                        )}
                        {rij.IndicatieAAA ? ' (A)' : ''}
                      </span>
                    );
                  }}
                />

                <DataTypeProvider
                  for={['__indicaties']}
                  formatterComponent={(formatterProps) => {
                    const rij = formatterProps.row as IRegel;
                    if (rij.opdrachtstatus.Status !== EOpdrachtstatusTransport.Afgehandeld) {
                      return <PlanningStatus opdracht={rij} />;
                    }
                    return <span></span>;
                  }}
                />

                <DataTypeProvider
                  for={['locatie']}
                  formatterComponent={(formatterProps) => {
                    const rij = formatterProps.row as IRegel;

                    return (
                      <AdresVisualisatie
                        weergaveTekst={formatteerAdresV2({
                          bisnummer: rij.locatie.Bisnummer,
                          straatnaam: rij.locatie.Straatnaam,
                          huisnummer: rij.locatie.Huisnummer,
                          postcode: rij.locatie.Postcode,
                          plaatsnaam: rij.locatie.Plaatsnaam,
                          landnaamKort: rij.locatie.LandnaamKort,
                          landnaamEnum: rij.locatie.LandnaamEnum,
                        })}
                      />
                    );
                  }}
                />

                <DataTypeProvider
                  for={['__afmeldStatus']}
                  formatterComponent={(formatterProps) => {
                    const rij = formatterProps.row as IRegel;

                    if (rij.opdrachtstatus.Status !== 2) {
                      return <span></span>;
                    }

                    const reedsAfgemeld = rij.regels.some((x) => x.status.Status !== 0);
                    const nogAfmelden = rij.regels.some((x) => x.status.Status === 0);

                    const afgemeld =
                      reedsAfgemeld && nogAfmelden ? (
                        <span style={{ color: Kleur.LichtGroen }}>Deels afgemeld</span>
                      ) : reedsAfgemeld ? (
                        <span style={{ color: Kleur.Groen }}>Afgemeld</span>
                      ) : (
                        // <a
                        //   href="#"
                        //   onClick={async () => {
                        //     // setRegelIDs(row.regels.map((x) => x.TrsRegID));
                        //     // setAfmeldenDialoogTonen(true);
                        //     // handleRequestRefresh();
                        //   }}
                        //   style={{ color: Kleur.Blauw }}
                        // >
                        //   Afmelden
                        // </a>
                        <span style={{ color: Kleur.Grijs }}>Niet afgemeld</span>
                      );

                    return <span>{afgemeld}</span>;
                  }}
                />

                <EditingState
                  onAddedRowsChange={() => {}}
                  onEditingRowIdsChange={(x) => {
                    const id = x[x.length - 1] as number;
                    setUrlStateSync('wijzigenOpdrachtDialoogState', { trsOpdID: id });
                  }}
                  onCommitChanges={async (changes) => {
                    if (changes.deleted === undefined) {
                      return;
                    }
                    const deleted = changes.deleted;
                    const id = deleted[deleted.length - 1] as number;
                    // await handleVerwijderenOpdracht(id);
                  }}
                />

                <RowDetailState
                  expandedRowIds={urlState.uitgeklapteOpdrachten}
                  onExpandedRowIdsChange={(x) =>
                    setUrlStateSync('uitgeklapteOpdrachten', x as number[])
                  }
                />
                <VirtualTable
                  messages={{ noData: 'Geen opdrachten aanwezig voor het filter' }}
                  rowComponent={(rowProps) => {
                    const rij = rowProps.row as IRegel;

                    const vanRelatie = rij.regels.some((x) => x.RelID === transportContext.relID);

                    if (!vanRelatie) {
                      return <PrioriteitTr kleuren={true}>{rowProps.children}</PrioriteitTr>;
                    }
                    return <tr>{rowProps.children}</tr>;
                  }}
                />
                <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
                <TableHeaderRow />
                <TableEditColumn
                  width={60}
                  // showAddCommand={true}
                  showEditCommand
                  // showDeleteCommand
                  commandComponent={DXCommandComponent}
                />
                <TableRowDetail
                  contentComponent={DetailComp}
                  toggleCellComponent={DXTableToggleCellComponent}
                />
                <SelectionState
                  selection={urlState.opdrachtenSelectie}
                  onSelectionChange={(x) => setUrlStateSync('opdrachtenSelectie', x as number[])}
                />
                <TableSelection cellComponent={DXTableCheckboxComponent} />
              </Grid>
            </GridStyleWrapper>
          )
        }
      />
      {urlState.versturenBevestigingDialoogState !== null && (
        <VersturenBevestigingDialoog
          open
          onSuccess={async () => {
            setUrlStateSync('versturenBevestigingDialoogState', null);
            ophalenTransportopdrachten();
          }}
          onAnnuleren={() => setUrlStateSync('versturenBevestigingDialoogState', null)}
          trsOpdIDs={urlState.opdrachtenSelectie}
          persID={urlState.versturenBevestigingDialoogState.persID}
          relID={transportContext.relID}
        />
      )}
      {urlState.wijzigenOpdrachtDialoogState !== null && (
        <WijzigenOpdrachtDialoog
          open
          onSuccess={() => {
            setUrlStateSync('wijzigenOpdrachtDialoogState', null);
            ophalenTransportopdrachten();
          }}
          onAnnuleren={() => setUrlStateSync('wijzigenOpdrachtDialoogState', null)}
          trsOpdID={urlState.wijzigenOpdrachtDialoogState.trsOpdID}
          contextRelID={transportContext.relID}
        />
      )}
      {urlState.versturenOpdrachtenDialoogState !== null && (
        <VersturenDialoog
          open
          onSuccess={async () => {
            setUrlStateSync('versturenOpdrachtenDialoogState', null);
            ophalenTransportopdrachten();
          }}
          onAnnuleren={() => setUrlStateSync('versturenOpdrachtenDialoogState', null)}
          trsOpdIDs={urlState.versturenOpdrachtenDialoogState.trsOpdIDs}
        />
      )}
      {urlState.opgevenPremiumsDialoogState !== null && (
        <OpgevenPremiumsDialoog
          open
          onSuccess={async () => {
            setUrlStateSync('opgevenPremiumsDialoogState', null);
            ophalenTransportopdrachten();
          }}
          onAnnuleren={() => setUrlStateSync('opgevenPremiumsDialoogState', null)}
          trsOpdIDs={urlState.opgevenPremiumsDialoogState.trsOpdIDs}
        />
      )}
      {urlState.transportopdrachtInfoDialoogState !== null && (
        <OpdrachtInfoDialoog
          open
          trsOpdID={urlState.transportopdrachtInfoDialoogState.trsOpdID}
          onSuccess={() => {
            setUrlStateSync('transportopdrachtInfoDialoogState', null);
          }}
          onAnnuleren={() => setUrlStateSync('transportopdrachtInfoDialoogState', null)}
        />
      )}
      {urlState.transportopdrachtToevoegenDialoogState !== null && (
        <NieuweOpdrachtDialoog
          open
          relID={urlState.transportopdrachtToevoegenDialoogState.relID}
          // soortOpdracht={context.nieuweTransportopdrachtDialoogState.soortopdracht}
          leverProduct={null}
          onSuccess={async () => {
            ophalenTransportopdrachten();
            setUrlStateSync('transportopdrachtToevoegenDialoogState', null);
          }}
          onAnnuleren={() => setUrlStateSync('transportopdrachtToevoegenDialoogState', null)}
        />
      )}
    </TransportOpdrachtenContext.Provider>
  );
});

export default withRouter(Opdrachten);
