import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import MenuLayout from '../../../../components/MenuLayout';
import ASPTabel from '../../../../components/tabel/ASPTabel';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../models/IRemoteData';
import api, { IPaginatiePositie } from '../../../../api';
import FilterBalkV2, {
  IFilter,
  IFilterData,
  maakFilterSchema,
} from '../../../../components/FilterBalkV2';
import { RootStoreContext } from '../../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import useUrlState from '../../../../core/useUrlState';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import RekeningCombobox from './RekeningCombobox';
import { IBanksaldo } from '../../../../../../shared/src/api/v2/boekhouding/controle';
import { ASPKolom, EAspKolomBreedteType } from '../../../../components/tabel/ASPTabel/types';
import { addMonths, format } from 'date-fns';
import FormatteerBedrag from '../../../../components/MutatieBedrag';
import { IconCheck } from '../../../../components/Icons';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import UitgeklapteRij from './UitgeklapteRij';
import DatumKiezer from '../../../../components/formulier/DatumKiezer';
import { dagDatum } from '../../../../helpers/datum';
import { Helmet } from 'react-helmet';

enum EFilter {
  Bankrekening = 'BANK_REK_IDS',
  MetVerschil = 'MET_VERSCHIL',
  Peildatum = 'PEILDATUM',
}

enum EKolom {
  Mutatiedatum,
  AantalMutaties,
  CumulatiefSaldo,
  MutatieSaldo,
  GeboektSaldo,
  VerschilSaldo,
}

interface IData {
  boeksaldi: Record<number, IBanksaldo>;
  totaalAantal: number;
}

interface IUrlState {
  filterdata: IFilterData<EFilter>[];
  uitgeklapt: string[];
}

export interface IBanksaldiV2Context {
  bankRekID: number;
}

export const BanksaldiV2Context = React.createContext<IBanksaldiV2Context>(null as any);

interface IProps extends RouteComponentProps {}

const BanksaldiV2 = observer((props: IProps) => {
  const { bankStore, instellingStore } = useContext(RootStoreContext);

  const [urlState, setUrlState, setUrlStateSync] = useUrlState<IUrlState>(
    props,
    useMemo(
      () => ({
        uitgeklapt: [],
        filterdata: [
          {
            naam: EFilter.Bankrekening,
            data: instellingStore.BankRekID === null ? [] : [instellingStore.BankRekID],
            isActief: true,
            isLokaleFilter: true,
          },
          {
            naam: EFilter.MetVerschil,
            data: true,
            isActief: false,
          },
          {
            naam: EFilter.Peildatum,
            data: dagDatum(addMonths(new Date(), -6)).toISOString(),
            isActief: true,
            isLokaleFilter: true,
          },
        ],
      }),
      [],
    ),
  );
  const filterSchema = useMemo(() => maakFilterSchema(urlState.filterdata), [urlState.filterdata]);

  const dataRef = useRef<IRemoteData<IData>>(createPendingRemoteData());
  const [dataState, setDataState] = useState(dataRef.current);
  const data = useMemo(() => dataState, [dataState]);
  const setData = useCallback((data: IRemoteData<IData>) => {
    dataRef.current = data;
    setDataState(data);
  }, []);

  const bankRekID = useMemo<number>(() => {
    const bankrekeningFilter = urlState.filterdata.find((x) => x.naam === EFilter.Bankrekening)!;
    return bankrekeningFilter.data[0];
  }, [JSON.stringify(urlState.filterdata)]);

  const ophalenAbortControllerRef = useRef<AbortController | null>(null);
  const ophalenData = useCallback(
    async (paginatie: IPaginatiePositie, uitbreiden: boolean) => {
      if (ophalenAbortControllerRef.current !== null) {
        ophalenAbortControllerRef.current.abort();
      }
      ophalenAbortControllerRef.current = new AbortController();
      const peildatumFilter = urlState.filterdata.find((x) => x.naam === EFilter.Peildatum)!;

      const result = await api.v2.boekhouding.controle.ophalenBanksaldi(
        {
          bankRekID,
          peildatum: peildatumFilter.data,
          filterSchema: {
            filters: [
              ...(filterSchema.filters ?? []),
              // {
              //   naam: 'IS_DEFINITIEF',
              //   data: true,
              // },
              // {
              //   naam: 'MUTATIEDATUM_VANAF',
              //   data: format(new Date(), 'yyyy-MM-dd'),
              // },
            ],
          },
          paginatie,
          orderSchema: {
            orders: [
              {
                naam: 'MUTATIEDATUM',
                richting: 'DESC',
              },
            ],
          },
        },
        ophalenAbortControllerRef.current!.signal,
      );

      const boeksaldi = result.boeksaldi.reduce(
        (acc, curr, idx) => ({
          ...acc,
          [paginatie.index + idx]: curr,
        }),
        uitbreiden ? dataRef.current?.data?.boeksaldi ?? {} : {},
      );

      setData(
        createReadyRemoteData({
          totaalAantal: result.totaalAantal,
          boeksaldi,
        }),
      );
    },
    [JSON.stringify(filterSchema), JSON.stringify(urlState.filterdata), bankRekID],
  );

  useEffect(() => {
    ophalenData({ index: 0, aantal: 5000 }, false);
  }, [ophalenData]);

  const handleExtraRijenAangevraagd = useCallback(
    async (paginatie: IPaginatiePositie) => {
      await ophalenData(paginatie, true);
    },
    [ophalenData],
  );

  const keyExtractor = useCallback(
    (item: IBanksaldo): string => format(new Date(item.Mutatiedatum), 'dd-MM-yyyy'),
    [],
  );

  const kolommen = useMemo<ASPKolom<EKolom, IBanksaldo>[]>(
    () => [
      {
        key: EKolom.Mutatiedatum,
        label: 'Mutatiedatum',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 125,
        renderer: (item) => format(new Date(item.Mutatiedatum), 'dd-MM-yyyy'),
      },
      {
        key: EKolom.AantalMutaties,
        label: 'Aantal mutaties',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 135,
        renderer: (item) => item.aantalMutaties,
      },
      {
        key: EKolom.CumulatiefSaldo,
        label: 'Cumulatief',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 125,
        renderer: (item) => <FormatteerBedrag bedrag={item.cumulatiefSaldo} />,
      },
      {
        key: EKolom.MutatieSaldo,
        label: 'Saldo',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 125,
        renderer: (item) => <FormatteerBedrag bedrag={item.mutatieSaldo} />,
      },
      {
        key: EKolom.GeboektSaldo,
        label: 'Geboekt',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 125,
        renderer: (item) => <FormatteerBedrag bedrag={item.geboektSaldo} />,
      },
      {
        key: EKolom.VerschilSaldo,
        label: 'Verschil',
        breedteType: EAspKolomBreedteType.Flex,
        flex: 1,
        renderer: (item) =>
          item.verschilSaldo === 0 ? (
            <IconCheck style={{ fill: Kleur.LichtGroen, width: 18, height: 18 }} />
          ) : (
            <FormatteerBedrag bedrag={item.verschilSaldo} />
          ),
      },
    ],
    [],
  );

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.Bankrekening,
        altijdWeergevenInBalk: true,
        actiefMuteerbaar: false,
        weergave: (weergaveProps) => {
          const data: number[] = weergaveProps.data;
          const waarde = data.length === 0 ? null : data[0];

          return (
            <div className="d-flex align-items-center">
              <span>Rekening</span>
              <span className="ml-2">
                <RekeningCombobox
                  bankRekID={waarde}
                  onBankRekIDChange={(x) => {
                    weergaveProps.onDataChange(x === null ? [] : [x]);
                    weergaveProps.toepassen();
                  }}
                />
              </span>
            </div>
          );
        },
      },
      {
        naam: EFilter.MetVerschil,
        weergave: (weergaveProps) => {
          return <span>Alleen met verschil</span>;
        },
        altijdWeergevenInBalk: true,
      },
      {
        naam: EFilter.Peildatum,
        altijdWeergevenInBalk: true,
        actiefMuteerbaar: false,
        weergave: (weergaveProps) => {
          const date = new Date(weergaveProps.data);

          return (
            <div className="d-flex align-items-center">
              <span>Vanaf datum</span>
              <span className="ml-2">
                <DatumKiezer
                  waarde={date}
                  onGewijzigd={(x) => {
                    weergaveProps.onDataChange(x!.toISOString());
                    weergaveProps.toepassen();
                  }}
                  determineNextValidDate="ONBEGRENST"
                  determinePreviousValidDate="ONBEGRENST"
                />
              </span>
            </div>
          );
        },
      },
    ],
    [bankStore.rekeningen],
  );

  const context = useMemo<IBanksaldiV2Context>(
    () => ({
      bankRekID,
    }),
    [bankRekID],
  );

  return (
    <BanksaldiV2Context.Provider value={context}>
      <Helmet>
        <title>Controle saldi</title>
      </Helmet>
      <MenuLayout
        menu={
          <div className="d-flex align-items-center">
            <FilterBalkV2
              filters={filters}
              filterData={urlState.filterdata}
              onFilterDataChange={(x) => setUrlStateSync('filterdata', x)}
            />
          </div>
        }
        body={
          data.state === ERemoteDataState.Pending ? (
            <div className="d-flex align-items-center justify-content-center flex-fill">
              <LoadingSpinner />
            </div>
          ) : (
            <ASPTabel<string, EKolom, IBanksaldo>
              keyExtractor={keyExtractor}
              rijen={data.data!.boeksaldi}
              kolommen={kolommen}
              totaalAantalRijen={data.data!.totaalAantal}
              onExtraRijenAangevraagd={handleExtraRijenAangevraagd}
              uitgeklapt={urlState.uitgeklapt}
              onUitgeklaptChange={(x) => setUrlStateSync('uitgeklapt', x)}
              uitgeklapteRijComponent={UitgeklapteRij}
              uitgeklapteRijHoogte={500}
            />
          )
        }
      />
    </BanksaldiV2Context.Provider>
  );
});

export default withRouter(BanksaldiV2);
