import api from '../../..';
import {
  IOphalenBanksaldiParams,
  IOphalenBanksaldiResult,
} from '../../../../../../shared/src/api/v2/boekhouding/controle';

const controle = {
  ophalenBanksaldi: async (
    params: IOphalenBanksaldiParams,
    signal?: AbortSignal,
  ): Promise<IOphalenBanksaldiResult> => {
    return await api.post('/v2/boekhouding/controle/ophalen-banksaldi', params, {
      signal,
    });
  },
};

export default controle;
