import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import IDialoogProps from '../../../../../core/IDialoogProps';
import Dialoog from '../../../../../components/dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import { Formik, FormikActions, FormikProps } from 'formik';
import LoadingSpinner from '../../../../../components/Gedeeld/LoadingSpinner';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import * as Yup from 'yup';
import {
  IOphalenProductsoortenResultElement,
  IWijzigenProductsoortParams,
} from '../../../../../../../shared/src/api/v2/product/soort';
import api from '../../../../../api';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../../../stores/RootStore';
import Tabblad, { ITabblad } from '../../../../../components/layout/Tabblad';
import ProductsoortTab from './ProductsoortTab';
import TaalafhankelijkTab from './TaalafhankelijkTab';
import ITaalTekst from '../../../../../../../shared/src/models/talen/ITaalTekst';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../../models/IRemoteData';
import { IOphalenTekstenInAlleTalenResult } from '../../../../../../../shared/src/api/v2/tekst';

interface IProps extends IDialoogProps<null> {
  id: number;
}

export interface IFormikValues {
  naam: string;
  naamKort: string;
  actief: boolean;
  naamEnum: string | null;
  aantalGebruikersTarief: number;
  aantalGebruikersMax: number;
  leeftijdGrens: number | null;
  standaardVolume: number | null;
  naamEnkelvoud: ITaalTekst[];
  naamMeervoud: ITaalTekst[];
  slug: ITaalTekst[];
  overzichtOmschrijving: ITaalTekst[];
  toelichting1: ITaalTekst[];
  toelichting2: ITaalTekst[];
  toelichting3: ITaalTekst[];
  paginatitel: ITaalTekst[];
  metadata: ITaalTekst[];
}

export const veldnamen: Record<keyof IFormikValues, string> = {
  naam: 'Naam',
  naamKort: 'Naam kort',
  actief: 'Actief',
  naamEnum: 'Naam enum',
  aantalGebruikersTarief: 'Atl. gebr. tarief',
  aantalGebruikersMax: 'Atl. gebr. max',
  leeftijdGrens: 'Lft.grens',
  standaardVolume: 'Standaard volume (in M3)',
  naamEnkelvoud: 'Naam enkelvoud',
  naamMeervoud: 'Naam meervoud',
  slug: 'Slug',
  overzichtOmschrijving: 'Overzicht omschrijving',
  toelichting1: 'Toelichting 1',
  toelichting2: 'Toelichting 2',
  toelichting3: 'Toelichting 3',
  paginatitel: 'Paginatitel',
  metadata: 'Metadata',
};

const WijzigenDialoog: React.FC<IProps> = observer((props) => {
  const { dialoogIndex, onSuccess } = props;
  const { checkStore } = useContext(RootStoreContext);
  const formikRef = useRef<Formik<IFormikValues>>(null);

  const [productsoort, setProductsoort] = useState<IOphalenProductsoortenResultElement | null>(
    null,
  );

  const ophalenProductsoort = useCallback(async () => {
    const result = await api.v2.product.soort.ophalenProductsoorten({
      filterSchema: { filters: [{ naam: 'IDS', data: [props.id] }] },
    });

    setProductsoort(result[0]);
  }, [props.id]);

  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    ophalenProductsoort();
  }, [ophalenProductsoort]);

  const [teksten, setTeksten] = useState<IRemoteData<IOphalenTekstenInAlleTalenResult>>(
    createPendingRemoteData(),
  );

  const ophalenTeksten = useCallback(async () => {
    if (productsoort === null) {
      if (teksten.state === ERemoteDataState.Ready) {
        setTeksten(createPendingRemoteData());
      }
      return;
    }

    const tekstIDs = [
      productsoort.NaamEnkelvoud_TekstID,
      productsoort.NaamMeervoud_TekstID,
      productsoort.Slug_TekstID,
      productsoort.OverzichtOmschrijving_TekstID,
      productsoort.Toelichting1_TekstID,
      productsoort.Toelichting2_TekstID,
      productsoort.Toelichting3_TekstID,
      productsoort.Paginatitel_TekstID,
      productsoort.Metadata_TekstID,
    ].filter((x) => x !== null) as number[];

    const result = await api.v2.tekst.ophalenTekstenInAlleTalen({
      tekstIDs,
    });
    setTeksten(createReadyRemoteData(result));
  }, [productsoort]);
  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    ophalenTeksten();
  }, [ophalenTeksten]);

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (productsoort === null || teksten.state === ERemoteDataState.Pending) {
      return null;
    }
    const naamEnkelvoudTeksten =
      productsoort.NaamEnkelvoud_TekstID === null
        ? null
        : teksten.data!.teksten.filter((x) => x.TekstID === productsoort.NaamEnkelvoud_TekstID);
    const naamMeervoudTeksten =
      productsoort.NaamMeervoud_TekstID === null
        ? null
        : teksten.data!.teksten.filter((x) => x.TekstID === productsoort.NaamMeervoud_TekstID);
    const slugTeksten =
      productsoort.Slug_TekstID === null
        ? null
        : teksten.data!.teksten.filter((x) => x.TekstID === productsoort.Slug_TekstID);
    const overzichtOmschrijvingTeksten =
      productsoort.OverzichtOmschrijving_TekstID === null
        ? null
        : teksten.data!.teksten.filter(
            (x) => x.TekstID === productsoort.OverzichtOmschrijving_TekstID,
          );
    const toelichting1Teksten =
      productsoort.Toelichting1_TekstID === null
        ? null
        : teksten.data!.teksten.filter((x) => x.TekstID === productsoort.Toelichting1_TekstID);
    const toelichting2Teksten =
      productsoort.Toelichting2_TekstID === null
        ? null
        : teksten.data!.teksten.filter((x) => x.TekstID === productsoort.Toelichting2_TekstID);
    const toelichting3Teksten =
      productsoort.Toelichting3_TekstID === null
        ? null
        : teksten.data!.teksten.filter((x) => x.TekstID === productsoort.Toelichting3_TekstID);
    const paginatitelTeksten =
      productsoort.Paginatitel_TekstID === null
        ? null
        : teksten.data!.teksten.filter((x) => x.TekstID === productsoort.Paginatitel_TekstID);
    const metadataTeksten =
      productsoort.Metadata_TekstID === null
        ? null
        : teksten.data!.teksten.filter((x) => x.TekstID === productsoort.Metadata_TekstID);

    return {
      naam: productsoort.Naam,
      naamKort: productsoort.NaamKort,
      actief: productsoort.Actief,
      naamEnum: productsoort.NaamEnum,
      aantalGebruikersTarief: productsoort.AantalGebruikersTarief,
      aantalGebruikersMax: productsoort.AantalGebruikersMax,
      leeftijdGrens: productsoort.LeeftijdGrens === null ? 0 : productsoort.LeeftijdGrens,
      standaardVolume: productsoort.StandaardVolume,
      naamEnkelvoud:
        naamEnkelvoudTeksten === null
          ? []
          : naamEnkelvoudTeksten.map((x) => ({
              tekst: x.Tekst ?? '',
              taalID: x.TaalID,
              toepassen: x.Toepassen,
            })),
      naamMeervoud:
        naamMeervoudTeksten === null
          ? []
          : naamMeervoudTeksten.map((x) => ({
              tekst: x.Tekst ?? '',
              taalID: x.TaalID,
              toepassen: x.Toepassen,
            })),
      slug:
        slugTeksten === null
          ? []
          : slugTeksten.map((x) => ({
              tekst: x.Tekst ?? '',
              taalID: x.TaalID,
              toepassen: x.Toepassen,
            })),
      overzichtOmschrijving:
        overzichtOmschrijvingTeksten === null
          ? []
          : overzichtOmschrijvingTeksten.map((x) => ({
              tekst: x.Tekst ?? '',
              taalID: x.TaalID,
              toepassen: x.Toepassen,
            })),
      toelichting1:
        toelichting1Teksten === null
          ? []
          : toelichting1Teksten.map((x) => ({
              tekst: x.Tekst ?? '',
              taalID: x.TaalID,
              toepassen: x.Toepassen,
            })),
      toelichting2:
        toelichting2Teksten === null
          ? []
          : toelichting2Teksten.map((x) => ({
              tekst: x.Tekst ?? '',
              taalID: x.TaalID,
              toepassen: x.Toepassen,
            })),
      toelichting3:
        toelichting3Teksten === null
          ? []
          : toelichting3Teksten.map((x) => ({
              tekst: x.Tekst ?? '',
              taalID: x.TaalID,
              toepassen: x.Toepassen,
            })),
      paginatitel:
        paginatitelTeksten === null
          ? []
          : paginatitelTeksten.map((x) => ({
              tekst: x.Tekst ?? '',
              taalID: x.TaalID,
              toepassen: x.Toepassen,
            })),
      metadata:
        metadataTeksten === null
          ? []
          : metadataTeksten.map((x) => ({
              tekst: x.Tekst ?? '',
              taalID: x.TaalID,
              toepassen: x.Toepassen,
            })),
    };
  }, [productsoort, teksten]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      if (productsoort === null) {
        return;
      }
      actions.setSubmitting(true);

      const params: IWijzigenProductsoortParams = {
        prodSrtID: props.id,
        naam: values.naam,
        naamKort: values.naamKort,
        actief: values.actief,
        naamEnum: values.naamEnum,
        aantalGebruikersTarief: values.aantalGebruikersTarief,
        aantalGebruikersMax: values.aantalGebruikersMax,
        leeftijdGrens: values.leeftijdGrens === 0 ? null : values.leeftijdGrens,
        standaardVolume: values.standaardVolume,
        naamEnkelvoud: {
          tekstID: productsoort.NaamEnkelvoud_TekstID,
          taalTeksten: values.naamEnkelvoud,
        },
        naamMeervoud: {
          tekstID: productsoort.NaamMeervoud_TekstID,
          taalTeksten: values.naamMeervoud,
        },
        slug: {
          tekstID: productsoort.Slug_TekstID,
          taalTeksten: values.slug,
        },
        overzichtOmschrijving: {
          tekstID: productsoort.OverzichtOmschrijving_TekstID,
          taalTeksten: values.overzichtOmschrijving,
        },
        toelichting1: {
          tekstID: productsoort.Toelichting1_TekstID,
          taalTeksten: values.toelichting1,
        },
        toelichting2: {
          tekstID: productsoort.Toelichting2_TekstID,
          taalTeksten: values.toelichting2,
        },
        toelichting3: {
          tekstID: productsoort.Toelichting3_TekstID,
          taalTeksten: values.toelichting3,
        },
        paginatitel: {
          tekstID: productsoort.Paginatitel_TekstID,
          taalTeksten: values.paginatitel,
        },
        metadata: {
          tekstID: productsoort.Metadata_TekstID,
          taalTeksten: values.metadata,
        },
      };

      await api.v2.product.soort.wijzigenProductsoort(params);

      actions.setSubmitting(false);
      onSuccess(null);
    },
    [productsoort],
  );

  const validationSchema = useMemo(() => {
    const fields: Partial<Record<keyof IFormikValues, any>> = {
      naam: Yup.string().required(),
      naamKort: Yup.string().required(),
      actief: Yup.boolean(),
      // naamEnum: Yup.string(),
      aantalGebruikersTarief: Yup.number().notRequired(),
      aantalGebruikersMax: Yup.number(),
      leeftijdGrens: Yup.number(),
    };

    return Yup.object().shape(fields);
  }, []);

  return (
    <Dialoog index={dialoogIndex || 0} modalProps={{ size: 'lg' }}>
      <ModalHeader>
        <ModalTitle>Wijzigen productsoort</ModalTitle>
      </ModalHeader>
      {initialValues === null ? (
        <LoadingSpinner />
      ) : (
        <Formik<IFormikValues>
          ref={formikRef}
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
          render={(formikProps: FormikProps<IFormikValues>) => {
            return <Formulier {...props} formikProps={formikProps} />;
          }}
        />
      )}
    </Dialoog>
  );
});

enum ETabblad {
  Productsoort,
  Taalafhankelijk,
}

export interface IFormulierProps {
  formikProps: FormikProps<IFormikValues>;
}

const Formulier = (props: IProps & IFormulierProps) => {
  const { submitForm, isSubmitting, isValid, values } = props.formikProps;
  const [tabblad, setTabblad] = useState<ETabblad>(ETabblad.Productsoort);

  const tabbladen = useMemo<ITabblad<ETabblad>[]>(
    () => [
      {
        id: ETabblad.Productsoort,
        label: 'Productsoort',
        content: ProductsoortTab,
      },
      {
        id: ETabblad.Taalafhankelijk,
        label: 'Taalafhankelijk',
        content: TaalafhankelijkTab,
      },
    ],
    [],
  );

  return (
    <>
      <ModalBody className="p-0">
        <Tabblad
          geselecteerd={tabblad}
          onSelectieChange={setTabblad}
          tabbladen={tabbladen}
          options={{
            tabbladComponentProps: {
              values,
            },
          }}
        />
      </ModalBody>

      <ModalFooter className="d-flex flex-row justify-content-start">
        <button
          className="btn btn-primary"
          onClick={submitForm}
          style={{ width: 100 }}
          disabled={isSubmitting || !isValid}
        >
          Ok
        </button>
        <button className="btn btn-secondary" onClick={props.onAnnuleren} style={{ width: 100 }}>
          Annuleren
        </button>
      </ModalFooter>
    </>
  );
};

export default WijzigenDialoog;
