import {
  IOphalenWebbetalingenParams,
  IOphalenWebbetalingenResult,
} from '../../../../../../shared/src/api/v2/website/betaling';
import api from '../../../index';

const betaling = {
  ophalenBetalingen: async (
    params: IOphalenWebbetalingenParams,
  ): Promise<IOphalenWebbetalingenResult> => {
    return api.post('/v2/extern/portaal/betaling/ophalen-webbetalingen', params);
  },
};

export default betaling;
