import { useEffect, useRef } from 'react';

const useBijGewijzigdEffect = (fn: () => void, deps: any[]) => {
  const eersteRender = useRef(true);
  useEffect(() => {
    if (eersteRender.current) {
      eersteRender.current = false;
      return;
    }
    fn();
  }, deps);
};

export default useBijGewijzigdEffect;
