import React, { useCallback, useMemo } from 'react';
import { DataTypeProvider, TableRowDetail } from '@devexpress/dx-react-grid';
import TabelInspringBlok from '../../../../components/layout/TabelInspringBlok';
import {
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import {
  Grid,
  Table,
  TableColumnResizing,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-bootstrap4';
import { VirtualTable as VirtualTableBase } from '@devexpress/dx-react-grid';
import { format } from 'date-fns';
import { IRegel } from '..';
import { IOphalenContractenResultElementV2 } from '../../../../../../shared/src/api/v2/contract';
import ContractVisualisatie from '../../../../components/entiteitVisualisaties/ContractVisualisatie';

const DetailComp = (props: TableRowDetail.ContentProps) => {
  const row: IRegel = props.row;
  const contracten: IOphalenContractenResultElementV2[] = row.contracten;

  const kolommen = useMemo<TypedColumn<IOphalenContractenResultElementV2>[]>(
    () => [
      {
        name: '__contractnummer' as any,
        title: 'Contractnr',
      },
      {
        name: 'Ingangsdatum',
        title: 'Ing.datum',
      },
      {
        name: '__modelnaam',
        title: 'Model',
      },
    ],
    [],
  );
  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IOphalenContractenResultElementV2>[]>(
    () => [
      {
        columnName: '__contractnummer' as any,
        width: 100,
      },
      {
        columnName: 'Ingangsdatum',
        width: 110,
      },
      {
        columnName: '__modelnaam',
        width: 300,
      },
    ],
    [],
  );

  const keyExtractor = useCallback((x: IOphalenContractenResultElementV2) => x.CntID, []);

  return (
    <div className="d-flex">
      <TabelInspringBlok />
      <GridStyleWrapper maxHeight={500} rowAmount={contracten.length}>
        <Grid rows={contracten} columns={kolommen} getRowId={keyExtractor}>
          <DataTypeProvider
            for={['__contractnummer']}
            formatterComponent={(formatterProps) => {
              const rij: IOphalenContractenResultElementV2 = formatterProps.row;
              return <ContractVisualisatie cntID={rij.CntID} />;
            }}
          />

          <DataTypeProvider
            for={['Ingangsdatum']}
            formatterComponent={(props) => {
              const rij: IOphalenContractenResultElementV2 = props.row;
              return <span>{format(new Date(rij.Ingangsdatum!), 'dd-MM-yyyy')}</span>;
            }}
          />

          <DataTypeProvider
            for={['__modelnaam']}
            formatterComponent={(props) => {
              const rij: IOphalenContractenResultElementV2 = props.row;
              return <span>{rij.basis.productmodel.Modelnaam}</span>;
            }}
          />

          <DataTypeProvider
            for={['AangebodenOvernameOp']}
            formatterComponent={(props) => {
              const rij: IOphalenContractenResultElementV2 = props.row;
              return <span>{format(new Date(rij.AangebodenOvernameOp!), 'dd-MM-yyyy')}</span>;
            }}
          />

          <Table />
          <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
          {/* <TableHeaderRow showSortingControls /> */}
          <TableHeaderRow />
        </Grid>
      </GridStyleWrapper>
    </div>
  );
};

export default DetailComp;
