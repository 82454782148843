import api from '../../../index';
import {
  IKoppelenLocatiesVoorRelatiesParams,
  IKoppelenLocatiesVoorRelatiesResult,
  IOntkoppelenLocatiesVoorRelatiesParams,
  IOntkoppelenLocatiesVoorRelatiesResult,
  IOphalenLocatiesParams,
  IOphalenLocatiesResult,
} from '../../../../../../shared/src/api/v2/relatie/locatie';

const locatie = {
  koppelenLocatiesVoorRelaties: async (
    params: IKoppelenLocatiesVoorRelatiesParams,
  ): Promise<IKoppelenLocatiesVoorRelatiesResult> => {
    return await api.post('v2/relatie/locatie/koppelen-locaties-voor-relaties', params);
  },
  ontkoppelenLocatiesVoorRelaties: async (
    params: IOntkoppelenLocatiesVoorRelatiesParams,
  ): Promise<IOntkoppelenLocatiesVoorRelatiesResult> => {
    return await api.post('v2/relatie/locatie/ontkoppelen-locaties-voor-relaties', params);
  },
  ophalenLocaties: async (params: IOphalenLocatiesParams): Promise<IOphalenLocatiesResult> => {
    return await api.post('v2/relatie/locatie/ophalen-locaties', params);
  },
};

export default locatie;
