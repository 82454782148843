import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import IDialoogProps from '../../../../../core/IDialoogProps';
import Dialoog from '../../../../../components/dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import LoadingSpinner from '../../../../../components/Gedeeld/LoadingSpinner';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import * as Yup from 'yup';
import api from '../../../../../api';
import nameOf from '../../../../../core/nameOf';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../../../stores/RootStore';
import NumeriekVeld from '../../../../../components/formulier/NumeriekVeld';
import VinkVeld from '../../../../../components/formulier/VinkVeld';
import { IOphalenPremiumsResultElement } from '../../../../../../../shared/src/api/v2/transport/opdracht/premium';
import { IOphalenProducttypenResultElement } from '../../../../../../../shared/src/api/v2/product/type';
import { EProductsoort } from '../../../../../bedrijfslogica/enums';
import Combobox from '../../../../../components/formulier/Combobox';

interface IProps extends IDialoogProps<null> {}

interface IFormikValues {
  naam: string;
  naamKort: string;
  naamEnum: string | null;
  actief: boolean;
  typeID: number | null;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  naam: 'Naam',
  naamKort: 'Naam kort',
  naamEnum: 'Naam enum',
  actief: 'Actief',
  typeID: 'Producttype',
};

const ToevoegenDialoog: React.FC<IProps> = observer((props) => {
  const { dialoogIndex, onAnnuleren, onSuccess } = props;
  const { checkStore } = useContext(RootStoreContext);
  const formikRef = useRef<Formik<IFormikValues>>(null);

  const [producttypen, setProducttypen] = useState<IOphalenProducttypenResultElement[] | null>(
    null,
  );

  const ophalenProducttypen = useCallback(async () => {
    const result = await api.v2.product.type.ophalenProducttypen({
      filterSchema: { filters: [{ naam: 'PRODUCTSOORT_ENUMS', data: [EProductsoort.Premium] }] },
    });

    setProducttypen(result.producttypen);
  }, []);

  useEffect(() => {
    ophalenProducttypen();
  }, [ophalenProducttypen]);

  const initialValues = useMemo<IFormikValues | null>(() => {
    return {
      naam: '',
      naamKort: '',
      actief: true,
      naamEnum: '',
      typeID: null,
    };
  }, []);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      const params = {
        naam: values.naam,
        naamKort: values.naamKort,
        actief: values.actief,
        naamEnum: values.naamEnum !== '' ? values.naamEnum : null,
        criterium: 1,
        typeID: values.typeID,
      };

      await api.v2.transport.opdracht.premium.toevoegenPremium(params);

      actions.setSubmitting(false);
      onSuccess(null);
    },
    [],
  );

  const validationSchema = useMemo(() => {
    const fields: Record<keyof IFormikValues, any> = {
      naam: Yup.string().required(),
      naamKort: Yup.string().required(),
      actief: Yup.boolean(),
      naamEnum: Yup.string(),
      typeID: Yup.number(),
    };
    return Yup.object().shape(fields);
  }, []);

  return (
    <Dialoog index={dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Toevoegen premium</ModalTitle>
      </ModalHeader>
      {initialValues === null ? (
        <LoadingSpinner />
      ) : (
        <Formik<IFormikValues>
          ref={formikRef}
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
          render={(formikProps: FormikProps<IFormikValues>) => {
            const { submitForm, isSubmitting, isValid } = formikProps;
            return (
              <>
                <ModalBody>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-12">
                        <label>{veldnamen.naam}</label>
                        <Field
                          name={nameOf<IFormikValues>('naam')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            return (
                              <input type="text" className="form-control" {...fieldProps.field} />
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <label>{veldnamen.naamKort}</label>
                        <Field
                          name={nameOf<IFormikValues>('naamKort')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            return (
                              <input type="text" className="form-control" {...fieldProps.field} />
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <label>{veldnamen.typeID}</label>
                        <Field
                          name={nameOf<IFormikValues>('typeID')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            if (producttypen === null) {
                              return;
                            }
                            return (
                              <>
                                <Combobox
                                  geselecteerd={field.value}
                                  opties={producttypen.map((x) => {
                                    return { id: x.TypeID, label: x.Typenaam };
                                  })}
                                  onSelectieChange={(x) => form.setFieldValue(field.name, x)}
                                />
                              </>
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <span className="d-flex align-items-center">
                          <Field
                            name="actief"
                            render={(x: FieldProps<IFormikValues>) => (
                              <VinkVeld
                                aangevinkt={x.field.value}
                                onGewijzigd={(aangevinkt: boolean) =>
                                  x.form.setFieldValue(x.field.name, aangevinkt)
                                }
                              />
                            )}
                          />
                          <span className="ml-2">{veldnamen.actief}</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </ModalBody>

                <ModalFooter className="d-flex flex-row justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    style={{ width: 100 }}
                    disabled={isSubmitting || !isValid}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={onAnnuleren}
                    style={{ width: 100 }}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Dialoog>
  );
});

export default ToevoegenDialoog;
