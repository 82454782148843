import { IOphalenBoekingenResultElement } from '../../../../../../../shared/src/api/v2/boekhouding/boeking';

export interface IIsDefinitiefProvideParams {
  boeking: IOphalenBoekingenResultElement | null;
}

export interface IIsDefinitiefProvider {
  provide: (params: IIsDefinitiefProvideParams) => Promise<boolean>;
}

export const standaardIsDefinitiefProvider: IIsDefinitiefProvider = {
  provide: async ({ boeking }) => {
    return boeking?.Definitief ?? false;
  },
};
