import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import IDialoogProps from '../../../../core/IDialoogProps';
import Dialoog from '../../../../components/dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import * as Yup from 'yup';
import { IOphalenTakenResultElement } from '../../../../../../shared/src/api/v2/taken';
import { IOphalenProductsoortenResultElement } from '../../../../../../shared/src/api/v2/product/soort';
import { IOphalenProductmerkenResultElement } from '../../../../../../shared/src/api/v2/product/merk';
import { IOphalenDienstenResultElement } from '../../../../../../shared/src/api/v2/dienst/inkoop';
import api from '../../../../api';
import nameof from '../../../../core/nameOf';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../../stores/RootStore';
import { IconOpenInNew } from '../../../../components/Icons';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import Combobox, { IOptie } from '../../../../components/formulier/Combobox';
import { EResultType } from '../../../../stores/CheckStore';

interface IProps extends IDialoogProps<null> {
  prodSrtID?: number | null;
}

interface IFormikValues {
  prodSrtID: number | null;
  kenmerk: string;
  typenaam: string;
  merkID: number | null;
  inkDienstID: number | null;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  prodSrtID: 'Productsoort',
  typenaam: 'Type',
  kenmerk: 'Kenmerk',
  merkID: 'Merk',
  inkDienstID: 'Inkoopdienst',
};

const NieuwDialoog: React.FC<IProps> = observer((props) => {
  const { children, dialoogIndex, onAnnuleren, onSuccess, open } = props;
  const { instellingStore, checkStore } = useContext(RootStoreContext);

  const [merken, setMerken] = useState<IOphalenProductmerkenResultElement[] | null>(null);
  const [diensten, setDiensten] = useState<IOphalenDienstenResultElement[] | null>(null);
  const [productsoorten, setProductsoorten] = useState<
    IOphalenProductsoortenResultElement[] | null
  >(null);

  useEffect(() => {
    (async () => {
      const results = await api.v2.product.soort.ophalenProductsoorten({
        filterSchema: { filters: [] },
      });
      setProductsoorten(results);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const productmerkenResults = await api.v2.product.merk.ophalenProductmerken({
        filterSchema: { filters: [{ naam: 'IS_ACTIEF', data: true }] },
      });
      setMerken(productmerkenResults.productmerken);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const { diensten } = await api.v2.dienst.inkoop.ophalenDiensten({
        filterSchema: { filters: [{ naam: 'IS_ACTIEF', data: true }] },
      });
      setDiensten(diensten);
    })();
  }, []);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      const params = {
        prodSrtID: values.prodSrtID!,
        typenaam: values.typenaam,
        kenmerk: values.kenmerk,
        merkID: values.merkID!,
        dienst:
          values.inkDienstID !== null
            ? {
                ID: values.inkDienstID,
                inkoopprijs: null,
              }
            : null,
      };
      const checkData = await api.v2.inkoop.aanbod.checkNieuwProduct(params);
      const controleResult = await checkStore.controleren({
        checkData,
      });
      if (controleResult.type === EResultType.Annuleren) {
        actions.setSubmitting(false);
        return;
      }

      await api.v2.inkoop.aanbod.nieuwProduct(params);

      actions.setSubmitting(false);
      onSuccess(null);
    },
    [],
  );

  const initialValues = useMemo<IFormikValues | null>(() => {
    return {
      prodSrtID: props.prodSrtID!,
      typenaam: '',
      kenmerk: '',
      merkID: null,
      inkDienstID: null,
    };
  }, []);

  const validationSchema = useMemo(() => {
    const fields: Record<keyof IFormikValues, any> = {
      prodSrtID: Yup.number(),

      typenaam: Yup.string().required(),
      kenmerk: Yup.string().required(),
      merkID: Yup.number(),

      inkDienstID: Yup.number().nullable(),
    };
    return Yup.object().shape(fields);
  }, []);

  return (
    <Dialoog index={dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Nieuw product</ModalTitle>
      </ModalHeader>
      {initialValues === null ? (
        <LoadingSpinner />
      ) : (
        <Formik<IFormikValues>
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
          render={(formikProps: FormikProps<IFormikValues>) => {
            const { submitForm, isSubmitting, isValid } = formikProps;
            return (
              <>
                <ModalBody>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-12 mt-2">
                        <label>{veldnamen.prodSrtID}</label>

                        <Field
                          name={nameof<IFormikValues>('prodSrtID')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            if (productsoorten === null) {
                              return <LoadingSpinner />;
                            }

                            return (
                              <Combobox
                                legeOptieTonen
                                geselecteerd={field.value}
                                onSelectieChange={(x) => form.setFieldValue(field.name, x)}
                                opties={productsoorten!.map((x) => {
                                  return {
                                    id: x.ProdSrtID,
                                    label: x.Naam,
                                  };
                                })}
                              />
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-2">
                        <label>{veldnamen.merkID}</label>

                        <Field
                          name={nameof<IFormikValues>('merkID')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            if (merken === null) {
                              return <LoadingSpinner />;
                            }

                            return (
                              <Combobox
                                legeOptieTonen
                                geselecteerd={field.value}
                                onSelectieChange={(x) => form.setFieldValue(field.name, x)}
                                opties={merken!.map((merk) => {
                                  return {
                                    id: merk.MerkID,
                                    label: merk.Merknaam,
                                  };
                                })}
                              />
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-2">
                        <label>{veldnamen.typenaam}</label>
                        <Field
                          name={nameof<IFormikValues>('typenaam')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return <input className="form-control" type="text" {...field} />;
                          }}
                        />
                      </div>

                      <div className="col-12 mt-2">
                        <label>{veldnamen.kenmerk}</label>
                        <Field
                          name={nameof<IFormikValues>('kenmerk')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return <input className="form-control" type="text" {...field} />;
                          }}
                        />
                      </div>

                      <div className="col-12 mt-2">
                        <label>{veldnamen.inkDienstID}</label>

                        <Field
                          name={nameof<IFormikValues>('inkDienstID')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            if (diensten === null) {
                              return <LoadingSpinner />;
                            }

                            return (
                              <Combobox
                                legeOptieTonen
                                geselecteerd={field.value}
                                onSelectieChange={(x) => form.setFieldValue(field.name, x)}
                                opties={diensten!.map((leverancier) => {
                                  return {
                                    id: leverancier.ID,
                                    label: leverancier.relatie!.weergavenaam!,
                                  };
                                })}
                              />
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </ModalBody>

                <ModalFooter className="d-flex flex-row justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    style={{ width: 100 }}
                    disabled={isSubmitting || !isValid}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={onAnnuleren}
                    style={{ width: 100 }}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Dialoog>
  );
});

export default NieuwDialoog;
