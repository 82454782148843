import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  IOphalenTaakEventsResult,
  IOphalenTaakEventsResultTaakEventElement,
  IOphalenTakenResult,
} from '../../../../../../shared/src/api/v2/taken';
import api from '../../../../api';
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List,
  InfiniteLoader,
} from 'react-virtualized';
import EventRegel from '../EventsWeergave/EventRegel';
import HorizontaleScheidingslijn from '../../../../components/layout/HorizontaleScheidingslijn';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';

interface IProps {
  takenResult: IOphalenTakenResult | null;
}

const AlleEventsWeergave: React.FC<IProps> = (props) => {
  const [events, setEvents] = useState<IOphalenTaakEventsResultTaakEventElement[]>([]);
  const [eventsResult, setEventsResult] = useState<IOphalenTaakEventsResult | null>(null);

  const ophalenEvents = useCallback(async () => {
    const result = await api.v2.taken.ophalenTaakEvents({
      filterSchema: {
        filters: [],
      },
      orderSchema: {
        orders: [
          {
            naam: 'RECORD_TOEGEVOEGD',
            richting: 'DESC',
          },
        ],
      },
      paginatie: {
        index: 0,
        aantal: 50,
      },
    });
    setEventsResult(result);
  }, []);
  useEffect(() => {
    ophalenEvents();
  }, [ophalenEvents]);

  const laadMeerEvents = useCallback(async (index, aantal) => {
    const result = await api.v2.taken.ophalenTaakEvents({
      filterSchema: {
        filters: [],
      },
      orderSchema: {
        orders: [
          {
            naam: 'RECORD_TOEGEVOEGD',
            richting: 'DESC',
          },
        ],
      },
      paginatie: {
        index,
        aantal,
      },
    });
    setEventsResult((curr) => ({
      taakEvents: [...curr!.taakEvents, ...result.taakEvents],
    }));
  }, []);

  useEffect(() => {
    if (eventsResult === null) {
      return;
    }
    setEvents(eventsResult.taakEvents);
  }, [eventsResult]);

  const cache = useMemo(
    () =>
      new CellMeasurerCache({
        defaultHeight: 32,
        fixedWidth: true,
      }),
    [],
  );

  const rowRenderer = useCallback(
    (rowProps) => {
      const event = events[rowProps.index]!;
      const taak = props.takenResult!.taken.find((x) => x.ID === event.TaakID)!;
      return (
        <CellMeasurer
          cache={cache}
          columnIndex={0}
          key={rowProps.key}
          parent={rowProps.parent}
          rowIndex={rowProps.index}
        >
          {({ measure }) => (
            <div style={rowProps.style}>
              <EventRegel
                event={event}
                height={cache.rowHeight(rowProps.index)}
                metTaakNaam={taak.Naam}
              />
              {rowProps.index !== events.length - 1 && <HorizontaleScheidingslijn />}
            </div>
          )}
        </CellMeasurer>
      );
    },
    [events, cache, props.takenResult],
  );

  if (props.takenResult === null || eventsResult === null) {
    return (
      <div className="d-flex flex-fill align-items-center justify-content-center">
        <LoadingSpinner />
      </div>
    );
  }

  if (events.length === 0) {
    return (
      <div className="d-flex flex-fill align-items-center justify-content-center">
        <span>Er zijn nog geen events geregistreerd voor deze taak.</span>
      </div>
    );
  }

  return (
    <div className="d-flex flex-fill flex-column">
      <InfiniteLoader
        isRowLoaded={({ index }) => !!events[index]}
        loadMoreRows={async ({ startIndex, stopIndex }) =>
          await laadMeerEvents(startIndex, stopIndex - startIndex)
        }
        rowCount={999999}
      >
        {({ onRowsRendered, registerChild }) => (
          <AutoSizer>
            {({ width, height }) => (
              <List
                ref={registerChild}
                onRowsRendered={onRowsRendered}
                deferredMeasurementCache={cache}
                overscanRowCount={10}
                rowCount={events.length}
                rowHeight={cache.rowHeight}
                width={width}
                height={height}
                rowRenderer={rowRenderer}
              />
            )}
          </AutoSizer>
        )}
      </InfiniteLoader>
    </div>
  );
};

export default AlleEventsWeergave;
