import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { Kleur } from '../../../bedrijfslogica/constanten';
import { IconToevoegen } from '../../../components/Icons';
import { Calendar, momentLocalizer, Event, View, EventPropGetter } from 'react-big-calendar';
import moment from 'moment';
import api from '../../../api';
import {
  IOphalenTakenResult,
  IOphalenTakenResultElement,
} from '../../../../../shared/src/api/v2/taken';
import LoadingSpinner from '../../../components/Gedeeld/LoadingSpinner';
import * as _ from 'lodash';
import cronParser from 'cron-parser';
import useUrlState from '../../../core/useUrlState';
import { date } from 'yup';
import EventComp from './EventComp';

interface IProps extends RouteComponentProps {}

interface IUrlState {
  view: View;
  fromDate: Date;
}

const defaultUrlState: IUrlState = {
  view: 'week',
  fromDate: new Date(),
};

export interface IVisualisatieEvent extends Pick<Event, 'start' & 'end' & 'allDay'> {
  taak: IOphalenTakenResultElement;
}

const Visualisatie: React.FC<IProps> = (props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const fromDate = urlState.fromDate;
  const dateRange = useMemo(() => {
    if (urlState.view === 'day') {
      const vandaagZonderTijd = new Date(fromDate);
      vandaagZonderTijd.setMinutes(0);
      vandaagZonderTijd.setHours(0);
      vandaagZonderTijd.setSeconds(0);

      const begin = vandaagZonderTijd;
      const eind = new Date(vandaagZonderTijd);
      eind.setHours(24);
      return {
        begin,
        eind,
      };
    } else if (urlState.view === 'week') {
      const begin = moment(fromDate)
        .set('hour', 0)
        .set('minute', 0)
        .set('second', 0)
        .set('weekday', 0)
        .add(-1, 'week');
      const eind = moment(fromDate)
        .set('hour', 24)
        .set('minute', 60)
        .set('second', 59)
        .set('weekday', 7);
      return {
        begin: begin.toDate(),
        eind: eind.toDate(),
      };
    }

    return {
      begin: fromDate,
      eind: fromDate,
    };
  }, [fromDate, urlState.view]);

  const [taken, setTaken] = useState<IOphalenTakenResult | null>(null);

  const ophalenTaken = useCallback(async () => {
    const result = await api.v2.taken.ophalenTaken({
      filterSchema: {
        filters: [
          {
            naam: 'VISUALISEREN',
            data: true,
          },
        ],
      },
    });
    setTaken(result);
  }, []);

  useEffect(() => {
    ophalenTaken();

    const intervalId = setInterval(() => ophalenTaken(), 30000);
    return () => clearInterval(intervalId);
  }, [ophalenTaken]);

  const localizer = useMemo(() => momentLocalizer(moment), []);

  const events = useMemo<IVisualisatieEvent[] | null>(() => {
    if (taken === null) {
      return null;
    }
    return _.flatten(
      taken.taken.map((taak): IVisualisatieEvent[] => {
        const interval = cronParser.parseExpression(taak.CrontabSyntax, {
          iterator: true,
          currentDate: dateRange.begin,
          endDate: dateRange.eind,
        });
        const dates: Date[] = [];
        while (interval.hasNext()) {
          const x = (interval.next() as any).value._date._d;
          dates.push(x);
        }
        return dates.map((date) => {
          return {
            start: date,
            end: date,
            taak,
          };
        });
      }),
    );
  }, [taken, dateRange]);

  const eventPropGetter = useCallback<EventPropGetter<IVisualisatieEvent>>(
    (event, start, end, isSelected) => {
      return {
        style: {
          backgroundColor: 'white',
          borderRadius: 0,
          color: Kleur.Zwart,
          border: `1px solid ${Kleur.LichtGrijs}`,
          padding: '2px 5px',
        },
      };
    },
    [],
  );

  return (
    <div style={{ backgroundColor: 'white' }}>
      <div
        className="d-flex flex-column p-3"
        style={{
          backgroundColor: Kleur.HeelLichtGrijs,
          borderBottom: `1px solid ${Kleur.LichtGrijs}`,
        }}
      >
        <div className="d-flex">
          <button
            className="btn btn-sm btn-light d-flex align-items-center"
            style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
            disabled
          >
            <IconToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
            <span className="ml-2">Actie</span>
          </button>
        </div>
      </div>

      <div className="m-3">
        {events === null ? (
          <LoadingSpinner />
        ) : (
          <Calendar
            localizer={localizer}
            events={events}
            step={40}
            showMultiDayTimes
            views={['week', 'day']}
            view={urlState.view}
            onView={(x) => setUrlStateSync('view', x)}
            onRangeChange={(range) => {
              // In onze situatie (week of dag) is range een array, dit kunnen we gebruiken
              // om de events de genereren
              const rangeArray = range as Date[];
              setUrlStateSync('fromDate', rangeArray[0]);
            }}
            // startAccessor="start"
            // endAccessor="end"
            components={{
              event: EventComp,
            }}
            eventPropGetter={eventPropGetter}
          />
        )}
      </div>
    </div>
  );
};

export default Visualisatie;
