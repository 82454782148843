import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import IDialoogProps from '../../../../core/IDialoogProps';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { Field, FieldProps, Formik, FormikActions, FormikErrors, FormikProps } from 'formik';
import Combobox, { IOptie } from '../../../../components/formulier/Combobox';
import { RootStoreContext } from '../../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import api from '../../../../api';
import { EResultType } from '../../../../stores/CheckStore';
import * as Yup from 'yup';
import FormikVeldFout from '../../../../components/formulier/FormikVeldFout';
import { IOphalenProductstatussenResultElement } from '../../../../../../shared/src/api/v2/product';
import Dialoog from '../../../../components/dialogen/Dialoog';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import nameOf from '../../../../core/nameOf';
import NumeriekVeld from '../../../../components/formulier/NumeriekVeld';
import teksten from '../../../../bedrijfslogica/teksten';

interface IProps extends IDialoogProps<{}> {
  magID: number;
  prodIDs?: number[];
  producttype?: { typeID: number; aantal: number; prodStatID: number };
  initieelProdStatID: number | null;
  naamEnums: string[] | null;
}

interface IFormikValues {
  productstatusID: number | null;
  aantal: number;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  productstatusID: 'Productstatus',
  aantal: 'Aantal',
};

const WijzigenProductstatusDialoog = observer((props: IProps) => {
  const { onAnnuleren, onSuccess, open, prodIDs, initieelProdStatID } = props;
  const { checkStore } = useContext(RootStoreContext);
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      setHasSubmitted(true);
      actions.setSubmitting(true);

      if (
        (
          await checkStore.bevestigen({
            inhoud: `Status wijzigen?`,
          })
        ).type === EResultType.Annuleren
      ) {
        actions.setSubmitting(false);
        return;
      }

      if (props.producttype !== undefined) {
        // Nieuwe producten
        const type = props.producttype;
        await api.v2.retourverwerking.wijzigenProductstatus({
          magID: props.magID,
          producttype: { typeID: type.typeID, aantal: values.aantal, prodStatID: type.prodStatID },
          prodStatID: values.productstatusID!,
        });
      } else {
        // Gebruikte producten
        await api.v2.retourverwerking.wijzigenProductstatus({
          magID: props.magID,
          prodIDs: props.prodIDs,
          prodStatID: values.productstatusID!!,
        });
      }

      onSuccess({});
      actions.setSubmitting(false);
    },
    [onSuccess, setHasSubmitted, props.prodIDs],
  );

  const [statussen, setStatussen] = useState<IOphalenProductstatussenResultElement[] | null>(null);

  useEffect(() => {
    (async () => {
      const result = (
        await api.v2.product.ophalenProductstatussen({
          filterSchema: { filters: [{ naam: 'ENUMS', data: props.naamEnums }] },
        })
      ).statussen;
      setStatussen(result);
    })();
  }, [props.naamEnums]);

  const initieleWaarden = useMemo<IFormikValues>(() => {
    return {
      productstatusID: initieelProdStatID,
      aantal: props.producttype?.aantal ?? 1,
    };
  }, [initieelProdStatID]);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        productstatusID: Yup.number(),
      }),
    [],
  );

  return (
    <Dialoog index={props.dialoogIndex || 0}>
      {statussen === null ? (
        <div className="flex-fill d-flex align-items-center justify-content-center m-5">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          <ModalHeader>
            <ModalTitle>Wijzigen status</ModalTitle>
          </ModalHeader>
          <Formik<IFormikValues>
            onSubmit={handleSubmit}
            initialValues={initieleWaarden}
            isInitialValid
            validationSchema={validationSchema}
            render={(formikProps: FormikProps<IFormikValues>) => {
              const { submitForm, isSubmitting, values, isValid } = formikProps;
              return (
                <>
                  <ModalBody>
                    <div className="form-group">
                      <div className="row">
                        <div className="col-12">
                          <label>{veldnamen.productstatusID}</label>
                          <Field
                            name="productstatusID"
                            render={(fieldProps: FieldProps<IFormikValues>) => (
                              <>
                                <Combobox
                                  geselecteerd={fieldProps.field.value}
                                  opties={statussen.map((status) => {
                                    return {
                                      id: status.ProdStatID,
                                      label: status.Naam,
                                    };
                                  })}
                                  onSelectieChange={(id) => {
                                    fieldProps.form.setFieldValue(fieldProps.field.name, id);
                                  }}
                                  options={{
                                    legeOptieTekst: 'Selecteer een status',
                                  }}
                                />
                                <FormikVeldFout
                                  fieldProps={fieldProps}
                                  directTonen={hasSubmitted}
                                />
                              </>
                            )}
                          />
                        </div>

                        <div className="col-3 mt-2">
                          <label>{veldnamen.aantal}</label>
                          <Field
                            name={nameOf<IFormikValues>('aantal')}
                            render={(fieldProps: FieldProps<IFormikValues>) => {
                              const { field, form } = fieldProps;
                              return (
                                <NumeriekVeld
                                  waarde={field.value}
                                  onChange={(x) => form.setFieldValue(field.name, x)}
                                  min={1}
                                  max={
                                    props.producttype !== undefined ? props.producttype.aantal : 1
                                  }
                                />
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </ModalBody>
                  <ModalFooter className="d-flex justify-content-start">
                    <button
                      className="btn btn-primary"
                      onClick={submitForm}
                      disabled={isSubmitting || !isValid}
                      // disabled={isSubmitting}
                      style={{ width: 100 }}
                    >
                      Ok
                    </button>
                    <button
                      className="btn btn-secondary"
                      onClick={onAnnuleren}
                      style={{ width: 100 }}
                    >
                      Annuleren
                    </button>
                  </ModalFooter>
                </>
              );
            }}
          />
        </>
      )}
    </Dialoog>
  );
});

export default WijzigenProductstatusDialoog;
