import _ from 'lodash';
import React, { useCallback, useContext, useState, useMemo, useEffect } from 'react';
import { ModalFooter, ModalTitle } from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/esm/ModalHeader';
import { number } from 'yup';
import {
  IOphalenBoekingenResultElement,
  IOphalenBoekingRegelsResultElement,
} from '../../../../../../shared/src/api/v2/boekhouding/boeking';
import {
  IOphalenFacturenBasisResult,
  IOphalenFacturenBasisResultElement,
} from '../../../../../../shared/src/api/v2/factuur';
import { IOphalenFacturenResultElement } from '../../../../../../shared/src/api/v2/inkoopfactuur';
import api from '../../../../api';
import IDialoogProps from '../../../../core/IDialoogProps';
import Dialoog from '../../../dialogen/Dialoog';
import LoadingSpinner from '../../../Gedeeld/LoadingSpinner';
import Tabblad, { ITabblad } from '../../../layout/Tabblad';
import BoekingregelsTab from './BoekingregelTab';
import BoekingTab from './BoekingTab';

interface IProps extends IDialoogProps<{}> {
  id: number;
}

enum ETabblad {
  Boeking,
  Regel,
}

export interface IContext {
  boeking: IOphalenBoekingenResultElement | null;
  boekingregels: IOphalenBoekingRegelsResultElement[] | null;
  facturen: IOphalenFacturenBasisResultElement[] | null;
  inkoopfacturen: IOphalenFacturenResultElement[] | null;
  boekingregelID: number | null;
}
export const BoekingContext = React.createContext<IContext>({
  boeking: null,
  boekingregels: null,
  facturen: null,
  inkoopfacturen: null,
  boekingregelID: null,
});

const BoekingregelInfoDialoog: React.FC<IProps> = (props) => {
  const { dialoogIndex, onAnnuleren, onSuccess, open } = props;
  const [tabblad, setTabblad] = useState<ETabblad>(ETabblad.Regel);

  const [boeking, setBoeking] = useState<IOphalenBoekingenResultElement | null>(null);
  const [boekingregels, setBoekingregels] = useState<IOphalenBoekingRegelsResultElement[] | null>(
    null,
  );
  const [facturen, setFacturen] = useState<IOphalenFacturenBasisResultElement[] | null>(null);
  const [inkoopfacturen, setInkoopfacturen] = useState<IOphalenFacturenResultElement[] | null>(
    null,
  );

  const ophalenBoeking = useCallback(async () => {
    const regelsResult = await api.v2.boeking.ophalenBoekingen({
      filterSchema: {
        filters: [
          {
            naam: 'BOEKINGREG_IDS',
            data: [props.id],
          },
        ],
      },
    });

    setBoeking(regelsResult.boekingen[0]);
  }, [props.id]);

  useEffect(() => {
    ophalenBoeking();
  }, [ophalenBoeking]);

  const ophalenBoekingregels = useCallback(async () => {
    if (boeking === null) {
      return;
    }

    const regelsResult = await api.v2.boeking.ophalenBoekingregels({
      filterSchema: {
        filters: [
          {
            naam: 'BOEKING_IDS',
            data: [boeking.ID],
          },
        ],
      },
      orderSchema: {
        orders: [
          { naam: 'BOEKDATUM', richting: 'DESC' },
          { naam: 'BOEKNUMMER', richting: 'DESC' },
          { naam: 'REGELNUMMER', richting: 'DESC' },
        ],
      },
    });

    setBoekingregels(regelsResult.regels);
  }, [boeking]);

  useEffect(() => {
    ophalenBoekingregels();
  }, [ophalenBoekingregels]);

  const ophalenFacturen = useCallback(async () => {
    if (boekingregels === null) {
      return;
    }

    const factIDs = _.uniq(boekingregels.filter((x) => x.FactID !== null).map((x) => x.FactID));
    if (factIDs.length === 0) {
      setFacturen([]);
      return;
    }

    const regelsResult = await api.v2.factuur.ophalenFacturenBasis({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: [_.uniq(boekingregels.filter((x) => x.FactID !== null).map((x) => x.FactID))],
          },
        ],
      },
    });

    setFacturen(regelsResult.facturen);
  }, [boekingregels]);

  useEffect(() => {
    ophalenFacturen();
  }, [ophalenFacturen]);

  const ophalenInkoopfacturen = useCallback(async () => {
    if (boekingregels === null) {
      return;
    }
    const inkFactIDs = _.uniq(
      boekingregels.filter((x) => x.InkFactID !== null).map((x) => x.InkFactID),
    );
    if (inkFactIDs.length === 0) {
      setInkoopfacturen([]);
      return;
    }
    const regelsResult = await api.v2.inkoopfactuur.ophalenFacturen({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: [inkFactIDs],
          },
        ],
      },
    });

    setInkoopfacturen(regelsResult.facturen);
  }, [boekingregels]);

  useEffect(() => {
    ophalenInkoopfacturen();
  }, [ophalenInkoopfacturen]);

  const tabbladen = useMemo<ITabblad<ETabblad>[]>(
    () => [
      {
        id: ETabblad.Regel,
        label: 'Regel',
        content: BoekingregelsTab,
      },
      {
        id: ETabblad.Boeking,
        label: 'Boeking',
        content: BoekingTab,
      },
    ],
    [],
  );

  if (boeking === null || boekingregels === null) {
    return <LoadingSpinner />;
  }

  return (
    <Dialoog index={props.dialoogIndex ?? 0} modalProps={{ size: 'lg' }}>
      <ModalHeader>
        <ModalTitle>Boekingregelinformatie</ModalTitle>
      </ModalHeader>
      <BoekingContext.Provider
        value={{
          boeking,
          boekingregels,
          facturen,
          inkoopfacturen,
          boekingregelID: props.id,
        }}
      >
        <div className="d-flex flex-column flex-fill" style={{ minHeight: `550px` }}>
          <>
            <div className="d-flex flex-column flex-fill">
              <Tabblad
                tabbladen={tabbladen}
                geselecteerd={tabblad}
                onSelectieChange={(id) => {
                  setTabblad(id);
                }}
              />
            </div>
            <ModalFooter className="d-flex flex-row justify-content-start">
              <button className="btn btn-primary" onClick={onAnnuleren} style={{ width: 100 }}>
                Sluiten
              </button>
            </ModalFooter>
          </>
        </div>
      </BoekingContext.Provider>
    </Dialoog>
  );
};

export default BoekingregelInfoDialoog;
