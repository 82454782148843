import React, { useContext, useEffect, useMemo, useState, useCallback } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import useUrlState from '../../../../core/useUrlState';
import api from '../../../../api';
import { addMonths } from 'date-fns';
import _ from 'lodash';
import { IOphalenContractenResultElementV2 } from '../../../../../../shared/src/api/v2/contract';
import Tabel from '../ContractenTabel';

interface IProps extends RouteComponentProps {}

interface IUrlState {}

const defaultUrlState: IUrlState = {};

export interface IRegel extends IOphalenContractenResultElementV2 {}

const Nieuw: React.FC<IProps> = observer((props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);

  const [contracten, setContracten] = useState<IRegel[] | null>(null);
  const ophalenContracten = useCallback(async () => {
    const peildatum = addMonths(new Date(), -3);
    const contracten = (
      await api.v2.contract.ophalenContractenV2({
        filterSchema: {
          filters: [
            { naam: 'IS_BASIS', data: true },
            { naam: 'CONTRACTDATUM_VANAF', data: peildatum },
          ],
        },
        orderSchema: {
          orders: [
            {
              naam: 'INGANGSDATUM',
              richting: 'DESC',
            },
            {
              naam: 'BASISNUMMER',
              richting: 'DESC',
            },
          ],
        },
      })
    ).contracten;

    setContracten(contracten);
  }, []);

  useEffect(() => {
    ophalenContracten();
  }, [ophalenContracten]);

  return <>{contracten === null ? <LoadingSpinner /> : <Tabel contracten={contracten} />}</>;
});

export default withRouter(Nieuw);
