import {
  IOphalenTaakParams,
  IOphalenTakenParams,
  IOphalenTakenResult,
  IOphalenTakenResultElement,
  IDirectUitvoerenParams,
  IWijzigenTaakResult,
  IWijzigenTaakParams,
  IDirectUitvoerenResult,
  IOphalenTaakEventsParams,
  IOphalenTaakEventsResult,
  IAnnulerenLopendeTakenParams,
  IAnnulerenLopendeTakenResult,
} from '../../../../shared/src/api/v2/taken';
import api from '../index';
import ICheckData from '../../../../shared/src/models/ICheckData';
import { IAchtergrondProces } from '../../../../shared/src/api/v2/achtergrondProces';

const taken = {
  ophalenTaken: async (params: IOphalenTakenParams): Promise<IOphalenTakenResult> => {
    return await api.post('v2/taken/ophalen-taken', params);
  },
  ophalenTaak: async (params: IOphalenTaakParams): Promise<IOphalenTakenResultElement> => {
    return await api.post('v2/taken/ophalen-taak', params);
  },
  checkWijzigenTaak: async (params: IWijzigenTaakParams): Promise<ICheckData> => {
    return await api.post('v2/taken/check-wijzigen-taak', params);
  },
  wijzigenTaak: async (params: IWijzigenTaakParams): Promise<IWijzigenTaakResult> => {
    return await api.post('v2/taken/wijzigen-taak', params);
  },
  directUitvoeren: async (
    params: IDirectUitvoerenParams,
  ): Promise<IAchtergrondProces<IDirectUitvoerenResult>> => {
    return await api.post('v2/taken/direct-uitvoeren', params);
  },
  ophalenTaakEvents: async (
    params: IOphalenTaakEventsParams,
  ): Promise<IOphalenTaakEventsResult> => {
    return await api.post('v2/taken/ophalen-taak-events', params);
  },
  annuleerLopendeTaken: async (
    params: IAnnulerenLopendeTakenParams,
  ): Promise<IAnnulerenLopendeTakenResult> => {
    return await api.post('v2/taken/annuleren-lopende-taken', params);
  },
};

export default taken;
