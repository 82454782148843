import { observer } from 'mobx-react-lite';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IEntiteitProps } from '../../../../../../components/kaart/EntiteitContainer';
import Tabblad, { ITabblad } from '../../../../../../components/layout/Tabblad';
import { RootStoreContext } from '../../../../../../stores/RootStore';
import Kredietwaardigheid from './Kredietwaardigheid';
import Procedure from './Procedure';

interface IProps extends IEntiteitProps, RouteComponentProps {}

export enum ETabblad {
  Procedure = 1,
  Kredietwaardigheid = 2,
}

const pathMap: Record<ETabblad, string> = {
  [ETabblad.Procedure]: '/procedure',
  [ETabblad.Kredietwaardigheid]: '/kredietwaardigheid',
};

export interface IAcceptatieContext {
  relID: number;
  persID: number;
}

export const AcceptatieContext = React.createContext<IAcceptatieContext>(null as any);

const Acceptatie: React.FC<IProps> = observer((props) => {
  const tabblad = useMemo<ETabblad | null>(() => {
    const matchKey = Object.keys(pathMap).find((key) => {
      const path = pathMap[Number(key) as ETabblad]!;
      return props.location.pathname.endsWith(path);
    });
    if (matchKey === undefined) {
      props.history.replace(`${props.location.pathname}${pathMap[ETabblad.Procedure]}`);
      return null;
    }
    return Number(matchKey) as ETabblad;
  }, [props.location.pathname]);

  const tabbladen = useMemo<ITabblad<ETabblad>[]>(
    () => [
      {
        id: ETabblad.Procedure,
        label: 'Procedure',
        content: Procedure,
      },
      {
        id: ETabblad.Kredietwaardigheid,
        label: 'Kredietwaardigheid',
        content: Kredietwaardigheid,
      },
    ],
    [],
  );

  const { klantkaartStore } = useContext(RootStoreContext);

  const context = useMemo<IAcceptatieContext>(() => {
    const persID = klantkaartStore.relatie!.PersoonPrimair_PersID!;
    return {
      relID: props.relID,
      persID,
    };
  }, [props.relID, klantkaartStore]);

  return (
    <AcceptatieContext.Provider value={context}>
      <div className="d-flex flex-column flex-fill flex-fill">
        {tabblad !== null && (
          <Tabblad<ETabblad>
            geselecteerd={tabblad}
            onSelectieChange={(tab) => {
              props.history.push(
                `${props.location.pathname.replace(pathMap[tabblad], '')}${pathMap[tab]}`,
              );
            }}
            tabbladen={tabbladen}
            options={{
              tabbladComponentProps: {
                relID: props.relID,
              },
            }}
          />
        )}
      </div>
    </AcceptatieContext.Provider>
  );
});

export default withRouter(Acceptatie);
