import {
  IOphalenRollenParams,
  IOphalenRollenResult,
  IToevoegenRolParams,
  IToevoegenRolResult,
  IVerwijderenRollenParams,
  IVerwijderenRollenResult,
  IWijzigenRolParams,
  IWijzigenRolResult,
} from '../../../../../../shared/src/api/v2/autorisatie/rol/index';
import ICheckData from '../../../../../../shared/src/models/ICheckData';
import api from '../../../index';

const rol = {
  ophalenRollen: async (params: IOphalenRollenParams): Promise<IOphalenRollenResult> => {
    return await api.post('/v2/autorisatie/rol/ophalen-rollen', params);
  },
  checkToevoegenRol: async (params: IToevoegenRolParams): Promise<ICheckData> => {
    return await api.post('/v2/autorisatie/rol/check-toevoegen-rol', params);
  },
  toevoegenRol: async (params: IToevoegenRolParams): Promise<IToevoegenRolResult> => {
    return await api.post('/v2/autorisatie/rol/toevoegen-rol', params);
  },
  checkVerwijderenRollen: async (params: IVerwijderenRollenParams): Promise<ICheckData> => {
    return await api.post('/v2/autorisatie/rol/check-verwijderen-rollen', params);
  },
  verwijderenRollen: async (
    params: IVerwijderenRollenParams,
  ): Promise<IVerwijderenRollenResult> => {
    return await api.post('/v2/autorisatie/rol/verwijderen-rollen', params);
  },
  wijzigenRol: async (params: IWijzigenRolParams): Promise<IWijzigenRolResult> => {
    return await api.post('/v2/autorisatie/rol/wijzigen-rol', params);
  },
};

export default rol;
