import React, { useCallback, useEffect, useMemo, useState } from 'react';
import IDialoogProps from '../../../../../core/IDialoogProps';
import Dialoog from '../../../../dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import LoadingSpinner from '../../../../Gedeeld/LoadingSpinner';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../../models/IRemoteData';
import api from '../../../../../api';
import nameof from '../../../../../core/nameOf';
import TelefoonnummerInput from '../../../../formulier/TelefoonnummerInput';
import { IOphalenAfdelingenResultElement } from '../../../../../../../shared/src/api/v2/relatie/afdeling';

export interface IDialoogResult {
  afdeling: IOphalenAfdelingenResultElement;
}

interface IFormikValues {
  naam: string;
  telefoonnummmer: string | null;
  email: string;
}

interface IProps extends IDialoogProps<IDialoogResult> {
  orgID: number;
  orgAfdID?: number;
}

const AfdelingMuterenDialoog = (props: IProps) => {
  const [initialValues, setInitialValues] = useState<IRemoteData<IFormikValues>>(
    createPendingRemoteData(),
  );

  useEffect(() => {
    if (props.orgAfdID === undefined) {
      setInitialValues(
        createReadyRemoteData({
          naam: '',
          telefoonnummmer: null,
          email: '',
        }),
      );
      return;
    }

    api.v2.relatie.afdeling
      .ophalenAfdelingen({
        filterSchema: {
          filters: [
            {
              naam: 'IDS',
              data: [props.orgAfdID!],
            },
          ],
        },
      })
      .then((result) => {
        const afdeling = result.afdelingen[0];
        setInitialValues(
          createReadyRemoteData({
            naam: afdeling.Naam,
            telefoonnummmer: afdeling.Telefoon,
            email: afdeling.Email ?? '',
          }),
        );
      });
  }, []);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      const email = values.email.trim() === '' ? null : values.email.trim();

      let afdeling: IOphalenAfdelingenResultElement;

      if (props.orgAfdID === undefined) {
        const toevoegenResult = await api.v2.relatie.afdeling.toevoegenAfdeling({
          orgID: props.orgID,
          naam: values.naam,
          telefoon: values.telefoonnummmer,
          email,
        });
        afdeling = toevoegenResult.afdeling;
      } else {
        const wijzigenResult = await api.v2.relatie.afdeling.wijzigenAfdeling({
          id: props.orgAfdID!,
          naam: values.naam,
          telefoon: values.telefoonnummmer,
          email,
        });
        afdeling = wijzigenResult.afdeling;
      }

      props.onSuccess({
        afdeling,
      });

      actions.setSubmitting(false);
    },
    [props.orgID, props.orgAfdID],
  );

  return (
    <Dialoog index={props.dialoogIndex ?? 0}>
      <ModalHeader>
        <ModalTitle>Afdeling {props.orgAfdID === undefined ? 'toevoegen' : 'wijzigen'}</ModalTitle>
      </ModalHeader>
      {initialValues.state === ERemoteDataState.Pending ? (
        <ModalBody>
          <LoadingSpinner />
        </ModalBody>
      ) : (
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialValues.data!}
          render={(formikProps) => <AfdelingenMuterenInner {...props} formikProps={formikProps} />}
        />
      )}
    </Dialoog>
  );
};

const AfdelingenMuterenInner = (props: IProps & { formikProps: FormikProps<IFormikValues> }) => {
  const formikProps = props.formikProps;
  const { isSubmitting, submitForm } = formikProps;

  return (
    <>
      <ModalBody>
        <div>
          <label>Naam</label>
          <Field
            name={nameof<IFormikValues>('naam')}
            render={(fieldProps: FieldProps<IFormikValues>) => {
              const { field } = fieldProps;
              return <input {...field} className="form-control" type="text" />;
            }}
          />
        </div>
        <div className="mt-2">
          <label>Telefoonnummer</label>
          <Field
            name={nameof<IFormikValues>('telefoonnummmer')}
            render={(fieldProps: FieldProps<IFormikValues>) => {
              const { field, form } = fieldProps;
              return (
                <TelefoonnummerInput
                  telefoonnummer={field.value}
                  onChange={(value) => form.setFieldValue(field.name, value)}
                />
              );
              // return <input {...field} className="form-control" type="text" />;
            }}
          />
        </div>
        <div className="mt-2">
          <label>Email</label>
          <Field
            name={nameof<IFormikValues>('email')}
            render={(fieldProps: FieldProps<IFormikValues>) => {
              const { field } = fieldProps;
              return <input {...field} className="form-control" type="email" />;
            }}
          />
        </div>
      </ModalBody>
      <ModalFooter className="d-flex flex-row justify-content-start">
        <button
          className="btn btn-primary d-flex align-items-center justify-content-center"
          onClick={submitForm}
          style={{ width: 100 }}
          disabled={isSubmitting}
        >
          {isSubmitting && (
            <span className="mr-2">
              <LoadingSpinner soort="light" grootte="15px" dikte="2px" />
            </span>
          )}
          Ok
        </button>
        <button
          className="btn btn-secondary"
          onClick={props.onAnnuleren}
          style={{ width: 100 }}
          disabled={isSubmitting}
        >
          Annuleren
        </button>
      </ModalFooter>
    </>
  );
};

export default AfdelingMuterenDialoog;
