import React from 'react';
import VinkVeld from '../VinkVeld';
import { Kleur } from '../../../bedrijfslogica/constanten';

interface IProps {
  label: string | JSX.Element;
  aangevinkt: boolean;
  onAangevinktChange: (aangevinkt: boolean) => void;
  veldverzamelingComponent: JSX.Element | null;
}

const UitklapVeldverzameling = (props: IProps) => {
  return (
    <div style={{ backgroundColor: Kleur.HeelLichtGrijs }}>
      <div
        onClick={() => props.onAangevinktChange(!props.aangevinkt)}
        style={{ cursor: 'pointer' }}
        className="d-flex align-items-center p-2"
      >
        <VinkVeld aangevinkt={props.aangevinkt} onGewijzigd={(x) => props.onAangevinktChange(x)} />
        <span className="ml-2">{props.label}</span>
      </div>
      {props.aangevinkt && <div className="p-3 pt-0">{props.veldverzamelingComponent}</div>}
    </div>
  );
};

export default UitklapVeldverzameling;
