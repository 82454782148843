import api from '../../../index';
import {
  IToevoegenGeneriekResult,
  ISelectieGeneriekParams,
  ILeegGeneriekResult,
  IOphalenGeneriekParams,
} from '../../../../../../shared/src/api/v2/generiek';

import {
  ISponsorContract,
  ISponsorContractBijlage,
  ISponsorContractGekoppeldEvent,
} from '../../../../../../shared/src/api/v2/sponsoringv2/contract';

const contract = {
  ophalenContracten: async (params: IOphalenGeneriekParams): Promise<ISponsorContract[]> => {
    return await api.post('/v2/sponsoringv2/contract/ophalen-contracten', params);
  },
  toevoegenOfWijzigenContract: async (
    params: ISponsorContract,
  ): Promise<IToevoegenGeneriekResult> => {
    return await api.post('/v2/sponsoringv2/contract/toevoegen-of-wijzigen-contract', params);
  },
  verwijderenContracten: async (params: ISelectieGeneriekParams): Promise<ILeegGeneriekResult> => {
    return await api.post('/v2/sponsoringv2/contract/verwijderen-contracten', params);
  },

  // koppelen contracten aan events

  ophalenGekoppeldeEvents: async (
    params: IOphalenGeneriekParams,
  ): Promise<ISponsorContractGekoppeldEvent[]> => {
    return await api.post('/v2/sponsoringv2/contract/ophalen-gekoppelde-events', params);
  },
  koppelenContractEvent: async (
    params: ISponsorContractGekoppeldEvent,
  ): Promise<IToevoegenGeneriekResult> => {
    return await api.post('/v2/sponsoringv2/contract/koppelen-event', params);
  },
  ontkoppelenContractEvents: async (
    params: ISelectieGeneriekParams,
  ): Promise<ILeegGeneriekResult> => {
    return await api.post('/v2/sponsoringv2/contract/ontkoppelen-events', params);
  },

  // koppelen bijlagen

  ophalenGekoppeldeBijlagen: async (
    params: IOphalenGeneriekParams,
  ): Promise<ISponsorContractBijlage[]> => {
    return await api.post('/v2/sponsoringv2/contract/ophalen-gekoppelde-bijlagen', params);
  },
  koppelenContractBijlagen: async (
    params: ISponsorContractBijlage,
  ): Promise<IToevoegenGeneriekResult> => {
    return await api.post('/v2/sponsoringv2/contract/koppelen-bijlage', params);
  },
  ontkoppelenContractBijlagen: async (
    params: ISelectieGeneriekParams,
  ): Promise<ILeegGeneriekResult> => {
    return await api.post('/v2/sponsoringv2/contract/ontkoppelen-bijlagen', params);
  },
};

export default contract;
