import api from '../../index';
import {
  IToevoegenTelefoonnotitieParams,
  IToevoegenTelefoonnotitieResult,
  IVerwerkenPotentieelOntvangenItemTelefoonnotitieParams,
  IVerwerkenPotentieelOntvangenItemTelefoonnotitieResult,
} from '../../../../../shared/src/api/v2/telefoonnotitie';

const telefoonnotitie = {
  async toevoegen(
    params: IToevoegenTelefoonnotitieParams,
  ): Promise<IToevoegenTelefoonnotitieResult> {
    return await api.post('/v2/telefoonnotitie/toevoegen', params);
  },
  async verwerkenPotentieelOntvangenItemTelefoonnotitie(
    params: IVerwerkenPotentieelOntvangenItemTelefoonnotitieParams,
  ): Promise<IVerwerkenPotentieelOntvangenItemTelefoonnotitieResult> {
    return await api.post(
      '/v2/telefoonnotitie/verwerken-potentieel-ontvangen-item-telefoonnotitie',
      params,
    );
  },
};

export default telefoonnotitie;
