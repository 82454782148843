import React, { useCallback, useMemo } from 'react';
import IDialoogProps from '../../../../../core/IDialoogProps';
import Dialoog from '../../../../../components/dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { Field, FieldProps, Formik, FormikProps } from 'formik';
import nameof from '../../../../../core/nameOf';
import DatumKiezer from '../../../../../components/formulier/DatumKiezer';
import * as Yup from 'yup';

interface IFormikValues {
  einddatum: Date;
}

interface IProps extends IDialoogProps<null> {}

const EinddatumOpgevenDialoog: React.FC<IProps> = (props) => {
  const { children, dialoogIndex, onAnnuleren, onSuccess, open } = props;

  const initialValues = useMemo<IFormikValues>(() => ({ einddatum: new Date() }), []);
  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        einddatum: Yup.date().required(),
      }),
    [],
  );

  const handleSubmit = useCallback(
    async (values: IFormikValues) => {
      // TODO
    },
    [onSuccess],
  );

  return (
    <Dialoog index={dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Einddatum opgeven</ModalTitle>
      </ModalHeader>
      <Formik<IFormikValues>
        initialValues={initialValues}
        isInitialValid
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        render={(formikProps: FormikProps<IFormikValues>) => {
          const { isValid, submitForm } = formikProps;
          return (
            <>
              <ModalBody>
                <div className="form-group">
                  <div className="row">
                    <div className="col-12">
                      <Field
                        name={nameof<IFormikValues>('einddatum')}
                        render={(fieldProps: FieldProps<IFormikValues>) => {
                          const { field, form } = fieldProps;
                          return (
                            <>
                              <label>Einddatum</label>
                              <DatumKiezer
                                waarde={field.value}
                                onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                              />
                            </>
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
              </ModalBody>
              <ModalFooter className="d-flex flex-row justify-content-start">
                <button
                  className="btn btn-primary"
                  onClick={submitForm}
                  style={{ width: 100 }}
                  disabled={isValid}
                >
                  Ok
                </button>
                <button className="btn btn-secondary" onClick={onAnnuleren} style={{ width: 100 }}>
                  Annuleren
                </button>
              </ModalFooter>
            </>
          );
        }}
      />
    </Dialoog>
  );
};

export default EinddatumOpgevenDialoog;
