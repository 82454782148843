import React, { useContext, useEffect, useMemo, useState, useCallback } from 'react';
import useUrlState from '../../../../core/useUrlState';
import { RouteComponentProps, withRouter } from 'react-router';
import { observer } from 'mobx-react-lite';
import api from '../../../../api';
import {
  IMakenBankopdrachtenResult,
  IOphalenFacturenBasisResultElement,
} from '../../../../../../shared/src/api/v2/factuur';
import {
  DXTableCheckboxComponent,
  DXTableToggleCellComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  RowDetailState,
  SelectionState,
  VirtualTable as VirtualTableBase,
} from '@devexpress/dx-react-grid';
import {
  DataTypeProvider,
  IntegratedFiltering,
  IntegratedSorting,
  SearchState,
  SortingState,
} from '@devexpress/dx-react-grid';
import FormatteerBedrag from '../../../../components/MutatieBedrag';
import { format } from 'date-fns';
// import FactuurregelsComp from './Factuurregels';
import RelatieVisualisatie from '../../../../components/personalia/RelatieVisualisatie';
import { ITabbladProps } from '../index';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import { EResultType } from '../../../../stores/CheckStore';
import { RootStoreContext } from '../../../../stores/RootStore';
import FactuurregelsComp from '../Factuurregels';
import SelectieVak from '../../../../components/SelectieVak';
import VersturenDialoog from '../../../../components/factuur/VersturenDialoog';
import {
  EVerzendwijzeFacturen as EVerzendwijze,
  EBetaalwijze,
} from '../../../../bedrijfslogica/enums';
import { IOphalenRelatiesResultElementV2 } from '../../../../../../shared/src/api/v2/relatie';
import _ from 'lodash';
import { achtergrondProcesAfwachten } from '../../../../core/achtergrondProces';
import { RealtimeContext } from '../../../../one-off-components/realtime/RealtimeIntegratie';
import { indexOf } from 'lodash';
import { IOphalenFacturenResultElement } from '../../../../../../shared/src/api/v2/debiteur/incassozaak';
import { Helmet } from 'react-helmet';

interface IProps extends RouteComponentProps, ITabbladProps {}

const geenData = {
  noData: 'Geen facturen gevonden',
};

export interface IVersturenDialoogState {
  factIDs: number[];
}

const Bankopdrachten: React.FC<IProps> = observer((props) => {
  const { checkStore } = useContext(RootStoreContext);
  const realtimeContext = useContext(RealtimeContext);

  const [facturen, setFacturen] = useState<IOphalenFacturenBasisResultElement[] | null>(null);
  const [versturenDialoogState, setVersturenDialoogState] = useState<IVersturenDialoogState | null>(
    null,
  );
  const [relaties, setRelaties] = useState<IOphalenRelatiesResultElementV2[] | null>(null);

  const keyExtractor = useCallback((row: IOphalenFacturenBasisResultElement) => row.FactID, []);

  const ophalenFacturen = useCallback(async () => {
    const facturenResult = (
      await api.v2.factuur.ophalenFacturenBasis({
        filterSchema: {
          filters: [
            { naam: 'DEFINITIEF', data: true },
            { naam: 'OPENSTAAND', data: true },
            {
              naam: 'BETAALWIJZE_NAANENUMS',
              data: [EBetaalwijze.Incasso, EBetaalwijze.Verzamelbetaling],
            },
            {
              naam: 'HEEFT_GEEN_BANKOPDRACHT',
              data: true,
            },
          ],
        },
        orderSchema: {
          orders: [
            {
              naam: 'FACTUURDATUM',
              richting: 'DESC',
            },
            {
              naam: 'FACTUURNUMMER',
              richting: 'DESC',
            },
          ],
        },
        // paginatie: {
        //   index: 0,
        //   aantal: 1000,
        // },
      })
    ).facturen;

    const factIDsResult = facturenResult.map((x) => x.FactID);

    // Incassozaken voor de opgehaalde facturen
    const incassozaken = (
      await api.v2.debiteur.incassozaak.ophalenIncassozaken({
        filterSchema: {
          filters: [
            {
              naam: 'FACT_IDS',
              data: factIDsResult,
            },
            {
              naam: 'IS_AFGEHANDELD',
              data: false,
            },
          ],
        },
      })
    ).incassozaken;

    const facturenInIncassozaak: IOphalenFacturenResultElement[] = _.flatten(
      incassozaken.map((x) => x.facturen),
    );
    const factIDsInIncassozaak = facturenInIncassozaak.map((x) => x.FactID);

    // Betalingsregelingen voor de opgehaalde facturen
    const betalingsregelingen = (
      await api.v2.debiteur.betalingsregeling.ophalenBetalingsregelingen({
        filterSchema: {
          filters: [
            {
              naam: 'FACT_IDS',
              data: factIDsResult,
            },
            {
              naam: 'IS_AFGEHANDELD',
              data: false,
            },
          ],
        },
      })
    ).betalingsregelingen;

    const facturenInBetalingsregeling: any[] = _.flatten(
      betalingsregelingen.map((x) => x.facturen),
    );
    const factIDsInBetalingsregeling = facturenInBetalingsregeling.map((x) => x.FactID);

    // Filter de facturen eruit die in een dossier zitten
    const facturen = facturenResult.filter(
      (x) =>
        factIDsInIncassozaak.indexOf(x.FactID) === -1 &&
        factIDsInBetalingsregeling.indexOf(x.FactID) === -1,
    );

    const relIDs = _.uniq(facturen.map((x) => x.RelID));
    const relatiesResult = await api.v2.relatie.ophalenRelaties({
      filterSchema: {
        filters: [{ naam: 'IDS', data: relIDs }],
      },
    });
    setRelaties(relatiesResult.relaties);
    setFacturen(facturen);
  }, []);

  useEffect(() => {
    ophalenFacturen();
  }, []);

  // const handleVerwijderen = useCallback(async () => {
  //   const checkData = await api.v2.factuur.voorstel.checkVerwijderenFacturenUitVoorstel({
  //     factIDs: props.voorstelSelectie,
  //   });
  //   if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
  //     return;
  //   }
  //   if (
  //     (
  //       await checkStore.bevestigen({
  //         titel: `Wil je de ${props.voorstelSelectie.length} facturen uit het voorstel verwijderen?`,
  //       })
  //     ).type === EResultType.Annuleren
  //   ) {
  //     return;
  //   }

  //   await api.v2.factuur.voorstel.verwijderenFacturenUitVoorstel({
  //     factIDs: props.voorstelSelectie,
  //   });

  //   props.onVoorstelSelectieChange([]);

  //   ophalenFacturen();
  // }, [props.voorstelSelectie]);

  const handleVersturen = useCallback(async (factIDs: number[]) => {
    const params = { factIDs, uitvoerdatum: null };
    const checkData = await api.v2.factuur.checkMakenBankopdrachten(params);
    if (
      (
        await checkStore.controleren({
          checkData,
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    if (
      (
        await checkStore.bevestigen({
          inhoud: `Bankopdracht(en) maken?`,
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    // const result = await api.v2.factuur.makenBankopdrachten(params);

    const makenBankopdrachtenAchtergrondProces = await api.v2.factuur.makenBankopdrachtenAchtergrondProces(
      {
        factIDs,
      },
    );
    const definitiefAchtergrondProcesResult = await achtergrondProcesAfwachten<
      IMakenBankopdrachtenResult
    >(makenBankopdrachtenAchtergrondProces.id, realtimeContext);
    if (definitiefAchtergrondProcesResult.type !== 'DATA') {
      // Timeout of error
      alert('ERROR');
      return;
    }

    props.onVersturenSelectieChange([]);

    ophalenFacturen();
  }, []);

  const kolommen = useMemo<TypedColumn<IOphalenFacturenBasisResultElement>[]>(
    () => [
      {
        name: '__debiteur' as any,
        title: 'Debiteur',
        getCellValue: (x) => {
          if (relaties === null) {
            return '';
          }
          const naam = relaties!.find((r) => r.RelID === x.RelID)!.weergavenaam;
          return naam;
        },
      },
      // {
      //   name: 'Factuurnummer',
      //   title: 'Fact.nr',
      // },
      {
        name: 'Factuurdatum',
        title: 'Factuurdatum',
      },
      {
        name: 'Vervaldatum',
        title: 'Vervaldatum',
      },
      {
        name: 'Bedrag',
        title: 'Bedrag',
      },
      {
        name: 'Openstaand',
        title: 'Openstaand',
      },
      {
        name: '__betaalwijze' as any,
        title: 'Betaalwijze',
        getCellValue: (x) => {
          return x.betaalwijze.Naam;
        },
      },
      {
        name: '__iban' as any,
        title: 'IBAN',
      },
      {
        name: '__betaaldag' as any,
        title: 'Betaaldag',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IOphalenFacturenBasisResultElement>[]>(
    () => [
      // {
      //   columnName: 'Factuurnummer',
      //   width: 100,
      // },
      {
        columnName: 'Factuurdatum',
        width: 130,
      },
      {
        columnName: 'Vervaldatum',
        width: 130,
      },
      {
        columnName: 'Bedrag',
        width: 100,
      },
      {
        columnName: 'Openstaand',
        width: 150,
      },
      {
        columnName: '__debiteur' as any,
        width: 250,
      },
      {
        columnName: '__betaalwijze' as any,
        width: 150,
      },
      {
        columnName: '__iban' as any,
        width: 175,
      },
      {
        columnName: '__betaaldag',
        width: 110,
      },
    ],
    [],
  );

  const kolomExtensies: VirtualTableBase.ColumnExtension[] = useMemo(() => {
    return [
      {
        columnName: `__betaaldag`,
        align: 'right',
      },
    ];
  }, []);

  return (
    <>
      {facturen === null || relaties === null ? (
        <div className="d-flex flex-fill align-items-center justify-content-center">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          <Helmet>
            <title>Bankopdrachten maken</title>
          </Helmet>
          <div
            className="d-flex flex-column p-3"
            style={{
              backgroundColor: Kleur.HeelLichtGrijs,
              borderBottom: `1px solid ${Kleur.LichtGrijs}`,
            }}
          >
            <div className="d-flex">
              {/* <button
                className="btn btn-sm btn-light d-flex align-items-center ml-2"
                style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                disabled={props.voorstelSelectie.length === 0}
                onClick={() => handleVerwijderen()}
              >
                <IcoonToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                <span className="ml-2">Facturen uit voorstel verwijderen</span>
              </button> */}
              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-2"
                style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                disabled={props.versturenSelectie.length === 0}
                onClick={() => handleVersturen(props.versturenSelectie)}
              >
                {/* <IcoonToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} /> */}
                <span className="ml-2">Bankopdrachten maken</span>
              </button>
            </div>

            <div className="mt-3 ml-3">
              <SelectieVak
                aantal={props.versturenSelectie.length}
                totaalAantal={facturen.length}
                onChange={(alles) => {
                  if (alles) {
                    props.onVersturenSelectieChange(facturen.map((x) => x.FactID));
                  } else {
                    props.onVersturenSelectieChange([]);
                  }
                }}
                entiteitEnkelvoud="factuur"
                entiteitMeervoud="facturen"
              />
            </div>
          </div>
          <GridStyleWrapper height="calc(100vh - 175px)">
            <Grid columns={kolommen} getRowId={keyExtractor} rows={facturen!}>
              <DataTypeProvider
                for={['Factuurdatum']}
                formatterComponent={(props) => (
                  <span>{format(new Date(props.value), 'dd-MM-yyyy')}</span>
                )}
              />
              <DataTypeProvider
                for={['Vervaldatum']}
                formatterComponent={(props) => (
                  <span>{format(new Date(props.value), 'dd-MM-yyyy')}</span>
                )}
              />
              <DataTypeProvider
                for={['Bedrag']}
                formatterComponent={(props) => <FormatteerBedrag bedrag={props.value} />}
              />
              <DataTypeProvider
                for={['Openstaand']}
                formatterComponent={(props) => <FormatteerBedrag bedrag={props.value} />}
              />
              <DataTypeProvider
                for={['__debiteur']}
                formatterComponent={(props) =>
                  props.row.debiteur.RelID !== null ? (
                    <RelatieVisualisatie relID={props.row.debiteur.RelID} />
                  ) : (
                    <span></span>
                  )
                }
              />

              <DataTypeProvider
                for={['__betaalwijze']}
                formatterComponent={(props) => props.row.betaalwijze.Naam}
              />

              <DataTypeProvider
                for={['__betaaldag']}
                formatterComponent={(props) => {
                  const row: IOphalenFacturenBasisResultElement = props.row;
                  const betaaldag =
                    row.debiteur.Betaaldag !== -1 ? row.debiteur.Betaaldag : 'Stufi';
                  return <span>{betaaldag}</span>;
                }}
              />

              <DataTypeProvider
                for={['__iban']}
                formatterComponent={(props) => {
                  const row: IOphalenFacturenBasisResultElement = props.row;
                  return <span>{row.rekening !== null ? row.rekening.IBAN : ''}</span>;
                }}
              />

              <SortingState defaultSorting={[]} />
              <IntegratedSorting />

              <VirtualTable columnExtensions={kolomExtensies} messages={geenData} />

              <TableColumnResizing defaultColumnWidths={kolomBreedtes} />

              <SelectionState
                selection={props.versturenSelectie}
                onSelectionChange={(value) => props.onVersturenSelectieChange(value as number[])}
              />

              <TableHeaderRow showSortingControls />
              <RowDetailState defaultExpandedRowIds={[]} />
              <TableRowDetail
                contentComponent={FactuurregelsComp}
                toggleCellComponent={DXTableToggleCellComponent}
              />
              <TableSelection cellComponent={DXTableCheckboxComponent} />
            </Grid>
          </GridStyleWrapper>
        </>
      )}

      {/* {versturenDialoogState !== null && (
        <VersturenDialoog
          open
          factIDs={versturenDialoogState.factIDs}
          onSuccess={() => {
            setVersturenDialoogState(null);
            ophalenFacturen();
          }}
          onAnnuleren={() => setVersturenDialoogState(null)}
        ></VersturenDialoog>
      )} */}
    </>
  );
});

export default withRouter(Bankopdrachten);
