import React from 'react';
import styled from 'styled-components';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { IOphalenFacturenBasisResultElement } from '../../../../../../../shared/src/api/v2/factuur';
import GegevensLayout from '../../../../layout/GegevensLayout';
import { format } from 'date-fns';
import FormatteerBedrag from '../../../../MutatieBedrag';

const Root = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

interface IProps extends RouteComponentProps {
  factuur: IOphalenFacturenBasisResultElement;
}

const Data = (props: IProps) => {
  return (
    <Root>
      <GegevensLayout
        gegevens={[
          {
            label: 'Factuurnummer',
            waarde: props.factuur.Factuurnummer,
          },
          {
            label: 'Factuurdatum',
            waarde: format(new Date(props.factuur.Factuurdatum), 'dd-MM-yyyy'),
          },
          {
            label: 'Vervaldatum',
            waarde: format(new Date(props.factuur.Vervaldatum), 'dd-MM-yyyy'),
          },
          {
            label: 'Bedrag',
            waarde: <FormatteerBedrag bedrag={props.factuur.Bedrag} />,
          },
          {
            label: 'Openstaand',
            waarde: <FormatteerBedrag bedrag={props.factuur.Openstaand} />,
          },
        ]}
      />
    </Root>
  );
};

export default withRouter(Data);
