import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { IOphalenStornoredenenResultElement } from '../../../../../../shared/src/api/v2/bank/bank';
import api from '../../../../api';
import {
  DXCommandComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import MenuLayout from '../../../../components/MenuLayout';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import FilterBalkV2 from '../../../../components/FilterBalkV2';
import { Kleur as EKleur } from '../../../../bedrijfslogica/constanten';
import useUrlState from '../../../../core/useUrlState';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  TableRowDetail,
  Toolbar,
  VirtualTable,
  Table,
  TableEditColumn,
  TableSelection,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  DataTypeProvider,
  EditingState,
  IntegratedSorting,
  SortingState,
} from '@devexpress/dx-react-grid';
import WijzigenDialoog from './WijzigenDialoog';

interface IProps extends RouteComponentProps {}

export interface IWijzigenDialoogState {
  id: number;
}

interface IUrlState {
  selectie: number[];
  wijzigenDialoogState: IWijzigenDialoogState | null;
}

const defaultUrlState: IUrlState = {
  selectie: [],
  wijzigenDialoogState: null,
};

const Stornoredenen: React.FC<IProps> = (props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);

  const [stornoredenen, setStornoredenen] = useState<IOphalenStornoredenenResultElement[] | null>(
    null,
  );
  const ophalenStornoredenen = useCallback(async () => {
    const result = await api.v2.bank.ophalenStornoredenen({
      orderSchema: {
        orders: [{ naam: 'CODE', richting: 'ASC' }],
      },
    });

    setStornoredenen(result.redenen);
  }, []);

  useEffect(() => {
    ophalenStornoredenen();
  }, [ophalenStornoredenen]);

  const kolommen = useMemo<TypedColumn<IOphalenStornoredenenResultElement>[]>(() => {
    return [
      {
        name: 'Code',
        title: 'Code',
      },
      {
        name: 'Naam',
        title: 'Naam',
      },
      {
        name: 'Signaleren',
        title: 'Signaleren',
      },
    ];
  }, []);

  const keyExtractor = useCallback((row: IOphalenStornoredenenResultElement) => row.ID, []);

  const kolomBreedtes = useMemo<
    TypedTableColumnWidthInfo<IOphalenStornoredenenResultElement>[]
  >(() => {
    return [
      {
        columnName: 'Code',
        width: 100,
      },
      {
        columnName: 'Naam',
        width: 500,
      },
      {
        columnName: 'Signaleren',
        width: 150,
      },
    ];
  }, []);

  return (
    <>
      <MenuLayout
        // menu={
        //   <>
        //     <div className="d-flex">
        //       <div className="d-flex align-items-center">
        //         <button
        //           className="btn btn-sm btn-light d-flex align-items-center"
        //           style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
        //           disabled={urlState.selectie.length === 0}
        //           onClick={() => null}
        //         >
        //           {/* <IconSend style={{ width: 16, height: 16, fill: EKleur.Grijs }} /> */}
        //           <span className="ml-2">Signaleren</span>
        //         </button>
        //       </div>
        //       {/* <div className="d-flex flex-fill ml-3">
        //           <FilterBalkV2
        //             filters={filters}
        //             filterData={urlState.filterData}
        //             onFilterDataChange={(x) => setUrlStateSync('filterData', x)}
        //             onFilterSchemaChange={(x) => setFilterSchema(x)}
        //           />
        //         </div> */}
        //     </div>
        //   </>
        // }
        body={
          <>
            {stornoredenen === null ? (
              <LoadingSpinner />
            ) : (
              <div>
                <GridStyleWrapper height="calc(100vh - 210px)">
                  <Grid columns={kolommen} rows={stornoredenen || []} getRowId={keyExtractor}>
                    <DataTypeProvider
                      for={['Signaleren']}
                      formatterComponent={(formatterProps) => {
                        return <span>{formatterProps.value ? 'Ja' : 'Nee'}</span>;
                      }}
                    />

                    <SortingState defaultSorting={[]} />
                    <IntegratedSorting />

                    <VirtualTable />

                    <TableColumnResizing defaultColumnWidths={kolomBreedtes} />

                    <EditingState
                      onAddedRowsChange={() => {}}
                      onEditingRowIdsChange={async (x) => {
                        const id = x[x.length - 1] as number;
                        setUrlStateSync('wijzigenDialoogState', { id });
                      }}
                      onCommitChanges={() => null}
                    />
                    <TableEditColumn
                      width={45}
                      showEditCommand
                      commandComponent={DXCommandComponent}
                    />

                    {/* <SelectionState
                    selection={urlState.selectie}
                    onSelectionChange={(value) => setUrlStateSync('selectie', value as number[])}
                  /> */}

                    {/* <TableSelection cellComponent={DXTableCheckboxComponent} /> */}

                    <TableHeaderRow showSortingControls />
                  </Grid>
                </GridStyleWrapper>
              </div>
            )}
          </>
        }
      />
      {urlState.wijzigenDialoogState !== null && (
        <WijzigenDialoog
          open
          id={urlState.wijzigenDialoogState.id}
          onSuccess={() => {
            setUrlStateSync('wijzigenDialoogState', null);
            ophalenStornoredenen();
          }}
          onAnnuleren={() => setUrlStateSync('wijzigenDialoogState', null)}
        />
      )}
    </>
  );
};

export default withRouter(Stornoredenen);
