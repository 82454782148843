import { makeObservable, observable } from 'mobx';
import { RootStore } from './RootStore';
import api from '../api';
import {
  IBezigheid,
  IInstellenBezigheidResult,
  IOphalenBezighedenResult,
  IOphalenGebruikersResult,
} from '../../../shared/src/api/v2/gebruiker';
import {
  IAspGebruiker,
  IAspGebruikerAutoStatusGewijzigdMessageData,
  IAspGebruikerGebruikersprofielIngesteldMessageData,
  IAspGebruikerGewijzigdMessageData,
  IAspGebruikersprofiel,
  IAspGebruikerStatusIngesteldMessageData,
  IGebruikersprofielGewijzigdMessageData,
} from '../../../shared/src/api/v2/aspGebruiker';
import { ws } from '../one-off-components/realtime/RealtimeIntegratie';
import {
  bezigheidNaarSleutel,
  bezigheidsleutelNaarString,
  useBezigheidKnopStore,
} from '../components/BezigheidKnop/store';
import { createReadyRemoteData } from '../models/IRemoteData';

interface ILoginWaardes {
  gebruikersnaam: string;
  wachtwoord: string;
}

class GebruikerStore {
  public rootStore: RootStore;
  public gebruiker: IAspGebruiker | null = null;
  public aspGebruikersprofiel: IAspGebruikersprofiel | null = null;
  public loginError: string = '';
  public isAanhetLaden: boolean = false;
  public gebruikers: IOphalenGebruikersResult | null = null;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    makeObservable(this, {
      gebruiker: observable,
      aspGebruikersprofiel: observable,
      loginError: observable,
      isAanhetLaden: observable,
      gebruikers: observable,
    });
  }

  public login = async (values: ILoginWaardes) => {
    try {
      const result = await api.v2.auth.login({
        gebruikersnaam: values.gebruikersnaam,
        wachtwoord: values.wachtwoord,
      });

      if (result.err !== undefined) {
        switch (result.err.type) {
          case 'GEBRUIKERSNAAM_OF_WACHTWOORD_INCORRECT':
            this.loginError = 'Gebruikersnaam of wachtwoord is incorrect.';
            break;
        }
        return;
      }

      // this.gebruiker = response;
      ws?.send(
        JSON.stringify({
          type: 'LOGIN',
          data: {
            apiKey: result.data!.apiSleutel,
          },
        }),
      );
      this.loginError = '';
      await this.getCurrentUser();
      // realtimeManager.login(this.gebruiker.key);
    } catch (error) {
      this.loginError = `${error.message}`;
    }
  };

  public logout = async (history: any) => {
    this.gebruiker = null;
    this.aspGebruikersprofiel = null;
    history.push('/');
    await api.v2.auth.logout({});

    ws?.send(
      JSON.stringify({
        type: 'LOGOUT',
        data: {},
      }),
    );

    // resetAxios();
  };

  public getCurrentUser = async () => {
    this.isAanhetLaden = true;
    try {
      const gebruiker = await api.v2.aspGebruiker.ophalenMijnGebruiker();
      const profielenResult = await api.v2.aspGebruiker.ophalenGebruikersprofielen({
        filterSchema: {
          filters: [
            {
              naam: 'ASP_GEBR_IDS_IN',
              data: [gebruiker.AspGebrID],
            },
          ],
        },
      });
      this.gebruiker = gebruiker;
      this.aspGebruikersprofiel =
        gebruiker.AspGebrProfielID === null
          ? null
          : profielenResult.profielen.find((x) => x.ID === gebruiker.AspGebrProfielID) || null;

      this.isAanhetLaden = false;
    } catch (error) {
      this.isAanhetLaden = false;
      return null;
    }
  };

  public ophalenGebruikers = async () => {
    this.gebruikers = await api.v2.gebruiker.ophalenGebruikers();
  };

  public handleInstellenBezigheid = async (data: { bezigheid: IBezigheid }) => {
    const store = useBezigheidKnopStore.getState();
    const bezigheidsleutel = bezigheidNaarSleutel(data.bezigheid);
    const sleutelString = bezigheidsleutelNaarString(bezigheidsleutel);

    const bezigheden = { ...store.bezigheden };

    bezigheden[sleutelString] = createReadyRemoteData(data.bezigheid);

    useBezigheidKnopStore.setState({
      bezigheden,
    });

    // const bezigheden = this.bezigheden || [];
    // this.bezigheden = [
    //   ...bezigheden.filter((x) => x.ID !== data.ID),
    //   {
    //     ID: data.ID,
    //     AspGebrID: data.AspGebrID,
    //     Datum: data.Datum,
    //     NaamEnum: data.NaamEnum,
    //     RelID: data.RelID,
    //     Status: data.Status,
    //     TelefoonnummerWhatsApp: data.TelefoonnummerWhatsApp,
    //   },
    // ];
  };

  public handleAspGebruikerStatusIngesteld = async (
    data: IAspGebruikerStatusIngesteldMessageData,
  ) => {
    if (this.gebruiker !== null && data.aspGebrID === this.gebruiker.AspGebrID) {
      this.gebruiker = {
        ...this.gebruiker,
        HandmatigeStatus: data.status,
      };
    }
    if (
      this.gebruikers !== null &&
      this.gebruikers.findIndex((x) => x.AspGebrID === data.aspGebrID) !== -1
    ) {
      // Update gebruikers
      this.gebruikers = this.gebruikers.map((x) => {
        if (x.AspGebrID === data.aspGebrID) {
          return {
            ...x,
            HandmatigeStatus: data.status,
          };
        }
        return x;
      });
    }
  };

  public handleAspGebruikerAutoStatusGewijzigd = async (
    data: IAspGebruikerAutoStatusGewijzigdMessageData,
  ) => {
    if (this.gebruiker !== null && data.aspGebrID === this.gebruiker.AspGebrID) {
      this.gebruiker = {
        ...this.gebruiker,
        AutoStatus: data.status,
      };
    }
    if (
      this.gebruikers !== null &&
      this.gebruikers.findIndex((x) => x.AspGebrID === data.aspGebrID) !== -1
    ) {
      // Update gebruikers
      this.gebruikers = this.gebruikers.map((x) => {
        if (x.AspGebrID === data.aspGebrID) {
          return {
            ...x,
            AutoStatus: data.status,
          };
        }
        return x;
      });
    }
  };

  public handleAspGebruikerGewijzigd = async (data: IAspGebruikerGewijzigdMessageData) => {
    if (this.gebruiker !== null && data.aspGebrID === this.gebruiker.AspGebrID) {
      this.gebruiker = await api.v2.aspGebruiker.ophalenMijnGebruiker();
    }
    if (
      this.gebruikers !== null &&
      this.gebruikers.findIndex((x) => x.AspGebrID === data.aspGebrID) !== -1
    ) {
      const ophalenGebruikersResult = await api.v2.aspGebruiker.ophalenGebruikers({
        filterSchema: {
          filters: [
            {
              naam: 'ASP_GEBR_IDS',
              data: [data.aspGebrID],
            },
          ],
        },
      });
      const g = ophalenGebruikersResult.gebruikers[0];
      // Update gebruikers
      this.gebruikers = this.gebruikers.map((x) => {
        if (x.AspGebrID === data.aspGebrID) {
          return {
            ...g,
          };
        }
        return x;
      });
    }
  };

  public handleAspGebruikerGebruikersprofielIngesteld = async (
    data: IAspGebruikerGebruikersprofielIngesteldMessageData,
  ) => {
    if (this.gebruiker !== null && data.aspGebrID === this.gebruiker.AspGebrID) {
      this.gebruiker = await api.v2.aspGebruiker.ophalenMijnGebruiker();

      if (data.id === null) {
        this.aspGebruikersprofiel = null;
      } else {
        const profielenResult = await api.v2.aspGebruiker.ophalenGebruikersprofielen({
          filterSchema: {
            filters: [
              {
                naam: 'IDS',
                data: [data.id],
              },
            ],
          },
        });
        this.aspGebruikersprofiel = profielenResult.profielen[0] || null;
      }
    }
    if (
      this.gebruikers !== null &&
      this.gebruikers.findIndex((x) => x.AspGebrID === data.aspGebrID) !== -1
    ) {
      const result = await api.v2.aspGebruiker.ophalenGebruikers({
        filterSchema: {
          filters: [
            {
              naam: 'ASP_GEBR_IDS',
              data: [data.aspGebrID],
            },
          ],
        },
      });
      const gebr = result.gebruikers[0];
      // Update gebruikers
      this.gebruikers = this.gebruikers.map((x) => {
        if (x.AspGebrID === data.aspGebrID) {
          return {
            ...gebr,
          };
        }
        return x;
      });
    }
  };

  public handleGebruikersprofielGewijzigd = async (
    data: IGebruikersprofielGewijzigdMessageData,
  ) => {
    if (this.aspGebruikersprofiel !== null && this.aspGebruikersprofiel.ID === data.profiel.ID) {
      this.aspGebruikersprofiel = data.profiel;
    }
  };
}

export default GebruikerStore;
