import api from '../../index';
import {
  ICheckToevoegenFaqParams,
  ICheckWijzigenFaqParams,
  IOphalenFaqParams,
  IOphalenFaqResult,
  IOphalenGroepVragenParams,
  IOphalenGroepVragenResults,
  IOphalenGroepenParams,
  IOphalenGroepenResult,
  IToevoegenFaqParams,
  IToevoegenFaqResult,
  IVerwijderenFaqParams,
  IWijzigenFaqParams,
} from '../../../../../shared/src/api/v2/faq';
import ICheckData from '../../../../../shared/src/models/ICheckData';
import { ISorterenParams } from '../../../../../shared/src/api/v2/sorteren';

const faq = {
  // ophalen
  ophalenFaq: async (params: IOphalenFaqParams): Promise<IOphalenFaqResult> => {
    return await api.post('/v2/faq/ophalen-faq', params);
  },
  ophalenGroepen: async (params: IOphalenGroepenParams): Promise<IOphalenGroepenResult> => {
    return await api.post('/v2/faq/ophalen-groepen', params);
  },

  // toevoegen
  checkToevoegenFaq: async (params: ICheckToevoegenFaqParams) => {
    return await api.post<ICheckData>('v2/faq/check-toevoegen-faq', params);
  },
  toevoegenFaq: async (params: IToevoegenFaqParams) => {
    return await api.post<IToevoegenFaqResult>('v2/faq/toevoegen-faq', params);
  },

  // wijzigen
  checkWijzigenFaq: async (params: ICheckWijzigenFaqParams) => {
    return await api.post<ICheckData>('v2/faq/check-wijzigen-faq', params);
  },
  wijzigenFaq: async (params: IWijzigenFaqParams) => {
    return await api.post<any>('v2/faq/wijzigen-faq', params);
  },

  // verwijderen
  checkVerwijderenFaq: async (params: IVerwijderenFaqParams) => {
    return await api.post<ICheckData>('v2/faq/check-verwijderen-faq', params);
  },
  verwijderenFaq: async (params: IVerwijderenFaqParams) => {
    return await api.post<any>('v2/faq/verwijderen-faq', params);
  },

  // groep-vragen

  ophalenGroepVragen: async (
    params: IOphalenGroepVragenParams,
  ): Promise<IOphalenGroepVragenResults> => {
    return await api.post('/v2/faq/ophalen-groep-vragen', params);
  },

  sorterenGroepVragen: async (params: ISorterenParams): Promise<any> => {
    return await api.post('/v2/faq/sorteren-groep-vragen', params);
  },

  // pagina-vragen

  ophalenPaginaVragen: async (
    params: IOphalenGroepVragenParams,
  ): Promise<IOphalenGroepVragenResults> => {
    return await api.post('/v2/faq/ophalen-pagina-vragen', params);
  },

  sorterenPaginaVragen: async (params: ISorterenParams): Promise<any> => {
    return await api.post('/v2/faq/sorteren-pagina-vragen', params);
  },
};

export default faq;
