import React, { useContext, useMemo, useState } from 'react';
import { IContext, IRegel, ITabbladProps, ProductContext } from '../index';
import LoadingSpinner from '../../../Gedeeld/LoadingSpinner';
import MeldingenComp from '../../../service/MeldingenComp';
import OpdrachtenComp from '../../../service/OpdrachtenComp';
import WerkbonnenComp from '../../../service/WerkbonnenComp';

interface IProps extends ITabbladProps {}

const ServiceTab: React.FC<IProps> = (props) => {
  const { servicemeldingen, serviceopdrachten, werkbonnen } = useContext(ProductContext);

  if (servicemeldingen === null || serviceopdrachten === null || werkbonnen === null) {
    return <LoadingSpinner />;
  }

  return (
    <div className="d-flex flex-column flex-fill" style={{ overflowY: 'auto', maxHeight: 600 }}>
      <div className="mt-2 pl-3 pr-3 pt-2 mb-4">
        <MeldingenComp meldingen={servicemeldingen} />
      </div>
      <div className="mt-2 pl-3 pr-3 pt-2 mb-4">
        <OpdrachtenComp opdrachten={serviceopdrachten} />
      </div>
      <div className="mt-2 pl-3 pr-3 pt-2 mb-4">
        <WerkbonnenComp werkbonnen={werkbonnen} />
      </div>
    </div>
  );
};

export default ServiceTab;
