import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { IUitgeklapteRijProps } from '../../../../tabel/ASPTabel/Body/UitgeklapteRij';
import styled from 'styled-components';
import TabelInspringBlok from '../../../../layout/TabelInspringBlok';
import MenuLayout from '../../../../MenuLayout';
import ASPTabel from '../../../../tabel/ASPTabel';
import {
  IOphalenProlongatieContractVorderingenResult,
  IProlongatieContract,
  IProlongatieContractVordering,
} from '../../../../../../../shared/src/api/v2/prolongatie';
import { ASPKolom, EAspKolomBreedteType } from '../../../../tabel/ASPTabel/types';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
} from '../../../../../models/IRemoteData';
import { format } from 'date-fns';
import api from '../../../../../api';
import FormatteerBedrag from '../../../../MutatieBedrag';
import { ProlongatieContractContext } from '../index';
import { EStoplichtStatus, StoplichtIndicatie } from '../../../../formulier/StoplichtIndicatie';

const Root = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
`;

type Kolom = 'periode_van' | 'periode_tot' | 'bedrag' | 'maandbedrag' | 'omschrijving';

const ContractDetail = (props: IUitgeklapteRijProps<number, IProlongatieContract>) => {
  const prolongatieContractContext = useContext(ProlongatieContractContext);
  const afgeleideData = useMemo(() => {
    return prolongatieContractContext.data.afgeleideDatas[props.regel.ID];
  }, [props.regel.ID, prolongatieContractContext.data]);

  const [vorderingenResult, setVorderingenResult] = useState<
    IRemoteData<IOphalenProlongatieContractVorderingenResult>
  >(createPendingRemoteData());
  const ophalenVorderingen = useCallback(async () => {
    const result = await api.v2.prolongatie.ophalenProlongatieContractVorderingen({
      filterSchema: {
        filters: [
          {
            naam: 'PROLONGATIE_CONTRACT_IDS',
            data: [props.regel.ID],
          },
        ],
      },
    });
    setVorderingenResult(createReadyRemoteData(result));
  }, [props.regel.ID]);
  useEffect(() => {
    ophalenVorderingen();
  }, [ophalenVorderingen]);

  const keyExtractor = useCallback((entiteit: IProlongatieContractVordering) => entiteit.ID, []);
  const kolommen = useMemo<ASPKolom<Kolom, IProlongatieContractVordering>[]>(
    () => [
      {
        key: 'periode_van',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 125,
        label: 'Periode van',
        renderer: (rij) => format(new Date(rij.PeriodeVan), 'dd-MM-yyyy'),
      },
      {
        key: 'periode_tot',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 125,
        label: 'Periode tot',
        renderer: (rij) => format(new Date(rij.PeriodeTot), 'dd-MM-yyyy'),
      },
      {
        key: 'bedrag',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 100,
        label: 'Bedrag',
        renderer: (rij) => <FormatteerBedrag bedrag={rij.Bedrag} />,
      },
      {
        key: 'maandbedrag',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 120,
        label: 'Maandbedrag',
        renderer: (rij) => <FormatteerBedrag bedrag={rij.Maandbedrag} />,
      },
      {
        key: 'omschrijving',
        breedteType: EAspKolomBreedteType.Flex,
        flex: 1,
        label: 'Omschrijving',
        renderer: (rij) => rij.Omschrijving,
      },
    ],
    [],
  );

  return (
    <Root>
      <TabelInspringBlok />
      <MenuLayout
        body={
          <>
            {afgeleideData.signaleringen.length > 0 && (
              <div className="p-3 pl-4 pr-4">
                <h5>Signaleringen</h5>
                <div className="pl-2 pr-2 pb-2">
                  {afgeleideData.signaleringen.map((signalering, index) => (
                    <div key={index} className="d-flex align-items-center">
                      <StoplichtIndicatie
                        status={
                          signalering.niveau === 'error'
                            ? EStoplichtStatus.Rood
                            : EStoplichtStatus.Oranje
                        }
                      />

                      <span className="ml-2">{signalering.tekst}</span>
                    </div>
                  ))}
                </div>
              </div>
            )}
            <ASPTabel<number, Kolom, IProlongatieContractVordering>
              keyExtractor={keyExtractor}
              kolommen={kolommen}
              rijen={vorderingenResult.data?.vorderingen ?? {}}
            />
          </>
        }
      />
    </Root>
  );
};

export default ContractDetail;
