import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  IOphalenProductmodellenResultElement,
  IToevoegenProductmodelParams,
  IWijzigenProductmodelParams,
} from '../../../../../../shared/src/api/v2/aanbod/productmodel';
import api from '../../../../api';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import { RouteComponentProps, withRouter } from 'react-router';

import {
  DXTableCheckboxComponent,
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
  DXTableToggleCellComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import {
  Grid,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  DataTypeProvider,
  EditingState,
  IntegratedSorting,
  RowDetailState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import { Kleur as EKleur } from '../../../../bedrijfslogica/constanten';
import useUrlState from '../../../../core/useUrlState';
import DetailRegel from './DetailRegel';
import MuterenDialoog, { EAfbeeldingSet, ESpecificatieSet } from './MuterenDialoog';
import FilterBalkV2, {
  IFilter,
  IFilterData,
  maakFilterSchema,
} from '../../../../components/FilterBalkV2';
import MultiCombobox from '../../../../components/formulier/MultiCombobox';
import { IOphalenProductsoortenResultElement } from '../../../../../../shared/src/api/v2/product/soort';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../models/IRemoteData';
import { RootStoreContext } from '../../../../stores/RootStore';
import { EResultType } from '../../../../stores/CheckStore';
import nameOf from '../../../../core/nameOf';
import ProductmodelInfoDialoog from '../../../../components/productmodel/ProductmodelInfoDialoog';
import { IconInformatie } from '../../../../components/Icons';
import { Helmet } from 'react-helmet';

enum EFilter {
  Actief = 'ACTIEF',
  ProductsoortIds = 'PRODSRT_IDS',
  Openbaar = 'OPENBAAR',
}

interface IProps extends RouteComponentProps {}

interface IPropsWithProductsoorten extends IProps {
  productsoorten: IOphalenProductsoortenResultElement[];
}

interface IToevoegenState {}

interface IWijzigenState {
  prodModID: number;
}
interface IProductmodelInfoDialoogState {
  prodModID: number;
}

interface IUrlState {
  selectie: number[];
  uitgeklapt: number[];
  toevoegenState: IToevoegenState | null;
  wijzigenState: IWijzigenState | null;
  filterdata: IFilterData<EFilter>[];
  productmodelInfoDialoogState: IProductmodelInfoDialoogState | null;
}

export interface IProductmodellenContext {
  onVerversenAangevraagd: () => void;
}

export const ProductmodellenContext = React.createContext<IProductmodellenContext | null>(null);

export interface IRow extends IOphalenProductmodellenResultElement {}

const geenData = {
  noData: 'Geen productmodellen gevonden',
};

const Productmodellen: React.FC<IPropsWithProductsoorten> = (props) => {
  const { productsoorten } = props;

  const defaultUrlState = useMemo<IUrlState>(
    () => ({
      selectie: [],
      uitgeklapt: [],
      toevoegenState: null,
      wijzigenState: null,
      productmodelInfoDialoogState: null,
      filterdata: [
        {
          naam: EFilter.Actief,
          data: true,
          isActief: true,
        },
        {
          naam: EFilter.ProductsoortIds,
          data: productsoorten.length === 0 ? [] : [productsoorten[0].ProdSrtID],
          isActief: true,
        },
        {
          naam: EFilter.Openbaar,
          data: true,
          isActief: true,
        },
      ],
    }),
    [productsoorten],
  );
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);

  const { checkStore } = useContext(RootStoreContext);

  const [modellen, setModellen] = useState<IOphalenProductmodellenResultElement[] | null>(null);

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.Actief,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return <span>Alleen actieve modellen</span>;
        },
      },
      {
        naam: EFilter.ProductsoortIds,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return (
            <div className="d-flex align-items-center">
              <span className="mr-2">Productsoort</span>
              <MultiCombobox<number, IOphalenProductsoortenResultElement>
                sleutelExtractor={(x) => x.ProdSrtID}
                representatieFabriek={(x) => x.Naam}
                waarde={weergaveProps.data.length === 0 ? null : weergaveProps.data[0]}
                onWaardeChange={(x) => {
                  weergaveProps.onDataChange(x === null ? [] : [x]);
                  weergaveProps.toepassen();
                }}
                opties={productsoorten}
                kolommen={[
                  {
                    key: 'Naam',
                    label: 'Naam',
                    breedte: 250,
                  },
                ]}
                options={{
                  geenWaardeBericht: 'Laden...',
                }}
              />
            </div>
          );
        },
      },
      {
        naam: EFilter.Openbaar,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return <span>Alleen openbare modellen</span>;
        },
      },
    ],
    [productsoorten],
  );
  const [filterSchema, setFilterSchema] = useState(maakFilterSchema(urlState.filterdata));

  const ophalenProductmodellen = useCallback(async () => {
    const result = await api.v2.aanbod.productmodel.ophalenProductmodellen({
      filterSchema,
    });

    setModellen(result.modellen);
  }, [filterSchema]);

  useEffect(() => {
    ophalenProductmodellen();
  }, [ophalenProductmodellen]);

  useEffect(() => {
    const filterdata = urlState.filterdata.find((x) => x.naam === EFilter.ProductsoortIds)!;
    const ids = filterdata.data as number[];
    if (ids.length !== 0) {
      return;
    }
    const id = productsoorten.length === 0 ? null : productsoorten[0].ProdSrtID;
    if (id === null) {
      return;
    }

    setUrlStateSync('filterdata', [
      ...urlState.filterdata.filter((x) => x.naam !== EFilter.ProductsoortIds),
      {
        ...filterdata,
        data: [id],
      },
    ]);
  }, [productsoorten]);

  const sleutelExtractor = useCallback((row: IRow) => row.ProdModID, []);
  const kolommen = useMemo<TypedColumn<IRow>[]>(
    () => [
      {
        name: 'Modelcode',
        title: 'Code',
      },
      {
        name: 'Modelnaam',
        title: 'Naam',
      },
      {
        name: '__productmodelinfo' as any,
        title: ' ',
      },
      {
        name: '__productsoortnaamKort' as any,
        title: 'Cat.',
        getCellValue: (x) => {
          return x.productsoort.NaamKort;
        },
      },
      {
        name: 'Huismodel',
        title: 'Huismodel',
      },
      {
        name: 'GebruiktProduct',
        title: 'Gebruikt',
      },
      {
        name: 'Kenmerk',
        title: 'Kenmerken',
      },
      {
        name: '__aantalAfbeeldingen' as any,
        title: '# Afb.',
        getCellValue: (x) => x.bestanden.length,
      },
      {
        name: '__afbeeldingenVia' as any,
        title: 'Afb. via',
      },
      {
        name: '__specificatiesVia' as any,
        title: 'Specs. via',
      },
      {
        name: 'Actief',
        title: 'Actief',
      },
      {
        name: 'OpenbaarAanbod',
        title: 'Openbaar',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRow>[]>(
    () => [
      {
        columnName: 'Modelcode',
        width: 140,
      },
      {
        columnName: 'Modelnaam',
        width: 275,
      },
      {
        columnName: '__productsoortnaamKort' as any,
        width: 80,
      },
      {
        columnName: 'Huismodel',
        width: 110,
      },
      {
        columnName: 'GebruiktProduct',
        width: 100,
      },
      {
        columnName: 'Kenmerk',
        width: 275,
      },
      {
        columnName: '__aantalAfbeeldingen' as any,
        width: 85,
      },
      {
        columnName: '__afbeeldingenVia' as any,
        width: 110,
      },
      {
        columnName: '__specificatiesVia' as any,
        width: 125,
      },
      {
        columnName: 'Actief',
        width: 100,
      },
      {
        columnName: 'OpenbaarAanbod',
        width: 110,
      },
      {
        columnName: '__productmodelinfo' as any,
        width: 80,
      },
    ],
    [],
  );

  return (
    <ProductmodellenContext.Provider
      value={{
        onVerversenAangevraagd: async () => await ophalenProductmodellen(),
      }}
    >
      <Helmet>
        <title>Productmodellen</title>
      </Helmet>
      <div
        className="d-flex flex-column p-3"
        style={{
          backgroundColor: EKleur.HeelLichtGrijs,
          borderBottom: `1px solid ${EKleur.LichtGrijs}`,
        }}
      >
        <div className="d-flex">
          {/* <button
            className="btn btn-sm btn-light d-flex align-items-center ml-3"
            disabled={urlState.selectie.length !== 1}
            style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
            onClick={() => {}}
          >
            <IconV style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
            <span className="ml-2">Knop</span>
          </button> */}
          <FilterBalkV2
            filters={filters}
            filterData={urlState.filterdata}
            onFilterDataChange={(x) => setUrlStateSync('filterdata', x)}
            onFilterSchemaChange={(x) => setFilterSchema(x)}
          />
        </div>
      </div>

      {modellen === null ? (
        <div className="flex-fill d-flex align-items-center justify-content-center">
          <LoadingSpinner />
        </div>
      ) : (
        <GridStyleWrapper height={'calc(100vh - 150px)'}>
          <Grid rows={modellen} getRowId={sleutelExtractor} columns={kolommen}>
            <DataTypeProvider
              for={['Actief', 'OpenbaarAanbod']}
              formatterComponent={(formatterProps) => (
                <span style={{ color: formatterProps.value ? EKleur.Groen : EKleur.Rood }}>
                  {formatterProps.value ? 'Ja' : 'Nee'}
                </span>
              )}
            />

            <DataTypeProvider
              for={['GebruiktProduct']}
              formatterComponent={(formatterProps) => (
                <span>{formatterProps.value ? 'Ja' : 'Nee'}</span>
              )}
            />

            <DataTypeProvider
              for={[nameOf<IRow>('Huismodel')]}
              formatterComponent={(formatterProps) => (
                <span>{formatterProps.value ? 'Ja' : ''}</span>
              )}
            />

            <DataTypeProvider
              for={['__aantalAfbeeldingen']}
              formatterComponent={(formatterProps) => {
                const rij: IRow = formatterProps.row;
                return <span>{rij.bestanden.length !== 0 ? rij.bestanden.length : ''}</span>;
              }}
            />

            <DataTypeProvider
              for={['__productsoortnaamKort']}
              formatterComponent={(formatterProps) => {
                const rij: IRow = formatterProps.row;
                return <span>{rij.productsoort.NaamKort}</span>;
              }}
            />

            <DataTypeProvider
              for={['__afbeeldingenVia']}
              formatterComponent={(formatterProps) => {
                const rij: IRow = formatterProps.row;

                /*
                  0 = Geen,
                  1 = Automatisch bepalen (eerst model, anders type)
                  2 = De aan dit model gekoppelde afbeeldingen
                  3 = De afbeeldingen van het primair gekoppelde producttype
                */

                const via =
                  rij.AfbeeldingSet === 0
                    ? 'Geen'
                    : rij.AfbeeldingSet === 1
                    ? 'Model / Type'
                    : rij.AfbeeldingSet === 2
                    ? 'Model'
                    : rij.AfbeeldingSet === 3
                    ? 'Type'
                    : '';

                return <span>{via}</span>;
              }}
            />

            <DataTypeProvider
              for={['__specificatiesVia']}
              formatterComponent={(formatterProps) => {
                const rij: IRow = formatterProps.row;

                /*
                  0 = Geen,
                  1 = Automatisch bepalen (eerst model, anders type)
                  2 = De aan dit model gekoppelde afbeeldingen
                  3 = De afbeeldingen van het primair gekoppelde producttype
                */

                const via =
                  rij.SpecificatieSet === 0
                    ? 'Geen'
                    : rij.SpecificatieSet === 1
                    ? 'Model / Type'
                    : rij.SpecificatieSet === 2
                    ? 'Model'
                    : rij.SpecificatieSet === 3
                    ? 'Type'
                    : '';

                return <span>{via}</span>;
              }}
            />

            <DataTypeProvider
              for={['__productmodelinfo']}
              formatterComponent={(formatterProps) => {
                const rij: IRow = formatterProps.row;
                return (
                  <a
                    href="#"
                    onClick={async () =>
                      setUrlStateSync('productmodelInfoDialoogState', {
                        prodModID: rij.ProdModID,
                      })
                    }
                  >
                    <IconInformatie style={{ width: 15, height: 15, fill: EKleur.Blauw }} />
                  </a>
                );
              }}
            />

            <EditingState
              onAddedRowsChange={() => setUrlStateSync('toevoegenState', {})}
              onCommitChanges={async (changes) => {
                if (changes.deleted === undefined) {
                  return;
                }
                const deleted = changes.deleted;
                const id = deleted[deleted.length - 1] as number;

                const checkParams = { prodModIDs: [id] };

                const checkData = await api.v2.aanbod.productmodel.checkVerwijderenProductmodellen(
                  checkParams,
                );
                const checkResult = await checkStore.controleren({
                  checkData,
                });
                if (checkResult.type === EResultType.Annuleren) {
                  return;
                }

                if (
                  (
                    await checkStore.bevestigen({
                      inhoud: <span>Productmodel verwijderen?</span>,
                    })
                  ).type === EResultType.Annuleren
                ) {
                  return;
                }

                await api.v2.aanbod.productmodel.verwijderenProductmodellen(checkParams);

                ophalenProductmodellen();
              }}
              onEditingRowIdsChange={(rowIds) => {
                const id = rowIds[rowIds.length - 1] as number;
                setUrlStateSync('wijzigenState', {
                  prodModID: id,
                });
                // alert(id);
              }}
            />

            <RowDetailState
              expandedRowIds={urlState.uitgeklapt}
              onExpandedRowIdsChange={(x) => setUrlStateSync('uitgeklapt', x as number[])}
            />

            <SortingState defaultSorting={[]} />
            <IntegratedSorting />

            <VirtualTable />
            <VirtualTable messages={geenData} />
            <TableColumnResizing defaultColumnWidths={kolomBreedtes} />

            <TableEditColumn
              width={65}
              showEditCommand
              showDeleteCommand
              showAddCommand
              cellComponent={DXTableEditColumnCellComponent}
              commandComponent={DXTableEditColumnCommandComponent}
            />
            <TableHeaderRow showSortingControls />
            <TableRowDetail
              contentComponent={DetailRegel}
              toggleCellComponent={DXTableToggleCellComponent}
            />
            <SelectionState
              selection={urlState.selectie}
              onSelectionChange={(x) => setUrlStateSync('selectie', x as number[])}
            />
            <TableSelection cellComponent={DXTableCheckboxComponent} />
          </Grid>
        </GridStyleWrapper>
      )}
      {urlState.toevoegenState !== null &&
        (() => {
          return (
            <MuterenDialoog
              open
              onSuccess={async (result) => {
                const params: IToevoegenProductmodelParams = {
                  aantalGebruikersMax:
                    result.aantalGebruikersMax !== 0 ? result.aantalGebruikersMax : null,
                  aantalGebruikersTarief:
                    result.aantalGebruikersTarief !== 0 ? result.aantalGebruikersTarief : null,
                  actief: result.actief,
                  afbeeldingSet: Boolean(result.afbeeldingSet),
                  specificatieSet: Boolean(result.specificatieSet),
                  afbeeldingen: [],
                  gebruiktProduct: result.gebruiktProduct, // TODO,
                  huismodel: result.huismodel, // TODO,
                  kenmerk: result.kenmerk,
                  merkID: result.merkID,
                  modelcode: result.modelcode,
                  modelnaam: result.modelnaam,
                  prodSrtID: result.prodSrtID,
                  producttypen: [],
                  kenmerken: [],
                };
                if (
                  (
                    await checkStore.controleren({
                      checkData: await api.v2.aanbod.productmodel.checkToevoegenProductmodel(
                        params,
                      ),
                    })
                  ).type === EResultType.Annuleren
                ) {
                  return;
                }

                await api.v2.aanbod.productmodel.toevoegenProductmodel(params);

                await ophalenProductmodellen();
                setUrlStateSync('toevoegenState', null);
              }}
              onAnnuleren={() => setUrlStateSync('toevoegenState', null)}
            />
          );
        })()}
      {urlState.wijzigenState !== null &&
        modellen !== null &&
        (() => {
          const prodModID = urlState.wijzigenState!.prodModID;
          const productmodel = modellen.find((x) => x.ProdModID === prodModID);

          if (productmodel === undefined) {
            return null;
          }

          return (
            <MuterenDialoog
              open
              onSuccess={async (result) => {
                const tekstIDs = productmodel.kenmerken.map((x) => x.Kenmerk_TekstID);
                const kenmerkenTeksten =
                  tekstIDs.length === 0
                    ? null
                    : await api.v2.tekst.ophalenTekstenInAlleTalen({
                        tekstIDs,
                      });

                const params: IWijzigenProductmodelParams = {
                  aantalGebruikersMax:
                    result.aantalGebruikersMax !== 0 ? result.aantalGebruikersMax : null,
                  aantalGebruikersTarief:
                    result.aantalGebruikersTarief !== 0 ? result.aantalGebruikersTarief : null,
                  actief: result.actief,
                  openbaarAanbod: result.openbaarAanbod,
                  afbeeldingSet: result.afbeeldingSet as number,
                  specificatieSet: result.specificatieSet as number,
                  afbeeldingen: productmodel.bestandenVoorModel.map((value, index) => ({
                    bestandID: value.bestand.ID,
                    sortNr: index + 1,
                  })),
                  gebruiktProduct: result.gebruiktProduct,
                  huismodel: result.huismodel,
                  kenmerk: result.kenmerk,
                  energielabelID: result.energielabelID,
                  merkID: result.merkID,
                  modelcode: result.modelcode,
                  modelnaam: result.modelnaam,
                  prodModID: productmodel.ProdModID,
                  prodSrtID: result.prodSrtID,
                  producttypen: productmodel.producttypen.map((x) => ({
                    typeID: x.TypeID,
                    prioNr: x.PrioNr,
                  })),
                  kenmerken:
                    kenmerkenTeksten === null
                      ? []
                      : productmodel.kenmerken.map((x) => ({
                          prodModKenID: x.ProdModKenID,
                          sortNr: x.SortNr,
                          opOverzicht: x.OpOverzicht,
                          teksten: kenmerkenTeksten.teksten
                            .filter((tekst) => tekst.TekstID === x.Kenmerk_TekstID)
                            .map((tekst) => ({
                              taalID: tekst.TaalID,
                              tekst: tekst.Tekst || '',
                              toepassen: tekst.Toepassen,
                            })),
                        })),
                  modelnaamExtern: result.modelnaamExtern,
                  modelinfoExtern: result.modelinfoExtern,
                  toelichting: result.toelichting,
                  slug: result.slug,
                  titel: result.titel,
                  metadata: result.metadata,
                };
                if (
                  (
                    await checkStore.controleren({
                      checkData: await api.v2.aanbod.productmodel.checkWijzigenProductmodel(params),
                    })
                  ).type === EResultType.Annuleren
                ) {
                  return;
                }

                await api.v2.aanbod.productmodel.wijzigenProductmodel(params);
                await ophalenProductmodellen();
                setUrlStateSync('wijzigenState', null);
              }}
              onAnnuleren={() => setUrlStateSync('wijzigenState', null)}
              initialValues={{
                afbeeldingSet: productmodel.AfbeeldingSet as EAfbeeldingSet,
                specificatieSet: productmodel.SpecificatieSet as ESpecificatieSet,
                modelnaam: productmodel.Modelnaam,
                kenmerk: productmodel.Kenmerk,
                actief: productmodel.Actief,
                openbaarAanbod: productmodel.OpenbaarAanbod,
                prodSrtID: productmodel.ProdSrtID,
                gebruiktProduct: productmodel.GebruiktProduct,
                huismodel: productmodel.Huismodel,
                merkID: productmodel.MerkID,
                modelcode: productmodel.Modelcode,
                modelnaamExtern: productmodel.modelnaamExtern,
                modelinfoExtern: productmodel.modelinfoExtern,
                slug: productmodel.slug,
                titel: productmodel.titel,
                metadata: productmodel.metadata,
                aantalGebruikersMax: productmodel.AantalGebruikersMax ?? 0,
                aantalGebruikersTarief: productmodel.AantalGebruikersTarief ?? 0,
                energielabelID: productmodel.EnergielabelID,
                toelichting: productmodel.toelichting,
              }}
            />
          );
        })()}

      {urlState.productmodelInfoDialoogState !== null && (
        <ProductmodelInfoDialoog
          open
          prodModID={urlState.productmodelInfoDialoogState.prodModID}
          onSuccess={() => setUrlStateSync('productmodelInfoDialoogState', null)}
          onAnnuleren={() => setUrlStateSync('productmodelInfoDialoogState', null)}
        />
      )}
    </ProductmodellenContext.Provider>
  );
};

const WithProductsoortenWrapper = (props: IProps) => {
  const [productsoorten, setProductsoorten] = useState<
    IRemoteData<IOphalenProductsoortenResultElement[]>
  >(createPendingRemoteData());

  const ophalenProductsoorten = useCallback(async () => {
    const result = await api.v2.product.soort.ophalenProductsoorten({
      filterSchema: { filters: [] },
    });
    setProductsoorten(createReadyRemoteData(result));
  }, []);
  useEffect(() => {
    ophalenProductsoorten();
  }, []);

  if (productsoorten.state === ERemoteDataState.Pending) {
    return null;
  }

  return <Productmodellen productsoorten={productsoorten.data!} {...props} />;
};

export default withRouter(WithProductsoortenWrapper);
