import React, { useContext, useEffect, useMemo, useState, useCallback } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import useUrlState from '../../../../core/useUrlState';
import api from '../../../../api';
import { addMonths, format } from 'date-fns';
import _ from 'lodash';
import {
  IOphalenContractenResultElement,
  IOphalenContractenResultElementV2,
} from '../../../../../../shared/src/api/v2/contract';
import { IOphalenRelatiesResultElementV2 } from '../../../../../../shared/src/api/v2/relatie';
import MenuLayout from '../../../../components/MenuLayout';
import {
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import { DataTypeProvider, RowDetailState } from '@devexpress/dx-react-grid';
import RelatieVisualisatie from '../../../../components/personalia/RelatieVisualisatie';
import { EContractstatus } from '../../../../bedrijfslogica/enums';

interface IProps extends RouteComponentProps {}

interface IUrlState {}

const defaultUrlState: IUrlState = {};

export interface IRegel extends IOphalenRelatiesResultElementV2 {}

const MetFactuurkenmerk: React.FC<IProps> = observer((props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);

  const [contracten, setContracten] = useState<IOphalenContractenResultElementV2[] | null>(null);

  const [relaties, setRelaties] = useState<IRegel[] | null>(null);

  const ophalenContracten = useCallback(async () => {
    const contracten = (
      await api.v2.contract.ophalenContractenV2({
        filterSchema: {
          filters: [
            { naam: 'MET_FACTUURKENMERK', data: true },
            { naam: 'STATUS_NAAM_ENUMS', data: [EContractstatus.Lopend] },
          ],
        },
        orderSchema: {
          orders: [
            {
              naam: 'INGANGSDATUM',
              richting: 'DESC',
            },
          ],
        },
      })
    ).contracten;

    setContracten(contracten);
  }, []);

  useEffect(() => {
    ophalenContracten();
  }, [ophalenContracten]);

  const ophalenRelaties = useCallback(async () => {
    if (contracten === null) {
      return;
    }

    const relatiesResult = (
      await api.v2.relatie.ophalenRelaties({
        filterSchema: {
          filters: [{ naam: 'IDS', data: contracten.map((x) => x.RelID) }],
        },
        // orderSchema: {
        //   orders: [
        //     {
        //       naam: 'RECORD_TOEGEVOEGD',
        //       richting: 'DESC',
        //     },
        //   ],
        // },
      })
    ).relaties;

    const relaties = relatiesResult.map((x) => {
      return { ...x };
    });

    const relatiesGesorteerd = _.orderBy(relaties, ['aantalContracten'], ['desc']);

    setRelaties(relatiesGesorteerd);
  }, [contracten]);

  useEffect(() => {
    ophalenRelaties();
  }, [ophalenRelaties]);

  const keyExtractor = useCallback((row: IRegel) => row.RelID, []);

  const kolommen = useMemo<TypedColumn<IRegel>[]>(
    () => [
      {
        name: 'Relatienummer',
        title: 'Rel.nr',
      },
      {
        name: '__relatie' as any,
        title: 'Relatie',
      },
    ],
    [],
  );

  const kolombreedtes = useMemo<TypedTableColumnWidthInfo<IRegel>[]>(
    () => [
      {
        columnName: 'Relatienummer',
        width: 110,
      },
      {
        columnName: '__relatie' as any,
        width: 250,
      },
    ],
    [],
  );

  return (
    <>
      {relaties === null ? (
        <LoadingSpinner />
      ) : (
        <div className="d-flex">
          <MenuLayout
            menu={
              <div className="d-flex">
                <></>
              </div>
            }
            body={
              <GridStyleWrapper height={'calc(100vh - 100px)'}>
                <Grid rows={relaties} getRowId={keyExtractor} columns={kolommen}>
                  <DataTypeProvider
                    for={['__relatie']}
                    formatterComponent={(formatterProps) => {
                      const rij = formatterProps.row as IRegel;
                      return <RelatieVisualisatie relID={rij.RelID} />;
                    }}
                  />

                  <DataTypeProvider
                    for={['RecordToegevoegd']}
                    formatterComponent={(formatterProps) => {
                      const rij = formatterProps.row as IRegel;
                      const datum = new Date(rij.RecordToegevoegd!);
                      return <span>{format(datum, 'dd-MM-yyyy HH:mm:ss')}</span>;
                    }}
                  />

                  {/* <SortingState defaultSorting={[]} />
              <IntegratedSorting /> */}

                  <RowDetailState defaultExpandedRowIds={[]} />

                  <VirtualTable />
                  <TableColumnResizing defaultColumnWidths={kolombreedtes} />

                  <TableHeaderRow />
                  {/* <TableRowDetail
                    contentComponent={DetailComp}
                    toggleCellComponent={DXTableToggleCellComponent}
                  /> */}

                  {/* <SelectionState
                    selection={props.journaalpostregelsSelectie}
                    onSelectionChange={(x) =>
                      props.onJournaalpostregelsSelectieChange(x as number[])
                    }
                  /> */}

                  {/* <TableSelection cellComponent={DXTableCheckboxComponent} /> */}
                </Grid>
              </GridStyleWrapper>
            }
          />
        </div>
      )}
    </>
  );
});

export default withRouter(MetFactuurkenmerk);
