import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import IDialoogProps from '../../../core/IDialoogProps';
import useUrlState from '../../../core/useUrlState';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import Dialoog from '../../dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import { Field, FieldProps, Formik, FormikProps } from 'formik';
import ModalBody from 'react-bootstrap/ModalBody';
import nameOf from '../../../core/nameOf';
import ContactSelectie, { EType } from '../../formulier/ContactSelectie';
import VeldWeergave from '../../formulier/VeldWeergave';
import RadioKnop from '../../formulier/RadioKnop';
import ModalFooter from 'react-bootstrap/ModalFooter';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../models/IRemoteData';
import { IOphalenPersonenResultElementV2 } from '../../../../../shared/src/api/v2/persoon/persoon';
import api from '../../../api';
import { EResultType } from '../../../stores/CheckStore';
import { RootStoreContext } from '../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import * as Yup from 'yup';
import FormikVeldFout from '../../formulier/FormikVeldFout';
import LoadingSpinner from '../../Gedeeld/LoadingSpinner';
import MultiCombobox, { IKolom } from '../../formulier/MultiCombobox';
import { IOphalenDienstenResultElement } from '../../../../../shared/src/api/v2/dienst/service';

export interface IUrlState {
  selectie: number[];
}

const defaultUrlState: IUrlState = {
  selectie: [],
};

const urlStateKey = 'documentenSelectie';

interface IFormikValues {
  persID: number | null;
  verzendwijze: string;
  uitvoerendeServicedienstID: number | null;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  persID: 'Persoon',
  verzendwijze: 'Verzendwijze',
  uitvoerendeServicedienstID: 'Uitvoerende servicedienst',
};

interface IDialoogResult {}

interface IProps extends IDialoogProps<IDialoogResult>, RouteComponentProps {
  relID?: number;
  persID?: number;
}

const VersturenContactgegevensDialoog = observer((props: IProps) => {
  const { checkStore } = useContext(RootStoreContext);
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState, urlStateKey);

  const [primairPersoonPersID, setPrimairPersoonPersID] = useState<IRemoteData<number | null>>(
    createPendingRemoteData(),
  );

  const [servicediensten, setServicediensten] = useState<IOphalenDienstenResultElement[] | null>(
    null,
  );

  useEffect(() => {
    if (props.relID === undefined) {
      setPrimairPersoonPersID(createReadyRemoteData(null));
      return;
    }

    api.v2.relatie
      .ophalenRelaties({
        filterSchema: {
          filters: [
            {
              naam: 'IDS',
              data: [props.relID],
            },
          ],
        },
      })
      .then((result) => {
        const persID = result.relaties[0].PersoonPrimair_PersID;
        setPrimairPersoonPersID(createReadyRemoteData(persID));
      });
  }, [props.relID]);

  useEffect(() => {
    (async () => {
      const result = await api.v2.dienst.service.ophalenDiensten({
        filterSchema: {
          filters: [
            { naam: 'IS_ACTIEF', data: true },
            { naam: 'IS_UITVOEREND', data: true },
          ],
        },
        // orderSchema: { orders: [{ naam: 'NAAM', richting: 'ASC' }] },
      });
      const diensten = result.diensten;

      setServicediensten(diensten);
    })();
  }, []);

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (primairPersoonPersID.state === ERemoteDataState.Pending || servicediensten === null) {
      return null;
    }

    return {
      persID: props.persID ?? primairPersoonPersID.data,
      verzendwijze: 'EMAIL',
      uitvoerendeServicedienstID: null,
    };
  }, [primairPersoonPersID.data, props.persID, servicediensten]);

  const handleSubmit = useCallback(
    async (values: IFormikValues) => {
      if (
        (
          await checkStore.bevestigen({
            inhoud: 'Contactgegevens versturen?',
          })
        ).type === EResultType.Annuleren
      ) {
        return;
      }

      const params = {
        servDienstID: values.uitvoerendeServicedienstID!,
        persID: values.persID!,
        kanaal: values.verzendwijze,
      };

      await api.v2.service.versturenContactgegevensNaarRelatie(params);

      props.onSuccess({});
    },
    [props.relID, urlState.selectie],
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        persID: Yup.number().required('Geen persoon geselecteerd'),
        uitvoerendeServicedienstID: Yup.number().required(),
      }),
    [],
  );

  const ladenVisualisatie = useMemo(() => {
    return (
      <ModalBody>
        <LoadingSpinner />
      </ModalBody>
    );
  }, []);

  return (
    <Dialoog index={props.dialoogIndex ?? 0} modalProps={{ size: 'lg' }}>
      <ModalHeader>
        <ModalTitle>Contactgegevens versturen</ModalTitle>
      </ModalHeader>

      {initialValues === null ? (
        ladenVisualisatie
      ) : (
        <Formik<IFormikValues>
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
          render={(formikProps) => {
            return false ? (
              ladenVisualisatie
            ) : (
              <Formulier
                relID={props.relID}
                formikProps={formikProps}
                servicediensten={servicediensten!}
                uitvoerendeServicedienstID={null}
                onAnnuleren={props.onAnnuleren}
              />
            );
          }}
        />
      )}
    </Dialoog>
  );
});

interface IFormulierProps {
  relID?: number;
  formikProps: FormikProps<IFormikValues>;
  servicediensten: IOphalenDienstenResultElement[];
  uitvoerendeServicedienstID: number | null;
  onAnnuleren: () => void;
}

const Formulier = (props: IFormulierProps) => {
  const [persoon, setPersoon] = useState<IRemoteData<IOphalenPersonenResultElementV2>>(
    createPendingRemoteData(),
  );

  useEffect(() => {
    if (props.formikProps.values.persID === null) {
      if (persoon.state === ERemoteDataState.Ready) {
        setPersoon(createPendingRemoteData());
      }
      return;
    }

    api.v2.persoon
      .ophalenPersonen({
        filterSchema: {
          filters: [
            {
              naam: 'IDS',
              data: [props.formikProps.values.persID],
            },
          ],
        },
      })
      .then((result) => {
        const persoon = result.personen[0];
        setPersoon(createReadyRemoteData(persoon));
      });
  }, [props.formikProps.values.persID]);

  const servicedienstenKolommen = useMemo<IKolom<IOphalenDienstenResultElement>[]>(
    () => [
      {
        key: 'Naam',
        label: 'Naam',
        breedte: 150,
      },
    ],
    [],
  );

  return (
    <>
      <ModalBody>
        <div className="row">
          <div className="col-12">
            <label>{veldnamen.persID}</label>
            <Field
              name={nameOf<IFormikValues>('persID')}
              render={(fieldProps: FieldProps<IFormikValues>) => {
                const { field, form } = fieldProps;

                return (
                  <>
                    <ContactSelectie
                      value={field.value === null ? null : { persID: field.value, orgID: null }}
                      onChange={(value) => {
                        form.setFieldValue(field.name, value?.persoon?.PersID ?? null);
                      }}
                      alleenVoorRelIDs={props.relID !== undefined ? [props.relID] : undefined}
                      options={{
                        bijEnkeleDirectVoorselecteren: true,
                        types: [EType.Persoon],
                      }}
                    />
                    <FormikVeldFout fieldProps={fieldProps} />
                  </>
                );
              }}
            />
          </div>

          {persoon.state === ERemoteDataState.Ready && (
            <div className="col-12 mt-3">
              <VeldWeergave>Taal {persoon.data!.taal.Taal}</VeldWeergave>
            </div>
          )}

          <div className="col-12 mt-3">
            <div className="d-flex justify-content-between align-items-center">
              <label>{veldnamen.uitvoerendeServicedienstID}</label>
              {/* <div className="d-flex">
                <Field
                  name={veldnamen.meerDiensten}
                  render={(fieldProps: FieldProps<IFormikValues>) => (
                    <>
                      <VinkVeld
                        aangevinkt={fieldProps.field.value}
                        onGewijzigd={async (x) => {
                          fieldProps.form.setFieldValue(fieldProps.field.name, x);
                          setMeerDiensten(x);
                        }}
                      />
                      <div className="ml-2">{veldnamen.meerDiensten}</div>
                      <FormikVeldFout fieldProps={fieldProps} directTonen={hasSubmitted} />
                    </>
                  )}
                />
              </div> */}
            </div>
            <Field
              name={nameOf<IFormikValues>('uitvoerendeServicedienstID')}
              render={(fieldProps: FieldProps<IFormikValues>) => {
                const { field, form } = fieldProps;

                if (props.servicediensten === null) {
                  return <LoadingSpinner />;
                }
                return (
                  <MultiCombobox<number, any>
                    sleutelExtractor={(x) => x.ID}
                    representatieFabriek={(x) => `${x.Naam}`}
                    waarde={field.value}
                    onWaardeChange={(x) => form.setFieldValue(field.name, x)}
                    // opties={props.servicediensten}
                    kolommen={servicedienstenKolommen}
                    opties={props.servicediensten.map((x) => {
                      return { ID: x.ID, Naam: x.relatie!.weergavenaam };
                    })}
                  />
                );
              }}
            />
          </div>

          <div className="col-12 mt-4 mb-2 d-flex">
            <label>{veldnamen.verzendwijze}</label>
            <Field
              name="verzendwijze"
              render={(fieldProps: FieldProps<IFormikValues>) => {
                const { field, form } = fieldProps;
                return (
                  <>
                    <div className="d-flex" style={{ width: 250 }}>
                      <span className="d-flex align-items-center ml-3">
                        <RadioKnop
                          aangevinkt={field.value === 'EMAIL'}
                          onAangevinkt={() => form.setFieldValue(field.name, 'EMAIL')}
                        />
                        Email
                      </span>
                      <span className="d-flex align-items-center ml-3">
                        <RadioKnop
                          aangevinkt={field.value === 'SMS'}
                          onAangevinkt={() => form.setFieldValue(field.name, 'SMS')}
                        />
                        SMS
                      </span>
                    </div>

                    <FormikVeldFout fieldProps={fieldProps} />
                  </>
                );
              }}
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter className="d-flex flex-row justify-content-start">
        <button
          className="btn btn-primary"
          onClick={props.formikProps.submitForm}
          style={{ width: 100 }}
        >
          Ok
        </button>
        <button className="btn btn-secondary" onClick={props.onAnnuleren} style={{ width: 100 }}>
          Annuleren
        </button>
      </ModalFooter>
    </>
  );
};

export default withRouter(VersturenContactgegevensDialoog);
