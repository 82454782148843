import { EIdentiteitType, Identiteit, IRelatieIdentiteit } from './types';
import IEmailGeadresseerde from '../../../../../shared/src/models/email/IEmailGeadresseerde';
import IRemoteData, { ERemoteDataState } from '../../../models/IRemoteData';
import { IOphalenRelPersResultElement } from '../../../../../shared/src/api/v2/relatie';
import * as _ from 'lodash';
import { IEmail } from '../../../../../shared/src/api/v2/email-v2';

export const geadresseerdeNaarIdentiteiten = (geadresseerde: IEmailGeadresseerde): Identiteit[] => {
  const identiteiten: Identiteit[] = [];

  if (geadresseerde.persID !== null && geadresseerde.persID !== undefined) {
    identiteiten.push({
      type: EIdentiteitType.Persoon,
      persID: geadresseerde.persID,
    });
  }
  if (geadresseerde.orgID !== null && geadresseerde.orgID !== undefined) {
    identiteiten.push({
      type: EIdentiteitType.Organisatie,
      orgID: geadresseerde.orgID,
    });
  }

  if (identiteiten.length === 0) {
    identiteiten.push({
      type: EIdentiteitType.EmailGeadresseerde,
      emailAdres: geadresseerde.emailAdres,
    });
  }

  return identiteiten;
};

export const identiteitTypeSignificantie: Record<EIdentiteitType, number> = {
  [EIdentiteitType.Relatie]: 4,
  [EIdentiteitType.Persoon]: 3,
  [EIdentiteitType.Organisatie]: 2,
  [EIdentiteitType.EmailGeadresseerde]: 1,
};

export const genereerIdentiteitenVanEmail = (
  email: IEmail,
  accountEmailAdres: string,
  relPersCache: Record<number, IRemoteData<IOphalenRelPersResultElement[]>>,
): Identiteit[] => {
  const emailGeadresseerden: IEmailGeadresseerde[] = [
    email.van,
    ...email.aan,
    ...email.cc,
    ...email.bcc,
  ]
    .map((x) => ({
      emailAdres: x.Email,
      persID: x.PersID,
      orgID: x.OrgID,
    }))
    .filter((x) => x.emailAdres !== accountEmailAdres);

  const relIDsUitRelPers: number[] = _.uniq(
    emailGeadresseerden.flatMap((geadresseerde) => {
      if (
        geadresseerde.persID !== null &&
        geadresseerde.persID !== undefined &&
        relPersCache[geadresseerde.persID] !== undefined &&
        relPersCache[geadresseerde.persID].state === ERemoteDataState.Ready
      ) {
        return relPersCache[geadresseerde.persID]
          .data!.filter((x) => !x.IsRelatie)
          .map((relPers) => relPers.RelID);
      }
      return [];
    }),
  );

  const relatieIdentiteitenRelIDs: number[] = _.uniq([
    ...email.relCtxXEmailBers.map((x) => x.RelID),
    ...relIDsUitRelPers,
  ]);

  const relatieIdentiteiten = relatieIdentiteitenRelIDs.map(
    (relID) =>
      ({
        type: EIdentiteitType.Relatie,
        relID,
      } as IRelatieIdentiteit),
  );
  const geadresseerdenIdentiteiten = emailGeadresseerden.flatMap(geadresseerdeNaarIdentiteiten);

  return [...relatieIdentiteiten, ...geadresseerdenIdentiteiten].sort((lhs, rhs) => {
    const lhsSignificantie = identiteitTypeSignificantie[lhs.type];
    const rhsSignificantie = identiteitTypeSignificantie[rhs.type];
    return rhsSignificantie - lhsSignificantie;
  });
};
