import React, { useContext, useMemo } from 'react';
import UitklapFormulier from '../../../../components/formulier/UitklapFormulier';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import useUrlState from '../../../../core/useUrlState';
import { Field, FieldProps } from 'formik';
import nameOf from '../../../../core/nameOf';
import { IFormikValues, InstellingenContext } from '../index';
import MultiCombobox, { IKolom } from '../../../../components/formulier/MultiCombobox';
import { IOphalenDienstenResultElement } from '../../../../../../shared/src/api/v2/dienst/sms/sms';
import RelatieVisualisatie from '../../../../components/personalia/RelatieVisualisatie';
import VinkVeld from '../../../../components/formulier/VinkVeld';
import NumeriekVeld from '../../../../components/formulier/NumeriekVeld';
import { IOphalenMagazijnenResultElement } from '../../../../../../shared/src/api/v2/magazijn';
import { IOphalenVerzendwijzenResultElement } from '../../../../../../shared/src/api/v2/factuur';
import Combobox from '../../../../components/formulier/Combobox';
import { IMailMap, IOphalenMailMappenResult } from '../../../../../../shared/src/api/v2/email-v2';
import gebruikers from '../../../../api/v2/autorisatie/rolXAspGebrs';
import { IOphalenEmailAccountsResultElement } from '../../../../../../shared/src/api/v2/dienst/email/email';
import BedragInput from '../../../../components/formulier/BedragInput';

export interface IUrlState {
  algemeenUitgeklapt: boolean;
  dienstenUitgeklapt: boolean;
  transportUitgeklapt: boolean;
  financieelUitgeklapt: boolean;
  openingstijdUitgeklapt: boolean;
  debiteurUitgeklapt: boolean;
  externeDataUitgeklapt: boolean;
  bulkberichtenUitgeklapt: boolean;
  bankzakenUitgeklapt: boolean;
  telefonieUitgeklapt: boolean;
  whatsappUitgeklapt: boolean;
  loonadministratie: boolean;
}

export const defaultUrlState: IUrlState = {
  algemeenUitgeklapt: false,
  dienstenUitgeklapt: false,
  transportUitgeklapt: false,
  financieelUitgeklapt: false,
  openingstijdUitgeklapt: false,
  debiteurUitgeklapt: false,
  externeDataUitgeklapt: false,
  bulkberichtenUitgeklapt: false,
  bankzakenUitgeklapt: false,
  telefonieUitgeklapt: false,
  whatsappUitgeklapt: false,
  loonadministratie: false,
};

interface IProps extends RouteComponentProps {}

interface IMailMapOptie {
  ID: number;
  Weergavenaam: string;
  Gebruikersnaam: string;
  EmailGebrID: number;
}

const Applicatie: React.ComponentType<IProps> = (props) => {
  const context = useContext(InstellingenContext);
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);

  const smsDienstKolommen = useMemo<IKolom<IOphalenDienstenResultElement>[]>(() => {
    return [
      {
        key: 'Naam',
        label: 'Naam',
        breedte: 150,
      },
      {
        key: 'RelID',
        label: 'Relatie',
        breedte: 250,
        formatFabriek: (x) => <RelatieVisualisatie relID={x.RelID} />,
      },
    ];
  }, []);

  const magazijnKolommen = useMemo<IKolom<IOphalenMagazijnenResultElement>[]>(() => {
    return [
      {
        key: 'NaamKort',
        label: 'Naam',
        breedte: 150,
      },
      // {
      //   key: 'PlaatsNaam',
      //   label: 'Plaats',
      //   breedte: 200,
      // },
    ];
  }, []);

  const factuurVerzendwijzeKolommen = useMemo<IKolom<IOphalenVerzendwijzenResultElement>[]>(() => {
    return [
      {
        key: 'Naam',
        label: 'Naam',
        breedte: 150,
      },
    ];
  }, []);

  const mailMapKolommen = useMemo<IKolom<IMailMapOptie>[]>(() => {
    return [
      {
        key: 'Weergavenaam',
        label: 'Weergavenaam',
        breedte: 150,
      },
      {
        key: 'Gebruikersnaam',
        label: 'Gebruikersnaam',
        breedte: 150,
      },
    ];
  }, []);

  const emailGebrMapKolommen = useMemo<IKolom<IOphalenEmailAccountsResultElement>[]>(() => {
    return [
      {
        key: 'GebruikersNaam',
        label: 'Gebruikersnaam',
        breedte: 225,
      },
    ];
  }, []);

  const vindGebruikersnaam = (EmailGebrID: number) => {
    if (context.gebruikersResult == null) {
      return '';
    }
    const gebruiker = context.gebruikersResult.find((g) => g.EmailGebrID == EmailGebrID);
    if (gebruiker == null) {
      return '';
    }
    return gebruiker.GebruikersNaam;
  };

  const getMailmapOpties = useMemo<IMailMapOptie[]>(() => {
    if (context.mailmappenResult == null) {
      return [];
    }
    const result = context.mailmappenResult.map((m) => {
      return {
        ID: m.ID,
        Weergavenaam: m.Weergavenaam,
        Gebruikersnaam: vindGebruikersnaam(m.EmailGebrID),
        EmailGebrID: m.EmailGebrID,
      };
    });
    result.sort((a, b) => (a.Weergavenaam > b.Weergavenaam ? -1 : 1));
    result.sort((a, b) => (a.Gebruikersnaam < b.Gebruikersnaam ? -1 : 1));
    return result;
  }, [context]);

  return (
    <div className="p-3" style={{ overflowY: 'auto', height: 0, flex: '1 1 auto' }}>
      <UitklapFormulier
        uitgeklapt={urlState.algemeenUitgeklapt}
        onUitgeklaptChange={(x) => setUrlStateSync('algemeenUitgeklapt', x)}
        label="Algemeen"
        formulier={
          <div className="row">
            <div className="col-12 d-flex align-items-center">
              <Field
                name={nameOf<IFormikValues>('bestellingenDirectVerwerken')}
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;

                  return (
                    <VinkVeld
                      aangevinkt={field.value}
                      onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                    />
                  );
                }}
              />
              <span className="ml-2">Bestellingen direct verwerken</span>
            </div>

            <div className="col-12 d-flex align-items-center mt-3">
              <Field
                name={nameOf<IFormikValues>('krediettoetsBijBestelling')}
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;

                  return (
                    <VinkVeld
                      aangevinkt={field.value}
                      onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                    />
                  );
                }}
              />
              <span className="ml-2">Automatisch krediettoets bij bestelling</span>
            </div>

            <div className="col-12 d-flex align-items-center mt-3">
              <Field
                name={nameOf<IFormikValues>('krediettoetsBijRegistratie')}
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;

                  return (
                    <VinkVeld
                      aangevinkt={field.value}
                      onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                    />
                  );
                }}
              />
              <span className="ml-2">Automatisch krediettoets bij registratie</span>
            </div>

            <div className="col-12 d-flex align-items-center mt-3">
              <Field
                name={nameOf<IFormikValues>('contractwisselToestaanWebsite')}
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;

                  return (
                    <VinkVeld
                      aangevinkt={field.value}
                      onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                    />
                  );
                }}
              />
              <span className="ml-2">Contractwissel toestaan op de website</span>
            </div>

            <div className="col-12 d-flex align-items-center mt-3">
              <Field
                name={nameOf<IFormikValues>('betalingsregelingViaWebsiteToestaan')}
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;

                  return (
                    <VinkVeld
                      aangevinkt={field.value}
                      onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                    />
                  );
                }}
              />
              <span className="ml-2">
                Betalingsregeling via website toestaan (kan ook per relatie worden ingesteld)
              </span>
            </div>

            <div className="col-12 d-flex align-items-center mt-3">
              <Field
                name={nameOf<IFormikValues>('whatsAppBerAutReactie')}
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;

                  return (
                    <VinkVeld
                      aangevinkt={field.value}
                      onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                    />
                  );
                }}
              />
              <span className="ml-2">
                Automatisch reactie geven op inkomende WhatsApp-berichten
              </span>
            </div>

            <div className="col-12 d-flex align-items-center mt-3">
              <Field
                name={nameOf<IFormikValues>('eenmaligeKostenBijWissel')}
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;

                  return (
                    <VinkVeld
                      aangevinkt={field.value}
                      onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                    />
                  );
                }}
              />
              <span className="ml-2">Eenmalige kosten bij contractwissel toepassen</span>
            </div>

            <div className="col-12">
              <div className="col-3 mt-3">
                <label>Automatisch fiatteren overnamecontracten</label>
                <Field
                  name={nameOf<IFormikValues>('automatischFiatterenOvernameContracten')}
                  render={(fieldProps: FieldProps<IFormikValues>) => {
                    const { field, form } = fieldProps;

                    const opties = [
                      { id: 0, label: 'Niet' },
                      { id: 1, label: 'Voorwaardelijk' },
                      { id: 2, label: 'Onvoorwaardelijk' },
                    ];

                    return (
                      <Combobox
                        geselecteerd={fieldProps.field.value}
                        opties={opties}
                        onSelectieChange={(id) => {
                          fieldProps.form.setFieldValue(fieldProps.field.name, id);
                        }}
                      />
                    );
                  }}
                />
              </div>
            </div>

            <div className="col-12">
              <div className="col-6 mt-3">
                <label>
                  Uit te sluiten termen in achternaam bij bepalen wel/niet automatisch fiatteren
                  overname (scheiden door ;)
                </label>
                <Field
                  name={nameOf<IFormikValues>('termenUitsluitenInAchternaam')}
                  render={(fieldProps: FieldProps<IFormikValues>) => {
                    const { field, form } = fieldProps;

                    return (
                      <textarea
                        {...field}
                        className="form-control"
                        rows={1}
                        placeholder="Bijv. commissie;woon;huisoudste"
                      />
                    );
                  }}
                />
              </div>
            </div>

            <div className="col-12">
              <div className="col-6 mt-3">
                <label>Te signaleren voorvoegsels in achternaam (scheiden door ;)</label>
                <Field
                  name={nameOf<IFormikValues>('voorvoegselsInAchternaam')}
                  render={(fieldProps: FieldProps<IFormikValues>) => {
                    const { field, form } = fieldProps;

                    return (
                      <textarea
                        {...field}
                        className="form-control"
                        rows={1}
                        placeholder="Bijv. van;de;van der"
                      />
                    );
                  }}
                />
              </div>
            </div>

            <div className="col-12">
              <div className="col-3 mt-3">
                <label>Opzegtermijn (maanden)</label>
                <Field
                  name={nameOf<IFormikValues>('opzegtermijn')}
                  render={(fieldProps: FieldProps<IFormikValues>) => {
                    const { field, form } = fieldProps;

                    return (
                      <NumeriekVeld
                        waarde={field.value}
                        onChange={(x) => form.setFieldValue(field.name, x)}
                        min={1}
                      />
                    );
                  }}
                />
              </div>
            </div>

            <div className="col-12">
              <div className="col-3 mt-3">
                <label>Retentieperiode Annulering (dagen)</label>
                <Field
                  name={nameOf<IFormikValues>('retentieperiodeAnnulering')}
                  render={(fieldProps: FieldProps<IFormikValues>) => {
                    const { field, form } = fieldProps;

                    return (
                      <NumeriekVeld
                        waarde={field.value}
                        onChange={(x) => form.setFieldValue(field.name, x)}
                        min={0}
                      />
                    );
                  }}
                />
              </div>
            </div>

            <div className="col-12">
              <div className="col-3 mt-3">
                <label>Signaleringsgrens niet-overgenomen contracten (dagen)</label>
                <Field
                  name={nameOf<IFormikValues>('signaleringsgrensNietOvergenomen')}
                  render={(fieldProps: FieldProps<IFormikValues>) => {
                    const { field, form } = fieldProps;

                    return (
                      <NumeriekVeld
                        waarde={field.value}
                        onChange={(x) => form.setFieldValue(field.name, x)}
                        min={0}
                      />
                    );
                  }}
                />
              </div>
            </div>

            <div className="mt-2" />
          </div>
        }
      />

      <div className="mt-2" />

      <UitklapFormulier
        uitgeklapt={urlState.openingstijdUitgeklapt}
        onUitgeklaptChange={(x) => setUrlStateSync('openingstijdUitgeklapt', x)}
        label="Openingstijd"
        formulier={
          <div className="row">
            <div className="col-12">
              <div className="col-3">
                <label>Modus</label>
                {context !== null && (
                  <Field
                    name={nameOf<IFormikValues>('openingstijdenModus')}
                    render={(fieldProps: FieldProps<IFormikValues>) => {
                      const { field, form } = fieldProps;

                      const opties = [
                        { id: 0, label: 'Automatisch' },
                        { id: 1, label: 'Geopend' },
                        { id: 2, label: 'Gesloten' },
                      ];

                      return (
                        <Combobox
                          geselecteerd={fieldProps.field.value}
                          opties={opties}
                          onSelectieChange={(id) => {
                            fieldProps.form.setFieldValue(fieldProps.field.name, id);
                          }}
                        />
                      );

                      // return (
                      //   <MultiCombobox<number, IOphalenVerzendwijzenResultElement>
                      //     sleutelExtractor={(x) => x.ID}
                      //     representatieFabriek={(x) => x.Naam}
                      //     waarde={field.value}
                      //     onWaardeChange={(x) => form.setFieldValue(field.name, x)}
                      //     opties={context.factuurVerzendwijzenResult}
                      //     kolommen={factuurVerzendwijzeKolommen!}
                      //   />
                      // );
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        }
      />

      <div className="mt-2" />

      <UitklapFormulier
        uitgeklapt={urlState.debiteurUitgeklapt}
        onUitgeklaptChange={(x) => setUrlStateSync('debiteurUitgeklapt', x)}
        label="Debiteuren"
        formulier={
          <div className="row">
            <div className="col-12">
              <div className="col-3">
                <label>Standaard verzendwijze facturen</label>
                {context !== null && context.factuurVerzendwijzenResult !== null && (
                  <Field
                    name={nameOf<IFormikValues>('factuurVerzendwijze')}
                    render={(fieldProps: FieldProps<IFormikValues>) => {
                      const { field, form } = fieldProps;

                      return (
                        <MultiCombobox<number, IOphalenVerzendwijzenResultElement>
                          sleutelExtractor={(x) => x.ID}
                          representatieFabriek={(x) => x.Naam}
                          waarde={field.value}
                          onWaardeChange={(x) => form.setFieldValue(field.name, x)}
                          opties={context.factuurVerzendwijzenResult}
                          kolommen={factuurVerzendwijzeKolommen!}
                        />
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div className="col-12">
              <div className="col-3 mt-3">
                <label>Standaard betalingstermijn voor facturen</label>
                <Field
                  name={nameOf<IFormikValues>('betalingstermijnDebiteur')}
                  render={(fieldProps: FieldProps<IFormikValues>) => {
                    const { field, form } = fieldProps;

                    return (
                      <NumeriekVeld
                        waarde={field.value}
                        onChange={(x) => form.setFieldValue(field.name, x)}
                        min={1}
                      />
                    );
                  }}
                />
              </div>
            </div>
            <div className="col-12">
              <div className="col-3 mt-3">
                <label>Standaard betalingstermijn voor aanmaningen</label>
                <Field
                  name={nameOf<IFormikValues>('betalingstermijnAanmaning')}
                  render={(fieldProps: FieldProps<IFormikValues>) => {
                    const { field, form } = fieldProps;

                    return (
                      <NumeriekVeld
                        waarde={field.value}
                        onChange={(x) => form.setFieldValue(field.name, x)}
                        min={0}
                      />
                    );
                  }}
                />
              </div>
            </div>
            <div className="col-12">
              <div className="col-3 mt-3">
                <label>Standaard betalingstermijn voor de laatste aanmaning</label>
                <Field
                  name={nameOf<IFormikValues>('betalingstermijnLaatsteAanmaning')}
                  render={(fieldProps: FieldProps<IFormikValues>) => {
                    const { field, form } = fieldProps;

                    return (
                      <NumeriekVeld
                        waarde={field.value}
                        onChange={(x) => form.setFieldValue(field.name, x)}
                        min={0}
                      />
                    );
                  }}
                />
              </div>
            </div>
            <div className="col-12">
              <div className="col-3 mt-3">
                <label>Maximaal aantal aanmaningen voor een factuur</label>
                <Field
                  name={nameOf<IFormikValues>('maximaalAantalAanmaningen')}
                  render={(fieldProps: FieldProps<IFormikValues>) => {
                    const { field, form } = fieldProps;

                    return (
                      <NumeriekVeld
                        waarde={field.value}
                        onChange={(x) => form.setFieldValue(field.name, x)}
                        min={0}
                      />
                    );
                  }}
                />
              </div>
            </div>
            <div className="col-12">
              <div className="col-3 mt-3">
                <label>Parallel SMS sturen vanaf</label>

                <Field
                  name={nameOf<IFormikValues>('parallelSMSVanafAanmaning')}
                  render={(fieldProps: FieldProps<IFormikValues>) => {
                    const { field, form } = fieldProps;

                    const opties = [
                      { id: 0, label: 'Niet sturen' },
                      { id: 1, label: '1e aanmaning' },
                      { id: 2, label: '2e aanmaning' },
                      { id: 3, label: '3e aanmaning' },
                    ];

                    return (
                      <Combobox
                        geselecteerd={fieldProps.field.value}
                        opties={opties}
                        onSelectieChange={(id) => {
                          fieldProps.form.setFieldValue(fieldProps.field.name, id);
                        }}
                      />
                    );
                  }}
                />
              </div>
            </div>

            <div className="col-12">
              <div className="col-3 mt-3">
                <label>Respijttermijn facturen in aanmaningen</label>
                <Field
                  name={nameOf<IFormikValues>('aanmaningRespijttermijn')}
                  render={(fieldProps: FieldProps<IFormikValues>) => {
                    const { field, form } = fieldProps;

                    return (
                      <NumeriekVeld
                        waarde={field.value}
                        onChange={(x) => form.setFieldValue(field.name, x)}
                        min={0}
                      />
                    );
                  }}
                />
              </div>
            </div>
            <div className="col-12">
              <div className="col-3 mt-3">
                <label>Respijttermijn aanmaningen in beltaken</label>
                <Field
                  name={nameOf<IFormikValues>('respijttermijnAanmaningenInBeltaak')}
                  render={(fieldProps: FieldProps<IFormikValues>) => {
                    const { field, form } = fieldProps;

                    return (
                      <NumeriekVeld
                        waarde={field.value}
                        onChange={(x) => form.setFieldValue(field.name, x)}
                        min={0}
                      />
                    );
                  }}
                />
              </div>
            </div>
          </div>
        }
      />

      {/* <div className="mt-2" />

      <UitklapFormulier
        uitgeklapt={urlState.financieelUitgeklapt}
        onUitgeklaptChange={(x) => setUrlStateSync('financieelUitgeklapt', x)}
        label="Financieel"
        formulier={<div className="row"></div>}
      /> */}

      <div className="mt-2" />

      <UitklapFormulier
        uitgeklapt={urlState.dienstenUitgeklapt}
        onUitgeklaptChange={(x) => setUrlStateSync('dienstenUitgeklapt', x)}
        label="Diensten"
        formulier={
          <div className="row">
            <div className="col-3">
              <label>Sms Dienst</label>
              {context !== null && context.smsDienstenResult !== null && (
                <Field
                  name={nameOf<IFormikValues>('smsDienstID')}
                  render={(fieldProps: FieldProps<IFormikValues>) => {
                    const { field, form } = fieldProps;

                    return (
                      <MultiCombobox<number, IOphalenDienstenResultElement>
                        sleutelExtractor={(x) => x.SmsDienstID}
                        representatieFabriek={(x) => x.Naam}
                        waarde={field.value}
                        onWaardeChange={(x) => form.setFieldValue(field.name, x)}
                        opties={context.smsDienstenResult!.diensten}
                        kolommen={smsDienstKolommen!}
                      />
                    );
                  }}
                />
              )}
            </div>
          </div>
        }
      />
      <div className="mt-2" />
      <UitklapFormulier
        uitgeklapt={urlState.transportUitgeklapt}
        onUitgeklaptChange={(x) => setUrlStateSync('transportUitgeklapt', x)}
        label="Transport"
        formulier={
          <div className="row">
            <div className="col-3">
              <label>Magazijn</label>
              <Field
                name={nameOf<IFormikValues>('magID')}
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;

                  return (
                    <MultiCombobox<number, IOphalenMagazijnenResultElement>
                      sleutelExtractor={(x) => x.MagID}
                      representatieFabriek={(x) => x.NaamKort}
                      waarde={field.value}
                      onWaardeChange={(x) => form.setFieldValue(field.name, x)}
                      opties={
                        context.magazijnenResult === null
                          ? null
                          : context.magazijnenResult.magazijnen
                      }
                      kolommen={magazijnKolommen!}
                    />
                  );
                }}
              />
            </div>
            <div className="col-3">
              <label>Voorraad magazijn</label>
              <Field
                name={nameOf<IFormikValues>('voorraadMagID')}
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;

                  return (
                    <MultiCombobox<number, IOphalenMagazijnenResultElement>
                      sleutelExtractor={(x) => x.MagID}
                      representatieFabriek={(x) => x.NaamKort}
                      waarde={field.value}
                      onWaardeChange={(x) => form.setFieldValue(field.name, x)}
                      opties={
                        context.magazijnenResult === null
                          ? null
                          : context.magazijnenResult.magazijnen
                      }
                      kolommen={magazijnKolommen!}
                    />
                  );
                }}
              />
            </div>
            <div className="col-3">
              <label>Revisie magazijn</label>
              <Field
                name={nameOf<IFormikValues>('revisieMagID')}
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;

                  return (
                    <MultiCombobox<number, IOphalenMagazijnenResultElement>
                      sleutelExtractor={(x) => x.MagID}
                      representatieFabriek={(x) => x.NaamKort}
                      waarde={field.value}
                      onWaardeChange={(x) => form.setFieldValue(field.name, x)}
                      opties={
                        context.magazijnenResult === null
                          ? null
                          : context.magazijnenResult.magazijnen
                      }
                      kolommen={magazijnKolommen!}
                    />
                  );
                }}
              />
            </div>

            <div className="col-6 mt-3">
              <label>Respijttermijn retourdatum</label>
              <Field
                name={nameOf<IFormikValues>('respijttermijnRetourdatum')}
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;

                  return (
                    <NumeriekVeld
                      waarde={field.value}
                      onChange={(x) => form.setFieldValue(field.name, x)}
                      min={0}
                    />
                  );
                }}
              />
            </div>
            <div className="col-12 d-flex align-items-center mt-3">
              <Field
                name={nameOf<IFormikValues>('toestaanTransportopdrachtMetIR')}
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;

                  return (
                    <VinkVeld
                      aangevinkt={field.value}
                      onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                    />
                  );
                }}
              />
              <span className="ml-2">Transportopdracht met Inkoopreservering toestaan</span>
            </div>
          </div>
        }
      />

      <div className="mt-2" />
      <UitklapFormulier
        uitgeklapt={urlState.externeDataUitgeklapt}
        onUitgeklaptChange={(x) => setUrlStateSync('externeDataUitgeklapt', x)}
        label="Externe Data"
        formulier={
          <div className="row">
            <div className="col-3">
              <label>Doelmap afleverbewijs</label>
              <Field
                name={nameOf<IFormikValues>('doelmapAfleverbewijs')}
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;

                  return (
                    <MultiCombobox<number, IMailMapOptie>
                      sleutelExtractor={(x) => x.ID}
                      representatieFabriek={(x) =>
                        `${x.Weergavenaam} - ${vindGebruikersnaam(x.EmailGebrID)}`
                      }
                      waarde={field.value}
                      onWaardeChange={(x) => form.setFieldValue(field.name, x)}
                      opties={getMailmapOpties}
                      kolommen={mailMapKolommen!}
                      isWisbaar={true}
                    />
                  );
                }}
              />
            </div>
            <div className="col-3">
              <label>Doelmap inkoopfactuur</label>
              <Field
                name={nameOf<IFormikValues>('doelmapInkoopfactuur')}
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;

                  return (
                    <MultiCombobox<number, IMailMapOptie>
                      sleutelExtractor={(x) => x.ID}
                      representatieFabriek={(x) =>
                        `${x.Weergavenaam} - ${vindGebruikersnaam(x.EmailGebrID)}`
                      }
                      waarde={field.value}
                      onWaardeChange={(x) => form.setFieldValue(field.name, x)}
                      opties={getMailmapOpties}
                      kolommen={mailMapKolommen!}
                      isWisbaar={true}
                    />
                  );
                }}
              />
            </div>
            <div className="col-3">
              <label>Doelmap pakketbezorging bezoekinfo</label>
              <Field
                name={nameOf<IFormikValues>('doelmapPakketbezorgingBezoekinfo')}
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;

                  return (
                    <MultiCombobox<number, IMailMapOptie>
                      sleutelExtractor={(x) => x.ID}
                      representatieFabriek={(x) =>
                        `${x.Weergavenaam} - ${vindGebruikersnaam(x.EmailGebrID)}`
                      }
                      waarde={field.value}
                      onWaardeChange={(x) => form.setFieldValue(field.name, x)}
                      opties={getMailmapOpties}
                      kolommen={mailMapKolommen!}
                      isWisbaar={true}
                    />
                  );
                }}
              />
            </div>
          </div>
        }
      />

      <div className="mt-2" />
      <UitklapFormulier
        uitgeklapt={urlState.bulkberichtenUitgeklapt}
        onUitgeklaptChange={(x) => setUrlStateSync('bulkberichtenUitgeklapt', x)}
        label="Bulkberichten"
        formulier={
          <div className="row">
            <div className="col-3">
              <label>Emailgebruiker voor verzenden bulkberichten</label>
              <Field
                name={nameOf<IFormikValues>('bulkberichtenEmailGebrID')}
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;

                  return (
                    <MultiCombobox<number, IOphalenEmailAccountsResultElement>
                      sleutelExtractor={(x) => x.EmailGebrID}
                      representatieFabriek={(x) => `${x.GebruikersNaam}`}
                      waarde={field.value}
                      onWaardeChange={(x) => form.setFieldValue(field.name, x)}
                      opties={context.gebruikersResult}
                      kolommen={emailGebrMapKolommen!}
                      isWisbaar={true}
                    />
                  );
                }}
              />
            </div>
          </div>
        }
      />

      <div className="mt-2" />
      <UitklapFormulier
        uitgeklapt={urlState.bankzakenUitgeklapt}
        onUitgeklaptChange={(x) => setUrlStateSync('bankzakenUitgeklapt', x)}
        label="Bankzaken"
        formulier={
          <div className="row">
            <div className="col-2">
              <label>Grensbedrag Incassoopdracht Debiteur</label>
              <Field
                name={nameOf<IFormikValues>('grensbedragIncassoopdrachtDebiteur')}
                render={(x: FieldProps<IFormikValues>) => {
                  return (
                    <div>
                      <BedragInput
                        value={x.field.value}
                        onChange={(bedrag) => {
                          x.form.setFieldValue(x.field.name, bedrag);
                        }}
                      />
                    </div>
                  );
                }}
              />
            </div>
            <div className="col-2">
              <label>Grensbedrag Betaalopdracht Debiteur</label>
              <Field
                name={nameOf<IFormikValues>('grensbedragBetaalopdrachtDebiteur')}
                render={(x: FieldProps<IFormikValues>) => {
                  return (
                    <div>
                      <BedragInput
                        value={x.field.value}
                        onChange={(bedrag) => {
                          x.form.setFieldValue(x.field.name, bedrag);
                        }}
                      />
                    </div>
                  );
                }}
              />
            </div>
            <div className="col-2">
              <label>Grensbedrag Betaalopdracht Crediteur</label>
              <Field
                name={nameOf<IFormikValues>('grensbedragBetaalopdrachtCrediteur')}
                render={(x: FieldProps<IFormikValues>) => {
                  return (
                    <div>
                      <BedragInput
                        value={x.field.value}
                        onChange={(bedrag) => {
                          x.form.setFieldValue(x.field.name, bedrag);
                        }}
                      />
                    </div>
                  );
                }}
              />
            </div>
          </div>
        }
      />

      <div className="mt-2" />
      <UitklapFormulier
        uitgeklapt={urlState.telefonieUitgeklapt}
        onUitgeklaptChange={(x) => setUrlStateSync('telefonieUitgeklapt', x)}
        label="Telefonie"
        formulier={
          <div className="row">
            <div className="col-12 d-flex align-items-center">
              <Field
                name={nameOf<IFormikValues>('terugbelverzoekNietBeantwoordVersturen')}
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;

                  return (
                    <VinkVeld
                      aangevinkt={field.value}
                      onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                    />
                  );
                }}
              />
              <span className="ml-2">Versturen van terugbelverzoek bij gemiste oproep</span>
            </div>
          </div>
        }
      />

      <div className="mt-2" />
      <UitklapFormulier
        uitgeklapt={urlState.whatsappUitgeklapt}
        onUitgeklaptChange={(x) => setUrlStateSync('whatsappUitgeklapt', x)}
        label="WhatsApp"
        formulier={
          <div className="row">
            <div className="col-12 d-flex align-items-center">
              <Field
                name={nameOf<IFormikValues>('ontvangstbevestigingWhatsAppBinnenOpeningstijd')}
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;

                  return (
                    <VinkVeld
                      aangevinkt={field.value}
                      onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                    />
                  );
                }}
              />
              <span className="ml-2">
                Ontvangstbevestiging versturen - Binnen de openingstijden
              </span>
            </div>
            <div className="col-12 d-flex align-items-center mt-3">
              <Field
                name={nameOf<IFormikValues>('ontvangstbevestigingWhatsAppBuitenOpeningstijd')}
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;

                  return (
                    <VinkVeld
                      aangevinkt={field.value}
                      onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                    />
                  );
                }}
              />
              <span className="ml-2">
                Ontvangstbevestiging versturen - Buiten de openingstijden
              </span>
            </div>
          </div>
        }
      />

      <div className="mt-2" />
      <UitklapFormulier
        uitgeklapt={urlState.loonadministratie}
        onUitgeklaptChange={(x) => setUrlStateSync('loonadministratie', x)}
        label="Loonadministratie"
        formulier={
          <div className="row">
            <div className="col-12 d-flex align-items-center mt-3">
              <Field
                name={nameOf<IFormikValues>('rekeningLoonbetalingInternBetrekken')}
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;

                  return (
                    <VinkVeld
                      aangevinkt={field.value}
                      onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                    />
                  );
                }}
              />
              <span className="ml-2">
                Rekeningnaam/IBAN bij inlezen loonbetalingen intern betrekken, dus niet uit het
                importbestand halen
              </span>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default withRouter(Applicatie);
