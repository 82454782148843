import { format } from 'date-fns';
import React, { useContext, useMemo } from 'react';
import { RouteComponentProps } from 'react-router';
import { IContext, PersoonContext } from '..';
import VeldWeergave from '../../../formulier/VeldWeergave';
import LoadingSpinner from '../../../Gedeeld/LoadingSpinner';
import GegevensLayout from '../../../layout/GegevensLayout';

import { ITabbladProps } from '../../../product/ProductinfoDialoog';
import { EHoedanigheid } from '../../RelatieSelectieDialoog';
import RelatiesVisualisaties from '../../RelatiesVisualisaties';

interface IProps {}

const DetailTab: React.FC<IProps> = (props) => {
  const { persoon, persoonRelaties } = useContext<IContext>(PersoonContext);

  const Relaties = () => {
    if (persoonRelaties === null) {
      return <span></span>;
    }

    return (
      <RelatiesVisualisaties
        relIDs={persoonRelaties.map((x) => x.RelID)}
        relatieLinkBuilder={(hoedanigheid, relID) =>
          `/${
            hoedanigheid === EHoedanigheid.Klant ? 'klant' : 'leverancier'
          }/${relID}/contactpersonen`
        }
      />
    );
  };

  return (
    <div className="d-flex flex-column flex-fill">
      {persoon === null ? (
        <div className="d-flex flex-fill align-items-center justify-content-center">
          <LoadingSpinner />
        </div>
      ) : (
        <div className="d-flex flex-column p-3 flex-fill" style={{ rowGap: 10 }}>
          <div>
            <VeldWeergave>
              <GegevensLayout
                gegevens={[
                  {
                    label: 'Geboortedatum',
                    waarde: `${
                      persoon.Geboortedatum !== null
                        ? format(new Date(persoon.Geboortedatum), 'dd-MM-yyyy')
                        : ''
                    }`,
                  },
                  {
                    label: 'Geboorteplaats',
                    waarde: `${persoon.Geboorteplaats ?? ''}`,
                  },
                ]}
              />
            </VeldWeergave>

            <div className="mt-3">
              <VeldWeergave>
                <GegevensLayout
                  gegevens={[
                    {
                      label: 'Vastgelegd',
                      waarde: `${
                        persoon.RecordToegevoegd !== null
                          ? format(new Date(persoon.RecordToegevoegd), 'dd-MM-yyyy HH:mm')
                          : ''
                      }`,
                    },
                    {
                      label: 'Gewijzigd',
                      waarde: `${
                        persoon.RecordGewijzigd !== null
                          ? format(new Date(persoon.RecordGewijzigd), 'dd-MM-yyyy HH:mm')
                          : ''
                      }`,
                    },
                  ]}
                />
              </VeldWeergave>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DetailTab;
