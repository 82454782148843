import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../stores/RootStore';
import { Kleur } from '../../../bedrijfslogica/constanten';
import { OverlayTrigger } from 'react-bootstrap';
import InfoCard from './InfoCard';

interface IProps {
  bestandID: number;
}

const BestandVisualisatie: React.FC<IProps> = observer((props) => {
  const { bestandStore } = useContext(RootStoreContext);

  const bestandinfo = useMemo(() => bestandStore.bestandinfos[props.bestandID] ?? null, [
    bestandStore.bestandinfos,
    props.bestandID,
  ]);

  useEffect(() => {
    if (bestandinfo !== null) {
      return;
    }

    bestandStore.scheduleOphalenBestandinfos([props.bestandID]);
  }, [props.bestandID]);

  const [showInfoCardOverlay, setShowInfoCardOverlay] = useState(false);
  const showInfoCardOverlayTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const handleMouseEnterInfoCardOverlay = useCallback(() => {
    if (showInfoCardOverlayTimeoutRef.current !== null) {
      clearTimeout(showInfoCardOverlayTimeoutRef.current);
    }
    showInfoCardOverlayTimeoutRef.current = setTimeout(() => setShowInfoCardOverlay(true), 800);
  }, []);
  const handleMouseLeaveInfoCardOverlay = useCallback(() => {
    if (showInfoCardOverlayTimeoutRef.current !== null) {
      clearTimeout(showInfoCardOverlayTimeoutRef.current);
    }
    showInfoCardOverlayTimeoutRef.current = setTimeout(() => setShowInfoCardOverlay(false), 400);
  }, []);
  const renderInfoCardOverlay = useCallback(
    (p: any) => {
      return (
        <div
          id={`bestand-visualisatie-overlay-${props.bestandID}`}
          {...p}
          onMouseEnter={handleMouseEnterInfoCardOverlay}
          onMouseLeave={handleMouseLeaveInfoCardOverlay}
          style={{
            ...p.style,
            zIndex: 99999,
            border: `1px solid ${Kleur.LichtGrijs}`,
            borderRadius: 5,
          }}
        >
          <InfoCard bestandID={props.bestandID} />
        </div>
      );
    },
    [props.bestandID, handleMouseEnterInfoCardOverlay, handleMouseLeaveInfoCardOverlay],
  );

  if (bestandinfo == null) {
    return <span>Laden...</span>;
  }

  return (
    // <OverlayTrigger
    //   trigger="hover"
    //   placement="auto-start"
    //   overlay={renderInfoCardOverlay}
    //   show={showInfoCardOverlay}
    //   popperConfig={
    //     {
    //       modifiers: {
    //         name: 'offset',
    //         options: {
    //           offset: [0, 5],
    //         },
    //       },
    //     } as any
    //   }
    // >
    <a
      href="#"
      onClick={(ev) => {
        ev.preventDefault();

        const url = bestandinfo.url;
        window.open(`${url}?inline=true`, '_blank');
      }}
      onMouseEnter={handleMouseEnterInfoCardOverlay}
      onMouseLeave={handleMouseLeaveInfoCardOverlay}
    >
      {bestandinfo.Naam}
    </a>
    // </OverlayTrigger>
  );
});

export default BestandVisualisatie;
