import React, { useCallback, useContext, useState, useMemo, useEffect } from 'react';
import { ModalBody, ModalFooter, ModalTitle } from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/ModalHeader';
import api from '../../../api';
import IDialoogProps from '../../../core/IDialoogProps';
import Dialoog from '../../dialogen/Dialoog';
import _ from 'lodash';
import LoadingSpinner from '../../Gedeeld/LoadingSpinner';
import Tabblad, { ITabblad } from '../../layout/Tabblad';
import OpdrachtTab from './OpdrachtTab';
import { IOphalenOpdrachtenResultElement } from '../../../../../shared/src/api/v2/bank/opdracht/opdracht';
import { IOphalenBetalingsregelingenResultElement } from '../../../../../shared/src/api/v2/debiteur/betalingsregeling';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../models/IRemoteData';
import TechnischeInformatieKnop from '../../TechnischeInformatieKnop';

interface IProps extends IDialoogProps<{}> {
  bankOpdID: number;
}

enum ETablad {
  Opdracht,
}

export interface IContext {
  bankopdracht: IOphalenOpdrachtenResultElement | null;
  betalingsregeling: IOphalenBetalingsregelingenResultElement | null;
}
export const OpdrachtContext = React.createContext<IContext>({
  bankopdracht: null,
  betalingsregeling: null,
});

const ProductmodelInfoDialoog: React.FC<IProps> = (props) => {
  const { dialoogIndex, onAnnuleren, onSuccess, open, bankOpdID } = props;

  const [tabblad, setTabblad] = useState<ETablad>(ETablad.Opdracht);
  const [bankopdracht, setBankopdracht] = useState<IOphalenOpdrachtenResultElement | null>(null);
  const [betalingsregeling, setBetalingsregeling] = useState<
    IRemoteData<IOphalenBetalingsregelingenResultElement | null>
  >(createPendingRemoteData());

  const tabbladen = useMemo<ITabblad<ETablad>[]>(
    () => [
      {
        id: ETablad.Opdracht,
        label: 'Opdracht',
        content: OpdrachtTab,
      },
    ],
    [onSuccess],
  );

  const ophalenBankopdracht = useCallback(async () => {
    const opdracht = (
      await api.v2.bank.opdracht.ophalenOpdrachten({
        filterSchema: { filters: [{ naam: 'IDS', data: [bankOpdID] }] },
      })
    ).opdrachten[0];

    setBankopdracht(opdracht);
  }, [bankOpdID]);

  useEffect(() => {
    ophalenBankopdracht();
  }, [ophalenBankopdracht]);

  const ophalenBetalingsregeling = useCallback(async () => {
    if (bankopdracht === null) {
      return;
    }
    if (bankopdracht.BetRglID === null) {
      setBetalingsregeling(createReadyRemoteData(null));
      return;
    }
    const betalingsregeling = (
      await api.v2.debiteur.betalingsregeling.ophalenBetalingsregelingen({
        filterSchema: { filters: [{ naam: 'IDS', data: [bankopdracht.BetRglID] }] },
      })
    ).betalingsregelingen[0];

    setBetalingsregeling(createReadyRemoteData(betalingsregeling));
  }, [bankopdracht]);

  useEffect(() => {
    ophalenBetalingsregeling();
  }, [ophalenBetalingsregeling]);

  if (bankopdracht === null || betalingsregeling.state === ERemoteDataState.Pending) {
    return <LoadingSpinner />;
  }

  return (
    <OpdrachtContext.Provider value={{ bankopdracht, betalingsregeling: betalingsregeling.data }}>
      <Dialoog index={props.dialoogIndex ?? 0} modalProps={{ size: 'lg' }}>
        <ModalHeader>
          <ModalTitle>Bankopdracht info</ModalTitle>
        </ModalHeader>
        <ModalBody className="p-0">
          <Tabblad
            geselecteerd={tabblad}
            onSelectieChange={(id) => setTabblad(id)}
            tabbladen={tabbladen}
          />
        </ModalBody>
        <ModalFooter className="d-flex justify-content-start mt-2">
          <button className="btn btn-secondary" style={{ width: 100 }} onClick={onAnnuleren}>
            Annuleren
          </button>
          <div className="flex-fill" />
          <TechnischeInformatieKnop
            dialoogIndex={(props.dialoogIndex ?? 0) + 1}
            content={() => <div>BankOpdID: {props.bankOpdID}</div>}
          />
        </ModalFooter>
      </Dialoog>
    </OpdrachtContext.Provider>
  );
};

export default ProductmodelInfoDialoog;
