import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useMemo } from 'react';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import * as Yup from 'yup';
import { ISponsorEventPlaats } from '../../../../../../../../shared/src/api/v2/sponsoringv2/event';
import api from '../../../../../../api';
import LoadingSpinner from '../../../../../../components/Gedeeld/LoadingSpinner';
import Dialoog from '../../../../../../components/dialogen/Dialoog';
import IDialoogProps from '../../../../../../core/IDialoogProps';
import nameOf from '../../../../../../core/nameOf';

interface IDialoogResult {}

interface IProps extends IDialoogProps<IDialoogResult> {
  koppelBasisID: number;
}

interface IFormikValues {
  Naam: string;
}

const KoppelenDialoog: React.FC<IProps> = observer((props) => {
  const { dialoogIndex, onAnnuleren } = props;
  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      let params: ISponsorEventPlaats = {
        EventID: props.koppelBasisID,
        Naam: values.Naam,
      };

      await api.v2.sponsoringv2.event.toevoegenPlaats(params);

      actions.setSubmitting(false);
      props.onSuccess({});
    },
    [],
  );

  const initialValues = useMemo<IFormikValues | null>(() => {
    return {
      Naam: '',
    };
  }, []);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        Naam: Yup.string().required(),
      }),
    [],
  );

  return (
    <Dialoog index={dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Koppelen plaats</ModalTitle>
      </ModalHeader>
      {initialValues === null ? (
        <LoadingSpinner />
      ) : (
        <Formik<IFormikValues>
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize
          isInitialValid
          render={(formikProps: FormikProps<IFormikValues>) => {
            const { submitForm, isValid, isSubmitting } = formikProps;

            return (
              <>
                <ModalBody>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-12 mt-3">
                        <label>Naam</label>
                        <Field
                          name={nameOf<IFormikValues>('Naam')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <>
                                <input type="text" className="form-control" {...field} />
                              </>
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </ModalBody>

                <ModalFooter className="d-flex flex-row justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    style={{ width: 100 }}
                    disabled={!isValid || isSubmitting}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={onAnnuleren}
                    style={{ width: 100 }}
                    disabled={isSubmitting}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Dialoog>
  );
});

export default KoppelenDialoog;
