import api from '../index';
import {
  IOphalenBerichtContextenParams,
  IOphalenBerichtContextenResult,
  IOphalenCommunicatiekanalenParams,
  IOphalenCommunicatiekanalenResult,
} from '../../../../shared/src/api/v2/bericht/Bericht';

const bericht = {
  ophalenBerichtContexten: async (params: IOphalenBerichtContextenParams) => {
    return await api.post<IOphalenBerichtContextenResult>(
      '/v2/bericht/ophalen-bericht-contexten',
      params,
    );
  },
  ophalenCommunicatiekanalen: async (
    params: IOphalenCommunicatiekanalenParams,
  ): Promise<IOphalenCommunicatiekanalenResult> => {
    return await api.post('/v2/bericht/ophalen-communicatiekanalen', params);
  },
};

export default bericht;
