import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  IOphalenHuidigeSignaleringenResult,
  IOphalenSignaleringstypesResultElement,
} from '../../../../shared/src/api/v2/control/signalering';
import api from '../../api';
import { Kleur } from '../../bedrijfslogica/constanten';
import { Sorting } from '@devexpress/dx-react-grid';
import useUrlState from '../../core/useUrlState';
import MenuLayout from '../../components/MenuLayout';
import GegevensLayout from '../../components/layout/GegevensLayout';
import { format } from 'date-fns';
import SignaleringTabel from '../../components/signalering/SignaleringTabel';
import ResizeObserver from 'react-resize-observer';
import { useRealtimeListener } from '../../one-off-components/realtime/RealtimeIntegratie';
import FilterBalkV2, {
  IFilter,
  IFilterData,
  maakFilterSchema,
} from '../../components/FilterBalkV2';
import { IFilterSchema } from '../../../../shared/src/models/filter';
import GeenVerborgenSignaleringenFilter from './GeenVerborgenSignaleringenFilter';
import MultiCombobox from '../../components/formulier/MultiCombobox';
import { Helmet } from 'react-helmet';

interface IProps extends RouteComponentProps {}

enum EFilter {
  GeenVerborgenSignaleringen = 'VERBERGEN',
  Signaleringstype = 'SIGNTYPE_IDS',
}

interface IUrlState {
  selection: number[];
  sortering: Array<Sorting>;
  filterData: Array<IFilterData<EFilter>>;
}

const defaultUrlState: IUrlState = {
  selection: [],
  sortering: [],
  filterData: [
    {
      naam: EFilter.GeenVerborgenSignaleringen,
      data: false,
      isActief: true,
    },
    {
      naam: EFilter.Signaleringstype,
      data: [],
      isActief: false,
    },
  ],
};

const Signaleringen: React.FC<IProps> = (props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState<IUrlState>(props, defaultUrlState);

  const [signaleringstypes, setSignaleringstypes] = useState<
    IOphalenSignaleringstypesResultElement[] | null
  >(null);

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.GeenVerborgenSignaleringen,
        altijdWeergevenInBalk: true,
        weergave: GeenVerborgenSignaleringenFilter,
      },
      {
        naam: EFilter.Signaleringstype,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          if (signaleringstypes === null) {
            return <span></span>;
          }

          return (
            <div className="d-flex align-items-center">
              <span className="mr-2">Signaleringstype</span>
              <MultiCombobox<number, IOphalenSignaleringstypesResultElement>
                sleutelExtractor={(x) => x.ID}
                representatieFabriek={(x) => x.Naam}
                waarde={weergaveProps.data.length === 0 ? null : weergaveProps.data[0]}
                // waarde={weergaveProps.data}
                onWaardeChange={(x) => {
                  weergaveProps.onDataChange(x === null ? [] : [x]);
                  weergaveProps.setIsActief(true);
                  weergaveProps.toepassen();
                }}
                opties={signaleringstypes}
                kolommen={[
                  {
                    key: 'Naam',
                    label: 'Naam',
                    breedte: 400,
                  },
                  // {
                  //   key: 'NaamEnum',
                  //   label: 'Enum',
                  //   breedte: 135,
                  // },
                ]}
                options={{
                  geenWaardeBericht: 'Kies een type',
                }}
              />
            </div>
          );
        },
      },
    ],
    [signaleringstypes],
  );

  const [filterSchema, setFilterSchema] = useState<IFilterSchema>(
    maakFilterSchema(urlState.filterData),
  );

  useEffect(() => {
    setFilterSchema(maakFilterSchema(urlState.filterData));
  }, [JSON.stringify(urlState.filterData)]);

  const [
    signaleringenResult,
    setSignaleringenResult,
  ] = useState<IOphalenHuidigeSignaleringenResult | null>(null);

  const ophalenHuidigeSignaleringen = useCallback(async () => {
    const result = await api.v2.signalering.ophalenHuidigeSignaleringen({
      filterSchema,
    });

    if (result.signaleringen === null) {
      return;
    }

    setSignaleringenResult(result);
  }, [filterSchema]);

  useEffect(() => {
    ophalenHuidigeSignaleringen();
  }, [ophalenHuidigeSignaleringen]);

  const ophalenSignaleringtypes = useCallback(async () => {
    const result = await api.v2.signalering.ophalenSignaleringstypes({
      orderSchema: { orders: [{ naam: 'NAAM', richting: 'ASC' }] },
    });
    setSignaleringstypes(result.signaleringstypes);
  }, []);

  useEffect(() => {
    ophalenSignaleringtypes();
  }, [ophalenSignaleringtypes]);

  useRealtimeListener(async (event, data) => {
    if (event === 'SIGNALERINGEN_VERBORGEN' || event === 'SIGNALERINGEN_WEERGEGEVEN') {
      await ophalenHuidigeSignaleringen();
    }
  });

  const [tabelHeight, setTabelHeight] = useState<number | null>(null);
  console.log(tabelHeight);

  return (
    <div className="d-flex flex-fill flex-column" style={{ backgroundColor: Kleur.Wit }}>
      <>
        <Helmet>
          <title>Signaleringen</title>
        </Helmet>

        <MenuLayout
          menu={
            <div>
              <FilterBalkV2
                filters={filters}
                filterData={urlState.filterData}
                onFilterDataChange={(x) => setUrlStateSync('filterData', x)}
                onFilterSchemaChange={(x) => setFilterSchema(x)}
              />
              <div className="mt-2">
                <GegevensLayout
                  gegevens={[
                    {
                      label: 'Datum laatst geconstateerde signalering',
                      waarde:
                        signaleringenResult === null || signaleringenResult.run === null
                          ? null
                          : `${format(
                              new Date(signaleringenResult.run.Datum),
                              'dd-MM-yyyy HH:mm',
                            )}`,
                    },
                  ]}
                />
              </div>
            </div>
          }
          body={
            signaleringenResult === null ||
            signaleringenResult.signaleringen === null ||
            signaleringenResult.signaleringen.length === 0 ? (
              <div
                className="d-flex flex-column justify-content-center align-items-center flex-fill"
                style={{ width: '100%' }}
              >
                <h1>Er zijn geen signaleringen beschikbaar</h1>
              </div>
            ) : signaleringenResult.run === null || signaleringenResult.signaleringen === null ? (
              <span>Er zijn geen signaleringen beschikbaar.</span>
            ) : (
              <div className="flex-fill d-flex flex-column">
                <SignaleringTabel
                  selection={urlState.selection}
                  onSelectionChange={(x) => setUrlStateSync('selection', x)}
                  sortering={urlState.sortering}
                  onSorteringChange={(x) => setUrlStateSync('sortering', x)}
                  signaleringen={signaleringenResult.signaleringen}
                  run={signaleringenResult.run}
                  // maxHeight={tabelHeight === null ? undefined : tabelHeight}
                  height={tabelHeight === null ? undefined : tabelHeight}
                />
                <ResizeObserver onResize={(x) => setTabelHeight(x.height)} />
              </div>
            )
          }
        />
      </>
    </div>
  );
};
const SignaleringenWithRouter = withRouter(Signaleringen);

export default SignaleringenWithRouter;
