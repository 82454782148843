import React, { useCallback, useContext, useMemo } from 'react';
import { TableRowDetail } from '@devexpress/dx-react-grid';
import { TableRowDetailWrapper } from '../../../../../helpers/dxTableGrid';
import TabelInspringBlok from '../../../../../components/layout/TabelInspringBlok';
import BijlageKnop from '../../../../../components/BijlageKnop';
import BijlagenContainer, {
  Bestand,
  BestandType,
  EWeergaveFocus,
} from '../../../../../components/BijlagenContainer';
import FormikVeldFout from '../../../../../components/formulier/FormikVeldFout';
import useUpload from '../../../../../core/useUpload';
import { IOphalenVerkoopmodellenResultElement } from '../../../../../../../shared/src/api/v2/aanbod/verkoop';
import { Formik, FormikActions, FormikProps } from 'formik';
import LoadingSpinner from '../../../../../components/Gedeeld/LoadingSpinner';
import nameof from '../../../../../core/nameOf';
import api from '../../../../../api';
import { VerkoopContext } from '../index';

interface IFormikValues {
  afbeeldingen: Bestand[];
}

type IProps = TableRowDetail.ContentProps;

const RowDetailComp = (props: IProps) => {
  const row: IOphalenVerkoopmodellenResultElement = props.row;
  const verkoopContext = useContext(VerkoopContext);

  const {
    bestanden,
    isBezigMetUploaden,
    muteerBestanden,
    uploadProgresses,
    upload,
    moetNogUploaden,
  } = useUpload(
    useMemo(
      () => ({
        initieleBestanden: row.afbeeldingen.map((x) => {
          return {
            type: BestandType.ASPDrive as BestandType.ASPDrive,
            bestand: x.bestand,
          };
        }),
        automatischUploaden: false,
        onAlleBestandenGeuploaded: () => {},
      }),
      [],
    ),
  );

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (bestanden === null) {
      return null;
    }
    return {
      afbeeldingen: bestanden,
    };
  }, [bestanden]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      const uploadResult = await upload();
      if (uploadResult === null) {
        actions.setSubmitting(false);
        return;
      }

      const tekstenResult = await api.v2.tekst.ophalenTekstenInAlleTalen({
        tekstIDs: [row.Naam_TekstID, row.Omschrijving_TekstID],
      });

      await api.v2.aanbod.verkoop.wijzigenVerkoopmodel({
        naam: row.Naam,
        actief: row.Actief,
        naam_TekstID: row.Naam_TekstID,
        omschrijving_TekstID: row.Omschrijving_TekstID,
        prodSrtID: row.ProdSrtID,
        typeID: row.TypeID,
        naamTeksten: tekstenResult.teksten
          .filter((x) => x.TekstID === row.Naam_TekstID)
          .map((tekst) => ({
            taalID: tekst.TaalID,
            tekst: tekst.Tekst || '',
            toepassen: tekst.Toepassen,
          })),
        omschrijvingTeksten: tekstenResult.teksten
          .filter((x) => x.TekstID === row.Omschrijving_TekstID)
          .map((tekst) => ({
            taalID: tekst.TaalID,
            tekst: tekst.Tekst || '',
            toepassen: tekst.Toepassen,
          })),
        prijs: row.Prijs,
        verkModID: row.VerkModID,
        afbeeldingen: uploadResult.map((x, i) => ({
          bestandID: x.ID,
          sortNr: i + 1,
        })),
      });

      await verkoopContext.onVerversenAangevraagd();
      actions.setSubmitting(false);
    },
    [row, upload, verkoopContext.onVerversenAangevraagd],
  );

  return (
    <TableRowDetailWrapper>
      <div className="d-flex w-100 h-100">
        <TabelInspringBlok />
        <div className="flex-fill d-flex flex-column">
          {initialValues === null ? (
            <LoadingSpinner />
          ) : (
            <Formik<IFormikValues>
              onSubmit={handleSubmit}
              initialValues={initialValues}
              render={(formikProps: FormikProps<IFormikValues>) => {
                const {
                  isSubmitting,
                  setFieldValue,
                  values,
                  submitForm,
                  resetForm,
                  dirty,
                } = formikProps;
                return (
                  <>
                    <div className="flex-fill p-3">
                      <div className="d-flex align-items-center mb-2">
                        <h5 className="mr-4" style={{ position: 'relative', top: 2 }}>
                          Verkoopmodelafbeeldingen
                        </h5>
                        <BijlageKnop
                          disabled={isBezigMetUploaden || isSubmitting}
                          onBijgevoegd={async (bestanden) => {
                            const value = await muteerBestanden((x) => [...x, ...bestanden]);
                            setFieldValue(nameof<IFormikValues>('afbeeldingen'), value);
                          }}
                          toegestaneBestandstypes={[
                            {
                              weergaveNaam: 'Afbeelding',
                              mediaType: 'image/*',
                            },
                          ]}
                        />
                      </div>
                      <BijlagenContainer
                        bestanden={values.afbeeldingen}
                        weergaveFocus={EWeergaveFocus.Visualisatie}
                        uploadProgresses={uploadProgresses}
                        bestandenMuterenToegestaan={!isBezigMetUploaden && !isSubmitting}
                        onBestandenChange={async (bestanden) => {
                          const value = await muteerBestanden((_) => bestanden);
                          setFieldValue(nameof<IFormikValues>('afbeeldingen'), value);
                        }}
                        herordenenToestaan
                      />
                    </div>

                    <div className="d-flex flex-fill flex-column">
                      <div className="d-flex flex-column flex-fill">
                        <div className="pl-3 pr-3 pb-3">
                          <button
                            className="btn btn-primary btn-sm"
                            onClick={submitForm}
                            disabled={isBezigMetUploaden || isSubmitting}
                          >
                            Opslaan
                          </button>
                          <button
                            className="btn btn-secondary btn-sm ml-2"
                            onClick={() => resetForm()}
                            disabled={isBezigMetUploaden || !dirty || isSubmitting}
                          >
                            Herstellen
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                );
              }}
            />
          )}
        </div>
      </div>
    </TableRowDetailWrapper>
  );
};

export default RowDetailComp;
