import { IOphalenHoedanighedenResultElement } from '../../../../../../../shared/src/api/v2/relatie/hoedanigheid';
import api from '../../../../../api';

export interface IHoedanighedenProvider {
  provide: () => Promise<IOphalenHoedanighedenResultElement[]>;
}

export const standaardHoedanighedenProvider: IHoedanighedenProvider = {
  provide: async () => {
    const result = await api.v2.relatie.hoedanigheid.ophalenHoedanigheden({});
    return result.hoedanigheden;
  },
};
