import { Field, FieldProps, Formik, FormikActions } from 'formik';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import api from '../../../api';
import IDialoogProps from '../../../core/IDialoogProps';
import { EResultType } from '../../../stores/CheckStore';
import { RootStoreContext } from '../../../stores/RootStore';
import Dialoog from '../../dialogen/Dialoog';
import RadioKnop from '../../formulier/RadioKnop';
import {
  EVerzendwijzeFacturen as EVerzendwijze,
  EVerzendwijzeFacturen,
} from '../../../bedrijfslogica/enums';
import nameOf from '../../../core/nameOf';
import VinkVeld from '../../formulier/VinkVeld';
import { setCommunicatieOverlayState } from '../../../one-off-components/CommunicatieOverlay';
import { BestandType } from '../../BijlagenContainer';
import { ECommunicatieTabblad } from '../../../one-off-components/CommunicatieOverlay/MenuHandle';
import { EUitstelling } from '../../communicatie/EmailWerkblad/OpstellenFormulier/VersturenKnop';
interface IProps extends IDialoogProps<null> {
  relID: number;
  persID?: number;
  factIDs: number[];
  standaardVerzendwijze?: number;
}

interface IFormikValues {
  verzendwijze: number;
  aanpassenVoorVerzenden: boolean;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  verzendwijze: '',
  aanpassenVoorVerzenden: 'Aanpassen voor verzenden',
};

const VersturenDialoog: React.FC<IProps> = (props) => {
  const { checkStore, instellingStore } = useContext(RootStoreContext);

  const initialValues = useMemo<IFormikValues>(() => {
    return {
      verzendwijze:
        props.standaardVerzendwijze !== undefined && props.standaardVerzendwijze !== null
          ? props.standaardVerzendwijze
          : instellingStore.StandaardVerzendwijzeFacturen!,
      aanpassenVoorVerzenden: false,
    };
  }, []);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);
      const params = { factIDs: props.factIDs, verzendwijze: values.verzendwijze };

      const checkData = await api.v2.factuur.checkVersturenFacturen(params);
      if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
        actions.setSubmitting(false);
        return;
      }

      if (values.aanpassenVoorVerzenden) {
        await openAanpassenSjabloon(values);
        actions.setSubmitting(false);
        props.onSuccess(null);
        return;
      }

      if (
        (
          await checkStore.bevestigen({
            inhoud: <span>Geselecteerde facturen versturen?</span>,
          })
        ).type === EResultType.Annuleren
      ) {
        actions.setSubmitting(false);
        return;
      }

      await api.v2.factuur.versturenFacturen(params);

      actions.setSubmitting(false);
      props.onSuccess(null);
    },
    [props.factIDs, props.onSuccess],
  );

  const openAanpassenSjabloon = async (values: IFormikValues) => {
    const verzendwijze =
      values.verzendwijze === EVerzendwijzeFacturen.EmailMetBijlage ? 'email' : 'sms';

    const params = {
      relID: props.relID,
      persID: props.persID,
      factIDs: props.factIDs,
      verzendwijze,
      basisNietToepassen: true,
    };

    const result = await api.v2.factuur.genererenFacturenSjabloon(params);
    const { resolveResult, persoon, bestanden, emailAdres } = result;

    switch (values.verzendwijze) {
      case EVerzendwijzeFacturen.EmailMetBijlage:
        await setCommunicatieOverlayState!((prevState) => ({
          ...prevState,
          geselecteerdTabblad: ECommunicatieTabblad.Email,
          emailContext: {
            ...prevState.emailContext,
            formulier: {
              recipients: [
                {
                  persID: persoon.PersID,
                  // Email bestaat hier, wordt gecontroleerd in de check
                  emailAdres: emailAdres, //persoon.Email!,
                },
              ],
              CC: [],
              BCC: [],
              subject: resolveResult.onderwerp ?? '',
              body: resolveResult.inhoud ?? '',
              bijlagen: bestanden.map((x) => ({
                type: BestandType.ASPDrive,
                bestand: x,
              })),
              contextRelIDs: [props.relID],
              taalID: resolveResult.taalID,
              sjabBasisID: resolveResult.sjabBasisID,
              emailBerID: null,
              fromEmailGebrID: prevState.emailContext.formulier?.fromEmailGebrID ?? null,
              uitstelling: prevState.emailContext.formulier?.uitstelling ?? EUitstelling.Direct,
            },
          },
        }));
        break;
      case EVerzendwijzeFacturen.SMSMetLink:
        await setCommunicatieOverlayState!((prevState) => ({
          ...prevState,
          geselecteerdTabblad: ECommunicatieTabblad.Sms,
          smsContext: {
            ...prevState.smsContext,
            // TelefoonMobiel bestaat hier, wordt gecontroleerd in de check
            telefoonnummer: persoon.TelefoonMobiel,
            persID: persoon.PersID,
            bericht: resolveResult.inhoud ?? '',
          },
        }));
        break;
    }
  };

  return (
    <>
      <Dialoog index={props.dialoogIndex || 0}>
        <ModalHeader>
          <ModalTitle>Facturen versturen</ModalTitle>
        </ModalHeader>

        <>
          <Formik<IFormikValues>
            // ref={formikRef}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            isInitialValid
            render={(formikProps) => {
              return (
                <>
                  <ModalBody>
                    <div className="row">
                      {/* <div className="col-12 d-flex"> */}
                      <label>{veldnamen.verzendwijze}</label>
                      <Field
                        name="verzendwijze"
                        render={({ field, form }: FieldProps<IFormikValues>) => {
                          return (
                            <div className="d-flex flex-column col-12">
                              <span className="d-flex align-items-center">
                                <RadioKnop
                                  aangevinkt={field.value === EVerzendwijze.EmailMetBijlage}
                                  onAangevinkt={() =>
                                    form.setFieldValue(field.name, EVerzendwijze.EmailMetBijlage)
                                  }
                                />
                                <span className="ml-2">Email met bijlagen</span>
                              </span>
                              {/* <span className="d-flex align-items-center mt-2">
                                <RadioKnop
                                  aangevinkt={field.value === EVerzendwijze.EmailMetLink}
                                  onAangevinkt={() =>
                                    form.setFieldValue(field.name, EVerzendwijze.EmailMetLink)
                                  }
                                />
                                <span className="ml-2">Email met link</span>
                              </span> */}
                              <span className="d-flex align-items-center mt-2">
                                <RadioKnop
                                  aangevinkt={field.value === EVerzendwijze.SMSMetLink}
                                  onAangevinkt={() =>
                                    form.setFieldValue(field.name, EVerzendwijze.SMSMetLink)
                                  }
                                />
                                <span className="ml-2">SMS met link</span>
                              </span>
                            </div>
                          );
                        }}
                      />
                      <div className="col-12 mt-3">
                        <Field
                          name={nameOf<IFormikValues>('aanpassenVoorVerzenden')}
                          render={({ field, form }: FieldProps<IFormikValues>) => {
                            return (
                              <div className="d-flex align-items-center">
                                <VinkVeld
                                  aangevinkt={field.value}
                                  onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                />
                                <span className="ml-2">{veldnamen.aanpassenVoorVerzenden}</span>
                              </div>
                            );
                          }}
                        />
                      </div>
                      {!formikProps.values.aanpassenVoorVerzenden ? null : (
                        <div className="col-12 mt-3">
                          <i>
                            Het openen van de email-bewerker duurt soms lang omdat de PDF in de
                            achtergrond moet worden aangemaakt.
                          </i>
                        </div>
                      )}
                      {/* </div> */}
                    </div>
                  </ModalBody>

                  <ModalFooter className="d-flex flex-row justify-content-start">
                    <button
                      className="btn btn-primary"
                      onClick={formikProps.submitForm}
                      style={{ width: 100 }}
                      disabled={!formikProps.isValid || formikProps.isSubmitting}
                    >
                      Ok
                    </button>
                    <button
                      className="btn btn-secondary"
                      onClick={props.onAnnuleren}
                      style={{ width: 100 }}
                      disabled={formikProps.isSubmitting}
                    >
                      Annuleren
                    </button>
                  </ModalFooter>
                </>
              );
            }}
          />
        </>
      </Dialoog>
    </>
  );
};

export default VersturenDialoog;
