import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import IDialoogProps from '../../../../core/IDialoogProps';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import nameOf from '../../../../core/nameOf';
import * as Yup from 'yup';
import api from '../../../../api';
import Dialoog from '../../../../components/dialogen/Dialoog';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import { IOphalenInventarisatiesResultElement } from '../../../../../../shared/src/api/v2/magazijn/inventarisatie';
import VinkVeld from '../../../../components/formulier/VinkVeld';

interface IDialoogResult {}

interface IFormikValues {
  afgehandeld: boolean;
}

interface IProps extends IDialoogProps<IDialoogResult> {
  id: number;
}

const WijzigenRegelDialoog: React.FC<IProps> = (props) => {
  const [inventarisatie, setInventarisatie] = useState<IOphalenInventarisatiesResultElement | null>(
    null,
  );

  const ophalenInventarisatie = useCallback(async () => {
    const regel = (
      await api.v2.magazijn.inventarisatie.ophalenInventarisaties({
        filterSchema: { filters: [{ naam: 'IDS', data: [props.id] }] },
      })
    ).inventarisaties[0];

    setInventarisatie(regel);
  }, [props.id]);

  useEffect(() => {
    ophalenInventarisatie();
  }, [ophalenInventarisatie]);

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (inventarisatie === null) {
      return null;
    }
    return {
      afgehandeld: inventarisatie.DatumAfgehandeld !== null,
    };
  }, [inventarisatie]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      const wasAfgehanded = inventarisatie!.DatumAfgehandeld !== null;

      await api.v2.magazijn.inventarisatie.wijzigenInventarisatie({
        id: props.id,
        afgehandeld: wasAfgehanded === values.afgehandeld ? undefined : values.afgehandeld,
      });

      await props.onSuccess({});
      actions.setSubmitting(false);
    },
    [props.onSuccess, inventarisatie],
  );

  const validationSchema = useMemo(() => Yup.object().shape({}), []);

  const notitiesRef = useRef<HTMLTextAreaElement>(null);
  useEffect(() => {
    notitiesRef.current?.focus();
  }, []);

  if (initialValues === null) {
    return <LoadingSpinner />;
  }

  return (
    <Dialoog
      index={props.dialoogIndex || 0}
      modalProps={{
        size: `lg`,
      }}
    >
      <ModalHeader>
        <ModalTitle>Inventarisatie wijzigen</ModalTitle>
      </ModalHeader>
      <Formik<IFormikValues>
        onSubmit={handleSubmit}
        initialValues={initialValues}
        // isInitialValid
        validationSchema={validationSchema}
        render={(formikProps: FormikProps<IFormikValues>) => {
          const { isValid, isSubmitting, submitForm } = formikProps;

          return (
            <>
              <ModalBody>
                <div className="row">
                  <div className="col-12">
                    <Field
                      name={nameOf<IFormikValues>('afgehandeld')}
                      render={(fieldProps: FieldProps<IFormikValues>) => {
                        const { field, form } = fieldProps;

                        return (
                          <div className="d-flex align-items-center">
                            <VinkVeld
                              aangevinkt={field.value}
                              onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                            />
                            <span className="ml-2">Afgehandeld</span>
                          </div>
                        );
                      }}
                    />
                  </div>
                </div>
              </ModalBody>
              <ModalFooter className="d-flex flex-row justify-content-start">
                <button
                  className="btn btn-primary d-flex align-items-center justify-content-center"
                  onClick={submitForm}
                  style={{ width: 100 }}
                  disabled={isSubmitting || !isValid}
                >
                  Ok
                </button>
                <button
                  className="btn btn-secondary"
                  onClick={props.onAnnuleren}
                  style={{ width: 100 }}
                  disabled={isSubmitting}
                >
                  Annuleren
                </button>
              </ModalFooter>
            </>
          );
        }}
      />
    </Dialoog>
  );
};

export default WijzigenRegelDialoog;
