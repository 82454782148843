import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IFormulierComponentProps, IModelData } from './index';
import { Field, FieldProps, Formik, FormikErrors } from 'formik';
import api from '../../../../../api';
import MultiCombobox, { IKolom } from '../../../../../components/formulier/MultiCombobox';
import FormikVeldFout from '../../../../../components/formulier/FormikVeldFout';
import nameOf from '../../../../../core/nameOf';
import LoadingSpinner from '../../../../../components/Gedeeld/LoadingSpinner';
import NumeriekVeld from '../../../../../components/formulier/NumeriekVeld';
import InactiefOverlay from '../../../../../components/InactiefOverlay';
import VinkVeld from '../../../../../components/formulier/VinkVeld';
import UitlegTooltip from '../../../../../components/formulier/UitlegTooltip';
import {
  IOphalenAanbodResult,
  IOphalenAanbodResultElement,
  IOphalenAanbodResultElementTariefElement,
} from '../../../../../../../shared/src/api/v2/aanbod/index';
import AfbeeldingKader from '../../../../../components/weergave/AfbeeldingKader';
import FormatteerBedrag from '../../../../../components/MutatieBedrag';
import { formatteerBedrag } from '../../../../../helpers';

interface IProps extends IFormulierComponentProps<IModelData> {
  relID: number | null;
  data: IModelData | null;
  onChange: (data: IModelData | null) => void;
}

interface IFormikValues {
  prodModID: number | null;
  duurInMaandenActief: boolean;
  duurInMaanden: number | null;
  kortingspercentage: number | null;
  vanafAbonID: number | null;
  heeftActieOpEenmaligBedrag: boolean;
  heeftActieOpMaandhuur: boolean;
}

const ModelFormulier: React.FC<IProps> = (props) => {
  const [aanbod, setAanbod] = useState<IOphalenAanbodResult | null>(null);
  const ophalenAanbod = useCallback(async () => {
    const result = await api.v2.aanbod.ophalenAanbod({
      filterSchema: {
        filters: [{ naam: 'REL_ID', data: props.relID }],
      },
    });

    setAanbod(result);
  }, [props.relID]);

  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    ophalenAanbod();
  }, [ophalenAanbod]);

  const modelKolommen = useMemo<IKolom<IOphalenAanbodResultElement>[]>(() => {
    return [
      {
        key: 'afbeeldingen',
        label: '',
        formatFabriek: (row) => {
          const afbeeldingToken = row.afbeeldingen.length === 0 ? null : row.afbeeldingen[0];
          const afbeeldingSrc = afbeeldingToken === null ? null : afbeeldingToken.bestand.url;
          return (
            <AfbeeldingKader
              afbeelding={
                afbeeldingSrc === null ? null : (
                  <img src={afbeeldingSrc} style={{ width: 20, height: 20 }} />
                )
              }
              width={20}
              height={20}
            />
          );
        },
        breedte: 50,
      },
      {
        key: 'Modelnaam',
        label: 'Naam',
        breedte: 250,
      },
      {
        key: 'Modelcode',
        label: 'Code',
        breedte: 150,
      },
      {
        key: 'laagsteMaandhuur',
        label: 'Huur vanaf',
        breedte: 125,
        formatFabriek: (row) => <FormatteerBedrag bedrag={row.laagsteMaandhuur} />,
      },
    ];
  }, []);

  const abonnementKolommen = useMemo<IKolom<IOphalenAanbodResultElementTariefElement>[]>(() => {
    return [
      {
        key: 'Naam',
        label: 'Naam',
        breedte: 100,
      },
      {
        key: 'MinimaleTermijn',
        label: 'Minimale termijn',
        breedte: 125,
      },
      {
        key: 'Maandhuur',
        label: 'Maandhuur',
        breedte: 100,
        formatFabriek: (entiteit) => <FormatteerBedrag bedrag={entiteit.Maandhuur} />,
      },
    ];
  }, []);

  const initieleWaarden: IFormikValues = useMemo(() => {
    return {
      prodModID: null,
      duurInMaandenActief: true,
      duurInMaanden: 1,
      kortingspercentage: 100,
      vanafAbonID: null,
      heeftActieOpEenmaligBedrag: false,
      heeftActieOpMaandhuur: true,
    };
  }, []);

  // if (aanbod === null) {
  //   return (
  //     <div className="d-flex align-items-center justify-content-center flex-fill">
  //       <LoadingSpinner />
  //     </div>
  //   );
  // }

  return (
    <Formik<IFormikValues>
      initialValues={initieleWaarden}
      onSubmit={() => {}}
      validate={(values: IFormikValues) => {
        const errors: FormikErrors<IFormikValues> = {};

        if (values.prodModID === null) {
          errors.prodModID = 'Veld is verplicht.';
        }
        if (values.vanafAbonID === null) {
          errors.vanafAbonID = 'Veld is verplicht.';
        }
        if (!values.duurInMaandenActief && values.kortingspercentage === 100) {
          errors.kortingspercentage =
            'Een kortingspercentage van 100% (gratis) is niet mogelijk zonder gedurende looptijd.';
        }

        if (Object.keys(errors).length > 0) {
          props.onChange(null);
        } else {
          const tarieven = aanbod!.find((model) => model.ProdModID === values.prodModID)!.tarieven;
          const vanafTarief = tarieven.find((tarief) => tarief.AbonID === values.vanafAbonID!)!;
          const abonIDs = tarieven
            .filter((tarief) => tarief.MinimaleTermijn >= vanafTarief.MinimaleTermijn)
            .map((x) => x.AbonID);

          props.onChange({
            prodModID: values.prodModID!,
            abonIDs,
            heeftActieOpMaandhuur: values.heeftActieOpMaandhuur,
            actieOpMaandhuur: values.heeftActieOpMaandhuur
              ? {
                  duurInMaanden: values.duurInMaanden!,
                  kortingspercentage: values.kortingspercentage!,
                }
              : null,
            heeftActieOpEenmaligBedrag: values.heeftActieOpEenmaligBedrag,
          });
        }
        return errors;
      }}
      render={(formikProps) => {
        const { values } = formikProps;
        const abonnementOpties =
          values.prodModID === null
            ? []
            : aanbod!.find((model) => model.ProdModID === values.prodModID)!.tarieven;

        return (
          <div className="form-group">
            <div className="row">
              <div className="col-12">
                <label>Productmodel</label>
                <Field
                  name={nameOf<IFormikValues>('prodModID')}
                  render={(fieldProps: FieldProps<IFormikValues>) => {
                    const { field, form } = fieldProps;
                    return (
                      <>
                        <MultiCombobox<number, IOphalenAanbodResultElement>
                          sleutelExtractor={(row) => row.ProdModID}
                          onWaardeChange={(waarde: number | null) => {
                            form.setFieldValue(nameOf<IFormikValues>('vanafAbonID'), null);
                            form.setFieldValue(field.name, waarde);
                          }}
                          representatieFabriek={(row) => `${row.Modelnaam} - ${row.Modelcode}`}
                          waarde={fieldProps.field.value}
                          opties={aanbod!}
                          kolommen={modelKolommen}
                        />
                        <FormikVeldFout fieldProps={fieldProps} />
                      </>
                    );
                  }}
                />
              </div>

              <div className="col-12 mt-3">
                <label>
                  <div className="d-flex">
                    Vanaf abonnement
                    <span className="ml-2">
                      <UitlegTooltip
                        inhoud={
                          <span>
                            Vanaf het geselecteerde abonnement en omhoog (abonnement met een langere
                            looptijd) zal de actie worden toegepast.
                          </span>
                        }
                      />
                    </span>
                  </div>
                </label>
                <Field
                  name={nameOf<IFormikValues>('vanafAbonID')}
                  render={(fieldProps: FieldProps<IFormikValues>) => {
                    const { field, form } = fieldProps;
                    return (
                      <div>
                        <InactiefOverlay
                          isInactief={form.values.prodModID === null}
                          element={
                            <>
                              <MultiCombobox<number, IOphalenAanbodResultElementTariefElement>
                                sleutelExtractor={(row) => row.AbonID}
                                onWaardeChange={(waarde: number | null) =>
                                  fieldProps.form.setFieldValue(fieldProps.field.name, waarde)
                                }
                                representatieFabriek={(row) =>
                                  row.Naam + ' - ' + formatteerBedrag(row.Maandhuur)
                                }
                                waarde={fieldProps.field.value}
                                opties={abonnementOpties}
                                kolommen={abonnementKolommen}
                                // Er moet eerst een product model gekozen worden voordat er een
                                // abonnement kan worden geselecteerd
                                disabled={values.prodModID === null}
                              />
                            </>
                          }
                        />

                        <FormikVeldFout fieldProps={fieldProps} />
                      </div>
                    );
                  }}
                />
              </div>

              <div className="row d-flex col-12">
                <div className="col-6 mt-3">
                  <div>
                    <Field
                      name={nameOf<IFormikValues>('heeftActieOpMaandhuur')}
                      render={(fieldProps: FieldProps<IFormikValues>) => {
                        const { field, form } = fieldProps;

                        return (
                          <>
                            <div className="d-flex align-items-center">
                              <VinkVeld
                                aangevinkt={field.value}
                                onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                              />
                              <span className="ml-2">Actie op de maandhuur</span>
                            </div>
                            <FormikVeldFout fieldProps={fieldProps} />
                          </>
                        );
                      }}
                    />
                  </div>
                  <div className="col-12 mt-3">
                    <label>
                      <div className="d-flex">
                        Duur in maanden
                        <span className="ml-2">
                          <UitlegTooltip
                            inhoud={
                              <span>
                                Bij het niet toepassen van dit veld wordt de actie toegepast voor de
                                gedurende looptijd van het contract.
                              </span>
                            }
                          />
                        </span>
                      </div>
                    </label>
                    <Field
                      name={nameOf<IFormikValues>('duurInMaanden')}
                      render={(fieldProps: FieldProps<IFormikValues>) => {
                        const { field, form } = fieldProps;
                        return (
                          <>
                            <div className="d-flex align-items-center">
                              <VinkVeld
                                aangevinkt={form.values.duurInMaandenActief}
                                onGewijzigd={(aangevinkt) =>
                                  form.setFieldValue(
                                    nameOf<IFormikValues>('duurInMaandenActief'),
                                    aangevinkt,
                                  )
                                }
                              />
                              <span className="ml-2">
                                <InactiefOverlay
                                  isInactief={!form.values.duurInMaandenActief}
                                  element={
                                    <NumeriekVeld
                                      min={1}
                                      max={12}
                                      waarde={field.value}
                                      onChange={(x) => form.setFieldValue(field.name, x)}
                                    />
                                  }
                                />
                              </span>
                            </div>
                            <FormikVeldFout fieldProps={fieldProps} />
                          </>
                        );
                      }}
                    />
                  </div>
                  <div className="col-12 mt-3">
                    <label>Kortingspercentage</label>
                    <Field
                      name={nameOf<IFormikValues>('kortingspercentage')}
                      render={(fieldProps: FieldProps<IFormikValues>) => {
                        const { field, form } = fieldProps;
                        return (
                          <>
                            <NumeriekVeld
                              min={1}
                              max={100}
                              waarde={field.value}
                              onChange={(x) => form.setFieldValue(field.name, x)}
                              inactiefRepresentatie={(input) => {
                                if (field.value === 100) {
                                  return '100% (gratis)';
                                }
                                return `${field.value}%`;
                              }}
                            />
                            <FormikVeldFout fieldProps={fieldProps} />
                          </>
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="d-flex col-6 mt-3">
                  <div>
                    <Field
                      name={nameOf<IFormikValues>('heeftActieOpEenmaligBedrag')}
                      render={(fieldProps: FieldProps<IFormikValues>) => {
                        const { field, form } = fieldProps;

                        return (
                          <>
                            <div className="d-flex align-items-center">
                              <VinkVeld
                                aangevinkt={field.value}
                                onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                              />
                              <span className="ml-2">Geen eenmalig bedrag</span>
                            </div>
                            <FormikVeldFout fieldProps={fieldProps} />
                          </>
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }}
    />
  );
};

export default ModelFormulier;
