import api from '../../..';
import dagboek from './dagboek';
import grootboek from './grootboek';
import {
  IOphalenBoekingenParams,
  IOphalenBoekingenResult,
  IOphalenBoekingRegelsParams,
  IOphalenBoekingRegelsResult,
  IOphalenWerkkostenRegelingenParams,
  IOphalenWerkkostenRegelingenResult,
  IDefinitiefMakenBoekingenParams,
  IDefinitiefMakenBoekingenResult,
  IDefinitiefOngedaanMakenBoekingenParams,
  IDefinitiefOngedaanMakenBoekingenResult,
  IVastleggenBoekingParams,
  IVastleggenBoekingResult,
  IAfboekenVerkoopfacturenParams,
  IAfboekenVerkoopfacturenResult,
  IInlezenLoonjournaalParams,
  IInlezenLoonjournaalResult,
  IOphalenExportsParams,
  IOphalenExportsResult,
  IExporterenParams,
  IExporterenResult,
  IOphalenRelatiesMetSaldoGrootboekParams,
  IOphalenRelatiesMetSaldoGrootboekResult,
  IOphalenSaldiGrootboekrekeningenParams,
  IOphalenSaldiGrootboekrekeningenResult,
  IVerwijderenBoekingenParams,
  IVerwijderenBoekingenResult,
  IToevoegenBoekingenParams,
  IToevoegenBoekingenResult,
  IOphalenBoekstuksaldoResult,
  IOphalenBoekstuksaldoParams,
  IOphalenBoekstukkensaldoParams,
  IOphalenBoekstukkensaldoResult,
  IVerwijderenExportsParams,
  IVerwijderenExportsResult,
  IWijzigenStatusGeimporteerdParams,
  IWijzigenStatusGeimporteerdResult,
  IBoekenVerkoopfacturenInBetalingsregelingParams,
  IBoekenVerkoopfacturenInBetalingsregelingResult,
  IAfboekenSaldoVooruitOntvangenBedragenParams,
  IAfboekenSaldoVooruitOntvangenBedragenResult,
} from '../../../../../../shared/src/api/v2/boekhouding/boeking';
import ICheckData from '../../../../../../shared/src/models/ICheckData';
import bank from './bank';
import { IAchtergrondProces } from '../../../../../../shared/src/api/v2/achtergrondProces';

const boeking = {
  dagboek,
  grootboek,
  bank,
  checkVerwijderenBoekingen: async (params: IVerwijderenBoekingenParams): Promise<ICheckData> => {
    return await api.post('/v2/boeking/check-verwijderen-boekingen', params);
  },
  verwijderenBoekingen: async (
    params: IVerwijderenBoekingenParams,
  ): Promise<IVerwijderenBoekingenResult> => {
    return await api.post('/v2/boeking/verwijderen-boekingen', params);
  },
  ophalenSaldiGrootboekrekeningen: async (
    params: IOphalenSaldiGrootboekrekeningenParams,
  ): Promise<IOphalenSaldiGrootboekrekeningenResult> => {
    return await api.post('/v2/boeking/ophalen-saldi-grootboekrekeningen', params);
  },
  // ophalenDCBoekingen: async (
  //   params: IOphalenDCBoekingenParams,
  // ): Promise<IOphalenDCBoekingenResult> => {
  //   return await api.post('/v2/boeking/ophalen-dc-boekingen', params);
  // },
  ophalenBoekingen: async (
    params: IOphalenBoekingenParams,
    signal?: AbortSignal,
  ): Promise<IOphalenBoekingenResult> => {
    return await api.post('/v2/boeking/ophalen-boekingen', params, { signal });
  },
  ophalenBoekingregels: async (
    params: IOphalenBoekingRegelsParams,
    signal?: AbortSignal,
  ): Promise<IOphalenBoekingRegelsResult> => {
    return await api.post('/v2/boeking/ophalen-boeking-regels', params, {
      signal,
    });
  },
  ophalenWerkkostenRegelingen: async (
    params: IOphalenWerkkostenRegelingenParams,
  ): Promise<IOphalenWerkkostenRegelingenResult> => {
    return await api.post('/v2/boeking/ophalen-werkkosten-regelingen', params);
  },
  checkDefinitiefMakenBoekingen: async (
    params: IDefinitiefMakenBoekingenParams,
  ): Promise<ICheckData> => {
    return await api.post('/v2/boeking/check-definitief-maken-boekingen', params);
  },
  definitiefMakenBoekingen: async (
    params: IDefinitiefMakenBoekingenParams,
  ): Promise<IDefinitiefMakenBoekingenResult> => {
    return await api.post('/v2/boeking/definitief-maken-boekingen', params);
  },
  checkDefinitiefOngedaanMakenBoekingen: async (
    params: IDefinitiefMakenBoekingenParams,
  ): Promise<ICheckData> => {
    return await api.post('/v2/boeking/check-definitief-ongedaan-maken-boekingen', params);
  },
  definitiefOngedaanMakenBoekingen: async (
    params: IDefinitiefOngedaanMakenBoekingenParams,
  ): Promise<IDefinitiefOngedaanMakenBoekingenResult> => {
    return await api.post('/v2/boeking/definitief-ongedaan-maken-boekingen', params);
  },
  checkAfboekenVerkoopfacturen: async (
    params: IAfboekenVerkoopfacturenParams,
  ): Promise<ICheckData> => {
    return await api.post('/v2/boeking/check-afboeken-verkoopfacturen', params);
  },
  afboekenVerkoopfacturen: async (
    params: IAfboekenVerkoopfacturenParams,
  ): Promise<IAfboekenVerkoopfacturenResult> => {
    return await api.post('/v2/boeking/afboeken-verkoopfacturen', params);
  },
  inlezenLoonjournaal: async (
    params: IInlezenLoonjournaalParams,
  ): Promise<IInlezenLoonjournaalResult> => {
    return await api.post('/v2/boeking/inlezen-loonjournaal', params);
  },
  ophalenExports: async (params: IOphalenExportsParams): Promise<IOphalenExportsResult> => {
    return await api.post('/v2/boeking/ophalen-exports', params);
  },
  exporteren: async (params: IExporterenParams): Promise<IAchtergrondProces<IExporterenResult>> => {
    return await api.post('/v2/boekhouding/boeking/exporteren-boekingen', params);
  },
  checkVerwijderenExports: async (params: IVerwijderenExportsParams): Promise<ICheckData> => {
    return await api.post('/v2/boeking/check-verwijderen-exports', params);
  },
  verwijderenExports: async (
    params: IVerwijderenExportsParams,
  ): Promise<IVerwijderenExportsResult> => {
    return await api.post('/v2/boeking/verwijderen-exports', params);
  },
  ophalenRelatiesMetSaldoGrootboek: async (
    params: IOphalenRelatiesMetSaldoGrootboekParams,
  ): Promise<IOphalenRelatiesMetSaldoGrootboekResult> => {
    return await api.post('/v2/boekhouding/boeking/ophalen-relaties-met-saldo-grootboek', params);
  },

  vastleggenBoeking: async (
    params: IVastleggenBoekingParams,
  ): Promise<IVastleggenBoekingResult> => {
    return await api.post('/v2/boeking/vastleggen-boeking', params);
  },
  checkVastleggenBoeking: async (params: IVastleggenBoekingParams): Promise<ICheckData> => {
    return await api.post('/v2/boeking/check-vastleggen-boeking', params);
  },

  // Deze zijn de juiste
  checkToevoegenBoeking: async (
    params: IToevoegenBoekingenParams,
  ): Promise<IToevoegenBoekingenResult> => {
    return await api.post('/v2/boeking/check-toevoegen-boekingen', params);
  },
  toevoegenBoeking: async (params: IToevoegenBoekingenParams): Promise<ICheckData> => {
    return await api.post('/v2/boeking/toevoegen-boekingen', params);
  },
  ophalenBoekstuksaldo: async (
    params: IOphalenBoekstuksaldoParams,
  ): Promise<IOphalenBoekstuksaldoResult> => {
    return await api.post('/v2/boekhouding/boeking/ophalen-boekstuksaldo', params);
  },
  ophalenBoekstukkensaldo: async (
    params: IOphalenBoekstukkensaldoParams,
  ): Promise<IOphalenBoekstukkensaldoResult> => {
    return await api.post('/v2/boekhouding/boeking/ophalen-boekstukkensaldo', params);
  },
  wijzigenStatusGeimporteerd: async (
    params: IWijzigenStatusGeimporteerdParams,
  ): Promise<IWijzigenStatusGeimporteerdResult> => {
    return await api.post('/v2/boekhouding/boeking/wijzigen-status-geimporteerd', params);
  },
  checkBoekenVerkoopfacturenInBetalingsregeling: async (
    params: IBoekenVerkoopfacturenInBetalingsregelingParams,
  ): Promise<ICheckData> => {
    return await api.post(
      '/v2/boekhouding/boeking/check-boeken-verkoopfacturen-in-Betalingsregeling',
      params,
    );
  },
  boekenVerkoopfacturenInBetalingsregeling: async (
    params: IBoekenVerkoopfacturenInBetalingsregelingParams,
  ): Promise<IBoekenVerkoopfacturenInBetalingsregelingResult> => {
    return await api.post(
      '/v2/boekhouding/boeking/boeken-verkoopfacturen-in-Betalingsregeling',
      params,
    );
  },
  afboekenSaldoVooruitOntvangenBedragen: async (
    params: IAfboekenSaldoVooruitOntvangenBedragenParams,
  ): Promise<IAfboekenSaldoVooruitOntvangenBedragenResult> => {
    return await api.post(
      '/v2/boekhouding/boeking/afboeken-saldo-vooruit-ontvangen-bedragen',
      params,
    );
  },
};

export default boeking;
