import React, { useCallback, useContext, useMemo, useState } from 'react';
import Tegel from '../../../../../../../components/tegel/Tegel';
import Avatar from '../../../../../../../components/personalia/Avatar';
import { formatteerPersoonNaam } from '../../../../../../../helpers';
import { IconVerwijderen, IconWijzigen } from '../../../../../../../components/Icons';
import { Kleur as EKleur } from '../../../../../../../bedrijfslogica/constanten';
import { IOphalenAccountsResultElement } from '../../../../../../../../../shared/src/api/v2/account/account';
import api from '../../../../../../../api';
import ActieMenuKnop, { IActie } from '../../../../../../../components/ActieMenuKnop';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { format } from 'date-fns';
import GegevensLayout from '../../../../../../../components/layout/GegevensLayout';
import TegelUitklapMenu from '../../../../../../../components/tegel/TegelUitklapMenu';
import Logging from './Logging';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../../../../../stores/RootStore';
import { EResultType } from '../../../../../../../stores/CheckStore';
import KeuzeDialoog, {
  EKeuzeType,
  IOptie,
  ISelectieResult,
} from '../../../../../../../components/dialogen/KeuzeDialoog';
import WijzigenAccountDialoog from '../ModalAccountWijzigen';
import KopieerNaarKlembord from '../../../../../../../components/KopieerNaarKlembord';

interface IProps {
  account: IOphalenAccountsResultElement;
  onVerversenAangevraagd: () => void;
}

export interface ISectieProps {
  accID: number;
}

enum ESectie {
  Logging,
}

enum EKanaal {
  SMS = 'SMS',
  Email = 'EMAIL',
}

const sectieComponentMapping: { [sectie: number]: React.ComponentType<ISectieProps> } = {
  [ESectie.Logging]: Logging,
};

const AccountTegel: React.FC<IProps> = observer((props) => {
  const { account } = props;
  const { checkStore } = useContext(RootStoreContext);

  const [uitgeklaptSectieId, setUitgeklaptSectieId] = useState<ESectie | null>(null);
  const [wijzigAccID, setWijzigAccID] = useState<number | null>(null);
  const [wijzigenDialoogOpen, setWijzigenDialoogOpen] = useState<boolean>(false);
  const [activatieLinkSturenDialoogTonen, setActivatieLinkSturenDialoogTonen] = useState<boolean>(
    false,
  );
  const [wachtwoordVergetenDialoogTonen, setWachtwoordVergetenDialoogTonen] = useState<boolean>(
    false,
  );

  const handleActivatielinkSturenClick = useCallback(
    async (ev: React.MouseEvent<HTMLButtonElement>) => {
      ev.stopPropagation();

      if (account.Email === null && account.TelefoonMobiel === null) {
        await checkStore.melden({
          titel: 'De persoon heeft geen email en telefoonnummer',
        });
        return;
      }
      setActivatieLinkSturenDialoogTonen(true);
    },
    [setActivatieLinkSturenDialoogTonen],
  );

  const handleActievatielinkSturenDialoogOnSuccess = useCallback(
    async (result: ISelectieResult<EKanaal>) => {
      setActivatieLinkSturenDialoogTonen(false);

      await api.v2.account.versturenActivatielink({
        accID: account.AccID,
        kanalen: result.keuze as string[],
      });
    },
    [account.AccID, setActivatieLinkSturenDialoogTonen],
  );

  const handleWachtwoordVergetenClick = useCallback(
    (ev: React.MouseEvent<HTMLButtonElement>) => {
      ev.stopPropagation();
      setWachtwoordVergetenDialoogTonen(true);
    },
    [setWachtwoordVergetenDialoogTonen],
  );

  const handleWachtwoordVergetenLinkDialoogOnSuccess = useCallback(
    async (result: ISelectieResult<EKanaal>) => {
      setWachtwoordVergetenDialoogTonen(false);

      // const resultaat = await checkStore.bevestigen({
      //   titel: `Wachtwoord-vergeten-link sturen ?`,
      // });
      // if (resultaat.type === EResultType.Annuleren) {
      //   return;
      // }

      await api.v2.account.versturenWachtwoordVergetenLink({
        accID: account.AccID,
        kanalen: result.keuze as string[],
        email: account.Email,
        telefoonnummer: account.TelefoonMobiel,
      });
    },
    [account, setWachtwoordVergetenDialoogTonen],
  );

  const handleAanpassenClick = useCallback(() => {
    setWijzigenDialoogOpen(true);
  }, [setWijzigenDialoogOpen]);

  const handleVerwijderenClick = useCallback(async () => {
    const accIDs = [account.AccID];
    const checkData = await api.v2.account.checkVerwijderenAccounts({
      accIDs,
    });

    const controlerenResult = await checkStore.controleren({
      checkData,
    });
    if (controlerenResult.type === EResultType.Annuleren) {
      return;
    }

    if (
      (await checkStore.bevestigen({ inhoud: 'Account verwijderen?' })).type ===
      EResultType.Annuleren
    ) {
      return;
    }

    await api.v2.account.verwijderenAccounts({
      accIDs,
    });
  }, [account.AccID]);

  const acties = useMemo<IActie[]>(
    () => [
      // {
      //   icon: <IconWijzigen style={{ fill: Kleur.Grijs, width: 16, height: 16 }} />,
      //   text: 'Aanpassen',
      //   onClick: handleAanpassenClick,
      // },
      {
        icon: <IconVerwijderen style={{ fill: EKleur.Grijs, width: 16, height: 16 }} />,
        text: 'Verwijderen',
        onClick: handleVerwijderenClick,
      },
      {
        text: 'Verwijderen inzake AVG',
        onClick: () => {
          alert('Nog implementeren');
        },
      },
    ],
    [handleAanpassenClick],
  );

  const SectieComp = useMemo(() => {
    if (uitgeklaptSectieId === null) {
      return null;
    }
    return sectieComponentMapping[uitgeklaptSectieId];
  }, [uitgeklaptSectieId]);

  const handleWijzigenAccountSuccess = useCallback(() => {
    props.onVerversenAangevraagd();
    setWijzigenDialoogOpen(false);
  }, [setWijzigenDialoogOpen, props.onVerversenAangevraagd]);

  const handleWijzigenAccountAnnuleren = useCallback(() => {
    setWijzigenDialoogOpen(false);
  }, [setWijzigenDialoogOpen]);

  const kanaalOpties = useMemo<IOptie<EKanaal>[]>(() => {
    return [
      account.Email !== null
        ? {
            id: EKanaal.Email,
            label: 'Email',
          }
        : null,
      account.TelefoonMobiel !== null
        ? {
            id: EKanaal.SMS,
            label: 'SMS',
          }
        : null,
    ].filter((x) => x !== null) as IOptie<EKanaal>[];
  }, [account]);

  return (
    <>
      <Tegel key={account.AccID}>
        <div className="d-flex">
          <div className="mt-1">
            <Avatar size="40px">{account.Voorletters}</Avatar>
          </div>
          <div className="col-4 pl-4">
            <div className="font-weight-bold">
              {formatteerPersoonNaam({
                aanhefKort: account.AanhefKort,
                achternaam: account.Achternaam,
                voorletters: account.Voorletters,
                voornaam: account.Voornaam,
                voorvoegsel: account.Voorvoegsel,
              })}
            </div>
            <div className="d-flex">
              <div className="mb-3">{account.Gebruikersnaam}</div>
              <span className="ml-2">
                <KopieerNaarKlembord waarde={account.Gebruikersnaam} />
              </span>
              {account.Gebruikersnaam !== account.Email && (
                <span className="ml-3" style={{ color: EKleur.Blauw }}>
                  Verschilt van emailadres
                </span>
              )}
            </div>

            <GegevensLayout
              gegevens={[
                {
                  label: 'Telefoon',
                  waarde: account.TelefoonMobiel,
                },
                {
                  label: 'Email',
                  waarde: account.Email,
                },
              ]}
            />
          </div>
          <div className="col-4 pl-4">
            <GegevensLayout
              gegevens={[
                {
                  label: 'Registratie',
                  waarde:
                    account.RecordToegevoegd !== null
                      ? format(new Date(account.RecordToegevoegd), 'dd-MM-yyyy HH:mm')
                      : '',
                },
                {
                  label: 'Activatie',
                  waarde:
                    account.Activatiedatum !== null
                      ? format(new Date(account.Activatiedatum), 'dd-MM-yyyy HH:mm')
                      : 'Nog activeren',
                },
                {
                  label: 'Geblokkeerd',
                  waarde: account.Geblokkeerd ? (
                    <span style={{ color: EKleur.Rood }}>Ja</span>
                  ) : (
                    'Nee'
                  ),
                },
                {
                  label: (
                    <span style={{ color: account.Uitgeschakeld ? EKleur.Rood : undefined }}>
                      Uitgeschakeld
                    </span>
                  ),
                  waarde: account.Uitgeschakeld ? (
                    <span style={{ color: EKleur.Rood }}>Ja</span>
                  ) : (
                    'Nee'
                  ),
                },
              ]}
            />
          </div>
          <div className="ml-4 flex-fill">
            <div>
              {account.NieuwWachtwoord && (
                <span style={{ color: EKleur.Blauw }}>Moet wachtwoord zetten bij inloggen</span>
              )}
            </div>
          </div>
          <div>
            <ActieMenuKnop acties={acties} />
          </div>
        </div>
        <div className="mt-3 ml-1 d-flex justify-content-between align-items-right">
          <TegelUitklapMenu<ESectie>
            uitgeklaptId={uitgeklaptSectieId}
            onUitgeklaptIdChange={(id) => setUitgeklaptSectieId(id)}
            secties={[
              {
                id: ESectie.Logging,
                label: 'Logging',
              },
            ]}
          />
          <>
            <div className="d-flex justify-content-end">
              {account.Activatiedatum === null && (
                <>
                  <button
                    className="btn btn-sm btn-light ml-2"
                    onClick={handleActivatielinkSturenClick}
                    style={{
                      pointerEvents: account.Activatiedatum === null ? 'inherit' : 'none',
                    }}
                  >
                    Activatielink sturen
                  </button>
                </>
              )}
              {/* {account.Activatiedatum !== null && ( */}
              <button className="btn btn-sm btn-light ml-2" onClick={() => handleAanpassenClick()}>
                Wijzigen account
              </button>
              {/* )} */}
              {account.Activatiedatum !== null && (
                <button
                  className="btn btn-sm btn-light ml-2"
                  onClick={handleWachtwoordVergetenClick}
                >
                  Wachtwoord vergeten
                </button>
              )}
            </div>
          </>
        </div>
        {SectieComp !== null && (
          <>
            <div style={{ borderTop: `1px solid ${EKleur.LichtGrijs}` }} className="my-3" />
            <SectieComp accID={account.AccID} />
          </>
        )}
      </Tegel>
      {wijzigenDialoogOpen && (
        <WijzigenAccountDialoog
          open={wijzigenDialoogOpen}
          onSuccess={handleWijzigenAccountSuccess}
          onAnnuleren={handleWijzigenAccountAnnuleren}
          account={account}
        />
      )}
      {activatieLinkSturenDialoogTonen && (
        <KeuzeDialoog
          titel="Activatielink sturen via"
          type={EKeuzeType.Enkel}
          opties={kanaalOpties}
          open
          onSuccess={handleActievatielinkSturenDialoogOnSuccess}
          onAnnuleren={() => setActivatieLinkSturenDialoogTonen(false)}
          // meerOptions={{
          //   beginSelectie: [EKanaal.Email],
          // }}
        />
      )}
      {wachtwoordVergetenDialoogTonen && (
        <KeuzeDialoog
          titel="Wachtwoord-vergeten link sturen via"
          type={EKeuzeType.Enkel}
          opties={kanaalOpties}
          open
          onSuccess={handleWachtwoordVergetenLinkDialoogOnSuccess}
          onAnnuleren={() => setWachtwoordVergetenDialoogTonen(false)}
          // meerOptions={{
          //   beginSelectie: [EKanaal.Email],
          // }}
        />
      )}
    </>
  );
});

export default AccountTegel;
