import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import IPopupProps, { parsePopupProps } from '../../../../../../core/IPopupProps';
import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import nameof from '../../../../../../core/nameOf';
import FormikVeldFout from '../../../../../../components/formulier/FormikVeldFout';
import { v4 as uuidv4 } from 'uuid';

export interface IFormikValues {
  actiecode: string;
}

interface IPopupResult {
  actiecode: string;
}

interface IProps extends IPopupProps<IPopupResult> {
  initialValues?: IFormikValues;
}

const ActiecodeMuterenPopupFormulier: React.FC<IProps> = (p) => {
  const props = useMemo(() => parsePopupProps(p), [p]);
  const id = useMemo(() => uuidv4(), []);
  const actiecodeInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!props.open || actiecodeInputRef.current === null) {
      return;
    }
    actiecodeInputRef.current.focus();
  }, [props.open]);

  const initialValues = useMemo<IFormikValues>(() => {
    if (props.initialValues !== undefined) {
      return props.initialValues;
    }

    return {
      actiecode: '',
    };
  }, [props.initialValues]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      props.onSuccess({
        actiecode: values.actiecode,
      });

      actions.setSubmitting(false);
    },
    [],
  );

  return (
    <Overlay
      target={props.target as any}
      show={props.open}
      onHide={!props.blurIsImplicietAnnuleren ? undefined : props.onAnnuleren}
      rootClose
      placement={props.placement}
    >
      <Popover id={id}>
        <div className="p-3">
          <Formik<IFormikValues>
            initialValues={initialValues}
            onSubmit={handleSubmit}
            render={(formikProps: FormikProps<IFormikValues>) => {
              const { submitForm, isSubmitting } = formikProps;
              return (
                <>
                  <div>
                    <label>Code</label>
                    <Field
                      name={nameof<IFormikValues>('actiecode')}
                      render={(fieldProps: FieldProps<IFormikValues>) => {
                        const { field, form } = fieldProps;
                        return (
                          <>
                            <input
                              ref={actiecodeInputRef}
                              type="text"
                              className="form-control"
                              {...field}
                              onChange={(ev) =>
                                form.setFieldValue(field.name, ev.target.value.toUpperCase())
                              }
                              onKeyUp={(ev) => {
                                if (ev.key === 'Enter') {
                                  form.submitForm();
                                }
                              }}
                            />
                            <FormikVeldFout fieldProps={fieldProps} />
                          </>
                        );
                      }}
                    />
                  </div>
                  <div className="d-flex flex-row justify-content-start mt-3">
                    <button
                      className="btn btn-primary"
                      onClick={submitForm}
                      style={{ width: 100 }}
                      disabled={isSubmitting}
                    >
                      Ok
                    </button>
                    <button
                      className="btn btn-secondary ml-3"
                      onClick={props.onAnnuleren}
                      style={{ width: 100 }}
                      disabled={isSubmitting}
                    >
                      Annuleren
                    </button>
                  </div>
                </>
              );
            }}
          />
        </div>
      </Popover>
    </Overlay>
  );
};

export default ActiecodeMuterenPopupFormulier;
