import React, { useMemo, useEffect, useState, useContext } from 'react';
import api from '../../../../api';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import { IDataSignaleringenResult } from '../../../../../../shared/src/api/v2/relatie/Signalering';
import { addDays, format } from 'date-fns';
import { IOphalenRelatiesResultElementV2 } from '../../../../../../shared/src/api/v2/relatie';
import RelatieVisualisatie from '../../../personalia/RelatieVisualisatie';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { genereerUrlStateQueryParam } from '../../../../core/useUrlState';
import { IUrlState as IRelatieDuplicatenUrlState } from '../../../../paginas/Backoffice/Duplicaten/Relatie';
import { IOphalenDienstenResultElement } from '../../../../../../shared/src/api/v2/dienst/service';
import { bepaalDefaultUrlState, IUrlState } from '../../../../paginas/Taken';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../../stores/RootStore';
import {
  EAccLogSoort,
  EGrootboekrekening,
  EProcedureStatus,
  ERegelstatusVordering,
  ERelatiehoedanigheid,
} from '../../../../bedrijfslogica/enums';
import _ from 'lodash';
import { IOphalenEventsResultElement } from '../../../../../../shared/src/api/v2/appEvent';
import { EContractStatus } from '../../../../paginas/Klant/Klantkaart/Entiteiten/entiteiten/Contracten/Overzicht/ContractTegel/ContractStatus';
import { IOphalenOpdrachtenResultElement } from '../../../../../../shared/src/api/v2/bank/opdracht/opdracht';
import { IOphalenBetalingsregelingenResultElement } from '../../../../../../shared/src/api/v2/debiteur/betalingsregeling';
import { IOphalenContractenResultElementV2 } from '../../../../../../shared/src/api/v2/contract';
import {
  IOphalenOpdrachtenResultElementV2,
  IOphalenOpdrachtregelsResultElementV2,
} from '../../../../../../shared/src/api/v2/transport/opdracht';
import { EOpdrachtStatus } from '../../../../paginas/Transport/Opdrachten';
import { IOphalenVerkoopVoorstellenResultElement } from '../../../../../../shared/src/api/v2/product/verkoop';
import FormatteerBedrag, { StandaardMutatieBedragOpmaker } from '../../../MutatieBedrag';
import classNames from 'classnames';
import { IOphalenFactuurregelsResult } from '../../../../../../shared/src/api/v2/factuur';
import NavigatiePijl from '../../../../paginas/Klant/Klantkaart/Entiteiten/entiteiten/Overzicht/NavigatiePijl';
import VerkoopvoorstelVisualisatie from '../../../entiteitVisualisaties/VerkoopvoorstelVisualisatie';
import ContractVisualisatie from '../../../entiteitVisualisaties/ContractVisualisatie';
import { nl } from 'date-fns/locale';
import Beltaken from '../../../../paginas/Backoffice/Beltaken';
import RelatiesMetContractenOpDezelfdeLocatie from './RelatiesMetContractenOpDezelfdeLocatie';
import { IDienstAfwezigheid } from '../../../../../../shared/src/api/v2/dienst/service/afwezigheid';
import { dagDatum } from '../../../../helpers/datum';
import { IOphalenEscalatiesResultElement } from '../../../../../../shared/src/api/v2/escalatie';

interface IProps extends RouteComponentProps {
  relatie: IOphalenRelatiesResultElementV2;
}

const Bijzonderheden: React.FC<IProps> = observer((props) => {
  const { children, relatie } = props;
  const { gebruikerStore, klantkaartStore } = useContext(RootStoreContext);

  // dataSignaleringen gaan vervangen door de brondata die hier opgehaald wordt
  const [dataSignaleringen, setDataSignaleringen] = useState<IDataSignaleringenResult | null>(null);

  const [contracten, setContracten] = useState<IOphalenContractenResultElementV2[] | null>(null);
  const [transportopdrachtregels, setTransportopdrachtregels] = useState<
    IOphalenOpdrachtregelsResultElementV2[] | null
  >(null);
  const [transportopdrachten, setTransportopdrachten] = useState<
    IOphalenOpdrachtenResultElementV2[] | null
  >(null);
  const [escalaties, setEscalaties] = useState<IOphalenEscalatiesResultElement[] | null>(null);

  // const [reviews, setReviews] = useState<IOphalenReviewsResultElement[] | null>(null);
  const [servicediensten, setServicediensten] = useState<IOphalenDienstenResultElement[] | null>(
    null,
  );
  const [servicedienstAfwezigheden, setServicedienstAfwezigheden] = useState<
    IDienstAfwezigheid[] | null
  >(null);
  const [events, setEvents] = useState<IOphalenEventsResultElement[] | null>(null);
  const [bankopdrachten, setBankopdrachten] = useState<IOphalenOpdrachtenResultElement[] | null>(
    null,
  );
  const [betalingsregelingen, setBetalingsregelingen] = useState<
    IOphalenBetalingsregelingenResultElement[] | null
  >(null);
  const [verkoopvoorstellen, setVerkoopvoorstellen] = useState<
    IOphalenVerkoopVoorstellenResultElement[] | null
  >(null);
  const [factuurregels, setFactuurregels] = useState<IOphalenFactuurregelsResult | null>(null);

  const [bedragVooruitOntvangen, setBedragVooruitOntvangen] = useState<number | null>(null);

  useEffect(() => {
    (async () => {
      const contracten = (
        await api.v2.contract.ophalenContractenV2({
          filterSchema: {
            filters: [
              {
                naam: 'REL_IDS',
                data: [relatie.RelID],
              },
            ],
          },
        })
      ).contracten;

      setContracten(contracten);
    })();
  }, [relatie.RelID]);

  useEffect(() => {
    (async () => {
      const escalaties = (
        await api.v2.escalatie.ophalenEscalaties({
          filterSchema: {
            filters: [
              {
                naam: 'REL_IDS',
                data: [relatie.RelID],
              },
            ],
          },
        })
      ).escalaties;

      setEscalaties(escalaties);
    })();
  }, [relatie.RelID]);

  // WOP-vorderingen
  useEffect(() => {
    (async () => {
      const factuurregels = await api.v2.factuur.ophalenFactuurregels({
        filterSchema: {
          filters: [
            {
              naam: 'REL_IDS',
              data: [relatie.RelID],
            },
            { naam: 'FACTID_IS_LEEG', data: true },
            { naam: 'REGELSTATUS', data: [ERegelstatusVordering.WOP] },
          ],
        },
      });

      setFactuurregels(factuurregels);
    })();
  }, [relatie.RelID]);

  useEffect(() => {
    (async () => {
      const verkoopvoorstellen = (
        await api.v2.product.verkoop.ophalenVerkoopvoorstellen({
          filterSchema: {
            filters: [
              {
                naam: 'REL_IDS',
                data: [relatie.RelID],
              },
            ],
          },
        })
      ).voorstellen;
      setVerkoopvoorstellen(verkoopvoorstellen);
    })();
  }, [relatie.RelID]);

  useEffect(() => {
    (async () => {
      const transportopdrachtregels = (
        await api.v2.transport.opdracht.ophalenOpdrachtregelsV2({
          filterSchema: {
            filters: [
              {
                naam: 'REL_IDS',
                data: [relatie.RelID],
              },
            ],
          },
        })
      ).regels;

      setTransportopdrachtregels(transportopdrachtregels);
    })();
  }, [relatie.RelID]);

  useEffect(() => {
    (async () => {
      const saldiResult = await api.v2.boekhouding.boeking.ophalenSaldiGrootboekrekeningen({
        filterSchema: { filters: [{ naam: 'REL_IDS', data: [relatie.RelID] }] },
      });

      const saldoVooruitbetaaldResult = saldiResult.saldi.find(
        (x) => x.grootboekrekening.Nummer === EGrootboekrekening.Vooruitbetaald,
      );
      const saldoVooruitbetaald =
        saldoVooruitbetaaldResult !== undefined ? -saldoVooruitbetaaldResult.saldo : 0;

      setBedragVooruitOntvangen(saldoVooruitbetaald);
    })();
  }, [relatie.RelID]);

  useEffect(() => {
    (async () => {
      if (transportopdrachtregels === null) {
        return;
      }

      if (transportopdrachtregels.length === 0) {
        setTransportopdrachten([]);
        return;
      }

      const transportopdrachten = (
        await api.v2.transport.opdracht.ophalenOpdrachtenV2({
          filterSchema: {
            filters: [
              {
                naam: 'REL_IDS',
                data: [relatie.RelID],
              },
            ],
          },
        })
      ).opdrachten;

      setTransportopdrachten(transportopdrachten);
    })();
  }, [transportopdrachtregels]);

  useEffect(() => {
    (async () => {
      const result = await api.v2.relatie.ophalenRelatieBijzonderheden({
        relID: relatie.RelID,
      });

      setDataSignaleringen(result);
    })();
  }, [relatie.RelID]); // Stond eerst md5(JSON.stringify(relatie)), maar dan werd de functie 2 x uitgevoerd

  useEffect(() => {
    (async () => {
      const events = (
        await api.v2.appEvent.ophalenEvents({
          filterSchema: {
            filters: [
              {
                naam: 'REL_IDS',
                data: [relatie.RelID],
              },
            ],
          },
          orderSchema: { orders: [{ naam: 'DATUM', richting: 'DESC' }] },
        })
      ).events;

      setEvents(events);
    })();
  }, [relatie.RelID]);

  useEffect(() => {
    (async () => {
      const opdrachtenResult = (
        await api.v2.bank.opdracht.ophalenOpdrachten({
          filterSchema: {
            filters: [
              {
                naam: 'REL_IDS',
                data: [relatie.RelID],
              },
            ],
          },
        })
      ).opdrachten;

      setBankopdrachten(opdrachtenResult);
    })();
  }, [relatie.RelID]);

  useEffect(() => {
    (async () => {
      const diensten = (
        await api.v2.dienst.service.ophalenDiensten({
          filterSchema: {
            filters: [
              {
                naam: 'IS_ACTIEF',
                data: true,
              },
              {
                naam: 'REL_IDS',
                data: [relatie.RelID],
              },
            ],
          },
        })
      ).diensten;

      const afwezigheden = (
        await api.v2.dienst.service.afwezigheid.ophalenDienstAfwezigheden({
          filterSchema: {
            filters: [
              {
                naam: 'DIENST_IDS',
                data: diensten.map((x) => x.ID),
              },
            ],
          },
        })
      ).afwezigheden;

      setServicedienstAfwezigheden(afwezigheden);
      setServicediensten(diensten);
    })();
  }, [relatie.RelID]);

  useEffect(() => {
    (async () => {
      const betalingsregelingen = (
        await api.v2.debiteur.betalingsregeling.ophalenBetalingsregelingen({
          filterSchema: {
            filters: [
              {
                naam: 'REL_IDS',
                data: [relatie.RelID],
              },
              { naam: 'STATUS_NAAM_ENUMS', data: ['LOPEND', 'CONCEPT'] },
            ],
          },
        })
      ).betalingsregelingen;

      setBetalingsregelingen(betalingsregelingen);
    })();
  }, [relatie.RelID]);

  const signaleringen = useMemo<{
    hoog: Array<string | JSX.Element>;
    laag: Array<string | JSX.Element>;
  } | null>(() => {
    if (
      dataSignaleringen === null ||
      servicediensten === null ||
      servicedienstAfwezigheden === null ||
      events === null ||
      contracten === null ||
      betalingsregelingen === null ||
      verkoopvoorstellen === null ||
      bankopdrachten === null ||
      transportopdrachten === null ||
      factuurregels === null
    ) {
      return null;
    }

    const hoog: Array<string | JSX.Element> = [];
    const laag: Array<string | JSX.Element> = [];

    if (
      dataSignaleringen.relatie.Relatiesoort === 'P' &&
      dataSignaleringen.relatie.persoon!.Overleden
    ) {
      hoog.push('Relatie is overleden');
    }

    if (escalaties !== null && escalaties.some((x) => !x.IsAfgehandeld)) {
      const escalatie = _.orderBy(escalaties, (x: any) => x.Ontstaansdatum, 'asc')[0];
      hoog.push(
        <span>
          Er is sprake van een <b>ESCALATIE</b> vanaf{' '}
          {format(new Date(escalatie.Ontstaansdatum), 'dd-MM-yyyy')}
        </span>,
      );
    }

    // if (
    //   dataSignaleringen.oudsteContract !== null &&
    //   differenceInYears(new Date(), new Date(dataSignaleringen.oudsteContract)) > 5
    // ) {
    //   laag.push(`Huren sinds ${format(new Date(dataSignaleringen.oudsteContract), 'dd-MM-yyyy')}`);
    // }

    if (transportopdrachten !== null && transportopdrachten.length !== 0) {
      const uitstaandeOpdrachten = transportopdrachten.filter(
        (x) => x.opdrachtstatus.Status === EOpdrachtStatus.Uitstaand,
      );

      if (uitstaandeOpdrachten.length > 1) {
        laag.push(`Er zijn ${uitstaandeOpdrachten.length} uitstaande transportopdrachten`);
      } else {
        if (uitstaandeOpdrachten.length === 1) {
          const opdracht = uitstaandeOpdrachten[0];

          const bezoektijdVan =
            opdracht.BezoekdatumVan !== null
              ? format(new Date(opdracht.BezoekdatumVan), 'HH:mm')
              : null;
          const bezoektijdTot =
            opdracht.BezoekdatumTot !== null
              ? format(new Date(opdracht.BezoekdatumTot), 'HH:mm')
              : null;

          const bezoekdatumIsVandaag =
            format(new Date(opdracht.Bezoekdatum!), 'dd-MM-yyyy') ===
            format(new Date(), 'dd-MM-yyyy');

          const bezoektijd =
            bezoektijdVan === null && bezoektijdTot === null
              ? 'onbekend'
              : bezoektijdVan !== null && bezoektijdTot === null
              ? `vanaf ${opdracht.BezoekdatumVan}`
              : bezoektijdVan === null && bezoektijdTot !== null
              ? `tot ${opdracht.BezoekdatumTot}`
              : `van ${bezoektijdVan} tot ${bezoektijdTot}`;

          hoog.push(
            `Transportopdracht ${
              opdracht.Bezoekdatum === null
                ? ''
                : bezoekdatumIsVandaag
                ? 'vandaag'
                : `${format(new Date(opdracht.Bezoekdatum), 'eeee dd-MM-yyyy', { locale: nl })}`
            }, bezoektijd ${bezoektijd}`,
          );
        }
      }

      // if (
      //   uitstaandeOpdrachten
      //     .filter((x) => x.Bezoekdatum !== null)
      //     .some(
      //       (x) =>
      //         format(new Date(x.Bezoekdatum!), 'dd-MM-yyyy') === format(new Date(), 'dd-MM-yyyy'),
      //     )
      // ) {
      //   hoog.push('Er is een transportbezoek vandaag');
      // } else {
      //   laag.push('Er zijn één of meer uitstaande transportopdrachten');
      // }
    }

    if (
      dataSignaleringen.beltaken.some((x) => {
        return x.IsAfgehandeld &&
          x.GesprokenOp !== null &&
          new Date() <=
            addDays(
              new Date(x.GesprokenOp),
              dataSignaleringen.instelling.RespijttermijnAanmaningenInBeltaak,
            )
          ? true
          : false;
      })
    ) {
      hoog.push(`Zit in uitstel 3e aanmaning`);
    }

    if (dataSignaleringen.vertegenwoordigingen.length > 0) {
      hoog.push(
        <>
          <span className="d-flex">
            {/* <span>Is vertegenwoordiger voor relatie(s)&nbsp;</span> */}
            <a
              href="#"
              style={{ color: Kleur.DonkerBlauw }}
              onClick={(ev) => {
                ev.preventDefault();
                props.history.push(`/klant/${props.relatie.RelID}/vertegenwoordiging/relaties`);
              }}
            >
              Is vertegenwoordiger voor {dataSignaleringen.vertegenwoordigingen.length} relatie
              {dataSignaleringen.vertegenwoordigingen.length > 1 ? 's' : ''}
            </a>
          </span>
        </>,
      );
    }

    // if (
    //   klantkaartStore.debiteurInformatie !== null &&
    //   klantkaartStore.debiteurInformatie.facturen.bedragOpenstaand > 0
    // ) {
    //   hoog.push(
    //     <div className="d-flex align-items-center">
    //       <span>Openstaand saldo</span>
    //       <FormatteerBedrag bedrag={klantkaartStore.debiteurInformatie.facturen.bedragOpenstaand} />
    //       {klantkaartStore.debiteurInformatie.facturen.bedragVervallen !== 0 && (
    //         <span className="d-flex ml-2">
    //           <>
    //             <span>(waarvan </span>
    //             <FormatteerBedrag
    //               bedrag={klantkaartStore.debiteurInformatie.facturen.bedragVervallen}
    //               opmaakComponent={(opmaakProps) => {
    //                 return <StandaardMutatieBedragOpmaker {...opmaakProps} color={Kleur.Rood} />;
    //               }}
    //             />
    //             <span>&nbsp;vervallen)</span>
    //           </>
    //         </span>
    //       )}
    //     </div>,
    //   );
    // }

    // if (bedragVooruitOntvangen !== null && bedragVooruitOntvangen !== 0) {
    //   hoog.push(
    //     <div className="d-flex align-items-center">
    //       <span>Saldo vooruitbetaald</span>
    //       <FormatteerBedrag bedrag={bedragVooruitOntvangen} />
    //     </div>,
    //   );
    // }

    if (dataSignaleringen.duplicaatSuggesties.length > 0) {
      hoog.push(
        <div className="d-flex flex-column">
          <span>Potentiële relatie duplicaten gevonden:</span>
          <ul>
            {dataSignaleringen.duplicaatSuggesties.map((ds) => (
              <li key={ds.relDupSugID}>
                <div className="d-flex flex-column">
                  <div className="d-flex flex-fill align-items-center flex-wrap">
                    {ds.andereRelIDs
                      .map((relID) => <RelatieVisualisatie relID={relID} />)
                      .map((el, i) => {
                        if (i !== ds.andereRelIDs.length - 1) {
                          return (
                            <div className="d-flex">
                              {el}
                              &nbsp;–&nbsp;
                            </div>
                          );
                        }
                        return el;
                      })}
                  </div>
                  <a
                    href="#"
                    onClick={(ev) => {
                      ev.preventDefault();
                      const state = genereerUrlStateQueryParam<IRelatieDuplicatenUrlState>({
                        uitgeklapt: [ds.relDupSugID],
                      });
                      props.history.push(`/opvolging/relatie-duplicaten?state=${state}`);
                    }}
                  >
                    Ga naar duplicaat suggestie
                  </a>
                </div>
              </li>
            ))}
          </ul>
        </div>,
      );
    }

    if (dataSignaleringen.teOntdubbelenPersonen_PersID.length !== 0) {
      hoog.push(
        dataSignaleringen.teOntdubbelenPersonen_PersID.length === 1
          ? `Er is een te ontdubbelen persoon`
          : `Er zijn ${dataSignaleringen.teOntdubbelenPersonen_PersID.length} te ontdubbelen personen`,
      );
    }

    for (const acceptatieProcedure of dataSignaleringen.acceptatieProceduresResult) {
      const Bericht = ({
        statusText,
        metVerwijzing,
      }: {
        statusText: string;
        metVerwijzing?: boolean;
      }) => (
        <span>
          Acceptatie-procedure &ndash; {statusText}
          {metVerwijzing ? (
            <>
              <br />
              <a
                href="#"
                onClick={(ev) => {
                  ev.preventDefault();

                  props.history.push(`/klant/${props.relatie.RelID}/acceptatie/procedure`);
                }}
              >
                Ga naar acceptatie
              </a>
            </>
          ) : null}
        </span>
      );

      switch (acceptatieProcedure.NaamEnum) {
        case EProcedureStatus.Geweigerd: {
          hoog.push(<Bericht statusText="GEWEIGERD" />);
          break;
        }
        case EProcedureStatus.Geaccepteerd: {
          laag.push(<Bericht statusText="geaccepteerd" />);
          break;
        }
        case EProcedureStatus.Uitstaand: {
          hoog.push(<Bericht statusText="wacht op reactie" metVerwijzing />);
          break;
        }
        case EProcedureStatus.Concept: {
          hoog.push(<Bericht statusText="nog versturen" metVerwijzing />);
          break;
        }
        case EProcedureStatus.GeenReactie: {
          hoog.push(<Bericht statusText="geen reactie" metVerwijzing />);
          break;
        }
        default: {
          throw new Error(
            `Acceptatie-produce enum: ${acceptatieProcedure.NaamEnum} is niet geimplementeerd.`,
          );
        }
      }
    }

    if (
      dataSignaleringen.relatie.financieel.rekeningen.some(
        (x) => x.sepaLand === null && !x.NietGebruiken,
      )
    ) {
      hoog.push(`Eén of meer IBAN's behorende niet tot SEPA-gebied`);
    }

    const gebruikersaantallen = _.uniq(_.flatten(contracten.map((x) => x.basis.AantalGebruikers)));

    if (gebruikersaantallen.length === 1 && gebruikersaantallen[0] === 1) {
      if (dataSignaleringen.contracten.some((x) => x.status.NaamEnum === EContractStatus.Concept)) {
        hoog.push(`Heeft 1 gebruiker opgegeven`);
      } else {
        laag.push(`Heeft 1 gebruiker opgegeven`);
      }
    }

    //   <>
    //   Heeft signaleringen
    // <span
    //   style={{
    //     color: Kleur.Blauw,
    //     cursor: 'pointer',
    //   }}
    //   onClick={(ev) => {
    //     ev.stopPropagation();
    //     props.history.push(`/klant/${relatie.RelID}/signaleringen`);
    //   }}
    // >
    //   {' '}
    //   (bekijken)
    // </span>
    // </>,

    if (factuurregels.regels.length !== 0) {
      const bedrag = _.sum(factuurregels.regels.map((x) => x.Bedrag));
      factuurregels.regels.length === 1
        ? hoog.push(
            <span className="d-flex">
              Er is een WOP-vorderingen (<FormatteerBedrag bedrag={bedrag} />)
            </span>,
          )
        : hoog.push(
            <span className="d-flex">
              Er zijn {factuurregels.regels.length} WOP-vorderingen (totaal
              <FormatteerBedrag bedrag={bedrag} />)
              <NavigatiePijl
                relID={relatie.RelID}
                pathWithStateBuilder={() => {
                  return `facturen/vorderingen`;
                }}
              />
            </span>,
          );
    }

    if (dataSignaleringen.signaleringenAanwezig) {
      laag.push(
        <>
          Heeft signaleringen
          <span
            style={{
              color: Kleur.Blauw,
              cursor: 'pointer',
            }}
            onClick={(ev) => {
              ev.stopPropagation();
              props.history.push(`/klant/${relatie.RelID}/signaleringen`);
            }}
          >
            {' '}
            (bekijken)
          </span>
        </>,
      );
    }

    if (contracten !== null) {
      // const aantalContractenVoorWissel = contracten.filter(
      //   (x) => x.AangebodenContractwisselOp !== null,
      // ).length;
      // if (aantalContractenVoorWissel > 0) {
      //   hoog.push(
      //     <span>
      //       Er zijn {aantalContractenVoorWissel} contracten aangeboden voor contractwissel
      //     </span>,
      //   );
      // }

      // Zijn er aangeboden contracten ter overname?
      const aantalAangebodenContracten = contracten.filter(
        (x) => x.status.NaamEnum === EContractStatus.Lopend && x.AangebodenOvernameOp !== null,
      ).length;

      if (aantalAangebodenContracten !== 0 && relatie.Overnamecode) {
        if (aantalAangebodenContracten === 1) {
          laag.push(<span>Er is een contract aangeboden ter overname</span>);
        } else {
          laag.push(
            <span>Er zijn {aantalAangebodenContracten} contracten aangeboden ter overname</span>,
          );
        }
      }
    }

    const afwezigheid =
      servicedienstAfwezigheden.find(
        (x) =>
          new Date(x.AfwezigVan) <= dagDatum(new Date()) &&
          new Date(x.AfwezigTem) >= dagDatum(new Date()),
      ) ?? null;

    if (afwezigheid !== null) {
      hoog.push(
        `Servicedienst is afwezig t/m ${format(new Date(afwezigheid.AfwezigTem), 'dd-MM-yyyy')}`,
      );
      // hoog.push(`Servicedienst is afwezig`);
    }

    const handmatigeTaakControltaak = dataSignaleringen.controltaken.find(
      (x) => x.NaamEnum === 'HANDMATIGE_TAAK',
    );
    if (handmatigeTaakControltaak !== undefined) {
      laag.push(
        <span>
          Er is een&nbsp;
          <a
            href="#"
            onClick={async (ev) => {
              ev.preventDefault();

              const defaultUrlState = await bepaalDefaultUrlState(
                gebruikerStore.gebruiker!.AspGebrID,
              );
              const state = genereerUrlStateQueryParam<IUrlState>({
                ...defaultUrlState,
                uitgeklapt: [handmatigeTaakControltaak.ID],
              });
              props.history.push(`/taken?state=${state}`);
            }}
          >
            handmatige taak
          </a>
        </span>,
      );
    }
    if (dataSignaleringen.relatie !== null && dataSignaleringen.relatie.GeenEigenGebruik) {
      laag.push('Huurt niet voor eigen gebruik');
    }

    if (
      dataSignaleringen.relatie !== null &&
      dataSignaleringen.relatie.hoedanigheid!.NaamEnum === ERelatiehoedanigheid.Leverancier &&
      dataSignaleringen.relatie.crediteur !== null &&
      dataSignaleringen.relatie.crediteur.Betalingstermijn === 0
    ) {
      laag.push('Er is geen betalingstermijn opgegeven');
    }

    if (dataSignaleringen.account !== null && dataSignaleringen.account.Activatiedatum === null) {
      if (dataSignaleringen.contracten.some((x) => x.status.NaamEnum === EContractStatus.Concept)) {
        hoog.push('Er is een nog te activeren account');
      } else {
        laag.push('Er is een nog te activeren account');
      }
    }
    if (dataSignaleringen.account !== null && dataSignaleringen.account.Geblokkeerd) {
      laag.push('Heeft een geblokkeerd account');
    }
    if (dataSignaleringen.relatie.debiteur !== null && dataSignaleringen.rechtzaakAanwezig) {
      hoog.push('Heeft lopende rechtzaak');
    }
    if (dataSignaleringen.relatie.debiteur !== null && dataSignaleringen.incassozaakAanwezig) {
      hoog.push('Heeft lopende incassozaak');
    }
    if (dataSignaleringen.relatie.debiteur !== null && dataSignaleringen.herincassozaakAanwezig) {
      hoog.push('Heeft lopende herincassozaak');
    }
    if (betalingsregelingen !== null && betalingsregelingen.length !== 0) {
      hoog.push('Heeft concept- of lopende betalingsregeling');
    }

    if (relatie.debiteur !== null && !relatie.debiteur.BetalingsregelingToestaan) {
      laag.push('Betalingsregeling niet toegestaan');
    }

    // if (dataSignaleringen.aantalTeFiatterenOvernameContracten !== 0) {
    //   hoog.push(
    //     `Er zijn ${dataSignaleringen.aantalTeFiatterenOvernameContracten} te fiatteren overname-contracten`,
    //   );
    // }

    // Te fiatteren contracten
    if (contracten !== null) {
      const aantalTeFiatterenContracten = contracten.filter(
        (x) => x.status.NaamEnum === EContractStatus.Concept && x.Volgnummer === 0,
      ).length;
      const aantalTeFiatterenOvernameContracten = contracten.filter(
        (x) => x.status.NaamEnum === EContractStatus.Concept && x.Volgnummer !== 0,
      ).length;

      if (aantalTeFiatterenContracten !== 0) {
        if (aantalTeFiatterenContracten === 1) {
          hoog.push(`Er is een te fiatteren contract`);
        } else {
          hoog.push(`Er zijn ${aantalTeFiatterenContracten} te fiatteren contracten`);
        }
      }
      if (aantalTeFiatterenOvernameContracten !== 0) {
        hoog.push(
          `Er zijn ${aantalTeFiatterenOvernameContracten} te fiatteren overname-contracten`,
        );
      }
    }

    // Contractwissel toegestaan of niet, alleen indien dit afwijkend is van de globale instelling
    if (
      dataSignaleringen.instelling.ContractwisselToestaanWebsite &&
      dataSignaleringen.relatie.ContractwisselToestaanWebsite === 2
    ) {
      laag.push('Geen contractwissel toegestaan via website');
    } else {
      if (
        !dataSignaleringen.instelling.ContractwisselToestaanWebsite &&
        dataSignaleringen.relatie.ContractwisselToestaanWebsite === 1
      ) {
        laag.push('Contractwissel toegestaan via website');
      }
    }

    if (
      dataSignaleringen.contracten.some(
        (x) => x.status.NaamEnum === EContractStatus.Lopend && x.RelRekID !== null,
      )
    ) {
      laag.push('Afwijkende rekening voor 1 of meer contracten');
    }
    if (
      dataSignaleringen.relatie.debiteur !== null &&
      dataSignaleringen.relatie.debiteur.FactuurKenmerk !== null
    ) {
      laag.push(`Factuurkenmerk: ${dataSignaleringen.relatie.debiteur.FactuurKenmerk}`);
    }
    if (dataSignaleringen.contracten.some((x) => x.factuurkenmerk !== null)) {
      laag.push('Factuurkenmerk voor 1 of meer contracten');
    }

    const peildatum = format(new Date(), 'yyyy-MM-dd');

    const lopendeVerkoopvoorstellen = verkoopvoorstellen.filter((x) => {
      const datumVan = format(new Date(x.GeldigVan), 'yyyy-MM-dd');
      const datumTot = format(new Date(x.GeldigTot), 'yyyy-MM-dd');
      const geldig1 = datumVan <= peildatum;
      const geldig2 = datumTot > peildatum;
      const geldig =
        x.VerwerktOp === null && datumVan <= peildatum && datumTot > peildatum && x.Status === 0;
      return geldig;
    });

    if (lopendeVerkoopvoorstellen.length !== 0) {
      if (lopendeVerkoopvoorstellen.length === 1) {
        laag.push(
          <span className="d-flex">
            <span className="mr-2"> Heeft een lopend verkoopvoorstel</span>
            <VerkoopvoorstelVisualisatie verkVrstID={lopendeVerkoopvoorstellen[0].ID} />
          </span>,
        );
      } else {
        laag.push(
          <span
            style={{
              color: Kleur.Blauw,
              cursor: 'pointer',
            }}
            onClick={(ev) => {
              ev.stopPropagation();
              props.history.push(`/klant/${relatie.RelID}/contracten/verkoop`);
            }}
          >
            <span>Heeft {lopendeVerkoopvoorstellen.length} lopende verkoopvoorstellen</span>
          </span>,
        );
      }
    }

    // if (
    //   verkoopvoorstellen.length !== 0 &&
    //   verkoopvoorstellen.filter((x) => {
    //     const datumVan = format(new Date(x.GeldigVan), 'yyyy-MM-dd');
    //     const datumTot = format(new Date(x.GeldigTot), 'yyyy-MM-dd');
    //     const geldig1 = datumVan <= peildatum;
    //     const geldig2 = datumTot > peildatum;
    //     const geldig =
    //       x.VerwerktOp === null && datumVan <= peildatum && datumTot > peildatum && x.Status === 0;

    //     return (
    //       x.VerwerktOp === null && datumVan <= peildatum && datumTot > peildatum && x.Status === 0
    //     );
    //   })
    // ) {
    //   // laag.push(
    //   //   verkoopvoorstellen.length === 1 ? (
    //   //     <span>Heeft een lopend verkoopvoorstel</span>
    //   //   ) : (
    //   //     <span>Heeft {verkoopvoorstellen.length} lopende verkoopvoorstellen</span>
    //   //   ),
    //   // );

    //   laag.push(
    //     <span
    //       style={{
    //         color: Kleur.Blauw,
    //         cursor: 'pointer',
    //       }}
    //       onClick={(ev) => {
    //         ev.stopPropagation();
    //         props.history.push(`/klant/${relatie.RelID}/contracten/verkoop`);
    //       }}
    //     >
    //       {verkoopvoorstellen.length === 1 ? (
    //         <span>Heeft een lopend verkoopvoorstel</span>
    //       ) : (
    //         <span>Heeft {verkoopvoorstellen.length} lopende verkoopvoorstellen</span>
    //       )}
    //     </span>,
    //   );
    // }

    if (dataSignaleringen.relatiesMetContractenOpZelfdeLocaties.length !== 0) {
      const relIDs = dataSignaleringen.relatiesMetContractenOpZelfdeLocaties;

      laag.push(
        <RelatiesMetContractenOpDezelfdeLocatie
          relIDs={relIDs}
          onNavigeerNaarRelatie={(relID) => {
            props.history.push(`/klant/${relID}`);
          }}
          defaultIsUitgeklapt={!relatie.GeenEigenGebruik}
        />,
      );
    }

    if (relatie.ServDienstID !== null) {
      laag.push(
        `Vaste servicedienst ${
          servicediensten.find((x) => x.ID === relatie.ServDienstID)!.relatie!.weergavenaam
        } ${relatie.ServDienstOngeachtGarantie ? ' (ook bij garantie)' : ''}`,
      );
    }
    if (dataSignaleringen.kwkActie) {
      hoog.push('KwK-actie te beoordelen');
    }

    if (dataSignaleringen.relatie.GeenReviewUitnodiging) {
      laag.push('Geen review uitnodiging sturen');
    }
    if (dataSignaleringen.reviews.voorstellen.IDs.length > 0) {
      laag.push('Er staat een review-uitnodiging klaar in het voorstel');
    }
    if (dataSignaleringen.reviews.uitnodigingen.IDs.length > 0) {
      laag.push('Er staat een review-uitnodiging klaar om te versturen');
    }

    if (
      dataSignaleringen.relatie.debiteur !== null &&
      dataSignaleringen.relatie.debiteur.NietAanmanen &&
      dataSignaleringen.relatie.debiteur.DatumNietAanmanen === null
    ) {
      hoog.push(`Relatie staat op niet aanmanen zonder einddatum`);
    }
    if (dataSignaleringen.prolongatieOphoudenRelatie) {
      hoog.push('Relatie staat op niet-prolongeren');
    }
    if (dataSignaleringen.prolongatieOphoudenContract.length !== 0) {
      // const contracten = dataSignaleringen.prolongatieOphoudenContract.map((x) => {
      //   return (
      //     <li>
      //       <ContractVisualisatie cntID={x.CntID} />
      //     </li>
      //   );
      // });

      const contracten = dataSignaleringen.prolongatieOphoudenContract.map((x) => {
        return <ContractVisualisatie cntID={x.CntID} />;
      });

      hoog.push(
        <>
          Contract(en) die niet geprolongeerd worden:
          {contracten}
        </>,
      );
    }
    if (dataSignaleringen.contractenMetProductenOpAfwijkendeLocatie && !relatie.GeenEigenGebruik) {
      laag.push(`Product(en) met afwijkende locatie`);
    }
    // if (dataSignaleringen.toegevoegdeRelaties.length !== 0) {
    //   laag.push('Heeft toegevoegde relaties');
    // }
    if (dataSignaleringen.heeftTeVersturenFacturen) {
      laag.push('Nog te versturen facturen');
    }
    if (dataSignaleringen.heeftNogDefinitiefTeMakenFacturen) {
      hoog.push('Nog definitief te maken facturen');
    }

    if (dataSignaleringen.isContactpersoonVoor.length !== 0) {
      const Relaties = dataSignaleringen.isContactpersoonVoor.map((relID) => {
        return (
          <span
            style={{
              color: Kleur.Blauw,
              cursor: 'pointer',
            }}
            onClick={(ev) => {
              ev.stopPropagation();
              props.history.push(`/klant/${relID}`);
            }}
          >
            {' '}
            <RelatieVisualisatie
              relID={relID}
              options={{
                geenLinkToepassen: true,
              }}
            />
          </span>
        );
      });
      laag.push(
        <>
          Is tevens contactpersoon voor:
          {Relaties}
        </>,
      );
    }

    if (dataSignaleringen.vervangenRelaties.relIDs.length > 0) {
      laag.push(
        <>
          <span
            style={{
              color: Kleur.Blauw,
              cursor: 'pointer',
            }}
            onClick={(ev) => {
              ev.stopPropagation();
              props.history.push(`/klant/${relatie.RelID}/klant/vervangen`);
            }}
          >
            Heeft vervangen relaties
          </span>
        </>,
      );
    }

    if (dataSignaleringen.heeftBetalingsregeling) {
      hoog.push(`Heeft betalingsregeling`);
    }

    const aantalBankopdrachtenNietInBatch =
      bankopdrachten !== null ? bankopdrachten.filter((x) => x.batch === null).length : 0;

    const aantalBankopdrachtenInBatchNietVerstuurd =
      bankopdrachten !== null
        ? bankopdrachten.filter(
            (x) => x.batch !== null && !x.batch.IsUitgevoerd && x.batch.DatumVerstuurd === null,
          ).length
        : 0;

    const aantalBankopdrachtenInBatchVerstuurd =
      bankopdrachten !== null
        ? bankopdrachten.filter(
            (x) => x.batch !== null && !x.batch.IsUitgevoerd && x.batch.DatumVerstuurd !== null,
          ).length
        : 0;

    if (aantalBankopdrachtenNietInBatch > 0) {
      laag.push(`Heeft ${aantalBankopdrachtenNietInBatch} bankopdracht(en), nog niet in een batch`);
    }
    if (aantalBankopdrachtenInBatchNietVerstuurd > 0) {
      laag.push(
        `Heeft ${aantalBankopdrachtenInBatchNietVerstuurd} bankopdracht(en) in een niet-verstuurde batch`,
      );
    }
    if (aantalBankopdrachtenInBatchVerstuurd > 0) {
      laag.push(
        `Heeft ${aantalBankopdrachtenInBatchVerstuurd} bankopdracht(en) in een verstuurde batch`,
      );
    }

    if (dataSignaleringen.account !== null && dataSignaleringen.account.loggingen.length > 0) {
      // if (dataSignaleringen.debiteur !== null && dataSignaleringen.debiteur.Betaaldag !== 1) {
      //   laag.push(
      //     `Betaaldag ${
      //       dataSignaleringen.debiteur.Betaaldag === -1
      //         ? 'op Stufidag'
      //         : `${dataSignaleringen.debiteur.Betaaldag}e van de maand`
      //     }`,
      //   );
      // }

      // Laatste keer In- of Uitgelogd
      const accountLoggingen = _.orderBy(
        dataSignaleringen.account.loggingen,
        ['Registratiedatum'],
        ['desc'],
      );
      const accountLoggingenInUitgelogd = accountLoggingen.filter(
        (x: any) => x.Soort === EAccLogSoort.Inloggen || x.Soort === EAccLogSoort.Uitloggen,
      );

      // if (accountLoggingenInUitgelogd.length > 0) {
      //   const accountLogging = accountLoggingenInUitgelogd[0];

      //   if (accountLogging.Soort === EAccLogSoort.Inloggen) {
      //     laag.push(
      //       `Laatst ingelogd op ${format(
      //         new Date(accountLogging.Registratiedatum),
      //         'dd-MM-yyyy HH:mm',
      //       )}`,
      //     );
      //   }
      //   if (accountLogging.Soort === EAccLogSoort.Uitloggen) {
      //     laag.push(
      //       `Laatst uitgelogd op ${format(
      //         new Date(accountLogging.Registratiedatum),
      //         'dd-MM-yyyy HH:mm',
      //       )}`,
      //     );
      //   }
      // }
    }

    return { hoog, laag };
  }, [
    dataSignaleringen,
    servicediensten,
    transportopdrachten,
    contracten,
    verkoopvoorstellen,
    transportopdrachten,
    transportopdrachtregels,
    betalingsregelingen,
    bankopdrachten,
    relatie.GeenEigenGebruik,
  ]);

  if (signaleringen === null) {
    return null;
  }

  if (signaleringen.hoog.length === 0 && signaleringen.laag.length === 0) {
    return null;
  }

  return (
    <div className="d-flex flex-column">
      <span className="font-weight-bold mb-1 pl-3 pr-3">Bijzonderheden</span>
      {signaleringen.hoog.length !== 0 && (
        <div
          style={{
            backgroundColor: Kleur.Geel,
            color: Kleur.BeetjeDonkerGrijs,
          }}
          className="pb-2 pt-2 pl-4 pr-4"
        >
          {/*<IcoonWarning style={{ fill: Kleur.Grijs, width: 18, height: 18 }} />*/}
          <ul className="p-0 mb-0 ml-3">
            {signaleringen.hoog.map((signalering: any, i) => (
              <li key={i}>{signalering}</li>
            ))}
          </ul>
        </div>
      )}

      {signaleringen.laag.length !== 0 && (
        <>
          <div
            style={{
              // backgroundColor: Kleur.HeelLichtGrijs,
              color: Kleur.BeetjeDonkerGrijs,
            }}
            className={classNames(['pb-2 pl-4 pr-4', signaleringen.hoog.length > 0 ? 'pt-2' : ''])}
          >
            {/*<IcoonWarning style={{ fill: Kleur.Grijs, width: 18, height: 18 }} />*/}
            <ul className="p-0 mb-0 ml-3">
              {signaleringen.laag.map((signalering, i) => (
                <li key={i}>{signalering}</li>
              ))}
            </ul>
          </div>
        </>
      )}
    </div>
  );
});

export default withRouter(Bijzonderheden);
