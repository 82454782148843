import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import useUrlState from '../../../core/useUrlState';
import { RouteComponentProps } from 'react-router';
import api from '../../../api';
import { IFilterSchema } from '../../../../../shared/src/models/filter';
import { IOphalenOpdrachtenResultElement } from '../../../../../shared/src/api/v2/pendel';
import LoadingSpinner from '../../../components/Gedeeld/LoadingSpinner';
import {
  Grid,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  DXCommandComponent,
  DXTableCheckboxComponent,
  DXTableToggleCellComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../helpers/dxTableGrid';
import {
  DataTypeProvider,
  EditingState,
  RowDetailState,
  SelectionState,
} from '@devexpress/dx-react-grid';
import { format } from 'date-fns';
import OpdrachtDetailComp from './OpdrachtDetailComp';
import WijzigenOpdrachtDialoog from './WijzigenOpdrachtDialoog';
import { RootStoreContext } from '../../../stores/RootStore';
import { EResultType } from '../../../stores/CheckStore';
import StoplichtIndicatie, {
  EStoplichtStatus,
} from '../../../components/formulier/StoplichtIndicatie';
import MenuLayout from '../../../components/MenuLayout';
import { Kleur as EKleur } from '../../../bedrijfslogica/constanten';
import { IconLock, IconSend } from '../../../components/Icons';
import VersturenOpdrachtDialoog from './VersturenOpdrachtDialoog';
import { IOphalenAfhaalOpdrachtenResultElement } from '../../../../../shared/src/api/v2/magazijn/afhaal';
import _ from 'lodash';
import FilterBalkV2, {
  IFilter,
  IFilterData,
  maakFilterSchema,
} from '../../../components/FilterBalkV2';
import MultiSelect, { IOptie } from '../../../components/formulier/MultiSelect';
import VersturenLaadLosBerichtDialoog from './VersturenLaadLosBerichtDialoog';
import { Helmet } from 'react-helmet';
import { GlobaleRendererContext } from '../../../one-off-components/GlobaleRenderer';
import SjabloonOplosser, { ISjabloonOplosserOutput } from '../../../components/SjabloonOplosser';
import {
  ECommunicatiekanaalSelectieType,
  ESjabloonOplosserModus,
  ETaalSelectieType,
  SjabloonOplosserVerstuurModusKanalen,
  EVerstuurModusKanaal,
} from '../../../components/SjabloonOplosser/types';

interface IProps extends RouteComponentProps<{}> {}

export enum EFilter {
  Status = 'STATUSSEN',
}

export interface IVersturenOpdrachtDialoogState {
  penOpdID: number;
}
export interface IVersturenLaadLosDialoogState {
  penOpdID: number;
}
interface IUrlState {
  selectie: number[];
  versturenOpdrachtDialoogState: IVersturenOpdrachtDialoogState | null;
  versturenLaadLosDialoogState: IVersturenLaadLosDialoogState | null;
  filterData: IFilterData<EFilter>[];
}

const defaultUrlState: IUrlState = {
  selectie: [],
  versturenOpdrachtDialoogState: null,
  versturenLaadLosDialoogState: null,
  filterData: [
    {
      naam: EFilter.Status,
      data: [1, 2],
      isActief: true,
    },
  ],
};

interface IPendelsContext {
  onVerversenAangevraagd: () => void;
  // afhaalopdrachten: IOphalenAfhaalOpdrachtenResultElement[] | null;
}

export const PendelsContext = React.createContext<IPendelsContext | null>(null);

export interface IRow extends IOphalenOpdrachtenResultElement {}

const Pendels: React.FC<IProps> = (props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const [isBezig, setIsBezig] = useState<boolean>(false);
  const { checkStore } = useContext(RootStoreContext);
  const globaleRenderer = useContext(GlobaleRendererContext);

  const [opdrachten, setOpdrachten] = useState<IRow[] | null>(null);
  const [opdrachtWijzigenID, setOpdrachtWijzigenID] = useState<number | null>(null);

  // const [afhaalopdrachten, setAfhaalopdrachten] = useState<
  //   IOphalenAfhaalOpdrachtenResultElement[] | null
  // >(null);

  const [filterSchema, setFilterSchema] = useState<IFilterSchema>(
    useMemo(() => maakFilterSchema(urlState.filterData), []),
  );

  const ophalenPendelopdrachten = useCallback(async () => {
    const result = await api.v2.pendel.ophalenOpdrachten({
      filterSchema: {
        // filters: [...filterSchema.filters!, { naam: 'LIJST', data: null }],
        filters: [...filterSchema.filters!],
      },
      orderSchema: { orders: [{ naam: 'ID', richting: 'DESC' }] },
      paginatie: { index: 0, aantal: 250 },
    });
    const opdrachten = result.opdrachten.map((x) => {
      return { ...x };
    });
    // const productenGesorteerd = _.orderBy(producten, ['Merknaam', 'Typenaam'], ['asc', 'asc']);

    setOpdrachten(opdrachten);
  }, [filterSchema.filters]);

  useEffect(() => {
    ophalenPendelopdrachten();
  }, [ophalenPendelopdrachten]);

  // const ophalenAfhaalopdrachten = useCallback(async () => {
  //   if (opdrachten === null) {
  //     return;
  //   }
  //   const prodIDs = _.flatten(opdrachten.map((x) => x.regels)).map((x: any) => x.product.ProdID);
  //   const result = await api.v2.magazijn.afhaal.ophalenAfhaalOpdrachten({
  //     filterSchema: {
  //       filters: [
  //         { naam: 'PROD_IDS', data: prodIDs },
  //         { naam: 'IS_AFGEHAALD', data: false },
  //       ],
  //     },
  //   });
  //   setAfhaalopdrachten(result.afhaalopdrachten);
  // }, [opdrachten]);

  // useEffect(() => {
  //   ophalenAfhaalopdrachten();
  // }, [ophalenAfhaalopdrachten]);

  const handleVerwijderen = useCallback(async (ID: number) => {
    const params = { IDs: [ID] };
    const checkData = await api.v2.pendel.checkVerwijderenOpdrachten(params);
    if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
      return;
    }
    if (
      (
        await checkStore.bevestigen({
          inhoud: `Wil je de opdracht verwijderen?`,
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    await api.v2.pendel.verwijderenOpdrachten(params);

    setUrlStateSync('selectie', []);

    ophalenPendelopdrachten();
  }, []);

  const keyExtractor = useCallback((row: IRow) => row.ID, []);

  const kolommen = useMemo<TypedColumn<IRow>[]>(
    () => [
      {
        name: 'Opdrachtnummer',
        title: 'Opd.nr.',
      },
      {
        name: 'DatumGepland',
        title: 'Gepland',
      },
      {
        name: 'DatumVerstuurd',
        title: 'Verstuurd',
      },
      {
        name: 'DatumUitgevoerd',
        title: 'Uitgevoerd',
      },
      {
        name: '__transporteur' as any,
        title: 'Transporteur',
      },
      {
        name: '__laden' as any,
        title: 'Laden',
      },
      {
        name: '__lossen' as any,
        title: 'Lossen',
      },
      {
        name: '__gesloten' as any,
        title: 'Slot',
      },
      {
        name: '__aantalProducten' as any,
        title: '# Prod.',
      },
      {
        name: '__aantalUitgevoerd' as any,
        title: '# Uitgev.',
      },
      {
        name: '__verschil' as any,
        title: 'Verschil',
      },
      {
        name: '__status' as any,
        title: 'Status',
      },
      {
        name: 'Notities',
        title: 'Notities',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRow>[]>(
    () => [
      {
        columnName: 'Opdrachtnummer',
        width: 85,
      },
      {
        columnName: 'DatumGepland',
        width: 110,
      },
      {
        columnName: 'DatumVerstuurd',
        width: 110,
      },
      {
        columnName: 'DatumUitgevoerd',
        width: 110,
      },
      {
        columnName: '__transporteur' as any,
        width: 125,
      },
      {
        columnName: '__laden' as any,
        width: 90,
      },
      {
        columnName: '__lossen' as any,
        width: 90,
      },
      {
        columnName: '__gesloten' as any,
        width: 50,
      },
      {
        columnName: '__aantalProducten' as any,
        width: 75,
      },
      {
        columnName: '__aantalUitgevoerd' as any,
        width: 75,
      },
      {
        columnName: '__verschil' as any,
        width: 75,
      },
      {
        columnName: '__status' as any,
        width: 150,
      },
      {
        columnName: 'Notities',
        width: 250,
      },
    ],
    [],
  );

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.Status,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          const ids = weergaveProps.data as number[];
          const geselecteerd = ids.length === 0 ? null : ids[0];

          const opties = [
            { id: 1, label: 'Nog versturen' },
            { id: 2, label: 'Lopend' },
            { id: 3, label: 'Afgehandeld' },
          ];

          return (
            <div className="d-flex align-items-center">
              <span className="mr-2">Status:</span>
              {/* <Combobox
                geselecteerd={geselecteerd}
                onSelectieChange={(x) => {
                  const newValue = x === null ? [] : [x];
                  weergaveProps.onDataChange(newValue);
                  weergaveProps.setIsActief(true);
                  weergaveProps.toepassen();
                }}
                legeOptieTonen
                opties={[
                  { id: 1, label: 'Nog versturen' },
                  { id: 2, label: 'Lopend' },
                  { id: 3, label: 'Afgehandeld' },
                ]}
                options={{
                  legeOptieTekst: 'Zonder status',
                }}
              /> */}

              <MultiSelect
                value={weergaveProps.data}
                onChange={(data) => {
                  weergaveProps.onDataChange(data);
                  weergaveProps.toepassen();
                }}
                opties={opties.map(
                  (status): IOptie<number> => {
                    return { key: status.id, weergave: status.label };
                  },
                )}
                isVerwijderbaar={false}
              />
            </div>
          );
        },
      },
    ],
    [],
  );

  return (
    <PendelsContext.Provider
      value={{
        onVerversenAangevraagd: async () => await ophalenPendelopdrachten(),
        // afhaalopdrachten,
      }}
    >
      <Helmet>
        <title>Pendelopdrachten</title>
      </Helmet>

      <MenuLayout
        menu={
          <div className="d-flex align-items-center">
            <button
              className="btn btn-sm btn-light d-flex align-items-center"
              style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
              disabled={isBezig || urlState.selectie.length !== 1}
              onClick={async () => {
                const checkData = await api.v2.pendel.checkSelectieVersturenOpdracht({
                  penOpdID: urlState.selectie[0],
                });
                const checkResult = await checkStore.controleren({
                  checkData,
                });
                if (checkResult.type === EResultType.Annuleren) {
                  return;
                }

                setUrlStateSync('versturenOpdrachtDialoogState', {
                  penOpdID: urlState.selectie[0],
                });
              }}
            >
              <IconSend style={{ width: 16, height: 16, fill: EKleur.Grijs }} />
              <span className="ml-2">Versturen pendelopdracht</span>
            </button>

            <button
              className="btn btn-sm btn-light d-flex align-items-center ml-2"
              style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
              disabled={isBezig || urlState.selectie.length !== 1}
              onClick={async () => {
                // const checkData = await api.v2.pendel.checkSelectieVersturenOpdracht({
                //   penOpdID: urlState.selectie[0],
                // });
                // const checkResult = await checkStore.controleren({
                //   checkData,
                // });
                // if (checkResult.type === EResultType.Annuleren) {
                //   return;
                // }

                setUrlStateSync('versturenLaadLosDialoogState', {
                  penOpdID: urlState.selectie[0],
                });
              }}
            >
              <IconSend style={{ width: 16, height: 16, fill: EKleur.Grijs }} />
              <span className="ml-2">Versturen Laad/Los-bericht</span>
            </button>

            <div className="d-flex flex-fill ml-3">
              <FilterBalkV2
                filters={filters}
                filterData={urlState.filterData}
                onFilterDataChange={(x) => setUrlStateSync('filterData', x)}
                onFilterSchemaChange={(x) => setFilterSchema(x)}
              />
            </div>
          </div>
        }
        body={
          <>
            {opdrachten === null ? (
              <div className="d-flex flex-column flex-fill align-items-center justify-content-center">
                <LoadingSpinner />
              </div>
            ) : (
              <>
                <GridStyleWrapper height={'calc(100vh - 100px)'}>
                  <Grid rows={opdrachten} columns={kolommen} getRowId={keyExtractor}>
                    <DataTypeProvider
                      for={['__status']}
                      formatterComponent={(props) => {
                        const rij = props.row as IOphalenOpdrachtenResultElement;

                        const status = rij.Afgehandeld ? (
                          <StoplichtIndicatie status={EStoplichtStatus.Rood} naam={'Afgehandeld'} />
                        ) : rij.DatumVerstuurd !== null ? (
                          <StoplichtIndicatie status={EStoplichtStatus.Groen} naam={'Lopend'} />
                        ) : (
                          <StoplichtIndicatie
                            status={EStoplichtStatus.Blauw}
                            naam={'Nog versturen'}
                          />
                        );

                        return <div style={{ marginTop: 1 }}>{status}</div>;
                      }}
                    />

                    <DataTypeProvider
                      for={['DatumGepland']}
                      formatterComponent={(props) => {
                        const rij = props.row as IOphalenOpdrachtenResultElement;
                        return (
                          <span>
                            {rij.DatumGepland !== null
                              ? format(new Date(rij.DatumGepland), 'dd-MM-yyyy')
                              : ''}
                          </span>
                        );
                      }}
                    />

                    <DataTypeProvider
                      for={['DatumVerstuurd']}
                      formatterComponent={(props) => {
                        const rij = props.row as IOphalenOpdrachtenResultElement;

                        return (
                          <span>
                            {rij.DatumVerstuurd !== null
                              ? format(new Date(rij.DatumVerstuurd), 'dd-MM-yyyy')
                              : ''}
                          </span>
                        );
                      }}
                    />

                    <DataTypeProvider
                      for={['DatumUitgevoerd']}
                      formatterComponent={(props) => {
                        const rij = props.row as IOphalenOpdrachtenResultElement;
                        return (
                          <span>
                            {rij.DatumUitgevoerd !== null ? (
                              <span style={{ color: EKleur.Groen }}>
                                {format(new Date(rij.DatumUitgevoerd), 'dd-MM-yyyy')}
                              </span>
                            ) : (
                              <span></span>
                            )}
                          </span>
                        );
                      }}
                    />

                    <DataTypeProvider
                      for={['__transporteur']}
                      formatterComponent={(props) => {
                        const rij = props.row as IOphalenOpdrachtenResultElement;
                        return (
                          <span>{rij.dienst !== null ? rij.dienst.relatie!.weergavenaam : ''}</span>
                        );
                      }}
                    />

                    <DataTypeProvider
                      for={['__laden']}
                      formatterComponent={(props) => {
                        const rij = props.row as IOphalenOpdrachtenResultElement;
                        return <span>{rij.magazijn.laden.NaamKort}</span>;
                      }}
                    />

                    <DataTypeProvider
                      for={['__lossen']}
                      formatterComponent={(props) => {
                        const rij = props.row as IOphalenOpdrachtenResultElement;
                        return <span>{rij.magazijn.lossen.NaamKort}</span>;
                      }}
                    />

                    <DataTypeProvider
                      for={['__aantalProducten']}
                      formatterComponent={(props) => {
                        const rij = props.row as IOphalenOpdrachtenResultElement;
                        return (
                          <span>
                            {rij.regels.length}{' '}
                            {rij.regels.length > rij.MaxAantalProducten ? ' (!)' : ''}
                          </span>
                        );
                      }}
                    />

                    <DataTypeProvider
                      for={['__aantalUitgevoerd']}
                      formatterComponent={(props) => {
                        const rij = props.row as IOphalenOpdrachtenResultElement;
                        const aantal = rij.regels.filter((x) => x.Uitgevoerd).length;
                        if (rij.DatumUitgevoerd === null) {
                          return <span>{aantal}</span>;
                        }
                        if (aantal === rij.regels.length) {
                          return <span style={{ color: EKleur.Groen }}>{aantal}</span>;
                        }
                        return <span style={{ color: EKleur.Rood }}>{aantal}</span>;
                      }}
                    />

                    <DataTypeProvider
                      for={['__verschil']}
                      formatterComponent={(props) => {
                        const rij = props.row as IOphalenOpdrachtenResultElement;

                        if (rij.DatumUitgevoerd === null) {
                          return <span></span>;
                        }

                        const aantalUitgevoerd = rij.regels.filter((x) => x.Uitgevoerd).length;
                        const verschil = aantalUitgevoerd - rij.regels.length;

                        if (verschil === 0) {
                          return <span></span>;
                        }

                        return <span>{verschil}</span>;
                      }}
                    />

                    {/* <DataTypeProvider
                      for={['__gesloten']}
                      formatterComponent={(props) => {
                        const rij = props.row as IOphalenOpdrachtenResultElement;

                        if (rij.regels.length >= rij.MaxAantalProducten) {
                          return <IconLock style={{ width: 16, height: 16, fill: Kleur.Grijs }} />;
                        }
                        return <span></span>;
                      }}
                    /> */}

                    <DataTypeProvider
                      for={['__gesloten']}
                      formatterComponent={(props) => {
                        const rij = props.row as IOphalenOpdrachtenResultElement;
                        if (rij.Gesloten) {
                          return <IconLock style={{ width: 16, height: 16, fill: EKleur.Grijs }} />;
                        }
                        return <span></span>;
                      }}
                    />

                    <VirtualTable
                      messages={{ noData: 'Geen opdrachten voor het ingestelde filter' }}
                    />
                    <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
                    <TableHeaderRow />

                    <EditingState
                      onAddedRowsChange={() => {}}
                      onEditingRowIdsChange={(x) => {
                        const id = x[x.length - 1] as number;
                        setOpdrachtWijzigenID(id);
                      }}
                      onCommitChanges={async (changes) => {
                        if (changes.deleted === undefined) {
                          return;
                        }
                        const deleted = changes.deleted;
                        const id = deleted[deleted.length - 1] as number;
                        await handleVerwijderen(id);
                      }}
                    />
                    <TableEditColumn
                      width={65}
                      showEditCommand
                      showDeleteCommand
                      commandComponent={DXCommandComponent}
                    />

                    <RowDetailState />
                    <TableRowDetail
                      toggleCellComponent={DXTableToggleCellComponent}
                      contentComponent={OpdrachtDetailComp}
                    />

                    <SelectionState
                      selection={urlState.selectie}
                      onSelectionChange={(value) => setUrlStateSync('selectie', value as number[])}
                    />
                    <TableSelection cellComponent={DXTableCheckboxComponent} />
                  </Grid>
                </GridStyleWrapper>

                {opdrachtWijzigenID !== null && (
                  <WijzigenOpdrachtDialoog
                    open
                    ID={opdrachtWijzigenID}
                    onSuccess={() => {
                      ophalenPendelopdrachten();
                      setOpdrachtWijzigenID(null);
                    }}
                    onAnnuleren={() => {
                      setOpdrachtWijzigenID(null);
                    }}
                  />
                )}

                {urlState.versturenOpdrachtDialoogState !== null && (
                  <VersturenOpdrachtDialoog
                    open
                    penOpdID={urlState.versturenOpdrachtDialoogState.penOpdID}
                    onSuccess={() => {
                      setUrlStateSync('versturenOpdrachtDialoogState', null);
                      ophalenPendelopdrachten();
                    }}
                    onAnnuleren={() => {
                      setUrlStateSync('versturenOpdrachtDialoogState', null);
                    }}
                  />
                )}
                {urlState.versturenLaadLosDialoogState !== null && (
                  <VersturenLaadLosBerichtDialoog
                    open
                    penOpdID={urlState.versturenLaadLosDialoogState.penOpdID}
                    onSuccess={() => {
                      setUrlStateSync('versturenLaadLosDialoogState', null);
                      ophalenPendelopdrachten();
                    }}
                    onAnnuleren={() => {
                      setUrlStateSync('versturenLaadLosDialoogState', null);
                    }}
                  />
                )}
              </>
            )}
          </>
        }
      />
    </PendelsContext.Provider>
  );
};

export default Pendels;
