import {
  IOphalenContractenParams,
  IOphalenContractenResult,
  IToevoegenContractParams,
  IToevoegenContractResult,
  IWijzigenContractParams,
  IWijzigenContractResult,
  IVerwijderenContractenParams,
  IVerwijderenContractenResult,
  IWijzigenBestandenParams,
  IWijzigenBestandenResult,
} from '../../../../../../shared/src/api/v2/sponsoring/contract';
import ICheckData from '../../../../../../shared/src/models/ICheckData';
import api from '../../../index';

const contract = {
  ophalenContracten: async (
    params: IOphalenContractenParams,
  ): Promise<IOphalenContractenResult> => {
    return await api.post('/v2/sponsoring/contract/ophalen-contracten', params);
  },
  checkToevoegenContract: async (params: IToevoegenContractParams): Promise<ICheckData> => {
    return await api.post('/v2/sponsoring/contract/check-toevoegen-contract', params);
  },
  toevoegenContract: async (
    params: IToevoegenContractParams,
  ): Promise<IToevoegenContractResult> => {
    return await api.post('/v2/sponsoring/contract/toevoegen-contract', params);
  },
  checkWijzigenContract: async (params: IWijzigenContractParams): Promise<ICheckData> => {
    return await api.post('/v2/sponsoring/contract/check-wijzigen-contract', params);
  },
  wijzigenContract: async (params: IWijzigenContractParams): Promise<IWijzigenContractResult> => {
    return await api.post('/v2/sponsoring/contract/wijzigen-contract', params);
  },
  checkVerwijderenContracten: async (params: IVerwijderenContractenParams): Promise<ICheckData> => {
    return await api.post('/v2/sponsoring/contract/check-verwijderen-contracten', params);
  },
  verwijderenContracten: async (
    params: IVerwijderenContractenParams,
  ): Promise<IVerwijderenContractenResult> => {
    return await api.post('/v2/sponsoring/contract/verwijderen-contracten', params);
  },
  wijzigenBestanden: async (
    params: IWijzigenBestandenParams,
  ): Promise<IWijzigenBestandenResult> => {
    return await api.post('/v2/sponsoring/contract/wijzigen-bestanden', params);
  },
};

export default contract;
