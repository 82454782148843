import { makeObservable, observable } from 'mobx';
import api from '../api';

class ApiStore {
  public bereikbaar = true;
  private isChecking = false;

  constructor() {
    makeObservable(this, {
      bereikbaar: observable,
    });
  }

  public traceerBereikbaarheid = async () => {
    if (this.isChecking) {
      return;
    }
    this.isChecking = true;
    while (true) {
      try {
        await api.check();
        this.bereikbaar = true;
        this.isChecking = false;
        window.location.reload();
        return;
      } catch (err) {
        if (this.bereikbaar) {
          this.bereikbaar = false;
        }
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }
    }
  };
}

export default ApiStore;
