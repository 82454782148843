import api from '../index';
import {
  IOphalenWerkdagenParams,
  IOphalenWerkdagenResult,
} from '../../../../shared/src/api/v2/datum/datum';

const datum = {
  async ophalenWerkdagen(params: IOphalenWerkdagenParams): Promise<IOphalenWerkdagenResult> {
    return await api.post('v2/datum/ophalen-werkdagen', params);
  },
};

export default datum;
