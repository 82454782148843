import {
  IOphalenLocatiesParams,
  IOphalenLocatiesResult,
  IWijzigenLocatieParams,
  IWijzigenLocatieResult,
} from '../../../../../shared/src/api/v2/relatie/locatie';
import api from '../../index';

const relatieLocatie = {
  ophalenLocaties: async (params: IOphalenLocatiesParams): Promise<IOphalenLocatiesResult> => {
    return await api.post('/v2/relatie/locatie/ophalen-locaties', params);
  },
  wijzigenLocatie: async (params: IWijzigenLocatieParams): Promise<IWijzigenLocatieResult> => {
    return await api.post('/v2/relatie/locatie/wijzigen-locatie', params);
  },
};

export default relatieLocatie;
