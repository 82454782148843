import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { RootStoreContext } from '../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import { ERemoteDataState } from '../../../models/IRemoteData';
import {
  defaultUrlState as transportopdrachtenOverzichtDefaultUrlState,
  IUrlState as ITransportopdrachtenOverzichtUrlState,
} from '../../../components/kaart/entiteiten/Transport/Opdrachten';
import { genereerUrlStateQueryParam } from '../../../core/useUrlState';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  IOphalenOpdrachtenResultElementV2,
  IOphalenOpdrachtregelsResultElementV2,
} from '../../../../../shared/src/api/v2/transport/opdracht';
import OpdrachtStatus from '../../../components/kaart/entiteiten/Transport/OpdrachtStatus';
import { ERegelstatusTransport, ETransportopdrachtRegelsoort } from '../../../bedrijfslogica/enums';

interface IProps extends RouteComponentProps {
  trsRegID: number;
  onClick?: () => void;
}

interface ITransportopdrachtregel extends IOphalenOpdrachtregelsResultElementV2 {
  opdracht: IOphalenOpdrachtenResultElementV2;
}

const TransportopdrachtregelVisualisatie: React.FC<IProps> = observer((props) => {
  const { transportopdrachtStore, transportopdrachtregelStore } = useContext(RootStoreContext);

  const transportopdrachtregel = useMemo(
    () => transportopdrachtregelStore.transportopdrachtregels[props.trsRegID] ?? null,
    [props.trsRegID, transportopdrachtregelStore.transportopdrachtregels],
  );
  const transportopdracht = useMemo(
    () =>
      transportopdrachtregel === null
        ? null
        : transportopdrachtStore.transportopdrachten.state === ERemoteDataState.Pending
        ? null
        : transportopdrachtStore.transportopdrachten.data![transportopdrachtregel.TrsOpdID] ?? null,
    [transportopdrachtregel, transportopdrachtStore.transportopdrachten],
  );

  useEffect(() => {
    if (transportopdrachtregel !== null) {
      return;
    }

    transportopdrachtregelStore.enqueueTransportopdrachtregels([props.trsRegID]);
  }, [props.trsRegID]);

  useEffect(() => {
    if (transportopdracht !== null || transportopdrachtregel === null) {
      return;
    }
    transportopdrachtStore.enqueueOphalenTransportopdrachten([transportopdrachtregel.TrsOpdID]);
  }, [transportopdrachtregel]);

  return (
    <span>
      {transportopdrachtregel === null || transportopdracht === null ? (
        <span>Laden...</span>
      ) : (
        <a
          href="#"
          className="d-flex align-items-center"
          onClick={(ev) => {
            ev.preventDefault();

            if (props.onClick !== undefined) {
              props.onClick();
            }

            const urlState: ITransportopdrachtenOverzichtUrlState = {
              ...transportopdrachtenOverzichtDefaultUrlState,
              uitgeklapteOpdrachten: [transportopdrachtregel!.TrsOpdID],
              opdrachtenStates: {
                [transportopdrachtregel!.TrsOpdID]: {
                  afmeldenDialoogState: null,
                  annulerenOpdrachtregelsDialoogState: null,
                  toevoegenMontageregelDialoogState: null,
                  voorraadInfoDialoogState: null,
                  koppelenVoorraadProductDialoogState: null,
                  wijzigenRegelDialoogState: null,
                  regelsSelectie: [transportopdrachtregel!.TrsRegID],
                  productinfoDialoogState: null,
                  verplaatsenRegelsDialoogState: null,
                },
              },
              // regelsSelectie: [transportopdrachtregel.TrsRegID],
            };
            const query = genereerUrlStateQueryParam(urlState);

            props.history.push(
              `/klant/${transportopdrachtregel.RelID}/transport/opdracht?state=${query}`,
            );
          }}
        >
          <>
            <OpdrachtStatus status={transportopdracht.Opdrachtstatus} naamWeergeven={false} />
            <span className="ml-2">
              {transportopdracht.Opdrachtnummer}-{transportopdrachtregel.Regelnummer}
              {transportopdrachtregel.Gereserveerd !== null &&
                transportopdrachtregel.regelsoort.NaamEnum ===
                  ETransportopdrachtRegelsoort.Levering &&
                transportopdrachtregel.status.Status === ERegelstatusTransport.NIET_AFGEMELD &&
                transportopdrachtregel.product === null &&
                !transportopdrachtregel.Bulkproduct && (
                  <>
                    &nbsp;{transportopdrachtregel.Gereserveerd}(
                    {transportopdrachtregel.Gereserveerd ? 'Res.' : 'IR'})
                  </>
                )}
            </span>
          </>
        </a>
      )}
    </span>
  );
});

export default withRouter(TransportopdrachtregelVisualisatie);
