import React, { useCallback, useMemo, useRef } from 'react';
import { IconArchive, IconLopend, IconToevoegen } from '../../../../Icons';
import { Kleur } from '../../../../../bedrijfslogica/constanten';
import IRemoteData, { ERemoteDataState, mapRemoteData } from '../../../../../models/IRemoteData';
import { AutoSizer, Index, IndexRange, InfiniteLoader, List } from 'react-virtualized';
import LoadingSpinner from '../../../../Gedeeld/LoadingSpinner';
import styled from 'styled-components';
import { IChatsessiesData, useWhatsappV2Store } from '../../store';
import { ListRowRenderer } from 'react-virtualized/dist/es/List';
import Skeleton from 'react-loading-skeleton';
import ChatsessieItem from '../ChatsessieItem';
import { EWhatsappTabblad } from '../../types';

const TabContainer = styled.div`
  display: flex;
  min-height: 40px;
  max-height: 40px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);

  & > *:not(:last-child) {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
  }
`;

const Tab = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.2s ease-in-out;

  &.active {
    // blueish highlight
    background-color: rgba(100, 100, 255, 0.1);
  }

  .icon {
    margin-right: 8px;
  }

  .count {
    margin-left: 8px;
    color: ${Kleur.LichtBlauw}
    font-size: 12px;
    font-weight: bold;
    position: relative;
    top: 1px;
  }

  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const ListContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-top: 5px;
  padding-bottom: 5px;
`;

interface IProps {
  tabblad: EWhatsappTabblad;
  onTabbladChange: (tabblad: EWhatsappTabblad) => void;
  chatsessieID: number | null;
  onChatsessieIDChange: (chatsessieID: number | null) => void;
}

const StandaardWeergave = (props: IProps) => {
  const store = useWhatsappV2Store();

  const chatsessiesData = useMemo<IRemoteData<IChatsessiesData>>(() => {
    switch (props.tabblad) {
      case EWhatsappTabblad.Nieuw:
        return store.chatsessiesDataNieuw;
      case EWhatsappTabblad.Lopend:
        return store.chatsessiesDataLopend;
      case EWhatsappTabblad.Archief:
        return store.chatsessiesDataArchief;
    }
  }, [
    store.chatsessiesDataNieuw,
    store.chatsessiesDataLopend,
    store.chatsessiesDataArchief,
    props.tabblad,
  ]);

  const chatsessiesNieuwAantal = useMemo<IRemoteData<number>>(
    () => mapRemoteData(store.chatsessiesDataNieuw, (x: IChatsessiesData) => x.totaalAantal),
    [store.chatsessiesDataNieuw],
  );
  const chatsessiesLopendAantal = useMemo<IRemoteData<number>>(
    () => mapRemoteData(store.chatsessiesDataLopend, (x: IChatsessiesData) => x.totaalAantal),
    [store.chatsessiesDataLopend],
  );

  const rowRenderer = useCallback<ListRowRenderer>(
    ({ index, key, style }) => {
      const data = chatsessiesData.data!;
      const chatsessieIdData = data.chatsessieIDBijIndex[index];

      if (chatsessieIdData === undefined || chatsessieIdData.state === ERemoteDataState.Pending) {
        return (
          <div key={key} style={style} className="d-flex p-2 pl-4 pr-4">
            <Skeleton
              className="flex-fill w-100 h-100"
              containerClassName="flex-fill w-100 h-100"
            />
          </div>
        );
      }
      const chatsessieId = chatsessieIdData.data!;

      const c = store.chatsessieBijID.data![chatsessieId];

      return (
        <ChatsessieItem
          key={key}
          style={style}
          chatsessie={c}
          actief={props.chatsessieID === c.id}
          onClick={() => props.onChatsessieIDChange(c.id)}
          tabblad={props.tabblad}
          onTabbladChange={props.onTabbladChange}
          onChatsessieGekozen={() => props.onChatsessieIDChange(chatsessieId)}
        />
      );
    },
    [
      chatsessiesData,
      props.chatsessieID,
      props.onChatsessieIDChange,
      props.tabblad,
      props.onTabbladChange,
      store.chatsessieBijID,
      props.onChatsessieIDChange,
    ],
  );

  const isRijGeladen = useCallback(
    (index: Index) => {
      const heeftEntry =
        chatsessiesData.state === ERemoteDataState.Ready &&
        chatsessiesData.data!.chatsessieIDBijIndex[index.index] !== undefined;
      if (!heeftEntry) {
        console.log(chatsessiesData);
        console.log('isRijGeladen', index, heeftEntry);
      }
      return heeftEntry;
    },
    [chatsessiesData],
  );
  const laadMeerRijenTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const laadMeerRijen = useCallback(
    async (range: IndexRange) => {
      if (laadMeerRijenTimeoutRef.current !== null) {
        clearTimeout(laadMeerRijenTimeoutRef.current);
      }

      laadMeerRijenTimeoutRef.current = setTimeout(async () => {
        let status: 'nieuw' | 'lopend' | 'archief';
        switch (props.tabblad) {
          case EWhatsappTabblad.Nieuw:
            status = 'nieuw';
            break;
          case EWhatsappTabblad.Lopend:
            status = 'lopend';
            break;
          case EWhatsappTabblad.Archief:
            status = 'archief';
            break;
        }

        await store.meerChatsessiesLaden({
          status,
          index: range.startIndex,
          aantal: range.stopIndex - range.startIndex + 1,
        });
      }, 100);
    },
    [store.meerChatsessiesLaden, props.tabblad],
  );

  const scrollToIndex = useMemo(() => {
    if (
      store.scrollNaarIndexVoorTabblad === null ||
      store.scrollNaarIndexVoorTabblad.tabblad !== props.tabblad
    ) {
      return undefined;
    }

    const index = store.scrollNaarIndexVoorTabblad.index;
    store.setScrollNaarIndexVoorTabblad(null);

    return index;
  }, [props.tabblad, store.scrollNaarIndexVoorTabblad]);

  return (
    <>
      <TabContainer>
        <Tab
          className={props.tabblad === EWhatsappTabblad.Nieuw ? 'active' : ''}
          onClick={() => props.onTabbladChange(EWhatsappTabblad.Nieuw)}
        >
          <IconToevoegen
            className="icon"
            style={{ fill: Kleur.Grijs, width: 18, height: 18, position: 'relative', top: 1 }}
          />
          <span>Nieuw</span>
          {chatsessiesNieuwAantal.state === ERemoteDataState.Ready &&
            chatsessiesNieuwAantal.data! > 0 && (
              <span className="count">{chatsessiesNieuwAantal.data!}</span>
            )}
        </Tab>
        <Tab
          className={props.tabblad === EWhatsappTabblad.Lopend ? 'active' : ''}
          onClick={() => props.onTabbladChange(EWhatsappTabblad.Lopend)}
        >
          <IconLopend className="icon" style={{ fill: Kleur.Grijs, width: 18, height: 18 }} />
          <span>Lopend</span>
          {chatsessiesLopendAantal.state === ERemoteDataState.Ready &&
            chatsessiesLopendAantal.data! > 0 && (
              <span className="count">{chatsessiesLopendAantal.data!}</span>
            )}
        </Tab>
        <Tab
          className={props.tabblad === EWhatsappTabblad.Archief ? 'active' : ''}
          onClick={() => props.onTabbladChange(EWhatsappTabblad.Archief)}
        >
          <IconArchive className="icon" style={{ fill: Kleur.Grijs, width: 18, height: 18 }} />
          <span>Archief</span>
        </Tab>
      </TabContainer>
      <div style={{ position: 'relative', flex: 1 }}>
        <ListContainer>
          <AutoSizer>
            {({ width, height }) => {
              if (chatsessiesData.state === ERemoteDataState.Pending) {
                return (
                  <div
                    style={{ width, height }}
                    className="d-flex align-items-center justify-content-center"
                  >
                    <LoadingSpinner />
                  </div>
                );
              }
              const data = chatsessiesData.data!;
              if (data.totaalAantal === 0) {
                return (
                  <div
                    style={{ width, height }}
                    className="d-flex align-items-center justify-content-center"
                  >
                    <span className="text-muted">Er zijn geen chatsessies beschikbaar.</span>
                  </div>
                );
              }

              return (
                <InfiniteLoader
                  loadMoreRows={laadMeerRijen}
                  isRowLoaded={isRijGeladen}
                  rowCount={data.totaalAantal}
                  minimumBatchSize={30}
                  threshold={30}
                >
                  {(ilp) => (
                    <List
                      ref={(ref) => {
                        ilp.registerChild(ref);
                      }}
                      rowCount={data.totaalAantal}
                      height={height}
                      rowHeight={props.tabblad === EWhatsappTabblad.Lopend ? 115 : 90}
                      rowRenderer={rowRenderer}
                      width={width}
                      overscanRowCount={10}
                      onRowsRendered={ilp.onRowsRendered}
                      scrollToIndex={scrollToIndex}
                      scrollToAlignment="start"
                    />
                  )}
                </InfiniteLoader>
              );
            }}
          </AutoSizer>
        </ListContainer>
      </div>
    </>
  );
};

export default StandaardWeergave;
