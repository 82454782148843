import React, { useContext, useEffect, useMemo, useState, useCallback } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { withRouter } from 'react-router';
import api from '../../../../api';
import useUrlState from '../../../../core/useUrlState';
import _ from 'lodash';
import {
  DXCommandComponent,
  DXTableCheckboxComponent,
  DXTableToggleCellComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import {
  Column,
  DataTypeProvider,
  FilteringState,
  IntegratedFiltering,
  IntegratedSorting,
  RowDetailState,
  SearchState,
  SelectionState,
  SortingState,
  TableColumnWidthInfo,
  EditingState,
  VirtualTable as VirtualTableBase,
} from '@devexpress/dx-react-grid';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import {
  Grid,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import { format } from 'date-fns';
import FormatteerBedrag, {
  StandaardMutatieBedragOpmaker,
} from '../../../../components/MutatieBedrag';
import RowDetailComp from './RowDetailComp';
import { Kleur as EKleur, Kleur } from '../../../../bedrijfslogica/constanten';
import MenuLayout from '../../../../components/MenuLayout';
import { RootStoreContext } from '../../../../stores/RootStore';
import { EResultType } from '../../../../stores/CheckStore';
import RelatieVisualisatie from '../../../../components/personalia/RelatieVisualisatie';
import KoppelProductenDialoog from './KoppelProductenDialoog';
import WijzigenFactuurDialoog from '../../../../components/inkoopfactuur/WijzigenFactuurDialoog';
import {
  IOphalenFacturenResultElement,
  IOphalenGekoppeldeProductenResultElement,
} from '../../../../../../shared/src/api/v2/inkoopfactuur';
import VinkVeld from '../../../../components/formulier/VinkVeld';
// import { IOphalenBoekingenResultElement } from '../../../../../../../shared/src/api/v2/boekhouding/boeking';
import { IFilterSchema, IFilterSchemaFilter } from '../../../../../../shared/src/models/filter';
import DownloadKnop from '../../../../components/DownloadKnop';
import downloadUrl from '../../../../core/downloadUrl';
import FilterBalkV2, {
  IFilter,
  IFilterData,
  maakFilterSchema,
} from '../../../../components/FilterBalkV2';
import Combobox from '../../../../components/formulier/Combobox';
import {
  IOphalenBoekingenResultElement,
  IOphalenBoekingRegelsResultElement,
} from '../../../../../../shared/src/api/v2/boekhouding/boeking';
import { EGrootboekrekening } from '../../../../bedrijfslogica/enums';
import { AutoSizer } from 'react-virtualized';
import { IconUitvergroten } from '../../../../components/Icons';
import MediaWeergaveDialoog from '../../../../components/dialogen/MediaWeergaveDialoog';
import { mediaTypeNaarMediaWeergaveType } from '../../../../components/MediaWeergave';
import { EHoedanigheid } from '../../../../components/personalia/RelatieSelectieDialoog';
import RelatieSelectieUitgebreid from '../../../../components/formulier/RelatieSelectieUitgebreid';
import {
  IInitieleRelatiesProvider,
  relatieSelectieRelatieToRow,
} from '../../../../components/formulier/RelatieSelectie';
import { Helmet } from 'react-helmet';

interface IProps extends RouteComponentProps {}

export interface IKoppelProductenDialoogState {
  inkFactID: number;
}

export interface IWijzigenFactuurDialoogState {
  inkFactID: number;
}

export interface IBestandenUitvergrotenDialoogState {
  inkFactID: number;
}

enum EFilter {
  RelIDs = 'REL_IDS',
  ProductenGekoppeld = 'PRODUCTEN_GEKOPPELD',
  CreditFacturen = 'IS_CREDIT',
}

export interface IUrlState {
  selectie: number[];
  uitgeklapt: number[];
  koppelProductenDialoogState: IKoppelProductenDialoogState | null;
  wijzigenFactuurDialoogState: IWijzigenFactuurDialoogState | null;
  bestandenUitvergrotenDialoogState: IBestandenUitvergrotenDialoogState | null;
  filterData: IFilterData<EFilter>[];
}

const defaultUrlState: IUrlState = {
  selectie: [],
  uitgeklapt: [],
  koppelProductenDialoogState: null,
  wijzigenFactuurDialoogState: null,
  bestandenUitvergrotenDialoogState: null,
  filterData: [
    {
      naam: EFilter.RelIDs,
      isActief: false,
      data: [],
    },
    {
      naam: EFilter.ProductenGekoppeld,
      isActief: true,
      data: false,
    },
    {
      naam: EFilter.CreditFacturen,
      isActief: false,
      data: true,
    },
  ],
};

export interface IRow extends IOphalenFacturenResultElement {}

export interface IBoeking extends IOphalenBoekingRegelsResultElement {
  boeking: IOphalenBoekingenResultElement;
}

const geenData = {
  noData: 'Geen inkoopfacturen gevonden',
};

const Koppelen: React.FC<IProps> = observer((props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const { checkStore } = useContext(RootStoreContext);

  const [filterSchema, setFilterSchema] = useState<IFilterSchema>(
    maakFilterSchema(urlState.filterData),
  );

  const [facturen, setFacturen] = useState<IRow[] | null>(null);

  const [boekingen, setBoekingen] = useState<IBoeking[] | null>(null);
  const [producten, setProducten] = useState<IOphalenGekoppeldeProductenResultElement[] | null>(
    null,
  );

  // Ophalen van inkoopfacturen die gekoppeld moeten worden

  const ophalenFacturen = useCallback(async () => {
    const facturenResult = await api.v2.inkoopfactuur.ophalenFacturen({
      filterSchema: {
        filters: [
          ...filterSchema.filters!,
          {
            naam: 'IS_GEFIATTEERD',
            data: true,
          },
          // TODO: Dit filter weer activeren zodra het boeken geimplemneerd is
          // {
          //   naam: 'IS_GEBOEKT',
          //   data: true,
          // },
          {
            naam: 'KOPPELEN_PRODUCTEN',
            data: true,
          },
        ].filter((x) => x !== null) as IFilterSchemaFilter[],
      },
      orderSchema: { orders: [{ naam: 'FACTUURDATUM', richting: 'DESC' }] },
      paginatie: {
        index: 0,
        aantal: 500,
      },
    });

    // Ophalen relatie-gegevens
    const relIDs = _.uniq(facturenResult.facturen.map((x) => x.RelID));
    const relatiesResult = await api.v2.relatie.ophalenRelaties({
      filterSchema: { filters: [{ naam: 'IDS', data: relIDs }] },
    });

    const facturen = facturenResult.facturen.map((x: any) => {
      const relatie = relatiesResult.relaties.find((r) => r.RelID === x.RelID)!;
      return { ...x, relatie };
    });

    setFacturen(facturen);
  }, [filterSchema]);

  useEffect(() => {
    ophalenFacturen();
  }, [ophalenFacturen]);

  const handleKoppelProducten = useCallback(async () => {
    alert('Producten');
    const params = { inkFactIDs: urlState.selectie };

    ophalenFacturen();
  }, [urlState.selectie]);

  // Bedrag geboekt op grootboek 100 voor deze factuur
  useEffect(() => {
    (async () => {
      if (facturen === null) {
        return;
      }

      const inkFactIDs = facturen.map((x) => x.InkFactID);

      const boekingenResult = (
        await api.v2.boeking.ophalenBoekingen({
          filterSchema: {
            filters: [
              { naam: 'INKFACT_IDS', data: inkFactIDs },
              { naam: 'GRBREK_NUMMERS', data: [100] },
            ],
          },
        })
      ).boekingen;

      const boekingIDs = boekingenResult.map((x) => x.ID);
      const boekingregels = (
        await api.v2.boeking.ophalenBoekingregels({
          filterSchema: {
            filters: [{ naam: 'BOEKING_IDS', data: boekingIDs }],
          },
        })
      ).regels;

      const boekingen = boekingregels.map((x) => {
        const boeking = boekingenResult.find((b) => b.ID === x.BoekingID)!;
        return { ...x, boeking };
      });

      setBoekingen(boekingen);
    })();
  }, [facturen]);

  useEffect(() => {
    (async () => {
      if (facturen === null) {
        return;
      }

      const inkFactIDs = facturen.map((x) => x.InkFactID);

      const producten = (
        await api.v2.inkoopfactuur.ophalenGekoppeldeProducten({
          inkFactIDs,
        })
      ).producten;

      setProducten(producten);
    })();
  }, [facturen]);

  const [relatieOpties, setRelatieOpties] = useState<{ RelID: number; Naam: string }[] | null>(
    null,
  );

  const ophalenRelatieOpties = useCallback(async () => {
    const diensten = (
      await api.v2.dienst.inkoop.ophalenDiensten({
        filterSchema: { filters: [{ naam: 'IS_ACTIEF', data: true }] },
      })
    ).diensten;

    const relaties = (
      await api.v2.relatie.ophalenRelaties({
        filterSchema: { filters: [{ naam: 'IDS', data: [_.uniq(diensten.map((x) => x.RelID))] }] },
      })
    ).relaties.map((x) => {
      return { RelID: x.RelID, Naam: x.weergavenaam };
    });

    const relatiesGesorteerd: any[] = _.orderBy(relaties, ['Naam'], ['asc']);

    setRelatieOpties(relatiesGesorteerd);
  }, []);

  useEffect(() => {
    ophalenRelatieOpties();
  }, [ophalenRelatieOpties]);

  // useEffect(() => {
  //   if (opties.state === ERemoteDataState.Pending) {
  //     return;
  //   }
  //   bepalenRelatiesOpties();
  // }, [bepalenRelatiesOpties]);

  const initieleRelatiesProvider = useMemo<IInitieleRelatiesProvider>(
    () => ({
      provide: async () => {
        const relatiesResult = await api.v2.relatie.ophalenRelaties({
          filterSchema: {
            filters: [
              {
                naam: 'IS_CREDITEUR',
                data: true,
              },
              {
                naam: 'MET_OPENSTAANDE_FACTUREN',
                data: true,
              },
            ],
          },
        });
        return relatiesResult.relaties.map(relatieSelectieRelatieToRow);
      },
    }),
    [],
  );

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.ProductenGekoppeld,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return (
            <div className="d-flex align-items-center">
              <span>Alleen nog te koppelen facturen</span>
            </div>
          );
        },
      },
      {
        naam: EFilter.RelIDs,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          // if (relatieOpties === null) {
          //   return null;
          // }
          const data: number[] = weergaveProps.data;

          return (
            <div className="d-flex align-items-center">
              <span className="mr-2">Inkoopleverancier</span>
              <RelatieSelectieUitgebreid
                filters={[{ naam: 'IS_CREDITEUR', data: true }]}
                onChange={(relID) => {
                  const newData = relID === null ? [] : [relID];
                  weergaveProps.onDataChange(newData);
                  weergaveProps.setIsActief(true);
                  weergaveProps.toepassen();
                }}
                relID={data.length === 0 ? null : data[0]}
                initieleRelatiesProvider={initieleRelatiesProvider}
              />
              {/* <Combobox
                geselecteerd={data.length === 0 ? null : data[0]}
                onSelectieChange={(x) => {
                  const newData = x === null ? [] : [x];
                  weergaveProps.onDataChange(newData);
                  weergaveProps.setIsActief(true);
                  weergaveProps.toepassen();
                }}
                opties={relatieOpties.map((x) => {
                  return { id: x.RelID, label: x.Naam };
                })}
              /> */}
            </div>
          );
        },
      },
      {
        naam: EFilter.CreditFacturen,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return (
            <div className="d-flex align-items-center">
              <span>Alleen Credit-facturen</span>
            </div>
          );
        },
      },
    ],
    [],
  );

  const keyExtractor = useCallback((row: IRow) => row.InkFactID, []);
  const kolommen = useMemo<TypedColumn<IRow>[]>(
    () => [
      {
        name: 'Factuurdatum',
        title: 'Fact.datum',
      },
      {
        name: 'Factuurnummer',
        title: 'Fact.nr.',
      },
      {
        name: 'Bedrag',
        title: 'Bedrag (incl.)',
      },
      {
        name: '__relatie' as any,
        title: 'Crediteur',
      },
      {
        name: 'Onderwerp',
        title: 'Onderwerp',
      },
      {
        name: '__heeftBestanden' as any,
        title: ' ',
      },
      {
        name: '__Geboekt100' as any,
        title: 'Geboekt 100',
      },
      {
        name: '__nogKoppelen' as any,
        title: 'Nog koppelen',
      },
      {
        name: '__koppelen' as any,
        title: ' ',
      },
      {
        name: '__aantalGekoppeld' as any,
        title: '# Prod.',
      },
      // {
      //   name: '__heeftBestanden' as any,
      //   title: 'Bijl.',
      // },
      {
        name: 'ProductenGekoppeld',
        title: 'Volledig gekoppeld',
      },
    ],
    [],
  );
  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRow>[]>(
    () => [
      {
        columnName: 'Factuurdatum',
        width: 125,
      },
      {
        columnName: 'Factuurnummer',
        width: 125,
      },
      {
        columnName: '__relatie' as any,
        width: 135,
      },
      {
        columnName: 'Bedrag',
        width: 120,
      },
      {
        columnName: 'Onderwerp',
        width: 300,
      },
      {
        columnName: '__heeftBestanden' as any,
        width: 80,
      },
      {
        columnName: '__koppelen',
        width: 90,
      },
      {
        columnName: '__aantalGekoppeld',
        width: 100,
      },
      // {
      //   columnName: '__heeftBestanden' as any,
      //   width: 80,
      // },
      {
        columnName: '__Geboekt100',
        width: 125,
      },
      {
        columnName: 'ProductenGekoppeld',
        width: 175,
      },
      {
        columnName: '__nogKoppelen' as any,
        width: 125,
      },
    ],
    [],
  );

  const kolomExtensies: VirtualTableBase.ColumnExtension[] = useMemo(() => {
    return [
      // {
      //   columnName: `Bedrag`,
      //   align: 'right',
      // },
      // {
      //   columnName: `__nogKoppelen`,
      //   align: 'right',
      // },
    ];
  }, []);

  return (
    <>
      <Helmet>
        <title>Koppelen producten</title>
      </Helmet>
      <MenuLayout
        menu={
          <div className="d-flex">
            {/* <button
              className="btn btn-sm btn-light d-flex align-items-center"
              style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
              disabled={urlState.selectie.length !== 1}
              onClick={() => handleKoppelProducten()}
            >
              <span className="ml-2">Producten</span>
            </button> */}

            <div className="d-flex flex-fill">
              <FilterBalkV2
                filters={filters}
                filterData={urlState.filterData}
                onFilterDataChange={(x) => setUrlStateSync('filterData', x)}
                onFilterSchemaChange={(x) => setFilterSchema(x)}
              />
            </div>
          </div>
        }
        body={
          <div className="d-flex flex-column flex-fill">
            {facturen === null || boekingen === null || producten === null ? (
              <div className="d-flex flex-column flex-fill align-items-center justify-content-center">
                <LoadingSpinner />
              </div>
            ) : (
              <AutoSizer style={{ flex: 1, height: '100%', width: '100%' }}>
                {(size) => (
                  <GridStyleWrapper height={size.height}>
                    <Grid columns={kolommen} rows={facturen || []} getRowId={keyExtractor}>
                      {/* <DataTypeProvider
                    for={['__heeftBestanden']}
                    formatterComponent={(formatterProps) => {
                      const rij: IRow = formatterProps.row;
                      const heeftBestanden = rij.bestanden.length > 0 ? true : false;
                      return (
                        <span>
                          {heeftBestanden ? (
                            <IconAttachment style={{ width: 20, height: 20, fill: Kleur.Grijs }} />
                          ) : null}
                        </span>
                      );
                    }}
                  /> */}

                      <DataTypeProvider
                        for={['Factuurdatum']}
                        formatterComponent={(formatterProps) => {
                          const rij: IRow = formatterProps.row;
                          const datum = format(new Date(rij.Factuurdatum), 'dd-MM-yyyy');
                          return <span>{datum}</span>;
                        }}
                      />

                      <DataTypeProvider
                        for={['__relatie']}
                        formatterComponent={(formatterProps) => {
                          const rij = formatterProps.row as IRow;
                          return (
                            <RelatieVisualisatie
                              relID={rij.RelID}
                              relatieLinkBuilder={(hoedanigheid, relID) =>
                                `/${
                                  hoedanigheid === EHoedanigheid.Klant ? 'klant' : 'leverancier'
                                }/${relID}/facturen/overzicht`
                              }
                            />
                          );
                        }}
                      />

                      <DataTypeProvider
                        for={['Bedrag']}
                        formatterComponent={(formatterProps) => (
                          <FormatteerBedrag bedrag={formatterProps.value} />
                        )}
                      />

                      <DataTypeProvider
                        for={['__heeftBestanden']}
                        formatterComponent={(formatterProps) => {
                          const rij: IRow = formatterProps.row;
                          const heeftBestanden = rij.bestanden.length > 0;
                          return (
                            <>
                              {heeftBestanden && (
                                <div className="d-flex align-items-center">
                                  <button
                                    style={{
                                      outline: 0,
                                      background: 0,
                                      border: 0,
                                      padding: 0,
                                      marginTop: 1,
                                    }}
                                    onClick={() => {
                                      setUrlStateSync('bestandenUitvergrotenDialoogState', {
                                        inkFactID: rij.InkFactID,
                                      });
                                    }}
                                  >
                                    <IconUitvergroten
                                      style={{ width: 20, height: 20, fill: Kleur.Blauw }}
                                    />
                                  </button>

                                  <DownloadKnop
                                    onDownloadAangevraagd={async () => {
                                      await downloadUrl(
                                        rij.bestanden[0].url,
                                        rij.bestanden[0].Naam,
                                      );
                                    }}
                                  />
                                </div>
                              )}
                            </>
                          );
                        }}
                      />

                      <DataTypeProvider
                        for={['__aantalGekoppeld']}
                        formatterComponent={(formatterProps) => {
                          const rij = formatterProps.row as IRow;

                          const gekoppeldeProducten = producten.filter(
                            (x) => x.InkFactID === rij.InkFactID,
                          );

                          return <span>{gekoppeldeProducten.length}</span>;
                        }}
                      />

                      <DataTypeProvider
                        for={['ProductenGekoppeld']}
                        formatterComponent={(formatterProps) => {
                          return (
                            <span
                              style={{ color: formatterProps.value ? EKleur.Groen : EKleur.Rood }}
                            >
                              {formatterProps.value ? 'Ja' : 'Nee'}
                            </span>
                          );
                        }}
                      />

                      <DataTypeProvider
                        for={['__Geboekt100']}
                        formatterComponent={(formatterProps) => {
                          const rij = formatterProps.row as IRow;

                          // Totaal geboekt op de 100
                          const boekingenVoorFactuur = boekingen.filter(
                            (x) =>
                              x.InkFactID === rij.InkFactID &&
                              (x.dbRekening.Nummer === EGrootboekrekening.Machines ||
                                x.crRekening.Nummer === EGrootboekrekening.Machines),
                          );
                          const bedrag100 = _.sum(
                            boekingenVoorFactuur.map((x) =>
                              x.crRekening.NaamEnum === 'MACHINES'
                                ? -(x.Bedrag - (x.BtwBedrag || 0))
                                : x.Bedrag - (x.BtwBedrag || 0),
                            ),
                          ).toFixed(2);

                          return (
                            <span>
                              <FormatteerBedrag bedrag={bedrag100} />
                            </span>
                          );
                        }}
                      />

                      <DataTypeProvider
                        for={['__nogKoppelen']}
                        formatterComponent={(formatterProps) => {
                          const rij = formatterProps.row as IRow;

                          // Totaal geboekt op de 100
                          const boekingenVoorFactuur = boekingen.filter(
                            (x) =>
                              x.InkFactID === rij.InkFactID &&
                              (x.dbRekening.Nummer === EGrootboekrekening.Machines ||
                                x.crRekening.Nummer === EGrootboekrekening.Machines),
                          );

                          const bedrag100 = _.sum(
                            boekingenVoorFactuur.map((x) =>
                              x.crRekening.Nummer === EGrootboekrekening.Machines
                                ? -(x.Bedrag - (x.BtwBedrag || 0))
                                : x.Bedrag - (x.BtwBedrag || 0),
                            ),
                          ).toFixed(2);

                          // Totaal aan inkoopbedragen (excl btw) wat bij de gekoppelde producten vastgelegd is
                          const productenVoorFactuur = producten.filter(
                            (x) => x.InkFactID === rij.InkFactID,
                          );

                          const bedragGekoppeld = _.sum(
                            productenVoorFactuur.map((x) => x.Bedrag || 0),
                          ).toFixed(2);

                          const bedragNogTeKoppelen = bedrag100 - bedragGekoppeld;

                          return (
                            <span>
                              <FormatteerBedrag
                                bedrag={bedragNogTeKoppelen}
                                opmaakComponent={(opmaakProps) => {
                                  return (
                                    <StandaardMutatieBedragOpmaker
                                      {...opmaakProps}
                                      color={bedragNogTeKoppelen !== 0 ? EKleur.Rood : EKleur.Groen}
                                    />
                                  );
                                }}
                              />
                            </span>
                          );
                        }}
                      />

                      {/* <DataTypeProvider
                    for={['__nogKoppelen']}
                    formatterComponent={(formatterProps) => {
                      const rij = formatterProps.row as IRow;

                      // Totaal geboekt op de 100
                      const boekingenVoorFactuur = boekingen.filter(
                        (x) => x.InkFactID === rij.InkFactID,
                      );
                      const bedrag100 = _.sum(boekingenVoorFactuur.map((x) => x.Bedrag)).toFixed(2);

                      // Totaal inkoop aan gekoppelde producten
                      const productenVoorFactuur = producten.filter(
                        (x) => x.InkFactID === rij.InkFactID,
                      );
                      const bedragGekoppeld = _.sum(
                        productenVoorFactuur.map((x) => x.Bedrag),
                      ).toFixed(2);

                      const bedrag = bedrag100 - bedragGekoppeld;
                      if (bedrag === 0) {
                        return <span></span>;
                      }

                      // if (rij.ProductenGekoppeld) {
                      //   return <span>({<MutatieBedrag bedrag={bedrag} rechtsUitlijnen />})</span>;
                      // }

                      return (
                        <span style={{ color: EKleur.Rood }}>
                          <MutatieBedrag bedrag={bedrag} />
                        </span>
                      );
                    }}
                  /> */}

                      {/* <DataTypeProvider
                    for={['Gefiatteerd']}
                    formatterComponent={(props) => {
                      return (
                        <span>
                          {props.value ? (
                            <IconVink style={{ width: 20, height: 20, fill: Kleur.Groen }} />
                          ) : (
                            <IconKruis style={{ width: 20, height: 20, fill: Kleur.Rood }} />
                          )}
                        </span>
                      );
                    }}
                  /> */}

                      {/* <DataTypeProvider
                    for={['__koppelen']}
                    formatterComponent={(formatterProps) => {
                      const rij = formatterProps.row as IRow;
                      return (
                        <>
                          {rij.KoppelenProducten && (
                            <a
                              href="#"
                              style={{ color: Kleur.Blauw }}
                              onClick={() =>
                                setUrlStateSync('koppelProductenDialoogState', {
                                  inkFactID: rij.InkFactID,
                                })
                              }
                            >
                              producten
                            </a>
                          )}{' '}
                          {false && (
                            <a href="#" style={{ color: Kleur.DonkerBlauw }} onClick={() => null}>
                              sponsorcontracten
                            </a>
                          )}{' '}
                        </>
                      );
                    }}
                  /> */}

                      <DataTypeProvider
                        for={['__koppelen']}
                        formatterComponent={(formatterProps) => {
                          const rij = formatterProps.row as IRow;
                          return (
                            <>
                              <a
                                href="#"
                                style={{ color: EKleur.Blauw }}
                                onClick={() =>
                                  setUrlStateSync('koppelProductenDialoogState', {
                                    inkFactID: rij.InkFactID,
                                  })
                                }
                              >
                                Koppelen
                              </a>
                            </>
                          );
                        }}
                      />

                      <RowDetailState
                        expandedRowIds={urlState.uitgeklapt}
                        onExpandedRowIdsChange={(x) => setUrlStateSync('uitgeklapt', x as number[])}
                      />
                      <SortingState defaultSorting={[]} />
                      <IntegratedSorting />

                      <VirtualTable
                        // estimatedRowHeight={43}
                        columnExtensions={kolomExtensies}
                        messages={geenData}
                      />

                      <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
                      <TableHeaderRow showSortingControls />

                      <EditingState
                        onAddedRowsChange={() => {}}
                        onEditingRowIdsChange={(x) => {
                          const id = x[x.length - 1] as number;
                          setUrlStateSync('wijzigenFactuurDialoogState', { inkFactID: id });
                        }}
                        onCommitChanges={() => null}
                      />
                      <TableEditColumn
                        width={35}
                        // showAddCommand={true}
                        showEditCommand
                        commandComponent={DXCommandComponent}
                      />

                      <TableRowDetail
                        contentComponent={RowDetailComp}
                        toggleCellComponent={DXTableToggleCellComponent}
                      />

                      <SelectionState
                        selection={urlState.selectie}
                        onSelectionChange={(x) => setUrlStateSync('selectie', x as number[])}
                      />
                      <TableSelection cellComponent={DXTableCheckboxComponent} />
                    </Grid>
                  </GridStyleWrapper>
                )}
              </AutoSizer>
            )}

            {urlState.koppelProductenDialoogState !== null && (
              <KoppelProductenDialoog
                open
                inkFactID={urlState.koppelProductenDialoogState.inkFactID}
                onSuccess={() => {}}
                onAnnuleren={() => {
                  setUrlStateSync('koppelProductenDialoogState', null);
                  ophalenFacturen();
                }}
              />
            )}
            {urlState.wijzigenFactuurDialoogState !== null && (
              <WijzigenFactuurDialoog
                open
                inkFactID={urlState.wijzigenFactuurDialoogState.inkFactID}
                onSuccess={() => {
                  setUrlStateSync('wijzigenFactuurDialoogState', null);
                  ophalenFacturen();
                }}
                onAnnuleren={() => setUrlStateSync('wijzigenFactuurDialoogState', null)}
              />
            )}
            {urlState.bestandenUitvergrotenDialoogState !== null &&
              facturen !== null &&
              (() => {
                const factuur = facturen.find(
                  (x) => x.InkFactID === urlState.bestandenUitvergrotenDialoogState!.inkFactID,
                )!;
                const mediaWeergaven = factuur.bestanden.map((x) => ({
                  id: x.ID,
                  title: x.Naam,
                  src: x.url,
                  type: mediaTypeNaarMediaWeergaveType(x.MediaType)!,
                  mediaType: x.MediaType,
                }));

                return (
                  <MediaWeergaveDialoog
                    mediaWeergaven={mediaWeergaven}
                    defaultCurrent={mediaWeergaven[0].id}
                    open
                    onSuccess={() => setUrlStateSync('bestandenUitvergrotenDialoogState', null)}
                    onAnnuleren={() => setUrlStateSync('bestandenUitvergrotenDialoogState', null)}
                  />
                );
              })()}
          </div>
        }
      />
    </>
  );
});

export default withRouter(Koppelen);
