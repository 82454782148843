import {
  IOphalenDienstenParams,
  IOphalenDienstenResult,
  IWijzigenDienstParams,
  IWijzigenDienstResult,
} from '../../../../../../shared/src/api/v2/dienst/creditmanagement';
import api from '../../../index';
import ICheckData from '../../../../../../shared/src/models/ICheckData';

const creditmanagement = {
  ophalenDiensten: async (params: IOphalenDienstenParams): Promise<IOphalenDienstenResult> => {
    return await api.post('v2/dienst/creditmanagement/ophalen-diensten', params);
  },
  wijzigenDienst: async (params: IWijzigenDienstParams): Promise<IWijzigenDienstResult> => {
    return await api.post('v2/dienst/creditmanagement/wijzigen-dienst', params);
  },
  checkWijzigenDienst: async (params: IWijzigenDienstParams): Promise<ICheckData> => {
    return await api.post('v2/dienst/creditmanagement/check-wijzigen-dienst', params);
  },
};

export default creditmanagement;
