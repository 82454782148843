import React, { useCallback, useContext, useMemo } from 'react';
import { ISectieProps } from '../index';
import ASPTabel from '../../../../../../../../../../../components/tabel/ASPTabel';
import { IContractbasisHuur } from '../../../../../../../../../../../../../shared/src/api/v2/relatie/contract';
import {
  ASPKolom,
  EAspKolomBreedteType,
} from '../../../../../../../../../../../components/tabel/ASPTabel/types';
import { format } from 'date-fns';
import FormatteerBedrag from '../../../../../../../../../../../components/MutatieBedrag';
import MenuLayout from '../../../../../../../../../../../components/MenuLayout';
import { Kleur } from '../../../../../../../../../../../bedrijfslogica/constanten';
import { IconToevoegen } from '../../../../../../../../../../../components/Icons';
import { GlobaleRendererContext } from '../../../../../../../../../../../one-off-components/GlobaleRenderer';
import NieuwContractTariefDialoog, {
  INieuwContractTariefFormikValues,
} from '../../../../../../../../../../../components/tarief/NieuwContractTariefDialoog';
import api from '../../../../../../../../../../../api';

type Kolom =
  | 'ingangsdatum'
  | 'maandhuur'
  | 'indexeringspercentage'
  | 'aantalGebruikers'
  | 'reden'
  | 'geregistreerd';

const TarievenSectie = (props: ISectieProps) => {
  const globaleRenderer = useContext(GlobaleRendererContext);
  const contractbasisHuren = useMemo(
    () => props.dataBijID.contractbasisHurenBijCntBasisID[props.contract.CntBasisID] ?? [],
    [props.contract.CntBasisID, props.dataBijID.contractbasisHurenBijCntBasisID],
  );

  const keyExtractor = useCallback((rij: IContractbasisHuur) => rij.ID, []);
  const kolommen = useMemo<ASPKolom<Kolom, IContractbasisHuur>[]>(
    () => [
      {
        key: 'ingangsdatum',
        label: 'Ingangsdatum',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 125,
        renderer: (rij) => format(new Date(rij.Ingangsdatum), 'dd-MM-yyyy'),
      },
      {
        key: 'maandhuur',
        label: 'Maandhuur',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 125,
        renderer: (rij) => <FormatteerBedrag bedrag={rij.Maandhuur} />,
      },
      // {
      //   key: 'aantalGebruikers',
      //   label: 'Aantal gebruikers',
      //   breedteType: EAspKolomBreedteType.Vast,
      //   vasteBreedte: 175,
      //   renderer: (rij) => rij.AantalGebruikers ?? 'Niet opgegeven',
      // },
      {
        key: 'reden',
        label: 'Reden',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 350,
        renderer: (rij) => rij.Reden,
      },
      {
        key: 'geregistreerd',
        label: 'Geregistreerd',
        breedteType: EAspKolomBreedteType.Flex,
        flex: 1,
        renderer: (rij) =>
          rij.RecordToegevoegd !== null
            ? format(new Date(rij.RecordToegevoegd), 'dd-MM-yyyy HH:mm')
            : '',
      },
      // {
      //   key: 'indexeringspercentage',
      //   label: 'Indexeringspercentage',
      //   breedteType: EAspKolomBreedteType.Flex,
      //   flex: 1,
      //   renderer: (rij) => {
      //     const indexering =
      //       rij.IndexeringID === null
      //         ? null
      //         : props.dataBijID.indexeringenBijID[rij.IndexeringID] ?? null;

      //     if (indexering === null) {
      //       return '';
      //     }
      //     return `${indexering.Percentage}%`;
      //   },
      // },
    ],
    [props.dataBijID.indexeringenBijID],
  );

  const meestRecenteContractbasisHuur = useMemo(() => {
    return contractbasisHuren.reduce<IContractbasisHuur | null>((meestRecente, huidige) => {
      if (meestRecente === null || huidige.Ingangsdatum > meestRecente.Ingangsdatum) {
        return huidige;
      }
      return meestRecente;
    }, null);
  }, [contractbasisHuren]);

  const handleTariefToevoegenClick = useCallback(async () => {
    const initieleWaarden: Partial<INieuwContractTariefFormikValues> = {
      maandhuur: meestRecenteContractbasisHuur?.Maandhuur ?? 0,
    };
    const output = await globaleRenderer.render((renderProps) => (
      <NieuwContractTariefDialoog
        open
        onSuccess={(output) => renderProps.destroy(output)}
        onAnnuleren={() => renderProps.destroy()}
        cntBasisIDs={[props.contract.CntBasisID]}
        initieleWaarden={initieleWaarden}
      />
    ));

    if (output === undefined) {
      return;
    }
    await props.vernieuwenContracten();
  }, [props.vernieuwenContracten, meestRecenteContractbasisHuur]);

  const handleVerwijderenRij = useCallback(
    async (rij: IContractbasisHuur) => {
      await api.v2.contract.tarief.verwijderenContracttarieven({
        ids: [rij.ID],
      });

      await props.vernieuwenContracten();
    },
    [props.vernieuwenContracten],
  );

  return (
    <div className="d-flex flex-fill flex-column" style={{ height: 250 }}>
      <MenuLayout
        menu={
          <div className="d-flex align-items-center">
            <button
              className="btn btn-sm btn-light d-flex align-items-center"
              style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
              onClick={handleTariefToevoegenClick}
            >
              <IconToevoegen style={{ width: 18, height: 18, fill: Kleur.Grijs }} />
              <span className="ml-2">Tarief toevoegen</span>
            </button>
          </div>
        }
        body={
          <ASPTabel
            rijen={contractbasisHuren}
            kolommen={kolommen}
            keyExtractor={keyExtractor}
            onVerwijderenRij={handleVerwijderenRij}
          />
        }
      />
    </div>
  );
};

export default TarievenSectie;
