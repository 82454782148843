import React, { PropsWithChildren, useContext, useEffect, useMemo, useRef, useState } from 'react';
import MultiCombobox, { IKolom } from '../MultiCombobox';
import { formatteerAdresV2 } from '../../../helpers';
import api from '../../../api';
import IPaginatiePositie from '../../../../../shared/src/models/IPaginatiePositie';
import { IOphalenLocatiesMetZoektermResultElement } from '../../../../../shared/src/api/v2/locatie/locatie';
import { IconToevoegen, IconWijzigen } from '../../Icons';
import { Kleur } from '../../../bedrijfslogica/constanten';
import VinkVeld from '../VinkVeld';
import VerticaleScheidingslijn from '../../layout/VerticaleScheidingslijn';
import LocatieMutatieDialoog, { ELocatieMutatieDialoogType } from './LocatieMutatieDialoog';
import { IFilterSchemaFilter } from '../../../../../shared/src/models/filter';
import _ from 'lodash';

interface IProps {
  locID: number | null;
  onChange: (locID: number | null) => void;
  /**
   * Als het relID is opgegeven dan wordt er de mogelijkheid gegeven om alleen locaties weer te geven
   * die bij deze relatie horen, voor het eenvoudig selecteren van een locatie
   */
  relIDs?: number[];
  options?: IOptions;
}

export interface IOptions {
  mogelijkeLocIDs?: number[];
  bijEnkeleDirectVoorselecteren?: boolean;
  zonderToevoegen?: boolean;
  zonderMuteren?: boolean;
}

const defaultOptions: IOptions = {
  mogelijkeLocIDs: undefined,
  bijEnkeleDirectVoorselecteren: false,
  zonderToevoegen: false,
  zonderMuteren: false,
};

interface ILocatieSelectieContext {
  zoekterm: string;
  onZoektermChange: (zoekterm: string) => void;
  relIDs?: number[];
  alleenVoorRelatieTonen: boolean;
  onAlleenVoorRelatieTonenChange: (tonen: boolean) => void;
  onNieuweLocatieDialoogTonenChange: (tonen: boolean) => void;
  options: IOptions;
}

const LocatieSelectieContext = React.createContext<ILocatieSelectieContext>(null as any);

const PopoverComp = (props: PropsWithChildren<any>) => {
  const {
    zoekterm,
    onZoektermChange,
    relIDs,
    alleenVoorRelatieTonen,
    onAlleenVoorRelatieTonenChange,
    onNieuweLocatieDialoogTonenChange,
    options,
  } = useContext(LocatieSelectieContext);
  return (
    <div className="d-flex flex-column">
      <div className="d-flex align-items-center p-2">
        <div style={{ width: '100%' }}>
          <input
            className="form-control"
            placeholder="Zoeken..."
            value={zoekterm}
            onChange={(ev) => onZoektermChange(ev.target.value)}
            disabled={alleenVoorRelatieTonen}
            style={{
              minWidth: 225,
            }}
          />
        </div>

        {relIDs !== undefined && (
          <>
            <VerticaleScheidingslijn className="ml-3 mr-2" height={25} />
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ minWidth: 170 }}
            >
              <VinkVeld
                aangevinkt={alleenVoorRelatieTonen}
                onGewijzigd={(x) => onAlleenVoorRelatieTonenChange(x)}
              />
              <span className="ml-1">Alleen voor relatie{relIDs.length !== 1 && 's'} tonen</span>
            </div>
          </>
        )}

        {!options.zonderToevoegen && (
          <>
            <VerticaleScheidingslijn className="ml-3 mr-3" height={25} />
            <button
              className="btn btn-light btn-sm"
              onClick={() => {
                onNieuweLocatieDialoogTonenChange(true);
                document.body.click();
              }}
              style={{ minWidth: 105, border: `1px solid ${Kleur.LichtGrijs}` }}
            >
              <IconToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
              &nbsp; Toevoegen
            </button>
          </>
        )}
      </div>

      {/*{zoekterm === '' && !alleenVoorRelatieTonen ? (*/}
      {/*  <div className="p-4 d-flex align-items-center justify-content-center">*/}
      {/*    Zoek om te beginnen...*/}
      {/*  </div>*/}
      {/*) : (*/}
      {/*  props.children*/}
      {/*)}*/}
      {props.children}
    </div>
  );
};

const LocatieSelectie: React.FC<IProps> = (props) => {
  const options = useMemo(() => ({ ...defaultOptions, ...props.options }), [props.options]);

  const { locID, onChange, relIDs } = props;

  const [locaties, setLocaties] = useState<IOphalenLocatiesMetZoektermResultElement[] | null>(null);
  const [paginatie, setPaginatie] = useState<IPaginatiePositie | null>(null);
  const [zoekterm, setZoekterm] = useState<string | null>(null);
  const [zoektermInput, setZoektermInput] = useState('');
  const zoektermDebounceCancelFn = useRef<(() => void) | null>(null);
  const [alleenVoorRelatieTonen, setAlleenVoorRelatieTonen] = useState(relIDs !== undefined);

  const [nieuweLocatieDialoogTonen, setNieuweLocatieDialoogTonen] = useState(false);
  const [wijzigenLocatieDialoogTonen, setWijzgenLocatieDialoogTonen] = useState<number | null>(
    null,
  );

  // Bij enkele locatie direct voorselecteren
  useEffect(() => {
    if (
      locID !== null ||
      !options.bijEnkeleDirectVoorselecteren ||
      locaties === null ||
      locaties.length !== 1
    ) {
      return;
    }

    const locatie = locaties[0]!;
    onChange(locatie.LocID);
  }, [options.bijEnkeleDirectVoorselecteren, locaties, locID, onChange]);

  const kolommen = useMemo<IKolom<IOphalenLocatiesMetZoektermResultElement>[]>(() => {
    return [
      {
        key: 'Straatnaam',
        label: 'Straatnaam',
        breedte: 150,
      },
      {
        key: 'Huisnummer',
        label: 'Huisnr',
        breedte: 75,
      },
      {
        key: 'Bisnummer',
        label: 'Bisnr',
        breedte: 75,
      },
      {
        key: 'Postcode',
        label: 'Postcode',
        breedte: 100,
      },
      {
        key: 'Plaatsnaam',
        label: 'Plaatsnaam',
        breedte: 150,
      },
    ];
  }, []);

  useEffect(() => {
    (async () => {
      /** Als de relID gevuld is, dan wordt de rijbron bepaald door de relatie, mits het vinkveld is
       * aangevinkt.
       */
      if (relIDs === undefined || !alleenVoorRelatieTonen) {
        if (zoekterm === null && options.mogelijkeLocIDs === undefined) {
          setLocaties(null);
          return;
        }
        // const pag =
        //   paginatie === null
        //     ? {
        //         index: 0,
        //         aantal: 50,
        //       }
        //     : {
        //         index: paginatie.index + 1,
        //         aantal: paginatie.aantal,
        //       };
        const locatiesResult = await api.v2.locatie.ophalenLocaties({
          // paginatie: pag,
          paginatie: {
            index: 0,
            aantal: 250,
          },
          filterSchema: {
            filters: [
              zoekterm === null
                ? null
                : {
                    naam: 'ZOEKTERM',
                    data: zoekterm,
                  },
              options.mogelijkeLocIDs !== undefined
                ? {
                    naam: 'IDS',
                    data: options.mogelijkeLocIDs,
                  }
                : null,
            ].filter((x) => x !== null) as IFilterSchemaFilter[],
          },
        });

        // setPaginatie(pag);
        setLocaties(locatiesResult.locaties);
      } else {
        // Relatie gekozen
        const locatiesResult = await api.v2.locatie.ophalenLocatiesVanRelatieCorrespondentieadres({
          relIDs,
        });

        // setPaginatie(null);
        setLocaties(locatiesResult.locaties);
      }
    })();
  }, [relIDs, alleenVoorRelatieTonen, options.mogelijkeLocIDs, zoekterm]);

  useEffect(() => {
    if (zoektermDebounceCancelFn.current !== null) {
      zoektermDebounceCancelFn.current();
      zoektermDebounceCancelFn.current = null;
    }

    const trimmedZoekterm = zoektermInput.trim();
    const debounced = _.debounce(
      () => setZoekterm(trimmedZoekterm.length === 0 ? null : trimmedZoekterm),
      500,
    );

    zoektermDebounceCancelFn.current = debounced.cancel;
    debounced();
  }, [zoektermInput]);

  const contextValue = useMemo<ILocatieSelectieContext>(() => {
    return {
      zoekterm: zoektermInput,
      onZoektermChange: setZoektermInput,
      relIDs,
      alleenVoorRelatieTonen,
      onAlleenVoorRelatieTonenChange: setAlleenVoorRelatieTonen,
      onNieuweLocatieDialoogTonenChange: setNieuweLocatieDialoogTonen,
      options,
    };
  }, [
    zoektermInput,
    setZoektermInput,
    relIDs,
    alleenVoorRelatieTonen,
    setAlleenVoorRelatieTonen,
    setNieuweLocatieDialoogTonen,
    options,
  ]);

  if (alleenVoorRelatieTonen && relIDs === undefined) {
    throw new Error(`Alleen voor relatie tonen verwacht een RelID`);
  }

  return (
    <LocatieSelectieContext.Provider value={contextValue}>
      <MultiCombobox<number, IOphalenLocatiesMetZoektermResultElement>
        sleutelExtractor={(row) => row.LocID}
        representatieFabriek={(loc) => {
          return formatteerAdresV2({
            straatnaam: loc.Straatnaam,
            postcode: loc.Postcode,
            plaatsnaam: loc.Plaatsnaam,
            landnaamKort: loc.LandnaamKort,
            landnaamEnum: loc.LandnaamEnum,
            huisnummer: loc.Huisnummer,
            bisnummer: loc.Bisnummer,
          });
        }}
        waarde={locID}
        onWaardeChange={onChange}
        kolommen={kolommen}
        opties={locaties}
        options={{
          entiteitBepaler: async (id) => {
            const locatiesResult = await api.v2.locatie.ophalenLocaties({
              filterSchema: { filters: [{ naam: 'IDS', data: [id] }] },
            });
            return locatiesResult.locaties[0];
          },
          geenWaardeBericht: 'Geen locatie',
          toevoegingRechts:
            locID === null || options.zonderMuteren ? (
              undefined
            ) : (
              <div
                className="d-flex justify-content-center align-items-center mr-2"
                style={{ marginTop: 1 }}
                onClick={(ev) => {
                  ev.stopPropagation();
                  setWijzgenLocatieDialoogTonen(locID);
                }}
              >
                <IconWijzigen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
              </div>
            ),
          popoverComp: PopoverComp,
        }}
      />

      {nieuweLocatieDialoogTonen && (
        <LocatieMutatieDialoog
          type={ELocatieMutatieDialoogType.Nieuw}
          open
          onSuccess={(result) => {
            onChange(result.locID);
            setNieuweLocatieDialoogTonen(false);
          }}
          onAnnuleren={() => setNieuweLocatieDialoogTonen(false)}
          dialoogIndex={1}
        />
      )}

      {wijzigenLocatieDialoogTonen !== null && (
        <LocatieMutatieDialoog
          type={ELocatieMutatieDialoogType.Muteren}
          open
          onSuccess={(result) => {
            onChange(result.locID);
            setWijzgenLocatieDialoogTonen(null);
          }}
          onAnnuleren={() => setWijzgenLocatieDialoogTonen(null)}
          dialoogIndex={1}
          locID={wijzigenLocatieDialoogTonen}
        />
      )}
    </LocatieSelectieContext.Provider>
  );
};

export default LocatieSelectie;
