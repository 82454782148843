import React, { useCallback, useMemo, useState, useEffect, useContext } from 'react';
import IDialoogProps from '../../../../core/IDialoogProps';
import { Modal, ModalTitle, ModalBody, ModalFooter } from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/ModalHeader';
import { Formik, Field, FieldProps, FormikErrors, FormikActions } from 'formik';
import nameof from '../../../../core/nameOf';
import DatumKiezer from '../../../../components/formulier/DatumKiezer';
import BedragInput from '../../../../components/formulier/BedragInput';
import VinkVeld from '../../../../components/formulier/VinkVeld';
import Combobox, { IOptie } from '../../../../components/formulier/Combobox';
import MultiCombobox, { IKolom } from '../../../../components/formulier/MultiCombobox';
import { IOphalenContractenResultElementV2 } from '../../../../../../shared/src/api/v2/contract';
import {
  IOphalenRekeningenResultElement,
  IOphalenRelatiesResultElementV2,
} from '../../../../../../shared/src/api/v2/relatie';
import { EBetaalwijze, ERegelstatusVordering } from '../../../../bedrijfslogica/enums';
import { addDays, format } from 'date-fns';
import api from '../../../../api';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import { EResultType } from '../../../../stores/CheckStore';
import { RootStoreContext } from '../../../../stores/RootStore';
import { IToevoegenVorderingParams } from '../../../../../../shared/src/api/v2/vordering';
import FormikVeldFout from '../../../../components/formulier/FormikVeldFout';
import Dialoog from '../../../../components/dialogen/Dialoog';
import { dagDatum } from '../../../../helpers/datum';
import _ from 'lodash';
import { IOphalenGrootboekenResultElement } from '../../../../../../shared/src/api/v2/boekhouding/boeking/grootboek';
import { Kleur as EKleur } from '../../../../bedrijfslogica/constanten';

interface IFormikValues {
  bedrag: number;
  credit: boolean;
  omschrijving: string;
  cntID: number | null;
  regelstatus: number;
  periodeVan: Date | null;
  periodeTot: Date | null;
  directFactuur: boolean;
  bankopdracht: boolean;
  betaaldatum: Date | null;
  grootboekrekening: number;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  bedrag: 'Bedrag (incl. btw)',
  credit: 'Credit-vordering',
  omschrijving: 'Omschrijving',
  cntID: 'Contract',
  regelstatus: 'Statustype',
  periodeVan: 'Van',
  periodeTot: 'Tot',
  directFactuur: 'Direct factuur maken',
  bankopdracht: 'Bankopdracht',
  betaaldatum: 'Betaaldatum',
  grootboekrekening: 'Grootboek',
};

interface IDialoogResult {}

interface IProps extends IDialoogProps<IDialoogResult> {
  relID: number;
  cntID?: number;
}

interface IRelatie extends IOphalenRelatiesResultElementV2 {
  standaardrekening: IOphalenRekeningenResultElement | null;
}

const NieuweVorderingDialoog: React.FC<IProps> = (props) => {
  const { checkStore } = useContext(RootStoreContext);
  const { onAnnuleren, onSuccess, open, relID } = props;
  // const [bedrag, setBedrag] = useState<number | null>(null);
  const [regelStatussen, setRegelStatussen] = useState<IOptie<number>[] | null>(null);
  const [contracten, setContracten] = useState<IOphalenContractenResultElementV2[] | null>(null);
  const [relatie, setRelatie] = useState<IRelatie | null>(null);
  const [grootboekrekeningen, setGrootboekrekeningen] = useState<
    IOphalenGrootboekenResultElement[] | null
  >(null);

  const ophalenRelatie = useCallback(async () => {
    const relatiesResult = await api.v2.relatie.ophalenRelaties({
      filterSchema: { filters: [{ naam: 'IDS', data: [props.relID] }] },
    });

    const relatie = relatiesResult.relaties[0];

    const standaardrekening =
      relatie.financieel.StandaardRekening_RelRekID !== null
        ? relatie.financieel.rekeningen.find(
            (x) => x.RelRekID === relatie.financieel.StandaardRekening_RelRekID,
          )!
        : null;

    setRelatie({ ...relatie, standaardrekening });
  }, [props.relID]);

  useEffect(() => {
    ophalenRelatie();
  }, [ophalenRelatie]);

  const ophalenContracten = useCallback(async () => {
    const contracten = (
      await api.v2.contract.ophalenContractenV2({
        filterSchema: { filters: [{ naam: 'REL_IDS', data: [props.relID] }] },
        orderSchema: {
          orders: [
            { naam: 'INGANGSDATUM', richting: 'DESC' },
            { naam: 'BASISNUMMER', richting: 'DESC' },
          ],
        },
      })
    ).contracten;

    setContracten(contracten);
  }, [props.relID]);

  useEffect(() => {
    ophalenContracten();
  }, [ophalenContracten]);

  const ophalenRegelStatussen = useCallback(async () => {
    if (relatie === null) {
      return;
    }

    const params = relatie!.debiteur!.GeenWOPVordering
      ? {
          filterSchema: {
            filters: [
              {
                naam: 'STATUS',
                data: [ERegelstatusVordering.WOP],
                inverteren: true,
              },
            ],
          },
        }
      : {};
    const { statussen: statussenResult } = await api.v2.vordering.ophalenRegelStatussen(params);

    const statussen = statussenResult.map((x) => {
      return { id: x.Status, label: x.Naam };
    });

    setRegelStatussen(statussen);
  }, [relatie]);

  useEffect(() => {
    ophalenRegelStatussen();
  }, [ophalenRegelStatussen]);

  const ophalenGrootboekrekeningen = useCallback(async () => {
    const rekeningenResult = await api.v2.boeking.grootboek.ophalenGrootboeken({
      filterSchema: { filters: [{ naam: 'NUMMERS', data: [8000, 1640] }] },
    });

    setGrootboekrekeningen(rekeningenResult.grootboeken);
  }, []);

  useEffect(() => {
    ophalenGrootboekrekeningen();
  }, [ophalenGrootboekrekeningen]);

  const contractwisselKolommen = useMemo<IKolom<IOphalenContractenResultElementV2>[]>(
    () => [
      {
        key: '__Contractnummer' as any,
        label: 'Cnt.nr',
        breedte: 75,
        formatFabriek: (rij) => rij.basis.Basisnummer + '.' + rij.Volgnummer,
      },
      {
        key: '__Ingangsdatum' as any,
        label: 'Ing.datum',
        breedte: 85,
        formatFabriek: (rij) => {
          const datum = format(new Date(rij.Ingangsdatum), 'dd-MM-yyyy');
          return datum;
        },
      },
      {
        key: '__Einddatum' as any,
        label: 'Einddatum',
        breedte: 85,
        formatFabriek: (rij) => {
          const datum =
            rij.Einddatum !== null ? format(new Date(rij.Einddatum!), 'dd-MM-yyyy') : '';
          return datum;
        },
      },
      {
        key: '__modelcode' as any,
        label: 'Model',
        breedte: 100,
        formatFabriek: (rij) => rij.basis.productmodel.Modelcode,
      },
      {
        key: '__productsoortnaamKort' as any,
        label: 'Soort',
        breedte: 60,
        formatFabriek: (rij) => {
          return rij.basis.productmodel.Productsoortnaam;
        },
      },
      {
        key: '__Locatie' as any,
        label: 'Locatie',
        breedte: 250,
        formatFabriek: (rij) => {
          return (
            rij.basis.locatie.Straatnaam +
            ' ' +
            rij.basis.locatie.Huisnummer +
            (rij.basis.locatie.Bisnummer !== null ? ` ${rij.basis.locatie.Bisnummer}` : '')
          );
        },
      },
    ],
    [],
  );

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (grootboekrekeningen === null || regelStatussen === null) {
      return null;
    }

    return {
      bedrag: 0,
      credit: false,
      omschrijving: ``,
      cntID: props.cntID ?? null,
      regelstatus: regelStatussen[0].id,
      periodeVan: null,
      periodeTot: null,
      directFactuur: false,
      bankopdracht: true,
      betaaldatum: null,
      grootboekrekening: grootboekrekeningen.find((x) => x.Nummer === 8000)!.ID,
    };
  }, [grootboekrekeningen, regelStatussen]);

  const handleNieuweVordering = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      if (relatie === null) {
        return;
      }

      if (!values.credit) {
        if (values.bedrag > 10000) {
          if (
            (
              await checkStore.bevestigen({
                inhoud: (
                  <span>
                    Het bedrag is meer dan € 10.000,-
                    <br />
                    Wil je toch doorgaan?
                  </span>
                ),
              })
            ).type === EResultType.Annuleren
          ) {
            return;
          }
        }
      } else {
        if (values.bedrag > 1000) {
          if (
            (
              await checkStore.bevestigen({
                inhoud: (
                  <span>
                    Het te crediteren bedrag is meer dan € 1000,-
                    <br />
                    Wil je toch doorgaan?
                  </span>
                ),
              })
            ).type === EResultType.Annuleren
          ) {
            return;
          }
        }
      }

      actions.setSubmitting(true);

      const params: IToevoegenVorderingParams = {
        bedrag: values.bedrag,
        cntID: values.cntID,
        credit: values.credit,
        relID: props.relID,
        omschrijving: values.omschrijving,
        periodeTot: values.periodeTot !== null ? format(values.periodeTot, 'yyyy-MM-dd') : null,
        periodeVan: values.periodeVan !== null ? format(values.periodeVan, 'yyyy-MM-dd') : null,
        regelstatus: values.regelstatus,
        grbRekID: values.grootboekrekening,
        directFactuur: false, //values.regelstatus === 0 && values.directFactuur,
      };

      const checkData = await api.v2.vordering.checkToevoegenVordering(params);

      // Voege eventueel checkdata toe als er een bankopdracht gewenst is maar er geen bankrekening beschikbaar is
      if (values.directFactuur && values.bankopdracht && relatie.standaardrekening === null) {
        checkData.errors.push(
          'Voor een bankopdracht is een bankrekening nodig. Voor deze relatie is geen standaardrekening opgegeven.',
        );
      }

      const controleResult = await checkStore.controleren({
        checkData,
      });
      if (controleResult.type === EResultType.Annuleren) {
        actions.setSubmitting(false);
        return;
      }

      if (
        (
          await checkStore.bevestigen({
            inhoud: (
              <span>
                Vordering maken?
                {values.directFactuur ? (
                  <span>
                    <br />
                    <br />
                    Er zal hiervoor direct een factuur gemaakt worden
                  </span>
                ) : (
                  ''
                )}
              </span>
            ),
          })
        ).type === EResultType.Annuleren
      ) {
        actions.setSubmitting(false);
        return;
      }

      const vorderingResult = await api.v2.vordering.toevoegenVordering(params);

      // Factuur maken
      if (values.directFactuur && values.regelstatus === 0) {
        const betWijzeID =
          values.bankopdracht && !values.credit
            ? (
                await api.v2.betaalwijze.ophalenBetaalwijzen({
                  filterSchema: { filters: [{ naam: 'NAAM_ENUMS', data: [EBetaalwijze.Incasso] }] },
                })
              ).betaalwijzen[0].BetWijzeID
            : undefined;

        const factuurResult = await api.v2.vordering.makenFactuurVoorstel({
          factRegIDs: [vorderingResult.factRegID],
          factuurdatum: null,
          definitief: true,
          betWijzeID,
        });

        // Bankopdracht (Incasso of Betaal) maken
        if (values.bankopdracht) {
          await api.v2.factuur.makenBankopdrachten({
            factIDs: factuurResult.factIDs,
            uitvoerdatum: values.betaaldatum !== null ? dagDatum(values.betaaldatum) : null,
          });
        }
      }

      onSuccess({});
      actions.setSubmitting(true);
    },
    [relatie],
  );

  const handleValidate = useCallback((values: IFormikValues) => {
    const errors: FormikErrors<IFormikValues> = {};

    if (values.bedrag < 0) {
      errors.bedrag = `Ongeldig bedrag`;
    }

    if (values.omschrijving === ``) {
      errors.omschrijving = `Omschrijving is verplicht`;
    }

    if (values.periodeTot !== null && values.periodeVan === null) {
      errors.periodeVan = `Verplicht`;
    }

    // if (values.regelstatus === ERegelstatusVordering.WOP && values.cntID === null) {
    //   errors.cntID = `Bij een WOP-vordering moet een contract opgegeven zijn`;
    // }

    return errors;
  }, []);

  return initialValues === null || grootboekrekeningen === null || contracten === null ? (
    <div className="d-flex flex-fill align-items-center justify-content-center p-5">
      <LoadingSpinner />
    </div>
  ) : (
    <Formik<IFormikValues>
      initialValues={initialValues}
      onSubmit={handleNieuweVordering}
      validate={handleValidate}
      render={(FormikProps) => {
        const { submitForm, isSubmitting, values, errors } = FormikProps;
        return (
          <Dialoog index={props.dialoogIndex || 0}>
            <ModalHeader>
              <ModalTitle>Nieuwe vordering</ModalTitle>
            </ModalHeader>
            <ModalBody>
              <div>
                <div className="row justify-content-between">
                  <div className="col-4 align-items-left">
                    <label>{veldnamen.bedrag}</label>
                    <Field
                      name={nameof<IFormikValues>('bedrag')}
                      render={(fieldProps: FieldProps<IFormikValues>) => {
                        const { field, form } = fieldProps;
                        return (
                          <div>
                            <BedragInput
                              value={Math.abs(field.value) || 0}
                              onChange={(x) => {
                                form.setFieldValue(field.name, x);
                                // setBedrag(x);
                                if (x <= 0) {
                                  form.setFieldValue(nameof<IFormikValues>('credit'), false);
                                }
                              }}
                              min={0}
                              // max={+10000}
                            />
                            <FormikVeldFout fieldProps={fieldProps} />
                          </div>
                        );
                      }}
                    />
                  </div>
                  <div className="col-4">
                    <label>{veldnamen.credit}</label>
                    <Field
                      name={nameof<IFormikValues>('credit')}
                      render={(fieldProps: FieldProps<IFormikValues>) => {
                        const { field, form } = fieldProps;
                        return (
                          <VinkVeld
                            onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                            aangevinkt={field.value}
                          />
                        );
                      }}
                    />
                  </div>
                  <div className="col-4 position-relative">
                    <label>{veldnamen.regelstatus}</label>
                    <Field
                      name={nameof<IFormikValues>('regelstatus')}
                      render={(fieldProps: FieldProps<IFormikValues>) => {
                        const { field, form } = fieldProps;
                        return regelStatussen === null ? (
                          <LoadingSpinner />
                        ) : (
                          <Combobox
                            geselecteerd={field.value}
                            onSelectieChange={(x) => form.setFieldValue(field.name, x)}
                            opties={regelStatussen!}
                          />
                        );
                      }}
                    />
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-6">
                    <label>{veldnamen.cntID}</label>
                    <Field
                      name={nameof<IFormikValues>('cntID')}
                      render={(fieldProps: FieldProps<IFormikValues>) => {
                        const { field, form } = fieldProps;

                        return (
                          <>
                            <MultiCombobox<number, IOphalenContractenResultElementV2>
                              sleutelExtractor={(row) => row.CntID}
                              representatieFabriek={(row) =>
                                row.basis.Basisnummer +
                                '.' +
                                row.Volgnummer +
                                ' - ' +
                                row.basis.productmodel.Modelcode
                              }
                              waarde={field.value}
                              onWaardeChange={(x) => form.setFieldValue(field.name, x)}
                              opties={contracten}
                              kolommen={contractwisselKolommen}
                              isWisbaar
                              options={{
                                geenWaardeBericht: 'Geen contract',
                              }}
                            />
                            <FormikVeldFout fieldProps={fieldProps} />
                          </>
                        );
                      }}
                    />
                  </div>

                  <div className="col-6">
                    <label>{veldnamen.grootboekrekening}</label>
                    <Field
                      name={nameof<IFormikValues>('grootboekrekening')}
                      render={(fieldProps: FieldProps<IFormikValues>) => {
                        const { field, form } = fieldProps;
                        return regelStatussen === null ? (
                          <LoadingSpinner />
                        ) : (
                          <Combobox
                            geselecteerd={field.value}
                            onSelectieChange={(x) => form.setFieldValue(field.name, x)}
                            opties={grootboekrekeningen.map((x) => {
                              return {
                                id: x.ID,
                                label: x.Naam + ' - ' + x.Nummer,
                              };
                            })}
                          />
                        );
                      }}
                    />
                  </div>
                </div>

                {values.cntID !== null && (
                  <div className="row pt-3">
                    <div className="col-4">
                      <label>{veldnamen.periodeVan}</label>
                      <Field
                        name={nameof<IFormikValues>('periodeVan')}
                        render={(fieldProps: FieldProps<IFormikValues>) => {
                          const { field, form } = fieldProps;
                          return (
                            <>
                              <DatumKiezer
                                isClearable
                                onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                waarde={field.value !== null ? field.value : null}
                                determineValidDate={(datum) => {
                                  return true;
                                }}
                                determineNextValidDate="ONBEGRENST"
                                determinePreviousValidDate="ONBEGRENST"
                              />
                              <FormikVeldFout fieldProps={fieldProps} />
                            </>
                          );
                        }}
                      />
                    </div>
                    <div className="col-4">
                      <label>{veldnamen.periodeTot}</label>
                      <Field
                        name={nameof<IFormikValues>('periodeTot')}
                        render={(fieldProps: FieldProps<IFormikValues>) => {
                          const { field, form } = fieldProps;
                          return (
                            <DatumKiezer
                              isClearable
                              onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                              waarde={field.value !== null ? field.value : null}
                              determineValidDate={(datum) => {
                                return true;
                              }}
                              determineNextValidDate="ONBEGRENST"
                              determinePreviousValidDate="ONBEGRENST"
                            />
                          );
                        }}
                      />
                    </div>
                  </div>
                )}

                <div className="row">
                  <div className="col-12 mt-4">
                    <label>{veldnamen.omschrijving}</label>
                    <Field
                      name={nameof<IFormikValues>('omschrijving')}
                      render={(fieldProps: FieldProps<IFormikValues>) => {
                        const { field, form } = fieldProps;

                        return (
                          <>
                            <textarea {...field} className="form-control" rows={3} />
                            <FormikVeldFout fieldProps={fieldProps} />
                          </>
                        );
                      }}
                    />
                  </div>
                </div>

                {values.regelstatus === 0 && (
                  <>
                    <div className="row">
                      <div className="d-flex col-12 mt-3">
                        <Field
                          name={nameof<IFormikValues>('directFactuur')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <VinkVeld
                                onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                aangevinkt={field.value}
                              />
                            );
                          }}
                        />
                        <span className="ml-2">{veldnamen.directFactuur}</span>
                      </div>
                    </div>
                    {values.directFactuur && relatie !== null && relatie.debiteur !== null && (
                      // relatie.debiteur.betaalwijze.NaamEnum === EBetaalwijze.Incasso &&
                      // values.bedrag !== 0 &&
                      // values.regelstatus === ERegelstatusVordering.Direct
                      <div className="row" style={{ minHeight: 45 }}>
                        <div className="d-flex col-5 mt-3">
                          <Field
                            name={nameof<IFormikValues>('bankopdracht')}
                            render={(fieldProps: FieldProps<IFormikValues>) => {
                              const { field, form } = fieldProps;
                              return (
                                <VinkVeld
                                  onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                  aangevinkt={field.value}
                                />
                              );
                            }}
                          />
                          <span className="ml-2">
                            {values.credit ? 'Betaalopdracht' : 'Incasso-opdracht'}
                          </span>
                        </div>
                        {values.bankopdracht && (
                          <div className="col-7 mt-2 d-flex">
                            <div className="d-flex flex-fill align-items-center justify-content-end">
                              <span>{values.credit ? 'Betaaldatum' : 'Incassodatum'}</span>

                              <Field
                                name={nameof<IFormikValues>('betaaldatum')}
                                render={(fieldProps: FieldProps<IFormikValues>) => {
                                  const { field, form } = fieldProps;
                                  return (
                                    <Field
                                      name={nameof('betaaldatum')}
                                      render={({ field, form }: FieldProps<IFormikValues>) => {
                                        return (
                                          <div className="d-flex flex-fill align-items-center justify-content-end ml-2">
                                            <DatumKiezer
                                              onGewijzigd={(x) => {
                                                form.setFieldValue(field.name, x);
                                              }}
                                              waarde={field.value}
                                              isClearable
                                              placeholder={'Opdrachtdatum'}
                                              determineValidDate={(date) => {
                                                return (
                                                  date >= addDays(new Date(), 0) &&
                                                  date < addDays(new Date(), 65)
                                                );
                                              }}
                                            />
                                          </div>
                                        );
                                      }}
                                    />
                                  );
                                }}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </>
                )}

                {values.directFactuur &&
                  values.bankopdracht &&
                  values.regelstatus === ERegelstatusVordering.Direct &&
                  relatie !== null &&
                  relatie.debiteur !== null &&
                  !values.credit &&
                  relatie.debiteur.betaalwijze.NaamEnum !== EBetaalwijze.Incasso && (
                    <>
                      <div className="row col-12 mt-3">
                        <span style={{ color: EKleur.Rood }}>
                          De betaalwijze voor deze relatie is niet Automatische incasso
                        </span>
                      </div>
                    </>
                  )}

                {relatie!.debiteur!.GeenWOPVordering && (
                  <div className="mt-2">
                    Een WOP-vordering is niet toegestaan voor deze klant, zie klantgegevens.
                  </div>
                )}
              </div>
            </ModalBody>
            <ModalFooter className="d-flex flex-row justify-content-start">
              <button className="btn btn-primary" onClick={submitForm} style={{ width: 100 }}>
                Ok
              </button>
              <button className="btn btn-secondary" onClick={onAnnuleren} style={{ width: 100 }}>
                Annuleren
              </button>
            </ModalFooter>
          </Dialoog>
        );
      }}
    />
  );
};

export default NieuweVorderingDialoog;
