import React, { useMemo } from 'react';

import styled from 'styled-components';
import { Kleur } from '../../../../../../../bedrijfslogica/constanten';
import { IconContact, IconKruis, IconVerwijderen } from '../../../../../../Icons';
import IRemoteData, {
  createPendingRemoteData,
  ERemoteDataState,
} from '../../../../../../../models/IRemoteData';
import { IOphalenRelatiesResultElementV2 } from '../../../../../../../../../shared/src/api/v2/relatie';
import Skeleton from 'react-loading-skeleton';

const Root = styled.div`
  display: flex;
  align-items: center;
  background-color: #ffc3ff;
  padding: 2px 8px;
  border-radius: 5px;
`;

interface IProps {
  relID: number;
  relatiesCache: Record<number, IRemoteData<IOphalenRelatiesResultElementV2>>;
}

const Item = (props: IProps) => {
  const relatie = useMemo<IRemoteData<IOphalenRelatiesResultElementV2>>(() => {
    const entry = props.relatiesCache[props.relID];
    return entry ?? createPendingRemoteData();
  }, [props.relID, props.relatiesCache[props.relID]]);

  return (
    <Root>
      <IconContact style={{ fill: Kleur.DonkerGrijs, width: 18, height: 18, marginRight: 8 }} />
      {relatie.state === ERemoteDataState.Pending ? (
        <Skeleton width={150} />
      ) : (
        <span style={{ color: Kleur.DonkerGrijs, fontSize: 14 }}>{relatie.data!.weergavenaam}</span>
      )}
    </Root>
  );
};

export default Item;
