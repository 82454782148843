import {
  IBestandenMakenVanBatchesParams,
  IBestandenMakenVanBatchesResult,
  IExporterenBatchesParams,
  IExporterenBatchesResult,
  IBevestigenVerstuurdParams,
  IBevestigenVerstuurdResult,
  IOphalenBatchesParams,
  IOphalenBatchesResult,
  IOphalenOpdrachtenParams,
  IOphalenOpdrachtenResult,
  IVerwijderenBatchesParams,
  IVerwijderenBatchesResult,
  IVerzamelenOpdrachtenParams,
  IVerzamelenOpdrachtenResult,
  IVerwijderenOpdrachtenParams,
  IVerwijderenOpdrachtenResult,
  IVerwijderenBatchOpdrachtenParams,
  IVerwijderenBatchOpdrachtenResult,
  IWijzigenOpdrachtParams,
  IWijzigenOpdrachtResult,
  IWijzigenBatchParams,
  IWijzigenBatchResult,
  IVerzamelenOpdrachtenFilterParams,
  IVerzamelenOpdrachtenFilterResult,
  IVerwijderenVerkoopfacturenUitOpdrachtParams,
  IVerwijderenVerkoopfacturenUitOpdrachtResult,
  IVerwijderenInkoopfacturenUitOpdrachtParams,
  IVerwijderenInkoopfacturenUitOpdrachtResult,
  IInlezenSalarisbetalingenParams,
  IInlezenSalarisbetalingenResult,
  ICheckWijzigenBatchInitieelParams,
  ICheckVerwijderenBatchesInitieelParams,
  IToevoegenOpdrachtenParams,
  IToevoegenOpdrachtenResult,
  IVerwerkOpdrachtenMetGewijzigdeIBANParams,
  IVerwerkOpdrachtenMetGewijzigdeIBANResult,
  IOphalenSignaleringenBatchesParams,
  IOphalenSignaleringenBatchesResult,
  IOphalenSignaleringenOpdrachtenParams,
  IOphalenSignaleringenOpdrachtenResult,
  IOphalenPreviewBatchesParams,
  IOphalenPreviewBatchesResult,
  IWijzigenInkoopfactuurInOpdrachtParams,
  IWijzigenInkoopfactuurInOpdrachtResult,
  IVersturenStornoberichtenParams,
  IVersturenStornoberichtenResult,
  IWijzigenStornoberichtVersturenIndicatiesParams,
  IWijzigenStornoberichtVersturenIndicatiesResult,
  IVersturenBetaalspecificatiesParams,
  IVersturenBetaalspecificatiesResult,
  IOphalenSEPALandenParams,
  IOphalenSEPALandenResult,
} from '../../../../../shared/src/api/v2/bank/opdracht/opdracht';
import api from '../../index';
import ICheckData from '../../../../../shared/src/models/ICheckData';
import { IAchtergrondProces } from '../../../../../shared/src/api/v2/achtergrondProces';

const opdracht = {
  toevoegenOpdrachten: async (params: IToevoegenOpdrachtenParams) => {
    return await api.post<IToevoegenOpdrachtenResult>(
      'v2/bank/opdracht/toevoegen-opdrachten',
      params,
    );
  },
  ophalenOpdrachten: async (params: IOphalenOpdrachtenParams) => {
    return await api.post<IOphalenOpdrachtenResult>('v2/bank/opdracht/ophalen-opdrachten', params);
  },
  ophalenSEPALanden: async (params: IOphalenSEPALandenParams) => {
    return await api.post<IOphalenSEPALandenResult>('v2/bank/opdracht/ophalen-sepalanden', params);
  },
  ophalenBatches: async (params: IOphalenBatchesParams) => {
    return await api.post<IOphalenBatchesResult>('v2/bank/opdracht/ophalen-batches', params);
  },
  checkVerwijderenBatchesInitieel: async (params: ICheckVerwijderenBatchesInitieelParams) => {
    return await api.post<ICheckData>(
      'v2/bank/opdracht/check-verwijderen-batches-initieel',
      params,
    );
  },
  checkVerwijderenBatches: async (params: IVerwijderenBatchesParams) => {
    return await api.post<ICheckData>('v2/bank/opdracht/check-verwijderen-batches', params);
  },
  verwijderenBatches: async (params: IVerwijderenBatchesParams) => {
    return await api.post<IVerwijderenBatchesResult>(
      'v2/bank/opdracht/verwijderen-batches',
      params,
    );
  },
  verzamelenOpdrachtenFilter: async (params: IVerzamelenOpdrachtenFilterParams) => {
    return await api.post<IVerzamelenOpdrachtenFilterResult>(
      'v2/bank/opdracht/verzamelen-opdrachten-filter',
      params,
    );
  },
  checkVerzamelenOpdrachten: async (params: IVerzamelenOpdrachtenParams) => {
    return await api.post<ICheckData>('v2/bank/opdracht/check-verzamelen-opdrachten', params);
  },
  verzamelenOpdrachten: async (params: IVerzamelenOpdrachtenParams) => {
    return await api.post<IVerzamelenOpdrachtenResult>(
      'v2/bank/opdracht/verzamelen-opdrachten',
      params,
    );
  },
  verzamelenOpdrachtenAchtergrondProces: async (params: IVerzamelenOpdrachtenParams) => {
    return await api.post<IAchtergrondProces<IVerzamelenOpdrachtenResult>>(
      'v2/bank/opdracht/verzamelen-opdrachten-achtergrond-proces',
      params,
    );
  },
  checkExporterenBatches: async (params: IExporterenBatchesParams) => {
    return await api.post<ICheckData>('v2/bank/opdracht/check-exporteren-batches', params);
  },
  exporterenBatches: async (params: IExporterenBatchesParams) => {
    return await api.post<IExporterenBatchesResult>('v2/bank/opdracht/exporteren-batches', params);
  },
  bestandenMakenVanBatches: async (params: IBestandenMakenVanBatchesParams) => {
    return await api.post<IBestandenMakenVanBatchesResult>(
      'v2/bank/opdracht/bestanden-maken-van-batches',
      params,
    );
  },
  checkBevestigenVerstuurd: async (params: IBevestigenVerstuurdParams) => {
    return await api.post<ICheckData>('v2/bank/opdracht/check-bevestigen-verstuurd', params);
  },
  bevestigenVerstuurd: async (params: IBevestigenVerstuurdParams) => {
    return await api.post<IBevestigenVerstuurdResult>(
      'v2/bank/opdracht/bevestigen-verstuurd',
      params,
    );
  },
  checkVerwijderenOpdrachten: async (params: IVerwijderenOpdrachtenParams) => {
    return await api.post<ICheckData>('v2/bank/opdracht/check-verwijderen-opdrachten', params);
  },
  verwijderenOpdrachten: async (params: IVerwijderenOpdrachtenParams) => {
    return await api.post<IVerwijderenOpdrachtenResult>(
      'v2/bank/opdracht/verwijderen-opdrachten',
      params,
    );
  },
  checkVerwijderenVerkoopfacturenUitOpdracht: async (
    params: IVerwijderenVerkoopfacturenUitOpdrachtParams,
  ) => {
    return await api.post<ICheckData>(
      'v2/bank/opdracht/check-verwijderen-verkoopfacturen-uit-opdracht',
      params,
    );
  },
  verwijderenVerkoopfacturenUitOpdracht: async (
    params: IVerwijderenVerkoopfacturenUitOpdrachtParams,
  ) => {
    return await api.post<IVerwijderenVerkoopfacturenUitOpdrachtResult>(
      'v2/bank/opdracht/verwijderen-verkoopfacturen-uit-opdracht',
      params,
    );
  },
  checkVerwijderenInkoopfacturenUitOpdracht: async (
    params: IVerwijderenInkoopfacturenUitOpdrachtParams,
  ) => {
    return await api.post<ICheckData>(
      'v2/bank/opdracht/check-verwijderen-inkoopfacturen-uit-opdracht',
      params,
    );
  },
  verwijderenInkoopfacturenUitOpdracht: async (
    params: IVerwijderenInkoopfacturenUitOpdrachtParams,
  ) => {
    return await api.post<IVerwijderenInkoopfacturenUitOpdrachtResult>(
      'v2/bank/opdracht/verwijderen-inkoopfacturen-uit-opdracht',
      params,
    );
  },
  checkVerwijderenBatchOpdrachten: async (params: IVerwijderenBatchOpdrachtenParams) => {
    return await api.post<ICheckData>(
      'v2/bank/opdracht/check-verwijderen-batch-opdrachten',
      params,
    );
  },
  verwijderenBatchOpdrachten: async (params: IVerwijderenBatchOpdrachtenParams) => {
    return await api.post<IVerwijderenBatchOpdrachtenResult>(
      'v2/bank/opdracht/verwijderen-batch-opdrachten',
      params,
    );
  },
  checkWijzigenOpdracht: async (params: IWijzigenOpdrachtParams) => {
    return await api.post<ICheckData>('v2/bank/opdracht/check-wijzigen-opdracht', params);
  },
  wijzigenOpdracht: async (params: IWijzigenOpdrachtParams) => {
    return await api.post<IWijzigenOpdrachtResult>('v2/bank/opdracht/wijzigen-opdracht', params);
  },
  checkWijzigenBatchInitieel: async (params: ICheckWijzigenBatchInitieelParams) => {
    return await api.post<ICheckData>('v2/bank/opdracht/check-wijzigen-batch-initieel', params);
  },
  wijzigenBatch: async (params: IWijzigenBatchParams) => {
    return await api.post<IWijzigenBatchResult>('v2/bank/opdracht/wijzigen-batch', params);
  },
  inlezenSalarisbetalingen: async (
    params: IInlezenSalarisbetalingenParams,
  ): Promise<IInlezenSalarisbetalingenResult> => {
    return await api.post('v2/bank/opdracht/inlezen-salarisbetalingen', params);
  },
  checkVerwerkOpdrachtenMetGewijzigdeIBAN: async (
    params: IVerwerkOpdrachtenMetGewijzigdeIBANParams,
  ) => {
    return await api.post<ICheckData>(
      'v2/bank/opdracht/check-verwerk-opdrachten-met-gewijzigde-IBAN',
      params,
    );
  },
  verwerkOpdrachtenMetGewijzigdeIBAN: async (params: IVerwerkOpdrachtenMetGewijzigdeIBANParams) => {
    return await api.post<IVerwerkOpdrachtenMetGewijzigdeIBANResult>(
      'v2/bank/opdracht/verwerk-opdrachten-met-gewijzigde-IBAN',
      params,
    );
  },
  ophalenSignaleringenBatches: async (params: IOphalenSignaleringenBatchesParams) => {
    return await api.post<IOphalenSignaleringenBatchesResult>(
      'v2/bank/opdracht/ophalen-signaleringen-batches',
      params,
    );
  },
  ophalenSignaleringenOpdrachten: async (params: IOphalenSignaleringenOpdrachtenParams) => {
    return await api.post<IOphalenSignaleringenOpdrachtenResult>(
      'v2/bank/opdracht/ophalen-signaleringen-opdrachten',
      params,
    );
  },
  ophalenPreviewBatches: async (params: IOphalenPreviewBatchesParams) => {
    return await api.post<IOphalenPreviewBatchesResult>(
      'v2/bank/opdracht/ophalen-preview-batches',
      params,
    );
  },
  checkWijzigenInkoopfactuurInOpdracht: async (params: IWijzigenInkoopfactuurInOpdrachtParams) => {
    return await api.post<ICheckData>(
      'v2/bank/opdracht/check-wijzigen-inkoopfactuur-in-opdracht',
      params,
    );
  },
  wijzigenInkoopfactuurInOpdracht: async (params: IWijzigenInkoopfactuurInOpdrachtParams) => {
    return await api.post<IWijzigenInkoopfactuurInOpdrachtResult>(
      'v2/bank/opdracht/wijzigen-inkoopfactuur-in-opdracht',
      params,
    );
  },
  checkVersturenStornoberichten: async (params: IVersturenStornoberichtenParams) => {
    return await api.post<ICheckData>('v2/bank/opdracht/check-versturen-stornoberichten', params);
  },
  versturenStornoberichten: async (params: IVersturenStornoberichtenParams) => {
    return await api.post<IVersturenStornoberichtenResult>(
      'v2/bank/opdracht/versturen-stornoberichten',
      params,
    );
  },
  wijzigenStornoberichtVersturenIndicaties: async (
    params: IWijzigenStornoberichtVersturenIndicatiesParams,
  ) => {
    return await api.post<IWijzigenStornoberichtVersturenIndicatiesResult>(
      'v2/bank/opdracht/wijzigen-stornobericht-versturen-indicaties',
      params,
    );
  },
  checkVersturenBetaalspecificaties: async (params: IVersturenBetaalspecificatiesParams) => {
    return await api.post<ICheckData>(
      'v2/bank/opdracht/check-versturen-betaalspecificaties',
      params,
    );
  },
  versturenBetaalspecificaties: async (params: IVersturenBetaalspecificatiesParams) => {
    return await api.post<IVersturenBetaalspecificatiesResult>(
      'v2/bank/opdracht/versturen-betaalspecificaties',
      params,
    );
  },
};

export default opdracht;
