import React from 'react';
import { IWeergaveProps } from '../../../../../components/FilterBalkV2';
import Combobox from '../../../../../components/formulier/Combobox';

const ProductstaatFilter = (props: IWeergaveProps<any>) => {
  return (
    <div className="d-flex align-items-center">
      <span className="mr-2">Productstaat</span>
      <Combobox
        geselecteerd={props.data}
        opties={[
          {
            id: 'NIEUW',
            label: 'Nieuw',
          },
          {
            id: 'GEBRUIKT',
            label: 'Gebruikt',
          },
        ]}
        onSelectieChange={(x) => {
          props.onDataChange(x!);
          props.setIsActief(true);
          props.toepassen();
        }}
      />
    </div>
  );
};

export default ProductstaatFilter;
