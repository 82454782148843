import { create } from 'zustand';
import api from '../../../../../../../api';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../../../../models/IRemoteData';
import { IOphalenContractWeergaveResult } from '../../../../../../../../../shared/src/api/v2/relatie/contract';
import { IFilterData, maakFilterSchema } from '../../../../../../../components/FilterBalkV2';
import _ from 'lodash';
import { EFilter } from '../../../../../../../stores/klantkaart/entiteiten/contracten/overzicht/KEContractenOverzichtStore';

export interface IOverzichtV2Data {
  relID: number;
  result: IOphalenContractWeergaveResult;
}

export interface IInitialiseerParams {
  relID: number;
  urlState: IContractOverzichtV2UrlState;
}

export interface IInitialiseerResult {
  urlState: IContractOverzichtV2UrlState;
}

export interface IOphalenContractWeergaveParams {
  relID: number;
  abortSignal?: AbortSignal;
}

export const defaultFilterData = [
  // {
  //   naam: EFilter.AlleenActieveContracten,
  //   data: null,
  //   isActief: true,
  // },
  {
    naam: EFilter.LOC_IDS,
    data: [],
    isActief: false,
  },
  {
    naam: EFilter.INTERNE_LOCATIE_NAMEN,
    data: [],
    isActief: false,
  },
  {
    naam: EFilter.STATUS_NAAMENUM,
    data: [],
    isActief: false,
  },
  {
    naam: EFilter.IS_ACTUEEL,
    data: true,
    isActief: false,
  },
  {
    naam: EFilter.ZOEKTERM,
    data: '',
    isActief: false,
  },
  {
    naam: EFilter.LOCATIE_VERDIEPINGEN,
    data: [],
    isActief: false,
  },
  {
    naam: EFilter.REL_PERS_IDS,
    data: [],
    isActief: false,
  },
  {
    naam: EFilter.FACTUURKENMERK,
    data: [],
    isActief: false,
  },
  {
    naam: EFilter.COMPLEX,
    data: [],
    isActief: false,
  },
];

export interface IContractOverzichtV2UrlState {
  filterData?: IFilterData<EFilter>[];
  selectieCntIDs?: number[];
  uitgeklapteLocIDs?: number[];
}

export const defaultUrlState: IContractOverzichtV2UrlState = {
  filterData: defaultFilterData,
  selectieCntIDs: [],
  uitgeklapteLocIDs: [],
};

export type SectieId = 'details' | 'product' | 'transport' | 'service' | 'financieel' | 'tarieven';

export interface IContractTegelState {
  uitgeklapteSectieId: SectieId | null;
}

export interface IOverzichtV2Store {
  isBezig: boolean;
  scopeBezigheid: (fn: () => Promise<void>) => Promise<void>;
  initRelID: number | null;
  initialiseer: (params: IInitialiseerParams) => IInitialiseerResult;
  ophalenContractWeergave: (params: IOphalenContractWeergaveParams) => Promise<void>;
  verversenContractWeergave: () => Promise<void>;
  data: IRemoteData<IOverzichtV2Data>;
  uitgeklapteLocIDs: number[];
  setUitgeklaptLocIDs: (dispatch: (uitgeklapt: number[]) => number[]) => void;
  selectieCntIDs: number[];
  setSelectieCntIDs: (dispatch: (selectie: number[]) => number[]) => void;
  filterData: IFilterData<EFilter>[];
  setFilterData: (dispatch: (filterData: IFilterData<EFilter>[]) => IFilterData<EFilter>[]) => void;
  contractTegelState: { [cntID: number]: IContractTegelState };
  setContractTegelState: (
    dispatch: (state: {
      [cntID: number]: IContractTegelState;
    }) => { [cntID: number]: IContractTegelState },
  ) => void;
  setContractTegelStateItem: (
    cntID: number,
    dispatch: (state: IContractTegelState | null) => IContractTegelState,
  ) => void;
}

export const useOverzichtV2Store = create<IOverzichtV2Store>((set, get) => ({
  isBezig: false,
  scopeBezigheid: async (fn) => {
    const isAlBezig = get().isBezig;
    if (!isAlBezig) {
      set({ isBezig: true });
    }

    try {
      await fn();
    } finally {
      if (!isAlBezig) {
        set({ isBezig: false });
      }
    }
  },
  initRelID: null,
  initialiseer: (params) => {
    const huidigInitRelID = get().initRelID;

    if (huidigInitRelID === params.relID) {
      if (JSON.stringify(params.urlState) === JSON.stringify(defaultUrlState)) {
        return {
          urlState: {
            filterData: get().filterData,
            selectieCntIDs: get().selectieCntIDs,
            uitgeklapteLocIDs: get().uitgeklapteLocIDs,
          },
        };
      }

      set({
        filterData: params.urlState.filterData ?? defaultFilterData,
        selectieCntIDs: params.urlState.selectieCntIDs ?? [],
        uitgeklapteLocIDs: params.urlState.uitgeklapteLocIDs ?? [],
      });
      return {
        urlState: params.urlState,
      };
    }

    set({
      initRelID: params.relID,
      filterData: params.urlState.filterData ?? defaultFilterData,
      selectieCntIDs: params.urlState.selectieCntIDs ?? [],
      uitgeklapteLocIDs: params.urlState.uitgeklapteLocIDs ?? [],
    });

    return {
      urlState: params.urlState,
    };
  },
  ophalenContractWeergave: async (params) => {
    const curr = get();
    const scopeBezigheid = curr.scopeBezigheid;
    // const isVerversen =
    //   curr.data.state === ERemoteDataState.Ready && params.relID === curr.data.data!.relID;

    await scopeBezigheid(async () => {
      const filterSchema = maakFilterSchema(get().filterData);

      const result = await api.v2.relatie.contract.ophalenContractWeergave(
        { relID: params.relID, filterSchema },
        params.abortSignal,
      );
      const data: IOverzichtV2Data = {
        relID: params.relID,
        result,
      };

      const update: Partial<IOverzichtV2Store> = {};
      let huidigeUitgeklapteLocIDs = get().uitgeklapteLocIDs;
      if (huidigeUitgeklapteLocIDs.length > 0) {
        const nieuweUitgeklapteLocIDs = huidigeUitgeklapteLocIDs.filter((locID: number) => {
          return result.contracten.some(
            (cnt) => cnt.LocID === locID && result.cntIDsVoorWeergave.includes(cnt.CntID),
          );
        });
        update.uitgeklapteLocIDs = nieuweUitgeklapteLocIDs;
        huidigeUitgeklapteLocIDs = nieuweUitgeklapteLocIDs;
      }
      if (
        // !isVerversen &&
        huidigeUitgeklapteLocIDs.length === 0 &&
        result.cntIDsVoorWeergave.length <= 10
      ) {
        // Automatisch locaties uitklappen als het minder of gelijk is aan 10 contracten
        // en er nog geen locaties zijn uitgeklapt
        update.uitgeklapteLocIDs = _.uniq(
          result.contracten
            .map((contract) => {
              const isInWeergave = result.cntIDsVoorWeergave.includes(contract.CntID);
              if (!isInWeergave) {
                return null;
              }
              return contract.LocID;
            })
            .filter((x) => x !== null),
        ) as number[];
      }
      update.data = createReadyRemoteData(data);

      // Controleer of nieuwe selectie nog in weergave zit
      const huidigeSelectieCntIDs = get().selectieCntIDs;
      if (huidigeSelectieCntIDs.length > 0) {
        const nieuweSelectieCntIDs = huidigeSelectieCntIDs.filter((cntID: number) =>
          result.cntIDsVoorWeergave.includes(cntID),
        );
        if (nieuweSelectieCntIDs.length !== huidigeSelectieCntIDs.length) {
          update.selectieCntIDs = nieuweSelectieCntIDs;
        }
      }

      set(update);
    });
  },
  verversenContractWeergave: async () => {
    const curr = get();
    const data = curr.data;
    if (data.state === ERemoteDataState.Pending) {
      return;
    }
    const ophalenContractWeergave = curr.ophalenContractWeergave;
    await ophalenContractWeergave({
      relID: data.data!.relID,
    });
  },
  data: createPendingRemoteData(),
  uitgeklapteLocIDs: [],
  setUitgeklaptLocIDs: (dispatch) =>
    set((state: IOverzichtV2Store) => ({
      uitgeklapteLocIDs: dispatch(state.uitgeklapteLocIDs),
    })),
  selectieCntIDs: [],
  setSelectieCntIDs: (dispatch) =>
    set((state: IOverzichtV2Store) => ({ selectieCntIDs: dispatch(state.selectieCntIDs) })),
  filterData: defaultFilterData,
  setFilterData: (dispatch) =>
    set((state: IOverzichtV2Store) => ({ filterData: dispatch(state.filterData) })),
  contractTegelState: {},
  setContractTegelState: (dispatch) =>
    set((state: IOverzichtV2Store) => ({
      contractTegelState: dispatch(state.contractTegelState),
    })),
  setContractTegelStateItem: (cntID, dispatch) =>
    set((state: IOverzichtV2Store) => ({
      contractTegelState: {
        ...state.contractTegelState,
        [cntID]: dispatch(state.contractTegelState[cntID] ?? null),
      },
    })),
}));
