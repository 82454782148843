import React, { useEffect, useMemo, useState } from 'react';
import Combobox, { IOptie } from '../Combobox';
import { IOphalenGeslachtenResult } from '../../../../../shared/src/api/v2/geslacht';
import api from '../../../api';
import LoadingSpinner from '../../Gedeeld/LoadingSpinner';

interface IProps {
  geslacht: number | null;
  onChange: (geslachtID: number | null) => void;
  isWisbaar?: boolean;
}

const GeslachtSelectie: React.FC<IProps> = (props) => {
  const { children, geslacht, onChange } = props;
  const [geslachten, setGeslachten] = useState<IOphalenGeslachtenResult | null>(null);

  useEffect(() => {
    (async () => {
      const result = await api.v2.geslacht.ophalen();
      setGeslachten(result);
    })();
  }, []);

  const opties = useMemo<Array<IOptie<number>> | null>(() => {
    if (geslachten === null) {
      return null;
    }

    return geslachten.map((geslacht) => ({
      id: geslacht.Geslacht,
      label: geslacht.Naam,
    }));
  }, [geslachten]);

  if (opties === null) {
    return <LoadingSpinner />;
  }
  return (
    <Combobox<number>
      geselecteerd={geslacht}
      onSelectieChange={onChange}
      opties={opties}
      options={{ legeOptieTekst: 'Kies' }}
      isWisbaar={props.isWisbaar}
    />
  );
};

export default GeslachtSelectie;
