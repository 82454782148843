import React, { useContext, useEffect, useMemo } from 'react';
import api from '../../../../api';
import { observer } from 'mobx-react-lite';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Kleur as EKleur } from '../../../../bedrijfslogica/constanten';
import { EResultType } from '../../../../stores/CheckStore';
import { RootStoreContext } from '../../../../stores/RootStore';
import { IOphalenOpdrachtregelsResultElementV2 } from '../../../../../../shared/src/api/v2/transport/opdracht';

interface IRegelRow extends IOphalenOpdrachtregelsResultElementV2 {}

interface IProps extends RouteComponentProps {
  rij: IRegelRow;
}

const NieuwOfGebruiktComp: React.FC<IProps> = observer((props) => {
  const { checkStore } = useContext(RootStoreContext);

  const rij: IRegelRow = props.rij;

  return (
    <span>
      {rij.type !== null ? 'Nwe' : rij.product !== null ? (rij.product.Nieuw ? 'Nwe' : 'Gbr') : ''}
    </span>
  );
});

export default withRouter(NieuwOfGebruiktComp);
