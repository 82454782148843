import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import api from '../../../../../api';
import LoadingSpinner from '../../../../Gedeeld/LoadingSpinner';
import { observer } from 'mobx-react-lite';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import useUrlState from '../../../../../core/useUrlState';
import {
  IOphalenBatchesResultElement,
  IOphalenOpdrachtenResultElement,
} from '../../../../../../../shared/src/api/v2/bank/opdracht/opdracht';
import { RootStoreContext } from '../../../../../stores/RootStore';
import {
  DXTableCheckboxComponent,
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
  DXTableToggleCellComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../../helpers/dxTableGrid';
import { Kleur } from '../../../../../bedrijfslogica/constanten';
import {
  Grid,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  DataTypeProvider,
  EditingState,
  RowDetailState,
  SelectionState,
  VirtualTable as VirtualTableBase,
} from '@devexpress/dx-react-grid';
import { format } from 'date-fns';
import FormatteerBedrag from '../../../../MutatieBedrag';
import { EResultType } from '../../../../../stores/CheckStore';
import { IconInformatie, IconSend, IconToevoegen, IconVerwijderen } from '../../../../Icons';
import { bepaalStatus, statusNaam } from '../../../../../paginas/Bankzaken/Bankopdrachten';
import NieuwOpdrachtDialoog from '../../../../../paginas/Bankzaken/Bankopdrachten/Opdrachten/NieuwOpdrachtDialoog';
import * as _ from 'lodash';
import RowDetailFacturen from '../../../../bankopdracht/RowDetailFacturen';
import WijzigOpdrachtDialoog from '../../../../../paginas/Bankzaken/Bankopdrachten/Opdrachten/WijzigOpdrachtDialoog';
import BankopdrachtInfoDialoog from '../../../../bankopdracht/BankopdrachtInfoDialoog';
import FilterBalkV2, { IFilter, IFilterData, maakFilterSchema } from '../../../../FilterBalkV2';
import { IFilterSchemaFilter } from '../../../../../../../shared/src/models/filter';
import MenuLayout from '../../../../MenuLayout';

interface IProps extends RouteComponentProps {
  relID: number;
}

export enum EFilter {
  // HeeftSignaleringen = 'HEEFT_SIGNALERINGEN',
  AlleenNietUitgevoerd = 'IS_UITGEVOERD',
}

export interface IBankzakenBankopdrachtenUrlState {
  selectie: number[];
  filterData: IFilterData<EFilter>[];
}

export const defaultBankzakenBankopdrachtenUrlState: IBankzakenBankopdrachtenUrlState = {
  selectie: [],
  filterData: [
    {
      naam: EFilter.AlleenNietUitgevoerd,
      data: false,
      isActief: false,
    },
  ],
};

export interface IWijzigenOpdrachtDialoogState {
  bankOpdID: number;
}

export interface IBankopdrachtInfoDialoogState {
  bankOpdID: number;
}

const geenData = {
  noData: 'Geen bankopdrachten gevonden',
};

const BankopdrachtenComp: React.FC<IProps> = observer((props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState<IBankzakenBankopdrachtenUrlState>(
    props,
    defaultBankzakenBankopdrachtenUrlState,
  );
  const [opdrachten, setOpdrachten] = useState<IOphalenOpdrachtenResultElement[] | null>(null);
  const { klantkaartStore, checkStore } = useContext(RootStoreContext);

  const [filterSchema, setFilterSchema] = useState(maakFilterSchema(urlState.filterData));

  const [
    bankopdrachtInfoDialoogState,
    setBankopdrachtInfoDialoogState,
  ] = useState<IBankopdrachtInfoDialoogState | null>(null);
  const [
    wijzigenOpdrachtDialoogState,
    setWijzigenOpdrachtDialoogState,
  ] = useState<IWijzigenOpdrachtDialoogState | null>(null);
  const [nieuwOpdrachtModalTonen, setNieuwOpdrachtModalTonen] = useState<boolean>(false);

  const relID = props.relID;
  // const debID = klantkaartStore.relatie!.debiteur!.DebID;

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.AlleenNietUitgevoerd,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return <span>Alleen niet uitgevoerd</span>;
        },
      },
    ],
    [],
  );

  const ophalenOpdrachten = useCallback(async () => {
    const filterSchemaBasis = {
      filters: [...filterSchema.filters!, { naam: 'REL_IDS', data: [relID] }].filter(
        (x) => x !== null,
      ) as IFilterSchemaFilter[],
    };
    const { opdrachten } = await api.v2.bank.opdracht.ophalenOpdrachten({
      // filterSchema: { filters: [{ naam: 'REL_IDS', data: [relID] }] },
      filterSchema: filterSchemaBasis,
    });

    const opdrachtenGesorteerd = _.orderBy(
      opdrachten,
      [
        // function(x: any) {
        //   const statusBatch = bepaalStatus(x.batch);
        //   const sort =
        //     x.batch === null
        //       ? 9
        //       : statusBatch === Status.Uitgevoerd
        //       ? 0
        //       : statusBatch === Status.Goedgekeurd
        //       ? 1
        //       : statusBatch === Status.NogGoedkeuren
        //       ? 2
        //       : 3;
        //   return sort;
        // },
        (x: any) =>
          x.Uitvoerdatum !== null ? format(new Date(x.Uitvoerdatum), 'yyyy-MM-dd') : '2100-01-01',
        'Uitvoerdatum',
        'BankOpdID',
      ],
      ['desc', 'desc', 'desc'],
    );

    setOpdrachten(opdrachtenGesorteerd);
  }, [relID, filterSchema.filters!]);

  useEffect(() => {
    ophalenOpdrachten();
  }, [ophalenOpdrachten]);

  const handleVerwijderen = useCallback(async () => {
    const checkData = await api.v2.bank.opdracht.checkVerwijderenOpdrachten({
      opdIDs: urlState.selectie,
    });
    if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
      return;
    }
    if (
      (
        await checkStore.bevestigen({
          inhoud:
            urlState.selectie.length === 1
              ? 'Wil je deze bankopdracht verwijderen?'
              : `Wil je de ${urlState.selectie.length} bankopdrachten verwijderen?`,
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    await api.v2.bank.opdracht.verwijderenOpdrachten({
      opdIDs: urlState.selectie,
    });

    setUrlStateSync('selectie', []);

    ophalenOpdrachten();
  }, [urlState.selectie]);

  const keyExtractor = useCallback((row: IOphalenOpdrachtenResultElement) => row.BankOpdID, []);

  const kolommen = useMemo<TypedColumn<IOphalenOpdrachtenResultElement>[]>(
    () => [
      {
        name: 'Uitvoerdatum',
        title: 'Uitvoerdatum',
      },
      {
        name: '__info' as any,
        title: 'Info',
      },
      {
        name: 'Bedrag',
        title: 'Bedrag',
      },
      {
        name: '__soortOpdracht' as any,
        title: 'Srt.',
      },
      {
        name: 'Omschrijving',
        title: 'Omschrijving',
      },
      // {
      //   name: 'IBAN',
      //   title: 'IBAN',
      // },
      {
        name: '__status' as any,
        title: 'Status',
      },
      // {
      //   name: '__signaleringen' as any,
      //   title: 'Sign.',
      // },
      // {
      //   name: '__uitgevoerdOp' as any,
      //   title: 'Uitgevoerd',
      // },
      {
        name: '__stornodatum' as any,
        title: 'Gestorneerd',
      },
      {
        name: '__stornoreden' as any,
        title: 'Reden',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IOphalenOpdrachtenResultElement>[]>(
    () => [
      {
        columnName: 'Uitvoerdatum',
        width: 115,
      },
      {
        columnName: 'Bedrag',
        width: 90,
      },
      {
        columnName: '__soortOpdracht' as any,
        width: 55,
      },
      {
        columnName: 'Omschrijving',
        width: 250,
      },
      // {
      //   columnName: 'IBAN',
      //   width: 200,
      // },
      {
        columnName: '__status' as any,
        width: 135,
      },
      {
        columnName: '__signaleringen' as any,
        width: 70,
      },
      // {
      //   columnName: '__uitgevoerdOp',
      //   width: 115,
      // },
      {
        columnName: '__stornodatum' as any,
        width: 115,
      },
      {
        columnName: '__stornoreden',
        width: 250,
      },
      {
        columnName: '__info' as any,
        width: 85,
      },
    ],
    [],
  );

  const kolomExtensies: VirtualTableBase.ColumnExtension[] = useMemo(() => {
    return [
      // { columnName: 'Bedrag', align: 'right' }
    ];
  }, []);

  const handleVerversenAangevraagd = useCallback(async () => {
    await ophalenOpdrachten();
  }, [ophalenOpdrachten]);

  const RowDetailComponent = useCallback(
    (props: TableRowDetail.ContentProps) => (
      <RowDetailFacturen {...props} onVerversenAangevraagd={handleVerversenAangevraagd} />
    ),
    [handleVerversenAangevraagd],
  );

  return (
    <>
      <MenuLayout
        menu={
          <>
            <div className="d-flex align-items-center">
              <button
                className="btn btn-sm btn-light d-flex align-items-center"
                style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                onClick={() => setNieuwOpdrachtModalTonen(true)}
              >
                <IconToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                <span className="ml-2">Nieuwe bankopdracht</span>
              </button>

              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-3"
                style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                disabled={urlState.selectie.length === 0}
                onClick={() => handleVerwijderen()}
              >
                <IconVerwijderen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                <span className="ml-2">Verwijderen bankopdracht</span>
              </button>

              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-3"
                style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                disabled={urlState.selectie.length === 0}
                onClick={async () => {
                  const checkData = await api.v2.bank.opdracht.checkVersturenBetaalspecificaties({
                    bankOpdIDs: urlState.selectie,
                  });
                  if (
                    (await checkStore.controleren({ checkData })).type === EResultType.Annuleren
                  ) {
                    return;
                  }

                  if (
                    (
                      await checkStore.bevestigen({
                        inhoud: (
                          <span>
                            Een betaalspecificatie versturen voor de geselecteerde bankopdrachten?
                          </span>
                        ),
                      })
                    ).type === EResultType.Annuleren
                  ) {
                    return;
                  }

                  await api.v2.bank.opdracht.versturenBetaalspecificaties({
                    bankOpdIDs: urlState.selectie,
                  });
                }}
              >
                <IconSend style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                <span className="ml-2">Betaalspecificatie versturen</span>
              </button>

              <div className="flex-fill ml-3">
                <FilterBalkV2
                  filters={filters}
                  filterData={urlState.filterData}
                  onFilterDataChange={(x) => setUrlStateSync('filterData', x)}
                  onFilterSchemaChange={setFilterSchema}
                />
              </div>
            </div>
          </>
        }
        body={
          opdrachten === null ? (
            <div className="flex-fill d-flex align-items-center justify-content-center p-4">
              <LoadingSpinner />
            </div>
          ) : (
            <>
              <GridStyleWrapper height="calc(100vh - 150px)">
                <Grid columns={kolommen} getRowId={keyExtractor} rows={opdrachten!}>
                  <DataTypeProvider
                    for={['__status']}
                    formatterComponent={(props) => {
                      const batch =
                        props.row.batch !== null
                          ? (props.row.batch as IOphalenBatchesResultElement)
                          : null;

                      const status = bepaalStatus(batch!);
                      const statusText = statusNaam[status];
                      return <span>{statusText}</span>;
                    }}
                  />

                  <DataTypeProvider
                    for={['Bedrag']}
                    formatterComponent={(props) => <FormatteerBedrag bedrag={props.value} />}
                  />

                  <DataTypeProvider
                    for={['__soortOpdracht']}
                    formatterComponent={(props) => {
                      return <span>{props.row.Bedrag > 0 ? 'Inc' : 'Bet'}</span>;
                    }}
                  />

                  <DataTypeProvider
                    for={['Uitvoerdatum']}
                    formatterComponent={(props) => {
                      const row: IOphalenOpdrachtenResultElement = props.row;

                      // if (row.batch !== null) {
                      //   if (row.batch.UitgevoerdOp !== null) {
                      //     return <span>{format(new Date(row.batch.UitgevoerdOp), 'dd-MM-yyyy')}</span>;
                      //   }
                      //   return <span>{format(new Date(row.batch.Uitvoerdatum!), 'dd-MM-yyyy')}</span>;
                      // }

                      const datum =
                        props.value !== null
                          ? format(new Date(props.value), 'dd-MM-yyyy')
                          : 'Z.s.m.';
                      return <span>{datum}</span>;
                    }}
                  />

                  <DataTypeProvider
                    for={['__stornodatum']}
                    formatterComponent={(props) => {
                      const rij: IOphalenOpdrachtenResultElement = props.row;

                      const datum =
                        rij.stornomutatie !== null
                          ? format(new Date(rij.stornomutatie.Mutatiedatum), 'dd-MM-yyyy')
                          : '';

                      return <span>{datum}</span>;
                    }}
                  />

                  <DataTypeProvider
                    for={['__stornoreden']}
                    formatterComponent={(props) => {
                      const rij: IOphalenOpdrachtenResultElement = props.row;
                      if (rij.stornomutatie === null || rij.stornomutatie.stornoreden === null) {
                        return <span></span>;
                      }
                      const reden = rij.stornomutatie.stornoreden!;

                      return (
                        <span>
                          {reden.Code} {reden.Naam}
                        </span>
                      );
                    }}
                  />

                  <DataTypeProvider
                    for={['__info']}
                    formatterComponent={(props) => {
                      return (
                        <>
                          <a
                            href="#"
                            className="ml-1"
                            style={{ color: Kleur.LichtBlauw, position: 'relative', bottom: 2 }}
                            onClick={() => {
                              setBankopdrachtInfoDialoogState({ bankOpdID: props.row.BankOpdID });
                            }}
                          >
                            <IconInformatie style={{ width: 15, height: 15, fill: Kleur.Blauw }} />
                          </a>
                        </>
                      );
                    }}
                  />

                  <VirtualTable messages={geenData} columnExtensions={kolomExtensies} />

                  <RowDetailState defaultExpandedRowIds={[]} />

                  <EditingState
                    onAddedRowsChange={() => {
                      alert('Plus');
                    }}
                    onCommitChanges={() => null}
                    onEditingRowIdsChange={(rowIds) => {
                      const id = rowIds[rowIds.length - 1] as number;
                      setWijzigenOpdrachtDialoogState({ bankOpdID: id });
                    }}
                  />
                  <TableEditColumn
                    width={35}
                    // showAddCommand={true}
                    showEditCommand
                    cellComponent={DXTableEditColumnCellComponent}
                    commandComponent={DXTableEditColumnCommandComponent}
                  />

                  <SelectionState
                    selection={urlState.selectie}
                    onSelectionChange={(value) => setUrlStateSync('selectie', value as number[])}
                  />
                  <TableRowDetail
                    contentComponent={RowDetailComponent}
                    toggleCellComponent={DXTableToggleCellComponent}
                  />
                  <TableSelection cellComponent={DXTableCheckboxComponent} />

                  <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
                  <TableHeaderRow />
                </Grid>
              </GridStyleWrapper>

              {nieuwOpdrachtModalTonen && (
                <NieuwOpdrachtDialoog
                  relID={relID}
                  open={nieuwOpdrachtModalTonen}
                  onSuccess={() => {
                    ophalenOpdrachten();
                    setNieuwOpdrachtModalTonen(false);
                  }}
                  onAnnuleren={() => {
                    setNieuwOpdrachtModalTonen(false);
                  }}
                />
              )}

              {wijzigenOpdrachtDialoogState !== null && (
                <WijzigOpdrachtDialoog
                  open={true}
                  bankOpdID={wijzigenOpdrachtDialoogState.bankOpdID}
                  onSuccess={() => {
                    ophalenOpdrachten();
                    setWijzigenOpdrachtDialoogState(null);
                  }}
                  onAnnuleren={() => setWijzigenOpdrachtDialoogState(null)}
                />
              )}

              {bankopdrachtInfoDialoogState !== null && (
                <BankopdrachtInfoDialoog
                  open
                  bankOpdID={bankopdrachtInfoDialoogState.bankOpdID}
                  onSuccess={() => {
                    setBankopdrachtInfoDialoogState(null);
                  }}
                  onAnnuleren={() => setBankopdrachtInfoDialoogState(null)}
                />
              )}
            </>
          )
        }
      />
    </>
  );
});

const Bankopdrachten = withRouter(BankopdrachtenComp);
export default Bankopdrachten;
