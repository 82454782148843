import {
  IAnnulerenOpdrachtregelsParams,
  IAnnulerenOpdrachtregelsResult,
  IHerstellenAfmeldenOpdrachtenParams,
  IHerstellenAfmeldenOpdrachtenResult,
  IVersturenAnnuleringsberichtTransporteurParams,
  IVersturenAnnuleringsberichtTransporteurResult,
  IMarkerenOpdrachtenAfgehandeldParams,
  IMarkerenOpdrachtenAfgehandeldResult,
  IMarkerenOpdrachtenPlanningParams,
  IMarkerenOpdrachtenPlanningResult,
  IHerstellenAnnulerenOpdrachtregelsParams,
  IHerstellenAnnulerenOpdrachtregelsResult,
  ICheckReferentiecodesBijAfmeldenParams,
} from '../../../../../../shared/src/api/v2/transport/opdracht/uitstaand';
import ICheckData from '../../../../../../shared/src/models/ICheckData';
import api from '../../../index';

const uitstaand = {
  checkAnnulerenOpdrachtregels: async (
    params: IAnnulerenOpdrachtregelsParams,
  ): Promise<ICheckData> => {
    return await api.post('v2/transport/opdracht/uitstaand/check-annuleren-opdrachtregels', params);
  },
  annulerenOpdrachtregels: async (
    params: IAnnulerenOpdrachtregelsParams,
  ): Promise<IAnnulerenOpdrachtregelsResult> => {
    return await api.post('v2/transport/opdracht/uitstaand/annuleren-opdrachtregels', params);
  },
  checkHerstellenAnnulerenOpdrachtregels: async (
    params: IHerstellenAnnulerenOpdrachtregelsParams,
  ): Promise<ICheckData> => {
    return await api.post(
      'v2/transport/opdracht/uitstaand/check-herstellen-annuleren-opdrachtregels',
      params,
    );
  },
  herstellenAnnulerenOpdrachtregels: async (
    params: IHerstellenAnnulerenOpdrachtregelsParams,
  ): Promise<IHerstellenAnnulerenOpdrachtregelsResult> => {
    return await api.post(
      'v2/transport/opdracht/uitstaand/herstellen-annuleren-opdrachtregels',
      params,
    );
  },
  herstellenAfmeldenOpdrachten: async (
    params: IHerstellenAfmeldenOpdrachtenParams,
  ): Promise<IHerstellenAfmeldenOpdrachtenResult> => {
    return await api.post('v2/transport/opdracht/uitstaand/herstellen-afmelden-opdrachten', params);
  },
  versturenAnnuleringsberichtTransporteur: async (
    params: IVersturenAnnuleringsberichtTransporteurParams,
  ): Promise<IVersturenAnnuleringsberichtTransporteurResult> => {
    return await api.post(
      'v2/transport/opdracht/uitstaand/versturen-annuleringsbericht-transporteur',
      params,
    );
  },
  checkMarkerenOpdrachtenAfgehandeld: async (
    params: IMarkerenOpdrachtenAfgehandeldParams,
  ): Promise<ICheckData> => {
    return await api.post(
      'v2/transport/opdracht/uitstaand/check-markeren-opdracht-afgehandeld',
      params,
    );
  },
  markerenOpdrachtenAfgehandeld: async (
    params: IMarkerenOpdrachtenAfgehandeldParams,
  ): Promise<IMarkerenOpdrachtenAfgehandeldResult> => {
    return await api.post('v2/transport/opdracht/uitstaand/markeren-opdracht-afgehandeld', params);
  },
  checkMarkerenOpdrachtenPlanning: async (
    params: IMarkerenOpdrachtenPlanningParams,
  ): Promise<ICheckData> => {
    return await api.post(
      'v2/transport/opdracht/uitstaand/check-markeren-opdracht-planning',
      params,
    );
  },
  markerenOpdrachtenPlanning: async (
    params: IMarkerenOpdrachtenPlanningParams,
  ): Promise<IMarkerenOpdrachtenPlanningResult> => {
    return await api.post('v2/transport/opdracht/uitstaand/markeren-opdracht-planning', params);
  },
  checkReferentiecodesBijAfmelden: async (
    params: ICheckReferentiecodesBijAfmeldenParams,
  ): Promise<ICheckData> => {
    return await api.post(
      'v2/transport/opdracht/uitstaand/check-referentiecodes-bij-afmelden',
      params,
    );
  },
};

export default uitstaand;
