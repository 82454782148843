import { makeObservable, observable } from 'mobx';
import IRemoteData, { createPendingRemoteData, createReadyRemoteData } from '../models/IRemoteData';
import { IOphalenOpdrachtenResultElement } from '../../../shared/src/api/v2/transport/opdracht';
import * as _ from 'lodash';
import api from '../api';

class TransportopdrachtStore {
  public transportopdrachten: IRemoteData<
    Record<number, IOphalenOpdrachtenResultElement>
  > = createPendingRemoteData();
  private ophalenTransportopdrachtenQueue: number[] = [];
  private ophalenTransportopdrachtenQueueDebounceHandle: number | null = 0;

  constructor() {
    makeObservable(this, {
      transportopdrachten: observable,
    });
  }

  public enqueueOphalenTransportopdrachten = (trsOpdIDs: number[]) => {
    if (this.ophalenTransportopdrachtenQueueDebounceHandle !== null) {
      clearTimeout(this.ophalenTransportopdrachtenQueueDebounceHandle);
    }
    this.ophalenTransportopdrachtenQueue = _.uniq([
      ...this.ophalenTransportopdrachtenQueue,
      ...trsOpdIDs,
    ]);
    this.ophalenTransportopdrachtenQueueDebounceHandle = setTimeout(
      this.ophalenQueuedTransportopdrachten,
      100,
    ) as any;
  };
  private ophalenQueuedTransportopdrachten = async () => {
    this.ophalenTransportopdrachtenQueueDebounceHandle = null;
    const trsOpdIDs = this.ophalenTransportopdrachtenQueue;

    if (trsOpdIDs.length === 0) {
      return;
    }

    this.ophalenTransportopdrachtenQueue = [];

    const result = await api.v2.transport.opdracht.ophalenOpdrachten({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: trsOpdIDs,
          },
        ],
      },
    });
    this.transportopdrachten = createReadyRemoteData(
      result.opdrachten.reduce((acc, curr) => {
        return {
          ...acc,
          [curr.TrsOpdID]: curr,
        };
      }, this.transportopdrachten.data ?? {}),
    );
  };
}

export default TransportopdrachtStore;
