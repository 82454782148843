import React from 'react';
import styled from 'styled-components';
import { IMergeDialoogVeldFormatterProps } from '../../../types';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

interface IProps<TData> {
  data: TData;
  andereDatas: TData[];
  formatter: React.ComponentType<IMergeDialoogVeldFormatterProps<TData>>;
  height: number;
}

const EntiteitVeld = <TData extends any>(props: IProps<TData>) => {
  const Formatter = props.formatter;

  return (
    <Root style={{ height: props.height }}>
      <Formatter data={props.data} andereDatas={props.andereDatas} />
    </Root>
  );
};

export default EntiteitVeld;
