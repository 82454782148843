import React, { useContext, useEffect, useMemo, useState, useCallback } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import api from '../../../api';
import { IOphalenActieclaimsResultElement } from '../../../../../shared/src/api/v2/actie/klantWerftKlant';
import ActieclaimTabel from '../../../components/kwk/actieclaimTabel';
import useUrlState from '../../../core/useUrlState';
import _ from 'lodash';
import { IOphalenContractenResultElementV2 } from '../../../../../shared/src/api/v2/contract';
import { Helmet } from 'react-helmet';

interface IProps extends RouteComponentProps {}

interface IUrlState {
  actieclaimsSelectie: number[];
  regelsSelectie: number[];
}

const defaultUrlState: IUrlState = {
  actieclaimsSelectie: [],
  regelsSelectie: [],
};

export interface IRegel extends IOphalenActieclaimsResultElement {
  contracten: IOphalenContractenResultElementV2[];
}

const KlantWerftKlant: React.FC<IProps> = observer((props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);

  const [actieclaims, setActieclaims] = useState<IRegel[] | null>(null);

  const ophalenActieclaims = useCallback(async () => {
    const actieclaimsResult = (
      await api.v2.klantactie.klantWerftKlant.ophalenActieclaims({
        filterSchema: {
          filters: [],
        },
      })
    ).actieclaims;

    const cntIDs = _.flatten(actieclaimsResult.map((x) => x.cntIDs));

    const contractenResult = (
      await api.v2.contract.ophalenContractenV2({
        filterSchema: {
          filters: [{ naam: 'IDS', data: cntIDs }],
        },
      })
    ).contracten;

    // {
    //   const selectie = selectieCheck(
    //     opdrachten.map((x) => x.TrsOpdID),
    //     urlState.opdrachtenSelectie,
    //   );
    //   if (selectie !== null) {
    //     setUrlStateSync('opdrachtenSelectie', selectie);
    //   }
    // }
    // {
    //   const selectie = selectieCheck(
    //     opdrachten.map((x) => x.regels.map((x) => x.TrsRegID)).flat(),
    //     urlState.regelsSelectie,
    //   );
    //   if (selectie !== null) {
    //     setUrlStateSync('regelsSelectie', selectie);
    //   }
    // }

    const actieclaims = actieclaimsResult.map((x) => {
      const contracten = contractenResult.filter(
        (contract) => x.cntIDs.indexOf(contract.CntID) !== -1,
      );
      return { ...x, contracten };
    });

    setActieclaims(actieclaims);
  }, []);

  useEffect(() => {
    ophalenActieclaims();
  }, [ophalenActieclaims]);

  return (
    <>
      <Helmet>
        <title>KwK Actieclaims</title>
      </Helmet>
      <ActieclaimTabel
        actieclaims={actieclaims}
        actieclaimsSelectie={urlState.actieclaimsSelectie}
        onActieclaimsSelectieChange={(value: number[]) =>
          setUrlStateSync('actieclaimsSelectie', value)
        }
        regelsSelectie={[]}
        onRegelsSelectieChange={(value: number[]) => setUrlStateSync('regelsSelectie', value)}
        onRequestRefresh={() => ophalenActieclaims()}
      />
    </>
  );
});

export default withRouter(KlantWerftKlant);
