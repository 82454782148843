import {
  IAfhandelenControlTakenParams,
  IAfhandelenControlTakenResult,
  IOphalenControltaakToewijzingenParams,
  IOphalenControltaakToewijzingenResult,
  IOphalenControltaakTypesParams,
  IOphalenControltaakTypesResult,
  IOphalenControlTakenParams,
  IOphalenControlTakenResult,
  IMuterenControltaakToewijzingenParams,
  IMuterenControltaakToewijzingenResult,
  IToevoegenHandmatigEventParams,
  IToevoegenHandmatigEventResult,
  IVerwijderenTakenParams,
  IVerwijderenTakenResult,
  IWijzigenTaakParams,
  IWijzigenTaakResult,
  ISelectieWijzigenTaakParams,
} from '../../../../shared/src/api/v2/control/control';
import ICheckData from '../../../../shared/src/models/ICheckData';
import api from '../index';

const control = {
  ophalenControlTaken: async (
    params: IOphalenControlTakenParams,
  ): Promise<IOphalenControlTakenResult> => {
    return await api.post('/v2/control/ophalen-control-taken', params);
  },
  afhandelenControlTaken: async (
    params: IAfhandelenControlTakenParams,
  ): Promise<IAfhandelenControlTakenResult> => {
    return await api.post('/v2/control/afhandelen-control-taken', params);
  },
  herstelAfhandelenControlTaken: async (
    params: IAfhandelenControlTakenParams,
  ): Promise<IAfhandelenControlTakenResult> => {
    return await api.post('/v2/control/herstel-afhandelen-control-taken', params);
  },
  ophalenControltaakTypes: async (
    params: IOphalenControltaakTypesParams,
  ): Promise<IOphalenControltaakTypesResult> => {
    return await api.post('/v2/control/ophalen-controltaak-types', params);
  },
  ophalenControltaakToewijzingen: async (
    params: IOphalenControltaakToewijzingenParams,
  ): Promise<IOphalenControltaakToewijzingenResult> => {
    return await api.post('/v2/control/ophalen-controltaak-toewijzingen', params);
  },
  muterenControltaakToewijzingen: async (
    params: IMuterenControltaakToewijzingenParams,
  ): Promise<IMuterenControltaakToewijzingenResult> => {
    return await api.post('/v2/control/muteren-controltaak-toewijzingen', params);
  },
  toevoegenHandmatigEvent: async (
    params: IToevoegenHandmatigEventParams,
  ): Promise<IToevoegenHandmatigEventResult> => {
    return await api.post('/v2/control/toevoegen-handmatig-event', params);
  },
  checkVerwijderenTaken: async (params: IVerwijderenTakenParams): Promise<ICheckData> => {
    return await api.post('/v2/control/check-verwijderen-taken', params);
  },
  verwijderenTaken: async (params: IVerwijderenTakenParams): Promise<IVerwijderenTakenResult> => {
    return await api.post('/v2/control/verwijderen-taken', params);
  },
  checkSelectieWijzigenTaak: async (params: ISelectieWijzigenTaakParams): Promise<ICheckData> => {
    return await api.post('/v2/control/check-selectie-wijzigen-taak', params);
  },
  wijzigenTaak: async (params: IWijzigenTaakParams): Promise<IWijzigenTaakResult> => {
    return await api.post('/v2/control/wijzigen-taak', params);
  },
};

export default control;
