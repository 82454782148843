import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { INavigatieItem, NavigatieItemKey, parentNavigatieItemBijKey } from '../../shared';
import { IconChevronRight } from '../../../../components/Icons';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import NavigatiePad from './NavigatiePad';
import Highlighted from './Highlighted';

interface IRootProps {}

const Root = styled.button<IRootProps>`
  padding: 10px 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  border: none;
  background-color: #fff;
  width: 100%;
  text-align: left;
  color: ${Kleur.BeetjeDonkerGrijs};

  &:hover {
    background-color: #f5f5f5;
  }

  &:focus {
    outline: none;
    background-color: ${Kleur.DoorschijnendLichtBlauw};
  }
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

const Pad = styled.div`
  padding-top: 3px;
  padding-left: 30px;
`;

const Naam = styled.span`
  font-size: 17px;
  flex: 1;
`;

interface IProps {
  navigatieItem: INavigatieItem;
  termen: string[];
  onClick: () => void;
}

const NavigatieItem = (props: IProps) => {
  const { key, icoon } = props.navigatieItem;

  const Icoon = icoon;

  const padNavigatieItems = useMemo(() => {
    const fn = (k: NavigatieItemKey, acc: INavigatieItem[]): INavigatieItem[] => {
      const navItem = parentNavigatieItemBijKey[k];
      if (navItem === undefined) {
        return acc;
      }
      acc.unshift(navItem);
      return fn(navItem.key, acc);
    };

    return fn(key, []);
  }, [key]);

  return (
    <Root onClick={props.onClick}>
      <Main>
        <Item>
          {Icoon !== undefined && <Icoon hoogte={21} breedte={21} kleur={Kleur.Grijs} />}
          <Naam style={{ marginLeft: Icoon !== undefined ? 10 : 0 }}>
            <Highlighted tekst={props.navigatieItem.naam} termen={props.termen} />
          </Naam>
        </Item>
        {padNavigatieItems.length > 0 && (
          <Pad>
            <NavigatiePad navigatieItems={padNavigatieItems} termen={props.termen} />
          </Pad>
        )}
      </Main>
      <IconChevronRight style={{ width: 21, height: 21, fill: Kleur.Grijs }} />
    </Root>
  );
};

export default NavigatieItem;
