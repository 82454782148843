import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import IDialoogProps from '../../../../../core/IDialoogProps';
import Dialoog from '../../../../../components/dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { Field, FieldProps, Formik, FormikActions, FormikErrors, FormikProps } from 'formik';
import * as Yup from 'yup';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../../../stores/RootStore';
import nameOf from '../../../../../core/nameOf';
import api from '../../../../../api';
import LoadingSpinner from '../../../../../components/Gedeeld/LoadingSpinner';
import { EResultType } from '../../../../../stores/CheckStore';
import ITaalTekst from '../../../../../../../shared/src/models/talen/ITaalTekst';
import MeertaligTekstveld from '../../../../../components/formulier/MeertaligTekstveld';
import teksten from '../../../../../bedrijfslogica/teksten';
import MultiSelect from '../../../../../components/formulier/MultiSelect';
import { IOphalenFaqGroepResultElement } from '../../../../../../../shared/src/api/v2/faqGroep';
import { IOphalenTekstenResultElement } from '../../../../../../../shared/src/api/v2/tekst';

interface IDialoogResult {}

interface IProps extends IDialoogProps<IDialoogResult> {}

interface IFormikValues {
  vraag: ITaalTekst[];
  antwoord: ITaalTekst[];
}

const veldnamen: Record<keyof IFormikValues, string> = {
  vraag: 'Vraag',
  antwoord: 'Antwoord',
};

const ToevoegenDialoog: React.FC<IProps> = observer((props) => {
  const { children, dialoogIndex, onAnnuleren, onSuccess } = props;
  const { checkStore } = useContext(RootStoreContext);
  const formikRef = useRef<Formik<IFormikValues>>(null);

  const [faqGroepen, setFaqGroepen] = useState<IOphalenFaqGroepResultElement[] | null>(null);
  const [taalTeksten, setTaalTeksten] = useState<IOphalenTekstenResultElement[] | null>(null);

  const ophalenfaqGroepen = useCallback(async () => {
    const result = await api.v2.faqGroep.ophalenFaqGroepen({});
    console.log(result.faqGroepen[0]);

    setFaqGroepen(result.faqGroepen);
  }, []);

  useEffect(() => {
    ophalenfaqGroepen();
  }, []);

  const ophalenTeksten = useCallback(async () => {
    if (faqGroepen === null) {
      return;
    }
    const tekstIDs = [...faqGroepen.map((x) => x.Naam_TekstID).filter((x) => x !== null)];

    const resultTeksten = await api.v2.tekst.ophalenTekstenInAlleTalen({
      tekstIDs,
    });

    setTaalTeksten(resultTeksten.teksten);
  }, [faqGroepen]);

  useEffect(() => {
    ophalenTeksten();
  }, [ophalenTeksten]);

  const ophalenGroepOpties = useCallback(() => {
    if (taalTeksten === null) return [];
    console.log('ophalen-tekst');
    const result =
      faqGroepen == null
        ? []
        : faqGroepen.map((groep) => {
            const naamTekst = taalTeksten.find((x) => x.TekstID === groep.Naam_TekstID);
            return {
              key: groep.ID,
              weergave: naamTekst == null || naamTekst.Tekst == null ? '' : naamTekst.Tekst,
            };
          });
    return result;
  }, [taalTeksten]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      const params = { Vraag: values.vraag, Antwoord: values.antwoord };

      const checkData = await api.v2.faq.checkToevoegenFaq({});
      const checkResult = await checkStore.controleren({
        checkData,
      });
      if (checkResult.type === EResultType.Annuleren) {
        onAnnuleren();
        return;
      }

      await api.v2.faq.toevoegenFaq(params);

      props.onSuccess({});
      actions.setSubmitting(false);
    },
    [],
  );

  const initialValues = useMemo<IFormikValues>(() => {
    return {
      antwoord: [],
      vraag: [],
      groepen: [],
    };
  }, []);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        ID: Yup.string().required(),
      }),
    [],
  );

  const validate = useCallback((values: IFormikValues) => {
    const errors: FormikErrors<IFormikValues> = {};
    if (values.vraag.length === 0) {
      errors.vraag = teksten.formulier.E_VERPLICHT_VELD({ veldnaam: 'Vraag' }) as any;
    }
    if (values.antwoord.length === 0) {
      errors.antwoord = teksten.formulier.E_VERPLICHT_VELD({ veldnaam: 'Antwoord' }) as any;
    }
    return errors;
  }, []);

  return (
    <Dialoog index={dialoogIndex || 0} modalProps={{ size: 'lg' }}>
      <ModalHeader>
        <ModalTitle>Toevoegen faq</ModalTitle>
      </ModalHeader>
      {initialValues !== null && (
        <Formik<IFormikValues>
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validate={validate}
          enableReinitialize
          isInitialValid
          render={(formikProps: FormikProps<IFormikValues>) => {
            const { submitForm, isValid, isSubmitting } = formikProps;

            return (
              <>
                <ModalBody>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-12 mt-3">
                        <label>Vraag</label>
                        <Field
                          name={nameOf<IFormikValues>('vraag')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            return (
                              <MeertaligTekstveld
                                waarden={fieldProps.field.value}
                                onChange={(x) =>
                                  fieldProps.form.setFieldValue(fieldProps.field.name, x)
                                }
                              />
                            );
                          }}
                        />
                      </div>
                      <div className="col-12 mt-3">
                        <label>Antwoord</label>
                        <Field
                          name={nameOf<IFormikValues>('antwoord')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            return (
                              <MeertaligTekstveld
                                waarden={fieldProps.field.value}
                                onChange={(x) =>
                                  fieldProps.form.setFieldValue(fieldProps.field.name, x)
                                }
                              />
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </ModalBody>

                <ModalFooter className="d-flex flex-row justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    style={{ width: 100 }}
                    disabled={!isValid || isSubmitting}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={onAnnuleren}
                    style={{ width: 100 }}
                    disabled={isSubmitting}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Dialoog>
  );
});

export default ToevoegenDialoog;
