import React from 'react';
import { formatDistance } from 'date-fns';
import { nl as datefns_nl_locale } from 'date-fns/locale';
import { Lijn, Root, TijdContainer } from './style';

interface IProps {
  datum: Date;
}

const DagScheider = (props: IProps) => {
  const content = formatDistance(props.datum, new Date(), {
    addSuffix: true,
    locale: datefns_nl_locale,
  });

  return (
    <Root>
      <Lijn />
      <TijdContainer>{content}</TijdContainer>
      <Lijn />
    </Root>
  );
};

export default DagScheider;
