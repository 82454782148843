import React, { useMemo } from 'react';
import Tabblad, { ITabblad } from '../../../components/layout/Tabblad';
import { matchPath, Redirect, Route, RouteComponentProps } from 'react-router-dom';
import { Root } from '../../Inkoop/Aanbod/style';
import Verhuur from './Verhuur';
import Acties from './Acties';
import Verkoop from './Verkoop';
import Overige from './Overige';

export enum ETabblad {
  Verhuur = 1,
  Acties = 2,
  Verkoop = 3,
  Overige = 4,
}

const urlTabbladMap: Record<ETabblad, string> = {
  [ETabblad.Verhuur]: 'verhuur',
  [ETabblad.Acties]: 'acties',
  [ETabblad.Verkoop]: 'verkoop',
  [ETabblad.Overige]: 'overige',
};

interface IProps extends RouteComponentProps {}

const Tarieven: React.FC<IProps> = (props) => {
  const tabbladen = useMemo<ITabblad<ETabblad>[]>(
    () => [
      {
        id: ETabblad.Verhuur,
        label: 'Verhuur',
        content: Verhuur,
      },
      {
        id: ETabblad.Acties,
        label: 'Acties',
        content: Acties,
      },
      {
        id: ETabblad.Verkoop,
        label: 'Verkoop',
        content: Verkoop,
      },
      {
        id: ETabblad.Overige,
        label: 'Overige',
        content: Overige,
      },
    ],
    [],
  );

  const tabblad = useMemo<ETabblad | null>(() => {
    const paths = Object.keys(urlTabbladMap).map((key) => {
      const tabblad = Number(key) as ETabblad;
      const url = urlTabbladMap[tabblad];
      return {
        tabblad,
        path: `${props.match.path}/${url}`,
      };
    });
    for (const pathObj of paths) {
      const match = matchPath(props.location.pathname, pathObj.path);
      if (match !== null) {
        return pathObj.tabblad;
      }
    }

    return null;
  }, [props.location.pathname]);

  return (
    <>
      <Route
        exact
        path={props.match.path}
        component={() => <Redirect to={`${props.match.path}/${urlTabbladMap[ETabblad.Verhuur]}`} />}
      />
      {tabblad === null ? (
        <Redirect to={`${props.match.path}/${urlTabbladMap[ETabblad.Verhuur]}`} />
      ) : (
        <Root>
          <Tabblad
            geselecteerd={tabblad}
            onSelectieChange={(identifier) =>
              props.history.push(`${props.match.path}/${urlTabbladMap[identifier]}`)
            }
            tabbladen={tabbladen}
          />
        </Root>
      )}
    </>
  );
};

export default Tarieven;
