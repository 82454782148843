import api from '../index';
import {
  IDeelsWijzigenInstellingenParams,
  IDeelsWijzigenInstellingenResult,
  IOphalenInstellingenParams,
  IOphalenInstellingenResult,
  IWijzigenInstellingenParams,
  IWijzigenInstellingenResult,
} from '../../../../shared/src/api/v2/instelling';

const instelling = {
  ophalenInstellingen: async (params: IOphalenInstellingenParams) => {
    return await api.post<IOphalenInstellingenResult>('v2/instelling/ophalen-instellingen');
  },
  wijzigenInstellingen: async (params: IWijzigenInstellingenParams) => {
    return await api.post<IWijzigenInstellingenResult>(
      'v2/instelling/wijzigen-instellingen',
      params,
    );
  },
  deelsWijzigenInstellingen: async (
    params: IDeelsWijzigenInstellingenParams,
  ): Promise<IDeelsWijzigenInstellingenResult> => {
    return await api.post<IDeelsWijzigenInstellingenResult>(
      'v2/instelling/deels-wijzigen-instellingen',
      params,
    );
  },
};

export default instelling;
