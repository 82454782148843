import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import api from '../../../../../../../api';
import LoadingSpinner from '../../../../../../../components/Gedeeld/LoadingSpinner';
import { observer } from 'mobx-react-lite';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import useUrlState from '../../../../../../../core/useUrlState';
import { RootStoreContext } from '../../../../../../../stores/RootStore';
import MenuLayout from '../../../../../../../components/MenuLayout';
import { format } from 'date-fns';
import { IOphalenLeveropdrachtenResultElement } from '../../../../../../../../../shared/src/api/v2/opvolging/leveropdracht';
import {
  ASPKolom,
  EAspKolomBreedteType,
} from '../../../../../../../components/tabel/ASPTabel/types';
import TransportopdrachtregelVisualisatie from '../../../../../../../components/entiteitVisualisaties/TransportopdrachtregelVisualisatie';
import ASPTabel from '../../../../../../../components/tabel/ASPTabel';
import WijzigenDialoog from '../../../../../../Kwaliteitsbeheer/Leveringen/WijzigenDialoog';
import _ from 'lodash';

interface IProps extends RouteComponentProps {
  relID: number;
}

enum ETabelKolom {
  Registratiedatum,
  IsAfgehandeld,
  NietOpvolgen,
  Notities,
  RecordToegevoegd,
  Transportopdrachtregels,
  Bezoekdatums,
}

export interface IToevoegenDialoogState {}

interface IWijzigenDialoogState {
  id: number;
}

interface IUrlState {
  selectie: number[];
  toevoegenDialoogState: IToevoegenDialoogState | null;
  wijzigDialoogState: IWijzigenDialoogState | null;
}

const defaultUrlState: IUrlState = {
  selectie: [],
  toevoegenDialoogState: null,
  wijzigDialoogState: null,
};

const geenData = {
  noData: 'Er zijn geen opvolgingen gevonden',
};

const LeveropdrachtenComp: React.FC<IProps> = observer((props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState<IUrlState>(props, defaultUrlState);
  const { checkStore } = useContext(RootStoreContext);

  const [opvolgingen, setOpvolgingen] = useState<IOphalenLeveropdrachtenResultElement[] | null>(
    null,
  );

  const ophalenOpvolgingen = useCallback(async () => {
    const result = await api.v2.opvolging.leveropdracht.ophalenOpvolgingen({
      filterSchema: {
        filters: [{ naam: 'REL_IDS', data: [props.relID] }],
      },
    });

    setOpvolgingen(result.opvolgingen);
  }, []);

  useEffect(() => {
    ophalenOpvolgingen();
  }, [ophalenOpvolgingen]);

  const keyExtractor = useCallback((rij: IOphalenLeveropdrachtenResultElement) => rij.ID, []);

  const tabelKolommen = useMemo<Array<ASPKolom<ETabelKolom, IOphalenLeveropdrachtenResultElement>>>(
    () => [
      {
        key: ETabelKolom.Registratiedatum,
        label: 'Vastgelegd op',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 145,
        renderer: (rij) => format(new Date(rij.Registratiedatum), 'dd-MM-yyyy HH:mm'),
      },
      {
        key: ETabelKolom.Bezoekdatums,
        label: 'Bezoekdatums',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 200,
        renderer: (rij) => {
          const opdrachten = rij.regels.map((x) => x.opdracht);

          const bezoekdatums: Date[] = _.uniq(
            opdrachten.map((x: any) => (x.Bezoekdatum !== null ? new Date(x.Bezoekdatum) : null)),
          );
          const datums = bezoekdatums
            .map((x) => (x !== null ? format(x, 'dd-MM-yyyy') : 'Onbekend'))
            .join(', ');

          return <span>{datums}</span>;
        },
      },
      {
        key: ETabelKolom.Transportopdrachtregels,
        label: 'Opdrachtregels',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 200,
        renderer: (rij) => {
          return (
            <span className="d-flex">
              {rij.regels.map((x) => (
                <span className="mr-3">
                  {/* <TransportopdrachtregelVisualisatie trsRegID={x.Trs} /> */}
                </span>
              ))}
            </span>
          );
        },
      },
      {
        key: ETabelKolom.NietOpvolgen,
        label: 'Opvolgen',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 90,
        renderer: (rij) => (rij.NietOpvolgen ? 'Nee' : 'Ja'),
      },
      {
        key: ETabelKolom.IsAfgehandeld,
        label: 'Afgehandeld',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 90,
        renderer: (rij) => (rij.IsAfgehandeld ? 'Ja' : 'Nee'),
      },
      {
        key: ETabelKolom.Notities,
        label: 'Notities bij opvolging',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 400,
        renderer: (rij) => rij.Notities,
      },
    ],
    [],
  );

  const handleWijzigenRij = useCallback(async (rij: IOphalenLeveropdrachtenResultElement) => {
    setUrlStateSync('wijzigDialoogState', {
      id: rij.ID,
    });
  }, []);

  return (
    <>
      <MenuLayout
        menu={<div className="d-flex"></div>}
        body={
          <div className="d-flex flex-column flex-fill">
            {opvolgingen === null ? (
              <div className="flex-fill d-flex align-items-center justify-content-center">
                <LoadingSpinner />
              </div>
            ) : (
              <div className="d-flex flex-column flex-fill">
                <ASPTabel
                  rijen={opvolgingen}
                  kolommen={tabelKolommen}
                  keyExtractor={keyExtractor}
                  onWijzigenRij={handleWijzigenRij}
                />
              </div>
            )}
          </div>
        }
      />
      {urlState.wijzigDialoogState !== null && (
        <WijzigenDialoog
          open
          id={urlState.wijzigDialoogState.id}
          onSuccess={() => {
            ophalenOpvolgingen();
            setUrlStateSync('wijzigDialoogState', null);
          }}
          onAnnuleren={() => [setUrlStateSync('wijzigDialoogState', null)]}
        />
      )}
    </>
  );
});

export default withRouter(LeveropdrachtenComp);
