import React, { useCallback, useRef, useState } from 'react';
import { IContactData } from '../index';
import styled from 'styled-components';
import { IconChevronRight, IconContact } from '../../../../../../../Icons';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { Kleur } from '../../../../../../../../bedrijfslogica/constanten';
import InfoCard from './InfoCard';

const Root = styled.div`
  padding: 10px 10px 10px 15px;
  background-color: rgba(150, 150, 150, 0.1);
  border-radius: 4px;
  margin: 5px 5px 0;
  border: 1px solid rgba(200, 200, 200, 0.3);
  transition: background-color 0.2s;

  :hover {
    background-color: rgba(150, 150, 150, 0.2);
  }

  .name {
    font-size: 14px;
    font-weight: bold;
  }
`;

interface IProps {
  data: IContactData;
}

const ContactItem = (props: IProps) => {
  const { data } = props;
  const { name } = data;

  const [infoCardOverlayHoverOpen, setInfoCardOverlayHoverOpen] = useState(false);
  const showInfoCardOverlayTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const handleMouseEnterInfoCardOverlay = useCallback(() => {
    if (showInfoCardOverlayTimeoutRef.current !== null) {
      clearTimeout(showInfoCardOverlayTimeoutRef.current);
    }
    showInfoCardOverlayTimeoutRef.current = setTimeout(
      () => setInfoCardOverlayHoverOpen(true),
      800,
    );
  }, []);
  const handleMouseLeaveInfoCardOverlay = useCallback(() => {
    if (showInfoCardOverlayTimeoutRef.current !== null) {
      clearTimeout(showInfoCardOverlayTimeoutRef.current);
    }
    showInfoCardOverlayTimeoutRef.current = setTimeout(
      () => setInfoCardOverlayHoverOpen(false),
      400,
    );
  }, []);
  const renderInfoCardOverlay = useCallback(
    (p: any) => {
      return (
        <div
          id={`contactitem-overlay-${props.data.name.formatted_name}`}
          {...p}
          onMouseEnter={handleMouseEnterInfoCardOverlay}
          onMouseLeave={handleMouseLeaveInfoCardOverlay}
          style={{
            ...p.style,
            zIndex: 99999,
            border: `1px solid ${Kleur.Persoon}`,
            borderRadius: 5,
          }}
        >
          <InfoCard data={data} />
        </div>
      );
    },
    [data, handleMouseEnterInfoCardOverlay, handleMouseLeaveInfoCardOverlay],
  );

  return (
    <OverlayTrigger
      trigger="hover"
      placement="auto-start"
      overlay={renderInfoCardOverlay}
      show={infoCardOverlayHoverOpen}
      popperConfig={
        {
          modifiers: {
            name: 'offset',
            options: {
              offset: [0, 5],
            },
          },
        } as any
      }
    >
      <Root
        onMouseEnter={handleMouseEnterInfoCardOverlay}
        onMouseLeave={handleMouseLeaveInfoCardOverlay}
      >
        <div className="d-flex align-items-center flex-fill">
          <IconContact style={{ fill: Kleur.Grijs, width: 18, height: 18 }} />
          <span className="name ml-3 mr-3">{name.formatted_name}</span>
          <IconChevronRight style={{ fill: Kleur.Grijs, width: 21, height: 21 }} />
        </div>
      </Root>
    </OverlayTrigger>
  );
};

export default ContactItem;
