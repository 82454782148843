import React, {
  HTMLProps,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { OverlayLink, Root } from './style';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { RootStoreContext } from '../../../stores/RootStore';
import {
  IOphalenPersonenResultElementV2,
  IOphalenRelatiesVanPersoonResult,
  IPersoonAttributen,
} from '../../../../../shared/src/api/v2/persoon/persoon';
import { formatteerPersoonNaam, formatteerRelatieNaam } from '../../../helpers';
import { observer } from 'mobx-react-lite';
import LoadingSpinner from '../../Gedeeld/LoadingSpinner';
import api from '../../../api';
import Popover from 'react-bootstrap/Popover';
import Overlay from 'react-bootstrap/Overlay';
import { Kleur } from '../../../bedrijfslogica/constanten';
import { v4 as uuid } from 'uuid';
import { IconChristelijkKruis, IconOpenInNew, IconWarning } from '../../Icons';
import InfoCard from './InfoCard';
import { OverlayTrigger } from 'react-bootstrap';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../models/IRemoteData';
import RelatieVisualisatie from '../RelatieVisualisatie';

interface IOverlayState {
  openenInNieuwTabblad: boolean;
}

export interface IOptions {
  geenLinkToepassen: boolean;
}

const defaultOptions: IOptions = {
  geenLinkToepassen: false,
};

export interface IProps extends RouteComponentProps {
  persID: number;
  relID?: number;
  persoon?: IOphalenPersonenResultElementV2;
  options?: IOptions;
  naamComponent?: React.ComponentType<HTMLProps<HTMLSpanElement>>;
  rootStyle?: React.CSSProperties;
  linkStyle?: React.CSSProperties;
}

const PersoonVisualisatie: React.FC<IProps> = observer((props) => {
  const options: IOptions = useMemo(() => ({ ...defaultOptions, ...props.options }), [
    props.options,
  ]);
  const { persoonStore } = useContext(RootStoreContext);
  const persoon = useMemo<IOphalenPersonenResultElementV2 | null>(() => {
    if (props.persoon !== undefined) {
      return props.persoon;
    }
    if (props.persID === null) {
      return null;
    }
    const foundPersoon = persoonStore.personen[props.persID];
    if (foundPersoon === undefined) {
      return null;
    }
    return foundPersoon;
  }, [persoonStore.personen, props.persoon, props.persID]);
  const attributen = useMemo<IRemoteData<IPersoonAttributen>>(() => {
    const item = persoonStore.persoonAttributen[props.persID];
    if (item === undefined) {
      return createPendingRemoteData();
    }
    return createReadyRemoteData(item);
  }, [props.persID, persoonStore.persoonAttributen]);

  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (props.persID === null) {
      return;
    }
    if (persoon !== null) {
      return;
    }
    persoonStore.enqueuePersoonOphalen(props.persID);
  }, [props.persID]);

  const isAanHetOphalen = useMemo(() => {
    return persoonStore.personenOphaalQueue.findIndex((id) => id === props.persID) !== -1;
  }, [persoonStore.personenOphaalQueue, props.persID]);

  const geformatteerdeNaam = useMemo(() => {
    if (persoon === null) {
      return null;
    }

    return formatteerPersoonNaam({
      voornaam: persoon.Voornaam,
      achternaam: persoon.Achternaam,
      aanhefKort: persoon.geslacht.AanhefKort,
      voorletters: persoon.Voorletters,
      voorvoegsel: persoon.Voorvoegsel,
    });
  }, [persoon]);

  const overlayLinkRef = useRef<HTMLSpanElement>(null);
  const overlayId = useMemo(() => uuid(), []);
  const [overlayState, setOverlayState] = useState<IOverlayState | null>(null);
  const [overlayRelaties, setOverlayRelaties] = useState<IOphalenRelatiesVanPersoonResult | null>(
    null,
  );

  const gaNaarRelatie = useCallback((relID: number) => {
    props.history.push(`/klant/${relID}`);
  }, []);

  const naam = useMemo(() => {
    if (props.naamComponent !== undefined) {
      return <props.naamComponent>{geformatteerdeNaam}</props.naamComponent>;
    }
    return <span>{geformatteerdeNaam}</span>;
  }, [geformatteerdeNaam, props.naamComponent]);

  const link = useMemo(() => {
    if (overlayRelaties === null) {
      return null;
    }

    return overlayRelaties!.relaties.map((relatie) => (
      <div
        key={relatie.RelID}
        className="list-group-item list-group-item-action d-flex align-items-center"
        style={{ cursor: 'pointer' }}
        onClick={() => {
          const link = `/klant/${relatie.RelID}`;
          if (overlayState!.openenInNieuwTabblad) {
            window.open(link!, '_blank');
            return;
          }
          props.history.push(link);
          setOverlayState(null);
        }}
      >
        <span className="flex-fill">
          <RelatieVisualisatie
            relID={relatie.RelID}
            weergaveNaam={formatteerRelatieNaam({
              voornaam: relatie.Voornaam,
              achternaam: relatie.Achternaam,
              voorletters: relatie.Voorletters,
              aanhefKort: relatie.AanhefKort,
              voorvoegsel: relatie.Voorvoegsel,
              relatiesoort: relatie.RelatieSoort,
              organisatienaam: relatie.Organisatienaam,
            })}
            onGenavigeerd={() => {
              setOverlayState(null);
            }}
          />
        </span>
        {relatie.IsRelatie && (
          <span
            style={{ fontWeight: 'bold', color: Kleur.Blauw, fontSize: 14 }}
            title="Is gemarkeerd als relatie"
          >
            R
          </span>
        )}
      </div>
    ));
  }, [overlayRelaties, overlayState, setOverlayState]);

  const [infoCardOverlayHoverOpen, setInfoCardOverlayHoverOpen] = useState(false);
  const showInfoCardOverlayTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const handleMouseEnterInfoCardOverlay = useCallback(() => {
    if (showInfoCardOverlayTimeoutRef.current !== null) {
      clearTimeout(showInfoCardOverlayTimeoutRef.current);
    }
    showInfoCardOverlayTimeoutRef.current = setTimeout(
      () => setInfoCardOverlayHoverOpen(true),
      800,
    );
  }, []);
  const handleMouseLeaveInfoCardOverlay = useCallback(() => {
    if (showInfoCardOverlayTimeoutRef.current !== null) {
      clearTimeout(showInfoCardOverlayTimeoutRef.current);
    }
    showInfoCardOverlayTimeoutRef.current = setTimeout(
      () => setInfoCardOverlayHoverOpen(false),
      400,
    );
  }, []);
  const renderInfoCardOverlay = useCallback(
    (p: any) => {
      return (
        <div
          id={`relatie-visualisatie-overlay-${props.persID}`}
          {...p}
          onMouseEnter={handleMouseEnterInfoCardOverlay}
          onMouseLeave={handleMouseLeaveInfoCardOverlay}
          style={{
            ...p.style,
            zIndex: 99999,
            border: `1px solid ${Kleur.Persoon}`,
            borderRadius: 5,
          }}
        >
          <InfoCard persID={props.persID} />
        </div>
      );
    },
    [props.persID, handleMouseEnterInfoCardOverlay, handleMouseLeaveInfoCardOverlay],
  );

  const toonOverlay = useCallback(
    async (openenInNieuwTabblad: boolean) => {
      setInfoCardOverlayHoverOpen(false);
      if (showInfoCardOverlayTimeoutRef.current) {
        clearTimeout(showInfoCardOverlayTimeoutRef.current);
        showInfoCardOverlayTimeoutRef.current = null;
      }
      if (props.relID !== undefined) {
        gaNaarRelatie(props.relID);
        return;
      }
      setOverlayState({
        openenInNieuwTabblad,
      });
      const result = await api.v2.persoon.ophalenRelatiesVanPersoon({
        persID: props.persID!,
      });
      if (result.relaties.length === 1) {
        if (openenInNieuwTabblad) {
          const link = `/klant/${result.relaties[0].RelID}`;
          window.open(link, '_blank');
        } else {
          gaNaarRelatie(result.relaties[0].RelID);
        }
        setOverlayState(null);
        return;
      }
      setOverlayRelaties(result);
    },
    [overlayState, setOverlayState, props.persID, props.relID, setInfoCardOverlayHoverOpen],
  );

  const infoCardOpen = infoCardOverlayHoverOpen && overlayState === null;

  return (
    <OverlayTrigger
      trigger="hover"
      placement="auto-start"
      overlay={renderInfoCardOverlay}
      show={infoCardOpen}
      popperConfig={
        {
          modifiers: {
            name: 'offset',
            options: {
              offset: [0, 5],
            },
          },
        } as any
      }
    >
      <Root
        style={props.rootStyle}
        onMouseEnter={() => {
          setIsHovered(true);
          handleMouseEnterInfoCardOverlay();
        }}
        onMouseLeave={() => {
          setIsHovered(false);
          handleMouseLeaveInfoCardOverlay();
        }}
      >
        {isAanHetOphalen ? (
          <div className="ongekoppeld-container">Persoon ophalen...</div>
        ) : (
          <div className="gekoppeld-container" style={{ minWidth: 0 }}>
            {options.geenLinkToepassen ? (
              naam
            ) : (
              <div className="d-flex align-items-center" style={{ minWidth: 0 }}>
                <OverlayLink
                  ref={overlayLinkRef}
                  onClick={async (ev) => {
                    ev.stopPropagation();

                    await toonOverlay(false);
                  }}
                  style={{
                    color: Kleur.Persoon,
                    display: 'flex',
                    alignItems: 'center',
                    textDecoration:
                      persoon !== null && persoon.Vervangende_PersID !== null
                        ? 'line-through'
                        : undefined,
                    minWidth: 0,
                    ...props.linkStyle,
                  }}
                >
                  {naam}
                </OverlayLink>
                {attributen.state === ERemoteDataState.Ready &&
                  attributen.data!.heeftDuplicaatSuggestie && (
                    <div
                      style={{
                        marginLeft: 5,
                        position: 'relative',
                        top: -1,
                      }}
                    >
                      <IconWarning
                        style={{
                          fill: Kleur.LichtGeel,
                          width: 16,
                          height: 16,
                        }}
                      />
                    </div>
                  )}
                {persoon !== null && persoon.Overleden && (
                  <div
                    style={{
                      marginLeft: 5,
                      position: 'relative',
                      top: -1,
                    }}
                  >
                    <IconChristelijkKruis style={{ fill: Kleur.Grijs, width: 14, height: 14 }} />
                  </div>
                )}
                {isHovered && (
                  <IconOpenInNew
                    style={{
                      width: 16,
                      height: 16,
                      marginLeft: 5,
                      marginTop: 3,
                      cursor: 'pointer',
                      fill: Kleur.Grijs,
                      position: 'relative',
                      top: -1,
                    }}
                    onClick={async (ev) => {
                      ev.stopPropagation();
                      await toonOverlay(true);
                    }}
                  />
                )}
              </div>
            )}
            <div className="flex-fill" />
            <Overlay
              target={overlayLinkRef.current!}
              placement="auto"
              rootClose
              show={overlayState !== null}
              rootCloseEvent="click"
              onHide={() => setOverlayState(null)}
            >
              {(p: any) => (
                <Popover id={overlayId} {...p}>
                  {overlayRelaties === null ? (
                    <LoadingSpinner />
                  ) : overlayRelaties!.relaties.length === 0 ? (
                    <span className="p-2">Er zijn geen relaties gekoppeld aan dit persoon.</span>
                  ) : (
                    <div>
                      <div className="p-3">
                        <p className="m-0">Er zijn meerdere relaties gekoppeld aan dit persoon.</p>
                        <p className="m-0">Kies een relatie uit de lijst.</p>
                      </div>
                      <div className="list-group list-group-flush">{link}</div>
                    </div>
                  )}
                </Popover>
              )}
            </Overlay>
          </div>
        )}
      </Root>
    </OverlayTrigger>
  );
});

export default withRouter(PersoonVisualisatie);
