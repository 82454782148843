import { TableRowDetail } from '@devexpress/dx-react-grid-bootstrap4';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IOphalenMutatiesResultElement } from '../../../../../../../../shared/src/api/v2/bank/mutaties';
import TabelInspringBlok from '../../../../../../components/layout/TabelInspringBlok';
import { TableRowDetailWrapper } from '../../../../../../helpers/dxTableGrid';
import GegevensLayout from '../../../../../../components/layout/GegevensLayout';
import VerticaleScheidingslijn from '../../../../../../components/layout/VerticaleScheidingslijn';

const DetailComp = (props: TableRowDetail.ContentProps) => {
  const row: IOphalenMutatiesResultElement = props.row;

  return (
    <TableRowDetailWrapper>
      <div className="row d-flex">
        <TabelInspringBlok />
        <div className="d-flex p-3">
          <div style={{ width: 300 }}>
            <GegevensLayout
              gegevens={[
                {
                  label: 'Transactiecode',
                  waarde: row.Transactiecode !== null ? row.Transactiecode : 'Niet opgegeven',
                },
                {
                  label: 'Batchreferentie',
                  waarde: row.BatchReferentie,
                },
                {
                  label: 'AantalTransactiesInBatch',
                  waarde:
                    row.AantalTransactiesInBatch !== null
                      ? row.AantalTransactiesInBatch.toString()
                      : '',
                },
                {
                  label: 'Stornocode',
                  waarde: row.Returncode,
                },
              ]}
            />
          </div>

          <VerticaleScheidingslijn className="ml-3 mr-3" height={80} />

          <div style={{ width: 900 }}>
            <GegevensLayout
              gegevens={[
                {
                  label: 'Omschrijving',
                  waarde: row.Omschrijving,
                },
                {
                  label: 'Betalingskenmerk',
                  waarde: row.Betalingskenmerk,
                },
                {
                  label: 'IBAN',
                  waarde: row.IBAN,
                },
                {
                  label: 'Rekeningnaam',
                  waarde: row.Rekeningnaam,
                },
              ]}
            />
          </div>
        </div>
      </div>
    </TableRowDetailWrapper>
  );
};

export default DetailComp;
