import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import LoadingSpinner from '../../../../../../../../components/Gedeeld/LoadingSpinner';
import {
  DXTableCheckboxComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../../../../../helpers/dxTableGrid';
import {
  Grid,
  Table,
  TableColumnResizing,
  TableHeaderRow,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  DataTypeProvider,
  RowDetailState,
  SelectionState,
  TableRowDetail,
} from '@devexpress/dx-react-grid';
import TabelInspringBlok from '../../../../../../../../components/layout/TabelInspringBlok';
import { IDocument } from '../../../../../../../../../../shared/src/api/v2/relatie/acceptatie';
import nameof from '../../../../../../../../core/nameOf';
import useUrlState from '../../../../../../../../core/useUrlState';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import MenuLayout from '../../../../../../../../components/MenuLayout';
import { Kleur as EKleur } from '../../../../../../../../bedrijfslogica/constanten';
import api from '../../../../../../../../api';
import { Row } from 'react-bootstrap';
import { IContext, ProcedureContext } from '..';
import { RootStoreContext } from '../../../../../../../../stores/RootStore';
import { EResultType } from '../../../../../../../../stores/CheckStore';
import { IconToevoegen, IconVerwijderen } from '../../../../../../../../components/Icons';
import ToevoegenDialoog from './ToevoegenDialoog';
import { EKoppelenProductResultType } from '../../../../../../../Transport/Opdrachten/reservering/KoppelenProductDialoog';

interface IProps extends TableRowDetail.ContentProps, RouteComponentProps {}

const urlStateKey = 'procedure';

interface IToevoegenDocumentenDialoogState {
  aprProcID: number;
}

export interface IUrlState {
  selectie: number[];
  uitgeklapt: number[];
  toevoegenDocumentenDialoogState: IToevoegenDocumentenDialoogState | null;
}

const defaultUrlState: IUrlState = {
  selectie: [],
  uitgeklapt: [],
  toevoegenDocumentenDialoogState: null,
};

export interface IRegel extends IDocument {}

const DetailComp: React.FC<IProps> = (props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState, urlStateKey);
  const { checkStore } = useContext(RootStoreContext);

  const { onVerversenAangevraagd } = useContext<IContext>(ProcedureContext);

  const documenten: IRegel[] = props.row.documenten;

  const keyExtractor = useCallback((row: IRegel) => row.ID, []);

  const kolommen = useMemo<TypedColumn<IRegel>[]>(() => {
    return [
      {
        name: '__naam' as any,
        title: 'Naam',
      },
      {
        name: 'Toelichting',
        title: 'Toelichting',
      },
      {
        name: 'Ontvangen',
        title: 'Ontvangen',
      },
    ];
  }, []);

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRegel>[]>(() => {
    return [
      {
        columnName: '__naam' as any,
        width: 190,
      },
      {
        columnName: 'Toelichting',
        width: 475,
      },
      {
        columnName: 'Ontvangen',
        width: 100,
      },
    ];
  }, []);

  return (
    <>
      <div className="d-flex">
        <TabelInspringBlok />
        <MenuLayout
          menu={
            <>
              <div className="mt-2 d-flex align-items-center">
                <button
                  className="btn btn-sm btn-light"
                  style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                  // disabled={urlState.selectie.length === 0}
                  onClick={async () => {
                    setUrlStateSync('toevoegenDocumentenDialoogState', { aprProcID: props.row.ID });
                    onVerversenAangevraagd();
                  }}
                >
                  <IconToevoegen style={{ width: 16, height: 16, fill: EKleur.Grijs }} />
                  <span className="ml-2">Toevoegen</span>
                </button>

                <button
                  className="btn btn-sm btn-light ml-2"
                  style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                  disabled={urlState.selectie.length === 0}
                  onClick={async () => {
                    await api.v2.relatie.acceptatie.wijzigenStatusOntvangen({
                      aprProcID: props.row.ID,
                      aprDocIDs: urlState.selectie,
                      isOntvangen: true,
                    });
                    onVerversenAangevraagd();
                  }}
                >
                  {/* <IconToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} /> */}
                  <span className="ml-2">Markeer als Ontvangen</span>
                </button>

                <button
                  className="btn btn-sm btn-light ml-2"
                  style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                  disabled={urlState.selectie.length === 0}
                  onClick={async () => {
                    await api.v2.relatie.acceptatie.wijzigenStatusOntvangen({
                      aprProcID: props.row.ID,
                      aprDocIDs: urlState.selectie,
                      isOntvangen: false,
                    });
                    onVerversenAangevraagd();
                  }}
                >
                  {/* <IconToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} /> */}
                  <span className="ml-2">Markeer als Niet Ontvangen</span>
                </button>

                <button
                  className="btn btn-sm btn-light ml-2"
                  style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                  disabled={urlState.selectie.length === 0}
                  onClick={async () => {
                    await api.v2.relatie.acceptatie.verwijderenDocumentenUitProcedure({
                      aprProcID: props.row.ID,
                      aprDocIDs: urlState.selectie,
                    });
                    setUrlStateSync('selectie', []);
                    onVerversenAangevraagd();
                  }}
                >
                  <IconVerwijderen style={{ width: 16, height: 16, fill: EKleur.Grijs }} />
                  <span className="ml-2">Verwijderen</span>
                </button>
              </div>
            </>
          }
          body={
            <div className="d-flex">
              <GridStyleWrapper maxHeight={500} rowAmount={documenten.length}>
                <Grid getRowId={keyExtractor} rows={documenten} columns={kolommen}>
                  <DataTypeProvider
                    for={['__naam']}
                    formatterComponent={(formatterProps) => {
                      const rij: IRegel = formatterProps.row;
                      return <span>{rij.Naam}</span>;
                    }}
                  />

                  <DataTypeProvider
                    for={[nameof<IRegel>('Ontvangen')]}
                    formatterComponent={(formatterProps) => {
                      return (
                        <span style={{ color: formatterProps.value ? EKleur.Groen : EKleur.Rood }}>
                          {formatterProps.value ? 'Ja' : 'Nee'}
                        </span>
                      );
                    }}
                  />

                  <RowDetailState
                    expandedRowIds={urlState.uitgeklapt}
                    onExpandedRowIdsChange={(x) => setUrlStateSync('uitgeklapt', x as number[])}
                  />

                  <VirtualTable messages={{ noData: 'Geen documenten' }} />
                  <TableColumnResizing />
                  <TableHeaderRow />
                  <TableColumnResizing defaultColumnWidths={kolomBreedtes as any} />
                  <SelectionState
                    selection={urlState.selectie}
                    onSelectionChange={(x) => setUrlStateSync('selectie', x as number[])}
                  />
                  <TableSelection cellComponent={DXTableCheckboxComponent} />
                </Grid>
              </GridStyleWrapper>
            </div>
          }
        />
      </div>
      {urlState.toevoegenDocumentenDialoogState !== null && (
        <ToevoegenDialoog
          open
          aprProcID={props.row.ID}
          uitsluitenIDs={documenten.map((x) => x.ID)}
          onSuccess={() => {
            setUrlStateSync('selectie', []);
            onVerversenAangevraagd();
            setUrlStateSync('toevoegenDocumentenDialoogState', null);
          }}
          onAnnuleren={() => {
            setUrlStateSync('toevoegenDocumentenDialoogState', null);
          }}
        />
      )}
    </>
  );
};

export default withRouter(DetailComp);
