import React, { useContext, useMemo } from 'react';
import { IProps } from '@tinymce/tinymce-react/lib/cjs/main/ts/components/Editor';
import { IContext, BatchContext } from '../index';
import VeldWeergave from '../../../formulier/VeldWeergave';
import LoadingSpinner from '../../../Gedeeld/LoadingSpinner';
import { Grid, Table, TableHeaderRow } from '@devexpress/dx-react-grid-bootstrap4';
import GegevensLayout from '../../../layout/GegevensLayout';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import { GridStyleWrapper, TypedColumn } from '../../../../helpers/dxTableGrid';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import nameOf from '../../../../core/nameOf';
import FormatteerBedrag from '../../../MutatieBedrag';
import { format } from 'date-fns';
import { EBankopdrachtsoort } from '../../../../bedrijfslogica/enums';

const BatchTab: React.FC<IProps> = (props) => {
  const { batch, opdrachten } = useContext<IContext>(BatchContext);

  return (
    <>
      <div className="d-flex flex-column">
        <div className="row mt-3">
          <div className="col-12 d-flex">
            <div className="col-6">
              <VeldWeergave>
                <GegevensLayout
                  gegevens={[
                    {
                      label: 'Batchnummer',
                      waarde: batch!.Batchnummer.toString(),
                    },
                    {
                      label: 'Soort',
                      waarde: batch!.Soort === EBankopdrachtsoort.Betaling ? 'Betaling' : 'Incasso',
                    },
                    {
                      label: 'Bedrag',
                      waarde: <FormatteerBedrag bedrag={batch!.Bedrag} />,
                    },
                    {
                      label: 'Aantal opdrachten',
                      waarde: batch!.AantalOpdrachten.toString(),
                    },
                  ]}
                />
              </VeldWeergave>
            </div>

            <div className="col-6">
              <VeldWeergave>
                <GegevensLayout
                  gegevens={[
                    {
                      label: 'IBAN',
                      waarde: batch!.IBAN,
                    },
                    null,

                    null,
                  ]}
                />
              </VeldWeergave>
            </div>
          </div>

          <div className="col-12 d-flex mt-3">
            <div className="col-6">
              <VeldWeergave>
                <GegevensLayout
                  gegevens={[
                    {
                      label: 'Uitvoerdatum',
                      waarde: format(new Date(batch!.Uitvoerdatum), 'dd-MM-yyyy'),
                    },
                    {
                      label: 'Uiterlijk aanleveren',
                      waarde:
                        batch!.DatumAanleveren !== null
                          ? format(new Date(batch!.DatumAanleveren), 'dd-MM-yyyy')
                          : '',
                    },
                    null,

                    null,
                  ]}
                />
              </VeldWeergave>
            </div>

            <div className="col-6">
              <VeldWeergave>
                <GegevensLayout gegevens={[null, null, null, null]} />
              </VeldWeergave>
            </div>
          </div>
        </div>

        {batch!.VasteOmschrijving !== null && (
          <div className="col-12 mt-3">
            <VeldWeergave>
              <span>
                <b>Vaste omschrijving:</b>
                <br />
                {batch!.VasteOmschrijving}
              </span>
            </VeldWeergave>
          </div>
        )}
      </div>
    </>
  );
};

export default BatchTab;
