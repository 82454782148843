// import { IOphalenGebruikersResult } from '../../../../shared/src/api/v2/aspGebruiker';
import {
  IOphalenEmailAccountsParams,
  IOphalenEmailAccountsResult,
} from '../../../../shared/src/api/v2/communicatie/email';
import {
  IInstellenBezigheidParams,
  IInstellenBezigheidResult,
  IOphalenBezighedenResult,
  // IOphalenGebruikersResult,
  IOphalenStandaardGebruikerLijstRecentResult,
  IOphalenStandaardGebruikerLijstRecentParams,
  IOphalenGebruikersResult,
  IOphalenBezighedenV2Params,
  IOphalenBezighedenV2Result,
} from '../../../../shared/src/api/v2/gebruiker';
import api from '../index';

const gebruiker = {
  ophalenGebruikers: async (): Promise<IOphalenGebruikersResult> => {
    return await api.post('v2/gebruiker/ophalen-gebruikers', {});
  },
  ophalenStandaardgebruikerLijstRecent: async (
    params: IOphalenStandaardGebruikerLijstRecentParams,
  ): Promise<IOphalenStandaardGebruikerLijstRecentResult> => {
    return await api.post('v2/gebruiker/ophalen-standaardgebruiker-lijst-recent', params);
  },
  ophalenBezigheden: async (): Promise<IOphalenBezighedenResult> => {
    return await api.post('v2/gebruiker/ophalen-bezigheden', {});
  },
  ophalenBezighedenV2: async (
    params: IOphalenBezighedenV2Params,
  ): Promise<IOphalenBezighedenV2Result> => {
    return await api.post('v2/gebruiker/ophalen-bezigheden-v2', params);
  },
  ophalenEmailaccounts: async (
    params: IOphalenEmailAccountsParams,
  ): Promise<IOphalenEmailAccountsResult> => {
    return await api.post('/v2/gebruiker/ophalen-emailaccounts', params);
  },
  instellenBezigheid: async (
    data: IInstellenBezigheidParams,
  ): Promise<IInstellenBezigheidResult> => {
    return await api.post('v2/gebruiker/instellen-bezigheid', data);
  },
};

export default gebruiker;
