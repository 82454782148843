import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ITabbladProps } from '../../KoppelVoorraadProductDialoog';
import MultiCombobox from '../../../../../../components/formulier/MultiCombobox';
import { Kleur } from '../../../../../../bedrijfslogica/constanten';
import {
  DXTableCheckboxComponent,
  DXTableToggleCellComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../../../helpers/dxTableGrid';
import { IOphalenSelectieNieuwResultElement } from '../../../../../../../../shared/src/api/v2/transport/reservering/voorraad';
import LoadingSpinner from '../../../../../../components/Gedeeld/LoadingSpinner';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import { RowDetailState, SelectionState, DataTypeProvider } from '@devexpress/dx-react-grid';
import api from '../../../../../../api';
import * as _ from 'lodash';
import styled from 'styled-components';
import { IFilterSchemaFilter } from '../../../../../../../../shared/src/models/filter';

interface IProps extends ITabbladProps {}

const geenData = {
  noData: 'Geen producten gevonden',
};

interface IPrioriteitTrProps {
  kleuren?: boolean;
}

const PrioriteitTr = styled.tr<IPrioriteitTrProps>`
  td {
    ${(props) => (props.kleuren ? `background-color: ${Kleur.HeelLichtGeel} !important;` : '')}
  }
`;

const Nieuw: React.FC<IProps> = (props) => {
  const {
    magID,
    typeID,
    onTypeIDChange,
    productmodel,
    alleenVoorMerk,
    metVrijeVoorraad,
    prodModID,
    productmodelSelectie,
    prodSrtID,
    zoekterm,
  } = props;

  const [selectieNieuw, setSelectieNieuw] = useState<IOphalenSelectieNieuwResultElement[]>([]);

  // Selectie van nieuwe producten voor het vigerende productmodel
  useEffect(() => {
    (async () => {
      if (prodModID === null) {
        return;
      }

      const productmodel = (
        await api.v2.product.model.ophalenProductmodellen({
          filterSchema: { filters: [{ naam: 'IDS', data: [prodModID] }] },
        })
      ).modellen[0];

      let result = await api.v2.transport.reservering.levering.ophalenSelectieNieuw({
        filterSchema: {
          filters: [
            { naam: 'MAG_ID', data: magID },
            productmodelSelectie ? { naam: 'PRODMOD_ID', data: prodModID } : null,
            !productmodelSelectie ? { naam: 'PRODSRT_ID', data: prodSrtID } : null,
            { naam: 'MERK_ID', data: alleenVoorMerk ? productmodel.MerkID : null },
            { naam: 'MET_VRIJE_VOORRAAD', data: metVrijeVoorraad },
            { naam: 'ZOEKTERM', data: zoekterm },
          ].filter((x) => x !== null) as IFilterSchemaFilter[],
        },
      });
      if (productmodelSelectie) {
        result = result.filter((x) => x.prioriteit !== null);
      }
      const resultGesorteerd = _.sortBy(result, ['prioriteit', 'Merknaam', 'Typenaam']);

      setSelectieNieuw(resultGesorteerd);
    })();
  }, [
    magID,
    productmodel,
    alleenVoorMerk,
    metVrijeVoorraad,
    prodModID,
    prodSrtID,
    productmodelSelectie,
    zoekterm,
  ]);

  const kolommen = useMemo<TypedColumn<IOphalenSelectieNieuwResultElement>[]>(
    () =>
      [
        productmodelSelectie
          ? {
              name: 'prioriteit',
              title: 'Prio',
            }
          : null,
        {
          name: 'Merknaam',
          title: 'Merk',
        },
        {
          name: 'Typenaam',
          title: 'Type',
        },
        {
          name: 'ProductsoortnaamKort',
          title: 'Cat.',
        },
        {
          name: 'Kenmerk',
          title: 'Kenmerk',
        },
        {
          name: '__aantalVrij' as any,
          title: 'Vrij',
        },
        {
          name: '__aantalInkoop' as any,
          title: 'Inkoop',
        },
      ].filter((x) => x !== null) as TypedColumn<IOphalenSelectieNieuwResultElement>[],
    [productmodelSelectie],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IOphalenSelectieNieuwResultElement>[]>(
    () => [
      {
        columnName: 'prioriteit',
        width: 50,
      },
      {
        columnName: 'Merknaam',
        width: 100,
      },
      {
        columnName: 'Typenaam',
        width: 175,
      },
      {
        columnName: 'ProductsoortnaamKort',
        width: 60,
      },
      {
        columnName: 'Kenmerk',
        width: 170,
      },
      {
        columnName: '__aantalVrij' as any,
        width: 60,
      },
      {
        columnName: '__aantalInkoop' as any,
        width: 60,
      },
    ],
    [],
  );

  const keyExtractor = useCallback((row: IOphalenSelectieNieuwResultElement) => row.TypeID, []);

  return selectieNieuw === null ? (
    <LoadingSpinner />
  ) : (
    <GridStyleWrapper maxHeight={300}>
      <Grid columns={kolommen} getRowId={keyExtractor} rows={selectieNieuw}>
        <DataTypeProvider
          for={['__aantalVrij']}
          formatterComponent={(props) => {
            const aantal = props.row.voorraad !== null ? props.row.voorraad.aantalVrij : 0;
            return <span>{aantal !== 0 ? aantal : ''}</span>;
          }}
        />

        <DataTypeProvider
          for={['__aantalInkoop']}
          formatterComponent={(props) => {
            const aantal = props.row.voorraad !== null ? props.row.voorraad.aantalInkoop : 0;
            return <span>{aantal !== 0 ? aantal : ''}</span>;
          }}
        />

        <VirtualTable
          messages={geenData}
          rowComponent={(rowProps) => {
            const row: IOphalenSelectieNieuwResultElement = rowProps.row;

            if (row.prioriteit !== null) {
              return <PrioriteitTr kleuren={true}>{rowProps.children}</PrioriteitTr>;
            }
            return <tr>{rowProps.children}</tr>;
          }}
        />
        <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
        <TableHeaderRow />
        {/* <RowDetailState defaultExpandedRowIds={[]} />
        <TableRowDetail toggleCellComponent={DXTableToggleCellComponent} /> */}

        <SelectionState
          selection={typeID === null ? [] : [typeID]}
          // Maar een element selecteren per keer, dus pakken bij een deselectie, een lege array
          // of bij een selectie het laatst geselecteerde element als het enige element
          onSelectionChange={(x) =>
            onTypeIDChange((x.length > 0 ? x[x.length - 1] : null) as number)
          }
        />

        <TableSelection cellComponent={DXTableCheckboxComponent} />
      </Grid>
    </GridStyleWrapper>
  );
};

export default Nieuw;
