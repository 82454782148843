import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import IDialoogProps from '../../../../core/IDialoogProps';
import Dialoog from '../../../../components/dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import * as Yup from 'yup';
import { IOphalenProductsoortenResultElement } from '../../../../../../shared/src/api/v2/product/soort';
import { IOphalenDienstenVoorTypeResultElement } from '../../../../../../shared/src/api/v2/inkoop/opdracht/nieuw';
import api from '../../../../api';
import nameOf from '../../../../core/nameOf';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../../stores/RootStore';
import Combobox from '../../../../components/formulier/Combobox';
import { EResultType } from '../../../../stores/CheckStore';

import MultiCombobox, { IKolom } from '../../../../components/formulier/MultiCombobox';
import NumeriekVeld from '../../../../components/formulier/NumeriekVeld';
import teksten from '../../../../bedrijfslogica/teksten';
import * as _ from 'lodash';
import VinkVeld from '../../../../components/formulier/VinkVeld';
import { IFilterSchemaFilter } from '../../../../../../shared/src/models/filter';
import FormatteerBedrag, {
  StandaardMutatieBedragOpmaker,
} from '../../../../components/MutatieBedrag';
import VeldWeergave from '../../../../components/formulier/VeldWeergave';
import { Kleur as EKleur } from '../../../../bedrijfslogica/constanten';
import { IOphalenMagazijnenResultElement } from '../../../../../../shared/src/api/v2/magazijn';
import { IOphalenInstellingenResult } from '../../../../../../shared/src/api/v2/instelling';
import { IOphalenProducttypenInkoopdienstenResultElement } from '../../../../../../shared/src/api/v2/inkoop/aanbod';
import { format } from 'date-fns';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../models/IRemoteData';
import { IOphalenProducttypenResultElement } from '../../../../../../shared/src/api/v2/product/type';
import { IOphalenDienstenResultElement } from '../../../../../../shared/src/api/v2/dienst/inkoop';
import Inkoopdienst from '../../../Leverancier/Entiteiten/entiteiten/Inkoopdienst';
import { EOpdrachtwijze } from '../../../../bedrijfslogica/enums';

interface IProps extends IDialoogProps<null> {
  prodSrtID?: number | null;
  typeID?: number | null;
}

interface IFormikValues {
  prodSrtID: number | null;
  typeID: number | null;
  inkDienstID: number | null;
  aantal: number | null;
  directVersturen: boolean;
  magID: number;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  prodSrtID: 'Productsoort',
  typeID: 'Type',
  inkDienstID: 'Leverancier(s) voor het type',
  aantal: 'Aantal',
  directVersturen: 'Opdracht direct versturen',
  magID: 'Aflevermagazijn',
};

const NieuwDialoog: React.FC<IProps> = observer((props) => {
  const { dialoogIndex, onAnnuleren, onSuccess } = props;
  const { checkStore } = useContext(RootStoreContext);
  const formikRef = useRef<Formik<IFormikValues>>(null);

  const [magazijnen, setMagazijnen] = useState<IOphalenMagazijnenResultElement[] | null>(null);
  const [instellingen, setInstellingen] = useState<IOphalenInstellingenResult | null>(null);
  const [producttype, setProducttype] = useState<
    IRemoteData<IOphalenProducttypenResultElement | null>
  >(createPendingRemoteData());

  useEffect(() => {
    (async () => {
      const magazijnen = (
        await api.v2.magazijn.ophalenMagazijnen({
          filterSchema: { filters: [{ naam: 'IS_VOORRAAD', data: true }] },
        })
      ).magazijnen;

      setMagazijnen(magazijnen);
    })();
  }, []);

  // Ophalen gegevens van het producttype als TypeID is meegegeven met de props tbv initiele waarde van ProdSrtID
  useEffect(() => {
    (async () => {
      if (props.typeID === undefined) {
        setProducttype(createReadyRemoteData(null));
        return;
      }
      const producttype = (
        await api.v2.product.type.ophalenProducttypen({
          filterSchema: { filters: [{ naam: 'IDS', data: [props.typeID] }] },
        })
      ).producttypen[0];

      setProducttype(createReadyRemoteData(producttype));
    })();
  }, [props.typeID]);

  useEffect(() => {
    (async () => {
      const instellingen = await api.v2.instelling.ophalenInstellingen({});
      setInstellingen(instellingen);
    })();
  }, []);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      if (values.inkDienstID === null) {
        return;
      }

      const checkParams = {
        inkDienstID: values.inkDienstID!,
        magID: values.magID,
        typeID: values.typeID!,
        aantal: values.aantal!,
        referentie: null,
        datumGewenst: null,
      };
      const checkData = await api.v2.inkoop.opdracht.nieuw.checkToevoegenOpdracht(checkParams);
      const controleResult = await checkStore.controleren({
        checkData,
      });
      if (controleResult.type === EResultType.Annuleren) {
        actions.setSubmitting(false);
        return;
      }

      if (values.directVersturen) {
        const dienst = await api.v2.dienst.inkoop
          .ophalenDiensten({
            filterSchema: { filters: [{ naam: 'IDS', data: [values.inkDienstID] }] },
          })
          .then((x) => x.diensten[0]);

        if (
          (
            await checkStore.bevestigen({
              inhoud:
                dienst.opdrachtwijze?.NaamEnum === EOpdrachtwijze.Portal ? (
                  <span>
                    Je dient de opdracht zelf in het portaal te zetten.
                    <br />
                    De opdracht wordt als Verstuurd aangemerkt.
                  </span>
                ) : (
                  `Opdracht zal direct verstuurd worden, doorgaan?`
                ),
            })
          ).type === EResultType.Annuleren
        ) {
          actions.setSubmitting(false);
          return;
        }
      }

      const result = await api.v2.inkoop.opdracht.nieuw.toevoegenOpdracht(checkParams);

      if (values.directVersturen) {
        const opdrachtenResult = await api.v2.inkoop.opdracht.ophalenOpdrachten({
          filterSchema: {
            filters: [
              {
                naam: 'IDS',
                data: [result.InkOpdID],
              },
            ],
          },
        });
        const opdracht = opdrachtenResult.inkoopopdrachten[0];

        const { diensten } = await api.v2.dienst.inkoop.ophalenDiensten({
          filterSchema: {
            filters: [
              {
                naam: 'IDS',
                data: [opdracht.dienst.ID],
              },
            ],
          },
        });
        const dienst = diensten[0];

        // const params = {
        //   inkOpdIDs: [result.InkOpdID],
        //   opdWijzeID: dienst.OpdWijzeID ?? null,
        // };
        // const checkData = await api.v2.inkoop.opdracht.checkVersturenOpdrachten(params);

        // const checkResult = await checkStore.controleren({
        //   checkData,
        // });
        // if (checkResult.type === EResultType.Annuleren) {
        //   actions.setSubmitting(false);
        //   return;
        // }

        await api.v2.inkoop.opdracht.versturenOpdrachten({
          inkOpdIDs: [result.InkOpdID],
          opdWijzeID: dienst.OpdWijzeID ?? null,
        });
      }

      actions.setSubmitting(false);
      onSuccess(null);
    },
    [formikRef.current],
  );

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (
      magazijnen === null ||
      instellingen === null ||
      producttype.state === ERemoteDataState.Pending
    ) {
      return null;
    }

    const magID = instellingen.Voorraad_MagID || magazijnen[0].MagID;
    const prodSrtID =
      props.prodSrtID ??
      (producttype.data !== null ? producttype.data.ProdSrtID : instellingen.ProdSrtID);

    return {
      prodSrtID,
      typeID: producttype.data !== null ? producttype.data!.TypeID : null,
      inkDienstID: null,
      aantal: 1,
      directVersturen: false,
      magID,
    };
  }, [props.prodSrtID, props.typeID, magazijnen, instellingen]);

  const validationSchema = useMemo(() => {
    const fields: Partial<Record<keyof IFormikValues, any>> = {
      prodSrtID: Yup.number(),
      typeID: Yup.number().required(),
      inkDienstID: Yup.number().required(),
      aantal: Yup.number()
        .min(
          1,
          teksten.formulier.E_GETAL_MINIMAAL({
            veldnaam: veldnamen.aantal,
            aantal: 1,
          }),
        )
        .max(
          250,
          teksten.formulier.E_GETAL_MAXIMAAL({
            veldnaam: veldnamen.aantal,
            aantal: 250,
          }),
        ),
      directVersturen: Yup.boolean(),
      magID: Yup.number(),
    };

    return Yup.object().shape(fields);
  }, []);

  return (
    <Dialoog index={dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Nieuwe inkoopopdracht</ModalTitle>
        {/* <span>{JSON.stringify(dienstID)}</span> */}
      </ModalHeader>
      {initialValues === null ? (
        <LoadingSpinner />
      ) : (
        <Formik<IFormikValues>
          ref={formikRef}
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
          render={(formikProps: FormikProps<IFormikValues>) => {
            return (
              <Formulier
                formikProps={formikProps}
                magazijnen={magazijnen}
                onAnnuleren={props.onAnnuleren}
              />
            );
          }}
        />
      )}
    </Dialoog>
  );
});

interface IFormulierProps {
  formikProps: FormikProps<IFormikValues>;
  magazijnen: IOphalenMagazijnenResultElement[] | null;
  onAnnuleren: () => void;
}

const Formulier = (props: IFormulierProps) => {
  const { formikProps } = props;
  const { submitForm, isSubmitting, isValid, values, setFieldValue } = formikProps;

  const [diensten, setDiensten] = useState<IOphalenDienstenVoorTypeResultElement[] | null>(null);
  const [productsoorten, setProductsoorten] = useState<
    IOphalenProductsoortenResultElement[] | null
  >(null);
  const [producttypen, setProducttypen] = useState<IOphalenProducttypenResultElement[] | null>(
    null,
  );
  const [
    inkoopdataVoorType,
    setInkoopdataVoorType,
  ] = useState<IOphalenProducttypenInkoopdienstenResultElement | null>(null);

  // useEffect(() => {
  //   (async () => {
  //     const results = await api.v2.product.soort.ophalenProductsoorten({
  //       filterSchema: {
  //         filters: [
  //           values.prodSrtID !== null
  //             ? {
  //                 naam: 'ACTIEF',
  //                 data: true,
  //               }
  //             : null,
  //         ].filter((x) => x !== null) as IFilterSchemaFilter[],
  //       },
  //     });

  //     setProductsoorten(results);
  //   })();
  // }, [values.prodSrtID]);

  useEffect(() => {
    (async () => {
      // const results = await api.v2.product.soort.ophalenProductsoorten({
      //   filterSchema: {
      //     filters: [
      //       {
      //         naam: 'ACTIEF',
      //         data: true,
      //       },
      //     ].filter((x) => x !== null) as IFilterSchemaFilter[],
      //   },
      // });

      const results = (await api.v2.product.soort.ophalenProductsoorten({})).filter(
        (x) => true, //x.Actief,
      );

      setProductsoorten(results);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (values.typeID === null) {
        setDiensten([]);
        return [];
      }
      // Inkoopdiensten voor het type
      const diensten = (
        await api.v2.inkoop.opdracht.nieuw.ophalenDienstenVoorType({ typeID: values.typeID })
      ).diensten;

      setDiensten(diensten);
    })();
  }, [values.typeID]);

  useEffect(() => {
    (async () => {
      if (values.typeID === null || diensten === null || diensten.length === 0) {
        return;
      }
      const dienst = diensten.find((x) => x.Prioriteit) || diensten[0];
      setFieldValue('inkDienstID', dienst.ID);
      // setDienstID(dienst.ID);

      // Onderstaande regel toegevoegd omdat anders het veld de waarde niet krijgt (weet niet waarom niet ...)
      // formikRef.current!.setFieldValue(nameof<IFormikValues>('inkDienstID'), dienst.ID);
    })();
  }, [values.typeID, diensten]);

  // useEffect(() => {
  //   (async () => {
  //     if (productsoorten === null) {
  //       return;
  //     }

  //     setFieldValue('prodSrtID', productsoorten[0].ProdSrtID);
  //   })();
  // }, [productsoorten]);

  useEffect(() => {
    (async () => {
      if (values.prodSrtID === null || productsoorten === null) {
        return;
      }
      setFieldValue('typeID', values.typeID);
    })();
  }, [values.prodSrtID, productsoorten]);

  useEffect(() => {
    (async () => {
      if (values.prodSrtID === null) {
        return;
      }

      const results = (
        await api.v2.product.type.ophalenProducttypen({
          filterSchema: {
            filters: [
              { naam: 'PRODSRT_IDS', data: [values.prodSrtID] },
              { naam: 'INKOOP_TOEGESTAAN', data: true },
            ],
          },
        })
      ).producttypen;
      const producttypen = _.orderBy(results, ['Merknaam', 'Typenaam'], ['asc', 'asc']);

      setProducttypen(producttypen);
    })();
  }, [values.prodSrtID]);

  // Inkoopdata ophalen voor TypeID/InkDienstID (tbv signalering van de leverstatus)
  useEffect(() => {
    (async () => {
      if (values.typeID === null || values.inkDienstID === null) {
        return;
      }
      const params = {
        producttypenInkoopdiensten: [{ typeID: values.typeID, inkDienstID: values.inkDienstID }],
      };
      const result = await api.v2.inkoop.aanbod.ophalenProducttypenInkoopdiensten(params);
      if (result.producttypenInkoopdiensten.length === 0) {
        setInkoopdataVoorType(null);
        return;
      }
      setInkoopdataVoorType(result.producttypenInkoopdiensten[0]);
    })();
  }, [values.typeID, values.inkDienstID]);

  const [inkoopdiensten, setInkoopdiensten] = useState<IOphalenDienstenResultElement[] | null>(
    null,
  );

  useEffect(() => {
    (async () => {
      const diensten = (await api.v2.dienst.inkoop.ophalenDiensten({})).diensten;
      setInkoopdiensten(diensten);
    })();
  }, []);

  const typenKolommen = useMemo<IKolom<IOphalenProducttypenResultElement>[]>(() => {
    const breedteSoort = values.prodSrtID === null ? 75 : 0;
    return [
      {
        key: 'Merknaam',
        label: 'Merk',
        breedte: 100,
      },
      {
        key: 'Typenaam',
        label: 'Type',
        breedte: 200,
      },
      {
        key: 'ProductsoortnaamKort',
        label: 'Soort',
        breedte: breedteSoort,
      },
      {
        key: 'Kenmerk',
        label: 'Kenmerk',
        breedte: 200,
      },
    ];
  }, [values.prodSrtID]);

  const leveranciersKolommen = useMemo<IKolom<IOphalenDienstenVoorTypeResultElement>[]>(() => {
    return [
      {
        key: 'LeveranciernaamKort',
        label: 'Leverancier',
        breedte: 175,
      },
      {
        key: 'Prioriteit',
        formatFabriek: (row) => {
          return <span>{row.Prioriteit ? 'Ja' : ''}</span>;
        },
        label: 'Prio.lev.',
        breedte: 80,
      },
      {
        key: 'Inkoopprijs',
        label: 'Ink.prijs',
        breedte: 100,
        formatFabriek: (x) => {
          if (x.Inkoopprijs === null) {
            return <span>Niet opgegeven</span>;
          }
          return <FormatteerBedrag bedrag={x.Inkoopprijs} />;
        },
      },
      {
        key: 'InkoopprijsActie',
        label: 'Actieprijs',
        breedte: 100,
        formatFabriek: (x) => {
          if (x.InkoopprijsActie === null) {
            return <span></span>;
          }
          return <FormatteerBedrag bedrag={x.InkoopprijsActie} />;
        },
      },
      {
        key: 'ActieMinimaalAantal',
        label: 'Min.#',
        breedte: 75,
        formatFabriek: (x) => {
          if (x.InkoopprijsActie === null) {
            return <span></span>;
          }
          return x.ActieMinimaalAantal;
        },
      },
    ];
  }, []);

  const ActieInfo = useMemo(
    () => (props: any) => {
      if (diensten === null || diensten.length === 0) {
        return <span></span>;
      }
      const dienst = diensten.find((x: any) => x.ID === values.inkDienstID) || null;
      if (dienst === null || dienst.InkoopprijsActie === null) {
        return <span>Geen actie</span>;
      }

      const aantal = props.aantal!;

      const actieVanToepassing =
        dienst.InkoopprijsActie !== null && aantal >= dienst.ActieMinimaalAantal! ? true : false;

      return (
        <VeldWeergave>
          <span style={{ color: actieVanToepassing ? EKleur.Groen : EKleur.Rood }}>
            <span className="d-flex">
              Korting&nbsp;
              <FormatteerBedrag
                bedrag={dienst.Inkoopprijs - dienst.InkoopprijsActie}
                opmaakComponent={(opmaakProps) => {
                  const kleur = actieVanToepassing ? EKleur.Groen : EKleur.Rood;
                  return <StandaardMutatieBedragOpmaker {...opmaakProps} color={kleur} />;
                }}
              />
              &nbsp;p/stuk bij minimaal {dienst.ActieMinimaalAantal}
            </span>
          </span>
        </VeldWeergave>
      );
    },
    [diensten, values.inkDienstID],
  );

  const magazijnKolommen = useMemo<IKolom<IOphalenMagazijnenResultElement>[]>(() => {
    return [
      {
        key: 'NaamKort',
        label: 'Naam',
        breedte: 200,
      },
    ];
  }, []);

  if (
    diensten === null ||
    producttypen === null ||
    productsoorten === null ||
    inkoopdiensten === null
  ) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <ModalBody>
        {/* <span>{JSON.stringify(values)}</span>; */}
        <div className="form-group">
          <div className="row">
            <div className="col-12">
              <label>{veldnamen.prodSrtID}</label>

              <Field
                name={nameOf<IFormikValues>('prodSrtID')}
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;

                  return (
                    <Combobox
                      isWisbaar
                      legeOptieTonen
                      geselecteerd={field.value}
                      onSelectieChange={(x) => {
                        form.setFieldValue(field.name, x);
                      }}
                      opties={productsoorten!.map((x) => {
                        return {
                          id: x.ProdSrtID,
                          label: x.Naam,
                        };
                      })}
                    />
                  );
                }}
              />
            </div>

            <div className="col-12 mt-3">
              <label>{veldnamen.typeID}</label>

              <Field
                name={nameOf<IFormikValues>('typeID')}
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;

                  if (producttypen === null) {
                    // return <LoadingSpinner />;
                    return;
                  }

                  return (
                    <MultiCombobox<number, any>
                      sleutelExtractor={(row: IOphalenProducttypenResultElement) => row.TypeID}
                      onWaardeChange={(waarde: number | null) => {
                        form.setFieldValue(field.name, waarde);
                      }}
                      representatieFabriek={(row: IOphalenProducttypenResultElement) =>
                        `${row.Merknaam} - ${row.Typenaam}`
                      }
                      waarde={field.value}
                      opties={
                        producttypen !== null
                          ? producttypen.map((x) => {
                              return {
                                TypeID: x.TypeID,
                                Typenaam: x.Typenaam,
                                Merknaam: x.Merknaam,
                                ProductsoortnaamKort: x.ProductsoortnaamKort,
                                Kenmerk: x.Kenmerk,
                              };
                            })
                          : []
                      }
                      kolommen={typenKolommen}
                    />
                  );
                }}
              />
            </div>

            <div className="col-12 mt-3">
              <label>
                {diensten.length > 1
                  ? `Leveranciers (${diensten.length}) voor het type`
                  : 'Leverancier voor het type'}
              </label>

              <Field
                name={nameOf<IFormikValues>('inkDienstID')}
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;
                  return (
                    <MultiCombobox<number, IOphalenDienstenVoorTypeResultElement>
                      sleutelExtractor={(row: IOphalenDienstenVoorTypeResultElement) => row.ID}
                      onWaardeChange={(waarde: number | null) => {
                        form.setFieldValue(field.name, waarde);
                      }}
                      representatieFabriek={(row: IOphalenDienstenVoorTypeResultElement) =>
                        `${row.LeveranciernaamKort} ${row.Prioriteit ? ' (prio leverancier)' : ''}`
                      }
                      waarde={field.value}
                      opties={diensten.map((dienst) => {
                        return {
                          ID: dienst.ID,
                          LeveranciernaamKort: dienst.LeveranciernaamKort,
                          Prioriteit: dienst.Prioriteit,
                          Inkoopprijs: dienst.Inkoopprijs,
                          InkoopprijsActie: dienst.InkoopprijsActie,
                          ActieMinimaalAantal: dienst.ActieMinimaalAantal,
                          DirectLeverbaar: dienst.DirectLeverbaar,
                        };
                      })}
                      kolommen={leveranciersKolommen}
                      disabled={diensten.length <= 1}
                    />
                  );
                }}
              />
            </div>

            <div className="col-6 mt-3">
              <label>{veldnamen.magID}</label>

              <Field
                name={nameOf<IFormikValues>('magID')}
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;

                  if (props.magazijnen === null) {
                    return;
                  }

                  return (
                    <MultiCombobox<number, any>
                      sleutelExtractor={(row: IOphalenMagazijnenResultElement) => row.MagID}
                      onWaardeChange={(waarde: number | null) => {
                        fieldProps.form.setFieldValue(field.name, waarde);
                      }}
                      representatieFabriek={(row: IOphalenMagazijnenResultElement) =>
                        `${row.NaamKort} - ${row.locatie.Plaatsnaam}`
                      }
                      waarde={field.value}
                      opties={props.magazijnen}
                      kolommen={magazijnKolommen}
                    />
                  );
                }}
              />
            </div>

            <div className="row col-12 d-flex align-items-center mt-3">
              <div className="col-4">
                <label>{veldnamen.aantal}</label>
                <Field
                  name={nameOf<IFormikValues>('aantal')}
                  render={(fieldProps: FieldProps<IFormikValues>) => {
                    const { field, form } = fieldProps;
                    return (
                      <NumeriekVeld
                        waarde={field.value}
                        onChange={(x) => form.setFieldValue(field.name, x)}
                        min={1}
                        max={250}
                      />
                    );
                  }}
                />
              </div>

              <div className="col-8 d-flex h-100 align-items-end">
                <ActieInfo aantal={values.aantal} />
              </div>
            </div>

            <div className="col-12 d-flex align-items-center mt-3">
              <Field
                name="directVersturen"
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;
                  return (
                    <VinkVeld
                      aangevinkt={field.value}
                      onGewijzigd={(x) => {
                        form.setFieldValue(field.name, x);
                      }}
                    />
                  );
                }}
              />

              {/* TODO Dennis Hoe kan onderstaand mooier? */}

              <span className="ml-2">
                {veldnamen.directVersturen}{' '}
                {values.inkDienstID !== null ? (
                  inkoopdiensten.find((x) => x.ID === values.inkDienstID) !== undefined &&
                  inkoopdiensten.find((x) => x.ID === values.inkDienstID)!.opdrachtwijze
                    ?.NaamEnum === EOpdrachtwijze.Portal ? (
                    <span className="ml-2" style={{ color: EKleur.Blauw }}>
                      Gaat via een PORTAL
                    </span>
                  ) : (
                    ''
                  )
                ) : (
                  ''
                )}
              </span>
            </div>

            {/* <div className="col-12 mt-3" style={{ color: EKleur.Rood }}>
              <span>
                {values.inkDienstID !== null ? (
                  inkoopdiensten.find((x) => x.ID === values.inkDienstID) !== undefined &&
                  inkoopdiensten.find((x) => x.ID === values.inkDienstID)!.opdrachtwijze
                    ?.NaamEnum === EOpdrachtwijze.Portal &&
                  inkoopdiensten.find((x) => x.ID === values.inkDienstID)!.PortalURL !== null ? (
                    <a
                      href={inkoopdiensten.find((x) => x.ID === values.inkDienstID)!.PortalURL!}
                      target="_blank"
                    >
                      {inkoopdiensten.find((x) => x.ID === values.inkDienstID)!.PortalURL}
                    </a>
                  ) : (
                    'Er is geen portal URL opgegeven'
                  )
                ) : (
                  ''
                )}
              </span>
            </div> */}

            {inkoopdataVoorType !== null && !inkoopdataVoorType.DirectLeverbaar && (
              <div className="col-12 mt-3" style={{ color: EKleur.Rood }}>
                <VeldWeergave>
                  Type is niet direct leverbaar
                  {inkoopdataVoorType.DatumVerwacht !== null
                    ? ' (Verwacht op ' +
                      format(new Date(inkoopdataVoorType.DatumVerwacht), 'dd-MM-yyyy') +
                      ')'
                    : ''}
                </VeldWeergave>
              </div>
            )}
          </div>
        </div>
      </ModalBody>

      <ModalFooter className="d-flex flex-row justify-content-start">
        <button
          className="btn btn-primary"
          onClick={submitForm}
          style={{
            width: 100,
          }}
          disabled={isSubmitting || !isValid}
        >
          Ok
        </button>
        <button
          className="btn btn-secondary"
          onClick={props.onAnnuleren}
          style={{
            width: 100,
          }}
        >
          Annuleren
        </button>
      </ModalFooter>
    </>
  );
};

export default NieuwDialoog;
