import { addDays, addMonths, format } from 'date-fns';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ModalBody, ModalFooter, ModalTitle } from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/esm/ModalHeader';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IOphalenSaldiGrootboekrekeningenResultElement } from '../../../../../../shared/src/api/v2/boekhouding/boeking';
import { IOphalenDagboekenResultElement } from '../../../../../../shared/src/api/v2/boekhouding/boeking/dagboek';
import { IFilterSchema } from '../../../../../../shared/src/models/filter';
import api from '../../../../api';
import { EDagboek, EDagboeksoort } from '../../../../bedrijfslogica/enums';
import Dialoog from '../../../../components/dialogen/Dialoog';
import FilterBalkV2, {
  IFilter,
  IFilterData,
  IWeergaveProps,
  maakFilterSchema,
} from '../../../../components/FilterBalkV2';
import Combobox from '../../../../components/formulier/Combobox';
import DatumKiezer from '../../../../components/formulier/DatumKiezer';
import MultiSelect from '../../../../components/formulier/MultiSelect';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import MenuLayout from '../../../../components/MenuLayout';
import FormatteerBedrag from '../../../../components/MutatieBedrag';
import ASPTabel from '../../../../components/tabel/ASPTabel';
import {
  ASPKolom,
  EAspKolomBreedteType,
  ESortering,
  IAspKolomSorteringItem,
} from '../../../../components/tabel/ASPTabel/types';
import IDialoogProps from '../../../../core/IDialoogProps';
import useUrlState from '../../../../core/useUrlState';
import { dagDatum } from '../../../../helpers/datum';
import GrootboekSelectie from '../../../../components/formulier/GrootboekSelectie';
import { IOphalenGrootboekenResultElement } from '../../../../../../shared/src/api/v2/boekhouding/boeking/grootboek';

const urlStateKey = 'saldiGrootboekrekening';

interface IProps extends IDialoogProps, RouteComponentProps {
  id: number;
}

enum EFilter {
  DatumVem = 'DATUM_VEM',
  DatumTem = 'DATUM_TEM',
  Dagboeken = 'DAGBOEK_IDS',
  SoortenGrootboekrekening = 'SOORTEN_GROOTBOEKREKENING',
  Grootboeken = 'GRB_REK_IDS',
}

interface IUrlState {
  filterData: IFilterData<EFilter>[];
}

// const defaultUrlState: IUrlState = {
//   filterData: [
//     {
//       naam: EFilter.DatumVem,
//       isActief: false,
//       data: dagDatum(new Date()).toISOString(),
//     },
//     {
//       naam: EFilter.DatumTem,
//       isActief: false,
//       data: dagDatum(new Date()).toISOString(),
//     },
//     {
//       naam: EFilter.Dagboeken,
//       data: [1],
//       isActief: true,
//     },
//     {
//       naam: EFilter.SoortenGrootboekrekening,
//       data: ['L'],
//       isActief: false,
//     },
//   ],
// };

export enum EKolom {
  Nummer,
  Soort,
  Naam,
  saldo,
  saldoDB,
  saldoCR,
}

const hoogteModaal = 600;

interface IWrapperData {
  dagboekID: number;
  dagboeken: IOphalenDagboekenResultElement[];
}

const GrootboekrekeningSaldiInfoDialoogWrapper = (props: IProps) => {
  const [dagboeken, setDagboeken] = useState<IOphalenDagboekenResultElement[] | null>(null);
  const ophalenDagboeken = useCallback(async () => {
    const dagboekenResult = await api.v2.boeking.dagboek.ophalenDagboeken({
      filterSchema: {
        // filters: [{ naam: 'DAGBOEKSOORT_NAAM_ENUMS', data: [params.dagboeksoort] }],
      },
    });

    const dagboekenGesorteerd = _.orderBy(dagboekenResult.dagboeken, ['Nummer'], ['asc']);

    setDagboeken(dagboekenGesorteerd);
  }, []);

  useEffect(() => {
    ophalenDagboeken();
  }, [ophalenDagboeken]);

  const dagboekID = useMemo(() => {
    if (dagboeken === null) {
      return null;
    }
    return dagboeken.find((x) => x.Nummer === EDagboek.MEMO)!.ID;
  }, [dagboeken]);

  if (dagboeken === null) {
    return null;
  }

  return (
    <GrootboekrekeningSaldiInfoDialoog {...props} dagboekID={dagboekID!} dagboeken={dagboeken} />
  );
};

const GrootboekrekeningSaldiInfoDialoog = (props: IProps & IWrapperData) => {
  const { dialoogIndex, onAnnuleren, onSuccess, open } = props;

  const eersteDagVanHetJaar = format(new Date(), 'yyyy-MM-01');

  const datumVem = dagDatum(new Date(eersteDagVanHetJaar)).toISOString();
  const datumTem = dagDatum(addDays(addMonths(new Date(eersteDagVanHetJaar), 1), -1)).toISOString();

  const defaultUrlState = useMemo<IUrlState>(() => {
    return {
      filterData: [
        {
          naam: EFilter.DatumVem,
          isActief: true,
          data: datumVem,
        },
        {
          naam: EFilter.DatumTem,
          isActief: true,
          data: datumTem,
        },
        {
          naam: EFilter.Dagboeken,
          data: [props.dagboekID],
          isActief: false,
        },
        {
          naam: EFilter.SoortenGrootboekrekening,
          data: ['L'],
          isActief: false,
        },
        {
          naam: EFilter.Grootboeken,
          data: [],
          isActief: false,
        },
      ],
    };
  }, [datumVem, datumTem, props.dagboekID]);

  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState, urlStateKey);

  const [sortering, setSortering] = useState<IAspKolomSorteringItem<EKolom>[]>([
    {
      key: EKolom.Nummer,
      sortering: ESortering.Descending,
    },
  ]);

  const [filterSchema, setFilterSchema] = useState<IFilterSchema>(
    maakFilterSchema(urlState.filterData),
  );

  const [saldiregels, setSaldiregels] = useState<
    IOphalenSaldiGrootboekrekeningenResultElement[] | null
  >(null);

  const ophalenSaldiregels = useCallback(async () => {
    const result = await api.v2.boekhouding.boeking.ophalenSaldiGrootboekrekeningen({
      filterSchema,
      // filterSchema: {
      //   filters: [
      //     { naam: 'DAGBOEK_IDS', data: [8] },
      //     { naam: 'DATUM_VEM', data: '2022-01-01' },
      //     { naam: 'DATUM_TEM', data: '2022-01-31' },
      //   ],
      // },
    });

    setSaldiregels(result.saldi);
  }, [filterSchema]);

  useEffect(() => {
    ophalenSaldiregels();
  }, [ophalenSaldiregels]);

  const keyExtractor = useCallback((item: IOphalenSaldiGrootboekrekeningenResultElement) => {
    return item.grootboekrekening.ID;
  }, []);

  const kolommen = useMemo<ASPKolom<EKolom, IOphalenSaldiGrootboekrekeningenResultElement>[]>(
    () => [
      {
        key: EKolom.Nummer,
        label: 'Nummer',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 100,
        renderer: (item: IOphalenSaldiGrootboekrekeningenResultElement) => {
          return item.grootboekrekening.Nummer;
        },
      },
      {
        key: EKolom.Soort,
        label: 'APBL',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 70,
        renderer: (item: IOphalenSaldiGrootboekrekeningenResultElement) => {
          return item.grootboekrekening.Soort;
        },
      },
      {
        key: EKolom.Naam,
        label: 'Naam',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 230,
        renderer: (item: IOphalenSaldiGrootboekrekeningenResultElement) => {
          return item.grootboekrekening.Naam;
        },
      },
      {
        key: EKolom.saldoDB,
        label: 'Debet',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 120,
        renderer: (item: IOphalenSaldiGrootboekrekeningenResultElement) => {
          return <FormatteerBedrag bedrag={item.saldoDb - item.saldoDbBtw} />;
        },
      },
      {
        key: EKolom.saldoCR,
        label: 'Credit',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 120,
        renderer: (item: IOphalenSaldiGrootboekrekeningenResultElement) => {
          return <FormatteerBedrag bedrag={item.saldoCr - item.saldoCrBtw} />;
        },
      },
      {
        key: EKolom.saldo,
        label: 'Saldo',
        breedteType: EAspKolomBreedteType.Flex,
        flex: 1,
        renderer: (item: IOphalenSaldiGrootboekrekeningenResultElement) => {
          return <FormatteerBedrag bedrag={item.saldo - item.saldoBtw} />;
        },
      },
    ],
    [],
  );

  const grootboekenProvider = useCallback(async () => {
    const result = await api.v2.boeking.grootboek.ophalenGrootboeken({});
    return result.grootboeken;
  }, []);

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.DatumVem,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return (
            <div className="d-flex align-items-center">
              <span>Vanaf</span>
              <span className="ml-2">
                <DatumKiezer
                  waarde={weergaveProps.data === null ? null : new Date(weergaveProps.data)}
                  onGewijzigd={(x) => {
                    weergaveProps.onDataChange(x === null ? null : x.toISOString());
                    weergaveProps.toepassen();
                  }}
                  isClearable
                  determineValidDate={() => true}
                  determineNextValidDate={(date) => addDays(date, 1)}
                  determinePreviousValidDate={(date) => addDays(date, -1)}
                />
              </span>
            </div>
          );
        },
      },
      {
        naam: EFilter.DatumTem,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return (
            <div className="d-flex align-items-center">
              <span>T/m</span>
              <span className="ml-2">
                <DatumKiezer
                  waarde={weergaveProps.data === null ? null : new Date(weergaveProps.data)}
                  onGewijzigd={(x) => {
                    weergaveProps.onDataChange(x === null ? null : x.toISOString());
                    weergaveProps.toepassen();
                  }}
                  isClearable
                  determineValidDate={() => true}
                  determineNextValidDate={(date) => addDays(date, 1)}
                  determinePreviousValidDate={(date) => addDays(date, -1)}
                />
              </span>
            </div>
          );
        },
      },
      {
        naam: EFilter.Dagboeken,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps: IWeergaveProps<EFilter>) => {
          const data: number[] = weergaveProps.data;
          return (
            <div className="d-flex align-items-center">
              Dagboek
              <span className="ml-2">
                {props.dagboeken === null ? (
                  <LoadingSpinner />
                ) : (
                  <Combobox
                    geselecteerd={data.length === 0 ? null : data[0]}
                    onSelectieChange={(x) => {
                      const newData = x === null ? [] : [x];

                      weergaveProps.onDataChange(newData);
                      weergaveProps.setIsActief(true);
                      weergaveProps.toepassen();
                    }}
                    opties={props.dagboeken.map((x) => ({
                      id: x.ID,
                      label: x.Naam,
                    }))}
                    style={{
                      minWidth: 150,
                    }}
                  />
                  // <MultiSelect
                  //   value={weergaveProps.data}
                  //   onChange={(x) => {
                  //     weergaveProps.onDataChange(x);
                  //     weergaveProps.toepassen();
                  //   }}
                  //   opties={dagboeken.map((x) => ({
                  //     key: x.ID,
                  //     weergave: x.Naam,
                  //   }))}
                  // />
                )}
              </span>
            </div>
          );
        },
      },
      {
        naam: EFilter.SoortenGrootboekrekening,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps: IWeergaveProps<EFilter>) => {
          const data: string[] = weergaveProps.data;
          return (
            <div className="d-flex align-items-center">
              Soort
              <span className="ml-2">
                <Combobox
                  geselecteerd={data.length === 0 ? null : data[0]}
                  onSelectieChange={(x) => {
                    const newData = x === null ? [] : [x];

                    weergaveProps.onDataChange(newData);
                    weergaveProps.setIsActief(true);
                    weergaveProps.toepassen();
                  }}
                  opties={['A', 'P', 'L', 'B'].map((x) => ({
                    id: x,
                    label: x,
                  }))}
                />
                {/* <MultiSelect
                  value={weergaveProps.data}
                  onChange={(x) => {
                    weergaveProps.onDataChange(x);
                    weergaveProps.toepassen();
                  }}
                  opties={['A', 'P', 'L', 'B'].map((x) => ({
                    key: x,
                    weergave: x,
                  }))}
                /> */}
              </span>
            </div>
          );
        },
      },
      {
        naam: EFilter.Grootboeken,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps: IWeergaveProps<EFilter>) => {
          const data: number[] = weergaveProps.data;
          const grootboekID: number | null = data.length === 0 ? null : data[0];

          return (
            <div className="d-flex align-items-center">
              <span className="mr-2">Grootboek</span>
              <GrootboekSelectie
                grootboekID={grootboekID}
                onChange={(grootboekID) => {
                  const nieuweData: number[] = grootboekID === null ? [] : [grootboekID];
                  weergaveProps.onDataChange(nieuweData);
                  weergaveProps.setIsActief(true);
                  weergaveProps.toepassen();
                }}
                grootboekenProvider={grootboekenProvider}
              />
            </div>
          );
        },
      },
    ],
    [props.dagboeken, grootboekenProvider],
  );

  return (
    <Dialoog index={dialoogIndex || 0} modalProps={{ size: `xl` }}>
      <ModalHeader>
        <ModalTitle>Saldi-informatie grootboekrekeningen</ModalTitle>
      </ModalHeader>

      {saldiregels === null || props.dagboeken == null ? (
        <>
          <div
            className="flex-fill d-flex align-items-center justify-content-center"
            style={{ height: hoogteModaal }}
          >
            <LoadingSpinner />
          </div>
        </>
      ) : (
        <>
          <ModalBody className="d-flex flex-column flex-fill p-0" style={{ height: hoogteModaal }}>
            <MenuLayout
              menu={
                <div className="d-flex align-items-center">
                  <FilterBalkV2
                    filters={filters}
                    filterData={urlState.filterData}
                    onFilterDataChange={(x) => setUrlStateSync('filterData', x)}
                    onFilterSchemaChange={setFilterSchema}
                  />
                </div>
              }
              body={
                <div className="d-flex flex-column flex-fill">
                  <ASPTabel
                    rijen={saldiregels}
                    kolommen={kolommen}
                    keyExtractor={keyExtractor}
                    totaalAantalRijen={saldiregels.length}
                    sortering={sortering}
                    onSorteringChange={setSortering}
                    lokaalSorteren
                  />
                </div>
              }
            />
          </ModalBody>
        </>
      )}

      <ModalFooter className="d-flex flex-row justify-content-start">
        <button className="btn btn-primary" onClick={onAnnuleren} style={{ width: 100 }}>
          Sluiten
        </button>
      </ModalFooter>
    </Dialoog>
  );
};

export default withRouter(GrootboekrekeningSaldiInfoDialoogWrapper);
