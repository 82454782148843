import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { observer } from 'mobx-react-lite';
import MenuLayout from '../../../../components/MenuLayout';
import useUrlState from '../../../../core/useUrlState';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import api from '../../../../api';
import ASPTabel from '../../../../components/tabel/ASPTabel';
import {
  ASPKolom,
  EAspKolomBreedteType,
  ESortering,
  IAspKolomSorteringItem,
} from '../../../../components/tabel/ASPTabel/types';
import { IOphalenBtwSoortenResultElement } from '../../../../../../shared/src/api/v2/btw';
import { Helmet } from 'react-helmet';

enum EKolom {
  Code,
  Naam,
  NaamEnum,
}

export interface IExporterenDialoogState {}

export interface IUrlState {
  // exporterenDialoogState: IExporterenDialoogState | null;
  selectie: number[];
  sortering: IAspKolomSorteringItem<EKolom>[];
}

interface IProps extends RouteComponentProps {}

const BtwSoorten: React.FC<IProps> = observer((props) => {
  const defaultUrlState = useMemo<IUrlState>(
    () => ({
      exporterenDialoogState: null,
      selectie: [],
      sortering: [
        {
          key: EKolom.Code,
          sortering: ESortering.Ascending,
        },
      ],
    }),
    [],
  );
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);

  const [btwSoorten, setBtwSoorten] = useState<IOphalenBtwSoortenResultElement[] | null>(null);

  const ophalenBtwSoorten = useCallback(async () => {
    const result = await api.v2.btw.ophalenBtwSoorten({
      filterSchema: {
        filters: [],
      },
    });

    setBtwSoorten(result.btwSoorten);
  }, []);

  useEffect(() => {
    ophalenBtwSoorten();
  }, [ophalenBtwSoorten]);

  const keyExtractor = useCallback((item: IOphalenBtwSoortenResultElement) => item.ID, []);

  const kolommen = useMemo<ASPKolom<EKolom, IOphalenBtwSoortenResultElement>[]>(
    () => [
      {
        key: EKolom.Code,
        label: 'Nummer',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 90,
        renderer: (item: IOphalenBtwSoortenResultElement) => item.Code,
      },
      {
        key: EKolom.Naam,
        label: 'Naam',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 250,
        renderer: (item: IOphalenBtwSoortenResultElement) => item.Naam,
      },
      {
        key: EKolom.NaamEnum,
        label: 'NaamEnum',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 250,
        renderer: (item: IOphalenBtwSoortenResultElement) => item.NaamEnum,
      },
    ],
    [],
  );

  return (
    <>
      <Helmet>
        <title>Btw-soorten</title>
      </Helmet>
      <MenuLayout
        body={
          <div className="d-flex flex-column flex-fill">
            {btwSoorten === null ? (
              <div className="flex-fill d-flex align-items-center justify-content-center">
                <LoadingSpinner />
              </div>
            ) : (
              <div className="d-flex flex-column flex-fill">
                <ASPTabel
                  rijen={btwSoorten}
                  kolommen={kolommen}
                  keyExtractor={keyExtractor}
                  selectie={urlState.selectie}
                  onSelectieChange={(selectie) => setUrlStateSync('selectie', selectie)}
                  sortering={urlState.sortering}
                  onSorteringChange={(sortering) => setUrlStateSync('sortering', sortering)}
                  onWijzigenRij={async (grootboek) => {
                    alert('Nog implementeren');
                  }}
                  onVerwijderenRij={async (grootboek) => {
                    alert('Nog implementeren');
                    // await new Promise((resolve) => setTimeout(resolve, 3000));
                  }}
                  lokaalSorteren
                />
              </div>
            )}
          </div>
        }
      />
    </>
  );
});

export default withRouter(BtwSoorten);
