import { makeObservable, observable } from 'mobx';

class SessieStore {
  public beheerInitieelMagazijnID: number | null = null;

  constructor() {
    makeObservable(this, {
      beheerInitieelMagazijnID: observable,
    });
  }

  public bijwerkenBeheerInitieelMagazijnID = (beheerInitieelMagazijnID: number | null) => {
    this.beheerInitieelMagazijnID = beheerInitieelMagazijnID;
  };
}

export default SessieStore;
