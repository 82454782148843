import React, { useContext, useEffect, useMemo, useState, useCallback } from 'react';
import useUrlState from '../../../../core/useUrlState';
import { RouteComponentProps, withRouter } from 'react-router';
import { observer } from 'mobx-react-lite';
import api from '../../../../api';
import {
  IDefinitiefMakenResult,
  IOphalenFacturenBasisResultElement,
} from '../../../../../../shared/src/api/v2/factuur';
import {
  DXTableCheckboxComponent,
  DXTableToggleCellComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import { RowDetailState, SelectionState } from '@devexpress/dx-react-grid';
import {
  DataTypeProvider,
  IntegratedFiltering,
  IntegratedSorting,
  SearchState,
  SortingState,
  VirtualTable as VirtualTableBase,
} from '@devexpress/dx-react-grid';
import FormatteerBedrag from '../../../../components/MutatieBedrag';
import { format } from 'date-fns';
// import FactuurregelsComp from './Factuurregels';
import RelatieVisualisatie from '../../../../components/personalia/RelatieVisualisatie';
import { ITabbladProps } from '../index';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import { EResultType } from '../../../../stores/CheckStore';
import { RootStoreContext } from '../../../../stores/RootStore';
import FactuurregelsComp from '../Factuurregels';
import SelectieVak from '../../../../components/SelectieVak';
import { achtergrondProcesAfwachten } from '../../../../core/achtergrondProces';
import { RealtimeContext } from '../../../../one-off-components/realtime/RealtimeIntegratie';
import { Helmet } from 'react-helmet';

interface IProps extends RouteComponentProps, ITabbladProps {}

const geenData = {
  noData: 'Geen facturen gevonden',
};

const Voorstel: React.FC<IProps> = observer((props) => {
  const { checkStore } = useContext(RootStoreContext);
  const realtimeContext = useContext(RealtimeContext);

  const [facturen, setFacturen] = useState<IOphalenFacturenBasisResultElement[] | null>(null);

  const keyExtractor = useCallback((row: IOphalenFacturenBasisResultElement) => row.FactID, []);

  const ophalenFacturen = useCallback(async () => {
    const { facturen } = await api.v2.factuur.ophalenFacturenBasis({
      filterSchema: { filters: [{ naam: 'DEFINITIEF', data: false }] },
    });
    setFacturen(facturen);
  }, []);

  useEffect(() => {
    ophalenFacturen();
  }, []);

  const handleVerwijderen = useCallback(async () => {
    const checkData = await api.v2.factuur.checkVerwijderenFacturenUitVoorstel({
      factIDs: props.voorstelSelectie,
    });
    if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
      return;
    }
    if (
      (
        await checkStore.bevestigen({
          inhoud: `Wil je de ${props.voorstelSelectie.length} facturen uit het voorstel verwijderen?`,
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    await api.v2.factuur.verwijderenFacturenUitVoorstel({
      factIDs: props.voorstelSelectie,
    });

    props.onVoorstelSelectieChange([]);

    ophalenFacturen();
  }, [props.voorstelSelectie]);

  const handleDefinitiefMaken = useCallback(async (factIDs: number[]) => {
    const checkData = await api.v2.factuur.checkDefinitiefMaken({
      factIDs,
    });
    if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
      return;
    }
    if (
      (
        await checkStore.bevestigen({
          inhoud: <span>Geselecteerde facturen definitief maken?</span>,
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    // await api.v2.factuur.definitiefMaken({
    //   factIDs,
    // });

    const definitiefAchtergrondProces = await api.v2.factuur.definitiefMakenAchtergrondProces({
      factIDs,
    });
    const definitiefAchtergrondProcesResult = await achtergrondProcesAfwachten<
      IDefinitiefMakenResult
    >(definitiefAchtergrondProces.id, realtimeContext);
    if (definitiefAchtergrondProcesResult.type !== 'DATA') {
      // Timeout of error
      alert('ERROR');
      return;
    }

    props.onVoorstelSelectieChange([]);
    ophalenFacturen();
  }, []);

  const kolommen = useMemo<TypedColumn<IOphalenFacturenBasisResultElement>[]>(
    () => [
      {
        name: '__debiteur' as any,
        title: 'Debiteur',
      },
      // {
      //   name: 'Factuurnummer',
      //   title: 'Fact.nr',
      // },
      {
        name: 'Factuurdatum',
        title: 'Factuurdatum',
      },
      {
        name: 'Vervaldatum',
        title: 'Vervaldatum',
      },
      {
        name: 'Bedrag',
        title: 'Bedrag',
      },
      {
        name: '__betaalwijze' as any,
        title: 'Betaalwijze',
        getCellValue: (x) => {
          return x.betaalwijze.Naam;
        },
      },
      {
        name: '__versturen' as any,
        title: 'Versturen',
        getCellValue: (x) => {
          return x.debiteur.OptieFactuurVersturen;
        },
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IOphalenFacturenBasisResultElement>[]>(
    () => [
      // {
      //   columnName: 'Factuurnummer',
      //   width: 100,
      // },
      {
        columnName: 'Factuurdatum',
        width: 135,
      },
      {
        columnName: 'Vervaldatum',
        width: 125,
      },
      {
        columnName: 'Bedrag',
        width: 100,
      },
      {
        columnName: '__debiteur' as any,
        width: 250,
      },
      {
        columnName: '__betaalwijze' as any,
        width: 150,
      },
      {
        columnName: '__versturen' as any,
        width: 110,
      },
    ],
    [],
  );

  const kolomExtensies: VirtualTableBase.ColumnExtension[] = useMemo(() => {
    return [{ columnName: 'Bedrag', align: 'right' }];
  }, []);

  return (
    <>
      {facturen === null ? (
        <div className="d-flex flex-fill align-items-center justify-content-center">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          <Helmet>
            <title>Facturen Voorstel</title>
          </Helmet>
          <div
            className="d-flex flex-column p-3"
            style={{
              backgroundColor: Kleur.HeelLichtGrijs,
              borderBottom: `1px solid ${Kleur.LichtGrijs}`,
            }}
          >
            <div className="d-flex">
              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-2"
                style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                disabled={props.voorstelSelectie.length === 0}
                onClick={() => handleDefinitiefMaken(props.voorstelSelectie)}
              >
                {/* <IcoonToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} /> */}
                <span className="ml-2">Definitief maken</span>
              </button>
              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-2"
                style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                disabled={props.voorstelSelectie.length === 0}
                onClick={() => handleVerwijderen()}
              >
                {/* <IcoonToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} /> */}
                <span className="ml-2">Facturen uit voorstel verwijderen</span>
              </button>
            </div>

            <div className="mt-3 ml-3">
              <SelectieVak
                aantal={props.voorstelSelectie.length}
                totaalAantal={facturen.length}
                onChange={(alles) => {
                  if (alles) {
                    props.onVoorstelSelectieChange(facturen.map((x) => x.FactID));
                  } else {
                    props.onVoorstelSelectieChange([]);
                  }
                }}
                entiteitEnkelvoud="factuur"
                entiteitMeervoud="facturen"
              />
            </div>
          </div>
          <GridStyleWrapper height="calc(100vh - 150px)">
            <Grid columns={kolommen} getRowId={keyExtractor} rows={facturen!}>
              <DataTypeProvider
                for={['Factuurdatum']}
                formatterComponent={(props) => (
                  <span>{format(new Date(props.value), 'dd-MM-yyyy')}</span>
                )}
              />
              <DataTypeProvider
                for={['Vervaldatum']}
                formatterComponent={(props) => (
                  <span>{format(new Date(props.value), 'dd-MM-yyyy')}</span>
                )}
              />
              <DataTypeProvider
                for={['Bedrag']}
                formatterComponent={(props) => (
                  <FormatteerBedrag bedrag={props.value} rechtsUitlijnen />
                )}
              />
              <DataTypeProvider
                for={['Openstaand']}
                formatterComponent={(props) => <FormatteerBedrag bedrag={props.value} />}
              />
              <DataTypeProvider
                for={['__debiteur']}
                formatterComponent={(props) =>
                  props.row.debiteur.RelID !== null ? (
                    <RelatieVisualisatie relID={props.row.debiteur.RelID} />
                  ) : (
                    <span></span>
                  )
                }
              />

              <DataTypeProvider
                for={['__betaalwijze']}
                formatterComponent={(props) => props.row.betaalwijze.Naam}
              />

              <DataTypeProvider
                for={['__versturen']}
                formatterComponent={(formatterProps) => {
                  const rij: IOphalenFacturenBasisResultElement = formatterProps.row;
                  return <span>{rij.debiteur.OptieFactuurVersturen ? 'Ja' : 'Nee'}</span>;
                }}
              />

              <SortingState defaultSorting={[]} />
              <IntegratedSorting />
              <VirtualTable messages={geenData} columnExtensions={kolomExtensies} />

              <TableColumnResizing defaultColumnWidths={kolomBreedtes} />

              <SelectionState
                selection={props.voorstelSelectie}
                onSelectionChange={(value) => props.onVoorstelSelectieChange(value as number[])}
              />

              <TableHeaderRow showSortingControls />
              <RowDetailState defaultExpandedRowIds={[]} />
              <TableRowDetail
                contentComponent={FactuurregelsComp}
                toggleCellComponent={DXTableToggleCellComponent}
              />
              <TableSelection cellComponent={DXTableCheckboxComponent} />
            </Grid>
          </GridStyleWrapper>
        </>
      )}
    </>
  );
});

export default withRouter(Voorstel);
