import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import { DataTypeProvider, SelectionState } from '@devexpress/dx-react-grid';
import { format } from 'date-fns';
import { IEntiteitProps } from '../../../EntiteitContainer';
import { IOphalenBezoektijdenResultElement } from '../../../../../../../shared/src/api/v2/transport/bezoek';
import { observer } from 'mobx-react-lite';
import useUrlState from '../../../../../core/useUrlState';
import { RootStoreContext } from '../../../../../stores/RootStore';
import { TransportContext } from '../index';
import api from '../../../../../api';
import { EResultType } from '../../../../../stores/CheckStore';
import {
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../../helpers/dxTableGrid';
import LoadingSpinner from '../../../../Gedeeld/LoadingSpinner';
import MenuLayout from '../../../../MenuLayout';
import { Kleur } from '../../../../../bedrijfslogica/constanten';
import { IconKlok } from '../../../../Icons';
import BezoektijdenDialoog from '../../../../transport/UitstaandTabel/BezoektijdenDialoog';
import { IOphalenOpdrachtenResultElementV2 } from '../../../../../../../shared/src/api/v2/transport/opdracht';

interface IProps extends IEntiteitProps, RouteComponentProps {}

export interface IOphalenBezoektijdenDialoogState {}

export interface IUrlState {
  selectie: number[];
  ophalenBezoektijdenDialoogState: IOphalenBezoektijdenDialoogState | null;
}

export const defaultUrlState: IUrlState = { selectie: [], ophalenBezoektijdenDialoogState: null };

export interface IRegel extends IOphalenBezoektijdenResultElement {}

const Bezoektijden: React.FC<IProps> = observer((props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const { checkStore, instellingStore } = useContext(RootStoreContext);

  const transportContext = useContext(TransportContext);

  const [bezoektijden, setBezoektijden] = useState<IOphalenBezoektijdenResultElement[] | null>(
    null,
  );
  const [opdrachten, setOpdrachten] = useState<IOphalenOpdrachtenResultElementV2[] | null>(null);

  const ophalenBezoektijden = useCallback(async () => {
    const bezoekdatum = null; //format(new Date(), 'yyyy-MM-dd');
    const result = await api.v2.transport.bezoek.ophalenBezoektijden({
      filterSchema: {
        filters: [
          { naam: 'BEZOEKDATUM', data: bezoekdatum },
          { naam: 'REL_IDS', data: [transportContext.relID] },
        ],
      },
    });

    setBezoektijden(result.bezoektijden);
  }, []);

  useEffect(() => {
    ophalenBezoektijden();
  }, [ophalenBezoektijden]);

  const ophalenOpdrachten = useCallback(async () => {
    if (bezoektijden === null) {
      return;
    }
    const result = await api.v2.transport.opdracht.ophalenOpdrachtenV2({
      filterSchema: {
        filters: [{ naam: 'IDS', data: bezoektijden?.map((x) => x.TrsOpdID) }],
      },
    });

    setOpdrachten(result.opdrachten);
  }, [bezoektijden]);

  useEffect(() => {
    ophalenOpdrachten();
  }, [ophalenOpdrachten]);

  const handleBezoektijdenVoorSelectie = useCallback(async () => {
    const params = { trsOpdIDs: urlState.selectie };

    const checkData = await api.v2.transport.checkBijwerkenBezoektijden(params);

    const checkResult = await checkStore.controleren({
      checkData,
    });
    if (checkResult.type === EResultType.Annuleren) {
      return;
    }

    if (
      (
        await checkStore.bevestigen({
          inhoud: 'Bezoektijden voor de geselecteerde opdrachten ophalen?',
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    const result = await api.v2.transport.bijwerkenBezoektijden(params);

    // await checkStore.melden({
    //   titel: `Er zijn voor ${result.aantalOpdrachten} opdrachten tijden opgehaald`,
    // });

    ophalenBezoektijden();
  }, [urlState.selectie]);

  const handleVerwijderenBezoektijden = useCallback(async (trsOpdIDs: number[]) => {
    if (
      (
        await checkStore.bevestigen({
          inhoud: 'Bezoektijden wissen voor de geselecteerde opdrachten?',
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }
    await api.v2.transport.opdracht.verwijderenBezoektijden({ trsOpdIDs });

    await ophalenBezoektijden();
  }, []);

  const keyExtractor = useCallback((row: IRegel) => row.ID, []);

  const kolommen = useMemo<TypedColumn<IRegel>[]>(
    () => [
      {
        name: '__peildatum' as any,
        title: 'Peildatum',
      },
      {
        name: 'Opdrachtnummer',
        title: 'Opd.nr',
      },
      {
        name: '__adres' as any,
        title: 'Adres',
      },
      {
        name: '__bezoekdatum' as any,
        title: 'Bezoekdatum',
      },
      {
        name: 'BezoekdatumVan',
        title: 'Van',
      },
      {
        name: 'BezoekdatumTot',
        title: 'Tot',
      },
      //   {
      //     name: '__resultaat',
      //     title: ' ',
      //   },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRegel>[]>(
    () => [
      {
        columnName: '__peildatum' as any,
        width: 150,
      },
      {
        columnName: 'Opdrachtnummer',
        width: 85,
      },
      {
        columnName: '__adres' as any,
        width: 300,
      },
      {
        columnName: '__bezoekdatum' as any,
        width: 125,
      },
      {
        columnName: 'BezoekdatumVan' as any,
        width: 75,
      },
      {
        columnName: 'BezoekdatumTot' as any,
        width: 75,
      },
      //   {
      //     columnName: '__resultaat' as any,
      //     width: 200,
      //   },
    ],
    [],
  );

  return (
    <>
      {bezoektijden === null || opdrachten === null ? (
        <div className="flex-fill d-flex align-items-center justify-content-center">
          <LoadingSpinner />
        </div>
      ) : (
        <MenuLayout
          menu={
            <>
              <div className="d-flex">
                <button
                  className="btn btn-sm btn-light d-flex align-items-center ml-2"
                  style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                  disabled={false}
                  onClick={() => setUrlStateSync('ophalenBezoektijdenDialoogState', {})}
                >
                  <IconKlok style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                  <span className="ml-2">Bezoektijden ophalen</span>
                </button>

                {/* <button
                  className="btn btn-sm btn-light d-flex align-items-center ml-2"
                  style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                  disabled={urlState.selectie.length === 0}
                  onClick={() => {
                    const trsOpdIDs = bezoektijden
                      .filter((x) => urlState.selectie.indexOf(x.ID) !== -1)
                      .map((x) => x.TrsOpdID);

                    return handleVerwijderenBezoektijden(trsOpdIDs);
                  }}
                >
                  <IconVerwijderen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                  <span className="ml-2">Bezoektijden wissen</span>
                </button> */}

                {/* <button
                  className="btn btn-sm btn-light d-flex align-items-center"
                  style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                  onClick={() => setBezoektijdenDialoogTonen(true)}
                >
                  <IconKlok style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                  <span className="ml-2">Bezoektijden ophalen</span>
                </button>

                <button
                  className="btn btn-sm btn-light d-flex align-items-center ml-2"
                  style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                  disabled={urlState.selectie.length === 0}
                  onClick={() => {
                    handleVerwijderenOpdrachten();
                  }}
                >
                  <IconVerwijderen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                  <span className="ml-2">Verwijderen</span>
                </button>

                <button
                  className="btn btn-sm btn-light d-flex align-items-center ml-2"
                  style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                  disabled={true}
                  onClick={() => {}}
                >
                  <IconWebsite style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                  <span className="ml-2">Zoek op website</span>
                </button> */}
              </div>
            </>
          }
          body={
            <GridStyleWrapper height={'calc(100vh - 100px)'}>
              <Grid rows={bezoektijden} columns={kolommen} getRowId={keyExtractor}>
                <DataTypeProvider
                  for={['Opdrachtnummer']}
                  formatterComponent={(formatterProps) => {
                    const rij = formatterProps.row as IRegel;
                    const opdracht = opdrachten.find((x) => x.TrsOpdID === rij.TrsOpdID)!;

                    return <span>{opdracht.Opdrachtnummer}</span>;
                  }}
                />

                <DataTypeProvider
                  for={['__resultaat']}
                  formatterComponent={(formatterProps) => {
                    const rij = formatterProps.row as IRegel;
                    return (
                      <span>
                        {rij.BezoekdatumVan === null ? (
                          <span style={{ color: Kleur.Rood }}>Geen tijden gevonden</span>
                        ) : (
                          ''
                        )}
                      </span>
                    );
                  }}
                />

                <DataTypeProvider
                  for={['__adres']}
                  formatterComponent={(formatterProps) => {
                    const rij = formatterProps.row as IRegel;
                    const locatie =
                      rij.locatie.Straatnaam +
                      ' ' +
                      rij.locatie.Huisnummer +
                      (rij.locatie.Bisnummer !== null ? ' ' + rij.locatie.Bisnummer : '') +
                      ', ' +
                      rij.locatie.Postcode +
                      ' ' +
                      rij.locatie.Plaatsnaam;

                    return <span>{locatie}</span>;
                  }}
                />

                <DataTypeProvider
                  for={['__bezoekdatum']}
                  formatterComponent={(formatterProps) => {
                    const row = formatterProps.row as IRegel;
                    return (
                      <span>
                        {row.Bezoekdatum === null
                          ? ''
                          : format(new Date(row.Bezoekdatum), 'dd-MM-yyyy')}
                      </span>
                    );
                  }}
                />

                <DataTypeProvider
                  for={['BezoekdatumVan', 'BezoekdatumTot']}
                  formatterComponent={(formatterProps) => {
                    return (
                      <span>
                        {formatterProps.value === null
                          ? '- -'
                          : format(new Date(formatterProps.value), 'HH:mm')}
                      </span>
                    );
                  }}
                />

                <DataTypeProvider
                  for={['__peildatum']}
                  formatterComponent={(formatterProps) => {
                    const row = formatterProps.row as IRegel;
                    return (
                      <span>
                        {row.Peildatum === null
                          ? ''
                          : format(new Date(row.Peildatum), 'dd-MM-yyyy HH:mm')}
                      </span>
                    );
                  }}
                />

                {/* <DataTypeProvider
                  for={['__relaties']}
                  formatterComponent={(formatterProps) => {
                    const row = formatterProps.row as IRegel;
                    if (row.relIDs.length === 0) {
                      return <span />;
                    }
                    const maxRelatiesWeergeven = 2;
                    const heeftMeerDanMaxAantalRelaties = row.relIDs.length > maxRelatiesWeergeven;

                    return (
                      <span className="d-flex">
                        {row.relIDs
                          .map((relID) => <RelatieVisualisatie relID={relID} />)
                          .reduce((acc: Array<JSX.Element | null>, curr: JSX.Element, i) => {
                            return [
                              ...acc,
                              curr,
                              i === row.relIDs.length - 1 ? null : (
                                <span key={i}>
                                  ,<span className="ml-2" />
                                </span>
                              ),
                            ];
                          }, [])}
                        {heeftMeerDanMaxAantalRelaties ? <span>,&nbsp;...</span> : null}
                      </span>
                    );
                  }}
                /> */}

                {/* <SelectionState
                  selection={urlState.selectie}
                  onSelectionChange={(value) => setUrlStateSync('selectie', value as number[])}
                /> */}
                {/* <RowDetailState
                expandedRowIds={urlState.uitgeklapteOpdrachten}
                onExpandedRowIdsChange={(value) =>
                  setUrlStateSync('uitgeklapteOpdrachten', value as number[])
                }
              /> */}

                <VirtualTable />
                <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
                <TableHeaderRow />
                {/* <TableRowDetail
                toggleCellComponent={DXTableToggleCellComponent}
                contentComponent={Regels}
              /> */}
                {/* <TableSelection cellComponent={DXTableCheckboxComponent} /> */}
              </Grid>
            </GridStyleWrapper>
          }
        />
      )}
      {urlState.ophalenBezoektijdenDialoogState && (
        <BezoektijdenDialoog
          open
          onSuccess={() => {
            setUrlStateSync('ophalenBezoektijdenDialoogState', null);
            ophalenBezoektijden();
          }}
          onAnnuleren={() => setUrlStateSync('ophalenBezoektijdenDialoogState', null)}
          relIDs={[transportContext.relID]}
        />
      )}
    </>
  );
});

export default withRouter(Bezoektijden);
