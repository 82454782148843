import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { RootStoreContext } from '../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import { ERemoteDataState } from '../../../models/IRemoteData';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { genereerUrlStateQueryParam } from '../../../core/useUrlState';
// import { Kleur } from '../../../bedrijfslogica/constanten';
// import InfoCard from './InfoCard';
// import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import {
  defaultBeheerServiceDienstUrlState,
  IBeheerServiceDienstUrlState,
} from '../../../paginas/Diensten/Beheer/Service';
import RelatieVisualisatie from '../../personalia/RelatieVisualisatie';

interface IProps extends RouteComponentProps {
  servDienstID: number;
}

const BankopdrachtVisualisatie: React.FC<IProps> = observer((props) => {
  const { servicedienstStore } = useContext(RootStoreContext);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (servicedienstStore.servicediensten[props.servDienstID] === undefined) {
      // noinspection JSIgnoredPromiseFromCall
      servicedienstStore.enqueueOphalenServicediensten([props.servDienstID]);
    }
  }, [props.servDienstID]);

  const servicedienst = useMemo(() => {
    return servicedienstStore.servicediensten[props.servDienstID] ?? null;
  }, [props.servDienstID, servicedienstStore.servicediensten]);

  // const [showInfoCardOverlay, setShowInfoCardOverlay] = useState(false);
  // const showInfoCardOverlayTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  // const handleMouseEnterInfoCardOverlay = useCallback(() => {
  //   if (showInfoCardOverlayTimeoutRef.current !== null) {
  //     clearTimeout(showInfoCardOverlayTimeoutRef.current);
  //   }
  //   showInfoCardOverlayTimeoutRef.current = setTimeout(() => setShowInfoCardOverlay(true), 800);
  // }, []);
  // const handleMouseLeaveInfoCardOverlay = useCallback(() => {
  //   if (showInfoCardOverlayTimeoutRef.current !== null) {
  //     clearTimeout(showInfoCardOverlayTimeoutRef.current);
  //   }
  //   showInfoCardOverlayTimeoutRef.current = setTimeout(() => setShowInfoCardOverlay(false), 400);
  // }, []);
  // const renderInfoCardOverlay = useCallback(
  //   (p: any) => {
  //     return (
  //       <div
  //         id={`bankopdracht-visualisatie-overlay-${props.servDienstID}`}
  //         {...p}
  //         onMouseEnter={handleMouseEnterInfoCardOverlay}
  //         onMouseLeave={handleMouseLeaveInfoCardOverlay}
  //         style={{
  //           ...p.style,
  //           zIndex: 99999,
  //           border: `1px solid ${Kleur.LichtGrijs}`,
  //           borderRadius: 5,
  //         }}
  //       >
  //         <InfoCard bankOpdID={props.servDienstID} />
  //       </div>
  //     );
  //   },
  //   [props.servDienstID, handleMouseEnterInfoCardOverlay, handleMouseLeaveInfoCardOverlay],
  // );

  if (servicedienst === null || servicedienst.state === ERemoteDataState.Pending) {
    return <span>Laden...</span>;
  }

  return (
    <div
      className="d-flex align-items-center"
      style={{
        verticalAlign: 'middle',
      }}
    >
      <RelatieVisualisatie relID={servicedienst.data!.RelID} />
      <a
        href="#"
        className="ml-2"
        style={{
          fontSize: 12,
          verticalAlign: 'middle',
        }}
        // onMouseEnter={() => {
        //   setIsHovered(true);
        //   handleMouseEnterInfoCardOverlay();
        // }}
        // onMouseLeave={() => {
        //   setIsHovered(false);
        //   handleMouseLeaveInfoCardOverlay();
        // }}
        onClick={(ev) => {
          ev.preventDefault();

          const urlState: IBeheerServiceDienstUrlState = {
            ...defaultBeheerServiceDienstUrlState,
            selectie: [servicedienst.data!.ServDienstID],
          };
          const query = genereerUrlStateQueryParam(urlState);

          props.history.push(`/service/diensten?state=${query}`);
        }}
      >
        [<span>{servicedienst.data?.Naam ?? 'dienst'}</span>]
      </a>
    </div>
  );

  // return (
  //   // <OverlayTrigger
  //   //   trigger="hover"
  //   //   placement="auto-start"
  //   //   overlay={renderInfoCardOverlay}
  //   //   show={showInfoCardOverlay}
  //   //   popperConfig={
  //   //     {
  //   //       modifiers: {
  //   //         name: 'offset',
  //   //         options: {
  //   //           offset: [0, 5],
  //   //         },
  //   //       },
  //   //     } as any
  //   //   }
  //   // >
  //   <a
  //     href="#"
  //     className="d-flex align-items-center"
  //     // onMouseEnter={() => {
  //     //   setIsHovered(true);
  //     //   handleMouseEnterInfoCardOverlay();
  //     // }}
  //     // onMouseLeave={() => {
  //     //   setIsHovered(false);
  //     //   handleMouseLeaveInfoCardOverlay();
  //     // }}
  //     onClick={(ev) => {
  //       ev.preventDefault();
  //
  //       const urlState: IBeheerServiceDienstUrlState = {
  //         ...defaultBeheerServiceDienstUrlState,
  //         selectie: [servicedienst.data!.ServDienstID],
  //       };
  //       const query = genereerUrlStateQueryParam(urlState);
  //
  //       props.history.push(`/service/diensten?state=${query}`);
  //     }}
  //   >
  //     <span>{servicedienst.data!.Naam}</span>
  //   </a>
  //   // </OverlayTrigger>
  // );
});

export default withRouter(BankopdrachtVisualisatie);
