import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import IDialoogProps from '../../../../../../../../core/IDialoogProps';
import Dialoog from '../../../../../../../../components/dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import LoadingSpinner from '../../../../../../../../components/Gedeeld/LoadingSpinner';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import * as Yup from 'yup';
import api from '../../../../../../../../api';
import nameof from '../../../../../../../../core/nameOf';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../../../../../../stores/RootStore';
import Combobox from '../../../../../../../../components/formulier/Combobox';
import {
  IOphalenProceduresResultElement,
  IOphalenProcedureStatussenResultElement,
} from '../../../../../../../../../../shared/src/api/v2/relatie/acceptatie';
import { EResultType } from '../../../../../../../../stores/CheckStore';
import { EProcedureStatus } from '../../../../../../../../bedrijfslogica/enums';
interface IProps extends IDialoogProps<{}> {
  id: number;
}

interface IFormikValues {
  status: number;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  status: 'Status',
};

const WijzigenStatusDialoog: React.FC<IProps> = observer((props) => {
  const { dialoogIndex, onAnnuleren, onSuccess } = props;
  const { checkStore } = useContext(RootStoreContext);
  const formikRef = useRef<Formik<IFormikValues>>(null);

  const [procedure, setProcedure] = useState<IOphalenProceduresResultElement | null>(null);

  const [statussen, setStatussen] = useState<IOphalenProcedureStatussenResultElement[] | null>(
    null,
  );

  const ophalenProcedure = useCallback(async () => {
    const result = await api.v2.relatie.acceptatie.ophalenProcedures({
      filterSchema: { filters: [{ naam: 'IDS', data: [props.id] }] },
    });
    setProcedure(result.procedures[0]);
  }, [props.id]);

  useEffect(() => {
    ophalenProcedure();
  }, [ophalenProcedure]);

  const ophalenStatussen = useCallback(async () => {
    const result = (await api.v2.relatie.acceptatie.ophalenProcedureStatussen({})).statussen;
    setStatussen(result);
  }, []);

  useEffect(() => {
    ophalenStatussen();
  }, [ophalenStatussen]);

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (procedure === null) {
      return null;
    }

    return {
      status: procedure.status.ID,
    };
  }, [procedure]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      if (procedure === null || statussen === null) {
        return;
      }

      actions.setSubmitting(true);

      const statusNaamEnum = statussen.find((x) => x.ID === values.status)!.NaamEnum;

      if (
        statusNaamEnum === EProcedureStatus.Geaccepteerd &&
        procedure.documenten.some((x) => !x.Ontvangen)
      ) {
        const checkData = {
          errors: [],
          warnings: ['Eén of meer documenten zijn nog niet ontvangen'],
        };

        if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
          actions.setSubmitting(false);
          return;
        }
      }

      const params = {
        id: props.id,
        status: values.status,
      };
      await api.v2.relatie.acceptatie.wijzigenProcedure(params);

      actions.setSubmitting(false);
      onSuccess({});
    },
    [props.id, procedure, statussen],
  );

  const validationSchema = useMemo(() => {
    const fields: Record<keyof IFormikValues, any> = {
      status: Yup.number().required(),
    };
    return Yup.object().shape(fields);
  }, []);

  return (
    <Dialoog index={dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Wijzigen status</ModalTitle>
      </ModalHeader>
      {initialValues === null ? (
        <LoadingSpinner />
      ) : (
        <Formik<IFormikValues>
          ref={formikRef}
          onSubmit={handleSubmit}
          initialValues={initialValues}
          isInitialValid
          validationSchema={validationSchema}
          render={(formikProps: FormikProps<IFormikValues>) => {
            const { submitForm, isSubmitting, isValid } = formikProps;
            return (
              <>
                <ModalBody>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-12 mt-3">
                        <label>{veldnamen.status}</label>
                        <Field
                          name={nameof<IFormikValues>('status')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            if (statussen === null) {
                              return <LoadingSpinner />;
                            }

                            return (
                              <Combobox
                                geselecteerd={field.value}
                                onSelectieChange={(x) => form.setFieldValue(field.name, x)}
                                opties={statussen.map((x) => {
                                  return {
                                    id: x.ID,
                                    label: x.Naam,
                                  };
                                })}
                              />
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </ModalBody>

                <ModalFooter className="d-flex flex-row justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    style={{ width: 100 }}
                    disabled={isSubmitting || !isValid}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={onAnnuleren}
                    style={{ width: 100 }}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Dialoog>
  );
});

export default WijzigenStatusDialoog;
