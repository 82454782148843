import React from 'react';
import { IFormikValues, veldnamen } from '../index';
import { Field, FieldProps } from 'formik';
import nameOf from '../../../../../../../../core/nameOf';
import MeertaligTekstveld from '../../../../../../../../components/formulier/MeertaligTekstveld';

const TekstenTabblad = () => {
  return (
    <div className="row p-3">
      <div className="col-12">
        <label>{veldnamen.titel}</label>
        <Field
          name={nameOf<IFormikValues>('titel')}
          render={(fieldProps: FieldProps<IFormikValues>) => {
            const { field, form } = fieldProps;

            return (
              <MeertaligTekstveld
                waarden={field.value}
                onChange={(x) => form.setFieldValue(field.name, x)}
              />
            );
          }}
        />
      </div>

      <div className="col-12 mt-3">
        <label>{veldnamen.inhoud}</label>
        <Field
          name={nameOf<IFormikValues>('inhoud')}
          render={(fieldProps: FieldProps<IFormikValues>) => {
            const { field, form } = fieldProps;

            return (
              <MeertaligTekstveld
                waarden={field.value}
                onChange={(x) => form.setFieldValue(field.name, x)}
                inputComponent="multiline"
                multilineConfig={{
                  rows: 10,
                }}
              />
            );
          }}
        />
      </div>

      <div className="col-12 mt-3">
        <label>{veldnamen.toegangscodeInhoud}</label>
        <Field
          name={nameOf<IFormikValues>('toegangscodeInhoud')}
          render={(fieldProps: FieldProps<IFormikValues>) => {
            const { field, form } = fieldProps;

            return (
              <MeertaligTekstveld
                waarden={field.value}
                onChange={(x) => form.setFieldValue(field.name, x)}
              />
            );
          }}
        />
      </div>
    </div>
  );
};

export default TekstenTabblad;
