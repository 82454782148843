import React from 'react';
import IDialoogProps from '../../../../../core/IDialoogProps';
import Dialoog from '../../../../dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/esm/ModalHeader';
import { ModalTitle, ModalBody, ModalFooter } from 'react-bootstrap';

export enum EBoekingAlsnogDefinitiefMakenKeuze {
  Ja,
  Nee,
}

interface IProps extends IDialoogProps<EBoekingAlsnogDefinitiefMakenKeuze> {}

const BoekingAlsnogDefinitiefMakenDialoog = (props: IProps) => {
  return (
    <Dialoog index={props.dialoogIndex ?? 0}>
      <ModalHeader>
        <ModalTitle>Boeking alsnog definitief maken?</ModalTitle>
      </ModalHeader>
      <ModalBody style={{ padding: '2rem', fontSize: 17 }}>
        <p>Deze boeking kan definitief worden gemaakt, echter heb je dit niet aangegeven.</p>
        <p>Wil je deze boeking alsnog definitief maken?</p>
      </ModalBody>
      <ModalFooter className="d-flex flex-row justify-content-start">
        <button
          className="btn btn-primary d-flex align-items-center justify-content-center"
          onClick={() => props.onSuccess(EBoekingAlsnogDefinitiefMakenKeuze.Ja)}
          style={{ width: 100 }}
        >
          Ja
        </button>
        <button
          className="btn btn-secondary d-flex align-items-center justify-content-center"
          onClick={() => props.onSuccess(EBoekingAlsnogDefinitiefMakenKeuze.Nee)}
          style={{ width: 100 }}
        >
          Nee
        </button>
        <div className="flex-fill" />
        <a href="#" onClick={props.onAnnuleren}>
          Annuleren
        </a>
      </ModalFooter>
    </Dialoog>
  );
};

export default BoekingAlsnogDefinitiefMakenDialoog;
