import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IUitgeklapteInhoudProps } from '../..';
import {
  IOphalenPotentieelOntvangenTransportregelAfleverbewijsItemsResult,
  IPotentieelOntvangenTransportopdrachtAfleverbewijsItem,
  IPotentieelOntvangenTransportregelAfleverbewijsItem,
} from '../../../../../../shared/src/api/v2/externeData';
import api from '../../../../api';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../models/IRemoteData';
import GegevensLayout from '../../../../components/layout/GegevensLayout';
import { formatteerTypeOpdracht } from '../../weergaven/TransportopdrachtAfleverbewijsWeergave';
import { formatteerAdresV2 } from '../../../../helpers';
import ASPTabel from '../../../../components/tabel/ASPTabel';
import { ASPKolom, EAspKolomBreedteType } from '../../../../components/tabel/ASPTabel/types';

enum EKolom {
  Soort,
  IsOmruil,
  Type,
  Aantal,
  Referentiecode,
}

const TransportopdrachtAfleverbewijsUitgeklapteInhoud = <TRow extends any>(
  props: IUitgeklapteInhoudProps<TRow, IPotentieelOntvangenTransportopdrachtAfleverbewijsItem>,
) => {
  const [regelsResult, setRegelsResult] = useState<
    IRemoteData<IOphalenPotentieelOntvangenTransportregelAfleverbewijsItemsResult>
  >(createPendingRemoteData());

  useEffect(() => {
    api.v2.externeData
      .ophalenPotentieelOntvangenTransportregelAfleverbewijsItems({
        filterSchema: {
          filters: [
            {
              naam: 'POTENTIEEL_ONTV_ITEM_TRS_OPD_AFLEVERBEWIJS_IDS',
              data: [props.item.ID],
            },
          ],
        },
      })
      .then((result) => setRegelsResult(createReadyRemoteData(result)));
  }, []);

  const rijenData = useMemo(() => {
    if (regelsResult.state === ERemoteDataState.Pending) {
      return {
        totaalAantal: 5,
        rijen: {},
      };
    }
    return {
      totaalAantal: regelsResult.data!.totaalAantal,
      rijen: regelsResult.data!.items.reduce((acc, curr, i) => ({ ...acc, [i]: curr }), {}),
    };
  }, [regelsResult]);

  const keyExtractor = useCallback(
    (item: IPotentieelOntvangenTransportregelAfleverbewijsItem) => item.ID,
    [],
  );

  const kolommen = useMemo<ASPKolom<EKolom, IPotentieelOntvangenTransportregelAfleverbewijsItem>[]>(
    () => [
      {
        key: EKolom.Soort,
        label: 'Soort',
        renderer: (item) => item.Soort,
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 75,
      },
      {
        key: EKolom.IsOmruil,
        label: 'Omruil',
        renderer: (item) => (item.IsOmruil ? 'Ja' : 'Nee'),
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 100,
      },
      {
        key: EKolom.Type,
        label: 'Type',
        renderer: (item) => 'TODO',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 150,
      },
      {
        key: EKolom.Aantal,
        label: 'Aantal',
        renderer: (item) => item.Aantal,
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 100,
      },
      {
        key: EKolom.Referentiecode,
        label: 'Referentiecode',
        renderer: (item) => item.Referentiecode,
        breedteType: EAspKolomBreedteType.Flex,
        flex: 1,
      },
    ],
    [],
  );

  return (
    <div className="d-flex flex-column">
      <GegevensLayout
        gegevens={[
          {
            label: 'Type opdracht',
            waarde: formatteerTypeOpdracht(props.item.TypeOpdracht),
          },
          {
            label: 'Adres',
            waarde: formatteerAdresV2({
              bisnummer: props.item.Bisnummer,
              huisnummer: Number(props.item.Huisnummer),
              postcode: props.item.Postcode,
              straatnaam: props.item.Straatnaam,
              plaatsnaam: props.item.Plaatsnaam,
            }),
          },
          {
            label: 'Aankomsttijd',
            waarde: props.item.Aankomsttijd,
          },
          {
            label: 'Vertrektijd',
            waarde: props.item.Aankomstvertrek,
          },
        ]}
      />
      <div className="d-flex w-100 mt-3" style={{ height: 200 }}>
        <ASPTabel
          rijen={rijenData.rijen}
          kolommen={kolommen}
          keyExtractor={keyExtractor}
          totaalAantalRijen={rijenData.totaalAantal}
        />
      </div>
    </div>
  );
};

export default TransportopdrachtAfleverbewijsUitgeklapteInhoud;
