import { RouteComponentProps, NavLink, Route } from 'react-router-dom';
import React from 'react';
import PaginaHeader from '../../PaginaHeader';
import { CommunicatieNavigatie } from '../../Communicatie/styles';
import { IconContact, IconEmail, IconLijst } from '../../../components/Icons';
import { Redirect } from 'react-router';
import Overzicht from './Overzicht';
import Berichten from './Berichten';

interface IProps extends RouteComponentProps {}

const Bulkverzender = (props: IProps) => {
  const { match } = props;
  return (
    <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
      <PaginaHeader>
        <CommunicatieNavigatie>
          <li>
            <NavLink
              to={`${match.url}/overzicht`}
              style={{ paddingLeft: 20, paddingRight: 20 }}
              className="d-flex align-items-center justify-content-center"
            >
              <IconLijst />
              &nbsp; Overzicht
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`${match.url}/berichten`}
              style={{ paddingLeft: 20, paddingRight: 20 }}
              className="d-flex align-items-center justify-content-center"
            >
              <IconEmail />
              &nbsp; Berichten
            </NavLink>
          </li>
        </CommunicatieNavigatie>
      </PaginaHeader>

      <Route path={`${match.path}/overzicht`} component={Overzicht} />
      <Route path={`${match.path}/berichten`} component={Berichten} />

      <Route exact path={match.path} render={() => <Redirect to={`${match.path}/overzicht`} />} />
    </div>
  );
};

export default Bulkverzender;
