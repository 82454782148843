import api from '../../..';
import {
  IOphalenUrlsViaPaginadataParams,
  IOphalenUrlsViaPaginadataResult,
  IOphalenWebPaginasParams,
  IOphalenWebPaginasResult,
  IWijzigenWebPaginaParams,
  IWijzigenWebPaginaResult,
} from '../../../../../../shared/src/api/v2/website/pagina';

const pagina = {
  ophalenWebPaginas: async (
    params: IOphalenWebPaginasParams,
  ): Promise<IOphalenWebPaginasResult> => {
    return await api.post('/v2/extern/pagina/ophalen-web-paginas', params);
  },
  wijzigenWebPagina: async (
    params: IWijzigenWebPaginaParams,
  ): Promise<IWijzigenWebPaginaResult> => {
    return await api.post('/v2/extern/pagina/wijzigen-web-pagina', params);
  },
  ophalenUrlsViaPaginadata: async (
    params: IOphalenUrlsViaPaginadataParams,
  ): Promise<IOphalenUrlsViaPaginadataResult> => {
    return await api.post('/v2/extern/pagina/ophalen-urls-via-paginadata', params);
  },
};

export default pagina;
