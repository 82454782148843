import React, { useContext, useMemo } from 'react';
import { DashboardContext } from '../index';
import TegelBasis from '../../../components/tegel/TegelBasis';

interface IProps {}

const Bijzonderheden: React.FC<IProps> = (props) => {
  const context = useContext(DashboardContext);

  const aanmaningRunIsNietActief = context.plannertaken.data!.taken.find(
    (x) => x.NaamEnum === 'AANMANING_RUN' && !x.Actief,
  );

  const instellingen = context.instellingen.data!;

  const premiums = context.premiums
    .data!.filter((x) => x.OpWebsite)
    .map((x) => {
      return x.Naam;
    })
    .join(', ');

  const lijst = (
    <>
      {premiums.length !== 0 && <li>Premiums op website: {premiums}</li>}
      {!instellingen.website.InloggenToegestaan && (
        <li>Inloggen op de website is niet toegestaan</li>
      )}
      {aanmaningRunIsNietActief && (
        <li>Aanmaningen worden thans niet automatisch gemaakt en verstuurd</li>
      )}
    </>
  );

  return (
    <TegelBasis titel="Bijzonderheden">
      <div>
        <ul>{lijst !== <></> ? lijst : <span>Er zijn geen meldingen</span>}</ul>
      </div>
    </TegelBasis>
  );
};

export default Bijzonderheden;
