import React, { useContext, useEffect, useMemo } from 'react';
import api from '../../../../api';
import { observer } from 'mobx-react-lite';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Kleur as EKleur } from '../../../../bedrijfslogica/constanten';
import { EResultType } from '../../../../stores/CheckStore';
import { RootStoreContext } from '../../../../stores/RootStore';
import { IOphalenOpdrachtregelsResultElementV2 } from '../../../../../../shared/src/api/v2/transport/opdracht';
import { ERegelstatusTransport } from '../../../../bedrijfslogica/enums';
import { IconPijlVolgend } from '../../../Icons';

interface IRegelRow extends IOphalenOpdrachtregelsResultElementV2 {}

interface IProps extends RouteComponentProps {
  rij: IRegelRow;
}

const ReferentieProductComp: React.FC<IProps> = observer((props) => {
  const { checkStore } = useContext(RootStoreContext);

  const rij: IRegelRow = props.rij;

  if (rij.type !== null) {
    if (rij.type.Bulkproduct) {
      return <span>Bulk</span>;
    }

    if (rij.status.Status === ERegelstatusTransport.UITGEVOERD) {
      return (
        <span>
          <IconPijlVolgend style={{ width: 15, height: 15, fill: EKleur.Grijs }} />
          {rij.Referentiecode !== null ? rij.Referentiecode : '?'}
        </span>
      );
    }
  }

  if (rij.product !== null) {
    return <span>{rij.product.Referentiecode !== null ? rij.product.Referentiecode : '?'}</span>;
  }

  return <span></span>;
});

export default withRouter(ReferentieProductComp);
