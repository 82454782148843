import React, { useCallback, useEffect, useMemo, useState } from 'react';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../../models/IRemoteData';
import api from '../../../../../api';
import {
  ASPKolom,
  EAspKolomBreedteType,
  ESortering,
  ESorteringModus,
  IAspKolomSorteringItem,
} from '../../../../tabel/ASPTabel/types';
import TableData, { ITableDataProps } from '../../../../tabel/ASPTabel/Body/TableData';
import ASPTabel from '../../../../tabel/ASPTabel';
import {
  IOphalenGrootboekenResult,
  IOphalenGrootboekenResultElement,
} from '../../../../../../../shared/src/api/v2/boekhouding/boeking/grootboek';
import { ITabbladProps } from '../index';
import LoadingSpinner from '../../../../Gedeeld/LoadingSpinner';

enum ETabelKolom {
  Nummer,
  Naam,
  Omschrijving,
  KiesActie,
}

interface IProps extends ITabbladProps {}

const MeestGebruiktTabblad = (props: IProps) => {
  const [resultaat, setResultaat] = useState<IRemoteData<IOphalenGrootboekenResult>>(
    createPendingRemoteData(),
  );
  const ophalenGrootboeken = useCallback(async () => {
    const result = await api.v2.boeking.grootboek.ophalenGrootboeken({
      filterSchema: {
        filters: [
          {
            naam: 'MEEST_GEBRUIKT_SORTNR',
            data: null,
            inverteren: true,
          },
          { naam: 'IS_ACTIEF', data: true },
        ],
      },
      orderSchema: {
        orders: [
          // {
          //   naam: 'MEEST_GEBRUIKT_SORTNR',
          //   richting: 'ASC',
          // },
          {
            naam: 'NAAM',
            richting: 'ASC',
          },
        ],
      },
    });

    setResultaat(createReadyRemoteData(result));
  }, []);
  useEffect(() => {
    ophalenGrootboeken();
  }, [ophalenGrootboeken]);

  const tabelRijen = useMemo<Record<number, IOphalenGrootboekenResultElement> | null>(() => {
    if (resultaat.data === null) {
      return null;
    }

    return resultaat.data.grootboeken.reduce((acc, grootboek, i) => {
      return {
        ...acc,
        [i]: grootboek,
      };
    }, {});
  }, [resultaat.data]);

  const tabelKolommen = useMemo<Array<ASPKolom<ETabelKolom, IOphalenGrootboekenResultElement>>>(
    () => [
      {
        key: ETabelKolom.Nummer,
        label: 'Nummer',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 100,
        renderer: (row) => row.Nummer.toString(),
      },
      {
        key: ETabelKolom.Naam,
        label: 'Naam',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 250,
        renderer: (row) => row.Naam,
      },
      {
        key: ETabelKolom.Omschrijving,
        label: 'Omschrijving',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 300,
        renderer: (row) => row.Omschrijving,
      },
      {
        key: ETabelKolom.KiesActie,
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 95,
        renderer: (row) => (
          <a
            href="#"
            onClick={(ev) => {
              ev.preventDefault();
              props.onGrootboekIDGeselecteerd(row.ID);
            }}
          >
            Kies
          </a>
        ),
      },
    ],
    [props.onGrootboekIDGeselecteerd],
  );

  const [sortering, setSortering] = useState<IAspKolomSorteringItem<ETabelKolom>[]>([
    {
      key: ETabelKolom.Naam,
      sortering: ESortering.Descending,
    },
    // {
    //   key: ETabelKolom.Nummer,
    //   sortering: ESortering.Ascending,
    // },
  ]);

  const TdComponent = useMemo<
    React.ComponentType<ITableDataProps<ETabelKolom, IOphalenGrootboekenResultElement>>
  >(
    () => (rowProps) => {
      return (
        <TableData
          {...rowProps}
          onDoubleClick={
            rowProps.row === undefined
              ? undefined
              : (ev) => {
                  props.onGrootboekIDGeselecteerd(rowProps.row!.ID);
                }
          }
          style={rowProps.row === undefined ? undefined : { cursor: 'pointer' }}
        >
          {rowProps.children}
        </TableData>
      );
    },
    [props.onGrootboekIDGeselecteerd],
  );

  return (
    <div className="d-flex flex-fill flex-column">
      <div style={{ height: 500 }} className="d-flex flex-column">
        {resultaat.state === ERemoteDataState.Pending ? (
          <div className="d-flex flex-fill align-items-center justify-content-center">
            <LoadingSpinner />
          </div>
        ) : (
          <ASPTabel
            keyExtractor={(row) => row.ID}
            rijen={tabelRijen!}
            kolommen={tabelKolommen}
            totaalAantalRijen={resultaat.data!.grootboeken.length}
            tdComponent={TdComponent}
            sortering={sortering}
            onSorteringChange={setSortering}
            sorteringOpties={{
              modus: ESorteringModus.Whitelist,
              kolomOpties: {
                [ETabelKolom.Naam]: {
                  magSorteren: true,
                },
                [ETabelKolom.Nummer]: {
                  magSorteren: true,
                },
              },
            }}
            lokaalSorteren
          />
        )}
      </div>
    </div>
  );
};
export default MeestGebruiktTabblad;
