import React, { useMemo } from 'react';
import Tabblad, { ITabblad } from '../../../../../components/layout/Tabblad';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import OverzichtDetailComp from './Overzicht';
import useBepaalUrlTabblad from '../../../../../core/useUrlTabblad';

interface IProps extends RouteComponentProps {
  relID: number;
}

export enum ETabblad {
  Overzicht = 1,
  // Vorderingen = 2,
  // Aanmaningen = 3,
  // Prolongaties = 4,
  // Bankopdrachten = 5,
}

const pathMap: Record<ETabblad, string> = {
  [ETabblad.Overzicht]: '/overzicht',
};

const FacturenComp: React.FC<IProps> = (props) => {
  const { tabblad, onChange: onChangeTabblad } = useBepaalUrlTabblad<ETabblad>(
    props,
    pathMap,
    ETabblad.Overzicht,
  );

  // const tabblad = useMemo<ETabblad | null>(() => {
  //   const matchKey = Object.keys(pathMap).find((key) => {
  //     const path = pathMap[Number(key) as ETabblad]!;
  //     return props.location.pathname.endsWith(path);
  //   });
  //   if (matchKey === undefined) {
  //     props.history.push(`${props.match.path}${pathMap[ETabblad.Overzicht]}`);
  //     return null;
  //   }
  //   return Number(matchKey) as ETabblad;
  // }, [props.location.pathname]);

  const tabbladen = useMemo<ITabblad<ETabblad>[]>(
    () => [
      {
        id: ETabblad.Overzicht,
        label: 'Overzicht',
        content: () => <OverzichtDetailComp relID={props.relID} />,
      },
      // {
      //   id: ETabblad.Vorderingen,
      //   label: 'Vorderingen',
      //   content: () => <VorderingenDetailComp />,
      // },
      // {
      //   id: ETabblad.Aanmaningen,
      //   label: 'Aanmaningen',
      //   content: () => <span>Aanmaningen</span>,
      // },
      // {
      //   id: ETabblad.Prolongaties,
      //   label: 'Prolongaties',
      //   content: () => <ProlongatiesDetailComp />,
      // },
      // {
      //   id: ETabblad.Bankopdrachten,
      //   label: 'Bankopdrachten',
      //   content: () => <BankopdrachtenDetailComp />,
      // },
    ],
    [props.relID],
  );

  return (
    <div className="d-flex flex-column flex-fill flex-fill">
      <div>
        {tabblad !== null && (
          <Tabblad<ETabblad>
            geselecteerd={tabblad}
            onSelectieChange={(tab) => onChangeTabblad(tab)}
            tabbladen={tabbladen}
          />
        )}
      </div>
    </div>
  );
};

const Facturen = withRouter(FacturenComp);
export default Facturen;
