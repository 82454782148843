import React, { useCallback, useMemo } from 'react';
import MultiComboboxV2MetZoeken, {
  IMultiComboboxV2MetZoekenOverlayState,
} from '../../../../../../components/formulier/MultiComboboxV2/MetZoeken';
import {
  IRepresentatieProps,
  Provider,
} from '../../../../../../components/formulier/MultiComboboxV2';
import api from '../../../../../../api';
import { IFilterSchemaFilter } from '../../../../../../../../shared/src/models/filter';
import { ASPKolom, EAspKolomBreedteType } from '../../../../../../components/tabel/ASPTabel/types';
import { ITekstPrecisieData } from '../../../../../../../../shared/src/api/sql';
import { IOntvangersselectie } from '../../../../../../../../shared/src/api/v2/bulkbericht/ontvangersselectie';

type Kolom = 'naam' | 'omschrijving';

interface IProps {
  ontvangerssoortID: number;
  ontvangersselectieID: number | null;
  onOntvangersselectieIDChange: (voorkeurID: number | null) => void;
}

const OntvangersselectieSelectie = (props: IProps) => {
  const provider = useMemo<
    Provider<Kolom, IOntvangersselectie, IMultiComboboxV2MetZoekenOverlayState>
  >(
    () => async (params) => {
      const filters: IFilterSchemaFilter[] = [
        {
          naam: 'ONTVANGERSSOORT_IDS',
          data: [props.ontvangerssoortID],
        },
      ];

      const zoekterm = params.overlayContainerState.zoekterm.trim();
      if (zoekterm.length > 0) {
        filters.push({
          naam: 'NAAM_UITGEBREID',
          data: {
            precisie: 'ONGEVEER',
            waarde: zoekterm,
          } as ITekstPrecisieData,
        });
      }

      const result = await api.v2.bulkbericht.ontvangersselectie.ophalenOntvangersselecties({
        paginatie: params.paginatie,
        filterSchema: {
          filters,
        },
      });

      const items = result.items.reduce((acc, item, i) => {
        acc[params.paginatie.index + i] = item;
        return acc;
      }, params.huidigeBron);

      return {
        items,
        totaalAantal: result.totaalAantal,
      };
    },
    [props.ontvangerssoortID],
  );
  const enkeleProvider = useMemo(
    () => async (ontvangersselectieID: number) => {
      const result = await api.v2.bulkbericht.ontvangersselectie.ophalenOntvangersselecties({
        filterSchema: {
          filters: [
            {
              naam: 'IDS',
              data: [ontvangersselectieID],
            },
          ],
        },
      });
      return result.items[0];
    },
    [],
  );
  const keyExtractor = useCallback((item: IOntvangersselectie) => item.ID, []);

  const kolommen = useMemo<ASPKolom<Kolom, IOntvangersselectie>[]>(
    () => [
      {
        key: 'naam',
        label: 'Naam',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 500,
        renderer: (item) => item.Naam,
      },
    ],
    [],
  );

  return (
    <MultiComboboxV2MetZoeken
      provider={provider}
      enkeleProvider={enkeleProvider}
      keyExtractor={keyExtractor}
      waarde={props.ontvangersselectieID}
      onChange={props.onOntvangersselectieIDChange}
      representatieComponent={Representatie}
      kolommen={kolommen}
    />
  );
};

const Representatie = (props: IRepresentatieProps<IOntvangersselectie>) => {
  return <span>{props.entiteit.Naam}</span>;
};

export default OntvangersselectieSelectie;
