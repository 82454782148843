import React, { useEffect, useState } from 'react';
import PersoonVisualisatie from '../../../../../components/personalia/PersoonVisualisatie';
import { Kleur } from '../../../../../bedrijfslogica/constanten';
import OrganisatieVisualisatie from '../../../../../components/personalia/OrganisatieVisualisatie';
import api from '../../../../../api';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../../models/IRemoteData';
import { IOphalenContactpersonenResultElement } from '../../../../../../../shared/src/api/v2/relatie/contactpersoon';
import RelatieVisualisatie from '../../../../../components/personalia/RelatieVisualisatie';
import AfdelingVisualisatie from '../../../../../components/personalia/AfdelingVisualisatie';
import { IOproep } from '../../../../../../../shared/src/api/v2/telefonie';

interface IProps {
  oproep: IOproep;
}

const EnkeleOproep: React.FC<IProps> = (props) => {
  const { oproep } = props;

  const [beltVoorContactpersonen, setBeltVoorContactpersonen] = useState<
    IRemoteData<IOphalenContactpersonenResultElement[] | null>
  >(createPendingRemoteData());

  useEffect(() => {
    if (props.oproep.PersID === null) {
      setBeltVoorContactpersonen(createReadyRemoteData(null));
      return;
    }

    api.v2.relatie
      .ophalenContactpersonen({
        filterSchema: {
          filters: [
            {
              naam: 'PERS_IDS',
              data: [props.oproep.PersID],
            },
            {
              naam: 'IS_RELATIE',
              data: false,
            },
          ],
        },
      })
      .then((result) => {
        setBeltVoorContactpersonen(
          createReadyRemoteData(
            result.contactpersonen.length === 0 ? null : result.contactpersonen,
          ),
        );
      });
  }, [props.oproep.PersID]);

  if (beltVoorContactpersonen.state === ERemoteDataState.Pending) {
    return <span>Inkomend gesprek laden...</span>;
  }

  return (
    <>
      {oproep.PersID !== null ? (
        <PersoonVisualisatie
          persID={oproep.PersID}
          naamComponent={(naamProps) => (
            <span
              className="w-100 flex-fill d-flex align-items-center justify-content-center"
              style={{ color: Kleur.LichtGeel, fontSize: 14, fontWeight: 'bold' }}
            >
              {naamProps.children}
            </span>
          )}
        />
      ) : oproep.OrgID !== null ? (
        <div className="d-flex flex-column">
          <OrganisatieVisualisatie
            orgID={oproep.OrgID}
            naamComponent={(naamProps) => (
              <span style={{ color: Kleur.LichtGeel, fontSize: 14, fontWeight: 'bold' }}>
                {naamProps.children}
              </span>
            )}
          />
          {oproep.OrgAfdID !== null && (
            <div className="d-flex flex-fill align-items-center justify-content-center">
              <span className="mr-2 font-weight-bold">Afd.</span>
              <AfdelingVisualisatie orgAfdID={oproep.OrgAfdID} />
            </div>
          )}
        </div>
      ) : (
        <span>{oproep.Nummer === null ? 'Anoniem nummer' : oproep.Nummer}</span>
      )}
      {beltVoorContactpersonen.data === null ? null : (
        <>
          <span>belt inzake</span>
          <span>
            {beltVoorContactpersonen.data!.length === 1 ? (
              <RelatieVisualisatie
                relID={beltVoorContactpersonen.data![0].RelID}
                naamComponent={(naamProps) => (
                  <span
                    className="w-100 flex-fill d-flex align-items-center justify-content-center"
                    style={{ color: Kleur.LichtGeel, fontSize: 14, fontWeight: 'bold' }}
                  >
                    {naamProps.naam}
                  </span>
                )}
              />
            ) : (
              <span>meer dan één relatie</span>
            )}
          </span>
        </>
      )}
    </>
  );
};

export default EnkeleOproep;
