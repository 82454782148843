import React, { useState, useEffect, useCallback, useMemo, useContext } from 'react';
import api from '../../../../../../api';
import LoadingSpinner from '../../../../../../components/Gedeeld/LoadingSpinner';
import { observer } from 'mobx-react-lite';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import useUrlState from '../../../../../../core/useUrlState';
import { RootStoreContext } from '../../../../../../stores/RootStore';
import {
  TypedColumn,
  TypedTableColumnWidthInfo,
  GridStyleWrapper,
  DXTableCheckboxComponent,
  DXTableToggleCellComponent,
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
} from '../../../../../../helpers/dxTableGrid';
import { Kleur as EKleur } from '../../../../../../bedrijfslogica/constanten';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  VirtualTable,
  TableEditColumn,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  DataTypeProvider,
  EditingState,
  IntegratedSorting,
  RowDetailState,
  SelectionState,
  SortingState,
  VirtualTable as VirtualTableBase,
} from '@devexpress/dx-react-grid';
import { format } from 'date-fns';
import FormatteerBedrag, {
  StandaardMutatieBedragOpmaker,
} from '../../../../../../components/MutatieBedrag';
import { EResultType } from '../../../../../../stores/CheckStore';
import { IconInformatie, IconPijlVolgend } from '../../../../../../components/Icons';
import WijzigenFactuurDialoog from '../../../../../Facturering/Facturen/WijzigenFactuurDialoog';
import BankopdrachtDialoog from './NieuwBankopdrachtDialoog';
import * as _ from 'lodash';
import ActieMenuKnop from '../../../../../../components/ActieMenuKnop';
import RegelsDetailComp from './RegelsDetailComp';
import SelectieVak from '../../../../../../components/SelectieVak';
import FilterBalkV2, { IFilter, IFilterData } from '../../../../../../components/FilterBalkV2';
import { IFilterSchema } from '../../../../../../../../shared/src/models/filter';
import VerrekenenDialoog from '../../../../../../components/inkoopfactuur/VerrekenenDialoog';
import DownloadKnop from '../../../../../../components/DownloadKnop';
import { IOphalenFacturenResultElement } from '../../../../../../../../shared/src/api/v2/inkoopfactuur';
import FactuurinfoDialoog from '../../../../../../components/inkoopfactuur/FactuurinfoDialoog';
import MenuLayout from '../../../../../../components/MenuLayout';
import InkoopfactuurVisualisatie from '../../../../../../components/entiteitVisualisaties/InkoopfactuurVisualisatie';

enum EFilter {
  IsOpenstaand = 'IS_OPENSTAAND',
}

interface IProps extends RouteComponentProps {
  relID: number;
}

export interface IBetalingsregelingDialoogState {
  factIDs: number[];
}
export interface IIncassozaakDialoogState {
  factIDs: number[];
}
export interface IFactuurinfoDialoogState {
  factID: number;
}

export interface IUrlState {
  selectie: number[];
  filterData: IFilterData<EFilter>[];
  verrekenenDialoogTonen: boolean;
  factuurinfoDialoogState: IFactuurinfoDialoogState | null;
}

export const defaultUrlState: IUrlState = {
  selectie: [],
  filterData: [
    {
      naam: EFilter.IsOpenstaand,
      data: true,
      isActief: false,
    },
  ],
  verrekenenDialoogTonen: false,
  factuurinfoDialoogState: null,
};

const geenData = {
  noData: 'Geen facturen gevonden',
};

interface IRow extends IOphalenFacturenResultElement {
  bankopdrachten: any[];
}

const FacturenComp: React.FC<IProps> = observer((props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState<IUrlState>(props, defaultUrlState);
  const [facturen, setFacturen] = useState<IRow[] | null>(null);
  const { klantkaartStore, checkStore } = useContext(RootStoreContext);
  const [wijzigenID, setWijzigenID] = useState<number | null>(null);
  const [uitvoerdatum, setUitvoerdatum] = useState<Date | null>(null);
  const [bankopdrachtDialoogTonen, setBankopdrachtDialoogTonen] = useState<boolean>(false);
  const [filterSchema, setFilterSchema] = useState<IFilterSchema | null>(null);

  const relID = props.relID;

  const ophalenFacturen = useCallback(async () => {
    // const { facturen } = await api.v2.factuur.ophalenFacturenVoorDebiteur({
    //   debID,
    //   // filterSchema: filterSchema || undefined,
    // });

    const facturenResult = await api.v2.inkoopfactuur.ophalenFacturen({
      filterSchema: {
        filters: [
          ...(filterSchema === null ? [] : filterSchema.filters!),
          { naam: 'REL_IDS', data: [relID] },
        ],
      },
    });

    const bankopdrachtenData = _.flatten(facturenResult.facturen.map((x) => x.bankopdrachtenData));
    const bankOpdIDs = bankopdrachtenData.map((x: any) => x.BankOpdID);

    const bankopopdrachtenResult = (
      await api.v2.bank.opdracht.ophalenOpdrachten({
        filterSchema: { filters: [{ naam: 'IDS', data: bankOpdIDs }] },
      })
    ).opdrachten;

    const facturen = facturenResult.facturen.map((x) => {
      const bankOpdIDs = x.bankopdrachtenData.map((x) => x.BankOpdID);
      const bankopdrachten = bankopopdrachtenResult.filter(
        (x) => bankOpdIDs.indexOf(x.BankOpdID) !== -1,
      );

      return { ...x, bankopdrachten };
    });

    const facturenGesorteerd = _.orderBy(facturen, ['Factuurdatum'], ['desc']);

    setFacturen(facturenGesorteerd);

    // Alleen ids geselecteerd houden die ook voorkomen in de lijst met mogelijke facturen
    const beschikbareIds = facturen.map(keyExtractor);
    const newSelection = urlState.selectie.filter((id) => beschikbareIds.indexOf(id) !== -1);
    setUrlStateSync('selectie', newSelection);
  }, [relID, filterSchema]);

  useEffect(() => {
    // if (filterSchema === null) {
    //   return;
    // }

    ophalenFacturen();
  }, [ophalenFacturen]);

  const handleVerwijderen = useCallback(async (factID: number) => {
    const checkData = await api.v2.factuur.checkVerwijderenFacturenUitVoorstel({
      factIDs: [factID],
    });
    if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
      return;
    }
    if (
      (
        await checkStore.bevestigen({
          inhoud: `Wil je de factuur verwijderen uit het voorstel?`,
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    await api.v2.factuur.verwijderenFacturenUitVoorstel({
      factIDs: [factID],
    });

    // setUrlStateSync('selectie', []);

    ophalenFacturen();
  }, []);

  const keyExtractor = useCallback((row: IOphalenFacturenResultElement) => row.InkFactID, []);

  const kolommen = useMemo<TypedColumn<IOphalenFacturenResultElement>[]>(
    () => [
      {
        name: '__factuurnummer' as any,
        title: 'Fact.nr.',
      },
      {
        name: '__factuurinfo' as any,
        title: 'Info',
      },
      {
        name: 'Factuurdatum',
        title: 'Fact.datum',
      },
      {
        name: 'Vervaldatum',
        title: 'Verv.datum',
      },
      {
        name: 'Bedrag',
        title: 'Bedrag',
      },
      {
        name: '__openstaand' as any,
        title: 'Openst.',
      },
      {
        name: 'Onderwerp',
        title: 'Onderwerp',
      },
      // {
      //   name: '__betaalwijze' as any,
      //   title: 'Bet.wijze',
      // },
      {
        name: '__signaleringen' as any,
        title: 'Info',
      },
      {
        name: '__downloadActie' as any,
        title: ' ',
      },
      {
        name: '__actieMenu' as any,
        title: ' ',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IOphalenFacturenResultElement>[]>(
    () => [
      {
        columnName: '__factuurnummer' as any,
        width: 115,
      },
      {
        columnName: '__factuurinfo' as any,
        width: 70,
      },
      {
        columnName: 'Factuurnummer',
        width: 90,
      },
      {
        columnName: 'Factuurdatum',
        width: 110,
      },
      {
        columnName: 'Vervaldatum',
        width: 110,
      },
      {
        columnName: 'Bedrag',
        width: 90,
      },
      // { columnName: '__betaalwijze', width: 125 },
      {
        columnName: '__openstaand' as any,
        width: 90,
      },
      {
        columnName: 'Onderwerp',
        width: 210,
      },
      {
        columnName: '__signaleringen',
        width: 120,
      },
      {
        columnName: '__downloadActie',
        width: 50,
      },
      {
        columnName: '__actieMenu',
        width: 50,
      },
    ],
    [],
  );

  const kolomExtensies: VirtualTableBase.ColumnExtension[] = useMemo(() => {
    return [
      // {
      //   columnName: `Bedrag`,
      //   align: 'right',
      // },
      // {
      //   columnName: `Openstaand`,
      //   align: 'right',
      // },
    ];
  }, []);

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.IsOpenstaand,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return <span>Alleen openstaand</span>;
        },
      },
    ],
    [],
  );

  return (
    <MenuLayout
      menu={
        <>
          <div className="d-flex align-items-center">
            <button
              className="btn btn-sm btn-light d-flex align-items-center ml-2"
              style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
              disabled={urlState.selectie.length === 0}
              onClick={async () => {
                const checkData = await api.v2.inkoopfactuur.checkSelectieVerrekenenInkoopfacturen({
                  inkFactIDs: urlState.selectie,
                });

                if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
                  return;
                }
                setUrlStateSync('verrekenenDialoogTonen', true);
              }}
            >
              <IconPijlVolgend style={{ width: 16, height: 16, fill: EKleur.Grijs }} />
              <span className="ml-2">Verrekenen</span>
            </button>
          </div>
        </>
      }
      body={
        <>
          {facturen === null ? (
            <div className="flex-fill d-flex align-items-center justify-content-center">
              <LoadingSpinner />
            </div>
          ) : (
            <>
              <GridStyleWrapper height="calc(100vh - 150px)">
                <Grid columns={kolommen} getRowId={keyExtractor} rows={facturen!}>
                  <DataTypeProvider
                    for={['__factuurinfo']}
                    formatterComponent={(formatterProps) => {
                      const rij = formatterProps.row as IOphalenFacturenResultElement;

                      return (
                        <a
                          href="#"
                          className="ml-1"
                          style={{ color: EKleur.LichtBlauw, position: 'relative', bottom: 2 }}
                          onClick={() => {
                            setUrlStateSync('factuurinfoDialoogState', { factID: rij.InkFactID });
                          }}
                        >
                          <IconInformatie style={{ width: 15, height: 15, fill: EKleur.Blauw }} />
                        </a>
                      );
                    }}
                  />

                  <DataTypeProvider
                    for={['__openstaand']}
                    formatterComponent={(props) => {
                      const rij = props.row as IOphalenFacturenResultElement;

                      if (!rij.Geboekt) {
                        return <span>Nog boeken</span>;
                      }

                      return (
                        <FormatteerBedrag
                          bedrag={rij.Openstaand}
                          // rechtsUitlijnen
                          opmaakComponent={(opmaakProps) => {
                            const kleur = rij.Openstaand !== 0 ? EKleur.Rood : EKleur.Groen;
                            return <StandaardMutatieBedragOpmaker {...opmaakProps} />;
                          }}
                        />
                      );
                    }}
                  />

                  <DataTypeProvider
                    for={['__betaalwijze']}
                    formatterComponent={(props) => <span>{props.row.betaalwijze.Naam}</span>}
                  />

                  <DataTypeProvider
                    for={['__signaleringen']}
                    formatterComponent={(props) => {
                      const bankopdracht =
                        props.row.bankopdrachten.length !== 0 ? props.row.bankopdrachten[0] : null;

                      let bankopdrachtInfo = '';
                      if (bankopdracht !== null && !bankopdracht.Uitgevoerd) {
                        bankopdrachtInfo = bankopdracht.Bedrag > 0 ? 'Inc ' : 'Bet ';

                        bankopdrachtInfo +=
                          bankopdracht.BatchID !== null
                            ? (bankopdracht.Uitvoerdatum !== null
                                ? format(new Date(bankopdracht.Uitvoerdatum), 'dd-MM-yyyy')
                                : '') + (bankopdracht.DatumVerstuurd === null ? ' ~' : '')
                            : bankopdracht.UitvoerdatumOpdracht !== null
                            ? format(new Date(bankopdracht.UitvoerdatumOpdracht), 'dd-MM-yyyy') +
                              ' ~'
                            : '';
                      }

                      // const signaleringen = props.row.signaleringen.join(', ');
                      const result = bankopdrachtInfo; //+ ' ' + signaleringen;
                      return <span>{result}</span>;
                    }}
                  />

                  <DataTypeProvider
                    for={['__actieMenu']}
                    formatterComponent={(props) => {
                      return (
                        <ActieMenuKnop
                          acties={[
                            {
                              text: 'Verwijderen uit voorstel',
                              disabled: props.row.Definitief,
                              onClick: () => handleVerwijderen(props.row.FactID),
                            },
                          ]}
                        />
                      );
                    }}
                  />

                  <DataTypeProvider
                    for={['__factuurnummer']}
                    formatterComponent={(props) => {
                      const row = props.row as IOphalenFacturenResultElement;

                      return (
                        <InkoopfactuurVisualisatie
                          inkFactID={row.InkFactID}
                          linkToepassen={false}
                        />
                      );
                    }}
                  />

                  <DataTypeProvider
                    for={['Factuurnummer']}
                    formatterComponent={(props) => (
                      <span>{props.value !== null ? props.value : '00.000000'}</span>
                    )}
                  />

                  <DataTypeProvider
                    for={['Factuurdatum']}
                    formatterComponent={(props) => (
                      <span>{format(new Date(props.value), 'dd-MM-yyyy')}</span>
                    )}
                  />
                  <DataTypeProvider
                    for={['Vervaldatum']}
                    formatterComponent={(props) => (
                      <span>{format(new Date(props.value), 'dd-MM-yyyy')}</span>
                    )}
                  />
                  <DataTypeProvider
                    for={['Bedrag']}
                    formatterComponent={(props) => <FormatteerBedrag bedrag={props.value} />}
                  />
                  <DataTypeProvider
                    for={['Openstaand']}
                    formatterComponent={(props) => {
                      const row = props.row as IOphalenFacturenResultElement;

                      return <FormatteerBedrag bedrag={props.value} />;

                      // return (
                      //   <FormatteerBedrag
                      //     bedrag={props.value}
                      //     opmaakComponent={(opmaakProps) => {
                      //       return (
                      //         <StandaardMutatieBedragOpmaker
                      //           {...opmaakProps}
                      //           color={row.Geboekt ? EKleur.Rood : EKleur.Rood}
                      //         />
                      //       );
                      //     }}
                      //   />
                      // );
                    }}
                  />

                  <DataTypeProvider
                    for={['__downloadActie']}
                    formatterComponent={(props) => {
                      const row = props.row as IOphalenFacturenResultElement;
                      const bestand = row.bestanden.length !== 0 ? row.bestanden[0] : null;
                      if (bestand === null) {
                        return <span></span>;
                      }
                      return (
                        <DownloadKnop
                          onDownloadAangevraagd={async () => {
                            const downloadLink = bestand.url;
                            window.open(downloadLink, '_blank');
                          }}
                        />
                      );
                    }}
                  />

                  {/* <DataTypeProvider
                for={['__debiteur']}
                formatterComponent={(props) =>
                  props.row.debiteur.RelID !== null ? (
                    <RelatieVisualisatie relID={props.row.debiteur.RelID} />
                  ) : (
                    <span></span>
                  )
                }
              /> */}

                  <EditingState
                    onCommitChanges={() => null}
                    onEditingRowIdsChange={(rowIds) => {
                      const id = rowIds[rowIds.length - 1] as number;
                      setWijzigenID(id);
                    }}
                  />

                  <SelectionState
                    selection={urlState.selectie}
                    onSelectionChange={(value) => setUrlStateSync('selectie', value as number[])}
                  />

                  <SortingState defaultSorting={[]} />
                  <IntegratedSorting />

                  <RowDetailState defaultExpandedRowIds={[]} />

                  <VirtualTable messages={geenData} columnExtensions={kolomExtensies} />
                  <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
                  <TableHeaderRow showSortingControls />
                  <TableEditColumn
                    width={35}
                    // showEditCommand
                    cellComponent={DXTableEditColumnCellComponent}
                    commandComponent={DXTableEditColumnCommandComponent}
                  />
                  <TableRowDetail
                    contentComponent={RegelsDetailComp}
                    toggleCellComponent={DXTableToggleCellComponent}
                  />
                  <TableSelection cellComponent={DXTableCheckboxComponent} />
                </Grid>
              </GridStyleWrapper>
              <WijzigenFactuurDialoog
                factID={wijzigenID!}
                open={wijzigenID !== null}
                onAnnuleren={() => setWijzigenID(null)}
                onSuccess={() => {
                  setWijzigenID(null);
                  ophalenFacturen();
                }}
              />
              <BankopdrachtDialoog
                factIDs={urlState.selectie!}
                uitvoerdatum={uitvoerdatum!}
                open={bankopdrachtDialoogTonen}
                onAnnuleren={() => setBankopdrachtDialoogTonen(false)}
                onSuccess={() => {
                  setBankopdrachtDialoogTonen(false);
                  ophalenFacturen();
                }}
              />
              {urlState.verrekenenDialoogTonen && (
                <VerrekenenDialoog
                  inkfactIDs={urlState.selectie}
                  open
                  onSuccess={() => {
                    setUrlStateSync('verrekenenDialoogTonen', false);
                    ophalenFacturen();
                  }}
                  onAnnuleren={() => setUrlStateSync('verrekenenDialoogTonen', false)}
                />
              )}
              {urlState.factuurinfoDialoogState !== null && (
                <FactuurinfoDialoog
                  open
                  inkFactID={urlState.factuurinfoDialoogState.factID}
                  onSuccess={() => {
                    setUrlStateSync('factuurinfoDialoogState', null);
                  }}
                  onAnnuleren={() => {
                    setUrlStateSync('factuurinfoDialoogState', null);
                  }}
                />
              )}
            </>
          )}
        </>
      }
    />
  );
});

export default withRouter(FacturenComp);
