import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { IConcurrent } from '../../../../../../shared/src/api/v2/concurrent';
import api from '../../../../api';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import { IFilterData } from '../../../../components/FilterBalkV2';
import { IconToevoegen, IconVerwijderen } from '../../../../components/Icons';
import MenuLayout from '../../../../components/MenuLayout';
import ASPTabel from '../../../../components/tabel/ASPTabel';
import TableData, { ITableDataProps } from '../../../../components/tabel/ASPTabel/Body/TableData';
import { ASPKolom, EAspKolomBreedteType } from '../../../../components/tabel/ASPTabel/types';
import useUrlState from '../../../../core/useUrlState';
import { EResultType } from '../../../../stores/CheckStore';
import { RootStoreContext } from '../../../../stores/RootStore';
import WijzigenDialoog from './WijzigenDialoog';
import { formatteerBoolean } from '../../../../helpers';

type Kolom = 'naam' | 'naam-enum' | 'in_vergelijker' | 'website-url';

export enum EFilter {
  Zoekterm = 'ZOEKTERM',
}

interface IProps extends RouteComponentProps {}

interface IToevoegenDialoogState {}
interface IWijzigenDialoogState {
  id: number;
}
interface IUrlState {
  selectie: number[];
  filterdata: IFilterData<EFilter>[];
  toevoegenDialoogState: IToevoegenDialoogState | null;
  wijzigenDialoogState: IWijzigenDialoogState | null;
}

const defaultUrlState: IUrlState = {
  selectie: [],
  filterdata: [],
  toevoegenDialoogState: null,
  wijzigenDialoogState: null,
};

const Item: React.FC<IProps> = (props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const { checkStore } = useContext(RootStoreContext);

  const [rijen, setRijen] = useState<IConcurrent[] | null>(null);

  const ophalenRijen = useCallback(async () => {
    console.log(urlState);
    const results = await api.v2.concurrentie.ophalenConcurrenten();
    console.log('resultaat:');
    console.log(results);
    setRijen(results);
  }, []);

  useEffect(() => {
    ophalenRijen();
  }, [ophalenRijen]);

  const handleToevoegen = useCallback(async () => {
    setUrlStateSync('wijzigenDialoogState', { id: null });
  }, []);

  const handleVerwijderen = useCallback(async () => {
    const params = { ids: urlState.selectie };

    const checkData = await api.v2.concurrentie.checkVerwijderenConcurrenten(params);
    const controleResult = await checkStore.controleren({
      checkData,
    });
    if (controleResult.type === EResultType.Annuleren) {
      return;
    }

    if (
      (
        await checkStore.bevestigen({
          inhoud: (
            <span>
              Geselecteerde items verwijderen?
              <br />
              <br />
              Let op, hiermee verwijder je tevens alle gerelateerde data (b.v. de
              Vergelijkingspunten) voor deze concurrent.
            </span>
          ),
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    await api.v2.concurrentie.verwijderenConcurrenten(params);
    setUrlStateSync('selectie', []);

    await ophalenRijen();
  }, [urlState.selectie]);

  const keyExtractor = useCallback((row: IConcurrent) => (row.ID == null ? 0 : row.ID), [rijen]);

  const kolommen = useMemo<ASPKolom<Kolom, IConcurrent>[]>(
    () => [
      {
        key: 'naam',
        label: 'Naam',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 300,
        renderer: (x) => x.Naam,
      },

      {
        key: 'naam-enum',
        label: 'Identificatie (Naam-Enum)',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 300,
        renderer: (x) => x.NaamEnum,
      },
      {
        key: 'in_vergelijker',
        label: 'In vergelijker',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 130,
        renderer: (x) => formatteerBoolean(x.InVergelijker),
      },
      {
        key: 'website-url',
        label: 'Website URL',
        breedteType: EAspKolomBreedteType.Flex,
        flex: 1,
        renderer: (x) => x.WebsiteURL,
      },
    ],
    [],
  );

  return (
    <>
      <Helmet>
        <title>Concurrenten</title>
      </Helmet>
      <MenuLayout
        menu={
          <div className="d-flex align-items-center">
            <button
              className="btn btn-sm btn-light d-flex align-items-center"
              style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
              disabled={false}
              onClick={() => handleToevoegen()}
            >
              <IconToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
              <span className="ml-2">Toevoegen</span>
            </button>
            <button
              className="btn btn-sm btn-light d-flex align-items-center ml-3"
              style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
              disabled={urlState.selectie.length === 0}
              onClick={() => handleVerwijderen()}
            >
              <IconVerwijderen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
              <span className="ml-2">Verwijderen</span>
            </button>
          </div>
        }
        body={
          rijen == null ? (
            <span></span>
          ) : (
            <ASPTabel
              rijen={rijen}
              kolommen={kolommen}
              keyExtractor={keyExtractor}
              selectie={urlState.selectie}
              onSelectieChange={(selectie) => setUrlStateSync('selectie', selectie)}
              onWijzigenRij={async (rij) => {
                setUrlStateSync('wijzigenDialoogState', { id: rij.ID });
              }}
              tdComponent={TdComponent}
            />
          )
        }
      />
      {urlState.wijzigenDialoogState !== null && (
        <WijzigenDialoog
          open
          id={urlState.wijzigenDialoogState.id}
          onSuccess={async () => {
            setUrlStateSync('wijzigenDialoogState', null);
            await ophalenRijen();
          }}
          onAnnuleren={() => setUrlStateSync('wijzigenDialoogState', null)}
        />
      )}
    </>
  );
};

const TdComponent = (props: ITableDataProps<Kolom, IConcurrent>) => {
  return (
    <TableData
      {...props}
      style={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
        display: 'block',
        paddingTop: '8px',
      }}
    />
  );
};

export default withRouter(Item);
