import { format } from 'date-fns';
import { Field, FieldProps } from 'formik';
import React, { useEffect, useState, useCallback, useContext } from 'react';
import ModalBody from 'react-bootstrap/ModalBody';
import { ESoortOpdracht, FormulierContext, IFormikValues, veldnamen } from '..';
import nameof from '../../../../core/nameOf';
import { createReadyRemoteData } from '../../../../models/IRemoteData';
import Combobox from '../../../formulier/Combobox';
import ContactSelectie, { EType } from '../../../formulier/ContactSelectie';
import DatumKiezer from '../../../formulier/DatumKiezer';
import FormikVeldFout from '../../../formulier/FormikVeldFout';
import LocatieSelectie from '../../../formulier/LocatieSelectie';
import TijdvakVeld from '../../../formulier/TijdvakVeld';
import VinkVeld from '../../../formulier/VinkVeld';
import LoadingSpinner from '../../../Gedeeld/LoadingSpinner';

export const aantalAfvoer = new Array(21).fill(null).map((_, i) => ({
  id: i,
  label: i === 0 ? '0' : String(i),
}));

const OpdrachtTab: React.FC = (props) => {
  const {
    diensten,
    dienstID,
    magazijnen,
    bezoekdagen,
    setDienstID,
    relID,
    locatieID,
    setLocatieID,
  } = useContext(FormulierContext);

  return (
    <ModalBody>
      <div style={{ minHeight: `500px` }}>
        <div className="row">
          <div className="row col-12">
            <div className="col-8">
              <label>{veldnamen.dienstID}</label>
              <Field
                name={nameof<IFormikValues>('dienstID')}
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;

                  if (diensten === null) {
                    return <LoadingSpinner />;
                  }

                  return (
                    <Combobox
                      geselecteerd={dienstID}
                      opties={diensten.map((dienst) => {
                        return {
                          id: dienst.ID,
                          label: dienst.Naam !== null ? dienst.Naam : dienst.relatie!.weergavenaam,
                        };
                      })}
                      onSelectieChange={(x) => {
                        setDienstID(x!);
                        return form.setFieldValue(field.name, x);
                      }}
                    />
                  );
                }}
              />
            </div>
            <div className="col-4">
              <label>{veldnamen.magazijnID}</label>
              <Field
                name={nameof<IFormikValues>('magazijnID')}
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;

                  if (magazijnen === null) {
                    return <LoadingSpinner />;
                  }

                  return (
                    <>
                      <Combobox
                        geselecteerd={field.value}
                        opties={magazijnen.map((magazijn) => {
                          return {
                            id: magazijn.MagID,
                            label: magazijn.NaamKort,
                          };
                        })}
                        onSelectieChange={(x) => form.setFieldValue(field.name, x)}
                      />
                      <FormikVeldFout fieldProps={fieldProps} />
                    </>
                  );
                }}
              />
            </div>
          </div>

          {/* <div className="col-12 mt-3">
            <label>{veldnamen.locatieID}</label>
            <Field
              name="locatieID"
              render={(fieldProps: FieldProps<IFormikValues>) => {
                const { field, form } = fieldProps;
                return (
                  <>
                    <LocatieSelectie
                      locID={locatieID.data}
                      onChange={(locID) => {
                        setLocatieID(createReadyRemoteData(locID));
                        form.setFieldValue(field.name, locID);
                      }}
                      relIDs={[relID]}
                      options={{
                        bijEnkeleDirectVoorselecteren: true,
                      }}
                    />
                    <FormikVeldFout fieldProps={fieldProps} />
                  </>
                );
              }}
            />
          </div> */}

          <div className="col-12 mt-3">
            <div className="row">
              <div className="col-4">
                <label>{veldnamen.bezoekdatum}</label>
                <Field
                  name={nameof<IFormikValues>('bezoekdatum')}
                  render={(fieldProps: FieldProps<IFormikValues>) => {
                    const { field, form } = fieldProps;
                    return (
                      <>
                        <DatumKiezer
                          waarde={field.value}
                          onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                          isClearable
                          determineValidDate={(date) => {
                            return bezoekdagen !== null
                              ? bezoekdagen.findIndex((bezoekdag: any) => {
                                  return format(date, 'yyyy-MM-dd') === bezoekdag;
                                }) !== -1
                              : true;
                          }}
                        />
                        <FormikVeldFout fieldProps={fieldProps} />
                      </>
                    );
                  }}
                />
              </div>
              <div className="col-4">
                <label>{veldnamen.voorkeurTijdvak}</label>
                <Field
                  name={nameof<IFormikValues>('voorkeurTijdvak')}
                  render={(fieldProps: FieldProps<IFormikValues>) => {
                    const { field, form } = fieldProps;
                    return (
                      <TijdvakVeld
                        value={field.value}
                        onChange={(x) => {
                          form.setFieldValue(field.name, x);
                        }}
                        enkeleToestaan
                      />
                    );
                  }}
                />
              </div>

              <div className="col-4 d-flex align-items-center pt-4">
                <Field
                  name={nameof<IFormikValues>('indicatieAAA')}
                  render={(fieldProps: FieldProps<IFormikValues>) => {
                    const { field, form } = fieldProps;
                    return (
                      <VinkVeld
                        aangevinkt={field.value}
                        onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                      />
                    );
                  }}
                />
                <span className="ml-2">{veldnamen.indicatieAAA}</span>
              </div>
            </div>
          </div>

          <div className="col-12 mt-2">
            <div className="row">
              <div className="col-3">
                <label>{veldnamen.aantalAfvoer}</label>
                <Field
                  name={nameof<IFormikValues>('aantalAfvoer')}
                  render={(fieldProps: FieldProps<IFormikValues>) => {
                    const { field, form } = fieldProps;

                    return (
                      <>
                        <Combobox
                          geselecteerd={field.value}
                          onSelectieChange={(x) => form.setFieldValue(field.name, x)}
                          opties={aantalAfvoer.map((x) => {
                            return {
                              id: x.id,
                              label: x.label,
                            };
                          })}
                        />
                      </>
                    );
                  }}
                />
              </div>
            </div>
          </div>

          <div className="col-12 mt-3">
            <label>{veldnamen.bezoekinstructies}</label>
            <Field
              name="bezoekinstructies"
              render={(fieldProps: FieldProps<IFormikValues>) => {
                const { field, form } = fieldProps;
                return (
                  <textarea
                    {...field}
                    className="form-control"
                    rows={1}
                    placeholder={'Deurbel doet het niet, op raam bonken'}
                  />
                );
              }}
            />
          </div>

          <div className="col-12 mt-2">
            <label>{veldnamen.infoPlanning}</label>
            <Field
              name="infoPlanning"
              render={(fieldProps: FieldProps<IFormikValues>) => {
                const { field, form } = fieldProps;
                return (
                  <textarea
                    {...field}
                    className="form-control"
                    rows={1}
                    placeholder={'2e contactpersoon is Kees (06-12345678)'}
                  />
                );
              }}
            />
          </div>

          <div className="col-12 mt-2">
            <label>{veldnamen.persoonID}</label>
            <Field
              name="persoonID"
              render={(fieldProps: FieldProps<IFormikValues>) => {
                const { field, form } = fieldProps;
                return (
                  <>
                    <ContactSelectie
                      value={field.value === null ? null : { persID: field.value, orgID: null }}
                      onChange={(value) =>
                        form.setFieldValue(field.name, value?.persoon?.PersID ?? null)
                      }
                      alleenVoorRelIDs={[relID]}
                      options={{
                        bijEnkeleDirectVoorselecteren: true,
                        types: [EType.Persoon],
                        // voorselectieRelatieContactpersoonType:
                        //   EVoorselectieRelatieContactpersoonType.Administratief,
                      }}
                    />
                    <FormikVeldFout fieldProps={fieldProps} />
                  </>
                );
              }}
            />
          </div>
        </div>
      </div>
    </ModalBody>
  );
};

export default OpdrachtTab;
