import React, { useMemo } from 'react';
import { Kleur } from '../../bedrijfslogica/constanten';

interface IProps {
  style?: React.CSSProperties;
  aantal: number;
  maxAantal?: number | null;
}

const Badge = (props: IProps) => {
  const maxAantal = useMemo(() => {
    if (props.maxAantal === null) {
      return null;
    }
    return props.maxAantal ?? 99;
  }, [props.maxAantal]);
  const weergave = useMemo(() => {
    if (maxAantal === null) {
      return props.aantal;
    }
    return props.aantal > maxAantal ? maxAantal : props.aantal;
  }, [props.aantal, maxAantal]);
  const meerDanMaxAantal = useMemo(() => {
    return maxAantal !== null && props.aantal > maxAantal;
  }, [props.aantal, maxAantal]);

  return (
    <span
      className="badge d-flex align-items-center justify-content-center"
      style={{
        backgroundColor: Kleur.DonkerRood,
        color: Kleur.Wit,
        display: 'inline-block',
        padding: '.35em .65em',
        fontSize: '.75em',
        fontWeight: 700,
        lineHeight: 1,
        textAlign: 'center',
        whiteSpace: 'nowrap',
        verticalAlign: 'baseline',
        borderRadius: '50rem',
        minHeight: 19,
        maxHeight: 19,
        ...props.style,
      }}
    >
      <span style={{ position: 'relative', left: meerDanMaxAantal ? undefined : -0.5 }}>
        {weergave}
      </span>
      {meerDanMaxAantal && <span style={{ fontSize: 12, marginLeft: 1 }}>+</span>}
    </span>
  );
};

export default Badge;
