import React, { useContext } from 'react';
import { object, string } from 'yup';
import { Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import { SplashLogo } from '../../components/Icons/splashLogo';
import { Wrapper, Container, FormHeader, Error } from './styles';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { RootStoreContext } from '../../stores/RootStore';
import { Kleur } from '../../bedrijfslogica/constanten';

const schema = object().shape({
  gebruikersnaam: string().required('Gebruikersnaam is vereist'),
  wachtwoord: string().required('Wachtwoord is vereist'),
});

const Login = observer(() => {
  const { gebruikerStore } = useContext(RootStoreContext);
  return (
    <Wrapper>
      <Container>
        <Formik
          initialValues={{ gebruikersnaam: '', wachtwoord: '' }}
          validationSchema={schema}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            await gebruikerStore.login(values);
            setSubmitting(false);
          }}
        >
          {({ values, errors, touched, handleBlur, handleChange, handleSubmit, isSubmitting }) => (
            <Form onSubmit={handleSubmit}>
              <FormHeader>
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <h1>
                    <b>Welkom bij het ASP Systeem</b>
                  </h1>
                  <span className="mt-3">
                    <SplashLogo />
                  </span>
                </div>
              </FormHeader>

              {/*<div style={{ marginBottom: 10, color: Kleur.Rood }}>Je sessie is verlopen.</div>*/}

              <Form.Group controlId="gebruikersnaam">
                <Form.Label>Gebruikersnaam</Form.Label>
                <Form.Control
                  type="text"
                  name="gebruikersnaam"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.gebruikersnaam}
                  isInvalid={errors.gebruikersnaam && touched.gebruikersnaam ? true : false}
                />

                <Form.Control.Feedback type="invalid">
                  {errors.gebruikersnaam}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="wachtwoord">
                <Form.Label>Wachtwoord</Form.Label>
                <Form.Control
                  type="password"
                  name="wachtwoord"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.wachtwoord}
                  isInvalid={errors.wachtwoord && touched.wachtwoord ? true : false}
                />
                <Form.Control.Feedback type="invalid">{errors.wachtwoord}</Form.Control.Feedback>
              </Form.Group>

              <Button type="submit" variant="primary" disabled={isSubmitting}>
                {!isSubmitting && <span>Inloggen</span>}
                {isSubmitting && (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    <span>&nbsp; Bezig...</span>
                  </>
                )}
              </Button>
              <Error>{gebruikerStore.loginError}</Error>
            </Form>
          )}
        </Formik>
      </Container>
    </Wrapper>
  );
});

export default Login;
