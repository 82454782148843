import { Formik, FormikActions, FormikProps } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useMemo, useState } from 'react';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import * as Yup from 'yup';
import { IGroepSorterenParams } from '../../../../../../../shared/src/api/v2/faq';
import api from '../../../../../api';
import LoadingSpinner from '../../../../../components/Gedeeld/LoadingSpinner';
import Dialoog from '../../../../../components/dialogen/Dialoog';
import IDialoogProps from '../../../../../core/IDialoogProps';
import VraagSortering from '../../VraagSortering';

interface IDialoogResult {}

interface IProps extends IDialoogProps<IDialoogResult> {
  id: number;
  naamEnum: string;
}

interface IFormikValues {
  ID: number;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  ID: 'ID',
};

const WijzigenDialoog: React.FC<IProps> = observer((props) => {
  const { children, dialoogIndex, onAnnuleren, onSuccess } = props;

  const [sortering, setSortering] = useState<IGroepSorterenParams | null>(null);

  const handleSorteringVeranderd = (sort: IGroepSorterenParams) => {
    console.log(sort);
    setSortering(sort);
    console.log(sortering);
  };

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      console.log(sortering);
      if (sortering !== null) {
        console.log('update sortering');
        console.log(sortering);
        await api.v2.faq.sorterenPaginaVragen(sortering);
      } else {
        console.log('niet sorteren');
      }

      actions.setSubmitting(false);
      props.onSuccess({});
    },
    [sortering],
  );

  const initialValues = useMemo<IFormikValues | null>(() => {
    return {
      ID: props.id,
    };
  }, []);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        ID: Yup.string().required(),
      }),
    [],
  );

  return (
    <Dialoog index={dialoogIndex || 0} modalProps={{ size: 'lg' }}>
      <ModalHeader>
        <ModalTitle>Wijzigen Faq Vragen voor {props.naamEnum}</ModalTitle>
      </ModalHeader>
      {initialValues === null ? (
        <LoadingSpinner />
      ) : (
        <Formik<IFormikValues>
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize
          isInitialValid
          render={(formikProps: FormikProps<IFormikValues>) => {
            const { submitForm, isSubmitting } = formikProps;
            return (
              <>
                <ModalBody>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-12 mt-3">
                        <VraagSortering
                          Groep_ID={props.id}
                          onSorteringVeranderd={handleSorteringVeranderd}
                          onVragenOphalen={api.v2.faq.ophalenPaginaVragen}
                        ></VraagSortering>
                      </div>
                    </div>
                  </div>
                </ModalBody>

                <ModalFooter className="d-flex flex-row justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    style={{ width: 100 }}
                    disabled={isSubmitting || sortering == null}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={onAnnuleren}
                    style={{ width: 100 }}
                    disabled={isSubmitting}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Dialoog>
  );
});

export default WijzigenDialoog;
