import {
  DataTypeProvider,
  EditingState,
  IntegratedSorting,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  VirtualTable,
  Table,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  TableSelection,
} from '@devexpress/dx-react-grid-bootstrap4';
import _ from 'lodash';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { RouteComponentProps } from 'react-router';
import api from '../../../api';
import { Kleur } from '../../../bedrijfslogica/constanten';
import LoadingSpinner from '../../../components/Gedeeld/LoadingSpinner';
import { IconToevoegen, IconVerwijderen } from '../../../components/Icons';
import MenuLayout from '../../../components/MenuLayout';
import nameOf from '../../../core/nameOf';
import useUrlState from '../../../core/useUrlState';
import {
  DXTableCheckboxComponent,
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../helpers/dxTableGrid';
import { EResultType } from '../../../stores/CheckStore';
import { RootStoreContext } from '../../../stores/RootStore';
import ToevoegenDialoog from './ToevoegenDialoog';
import WijzigenDialoog from './WijzigenDialoog';
import { IOphalenWebtekstenResultElement } from '../../../../../shared/src/api/v2/webtekst';
import { IOphalenTekstenResultElement } from '../../../../../shared/src/api/v2/tekst';
import FilterBalkV2, {
  IFilter,
  IFilterData,
  maakFilterSchema,
} from '../../../components/FilterBalkV2';
import ZoektermFilter from './ZoektermFilter';

export enum EFilter {
  Zoekterm = 'ZOEKTERM',
}

interface IProps extends RouteComponentProps {}

interface IToevoegenDialoogState {}
interface IWijzigenDialoogState {
  id: number;
}
interface IUrlState {
  selectie: number[];
  filterdata: IFilterData<EFilter>[];
  toevoegenDialoogState: IToevoegenDialoogState | null;
  wijzigenDialoogState: IWijzigenDialoogState | null;
}

const defaultUrlState: IUrlState = {
  selectie: [],
  filterdata: [
    {
      naam: EFilter.Zoekterm,
      data: '',
      isActief: false,
    },
  ],
  toevoegenDialoogState: null,
  wijzigenDialoogState: null,
};

const Webteksten: React.FC<IProps> = (props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const { checkStore, instellingStore } = useContext(RootStoreContext);

  const [webteksten, setWebteksten] = useState<IOphalenWebtekstenResultElement[] | null>(null);

  const [teksten, setTeksten] = useState<IOphalenTekstenResultElement[] | null>(null);

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.Zoekterm,
        altijdWeergevenInBalk: true,
        weergave: ZoektermFilter,
      },
    ],
    [],
  );

  const [filterSchema, setFilterSchema] = useState(maakFilterSchema(urlState.filterdata));

  const handleToevoegen = useCallback(async () => {
    setUrlStateSync('toevoegenDialoogState', {});
  }, []);

  const handleVerwijderen = useCallback(async () => {
    const params = { webTekstIDs: urlState.selectie };
    if (
      (
        await checkStore.bevestigen({
          inhoud: <span>Geselecteerde teksten verwijderen?</span>,
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    await api.v2.webtekst.verwijderenWebteksten(params);
    setUrlStateSync('selectie', []);

    await ophalenWebteksten();
  }, [urlState.selectie]);

  const ophalenWebteksten = useCallback(async () => {
    console.log(urlState);
    const resultWebteksten = await api.v2.webtekst.ophalenWebteksten({
      filterSchema,
    });

    const webtekstenGesorteerd = _.orderBy(resultWebteksten.webteksten, ['NaamEnum'], ['asc']);

    setWebteksten(webtekstenGesorteerd);
  }, [filterSchema.filters]);

  useEffect(() => {
    ophalenWebteksten();
  }, [ophalenWebteksten]);

  const ophalenTeksten = useCallback(async () => {
    if (webteksten === null) {
      return;
    }
    const tekstIDs = webteksten.map((x) => x.Tekst_TekstID);
    const resultTeksten = await api.v2.tekst.ophalenTekstenInAlleTalen({
      // taalID: instellingStore.TaalID!,
      tekstIDs,
    });

    setTeksten(resultTeksten.teksten);
  }, [webteksten]);

  useEffect(() => {
    ophalenTeksten();
  }, [ophalenTeksten]);

  const keyExtractor = useCallback((row: IOphalenWebtekstenResultElement) => row.WebTekstID, []);
  const kolommen = useMemo<TypedColumn<IOphalenWebtekstenResultElement>[]>(
    () => [
      {
        name: 'NaamEnum',
        title: 'Tekstcode',
      },
      {
        name: '__tekst_NL' as any,
        title: 'Tekst',
        getCellValue: (rij) => {
          if (teksten === null) {
            return;
          }
          const tekst = teksten.find((x) => x.TekstID === rij.Tekst_TekstID && x.TaalID === 1)!;
          return tekst !== undefined ? tekst.Tekst : '';
        },
      },
      {
        name: '__tekst_E' as any,
        title: 'Tekst Engels',
        getCellValue: (rij) => {
          if (teksten === null) {
            return;
          }
          const tekst = teksten.find((x) => x.TekstID === rij.Tekst_TekstID && x.TaalID === 2);
          return tekst !== undefined ? tekst.Tekst : '';
        },
      },
    ],
    [teksten],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IOphalenWebtekstenResultElement>[]>(
    () => [
      {
        columnName: 'NaamEnum',
        width: 400,
      },
      {
        columnName: '__tekst_NL' as any,
        width: 700,
      },
      {
        columnName: '__tekst_E' as any,
        width: 400,
      },
    ],
    [],
  );

  return (
    <>
      <Helmet>
        <title>Website Teksten</title>
      </Helmet>
      <MenuLayout
        menu={
          <div className="d-flex align-items-center">
            <button
              className="btn btn-sm btn-light d-flex align-items-center"
              style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
              disabled={false}
              onClick={() => handleToevoegen()}
            >
              <IconToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
              <span className="ml-2">Toevoegen</span>
            </button>
            <button
              className="btn btn-sm btn-light d-flex align-items-center ml-3"
              style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
              disabled={urlState.selectie.length === 0}
              onClick={() => handleVerwijderen()}
            >
              <IconVerwijderen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
              <span className="ml-2">Verwijderen</span>
            </button>
            <div className="d-flex flex-fill ml-3">
              <FilterBalkV2
                filters={filters}
                filterData={urlState.filterdata}
                onFilterDataChange={(x) => {
                  setUrlStateSync('filterdata', x);
                }}
                onFilterSchemaChange={(x) => {
                  setFilterSchema(x);
                }}
              />
            </div>
          </div>
        }
        body={
          <div className="d-flex flex-fill flex-column" style={{ width: '100%', height: '100%' }}>
            {webteksten === null ? (
              <LoadingSpinner />
            ) : (
              <GridStyleWrapper height="calc(100vh - 100px)">
                <Grid getRowId={keyExtractor} rows={webteksten} columns={kolommen}>
                  <DataTypeProvider
                    for={[nameOf<IOphalenWebtekstenResultElement>('NaamEnum')]}
                    formatterComponent={(formatterProps) => <span>{formatterProps.value}</span>}
                  />

                  <EditingState
                    onCommitChanges={async (changes) => {
                      if (changes.deleted !== undefined && changes.deleted.length > 0) {
                        const ids = changes.deleted as number[];
                      }
                    }}
                    onEditingRowIdsChange={(rowIds) => {
                      const id = rowIds[rowIds.length - 1] as number;
                      setUrlStateSync('wijzigenDialoogState', { id });
                    }}
                  />

                  <SortingState defaultSorting={[]} />
                  <IntegratedSorting />

                  <VirtualTable messages={{ noData: 'Geen teksten aanwezig' }} />
                  <TableColumnResizing defaultColumnWidths={kolomBreedtes} />

                  <TableHeaderRow showSortingControls />
                  <TableEditColumn
                    width={35}
                    commandComponent={DXTableEditColumnCommandComponent}
                    cellComponent={DXTableEditColumnCellComponent}
                    showEditCommand
                  />
                  <SelectionState
                    selection={urlState.selectie}
                    onSelectionChange={(x) => setUrlStateSync('selectie', x as number[])}
                  />
                  <TableSelection cellComponent={DXTableCheckboxComponent} />
                </Grid>
              </GridStyleWrapper>
            )}
          </div>
        }
      />
      {urlState.toevoegenDialoogState !== null && (
        <ToevoegenDialoog
          open
          onSuccess={async () => {
            setUrlStateSync('toevoegenDialoogState', null);
            await ophalenWebteksten();
          }}
          onAnnuleren={() => setUrlStateSync('toevoegenDialoogState', null)}
        />
      )}

      {urlState.wijzigenDialoogState !== null && (
        <WijzigenDialoog
          open
          id={urlState.wijzigenDialoogState.id}
          onSuccess={async () => {
            setUrlStateSync('wijzigenDialoogState', null);
            await ophalenWebteksten();
          }}
          onAnnuleren={() => setUrlStateSync('wijzigenDialoogState', null)}
        />
      )}
    </>
  );
};

export default Webteksten;
function _uniq(arg0: number[]) {
  throw new Error('Function not implemented.');
}
