import api from '../../index';
import {
  IConcurrent,
  IConcurrentAanbodscan,
  IConcurrentProduct,
  IConcurrentProductData,
  IConcurrentProductKoppeling,
  IOphalenConcurrentenV2Params,
  IOphalenConcurrentenV2Result,
  IOphalenConcurrentProductenParams,
} from '../../../../../shared/src/api/v2/concurrent';
import {
  ILeegGeneriekResult,
  IOphalenGeneriekParams,
  ISelectieGeneriekParams,
} from '../../../../../shared/src/api/v2/generiek';
import vergelijkingspunt from './vergelijkingspunt';
import ICheckData from '../../../../../shared/src/models/ICheckData';

const endpoints = {
  vergelijkingspunt,
  // concurrenten
  ophalenConcurrenten: async (): Promise<IConcurrent[]> => {
    return await api.post('/v2/concurrentie/ophalen-concurrenten', {});
  },
  ophalenConcurrentenV2: async (
    params: IOphalenConcurrentenV2Params,
  ): Promise<IOphalenConcurrentenV2Result> => {
    return await api.post('/v2/concurrentie/ophalen-concurrenten-v2', params);
  },
  toevoegenOfWijzigenConcurrent: async (params: IConcurrent): Promise<ILeegGeneriekResult> => {
    return await api.post('/v2/concurrentie/toevoegen-of-wijzigen-concurrent', params);
  },
  checkVerwijderenConcurrenten: async (params: ISelectieGeneriekParams): Promise<ICheckData> => {
    return await api.post('/v2/concurrentie/check-verwijderen-concurrenten', params);
  },
  verwijderenConcurrenten: async (
    params: ISelectieGeneriekParams,
  ): Promise<ILeegGeneriekResult> => {
    return await api.post('/v2/concurrentie/verwijderen-concurrenten', params);
  },
  ophalenProducten: async (
    params: IOphalenConcurrentProductenParams,
  ): Promise<IConcurrentProduct[]> => {
    return await api.post('/v2/concurrentie/ophalen-producten', params);
  },
  toevoegenOfWijzigenProduct: async (params: IConcurrentProduct): Promise<ILeegGeneriekResult> => {
    return await api.post('/v2/concurrentie/toevoegen-of-wijzigen-product', params);
  },
  verwijderenProducten: async (params: ISelectieGeneriekParams): Promise<ILeegGeneriekResult> => {
    return await api.post('/v2/concurrentie/verwijderen-product', params);
  },

  ophalenProductData: async (params: IOphalenGeneriekParams): Promise<IConcurrentProductData[]> => {
    return await api.post('/v2/concurrentie/ophalen-product-data', params);
  },

  ophalenProductModelKoppelingen: async (
    params: IOphalenGeneriekParams,
  ): Promise<IConcurrentProductKoppeling[]> => {
    return await api.post('/v2/concurrentie/ophalen-productmodel-koppelingen', params);
  },
  koppelenProductModel: async (
    params: IConcurrentProductKoppeling,
  ): Promise<ILeegGeneriekResult> => {
    return await api.post('/v2/concurrentie/koppelen-productmodel', params);
  },
  ontkoppelenProductModellen: async (
    params: ISelectieGeneriekParams,
  ): Promise<ILeegGeneriekResult> => {
    return await api.post('/v2/concurrentie/ontkoppelen-productmodellen', params);
  },

  ophalenScans: async (params: IOphalenGeneriekParams): Promise<IConcurrentAanbodscan[]> => {
    return await api.post('/v2/concurrentie/ophalen-scans', params);
  },
  startenScan: async (params: any): Promise<ILeegGeneriekResult> => {
    return await api.post('/v2/concurrentie/starten-scan', params);
  },
};

export default endpoints;
