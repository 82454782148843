import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import IDialoogProps from '../../../../../../../../core/IDialoogProps';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { Field, FieldProps, Formik, FormikActions, FormikErrors, FormikProps } from 'formik';
import Combobox, { IOptie } from '../../../../../../../../components/formulier/Combobox';
import { RootStoreContext } from '../../../../../../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import VinkVeld from '../../../../../../../../components/formulier/VinkVeld';
import api from '../../../../../../../../api';
import { EResultType } from '../../../../../../../../stores/CheckStore';
import * as Yup from 'yup';
import FormikVeldFout from '../../../../../../../../components/formulier/FormikVeldFout';
import { IOphalenRedenenAfkeuringResultElement } from '../../../../../../../../../../shared/src/api/v2/contract/afkeuring';
import Dialoog from '../../../../../../../../components/dialogen/Dialoog';
import { IOphalenRelatiesResultElementV2 } from '../../../../../../../../../../shared/src/api/v2/relatie';
import _ from 'lodash';
import LoadingSpinner from '../../../../../../../../components/Gedeeld/LoadingSpinner';
import nameOf from '../../../../../../../../core/nameOf';
import { IOphalenContractenResultElementV2 } from '../../../../../../../../../../shared/src/api/v2/contract';
import MultiCombobox, { IKolom } from '../../../../../../../../components/formulier/MultiCombobox';
import VeldWeergave from '../../../../../../../../components/formulier/VeldWeergave';
import RelatieSelectieV2, {
  ERelatieSelectieKolom,
} from '../../../../../../../../components/formulier/RelatieSelectieV2';
import { Provider } from '../../../../../../../../components/formulier/MultiComboboxV2';
import Skeleton from 'react-loading-skeleton';
import { EMutatiebron } from '../../../../../../../../bedrijfslogica/enums';

interface IProps extends IDialoogProps<{}> {
  relID: number;
  persID: number;
}

interface IFormikValues {
  persID: number | null;
  relID: number | null;
  bevestigingSturen: boolean;
  directFiatteren: boolean;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  relID: 'Aangeboden contracten overnemen van',
  directFiatteren: 'Direct fiatteren',
  bevestigingSturen: 'Bevestiging naar nieuwe huurder sturen',
  persID: 'Persoon',
};

const OvernemenContractDialoog = observer((props: IProps) => {
  const { onAnnuleren, onSuccess, open } = props;
  const { checkStore } = useContext(RootStoreContext);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const formikRef = useRef<Formik<IFormikValues>>(null);
  const [redenen, setRedenen] = useState<IOphalenRedenenAfkeuringResultElement[] | null>(null);
  const [relaties, setRelaties] = useState<IOphalenRelatiesResultElementV2[] | null>(null);
  const [contracten, setContracten] = useState<IOphalenContractenResultElementV2[] | null>(null);

  useEffect(() => {
    (async () => {
      // TODO Gil Uitsluiten contracten die in overdracht zitten
      // Haal de contracten op die aangeboden zijn
      const contractenResult = await api.v2.contract.ophalenContractenV2({
        filterSchema: {
          filters: [
            { naam: 'AANGEBODEN_VOOR_OVERNAME', data: true },
            { naam: 'STATUS_NAAM_ENUMS', data: ['LOPEND'] },
          ],
        },
      });

      setContracten(contractenResult.contracten);
    })();
  }, []);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      if (contracten === null) {
        actions.setSubmitting(false);
        return;
      }
      const cntIDs = contracten.filter((x) => x.RelID === values.relID).map((x) => x.CntID);

      const params = {
        relID: props.relID,
        persID: props.persID,
        // aanbieder_relID: values.relID!,
        cntIDs,
        // contractdatum: null,
        ingangsdatum: null,
        // cntMutID: null,
        mutatiebron: EMutatiebron.Intern,
      };

      // Voor nu nog een aparte params voor de check. De check-functie wordt ook door de website aangeroepen, dit eerst nog aanpassen
      // Er wordt nog een aparte typedef gebruikt voor de check: ICheckOvernemenContractenParams ipv IOvernemenContractenParams

      const checkParams = {
        overnemer_RelID: props.relID,
        cntIDs,
      };

      const checkData = await api.v2.contract.overname.checkOvernemenContracten(checkParams);

      if (
        (
          await checkStore.controleren({
            checkData,
          })
        ).type === EResultType.Annuleren
      ) {
        actions.setSubmitting(false);
        return;
      }

      if (
        (
          await checkStore.bevestigen({
            inhoud: (
              <span>
                Wil je de {cntIDs.length} aangeboden contracten van de geselecteerde relatie laten
                overnemen door de vigerende relatie?
              </span>
            ),
          })
        ).type === EResultType.Annuleren
      ) {
        actions.setSubmitting(false);
        return;
      }

      const overnemenResult = await api.v2.contract.overname.overnemenContracten(params);

      if (values.directFiatteren) {
        await api.v2.contract.overname.fiatterenContracten({
          relID: props.relID,
          cntIDs: overnemenResult.CntIDs,
        });

        if (values.bevestigingSturen) {
          await api.v2.contract.overname.versturenBevestigingNieuweHuurder({
            relID: props.relID,
            persID: null,
            cntIDs: overnemenResult.CntIDs,
            kanaal: 'EMAIL',
          });
        }
      }

      onSuccess({});

      actions.setSubmitting(false);
    },
    [onSuccess, contracten, setHasSubmitted],
  );

  const initieleWaarden = useMemo<IFormikValues>(() => {
    return {
      persID: null,
      relID: null,
      bevestigingSturen: false,
      directFiatteren: true,
    };
  }, []);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        relID: Yup.number().nullable(false),
        // .required(teksten.formulier.E_VERPLICHT_VELD({ veldnaam: veldnamen.persID })),
      }),
    [],
  );

  const relatieSelectieProvider = useMemo<Provider<
    ERelatieSelectieKolom,
    IOphalenRelatiesResultElementV2
  > | null>(
    () =>
      contracten === null
        ? null
        : async (params) => {
            // Haal de relaties op voor de gevonden contracten
            const relIDs = _.uniq(
              contracten.filter((x) => x.RelID !== props.relID).map((x) => x.RelID),
            );

            const relatiesResult = await api.v2.relatie.ophalenRelaties({
              filterSchema: {
                filters: [{ naam: 'IDS', data: relIDs }],
              },
              orderSchema: {
                orders: [
                  {
                    naam: 'WEERGAVENAAM',
                    richting: 'ASC',
                  },
                ],
              },
              paginatie: params.paginatie,
            });

            const items = relatiesResult.relaties.reduce(
              (acc, curr, idx) => ({
                ...acc,
                [params.paginatie.index + idx]: curr,
              }),
              params.huidigeBron,
            );

            return {
              items,
              totaalAantal: relatiesResult.totaalAantal,
            };
          },
    [contracten],
  );

  return (
    <Dialoog index={props.dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Overnemen contracten</ModalTitle>
      </ModalHeader>
      <Formik<IFormikValues>
        onSubmit={handleSubmit}
        initialValues={initieleWaarden}
        // isInitialValid
        ref={formikRef}
        validationSchema={validationSchema}
        render={(formikProps: FormikProps<IFormikValues>) => {
          const { submitForm, isSubmitting, values, isValid } = formikProps;

          return (
            <>
              <ModalBody>
                <div className="form-group">
                  <div className="row">
                    <div className="col-12">
                      <VeldWeergave>
                        Normaal dient de klant zelf de overname te regelen via de website. Door dit
                        hier te doen wordt het als een interne overname beschouwd.
                        <br />
                        Doe dit alleen als de overnemende partij hiervoor uitdrukkelijk de opdracht
                        heeft gegeven.
                      </VeldWeergave>
                    </div>
                    <div className="col-12 mt-3">
                      <label>{veldnamen.relID}</label>

                      <Field
                        name={nameOf<IFormikValues>('relID')}
                        render={(fieldProps: FieldProps<IFormikValues>) => {
                          const { field, form } = fieldProps;

                          if (relatieSelectieProvider === null) {
                            return <Skeleton />;
                          }

                          return (
                            <RelatieSelectieV2
                              relID={field.value}
                              onChange={(relID) => form.setFieldValue(field.name, relID)}
                              provider={relatieSelectieProvider}
                            />
                          );
                        }}
                      />
                    </div>

                    <div className="col-12 mt-3 d-flex align-items-center mt-3">
                      <Field
                        name={nameOf<IFormikValues>('directFiatteren')}
                        render={(fieldProps: FieldProps<IFormikValues>) => (
                          <>
                            <VinkVeld
                              aangevinkt={fieldProps.field.value}
                              onGewijzigd={(x) =>
                                fieldProps.form.setFieldValue(fieldProps.field.name, x)
                              }
                            />
                            <FormikVeldFout fieldProps={fieldProps} directTonen={hasSubmitted} />
                          </>
                        )}
                      />
                      <label className="ml-2 mt-1">{veldnamen.directFiatteren}</label>
                    </div>

                    {values.directFiatteren && (
                      <div className="col-12 mt-3 d-flex align-items-center mt-3">
                        <Field
                          name={nameOf<IFormikValues>('bevestigingSturen')}
                          render={(fieldProps: FieldProps<IFormikValues>) => (
                            <>
                              <VinkVeld
                                aangevinkt={fieldProps.field.value}
                                onGewijzigd={(x) =>
                                  fieldProps.form.setFieldValue(fieldProps.field.name, x)
                                }
                              />
                              <FormikVeldFout fieldProps={fieldProps} directTonen={hasSubmitted} />
                            </>
                          )}
                        />
                        <label className="ml-2 mt-1">{veldnamen.bevestigingSturen}</label>
                      </div>
                    )}
                  </div>
                </div>
              </ModalBody>
              <ModalFooter className="d-flex justify-content-start">
                <button
                  className="btn btn-primary"
                  onClick={submitForm}
                  disabled={isSubmitting || !isValid}
                  style={{ width: 100 }}
                >
                  Ok
                </button>
                <button className="btn btn-secondary" onClick={onAnnuleren} style={{ width: 100 }}>
                  Annuleren
                </button>
              </ModalFooter>
            </>
          );
        }}
      />
    </Dialoog>
  );
});

export default OvernemenContractDialoog;
