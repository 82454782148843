import * as React from 'react';
import { useCallback, useMemo } from 'react';
import ASPTabel from '../../tabel/ASPTabel';
import { ASPKolom, EAspKolomBreedteType } from '../../tabel/ASPTabel/types';
import {
  ISjabloon,
  ISjabloonCategorie,
  ISjabloonContext,
  ISjabloonInhoud,
  ISjabloonKanaal,
} from '../../../../../shared/src/api/v2/Sjabloon/sjabloon';
import { Kleur } from '../../../bedrijfslogica/constanten';
import Zoekveld from './Zoekveld';
import FilterBalkV2, { IFilter, IFilterData } from '../../FilterBalkV2';
import LoadingSpinner from '../../Gedeeld/LoadingSpinner';
import MultiSelect from '../../formulier/MultiSelect';
import { IOphalenTalenResultElement } from '../../../../../shared/src/api/v2/taal';
import { ICommunicatiekanaal } from '../../../../../shared/src/api/v2/bericht/Bericht';
import IRemoteData, { ERemoteDataState, mapRemoteData } from '../../../models/IRemoteData';
import Skeleton from 'react-loading-skeleton';
import _ from 'lodash';
import { EyeIcon } from '../../Icons';
import { IFilterSchema } from '../../../../../shared/src/models/filter';
import { EFilter, ESjabloonOplosserModus, SjabloonOplosserModus } from '../types';

enum EKolom {
  Preview,
  Naam,
  Categorie,
  Kanalen,
  Talen,
  Contexten,
  Kies,
  OpstellenOfVersturen,
  Selecteer,
}

interface IProps {
  zoekterm: string;
  onZoektermChange: (zoekterm: string) => void;
  sjablonen: IRemoteData<ISjabloon[]>;
  sjabloonKanalen: IRemoteData<ISjabloonKanaal[]>;
  sjabloonCategorieen: IRemoteData<ISjabloonCategorie[]>;
  sjabloonInhouden: IRemoteData<ISjabloonInhoud[]>;
  communicatiekanalen: IRemoteData<ICommunicatiekanaal[]>;
  talen: IRemoteData<IOphalenTalenResultElement[]>;
  contexten: IRemoteData<ISjabloonContext[]>;
  filterData: IRemoteData<IFilterData<EFilter>[]>;
  onFilterDataChange: (filterData: IFilterData<EFilter>[]) => void;
  onFilterSchemaChange: (filterSchema: IFilterSchema) => void;
  magTaalFilterWijzigen?: boolean;
  magVoldoetAanSjabloonContextFilterWijzigen?: boolean;
  magSjabloonCommunicatiekanaalFilterWijzigen?: boolean;
  magSjablooncategorieFilterWijzigen?: boolean;
  genereerbaarAangestuurdeSelectie: boolean;
  taalID: IRemoteData<number | null>;
  comKanID: IRemoteData<number | null>;
  heeftBenodigdeInformatieVoorGenereren: boolean;
  onPreviewAangevraagd: (sjabloon: ISjabloon) => void;
  onSjabloonGenereren: (sjabloon: ISjabloon) => void;
  onSjabloonOpstellen: (sjabloon: ISjabloon) => void;
  onSjabloonVersturen: (sjabloon: ISjabloon) => void;
  onSjabloonSelecteren: (sjabloon: ISjabloon) => void;
  isBezig: boolean;
  modus: SjabloonOplosserModus;
}

const RechterDeel = (props: IProps) => {
  const sjabloonKanalenBijSjabID = useMemo<IRemoteData<Record<number, ISjabloonKanaal[]>>>(
    () =>
      mapRemoteData(props.sjabloonKanalen, (data) =>
        data.reduce<Record<number, ISjabloonKanaal[]>>(
          (acc, curr) => ({
            ...acc,
            [curr.SjabID]: [...(acc[curr.SjabID] ?? []), curr],
          }),
          {},
        ),
      ),
    [props.sjabloonKanalen],
  );

  const sjabloonInhoudBijSjabKanID = useMemo<IRemoteData<Record<number, ISjabloonInhoud[]>>>(
    () =>
      mapRemoteData(props.sjabloonInhouden, (data) =>
        data.reduce<Record<number, ISjabloonInhoud[]>>(
          (acc, curr) => ({
            ...acc,
            [curr.SjabKanID]: [...(acc[curr.SjabKanID] ?? []), curr],
          }),
          {},
        ),
      ),
    [props.sjabloonInhouden],
  );

  const sjabloonCategorieenBijID = useMemo<IRemoteData<Record<number, ISjabloonCategorie>>>(
    () =>
      mapRemoteData(props.sjabloonCategorieen, (data) =>
        data.reduce(
          (acc, curr) => ({
            ...acc,
            [curr.SjabCatID]: curr,
          }),
          {},
        ),
      ),
    [props.sjabloonCategorieen],
  );

  const communicatiekanaalBijID = useMemo<IRemoteData<Record<number, ICommunicatiekanaal>>>(
    () =>
      mapRemoteData(props.communicatiekanalen, (data) =>
        data.reduce(
          (acc, curr) => ({
            ...acc,
            [curr.ComKanID]: curr,
          }),
          {},
        ),
      ),
    [props.communicatiekanalen],
  );

  const taalBijID = useMemo<IRemoteData<Record<number, IOphalenTalenResultElement>>>(
    () =>
      mapRemoteData(props.talen, (data) =>
        data.reduce(
          (acc, curr) => ({
            ...acc,
            [curr.TaalID]: curr,
          }),
          {},
        ),
      ),
    [props.talen],
  );

  const contextenBijSjabCtxID = useMemo<IRemoteData<Record<number, ISjabloonContext>>>(
    () =>
      mapRemoteData(props.contexten, (data) =>
        data.reduce<Record<number, ISjabloonContext>>(
          (acc, curr) => ({
            ...acc,
            [curr.SjabCtxID]: curr,
          }),
          {},
        ),
      ),
    [props.contexten],
  );

  const kanSjabloonGenereren = useCallback(
    (sjabloon: ISjabloon) => {
      if (
        !props.heeftBenodigdeInformatieVoorGenereren ||
        communicatiekanaalBijID.state === ERemoteDataState.Pending ||
        sjabloonKanalenBijSjabID.state === ERemoteDataState.Pending ||
        props.comKanID.state === ERemoteDataState.Pending
      ) {
        return false;
      }
      let comKanID: number | null = null;
      if (props.comKanID.data !== null) {
        comKanID = props.comKanID.data;
      } else {
        const kanalen = sjabloonKanalenBijSjabID.data![sjabloon.ID];
        if (kanalen.length === 1) {
          const kanaal = kanalen[0];
          comKanID = kanaal.ComKanID;
        }
      }

      if (comKanID === null) {
        return false;
      }

      const communicatiekanaal = communicatiekanaalBijID.data![comKanID];
      const ondersteundeCommunicatiekanalen = ['EMAIL', 'SMS', 'WHATSAPP', 'BESTAND_HTML'];
      return ondersteundeCommunicatiekanalen.includes(communicatiekanaal.NaamEnum);
    },
    [
      props.heeftBenodigdeInformatieVoorGenereren,
      communicatiekanaalBijID,
      props.comKanID,
      sjabloonInhoudBijSjabKanID,
    ],
  );

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.SjabloonCategorieIds,
        altijdWeergevenInBalk: true,
        actiefMuteerbaar: !props.isBezig && (props.magSjablooncategorieFilterWijzigen ?? true),
        weergave: (weergaveProps) => {
          return (
            <span className="d-flex align-items-center">
              Categorie
              <span className="ml-2">
                {props.sjabloonCategorieen.state === ERemoteDataState.Pending ? (
                  <LoadingSpinner />
                ) : (
                  <MultiSelect
                    value={weergaveProps.data}
                    onChange={(x) => {
                      weergaveProps.onDataChange(x);
                      weergaveProps.setIsActief(true);
                      weergaveProps.toepassen();
                    }}
                    opties={props.sjabloonCategorieen.data!.map((x) => ({
                      key: x.SjabCatID,
                      weergave: x.Naam,
                    }))}
                    disabled={props.isBezig || props.magSjablooncategorieFilterWijzigen === false}
                  />
                )}
              </span>
            </span>
          );
        },
      },
      {
        naam: EFilter.HeeftSjabloonCommunicatiekanaalIds,
        altijdWeergevenInBalk: true,
        actiefMuteerbaar:
          !props.isBezig && (props.magSjabloonCommunicatiekanaalFilterWijzigen ?? true),
        weergave: (weergaveProps) => {
          return (
            <span className="d-flex align-items-center">
              Kanaal
              <span className="ml-2">
                {props.communicatiekanalen.state === ERemoteDataState.Pending ? (
                  <LoadingSpinner />
                ) : (
                  <MultiSelect
                    value={weergaveProps.data}
                    onChange={(x) => {
                      weergaveProps.onDataChange(x);
                      weergaveProps.setIsActief(true);
                      weergaveProps.toepassen();
                    }}
                    opties={props.communicatiekanalen.data!.map((x) => ({
                      key: x.ComKanID,
                      weergave: x.Naam,
                    }))}
                    disabled={
                      props.isBezig || props.magSjabloonCommunicatiekanaalFilterWijzigen === false
                    }
                  />
                )}
              </span>
            </span>
          );
        },
      },
      {
        naam: EFilter.HeeftTaalIds,
        altijdWeergevenInBalk: true,
        actiefMuteerbaar: !props.isBezig && (props.magTaalFilterWijzigen ?? true),
        weergave: (weergaveProps) => {
          return (
            <span className="d-flex align-items-center">
              Taal
              <span className="ml-2">
                {props.talen.state === ERemoteDataState.Pending ? (
                  <LoadingSpinner />
                ) : (
                  <MultiSelect
                    value={weergaveProps.data}
                    onChange={(x) => {
                      weergaveProps.onDataChange(x);
                      weergaveProps.setIsActief(true);
                      weergaveProps.toepassen();
                    }}
                    opties={props.talen.data!.map((x) => ({
                      key: x.TaalID,
                      weergave: x.Naam,
                    }))}
                    disabled={props.isBezig || props.magTaalFilterWijzigen === false}
                  />
                )}
              </span>
            </span>
          );
        },
      },
      {
        naam: EFilter.VoldoetAanSjabloonContextIds,
        altijdWeergevenInBalk: true,
        actiefMuteerbaar:
          !props.isBezig && (props.magVoldoetAanSjabloonContextFilterWijzigen ?? true),
        weergave: (weergaveProps) => {
          return (
            <span className="d-flex align-items-center">
              Voldoet aan contexten
              <span className="ml-2">
                {props.contexten.state === ERemoteDataState.Pending ? (
                  <LoadingSpinner />
                ) : (
                  <MultiSelect
                    value={weergaveProps.data}
                    onChange={(x) => {
                      weergaveProps.onDataChange(x);
                      weergaveProps.setIsActief(true);
                      weergaveProps.toepassen();
                    }}
                    opties={props.contexten.data!.map((x) => ({
                      key: x.SjabCtxID,
                      weergave: x.Naam,
                    }))}
                    disabled={
                      props.isBezig || props.magVoldoetAanSjabloonContextFilterWijzigen === false
                    }
                  />
                )}
              </span>
            </span>
          );
        },
      },
    ],
    [
      props.sjabloonCategorieen,
      props.contexten,
      props.talen,
      props.communicatiekanalen,
      props.magSjablooncategorieFilterWijzigen,
      props.magTaalFilterWijzigen,
      props.magSjabloonCommunicatiekanaalFilterWijzigen,
      props.magVoldoetAanSjabloonContextFilterWijzigen,
      props.isBezig,
    ],
  );

  const keyExtractor = useCallback((item: ISjabloon) => item.ID, []);

  const rijen = useMemo<IRemoteData<Record<number, ISjabloon>>>(
    () =>
      mapRemoteData(props.sjablonen, (data) =>
        data.reduce(
          (acc, curr, i) => ({
            ...acc,
            [i]: curr,
          }),
          {},
        ),
      ),
    [props.sjablonen],
  );

  const kolommen = useMemo<ASPKolom<EKolom, ISjabloon>[]>(() => {
    let laatsteKolom: ASPKolom<EKolom, ISjabloon>;
    switch (props.modus.type) {
      case ESjabloonOplosserModus.Genereer:
        laatsteKolom = {
          key: EKolom.Kies,
          breedteType: EAspKolomBreedteType.Vast,
          vasteBreedte: 60,
          renderer: (rij) => {
            if (props.isBezig || !kanSjabloonGenereren(rij)) {
              return null;
            }

            return (
              <a href="#" onClick={() => props.onSjabloonGenereren(rij)}>
                Kies
              </a>
            );
          },
        };
        break;
      case ESjabloonOplosserModus.Verstuur:
        laatsteKolom = {
          key: EKolom.OpstellenOfVersturen,
          breedteType: EAspKolomBreedteType.Vast,
          vasteBreedte: 160,
          renderer: (rij) => {
            if (props.isBezig || !kanSjabloonGenereren(rij)) {
              return null;
            }

            return (
              <div className="d-flex align-items-center">
                <a
                  href="#"
                  onClick={() => props.onSjabloonOpstellen(rij)}
                  className="mr-3"
                  title="Doorgeven aan het opstelformulier voor het gekozen kanaal"
                >
                  Opstellen
                </a>
                <a
                  href="#"
                  onClick={() => props.onSjabloonVersturen(rij)}
                  title="Versturen via het gekozen kanaal"
                >
                  Versturen
                </a>
              </div>
            );
          },
        };
        break;
      case ESjabloonOplosserModus.Selecteer:
        laatsteKolom = {
          key: EKolom.Selecteer,
          breedteType: EAspKolomBreedteType.Vast,
          vasteBreedte: 85,
          renderer: (rij) => {
            if (props.isBezig) {
              return null;
            }

            return (
              <a href="#" onClick={() => props.onSjabloonSelecteren(rij)}>
                Selecteer
              </a>
            );
          },
        };
        break;
    }

    return [
      {
        key: EKolom.Naam,
        label: 'Naam',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 300,
        renderer: (rij) => rij.Naam,
      },
      {
        key: EKolom.Categorie,
        label: 'Categorie',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 200,
        renderer: (rij) => {
          if (rij.SjabCatID === null) {
            return null;
          }
          if (sjabloonCategorieenBijID.state === ERemoteDataState.Pending) {
            return <Skeleton />;
          }

          const categorie = sjabloonCategorieenBijID.data![rij.SjabCatID]!;
          return categorie.Naam;
        },
      },
      {
        key: EKolom.Kanalen,
        label: 'Kanalen',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 175,
        renderer: (rij) => {
          if (
            sjabloonKanalenBijSjabID.state === ERemoteDataState.Pending ||
            communicatiekanaalBijID.state === ERemoteDataState.Pending
          ) {
            return <Skeleton />;
          }

          const kanalen = sjabloonKanalenBijSjabID.data![rij.ID];
          const comKanIDs = _.uniq(kanalen.map((x) => x.ComKanID)) as number[];
          const communicatiekanalen = comKanIDs.map(
            (comKanID) => communicatiekanaalBijID.data![comKanID]!,
          );

          return communicatiekanalen.map((x) => x.Naam).join(', ');
        },
      },
      {
        key: EKolom.Talen,
        label: 'Talen',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 200,
        renderer: (rij) => {
          if (
            taalBijID.state === ERemoteDataState.Pending ||
            sjabloonKanalenBijSjabID.state === ERemoteDataState.Pending ||
            sjabloonInhoudBijSjabKanID.state === ERemoteDataState.Pending
          ) {
            return <Skeleton />;
          }

          const kanalen = sjabloonKanalenBijSjabID.data![rij.ID];
          const inhouden = kanalen.flatMap((x) => sjabloonInhoudBijSjabKanID.data![x.ID]);
          const taalIDs = _.uniq(inhouden.map((x) => x.TaalID)) as number[];
          const talen = taalIDs.map((taalID) => taalBijID.data![taalID]);

          return talen.map((x) => x.Naam).join(', ');
        },
      },
      {
        key: EKolom.Contexten,
        label: 'Contexten',
        breedteType: EAspKolomBreedteType.Flex,
        flex: 1,
        minimaleVasteBreedte: 200,
        renderer: (rij) => {
          if (contextenBijSjabCtxID.state === ERemoteDataState.Pending) {
            return <Skeleton />;
          }

          const contexten = rij.sjabCtxs.map((x) => ({
            ...x,
            context: contextenBijSjabCtxID.data![x.sjabCtxID],
          }));
          return (
            <span>
              {contexten
                .map<React.ReactNode>((x) => {
                  if (x.alias !== undefined && x.alias !== null) {
                    return (
                      <span>
                        {x.isOptioneel && '['}
                        {x.context.Naam}&nbsp;(
                        <span style={{ fontWeight: 'bold', fontSize: 13, color: Kleur.Grijs }}>
                          {x.alias}
                        </span>
                        ){x.isOptioneel && ']'}
                      </span>
                    );
                  }
                  return (
                    <span>
                      {x.isOptioneel && '['}
                      {x.context.Naam}
                      {x.isOptioneel && ']'}
                    </span>
                  );
                })
                .map((item, index) => (
                  <React.Fragment key={index}>
                    {index > 0 && ', '}
                    {item}
                  </React.Fragment>
                ))}
            </span>
          );
        },
      },
      {
        key: EKolom.Preview,
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 40,
        renderer: (rij) => {
          const genereerbaar = kanSjabloonGenereren(rij);
          return (
            <button
              style={{
                border: 'none',
                background: 'none',
                outline: 'none',
                cursor: 'pointer',
              }}
              disabled={props.isBezig || !genereerbaar}
              title={
                genereerbaar
                  ? 'Preview weergeven'
                  : 'Heeft niet alle benodigde informatie om te genereren (taal, kanaal, genereerbaar aangestuurd)'
              }
              onClick={() => props.onPreviewAangevraagd(rij)}
            >
              <EyeIcon
                style={{
                  fill: genereerbaar && !props.isBezig ? Kleur.Grijs : Kleur.LichtGrijs,
                  width: 18,
                  height: 18,
                }}
              />
            </button>
          );
        },
      },
      laatsteKolom,
    ];
  }, [
    sjabloonKanalenBijSjabID,
    sjabloonInhoudBijSjabKanID,
    taalBijID,
    communicatiekanaalBijID,
    sjabloonCategorieenBijID,
    contextenBijSjabCtxID,
    kanSjabloonGenereren,
    props.onPreviewAangevraagd,
    props.onSjabloonGenereren,
    props.isBezig,
  ]);

  return (
    <div style={{ flex: 1 }} className="d-flex flex-column">
      <div
        style={{
          borderBottom: `1px solid ${Kleur.LichtGrijs}`,
        }}
        className="d-flex flex-column"
      >
        <div className="flex-fill pl-2 pt-2 pr-2">
          {props.filterData.state === ERemoteDataState.Pending ? (
            <Skeleton height={40} />
          ) : (
            <FilterBalkV2
              filters={filters}
              filterData={props.filterData.data!}
              onFilterDataChange={(x) => props.onFilterDataChange(x)}
              onFilterSchemaChange={(x) => props.onFilterSchemaChange(x)}
            />
          )}
        </div>
        <div className="p-2">
          <Zoekveld
            waarde={props.zoekterm}
            onChange={props.onZoektermChange}
            disabled={props.isBezig}
          />
        </div>
      </div>
      <ASPTabel
        rijen={rijen.state === ERemoteDataState.Pending ? {} : rijen.data!}
        kolommen={kolommen}
        keyExtractor={keyExtractor}
      />
    </div>
  );
};

export default RechterDeel;
