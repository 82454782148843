import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import LoadingSpinner from '../../../components/Gedeeld/LoadingSpinner';
import { RootStoreContext } from '../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import { IOphalenVerlofMutatiesResultElement } from '../../../../../shared/src/api/v2/medewerker/verlof';
import api from '../../../api';
import MenuLayout from '../../../components/MenuLayout';
import { IconToevoegen, IconVerwijderen } from '../../../components/Icons';
import { Kleur } from '../../../bedrijfslogica/constanten';
import useUrlState from '../../../core/useUrlState';
import FilterBalkV2, {
  IFilter,
  IFilterData,
  maakFilterSchema,
} from '../../../components/FilterBalkV2';
import { IFilterSchema } from '../../../../../shared/src/models/filter';
import MultiCombobox from '../../../components/formulier/MultiCombobox';
import { IOphalenMedewerkersResultElement } from '../../../../../shared/src/api/v2/medewerker';
import ToevoegenMutatieDialoog from './ToevoegenMutatieDialoog';
import { EResultType } from '../../../stores/CheckStore';
import ASPTabel from '../../../components/tabel/ASPTabel';
import {
  ASPKolom,
  EAspKolomBreedteType,
  ESortering,
  ESorteringModus,
  IAspKolomSorteringItem,
  IASPTabelSorteringOpties,
} from '../../../components/tabel/ASPTabel/types';
import { format } from 'date-fns';
import { IOrderSchemaOrder } from '../../../../../shared/src/models/order';
import WijzigenMutatieDialoog from './WijzigenMutatieDialoog';

interface IProps extends RouteComponentProps {}

export enum EFilter {
  Medewerker = 'MDW_IDS',
  Jaar = 'JAAR_IN',
}

export interface IToevoegenMutatieDialoogState {
  mdwID: number | null;
}

export interface IWijzigenMutatieDialoogState {
  id: number;
}

enum EKolom {
  DatumVem,
  DatumTem,
  Medewerker,
  MedewerkerNummer,
  AantalUren,
  Soort,
  Notities,
}

interface IUrlState {
  selectie: number[];
  filterData: IFilterData<EFilter>[];
  toevoegenMutatieDialoogState: IToevoegenMutatieDialoogState | null;
  sortering: IAspKolomSorteringItem<EKolom>[];
  wijzigenMutatieDialoogState: IWijzigenMutatieDialoogState | null;
}

const Verlofregistratie: React.FC<IProps> = observer((props) => {
  const defaultUrlState = useMemo<IUrlState>(() => {
    return {
      selectie: [],
      filterData: [
        {
          naam: EFilter.Medewerker,
          data: [],
          isActief: false,
        },
        {
          naam: EFilter.Jaar,
          data: [new Date().getFullYear()],
          isActief: true,
        },
      ],
      toevoegenMutatieDialoogState: null,
      sortering: [
        {
          key: EKolom.DatumVem,
          sortering: ESortering.Descending,
        },
        {
          key: EKolom.Medewerker,
          sortering: ESortering.Ascending,
        },
        {
          key: EKolom.MedewerkerNummer,
          sortering: ESortering.Ascending,
        },
      ],
      wijzigenMutatieDialoogState: null,
    };
  }, []);

  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const { checkStore } = useContext(RootStoreContext);

  const [mutaties, setMutaties] = useState<IOphalenVerlofMutatiesResultElement[] | null>(null);
  const [medewerkers, setMedewerkers] = useState<IOphalenMedewerkersResultElement[] | null>(null);

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.Medewerker,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          if (medewerkers === null) {
            return null;
          }

          return (
            <div className="d-flex align-items-center">
              <span className="mr-2">Medewerker</span>
              <MultiCombobox<number, IOphalenMedewerkersResultElement>
                sleutelExtractor={(x) => x.MdwID}
                representatieFabriek={(rij: IOphalenMedewerkersResultElement) => {
                  const naam =
                    rij.persoon!.Voornaam +
                    (rij.persoon!.Voorvoegsel !== null ? ` ` + rij.persoon!.Voorvoegsel : ``) +
                    ' ' +
                    rij.persoon!.Achternaam;

                  return `${naam}`;
                }}
                waarde={weergaveProps.data.length === 0 ? null : weergaveProps.data[0]}
                onWaardeChange={(x) => {
                  weergaveProps.onDataChange(x === null ? [] : [x]);
                  weergaveProps.setIsActief(true);
                  weergaveProps.toepassen();
                }}
                opties={medewerkers}
                kolommen={[
                  {
                    key: 'Naam' as any,
                    label: 'Naam',
                    breedte: 200,
                    formatFabriek: (x) =>
                      x.persoon!.Voornaam +
                      (x.persoon!.Voorvoegsel !== null ? ` ` + x.persoon!.Voorvoegsel : ``) +
                      ' ' +
                      x.persoon!.Achternaam,
                  },
                ]}
                options={{
                  geenWaardeBericht: 'Geen keuze gemaakt',
                }}
                isWisbaar={true}
              />
            </div>
          );
        },
      },
      {
        naam: EFilter.Jaar,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => (
          <div className="d-flex align-items-center">
            <span className="mr-2">Jaar</span>
            <input
              type="number"
              className="form-control"
              style={{ width: 100 }}
              value={weergaveProps.data}
              onChange={(ev) => {
                weergaveProps.onDataChange(ev.target.value);
                weergaveProps.toepassen();
              }}
              onKeyUp={(ev) => {
                if (ev.key === 'Enter') {
                  weergaveProps.toepassen();
                }
              }}
            />
          </div>
        ),
      },
    ],
    [medewerkers],
  );

  const [filterSchema, setFilterSchema] = useState<IFilterSchema>(
    useMemo(() => maakFilterSchema(urlState.filterData), []),
  );

  const ophalenMutaties = useCallback(async () => {
    if (filterSchema.filters === undefined) {
      return;
    }

    const orderschemaOrdersVanSortering = urlState.sortering.flatMap((s): IOrderSchemaOrder[] => {
      switch (s.key) {
        case EKolom.DatumVem: {
          return [
            {
              naam: 'DATUM_VEM',
              richting: s.sortering === ESortering.Ascending ? 'ASC' : 'DESC',
            },
          ];
        }
        case EKolom.DatumTem: {
          return [
            {
              naam: 'DATUM_TEM',
              richting: s.sortering === ESortering.Ascending ? 'ASC' : 'DESC',
            },
          ];
        }
        case EKolom.Medewerker: {
          return [
            {
              naam: 'VOORNAAM_MEDEWERKER',
              richting: s.sortering === ESortering.Ascending ? 'ASC' : 'DESC',
            },
            {
              naam: 'ACHTERNAAM_MEDEWERKER',
              richting: s.sortering === ESortering.Ascending ? 'ASC' : 'DESC',
            },
          ];
        }
        case EKolom.MedewerkerNummer: {
          return [
            {
              naam: 'NUMMER_MEDEWERKER',
              richting: s.sortering === ESortering.Ascending ? 'ASC' : 'DESC',
            },
          ];
        }
        case EKolom.AantalUren: {
          return [
            {
              naam: 'AANTAL_UREN',
              richting: s.sortering === ESortering.Ascending ? 'ASC' : 'DESC',
            },
          ];
        }
        case EKolom.Soort: {
          return [];
        }
        case EKolom.Notities: {
          return [
            {
              naam: 'NOTITIES',
              richting: s.sortering === ESortering.Ascending ? 'ASC' : 'DESC',
            },
          ];
        }
      }

      return [];
    });

    const orders: IOrderSchemaOrder[] = [
      ...orderschemaOrdersVanSortering,
      {
        naam: 'RECORD_TOEGEVOEGD',
        richting: 'DESC',
      },
      // {
      //   naam: 'DATUM_TEM',
      //   richting: 'DESC',
      // },
    ];

    const mutatiesResult = (
      await api.v2.medewerker.verlof.ophalenVerlofMutaties({
        filterSchema: {
          filters: [
            ...filterSchema.filters!,
            // { naam: 'IN_DIENST', data: true },
            // { naam: 'NIET_IN_NAAM_ENUMS', data: [EVerlofMutatiesoort.Saldo2014] },
          ],
        },
        orderSchema: {
          orders,
        },
      })
    ).mutaties;

    setMutaties(mutatiesResult);
  }, [filterSchema.filters!, JSON.stringify(urlState.sortering)]);

  useEffect(() => {
    ophalenMutaties();
  }, [ophalenMutaties]);

  const ophalenMedewerkers = useCallback(async () => {
    const medewerkersResult = (
      await api.v2.medewerker.ophalenMedewerkers({
        filterSchema: {
          filters: [
            { naam: 'IN_DIENST', data: true },
            { naam: 'IS_ACTIEF', data: true },
          ],
        },
        orderSchema: {
          orders: [
            {
              naam: 'ACHTERNAAM',
              richting: 'ASC',
            },
          ],
        },
      })
    ).medewerkers;

    setMedewerkers(medewerkersResult);
  }, []);

  useEffect(() => {
    ophalenMedewerkers();
  }, [ophalenMedewerkers]);

  const keyExtractor = useCallback((row: IOphalenVerlofMutatiesResultElement) => row.ID, []);

  const kolommen = useMemo<ASPKolom<EKolom, IOphalenVerlofMutatiesResultElement>[]>(
    () => [
      {
        key: EKolom.DatumVem,
        label: 'Datum van',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 135,
        renderer: (rij) => format(new Date(rij.DatumVem), 'dd-MM-yyyy'),
      },
      {
        key: EKolom.DatumTem,
        label: 'Datum t/m',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 135,
        renderer: (rij) =>
          rij.DatumTem === null ? null : format(new Date(rij.DatumTem), 'dd-MM-yyyy'),
      },
      {
        key: EKolom.Medewerker,
        label: 'Medewerker',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 200,
        renderer: (rij) => {
          if (rij.medewerker.persoon === null) {
            return null;
          }
          const p = rij.medewerker.persoon;

          return (
            <span>
              {p.Voornaam}
              {p.Voorvoegsel !== null ? ' ' + p.Voorvoegsel : ''}
              {' ' + p.Achternaam}
            </span>
          );
        },
      },
      {
        key: EKolom.MedewerkerNummer,
        label: 'Mdw.nr.',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 90,
        renderer: (rij) => rij.medewerker.Nummer,
      },
      {
        key: EKolom.AantalUren,
        label: 'Uren',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 90,
        renderer: (rij) => {
          if (rij.AantalUren > 0) {
            return (
              <span style={{ color: rij.soort.ProForma ? Kleur.Grijs : undefined }}>
                +{rij.AantalUren}
              </span>
            );
          }

          return (
            <span style={{ color: rij.soort.ProForma ? Kleur.Grijs : undefined }}>
              {rij.AantalUren}
            </span>
          );
        },
      },
      {
        key: EKolom.Soort,
        label: 'Soort',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 225,
        renderer: (rij) => rij.soort.Naam,
      },
      {
        key: EKolom.Notities,
        label: 'Notities',
        breedteType: EAspKolomBreedteType.Flex,
        flex: 1,
        renderer: (rij) => rij.Notities,
      },
    ],
    [],
  );

  const sorteringOpties = useMemo<IASPTabelSorteringOpties<EKolom>>(
    () => ({
      kolomOpties: {
        [EKolom.DatumVem]: {
          magSorteren: true,
        },
        [EKolom.DatumTem]: {
          magSorteren: true,
        },
        [EKolom.Medewerker]: {
          magSorteren: true,
        },
        [EKolom.MedewerkerNummer]: {
          magSorteren: true,
        },
        [EKolom.AantalUren]: {
          magSorteren: true,
        },
        [EKolom.Notities]: {
          magSorteren: true,
        },
      },
      modus: ESorteringModus.Whitelist,
    }),
    [],
  );

  const handleWijzigenRij = useCallback(async (rij: IOphalenVerlofMutatiesResultElement) => {
    setUrlStateSync('wijzigenMutatieDialoogState', { id: rij.ID });
  }, []);

  return (
    <>
      {mutaties === null || medewerkers === null ? (
        <div className="justify-content-center align-items-center d-flex flex-fill">
          <LoadingSpinner />
        </div>
      ) : (
        <MenuLayout
          menu={
            <div className="d-flex align-items-center">
              <button
                className="btn btn-sm btn-light d-flex align-items-center"
                style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                disabled={false}
                onClick={() => {
                  const filterMedewerker =
                    filterSchema.filters!.find((x) => x.naam === EFilter.Medewerker) ?? null;
                  const mdwIDs = filterMedewerker !== null ? filterMedewerker.data : null;
                  setUrlStateSync('toevoegenMutatieDialoogState', {
                    mdwID: mdwIDs !== null ? mdwIDs[0] : null,
                  });
                }}
              >
                <IconToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                <span className="ml-2">Toevoegen mutatie</span>
              </button>

              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-3"
                style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                disabled={urlState.selectie.length === 0}
                onClick={async () => {
                  const params = {
                    ids: urlState.selectie,
                  };

                  const checkData = await api.v2.medewerker.verlof.checkVerwijderenMutaties(params);
                  const checkResult = await checkStore.controleren({ checkData });
                  if (checkResult.type === EResultType.Annuleren) {
                    return;
                  }

                  if (
                    (
                      await checkStore.bevestigen({
                        inhoud: `Wil je de geselecteerde mutaties verwijderen?`,
                      })
                    ).type === EResultType.Annuleren
                  ) {
                    return;
                  }

                  await api.v2.medewerker.verlof.verwijderenMutaties(params);
                  ophalenMutaties();

                  return;
                }}
              >
                <IconVerwijderen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                <span className="ml-2">Verwijderen</span>
              </button>

              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-3"
                style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                onClick={async () => {
                  const maandNu = new Date().getMonth() + 1;
                  const vigerendJaar =
                    maandNu > 6 ? new Date().getFullYear() + 1 : new Date().getFullYear();

                  const params = { vigerendJaar };
                  const checkData = await api.v2.medewerker.verlof.checkToekennenVakantiedagen(
                    params,
                  );
                  const checkResult = await checkStore.controleren({ checkData });
                  if (checkResult.type === EResultType.Annuleren) {
                    return;
                  }

                  if (
                    (
                      await checkStore.bevestigen({
                        inhoud: (
                          <span>
                            Hiermee wordt voor elke interne, actieve medewerker de jaarlijkse
                            toekenning van verlofdagen toegepast. Dit betreft zowel de wettelijke
                            als niet-wettelijke dagen.
                          </span>
                        ),
                      })
                    ).type === EResultType.Annuleren
                  ) {
                    return;
                  }

                  await api.v2.medewerker.verlof.toekennenVakantiedagen(params);
                  ophalenMutaties();
                }}
              >
                <IconToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                <span className="ml-2">Jaarlijkse toekenning</span>
              </button>

              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-3"
                style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                disabled={urlState.selectie.length !== 0}
                onClick={async () => {
                  const maandNu = new Date().getMonth() + 1;
                  const vigerendJaar =
                    maandNu > 6 ? new Date().getFullYear() + 1 : new Date().getFullYear();

                  const params = { vigerendJaar };
                  const checkData = await api.v2.medewerker.verlof.checkOverhevelenSaldoVorigJaar(
                    params,
                  );
                  const checkResult = await checkStore.controleren({ checkData });
                  if (checkResult.type === EResultType.Annuleren) {
                    return;
                  }

                  if (
                    (
                      await checkStore.bevestigen({
                        inhoud: (
                          <span>
                            Hiermee wordt voor elke interne, actieve medewerker het saldo van vorig
                            jaar overgeheveld naar het vigerende jaar.
                          </span>
                        ),
                      })
                    ).type === EResultType.Annuleren
                  ) {
                    return;
                  }

                  await api.v2.medewerker.verlof.overhevelenSaldoVorigJaar(params);
                  ophalenMutaties();
                }}
              >
                <IconToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                <span className="ml-2">Overhevelen saldo vorig jaar</span>
              </button>

              <div className="dd-flex flex-fill ml-3">
                <FilterBalkV2
                  filters={filters}
                  filterData={urlState.filterData}
                  onFilterDataChange={(value) => setUrlStateSync('filterData', value)}
                  onFilterSchemaChange={(x) => setFilterSchema(x)}
                />
              </div>
            </div>
          }
          body={
            <ASPTabel
              rijen={mutaties}
              kolommen={kolommen}
              keyExtractor={keyExtractor}
              sortering={urlState.sortering}
              onSorteringChange={(x) => setUrlStateSync('sortering', x)}
              sorteringOpties={sorteringOpties}
              onWijzigenRij={handleWijzigenRij}
              selectie={urlState.selectie}
              onSelectieChange={(selectie) => setUrlStateSync('selectie', selectie)}
            />
          }
        />
      )}
      {urlState.toevoegenMutatieDialoogState !== null && (
        <ToevoegenMutatieDialoog
          open
          mdwID={urlState.toevoegenMutatieDialoogState.mdwID}
          onSuccess={() => {
            ophalenMutaties();
            setUrlStateSync('toevoegenMutatieDialoogState', null);
          }}
          onAnnuleren={() => {
            setUrlStateSync('toevoegenMutatieDialoogState', null);
          }}
        />
      )}
      {urlState.wijzigenMutatieDialoogState !== null && (
        <WijzigenMutatieDialoog
          open
          id={urlState.wijzigenMutatieDialoogState.id}
          onSuccess={() => {
            ophalenMutaties();
            setUrlStateSync('wijzigenMutatieDialoogState', null);
          }}
          onAnnuleren={() => {
            setUrlStateSync('wijzigenMutatieDialoogState', null);
          }}
        />
      )}
    </>
  );
});

export default Verlofregistratie;
