import React from 'react';
import ICheckData from '../../../../shared/src/models/ICheckData';
import { Message, Root } from './style';
import { IconError, IconWarning } from '../Icons';
import { Kleur } from '../../bedrijfslogica/constanten';

interface IProps {
  checkData: ICheckData;
}

const CheckDataMelding: React.FC<IProps> = (props) => {
  const { warnings, errors } = props.checkData;

  return (
    <Root>
      {errors.map((error, i) => (
        <Message key={i}>
          <span className="icon">
            <IconError style={{ fill: Kleur.Rood }} />
          </span>
          <span className="content ml-3">{error}</span>
        </Message>
      ))}
      {warnings.map((warning, i) => (
        <Message key={i}>
          <span className="icon">
            <IconWarning style={{ fill: Kleur.Geel }} />
          </span>
          <span className="content ml-3">{warning}</span>
        </Message>
      ))}
    </Root>
  );
};

export default CheckDataMelding;
