import * as React from 'react';
import { useEffect, useState } from 'react';
import Dialoog from '../Dialoog';
import IDialoogProps from '../../../core/IDialoogProps';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import Combobox from '../../formulier/Combobox';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../models/IRemoteData';
import { IOphalenFactuurkenmerkenResultElement } from '../../../../../shared/src/api/v2/factuur';

interface IDialoogResult {
  factuurKenmerkID: number;
}

interface IProps extends IDialoogProps<IDialoogResult> {
  initieelFactuurKenmerkIDProvider?: () => Promise<number | null>;
  factuurKenmerkenProvider: () => Promise<IOphalenFactuurkenmerkenResultElement[]>;
}

const FactuurkenmerkSelectieDialoog: React.FC<IProps> = (props) => {
  const [factuurKenmerkID, setFactuurKenmerkID] = useState<IRemoteData<number | null>>(
    createPendingRemoteData(),
  );
  const [factuurKenmerken, setFactuurKenmerken] = useState<
    IRemoteData<IOphalenFactuurkenmerkenResultElement[]>
  >(createPendingRemoteData());

  useEffect(() => {
    if (props.initieelFactuurKenmerkIDProvider === undefined) {
      setFactuurKenmerkID(createReadyRemoteData(null));
      return;
    }

    props
      .initieelFactuurKenmerkIDProvider()
      .then((locIntID) => setFactuurKenmerkID(createReadyRemoteData(locIntID)));
  }, []);

  useEffect(() => {
    props
      .factuurKenmerkenProvider()
      .then((factuurKenmerken) => setFactuurKenmerken(createReadyRemoteData(factuurKenmerken)));
  }, [props.factuurKenmerkenProvider]);

  return (
    <Dialoog index={props.dialoogIndex ?? 0}>
      <ModalHeader>
        <ModalTitle>Selecteer een factuurkenmerk</ModalTitle>
      </ModalHeader>

      <ModalBody>
        {factuurKenmerkID.state === ERemoteDataState.Pending ||
        factuurKenmerken.state === ERemoteDataState.Pending ? (
          <div>
            <span>Bezig met laden...</span>
          </div>
        ) : factuurKenmerken.data!.length === 0 ? (
          <p>Geen factuurkenmerken gevonden, voer deze eerst bij de locatie op.</p>
        ) : (
          <div>
            <label>Factuurkenmerk</label>
            <Combobox
              geselecteerd={factuurKenmerkID.data}
              opties={factuurKenmerken.data!.map((factuurKenmerk) => {
                return {
                  label: factuurKenmerk.Naam,
                  id: factuurKenmerk.ID,
                };
              })}
              onSelectieChange={(locIntID) => {
                setFactuurKenmerkID(createReadyRemoteData(locIntID));
              }}
              legeOptieTonen
              options={{ legeOptieTekst: 'Geen factuurkenmerk' }}
            />
          </div>
        )}
      </ModalBody>

      <ModalFooter className="d-flex flex-row justify-content-start">
        <button
          className="btn btn-primary d-flex align-items-center justify-content-center"
          onClick={() =>
            props.onSuccess({
              factuurKenmerkID: factuurKenmerkID.data!,
            })
          }
          style={{ width: 100 }}
          disabled={factuurKenmerkID.state === ERemoteDataState.Pending}
        >
          Ok
        </button>
        <button className="btn btn-secondary" onClick={props.onAnnuleren} style={{ width: 100 }}>
          Annuleren
        </button>
      </ModalFooter>
    </Dialoog>
  );
};

export default FactuurkenmerkSelectieDialoog;
