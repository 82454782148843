import { Formik } from 'formik';
import React, { useCallback, useContext, useState, useMemo, useEffect } from 'react';
import { ModalFooter, ModalTitle } from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/ModalHeader';
import { IOphalenRelatiesResultElementV2 } from '../../../../../shared/src/api/v2/relatie';
import api from '../../../api';
import IDialoogProps from '../../../core/IDialoogProps';
import Dialoog from '../../dialogen/Dialoog';
import LoadingSpinner from '../../Gedeeld/LoadingSpinner';
import Tabblad, { ITabblad } from '../../layout/Tabblad';
import FactuurTab from './FactuurTab';
import BankopdrachtTab from './BankopdrachtTab';
import BoekingTab from './BoekingTab';
import _ from 'lodash';
import { IOphalenOpdrachtenResultElement } from '../../../../../shared/src/api/v2/bank/opdracht/opdracht';
import { IOphalenFacturenResultElement } from '../../../../../shared/src/api/v2/inkoopfactuur';
import {
  IOphalenBoekingenResultElement,
  IOphalenBoekingRegelsResultElement,
} from '../../../../../shared/src/api/v2/boekhouding/boeking';
import TechnischeInformatieKnop from '../../TechnischeInformatieKnop';
import BoekingInfoDialoog from '../../boekhouding/boeking/BoekingInfoDialoog';

interface IProps extends IDialoogProps<{}> {
  inkFactID: number;
}

enum ETablad {
  Factuur,
  Bankopdracht,
  Boeking,
}

export interface IContext {
  factuur: IOphalenFacturenResultElement | null;
  relatie: IOphalenRelatiesResultElementV2 | null;
  bankopdrachten: IOphalenOpdrachtenResultElement[];
  boekingregels: IOphalenBoekingRegelsResultElement[] | null;
  boekingen: IOphalenBoekingenResultElement[] | null;
}

export const FactuurContext = React.createContext<IContext>({
  factuur: null,
  relatie: null,
  bankopdrachten: [],
  boekingregels: null,
  boekingen: null,
});

export interface IFormikValues {}

export const veldnamen: Record<keyof IFormikValues, string> = {};

export const FactuurinfoDialoog: React.FC<IProps> = (props) => {
  const { dialoogIndex, onAnnuleren, onSuccess, open, inkFactID } = props;

  const [tabblad, setTabblad] = useState<ETablad>(ETablad.Factuur);
  const [factuur, setFactuur] = useState<IOphalenFacturenResultElement | null>(null);
  const [bankopdrachten, setBankopdrachten] = useState<IOphalenOpdrachtenResultElement[]>([]);
  const [relatie, setRelatie] = useState<IOphalenRelatiesResultElementV2 | null>(null);
  const [boekingen, setBoekingen] = useState<IOphalenBoekingenResultElement[] | null>(null);
  const [boekingregels, setBoekingregels] = useState<IOphalenBoekingRegelsResultElement[] | null>(
    null,
  );

  useEffect(() => {
    (async () => {
      const factuur = (
        await api.v2.inkoopfactuur.ophalenFacturen({
          filterSchema: {
            filters: [{ naam: 'IDS', data: [inkFactID] }],
          },
        })
      ).facturen[0];

      setFactuur(factuur);
    })();
  }, [inkFactID]);

  useEffect(() => {
    (async () => {
      if (factuur === null) {
        return;
      }
      const relatie = (
        await api.v2.relatie.ophalenRelaties({
          filterSchema: {
            filters: [{ naam: 'IDS', data: [factuur.RelID] }],
          },
        })
      ).relaties[0];

      setRelatie(relatie);
    })();
  }, [factuur]);

  useEffect(() => {
    (async () => {
      const bankopdrachten = (
        await api.v2.bank.opdracht.ophalenOpdrachten({
          filterSchema: {
            filters: [{ naam: 'INKFACT_IDS', data: [inkFactID] }],
          },
        })
      ).opdrachten;

      setBankopdrachten(bankopdrachten);
    })();
  }, [inkFactID]);

  const ophalenBoekingregels = useCallback(async () => {
    const regelsResult = (
      await api.v2.boeking.ophalenBoekingregels({
        filterSchema: {
          filters: [
            {
              naam: 'INKFACT_IDS',
              data: [inkFactID],
            },
          ],
        },
        orderSchema: {
          orders: [
            { naam: 'BOEKDATUM', richting: 'DESC' },
            { naam: 'BOEKNUMMER', richting: 'DESC' },
            { naam: 'REGELNUMMER', richting: 'DESC' },
          ],
        },
      })
    ).regels;

    setBoekingregels(regelsResult);
  }, [inkFactID]);

  useEffect(() => {
    ophalenBoekingregels();
  }, [ophalenBoekingregels]);

  const ophalenBoekingen = useCallback(async () => {
    if (boekingregels === null) {
      return;
    }

    const boekingIDs = _.uniq(boekingregels.map((x) => x.BoekingID));

    const regelsResult = (
      await api.v2.boeking.ophalenBoekingen({
        filterSchema: {
          filters: [
            {
              naam: 'IDS',
              data: boekingIDs,
            },
          ],
        },
      })
    ).boekingen;

    setBoekingen(regelsResult);
  }, [boekingregels]);

  useEffect(() => {
    ophalenBoekingen();
  }, [ophalenBoekingen]);

  const tabbladen = useMemo<ITabblad<ETablad>[]>(
    () => [
      {
        id: ETablad.Factuur,
        label: 'Factuur',
        content: FactuurTab,
      },
      {
        id: ETablad.Bankopdracht,
        label: 'Bankopdrachten',
        content: BankopdrachtTab,
      },
      {
        id: ETablad.Boeking,
        label: 'Boekingen',
        content: BoekingTab,
      },
    ],
    [onSuccess],
  );

  const initialFormikValues = useMemo<IFormikValues | null>(() => {
    if (factuur === null) {
      return null;
    }

    return {};
  }, [factuur]);

  return (
    <>
      {' '}
      <Dialoog index={dialoogIndex || 0} modalProps={{ size: `lg` }}>
        <ModalHeader>
          <ModalTitle>Factuurinformatie</ModalTitle>
        </ModalHeader>
        {initialFormikValues === null ||
        boekingregels === null ||
        boekingen === null ||
        relatie === null ? (
          <div className="flex-fill d-flex align-items-center justify-content-center">
            <LoadingSpinner />
          </div>
        ) : (
          <FactuurContext.Provider
            value={{
              factuur,
              relatie,
              bankopdrachten,
              boekingregels,
              boekingen,
            }}
          >
            <div className="d-flex flex-column flex-fill" style={{ minHeight: `600px` }}>
              <>
                <Formik<IFormikValues>
                  isInitialValid
                  initialValues={initialFormikValues}
                  //   validate={handleValidate}
                  onSubmit={() => null}
                  render={(FormikProps) => {
                    const { submitForm, isSubmitting, values, errors } = FormikProps;
                    return (
                      <>
                        <div className="d-flex flex-column flex-fill">
                          <Tabblad
                            tabbladen={tabbladen}
                            geselecteerd={tabblad}
                            onSelectieChange={(id) => {
                              setTabblad(id);
                            }}
                          />
                        </div>
                        <ModalFooter className="d-flex flex-row justify-content-start">
                          {/* <button
                        className="btn btn-primary"
                        disabled={isSubmitting}
                        onClick={submitForm}
                        style={{ width: 100 }}
                      >
                        Ok
                      </button> */}
                          <button
                            className="btn btn-primary"
                            onClick={onAnnuleren}
                            style={{ width: 100 }}
                          >
                            Sluiten
                          </button>
                          <div className="flex-fill" />
                          <TechnischeInformatieKnop
                            content={() => <div>FactID: {props.inkFactID}</div>}
                          />
                        </ModalFooter>
                      </>
                    );
                  }}
                />
              </>
            </div>
          </FactuurContext.Provider>
        )}
      </Dialoog>
    </>
  );
};

export default FactuurinfoDialoog;
