import { Field, FieldProps, Formik } from 'formik';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import { IOphalenRekeningenResultElement } from '../../../../../../shared/src/api/v2/bank/bank';
import api from '../../../../api';
import Dialoog from '../../../../components/dialogen/Dialoog';
import BedragInput from '../../../../components/formulier/BedragInput';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import IDialoogProps from '../../../../core/IDialoogProps';
import nameOf from '../../../../core/nameOf';

interface IDialoogResult {}

interface IProps extends IDialoogProps<IDialoogResult> {
  bankRekID: number;
}

const veldnamen = {
  saldo: 'Saldo',
};

interface IFormikValues {
  saldo: number;
}

const SaldoCorrigerenDialoog: React.FC<IProps> = (props) => {
  const [rekening, setRekening] = useState<IOphalenRekeningenResultElement | null>(null);

  const ophalenRekening = useCallback(async () => {
    const result = await api.v2.bank.ophalenRekeningen({
      filterSchema: { filters: [{ naam: 'IDS', data: [props.bankRekID] }] },
    });

    setRekening(result.rekeningen[0]);
  }, [props.bankRekID]);

  useEffect(() => {
    ophalenRekening();
  }, [ophalenRekening]);

  const initieleWaarden = useMemo<IFormikValues | null>(() => {
    if (rekening === null) {
      return null;
    }

    return {
      saldo: rekening.Saldo,
    };
  }, [rekening]);

  const handleSubmit = useCallback(
    async (values: IFormikValues) => {
      // const checkData = await api.v2.aanbod.tarieven.acties.checkWijzigenActietarievenAbonnement({
      //   id: props.id,
      //   eenmaligBedrag: values.eenmaligBedrag,
      // });
      // const checkResultaat = await checkStore.controleren({ checkData });
      // if (checkResultaat.type === EResultType.Annuleren) {
      //   setIsSubmitting(false);
      //   return;
      // }

      await api.v2.bank.rekening.corrigerenSaldo({
        bankRekID: props.bankRekID,
        saldo: values.saldo,
      });

      props.onSuccess({});
    },
    [props.bankRekID, props.onSuccess],
  );

  return (
    <Dialoog index={props.dialoogIndex ?? 0}>
      <ModalHeader>
        <ModalTitle>Saldo corrigeren</ModalTitle>
      </ModalHeader>

      {rekening === null || initieleWaarden === null ? (
        <div>
          <LoadingSpinner />
        </div>
      ) : (
        <Formik<IFormikValues>
          isInitialValid
          // validationSchema={validationSchema}
          onSubmit={handleSubmit}
          initialValues={initieleWaarden}
          render={(formikProps) => {
            const { submitForm, isSubmitting, values, isValid } = formikProps;

            return (
              <>
                <ModalBody>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-12">
                        <label>{veldnamen.saldo}</label>
                        <Field
                          name={nameOf<IFormikValues>('saldo')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            return (
                              <>
                                <div>
                                  <BedragInput
                                    value={field.value}
                                    onChange={(x) => {
                                      form.setFieldValue(field.name, x);
                                    }}
                                  />
                                </div>
                              </>
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </ModalBody>
                <ModalFooter className="d-flex flex-row justify-content-start">
                  <button
                    className="btn btn-primary d-flex align-items-center justify-content-center"
                    onClick={submitForm}
                    style={{ width: 100 }}
                    disabled={false}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={props.onAnnuleren}
                    style={{ width: 100 }}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Dialoog>
  );
};

export default SaldoCorrigerenDialoog;
