import api from '../../index';
import contract from './contract';
import voorstel from './voorstel';
import item from './item';
import materiaal from './materiaal';
import dienst from './dienst';

const sponsoring = {
  dienst,
  materiaal,
  contract,
  voorstel,
  item,
};

export default sponsoring;
