import React, { useContext, useMemo, useRef, useState } from 'react';
import { IEmailBijlageBestand } from '../../../../../../../../../shared/src/api/v2/communicatie/email';
import styled from 'styled-components';
import { Kleur } from '../../../../../../../bedrijfslogica/constanten';
import { getIconFile, IconDownload, IconUitvergroten } from '../../../../../../Icons';
import Overlay from 'react-bootstrap/Overlay';
import downloadUrl from '../../../../../../../core/downloadUrl';
import { GlobaleRendererContext } from '../../../../../../../one-off-components/GlobaleRenderer';
import { observer } from 'mobx-react-lite';
import MediaWeergaveDialoog from '../../../../../../dialogen/MediaWeergaveDialoog';
import MediaWeergave, {
  EMediaWeergaveType,
  MediaWeergaveItem,
} from '../../../../../../MediaWeergave';
import { IEmailBerBest } from '../../../../../../../../../shared/src/api/v2/email-v2';

const Root = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${Kleur.LichtGrijs};
  border-radius: 3px;
  padding: 3px 6px;
  font-size: 12px;
  background-color: ${Kleur.Wit};
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: ${Kleur.LichtGrijs};
  }
`;

interface IProps {
  bijlage: IEmailBerBest;
}

const BijlageItem = observer((props: IProps) => {
  const globaleRenderer = useContext(GlobaleRendererContext);
  const target = useRef<HTMLDivElement | null>(null);
  const [show, setShow] = useState(false);

  const mediaWeergaven = useMemo<MediaWeergaveItem[]>(() => {
    if (props.bijlage.bestand.MediaType.startsWith('image/')) {
      return [
        {
          type: EMediaWeergaveType.Afbeelding,
          mediaType: props.bijlage.bestand.MediaType,
          src: props.bijlage.bestand.url,
          id: 1,
          title: props.bijlage.bestand.Naam,
        },
      ];
    }
    if (props.bijlage.bestand.MediaType.startsWith('video/')) {
      return [
        {
          type: EMediaWeergaveType.Video,
          mediaType: props.bijlage.bestand.MediaType,
          src: props.bijlage.bestand.url,
          id: 1,
          title: props.bijlage.bestand.Naam,
        },
      ];
    }
    if (props.bijlage.bestand.MediaType === 'application/pdf') {
      return [
        {
          type: EMediaWeergaveType.PDF,
          mediaType: props.bijlage.bestand.MediaType,
          src: props.bijlage.bestand.url,
          id: 1,
          title: props.bijlage.bestand.Naam,
        },
      ];
    }

    return [];
  }, [props.bijlage.bestand.ID]);

  const Svg = getIconFile(props.bijlage.bestand.MediaType);
  return (
    <>
      <Root
        ref={target}
        onClick={(ev) => {
          ev.stopPropagation();
          setShow(true);
        }}
      >
        <Svg style={{ width: 16, height: 16 }} />
        <span
          className="ml-2"
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {props.bijlage.bestand.Naam}
        </span>
      </Root>
      <Overlay
        target={target.current!}
        show={show}
        placement="bottom-start"
        rootClose
        onHide={() => setShow(false)}
      >
        {({ placement, arrowProps, popper, ...overlayProps }) => (
          <div
            {...overlayProps}
            style={{
              position: 'absolute',
              backgroundColor: Kleur.Wit,
              // border: `1px solid ${Kleur.LichtGrijs}`,
              ...overlayProps.style,
            }}
          >
            <div className="list-group">
              <button
                className="list-group-item list-group-item-action d-flex align-items-center"
                onClick={async (ev) => {
                  setShow(false);
                  await downloadUrl(props.bijlage.bestand.url, props.bijlage.bestand.Naam);
                }}
              >
                <IconDownload style={{ fill: Kleur.Grijs, width: 16, height: 16 }} />
                <span className="ml-3">Downloaden</span>
              </button>
              {mediaWeergaven.length > 0 && (
                <button
                  className="list-group-item list-group-item-action  d-flex align-items-center"
                  onClick={async (ev) => {
                    setShow(false);
                    await globaleRenderer.render((renderProps) => (
                      <MediaWeergaveDialoog
                        mediaWeergaven={mediaWeergaven}
                        current={1}
                        onCurrentChange={() => {}}
                        open
                        onSuccess={() => renderProps.destroy()}
                        onAnnuleren={() => renderProps.destroy()}
                      />
                    ));
                  }}
                >
                  <IconUitvergroten style={{ fill: Kleur.Grijs, width: 17, height: 17 }} />
                  <span className="ml-3">Weergeven</span>
                </button>
              )}
            </div>
          </div>
        )}
      </Overlay>
    </>
  );
});

export default BijlageItem;
