import React, { useCallback, useContext, useMemo } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import RelatieVisualisatie from '../RelatieVisualisatie';
import { Kleur } from '../../../bedrijfslogica/constanten';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../stores/RootStore';
import hoedanigheid from '../../../api/v2/relatie/hoedanigheid';
import { EHoedanigheid } from '../RelatieSelectieDialoog';

interface IProps extends RouteComponentProps {
  relIDs: number[];
  relatieLinkBuilder?: (hoedanigheid: EHoedanigheid, relID: number) => string;
}

const RelatiesVisualisatie: React.FC<IProps> = observer((props) => {
  const { toetsenbordStore, relatieStore } = useContext(RootStoreContext);
  const maxRelatiesWeergeven = 2;
  const heeftMeerDanMaxAantalRelaties = props.relIDs.length > maxRelatiesWeergeven;

  return (
    <span className="d-flex">
      {props.relIDs
        .map((relID) => {
          const relatie = relatieStore.relaties[relID] ?? null;

          return (
            <RelatieVisualisatie
              relID={relID}
              // options={{ geenLinkToepassen: true }}
              relatieLinkBuilder={props.relatieLinkBuilder}
            />
          );
        })
        .reduce((acc: Array<JSX.Element | null>, curr: JSX.Element, i) => {
          return [
            ...acc,
            curr,
            i === props.relIDs.length - 1 ? null : (
              <span key={i}>
                ,<span className="ml-2" />
              </span>
            ),
          ];
        }, [])}
      {heeftMeerDanMaxAantalRelaties ? <span>,&nbsp;...</span> : null}
    </span>
  );
});

export default withRouter(RelatiesVisualisatie);
