import React, { useContext, useMemo } from 'react';
import Tabblad, { ITabblad } from '../../../../../components/layout/Tabblad';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Serviceopdrachten from './Serviceopdrachten';
import { LeverancierContext } from '../../../index';
import Dienst from './Dienst';
import Gebieden from './Gebieden';
import Afwezigheid from './Afwezigheid';

export enum ETabblad {
  Serviceopdrachten,
  Dienst,
  Gebieden,
  Afwezigheid,
}

const pathMap: Record<ETabblad, string> = {
  [ETabblad.Serviceopdrachten]: '/serviceopdrachten',
  [ETabblad.Dienst]: '/dienst',
  [ETabblad.Gebieden]: '/gebieden',
  [ETabblad.Afwezigheid]: '/afwezigheid',
};

interface IProps extends RouteComponentProps {}

const Servicedienst: React.FC<IProps> = (props) => {
  const { relID, servicedienst } = useContext(LeverancierContext);

  const tabblad = useMemo<ETabblad | null>(() => {
    const matchKey = Object.keys(pathMap).find((key) => {
      const path = pathMap[Number(key) as ETabblad]!;
      return props.location.pathname.endsWith(path);
    });
    if (matchKey === undefined) {
      const path1 = `/leverancier/${relID}/servicedienst${pathMap[ETabblad.Serviceopdrachten]}`;
      props.history.push(path1);
      return null;
    }
    return Number(matchKey) as ETabblad;
  }, [props.location.pathname, relID]);

  const tabbladen = useMemo<ITabblad<ETabblad>[]>(
    () => [
      {
        id: ETabblad.Serviceopdrachten,
        label: 'Serviceopdrachten',
        content: Serviceopdrachten,
      },
      {
        id: ETabblad.Gebieden,
        label: 'Gebieden',
        content: Gebieden,
      },
      {
        id: ETabblad.Dienst,
        label: 'Dienst',
        content: Dienst,
      },
      {
        id: ETabblad.Afwezigheid,
        label: 'Afwezigheid',
        content: Afwezigheid,
      },
    ],
    [],
  );

  return (
    <div className="d-flex flex-column flex-fill flex-fill">
      {tabblad !== null && (
        <Tabblad<ETabblad>
          geselecteerd={tabblad}
          onSelectieChange={(tab) => {
            props.history.push(
              `${props.location.pathname.replace(pathMap[tabblad], '')}${pathMap[tab]}`,
            );
          }}
          tabbladen={tabbladen}
        />
      )}
    </div>
  );
};

export default withRouter(Servicedienst);
