import {
  IOphalenFeestdagenParams,
  IOphalenFeestdagenResult,
  IToevoegenFeestdagParams,
  IToevoegenFeestdagResult,
  IVerwijderenFeestdagenParams,
  IVerwijderenFeestdagenResult,
  IWijzigenFeestdagParams,
  IWijzigenFeestdagResult,
} from '../../../../../../shared/src/api/v2/feestdag';
import ICheckData from '../../../../../../shared/src/models/ICheckData';
import api from '../../../index';

const feestdag = {
  ophalenFeestdagen: async (
    params: IOphalenFeestdagenParams,
  ): Promise<IOphalenFeestdagenResult> => {
    return await api.post('/v2/stambeheer/feestdag/ophalen-feestdagen', params);
  },
  checkToevoegenFeestdag: async (params: IToevoegenFeestdagParams): Promise<ICheckData> => {
    return await api.post('/v2/stambeheer/feestdag/check-toevoegen-feestdag', params);
  },
  toevoegenFeestdag: async (
    params: IToevoegenFeestdagParams,
  ): Promise<IToevoegenFeestdagResult> => {
    return await api.post('/v2/stambeheer/feestdag/toevoegen-feestdag', params);
  },
  checkWijzigenFeestdag: async (params: IWijzigenFeestdagParams): Promise<ICheckData> => {
    return await api.post('/v2/stambeheer/feestdag/check-wijzigen-feestdag', params);
  },
  wijzigenFeestdag: async (params: IWijzigenFeestdagParams): Promise<IWijzigenFeestdagResult> => {
    return await api.post('/v2/stambeheer/feestdag/wijzigen-feestdag', params);
  },
  checkVerwijderenFeestdagen: async (params: IVerwijderenFeestdagenParams): Promise<ICheckData> => {
    return await api.post('/v2/stambeheer/feestdag/check-verwijderen-feestdagen', params);
  },
  verwijderenFeestdagen: async (
    params: IVerwijderenFeestdagenParams,
  ): Promise<IVerwijderenFeestdagenResult> => {
    return await api.post('/v2/stambeheer/feestdag/verwijderen-feestdagen', params);
  },
};

export default feestdag;
