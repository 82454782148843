import React, { useCallback, useContext, useEffect, useMemo, useState, useRef } from 'react';
import { Field, FieldProps, Formik, FormikActions, FormikProps, FormikErrors } from 'formik';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  IOphalenRelatiesResultElementV2,
  IWijzigenRelatieParams,
} from '../../../../../../../../shared/src/api/v2/relatie';
import VinkVeld from '../../../../../../components/formulier/VinkVeld';
import UitklapFormulier from '../../../../../../components/formulier/UitklapFormulier';
import Combobox from '../../../../../../components/formulier/Combobox';
import { RootStoreContext } from '../../../../../../stores/RootStore';
import HorizontaleScheidingslijn from '../../../../../../components/layout/HorizontaleScheidingslijn';
import AdresFormulier, { IValues } from '../../../../../../components/formulier/AdresFormulier';
import { observer } from 'mobx-react-lite';
import api from '../../../../../../api';
import { IOphalenBetaalWijzenResultElement } from '../../../../../../../../shared/src/api/v2/debiteur/betaalwijzen';
import { IOphalenBtwSoortenResultElement } from '../../../../../../../../shared/src/api/v2/btw';
import nameOf from '../../../../../../core/nameOf';
import useUrlState from '../../../../../../core/useUrlState';
import LoadingSpinner from '../../../../../../components/Gedeeld/LoadingSpinner';
import { Kleur } from '../../../../../../bedrijfslogica/constanten';
import { formatteerRelatieNaam } from '../../../../../../helpers';
import { EResultType } from '../../../../../../stores/CheckStore';
import NumeriekVeld from '../../../../../../components/formulier/NumeriekVeld';
import TelefoonnummerInput from '../../../../../../components/formulier/TelefoonnummerInput';
import { IToevoegenRelatieHoedanigheidParams } from '../../../../../../../../shared/src/api/v2/relatie/hoedanigheid';

interface IProps extends RouteComponentProps {
  relID: number;
}

interface IFormikValues {
  geblokkeerd: boolean;

  // Contactpersoon
  primaireContactpersoon: number | null;

  // Organisatie
  organisatienaam: string;
  organisatienaamKort: string;
  organisatieEmail: string | null;
  organisatieTelefoonAlgemeen: string | null;
  websiteURL: string | null;
  logoBestandID: number | null;
  rechtsvormID: number | null;
  btwnummer: string;
  kvknummer: string;
  bankmutatieReferentie: string | null;

  nietGebruiken: boolean;
  domein: string | null;
  // Adres
  adres: IValues | null;

  // Crediteur
  betWijzeID: number;
  betalingstermijn: number;
  betalingstermijnKorting: number;
  kortingsPercentage: number;
  betaalspecificatieVersturen: boolean;
  btwSrtInkoopBtwSrtID: number | null;
  btwSrtBankBtwSrtID: number | null;
  onsKlantnummer: string | null;
  onsKlantnummerVermelden: boolean;
  crediteurEmail: string | null;
  crediteurEmailBetaalspecificatie: string | null;

  vasteInfo: string | null;
  externeRelatiemapLink: string | null;
}

interface IUrlState {
  algemeenTonen: boolean;
  adresTonen: boolean;
  crediteurTonen: boolean;
}

const defaultUrlState: IUrlState = {
  algemeenTonen: true,
  adresTonen: false,
  crediteurTonen: false,
};

const veldnamen: Partial<Record<keyof IFormikValues, string>> = {
  primaireContactpersoon: 'Primaire contactpersoon',
  geblokkeerd: 'Geblokkeerd',
  adres: 'Adres',
  btwnummer: 'BTW nummer',
  kvknummer: 'KvK-nummer',
  bankmutatieReferentie: 'Bankmutatie referentie',
  rechtsvormID: 'Rechtsvorm',
  organisatienaamKort: 'Organisatienaam kort',
  organisatienaam: 'Organisatienaam',
  organisatieEmail: 'Email algemeen',
  websiteURL: 'Website',
  organisatieTelefoonAlgemeen: 'Telefoon algemeen',
  nietGebruiken: 'Relatie niet gebruiken',
  domein: 'Domein',
  vasteInfo: 'Vaste info',
  betWijzeID: 'Betaalwijze',
  betalingstermijn: 'Betalingstermijn',
  betalingstermijnKorting: 'Betalingstermijn voor korting',
  kortingsPercentage: 'Kortingspercentage',
  betaalspecificatieVersturen: 'Betaalspecificatie versturen',
  btwSrtInkoopBtwSrtID: 'Standaard Btw-soort Inkoop dagboek',
  btwSrtBankBtwSrtID: 'Standaard Btw-soort Bank dagboek',
  onsKlantnummer: 'Ons klantnummer (zoals bij de crediteur bekend)',
  onsKlantnummerVermelden: 'Ons klantnummer vermelden bij betaalopdrachten',
  externeRelatiemapLink: 'Link naar Externe relatiemap',
  crediteurEmail: 'Email',
  crediteurEmailBetaalspecificatie: 'Email voor betaalspecificaties',
};

const Gegevens: React.FC<IProps> = observer((props) => {
  const { klantkaartStore, checkStore } = useContext(RootStoreContext);
  const formikRef = useRef<Formik<IFormikValues>>(null);
  const [urlState, setUrlState, setUrlStateSync] = useUrlState<IUrlState>(props, defaultUrlState);

  const [huidigeRelatie, setRelatie] = useState<IOphalenRelatiesResultElementV2 | null>(null);
  const [btwsoorten, setBtwsoorten] = useState<IOphalenBtwSoortenResultElement[] | null>(null);
  const [betaalwijzen, setBetaalwijzen] = useState<IOphalenBetaalWijzenResultElement[] | null>(
    null,
  );
  const [betaaldagen, setBetaaldagen] = useState<number[] | null>(null);
  const [prolongatieTermijnen, setProlongatieTermijnen] = useState<number[]>([]);
  const [hoedanigheidPopupActief, setHoedanigheidPopupActief] = useState<boolean>(false);

  const ophalenBtwsoorten = useCallback(async () => {
    const result = await api.v2.btw.ophalenBtwSoorten({
      filterSchema: {
        filters: [],
      },
    });
    setBtwsoorten(result.btwSoorten);
  }, []);

  useEffect(() => {
    ophalenBtwsoorten();
  }, [ophalenBtwsoorten]);

  useEffect(() => {
    klantkaartStore.ophalenContactpersonenVoorlijst({
      relID: props.relID,
      sortering: 1,
    });
  }, [props.relID]);

  const ophalenRelatie = useCallback(async () => {
    setRelatie(null);
    const result = await api.v2.relatie.ophalenRelaties({
      filterSchema: {
        filters: [{ naam: 'IDS', data: [props.relID] }],
      },
    });

    setRelatie(result.relaties[0]);
  }, [props.relID]);

  useEffect(() => {
    ophalenRelatie();
  }, [ophalenRelatie]);

  useEffect(() => {
    if (huidigeRelatie != null && huidigeRelatie.crediteur == null) {
      aanmakenHoedanigheidLeverancier();
    }
  }, [huidigeRelatie]);

  const aanmakenHoedanigheidLeverancier = useCallback(async () => {
    if (huidigeRelatie == null || hoedanigheidPopupActief) {
      return;
    }
    if (huidigeRelatie?.Relatiesoort !== 'O') {
      checkStore.melden({
        titel: 'Relatie kan alleen leverancier worden als het een organisatie betreft.',
      });
      return;
    }

    const params: IToevoegenRelatieHoedanigheidParams = {
      relID: props.relID,
      NaamEnum: 'LEVERANCIER',
    };

    const checkResult = await api.v2.relatie.hoedanigheid.checkToevoegenRelatieHoedanigheid(params);
    if ((await checkStore.controleren({ checkData: checkResult })).type === EResultType.Annuleren) {
      console.log(
        'Check gefaald. Het kan zijn dat er toch wel al een leverancier hoedanigheid is?',
      );
      return;
    }

    setHoedanigheidPopupActief(true);
    if (
      (
        await checkStore.bevestigen({
          inhoud: `Klant heeft geen hoedanigheid als Leverancier, wil je die eerst toevoegen?`,
        })
      ).type === EResultType.Annuleren
    ) {
      setHoedanigheidPopupActief(false);
      return;
    }

    const result = await api.v2.relatie.hoedanigheid.toevoegenRelatieHoedanigheid(params);
    ophalenRelatie();
    setHoedanigheidPopupActief(false);
  }, [huidigeRelatie]);

  const ophalenBetaalwijzen = useCallback(async () => {
    const result = await api.v2.betaalwijze.ophalenBetaalwijzen({
      filterSchema: { filters: [] },
    });
    setBetaalwijzen(result.betaalwijzen);
  }, []);

  useEffect(() => {
    ophalenBetaalwijzen();
  }, [ophalenBetaalwijzen]);

  const ophalenBetaaldagen = useCallback(async () => {
    const result = await api.v2.debiteur.ophalenBetaaldagen({});
    setBetaaldagen(result);
  }, []);

  useEffect(() => {
    ophalenBetaaldagen();
  }, []);

  const ophalenProlongatieTermijnen = useCallback(async () => {
    const result = await api.v2.debiteur.ophalenProlongatieTermijnen();
    setProlongatieTermijnen(result);
  }, []);

  useEffect(() => {
    ophalenProlongatieTermijnen();
  }, []);

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (huidigeRelatie === null || huidigeRelatie.crediteur === null) {
      return null;
    }

    let adres: IValues | null = null;
    // Is er een adres
    if (huidigeRelatie.adres !== null) {
      adres = {
        straatnaam: huidigeRelatie.adres.Straatnaam,
        bisnummer: huidigeRelatie.adres.Bisnummer || '',
        huisnummer: huidigeRelatie.adres.Huisnummer || 1,
        landID: huidigeRelatie.adres.LandID,
        plaatsnaam: huidigeRelatie.adres.Plaatsnaam || '',
        postcode: huidigeRelatie.adres.Postcode || '',
      };
    }

    return {
      primaireContactpersoon: huidigeRelatie.PersoonPrimair_PersID,
      geblokkeerd: huidigeRelatie.Geblokkeerd,
      adres,
      organisatienaam: huidigeRelatie.organisatie !== null ? huidigeRelatie.organisatie.Naam : '',
      organisatienaamKort:
        huidigeRelatie.organisatie !== null ? huidigeRelatie.organisatie.NaamKort : '',
      organisatieEmail: huidigeRelatie.organisatie !== null ? huidigeRelatie.organisatie.Email : '',
      organisatieTelefoonAlgemeen:
        huidigeRelatie.organisatie !== null ? huidigeRelatie.organisatie.Telefoonnummer : '',
      websiteURL: huidigeRelatie.organisatie !== null ? huidigeRelatie.organisatie.WebsiteURL : '',
      logoBestandID:
        huidigeRelatie.organisatie !== null ? huidigeRelatie.organisatie.Logo_BestandID : null,
      rechtsvormID:
        huidigeRelatie.organisatie !== null ? huidigeRelatie.organisatie.rechtsvorm.ID : null,
      kvknummer:
        huidigeRelatie.organisatie !== null ? huidigeRelatie.organisatie.KvKnummer || '' : '',
      bankmutatieReferentie:
        huidigeRelatie.BankmutatieReferentie !== null ? huidigeRelatie.BankmutatieReferentie : '',
      btwnummer: huidigeRelatie.organisatie!.Btwnummer || '',
      nietGebruiken: huidigeRelatie.NietGebruiken,
      domein: huidigeRelatie.organisatie !== null ? huidigeRelatie.organisatie.Domein : null,

      betWijzeID: huidigeRelatie.crediteur.betaalwijze.BetWijzeID,
      betalingstermijn: huidigeRelatie.crediteur.Betalingstermijn,
      betalingstermijnKorting: huidigeRelatie.crediteur.BetalingstermijnKorting || 0,
      kortingsPercentage: huidigeRelatie.crediteur.KortingsPercentage,
      betaalspecificatieVersturen: huidigeRelatie.crediteur!.BetaalspecificatieVersturen,
      btwSrtInkoopBtwSrtID: huidigeRelatie.crediteur.BtwSrtInkoop_BtwSrtID,
      btwSrtBankBtwSrtID: huidigeRelatie.crediteur.BtwSrtBank_BtwSrtID,
      onsKlantnummer: huidigeRelatie.crediteur.OnsKlantnummer,
      onsKlantnummerVermelden: huidigeRelatie.crediteur.OnsKlantnummerVermelden,
      vasteInfo: huidigeRelatie.VasteInfo,
      externeRelatiemapLink: huidigeRelatie.ExterneRelatiemapLink,
      crediteurEmail: huidigeRelatie.crediteur!.Email ?? '',
      crediteurEmailBetaalspecificatie: huidigeRelatie.crediteur.EmailBetaalspecificatie ?? '',
    };
  }, [huidigeRelatie]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      const adres = values.adres!;

      const params: IWijzigenRelatieParams = {
        relID: props.relID,
        relatiesoort: klantkaartStore.relatie!.Relatiesoort,
        geblokkeerd: values.geblokkeerd,
        straatnaam: adres === null ? null : adres.straatnaam,
        huisnummer: adres === null ? null : adres.huisnummer,
        bisnummer: adres === null ? null : adres.bisnummer,
        postcode: adres === null ? null : adres.postcode,
        plaatsnaam: adres === null ? null : adres.plaatsnaam,
        landID: adres === null ? null : adres.landID,
        btwnummer: values.btwnummer !== '' ? values.btwnummer : null,
        kvknummer: values.kvknummer !== '' ? values.kvknummer : null,
        organisatienaam: values.organisatienaam,
        organisatienaamKort: values.organisatienaamKort,
        organisatieEmail: values.organisatieEmail !== '' ? values.organisatieEmail : null,
        organisatieTelefoonAlgemeen:
          values.organisatieTelefoonAlgemeen !== '' ? values.organisatieTelefoonAlgemeen : null,
        websiteURL: values.websiteURL !== '' ? values.websiteURL : null,
        logoBestandID: values.logoBestandID,
        nietGebruiken: values.nietGebruiken,
        geenReviewUitnodiging: false,
        geenEigenGebruik: false,
        overnamecode: klantkaartStore.relatie!.Overnamecode,
        crediteur: {
          betWijzeID: values.betWijzeID,
          betalingstermijn: values.betalingstermijn,
          betalingstermijnKorting:
            values.betalingstermijnKorting !== 0 ? values.betalingstermijnKorting : null,
          kortingsPercentage: values.kortingsPercentage,
          betaalspecificatieVersturen: values.betaalspecificatieVersturen,
          btwSrtInkoopBtwSrtID: values.btwSrtInkoopBtwSrtID,
          btwSrtBankBtwSrtID: values.btwSrtBankBtwSrtID,
          onsKlantnummer: values.onsKlantnummer,
          onsKlantnummerVermelden: values.onsKlantnummerVermelden,
          email: values.crediteurEmail,
          emailBetaalspecificatie: values.crediteurEmailBetaalspecificatie,
        },
        debiteur: null,
        persoonPrimair_PersID: values.primaireContactpersoon,
        persoonFinancieel_PersID: values.primaireContactpersoon,
        vasteInfo: values.vasteInfo?.trim() ?? null,
        bankmutatieReferentie:
          values.bankmutatieReferentie !== '' ? values.bankmutatieReferentie : null,
        domein: null,
        documentatieVersturenNaLevering: klantkaartStore.relatie!.DocumentatieVersturenNaLevering,
        externeRelatiemapLink: values.externeRelatiemapLink,
      };

      const checkData = await api.v2.relatie.checkWijzigenRelatie(params);
      if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
        actions.setSubmitting(false);
        return;
      }

      const result = await api.v2.relatie.wijzigenRelatie(params);

      await klantkaartStore.verversRelatie();
      await ophalenRelatie();

      klantkaartStore.ophalenContactpersonenVoorlijst({
        relID: props.relID,
        sortering: 1,
      });

      actions.setSubmitting(false);
    },
    [],
  );

  const validateForm = useCallback((values: IFormikValues) => {
    const errors: FormikErrors<IFormikValues> = {};
    // if (values.adres === null) {
    //   errors.adres = teksten.formulier.E_VERPLICHT_VELD({ veldnaam: veldnamen.adres! });
    // }
    return errors;
  }, []);

  return (
    <>
      <div className="flex-fill d-flex flex-column justify-content-center align-items-center">
        {initialValues === null ? (
          <LoadingSpinner />
        ) : (
          <div className="flex-fill d-flex flex-column" style={{ width: '100%' }}>
            <Formik<IFormikValues>
              ref={formikRef}
              onSubmit={handleSubmit}
              initialValues={initialValues}
              validate={validateForm}
              render={(formikProps: FormikProps<IFormikValues>) => {
                const {
                  submitForm,
                  isSubmitting,
                  isValid,
                  dirty,
                  resetForm,
                  values,
                  errors,
                } = formikProps;
                return (
                  <div className="d-flex flex-column flex-fill">
                    <div className="row p-4">
                      <div className="col-12">
                        <UitklapFormulier
                          uitgeklapt={urlState.algemeenTonen}
                          onUitgeklaptChange={(x) => setUrlStateSync('algemeenTonen', x)}
                          label="Algemeen"
                          formulier={
                            <div className="row">
                              <div className="col-3">
                                <Field
                                  name={nameOf<IFormikValues>('primaireContactpersoon')}
                                  render={(fieldProps: FieldProps<IFormikValues>) => {
                                    const { field, form } = fieldProps;
                                    return (
                                      <>
                                        <div>{veldnamen.primaireContactpersoon}</div>

                                        {klantkaartStore.contactpersonen.length > 0 && (
                                          <Combobox
                                            geselecteerd={field.value}
                                            onSelectieChange={(x) => {
                                              form.setFieldValue(field.name, x);
                                            }}
                                            opties={klantkaartStore.contactpersonen.map(
                                              (contactPersoon) => {
                                                return {
                                                  id: contactPersoon.PersID,
                                                  label: formatteerRelatieNaam({
                                                    aanhefKort: contactPersoon.geslacht.AanhefKort,
                                                    achternaam: contactPersoon.Achternaam,
                                                    voorletters: contactPersoon.Voorletters,
                                                    voornaam: contactPersoon.Voornaam,
                                                    voorvoegsel: contactPersoon.Voorvoegsel,
                                                  }),
                                                };
                                              },
                                            )}
                                          />
                                        )}
                                      </>
                                    );
                                  }}
                                />
                              </div>

                              <div className="col-12 mt-3">
                                <div className="d-flex align-items-center">
                                  <Field
                                    name={nameOf<IFormikValues>('nietGebruiken')}
                                    render={(fieldProps: FieldProps<IFormikValues>) => {
                                      const { field, form } = fieldProps;
                                      return (
                                        <VinkVeld
                                          aangevinkt={field.value}
                                          onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                        />
                                      );
                                    }}
                                  />
                                  <span className="ml-2">{veldnamen.nietGebruiken}</span>
                                </div>
                              </div>

                              <div className="col-12 mt-2">
                                <div className="d-flex align-items-center">
                                  <Field
                                    name={nameOf<IFormikValues>('geblokkeerd')}
                                    render={(fieldProps: FieldProps<IFormikValues>) => {
                                      const { field, form } = fieldProps;
                                      return (
                                        <VinkVeld
                                          aangevinkt={field.value}
                                          onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                        />
                                      );
                                    }}
                                  />
                                  <span className="ml-2">{veldnamen.geblokkeerd}</span>
                                </div>
                              </div>

                              <div className="col-9 mt-3">
                                <div className="row">
                                  <div className="col-12">
                                    <label>{veldnamen.vasteInfo}</label>
                                    <Field
                                      name={nameOf<IFormikValues>('vasteInfo')}
                                      render={(fieldProps: FieldProps<IFormikValues>) => {
                                        const { field, form } = fieldProps;

                                        return (
                                          <textarea
                                            rows={3}
                                            {...field}
                                            className="form-control w-100"
                                          />
                                        );
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="row col-12 mt-3">
                                <div className="col-12">
                                  <label>{veldnamen.externeRelatiemapLink}</label>
                                  <Field
                                    name={nameOf<IFormikValues>('externeRelatiemapLink')}
                                    render={(fieldProps: FieldProps<IFormikValues>) => {
                                      const { field, form } = fieldProps;

                                      return (
                                        <input
                                          {...field}
                                          className="form-control"
                                          type="text"
                                          placeholder={'B.v. een link naar een Sharepoint-locatie'}
                                        />
                                      );
                                    }}
                                  />
                                </div>
                              </div>

                              {huidigeRelatie !== null && huidigeRelatie.Relatiesoort === 'O' && (
                                <div className="col-9 mt-3">
                                  <div className="row">
                                    <div className="col-6">
                                      <label>{veldnamen.organisatienaam}</label>
                                      <Field
                                        name={nameOf<IFormikValues>('organisatienaam')}
                                        render={(fieldProps: FieldProps<IFormikValues>) => {
                                          const { field, form } = fieldProps;

                                          return (
                                            <input
                                              {...field}
                                              className="form-control"
                                              type="text"
                                            />
                                          );
                                        }}
                                      />
                                    </div>

                                    <div className="col-3">
                                      <label>{veldnamen.organisatienaamKort}</label>
                                      <Field
                                        name={nameOf<IFormikValues>('organisatienaamKort')}
                                        render={(fieldProps: FieldProps<IFormikValues>) => {
                                          const { field, form } = fieldProps;

                                          return (
                                            <input
                                              {...field}
                                              className="form-control"
                                              type="text"
                                            />
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>

                                  <div className="row mt-3">
                                    <div className="col-6">
                                      <label>{veldnamen.organisatieEmail}</label>
                                      <Field
                                        name={nameOf<IFormikValues>('organisatieEmail')}
                                        render={(fieldProps: FieldProps<IFormikValues>) => {
                                          const { field, form } = fieldProps;

                                          return (
                                            <input
                                              {...field}
                                              className="form-control"
                                              type="text"
                                            />
                                          );
                                        }}
                                      />
                                    </div>

                                    <div className="col-6">
                                      <label>{veldnamen.domein}</label>
                                      <Field
                                        name={nameOf<IFormikValues>('domein')}
                                        render={(fieldProps: FieldProps<IFormikValues>) => {
                                          const { field, form } = fieldProps;

                                          return (
                                            <input
                                              {...field}
                                              className="form-control"
                                              type="text"
                                            />
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>

                                  <div className="row mt-3">
                                    <div className="col-3">
                                      <label>{veldnamen.organisatieTelefoonAlgemeen}</label>
                                      <Field
                                        name={nameOf<IFormikValues>('organisatieTelefoonAlgemeen')}
                                        render={(fieldProps: FieldProps<IFormikValues>) => {
                                          const { field, form } = fieldProps;

                                          return (
                                            <TelefoonnummerInput
                                              telefoonnummer={field.value}
                                              onChange={(x) => form.setFieldValue(field.name, x)}
                                              // suggestiesProvider={
                                              //   telefoonnummerInputSuggestiesProvider
                                              // }
                                            />
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>

                                  <div className="row mt-3">
                                    <div className="col-9">
                                      <label>{veldnamen.websiteURL}</label>
                                      <Field
                                        name={nameOf<IFormikValues>('websiteURL')}
                                        render={(fieldProps: FieldProps<IFormikValues>) => {
                                          const { field, form } = fieldProps;

                                          return (
                                            <input
                                              {...field}
                                              className="form-control"
                                              type="text"
                                            />
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>

                                  <div className="row mt-3">
                                    <div className="col-3">
                                      <label>{veldnamen.btwnummer}</label>
                                      <Field
                                        name={nameOf<IFormikValues>('btwnummer')}
                                        render={(fieldProps: FieldProps<IFormikValues>) => {
                                          const { field, form } = fieldProps;

                                          return (
                                            <input
                                              {...field}
                                              className="form-control"
                                              type="text"
                                            />
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>

                                  <div className="row mt-3">
                                    <div className="col-3">
                                      <label>{veldnamen.kvknummer}</label>
                                      <Field
                                        name={nameOf<IFormikValues>('kvknummer')}
                                        render={(fieldProps: FieldProps<IFormikValues>) => {
                                          const { field, form } = fieldProps;

                                          return (
                                            <input
                                              {...field}
                                              className="form-control"
                                              type="text"
                                            />
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>

                                  <div className="row mt-3">
                                    <div className="col-6 ">
                                      <label>{veldnamen.bankmutatieReferentie}</label>
                                      <Field
                                        name={nameOf<IFormikValues>('bankmutatieReferentie')}
                                        render={(fieldProps: FieldProps<IFormikValues>) => {
                                          const { field, form } = fieldProps;

                                          return (
                                            <input
                                              {...field}
                                              className="form-control"
                                              type="text"
                                            />
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          }
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <UitklapFormulier
                          uitgeklapt={urlState.adresTonen}
                          onUitgeklaptChange={(x) => setUrlStateSync('adresTonen', x)}
                          label={veldnamen.adres!}
                          formulier={
                            <>
                              <Field
                                name={nameOf<IFormikValues>('adres')}
                                render={(fieldProps: FieldProps<IFormikValues>) => {
                                  const { field, form } = fieldProps;
                                  return (
                                    <AdresFormulier
                                      values={field.value}
                                      onChange={(x) => form.setFieldValue(field.name, x)}
                                    />
                                  );
                                }}
                              />
                            </>
                          }
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <UitklapFormulier
                          uitgeklapt={urlState.crediteurTonen}
                          onUitgeklaptChange={(x) => setUrlStateSync('crediteurTonen', x)}
                          label="Crediteur"
                          formulier={
                            <div className="row">
                              <div className="col-12">
                                <div className="d-flex align-items-center">
                                  <Field
                                    name={nameOf<IFormikValues>('betaalspecificatieVersturen')}
                                    render={(fieldProps: FieldProps<IFormikValues>) => {
                                      const { field, form } = fieldProps;
                                      return (
                                        <VinkVeld
                                          aangevinkt={field.value}
                                          onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                        />
                                      );
                                    }}
                                  />
                                  <span className="ml-2">
                                    {veldnamen.betaalspecificatieVersturen}
                                  </span>
                                </div>
                              </div>

                              <div className="row d-flex col-12 mt-3">
                                <div className="col-4">
                                  <label>{veldnamen.crediteurEmail}</label>
                                  <Field
                                    name={nameOf<IFormikValues>('crediteurEmail')}
                                    render={(fieldProps: FieldProps<IFormikValues>) => {
                                      const { field, form } = fieldProps;

                                      return (
                                        <input {...field} className="form-control" type="text" />
                                      );
                                    }}
                                  />
                                </div>

                                <div className="col-4">
                                  <label>{veldnamen.crediteurEmailBetaalspecificatie}</label>
                                  <Field
                                    name={nameOf<IFormikValues>('crediteurEmailBetaalspecificatie')}
                                    render={(fieldProps: FieldProps<IFormikValues>) => {
                                      const { field, form } = fieldProps;

                                      return (
                                        <input {...field} className="form-control" type="text" />
                                      );
                                    }}
                                  />
                                </div>
                              </div>

                              <div className="row d-flex col-12 mt-3">
                                <div className="col-4">
                                  <label>{veldnamen.betalingstermijn}</label>
                                  <Field
                                    name={nameOf<IFormikValues>('betalingstermijn')}
                                    render={(fieldProps: FieldProps<IFormikValues>) => {
                                      const { field, form } = fieldProps;
                                      return (
                                        <NumeriekVeld
                                          waarde={field.value}
                                          onChange={(x) => form.setFieldValue(field.name, x)}
                                          min={0}
                                          max={90}
                                        />
                                      );
                                    }}
                                  />
                                </div>
                                <div className="col-4">
                                  <label>{veldnamen.betWijzeID}</label>

                                  <Field
                                    name={nameOf<IFormikValues>('betWijzeID')}
                                    render={(fieldProps: FieldProps<IFormikValues>) => {
                                      const { field, form } = fieldProps;

                                      if (betaalwijzen === null) {
                                        return <LoadingSpinner />;
                                      }

                                      return (
                                        <Combobox
                                          legeOptieTonen
                                          geselecteerd={field.value}
                                          onSelectieChange={(x) =>
                                            form.setFieldValue(field.name, x)
                                          }
                                          opties={betaalwijzen.map((x) => {
                                            return {
                                              id: x.BetWijzeID,
                                              label: x.Naam,
                                            };
                                          })}
                                        />
                                      );
                                    }}
                                  />
                                </div>
                              </div>

                              <div className="row d-flex col-12 mt-3">
                                <div className="col-4">
                                  <label>{veldnamen.betalingstermijnKorting}</label>
                                  <Field
                                    name={nameOf<IFormikValues>('betalingstermijnKorting')}
                                    render={(fieldProps: FieldProps<IFormikValues>) => {
                                      const { field, form } = fieldProps;
                                      return (
                                        <NumeriekVeld
                                          waarde={field.value}
                                          onChange={(x) => form.setFieldValue(field.name, x)}
                                          min={0}
                                          max={90}
                                        />
                                      );
                                    }}
                                  />
                                </div>
                                <div className="col-4">
                                  <label>{veldnamen.kortingsPercentage}</label>
                                  <Field
                                    name={nameOf<IFormikValues>('kortingsPercentage')}
                                    render={(fieldProps: FieldProps<IFormikValues>) => {
                                      const { field, form } = fieldProps;
                                      return (
                                        <NumeriekVeld
                                          waarde={field.value}
                                          onChange={(x) => form.setFieldValue(field.name, x)}
                                          min={0}
                                          max={99}
                                        />
                                      );
                                    }}
                                  />
                                </div>
                              </div>

                              <div className="row d-flex col-12 mt-3">
                                <div className="col-4">
                                  <label>{veldnamen.btwSrtInkoopBtwSrtID}</label>

                                  <Field
                                    name={nameOf<IFormikValues>('btwSrtInkoopBtwSrtID')}
                                    render={(fieldProps: FieldProps<IFormikValues>) => {
                                      const { field, form } = fieldProps;

                                      if (btwsoorten === null) {
                                        return <LoadingSpinner />;
                                      }

                                      return (
                                        <Combobox
                                          legeOptieTonen
                                          geselecteerd={field.value}
                                          onSelectieChange={(x) =>
                                            form.setFieldValue(field.name, x)
                                          }
                                          opties={btwsoorten.map(
                                            (x: IOphalenBtwSoortenResultElement) => {
                                              return {
                                                id: x.ID,
                                                label: x.Naam,
                                              };
                                            },
                                          )}
                                        />
                                      );
                                    }}
                                  />
                                </div>

                                <div className="col-4">
                                  <label>{veldnamen.btwSrtBankBtwSrtID}</label>

                                  <Field
                                    name={nameOf<IFormikValues>('btwSrtBankBtwSrtID')}
                                    render={(fieldProps: FieldProps<IFormikValues>) => {
                                      const { field, form } = fieldProps;

                                      if (btwsoorten === null) {
                                        return <LoadingSpinner />;
                                      }

                                      return (
                                        <Combobox
                                          legeOptieTonen
                                          geselecteerd={field.value}
                                          onSelectieChange={(x) =>
                                            form.setFieldValue(field.name, x)
                                          }
                                          opties={btwsoorten.map(
                                            (x: IOphalenBtwSoortenResultElement) => {
                                              return {
                                                id: x.ID,
                                                label: x.Naam,
                                              };
                                            },
                                          )}
                                        />
                                      );
                                    }}
                                  />
                                </div>
                              </div>

                              <div className="col-12 mt-3">
                                <div className="d-flex align-items-center">
                                  <Field
                                    name={nameOf<IFormikValues>('onsKlantnummerVermelden')}
                                    render={(fieldProps: FieldProps<IFormikValues>) => {
                                      const { field, form } = fieldProps;
                                      return (
                                        <VinkVeld
                                          aangevinkt={field.value}
                                          onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                        />
                                      );
                                    }}
                                  />
                                  <span className="ml-2">{veldnamen.onsKlantnummerVermelden}</span>
                                </div>
                              </div>

                              <div className="col-4 mt-3">
                                <label>{veldnamen.onsKlantnummer}</label>
                                <Field
                                  name={nameOf<IFormikValues>('onsKlantnummer')}
                                  render={(fieldProps: FieldProps<IFormikValues>) => {
                                    const { field, form } = fieldProps;

                                    return (
                                      <input {...field} className="form-control" type="text" />
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          }
                        />
                      </div>
                    </div>
                    <div className="flex-fill" />

                    <code className="m-4"></code>
                    <HorizontaleScheidingslijn color={Kleur.LichtGrijs} />

                    <div className="d-flex flex-row justify-content-start p-3">
                      <button
                        className="btn btn-primary"
                        style={{ width: 100 }}
                        disabled={isSubmitting || !isValid}
                        onClick={submitForm}
                      >
                        Ok
                      </button>
                      <button
                        className="btn btn-secondary ml-2"
                        style={{ width: 100 }}
                        onClick={async () => {
                          if (
                            (
                              await checkStore.bevestigen({
                                inhoud:
                                  'Bij het annuleren van deze actie zullen alle waarden worden hersteld',
                              })
                            ).type === EResultType.Annuleren
                          ) {
                            return;
                          }

                          resetForm();
                        }}
                        disabled={!dirty}
                      >
                        Annuleren
                      </button>
                    </div>
                  </div>
                );
              }}
            />
          </div>
        )}
      </div>
    </>
  );
});

export default withRouter(Gegevens);
