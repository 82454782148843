import React, { useContext, useMemo, useState } from 'react';
import { IProps } from '@tinymce/tinymce-react/lib/cjs/main/ts/components/Editor';
import { FactuurContext, IContext } from '../index';
import VeldWeergave from '../../../formulier/VeldWeergave';
import LoadingSpinner from '../../../Gedeeld/LoadingSpinner';
import { format } from 'date-fns';
import GegevensLayout from '../../../layout/GegevensLayout';
import FormatteerBedrag, { StandaardMutatieBedragOpmaker } from '../../../MutatieBedrag';
import {
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import { DataTypeProvider, VirtualTable as VirtualTableBase } from '@devexpress/dx-react-grid';
import { IOphalenAanmaningenResultElement } from '../../../../../../shared/src/api/v2/aanmaning';
import nameOf from '../../../../core/nameOf';
import { Kleur, Kleur as EKleur } from '../../../../bedrijfslogica/constanten';
import { IRegel as IFactuurregel } from '../../../../../../shared/src/api/v2/factuur';
import ContractVisualisatie from '../../../entiteitVisualisaties/ContractVisualisatie';
import api from '../../../../api';
import _ from 'lodash';
import { IconDownload, IconUitvergroten } from '../../../Icons';
import MediaWeergaveDialoog from '../../../dialogen/MediaWeergaveDialoog';
import { EMediaWeergaveType } from '../../../MediaWeergave';
import debiteur from '../../../../api/v2/debiteur';

interface IFactuurWeergevenState {
  url: string;
}

const FactuurTab: React.FC<IProps> = (props) => {
  const { factuur, aanmaningen, relatie } = useContext<IContext>(FactuurContext);

  const [isBezigMetFactuurOphalen, setIsBezigMetFactuurOphalen] = useState(false);
  const [factuurWeergevenState, setFactuurWeergevenState] = useState<IFactuurWeergevenState | null>(
    null,
  );

  const standaardrekening =
    relatie!.financieel.rekeningen.length !== 0
      ? relatie!.financieel.rekeningen.find(
          (r) => r.RelRekID === relatie!.financieel.StandaardRekening_RelRekID,
        ) ?? null
      : null;

  const rekening = useMemo(() => {
    if (factuur === null) {
      return null;
    }

    const rekening =
      factuur.rekening !== null
        ? factuur.rekening
        : standaardrekening !== null
        ? standaardrekening
        : null;
    return rekening;
  }, [factuur]);

  const kolommenFactuurregels = useMemo<TypedColumn<IFactuurregel>[]>(
    () => [
      {
        name: 'Bedrag',
        title: 'Bedrag',
      },
      {
        name: 'Omschrijving',
        title: 'Omschrijving',
      },
      {
        name: '__contract' as any,
        title: 'Contract',
      },
      {
        name: 'periodeVan',
        title: 'Van',
      },
      {
        name: 'periodeTot',
        title: 'Tot',
      },
      {
        name: 'BtwPercentage',
        title: 'Btw %',
      },
    ],
    [],
  );

  const kolomBreedtesFactuurregels = useMemo<TypedTableColumnWidthInfo<IFactuurregel>[]>(
    () => [
      {
        columnName: 'Bedrag',
        width: 100,
      },
      {
        columnName: 'Omschrijving',
        width: 500,
      },
      {
        columnName: '__contract' as any,
        width: 100,
      },
      {
        columnName: 'periodeVan',
        width: 100,
      },
      {
        columnName: 'periodeTot',
        width: 100,
      },
      {
        columnName: 'BtwPercentage',
        width: 100,
      },
    ],
    [],
  );

  const kolommenAanmaningen = useMemo<TypedColumn<IOphalenAanmaningenResultElement>[]>(
    () => [
      {
        name: 'Aanmaningdatum',
        title: 'Datum',
      },
      {
        name: 'Vervaldatum',
        title: 'Verv.datum',
      },
      {
        name: 'Definitief',
        title: 'Def.',
      },
      {
        name: '__bedragInAanmaning' as any,
        title: 'Bedrag',
      },
      {
        name: '__kostenAanmaning' as any,
        title: 'Kosten',
      },
      {
        name: 'Bedrag',
        title: 'Tot. aanm.',
      },
    ],
    [],
  );

  const kolomBreedtesAanmaningen = useMemo<
    TypedTableColumnWidthInfo<IOphalenAanmaningenResultElement>[]
  >(
    () => [
      {
        columnName: 'Aanmaningdatum',
        width: 90,
      },
      {
        columnName: 'Vervaldatum',
        width: 90,
      },
      {
        columnName: 'Definitief',
        width: 60,
      },
      {
        columnName: '__bedragInAanmaning' as any,
        width: 80,
      },
      {
        columnName: '__kostenAanmaning' as any,
        width: 80,
      },
      {
        columnName: 'Bedrag',
        width: 110,
      },
    ],
    [],
  );

  const kolomExtensies: VirtualTableBase.ColumnExtension[] = useMemo(() => {
    return [
      // {
      //   columnName: `__bedragInAanmaning`,
      //   align: 'right',
      // },
      // {
      //   columnName: `__kostenAanmaning`,
      //   align: 'right',
      // },
      // {
      //   columnName: `Bedrag`,
      //   align: 'right',
      // },
    ];
  }, []);

  return (
    <>
      <div className="d-flex flex-column flex-fill" style={{ minHeight: 700 }}>
        {factuur === null || aanmaningen === null ? (
          <div className="d-flex flex-fill align-items-center justify-content-center">
            <LoadingSpinner />
          </div>
        ) : (
          <>
            <div className="d-flex flex-column pl-3 pr-3 pb-3">
              <div className="row mt-3">
                <div className="col-12">
                  <div className="row">
                    <div className="col-3">
                      {/* <label></label> */}
                      <VeldWeergave>
                        <GegevensLayout
                          gegevens={[
                            {
                              label: 'Factuurnr',
                              waarde: `${
                                factuur.Factuurnummer !== null ? factuur.Factuurnummer : 'CONCEPT'
                              }`,
                            },
                            {
                              label: 'Fact.datum',
                              waarde: `${format(new Date(factuur.Factuurdatum), 'dd-MM-yyyy')}`,
                            },
                            {
                              label: 'Factuurkenmerk',
                              waarde: `${factuur.Kenmerk !== null ? factuur.Kenmerk : ''}`,
                            },
                            {
                              label: 'Vervaldatum',
                              waarde: `${format(new Date(factuur.Vervaldatum), 'dd-MM-yyyy')}`,
                            },
                            {
                              label: 'Geregistreerd',
                              waarde: `${
                                factuur.RecordToegevoegd !== null
                                  ? format(new Date(factuur.RecordToegevoegd), 'dd-MM-yyyy HH:mm')
                                  : ''
                              }`,
                            },
                          ]}
                        />
                      </VeldWeergave>
                    </div>
                    <div className="col-3">
                      {/* <label></label> */}
                      <VeldWeergave>
                        <GegevensLayout
                          gegevens={[
                            {
                              label: 'Bedrag',
                              waarde: <FormatteerBedrag bedrag={factuur.Bedrag} />,
                            },
                            {
                              label: 'Openstaand',
                              waarde: factuur.Geboekt ? (
                                <FormatteerBedrag
                                  bedrag={factuur.Openstaand}
                                  opmaakComponent={(opmaakProps) => {
                                    return (
                                      <StandaardMutatieBedragOpmaker
                                        {...opmaakProps}
                                        color={factuur.Openstaand === 0 ? EKleur.Groen : undefined}
                                      />
                                    );
                                  }}
                                />
                              ) : (
                                <span style={{ color: EKleur.Rood }}>Niet geboekt</span>
                              ),
                            },
                            {
                              label: 'Bedrag excl.',
                              waarde: (
                                <FormatteerBedrag
                                  bedrag={
                                    factuur.Bedrag - _.sum(factuur.regels.map((x) => x.BtwBedrag))
                                  }
                                />
                              ),
                            },
                            {
                              label: 'Btw-bedrag',
                              waarde: (
                                <FormatteerBedrag
                                  bedrag={_.sum(factuur.regels.map((x) => x.BtwBedrag))}
                                />
                              ),
                            },
                            null,
                          ]}
                        />
                      </VeldWeergave>
                    </div>
                    <div className="col-2">
                      <VeldWeergave>
                        <GegevensLayout
                          gegevens={[
                            {
                              label: 'Definitief',
                              waarde: <span>{factuur.Definitief ? 'Ja' : 'Nee'}</span>,
                            },
                            {
                              label: 'Geboekt',
                              waarde: <span>{factuur.Geboekt ? 'Ja' : 'Nee'}</span>,
                            },
                            {
                              label: 'Versturen',
                              waarde: <span>{factuur.Versturen ? 'Ja' : 'Nee'}</span>,
                            },
                            {
                              label: 'Verstuurd',
                              waarde: <span>{factuur.Verstuurd ? 'Ja' : 'Nee'}</span>,
                            },
                            {
                              label: 'Bestand',
                              waarde: <span>{factuur.BestandID !== null ? 'Ja' : 'Nee'}</span>,
                            },
                          ]}
                        />
                      </VeldWeergave>
                    </div>
                    <div className="col-4">
                      {/* <label></label> */}
                      <VeldWeergave>
                        <GegevensLayout
                          gegevens={[
                            {
                              label: 'Betaalwijze',
                              waarde: factuur.betaalwijze.Naam,
                            },
                            null,
                            {
                              label: 'IBAN',
                              waarde:
                                rekening !== null ? (
                                  !rekening.NietGebruiken ? (
                                    <span>{rekening.IBAN}</span>
                                  ) : (
                                    <span style={{ color: EKleur.Rood }}>{rekening.IBAN}</span>
                                  )
                                ) : (
                                  ''
                                ),

                              // rekening !== null
                              //   ? rekening.IBAN
                              //   : '' + factuur.rekening !== null
                              //   ? ''
                              //   : '',
                            },
                            {
                              label: 'Rekeningnaam',
                              waarde: rekening !== null ? rekening.Rekeningnaam : '',
                            },
                            {
                              label: 'Toevoeging naam',
                              waarde: factuur.ToevoegingNaamOpFactuur ?? '',
                            },
                          ]}
                        />
                      </VeldWeergave>
                    </div>
                  </div>
                </div>

                {/* <div className="col-12 d-flex mt-3">
                <div className="row">
                  <div className="col-4">
                    <VeldWeergave>
                      <GegevensLayout
                        gegevens={[
                          {
                            label: 'Versturen',
                            waarde: <span>{factuur.Versturen ? 'Ja' : 'Nee'}</span>,
                          },
                          {
                            label: 'Verstuurd',
                            waarde: <span>{factuur.Verstuurd ? 'Ja' : 'Nee'}</span>,
                          },
                          null,
                        ]}
                      />
                    </VeldWeergave>
                  </div>
                </div>
              </div> */}

                {factuur.Kenmerk !== null && (
                  <div className="col-12 d-flex mt-3">
                    <div className="col-12">
                      <VeldWeergave>
                        <div>Factuurkenmerk: {factuur.Kenmerk}</div>
                      </VeldWeergave>
                    </div>
                  </div>
                )}

                <div className="col-12 d-flex">
                  <div className="col-12">
                    <span></span>
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex flex-column flex-fill mt-3">
              <div className="col-12">
                <h6>Regels:</h6>
                <GridStyleWrapper maxHeight={250} rowAmount={factuur.regels.length}>
                  <Grid rows={factuur.regels} columns={kolommenFactuurregels}>
                    <DataTypeProvider
                      for={[nameOf<IFactuurregel>('Bedrag')]}
                      formatterComponent={(formatterProps) => (
                        <FormatteerBedrag bedrag={formatterProps.value} />
                      )}
                    />

                    <DataTypeProvider
                      for={['__contract']}
                      formatterComponent={(formatterProps) => {
                        const rij: IFactuurregel = formatterProps.row;

                        if (rij.contract === null) {
                          return <span></span>;
                        }
                        return <ContractVisualisatie cntID={rij.contract.CntID} />;
                      }}
                    />

                    <DataTypeProvider
                      for={[
                        nameOf<IFactuurregel>('periodeVan'),
                        nameOf<IFactuurregel>('periodeTot'),
                      ]}
                      formatterComponent={(formatterProps) => {
                        if (formatterProps.value === null) {
                          return <span></span>;
                        }
                        return <span>{format(new Date(formatterProps.value), 'dd-MM-yyyy')}</span>;
                      }}
                    />

                    <DataTypeProvider
                      for={[nameOf<IFactuurregel>('BtwPercentage')]}
                      formatterComponent={(formatterProps) => {
                        return formatterProps.value;
                      }}
                    />

                    <VirtualTable
                      columnExtensions={kolomExtensies}
                      messages={{ noData: 'Geen regels' }}
                    />
                    <TableColumnResizing defaultColumnWidths={kolomBreedtesFactuurregels} />
                    <TableHeaderRow />
                  </Grid>
                </GridStyleWrapper>
              </div>
            </div>

            {/* {aanmaningen.length !== 0 && ( */}
            <div className="d-flex flex-column flex-fill mt-3">
              <div className="col-12">
                <h6>Aanmaningen:</h6>
                <GridStyleWrapper maxHeight={500} rowAmount={aanmaningen.length}>
                  <Grid rows={aanmaningen} columns={kolommenAanmaningen}>
                    <DataTypeProvider
                      for={[
                        nameOf<IOphalenAanmaningenResultElement>('Aanmaningdatum'),
                        nameOf<IOphalenAanmaningenResultElement>('Vervaldatum'),
                      ]}
                      formatterComponent={(formatterProps) => (
                        <span>
                          {formatterProps.value === null
                            ? null
                            : format(new Date(formatterProps.value), 'dd-MM-yyyy')}
                        </span>
                      )}
                    />

                    <DataTypeProvider
                      for={[nameOf<IOphalenAanmaningenResultElement>('Definitief')]}
                      formatterComponent={(formatterProps) => (
                        <span>{formatterProps.value ? 'Ja' : 'Nee'}</span>
                      )}
                    />

                    <DataTypeProvider
                      for={['__bedragInAanmaning']}
                      formatterComponent={(formatterProps) => {
                        const rij: IOphalenAanmaningenResultElement = formatterProps.row;

                        const aanmaning = aanmaningen.find((x) => x.AanmID === rij.AanmID)!;
                        const factuurInAanmaning = aanmaning.facturen.find(
                          (x) => x.FactID === factuur.FactID,
                        )!;
                        const bedrag = factuurInAanmaning.BedragInAanmaning;
                        return <FormatteerBedrag bedrag={bedrag} />;
                      }}
                    />

                    <DataTypeProvider
                      for={['__kostenAanmaning']}
                      formatterComponent={(formatterProps) => {
                        const rij: IOphalenAanmaningenResultElement = formatterProps.row;

                        const aanmaning = aanmaningen.find((x) => x.AanmID === rij.AanmID)!;
                        const factuurInAanmaning = aanmaning.facturen.find(
                          (x) => x.FactID === factuur.FactID,
                        )!;
                        const bedrag = factuurInAanmaning.KostenAanmaning;
                        return <FormatteerBedrag bedrag={bedrag} />;
                      }}
                    />

                    <DataTypeProvider
                      for={[nameOf<IOphalenAanmaningenResultElement>('Bedrag')]}
                      formatterComponent={(formatterProps) => (
                        <FormatteerBedrag bedrag={formatterProps.value} />
                      )}
                    />

                    <VirtualTable
                      columnExtensions={kolomExtensies}
                      messages={{ noData: 'Geen aanmaningen' }}
                    />
                    <TableColumnResizing defaultColumnWidths={kolomBreedtesAanmaningen} />
                    <TableHeaderRow />
                  </Grid>
                </GridStyleWrapper>
              </div>
            </div>
            {/* )} */}

            <div className="col-12 d-flex mt-4 mb-2">
              <button
                className="btn btn-sm btn-light d-flex align-items-center"
                style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                onClick={async () => {
                  setIsBezigMetFactuurOphalen(true);
                  const result = await api.v2.factuur.genereerFactuurPDF({
                    factID: factuur.FactID,
                  });
                  const downloadLink = result.bestand.url;
                  setFactuurWeergevenState({
                    url: downloadLink,
                  });
                  setIsBezigMetFactuurOphalen(false);
                }}
                disabled={isBezigMetFactuurOphalen}
              >
                {isBezigMetFactuurOphalen && (
                  <LoadingSpinner dikte="2px" grootte="15px" style={{ marginRight: 5 }} />
                )}
                <IconUitvergroten style={{ fill: Kleur.Grijs, width: 16, height: 16 }} />
                <span className="ml-2">Factuur weergeven</span>
              </button>

              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-3"
                style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                onClick={async () => {
                  setIsBezigMetFactuurOphalen(true);
                  const result = await api.v2.factuur.genereerFactuurPDF({
                    factID: factuur.FactID,
                  });
                  const downloadLink = result.bestand.url;
                  window.open(downloadLink, '_blank');
                  setIsBezigMetFactuurOphalen(false);
                }}
                disabled={isBezigMetFactuurOphalen}
              >
                {isBezigMetFactuurOphalen && (
                  <LoadingSpinner dikte="2px" grootte="15px" style={{ marginRight: 5 }} />
                )}
                <IconDownload style={{ fill: Kleur.Grijs, width: 16, height: 16 }} />
                <span className="ml-2">Factuur downloaden</span>
              </button>
            </div>
          </>
        )}
      </div>
      {factuurWeergevenState !== null && (
        <MediaWeergaveDialoog
          current={1}
          mediaWeergaven={[
            {
              id: 1,
              type: EMediaWeergaveType.PDF,
              mediaType: 'application/pdf',
              src: factuurWeergevenState.url,
            },
          ]}
          open
          onSuccess={() => setFactuurWeergevenState(null)}
          onAnnuleren={() => setFactuurWeergevenState(null)}
        />
      )}
    </>
  );
};

export default FactuurTab;
