import React, { useMemo } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Gegevens from './Gegevens';
import Documenten from './Documenten';
import Tabblad, { ITabblad } from '../../../../../components/layout/Tabblad';
import Signaleringen from '../../../../../components/kaart/relatie/Signaleringen';
import Rekeningen from './Rekeningen';

interface IProps extends RouteComponentProps {
  relID: number;
}

export enum ETabblad {
  Gegevens = 1,
  Documenten = 2,
  Signaleringen = 3,
  // Rekeningen = 4,
}

const pathMap: Record<ETabblad, string> = {
  [ETabblad.Gegevens]: '/gegevens',
  [ETabblad.Documenten]: '/documenten',
  [ETabblad.Signaleringen]: '/signaleringen',
  // [ETabblad.Rekeningen]: '/rekeningen',
};

const LeverancierComp: React.FC<IProps> = (props) => {
  const tabblad = useMemo<ETabblad | null>(() => {
    const matchKey = Object.keys(pathMap).find((key) => {
      const path = pathMap[Number(key) as ETabblad]!;
      return props.location.pathname.endsWith(path);
    });
    if (matchKey === undefined) {
      props.history.push(`${props.match.path}${pathMap[ETabblad.Gegevens]}`);
      return null;
    }
    return Number(matchKey) as ETabblad;
  }, [props.location.pathname]);

  const tabbladen = useMemo<ITabblad<ETabblad>[]>(
    () => [
      {
        id: ETabblad.Gegevens,
        label: 'Gegevens',
        content: Gegevens,
      },
      {
        id: ETabblad.Documenten,
        label: 'Documenten',
        content: Documenten,
      },
      {
        id: ETabblad.Signaleringen,
        label: 'Signaleringen',
        content: Signaleringen,
      },
      // {
      //   id: ETabblad.Rekeningen,
      //   label: 'Rekeningen',
      //   content: Rekeningen,
      // },
    ],
    [],
  );

  return (
    <div className="d-flex flex-column flex-fill flex-fill">
      {tabblad !== null && (
        <Tabblad<ETabblad>
          geselecteerd={tabblad}
          onSelectieChange={(tab: ETabblad) => {
            props.history.push(
              `${props.location.pathname.replace(pathMap[tabblad], '')}${pathMap[tab]}`,
            );
          }}
          tabbladen={tabbladen}
          options={{
            tabbladComponentProps: {
              relID: props.relID,
            },
          }}
        />
      )}
    </div>
  );
};

const Leverancier = withRouter(LeverancierComp);
export default Leverancier;
