import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import api from '../../../../../../../api';
import LoadingSpinner from '../../../../../../../components/Gedeeld/LoadingSpinner';
import { observer } from 'mobx-react-lite';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import useUrlState from '../../../../../../../core/useUrlState';
import { IOphalenOpdrachtenResultElement } from '../../../../../../../../../shared/src/api/v2/bank/opdracht/opdracht';
import { RootStoreContext } from '../../../../../../../stores/RootStore';
import {
  DXTableCheckboxComponent,
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
  DXTableToggleCellComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../../../../helpers/dxTableGrid';
import { Kleur } from '../../../../../../../bedrijfslogica/constanten';
import {
  Grid,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  DataTypeProvider,
  EditingState,
  RowDetailState,
  SelectionState,
  VirtualTable as VirtualTableBase,
} from '@devexpress/dx-react-grid';
import { format } from 'date-fns';
import FormatteerBedrag from '../../../../../../../components/MutatieBedrag';
import { EResultType } from '../../../../../../../stores/CheckStore';
import { IconSend } from '../../../../../../../components/Icons';
import * as _ from 'lodash';
import MenuLayout from '../../../../../../../components/MenuLayout';
import { IOphalenAdviezenResultElement } from '../../../../../../../../../shared/src/api/v2/product/advies';
import nameof from '../../../../../../../core/nameOf';
import DetailComponent from './DetailComp';

interface IProps extends RouteComponentProps {
  relID: number;
}

interface IUrlState {
  selectie: number[];
}

const defaultUrlState: IUrlState = {
  selectie: [],
};

const geenData = {
  noData: 'Geen adviezen aanwezig',
};

export interface IRegel extends IOphalenAdviezenResultElement {}

const AdviezenComp: React.FC<IProps> = observer((props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState<IUrlState>(props, defaultUrlState);
  const { checkStore } = useContext(RootStoreContext);

  const [adviezen, setAdviezen] = useState<IRegel[] | null>(null);

  const ophalenAdviezen = useCallback(async () => {
    const adviezen = (
      await api.v2.product.advies.ophalenProductadviezen({
        filterSchema: { filters: [{ naam: 'REL_IDS', data: [props.relID] }] },
      })
    ).adviezen;

    setAdviezen(adviezen);
  }, [props.relID]);

  useEffect(() => {
    ophalenAdviezen();
  }, [ophalenAdviezen]);

  const keyExtractor = useCallback((row: IRegel) => row.ID, []);

  const kolommen = useMemo<TypedColumn<IRegel>[]>(
    () => [
      {
        name: 'DatumAdvies',
        title: 'Adviesdatum',
      },
      {
        name: 'DatumVerstuurd',
        title: 'Verstuurd',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRegel>[]>(
    () => [
      {
        columnName: 'DatumAdvies' as any,
        width: 115,
      },
      {
        columnName: 'DatumVerstuurd' as any,
        width: 135,
      },
    ],
    [],
  );

  const kolomExtensies: VirtualTableBase.ColumnExtension[] = useMemo(() => {
    return [{ columnName: 'Bedrag', align: 'right' }];
  }, []);

  const handleVersturen = useCallback(async () => {
    if (
      (
        await checkStore.bevestigen({
          inhoud: 'Productadvies versturen?',
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    await api.v2.product.advies.versturenProductAdviezen({
      ids: urlState.selectie,
    });

    setUrlStateSync('selectie', []);
    ophalenAdviezen();
  }, [urlState.selectie]);

  const handleVerversenAangevraagd = useCallback(async () => {
    await ophalenAdviezen();
  }, [ophalenAdviezen]);

  // const RowDetailComponent = useCallback(
  //   (props: TableRowDetail.ContentProps) => (
  //     <RowDetailFacturen {...props} onVerversenAangevraagd={handleVerversenAangevraagd} />
  //   ),
  //   [handleVerversenAangevraagd],
  // );

  return (
    <>
      {adviezen === null ? (
        <div className="d-flex flex-fill justify-content-center align-items-center">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          <MenuLayout
            menu={
              <div className="d-flex">
                <button
                  className="btn btn-sm btn-light d-flex align-items-center"
                  style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                  disabled={urlState.selectie.length === 0}
                  onClick={() => handleVersturen()}
                >
                  <IconSend style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                  <span className="ml-2">Versturen advies</span>
                </button>
              </div>
            }
            body={
              <>
                <GridStyleWrapper height="calc(100vh - 150px)">
                  <Grid columns={kolommen} getRowId={keyExtractor} rows={adviezen!}>
                    <DataTypeProvider
                      for={[nameof<IRegel>('DatumAdvies')]}
                      formatterComponent={(formatterProps) => {
                        const rij: IOphalenOpdrachtenResultElement = formatterProps.row;

                        return <span>{format(new Date(formatterProps.value), 'dd-MM-yyyy')}</span>;
                      }}
                    />

                    <DataTypeProvider
                      for={[nameof<IRegel>('DatumVerstuurd')]}
                      formatterComponent={(formatterProps) => {
                        const rij: IOphalenOpdrachtenResultElement = formatterProps.row;
                        const datum =
                          formatterProps.value !== null
                            ? format(new Date(formatterProps.value), 'dd-MM-yyyy HH:mm')
                            : '';

                        return <span>{datum}</span>;
                      }}
                    />

                    <VirtualTable messages={geenData} columnExtensions={kolomExtensies} />

                    <RowDetailState defaultExpandedRowIds={[]} />

                    <EditingState
                      onAddedRowsChange={() => {}}
                      onCommitChanges={async (changes) => {
                        if (changes.deleted === undefined) {
                          return;
                        }
                        const deleted = changes.deleted;
                        const id = deleted[deleted.length - 1] as number;

                        const params = { ids: [id] };

                        if (
                          (
                            await checkStore.bevestigen({
                              inhoud: `Productadvies verwijderen?`,
                            })
                          ).type === EResultType.Annuleren
                        ) {
                          return;
                        }

                        await api.v2.product.advies.verwijderenProductAdviezen(params);
                        await ophalenAdviezen();
                      }}
                      onEditingRowIdsChange={(rowIds) => {
                        const id = rowIds[rowIds.length - 1] as number;
                      }}
                    />
                    <TableEditColumn
                      width={65}
                      // showAddCommand={true}
                      showEditCommand
                      showDeleteCommand
                      cellComponent={DXTableEditColumnCellComponent}
                      commandComponent={DXTableEditColumnCommandComponent}
                    />

                    <SelectionState
                      selection={urlState.selectie}
                      onSelectionChange={(value) => setUrlStateSync('selectie', value as number[])}
                    />
                    <TableRowDetail
                      contentComponent={DetailComponent}
                      toggleCellComponent={DXTableToggleCellComponent}
                    />
                    <TableSelection cellComponent={DXTableCheckboxComponent} />

                    <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
                    <TableHeaderRow />
                  </Grid>
                </GridStyleWrapper>
              </>
            }
          />
        </>
      )}
    </>
  );
});

export default withRouter(AdviezenComp);
