import React, { useContext, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { observer } from 'mobx-react-lite';
import Tabblad, { ITabblad } from '../../../components/layout/Tabblad';
import LoadingSpinner from '../../../components/Gedeeld/LoadingSpinner';
import useUrlTabblad from '../../../core/useUrlTabblad';
import Register from './Register';
import Activeren from './Activeren';
import Deactiveren from './Deactiveren';
import Afschrijvingen from './Afschrijvingen';

interface IProps extends RouteComponentProps {}

export enum ETabblad {
  Activeren = 2,
  Deactiveren = 3,
  Afschrijvingen = 4,
  Register = 1,
}

const pathMap: Record<ETabblad, string> = {
  [ETabblad.Activeren]: '/activeren',
  [ETabblad.Deactiveren]: '/deactiveren',
  [ETabblad.Afschrijvingen]: '/afschrijvingen',
  [ETabblad.Register]: '/register',
};

const Activaregister: React.FC<IProps> = observer((props) => {
  const { tabblad, onChange: onChangeTabblad } = useUrlTabblad<ETabblad>(
    props,
    pathMap,
    ETabblad.Activeren,
  );

  const tabbladen = useMemo<ITabblad<ETabblad>[]>(
    () => [
      {
        id: ETabblad.Activeren,
        label: 'Activeren',
        content: Activeren,
      },
      {
        id: ETabblad.Deactiveren,
        label: 'Deactiveren',
        content: Deactiveren,
      },
      {
        id: ETabblad.Afschrijvingen,
        label: 'Afschrijvingen',
        content: Afschrijvingen,
      },
      {
        id: ETabblad.Register,
        label: 'Register',
        content: Register,
      },
    ],
    [],
  );

  return (
    <Tabblad
      geselecteerd={tabblad}
      onSelectieChange={(tabblad) => onChangeTabblad(tabblad)}
      tabbladen={tabbladen}
    />
  );
});

export default withRouter(Activaregister);
