import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import IDialoogProps from '../../../core/IDialoogProps';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { Field, FieldProps, Formik, FormikActions, FormikErrors, FormikProps } from 'formik';
import Combobox, { IOptie } from '../../../components/formulier/Combobox';
import { RootStoreContext } from '../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import api from '../../../api';
import { EResultType } from '../../../stores/CheckStore';
import * as Yup from 'yup';
import teksten from '../../../bedrijfslogica/teksten';
import FormikVeldFout from '../../../components/formulier/FormikVeldFout';
import Dialoog from '../../../components/dialogen/Dialoog';
import LoadingSpinner from '../../../components/Gedeeld/LoadingSpinner';
import { dagDatum } from '../../../helpers/datum';
import DatumKiezer from '../../../components/formulier/DatumKiezer';
import { addDays } from 'date-fns';
import nameOf from '../../../core/nameOf';

interface IProps extends IDialoogProps<{}> {
  ids: number[];
}

interface IFormikValues {
  aanmaningdatum: Date;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  aanmaningdatum: 'Aanmaningsdatum',
};

const minimaleDatum = addDays(new Date(), -5);
const maximaleDatum = addDays(new Date(), +5);

const WijzigenAanmaningdatumDialoog = observer((props: IProps) => {
  const { onAnnuleren, onSuccess, open } = props;
  const { checkStore } = useContext(RootStoreContext);
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      setHasSubmitted(true);

      actions.setSubmitting(true);

      const params = { ids: props.ids, aanmaningdatum: values.aanmaningdatum };

      const checkData = await api.v2.aanmaning.checkWijzigenAanmaningdatum(params);
      const checkResult = await checkStore.controleren({
        checkData,
      });
      if (checkResult.type === EResultType.Annuleren) {
        setHasSubmitted(false);
        actions.setSubmitting(false);
        return;
      }

      if (
        (
          await checkStore.bevestigen({
            inhoud: `Aanpassen?`,
          })
        ).type === EResultType.Annuleren
      ) {
        setHasSubmitted(false);
        actions.setSubmitting(false);
        return;
      }

      await api.v2.aanmaning.wijzigenAanmaningdatum(params);

      onSuccess({});
      actions.setSubmitting(false);
    },
    [onSuccess, setHasSubmitted, props.ids],
  );

  const initieleWaarden = useMemo<IFormikValues | null>(() => {
    return {
      aanmaningdatum: dagDatum(new Date()),
    };
  }, []);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        aanmaningdatum: Yup.date().required(
          teksten.formulier.E_VERPLICHT_VELD({ veldnaam: veldnamen.aanmaningdatum }),
        ),
      }),
    [],
  );

  return (
    <Dialoog index={props.dialoogIndex || 0}>
      {false ? (
        <LoadingSpinner />
      ) : (
        <>
          <ModalHeader>
            <ModalTitle>Aanmaningsdatum wijzigen</ModalTitle>
          </ModalHeader>
          <Formik<IFormikValues>
            onSubmit={handleSubmit}
            initialValues={initieleWaarden!}
            isInitialValid
            validationSchema={validationSchema}
            render={(formikProps: FormikProps<IFormikValues>) => {
              const { submitForm, isSubmitting, values, isValid } = formikProps;
              return (
                <>
                  <ModalBody>
                    <div className="form-group">
                      <div className="row">
                        <div className="col-12">
                          <label>{veldnamen.aanmaningdatum}</label>
                          <Field
                            name={nameOf<IFormikValues>('aanmaningdatum')}
                            render={({ field, form }: FieldProps<IFormikValues>) => {
                              return (
                                <div className="d-flex align-items-center">
                                  <DatumKiezer
                                    onGewijzigd={(x) => {
                                      form.setFieldValue(field.name, x);
                                    }}
                                    waarde={field.value}
                                    determineValidDate={(date) => {
                                      return date >= minimaleDatum && date < maximaleDatum;
                                    }}
                                    determineNextValidDate={(date) => {
                                      const newDate = addDays(date, 1);
                                      if (newDate < maximaleDatum) {
                                        return newDate;
                                      }
                                      return null;
                                    }}
                                    determinePreviousValidDate={(date) => {
                                      const newDate = addDays(date, -1);
                                      if (newDate >= minimaleDatum) {
                                        return newDate;
                                      }
                                      return null;
                                    }}
                                  />
                                </div>
                              );
                            }}
                          />
                        </div>
                        {/* <div className="col-12 mt-3">
                          <label>Reden uit bedrijf</label>
                          <Field
                            name={nameof<IFormikValues>('redenUB_ID')}
                            render={(fieldProps: FieldProps<IFormikValues>) => {
                              const { field, form } = fieldProps;

                              return (
                                <>
                                  <Combobox
                                    geselecteerd={field.value}
                                    opties={redenen.map((x: any) => {
                                      return {
                                        id: x.ID,
                                        label: x.Naam,
                                      };
                                    })}
                                    onSelectieChange={(x) => form.setFieldValue(field.name, x)}
                                  />
                                </>
                              );
                            }}
                          />
                        </div> */}
                      </div>
                    </div>
                  </ModalBody>
                  <ModalFooter className="d-flex justify-content-start">
                    <button
                      className="btn btn-primary"
                      onClick={submitForm}
                      disabled={isSubmitting || !isValid}
                      // disabled={isSubmitting}
                      style={{ width: 100 }}
                    >
                      Ok
                    </button>
                    <button
                      className="btn btn-secondary"
                      onClick={onAnnuleren}
                      style={{ width: 100 }}
                    >
                      Annuleren
                    </button>
                  </ModalFooter>
                </>
              );
            }}
          />
        </>
      )}
    </Dialoog>
  );
});

export default WijzigenAanmaningdatumDialoog;
