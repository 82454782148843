import React, { useMemo } from 'react';
import { Route, Link, RouteComponentProps, NavLink, Redirect } from 'react-router-dom';
import PaginaHeader from '../PaginaHeader';
import Bankopdrachten from './Bankopdrachten';
import Bankmutaties from './Bankmutaties';
import { useAutorisatieBewaker } from '../../helpers/heeftAutorisatie';
import { EPermissie } from '../../bedrijfslogica/enums';

const Bankzaken = (props: RouteComponentProps) => {
  // useAutorisatieBewaker(
  //   props,
  //   useMemo(() => [EPermissie.FinancieelBanzakenWeergeven], []),
  // );
  const { match } = props;

  return (
    <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
      <PaginaHeader>
        <ul>
          <li>
            <NavLink
              to={`${match.url}/bankopdrachten`}
              style={{ paddingLeft: 20, paddingRight: 20 }}
              className="d-flex align-items-center justify-content-center"
            >
              {/* <IconTransport style={{ width: 20, height: 20 }} /> */}
              &nbsp; Bankopdrachten
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`${match.url}/bankmutaties`}
              style={{ paddingLeft: 20, paddingRight: 20 }}
              className="d-flex align-items-center justify-content-center"
            >
              {/* <IconTransport style={{ width: 20, height: 20 }} /> */}
              &nbsp; Bankmutaties
            </NavLink>
          </li>
        </ul>
      </PaginaHeader>

      <Route
        exact
        path={match.path}
        component={() => <Redirect to={`${match.path}/bankopdrachten`} />}
      />

      <Route path={`${match.path}/bankopdrachten`} component={Bankopdrachten} />
      <Route path={`${match.path}/bankmutaties`} component={Bankmutaties} />

      {/* <Route path={`${match.path}/export`} component={Boekingen} /> */}
      <Route exact={true} path={match.path} render={() => <h3>selecteer een subpagina</h3>} />
    </div>
  );
};

export default Bankzaken;
