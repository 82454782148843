import React, { useContext, useMemo } from 'react';
import { DashboardContext } from '../index';
import TegelBasis from '../../../components/tegel/TegelBasis';
import { Kleur as EKleur } from '../../../bedrijfslogica/constanten';
import RelatieVisualisatie from '../../../components/personalia/RelatieVisualisatie';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { format } from 'date-fns';

interface IProps extends RouteComponentProps {}

const Servicedienstinformatie: React.FC<IProps> = (props) => {
  const context = useContext(DashboardContext);

  const servicedienstenAfwezig = context.servicediensten.data!.servicedienstenAfwezig;

  const Relaties = servicedienstenAfwezig.map((x) => {
    return (
      <span
        className="d-flex row"
        style={{
          color: EKleur.Blauw,
          cursor: 'pointer',
        }}
        onClick={(ev) => {
          ev.stopPropagation();
          props.history.push(`/leverancier/${x.relID}`);
        }}
      >
        {' '}
        <span className="col-3">
          {' '}
          <RelatieVisualisatie
            relID={x.relID}
            options={{
              geenLinkToepassen: true,
            }}
          />
        </span>
        <span className="ml-2">{format(new Date(x.afwezigVan), 'dd-MM-yyyy')}</span>
        <span>&nbsp;t/m&nbsp;</span>
        <span>{format(new Date(x.afwezigTem), 'dd-MM-yyyy')}</span>
      </span>
    );
  });

  return (
    <TegelBasis titel="Servicediensten Afwezig">
      {servicedienstenAfwezig.length !== 0 ? (
        <>
          <div>{Relaties}</div>
        </>
      ) : (
        <span>Geen bijzonderheden</span>
      )}
    </TegelBasis>
  );
};

export default withRouter(Servicedienstinformatie);
