import React from 'react';
import styled from 'styled-components';
import { IconToevoegen } from '../../../../Icons';
import { Kleur } from '../../../../../bedrijfslogica/constanten';

export const Root = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
  user-select: none;
`;

interface IProps {
  onClick: () => void;
}

const ToevoegenHeading = (props: IProps) => {
  return (
    <Root>
      <button
        className="btn btn-sm btn-light d-flex align-items-center justify-content-center"
        style={{
          border: `1px solid ${Kleur.LichtGrijs}`,
          padding: '.2rem .75rem',
          position: 'relative',
          top: 1,
        }}
        onClick={props.onClick}
      >
        <IconToevoegen style={{ width: 18, height: 18, fill: Kleur.Grijs }} />
      </button>
    </Root>
  );
};

export default ToevoegenHeading;
