import React from 'react';

interface IProps {
  tekst: string;
  termen: string[];
}

const Highlighted = ({ tekst, termen }: IProps) => {
  if (termen.every((x) => x.length === 0)) {
    return <span>{tekst}</span>;
  }
  const regex = new RegExp(`(${termen.join('|')})`, 'gi');
  const parts = tekst.split(regex);
  return (
    <span>
      {parts
        .filter((part) => part)
        .map((part, i) =>
          regex.test(part) ? <mark key={i}>{part}</mark> : <span key={i}>{part}</span>,
        )}
    </span>
  );
};

export default Highlighted;
