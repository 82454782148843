import React, { useCallback, useRef, useState } from 'react';
import copy from 'copy-to-clipboard';
import { IconCopy, IconVink } from '../Icons';
import { Kleur } from '../../bedrijfslogica/constanten';
import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';

interface IProps {
  waarde: string;
  width?: number;
  height?: number;
}

const Melding = (props: any) => {
  return (
    <Tooltip id="melding-tooltip" {...props}>
      <div className="d-flex align-items-center justify-content-center">
        <span>Gekopieerd naar klembord</span>
        <span className="ml-2">
          <IconVink style={{ width: 15, height: 15, fill: Kleur.Wit }} />
        </span>
      </div>
    </Tooltip>
  );
};

const KopieerNaarKlembord: React.FC<IProps> = (props) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const timeoutIdRef = useRef<number | null>(null);
  const [meldingWeergeven, setMeldingWeergeven] = useState(false);

  const handleClick = useCallback(() => {
    if (timeoutIdRef.current !== null) {
      clearTimeout(timeoutIdRef.current);
      timeoutIdRef.current = null;
    }
    setMeldingWeergeven(true);
    copy(props.waarde);

    timeoutIdRef.current = setTimeout(() => {
      setMeldingWeergeven(false);
    }, 1500) as any;
  }, []);

  return (
    <>
      <Overlay target={buttonRef.current!} show={meldingWeergeven}>
        {Melding}
      </Overlay>

      <button
        ref={buttonRef}
        style={{
          border: 0,
          background: 0,
          outline: 0,
          padding: 0,
          margin: 0,
        }}
        onClick={handleClick}
      >
        <IconCopy
          style={{
            width: props.width || 14,
            height: props.height || 14,
            fill: 'rgb(206 206 206)',

            position: 'relative',
            top: -1,
          }}
        />
      </button>
    </>
  );
};

export default KopieerNaarKlembord;
