import { Field, FieldProps } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { IOphalenOpdrachtwijzenResultElement } from '../../../../../../../shared/src/api/v2/dienst';
import api from '../../../../../api';
import Combobox from '../../../../../components/formulier/Combobox';
import FormikVeldFout from '../../../../../components/formulier/FormikVeldFout';
import VinkVeld from '../../../../../components/formulier/VinkVeld';
import LoadingSpinner from '../../../../../components/Gedeeld/LoadingSpinner';
import nameof from '../../../../../core/nameOf';
import { IFormikValues, veldnamen } from '../../Inkoop/MutatieDialoog';
import RelatieSelectieCombobox from '../../../../../components/formulier/RelatieSelectie';
import { Kleur as EKleur } from '../../../../../bedrijfslogica/constanten';
import RelatieSelectieUitgebreid from '../../../../../components/formulier/RelatieSelectieUitgebreid';

const GedeeldFormulier: React.FC = (props) => {
  // const [relID, setRelID] = useState<number | null>(null);
  const [opdrachtWijzen, setOpdrachtWijzen] = useState<
    IOphalenOpdrachtwijzenResultElement[] | null
  >(null);

  const ophalenOpdrachtwijzen = useCallback(async () => {
    const opdrachtwijzenResult = await api.v2.dienst.ophalenOpdrachtwijzen({
      filterSchema: { filters: [] },
    });

    setOpdrachtWijzen(opdrachtwijzenResult.opdrachtwijzen);
  }, []);

  useEffect(() => {
    ophalenOpdrachtwijzen();
  }, []);

  return (
    <div className="p-3">
      <div className="row mb-3">
        <div className="col-12">
          <label>{veldnamen.relID}</label>
          <Field
            name={nameof<IFormikValues>('relID')}
            render={(fieldProps: FieldProps<IFormikValues>) => {
              const { field, form } = fieldProps;
              return (
                <>
                  <RelatieSelectieUitgebreid
                    filters={[{ naam: 'HOEDANIGHEID_NAAM_ENUMS', data: ['LEVERANCIER'] }]}
                    relID={field.value}
                    onChange={(x) => {
                      form.setFieldValue(field.name, x);
                    }}
                    isWisbaar
                    options={{ geenWaardeBericht: 'Kies een relatie' }}
                  />

                  {/* <RelatieSelectieCombobox
                    filters={[{ naam: 'HOEDANIGHEID_NAAM_ENUMS', data: ['LEVERANCIER'] }]}
                    onChange={(relID) => {
                      // setRelID(relID);
                      form.setFieldValue(field.name, relID);
                    }}
                    relID={field.value}
                    options={{ geenWaardeBericht: 'Kies een relatie' }}
                  /> */}
                  <FormikVeldFout fieldProps={fieldProps} />
                </>
              );
            }}
          />
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-6">
          <label>{veldnamen.naam}</label>
          <Field
            name={nameof<IFormikValues>('naam')}
            render={(x: FieldProps<IFormikValues>) => (
              <>
                <input className="form-control" {...x.field} />
                <FormikVeldFout fieldProps={x} />
              </>
            )}
          />
        </div>
        <div className="col-6">
          <label>{veldnamen.naamEnum}</label>
          <Field
            name={nameof<IFormikValues>('naamEnum')}
            render={(x: FieldProps<IFormikValues>) => (
              <>
                <input
                  className="form-control"
                  {...x.field}
                  style={{
                    backgroundColor: EKleur.HeelLichtGrijs,
                  }}
                />
                <FormikVeldFout fieldProps={x} />
              </>
            )}
          />
        </div>
      </div>
      {/* <div className="row mb-3">
        <div className="col-12">
          <label>{veldnamen.opdWijzeID}</label>
          <Field
            name={nameof<IFormikValues>('opdWijzeID')}
            render={(fieldProps: FieldProps<IFormikValues>) => {
              const { field, form } = fieldProps;

              if (opdrachtWijzen === null) {
                return <LoadingSpinner />;
              }

              return (
                <div>
                  <Combobox
                    geselecteerd={field.value}
                    opties={opdrachtWijzen.map((opdrachtwijze) => {
                      return {
                        id: opdrachtwijze.OpdWijzeID,
                        label: opdrachtwijze.Naam,
                      };
                    })}
                    onSelectieChange={(x) => form.setFieldValue(field.name, x)}
                  />
                </div>
              );
            }}
          />
        </div>
      </div> */}
      <div className="row mb-3">
        <div className="col-12">
          <label>{veldnamen.parameters}</label>
          <Field
            name={nameof<IFormikValues>('parameters')}
            render={(fieldProps: FieldProps<IFormikValues>) => {
              const { field, form } = fieldProps;
              return (
                <textarea
                  {...field}
                  className="form-control"
                  rows={2}
                  placeholder={'Alleen door beheerder laten vullen/bewerken'}
                  style={{
                    backgroundColor: EKleur.HeelLichtGrijs,
                  }}
                />
              );
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 d-flex">
          <Field
            name={nameof<IFormikValues>('actief')}
            render={(fieldProps: FieldProps<IFormikValues>) => {
              const { field, form } = fieldProps;
              return (
                <VinkVeld
                  aangevinkt={field.value}
                  onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                />
              );
            }}
          />
          <label className="ml-2">{veldnamen.actief}</label>
        </div>
      </div>
    </div>
  );
};

export default GedeeldFormulier;
