import React, { useCallback, useState } from 'react';
import IDialoogProps from '../../../../../core/IDialoogProps';
import Dialoog from '../../../../../components/dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import { IOphalenSponsoringVoorstelPersonenResultElement } from '../../../../../../../shared/src/api/v2/sponsoring/voorstel';
import ModalFooter from 'react-bootstrap/ModalFooter';
import api from '../../../../../api';

interface IDialoogOutput {}

interface IProps extends IDialoogProps<IDialoogOutput> {
  sponsoringVoorstelPersoon: IOphalenSponsoringVoorstelPersonenResultElement;
}

const WijzigenDialoog = (props: IProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [functie, setFunctie] = useState(props.sponsoringVoorstelPersoon.Functie ?? '');

  const handleOkClick = useCallback(async () => {
    setIsSubmitting(true);

    const trimmedFunctie = functie.trim();

    const fun = trimmedFunctie.length === 0 ? null : trimmedFunctie;

    await api.v2.sponsoring.voorstel.wijzigenSponsoringVoorstelPersoon({
      id: props.sponsoringVoorstelPersoon.ID,
      sponVoorstelID: props.sponsoringVoorstelPersoon.SponVoorstelID,
      persID: props.sponsoringVoorstelPersoon.PersID,
      functie: fun,
    });

    await props.onSuccess({});
    setIsSubmitting(false);
  }, [props.sponsoringVoorstelPersoon, functie]);

  return (
    <Dialoog index={props.dialoogIndex ?? 0}>
      <ModalHeader>
        <ModalTitle>Wijzigen sponsoring voorstel persoon</ModalTitle>
      </ModalHeader>
      <ModalBody className="p-4">
        <div className="row">
          <div className="col-12">
            <label>Functie</label>
            <input
              type="text"
              className="form-control"
              value={functie}
              onChange={(ev) => setFunctie(ev.target.value)}
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter className="d-flex flex-row justify-content-start">
        <button
          className="btn btn-primary"
          onClick={handleOkClick}
          style={{ width: 100 }}
          disabled={isSubmitting}
        >
          Ok
        </button>
        <button
          className="btn btn-secondary"
          onClick={() => props.onAnnuleren()}
          style={{ width: 100 }}
        >
          Annuleren
        </button>
      </ModalFooter>
    </Dialoog>
  );
};

export default WijzigenDialoog;
