import React, { useCallback, useRef } from 'react';
import DatumKiezer, { IDetermineValidDateProps } from '../DatumKiezer';
import TijdKiezer from '../TijdKiezer';
import InactiefOverlay from '../../InactiefOverlay';
import { IconKruis } from '../../Icons';
import { Kleur } from '../../../bedrijfslogica/constanten';
import { setHours, setMinutes } from 'date-fns';

interface IProps extends IDetermineValidDateProps {
  waarde: Date | null;
  onChange: (waarde: Date | null) => void;
  isWisbaar?: boolean;
}

const DatumEnTijdKiezer: React.FC<IProps> = (props) => {
  const tijdKiezerInputRef = useRef<HTMLInputElement>();
  const handleDatumKiezerGewijzigd = useCallback(
    (date: Date | null) => {
      let newDate = date;
      if (newDate !== null) {
        const now = new Date();
        newDate = setHours(newDate, now.getHours());
        newDate = setMinutes(newDate, now.getMinutes());
      }

      props.onChange(newDate);
      if (tijdKiezerInputRef.current !== undefined) {
        tijdKiezerInputRef.current!.select();
      }
    },
    [props.onChange, tijdKiezerInputRef.current],
  );

  return (
    <div className="d-flex align-items-center">
      <DatumKiezer
        waarde={props.waarde}
        onGewijzigd={handleDatumKiezerGewijzigd}
        geenDagDatum
        determineNextValidDate={props.determineNextValidDate}
        determinePreviousValidDate={props.determinePreviousValidDate}
        determineValidDate={props.determineValidDate}
      />
      <div className="ml-1">
        <TijdKiezer
          waarde={props.waarde}
          onChange={props.onChange}
          onInputRef={(ref) => (tijdKiezerInputRef.current = ref)}
        />
      </div>
      {props.isWisbaar && props.waarde !== null && (
        <button
          className="ml-2"
          style={{ outline: 0, padding: 0, background: 0, border: 0 }}
          onClick={() => props.onChange(null)}
        >
          <IconKruis style={{ fill: Kleur.Grijs, width: 20, height: 20 }} />
        </button>
      )}
    </div>
  );
};

export default DatumEnTijdKiezer;
