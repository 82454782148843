import React, { useContext, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { observer } from 'mobx-react-lite';
import Tabblad, { ITabblad } from '../../../components/layout/Tabblad';
import Voorstel from './Voorstel/index';
import Definitief from './Definitief';
import Versturen from './Versturen';
import Bankopdrachten from './Bankopdrachten';
import LoadingSpinner from '../../../components/Gedeeld/LoadingSpinner';
import useUrlState from '../../../core/useUrlState';

interface IProps extends RouteComponentProps {}

export interface ITabbladProps {
  voorstelSelectie: number[];
  onVoorstelSelectieChange: (value: number[]) => void;
  definitiefSelectie: number[];
  onDefinitiefSelectieChange: (value: number[]) => void;
  versturenSelectie: number[];
  onVersturenSelectieChange: (value: number[]) => void;
  bankopdrachtenSelectie: number[];
  onBankopdrachtenSelectieChange: (value: number[]) => void;
}

export enum ETabblad {
  Voorstel = 1,
  Definitief = 2,
  Versturen = 3,
  Bankopdrachten = 4,
}
interface IUrlState {
  tabblad: ETabblad;
  voorstelSelectie: number[];
  definitiefSelectie: number[];
  versturenSelectie: number[];
  bankopdrachtenSelectie: number[];
}
const defaultUrlState: IUrlState = {
  tabblad: ETabblad.Voorstel,
  voorstelSelectie: [],
  definitiefSelectie: [],
  versturenSelectie: [],
  bankopdrachtenSelectie: [],
};

const Facturen: React.FC<IProps> = observer((props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);

  const tabbladen = useMemo<ITabblad<ETabblad>[]>(
    () => [
      {
        id: ETabblad.Voorstel,
        label: 'Voorstel',
        content: Voorstel,
      },
      {
        id: ETabblad.Bankopdrachten,
        label: 'Bankopdrachten maken',
        content: Bankopdrachten,
      },
      {
        id: ETabblad.Versturen,
        label: 'Versturen',
        content: Versturen,
      },
      {
        id: ETabblad.Definitief,
        label: 'Definitief',
        content: Definitief,
      },
    ],
    [],
  );

  return (
    <>
      {false ? (
        <LoadingSpinner />
      ) : (
        <Tabblad
          geselecteerd={urlState.tabblad}
          onSelectieChange={(tabblad) => setUrlStateSync('tabblad', tabblad)}
          tabbladen={tabbladen}
          options={{
            tabbladComponentProps: {
              voorstelSelectie: urlState.voorstelSelectie,
              definitiefSelectie: urlState.definitiefSelectie,
              versturenSelectie: urlState.versturenSelectie,
              bankopdrachtenSelectie: urlState.bankopdrachtenSelectie,

              onVoorstelSelectieChange: (value) => setUrlStateSync('voorstelSelectie', value),
              onDefinitiefSelectieChange: (value) => setUrlStateSync('definitiefSelectie', value),
              onVersturenSelectieChange: (value) => setUrlStateSync('versturenSelectie', value),
              onBankopdrachtenSelectieChange: (value) =>
                setUrlStateSync('bankopdrachtenSelectie', value),
            } as ITabbladProps,
          }}
        />
      )}
    </>
  );
});

export default withRouter(Facturen);
