import React, { useMemo } from 'react';
import { TableRowDetail } from '@devexpress/dx-react-grid-bootstrap4';
// import { IRow } from '../index';
import api from '../../../../../api';
import useUpload from '../../../../../core/useUpload';
import BijlagenContainer, { BestandType } from '../../../../../components/BijlagenContainer';
import BijlageKnop, { EOrientatie } from '../../../../../components/BijlageKnop';
import { IOphalenWerkbonnenResultElement } from '../../../../../../../shared/src/api/v2/service/werkbon';

interface IRow extends IOphalenWerkbonnenResultElement {}

const RowDetailComp = (props: TableRowDetail.ContentProps) => {
  const row: IRow = props.row;

  const {
    moetNogUploaden,
    isBezigMetUploaden,
    uploadProgresses,
    bestanden,
    muteerBestanden,
  } = useUpload(
    useMemo(
      () => ({
        // initieleBestanden: row.bestanden.map((bestand) => ({
        //   type: BestandType.ASPDrive,
        //   bestID: bestand.ID,
        // })),
        initieleBestanden:
          row.bron !== null ? [{ type: BestandType.ASPDrive, bestand: row.bron! }] : [],
        automatischUploaden: true,
        onAlleBestandenGeuploaded: async (ids) => {
          // await api.v2.inkoopfactuur.wijzigenBestanden({
          //   inkFactID: row.InkFactID,
          //   bestandIDs: ids,
          // });
        },
      }),
      [row.bron],
    ),
  );

  return (
    <div className="p-2">
      <div className="row">
        <div className="col-4 d-flex">
          <div style={{ height: '100%' }} className="mr-2">
            <BijlageKnop
              disabled={isBezigMetUploaden}
              onBijgevoegd={(bijlagen) =>
                muteerBestanden((bestanden) => [...bestanden, ...bijlagen])
              }
              orientatie={EOrientatie.Verticaal}
            />
          </div>
          <BijlagenContainer
            bestanden={bestanden}
            uploadProgresses={uploadProgresses}
            onBestandenChange={(bijlagen) => muteerBestanden((_) => bijlagen)}
            bestandenMuterenToegestaan={!isBezigMetUploaden}
          />
        </div>
        {/* <div className="col-8">
          <BoekingTabel
            boeking={row.boeking}
            regelsSelectie={[]}
            // onRegelsSelectieChange={(value: number[]) => setUrlStateSync('regelsSelectie', value)}
            onRequestRefresh={() => null}
          ></BoekingTabel>
        </div> */}
      </div>
    </div>
  );
};

export default RowDetailComp;
