import React from 'react';
import { Kleur } from '../../../bedrijfslogica/constanten';
import styled from 'styled-components';

const Root = styled.div`
  .container {
    /* Border */
    //border: 1px solid rgba(0, 0, 0, 0.3);
    border: 0 !important;

    /* Used to position the arrow */
    position: relative;
  }

  .container__arrow {
    /* Size */
    height: 12px;
    width: 12px;

    background-color: ${Kleur.Navigatie};
    //background-color: #900096;
    position: absolute;
  }

  .container__arrow--lt {
    /* Position at the left top corner */
    left: 0;
    top: 32px;

    /* Border */
    //border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    //border-left: 1px solid rgba(0, 0, 0, 0.3);
    transform: translate(-50%, 50%) rotate(45deg);
  }

  .container__arrow--lc {
    /* Position at the left center */
    left: 0;
    top: 50%;

    /* Border */
    //border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    //border-left: 1px solid rgba(0, 0, 0, 0.3);
    transform: translate(-50%, -50%) rotate(45deg);
  }

  .container__arrow--lb {
    /* Position at the left bottom corner */
    bottom: 32px;
    left: 0;

    /* Border */
    //border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    //border-left: 1px solid rgba(0, 0, 0, 0.3);
    transform: translate(-50%, -50%) rotate(45deg);
  }

  .content {
    //padding: 15px;
  }
`;

interface IProps {}

const NavigatieOverlay: React.FC<IProps> = (props) => {
  return (
    <div style={{ position: 'relative', left: 18 }}>
      <Root
        // className="navigatie-container"
        style={{
          // backgroundColor: Kleur.Wit,
          color: 'white',
          backgroundColor: Kleur.Navigatie,
          // color: Kleur.HeelLichtGrijs,
          // border: `1px solid ${Kleur.LichtGrijs}`,
          borderRadius: 5,
          // overflow: 'hidden',
          // perspective: 1,
          // borderCollapse: 'separate',
          boxShadow: '#a7a7a7 0px 0px 15px',
        }}
      >
        <div className="content">{props.children}</div>

        <div className="container__arrow container__arrow--lt" />
        {/*<div className="container__arrow container__arrow--lc" />*/}

        {/*<div className="container__arrow container__arrow--lb" />*/}
      </Root>
    </div>
  );
};

export default NavigatieOverlay;
