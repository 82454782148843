import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import IDialoogProps from '../../../core/IDialoogProps';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { observer } from 'mobx-react-lite';
import api from '../../../api';
import { IOphalenVerkoopVoorstellenResultElement } from '../../../../../shared/src/api/v2/product/verkoop';
import VeldWeergave from '../../formulier/VeldWeergave';
import GegevensLayout from '../../layout/GegevensLayout';
import LoadingSpinner from '../../Gedeeld/LoadingSpinner';
import Dialoog from '../../dialogen/Dialoog';
import FormatteerBedrag from '../../MutatieBedrag';
import TechnischeInformatieKnop from '../../TechnischeInformatieKnop';
import { format } from 'date-fns';

interface IProps extends IDialoogProps<{}> {
  id: number;
}

const VerkoopvoorstelInfoDialoosg = observer((props: IProps) => {
  const { onAnnuleren, onSuccess, open } = props;

  const [
    verkoopvoorstel,
    setVerkoopvoorstel,
  ] = useState<IOphalenVerkoopVoorstellenResultElement | null>(null);

  const ophalenVerkoopvoorstellen = useCallback(async () => {
    const result = await api.v2.product.verkoop.ophalenVerkoopvoorstellen({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: [props.id],
          },
        ],
      },
    });
    setVerkoopvoorstel(result.voorstellen[0]);
  }, [props.id]);

  useEffect(() => {
    ophalenVerkoopvoorstellen();
  }, [ophalenVerkoopvoorstellen]);

  const totaalbedrag = useMemo<number | null>(() => {
    if (verkoopvoorstel === null) {
      return null;
    }
    const kostenVtb = verkoopvoorstel?.KostenVtb ?? 0;
    return verkoopvoorstel.Bedrag + kostenVtb;
  }, [verkoopvoorstel]);

  if (verkoopvoorstel === null || totaalbedrag === null) {
    return (
      <div className="d-flex flex-fill align-items-center justify-content-center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <>
      <Dialoog index={props.dialoogIndex ?? 0}>
        <ModalHeader>
          <ModalTitle>Info verkoopvoorstel</ModalTitle>
        </ModalHeader>

        <ModalBody>
          <div className="col-12">
            <VeldWeergave>
              <GegevensLayout
                gegevens={[
                  {
                    label: 'Product',
                    waarde:
                      (verkoopvoorstel.Typenaam ?? '') +
                      ' ' +
                      (verkoopvoorstel.Merknaam ?? '') +
                      ' (' +
                      verkoopvoorstel.ProductsoortnaamKort +
                      ')',
                  },
                  null,
                  {
                    label: 'Geregistreerd',
                    waarde:
                      verkoopvoorstel.RecordToegevoegd !== null
                        ? format(new Date(verkoopvoorstel.RecordToegevoegd), 'dd-MM-yyyy HH:mm')
                        : '',
                  },
                  {
                    label: 'Geldig van',
                    waarde: format(new Date(verkoopvoorstel.GeldigVan), 'dd-MM-yyyy'),
                  },
                  {
                    label: 'Geldig tot',
                    waarde: format(new Date(verkoopvoorstel.GeldigTot), 'dd-MM-yyyy'),
                  },
                  null,
                  {
                    label: 'Berekend bedrag',
                    waarde:
                      verkoopvoorstel.BedragBerekend !== null ? (
                        <FormatteerBedrag bedrag={verkoopvoorstel.BedragBerekend} />
                      ) : (
                        <span>Niet vastgelegd</span>
                      ),
                  },
                  {
                    label: 'Bedrag voorstel',
                    waarde: <FormatteerBedrag bedrag={verkoopvoorstel.Bedrag} />,
                  },
                  {
                    label: 'Status',
                    waarde:
                      verkoopvoorstel.Status === 1
                        ? 'Geaccepteerd'
                        : verkoopvoorstel.Status === 2
                        ? 'Afgewezen'
                        : 'Onbekend',
                  },
                  {
                    label: 'Verwerkt',
                    waarde:
                      verkoopvoorstel.VerwerktOp !== null
                        ? `${format(new Date(verkoopvoorstel.VerwerktOp), 'dd-MM-yyyy HH:mm')}`
                        : `Nee`,
                  },
                  null,
                  {
                    label: 'Kosten VTB',
                    waarde:
                      verkoopvoorstel.KostenVtb !== null ? (
                        <FormatteerBedrag bedrag={verkoopvoorstel.KostenVtb} />
                      ) : (
                        <span></span>
                      ),
                  },
                  {
                    label: 'Totaalbedrag',
                    waarde: <FormatteerBedrag bedrag={totaalbedrag} />,
                  },
                ]}
              />
            </VeldWeergave>
          </div>
        </ModalBody>
        <ModalFooter className="d-flex flex-row justify-content-start">
          <button className="btn btn-primary" onClick={onAnnuleren} style={{ width: 100 }}>
            Sluiten
          </button>
          <div className="flex-fill" />
          <TechnischeInformatieKnop
            dialoogIndex={(props.dialoogIndex ?? 0) + 1}
            content={() => <div>ID: {props.id}</div>}
          />
        </ModalFooter>
      </Dialoog>
    </>
  );
});

export default VerkoopvoorstelInfoDialoosg;
