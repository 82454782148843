import { toast } from 'react-hot-toast';

export function notificatie(type: string, text: string) {
  if (type === 'success') {
    toast.success(text, {});
  }
  if (type === 'error') {
    toast.error(text, {});
  }
}
