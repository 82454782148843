import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { EWeergaveKeuze, VerkennerSortering } from '../../drive/Verkenner';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../models/IRemoteData';
import { EVerkennerItemKolom, EVerkennerItemType } from '../../drive/TabelWeergave/types';
import { ESortering } from '../../tabel/ASPTabel/types';
import { IOrderSchema } from '../../../../../shared/src/models/order';
import api, { IPaginatiePositie } from '../../../api';
import { IEntiteitProps } from '../../kaart/EntiteitContainer';
import { naarVerkennerItemId } from '../../drive/helpers';
import { IOphalenRelBestsResult, IRelBest } from '../../../../../shared/src/api/v2/relatie/bestand';
import {
  IBestandslabelGewijzigdMessageData,
  IBestandslabelsVerwijderdMessageData,
  IBestandslabelToegevoegdMessageData,
  IOphalenBestandslabelsResult,
} from '../../../../../shared/src/api/v2/bestand/label';
import verkennerItemHernoemen from '../../drive/verkennerItemHernoemen';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../stores/RootStore';
import { useRealtimeListener } from '../../../one-off-components/realtime/RealtimeIntegratie';
import IBestandslabelsGemuteerdData from '../../../../../shared/src/realtime-manager/messages/bestand/bestandslabelsGemuteerd';
import FilterBalkV2, { IFilter, IFilterData, maakFilterSchema } from '../../FilterBalkV2';
import { RouteComponentProps } from 'react-router';
import useUrlState from '../../../core/useUrlState';
import { withRouter } from 'react-router-dom';
import { ITekstPrecisieData } from '../../../../../shared/src/api/sql';
import HorizontaleScheidingslijn from '../../layout/HorizontaleScheidingslijn';
import { GlobaleRendererContext } from '../../../one-off-components/GlobaleRenderer';
import LabelSelectieDialoog from '../../drive/TabelWeergave/LabelSelectieDialoog';
import Bestandslabel from '../../drive/TabelWeergave/Bestandslabel';
import LoadingSpinner from '../../Gedeeld/LoadingSpinner';
import {
  IBestandVerkennerItem,
  IVerkennerItem,
  IVerkennerItemBase,
  IVerkennerItemState,
} from '../../drive/types';
import TabelWeergave, { KolommenConfig } from '../../drive/TabelWeergave';
import WeergaveKeuze from '../../WeergaveKeuze';
import { IconGridView, IconGroepWeergave } from '../../Icons';
import { Kleur } from '../../../bedrijfslogica/constanten';
import GridWeergave from '../../drive/GridWeergave';
import BijlageKnop from '../../BijlageKnop';
import { BestandType, IExternBestand, ILokaalBestand } from '../../BijlagenContainer';
import { EReferentieNaamEnum } from '../../../bedrijfslogica/enums';
import { EResultType } from '../../../stores/CheckStore';
import { isBestandEnRelatieReferentie } from '../../../bedrijfslogica/bestand';
import { IFilterSchema } from '../../../../../shared/src/models/filter';
import VinkVeld from '../../formulier/VinkVeld';

enum EFilter {
  Naam = 'NAAM_UITGEBREID',
  Labels = 'BESTANDSLABEL_IDS',
  AlleenRelatieBestandRelIDs = 'ALLEEN_RELATIE_BESTAND_REL_IDS',
}

interface IProps extends IEntiteitProps, RouteComponentProps {}

interface IItemsData {
  items: Record<number, IVerkennerItem>;
  totaalAantal: number;
}

interface IUrlState {
  filterData: IFilterData<EFilter>[];
}

const verkennerSorteringNaarOrderSchema = (
  verkennerSortering: VerkennerSortering,
): IOrderSchema => {
  const verkennerItemKolomNaarOrderSchemaNaam = (kolom: EVerkennerItemKolom): string => {
    switch (kolom) {
      case EVerkennerItemKolom.Naam:
        return 'NAAM';
      case EVerkennerItemKolom.Grootte:
        return 'GROOTTE';
      case EVerkennerItemKolom.Type:
        return 'MEDIA_TYPE_NAAM_MET_TERUGVAL_OP_MEDIA_TYPE';
      case EVerkennerItemKolom.DatumToegevoegd:
        return 'RECORD_TOEGEVOEGD';
      case EVerkennerItemKolom.Opslagdienst:
        return 'OPSLAG_DIENST_NAAM';
    }
    throw new Error();
  };

  return {
    orders: verkennerSortering.map(({ key, sortering }) => ({
      naam: verkennerItemKolomNaarOrderSchemaNaam(key),
      richting: sortering === ESortering.Ascending ? 'ASC' : 'DESC',
    })),
  };
};

const bepalenVerkennerItemsVanRelatie = async (
  relID: number,
  paginatie: IPaginatiePositie,
  sortering: VerkennerSortering,
  filterSchema: IFilterSchema,
  toevoegenAanItems: Record<number, IVerkennerItem> = {},
): Promise<{ totaalAantal: number; items: Record<number, IVerkennerItem> }> => {
  const bestandenResult = await api.v2.bestand.ophalenBestanden({
    filterReferentieRelIDs: [relID],
    orderSchema: verkennerSorteringNaarOrderSchema(sortering),
    filterSchema,
  });
  const bestandXBestandslabelsResult = await api.v2.bestand.label.ophalenBestandXBestandslabels({
    filterSchema: {
      filters: [
        {
          naam: 'BESTAND_IDS',
          data: bestandenResult.bestanden.map((x) => x.ID),
        },
      ],
    },
  });

  const items = bestandenResult.bestanden.reduce<Record<number, IVerkennerItem>>(
    (acc, bestand, i) => {
      const labels = bestandXBestandslabelsResult.bestandXBestandslabels.filter(
        (x) => x.BestandID === bestand.ID,
      );
      const id = naarVerkennerItemId(bestand.ID, EVerkennerItemType.Bestand);
      return {
        ...acc,
        [paginatie.index + i]: {
          type: EVerkennerItemType.Bestand,
          id,
          bestand,
          labels,
        },
      };
    },
    toevoegenAanItems,
  );

  return {
    items,
    totaalAantal: bestandenResult.totaalAantal,
  };
};

const Bestanden = observer((props: IProps) => {
  const { checkStore } = useContext(RootStoreContext);
  const globaleRenderer = useContext(GlobaleRendererContext);
  const rowsRenderedRef = useRef<{
    startIndex: number;
    stopIndex: number;
  } | null>(null);
  const [itemsData, _setItemsData] = useState<IRemoteData<IItemsData>>(createPendingRemoteData());
  const itemsDataRef = useRef<IRemoteData<IItemsData>>(itemsData);
  const setItemsData = useCallback(
    (itemData: IRemoteData<IItemsData>) => {
      itemsDataRef.current = itemData;
      _setItemsData(itemData);
    },
    [_setItemsData],
  );
  const [itemState, setItemState] = useState<Record<string, IVerkennerItemState>>({});
  const [weergaveKeuze, setWeergaveKeuze] = useState<EWeergaveKeuze>(EWeergaveKeuze.Lijst);

  const [bestandslabelsResult, setBestandslabelsResult] = useState<
    IRemoteData<IOphalenBestandslabelsResult>
  >(createPendingRemoteData());
  const ophalenBestandslabels = useCallback(async () => {
    const result = await api.v2.bestand.label.ophalenBestandslabels({});

    setBestandslabelsResult(createReadyRemoteData(result));
  }, []);
  useEffect(() => {
    ophalenBestandslabels();
  }, [ophalenBestandslabels]);

  const [relBestsResult, setRelBestsResult] = useState<IRemoteData<IOphalenRelBestsResult>>(
    createPendingRemoteData(),
  );
  const ophalenRelBests = useCallback(async () => {
    const result = await api.v2.relatie.bestand.ophalenRelBests({
      filterSchema: {
        filters: [
          {
            naam: 'REL_IDS',
            data: [props.relID],
          },
        ],
      },
    });
    setRelBestsResult(createReadyRemoteData(result));
  }, [props.relID]);
  useEffect(() => {
    ophalenRelBests();
  }, [ophalenRelBests]);
  const relBestsBijBestandIDs = useMemo<IRemoteData<Record<number, IRelBest>>>(() => {
    if (relBestsResult.state === ERemoteDataState.Pending) {
      return createPendingRemoteData();
    }
    const data = relBestsResult.data!.relBests.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.BestandID]: curr,
      }),
      {},
    );
    return createReadyRemoteData(data);
  }, [relBestsResult]);

  useRealtimeListener(async (naamEnum, data) => {
    if (
      naamEnum === 'BESTANDSLABEL_TOEGEVOEGD' ||
      naamEnum === 'BESTANDSLABEL_GEWIJZIGD' ||
      naamEnum === 'BESTANDSLABELS_VERWIJDERD'
    ) {
      const d = data as
        | IBestandslabelToegevoegdMessageData
        | IBestandslabelGewijzigdMessageData
        | IBestandslabelsVerwijderdMessageData;

      await ophalenBestandslabels();
    }
  });

  const defaultUrlState = useMemo<IUrlState>(
    () => ({
      filterData: [
        {
          naam: EFilter.Naam,
          data: {
            precisie: 'ONGEVEER',
            waarde: '',
          } as ITekstPrecisieData,
          isActief: false,
        },
        {
          naam: EFilter.Labels,
          data: [],
          isActief: false,
        },
        {
          naam: EFilter.AlleenRelatieBestandRelIDs,
          data: [props.relID],
          isActief: true,
        },
      ],
    }),
    [props.relID],
  );
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);

  const [gefocustItemId, setGefocustItemId] = useState<string | null>(null);
  const [selectie, setSelectie] = useState<string[]>([]);
  const [sortering, setSortering] = useState<VerkennerSortering>([
    {
      key: EVerkennerItemKolom.Type,
      sortering: ESortering.Ascending,
    },
    {
      key: EVerkennerItemKolom.DatumToegevoegd,
      sortering: ESortering.Descending,
    },
  ]);

  const filterSchema = useMemo(() => maakFilterSchema(urlState.filterData), [
    JSON.stringify(urlState.filterData),
  ]);
  const orderSchema = useMemo(() => verkennerSorteringNaarOrderSchema(sortering), [
    JSON.stringify(sortering),
  ]);

  const bepalenItems = useCallback(
    async (
      paginatie: IPaginatiePositie,
      toevoegenAanItems: Record<number, IVerkennerItem> = {},
    ): Promise<{
      totaalAantal: number;
      items: Record<number, IVerkennerItem>;
    }> => {
      // const mergedFilterSchema: IFilterSchema = {
      //   uitgebreideFilter: {
      //     and: [
      //       {
      //         filter: {
      //           naam: 'REL_IDS',
      //           data: [props.relID],
      //         },
      //       },
      //       ...(filterSchema.filters?.map((filter) => ({
      //         filter,
      //       })) ?? []),
      //       ...(filterSchema.uitgebreideFilter === undefined
      //         ? []
      //         : [filterSchema.uitgebreideFilter!]),
      //     ],
      //   },
      // };

      return await bepalenVerkennerItemsVanRelatie(
        props.relID,
        paginatie,
        sortering,
        filterSchema,
        toevoegenAanItems,
      );
    },
    [props.relID, filterSchema, orderSchema, sortering],
  );

  useEffect(() => {
    (async () => {
      const data = await bepalenItems({
        index: 0,
        aantal: 50,
      });
      setItemsData(createReadyRemoteData(data));
    })();
  }, [bepalenItems]);

  const handleItemHernoemd = useCallback(
    async (id: string, naam: string) => {
      await verkennerItemHernoemen({
        id,
        naam,
        checkStore,
        items: itemsDataRef.current!.data!.items,
        onItemsChange: (items) =>
          setItemsData(
            createReadyRemoteData({
              ...itemsDataRef.current!.data!,
              items,
            }),
          ),
      });
    },
    [setItemsData, checkStore],
  );

  const handleItemsVerwijderd = useCallback(
    async (ids: string[]) => {
      // await verkennerItemsVerwijderen({
      //   ids,
      //   checkStore,
      //   data: itemsDataRef.current!.data!,
      //   onDataChange: (data) => {
      //     setItemsData(createReadyRemoteData(data));
      //   },
      // });
      //
      // const parsedIds = ids.map(vanVerkennerItemId);

      const itemsMetNull = ids.map(
        (id) =>
          Object.values(itemsDataRef.current!.data!.items).find((item) => item.id === id) ?? null,
      );

      if (itemsMetNull.some((x) => x === null)) {
        throw new Error(`Een van de items die verwijderd moeten worden kan niet worden gevonden.`);
      }

      const items = itemsMetNull as IVerkennerItem[];
      const bestandItems = items.filter(
        (x) => x.type === EVerkennerItemType.Bestand,
      ) as IBestandVerkennerItem[];

      const bestandIds = bestandItems.map((x) => x.bestand.ID);

      // Als de referenties, waarvan de referenties gelden voor zowel bestanden als relaties, alleen maar bestaat
      // uit RelatieBestand, dan zal het bestand ook echt verdwijnen uit de lijst.
      // Als het bestand meerdere referenties heeft, dan zal deze alsnog blijven bestaan maar alleen worden verwijderd
      // bij RelatieBestand.
      const bestandItemsDieVerdwijnenUitDeLijst = bestandItems.filter((x) => {
        const referenties = Object.keys(x.bestand.referenties) as EReferentieNaamEnum[];
        const relatieReferenties = referenties.filter(isBestandEnRelatieReferentie);
        return (
          relatieReferenties.length === 1 &&
          relatieReferenties[0] === EReferentieNaamEnum.RelatieBestand
        );
      });
      const bestandIDsDieVerdwijnenUitDeLijst = bestandItemsDieVerdwijnenUitDeLijst.map(
        (x) => x.bestand.ID,
      );
      const bestandIDsDieBlijven = bestandItems
        .filter((x) => bestandIDsDieVerdwijnenUitDeLijst.indexOf(x.bestand.ID) === -1)
        .map((x) => x.bestand.ID);

      if (bestandIDsDieBlijven.length > 0) {
        const bevestigenResult = await checkStore.bevestigen({
          inhoud: (
            <div>
              <p>
                Een of meerdere bestanden die je wilt verwijderen zijn zowel handmatig toegevoegd
                (via de bijvoegen knop) als dat ze door een ander entiteit binnen het systeem worden
                gerefeerd.
              </p>
              <p>
                Dit houdt in dat we voor de betreffende bestanden alleen de koppeling zullen
                verwijderen (die was gelegd tijdens het toevoegen middels de bijvoegen knop),
                waardoor het bestand wel in de lijst blijft staan, maar de verwijder knop niet meer
                zichtbaar zal zijn.
              </p>
            </div>
          ),
        });
        if (bevestigenResult.type === EResultType.Annuleren) {
          return;
        }
      }

      const newItems = Object.keys(itemsDataRef.current!.data!.items)
        .map(Number)
        .reduce(
          (acc, idx) => {
            const item = itemsDataRef.current!.data!.items[idx];

            if (
              item.type === EVerkennerItemType.Bestand &&
              bestandIDsDieVerdwijnenUitDeLijst.indexOf(item.bestand.ID) !== -1
            ) {
              return {
                ...acc,
                offset: acc.offset + 1,
              };
            }

            return {
              ...acc,
              data: {
                ...acc.data,
                [idx - acc.offset]: item,
              },
            };
          },
          {
            data: {},
            offset: 0,
          },
        ).data;

      // Optimistisch verwijderen van items
      setItemsData(
        createReadyRemoteData({
          items: newItems,
          totaalAantal:
            itemsDataRef.current!.data!.totaalAantal - bestandIDsDieVerdwijnenUitDeLijst.length,
        }),
      );

      await api.v2.relatie.bestand.verwijderenBestanden({
        relID: props.relID,
        bestandIDs: bestandIds,
      });

      // noinspection ES6MissingAwait
      ophalenRelBests();

      if (bestandIDsDieBlijven.length > 0) {
        const bestandenResult = await api.v2.bestand.ophalenBestanden({
          filterSchema: {
            filters: [
              {
                naam: 'IDS',
                data: bestandIDsDieBlijven,
              },
            ],
          },
        });

        const newItems = Object.keys(itemsDataRef.current!.data!.items)
          .map(Number)
          .reduce<Record<number, IVerkennerItem>>((acc, idx) => {
            const item = itemsDataRef.current!.data!.items[idx];

            if (item.type === EVerkennerItemType.Bestand) {
              const bijgewerktBestand = bestandenResult.bestanden.find(
                (x) => x.ID === item.bestand.ID,
              );
              if (bijgewerktBestand !== undefined) {
                return {
                  ...acc,
                  [idx]: {
                    ...item,
                    bestand: bijgewerktBestand,
                  } as IVerkennerItem,
                };
              }
            }

            return {
              ...acc,
              [idx]: item,
            };
          }, itemsDataRef.current!.data!.items);

        setItemsData(
          createReadyRemoteData({
            items: newItems,
            totaalAantal: itemsDataRef.current!.data!.totaalAantal,
          }),
        );
      }
    },
    [setItemsData, checkStore, props.relID],
  );

  const handleReportRowsRendered = useCallback(
    (index: { startIndex: number; stopIndex: number }) => {
      rowsRenderedRef.current = index;
    },
    [],
  );

  const handleExtraRijenAangevraagd = useCallback(
    async (positie: IPaginatiePositie) => {
      if (itemsDataRef.current.state === ERemoteDataState.Pending) {
        return;
      }

      const result = await bepalenItems(positie, itemsDataRef.current!.data!.items);
      setItemsData(
        createReadyRemoteData({
          ...itemsDataRef.current.data!,
          items: result.items,
          totaalAantal: result.totaalAantal,
        }),
      );
    },
    [setItemsData, bepalenItems],
  );

  useRealtimeListener((naamEnum: string, data: any) => {
    if (naamEnum === 'BESTAND_X_BESTANDSLABEL_GEMUTEERD') {
      const d = data as IBestandslabelsGemuteerdData;

      if (itemsDataRef.current!.state === ERemoteDataState.Pending) {
        return;
      }

      // Kijk of we dit bestand hebben opgehaald
      const key = Object.keys(itemsDataRef.current!.data!.items).find((key) => {
        const item = itemsData.data!.items[Number(key)];
        return (
          item !== undefined &&
          item.type === EVerkennerItemType.Bestand &&
          item.bestand.ID === d.bestandID
        );
      });

      if (key === undefined) {
        return;
      }

      const verkennerItem = itemsDataRef.current!.data!.items[Number(key)] as IVerkennerItemBase &
        IBestandVerkennerItem;

      // Als we dit bestand hebben, dan labels bijwerken
      const newItemsData: IItemsData = {
        ...itemsDataRef.current!.data!,
        items: {
          ...itemsDataRef.current!.data!.items,
          [Number(key)]: {
            ...verkennerItem,
            labels: d.bestandXBestandslabels,
          },
        },
      };
      setItemsData(createReadyRemoteData(newItemsData));
    }
  });

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.Naam,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          const tpd = weergaveProps.data as ITekstPrecisieData;
          return (
            <div className="d-flex align-items-center">
              <span className="mr-2">Naam</span>
              <input
                type="text"
                value={tpd.waarde}
                className="form-control ml-2"
                onChange={(ev) => {
                  weergaveProps.onDataChange({
                    ...tpd,
                    waarde: ev.target.value,
                  } as ITekstPrecisieData);
                }}
                onKeyUp={(ev) => {
                  if (ev.key === 'Enter') {
                    weergaveProps.setIsActief(true);
                    weergaveProps.toepassen();
                  }
                }}
              />
            </div>
          );
        },
      },
      {
        naam: EFilter.Labels,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          const bestandslabelIDs = weergaveProps.data as number[];

          return (
            <div className="d-flex align-items-center">
              <span className="mr-2">Labels</span>
              {bestandslabelsResult.state === ERemoteDataState.Pending ? (
                <LoadingSpinner />
              ) : (
                <>
                  <div
                    className="d-flex align-items-center"
                    style={{
                      maxWidth: 250,
                      overflowX: 'auto',
                    }}
                  >
                    {bestandslabelIDs.map((bestandslabelID, i) => {
                      const label = bestandslabelsResult.data!.labels.find(
                        (x) => x.ID === bestandslabelID,
                      );
                      return (
                        <React.Fragment key={bestandslabelID}>
                          {label !== undefined ? (
                            <Bestandslabel
                              label={label}
                              onRemoveClick={async () => {
                                const nieuweIDs = bestandslabelIDs.filter(
                                  (x) => x !== bestandslabelID,
                                );
                                weergaveProps.onDataChange(nieuweIDs);
                                weergaveProps.setIsActief(nieuweIDs.length > 0);
                                weergaveProps.toepassen();
                              }}
                            />
                          ) : (
                            <LoadingSpinner />
                          )}
                          {i < bestandslabelIDs.length - 1 && <span className="ml-2" />}
                        </React.Fragment>
                      );
                    })}
                  </div>
                  <a
                    href="#"
                    className="ml-2"
                    style={{ fontSize: 13 }}
                    onClick={() => {
                      globaleRenderer.render((renderProps) => {
                        return (
                          <LabelSelectieDialoog
                            open
                            onSuccess={(result) => {
                              weergaveProps.onDataChange(result.bestandslabelIDs);
                              weergaveProps.setIsActief(true);
                              weergaveProps.toepassen();
                              renderProps.destroy();
                            }}
                            onAnnuleren={() => renderProps.destroy()}
                            bestandslabelIDs={bestandslabelIDs}
                            relID={props.relID}
                          />
                        );
                      });
                    }}
                  >
                    Selecteren...
                  </a>
                </>
              )}
            </div>
          );
        },
      },
      {
        naam: EFilter.AlleenRelatieBestandRelIDs,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return <span>Enkel handmatig toegevoegd</span>;
        },
      },
    ],
    [bestandslabelsResult],
  );

  // const { muteerBestanden, isBezigMetUploaden } = useUpload({
  //   initieleBestanden:
  //     itemsData.data === null
  //       ? null
  //       : Object.keys(itemsData.data.items)
  //           .filter((key) => {
  //             const item = itemsData.data!.items[Number(key)];
  //             return item.type === EVerkennerItemType.Bestand;
  //           })
  //           .map((key) => {
  //             const item = itemsData.data!.items[Number(key)] as IBestandVerkennerItem;
  //             return {
  //               bestand: item.bestand,
  //               type: BestandType.ASPDrive,
  //             };
  //           }),
  //   automatischUploaden: true,
  //   onAlleBestandenGeuploaded: async (bestandIDs) => {
  //     console.log(bestandIDs);
  //
  //     return;
  //     await api.v2.relatie.bestand.toevoegenBestanden({
  //       relID: props.relID,
  //       bestandIDs,
  //     });
  //     const data = await bepalenItems(
  //       {
  //         index: 0,
  //         aantal: 50,
  //       },
  //       {},
  //     );
  //     setItemsData(createReadyRemoteData(data));
  //   },
  // });

  const [bezigMetBijvoegen, setBezigMetBijvoegen] = useState(false);

  const kolommenConfig = useMemo<KolommenConfig>(
    () => ({
      [EVerkennerItemKolom.Naam]: {
        kolom: {
          vasteBreedte: 250,
        },
      },
      [EVerkennerItemKolom.Grootte]: {
        kolom: {
          vasteBreedte: 100,
        },
      },
      [EVerkennerItemKolom.Type]: {
        weergeven: false,
      },
      [EVerkennerItemKolom.Opslagdienst]: {
        weergeven: false,
      },
    }),
    [],
  );

  const kolommenVolgorde = useMemo<Array<EVerkennerItemKolom>>(
    () => [
      EVerkennerItemKolom.SoortIcon,
      EVerkennerItemKolom.Naam,
      EVerkennerItemKolom.Inzien,
      EVerkennerItemKolom.Type,
      EVerkennerItemKolom.DatumToegevoegd,
      EVerkennerItemKolom.Labels,
      EVerkennerItemKolom.Referenties,
      EVerkennerItemKolom.Opslagdienst,
      EVerkennerItemKolom.Grootte,
      EVerkennerItemKolom.Acties,
    ],
    [],
  );

  const magItemVerwijderen = useCallback(
    (item: IVerkennerItem) => {
      if (
        relBestsBijBestandIDs.state === ERemoteDataState.Pending ||
        item.type === EVerkennerItemType.Map
      ) {
        return false;
      }
      const bestandID = item.bestand.ID;
      const relBest = relBestsBijBestandIDs.data![bestandID];
      return relBest !== undefined;
    },
    [relBestsBijBestandIDs],
  );

  return (
    <div className="d-flex flex-column flex-fill">
      <div className="d-flex w-100 pl-3 pr-3 p-2 align-items-center">
        <div className="d-flex align-items-center mr-2" style={{ minWidth: 120 }}>
          <BijlageKnop
            onBijgevoegd={async (bestanden) => {
              setBezigMetBijvoegen(true);

              let externeBestanden = bestanden.filter(
                (x) => x.type === BestandType.ASPDrive,
              ) as IExternBestand[];
              const lokaleBestanden = bestanden.filter(
                (x) => x.type === BestandType.Lokaal,
              ) as ILokaalBestand[];
              if (lokaleBestanden.length > 0) {
                const uploadResults = [];
                for (const lokaalBestand of lokaleBestanden) {
                  const result = await api.v2.bestand.upload(lokaalBestand);
                  uploadResults.push(result);
                }
                externeBestanden = [
                  ...externeBestanden,
                  ...uploadResults.map((x) => ({
                    type: BestandType.ASPDrive as BestandType.ASPDrive,
                    bestand: x,
                  })),
                ];
              }

              await api.v2.relatie.bestand.toevoegenBestanden({
                relID: props.relID,
                bestandIDs: externeBestanden.map((x) => x.bestand.ID),
              });

              const data = await bepalenItems(
                {
                  index: 0,
                  aantal: 50,
                },
                {},
              );
              setItemsData(createReadyRemoteData(data));
              await ophalenRelBests();

              setBezigMetBijvoegen(false);

              // await muteerBestanden((curr) => {
              //   const newArr = [...curr, ...bestanden];
              //   return newArr.filter((bestand, i) => {
              //     // Alle items overhouden, behalve de items die dubbel voorkomen
              //     // Dubbel voorkomen is als er een ander item van hetzelfde type en dezelfde identificatie bevat
              //     // Waarvan het niet dezelfde index in de array is (hetzelfde item)
              //     return !newArr.some((other, idx) => {
              //       if (idx === i) {
              //         return false;
              //       }
              //       if (other.type === BestandType.Lokaal && bestand.type === BestandType.Lokaal) {
              //         return other.url === bestand.url;
              //       }
              //       if (
              //         other.type === BestandType.ASPDrive &&
              //         bestand.type === BestandType.ASPDrive
              //       ) {
              //         return other.bestand.ID === bestand.bestand.ID;
              //       }
              //
              //       return false;
              //     });
              //   });
              // });
            }}
            disabled={bezigMetBijvoegen}
          />
        </div>
        <FilterBalkV2
          filters={filters}
          filterData={urlState.filterData}
          onFilterDataChange={(x) => setUrlStateSync('filterData', x)}
        />
        <div className="d-flex align-items-center ml-2" style={{ position: 'relative', top: 3 }}>
          <WeergaveKeuze
            weergave={weergaveKeuze}
            weergaven={[
              {
                key: EWeergaveKeuze.Lijst,
                // naam: 'Gegroepeerd',
                icon: (
                  <IconGroepWeergave
                    style={{
                      width: 18,
                      height: 18,
                      fill: Kleur.Grijs,
                    }}
                  />
                ),
                tooltip: 'Lijst',
              },
              {
                key: EWeergaveKeuze.Iconen,
                // naam: 'Niet gegroepeerd',
                icon: (
                  <IconGridView
                    style={{
                      width: 18,
                      height: 18,
                      fill: Kleur.Grijs,
                    }}
                  />
                ),
                tooltip: 'Iconen',
              },
            ]}
            onChange={(x) => setWeergaveKeuze(x)}
          />
        </div>
      </div>
      <HorizontaleScheidingslijn />
      <div className="d-flex flex-column flex-fill">
        {weergaveKeuze === EWeergaveKeuze.Lijst ? (
          <TabelWeergave
            gefocustItemId={gefocustItemId}
            onGefocustItemIdChange={setGefocustItemId}
            selectie={selectie}
            onSelectieChange={setSelectie}
            items={itemsData.data?.items ?? {}}
            totaalAantalItems={itemsData.data?.totaalAantal ?? 50}
            itemsState={itemState}
            onItemsStateChange={setItemState}
            onItemHernoemd={handleItemHernoemd}
            onItemsVerwijderd={handleItemsVerwijderd}
            sortering={sortering}
            onSorteringChange={setSortering}
            onReportRowsRendered={handleReportRowsRendered}
            magItemSelecteren={() => true}
            onExtraRijenAangevraagd={handleExtraRijenAangevraagd}
            onItemInBestandsmapToegevoegd={async () => {}}
            onItemVerplaatst={async () => {}}
            onBestandenDrop={async () => {}}
            relID={props.relID}
            kolommenConfig={kolommenConfig}
            kolommenVolgorde={kolommenVolgorde}
            magItemVerwijderen={magItemVerwijderen}
          />
        ) : (
          <GridWeergave
            gefocustItemId={gefocustItemId}
            onGefocustItemIdChange={setGefocustItemId}
            selectie={selectie}
            onSelectieChange={setSelectie}
            items={itemsData.data?.items ?? {}}
            totaalAantalItems={itemsData.data?.totaalAantal ?? 50}
            itemsState={itemState}
            onItemsStateChange={setItemState}
            onItemHernoemd={handleItemHernoemd}
            onItemsVerwijderd={handleItemsVerwijderd}
            sortering={sortering}
            onSorteringChange={setSortering}
            onReportRowsRendered={handleReportRowsRendered}
            magItemSelecteren={() => true}
            onExtraRijenAangevraagd={handleExtraRijenAangevraagd}
            onItemInBestandsmapToegevoegd={async () => {}}
            onItemVerplaatst={async () => {}}
            onBestandenDrop={async () => {}}
            relID={props.relID}
          />
        )}
      </div>
    </div>
  );
});

export default withRouter(Bestanden);
