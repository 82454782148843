import {
  IAfwijzenDuplicaatSuggestieParams,
  IAfwijzenDuplicaatSuggestieResult,
  IOphalenPersoonDuplicaatSuggestiesParams,
  IOphalenPersoonDuplicaatSuggestiesResult,
  IOphalenToegevoegdePersonenParams,
  IOphalenToegevoegdePersonenResult,
  IRegistreerDuplicatenParams,
  IRegistreerDuplicatenResult,
  IOphalenToegevoegdePersonenVervangenLijstParams,
  IOphalenToegevoegdePersonenVervangenLijstResult,
  IHerstellenToegevoegdePersoonParams,
  IHerstellenToegevoegdePersoonResult,
  IToevoegenPersoonEnRegistreerDuplicatenParams,
  IToevoegenPersoonEnRegistreerDuplicatenResult,
} from '../../../../../../shared/src/api/v2/persoon/duplicaat';
import api from '../../..';
import ICheckData from '../../../../../../shared/src/models/ICheckData';

const duplicaat = {
  ophalenPersoonDuplicaatSuggesties: async (
    params: IOphalenPersoonDuplicaatSuggestiesParams,
  ): Promise<IOphalenPersoonDuplicaatSuggestiesResult> => {
    return await api.post('v2/persoon/duplicaat/ophalen-persoon-duplicaat-suggesties', params);
  },
  checkRegistreerDuplicaten: async (params: IRegistreerDuplicatenParams): Promise<ICheckData> => {
    return await api.post('v2/persoon/duplicaat/check-registreer-duplicaten', params);
  },
  registreerDuplicaten: async (
    params: IRegistreerDuplicatenParams,
  ): Promise<IRegistreerDuplicatenResult> => {
    return await api.post('v2/persoon/duplicaat/registreer-duplicaten', params);
  },
  checkToevoegenPersoonEnRegistreerDuplicaten: async (
    params: IToevoegenPersoonEnRegistreerDuplicatenParams,
  ): Promise<ICheckData> => {
    return await api.post(
      'v2/persoon/duplicaat/check-toevoegen-persoon-en-registreer-duplicaten',
      params,
    );
  },
  toevoegenPersoonEnRegistreerDuplicaten: async (
    params: IToevoegenPersoonEnRegistreerDuplicatenParams,
  ): Promise<IToevoegenPersoonEnRegistreerDuplicatenResult> => {
    return await api.post(
      'v2/persoon/duplicaat/toevoegen-persoon-en-registreer-duplicaten',
      params,
    );
  },
  afwijzenDuplicaatSuggestie: async (
    params: IAfwijzenDuplicaatSuggestieParams,
  ): Promise<IAfwijzenDuplicaatSuggestieResult> => {
    return await api.post('v2/persoon/duplicaat/afwijzen-duplicaat-suggestie', params);
  },
  ophalenToegevoegdePersonen: async (
    params: IOphalenToegevoegdePersonenParams,
  ): Promise<IOphalenToegevoegdePersonenResult> => {
    return await api.post('v2/persoon/duplicaat/ophalen-toegevoegde-personen', params);
  },
  ophalenToegevoegdePersonenVervangenLijst: async (
    params: IOphalenToegevoegdePersonenVervangenLijstParams,
  ): Promise<IOphalenToegevoegdePersonenVervangenLijstResult> => {
    return await api.post(
      'v2/persoon/duplicaat/ophalen-toegevoegde-personen-vervangen-lijst',
      params,
    );
  },
  herstellenToegevoegdePersoon: async (
    params: IHerstellenToegevoegdePersoonParams,
  ): Promise<IHerstellenToegevoegdePersoonResult> => {
    return await api.post('v2/persoon/duplicaat/herstellen-toegevoegde-persoon', params);
  },
  checkHerstellenToegevoegdePersoon: async (
    params: IHerstellenToegevoegdePersoonParams,
  ): Promise<ICheckData> => {
    return await api.post('v2/persoon/duplicaat/check-herstellen-toegevoegde-persoon', params);
  },
};

export default duplicaat;
