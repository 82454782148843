import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { RootStoreContext } from '../../stores/RootStore';
import { Root } from '../Klant/Klantkaart/style';
import LoadingSpinner from '../../components/Gedeeld/LoadingSpinner';
import KlantInformatieSectie from '../../components/kaart/KlantInformatieSectie';
import { observer } from 'mobx-react-lite';
import Entiteiten from './Entiteiten';
import Navigatie from './Navigatie';
import api from '../../api';
import Control from '../../components/kaart/Control';
import { RouterContext } from '../../routers/RouterContextProvider';
import { EKaartType } from '../../components/kaart/root';
import { IOphalenLijstRecentResult } from '../../../../shared/src/api/v2/relatie/Selectie';
import { IOphalenDienstenResultElement as IInkoopdienst } from '../../../../shared/src/api/v2/dienst/inkoop';
import { IOphalenDienstenResultElement as ITransportdienst } from '../../../../shared/src/api/v2/dienst/transport';
import { IOphalenDienstenResultElement as IPendeldienst } from '../../../../shared/src/api/v2/dienst/pendel';
import { IOphalenDienstenResultElement as IIncassodienst } from '../../../../shared/src/api/v2/dienst/incasso';
import { IOphalenDienstenResultElement as ISmsdienst } from '../../../../shared/src/api/v2/dienst/sms/sms';
import { IOphalenDienstenResultElement as IEmaildienst } from '../../../../shared/src/api/v2/dienst/email/email';
import { IOphalenDienstenResultElement as IServicedienst } from '../../../../shared/src/api/v2/dienst/service';
import { IOphalenDienstenResultElement as IReviewdienst } from '../../../../shared/src/api/v2/dienst/review';
import { IOphalenDienstenResultElement as ISponsordienst } from '../../../../shared/src/api/v2/dienst/sponsoring';
import { IOphalenDienstenResultElement as IMagazijndienst } from '../../../../shared/src/api/v2/dienst/magazijn';
import { IOphalenDienstenResultElement as ICreditmanagementdienst } from '../../../../shared/src/api/v2/dienst/creditmanagement';

import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
} from '../../models/IRemoteData';
import { IOphalenRelatiesResultElementV2 } from '../../../../shared/src/api/v2/relatie';

export interface ILeverancierContext {
  relID: number | null;
  inkoopdienst: IRemoteData<IInkoopdienst | null>;
  onVerversenInkoopdienstAangevraagd: () => void;
  transportdienst: IRemoteData<ITransportdienst | null>;
  onVerversenTransportdienstAangevraagd: () => void;
  incassodienst: IRemoteData<IIncassodienst | null>;
  onVerversenIncassodienstAangevraagd: () => void;
  pendeldienst: IRemoteData<IPendeldienst | null>;
  onVerversenPendeldienstAangevraagd: () => void;
  magazijndienst: IRemoteData<IMagazijndienst | null>;
  onVerversenMagazijndienstAangevraagd: () => void;
  smsdienst: IRemoteData<ISmsdienst | null>;
  onVerversenSmsdienstAangevraagd: () => void;
  emaildienst: IRemoteData<IEmaildienst | null>;
  onVerversenEmaildienstAangevraagd: () => void;
  servicedienst: IRemoteData<IServicedienst | null>;
  onVerversenServicedienstAangevraagd: () => void;
  reviewdienst: IRemoteData<IReviewdienst | null>;
  onVerversenReviewdienstAangevraagd: () => void;
  sponsordienst: IRemoteData<ISponsordienst | null>;
  creditmanagementdienst: IRemoteData<ICreditmanagementdienst | null>;
  onVerversenCreditmanagementdienstAangevraagd: () => void;
  onVerversenSponsordienstAangevraagd: () => void;
}

export const LeverancierContext = React.createContext<ILeverancierContext>(null as any);

interface IRouteParams {
  relID?: string;
}
interface IProps extends RouteComponentProps<IRouteParams> {}

export const defaultEntiteitRoute = '/overzicht';

const Leverancierkaart: React.FC<IProps> = observer((props) => {
  const { gebruikerStore, klantkaartStore } = useContext(RootStoreContext);
  const { navigeerLeverancierNaarRelID } = useContext(RouterContext);
  const relID = useMemo(() => {
    if (props.match.params.relID === undefined) {
      return null;
    }
    const id = Number(props.match.params.relID);
    if (isNaN(id)) {
      return null;
    }
    return id;
  }, [props.match.params.relID]);

  const [recenteReferenties, setRecenteReferenties] = useState<IOphalenLijstRecentResult | null>(
    null,
  );

  useEffect(() => {
    if (relID === null) {
      return;
    }
    // noinspection JSIgnoredPromiseFromCall
    klantkaartStore.ophalenRelatie(relID);

    (async () => {
      await api.v2.relatieZoeken.bijwerkenLijstRecent({
        relID,
        aspGebrID: null,
        hoedanigheid: 'LEVERANCIER',
      });
    })();
  }, [relID]);
  const [gezochtNaarRelatie, setGezochtNaarRelatie] = useState(false);

  const ophalenRecenteReferenties = useCallback(async () => {
    return await api.v2.relatieZoeken.ophalenLijstRecent({
      aspGebrID: gebruikerStore.gebruiker!.AspGebrID,
      filterSchema: {
        filters: [
          {
            naam: 'HOEDANIGHEID_NAAM_ENUMS',
            data: ['LEVERANCIER'],
          },
        ],
      },
      paginatie: {
        index: 0,
        aantal: 25,
      },
    });
  }, []);
  useEffect(() => {
    (async () => {
      const result = await ophalenRecenteReferenties();
      setRecenteReferenties(result);
    })();
  }, []);

  useEffect(() => {
    if (gezochtNaarRelatie) {
      setGezochtNaarRelatie(false);
    }

    if (relID === null) {
      (async () => {
        const lijstRecent = await ophalenRecenteReferenties();

        if (lijstRecent.relaties.length === 0) {
          props.history.push(`/leverancier/1${defaultEntiteitRoute}`);
          return;
        }

        props.history.push(`/leverancier/${lijstRecent.relaties[0].RelID}${defaultEntiteitRoute}`);
      })();
    }
  }, [relID]);

  const [zoekterm, setZoekterm] = useState('');

  const [inkoopdienst, setInkoopdienst] = useState<IRemoteData<IInkoopdienst | null>>(
    createPendingRemoteData(),
  );
  const [incassodienst, setIncassodienst] = useState<IRemoteData<IIncassodienst | null>>(
    createPendingRemoteData(),
  );
  const [transportdienst, setTransportdienst] = useState<IRemoteData<ITransportdienst | null>>(
    createPendingRemoteData(),
  );
  const [bankdienst, setBankdienst] = useState(null);
  const [telefoniedienst, setTelefoniedienst] = useState(null);
  // const [servicedienst, setServicedienst] = useState(null);
  const [emaildienst, setEmaildienst] = useState<IRemoteData<IEmaildienst | null>>(
    createPendingRemoteData(),
  );
  const [betaaldienst, setBetaaldienst] = useState(null);
  const [chatdienst, setChatdienst] = useState(null);
  const [pendeldienst, setPendeldienst] = useState<IRemoteData<IPendeldienst | null>>(
    createPendingRemoteData(),
  );
  const [magazijndienst, setMagazijndienst] = useState<IRemoteData<IMagazijndienst | null>>(
    createPendingRemoteData(),
  );
  const [reviewdienst, setReviewdienst] = useState<IRemoteData<IReviewdienst | null>>(
    createPendingRemoteData(),
  );
  const [smsdienst, setSmsdienst] = useState<IRemoteData<ISmsdienst | null>>(
    createPendingRemoteData(),
  );
  const [servicedienst, setServicedienst] = useState<IRemoteData<IServicedienst | null>>(
    createPendingRemoteData(),
  );
  const [sponsordienst, setSponsordienst] = useState<IRemoteData<ISponsordienst | null>>(
    createPendingRemoteData(),
  );
  const [creditmanagementdienst, setCreditmanagementdienst] = useState<
    IRemoteData<ICreditmanagementdienst | null>
  >(createPendingRemoteData());

  const ophalenInkoopdienst = useCallback(async () => {
    if (relID === null) {
      setInkoopdienst(createPendingRemoteData());
      return;
    }
    const result = await api.v2.dienst.inkoop.ophalenDiensten({
      filterSchema: {
        filters: [
          {
            naam: 'REL_IDS',
            data: [relID],
          },
        ],
      },
    });

    setInkoopdienst(createReadyRemoteData(result.diensten[0] || null));
  }, [relID]);

  const ophalenIncassodienst = useCallback(async () => {
    if (relID === null) {
      setIncassodienst(createPendingRemoteData());
      return;
    }
    const result = await api.v2.dienst.incasso.ophalenDiensten({
      filterSchema: {
        filters: [
          {
            naam: 'REL_IDS',
            data: [relID],
          },
        ],
      },
    });
    setIncassodienst(createReadyRemoteData(result.diensten[0] || null));
  }, [relID]);

  const ophalenTransportdienst = useCallback(async () => {
    if (relID === null) {
      setTransportdienst(createPendingRemoteData());
      return;
    }
    const result = await api.v2.dienst.transport.ophalenDiensten({
      filterSchema: {
        filters: [
          {
            naam: 'REL_IDS',
            data: [relID],
          },
        ],
      },
    });
    setTransportdienst(createReadyRemoteData(result.diensten[0] || null));
  }, [relID]);

  const ophalenServicedienst = useCallback(async () => {
    if (relID === null) {
      setServicedienst(createPendingRemoteData());
      return;
    }
    const result = await api.v2.dienst.service.ophalenDiensten({
      filterSchema: {
        filters: [
          {
            naam: 'REL_IDS',
            data: [relID],
          },
        ],
      },
    });
    setServicedienst(createReadyRemoteData(result.diensten[0] || null));
  }, [relID]);

  // const ophalenBankdienst = useCallback(async () => {
  //   if (relID === null) {
  //     setBankdienst(createPendingRemoteData());
  //     return;
  //   }
  //   const result = await api.v2.dienst.bank.ophalenDiensten({
  //     filterSchema: {
  //       filters: [
  //         {
  //           naam: 'REL_IDS',
  //           data: [relID],
  //         },
  //       ],
  //     },
  //   });
  //   setBankdienst(createReadyRemoteData(result.diensten[0] || null));
  // }, [relID]);

  // const ophalenTelefoniedienst = useCallback(async () => {
  //   if (relID === null) {
  //     setTelefoniedienst(createPendingRemoteData());
  //     return;
  //   }
  //   const result = await api.v2.dienst.telefonie.ophalenDiensten({
  //     filterSchema: {
  //       filters: [
  //         {
  //           naam: 'REL_IDS',
  //           data: [relID],
  //         },
  //       ],
  //     },
  //   });
  //   setTelefoniedienst(createReadyRemoteData(result.diensten[0] || null));
  // }, [relID]);

  // const ophalenServicedienst = useCallback(async () => {
  //   if (relID === null) {
  //     setServicedienst(createPendingRemoteData());
  //     return;
  //   }
  //   const result = await api.v2.dienst.service.ophalenDiensten({
  //     filterSchema: {
  //       filters: [
  //         {
  //           naam: 'REL_IDS',
  //           data: [relID],
  //         },
  //       ],
  //     },
  //   });
  //   setServicedienst(createReadyRemoteData(result.diensten[0] || null));
  // }, [relID]);

  const ophalenEmaildienst = useCallback(async () => {
    if (relID === null) {
      setEmaildienst(createPendingRemoteData());
      return;
    }
    const result = await api.v2.dienst.email.ophalenDiensten({
      filterSchema: {
        filters: [
          {
            naam: 'REL_IDS',
            data: [relID],
          },
        ],
      },
    });
    setEmaildienst(createReadyRemoteData(result.diensten[0] || null));
  }, [relID]);

  // const ophalenBetaaldienst = useCallback(async () => {
  //   if (relID === null) {
  //     setBetaaldienst(createPendingRemoteData());
  //     return;
  //   }
  //   const result = await api.v2.dienst.email.ophalenDiensten({
  //     filterSchema: {
  //       filters: [
  //         {
  //           naam: 'REL_IDS',
  //           data: [relID],
  //         },
  //       ],
  //     },
  //   });
  //   setBetaaldienst(createReadyRemoteData(result.diensten[0] || null));
  // }, [relID]);

  // const ophalenChatdienst = useCallback(async () => {
  //   if (relID === null) {
  //     setChatdienst(createPendingRemoteData());
  //     return;
  //   }
  //   const result = await api.v2.dienst.chat.ophalenDiensten({
  //     filterSchema: {
  //       filters: [
  //         {
  //           naam: 'REL_IDS',
  //           data: [relID],
  //         },
  //       ],
  //     },
  //   });
  //   setChatdienst(createReadyRemoteData(result.diensten[0] || null));
  // }, [relID]);

  const ophalenPendeldienst = useCallback(async () => {
    if (relID === null) {
      setPendeldienst(createPendingRemoteData());
      return;
    }
    const result = await api.v2.dienst.pendel.ophalenDiensten({
      filterSchema: {
        filters: [
          {
            naam: 'REL_IDS',
            data: [relID],
          },
        ],
      },
    });
    setPendeldienst(createReadyRemoteData(result.diensten[0] || null));
  }, [relID]);

  const ophalenMagazijndienst = useCallback(async () => {
    if (relID === null) {
      setMagazijndienst(createPendingRemoteData());
      return;
    }
    const result = await api.v2.dienst.magazijn.ophalenDiensten({
      filterSchema: {
        filters: [
          {
            naam: 'REL_IDS',
            data: [relID],
          },
        ],
      },
    });
    setMagazijndienst(createReadyRemoteData(result.diensten[0] || null));
  }, [relID]);

  const ophalenReviewdienst = useCallback(async () => {
    if (relID === null) {
      setReviewdienst(createPendingRemoteData());
      return;
    }

    const result = await api.v2.dienst.review.ophalenDiensten({
      filterSchema: {
        filters: [
          {
            naam: 'REL_IDS',
            data: [relID],
          },
        ],
      },
    });
    setReviewdienst(createReadyRemoteData(result.diensten[0] || null));
  }, [relID]);

  const ophalenSmsdienst = useCallback(async () => {
    if (relID === null) {
      setSmsdienst(createPendingRemoteData());
      return;
    }
    const result = await api.v2.dienst.sms.ophalenDiensten({
      filterSchema: {
        filters: [
          {
            naam: 'REL_IDS',
            data: [relID],
          },
        ],
      },
    });

    setSmsdienst(createReadyRemoteData(result.diensten[0] || null));
  }, [relID]);

  // const ophalenSmsdienst = useCallback(async () => {
  //   if (relID === null) {
  //     setSmsdienst(createPendingRemoteData());
  //     return;
  //   }
  //   const result = await api.v2.dienst.sms.ophalenDiensten({
  //     filterSchema: {
  //       filters: [
  //         {
  //           naam: 'REL_IDS',
  //           data: [relID],
  //         },
  //       ],
  //     },
  //   });
  //   setSmsdienst(createReadyRemoteData(result.diensten[0] || null));
  // }, [relID]);

  useEffect(() => {
    ophalenInkoopdienst();
  }, [ophalenInkoopdienst]);
  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    ophalenIncassodienst();
  }, [ophalenIncassodienst]);
  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    ophalenTransportdienst();
  }, [ophalenTransportdienst]);
  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    ophalenSmsdienst();
  }, [ophalenSmsdienst]);
  // useEffect(() => {
  //   // noinspection JSIgnoredPromiseFromCall
  //   ophalenBankdienst();
  // }, [ophalenBankdienst]);
  // useEffect(() => {
  //   // noinspection JSIgnoredPromiseFromCall
  //   ophalenTelefoniedienst();
  // }, [ophalenTelefoniedienst]);
  // useEffect(() => {
  //   // noinspection JSIgnoredPromiseFromCall
  //   ophalenServicedienst();
  // }, [ophalenServicedienst]);
  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    ophalenEmaildienst();
  }, [ophalenEmaildienst]);
  // useEffect(() => {
  //   // noinspection JSIgnoredPromiseFromCall
  //   ophalenBetaaldienst();
  // }, [ophalenBetaaldienst]);
  // useEffect(() => {
  //   // noinspection JSIgnoredPromiseFromCall
  //   ophalenChatdienst();
  // }, [ophalenChatdienst]);
  useEffect(() => {
    ophalenPendeldienst();
  }, [ophalenPendeldienst]);
  useEffect(() => {
    ophalenMagazijndienst();
  }, [ophalenMagazijndienst]);
  useEffect(() => {
    ophalenReviewdienst();
  }, [ophalenReviewdienst]);
  // useEffect(() => {
  //   // noinspection JSIgnoredPromiseFromCall
  //   ophalenSmsdienst();
  // }, [ophalenSmsdienst]);
  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    ophalenServicedienst();
  }, [ophalenServicedienst]);

  const ophalenSponsordienst = useCallback(async () => {
    if (relID === null) {
      setSponsordienst(createPendingRemoteData());
      return;
    }

    const result = await api.v2.dienst.sponsoring.ophalenDiensten({
      filterSchema: {
        filters: [
          {
            naam: 'REL_IDS',
            data: [relID],
          },
        ],
      },
    });
    setSponsordienst(createReadyRemoteData(result.diensten[0] || null));
  }, [relID]);

  useEffect(() => {
    ophalenSponsordienst();
  }, [ophalenSponsordienst]);

  const ophalenCreditmanagementdienst = useCallback(async () => {
    if (relID === null) {
      setCreditmanagementdienst(createPendingRemoteData());
      return;
    }

    const result = await api.v2.dienst.creditmanagement.ophalenDiensten({
      filterSchema: {
        filters: [
          {
            naam: 'REL_IDS',
            data: [relID],
          },
        ],
      },
    });
    setCreditmanagementdienst(createReadyRemoteData(result.diensten[0] || null));
  }, [relID]);

  useEffect(() => {
    ophalenCreditmanagementdienst();
  }, [ophalenCreditmanagementdienst]);

  const context = useMemo<ILeverancierContext>(() => {
    return {
      relID,
      inkoopdienst,
      onVerversenInkoopdienstAangevraagd: () => ophalenInkoopdienst(),
      incassodienst,
      onVerversenIncassodienstAangevraagd: () => ophalenIncassodienst(),
      transportdienst,
      onVerversenTransportdienstAangevraagd: () => ophalenTransportdienst(),
      pendeldienst,
      onVerversenPendeldienstAangevraagd: () => ophalenPendeldienst(),
      magazijndienst,
      onVerversenMagazijndienstAangevraagd: () => ophalenMagazijndienst(),
      smsdienst,
      onVerversenSmsdienstAangevraagd: () => ophalenSmsdienst(),
      emaildienst,
      onVerversenEmaildienstAangevraagd: () => ophalenEmaildienst(),
      servicedienst,
      onVerversenServicedienstAangevraagd: () => ophalenServicedienst(),
      reviewdienst,
      onVerversenReviewdienstAangevraagd: () => ophalenReviewdienst(),
      sponsordienst,
      onVerversenSponsordienstAangevraagd: () => ophalenSponsordienst(),
      creditmanagementdienst,
      onVerversenCreditmanagementdienstAangevraagd: () => ophalenCreditmanagementdienst(),
    };
  }, [
    relID,
    inkoopdienst,
    incassodienst,
    transportdienst,
    pendeldienst,
    magazijndienst,
    smsdienst,
    emaildienst,
    servicedienst,
    reviewdienst,
    sponsordienst,
    creditmanagementdienst,
    ophalenInkoopdienst,
    ophalenIncassodienst,
    ophalenTransportdienst,
    ophalenPendeldienst,
    ophalenMagazijndienst,
    ophalenSmsdienst,
    ophalenEmaildienst,
    ophalenServicedienst,
    ophalenReviewdienst,
    ophalenSponsordienst,
    ophalenCreditmanagementdienst,
  ]);

  const relatie = useMemo<IRemoteData<IOphalenRelatiesResultElementV2>>(() => {
    if (klantkaartStore.relatie === null || klantkaartStore.relatie.RelID !== relID) {
      return createPendingRemoteData();
    }
    return createReadyRemoteData(klantkaartStore.relatie);
  }, [relID, klantkaartStore.relatie]);

  const verversRelatie = useCallback(async () => {
    await klantkaartStore.verversRelatie();
  }, [klantkaartStore.verversRelatie]);

  return (
    <LeverancierContext.Provider value={context}>
      <Root>
        {klantkaartStore.relatie! === null ||
        klantkaartStore.relatie!.RelID === -1 ||
        relID === null ? (
          <div className="flex-fill d-flex align-items-center justify-content-center">
            <LoadingSpinner />
          </div>
        ) : (
          <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
            <div className="d-flex flex-fill">
              <div className="d-flex flex-fill">
                {/*<Control*/}
                {/*  zoekterm={zoekterm}*/}
                {/*  onZoektermChange={setZoekterm}*/}
                {/*  recenteReferenties={recenteReferenties}*/}
                {/*  navigeerNaarVorigeRelatie={async () => {*/}
                {/*    const recenteReferenties = await ophalenRecenteReferenties();*/}
                {/*    const referentie = recenteReferenties![1];*/}
                {/*    await navigeerLeverancierNaarRelID(referentie.RelID);*/}
                {/*  }}*/}
                {/*  navigeerNaarRelID={navigeerLeverancierNaarRelID}*/}
                {/*  type={EKaartType.Leverancier}*/}
                {/*  relID={relID}*/}
                {/*  hoedanigheid="LEVERANCIER"*/}
                {/*  onVerversenAangevraagd={() => klantkaartStore.verversRelatie()}*/}
                {/*/>*/}
                <div className="d-flex flex-fill">
                  <KlantInformatieSectie
                    relID={relID}
                    relatie={klantkaartStore.relatie!}
                    type={EKaartType.Leverancier}
                    onVerversenAangevraagd={() => klantkaartStore.verversRelatie()}
                    onZoektermChange={setZoekterm}
                    recenteReferenties={recenteReferenties?.relaties ?? null}
                    navigeerNaarRelID={navigeerLeverancierNaarRelID}
                    zoekterm={zoekterm}
                    ophalenRecenteReferenties={ophalenRecenteReferenties}
                    hoedanigheid={'LEVERANCIER'}
                  />
                  <Navigatie />
                  <Entiteiten relID={relID} relatie={relatie} verversRelatie={verversRelatie} />
                </div>
              </div>
            </div>
          </div>
        )}
      </Root>
    </LeverancierContext.Provider>
  );
});

export default Leverancierkaart;
