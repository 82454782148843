import React from 'react';
import styled from 'styled-components';
import { RapportageType } from '../types';
import OpenstaandeDebiteuren from './details/OpenstaandeDebiteuren';
import OpenstaandeCrediteuren from './details/OpenstaandeCrediteuren';
import GegevensTenBehoeveVanJaarwerk from './details/GegevensTenBehoeveVanJaarwerk';
import WinstEnVerliesRekening from './details/WinstEnVerliesRekening';
import { EMaand } from '../../../../components/formulier/MaandSelectie';
import LijstActivaregister from './details/LijstActivaregister';
import LijstProductenUitBedrijf from './details/LijstProductenUitBedrijf';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export interface IRapportageDetailProps {
  peildatum: Date;
  jaar: number;
  maandVem: EMaand;
  maandTem: EMaand;
}

const detailComponentMap: Record<RapportageType, React.ComponentType<IRapportageDetailProps>> = {
  OPENSTAANDE_DEBITEUREN: OpenstaandeDebiteuren,
  OPENSTAANDE_CREDITEUREN: OpenstaandeCrediteuren,
  GEGEVENS_TEN_BEHOEVE_VAN_JAARWERK: GegevensTenBehoeveVanJaarwerk,
  WINST_EN_VERLIES_REKENING: WinstEnVerliesRekening,
  LIJST_ACTIVAREGISTER: LijstActivaregister,
  LIJST_PRODUCTEN_UIT_BEDRIJF: LijstProductenUitBedrijf,
};

interface IProps {
  rapportageType: RapportageType;
  peildatum: Date;
  jaar: number;
  maandVem: EMaand;
  maandTem: EMaand;
}

const Rechts = (props: IProps) => {
  const DetailComp = detailComponentMap[props.rapportageType];
  return (
    <Root>
      <DetailComp
        peildatum={props.peildatum}
        jaar={props.jaar}
        maandVem={props.maandVem}
        maandTem={props.maandTem}
      />
    </Root>
  );
};

export default Rechts;
