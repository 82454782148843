import React, { useCallback, useEffect, useMemo, useState } from 'react';
import IDialoogProps from '../../../../../core/IDialoogProps';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import LoadingSpinner from '../../../../../components/Gedeeld/LoadingSpinner';
import api from '../../../../../api';
import nameOf from '../../../../../core/nameOf';
import VinkVeld from '../../../../../components/formulier/VinkVeld';
import Dialoog from '../../../../../components/dialogen/Dialoog';
import { IOphalenRegisterItemsResultElement } from '../../../../../../../shared/src/api/v2/boekhouding/activaregister/register';

interface IProps extends IDialoogProps<null> {
  id: number;
}

export interface IFormikValues {
  notities: string;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  notities: 'Notities',
};

const WijzigDialoog: React.FC<IProps> = (props) => {
  const { onAnnuleren, onSuccess, open, dialoogIndex } = props;

  const [registeritem, setRegisteritem] = useState<IOphalenRegisterItemsResultElement | null>(null);

  const ophalenRegisteritem = useCallback(async () => {
    const result = await api.v2.boekhouding.activaregister.register.ophalenRegisterItems({
      filterSchema: { filters: [{ naam: 'IDS', data: [props.id] }] },
    });

    setRegisteritem(result.registerItems[0]);
  }, [props.id]);

  useEffect(() => {
    ophalenRegisteritem();
  }, [ophalenRegisteritem]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      const params = {
        id: props.id,
        notities: values.notities !== '' ? values.notities : null,
      };

      // const checkresult = await api.v2.boekhouding.activaregister.register.wijzigenRegisterItem(params)

      await api.v2.boekhouding.activaregister.register.wijzigenRegisterItem(params);

      onSuccess(null);
    },
    [],
  );

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (registeritem === null) {
      return null;
    }

    return {
      notities: registeritem.Notities !== null ? registeritem.Notities : '',
    };
  }, [registeritem]);

  return (
    <Dialoog index={dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Wijzigen Registeritem</ModalTitle>
      </ModalHeader>
      <ModalBody>
        {registeritem === null || initialValues === null ? (
          <div className="d-flex flex-fill align-items-center justify-content-center">
            <LoadingSpinner />
          </div>
        ) : (
          <Formik<IFormikValues>
            initialValues={initialValues}
            onSubmit={handleSubmit}
            render={(formikProps: FormikProps<IFormikValues | null>) => {
              const { submitForm, isSubmitting, isValid, values } = formikProps;
              return (
                <>
                  <div className="row">
                    <div className="col-12 mt-2">
                      <label>{veldnamen.notities}</label>
                      <Field
                        name={nameOf<IFormikValues>('notities')}
                        render={(fieldProps: FieldProps<IFormikValues>) => {
                          const { field, form } = fieldProps;
                          return <textarea {...field} className="form-control" rows={3} />;
                        }}
                      />
                    </div>
                  </div>

                  <ModalFooter className="d-flex justify-content-start mt-3">
                    <button
                      className="btn btn-primary"
                      onClick={submitForm}
                      style={{ width: 100 }}
                      disabled={false}
                    >
                      Ok
                    </button>
                    <button
                      className="btn btn-secondary"
                      style={{ width: 100 }}
                      onClick={onAnnuleren}
                    >
                      Annuleren
                    </button>
                  </ModalFooter>
                </>
              );
            }}
          />
        )}
      </ModalBody>
    </Dialoog>
  );
};

export default WijzigDialoog;
