import React, { HTMLProps, useEffect, useMemo, useState } from 'react';
import { setInterval } from 'timers';
import { addSeconds } from 'date-fns';

// Format tijdverschil als uren, minuten en seconden.
// Voorbeeld:
//  1 uur, 2 minuten en 3 seconden
// 50 minuten en 6 seconden
// 1 minuut en 2 seconden
// 40 seconden
const formatTijdVerschil = (tijdA: Date, tijdB: Date) => {
  const verschil = Math.abs(tijdA.getTime() - tijdB.getTime()) / 1000;
  const uren = Math.floor(verschil / 3600);
  const minuten = Math.floor((verschil % 3600) / 60);
  const seconden = Math.floor(verschil % 60);

  const urenStr = uren > 0 ? `${uren} ${uren === 1 ? 'uur' : 'uren'}` : '';
  const minutenStr = minuten > 0 ? `${minuten} ${minuten === 1 ? 'minuut' : 'minuten'}` : '';
  const secondenStr = seconden > 0 ? `${seconden} ${seconden === 1 ? 'seconde' : 'seconden'}` : '';

  const onderdelen = [urenStr, minutenStr, secondenStr].filter((x) => x.length > 0);
  if (onderdelen.length === 0) {
    return '0 seconden';
  }
  if (onderdelen.length === 1) {
    return onderdelen[0];
  }
  return `${onderdelen.slice(0, -1).join(', ')} en ${onderdelen.slice(-1)[0]}`;
};

interface IProps {
  datum: Date;
  comp?: React.ComponentType<HTMLProps<HTMLSpanElement>>;
  onGeenTijdVerschil?: () => void;
}

const TijdVerschilMetNu = (props: IProps) => {
  const datum = useMemo(() => {
    // props.datum copy
    const copy = addSeconds(props.datum, 0);
    copy.setMilliseconds(0);

    return copy;
  }, [props.datum]);

  const nu = new Date();
  nu.setMilliseconds(0);

  const Comp = props.comp ?? 'span';
  const tekst = formatTijdVerschil(nu, datum);

  useEffect(() => {
    if (tekst === '0 seconden') {
      props.onGeenTijdVerschil?.();
    }
  }, [tekst, props.onGeenTijdVerschil]);

  const [_, setForceState] = useState(false);
  useEffect(() => {
    const interval = setInterval(() => setForceState((x) => !x), 1000);
    return () => clearInterval(interval);
  }, []);

  return <Comp>{tekst}</Comp>;
};

export default TijdVerschilMetNu;
