import {
  DataTypeProvider,
  IntegratedSorting,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { IOphalenPersonenResultElementV2 } from '../../../../../../../../shared/src/api/v2/persoon/persoon';
import { ISponsorCommissie } from '../../../../../../../../shared/src/api/v2/sponsoringv2/event';
import api from '../../../../../../api';
import { Kleur } from '../../../../../../bedrijfslogica/constanten';
import LoadingSpinner from '../../../../../../components/Gedeeld/LoadingSpinner';
import { IconLink, IconVerwijderen } from '../../../../../../components/Icons';
import MenuLayout from '../../../../../../components/MenuLayout';
import nameOf from '../../../../../../core/nameOf';
import {
  DXTableCheckboxComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../../../helpers/dxTableGrid';
import { EResultType } from '../../../../../../stores/CheckStore';
import { RootStoreContext } from '../../../../../../stores/RootStore';
import WijzigenDialoog from './WijzigenDialoog';
import PersoonVisualisatie from '../../../../../../components/personalia/PersoonVisualisatie';

interface IProps {
  id: number | null;
  relID: number | null;
}

interface IWijzigenDialoogState {
  id: number | null;
  relID: number | null;
}

const Item: React.FC<IProps> = (props) => {
  const { checkStore } = useContext(RootStoreContext);

  const [rijen, setRijen] = useState<ISponsorCommissie[] | null>(null);
  const [wijzigenDialoogState, setWijzigenDialoogState] = useState<IWijzigenDialoogState | null>(
    null,
  );
  const [selectieState, setSelectieState] = useState<number[]>([]);

  const ophalenRijen = async () => {
    const results = await api.v2.sponsoringv2.commissie.ophalenCommissies({
      filterSchema: {
        filters: [{ naam: 'DIENST_ID', data: props.id }],
      },
    });

    console.log(results);
    setRijen(results);
  };

  useEffect(() => {
    ophalenRijen();
  }, []);

  const handleWijzigen = useCallback(async () => {
    setWijzigenDialoogState({ id: props.id, relID: props.relID });
  }, []);

  const handleVerwijderen = async () => {
    console.log('verwijderen...');
    const params = { ids: selectieState };
    if (
      (
        await checkStore.bevestigen({
          inhoud: <span>Geselecteerde items verwijderen?</span>,
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    console.log('doen...');
    await api.v2.sponsoringv2.commissie.verwijderenCommissies(params);
    console.log('verwijderd');
    setSelectieState([]);

    await ophalenRijen();
  };

  const keyExtractor = useCallback((row: ISponsorCommissie) => (row.ID == null ? 0 : row.ID), [
    rijen,
  ]);

  const kolommen = useMemo<TypedColumn<ISponsorCommissie>[]>(
    () => [
      {
        name: 'Naam',
        title: 'Naam',
      },
      {
        name: 'Jaar',
        title: 'Jaar',
      },
      {
        name: 'Contactpersoon_PersID',
        title: 'Contactpersoon',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<ISponsorCommissie>[]>(
    () => [
      {
        columnName: 'Naam',
        width: 150,
      },
      {
        columnName: 'Jaar',
        width: 120,
      },
      {
        columnName: 'Contactpersoon_PersID',
        width: 120,
      },
    ],
    [],
  );

  return (
    <>
      <MenuLayout
        menu={
          <div className="d-flex align-items-center">
            <span style={{ marginRight: 10 }}>Commissies</span>
            <button
              className="btn btn-sm btn-light d-flex align-items-center"
              style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
              disabled={false}
              onClick={() => handleWijzigen()}
            >
              <IconLink style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
              <span className="ml-2">Aanmaken</span>
            </button>
            <button
              className="btn btn-sm btn-light d-flex align-items-center ml-3"
              style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
              disabled={selectieState.length == 0}
              onClick={() => handleVerwijderen()}
            >
              <IconVerwijderen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
              <span className="ml-2">Verwijderen</span>
            </button>
          </div>
        }
        body={
          <div className="d-flex flex-fill flex-column" style={{ width: '100%', height: '100%' }}>
            {rijen === null ? (
              <LoadingSpinner />
            ) : (
              <GridStyleWrapper height="200px">
                <Grid getRowId={keyExtractor} rows={rijen} columns={kolommen}>
                  <DataTypeProvider
                    for={[nameOf<ISponsorCommissie>('Naam')]}
                    formatterComponent={(formatterProps) => <span>{formatterProps.value}</span>}
                  />
                  <DataTypeProvider
                    for={[nameOf<ISponsorCommissie>('Jaar')]}
                    formatterComponent={(formatterProps) => <span>{formatterProps.value}</span>}
                  />
                  <DataTypeProvider
                    for={[nameOf<ISponsorCommissie>('Contactpersoon_PersID')]}
                    formatterComponent={(formatterProps) => (
                      <span>{<PersoonVisualisatie persID={formatterProps.value} />}</span>
                    )}
                  />
                  <SortingState defaultSorting={[]} />
                  <IntegratedSorting />

                  <VirtualTable messages={{ noData: 'Geen items aanwezig' }} />
                  <TableColumnResizing defaultColumnWidths={kolomBreedtes} />

                  <TableHeaderRow />
                  <SelectionState
                    selection={selectieState}
                    onSelectionChange={(x) => setSelectieState(x as number[])}
                  />
                  <TableSelection cellComponent={DXTableCheckboxComponent} />
                </Grid>
              </GridStyleWrapper>
            )}
          </div>
        }
      />
      {wijzigenDialoogState !== null && (
        <WijzigenDialoog
          open
          id={wijzigenDialoogState.id}
          relID={wijzigenDialoogState.relID}
          onSuccess={async () => {
            setWijzigenDialoogState(null);
            await ophalenRijen();
          }}
          onAnnuleren={() => setWijzigenDialoogState(null)}
        />
      )}
    </>
  );
};

export default Item;
