import React, { useCallback, useMemo } from 'react';
import MultiComboboxV2MetZoeken, {
  IMultiComboboxV2MetZoekenOverlayState,
} from '../../../../../../components/formulier/MultiComboboxV2/MetZoeken';
import {
  IRepresentatieProps,
  Provider,
} from '../../../../../../components/formulier/MultiComboboxV2';
import api from '../../../../../../api';
import { IFilterSchemaFilter } from '../../../../../../../../shared/src/models/filter';
import { ASPKolom, EAspKolomBreedteType } from '../../../../../../components/tabel/ASPTabel/types';
import { ITekstPrecisieData } from '../../../../../../../../shared/src/api/sql';
import { ISjabloon } from '../../../../../../../../shared/src/api/v2/Sjabloon/sjabloon';

type Kolom = 'naam' | 'omschrijving';

interface IProps {
  sjabID: number | null;
  onSjabIDChange: (sjabID: number | null) => void;
  cache?: { [sjabID: number]: ISjabloon };
  comKanID: number;
}

const SjabloonSelectie = (props: IProps) => {
  const provider = useMemo<Provider<Kolom, ISjabloon, IMultiComboboxV2MetZoekenOverlayState>>(
    () => async (params) => {
      const filters: IFilterSchemaFilter[] = [
        {
          naam: 'IS_CONCEPT',
          data: false,
        },
        {
          naam: 'IS_HOOFD_SJABLOON',
          data: true,
        },
        {
          naam: 'IS_SYSTEEM_SJABLOON',
          data: false,
        },
        {
          naam: 'HEEFT_COM_KAN_IDS',
          data: [props.comKanID],
        },
      ];

      const zoekterm = params.overlayContainerState.zoekterm.trim();
      if (zoekterm.length > 0) {
        filters.push({
          naam: 'NAAM_UITGEBREID',
          data: {
            precisie: 'ONGEVEER',
            waarde: zoekterm,
          } as ITekstPrecisieData,
        });
      }

      const result = await api.v2.sjabloon.ophalenSjablonen({
        paginatie: params.paginatie,
        filterSchema: {
          filters,
        },
      });

      const items = result.sjablonen.reduce(
        (acc, item, i) => {
          acc[params.paginatie.index + i] = item;
          return acc;
        },
        { ...params.huidigeBron },
      );

      if (props.cache !== undefined) {
        for (const sjabloon of result.sjablonen) {
          props.cache[sjabloon.ID] = sjabloon;
        }
      }

      return {
        items,
        totaalAantal: result.totaalAantal,
      };
    },
    [props.cache, props.comKanID],
  );
  const enkeleProvider = useMemo(
    () => async (sjabID: number) => {
      const result = await api.v2.sjabloon.ophalenSjablonen({
        filterSchema: {
          filters: [
            {
              naam: 'IDS',
              data: [sjabID],
            },
          ],
        },
      });
      const sjabloon = result.sjablonen[0];

      if (props.cache !== undefined) {
        props.cache[sjabID] = sjabloon;
      }

      return sjabloon;
    },
    [props.cache],
  );
  const keyExtractor = useCallback((item: ISjabloon) => item.ID, []);

  const kolommen = useMemo<ASPKolom<Kolom, ISjabloon>[]>(
    () => [
      {
        key: 'naam',
        label: 'Naam',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 400,
        renderer: (item) => item.Naam,
      },
      {
        key: 'omschrijving',
        label: 'Omschrijving',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 500,
        renderer: (item) => item.Omschrijving,
      },
    ],
    [],
  );

  return (
    <MultiComboboxV2MetZoeken
      provider={provider}
      enkeleProvider={enkeleProvider}
      keyExtractor={keyExtractor}
      waarde={props.sjabID}
      onChange={props.onSjabIDChange}
      representatieComponent={Representatie}
      kolommen={kolommen}
    />
  );
};

const Representatie = (props: IRepresentatieProps<ISjabloon>) => {
  return <span>{props.entiteit.Naam}</span>;
};

export default SjabloonSelectie;
