import React, { useCallback, useEffect, useMemo, useState } from 'react';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import {
  DXTableCheckboxComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import {
  Grid,
  Table,
  VirtualTable,
  TableColumnResizing,
  TableHeaderRow,
  TableSelection,
} from '@devexpress/dx-react-grid-bootstrap4';
import { DataTypeProvider, SelectionState, TableRowDetail } from '@devexpress/dx-react-grid';
import { format } from 'date-fns';
import * as _ from 'lodash';
import FormatteerBedrag from '../../../../components/MutatieBedrag';
import { IDebiteur, IFactuur } from '..';
import MenuLayout from '../../../../components/MenuLayout';
import TabelInspringBlok from '../../../../components/layout/TabelInspringBlok';
import { IOphalenFacturenBasisResultElement } from '../../../../../../shared/src/api/v2/factuur';
import nameof from '../../../../core/nameOf';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import { EFunctioneleIcon, functioneleIconMap, IconInformatie } from '../../../../components/Icons';
import FactuurinfoDialoog from '../../../../components/factuur/FactuurinfoDialoog';

// interface IRow {
//   factID: number;
//   factuurnummer: string | null;
//   factuurdatum: Date;
//   vervaldatum: Date;
//   bedrag: number;
//   aanmaningen: IAanmaning[];
// }

interface IProps extends TableRowDetail.ContentProps {}

export interface IRegel extends IOphalenFacturenBasisResultElement {}

const IconProductInfo = functioneleIconMap[EFunctioneleIcon.ProductInfo];

export interface IFactuurinfoDialoogState {
  factID: number;
}

const FacturenDetailComp: React.FC<IProps> = (props) => {
  const debiteur: IDebiteur = props.row;

  const facturen: IFactuur[] = debiteur.facturen;

  const [
    factuurinfoDialoogState,
    setFactuurinfoDialoogState,
  ] = useState<IFactuurinfoDialoogState | null>(null);

  // const dossier = useMemo(() => {
  //   return props.row as IOphalenDebiteurenResultElement;
  // }, [props.row]);

  // useEffect(() => {
  //   (async () => {
  //     const result = await api.v2.relatie.account.ophalenLogging({
  //       accID,
  //     });
  //     setLogging(result);
  //   })();
  // }, [accID]);

  // const rows = useMemo<IRow[]>(() => {
  //   return dossier.facturen.map(
  //     (x): IRow => ({
  //       factID: x.FactID,
  //       factuurnummer: x.Factuurnummer,
  //       factuurdatum: new Date(x.Factuurdatum),
  //       vervaldatum: new Date(x.Vervaldatum),
  //       bedrag: x.Bedrag,
  //       aanmaningen: x.aanmaningen,
  //     }),
  //   );
  // }, [dossier.facturen]);

  const keyExtractor = useCallback((row: IFactuur) => row.FactID, []);

  const kolommen = useMemo<TypedColumn<IFactuur>[]>(() => {
    return [
      {
        name: 'Factuurnummer',
        title: 'Fact.nr',
      },
      {
        name: 'Factuurdatum',
        title: 'Fact.datum',
      },
      {
        name: 'Vervaldatum',
        title: 'Vervaldatum',
      },
      {
        name: 'Bedrag',
        title: 'Fact.bedrag',
      },
      {
        name: 'Openstaand',
        title: 'Openstaand',
      },
      {
        name: '__aantalAanmaningen' as any,
        title: '# Aanm.',
      },
      // {
      //   name: '__laatsteVervaldatum' as any,
      //   title: 'Laatste vervaldatum',
      // },
      {
        name: '__factuurInfo' as any,
        title: ' ',
      },
    ];
  }, []);

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IFactuur>[]>(() => {
    return [
      {
        columnName: 'Factuurnummer',
        width: 100,
      },
      {
        columnName: '__factuurInfo' as any,
        width: 75,
      },
      {
        columnName: 'Factuurdatum',
        width: 100,
      },
      {
        columnName: 'Vervaldatum',
        width: 100,
      },
      {
        columnName: 'Bedrag',
        width: 100,
      },
      {
        columnName: 'Openstaand',
        width: 100,
      },
      {
        columnName: '__aantalAanmaningen' as any,
        width: 75,
      },
      // {
      //   columnName: '__laatsteVervaldatum' as any,
      //   width: 150,
      // },
    ];
  }, []);

  return (
    <>
      {facturen === null ? (
        <LoadingSpinner />
      ) : (
        <MenuLayout
          // menu={<></>}
          body={
            <>
              <div className="d-flex">
                <TabelInspringBlok />
                <GridStyleWrapper maxHeight={500} rowAmount={facturen.length}>
                  <Grid getRowId={keyExtractor} rows={facturen} columns={kolommen}>
                    <DataTypeProvider
                      for={[nameof<IFactuur>('Factuurdatum')]}
                      formatterComponent={(formatterProps) => {
                        const row: IRegel = formatterProps.row;

                        return <span>{format(new Date(row.Factuurdatum), 'dd-MM-yyyy')}</span>;
                      }}
                    />

                    <DataTypeProvider
                      for={[nameof<IFactuur>('Vervaldatum')]}
                      formatterComponent={(formatterProps) => {
                        const row: IRegel = formatterProps.row;

                        return <span>{format(new Date(row.Vervaldatum), 'dd-MM-yyyy')}</span>;
                      }}
                    />

                    <DataTypeProvider
                      for={[nameof<IFactuur>('Bedrag'), nameof<IFactuur>('Openstaand')]}
                      formatterComponent={(props) => (
                        <span>
                          <FormatteerBedrag bedrag={props.value} />
                        </span>
                      )}
                    />

                    <DataTypeProvider
                      for={['__aantalAanmaningen']}
                      formatterComponent={(formatterProps) => {
                        const rij: IFactuur = formatterProps.row;

                        return <span>{rij.aanmaningen.length}</span>;
                      }}
                    />

                    <DataTypeProvider
                      for={['__laatsteVervaldatum']}
                      formatterComponent={(formatterProps) => {
                        const rij: IFactuur = formatterProps.row;
                        const datum =
                          rij.aanmaningen.length !== 0
                            ? _.max(rij.aanmaningen.map((x) => x.Vervaldatum))
                            : null;
                        return (
                          <span>{datum !== null ? format(new Date(datum), 'dd-MM-yyyy') : ''}</span>
                        );
                      }}
                    />

                    <DataTypeProvider
                      for={['__factuurInfo']}
                      formatterComponent={(formatterProps) => {
                        const rij: IFactuur = formatterProps.row;
                        return (
                          <a
                            href="#"
                            className="ml-1"
                            style={{ color: Kleur.LichtBlauw, position: 'relative', bottom: 2 }}
                            onClick={() => {
                              setFactuurinfoDialoogState({ factID: rij.FactID });
                            }}
                          >
                            <IconProductInfo style={{ width: 15, height: 15, fill: Kleur.Blauw }} />
                          </a>
                        );
                      }}
                    />

                    <Table />
                    <TableColumnResizing />
                    <TableHeaderRow />
                    <TableColumnResizing defaultColumnWidths={kolomBreedtes as any} />
                  </Grid>
                </GridStyleWrapper>
              </div>
            </>
          }
        />
      )}
      {factuurinfoDialoogState !== null && (
        <FactuurinfoDialoog
          open
          factID={factuurinfoDialoogState.factID}
          onSuccess={() => {
            setFactuurinfoDialoogState(null);
          }}
          onAnnuleren={() => setFactuurinfoDialoogState(null)}
        ></FactuurinfoDialoog>
      )}
    </>
  );
};

export default FacturenDetailComp;
