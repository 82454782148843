import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import api from '../../../api';
import {
  DataTypeProvider,
  EditingState,
  IntegratedSorting,
  RowDetailState,
  SortingState,
  VirtualTable as VirtualTableBase,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  TableRowDetail,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import _ from 'lodash';
import { RouteComponentProps } from 'react-router-dom';
import { IOphalenMaterialenResultElement } from '../../../../../shared/src/api/v2/sponsoring/materiaal';
import { Kleur } from '../../../bedrijfslogica/constanten';
import LoadingSpinner from '../../../components/Gedeeld/LoadingSpinner';
import { IconToevoegen } from '../../../components/Icons';
import MenuLayout from '../../../components/MenuLayout';
import nameof from '../../../core/nameOf';
import {
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../helpers/dxTableGrid';
import WijzigenDialoog from './WijzigenDialoog';
import { EResultType } from '../../../stores/CheckStore';
import { RootStoreContext } from '../../../stores/RootStore';
import Bijlage from '../../Service/Werkbonnen/Bijlage';

interface IProps extends RouteComponentProps<{}> {}

export interface IRegel extends IOphalenMaterialenResultElement {}

interface IWijzigenDialoogState {
  id: number;
}

const Materiaal: React.FC<IProps> = (props) => {
  const { checkStore, klantkaartStore } = useContext(RootStoreContext);

  const [materialen, setMaterialen] = useState<IRegel[] | null>(null);
  const [wijzigenDialoogState, setWijzigenDialoogState] = useState<IWijzigenDialoogState | null>(
    null,
  );

  const ophalenMaterialen = useCallback(async () => {
    const materialenResult = await api.v2.sponsoring.materiaal.ophalenMaterialen({
      filterSchema: {
        filters: [].filter((x) => x !== null),
      },
    });

    const materialenGesorteerd = _.orderBy(materialenResult.materialen, ['Naam'], ['asc']);

    setMaterialen(materialenGesorteerd);
  }, []);

  useEffect(() => {
    ophalenMaterialen();
  }, [ophalenMaterialen]);

  const keyExtractor = useCallback((row: IRegel) => row.ID, []);
  const kolommen = useMemo<TypedColumn<IRegel>[]>(
    () => [
      {
        name: 'Naam',
        title: 'Naam',
      },
      {
        name: 'Omschrijving',
        title: 'Omschrijving',
      },
      {
        name: '__bijlage' as any,
        title: 'Bijlage',
      },
      {
        name: 'Actief',
        title: 'Actief',
      },
    ],
    [],
  );
  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRegel>[]>(
    () => [
      {
        columnName: 'Naam',
        width: 150,
      },
      {
        columnName: 'Omschrijving',
        width: 375,
      },
      {
        columnName: '__bijlage' as any,
        width: 150,
      },
      {
        columnName: 'Actief',
        width: 90,
      },
    ],
    [],
  );

  const kolomExtensies: VirtualTableBase.ColumnExtension[] = useMemo(() => {
    return [
      {
        columnName: `__bijlage`,
        align: 'right',
      },
    ];
  }, []);

  return (
    <>
      <MenuLayout
        menu={
          <>
            <div className="d-flex">
              <button
                className="btn btn-sm btn-light d-flex align-items-center"
                style={{
                  border: `1px solid ${Kleur.LichtGrijs}`,
                }}
                onClick={() => {
                  // setToevoegenContractDialoogState({});
                }}
              >
                <IconToevoegen
                  style={{
                    width: 16,
                    height: 16,
                    fill: Kleur.Grijs,
                  }}
                />
                <span className="ml-2">Nieuw contract</span>
              </button>
            </div>
          </>
        }
        body={
          <div>
            {materialen === null ? (
              <LoadingSpinner />
            ) : (
              <>
                <GridStyleWrapper height="calc(100vh - 88px)">
                  <Grid rows={materialen} columns={kolommen} getRowId={keyExtractor}>
                    <DataTypeProvider
                      for={[nameof('Naam')]}
                      formatterComponent={(formatterProps) => {
                        const rij: IRegel = formatterProps.row;
                        return <span>{rij.Naam}</span>;
                      }}
                    />

                    <DataTypeProvider
                      for={[nameof('Actief')]}
                      formatterComponent={(formatterProps) => (
                        <span>{formatterProps.value ? 'Ja' : 'Nee'}</span>
                      )}
                    />

                    <DataTypeProvider
                      for={['__bijlage']}
                      formatterComponent={(formatterProps) => {
                        const x: IRegel = formatterProps.row;
                        return (
                          <Bijlage
                            bestand={x.bestand}
                            onBestIDChange={async (bestID) => {
                              const params = {
                                id: x.ID,
                                naam: x.Naam,
                                omschrijving: x.Omschrijving,
                                actief: x.Actief,
                                bestandID: bestID,
                              };
                              await api.v2.sponsoring.materiaal.wijzigenMateriaal(params);
                              await ophalenMaterialen();
                            }}
                          />
                        );
                      }}
                    />

                    <SortingState defaultSorting={[]} />
                    <IntegratedSorting />

                    <VirtualTable />
                    <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
                    <TableHeaderRow showSortingControls />

                    <EditingState
                      onCommitChanges={async (changes) => {
                        if (changes.deleted === undefined) {
                          return;
                        }
                        const deleted = changes.deleted;
                        const id = deleted[deleted.length - 1] as number;

                        if (
                          (
                            await checkStore.bevestigen({
                              inhoud: (
                                <span>
                                  Wil je dit materiaal verwijderen?
                                  <br />
                                  Een eventueel gekoppeld bestand wordt niet verwijderd.
                                </span>
                              ),
                            })
                          ).type === EResultType.Annuleren
                        ) {
                          return;
                        }

                        await api.v2.sponsoring.materiaal.verwijderenMaterialen({
                          ids: [id],
                        });

                        ophalenMaterialen();
                      }}
                      onEditingRowIdsChange={(rowIds) => {
                        const id = rowIds[rowIds.length - 1] as number;
                        setWijzigenDialoogState({ id });
                      }}
                    />

                    <TableEditColumn
                      width={65}
                      showEditCommand
                      showDeleteCommand
                      cellComponent={DXTableEditColumnCellComponent}
                      commandComponent={DXTableEditColumnCommandComponent}
                    />

                    {/* <RowDetailState defaultExpandedRowIds={[]} /> */}

                    {/* <TableRowDetail
                      contentComponent={DetailComp}
                      toggleCellComponent={DXTableToggleCellComponent}
                    /> */}

                    {/* <TableSelection cellComponent={DXTableCheckboxComponent} /> */}
                  </Grid>
                </GridStyleWrapper>
              </>
            )}
          </div>
        }
      />
      {wijzigenDialoogState !== null && (
        <WijzigenDialoog
          id={wijzigenDialoogState.id}
          open={true}
          onSuccess={() => {
            ophalenMaterialen();
            setWijzigenDialoogState(null);
          }}
          onAnnuleren={() => {
            setWijzigenDialoogState(null);
          }}
        />
      )}
    </>
  );
};

export default Materiaal;
