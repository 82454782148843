import { makeObservable, observable } from 'mobx';
import IRemoteData, { createPendingRemoteData, createReadyRemoteData } from '../models/IRemoteData';
import { IOphalenFacturenResultElement } from '../../../shared/src/api/v2/inkoopfactuur';
import * as _ from 'lodash';
import api from '../api';

class InkoopfactuurStore {
  public facturen: IRemoteData<
    Record<number, IOphalenFacturenResultElement>
  > = createPendingRemoteData();
  private ophalenFacturenQueue: number[] = [];
  private ophalenFacturenQueueDebounceHandle: number | null = 0;

  constructor() {
    makeObservable(this, {
      facturen: observable,
    });
  }

  public enqueueOphalenFacturen = (inkFactIDs: number[]) => {
    if (this.ophalenFacturenQueueDebounceHandle !== null) {
      clearTimeout(this.ophalenFacturenQueueDebounceHandle);
    }
    this.ophalenFacturenQueue = _.uniq([...this.ophalenFacturenQueue, ...inkFactIDs]);
    this.ophalenFacturenQueueDebounceHandle = setTimeout(this.ophalenQueuedFacturen, 100) as any;
  };
  private ophalenQueuedFacturen = async () => {
    this.ophalenFacturenQueueDebounceHandle = null;
    const inkFactIDs = this.ophalenFacturenQueue;

    if (inkFactIDs.length === 0) {
      return;
    }

    this.ophalenFacturenQueue = [];

    const result = await api.v2.inkoopfactuur.ophalenFacturen({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: inkFactIDs,
          },
        ],
      },
    });
    this.facturen = createReadyRemoteData(
      result.facturen.reduce((acc, curr) => {
        return {
          ...acc,
          [curr.InkFactID]: curr,
        };
      }, this.facturen.data ?? {}),
    );
  };
}

export default InkoopfactuurStore;
