import React, { useContext, useMemo } from 'react';
import { IProps } from '@tinymce/tinymce-react/lib/cjs/main/ts/components/Editor';
import { IContext, FactuurContext } from '../index';
import VeldWeergave from '../../../formulier/VeldWeergave';
import LoadingSpinner from '../../../Gedeeld/LoadingSpinner';
import { format } from 'date-fns';
import GegevensLayout from '../../../layout/GegevensLayout';
import FormatteerBedrag from '../../../MutatieBedrag';
import { GridStyleWrapper, TypedColumn } from '../../../../helpers/dxTableGrid';
import { Grid, TableHeaderRow, VirtualTable, Table } from '@devexpress/dx-react-grid-bootstrap4';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { IOphalenOpdrachtenResultElement } from '../../../../../../shared/src/api/v2/bank/opdracht/opdracht';

const BoekingTab: React.FC<IProps> = (props) => {
  const { factuur, relatie, bankopdrachten } = useContext<IContext>(FactuurContext);

  const kolommenBankopdrachten = useMemo<TypedColumn<IOphalenOpdrachtenResultElement>[]>(
    () => [
      {
        name: 'Soort',
        title: 'Soort',
      },
      {
        name: 'Uitvoerdatum',
        title: 'Uitvoerdatum',
      },
      {
        name: '__status' as any,
        title: 'Status',
      },
    ],
    [],
  );

  const kolomExtensiesBankopdrachten = useMemo<Table.ColumnExtension[]>(
    () => [
      {
        columnName: 'Soort',
        width: 70,
      },
      {
        columnName: 'Uitvoerdatum',
        width: 110,
      },
      {
        columnName: '__status',
        width: 200,
      },
    ],
    [],
  );

  return (
    <>
      {factuur === null ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="form-group"></div>
          <div className="row">
            <div className="col-12 d-flex">
              <div className="d-flex flex-column flex-fill mt-3">
                <div className="col-12">
                  <GridStyleWrapper maxHeight={500} rowAmount={bankopdrachten.length}>
                    <Grid rows={bankopdrachten} columns={kolommenBankopdrachten}>
                      <DataTypeProvider
                        for={['Soort']}
                        formatterComponent={(formatterProps) => {
                          const rij: IOphalenOpdrachtenResultElement = formatterProps.row;

                          return <span>{rij.Bedrag > 0 ? 'Inc.' : 'Bet.'}</span>;
                        }}
                      />

                      <DataTypeProvider
                        for={['Uitvoerdatum']}
                        formatterComponent={(formatterProps) => (
                          <span>
                            {formatterProps.value === null
                              ? null
                              : format(new Date(formatterProps.value), 'dd-MM-yyyy')}
                          </span>
                        )}
                      />

                      <DataTypeProvider
                        for={['__status']}
                        formatterComponent={(formatterProps) => {
                          const rij: IOphalenOpdrachtenResultElement = formatterProps.row;
                          if (rij.batch === null) {
                            return <span>Nog verzamelen</span>;
                          }

                          if (rij.batch.UitgevoerdOp !== null) {
                            return (
                              <span>
                                Uitgevoerd op{' '}
                                {format(new Date(rij.batch.UitgevoerdOp), 'dd-MM-yyyy')}
                              </span>
                            );
                          }

                          if (rij.batch.DatumVerstuurd !== null) {
                            return (
                              <span>
                                Verstuurd op{' '}
                                {format(new Date(rij.batch.DatumVerstuurd!), 'dd-MM-yyyy')}
                              </span>
                            );
                          }
                          return <span>Nog versturen</span>;
                        }}
                      />

                      <VirtualTable
                        columnExtensions={kolomExtensiesBankopdrachten}
                        messages={{ noData: 'Geen bankopdrachten' }}
                      />
                      <TableHeaderRow />
                    </Grid>
                  </GridStyleWrapper>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default BoekingTab;
