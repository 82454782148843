import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';
import IDialoogProps from '../../../core/IDialoogProps';
import Dialoog from '../Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import { Formik, FormikActions, FormikProps } from 'formik';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import Tabblad, { ITabblad } from '../../layout/Tabblad';
import NieuwOfSelecterenContent from './NieuwOfSelecterenContent';
import { defaultFormValues, IFormValues } from '../../formulier/PersoonFormulier';
import api from '../../../api';
import RecentContent from './RecentContent';
import { RootStoreContext } from '../../../stores/RootStore';
import { EResultType } from '../../../stores/CheckStore';
import { IOphalenPersonenResultElementV2 } from '../../../../../shared/src/api/v2/persoon/persoon';

export enum ETabblad {
  NieuwOfSelecteren,
  Recent,
}

export interface ITabbladProps {
  onSelectieBepaald: (selectie: IOphalenPersonenResultElementV2[] | null) => void;
}

const tabbladLabelMap: Record<ETabblad, string> = {
  [ETabblad.NieuwOfSelecteren]: 'Nieuw of selecteren',
  [ETabblad.Recent]: 'Recent',
};

export interface IPersoonSelectieDialoogDialoogResult {
  persID: number;
}

export type PersoonSelectieModus = 'opvoeren' | 'selecteren' | 'beide';

interface IProps extends IDialoogProps<IPersoonSelectieDialoogDialoogResult> {
  tabblad?: ETabblad;
  onTabbladChange?: (tabblad: ETabblad) => void;
  defaultFormValues?: Partial<IFormValues>;
  // modus?: PersoonSelectieModus;
}

interface IFormikValues {
  persID: number | null;
}

export interface IPersoonSelectieDialoogContext {
  defaultFormulierWaarden: IFormValues | null;
  onFormulierChange: (formulier: IFormValues) => void;
  onFormulierIsValidChange: (valid: boolean) => void;
  onPersIDChange: (persID: number) => void;
}

export const PersoonSelectieDialoogContext = React.createContext<IPersoonSelectieDialoogContext>(
  null as any,
);

const PersoonSelectieDialoog: React.FC<IProps> = (props) => {
  const { dialoogIndex, onAnnuleren, onSuccess } = props;
  const { checkStore } = useContext(RootStoreContext);

  const modus = useMemo<PersoonSelectieModus>(() => /*props.modus ??*/ 'beide', [
    /*props.modus*/
  ]);
  const [tabbladState, setTabbladState] = useState<ETabblad>(
    props.tabblad || ETabblad.NieuwOfSelecteren,
  );
  const tabblad = useMemo(() => {
    return props.tabblad ?? tabbladState;
  }, [props.tabblad, tabbladState]);
  const setTabblad = useCallback(
    (tabblad: ETabblad) => {
      if (props.onTabbladChange !== undefined) {
        props.onTabbladChange(tabblad);
      }
      setTabbladState(tabblad);
    },
    [props.onTabbladChange],
  );

  const initialValues: IFormikValues = useMemo(() => {
    return {
      persID: null,
    };
  }, []);

  const [formulierWaarden, setFormulierWaarden] = useState<IFormValues>({
    ...defaultFormValues,
    ...props.defaultFormValues,
  });
  const [formulierWaardenIsValid, setFormulierWaardenIsValid] = useState(false);

  const handleOnFormulierChange = useCallback(
    (values: IFormValues) => {
      setFormulierWaarden(values);
    },
    [setFormulierWaarden],
  );

  const tabbladen: ITabblad<ETabblad>[] = useMemo(() => {
    return [
      {
        id: ETabblad.NieuwOfSelecteren,
        label: tabbladLabelMap[ETabblad.NieuwOfSelecteren],
        content: NieuwOfSelecterenContent,
      },
      {
        id: ETabblad.Recent,
        label: tabbladLabelMap[ETabblad.Recent],
        content: RecentContent,
      },
    ];
  }, [onSuccess, handleOnFormulierChange]);

  const context = useMemo<IPersoonSelectieDialoogContext>(
    () => ({
      defaultFormulierWaarden: formulierWaarden,
      onFormulierChange: handleOnFormulierChange,
      onFormulierIsValidChange: setFormulierWaardenIsValid,
      onPersIDChange: (persID) => {
        onSuccess({
          persID,
        });
      },
    }),
    [formulierWaarden, handleOnFormulierChange, setFormulierWaardenIsValid, onSuccess],
  );

  const selectieRef = useRef<IOphalenPersonenResultElementV2[] | null>(null);
  const tabbladProps = useMemo<ITabbladProps>(
    () => ({
      onSelectieBepaald: (selectie) => (selectieRef.current = selectie),
    }),
    [],
  );

  const handleSubmit = useCallback(
    async (_, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      if (
        tabblad === ETabblad.NieuwOfSelecteren &&
        selectieRef.current !== null &&
        selectieRef.current.length > 0
      ) {
        const bevestigenResult = await checkStore.bevestigen({
          titel: 'Bevestig opvoeren als nieuwe persoon',
          inhoud: 'Er zijn suggesties gevonden. Toch als nieuwe persoon opvoeren?',
        });
        if (bevestigenResult.type === EResultType.Annuleren) {
          actions.setSubmitting(false);
          return;
        }
      }

      const {
        achternaam,
        email,
        geboortedatum,
        geboorteplaats,
        geslacht,
        overleden,
        taalID,
        telefoon,
        voorletters,
        voornaam,
        voorvoegsel,
        notities,
      } = formulierWaarden!;

      const params = {
        achternaam,
        email,
        geboortedatum,
        geboorteplaats,
        geslacht: geslacht!,
        overleden,
        taalID: taalID!,
        telefoonMobiel: telefoon,
        telefoonExtra: null,
        voorletters,
        voornaam,
        voorvoegsel,
        notities,
      };

      const checkData = await api.v2.persoon.checkToevoegenPersoon(params);
      const controleResult = await checkStore.controleren({
        checkData,
        options: {
          dialoogIndex: props.dialoogIndex,
        },
      });
      if (controleResult.type === EResultType.Annuleren) {
        actions.setSubmitting(false);
        return;
      }

      const result = await api.v2.persoon.toevoegenPersoon(params);

      await onSuccess({
        persID: result.persID,
      });

      actions.setSubmitting(false);
    },
    [formulierWaarden, onSuccess, tabblad],
  );

  return (
    <PersoonSelectieDialoogContext.Provider value={context}>
      <Dialoog
        index={dialoogIndex || 0}
        modalProps={{
          size: 'lg',
        }}
      >
        <ModalHeader>
          <ModalTitle>
            {modus === 'beide'
              ? 'Persoon nieuw of selecteren'
              : modus === 'opvoeren'
              ? 'Persoon nieuw'
              : 'Persoon selecteren'}
          </ModalTitle>
        </ModalHeader>
        <Formik<IFormikValues>
          onSubmit={handleSubmit}
          initialValues={initialValues}
          render={(formikProps: FormikProps<IFormikValues>) => {
            const { submitForm, isSubmitting } = formikProps;
            return (
              <>
                <ModalBody className="p-0">
                  <Tabblad<ETabblad>
                    geselecteerd={tabblad}
                    onSelectieChange={setTabblad}
                    tabbladen={tabbladen}
                    options={{
                      tabbladComponentProps: tabbladProps,
                    }}
                  />
                </ModalBody>
                <ModalFooter className="d-flex flex-row justify-content-start">
                  {tabblad === ETabblad.NieuwOfSelecteren && (
                    <button
                      className="btn btn-primary"
                      onClick={submitForm}
                      style={{
                        width: 100,
                      }}
                      disabled={isSubmitting || !formulierWaardenIsValid}
                    >
                      Ok
                    </button>
                  )}
                  <button
                    className="btn btn-secondary"
                    onClick={onAnnuleren}
                    style={{ width: 100 }}
                    disabled={isSubmitting}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      </Dialoog>
    </PersoonSelectieDialoogContext.Provider>
  );
};

export default PersoonSelectieDialoog;
