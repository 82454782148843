import React, { useState, useEffect, useCallback, useMemo, useContext } from 'react';
import api from '../../../../../api';
import LoadingSpinner from '../../../../../components/Gedeeld/LoadingSpinner';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import useUrlState from '../../../../../core/useUrlState';
import {
  ASPKolom,
  EAspKolomBreedteType,
  ESortering,
  IAspKolomSorteringItem,
} from '../../../../../components/tabel/ASPTabel/types';
import MenuLayout from '../../../../../components/MenuLayout';
import ASPTabel from '../../../../../components/tabel/ASPTabel';
import { format } from 'date-fns';
import { ITabbladComponentProps } from '../..';
import _ from 'lodash';
import { IOphalenProducttypenResultElement } from '../../../../../../../shared/src/api/v2/product/type';
import { IOphalenProductenResultElementV2 } from '../../../../../../../shared/src/api/v2/product';
import { Kleur as EKleur } from '../../../../../bedrijfslogica/constanten';
import FilterBalkV2, {
  IFilter,
  IFilterData,
  maakFilterSchema,
} from '../../../../../components/FilterBalkV2';
import { RootStoreContext } from '../../../../../stores/RootStore';
import { EResultType } from '../../../../../stores/CheckStore';
import { IOphalenInventarisatiesResultElement } from '../../../../../../../shared/src/api/v2/magazijn/inventarisatie/lijst/extern';
import BestandenDragAndDropDialoog, {
  IBestandenDragAndDropDialoogResult,
} from '../../../../../components/BestandenDragAndDropDialoog';
import { GlobaleRendererContext } from '../../../../../one-off-components/GlobaleRenderer';
import { EBestandDragAndDropZoneSelectieModus } from '../../../../../components/BestandDragAndDropZone';
import { ILadenExterneInventarisatieResult } from '../../../../../../../shared/src/api/v2/magazijn/inventarisatie/lijst/extern';
import { toast } from 'react-hot-toast';
import Combobox from '../../../../../components/formulier/Combobox';
import { IconKruis } from '../../../../../components/Icons';
import ProducttypeSelectieDialoog, {
  IDialoogOutput,
} from '../../../../../components/dialogen/ProducttypeSelectieDialoog';

interface IProps extends ITabbladComponentProps, RouteComponentProps {}

enum EKolom {
  Bezoekdatum,
  Producttype,
  Artikelcode,
  Productmerk,
  Referentiecode,
  Nieuw,
  Status,
  Inslagdatum,
  Partijcode,
  Partij,
  IsDefinitief,
  Saldo,
  Actie,
  Koppelen,
  Ontkoppelen,
  Regelnummer,
}

enum EFilter {
  StatusArtikelOnbekend = 'STATUS_ARTIKEL_ONBEKEND',
}

interface IKoppelenProductypeDialoogState {}

interface IUrlState {
  selectie: number[];
  sortering: IAspKolomSorteringItem<EKolom>[];
  filterData: IFilterData<EFilter>[];
  koppelenProductypeDialoogState: IKoppelenProductypeDialoogState | null;
}

const defaultUrlState: IUrlState = {
  selectie: [],
  sortering: [
    {
      key: EKolom.Regelnummer,
      sortering: ESortering.Ascending,
    },
    // {
    //   key: EKolom.Productmerk,
    //   sortering: ESortering.Ascending,
    // },
    // {
    //   key: EKolom.Producttype,
    //   sortering: ESortering.Ascending,
    // },
    // {
    //   key: EKolom.Nieuw,
    //   sortering: ESortering.Ascending,
    // },
    // {
    //   key: EKolom.Referentiecode,
    //   sortering: ESortering.Ascending,
    // },
  ],
  filterData: [
    {
      naam: EFilter.StatusArtikelOnbekend,
      data: 1,
      isActief: false,
    },
  ],
  koppelenProductypeDialoogState: null,
};

interface IRegel extends IOphalenInventarisatiesResultElement {}

const Extern: React.FC<IProps> = (props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(
    props,
    defaultUrlState,
    'lijstInternState',
  );

  const { checkStore } = useContext(RootStoreContext);
  const globaleRenderer = useContext(GlobaleRendererContext);

  const [filterSchema, setFilterSchema] = useState(maakFilterSchema(urlState.filterData));

  const [regels, setRegels] = useState<IRegel[] | null>(null);
  const [producttypen, setProducttypen] = useState<IOphalenProducttypenResultElement[] | null>(
    null,
  );
  const [producten, setProducten] = useState<IOphalenProductenResultElementV2[] | null>(null);

  const ophalenRegels = useCallback(async () => {
    const filterOnbekend = filterSchema.filters!.find(
      (x) => x.naam === EFilter.StatusArtikelOnbekend,
    )!;

    const filters = [
      ...(filterOnbekend !== undefined
        ? filterOnbekend.data === 1
          ? [{ naam: 'TYPEID_IS_LEEG', data: true }]
          : filterOnbekend.data === 2
          ? [{ naam: 'TYPEID_IS_LEEG', data: false }]
          : []
        : []),
    ];

    const result = await api.v2.magazijn.inventarisatie.lijst.extern.ophalenInventarisaties({
      filterSchema: {
        filters: [
          ...filters,
          { naam: 'MAGINV_IDS', data: [props.inventarisatieID] },
          // { naam: 'IS_DEFINITIEF', data: true },
          // { naam: 'AANTAL_AANWEZIG_IS_LEEG', data: false }, // TODO -> false
          // { naam: 'AANTAL_AANWEZIG_IS_ONGELIJK_AANTAL_INTERN', data: true },
        ],
      },
    });

    const regels = result.regels.map((x) => {
      return { ...x };
    });

    setRegels(regels);
  }, [props.inventarisatieID, filterSchema.filters]);

  useEffect(() => {
    ophalenRegels();
  }, [ophalenRegels]);

  const ophalenProducttypen = useCallback(async () => {
    if (regels === null) {
      return;
    }

    const result = await api.v2.product.type.ophalenProducttypen({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: _.uniq(regels.filter((x) => x.TypeID !== null).map((x) => x.TypeID)),
          },
        ],
      },
    });

    setProducttypen(result.producttypen);
  }, [regels]);

  useEffect(() => {
    ophalenProducttypen();
  }, [ophalenProducttypen]);

  const ophalenProducten = useCallback(async () => {
    if (regels === null) {
      return;
    }

    const result = await api.v2.product.ophalenProductenV2({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: _.uniq(regels.filter((x) => x.ProdID !== null).map((x) => x.ProdID)),
          },
        ],
      },
    });

    setProducten(result.producten);
  }, [regels]);

  useEffect(() => {
    ophalenProducten();
  }, [ophalenProducten]);

  const keyExtractor = useCallback((item: IRegel) => item.ID, []);

  const kolommen = useMemo<ASPKolom<EKolom, IRegel>[]>(
    () => [
      {
        key: EKolom.Regelnummer,
        label: 'Reg.',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 50,
        renderer: (item: IRegel) => {
          return item.Regelnummer;
        },
      },
      {
        key: EKolom.Productmerk,
        label: 'Merk',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 100,
        renderer: (item: IRegel) => {
          if (producttypen === null) {
            return;
          }

          if (item.TypeID === null) {
            return <span>-</span>;
          }

          const producttype = producttypen.find((x) => x.TypeID === item.TypeID);

          if (producttype === undefined) {
            return <span>Merk, typeID {item.TypeID}</span>;
          }

          return <span>{producttype.Merknaam}</span>;
        },
        vergelijkingswaarde: (item) => {
          if (producttypen === null) {
            return;
          }

          if (item.TypeID === null) {
            return null;
          }

          const producttype = producttypen.find((x) => x.TypeID === item.TypeID);

          if (producttype === undefined) {
            return null;
          }

          return producttype.Merknaam;
        },
      },
      {
        key: EKolom.Producttype,
        label: 'Type',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 160,
        renderer: (item: IRegel) => {
          if (producttypen === null) {
            return;
          }

          if (item.TypeID === null) {
            return <span>-</span>;
          }

          const producttype = producttypen.find((x) => x.TypeID === item.TypeID);

          if (producttype === undefined) {
            return <span>TypeID {item.TypeID}</span>;
          }

          return <span>{producttype!.Typenaam}</span>;
        },
        vergelijkingswaarde: (item) => {
          if (producttypen === null) {
            return;
          }

          if (item.TypeID === null) {
            return null;
          }

          const producttype = producttypen.find((x) => x.TypeID === item.TypeID);

          if (producttype === undefined) {
            return null;
          }

          return producttype.Typenaam;
        },
      },
      {
        key: EKolom.Koppelen,
        label: 'Actie',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 90,
        renderer: (item: IRegel) => {
          return (
            <>
              <a
                href="#"
                // className="ml-1"
                style={{ color: EKleur.LichtBlauw, position: 'relative', bottom: 2 }}
                onClick={async () => {
                  const output = await globaleRenderer.render<IDialoogOutput>((renderProps) => (
                    <ProducttypeSelectieDialoog
                      open
                      onSuccess={(x) => renderProps.destroy(x)}
                      onAnnuleren={() => renderProps.destroy()}
                      zoekterm={(item.Typenaam || item.Artikelcode) ?? undefined}
                    />
                  ));
                  if (output === undefined) {
                    return;
                  }

                  // alert(JSON.stringify(output));
                  await api.v2.magazijn.inventarisatie.lijst.extern.koppelenProducttype({
                    id: item.ID,
                    typeID: output.prodTypeID,
                  });

                  ophalenRegels();
                }}
              >
                {/* <IconInformatie style={{ width: 15, height: 15, fill: EKleur.Blauw }} /> */}
                Koppelen
              </a>
            </>
          );
        },
      },
      {
        key: EKolom.Ontkoppelen,
        label: '',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 90,
        renderer: (item: IRegel) => {
          return (
            <>
              {' '}
              <a
                href="#"
                // className="ml-1"
                style={{ color: EKleur.LichtBlauw, position: 'relative', bottom: 2 }}
                onClick={async () => {
                  if (
                    (
                      await checkStore.bevestigen({
                        inhoud: <span>Producttype ontkoppelen?</span>,
                      })
                    ).type === EResultType.Annuleren
                  ) {
                    return;
                  }

                  await api.v2.magazijn.inventarisatie.lijst.extern.ontkoppelenProducttype({
                    id: item.ID,
                  });

                  ophalenRegels();
                }}
              >
                <IconKruis style={{ width: 15, height: 15, fill: EKleur.Rood }} />
              </a>
            </>
          );
        },
      },
      {
        key: EKolom.Nieuw,
        label: 'N/G',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 75,
        renderer: (item: IRegel) => {
          return <span>{item.Nieuw ? 'N' : 'G'}</span>;
        },
        vergelijkingswaarde: (item) => {
          return item.Nieuw ? 0 : 1;
        },
      },
      {
        key: EKolom.Referentiecode,
        label: 'Ref.code',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 120,
        renderer: (item: IRegel) => {
          return <span>{item.Referentiecode !== null ? item.Referentiecode : ''}</span>;
        },
        vergelijkingswaarde: (item) => {
          return item.Referentiecode !== null ? item.Referentiecode : '';
        },
      },
      {
        key: EKolom.Saldo,
        label: 'Saldo',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 80,
        renderer: (x) => x.Saldo,
      },
      {
        key: EKolom.Artikelcode,
        label: 'Artikelcode',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 165,
        renderer: (item: IRegel) => {
          if (item.TypeID === null) {
            return <span>{item.Artikelcode}</span>;
          }

          if (item.Typenaam!.replace(' ', '') !== item.Artikelcode.replace(' ', '')) {
            return (
              <span
                style={{
                  color: item.ArtikelcodeEerderGekoppeld ? EKleur.HeelLichtRood : EKleur.Rood,
                }}
              >
                {item.Artikelcode}
              </span>
            );
          }

          return <span style={{ color: EKleur.LichtGrijs }}>{item.Artikelcode}</span>;
        },
      },
      {
        key: EKolom.Inslagdatum,
        label: 'Inslagdatum',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 115,
        renderer: (x) =>
          x.Inslagdatum !== null ? format(new Date(x.Inslagdatum), 'dd-MM-yyyy') : '',
      },
      {
        key: EKolom.Partijcode,
        label: 'Partijcode',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 125,
        renderer: (x) => x.Partijcode,
      },
      {
        key: EKolom.Partij,
        label: 'Partij',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 200,
        renderer: (x) => x.Partij,
      },
      // {
      //   key: EKolom.AantalAanwezig,
      //   label: '# Aanwezig',
      //   breedteType: EAspKolomBreedteType.Vast,
      //   vasteBreedte: 105,
      //   renderer: (x) => x.AantalAanwezig,
      // },
      // {
      //   key: EKolom.Actie,
      //   label: 'Actie',
      //   breedteType: EAspKolomBreedteType.Flex,
      //   flex: 1,
      //   renderer: (item: IOphalenInventarisatieregelsResultElement) => {
      //     if (item.AantalAanwezig === null) {
      //       return '- -';
      //     }
      //     const verschil = item.AantalAanwezig - item.AantalIntern;
      //     if (verschil > 0) {
      //       return <span>Opvoeren {verschil !== 1 ? ` (${verschil}x)` : ''}</span>;
      //     }
      //     return (
      //       <span>Zoekmelden {Math.abs(verschil) !== 1 ? ` (${Math.abs(verschil)}x)` : ''}</span>
      //     );
      //   },
      // },
    ],
    [producten, producttypen],
  );

  const filterOnbekendopties = [
    { id: 1, label: 'Ja' },
    { id: 2, label: 'Nee' },
  ];

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.StatusArtikelOnbekend,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return (
            <div className="d-flex align-items-center">
              <span className="mr-2" style={{ minWidth: 200 }}>
                Typenaam artikel onbekend
              </span>
              {filterOnbekendopties === null ? (
                <LoadingSpinner />
              ) : (
                <Combobox
                  geselecteerd={weergaveProps.data}
                  onSelectieChange={(x) => {
                    weergaveProps.onDataChange(x);
                    weergaveProps.setIsActief(true);
                    weergaveProps.toepassen();
                  }}
                  legeOptieTonen
                  opties={filterOnbekendopties}
                />
              )}
            </div>
          );
        },
      },
    ],
    [],
  );

  return (
    <>
      <MenuLayout
        menu={
          <div className="d-flex align-items-center">
            <button
              className="btn btn-sm btn-light d-flex align-items-center"
              style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
              onClick={async () => {
                const bestandenResult = await globaleRenderer.render<
                  IBestandenDragAndDropDialoogResult
                >((renderProps) => (
                  <BestandenDragAndDropDialoog
                    open
                    onSuccess={(result) => renderProps.destroy(result)}
                    onAnnuleren={() => renderProps.destroy()}
                    toegestaneBestandstypen={[
                      {
                        weergaveNaam: 'CSV',
                        mediaType: 'text/csv',
                      },
                    ]}
                    selectieModus={EBestandDragAndDropZoneSelectieModus.Enkel}
                  />
                ));

                if (bestandenResult === undefined) {
                  return;
                }
                const bestand = bestandenResult.bestanden[0];

                let ladenResult: ILadenExterneInventarisatieResult;
                try {
                  ladenResult = await api.v2.magazijn.inventarisatie.lijst.extern.ladenExterneInventarisatie(
                    {
                      bestandID: bestand.ID,
                    },
                  );
                } catch (err) {
                  console.error(err);
                  toast.error('Fout bij het inlezen van de externe inventarisatie');
                  return;
                }

                await api.v2.magazijn.inventarisatie.lijst.extern.converterenExternInventarisatiebestand(
                  {
                    magInvExtBestID: ladenResult.MagInvExtBestID,
                    magInvID: props.inventarisatieID,
                  },
                );

                // await api.v2.magazijn.inventarisatie.toevoegenExterneInventarisatie({
                //   magInvID: props.inventarisatieID,
                // });

                await ophalenRegels();

                toast.success('Inlezen en converteren van externe inventarisatie gelukt');

                return;
              }}
            >
              <span className="ml-2">Inlezen extern bestand</span>
            </button>

            <button
              className="btn btn-sm btn-light d-flex align-items-center ml-3"
              style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
              onClick={async () => {
                const params = { magInvID: props.inventarisatieID };
                const checkData = await api.v2.magazijn.inventarisatie.checkToevoegenExterneInventarisatie(
                  params,
                );
                if (
                  (
                    await checkStore.controleren({
                      checkData,
                    })
                  ).type === EResultType.Annuleren
                ) {
                  return;
                }

                if (
                  (
                    await checkStore.bevestigen({
                      inhoud: (
                        <span>
                          Externe lijst samenvoegen met de interne lijst?
                          <br />
                          <br />
                          De samenvoeging zal te zien zijn bij het tabje Inventarisatie
                        </span>
                      ),
                    })
                  ).type === EResultType.Annuleren
                ) {
                  return;
                }

                await api.v2.magazijn.inventarisatie.toevoegenExterneInventarisatie(params);

                await api.v2.magazijn.inventarisatie.voorvullenAantalAanwezig(params);

                ophalenRegels();

                toast.success('Samenvoegen externe lijst gelukt');

                return;
              }}
            >
              <span className="ml-2">Samenvoegen</span>
            </button>

            <button
              className="btn btn-sm btn-light d-flex align-items-center ml-3"
              style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
              onClick={async () => {
                if (
                  (
                    await checkStore.bevestigen({
                      inhoud: (
                        <span>
                          Lijst verwijderen?
                          <br />
                          <br />
                          Let op, eventueel reeds opgegeven inventarisaties voor de externe lijst
                          zullen hierbij eveneens verwijderd worden.
                        </span>
                      ),
                    })
                  ).type === EResultType.Annuleren
                ) {
                  return;
                }

                await api.v2.magazijn.inventarisatie.lijst.extern.verwijderenInventarisaties({
                  magInvIDs: [props.inventarisatieID],
                });

                ophalenRegels();

                toast.success('Verwijderen externe lijst gelukt');

                return;
              }}
            >
              <span className="ml-2">Verwijderen lijst</span>
            </button>

            <div className="d-flex flex-fill ml-3">
              <FilterBalkV2
                filters={filters}
                filterData={urlState.filterData}
                onFilterDataChange={(x) => setUrlStateSync('filterData', x)}
                onFilterSchemaChange={(x) => setFilterSchema(x)}
              />
            </div>
          </div>
        }
        body={
          <div className="d-flex flex-column flex-fill">
            {regels === null ? (
              <div className="flex-fill d-flex align-items-center justify-content-center">
                <LoadingSpinner />
              </div>
            ) : (
              <div className="d-flex flex-column flex-fill">
                <ASPTabel
                  rijen={regels}
                  kolommen={kolommen}
                  keyExtractor={keyExtractor}
                  selectie={urlState.selectie}
                  onSelectieChange={(selectie) => setUrlStateSync('selectie', selectie)}
                  sortering={urlState.sortering}
                  onSorteringChange={(sortering) => setUrlStateSync('sortering', sortering)}
                  // onWijzigenRij={async (x) => {}}
                  // onVerwijderenRij={async (x) => {
                  //   await new Promise((resolve) => setTimeout(resolve, 3000));
                  // }}
                  lokaalSorteren
                />
              </div>
            )}
          </div>
        }
      />
      {/* {urlState.koppelenProductypeDialoogState !== null && (
        <ToevoegenDialoog
          open
          onSuccess={async () => {
            setUrlStateSync('koppelenProductypeDialoogState', null);
            ophalenRegels();
          }}
          onAnnuleren={() => setUrlStateSync('koppelenProductypeDialoogState', null)}
        />
      )} */}
    </>
  );
};

export default withRouter(Extern);
