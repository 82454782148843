import React from 'react';
import { Redirect, Route } from 'react-router';
import { RouteComponentProps } from 'react-router';
import Verlofregistratie from './Verlofregistratie';
import Verlofoverzicht from './Verlofoverzicht';

interface IProps extends RouteComponentProps {}

const Verlof = (props: IProps) => {
  const { match } = props;
  return (
    <>
      <Route path={`${match.path}/overzicht`} component={Verlofoverzicht} />
      <Route path={`${match.path}/registratie`} component={Verlofregistratie} />

      <Route
        exact={true}
        path={match.path}
        render={() => <Redirect to={`${match.path}/overzicht`} />}
      />
    </>
  );
};

export default Verlof;
