import React, { useContext, useMemo } from 'react';
import MultiCombobox, {
  IKolom,
} from '../../../../../../../../../components/formulier/MultiCombobox';
import { IOphalenServicedienstenResultElement } from '../../../../../../../../../../../shared/src/api/v2/service/opdracht';
import InactiefOverlay from '../../../../../../../../../components/InactiefOverlay';
import { Field, FieldProps } from 'formik';
import { IFormikValues, veldnamen } from '..';
import VinkVeld from '../../../../../../../../../components/formulier/VinkVeld';
import FormikVeldFout from '../../../../../../../../../components/formulier/FormikVeldFout';
import HorizontaleScheidingslijn from '../../../../../../../../../components/layout/HorizontaleScheidingslijn';
import nameof from '../../../../../../../../../core/nameOf';
import { FormulierContext } from '../Formulier';
import Skeleton from 'react-loading-skeleton';
import IRemoteData, {
  ERemoteDataState,
  createReadyRemoteData,
} from '../../../../../../../../../models/IRemoteData';

interface IProps {}

const OpdrachtTab: React.FC<IProps> = (props) => {
  const { values, servicediensten } = useContext(FormulierContext);

  const servicedienstenKolommen = useMemo<IKolom<IOphalenServicedienstenResultElement>[]>(
    () => [
      {
        key: '__naam' as any,
        label: 'Servicedienst',
        formatFabriek: (row) => {
          return <span>{row.relatie!.weergavenaam}</span>;
        },
        breedte: 180,
      },
      {
        key: 'Criterium' as any,
        label: 'Criterium',
        formatFabriek: (row) => {
          return <span>{row.criterium}</span>;
        },
        breedte: 225,
      },
      {
        key: 'Beschikbaarheid' as any,
        label: 'Beschikbaarheid',
        formatFabriek: (row) => {
          return <span>{row.afwezig ? 'Afwezig' : 'Beschikbaar'}</span>;
        },
        breedte: 125,
      },
    ],
    [],
  );

  return (
    <>
      <div className="p-3">
        <div className="form-group">
          <div className="row mb-3">
            <div className="col-12 mt-3 mb-3 d-flex align-items-center">
              <Field
                name="opdrachtMaken"
                render={(fieldProps: FieldProps<IFormikValues>) => (
                  <>
                    <VinkVeld
                      aangevinkt={fieldProps.field.value}
                      onGewijzigd={async (x) => {
                        fieldProps.form.setFieldValue(fieldProps.field.name, x);
                      }}
                    />
                    <FormikVeldFout fieldProps={fieldProps} />
                  </>
                )}
              />
              <span className="ml-2">{veldnamen.opdrachtMaken}</span>
            </div>

            <div className="col-12 mt-2">
              <div className="flex-fill d-flex flex-column">
                <InactiefOverlay
                  rootStyle={{ flex: 1 }}
                  isInactief={!values.opdrachtMaken}
                  element={
                    <div className="row p-3">
                      <div className="col-12 d-flex align-items-center">
                        <Field
                          name="opdrachtVersturen"
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <>
                                <VinkVeld
                                  disabled={!form.values.opdrachtMaken}
                                  aangevinkt={field.value}
                                  onGewijzigd={(x) => form.setFieldValue(fieldProps.field.name, x)}
                                />
                                <FormikVeldFout fieldProps={fieldProps} />
                              </>
                            );
                          }}
                        />
                        <span className="ml-2">{veldnamen.opdrachtVersturen}</span>
                      </div>

                      <div className="col-12 mt-3">
                        <HorizontaleScheidingslijn />
                      </div>

                      <div className="col-12 mt-3 d-flex align-items-center">
                        <Field
                          name="garantie"
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <>
                                <VinkVeld
                                  aangevinkt={field.value}
                                  onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                />
                                <FormikVeldFout fieldProps={fieldProps} />
                              </>
                            );
                          }}
                        />
                        <span className="ml-2">{veldnamen.garantie}</span>
                      </div>

                      <div className="col-12 mt-2">
                        <label>{veldnamen.servDienstID}</label>
                        <Field
                          name={nameof<IFormikValues>('servDienstID')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            if (servicediensten === null) {
                              return (
                                <>
                                  <Skeleton />
                                  {values.locID === null ? (
                                    <span>
                                      <i>
                                        Diensten kunnen nog niet worden opgehaald. Is de locatie nog
                                        niet ingesteld?
                                      </i>
                                    </span>
                                  ) : (
                                    <span></span>
                                  )}
                                </>
                              );
                            }
                            const value = field.value as IRemoteData<number | null>;

                            return (
                              <>
                                <MultiCombobox<number, IOphalenServicedienstenResultElement>
                                  sleutelExtractor={(x) => x.ID}
                                  representatieFabriek={(x) => `${x.relatie!.weergavenaam}`}
                                  waarde={value.data ?? null}
                                  onWaardeChange={(x) =>
                                    form.setFieldValue(field.name, createReadyRemoteData(x))
                                  }
                                  opties={servicediensten}
                                  kolommen={servicedienstenKolommen}
                                  disabled={value.state === ERemoteDataState.Pending}
                                />

                                <FormikVeldFout fieldProps={fieldProps} />
                              </>
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3 d-flex align-items-center">
                        <Field
                          name="spoed"
                          render={(fieldProps: FieldProps<IFormikValues>) => (
                            <>
                              <VinkVeld
                                aangevinkt={fieldProps.field.value}
                                onGewijzigd={async (x) => {
                                  fieldProps.form.setFieldValue(fieldProps.field.name, x);
                                }}
                              />
                              <span className="ml-2">{veldnamen.spoed}</span>
                              <FormikVeldFout fieldProps={fieldProps} />
                            </>
                          )}
                        />
                      </div>

                      <div className="col-12 mt-5">
                        <HorizontaleScheidingslijn />
                      </div>

                      <div className="col-12 mt-3 d-flex align-items-center">
                        <Field
                          name="bevestigingNaarMelder"
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <>
                                <VinkVeld
                                  disabled={!form.values.opdrachtMaken}
                                  aangevinkt={fieldProps.field.value}
                                  onGewijzigd={async (x) => {
                                    fieldProps.form.setFieldValue(fieldProps.field.name, x);
                                  }}
                                />
                                <span className="ml-2">{veldnamen.bevestigingNaarMelder}</span>
                                <FormikVeldFout fieldProps={fieldProps} />
                              </>
                            );
                          }}
                        />
                      </div>
                    </div>
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OpdrachtTab;
