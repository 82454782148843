import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { IProps } from '@tinymce/tinymce-react/lib/cjs/main/ts/components/Editor';
import { IContext, BoekingenContext, IRegel } from '../index';
import LoadingSpinner from '../../../Gedeeld/LoadingSpinner';
import { format } from 'date-fns';
import GegevensLayout from '../../../layout/GegevensLayout';
import FormatteerBedrag from '../../../MutatieBedrag';
import { Kleur as EKleur } from '../../../../bedrijfslogica/constanten';
import nameOf from '../../../../core/nameOf';
import {
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import VeldWeergave from '../../../formulier/VeldWeergave';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableColumnResizing,
  TableHeaderRow,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import FactuurVisualisatie from '../../../entiteitVisualisaties/FactuurVisualisatie';
import InkoopfactuurVisualisatie from '../../../entiteitVisualisaties/InkoopfactuurVisualisatie';
import api from '../../../../api';
import { IOphalenBoekingRegelsResultElement } from '../../../../../../shared/src/api/v2/boekhouding/boeking';
import { IOphalenBetalingsregelingenResultElement } from '../../../../../../shared/src/api/v2/debiteur/betalingsregeling';
import _ from 'lodash';

export enum EFilter {
  Dagboeksoort = 'IDS',
}

const BoekingenTab: React.FC<IProps> = (props) => {
  const { relatie, bankopdrachten } = useContext<IContext>(BoekingenContext);

  const [boekingregels, setBoekingregels] = useState<IOphalenBoekingRegelsResultElement[] | null>(
    null,
  );

  const [betalingsregelingen, setBetalingsregelingen] = useState<
    IOphalenBetalingsregelingenResultElement[] | null
  >(null);

  const ophalenBoekingregels = useCallback(async () => {
    const regelsResult = (
      await api.v2.boeking.ophalenBoekingregels({
        filterSchema: {
          filters: [
            {
              naam: 'REL_IDS',
              data: [relatie!.RelID],
            },
          ],
        },
        orderSchema: {
          orders: [
            { naam: 'BOEKDATUM', richting: 'DESC' },
            { naam: 'BOEKNUMMER', richting: 'DESC' },
            { naam: 'REGELNUMMER', richting: 'DESC' },
          ],
        },
      })
    ).regels;

    setBoekingregels(regelsResult);
  }, [relatie!.RelID]);

  useEffect(() => {
    ophalenBoekingregels();
  }, [ophalenBoekingregels]);

  const ophalenBetalingsregelingen = useCallback(async () => {
    if (boekingregels === null) {
      return;
    }

    const betRglIDs = _.uniq(
      boekingregels.filter((x) => x.BetRglID !== null).map((x) => x.BetRglID),
    );

    const regelingenResult = (
      await api.v2.debiteur.betalingsregeling.ophalenBetalingsregelingen({
        filterSchema: {
          filters: [
            {
              naam: 'IDS',
              data: betRglIDs,
            },
          ],
        },
      })
    ).betalingsregelingen;

    setBetalingsregelingen(regelingenResult);
  }, [boekingregels]);

  useEffect(() => {
    ophalenBetalingsregelingen();
  }, [ophalenBetalingsregelingen]);

  const keyExtractor = useCallback((row: IRegel) => row.ID, []);

  const kolommen = useMemo<TypedColumn<IRegel>[]>(
    () => [
      {
        name: '__datum' as any,
        title: 'Datum',
      },
      {
        name: '__dbRekening' as any,
        title: 'DB',
      },
      {
        name: 'Bedrag',
        title: 'Bedrag',
      },
      {
        name: '__crRekening' as any,
        title: 'CR',
      },
      {
        name: '__factuur' as any,
        title: 'Factuur',
      },
      // {
      //   name: '__id' as any,
      //   title: 'ID',
      // },
      {
        name: '__bankopdracht' as any,
        title: 'Bankopdracht',
      },
      {
        name: '__betalingsregeling' as any,
        title: 'Bet.rgl.',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRegel>[]>(
    () => [
      {
        columnName: '__datum' as any,
        width: 100,
      },
      {
        columnName: 'Bedrag',
        width: 100,
      },
      {
        columnName: '__dbRekening' as any,
        width: 160,
      },
      {
        columnName: '__crRekening' as any,
        width: 160,
      },
      {
        columnName: '__factuur' as any,
        width: 150,
      },
      {
        columnName: '__id' as any,
        width: 100,
      },
      {
        columnName: '__bankopdracht' as any,
        width: 150,
      },
      {
        columnName: '__betalingsregeling' as any,
        width: 100,
      },
    ],
    [],
  );

  if (boekingregels === null || betalingsregelingen === null) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <div className="d-flex flex-column">
        <div className="mt-2 pl-3 pr-3 pt-2 mb-4">
          <VeldWeergave>
            <GridStyleWrapper rowAmount={boekingregels.length} maxHeight={650}>
              <Grid rows={boekingregels} columns={kolommen} getRowId={keyExtractor}>
                <DataTypeProvider
                  for={['__datum']}
                  formatterComponent={(formatterProps) => {
                    const rij: IRegel = formatterProps.row;
                    return <span>{format(new Date(rij.boeking.Boekdatum), 'dd-MM-yyyy')}</span>;
                  }}
                />

                <DataTypeProvider
                  for={[nameOf('Bedrag')]}
                  formatterComponent={(formatterProps) => {
                    const rij: IRegel = formatterProps.row;
                    return <FormatteerBedrag bedrag={rij.Bedrag} />;
                  }}
                />

                <DataTypeProvider
                  for={['__dbRekening']}
                  formatterComponent={(formatterProps) => {
                    const rij: IRegel = formatterProps.row;
                    return (
                      <span>
                        {rij.dbRekening.Nummer} - {rij.dbRekening.Naam}
                      </span>
                    );
                  }}
                />

                <DataTypeProvider
                  for={['__crRekening']}
                  formatterComponent={(formatterProps) => {
                    const rij: IRegel = formatterProps.row;

                    return (
                      <span>
                        {rij.crRekening.Nummer} - {rij.crRekening.Naam}
                      </span>
                    );
                  }}
                />

                <DataTypeProvider
                  for={['__factuur']}
                  formatterComponent={(formatterProps) => {
                    const rij: IRegel = formatterProps.row;

                    if (rij.FactID !== null) {
                      return <FactuurVisualisatie factID={rij.FactID} />;
                    }
                    if (rij.InkFactID !== null) {
                      return <InkoopfactuurVisualisatie inkFactID={rij.InkFactID} />;
                    }
                    return <span></span>;
                  }}
                />

                <DataTypeProvider
                  for={['__bankopdracht']}
                  formatterComponent={(formatterProps) => {
                    const rij: IRegel = formatterProps.row;

                    if (bankopdrachten === null) {
                      return <span></span>;
                    }
                    const bankopdracht =
                      rij.BankOpdID !== null
                        ? bankopdrachten.find((x) => x.BankOpdID === rij.BankOpdID)!
                        : null;
                    if (bankopdracht === null) {
                      return <span></span>;
                    }
                    return <span>{bankopdracht.Omschrijving}</span>;
                  }}
                />

                <DataTypeProvider
                  for={['__betalingsregeling']}
                  formatterComponent={(formatterProps) => {
                    const rij: IRegel = formatterProps.row;

                    const regeling =
                      rij.BetRglID !== null
                        ? betalingsregelingen.find((x) => x.ID === rij.BetRglID)!
                        : null;
                    if (regeling === null) {
                      return <span></span>;
                    }
                    return <span>{regeling.Referentie}</span>;
                  }}
                />

                <Table columnExtensions={kolomBreedtes} />

                {/* <VirtualTable
            messages={{
              noData: 'Geen documenten gevonden',
            }}
          /> */}
                {/* <TableColumnResizing defaultColumnWidths={kolomBreedtes} /> */}
                <TableHeaderRow />
              </Grid>
            </GridStyleWrapper>
          </VeldWeergave>
        </div>
      </div>
    </>
  );
};

export default BoekingenTab;
