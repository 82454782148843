import { Redirect, Route } from 'react-router-dom';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import Aanbod from './Aanbod';
import Vergelijken from './Vergelijken';

const Concurrentie = (props: RouteComponentProps) => {
  const { match } = props;

  return (
    <>
      <Route path={`${match.path}/aanbod`} component={Aanbod} />
      <Route path={`${match.path}/vergelijken`} component={Vergelijken} />

      <Route
        exact={true}
        path={match.path}
        render={() => <Redirect to={`${match.path}/aanbod`} />}
      />
    </>
  );
};

export default Concurrentie;
