import React from 'react';
import { Route, Link, RouteComponentProps, NavLink } from 'react-router-dom';
import PaginaHeader from '../PaginaHeader';
import {
  IconTransport,
  IconKlok,
  functioneleIconMap,
  EFunctioneleIcon,
} from '../../components/Icons';
import Opdrachten from './Opdrachten';
import Bezoektijden from './Bezoektijden';
import Diensten from '../Diensten/Beheer/Transport';
import { Redirect } from 'react-router';

const IconDienst = functioneleIconMap[EFunctioneleIcon.Dienst];

const Transport = ({ match }: RouteComponentProps) => (
  <div className="d-flex flex-column flex-fill">
    <PaginaHeader>
      <ul>
        <li>
          <NavLink
            to={`${match.url}/opdrachten`}
            style={{ paddingLeft: 20, paddingRight: 20 }}
            className="d-flex align-items-center justify-content-center"
          >
            <IconTransport style={{ width: 20, height: 20 }} />
            &nbsp; Opdrachten
          </NavLink>
        </li>
        <li>
          <NavLink
            to={`${match.url}/bezoektijden`}
            style={{ paddingLeft: 20, paddingRight: 20 }}
            className="d-flex align-items-center justify-content-center"
          >
            <IconKlok style={{ width: 20, height: 20 }} />
            &nbsp; Bezoektijden
          </NavLink>
        </li>
        <li>
          <NavLink
            to={`${match.url}/diensten`}
            style={{ paddingLeft: 20, paddingRight: 20 }}
            className="d-flex align-items-center justify-content-center"
          >
            <IconDienst style={{ width: 20, height: 20 }} />
            &nbsp; Transportdiensten
          </NavLink>
        </li>
      </ul>
    </PaginaHeader>

    <Route path={`${match.path}/opdrachten`} component={Opdrachten} />
    <Route path={`${match.path}/bezoektijden`} component={Bezoektijden} />
    <Route path={`${match.path}/diensten`} component={Diensten} />

    <Route exact path={match.path} render={() => <Redirect to={`${match.path}/opdrachten`} />} />
  </div>
);

export default Transport;
