import React, { useMemo, useRef, useState } from 'react';
import { Kleur } from '../../bedrijfslogica/constanten';
import { Document, Page } from 'react-pdf';
import LoadingSpinner from '../Gedeeld/LoadingSpinner';
import { PDFDocumentProxy } from 'pdfjs-dist';
import styled from 'styled-components';
import { AutoSizer } from 'react-virtualized';
import { IconChevronRight } from '../Icons';

interface IProps {
  url: string;
  pageWidth?: number;
}

interface IStyleWrapperProps {
  height: number;
  width: number;
  pageWidth: number;
}

const StyleWrapper = styled.div<IStyleWrapperProps>`
  .document {
    overflow: auto;
    padding: 25px 0 25px 0;
    min-height: ${(props) => props.height}px;
    min-width: ${(props) => props.width}px;
    max-height: ${(props) => props.height}px;
    max-width: ${(props) => props.width}px;
  }

  .react-pdf__Page {
    left: ${(props) => (props.width - props.pageWidth) / 2}px;
    max-width: ${(props) => props.pageWidth}px;
  }
`;

const MAX_PAGES = 25;

const PDFWeergever: React.FC<IProps> = (props) => {
  const pdfDocumentProxyRef = useRef<PDFDocumentProxy | null>(null);
  const [numPages, setNumPages] = useState<number | null>(null);
  const pagesArr = useMemo(() => {
    if (numPages === null) {
      return null;
    }
    const numPagesToDisplay = Math.min(numPages, MAX_PAGES);
    return new Array(numPagesToDisplay).fill(null).map((_, i) => i + 1);
  }, [numPages]);

  return (
    <div style={{ backgroundColor: Kleur.LichtGrijs }} className="d-flex flex-fill">
      <AutoSizer>
        {({ width, height }) => {
          const pageWidth = props.pageWidth ?? width * 0.9;
          return (
            <StyleWrapper width={width} height={height} pageWidth={pageWidth}>
              <Document
                file={props.url}
                loading={() => <LoadingSpinner />}
                error={() => (
                  <div className="flex-fill d-flex flex-column justify-content-center align-items-center">
                    PDF kan niet worden weergegeven
                  </div>
                )}
                onLoadSuccess={(pdf) => {
                  pdfDocumentProxyRef.current = pdf;
                  setNumPages(pdf.numPages);
                }}
                className="document"
              >
                {pagesArr === null ? (
                  <LoadingSpinner />
                ) : (
                  <>
                    {pagesArr.map((i) => {
                      return (
                        <div>
                          <Page key={i} pageNumber={i} width={pageWidth} />
                          {i !== numPages! && <div style={{ height: 20 }} />}
                        </div>
                      );
                    })}
                    {numPages! > MAX_PAGES && (
                      <div className="mt-2 d-flex flex-column justify-content-center align-items-center">
                        <div
                          style={{ background: Kleur.Wit, borderRadius: 5 }}
                          className="p-3 pl-4 pr-4"
                        >
                          <span className="">
                            Er zijn nog {numPages! - MAX_PAGES} pagina's. Klik&nbsp;
                            <a href={props.url} target="_blank">
                              hier
                            </a>
                            &nbsp;om de rest van het document te bekijken.
                          </span>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </Document>
            </StyleWrapper>
          );
        }}
      </AutoSizer>
    </div>
  );
};

export default PDFWeergever;
