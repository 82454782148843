import { makeObservable, observable } from 'mobx';
import {
  IIndicaties,
  IMeldingIndicatieGewijzigdMessageData,
} from '../../../shared/src/api/v2/meldingIndicatie';
import api from '../api';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../models/IRemoteData';
import { RootStore } from './RootStore';

class MeldingIndicatieStore {
  public indicaties: IRemoteData<IIndicaties> = createPendingRemoteData();
  private rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this, {
      indicaties: observable,
    });
  }

  public initialiseer = async () => {
    const result = await api.v2.meldingIndicatie.ophalenMeldingIndicaties({
      aspGebrID: this.rootStore.gebruikerStore.gebruiker!.AspGebrID,
    });
    this.indicaties = createReadyRemoteData(result.indicaties);
  };

  public handleMeldingIndicatieGewijzigdMessageData = (
    data: IMeldingIndicatieGewijzigdMessageData,
  ) => {
    if (this.indicaties.state === ERemoteDataState.Pending) {
      return;
    }

    const nieuweIndicaties = {
      ...this.indicaties.data!,
      [data.soort]: data.aantal,
    };
    this.indicaties = createReadyRemoteData(nieuweIndicaties);
  };
}

export default MeldingIndicatieStore;
