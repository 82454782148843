import api from '../index';
import {
  IOphalenRelatiesVanPersoonParams,
  IOphalenRelatiesVanPersoonResult,
  ISelecterenPersonenParams,
  SelecterenPersonenResult,
  IWijzigenPersoonParams,
  IWijzigenPersoonResult,
  IToevoegenPersoonParams,
  IToevoegenPersoonResult,
  IOphalenGeslachtssuggestieParams,
  IOphalenGeslachtssuggestieResult,
  IOphalenRelatieGerelateerdParams,
  IOphalenRelatieGerelateerdResult,
  IWijzigenRelatieGerelateerdParams,
  IWijzigenRelatieGerelateerdResult,
  IOphalenGeslachtenParams,
  IOphalenGeslachtenResult,
  IOphalenPersonenParams,
  IOphalenPersonenResult,
  IBijwerkenLijstRecentParams,
  IBijwerkenLijstRecentResult,
  IOphalenPersonenRecentParams,
  IOphalenPersonenRecentResult,
  IVerwijderenPersonenParams,
  IVerwijderenPersonenResult,
  IOphalenPersoonAttributenParams,
  IOphalenPersoonAttributenResult,
} from '../../../../shared/src/api/v2/persoon/persoon';

import kredietwaardigheid from './kredietwaardigheid';
import ICheckData from '../../../../shared/src/models/ICheckData';
import duplicaat from './persoon/duplicaat';

const persoon = {
  kredietwaardigheid,
  duplicaat,

  ophalenRelatieGerelateerd: async (
    params: IOphalenRelatieGerelateerdParams,
  ): Promise<IOphalenRelatieGerelateerdResult> => {
    return await api.post('v2/persoon/ophalen-relatie-gerelateerd', params);
  },
  wijzigenRelatieGerelateerd: async (
    params: IWijzigenRelatieGerelateerdParams,
  ): Promise<IWijzigenRelatieGerelateerdResult> => {
    return await api.post('v2/persoon/wijzigen-relatie-gerelateerd', params);
  },
  async ophalenPersonen(
    params: IOphalenPersonenParams,
    signal?: AbortSignal,
  ): Promise<IOphalenPersonenResult> {
    return await api.post('v2/persoon/ophalen-personen-v2', params, { signal });
  },
  async ophalenRelatiesVanPersoon(
    params: IOphalenRelatiesVanPersoonParams,
  ): Promise<IOphalenRelatiesVanPersoonResult> {
    return await api.post('v2/persoon/ophalen-relaties-van-persoon', params);
  },
  wijzigenPersoon: async (params: IWijzigenPersoonParams): Promise<IWijzigenPersoonResult> => {
    return await api.post('v2/persoon/wijzigen-persoon', params);
  },
  checkToevoegenPersoon: async (params: IToevoegenPersoonParams): Promise<ICheckData> => {
    return await api.post('v2/persoon/check-toevoegen-persoon', params);
  },
  toevoegenPersoon: async (params: IToevoegenPersoonParams): Promise<IToevoegenPersoonResult> => {
    return await api.post('v2/persoon/toevoegen-persoon', params);
  },
  ophalenGeslachtssuggestie: async (
    params: IOphalenGeslachtssuggestieParams,
  ): Promise<IOphalenGeslachtssuggestieResult> => {
    return await api.post('v2/persoon/ophalen-geslachtssuggestie', params);
  },
  ophalenGeslachten: async (
    params: IOphalenGeslachtenParams,
  ): Promise<IOphalenGeslachtenResult> => {
    return await api.post('v2/persoon/ophalen-geslachten', params);
  },
  bijwerkenLijstRecent: async (
    params: IBijwerkenLijstRecentParams,
  ): Promise<IBijwerkenLijstRecentResult> => {
    return await api.post('v2/persoon/bijwerken-lijst-recent', params);
  },
  ophalenPersonenRecent: async (
    params: IOphalenPersonenRecentParams,
  ): Promise<IOphalenPersonenRecentResult> => {
    return await api.post('v2/persoon/ophalen-personen-recent', params);
  },
  checkVerwijderenPersonen: async (params: IVerwijderenPersonenParams): Promise<ICheckData> => {
    return await api.post('v2/persoon/check-verwijderen-personen', params);
  },
  verwijderenPersonen: async (
    params: IVerwijderenPersonenParams,
  ): Promise<IVerwijderenPersonenResult> => {
    return await api.post('v2/persoon/verwijderen-personen', params);
  },
  ophalenPersoonAttributen: async (
    params: IOphalenPersoonAttributenParams,
  ): Promise<IOphalenPersoonAttributenResult> => {
    return await api.post('v2/persoon/ophalen-persoon-attributen', params);
  },
};
export default persoon;
