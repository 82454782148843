import {
  IOphalenOpdrachtenParams,
  IOphalenOpdrachtenResult,
  IVerwijderenOpdrachtenParams,
  IVerwijderenOpdrachtenResult,
  // IOphalenDienstenParams,
  // IOphalenDienstenResult,
  IToevoegenProductenAanPendelParams,
  IToevoegenProductenAanPendelResult,
  IVerwijderenRegelsParams,
  IVerwijderenRegelsResult,
  IWijzigenOpdrachtParams,
  IWijzigenOpdrachtResult,
  IAfmeldenRegelsParams,
  IAfmeldenRegelsResult,
  IHerstellenAfmeldenRegelsParams,
  IHerstellenAfmeldenRegelsResult,
  IOphalenOpdrachtregelsParams,
  IPendelOpdrachtregelsResult,
  IVersturenOpdrachtParams,
  IVersturenOpdrachtResult,
  ICheckSelectieVersturenOpdrachtParams,
  IVersturenLaadLosBerichtParams,
  IVersturenLaadLosBerichtResult,
  IMuterenBestandenOpdrachtResult,
  IMuterenBestandenOpdrachtParams,
} from '../../../../../shared/src/api/v2/pendel';
import api from '../../index';
import ICheckData from '../../../../../shared/src/models/ICheckData';

const pendel = {
  ophalenOpdrachten: async (
    params: IOphalenOpdrachtenParams,
  ): Promise<IOphalenOpdrachtenResult> => {
    return await api.post('/v2/pendel/ophalen-opdrachten', params);
  },
  ophalenOpdrachtregels: async (
    params: IOphalenOpdrachtregelsParams,
  ): Promise<IPendelOpdrachtregelsResult> => {
    return await api.post('/v2/pendel/ophalen-opdrachtregels', params);
  },
  checkVerwijderenOpdrachten: async (params: IVerwijderenOpdrachtenParams): Promise<ICheckData> => {
    return await api.post('/v2/pendel/check-verwijderen-opdrachten', params);
  },
  verwijderenOpdrachten: async (
    params: IVerwijderenOpdrachtenParams,
  ): Promise<IVerwijderenOpdrachtenResult> => {
    return await api.post('/v2/pendel/verwijderen-opdrachten', params);
  },
  checkToevoegenProductenAanPendel: async (
    params: IToevoegenProductenAanPendelParams,
  ): Promise<ICheckData> => {
    return await api.post('/v2/pendel/check-toevoegen-producten-aan-pendel', params);
  },
  toevoegenProductenAanPendel: async (
    params: IToevoegenProductenAanPendelParams,
  ): Promise<IToevoegenProductenAanPendelResult> => {
    return await api.post('/v2/pendel/toevoegen-producten-aan-pendel', params);
  },
  checkVerwijderenRegels: async (params: IVerwijderenRegelsParams): Promise<ICheckData> => {
    return await api.post('/v2/pendel/check-verwijderen-regels', params);
  },
  verwijderenRegels: async (
    params: IVerwijderenRegelsParams,
  ): Promise<IVerwijderenRegelsResult> => {
    return await api.post('/v2/pendel/verwijderen-regels', params);
  },
  checkWijzigenOpdracht: async (params: IWijzigenOpdrachtParams): Promise<ICheckData> => {
    return await api.post('/v2/pendel/check-wijzigen-opdracht', params);
  },
  wijzigenOpdracht: async (params: IWijzigenOpdrachtParams): Promise<IWijzigenOpdrachtResult> => {
    return await api.post('/v2/pendel/wijzigen-opdracht', params);
  },
  checkAfmeldenRegels: async (params: IAfmeldenRegelsParams): Promise<ICheckData> => {
    return await api.post('/v2/pendel/check-afmelden-regels', params);
  },
  afmeldenRegels: async (params: IAfmeldenRegelsParams): Promise<IAfmeldenRegelsResult> => {
    return await api.post('/v2/pendel/afmelden-regels', params);
  },
  checkHerstellenAfmeldenRegels: async (
    params: IHerstellenAfmeldenRegelsParams,
  ): Promise<ICheckData> => {
    return await api.post('/v2/pendel/check-herstellen-afmelden-regels', params);
  },
  herstellenAfmeldenRegels: async (
    params: IHerstellenAfmeldenRegelsParams,
  ): Promise<IHerstellenAfmeldenRegelsResult> => {
    return await api.post('/v2/pendel/herstellen-afmelden-regels', params);
  },
  checkSelectieVersturenOpdracht: async (
    params: ICheckSelectieVersturenOpdrachtParams,
  ): Promise<ICheckData> => {
    return await api.post('/v2/pendel/check-selectie-versturen-opdracht', params);
  },
  checkVersturenOpdracht: async (params: IVersturenOpdrachtParams): Promise<ICheckData> => {
    return await api.post('/v2/pendel/check-versturen-opdracht', params);
  },
  versturenOpdracht: async (
    params: IVersturenOpdrachtParams,
  ): Promise<IVersturenOpdrachtResult> => {
    return await api.post('/v2/pendel/versturen-opdracht', params);
  },
  versturenLaadLosBericht: async (
    params: IVersturenLaadLosBerichtParams,
  ): Promise<IVersturenLaadLosBerichtResult> => {
    return await api.post('/v2/pendel/versturen-laad-los-bericht', params);
  },
  muterenBestandenOpdracht: async (
    params: IMuterenBestandenOpdrachtParams,
  ): Promise<IMuterenBestandenOpdrachtResult> => {
    return await api.post('/v2/pendel/muteren-bestanden-opdracht', params);
  },
};

export default pendel;
