import {
  IOphalenVerkoopvoorstellenParams,
  IOphalenVerkoopvoorstellenResult,
  ISelecterenVerkoopProductenParams,
  ISelecterenVerkoopProductenResult,
  IMakenVerkoopvoorstellenParams,
  IMakenVerkoopvoorstellenResult,
  IVerwijderenVerkoopvoorstellenParams,
  IVerwijderenVerkoopvoorstellenResult,
  IVersturenVerkoopvoorstellenParams,
  IVersturenVerkoopvoorstellenResult,
  IVerwerkenVerkoopVoorstellenParams,
  IVerwerkenVerkoopVoorstellenResult,
  IBerekenVerkoopbedragParams,
  IBerekenVerkoopbedragResult,
  IWijzigenVerkoopvoorstelParams,
  IWijzigenVerkoopvoorstelResult,
  IVersturenHerinneringenParams,
  IVersturenHerinneringenResult,
  IWijzigenStatusVerkoopvoorstellenParams,
  IWijzigenStatusVerkoopvoorstellenResult,
  IVersturenBevestigingVerkoopvoorstellenParams,
  IVersturenBevestigingVerkoopvoorstellenResult,
} from '../../../../../shared/src/api/v2/product/verkoop';
import ICheckData from '../../../../../shared/src/models/ICheckData';
import api from '../../index';

const verkoop = {
  ophalenVerkoopvoorstellen: async (
    params: IOphalenVerkoopvoorstellenParams,
  ): Promise<IOphalenVerkoopvoorstellenResult> => {
    return await api.post('v2/product/verkoop/ophalen-verkoop-voorstellen', params);
  },
  selecterenVerkoopProducten: async (
    params: ISelecterenVerkoopProductenParams,
  ): Promise<ISelecterenVerkoopProductenResult> => {
    return await api.post('v2/product/verkoop/selecteren-verkoop-producten', params);
  },
  checkMakenVerkoopVoorstellen: async (
    params: IMakenVerkoopvoorstellenParams,
  ): Promise<ICheckData> => {
    return await api.post('v2/product/verkoop/check-maken-verkoop-voorstellen', params);
  },
  makenVerkoopVoorstellen: async (
    params: IMakenVerkoopvoorstellenParams,
  ): Promise<IMakenVerkoopvoorstellenResult> => {
    return await api.post('v2/product/verkoop/maken-verkoop-voorstellen', params);
  },
  checkVerwijderenVerkoopVoorstellen: async (
    params: IVerwijderenVerkoopvoorstellenParams,
  ): Promise<ICheckData> => {
    return await api.post('v2/product/verkoop/check-verwijderen-verkoop-voorstellen', params);
  },
  verwijderenVerkoopVoorstellen: async (
    params: IVerwijderenVerkoopvoorstellenParams,
  ): Promise<IVerwijderenVerkoopvoorstellenResult> => {
    return await api.post('v2/product/verkoop/verwijderen-verkoop-voorstellen', params);
  },
  checkVersturenVerkoopVoorstellen: async (
    params: IVersturenVerkoopvoorstellenParams,
  ): Promise<ICheckData> => {
    return await api.post('v2/product/verkoop/check-versturen-verkoop-voorstellen', params);
  },
  versturenVerkoopVoorstellen: async (
    params: IVersturenVerkoopvoorstellenParams,
  ): Promise<IVersturenVerkoopvoorstellenResult> => {
    return await api.post('v2/product/verkoop/versturen-verkoop-voorstellen', params);
  },
  checkVerwerkenVerkoopVoorstellen: async (
    params: IVerwerkenVerkoopVoorstellenParams,
  ): Promise<ICheckData> => {
    return await api.post('v2/product/verkoop/check-verwerken-verkoop-voorstellen', params);
  },
  verwerkenVerkoopVoorstellen: async (
    params: IVerwerkenVerkoopVoorstellenParams,
  ): Promise<IVerwerkenVerkoopVoorstellenResult> => {
    return await api.post('v2/product/verkoop/verwerken-verkoop-voorstellen', params);
  },
  berekenVerkoopbedrag: async (
    params: IBerekenVerkoopbedragParams,
  ): Promise<IBerekenVerkoopbedragResult> => {
    return await api.post('v2/product/verkoop/bereken-verkoopbedrag', params);
  },
  checkWijzigenVerkoopvoorstel: async (
    params: IWijzigenVerkoopvoorstelParams,
  ): Promise<ICheckData> => {
    return await api.post('v2/product/verkoop/check-wijzigen-verkoopvoorstel', params);
  },
  wijzigenVerkoopvoorstel: async (
    params: IWijzigenVerkoopvoorstelParams,
  ): Promise<IWijzigenVerkoopvoorstelResult> => {
    return await api.post('v2/product/verkoop/wijzigen-verkoopvoorstel', params);
  },
  checkVersturenHerinneringen: async (
    params: IVersturenHerinneringenParams,
  ): Promise<ICheckData> => {
    return await api.post('v2/product/verkoop/check-versturen-herinneringen', params);
  },
  versturenHerinneringen: async (
    params: IVersturenHerinneringenParams,
  ): Promise<IVersturenHerinneringenResult> => {
    return await api.post('v2/product/verkoop/versturen-herinneringen', params);
  },
  checkWijzigenStatusVerkoopvoorstellen: async (
    params: IWijzigenStatusVerkoopvoorstellenParams,
  ): Promise<ICheckData> => {
    return await api.post('v2/product/verkoop/check-wijzigen-status-verkoopvoorstellen', params);
  },
  wijzigenStatusVerkoopvoorstellen: async (
    params: IWijzigenStatusVerkoopvoorstellenParams,
  ): Promise<IWijzigenStatusVerkoopvoorstellenResult> => {
    return await api.post('v2/product/verkoop/wijzigen-status-verkoopvoorstellen', params);
  },
  checkVersturenBevestigingVerkoopvoorstellen: async (
    params: IVersturenBevestigingVerkoopvoorstellenParams,
  ): Promise<ICheckData> => {
    return await api.post(
      'v2/product/verkoop/check-versturen-bevestiging-verkoopvoorstellen',
      params,
    );
  },
  versturenBevestigingVerkoopvoorstellen: async (
    params: IVersturenBevestigingVerkoopvoorstellenParams,
  ): Promise<IVersturenBevestigingVerkoopvoorstellenResult> => {
    return await api.post('v2/product/verkoop/versturen-bevestiging-verkoopvoorstellen', params);
  },
};

export default verkoop;
