import { makeObservable, observable } from 'mobx';
import { RootStore } from './RootStore';
import { IOphalenRekeningenResultElement } from '../../../shared/src/api/v2/bank/bank';
import api from '../api';

class BankStore {
  public rekeningen: IOphalenRekeningenResultElement[] | null = null;
  // tslint:disable-next-line:variable-name
  private _rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this._rootStore = rootStore;

    makeObservable(this, {
      rekeningen: observable,
    });
  }

  public ophalenRekeningen = async () => {
    this.rekeningen = (
      await api.v2.bank.ophalenRekeningen({ filterSchema: { filters: [] } })
    ).rekeningen;
  };
}

export default BankStore;
