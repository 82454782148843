import React, { useContext, useMemo } from 'react';
import { DashboardContext } from '../index';
import TegelBasis from '../../../components/tegel/TegelBasis';
import { ERemoteDataState } from '../../../models/IRemoteData';
import LoadingSpinner from '../../../components/Gedeeld/LoadingSpinner';
import { format } from 'date-fns';
import GegevensLayout from '../../../components/layout/GegevensLayout';
import VeldWeergave from '../../../components/formulier/VeldWeergave';

interface IProps {}

const Transportinformatie: React.FC<IProps> = (props) => {
  const context = useContext(DashboardContext);

  const aantalOpdrachten = context.transport.data!.aantalOpdrachten;
  const aantalRegels = context.transport.data!.aantalRegels;
  const aantalUitgevoerd = context.transport.data!.aantalUitgevoerd;
  const aantalMislukt = context.transport.data!.aantalMislukt;
  const aantalGeannuleerd = context.transport.data!.aantalGeannuleerd;

  return (
    <TegelBasis titel="Transportopdrachten vandaag">
      {aantalOpdrachten !== 0 ? (
        <>
          <div>
            <GegevensLayout
              gegevens={[
                {
                  label: 'Aantal opdrachten',
                  waarde: `${aantalOpdrachten} (${aantalRegels} regels)`,
                },
                aantalUitgevoerd !== 0
                  ? {
                      label: 'Uitgevoerd',
                      waarde: `${aantalUitgevoerd}`,
                    }
                  : null,
                aantalGeannuleerd !== 0
                  ? {
                      label: 'Geannuleerd',
                      waarde: `${aantalGeannuleerd}`,
                    }
                  : null,
                aantalMislukt !== 0
                  ? {
                      label: 'Mislukt',
                      waarde: `${aantalMislukt}`,
                    }
                  : null,
              ].filter((x) => x !== null)}
            />
          </div>
          {aantalUitgevoerd + aantalMislukt + aantalGeannuleerd === aantalRegels && (
            <div className="mt-2">Alle transportregels zijn afgemeld</div>
          )}
        </>
      ) : (
        <span>Er zijn geen transportopdrachten aanwezig</span>
      )}
    </TegelBasis>
  );
};

export default Transportinformatie;
