import React, { useCallback, useContext, useState, useMemo, useEffect } from 'react';
import { ModalFooter, ModalTitle } from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/ModalHeader';
import api from '../../../api';
import IDialoogProps from '../../../core/IDialoogProps';
import Dialoog from '../../dialogen/Dialoog';
import _ from 'lodash';
import LoadingSpinner from '../../Gedeeld/LoadingSpinner';
import Tabblad, { ITabblad } from '../../layout/Tabblad';
import BoekingenTab from './BoekingenTab';
import { IOphalenRelatiesResultElementV2 } from '../../../../../shared/src/api/v2/relatie';
import { IOphalenBoekingRegelsResultElement } from '../../../../../shared/src/api/v2/boekhouding/boeking';
import { IOphalenMutatiesResultElement } from '../../../../../shared/src/api/v2/bank/mutaties';
import { IOphalenOpdrachtenResultElement } from '../../../../../shared/src/api/v2/bank/opdracht/opdracht';
import { IOphalenBetalingsregelingenResultElement } from '../../../../../shared/src/api/v2/debiteur/betalingsregeling';
import { IFilter, IFilterData, maakFilterSchema } from '../../FilterBalkV2';
import { IOphalenDagboekSoortenResultElement } from '../../../../../shared/src/api/v2/boekhouding/boeking/dagboek';
import { IFilterSchema } from '../../../../../shared/src/models/filter';
import useUrlState from '../../../core/useUrlState';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import MultiSelect from '../../formulier/MultiSelect';
import {
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../helpers/dxTableGrid';
import { Grid, Table, TableHeaderRow } from '@devexpress/dx-react-grid-bootstrap4';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { format } from 'date-fns';
import nameOf from '../../../core/nameOf';
import FormatteerBedrag from '../../MutatieBedrag';
import FactuurVisualisatie from '../../entiteitVisualisaties/FactuurVisualisatie';
import InkoopfactuurVisualisatie from '../../entiteitVisualisaties/InkoopfactuurVisualisatie';

interface IProps extends RouteComponentProps, IDialoogProps<{}> {
  relID: number;
}

export enum EFilter {
  Dagboeksoort = 'IDS',
}

enum ETablad {
  Boekingen,
}

interface IUrlState {
  filterData: IFilterData<EFilter>[];
}

export interface IContext {
  relatie: IOphalenRelatiesResultElementV2 | null;
  boekingregels: IRegel[] | null;
  // bankmutaties: IOphalenMutatiesResultElement[] | null;
  bankopdrachten: IOphalenOpdrachtenResultElement[] | null;
}

export const BoekingenContext = React.createContext<IContext>({
  relatie: null,
  boekingregels: null,
  // bankmutaties: null,
  bankopdrachten: null,
});

export interface IRegel extends IOphalenBoekingRegelsResultElement {}

const BoekingenInfoDialoog: React.FC<IProps> = (props) => {
  const { dialoogIndex, onAnnuleren, onSuccess, open, relID } = props;

  const defaultUrlState = useMemo<IUrlState>(() => {
    const dagboekIDs = [1, 2]; // TODO Dennis

    return {
      filterData: [
        {
          naam: EFilter.Dagboeksoort,
          data: [1],
          isActief: false,
        },
      ],
    };
  }, []);

  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);

  const [tabblad, setTabblad] = useState<ETablad>(ETablad.Boekingen);
  const [relatie, setRelatie] = useState<IOphalenRelatiesResultElementV2 | null>(null);

  const [boekingregels, setBoekingregels] = useState<IOphalenBoekingRegelsResultElement[] | null>(
    null,
  );
  // const [bankmutaties, setBankmutaties] = useState<IOphalenMutatiesResultElement[] | null>(null);
  const [bankopdrachten, setBankopdrachten] = useState<IOphalenOpdrachtenResultElement[] | null>(
    null,
  );

  const [dagboeksoorten, setDagboeksoorten] = useState<
    IOphalenDagboekSoortenResultElement[] | null
  >(null);

  const tabbladen = useMemo<ITabblad<ETablad>[]>(
    () => [
      {
        id: ETablad.Boekingen,
        label: 'Boekingen',
        content: BoekingenTab,
      },
    ],
    [onSuccess],
  );

  const ophalenRelatie = useCallback(async () => {
    const relatie = (
      await api.v2.relatie.ophalenRelaties({
        filterSchema: { filters: [{ naam: 'IDS', data: [relID] }] },
      })
    ).relaties[0];

    setRelatie(relatie);
  }, [relID]);

  useEffect(() => {
    ophalenRelatie();
  }, [ophalenRelatie]);

  // const ophalenBoekingregels = useCallback(async () => {
  //   const regelsResult = (
  //     await api.v2.boeking.ophalenBoekingregels({
  //       filterSchema: {
  //         filters: [
  //           {
  //             naam: 'REL_IDS',
  //             data: [props.relID],
  //           },
  //         ],
  //       },
  //       orderSchema: {
  //         orders: [
  //           { naam: 'BOEKDATUM', richting: 'DESC' },
  //           { naam: 'BOEKNUMMER', richting: 'DESC' },
  //           { naam: 'REGELNUMMER', richting: 'DESC' },
  //         ],
  //       },
  //     })
  //   ).regels;

  //   setBoekingregels(regelsResult);
  // }, [props.relID]);

  // useEffect(() => {
  //   ophalenBoekingregels();
  // }, [ophalenBoekingregels]);

  const [filterSchema, setFilterSchema] = useState<IFilterSchema>(
    useMemo(() => maakFilterSchema(urlState.filterData), []),
  );

  const ophalenDagboeksoorten = useCallback(async () => {
    const result = (
      await api.v2.boeking.dagboek.ophalenDagboekSoorten({
        filterSchema: {
          // filters: [...filterSchema.filters!],
        },
        // orderSchema: {
        //   orders: [{ naam: 'SORTNR', richting: 'ASC' }],
        // },
      })
    ).soorten;
    setDagboeksoorten(result);
  }, [filterSchema.filters!]);

  useEffect(() => {
    ophalenDagboeksoorten();
  }, [ophalenDagboeksoorten]);

  const ophalenBankopdrachten = useCallback(async () => {
    if (boekingregels === null) {
      return;
    }

    const bankOpdIDs = _.uniq(
      boekingregels.filter((x) => x.BankOpdID !== null).map((x) => x.BankOpdID),
    );

    const regelsResult = (
      await api.v2.bank.opdracht.ophalenOpdrachten({
        filterSchema: {
          filters: [
            {
              naam: 'IDS',
              data: bankOpdIDs,
            },
          ],
        },
      })
    ).opdrachten;

    setBankopdrachten(regelsResult);
  }, [boekingregels]);

  useEffect(() => {
    ophalenBankopdrachten();
  }, [ophalenBankopdrachten]);

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.Dagboeksoort,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          if (dagboeksoorten === null) {
            return null;
          }

          return (
            <span className="d-flex align-items-center">
              <span className="mr-3">Dagboeken</span>
              {false === null ? (
                <LoadingSpinner />
              ) : (
                <MultiSelect
                  value={weergaveProps.data}
                  onChange={(data) => {
                    weergaveProps.onDataChange(data);
                    weergaveProps.toepassen();
                  }}
                  // opties={grootboekrekeningenFilterOpties}
                  // opties={grootboekrekeningenFilterOpties.map(
                  //   (status): IOptie<number> => {
                  //     return { key: status.ID, weergave: status.Naam };
                  //   },
                  // )}
                  opties={dagboeksoorten.map((x) => ({
                    key: x.ID,
                    weergave: x.Naam,
                  }))}
                  isVerwijderbaar={false}
                />
              )}
            </span>
          );
        },
      },
    ],
    [dagboeksoorten],
  );

  if (relatie === null) {
    return <LoadingSpinner />;
  }

  return (
    <Dialoog index={props.dialoogIndex ?? 0} modalProps={{ size: 'xl' }}>
      <ModalHeader>
        <ModalTitle>Boekingsinformatie voor relatie</ModalTitle>
      </ModalHeader>
      <BoekingenContext.Provider
        value={{
          relatie,
          boekingregels,
          bankopdrachten,
        }}
      >
        <div className="d-flex flex-column flex-fill" style={{ minHeight: `550px` }}>
          <>
            <div className="d-flex flex-column flex-fill">
              <Tabblad
                tabbladen={tabbladen}
                geselecteerd={tabblad}
                onSelectieChange={(id) => {
                  setTabblad(id);
                }}
              />
            </div>
            <ModalFooter className="d-flex flex-row justify-content-start">
              <button className="btn btn-primary" onClick={onAnnuleren} style={{ width: 100 }}>
                Sluiten
              </button>
            </ModalFooter>
          </>
        </div>
      </BoekingenContext.Provider>
    </Dialoog>
  );
};

export default withRouter(BoekingenInfoDialoog);
