import { RouteComponentProps, withRouter } from 'react-router';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import useUrlState from '../../../core/useUrlState';
import Tabblad, { ITabblad } from '../../../components/layout/Tabblad';
import { Root } from '../Aanbod/style';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../stores/RootStore';
import { matchPath, Redirect, Route } from 'react-router-dom';
import NieuwTab from './NieuwTab';
import Uitstaand from './UitstaandTab';
import Afgehandeld from './AfgehandeldTab';
import Reserveringen from './ReserveringTab';
import Parameters from './ParametersTab';
import InslagMutaties from './InslagMutatiesTab';

export enum ETabblad {
  Nieuw = 1,
  Uitstaand = 2,
  Afgehandeld = 3,
  Reserveringen = 4,
  Parameters = 5,
  InslagMutaties = 6,
}

const urlTabbladMap: Record<ETabblad, string> = {
  [ETabblad.Nieuw]: 'nieuw',
  [ETabblad.Uitstaand]: 'uitstaand',
  [ETabblad.Afgehandeld]: 'afgehandeld',
  [ETabblad.Reserveringen]: 'reserveringen',
  [ETabblad.Parameters]: 'parameters',
  [ETabblad.InslagMutaties]: 'inslag-mutaties',
};

interface IUrlState {}

const defaultUrlState: IUrlState = {};

interface IProps extends RouteComponentProps {}

export interface IInkoopopdrachtenContext {
  verversen: () => void;
}
export const InkoopopdrachtenContext = React.createContext<IInkoopopdrachtenContext>({
  verversen: () => {},
});

export interface ITabbladProps {
  selectie: number[];
  onSelectieChange: (value: number[]) => void;
}

const Opdrachten: React.FC<IProps> = observer((props) => {
  const { instellingStore } = useContext(RootStoreContext);
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);

  const tabbladen = useMemo<ITabblad<ETabblad>[]>(
    () => [
      {
        id: ETabblad.Nieuw,
        label: 'Nieuw',
        content: NieuwTab,
      },
      {
        id: ETabblad.Uitstaand,
        label: 'Uitstaand',
        content: Uitstaand,
      },
      {
        id: ETabblad.Afgehandeld,
        label: 'Afgehandeld',
        content: Afgehandeld,
      },
      {
        id: ETabblad.InslagMutaties,
        label: 'Inslagmutaties',
        content: InslagMutaties,
      },
      {
        id: ETabblad.Reserveringen,
        label: 'Reserveringen',
        content: Reserveringen,
      },
      {
        id: ETabblad.Parameters,
        label: 'Inkoopparameters',
        content: Parameters,
      },
    ],
    [],
  );

  const tabblad = useMemo<ETabblad | null>(() => {
    const paths = Object.keys(urlTabbladMap).map((key) => {
      const tabblad = Number(key) as ETabblad;
      const url = urlTabbladMap[tabblad];
      return {
        tabblad,
        path: `${props.match.path}/${url}`,
      };
    });
    for (const pathObj of paths) {
      const match = matchPath(props.location.pathname, pathObj.path);
      if (match !== null) {
        return pathObj.tabblad;
      }
    }

    return null;
  }, [props.location.pathname]);

  return (
    <>
      <Route
        exact
        path={props.match.path}
        component={() => <Redirect to={`${props.match.path}/${urlTabbladMap[ETabblad.Nieuw]}`} />}
      />
      {tabblad === null ? (
        <Redirect to={`${props.match.path}/${urlTabbladMap[ETabblad.Uitstaand]}`} />
      ) : (
        <Root className="d-flex flex-column flex-fill">
          <Tabblad
            geselecteerd={tabblad}
            onSelectieChange={(identifier) =>
              props.history.push(`${props.match.path}/${urlTabbladMap[identifier]}`)
            }
            tabbladen={tabbladen}
          />
        </Root>
      )}
    </>
  );
});

export default withRouter(Opdrachten);
