import styled from 'styled-components';
import { boxShadow, colors } from '../../../../globalstyles/variables';

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const KlantkaartInfoSectie = styled.div`
  background-color: white;
  border-radius: 4px;
  box-shadow: ${boxShadow};
  flex: 0 0 auto;
`;

export const Relatienummer = styled.span`
  font-size: 14px;
  font-weight: bold;
  // color: ${colors.primaryBlue};
`;

export const HeaderIcon = styled.div``;

export const Header = styled.header`
  //padding: 0.75rem 1.25rem;
   border-top: 1px solid ${colors.borderGrey};
  // border-bottom: 1px solid ${colors.borderGrey};
  //margin-bottom: 10px;
  h5 {
    margin: 3px 0 0 5px;
    padding: 0;
  }
  svg {
    padding: 0;
  }
`;

export const Adres = styled.p`
  margin: 0;
  display: flex;
  align-items: center;
`;

export const DropdownWrapper = styled.div`
  flex: 1;
  text-align: right;
`;
