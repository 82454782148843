import React, { useMemo } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import api from '../../../api';
import { genereerUrlStateQueryParam } from '../../../core/useUrlState';
import { IUrlState } from '../../Communicatie/Contactverzoek';
import { IActieProps } from '../index';

interface IData {
  ID: number;
}

const MIJN_GEGEVENS_WIJZIGING: React.VoidFunctionComponent<IActieProps> = (props: IActieProps) => {
  const data = useMemo(() => JSON.parse(props.controltaak.Data!) as IData, [
    props.controltaak.Data,
  ]);

  return (
    <a
      href="#"
      onClick={async () => {
        const mutatie = (
          await api.v2.relatie.ophalenMutaties({
            filterSchema: { filters: [{ naam: 'IDS', data: [data.ID] }] },
          })
        ).mutaties[0];
        // const state = genereerUrlStateQueryParam<IUrlState>({
        //   selectie: [],
        //   uitgeklapt: [data.id],
        //   scrollenNaarID: data.id,
        // });

        props.history.push(`/klant/${mutatie.RelID}/klant/mutaties`);
      }}
    >
      Naar mutaties
    </a>
  );
};

export default MIJN_GEGEVENS_WIJZIGING;
