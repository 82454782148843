import React, { useCallback, useMemo } from 'react';
import { DataTypeProvider, TableRowDetail } from '@devexpress/dx-react-grid';
import TabelInspringBlok from '../../../layout/TabelInspringBlok';
import {
  IOphalenIncassozakenResultElement,
  IOphalenFacturenResultElement,
} from '../../../../../../shared/src/api/v2/debiteur/incassozaak';
import {
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import {
  Grid,
  Table,
  TableColumnResizing,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-bootstrap4';
import { VirtualTable as VirtualTableBase } from '@devexpress/dx-react-grid';
import { format } from 'date-fns';
import FormatteerBedrag from '../../../MutatieBedrag';
import nameof from '../../../../core/nameOf';
import nameOf from '../../../../core/nameOf';

const DetailComp = (props: TableRowDetail.ContentProps) => {
  const row: IOphalenIncassozakenResultElement = props.row;

  const kolommen = useMemo<TypedColumn<IOphalenFacturenResultElement>[]>(
    () => [
      {
        name: 'Factuurnummer',
        title: 'Fact.nr.',
      },
      {
        name: 'Factuurdatum',
        title: 'Datum',
      },
      {
        name: 'Bedrag',
        title: 'Fact.bedrag',
      },
      {
        name: 'BedragInZaak',
        title: 'Bedrag in zaak',
      },
      {
        name: 'Openstaand',
        title: 'Openstaand',
      },
      {
        name: 'DatumToegevoegdAanZaak',
        title: 'Toegevoegd op',
      },
      {
        name: 'DatumVerstuurd',
        title: 'Verstuurd op',
      },
    ],
    [],
  );
  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IOphalenFacturenResultElement>[]>(
    () => [
      {
        columnName: 'Factuurnummer',
        width: 100,
      },
      {
        columnName: 'Factuurdatum',
        title: 'Datum',
        width: 100,
      },
      {
        columnName: 'Bedrag',
        width: 100,
      },
      {
        columnName: 'Openstaand',
        width: 100,
      },
      {
        columnName: 'BedragInZaak',
        width: 150,
      },
      {
        columnName: 'DatumToegevoegdAanZaak',
        width: 125,
      },
      {
        columnName: 'DatumVerstuurd',
        width: 125,
      },
    ],
    [],
  );

  const keyExtractor = useCallback((factuur: IOphalenFacturenResultElement) => factuur.FactID, []);

  const kolomExtensies: VirtualTableBase.ColumnExtension[] = useMemo(() => {
    return [
      // {
      //   columnName: `Bedrag`,
      //   align: 'right',
      // },
      // {
      //   columnName: `Openstaand`,
      //   align: 'right',
      // },
      // {
      //   columnName: `BedragInZaak`,
      //   align: 'right',
      // },
    ];
  }, []);

  return (
    <div className="d-flex">
      <TabelInspringBlok />
      <GridStyleWrapper maxHeight={500} rowAmount={row.facturen.length}>
        <Grid rows={row.facturen} columns={kolommen} getRowId={keyExtractor}>
          <DataTypeProvider
            for={['Factuurdatum']}
            formatterComponent={(formatterProps) => {
              const rij: IOphalenFacturenResultElement = formatterProps.row;
              return <span>{format(new Date(rij.Factuurdatum), 'dd-MM-yyyy')}</span>;
            }}
          />

          <DataTypeProvider
            for={[nameOf<IOphalenFacturenResultElement>('DatumToegevoegdAanZaak')]}
            formatterComponent={(formatterProps) => {
              const rij: IOphalenFacturenResultElement = formatterProps.row;
              return (
                <span>
                  {formatterProps.value !== null
                    ? format(new Date(formatterProps.value), 'dd-MM-yyyy')
                    : ''}
                </span>
              );
            }}
          />

          <DataTypeProvider
            for={[nameOf<IOphalenFacturenResultElement>('DatumVerstuurd')]}
            formatterComponent={(formatterProps) => {
              const rij: IOphalenFacturenResultElement = formatterProps.row;
              return (
                <span>
                  {formatterProps.value !== null
                    ? format(new Date(formatterProps.value), 'dd-MM-yyyy')
                    : ''}
                </span>
              );
            }}
          />

          <DataTypeProvider
            for={[
              nameof<IOphalenFacturenResultElement>('Bedrag'),
              nameof<IOphalenFacturenResultElement>('Openstaand'),
              nameof<IOphalenFacturenResultElement>('BedragInZaak'),
            ]}
            formatterComponent={(formatterProps) => {
              return <FormatteerBedrag bedrag={formatterProps.value} />;
            }}
          />

          <Table columnExtensions={kolomExtensies} />
          <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
          {/* <TableHeaderRow showSortingControls /> */}
          <TableHeaderRow />
        </Grid>
      </GridStyleWrapper>
    </div>
  );
};

export default DetailComp;
