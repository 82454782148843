import React, { useMemo } from 'react';
import Tabblad, { ITabblad } from '../../../../../../components/layout/Tabblad';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IEntiteitProps } from '../../../../../../components/kaart/EntiteitContainer';
import Betalingsregeling from './Betalingsregeling';
import Incassozaken from './Incassozaken';
import Herincassozaken from './Herincassozaken';
import Rechtzaken from './Rechtzaken';

interface IProps extends IEntiteitProps, RouteComponentProps {}

export enum ETabblad {
  Betalingsregelingen = 1,
  Incassozaken = 2,
  Herincassozaken = 3,
  Rechtzaken = 4,
}

const pathMap: Record<ETabblad, string> = {
  [ETabblad.Betalingsregelingen]: '/betalingsregelingen',
  [ETabblad.Incassozaken]: '/incassozaken',
  [ETabblad.Rechtzaken]: '/rechtzaken',
  [ETabblad.Herincassozaken]: '/herincassozaken',
};

const Debiteur: React.FC<IProps> = (props) => {
  const tabblad = useMemo<ETabblad | null>(() => {
    const matchKey = Object.keys(pathMap).find((key) => {
      const path = pathMap[Number(key) as ETabblad]!;
      return props.location.pathname.endsWith(path);
    });
    if (matchKey === undefined) {
      props.history.push(`${props.match.path}${pathMap[ETabblad.Betalingsregelingen]}`);
      return null;
    }
    return Number(matchKey) as ETabblad;
  }, [props.location.pathname]);

  const tabbladen = useMemo<ITabblad<ETabblad>[]>(
    () => [
      {
        id: ETabblad.Betalingsregelingen,
        label: 'Betalingsregelingen',
        content: Betalingsregeling,
      },
      {
        id: ETabblad.Incassozaken,
        label: 'Incassozaken',
        content: Incassozaken,
      },
      {
        id: ETabblad.Herincassozaken,
        label: 'Herincassozaken',
        content: Herincassozaken,
      },
      {
        id: ETabblad.Rechtzaken,
        label: 'Rechtzaken',
        content: Rechtzaken,
      },
    ],
    [],
  );

  return (
    <div className="d-flex flex-column flex-fill flex-fill">
      {tabblad !== null && (
        <Tabblad<ETabblad>
          geselecteerd={tabblad}
          onSelectieChange={(tab) => {
            props.history.push(
              `${props.location.pathname.replace(pathMap[tabblad], '')}${pathMap[tab]}`,
            );
          }}
          tabbladen={tabbladen}
          options={{
            tabbladComponentProps: {
              relID: props.relID,
            },
          }}
        />
      )}
    </div>
  );
};

export default withRouter(Debiteur);
