import {
  ICorrigerenSaldoParams,
  ICorrigerenSaldoResult,
} from '../../../../../shared/src/api/v2/bank/rekening';
import api from '../../index';
import ICheckData from '../../../../../shared/src/models/ICheckData';

const rekening = {
  async corrigerenSaldo(params: ICorrigerenSaldoParams): Promise<ICorrigerenSaldoResult> {
    return await api.post('v2/bank/rekening/corrigeren-saldo', params);
  },
};

export default rekening;
