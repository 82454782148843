import React, { useContext, useMemo, useState } from 'react';
import VeldWeergave from '../../formulier/VeldWeergave';
import LoadingSpinner from '../../Gedeeld/LoadingSpinner';
import { format } from 'date-fns';
import GegevensLayout from '../../layout/GegevensLayout';
import {
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../helpers/dxTableGrid';
import { Kleur as EKleur } from '../../../bedrijfslogica/constanten';
import { Grid, TableHeaderRow, VirtualTable } from '@devexpress/dx-react-grid-bootstrap4';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import nameOf from '../../../core/nameOf';
import { IconInformatie } from '../../Icons';
import _ from 'lodash';
import { IOphalenOpdrachtenResultElement } from '../../../../../shared/src/api/v2/service/opdracht';
import OpdrachtInfoDialoog from '../OpdrachtInfoDialoog';
import FormatteerBedrag from '../../MutatieBedrag';

interface IProps {
  opdrachten: IOphalenOpdrachtenResultElement[];
}

interface IOpdrachtInfoDialoogState {
  id: number;
}

const OpdrachtenComp: React.FC<IProps> = (props) => {
  const [
    opdrachtInfoDialoogState,
    setOpdrachtInfoDialoogState,
  ] = useState<IOpdrachtInfoDialoogState | null>(null);

  const kolommenOpdracht = useMemo<TypedColumn<IOphalenOpdrachtenResultElement>[]>(
    () => [
      // {
      //   name: 'DatumVerstuurd',
      //   title: 'Verstuurd',
      // },
      {
        name: '__datum' as any,
        title: 'Bezoek',
      },
      {
        name: '__werkzaamheden' as any,
        title: 'Werkzaamheden',
      },
      {
        name: '__kosten' as any,
        title: 'Kosten',
      },
      {
        name: '__status' as any,
        title: 'Status',
      },
      {
        name: '__info' as any,
        title: ' ',
      },
    ],
    [],
  );

  const kolombreedtesOpdracht = useMemo<
    TypedTableColumnWidthInfo<IOphalenOpdrachtenResultElement>[]
  >(
    () => [
      // {
      //   columnName: 'DatumVerstuurd',
      //   width: 100,
      // },
      {
        columnName: '__datum' as any,
        width: 90,
      },
      {
        columnName: '__werkzaamheden' as any,
        width: 400,
      },
      {
        columnName: '__kosten' as any,
        width: 100,
      },
      {
        columnName: '__status' as any,
        width: 100,
      },
      {
        columnName: '__info' as any,
        width: 30,
      },
    ],
    [],
  );

  return (
    <>
      <VeldWeergave>
        <div className="p-1 font-weight-bold">Serviceopdrachten</div>
        <div className="mt-1" style={{ backgroundColor: EKleur.Wit }}>
          <GridStyleWrapper maxHeight={150} rowAmount={props.opdrachten.length}>
            <Grid rows={props.opdrachten} columns={kolommenOpdracht}>
              {/* <DataTypeProvider
                    for={[nameof<IOphalenOpdrachtenResultElement>('DatumVerstuurd')]}
                    formatterComponent={(formatterProps) => (
                      <span>
                        {formatterProps.value === null
                          ? null
                          : format(new Date(formatterProps.value), 'dd-MM-yyyy')}
                      </span>
                    )}
                  /> */}

              <DataTypeProvider
                for={['__datum']}
                formatterComponent={(formatterProps) => {
                  const rij: IOphalenOpdrachtenResultElement = formatterProps.row;

                  // Werkbonnen met een gevulde bezoekdatum
                  const werkbonnen = rij.werkbonnen.filter((x) => x.Bezoekdatum !== null);

                  if (werkbonnen.length === 0) {
                    return <span></span>;
                  }
                  const datum = _.max(werkbonnen.map((x) => x.Bezoekdatum));
                  return <span>{format(new Date(datum), 'dd-MM-yyyy')}</span>;
                }}
              />

              <DataTypeProvider
                for={['__werkzaamheden']}
                formatterComponent={(formatterProps) => {
                  const rij: IOphalenOpdrachtenResultElement = formatterProps.row;
                  const werkbon = rij.werkbonnen.length > 0 ? rij.werkbonnen[0] : null;
                  if (werkbon === null) {
                    return <span>Geen werkzaamheden bekend</span>;
                  }
                  return <span>{werkbon.Werkzaamheden}</span>;
                }}
              />

              <DataTypeProvider
                for={['__status']}
                formatterComponent={(formatterProps) => {
                  const rij: IOphalenOpdrachtenResultElement = formatterProps.row;
                  return <span>{rij.status.Naam}</span>;
                }}
              />

              <DataTypeProvider
                for={['__kosten']}
                formatterComponent={(formatterProps) => {
                  const rij: IOphalenOpdrachtenResultElement = formatterProps.row;
                  const kosten = _.sum(rij.werkbonnen.map((x) => x.KostenTotaal));
                  return <FormatteerBedrag bedrag={kosten} />;
                }}
              />

              <DataTypeProvider
                for={['__info']}
                formatterComponent={(formatterProps) => {
                  const rij: IOphalenOpdrachtenResultElement = formatterProps.row;
                  return (
                    <a
                      href="#"
                      onClick={(ev) => {
                        ev.stopPropagation();
                        setOpdrachtInfoDialoogState({ id: rij.ServOpdID });
                      }}
                    >
                      <IconInformatie style={{ width: 15, height: 15, fill: EKleur.Blauw }} />
                    </a>
                  );
                }}
              />

              <VirtualTable
                columnExtensions={kolombreedtesOpdracht}
                messages={{ noData: 'Geen serviceopdrachten bekend' }}
              />
              <TableHeaderRow />
            </Grid>
          </GridStyleWrapper>
        </div>
      </VeldWeergave>

      {opdrachtInfoDialoogState !== null && (
        <OpdrachtInfoDialoog
          id={opdrachtInfoDialoogState.id}
          open
          onSuccess={() => setOpdrachtInfoDialoogState(null)}
          onAnnuleren={() => setOpdrachtInfoDialoogState(null)}
        />
      )}
    </>
  );
};

export default OpdrachtenComp;
