import { ERegelstate, Regel, IRegelfocusbaar, ERegelfocusbaarOp } from '../types';
import { maakNieuweRegel } from '../helpers';
import { floatNaarBedrag } from '../../../../../bedrijfslogica/bedrag';

export interface IRegelModificatieNaOpslaanProviderParams {
  opgeslagenRegel: Regel;
  regels: Regel[];
}

export interface IRegelModificatieNaOpslaanProviderOutput {
  regels: Regel[];
  regelfocusbaar?: IRegelfocusbaar;
}

export interface IRegelModificatieNaOpslaanProvider {
  provide: (
    params: IRegelModificatieNaOpslaanProviderParams,
  ) => Promise<IRegelModificatieNaOpslaanProviderOutput>;
}

export const standaardRegelModificatieNaOpslaanProvider: IRegelModificatieNaOpslaanProvider = {
  provide: async (params): Promise<IRegelModificatieNaOpslaanProviderOutput> => {
    const nogTeBoeken = params.regels.reduce((acc, curr) => floatNaarBedrag(acc + curr.bedrag), 0);
    if (nogTeBoeken !== 0) {
      const nieuweRegel = maakNieuweRegel();
      nieuweRegel.state = ERegelstate.Muteren;
      nieuweRegel.bedrag = -nogTeBoeken;
      return {
        regels: [...params.regels, nieuweRegel],
        regelfocusbaar: {
          regelID: nieuweRegel.id,
          op: ERegelfocusbaarOp.Grootboek,
        },
      };
    }

    return {
      regels: params.regels,
    };
  },
};
