import React, { useState, useEffect, useCallback, useMemo, useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { LeverancierContext } from '../../../../index';
import {
  IOphalenBezoeksetregelsResultElement,
  IOphalenBezoeksetsResultElement,
} from '../../../../../../../../shared/src/api/v2/transport/bezoek';
import api from '../../../../../../api';
import MenuLayout from '../../../../../../components/MenuLayout';
import { IconToevoegen } from '../../../../../../components/Icons';
import { Kleur } from '../../../../../../bedrijfslogica/constanten';
import LoadingSpinner from '../../../../../../components/Gedeeld/LoadingSpinner';
import {
  DXCommandComponent,
  DXTableToggleCellComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../../../helpers/dxTableGrid';
import { DataTypeProvider, EditingState, RowDetailState } from '@devexpress/dx-react-grid';

import {
  Grid,
  TableHeaderRow,
  VirtualTable,
  TableSelection,
  TableColumnResizing,
  TableRowDetail,
  TableGroupRow,
  TableEditColumn,
} from '@devexpress/dx-react-grid-bootstrap4';
import { format } from 'date-fns';
import nameOf from '../../../../../../core/nameOf';
import _ from 'lodash';
import { IOphalenBestandoverdrachtenResultElement } from '../../../../../../../../shared/src/api/v2/transport/opdracht';
import DownloadKnop from '../../../../../../components/DownloadKnop';
import TransportopdrachtVisualisatie from '../../../../../../components/entiteitVisualisaties/TransportopdrachtVisualisatie';

interface IProps extends RouteComponentProps {}

export interface IRegel extends IOphalenBestandoverdrachtenResultElement {}

const Bestandsoverdrachten: React.FC<IProps> = observer((props) => {
  const context = useContext(LeverancierContext);

  const [overdrachten, setOverdrachten] = useState<IRegel[] | null>(null);

  const ophalenOverdrachten = useCallback(async () => {
    const overdrachtenResult = (
      await api.v2.transport.opdracht.ophalenBestandsoverdrachten({
        filterSchema: {
          filters: [
            {
              naam: 'TRSDIENST_IDS',
              data: [context.transportdienst.data!.ID],
            },
          ],
        },
      })
    ).overdrachten;

    setOverdrachten(overdrachtenResult);
  }, []);

  useEffect(() => {
    ophalenOverdrachten();
  }, [ophalenOverdrachten]);

  const kolommen = useMemo<TypedColumn<IRegel>[]>(
    () => [
      {
        name: 'Datum',
        title: 'Datum',
      },
      {
        name: '__bestandsnaam' as any,
        title: 'Naam bestand',
      },
      {
        name: '__bestand' as any,
        title: 'Bestand',
      },
      {
        name: 'Resultaat' as any,
        title: 'Resultaat',
      },
      {
        name: '__opdrachten' as any,
        title: 'Aantal opdrachten',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRegel>[]>(
    () => [
      {
        columnName: 'Datum',
        width: 160,
      },
      {
        columnName: '__bestand' as any,
        width: 80,
      },
      {
        columnName: '__bestandsnaam' as any,
        width: 350,
      },
      {
        columnName: 'Resultaat' as any,
        width: 150,
      },
      {
        columnName: '__opdrachten' as any,
        width: 250,
      },
    ],
    [],
  );

  return (
    <>
      <MenuLayout
        menu={
          <div className="d-flex">
            <button
              className="btn btn-sm btn-light d-flex align-items-center"
              style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
              onClick={() => null!}
            >
              <IconToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
              <span className="ml-2">Opnieuw versturen</span>
            </button>
          </div>
        }
        body={
          <div>
            {overdrachten === null ? (
              <LoadingSpinner />
            ) : (
              <GridStyleWrapper height="calc(100vh - 125px)">
                <Grid columns={kolommen} rows={overdrachten || []} getRowId={(x) => x.ID}>
                  <DataTypeProvider
                    for={[nameOf<IRegel>('Datum')]}
                    formatterComponent={(formatterProps) => {
                      return (
                        <span>{format(new Date(formatterProps.value), 'dd-MM-yyyy HH:mm:ss')}</span>
                      );
                    }}
                  />

                  <DataTypeProvider
                    for={['__bestand']}
                    formatterComponent={(formatterProps) => {
                      const rij: IRegel = formatterProps.row;
                      return (
                        <>
                          <DownloadKnop
                            onDownloadAangevraagd={async () => {
                              const downloadLink = rij.bestand.url;
                              // await downloadUrl(downloadLink, rij.bestand.Naam);
                              window.open(downloadLink, '_blank');
                            }}
                          />
                        </>
                      );
                    }}
                  />

                  <DataTypeProvider
                    for={['__bestandsnaam']}
                    formatterComponent={(formatterProps) => {
                      const rij: IRegel = formatterProps.row;
                      return <span>{rij.bestand.Naam}</span>;
                    }}
                  />

                  <DataTypeProvider
                    for={[nameOf<IRegel>('Resultaat')]}
                    formatterComponent={(formatterProps) => {
                      const rij: IRegel = formatterProps.row;

                      if (rij.Resultaat === 1) {
                        return <span>Ok</span>;
                      }
                      if (rij.Resultaat === 2) {
                        return <span>FTP is niet toegestaan</span>;
                      }

                      return <span>Onbekend</span>;
                    }}
                  />

                  <DataTypeProvider
                    for={['__opdrachten']}
                    formatterComponent={(formatterProps) => {
                      const rij: IRegel = formatterProps.row;

                      // return (
                      //   <span className="d-flex">
                      //     <TransportopdrachtVisualisatie trsOpdID={1} />,
                      //     <TransportopdrachtVisualisatie trsOpdID={1} />,
                      //     <TransportopdrachtVisualisatie trsOpdID={1} />
                      //   </span>
                      // );

                      return <span>{rij.trsOpdIDs.length}</span>;
                    }}
                  />

                  <VirtualTable
                    estimatedRowHeight={43}
                    messages={{ noData: 'Geen bestandsoverdrachten' }}
                  />

                  <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
                  <TableHeaderRow />

                  <EditingState
                    onAddedRowsChange={() => {}}
                    onEditingRowIdsChange={(x) => {
                      const id = x[x.length - 1] as number;
                    }}
                    onCommitChanges={() => null}
                  />
                  {/* <TableEditColumn
                    width={65}
                    // showAddCommand={true}
                    showEditCommand
                    // showDeleteCommand
                    commandComponent={DXCommandComponent}
                  /> */}

                  {/* <SelectionState
                    selection={urlState.selectie}
                    onSelectionChange={(x) => setUrlStateSync('selectie', x as number[])}
                  />
                  <TableSelection cellComponent={DXTableCheckboxComponent} /> */}
                </Grid>
              </GridStyleWrapper>
            )}
          </div>
        }
      />
    </>
  );
});

export default withRouter(Bestandsoverdrachten);
