import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import IDialoogProps from '../../../../../core/IDialoogProps';
import MergeDialoog, { IMergeDialoogOutput } from '../../../../../components/MergeDialoog';
import {
  EDataType,
  IEntiteit,
  IMergeDialoogVeld,
  IVastGegeven,
} from '../../../../../components/MergeDialoog/types';
import api from '../../../../../api';
import PersoonVisualisatie from '../../../../../components/personalia/PersoonVisualisatie';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../../models/IRemoteData';
import { IOphalenPersonenResult } from '../../../../../../../shared/src/api/v2/persoon/persoon';
import {
  StandaardBooleanInput,
  StandaardMergeDialoogBooleanFormatter,
  StandaardMergeDialoogDateFormatter,
  StandaardMergeDialoogDateInput,
  StandaardMergeDialoogTextFormatter,
  StandaardMergeDialoogTextInput,
} from '../../../../../components/MergeDialoog/helpers';
import { observer } from 'mobx-react-lite';
import { IOphalenGeslachtenResult } from '../../../../../../../shared/src/api/v2/geslacht';
import Combobox from '../../../../../components/formulier/Combobox';
import { IOphalenTalenResult } from '../../../../../../../shared/src/api/v2/taal';
import { EResultType } from '../../../../../stores/CheckStore';
import { IToevoegenPersoonEnRegistreerDuplicatenParams } from '../../../../../../../shared/src/api/v2/persoon/duplicaat';
import { RootStoreContext } from '../../../../../stores/RootStore';
import InfoKnop from '../../../../../components/InfoKnop';
import { GlobaleRendererContext } from '../../../../../one-off-components/GlobaleRenderer';
import PersooninfoDialoog from '../../../../../components/personalia/PersooninfoDialoog';
import {
  IOphalenContactpersonenResult,
  IOphalenContactpersonenResultElement,
} from '../../../../../../../shared/src/api/v2/relatie/contactpersoon';
import { format } from 'date-fns';
import RelatieVisualisatie from '../../../../../components/personalia/RelatieVisualisatie';

enum EMergeVeld {
  Geslacht,
  Voornaam,
  Voorletters,
  Voorvoegsel,
  Achternaam,
  TelefoonMobiel,
  TelefoonExtra,
  Email,
  TaalID,
  Overleden,
  Geboortedatum,
  Geboorteplaats,
  Notities,
}

interface IVasteGegevens {
  recordToegevoegd: Date | null;
  contactpersonen: IOphalenContactpersonenResultElement[];
}

export interface IPersoonMergeDialoogOutput {
  persID: number;
  toegepastePersIDs: number[];
}

interface IProps extends IDialoogProps<IPersoonMergeDialoogOutput> {
  persIDs: number[];
  persDupSugID: number;
}

const PersoonMergeDialoog = observer((props: IProps) => {
  const { checkStore } = useContext(RootStoreContext);
  const globaleRenderer = useContext(GlobaleRendererContext);
  const [geslachtenResult, setGeslachtenResult] = useState<IRemoteData<IOphalenGeslachtenResult>>(
    createPendingRemoteData(),
  );
  const [talenResult, setTalenResult] = useState<IRemoteData<IOphalenTalenResult>>(
    createPendingRemoteData(),
  );
  const [personenResult, setPersonenResult] = useState<IRemoteData<IOphalenPersonenResult>>(
    createPendingRemoteData(),
  );
  const [contactpersonenResult, setContactpersonenResult] = useState<
    IRemoteData<IOphalenContactpersonenResult>
  >(createPendingRemoteData());

  const ophalenGeslachten = useCallback(async () => {
    const result = await api.v2.geslacht.ophalen();
    setGeslachtenResult(createReadyRemoteData(result));
  }, []);
  useEffect(() => {
    ophalenGeslachten();
  }, [ophalenGeslachten]);

  const ophalenTalen = useCallback(async () => {
    const result = await api.v2.taal.ophalen({});
    setTalenResult(createReadyRemoteData(result));
  }, []);
  useEffect(() => {
    ophalenTalen();
  }, [ophalenTalen]);

  const ophalenPersonen = useCallback(async () => {
    const result = await api.v2.persoon.ophalenPersonen({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: props.persIDs,
          },
        ],
      },
    });
    setPersonenResult(createReadyRemoteData(result));
  }, [props.persIDs]);
  useEffect(() => {
    ophalenPersonen();
  }, [ophalenPersonen]);

  const ophalenContactpersonen = useCallback(async () => {
    if (personenResult.state === ERemoteDataState.Pending) {
      return;
    }
    const persIDs = personenResult.data!.personen.map((persoon) => persoon.PersID);
    const result = await api.v2.relatie.ophalenContactpersonen({
      filterSchema: {
        filters: [
          {
            naam: 'PERS_IDS',
            data: persIDs,
          },
        ],
      },
    });
    setContactpersonenResult(createReadyRemoteData(result));
  }, [personenResult]);
  useEffect(() => {
    ophalenContactpersonen();
  }, [ophalenContactpersonen]);

  const velden = useMemo<IRemoteData<Record<EMergeVeld, IMergeDialoogVeld<any>>>>(() => {
    if (
      geslachtenResult.state === ERemoteDataState.Pending ||
      talenResult.state === ERemoteDataState.Pending
    ) {
      return createPendingRemoteData();
    }

    const velden: Record<EMergeVeld, IMergeDialoogVeld<any>> = {
      [EMergeVeld.Geslacht]: {
        label: 'Geslacht',
        input: (inputProps) => {
          const value =
            inputProps.data.type === EDataType.Bepaald ? inputProps.data.data ?? null : null;

          const opties = geslachtenResult.data!.map((x) => ({
            id: x.Geslacht,
            label: x.Naam,
          }));

          return (
            <Combobox
              geselecteerd={value}
              opties={opties}
              onSelectieChange={(geslacht) =>
                inputProps.onDataChange({ type: EDataType.Bepaald, data: geslacht })
              }
            />
          );
        },
        formatter: (formatterProps) => {
          const geslacht = formatterProps.data as number | null;
          const element = geslachtenResult.data!.find((x) => x.Geslacht === geslacht);

          return <span>{element!.Naam}</span>;
        },
        height: 50,
      },
      [EMergeVeld.Voornaam]: {
        label: 'Voornaam',
        input: StandaardMergeDialoogTextInput,
        formatter: StandaardMergeDialoogTextFormatter,
        height: 50,
      },
      [EMergeVeld.Voorletters]: {
        label: 'Voorletters',
        input: StandaardMergeDialoogTextInput,
        formatter: StandaardMergeDialoogTextFormatter,
        height: 50,
      },
      [EMergeVeld.Voorvoegsel]: {
        label: 'Voorvoegsel',
        input: StandaardMergeDialoogTextInput,
        formatter: StandaardMergeDialoogTextFormatter,
        height: 50,
      },
      [EMergeVeld.Achternaam]: {
        label: 'Achternaam',
        input: StandaardMergeDialoogTextInput,
        formatter: StandaardMergeDialoogTextFormatter,
        height: 50,
      },
      [EMergeVeld.TelefoonMobiel]: {
        label: 'Telefoon mobiel',
        input: StandaardMergeDialoogTextInput,
        formatter: StandaardMergeDialoogTextFormatter,
        height: 50,
      },
      [EMergeVeld.TelefoonExtra]: {
        label: 'Telefoon extra',
        input: StandaardMergeDialoogTextInput,
        formatter: StandaardMergeDialoogTextFormatter,
        height: 50,
      },
      [EMergeVeld.Email]: {
        label: 'Email',
        formatter: StandaardMergeDialoogTextFormatter,
        input: StandaardMergeDialoogTextInput,
        height: 50,
      },
      [EMergeVeld.TaalID]: {
        label: 'Taal',
        input: (inputProps) => {
          const value =
            inputProps.data.type === EDataType.Bepaald ? inputProps.data.data ?? null : null;

          const opties = talenResult.data!.map((x) => ({
            id: x.TaalID,
            label: x.Naam,
          }));

          return (
            <Combobox
              geselecteerd={value}
              opties={opties}
              onSelectieChange={(taalID) =>
                inputProps.onDataChange({ type: EDataType.Bepaald, data: taalID })
              }
            />
          );
        },
        formatter: (formatterProps) => {
          const taalID = formatterProps.data as number | null;
          const element = talenResult.data!.find((x) => x.TaalID === taalID);

          return <span>{element!.Naam}</span>;
        },
        height: 50,
      },
      [EMergeVeld.Overleden]: {
        label: 'Overleden',
        input: StandaardBooleanInput,
        formatter: StandaardMergeDialoogBooleanFormatter,
        height: 50,
      },
      [EMergeVeld.Geboortedatum]: {
        label: 'Geboortedatum',
        input: StandaardMergeDialoogDateInput,
        formatter: StandaardMergeDialoogDateFormatter,
        height: 50,
      },
      [EMergeVeld.Geboorteplaats]: {
        label: 'Geboorteplaats',
        input: StandaardMergeDialoogTextInput,
        formatter: StandaardMergeDialoogTextFormatter,
        height: 50,
      },
      [EMergeVeld.Notities]: {
        label: 'Notities',
        input: (inputProps) => {
          const value =
            inputProps.data.type === EDataType.Bepaald ? inputProps.data.data ?? '' : '';

          return (
            <textarea
              className="form-control"
              value={value}
              onChange={(ev) =>
                inputProps.onDataChange({
                  type: EDataType.Bepaald,
                  data: ev.target.value,
                })
              }
              rows={8}
              style={{ resize: 'none' }}
            />
          );
        },
        formatter: StandaardMergeDialoogTextFormatter,
        height: 200,
      },
    };

    return createReadyRemoteData(velden);
  }, [geslachtenResult, talenResult]);

  const entiteiten = useMemo<IRemoteData<IEntiteit<any, IVasteGegevens>[]>>(() => {
    if (
      personenResult.state === ERemoteDataState.Pending ||
      contactpersonenResult.state === ERemoteDataState.Pending
    ) {
      return createPendingRemoteData();
    }

    const entiteiten = personenResult.data!.personen.map(
      (persoon): IEntiteit<EMergeVeld, IVasteGegevens> => {
        const contactpersonen = contactpersonenResult.data!.contactpersonen.filter(
          (x) => x.PersID === persoon.PersID,
        );

        return {
          key: persoon.PersID,
          toepassen: true,
          header: (headerProps) => (
            <div className="d-flex align-items-center">
              <PersoonVisualisatie persID={persoon.PersID} />
              <InfoKnop
                onClick={async () => {
                  await globaleRenderer.render((renderProps) => (
                    <PersooninfoDialoog
                      dialoogIndex={headerProps.dialoogIndex + 1}
                      persID={persoon.PersID}
                      open
                      onSuccess={() => renderProps.destroy()}
                      onAnnuleren={() => renderProps.destroy()}
                    />
                  ));
                }}
                style={{ marginLeft: 5, position: 'relative', top: -1 }}
              />
            </div>
          ),
          data: {
            [EMergeVeld.Geslacht]: persoon.geslacht.Geslacht,
            [EMergeVeld.Voornaam]: persoon.Voornaam,
            [EMergeVeld.Voorletters]: persoon.Voorletters,
            [EMergeVeld.Voorvoegsel]: persoon.Voorvoegsel,
            [EMergeVeld.Achternaam]: persoon.Achternaam,
            [EMergeVeld.TelefoonMobiel]: persoon.TelefoonMobiel,
            [EMergeVeld.TelefoonExtra]: persoon.TelefoonExtra,
            [EMergeVeld.Email]: persoon.Email,
            [EMergeVeld.TaalID]: persoon.taal.TaalID,
            [EMergeVeld.Overleden]: persoon.Overleden,
            [EMergeVeld.Geboortedatum]: persoon.Geboortedatum,
            [EMergeVeld.Geboorteplaats]: persoon.Geboorteplaats,
            [EMergeVeld.Notities]: persoon.Notities,
          },
          vasteGegevens: {
            recordToegevoegd:
              persoon.RecordToegevoegd === null ? null : new Date(persoon.RecordToegevoegd),
            contactpersonen,
          },
          breedte: 400,
        };
      },
    );

    return createReadyRemoteData(entiteiten);
  }, [personenResult, contactpersonenResult]);

  const handleValideren = useCallback(async (velden: Record<EMergeVeld, any>) => {
    const keys = Object.keys(velden);

    for (const key of keys) {
      const veld = Number(key) as EMergeVeld;
      const data = velden[veld];

      switch (veld) {
        case EMergeVeld.Geslacht:
          if (data === null) {
            return false;
          }
          break;
        case EMergeVeld.Voornaam:
          break;
        case EMergeVeld.Voorletters:
          break;
        case EMergeVeld.Voorvoegsel:
          break;
        case EMergeVeld.Achternaam:
          if (data === null || data.trim() === '') {
            return false;
          }
          break;
        case EMergeVeld.TelefoonMobiel:
          break;
        case EMergeVeld.TelefoonExtra:
          break;
        case EMergeVeld.Email:
          break;
        case EMergeVeld.TaalID:
          if (data === null) {
            return false;
          }
          break;
        case EMergeVeld.Overleden:
          if (data === null) {
            return false;
          }
          break;
        case EMergeVeld.Geboortedatum:
          break;
        case EMergeVeld.Geboorteplaats:
          break;
        case EMergeVeld.Notities:
          break;
      }
    }

    return true;
  }, []);

  const vasteGegevens = useMemo<IVastGegeven<EMergeVeld, IVasteGegevens>[]>(
    () => [
      {
        label: 'Relaties',
        formatter: (formatterProps) => (
          <span className="d-flex flex-column overflow-auto">
            {formatterProps.entiteit.vasteGegevens.contactpersonen.map((contactpersoon) => (
              <RelatieVisualisatie relID={contactpersoon.RelID} />
            ))}
          </span>
        ),
        height: 100,
      },
      {
        label: 'Toegevoegd op',
        formatter: (formatterProps) => {
          const date = formatterProps.entiteit.vasteGegevens.recordToegevoegd;
          return <span>{date === null ? '- -' : format(date, 'dd-MM-yyyy HH:mm')}</span>;
        },
        height: 40,
      },
    ],
    [],
  );

  const handleSuccess = useCallback(
    async (output: IMergeDialoogOutput<EMergeVeld>) => {
      const toegepastePersIDs = output.toegepasteEntiteitenKeys as number[];

      const params: IToevoegenPersoonEnRegistreerDuplicatenParams = {
        persoon: {
          taalID: output.waarden[EMergeVeld.TaalID],
          geslacht: output.waarden[EMergeVeld.Geslacht],
          achternaam: output.waarden[EMergeVeld.Achternaam],
          email: output.waarden[EMergeVeld.Email],
          geboortedatum: output.waarden[EMergeVeld.Geboortedatum],
          geboorteplaats: output.waarden[EMergeVeld.Geboorteplaats],
          overleden: output.waarden[EMergeVeld.Overleden],
          telefoonMobiel: output.waarden[EMergeVeld.TelefoonMobiel],
          telefoonExtra: output.waarden[EMergeVeld.TelefoonExtra],
          voorletters: output.waarden[EMergeVeld.Voorletters],
          voornaam: output.waarden[EMergeVeld.Voornaam],
          voorvoegsel: output.waarden[EMergeVeld.Voorvoegsel],
          notities: output.waarden[EMergeVeld.Notities],
        },
        persDupSugID: props.persDupSugID,
        vervangenPersIDs: toegepastePersIDs,
      };

      const checkData = await api.v2.persoon.duplicaat.checkToevoegenPersoonEnRegistreerDuplicaten(
        params,
      );
      if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
        return;
      }

      const result = await api.v2.persoon.duplicaat.toevoegenPersoonEnRegistreerDuplicaten(params);

      await props.onSuccess({
        toegepastePersIDs,
        persID: result.persID,
      });
    },
    [props.onSuccess],
  );

  if (velden.state === ERemoteDataState.Pending || entiteiten.state === ERemoteDataState.Pending) {
    return null;
  }

  return (
    <MergeDialoog
      velden={velden.data!}
      entiteiten={entiteiten.data!}
      vasteGegevens={vasteGegevens}
      valideren={handleValideren}
      open={props.open}
      onSuccess={handleSuccess}
      onAnnuleren={props.onAnnuleren}
    />
  );
});

export default PersoonMergeDialoog;
