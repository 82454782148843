import { Formik, FormikActions, FormikProps, Field, FieldProps } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useContext, useRef, useState, useCallback, useEffect, useMemo } from 'react';
import { ModalTitle, ModalBody, ModalFooter } from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/esm/ModalHeader';
import api from '../../../../../../../api';
import LoadingSpinner from '../../../../../../../components/Gedeeld/LoadingSpinner';
import Dialoog from '../../../../../../../components/dialogen/Dialoog';
import IDialoogProps from '../../../../../../../core/IDialoogProps';
import nameOf from '../../../../../../../core/nameOf';
import { EResultType } from '../../../../../../../stores/CheckStore';
import { RootStoreContext } from '../../../../../../../stores/RootStore';
import {
  IToevoegenDienstAfwezigheidParams,
  IWijzigenDienstAfwezigheidParams,
} from '../../../../../../../../../shared/src/api/v2/dienst/service/afwezigheid';
import * as Yup from 'yup';
import DatumKiezer from '../../../../../../../components/formulier/DatumKiezer';
import { dagDatum } from '../../../../../../../helpers/datum';
import { IToevoegenGebiedenParams } from '../../../../../../../../../shared/src/api/v2/dienst/service/gebied';
import Combobox from '../../../../../../../components/formulier/Combobox';
import { IOphalenLandenVoorLijstResult } from '../../../../../../../../../shared/src/api/v2/land';

interface IDialoogResult {}

interface IProps extends IDialoogProps<IDialoogResult> {
  servDienstID: number;
}

interface IFormikValues {
  landID: number;
  postcodeVan: string;
  postcodeTot: string;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  landID: 'Land',
  postcodeVan: 'Postcode Van',
  postcodeTot: 'Postcode Tot',
};

const WijzigenDialoog: React.FC<IProps> = observer((props) => {
  const { dialoogIndex, onAnnuleren, onSuccess } = props;
  const { checkStore } = useContext(RootStoreContext);

  const [landen, setLanden] = useState<IOphalenLandenVoorLijstResult | null>(null);

  const ophalenLanden = useCallback(async () => {
    const result = await api.v2.land.ophalen({
      filterSchema: {
        filters: [],
      },
    });
    setLanden(result);
  }, []);

  useEffect(() => {
    ophalenLanden();
  }, [ophalenLanden]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      const params: IToevoegenGebiedenParams = {
        servDienstID: props.servDienstID,
        gebieden: [
          {
            landID: values.landID,
            postcodeVan: values.postcodeVan,
            postcodeTot: values.postcodeTot,
          },
        ],
      };

      const checkData = await api.v2.dienst.service.gebied.checkToevoegenGebieden(params);
      const checkResult = await checkStore.controleren({
        checkData,
      });
      if (checkResult.type === EResultType.Annuleren) {
        actions.setSubmitting(false);
        return;
      }

      await api.v2.dienst.service.gebied.toevoegenGebieden(params);

      actions.setSubmitting(false);
      props.onSuccess({});
    },
    [],
  );

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (landen === null) {
      return null;
    }

    return {
      landID: landen[0].LandID,
      postcodeVan: '',
      postcodeTot: '',
    };
  }, [landen]);

  return (
    <Dialoog index={dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Toevoegen gebied</ModalTitle>
      </ModalHeader>
      {initialValues === null || landen === null ? (
        <LoadingSpinner />
      ) : (
        <Formik<IFormikValues>
          onSubmit={handleSubmit}
          initialValues={initialValues}
          enableReinitialize
          isInitialValid
          render={(formikProps: FormikProps<IFormikValues>) => {
            const { submitForm, isValid, isSubmitting } = formikProps;

            return (
              <>
                <ModalBody>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-12">
                        <div className="row">
                          <div className="col-6">
                            <label>{veldnamen.landID}</label>
                            <Field
                              name={nameOf<IFormikValues>('landID')}
                              render={(fieldProps: FieldProps<IFormikValues>) => {
                                const { field, form } = fieldProps;

                                return (
                                  <Combobox
                                    geselecteerd={field.value}
                                    opties={landen.map((land) => {
                                      return {
                                        id: land.LandID,
                                        label: land.Naam,
                                      };
                                    })}
                                    onSelectieChange={(x) => {
                                      const newValues = {
                                        ...form.values,
                                        [field.name]: x,
                                      };
                                      form.setValues(newValues);
                                      // handleSubmit(newValues, form);
                                    }}
                                  />
                                );
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-6 mt-3">
                        <label>{veldnamen.postcodeVan}</label>
                        <Field
                          name={nameOf<IFormikValues>('postcodeVan')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            return (
                              <input
                                {...field}
                                className="form-control"
                                type="text"
                                onChange={(event) =>
                                  form.setFieldValue(field.name, event.target.value.toUpperCase())
                                }
                              />
                            );
                          }}
                        />
                      </div>
                      <div className="col-6 mt-3">
                        <label>{veldnamen.postcodeTot}</label>
                        <Field
                          name={nameOf<IFormikValues>('postcodeTot')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            return (
                              <input
                                {...field}
                                className="form-control"
                                type="text"
                                onChange={(event) =>
                                  form.setFieldValue(field.name, event.target.value.toUpperCase())
                                }
                              />
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </ModalBody>

                <ModalFooter className="d-flex flex-row justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    style={{ width: 100 }}
                    disabled={isSubmitting}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={onAnnuleren}
                    style={{ width: 100 }}
                    disabled={isSubmitting}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Dialoog>
  );
});

export default WijzigenDialoog;
