import {
  Column,
  DataTypeProvider,
  DataTypeProviderProps,
  Grouping,
  Sorting,
  TableColumnWidthInfo,
  TableSelection as TableSelectionBase,
  Table,
} from '@devexpress/dx-react-grid';
import React, { ReactNode, useCallback, useMemo } from 'react';
import {
  SearchPanel,
  SearchPanelProps,
  TableEditColumn,
  TableRowDetail,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  EyeIcon,
  IconVerwijderen,
  IconInklappen,
  IconToevoegen,
  IconUitklappen,
  IconWijzigen,
} from '../components/Icons';
import styled from 'styled-components';
import VinkVeld from '../components/formulier/VinkVeld';
import { Kleur } from '../bedrijfslogica/constanten';
import { colors } from '../globalstyles/variables';
import { SortableElement } from 'react-sortable-hoc';
import { IPaginatiePositie } from '../api';

// export interface ITablePaginatieData {
//   isAanHetLaden: boolean;
//   totaalAantalRijen: number;
//   paginatie: IPaginatiePositie;
//   onUitbreidenAangevraagd: () => void;
// }
//
// export const maakOnUitbreidenAangevraagd = (tablePaginatieData: ITablePaginatieData) => (
//   requestedSkip: number,
//   take: number,
// ) => {
//   tablePaginatieData.onUitbreidenAangevraagd();
// };

export const getCustomActionTableColumnEntry = <T extends {}>(config: {
  id: string;
  render: (item: T) => JSX.Element;
}): TypedColumn<T> => {
  return {
    title: ' ',
    name: `__action_${config.id}` as any,
    getCellValue: (item: T) => config.render(item),
  };
};

export const getCustomActionTableColumnWidthInfoEntry = <T extends {}>(config: {
  id: string;
  width: number;
}): TypedTableColumnWidthInfo<T> => {
  return {
    columnName: `__action_${config.id}` as any,
    width: config.width,
  };
};

export const actionsKey = '__actions';

export const getActionsTableColumnWidthInfoEntry = <T extends {}>(
  amountOfActions: number,
): TypedTableColumnWidthInfo<T> => {
  const baseWidth = 35;

  return {
    columnName: actionsKey as any,
    width: baseWidth + 30 * amountOfActions,
  };
};

export const getActionsTableColumnEntry = <T extends {}>(config: {
  detail?: {
    allowedForRow?: (item: T) => boolean;
    onClick: (item: T) => void;
  };
  edit?: {
    allowedForRow?: (item: T) => boolean;
    onClick: (item: T) => void;
  };
  delete?: {
    allowedForRow?: (item: T) => boolean;
    onClick: (item: T) => void;
  };
}): TypedColumn<T> => {
  const actions: ((item: T) => ReactNode)[] = [];

  if (config.detail !== undefined) {
    actions.push((item: T) => (
      <button
        className="btn btn-sm btn-primary mr-1"
        onClick={() => config.detail!.onClick!(item)}
        style={{ padding: '0px 5px' }}
        disabled={
          config.detail!.allowedForRow === undefined ? false : !config.detail!.allowedForRow(item)
        }
      >
        <EyeIcon style={{ width: 13, fill: 'white' }} />
      </button>
    ));
  }

  if (config.edit !== undefined) {
    actions.push((item: T) => (
      <button
        className="btn btn-sm btn-secondary mr-1"
        onClick={() => config.edit!.onClick(item)}
        style={{ padding: '0px 5px' }}
        disabled={
          config.edit!.allowedForRow === undefined ? false : !config.edit!.allowedForRow(item)
        }
      >
        <IconWijzigen style={{ width: 13, fill: 'white' }} />
      </button>
    ));
  }

  if (config.delete !== undefined) {
    actions.push((item: T) => (
      <button
        className="btn btn-sm mr-1"
        onClick={() => {
          config.delete!.onClick(item);
        }}
        style={{ padding: '0px 5px' }}
        disabled={
          config.delete!.allowedForRow === undefined ? false : !config.delete!.allowedForRow(item)
        }
      >
        <IconVerwijderen style={{ width: 18 }} />
      </button>
    ));
  }

  return {
    title: ' ',
    name: actionsKey as any,
    getCellValue: (row) => (
      <div style={{ fontSize: 17, margin: '-.3rem', marginLeft: 3 }}>
        {actions.map((action) => (
          <>{action(row)}</>
        ))}
      </div>
    ),
  };
};

export type TypedGetCellValueFn<T> = (row: T, columnName: keyof T | string) => any;

// @ts-ignore
export interface TypedColumn<T> extends Column {
  getCellValue?: TypedGetCellValueFn<T>;
  name: keyof T;
  title: string;
}

// @ts-ignore
export interface TypedTableColumnWidthInfo<T> extends TableColumnWidthInfo {
  columnName: keyof T;
}

// @ts-ignore
export interface TypedSorting<T> extends Sorting {
  columnName: keyof T;
}

// @ts-ignore
export interface TypedGrouping<T> extends Grouping {
  columnName: keyof T;
}

// @ts-ignore
export interface TypedColumnExtension<T> extends VirtualTable.ColumnExtension {
  columnName: keyof T;
}

// @ts-ignore
interface TypedDataTypeProviderProps<T> extends DataTypeProviderProps {
  for: Array<keyof T>;
}

export const TypedDataTypeProvider = <T extends {}>(props: TypedDataTypeProviderProps<T>) => {
  return <DataTypeProvider {...(props as any)} />;
};

export const DXSearchPanel: React.FC<SearchPanelProps> = (props) => (
  <SearchPanel
    inputComponent={(props) => (
      <input
        type="text"
        placeholder="Zoeken..."
        className="form-control"
        value={props.value}
        onChange={(ev) => props.onValueChange(ev.target.value)}
        style={{ width: 'inherit' }}
      />
    )}
    {...props}
  />
);

export const DXTableCheckboxComponent: React.FC<TableSelectionBase.CellProps> = (props) => (
  <td
    style={{
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderBottom: 'none',
      // height: 43,
      // borderBottom: '1px solid rgb(226, 229, 236)',
    }}
  >
    <VinkVeld onGewijzigd={props.onToggle} aangevinkt={props.selected} />
  </td>
);

export const DXTableEditColumnCommandComponent: React.FC<TableEditColumn.CommandProps> = (
  props,
) => {
  const style: React.CSSProperties = {
    fill: Kleur.Grijs,
    marginLeft: 5,
    marginRight: 5,
  };

  let icon: JSX.Element | null = null;
  switch (props.id) {
    case 'add':
      icon = (
        <IconToevoegen
          style={{
            ...style,
            position: 'relative',
            bottom: 6,
            left: 7,
          }}
        />
      );
      break;
    case 'edit':
      icon = (
        <IconWijzigen
          style={{
            ...style,
            width: 17,
            height: 17,
          }}
        />
      );
      break;
    case 'delete':
      icon = (
        <IconVerwijderen
          style={{
            ...style,
            width: 19,
            height: 19,
          }}
        />
      );
      break;
    case 'commit':
      return (
        <a
          href="#"
          onClick={props.onExecute}
          style={{ marginLeft: 5, marginRight: 5, width: 65 }}
          className="d-flex align-items-center justify-content-center"
        >
          Ok
        </a>
      );
    case 'cancel':
      return (
        <a
          href="#"
          onClick={props.onExecute}
          style={{ marginLeft: 5, marginRight: 5, width: 65 }}
          className="d-flex align-items-center justify-content-center"
        >
          Herstel
        </a>
      );
  }

  return (
    <a onClick={props.onExecute} href="#">
      {icon}
    </a>
  );
};

export const DXTableEditColumnCellComponent: React.FC<TableEditColumn.CellProps> = (props) => (
  <td
    style={{
      width: '100%',
      height: '100%',
      borderBottom: 'none',
    }}
  >
    <div className="d-flex align-items-center">{props.children}</div>
  </td>
);

export const DXTableToggleCellComponent: React.FC<TableRowDetail.ToggleCellProps> = (props) => (
  <td
    style={{
      cursor: 'pointer',
      borderBottom: 'none',
    }}
    onClick={props.onToggle}
  >
    {props.expanded ? (
      <IconInklappen style={{ fill: Kleur.Grijs }} />
    ) : (
      <IconUitklappen style={{ fill: Kleur.Grijs }} />
    )}
  </td>
);

export const DXCommandComponent: React.FC<TableEditColumn.CommandProps> = (props) => {
  const onClick = useCallback(
    (ev: React.MouseEvent<any>) => {
      ev.stopPropagation();

      props.onExecute();
    },
    [props.onExecute],
  );

  const icoonStyle: React.CSSProperties = useMemo(
    () => ({
      fill: Kleur.Grijs,
      width: 22,
      height: 22,
      cursor: 'pointer',
    }),
    [],
  );

  const buttonStyle = useMemo<React.CSSProperties>(
    () => ({
      outline: 'none',
      background: 'none',
      border: 'none',
      padding: 5,
    }),
    [],
  );

  if (props.id === 'add') {
    return (
      <a href="#" style={{ transform: 'translateY(-3px)', display: 'block', padding: 5 }}>
        <IconToevoegen style={{ ...icoonStyle, position: 'relative', top: 2 }} onClick={onClick} />
      </a>
    );
  }

  if (props.id === 'edit') {
    return (
      <button style={buttonStyle} onClick={onClick}>
        <IconWijzigen
          style={{
            ...icoonStyle,
            height: 18,
            width: 18,
            position: 'relative',
            fill: Kleur.Grijs,
          }}
        />
      </button>
    );
  }

  if (props.id === 'delete') {
    return (
      <button style={buttonStyle} onClick={onClick}>
        <IconVerwijderen
          style={{
            ...icoonStyle,
            height: 18,
            width: 18,
            position: 'relative',
            fill: Kleur.Grijs,
          }}
        />
      </button>
    );
  }

  throw new Error('ID niet ondersteund command table grid');
};

export const DXTableRowSortableRowComponent: React.ComponentType<Table.DataRowProps> = SortableElement(
  ({ ...props }: any) => {
    return <tr {...props} />;
  },
);

export interface IGridStyleWrapperProps {
  height?: string | number;
  // Of gebruik deze in combinatie
  maxHeight?: number;
  rowAmount?: number;
  noTableHeaderRow?: boolean;
  geenScheidingslijnen?: boolean;
  nietMiddenUitlijnen?: boolean;

  // Gebruik dit om wat hoogte toe te voegen aan het grid (als er bijvoorbeeld een rij wordt uigeklapt bij een grid van
  // dynamische hoogte. Dit wordt namelijk niet meegenomen in de berekening.
  // Pak in de situatie hierboven de hoogte van de RowDetail * het aantal regels, en vul dat bij deze prop in
  extraHeight?: number;

  zIndex?: number;
  hiddenOverflowX?: boolean;
}

export const GridStyleWrapper = styled.div<IGridStyleWrapperProps>`
  flex: 1;

  table {
    ${(p: IGridStyleWrapperProps) => (p.zIndex ? `z-index: ${p.zIndex} !important;` : '')}
  }

  td {
    padding: 0.45rem;
    color: #555;
    ${(p: IGridStyleWrapperProps) => (p.nietMiddenUitlijnen ? '' : 'vertical-align: middle;')}
  }

  .table td,
  .table th {
    ${(p: IGridStyleWrapperProps) => (p.geenScheidingslijnen ? 'border-top: 0;' : '')}
  }

  td.table-active {
    padding: 0;
  }

  th {
    padding: 0.6rem;
  }

  .table > thead {
    th {
      border-bottom: 0;
      font-weight: normal;
      font-size: 14px;
    }

    th[class*='p-0'] {
      //border-bottom: 1px solid rgb(226, 229, 236);
    }

    tr {
      background-color: #f6f7f9;
      color: grey;
    }
  }

  .table > tbody > tr {
    &:hover {
      background-color: ${colors.activeNavGrey};
    }
  }

  .table > tbody > tr:first-child > td {
    border-top: 1px solid rgb(226, 229, 236);
  }

  .table > tbody > tr > td {
    border-top: 0;
    border-bottom: 1px solid rgb(226, 229, 236);
  }

  // .table > tbody {
  //   tr {
  //     &:hover {
  //       background-color: ${colors.activeNavGrey};
  //     }
  //   }
  //
  //   td {
  //     border-top: 0;
  //     border-bottom: 1px solid rgb(226, 229, 236);
  //   }
  //
  //   tr:first-child {
  //     td {
  //       border-top: 1px solid rgb(226, 229, 236);
  //     }
  //   }
  }

  & > div > div[class*='table-responsive dx-g-bs4-table-container'] {
    min-height: 50px;
    height: ${(p: IGridStyleWrapperProps) => {
      if (p.maxHeight !== undefined) {
        const rowHeight = 36.6;
        const x =
          (p.extraHeight || 0) +
          (p.rowAmount === 0 ? 75 : (p.noTableHeaderRow ? 0 : 40) + p.rowAmount! * rowHeight);

        if (x > p.maxHeight) {
          return `${p.maxHeight}px !important`;
        }
        return `${x}px !important`;
      }

      return p.height === undefined
        ? 'inherit'
        : `${p.height}${typeof p.height === 'number' ? 'px' : ''} !important`;
    }};
    ${(props) => (props.hiddenOverflowX ? 'overflow-x: hidden' : '')};
  }
`;

export const TableRowDetailWrapper = styled.div`
  table td,
  table th {
    border-top: 0;
  }
`;
