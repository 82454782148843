import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { observer } from 'mobx-react-lite';
import MenuLayout from '../../../../components/MenuLayout';
import useUrlState from '../../../../core/useUrlState';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import api from '../../../../api';
import { IOphalenGrootboekenResultElement } from '../../../../../../shared/src/api/v2/boekhouding/boeking/grootboek';
import ASPTabel from '../../../../components/tabel/ASPTabel';
import {
  ASPKolom,
  EAspKolomBreedteType,
  ESortering,
  IAspKolomSorteringItem,
} from '../../../../components/tabel/ASPTabel/types';
import { Helmet } from 'react-helmet';
import WijzigenDialoog from './WijzigenDialoog';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import { IconToevoegen } from '../../../../components/Icons';
import ToevoegenDialoog from './ToevoegenDialoog';

enum EKolom {
  Nummer,
  Naam,
  Soort,
  BtwSoort,
  Werkkostenregeling,
  Omschrijving,
  IsActief,
}

export interface IExporterenDialoogState {}

interface IToevoegenDialoogState {}
export interface IWijzigenDialoogState {
  id: number;
}

export interface IUrlState {
  exporterenDialoogState: IExporterenDialoogState | null;
  selectie: number[];
  sortering: IAspKolomSorteringItem<EKolom>[];
  toevoegenDialoogState: IToevoegenDialoogState | null;
  wijzigenDialoogState: IWijzigenDialoogState | null;
}

interface IProps extends RouteComponentProps {}

const Grootboekrekeningen: React.FC<IProps> = observer((props) => {
  const defaultUrlState = useMemo<IUrlState>(
    () => ({
      toevoegenDialoogState: null,
      wijzigenDialoogState: null,
      exporterenDialoogState: null,
      selectie: [],
      sortering: [
        {
          key: EKolom.Nummer,
          sortering: ESortering.Ascending,
        },
      ],
    }),
    [],
  );
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);

  const [grootboekrekeningen, setGrootboekrekeningen] = useState<
    IOphalenGrootboekenResultElement[] | null
  >(null);

  const handleToevoegen = useCallback(async () => {
    setUrlStateSync('toevoegenDialoogState', {});
  }, []);

  const ophalenGrootboekrekeningen = useCallback(async () => {
    const result = await api.v2.boekhouding.boeking.grootboek.ophalenGrootboeken({
      filterSchema: {
        filters: [],
      },
      orderSchema: { orders: [{ naam: 'NUMMER', richting: 'ASC' }] },
    });

    setGrootboekrekeningen(result.grootboeken);
  }, []);

  useEffect(() => {
    ophalenGrootboekrekeningen();
  }, [ophalenGrootboekrekeningen]);

  //

  const grootboekrekeningRijen = useMemo(() => {
    if (grootboekrekeningen === null) {
      return null;
    }

    const grootboekrekeningenRijen = grootboekrekeningen.reduce((acc, grootboekrekening, idx) => {
      return {
        ...acc,
        [idx]: grootboekrekening,
      };
    }, {});

    return grootboekrekeningenRijen;
  }, [grootboekrekeningen]);

  const keyExtractor = useCallback((item: IOphalenGrootboekenResultElement) => item.ID, []);

  const kolommen = useMemo<ASPKolom<EKolom, IOphalenGrootboekenResultElement>[]>(
    () => [
      {
        key: EKolom.Nummer,
        label: 'Nummer',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 110,
        renderer: (item: IOphalenGrootboekenResultElement) => item.Nummer,
      },
      {
        key: EKolom.Naam,
        label: 'Naam',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 300,
        renderer: (item: IOphalenGrootboekenResultElement) => item.Naam,
      },
      {
        key: EKolom.Soort,
        label: 'Soort',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 80,
        renderer: (item: IOphalenGrootboekenResultElement) => item.Soort,
      },
      {
        key: EKolom.IsActief,
        label: 'Actief',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 80,
        renderer: (item: IOphalenGrootboekenResultElement) => (item.IsActief ? 'Ja' : 'Nee'),
      },
      {
        key: EKolom.BtwSoort,
        label: 'Btw-soort',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 135,
        renderer: (item: IOphalenGrootboekenResultElement) => (
          <span>{item.btwsoort?.Naam ?? ''}</span>
        ),
        vergelijkingswaarde: (row) => row.btwsoort?.Naam,
      },
      {
        key: EKolom.Werkkostenregeling,
        label: 'WKR',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 200,
        renderer: (item: IOphalenGrootboekenResultElement) => item.werkkostenRegeling?.Naam,
      },
      {
        key: EKolom.Omschrijving,
        label: 'Omschrijving',
        breedteType: EAspKolomBreedteType.Flex,
        flex: 1,
        renderer: (item: IOphalenGrootboekenResultElement) => item.Omschrijving,
      },
    ],
    [],
  );

  return (
    <>
      <Helmet>
        <title>Grootboekrekeningen</title>
      </Helmet>
      <MenuLayout
        menu={
          <div className="d-flex align-items-center">
            <button
              className="btn btn-sm btn-light d-flex align-items-center"
              style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
              disabled={false}
              onClick={() => handleToevoegen()}
            >
              <IconToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
              <span className="ml-2">Toevoegen</span>
            </button>
            {/* <button
              className="btn btn-sm btn-light"
              style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
              onClick={() => setUrlStateSync('exporterenDialoogState', {})}
            >
              Exporteren
            </button> */}
          </div>
        }
        body={
          <div className="d-flex flex-column flex-fill">
            {grootboekrekeningRijen === null ? (
              <LoadingSpinner />
            ) : (
              <div className="d-flex flex-column flex-fill">
                <ASPTabel
                  rijen={grootboekrekeningRijen}
                  kolommen={kolommen}
                  keyExtractor={keyExtractor}
                  selectie={urlState.selectie}
                  onSelectieChange={(selectie) => setUrlStateSync('selectie', selectie)}
                  sortering={urlState.sortering}
                  onSorteringChange={(sortering) => setUrlStateSync('sortering', sortering)}
                  onWijzigenRij={async (x) => {
                    setUrlStateSync('wijzigenDialoogState', { id: x.ID });
                  }}
                  onVerwijderenRij={async (grootboek) => {
                    await new Promise((resolve) => setTimeout(resolve, 3000));
                  }}
                  lokaalSorteren
                />
              </div>
            )}
          </div>
        }
      />

      {urlState.toevoegenDialoogState !== null && (
        <ToevoegenDialoog
          open
          onSuccess={async () => {
            setUrlStateSync('toevoegenDialoogState', null);
            await ophalenGrootboekrekeningen();
          }}
          onAnnuleren={() => setUrlStateSync('toevoegenDialoogState', null)}
        />
      )}

      {urlState.wijzigenDialoogState !== null && (
        <WijzigenDialoog
          open
          id={urlState.wijzigenDialoogState.id}
          onSuccess={() => {
            ophalenGrootboekrekeningen();
            setUrlStateSync('wijzigenDialoogState', null);
          }}
          onAnnuleren={() => {
            setUrlStateSync('wijzigenDialoogState', null);
          }}
        />
      )}
      {/* {urlState.exporterenDialoogState !== null && (
        <ExporterenDialoog
          open
          onSuccess={async (result) => {
            await ophalenGrootboeken();
            // TODO Gil
            // await downloadUrl(result.bestand.url, null);

            setUrlStateSync('exporterenDialoogState', null);
          }}
          onAnnuleren={() => setUrlStateSync('exporterenDialoogState', null)}
        />
      )} */}
    </>
  );
});

export default withRouter(Grootboekrekeningen);
