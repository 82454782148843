import React from 'react';
import styled from 'styled-components';
import { Kleur } from '../../../bedrijfslogica/constanten';
import FormulierVeld from './FormulierVeld';
import { EDataType, IData, IEntiteit, IMergeDialoogVeld, IVastGegeven } from '../types';
import DataTypeIndicatie from './DataTypeIndicatie';

interface IRootProps {
  width?: number;
}

const Root = styled.div<IRootProps>`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: ${Kleur.HeelLichtGrijs};
  min-width: ${(props) => `${props.width ?? 400}px`};
  max-width: ${(props) => `${props.width ?? 400}px`};
  border-right: 1px solid ${Kleur.LichtGrijs};
    // border-top: 1px solid ${Kleur.LichtGrijs};
    // border-bottom: 1px solid ${Kleur.LichtGrijs};
`;

const Header = styled.div`
  padding: 8px 10px;
  height: 40px;
  display: flex;
  align-items: center;

  h5 {
    margin: 0;
  }
`;

const VasteGegevensContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Formulier = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const VastGegevenVeldContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FormulierVeldContainer = styled.div`
  display: flex;
  align-items: center;
`;

interface IProps<TVelden extends string | number | symbol, TVasteGegevens = {}> {
  waarden: Record<TVelden, IData<any>>;
  onWaardenChange: (waarden: Record<TVelden, IData<any>>) => void;
  velden: Record<TVelden, IMergeDialoogVeld<any>>;
  labelWidth?: number;
  formulierWidth?: number;
  vasteGegevens?: IVastGegeven<TVelden, TVasteGegevens>[];
}

const FormulierVlak = <TVelden extends string | number | symbol, TVasteGegevens>(
  props: IProps<TVelden, TVasteGegevens>,
) => {
  return (
    <Root width={props.formulierWidth}>
      <Header>
        <h5>Formulier</h5>
      </Header>
      <Formulier>
        {props.vasteGegevens === undefined ? null : (
          <VasteGegevensContainer>
            {props.vasteGegevens.map((vastGegeven, i, arr) => {
              return (
                <VastGegevenVeldContainer
                  style={{
                    minHeight: vastGegeven.height,
                    maxHeight: vastGegeven.height,
                    borderBottom: `1px solid ${Kleur.LichtGrijs}`,
                    marginTop: 1,
                  }}
                >
                  {vastGegeven.label}
                </VastGegevenVeldContainer>
              );
            })}
          </VasteGegevensContainer>
        )}
        {Object.keys(props.velden).map((keyStr, i, arr) => {
          const veld = keyStr as TVelden;
          const veldEntry = props.velden[veld];
          const waarde = props.waarden[veld];

          const key = veld.toString();

          const isLaatste = i === arr.length - 1;
          const style = isLaatste
            ? undefined
            : {
                borderBottom: `1px solid ${Kleur.LichtGrijs}`,
              };

          return (
            <FormulierVeldContainer key={key} style={style}>
              <FormulierVeld<any>
                veld={veldEntry}
                waarde={waarde}
                onDataChange={(x) => {
                  props.onWaardenChange({
                    ...props.waarden,
                    [key]: x,
                  });
                }}
                labelWidth={props.labelWidth}
              />
              <DataTypeIndicatie
                type={waarde.type}
                onClick={() => {
                  if (waarde.type === EDataType.Bepaald) {
                    return;
                  }

                  props.onWaardenChange({
                    ...props.waarden,
                    [veld]: {
                      type: EDataType.Bepaald,
                      data: null,
                    },
                  });
                }}
              />
            </FormulierVeldContainer>
          );
        })}
      </Formulier>
    </Root>
  );
};

export default FormulierVlak;
