import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import ContactpersoonTegel from './ContactpersoonTegel';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import LoadingSpinner from '../../../../../../../components/Gedeeld/LoadingSpinner';
import {
  IKredietwaardigheidstoetsResultaatMessageData,
  IOphalenKrediettoetsenResult,
  IOphalenKrediettoetsenResultElement,
} from '../../../../../../../../../shared/src/api/v2/kredietwaardigheid';
import { Kleur } from '../../../../../../../bedrijfslogica/constanten';
import { IconToevoegen } from '../../../../../../../components/Icons';
import { RootStoreContext } from '../../../../../../../stores/RootStore';
import MenuLayout from '../../../../../../../components/MenuLayout';
import useUrlState, { genereerUrlStateQueryParam } from '../../../../../../../core/useUrlState';
import api from '../../../../../../../api';
import { useRealtimeListener } from '../../../../../../../one-off-components/realtime/RealtimeIntegratie';
import { IOphalenPersonenResultElementV2 } from '../../../../../../../../../shared/src/api/v2/persoon/persoon';
import _ from 'lodash';
import { IOphalenContactpersonenResultElement } from '../../../../../../../../../shared/src/api/v2/relatie/contactpersoon';
import KredietwaardigheidToetsDialoog from '../../../../../../../components/kaart/entiteiten/Contactpersonen/KredietwaardigheidToetsDialoog';

interface IProps extends RouteComponentProps {
  relID: number;
}

interface IKredietwaardigheidDialoogState {
  persID: number;
}

export interface IUrlState {
  selectie: number[];
  toetsingenZichtbaarPersIDs: number[];
  kredietwaardigheidDialoogState: IKredietwaardigheidDialoogState | null;
}

const defaultUrlState: IUrlState = {
  selectie: [],
  toetsingenZichtbaarPersIDs: [],
  kredietwaardigheidDialoogState: null,
};

const MAX_AANTAL_TOETSEN_DIRECT_UITKLAPPEN = 5;

const Kredietwaardigheid: React.FC<IProps> = observer((props) => {
  const { klantkaartStore } = useContext(RootStoreContext);
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);

  const [krediettoetsen, setKrediettoetsen] = useState<
    IOphalenKrediettoetsenResultElement[] | null
  >(null);
  const [contactpersonen, setContactpersonen] = useState<
    IOphalenContactpersonenResultElement[] | null
  >(null);

  const ophalenKrediettoetsen = useCallback(async () => {
    const result = (
      await api.v2.persoon.kredietwaardigheid.ophalenKrediettoetsen({
        filterSchema: { filters: [{ naam: 'REL_IDS', data: [props.relID] }] },
        orderSchema: { orders: [{ naam: 'DATUM', richting: 'DESC' }] },
      })
    ).toetsen;

    setKrediettoetsen(result);
  }, [props.relID]);

  useEffect(() => {
    ophalenKrediettoetsen();
  }, [ophalenKrediettoetsen]);

  const ophalenContactpersonen = useCallback(async () => {
    if (krediettoetsen === null) {
      return;
    }

    const persIDs = _.uniq(krediettoetsen.map((x) => x.persoon!.PersID));

    const result = await api.v2.relatie.ophalenContactpersonen({
      filterSchema: {
        filters: [
          { naam: 'PERS_IDS', data: persIDs },
          { naam: 'REL_IDS', data: [props.relID] },
        ],
      },
    });

    setContactpersonen(result.contactpersonen);
  }, [krediettoetsen, props.relID]);

  useEffect(() => {
    ophalenContactpersonen();
  }, [ophalenContactpersonen]);

  useRealtimeListener(async (key, data) => {
    if (key === 'KREDIETSWAARDIGHEIDTOETS_RESULTAAT') {
      // const resultaat: IKredietwaardigheidstoetsResultaatMessageData = data;
      await ophalenKrediettoetsen();
    }
  });

  const initieelUitklappenGedaan = useRef<boolean>(false);
  useEffect(() => {
    if (krediettoetsen === null) {
      return;
    }
    initieelUitklappenGedaan.current = true;

    const persIDs = _.uniq(krediettoetsen.map((x) => x.persoon!.PersID));
    if (persIDs.length === 1 || krediettoetsen.length <= MAX_AANTAL_TOETSEN_DIRECT_UITKLAPPEN) {
      setUrlStateSync('toetsingenZichtbaarPersIDs', persIDs);
      return;
    }
  }, [krediettoetsen]);

  return (
    <>
      <MenuLayout
        menu={
          <>
            <div className="mt-2 d-flex align-items-center">
              <button
                className="btn btn-sm btn-light"
                style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                disabled={
                  klantkaartStore.relatie === null ||
                  klantkaartStore.relatie.Relatiesoort === 'O' ||
                  klantkaartStore.relatie.PersoonPrimair_PersID === null
                }
                onClick={() =>
                  setUrlStateSync('kredietwaardigheidDialoogState', {
                    persID: klantkaartStore.relatie!.PersoonPrimair_PersID!,
                  })
                }
              >
                <IconToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                <span className="ml-2">Nieuwe toets voor primaire persoon</span>
              </button>
            </div>
          </>
        }
        body={
          krediettoetsen === null || contactpersonen === null ? (
            <div className="d-flex flex-fill align-items-center justify-content-center pt-4">
              <LoadingSpinner />
            </div>
          ) : (
            <div className="pl-3 pr-3 flex-fill">
              {contactpersonen!
                .filter(
                  (ctp) =>
                    krediettoetsen!.findIndex((kt) => {
                      if (kt.persoon === null) {
                        return false;
                      }
                      return kt.persoon.PersID === ctp.PersID;
                    }) !== -1,
                )
                .map((ctp, i) => {
                  const toetsen = krediettoetsen!.filter((toets) => {
                    if (toets.persoon === null) {
                      return false;
                    }
                    return toets.persoon.PersID === ctp.PersID;
                  });

                  const toetsingenZichtbaar =
                    urlState.toetsingenZichtbaarPersIDs.findIndex(
                      (persID) => ctp.PersID === persID,
                    ) !== -1;

                  return (
                    <div key={ctp.PersID} className={i === 0 ? 'mt-3' : 'mt-2'}>
                      <ContactpersoonTegel
                        toetsingen={krediettoetsen.filter((x) => x.persoon!.PersID === ctp.PersID)}
                        toetsingenZichtbaar={toetsingenZichtbaar}
                        onToetsingenZichtbaarChange={(zichtbaar) => {
                          let newState: number[];
                          if (zichtbaar) {
                            newState = [...urlState.toetsingenZichtbaarPersIDs, ctp.PersID];
                          } else {
                            newState = urlState.toetsingenZichtbaarPersIDs.filter(
                              (persID) => persID !== ctp.PersID,
                            );
                          }
                          setUrlStateSync('toetsingenZichtbaarPersIDs', newState);
                        }}
                        contactpersoon={ctp}
                        geselecteerd={urlState.selectie.indexOf(ctp.PersID) !== -1}
                        onSelectieChange={(aangevinkt) => {
                          if (aangevinkt) {
                            setUrlStateSync('selectie', [...urlState.selectie, ctp.PersID]);
                          } else {
                            setUrlStateSync(
                              'selectie',
                              urlState.selectie.filter((x) => x !== ctp.PersID),
                            );
                          }
                        }}
                      />
                    </div>
                  );
                })}
            </div>
          )
        }
      />
      {urlState.kredietwaardigheidDialoogState !== null && (
        <KredietwaardigheidToetsDialoog
          open
          onSuccess={async () => {
            setUrlStateSync('kredietwaardigheidDialoogState', null);
            await ophalenKrediettoetsen();

            // const state = genereerUrlStateQueryParam<KredietwaardigheidUrlState>({
            //   selectie: [],
            //   toetsingenZichtbaarPersIDs: [urlState.kredietwaardigheidDialoogState!.persID],
            // });
            // props.history.push(`/klant/${props.relID}/klant/kredietwaardigheid?state=${state}`);
          }}
          onAnnuleren={() => setUrlStateSync('kredietwaardigheidDialoogState', null)}
          persID={urlState.kredietwaardigheidDialoogState!.persID}
        />
      )}
    </>
  );
});

export default withRouter(Kredietwaardigheid);
