import {
  IOphalenMutatiebronnenParams,
  IOphalenMutatiebronnenResult,
  IOphalenSysteemomgevingenParams,
  IOphalenSysteemomgevingenResult,
} from '../../../../../shared/src/api/v2/applicatie';
import api from '../../index';

const applicatie = {
  ophalenSysteemomgevingen: async (
    params: IOphalenSysteemomgevingenParams,
  ): Promise<IOphalenSysteemomgevingenResult> => {
    return await api.post('/v2/applicatie/ophalen-systeemomgevingen', params);
  },
  ophalenMutatiebronnen: async (
    params: IOphalenMutatiebronnenParams,
  ): Promise<IOphalenMutatiebronnenResult> => {
    return await api.post('/v2/applicatie/ophalen-mutatiebronnen', params);
  },
};

export default applicatie;
