import React, { useCallback, useEffect, useMemo, useState } from 'react';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../../models/IRemoteData';
import api from '../../../../../api';
import { ASPKolom, EAspKolomBreedteType } from '../../../../tabel/ASPTabel/types';
import TableData, { ITableDataProps } from '../../../../tabel/ASPTabel/Body/TableData';
import ASPTabel from '../../../../tabel/ASPTabel';
import {
  IOphalenGrootboekenEerderGebruiktVoorRelatieResult,
  IOphalenGrootboekenEerderGebruiktVoorRelatieResultElement,
} from '../../../../../../../shared/src/api/v2/boekhouding/boeking/grootboek';
import { ITabbladProps } from '../index';
import LoadingSpinner from '../../../../Gedeeld/LoadingSpinner';
import { format } from 'date-fns';

enum ETabelKolom {
  Nummer,
  Naam,
  Omschrijving,
  Datum,
  KiesActie,
}

interface IProps extends ITabbladProps {}

const EerderGebruiktVoorRelatieTabblad = (props: IProps) => {
  const [resultaat, setResultaat] = useState<
    IRemoteData<IOphalenGrootboekenEerderGebruiktVoorRelatieResult>
  >(createPendingRemoteData());
  const ophalenGrootboeken = useCallback(async () => {
    const result = await api.v2.boeking.grootboek.ophalenGrootboekenEerderGebruiktVoorRelatie({
      relID: props.relID!,
    });

    setResultaat(createReadyRemoteData(result));
  }, []);
  useEffect(() => {
    ophalenGrootboeken();
  }, [ophalenGrootboeken]);

  const tabelKolommen = useMemo<
    Array<ASPKolom<ETabelKolom, IOphalenGrootboekenEerderGebruiktVoorRelatieResultElement>>
  >(
    () => [
      {
        key: ETabelKolom.Nummer,
        label: 'Nummer',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 100,
        renderer: (row) => row.grootboek.Nummer.toString(),
      },
      {
        key: ETabelKolom.Naam,
        label: 'Naam',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 250,
        renderer: (row) => row.grootboek.Naam,
      },
      {
        key: ETabelKolom.Omschrijving,
        label: 'Omschrijving',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 200,
        renderer: (row) => row.grootboek.Omschrijving,
      },
      {
        key: ETabelKolom.Datum,
        label: 'Datum',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 145,
        renderer: (row) => {
          return format(new Date(row.datum), 'dd-MM-yyyy');
        },
      },
      {
        key: ETabelKolom.KiesActie,
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 95,
        renderer: (row) => (
          <a
            href="#"
            onClick={(ev) => {
              ev.preventDefault();
              props.onGrootboekIDGeselecteerd(row.grootboek.ID);
            }}
          >
            Kies
          </a>
        ),
      },
    ],
    [props.onGrootboekIDGeselecteerd],
  );

  const TdComponent = useMemo<
    React.ComponentType<
      ITableDataProps<ETabelKolom, IOphalenGrootboekenEerderGebruiktVoorRelatieResultElement>
    >
  >(
    () => (rowProps) => {
      return (
        <TableData
          {...rowProps}
          onDoubleClick={
            rowProps.row === undefined
              ? undefined
              : (ev) => {
                  props.onGrootboekIDGeselecteerd(rowProps.row!.grootboek.ID);
                }
          }
          style={rowProps.row === undefined ? undefined : { cursor: 'pointer' }}
        >
          {rowProps.children}
        </TableData>
      );
    },
    [props.onGrootboekIDGeselecteerd],
  );

  return (
    <div className="d-flex flex-fill flex-column">
      <div style={{ height: 500 }} className="d-flex flex-column">
        {resultaat.state === ERemoteDataState.Pending ? (
          <div className="d-flex flex-fill align-items-center justify-content-center">
            <LoadingSpinner />
          </div>
        ) : (
          <ASPTabel
            keyExtractor={(row) => row.grootboek.ID}
            rijen={resultaat.data!.elementen}
            kolommen={tabelKolommen}
            totaalAantalRijen={resultaat.data!.elementen.length}
            tdComponent={TdComponent}
          />
        )}
      </div>
    </div>
  );
};
export default EerderGebruiktVoorRelatieTabblad;
