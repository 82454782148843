import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { IWebPagina } from '../../../../../../shared/src/api/v2/website/pagina';
import api from '../../../../api';
import { IFilter, IFilterData, maakFilterSchema } from '../../../../components/FilterBalkV2';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import MenuLayout from '../../../../components/MenuLayout';
import ASPTabel from '../../../../components/tabel/ASPTabel';
import TableData, { ITableDataProps } from '../../../../components/tabel/ASPTabel/Body/TableData';
import { ASPKolom, EAspKolomBreedteType } from '../../../../components/tabel/ASPTabel/types';
import useUrlState from '../../../../core/useUrlState';
import IRemoteData, {
  ERemoteDataState,
  createPendingRemoteData,
  createReadyRemoteData,
} from '../../../../models/IRemoteData';
import WijzigenDialoog from './WijzigenDialoog';
import { IFaqGroepVraag, IOphalenFaqResultElement } from '../../../../../../shared/src/api/v2/faq';
import { result } from 'lodash';
import { IOphalenTekstenResultElement } from '../../../../../../shared/src/api/v2/tekst';

type Kolom = 'naamEnum' | 'aantal' | 'vragen';

interface IProps extends RouteComponentProps {}

interface IWijzigenDialoogState {
  id: number;
  naamEnum: string;
}
interface IUrlState {
  wijzigenDialoogState: IWijzigenDialoogState | null;
}

const defaultUrlState: IUrlState = {
  wijzigenDialoogState: null,
};

const PaginaPlaatsing: React.FC<IProps> = (props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);

  const [teksten, setTeksten] = useState<IOphalenTekstenResultElement[] | null>(null);

  const [webPaginas, setWebPaginas] = useState<IRemoteData<IWebPagina[]>>(
    createPendingRemoteData(),
  );
  const [faq, setFaq] = useState<IOphalenFaqResultElement[] | null>(null);

  const ophalenWebPaginas = useCallback(async () => {
    const result = await api.v2.extern.pagina.ophalenWebPaginas({});

    setWebPaginas(createReadyRemoteData(result.webPaginas));
  }, []);

  useEffect(() => {
    ophalenWebPaginas();
  }, [ophalenWebPaginas]);

  const ophalenFaq = useCallback(async () => {
    const resultFaq = await api.v2.faq.ophalenFaq({});
    setFaq(resultFaq.faq);
    console.log('faq gezet');
  }, []);

  useEffect(() => {
    ophalenFaq();
  }, [ophalenFaq]);

  const ophalenTeksten = useCallback(async () => {
    if (faq === null) {
      return;
    }
    const tekstIDs = [...faq.map((x) => x.Vraag_TekstID).filter((x) => x !== null)];
    const resultTeksten = await api.v2.tekst.ophalenTekstenInAlleTalen({
      tekstIDs,
    });

    setTeksten(resultTeksten.teksten);
  }, [faq]);

  useEffect(() => {
    ophalenTeksten();
  }, [ophalenTeksten]);

  const keyExtractor = useCallback((row: IWebPagina) => row.ID, []);

  const kolommen = useMemo<ASPKolom<Kolom, IWebPagina>[]>(
    () => [
      {
        key: 'naamEnum',
        label: 'NaamEnum',
        breedteType: EAspKolomBreedteType.Flex,
        flex: 1,
        renderer: (rij) => {
          return rij.NaamEnum;
        },
      },
      {
        key: 'aantal',
        label: 'Aantal Vragen',
        breedteType: EAspKolomBreedteType.Flex,
        flex: 0.4,
        renderer: (rij) => {
          const length = faq?.filter((f) => f.Pagina_IDs.includes(rij.ID)).length;
          return length == null || length == 0 ? '' : length;
        },
      },
      {
        key: 'vragen',
        label: 'Vragen',
        breedteType: EAspKolomBreedteType.Flex,
        flex: 3.5,
        renderer: (rij) => {
          if (teksten == null) {
            return 'notext';
          }
          return faq
            ?.filter((f) => f.Pagina_IDs.includes(rij.ID))
            .map((f) => {
              let tekst = teksten.find((t) => t.TekstID == f.Vraag_TekstID);
              return tekst == null || tekst.Tekst == null ? '' : tekst.Tekst;
            })
            .join(' | ');
        },
      },
    ],
    [webPaginas, faq, teksten],
  );

  return (
    <>
      <Helmet>
        <title>Plaatsing FAQ vragen per pagina</title>
      </Helmet>
      <MenuLayout
        body={
          <div
            className="d-flex flex-fill flex-column select-none"
            style={{ width: '100%', height: '100%' }}
          >
            {webPaginas.state == ERemoteDataState.Pending || webPaginas.data == null ? (
              <LoadingSpinner />
            ) : (
              <ASPTabel
                rijen={webPaginas.data!}
                kolommen={kolommen}
                keyExtractor={keyExtractor}
                onWijzigenRij={async (rij) => {
                  setUrlStateSync('wijzigenDialoogState', { id: rij.ID, naamEnum: rij.NaamEnum });
                }}
                tdComponent={TdComponent}
              />
            )}
          </div>
        }
      />
      {urlState.wijzigenDialoogState !== null && (
        <WijzigenDialoog
          open
          id={urlState.wijzigenDialoogState.id}
          naamEnum={urlState.wijzigenDialoogState.naamEnum}
          onSuccess={async () => {
            setUrlStateSync('wijzigenDialoogState', null);
            ophalenWebPaginas();
            ophalenFaq();
          }}
          onAnnuleren={() => setUrlStateSync('wijzigenDialoogState', null)}
        />
      )}
    </>
  );
};

const TdComponent = (props: ITableDataProps<Kolom, IWebPagina>) => {
  return (
    <TableData
      {...props}
      style={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
        display: 'block',
        paddingTop: '8px',
      }}
    />
  );
};

export default withRouter(PaginaPlaatsing);
