import {
  IOphalenDienstenParams,
  IOphalenDienstenResult,
  IToevoegenDienstParams,
  IToevoegenDienstResult,
  IVerwijderenDienstenParams,
  IVerwijderenDienstenResult,
  IWijzigenDienstParams,
  IWijzigenDienstResult,
} from '../../../../../../shared/src/api/v2/dienst/transport';
import api from '../../../index';
import ICheckData from '../../../../../../shared/src/models/ICheckData';

const transport = {
  ophalenDiensten: async (params: IOphalenDienstenParams): Promise<IOphalenDienstenResult> => {
    return await api.post('v2/transport/dienst/ophalen-diensten', params);
  },
  checkToevoegenDienst: async (params: IToevoegenDienstParams): Promise<ICheckData> => {
    return await api.post('v2/transport/dienst/check-toevoegen-dienst', params);
  },
  toevoegenDienst: async (params: IToevoegenDienstParams): Promise<IToevoegenDienstResult> => {
    return await api.post('v2/transport/dienst/toevoegen-dienst', params);
  },
  checkVerwijderenDiensten: async (params: IVerwijderenDienstenParams): Promise<ICheckData> => {
    return await api.post('v2/transport/dienst/check-verwijderen-diensten', params);
  },
  verwijderenDiensten: async (
    params: IVerwijderenDienstenParams,
  ): Promise<IVerwijderenDienstenResult> => {
    return await api.post('v2/transport/dienst/verwijderen-diensten', params);
  },
  checkWijzigenDienst: async (params: IWijzigenDienstParams): Promise<ICheckData> => {
    return await api.post('v2/transport/dienst/check-wijzigen-dienst', params);
  },
  wijzigenDienst: async (params: IWijzigenDienstParams): Promise<IWijzigenDienstResult> => {
    return await api.post('v2/transport/dienst/wijzigen-dienst', params);
  },
};

export default transport;
