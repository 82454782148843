import React, { useCallback, useMemo, useState } from 'react';
import RerenderCheck from '../../../components/RerenderCheck';
import { Kleur } from '../../../bedrijfslogica/constanten';
import { addDays, differenceInDays, format } from 'date-fns';
import { nl } from 'date-fns/locale';

interface IProps {
  datum: Date;
}

const OpvolgdatumWeergave: React.FC<IProps> = (props) => {
  return (
    <RerenderCheck
      initialData={{ vervallen: new Date() > props.datum }}
      intervalMs={1000}
      shouldRerender={(params) => {
        const vervallen = new Date() > props.datum;
        const isAnders = params.data.vervallen !== vervallen;
        if (isAnders) {
          params.setData({ vervallen });
        }
        return isAnders;
      }}
      render={(renderProps) => {
        const d = props.datum;
        const datumFormatted =
          format(d, 'dd-MM-yyyy') === format(addDays(new Date(), 1), 'dd-MM-yyyy')
            ? `morgen ${format(d, 'HH:mm', { locale: nl })}`
            : format(d, 'dd-MM-yyyy') === format(new Date(), 'dd-MM-yyyy')
            ? `vandaag ${format(d, 'HH:mm', { locale: nl })}`
            : differenceInDays(d, new Date()) < 7
            ? `${format(d, 'EEEE HH:mm', { locale: nl })}`
            : `${format(d, 'dd-MM HH:mm')}`;
        return (
          <span style={{ color: renderProps.data.vervallen ? Kleur.Rood : undefined }}>
            {datumFormatted.charAt(0).toUpperCase() + datumFormatted.slice(1)}
          </span>
        );
      }}
    />
  );
};

export default OpvolgdatumWeergave;
