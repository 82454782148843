import {
  IOphalenProducttypenAlternatiefParams,
  OphalenProducttypenAlternatiefResult,
  IWijzigenOpdrachtParams,
  IWijzigenOpdrachtResult,
  IToevoegenInslagMutatiesParams,
  IToevoegenInslagMutatiesResult,
  IOphalenInslagMutatiesParams,
  IOphalenInslagMutatiesResult,
  ICorrigerenInslagMutatiesParams,
  ICorrigerenInslagMutatiesResult,
  IMarkerenAfgehandeldParams,
  IMarkerenAfgehandeldResult,
} from '../../../../../../shared/src/api/v2/inkoop/opdracht/uitstaand';
import ICheckData from '../../../../../../shared/src/models/ICheckData';
import api from '../../../index';

const uitstaand = {
  ophalenProducttypenAlternatief: async (
    params: IOphalenProducttypenAlternatiefParams,
  ): Promise<OphalenProducttypenAlternatiefResult> => {
    return await api.post('v2/inkoop/opdracht/uitstaand/ophalen-producttypen-alternatief', params);
  },
  inslagProducten: async (
    params: IToevoegenInslagMutatiesParams,
  ): Promise<IToevoegenInslagMutatiesResult> => {
    return await api.post('v2/inkoop/opdracht/uitstaand/toevoegen-inslag-mutaties', params);
  },
  ophalenInslag: async (
    params: IOphalenInslagMutatiesParams,
  ): Promise<IOphalenInslagMutatiesResult> => {
    return await api.post('v2/inkoop/opdracht/uitstaand/ophalen-inslag-mutaties', params);
  },
  wijzigenOpdracht: async (params: IWijzigenOpdrachtParams): Promise<IWijzigenOpdrachtResult> => {
    return await api.post('v2/inkoop/opdracht/uitstaand/wijzigen-opdracht', params);
  },
  corrigerenInslagMutaties: async (
    params: ICorrigerenInslagMutatiesParams,
  ): Promise<ICorrigerenInslagMutatiesResult> => {
    return await api.post('v2/inkoop/opdracht/uitstaand/corrigeren-inslag-mutaties', params);
  },
  markerenAfgehandeld: async (
    params: IMarkerenAfgehandeldParams,
  ): Promise<IMarkerenAfgehandeldResult> => {
    return await api.post('v2/inkoop/opdracht/uitstaand/markeren-afgehandeld', params);
  },
};

export default uitstaand;
