import {
  IOphalenProducttypenParams,
  IOphalenProducttypenResult,
} from '../../../../../shared/src/api/v2/product/type';
import api from '../../index';

const type = {
  ophalenProducttypen: async (
    params: IOphalenProducttypenParams,
  ): Promise<IOphalenProducttypenResult> => {
    return await api.post('v2/product/type/ophalen-producttypen', params);
  },
};

export default type;
