import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { IconSjablonen } from '../Icons';
import { Kleur } from '../../bedrijfslogica/constanten';
import { IResolveResult } from '../../../../shared/src/api/v2/Sjabloon/sjabloon';
import api from '../../api';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { GlobaleRendererContext } from '../../one-off-components/GlobaleRenderer';
import SjabloonOplosser, { ISjabloonOplosserOutput } from '../SjabloonOplosser';
import {
  ECommunicatiekanaalSelectieType,
  EOpgegevenContextSelectieType,
  ETaalSelectieType,
} from '../SjabloonOplosser/types';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../models/IRemoteData';

export interface IOnSjabloonGeresolvedData {
  sjabID: number;
  sjabBasisID: number | null;
  resolveResult: IResolveResult;
}

export enum EWeergave {
  Klein,
  Groot,
}

interface IProps {
  beschikbareContextenSelectie: Array<{
    naamEnum?: string;
    sjabCtxID?: number;
    alias?: string;
    data: any;
  }>;
  communicatieKanaalSelectie: {
    naamEnum?: string;
    comKanID?: number;
  };
  rootStyle?: React.CSSProperties;
  iconStyle?: React.CSSProperties;
  popoverStyle?: React.CSSProperties;
  onSjabloonGeresolved: (data: IOnSjabloonGeresolvedData) => void;
  weergave?: EWeergave;
  basisNietToepassen?: boolean;
  taalID?: number;
  disabled?: boolean;
}

const SjabloonKnop = observer((props: IProps) => {
  const globaleRendererContext = useContext(GlobaleRendererContext);
  const weergave = useMemo<EWeergave>(() => props.weergave || EWeergave.Klein, [props.weergave]);

  const [bepaaldComKanID, setBepaaldComKanID] = useState<IRemoteData<number | null>>(
    createPendingRemoteData(),
  );
  const bepaalBepaaldComKanID = useCallback(async () => {
    if (props.communicatieKanaalSelectie.comKanID !== undefined) {
      setBepaaldComKanID(createReadyRemoteData(null));
      return;
    }
    const result = await api.v2.bericht.ophalenCommunicatiekanalen({
      filterSchema: {
        filters: [
          {
            naam: 'NAAM_ENUMS',
            data: [props.communicatieKanaalSelectie.naamEnum!],
          },
        ],
      },
    });
    const comKan = result.kanalen[0];
    setBepaaldComKanID(createReadyRemoteData(comKan.ComKanID));
  }, [JSON.stringify(props.communicatieKanaalSelectie)]);
  useEffect(() => {
    bepaalBepaaldComKanID();
  }, [bepaalBepaaldComKanID]);
  const comKanID = useMemo<IRemoteData<number>>(() => {
    if (props.communicatieKanaalSelectie.comKanID !== undefined) {
      return createReadyRemoteData(props.communicatieKanaalSelectie.comKanID);
    }
    if (bepaaldComKanID.state === ERemoteDataState.Pending || bepaaldComKanID.data === null) {
      return createPendingRemoteData();
    }
    return createReadyRemoteData(bepaaldComKanID.data!);
  }, [props.communicatieKanaalSelectie, bepaaldComKanID]);

  return (
    <button
      style={{
        outline: weergave === EWeergave.Klein ? 0 : undefined,
        border: weergave === EWeergave.Klein ? 0 : `1px solid ${Kleur.LichtGrijs}`,
        background: weergave === EWeergave.Klein ? 0 : undefined,
        ...props.rootStyle,
      }}
      onClick={async () => {
        const result = await globaleRendererContext.render<ISjabloonOplosserOutput>(
          (renderProps) => (
            <SjabloonOplosser
              open
              onSuccess={(result) => renderProps.destroy(result)}
              onAnnuleren={() => renderProps.destroy()}
              taalSelectie={
                props.taalID === undefined
                  ? {
                      type: ETaalSelectieType.AutomatischBepalenViaContexten,
                      isMuteerbaar: false,
                    }
                  : {
                      type: ETaalSelectieType.Opgegeven,
                      taalID: props.taalID,
                      isMuteerbaar: false,
                    }
              }
              communicatiekanaalSelectie={{
                type: ECommunicatiekanaalSelectieType.Opgegeven,
                kanaalID: comKanID.data!,
                isMuteerbaar: false,
                magVoorvullen: false,
              }}
              defaultOpgegevenContexten={props.beschikbareContextenSelectie.map((x) => ({
                selectie:
                  x.naamEnum !== undefined
                    ? {
                        type: EOpgegevenContextSelectieType.NaamEnum,
                        naamEnum: x.naamEnum,
                      }
                    : {
                        type: EOpgegevenContextSelectieType.SjabCtxID,
                        sjabCtxID: x.sjabCtxID!,
                      },
                alias: x.alias,
                data: x.data,
              }))}
              sjabloonBasisNietToepassen
            />
          ),
        );
        if (result === undefined) {
          return;
        }
        props.onSjabloonGeresolved({
          sjabID: result.sjabloon.ID,
          sjabBasisID: result.resolveResult!.sjabBasisID,
          resolveResult: result.resolveResult!,
        });
      }}
      className={classNames([
        'd-flex align-items-center',
        weergave === EWeergave.Groot && 'btn btn-light btn-sm',
      ])}
      disabled={comKanID.state === ERemoteDataState.Pending || props.disabled}
    >
      <IconSjablonen
        style={{
          // width: weergave === EWeergave.Groot ? 15 : 20,
          // height: weergave === EWeergave.Groot ? 15 : 20,
          width: 16,
          height: 16,

          fill: Kleur.Grijs,
          ...props.iconStyle,
        }}
      />
      {weergave === EWeergave.Groot && <span className="ml-2">Sjabloon</span>}
    </button>
  );
});

export default SjabloonKnop;
