import {
  IMakenOpvolgingenVoorstelParams,
  IMakenOpvolgingenVoorstelResult,
  IOphalenLeveropdrachtenParams,
  IOphalenLeveropdrachtenResult,
  IVerwijderenOpvolgingenParams,
  IVerwijderenOpvolgingenResult,
  IWijzigenOpvolgingParams,
  IWijzigenOpvolgingResult,
} from '../../../../../../shared/src/api/v2/opvolging/leveropdracht';
import api from '../../../index';
import ICheckData from '../../../../../../shared/src/models/ICheckData';

const leveropdracht = {
  ophalenOpvolgingen: async (
    params: IOphalenLeveropdrachtenParams,
  ): Promise<IOphalenLeveropdrachtenResult> => {
    return api.post('v2/opvolging/leveropdracht/ophalen-opvolgingen', params);
  },
  makenOpvolgingenVoorstel: async (
    params: IMakenOpvolgingenVoorstelParams,
  ): Promise<IMakenOpvolgingenVoorstelResult> => {
    return api.post('v2/opvolging/leveropdracht/maken-opvolgingen-voorstel', params);
  },
  wijzigenOpvolging: async (
    params: IWijzigenOpvolgingParams,
  ): Promise<IWijzigenOpvolgingResult> => {
    return api.post('v2/opvolging/leveropdracht/wijzigen-opvolging', params);
  },
  verwijderenOpvolgingen: async (
    params: IVerwijderenOpvolgingenParams,
  ): Promise<IVerwijderenOpvolgingenResult> => {
    return api.post('v2/opvolging/leveropdracht/verwijderen-opvolgingen', params);
  },
};

export default leveropdracht;
