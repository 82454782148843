import React, { useContext, useMemo, useEffect } from 'react';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../models/IRemoteData';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../stores/RootStore';
import Skeleton from 'react-loading-skeleton';
import TelefoonComponent from '../TelefoonComponent';
import PersoonVisualisatie from '../../personalia/PersoonVisualisatie';
import OrganisatieVisualisatie from '../../personalia/OrganisatieVisualisatie';
import { IOproep } from '../../../../../shared/src/api/v2/telefonie';

export enum EWeergaveModus {
  Nummer,
  Contact,
  NummerEnContact,
}

interface IProps {
  telOprID?: number;
  telefoonoproep?: IOproep;
  weergaveModus: EWeergaveModus;
}

const TelefoonoproepVisualisatie = observer((props: IProps) => {
  const { telefoonStore } = useContext(RootStoreContext);

  const telefoonoproep = useMemo<IRemoteData<IOproep>>(() => {
    if (props.telefoonoproep !== undefined) {
      return createReadyRemoteData(props.telefoonoproep);
    }
    if (props.telOprID === undefined) {
      throw new Error(`Telefoonoproep of telOprID moet gevuld zijn voor de visualisatie`);
    }
    const oproep = telefoonStore.oproepen[props.telOprID] ?? null;
    return oproep === null ? createPendingRemoteData() : createReadyRemoteData(oproep);
  }, [props.telOprID, props.telefoonoproep, telefoonStore.oproepen]);

  useEffect(() => {
    if (props.telOprID === undefined || props.telefoonoproep !== undefined) {
      return;
    }
    telefoonStore.enqueueTelefoonoproepOphalen(props.telOprID);
  }, [props.telOprID]);

  if (telefoonoproep.state === ERemoteDataState.Pending) {
    return <Skeleton />;
  }

  if (telefoonoproep.data!.Nummer === null) {
    return <span>Anonieme beller</span>;
  }

  if (props.weergaveModus === EWeergaveModus.Nummer) {
    return (
      <TelefoonComponent
        telefoonNummer={telefoonoproep.data!.Nummer}
        options={{ icoonWeergeven: false }}
      />
    );
  }

  const contactvisualisatie = (() => {
    if (telefoonoproep.data!.PersID !== null) {
      return <PersoonVisualisatie persID={telefoonoproep.data!.PersID} />;
    }
    if (telefoonoproep.data!.OrgID !== null) {
      return <OrganisatieVisualisatie orgID={telefoonoproep.data!.OrgID} />;
    }
    return null;
  })();

  if (props.weergaveModus === EWeergaveModus.Contact) {
    return contactvisualisatie;
  }

  if (props.weergaveModus === EWeergaveModus.NummerEnContact) {
    return (
      <div className="d-flex align-items-center">
        <TelefoonComponent
          telefoonNummer={telefoonoproep.data!.Nummer}
          options={{ icoonWeergeven: false }}
        />
        {contactvisualisatie !== null && (
          <>
            <span className="ml-2" />
            &ndash;
            <span className="ml-2" />
            {contactvisualisatie}
          </>
        )}
      </div>
    );
  }

  throw new Error(`Ongeldige weergavemodus`);
});

export default TelefoonoproepVisualisatie;
