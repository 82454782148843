import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { Kleur } from '../../bedrijfslogica/constanten';
import FilterItem from './FilterItem';
import { IFilterSchema } from '../../../../shared/src/models/filter';
import classNames from 'classnames';
import { IconFilter, IconToevoegen } from '../Icons';
import ActieMenuKnop, { IActie } from '../ActieMenuKnop';
import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';
import HorizontaleScheidingslijn from '../layout/HorizontaleScheidingslijn';
import InactiefOverlay from '../InactiefOverlay';
import Badge from '../Badge';

export interface IWeergaveProps<TNaam extends string> {
  data: any;
  state?: any;
  onStateChange: (value: any) => void;
  onDataChange: (value: any) => void;
  setFilterState: (filter: TNaam, value: any) => void;
  setFilterData: (filter: TNaam, value: any) => void;
  setFilterIsActief: (filter: TNaam, actief: boolean) => void;
  getFilterIsActief: (filter: TNaam) => boolean;
  toepassen: () => void;
  isActief: boolean;
  setIsActief: (value: boolean) => void;
}

export interface IFilter<TNaam extends string> {
  naam: TNaam;
  altijdWeergevenInBalk?: boolean;
  actiefMuteerbaar?: boolean;
  /**
   * Als enkel programmatisch aangestuurd wordt, dan kan de gebruiker dit filter niet toevoegen via het user interface.
   * Het kan dus enkel via code worden ingesteld.
   * De gebruiker kan nog wel kiezen om het filter te activeren of deactiveren.
   */
  enkelProgrammatischAangestuurd?: boolean;
  weergave: React.ComponentType<IWeergaveProps<TNaam>>;
  onActiefGemuteerd?: (weergaveProps: IWeergaveProps<TNaam>) => void;
  disabled?: boolean;
}

export interface IFilterData<TNaam extends string> {
  naam: TNaam;
  data: any;
  state?: any;
  isActief: boolean;
  isLokaleFilter?: boolean;
}

interface IProps<TNaam extends string> {
  filters: IFilter<TNaam>[];
  filterData: IFilterData<TNaam>[];
  onFilterDataChange: (value: IFilterData<TNaam>[]) => void;
  onFilterSchemaChange?: (filterSchema: IFilterSchema) => void;
  style?: React.CSSProperties;
}

export const maakFilterSchema = (filterDatas: IFilterData<string>[]): IFilterSchema => {
  return {
    filters: filterDatas
      .filter((x) => x.isActief && !x.isLokaleFilter)
      .map((data) => {
        return {
          naam: data.naam,
          data: data.data,
        };
      }),
  };
};

interface IWeergegevenFilterPredicateParams<TNaam extends string> {
  filter: IFilter<TNaam>;
  data: IFilterData<TNaam>;
}

const weergegevenFilterPredicate = <TNaam extends string>(
  params: IWeergegevenFilterPredicateParams<TNaam>,
) => params.filter.altijdWeergevenInBalk || params.data.isActief;

const FilterBalkV2 = <TNaam extends string>(props: IProps<TNaam>) => {
  const currentData = useRef<IFilterData<TNaam>[]>(props.filterData);
  const filterButtonRef = useRef<HTMLButtonElement>(null);
  const [overlayOpen, setOverlayOpen] = useState(false);

  if (props.filters.length !== props.filterData.length) {
    throw new Error('Filters en filter data moeten evenveel entries hebben');
  }

  const handleToepassen = useCallback(() => {
    props.onFilterSchemaChange?.(maakFilterSchema(currentData.current!));
  }, [props.onFilterSchemaChange, currentData]);

  // useEffect(() => {
  //   handleToepassen();
  // }, []);

  useEffect(() => {
    currentData.current = props.filterData;
  }, [currentData, props.filterData]);

  const weergegevenFilters = useMemo(
    () =>
      props.filters
        .map((filter) => ({
          filter,
          data: props.filterData.find((x) => x.naam === filter.naam)!,
        }))
        .filter(weergegevenFilterPredicate),
    [props.filters, props.filterData],
  );

  const toevoegbareFilters = useMemo(
    () =>
      props.filters
        .map((filter) => ({
          filter,
          data: props.filterData.find((x) => x.naam === filter.naam)!,
        }))
        .filter((x) => !weergegevenFilterPredicate(x) && !x.filter.enkelProgrammatischAangestuurd),
    [props.filters, props.filterData],
  );

  // const acties = useMemo<IActie[]>(() => {
  //   const beschikbareFilters = props.filters.filter(
  //     (filter) => weergegevenFilters.findIndex((x) => x.filter.naam === filter.naam) === -1,

  //   );
  //
  //   return beschikbareFilters.map((filter) => ({
  //     text: filter.
  //   }));
  // }, [props.filters, weergegevenFilters]);

  const changeIsActief = useCallback(
    (filter: IFilter<TNaam>, actief: boolean) => {
      const filterData = currentData.current.find((x) => x.naam === filter.naam)!;
      const filters = currentData.current.filter((x) => x.naam !== filter.naam);
      const newDatas = [
        ...filters,
        {
          ...filterData,
          isActief: actief,
        },
      ];
      currentData.current = newDatas;
      props.onFilterDataChange(newDatas);
      handleToepassen();
    },
    [currentData.current, props.onFilterDataChange, handleToepassen],
  );

  const filtersNietAltijdWeergevenInBalk = useMemo(
    () => props.filters.filter((x) => !x.altijdWeergevenInBalk),
    [props.filters],
  );

  return (
    <div
      style={{
        width: '100%',
        // backgroundColor: 'rgba(0,0,0,.015)',
        borderRadius: 3,
        // border: `1px solid ${Kleur.HeelLichtGrijs}`,
        ...props.style,
      }}
      className="d-flex"
    >
      <div
        className="d-flex flex-fill flex-wrap"
        style={{
          width: '100%',
          columnGap: 5,
          rowGap: 3,
        }}
      >
        {weergegevenFilters.map((x, i) => {
          const { filter, data: filterData } = x;

          const weergaveProps: IWeergaveProps<TNaam> = {
            data: filterData.data,
            onDataChange: (data) => {
              const filterData = currentData.current.find((x) => x.naam === filter.naam)!;

              const filters = currentData.current.filter((x) => x.naam !== filter.naam);
              const newDatas = [
                ...filters,
                {
                  ...filterData,
                  data,
                },
              ];
              currentData.current = newDatas;
              props.onFilterDataChange(newDatas);
            },
            toepassen: handleToepassen,
            isActief: filterData.isActief,
            setIsActief: (actief) => {
              changeIsActief(filter, actief);
            },
            state: filterData.state,
            onStateChange: (state) => {
              const filterData = currentData.current.find((x) => x.naam === filter.naam)!;
              const filters = currentData.current.filter((x) => x.naam !== filter.naam);
              const newDatas: IFilterData<any>[] = [
                ...filters,
                {
                  ...filterData,
                  state,
                },
              ];
              currentData.current = newDatas;
              props.onFilterDataChange(newDatas);
            },
            setFilterIsActief: (f, isActief) => {
              const filter = props.filters.find((x) => x.naam === f)!;
              changeIsActief(filter, isActief);
            },
            getFilterIsActief: (f) => {
              const filterData = props.filterData.find((x) => x.naam === f)!;
              return filterData.isActief;
            },
            setFilterData: (f, data) => {
              const filterData = currentData.current.find((x) => x.naam === f)!;

              const filters = currentData.current.filter((x) => x.naam !== f);
              const newDatas = [
                ...filters,
                {
                  ...filterData,
                  data,
                },
              ];
              currentData.current = newDatas;
              props.onFilterDataChange(newDatas);
            },
            setFilterState: (f, state) => {
              const filterData = currentData.current.find((x) => x.naam === f)!;
              const filters = currentData.current.filter((x) => x.naam !== f);
              const newDatas: IFilterData<any>[] = [
                ...filters,
                {
                  ...filterData,
                  state,
                },
              ];
              currentData.current = newDatas;
              props.onFilterDataChange(newDatas);
            },
          };

          return (
            <InactiefOverlay
              key={filter.naam}
              isInactief={filter.disabled ?? false}
              rootStyle={{ borderRadius: 3 }}
              element={
                <div
                  // className={classNames(['d-flex align-items-center', i !== 0 && 'ml-2'])}
                  className={classNames(['d-flex align-items-center'])}
                >
                  <FilterItem<TNaam>
                    {...weergaveProps}
                    filter={filter}
                    data={filterData.data}
                    onIsActiefChange={(actief) => {
                      changeIsActief(filter, actief);
                      filter.onActiefGemuteerd?.({
                        ...weergaveProps,
                        // Direct overschrijven want deze gegevens worden pas op een later moment
                        // bijgewerkt dmv state change
                        getFilterIsActief: (f) =>
                          f === filter.naam ? actief : weergaveProps.getFilterIsActief(f),
                        isActief: actief,
                      });
                    }}
                    enkelProgrammatischAangestuurd={filter.enkelProgrammatischAangestuurd ?? false}
                  />
                </div>
              }
            />
          );
        })}
      </div>
      <div>
        <button
          ref={filterButtonRef}
          onClick={() => setOverlayOpen(true)}
          style={{ border: 0, outline: 0, background: 0, marginTop: 6 }}
          className="d-flex align-items-center"
        >
          <IconFilter
            style={{
              fill: filtersNietAltijdWeergevenInBalk.length > 0 ? Kleur.Grijs : Kleur.LichtGrijs,
              width: 22,
              height: 22,
            }}
          />
          {filtersNietAltijdWeergevenInBalk.length > 0 && (
            <span
              style={{
                fontSize: 12,
                color: Kleur.Grijs,
                position: 'relative',
                top: -5,
                marginBottom: -5,
              }}
            >
              {filtersNietAltijdWeergevenInBalk.length}
            </span>
          )}
        </button>
        <Overlay
          placement="bottom"
          show={overlayOpen}
          onHide={() => setOverlayOpen(false)}
          rootClose
          target={filterButtonRef.current!}
        >
          <Popover id="" style={{ backgroundColor: Kleur.Wit, maxWidth: 99999 }}>
            {toevoegbareFilters.length === 0 ? (
              <div className="p-3 d-flex align-items-center justify-content-center">
                Er zijn geen andere filters beschikbaar.
              </div>
            ) : (
              <div>
                {toevoegbareFilters.map((x, i) => {
                  const Weergave = x.filter.weergave;
                  return (
                    <React.Fragment key={x.filter.naam}>
                      <div
                        className="d-flex align-items-center"
                        onClick={() => {
                          changeIsActief(x.filter, true);
                          setOverlayOpen(false);
                        }}
                        style={{ cursor: 'pointer', padding: '.75rem' }}
                      >
                        <div className="d-flex justify-content-center, align-items-center ml-2 mr-3">
                          <IconToevoegen style={{ width: 22, height: 22, fill: Kleur.Grijs }} />
                        </div>

                        <div
                          className="d-flex justify-content-start align-items-center flex-fill"
                          style={{ pointerEvents: 'none' }}
                        >
                          <Weergave
                            data={x.data.data}
                            state={x.data.state}
                            onStateChange={null as any}
                            onDataChange={null as any}
                            setFilterData={null as any}
                            setFilterState={null as any}
                            getFilterIsActief={null as any}
                            setFilterIsActief={null as any}
                            toepassen={null as any}
                            isActief={x.data.isActief}
                            setIsActief={(actief) => changeIsActief(x.filter, actief)}
                          />
                        </div>
                      </div>
                      {i !== toevoegbareFilters.length - 1 && <HorizontaleScheidingslijn />}
                    </React.Fragment>
                  );
                })}
              </div>
            )}
          </Popover>
        </Overlay>
      </div>
    </div>
  );
};

export default FilterBalkV2;
