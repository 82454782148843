import React, { useCallback, useContext, useState, useMemo, useEffect, useRef } from 'react';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalHeader from 'react-bootstrap/ModalHeader';
import IDialoogProps from '../../../../../../../../core/IDialoogProps';
import { observer } from 'mobx-react-lite';
import VinkVeld from '../../../../../../../../components/formulier/VinkVeld';
import { RootStoreContext } from '../../../../../../../../stores/RootStore';
import { EResultType } from '../../../../../../../../stores/CheckStore';
import Dialoog from '../../../../../../../../components/dialogen/Dialoog';
import api from '../../../../../../../../api';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import ContactSelectie, {
  EType,
} from '../../../../../../../../components/formulier/ContactSelectie';
import { values } from 'lodash';
import FormikVeldFout from '../../../../../../../../components/formulier/FormikVeldFout';
import { IOphalenContractenResultElement } from '../../../../../../../../../../shared/src/api/v2/contract';
import _ from 'lodash';
import { IOphalenVerkopenResultElement } from '../../../../../../../../../../shared/src/api/v2/verkoop/verkoop';
import LoadingSpinner from '../../../../../../../../components/Gedeeld/LoadingSpinner';

interface IDialoogResult {}

interface IProps extends IDialoogProps<IDialoogResult> {
  relID: number;
  cntIDs: number[];
}

interface IFormikValues {
  persID: number | null;
  versturenBevestiging: boolean;
  verkoopopdrachtenBevestigen: boolean;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  versturenBevestiging: 'Bevestiging sturen',
  persID: 'Naar persoon:',
  verkoopopdrachtenBevestigen: 'Accessoires meenemen in bevestiging',
};

const FiatterenDialoog: React.FC<IProps> = observer((props) => {
  const { dialoogIndex, onAnnuleren, onSuccess, open, cntIDs, relID } = props;
  const { checkStore } = useContext(RootStoreContext);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const formikRef = useRef<Formik<IFormikValues>>(null);

  const [verkoopopdrachten, setVerkoopopdrachten] = useState<
    IOphalenVerkopenResultElement[] | null
  >(null);

  // const [contracten, setContracten] = useState<IOphalenContractenResultElement[] | null>(null);

  useEffect(() => {
    (async () => {
      // Haal de contacten op om te bepalen welke persoon voorgevuld gaat worden
      const contracten = (
        await api.v2.contract.ophalenContractenV2({
          filterSchema: { filters: [{ naam: 'IDS', data: [props.cntIDs] }] },
        })
      ).contracten;

      const persIDs = _.uniq(contracten.filter((x) => x.PersID !== null).map((x) => x.PersID));

      formikRef.current!.setFieldValue('persID', persIDs.length === 1 ? persIDs[0] : null);

      // setContracten(contracten);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const verkoopopdrachten = (
        await api.v2.verkoop.ophalenVerkopen({
          filterSchema: {
            filters: [
              { naam: 'RELID', data: relID },
              { naam: 'BEVESTIGING_STUREN', data: true },
            ],
          },
        })
      ).verkopen;

      setVerkoopopdrachten(verkoopopdrachten);
    })();
  }, [relID]);

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (verkoopopdrachten === null) {
      return null;
    }

    return {
      persID: null,
      versturenBevestiging: false,
      verkoopopdrachtenBevestigen: verkoopopdrachten.length !== 0 ? true : false,
    };
  }, [verkoopopdrachten]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      const params = {
        relID,
        cntIDs,
      };

      const checkData = await api.v2.contract.nieuw.checkFiatterenContracten(params);
      const controleResult = await checkStore.controleren({
        checkData,
      });
      if (controleResult.type === EResultType.Annuleren) {
        actions.setSubmitting(false);
        return;
      }

      if (
        (
          await checkStore.bevestigen({
            inhoud: `Contracten fiatteren?`,
          })
        ).type === EResultType.Annuleren
      ) {
        actions.setSubmitting(false);
        return;
      }

      // Fiatteren contracten
      await api.v2.contract.nieuw.fiatterenContracten(params);

      // Optioneel een bevestigingsbericht versturen
      if (values.versturenBevestiging) {
        await api.v2.contract.nieuw.versturenFiatteringsbericht({
          relID,
          cntIDs,
        });
      }

      actions.setSubmitting(false);

      onSuccess({});
    },
    [relID, cntIDs, verkoopopdrachten],
  );

  return (
    <Dialoog index={dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Fiatteren contracten</ModalTitle>
      </ModalHeader>

      {initialValues !== null && (
        <Formik<IFormikValues>
          isInitialValid
          onSubmit={handleSubmit}
          initialValues={initialValues}
          ref={formikRef}
          render={(FormikProps) => {
            const { submitForm, isSubmitting, values } = FormikProps;

            if (verkoopopdrachten === null) {
              return <LoadingSpinner />;
            }

            return (
              <>
                <ModalBody>
                  <>
                    <div className="col-12">
                      <div className="d-flex align-items-center">
                        <Field
                          name="versturenBevestiging"
                          render={(x: FieldProps<IFormikValues>) => (
                            <VinkVeld
                              aangevinkt={x.field.value}
                              onGewijzigd={(aangevinkt: boolean) =>
                                x.form.setFieldValue(x.field.name, aangevinkt)
                              }
                            />
                          )}
                        />
                        <label className="ml-3">{veldnamen.versturenBevestiging}</label>
                      </div>
                    </div>

                    {values.versturenBevestiging && (
                      <div className="col-12 mt-2">
                        <label>{veldnamen.persID}</label>
                        <Field
                          name="persID"
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <>
                                <ContactSelectie
                                  alleenVoorRelIDs={[relID]}
                                  value={
                                    field.value === null
                                      ? null
                                      : {
                                          persID: field.value,
                                          orgID: null,
                                        }
                                  }
                                  onChange={(x) =>
                                    form.setFieldValue(field.name, x?.persoon?.PersID ?? null)
                                  }
                                  options={{
                                    types: [EType.Persoon],
                                  }}
                                />
                                <FormikVeldFout
                                  fieldProps={fieldProps}
                                  directTonen={hasSubmitted}
                                />
                              </>
                            );
                          }}
                        />
                      </div>
                    )}

                    <div className="col-12 mt-3">
                      <div className="d-flex align-items-center">
                        <Field
                          name="verkoopopdrachtenBevestigen"
                          render={(x: FieldProps<IFormikValues>) => (
                            <VinkVeld
                              aangevinkt={x.field.value}
                              onGewijzigd={(aangevinkt: boolean) =>
                                x.form.setFieldValue(x.field.name, aangevinkt)
                              }
                              disabled={verkoopopdrachten.length === 0}
                            />
                          )}
                        />
                        <label className="ml-3">{veldnamen.verkoopopdrachtenBevestigen}</label>
                      </div>
                    </div>
                  </>
                </ModalBody>
                <ModalFooter className="d-flex flex-row justify-content-start">
                  <button className="btn btn-primary" onClick={submitForm} style={{ width: 100 }}>
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={onAnnuleren}
                    style={{ width: 100 }}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Dialoog>
  );
});

export default FiatterenDialoog;
