import React, { useContext, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { observer } from 'mobx-react-lite';
import api from '../../../../api';
import useUrlState from '../../../../core/useUrlState';
import BoekingTabel from '../../../../components/boekhouding/BoekingTabel';
import useBasisBoekingregelsFilters, { EFilter } from '../useBasisBoekingregelsFilters';
import FilterBalkV2, { IFilterData, maakFilterSchema } from '../../../../components/FilterBalkV2';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import { EDagboeksoort } from '../../../../bedrijfslogica/enums';
import useBoekingregels from '../useBoekingregels';
import { IDagboekenProvider } from '../../../../components/boekhouding/boeking/BoekingDialoogV3/providers/dagboeken';
import {
  IDagboekVoorselectieProvider,
  standaardDagboekVoorselectieProvider,
} from '../../../../components/boekhouding/boeking/BoekingDialoogV3/providers/dagboekVoorselectie';
import {
  IRegelsProvider,
  standaardRegelsProvider,
} from '../../../../components/boekhouding/boeking/BoekingDialoogV3/providers/regels';
import { maakNieuweRegel } from '../../../../components/boekhouding/boeking/BoekingDialoogV3/helpers';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import { IconUpload } from '../../../../components/Icons';
import { GlobaleRendererContext } from '../../../../one-off-components/GlobaleRenderer';
import BestandenDragAndDropDialoog, {
  IBestandenDragAndDropDialoogResult,
} from '../../../../components/BestandenDragAndDropDialoog';
import { EBestandDragAndDropZoneSelectieModus } from '../../../../components/BestandDragAndDropZone';
import { Helmet } from 'react-helmet';

interface IProps extends RouteComponentProps {}

interface IUrlState {
  regelsSelectie: number[];
  uitgeklapteOpdrachten: number[];
  filterData: IFilterData<EFilter>[] | null;
}

const regelsProvider: IRegelsProvider = {
  provide: async (boekingID, dagboek) => {
    if (boekingID === null) {
      const regel = maakNieuweRegel();
      regel.btwSoortID = dagboek.dagboekSoort.BtwSrtID;
      return [regel];
    }

    return await standaardRegelsProvider.provide(boekingID, dagboek);
  },
};

const dagboekenProvider: IDagboekenProvider = {
  provide: async () => {
    const result = await api.v2.boeking.dagboek.ophalenDagboeken({
      filterSchema: {
        filters: [
          {
            naam: 'DAGBOEKSOORT_NAAM_ENUMS',
            data: [EDagboeksoort.MEMORIAAL],
          },
        ],
      },
    });

    return result.dagboeken;
  },
};

const Memoriaal: React.FC<IProps> = observer((props) => {
  const globaleRenderer = useContext(GlobaleRendererContext);
  const { boekingregelFilters, defaultFilterData, dagboeksoort } = useBasisBoekingregelsFilters({
    dagboeksoort: EDagboeksoort.MEMORIAAL,
  });

  const defaultUrlState = useMemo<IUrlState>(
    () => ({
      regelsSelectie: [],
      uitgeklapteOpdrachten: [],
      filterData: defaultFilterData,
    }),
    [defaultFilterData],
  );
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  useEffect(() => {
    setUrlStateSync('filterData', defaultFilterData);
  }, [defaultFilterData]);

  const [filterSchema, setFilterSchema] = useState(
    urlState.filterData === null ? null : maakFilterSchema(urlState.filterData),
  );
  useEffect(() => {
    if (filterSchema === null && urlState.filterData !== null) {
      setFilterSchema(maakFilterSchema(urlState.filterData));
    }
  }, [urlState.filterData]);

  const {
    regels,
    ophalenBoekingregels,
    bezigMetOphalen,
    totaalBoekingsregels,
    huidigeBoekingsregelsPaginatieIndex,
  } = useBoekingregels(EDagboeksoort.MEMORIAAL, filterSchema);

  const dagboekVoorselectieProvider = useMemo<IDagboekVoorselectieProvider>(
    () => ({
      provide: async (dagboeken) => {
        if (urlState.filterData === null) {
          return await standaardDagboekVoorselectieProvider.provide(dagboeken);
        }

        const dagboekFilter = urlState.filterData.find((x) => x.naam === EFilter.Dagboeken)!;
        return dagboekFilter.data[0] as number;
      },
    }),
    [urlState.filterData],
  );

  const knoppenComponent = useMemo<React.ComponentType>(
    () => (knoppenProps) => {
      return (
        <div className="d-flex align-items-center">
          {knoppenProps.children}

          <button
            className="btn btn-sm btn-light d-flex align-items-center ml-2"
            style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
            onClick={async () => {
              const result = await globaleRenderer.render<IBestandenDragAndDropDialoogResult | null>(
                (renderProps) => (
                  <BestandenDragAndDropDialoog
                    open
                    onSuccess={async (result) => {
                      const bestand = result!.bestanden[0];
                      await api.v2.boeking.inlezenLoonjournaal({
                        bestandIDs: [bestand.ID],
                      });
                      await ophalenBoekingregels(0, 500, false);

                      renderProps.destroy(result);
                    }}
                    onAnnuleren={() => renderProps.destroy(null)}
                    selectieModus={EBestandDragAndDropZoneSelectieModus.Enkel}
                    toegestaneBestandstypen={[
                      { mediaType: 'text/csv', weergaveNaam: 'CSV' },
                      { mediaType: 'text/plain', weergaveNaam: 'Tekst' },
                    ]}
                  />
                ),
              );
              if (result === null) {
                return;
              }
            }}
          >
            <IconUpload style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
            <span className="ml-2">Inlezen journaalposten</span>
          </button>
        </div>
      );
    },
    [globaleRenderer],
  );

  return (
    <>
      {dagboeksoort === null ? (
        <LoadingSpinner />
      ) : (
        <>
          <Helmet>
            <title>Boekingen Memo</title>
          </Helmet>
          <BoekingTabel
            dagboekSrtID={dagboeksoort.ID}
            totaalBoekingsregels={totaalBoekingsregels}
            bezigMetOphalenBoekingsregels={bezigMetOphalen}
            huidigeBoekingsregelsPaginatieIndex={huidigeBoekingsregelsPaginatieIndex}
            boekingsregels={regels}
            boekingsregelsSelectie={urlState.regelsSelectie}
            onBoekingsregelsSelectieChange={(value: number[]) =>
              setUrlStateSync('regelsSelectie', value)
            }
            onRequestRefresh={async () => {
              await ophalenBoekingregels(0, 500, false);
            }}
            filterbalk={
              <>
                {urlState.filterData === null ? (
                  <LoadingSpinner />
                ) : (
                  <FilterBalkV2
                    filters={boekingregelFilters}
                    filterData={urlState.filterData}
                    onFilterDataChange={(x) => setUrlStateSync('filterData', x)}
                    onFilterSchemaChange={(x) => setFilterSchema(x)}
                  />
                )}
              </>
            }
            boekingdialoogRegelsProvider={regelsProvider}
            boekingdialoogDagboekenProvider={dagboekenProvider}
            boekingdialoogDagboekVoorselectieProvider={dagboekVoorselectieProvider}
            knoppenComponent={knoppenComponent}
          />
        </>
      )}
    </>
  );
});

export default withRouter(Memoriaal);
