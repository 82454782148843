import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import IDialoogProps from '../../../../../core/IDialoogProps';
import Dialoog from '../../../../../components/dialogen/Dialoog';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { Field, FieldProps, Formik, FormikActions } from 'formik';
import api from '../../../../../api';
import { addDays, addMonths, getDaysInMonth, setDate } from 'date-fns';
import { dagDatum } from '../../../../../helpers/datum';
import nameOf from '../../../../../core/nameOf';
import DatumKiezer from '../../../../../components/formulier/DatumKiezer';
import VinkVeld from '../../../../../components/formulier/VinkVeld';
import {
  IOphalenDagboekenResultElement,
  IOphalenDagboekSoortenResult,
} from '../../../../../../../shared/src/api/v2/boekhouding/boeking/dagboek';
import Combobox, { IOptie } from '../../../../../components/formulier/Combobox';
import LoadingSpinner from '../../../../../components/Gedeeld/LoadingSpinner';
import { IFilterSchemaFilter } from '../../../../../../../shared/src/models/filter';
import { RootStoreContext } from '../../../../../stores/RootStore';
import { EResultType } from '../../../../../stores/CheckStore';
import { IExporterenResult } from '../../../../../../../shared/src/api/v2/boekhouding/boeking';
import MultiCombobox, { IKolom } from '../../../../../components/formulier/MultiCombobox';
import MultiSelect from '../../../../../components/formulier/MultiSelect';
import { achtergrondProcesAfwachten } from '../../../../../core/achtergrondProces';
import { RealtimeContext } from '../../../../../one-off-components/realtime/RealtimeIntegratie';
import { observer } from 'mobx-react-lite';

interface IFormikValues {
  startdatum: Date | null;
  einddatum: Date | null;
  dagboeksoortID: number | null;
  ongeachtGeexporteerd: boolean;
  dagboekIDs: number[] | null;
  proef: boolean;
}

interface IProps extends IDialoogProps<IExporterenResult> {}

const ExporterenDialoog: React.FC<IProps> = observer((props) => {
  const { checkStore } = useContext(RootStoreContext);
  const realtimeContext = useContext(RealtimeContext);

  const [dagboeksoortResult, setDagboeksoortResult] = useState<IOphalenDagboekSoortenResult | null>(
    null,
  );
  const [dagboekenResult, setDagboekenResult] = useState<IOphalenDagboekenResultElement[] | null>(
    null,
  );

  const ophalenDagboeksoorten = useCallback(async () => {
    const result = await api.v2.boeking.dagboek.ophalenDagboekSoorten({
      filterSchema: {},
    });
    setDagboeksoortResult(result);
  }, []);

  useEffect(() => {
    ophalenDagboeksoorten();
  }, [ophalenDagboeksoorten]);

  const ophalenDagboeken = useCallback(async () => {
    const result = await api.v2.boeking.dagboek.ophalenDagboeken({
      filterSchema: {},
    });
    setDagboekenResult(result.dagboeken);
  }, []);

  useEffect(() => {
    ophalenDagboeken();
  }, [ophalenDagboeken]);

  const initialValues = useMemo<IFormikValues>(() => {
    const datum = addMonths(new Date(), -1);
    return {
      startdatum: dagDatum(setDate(datum, 1)),
      einddatum: dagDatum(setDate(datum, getDaysInMonth(datum))),
      dagboeksoortID: null,
      ongeachtGeexporteerd: false,
      dagboekIDs: [],
      proef: false,
    };
  }, []);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);
      if (
        (
          await checkStore.bevestigen({
            inhoud: `Export maken?`,
          })
        ).type === EResultType.Annuleren
      ) {
        actions.setSubmitting(false);
        return;
      }

      const filters = [
        // values.dagboeksoortID === null
        //   ? null
        //   : {
        //       naam: 'DAGBOEK_SOORT_IDS',
        //       data: [values.dagboeksoortID],
        //     },
        values.dagboekIDs!.length === 0
          ? null
          : {
              naam: 'DAGBOEK_IDS',
              data: values.dagboekIDs,
            },
        values.startdatum === null
          ? null
          : {
              naam: 'BOEKDATUM_VAN',
              data: values.startdatum,
            },
        values.einddatum === null
          ? null
          : {
              naam: 'BOEKDATUM_TEM',
              data: values.einddatum,
            },
        values.ongeachtGeexporteerd
          ? null
          : {
              naam: 'IS_GEEXPORTEERD',
              data: false,
            },
        {
          naam: 'NIET_EXPORTEREN',
          data: false,
        },
      ].filter((x) => x !== null) as IFilterSchemaFilter[];

      const boekingen = (
        await api.v2.boekhouding.boeking.ophalenBoekingen({
          filterSchema: {
            filters,
          },
        })
      ).boekingen;

      const achtergrondproces = await api.v2.boeking.exporteren({
        boekingIDs: boekingen.map((x) => x.ID),
        proef: values.proef,
        filterdataJSON: JSON.stringify(filters),
      });

      const result = await achtergrondProcesAfwachten<IExporterenResult>(
        achtergrondproces.id,
        realtimeContext,
      );
      if (result.type === 'ERROR') {
        await checkStore.melden({
          titel: 'Er heeft een fout plaatsgevonden',
        });
        actions.setSubmitting(false);
        return;
      }
      if (result.type === 'TIMEOUT') {
        await checkStore.melden({
          titel: 'Het proces duurde te lang en is daarom afgebroken',
        });
        actions.setSubmitting(false);
        return;
      }
      if (result.type === 'DATA') {
        if (result.data!.bestand === null) {
          await checkStore.melden({
            titel: 'Er zijn geen boekingen geexporteerd',
          });
        }
        props.onSuccess(result.data!);
      }

      actions.setSubmitting(false);
    },
    [props.onSuccess, realtimeContext],
  );

  const dagboeksoortOpties = useMemo<IOptie<number>[] | null>(() => {
    if (dagboeksoortResult === null) {
      return null;
    }
    return dagboeksoortResult.soorten.map((x) => ({
      id: x.ID,
      label: x.Naam,
    }));
  }, [dagboeksoortResult]);

  const dagboekKolommen = useMemo<IKolom<IOphalenDagboekenResultElement>[]>(() => {
    return [
      {
        key: 'Naam',
        label: 'Naam',
        breedte: 150,
      },
    ];
  }, []);

  return (
    <Dialoog index={props.dialoogIndex || 0}>
      <Formik<IFormikValues>
        initialValues={initialValues}
        onSubmit={handleSubmit}
        render={(formikProps) => {
          const { submitForm, values, isSubmitting } = formikProps;
          return (
            <>
              <ModalBody style={{ padding: '2rem' }}>
                {/* <div className="row">
                  <div className="col-12">
                    <label>Dagboeksoort</label>
                    <Field
                      name={nameof<IFormikValues>('dagboeksoortID')}
                      render={(fieldProps: FieldProps<IFormikValues>) => {
                        const { field, form } = fieldProps;
                        if (dagboeksoortOpties === null) {
                          return <LoadingSpinner />;
                        }
                        return (
                          <Combobox
                            geselecteerd={field.value}
                            onSelectieChange={(x) => form.setFieldValue(field.name, x)}
                            opties={dagboeksoortOpties}
                            isWisbaar
                            options={{
                              legeOptieTekst: 'Alle dagboeksoorten',
                            }}
                          />
                        );
                      }}
                    />
                  </div>
                </div> */}

                <div className="row">
                  <div className="col-12">
                    <label>Dagboeken</label>
                    <Field
                      name={nameOf<IFormikValues>('dagboekIDs')}
                      render={(fieldProps: FieldProps<IFormikValues>) => {
                        const { field, form } = fieldProps;

                        if (dagboekenResult === null) {
                          return <LoadingSpinner />;
                        }
                        return (
                          // <Combobox
                          //   geselecteerd={field.value}
                          //   onSelectieChange={(x) => form.setFieldValue(field.name, x)}
                          //   opties={dagboekenResult.map((dagboek) => {
                          //     return {
                          //       id: dagboek.ID,
                          //       label: dagboek.Naam,
                          //     };
                          //   })}
                          //   isWisbaar
                          //   options={{
                          //     legeOptieTekst: 'Alle dagboeken',
                          //   }}
                          // />

                          <MultiSelect
                            value={field.value}
                            onChange={(x) => form.setFieldValue(field.name, x)}
                            opties={dagboekenResult.map((x) => ({
                              key: x.ID,
                              weergave: x.Naam,
                            }))}
                            isVerwijderbaar
                            placeholder="Alle dagboeken"
                            disabled={isSubmitting}
                          />

                          // <MultiCombobox<number, IOphalenDagboekenResultElement>
                          //   sleutelExtractor={(row) => row.ID}
                          //   onWaardeChange={(waarde: number | null) => {
                          //     form.setFieldValue(field.name, waarde);
                          //   }}
                          //   representatieFabriek={(row) => `${row.Naam}`}
                          //   waarde={fieldProps.field.value}
                          //   opties={dagboekenResult}
                          //   kolommen={dagboekKolommen}
                          //   options={{
                          //     geenWaardeBericht: 'Alle dagboeken',
                          //   }}
                          //   isWisbaar
                          // />
                        );
                      }}
                    />
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-6">
                    <label>Vanaf</label>
                    <Field
                      name={nameOf<IFormikValues>('startdatum')}
                      render={(fieldProps: FieldProps<IFormikValues>) => {
                        const { field, form } = fieldProps;
                        return (
                          <DatumKiezer
                            waarde={field.value}
                            onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                            determineValidDate={() => {
                              return true;
                            }}
                            determinePreviousValidDate="ONBEGRENST"
                            determineNextValidDate="ONBEGRENST"
                            disabled={isSubmitting}
                            isClearable
                          />
                        );
                      }}
                    />
                  </div>
                  <div className="col-6">
                    <label>Tot en met</label>
                    <Field
                      name={nameOf<IFormikValues>('einddatum')}
                      render={(fieldProps: FieldProps<IFormikValues>) => {
                        const { field, form } = fieldProps;
                        return (
                          <DatumKiezer
                            waarde={field.value}
                            onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                            determineValidDate={() => {
                              return true;
                            }}
                            determinePreviousValidDate="ONBEGRENST"
                            determineNextValidDate="ONBEGRENST"
                            disabled={isSubmitting}
                            isClearable
                          />
                        );
                      }}
                    />
                  </div>
                </div>

                <div className="row mt-4">
                  <div className="col-12">
                    <Field
                      name={nameOf<IFormikValues>('ongeachtGeexporteerd')}
                      render={(fieldProps: FieldProps<IFormikValues>) => {
                        const { field, form } = fieldProps;
                        return (
                          <div className="d-flex align-items-center">
                            <VinkVeld
                              aangevinkt={field.value}
                              onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                              disabled={isSubmitting}
                            />
                            <span className="ml-2">Ongeacht eerder geexporteerd</span>
                          </div>
                        );
                      }}
                    />
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-12">
                    <Field
                      name={nameOf<IFormikValues>('proef')}
                      render={(fieldProps: FieldProps<IFormikValues>) => {
                        const { field, form } = fieldProps;
                        return (
                          <div className="d-flex align-items-center">
                            <VinkVeld
                              aangevinkt={field.value}
                              onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                              disabled={isSubmitting}
                            />
                            <span className="ml-2">Is proefexport</span>
                          </div>
                        );
                      }}
                    />
                  </div>
                </div>
              </ModalBody>
              <ModalFooter className="d-flex flex-row justify-content-start">
                <button
                  className="btn btn-primary d-flex align-items-center justify-content-center"
                  onClick={submitForm}
                  style={{ width: 100 }}
                  disabled={isSubmitting}
                >
                  Exporteren
                </button>
                <button
                  className="btn btn-secondary"
                  onClick={props.onAnnuleren}
                  style={{ width: 100 }}
                  disabled={isSubmitting}
                >
                  Annuleren
                </button>
                <div className="flex-fill" />
                {isSubmitting && <LoadingSpinner />}
              </ModalFooter>
            </>
          );
        }}
      />
    </Dialoog>
  );
});

export default ExporterenDialoog;
