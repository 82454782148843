import React, { useCallback, useEffect, useMemo, useState, useContext } from 'react';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import { ITabbladProps } from '../../Aanbod';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  DXTableCheckboxComponent,
  DXTableToggleCellComponent,
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import useUrlState from '../../../../core/useUrlState';
import { IOphalenOpdrachtenResultElement } from '../../../../../../shared/src/api/v2/inkoop/opdracht';
import api from '../../../../api';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  VirtualTable,
  TableEditColumn,
  Table,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  RowDetailState,
  SelectionState,
  EditingState,
  DataTypeProvider,
  SortingState,
  IntegratedSorting,
  Sorting,
} from '@devexpress/dx-react-grid';
import {
  IconWijzigen,
  IconToevoegen,
  IconVerwijderen,
  IconSend,
  IconInslag,
  IconGeenMelding,
  IconInformatie,
} from '../../../../components/Icons';
import { EResultType } from '../../../../stores/CheckStore';
import { RootStoreContext } from '../../../../stores/RootStore';
import InslagDialoog from '../InslagDialoog';
import WijzigenStatusDialoog from '../WijzigenStatusDialoog';
import { format } from 'date-fns';
import VersturenDialoog from '../VersturenDialoog';
import DetailComp from '../DetailComp';
import * as _ from 'lodash';
import RelatieVisualisatie from '../../../../components/personalia/RelatieVisualisatie';
import { IOphalenMutatiesResultElement } from '../../../../../../shared/src/api/v2/magazijn/mutatie';
import { IInkoopopdrachtenContext, InkoopopdrachtenContext } from '..';
import UitlegTooltip from '../../../../components/formulier/UitlegTooltip';
import VoorraadTypeInfoDialoog from '../../../../components/transport/VoorraadTypeInfoDialoog';
import ProducttypeVisualisatie, {
  EWeergave,
} from '../../../../components/entiteitVisualisaties/ProducttypeVisualisatie';
import nameOf from '../../../../core/nameOf';
import { Helmet } from 'react-helmet';
import FormatteerBedrag from '../../../../components/MutatieBedrag';

interface IProps extends ITabbladProps, RouteComponentProps {}

interface IUrlState {
  selectie: number[];
  sortering: Sorting[];
}
const defaultUrlState: IUrlState = {
  selectie: [],
  sortering: [
    {
      columnName: 'DatumOpdracht',
      direction: 'desc',
    },
    // {
    //   columnName: nameof<IOphalenOpdrachtenResultElement>('Referentie'),
    //   direction: 'desc',
    // },
    // {
    //   columnName: '__datumVerwacht',
    //   direction: 'asc',
    // },
    // {
    //   columnName: 'Referentie',
    //   direction: 'desc',
    // },
  ],
};

export interface IVoorraadTypeInfoDialoogState {
  typeID: number;
  magID: number;
}
export interface IInslagDialoogState {
  inkOpdID: number;
}

const geenData = {
  noData: 'Geen inkoopopdrachten',
};

export interface IRow extends IOphalenOpdrachtenResultElement {
  magazijnmutaties: IOphalenMutatiesResultElement[];
}

const UitstaandTab: React.FC<IProps> = (props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState<IUrlState>(props, defaultUrlState);

  const { checkStore, instellingStore } = useContext(RootStoreContext);
  const [opdrachten, setOpdrachten] = useState<IRow[] | null>(null);

  const [inkOpdID, setInkOpdID] = useState<number | null>(null);
  const [inslagDialoogState, setInslagDialoogState] = useState<IInslagDialoogState | null>(null);
  // const [tonenInslagDialoog, setTonenInslagDialoog] = useState<boolean>(false);

  const [tonenStatusDialoog, setTonenStatusDialoog] = useState<boolean>(false);
  const [tonenVersturenDialoog, setTonenVersturenDialoog] = useState<boolean>(false);

  const [
    voorraadTypeInfoDialoogState,
    setVoorraadTypeInfoDialoogState,
  ] = useState<IVoorraadTypeInfoDialoogState | null>(null);

  const keyExtractor = useCallback((row: IOphalenOpdrachtenResultElement) => row.InkOpdID, []);

  const ophalenOpdrachten = useCallback(async () => {
    const result = await api.v2.inkoop.opdracht.ophalenOpdrachten({
      filterSchema: {
        filters: [
          { naam: 'IS_VERSTUURD', data: true },
          { naam: 'IS_AFGEHANDELD', data: false },
        ],
      },
      orderSchema: {
        orders: [
          // { naam: 'DATUM_VERWACHT', richting: 'ASC' },
          { naam: 'RECORD_TOEGEVOEGD', richting: 'ASC' },
        ],
      },
    });

    // Bijbehorende magazijnmutaties ophalen
    const inkOpdIDs = result.inkoopopdrachten.map((x) => x.InkOpdID);
    const mutatiesResult = await api.v2.magazijn.mutatie.ophalenMutaties({
      filterSchema: { filters: [{ naam: 'INKOPD_IDS', data: inkOpdIDs }] },
    });

    const opdrachten = result.inkoopopdrachten.map((x) => {
      return {
        ...x,
        magazijnmutaties: mutatiesResult.mutaties.filter(
          (m) => m.inkoopopdracht!.InkOpdID === x.InkOpdID,
        ),
      };
    });

    // const opdrachtenGesorteerd = _.orderBy(opdrachten, ['Referentie'], ['desc']);

    setOpdrachten(opdrachten);
  }, []);

  useEffect(() => {
    ophalenOpdrachten();
  }, []);

  const handleVerwijderen = useCallback(async () => {
    const params = { inkOpdIDs: urlState.selectie };
    const checkData = await api.v2.inkoop.opdracht.checkVerwijderenOpdrachten(params);
    if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
      return;
    }

    if (
      (
        await checkStore.bevestigen({
          inhoud: 'Inkoopopdrachten verwijderen?',
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    await api.v2.inkoop.opdracht.verwijderenOpdrachten(params);

    setUrlStateSync('selectie', []);
    await ophalenOpdrachten();
  }, [urlState.selectie]);

  const kolommen = useMemo<TypedColumn<IRow>[]>(
    () => [
      // {
      //   name: '__merknaam' as any,
      //   title: 'Merk',
      //   getCellValue: (row) => {
      //     return row.producttype.Merknaam;
      //   },
      // },
      // {
      //   name: '__typenaam' as any,
      //   title: 'Type',
      //   getCellValue: (row) => {
      //     return row.producttype.Typenaam;
      //   },
      // },
      {
        name: '__producttype' as any,
        title: 'Product',
      },
      {
        name: '__productsoortnaamKort' as any,
        title: 'Cat.',
        getCellValue: (row) => {
          return row.producttype.ProductsoortnaamKort;
        },
      },
      {
        name: '__alternatief' as any,
        title: 'Alt. type',
      },
      {
        name: '__voorraadInfo' as any,
        title: 'Vrd.info',
      },
      {
        name: 'weergavenaamRelatie' as any,
        title: 'Leverancier',
        getCellValue: (row) => {
          const naam = row.dienst.relatie!.weergavenaam;
          return naam;
        },
      },
      {
        name: 'Aantal',
        title: 'Besteld',
      },
      {
        name: 'AantalGeleverdeProducten' as any,
        title: 'Gelev.',
      },
      {
        name: 'Referentie',
        title: 'Opd.ref.',
      },
      {
        name: '__datumVerwacht' as any,
        title: 'Verwacht',
        getCellValue: (x) => {
          return x.DatumVerwacht !== null ? x.DatumVerwacht : x.DatumGewenst;
        },
      },
      {
        name: '__geenMelding' as any,
        title: ' ',
      },
      {
        name: 'Notities',
        title: 'Notities',
      },
      {
        name: '__inkoopprijs' as any,
        title: 'Ink.prijs',
      },
      {
        name: 'DatumOpdracht',
        title: 'Opdr.datum',
      },
      {
        name: '__magazijnnaamKort' as any,
        title: 'Mag.',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRow>[]>(
    () => [
      {
        columnName: '__merknaam' as any,
        width: 100,
      },
      {
        columnName: '__typenaam' as any,
        width: 165,
      },
      {
        columnName: '__producttype' as any,
        width: 225,
      },
      {
        columnName: '__alternatief' as any,
        width: 110,
      },
      {
        columnName: '__voorraadInfo' as any,
        width: 95,
      },
      {
        columnName: '__productsoortnaamKort' as any,
        width: 85,
      },
      // {
      //   columnName: '__leveranciernaamKort' as any,
      //   width: 150,
      // },
      {
        columnName: 'weergavenaamRelatie' as any,
        width: 135,
      },
      {
        columnName: 'Aantal',
        width: 90,
      },
      {
        columnName: 'AantalGeleverdeProducten' as any,
        width: 80,
      },
      {
        columnName: '__inslag' as any,
        width: 75,
      },
      {
        columnName: 'Referentie',
        width: 100,
      },
      {
        columnName: '__magazijnnaamKort' as any,
        width: 90,
      },
      {
        columnName: 'DatumGewenst',
        width: 100,
      },
      {
        columnName: '__datumVerwacht' as any,
        width: 100,
      },
      {
        columnName: '__geenMelding' as any,
        width: 35,
      },
      {
        columnName: 'Notities',
        width: 235,
        // getCellValue: (x: any) => {
        //   return (
        //     <UitlegTooltip inhoud="Intern wil zeggen dat de mutatie op ons initiatief gedaan is">
        //       {x.Notities}
        //     </UitlegTooltip>
        //   );
        // },
      },
      {
        columnName: '__inkoopprijs' as any,
        width: 90,
      },
      {
        columnName: 'DatumOpdracht',
        width: 115,
      },
    ],
    [],
  );

  const inkoopopdrachtenContext = useMemo<IInkoopopdrachtenContext>(
    () => ({
      verversen: () => ophalenOpdrachten(),
    }),
    [ophalenOpdrachten],
  );

  return (
    <InkoopopdrachtenContext.Provider value={inkoopopdrachtenContext}>
      <div>
        <Helmet>
          <title>Uitstaande inkoopopdrachten</title>
        </Helmet>
        <div
          className="d-flex flex-column p-3"
          style={{
            backgroundColor: Kleur.HeelLichtGrijs,
            borderBottom: `1px solid ${Kleur.LichtGrijs}`,
          }}
        >
          <div className="d-flex">
            <button
              className="btn btn-sm btn-light d-flex align-items-center"
              style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
              disabled={urlState.selectie.length !== 1}
              onClick={() => {
                setInslagDialoogState({
                  inkOpdID: urlState.selectie[0],
                });
              }}
            >
              <IconToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
              <span className="ml-2">Inslag producten</span>
            </button>

            {/* <button
              className="btn btn-sm btn-light d-flex align-items-center ml-3"
              style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
              disabled={urlState.selectie.length !== 1}
              onClick={() => {
                setInkOpdID(urlState.selectie[0]);
                setTonenStatusDialoog(true);
              }}
            >
              <IconToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
              <span className="ml-2">Status wijzigen</span>
            </button> */}

            <button
              className="btn btn-sm btn-light d-flex align-items-center ml-3"
              style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
              disabled={urlState.selectie.length === 0}
              onClick={() => setTonenVersturenDialoog(true)}
            >
              <IconSend style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
              <span className="ml-2">Opnieuw versturen</span>
            </button>

            <button
              className="btn btn-sm btn-light d-flex align-items-center ml-3"
              style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
              disabled={urlState.selectie.length === 0}
              onClick={async () => {
                const params = { inkOpdIDs: urlState.selectie };
                const checkData = await api.v2.inkoop.opdracht.checkVersturenVooraanmeldingen(
                  params,
                );

                if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
                  return;
                }
                if (
                  (
                    await checkStore.bevestigen({
                      inhoud: `Vooraanmelding versturen voor de geselecteerde opdrachten?`,
                    })
                  ).type === EResultType.Annuleren
                ) {
                  return;
                }

                await api.v2.inkoop.opdracht.versturenVooraanmeldingen(params);
              }}
            >
              <IconInslag style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
              <span className="ml-2">Vooraanmelding versturen</span>
            </button>

            <button
              className="btn btn-sm btn-light d-flex align-items-center ml-3"
              style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
              disabled={urlState.selectie.length === 0}
              onClick={async () => {
                const params = { inkOpdIDs: urlState.selectie };
                const checkData = await api.v2.inkoop.opdracht.chekcStatusverzoekInkoopleveringenAanMagazijndienst(
                  params,
                );

                if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
                  return;
                }

                if (
                  (
                    await checkStore.bevestigen({
                      inhoud: `Statusverzoek naar magazijndienst versturen voor de geselecteerde opdrachten?`,
                    })
                  ).type === EResultType.Annuleren
                ) {
                  return;
                }

                await api.v2.inkoop.opdracht.statusverzoekInkoopleveringenAanMagazijndienst(params);
              }}
            >
              <IconInslag style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
              <span className="ml-2">Statusverzoek aan magazijndienst</span>
            </button>

            <button
              className="btn btn-sm btn-light d-flex align-items-center ml-3"
              style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
              disabled={urlState.selectie.length === 0}
              onClick={async () => {
                const params = { inkOpdIDs: urlState.selectie };
                // const checkData = await api.v2.inkoop.opdracht.checkVersturenVooraanmeldingen(
                //   params,
                // );

                // if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
                //   return;
                // }

                if (
                  (
                    await checkStore.bevestigen({
                      inhoud: <span>Annuleringsbericht versturen naar de inkoopdienst?</span>,
                    })
                  ).type === EResultType.Annuleren
                ) {
                  return;
                }

                await api.v2.inkoop.opdracht.versturenAnnuleringOpdrachten(params);
                setUrlStateSync('selectie', []);
                ophalenOpdrachten();
              }}
            >
              {/* <IconInslag style={{ width: 16, height: 16, fill: Kleur.Grijs }} /> */}
              <span className="ml-2">Annulering versturen</span>
            </button>

            <button
              className="btn btn-sm btn-light d-flex align-items-center ml-3"
              style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
              disabled={urlState.selectie.length === 0}
              onClick={async () => {
                if (
                  (
                    await checkStore.bevestigen({
                      inhoud: 'Geselecteerde opdrachten als Afgehandeld markeren?',
                    })
                  ).type === EResultType.Annuleren
                ) {
                  return;
                }

                await api.v2.inkoop.opdracht.uitstaand.markerenAfgehandeld({
                  inkOpdIDs: urlState.selectie,
                });
                setUrlStateSync('selectie', []);
                ophalenOpdrachten();
              }}
            >
              {/* <IconVerwijderen style={{ width: 16, height: 16, fill: Kleur.Grijs }} /> */}
              <span className="ml-2">Markeren Afgehandeld</span>
            </button>

            <button
              className="btn btn-sm btn-light d-flex align-items-center ml-3"
              style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
              disabled={urlState.selectie.length === 0}
              onClick={() => handleVerwijderen()}
            >
              <IconVerwijderen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
              <span className="ml-2">Verwijderen</span>
            </button>
          </div>
        </div>

        <div
          style={{
            backgroundColor: Kleur.Wit,
          }}
        >
          {opdrachten === null ? (
            <div className="flex-fill d-flex align-items-center justify-content-center p-5">
              <LoadingSpinner />
            </div>
          ) : (
            <GridStyleWrapper height="calc(100vh - 150px)">
              <Grid columns={kolommen} getRowId={keyExtractor} rows={opdrachten}>
                <DataTypeProvider
                  for={['weergavenaamRelatie']}
                  formatterComponent={(props) => {
                    const row: IRow = props.row;
                    return (
                      <RelatieVisualisatie
                        relID={row.dienst.RelID}
                        // weergaveNaam={row.dienst.relatie!.weergavenaam}
                      />
                    );
                  }}
                />

                <DataTypeProvider
                  for={['__magazijnnaamKort']}
                  formatterComponent={(props) => {
                    return <span>{props.row.magazijn.NaamKort}</span>;
                  }}
                />

                <DataTypeProvider
                  for={['__inkoopprijs']}
                  formatterComponent={(props) => {
                    const rij: IRow = props.row;
                    if (rij.InkoopPrijs === null) {
                      return <span />;
                    }
                    return <FormatteerBedrag bedrag={rij.InkoopPrijs} />;
                  }}
                />

                <DataTypeProvider
                  for={[nameOf<IRow>('Notities')]}
                  formatterComponent={(props) => {
                    if (props.value === null) {
                      return <span></span>;
                    }

                    return <UitlegTooltip inhoud={props.value}>{props.value}</UitlegTooltip>;
                  }}
                />

                <DataTypeProvider
                  for={['__leveranciernaamKort']}
                  formatterComponent={(props) => {
                    return <span>{props.row.dienst.LeveranciernaamKort}</span>;
                  }}
                />

                <DataTypeProvider
                  for={['__productsoortnaamKort']}
                  formatterComponent={(props) => {
                    return <span>{props.row.producttype.ProductsoortnaamKort}</span>;
                  }}
                />

                <DataTypeProvider
                  for={['__merknaam']}
                  formatterComponent={(props) => {
                    const producttype =
                      props.row.producttypeVerwacht !== null
                        ? props.row.producttypeVerwacht
                        : props.row.producttype;
                    return <span>{producttype.Merknaam}</span>;
                  }}
                />
                <DataTypeProvider
                  for={['__typenaam']}
                  formatterComponent={(props) => {
                    const rij: IRow = props.row;
                    const producttype =
                      rij.producttypeVerwacht !== null ? rij.producttypeVerwacht : rij.producttype;
                    return <span>{producttype.Typenaam}</span>;
                  }}
                />

                <DataTypeProvider
                  for={['__producttype']}
                  formatterComponent={(props) => {
                    const rij: IRow = props.row;
                    return <ProducttypeVisualisatie typeID={rij.producttype.TypeID} />;
                  }}
                />

                <DataTypeProvider
                  for={['__alternatief']}
                  formatterComponent={(props) => {
                    const rij: IRow = props.row;

                    if (rij.producttypeVerwacht === null) {
                      return <span />;
                    }

                    return (
                      <ProducttypeVisualisatie
                        typeID={rij.producttypeVerwacht.TypeID}
                        weergave={EWeergave.Typenaam}
                      />
                    );
                    // return <span>{rij.producttypeVerwacht.Typenaam}</span>;
                  }}
                />

                <DataTypeProvider
                  for={['DatumGewenst']}
                  formatterComponent={(props) => {
                    const datum =
                      props.value !== null ? format(new Date(props.value), 'dd-MM-yyyy') : 'Z.s.m.';
                    return (
                      <>
                        <span>{datum}</span>
                      </>
                    );
                  }}
                />

                <DataTypeProvider
                  for={['__datumVerwacht']}
                  formatterComponent={(props) => {
                    const datumGewenst =
                      props.row.DatumGewenst !== null
                        ? format(new Date(props.row.DatumGewenst), '(' + 'dd-MM-yyyy') + ')'
                        : 'Z.s.m.';

                    const datumVerwacht =
                      props.row.DatumVerwacht !== null
                        ? format(new Date(props.value), 'dd-MM-yyyy')
                        : datumGewenst;

                    const indicatieDatum =
                      props.row.DatumVerwacht !== null
                        ? props.row.DatumVerwacht
                        : props.row.DatumGewenst !== null
                        ? props.row.DatumGewenst
                        : null;
                    const indicatie =
                      indicatieDatum !== null && indicatieDatum < format(new Date(), 'yyyy-MM-dd')
                        ? 'X'
                        : '';

                    if (indicatie) {
                      return <span style={{ color: Kleur.Rood }}>{datumVerwacht}</span>;
                    }
                    return <span>{datumVerwacht}</span>;
                  }}
                />

                <DataTypeProvider
                  for={['__geenMelding']}
                  formatterComponent={(props) => {
                    const rij: IRow = props.row;
                    if (rij.GeenTeLaatMelding) {
                      return (
                        <UitlegTooltip inhoud="Er wordt geen herinnerings-controltaak (meer) gemaakt indien er niet tijdig geleverd is.">
                          <span style={{ color: 'red' }}>
                            <IconGeenMelding
                              height={18}
                              width={18}
                              style={{ fill: Kleur.LichtGrijs }}
                            />
                          </span>
                        </UitlegTooltip>
                      );
                    }

                    return <span></span>;
                  }}
                />

                {/* <DataTypeProvider
                  for={['AantalGeleverdeProducten']}
                  formatterComponent={(props) => {
                    return (
                      <>
                        <span>{props.value === 0 ? '' : props.value}</span>
                      </>
                    );
                  }}
                /> */}

                <DataTypeProvider
                  for={['AantalGeleverdeProducten']}
                  formatterComponent={(props) => {
                    return (
                      <span
                        style={{
                          color:
                            props.row.AantalGeleverdeProducten === props.row.Aantal
                              ? Kleur.Groen
                              : '',
                        }}
                      >
                        {props.row.AantalGeleverdeProducten !== 0
                          ? props.row.AantalGeleverdeProducten
                          : ''}
                      </span>
                    );
                  }}
                />

                <DataTypeProvider
                  for={['DatumOpdracht']}
                  formatterComponent={(props) => {
                    // const datum =
                    //   props.value !== null ? format(new Date(props.value), 'dd-MM-yyyy') : 'Z.s.m.';
                    return (
                      <>
                        <span>{format(new Date(props.value), 'dd-MM-yyyy')}</span>
                      </>
                    );
                  }}
                />

                <DataTypeProvider
                  for={['__voorraadInfo']}
                  formatterComponent={(formatterProps) => {
                    const rij: IRow = formatterProps.row;

                    return (
                      <div className="d-flex">
                        <a
                          href="#"
                          onClick={() => {
                            setVoorraadTypeInfoDialoogState({
                              typeID: rij.producttype.TypeID,
                              magID: rij.magazijn.MagID,
                            });
                          }}
                        >
                          {/* <VoorraadIndicatie
                            status={gereserveerd ? EVoorraadStatus.Groen : EVoorraadStatus.Rood}
                          /> */}
                          <IconInformatie style={{ width: 15, height: 15, fill: Kleur.Blauw }} />
                        </a>
                      </div>
                    );
                  }}
                />

                <DataTypeProvider
                  for={['__inslag']}
                  formatterComponent={(formatterProps) => {
                    const rij: IRow = formatterProps.row;

                    return (
                      <div className="d-flex">
                        <a
                          href="#"
                          onClick={() => {
                            setInslagDialoogState({
                              inkOpdID: rij.InkOpdID,
                            });
                          }}
                        >
                          <span>inslag</span>
                        </a>
                      </div>
                    );
                  }}
                />

                <SelectionState
                  selection={urlState.selectie}
                  onSelectionChange={(x) => setUrlStateSync('selectie', x as number[])}
                />
                <EditingState
                  onCommitChanges={() => null}
                  onEditingRowIdsChange={(rowIds) => {
                    const id = rowIds[rowIds.length - 1] as number;
                    setInkOpdID(id);
                    setTonenStatusDialoog(true);
                  }}
                />

                <SortingState
                  sorting={urlState.sortering}
                  onSortingChange={(value) => setUrlStateSync('sortering', value)}
                />
                {/* <IntegratedSorting /> */}
                <IntegratedSorting
                  columnExtensions={[
                    {
                      columnName: '__datumVerwacht',
                      compare: (links, rechts) => {
                        const linksDatum = links === null ? null : new Date(links);
                        const rechtsDatum = links === null ? null : new Date(rechts);

                        if (linksDatum === null) {
                          return -1;
                        }
                        if (rechtsDatum === null) {
                          return 1;
                        }

                        return linksDatum.getTime() - rechtsDatum.getTime();
                      },
                    },
                  ]}
                />

                <RowDetailState defaultExpandedRowIds={[]} />

                <VirtualTable messages={geenData} />

                <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
                <TableHeaderRow showSortingControls />

                <TableEditColumn
                  width={35}
                  showEditCommand
                  cellComponent={DXTableEditColumnCellComponent}
                  commandComponent={DXTableEditColumnCommandComponent}
                />

                <TableRowDetail
                  contentComponent={DetailComp}
                  toggleCellComponent={DXTableToggleCellComponent}
                />
                <TableSelection cellComponent={DXTableCheckboxComponent} />
              </Grid>
            </GridStyleWrapper>
          )}
        </div>
      </div>

      {inslagDialoogState !== null && (
        <InslagDialoog
          inkOpdID={inslagDialoogState.inkOpdID}
          open
          onSuccess={() => {
            setInslagDialoogState(null);
            ophalenOpdrachten();
            setUrlStateSync('selectie', []);
          }}
          onAnnuleren={() => setInslagDialoogState(null)}
        />
      )}

      {/* {tonenInslagDialoog && (
        <InslagDialoog
          inkOpdID={inkOpdID}
          open
          onSuccess={() => {
            setTonenInslagDialoog(false);
            setUrlStateSync('selectie', []);
            ophalenOpdrachten();
          }}
          onAnnuleren={() => setTonenInslagDialoog(false)}
        />
      )} */}

      {tonenStatusDialoog && (
        <WijzigenStatusDialoog
          inkOpdID={inkOpdID!}
          open
          onSuccess={() => {
            setTonenStatusDialoog(false);
            ophalenOpdrachten();
          }}
          onAnnuleren={() => setTonenStatusDialoog(false)}
        />
      )}

      {tonenVersturenDialoog && (
        <VersturenDialoog
          inkOpdIDs={urlState.selectie}
          open
          onSuccess={() => {
            setTonenVersturenDialoog(false);
            ophalenOpdrachten();
          }}
          onAnnuleren={() => {
            setTonenVersturenDialoog(false);
          }}
        />
      )}

      {voorraadTypeInfoDialoogState !== null && (
        <VoorraadTypeInfoDialoog
          open
          typeID={voorraadTypeInfoDialoogState.typeID}
          magID={voorraadTypeInfoDialoogState.magID}
          onSuccess={() => setVoorraadTypeInfoDialoogState(null)}
          onAnnuleren={() => setVoorraadTypeInfoDialoogState(null)}
        />
      )}
    </InkoopopdrachtenContext.Provider>
  );
};

export default withRouter(UitstaandTab);
