import React, { useContext, useEffect, useMemo, useState, useCallback } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import LoadingSpinner from '../../../components/Gedeeld/LoadingSpinner';
import useUrlState from '../../../core/useUrlState';
import api from '../../../api';
import { addMonths, format } from 'date-fns';
import _ from 'lodash';
import { IOphalenContractenResultElement } from '../../../../../shared/src/api/v2/contract';
import { EContractStatus } from '../../Klant/Klantkaart/Entiteiten/entiteiten/Contracten/Overzicht/ContractTegel/ContractStatus';
import { IOphalenProceduresResultElement } from '../../../../../shared/src/api/v2/relatie/acceptatie';
import {
  DXTableCheckboxComponent,
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
  DXTableToggleCellComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../helpers/dxTableGrid';
import {
  Grid,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  DataTypeProvider,
  EditingState,
  RowDetailState,
  SelectionState,
} from '@devexpress/dx-react-grid';
import RelatieVisualisatie from '../../../components/personalia/RelatieVisualisatie';
import nameof from '../../../core/nameOf';
import DetailComp from './DetailComp';
import { EProcedureStatus } from '../../../bedrijfslogica/enums';

interface IProps extends RouteComponentProps {}

interface IUrlState {}

const defaultUrlState: IUrlState = {};

export interface IRegel extends IOphalenProceduresResultElement {}

const Procedures: React.FC<IProps> = observer((props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);

  const [procedures, setProcedures] = useState<IRegel[] | null>(null);

  const ophalenProcedures = useCallback(async () => {
    // const peildatum = addMonths(new Date(), -2);
    const procedures = (
      await api.v2.relatie.acceptatie.ophalenProcedures({
        // filterSchema: {
        //   filters: [
        //     // { naam: 'STATUS_NAAMENUM', data: [EContractStatus.Geannuleerd] },
        //   ],
        // },
        orderSchema: {
          orders: [
            {
              naam: 'DATUM',
              richting: 'DESC',
            },
          ],
        },
      })
    ).procedures;

    setProcedures(procedures);
  }, []);

  useEffect(() => {
    ophalenProcedures();
  }, [ophalenProcedures]);

  const keyExtractor = useCallback((row: IRegel) => row.ID, []);

  const kolommen = useMemo<TypedColumn<IRegel>[]>(
    () => [
      {
        name: 'Datum',
        title: 'Datum',
      },
      {
        name: '__relatienaam' as any,
        title: 'Relatie',
      },
      {
        name: '__documenten' as any,
        title: 'Documenten',
      },
      {
        name: '__status' as any,
        title: 'Status',
      },
      {
        name: '__documentenStatus' as any,
        title: 'Documentenstatus',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRegel>[]>(
    () => [
      {
        columnName: 'Datum',
        width: 125,
      },
      {
        columnName: '__status' as any,
        width: 135,
      },
      {
        columnName: '__relatienaam' as any,
        width: 250,
      },
      {
        columnName: '__documenten' as any,
        width: 225,
      },
      {
        columnName: '__documentenStatus' as any,
        width: 250,
      },
    ],
    [],
  );

  return (
    <>
      {procedures === null ? (
        <div className="d-flex flex-fill align-items-center justify-content-center">
          <LoadingSpinner />
        </div>
      ) : (
        <GridStyleWrapper height={'calc(100vh - 150px)'}>
          <Grid rows={procedures} columns={kolommen} getRowId={keyExtractor}>
            <DataTypeProvider
              for={[nameof<IRegel>('Datum')]}
              formatterComponent={(props) => (
                <span>{format(new Date(props.value), 'dd-MM-yyyy')}</span>
              )}
            />

            <DataTypeProvider
              for={['__relatienaam']}
              formatterComponent={(props) => {
                const rij: IRegel = props.row;
                return (
                  <RelatieVisualisatie
                    relID={rij.RelID}
                    // weergaveNaam={rij}
                  />
                );
              }}
            />

            <DataTypeProvider
              for={['__status']}
              formatterComponent={(props) => {
                const rij: IRegel = props.row;
                return <span>{rij.status.Naam}</span>;
              }}
            />

            <DataTypeProvider
              for={['__documenten']}
              formatterComponent={(props) => {
                const rij: IRegel = props.row;
                const documenten = rij.documenten
                  .map((x) => {
                    return x.NaamKort;
                  })
                  .join(', ');
                return <span>{documenten}</span>;
              }}
            />

            <DataTypeProvider
              for={['__documentenStatus']}
              formatterComponent={(props) => {
                const rij: IRegel = props.row;

                if (
                  rij.status.NaamEnum !== EProcedureStatus.Concept &&
                  rij.status.NaamEnum !== EProcedureStatus.Uitstaand
                ) {
                  return <span></span>;
                }

                if (rij.documenten.every((x) => !x.Ontvangen)) {
                  return <span>Nog niets ontvangen</span>;
                }
                if (rij.documenten.every((x) => x.Ontvangen)) {
                  return <span>Alles ontvangen</span>;
                }
                return <span>Deels ontvangen</span>;
              }}
            />

            {/* <SelectionState
              selection={props.actieclaimsSelectie}
              onSelectionChange={(value) => props.onActieclaimsSelectieChange(value as number[])}
            /> */}

            <RowDetailState defaultExpandedRowIds={[]} />

            <VirtualTable />
            <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
            <TableHeaderRow />

            <EditingState
              onCommitChanges={(changes) => {
                if (changes.deleted === undefined) {
                  return;
                }
                const deleted = changes.deleted;
                const id = deleted[deleted.length - 1];
                alert(id);
              }}
              onEditingRowIdsChange={(rowIds) => {
                const id = rowIds[rowIds.length - 1] as number;
                // props.onWijzigenOpdrachtDialoogStateChange({ trsOpdID: id });
                // alert(id);
              }}
            />

            <TableEditColumn
              width={35}
              showEditCommand
              cellComponent={DXTableEditColumnCellComponent}
              commandComponent={DXTableEditColumnCommandComponent}
            />
            <TableRowDetail
              toggleCellComponent={DXTableToggleCellComponent}
              contentComponent={DetailComp}
            />
            {/* <TableSelection cellComponent={DXTableCheckboxComponent} /> */}
          </Grid>
        </GridStyleWrapper>
      )}
    </>
  );
});

export default withRouter(Procedures);
