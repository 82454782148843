import React, { useMemo, useState } from 'react';
import { IconZoeken, IconKruis } from '../../Icons';
import { Kleur } from '../../../bedrijfslogica/constanten';
import styled from 'styled-components';

export enum EThema {
  Licht,
  Donker,
}

interface IRootProps {
  thema: EThema;
}

const Root = styled.div<IRootProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  position: relative;
  background-color: ${(props) =>
    props.thema === EThema.Licht ? `rgba(200, 200, 200, 0.3)` : `rgba(150, 150, 150, 0.3)`};
  border-radius: 3px;
  border: 2px solid transparent;
  transition: border 0.2s ease-in-out;

  &.focus {
    border: 2px solid ${Kleur.SelectieBlauw};
  }
`;

interface IInputProps {
  thema: EThema;
}

const Input = styled.input<IInputProps>`
  flex: 1;
  background: transparent;
  border: 0;
  width: 100%;
  height: 30px;
  margin-left: 3px;
  color: ${(props) => (props.thema === EThema.Licht ? Kleur.DonkerGrijs : Kleur.HeelLichtGrijs)};
  outline: 0;

  &::placeholder {
    color: ${(props) => (props.thema === EThema.Licht ? Kleur.Grijs : Kleur.Wit)};
  }
`;

interface IProps {
  input: string;
  onInputChange: (input: string) => void;
  onEnter?: () => void;
  placeholder?: string;
  autoFocus?: boolean;
  thema?: EThema;
  rootStyle?: React.CSSProperties;
  onFocus?: () => void;
  onBlur?: () => void;
}

const Zoeken = React.forwardRef<HTMLInputElement, IProps>((props: IProps, ref) => {
  const [isFocused, setIsFocused] = useState(false);
  const thema = useMemo(() => props.thema ?? EThema.Licht, [props.thema]);

  return (
    <Root thema={thema} className={isFocused ? 'focus' : ''} style={props.rootStyle}>
      <div
        className="d-flex flex-column justify-content-center align-items-center"
        style={{
          height: '100%',
          marginLeft: 7,
        }}
      >
        <IconZoeken
          style={{ fill: thema === EThema.Licht ? Kleur.Grijs : Kleur.Wit, width: 18, height: 18 }}
        />
      </div>
      <Input
        ref={ref}
        thema={thema}
        value={props.input}
        onChange={(ev) => props.onInputChange(ev.target.value)}
        onKeyUp={async (ev) => {
          if (ev.key === 'Enter') {
            props.onEnter?.();
          }
        }}
        placeholder={props.placeholder}
        autoFocus={props.autoFocus}
        onFocus={() => {
          setIsFocused(true);
          props.onFocus?.();
        }}
        onBlur={() => {
          setIsFocused(false);
          props.onBlur?.();
        }}
      />
      {props.input.length !== 0 && (
        <button
          className="d-flex justify-content-center align-items-center"
          style={{
            position: 'absolute',
            right: 0,
            outline: 0,
            border: 0,
            background: 0,
            height: '100%',
          }}
          onClick={() => props.onInputChange('')}
          tabIndex={-1}
        >
          <IconKruis
            style={{
              fill: thema === EThema.Licht ? Kleur.Grijs : Kleur.Wit,
              height: 18,
              width: 18,
              position: 'relative',
              top: 1,
            }}
          />
        </button>
      )}
    </Root>
  );
});

export default Zoeken;
