import React, { useState, useCallback, useEffect, useContext, useMemo } from 'react';
import { RouteComponentProps } from 'react-router';
import api from '../../../api';
import LoadingSpinner from '../../../components/Gedeeld/LoadingSpinner';
import useUrlState from '../../../core/useUrlState';
import { RootStoreContext } from '../../../stores/RootStore';
import MenuLayout from '../../../components/MenuLayout';
import WinkelwagenTabel, { IRow } from '../../../components/winkelwagen/WinkelwagenTabel';
import _ from 'lodash';
import { addDays, format } from 'date-fns';
import { Helmet } from 'react-helmet';
import FilterBalkV2, {
  IFilter,
  IFilterData,
  maakFilterSchema,
} from '../../../components/FilterBalkV2';
import { IFilterSchema } from '../../../../../shared/src/models/filter';
import Combobox from '../../../components/formulier/Combobox';

enum EIsVerwerktOptie {
  Ja,
  Nee,
}

interface IProps extends RouteComponentProps {}

export enum EFilter {
  IsVerwerkt = 'IS_VERWERKT',
}

export interface IUrlState {
  selectie: number[];
  uitgeklapt: number[];
  filterData: IFilterData<EFilter>[];
}

const defaultUrlState: IUrlState = {
  selectie: [],
  uitgeklapt: [],
  filterData: [
    {
      naam: EFilter.IsVerwerkt,
      data: true,
      isActief: false,
    },
  ],
};

const Winkelwagens: React.FC<IProps> = (props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const { checkStore, instellingStore } = useContext(RootStoreContext);

  const [filterSchema, setFilterSchema] = useState<IFilterSchema>(
    maakFilterSchema(urlState.filterData),
  );

  const [winkelwagensResult, setWinkelwagensResult] = useState<IRow[] | null>(null);
  const expiratiedatumVanaf = format(addDays(new Date(), -10), 'yyyy-MM-dd');

  const ophalenWinkelwagens = useCallback(async () => {
    const winkelwagensResult = (
      await api.v2.winkelwagen.ophalenWinkelwagens({
        filterSchema: {
          filters: [
            ...(filterSchema?.filters ?? []),
            { naam: 'RECORD_TOEGEVOEGD', data: expiratiedatumVanaf },
          ],
        },
        orderSchema: {
          orders: [
            {
              naam: 'RECORD_GEWIJZIGD',
              richting: 'DESC',
            },
          ],
        },
      })
    ).winkelwagens;

    const accIDs = _.uniq(winkelwagensResult.map((x) => x.AccID));
    const accountsResult = await api.v2.account.ophalenAccounts({
      filterSchema: { filters: [{ naam: 'IDS', data: accIDs }] },
    });

    const winkelwagens = winkelwagensResult.map((x) => {
      const account = x.AccID !== null ? accountsResult.find((a) => a.AccID === x.AccID)! : null;
      // const contracten = contractenResult.filter()
      return { ...x, account };
    });

    setWinkelwagensResult(winkelwagens);
  }, [filterSchema]);

  useEffect(() => {
    ophalenWinkelwagens();
  }, [ophalenWinkelwagens]);

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.IsVerwerkt,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return (
            <div className="d-flex align-items-center">
              <span className="mr-2">Verwerkt</span>
              <Combobox
                geselecteerd={
                  weergaveProps.data === true ? EIsVerwerktOptie.Ja : EIsVerwerktOptie.Nee
                }
                opties={[
                  {
                    id: EIsVerwerktOptie.Ja,
                    label: 'Ja',
                  },
                  {
                    id: EIsVerwerktOptie.Nee,
                    label: 'Nee',
                  },
                ]}
                onSelectieChange={(optie) => {
                  weergaveProps.onDataChange(optie === EIsVerwerktOptie.Ja);
                  weergaveProps.setIsActief(true);
                  weergaveProps.toepassen();
                }}
              />
            </div>
          );
        },
      },
    ],
    [],
  );

  return (
    <>
      <Helmet>
        <title>Winkelwagens</title>
      </Helmet>
      <div className="d-flex flex-fill flex-column">
        {winkelwagensResult === null ? (
          <div className="flex-fill d-flex align-items-center justify-content-center">
            <LoadingSpinner />
          </div>
        ) : (
          <WinkelwagenTabel
            winkelwagens={winkelwagensResult}
            selectie={urlState.selectie}
            onSelectieChange={(x) => setUrlStateSync('selectie', x)}
            uitgeklapt={urlState.uitgeklapt}
            onUitgeklaptChange={(x) => setUrlStateSync('uitgeklapt', x)}
            onRequestRefresh={() => ophalenWinkelwagens()}
            filterbalk={
              <FilterBalkV2
                filters={filters}
                filterData={urlState.filterData}
                onFilterDataChange={(x) => setUrlStateSync('filterData', x)}
                onFilterSchemaChange={(x) => setFilterSchema(x)}
              />
            }
          />
        )}
      </div>
    </>
  );
};

export default Winkelwagens;
