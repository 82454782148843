import {
  IOphalenProductenParams,
  IOphalenProductenResult,
} from '../../../../../../shared/src/api/v2/retourverwerking/afvoer';
import api from '../../../index';

const afvoer = {
  ophalenProducten: async (params: IOphalenProductenParams): Promise<IOphalenProductenResult> => {
    return await api.post('/v2/retourverwerking/afvoer/ophalen-producten', params);
  },
};

export default afvoer;
