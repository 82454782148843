import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import IDialoogProps from '../../../../core/IDialoogProps';
import Dialoog from '../../../../components/dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import * as Yup from 'yup';
import { IOphalenProductsoortenResultElement } from '../../../../../../shared/src/api/v2/product/soort';
import { IOphalenProductmerkenResultElement } from '../../../../../../shared/src/api/v2/product/merk';
import { IOphalenDienstenResultElement } from '../../../../../../shared/src/api/v2/dienst/inkoop';
import api from '../../../../api';
import nameOf from '../../../../core/nameOf';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../../stores/RootStore';
import Combobox, { IOptie } from '../../../../components/formulier/Combobox';

import { IOphalenProducttypenResultElement } from '../../../../../../shared/src/api/v2/product/type';
import VinkVeld from '../../../../components/formulier/VinkVeld';
import FormikVeldFout from '../../../../components/formulier/FormikVeldFout';

interface IProps extends IDialoogProps<null> {
  typeID: number;
}

interface IFormikValues {
  kenmerk: string | null;
  typenaam: string;
  merkID: number | null;
  inkoopToegestaan: boolean;
  prodSrtID: number;
  notities: string | null;
  eancode: string | null;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  typenaam: 'Type',
  kenmerk: 'Kenmerk',
  merkID: 'Merk',
  inkoopToegestaan: 'Inkoop toegestaan',
  prodSrtID: 'Productsoort',
  notities: 'Notities',
  eancode: 'EAN-code (unieke internationale code voor dit product)',
};

const WijzigDialoog: React.FC<IProps> = observer((props) => {
  const { children, dialoogIndex, onAnnuleren, onSuccess, open } = props;
  const { instellingStore, checkStore } = useContext(RootStoreContext);

  const [producttype, setProducttype] = useState<IOphalenProducttypenResultElement | null>(null);

  const [merken, setMerken] = useState<IOphalenProductmerkenResultElement[] | null>(null);
  const [diensten, setDiensten] = useState<IOphalenDienstenResultElement[] | null>(null);
  const [productsoorten, setProductsoorten] = useState<
    IOphalenProductsoortenResultElement[] | null
  >(null);

  useEffect(() => {
    (async () => {
      const results = await api.v2.product.type.ophalenProducttypen({
        filterSchema: { filters: [{ naam: 'IDS', data: [props.typeID] }] },
      });
      setProducttype(results.producttypen[0]);
    })();
  }, [props.typeID]);

  useEffect(() => {
    (async () => {
      const results = await api.v2.product.soort.ophalenProductsoorten({
        filterSchema: { filters: [] },
      });
      setProductsoorten(results);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const productmerkenResults = await api.v2.product.merk.ophalenProductmerken({
        filterSchema: { filters: [{ naam: 'IS_ACTIEF', data: true }] },
      });
      setMerken(productmerkenResults.productmerken);
    })();
  }, []);

  // useEffect(() => {
  //   (async () => {
  //     const { diensten } = await api.v2.dienst.inkoop.ophalenDiensten({
  //       filterSchema: { filters: [{ naam: 'ACTIEF', data: true }] },
  //     });

  //     setDiensten(diensten);
  //   })();
  // }, []);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      const params = {
        typeID: props.typeID,
        typenaam: values.typenaam,
        kenmerk: values.kenmerk,
        // merkID: values.merkID!,
        inkoopToegestaan: values.inkoopToegestaan,
        prodSrtID: values.prodSrtID,
        notities: values.notities,
        eancode: values.eancode,
      };

      // const checkData = await api.v2.inkoop.aanbod.checkNieuwProduct(params);
      // const controleResult = await checkStore.controleren({
      //   checkData,
      // });
      // if (controleResult.type === EResultType.Annuleren) {
      //   return;
      // }

      await api.v2.inkoop.aanbod.wijzigenInkoopproduct(params);

      actions.setSubmitting(false);
      onSuccess(null);
    },
    [],
  );

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (producttype === null) {
      return null;
    }

    return {
      typenaam: producttype.Typenaam,
      kenmerk: producttype.Kenmerk,
      merkID: producttype.MerkID,
      inkoopToegestaan: producttype.InkoopToegestaan,
      prodSrtID: producttype.ProdSrtID,
      notities: producttype.Notities,
      eancode: producttype.EANCode,
    };
  }, [producttype]);

  const validationSchema = useMemo(() => {
    const fields: Record<keyof IFormikValues, any> = {
      typenaam: Yup.string().required(),
      kenmerk: Yup.string()
        .required()
        .nullable(),
      merkID: Yup.number(),
      inkoopToegestaan: Yup.boolean(),
      prodSrtID: Yup.number(),
      notities: Yup.string().nullable(),
      eancode: Yup.string().nullable(),
    };
    return Yup.object().shape(fields);
  }, []);

  return (
    <Dialoog index={dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Wijzigen product</ModalTitle>
      </ModalHeader>
      {initialValues === null ? (
        <div className="d-flex flex-column flex-fill align-items-center justify-content-center">
          <LoadingSpinner />
        </div>
      ) : (
        <Formik<IFormikValues>
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
          render={(formikProps: FormikProps<IFormikValues>) => {
            const { submitForm, isSubmitting, isValid } = formikProps;
            return (
              <>
                <ModalBody>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-12">
                        <Field
                          name={nameOf<IFormikValues>('prodSrtID')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            if (productsoorten === null) {
                              return <LoadingSpinner />;
                            }

                            const opties = productsoorten.map((x) => {
                              return {
                                id: x.ProdSrtID,
                                label: x.Naam,
                              };
                            });

                            return (
                              <>
                                <label>{veldnamen.prodSrtID}</label>
                                <Combobox
                                  geselecteerd={field.value}
                                  legeOptieTonen
                                  onSelectieChange={(x) => {
                                    form.setFieldValue(field.name, x);
                                  }}
                                  opties={opties}
                                />
                                {/* <FormikVeldFout fieldProps={fieldProps} /> */}
                              </>
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <label>{veldnamen.typenaam}</label>
                        <Field
                          name={nameOf<IFormikValues>('typenaam')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return <input className="form-control" type="text" {...field} />;
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <label>{veldnamen.kenmerk}</label>
                        <Field
                          name={nameOf<IFormikValues>('kenmerk')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return <input className="form-control" type="text" {...field} />;
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <label>{veldnamen.eancode}</label>
                        <Field
                          name={nameOf<IFormikValues>('eancode')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return <input className="form-control" type="text" {...field} />;
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3 d-flex align-items-center">
                        <Field
                          name={nameOf<IFormikValues>('inkoopToegestaan')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <VinkVeld
                                aangevinkt={field.value}
                                onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                              />
                            );
                          }}
                        />
                        <span className="ml-2">{veldnamen.inkoopToegestaan}</span>
                      </div>

                      <div className="col-12 mt-3">
                        <label>Notities</label>
                        <Field
                          name={nameOf<IFormikValues>('notities')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            return (
                              <textarea
                                // ref={notitiesRef}
                                {...field}
                                className="form-control"
                                rows={2}
                                placeholder={'B.v. Dit type gaat uit het assorti'}
                              />
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </ModalBody>

                <ModalFooter className="d-flex flex-row justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    style={{ width: 100 }}
                    disabled={isSubmitting || !isValid}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={onAnnuleren}
                    style={{ width: 100 }}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Dialoog>
  );
});

export default WijzigDialoog;
