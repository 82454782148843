import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
  DataTypeProvider,
  EditingState,
  IntegratedSorting,
  RowDetailState,
  SelectionState,
  SortingState,
  VirtualTable as VirtualTableBase,
} from '@devexpress/dx-react-grid';
import {
  DXTableCheckboxComponent,
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../helpers/dxTableGrid';
import LoadingSpinner from '../../Gedeeld/LoadingSpinner';
import MenuLayout from '../../MenuLayout';
import { Kleur as EKleur } from '../../../bedrijfslogica/constanten';
import {
  Grid,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import { format } from 'date-fns';
import FormatteerBedrag from '../../MutatieBedrag';
import RelatieVisualisatie from '../../personalia/RelatieVisualisatie';
import {
  IconDownload,
  IconInformatie,
  IconToevoegen,
  IconVerwijderen,
  IconWijzigen,
} from '../../Icons';
import { EDagboeksoort } from '../../../bedrijfslogica/enums';
import api from '../../../api';
import {
  IOphalenBoekingenResultElement,
  IOphalenBoekingRegelsResultElement,
  IOphalenWerkkostenRegelingenResult,
} from '../../../../../shared/src/api/v2/boekhouding/boeking';
import {
  IOphalenDagboekenResult,
  IOphalenDagboekenResultElement,
} from '../../../../../shared/src/api/v2/boekhouding/boeking/dagboek';
import { IOphalenGrootboekenResult } from '../../../../../shared/src/api/v2/boekhouding/boeking/grootboek';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../models/IRemoteData';
import {
  IOphalenFacturenBasisResult,
  IOphalenFacturenBasisResultElement,
} from '../../../../../shared/src/api/v2/factuur';
import {
  IOphalenFacturenResult,
  IOphalenFacturenResultElement,
} from '../../../../../shared/src/api/v2/inkoopfactuur';
import { IOphalenBtwSoortenResult } from '../../../../../shared/src/api/v2/btw';
import { AutoSizer } from 'react-virtualized';
import nameOf from '../../../core/nameOf';
import FactuurVisualisatie from '../../entiteitVisualisaties/FactuurVisualisatie';
import BoekingInfoDialoog from '../boeking/BoekingInfoDialoog';
import _ from 'lodash';
import BoekingregelInfoDialoog from '../boeking/BoekingregelInfoDialoog';
import { RootStoreContext } from '../../../stores/RootStore';
import { EResultType } from '../../../stores/CheckStore';
import UitlegTooltip from '../../formulier/UitlegTooltip';
import BoekingDialoogV3 from '../boeking/BoekingDialoogV3';
import { IRegelsProvider } from '../boeking/BoekingDialoogV3/providers/regels';
import { IDagboekenProvider } from '../boeking/BoekingDialoogV3/providers/dagboeken';
import { IDagboekVoorselectieProvider } from '../boeking/BoekingDialoogV3/providers/dagboekVoorselectie';
import { IBoekdatumProvider } from '../boeking/BoekingDialoogV3/providers/boekdatum';

interface IBoekingDialoogState {
  boekingID?: number;
  boekdatum?: Date | string;
}

interface IBoekingInfoDialoogState {
  boekingID: number;
}

interface IBoekingregelInfoDialoogState {
  id: number;
}

interface IProps {
  dagboekSrtID: number;
  boekingsregels: IOphalenBoekingRegelsResultElement[] | null;
  huidigeBoekingsregelsPaginatieIndex: number;
  totaalBoekingsregels: number | null;
  bezigMetOphalenBoekingsregels: boolean;
  boekingsregelsSelectie: number[];
  onBoekingsregelsSelectieChange: (value: number[]) => void;
  onRequestRefresh: () => void;

  boekingdialoogDagboekenProvider?: IDagboekenProvider;
  boekingdialoogDagboekVoorselectieProvider?: IDagboekVoorselectieProvider;
  boekingdialoogRegelsProvider?: IRegelsProvider;

  knoppenComponent?: React.ComponentType;
  filterbalk?: JSX.Element;
}

interface IRow extends IOphalenBoekingRegelsResultElement {
  boeking: IOphalenBoekingenResultElement;
  dagboek: IOphalenDagboekenResultElement;
}

export interface IBoekingenContext {
  // verversen: () => void;
  inkoopfacturen: IOphalenFacturenResultElement[];
  verkoopfacturen: IOphalenFacturenBasisResultElement[];
}

export const BoekingenContext = React.createContext<IBoekingenContext>(null as any);

const BoekingTabel: React.FC<IProps> = observer((props) => {
  const regels = props.boekingsregels;
  const { checkStore } = useContext(RootStoreContext);

  const naamGrootboekrekeningenWeergeven = false;

  const [dagboekenResult, setDagboekenResult] = useState<IOphalenDagboekenResult | null>(null);
  const ophalenDagboeken = useCallback(async () => {
    const result = await api.v2.boeking.dagboek.ophalenDagboeken({});
    setDagboekenResult(result);
  }, []);
  useEffect(() => {
    ophalenDagboeken();
  }, [ophalenDagboeken]);

  const [grootboekenResult, setGrootboekenResult] = useState<IOphalenGrootboekenResult | null>(
    null,
  );
  const ophalenGrootboeken = useCallback(async () => {
    if (regels === null) {
      return;
    }
    const ids = [...new Set(regels.flatMap((regel) => [regel.C_GrbRekID, regel.D_GrbRekID]))];
    const result = await api.v2.boeking.grootboek.ophalenGrootboeken({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: ids,
          },
        ],
      },
    });
    setGrootboekenResult(result);
  }, [regels]);
  useEffect(() => {
    ophalenGrootboeken();
  }, [ophalenGrootboeken]);

  const [facturenResult, setFacturenResult] = useState<
    IRemoteData<IOphalenFacturenBasisResult | null>
  >(createPendingRemoteData());
  const ophalenFacturen = useCallback(async () => {
    if (regels === null) {
      return;
    }
    const factIDs = regels.map((x) => x.FactID).filter((x) => x !== null) as number[];
    if (factIDs.length === 0) {
      setFacturenResult(createReadyRemoteData(null));
      return;
    }
    const result = await api.v2.factuur.ophalenFacturenBasis({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: factIDs,
          },
        ],
      },
    });
    setFacturenResult(createReadyRemoteData(result));
  }, [regels]);
  useEffect(() => {
    ophalenFacturen();
  }, [ophalenFacturen]);

  const [inkoopfacturenResult, setInkoopfacturenResult] = useState<
    IRemoteData<IOphalenFacturenResult | null>
  >(createPendingRemoteData());
  const ophalenInkoopfacturen = useCallback(async () => {
    if (regels === null) {
      return;
    }
    const inkFactIDs = regels.map((x) => x.InkFactID).filter((x) => x !== null) as number[];
    if (inkFactIDs.length === 0) {
      setInkoopfacturenResult(createReadyRemoteData(null));
      return;
    }
    const result = await api.v2.inkoopfactuur.ophalenFacturen({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: inkFactIDs,
          },
        ],
      },
    });
    setInkoopfacturenResult(createReadyRemoteData(result));
  }, [regels]);

  useEffect(() => {
    ophalenInkoopfacturen();
  }, [ophalenInkoopfacturen]);

  const [boekingdialoogState, setBoekingdialoogState] = useState<IBoekingDialoogState | null>(null);
  const [
    boekingInfoDialoogState,
    setBoekingInfoDialoogState,
  ] = useState<IBoekingInfoDialoogState | null>(null);
  const [
    boekingregelInfoDialoogState,
    setBoekingregelInfoDialoogState,
  ] = useState<IBoekingregelInfoDialoogState | null>(null);

  // const boekingdialoogBoekstukVoorselectieKiezer = useMemo<
  //   IBoekstukVoorselectieKiezer | null | undefined
  // >(() => {
  //   if (boekingdialoogState === null) {
  //     return null;
  //   }
  //   if (boekingdialoogState.boekstuk === undefined) {
  //     return undefined;
  //   }
  //   return {
  //     voorselecterenBoekstuk: async () => {
  //       return boekingdialoogState.boekstuk!;
  //     },
  //   };
  // }, [boekingdialoogState]);

  const boekingdialoogBoekdatumProvider = useMemo<IBoekdatumProvider | null | undefined>(() => {
    if (boekingdialoogState === null) {
      return null;
    }
    if (boekingdialoogState.boekdatum === undefined) {
      return undefined;
    }
    return {
      provide: async () => {
        return new Date(boekingdialoogState.boekdatum!);
      },
    };
  }, [boekingdialoogState]);

  const [btwSoortenResult, setBtwSoortenResult] = useState<IOphalenBtwSoortenResult | null>(null);
  const ophalenBtwSoorten = useCallback(async () => {
    const result = await api.v2.btw.ophalenBtwSoorten({});
    setBtwSoortenResult(result);
  }, []);
  useEffect(() => {
    ophalenBtwSoorten();
  }, [ophalenBtwSoorten]);

  const [
    werkkostenregelingenResult,
    setWerkkostenregelingenResult,
  ] = useState<IOphalenWerkkostenRegelingenResult | null>(null);
  const ophalenWerkkostenRegelingen = useCallback(async () => {
    const result = await api.v2.boeking.ophalenWerkkostenRegelingen({});
    setWerkkostenregelingenResult(result);
  }, []);
  useEffect(() => {
    ophalenWerkkostenRegelingen();
  }, [ophalenWerkkostenRegelingen]);

  const handleVerwijderen = useCallback(
    async (ids: number[]) => {
      const params = { ids };
      const checkData = await api.v2.boekhouding.boeking.checkVerwijderenBoekingen(params);
      const controleResult = await checkStore.controleren({ checkData });
      if (controleResult.type === EResultType.Annuleren) {
        return;
      }

      const resultaat = await checkStore.bevestigen({
        inhoud: `De ${ids.length} geselecteerde boekingen(en) verwijderen?`,
      });
      if (resultaat.type === EResultType.Annuleren) {
        return;
      }

      await api.v2.boekhouding.boeking.verwijderenBoekingen(params);

      props.onBoekingsregelsSelectieChange([]);

      props.onRequestRefresh();
    },
    [props.onRequestRefresh],
  );

  const keyExtractor = useCallback((row: IRow) => row.ID, []);

  const kolommen = useMemo<TypedColumn<IRow>[]>(() => {
    if (regels === null) {
      return [];
    }
    const aantalDagboeken = _.uniq(regels!.map((x) => x.boeking.DagboekID)).length;

    return [
      {
        name: '__muteren',
        title: ' ',
      },
      aantalDagboeken !== 1
        ? {
            name: '__dagboek' as any,
            title: 'Dagboek',
          }
        : null,
      // {
      //   name: '__boeknummer' as any,
      //   title: 'Boek#',
      //   getCellValue: (x) => {
      //     return x.boeking.Boeknummer;
      //   },
      // },
      {
        name: '__boekregel' as any,
        title: 'Boek#',
        getCellValue: (x: IRow) => {
          return x.boeking.Boeknummer.toString() + '-' + x.Regelnummer.toString();
        },
      },
      {
        name: '__boekinginfo' as any,
        title: ' ',
      },
      {
        name: '__boekdatum' as any,
        title: 'Boekdatum',
        getCellValue: (x: IRow) => {
          return x.boeking.Boekdatum;
        },
      },
      // {
      //   name: 'Definitief',
      //   title: 'Def.',
      // },
      // {
      //   name: 'Regelnummer',
      //   title: 'Rg.',
      // },
      {
        name: '__rekening' as any,
        title: 'Rekening',
      },
      {
        name: '__weergavenaamRelatie' as any,
        title: 'Deb./Cred.',
        // getCellValue: (x) => {
        //   const relatie = relaties!.find((r) => r.RelID === x.RelID);
        //   return relatie!.weergavenaam;
        // },
      },
      {
        name: '__boekstuknummer' as any,
        title: 'Boekstuknr',

        getCellValue: (x: IRow) => {
          if (x.InkFactID !== null) {
            const inkoopfactuur = inkoopfacturenResult.data?.facturen.find(
              (x) => x.InkFactID === x.InkFactID,
            );
            if (inkoopfactuur === undefined) {
              return null;
            }

            return inkoopfactuur.Boekstuknummer;
          }
          if (x.FactID !== null) {
            const verkoopfactuur = facturenResult.data?.facturen.find((x) => x.FactID === x.FactID);
            if (verkoopfactuur === undefined) {
              return null;
            }
            return verkoopfactuur.Factuurnummer;
          }
          return 1;
        },
      },
      {
        name: 'Bedrag',
        title: 'Bedrag',
      },
      {
        name: 'BtwBedrag',
        title: 'Btw.bd.',
      },
      {
        name: '__btwCode' as any,
        title: 'Cd',
      },
      {
        name: '__wkr' as any,
        title: 'Wkr.',
      },
      // {
      //   name: '__storno' as any,
      //   title: 'Sto.',
      // },
      {
        name: '__tegenRekening' as any,
        title: 'Tegenrek.',
      },
      {
        name: '__geexporteerd' as any,
        title: 'Exp.',
      },
      {
        name: 'Omschrijving',
        title: 'Omschrijving',
      },
      {
        name: '__regelinfo' as any,
        title: ' ',
      },
    ].filter((x) => x !== null) as TypedColumn<IRow>[];
  }, [inkoopfacturenResult, facturenResult, regels]);

  const kolombreedtes = useMemo<TypedTableColumnWidthInfo<IRow>[]>(
    () => [
      {
        columnName: '__muteren',
        width: 50,
      },
      // {
      //   columnName: '__boeknummer' as any,
      //   width: 80,
      // },
      {
        columnName: '__boekregel' as any,
        width: 90,
      },
      {
        columnName: '__boekinginfo' as any,
        width: 60,
      },
      {
        columnName: '__dagboek' as any,
        width: 150,
      },
      {
        columnName: '__boekdatum' as any,
        width: 110,
      },
      {
        columnName: 'Definitief',
        width: 75,
      },
      {
        columnName: 'Regelnummer',
        width: 60,
      },
      {
        columnName: 'Bedrag',
        width: 100,
      },
      {
        columnName: 'BtwBedrag',
        width: 100,
      },
      {
        columnName: '__btwCode',
        width: 60,
      },
      {
        columnName: '__wkr' as any,
        width: 70,
      },
      // {
      //   columnName: '__storno',
      //   width: 90,
      // },
      {
        columnName: '__rekening',
        width: naamGrootboekrekeningenWeergeven ? 190 : 100,
      },
      {
        columnName: '__tegenRekening',
        width: naamGrootboekrekeningenWeergeven ? 190 : 100,
      },
      {
        columnName: '__weergavenaamRelatie',
        width: 200,
      },
      {
        columnName: '__boekstuknummer',
        width: 120,
      },
      {
        columnName: '__geexporteerd' as any,
        width: 65,
      },
      {
        columnName: 'Omschrijving',
        width: 300,
      },
      {
        columnName: '__regelinfo' as any,
        width: 60,
      },
    ],
    [],
  );

  const kolomExtensies: VirtualTableBase.ColumnExtension[] = useMemo(() => {
    return [
      // { columnName: 'Bedrag', align: 'right' },
      // { columnName: 'BtwBedrag', align: 'right' },
    ];
  }, []);

  const KnoppenComponent = useMemo<React.ComponentType>(() => {
    if (props.knoppenComponent !== undefined) {
      return props.knoppenComponent;
    }
    return (p) => <div className="d-flex align-items-center">{p.children}</div>;
  }, [props.knoppenComponent]);

  const context = useMemo<IBoekingenContext | null>(() => {
    if (
      inkoopfacturenResult.state === ERemoteDataState.Pending ||
      facturenResult.state === ERemoteDataState.Pending
    ) {
      return null;
    }
    return {
      // verversen: () => props.onRequestRefresh,
      inkoopfacturen: inkoopfacturenResult.data !== null ? inkoopfacturenResult.data.facturen : [],
      verkoopfacturen: facturenResult.data !== null ? facturenResult.data.facturen : [],
    };
  }, [inkoopfacturenResult, facturenResult]);

  return (
    <AutoSizer className="flex-column d-flex flex-fill w-100">
      {({ height, width }) => {
        return (
          <>
            <div className="d-flex flex-fill w-100">
              {regels === null ||
              context === null ||
              props.totaalBoekingsregels === null ||
              dagboekenResult === null ||
              grootboekenResult === null ||
              facturenResult.state === ERemoteDataState.Pending ||
              inkoopfacturenResult.state === ERemoteDataState.Pending ||
              btwSoortenResult === null ||
              werkkostenregelingenResult === null ? (
                <div className="d-flex flex-fill align-items-center justify-content-center w-100">
                  <LoadingSpinner />
                </div>
              ) : (
                <>
                  <BoekingenContext.Provider value={context}>
                    <MenuLayout
                      menu={
                        <div className="d-flex align-items-center">
                          <KnoppenComponent>
                            <button
                              className="btn btn-sm btn-light d-flex align-items-center"
                              style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                              onClick={() => setBoekingdialoogState({})}
                            >
                              <IconToevoegen
                                style={{ width: 16, height: 16, fill: EKleur.Grijs }}
                              />
                              <span className="ml-2">Nieuwe boeking</span>
                            </button>
                            <button
                              className="btn btn-sm btn-light d-flex align-items-center ml-3"
                              style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                              disabled={props.boekingsregelsSelectie.length !== 1}
                              onClick={() => {
                                // alert('TODO');
                                const boekingID = props.boekingsregels?.find(
                                  (x) => x.ID === props.boekingsregelsSelectie[0],
                                )!.BoekingID!;
                                handleVerwijderen([boekingID]);
                              }}
                            >
                              <IconVerwijderen
                                style={{ width: 16, height: 16, fill: EKleur.Grijs }}
                              />
                              <span className="ml-2">Verwijderen boeking</span>
                            </button>

                            <button
                              className="btn btn-sm btn-light d-flex align-items-center ml-3"
                              style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                              disabled={props.boekingsregelsSelectie.length === 0}
                              onClick={async () => {
                                const checkNietAlleenEersteRegelsGesecteerd = props
                                  .boekingsregels!.filter(
                                    (x) => props.boekingsregelsSelectie.indexOf(x.ID) !== -1,
                                  )
                                  .some((x) => x.Regelnummer !== 1);

                                if (checkNietAlleenEersteRegelsGesecteerd) {
                                  if (
                                    (
                                      await checkStore.bevestigen({
                                        inhoud: (
                                          <span>
                                            Je hebt regels geselecteerd die niet de eerste regel van
                                            een boeking zijn. Wil je toch doorgaan?
                                          </span>
                                        ),
                                      })
                                    ).type === EResultType.Annuleren
                                  ) {
                                    return;
                                  }
                                  if (
                                    (
                                      await checkStore.bevestigen({
                                        inhoud: (
                                          <span>
                                            Wil je de geselecteerde boekingen exporteren?
                                            <br />
                                            Let op: voor nu is dit alleen een proef-export
                                          </span>
                                        ),
                                      })
                                    ).type === EResultType.Annuleren
                                  ) {
                                    return;
                                  }
                                }

                                const boekingIDs = props
                                  .boekingsregels!.filter(
                                    (x) => props.boekingsregelsSelectie.indexOf(x.ID) !== -1,
                                  )
                                  .map((x) => x.BoekingID);

                                if (
                                  (
                                    await checkStore.bevestigen({
                                      inhoud: (
                                        <span>
                                          Wil je de geselecteerde boekingen exporteren?
                                          <br />
                                          <br />
                                          Het resultaat wordt weergeven in het tabje Export, daar is
                                          het bestand te downloaden.
                                        </span>
                                      ),
                                    })
                                  ).type === EResultType.Annuleren
                                ) {
                                  return;
                                }

                                const filterdata = [{ naam: 'BOEKING_IDS', data: boekingIDs }];

                                await api.v2.boeking.exporteren({
                                  boekingIDs,
                                  proef: false,
                                  filterdataJSON: JSON.stringify(filterdata),
                                });

                                props.onRequestRefresh();
                              }}
                            >
                              <IconDownload style={{ width: 16, height: 16, fill: EKleur.Grijs }} />
                              <span className="ml-2">Exporteren boekingen</span>
                            </button>
                          </KnoppenComponent>

                          <div className="ml-2 flex-fill">{props.filterbalk}</div>
                        </div>
                      }
                      body={
                        <GridStyleWrapper height={height - 71}>
                          <Grid rows={regels} getRowId={keyExtractor} columns={kolommen}>
                            <DataTypeProvider
                              for={['__boekinginfo']}
                              formatterComponent={(formatterProps) => {
                                const rij: IRow = formatterProps.row;

                                return (
                                  <a
                                    href="#"
                                    onClick={() => {
                                      setBoekingInfoDialoogState({ boekingID: rij.BoekingID });
                                    }}
                                  >
                                    <IconInformatie
                                      style={{ width: 15, height: 15, fill: EKleur.Blauw }}
                                    />
                                  </a>
                                );
                              }}
                            />

                            <DataTypeProvider
                              for={['__regelinfo']}
                              formatterComponent={(formatterProps) => {
                                const rij: IRow = formatterProps.row;

                                return (
                                  <a
                                    href="#"
                                    onClick={() => {
                                      setBoekingregelInfoDialoogState({ id: rij.ID });
                                    }}
                                  >
                                    <IconInformatie
                                      style={{ width: 15, height: 15, fill: EKleur.Blauw }}
                                    />
                                  </a>
                                );
                              }}
                            />

                            <DataTypeProvider
                              for={['__muteren']}
                              formatterComponent={(formatterProps) => {
                                const rij: IRow = formatterProps.row;

                                if (rij.Regelnummer !== 1) {
                                  return <span />;
                                }

                                return (
                                  <button
                                    style={{
                                      outline: 0,
                                      background: 0,
                                      border: 0,
                                    }}
                                    onClick={() => {
                                      setBoekingdialoogState({
                                        boekingID: rij.BoekingID,
                                        // boekstuk,
                                        boekdatum: rij.boeking.Boekdatum,
                                      });
                                    }}
                                  >
                                    {/* <IconBoeking
                                      style={{ fill: EKleur.Grijs, width: 16, height: 16 }}
                                    /> */}
                                    <IconWijzigen
                                      style={{ fill: EKleur.Grijs, width: 16, height: 16 }}
                                    />
                                  </button>
                                );
                              }}
                            />

                            <DataTypeProvider
                              for={['__boekdatum']}
                              formatterComponent={(formatterProps) => {
                                const rij: IRow = formatterProps.row;
                                const datum = format(new Date(rij.boeking.Boekdatum), 'dd-MM-yyyy');

                                return <span>{datum}</span>;
                                if (rij.Regelnummer === 1) {
                                  return <span>{datum}</span>;
                                }
                                return <span style={{ color: EKleur.LichtGrijs }}>{datum}</span>;
                              }}
                            />

                            <DataTypeProvider
                              for={[nameOf('Definitief')]}
                              formatterComponent={(formatterProps) => {
                                const rij: IRow = formatterProps.row;

                                return (
                                  <span>
                                    {rij.boeking.Definitief ? (
                                      <span>Ja</span>
                                    ) : (
                                      <span style={{ color: EKleur.Blauw }}>Nee</span>
                                    )}
                                  </span>
                                );

                                if (rij.Regelnummer === 1) {
                                  return (
                                    <span>
                                      {rij.boeking.Definitief ? (
                                        <span>Ja</span>
                                      ) : (
                                        <span style={{ color: EKleur.Blauw }}>Nee</span>
                                      )}
                                    </span>
                                  );
                                }

                                return (
                                  <span style={{ color: EKleur.LichtGrijs }}>
                                    {rij.boeking.Definitief ? 'Ja' : 'Nee'}
                                  </span>
                                );
                              }}
                            />

                            {/* <DataTypeProvider
                              for={['__boeknummer']}
                              formatterComponent={(formatterProps) => {
                                const rij: IRow = formatterProps.row;
                                return <span>{rij.boeking.Boeknummer}</span>;
                                if (rij.Regelnummer === 1) {
                                  return <span>{rij.boeking.Boeknummer}</span>;
                                }
                                return (
                                  <span style={{ color: Kleur.LichtGrijs }}>
                                    {rij.boeking.Boeknummer}
                                  </span>
                                );
                              }}
                            /> */}

                            <DataTypeProvider
                              for={['__boekregel']}
                              formatterComponent={(formatterProps) => {
                                const rij: IRow = formatterProps.row;

                                // if (rij.Regelnummer === 1) {
                                //   return <span>{rij.boeking.Boeknummer}</span>;
                                // }
                                return (
                                  <span
                                    style={{
                                      color: !rij.boeking.Definitief ? EKleur.Rood : undefined,
                                    }}
                                  >
                                    {rij.boeking.Boeknummer}-{rij.Regelnummer}
                                  </span>
                                );
                              }}
                            />

                            <DataTypeProvider
                              for={['__dagboek']}
                              formatterComponent={(formatterProps) => {
                                const rij: IRow = formatterProps.row;

                                const dagboek = rij.dagboek.Nummer + ' - ' + rij.dagboek.NaamKort;
                                return <span>{dagboek}</span>;
                              }}
                            />

                            <DataTypeProvider
                              for={['__geexporteerd']}
                              formatterComponent={(formatterProps) => {
                                const rij: IRow = formatterProps.row;

                                return (
                                  <span>{rij.boeking.BoekingExportID !== null ? 'Ja' : 'Nee'}</span>
                                );
                              }}
                            />

                            <DataTypeProvider
                              for={['__weergavenaamRelatie']}
                              formatterComponent={(formatterProps) => {
                                const rij: IRow = formatterProps.row;

                                if (rij.RelID === null) {
                                  return <span>{rij.dbRekening.Naam}</span>;
                                }

                                return <RelatieVisualisatie relID={rij.RelID} />;
                              }}
                            />

                            <DataTypeProvider
                              for={['__rekening']}
                              formatterComponent={(formatterProps) => {
                                const rij: IRow = formatterProps.row;
                                const dbRekening = grootboekenResult!.grootboeken.find(
                                  (x) => x.ID === rij.D_GrbRekID,
                                );
                                if (dbRekening === undefined) {
                                  return <span />;
                                }

                                // if (
                                //   rij.boeking.dagboek.dagboekSoort.NaamEnum === EDagboeksoort.INKOOP
                                // ) {
                                //   return <span>{dbRekening.Nummer}</span>;
                                // }

                                const rekening =
                                  dbRekening.Nummer.toString() +
                                  (naamGrootboekrekeningenWeergeven ? ' - ' + dbRekening.Naam : '');

                                return (
                                  <UitlegTooltip
                                    inhoud={
                                      <div>
                                        <span>{dbRekening.Naam}</span>
                                      </div>
                                    }
                                  >
                                    {rekening}
                                  </UitlegTooltip>
                                );
                                // return <span>{rekening}</span>;
                              }}
                            />

                            {/* <DataTypeProvider
                              for={['__boekstuknummer']}
                              formatterComponent={(formatterProps) => {
                                const rij: IRow = formatterProps.row;
                                if (rij.InkFactID !== null) {
                                  const inkoopfactuur = inkoopfacturenResult.data?.facturen.find(
                                    (x) => x.InkFactID === rij.InkFactID,
                                  );
                                  if (inkoopfactuur === undefined) {
                                    return <span />;
                                  }

                                  const jaar = format(new Date(inkoopfactuur.Factuurdatum), 'yyyy');
                                  const nummer = ('0000' + inkoopfactuur.Boekstuknummer).slice(-4);
                                  return (
                                    <span>
                                      {jaar}
                                      {nummer}
                                    </span>
                                  );
                                }
                                if (rij.FactID !== null) {
                                  const verkoopfactuur = facturenResult.data?.facturen.find(
                                    (x) => x.FactID === rij.FactID,
                                  );
                                  if (verkoopfactuur === undefined) {
                                    return <span />;
                                  }
                                  return <span>{verkoopfactuur.Factuurnummer}</span>;
                                }
                                return <span />;
                              }}
                            /> */}

                            <DataTypeProvider
                              for={['__boekstuknummer']}
                              formatterComponent={(formatterProps) => {
                                const rij: IRow = formatterProps.row;
                                if (rij.InkFactID !== null) {
                                  const inkoopfactuur = inkoopfacturenResult.data?.facturen.find(
                                    (x) => x.InkFactID === rij.InkFactID,
                                  );

                                  if (inkoopfactuur === undefined) {
                                    return <span />;
                                  }

                                  const jaar = format(new Date(inkoopfactuur.Factuurdatum), 'yyyy');
                                  const nummer = ('0000' + inkoopfactuur.Boekstuknummer).slice(-4);
                                  return (
                                    <span>
                                      {jaar}
                                      {nummer}
                                    </span>
                                  );
                                }

                                if (rij.FactID !== null) {
                                  const verkoopfactuur = facturenResult.data?.facturen.find(
                                    (x) => x.FactID === rij.FactID,
                                  );
                                  if (verkoopfactuur === undefined) {
                                    return <span />;
                                  }
                                  return <FactuurVisualisatie factID={verkoopfactuur.FactID} />;
                                }
                                return <span />;
                              }}
                            />

                            <DataTypeProvider
                              for={['__tegenRekening']}
                              formatterComponent={(formatterProps) => {
                                const rij: IRow = formatterProps.row;
                                const crRekening = grootboekenResult!.grootboeken.find(
                                  (x) => x.ID === rij.C_GrbRekID,
                                );
                                if (crRekening === undefined) {
                                  return <span />;
                                }

                                const rekening =
                                  crRekening.Nummer.toString() +
                                  (naamGrootboekrekeningenWeergeven ? ' - ' + crRekening.Naam : '');

                                return (
                                  <UitlegTooltip
                                    inhoud={
                                      <div>
                                        <span>{crRekening.Naam}</span>
                                      </div>
                                    }
                                  >
                                    {rekening}
                                  </UitlegTooltip>
                                );

                                // return <span>{rekening}</span>;
                              }}
                            />

                            <DataTypeProvider
                              for={['Omschrijving']}
                              formatterComponent={(formatterProps) => {
                                const rij: IRow = formatterProps.row;

                                if (rij.Omschrijving !== null) {
                                  return <span>{rij.Omschrijving}</span>;
                                }

                                let omschrijving = '';

                                if (rij.InkFactID !== null) {
                                  const inkoopfactuur = inkoopfacturenResult.data?.facturen.find(
                                    (x) => x.InkFactID === rij.InkFactID,
                                  )!;
                                  // TODO inkoopfactuur zou hier niet undefined kunnen zijn
                                  omschrijving =
                                    inkoopfactuur !== undefined && inkoopfactuur.Onderwerp !== null
                                      ? inkoopfactuur.Onderwerp
                                      : '';
                                } else {
                                  if (rij.FactID !== null) {
                                    const factuur = facturenResult.data?.facturen.find(
                                      (x) => x.FactID === rij.FactID,
                                    )!;
                                    omschrijving =
                                      factuur !== undefined && factuur.Onderwerp !== null
                                        ? factuur.Onderwerp
                                        : '';
                                  }
                                }

                                return (
                                  <span
                                    style={{
                                      // fontStyle:
                                      //   rij.Omschrijving === null && omschrijving !== null
                                      //     ? 'italic'
                                      //     : '',
                                      color:
                                        rij.Omschrijving === null && omschrijving !== null
                                          ? EKleur.Grijs
                                          : undefined,
                                    }}
                                  >
                                    {omschrijving}
                                  </span>
                                );

                                // if (rij.WkrRglID !== null) {
                                //   const werkkostenregeling = werkkostenregelingenResult?.regelingen.find(
                                //     (x) => x.ID === rij.WkrRglID,
                                //   );
                                //   if (werkkostenregeling === undefined) {
                                //     return <span />;
                                //   }
                                //   omschrijving += ` [WKR ${werkkostenregeling.Naam}]`;
                                // }
                              }}
                            />

                            <DataTypeProvider
                              for={['Bedrag']}
                              formatterComponent={(formatterProps) => (
                                <span>
                                  <FormatteerBedrag bedrag={formatterProps.value} />
                                </span>
                              )}
                            />

                            <DataTypeProvider
                              for={['BtwBedrag']}
                              formatterComponent={(formatterProps) => {
                                if (formatterProps.value === null) {
                                  return <span />;
                                }
                                return (
                                  <span>
                                    <FormatteerBedrag bedrag={formatterProps.value} />
                                  </span>
                                );
                              }}
                            />

                            <DataTypeProvider
                              for={['__btwCode']}
                              formatterComponent={(formatterProps) => {
                                const rij: IRow = formatterProps.row;
                                if (rij.BtwSrtID === null) {
                                  return <span />;
                                }
                                const btwSoort = btwSoortenResult?.btwSoorten.find(
                                  (x) => x.ID === rij.BtwSrtID,
                                );
                                if (btwSoort === undefined) {
                                  return <span />;
                                }
                                const code = btwSoort.Code;
                                return <span>{code}</span>;
                              }}
                            />

                            <DataTypeProvider
                              for={['__wkr']}
                              formatterComponent={(formatterProps) => {
                                const rij: IRow = formatterProps.row;
                                if (rij.werkkostenregeling === null) {
                                  return <span />;
                                }
                                return <span>Ja</span>;
                              }}
                            />

                            <EditingState
                              onCommitChanges={async (changes) => {
                                if (changes.deleted === undefined) {
                                  return;
                                }
                                const deleted = changes.deleted;
                                const id = deleted[deleted.length - 1] as number;
                                // await handleVerwijderen([id]);
                              }}
                              onEditingRowIdsChange={(rowIds) => {
                                const id = rowIds[rowIds.length - 1] as number;
                                // props.onWijzigenBetalingsregelingDialoogStateChange({
                                //   ID: id,
                                // });
                              }}
                            />

                            <SortingState defaultSorting={[]} />
                            <IntegratedSorting />

                            <RowDetailState defaultExpandedRowIds={[]} />

                            <VirtualTable
                              columnExtensions={kolomExtensies}
                              messages={{
                                noData: 'Geen boekingen aanwezig voor het ingestelde filter',
                              }}
                            />
                            <TableColumnResizing defaultColumnWidths={kolombreedtes} />

                            {/* <TableEditColumn
                              width={65}
                              showDeleteCommand
                              cellComponent={DXTableEditColumnCellComponent}
                              commandComponent={DXTableEditColumnCommandComponent}
                            /> */}

                            <TableHeaderRow showSortingControls />
                            {/* <TableRowDetail
                              contentComponent={DetailComp}
                              toggleCellComponent={DXTableToggleCellComponent}
                            /> */}
                            <SelectionState
                              selection={props.boekingsregelsSelectie}
                              onSelectionChange={(x) =>
                                props.onBoekingsregelsSelectieChange(x as number[])
                              }
                            />
                            <TableSelection cellComponent={DXTableCheckboxComponent} />
                          </Grid>
                        </GridStyleWrapper>
                      }
                    />
                  </BoekingenContext.Provider>
                </>
              )}
            </div>
            {boekingdialoogState !== null && (
              <BoekingDialoogV3
                open
                onSuccess={async () => {
                  setBoekingdialoogState(null);
                  props.onRequestRefresh();
                }}
                onAnnuleren={() => setBoekingdialoogState(null)}
                boekingID={boekingdialoogState.boekingID}
                dagboekenProvider={props.boekingdialoogDagboekenProvider}
                regelsProvider={props.boekingdialoogRegelsProvider}
                dagboekVoorselectieProvider={props.boekingdialoogDagboekVoorselectieProvider}
                boekdatumProvider={boekingdialoogBoekdatumProvider!}
              />
            )}
            {boekingInfoDialoogState !== null && (
              <BoekingInfoDialoog
                open
                boekingID={boekingInfoDialoogState.boekingID}
                onSuccess={async () => {
                  setBoekingInfoDialoogState(null);
                  props.onRequestRefresh();
                }}
                onAnnuleren={() => setBoekingInfoDialoogState(null)}
              />
            )}
            {boekingregelInfoDialoogState !== null && (
              <BoekingregelInfoDialoog
                open
                id={boekingregelInfoDialoogState.id}
                onSuccess={async () => {
                  setBoekingregelInfoDialoogState(null);
                  props.onRequestRefresh();
                }}
                onAnnuleren={() => setBoekingregelInfoDialoogState(null)}
              />
            )}
          </>
        );
      }}
    </AutoSizer>
  );
});

export default BoekingTabel;
