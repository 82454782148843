import styled from 'styled-components';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import { colors } from '../../../../globalstyles/variables';

export interface IRootProps {
  selected: boolean;
  disabled?: boolean;
}

export const Root = styled.div<IRootProps>`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  background-color: ${(props) =>
    props.disabled
      ? Kleur.LichtGrijs
      : props.selected
      ? colors.activeNavBlue
      : Kleur.HeelLichtGrijs};

  &:hover {
    background-color: ${(props) => (props.disabled ? Kleur.LichtGrijs : Kleur.SelectieGrijs)};
  }
`;
