import {
  IOphalenHuidigeSignaleringenParams,
  IOphalenHuidigeSignaleringenResult,
  IOphalenSignaleringstypesParams,
  IOphalenSignaleringstypesResult,
  IVerbergenSignaleringenParams,
  IVerbergenSignaleringenResult,
  IWeergevenSignaleringenParams,
  IWeergevenSignaleringenResult,
  IWijzigenSignaleringstypeParams,
  IWijzigenSignaleringstypeResult,
} from '../../../../shared/src/api/v2/control/signalering';
import api from '../index';

const signalering = {
  ophalenHuidigeSignaleringen: async (
    params: IOphalenHuidigeSignaleringenParams,
  ): Promise<IOphalenHuidigeSignaleringenResult> => {
    return await api.post('/v2/signalering/ophalen-huidige-signaleringen', params);
  },
  verbergenSignaleringen: async (
    params: IVerbergenSignaleringenParams,
  ): Promise<IVerbergenSignaleringenResult> => {
    return await api.post('/v2/signalering/verbergen-signaleringen', params);
  },
  weergevenSignaleringen: async (
    params: IWeergevenSignaleringenParams,
  ): Promise<IWeergevenSignaleringenResult> => {
    return await api.post('/v2/signalering/weergeven-signaleringen', params);
  },
  ophalenSignaleringstypes: async (
    params: IOphalenSignaleringstypesParams,
  ): Promise<IOphalenSignaleringstypesResult> => {
    return await api.post('/v2/signalering/ophalen-signaleringstypes', params);
  },
  wijzigenSignaleringstype: async (
    params: IWijzigenSignaleringstypeParams,
  ): Promise<IWijzigenSignaleringstypeResult> => {
    return await api.post('/v2/signalering/wijzigen-signaleringstype', params);
  },
};

export default signalering;
