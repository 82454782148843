import styled from 'styled-components';
import { Kleur } from '../../../bedrijfslogica/constanten';

export const Root = styled.div`
  display: flex;
  flex: 1;
  background-color: ${Kleur.Wit};
  border: 1px solid ${Kleur.LichtGrijs};
  border-left: 0;
  position: relative;
  width: 100%;
`;

export const Container = styled.div`
  display: flex;
  flex: 1;
  overflow-y: scroll;
  position: absolute;
  bottom: 0;
  top: 0;
  width: 100%;
`;
