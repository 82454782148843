import React, { useMemo } from 'react';
import { ActieContainer, Root, Weergave } from './style';
import VerticaleScheidingslijn from '../../layout/VerticaleScheidingslijn';
import { IconKruis, IconVerwijderen, IconWijzigen } from '../../Icons';
import { Kleur } from '../../../bedrijfslogica/constanten';
import { ICustomFilter } from '../types';
import VinkVeld from '../../formulier/VinkVeld';

interface IProps<TCustomFilterId, TId, TEntiteit extends {}> {
  filter: ICustomFilter<TCustomFilterId, TId, TEntiteit>;
  onVerwijderenClick?: () => void;
  isActief?: boolean;
  onActiefChange?: (value: boolean) => void;
}

const CustomFilterItem = <TCustomFilterId, TId, TEntiteit extends {}>(
  props: IProps<TCustomFilterId, TId, TEntiteit>,
) => {
  const { filter, onVerwijderenClick, isActief, onActiefChange } = props;

  return (
    <Root>
      <ActieContainer>
        {onActiefChange !== undefined && (
          <VinkVeld aangevinkt={isActief || false} onGewijzigd={onActiefChange} />
        )}
      </ActieContainer>
      <Weergave>{filter.weergaveNaam}</Weergave>
      {onVerwijderenClick !== undefined && (
        <a href="#" onClick={onVerwijderenClick}>
          <IconKruis
            style={{
              fill: Kleur.Grijs,
              width: 16,
              height: 16,
              position: 'relative',
              bottom: 1,
              marginRight: 2,
            }}
          />
        </a>
      )}
      {/*<VerticaleScheidingslijn height={27} />*/}
    </Root>
  );
};

export default CustomFilterItem;
