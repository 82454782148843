import React, { useCallback, useEffect, useMemo, useState, useContext } from 'react';
import IDialoogProps from '../../../../core/IDialoogProps';
import Modal from 'react-bootstrap/Modal';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import Dialoog from '../../../../components/dialogen/Dialoog';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import nameof from '../../../../core/nameOf';
import { Field, FieldProps, Formik, FormikActions, FormikErrors, FormikProps } from 'formik';
import api from '../../../../api';
import { IOphalenOpdrachtenResultElement } from '../../../../../../shared/src/api/v2/pendel';
import { IOphalenDienstenResultElement } from '../../../../../../shared/src/api/v2/dienst/pendel';
import { RootStoreContext } from '../../../../stores/RootStore';
import { EResultType } from '../../../../stores/CheckStore';
import Combobox, { IOptie } from '../../../../components/formulier/Combobox';
import FormikVeldFout from '../../../../components/formulier/FormikVeldFout';
import DatumKiezer from '../../../../components/formulier/DatumKiezer';
import { addDays, format } from 'date-fns';
import { dagDatum } from '../../../../helpers/datum';

interface IProps extends IDialoogProps {
  ids: number[];
}

interface IFormikValues {
  afhaaldatum: Date | null;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  afhaaldatum: 'Afhaaldatum',
};

const BijwerkenAfhaaldatumDialoog: React.FC<IProps> = (props) => {
  const { checkStore } = useContext(RootStoreContext);

  const initieleWaarden = useMemo<IFormikValues | null>(() => {
    return {
      afhaaldatum: dagDatum(new Date()),
    };
  }, []);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      const params = {
        ids: props.ids,
        afhaaldatum: values.afhaaldatum !== null ? format(values.afhaaldatum, 'yyyy-MM-dd') : null,
      };
      const checkData = await api.v2.magazijn.afhaal.checkBijwerkenAfhaalopdracht(params);
      if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
        return;
      }

      await api.v2.magazijn.afhaal.bijwerkenAfhaalopdracht(params);

      actions.setSubmitting(false);
      props.onSuccess({});
    },
    [props.ids],
  );

  return (
    <Dialoog index={props.dialoogIndex || 0}>
      <>
        <ModalHeader>
          <ModalTitle>Bijwerken afhaaldatum</ModalTitle>
        </ModalHeader>
        <Formik<IFormikValues>
          onSubmit={handleSubmit}
          initialValues={initieleWaarden!}
          isInitialValid
          // validationSchema={validationSchema}
          render={(formikProps: FormikProps<IFormikValues>) => {
            const { submitForm, isSubmitting, values, isValid } = formikProps;
            return (
              <>
                <ModalBody>
                  <div className="form-group">
                    <div className="row">
                      <div className="row col-12 mt-3">
                        <div className="col-5">
                          <label>{veldnamen.afhaaldatum}</label>
                          <Field
                            name={nameof<IFormikValues>('afhaaldatum')}
                            render={(fieldProps: FieldProps<IFormikValues>) => {
                              const { field, form } = fieldProps;
                              return (
                                <DatumKiezer
                                  waarde={field.value}
                                  onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                  isClearable
                                  determineValidDate={(date) => {
                                    return date > addDays(new Date(), -1);
                                  }}
                                  determinePreviousValidDate={(date) => {
                                    const minDate = addDays(new Date(), -1);
                                    const newDate = addDays(date, -1);
                                    if (newDate >= minDate) {
                                      return newDate;
                                    }
                                    return null;
                                  }}
                                  determineNextValidDate="ONBEGRENST"
                                />
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </ModalBody>
                <ModalFooter className="d-flex justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    disabled={isSubmitting || !isValid}
                    style={{ width: 100 }}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={() => props.onAnnuleren()}
                    style={{ width: 100 }}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      </>
    </Dialoog>
  );
};

export default BijwerkenAfhaaldatumDialoog;
function dagdatum(arg0: Date): Date | null {
  throw new Error('Function not implemented.');
}
