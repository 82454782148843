import { addMonths, differenceInMonths, format } from 'date-fns';
import * as React from 'react';
import TegelBasis from '../../../../../../../components/tegel/TegelBasis';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { IOphalenSaldiGrootboekrekeningenResultElement } from '../../../../../../../../../shared/src/api/v2/boekhouding/boeking';
import FormatteerBedrag from '../../../../../../../components/MutatieBedrag';

interface IProps extends RouteComponentProps {
  data: IOphalenSaldiGrootboekrekeningenResultElement[];
}

const SaldiTegel = (props: IProps) => {
  const saldi = props.data;
  const saldoGeneraal = Math.round(_.sum(saldi.map((x) => x.saldo)) * 100) / 100;

  return (
    <TegelBasis
      titel={
        <>
          <span>Saldi grootboekrekeningen</span>
        </>
      }
      subtitel={
        <>
          <span>Alle boekingen</span>
        </>
      }
    >
      <div>
        {saldi.length > 0 ? (
          <>
            <div>
              <table>
                <tbody>
                  {saldi.map((saldo, i) => {
                    const bedrag =
                      saldo.grootboekrekening.Soort === 'P' || saldo.grootboekrekening.Soort === 'B'
                        ? -saldo.saldo
                        : saldo.saldo;
                    return (
                      <tr key={i}>
                        <td style={{ width: 65 }}>{saldo.grootboekrekening.Nummer}</td>
                        <td style={{ width: 50 }}>{saldo.grootboekrekening.Soort}</td>
                        <td style={{ width: 200 }}>{saldo.grootboekrekening.Naam}</td>
                        <td style={{ width: 175 }}>
                          <FormatteerBedrag bedrag={bedrag} />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="mt-3">
              {saldoGeneraal === 0 ? (
                'Er is geen boekingsverschil'
              ) : (
                <span className="d-flex">
                  Er is een boekingsverschil van <FormatteerBedrag bedrag={saldoGeneraal} />
                </span>
              )}
            </div>
          </>
        ) : (
          <span>Geen boekingen aanwezig</span>
        )}
      </div>
    </TegelBasis>
  );
};

export default withRouter(SaldiTegel);
