import React, { useCallback, useMemo, useState } from 'react';
import IDialoogProps from '../../../core/IDialoogProps';
import Dialoog from '../../dialogen/Dialoog';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import LoadingSpinner from '../../Gedeeld/LoadingSpinner';
import Verkenner, { EWeergaveKeuze } from '../Verkenner';
import { ESelectieModus } from '../../BijlageKnop';
import { EVerkennerItemType } from '../TabelWeergave/types';
import { vanVerkennerItemId } from '../helpers';
import { IBestandVerkennerItem } from '../types';

export interface IDriveBestandsselectieDialoogOutput {
  bestandIDs: number[];
}

interface IProps extends IDialoogProps<IDriveBestandsselectieDialoogOutput> {
  selectieModus: ESelectieModus;
  weergaveKeuze?: EWeergaveKeuze;
  magBestandSelecteren?: (bestandItem: IBestandVerkennerItem) => boolean;
}

const DriveBestandsselectieDialoog = (props: IProps) => {
  const [weergaveKeuze, setWeergaveKeuze] = useState<EWeergaveKeuze>(
    props.weergaveKeuze ?? EWeergaveKeuze.Lijst,
  );
  const [isAanHetLaden, setIsAanHetLaden] = useState(false);

  // const [bestandsmapID, setBestandsmapID] = useState<number | null>(null);
  const [selectie, setSelectie] = useState<string[]>([]);
  // const [gefocustItemId, setGefocustItemId] = useState<string | null>(null);
  // const [sortering, setSortering] = useState<VerkennerSortering>([]);
  // const [zoekterm, setZoekterm] = useState<string>('');

  const handleSubmit = useCallback(async () => {
    setIsAanHetLaden(true);

    const ids = selectie.map(vanVerkennerItemId);
    const bestandIDs = ids.filter((x) => x.type === EVerkennerItemType.Bestand).map((x) => x.id);
    props.onSuccess({ bestandIDs });

    setIsAanHetLaden(false);
  }, [setIsAanHetLaden, selectie]);

  const maySubmit = useMemo(() => {
    if (isAanHetLaden) {
      return false;
    }

    if (props.selectieModus === ESelectieModus.Enkel) {
      return selectie.length === 1;
    } else {
      return selectie.length > 0;
    }
  }, [props.selectieModus, selectie, isAanHetLaden]);

  return (
    <Dialoog
      index={props.dialoogIndex ?? 0}
      modalProps={{ dialogClassName: 'modal-bestandsselectie' }}
    >
      <ModalBody style={{ padding: 0, minHeight: 800 }} className="d-flex flex-column flex-fill">
        <Verkenner
          defaultBestandsmapID={null}
          // defaultSelectie={[]}
          defaultGefocustItemID={null}
          defaultSortering={[]}
          defaultZoekterm={''}
          // bestandsmapID={bestandsmapID}
          // onBestandsmapIDChange={setBestandsmapID}
          selectie={selectie}
          onSelectieChange={(selectie) => {
            switch (props.selectieModus) {
              case ESelectieModus.Enkel:
                const nieuweSelectie = selectie.length === 0 ? [] : [selectie[selectie.length - 1]];
                setSelectie(nieuweSelectie);
                break;
              case ESelectieModus.Meerdere:
                setSelectie(selectie);
                break;
            }
          }}
          magItemSelecteren={(item) => {
            if (item.type !== EVerkennerItemType.Bestand) {
              return false;
            }
            if (props.magBestandSelecteren !== undefined) {
              return props.magBestandSelecteren(item);
            }
            return true;
          }}
          weergaveKeuze={weergaveKeuze}
          onWeergaveKeuzeChange={setWeergaveKeuze}
        />
      </ModalBody>
      <ModalFooter className="d-flex flex-row justify-content-start">
        <button
          className="btn btn-primary d-flex align-items-center justify-content-center"
          onClick={handleSubmit}
          style={{ width: 100 }}
          disabled={!maySubmit}
        >
          {isAanHetLaden && (
            <span className="mr-2">
              <LoadingSpinner soort="light" grootte="15px" dikte="2px" />
            </span>
          )}
          Ok
        </button>
        <button
          className="btn btn-secondary"
          onClick={props.onAnnuleren}
          style={{ width: 100 }}
          disabled={isAanHetLaden}
        >
          Annuleren
        </button>
      </ModalFooter>
    </Dialoog>
  );
};

export default DriveBestandsselectieDialoog;
