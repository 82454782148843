import React, { useContext, useState } from 'react';
import { IOphalenRelatiesResultElementV2 } from '../../../../../../../../shared/src/api/v2/relatie';
import IRemoteData from '../../../../../../models/IRemoteData';
import Item from './Item';
import { IconVerwijderen, IconToevoegen, IconKruis } from '../../../../../Icons';
import { Kleur } from '../../../../../../bedrijfslogica/constanten';
import { observer } from 'mobx-react-lite';
import { GlobaleRendererContext } from '../../../../../../one-off-components/GlobaleRenderer';
import RelatieSelectieDialoog, {
  IRelatieSelectieDialoogResult,
} from '../../../../../personalia/RelatieSelectieDialoog';

interface IProps {
  relIDs: number[];
  onRelIDsChange: (relIDs: number[]) => Promise<void>;
  relatiesCache: Record<number, IRemoteData<IOphalenRelatiesResultElementV2>>;
}

const RelatieContexten = observer((props: IProps) => {
  const globaleRenderer = useContext(GlobaleRendererContext);
  const [isBezigMetMuteren, setIsBezigMetMuteren] = useState(false);

  return (
    <div
      className="d-flex p-2"
      // style={{ backgroundColor: Kleur.HeelLichtGrijs, borderRadius: 5 }}
    >
      {/*<span className="ml-2">Gekoppelde Relaties</span>*/}
      <div className="d-flex align-items-center flex-wrap flex-fill">
        {props.relIDs.map((relID) => (
          <div key={relID} style={{ margin: 5 }} className="d-flex align-items-center">
            <Item relID={relID} relatiesCache={props.relatiesCache} />

            <button
              style={{ background: 0, outline: 0, border: 0, padding: 0, margin: '0 0 0 5px' }}
              title="Ontkoppelen relatie"
              disabled={isBezigMetMuteren}
              onClick={async () => {
                setIsBezigMetMuteren(true);
                await props.onRelIDsChange(props.relIDs.filter((id) => id !== relID));
                setIsBezigMetMuteren(false);
              }}
            >
              <IconKruis
                style={{ fill: Kleur.Grijs, width: 20, height: 20, position: 'relative', top: -1 }}
              />
            </button>
          </div>
        ))}
      </div>
      <div className="d-flex align-items-center ml-2 mr-2">
        <button
          className="btn btn-sm btn-light d-flex align-items-center"
          style={{
            border: `1px solid ${Kleur.LichtGrijs}`,
            backgroundColor: Kleur.Wit,
          }}
          onClick={async () => {
            setIsBezigMetMuteren(true);
            const result = await globaleRenderer.render<IRelatieSelectieDialoogResult>(
              (renderProps) => (
                <RelatieSelectieDialoog
                  open
                  onSuccess={(result) => renderProps.destroy(result)}
                  onAnnuleren={() => renderProps.destroy()}
                />
              ),
            );
            if (result !== undefined) {
              await props.onRelIDsChange([...props.relIDs, result.relID]);
            }
            setIsBezigMetMuteren(false);
          }}
        >
          <IconToevoegen
            style={{
              width: 16,
              height: 16,
              fill: Kleur.Grijs,
            }}
          />
          <span className="ml-2">Relatie toevoegen</span>
        </button>
      </div>
    </div>
  );
});

export default RelatieContexten;
