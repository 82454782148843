import React, { useState, useEffect, useCallback, useMemo, useContext } from 'react';
import { IOphalenProductmodellenResultElement } from '../../../../../../shared/src/api/v2/aanbod/productmodel';
import api from '../../../../api';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import { RouteComponentProps, withRouter } from 'react-router';

import {
  TypedColumn,
  TypedTableColumnWidthInfo,
  GridStyleWrapper,
  DXTableToggleCellComponent,
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
  DXTableCheckboxComponent,
} from '../../../../helpers/dxTableGrid';
import {
  Grid,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  TableSelection,
  VirtualTable,
  TableRowDetail,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  DataTypeProvider,
  EditingState,
  SelectionState,
  RowDetailState,
  VirtualTable as VirtualTableBase,
} from '@devexpress/dx-react-grid';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import useUrlState from '../../../../core/useUrlState';
import {
  IOphalenVerkoopmodellenResult,
  IOphalenVerkoopmodellenResultElement,
} from '../../../../../../shared/src/api/v2/aanbod/verkoop';
import { IconToevoegen, IconVerwijderen } from '../../../../components/Icons';
import { formatteerBedrag } from '../../../../helpers';
import ToevoegenDialoog from './ToevoegenDialoog';
import WijzigenDialoog from './WijzigenDialoog';
import FormatteerBedrag from '../../../../components/MutatieBedrag';
import { RootStoreContext } from '../../../../stores/RootStore';
import { EResultType } from '../../../../stores/CheckStore';
import RowDetailComp from './RowDetail';
import { Helmet } from 'react-helmet';

interface IProps extends RouteComponentProps {}

interface IWijzigenState {
  VerkModID: number;
  productmodel: IOphalenVerkoopmodellenResultElement;
}

interface IUrlState {
  selectie: number[];
  uitgeklapt: number[];
  wijzigenState: IWijzigenState | null;
}

const defaultUrlState: IUrlState = {
  selectie: [],
  uitgeklapt: [],
  wijzigenState: null,
};

export interface IVerkoopContext {
  onVerversenAangevraagd: () => void;
}
export const VerkoopContext = React.createContext<IVerkoopContext>(null as any);

export interface IRow extends IOphalenVerkoopmodellenResultElement {}

const geenData = {
  noData: 'Geen verkoopmodellen gevonden',
};

const Verkoop: React.FC<IProps> = (props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const { checkStore } = useContext(RootStoreContext);

  const [toevoegendialoog, setToevoegendialoog] = useState<boolean>(false);
  const [verkoopModelID, setVerkoopModelID] = useState<number | null>(null);
  const [selectie, setSelectie] = useState<number[]>([]);

  const [verkoopModellen, setVerkoopModellen] = useState<
    IOphalenVerkoopmodellenResultElement[] | null
  >(null);

  const ophalenVerkoopModellen = useCallback(async () => {
    const result = await api.v2.aanbod.verkoop.ophalenVerkoopmodellen({
      filterSchema: { filters: [] },
    });
    setVerkoopModellen(result.verkoopmodellen);
  }, []);

  useEffect(() => {
    ophalenVerkoopModellen();
  }, [ophalenVerkoopModellen]);

  const handleVerwijderen = useCallback(async (ids: number[]) => {
    const params = { verkModIDs: ids };
    const checkData = await api.v2.aanbod.verkoop.checkVerwijderenVerkoopmodellen(params);

    if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
      return;
    }
    if (
      (
        await checkStore.bevestigen({
          inhoud: `Wil je deze verkoopmodellen verwijderen?`,
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    await api.v2.aanbod.verkoop.verwijderenVerkoopmodellen(params);
    ophalenVerkoopModellen();
  }, []);

  const sleutelExtractor = useCallback((row: IRow) => row.VerkModID, []);
  const kolommen = useMemo<TypedColumn<IRow>[]>(
    () => [
      {
        name: 'Naam',
        title: 'Naam',
      },
      {
        name: 'Prijs',
        title: 'Prijs',
      },
      {
        name: 'Actief',
        title: 'Actief',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRow>[]>(
    () => [
      {
        columnName: 'Naam',
        width: 250,
      },
      {
        columnName: 'Prijs',
        width: 150,
      },
      {
        columnName: 'Actief',
        width: 125,
      },
    ],
    [],
  );

  const kolomExtensies: VirtualTableBase.ColumnExtension[] = useMemo(() => {
    return [{ columnName: 'Prijs', align: 'right' }];
  }, []);

  const verkoopContextValue = useMemo<IVerkoopContext>(
    () => ({
      onVerversenAangevraagd: async () => {
        await ophalenVerkoopModellen();
      },
    }),
    [ophalenVerkoopModellen],
  );

  return (
    <VerkoopContext.Provider value={verkoopContextValue}>
      <Helmet>
        <title>Verkooptarieven</title>
      </Helmet>
      <div
        className="d-flex flex-column p-3"
        style={{
          backgroundColor: Kleur.HeelLichtGrijs,
          borderBottom: `1px solid ${Kleur.LichtGrijs}`,
        }}
      >
        <div className="d-flex">
          <button
            className="btn btn-sm btn-light d-flex align-items-center ml-3"
            disabled={!true}
            style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
            onClick={() => setToevoegendialoog(true)}
          >
            <IconToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
            <span className="ml-2">Nieuw</span>
          </button>

          <button
            className="btn btn-sm btn-light d-flex align-items-center ml-3"
            disabled={selectie.length === 0}
            style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
            onClick={() => handleVerwijderen(selectie)}
          >
            <IconVerwijderen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
            <span className="ml-2">
              Verwijderen {selectie.length > 0 && <span className="pl-1">({selectie.length})</span>}
            </span>
          </button>
        </div>
      </div>

      {verkoopModellen === null ? (
        <div className="flex-fill d-flex align-items-center justify-content-center">
          <LoadingSpinner />
        </div>
      ) : (
        <GridStyleWrapper height={'calc(100vh - 100px)'}>
          <Grid rows={verkoopModellen} getRowId={sleutelExtractor} columns={kolommen}>
            <DataTypeProvider
              for={['Actief']}
              formatterComponent={(props) => <span>{props.value ? 'Ja' : 'Nee'}</span>}
            />

            <DataTypeProvider
              for={['Prijs']}
              formatterComponent={(props) => (
                <FormatteerBedrag bedrag={props.value} rechtsUitlijnen />
              )}
            />

            <EditingState
              onCommitChanges={async (changes) => {
                if (changes.deleted === undefined) {
                  return;
                }
                const deleted = changes.deleted;
                const id = deleted[deleted.length - 1] as number;
                // alert(id);
              }}
              onEditingRowIdsChange={(rowIds) => {
                const id = rowIds[rowIds.length - 1] as number;
                const verkoopmodel = verkoopModellen!.find((x) => x.VerkModID === id)!;
                setVerkoopModelID(verkoopmodel.VerkModID);
              }}
            />

            <RowDetailState
              expandedRowIds={urlState.uitgeklapt}
              onExpandedRowIdsChange={(x) => setUrlStateSync('uitgeklapt', x as number[])}
            />

            <VirtualTable columnExtensions={kolomExtensies} messages={geenData} />
            <TableColumnResizing defaultColumnWidths={kolomBreedtes} />

            <TableEditColumn
              width={45}
              showEditCommand
              cellComponent={DXTableEditColumnCellComponent}
              commandComponent={DXTableEditColumnCommandComponent}
            />
            <TableHeaderRow />

            <TableRowDetail
              contentComponent={RowDetailComp}
              toggleCellComponent={DXTableToggleCellComponent}
            />

            <SelectionState
              selection={selectie}
              onSelectionChange={(x) => {
                setSelectie(x as any);
              }}
            />
            <TableSelection cellComponent={DXTableCheckboxComponent} />
          </Grid>
        </GridStyleWrapper>
      )}
      {toevoegendialoog && (
        <ToevoegenDialoog
          open={toevoegendialoog}
          onSuccess={async () => {
            await ophalenVerkoopModellen();
            setToevoegendialoog(false);
          }}
          onAnnuleren={() => {
            setToevoegendialoog(false);
          }}
        />
      )}
      {verkoopModelID !== null && (
        <WijzigenDialoog
          verkoopmodelID={verkoopModelID}
          open
          onSuccess={async () => {
            await ophalenVerkoopModellen();
            setVerkoopModelID(null);
          }}
          onAnnuleren={() => {
            setVerkoopModelID(null);
          }}
        />
      )}
    </VerkoopContext.Provider>
  );
};

export default withRouter(Verkoop);
