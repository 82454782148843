import * as React from 'react';
import { TableRowDetail } from '@devexpress/dx-react-grid';
import { TableRowDetailWrapper } from '../../../../helpers/dxTableGrid';
import TabelInspringBlok from '../../../../components/layout/TabelInspringBlok';
import GegevensLayout from '../../../../components/layout/GegevensLayout';
import { IRegel } from '..';

interface IProps extends TableRowDetail.ContentProps {}

const DetailComp: React.FunctionComponent<IProps> = (props) => {
  const rij: IRegel = props.row as any;

  return (
    <>
      <TableRowDetailWrapper>
        <div className="row d-flex">
          <TabelInspringBlok />
          <div className="d-flex p-3">
            <div style={{ width: 700 }}>
              <GegevensLayout
                gegevens={[
                  {
                    label: 'BeoordelingKort',
                    waarde: `${rij.BeoordelingKort}`,
                  },
                  {
                    label: 'Beoordeling',
                    waarde: `${rij.Beoordeling}`,
                  },
                  //   {
                  //     label: 'Email',
                  //     waarde: `${rij.Email}`,
                  //   },
                ]}
              />
            </div>
          </div>
        </div>
      </TableRowDetailWrapper>
    </>
  );
};

export default DetailComp;
