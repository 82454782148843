import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import IDialoogProps from '../../../../../../core/IDialoogProps';
import Dialoog from '../../../../../../components/dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { Formik, FormikActions, FormikProps } from 'formik';
import * as Yup from 'yup';
import api from '../../../../../../api';
import { format } from 'date-fns';
import LoadingSpinner from '../../../../../../components/Gedeeld/LoadingSpinner';
import ModalBody from 'react-bootstrap/ModalBody';
import { RootStoreContext } from '../../../../../../stores/RootStore';
import { IOphalenFacturenResultElement } from '../../../../../../../../shared/src/api/v2/inkoopfactuur';
import VeldWeergave from '../../../../../../components/formulier/VeldWeergave';
import GegevensLayout from '../../../../../../components/layout/GegevensLayout';
import {
  DXTableCheckboxComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../../../helpers/dxTableGrid';
import {
  Grid,
  VirtualTable,
  TableColumnResizing,
  TableHeaderRow,
  TableSelection,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  DataTypeProvider,
  IntegratedSorting,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import FormatteerBedrag from '../../../../../../components/MutatieBedrag';
import RelatieVisualisatie from '../../../../../../components/personalia/RelatieVisualisatie';
import useUrlState from '../../../../../../core/useUrlState';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IFilterSchemaFilter } from '../../../../../../../../shared/src/models/filter';
import _ from 'lodash';
import { Kleur as EKleur } from '../../../../../../bedrijfslogica/constanten';
import FilterBalkV2, {
  IFilter,
  IFilterData,
  maakFilterSchema,
} from '../../../../../../components/FilterBalkV2';
import ZoektermFilter from './ZoektermFilter';
import { IOphalenWerkbonnenResultElement } from '../../../../../../../../shared/src/api/v2/service/werkbon';
import nameOf from '../../../../../../core/nameOf';
import { IOphalenOpdrachtenResultElement } from '../../../../../../../../shared/src/api/v2/service/opdracht';
import { IOphalenDienstenResultElement } from '../../../../../../../../shared/src/api/v2/dienst/service';

export interface IDialoogResult {
  ids: number[];
}

interface IProps extends IDialoogProps<IDialoogResult>, RouteComponentProps {
  factuur: IOphalenFacturenResultElement | null;
  relID?: number;
}

enum EFilter {
  Zoekterm = 'ZOEKTERM',
}

export interface IFormikValues {}

export interface IRegel extends IOphalenWerkbonnenResultElement {}

const SelecterenWerkbonnenDialoog: React.FC<IProps> = (props) => {
  const [selectie, setSelectie] = useState<number[]>([]);
  const [filterData, setFilterData] = useState<IFilterData<EFilter>[]>([
    {
      naam: EFilter.Zoekterm,
      data: '',
      isActief: false,
    },
  ]);
  const [filterSchema, setFilterSchema] = useState(useMemo(() => maakFilterSchema(filterData), []));

  const { factuur, children, dialoogIndex, onAnnuleren, onSuccess, open } = props;
  const { checkStore } = useContext(RootStoreContext);

  const [werkbonnen, setWerkbonnen] = useState<IOphalenWerkbonnenResultElement[] | null>(null);
  const [servicediensten, setServicediensten] = useState<IOphalenDienstenResultElement[] | null>();

  const [serviceopdrachten, setServiceopdrachten] = useState<
    IOphalenOpdrachtenResultElement[] | null
  >(null);

  useEffect(() => {
    (async () => {
      //
      // let servOpdIDs: number[] | null = null;

      // // Als relID is meegegeven dan filteren we op die relatie
      // if (props.relID) {
      //   const servicediensten = (
      //     await api.v2.dienst.service.ophalenDiensten({
      //       filterSchema: { filters: [{ naam: 'REL_IDS', data: [props.relID] }] },
      //     })
      //   ).diensten;

      //   if (servicediensten.length !== 0) {
      //     const dienstIDs = servicediensten.map((x) => x.ID);

      //     const serviceopdrachten = (
      //       await api.v2.service.ophalenOpdrachten({
      //         filterSchema: { filters: [{ naam: 'SERVDIENST_IDS', data: dienstIDs }] },
      //       })
      //     ).opdrachten;

      //     servOpdIDs =
      //       serviceopdrachten.length !== 0 ? serviceopdrachten.map((x) => x.ServOpdID) : null;
      //   }
      // }
      //

      const werkbonnenResult = await api.v2.service.ophalenWerkbonnen({
        filterSchema: {
          filters: [
            {
              naam: 'GEKOPPELD_AAN_INKOOPFACTUUR',
              data: false,
            },
            props.relID !== undefined
              ? {
                  naam: 'SERVICEDIENST_REL_IDS',
                  data: [props.relID],
                }
              : null,
          ].filter((x) => x !== null) as IFilterSchemaFilter[],
        },
        orderSchema: {
          orders: [
            {
              naam: 'BEZOEKDATUM',
              richting: 'DESC',
            },
          ],
        },
        paginatie: {
          index: 1,
          aantal: 100,
        },
      });

      setWerkbonnen(werkbonnenResult.werkbonnen);
    })();
  }, [filterSchema.filters, props.relID]);

  useEffect(() => {
    (async () => {
      if (werkbonnen === null) {
        return;
      }
      const opdrachtenResult = await api.v2.service.ophalenOpdrachten({
        filterSchema: {
          filters: [
            {
              naam: 'IDS',
              data: _.uniq(werkbonnen.map((x) => x.ServOpdID)),
            },
          ],
        },
      });

      setServiceopdrachten(opdrachtenResult.opdrachten);
    })();
  }, [werkbonnen]);

  const initialValues = useMemo<IFormikValues | null>(() => {
    return {};
  }, []);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      const ids = werkbonnen!.filter((x, i) => selectie.indexOf(i) !== -1).map((x) => x.ID);
      onSuccess({ ids });

      actions.setSubmitting(false);
    },
    [onSuccess, selectie, werkbonnen],
  );

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.Zoekterm,
        altijdWeergevenInBalk: true,
        weergave: ZoektermFilter,
      },
    ],
    [],
  );

  const kolommen = useMemo<TypedColumn<IRegel>[]>(
    () => [
      {
        name: '__opdrachtnummer' as any,
        title: 'Opd.nr.',
      },
      {
        name: 'Bezoekdatum',
        title: 'Bez.datum',
      },
      {
        name: '__opdrachtdatum' as any,
        title: 'Opd.datum',
      },
      {
        name: '__postcode' as any,
        title: 'Postcode',
      },
      {
        name: '__huisnummer' as any,
        title: 'Huisnr.',
      },
      {
        name: '__straatnaam' as any,
        title: 'Straatnaam',
      },
      {
        name: '__servicedienst' as any,
        title: 'Serv.dienst',
      },
      {
        name: '__uitvoerendeServicedienst' as any,
        title: 'Uitgev. door',
      },

      {
        name: 'KostenTotaal',
        title: 'Kosten',
      },
      {
        name: 'Werkzaamheden' as any,
        title: 'Werkzaamheden',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRegel>[]>(
    () => [
      {
        columnName: 'Bezoekdatum',
        width: 110,
      },
      {
        columnName: '__opdrachtdatum' as any,
        width: 110,
      },
      {
        columnName: '__servicedienst' as any,
        width: 135,
      },
      {
        columnName: '__uitvoerendeServicedienst' as any,
        width: 135,
      },
      {
        columnName: 'Werkzaamheden',
        width: 350,
      },
      {
        columnName: 'KostenTotaal',
        width: 100,
      },
      {
        columnName: '__opdrachtnummer' as any,
        width: 90,
      },
      {
        columnName: '__postcode' as any,
        width: 100,
      },
      {
        columnName: '__huisnummer' as any,
        width: 90,
      },
      {
        columnName: '__straatnaam' as any,
        width: 150,
      },
    ],
    [],
  );

  return (
    <Dialoog index={dialoogIndex || 0} modalProps={{ size: 'xl' }}>
      <ModalHeader>
        <ModalTitle>Selecteren te koppelen werkbonnen</ModalTitle>
      </ModalHeader>

      {initialValues === null || werkbonnen === null || serviceopdrachten === null ? (
        <div className="d-flex flex-fill align-items-center justify-content-center">
          <LoadingSpinner />
        </div>
      ) : (
        <Formik<IFormikValues>
          onSubmit={handleSubmit}
          initialValues={initialValues}
          // enableReinitialize
          // validationSchema={validationSchema}
          render={(formikProps: FormikProps<IFormikValues>) => {
            const { submitForm, isSubmitting, isValid, values } = formikProps;

            return (
              <>
                <ModalBody>
                  <div className="form-group">
                    <div className="row">
                      {factuur !== null && (
                        <div className="col-12">
                          <VeldWeergave>
                            <GegevensLayout
                              gegevens={[
                                {
                                  label: 'Fact.nr.',
                                  waarde: `${factuur.Factuurnummer}`,
                                },
                                {
                                  label: 'Factuurdatum',
                                  waarde: `${format(new Date(factuur.Factuurdatum), 'dd-MM-yyyy')}`,
                                },
                                {
                                  label: 'Crediteur',
                                  waarde: (
                                    <span>
                                      <RelatieVisualisatie relID={factuur.RelID} />
                                    </span>
                                  ),
                                },
                                {
                                  label: 'Onderwerp',
                                  waarde: `${factuur.Onderwerp}`,
                                },
                                // {
                                //   label: 'Bedrag ex btw',
                                //   waarde: (
                                //     <MutatieBedrag bedrag={factuur.Bedrag - factuur.BedragBtw} />
                                //   ),
                                // },
                              ]}
                            />
                          </VeldWeergave>

                          <div className="mt-2">
                            <FilterBalkV2
                              filters={filters}
                              filterData={filterData}
                              onFilterDataChange={setFilterData}
                              onFilterSchemaChange={(x) => setFilterSchema(x)}
                            />
                          </div>
                        </div>
                      )}

                      <div className="col-12 mt-3">
                        <div className="d-flex flex-column flex-fill mt-2">
                          <GridStyleWrapper rowAmount={werkbonnen.length}>
                            <Grid columns={kolommen} rows={werkbonnen}>
                              <DataTypeProvider
                                for={[nameOf<IRegel>('Bezoekdatum')]}
                                formatterComponent={(formatterProps) => {
                                  const rij: IRegel = formatterProps.row;
                                  return (
                                    <span>
                                      {formatterProps.value !== null
                                        ? format(new Date(formatterProps.value), 'dd-MM-yyyy')
                                        : ''}
                                    </span>
                                  );
                                }}
                              />

                              <DataTypeProvider
                                for={['__opdrachtdatum']}
                                formatterComponent={(formatterProps) => {
                                  const rij: IRegel = formatterProps.row;

                                  const opdracht = serviceopdrachten.find(
                                    (x) => x.ServOpdID === rij.ServOpdID,
                                  )!;

                                  return (
                                    <span>
                                      {opdracht.Opdrachtdatum !== null
                                        ? format(new Date(opdracht.Opdrachtdatum), 'dd-MM-yyyy')
                                        : ''}
                                    </span>
                                  );
                                }}
                              />

                              <DataTypeProvider
                                for={['__opdrachtnummer']}
                                formatterComponent={(formatterProps) => {
                                  const rij: IRegel = formatterProps.row;

                                  const opdracht = serviceopdrachten.find(
                                    (x) => x.ServOpdID === rij.ServOpdID,
                                  )!;
                                  const melding = opdracht.melding;
                                  return (
                                    <span>
                                      {melding.Meldnummer}-{opdracht.Volgnummer}
                                    </span>
                                  );
                                }}
                              />

                              <DataTypeProvider
                                for={['__servicedienst']}
                                formatterComponent={(formatterProps) => {
                                  const rij: IRegel = formatterProps.row;

                                  const opdracht = serviceopdrachten.find(
                                    (x) => x.ServOpdID === rij.ServOpdID,
                                  )!;
                                  const melding = opdracht.melding;

                                  return <span>{opdracht.dienst.relatie?.organisatie?.Naam}</span>;
                                }}
                              />

                              <DataTypeProvider
                                for={['__uitvoerendeServicedienst']}
                                formatterComponent={(formatterProps) => {
                                  const rij: IRegel = formatterProps.row;

                                  const opdracht = serviceopdrachten.find(
                                    (x) => x.ServOpdID === rij.ServOpdID,
                                  )!;

                                  if (opdracht.uitvoerendeDienst === null) {
                                    return <span></span>;
                                  }

                                  return (
                                    <span>
                                      {opdracht.uitvoerendeDienst.relatie?.organisatie?.Naam}
                                    </span>
                                  );
                                }}
                              />

                              {/* <DataTypeProvider
                              for={[nameOf<IRegel>('KostenTotaal')]}
                              formatterComponent={(formatterProps) => {
                                const rij: IRegel = formatterProps.row;
                                return <FormatteerBedrag bedrag={formatterProps.value} />;
                              }}
                            /> */}

                              <DataTypeProvider
                                for={[nameOf<IRegel>('KostenTotaal')]}
                                formatterComponent={(formatterProps) => {
                                  const rij: IRegel = formatterProps.row;
                                  return <FormatteerBedrag bedrag={formatterProps.value} />;
                                }}
                              />

                              <DataTypeProvider
                                for={['__postcode']}
                                formatterComponent={(formatterProps) => {
                                  const rij: IRegel = formatterProps.row;

                                  const opdracht = serviceopdrachten.find(
                                    (x) => x.ServOpdID === rij.ServOpdID,
                                  )!;

                                  return <span>{opdracht.melding.locatie.Postcode}</span>;
                                }}
                              />

                              <DataTypeProvider
                                for={['__huisnummer']}
                                formatterComponent={(formatterProps) => {
                                  const rij: IRegel = formatterProps.row;

                                  const opdracht = serviceopdrachten.find(
                                    (x) => x.ServOpdID === rij.ServOpdID,
                                  )!;

                                  return (
                                    <span>
                                      {opdracht.melding.locatie.Huisnummer}{' '}
                                      {opdracht.melding.locatie.Bisnummer}
                                    </span>
                                  );
                                }}
                              />

                              <DataTypeProvider
                                for={['__straatnaam']}
                                formatterComponent={(formatterProps) => {
                                  const rij: IRegel = formatterProps.row;

                                  const opdracht = serviceopdrachten.find(
                                    (x) => x.ServOpdID === rij.ServOpdID,
                                  )!;

                                  return <span>{opdracht.melding.locatie.Straatnaam}</span>;
                                }}
                              />

                              {/* <DataTypeProvider
                            for={['__relatie']}
                            formatterComponent={(formatterProps) => {
                              const rij: IOphalenProductenResultElementV2 = formatterProps.row;
                              if (rij.inkoopopdracht === null) {
                                return <span></span>;
                              }
                              const relID = rij.inkoopopdracht.dienst.RelID;
                              return (
                                <RelatieVisualisatie
                                  relID={relID}
                                  options={{ geenLinkToepassen: true }}
                                />
                              );
                            }}
                          /> */}

                              <SortingState defaultSorting={[]} />
                              <IntegratedSorting />

                              <VirtualTable
                                messages={{
                                  noData: 'Geen werkbonnen',
                                }}
                              />
                              <TableColumnResizing defaultColumnWidths={kolomBreedtes} />

                              <TableHeaderRow showSortingControls />

                              <SelectionState
                                selection={selectie}
                                onSelectionChange={(x) => setSelectie(x as number[])}
                              />
                              <TableSelection cellComponent={DXTableCheckboxComponent} />
                            </Grid>
                          </GridStyleWrapper>
                        </div>
                      </div>
                    </div>
                  </div>
                </ModalBody>
                <ModalFooter className="d-flex flex-row justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    style={{
                      width: 100,
                    }}
                    disabled={selectie.length === 0}
                  >
                    Selecteren
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={() => {
                      onAnnuleren();
                    }}
                    style={{
                      width: 100,
                    }}
                    disabled={isSubmitting}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Dialoog>
  );
};

const SelecterenWerkbonnenDialoogWithRouter = withRouter(SelecterenWerkbonnenDialoog);

export default SelecterenWerkbonnenDialoogWithRouter;
