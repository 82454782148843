import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Dialoog from '../../dialogen/Dialoog';
import IDialoogProps from '../../../core/IDialoogProps';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import LoadingSpinner from '../../Gedeeld/LoadingSpinner';
import Modal from 'react-bootstrap/Modal';
import { IOphalenMeldingenResultElement } from '../../../../../shared/src/api/v2/service/melding';
import api from '../../../api';
import Tabblad, { ITabblad } from '../../layout/Tabblad';
import DetailTab from './DetailTab';
import { Formik } from 'formik';
import { IOphalenOpdrachtenResultElement } from '../../../../../shared/src/api/v2/service/opdracht';
import _ from 'lodash';
import OpdrachtenComp from '../OpdrachtenComp';

interface IProps extends IDialoogProps {
  serviceMeldingID: number;
}

export interface IContext {
  melding: IOphalenMeldingenResultElement | null;
  opdrachten: IOphalenOpdrachtenResultElement[] | null;
}

export const MeldingContext = React.createContext<IContext>({
  melding: null,
  opdrachten: null,
});

enum ETablad {
  Detail,
}

export interface IFormikValues {
  // notities: string | null;
}

const ServicemeldingInfoDialoog: React.FC<IProps> = (props) => {
  const { dialoogIndex, onAnnuleren, onSuccess, open } = props;
  const [tabblad, setTabblad] = useState<ETablad>(ETablad.Detail);

  const [melding, setMelding] = useState<IOphalenMeldingenResultElement | null>(null);
  const [opdrachten, setOpdrachten] = useState<IOphalenOpdrachtenResultElement[] | null>(null);

  const ophalenMelding = useCallback(async () => {
    const meldingenResult = await api.v2.service.ophalenMeldingen({
      filterSchema: {
        filters: [{ naam: 'IDS', data: [props.serviceMeldingID] }],
      },
    });

    setMelding(meldingenResult.meldingen[0]);
  }, []);

  useEffect(() => {
    ophalenMelding();
  }, [ophalenMelding]);

  const ophalenServiceopdrachten = useCallback(async () => {
    if (melding === null) {
      return;
    }
    const opdrachtenResult = (
      await api.v2.service.ophalenOpdrachten({
        filterSchema: {
          filters: [{ naam: 'SERVMELD_IDS', data: [melding.ID] }],
        },
      })
    ).opdrachten;

    const opdrachten = _.orderBy(opdrachtenResult, ['DatumVerstuurd'], ['desc']);

    setOpdrachten(opdrachten);
  }, [melding]);

  useEffect(() => {
    ophalenServiceopdrachten();
  }, [ophalenServiceopdrachten]);

  const tabbladen = useMemo<ITabblad<ETablad>[]>(
    () => [
      {
        id: ETablad.Detail,
        label: 'Detail',
        content: DetailTab,
      },
    ],
    [],
  );

  const initialFormikValues = useMemo<IFormikValues | null>(() => {
    if (melding === null) {
      return null;
    }

    return {
      // notities: melding.,
    };
  }, [melding]);

  return (
    <Dialoog index={dialoogIndex || 0} modalProps={{ size: `lg` }}>
      <ModalHeader>
        <ModalTitle>Meldinginformatie</ModalTitle>
      </ModalHeader>
      <MeldingContext.Provider
        value={{
          melding,
          opdrachten,
        }}
      >
        <div className="d-flex flex-column flex-fill" style={{ minHeight: `600px` }}>
          {initialFormikValues === null || melding === null || opdrachten === null ? (
            <div className="d-flex flex-fill align-items-center justify-content-center">
              <LoadingSpinner />
            </div>
          ) : (
            <>
              <Formik<IFormikValues>
                isInitialValid
                initialValues={initialFormikValues}
                //   validate={handleValidate}
                onSubmit={() => null}
                render={(FormikProps) => {
                  const { submitForm, isSubmitting, values, errors } = FormikProps;
                  return (
                    <>
                      <div className="d-flex flex-column flex-fill">
                        <Tabblad
                          tabbladen={tabbladen}
                          geselecteerd={tabblad}
                          onSelectieChange={(id) => {
                            setTabblad(id);
                          }}
                        />
                      </div>
                      <ModalFooter className="d-flex flex-row justify-content-start">
                        {/* <button
                        className="btn btn-primary"
                        disabled={isSubmitting}
                        onClick={submitForm}
                        style={{ width: 100 }}
                      >
                        Ok
                      </button> */}
                        <button
                          className="btn btn-primary"
                          onClick={onAnnuleren}
                          style={{ width: 100 }}
                        >
                          Sluiten
                        </button>
                      </ModalFooter>
                    </>
                  );
                }}
              />
            </>
          )}
        </div>
      </MeldingContext.Provider>
    </Dialoog>
  );
};

export default ServicemeldingInfoDialoog;
