import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import IDialoogProps from '../../../../../core/IDialoogProps';
import Modal from 'react-bootstrap/Modal';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { Field, FieldProps, Formik, FormikActions } from 'formik';
import api from '../../../../../api';
import { IVoorstelGebruikersnaamResult } from '../../../../../../../shared/src/api/v2/account/account';
import LoadingSpinner from '../../../../Gedeeld/LoadingSpinner';
import FormikVeldFout from '../../../../formulier/FormikVeldFout';
import teksten from '../../../../../bedrijfslogica/teksten';
import * as Yup from 'yup';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../../../stores/RootStore';
import { EResultType } from '../../../../../stores/CheckStore';
import Dialoog from '../../../../dialogen/Dialoog';

interface IProps extends IDialoogProps<null> {
  relID: number;
  persID: number;
}

const veldnamen = {
  gebruikersnaam: 'Gebruikersnaam',
};

interface IFormikValues {
  gebruikersnaam: string;
}

const ToevoegenAccountDialoog: React.FC<IProps> = observer((props) => {
  const { open, onSuccess, onAnnuleren, relID, persID } = props;
  const { checkStore } = useContext(RootStoreContext);
  const [
    voorstelGebruikersnaam,
    setVoorstelGebruikersnaam,
  ] = useState<IVoorstelGebruikersnaamResult | null>(null);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        gebruikersnaam: Yup.string()
          .min(
            1,
            teksten.formulier.E_MINIMAAL_AANTAL_KARAKTERS_BEVATTEN({
              veldnaam: veldnamen.gebruikersnaam,
              aantal: 1,
            }),
          )
          .ensure(),
      }),
    [],
  );

  useEffect(() => {
    (async () => {
      const resultaat = await api.v2.account.voorstelGebruikersnaam({
        persID,
      });
      setVoorstelGebruikersnaam(resultaat);
    })();
  }, [relID, persID]);

  const initieleWaarden = useMemo<IFormikValues | null>(() => {
    if (voorstelGebruikersnaam === null) {
      return null;
    }

    return {
      gebruikersnaam: voorstelGebruikersnaam.gebruikersnaam,
    };
  }, [voorstelGebruikersnaam]);

  const handleSubmit = useCallback(
    async (values: IFormikValues | null, actions: FormikActions<IFormikValues | null>) => {
      const { setSubmitting } = actions;

      setSubmitting(true);

      const params = {
        gebruikersnaam: values!.gebruikersnaam,
        relID,
        persID,
      };

      const checkResult = await api.v2.account.checkToevoegenAccount(params);
      const controlerenResult = await checkStore.controleren({
        checkData: checkResult,
      });
      if (controlerenResult.type === EResultType.Annuleren) {
        setSubmitting(false);
        return;
      }

      await api.v2.account.toevoegenAccount({
        gebruikersnaam: values!.gebruikersnaam,
        relID,
        persID,
      });

      onSuccess(null);
      setSubmitting(false);
    },
    [onSuccess, relID, persID],
  );

  return (
    <Dialoog index={props.dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Nieuw account</ModalTitle>
      </ModalHeader>
      <Formik<IFormikValues | null>
        isInitialValid
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        initialValues={initieleWaarden}
        render={(formikProps) => {
          const { submitForm, isSubmitting, values, isValid } = formikProps;

          return (
            <>
              <ModalBody style={{ padding: '2rem' }}>
                {values === null ? (
                  <LoadingSpinner />
                ) : (
                  <div className="form-group">
                    <label>{veldnamen.gebruikersnaam}</label>
                    <Field
                      name="gebruikersnaam"
                      render={(fieldProps: FieldProps<IFormikValues>) => {
                        return (
                          <>
                            <input {...fieldProps.field} type="text" className="form-control" />
                            <FormikVeldFout fieldProps={fieldProps} />
                          </>
                        );
                      }}
                    />
                  </div>
                )}
              </ModalBody>
              <ModalFooter className="d-flex flex-row justify-content-start">
                <button
                  className="btn btn-primary"
                  onClick={submitForm}
                  style={{ width: 100 }}
                  disabled={isSubmitting || !isValid || values === null}
                >
                  Ok
                </button>
                <button className="btn btn-secondary" onClick={onAnnuleren} style={{ width: 100 }}>
                  Annuleren
                </button>
              </ModalFooter>
            </>
          );
        }}
      />
    </Dialoog>
  );
});

export default ToevoegenAccountDialoog;
