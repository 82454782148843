import { IOphalenBtwSoortenResultElement } from '../../../../../../../shared/src/api/v2/btw';
import api from '../../../../../api';

export interface IBtwSoortenProvider {
  provide: () => Promise<IOphalenBtwSoortenResultElement[]>;
}

export const standaardBtwSoortenProvider: IBtwSoortenProvider = {
  provide: async () => {
    const result = await api.v2.btw.ophalenBtwSoorten({});

    return result.btwSoorten;
  },
};
