import React, { useCallback, useEffect, useState } from 'react';
import IDialoogProps from '../../../../../core/IDialoogProps';
import Dialoog from '../../../../dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/esm/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../../models/IRemoteData';
import { Formik, FormikProps, Field, FieldProps, FormikActions } from 'formik';
import api from '../../../../../api';
import LoadingSpinner from '../../../../Gedeeld/LoadingSpinner';
import nameof from '../../../../../core/nameOf';
import VinkVeld from '../../../../formulier/VinkVeld';
import { TwitterPicker } from 'react-color';
import { Kleur } from '../../../../../bedrijfslogica/constanten';
import KleurSelectie from '../../../../formulier/KleurSelectie';
import { IBestandslabel } from '../../../../../../../shared/src/api/v2/bestand/label';

interface IFormikValues {
  kleurcode: string | null;
  naam: string;
  isRelatieExclusief: boolean;
}

interface IDialoogResult {
  bestandslabel: IBestandslabel;
}

interface IProps extends IDialoogProps<IDialoogResult> {
  relID?: number;
  bestandslabelID?: number;
}

const LabelMuterenDialoog = (props: IProps) => {
  const [initialValues, setInitialValues] = useState<IRemoteData<IFormikValues>>(
    createPendingRemoteData(),
  );

  useEffect(() => {
    if (props.bestandslabelID === undefined) {
      setInitialValues(
        createReadyRemoteData({
          kleurcode: null,
          naam: '',
          isRelatieExclusief: true,
        }),
      );
      return;
    }

    api.v2.bestand.label
      .ophalenBestandslabels({
        filterSchema: {
          filters: [
            {
              naam: 'ID',
              data: props.bestandslabelID,
            },
          ],
        },
      })
      .then((result) => {
        const label = result.labels[0];
        setInitialValues(
          createReadyRemoteData({
            kleurcode: label.Kleurcode,
            naam: label.Naam,
            isRelatieExclusief: label.RelID !== null,
          }),
        );
      });
  }, []);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      let bestandslabel: IBestandslabel;
      if (props.bestandslabelID === undefined) {
        const result = await api.v2.bestand.label.toevoegenBestandslabel({
          kleurcode: values.kleurcode,
          naam: values.naam,
          relID: values.isRelatieExclusief ? props.relID ?? null : null,
        });
        bestandslabel = result.bestandslabel;
      } else {
        const result = await api.v2.bestand.label.wijzigenBestandslabel({
          id: props.bestandslabelID,
          kleurcode: values.kleurcode,
          naam: values.naam,
          relID: values.isRelatieExclusief ? props.relID ?? null : null,
        });
        bestandslabel = result.bestandslabel;
      }

      await props.onSuccess({
        bestandslabel,
      });

      actions.setSubmitting(false);
    },
    [],
  );

  return (
    <Dialoog index={props.dialoogIndex ?? 0}>
      <ModalHeader>
        <ModalTitle>
          Label {props.bestandslabelID === undefined ? 'toevoegen' : 'wijzigen'}
        </ModalTitle>
      </ModalHeader>
      {initialValues.state === ERemoteDataState.Pending ? (
        <ModalBody>
          <LoadingSpinner />
        </ModalBody>
      ) : (
        <Formik<IFormikValues>
          initialValues={initialValues.data!}
          onSubmit={handleSubmit}
          render={(formikProps) => {
            return <Formulier dialoogProps={props} formikProps={formikProps} />;
          }}
        />
      )}
    </Dialoog>
  );
};

interface IFormulierProps {
  dialoogProps: IProps;
  formikProps: FormikProps<IFormikValues>;
}

const Formulier = (props: IFormulierProps) => {
  return (
    <>
      <ModalBody>
        <div className="form-group">
          <div className="d-flex flex-column">
            <label htmlFor="kleur">Kleur</label>
            <Field
              name={nameof<IFormikValues>('kleurcode')}
              render={(fieldProps: FieldProps<IFormikValues>) => {
                const { field, form } = fieldProps;

                return (
                  <KleurSelectie
                    kleur={field.value}
                    onChange={(kleur) =>
                      form.setFieldValue(nameof<IFormikValues>('kleurcode'), kleur)
                    }
                  />
                );
              }}
            />
          </div>
          <div className="mt-3">
            <label htmlFor="naam">Naam</label>
            <Field
              name={nameof<IFormikValues>('naam')}
              render={(fieldProps: FieldProps<IFormikValues>) => {
                return <input className="form-control" type="text" {...fieldProps.field} />;
              }}
            />
          </div>

          {props.dialoogProps.relID !== undefined && (
            <div className="d-flex align-items-center mt-3">
              <Field
                name={nameof<IFormikValues>('isRelatieExclusief')}
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;
                  return (
                    <VinkVeld
                      aangevinkt={field.value}
                      onGewijzigd={(actief) =>
                        form.setFieldValue(nameof<IFormikValues>('isRelatieExclusief'), actief)
                      }
                    />
                  );
                }}
              />
              <span className="ml-2">Is relatie exclusief</span>
            </div>
          )}
        </div>
      </ModalBody>
      <ModalFooter className="d-flex flex-row justify-content-start">
        <button
          className="btn btn-primary d-flex align-items-center justify-content-center"
          onClick={props.formikProps.submitForm}
          style={{ width: 100 }}
          disabled={props.formikProps.isSubmitting}
        >
          {props.formikProps.isSubmitting && (
            <span className="mr-2">
              <LoadingSpinner soort="light" grootte="15px" dikte="2px" />
            </span>
          )}
          Ok
        </button>
        <button
          className="btn btn-secondary"
          onClick={props.dialoogProps.onAnnuleren}
          style={{ width: 100 }}
          disabled={props.formikProps.isSubmitting}
        >
          Annuleren
        </button>
      </ModalFooter>
    </>
  );
};

export default LabelMuterenDialoog;
