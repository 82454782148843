import { Formik } from 'formik';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ModalFooter, ModalTitle } from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/ModalHeader';
import api from '../../../../api';
import IDialoogProps from '../../../../core/IDialoogProps';
import InternTab from './InternTab';
import ExternTab from './ExternTab';
import TechnischeInformatieKnop from '../../../../components/TechnischeInformatieKnop';
import Dialoog from '../../../../components/dialogen/Dialoog';
import Tabblad, { ITabblad } from '../../../../components/layout/Tabblad';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import { IOphalenInventarisatieInfoResult } from '../../../../../../shared/src/api/v2/magazijn/inventarisatie';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../models/IRemoteData';
import { IOphalenProductstatussenResult } from '../../../../../../shared/src/api/v2/product';
import VeldWeergave from '../../../../components/formulier/VeldWeergave';
import GegevensLayout from '../../../../components/layout/GegevensLayout';
import { EProductstatus } from '../../../../bedrijfslogica/enums';

interface IProps extends IDialoogProps<{}> {
  typeID: number;
  magInvID: number;
}

enum ETablad {
  Intern,
  Extern,
}

export interface IContext {
  inventarisatieInfo: IOphalenInventarisatieInfoResult | null;
  productstatussenResult: IRemoteData<IOphalenProductstatussenResult>;
}

export const InfoContext = React.createContext<IContext>({
  inventarisatieInfo: null,
  productstatussenResult: createPendingRemoteData(),
});

export interface IFormikValues {
  // modelcode: string;
}

export const veldnamen: Record<keyof IFormikValues, string> = {
  // modelcode: 'Modelcode',
};

const InventarisatieInfoDialoog: React.FC<IProps> = (props) => {
  const { dialoogIndex, onAnnuleren, onSuccess, open, typeID: prodModID } = props;

  const [tabblad, setTabblad] = useState<ETablad>(ETablad.Intern);
  const [inventarisatieInfo, setInventarisatie] = useState<IOphalenInventarisatieInfoResult | null>(
    null,
  );
  const [productstatussenResult, setProductstatussenResult] = useState<
    IRemoteData<IOphalenProductstatussenResult>
  >(createPendingRemoteData());

  const tabbladen = useMemo<ITabblad<ETablad>[]>(
    () => [
      {
        id: ETablad.Intern,
        label: 'Intern',
        content: InternTab,
      },
      {
        id: ETablad.Extern,
        label: 'Extern',
        content: ExternTab,
      },
    ],
    [onSuccess],
  );

  const ophalenInventarisatieInfo = useCallback(async () => {
    const inventarisatieInfoResult = await api.v2.magazijn.inventarisatie.ophalenInventarisatieInfo(
      {
        typeID: props.typeID,
        magInvID: props.magInvID,
      },
    );

    setInventarisatie(inventarisatieInfoResult);
  }, [props]);

  useEffect(() => {
    ophalenInventarisatieInfo();
  }, [ophalenInventarisatieInfo]);

  const ophalenProductstatussen = useCallback(async () => {
    const productstatussenResult = await api.v2.product.ophalenProductstatussen({
      filterSchema: {
        filters: [
          {
            naam: 'UITSLUITEN_NAAM_ENUMS',
            data: [EProductstatus.Verhuur, EProductstatus.UitBedrijf],
          },
        ],
      },
    });
    setProductstatussenResult(createReadyRemoteData(productstatussenResult));
  }, []);

  useEffect(() => {
    ophalenProductstatussen();
  }, [ophalenProductstatussen]);

  const initialFormikValues = useMemo<IFormikValues | null>(() => {
    if (inventarisatieInfo === null) {
      return null;
    }

    return {
      inventarisatieInfo,
    };
  }, [inventarisatieInfo]);

  return (
    <Dialoog index={dialoogIndex || 0} modalProps={{ size: `lg` }}>
      <ModalHeader>
        <ModalTitle>Inventarisatie Producttype info</ModalTitle>
      </ModalHeader>
      <InfoContext.Provider
        value={{
          inventarisatieInfo,
          productstatussenResult,
        }}
      >
        <div className="d-flex flex-column flex-fill" style={{ minHeight: `500px` }}>
          {initialFormikValues === null ||
          inventarisatieInfo === null ||
          productstatussenResult.state === ERemoteDataState.Pending ? (
            <div className="d-flex flex-fill align-items-center justify-content-center">
              <LoadingSpinner />
            </div>
          ) : (
            <>
              <Formik<IFormikValues>
                isInitialValid
                initialValues={initialFormikValues}
                onSubmit={() => null}
                render={(FormikProps) => {
                  const { submitForm, isSubmitting, values, errors } = FormikProps;
                  return (
                    <>
                      <div className="d-flex flex-column flex-fill">
                        <div className="m-3">
                          <VeldWeergave>
                            <div className="d-flex">
                              <div className="col-4">
                                <GegevensLayout
                                  gegevens={[
                                    {
                                      label: 'Merk',
                                      waarde: inventarisatieInfo.producttype.Merknaam,
                                    },
                                    {
                                      label: 'Type',
                                      waarde: inventarisatieInfo.producttype.Typenaam,
                                    },
                                  ]}
                                ></GegevensLayout>
                              </div>
                              <div className="col-4">
                                <GegevensLayout
                                  gegevens={[
                                    {
                                      label: 'Productsoort',
                                      waarde: inventarisatieInfo.producttype.productsoort.Naam,
                                    },
                                    null,
                                  ]}
                                ></GegevensLayout>
                              </div>
                              <div className="col-4">
                                <GegevensLayout
                                  gegevens={[
                                    {
                                      label: 'Magazijn',
                                      waarde: inventarisatieInfo.magazijn.NaamKort,
                                    },
                                    null,
                                  ]}
                                ></GegevensLayout>
                              </div>
                            </div>
                          </VeldWeergave>
                        </div>
                        <Tabblad
                          tabbladen={tabbladen}
                          geselecteerd={tabblad}
                          onSelectieChange={(id) => {
                            setTabblad(id);
                          }}
                        />
                      </div>
                      <ModalFooter className="d-flex flex-row justify-content-start">
                        <button
                          className="btn btn-primary"
                          onClick={onAnnuleren}
                          style={{ width: 100 }}
                        >
                          Sluiten
                        </button>
                        <div className="flex-fill" />
                        <TechnischeInformatieKnop
                          dialoogIndex={(props.dialoogIndex ?? 0) + 1}
                          content={() => <div>TypeID: {props.typeID}</div>}
                        />
                      </ModalFooter>
                    </>
                  );
                }}
              />
            </>
          )}
        </div>
      </InfoContext.Provider>
    </Dialoog>
  );
};

export default InventarisatieInfoDialoog;
