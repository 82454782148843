import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import IDialoogProps from '../../../../core/IDialoogProps';
import Dialoog from '../../../dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import * as Yup from 'yup';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../../stores/RootStore';
import api from '../../../../api';
import { EResultType } from '../../../../stores/CheckStore';
import _ from 'lodash';
import { IOphalenInstellingenResult } from '../../../../../../shared/src/api/v2/instelling';
import { IOphalenProducttypenResultElement } from '../../../../../../shared/src/api/v2/product/type';
import VeldWeergave from '../../../formulier/VeldWeergave';

interface IDialoogResult {}

interface IProps extends IDialoogProps<IDialoogResult> {
  trsOpdIDs: number[];
}

interface IFormikValues {
  premium1: boolean;
  premium2: boolean;
  premium3: boolean;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  premium1: 'Premium 1',
  premium2: 'Premium 2',
  premium3: 'Premium 3',
};

const OpgevenPremiumsDialoog: React.FC<IProps> = observer((props) => {
  const { children, dialoogIndex, onAnnuleren, onSuccess, open, trsOpdIDs } = props;
  const { checkStore } = useContext(RootStoreContext);

  const [instelling, setInstelling] = useState<IOphalenInstellingenResult | null>(null);
  // const [producttypen, setProducttypen] = useState<IOphalenProducttypenResultElement[] | null>(
  //   null,
  // );

  useEffect(() => {
    (async () => {
      const instelling = await api.v2.instelling.ophalenInstellingen({});
      setInstelling(instelling);
    })();
  }, []);

  // useEffect(() => {
  //   (async () => {
  //     if (instelling === null) {
  //       return;
  //     }
  //     const typeIDs = [
  //       instelling.Premium1_TypeID,
  //       instelling.Premium2_TypeID,
  //       instelling.Premium3_TypeID,
  //     ].filter((x) => x !== null);

  //     if (typeIDs.length === 0) {
  //       return;
  //     }

  //     const producttypen = (
  //       await api.v2.product.type.ophalenProducttypen({
  //         filterSchema: { filters: [{ naam: 'IDS', data: typeIDs }] },
  //       })
  //     ).producttypen;

  //     setProducttypen(producttypen);
  //   })();
  // }, [instelling]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      if (
        (
          await checkStore.bevestigen({
            inhoud: 'Premiums toevoegen?',
          })
        ).type === EResultType.Annuleren
      ) {
        actions.setSubmitting(false);
        return;
      }

      const premiumsVoorOpdrachtenResult = (
        await api.v2.transport.opdracht.premium.ophalenPremiumsVoorOpdrachten({ trsOpdIDs })
      ).opdrachten;

      const premiumParams = {
        opdrachten: premiumsVoorOpdrachtenResult.map((x) => {
          return { trsOpdID: x.TrsOpdID, premiumIDs: x.premiums.map((x) => x.ID) };
        }),
      };

      await api.v2.transport.opdracht.toevoegenPremiumregels(premiumParams);

      props.onSuccess({});
      actions.setSubmitting(false);
    },
    [instelling],
  );

  const initialValues = useMemo<IFormikValues>(() => {
    return {
      premium1: true,
      premium2: false,
      premium3: false,
    };
  }, []);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        premium1: Yup.boolean(),
        premium2: Yup.boolean(),
        premium3: Yup.boolean(),
      }),
    [],
  );

  return (
    <Dialoog index={dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Premiums toevoegen</ModalTitle>
      </ModalHeader>
      <Formik<IFormikValues>
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        isInitialValid
        render={(formikProps: FormikProps<IFormikValues>) => {
          const { submitForm, isValid, isSubmitting } = formikProps;

          return (
            <>
              <ModalBody>
                <div className="form-group">
                  <div className="row">
                    <div className="col-12">
                      <VeldWeergave>
                        De voor de opdracht in aanmerking komende premiums worden aan de opdracht
                        toegevoegd
                      </VeldWeergave>
                    </div>
                  </div>
                </div>
              </ModalBody>
              <ModalFooter className="d-flex flex-row justify-content-start">
                <button
                  className="btn btn-primary"
                  onClick={submitForm}
                  style={{ width: 100 }}
                  disabled={!isValid || isSubmitting}
                >
                  Ok
                </button>
                <button
                  className="btn btn-secondary"
                  onClick={onAnnuleren}
                  style={{ width: 100 }}
                  disabled={isSubmitting}
                >
                  Annuleren
                </button>
              </ModalFooter>
            </>
          );
        }}
      />
    </Dialoog>
  );
});

export default OpgevenPremiumsDialoog;
