import React, { useCallback, useContext, useMemo, useState } from 'react';
import Tegel from '../../../../../../components/tegel/Tegel';
import { Kleur } from '../../../../../../bedrijfslogica/constanten';
import RelatieVisualisatie from '../../../../../../components/personalia/RelatieVisualisatie';
import { IconInklappen, IconKruis, IconUitklappen } from '../../../../../../components/Icons';
import VerticaleScheidingslijn from '../../../../../../components/layout/VerticaleScheidingslijn';
import HorizontaleScheidingslijn from '../../../../../../components/layout/HorizontaleScheidingslijn';
import DuplicatenTabel from './DuplicatenTabel';
import api from '../../../../../../api';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../../../../stores/RootStore';
import { EResultType } from '../../../../../../stores/CheckStore';
import { format } from 'date-fns';
import {
  IPersoonDuplicaatSuggestie,
  IRegistreerDuplicatenParams,
  IAfwijzenDuplicaatSuggestieParams,
} from '../../../../../../../../shared/src/api/v2/persoon/duplicaat';
import PersoonVisualisatie from '../../../../../../components/personalia/PersoonVisualisatie';
import { GlobaleRendererContext } from '../../../../../../one-off-components/GlobaleRenderer';
import PersoonMergeDialoog from '../../PersoonMergeDialoog';

interface IProps {
  suggestie: IPersoonDuplicaatSuggestie;
  uitgeklapt: boolean;
  onUitgeklaptChange: (uitgeklapt: boolean) => void;
  onVerversenAangevraagd: () => void;
}

const SuggestieItem: React.FC<IProps> = observer((props) => {
  const { suggestie, uitgeklapt, onUitgeklaptChange, onVerversenAangevraagd } = props;
  const { checkStore } = useContext(RootStoreContext);
  const globaleRenderer = useContext(GlobaleRendererContext);

  const handleAfwijzen = useCallback(
    async (ev: React.MouseEvent<any>) => {
      ev.preventDefault();

      if (
        (
          await checkStore.bevestigen({
            inhoud: (
              <div className="d-flex flex-column">
                <h5>Confirmatie afwijzen suggestie</h5>
                <span className="text-muted mt-1">
                  Let op: Door het af te wijzen van de suggestie worden alle personen die bij deze
                  suggestie horen beschouwd als géén duplicaat van ieder ander.
                </span>
                <span className="text-muted mt-1">
                  Als er dus bepaalde personen in deze suggestie wel duplicaten zijn van een ander,
                  dan kan je deze los selecteren en toewijzen, en vervolgens de overgebleven
                  personen afwijzen d.m.v. deze knop.
                </span>
              </div>
            ),
          })
        ).type === EResultType.Annuleren
      ) {
        return;
      }
      const params: IAfwijzenDuplicaatSuggestieParams = {
        persDupSugID: suggestie.PersDupSugID,
      };
      await api.v2.persoon.duplicaat.afwijzenDuplicaatSuggestie(params);
      await onVerversenAangevraagd();
    },
    [onVerversenAangevraagd, suggestie],
  );

  return (
    <Tegel
      rootProps={{
        style: {
          backgroundColor: Kleur.Wit,
        },
      }}
    >
      <div className="d-flex align-items-center">
        <button
          className="d-flex align-items-center justify-content-center"
          style={{
            outline: 0,
            background: 0,
            border: 0,
          }}
          onClick={() => onUitgeklaptChange(!uitgeklapt)}
        >
          {uitgeklapt ? (
            <IconInklappen
              style={{
                fill: Kleur.Grijs,
                width: 24,
                height: 24,
              }}
            />
          ) : (
            <IconUitklappen
              style={{
                fill: Kleur.Grijs,
                width: 24,
                height: 24,
              }}
            />
          )}
        </button>
        <VerticaleScheidingslijn className="ml-2 mr-3" />
        <button
          className="btn btn-light"
          style={{
            border: `1px solid ${Kleur.LichtGrijs}`,
          }}
          onClick={async () => {
            await globaleRenderer.render((renderProps) => (
              <PersoonMergeDialoog
                open
                onSuccess={async (output) => {
                  await onVerversenAangevraagd();

                  renderProps.destroy();
                }}
                onAnnuleren={() => renderProps.destroy()}
                persIDs={suggestie.duplicaten.map((x) => x.PersID)}
                persDupSugID={suggestie.PersDupSugID}
              />
            ));
          }}
        >
          Merge duplicaten
        </button>
        <VerticaleScheidingslijn className="ml-3 mr-3" />
        <div className="d-flex flex-fill">
          <div className="d-flex flex-fill align-items-center flex-wrap">
            {suggestie.duplicaten
              .map((duplicaat) => <PersoonVisualisatie persID={duplicaat.PersID} />)
              .map((el, i) => {
                if (i !== suggestie.duplicaten.length - 1) {
                  return <div className="d-flex">{el}&nbsp;–&nbsp;</div>;
                }
                return el;
              })}
          </div>
          <div>{format(new Date(suggestie.RecordGeregistreerd), 'dd-MM-yyyy HH:mm')}</div>
          <a
            href="#"
            className="d-flex align-items-center justify-content-center ml-2"
            onClick={handleAfwijzen}
          >
            Duplicaatsuggestie afwijzen
          </a>
        </div>
      </div>
      {uitgeklapt && (
        <>
          <HorizontaleScheidingslijn className="mt-2" />
          <div className="flex-fill d-flex flex-column mt-2">
            <div className="flex-fill d-flex flex-column">
              <DuplicatenTabel duplicaten={suggestie.duplicaten} />
            </div>
          </div>
        </>
      )}
    </Tegel>
  );
});

export default SuggestieItem;
