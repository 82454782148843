import React, { useCallback, useMemo, useRef } from 'react';
import { VerkennerSortering } from '../Verkenner';
import { IPaginatiePositie } from '../../../api';
import { IVerkennerItem, IVerkennerItemState } from '../types';
import { AutoSizer, Grid, Index, IndexRange, InfiniteLoader, Size } from 'react-virtualized';
import { GridCellProps } from 'react-virtualized/dist/es/Grid';
import { EVerkennerItemType } from '../TabelWeergave/types';
import { IconMap } from '../../Icons';
import { Kleur } from '../../../bedrijfslogica/constanten';
import TekstOverflowCenterEllipsis from '../../tekst/TekstOverflowCenterEllipsis';
import MapWeergave from './MapWeergave';
import BestandWeergave from './BestandWeergave';
import Skeleton from 'react-loading-skeleton';
import downloadUrl from '../../../core/downloadUrl';

interface IRenderGridParams {
  ref?: (node: Grid) => void;
  onRowsRendered?: (index: { startIndex: number; stopIndex: number }) => void;
}

interface IProps {
  gefocustItemId: string | null;
  onGefocustItemIdChange: (id: string | null) => void;
  selectie: string[];
  onSelectieChange: (ids: string[]) => void;
  items: Record<number, IVerkennerItem>;
  totaalAantalItems: number;
  itemsState: Record<string, IVerkennerItemState>;
  onItemsStateChange: (itemsState: Record<string, IVerkennerItemState>) => void;
  onItemHernoemd: (id: string, naam: string) => Promise<void>;
  onItemsVerwijderd: (ids: string[]) => Promise<void>;
  onItemInBestandsmapToegevoegd: (item: IVerkennerItem) => Promise<void>;
  onItemVerplaatst: (verplaatstNaarBestandsmapID: number | null, itemId: string) => Promise<void>;
  onBestandenDrop: (bestanden: File[]) => Promise<void>;
  sortering: VerkennerSortering;
  onSorteringChange: (sortering: VerkennerSortering) => void;
  onReportRowsRendered: (index: { startIndex: number; stopIndex: number }) => void;
  magItemSelecteren: (item: IVerkennerItem) => boolean;
  onExtraRijenAangevraagd: (positie: IPaginatiePositie) => Promise<void>;
  onNavigerenBestandsmap?: (bestandsmapID: number | null) => void;
  relID?: number;
}

const GridWeergave = (props: IProps) => {
  return (
    <AutoSizer>
      {(size) => {
        if (size.height === 0 && size.width === 0) {
          return null;
        }

        return <GridWeergaveInner {...props} size={size} />;
      }}
    </AutoSizer>
  );
};

const GridWeergaveInner = (props: IProps & { size: Size }) => {
  const gridRef = useRef<Grid>();
  const [hoveredId, setHoveredId] = React.useState<string | null>(null);

  const getColumnWidth = useCallback(() => {
    return 148;
  }, []);
  const getRowHeight = useCallback(() => {
    return 148;
  }, []);

  const columnCount = useMemo(() => {
    return Math.floor(props.size.width / getColumnWidth());
  }, [getColumnWidth, props.size.width]);

  const rowCount = useMemo(() => {
    return Math.ceil(props.totaalAantalItems / columnCount);
  }, [props.totaalAantalItems, columnCount]);

  const isRowLoaded = useCallback(
    (index: Index) => {
      // console.log('isRowLoaded', index);
      const start = columnCount * index.index;

      for (let i = 0; i < columnCount; i++) {
        if (props.items[start + i] === undefined) {
          return false;
        }
      }

      return true;
    },
    [props.items, columnCount],
  );
  const loadMoreRows = useCallback(
    async (range: IndexRange) => {
      const start = columnCount * range.startIndex;
      const end = columnCount * range.stopIndex;

      await props.onExtraRijenAangevraagd?.({
        index: start,

        // aantal: range.stopIndex - range.startIndex + 1,
        aantal: end - start + columnCount,
      });
    },
    [props.onExtraRijenAangevraagd, columnCount],
  );

  const cellRenderer = useCallback(
    (gridCellProps: GridCellProps) => {
      const itemIdx = gridCellProps.rowIndex * columnCount + gridCellProps.columnIndex;
      const item = props.items[itemIdx];
      const noItemAtThisCell = itemIdx >= props.totaalAantalItems;

      const width = gridCellProps.style.width as number;
      const height = gridCellProps.style.height as number;

      const wrapper = (content?: JSX.Element) => {
        return (
          <div
            key={gridCellProps.key}
            style={{
              ...gridCellProps.style,
              maxWidth: width,
              maxHeight: height,
            }}
            onMouseEnter={() => {
              if (item === undefined) {
                return;
              }
              setHoveredId(item.id);
            }}
            onMouseLeave={() => {
              if (item === undefined) {
                return;
              }
              setHoveredId((currId) => (currId === item.id ? null : currId));
            }}
            // onMouseUp={handleMouseUp}
          >
            {content}
          </div>
        );
      };

      if (noItemAtThisCell) {
        return wrapper();
      }

      let weergave = null;

      if (item === undefined) {
        weergave = (
          <>
            <Skeleton width={width * (2 / 3)} height={height * (2 / 3)} />
            <span style={{ marginTop: 3 }}>
              <Skeleton width={width * (3 / 4)} />
            </span>
          </>
        );
      } else if (item.type === EVerkennerItemType.Map) {
        weergave = <MapWeergave map={item.map} width={width} height={height} />;
      } else if (item.type === EVerkennerItemType.Bestand) {
        weergave = <BestandWeergave bestand={item.bestand} width={width} height={height} />;
      }

      const isHovered = item !== undefined && hoveredId === item.id;
      const isFocused = item !== undefined && props.gefocustItemId === item.id;

      return wrapper(
        <div
          className="d-flex flex-column justify-content-center align-items-center flex-fill p-2"
          style={{
            backgroundColor: isFocused
              ? Kleur.DoorschijnendLichtBlauw
              : isHovered
              ? Kleur.HeelLichtGrijs
              : undefined,
          }}
          onClick={() => {
            if (item === undefined) {
              return;
            }
            props.onGefocustItemIdChange?.(item.id);
          }}
          onDoubleClick={async (ev) => {
            ev.stopPropagation();
            ev.preventDefault();

            if (item === undefined) {
              return;
            }

            if (item.type === EVerkennerItemType.Bestand) {
              await downloadUrl(item.bestand.url, item.bestand.Naam);
            } else if (
              item.type === EVerkennerItemType.Map &&
              props.onNavigerenBestandsmap !== undefined
            ) {
              const bestandsmapID = Number(item.id.split('M-')[1]);
              props.onNavigerenBestandsmap(bestandsmapID);
            }
          }}
        >
          {weergave}
        </div>,
      );
    },
    [props.items, columnCount, props.totaalAantalItems, hoveredId, props.gefocustItemId],
  );

  const renderGrid = useCallback(
    (params: IRenderGridParams) => {
      return (
        <Grid
          ref={params.ref}
          cellRenderer={cellRenderer}
          columnCount={columnCount}
          columnWidth={getColumnWidth}
          rowCount={rowCount}
          rowHeight={getRowHeight}
          width={props.size.width}
          height={props.size.height}
          onSectionRendered={({ rowStartIndex, rowStopIndex }) => {
            const index = {
              startIndex: rowStartIndex,
              stopIndex: rowStopIndex,
            };
            params.onRowsRendered?.(index);
          }}
          containerStyle={{ overflow: 'visible', top: 20 }}
          overscanRowCount={3}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        />
      );
    },
    [
      cellRenderer,
      getColumnWidth,
      getRowHeight,
      props.size.width,
      props.size.height,
      columnCount,
      rowCount,
    ],
  );

  return (
    <InfiniteLoader
      loadMoreRows={loadMoreRows}
      isRowLoaded={isRowLoaded}
      rowCount={rowCount}
      threshold={1}
    >
      {(infiniteLoaderProps) =>
        renderGrid({
          ref: (node) => {
            infiniteLoaderProps.registerChild(node);
            gridRef.current = node;
          },
          onRowsRendered: (index) => {
            infiniteLoaderProps.onRowsRendered(index);
            // props.onRijenGerendered?.(index);
          },
        })
      }
    </InfiniteLoader>
  );
};

export default GridWeergave;
