import React, { useCallback, useContext, useMemo } from 'react';
import { ISectieProps } from '../index';
import BijlageKnop from '../../../../../../../../../components/BijlageKnop';
import BijlagenContainer, {
  BestandType,
  EWeergaveFocus,
} from '../../../../../../../../../components/BijlagenContainer';
import api from '../../../../../../../../../api';
import { RootStoreContext } from '../../../../../../../../../stores/RootStore';
import useUpload from '../../../../../../../../../core/useUpload';

interface IProps extends ISectieProps {}

const MediaSectie: React.FC<IProps> = (props) => {
  const { checkStore } = useContext(RootStoreContext);
  const handleAlleBestandenGeuploaded = useCallback(
    async (bestandIDs: number[]) => {
      await api.v2.service.muterenAfbeeldingenMelding({
        meldingID: props.melding.ID,
        bestIDs: bestandIDs,
      });

      props.onRequestRefresh();
    },
    [props.melding.ID, props.onRequestRefresh],
  );
  const {
    upload,
    uploadProgresses,
    bestanden,
    muteerBestanden,
    moetNogUploaden,
    isBezigMetUploaden,
  } = useUpload(
    useMemo(
      () => ({
        automatischUploaden: true,
        initieleBestanden: props.melding.bijlagen.map((x) => ({
          type: BestandType.ASPDrive,
          bestand: x.bestand,
        })),
        onAlleBestandenGeuploaded: handleAlleBestandenGeuploaded,
      }),
      [props.melding.bijlagen, handleAlleBestandenGeuploaded],
    ),
  );
  const handleBestandenBijgevoegd = useCallback(
    (bijlagen) => muteerBestanden((bestanden) => [...bestanden, ...bijlagen]),
    [muteerBestanden],
  );
  const handleBestandenChange = useCallback((bijlagen) => muteerBestanden((_) => bijlagen), [
    muteerBestanden,
  ]);

  return (
    <>
      <div>
        <BijlageKnop
          onBijgevoegd={handleBestandenBijgevoegd}
          toegestaneBestandstypes={[
            {
              weergaveNaam: 'Afbeelding',
              mediaType: 'image/*',
            },
            {
              weergaveNaam: 'Video',
              mediaType: 'video/*',
            },
          ]}
          disabled={isBezigMetUploaden}
        />
        {bestanden !== null && bestanden.length > 0 && (
          <div className="mt-2">
            <BijlagenContainer
              bestanden={bestanden}
              uploadProgresses={uploadProgresses}
              onBestandenChange={handleBestandenChange}
              bestandenMuterenToegestaan={!isBezigMetUploaden}
              weergaveFocus={EWeergaveFocus.Visualisatie}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default MediaSectie;
