import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { CommunicatieOverlayContext } from '../../../../index';
import { RootStoreContext } from '../../../../../../stores/RootStore';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../../../models/IRemoteData';
import { format, intervalToDuration } from 'date-fns';
import { ASPKolom, EAspKolomBreedteType } from '../../../../../../components/tabel/ASPTabel/types';
import { IconInkomend, IconUitgaand } from '../../../../../../components/Icons';
import { Kleur } from '../../../../../../bedrijfslogica/constanten';
import TelefoonComponent from '../../../../../../components/communicatie/TelefoonComponent';
import ContactKoppelComponent from '../../../../../../components/ContactKoppelComponent';
import LoadingSpinner from '../../../../../../components/Gedeeld/LoadingSpinner';
import ASPTabel from '../../../../../../components/tabel/ASPTabel';
import { observer } from 'mobx-react-lite';
import { ETelefooneventStatus } from '../../../../../../bedrijfslogica/enums';
import ASPGebruikerVisualisatie, {
  EModus,
} from '../../../../../../components/ASPGebruiker/ASPGebruikerVisualisatie';
import useNotifyChanges from '../../../../../../helpers/useNotifyChanges';
import Skeleton from 'react-loading-skeleton';
import RelatieKoppelComponent from '../../../../../../components/RelatieKoppelComponent';
import { INaamComponentProps } from '../../../../../../components/personalia/RelatieVisualisatie';
import { IOphalenAfdelingenResultElement } from '../../../../../../../../shared/src/api/v2/relatie/afdeling';
import api from '../../../../../../api';
import { IOphalenContactpersonenResultElement } from '../../../../../../../../shared/src/api/v2/relatie/contactpersoon';
import ActieMenuKnop from '../../../../../../components/ActieMenuKnop';
import { IOproep } from '../../../../../../../../shared/src/api/v2/telefonie';

interface IGespreksduratieProps {
  sinds: Date;
}

const Gespreksduratie = (props: IGespreksduratieProps) => {
  const notifyChanges = useNotifyChanges();
  useEffect(() => {
    const intervalId = setInterval(() => {
      notifyChanges();
    }, 1000);
    return () => clearInterval(intervalId);
  }, [notifyChanges]);

  const duration = intervalToDuration({
    start: new Date(props.sinds),
    end: new Date(),
  });

  return (
    <span style={{ fontSize: 13, fontWeight: 'bold' }}>
      {duration.hours !== 0 && `${duration.hours!.toString().padStart(2, '0')}:`}
      {(duration.minutes || 0).toString().padStart(2, '0')}:
      {(duration.seconds || 0).toString().padStart(2, '0')}
    </span>
  );
};

enum EKolom {
  Richting,
  Nummer,
  Contact,
  Afdeling,
  InzakeVan,
  Datum,
  NummberBedrijf,
  Gespreksindicatie,
  Acties,
}

const ActueelTabblad = observer(() => {
  const { telefoonContext } = useContext(CommunicatieOverlayContext);
  const { gebruikerStore, telefoonStore } = useContext(RootStoreContext);

  const telefoonOproepen = useMemo<IRemoteData<Record<number, IOproep>>>(() => {
    if (telefoonStore.actieveOproepen.state === ERemoteDataState.Pending) {
      return createPendingRemoteData();
    }

    const data = telefoonStore.actieveOproepen.data!.reduce((acc, curr, idx) => {
      return {
        ...acc,
        [idx]: curr,
      };
    }, {});
    return createReadyRemoteData(data);
  }, [telefoonStore.actieveOproepen]);

  const [afdelingen, setAfdelingen] = useState<IRemoteData<IOphalenAfdelingenResultElement[]>>(
    createPendingRemoteData(),
  );
  const ophalenAfdelingen = useCallback(async () => {
    if (telefoonStore.actieveOproepen.state === ERemoteDataState.Pending) {
      setAfdelingen(createPendingRemoteData());
      return;
    }
    const orgAfdIDs = telefoonStore.actieveOproepen
      .data!.map((x) => x.OrgAfdID)
      .filter((x) => x !== null) as number[];
    if (orgAfdIDs.length === 0) {
      setAfdelingen(createReadyRemoteData([]));
      return;
    }

    const result = await api.v2.relatie.afdeling.ophalenAfdelingen({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: orgAfdIDs,
          },
        ],
      },
    });
    setAfdelingen(createReadyRemoteData(result.afdelingen));
  }, [telefoonStore.actieveOproepen]);
  useEffect(() => {
    ophalenAfdelingen();
  }, [ophalenAfdelingen]);

  const [contactpersonen, setContactpersonen] = useState<
    IRemoteData<IOphalenContactpersonenResultElement[]>
  >(createPendingRemoteData());
  const ophalenContactpersonen = useCallback(async () => {
    if (telefoonStore.actieveOproepen.state === ERemoteDataState.Pending) {
      setContactpersonen(createPendingRemoteData());
      return;
    }
    const persIDs = telefoonStore.actieveOproepen
      .data!.map((x) => x.PersID)
      .filter((x) => x !== null);
    const inzakeRelIDs = telefoonStore.actieveOproepen
      .data!.map((x) => x.Inzake_RelID)
      .filter((x) => x !== null);

    if (persIDs.length === 0 && inzakeRelIDs.length === 0) {
      setContactpersonen(createReadyRemoteData([]));
      return;
    }

    const result = await api.v2.relatie.ophalenContactpersonen({
      filterSchema: {
        filters: [
          {
            naam: 'IS_RELATIE',
            data: true,
          },
          {
            naam: 'PERS_IDS',
            data: persIDs,
          },
          {
            naam: 'REL_IDS',
            data: inzakeRelIDs,
          },
        ],
      },
    });
    setContactpersonen(createReadyRemoteData(result.contactpersonen));
  }, [telefoonStore.actieveOproepen]);
  useEffect(() => {
    ophalenContactpersonen();
  }, [ophalenContactpersonen]);

  const persoonOfOrganisatieIsRelatieNaamComponent = useCallback(
    (naamProps: INaamComponentProps) => {
      return (
        <span
          style={{
            color: Kleur.Grijs,
          }}
        >
          {naamProps.naam}
        </span>
      );
    },
    [],
  );

  const keyExtractor = useCallback((row: IOproep) => row.ID, []);

  const kolommen = useMemo<ASPKolom<EKolom, IOproep>[]>(
    () => [
      {
        key: EKolom.Richting,
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 35,
        renderer: (row) => {
          const isUitgaand = row.Richting === 'U';
          return isUitgaand ? (
            <IconUitgaand
              style={{
                width: 18,
                height: 18,
                fill: Kleur.Blauw,
                transform: 'rotate(45deg)',
              }}
            />
          ) : (
            <IconInkomend
              style={{
                width: 18,
                height: 18,
                fill: Kleur.DonkerGroen,
                transform: 'rotate(45deg)',
              }}
            />
          );
        },
      },
      {
        key: EKolom.Nummer,
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 150,
        label: 'Telefoonnummer',
        renderer: (row) => {
          if (row.Nummer === null) {
            return <span>Anoniem</span>;
          }

          return (
            <TelefoonComponent telefoonNummer={row.Nummer} options={{ icoonWeergeven: false }} />
          );
        },
      },
      {
        key: EKolom.Contact,
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 300,
        label: 'Contact',
        renderer: (row) => {
          return (
            <div className="d-flex flex-column">
              <ContactKoppelComponent
                persID={row.PersID}
                orgID={row.OrgID}
                onChange={() => {
                  alert('TODO');
                }}
              />
              {/* <span style={{ fontSize: 13 }}>{oproep.nummer}</span> */}
            </div>
          );
        },
      },
      {
        key: EKolom.Afdeling,
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 125,
        label: 'Afdeling',
        renderer: (row) => {
          if (row.OrgAfdID === null) {
            return '- -';
          }
          const afdeling = afdelingen.data?.find((x) => x.ID === row.OrgAfdID!) ?? null;
          if (afdelingen.state === ERemoteDataState.Pending || afdeling === null) {
            return <Skeleton />;
          }

          return afdeling.Naam;
        },
      },
      {
        key: EKolom.InzakeVan,
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 300,
        label: 'Inzake van',
        renderer: (row) => {
          if (contactpersonen.state === ERemoteDataState.Pending) {
            return <Skeleton />;
          }

          // Als het een organisatie is, dan is het altijd zichzelf
          // zo niet, dan kijken of we een persID en relID hebben
          // en kijken of dit deze persoon de relatie is
          const persoonOfOrganisatieIsRelatie =
            row.OrgID !== null
              ? true
              : row.PersID === null || row.Inzake_RelID === null
              ? false
              : contactpersonen.data!.find(
                  (x) => x.PersID === row.PersID && x.RelID === row.Inzake_RelID,
                ) !== undefined;

          return (
            <RelatieKoppelComponent
              relID={row.Inzake_RelID}
              onRelIDChange={async (relID) => {
                const result = await api.v2.telefonie.wijzigenTelefoonOproep({
                  telOprID: row.ID,
                  inzake_RelID: relID,
                });
                // await verversenTabeldata();
              }}
              relatieNaamComponent={
                persoonOfOrganisatieIsRelatie
                  ? persoonOfOrganisatieIsRelatieNaamComponent
                  : undefined
              }
            />
          );
        },
      },
      {
        key: EKolom.Datum,
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 160,
        label: 'Datum/tijd',
        renderer: (row) => {
          return <span>{format(new Date(row.Datum), 'dd-MM-yyyy HH:mm')}</span>;
        },
      },
      {
        key: EKolom.NummberBedrijf,
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 115,
        label: 'Tel. bedrijf',
        renderer: (row) => {
          return (
            <div className="d-flex flex-column">
              <span style={{ fontSize: 13 }}>
                {row.NummerBedrijf !== null ? row.NummerBedrijf : ''}
              </span>
              {/* <span style={{ fontSize: 13 }}>{oproep.NummerBedrijf}</span> */}
            </div>
          );
        },
      },
      {
        key: EKolom.Gespreksindicatie,
        breedteType: EAspKolomBreedteType.Flex,
        flex: 1,
        minimaleVasteBreedte: 300,
        label: 'Gespreksindicatie',
        renderer: (row) => {
          const laatsteEvent = row.events[row.events.length - 1];
          if (laatsteEvent === undefined) {
            return <span>- -</span>;
          }
          const eventStatus: ETelefooneventStatus = laatsteEvent.Status;

          switch (eventStatus) {
            case ETelefooneventStatus.Inkomend: {
              return <span className="font-italic">Inkomend...</span>;
            }
            case ETelefooneventStatus.Rinkelt: {
              return <span className="font-italic">Rinkelt...</span>;
            }
            case ETelefooneventStatus.Beantwoord: {
              return (
                <div className="d-flex align-items-center flex-fill justify-content-between">
                  {row.AspGebrID === null ? (
                    <span className="font-italic">Gebruiker niet toegewezen</span>
                  ) : (
                    <div className="d-flex align-items-center">
                      <span className="mr-2 text-muted">In gesprek met</span>
                      <ASPGebruikerVisualisatie
                        aspGebrID={row.AspGebrID}
                        modus={EModus.Volledig}
                        rootStyle={{ fontSize: 13 }}
                        avatarGrootte={20}
                      />
                    </div>
                  )}
                  <span className="ml-3">
                    <Gespreksduratie sinds={new Date(laatsteEvent.Datum)} />
                  </span>
                </div>
              );
            }
            case ETelefooneventStatus.Beeindigd: {
              return <span className="font-italic">Beeïndigd</span>;
            }
          }
        },
      },
      {
        key: EKolom.Acties,
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 50,
        renderer: (row) => {
          return (
            <ActieMenuKnop
              acties={[
                {
                  text: 'Markeren als beëindigd',
                  onClick: async () => {
                    await api.v2.telefonie.markerenTelefoonoproepAlsBeeindigd({
                      telOprID: row.ID,
                    });
                  },
                },
              ]}
            />
          );
        },
      },
    ],
    [afdelingen, contactpersonen],
  );

  const isAanHetLaden = useMemo(() => telefoonOproepen.state === ERemoteDataState.Pending, [
    telefoonOproepen,
  ]);

  return (
    <div className="d-flex flex-fill flex-column">
      {isAanHetLaden ? (
        <div className="d-flex flex-fill justify-content-center align-items-center">
          <LoadingSpinner />
        </div>
      ) : (
        <div className="d-flex flex-fill flex-column">
          <div className="flex-fill d-flex flex-column">
            {telefoonOproepen.state === ERemoteDataState.Pending ? (
              <div className="d-flex flex-fill justify-content-center align-items-center">
                <LoadingSpinner />
              </div>
            ) : (
              <ASPTabel
                rijen={telefoonOproepen.data!}
                kolommen={kolommen}
                totaalAantalRijen={Object.keys(telefoonOproepen.data!).length}
                keyExtractor={keyExtractor}
                leegComponent={(leegProps) => (
                  <div
                    style={{
                      width: leegProps.width,
                      height: leegProps.height,
                    }}
                    className="d-flex align-items-center justify-content-center"
                  >
                    <h4>Geen actuele telefoongesprekken</h4>
                  </div>
                )}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
});

export default ActueelTabblad;
