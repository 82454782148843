import React, { useCallback, useMemo, useState, useEffect, useContext } from 'react';
import IDialoogProps from '../../../../../../../../../core/IDialoogProps';
import Dialoog from '../../../../../../../../../components/dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import api from '../../../../../../../../../api';
import { Field, FieldProps, Formik, FormikActions, FormikErrors, FormikProps } from 'formik';
import { RootStoreContext } from '../../../../../../../../../stores/RootStore';
import { EResultType } from '../../../../../../../../../stores/CheckStore';
import LoadingSpinner from '../../../../../../../../../components/Gedeeld/LoadingSpinner';
import useUrlState from '../../../../../../../../../core/useUrlState';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  DXTableCheckboxComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../../../../../../helpers/dxTableGrid';
import {
  Grid,
  Table,
  TableColumnResizing,
  TableHeaderRow,
  TableSelection,
} from '@devexpress/dx-react-grid-bootstrap4';
import { SelectionState } from '@devexpress/dx-react-grid';
import { IOphalenDocumentenResultElement } from '../../../../../../../../../../../shared/src/api/v2/relatie/acceptatie';

export interface IDialoogResult {}

interface IProps extends IDialoogProps<IDialoogResult> {
  aprProcID: number;
  uitsluitenIDs: number[];
}

interface IFormikValues {
  berichtVersturen: boolean;
}

// const veldnamen: Record<keyof IFormikValues, string | null> = {
//   berichtVersturen: 'Direct een acceptatiebericht versturen naar de relatie',
// };

export interface IDocument extends IOphalenDocumentenResultElement {}

const ToevoegenDialoog: React.FC<IProps> = (props) => {
  const { children, dialoogIndex, onAnnuleren, onSuccess, open } = props;
  const { checkStore } = useContext(RootStoreContext);

  const [hasSubmitted, setHasSubmitted] = useState(false);

  const [documenten, setDocumenten] = useState<IOphalenDocumentenResultElement[] | null>(null);

  const [selectie, setSelectie] = useState<number[]>([]);

  const ophalenDocumenten = useCallback(async () => {
    const documenten = (
      await api.v2.relatie.acceptatie.ophalenDocumenten({
        filterSchema: {
          filters: [{ naam: 'UITSLUITEN_IDS', data: props.uitsluitenIDs }],
        },
        orderSchema: {
          orders: [
            {
              naam: 'NAAM',
              richting: 'ASC',
            },
          ],
        },
      })
    ).documenten;

    setDocumenten(documenten);
  }, [props.uitsluitenIDs]);

  useEffect(() => {
    ophalenDocumenten();
  }, [ophalenDocumenten]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      // if (
      //   values.berichtVersturen &&
      //   (
      //     await checkStore.bevestigen({
      //       titel: (
      //         <>
      //           <span>
      //             Nieuwe procedure maken ?<br />
      //             Er zal direct een verzoek voor documenten verstuurd worden.
      //           </span>
      //         </>
      //       ),
      //     })
      //   ).type === EResultType.Annuleren
      // ) {
      //   actions.setSubmitting(false);
      //   return;
      // }

      const params = { aprProcID: props.aprProcID, aprDocIDs: selectie };

      const result = await api.v2.relatie.acceptatie.toevoegenDocumentenAanProcedure(params);

      // setUrlStateSync('selectie', []);

      actions.setSubmitting(false);

      onSuccess({});
    },
    [selectie, setHasSubmitted],
  );

  const initialValues = useMemo<IFormikValues>(() => {
    return {
      berichtVersturen: false,
    };
  }, []);

  // const validationSchema = useMemo(
  //   () =>
  //     Yup.object().shape({
  //       bezoekDatum: Yup.date().required(
  //         teksten.formulier.E_VERPLICHT_VELD({
  //           veldnaam: veldnamen.afmeldDatum!,
  //         }),
  //       ),
  //       referentiecode: Yup.string().notRequired(),
  //     }),
  //   [],
  // );

  const keyExtractor = useCallback((row: IDocument) => row.ID, []);

  const kolommen = useMemo<TypedColumn<IDocument>[]>(
    () => [
      {
        name: 'Naam',
        title: 'Naam',
      },
      // {
      //   name: 'Toelichting',
      //   title: 'Toelichting',
      // },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IDocument>[]>(
    () => [
      {
        columnName: 'Naam',
        width: 350,
      },
      // {
      //   columnName: 'Toelichting',
      //   width: 175,
      // },
    ],
    [],
  );

  return (
    <>
      <Dialoog index={dialoogIndex || 0}>
        <ModalHeader>
          <ModalTitle>Documenten toevoegen</ModalTitle>
        </ModalHeader>
        {initialValues === null || documenten === null ? (
          <LoadingSpinner />
        ) : (
          <Formik<IFormikValues>
            isInitialValid
            enableReinitialize
            onSubmit={handleSubmit}
            initialValues={initialValues}
            // validationSchema={validationSchema}
            render={(formikProps: FormikProps<IFormikValues>) => {
              const { submitForm, isSubmitting, isValid } = formikProps;

              return (
                <>
                  <ModalBody>
                    <div className="form-group">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center pt-2">
                          <GridStyleWrapper rowAmount={documenten.length} maxHeight={500}>
                            <Grid rows={documenten} columns={kolommen} getRowId={keyExtractor}>
                              <Table
                                messages={{
                                  noData: 'Geen documenten',
                                }}
                              />
                              <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
                              <TableHeaderRow />
                              <SelectionState
                                selection={selectie}
                                onSelectionChange={(x) => setSelectie(x as number[])}
                              />
                              <TableSelection cellComponent={DXTableCheckboxComponent} />
                            </Grid>
                          </GridStyleWrapper>
                        </div>
                      </div>
                    </div>
                  </ModalBody>
                  <ModalFooter className="d-flex flex-row justify-content-start">
                    <button
                      className="btn btn-primary"
                      onClick={submitForm}
                      style={{ width: 100 }}
                      disabled={!isValid || isSubmitting || selectie.length === 0}
                    >
                      Ok
                    </button>
                    <button
                      className="btn btn-secondary"
                      onClick={onAnnuleren}
                      style={{ width: 100 }}
                      disabled={isSubmitting}
                    >
                      Annuleren
                    </button>
                  </ModalFooter>
                </>
              );
            }}
          />
        )}
      </Dialoog>
    </>
  );
};

export default ToevoegenDialoog;
