import { addDays, addMonths } from 'date-fns';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IFilterSchema } from '../../../../../../shared/src/models/filter';
import api from '../../../../api';
import { EOpdrachtstatusTransport } from '../../../../bedrijfslogica/enums';
import FilterBalkV2, {
  IFilter,
  IFilterData,
  maakFilterSchema,
} from '../../../../components/FilterBalkV2';
import DatumKiezer from '../../../../components/formulier/DatumKiezer';
import AfgehandeldTabel, {
  IOpdracht,
  ITransportopdrachtInfoDialoogState,
  IWijzigenOpdrachtDialoogState,
} from '../../../../components/transport/AfgehandeldTabel';
import useUrlState from '../../../../core/useUrlState';
import { dagDatum } from '../../../../helpers/datum';
import { selectieCheck } from '../../../../helpers/selectie';

interface IProps extends RouteComponentProps {}

enum EFilter {
  DatumVanaf = 'DATUM_AFGEHANDELD_VANAF',
  DatumTot = 'DATUM_AFGEHANDELD_TOT_EN_MET',
  BezoekDatum = 'BEZOEKDATUM',
}

export interface ITransportOpdrachtenAfgehandeldUrlState {
  opdrachtenSelectie: number[];
  uitgeklapteOpdrachten: number[];
  regelsSelectie: number[];
  wijzigenOpdrachtDialoogState: IWijzigenOpdrachtDialoogState | null;
  filterData: IFilterData<EFilter>[];
  transportopdrachtInfoDialoogState: ITransportopdrachtInfoDialoogState | null;
}

export const defaultTransportOpdrachtenAfgehandeldUrlState: ITransportOpdrachtenAfgehandeldUrlState = {
  transportopdrachtInfoDialoogState: null,
  opdrachtenSelectie: [],
  uitgeklapteOpdrachten: [],
  regelsSelectie: [],
  wijzigenOpdrachtDialoogState: null,
  filterData: [
    {
      naam: EFilter.DatumVanaf,
      isActief: true,
      data: dagDatum(addMonths(new Date(), -1)),
    },
    {
      naam: EFilter.DatumTot,
      isActief: true,
      data: dagDatum(addDays(new Date(), +1)),
    },
    {
      naam: EFilter.BezoekDatum,
      isActief: false,
      data: dagDatum(new Date()),
    },
  ],
};

const Afgehandeld: React.FC<IProps> = (props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(
    props,
    defaultTransportOpdrachtenAfgehandeldUrlState,
  );
  const [opdrachten, setOpdrachten] = useState<IOpdracht[] | null>(null);
  const [ophalenBezig, setOphalenBezig] = useState<boolean>(false);

  const [filterSchema, setFilterSchema] = useState<IFilterSchema>(
    maakFilterSchema(urlState.filterData),
  );

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.DatumVanaf,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return (
            <div className="d-flex align-items-center">
              <span>Datum afgehandeld vanaf</span>
              <span className="ml-2">
                <DatumKiezer
                  waarde={weergaveProps.data === null ? null : new Date(weergaveProps.data)}
                  onGewijzigd={(x) => {
                    weergaveProps.onDataChange(x === null ? null : x.toISOString());
                    weergaveProps.toepassen();
                  }}
                  determineValidDate={() => true}
                  determineNextValidDate={(date) => addDays(date, 1)}
                  determinePreviousValidDate={(date) => addDays(date, -1)}
                />
              </span>
            </div>
          );
        },
      },
      {
        naam: EFilter.DatumTot,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return (
            <div className="d-flex align-items-center">
              <span>T/m</span>
              <span className="ml-2">
                <DatumKiezer
                  waarde={weergaveProps.data === null ? null : new Date(weergaveProps.data)}
                  onGewijzigd={(x) => {
                    weergaveProps.onDataChange(x === null ? null : x.toISOString());
                    weergaveProps.toepassen();
                  }}
                  determineValidDate={() => true}
                  determineNextValidDate={(date) => addDays(date, 1)}
                  determinePreviousValidDate={(date) => addDays(date, -1)}
                />
              </span>
            </div>
          );
        },
      },
      {
        naam: EFilter.BezoekDatum,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return (
            <div className="d-flex align-items-center">
              <span>Bezoekdatum</span>
              <span className="ml-2">
                <DatumKiezer
                  waarde={weergaveProps.data === null ? null : new Date(weergaveProps.data)}
                  onGewijzigd={(x) => {
                    weergaveProps.onDataChange(x === null ? null : x.toISOString());
                    weergaveProps.toepassen();
                  }}
                  determineValidDate={() => true}
                  determineNextValidDate={(date) => addDays(date, 1)}
                  determinePreviousValidDate={(date) => addDays(date, -1)}
                />
              </span>
            </div>
          );
        },
      },
    ],
    [],
  );

  const ophalenOpdrachten = useCallback(async () => {
    setOphalenBezig(true);
    const opdrachtenResult = (
      await api.v2.transport.opdracht.ophalenOpdrachtenV2({
        filterSchema: {
          filters: [
            ...filterSchema.filters!,
            { naam: 'STATUSSEN', data: [EOpdrachtstatusTransport.Afgehandeld] },
          ].filter((x) => x !== null),
        },
        orderSchema: { orders: [{ naam: 'DATUM_AFGEHANDELD', richting: 'DESC' }] },
      })
    ).opdrachten;

    if (opdrachtenResult.length === 0) {
      setOpdrachten([]);
      return;
    }

    const trsOpdIDs = opdrachtenResult.map((x) => x.TrsOpdID);

    const regelsResult = (
      await api.v2.transport.opdracht.ophalenOpdrachtregelsV2({
        filterSchema: { filters: [{ naam: 'TRSOPD_IDS', data: trsOpdIDs }] },
        orderSchema: {
          orders: [
            {
              naam: 'REGELNUMMER',
              richting: 'ASC',
            },
          ],
        },
      })
    ).regels;

    const relIDs = regelsResult.filter((x) => x.RelID !== null).map((x) => x.RelID);

    const relatiesResult = (
      await api.v2.relatie.ophalenRelaties({
        filterSchema: { filters: [{ naam: 'IDS', data: relIDs }] },
      })
    ).relaties;

    const opdrachten = opdrachtenResult.map((opdracht) => {
      const regelsVoorOpdracht = regelsResult.filter((x) => x.TrsOpdID === opdracht.TrsOpdID);

      const regels = regelsVoorOpdracht.map((regel) => {
        const relatie =
          regel.RelID !== null ? relatiesResult.find((x) => x.RelID === regel.RelID)! : null;
        return { ...regel, relatie };
      });

      return { ...opdracht, regels };
    });

    {
      const selectie = selectieCheck(
        opdrachten.map((x) => x.TrsOpdID),
        urlState.opdrachtenSelectie,
      );
      if (selectie !== null) {
        setUrlStateSync('opdrachtenSelectie', selectie);
      }
    }
    {
      const selectie = selectieCheck(
        opdrachten.map((x) => x.regels.map((x) => x.TrsRegID)).flat(),
        urlState.regelsSelectie,
      );
      if (selectie !== null) {
        setUrlStateSync('regelsSelectie', selectie);
      }
    }
    setOpdrachten(opdrachten);
    setOphalenBezig(false);
  }, [
    // JSON.stringify(urlState.opdrachtenSelectie),
    // JSON.stringify(urlState.regelsSelectie),
    filterSchema,
  ]);

  useEffect(() => {
    ophalenOpdrachten();
  }, [ophalenOpdrachten]);

  return (
    <>
      <Helmet>
        <title>Transport - Afgehandeld</title>
      </Helmet>
      <AfgehandeldTabel
        opdrachten={opdrachten}
        ophalenBezig={ophalenBezig}
        opdrachtenSelectie={urlState.opdrachtenSelectie}
        onOpdrachtenSelectieChange={(value: number[]) =>
          setUrlStateSync('opdrachtenSelectie', value)
        }
        uitgeklapteOpdrachten={urlState.uitgeklapteOpdrachten}
        onUitgeklapteOpdrachtenChange={(value: number[]) =>
          setUrlStateSync('uitgeklapteOpdrachten', value)
        }
        regelsSelectie={urlState.regelsSelectie}
        onRegelsSelectieChange={(value: number[]) => setUrlStateSync('regelsSelectie', value)}
        wijzigenOpdrachtDialoogState={urlState.wijzigenOpdrachtDialoogState}
        onWijzigenOpdrachtDialoogStateChange={(value: IWijzigenOpdrachtDialoogState | null) =>
          setUrlStateSync('wijzigenOpdrachtDialoogState', value)
        }
        onRequestRefresh={() => ophalenOpdrachten()}
        transportopdrachtInfoDialoogState={urlState.transportopdrachtInfoDialoogState}
        onTransportopdrachtInfoDialoogStateChange={(
          value: ITransportopdrachtInfoDialoogState | null,
        ) => setUrlStateSync('transportopdrachtInfoDialoogState', value)}
        filterbalk={
          <FilterBalkV2
            filters={filters}
            filterData={urlState.filterData}
            onFilterDataChange={(x) => setUrlStateSync('filterData', x)}
            onFilterSchemaChange={(x) => setFilterSchema(x)}
          />
        }
      />
    </>
  );
};

export default withRouter(Afgehandeld);
