import React, { useContext, useMemo } from 'react';
import { IProps } from '@tinymce/tinymce-react/lib/cjs/main/ts/components/Editor';
import { Field, FieldProps } from 'formik';
import { IFormikValues, IRegel, veldnamen } from '../index';
import { IContext, ProductContext } from '../index';
import VeldWeergave from '../../../formulier/VeldWeergave';
import LoadingSpinner from '../../../Gedeeld/LoadingSpinner';
import { GridStyleWrapper, TypedColumn } from '../../../../helpers/dxTableGrid';
import { IOphalenContractenResultElementV2 } from '../../../../../../shared/src/api/v2/contract';
import { Grid, Table, TableHeaderRow } from '@devexpress/dx-react-grid-bootstrap4';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import nameof from '../../../../core/nameOf';
import { differenceInDays, format } from 'date-fns';
import RelatieVisualisatie from '../../../personalia/RelatieVisualisatie';

const ContractenTab: React.FC<IProps> = (props) => {
  const { productmodel, contracten, peildatum } = useContext<IContext>(ProductContext);

  const dagen = differenceInDays(new Date(), peildatum!);

  const kolommen = useMemo<TypedColumn<IOphalenContractenResultElementV2>[]>(
    () => [
      {
        name: 'Ingangsdatum',
        title: 'Ing.datum',
      },
      {
        name: '__geleverd' as any,
        title: 'Geleverd',
      },
      {
        name: '__relatie' as any,
        title: 'Relatie',
      },
    ],
    [],
  );

  const columnExtensions = useMemo<Table.ColumnExtension[]>(
    () => [
      {
        columnName: 'Ingangsdatum',
        width: 100,
      },
      {
        columnName: '__geleverd' as any,
        width: 100,
      },
      {
        columnName: '__relatie' as any,
        width: 300,
      },
    ],
    [],
  );

  return (
    <>
      <div className="d-flex flex-column flex-fill">
        {contracten === null ? (
          <div className="d-flex flex-fill align-items-center justify-content-center">
            <LoadingSpinner />
          </div>
        ) : (
          <div className="d-flex flex-column flex-fill">
            <div className="p-3">
              <VeldWeergave>Gebruik voor de laatste {dagen} dagen</VeldWeergave>
            </div>
            <GridStyleWrapper maxHeight={500} rowAmount={contracten.length}>
              <Grid rows={contracten} columns={kolommen}>
                <DataTypeProvider
                  for={[nameof<IOphalenContractenResultElementV2>('Ingangsdatum')]}
                  formatterComponent={(formatterProps) => (
                    <span>{format(new Date(formatterProps.value), 'dd-MM-yyyy')}</span>
                  )}
                />

                <DataTypeProvider
                  for={['__geleverd']}
                  formatterComponent={(formatterProps) => {
                    const rij: IOphalenContractenResultElementV2 = formatterProps.row;
                    if (rij.basis.DatumGeleverd === null) {
                      return <span></span>;
                    }
                    return <span>{format(new Date(rij.basis.DatumGeleverd), 'dd-MM-yyyy')}</span>;
                  }}
                />

                <DataTypeProvider
                  for={['__relatie']}
                  formatterComponent={(formatterProps) => {
                    const rij: IOphalenContractenResultElementV2 = formatterProps.row;
                    return (
                      <RelatieVisualisatie
                        relID={rij.RelID}
                        options={{ geenLinkToepassen: true }}
                      />
                    );
                  }}
                />

                <Table columnExtensions={columnExtensions} />
                <TableHeaderRow />
              </Grid>
            </GridStyleWrapper>
          </div>
        )}
      </div>
    </>
  );
};

export default ContractenTab;
