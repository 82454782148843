import {
  DataTypeProvider,
  IntegratedSorting,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import { format } from 'date-fns';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { AutoSizer } from 'react-virtualized';
import {
  IOphalenFacturenResultElement,
  ISponsorcontract,
} from '../../../../../../../shared/src/api/v2/inkoopfactuur';
import { IOphalenContractenResultElement } from '../../../../../../../shared/src/api/v2/sponsoring/contract';
import api from '../../../../../api';
import { Kleur as EKleur } from '../../../../../bedrijfslogica/constanten';
import { IFilterData } from '../../../../../components/FilterBalkV2';
import LoadingSpinner from '../../../../../components/Gedeeld/LoadingSpinner';
import { IconVlag } from '../../../../../components/Icons';
import MenuLayout from '../../../../../components/MenuLayout';
import FormatteerBedrag from '../../../../../components/MutatieBedrag';
import RelatieVisualisatie from '../../../../../components/personalia/RelatieVisualisatie';
import nameOf from '../../../../../core/nameOf';
import useUrlState from '../../../../../core/useUrlState';
import {
  DXTableCheckboxComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../../helpers/dxTableGrid';

interface IProps extends RouteComponentProps {}

export interface IUrlState {
  selectie: number[];
  // filterdata: IFilterData<EFilter>[];
}

const defaultUrlState: IUrlState = {
  selectie: [],
};

interface IRegel extends IOphalenContractenResultElement {
  facturenVoorContract: {
    BedragGekoppeld: number;
    factuur: IOphalenFacturenResultElement;
  }[];
}

const Sponsoring: React.FC<IProps> = observer((props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);

  const [sponsorcontracten, setSponsorcontracten] = useState<IRegel[] | null>(null);

  const ophalenContracten = useCallback(async () => {
    const contracten = (
      await api.v2.sponsoring.contract.ophalenContracten({
        filterSchema: {
          filters: [
            // { naam: 'GEKOPPELD_AAN_INKOOPFACTUUR', data: false },
            { naam: 'NIET_KOPPELEN_AAN_INKOOPFACTUUR', data: false },
          ],
        },
        orderSchema: {
          orders: [{ naam: 'CONTRACTDATUM', richting: 'DESC' }],
        },
      })
    ).contracten;
    if (contracten.length === 0) {
      setSponsorcontracten([]);
      return;
    }
    const sponCntIDs = contracten.map((x) => x.ID);

    // Haal de inkoopfacturen erbij
    const facturenXContractenResult = (
      await api.v2.inkoopfactuur.ophalenFacturenXSponsorcontracten({
        filterSchema: { filters: [{ naam: 'SPONCNT_IDS', data: sponCntIDs }] },
      })
    ).data;
    const inkFactIDs = _.uniq(facturenXContractenResult.map((x) => x.InkFactID));

    const facturenResult = await api.v2.inkoopfactuur.ophalenFacturen({
      filterSchema: { filters: [{ naam: 'IDS', data: inkFactIDs }] },
    });

    const result = contracten.map((contract) => {
      // Kruistabel data
      const factuurXContracten = facturenXContractenResult.filter(
        (x) => x.SponCntID === contract.ID,
      );
      // Hiervan de InkFactIDs extraheren
      const inkFactIDs = factuurXContracten.map((x) => x.InkFactID);
      // Haal de factuurdata op
      const facturen = facturenResult.facturen.filter(
        (x) => inkFactIDs.indexOf(x.InkFactID) !== -1,
      );
      // Bepaal de data voor de facturen voor het contract
      const facturenVoorContract = factuurXContracten.map((x) => {
        const factuur = facturen.find((y) => y.InkFactID === x.InkFactID)!;
        return { BedragGekoppeld: x.BedragGekoppeld, factuur };
      });
      return { ...contract, facturenVoorContract };
    });

    setSponsorcontracten(result);
  }, []);

  useEffect(() => {
    ophalenContracten();
  }, [ophalenContracten]);

  const keyExtractor = useCallback((row: IRegel) => row.ID, []);

  const kolommen = useMemo<TypedColumn<IRegel>[]>(
    () => [
      {
        name: 'OnzeReferentie',
        title: 'Cnt.ref.',
      },
      {
        name: 'Contractdatum',
        title: 'Contractdatum',
      },
      {
        name: 'Registratiedatum',
        title: 'Registratiedatum',
      },
      {
        name: '__relatie' as any,
        title: 'Relatie',
        getCellValue: (x) => x.dienst.relatie!.weergavenaam,
      },
      {
        name: 'Inhoud',
        title: 'Inhoud',
      },
      {
        name: 'BedragTotaal',
        title: 'Bedrag',
      },
      {
        name: '__gekoppeldeFacturen' as any,
        title: 'Gekoppeld in facturen',
      },
    ],
    [],
  );
  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRegel>[]>(
    () => [
      {
        columnName: 'OnzeReferentie',
        width: 100,
      },
      {
        columnName: 'Registratiedatum',
        width: 155,
      },
      {
        columnName: 'Contractdatum',
        width: 135,
      },
      {
        columnName: '__relatie' as any,
        width: 200,
      },
      {
        columnName: 'Inhoud',
        width: 400,
      },
      {
        columnName: 'BedragTotaal',
        width: 120,
      },
      {
        columnName: '__gekoppeldeFacturen' as any,
        width: 250,
      },
    ],
    [],
  );

  return (
    <MenuLayout
      // menu={
      //   <>
      //     <div className="d-flex align-items-center">
      //       <button
      //         className="btn btn-sm btn-light d-flex align-items-center"
      //         style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
      //         // disabled={selectie.length === 0}
      //         onClick={() => null}
      //       >
      //         <IconVlag style={{ width: 16, height: 16, fill: EKleur.Grijs }} />
      //         <span className="ml-2">Knop</span>
      //       </button>

      //       <div className="d-flex ml-5">
      //         <FilterBalkV2
      //           filters={filters}
      //           filterData={filterData}
      //           onFilterDataChange={(x) => setFilterData(x)}
      //           onFilterSchemaChange={(x) => {}}
      //         />
      //       </div>
      //     </div>
      //   </>
      // }
      body={
        sponsorcontracten === null ? (
          <div className="flex-fill d-flex align-items-center justify-content-center">
            <LoadingSpinner />
          </div>
        ) : (
          <AutoSizer className="d-flex flex-column flex-fill w-100 h-100">
            {({ height, width }) => (
              <GridStyleWrapper height={height}>
                <Grid getRowId={keyExtractor} columns={kolommen} rows={sponsorcontracten}>
                  <DataTypeProvider
                    for={[nameOf<IRegel>('Registratiedatum')]}
                    formatterComponent={(formatterProps) => {
                      if (formatterProps.value === null) {
                        return <span></span>;
                      }
                      return <span>{format(new Date(formatterProps.value), 'dd-MM-yyyy')}</span>;
                    }}
                  />

                  <DataTypeProvider
                    for={[nameOf<IRegel>('Contractdatum')]}
                    formatterComponent={(formatterProps) => {
                      if (formatterProps.value === null) {
                        return <span></span>;
                      }
                      return <span>{format(new Date(formatterProps.value), 'dd-MM-yyyy')}</span>;
                    }}
                  />

                  <DataTypeProvider
                    for={['__relatie']}
                    formatterComponent={(formatterProps) => {
                      const rij: IRegel = formatterProps.row;
                      return <RelatieVisualisatie relID={rij.dienst.relatie!.RelID} />;
                    }}
                  />

                  <DataTypeProvider
                    for={['__gekoppeldeFacturen']}
                    formatterComponent={(formatterProps) => {
                      const rij: IRegel = formatterProps.row;
                      const facturen = rij.facturenVoorContract
                        .map((x) => x.factuur.Factuurnummer)
                        .join(', ');
                      return <span>{facturen}</span>;
                    }}
                  />

                  {/* <DataTypeProvider
                    for={['__adres']}
                    formatterComponent={(formatterProps) => {
                      const row: IOphalenLocatieResult = formatterProps.row;
                      return (
                        <span>
                          {formatteerAdresV2({
                            huisnummer: row.Huisnummer,
                            bisnummer: row.Bisnummer,
                            landnaamEnum: row.LandnaamEnum,
                            landnaamKort: row.LandnaamKort,
                            plaatsnaam: row.Plaatsnaam,
                            straatnaam: row.Straatnaam,
                            postcode: row.Postcode,
                          })}
                        </span>
                      );
                    }}
                  /> */}

                  <DataTypeProvider
                    for={[nameOf<IRegel>('BedragTotaal')]}
                    formatterComponent={(formatterProps) => {
                      return <FormatteerBedrag bedrag={formatterProps.value} />;
                    }}
                  />

                  {/* <DataTypeProvider
                    for={[nameOf<IOphalenContractenResultElement>('Plaatsnaam')]}
                    formatterComponent={(formatterProps) => {
                      return <span>{formatterProps.value}</span>;
                    }}
                  /> */}

                  <SortingState defaultSorting={[]} />
                  <IntegratedSorting />

                  {/* <SelectionState
                    selection={urlState.selectie}
                    onSelectionChange={(x) => setUrlStateSync('selectie', x as number[])}
                  /> */}

                  <VirtualTable
                    // cellComponent={CellComponent}
                    messages={{ noData: 'Geen sponsorcontracten voor het ingestelde filter' }}
                  />
                  {/* <TableSelection cellComponent={DXTableCheckboxComponent} /> */}
                  <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
                  <TableHeaderRow showSortingControls />
                </Grid>
              </GridStyleWrapper>
            )}
          </AutoSizer>
        )
      }
    />
  );
});

export default withRouter(Sponsoring);
