import { pad } from 'lodash';

const urlCriteria = {
  overzicht: (pad: string) => pad.startsWith('/overzicht'),
  contracten: (pad: string) => pad.startsWith('/contracten'),
  verkoop: (pad: string) => pad.startsWith('/verkoop'),
  communicatie: (pad: string) => pad.startsWith('/communicatie'),
  transport: (pad: string) => pad.startsWith('/transport'),
  service: (pad: string) => pad.startsWith('/service'),
  facturen: (pad: string) => pad.startsWith('/facturen'),
  accounts: (pad: string) => pad.startsWith('/accounts'),
  afdelingen: (pad: string) => pad.startsWith('/afdelingen'),
  events: (pad: string) => pad.startsWith('/events'),
  debiteur: (pad: string) => pad.startsWith('/debiteur'),
  bankzaken: (pad: string) => pad.startsWith('/bankzaken'),
  klant: (pad: string) => pad.startsWith('/klant'),
  contactpersonen: (pad: string) => pad.startsWith('/contactpersonen'),
  locaties: (pad: string) => pad.startsWith('/locaties'),
  kredietwaardigheid: (pad: string) => pad.startsWith('/kredietwaardigheid'),
  boekingen: (pad: string) => pad.startsWith('/boekingen'),
  acceptatie: (pad: string) => pad.startsWith('/acceptatie'),
  signaleringen: (pad: string) => pad.startsWith('/signaleringen'),
  productadvies: (pad: string) => pad.startsWith('/productadvies'),
  vertegenwoordiging: (pad: string) => pad.startsWith('/vertegenwoordiging'),
  winkelwagens: (pad: string) => pad.startsWith('/winkelwagens'),
  financieel: (pad: string) => pad.startsWith('/financieel'),
  documenten: (pad: string) => pad.startsWith('/bestanden'),
  rekeningen: (pad: string) => pad.startsWith('/rekeningen'),
  rubrieken: (pad: string) => pad.startsWith('/rubrieken'),
  reviews: (pad: string) => pad.startsWith('/reviews'),
  webbetalingen: (pad: string) => pad.startsWith('/webbetalingen'),
  partnerships: (pad: string) => pad.startsWith('/partnerships'),
  complexen: (pad: string) => pad.startsWith('/complexen'),
  opvolging: (pad: string) => pad.startsWith('/opvolging'),
};

export default urlCriteria;
