import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import MutatieTabel from '../../../../../../../components/relatie/MutatieTabel';
import api from '../../../../../../../api';
import { IOphalenMutatiesResultElement } from '../../../../../../../../../shared/src/api/v2/relatie/mutatie';
import useUrlState from '../../../../../../../core/useUrlState';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { RootStoreContext } from '../../../../../../../stores/RootStore';
import { KlantContext } from '../index';

interface IProps extends RouteComponentProps {
  relID: number;
}

export interface IUrlState {
  mutatiesSelectie: number[];
  uitgeklapteMutaties: number[];
}

export const defaultUrlState: IUrlState = {
  mutatiesSelectie: [],
  uitgeklapteMutaties: [],
};

const Mutaties: React.FC<IProps> = observer((props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const { klantkaartStore, checkStore } = useContext(RootStoreContext);
  const klantContext = useContext(KlantContext);

  const [mutaties, setMutaties] = useState<IOphalenMutatiesResultElement[] | null>(null);

  const ophalenMutaties = useCallback(async () => {
    const { mutaties } = await api.v2.relatie.ophalenMutaties({
      filterSchema: {
        filters: [{ naam: 'REL_IDS', data: [klantContext.relID] }],
      },
    });

    setMutaties(mutaties);
  }, [klantContext.relID]);

  useEffect(() => {
    ophalenMutaties();
  }, [ophalenMutaties]);

  return (
    <MutatieTabel
      mutaties={mutaties}
      mutatiesSelectie={urlState.mutatiesSelectie}
      onMutatiesSelectieChange={(value: number[]) => setUrlStateSync('mutatiesSelectie', value)}
      uitgeklapteMutaties={urlState.uitgeklapteMutaties}
      onUitgeklapteMutatiesChange={(value: number[]) =>
        setUrlStateSync('uitgeklapteMutaties', value)
      }
      onRequestRefresh={() => ophalenMutaties()}
    />
  );
});

export default withRouter(Mutaties);
