import React, { useCallback, useContext, useMemo, useState } from 'react';
import {
  DataTypeProvider,
  EditingState,
  RowDetailState,
  TableRowDetail,
} from '@devexpress/dx-react-grid';
import {
  DXCommandComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import {
  Grid,
  Table,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import { VirtualTable as VirtualTableBase } from '@devexpress/dx-react-grid';
import { format } from 'date-fns';
import TabelInspringBlok from '../../../../components/layout/TabelInspringBlok';
import { IProductVoorAdvies } from '../../../../../../shared/src/api/v2/product/advies';
import { DocumentatieContext, IDocumentatieContext, IRegel } from '..';
import useUrlState from '../../../../core/useUrlState';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { maandenNaarJaren } from '../../../../bedrijfslogica/teksten';
import { IProductype } from '../../../../../../shared/src/api/v2/product/documentatie';
import ToevoegenProducttypeDialoog from '../../../Aanbod/Producten/Productmodellen/DetailRegel/ProducttypesField/ToevoegenDialoog';
import api from '../../../../api';
import { RootStoreContext } from '../../../../stores/RootStore';
import { EResultType } from '../../../../stores/CheckStore';

interface IProps extends RouteComponentProps, TableRowDetail.ContentProps {}

export interface IToevoegenProducttypeDialoogState {}

interface IUrlState {
  // uitgeklapt: number[];
  toevoegenProducttypeDialoogState: IToevoegenProducttypeDialoogState | null;
}

const defaultUrlState: IUrlState = {
  // uitgeklapt: [],
  toevoegenProducttypeDialoogState: null,
};

const DetailComp = (props: IProps) => {
  const urlStateKey = 'producttypeSelectie';

  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState, urlStateKey);
  const { ophalenDocumenten } = useContext<IDocumentatieContext>(DocumentatieContext);
  const { checkStore } = useContext(RootStoreContext);

  const row: IRegel = props.row;
  const producttypen = row.producttypen;

  const kolommen = useMemo<TypedColumn<IProductype>[]>(
    () => [
      {
        name: '__merknaam' as any,
        title: 'Merk',
      },
      {
        name: '__typenaam' as any,
        title: 'Type',
      },
      {
        name: '__productsoortnaam' as any,
        title: 'Cat.',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IProductype>[]>(
    () => [
      {
        columnName: '__merknaam' as any,
        width: 110,
      },
      {
        columnName: '__typenaam' as any,
        width: 175,
      },
      {
        columnName: '__productsoortnaam' as any,
        width: 200,
      },
    ],
    [],
  );

  const keyExtractor = useCallback((x: IProductype) => x.TypeID, []);

  return (
    <>
      <div className="d-flex">
        <TabelInspringBlok />
        <GridStyleWrapper maxHeight={500} rowAmount={producttypen.length}>
          <Grid rows={producttypen} columns={kolommen} getRowId={keyExtractor}>
            <DataTypeProvider
              for={['__merknaam']}
              formatterComponent={(props) => {
                const rij: IProductype = props.row;
                return <span>{rij.Merknaam}</span>;
              }}
            />

            <DataTypeProvider
              for={['__typenaam']}
              formatterComponent={(props) => {
                const rij: IProductype = props.row;
                return <span>{rij.Typenaam}</span>;
              }}
            />

            <DataTypeProvider
              for={['__productsoortnaam']}
              formatterComponent={(props) => {
                const rij: IProductype = props.row;
                return <span>{rij.Productsoortnaam}</span>;
              }}
            />

            <VirtualTable messages={{ noData: 'Geen gekoppelde producttypen' }} />
            <TableColumnResizing defaultColumnWidths={kolomBreedtes} />

            <EditingState
              onAddedRowsChange={() => {
                setUrlStateSync('toevoegenProducttypeDialoogState', {});
              }}
              onEditingRowIdsChange={(x) => {
                const id = x[x.length - 1] as number;
              }}
              onCommitChanges={async (changes) => {
                if (changes.deleted === undefined) {
                  return;
                }
                const deleted = changes.deleted;
                const id = deleted[deleted.length - 1] as number;

                if (
                  (
                    await checkStore.bevestigen({
                      inhoud: `Wil je dit producttype loskoppelen van het document?`,
                    })
                  ).type === EResultType.Annuleren
                ) {
                  return;
                }

                await api.v2.product.documentatie.verwijderenProducttypen({
                  prodDocID: row.ID,
                  typeIDs: [id],
                });

                ophalenDocumenten();
              }}
            />
            <TableEditColumn
              width={65}
              showAddCommand
              showDeleteCommand
              commandComponent={DXCommandComponent}
            />

            {/* <RowDetailState
            expandedRowIds={urlState.uitgeklapt}
            onExpandedRowIdsChange={(x) => setUrlStateSync('uitgeklapt', x as number[])}
          /> */}
            <TableHeaderRow />
          </Grid>
        </GridStyleWrapper>
      </div>
      {urlState.toevoegenProducttypeDialoogState !== null && (
        <ToevoegenProducttypeDialoog
          open
          prodSrtID={null}
          merkID={null}
          uitgeslotenTypeIDs={producttypen.map((x) => x.TypeID)}
          onSuccess={async (result) => {
            const params = { prodDocID: row.ID, typeIDs: [result.typeID] };
            const checkData = await api.v2.product.documentatie.checkToevoegenProducttypen(params);
            if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
              return;
            }
            await api.v2.product.documentatie.toevoegenProducttypen(params);
            setUrlStateSync('toevoegenProducttypeDialoogState', null);
            ophalenDocumenten();
          }}
          onAnnuleren={() => setUrlStateSync('toevoegenProducttypeDialoogState', null)}
        />
      )}
    </>
  );
};

export default withRouter(DetailComp);
