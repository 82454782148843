import {
  IOphalenProducttypenParams,
  OphalenProducttypenResult,
  IOphalenLeveranciersParams,
  OphalenLeveranciersResult,
  IToevoegenOpdrachtParams,
  IToevoegenOpdrachtResult,
  IWijzigenOpdrachtParams,
  IWijzigenOpdrachtResult,
  IOphalenDienstenVoorTypeParams,
  IOphalenDienstenVoorTypeResult,
} from '../../../../../../shared/src/api/v2/inkoop/opdracht/nieuw';
import ICheckData from '../../../../../../shared/src/models/ICheckData';
import api from '../../../index';

const nieuw = {
  ophalenProducttypen: async (
    params: IOphalenProducttypenParams,
  ): Promise<OphalenProducttypenResult> => {
    return await api.post('v2/inkoop/opdracht/nieuw/ophalen-producttypen', params);
  },
  // ophalenLeveranciers: async (
  //   params: IOphalenLeveranciersParams,
  // ): Promise<OphalenLeveranciersResult> => {
  //   return await api.post('v2/inkoop/opdracht/nieuw/ophalen-leveranciers', params);
  // },
  ophalenDienstenVoorType: async (
    params: IOphalenDienstenVoorTypeParams,
  ): Promise<IOphalenDienstenVoorTypeResult> => {
    return await api.post('v2/inkoop/opdracht/nieuw/ophalen-diensten-voor-type', params);
  },
  checkToevoegenOpdracht: async (params: IToevoegenOpdrachtParams): Promise<ICheckData> => {
    return await api.post('v2/inkoop/opdracht/nieuw/check-toevoegen-opdracht', params);
  },
  toevoegenOpdracht: async (
    params: IToevoegenOpdrachtParams,
  ): Promise<IToevoegenOpdrachtResult> => {
    return await api.post('v2/inkoop/opdracht/nieuw/toevoegen-opdracht', params);
  },
  wijzigenOpdracht: async (params: IWijzigenOpdrachtParams): Promise<IWijzigenOpdrachtResult> => {
    return await api.post('v2/inkoop/opdracht/nieuw/wijzigen-opdracht', params);
  },
};

export default nieuw;
