import React from 'react';
import IDialoogProps from '../../../core/IDialoogProps';
import Dialoog from '../../dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/esm/ModalHeader';
import { ModalTitle } from 'react-bootstrap';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import LoadingSpinner from '../../Gedeeld/LoadingSpinner';

interface IProps extends IDialoogProps {
  content: React.VoidFunctionComponent;
}

const TechnischeInformatieContentDialoog = (props: IProps) => {
  const Content = props.content;

  return (
    <Dialoog index={props.dialoogIndex ?? 0}>
      <ModalHeader>
        <ModalTitle>Technische informatie</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <Content />
      </ModalBody>
      <ModalFooter className="d-flex flex-row justify-content-start">
        <button className="btn btn-secondary" onClick={props.onAnnuleren} style={{ width: 100 }}>
          Annuleren
        </button>
      </ModalFooter>
    </Dialoog>
  );
};

export default TechnischeInformatieContentDialoog;
