import React from 'react';
import Portal from '../Portal';

interface IProps {}

const FillWindowWrapper: React.FC<IProps> = (props) => {
  return (
    <Portal>
      <div className="fill-window">{props.children}</div>
    </Portal>
  );
};

export default FillWindowWrapper;
