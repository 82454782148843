import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { IOphalenMeldingenResultElement } from '../../../../../../../../../../../shared/src/api/v2/service/melding';
import api from '../../../../../../../../../api';
import LoadingSpinner from '../../../../../../../../../components/Gedeeld/LoadingSpinner';
import {
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
  DXTableToggleCellComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../../../../../../helpers/dxTableGrid';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  TableRowDetail,
  VirtualTable,
  Table,
  TableEditColumn,
} from '@devexpress/dx-react-grid-bootstrap4';
import { DataTypeProvider, EditingState, RowDetailState } from '@devexpress/dx-react-grid';
import { format } from 'date-fns';
import _ from 'lodash';
import nameof from '../../../../../../../../../core/nameOf';
import { Kleur } from '../../../../../../../../../bedrijfslogica/constanten';
import { ISectieProps } from '..';
import { EResultType } from '../../../../../../../../../stores/CheckStore';
import { RootStoreContext } from '../../../../../../../../../stores/RootStore';
import WijzigenMeldingDialoog from '../../../../../../../../../components/service/WijzigenMeldingDialoog';
import RowDetailComp from './RowDetailComp';
import ServiceMeldingDialoog from '../../../../Service/Meldingen/NieuweMeldingDialoog';
import { IOphalenOpdrachtenResultElement } from '../../../../../../../../../../../shared/src/api/v2/service/opdracht';
import RelatieVisualisatie from '../../../../../../../../../components/personalia/RelatieVisualisatie';

interface IProps extends ISectieProps {}

// interface IRow extends IOphalenMeldingenResultElement {}

interface IWijzigenMeldingDialoogState {
  id: number;
}

interface IServicemeldingDialoogState {
  prodID: number;
}

const geenData = { noData: 'Geen servicemeldingen' };

const Service: React.FC<IProps> = (props) => {
  const { checkStore } = useContext(RootStoreContext);

  const [meldingen, setMeldingen] = useState<IOphalenMeldingenResultElement[] | null>(null);
  const [opdrachten, setOpdrachten] = useState<IOphalenOpdrachtenResultElement[] | null>(null);

  const [
    wijzigenMeldingDialoogState,
    setWijzigenMeldingDialoogState,
  ] = useState<IWijzigenMeldingDialoogState | null>(null);
  const [
    servicemeldingDialoogState,
    setServicemeldingDialoogState,
  ] = useState<IServicemeldingDialoogState | null>(null);

  const ophalenMeldingen = useCallback(async () => {
    const meldingenResult = await api.v2.service.ophalenMeldingen({
      filterSchema: {
        filters: [{ naam: 'BASISNUMMER', data: [props.contract.basis.Basisnummer] }],
      },
    });

    // Reparatiopdrachten ophalen
    const meldIDs = meldingenResult.meldingen.map((x) => x.ID);
    const opdrachtenResult = await api.v2.service.ophalenOpdrachten({
      filterSchema: {
        filters: [{ naam: 'SERVMELD_IDS', data: meldIDs }],
      },
    });
    setOpdrachten(opdrachtenResult.opdrachten);

    const meldingenGesorteerd = _.orderBy(meldingenResult.meldingen, ['Melddatum'], ['desc']);
    setMeldingen(meldingenGesorteerd);
  }, []);

  useEffect(() => {
    ophalenMeldingen();
  }, [ophalenMeldingen]);

  const keyExtractor = useCallback((x: IOphalenMeldingenResultElement) => x.ID, []);
  const kolommen = useMemo<TypedColumn<IOphalenMeldingenResultElement>[]>(
    () => [
      // {
      //   name: 'Meldnummer',
      //   title: 'Meldnr.',
      // },
      {
        name: 'Melddatum',
        title: 'Datum',
      },
      {
        name: '__contract',
        title: 'Cnt.',
      },
      // {
      //   name: 'Afgehandeld',
      //   title: 'Afg.',
      // },
      {
        name: '__product' as any,
        title: 'Product',
      },
      {
        name: '__referentienummer',
        title: 'Ref.nr',
      },
      {
        name: 'Omschrijving',
        title: 'Omschrijving',
      },
      {
        name: '__opdracht',
        title: 'Rep.opdr.',
      },
    ],
    [],
  );
  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IOphalenMeldingenResultElement>[]>(
    () => [
      // {
      //   columnName: 'Meldnummer',
      //   width: 90,
      // },
      {
        columnName: 'Melddatum',
        width: 100,
      },
      {
        columnName: '__product' as any,
        width: 200,
      },
      {
        columnName: '__referentienummer' as any,
        width: 100,
      },
      {
        columnName: 'Omschrijving',
        width: 325,
      },
      // {
      //   columnName: 'Afgehandeld',
      //   width: 70,
      // },
      {
        columnName: '__contract',
        width: 70,
      },
      {
        columnName: '__opdracht',
        width: 140,
      },
    ],
    [],
  );

  return (
    <>
      {meldingen === null || opdrachten === null ? (
        <LoadingSpinner />
      ) : (
        <GridStyleWrapper
          rowAmount={meldingen.length}
          maxHeight={300}
          // extraHeight={100 * props.uitgeklapteRegels.length}
        >
          <Grid rows={meldingen} columns={kolommen} getRowId={keyExtractor}>
            <DataTypeProvider
              for={[nameof<IOphalenMeldingenResultElement>('Melddatum')]}
              formatterComponent={(props) => {
                const rij: IOphalenMeldingenResultElement = props.row;
                return <span>{format(new Date(rij.Melddatum), 'dd-MM-yyyy')}</span>;
              }}
            />

            <DataTypeProvider
              for={['__contract']}
              formatterComponent={(props) => {
                const rij: IOphalenMeldingenResultElement = props.row;
                return (
                  <span>
                    {rij.contract !== null
                      ? rij.contract.Basisnummer + '.' + rij.contract.Volgnummer
                      : ''}
                  </span>
                );
              }}
            />

            <DataTypeProvider
              for={['__opdracht']}
              formatterComponent={(props) => {
                const rij: IOphalenMeldingenResultElement = props.row;
                const opdrachtenVoorMelding = opdrachten.filter((x) => x.melding.ID === rij.ID);
                const opdrachtenVoorMeldingGesorteerd: IOphalenOpdrachtenResultElement[] = _.orderBy(
                  opdrachtenVoorMelding,
                  ['DatumVerstuurd'],
                  ['asc'],
                );
                const opdracht =
                  opdrachtenVoorMeldingGesorteerd.length !== 0
                    ? opdrachtenVoorMeldingGesorteerd[0]
                    : null;
                return (
                  <span>
                    {opdracht !== null ? <RelatieVisualisatie relID={opdracht.dienst.RelID} /> : ''}
                  </span>
                );
              }}
            />

            <DataTypeProvider
              for={['__product']}
              formatterComponent={(props) => {
                const rij: IOphalenMeldingenResultElement = props.row;
                return (
                  <span>
                    {rij.product !== null
                      ? rij.product.producttype.Merknaam + ' ' + rij.product.producttype.Typenaam
                      : ''}
                  </span>
                );
              }}
            />

            <DataTypeProvider
              for={['__referentienummer']}
              formatterComponent={(props) => {
                const rij: IOphalenMeldingenResultElement = props.row;
                return <span>{rij.product !== null ? rij.product.Referentiecode : ''}</span>;
              }}
            />

            {/* <DataTypeProvider
              for={[nameof<IOphalenMeldingenResultElement>('Afgehandeld')]}
              formatterComponent={(props) => {
                const rij: IOphalenMeldingenResultElement = props.row;
                return (
                  <span style={{ color: rij.Afgehandeld ? Kleur.Groen : Kleur.Rood }}>
                    {rij.Afgehandeld ? 'Ja' : 'Nee'}
                  </span>
                );
              }}
            /> */}

            <VirtualTable messages={geenData} />
            <EditingState
              onAddedRowsChange={async () => {
                if (props.contract.producten.length === 0) {
                  await checkStore.melden({
                    titel: 'Er is geen product gekoppeld',
                  });
                  return;
                }
                if (props.contract.producten.length !== 1) {
                  await checkStore.melden({
                    titel:
                      'Er zijn meer dan 1 producten gekoppeld. Ga naar de sectie Product en kies maak daar een melding voor het gewenste product',
                  });
                  return;
                }
                return setServicemeldingDialoogState({
                  prodID: props.contract.producten[0].ProdID,
                });
              }}
              onEditingRowIdsChange={(x) => {
                const id = x[x.length - 1] as number;
                setWijzigenMeldingDialoogState({ id });
              }}
              onCommitChanges={async (changeset) => {
                if (changeset.deleted !== undefined && changeset.deleted.length > 0) {
                  const ids = changeset.deleted as number[];

                  const checkData = await api.v2.service.checkVerwijderenMeldingen({
                    IDs: ids,
                  });
                  const controleResult = await checkStore.controleren({
                    checkData,
                  });
                  if (controleResult.type === EResultType.Annuleren) {
                    return;
                  }

                  if (
                    (
                      await checkStore.bevestigen({
                        inhoud: 'Wil je de melding verwijderen?',
                      })
                    ).type === EResultType.Annuleren
                  ) {
                    return;
                  }

                  await api.v2.service.verwijderenMeldingen({
                    IDs: ids,
                  });

                  ophalenMeldingen();
                }
              }}
            />

            <TableEditColumn
              width={65}
              showAddCommand
              showEditCommand
              showDeleteCommand
              cellComponent={DXTableEditColumnCellComponent}
              commandComponent={DXTableEditColumnCommandComponent}
            />

            <RowDetailState defaultExpandedRowIds={[]} />

            <TableRowDetail
              contentComponent={RowDetailComp}
              toggleCellComponent={DXTableToggleCellComponent}
            />

            <TableColumnResizing defaultColumnWidths={kolomBreedtes as any} />
            <TableHeaderRow />
          </Grid>
        </GridStyleWrapper>
      )}

      {wijzigenMeldingDialoogState !== null && (
        <WijzigenMeldingDialoog
          open
          servMeldID={wijzigenMeldingDialoogState.id}
          onSuccess={async () => {
            setWijzigenMeldingDialoogState(null);
            ophalenMeldingen();
          }}
          onAnnuleren={() => {
            setWijzigenMeldingDialoogState(null);
          }}
        />
      )}

      {servicemeldingDialoogState !== null && (
        <ServiceMeldingDialoog
          open
          prodID={servicemeldingDialoogState.prodID}
          relID={props.relID}
          cntID={props.contract.CntID}
          onSuccess={async () => {
            ophalenMeldingen();
            props.vernieuwenContracten();
            setServicemeldingDialoogState(null);
          }}
          onAnnuleren={() => setServicemeldingDialoogState(null)}
        />
      )}
    </>
  );
};

export default Service;
