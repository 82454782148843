import React, { useMemo } from 'react';
import { Root } from './Opdrachten/style';
import { RouteComponentProps, withRouter } from 'react-router';
import Tabblad, { ITabblad } from '../../components/layout/Tabblad';
import Planning from './Opdrachten/Planning';
import Uitstaand from './Opdrachten/Uitstaand';
import Afgehandeld from './Opdrachten/Afgehandeld';
import Referenties from './Opdrachten/Referenties';
import Reserveringen from '../../components/voorraad/Reserveringen';
import Locaties from './Opdrachten/Locaties';
import {
  kleurMap,
  EOpdrachtStatus as ETransportOpdrachtStatus,
} from '../../components/kaart/entiteiten/Transport/OpdrachtStatus';

const opdrachtStatusNaarTransportOpdrachtStatus = (
  status: EOpdrachtStatus,
): ETransportOpdrachtStatus | null => {
  switch (status) {
    case EOpdrachtStatus.Planning:
      return ETransportOpdrachtStatus.Planning;
    case EOpdrachtStatus.Uitstaand:
      return ETransportOpdrachtStatus.Uitstaand;
    case EOpdrachtStatus.Afgehandeld:
      return ETransportOpdrachtStatus.Afgehandeld;
    case EOpdrachtStatus.Referenties:
      return null;
    case EOpdrachtStatus.Reserveringen:
      return null;
    case EOpdrachtStatus.Locaties:
      return null;
  }
};

export enum EOpdrachtStatus {
  Planning = 1,
  Uitstaand = 2,
  Afgehandeld = 3,
  Referenties = 4,
  Reserveringen = 5,
  Locaties = 6,
}

// const kleurMap: { [status: string]: string } = {
//   [EOpdrachtStatus.Planning]: EKleur.LichtBlauw,
//   [EOpdrachtStatus.Uitstaand]: EKleur.Oranje,
//   [EOpdrachtStatus.Afgehandeld]: EKleur.Grijs,
// };

const pathMap: Record<EOpdrachtStatus, string> = {
  [EOpdrachtStatus.Planning]: '/planning',
  [EOpdrachtStatus.Uitstaand]: '/uitstaand',
  [EOpdrachtStatus.Afgehandeld]: '/afgehandeld',
  [EOpdrachtStatus.Referenties]: '/referenties',
  [EOpdrachtStatus.Reserveringen]: '/reserveringen',
  [EOpdrachtStatus.Locaties]: '/signaleringen',
};

interface IProps extends RouteComponentProps {}

const TabTitel = (props: { titel: string; kleur?: string }) => {
  return (
    <div className="d-flex align-items-center">
      {props.kleur && (
        <div
          className="mr-2"
          style={{
            width: 12,
            height: 12,
            borderRadius: '50%',
            backgroundColor: props.kleur,
            marginTop: 2,
            display: 'inline-block',
          }}
        />
      )}
      <div>{props.titel}</div>
    </div>
  );
};

const Opdrachten: React.FC<IProps> = (props) => {
  const tabblad = useMemo<EOpdrachtStatus | null>(() => {
    const matchKey = Object.keys(pathMap).find((key) => {
      const path = pathMap[Number(key) as EOpdrachtStatus]!;
      return props.location.pathname.endsWith(path);
    });
    if (matchKey === undefined) {
      props.history.push(`${props.match.path}${pathMap[EOpdrachtStatus.Planning]}`);
      return null;
    }
    return Number(matchKey) as EOpdrachtStatus;
  }, [props.location.pathname]);

  //   <div className="justify-content-center">
  //   <div
  //     className="justify-content-center"
  //     style={{
  //       width: 12,
  //       height: 12,
  //       borderRadius: '50%',
  //       backgroundColor: kleurMap[EOpdrachtStatus.Planning],
  //     }}
  //   />
  // </div>

  const tabbladen = useMemo<ITabblad<EOpdrachtStatus>[]>(
    () => [
      {
        id: EOpdrachtStatus.Reserveringen,
        label: 'Reserveringen',
        content: Reserveringen,
      },
      {
        id: EOpdrachtStatus.Planning,
        label: (
          <TabTitel
            titel={'Planning'}
            kleur={kleurMap[opdrachtStatusNaarTransportOpdrachtStatus(EOpdrachtStatus.Planning)!]}
          />
        ),
        content: Planning,
      },
      {
        id: EOpdrachtStatus.Uitstaand,
        label: (
          <TabTitel
            titel={'Uitstaand'}
            kleur={kleurMap[opdrachtStatusNaarTransportOpdrachtStatus(EOpdrachtStatus.Uitstaand)!]}
          />
        ),
        content: Uitstaand,
      },
      {
        id: EOpdrachtStatus.Afgehandeld,
        label: (
          <TabTitel
            titel={'Afgehandeld'}
            kleur={
              kleurMap[opdrachtStatusNaarTransportOpdrachtStatus(EOpdrachtStatus.Afgehandeld)!]
            }
          />
        ),
        content: Afgehandeld,
      },
      {
        id: EOpdrachtStatus.Referenties,
        label: 'Ontbrekende referenties',
        content: Referenties,
      },
      {
        id: EOpdrachtStatus.Locaties,
        label: 'Per locatie',
        content: Locaties,
      },
    ],
    [],
  );

  return (
    <Root>
      {tabblad !== null && (
        <Tabblad<EOpdrachtStatus>
          geselecteerd={tabblad}
          onSelectieChange={(tab) => {
            props.history.push(`${props.match.path}${pathMap[tab]}`);
          }}
          tabbladen={tabbladen}
        />
      )}
    </Root>
  );
};

export default withRouter(Opdrachten);
