import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  DXTableCheckboxComponent,
  DXTableToggleCellComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  DataTypeProvider,
  IntegratedSorting,
  RowDetailState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import { IOphalenVoorraadNieuwResultElement } from '../../../../../../shared/src/api/v2/inkoop/voorraad';
import VoorraadTypeInfoDialoog from '../../../../components/transport/VoorraadTypeInfoDialoog';
import { IconInformatie } from '../../../../components/Icons';
import { Kleur as EKleur } from '../../../../bedrijfslogica/constanten';
import { IOphalenProductmodellenResultElement } from '../../../../../../shared/src/api/v2/aanbod/productmodel';
import { IFilterSchemaFilter } from '../../../../../../shared/src/models/filter';
import nameOf from '../../../../core/nameOf';

const geenData = {
  noData: 'Geen voorraad gevonden voor de selectie',
};

interface IProps {
  rows: IOphalenVoorraadNieuwResultElement[];
  productmodellen: IOphalenProductmodellenResultElement[];
  selectie: number[];
  onSelectieChange: (selectie: number[]) => void;
  onNieuweInkoopopdracht: (typeID: number) => void;
}

export interface IVoorraadTypeInfoDialoogState {
  typeID: number;
  magID: number;
}

interface IWeergaveModellenProps {
  productmodellen: IOphalenProductmodellenResultElement[];
}

// Weergave van de modelcodes op een regel
const ModelcodesWeergave: React.FC<IWeergaveModellenProps> = (props) => {
  const info = props.productmodellen
    .map((x) => {
      return x.Modelcode;
    })
    .join(', ');
  const modellen = <span>{info}</span>;
  return modellen;
};

const TypeTabel: React.FC<IProps> = (props) => {
  const [
    voorraadTypeInfoDialoogState,
    setVoorraadTypeInfoDialoogState,
  ] = useState<IVoorraadTypeInfoDialoogState | null>(null);

  const keyExtractor = useCallback((row: IOphalenVoorraadNieuwResultElement) => row.TypeID, []);
  const kolommen = useMemo<TypedColumn<IOphalenVoorraadNieuwResultElement>[]>(
    () => [
      {
        name: 'Merknaam',
        title: 'Merk',
      },
      {
        name: 'Typenaam',
        title: 'Type',
      },
      {
        name: '__productsoortnaamKort' as any,
        title: 'Soort',
      },
      {
        name: 'Kenmerk',
        title: 'Kenmerk',
      },
      {
        name: '__voorraadInfo' as any,
        title: 'Vrd.info',
      },
      {
        name: '__inkoopActie' as any,
        title: ' ',
      },
      {
        name: 'AantalVoorraad',
        title: 'Vrd.',
      },
      {
        name: '__aantalGereserveerdPlanning' as any,
        title: 'Res. Plan.',
      },
      {
        name: '__aantalGereserveerdUitstaand' as any,
        title: 'Res. Uitst.',
      },
      {
        name: '__aantalVrij' as any,
        title: 'Vrij',
        getCellValue: (x) => x.voorraad.aantalVrij,
      },
      {
        name: '__aantalNogTeLeveren' as any,
        title: 'In best.',
        getCellValue: (x) => (x.voorraad.aantalInkoop !== 0 ? x.voorraad.aantalInkoop : ''),
      },
      {
        name: '__gekoppeldeProductmodellen' as any,
        title: 'Productmodellen',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IOphalenVoorraadNieuwResultElement>[]>(
    () => [
      {
        columnName: 'Typenaam',
        width: 200,
      },
      {
        columnName: 'Merknaam',
        width: 125,
      },
      {
        columnName: '__voorraadInfo' as any,
        width: 95,
      },
      {
        columnName: '__productsoortnaamKort' as any,
        width: 85,
      },
      {
        columnName: 'Kenmerk',
        width: 200,
      },
      {
        columnName: 'AantalVoorraad',
        width: 85,
      },
      {
        columnName: '__aantalGereserveerdPlanning' as any,
        width: 100,
      },
      {
        columnName: '__aantalGereserveerdUitstaand' as any,
        width: 105,
      },
      {
        columnName: '__aantalVrij' as any,
        width: 100,
      },
      {
        columnName: '__aantalNogTeLeveren' as any,
        width: 110,
      },
      {
        columnName: '__inkoopActie' as any,
        width: 125,
      },
      {
        columnName: '__gekoppeldeProductmodellen' as any,
        width: 250,
      },
    ],
    [],
  );

  return (
    <>
      <GridStyleWrapper height="calc(100vh - 150px)">
        <Grid columns={kolommen} getRowId={keyExtractor} rows={props.rows}>
          <DataTypeProvider
            for={['__productsoortnaamKort']}
            formatterComponent={(formatterProps) => {
              const rij: IOphalenVoorraadNieuwResultElement = formatterProps.row;

              return (
                <span style={{ color: !rij.productsoort.Actief ? EKleur.Rood : undefined }}>
                  {rij.productsoort.NaamKort}
                </span>
              );
            }}
          />

          <DataTypeProvider
            for={['__gekoppeldeProductmodellen']}
            formatterComponent={(formatterProps) => {
              const rij: IOphalenVoorraadNieuwResultElement = formatterProps.row;

              const modellen = props.productmodellen
                .filter((x) => x.Actief)
                .filter(
                  (x) =>
                    rij.gekoppeldeProductmodellen.map((x) => x.ProdModID).indexOf(x.ProdModID) !==
                    -1,
                );

              return <ModelcodesWeergave productmodellen={modellen} />;
            }}
          />

          <DataTypeProvider
            for={['__aantalNogTeLeveren']}
            formatterComponent={(formatterProps) => {
              const voorraad = formatterProps.row.voorraad;
              const aantal = voorraad.aantalInkoop; //- voorraad.aantalGeleverd;
              return (
                <>
                  <span>{aantal !== 0 ? aantal : ''}</span>
                </>
              );
            }}
          />

          <DataTypeProvider
            for={['__aantalGereserveerdPlanning']}
            formatterComponent={(formatterProps) => {
              const voorraad = formatterProps.row.voorraad;
              return (
                <>
                  <span>
                    {voorraad.aantalPlanningGereserveerd !== 0
                      ? voorraad.aantalPlanningGereserveerd
                      : ''}
                  </span>
                </>
              );
            }}
          />

          <DataTypeProvider
            for={['__aantalGereserveerdUitstaand']}
            formatterComponent={(formatterProps) => {
              const voorraad = formatterProps.row.voorraad;
              return (
                <>
                  <span>
                    {voorraad.aantalUitstaandGereserveerd !== 0
                      ? voorraad.aantalUitstaandGereserveerd
                      : ''}
                  </span>
                </>
              );
            }}
          />

          <DataTypeProvider
            for={['__aantalVrij']}
            formatterComponent={(formatterProps) => {
              const voorraad = formatterProps.row.voorraad;
              return (
                <>
                  {/* <span>{voorraad.aantalVrij !== 0 ? voorraad.aantalVrij : ''}</span> */}
                  <span>{voorraad.aantalVrij}</span>
                </>
              );
            }}
          />

          <DataTypeProvider
            for={['__inkoopActie']}
            formatterComponent={(formatterProps) => {
              const rij: IOphalenVoorraadNieuwResultElement = formatterProps.row;

              if (!rij.InkoopToegestaan) {
                return <span></span>;
              }

              return (
                <a
                  href="#"
                  onClick={() => {
                    props.onNieuweInkoopopdracht(rij.TypeID);
                  }}
                >
                  Ink.opd.
                </a>
              );
            }}
          />

          <DataTypeProvider
            for={['__voorraadInfo']}
            formatterComponent={(formatterProps) => {
              const rij: IOphalenVoorraadNieuwResultElement = formatterProps.row;

              return (
                <div className="d-flex">
                  <a
                    href="#"
                    onClick={() => {
                      setVoorraadTypeInfoDialoogState({
                        typeID: rij.TypeID,
                        magID: 4, //rij.magazijn.MagID,
                      });
                    }}
                  >
                    {/* <VoorraadIndicatie
                            status={gereserveerd ? EVoorraadStatus.Groen : EVoorraadStatus.Rood}
                          /> */}
                    <IconInformatie style={{ width: 15, height: 15, fill: EKleur.Blauw }} />
                  </a>
                </div>
              );
            }}
          />

          <SortingState defaultSorting={[]} />
          <IntegratedSorting />

          <VirtualTable messages={geenData} />
          <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
          <RowDetailState defaultExpandedRowIds={[]} />
          <TableHeaderRow showSortingControls />
          <TableRowDetail toggleCellComponent={DXTableToggleCellComponent} />
          <SelectionState
            selection={props.selectie}
            onSelectionChange={(x) => props.onSelectieChange(x as number[])}
          />
          <TableSelection cellComponent={DXTableCheckboxComponent} />
        </Grid>
      </GridStyleWrapper>
      {voorraadTypeInfoDialoogState !== null && (
        <VoorraadTypeInfoDialoog
          open
          typeID={voorraadTypeInfoDialoogState.typeID}
          magID={voorraadTypeInfoDialoogState.magID}
          onSuccess={() => setVoorraadTypeInfoDialoogState(null)}
          onAnnuleren={() => setVoorraadTypeInfoDialoogState(null)}
        />
      )}
    </>
  );
};

export default TypeTabel;
