import { EReferentieNaamEnum } from './enums';

export const isBestandEnRelatieReferentie = (referentie: EReferentieNaamEnum) => {
  return (
    referentie === EReferentieNaamEnum.RelatieBestand ||
    referentie === EReferentieNaamEnum.RelatieDocument ||
    referentie === EReferentieNaamEnum.PotentieelOntvangenItemWerkbon
  );
};

export const referentieNaamEnumNaarLeesbaarMap: Record<EReferentieNaamEnum, string> = {
  BANK_BATCH: 'Bank batch',
  BESTAND_BESTANDSLABEL: 'Bestandslabel',
  EMAIL_BERICHT_BESTAND: 'Email',
  NAV_HULP_ITEM_DOWNLOAD: 'Website Navitem',
  POTENTIEEL_BESTAND_INKOOP_FACTUUR: 'Pot. inkoopfactuur',
  POTENTIEEL_ONTVANGEN_ITEM_INKOOP_FACTUUR: 'Pot. inkoopfactuur item',
  POTENTIEEL_ONTVANGEN_ITEM_WERKBON: 'Pot. werkbon item',
  PRODUCTMODEL_AFBEELDING: 'Productmodel',
  RELATIE_BESTAND: 'Relatiebestand',
  RELATIE_DOCUMENT: 'Relatiedocument',
  SERVICE_MELDING_AFBEELDING: 'Servicemelding',
  TRANSPORTOPDRACHT_BESTAND: 'Transportopdracht',
  WHATSAPP_V2_CHATSESSIE: 'Whatsapp sessie',
  WHATSAPP_V2_MEDIA: 'Whatsapp bericht',
  WEB_AFBEELDING: 'Websiteafbeelding',
  BLOG_ITEM: 'Blog',
  BANK_DIENST: 'Bankdienst',
  BEDRIJF: 'Bedrijf',
  FACTUUR: 'Factuur',
  EMAILBERBEST_V2: 'Email bijlage',
  ALGEMENE_VOORWAARDEN: 'Algemene voorwaarden',
  SPONSOR_CONTRACT_BIJLAGE: 'Sponsorcontract bijlage',
  WINWEEK_WINNAAR: 'Winweek winnaard',
};
