import * as React from 'react';
import { useMemo, useState } from 'react';
import { Kolom } from '../';
import { ISponsorEvent } from '../../../../../../shared/src/api/v2/sponsoringv2/event';
import { IUitgeklapteRijProps } from '../../../../components/tabel/ASPTabel/Body/UitgeklapteRij';
import Tabblad, { ITabblad } from '../../../../components/layout/Tabblad';
import { observer } from 'mobx-react-lite';
import { ISponsorContract } from '../../../../../../shared/src/api/v2/sponsoringv2/contract';
import GekoppeldeEvents from './GekoppeldeEvents';
import GekoppeldeBijlagen from './GekoppeldeBijlagen';
import TabelInspringBlok from '../../../../components/layout/TabelInspringBlok';

export enum ETabblad {
  Bijlagen = 1,
  Events = 2,
}

interface IProps extends IUitgeklapteRijProps<Kolom, ISponsorContract> {}

const UitgeklapteRij: React.FC<IProps> = (props: IProps) => {
  const [tabblad, setTabblad] = useState<ETabblad>(ETabblad.Bijlagen);

  const tabbladen = useMemo<ITabblad<ETabblad>[]>(
    () => [
      {
        id: ETabblad.Bijlagen,
        label: 'Bijlagen',
        content: BijlagenTab,
      },
      {
        id: ETabblad.Events,
        label: 'Events',
        content: EventsTab,
      },
    ],
    [tabblad],
  );

  const row = props.regel;
  const detail = useMemo(() => {
    return (
      <>
        <div className="d-flex flex-fill">
          <TabelInspringBlok></TabelInspringBlok>
          <Tabblad
            geselecteerd={tabblad}
            onSelectieChange={(id) => {
              setTabblad(id);
            }}
            tabbladen={tabbladen}
            options={{
              tabbladComponentProps: props,
            }}
          />
        </div>
      </>
    );
  }, [row, tabblad]);

  return <>{detail}</>;
};

const EventsTab: React.FC<IProps> = observer((props) => {
  return <GekoppeldeEvents koppelBasisID={props.regel.ID!}></GekoppeldeEvents>;
});

const BijlagenTab: React.FC<IProps> = observer((props) => {
  return <GekoppeldeBijlagen koppelBasisID={props.regel.ID!}></GekoppeldeBijlagen>;
});

export default UitgeklapteRij;
