import React, { useMemo } from 'react';
import { IOphalenTaakEventsResultTaakEventElement } from '../../../../../../../shared/src/api/v2/taken';
import { TaakEventType, typeTekst } from '../index';
import { Kleur } from '../../../../../bedrijfslogica/constanten';
import { format } from 'date-fns';
import UitlegTooltip from '../../../../../components/formulier/UitlegTooltip';

interface IProps {
  event: IOphalenTaakEventsResultTaakEventElement;
  height: number;
  metTaakNaam?: string;
}

const EventRegel: React.FC<IProps> = (props) => {
  const eventType: TaakEventType = props.event.EventType;

  const indicatieKleur = useMemo(() => {
    switch (eventType) {
      case TaakEventType.Gestart:
        return Kleur.Blauw;
      case TaakEventType.Gestopt:
        return Kleur.Groen;
      case TaakEventType.Error:
        return Kleur.Rood;
      case TaakEventType.Log:
        return Kleur.Wit;
      case TaakEventType.Geannuleerd:
        return Kleur.Geel;
    }
  }, [eventType]);

  return (
    <div
      style={{
        backgroundColor: Kleur.Wit,
      }}
      className="d-flex"
    >
      <div
        style={{
          height: props.height,
          minWidth: 10,
          maxWidth: 10,
          backgroundColor: indicatieKleur,
        }}
      />
      <div className="p-2 pl-3 pr-3 d-flex flex-fill">
        <div className="d-flex flex-fill align-items-start">
          <span style={{ fontSize: 12, fontWeight: 'bold', minWidth: 90, maxWidth: 90 }}>
            {typeTekst[eventType]}
          </span>
          {props.metTaakNaam && (
            <span style={{ fontSize: 12, fontWeight: 'bold', minWidth: 150, maxWidth: 150 }}>
              {props.metTaakNaam}
            </span>
          )}
          {props.event.Data !== null && (
            <UitlegTooltip
              inhoud={props.event.Data}
              innerContainerProps={{
                style: {
                  flex: 1,
                  display: 'flex',
                },
              }}
            >
              <div
                className="ml-2"
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  width: 0,
                  flex: '1 1 auto',
                }}
              >
                {props.event.Data}
              </div>
            </UitlegTooltip>
          )}
        </div>

        <span style={{ fontSize: 12, minWidth: 135, maxWidth: 135 }} className="ml-2">
          {format(new Date(props.event.RecordToegevoegd), 'dd-MM-yyyy HH:mm:ss')}
        </span>
      </div>
    </div>
  );
};

export default EventRegel;
