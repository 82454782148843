import { IFilter, IFilterData, IWeergaveProps } from '../../../components/FilterBalkV2';
import MaandSelectie, {
  bepaalHuidigeMaand,
  EWeergave,
} from '../../../components/formulier/MaandSelectie';
import JaarSelectie, { bepaalHuidigJaar } from '../../../components/formulier/JaarSelectie';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import LoadingSpinner from '../../../components/Gedeeld/LoadingSpinner';
import {
  IOphalenDagboekenResultElement,
  IOphalenDagboekSoortenResultElement,
} from '../../../../../shared/src/api/v2/boekhouding/boeking/dagboek';
import api from '../../../api';
import _ from 'lodash';
import Combobox from '../../../components/formulier/Combobox';
import { EDagboeksoort } from '../../../bedrijfslogica/enums';
import { dagDatum } from '../../../helpers/datum';
import DatumKiezer from '../../../components/formulier/DatumKiezer';
import { addDays } from 'date-fns';

export enum EFilter {
  Dagboeken = 'DAGBOEK_IDS',
  Boekmaand = 'BOEKMAAND',
  Boekjaar = 'BOEKJAAR',
  Boekdatum = 'BOEKDATUM',
  HeeftIncassoBatchBoeking = 'HEEFT_INCASSO_BATCH_BOEKING',
  NietDefinitief = 'BOEKING_IS_DEFINITIEF',
}

export interface IUseBasisJournaalpostregelsFiltersParams {
  dagboeksoort: EDagboeksoort;
}

export interface IUseBasisJournaalpostregelsFiltersOutput {
  defaultFilterData: IFilterData<EFilter>[] | null;
  boekingregelFilters: IFilter<EFilter>[];
  dagboeksoort: IOphalenDagboekSoortenResultElement | null;
}

const useBasisBoekingregelsFilters = (
  params: IUseBasisJournaalpostregelsFiltersParams,
): IUseBasisJournaalpostregelsFiltersOutput => {
  const [dagboeken, setDagboeken] = useState<IOphalenDagboekenResultElement[] | null>(null);

  const ophalenDagboeken = useCallback(async () => {
    const dagboekenResult = await api.v2.boeking.dagboek.ophalenDagboeken({
      filterSchema: {
        filters: [{ naam: 'DAGBOEKSOORT_NAAM_ENUMS', data: [params.dagboeksoort] }],
      },
    });

    const dagboekenGesorteerd = _.orderBy(dagboekenResult.dagboeken, ['Nummer'], ['asc']);

    setDagboeken(dagboekenGesorteerd);
  }, []);

  useEffect(() => {
    ophalenDagboeken();
  }, [ophalenDagboeken]);

  const [dagboeksoort, setDagboeksoort] = useState<IOphalenDagboekSoortenResultElement | null>(
    null,
  );
  const ophalenDagboeksoort = useCallback(async () => {
    const result = await api.v2.boeking.dagboek.ophalenDagboekSoorten({
      filterSchema: {
        filters: [
          {
            naam: 'NAAM_ENUMS',
            data: [params.dagboeksoort],
          },
        ],
      },
    });
    const soort = result.soorten[0];
    setDagboeksoort(soort);
  }, []);
  useEffect(() => {
    ophalenDagboeksoort();
  }, [ophalenDagboeksoort]);

  const defaultFilterData = useMemo(
    () =>
      dagboeken === null
        ? null
        : ([
            {
              naam: EFilter.Dagboeken,
              data: [dagboeken[0].ID],
              isActief: true,
            },
            {
              naam: EFilter.Boekmaand,
              data: bepaalHuidigeMaand(),
              isActief: true,
            },
            {
              naam: EFilter.Boekjaar,
              data: bepaalHuidigJaar(),
              isActief: true,
            },
            {
              naam: EFilter.Boekdatum,
              data: dagDatum(new Date()).toISOString(),
              isActief: false,
            },
            {
              naam: EFilter.NietDefinitief,
              data: false,
              isActief: false,
            },
            params.dagboeksoort !== EDagboeksoort.BANK
              ? null
              : {
                  naam: EFilter.HeeftIncassoBatchBoeking,
                  data: false,
                  isActief: false,
                },
          ].filter((x) => x !== null) as IFilterData<EFilter>[]),
    [dagboeken],
  );

  const filters = useMemo<IFilter<EFilter>[]>(
    () =>
      [
        {
          naam: EFilter.Dagboeken,
          altijdWeergevenInBalk: true,
          weergave: (weergaveProps: IWeergaveProps<EFilter>) => {
            const data: number[] = weergaveProps.data;
            return (
              <div className="d-flex align-items-center">
                Dagboek
                <span className="ml-2">
                  {dagboeken === null ? (
                    <LoadingSpinner />
                  ) : (
                    <Combobox
                      geselecteerd={data.length === 0 ? null : data[0]}
                      onSelectieChange={(x) => {
                        const newData = x === null ? [] : [x];

                        weergaveProps.onDataChange(newData);
                        weergaveProps.toepassen();
                      }}
                      opties={dagboeken.map((x) => ({
                        id: x.ID,
                        label: x.Naam,
                      }))}
                    />
                    // <MultiSelect
                    //   value={weergaveProps.data}
                    //   onChange={(x) => {
                    //     weergaveProps.onDataChange(x);
                    //     weergaveProps.toepassen();
                    //   }}
                    //   opties={dagboeken.map((x) => ({
                    //     key: x.ID,
                    //     weergave: x.Naam,
                    //   }))}
                    // />
                  )}
                </span>
              </div>
            );
          },
        },
        {
          naam: EFilter.Boekmaand,
          altijdWeergevenInBalk: true,
          weergave: (weergaveProps: IWeergaveProps<EFilter>) => (
            <div className="d-flex align-items-center">
              Boekmaand
              <span className="ml-2">
                <MaandSelectie
                  maand={weergaveProps.data}
                  onMaandChange={(x) => {
                    weergaveProps.onDataChange(x);
                    weergaveProps.setFilterIsActief(EFilter.Boekdatum, false);
                    weergaveProps.toepassen();
                  }}
                  weergave={EWeergave.Getal}
                />
              </span>
            </div>
          ),
          onActiefGemuteerd: (weergaveProps: IWeergaveProps<EFilter>) => {
            if (weergaveProps.isActief) {
              weergaveProps.setFilterIsActief(EFilter.Boekdatum, false);
            }
          },
        },
        {
          naam: EFilter.Boekjaar,
          altijdWeergevenInBalk: true,
          weergave: (weergaveProps: IWeergaveProps<EFilter>) => (
            <div className="d-flex align-items-center">
              Boekjaar
              <span className="ml-2">
                <JaarSelectie
                  jaar={weergaveProps.data}
                  onJaarChange={(x) => {
                    weergaveProps.onDataChange(x);
                    weergaveProps.setFilterIsActief(EFilter.Boekdatum, false);
                    weergaveProps.toepassen();
                  }}
                  max={bepaalHuidigJaar() + 1}
                  min={bepaalHuidigJaar() - 10}
                />
              </span>
            </div>
          ),
          onActiefGemuteerd: (weergaveProps: IWeergaveProps<EFilter>) => {
            if (weergaveProps.isActief) {
              weergaveProps.setFilterIsActief(EFilter.Boekdatum, false);
            }
          },
        },
        {
          naam: EFilter.Boekdatum,
          altijdWeergevenInBalk: true,
          weergave: (weergaveProps: IWeergaveProps<EFilter>) => (
            <div className="d-flex align-items-center">
              Boekdatum
              <span className="ml-2">
                <DatumKiezer
                  waarde={new Date(weergaveProps.data)}
                  onGewijzigd={(x) => {
                    weergaveProps.onDataChange(x?.toISOString() ?? null);
                    weergaveProps.setFilterIsActief(EFilter.Boekmaand, false);
                    weergaveProps.setFilterIsActief(EFilter.Boekjaar, false);
                    weergaveProps.toepassen();
                  }}
                  determineValidDate={() => true}
                  determineNextValidDate={(date) => addDays(date, 1)}
                  determinePreviousValidDate={(date) => addDays(date, -1)}
                />
              </span>
            </div>
          ),
          onActiefGemuteerd: (weergaveProps: IWeergaveProps<EFilter>) => {
            if (weergaveProps.isActief) {
              weergaveProps.setFilterIsActief(EFilter.Boekmaand, false);
              weergaveProps.setFilterIsActief(EFilter.Boekjaar, false);
            }
          },
        },
        {
          naam: EFilter.NietDefinitief,
          altijdWeergevenInBalk: true,
          weergave: (weergaveProps: IWeergaveProps<EFilter>) => (
            <div className="d-flex align-items-center">Alleen niet-definitief</div>
          ),
        },
        params.dagboeksoort !== EDagboeksoort.BANK
          ? null
          : {
              naam: EFilter.HeeftIncassoBatchBoeking,
              altijdWeergevenInBalk: true,
              weergave: (weergaveProps: IWeergaveProps<EFilter>) => (
                <div className="d-flex align-items-center">
                  Geen boekingen voor een incasso batch
                </div>
              ),
            },
      ].filter((x) => x !== null) as IFilter<EFilter>[],
    [dagboeken, params.dagboeksoort],
  );

  return {
    defaultFilterData,
    boekingregelFilters: filters,
    dagboeksoort,
  };
};

export default useBasisBoekingregelsFilters;
