import React, { useCallback, useEffect, useMemo, useState, useContext } from 'react';
import { RouteComponentProps } from 'react-router';
import { Kleur, mutatiebronIconMap } from '../../../bedrijfslogica/constanten';
import { IconKruis, IconToevoegen, IconVerwijderen } from '../../../components/Icons';
import api from '../../../api';
import {
  Column,
  DataTypeProvider,
  IntegratedSorting,
  RowDetailState,
  SelectionState,
  SortingState,
  TableColumnWidthInfo,
  EditingState,
} from '@devexpress/dx-react-grid';
import {
  DXTableCheckboxComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
  DXTableToggleCellComponent,
  DXCommandComponent,
} from '../../../helpers/dxTableGrid';
import {
  Grid,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  TableSelection,
  VirtualTable,
  TableRowDetail,
} from '@devexpress/dx-react-grid-bootstrap4';
import LoadingSpinner from '../../../components/Gedeeld/LoadingSpinner';
import useUrlState, { genereerUrlStateQueryParam } from '../../../core/useUrlState';
import { IOphalenMeldingenResultElement } from '../../../../../shared/src/api/v2/service/melding';
import { IOphalenOpdrachtenResultElement } from '../../../../../shared/src/api/v2/service/opdracht';

import nameof from '../../../core/nameOf';
import { addDays, format } from 'date-fns';
import PersoonVisualisatie from '../../../components/personalia/PersoonVisualisatie';
import RowDetailComp from './RowDetailComp';
import { RootStoreContext } from '../../../stores/RootStore';
import { EResultType } from '../../../stores/CheckStore';
import WijzigenMeldingDialoog from '../../../components/service/WijzigenMeldingDialoog';
import RelatieSelectieDialoog, {
  EHoedanigheid,
} from '../../../components/personalia/RelatieSelectieDialoog';
import RelatieVisualisatie from '../../../components/personalia/RelatieVisualisatie';
import _ from 'lodash';
import MeldingstatusIndicatie, {
  EMeldingstatus,
} from '../../../components/service/MeldingstatusIndicatie';
import ContractVisualisatie from '../../../components/entiteitVisualisaties/ContractVisualisatie';
import Melding, {
  IUrlState as IServicemeldingUrlState,
  defaultUrlState as servicemeldingDefaultUrlState,
} from '../../Klant/Klantkaart/Entiteiten/entiteiten/Service/Meldingen';
import IPaginatiePositie from '../../../../../shared/src/models/IPaginatiePositie';
import ServicemeldingVisualisatie from '../../../components/entiteitVisualisaties/ServicemeldingVisualisatie';
import { IOphalenGebruikersResultElement } from '../../../../../shared/src/api/v2/gebruiker';
import ProductSelectieDialoog from '../../../components/product/ProductSelectieDialoog';
import { ETekstPrecisie } from '../../../components/formulier/TekstPrecisieSelectie';
import { Helmet } from 'react-helmet';
import { EMutatiebron } from '../../../bedrijfslogica/enums';

export interface IMeldingenContext {
  onVerversenAangevraagd: () => Promise<void>;
}

export const MeldingenContext = React.createContext<IMeldingenContext>({} as any);

interface IProductselectieDialoogState {
  servMeldID: number;
  referentiecode?: string;
  postcode?: string;
}

export interface IUrlState {
  selectie: number[];
  wijzigenID: number | null;
  productselectieDialoogState: IProductselectieDialoogState | null;
}

const defaultUrlState: IUrlState = {
  selectie: [],
  wijzigenID: null,
  productselectieDialoogState: null,
};

export interface IRow extends IOphalenMeldingenResultElement {
  opdrachten: IOphalenOpdrachtenResultElement[];
  aspGebruiker: IOphalenGebruikersResultElement | null;
}

interface IProps extends RouteComponentProps {}

const Meldingen: React.FC<IProps> = (props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState<IUrlState>(props, defaultUrlState);
  const [meldingen, setMeldingen] = useState<IOphalenMeldingenResultElement[] | null>(null);
  const { checkStore } = useContext(RootStoreContext);
  const [wijzigenID, setWijzigenID] = useState<number | null>(null);
  const [koppelenRelatieAanMelding, setKoppelenRelatieAanMelding] = useState<number | null>(null);
  const [aspGebruikers, setAspGebruikers] = useState<IOphalenGebruikersResultElement[] | null>(
    null,
  );

  // const [filterSchema, setFilterSchema] = useState<IFilterSchema>(
  //   maakFilterSchema(urlState.filterData),
  // );

  const ophalenMeldingen = useCallback(async () => {
    const datum = addDays(new Date(), -60).toISOString();

    const paginatie: IPaginatiePositie = {
      index: 0,
      aantal: 200,
    };

    const meldingenResult = await api.v2.service.ophalenMeldingen({
      filterSchema: { filters: [{ naam: 'MELDDATUM_VANAF', data: datum }] },
      paginatie,
    });
    const servMeldIDs = meldingenResult.meldingen.map((x) => x.ID);
    const aspGebrIDs = _.uniq(meldingenResult.meldingen.map((x) => x.AspGebrID));

    const opdrachtenResult = await api.v2.service.ophalenOpdrachten({
      filterSchema: { filters: [{ naam: 'SERVMELD_IDS', data: servMeldIDs }] },
    });

    const gebruikersResult = (
      await api.v2.aspGebruiker.ophalenGebruikers({
        filterSchema: { filters: [{ naam: 'ASP_GEBR_IDS', data: aspGebrIDs }] },
      })
    ).gebruikers;

    const meldingen = meldingenResult.meldingen.map((melding) => {
      const opdrachten = opdrachtenResult.opdrachten.filter((x) => x.melding.ID === melding.ID);
      const aspGebruiker =
        melding.AspGebrID !== null
          ? gebruikersResult.find((x) => x.AspGebrID === melding.AspGebrID) ?? null
          : null;
      return { ...melding, opdrachten, aspGebruiker };
    });

    const meldingenGesorteerd = _.orderBy(meldingen, ['Melddatum'], ['desc']);

    setMeldingen(meldingenGesorteerd);
  }, []);

  useEffect(() => {
    ophalenMeldingen();
  }, [ophalenMeldingen]);

  const handleVerwijderen = useCallback(async () => {
    const checkData = await api.v2.service.checkVerwijderenMeldingen({
      IDs: urlState.selectie,
    });
    if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
      return;
    }
    if (
      (
        await checkStore.bevestigen({
          inhoud: `Wil je de ${urlState.selectie.length} meldingen verwijderen?`,
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    await api.v2.service.verwijderenMeldingen({
      IDs: urlState.selectie,
    });

    setUrlStateSync('selectie', []);

    ophalenMeldingen();
  }, [urlState.selectie]);

  const keyExtractor = useCallback((row: IOphalenMeldingenResultElement) => row.ID, []);

  const kolommen = useMemo<TypedColumn<IRow>[]>(() => {
    return [
      {
        name: 'Melddatum',
        title: 'Melddatum',
      },
      {
        name: '__meldnummer' as any,
        title: 'Nummer',
      },
      // {
      //   name: '__meldstatus',
      //   title: 'Status',
      // },
      {
        name: '__productsoortnaam' as any,
        title: 'Cat.',
      },
      // {
      //   name: '__merknaam' as any,
      //   title: 'Merk',
      // },
      // {
      //   name: '__typenaam' as any,
      //   title: 'Type',
      // },
      // {
      //   name: '__product' as any,
      //   title: 'Product',
      // },
      {
        name: '__productSelectie' as any,
        title: 'Product',
      },
      {
        name: '__ontkoppelenProduct' as any,
        title: ' ',
      },
      // {
      //   name: '__referentiecode' as any,
      //   title: 'Ref.code',
      // },
      // {
      //   name: '__melder' as any,
      //   title: 'Melder',
      // },
      {
        name: '__relatie' as any,
        title: 'Relatie',
      },
      {
        name: '__ontkoppelenrelatie' as any,
        title: ' ',
      },
      {
        name: '__locatie' as any,
        title: 'Locatie',
      },
      {
        name: '__contract' as any,
        title: 'Contract',
      },
      // {
      //   name: 'Omschrijving',
      //   title: 'Omschrijving',
      // },
      {
        name: '__reparatieopdrachten',
        title: 'Rep.opd.',
      },
      {
        name: '__status' as any,
        title: 'Status',
      },
      // {
      //   name: '__bijlagen',
      //   title: '#Bijl.',
      // },
      // {
      //   name: '__mutatiebron' as any,
      //   title: 'Bron',
      // },
      // {
      //   name: '__aspGebruiker' as any,
      //   title: 'Gebr.',
      // },
    ];
  }, []);

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRow>[]>(() => {
    return [
      {
        columnName: 'Melddatum',
        width: 125,
      },
      {
        columnName: '__meldnummer' as any,
        width: 100,
      },
      // {
      //   columnName: '__meldstatus' as any,
      //   width: 90,
      // },
      {
        columnName: '__bijlagen' as any,
        width: 90,
      },
      {
        columnName: '__reparatieopdrachten',
        width: 100,
      },
      {
        columnName: 'Omschrijving',
        width: 250,
      },
      {
        columnName: '__productsoortnaam' as any,
        width: 75,
      },
      {
        columnName: '__productSelectie' as any,
        width: 225,
      },
      {
        columnName: '__referentiecode' as any,
        width: 100,
      },
      {
        columnName: '__ontkoppelenProduct' as any,
        width: 70,
      },
      {
        columnName: '__relatie' as any,
        width: 200,
      },
      {
        columnName: '__locatie' as any,
        width: 225,
      },
      {
        columnName: '__aspGebruiker' as any,
        width: 100,
      },
      {
        columnName: '__contract' as any,
        width: 100,
      },
      {
        columnName: '__ontkoppelenrelatie' as any,
        width: 70,
      },
      // {
      //   columnName: '__merknaam' as any,
      //   width: 100,
      // },
      // {
      //   columnName: '__typenaam' as any,
      //   width: 150,
      // },
      {
        columnName: '__product' as any,
        width: 250,
      },
      {
        columnName: '__melder' as any,
        width: 200,
      },

      {
        columnName: '__mutatiebron' as any,
        width: 75,
      },
      {
        columnName: '__status' as any,
        width: 200,
      },
    ];
  }, []);

  const handleOntkoppelenProduct = async (meldingID: number) => {
    if (
      (
        await checkStore.bevestigen({
          inhoud: `Wil je het product ontkoppelen?`,
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    const resultaat = await api.v2.service.koppelenAanProduct({
      servMeldID: meldingID,
      prodID: null,
      contractMeenemen: true,
      relatieMeenemen: true,
    });
    ophalenMeldingen();
  };

  const handleOntkoppelenRelatie = async (meldingID: number) => {
    if (
      (
        await checkStore.bevestigen({
          inhoud: `Wil je de relatie ontkoppelen?`,
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    const resultaat = await api.v2.service.koppelenAanRelatie({
      ids: [meldingID],
      relID: null,
    });
    ophalenMeldingen();
  };

  const meldingenContext = useMemo<IMeldingenContext>(
    () => ({
      onVerversenAangevraagd: async () => {
        await ophalenMeldingen();
      },
    }),
    [ophalenMeldingen],
  );

  if (meldingen === null) {
    return (
      <div className="flex-fill d-flex align-items-center justify-content-center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <MeldingenContext.Provider value={meldingenContext}>
      <Helmet>
        <title>Servicemeldingen</title>
      </Helmet>
      <div style={{ backgroundColor: 'white' }}>
        <div
          className="d-flex flex-column p-3"
          style={{
            backgroundColor: Kleur.HeelLichtGrijs,
            borderBottom: `1px solid ${Kleur.LichtGrijs}`,
          }}
        >
          <div className="d-flex">
            <button
              className="btn btn-sm btn-light d-flex align-items-center"
              style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
              disabled={urlState.selectie.length === 0}
              onClick={() => handleVerwijderen()}
            >
              <IconVerwijderen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
              <span className="ml-2">Verwijderen</span>
            </button>
            {/* <button
              className="btn btn-sm btn-light d-flex align-items-center ml-3"
              style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
              disabled={urlState.selectie.length !== 1}
              onClick={() => null}
            >
              <IconToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
              <span className="ml-2">Koppelen aan relatie</span>
            </button> */}
          </div>
        </div>

        <GridStyleWrapper height="calc(100vh - 150px)">
          <Grid columns={kolommen as Column[]} rows={meldingen} getRowId={keyExtractor}>
            <DataTypeProvider
              for={['__aspGebruiker']}
              formatterComponent={(formatterProps) => {
                const rij: IRow = formatterProps.row;
                return <span>{rij.aspGebruiker !== null ? rij.aspGebruiker.NaamKort : ''}</span>;
              }}
            />

            <DataTypeProvider
              for={[nameof<IRow>('Melddatum')]}
              formatterComponent={(props) => {
                return <span>{format(new Date(props.row.Melddatum), 'dd-MM-yyyy')}</span>;
              }}
            />

            <DataTypeProvider
              for={['__meldnummer']}
              formatterComponent={(formatterProps) => {
                const rij: IRow = formatterProps.row;

                return <ServicemeldingVisualisatie servMeldID={rij.ID} />;
              }}
            />

            <DataTypeProvider
              for={[nameof<IRow>('Meldnummer')]}
              formatterComponent={(props) => <span>{props.value}</span>}
            />

            <DataTypeProvider
              for={['__meldstatus']}
              formatterComponent={(props) => {
                const status = props.row.Afgehandeld ? EMeldingstatus.Groen : EMeldingstatus.Blauw;
                return (
                  <span>
                    <MeldingstatusIndicatie status={status} />
                  </span>
                );
              }}
            />

            <DataTypeProvider
              for={['__productsoortnaam']}
              formatterComponent={(formatterProps) => {
                const rij: IRow = formatterProps.row;
                if (rij.product === null) {
                  return <span></span>;
                }
                return <span>{rij.product.producttype.ProductsoortnaamKort}</span>;
              }}
            />

            <DataTypeProvider
              for={['__contract']}
              formatterComponent={(formatterProps) => {
                const rij: IRow = formatterProps.row;

                if (rij.contract === null) {
                  return <span></span>;
                }
                return <ContractVisualisatie cntID={rij.contract.CntID} />;
              }}
            />

            <DataTypeProvider
              for={['__locatie']}
              formatterComponent={(formatterProps) => {
                const rij: IRow = formatterProps.row;
                if (rij.locatie === null) {
                  return <span></span>;
                }

                const locatie =
                  rij.locatie.Straatnaam +
                  ' ' +
                  rij.locatie.Huisnummer +
                  (rij.locatie.Bisnummer !== null ? ' ' + rij.locatie.Bisnummer : '') +
                  ', ' +
                  rij.locatie.Plaatsnaam;
                return <span>{locatie}</span>;
              }}
            />

            {/* <DataTypeProvider
                for={['__merknaam']}
                formatterComponent={(formatterProps) => {
                  const row: IOphalenMeldingenResultElement = formatterProps.row;
                  if (row.product === null) {
                    return <span></span>;
                  }
                  return <span>{row.product.Merknaam}</span>;
                }}
              />

              <DataTypeProvider
                for={['__typenaam']}
                formatterComponent={(formatterProps) => {
                  const row: IOphalenMeldingenResultElement = formatterProps.row;
                  if (row.product === null) {
                    return <span></span>;
                  }
                  return <span>{row.product.Typenaam}</span>;
                }}
              /> */}

            {/* <DataTypeProvider
                for={['__product']}
                formatterComponent={(formatterProps) => {
                  const rij: IRow = formatterProps.row;
                  if (rij.product !== null) {
                    return (
                      <span>
                        {rij.product.producttype.Merknaam + ' ' + rij.product.producttype.Typenaam}
                      </span>
                    );
                  } else {
                    return <span>{rij.Productomschrijving}</span>;
                  }
                }}
              /> */}

            <DataTypeProvider
              for={['__bijlagen']}
              formatterComponent={(formatterProps) => {
                const rij: IRow = formatterProps.row;
                return <span>{rij.bijlagen.length !== 0 ? rij.bijlagen.length : ''}</span>;
              }}
            />

            <DataTypeProvider
              for={['__productSelectie']}
              formatterComponent={(formatterProps) => {
                const rij: IRow = formatterProps.row;

                if (rij.product === null) {
                  return (
                    <>
                      <a
                        href=""
                        onClick={(e) => {
                          e.preventDefault();
                          setUrlStateSync('productselectieDialoogState', {
                            servMeldID: rij.ID,
                            referentiecode: rij.Referentiecode || undefined,
                            postcode: rij.locatie.Postcode || undefined,
                          });
                        }}
                        // style={{ color: Kleur.Grijs }}
                      >
                        Koppel product
                      </a>
                    </>
                  );
                }

                if (rij.product !== null) {
                  return (
                    <span>
                      {rij.product.producttype.Merknaam + ' ' + rij.product.producttype.Typenaam}
                    </span>
                  );
                } else {
                  return <span>{rij.Productomschrijving}</span>;
                }

                // const urlState: IServicemeldingUrlState = {
                //   ...servicemeldingDefaultUrlState,
                //   selectie: [row.ID],
                // };
                // const query = genereerUrlStateQueryParam(urlState);

                // return (
                //   <>
                //     <RelatieVisualisatie
                //       relID={row.RelID}
                //       relatieLinkBuilder={(hoedanigheid, relID) =>
                //         `/${
                //           hoedanigheid === EHoedanigheid.Klant ? 'klant' : 'leverancier'
                //         }/${relID}/service/melding?state=${query}`
                //       }
                //     />
                //   </>
                // );
              }}
            />

            <DataTypeProvider
              for={['__referentiecode']}
              formatterComponent={(formatterProps) => {
                const rij: IRow = formatterProps.row;
                return <span>{rij.Referentiecode}</span>;
              }}
            />

            <DataTypeProvider
              for={['__ontkoppelenProduct']}
              formatterComponent={(formatterProps) => {
                const rij: IRow = formatterProps.row;

                if (rij.product === null) {
                  return <></>;
                }

                return (
                  <a href="#" onClick={() => handleOntkoppelenProduct(rij.ID)}>
                    <IconKruis style={{ width: 18, height: 18, fill: Kleur.Rood }} />
                  </a>
                );
              }}
            />

            <DataTypeProvider
              for={['__relatie']}
              formatterComponent={(formatterProps) => {
                const rij: IRow = formatterProps.row;

                if (rij.RelID === null) {
                  return (
                    <>
                      <a
                        href=""
                        onClick={(e) => {
                          e.preventDefault();
                          setKoppelenRelatieAanMelding(rij.ID);
                        }}
                        style={{ color: Kleur.Grijs }}
                      >
                        Koppelen
                      </a>
                    </>
                  );
                }

                const urlState: IServicemeldingUrlState = {
                  ...servicemeldingDefaultUrlState,
                  selectie: [rij.ID],
                };
                const query = genereerUrlStateQueryParam(urlState);

                return (
                  <>
                    <RelatieVisualisatie
                      relID={rij.RelID}
                      relatieLinkBuilder={(hoedanigheid, relID) =>
                        `/${
                          hoedanigheid === EHoedanigheid.Klant ? 'klant' : 'leverancier'
                        }/${relID}/service/melding?state=${query}`
                      }
                    />
                  </>
                );
              }}
            />

            <DataTypeProvider
              for={['__ontkoppelenrelatie']}
              formatterComponent={(formatterProps) => {
                const rij: IRow = formatterProps.row;

                if (rij.RelID === null) {
                  return <></>;
                }

                return (
                  <a href="#" onClick={() => handleOntkoppelenRelatie(rij.ID)}>
                    <IconKruis style={{ width: 18, height: 18, fill: Kleur.Rood }} />
                  </a>
                );
              }}
            />

            <DataTypeProvider
              for={['__melder']}
              formatterComponent={(formatterProps) => {
                const rij: IRow = formatterProps.row;
                if (rij.persoon === null) {
                  return <span>{rij.NaamMelder !== null ? rij.NaamMelder : ''}</span>;
                }
                if (rij.persoon.PersID === null) {
                  return <span></span>;
                }
                return <PersoonVisualisatie persID={rij.persoon.PersID} />;
              }}
            />

            <DataTypeProvider
              for={['__reparatieopdrachten']}
              formatterComponent={(formatterProps) => {
                const rij: IRow = formatterProps.row;
                return <span>{rij.opdrachten.length !== 0 ? 'Ja' : 'Nee'}</span>;
              }}
            />

            <DataTypeProvider
              for={['__mutatiebron']}
              formatterComponent={(formatterProps) => {
                const row: IRow = formatterProps.row;
                const Icon = mutatiebronIconMap[row.Mutatiebron as EMutatiebron];
                return (
                  <span>
                    <Icon style={{ width: 18, height: 18, fill: Kleur.Grijs }} />
                  </span>
                );
              }}
            />

            <DataTypeProvider
              for={['__status']}
              formatterComponent={(formatterProps) => {
                const rij: IRow = formatterProps.row;
                return <span>{rij.status.Naam}</span>;
              }}
            />

            <SortingState defaultSorting={[]} />
            <IntegratedSorting />
            <VirtualTable estimatedRowHeight={10} />
            <TableColumnResizing defaultColumnWidths={kolomBreedtes as TableColumnWidthInfo[]} />
            <TableHeaderRow showSortingControls />

            <EditingState
              onAddedRowsChange={() => {}}
              onEditingRowIdsChange={(x) => {
                const id = x[x.length - 1] as number;
                setWijzigenID(id);
              }}
              onCommitChanges={() => null}
            />
            <TableEditColumn
              width={40}
              // showAddCommand={true}
              showEditCommand
              // showDeleteCommand
              commandComponent={DXCommandComponent}
            />

            <RowDetailState defaultExpandedRowIds={[]} />
            <TableRowDetail
              contentComponent={RowDetailComp}
              toggleCellComponent={DXTableToggleCellComponent}
            />

            <SelectionState
              selection={urlState.selectie}
              onSelectionChange={(value) => setUrlStateSync('selectie', value as number[])}
            />
            <TableSelection cellComponent={DXTableCheckboxComponent} />
          </Grid>
        </GridStyleWrapper>
      </div>

      {koppelenRelatieAanMelding !== null && (
        <RelatieSelectieDialoog
          onSuccess={async (data) => {
            const result = await api.v2.service.koppelenAanRelatie({
              ids: [koppelenRelatieAanMelding],
              relID: data.relID,
            });
            ophalenMeldingen();
            setKoppelenRelatieAanMelding(null);
          }}
          onAnnuleren={() => {
            setKoppelenRelatieAanMelding(null);
          }}
          open={true}
        />
      )}

      {wijzigenID !== null && (
        <WijzigenMeldingDialoog
          open
          onSuccess={async () => {
            setWijzigenID(null);
            ophalenMeldingen();
          }}
          onAnnuleren={() => {
            setWijzigenID(null);
          }}
          servMeldID={wijzigenID}
        />
      )}
      {urlState.productselectieDialoogState !== null && (
        <ProductSelectieDialoog
          open
          koppelOpties={{
            defaultSelecterenValues: {
              referentiecode: urlState.productselectieDialoogState.referentiecode || '',
              referentiecodeTekstprecisie: ETekstPrecisie.Exact,
              postcode: urlState.productselectieDialoogState.postcode || '',
              typenaam: '',
              typenaamTekstprecisie: ETekstPrecisie.StartMet,
              ookProductenUitBedrijf: true,
              notities: '',
              notitiesTekstprecisie: ETekstPrecisie.Ongeveer,
            },
          }}
          onSuccess={async (data) => {
            const result = await api.v2.service.koppelenAanProduct({
              servMeldID: urlState.productselectieDialoogState!.servMeldID,
              prodID: data.prodID,
              contractMeenemen: true,
              relatieMeenemen: true,
            });

            const product = (
              await api.v2.product.ophalenProductenV2({
                filterSchema: { filters: [{ naam: 'IDS', data: [data.prodID] }] },
              })
            ).producten[0];

            if (product.contractActueel !== null) {
            }

            ophalenMeldingen();
            setUrlStateSync('productselectieDialoogState', null);
          }}
          onAnnuleren={() => setUrlStateSync('productselectieDialoogState', null)}
        />
      )}
    </MeldingenContext.Provider>
  );
};

export default Meldingen;
