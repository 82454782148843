import {
  IOphalenDienstenParams,
  IOphalenDienstenResult,
  IToevoegenDienstParams,
  IToevoegenDienstResult,
  IVerwijderenDienstenParams,
  IVerwijderenDienstenResult,
  IWijzigenDienstParams,
  IWijzigenDienstResult,
} from '../../../../../../shared/src/api/v2/dienst/service';
import api from '../../../index';
import ICheckData from '../../../../../../shared/src/models/ICheckData';
import gebied from './gebied';
import afwezigheid from './afwezigheid';

const service = {
  gebied,
  afwezigheid,
  ophalenDiensten: async (params: IOphalenDienstenParams): Promise<IOphalenDienstenResult> => {
    return await api.post('v2/dienst/service/ophalen-diensten', params);
  },
  checkToevoegenDienst: async (params: IToevoegenDienstParams): Promise<ICheckData> => {
    return await api.post('v2/dienst/service/check-toevoegen-dienst', params);
  },
  toevoegenDienst: async (params: IToevoegenDienstParams): Promise<IToevoegenDienstResult> => {
    return await api.post('v2/dienst/service/toevoegen-dienst', params);
  },
  checkWijzigenDienst: async (params: IWijzigenDienstParams): Promise<ICheckData> => {
    return await api.post('v2/dienst/service/check-wijzigen-dienst', params);
  },
  wijzigenDienst: async (params: IWijzigenDienstParams): Promise<IWijzigenDienstResult> => {
    return await api.post('v2/dienst/service/wijzigen-dienst', params);
  },
  checkVerwijderenDiensten: async (params: IVerwijderenDienstenParams): Promise<ICheckData> => {
    return await api.post('v2/dienst/service/check-verwijderen-diensten', params);
  },
  verwijderenDiensten: async (
    params: IVerwijderenDienstenParams,
  ): Promise<IVerwijderenDienstenResult> => {
    return await api.post('v2/dienst/service/verwijderen-diensten', params);
  },
};

export default service;
