import { format } from 'date-fns';
import React, { useContext, useMemo } from 'react';
import { RouteComponentProps } from 'react-router';
import { IContext, CrediteurContext } from '..';
import VeldWeergave from '../../../formulier/VeldWeergave';
import LoadingSpinner from '../../../Gedeeld/LoadingSpinner';
import GegevensLayout from '../../../layout/GegevensLayout';

import { ITabbladProps } from '../../../product/ProductinfoDialoog';

interface IProps extends ITabbladProps, RouteComponentProps {}

const DetailTab: React.FC<IProps> = (props) => {
  const { relatie: crediteur } = useContext<IContext>(CrediteurContext);

  return <div className="flex-fill d-flex align-items-center justify-content-center">TODO</div>;
};

export default DetailTab;
