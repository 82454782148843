import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import {
  IconBellen,
  IconEmail,
  IconKruis,
  IconPersoon,
  IconTaal,
  IconWarning,
} from '../../../../Icons';
import { Kleur as EKleur } from '../../../../../bedrijfslogica/constanten';
import { formatteerPersoonNaam } from '../../../../../helpers';
import EmailComponent from '../../../../communicatie/EmailComponent';
import TelefoonComponent from '../../../../communicatie/TelefoonComponent';
import { IOphalenPersonenResultElementV2 } from '../../../../../../../shared/src/api/v2/persoon/persoon';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../../models/IRemoteData';
import { IOphalenPersoonDuplicaatSuggestiesResult } from '../../../../../../../shared/src/api/v2/persoon/duplicaat';
import api from '../../../../../api';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { IDuplicatenPersoonSuggestiesUrlState } from '../../../../../paginas/Backoffice/Duplicaten/Persoon/Suggesties';
import { genereerUrlStateQueryParam } from '../../../../../core/useUrlState';
import PersoonVisualisatie from '../../index';
import { IOphalenContactpersonenResult } from '../../../../../../../shared/src/api/v2/relatie/contactpersoon';
import Skeleton from 'react-loading-skeleton';
import RelatieVisualisatie from '../../../RelatieVisualisatie';
import { IOphalenKrediettoetsenResultElement } from '../../../../../../../shared/src/api/v2/kredietwaardigheid';
import KredietwaardigheidIndicatie, {
  Kredietscore,
} from '../../../../../paginas/Debiteuren/Beheer/KredietwaardigheidIndicatie';

const Root = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Naam = styled.span`
  font-size: 17.5px;
  font-weight: 700;
`;

const Taal = styled.span``;

interface IProps extends RouteComponentProps {
  persoon: IOphalenPersonenResultElementV2;
}

const Data = (props: IProps) => {
  const [duplicaatSuggestiesResult, setDuplicaatSuggestiesResult] = useState<
    IRemoteData<IOphalenPersoonDuplicaatSuggestiesResult>
  >(createPendingRemoteData());
  const ophalenDuplicaatSuggesties = useCallback(async () => {
    const result = await api.v2.persoon.duplicaat.ophalenPersoonDuplicaatSuggesties({
      filterSchema: {
        filters: [
          {
            naam: 'PERS_IDS',
            data: [props.persoon.PersID],
          },
        ],
      },
    });
    setDuplicaatSuggestiesResult(createReadyRemoteData(result));
  }, [props.persoon.PersID]);
  useEffect(() => {
    ophalenDuplicaatSuggesties();
  }, [ophalenDuplicaatSuggesties]);

  const [contactpersonen, setContactpersonen] = useState<
    IRemoteData<IOphalenContactpersonenResult>
  >(createPendingRemoteData());
  const ophalenContactpersonen = useCallback(async () => {
    const result = await api.v2.relatie.ophalenContactpersonen({
      filterSchema: {
        filters: [
          {
            naam: 'PERS_IDS',
            data: [props.persoon.PersID],
          },
        ],
      },
    });
    setContactpersonen(createReadyRemoteData(result));
  }, [props.persoon.PersID]);
  useEffect(() => {
    ophalenContactpersonen();
  }, [ophalenContactpersonen]);

  const [krediettoets, setKrediettoets] = useState<
    IRemoteData<IOphalenKrediettoetsenResultElement | null>
  >(createPendingRemoteData());
  useEffect(() => {
    (async () => {
      const result = await api.v2.kredietwaardigheid.ophalenKrediettoetsen({
        filterSchema: {
          filters: [
            {
              naam: 'PERS_IDS',
              data: [props.persoon.PersID],
            },
          ],
        },
        orderSchema: {
          orders: [
            {
              naam: 'DATUM',
              richting: 'DESC',
            },
          ],
        },
        paginatie: {
          index: 0,
          aantal: 1,
        },
      });
      const toets = result.toetsen[0] ?? null;
      setKrediettoets(createReadyRemoteData(toets));
    })();
  }, [props.persoon.PersID]);

  const email = useMemo(() => {
    return props.persoon.Email ?? null;
  }, [props.persoon]);
  const telefoon = useMemo(() => {
    return props.persoon.TelefoonMobiel ?? null;
  }, [props.persoon]);
  const taal = useMemo(() => {
    return props.persoon.taal ?? null;
  }, [props.persoon]);

  return (
    <Root>
      <div className="d-flex flex-fill">
        <div className="d-flex flex-fill flex-column">
          <div className="d-flex align-items-center">
            <div style={{ width: 26 }}>
              <IconPersoon style={{ fill: EKleur.Grijs, width: 26, height: 26 }} />
            </div>
            <div className="flex-fill" style={{ marginLeft: 10 }}>
              <Naam>
                {formatteerPersoonNaam({
                  aanhefKort: props.persoon.geslacht.AanhefKort,
                  achternaam: props.persoon.Achternaam,
                  voorletters: props.persoon.Voorletters,
                  voornaam: props.persoon.Voornaam,
                  voorvoegsel: props.persoon.Voorvoegsel,
                })}
              </Naam>
              {props.persoon.Overleden && (
                <span className="ml-3" style={{ color: EKleur.Rood }}>
                  OVERLEDEN
                </span>
              )}
            </div>
          </div>

          {email !== null && (
            <div className="d-flex align-items-center" style={{ marginTop: 5 }}>
              <IconEmail style={{ fill: EKleur.Grijs, width: 16, height: 16 }} />
              <div className="flex-fill" style={{ marginLeft: 10 }}>
                <EmailComponent
                  persID={props.persoon.PersID ?? undefined}
                  emailAdres={email}
                  options={{
                    icoonWeergeven: false,
                  }}
                />
              </div>
            </div>
          )}

          {telefoon && (
            <div className="d-flex align-items-center" style={{ marginTop: 5 }}>
              <IconBellen style={{ fill: EKleur.Grijs, width: 16, height: 16 }} />
              <div className="flex-fill" style={{ marginLeft: 10 }}>
                <TelefoonComponent telefoonNummer={telefoon} options={{ icoonWeergeven: false }} />
              </div>
            </div>
          )}

          {taal !== null && (
            <div className="d-flex align-items-center" style={{ marginTop: 5 }}>
              <IconTaal style={{ fill: EKleur.Grijs, width: 16, height: 16 }} />
              <div className="flex-fill" style={{ marginLeft: 10 }}>
                <Taal>{taal.TaalKort}</Taal>
              </div>
            </div>
          )}

          {contactpersonen.state === ERemoteDataState.Pending ? (
            <Skeleton height={30} />
          ) : (
            <div className="d-flex flex-column mt-2">
              {contactpersonen.data!.contactpersonen.map((contactpersoon) => (
                <RelatieVisualisatie relID={contactpersoon.RelID} />
              ))}
            </div>
          )}

          {duplicaatSuggestiesResult.state === ERemoteDataState.Ready &&
            duplicaatSuggestiesResult.data!.suggesties.length > 0 && (
              <div className="d-flex align-items-center" style={{ marginTop: 5 }}>
                <IconWarning style={{ fill: EKleur.LichtGeel, width: 16, height: 16 }} />
                <a
                  href="#"
                  className="flex-fill"
                  style={{ marginLeft: 10 }}
                  onClick={(ev) => {
                    ev.preventDefault();
                    const urlState: IDuplicatenPersoonSuggestiesUrlState = {
                      uitgeklapt: [],
                      persIDFilter: props.persoon.PersID,
                    };
                    const qp = genereerUrlStateQueryParam(urlState);
                    props.history.push(`/backoffice/duplicaten/persoon?suggestiesState=${qp}`);
                  }}
                >
                  Er zijn duplicaat suggesties aanwezig
                </a>
              </div>
            )}

          {props.persoon.Vervangende_PersID !== null && (
            <div className="d-flex align-items-center" style={{ marginTop: 5 }}>
              <IconKruis style={{ fill: EKleur.LichtRood, width: 18, height: 19 }} />
              <span style={{ marginLeft: 10, marginRight: 5 }}>Deze persoon is vervangen door</span>
              <PersoonVisualisatie persID={props.persoon.Vervangende_PersID} />
            </div>
          )}
        </div>

        <div style={{ width: 50, marginLeft: 10 }} className="d-flex flex-column">
          <span>{props.persoon.PersID}</span>
          <div className="flex-fill" />
          <div className="d-flex align-items-center justify-content-end">
            {krediettoets.state === ERemoteDataState.Ready && krediettoets.data !== null && (
              <KredietwaardigheidIndicatie
                score={krediettoets.data!.Score! as Kredietscore}
                className="mr-2"
              />
            )}
          </div>
        </div>
      </div>

      <div style={{ height: 3 }} />

      <div style={{ borderTop: `1px solid ${EKleur.LichtGrijs}`, marginTop: 5, marginBottom: 8 }} />

      <div style={{ marginBottom: 5 }}>
        <textarea className="form-control" rows={11} disabled>
          {props.persoon.Notities ?? ''}
        </textarea>
      </div>
    </Root>
  );
};

export default withRouter(Data);
