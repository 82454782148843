import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import api from '../../../../../api';
import IDialoogProps from '../../../../../core/IDialoogProps';
import { RootStoreContext } from '../../../../../stores/RootStore';
import Dialoog from '../../../../../components/dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import nameof from '../../../../../core/nameOf';
import VinkVeld from '../../../../../components/formulier/VinkVeld';
import { EResultType } from '../../../../../stores/CheckStore';
import { achtergrondProcesAfwachten } from '../../../../../core/achtergrondProces';
import { IVersturenAanmaningenResult } from '../../../../../../../shared/src/api/v2/aanmaning';
import { RealtimeContext } from '../../../../../one-off-components/realtime/RealtimeIntegratie';
import { observer } from 'mobx-react-lite';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../../models/IRemoteData';
import ProgressieBalk from '../../../../../components/ProgressieBalk';
import { EKanaal } from '../../../../../bedrijfslogica/enums';

interface IProps extends IDialoogProps<null> {
  aanmIDs: number[];
  kanalen?: string[];
}

interface IFormikValues {
  directVersturen: boolean;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  directVersturen: 'Direct versturen',
};

const DefinitiefMakenDialoog: React.FC<IProps> = observer((props) => {
  const { open, onSuccess, onAnnuleren, dialoogIndex } = props;
  const formikRef = useRef<Formik<IFormikValues>>(null);
  const { checkStore } = useContext(RootStoreContext);
  const realtimeContext = useContext(RealtimeContext);

  const [progressiePercentage, setProgressiePercentage] = useState<IRemoteData<number>>(
    createPendingRemoteData(),
  );

  const initialValues = useMemo<IFormikValues>(
    () => ({
      directVersturen: false,
    }),
    [],
  );

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);
      const resultaat = await checkStore.bevestigen({
        inhoud: (
          <span>
            Geselecteerde aanmaningen definitief maken?
            {values.directVersturen && (
              <>
                <br />
                De aanmaningen worden direct verstuurd.
              </>
            )}
            <br />
            Let op: Dit kan niet ongedaan gemaakt worden.
          </span>
        ),
      });
      if (resultaat.type === EResultType.Annuleren) {
        actions.setSubmitting(false);
        return;
      }

      const params = { aanmIDs: props.aanmIDs };
      const results = await api.v2.aanmaning.definitiefMakenAanmaningen(params);

      // Direct versturen?
      const formik = formikRef.current!;
      const directVersturen = formik.getFormikBag().values.directVersturen;

      if (directVersturen) {
        const achtergrondproces = await api.v2.aanmaning.versturenAanmaningenAchtergrond({
          aanmIDs: props.aanmIDs,
          kanalen: props.kanalen ?? [EKanaal.Email],
        });

        const result = await achtergrondProcesAfwachten<IVersturenAanmaningenResult>(
          achtergrondproces.id,
          realtimeContext,
          (percentage) => {
            setProgressiePercentage(createReadyRemoteData(percentage));
          },
        );
        if (result.type === 'ERROR') {
          await checkStore.melden({
            titel: 'Er heeft een fout plaatsgevonden',
          });
          actions.setSubmitting(false);
          return;
        }
        if (result.type === 'TIMEOUT') {
          await checkStore.melden({
            titel: 'Het proces duurde te lang en is daarom afgebroken',
          });
          actions.setSubmitting(false);
          return;
        }
      }

      onSuccess(null);
      actions.setSubmitting(false);
    },
    [onSuccess],
  );

  const validationSchema = useCallback(() => {}, []);

  return (
    <Dialoog index={dialoogIndex || 0} modalProps={{ size: 'sm' }}>
      <ModalHeader>
        <ModalTitle>Definitief maken</ModalTitle>
      </ModalHeader>
      <Formik<IFormikValues>
        ref={formikRef}
        onSubmit={handleSubmit}
        initialValues={initialValues}
        isInitialValid={true}
        enableReinitialize
        validationSchema={validationSchema}
        render={(formikProps: FormikProps<IFormikValues>) => {
          const { submitForm, isSubmitting, isValid } = formikProps;

          return (
            <>
              <ModalBody>
                <div className="form-group">
                  <div className="row">
                    <div className="col-12 mt-1">
                      <div className="d-flex align-items-center">
                        <Field
                          name={nameof<IFormikValues>('directVersturen')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <VinkVeld
                                aangevinkt={field.value}
                                onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                              />
                            );
                          }}
                        />
                        <span className="ml-2">{veldnamen.directVersturen}</span>
                      </div>
                    </div>
                  </div>
                </div>
                {progressiePercentage.state === ERemoteDataState.Ready && (
                  <div className="mt-2">
                    <ProgressieBalk percentage={progressiePercentage.data!} />
                  </div>
                )}
              </ModalBody>
              <ModalFooter className="d-flex flex-row justify-content-start">
                <button
                  className="btn btn-primary"
                  onClick={submitForm}
                  style={{ width: 100 }}
                  disabled={isSubmitting || !isValid}
                >
                  Ok
                </button>
                <button
                  className="btn btn-secondary"
                  onClick={onAnnuleren}
                  style={{ width: 100 }}
                  disabled={isSubmitting}
                >
                  Annuleren
                </button>
              </ModalFooter>
            </>
          );
        }}
      />
    </Dialoog>
  );
});

export default DefinitiefMakenDialoog;
