import React, { useCallback, useContext, useMemo, useState } from 'react';
import AutoResizeTextarea from '../../AutoResizeTextarea';
import { IconAttachment, IconSend } from '../../Icons';
import { Container, InputContainer, Root } from './style';
import { Kleur } from '../../../bedrijfslogica/constanten';
import SjabloonKnop from '../../SjabloonKnop';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../stores/RootStore';
import InactiefOverlay from '../../InactiefOverlay';
import BijlageKnop from '../../BijlageKnop';
import BestandenDragAndDropDialoog from '../../BestandenDragAndDropDialoog';
import {
  EBestandDragAndDropZoneBronModus,
  EBestandDragAndDropZoneSelectieModus,
} from '../../BestandDragAndDropZone';
import { IOphalenBestandenResultElement } from '../../../../../shared/src/api/v2/bestand/bestand';

export type BeschikbareContextenSelectie = Array<{
  naamEnum?: string;
  sjabCtxID?: number;
  data: any;
}>;

export type CommunicatieKanaalSelectie = {
  naamEnum?: string;
  comKanID?: number;
};

export interface ISjabloonData {
  beschikbareContextenSelectie: BeschikbareContextenSelectie;
  communicatieKanaalSelectie: CommunicatieKanaalSelectie;
}

interface IBijlageConfig {
  selectieModus?: EBestandDragAndDropZoneSelectieModus;
  bronModus?: EBestandDragAndDropZoneBronModus;
  toegestaneBestandstypen?: {
    weergaveNaam: string;
    mediaType: string;
  }[];
  onGekozen: (bestanden: IOphalenBestandenResultElement[]) => void;
}

interface IBijlageDialoogState {}

export interface IIndienenKnopComponentProps {
  handleIndienenClick: () => void;
  magIndienen: boolean;
}

interface IProps {
  tekst: string;
  maxKarakters?: number;
  bepalenMaxKaraktersWeergeven?: () => boolean;
  onTekstChange: (tekst: string) => void;
  onIndienen: () => Promise<void>;
  onHeightChange?: (height: number) => void;
  sjabloonData?: ISjabloonData;
  bijlageConfig?: IBijlageConfig;
  disabled?: boolean;
  indienenKnopComponent?: React.ComponentType<IIndienenKnopComponentProps>;
  taalID?: number;
  disabledContent?: React.ComponentType;
}

const BerichtSamensteller = observer((props: IProps) => {
  const { checkStore } = useContext(RootStoreContext);
  const [bezigMetIndienen, setBezigMetIndienen] = useState(false);
  const [bijlageDialoogState, setBijlageDialoogState] = useState<IBijlageDialoogState | null>(null);

  const magIndienen = useMemo(() => {
    const trimmedTekst = props.tekst.trim();
    return !bezigMetIndienen && trimmedTekst.length !== 0;
  }, [props.tekst, bezigMetIndienen]);

  const handleIndienenClick = useCallback(async () => {
    setBezigMetIndienen(true);
    await props.onIndienen();
    setBezigMetIndienen(false);
  }, [props.onIndienen, setBezigMetIndienen]);

  const IndienenKnop = useMemo(() => {
    if (props.indienenKnopComponent !== undefined) {
      return props.indienenKnopComponent;
    }
    return (indienenKnopProps: IIndienenKnopComponentProps) => {
      return (
        <button
          className="btn btn-sm btn-light d-flex justify-content-center align-items-center"
          onClick={indienenKnopProps.handleIndienenClick}
          style={{
            border: `1px solid ${Kleur.LichtGrijs}`,
          }}
          disabled={!indienenKnopProps.magIndienen}
        >
          <IconSend style={{ width: 13, fill: Kleur.Grijs }} />
          <span className="ml-2">Versturen</span>
        </button>
      );
    };
  }, [props.indienenKnopComponent]);

  const bepalenMaxKaraktersWeergeven = useMemo(
    () =>
      props.bepalenMaxKaraktersWeergeven === undefined
        ? () => {
            const weergaveLengte = props.maxKarakters! * 0.3;
            const tekstLengte = props.tekst.length;
            return tekstLengte > weergaveLengte;
          }
        : props.bepalenMaxKaraktersWeergeven,
    [props.bepalenMaxKaraktersWeergeven, props.maxKarakters, props.tekst],
  );

  const maxKaraktersWeergeven = useMemo(
    () => props.maxKarakters !== undefined && bepalenMaxKaraktersWeergeven(),
    [props.maxKarakters, bepalenMaxKaraktersWeergeven],
  );

  return (
    <>
      <Root>
        <InactiefOverlay
          isInactief={Boolean(props.disabled)}
          inactiefContent={props.disabledContent}
          element={
            <Container>
              <InputContainer maxKaraktersWeergegeven={maxKaraktersWeergeven}>
                <AutoResizeTextarea
                  onExposeRef={(ref) => null}
                  maxResizeHeight={200}
                  placeholder="Typ een bericht"
                  value={props.tekst}
                  onChange={(ev: any) => {
                    const value = ev.target.value as string;
                    const tekst =
                      props.maxKarakters === undefined ? value : value.slice(0, props.maxKarakters);
                    props.onTekstChange(tekst);
                  }}
                  onResize={props.onHeightChange}
                  // disabled={versturenBezig}
                  max={props.maxKarakters}
                  minHeight={50}
                />
                {maxKaraktersWeergeven && (
                  <div className="max-karakters-container">
                    {props.tekst.length} / {props.maxKarakters}
                  </div>
                )}
              </InputContainer>

              {props.sjabloonData !== undefined && (
                <div className="ml-4">
                  <SjabloonKnop
                    beschikbareContextenSelectie={props.sjabloonData.beschikbareContextenSelectie}
                    communicatieKanaalSelectie={props.sjabloonData.communicatieKanaalSelectie}
                    onSjabloonGeresolved={(result) => {
                      // await checkStore.melden({ titel: 'Sjabloon resolven is mislukt' });
                      props.onTekstChange(result.resolveResult.inhoud!);
                    }}
                    iconStyle={{
                      width: 23,
                      height: 23,
                      fill: Kleur.Grijs,
                    }}
                    popoverStyle={{
                      zIndex: 99999999,
                    }}
                    taalID={props.taalID}
                  />
                </div>
              )}
              {props.bijlageConfig !== undefined && (
                <div
                  className="ml-4"
                  style={{ cursor: 'pointer' }}
                  onClick={() => setBijlageDialoogState({})}
                >
                  <IconAttachment style={{ width: 23, height: 23, fill: Kleur.Grijs }} />
                </div>
              )}
              <div className="ml-4 mr-2">
                <IndienenKnop magIndienen={magIndienen} handleIndienenClick={handleIndienenClick} />
              </div>
            </Container>
          }
        />
      </Root>

      {bijlageDialoogState !== null && (
        <BestandenDragAndDropDialoog
          open
          onSuccess={(result) => {
            setBijlageDialoogState(null);
            props.bijlageConfig!.onGekozen(result.bestanden);
          }}
          onAnnuleren={() => setBijlageDialoogState(null)}
          {...props.bijlageConfig!}
        />
      )}
    </>
  );
});

export default BerichtSamensteller;
