import React from 'react';
import { IProps } from '@tinymce/tinymce-react/lib/cjs/main/ts/components/Editor';
import { Field, FieldProps } from 'formik';
import { IFormikValues, veldnamen } from '../index';
import DatumKiezer from '../../../../../../../../../components/formulier/DatumKiezer';
import nameof from '../../../../../../../../../core/nameOf';
import VinkVeld from '../../../../../../../../../components/formulier/VinkVeld';
import VeldWeergave from '../../../../../../../../../components/formulier/VeldWeergave';

const ProlongatieTab: React.FC<IProps> = (props) => {
  return (
    <>
      <div className="p-3">
        <div className="form-group">
          <div className="row mb-3">
            <div className="col-4">
              <label>{veldnamen.prolongatieStart}</label>
              <Field
                name={nameof<IFormikValues>('prolongatieStart')}
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;
                  return (
                    <div>
                      <DatumKiezer
                        isClearable={true}
                        waarde={field.value}
                        onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                      />
                    </div>
                  );
                }}
              />
            </div>

            <div className="col-4">
              <label>{veldnamen.prolongatieTot}</label>
              <Field
                name={nameof<IFormikValues>('prolongatieTot')}
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;
                  return (
                    <div>
                      <DatumKiezer
                        isClearable={true}
                        waarde={field.value}
                        onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                      />
                    </div>
                  );
                }}
              />
            </div>

            <div className="col-4">
              <label>{veldnamen.prolongatieEind}</label>
              <Field
                name={nameof<IFormikValues>('prolongatieEind')}
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;
                  return (
                    <div>
                      <DatumKiezer
                        isClearable={true}
                        waarde={field.value}
                        onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                      />
                    </div>
                  );
                }}
              />
            </div>
          </div>

          <div className="row col-6 mt-4">
            <div className="d-flex align-items-center">
              <Field
                name={nameof<IFormikValues>('prolongatieOphouden')}
                render={(x: FieldProps<IFormikValues>) => (
                  <VinkVeld
                    aangevinkt={x.field.value}
                    onGewijzigd={(aangevinkt: boolean) =>
                      x.form.setFieldValue(x.field.name, aangevinkt)
                    }
                  />
                )}
              />
              <span className="ml-2">{veldnamen.prolongatieOphouden}</span>
            </div>
          </div>

          {/* <div className="col-6 mt-3">
            <label>&nbsp;</label>
            <div className="d-flex align-items-center">
              <VinkVeld
                aangevinkt={x.field.value}
                onGewijzigd={(aangevinkt: boolean) =>
                  x.form.setFieldValue(x.field.name, aangevinkt)
                }
              />
              <span className="ml-2">{veldnamen.prolongatieOphouden}</span>
            </div>
          </div> */}
        </div>
        <div className="row">
          <div className="col-4">
            <label>{veldnamen.datumGeleverd}</label>
            <Field
              name={nameof<IFormikValues>('datumGeleverd')}
              render={(fieldProps: FieldProps<IFormikValues>) => {
                const { field, form } = fieldProps;
                return (
                  <div>
                    <DatumKiezer
                      isClearable={true}
                      waarde={field.value}
                      onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                    />
                  </div>
                );
              }}
            />
          </div>
          <div className="col-4">
            <label>{veldnamen.datumRetour}</label>
            <Field
              name={nameof<IFormikValues>('datumRetour')}
              render={(fieldProps: FieldProps<IFormikValues>) => {
                const { field, form } = fieldProps;
                return (
                  <div>
                    <DatumKiezer
                      isClearable={true}
                      waarde={field.value}
                      onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                    />
                  </div>
                );
              }}
            />
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-12">
            <VeldWeergave>
              De velden <b>{veldnamen.prolongatieStart}</b> en <b>{veldnamen.prolongatieTot}</b>{' '}
              worden normaal gesproken door het systeem gevuld.
              <br />
              Deze alleen aanpassen in bijzondere gevallen.
            </VeldWeergave>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProlongatieTab;
