import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import IDialoogProps from '../../../core/IDialoogProps';
import Dialoog from '../../dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import api from '../../../api';
import { Formik, FormikActions, Field, FieldProps } from 'formik';
import Combobox, { IOptie } from '../../formulier/Combobox';
import nameof from '../../../core/nameOf';
import {
  IBepalenVerrekeningsbedragResult,
  IOphalenFacturenBasisResultElement,
} from '../../../../../shared/src/api/v2/factuur';
import { arrayMove, SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import LoadingSpinner from '../../Gedeeld/LoadingSpinner';
import FormatteerBedrag from '../../MutatieBedrag';
import { format } from 'date-fns';
import _ from 'lodash';
import { GridStyleWrapper } from '../../../helpers/dxTableGrid';
import { DataTypeProvider, IntegratedSorting } from '@devexpress/dx-react-grid';
import DragHandle from '../../tabel/DragHandle';
import {
  Table,
  TableColumnResizing,
  Grid,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-bootstrap4';
import { RootStoreContext } from '../../../stores/RootStore';
import { EResultType } from '../../../stores/CheckStore';

enum EMethode {
  FactuurdatumOplopend = 'FACTUURDATUM_OPLOPEND',
  FactuurdatumAflopend = 'FACTUURDATUM_AfLOPEND',
  FactuurbedragOplopend = 'FACTUURBEDRAG_OPLOPEND',
  FactuurbedragAflopend = 'FACTUURBEDRAG_AFLOPEND',
  Handmatig = 'HANDMATIG',
}

interface IProps extends IDialoogProps<null> {
  factIDs: number[];
}

interface IFormikValues {
  methode: EMethode;
  handmatigVolgordeFactIDs: number[];
}

const veldnamen: Record<keyof IFormikValues, string> = {
  methode: 'Methode',
  handmatigVolgordeFactIDs: 'Handmatige volgorde',
};

interface IVerrekenenDialoogContext {
  verrekeningsbedragFacturen: IBepalenVerrekeningsbedragResult | null;
}

const VerrekenenDialoogContext = React.createContext<IVerrekenenDialoogContext>(null as any);

const VerrekenenDialoog: React.FC<IProps> = (props) => {
  const { checkStore } = useContext(RootStoreContext);

  const formikRef = useRef<Formik<IFormikValues>>(null);
  const [facturenBasisResult, setFacturen] = useState<IOphalenFacturenBasisResultElement[] | null>(
    null,
  );

  useEffect(() => {
    (async () => {
      const result = await api.v2.factuur.ophalenFacturenBasis({
        filterSchema: { filters: [{ naam: 'IDS', data: props.factIDs }] },
      });
      const resultGesorteerd = _.orderBy(result.facturen, ['Factuurdatum'], ['asc']);

      setFacturen(resultGesorteerd);
    })();
  }, [props.factIDs]);

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (facturenBasisResult === null) {
      return null;
    }

    return {
      methode: EMethode.FactuurdatumOplopend,
      handmatigVolgordeFactIDs: facturenBasisResult.map((x) => x.FactID),
    };
  }, [props.factIDs, facturenBasisResult]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      // Bepaal de verrekeningsbedragen
      const bepalingResult = await api.v2.factuur.bepalenVerrekeningsbedragFacturen({
        methode: values.methode,
        factIDs:
          values.methode !== EMethode.Handmatig ? props.factIDs : values.handmatigVolgordeFactIDs,
      });

      // Is er wat te verrekenen?
      if (bepalingResult.resultaten.every((x) => x.afgeboekt === 0)) {
        await checkStore.melden({ titel: 'Er zijn geen bedragen om te verrekenen' });
        actions.setSubmitting(false);
        return;
      }

      // Vraag om bevestiging
      if (
        (
          await checkStore.bevestigen({
            inhoud: <div>Facturen verrekenen?</div>,
          })
        ).type === EResultType.Annuleren
      ) {
        actions.setSubmitting(false);
        return;
      }

      // Verrekening uitvoeren
      await api.v2.factuur.verwerkenFactuurverrekening({ facturen: bepalingResult.resultaten });

      actions.setSubmitting(false);
      props.onSuccess(null);
    },
    [props.factIDs, props.onSuccess],
  );

  const methodeOpties = useMemo<IOptie<EMethode>[]>(() => {
    return [
      {
        id: EMethode.FactuurdatumOplopend,
        label: 'Factuurdatum - oplopend',
      },
      {
        id: EMethode.FactuurdatumAflopend,
        label: 'Factuurdatum - aflopend',
      },
      {
        id: EMethode.FactuurbedragOplopend,
        label: 'Factuurbedrag - oplopend',
      },
      {
        id: EMethode.FactuurbedragAflopend,
        label: 'Factuurbedrag - aflopend',
      },
      {
        id: EMethode.Handmatig,
        label: 'Handmatig',
      },
    ];
  }, []);

  const [
    verrekeningsbedragFacturen,
    setVerrekeningsbedragFacturen,
  ] = useState<IBepalenVerrekeningsbedragResult | null>(null);

  const ophalenVerrekingsbedragFacturen = useCallback(
    async (methode: EMethode, factIDs: number[]) => {
      const bepalingResult = await api.v2.factuur.bepalenVerrekeningsbedragFacturen({
        methode,
        factIDs,
      });
      setVerrekeningsbedragFacturen(bepalingResult);
      const actions = formikRef.current!.getFormikActions();
      actions.setFieldValue(
        nameof<IFormikValues>('handmatigVolgordeFactIDs'),
        bepalingResult.resultaten.map((x) => x.factID),
      );
    },
    [],
  );
  useEffect(() => {
    if (initialValues === null || initialValues.methode === null) {
      return;
    }
    ophalenVerrekingsbedragFacturen(
      initialValues.methode,
      initialValues.methode !== EMethode.Handmatig
        ? props.factIDs
        : initialValues.handmatigVolgordeFactIDs,
    );
  }, [initialValues]);

  const context = useMemo<IVerrekenenDialoogContext>(
    () => ({
      verrekeningsbedragFacturen,
    }),
    [verrekeningsbedragFacturen],
  );

  return (
    <VerrekenenDialoogContext.Provider value={context}>
      <Dialoog
        index={props.dialoogIndex || 0}
        modalProps={{
          size: 'lg',
        }}
      >
        <ModalHeader>
          <ModalTitle>Facturen verrekenen</ModalTitle>
        </ModalHeader>
        {initialValues === null ? null : (
          <Formik<IFormikValues>
            ref={formikRef}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            isInitialValid
            render={(formikProps) => {
              return (
                <>
                  <ModalBody>
                    <div className="row">
                      <div className="col-12">
                        <div className="form-group">
                          <label>{veldnamen.methode}</label>
                          <Field
                            name={nameof<IFormikValues>('methode')}
                            render={(fieldProps: FieldProps<IFormikValues>) => {
                              return (
                                <Combobox
                                  geselecteerd={fieldProps.field.value}
                                  opties={methodeOpties}
                                  onSelectieChange={async (value) => {
                                    fieldProps.form.setFieldValue(fieldProps.field.name, value!);
                                    if (value === null) {
                                      setVerrekeningsbedragFacturen(null);
                                      return;
                                    }

                                    await ophalenVerrekingsbedragFacturen(
                                      value,
                                      value !== EMethode.Handmatig
                                        ? props.factIDs
                                        : fieldProps.form.values.handmatigVolgordeFactIDs,
                                    );
                                  }}
                                  style={{ maxWidth: 250 }}
                                />
                              );
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <Field
                            name={nameof<IFormikValues>('handmatigVolgordeFactIDs')}
                            render={(fieldProps: FieldProps<IFormikValues>) => {
                              if (facturenBasisResult === null) {
                                return <LoadingSpinner />;
                              }

                              const factIDs = fieldProps.field.value as number[];
                              const facturen = factIDs.map(
                                (factID) => facturenBasisResult.find((x) => x.FactID === factID)!,
                              );

                              return (
                                <GridStyleWrapper maxHeight={500} rowAmount={facturen.length}>
                                  <Grid
                                    getRowId={(x) => x.FactID}
                                    rows={facturen}
                                    columns={[
                                      {
                                        name: '__dragHandle' as any,
                                        title: ' ',
                                      },
                                      {
                                        name: '__factuurnummer',
                                        title: 'Nr',
                                      },
                                      {
                                        name: '__factuurdatum',
                                        title: 'Datum',
                                      },
                                      {
                                        name: '__openstaand',
                                        title: 'Openstaand',
                                      },
                                      {
                                        name: '__afboeken',
                                        title: 'Afboeken',
                                      },
                                      {
                                        name: '__naAfboeken',
                                        title: 'Na afboeken',
                                      },
                                    ]}
                                  >
                                    <DataTypeProvider
                                      for={['__dragHandle']}
                                      formatterComponent={(formatterProps) => {
                                        return <DragHandle />;
                                      }}
                                    />
                                    <DataTypeProvider
                                      for={['__factuurdatum']}
                                      formatterComponent={(formatterProps) => {
                                        const row: IOphalenFacturenBasisResultElement =
                                          formatterProps.row;
                                        return (
                                          <span>
                                            {format(new Date(row.Factuurdatum), 'dd-MM-yyyy')}
                                          </span>
                                        );
                                      }}
                                    />
                                    <DataTypeProvider
                                      for={['__factuurnummer']}
                                      formatterComponent={(formatterProps) => {
                                        const row: IOphalenFacturenBasisResultElement =
                                          formatterProps.row;
                                        return <span>{row.Factuurnummer}</span>;
                                      }}
                                    />
                                    <DataTypeProvider
                                      for={['__openstaand']}
                                      formatterComponent={(formatterProps) => {
                                        const row: IOphalenFacturenBasisResultElement =
                                          formatterProps.row;
                                        return <FormatteerBedrag bedrag={row.Openstaand} />;
                                      }}
                                    />
                                    <DataTypeProvider
                                      for={['__afboeken']}
                                      formatterComponent={(formatterProps) => {
                                        const row: IOphalenFacturenBasisResultElement =
                                          formatterProps.row;

                                        if (verrekeningsbedragFacturen === null) {
                                          return <span />;
                                        }
                                        const verrekeningsbedragFactuur = verrekeningsbedragFacturen.resultaten.find(
                                          (x) => x.factID === row.FactID,
                                        );
                                        if (verrekeningsbedragFactuur === undefined) {
                                          return <span />;
                                        }

                                        return (
                                          <FormatteerBedrag
                                            bedrag={verrekeningsbedragFactuur.afgeboekt}
                                          />
                                        );
                                      }}
                                    />

                                    <DataTypeProvider
                                      for={['__naAfboeken']}
                                      formatterComponent={(formatterProps) => {
                                        const row: IOphalenFacturenBasisResultElement =
                                          formatterProps.row;
                                        const saldoNaAfboeken =
                                          verrekeningsbedragFacturen !== null
                                            ? verrekeningsbedragFacturen.resultaten.find(
                                                (x) => x.factID === row.FactID,
                                              )!.afgeboekt
                                            : 0;
                                        return (
                                          <FormatteerBedrag
                                            bedrag={row.Openstaand - saldoNaAfboeken}
                                          />
                                        );
                                      }}
                                    />

                                    <Table
                                      rowComponent={({ row, ...restProps }) => {
                                        const TableRow = SortableElement(Table.Row);
                                        const r: IOphalenFacturenBasisResultElement = row;
                                        return (
                                          <TableRow
                                            {...restProps}
                                            row={r}
                                            index={factIDs.indexOf(r.FactID)}
                                          />
                                        );
                                      }}
                                      bodyComponent={({ row, ...restProps }: any) => {
                                        const TableBody = SortableContainer(Table.TableBody);
                                        return (
                                          <TableBody
                                            {...restProps}
                                            onSortEnd={async (x) => {
                                              const newFactIDs = arrayMove(
                                                factIDs,
                                                x.oldIndex,
                                                x.newIndex,
                                              );
                                              fieldProps.form.setFieldValue(
                                                nameof<IFormikValues>('handmatigVolgordeFactIDs'),
                                                newFactIDs,
                                              );

                                              if (
                                                fieldProps.form.values.methode !==
                                                EMethode.Handmatig
                                              ) {
                                                fieldProps.form.setFieldValue(
                                                  nameof<IFormikValues>('methode'),
                                                  EMethode.Handmatig,
                                                );
                                              }

                                              await ophalenVerrekingsbedragFacturen(
                                                EMethode.Handmatig,
                                                newFactIDs,
                                              );
                                            }}
                                            useDragHandle
                                          />
                                        );
                                      }}
                                    />
                                    <TableColumnResizing
                                      defaultColumnWidths={[
                                        {
                                          columnName: '__dragHandle',
                                          width: 30,
                                        },
                                        {
                                          columnName: '__factuurdatum',
                                          width: 100,
                                        },
                                        {
                                          columnName: '__factuurnummer',
                                          width: 100,
                                        },
                                        {
                                          columnName: '__openstaand',
                                          width: 100,
                                        },
                                        {
                                          columnName: '__afboeken',
                                          width: 100,
                                        },
                                        {
                                          columnName: '__naAfboeken',
                                          width: 100,
                                        },
                                      ]}
                                    />
                                    <TableHeaderRow />
                                  </Grid>
                                </GridStyleWrapper>
                                // <SortableList
                                //   onSortEnd={async ({ oldIndex, newIndex }: any) => {
                                //     const newValue = arrayMove<number>(
                                //       fieldProps.field.value,
                                //       oldIndex,
                                //       newIndex,
                                //     );
                                //     fieldProps.form.setFieldValue(fieldProps.field.name, newValue);
                                //     if (fieldProps.form.values.methode !== EMethode.Handmatig) {
                                //       fieldProps.form.setFieldValue(
                                //         nameof<IFormikValues>('methode'),
                                //         EMethode.Handmatig,
                                //       );
                                //     }
                                //
                                //     await ophalenVerrekingsbedragFacturen(
                                //       EMethode.Handmatig,
                                //       newValue,
                                //     );
                                //   }}
                                //   useDragHandle
                                // >
                                //   {(fieldProps.field.value as number[]).map((value, index) => {
                                //
                                //
                                //     return (
                                //       <SortableItem key={value} index={index} value={factuur} />
                                //     );
                                //   })}
                                // </SortableList>
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </ModalBody>
                  <ModalFooter className="d-flex flex-row justify-content-start">
                    <button
                      className="btn btn-primary"
                      onClick={formikProps.submitForm}
                      style={{ width: 100 }}
                      disabled={!formikProps.isValid || formikProps.isSubmitting}
                    >
                      Ok
                    </button>
                    <button
                      className="btn btn-secondary"
                      onClick={props.onAnnuleren}
                      style={{ width: 100 }}
                      disabled={formikProps.isSubmitting}
                    >
                      Annuleren
                    </button>
                  </ModalFooter>
                </>
              );
            }}
          />
        )}
      </Dialoog>
    </VerrekenenDialoogContext.Provider>
  );
};

export default VerrekenenDialoog;
