import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { RootStoreContext } from '../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import { ERemoteDataState } from '../../../models/IRemoteData';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { genereerUrlStateQueryParam } from '../../../core/useUrlState';
import {
  defaultBankzakenBankopdrachtenUrlState,
  IBankzakenBankopdrachtenUrlState,
} from '../../kaart/entiteiten/Bankzaken/Bankopdrachten';
import { Kleur } from '../../../bedrijfslogica/constanten';
import InfoCard from './InfoCard';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

interface IProps extends RouteComponentProps {
  bankOpdID: number;
}

const BankopdrachtVisualisatie: React.FC<IProps> = observer((props) => {
  const { bankOpdrachtenStore } = useContext(RootStoreContext);

  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (bankOpdrachtenStore.opdrachten[props.bankOpdID] === undefined) {
      // noinspection JSIgnoredPromiseFromCall
      bankOpdrachtenStore.enqueueOphalenOpdrachten([props.bankOpdID]);
    }
  }, [props.bankOpdID]);

  const opdracht = useMemo(() => {
    return bankOpdrachtenStore.opdrachten[props.bankOpdID] ?? null;
  }, [props.bankOpdID, bankOpdrachtenStore.opdrachten]);

  const [showInfoCardOverlay, setShowInfoCardOverlay] = useState(false);
  const showInfoCardOverlayTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const handleMouseEnterInfoCardOverlay = useCallback(() => {
    if (showInfoCardOverlayTimeoutRef.current !== null) {
      clearTimeout(showInfoCardOverlayTimeoutRef.current);
    }
    showInfoCardOverlayTimeoutRef.current = setTimeout(() => setShowInfoCardOverlay(true), 800);
  }, []);
  const handleMouseLeaveInfoCardOverlay = useCallback(() => {
    if (showInfoCardOverlayTimeoutRef.current !== null) {
      clearTimeout(showInfoCardOverlayTimeoutRef.current);
    }
    showInfoCardOverlayTimeoutRef.current = setTimeout(() => setShowInfoCardOverlay(false), 400);
  }, []);
  const renderInfoCardOverlay = useCallback(
    (p: any) => {
      return (
        <div
          id={`bankopdracht-visualisatie-overlay-${props.bankOpdID}`}
          {...p}
          onMouseEnter={handleMouseEnterInfoCardOverlay}
          onMouseLeave={handleMouseLeaveInfoCardOverlay}
          style={{
            ...p.style,
            zIndex: 99999,
            border: `1px solid ${Kleur.LichtGrijs}`,
            borderRadius: 5,
          }}
        >
          <InfoCard bankOpdID={props.bankOpdID} />
        </div>
      );
    },
    [props.bankOpdID, handleMouseEnterInfoCardOverlay, handleMouseLeaveInfoCardOverlay],
  );

  if (opdracht === null || opdracht.state === ERemoteDataState.Pending) {
    return <span>Laden...</span>;
  }

  return (
    <OverlayTrigger
      trigger="hover"
      placement="auto-start"
      overlay={renderInfoCardOverlay}
      show={showInfoCardOverlay}
      popperConfig={
        {
          modifiers: {
            name: 'offset',
            options: {
              offset: [0, 5],
            },
          },
        } as any
      }
    >
      <a
        href="#"
        className="d-flex align-items-center"
        onMouseEnter={() => {
          setIsHovered(true);
          handleMouseEnterInfoCardOverlay();
        }}
        onMouseLeave={() => {
          setIsHovered(false);
          handleMouseLeaveInfoCardOverlay();
        }}
        onClick={(ev) => {
          ev.preventDefault();

          const urlState: IBankzakenBankopdrachtenUrlState = {
            ...defaultBankzakenBankopdrachtenUrlState,
            selectie: [opdracht.data!.BankOpdID],
          };
          const query = genereerUrlStateQueryParam(urlState);

          props.history.push(
            `/klant/${opdracht.data!.RelID}/bankzaken/bankopdrachten?state=${query}`,
          );
        }}
      >
        <span>BO-{opdracht.data!.Betalingskenmerk.split('-')[2]}</span>
      </a>
    </OverlayTrigger>
  );
});

export default withRouter(BankopdrachtVisualisatie);
