import React, { useCallback, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';
import Links from './Links';
import Rechts from './Rechts';
import { ExportMedium, RapportageType } from './types';
import { EMaand } from '../../../components/formulier/MaandSelectie';
import useUrlState from '../../../core/useUrlState';
import { dagDatum } from '../../../helpers/datum';
import api from '../../../api';
import { ExportData } from '../../../../../shared/src/api/v2/boekhouding/rapportage';
import downloadUrl from '../../../core/downloadUrl';

interface IUrlState {
  rapportageType: RapportageType;
  peildatum: Date;
  jaar: number;
  maandVem: EMaand;
  maandTem: EMaand;
  exportMedium: ExportMedium;
}

const Root = styled.div`
  display: flex;
  flex: 1;
`;

interface IProps extends RouteComponentProps {}

const Rapportage = (props: IProps) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState<IUrlState>(
    props,
    {
      rapportageType: 'OPENSTAANDE_DEBITEUREN',
      peildatum: dagDatum(new Date()),
      jaar: new Date().getFullYear(),
      maandVem: new Date().getMonth() + 1,
      maandTem: new Date().getMonth() + 1,
      exportMedium: 'PDF',
    },
    'state',
    {
      peildatum: {
        getter: (value: string) => new Date(value),
      },
    },
  );
  const [isBezig, setIsBezig] = useState(false);

  const handleExporteren = useCallback(async () => {
    setIsBezig(true);

    let data: ExportData;
    switch (urlState.rapportageType) {
      case 'OPENSTAANDE_DEBITEUREN':
        data = {
          rapportageType: urlState.rapportageType,
          peildatum: urlState.peildatum,
        };
        break;
      case 'OPENSTAANDE_CREDITEUREN':
        data = {
          rapportageType: urlState.rapportageType,
          peildatum: urlState.peildatum,
        };
        break;
      case 'GEGEVENS_TEN_BEHOEVE_VAN_JAARWERK':
        data = {
          rapportageType: urlState.rapportageType,
          jaar: urlState.jaar,
        };
        break;
      case 'LIJST_PRODUCTEN_UIT_BEDRIJF':
        data = {
          rapportageType: urlState.rapportageType,
          jaar: urlState.jaar,
          maandTem: urlState.maandTem,
          maandVem: urlState.maandVem,
        };
        break;
      case 'LIJST_ACTIVAREGISTER':
        data = {
          rapportageType: urlState.rapportageType,
          jaar: urlState.jaar,
          maandTem: urlState.maandTem,
          maandVem: urlState.maandVem,
        };
        break;
      case 'WINST_EN_VERLIES_REKENING':
        data = {
          rapportageType: urlState.rapportageType,
          jaar: urlState.jaar,
          maandTem: urlState.maandTem,
          maandVem: urlState.maandVem,
        };
        break;
    }

    const exporteerResult = await api.v2.boekhouding.rapportage.exporteer({
      exportMedium: urlState.exportMedium,
      data,
    });
    await downloadUrl(exporteerResult.bestand.url, exporteerResult.bestand.Naam);

    setIsBezig(false);
  }, [urlState]);

  return (
    <Root>
      <Links
        rapportageType={urlState.rapportageType}
        onRapportageTypeChange={(x) => setUrlStateSync('rapportageType', x)}
        peildatum={urlState.peildatum}
        onPeildatumChange={(x) => setUrlStateSync('peildatum', x)}
        jaar={urlState.jaar}
        onJaarChange={(x) => setUrlStateSync('jaar', x)}
        maandVem={urlState.maandVem}
        onMaandVemChange={(x) => setUrlStateSync('maandVem', x)}
        maandTem={urlState.maandTem}
        onMaandTemChange={(x) => setUrlStateSync('maandTem', x)}
        exportMedium={urlState.exportMedium}
        onExportMediumChange={(x) => setUrlStateSync('exportMedium', x)}
        isBezig={isBezig}
        onExporteren={handleExporteren}
      />
      <Rechts
        rapportageType={urlState.rapportageType}
        peildatum={urlState.peildatum}
        jaar={urlState.jaar}
        maandVem={urlState.maandVem}
        maandTem={urlState.maandTem}
      />
    </Root>
  );
};

export default Rapportage;
