import React, { useCallback, useMemo } from 'react';
import { Bestand, BestandType, ILokaalBestand } from '../BijlagenContainer';
import { NativeTypes } from 'react-dnd-html5-backend';
import DragAndDropTarget from './DragAndDropTarget';
import api from '../../api';

export enum EBronModus {
  Alle,
  Lokaal,
  Drive,
}

export interface IBestandstype {
  weergaveNaam: string;
  mediaType: string;
}

export enum ESelectieModus {
  Enkel,
  Meerdere,
}

export enum EOrientatie {
  Horizontaal,
  Verticaal,
}

export interface IBijlageKnopContext {
  bronModus: EBronModus;
  selectieModus: ESelectieModus;
  orientatie: EOrientatie;
  toegestaneBestandstypes?: IBestandstype[];
  onBijgevoegd: (bestanden: Bestand[]) => void;
}

export const BijlageKnopContext = React.createContext<IBijlageKnopContext>(null as any);

export interface IBijlageKnopButtonComponentProps {
  onClick: () => void;
  canDrop: boolean;
  orientatie: EOrientatie;
  disabled?: boolean;
}

interface IProps {
  onBijgevoegd: (bestanden: Bestand[]) => void;
  bronModus?: EBronModus;
  selectieModus?: ESelectieModus;
  toegestaneBestandstypes?: IBestandstype[];
  orientatie?: EOrientatie;
  disabled?: boolean;
  buttonComponent?: React.ComponentType<
    IBijlageKnopButtonComponentProps & React.RefAttributes<any>
  >;
}

const BijlageKnop: React.FC<IProps> = (props) => {
  const selectieModus = useMemo(() => props.selectieModus || ESelectieModus.Meerdere, [
    props.selectieModus,
  ]);
  const orientatie = useMemo(() => props.orientatie || EOrientatie.Horizontaal, [props.orientatie]);
  const bronModus = useMemo(() => props.bronModus || EBronModus.Alle, [props.bronModus]);

  const dragAndDropAccepteert = useMemo(() => {
    if (bronModus === EBronModus.Alle || bronModus === EBronModus.Lokaal) {
      return [NativeTypes.FILE];
    }
    return [];
  }, [bronModus]);

  const handleFileDrop = useCallback(
    (item: any) => {
      if (item) {
        const files: File[] = item.files;

        const bestanden: ILokaalBestand[] = files.map((file) => ({
          file,
          url: URL.createObjectURL(file),
          mediaType: file.type,
          naam: file.name,
          grootte: file.size,
          type: BestandType.Lokaal,
        }));

        props.onBijgevoegd(bestanden);
      }
    },
    [props.onBijgevoegd],
  );

  const contextValue = useMemo<IBijlageKnopContext>(() => {
    return {
      bronModus,
      selectieModus,
      orientatie,
      onBijgevoegd: props.onBijgevoegd,
      toegestaneBestandstypes: props.toegestaneBestandstypes,
    };
  }, [bronModus, selectieModus, props.onBijgevoegd, props.toegestaneBestandstypes]);

  return (
    <BijlageKnopContext.Provider value={contextValue}>
      <DragAndDropTarget
        accepts={dragAndDropAccepteert}
        onDrop={handleFileDrop}
        selectieModus={selectieModus}
        onBestandIDs={async (bestandIDs) => {
          const result = await api.v2.bestand.ophalenBestanden({
            filterSchema: {
              filters: [
                {
                  naam: 'IDS',
                  data: bestandIDs,
                },
              ],
            },
          });

          props.onBijgevoegd(
            result.bestanden.map((bestand) => ({
              type: BestandType.ASPDrive,
              bestand,
            })),
          );
        }}
        canDrop={(item) => {
          if (props.toegestaneBestandstypes !== undefined) {
            for (const i of item.items) {
              if (i.kind === 'file') {
                if (
                  !props.toegestaneBestandstypes.some((t) => {
                    const mediaTypeRegexStr = t.mediaType.replace('/', '\\/').replace('*', '\\w+');
                    const regex = new RegExp(mediaTypeRegexStr);
                    return regex.test(i.type);
                  })
                ) {
                  return false;
                }
              }
            }
          }
          return true;
        }}
        toegestaneBestandstypes={props.toegestaneBestandstypes}
        buttonComponent={props.buttonComponent}
        disabled={props.disabled}
      />
    </BijlageKnopContext.Provider>
  );
};

export default BijlageKnop;
