import {
  IOphalenBestellingenParams,
  IOphalenBestellingenResult,
  IVersturenBevestigingBestellingParams,
  IVersturenBevestigingBestellingResult,
} from '../../../../../../shared/src/api/v2/website/bestelling';
import api from '../../../index';

const bestelling = {
  ophalenBestellingen: async (
    params: IOphalenBestellingenParams,
  ): Promise<IOphalenBestellingenResult> => {
    return api.post('/v2/extern/bestelling/ophalen-bestellingen', params);
  },
  versturenBevestigingBestelling: async (
    params: IVersturenBevestigingBestellingParams,
  ): Promise<IVersturenBevestigingBestellingResult> => {
    return api.post('/v2/extern/bestelling/versturen-bevestiging-bestelling', params);
  },
};

export default bestelling;
