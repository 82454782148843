import React, { useCallback, useMemo } from 'react';
import { ISponsorDienst } from '../../../../../shared/src/api/v2/sponsoringv2/event';
import api from '../../../api';
import { ASPKolom, EAspKolomBreedteType } from '../../tabel/ASPTabel/types';
import MultiComboboxV2, { EnkeleProvider, IRepresentatieProps, Provider } from '../MultiComboboxV2';

export enum ESelectieKolom {
  Naam,
}

export const enkeleProvider: EnkeleProvider<number, ISponsorDienst> = async (id) => {
  const results = await api.v2.dienst.sponsoring.ophalenDiensten({
    filterSchema: {
      filters: [
        {
          naam: 'IDS',
          data: [id],
        },
      ],
    },
  });
  return results.diensten[0];
};

const StandaardRepresentatieComponent: React.ComponentType<IRepresentatieProps<ISponsorDienst>> = (
  props,
) => {
  return <span>{props.entiteit?.Naam}</span>;
};

interface IProps {
  id: number | null;
  onChange: (id: number | null) => void;
  representatieComponent?: React.ComponentType<IRepresentatieProps<ISponsorDienst>>;
}

const SponsorDienstSelectie = (props: IProps) => {
  const provider = async () => {
    const dienstenResult = await api.v2.dienst.sponsoring.ophalenDiensten({
      filterSchema: {},
      orderSchema: { orders: [{ naam: 'NAAM', richting: 'ASC' }] },
    });

    const diensten = dienstenResult.diensten.map((x) => {
      const dienst: ISponsorDienst = {
        ...x,
      };
      return dienst;
    });

    return {
      items: diensten,
      totaalAantal: diensten.length,
    };
  };

  const keyExtractor = (item: ISponsorDienst) => item?.ID ?? -1;
  const representatieComponent = useMemo(
    () => props.representatieComponent ?? StandaardRepresentatieComponent,
    [props.representatieComponent],
  );

  const kolommen = useMemo<ASPKolom<ESelectieKolom, ISponsorDienst>[]>(
    () => [
      {
        key: ESelectieKolom.Naam,
        label: 'Naam',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 400,
        renderer: (dienst) => <span>{dienst.Naam ?? dienst.relatie?.organisatie?.Naam}</span>,
      },
    ],
    [],
  );

  return (
    <MultiComboboxV2
      provider={provider}
      enkeleProvider={enkeleProvider}
      keyExtractor={keyExtractor}
      waarde={props.id}
      onChange={props.onChange}
      representatieComponent={representatieComponent}
      kolommen={kolommen}
      wisbaar
    />
  );
};

export default SponsorDienstSelectie;
