import React, { useCallback, useContext, useMemo, useState } from 'react';
import { DataTypeProvider, TableRowDetail } from '@devexpress/dx-react-grid';
import {
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../../helpers/dxTableGrid';
import {
  Grid,
  Table,
  TableColumnResizing,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-bootstrap4';
import TabelInspringBlok from '../../../../../components/layout/TabelInspringBlok';
import { IRowModel, NieuwTabbladContext } from '../../index';
import NieuwOpdrachtDialoog from '../../../../Inkoop/Opdrachten/NieuwOpdrachtDialoog';
import { IOphalenVoorraadinfoResultElement } from '../../../../../../../shared/src/api/v2/voorraad';
import VoorraadTypeInfoDialoog from '../../../../../components/transport/VoorraadTypeInfoDialoog';
import { IProducttypenResultElement } from '../../../../../../../shared/src/api/v2/aanbod/productmodel';
import { Kleur as EKleur } from '../../../../../bedrijfslogica/constanten';
// import { IOphalenProducttypenResultElement } from '../../../../../../../shared/src/api/v2/product/type';

// interface IRij {
//   TypeID: number;
//   PrioNr: number;
//   Typenaam: string;
//   Merknaam: string;
//   InkoopToegestaan: boolean;
// }

interface INieuwOpdrachtDialoogState {
  open: boolean;
  typeID?: number;
  prodSrtID?: number;
}

export interface IVoorraadTypeInfoDialoogState {
  typeID: number;
  magID: number;
}

const DetailComp = (props: TableRowDetail.ContentProps) => {
  const row: IRowModel = props.row;
  const voorraadinfo: IOphalenVoorraadinfoResultElement[] = row.voorraadinfo;

  const [
    voorraadTypeInfoDialoogState,
    setVoorraadTypeInfoDialoogState,
  ] = useState<IVoorraadTypeInfoDialoogState | null>(null);

  const { onVerversenAangevraagd } = useContext(NieuwTabbladContext);

  /**
   * Alleen types weergeven
   * - waarvoor voorraad is of
   * - waarvoor een inkoopopdracht aanwezig is
   */

  const typeIDsMetVoorraad = voorraadinfo
    .filter((x) => x.aantalInkoop !== 0 || x.aantalVoorraad !== 0)
    .map((x) => x.TypeID);

  const producttypenResult = row.producttypen;
  const producttypen = producttypenResult.filter(
    (x) => typeIDsMetVoorraad.indexOf(x.TypeID) !== -1 || x.InkoopToegestaan,
  );

  const [nieuwOpdrachtDialoogState, setNieuwOpdrachtDialoogState] = useState<
    INieuwOpdrachtDialoogState
  >({
    open: false,
  });

  const kolommen = useMemo<TypedColumn<IProducttypenResultElement>[]>(
    () => [
      {
        name: 'PrioNr',
        title: 'Prio.',
      },
      {
        name: 'Merknaam',
        title: 'Merk',
      },
      {
        name: 'Typenaam',
        title: 'Type',
      },
      {
        name: '__productsoort' as any,
        title: 'Srt.',
      },
      {
        name: '__voorraadInfo' as any,
        title: ' ',
      },
      {
        name: '__inkoopActie' as any,
        title: ' ',
      },
      {
        name: '__voorraadVrij' as any,
        title: 'Vrij',
      },
      {
        name: '__voorraad' as any,
        title: 'Tot. Vrd.',
      },
      {
        name: '__voorraadUitgepaktVrij' as any,
        title: 'Uitgepakt Vrij',
      },
      {
        name: '__voorraadUitgepaktTotaal' as any,
        title: 'Uitgepakt Tot.',
      },
      {
        name: '__inBestelling' as any,
        title: 'In best.',
      },
    ],
    [],
  );
  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IProducttypenResultElement>[]>(
    () => [
      {
        columnName: 'PrioNr',
        width: 75,
      },
      {
        columnName: 'Merknaam',
        width: 125,
      },
      {
        columnName: 'Typenaam',
        width: 200,
      },
      {
        columnName: '__productsoort' as any,
        width: 100,
      },
      {
        columnName: '__voorraadInfo' as any,
        width: 75,
      },
      {
        columnName: '__voorraad' as any,
        width: 100,
      },
      {
        columnName: '__voorraadUitgepaktTotaal' as any,
        width: 125,
      },
      {
        columnName: '__voorraadUitgepaktVrij' as any,
        width: 125,
      },
      {
        columnName: '__voorraadVrij' as any,
        width: 90,
      },
      {
        columnName: '__inBestelling' as any,
        width: 100,
      },
      {
        columnName: '__inkoopActie' as any,
        width: 125,
      },
    ],
    [],
  );

  const keyExtractor = useCallback((producttype: any) => producttype.TypeID, []);

  // const kolomExtensies: VirtualTableBase.ColumnExtension[] = useMemo(() => {
  //   return [
  //     {
  //       columnName: `Bedrag`,
  //       align: 'right',
  //     },
  //   ];
  // }, []);

  return (
    <>
      <div className="d-flex">
        <TabelInspringBlok />
        <GridStyleWrapper maxHeight={500} rowAmount={producttypen.length}>
          <Grid rows={producttypen} columns={kolommen} getRowId={keyExtractor}>
            <DataTypeProvider
              for={['__productsoort']}
              formatterComponent={(formatterProps) => {
                const rij: IProducttypenResultElement = formatterProps.row;

                return (
                  <span
                    style={{
                      color: rij.productsoort.ProdSrtID !== row.ProdSrtID ? EKleur.Rood : undefined,
                    }}
                  >
                    {rij.productsoort.NaamKort}
                  </span>
                );
              }}
            />

            <DataTypeProvider
              for={['__inkoopActie']}
              formatterComponent={(formatterProps) => {
                const rij: IProducttypenResultElement = formatterProps.row;

                if (!rij.InkoopToegestaan) {
                  return <span></span>;
                }

                return (
                  <a
                    href="#"
                    onClick={() =>
                      setNieuwOpdrachtDialoogState({
                        open: true,
                        typeID: rij.TypeID,
                        prodSrtID: rij.productsoort.ProdSrtID,
                      })
                    }
                  >
                    Ink.opd.
                  </a>
                );
              }}
            />

            <DataTypeProvider
              for={['__voorraadInfo']}
              formatterComponent={(formatterProps) => {
                const rij: IProducttypenResultElement = formatterProps.row;

                return (
                  <div className="d-flex">
                    <a
                      href="#"
                      onClick={() => {
                        setVoorraadTypeInfoDialoogState({
                          typeID: rij.TypeID,
                          magID: 4, // TODO rij.magazijn.MagID,
                        });
                      }}
                    >
                      {/* <VoorraadIndicatie
                            status={gereserveerd ? EVoorraadStatus.Groen : EVoorraadStatus.Rood}
                          /> */}
                      <span>Vrd.info</span>
                    </a>
                  </div>
                );
              }}
            />

            <DataTypeProvider
              for={['__voorraad']}
              formatterComponent={(formatterProps) => {
                const rij: IProducttypenResultElement = formatterProps.row;
                const voorraadVoorType = voorraadinfo.find((x) => x.TypeID === rij.TypeID);
                const voorraad = voorraadVoorType !== undefined ? voorraadVoorType : null;
                return <span>{voorraad !== null ? voorraad.aantalVoorraad : ''}</span>;
              }}
            />

            <DataTypeProvider
              for={['__voorraadUitgepaktTotaal']}
              formatterComponent={(formatterProps) => {
                const rij: IProducttypenResultElement = formatterProps.row;
                const voorraadUitgepaktVoorType = voorraadinfo.find((x) => x.TypeID === rij.TypeID);
                const voorraad =
                  voorraadUitgepaktVoorType !== undefined ? voorraadUitgepaktVoorType : null;
                return (
                  <span>{voorraad !== null ? voorraad.aantalVoorraadUitgepaktTotaal : ''}</span>
                );
              }}
            />

            <DataTypeProvider
              for={['__voorraadUitgepaktVrij']}
              formatterComponent={(formatterProps) => {
                const rij: IProducttypenResultElement = formatterProps.row;
                const voorraadUitgepaktVoorType = voorraadinfo.find((x) => x.TypeID === rij.TypeID);
                const voorraad =
                  voorraadUitgepaktVoorType !== undefined ? voorraadUitgepaktVoorType : null;
                return <span>{voorraad !== null ? voorraad.aantalVoorraadUitgepaktVrij : ''}</span>;
              }}
            />

            <DataTypeProvider
              for={['__voorraadVrij']}
              formatterComponent={(formatterProps) => {
                const rij: IProducttypenResultElement = formatterProps.row;
                const voorraadVoorType = voorraadinfo.find((x) => x.TypeID === rij.TypeID);
                const voorraad = voorraadVoorType !== undefined ? voorraadVoorType : null;
                return (
                  <span>
                    {voorraad !== null && voorraad.aantalVrij !== 0 ? voorraad.aantalVrij : ''}
                  </span>
                );
              }}
            />

            <DataTypeProvider
              for={['__inBestelling']}
              formatterComponent={(formatterProps) => {
                const rij: IProducttypenResultElement = formatterProps.row;

                const voorraadVoorType = voorraadinfo.find((x) => x.TypeID === rij.TypeID);
                const voorraad = voorraadVoorType !== undefined ? voorraadVoorType : null;
                const aantalInBestelling = voorraad !== null ? voorraad.aantalInkoop : null;

                return (
                  <span>
                    {aantalInBestelling !== null && aantalInBestelling !== 0
                      ? aantalInBestelling
                      : ''}
                  </span>
                );
              }}
            />

            <Table />
            <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
            {/* <TableHeaderRow showSortingControls /> */}
            <TableHeaderRow />
          </Grid>
        </GridStyleWrapper>
      </div>

      {nieuwOpdrachtDialoogState.open && (
        <NieuwOpdrachtDialoog
          prodSrtID={nieuwOpdrachtDialoogState.prodSrtID ?? null}
          typeID={nieuwOpdrachtDialoogState.typeID}
          open
          onSuccess={async () => {
            await onVerversenAangevraagd();
            setNieuwOpdrachtDialoogState({
              open: false,
            });
          }}
          onAnnuleren={() => {
            setNieuwOpdrachtDialoogState({
              open: false,
            });
          }}
        />
      )}
      {voorraadTypeInfoDialoogState !== null && (
        <VoorraadTypeInfoDialoog
          open
          typeID={voorraadTypeInfoDialoogState.typeID}
          magID={voorraadTypeInfoDialoogState.magID}
          onSuccess={() => setVoorraadTypeInfoDialoogState(null)}
          onAnnuleren={() => setVoorraadTypeInfoDialoogState(null)}
        />
      )}
    </>
  );
};

export default DetailComp;
