import {
  DataTypeProvider,
  EditingState,
  IntegratedSorting,
  RowDetailState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import toast from 'react-hot-toast';
import { RouteComponentProps, withRouter } from 'react-router';
import api from '../../../../api';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import {
  EFunctioneleIcon,
  functioneleIconMap,
  IconKruis,
  IconVerwijderen,
} from '../../../../components/Icons';
import MenuLayout from '../../../../components/MenuLayout';
import nameOf from '../../../../core/nameOf';
import useUrlState from '../../../../core/useUrlState';
import {
  DXCommandComponent,
  DXTableCheckboxComponent,
  DXTableToggleCellComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import FilterBalkV2, {
  IFilter,
  IFilterData,
  maakFilterSchema,
} from '../../../../components/FilterBalkV2';
import {
  IOphalenAlternatieveProductmodellenResultElement,
  IOphalenProductmodellenResultElement,
} from '../../../../../../shared/src/api/v2/aanbod/productmodel';
import { EContractStatus } from '../../../Klant/Klantkaart/Entiteiten/entiteiten/Contracten/Overzicht/ContractTegel/ContractStatus';
import Combobox from '../../../../components/formulier/Combobox';
import { IFilterSchemaFilter } from '../../../../../../shared/src/models/filter';
import DetailComp from './DetailComp';
import _ from 'lodash';

interface IProps extends RouteComponentProps {}

enum EFilter {
  // Actief = 'ACTIEF',
  StatusActief = 'STATUS_ACTIEF',
  MetLopendeContracten = 'PRODUCTMODEL_IN_LOPENDE_CONTRACTEN',
  HeeftActiefAlternatiefProductmodel = 'HEEFT_ACTIEF_ALTERNATIEF_PRODUCTMODEL',
}

interface IWijzigenDialoogState {
  id: number;
}

interface IToevoegenDialoogState {}

interface IUrlState {
  selectie: number[];
  // wijzigDialoogState: IWijzigenDialoogState | null;
  // toevoegenDialoogState: IToevoegenDialoogState | null;
  filterdata: IFilterData<EFilter>[];
}
const defaultUrlState: IUrlState = {
  selectie: [],
  // wijzigDialoogState: null,
  // toevoegenDialoogState: null,
  filterdata: [
    // {
    //   naam: EFilter.Actief,
    //   data: true,
    //   isActief: true,
    // },
    {
      naam: EFilter.StatusActief,
      isActief: true,
      data: 1,
    },
    {
      naam: EFilter.MetLopendeContracten,
      isActief: true,
      data: true,
    },
    {
      naam: EFilter.HeeftActiefAlternatiefProductmodel,
      isActief: false,
      data: false,
    },
  ],
};

const messages = {
  noData: 'Geen productmodellen',
};

const IconToevoegen = functioneleIconMap[EFunctioneleIcon.Toevoegen];

export interface IProductmodel extends IOphalenProductmodellenResultElement {
  alternatieven: IOphalenAlternatieveProductmodellenResultElement[];
  cntIDs: number[];
}

const Alternatieven: React.FC<IProps> = (props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const [filterSchema, setFilterSchema] = useState(maakFilterSchema(urlState.filterdata));

  // const { checkStore } = useContext(RootStoreContext);
  // const globaleRenderer = useContext(GlobaleRendererContext);

  // const [alternatieven, setAlternatieven] = useState<
  //   IOphalenAlternatieveProductmodellenResultElement[] | null
  // >(null);

  const [productmodellen, setProductmodellen] = useState<IProductmodel[] | null>(null);

  // const [productsoorten, setProductsoorten] = useState<IRegel[] | null>(null);

  const ophalenProductmodellen = useCallback(async () => {
    const actiefFilter = urlState.filterdata.find((x) => x.naam === 'STATUS_ACTIEF')!;
    const metLopendeContractenFilter = urlState.filterdata.find(
      (x) => x.naam === 'PRODUCTMODEL_IN_LOPENDE_CONTRACTEN',
    )!;

    const ophalenProductmodellenResult = await api.v2.product.model.ophalenProductmodellen({
      filterSchema: {
        filters: [
          ...filterSchema.filters!,
          actiefFilter.isActief
            ? { naam: 'ACTIEF', data: actiefFilter.data === 1 ? true : false }
            : null,
          metLopendeContractenFilter.isActief
            ? { naam: 'PRODUCTMODEL_IN_LOPENDE_CONTRACTEN', data: true }
            : null,
        ].filter((x) => x !== null) as IFilterSchemaFilter[],
      },
    });
    const prodModIDs = ophalenProductmodellenResult.modellen.map((x) => x.ProdModID);

    const contracten = (
      await api.v2.contract.ophalenContractenV4({
        filterSchema: {
          filters: [
            { naam: 'PRODMOD_IDS', data: prodModIDs },
            { naam: 'STATUS_NAAM_ENUMS', data: [EContractStatus.Lopend] },
          ],
        },
      })
    ).contracten;

    // const contracten: any[] = [];

    const ophalenAlternatieveProductmodellenResult = await api.v2.product.model.ophalenAlternatieveProductmodellen(
      {
        filterSchema: {
          filters: [{ naam: 'PRODMOD_IDS', data: prodModIDs }],
        },
      },
    );

    const ophalenAlternatieveProductmodellenDataResult = await api.v2.product.model.ophalenProductmodellen(
      {
        filterSchema: {
          filters: [
            {
              naam: 'IDS',
              data: _.uniq(
                ophalenAlternatieveProductmodellenResult.productmodellen.map(
                  (x) => x.Alternatief_ProdModID,
                ),
              ),
            },
          ],
        },
      },
    );

    const result = ophalenProductmodellenResult.modellen.map((model) => {
      const alternatieveProductmodellen = ophalenAlternatieveProductmodellenResult.productmodellen
        .filter((x) => x.ProdModID === model.ProdModID)
        .map((x) => {
          const alternatiefModel = ophalenAlternatieveProductmodellenDataResult.modellen.find(
            (m) => m.ProdModID === x.Alternatief_ProdModID,
          )!;

          return { ...x, Actief: alternatiefModel.Actief };
        });

      const cntIDs = contracten
        .filter((x) => x.basis.ProdModID === model.ProdModID)
        .map((x) => x.CntID);

      return { ...model, alternatieven: alternatieveProductmodellen, cntIDs };
    });

    setProductmodellen(result);
  }, [urlState.filterdata, JSON.stringify(filterSchema.filters)]);

  useEffect(() => {
    ophalenProductmodellen();
  }, [ophalenProductmodellen]);

  const keyExtractor = useCallback((row: IProductmodel) => row.ProdModID, []);

  const kolommen = useMemo<TypedColumn<IProductmodel>[]>(
    () => [
      { name: 'Modelcode', title: 'Code' },
      { name: 'Modelnaam', title: 'Naam' },
      { name: 'Kenmerk', title: 'Kenmerk' },
      { name: 'Actief', title: 'Actief' },

      { name: '__aantalContracten' as any, title: '# Lopende cnt.' },
      { name: '__heeftActiefModel' as any, title: 'Heeft actief alternatief' },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IProductmodel>[]>(
    () => [
      {
        columnName: 'Modelcode',
        width: 135,
      },
      {
        columnName: 'Modelnaam',
        width: 275,
      },
      {
        columnName: 'Kenmerk',
        width: 350,
      },
      {
        columnName: 'Actief',
        width: 80,
      },
      {
        columnName: '__aantalContracten' as any,
        width: 150,
      },
      {
        columnName: '__heeftActiefModel' as any,
        width: 200,
      },
    ],
    [],
  );

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.MetLopendeContracten,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return <span>Alleen met lopende contracten</span>;
        },
      },
      {
        naam: EFilter.StatusActief,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return (
            <div className="d-flex align-items-center">
              <span className="mr-2">Status</span>
              <Combobox
                geselecteerd={weergaveProps.data}
                opties={[
                  {
                    id: 1,
                    label: 'Actief',
                  },
                  {
                    id: 2,
                    label: 'Niet actief',
                  },
                ]}
                onSelectieChange={(x) => {
                  weergaveProps.onDataChange(x!);
                  weergaveProps.setIsActief(true);
                  weergaveProps.toepassen();
                }}
              />
            </div>
          );
        },
      },
      {
        naam: EFilter.HeeftActiefAlternatiefProductmodel,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return <span>Zonder actief alternatief</span>;
        },
      },
    ],
    [],
  );

  return (
    <>
      <Helmet>
        <title>Alternatieve modellen</title>
      </Helmet>
      <MenuLayout
        menu={
          <>
            <div className="d-flex">
              <div className="d-flex align-items-center">
                {/* <button
                className="btn btn-sm btn-light d-flex align-items-center"
                style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                onClick={() => setUrlStateSync('toevoegenDialoogState', {})}
              >
                <span>
                  <IconToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                </span>
                <span className="ml-2">Nieuwe productsoort</span>
              </button> */}

                <div className="flex-fill">
                  <FilterBalkV2
                    filters={filters}
                    filterData={urlState.filterdata}
                    onFilterDataChange={(x) => setUrlStateSync('filterdata', x)}
                    onFilterSchemaChange={(x) => setFilterSchema(x)}
                  />
                </div>
              </div>
            </div>
          </>
        }
        body={
          productmodellen === null ? (
            <div className="flex-fill d-flex align-items-center justify-content-center">
              <LoadingSpinner />
            </div>
          ) : (
            <GridStyleWrapper height="calc(100vh - 150px)">
              <Grid getRowId={keyExtractor} rows={productmodellen} columns={kolommen}>
                <DataTypeProvider
                  for={[nameOf<IProductmodel>('Actief')]}
                  formatterComponent={(formatterProps) => {
                    return <span>{formatterProps.value ? 'Ja' : 'Nee'}</span>;
                  }}
                />

                <DataTypeProvider
                  for={['__aantalContracten']}
                  formatterComponent={(formatterProps) => {
                    const rij: IProductmodel = formatterProps.row;
                    return <span>{rij.cntIDs.length}</span>;
                  }}
                />

                <DataTypeProvider
                  for={['__heeftActiefModel']}
                  formatterComponent={(formatterProps) => {
                    const rij: IProductmodel = formatterProps.row;
                    const heeftActiefAlternatief =
                      rij.alternatieven.filter((x) => x.Actief).length !== 0 ? true : false;
                    return <span>{heeftActiefAlternatief ? 'Ja' : 'Nee'}</span>;
                  }}
                />

                {/* <DataTypeProvider
                  for={[nameOf<IProductmodel>('RecordToegevoegd'), nameOf<IRegel>('RecordGewijzigd')]}
                  formatterComponent={(formatterProps) => {
                    if (formatterProps.value === null) {
                      return <span></span>;
                    }
                    return (
                      <span>{format(new Date(formatterProps.value), 'dd-MM-yyyy HH:mm')}</span>
                    );
                  }}
                /> */}
                <EditingState
                  onAddedRowsChange={() => {}}
                  onEditingRowIdsChange={(x) => {
                    const id = x[x.length - 1] as number;
                    // setUrlStateSync('wijzigDialoogState', { id });
                  }}
                  onCommitChanges={async (changes) => {
                    if (changes.deleted === undefined) {
                      return;
                    }
                    const deleted = changes.deleted;
                    const id = deleted[deleted.length - 1] as number;
                    // await handleVerwijderen(id);
                  }}
                />
                <RowDetailState defaultExpandedRowIds={[]} />
                <SortingState defaultSorting={[]} />
                <IntegratedSorting />
                <VirtualTable messages={messages} />
                <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
                <TableHeaderRow showSortingControls />
                <TableEditColumn
                  width={35}
                  // showAddCommand={true}
                  showEditCommand
                  commandComponent={DXCommandComponent}
                />
                <TableRowDetail
                  contentComponent={DetailComp}
                  toggleCellComponent={DXTableToggleCellComponent}
                />
                <SelectionState
                  selection={urlState.selectie}
                  onSelectionChange={(x) => setUrlStateSync('selectie', x as number[])}
                />
                <TableSelection cellComponent={DXTableCheckboxComponent} />
              </Grid>
            </GridStyleWrapper>
          )
        }
      />
      {/* {urlState.wijzigDialoogState !== null && (
        <WijzigenDialoog
          open
          id={urlState.wijzigDialoogState.id}
          onSuccess={() => {
            ophalenProductsoorten();
            setUrlStateSync('wijzigDialoogState', null);
          }}
          onAnnuleren={() => [setUrlStateSync('wijzigDialoogState', null)]}
        />
      )}
      {urlState.toevoegenDialoogState !== null && (
        <ToevoegenDialoog
          open
          onSuccess={() => {
            ophalenProductsoorten();
            setUrlStateSync('toevoegenDialoogState', null);
          }}
          onAnnuleren={() => [setUrlStateSync('toevoegenDialoogState', null)]}
        />
      )} */}
    </>
  );
};

export default withRouter(Alternatieven);
