import { TableRowDetail } from '@devexpress/dx-react-grid-bootstrap4';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import GegevensLayout from '../../../layout/GegevensLayout';
import TabelInspringBlok from '../../../layout/TabelInspringBlok';
import VerticaleScheidingslijn from '../../../layout/VerticaleScheidingslijn';
import {
  IDataMijnGegevens,
  IOphalenMutatiesResultElement,
} from '../../../../../../shared/src/api/v2/relatie/mutatie';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import { RootStoreContext } from '../../../../stores/RootStore';
import { EResultType } from '../../../../stores/CheckStore';
import api from '../../../../api';
import { IconVernieuwen } from '../../../Icons';
import { TableRowDetailWrapper } from '../../../../helpers/dxTableGrid';
import { format } from 'date-fns';
import { IOphalenLandenVoorLijstResult } from '../../../../../../shared/src/api/v2/land';
import LoadingSpinner from '../../../Gedeeld/LoadingSpinner';
import { IOphalenTalenResult } from '../../../../../../shared/src/api/v2/taal';

interface IProps extends TableRowDetail.ContentProps {}

const DetailComp: React.FC<IProps> = (props) => {
  const { checkStore } = useContext(RootStoreContext);

  const row: IOphalenMutatiesResultElement = props.row;

  const handleHerstellen = useCallback(async (id: number, set: 'O' | 'N') => {
    if (
      (
        await checkStore.bevestigen({
          inhoud: 'Deze gegevens terugzetten?',
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }
    await api.v2.relatie.herstellenData({ id, set });

    //   props.onRequestRefresh();
  }, []);

  const [landen, setLanden] = useState<IOphalenLandenVoorLijstResult | null>(null);
  const [talenResult, setTalenResult] = useState<IOphalenTalenResult | null>(null);

  useEffect(() => {
    (async () => {
      const data = await api.v2.land.ophalen({});
      setLanden(data);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const data = await api.v2.taal.ophalen({});
      setTalenResult(data);
    })();
  }, []);

  const { oud, nieuw } = row.Data;

  const genereerTableEntries = useCallback(
    (
      naam: string,
      extractor: (x: IDataMijnGegevens) => any,
      moetVolledigKleurenBijWijziging: boolean = false,
    ) => {
      const oudValue = extractor(oud).toString();
      const nieuwValue = extractor(nieuw).toString();

      const isGewijzigd = oudValue !== nieuwValue;

      let oudView: JSX.Element[] | JSX.Element | string = oudValue;
      let nieuwView: JSX.Element[] | JSX.Element | string = nieuwValue;

      if (isGewijzigd) {
        if (moetVolledigKleurenBijWijziging) {
          oudView = <span style={{ color: Kleur.Rood }}>{oudValue}</span>;
          nieuwView = <span style={{ color: Kleur.Groen }}>{nieuwValue}</span>;
        } else {
          const oudArr: string[] = Array.from(oudValue);
          const nieuwArr: string[] = Array.from(nieuwValue);

          const oudArrOut = oudArr.map((char, i) => {
            const nieuwArrChar = nieuwArr[i];
            return char !== nieuwArrChar;
          });
          const nieuwArrOut = nieuwArr.map((char, i) => {
            const oudArrChar = oudArr[i];
            return char !== oudArrChar;
          });

          oudView = oudArr.map((x, i) => (
            <span key={i} style={{ color: oudArrOut[i] ? Kleur.Rood : undefined }}>
              {x}
            </span>
          ));
          nieuwView = nieuwArr.map((x, i) => (
            <span key={i} style={{ color: nieuwArrOut[i] ? Kleur.Groen : undefined }}>
              {x}
            </span>
          ));
        }
      }

      return (
        <>
          <td>
            <span style={{ fontWeight: isGewijzigd ? 'bold' : undefined }}>{naam}</span>:
          </td>
          <td>{oudView}</td>
          <td>{nieuwView}</td>
        </>
      );
    },
    [oud, nieuw],
  );

  return (
    <TableRowDetailWrapper>
      <div className="d-flex">
        <TabelInspringBlok />
        {landen === null || talenResult === null ? (
          <LoadingSpinner />
        ) : (
          <table>
            <thead>
              <tr>
                <td style={{ width: 180 }} />

                <td style={{ minWidth: 235 }}>Oud</td>
                <td style={{ minWidth: 235 }}>Nieuw</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                {genereerTableEntries(
                  'Geslacht',
                  (x) => {
                    const geslacht =
                      x.persoon.geslacht === 1
                        ? 'Man'
                        : x.persoon.geslacht === 2
                        ? 'Vrouw'
                        : 'Onbekend';
                    return x.persoon.geslacht !== null ? geslacht : '';
                  },
                  true,
                )}
              </tr>
              <tr>
                {genereerTableEntries('Voornaam', (x) =>
                  x.persoon.voornaam !== null ? x.persoon.voornaam : '',
                )}
              </tr>
              <tr>
                {genereerTableEntries('Achternaam', (x) =>
                  x.persoon.achternaam !== null ? x.persoon.achternaam : '',
                )}
              </tr>
              <tr>
                {genereerTableEntries('Voorletters', (x) =>
                  x.persoon.voorletters !== null ? x.persoon.voorletters : '',
                )}
              </tr>
              <tr>
                {genereerTableEntries('Voorvoegsel', (x) =>
                  x.persoon.voorvoegsel !== null ? x.persoon.voorvoegsel : '',
                )}
              </tr>
              <tr>
                {genereerTableEntries('Email', (x) =>
                  x.persoon.email !== null ? x.persoon.email : '',
                )}
              </tr>
              <tr>
                {genereerTableEntries('TelefoonMobiel', (x) =>
                  x.persoon.telefoonMobiel !== null ? x.persoon.telefoonMobiel : '',
                )}
              </tr>
              <tr>
                {genereerTableEntries('Geboortedatum', (x) =>
                  x.persoon.geboortedatum !== null
                    ? format(new Date(x.persoon.geboortedatum), 'dd-MM-yyyy')
                    : '',
                )}
              </tr>
              <tr>
                {genereerTableEntries('Geboorteplaats', (x) =>
                  x.persoon.geboorteplaats !== null ? x.persoon.geboorteplaats : '',
                )}
              </tr>
              <tr>{genereerTableEntries('Straatnaam', (x) => x.relatie.adres.straatnaam)}</tr>
              <tr>{genereerTableEntries('Huisnummer', (x) => x.relatie.adres.huisnummer)}</tr>
              <tr>
                {genereerTableEntries('Bisnummer', (x) =>
                  x.relatie.adres.bisnummer === null ? '' : x.relatie.adres.bisnummer,
                )}
              </tr>
              <tr>{genereerTableEntries('Postcode', (x) => x.relatie.adres.postcode)}</tr>
              <tr>{genereerTableEntries('Plaatsnaam', (x) => x.relatie.adres.plaatsnaam)}</tr>
              <tr>
                {genereerTableEntries(
                  'Land',
                  (x) => landen.find((l) => l.LandID === x.relatie.adres.landID)!.Naam,
                  true,
                )}
              </tr>
              <tr>
                {genereerTableEntries('IBAN', (x) => (x.rekening !== null ? x.rekening.iban : ''))}
              </tr>
              <tr>
                {genereerTableEntries('Rekeningnaam', (x) =>
                  x.rekening !== null ? x.rekening.rekeningnaam : '',
                )}
              </tr>
              <tr>
                {genereerTableEntries(
                  'Betaaldag',
                  (x) =>
                    x.debiteur.betaaldag === 0
                      ? 'Standaard'
                      : x.debiteur.betaaldag === -1
                      ? 'Stufidag'
                      : x.debiteur.betaaldag.toString() + 'e',
                  true,
                )}
              </tr>
              <tr>
                {genereerTableEntries(
                  'Taal',
                  (x) => talenResult.find((t) => t.TaalID === x.persoon.taalID)!.Naam,
                  true,
                )}
              </tr>
              <tr>
                {genereerTableEntries('Gebruikersnaam', (x) =>
                  x.account !== null && x.account !== undefined ? x.account.gebruikersnaam : '',
                )}
              </tr>
            </tbody>
          </table>
        )}
      </div>
    </TableRowDetailWrapper>
  );
};

export default DetailComp;
