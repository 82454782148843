import api from '..';
import {
  IOphalenVerkopenParams,
  IOphalenVerkopenResult,
  IVerwijderenVerkoopopdrachtenParams,
  IVerwijderenVerkoopopdrachtenResult,
  IToevoegenVerkoopopdrachtenParams,
  IToevoegenVerkoopopdrachtenResult,
} from '../../../../shared/src/api/v2/verkoop/verkoop';
import ICheckData from '../../../../shared/src/models/ICheckData';

const verkoop = {
  ophalenVerkopen: async (params: IOphalenVerkopenParams) => {
    return await api.post<IOphalenVerkopenResult>('v2/verkoop/ophalen-verkopen', params);
  },
  checkVerwijderenVerkoopopdrachten: async (params: IVerwijderenVerkoopopdrachtenParams) => {
    return await api.post<ICheckData>('v2/verkoop/check-verwijderen-verkoopopdrachten', params);
  },
  verwijderenVerkoopopdrachten: async (params: IVerwijderenVerkoopopdrachtenParams) => {
    return await api.post<IVerwijderenVerkoopopdrachtenResult>(
      'v2/verkoop/verwijderen-verkoopopdrachten',
      params,
    );
  },
  toevoegenVerkoopopdrachten: async (params: IToevoegenVerkoopopdrachtenParams) => {
    return await api.post<IToevoegenVerkoopopdrachtenResult>(
      'v2/verkoop/toevoegen-verkoopopdrachten',
      params,
    );
  },
};

export default verkoop;
