import React, { useCallback, useMemo } from 'react';
import {
  DataTypeProvider,
  TableRowDetail,
  VirtualTable as VirtualTableBase,
} from '@devexpress/dx-react-grid';
import {
  IOphalenFacturenBasisResultElement,
  IRegel,
} from '../../../../../../../../../../shared/src/api/v2/factuur';
import {
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../../../../../helpers/dxTableGrid';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import FormatteerBedrag from '../../../../../../../../components/MutatieBedrag';
import { Kleur } from '../../../../../../../../bedrijfslogica/constanten';
import { format } from 'date-fns';

type IProps = TableRowDetail.ContentProps;

const RegelsDetailComp: React.FC<IProps> = (props) => {
  const row: IOphalenFacturenBasisResultElement = props.row;

  const keyExtractor = useCallback((regel: IRegel) => regel.FactRegID, []);

  const kolommen = useMemo<TypedColumn<IRegel>[]>(
    () => [
      {
        name: 'Omschrijving',
        title: 'Omschrijving',
      },
      {
        name: 'Bedrag',
        title: 'Bedrag',
      },
      {
        name: '__contractnummer' as any,
        title: 'Cnt.nr',
      },
      {
        name: '__periodeVan' as any,
        title: 'Van',
      },
      {
        name: '__periodeTot' as any,
        title: 'Tot',
      },
      {
        name: '__locatie' as any,
        title: 'Locatie',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRegel>[]>(
    () => [
      {
        columnName: 'Omschrijving',
        width: 375,
      },
      {
        columnName: 'Bedrag',
        width: 100,
      },
      {
        columnName: '__periodeVan' as any,
        width: 100,
      },
      {
        columnName: '__periodeTot' as any,
        width: 100,
      },
      {
        columnName: '__contractnummer' as any,
        width: 75,
      },
      {
        columnName: '__locatie' as any,
        width: 225,
      },
    ],
    [],
  );

  const kolomExtensies: VirtualTableBase.ColumnExtension[] = useMemo(() => {
    return [
      {
        columnName: `Bedrag`,
        align: 'right',
      },
    ];
  }, []);

  return (
    <div className="pl-5" style={{ backgroundColor: Kleur.LichtGrijs }}>
      <div style={{ backgroundColor: Kleur.Wit }}>
        <GridStyleWrapper maxHeight={500} rowAmount={row.regels.length}>
          <Grid rows={row.regels} columns={kolommen} getRowId={keyExtractor}>
            <DataTypeProvider
              for={['__periodeVan']}
              formatterComponent={(props) => {
                const van =
                  props.row.periodeVan !== null
                    ? format(new Date(props.row.periodeVan), 'dd-MM-yyyy')
                    : '';
                return <span>{van}</span>;
              }}
            />
            <DataTypeProvider
              for={['__periodeTot']}
              formatterComponent={(props) => {
                const tot =
                  props.row.periodeTot !== null
                    ? format(new Date(props.row.periodeTot), 'dd-MM-yyyy')
                    : '';
                return <span>{tot}</span>;
              }}
            />

            <DataTypeProvider
              for={['__contractnummer']}
              formatterComponent={(props) => {
                const cntNr =
                  props.row.contract !== null
                    ? props.row.contract.Basisnummer + '.' + props.row.contract.Volgnummer
                    : '';
                return <span>{cntNr}</span>;
              }}
            />

            <DataTypeProvider
              for={['__locatie']}
              formatterComponent={(props) => {
                const contract = props.row.contract !== null ? props.row.contract : null;
                const locatie =
                  contract !== null
                    ? contract.locatie.Straatnaam +
                      ' ' +
                      contract.locatie.Huisnummer +
                      (contract.locatie.Bisnummer !== null ? ' ' + contract.locatie.Bisnummer : '')
                    : '';
                return <span>{locatie}</span>;
              }}
            />

            <DataTypeProvider
              for={['Bedrag']}
              formatterComponent={(props) => (
                <FormatteerBedrag bedrag={props.value} rechtsUitlijnen />
              )}
            />
            <VirtualTable columnExtensions={kolomExtensies} />
            <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
            <TableHeaderRow />
          </Grid>
        </GridStyleWrapper>
      </div>
    </div>
  );
};

export default RegelsDetailComp;
