import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  DataTypeProvider,
  EditingState,
  IntegratedSorting,
  SelectionState,
  SortingState,
  TableRowDetail,
} from '@devexpress/dx-react-grid';
import {
  DXCommandComponent,
  DXTableCheckboxComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../../../../helpers/dxTableGrid';
import {
  Grid,
  Table,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import { VirtualTable as VirtualTableBase } from '@devexpress/dx-react-grid';
import { format } from 'date-fns';
import { IOphalenOpdrachtenResultElement } from '../../../../../../../../../shared/src/api/v2/service/opdracht';
import { IOphalenWerkbonnenResultElement } from '../../../../../../../../../shared/src/api/v2/service/werkbon';
import TabelInspringBlok from '../../../../../../../components/layout/TabelInspringBlok';
import api from '../../../../../../../api';
import LoadingSpinner from '../../../../../../../components/Gedeeld/LoadingSpinner';
import { ServiceContext } from '../index';
import nameOf from '../../../../../../../core/nameOf';
import FormatteerBedrag from '../../../../../../../components/MutatieBedrag';
import { IOphalenDienstenResultElement } from '../../../../../../../../../shared/src/api/v2/dienst/service';
import WerkbonMutatieDialoog from '../../../../../../../components/service/WerkbonMutatieDialoog';
import { Kleur as EKleur } from '../../../../../../../bedrijfslogica/constanten';
import { IconInformatie, IconVerwijderen } from '../../../../../../../components/Icons';
import WerkbonInfoDialoog from '../../../../../../../components/service/WerkbonInfoDialoog';
import MenuLayout from '../../../../../../../components/MenuLayout';
import { RootStoreContext } from '../../../../../../../stores/RootStore';
import { EResultType } from '../../../../../../../stores/CheckStore';
import _ from 'lodash';
import useUrlState from '../../../../../../../core/useUrlState';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { EOverigeTarieven } from '../../../../../../../bedrijfslogica/enums';

interface IProps extends RouteComponentProps {}

export interface IMutatieWerkbonDialoogState {
  werkbonID: number;
}

export interface IInfoWerkbonDialoogState {
  werkbonID: number;
}

export interface IWerkbon extends IOphalenWerkbonnenResultElement {
  servicedienst: IOphalenDienstenResultElement | null;
}

export interface IUrlState {
  selectie: number[];
}

export const defaultUrlState: IUrlState = {
  selectie: [],
};

const Werkbonnen = (props: IProps) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState<IUrlState>(props, defaultUrlState);

  const { checkStore } = useContext(RootStoreContext);

  const [werkbonnen, setWerkbonnen] = useState<IWerkbon[] | null>(null);
  const [serviceopdrachten, setServiceopdrachten] = useState<
    IOphalenOpdrachtenResultElement[] | null
  >(null);

  const serviceContext = useContext(ServiceContext);

  const [
    mutatieWerkbonDialoogState,
    setMutatieWerkbonDialoogState,
  ] = useState<IMutatieWerkbonDialoogState | null>(null);

  const [
    infoWerkbonDialoogState,
    setInfoWerkbonDialoogState,
  ] = useState<IInfoWerkbonDialoogState | null>(null);

  const ophalenWerkbonnen = useCallback(async () => {
    const cntBasisIDs = _.uniq(
      (
        await api.v2.contract.ophalenContractenV2({
          filterSchema: { filters: [{ naam: 'REL_IDS', data: [serviceContext.relID] }] },
        })
      ).contracten.map((x) => x.basis.CntBasisID),
    );

    const meldingenResult = await api.v2.service.ophalenMeldingen({
      filterSchema: {
        // filters: [{ naam: 'RELID', data: [serviceContext.relID] }],
        uitgebreideFilter: {
          or: [
            {
              filter: {
                naam: 'RELID',
                data: [serviceContext.relID],
              },
            },
            {
              filter: {
                naam: 'CNTBASIS_IDS',
                data: cntBasisIDs,
              },
            },
          ],
        },
      },
    });

    const opdrachtenResult = await api.v2.service.ophalenOpdrachten({
      filterSchema: {
        filters: [{ naam: 'SERVMELD_IDS', data: meldingenResult.meldingen.map((x) => x.ID) }],
      },
      paginatie: { index: 0, aantal: 100 },
      orderSchema: { orders: [{ naam: 'DATUM_VERSTUURD', richting: 'DESC' }] },
    });

    const servOpdIDs = _.uniq(opdrachtenResult.opdrachten.map((x) => x.ServOpdID));

    if (servOpdIDs.length === 0) {
      setWerkbonnen([]);
      return;
    }

    const werkbonnenResult = await api.v2.service.ophalenWerkbonnen({
      filterSchema: { filters: [{ naam: 'SERVOPD_IDS', data: servOpdIDs }] },
      // paginatie: { index: 0, aantal: 100 },
    });

    const diensten = (
      await api.v2.dienst.service.ophalenDiensten({
        filterSchema: {
          filters: [
            {
              naam: 'IDS',
              data: werkbonnenResult.werkbonnen
                .filter((x) => x.ServDienstID !== null)
                .map((x) => x.ServDienstID),
            },
          ],
        },
      })
    ).diensten;

    const werkbonnen = werkbonnenResult.werkbonnen.map((werkbon) => {
      const servicedienst =
        werkbon.ServDienstID !== null ? diensten.find((x) => x.ID === werkbon.ServDienstID) : null;

      return { ...werkbon, servicedienst: servicedienst ?? null };
    });

    setWerkbonnen(werkbonnen);
  }, [serviceContext.relID]);

  useEffect(() => {
    ophalenWerkbonnen();
  }, [ophalenWerkbonnen]);

  const ophalenServiceopdrachten = useCallback(async () => {
    if (werkbonnen === null) {
      return;
    }

    if (werkbonnen.length === 0) {
      setServiceopdrachten([]);
      return;
    }

    const result = (
      await api.v2.service.ophalenOpdrachten({
        filterSchema: {
          filters: [{ naam: 'IDS', data: [werkbonnen.map((x) => x.ServOpdID)] }],
        },
        paginatie: { index: 0, aantal: 100 },
      })
    ).opdrachten;

    setServiceopdrachten(result);
  }, [werkbonnen]);

  useEffect(() => {
    ophalenServiceopdrachten();
  }, [ophalenServiceopdrachten]);

  const handleVerwijderen = useCallback(async (id: number) => {
    const checkData = await api.v2.service.checkVerwijderenWerkbonnen({
      ids: [id],
    });
    if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
      return;
    }
    if (
      (
        await checkStore.bevestigen({
          inhoud: `Wil je de werkbon verwijderen?`,
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    await api.v2.service.verwijderenWerkbonnen({
      ids: [id],
    });

    ophalenWerkbonnen();
  }, []);

  const kolommen = useMemo<TypedColumn<IWerkbon>[]>(
    () => [
      {
        name: '__opdrachtnummer' as any,
        title: 'Opd.nr',
      },
      {
        name: '__info' as any,
        title: ' ',
      },
      {
        name: 'Bezoekdatum',
        title: 'Bez.datum',
        getCellValue: (x) => {
          return x.Bezoekdatum !== null ? new Date(x.Bezoekdatum) : new Date('2099-12-31');
        },
      },
      {
        name: 'Werkzaamheden',
        title: 'Werkzaamheden',
      },
      { name: 'KostenTotaal', title: 'Kosten (ex)' },
      {
        name: 'IsBeoordeeld',
        title: 'Beoord.',
      },
      {
        name: 'KostenDoorberekenen',
        title: 'Ber.',
      },
      {
        name: 'KostenDoorberekend',
        title: 'Door.',
      },
      { name: '__dienst' as any, title: 'Rep.dienst' },
      { name: 'RecordToegevoegd' as any, title: 'Geregistreerd' },
    ],
    [],
  );
  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IWerkbon>[]>(
    () => [
      {
        columnName: '__opdrachtnummer' as any,
        width: 85,
      },
      {
        columnName: '__info' as any,
        width: 60,
      },
      {
        columnName: 'Bezoekdatum',
        width: 115,
      },
      {
        columnName: 'Werkzaamheden',
        width: 250,
      },
      {
        columnName: 'KostenTotaal',
        width: 110,
      },
      {
        columnName: 'IsBeoordeeld',
        width: 90,
      },
      {
        columnName: '__dienst' as any,
        width: 175,
      },
      {
        columnName: 'KostenDoorberekenen',
        width: 65,
      },
      {
        columnName: 'KostenDoorberekend',
        width: 75,
      },
      {
        columnName: 'RecordToegevoegd',
        width: 135,
      },
    ],
    [],
  );

  const keyExtractor = useCallback((x: IWerkbon) => x.ID, []);

  const kolomExtensies: VirtualTableBase.ColumnExtension[] = useMemo(() => {
    return [
      // {
      //   columnName: `KostenTotaal`,
      //   align: 'right',
      // },
    ];
  }, []);

  return werkbonnen === null || serviceopdrachten === null ? (
    <div className="d-flex flex-fill align-items-center justify-content-center">
      <LoadingSpinner />
    </div>
  ) : (
    <>
      <MenuLayout
        menu={
          <>
            <div className="d-flex align-items-center">
              <button
                className="btn btn-sm btn-light d-flex align-items-center"
                style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                disabled={urlState.selectie.length !== 1}
                onClick={async () => {
                  const werkbonID = urlState.selectie[0];
                  const werkbon = werkbonnen.find((x) => x.ID === werkbonID)!;

                  const standaardtarief = (
                    await api.v2.aanbod.tarieven.overige.ophalenOverigeTarieven({
                      filterSchema: {
                        filters: [
                          {
                            naam: 'NAAM_ENUMS',
                            data: [EOverigeTarieven.DoorTeBerekenen_Standaard_Servicekosten],
                          },
                        ],
                      },
                    })
                  )[0];
                  const standaardkosten = standaardtarief?.Bedrag ?? 0;

                  if (
                    (
                      await checkStore.bevestigen({
                        inhoud: (
                          <span>
                            Vordering maken voor de standaardkosten ({standaardkosten})?
                            <br />
                            <br />
                            Let op: de vordering wordt gemaakt bij de relatie waarvoor de melding
                            gedaan is.
                          </span>
                        ),
                      })
                    ).type === EResultType.Annuleren
                  ) {
                    return;
                  }

                  await api.v2.service.doorberekenenKosten({
                    werkbonID: urlState.selectie[0],
                    standaardkostenBerekenen: true,
                  });

                  ophalenWerkbonnen();
                }}
              >
                <IconVerwijderen style={{ width: 16, height: 16, fill: EKleur.Grijs }} />
                &nbsp; Vordering voor de standaard servicekosten
              </button>

              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-3"
                style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                disabled={urlState.selectie.length !== 1}
                onClick={async () => {
                  if (
                    (
                      await checkStore.bevestigen({
                        inhoud: (
                          <span>
                            Vordering maken voor de servicekosten?
                            <br />
                            <br />
                            Let op: de vordering wordt gemaakt bij de relatie waarvoor de melding
                            gedaan is.
                          </span>
                        ),
                      })
                    ).type === EResultType.Annuleren
                  ) {
                    return;
                  }

                  await api.v2.service.doorberekenenKosten({
                    werkbonID: urlState.selectie[0],
                    standaardkostenBerekenen: false,
                  });

                  ophalenWerkbonnen();
                }}
              >
                <IconVerwijderen style={{ width: 16, height: 16, fill: EKleur.Grijs }} />
                &nbsp; Vordering voor de servicekosten
              </button>
              <div className="flex-fill" />
            </div>
          </>
        }
        body={
          <>
            <div className="d-flex">
              <GridStyleWrapper height={'calc(100vh - 100px)'}>
                <Grid rows={werkbonnen} columns={kolommen} getRowId={keyExtractor}>
                  <DataTypeProvider
                    for={['__opdrachtnummer']}
                    formatterComponent={(formatterProps) => {
                      const rij: IWerkbon = formatterProps.row;
                      const serviceopdracht = serviceopdrachten.find(
                        (x) => x.ServOpdID === rij.ServOpdID,
                      )!;
                      return (
                        <span>
                          {serviceopdracht.melding.Meldnummer}-{serviceopdracht.Volgnummer}
                        </span>
                      );
                    }}
                  />

                  <DataTypeProvider
                    for={[nameOf<IWerkbon>('Bezoekdatum')]}
                    formatterComponent={(x) => {
                      if (x.value === null) {
                        return <span></span>;
                      }
                      return <span>{format(new Date(x.value), 'dd-MM-yyyy')}</span>;
                    }}
                  />

                  <DataTypeProvider
                    for={[nameOf<IWerkbon>('RecordToegevoegd')]}
                    formatterComponent={(x) => {
                      return <span>{format(new Date(x.value), 'dd-MM-yyyy HH:mm')}</span>;
                    }}
                  />

                  <DataTypeProvider
                    for={[nameOf<IWerkbon>('KostenTotaal')]}
                    formatterComponent={(x) => {
                      const bedrag = x.value;
                      return <FormatteerBedrag bedrag={bedrag} />;
                    }}
                  />

                  <DataTypeProvider
                    for={['__dienst']}
                    formatterComponent={(x) => {
                      const rij: IWerkbon = x.row;

                      if (rij.servicedienst === null) {
                        return <span></span>;
                      }
                      return <span>{rij.servicedienst.relatie!.weergavenaam}</span>;
                    }}
                  />

                  <DataTypeProvider
                    for={['__info']}
                    formatterComponent={(formatterProps) => {
                      const rij: IWerkbon = formatterProps.row;
                      return (
                        <div>
                          <a
                            href="#"
                            className="ml-1"
                            style={{ color: EKleur.LichtBlauw }}
                            onClick={() => {
                              setInfoWerkbonDialoogState({ werkbonID: rij.ID });
                            }}
                          >
                            <IconInformatie style={{ width: 15, height: 15, fill: EKleur.Blauw }} />
                          </a>
                        </div>
                      );
                    }}
                  />

                  <DataTypeProvider
                    for={[nameOf<IWerkbon>('KostenDoorberekenen')]}
                    formatterComponent={(formatterProps) => {
                      return <span>{formatterProps.value ? 'Ja' : 'Nee'}</span>;
                    }}
                  />

                  <DataTypeProvider
                    for={[nameOf<IWerkbon>('IsBeoordeeld')]}
                    formatterComponent={(formatterProps) => {
                      return <span>{formatterProps.value ? 'Ja' : 'Nee'}</span>;
                    }}
                  />

                  <DataTypeProvider
                    for={[nameOf<IWerkbon>('KostenDoorberekend')]}
                    formatterComponent={(formatterProps) => {
                      const rij: IWerkbon = formatterProps.row;
                      if (!rij.KostenDoorberekenen) {
                        return <span>-</span>;
                      }
                      return <span>{formatterProps.value ? 'Ja' : 'Nee'}</span>;
                    }}
                  />

                  <SortingState defaultSorting={[]} />
                  <IntegratedSorting />
                  <VirtualTable
                    columnExtensions={kolomExtensies}
                    messages={{ noData: 'Geen werkbonnen' }}
                  />
                  <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
                  {/* <TableHeaderRow showSortingControls /> */}
                  <TableHeaderRow showSortingControls />

                  <EditingState
                    onAddedRowsChange={() => {}}
                    onEditingRowIdsChange={(x) => {
                      const id = x[x.length - 1] as number;
                      setMutatieWerkbonDialoogState({ werkbonID: id });
                    }}
                    onCommitChanges={async (changes) => {
                      if (changes.deleted === undefined) {
                        return;
                      }

                      const deleted = changes.deleted;
                      const id = deleted[deleted.length - 1] as number;

                      const checkData = await api.v2.service.checkVerwijderenWerkbonnen({
                        ids: [id],
                      });
                      if (
                        (await checkStore.controleren({ checkData })).type === EResultType.Annuleren
                      ) {
                        return;
                      }
                      if (
                        (
                          await checkStore.bevestigen({
                            inhoud: `Wil je de werkbon verwijderen?`,
                          })
                        ).type === EResultType.Annuleren
                      ) {
                        return;
                      }

                      await api.v2.service.verwijderenWerkbonnen({
                        ids: [id],
                      });

                      ophalenWerkbonnen();
                    }}
                  />

                  <TableEditColumn
                    width={65}
                    showEditCommand
                    showDeleteCommand
                    commandComponent={DXCommandComponent}
                  />
                  <SelectionState
                    selection={urlState.selectie}
                    onSelectionChange={(selectie) =>
                      setUrlStateSync('selectie', selectie as number[])
                    }
                  />
                  <TableSelection cellComponent={DXTableCheckboxComponent} />
                </Grid>
              </GridStyleWrapper>
            </div>
          </>
        }
      />

      {mutatieWerkbonDialoogState !== null && (
        <WerkbonMutatieDialoog
          open={true}
          serviceopdracht={null}
          werkbonID={mutatieWerkbonDialoogState.werkbonID}
          onSuccess={() => {
            setMutatieWerkbonDialoogState(null);
            ophalenWerkbonnen();
          }}
          onAnnuleren={() => setMutatieWerkbonDialoogState(null)}
        />
      )}
      {infoWerkbonDialoogState !== null && (
        <WerkbonInfoDialoog
          open
          id={infoWerkbonDialoogState.werkbonID}
          onSuccess={() => {
            setInfoWerkbonDialoogState(null);
            ophalenWerkbonnen();
          }}
          onAnnuleren={() => setInfoWerkbonDialoogState(null)}
        />
      )}
    </>
  );
};

export default withRouter(Werkbonnen);
