import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { BankmutatiesContext } from '../index';
import ResizeObserver from 'react-resize-observer';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import MutatiesTabel from './MutatiesTabel';
import {
  IOngedaanMakenDefinitiefParams,
  IOphalenMutatiesResult,
} from '../../../../../../shared/src/api/v2/bank/mutaties';
import api from '../../../../api';
import { EResultType } from '../../../../stores/CheckStore';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../../stores/RootStore';
import BestandenDragAndDropDialoog from '../../../../components/BestandenDragAndDropDialoog';
import { EBestandDragAndDropZoneSelectieModus } from '../../../../components/BestandDragAndDropZone';
import GegevensLayout from '../../../../components/layout/GegevensLayout';
import FormatteerBedrag, {
  StandaardMutatieBedragOpmaker,
} from '../../../../components/MutatieBedrag';
import FilterBalkV2, { IFilter, maakFilterSchema } from '../../../../components/FilterBalkV2';
import { IFilterSchema } from '../../../../../../shared/src/models/filter';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import SelectieVak from '../../../../components/SelectieVak';
import MultiSelect, { IOptie } from '../../../../components/formulier/MultiSelect';
import SaldoCorrigerenDialoog from '../SaldoCorrigerenDialoog';
import ZoektermFilter from './ZoektermFilter';
import BedragFilter from './BedragFilter';
import { Helmet } from 'react-helmet';
import { AutoSizer } from 'react-virtualized';
import IsBezigOverlay from '../../../../components/IsBezigOverlay';
import { addDays } from 'date-fns';
import DatumKiezer from '../../../../components/formulier/DatumKiezer';

export enum EFilter {
  Status = 'STATUS',
  Geboekt = 'GEBOEKT',
  GekoppeldAanBankopdracht = 'SOORT_MUTATIE',
  Zoekterm = 'ZOEKTERM',
  Bedrag = 'BEDRAG',
  DatumVanaf = 'DATUM_VANAF',
  DatumTot = 'DATUM_TOT_EN_MET',
}

interface IProps {}

interface ISaldoCorrigerenDialoogState {
  bankRekID: number;
}

const DefinitiefTabblad: React.FC<IProps> = observer((props) => {
  const { checkStore } = useContext(RootStoreContext);
  const bankmutatiesContext = useContext(BankmutatiesContext);
  const {
    selectie,
    onSelectieChange,
    uitgeklapt,
    onUitgeklaptChange,
    gekozenRekening,
    onVerversenAangevraagd,
    inlezenMutatiesState,
    onInlezenMutatiesStateChange,
    bepalenSaldoMutatiesResult,
    boekenFilterData,
    onBoekenFilterDataChange,
  } = bankmutatiesContext;

  const [isBezig, setIsBezig] = useState(false);
  const [
    saldoCorrigerenDialoogState,
    setSaldoCorrigerenDialoogState,
  ] = useState<ISaldoCorrigerenDialoogState | null>(null);

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.Zoekterm,
        altijdWeergevenInBalk: true,
        weergave: ZoektermFilter,
      },
      {
        naam: EFilter.Bedrag,
        altijdWeergevenInBalk: true,
        weergave: BedragFilter,
      },
      {
        naam: EFilter.GekoppeldAanBankopdracht,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          const opties = [
            { optie: 1, naam: 'Batches' },
            { optie: 2, naam: 'Stornos' },
            { optie: 3, naam: 'Overig' },
          ];
          return (
            <span className="d-flex align-items-center">
              <span className="mr-3">Mutatiesoort</span>
              {false === null ? (
                <LoadingSpinner />
              ) : (
                <MultiSelect
                  value={weergaveProps.data}
                  onChange={(data) => {
                    weergaveProps.onDataChange(data);
                    weergaveProps.toepassen();
                  }}
                  opties={opties.map(
                    (optie): IOptie<number> => {
                      return { key: optie.optie, weergave: optie.naam };
                    },
                  )}
                  isVerwijderbaar={false}
                />
              )}
            </span>
          );
        },
      },
      {
        naam: EFilter.Geboekt,
        altijdWeergevenInBalk: true,
        actiefMuteerbaar: true,
        weergave: (weergaveProps) => {
          return <span>Alleen nog te boeken</span>;
        },
      },
      {
        naam: EFilter.Status,
        weergave: () => null,
        actiefMuteerbaar: false,
        altijdWeergevenInBalk: true,
      },
      {
        naam: EFilter.DatumVanaf,
        altijdWeergevenInBalk: false,
        weergave: (weergaveProps) => {
          return (
            <div className="d-flex align-items-center">
              <span>Mutatiedatum vanaf</span>
              <span className="ml-2">
                <DatumKiezer
                  waarde={weergaveProps.data === null ? null : new Date(weergaveProps.data)}
                  onGewijzigd={(x) => {
                    weergaveProps.onDataChange(x === null ? null : x.toISOString());
                    weergaveProps.toepassen();
                  }}
                  determineValidDate={() => true}
                  determineNextValidDate={(date) => addDays(date, 1)}
                  determinePreviousValidDate={(date) => addDays(date, -1)}
                />
              </span>
            </div>
          );
        },
      },
      {
        naam: EFilter.DatumTot,
        altijdWeergevenInBalk: false,
        weergave: (weergaveProps) => {
          return (
            <div className="d-flex align-items-center">
              <span>T/m</span>
              <span className="ml-2">
                <DatumKiezer
                  waarde={weergaveProps.data === null ? null : new Date(weergaveProps.data)}
                  onGewijzigd={(x) => {
                    weergaveProps.onDataChange(x === null ? null : x.toISOString());
                    weergaveProps.toepassen();
                  }}
                  determineValidDate={() => true}
                  determineNextValidDate={(date) => addDays(date, 1)}
                  determinePreviousValidDate={(date) => addDays(date, -1)}
                />
              </span>
            </div>
          );
        },
      },
    ],
    [],
  );
  const [filterSchema, setFilterSchema] = useState(
    maakFilterSchema(
      useMemo(() => [...boekenFilterData], [
        // filterdata,
        // bankmutatiesContext.filterdata,
      ]),
    ),
  );

  const [mutatiesResult, setMutatiesResult] = useState<IOphalenMutatiesResult | null>(null);

  const [isMutatiesAanHetOphalen, setIsMutatiesAanHetOphalen] = useState(false);
  const ophalenMutaties = useCallback(async () => {
    setIsMutatiesAanHetOphalen(true);

    const x = maakFilterSchema(bankmutatiesContext.filterdata);
    const mergedFilterSchema: IFilterSchema = {
      filters: [...(filterSchema.filters || []), ...(x.filters || [])],
    };

    const result = await api.v2.bank.mutatie.ophalenMutaties({
      filterSchema: mergedFilterSchema,
      paginatie: {
        index: 0,
        aantal: 10000,
      },
    });

    // const stornoredenen = await api.v2.bank.ophalenStornoredenen({});

    setMutatiesResult(result);
    setIsMutatiesAanHetOphalen(false);
  }, [filterSchema, JSON.stringify(bankmutatiesContext.filterdata)]);

  useEffect(() => {
    ophalenMutaties();
  }, [ophalenMutaties]);

  const handleMakenVoorstelBankboekingen = useCallback(
    async (bankMutIDs: number[]) => {
      setIsBezig(true);

      const params = { bankRekID: gekozenRekening!.BankRekID, bankMutIDs };
      const checkData = await api.v2.boekhouding.boeking.bank.checkMakenVoorstelBankboekingen(
        params,
      );
      const controleResult = await checkStore.controleren({ checkData });
      if (controleResult.type === EResultType.Annuleren) {
        setIsBezig(false);
        return;
      }

      const resultaat = await checkStore.bevestigen({
        inhoud: `Boekingsvoorstel voor de geselecteerde mutaties maken?`,
      });
      if (resultaat.type === EResultType.Annuleren) {
        setIsBezig(false);
        return;
      }

      await api.v2.boekhouding.boeking.bank.makenVoorstelBankboekingen(params);

      await ophalenMutaties();
      setIsBezig(false);
    },
    [gekozenRekening?.BankRekID, ophalenMutaties],
  );

  const verversen = useCallback(async () => {
    await Promise.all([ophalenMutaties(), onVerversenAangevraagd()]);
  }, [ophalenMutaties, onVerversenAangevraagd]);

  useEffect(() => {
    if (mutatiesResult === null) {
      return;
    }

    // Haal ids uit de selectie die niet voorkomen in de nieuwe mutaties
    const geldigeSelectie = selectie.filter((id) =>
      mutatiesResult.mutaties.some((m) => m.BankMutID === id),
    );
    // Als de nieuwe geldige selectie gelijk is aan de oude selectie, dan niets wijzigen (vergelijk inhoud ids)
    if (
      geldigeSelectie.length === selectie.length &&
      geldigeSelectie.every((id) => selectie.includes(id))
    ) {
      return;
    }
    // zet state
    onSelectieChange(geldigeSelectie);
  }, [mutatiesResult]);

  return (
    <>
      <Helmet>
        <title>Bankmutaties Definitief</title>
      </Helmet>

      <IsBezigOverlay
        isBezig={isBezig}
        rootStyle={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
        element={
          <div className="d-flex flex-fill flex-column">
            <div className="p-2 pl-3 pr-3 d-flex">
              <div className="d-flex align-items-center">
                <button
                  className="btn btn-sm btn-light d-flex align-items-center"
                  style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                  disabled={selectie.length === 0}
                  onClick={async () => {
                    const params: IOngedaanMakenDefinitiefParams = {
                      bankMutIDs: selectie,
                      bankRekID: gekozenRekening!.BankRekID,
                    };
                    const checkData = await api.v2.bank.mutatie.checkOngedaanMakenDefinitief(
                      params,
                    );
                    const checkResult = await checkStore.controleren({
                      checkData,
                    });
                    if (checkResult.type === EResultType.Annuleren) {
                      return;
                    }

                    if (
                      (
                        await checkStore.bevestigen({
                          inhoud: 'Geselecteerde mutaties naar Concept?',
                        })
                      ).type === EResultType.Annuleren
                    ) {
                      return;
                    }

                    await api.v2.bank.mutatie.ongedaanMakenDefinitief(params);
                    onSelectieChange([]);
                    verversen();
                  }}
                >
                  {/* <IconToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} /> */}
                  <span className="ml-2">Definitief ongedaan maken</span>
                </button>

                <button
                  className="btn btn-sm btn-light d-flex align-items-center ml-3"
                  style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                  disabled={selectie.length === 0}
                  onClick={async () => {
                    await handleMakenVoorstelBankboekingen(selectie);
                  }}
                >
                  {/* <IconToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} /> */}
                  <span className="ml-2">Boekingen maken</span>
                </button>
              </div>

              {/* <div className="d-flex align-items-center ml-4">
            {gekozenRekening !== null &&
              bepalenSaldoMutatiesResult !== null &&
              (bepalenSaldoMutatiesResult.saldo === 0 ? (
                <GegevensLayout
                  gegevens={[
                    {
                      label: 'Saldo',
                      waarde: <MutatieBedrag bedrag={gekozenRekening.Saldo} />,
                    },
                  ]}
                />
              ) : (
                <div className="d-flex align-items-center">
                  <div className="ml-2">
                    <GegevensLayout
                      gegevens={[
                        {
                          label: 'Saldo',
                          waarde: <MutatieBedrag bedrag={gekozenRekening.Saldo} />,
                        },
                      ]}
                    />
                  </div>
                </div>
              ))}
          </div> */}

              <div className="d-flex align-items-center ml-4">
                {gekozenRekening !== null && (
                  <GegevensLayout
                    gegevens={[
                      {
                        label: 'Saldo',
                        waarde: (
                          <div className="d-flex">
                            <FormatteerBedrag
                              bedrag={gekozenRekening.Saldo}
                              opmaakComponent={(opmaakProps) => {
                                return (
                                  <StandaardMutatieBedragOpmaker
                                    {...opmaakProps}
                                    color={Kleur.Blauw}
                                  />
                                );
                              }}
                            />

                            <span className="ml-3">
                              <a
                                href="#"
                                className="ml-1 mr-1"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  setSaldoCorrigerenDialoogState({
                                    bankRekID: gekozenRekening.BankRekID,
                                  });
                                }}
                              >
                                Corrigeren
                              </a>
                            </span>
                          </div>
                        ),
                      },
                    ]}
                  />
                )}
              </div>

              <div className="flex-fill" />

              <div>
                <FilterBalkV2
                  filterData={boekenFilterData}
                  filters={filters}
                  onFilterDataChange={(x) => onBoekenFilterDataChange(x)}
                  onFilterSchemaChange={(x) => setFilterSchema(x)}
                />
              </div>
            </div>

            {mutatiesResult !== null && (
              <div className="d-flex align-items-center ml-3 mb-2">
                <SelectieVak
                  totaalAantal={mutatiesResult.mutaties.length}
                  aantal={selectie.length}
                  onChange={(allesGeselecteerd) => {
                    if (allesGeselecteerd) {
                      onSelectieChange(mutatiesResult.mutaties.map((x) => x.BankMutID));
                    } else {
                      onSelectieChange([]);
                    }
                  }}
                />
                {/* <span className="ml-2">Totaal {producten!.length} tarieven</span> */}
              </div>
            )}

            <div className="d-flex flex-fill justify-content-center align-items-center flex-column">
              {mutatiesResult === null ? (
                <LoadingSpinner />
              ) : (
                <div className="flex-fill d-flex flex-column">
                  <AutoSizer className="flex-fill w-100 h-100">
                    {(size) => (
                      <IsBezigOverlay
                        isBezig={isMutatiesAanHetOphalen}
                        element={
                          <MutatiesTabel
                            mutaties={mutatiesResult.mutaties}
                            height={size.height}
                            onVerversenAangevraagd={verversen}
                            selectie={selectie}
                            uitgeklapt={uitgeklapt}
                            onSelectieChange={onSelectieChange}
                            onUitgeklaptChange={onUitgeklaptChange}
                            gekozenRekening={gekozenRekening}
                          />
                        }
                      />
                    )}
                  </AutoSizer>
                </div>
              )}
            </div>
          </div>
        }
      />
      {inlezenMutatiesState !== null && (
        <BestandenDragAndDropDialoog
          open
          onSuccess={async (result) => {
            if (result.bestanden.length !== 1) {
              await checkStore.melden({ titel: 'Er dient een bestand geselecteerd te worden' });
              return;
            }
            onInlezenMutatiesStateChange(null);

            const bestand = result.bestanden[0];
            const bestandImportResult = await api.v2.bank.mutatie.toevoegenBestandImport({
              bestandID: bestand.ID,
            });
            try {
              const result = await api.v2.bank.mutatie.inlezenBestandImport({
                id: bestandImportResult.bestandImport.ID,
              });

              await verversen();
            } catch (err) {
              await checkStore.melden({
                titel:
                  'Het inlezen van het import bestand is mislukt. Zie de logs voor verdere informatie.',
              });
              return;
            }
          }}
          onAnnuleren={() => onInlezenMutatiesStateChange(null)}
          toegestaneBestandstypen={[
            {
              mediaType: 'text/xml',
              weergaveNaam: 'XML',
            },
          ]}
          selectieModus={EBestandDragAndDropZoneSelectieModus.Enkel}
        />
      )}
      {saldoCorrigerenDialoogState !== null && (
        <SaldoCorrigerenDialoog
          open
          bankRekID={saldoCorrigerenDialoogState.bankRekID}
          onSuccess={() => {
            setSaldoCorrigerenDialoogState(null);
            verversen();
            return;
          }}
          onAnnuleren={() => setSaldoCorrigerenDialoogState(null)}
        />
      )}
    </>
  );
});

export default DefinitiefTabblad;
