import React, { HTMLProps, useMemo } from 'react';
import { IWhatsappBericht, IWhatsappChatsessie } from '../../../../../../api/services/v1/whatsapp';
import styled from 'styled-components';
import PersoonKoppelComponent from '../../../../../personalia/PersoonKoppelComponent';
import { Kleur } from '../../../../../../bedrijfslogica/constanten';
import { datumNaarStringKort, formatDatumTijd } from '../../../helpers';
import { IconInkomend, IconUitgaand } from '../../../../../Icons';
import { useWhatsappV2Store } from '../../../store';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  background-color: transparent;
  transition: background-color 0.2s ease-in-out;

  &:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  &:hover {
    background-color: rgba(100, 100, 100, 0.1);
    cursor: pointer;
  }
`;

const Boven = styled.div`
  display: flex;
  align-items: start;
  padding: 8px 15px 0 15px;
`;

const NaamContainer = styled.div`
  flex: 1;
`;

const Tijd = styled.span`
  font-size: 12px;
  font-weight: bold;
  color: ${Kleur.Grijs};
  min-width: 65px;
  text-align: right;

  &.unread {
    color: ${Kleur.LichtBlauw};
  }
`;

const Onder = styled.div`
  flex: 1;
  display: flex;
  padding: 0 15px 8px 15px;
`;

const BerichtContainer = styled.div`
  flex: 1;
  //white-space: nowrap;
  word-break: break-word;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
  font-size: 13px;
`;

const MessageTextRichtingContainer = styled.div`
  margin-left: 5px;
`;

const NaamComponent = (props: HTMLProps<HTMLSpanElement>) => {
  return (
    <span
      {...props}
      style={{
        ...props.style,
        fontSize: 13,
        fontWeight: 'bold',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        minWidth: 0,
      }}
    />
  );
};

const OngekoppeldContainerComponent = (props: HTMLProps<HTMLDivElement>) => {
  return <div {...props} style={{ ...props.style, fontSize: 13, fontWeight: 'bold' }} />;
};

interface IProps {
  bericht: IWhatsappBericht;
  chatsessie: IWhatsappChatsessie;
  style?: React.CSSProperties;
  onClick?: () => void;
}

const BerichtItem = (props: IProps) => {
  const berichtDatum = new Date(props.bericht.datum);
  const { volledigeTijd, tijd } = useMemo(() => {
    return {
      volledigeTijd: formatDatumTijd(berichtDatum),
      tijd: datumNaarStringKort(new Date(props.bericht.datum)),
    };
  }, [props.bericht.datum]);

  const richting = (
    <MessageTextRichtingContainer
      title={props.bericht.richting === 'I' ? 'Inkomend bericht' : 'Uitgaand bericht'}
    >
      {props.bericht.richting === 'U' ? (
        <IconUitgaand
          style={{
            width: 15,
            height: 15,
            fill: Kleur.Blauw,
            transform: 'rotate(45deg)',
          }}
        />
      ) : (
        <IconInkomend
          style={{
            width: 15,
            height: 15,
            fill: Kleur.DonkerGroen,

            transform: 'rotate(45deg)',
          }}
        />
      )}
    </MessageTextRichtingContainer>
  );

  return (
    <Root style={props.style} onClick={props.onClick}>
      <Boven>
        <NaamContainer>
          <PersoonKoppelComponent
            persID={props.chatsessie.persId}
            weergaveNaamPersoonOnbekend={props.chatsessie.naam ?? props.chatsessie.telefoonnummer}
            naamComponent={NaamComponent}
            ongekoppeldContainerComponent={OngekoppeldContainerComponent}
            magMuteren={false}
          />
        </NaamContainer>
        <Tijd title={volledigeTijd}>{tijd}</Tijd>
      </Boven>
      <Onder>
        <BerichtContainer>{props.bericht.inhoud}</BerichtContainer>
        {richting}
      </Onder>
    </Root>
  );
};

export default BerichtItem;
