import React from 'react';
import { ISectieProps } from '../index';
import GegevensLayout from '../../../../../../../../../components/layout/GegevensLayout';
import { format } from 'date-fns';
import { formatteerPersoonNaam } from '../../../../../../../../../helpers';
import VerticaleScheidingslijn from '../../../../../../../../../components/layout/VerticaleScheidingslijn';

interface IProps extends ISectieProps {}

const DetailsSectie: React.FC<IProps> = (props) => {
  const melding = props.melding;
  const contract = melding.contract;
  const persoon =
    melding.persoon !== null
      ? formatteerPersoonNaam({
          voornaam: melding.persoon.Voornaam,
          achternaam: melding.persoon.Achternaam,
          aanhefKort: melding.persoon.AanhefKort,
          voorletters: melding.persoon.Voorletters,
          voorvoegsel: melding.persoon.Voorvoegsel,
        })
      : null;

  return (
    <>
      <div className="d-flex">
        <div className="ml-3">
          <GegevensLayout
            gegevens={[
              {
                label: 'Contactpersoon',
                waarde: `${
                  persoon !== null
                    ? persoon
                    : melding.Voornaam +
                      (melding.Voorvoegsel !== null ? ' ' + melding.Voorvoegsel : '') +
                      ' ' +
                      melding.Achternaam
                }`,
              },
              {
                label: 'Email',
                waarde: `${melding.Email !== null ? melding.Email : ''}`,
              },
              {
                label: 'Telefoon',
                waarde: `${melding.Telefoon !== null ? melding.Telefoon : ''}`,
              },
              {
                label: 'Telefoon alt.',
                waarde: `${
                  melding.TelefoonAlternatief !== null ? melding.TelefoonAlternatief : ''
                }`,
              },
            ]}
          />
        </div>
        <div style={{ height: '100%' }} className="ml-4 mr-4 d-flex align-items-center">
          <VerticaleScheidingslijn height={80} />
        </div>
        <div className="ml-3">
          {contract !== null ? (
            <GegevensLayout
              gegevens={[
                {
                  label: 'Contractnr.',
                  waarde: `${contract!.Basisnummer}.${contract!.Volgnummer}`,
                },
                {
                  label: 'Modelcode',
                  waarde: contract!.Modelcode,
                },
                {
                  label: 'Ingangsdatum',
                  waarde: format(new Date(contract!.Ingangsdatum), 'dd-MM-yyyy HH:ss'),
                },
                {
                  label: 'Einddatum',
                  waarde:
                    contract!.Einddatum === null
                      ? 'Niet opgegeven'
                      : format(new Date(contract!.Einddatum), 'dd-MM-yyyy HH:ss'),
                },
              ]}
            />
          ) : (
            <div>Geen contract</div>
          )}
        </div>
        {/*<div style={{ height: '100%' }} className="ml-4 mr-4">*/}
        {/*  <VerticaleScheidingslijn height={80} />*/}
        {/*</div>*/}
      </div>
    </>
  );
};

export default DetailsSectie;
