import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import MenuLayout from '../../../../../../../components/MenuLayout';
import IncassozakenTabel, {
  IWijzigenIncassozaakDialoogState,
} from '../../../../../../../components/debiteuren/IncassozakenTabel';
import { IOphalenIncassozakenResultElement } from '../../../../../../../../../shared/src/api/v2/debiteur/incassozaak';
import useUrlState from '../../../../../../../core/useUrlState';
import api from '../../../../../../../api';
import { observer } from 'mobx-react-lite';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { RootStoreContext } from '../../../../../../../stores/RootStore';
import { Kleur as EKleur } from '../../../../../../../bedrijfslogica/constanten';
import { EResultType } from '../../../../../../../stores/CheckStore';
import { EIncassozaakStatus } from '../../../../../../../bedrijfslogica/enums';
import { IOphalenRechtzakenResultElement } from '../../../../../../../../../shared/src/api/v2/debiteur/rechtzaak';
import {
  DXTableCheckboxComponent,
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
  DXTableToggleCellComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../../../../helpers/dxTableGrid';
import { IOphalenFacturenBasisResultElement } from '../../../../../../../../../shared/src/api/v2/factuur';
import _ from 'lodash';
import LoadingSpinner from '../../../../../../../components/Gedeeld/LoadingSpinner';
import {
  Grid,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  DataTypeProvider,
  EditingState,
  IntegratedSorting,
  RowDetailState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import { format } from 'date-fns';
import FormatteerBedrag from '../../../../../../../components/MutatieBedrag';
import nameOf from '../../../../../../../core/nameOf';
import FacturenDetailComp from './FacturenDetailComp';
import WijzigenRechtzaakDialoog from './WijzigenRechtzaakDialoog';

interface IWijzigenRechtzaakDialoogState {
  id: number;
}

export interface IUrlState {
  selectie: number[];
  wijzigenRechtzaakDialoogState: IWijzigenRechtzaakDialoogState | null;
}

export const defaultUrlState: IUrlState = {
  selectie: [],
  wijzigenRechtzaakDialoogState: null,
};

interface IProps extends RouteComponentProps {}

const rechtzaken: React.FC<IProps> = observer((props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const { checkStore } = useContext(RootStoreContext);

  const { klantkaartStore } = useContext(RootStoreContext);

  const [rechtzaken, setRechtzaken] = useState<IOphalenRechtzakenResultElement[] | null>(null);

  const [facturen, setFacturen] = useState<IOphalenFacturenBasisResultElement[] | null>(null);

  const ophalenRechtzaken = useCallback(async () => {
    if (klantkaartStore.relatie === null) {
      setRechtzaken(null);
      return;
    }

    const results = await api.v2.debiteur.rechtzaak.ophalenRechtzaken({
      filterSchema: {
        filters: [{ naam: 'REL_IDS', data: [klantkaartStore.relatie.RelID] }],
      },
    });

    const facturenResult = _.flatten(
      results.rechtzaken.map((z) => {
        return [...z.facturen];
      }),
    );
    const factIDs = _.uniq(facturenResult.map((x: any) => x.FactID));
    const facturenResults = await api.v2.factuur.ophalenFacturenBasis({
      filterSchema: {
        filters: [{ naam: 'IDS', data: factIDs }],
      },
    });
    setFacturen(facturenResults.facturen);

    setRechtzaken(results.rechtzaken);
  }, [klantkaartStore.relatie === null ? null : klantkaartStore.relatie.RelID]);

  useEffect(() => {
    ophalenRechtzaken();
  }, [ophalenRechtzaken]);

  const handleVerwijderen = useCallback(async (ID: number) => {
    const checkData = await api.v2.debiteur.rechtzaak.checkVerwijderennRechtzaken({
      ids: [ID],
    });
    if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
      return;
    }
    if (
      (
        await checkStore.bevestigen({
          inhoud: (
            <span>
              Wil je de rechtzaak verwijderen?
              <br />
              Let op, de gekoppelde facturen komen daarbij vrij en kunnen eventueel weer aangemaand
              worden.
            </span>
          ),
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    await api.v2.debiteur.rechtzaak.verwijderenRechtzaken({
      ids: [ID],
    });

    ophalenRechtzaken();
  }, []);

  const keyExtractor = useCallback((rij: IOphalenRechtzakenResultElement) => rij.ID, []);

  const kolommen = useMemo<TypedColumn<IOphalenRechtzakenResultElement>[]>(
    () => [
      {
        name: 'Dossierdatum',
        title: 'Dossierdatum',
      },
      {
        name: 'Hoofdsom',
        title: 'Hoofdsom',
      },
      {
        name: '__incassoDienst' as any,
        title: 'Inc.dienst',
      },
      {
        name: 'Afgehandeld',
        title: 'Afgehandeld',
      },
      {
        name: 'Notities',
        title: 'Notities',
      },
      // {
      //   name: 'Statusnaam',
      //   title: 'Dossierstatus',
      // },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IOphalenRechtzakenResultElement>[]>(
    () => [
      {
        columnName: 'Dossierdatum',
        width: 135,
      },
      {
        columnName: 'Hoofdsom',
        width: 125,
      },
      {
        columnName: '__incassoDienst' as any,
        width: 125,
      },
      {
        columnName: 'Notities',
        width: 250,
      },
      {
        columnName: 'Afgehandeld',
        width: 120,
      },
      // {
      //   columnName: 'Statusnaam',
      //   width: 135,
      // },
    ],
    [],
  );

  return (
    <>
      <MenuLayout
        // menu={
        //   <div className="d-flex">
        //     <button
        //       className="btn btn-sm btn-light d-flex align-items-center"
        //       disabled={urlState.selectie.length !== 1}
        //       style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
        //       onClick={async () => {
        //         if (
        //           (
        //             await checkStore.bevestigen({
        //               inhoud: 'Opdracht versturen naar de incassodienst?',
        //             })
        //           ).type === EResultType.Annuleren
        //         ) {
        //           return;
        //         }

        //         const params = { ids: [urlState.selectie[0]] };

        //         await api.v2.debiteur.incassozaak.versturenIncassoOpdrachten(params);

        //         await api.v2.debiteur.incassozaak.wijzigenDossiersstatus({
        //           ids: [urlState.selectie[0]],
        //           status: EIncassozaakStatus.Uithanden,
        //         });

        //         ophalenIncassozaken();
        //       }}
        //     >
        //       {/* <IconV style={{ width: 16, height: 16, fill: Kleur.Grijs }} /> */}
        //       <span className="ml-2">Versturen opdracht</span>
        //     </button>
        //   </div>
        // }
        body={
          <>
            {rechtzaken === null ? (
              <div className="d-flex flex-column flex-fill align-items-center justify-content-center">
                <LoadingSpinner />
              </div>
            ) : (
              <GridStyleWrapper height={'calc(100vh - 100px)'}>
                <Grid rows={rechtzaken} getRowId={keyExtractor} columns={kolommen}>
                  <DataTypeProvider
                    for={['__incassoDienst']}
                    formatterComponent={(props) => {
                      // const incassoDienst =
                      //   props.row.StatusnaamEnum === 'INCASSO'
                      //     ? props.row.incassoDienst !== null
                      //       ? props.row.incassoDienst.Naam
                      //       : ''
                      //     : '';
                      const incassoDienst =
                        props.row.incassoDienst !== null ? props.row.incassoDienst.Naam : '';

                      return <span>{incassoDienst}</span>;
                    }}
                  />

                  <DataTypeProvider
                    for={[nameOf<IOphalenRechtzakenResultElement>('Dossierdatum')]}
                    formatterComponent={(props) => {
                      const datum =
                        props.value !== null ? (
                          <span>{format(new Date(props.value), 'dd-MM-yyyy')}</span>
                        ) : (
                          <span></span>
                        );
                      return datum;
                    }}
                  />

                  <DataTypeProvider
                    for={['Hoofdsom']}
                    formatterComponent={(props) => <FormatteerBedrag bedrag={props.value} />}
                  />

                  <DataTypeProvider
                    for={[nameOf<IOphalenRechtzakenResultElement>('Afgehandeld')]}
                    formatterComponent={(formatterProps) => {
                      return <span>{formatterProps.value ? 'Ja' : 'Nee'}</span>;
                    }}
                  />

                  <SortingState defaultSorting={[]} />
                  <IntegratedSorting />

                  <EditingState
                    onCommitChanges={async (changes) => {
                      if (changes.deleted === undefined) {
                        return;
                      }
                      const deleted = changes.deleted;
                      const id = deleted[deleted.length - 1] as number;
                      await handleVerwijderen(id);
                    }}
                    onEditingRowIdsChange={(rowIds) => {
                      const id = rowIds[rowIds.length - 1] as number;
                      setUrlStateSync('wijzigenRechtzaakDialoogState', { id });
                    }}
                  />

                  <RowDetailState defaultExpandedRowIds={[]} />

                  <VirtualTable />
                  <VirtualTable messages={{ noData: 'Geen rechtzaken' }} />
                  <TableColumnResizing defaultColumnWidths={kolomBreedtes} />

                  <TableEditColumn
                    width={65}
                    showEditCommand
                    showDeleteCommand
                    cellComponent={DXTableEditColumnCellComponent}
                    commandComponent={DXTableEditColumnCommandComponent}
                  />
                  <TableHeaderRow showSortingControls />
                  <TableRowDetail
                    contentComponent={FacturenDetailComp}
                    toggleCellComponent={DXTableToggleCellComponent}
                  />
                  <SelectionState
                    selection={urlState.selectie}
                    onSelectionChange={(x) => setUrlStateSync('selectie', x as number[])}
                  />
                  <TableSelection cellComponent={DXTableCheckboxComponent} />
                </Grid>
              </GridStyleWrapper>
            )}
          </>
        }
      />
      {urlState.wijzigenRechtzaakDialoogState !== null && (
        <>
          <WijzigenRechtzaakDialoog
            open
            id={urlState.wijzigenRechtzaakDialoogState.id}
            onSuccess={() => {
              setUrlStateSync('wijzigenRechtzaakDialoogState', null);
              ophalenRechtzaken();
            }}
            onAnnuleren={() => setUrlStateSync('wijzigenRechtzaakDialoogState', null)}
          />
        </>
      )}
    </>
  );
});

export default withRouter(rechtzaken);
