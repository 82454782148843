import React, { useState, useEffect, useCallback, useMemo, useContext } from 'react';
import api from '../../../../../../../api';
import LoadingSpinner from '../../../../../../../components/Gedeeld/LoadingSpinner';
import { observer } from 'mobx-react-lite';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import useUrlState from '../../../../../../../core/useUrlState';
import {
  IOphalenFacturenBasisResultElement,
  IOphalenFacturenDossiersResultElement,
} from '../../../../../../../../../shared/src/api/v2/factuur';
import {
  IOphalenOpdrachtenResult,
  IOphalenOpdrachtenResultElement,
} from '../../../../../../../../../shared/src/api/v2/bank/opdracht/opdracht';

import { RootStoreContext } from '../../../../../../../stores/RootStore';
import {
  TypedColumn,
  TypedTableColumnWidthInfo,
  GridStyleWrapper,
  DXTableCheckboxComponent,
  DXTableToggleCellComponent,
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
} from '../../../../../../../helpers/dxTableGrid';
import { Kleur as EKleur } from '../../../../../../../bedrijfslogica/constanten';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  VirtualTable,
  TableEditColumn,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  DataTypeProvider,
  EditingState,
  IntegratedSorting,
  RowDetailState,
  SelectionState,
  SortingState,
  VirtualTable as VirtualTableBase,
} from '@devexpress/dx-react-grid';
import { format, getMonth, addMonths, getYear } from 'date-fns';
import FormatteerBedrag, {
  StandaardMutatieBedragOpmaker,
} from '../../../../../../../components/MutatieBedrag';
import { EResultType } from '../../../../../../../stores/CheckStore';
import {
  IconVerwijderen,
  IconPijlVolgend,
  IconInformatie,
  IconCopy,
} from '../../../../../../../components/Icons';
import WijzigenFactuurDialoog from '../../../../../../Facturering/Facturen/WijzigenFactuurDialoog';
import BankopdrachtDialoog from './NieuwBankopdrachtDialoog';
import * as _ from 'lodash';
import RegelsDetailComp from './RegelsDetailComp';
import SelectieVak from '../../../../../../../components/SelectieVak';
import FilterBalkV2, { IFilter, IFilterData } from '../../../../../../../components/FilterBalkV2';
import { IFilterSchema } from '../../../../../../../../../shared/src/models/filter';
import VerrekenenDialoog from '../../../../../../../components/factuur/VerrekenenDialoog';
import ToevoegenBetalingsregelingDialoog from './ToevoegenBetalingsregelingDialoog';
import ToevoegenIncassozaakDialoog from './ToevoegenIncassozaakDialoog';
import DownloadKnop from '../../../../../../../components/DownloadKnop';
import UitlegTooltip from '../../../../../../../components/formulier/UitlegTooltip';
import FacturenVersturenDialoog from '../../../../../../../components/factuur/VersturenDialoog';
import {
  EBetaalwijze,
  EDagboeksoort,
  EGrootboekrekening,
} from '../../../../../../../bedrijfslogica/enums';
import FactuurinfoDialoog from '../../../../../../../components/factuur/FactuurinfoDialoog';
import AfboekenDialoog from '../../../../../../../components/factuur/AfboekenDialoog';
import { dagDatum } from '../../../../../../../helpers/datum';
import {
  IOphalenBoekingenResultElement,
  IOphalenBoekingRegelsResultElement,
} from '../../../../../../../../../shared/src/api/v2/boekhouding/boeking';
import { IOphalenWebbetalingenResultElement } from '../../../../../../../../../shared/src/api/v2/website/betaling';
import { IOphalenAanmaningenResultElement } from '../../../../../../../../../shared/src/api/v2/aanmaning';
import {
  dossierIndicaties,
  sorteerFactuurnummer,
} from '../../../../../../../bedrijfslogica/conversie';
// import useFacturenVersturen from '../../../../../../../hooks/facturen/useFacturenVersturen';
import { GlobaleRendererContext } from '../../../../../../../one-off-components/GlobaleRenderer';
import BetaallinkVersturenDialoog from '../../../../../../../components/betaallink/VersturenDialoog';
import { title } from 'process';
import ActieMenuKnop from '../../../../../../../components/ActieMenuKnop';
import { url } from 'inspector';
import ToevoegenHerincassozaakDialoog from './ToevoegenHerincassozaakDialoog';

enum EFilter {
  AlleenOpenstaand = 'OPENSTAAND',
}

interface IProps extends RouteComponentProps {
  relID: number;
}

export interface IBetalingsregelingDialoogState {
  factIDs: number[];
}
export interface IIncassozaakDialoogState {
  factIDs: number[];
}

export interface IHerincassozaakDialoogState {
  factIDs: number[];
}

export interface IVersturenDialoogState {
  factIDs: number[];
}
export interface IFactuurinfoDialoogState {
  factID: number;
}

export interface IAfboekenDialoogState {
  factIDs: number[];
}
export interface IUrlState {
  selectie: number[];
  filterData: IFilterData<EFilter>[];
  verrekenenDialoogTonen: boolean;
  betalingsregelingDialoogState: IBetalingsregelingDialoogState | null;
  incassozaakDialoogState: IIncassozaakDialoogState | null;
  herincassozaakDialoogState: IHerincassozaakDialoogState | null;
  factuurinfoDialoogState: IFactuurinfoDialoogState | null;
  afboekenDialoogState: IAfboekenDialoogState | null;
  uitgeklapteFacturen: number[];
}

export const defaultUrlState: IUrlState = {
  selectie: [],
  filterData: [
    {
      naam: EFilter.AlleenOpenstaand,
      data: true,
      isActief: false,
    },
  ],
  verrekenenDialoogTonen: false,
  betalingsregelingDialoogState: null,
  incassozaakDialoogState: null,
  herincassozaakDialoogState: null,
  factuurinfoDialoogState: null,
  afboekenDialoogState: null,
  uitgeklapteFacturen: [],
};

const geenData = {
  noData: 'Geen facturen gevonden',
};

export interface IRow extends IOphalenFacturenBasisResultElement {
  bankopdrachten2: IOphalenOpdrachtenResultElement[];
}

export interface IBoekingregel extends IOphalenBoekingRegelsResultElement {
  boeking: IOphalenBoekingenResultElement;
}

interface ISignaleringerenCompProps {
  opdrachten: IOphalenOpdrachtenResultElement[];
  factuur: IOphalenFacturenBasisResultElement;
}

const SignaleringenComp: React.FC<ISignaleringerenCompProps> = observer((props) => {
  const { opdrachten, factuur } = props;

  const signaleringen = useMemo(() => {
    const signaleringen = [];
    if (factuur.Versturen) {
      signaleringen.push('Versturen');
    }
    if (factuur.NietVerrekenen) {
      signaleringen.push('Niet verrekenen');
    }
    if (opdrachten.length > 0) {
      signaleringen.push('Bankopdracht');
    }
    return signaleringen.join(', ');
  }, [factuur, opdrachten]);

  return (
    <>
      <UitlegTooltip inhoud={signaleringen}>
        <span>{signaleringen}</span>
      </UitlegTooltip>
    </>
  );
});

const Facturen: React.FC<IProps> = observer((props) => {
  const globaleRenderer = useContext(GlobaleRendererContext);
  const [urlState, setUrlState, setUrlStateSync] = useUrlState<IUrlState>(props, defaultUrlState);
  const [facturen, setFacturen] = useState<IOphalenFacturenBasisResultElement[] | null>(null);
  const [aanmaningen, setAanmaningen] = useState<IOphalenAanmaningenResultElement[] | null>(null);

  const { klantkaartStore, checkStore } = useContext(RootStoreContext);
  const [wijzigenID, setWijzigenID] = useState<number | null>(null);
  const [uitvoerdatum, setUitvoerdatum] = useState<Date | null>(null);
  const [bankopdrachtDialoogTonen, setBankopdrachtDialoogTonen] = useState<boolean>(false);
  const [versturenDialoogState, setVersturenDialoogState] = useState<IVersturenDialoogState | null>(
    null,
  );
  const [bankopdrachtenResult, setBankopdrachtenResult] = useState<IOphalenOpdrachtenResult | null>(
    null,
  );
  const [boekingregels, setBoekingregels] = useState<IBoekingregel[] | null>(null);
  const [dossiers, setDossiers] = useState<IOphalenFacturenDossiersResultElement[] | null>(null);
  const [webbetalingen, setWebbetalingen] = useState<IOphalenWebbetalingenResultElement[] | null>(
    null,
  );

  const [
    factuurinfoDialoogState,
    setFactuurinfoDialoogState,
  ] = useState<IFactuurinfoDialoogState | null>(null);
  const [afboekenDialoogState, setAfboekenDialoogState] = useState<IAfboekenDialoogState | null>(
    null,
  );

  const [filterSchema, setFilterSchema] = useState<IFilterSchema | null>(null);

  const relID = props.relID;

  const ophalenFacturen = useCallback(async () => {
    const facturenResult = await api.v2.factuur.ophalenFacturenBasis({
      filterSchema: {
        filters: [
          ...(filterSchema === null ? [] : filterSchema.filters!),
          {
            naam: 'REL_IDS',
            data: [relID],
          },
        ],
      },
      orderSchema: {
        orders: [
          {
            naam: 'FACTUURNUMMER_IS_NULL',
            richting: 'ASC',
          },
          {
            naam: 'FACTUURNUMMER',
            richting: 'DESC',
          },
          {
            naam: 'RECORD_TOEGEVOEGD',
            richting: 'DESC',
          },
        ],
      },
    });

    const facturen = facturenResult.facturen.map((factuur) => {
      return { ...factuur };
    });

    setFacturen(facturen);

    // Alleen ids geselecteerd houden die ook voorkomen in de lijst met mogelijke facturen
    const beschikbareIds = facturen.map(keyExtractor);
    const newSelection = urlState.selectie.filter((id) => beschikbareIds.indexOf(id) !== -1);
    setUrlStateSync('selectie', newSelection);
  }, [relID, filterSchema, urlState.selectie]);

  useEffect(() => {
    ophalenFacturen();
  }, [relID, filterSchema]);

  const ophalenAanmaningen = useCallback(async () => {
    if (facturen === null) {
      return;
    }
    const aanmaningenResult = (
      await api.v2.aanmaning.ophalenAanmaningen({
        filterSchema: {
          filters: [
            {
              naam: 'FACT_IDS',
              data: facturen.map((x) => x.FactID),
            },
          ],
        },
      })
    ).aanmaningen;

    setAanmaningen(aanmaningenResult);
  }, [facturen]);

  useEffect(() => {
    ophalenAanmaningen();
  }, [ophalenAanmaningen]);

  const ophalenFacturenDossiers = useCallback(async () => {
    if (facturen === null) {
      return;
    }
    const dossiersResult = await api.v2.factuur.ophalenFacturenDossiers({
      factIDs: facturen.map((x) => x.FactID),
    });

    setDossiers(dossiersResult.facturen);
  }, [facturen]);

  useEffect(() => {
    ophalenFacturenDossiers();
  }, [ophalenFacturenDossiers]);

  const ophalenBoekingen = useCallback(async () => {
    if (facturen === null) {
      return;
    }
    const boekingregelsResult = (
      await api.v2.boeking.ophalenBoekingregels({
        filterSchema: {
          filters: [
            {
              naam: 'FACT_IDS',
              data: facturen.map((x) => x.FactID),
            },
            {
              naam: 'DAGBOEK_SOORT_NAAMENUMS',
              data: [EDagboeksoort.MEMORIAAL],
            },
          ],
        },
      })
    ).regels;

    const boekingIDs = _.uniq(boekingregelsResult.map((x) => x.BoekingID));
    const boekingenResult = (
      await api.v2.boeking.ophalenBoekingen({
        filterSchema: {
          filters: [
            {
              naam: 'IDS',
              data: boekingIDs,
            },
          ],
        },
      })
    ).boekingen;

    // Voeg de boeking toe aan de regels

    const boekingregels = boekingregelsResult.map((x) => {
      const boeking = boekingenResult.find((b) => b.ID === x.BoekingID)!;
      return {
        ...x,
        boeking,
      };
    });

    setBoekingregels(boekingregels);
  }, [facturen]);

  useEffect(() => {
    ophalenBoekingen();
  }, [ophalenBoekingen]);

  const ophalenWebbetalingen = useCallback(async () => {
    if (facturen === null) {
      return;
    }
    const betalingenResult = (
      await api.v2.extern.betaling.ophalenBetalingen({
        filterSchema: {
          filters: [
            {
              naam: 'FACT_IDS',
              data: facturen.map((x) => x.FactID),
            },
          ],
        },
        orderSchema: {
          orders: [
            {
              naam: 'STARTDATUM',
              richting: 'DESC',
            },
          ],
        },
      })
    ).betalingen;

    setWebbetalingen(betalingenResult);
  }, [facturen]);

  useEffect(() => {
    ophalenWebbetalingen();
  }, [ophalenWebbetalingen]);

  const ophalenBankopdrachten = useCallback(async () => {
    if (facturen === null) {
      return;
    }
    const bankopdrachtenResult = await api.v2.bank.opdracht.ophalenOpdrachten({
      filterSchema: {
        filters: [
          {
            naam: 'FACT_IDS',
            data: facturen.map((x) => x.FactID),
          },
        ],
      },
    });
    setBankopdrachtenResult(bankopdrachtenResult);
  }, [facturen]);

  useEffect(() => {
    ophalenBankopdrachten();
  }, [ophalenBankopdrachten]);

  const handleVerwijderen = useCallback(async (factIDs: number[]) => {
    const checkData = await api.v2.factuur.checkVerwijderenFacturen({
      factIDs,
    });
    if (
      (
        await checkStore.controleren({
          checkData,
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }
    if (
      (
        await checkStore.bevestigen({
          inhoud: (
            <span>
              Wil je de factu(u)r(en) verwijderen?
              <br />
              De vorderingen komen daarmee weer vrij.
            </span>
          ),
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    await api.v2.factuur.verwijderenFacturen({
      factIDs,
    });

    setUrlStateSync('selectie', []);

    ophalenFacturen();
  }, []);

  const handleBoekenDubieuzeDebiteuren = useCallback(async () => {
    if (
      (
        await checkStore.bevestigen({
          inhoud: (
            <span>
              Wil je voor de geselecteerde facturen een boeking op de Dubieuze debiteuren maken?
              <br />
              <br />
              De facturen zelf blijven met het openstaande saldo staan. De boeking zelf kun je zien
              bij de boekingen van deze relatie in het dagboek Memoriaal.
            </span>
          ),
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    const factIDs = urlState.selectie;
    await api.v2.debiteur.boeking.boekenDubieuzeDebiteuren({ factIDs });
    ophalenFacturen();

    return;
  }, [urlState.selectie]);

  const handleAanmaningskostenAfboeken = useCallback(async () => {
    const factID = urlState.selectie[0];

    const grbRekID = (
      await api.v2.boeking.grootboek.ophalenGrootboeken({
        filterSchema: {
          filters: [
            {
              naam: 'NAAM_ENUMS',
              data: ['AANMANINGSKOSTEN'],
            },
          ],
        },
      })
    ).grootboeken[0].ID;

    const boekingsregels = (
      await api.v2.boeking.ophalenBoekingregels({
        filterSchema: {
          filters: [
            {
              naam: 'FACT_IDS',
              data: [factID],
            },
            {
              naam: 'GRBREK_IDS',
              data: [grbRekID],
            },
          ],
        },
      })
    ).regels;

    const aanmaningskosten = _.sum(boekingsregels.map((x) => x.Bedrag));

    if (aanmaningskosten === 0) {
      await checkStore.melden({
        titel: 'Er zijn geen aanmaningskosten voor deze factuur',
      });
      return;
    }

    if (
      (
        await checkStore.bevestigen({
          inhoud: <span>Wil je de aanmaningskosten (€ {aanmaningskosten}) afboeken?</span>,
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    const params = {
      facturen: [
        {
          factID,
          bedrag: aanmaningskosten,
        },
      ],
      grbRekID,
      omschrijving: null,
    };

    const result = await api.v2.boeking.afboekenVerkoopfacturen(params);

    ophalenFacturen();
  }, [urlState.selectie]);

  const handleDefinitiefMaken = useCallback(async (id: number) => {
    const checkData = await api.v2.factuur.checkDefinitiefMaken({
      factIDs: [id],
    });
    if (
      (
        await checkStore.controleren({
          checkData,
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }
    if (
      (
        await checkStore.bevestigen({
          inhoud: (
            <span>
              Wil je de factuur definitief maken?
              <br />
              Na definitief maken wordt de factuur zichtbaar voor de klant{' '}
            </span>
          ),
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    await api.v2.factuur.definitiefMaken({
      factIDs: [id],
    });

    ophalenFacturen();
  }, []);

  const handleBankopdracht = useCallback(
    async (ids: number[]) => {
      const checkData = await api.v2.factuur.checkMakenBankopdrachten({
        factIDs: ids,
      });
      if (
        (
          await checkStore.controleren({
            checkData,
          })
        ).type === EResultType.Annuleren
      ) {
        return;
      }

      const relatie = (
        await api.v2.relatie.ophalenRelaties({
          filterSchema: {
            filters: [
              {
                naam: 'IDS',
                data: [relID],
              },
            ],
          },
        })
      ).relaties[0];

      const maand = getMonth(new Date()) + 1;
      const jaar = getYear(new Date());

      const betaalDatum = (
        await api.v2.debiteur.bepaalStufidatum({
          betaaldag: relatie.debiteur!.Betaaldag,
          maand,
          jaar,
        })
      ).datum as string;

      const vandaag = dagDatum(new Date()).toISOString();

      const uitvoerdatum =
        betaalDatum > vandaag ? new Date(betaalDatum) : addMonths(new Date(betaalDatum), 1);

      setUitvoerdatum(new Date(uitvoerdatum));
      setBankopdrachtDialoogTonen(true);
    },
    [relID],
  );

  const handleBetalingsregeling = useCallback(async () => {
    const params = {
      relID: props.relID,
      factIDs: urlState.selectie,
    };
    const checkData = await api.v2.debiteur.betalingsregeling.checkSelectieToevoegenBetalingsregeling(
      params,
    );

    if (
      (
        await checkStore.controleren({
          checkData,
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    setUrlStateSync('betalingsregelingDialoogState', { factIDs: urlState.selectie });
    await ophalenFacturen();
  }, [urlState.selectie, klantkaartStore.relatie]);

  const handleIncassozaak = useCallback(async () => {
    const params = {
      relID: props.relID,
      factIDs: urlState.selectie,
      incDienstID: 4,
    };
    const checkData = await api.v2.debiteur.incassozaak.checkSelectieToevoegenIncassozaak(params);
    if (
      (
        await checkStore.controleren({
          checkData,
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    setUrlStateSync('incassozaakDialoogState', { factIDs: urlState.selectie });
    await ophalenFacturen();
  }, [urlState.selectie, klantkaartStore.relatie]);

  const handleHerincassozaak = useCallback(async () => {
    const params = {
      relID: props.relID,
      factIDs: urlState.selectie,
      incDienstID: 4,
    };
    const checkData = await api.v2.debiteur.herincassozaak.checkSelectieToevoegenHerincassozaak(
      params,
    );
    if (
      (
        await checkStore.controleren({
          checkData,
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    setUrlStateSync('herincassozaakDialoogState', { factIDs: urlState.selectie });
  }, [urlState.selectie, klantkaartStore.relatie]);

  const keyExtractor = useCallback((row: IOphalenFacturenBasisResultElement) => row.FactID, []);

  const kolommen = useMemo<TypedColumn<IOphalenFacturenBasisResultElement>[]>(
    () => [
      {
        name: '__factuurnummer' as any,
        title: 'Fact.nr.',
        getCellValue: (x) =>
          x.Factuurnummer !== null ? '0' + sorteerFactuurnummer(x.Factuurnummer) : '1',
      },
      {
        name: 'Factuurdatum',
        title: 'Fact.datum',
      },
      {
        name: 'Vervaldatum',
        title: 'Vervaldatum',
      },
      {
        name: '__infoKnop' as any,
        title: ' ',
      },
      {
        name: 'Bedrag',
        title: 'Bedrag',
        getCellValue: (x) => x.Bedrag,
      },
      {
        name: 'Openstaand',
        title: 'Openst.',
      },
      {
        name: '__betaalwijze' as any,
        title: 'Bet.wijze',
      },
      {
        name: '__bankopdracht' as any,

        title: (
          <span title={'Betreft de meest recente bankopdracht voor deze factuur indien aanwezig'}>
            Bankopd.
          </span>
        ) as any,
      },
      {
        name: '__info' as any,
        title: 'Info',
      },
      {
        name: '__aantalAanmaningen' as any,
        title: '#Anm.',
        getCellValue: (x: IOphalenFacturenBasisResultElement) => {
          if (aanmaningen === null) {
            return 0;
          }
          const aantalAanmaninen = aanmaningen.filter((a) =>
            a.facturen.some((f) => f.FactID === x.FactID),
          ).length;
          return <span>{aantalAanmaninen}</span>;
        },
      },
      // {
      //   name: '__downloadActie' as any,
      //   title: ' ',
      // },
      {
        name: '__actieMenu' as any,
        title: ' ',
      },
    ],
    [aanmaningen],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IOphalenFacturenBasisResultElement>[]>(
    () => [
      {
        columnName: '__factuurnummer' as any,
        width: 100,
      },
      {
        columnName: 'Factuurnummer',
        width: 90,
      },
      {
        columnName: '__infoKnop',
        width: 50,
      },
      {
        columnName: 'Factuurdatum',
        width: 110,
      },
      {
        columnName: 'Vervaldatum',
        width: 125,
      },
      {
        columnName: 'Bedrag',
        width: 90,
      },
      {
        columnName: '__betaalwijze',
        width: 110,
      },

      {
        columnName: 'Openstaand',
        width: 110,
      },
      {
        columnName: '__bankopdracht' as any,
        width: 140,
      },
      {
        columnName: '__aantalAanmaningen' as any,
        width: 80,
      },
      {
        columnName: '__info' as any,
        width: 135,
      },
      {
        columnName: '__downloadActie' as any,
        width: 50,
      },
      {
        columnName: '__actieMenu' as any,
        width: 50,
      },
    ],
    [],
  );

  const kolomExtensies: VirtualTableBase.ColumnExtension[] = useMemo(() => {
    return [
      // {
      //   columnName: `Bedrag`,
      //   align: 'right',
      // },
      // {
      //   columnName: `Openstaand`,
      //   align: 'right',
      // },
    ];
  }, []);

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.AlleenOpenstaand,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return <span>Alleen openstaand</span>;
        },
      },
    ],
    [],
  );

  // const { versturenFacturen } = useFacturenVersturen(
  //   useMemo(
  //     () => ({
  //       facturen:
  //         facturen?.filter((factuur) => urlState.selectie.indexOf(factuur.FactID) !== -1) ?? null,
  //     }),
  //     [facturen, urlState.selectie],
  //   ),
  // );

  return (
    <>
      {facturen === null ||
      aanmaningen === null ||
      dossiers === null ||
      boekingregels === null ||
      webbetalingen === null ? (
        <div className="flex-fill d-flex align-items-center justify-content-center p-4">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          <div
            className="d-flex flex-column p-3"
            style={{
              backgroundColor: EKleur.HeelLichtGrijs,
              borderBottom: `1px solid ${EKleur.LichtGrijs}`,
            }}
          >
            <div className="d-flex">
              <button
                className="btn btn-sm btn-light d-flex align-items-center"
                style={{
                  border: `1px solid ${EKleur.LichtGrijs}`,
                }}
                disabled={urlState.selectie.length === 0}
                onClick={() =>
                  setVersturenDialoogState({
                    factIDs: urlState.selectie,
                  })
                }
                // onClick={versturenFacturen}
              >
                <IconPijlVolgend
                  style={{
                    width: 16,
                    height: 16,
                    fill: EKleur.Grijs,
                  }}
                />
                <span className="ml-2">Versturen</span>
              </button>

              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-2"
                style={{
                  border: `1px solid ${EKleur.LichtGrijs}`,
                }}
                disabled={urlState.selectie.length === 0}
                onClick={async () => {
                  await globaleRenderer.render((renderProps) => (
                    <BetaallinkVersturenDialoog
                      open
                      onSuccess={() => renderProps.destroy()}
                      onAnnuleren={() => renderProps.destroy()}
                      relID={props.relID}
                      factIDs={urlState.selectie}
                    />
                  ));

                  await ophalenFacturen();
                }}
              >
                <IconPijlVolgend
                  style={{
                    width: 16,
                    height: 16,
                    fill: EKleur.Grijs,
                  }}
                />
                <span className="ml-2">Betaallink</span>
              </button>

              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-2"
                style={{
                  border: `1px solid ${EKleur.LichtGrijs}`,
                }}
                disabled={urlState.selectie.length === 0}
                onClick={() => handleBankopdracht(urlState.selectie)}
              >
                <IconPijlVolgend
                  style={{
                    width: 16,
                    height: 16,
                    fill: EKleur.Grijs,
                  }}
                />
                <span className="ml-2">Bankopdracht</span>
              </button>

              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-2"
                style={{
                  border: `1px solid ${EKleur.LichtGrijs}`,
                }}
                disabled={urlState.selectie.length === 0}
                onClick={async () => {
                  const checkData = await api.v2.factuur.checkSelectieVerrekenenFacturen({
                    factIDs: urlState.selectie,
                  });

                  if (
                    (await checkStore.controleren({ checkData })).type === EResultType.Annuleren
                  ) {
                    return;
                  }

                  return setUrlStateSync('verrekenenDialoogTonen', true);
                }}
              >
                <IconPijlVolgend
                  style={{
                    width: 16,
                    height: 16,
                    fill: EKleur.Grijs,
                  }}
                />
                <span className="ml-2">Verrekenen</span>
              </button>

              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-2"
                style={{
                  border: `1px solid ${EKleur.LichtGrijs}`,
                }}
                disabled={urlState.selectie.length === 0}
                onClick={async () => {
                  setAfboekenDialoogState({
                    factIDs: urlState.selectie,
                  });
                }}
              >
                {/* <IconPijlVolgend style={{ width: 16, height: 16, fill:  EKleur.Grijs }} /> */}
                <span className="ml-2">Afboeken</span>
              </button>

              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-2"
                style={{
                  border: `1px solid ${EKleur.LichtGrijs}`,
                }}
                disabled={urlState.selectie.length === 0}
                onClick={() => handleBetalingsregeling()}
              >
                {/* <IconPijlVolgend style={{ width: 16, height: 16, fill:  EKleur.Grijs }} /> */}
                <span className="ml-2">Betalingsregeling</span>
              </button>

              {/* <button
                className="btn btn-sm btn-light d-flex align-items-center ml-2"
                style={{
                  border: `1px solid ${EKleur.LichtGrijs}`,
                }}
                disabled={urlState.selectie.length === 0}
                onClick={() => handleIncassozaak()}
              >
                <span className="ml-2">Incassozaak</span>
              </button>

              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-2"
                style={{
                  border: `1px solid ${EKleur.LichtGrijs}`,
                }}
                disabled={urlState.selectie.length === 0}
                onClick={() => handleHerincassozaak()}
              >
                <span className="ml-2">Herincassozaak</span>
              </button> */}

              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-2"
                style={{
                  border: `1px solid ${EKleur.LichtGrijs}`,
                }}
                disabled={urlState.selectie.length === 0}
                onClick={() => handleVerwijderen(urlState.selectie)}
              >
                <IconVerwijderen
                  style={{
                    width: 16,
                    height: 16,
                    fill: EKleur.Grijs,
                  }}
                />
                <span className="ml-2">Verwijderen</span>
              </button>

              <div className="flex-fill" />

              <ActieMenuKnop
                acties={[
                  {
                    text: 'Crediteren facturen',
                    // icon: <IconCopy style={{ width: 16, height: 16, fill: EKleur.Grijs }} />,
                    disabled: urlState.selectie.length === 0,
                    onClick: async () => {
                      const params = {
                        factIDs: urlState.selectie,
                      };
                      const checkData = await api.v2.factuur.checkCrediterenFacturen(params);
                      const checkResult = await checkStore.controleren({
                        checkData,
                      });
                      if (checkResult.type === EResultType.Annuleren) {
                        return;
                      }

                      if (
                        (
                          await checkStore.bevestigen({
                            inhoud: (
                              <span>Voor de geselecteerde facturen een creditfactuur maken?</span>
                            ),
                          })
                        ).type === EResultType.Annuleren
                      ) {
                        return;
                      }

                      await api.v2.factuur.crediterenFacturen(params);
                      ophalenFacturen();
                    },
                  },
                  {
                    text: 'Kopieren factuur(regels)',
                    icon: <IconCopy style={{ width: 16, height: 16, fill: EKleur.Grijs }} />,
                    disabled: urlState.selectie.length !== 1,
                    onClick: async () => {
                      if (
                        (
                          await checkStore.bevestigen({
                            inhoud: (
                              <span>
                                Voor de geselecteerde factuur een kopie maken van de factuurregels?
                                <br />
                                <br />
                                Het resultaat vind je bij de vorderingen, daar kun je de factuur
                                opnieuw maken. Na kopieren wordt naar het tabje Vorderingen gegaan.
                              </span>
                            ),
                          })
                        ).type === EResultType.Annuleren
                      ) {
                        return;
                      }

                      const params = {
                        factID: urlState.selectie[0],
                      };

                      await api.v2.factuur.kopierenFactuur(params);
                      props.history.push(`/klant/${relID}/facturen/vorderingen`);
                    },
                  },
                  {
                    text: 'Afboeken aanmaningskosten',
                    // icon: <IconCopy style={{ width: 16, height: 16, fill: EKleur.Grijs }} />,
                    disabled: urlState.selectie.length !== 1,
                    onClick: async () => {
                      handleAanmaningskostenAfboeken();
                    },
                  },
                  {
                    text: 'Boeken op dubieuze debiteuren',
                    // icon: <IconCopy style={{ width: 16, height: 16, fill: EKleur.Grijs }} />,
                    disabled: urlState.selectie.length === 0,
                    onClick: async () => {
                      handleBoekenDubieuzeDebiteuren();
                    },
                  },
                  {
                    text: 'Facturen naar incassozaak',
                    // icon: <IconCopy style={{ width: 16, height: 16, fill: EKleur.Grijs }} />,
                    disabled: urlState.selectie.length === 0,
                    onClick: async () => {
                      handleIncassozaak();
                    },
                  },
                  {
                    text: 'Facturen naar herincassozaak',
                    // icon: <IconCopy style={{ width: 16, height: 16, fill: EKleur.Grijs }} />,
                    disabled: urlState.selectie.length === 0,
                    onClick: async () => {
                      handleHerincassozaak();
                    },
                  },
                ]}
              />
            </div>
            <div className="mt-2">
              <FilterBalkV2
                filters={filters}
                filterData={urlState.filterData}
                onFilterDataChange={(value) => setUrlStateSync('filterData', value)}
                onFilterSchemaChange={(schema) => setFilterSchema(schema)}
              />
            </div>
            <div className="mt-2">
              <SelectieVak
                aantal={urlState.selectie.length}
                totaalAantal={facturen!.length}
                onChange={(allesGeselecteerd) => {
                  if (allesGeselecteerd) {
                    setUrlStateSync('selectie', facturen!.map(keyExtractor));
                  } else {
                    setUrlStateSync('selectie', []);
                  }
                }}
                entiteitEnkelvoud="factuur"
                entiteitMeervoud="facturen"
              />
            </div>
          </div>
          <GridStyleWrapper height="calc(100vh - 150px)">
            <Grid columns={kolommen} getRowId={keyExtractor} rows={facturen!}>
              <DataTypeProvider
                for={['__betaalwijze']}
                formatterComponent={(formatterProps) => {
                  const rij = formatterProps.row as IOphalenFacturenBasisResultElement;

                  if (rij.Openstaand === 0 || rij.bankopdrachten.length !== 0) {
                    return <span></span>;
                  }

                  return <span>{rij.betaalwijze.Naam}</span>;
                }}
              />

              <DataTypeProvider
                for={['__aantalAanmaningen']}
                formatterComponent={(formatterProps) => {
                  const rij = formatterProps.row as IOphalenFacturenBasisResultElement;
                  const aantal = aanmaningen.filter(
                    (x) => x.Definitief && x.facturen.some((x) => x.FactID === rij.FactID),
                  ).length;

                  const nietAanmanen = rij.NietAanmanen !== null;
                  const titel = nietAanmanen ? 'NA betekent Niet aanmanen' : '';

                  return (
                    <span title={titel} style={{ color: nietAanmanen ? EKleur.Rood : undefined }}>
                      {aantal !== 0 ? aantal : ''}
                      {nietAanmanen ? ' (NA)' : ''}
                    </span>
                  );
                }}
              />

              <DataTypeProvider
                for={['__info']}
                formatterComponent={(formatterProps) => {
                  // const factuur: IOphalenFacturenBasisResultElement = formatterProps.row;
                  const rij = formatterProps.row as IOphalenFacturenBasisResultElement;

                  let infos = '';

                  // webbetalingen
                  const webbetalingenVoorFactuur = webbetalingen
                    .filter((x) => x.facturen.map((x) => x.FactID).indexOf(rij.FactID) !== -1)
                    .filter((x) => x.events.some((x) => x.Status === 1)); // TODO met enum
                  if (webbetalingenVoorFactuur.length > 0) {
                    infos += webbetalingenVoorFactuur[0].BetaalMethode + ' '; // Meest recente
                  }

                  // Memo-boekingen
                  const memoboekingen = boekingregels.filter((x) => x.FactID === rij.FactID);
                  if (memoboekingen.length > 0) {
                    infos += 'Memo ';
                  }

                  // Dossiers
                  const dossiersVoorFactuur = dossiers.find((x) => x.FactID === rij.FactID);
                  if (dossiersVoorFactuur === undefined) {
                    return <span />;
                  }

                  const indicaties = dossierIndicaties(dossiersVoorFactuur);
                  infos += indicaties;

                  // const indicatieBetalingsregeling =
                  //   dossiersVoorFactuur.betalingsregelingen.filter((x) => !x.Afgehandeld).length >
                  //   0;
                  // const indicatieIncassozaak =
                  //   dossiersVoorFactuur.incassozaken.filter((x) => !x.Afgehandeld).length > 0;
                  // const indicatieHerincassozaak =
                  //   dossiersVoorFactuur.herincassozaken.filter((x) => !x.Afgehandeld).length > 0;
                  // const indicatieRechtzaak =
                  //   dossiersVoorFactuur.rechtzaken.filter((x) => !x.Afgehandeld).length > 0;

                  // infos += indicatieBetalingsregeling ? 'BR ' : '';
                  // infos += indicatieIncassozaak ? 'IZ ' : '';
                  // infos += indicatieHerincassozaak ? 'HI ' : '';
                  // infos += indicatieRechtzaak ? 'RZ ' : '';

                  return <span>{infos.slice(0, -1)}</span>;

                  // if (bankopdrachtenResult === null) {
                  //   return <span />;
                  // }

                  // const opdrachten = bankopdrachtenResult.opdrachten.filter((opdracht) => {
                  //   const heeftDebiteurFactuur = opdracht.debiteurFacturen.some(
                  //     (debFact) => debFact.FactID === factuur.FactID,
                  //   );
                  //   const nietUitgevoerd =
                  //     opdracht.batch === null || opdracht.batch.UitgevoerdOp === null;
                  //   return heeftDebiteurFactuur && nietUitgevoerd;
                  // });

                  // // Indien er meer dan 1 lopende bankopdracht is wordt een ster weergegeven TODO: beter weergeven
                  // return (
                  //   <>
                  //     <SignaleringenComp factuur={factuur} opdrachten={opdrachten} />
                  //     <span>{opdrachten.length > 1 ? ' *' : ''}</span>
                  //   </>
                  // );
                }}

                // formatterComponent={(props) => {
                //   let signaleringen = ``;
                //   if (props.row.Versturen) {
                //     signaleringen += 'Aangeboden voor versturen, ';
                //   }
                //   if (props.row.NietVerrekenen) {
                //     signaleringen += 'Niet verrekenen, ';
                //   }
                //   signaleringen = signaleringen.slice(0, -2);

                //   return (
                //     <>
                //       <UitlegTooltip inhoud={signaleringen}>
                //         <span>{signaleringen}</span>
                //       </UitlegTooltip>
                //     </>
                //   );
                // }}
              />

              <DataTypeProvider
                for={['__infoKnop']}
                formatterComponent={(props) => {
                  return (
                    <>
                      <a
                        href="#"
                        className="ml-1"
                        style={{
                          color: EKleur.LichtBlauw,
                          position: 'relative',
                          bottom: 2,
                        }}
                        onClick={() => {
                          setFactuurinfoDialoogState({
                            factID: props.row.FactID,
                          });
                        }}
                      >
                        <IconInformatie
                          style={{
                            width: 15,
                            height: 15,
                            fill: EKleur.Blauw,
                          }}
                        />
                      </a>
                    </>
                  );
                }}
              />

              {/* <DataTypeProvider
                for={['__actieMenu']}
                formatterComponent={(props) => {
                  return (
                    <ActieMenuKnop
                      acties={[
                        {
                          text: 'Verwijderen uit voorstel',
                          disabled: props.row.Definitief,
                          onClick: () => handleVerwijderen(props.row.FactID),
                        },
                      ]}
                    />
                  );
                }}
              /> */}

              <DataTypeProvider
                for={['__factuurnummer']}
                formatterComponent={(props) => {
                  const row = props.row;
                  return row.Definitief ? (
                    <span>{row.Factuurnummer}</span>
                  ) : (
                    <span className="d-flex align-items-center">
                      <a href="#" onClick={async () => handleDefinitiefMaken(row.FactID)}>
                        <span>Def. maken</span>
                      </a>
                    </span>
                  );
                }}
              />

              {/* <DataTypeProvider
                for={['Factuurnummer']}
                formatterComponent={(props) => (
                  <span>{props.value !== null ? props.value : '00.000000'}</span>
                )}
              /> */}

              <DataTypeProvider
                for={['Factuurdatum']}
                formatterComponent={(props) => (
                  <span>{format(new Date(props.value), 'dd-MM-yyyy')}</span>
                )}
              />
              <DataTypeProvider
                for={['Vervaldatum']}
                formatterComponent={(props) => (
                  <span>{format(new Date(props.value), 'dd-MM-yyyy')}</span>
                )}
              />
              <DataTypeProvider
                for={['Bedrag']}
                formatterComponent={(props) => <FormatteerBedrag bedrag={props.value} />}
              />
              <DataTypeProvider
                for={['Openstaand']}
                formatterComponent={(props) => {
                  const rij: IOphalenFacturenBasisResultElement = props.row;
                  if (!rij.Geboekt) {
                    return <span>Niet geboekt</span>;
                  }

                  return (
                    <FormatteerBedrag
                      bedrag={props.value}
                      // rechtsUitlijnen
                      opmaakComponent={(opmaakProps) => {
                        const kleur = props.value !== 0 ? EKleur.Rood : EKleur.Groen;
                        return <StandaardMutatieBedragOpmaker {...opmaakProps} />;
                      }}
                    />
                  );
                }}
              />

              <DataTypeProvider
                for={['__bankopdracht']}
                formatterComponent={(props) => {
                  const row = props.row as IOphalenFacturenBasisResultElement;
                  // Bepaal de meest recente bankopdracht indien aanwezig

                  const bankopdrachtenGesorteerd = _.orderBy(
                    row.bankopdrachten,
                    ['Uitvoerdatum'],
                    ['desc'],
                  );

                  const bankopdracht =
                    row.bankopdrachten.length !== 0 ? bankopdrachtenGesorteerd[0] : null;

                  if (bankopdracht === null) {
                    // Geen bankopdracht
                    const nogMaken =
                      row.betaalwijze.NaamEnum === EBetaalwijze.Incasso && row.Openstaand > 0
                        ? 'Nog maken'
                        : row.betaalwijze.NaamEnum === EBetaalwijze.Verzamelbetaling &&
                          row.Openstaand < 0
                        ? 'Nog maken'
                        : '';
                    return <span>{nogMaken}</span>;
                  } else {
                    if (bankopdracht.stornomutatie !== null) {
                      return (
                        <UitlegTooltip
                          inhoud={
                            bankopdracht.stornomutatie.Returncode +
                            ' ' +
                            (bankopdracht.stornomutatie.stornoreden !== null
                              ? bankopdracht.stornomutatie.stornoreden.Naam
                              : 'Onbekende code')
                          }
                        >
                          <span>
                            {format(
                              new Date(bankopdracht.stornomutatie.Mutatiedatum),
                              'dd-MM-yyyy',
                            )}{' '}
                            Storno
                          </span>
                        </UitlegTooltip>
                      );
                    } else {
                      if (bankopdracht.batch === null) {
                        // Bankopdracht zit nog niet in een batch
                        return (
                          <span>
                            {bankopdracht.Uitvoerdatum !== null
                              ? format(new Date(bankopdracht.Uitvoerdatum), 'dd-MM-yyyy')
                              : 'Z.s.m.'}
                          </span>
                        );
                      } else {
                        // Bankopdracht zit in een batch
                        if (!bankopdracht.batch.IsUitgevoerd) {
                          // Batch is nog niet uitgevoerd
                          return (
                            <span>
                              {format(new Date(bankopdracht.batch.Uitvoerdatum), 'dd-MM-yyyy')} In
                              batch
                            </span>
                          );
                        } else {
                          // Batch is uitgevoerd
                          const datum =
                            bankopdracht.batch.UitgevoerdOp !== null
                              ? bankopdracht.batch.UitgevoerdOp
                              : bankopdracht.batch.Uitvoerdatum;

                          return <span>{format(new Date(datum), 'dd-MM-yyyy')} Uitgev. </span>;
                        }
                      }
                    }
                  }
                }}
              />

              <DataTypeProvider
                for={['__downloadActie']}
                formatterComponent={(props) => {
                  const row = props.row as IOphalenFacturenBasisResultElement;
                  const factID = row.FactID;

                  return (
                    <DownloadKnop
                      onDownloadAangevraagd={async () => {
                        const result = await api.v2.factuur.genereerFactuurPDF({
                          factID,
                        });
                        const downloadLink = result.bestand.url;
                        window.open(downloadLink, '_blank');
                      }}
                    />
                  );
                }}
              />

              {/* <DataTypeProvider
                for={['__lopendeDossiers']}
                formatterComponent={(props) => {
                  const row = props.row as IOphalenFacturenBasisResultElement;

                  if (dossiers === null) {
                    return <span></span>;
                  }
                  const indicatie = dossiers!.find((x) => x.FactID === row.FactID)!
                    .indicatieLopendeDossiers;
                  return <span>{indicatie}</span>;
                }}
              /> */}

              {/* <DataTypeProvider
                for={['__lopendeDossiers']}
                formatterComponent={(formatterProps) => {
                  const rij = formatterProps.row as IOphalenFacturenBasisResultElement;

                  const dossiersVoorFactuur = dossiers.find((x) => x.FactID === rij.FactID)!;
                  const indicatieBetalingsregeling =
                    dossiersVoorFactuur.betalingsregelingen.filter((x) => !x.Afgehandeld).length > 0
                      ? true
                      : false;
                  const indicatieIncassozaak =
                    dossiersVoorFactuur.incassozaken.filter((x) => !x.Afgehandeld).length > 0
                      ? true
                      : false;
                  const indicatieHerincassozaak =
                    dossiersVoorFactuur.herincassozaken.filter((x) => !x.Afgehandeld).length > 0
                      ? true
                      : false;
                  const indicatieRechtzaak =
                    dossiersVoorFactuur.rechtzaken.filter((x) => !x.Afgehandeld).length > 0
                      ? true
                      : false;

                  let dossiersIndicaties = '';
                  dossiersIndicaties += indicatieBetalingsregeling ? 'BR ' : '';
                  dossiersIndicaties += indicatieIncassozaak ? 'IZ ' : '';
                  dossiersIndicaties += indicatieHerincassozaak ? 'HI ' : '';
                  dossiersIndicaties += indicatieRechtzaak ? 'RZ ' : '';

                  return <span>{dossiersIndicaties.slice(0, -1)}</span>;
                }}
              /> */}

              <EditingState
                onCommitChanges={() => null}
                onEditingRowIdsChange={(rowIds) => {
                  const id = rowIds[rowIds.length - 1] as number;
                  setWijzigenID(id);
                }}
              />

              <SelectionState
                selection={urlState.selectie}
                onSelectionChange={(value) => setUrlStateSync('selectie', value as number[])}
              />
              {/* <RowDetailState defaultExpandedRowIds={[]} /> */}

              <RowDetailState
                expandedRowIds={urlState.uitgeklapteFacturen}
                onExpandedRowIdsChange={(x) =>
                  setUrlStateSync('uitgeklapteFacturen', x as number[])
                }
              />

              <SortingState
                defaultSorting={[
                  {
                    columnName: '__factuurnummer',
                    direction: 'desc',
                  },
                ]}
              />
              <IntegratedSorting />
              <VirtualTable messages={geenData} columnExtensions={kolomExtensies} />
              <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
              <TableHeaderRow showSortingControls />
              <TableEditColumn
                width={35}
                showEditCommand
                cellComponent={DXTableEditColumnCellComponent}
                commandComponent={DXTableEditColumnCommandComponent}
              />
              <TableRowDetail
                contentComponent={RegelsDetailComp}
                toggleCellComponent={DXTableToggleCellComponent}
              />
              <TableSelection cellComponent={DXTableCheckboxComponent} />
            </Grid>
          </GridStyleWrapper>
          <WijzigenFactuurDialoog
            factID={wijzigenID!}
            open={wijzigenID !== null}
            onAnnuleren={() => setWijzigenID(null)}
            onSuccess={() => {
              setWijzigenID(null);
              ophalenFacturen();
            }}
          />
          <BankopdrachtDialoog
            factIDs={urlState.selectie!}
            uitvoerdatum={uitvoerdatum !== null ? uitvoerdatum : undefined}
            open={bankopdrachtDialoogTonen}
            onAnnuleren={() => setBankopdrachtDialoogTonen(false)}
            onSuccess={() => {
              setBankopdrachtDialoogTonen(false);
              ophalenFacturen();
            }}
          />
          {urlState.verrekenenDialoogTonen && (
            <VerrekenenDialoog
              factIDs={urlState.selectie}
              open
              onSuccess={() => {
                setUrlStateSync('verrekenenDialoogTonen', false);
                ophalenFacturen();
              }}
              onAnnuleren={() => setUrlStateSync('verrekenenDialoogTonen', false)}
            />
          )}
          {urlState.betalingsregelingDialoogState && (
            <ToevoegenBetalingsregelingDialoog
              open
              relID={props.relID}
              factIDs={urlState.betalingsregelingDialoogState.factIDs}
              onSuccess={() => setUrlStateSync('betalingsregelingDialoogState', null)}
              onAnnuleren={() => setUrlStateSync('betalingsregelingDialoogState', null)}
            />
          )}
          {urlState.incassozaakDialoogState && (
            <ToevoegenIncassozaakDialoog
              open
              relID={props.relID}
              factIDs={urlState.incassozaakDialoogState.factIDs}
              onSuccess={() => {
                setUrlStateSync('incassozaakDialoogState', null);
                ophalenFacturen();
              }}
              onAnnuleren={() => setUrlStateSync('incassozaakDialoogState', null)}
            />
          )}
          {urlState.herincassozaakDialoogState && (
            <ToevoegenHerincassozaakDialoog
              open
              relID={props.relID}
              factIDs={urlState.herincassozaakDialoogState.factIDs}
              onSuccess={() => {
                setUrlStateSync('herincassozaakDialoogState', null);
                ophalenFacturen();
              }}
              onAnnuleren={() => setUrlStateSync('herincassozaakDialoogState', null)}
            />
          )}
          {versturenDialoogState !== null && (
            <FacturenVersturenDialoog
              open
              relID={props.relID}
              factIDs={versturenDialoogState.factIDs}
              standaardVerzendwijze={klantkaartStore.relatie!.debiteur!.VerzendwijzeFacturen}
              onSuccess={async () => {
                setVersturenDialoogState(null);
                await ophalenFacturen();
              }}
              onAnnuleren={() => setVersturenDialoogState(null)}
            />
          )}
          {factuurinfoDialoogState !== null && (
            <FactuurinfoDialoog
              open
              factID={factuurinfoDialoogState.factID}
              onSuccess={() => {
                setFactuurinfoDialoogState(null);
                ophalenFacturen();
              }}
              onAnnuleren={() => setFactuurinfoDialoogState(null)}
            />
          )}
          {afboekenDialoogState !== null && (
            <AfboekenDialoog
              open
              relID={relID}
              factIDs={afboekenDialoogState.factIDs}
              onSuccess={async () => {
                setAfboekenDialoogState(null);
                ophalenFacturen();

                await klantkaartStore.verversRelatie();
              }}
              onAnnuleren={() => setAfboekenDialoogState(null)}
            />
          )}
        </>
      )}
    </>
  );
});

export default withRouter(Facturen);
