import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  IAbonnementActie,
  IActieCode,
  IActiePeriode,
  IEntiteitActie,
  IOphalenActiesResult,
  IVerwijderenActiesParams,
} from '../../../../../../shared/src/api/v2/aanbod/tarieven/acties';
import api from '../../../../api';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import {
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
  DXTableToggleCellComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import {
  Grid,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  TableRowDetail,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import nameOf from '../../../../core/nameOf';
import { DataTypeProvider, EditingState, RowDetailState } from '@devexpress/dx-react-grid';
import RelatieVisualisatie from '../../../../components/personalia/RelatieVisualisatie';
import { RootStoreContext } from '../../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import { IconVink, IconKruis, IconToevoegen } from '../../../../components/Icons';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import useUrlState from '../../../../core/useUrlState';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ToevoegenActieDialoog, { EActieType } from './ToevoegenActieDialoog';
import FilterBalkV2, {
  IFilter,
  IFilterData,
  maakFilterSchema,
} from '../../../../components/FilterBalkV2';
import DatumKiezer from '../../../../components/formulier/DatumKiezer';
import { dagDatum, datumsZijnOpDezelfdeDag } from '../../../../helpers/datum';
import TabelDetailRegel from './TabelDetailRegel';
import { addDays, format } from 'date-fns';
import { EResultType } from '../../../../stores/CheckStore';
import EinddatumOpgevenDialoog from './EinddatumOpgevenDialoog';
import WijzigenActieDialoog from './WijzigenActieDialoog';
import * as _ from 'lodash';
import { IOphalenBestandenResultElement } from '../../../../../../shared/src/api/v2/bestand/bestand';
import { Helmet } from 'react-helmet';
import { BooleanLiteral } from 'typescript';
import GebruikerRij from '../../../Dashboard/OnlineGebruikers/GebruikerRij';

enum EFilter {
  Peildatum = 'PEILDATUM',
}

export interface IActieregel {
  TarActieID: number;
  Actief: boolean;
  IsHoofdactie: Boolean;
  WeergevenOpOverzicht: boolean;
  RelID: number | null;
  Stapelactie: boolean;
  Naam: string | null;
  Actienaam_TekstID: number | null;
  ActieInfo_TekstID: number | null;
  afbeelding: IOphalenBestandenResultElement | null;
  ModelInfo_TekstID: number | null;
  Nummer: number | null;
  abonnementActies: IAbonnementActie[];
  actiePerioden: IActiePeriode[];
  actieCodes: IActieCode[];
  entiteitActies: IEntiteitActie[];
  onVerversenAangevraagd: () => void;
}

interface IToevoegenActieDialoogState {
  initieelActieType?: EActieType;
}

interface IUrlState {
  wijzigenActieDialoogState: number | null;
  toevoegenActieDialoogState: IToevoegenActieDialoogState | null;
  filterData: IFilterData<EFilter>[];
  uitgeklapt: number[];
  einddatumOpgevenTarActieID: number | null;
}

const defaultUrlState: IUrlState = {
  wijzigenActieDialoogState: null,
  toevoegenActieDialoogState: null,
  filterData: [
    {
      naam: EFilter.Peildatum,
      data: dagDatum(new Date()).toISOString(),
      isActief: true,
    },
  ],
  uitgeklapt: [],
  einddatumOpgevenTarActieID: null,
};

export interface IActiesContext {
  verversen: () => Promise<void>;
}
export const ActiesContext = React.createContext<IActiesContext>(null as any);

interface IProps extends RouteComponentProps {}

const Acties: React.FC<IProps> = observer((props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const { abonnementenStore, checkStore } = useContext(RootStoreContext);
  const [actiesResult, setActiesResult] = useState<IOphalenActiesResult | null>(null);

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.Peildatum,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          const datum = new Date(weergaveProps.data);
          return (
            <span className="d-flex align-items-center">
              <span>Peildatum</span>
              <span className="ml-3">
                <DatumKiezer
                  waarde={datum}
                  onGewijzigd={(datum) => {
                    weergaveProps.onDataChange(datum === null ? null : datum.toISOString());
                    weergaveProps.toepassen();
                  }}
                  determinePreviousValidDate={(date) => {
                    return addDays(date, -1);
                  }}
                  determineNextValidDate={(date) => {
                    return addDays(date, +1);
                  }}
                />
              </span>
              {datumsZijnOpDezelfdeDag(new Date(), datum) && (
                <span className="ml-2">
                  <a
                    href="#"
                    onClick={() => {
                      weergaveProps.onDataChange(new Date().toISOString());
                      weergaveProps.toepassen();
                    }}
                  >
                    Vandaag
                  </a>
                </span>
              )}
            </span>
          );
        },
      },
    ],
    [],
  );
  const [filterSchema, setFilterSchema] = useState(maakFilterSchema(urlState.filterData));

  useEffect(() => {
    if (abonnementenStore.abonnementen !== null) {
      return;
    }

    abonnementenStore.ophalenAbonnementen();
  }, []);

  const ophalenActiesData = useCallback(async () => {
    const result = await api.v2.aanbod.tarieven.acties.ophalenActies({
      filterSchema,
    });

    const actiesGesorteerd = _.orderBy(result.acties, ['Nummer'], ['desc']);
    const resultGesorteerd = { ...result, acties: actiesGesorteerd };

    setActiesResult(resultGesorteerd);
  }, [setActiesResult, filterSchema]);

  useEffect(() => {
    ophalenActiesData();
  }, [ophalenActiesData]);

  const rows: IActieregel[] | null = useMemo(() => {
    if (actiesResult === null) {
      return null;
    }

    return actiesResult.acties.map((actie) => {
      const abonnementActies = actiesResult.abonnementActies.filter(
        (x) => x.TarActieID === actie.TarActieID,
      );
      const actiePerioden = actiesResult.actiePerioden.filter(
        (x) => x.TarActieID === actie.TarActieID,
      );
      const actieCodes = actiesResult.actieCodes.filter((x) => x.TarActieID === actie.TarActieID);
      const entiteitActies = actiesResult.entiteitActies.filter(
        (x) => x.TarActieID === actie.TarActieID,
      );

      return {
        ...actie,
        abonnementActies,
        actiePerioden,
        actieCodes,
        entiteitActies,
        onVerversenAangevraagd: ophalenActiesData,
      };
    });
  }, [actiesResult, ophalenActiesData]);

  const gridData = useMemo(() => {
    if (rows === null || abonnementenStore.abonnementen === null) {
      return null;
    }

    return { rows, abonnementen: abonnementenStore.abonnementen };
  }, [rows, abonnementenStore.abonnementen]);

  const kolommen = useMemo<TypedColumn<IActieregel>[]>(() => {
    const peildatumAan =
      urlState.filterData.findIndex((x) => x.naam === EFilter.Peildatum && x.isActief) !== -1;
    return [
      {
        name: 'Nummer',
        title: 'Nr.',
      },
      {
        name: 'Naam',
        title: 'Naam',
      },
      // {
      //   name: '__hoofdActie' as any,
      //   title: 'Hoofdactie',
      // },
      {
        name: '__weergevenOpOverzicht' as any,
        title: 'Op ovz.',
      },
      {
        name: 'Actief',
        title: 'Actief',
      },
      peildatumAan
        ? {
            name: '__ingangsdatum',
            title: 'Ingangsdatum',
          }
        : null,
      peildatumAan
        ? {
            name: '__einddatum',
            title: 'Einddatum',
          }
        : null,
      {
        name: 'RelID',
        title: 'Openbaar/Relatie',
      },
      {
        name: '__productSelectie' as any,
        title: 'Productselectie',
      },
      {
        name: '__abonnementen' as any,
        title: 'Abonnementen',
      },
      {
        name: '__periodeVan' as any,
        title: 'Vanaf',
      },
      {
        name: '__periodeTot' as any,
        title: 'Tot',
      },
      {
        name: 'actieCodes',
        title: 'Code(s)',
      },
    ].filter((x) => x !== null) as TypedColumn<IActieregel>[];
  }, [urlState.filterData]);

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IActieregel>[]>(
    () => [
      {
        columnName: 'Nummer',
        width: 50,
      },
      {
        columnName: 'Naam',
        width: 325,
      },
      {
        columnName: '__ingangsdatum',
        width: 125,
      },
      {
        columnName: '__einddatum',
        width: 125,
      },
      {
        columnName: 'RelID',
        width: 190,
      },
      {
        columnName: '__abonnementen' as any,
        width: 175,
      },
      {
        columnName: 'actieCodes',
        width: 150,
      },
      {
        columnName: '__hoofdActie' as any,
        width: 100,
      },
      {
        columnName: '__weergevenOpOverzicht' as any,
        width: 100,
      },
      {
        columnName: '__productSelectie' as any,
        width: 200,
      },
      {
        columnName: 'Actief' as any,
        width: 100,
      },
      {
        columnName: '__periodeVan' as any,
        width: 110,
      },
      {
        columnName: '__periodeTot' as any,
        width: 110,
      },
    ],
    [],
  );
  const keyExtractor = useCallback((row: IActieregel) => row.TarActieID, []);

  const actiesContextValue = useMemo<IActiesContext>(
    () => ({
      verversen: async () => {
        await ophalenActiesData();
      },
    }),
    [ophalenActiesData],
  );

  return (
    <ActiesContext.Provider value={actiesContextValue}>
      <Helmet>
        <title>Actietarieven</title>
      </Helmet>
      <div
        className="d-flex flex-column p-3"
        style={{
          backgroundColor: Kleur.HeelLichtGrijs,
          borderBottom: `1px solid ${Kleur.LichtGrijs}`,
        }}
      >
        <div>
          <FilterBalkV2
            filters={filters}
            filterData={urlState.filterData}
            onFilterDataChange={(data) => setUrlStateSync('filterData', data)}
            onFilterSchemaChange={(schema) => setFilterSchema(schema)}
          />

          <div className="mt-2 d-flex align-items-center">
            <button
              className="btn btn-sm btn-light d-flex align-items-center"
              style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
              onClick={() =>
                setUrlStateSync('toevoegenActieDialoogState', {
                  initieelActieType: undefined,
                })
              }
            >
              <IconToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
              <span className="ml-2">Toevoegen</span>
            </button>
          </div>
        </div>
      </div>

      <div>
        {gridData === null ? (
          <div className="flex-fill d-flex align-items-center justify-content-center">
            <LoadingSpinner />
          </div>
        ) : (
          <GridStyleWrapper height="calc(100vh - 200px)">
            <Grid rows={gridData.rows} columns={kolommen} getRowId={keyExtractor}>
              <DataTypeProvider
                for={[nameOf<IActieregel>('Actief')]}
                formatterComponent={(formatterProps) => {
                  if (formatterProps.value) {
                    return <span>Ja</span>;
                  }
                  return <span style={{ color: Kleur.Rood }}>Nee</span>;
                }}
              />

              <DataTypeProvider
                for={['__periodeVan']}
                formatterComponent={(formatterProps) => {
                  const rij: IActieregel = formatterProps.row;

                  const actuelePeriode =
                    rij.actiePerioden.find(
                      (x) =>
                        new Date(x.Ingangsdatum) <= new Date() &&
                        (x.Einddatum === null || new Date(x.Einddatum) > new Date()),
                    ) ?? null;

                  return (
                    <span>
                      {actuelePeriode !== null
                        ? format(new Date(actuelePeriode.Ingangsdatum), 'dd-MM-yyyy')
                        : ''}
                    </span>
                  );
                }}
              />

              <DataTypeProvider
                for={['__periodeTot']}
                formatterComponent={(formatterProps) => {
                  const rij: IActieregel = formatterProps.row;

                  const actuelePeriode =
                    rij.actiePerioden.find(
                      (x) =>
                        new Date(x.Ingangsdatum) <= new Date() &&
                        (x.Einddatum === null || new Date(x.Einddatum) > new Date()),
                    ) ?? null;

                  return (
                    <span>
                      {actuelePeriode !== null
                        ? actuelePeriode.Einddatum !== null
                          ? format(new Date(actuelePeriode.Einddatum), 'dd-MM-yyyy')
                          : ''
                        : ''}
                    </span>
                  );
                }}
              />
              <DataTypeProvider
                for={['__ingangsdatum']}
                formatterComponent={(formatterProps) => {
                  const row: IActieregel = formatterProps.row;
                  const peildatum = new Date(
                    urlState.filterData.find((x) => x.naam === EFilter.Peildatum)!.data,
                  );
                  const periode = row.actiePerioden.find((x) =>
                    new Date(x.Ingangsdatum) <= peildatum && x.Einddatum === null
                      ? true
                      : new Date(x.Einddatum!) > peildatum,
                  );
                  if (periode === undefined) {
                    return <span />;
                  }
                  return <span>{format(new Date(periode.Ingangsdatum), 'dd-MM-yyyy')}</span>;
                }}
              />

              <DataTypeProvider
                for={['__einddatum']}
                formatterComponent={(formatterProps) => {
                  const row: IActieregel = formatterProps.row;
                  const peildatum = new Date(
                    urlState.filterData.find((x) => x.naam === EFilter.Peildatum)!.data,
                  );
                  const periode = row.actiePerioden.find((x) =>
                    new Date(x.Ingangsdatum) <= peildatum && x.Einddatum === null
                      ? true
                      : new Date(x.Einddatum!) > peildatum,
                  );
                  if (periode === undefined) {
                    return <span />;
                  }
                  if (periode.Einddatum === null) {
                    return <span />;
                  }
                  return <span>{format(new Date(periode.Einddatum), 'dd-MM-yyyy')}</span>;
                }}
              />

              <DataTypeProvider
                for={[nameOf<IActieregel>('RelID')]}
                formatterComponent={(formatterProps) => {
                  const row: IActieregel = formatterProps.row;
                  if (row.RelID === null) {
                    return <span>Openbaar</span>;
                  }

                  return <RelatieVisualisatie relID={row.RelID} />;
                }}
              />
              <DataTypeProvider
                for={['__abonnementen']}
                formatterComponent={(formatterProps) => {
                  const row: IActieregel = formatterProps.row;
                  if (row.abonnementActies.length === 0) {
                    return <span />;
                  }

                  const abonIDs = row.abonnementActies.map((x) => x.AbonID);
                  const abons = gridData!.abonnementen.filter(
                    (abon) => abonIDs.indexOf(abon.AbonID) !== -1,
                  );
                  const abonsRepr = abons.map((abon) => abon.NaamKort).join(', ');

                  return <span>{abonsRepr}</span>;
                }}
              />
              <DataTypeProvider
                for={[nameOf<IActieregel>('actieCodes')]}
                formatterComponent={(formatterProps) => {
                  const row: IActieregel = formatterProps.row;
                  return (
                    <span>
                      {row.actieCodes.map((actieCode, i) => (
                        <span>
                          {actieCode.Actiecode}
                          {i === row.actieCodes.length - 1 ? null : ', '}
                        </span>
                      ))}
                    </span>
                  );
                }}
              />

              {/* <DataTypeProvider
                for={['__hoofdActie']}
                formatterComponent={(formatterProps) => {
                  const row: IActieregel = formatterProps.row;
                  if (row.RelID !== null) {
                    return <span>Nvt</span>;
                  }
                  if (actiesResult!.hoofdActie_TarActieID === row.TarActieID) {
                    return (
                      <span
                        onClick={async () => {
                          if (
                            (
                              await checkStore.bevestigen({
                                inhoud: 'Bevestig ontkoppelen hoofd actie',
                              })
                            ).type === EResultType.Annuleren
                          ) {
                            return;
                          }
                          await api.v2.aanbod.tarieven.acties.toewijzenHoofdActie({
                            tarActieID: null,
                          });
                          await ophalenActiesData();
                        }}
                        className="dx-g-bs4-cursor-pointer"
                      >
                        <IconVink style={{ width: 20, height: 20, fill: Kleur.Zwart }} />
                      </span>
                    );
                  }

                  return (
                    <a
                      href="#"
                      onClick={async () => {
                        await api.v2.aanbod.tarieven.acties.toewijzenHoofdActie({
                          tarActieID: row.TarActieID,
                        });
                        await ophalenActiesData();
                      }}
                    >
                      Kies
                    </a>
                  );
                }}
              /> */}

              <DataTypeProvider
                for={['__hoofdActie']}
                formatterComponent={(formatterProps) => {
                  const row: IActieregel = formatterProps.row;
                  return <span>{row.IsHoofdactie ? 'Ja' : 'Nee'}</span>;
                }}
              />

              <DataTypeProvider
                for={['__weergevenOpOverzicht']}
                formatterComponent={(formatterProps) => {
                  const row: IActieregel = formatterProps.row;
                  return <span>{row.WeergevenOpOverzicht ? 'Ja' : 'Nee'}</span>;
                }}
              />

              <DataTypeProvider
                for={['__productSelectie']}
                formatterComponent={(formatterProps) => {
                  const row: IActieregel = formatterProps.row;
                  return (
                    <span>
                      {row.entiteitActies.map((entiteitActie, i) => (
                        <span>
                          {entiteitActie.visualisatie}
                          {i === row.entiteitActies.length - 1 ? null : ', '}
                        </span>
                      ))}
                    </span>
                  );
                }}
              />

              <EditingState
                onCommitChanges={async (changeset) => {
                  if (changeset.deleted !== undefined && changeset.deleted.length > 0) {
                    const params: IVerwijderenActiesParams = {
                      tarActieIDs: changeset.deleted as number[],
                    };
                    const checkData = await api.v2.aanbod.tarieven.acties.checkVerwijderenActies(
                      params,
                    );
                    if (
                      (await checkStore.controleren({ checkData })).type === EResultType.Annuleren
                    ) {
                      return;
                    }
                    if (
                      (await checkStore.bevestigen({ inhoud: 'Verwijderen?' })).type ===
                      EResultType.Annuleren
                    ) {
                      return;
                    }
                    await api.v2.aanbod.tarieven.acties.verwijderenActies(params);
                    await ophalenActiesData();
                  }
                }}
                onEditingRowIdsChange={(ids) => {
                  const id = ids[ids.length - 1] as number;
                  setUrlStateSync('wijzigenActieDialoogState', id as number);
                }}
              />
              <RowDetailState
                expandedRowIds={urlState.uitgeklapt}
                onExpandedRowIdsChange={(x) => setUrlStateSync('uitgeklapt', x as number[])}
              />

              <VirtualTable />
              <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
              <TableHeaderRow />
              <TableEditColumn
                width={65}
                showEditCommand
                showDeleteCommand
                cellComponent={DXTableEditColumnCellComponent}
                commandComponent={DXTableEditColumnCommandComponent}
              />
              <TableRowDetail
                toggleCellComponent={DXTableToggleCellComponent}
                contentComponent={TabelDetailRegel}
              />
            </Grid>
          </GridStyleWrapper>
        )}
      </div>

      {urlState.wijzigenActieDialoogState !== null && (
        <WijzigenActieDialoog
          open
          onSuccess={(result) => {
            setUrlStateSync('wijzigenActieDialoogState', null);
            ophalenActiesData();
          }}
          tarActieID={urlState.wijzigenActieDialoogState}
          onAnnuleren={() => setUrlStateSync('wijzigenActieDialoogState', null)}
        />
      )}
      {urlState.toevoegenActieDialoogState !== null && (
        <ToevoegenActieDialoog
          open
          onSuccess={(result) => {
            setUrlStateSync('toevoegenActieDialoogState', null);
            ophalenActiesData();
          }}
          onAnnuleren={() => setUrlStateSync('toevoegenActieDialoogState', null)}
          initieelActieType={urlState.toevoegenActieDialoogState.initieelActieType}
        />
      )}
      {urlState.einddatumOpgevenTarActieID !== null && (
        <EinddatumOpgevenDialoog
          open
          onSuccess={(result) => {
            setUrlStateSync('einddatumOpgevenTarActieID', null);
          }}
          onAnnuleren={() => setUrlStateSync('einddatumOpgevenTarActieID', null)}
        />
      )}
    </ActiesContext.Provider>
  );
});

export default withRouter(Acties);
