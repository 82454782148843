import { makeObservable, observable } from 'mobx';
import { RootStore } from '../RootStore';
import api from '../../api';
import {
  BerekenenParams,
  IInfoIndicatieGewijzigdMessageData,
  InfoIndicatieSoort,
  IOphalenInfoIndicatiesParams,
} from '../../../../shared/src/api/v2/infoIndicatie';

export const paramsToParamsKey = (params: BerekenenParams['params']): string =>
  JSON.stringify(params);
export const paramsKeyToParams = (paramsKey: string): BerekenenParams['params'] =>
  JSON.parse(paramsKey);

class InfoIndicatieStore {
  public indicaties: Record<InfoIndicatieSoort, { [paramsKey: string]: number }> = {
    KLANTKAART_CONTRACTEN: {},
    KLANTKAART_PERSONEN: {},
    KLANTKAART_TRANSPORT: {},
    KLANTKAART_SERVICE: {},
    KLANTKAART_FACTUREN: {},
    KLANTKAART_ACCOUNTS: {},
    KLANTKAART_WINKELWAGENS: {},
  };
  private rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    makeObservable(this, {
      indicaties: observable,
    });
  }

  public notificeerBenodidigd = async (indicaties: IOphalenInfoIndicatiesParams['indicaties']) => {
    const result = await api.v2.infoIndicatie.ophalenInfoIndicaties({
      indicaties,
    });

    this.indicaties = Object.entries(result.indicaties).reduce(
      (acc, [soort, params]) => ({
        ...acc,
        [soort]: {
          ...acc[soort as InfoIndicatieSoort],
          ...params,
        },
      }),
      this.indicaties,
    );
  };

  public handleInfoIndicatieGewijzigdMessageData = (
    data: IInfoIndicatieGewijzigdMessageData<BerekenenParams['params']>,
  ) => {
    this.indicaties = {
      ...this.indicaties,
      [data.soort]: {
        ...this.indicaties[data.soort],
        [paramsToParamsKey(data.params)]: data.aantal,
      },
    };
  };
}

export default InfoIndicatieStore;
