import React, { useContext, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import LoadingSpinner from '../../../components/Gedeeld/LoadingSpinner';
import { RootStoreContext } from '../../../stores/RootStore';
import { observer } from 'mobx-react-lite';

interface IProps extends RouteComponentProps {}

const Dashboard: React.FC<IProps> = observer((props) => {
  return <span>Dashboard</span>;
});

export default Dashboard;
