import React from 'react';
import Product from './Product';
import Verkoopmodel from './Verkoopmodel';
import Contractbeeindiging from './Contractbeeindiging';
import { TableRowDetail } from '@devexpress/dx-react-grid';
import { IRow } from '../index';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import VerticaleScheidingslijn from '../../../layout/VerticaleScheidingslijn';
import GegevensLayout from '../../../layout/GegevensLayout';
import { TableRowDetailWrapper } from '../../../../helpers/dxTableGrid';
import { format } from 'date-fns';
import { formatteerAdresV2 } from '../../../../helpers';

const RowDetail = (props: TableRowDetail.ContentProps) => {
  const row: IRow = props.row;

  return (
    <TableRowDetailWrapper>
      <div className="d-flex flex-fill">
        <div style={{ minWidth: 40, backgroundColor: Kleur.LichtGrijs }} />

        <div className="d-flex flex-column flex-fill" style={{ backgroundColor: Kleur.Wit }}>
          <div className="d-flex p-3">
            <div style={{ width: 175 }}>
              <GegevensLayout
                gegevens={[
                  {
                    label: 'Aantal afvoer',
                    waarde: <span>{row.AantalAfvoer}</span>,
                  },
                  {
                    label: 'Verdieping',
                    waarde: <span>{row.Verdieping}</span>,
                  },
                  {
                    label: 'Lift aanwezig',
                    waarde: <span>{row.locatie.LiftAanwezig ? 'Ja' : 'Nee'}</span>,
                  },
                ]}
              />
            </div>
            <div style={{ width: 200 }}>
              <GegevensLayout
                gegevens={[
                  {
                    label: 'Actiecode',
                    waarde: <span>{row.Actiecode !== null ? row.Actiecode : ''}</span>,
                  },
                  {
                    label: 'Wervingscode',
                    waarde: <span>{row.Wervingscode !== null ? row.Wervingscode : ''}</span>,
                  },
                  {
                    label: 'Overstapcode',
                    waarde: <span>{row.Overstapcode !== null ? row.Overstapcode : ''}</span>,
                  },
                ]}
              />
            </div>

            <div style={{ width: 325 }}>
              {row.Opmerking !== null ? <span>{row.Opmerking}</span> : <span>Geen opmerking</span>}

              {row.onderwijsinstelling !== null ? (
                <div>
                  {row.onderwijsinstelling.Naam} {row.onderwijsinstelling.Plaatsnaam}
                </div>
              ) : (
                <div>Geen onderwijsinstelling opgegeven</div>
              )}
            </div>

            <div style={{ width: 225 }}>
              <GegevensLayout
                gegevens={[
                  {
                    label: 'Account',
                    waarde: <span>{row.account !== null ? row.account.Gebruikersnaam : ''}</span>,
                  },
                  {
                    label: 'Ingelogd',
                    waarde: (
                      <span>{row.Ingelogd !== null ? (row.Ingelogd ? 'Ja' : 'Nee') : '-'}</span>
                    ),
                  },
                  {
                    label: 'Geregistreerd',
                    waarde: (
                      <span>{format(new Date(row.RecordToegevoegd), 'dd-MM-yyyy HH:mm')}</span>
                    ),
                  },
                  {
                    label: 'Gewijzigd',
                    waarde: (
                      <span>
                        {row.RecordGewijzigd !== null
                          ? format(new Date(row.RecordGewijzigd), 'dd-MM-yyyy HH:mm')
                          : ''}
                      </span>
                    ),
                  },
                ]}
              />
            </div>
          </div>
          <div className="d-flex flex-column flex-fill ml-2 pt-1 pb-3 p-2">
            <span>
              {formatteerAdresV2({
                plaatsnaam: row.locatie.Plaatsnaam,
                huisnummer: row.locatie.Huisnummer,
                straatnaam: row.locatie.Straatnaam,
                postcode: row.locatie.Postcode,
                bisnummer: row.locatie.Bisnummer,
              })}
            </span>
          </div>

          <div className="d-flex flex-column flex-fill">
            <Product row={row} />
          </div>

          {row.regels.some((x) => x.Soort === 3) && (
            <div className="d-flex flex-column flex-fill mt-3">
              <Contractbeeindiging row={row} />
            </div>
          )}

          {row.regels.some((x) => x.Soort === 2) && (
            <div className="d-flex flex-fill mt-3">
              <div className="d-flex flex-column flex-fill mt-3">
                <Verkoopmodel row={row} />
              </div>
            </div>
          )}
        </div>
      </div>
    </TableRowDetailWrapper>
  );
};

export default RowDetail;
