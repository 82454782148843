import React from 'react';
import { Kleur } from '../../../bedrijfslogica/constanten';

interface IProps {
  className?: string;
  color?: string;
  height?: number | string;
  width?: number | string;
  style?: React.CSSProperties;
}

const VerticaleScheidingslijn = (props: IProps) => {
  const { className, color, height, width } = props;
  return (
    <div
      className={className}
      style={{
        backgroundColor: color || Kleur.LichtGrijs,
        // height: height || '100%',
        minHeight: height || '100%',
        maxHeight: height || '100%',
        minWidth: width || 1,
        maxWidth: width || 1,
        ...props.style,
      }}
    />
  );
};

export default VerticaleScheidingslijn;
