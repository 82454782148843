import { RefObject, useMemo, useRef, useState } from 'react';

export interface IUseStateControlParams<T> {
  defaultUncontrolledState?: T;
  controlledState?: T;
  onControlledStateChange?: (controlledState: T) => void;
}
export type IUseStateControlOutput<T> = [T, (state: T) => void, RefObject<T>];

const useStateControl = <T>(params: IUseStateControlParams<T>): IUseStateControlOutput<T> => {
  const [uncontrolledState, setUncontrolledState] = useState<T | undefined>(
    params.defaultUncontrolledState,
  );

  const state = useMemo((): T => {
    if (params.controlledState !== undefined) {
      return params.controlledState;
    }
    if (params.defaultUncontrolledState !== undefined) {
      return uncontrolledState!;
    }
    throw new Error('No state provided');
  }, [params.controlledState, params.defaultUncontrolledState, uncontrolledState]);

  const ref = useRef(state);

  return [
    state,
    (s: T) => {
      ref.current = s;
      if (params.defaultUncontrolledState !== undefined) {
        setUncontrolledState(s);
      }
      params.onControlledStateChange?.(s);
    },
    ref,
  ];
};

export default useStateControl;
