import api from '../../../index';
import {
  IOphalenTypenParametersParams,
  IOphalenTypenParametersResult,
  IToevoegenTypeParametersParams,
  IToevoegenTypeParametersResult,
  IVerwijderenTypenParametersParams,
  IVerwijderenTypenParametersResult,
  IWijzigenTypeParametersParams,
  IWijzigenTypeParametersResult,
} from '../../../../../../shared/src/api/v2/inkoop/parameters';
import ICheckData from '../../../../../../shared/src/models/ICheckData';

const parameters = {
  ophalenTypenParameters: async (
    params: IOphalenTypenParametersParams,
  ): Promise<IOphalenTypenParametersResult> => {
    return await api.post('v2/inkoop/parameters/ophalen-typen-parameters', params);
  },
  checkToevoegenTypeParameters: async (
    params: IToevoegenTypeParametersParams,
  ): Promise<ICheckData> => {
    return await api.post('v2/inkoop/parameters/check-toevoegen-type-parameters', params);
  },
  toevoegenTypeParameters: async (
    params: IToevoegenTypeParametersParams,
  ): Promise<IToevoegenTypeParametersResult> => {
    return await api.post('v2/inkoop/parameters/toevoegen-type-parameters', params);
  },
  verwijderenTypenParameters: async (
    params: IVerwijderenTypenParametersParams,
  ): Promise<IVerwijderenTypenParametersResult> => {
    return await api.post('v2/inkoop/parameters/verwijderen-typen-parameters', params);
  },
  wijzigenTypeParameters: async (
    params: IWijzigenTypeParametersParams,
  ): Promise<IWijzigenTypeParametersResult> => {
    return await api.post('v2/inkoop/parameters/wijzigen-type-parameters', params);
  },
};

export default parameters;
