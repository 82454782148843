import { EVerkennerItemType } from './TabelWeergave/types';
import { vanVerkennerItemId } from './helpers';
import api from '../../api';
import CheckStore, { EResultType } from '../../stores/CheckStore';
import {
  IHernoemenBestandParams,
  IHernoemenBestandsmapParams,
} from '../../../../shared/src/api/v2/bestand/bestand';
import { IBestandVerkennerItem, IMapVerkennerItem, IVerkennerItem } from './types';

interface IVerkennerItemHernoemenParams {
  id: string;
  naam: string;
  checkStore: CheckStore;
  items: Record<number, IVerkennerItem>;
  onItemsChange: (items: Record<number, IVerkennerItem>) => void;
}

const verkennerItemHernoemen = async (params: IVerkennerItemHernoemenParams) => {
  const { id, naam, checkStore, items, onItemsChange } = params;

  const idx = Object.keys(items)
    .map(Number)
    .find((idx) => items[idx].id === id)!;

  const newItems = {
    ...items,
    [idx]: {
      ...items[idx],
      bestand:
        items[idx].type === EVerkennerItemType.Bestand
          ? {
              ...(items[idx] as IBestandVerkennerItem).bestand,
              Naam: naam,
            }
          : (undefined as any),
      map:
        items[idx].type === EVerkennerItemType.Map
          ? {
              ...(items[idx] as IMapVerkennerItem).map,
              Naam: naam,
            }
          : (undefined as any),
    },
  };

  // Optimistisch bijwerken
  onItemsChange(newItems);

  const parsedId = vanVerkennerItemId(id);
  switch (parsedId.type) {
    case EVerkennerItemType.Bestand: {
      const params: IHernoemenBestandParams = {
        bestandID: parsedId.id,
        naam,
      };
      const checkData = await api.v2.bestand.checkHernoemenBestand(params);
      if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
        // Herstellen
        onItemsChange(items);
        return;
      }
      await api.v2.bestand.hernoemenBestand(params);
      break;
    }
    case EVerkennerItemType.Map: {
      const params: IHernoemenBestandsmapParams = {
        bestandsmapID: parsedId.id,
        naam,
      };
      const checkData = await api.v2.bestand.checkHernoemenBestandsmap(params);
      if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
        // Herstellen
        onItemsChange(items);
        return;
      }
      await api.v2.bestand.hernoemenBestandsmap(params);
      break;
    }
  }
};

export default verkennerItemHernoemen;
