import React from 'react';
import { Kleur } from '../../../bedrijfslogica/constanten';

interface IProps {}

const TabelInspringBlok: React.FC<IProps> = (props) => {
  return (
    <div
      style={{ backgroundColor: Kleur.LichtGrijs, minWidth: 50, maxWidth: 50, height: '100%' }}
    />
  );
};

export default TabelInspringBlok;
