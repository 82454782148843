import React, { useMemo } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Klantgegevens from './Klantgegevens';
import DocumentenComp from '../../../../../../components/relatie/Bestanden';
import Mutaties from './Mutaties';
import KlantWerftKlant from './KlantWerftKlant';
// import ReviewsComp from './Reviews';
import Toegevoegd from './Toegevoegd';
import Vervangen from './Vervangen';
import Tabblad, { ITabblad } from '../../../../../../components/layout/Tabblad';
import { IEntiteitProps } from '../../../../../../components/kaart/EntiteitContainer';
import Signaleringen from '../../../../../../components/kaart/relatie/Signaleringen';
// import Rekeningen from './Rekeningen';
import useUrlTabblad from '../../../../../../core/useUrlTabblad';

interface IProps extends IEntiteitProps, RouteComponentProps {}

export enum ETabblad {
  Klantgegevens = 1,
  // Documenten = 2,
  Kredietwaardigheid = 3,
  Mutaties = 4,
  KlantWerftKlant = 5,
  Toegevoegd = 6,
  // Rekeningen = 7,
  // Reviews = 8,
  Vervangen = 9,
}

const pathMap: Record<ETabblad, string> = {
  [ETabblad.Klantgegevens]: '/klantgegevens',
  // [ETabblad.Documenten]: '/documenten',
  [ETabblad.Kredietwaardigheid]: '/kredietwaardigheid',
  [ETabblad.Mutaties]: '/mutaties',
  [ETabblad.KlantWerftKlant]: '/klant-werft-klant',
  [ETabblad.Toegevoegd]: '/toegevoegd',
  // [ETabblad.Rekeningen]: '/rekeningen',
  // [ETabblad.Reviews]: '/reviews',
  [ETabblad.Vervangen]: '/vervangen',
};

export interface IKlantContext {
  relID: number;
}
export const KlantContext = React.createContext<IKlantContext>(null as any);

const Klant: React.FC<IProps> = (props) => {
  const { tabblad, onChange: onTabbladChange } = useUrlTabblad<ETabblad>(
    props,
    pathMap,
    ETabblad.Klantgegevens,
  );

  const tabbladen = useMemo<ITabblad<ETabblad, { relID: number }>[]>(
    () => [
      {
        id: ETabblad.Klantgegevens,
        label: 'Klantgegevens',
        content: Klantgegevens,
      },
      {
        id: ETabblad.Mutaties,
        label: 'Mutaties',
        content: Mutaties,
      },
      // {
      //   id: ETabblad.Rekeningen,
      //   label: 'Rekeningen',
      //   content: Rekeningen,
      // },
      // {
      //   id: ETabblad.Documenten,
      //   label: 'Documenten',
      //   content: DocumentenComp,
      // },
      // {
      //   id: ETabblad.Reviews,
      //   label: 'Reviews',
      //   content: ReviewsComp,
      // },
      {
        id: ETabblad.KlantWerftKlant,
        label: 'KwK-acties',
        content: KlantWerftKlant,
      },
      {
        id: ETabblad.Vervangen,
        label: 'Vervangen',
        content: Vervangen,
      },
      {
        id: ETabblad.Toegevoegd,
        label: 'Toegevoegd',
        content: Toegevoegd,
      },
    ],
    [],
  );

  const context = useMemo<IKlantContext>(
    () => ({
      relID: props.relID,
    }),
    [props.relID],
  );

  return (
    <KlantContext.Provider value={context}>
      <div className="d-flex flex-column flex-fill flex-fill">
        {tabblad !== null && (
          <Tabblad<ETabblad>
            geselecteerd={tabblad}
            onSelectieChange={onTabbladChange}
            tabbladen={tabbladen}
            options={{
              tabbladComponentProps: {
                relID: props.relID,
              },
            }}
          />
        )}
      </div>
    </KlantContext.Provider>
  );
};

export default withRouter(Klant);
