import { DataTypeProvider } from '@devexpress/dx-react-grid';
import {
  Grid,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import _ from 'lodash';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import api from '../../../../api';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import MenuLayout from '../../../../components/MenuLayout';
import FormatteerBedrag from '../../../../components/MutatieBedrag';
import useUrlState from '../../../../core/useUrlState';
import {
  DXTableCheckboxComponent,
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import { RootStoreContext } from '../../../../stores/RootStore';
import {
  EditingState,
  IntegratedSorting,
  RowDetailState,
  SelectionState,
  SortingState,
  VirtualTable as VirtualTableBase,
} from '@devexpress/dx-react-grid';
import { format } from 'date-fns';
import NieuweAfschrijvingDialoog from './NieuweAfschrijvingDialoog';
import { EResultType } from '../../../../stores/CheckStore';
import { IBerekenenAfschrijvingenResultElement } from '../../../../../../shared/src/api/v2/boekhouding/activaregister/afschrijvingen';
import { IOphalenBoekingRegelsResultElement } from '../../../../../../shared/src/api/v2/boekhouding/boeking';
import { EGrootboekrekening, EGrootboekrekeningNaam } from '../../../../bedrijfslogica/enums';
import { Helmet } from 'react-helmet';

interface IProps extends RouteComponentProps {}

interface IUrlState {
  selectie: number[];
  uitgeklapt: number[];
}

const defaultUrlState: IUrlState = {
  selectie: [],
  uitgeklapt: [],
};

export interface INieuweAfschrijvingDialoogState {
  open: boolean;
}

const Afschrijvingen: React.FC<IProps> = (props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState<IUrlState>(props, defaultUrlState);
  const { checkStore } = useContext(RootStoreContext);

  const [afschrijvingen, setAfschrijvingen] = useState<
    IBerekenenAfschrijvingenResultElement[] | null
  >(null);
  const [boekingregels, setBoekingregels] = useState<IOphalenBoekingRegelsResultElement[] | null>(
    null,
  );

  const [
    nieuweAfschrijvingDialoogState,
    setNieuweAfschrijvingDialoogState,
  ] = useState<INieuweAfschrijvingDialoogState | null>(null);
  const [bezigMetOphalen, setBezigMetOphalen] = useState<boolean>(false);

  const ophalenAfschrijvingen = useCallback(async () => {
    setBezigMetOphalen(true);

    const jaarNu = new Date().getFullYear();
    const maandNu = new Date().getMonth() + 1;

    const afschrijvingenResult = await api.v2.boekhouding.activaregister.afschrijvingen.berekenenAfschrijvingen(
      {
        periodeVem: {
          jaar: jaarNu - 2,
          maand: 1,
        },
        periodeTem: {
          jaar: jaarNu,
          maand: maandNu,
        },
      },
      // {
      //   periodeVem: { jaar: jaarNu, maand: 1 },
      //   periodeTem: { jaar: jaarNu, maand: maandNu },
      // },
    );

    const afschrijvingenGesorteerd = _.orderBy(
      afschrijvingenResult.afschrijvingen,
      [
        (x: IBerekenenAfschrijvingenResultElement) => {
          return x.periode.jaar;
        },
        (x: IBerekenenAfschrijvingenResultElement) => {
          return x.periode.maand;
        },
      ],
      ['desc', 'desc'],
    );

    setAfschrijvingen(afschrijvingenGesorteerd);
    setBezigMetOphalen(false);
  }, []);

  const kolommen = useMemo<TypedColumn<IBerekenenAfschrijvingenResultElement>[]>(
    () => [
      {
        name: '__jaar' as any,
        title: 'Jaar',
      },
      {
        name: '__maand' as any,
        title: 'Maand',
      },
      {
        name: '__bedragAfschrijving' as any,
        title: 'Afschrijvingsbedrag',
      },
      {
        name: '__bedragAfschrijvingCumulatief' as any,
        title: 'Cumulatief Afschrijving',
      },
      {
        name: '__bedragGeboektPeriode' as any,
        title: 'Geboekt in periode',
      },
      {
        name: '__bedragGeboektPeriodeCumulatief' as any,
        title: 'Cumulatief geboekt',
      },
      {
        name: '__nogTeBoeken' as any,
        title: 'Nog te boeken',
      },
      {
        name: '__actieBoeken' as any,
        title: ' ',
      },
    ],
    [],
  );
  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IBerekenenAfschrijvingenResultElement>[]>(
    () => [
      {
        columnName: '__jaar' as any,
        width: 80,
      },
      {
        columnName: '__maand' as any,
        width: 90,
      },
      {
        columnName: '__bedragAfschrijving' as any,
        width: 165,
      },
      {
        columnName: '__bedragAfschrijvingCumulatief' as any,
        width: 200,
      },
      {
        columnName: '__bedragGeboektPeriode' as any,
        width: 200,
      },
      {
        columnName: '__bedragGeboektPeriodeCumulatief' as any,
        width: 200,
      },
      {
        columnName: '__nogTeBoeken' as any,
        width: 165,
      },
      {
        columnName: '__actieBoeken' as any,
        width: 200,
      },
    ],
    [],
  );

  const kolomExtensies: VirtualTableBase.ColumnExtension[] = useMemo(() => {
    return [
      {
        columnName: 'bedrag',
        align: 'right',
      },
      {
        columnName: 'geboekt',
        align: 'right',
      },
    ];
  }, []);

  const keyExtractor = useCallback(
    (afschrijving: IBerekenenAfschrijvingenResultElement) =>
      `${afschrijving.periode.jaar}-${afschrijving.periode.maand}`,
    [],
  );

  return (
    <>
      <Helmet>
        <title>Afschrijvingen</title>
      </Helmet>
      <MenuLayout
        menu={
          <div>
            {/* <button
              className="btn btn-sm btn-light d-flex align-items-center"
              style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
              // disabled={urlState.selectie.length !== 1}
              onClick={() => {
                setNieuweAfschrijvingDialoogState({ open: true });
              }}
            >
              <span className="ml-2">Afschrijving bepalen</span>
            </button> */}
            <button
              className="btn btn-sm btn-light d-flex align-items-center"
              style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
              disabled={bezigMetOphalen}
              onClick={async () => {
                if (
                  (
                    await checkStore.bevestigen({
                      inhoud: (
                        <span>
                          Wil je de actuele gegevens ophalen?
                          <br />
                          <br />
                          Dit kan soms enkele seconden duren.
                        </span>
                      ),
                    })
                  ).type === EResultType.Annuleren
                ) {
                  return;
                }
                await ophalenAfschrijvingen();
              }}
            >
              <span className="ml-2">Ophalen actuele waarden</span>
            </button>
          </div>
        }
        body={
          bezigMetOphalen ? (
            <div className="flex-fill d-flex align-items-center justify-content-center">
              <LoadingSpinner />
            </div>
          ) : afschrijvingen === null ? (
            <div className="flex-fill d-flex align-items-center justify-content-center">
              Klik op 'Ophalen actuele waarden' om gegevens weer te geven
            </div>
          ) : (
            <>
              <GridStyleWrapper height={'calc(100vh - 200px)'}>
                <Grid rows={afschrijvingen} columns={kolommen} getRowId={keyExtractor}>
                  <DataTypeProvider
                    for={['__jaar']}
                    formatterComponent={(formatterProps) => {
                      const rij: IBerekenenAfschrijvingenResultElement = formatterProps.row;
                      return <span>{rij.periode.jaar}</span>;
                    }}
                  />

                  <DataTypeProvider
                    for={['__maand']}
                    formatterComponent={(formatterProps) => {
                      const rij: IBerekenenAfschrijvingenResultElement = formatterProps.row;
                      return <span>{rij.periode.maand}</span>;
                    }}
                  />

                  <DataTypeProvider
                    for={['__bedragAfschrijving']}
                    formatterComponent={(formatterProps) => {
                      const rij: IBerekenenAfschrijvingenResultElement = formatterProps.row;
                      return <FormatteerBedrag bedrag={rij.afschrijvingVoorPeriode} />;
                    }}
                  />

                  <DataTypeProvider
                    for={['__bedragAfschrijvingCumulatief']}
                    formatterComponent={(formatterProps) => {
                      const rij: IBerekenenAfschrijvingenResultElement = formatterProps.row;
                      return <FormatteerBedrag bedrag={rij.afschrijvingCumulatief} />;
                    }}
                  />

                  <DataTypeProvider
                    for={['__bedragGeboektPeriode']}
                    formatterComponent={(formatterProps) => {
                      const rij: IBerekenenAfschrijvingenResultElement = formatterProps.row;
                      return <FormatteerBedrag bedrag={rij.bedragGeboektPeriode} />;
                    }}
                  />

                  <DataTypeProvider
                    for={['__bedragGeboektPeriodeCumulatief']}
                    formatterComponent={(formatterProps) => {
                      const rij: IBerekenenAfschrijvingenResultElement = formatterProps.row;
                      return <FormatteerBedrag bedrag={rij.geboektCumulatief} />;
                    }}
                  />

                  <DataTypeProvider
                    for={['__nogTeBoeken']}
                    formatterComponent={(formatterProps) => {
                      const rij: IBerekenenAfschrijvingenResultElement = formatterProps.row;
                      return <FormatteerBedrag bedrag={rij.nogTeBoeken} />;
                    }}
                  />

                  <DataTypeProvider
                    for={['__actieBoeken']}
                    formatterComponent={(formatterProps) => {
                      const rij: IBerekenenAfschrijvingenResultElement = formatterProps.row;

                      if (rij.nogTeBoeken === 0) {
                        return <span></span>;
                      }

                      return (
                        <span className="ml-2">
                          <a
                            href="#"
                            onClick={async () => {
                              if (
                                (
                                  await checkStore.bevestigen({
                                    inhoud: (
                                      <span>
                                        Boeking maken in het Memoriaal?
                                        <br />
                                        <br />
                                        Na het maken zullen de actuele waarden opnieuw opgehaald
                                        worden.
                                        <br />
                                        <br />
                                        Als je de boeking ongedaan wilt maken dan dien je de boeking
                                        te verwijderen in het Memoriaal.
                                      </span>
                                    ),
                                  })
                                ).type === EResultType.Annuleren
                              ) {
                                return;
                              }

                              await api.v2.boekhouding.activaregister.afschrijvingen.makenBoeking({
                                jaar: rij.periode.jaar,
                                maand: rij.periode.maand,
                                bedrag: rij.nogTeBoeken,
                              });

                              await ophalenAfschrijvingen();
                            }}
                          >
                            Maak boeking
                          </a>
                        </span>
                      );
                    }}
                  />

                  {/* <DataTypeProvider
                    for={['__jaar']}
                    formatterComponent={(formatterProps) => {
                      const rij: IBerekenenAfschrijvingenResultElement = formatterProps.row;
                      return <FormatteerBedrag bedrag={formatterProps.value} rechtsUitlijnen />;
                    }}
                  /> */}

                  <SortingState defaultSorting={[]} />
                  <IntegratedSorting />

                  <RowDetailState
                    expandedRowIds={urlState.uitgeklapt}
                    onExpandedRowIdsChange={(x) => setUrlStateSync('uitgeklapt', x as number[])}
                  />

                  <VirtualTable columnExtensions={kolomExtensies} />
                  <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
                  <TableHeaderRow showSortingControls />

                  <EditingState
                    onCommitChanges={async (changes) => {
                      if (changes.deleted === undefined) {
                        return;
                      }
                      const deleted = changes.deleted;
                      const id = deleted[deleted.length - 1] as number;

                      const params = { ids: [id] };

                      const checkData = await api.v2.boekhouding.activaregister.afschrijvingen.checkVerwijderenAfschrijvingen(
                        params,
                      );
                      const checkResult = await checkStore.controleren({
                        checkData,
                      });
                      if (checkResult.type === EResultType.Annuleren) {
                        return;
                      }

                      if (
                        (
                          await checkStore.bevestigen({
                            inhoud: `Deze afschrijving verwijderen?`,
                          })
                        ).type === EResultType.Annuleren
                      ) {
                        return;
                      }

                      await api.v2.boekhouding.activaregister.afschrijvingen.verwijderenAfschrijvingen(
                        params,
                      );
                      ophalenAfschrijvingen();
                    }}
                  />
                  <TableEditColumn
                    width={35}
                    showDeleteCommand
                    cellComponent={DXTableEditColumnCellComponent}
                    commandComponent={DXTableEditColumnCommandComponent}
                  />
                  <SelectionState
                    selection={urlState.selectie}
                    onSelectionChange={(x) => setUrlStateSync('selectie', x as number[])}
                  />
                  <TableSelection cellComponent={DXTableCheckboxComponent} />
                </Grid>
              </GridStyleWrapper>
            </>
          )
        }
      />
      {nieuweAfschrijvingDialoogState !== null && (
        <NieuweAfschrijvingDialoog
          open
          onSuccess={() => {
            setNieuweAfschrijvingDialoogState(null);
            ophalenAfschrijvingen();
          }}
          onAnnuleren={() => setNieuweAfschrijvingDialoogState(null)}
        />
      )}
    </>
  );
};

export default withRouter(Afschrijvingen);
