import React, { useContext, useEffect, useMemo, useState, useCallback } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import LoadingSpinner from '../../../components/Gedeeld/LoadingSpinner';
import useUrlState from '../../../core/useUrlState';
import api from '../../../api';
import { addMonths, format } from 'date-fns';
import _ from 'lodash';
import {
  DXTableCheckboxComponent,
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
  DXTableToggleCellComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../helpers/dxTableGrid';
import {
  Grid,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  DataTypeProvider,
  EditingState,
  RowDetailState,
  SelectionState,
} from '@devexpress/dx-react-grid';
import nameof from '../../../core/nameOf';
import DetailComp from './DetailComp';
import { IOphalenKrediettoetsenResultElement } from '../../../../../shared/src/api/v2/kredietwaardigheid';
import PersoonVisualisatie from '../../../components/personalia/PersoonVisualisatie';
import OrganisatieVisualisatie from '../../../components/personalia/OrganisatieVisualisatie';

interface IProps extends RouteComponentProps {}

interface IUrlState {}

const defaultUrlState: IUrlState = {};

export interface IRegel extends IOphalenKrediettoetsenResultElement {}

const Krediettoetsen: React.FC<IProps> = observer((props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);

  const [toetsen, setToetsen] = useState<IRegel[] | null>(null);

  const ophalenToetsen = useCallback(async () => {
    // const peildatum = addMonths(new Date(), -2);
    const toetsen = (
      await api.v2.kredietwaardigheid.ophalenKrediettoetsen({
        filterSchema: {
          filters: [
            // { naam: 'STATUS_NAAMENUM', data: [EContractStatus.Geannuleerd] },
          ],
        },
        orderSchema: { orders: [{ naam: 'DATUM', richting: 'DESC' }] },
      })
    ).toetsen;

    setToetsen(toetsen);
  }, []);

  useEffect(() => {
    ophalenToetsen();
  }, [ophalenToetsen]);

  const keyExtractor = useCallback((row: IRegel) => row.ID, []);

  const kolommen = useMemo<TypedColumn<IRegel>[]>(
    () => [
      {
        name: 'Datum',
        title: 'Datum',
      },
      {
        name: '__subject' as any,
        title: 'Subject',
      },
      {
        name: 'Score',
        title: 'Score',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRegel>[]>(
    () => [
      {
        columnName: 'Datum',
        width: 150,
      },
      {
        columnName: '__subject' as any,
        width: 250,
      },
      {
        columnName: 'Score',
        width: 100,
      },
    ],
    [],
  );

  return (
    <>
      {toetsen === null ? (
        <div className="d-flex flex-fill align-items-center justify-content-center">
          <LoadingSpinner />
        </div>
      ) : (
        <GridStyleWrapper height={'calc(100vh - 150px)'}>
          <Grid rows={toetsen} columns={kolommen} getRowId={keyExtractor}>
            <DataTypeProvider
              for={[nameof<IRegel>('Datum')]}
              formatterComponent={(props) => (
                <span>{format(new Date(props.value), 'dd-MM-yyyy HH:mm')}</span>
              )}
            />

            <DataTypeProvider
              for={['__subject']}
              formatterComponent={(props) => {
                const rij: IRegel = props.row;

                if (rij.persoon !== null) {
                  return (
                    <PersoonVisualisatie
                      persID={rij.persoon.PersID}
                      // weergaveNaam={rij}
                    />
                  );
                }
                return (
                  <OrganisatieVisualisatie
                    orgID={rij.organisatie!.OrgID}
                    // weergaveNaam={rij}
                  />
                );
              }}
            />

            {/* <SelectionState
              selection={props.actieclaimsSelectie}
              onSelectionChange={(value) => props.onActieclaimsSelectieChange(value as number[])}
            /> */}

            <RowDetailState defaultExpandedRowIds={[]} />

            <VirtualTable />
            <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
            <TableHeaderRow />

            <EditingState
              onCommitChanges={(changes) => {
                if (changes.deleted === undefined) {
                  return;
                }
                const deleted = changes.deleted;
                const id = deleted[deleted.length - 1];
                alert(id);
              }}
              onEditingRowIdsChange={(rowIds) => {
                const id = rowIds[rowIds.length - 1] as number;
                // props.onWijzigenOpdrachtDialoogStateChange({ trsOpdID: id });
                // alert(id);
              }}
            />

            <TableEditColumn
              width={35}
              showEditCommand
              cellComponent={DXTableEditColumnCellComponent}
              commandComponent={DXTableEditColumnCommandComponent}
            />
            <TableRowDetail
              toggleCellComponent={DXTableToggleCellComponent}
              contentComponent={DetailComp}
            />
            {/* <TableSelection cellComponent={DXTableCheckboxComponent} /> */}
          </Grid>
        </GridStyleWrapper>
      )}
    </>
  );
});

export default withRouter(Krediettoetsen);
