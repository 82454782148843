import React, { useCallback, useState } from 'react';
import { Kleur } from '../../../bedrijfslogica/constanten';
import MediaWeergaveDialoog from '../../dialogen/MediaWeergaveDialoog';
import { EMediaWeergaveType } from '../../MediaWeergave';

interface IProps {
  afbeelding: JSX.Element | null;
  containerStyle?: React.CSSProperties;
  width: number | string;
  height: number | string;
  isActief?: boolean;
  isVergrootbaar?: boolean;
  title?: string;
  imgSrc?: string;
  leegInhoud?: JSX.Element;
}

const AfbeeldingKader: React.FC<IProps> = (props) => {
  const {
    afbeelding,
    containerStyle,
    width,
    height,
    isActief,
    isVergrootbaar,
    title,
    imgSrc,
    leegInhoud,
  } = props;
  const [uitvergroot, setUitvergroot] = useState(false);

  const handeClick = useCallback(
    (ev: React.MouseEvent) => {
      if (!isVergrootbaar) {
        return;
      }
      ev.stopPropagation();
      setUitvergroot(true);
    },
    [isVergrootbaar],
  );

  return (
    <>
      <div
        style={{
          backgroundColor: Kleur.HeelLichtGrijs,
          border: isActief ? `1px solid ${Kleur.LichtBlauw}` : `1px solid ${Kleur.LichtGrijs}`,
          cursor: isVergrootbaar ? 'pointer' : 'inherit',
          ...containerStyle,
        }}
        className="d-flex justify-content-center align-items-center p-1"
        onClick={handeClick}
      >
        {afbeelding !== null ? (
          afbeelding
        ) : (
          <div
            style={{ width, height }}
            className="d-flex justify-content-center align-items-center"
          >
            {leegInhoud}
          </div>
        )}
      </div>
      {uitvergroot && (
        <MediaWeergaveDialoog
          mediaWeergaven={[
            {
              id: 1,
              title: title || 'Afbeelding',
              src: imgSrc!,
              type: EMediaWeergaveType.Afbeelding,
              mediaType: 'DOET ER NIET TOE',
            },
          ]}
          current={1}
          open
          onSuccess={() => setUitvergroot(false)}
          onAnnuleren={() => setUitvergroot(false)}
        />
      )}
    </>
  );
};

export default AfbeeldingKader;
