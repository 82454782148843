import React, { useMemo } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { genereerUrlStateQueryParam } from '../../../core/useUrlState';
import { IUrlState } from '../../Monitoring/Winkelwagens';
import { IActieProps } from '../index';

interface IData {
  cartID: number;
}

export enum EFilter {
  IsVerwerkt = 'IS_VERWERKT',
}

const BESTELLING_GEBLOKKEERD: React.VoidFunctionComponent<IActieProps> = (props: IActieProps) => {
  const data = useMemo(() => JSON.parse(props.controltaak.Data!) as IData, [
    props.controltaak.Data,
  ]);
  return (
    <a
      href="#"
      onClick={() => {
        const state = genereerUrlStateQueryParam<IUrlState>({
          selectie: [data.cartID],
          uitgeklapt: [],
          filterData: [
            {
              naam: EFilter.IsVerwerkt,
              data: true,
              isActief: false,
            },
          ],
        });
        props.history.push(`/monitoring/winkelwagens?state=${state}`);
      }}
    >
      Naar winkelwagens
    </a>
  );
};

export default BESTELLING_GEBLOKKEERD;
