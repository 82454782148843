import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { RootStoreContext } from '../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import { IconSettings, IconUitloggen, IconVink } from '../../../components/Icons';
import {
  EGebruikersStatus,
  gebruikersStatusKleurMap,
  gebruikersStatusTekstMap,
  Kleur,
} from '../../../bedrijfslogica/constanten';
import { Overlay } from 'react-bootstrap';
import Popover from 'react-bootstrap/Popover';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  IAspGebruikerGewijzigdMessageData,
  IOphalenGebruikersprofielenResult,
} from '../../../../../shared/src/api/v2/aspGebruiker';
import api from '../../../api';
import LoadingSpinner from '../../../components/Gedeeld/LoadingSpinner';
import GebruikersAvatar from '../../../components/communicatie/GebruikersAvatar';
import StatusOptie from './StatusOptie';
import { IOphalenPersonenResultElementV2 } from '../../../../../shared/src/api/v2/persoon/persoon';
import InstellingenDialoog from './InstellingenDialoog';
import { EResultType } from '../../../stores/CheckStore';
import HorizontaleScheidingslijn from '../../../components/layout/HorizontaleScheidingslijn';
import { useRealtimeListener } from '../../../one-off-components/realtime/RealtimeIntegratie';

import { v4 as uuid } from 'uuid';
import Skeleton from 'react-loading-skeleton';

interface IInstellingenDialoogState {}

interface IProps extends RouteComponentProps {}

const GebruikerSectie: React.FC<IProps> = observer((props) => {
  const { gebruikerStore, profielStore, checkStore } = useContext(RootStoreContext);
  const menuTargetRef = useRef(null);
  const [menuTonen, setMenuTonen] = useState(false);
  const id = useMemo(() => uuid(), []);
  const [
    instellingenDialoogState,
    setInstellingenDialoogState,
  ] = useState<IInstellingenDialoogState | null>(null);
  const [profielenResult, setProfielenResult] = useState<IOphalenGebruikersprofielenResult | null>(
    null,
  );
  const [persoon, setPersoon] = useState<IOphalenPersonenResultElementV2 | null>(null);

  const ophalenGebruikersprofielen = useCallback(async () => {
    const result = await api.v2.aspGebruiker.ophalenGebruikersprofielen({
      filterSchema: {
        filters: [
          {
            naam: 'ASP_GEBR_IDS_IN',
            data: [gebruikerStore.gebruiker!.AspGebrID],
          },
        ],
      },
    });
    setProfielenResult(result);
  }, [setProfielenResult]);

  const ophalenPersoonDmvGebruiker = useCallback(async () => {
    if (gebruikerStore.gebruiker!.PersID === null) {
      setPersoon(null);
    }
    const result = await api.v2.persoon.ophalenPersonen({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: [gebruikerStore.gebruiker!.PersID],
          },
        ],
      },
    });
    const persoon = result.personen.length !== 0 ? result.personen[0] : null;
    setPersoon(persoon);
  }, [gebruikerStore.gebruiker!.PersID, setPersoon]);

  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    ophalenGebruikersprofielen();
  }, []);

  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    ophalenPersoonDmvGebruiker();
  }, [ophalenPersoonDmvGebruiker]);

  useRealtimeListener(async (type, data) => {
    if (type === 'ASP_GEBRUIKER_GEWIJZIGD') {
      const messageData: IAspGebruikerGewijzigdMessageData = data;
      if (messageData.aspGebrID === gebruikerStore.gebruiker!.AspGebrID) {
        await ophalenPersoonDmvGebruiker();
      }
    }
  });

  return (
    <>
      <div className="pl-3 pr-3 pt-1 pb-1 d-flex align-items-center w-100">
        {gebruikerStore.gebruiker!.PersID !== null && persoon === null ? (
          <LoadingSpinner />
        ) : (
          <div
            ref={menuTargetRef}
            className="d-flex align-items-center dx-g-bs4-cursor-pointer"
            onClick={() => setMenuTonen(true)}
          >
            <div style={{ marginRight: 5 }}>
              <GebruikersAvatar
                status={
                  gebruikerStore.gebruiker!.HandmatigeStatus ?? gebruikerStore.gebruiker!.AutoStatus
                }
                fotoImageSrc={
                  persoon !== null && persoon.profielAfbeelding !== null
                    ? persoon.profielAfbeelding.url
                    : undefined
                }
                initialen={persoon !== null ? persoon!.Achternaam.substr(0, 1).toUpperCase() : '?'}
              />
            </div>
            <div className="d-flex flex-column ml-2">
              <span
                className="font-weight-bold"
                style={{ fontSize: 12, position: 'relative', top: 1 }}
              >
                {gebruikerStore.gebruiker!.GebruikersNaam}
              </span>
              <span className="" style={{ fontSize: 11, position: 'relative', bottom: 1 }}>
                {gebruikerStore.aspGebruikersprofiel === null ? (
                  <Skeleton />
                ) : (
                  gebruikerStore.aspGebruikersprofiel.Naam
                )}
              </span>
            </div>
          </div>
        )}
        <div className="flex-fill" />
        <div
          // ref={profielenTargetRef}
          onClick={() => setInstellingenDialoogState({})}
          style={{ cursor: 'pointer' }}
          className="mr-1"
        >
          <IconSettings style={{ fill: Kleur.LichtGrijs, width: 18, height: 18 }} />
        </div>
      </div>

      <Overlay
        target={menuTargetRef.current!}
        show={menuTonen}
        rootClose
        onHide={() => setMenuTonen(false)}
        placement="top"
      >
        <Popover id={id}>
          <div className="p-1 pl-2 pr-2 d-flex flex-column">
            {/*<span className="font-weight-bold">Status</span>*/}
            {Object.keys(EGebruikersStatus)
              .map((x) => Number(x))
              .filter((x) => !isNaN(x))
              .map((x) => {
                const status = x as EGebruikersStatus;
                return (
                  <StatusOptie
                    key={status}
                    kleur={gebruikersStatusKleurMap[status]}
                    naam={gebruikersStatusTekstMap[status]}
                    onClick={async () => {
                      setMenuTonen(false);
                      await api.v2.aspGebruiker.instellenStatus({
                        status: status === EGebruikersStatus.Beschikbaar ? null : status,
                      });
                    }}
                  />
                );
              })}
          </div>
          <HorizontaleScheidingslijn color={Kleur.GrijsScheiding} />
          <ul className="list-group">
            {profielenResult === null ? (
              <LoadingSpinner />
            ) : (
              profielenResult.profielen.map((profiel) => {
                return (
                  <li
                    key={profiel.ID}
                    className="list-group-item d-flex align-items-center"
                    onClick={async () => {
                      setMenuTonen(false);
                      await api.v2.aspGebruiker.instellenGebruikersprofiel({
                        id: profiel.ID,
                      });
                    }}
                    style={{
                      padding: '7px 10px',
                      cursor: 'pointer',
                    }}
                  >
                    {gebruikerStore.gebruiker!.AspGebrProfielID !== profiel.ID ? (
                      <div style={{ width: 16, height: 16 }} />
                    ) : (
                      <IconVink style={{ fill: Kleur.Grijs, width: 16, height: 16 }} />
                    )}

                    <span className="ml-2">{profiel.Naam}</span>
                  </li>
                );
              })
            )}
          </ul>
          <HorizontaleScheidingslijn color={Kleur.GrijsScheiding} />
          <ul className="list-group">
            <li
              className="list-group-item d-flex align-items-center"
              onClick={async () => {
                setMenuTonen(false);
                const bevestigenResult = await checkStore.bevestigen({
                  inhoud: 'Uitloggen bevestigen',
                });
                if (bevestigenResult.type === EResultType.Annuleren) {
                  return;
                }
                await gebruikerStore.logout(props.history);
              }}
              style={{
                padding: '7px 10px',
                cursor: 'pointer',
              }}
            >
              <IconUitloggen style={{ fill: Kleur.Grijs, width: 16, height: 16 }} />

              <span className="ml-2">Uitloggen</span>
            </li>
          </ul>
        </Popover>
      </Overlay>

      {instellingenDialoogState !== null && (
        <InstellingenDialoog
          open
          onSuccess={() => {
            setInstellingenDialoogState(null);
          }}
          onAnnuleren={() => setInstellingenDialoogState(null)}
        />
      )}
    </>
  );
});

export default withRouter(GebruikerSectie);
