import api from '../../../index';
import {
  IOphalenGeneriekParams,
  IToevoegenGeneriekResult,
  ISelectieGeneriekParams,
  ILeegGeneriekResult,
} from '../../../../../../shared/src/api/v2/generiek';
import {
  IAfwijzenSponsorVerzoekenParams,
  ISponsorVerzoek,
} from '../../../../../../shared/src/api/v2/sponsoringv2/verzoek';

const verzoek = {
  ophalenVerzoeken: async (params: IOphalenGeneriekParams): Promise<ISponsorVerzoek[]> => {
    return await api.post('/v2/sponsoringv2/verzoek/ophalen-verzoeken', params);
  },
  toevoegenOfWijzigenVerzoek: async (
    params: ISponsorVerzoek,
  ): Promise<IToevoegenGeneriekResult> => {
    return await api.post('/v2/sponsoringv2/verzoek/toevoegen-of-wijzigen-verzoek', params);
  },
  verwijderenVerzoeken: async (params: ISelectieGeneriekParams): Promise<ILeegGeneriekResult> => {
    return await api.post('/v2/sponsoringv2/verzoek/verwijderen-verzoeken', params);
  },
  afwijzenVerzoeken: async (
    params: IAfwijzenSponsorVerzoekenParams,
  ): Promise<ILeegGeneriekResult> => {
    return await api.post('/v2/sponsoringv2/verzoek/afwijzen-verzoeken', params);
  },
  afwijsBerichtenVersturen: async (
    params: ISelectieGeneriekParams,
  ): Promise<ILeegGeneriekResult> => {
    return await api.post('/v2/sponsoringv2/verzoek/afwijsberichten-versturen', params);
  },
  promoverenVerzoeken: async (params: ISelectieGeneriekParams): Promise<ILeegGeneriekResult> => {
    return await api.post('/v2/sponsoringv2/verzoek/promoveren-verzoeken', params);
  },
};

export default verzoek;
