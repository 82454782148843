import { Field, FieldProps, Formik } from 'formik';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IOphalenTypenParametersResultElement } from '../../../../../../../shared/src/api/v2/inkoop/parameters';
import api from '../../../../../api';
import Dialoog from '../../../../../components/dialogen/Dialoog';
import BedragInput from '../../../../../components/formulier/BedragInput';
import NumeriekVeld from '../../../../../components/formulier/NumeriekVeld';
import VeldWeergave from '../../../../../components/formulier/VeldWeergave';
import VinkVeld from '../../../../../components/formulier/VinkVeld';
import LoadingSpinner from '../../../../../components/Gedeeld/LoadingSpinner';
import IDialoogProps from '../../../../../core/IDialoogProps';
import nameof from '../../../../../core/nameOf';

export interface IDialoogResult {}

interface IProps extends IDialoogProps<IDialoogResult>, RouteComponentProps {
  id: number;
}

interface IFormikValues {
  aantalMinimaal: number;
  aanvullenTot: number;
  actief: boolean;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  aantalMinimaal: 'Ondergrens',
  aanvullenTot: 'Aanvullen tot',
  actief: 'Actief',
};

const WijzigenParametersDialoog: React.FC<IProps> = (props) => {
  const [typeParameters, setTypeParameters] = useState<IOphalenTypenParametersResultElement | null>(
    null,
  );

  const ophalenTypenParameters = useCallback(async () => {
    const result = await api.v2.inkoop.parameters.ophalenTypenParameters({
      filterSchema: {
        filters: [{ naam: 'IDS', data: [props.id] }],
      },
    });

    setTypeParameters(result.typenParameters[0]);
  }, [props.id]);

  useEffect(() => {
    ophalenTypenParameters();
  }, [ophalenTypenParameters]);

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (typeParameters === null) {
      return null;
    }

    return {
      aantalMinimaal: typeParameters.AantalMinimaal,
      aanvullenTot: typeParameters.AanvullenTot,
      actief: typeParameters.Actief,
    };
  }, [typeParameters]);

  const handleSubmit = useCallback(async (values: IFormikValues) => {
    const params = {
      id: props.id,
      aantalMinimaal: values.aantalMinimaal,
      aanvullenTot: values.aanvullenTot,
      actief: values.actief,
    };

    await api.v2.inkoop.parameters.wijzigenTypeParameters(params);

    props.onSuccess({});
  }, []);

  return (
    <>
      <Dialoog index={props.dialoogIndex || 0}>
        <ModalHeader>
          <ModalTitle>Wijzigen parameters</ModalTitle>
        </ModalHeader>

        {initialValues === null ? (
          <LoadingSpinner />
        ) : (
          <>
            <Formik<IFormikValues>
              // ref={formikRef}
              initialValues={initialValues}
              onSubmit={handleSubmit}
              isInitialValid
              render={(formikProps) => {
                return (
                  <>
                    <ModalBody>
                      <div className="row">
                        <div className="col-12">
                          {/* <label></label> */}
                          <VeldWeergave>
                            De ondergrens geeft aan waaronder de vrije voorraad leidt tot aanvulling
                          </VeldWeergave>
                        </div>

                        <div className="row col-12 mt-3">
                          <div className="col-4">
                            <label>{veldnamen.aantalMinimaal}</label>
                            <Field
                              name={nameof<IFormikValues>('aantalMinimaal')}
                              render={({ field, form }: FieldProps<IFormikValues>) => {
                                return (
                                  <NumeriekVeld
                                    waarde={field.value}
                                    onChange={(x) => form.setFieldValue(field.name, x)}
                                    min={1}
                                    max={99}
                                  />
                                );
                              }}
                            />
                          </div>
                        </div>

                        <div className="row col-12 mt-3">
                          <div className="col-4">
                            <label>{veldnamen.aanvullenTot}</label>
                            <Field
                              name={nameof<IFormikValues>('aanvullenTot')}
                              render={({ field, form }: FieldProps<IFormikValues>) => {
                                return (
                                  <NumeriekVeld
                                    waarde={field.value}
                                    onChange={(x) => form.setFieldValue(field.name, x)}
                                    min={1}
                                    max={99}
                                  />
                                );
                              }}
                            />
                          </div>
                        </div>

                        <div className="col-12 mt-3">
                          <span className="d-flex align-items-center">
                            <Field
                              name={nameof<IFormikValues>('actief')}
                              render={(fieldProps: FieldProps) => {
                                const { field, form } = fieldProps;
                                return (
                                  <VinkVeld
                                    aangevinkt={field.value}
                                    onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                  />
                                );
                              }}
                            />
                            <span className="ml-2">{veldnamen.actief}</span>
                          </span>
                        </div>
                      </div>
                    </ModalBody>

                    <ModalFooter className="d-flex flex-row justify-content-start">
                      <button
                        className="btn btn-primary"
                        onClick={formikProps.submitForm}
                        style={{ width: 100 }}
                        disabled={!formikProps.isValid || formikProps.isSubmitting}
                      >
                        Ok
                      </button>
                      <button
                        className="btn btn-secondary"
                        onClick={props.onAnnuleren}
                        style={{ width: 100 }}
                        disabled={formikProps.isSubmitting}
                      >
                        Annuleren
                      </button>
                    </ModalFooter>
                  </>
                );
              }}
            />
          </>
        )}
      </Dialoog>
    </>
  );
};

export default withRouter(WijzigenParametersDialoog);
