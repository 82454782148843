import { makeObservable, observable } from 'mobx';
import api, { IPaginatiePositie } from '../api';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../models/IRemoteData';
import { IFilterSchema } from '../../../shared/src/models/filter';
import { IOphalenAfdelingenResultElement } from '../../../shared/src/api/v2/relatie/afdeling';
import { IOphalenContactpersonenResultElement } from '../../../shared/src/api/v2/relatie/contactpersoon';
import { ERichting } from '../components/communicatie/TelefoonHistorie';
import {
  IOphalenTelefoonNummersResultElement,
  IOphalenTelefoonoproepXContextResultElement,
  IOphalenTelefoonoproepContextenResult,
  IWijzigenTelefoonOproepResult,
  ITelefoonoproepBeeindigdMessageData,
} from '../../../shared/src/api/v2/telefonie';

export interface IOproepenData {
  oproepen: {
    [idx: number]: ICommunicatieOverlayRecentTelefoonOproep;
  };
  totaalAantal: number;
}

export interface ICommunicatieOverlayRecentTelefoonOproep {
  ID: number;
  Datum: Date | string;
  Richting: 'I' | 'U';
  Nummer: string | null;
  NummerBedrijf_TelNrBedrijfID: number | null;
  AspGebrID: number | null;
  PersID: number | null;
  OrgID: number | null;
  OrgAfdID: number | null;
  Inzake_RelID: number | null;
  Inhoud: string | null;
  Notities: string | null;
}

export interface IEventsData {
  events: {
    [telOprID: number]: ICommunicatieOverlayRecentTelefoonEvent[];
  };
}

export interface ICommunicatieOverlayRecentTelefoonEvent {
  ID: number;
  TelOproep_ID: number;
  Datum: Date | string;
  Status: 0 | 1 | 2 | 3; // Inkomend | Rinkelt | Beantwoord | Beeindigd
  Handmatig: boolean;
  Reden: 0 | 1 | 2 | 3 | 4 | null; // Geslaagd | InGesprek | NietBeantwoord | Mislukt | Geweigerd
}

export interface IAfdelingenData {
  afdelingen: { [ID: number]: IOphalenAfdelingenResultElement };
}

export interface IContactpersonenData {
  contactpersonen: { [ID: number]: IOphalenContactpersonenResultElement };
}

export interface ITelefoonnummersData {
  telefoonnummers: { [TelNrBedrijfID: number]: IOphalenTelefoonNummersResultElement };
}

export interface ITelefoonoproepXContextenData {
  mapping: { [TelOprID: number]: IOphalenTelefoonoproepXContextResultElement[] };
}

export interface IOphalenOproepenParams {
  paginatie: IPaginatiePositie;
  filterSchema: IFilterSchema;
  uitbreiden: boolean;
}

class TelefoonHistorieStore {
  public oproepenData: IRemoteData<IOproepenData> = createPendingRemoteData();
  public eventsData: IRemoteData<IEventsData> = createPendingRemoteData();
  public oproepContextenResult: IRemoteData<
    IOphalenTelefoonoproepContextenResult
  > = createPendingRemoteData();
  public afdelingenData: IRemoteData<IAfdelingenData> = createPendingRemoteData();
  public contactpersonenData: IRemoteData<IContactpersonenData> = createPendingRemoteData();
  public telefoonnummersData: IRemoteData<ITelefoonnummersData> = createPendingRemoteData();
  public telefoonoproepXContextenData: IRemoteData<
    ITelefoonoproepXContextenData
  > = createPendingRemoteData();

  constructor() {
    makeObservable(this, {
      oproepenData: observable,
      eventsData: observable,
      oproepContextenResult: observable,
      afdelingenData: observable,
      contactpersonenData: observable,
      telefoonnummersData: observable,
    });
  }

  public ophalenOproepen = async (params: IOphalenOproepenParams) => {
    const result = await api.v2.telefonie.ophalenTelefoonOproepenV2({
      selectieSchema: {
        velden: [
          'ID',
          'NUMMER',
          'RICHTING',
          'NUMMER_BEDRIJF_TEL_NR_ID',
          'ASP_GEBR_ID',
          'PERS_ID',
          'ORG_ID',
          'ORG_AFD_ID',
          'INZAKE_REL_ID',
          'INHOUD',
          'NOTITIES',
          'SORTEERDATUM',
          'DATUM',
        ],
      },
      customFilters: {
        isBeeindigd: true,
      },
      paginatie: params.paginatie,
      filterSchema: params.filterSchema,
    });

    this.oproepenData = createReadyRemoteData({
      oproepen: result.oproepen.reduce(
        (acc, oproep, idx) => ({
          ...acc,
          [params.paginatie.index + idx]: oproep,
        }),
        params.uitbreiden ? this.oproepenData.data?.oproepen ?? {} : {},
      ),
      totaalAantal: result.totaalAantal,
    });

    const telOprIDs = result.oproepen.map((o) => o.ID!);
    // noinspection ES6MissingAwait
    this.ophalenEvents(telOprIDs);
    // noinspection ES6MissingAwait
    this.ophalenTelefoonoproepXContexten(telOprIDs);
    const orgAfdIDs = result.oproepen.map((x) => x.OrgAfdID).filter((x) => x !== null) as number[];
    // noinspection ES6MissingAwait
    this.ophalenAfdelingen(orgAfdIDs);

    const persIDs = result.oproepen.map((x) => x.PersID).filter((x) => x !== null) as number[];
    const inzakeRelIDs = result.oproepen
      .map((x) => x.Inzake_RelID)
      .filter((x) => x !== null) as number[];
    // noinspection ES6MissingAwait
    this.ophalenContactpersonen(persIDs, inzakeRelIDs);
  };

  private ophalenEvents = async (telOprIDs: number[]) => {
    if (telOprIDs.length === 0) {
      if (this.eventsData.state === ERemoteDataState.Pending) {
        this.eventsData = createReadyRemoteData({
          events: {},
        });
      }
      return;
    }

    const result = await api.v2.telefonie.ophalenTelefoonEvents({
      selectieSchema: {
        velden: ['ID', 'TEL_OPROEP_ID', 'DATUM', 'STATUS', 'HANDMATIG', 'REDEN'],
      },
      filterSchema: {
        filters: [
          {
            naam: 'TEL_OPROEP_IDS',
            data: telOprIDs,
          },
        ],
      },
      orderSchema: {
        orders: [
          {
            naam: 'DATUM',
            richting: 'DESC',
          },
        ],
      },
    });

    const events = result.events.reduce<{ [telOprID: number]: any[] }>(
      (acc, curr) => ({
        ...acc,
        [curr.TelOproep_ID!]: [...(acc[curr.TelOproep_ID!] ?? []), curr],
      }),
      {},
    );

    this.eventsData = createReadyRemoteData({
      events: {
        ...(this.eventsData.data?.events ?? {}),
        ...events,
      },
    });
  };

  private ophalenTelefoonoproepXContexten = async (telOprIDs: number[]) => {
    if (telOprIDs.length === 0) {
      if (this.telefoonoproepXContextenData.state === ERemoteDataState.Pending) {
        this.telefoonoproepXContextenData = createReadyRemoteData({
          mapping: {},
        });
      }
      return;
    }

    const result = await api.v2.telefonie.ophalenTelefoonoproepXContext({
      filterSchema: {
        filters: [
          {
            naam: 'TEL_OPR_IDS',
            data: telOprIDs,
          },
        ],
      },
    });

    const mapping = telOprIDs.reduce((acc, telOprID) => {
      const items = result.telefoonoproepXContexten.filter((x) => x.TelOprID === telOprID);
      return {
        ...acc,
        [telOprID]: items,
      };
    }, {});

    // const mapping = result.telefoonoproepXContexten.reduce<{
    //   [telOprID: number]: IOphalenTelefoonoproepXContextResultElement[];
    // }>(
    //   (acc, item) => ({
    //     ...acc,
    //     [item.TelOprID]: [...(acc[item.TelOprID] ?? []), item],
    //   }),
    //   {},
    // );

    this.telefoonoproepXContextenData = createReadyRemoteData({
      mapping: {
        ...this.telefoonoproepXContextenData.data?.mapping,
        ...mapping,
      },
    });
  };

  private ophalenAfdelingen = async (orgAfdIDs: number[]) => {
    const nogTeOphalenIDs = orgAfdIDs.filter((id) => !this.afdelingenData.data?.afdelingen[id]);
    if (nogTeOphalenIDs.length === 0) {
      if (this.afdelingenData.state === ERemoteDataState.Pending) {
        this.afdelingenData = createReadyRemoteData({
          afdelingen: {},
        });
      }
      return;
    }

    const result = await api.v2.relatie.afdeling.ophalenAfdelingen({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: nogTeOphalenIDs,
          },
        ],
      },
    });
    const nieuweAfdelingenData: IAfdelingenData = {
      afdelingen: result.afdelingen.reduce(
        (acc, curr) => ({
          ...acc,
          [curr.ID]: curr,
        }),
        this.afdelingenData.data?.afdelingen ?? {},
      ),
    };
    this.afdelingenData = createReadyRemoteData(nieuweAfdelingenData);
  };

  private ophalenContactpersonen = async (persIDs: number[], inzakeRelIDs: number[]) => {
    if (persIDs.length === 0 && inzakeRelIDs.length === 0) {
      if (this.contactpersonenData.state === ERemoteDataState.Pending) {
        this.contactpersonenData = createReadyRemoteData({
          contactpersonen: {},
        });
      }
      return;
    }

    const result = await api.v2.relatie.ophalenContactpersonen({
      filterSchema: {
        filters: [
          {
            naam: 'IS_RELATIE',
            data: true,
          },
          {
            naam: 'PERS_IDS',
            data: persIDs,
          },
          {
            naam: 'REL_IDS',
            data: inzakeRelIDs,
          },
        ],
      },
    });

    const nieuweContactpersonenData: IContactpersonenData = {
      contactpersonen: result.contactpersonen.reduce(
        (acc, curr) => ({
          ...acc,
          [curr.ID]: curr,
        }),
        this.contactpersonenData.data?.contactpersonen ?? {},
      ),
    };
    this.contactpersonenData = createReadyRemoteData(nieuweContactpersonenData);
  };

  public ophalenTelefoonnummers = async () => {
    const result = await api.v2.telefonie.ophalenTelefoonNummers({
      selectieSchema: {
        velden: ['TEL_NR_ID', 'NUMMER', 'OMSCHRIJVING', 'LOCATIE', 'NAAM_ENUM'],
      },
    });
    this.telefoonnummersData = createReadyRemoteData({
      telefoonnummers: result.nummers.reduce(
        (acc, curr) => ({
          ...acc,
          [curr.TelNrBedrijfID!]: curr,
        }),
        {},
      ),
    });
  };

  public ophalenOproepContexten = async () => {
    const result = await api.v2.telefonie.ophalenTelefoonoproepContexten({});
    this.oproepContextenResult = createReadyRemoteData(result);
  };

  public handleTelefoonOproepGewijzigd = async (data: IWijzigenTelefoonOproepResult) => {
    if (this.oproepenData.state === ERemoteDataState.Pending) {
      return;
    }

    const oproepKey = Object.keys(this.oproepenData.data!.oproepen).find((key) => {
      const idx = Number(key);
      return this.oproepenData.data!.oproepen[idx].ID === data.oproep.ID;
    });
    if (oproepKey === undefined) {
      return;
    }
    const oproepIdx = Number(oproepKey);

    const communicatieOverlayRecentTelefoonOproep: ICommunicatieOverlayRecentTelefoonOproep = {
      ID: data.oproep.ID,
      Datum: data.oproep.Datum,
      AspGebrID: data.oproep.AspGebrID,
      Inhoud: data.oproep.Inhoud,
      Inzake_RelID: data.oproep.Inzake_RelID,
      Notities: data.oproep.Notities,
      Nummer: data.oproep.Nummer,
      NummerBedrijf_TelNrBedrijfID: data.oproep.NummerBedrijf_TelNrBedrijfID,
      OrgAfdID: data.oproep.OrgAfdID,
      OrgID: data.oproep.OrgID,
      PersID: data.oproep.PersID,
      Richting: data.oproep.Richting,
    };

    const nieuweOproepenData: IOproepenData = {
      ...this.oproepenData.data!,
      oproepen: {
        ...this.oproepenData.data!.oproepen!,
        [oproepIdx]: communicatieOverlayRecentTelefoonOproep,
      },
    };

    this.oproepenData = createReadyRemoteData(nieuweOproepenData);

    if (this.eventsData.state === ERemoteDataState.Pending) {
      return;
    }
    const nieuweEvents: IEventsData = {
      events: {
        ...this.eventsData.data!.events,
        [data.oproep.ID]: data.oproep.events.map(
          (evt): ICommunicatieOverlayRecentTelefoonEvent => ({
            ID: evt.ID,
            Datum: evt.Datum,
            TelOproep_ID: evt.TelOproep_ID,
            Handmatig: evt.Handmatig,
            Reden: evt.Reden as 0 | 1 | 2 | 3 | 4 | null,
            Status: evt.Status as 0 | 1 | 2 | 3,
          }),
        ),
      },
    };
    this.eventsData = createReadyRemoteData(nieuweEvents);
  };

  public handleTelefoonOproepBeeindigd = (data: ITelefoonoproepBeeindigdMessageData) => {
    if (this.oproepenData.state === ERemoteDataState.Pending) {
      return;
    }
    const communicatieOverlayRecentTelefoonOproep: ICommunicatieOverlayRecentTelefoonOproep = {
      ID: data.oproep.ID,
      Datum: data.oproep.Datum,
      AspGebrID: data.oproep.AspGebrID,
      Inhoud: data.oproep.Inhoud,
      Inzake_RelID: data.oproep.Inzake_RelID,
      Notities: data.oproep.Notities,
      Nummer: data.oproep.Nummer,
      NummerBedrijf_TelNrBedrijfID: data.oproep.NummerBedrijf_TelNrBedrijfID,
      OrgAfdID: data.oproep.OrgAfdID,
      OrgID: data.oproep.OrgID,
      PersID: data.oproep.PersID,
      Richting: data.oproep.Richting,
    };

    const nieuweOproepenData: IOproepenData = {
      ...this.oproepenData.data!,
      totaalAantal: this.oproepenData.data!.totaalAantal + 1,
      oproepen: {
        [0]: communicatieOverlayRecentTelefoonOproep,
        ...Object.keys(this.oproepenData.data!.oproepen!)
          .map(Number)
          .reduce(
            (acc, curr) => ({
              ...acc,
              [curr + 1]: this.oproepenData.data!.oproepen![curr],
            }),
            {},
          ),
      },
    };

    this.oproepenData = createReadyRemoteData(nieuweOproepenData);

    const nieuweEvents: IEventsData = {
      events: {
        ...(this.eventsData.data?.events ?? {}),
        [data.oproep.ID]: data.oproep.events.map(
          (evt): ICommunicatieOverlayRecentTelefoonEvent => ({
            ID: evt.ID,
            Datum: evt.Datum,
            TelOproep_ID: evt.TelOproep_ID,
            Handmatig: evt.Handmatig,
            Reden: evt.Reden as 0 | 1 | 2 | 3 | 4 | null,
            Status: evt.Status as 0 | 1 | 2 | 3,
          }),
        ),
      },
    };
    this.eventsData = createReadyRemoteData(nieuweEvents);
  };
}

export default TelefoonHistorieStore;
