import {
  DataTypeProvider,
  EditingState,
  SelectionState,
  TableRowDetail,
} from '@devexpress/dx-react-grid';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { RootStoreContext } from '../../../../stores/RootStore';
import api from '../../../../api';
import { EResultType } from '../../../../stores/CheckStore';
import {
  DXTableCheckboxComponent,
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import { Kleur as EKleur } from '../../../../bedrijfslogica/constanten';
import {
  Grid,
  Table,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  TableSelection,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  EFunctioneleIcon,
  functioneleIconMap,
  IconCorrigeren,
  IconInformatie,
  IconKruis,
  IconToevoegen,
  IconVerwijderen,
  IconWijzigen,
} from '../../../Icons';
import RelatieVisualisatie from '../../../personalia/RelatieVisualisatie';
import ToevoegenRegelsDialoog from '../../ToevoegenRegelsDialoog';
import { IOpdracht, PlanningTabelContext } from '../index';
import WijzigenProductDialoog from '../../../../paginas/Magazijn/Depot/WijzigenProductDialoog';
import TabelInspringBlok from '../../../layout/TabelInspringBlok';
import MenuLayout from '../../../MenuLayout';
import BijlageKnop, { EBronModus, ESelectieModus } from '../../../BijlageKnop';
import BijlagenContainer, { BestandType } from '../../../BijlagenContainer';
import useUpload from '../../../../core/useUpload';
import WijzigenRegelDialoog from '../../../transport/WijzigenOpdrachtDialoog';
import ProductinfoDialoog from '../../../../components/product/ProductinfoDialoog';
import VoorraadTypeInfoDialoog from '../../VoorraadTypeInfoDialoog';
import ProductmodelInfoDialoog from '../../../productmodel/ProductmodelInfoDialoog';
import { maandenNaarJaren } from '../../../../bedrijfslogica/teksten';
import ReserveringProductComp from '../../../kolomveld/transportopdrachtregel/ReserveringProductComp';
import ReferentieProductComp from '../../../kolomveld/transportopdrachtregel/ReferentieProductComp';
import ContractnummerComp from '../../../kolomveld/contract/ContractnummerComp';
import { IOphalenOpdrachtregelsResultElementV2 } from '../../../../../../shared/src/api/v2/transport/opdracht';
import NieuwOfGebruiktComp from '../../../kolomveld/transportopdrachtregel/NieuwOfGebruiktComp';
import ProductReservering, { IRegelXXX, IKoppelDialoogState } from '../ProductReservering';
import KoppelenProductDialoog, {
  EKoppelenProductResultType,
  ILocatieResult,
  IVoorraadResult,
} from '../../../../paginas/Transport/Opdrachten/reservering/KoppelenProductDialoog';
import { EVoorraadTabblad } from '../../../../paginas/Transport/Opdrachten/reservering/KoppelenProductDialoog/VoorraadTabblad';
import { EHoedanigheid } from '../../../personalia/RelatieSelectieDialoog';
import { genereerUrlStateQueryParam } from '../../../../core/useUrlState';
import {
  IUrlState as ITransportopdrachtenOverzichtUrlState,
  defaultUrlState as transportopdrachtenOverzichtDefaultUrlState,
} from '../../../../components/kaart/entiteiten/Transport/Opdrachten';
import { defaultOpdrachtState } from '../../../../components/kaart/entiteiten/Transport/Opdrachten/DetailComp';
import UitlegTooltip from '../../../formulier/UitlegTooltip';
import VerplaatsenOpdrachtregelsDialoog from '../../VerplaatsenOpdrachtregelsDialoog';

const WitgoedApparaat = functioneleIconMap[EFunctioneleIcon.WitgoedApparaat];
const IconVerplaatsen = functioneleIconMap[EFunctioneleIcon.Verplaatsen];

interface IRegelRow extends IOphalenOpdrachtregelsResultElementV2 {}

export interface IVoorraadDialoogState {
  id: number;
}
export interface IProductinfoDialoogState {
  id: number;
}

export interface IReserverenProductDialoogState {
  id: number;
  cntID: number | null;
}

export interface IVoorraadTypeInfoDialoogState {
  typeID: number;
  magID: number;
}

export interface IProductmodelInfoDialoogState {
  prodModID: number;
}

export interface IVerplaatsenRegelsDialoogState {
  trsRegIDs: number[];
}

const Regels = (props: TableRowDetail.ContentProps) => {
  const row = props.row as IOpdracht; // row = Opdracht + regels

  // Map de velden van de meegegeven regels naar de regels van het grid
  const gridData = useMemo<IRegelRow[]>(
    () =>
      row.regels.map((regel) => ({
        ...regel,
      })),
    [row.regels],
  );

  const { checkStore } = useContext(RootStoreContext);
  const planningTabelContext = useContext(PlanningTabelContext);

  const [wijzigenProductDialoogTonen, setWijzigenProductDialoogTonen] = useState<number | null>(
    null,
  );
  const [wijzigenRegelDialoogTonen, setWijzigenRegelDialoogTonen] = useState<number | null>(null);

  const [koppelDialoogState, setKoppelDialoogState] = useState<IKoppelDialoogState | null>(null);

  const [
    verplaatsenRegelsDialoogState,
    setVerplaatsenRegelsDialoogState,
  ] = useState<IVerplaatsenRegelsDialoogState | null>(null);

  const [
    voorraadTypeInfoDialoogState,
    setVoorraadTypeInfoDialoogState,
  ] = useState<IVoorraadTypeInfoDialoogState | null>(null);

  const [
    productinfoDialoogState,
    setProductinfoDialoogState,
  ] = useState<IProductinfoDialoogState | null>(null);

  const [
    productmodelInfoDialoogState,
    setProductmodelInfoDialoogState,
  ] = useState<IProductmodelInfoDialoogState | null>(null);

  const [toevoegenTrsOpdID, setToevoegenTrsOpdID] = useState<number | null>(null);

  const handlKoppelenOmruil = useCallback(
    async (trsRegIDs: number[]) => {
      // De 2 items in de array zijn de 2 regels die gekoppeld moeten worden
      const params = {
        regels: [
          {
            trsRegID: trsRegIDs[0],
            koppelAan_TrsRegID: trsRegIDs[1],
          },
        ],
      };
      const checkData = await api.v2.transport.opdracht.omruil.checkKoppelenOmruil(params);
      if (
        (
          await checkStore.controleren({
            checkData,
          })
        ).type === EResultType.Annuleren
      ) {
        return;
      }

      const bevestigenResult = await checkStore.bevestigen({
        inhoud: 'Koppeling omruil maken?',
      });
      if (bevestigenResult.type === EResultType.Annuleren) {
        return;
      }

      await api.v2.transport.opdracht.omruil.koppelenOmruil(params);

      planningTabelContext.onRequestRefresh();
    },
    [planningTabelContext.onRequestRefresh],
  );

  const handlVerwijderenOmruil = useCallback(
    async (trsRegID: number) => {
      const bevestigenResult = await checkStore.bevestigen({
        inhoud: 'Koppeling omruil verwijderen?',
      });
      if (bevestigenResult.type === EResultType.Annuleren) {
        return;
      }

      await api.v2.transport.opdracht.omruil.verwijderenOmruil({
        trsRegID,
      });

      planningTabelContext.onRequestRefresh();
    },
    [planningTabelContext.onRequestRefresh],
  );

  const handlVerwijderenOpdrachtregels = useCallback(
    async (trsRegIDs: number[]) => {
      const bevestigenResult = await checkStore.bevestigen({
        inhoud: (
          <span>
            Geselecteerde regels verwijderen?
            <br />
            Dit kan niet ongedaan gemaakt worden.
          </span>
        ),
      });
      if (bevestigenResult.type === EResultType.Annuleren) {
        return;
      }

      await api.v2.transport.opdracht.verwijderenOpdrachtregels({
        trsRegIDs,
      });

      planningTabelContext.onRequestRefresh();
    },
    [planningTabelContext.onRequestRefresh],
  );

  // const handleVerwijderen = useCallback(
  //   async (regel: IRegelRow) => {
  //     const bevestigenResult = await checkStore.bevestigen({
  //       titel: 'Koppeling verwijderen?',
  //     });
  //     if (bevestigenResult.type === EResultType.Annuleren) {
  //       return;
  //     }

  //     // Afhandeling hangt af de regelsoort
  //     if (regel.soort.Code === 'L') {
  //       const checkData = await api.v2.transport.reservering.levering.checkVerwijderenProductReservering(
  //         {
  //           trsRegIDs: [regel.TrsRegID],
  //         },
  //       );
  //       const controleerResult = await checkStore.controleren({
  //         checkData,
  //       });
  //       if (controleerResult.type === EResultType.Annuleren) {
  //         return;
  //       }

  //       await api.v2.transport.reservering.levering.verwijderenProductReservering({
  //         trsRegIDs: [regel.TrsRegID],
  //       });
  //     } else {
  //       const checkData = await api.v2.transport.reservering.levering.checkVerwijderenProductReservering(
  //         {
  //           trsRegIDs: [regel.TrsRegID],
  //         },
  //       );
  //       const controleerResult = await checkStore.controleren({
  //         checkData,
  //       });
  //       if (controleerResult.type === EResultType.Annuleren) {
  //         return;
  //       }
  //       await api.v2.transport.reservering.retour.verwijderenProductReservering({
  //         trsRegIDs: [regel.TrsRegID],
  //       });
  //     }

  //     planningTabelContext.onRequestRefresh();
  //   },
  //   [planningTabelContext.onRequestRefresh],
  // );

  const keyExtractor = useCallback((row: IRegelRow) => row.TrsRegID, []);

  const kolommen = useMemo<TypedColumn<IRegelRow>[]>(
    () => [
      {
        name: '__soort' as any,
        title: 'Srt.',
      },
      {
        name: 'Aantal',
        title: '#',
      },
      {
        name: '__productreservering' as any,
        title: 'Product',
      },
      {
        name: '__productsoort' as any,
        title: 'Cat.',
      },
      {
        name: '__nieuw' as any,
        title: (
          <UitlegTooltip inhoud={'N(ieuw) of G(ebruikt) product'}>
            <span>N/G</span>
          </UitlegTooltip>
        ) as any,
      },
      {
        name: '__referentie' as any,
        title: 'Ref.',
      },
      {
        name: '__voorraadReservering' as any,
        title: 'Res.',
      },
      {
        name: '__voorraadInfo' as any,
        title: 'Info',
      },
      {
        name: '__retourstatus' as any,
        title: (
          <UitlegTooltip inhoud={'Retourstatus van het retour te halen product'}>
            <span>Doel</span>
          </UitlegTooltip>
        ) as any,
      },
      {
        name: '__contractnummer' as any,
        title: 'Cnt.nr',
      },
      {
        name: '__abonnement' as any,
        title: 'Abon.',
      },
      {
        name: '__modelcode' as any,
        title: 'Modelcode',
      },

      {
        name: 'relatienaam',
        title: 'Relatienaam',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRegelRow>[]>(
    () => [
      {
        columnName: '__soort' as any,
        width: 60,
      },
      {
        columnName: 'Aantal',
        width: 30,
      },
      {
        columnName: '__productreservering' as any,
        width: 250,
      },
      {
        columnName: '__nieuw' as any,
        width: 50,
      },
      {
        columnName: '__voorraadReservering' as any,
        width: 50,
      },
      {
        columnName: '__voorraadInfo' as any,
        width: 50,
      },
      {
        columnName: '__productsoort' as any,
        width: 60,
      },
      {
        columnName: '__referentie' as any,
        width: 70,
      },
      {
        columnName: '__retourstatus' as any,
        width: 65,
      },
      {
        columnName: '__modelcode',
        width: 110,
      },
      {
        columnName: '__contractnummer' as any,
        width: 90,
      },
      {
        columnName: '__abonnement' as any,
        width: 60,
      },
      {
        columnName: 'relatienaam',
        width: 225,
      },
    ],
    [],
  );

  const { isBezigMetUploaden, muteerBestanden, uploadProgresses, bestanden } = useUpload(
    useMemo(
      () => ({
        automatischUploaden: true,
        initieleBestanden: row.bestanden.map((x) => ({
          type: BestandType.ASPDrive as BestandType.ASPDrive,
          bestand: x,
        })),
        onAlleBestandenGeuploaded: async (bestandIDs: number[]) => {
          await api.v2.transport.muterenBestandenOpdracht({
            trsOpdID: row.TrsOpdID,
            bestIDs: bestandIDs,
          });
        },
      }),
      [row.bestanden, row.TrsOpdID],
    ),
  );

  const regelsSelectie = useMemo(
    () =>
      planningTabelContext.regelsSelectie.filter(
        (id) => row.regels.findIndex((regel) => regel.TrsRegID === id) !== -1,
      ),
    [planningTabelContext.regelsSelectie, row.regels],
  );
  const andereRegelsSelectie = useMemo(
    () => planningTabelContext.regelsSelectie.filter((id) => !regelsSelectie.includes(id)),
    [planningTabelContext.regelsSelectie, regelsSelectie],
  );
  const setRegelsSelectie = useCallback(
    (ids: number[]) => {
      planningTabelContext.onRegelsSelectieChange([...andereRegelsSelectie, ...ids]);
    },
    [planningTabelContext.onRegelsSelectieChange, andereRegelsSelectie],
  );

  return (
    <>
      <div className="d-flex">
        <TabelInspringBlok />
        <MenuLayout
          menu={
            <div className="d-flex">
              <button
                className="btn btn-sm btn-light d-flex align-items-center"
                style={{
                  border: `1px solid ${EKleur.LichtGrijs}`,
                }}
                onClick={() => setToevoegenTrsOpdID(row.TrsOpdID)}
              >
                <IconToevoegen style={{ width: 16, height: 16, fill: EKleur.Grijs }} />
                <span className="ml-2">Nieuwe regel</span>
              </button>
              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-3"
                style={{
                  border: `1px solid ${EKleur.LichtGrijs}`,
                }}
                disabled={regelsSelectie.length !== 2}
                onClick={() => handlKoppelenOmruil(regelsSelectie)}
              >
                {/* <IcoonToevoegen style={{ width: 16, height: 16, fill: EKleur.Grijs }} /> */}
                <span className="ml-2">Koppelen omruil</span>
              </button>

              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-3"
                style={{
                  border: `1px solid ${EKleur.LichtGrijs}`,
                }}
                disabled={regelsSelectie.length !== 1}
                onClick={() => handlVerwijderenOmruil(regelsSelectie[0])}
              >
                {/* <IcoonVerwijderen style={{ width: 16, height: 16, fill: EKleur.Grijs }} /> */}
                <span className="ml-2">Verwijderen omruil</span>
              </button>

              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-3"
                style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                disabled={regelsSelectie.length === 0}
                onClick={() => {
                  setVerplaatsenRegelsDialoogState({ trsRegIDs: regelsSelectie });
                }}
              >
                <IconVerplaatsen
                  style={{
                    width: 16,
                    height: 16,
                    fill: EKleur.Grijs,
                  }}
                />
                <span className="ml-2">Verplaatsen</span>
              </button>

              {/* <button
                className="btn btn-sm btn-light d-flex align-items-center ml-2"
                style={{
                  border: `1px solid ${EKleur.LichtGrijs}`,
                }}
                disabled={regelsSelectie.length !== 1}
                onClick={() => handlWijzigenProduct(regelsSelectie[0])}
              >
                <WitgoedApparaat style={{ width: 16, height: 16, fill: EKleur.Grijs }} />
                <span className="ml-2">Productgegevens wijzigen</span>
              </button> */}

              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-3"
                style={{
                  border: `1px solid ${EKleur.LichtGrijs}`,
                }}
                disabled={regelsSelectie.length === 0}
                onClick={() => handlVerwijderenOpdrachtregels(regelsSelectie)}
              >
                <IconVerwijderen
                  style={{
                    width: 16,
                    height: 16,
                    fill: EKleur.Grijs,
                  }}
                />
                <span className="ml-2">Verwijderen regels</span>
              </button>
              <div className="flex-fill" />
              <BijlageKnop
                selectieModus={ESelectieModus.Meerdere}
                onBijgevoegd={(bijlagen) =>
                  muteerBestanden((bestanden) => [...bestanden, ...bijlagen])
                }
                bronModus={EBronModus.Alle}
                disabled={isBezigMetUploaden}
              />
            </div>
          }
          body={
            <div>
              {bestanden !== null && bestanden.length > 0 && (
                <BijlagenContainer
                  bestanden={bestanden}
                  uploadProgresses={uploadProgresses}
                  onBestandenChange={(x) => muteerBestanden((_) => x)}
                  bestandenMuterenToegestaan={!isBezigMetUploaden}
                />
              )}
              {
                <GridStyleWrapper maxHeight={500} rowAmount={gridData.length}>
                  <Grid rows={gridData} getRowId={keyExtractor} columns={kolommen}>
                    <DataTypeProvider
                      for={['__soort']}
                      formatterComponent={(props) => {
                        const rij: IRegelRow = props.row;
                        return (
                          <>
                            <span>
                              {rij.regelsoort.Code} {rij.OmruilID !== null ? '/O' : ''}
                            </span>
                            {/* <span>{rij.indicaties.isVermoedelijkOmruil ? ' (/O?)' : ''}</span> */}
                          </>
                        );
                      }}
                    />

                    <DataTypeProvider
                      for={['__voorraadReservering']}
                      formatterComponent={(formatterProps) => {
                        return (
                          <ReserveringProductComp
                            rij={formatterProps.row}
                            magID={row.magazijn.MagID}
                            onVernieuwen={planningTabelContext.onRequestRefresh}
                          />
                        );
                      }}
                    />

                    <DataTypeProvider
                      for={['__nieuw']}
                      formatterComponent={(formatterProps) => {
                        const rij: IRegelRow = formatterProps.row;
                        return <NieuwOfGebruiktComp rij={rij} />;
                      }}
                    />

                    <DataTypeProvider
                      for={['__voorraadInfo']}
                      formatterComponent={(formatterProps) => {
                        const rij: IRegelRow = formatterProps.row;

                        if (rij.type !== null) {
                          if (rij.type.Bulkproduct) {
                            // Bulkproduct
                            return <span></span>;
                          }
                          // Type
                          return (
                            <div className="d-flex">
                              <a
                                href="#"
                                onClick={() => {
                                  setVoorraadTypeInfoDialoogState({
                                    typeID: rij.type!.TypeID,
                                    magID: row.magazijn.MagID,
                                  });
                                }}
                              >
                                <IconInformatie
                                  style={{ width: 15, height: 15, fill: EKleur.Blauw }}
                                />
                              </a>
                            </div>
                          );
                        }

                        if (rij.product !== null) {
                          // Product
                          return (
                            <a
                              href="#"
                              // style={{ color: Kleur.DonkerGrijs }}
                              onClick={() => {
                                setProductinfoDialoogState({
                                  id: rij.product!.ProdID!,
                                });
                              }}
                            >
                              <IconInformatie
                                style={{ width: 15, height: 15, fill: EKleur.Blauw }}
                              />
                            </a>
                          );
                        }

                        return <span></span>;
                      }}
                    />

                    <DataTypeProvider
                      for={['__productreservering']}
                      formatterComponent={(formatterProps) => {
                        const rij: IRegelRow = formatterProps.row;

                        const regel: IRegelXXX = { ...rij, opdracht: row };
                        return (
                          <span className="d-flex">
                            <ProductReservering
                              rij={regel}
                              onRequestRefresh={planningTabelContext.onRequestRefresh}
                              koppelDialoogState={koppelDialoogState}
                              onKoppelDialoogStateChange={setKoppelDialoogState}
                            />
                            {rij.product !== null && (
                              <span className="ml-1 align-items-center">
                                <a
                                  href="#"
                                  onClick={() => {
                                    setWijzigenProductDialoogTonen(rij.product!.ProdID);
                                  }}
                                >
                                  <WitgoedApparaat
                                    style={{ width: 14, height: 14, fill: EKleur.GrijsScheiding }}
                                  />
                                </a>
                              </span>
                            )}
                          </span>
                        );
                      }}
                    />

                    <DataTypeProvider
                      for={['__productsoort']}
                      formatterComponent={(formatterProps) => {
                        const rij: IRegelRow = formatterProps.row;
                        const product =
                          rij.type !== null ? (
                            <span>{rij.type.ProductsoortnaamKort}</span>
                          ) : rij.product !== null ? (
                            <span>{rij.product.producttype.ProductsoortnaamKort}</span>
                          ) : null;
                        return <span>{product}</span>;
                      }}
                    />

                    <DataTypeProvider
                      for={['__modelcode']}
                      formatterComponent={(formatterProps) => {
                        const rij: IRegelRow = formatterProps.row;

                        if (rij.contract === null) {
                          return <span></span>;
                        }

                        return (
                          <a
                            href="#"
                            onClick={async () =>
                              setProductmodelInfoDialoogState({
                                prodModID: rij.contract!.basis.productmodel.ProdModID,
                              })
                            }
                          >
                            <span>{rij.contract.basis.productmodel.Modelcode}</span>
                          </a>
                        );
                      }}
                    />

                    <DataTypeProvider
                      for={['__referentie']}
                      formatterComponent={(formatterProps) => {
                        const rij: IRegelRow = formatterProps.row;
                        return <ReferentieProductComp rij={rij} />;
                      }}
                    />

                    <DataTypeProvider
                      for={['__contractnummer']}
                      formatterComponent={(formatterProps) => {
                        const rij: IRegelRow = formatterProps.row;
                        return (
                          <ContractnummerComp
                            cntID={rij.contract !== null ? rij.contract.CntID : null}
                          />
                        );
                      }}
                    />

                    <DataTypeProvider
                      for={['__abonnement']}
                      formatterComponent={(formatterProps) => {
                        const rij: IRegelRow = formatterProps.row;
                        if (rij.contract === null) {
                          return <span></span>;
                        }
                        return <span>{maandenNaarJaren(rij.contract.basis.MinimaleTermijn)}</span>;
                      }}
                    />

                    <DataTypeProvider
                      for={['__retourstatus']}
                      formatterComponent={(formatterProps) => {
                        const rij: IRegelRow = formatterProps.row;
                        if (rij.product === null || rij.product.retourstatus === null) {
                          return <span></span>;
                        }

                        // return (
                        //   <div className="mt-1">
                        //     <RetourStatusIndicatie
                        //       status={rij.product.Retourstatus.NaamEnum as ERetourStatus}
                        //     />
                        //   </div>
                        // );

                        return <span>{rij.product.retourstatus.Naam}</span>;
                      }}
                    />

                    {/* <DataTypeProvider
                    for={['__actieMenu']}
                    formatterComponent={(props) => {
                      return (
                        <ActieMenuKnop
                          acties={[
                            {
                              text: 'Wijzigen',
                              onClick: () => null,
                            },
                          ]}
                        />
                      );
                    }}
                  /> */}

                    <DataTypeProvider
                      for={['relatienaam']}
                      formatterComponent={(p) => {
                        const rij: IRegelRow = p.row;
                        if (rij.RelID !== null) {
                          const urlState: ITransportopdrachtenOverzichtUrlState = {
                            ...transportopdrachtenOverzichtDefaultUrlState,
                            uitgeklapteOpdrachten: [rij.TrsOpdID],
                            opdrachtenSelectie: [rij.TrsOpdID],
                            opdrachtenStates: {
                              [rij.TrsOpdID]: {
                                ...defaultOpdrachtState,
                                regelsSelectie: [rij.TrsRegID],
                              },
                            },
                          };
                          const query = genereerUrlStateQueryParam(urlState);

                          return (
                            <RelatieVisualisatie
                              relID={rij.RelID}
                              relatieLinkBuilder={(hoedanigheid, relID) =>
                                `/${
                                  hoedanigheid === EHoedanigheid.Klant ? 'klant' : 'leverancier'
                                }/${relID}/transport/opdracht?state=${query}`
                              }
                            />
                          );
                        } else {
                          return <span />;
                        }
                      }}
                    />

                    <SelectionState
                      selection={regelsSelectie}
                      onSelectionChange={(x) => setRegelsSelectie(x as number[])}
                    />
                    <EditingState
                      // onAddedRowsChange={() => {
                      //   setToevoegenTrsOpdID(row.TrsOpdID);
                      // }}
                      onCommitChanges={(changes) => {
                        if (changes.deleted === undefined) {
                          return;
                        }
                        const deleted = changes.deleted;
                        const id = deleted[deleted.length - 1];
                      }}
                      onEditingRowIdsChange={(rowIds) => {
                        const id = rowIds[rowIds.length - 1] as number;
                        setWijzigenRegelDialoogTonen(id);
                      }}
                    />
                    <Table />
                    <TableColumnResizing defaultColumnWidths={kolomBreedtes as any} />
                    <TableEditColumn
                      width={35}
                      showEditCommand
                      // showAddCommand
                      // showDeleteCommand
                      cellComponent={DXTableEditColumnCellComponent}
                      commandComponent={DXTableEditColumnCommandComponent}
                    />
                    <TableSelection cellComponent={DXTableCheckboxComponent} />
                    <TableHeaderRow />
                  </Grid>
                </GridStyleWrapper>
              }
            </div>
          }
        />
        {toevoegenTrsOpdID !== null && (
          <ToevoegenRegelsDialoog
            open
            trsOpdID={toevoegenTrsOpdID}
            onSuccess={() => {
              setToevoegenTrsOpdID(null);
              planningTabelContext.onRequestRefresh();
            }}
            onAnnuleren={() => setToevoegenTrsOpdID(null)}
          />
        )}
        {wijzigenProductDialoogTonen !== null && (
          <WijzigenProductDialoog
            open
            prodID={wijzigenProductDialoogTonen}
            onSuccess={(data) => {
              setWijzigenProductDialoogTonen(null);
              planningTabelContext.onRequestRefresh();
            }}
            onAnnuleren={() => setWijzigenProductDialoogTonen(null)}
          />
        )}
        {wijzigenRegelDialoogTonen !== null && (
          <WijzigenRegelDialoog
            open
            trsOpdID={row.TrsOpdID}
            trsRegID={wijzigenRegelDialoogTonen}
            onSuccess={() => {
              setWijzigenRegelDialoogTonen(null);
              planningTabelContext.onRequestRefresh();
            }}
            onAnnuleren={() => setWijzigenRegelDialoogTonen(null)}
          />
        )}
        {productinfoDialoogState !== null && (
          <ProductinfoDialoog
            open
            id={productinfoDialoogState.id}
            onSuccess={() => {
              setProductinfoDialoogState(null);
              planningTabelContext.onRequestRefresh();
            }}
            onAnnuleren={() => setProductinfoDialoogState(null)}
          />
        )}
        {voorraadTypeInfoDialoogState !== null && (
          <VoorraadTypeInfoDialoog
            open
            typeID={voorraadTypeInfoDialoogState.typeID}
            magID={voorraadTypeInfoDialoogState.magID}
            onSuccess={() => setVoorraadTypeInfoDialoogState(null)}
            onAnnuleren={() => setVoorraadTypeInfoDialoogState(null)}
          />
        )}

        {productmodelInfoDialoogState !== null && (
          <ProductmodelInfoDialoog
            open
            prodModID={productmodelInfoDialoogState.prodModID}
            onSuccess={() => setProductmodelInfoDialoogState(null)}
            onAnnuleren={() => setProductmodelInfoDialoogState(null)}
          />
        )}
      </div>

      {koppelDialoogState && (
        <KoppelenProductDialoog
          open
          tabblad={koppelDialoogState.tabblad}
          voorraadTabblad={koppelDialoogState.voorraadTabblad}
          cntID={koppelDialoogState.cntID !== null ? koppelDialoogState.cntID : undefined}
          locID={koppelDialoogState.locID !== null ? koppelDialoogState.locID : undefined}
          onSuccess={async (result) => {
            let type = null;
            let product = null;

            switch (result.result.type) {
              case EKoppelenProductResultType.Locatie: {
                const locatieResult = result.result as ILocatieResult;
                const params = {
                  trsRegID: koppelDialoogState!.trsRegID,
                  type: null,
                  product: { prodID: locatieResult.prodID },
                };
                await api.v2.transport.opdracht.koppelenProductVoorTransportregel(params);

                await planningTabelContext.onRequestRefresh();
                setKoppelDialoogState(null);

                break;
              }
              case EKoppelenProductResultType.Voorraad: {
                const voorraadResult = result.result as IVoorraadResult;
                switch (voorraadResult.tabblad) {
                  case EVoorraadTabblad.Ingepakt:
                    // Bepaal of er vrije voorraad is
                    const voorraad = (
                      await api.v2.voorraad.ophalenVoorraadinfo({
                        magID: koppelDialoogState!.magID,
                        typeIDs: [voorraadResult.typeID!],
                      })
                    ).voorraad[0];

                    type = {
                      typeID: voorraadResult.typeID!,
                      bulkproduct: false,
                      gereserveerd: voorraad.aantalVrij > 0,
                    };
                    break;
                  case EVoorraadTabblad.Uitgepakt:
                    product = {
                      prodID: voorraadResult.prodID!,
                    };
                    break;
                  case EVoorraadTabblad.Bulk:
                    type = {
                      typeID: voorraadResult.typeID!,
                      bulkproduct: true,
                    };
                    break;
                }

                const params = {
                  trsRegID: koppelDialoogState!.trsRegID,
                  type,
                  product,
                };

                await api.v2.transport.opdracht.koppelenProductVoorTransportregel(params);

                planningTabelContext.onRequestRefresh();

                setKoppelDialoogState(null);

                break;
              }
            }
          }}
          onAnnuleren={() => setKoppelDialoogState(null)}
        />
      )}

      {verplaatsenRegelsDialoogState !== null && (
        <VerplaatsenOpdrachtregelsDialoog
          open
          trsRegIDs={verplaatsenRegelsDialoogState.trsRegIDs}
          onSuccess={() => {
            planningTabelContext.onRequestRefresh();
            setVerplaatsenRegelsDialoogState(null);
          }}
          onAnnuleren={() => setVerplaatsenRegelsDialoogState(null)}
        />
      )}
    </>
  );
};

export default Regels;
