import React, { useMemo, useState } from 'react';
import VeldWeergave from '../../formulier/VeldWeergave';
import { format } from 'date-fns';
import {
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../helpers/dxTableGrid';
import { Kleur as EKleur } from '../../../bedrijfslogica/constanten';
import { Grid, TableHeaderRow, VirtualTable } from '@devexpress/dx-react-grid-bootstrap4';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import nameOf from '../../../core/nameOf';
import { IOphalenWerkbonnenResultElement } from '../../../../../shared/src/api/v2/service/werkbon';
import OpdrachtInfoDialoog from '../OpdrachtInfoDialoog';
import DownloadKnop from '../../DownloadKnop';
import WerkbonInfoDialoog from '../WerkbonInfoDialoog';
import { IconInformatie } from '../../Icons';

interface IProps {
  werkbonnen: IOphalenWerkbonnenResultElement[];
}

interface IOpdrachtInfoDialoogState {
  id: number;
}

interface IInfoWerkbonDialoogState {
  id: number;
}

const WerkbonnenComp: React.FC<IProps> = (props) => {
  const [
    opdrachtInfoDialoogState,
    setOpdrachtInfoDialoogState,
  ] = useState<IOpdrachtInfoDialoogState | null>(null);

  const [
    infoWerkbonDialoogState,
    setInfoWerkbonDialoogState,
  ] = useState<IInfoWerkbonDialoogState | null>(null);

  const kolommenOpdracht = useMemo<TypedColumn<IOphalenWerkbonnenResultElement>[]>(
    () => [
      {
        name: 'Bezoekdatum',
        title: 'Bezoek',
      },
      {
        name: 'Werkzaamheden',
        title: 'Werkzaamheden',
      },
      {
        name: '__actieBijlage' as any,
        title: 'Bijl.',
      },
      {
        name: '__infoDialoog' as any,
        title: 'Info',
      },
    ],
    [],
  );

  const kolombreedtesOpdracht = useMemo<
    TypedTableColumnWidthInfo<IOphalenWerkbonnenResultElement>[]
  >(
    () => [
      {
        columnName: 'Bezoekdatum',
        width: 90,
      },
      {
        columnName: 'Werkzaamheden',
        width: 500,
      },
      {
        columnName: '__actieBijlage' as any,
        width: 80,
      },
      {
        columnName: '__infoDialoog' as any,
        width: 80,
      },
    ],
    [],
  );

  return (
    <>
      <VeldWeergave>
        <div className="p-1 font-weight-bold">Werkbonnen</div>
        <div className="mt-1" style={{ backgroundColor: EKleur.Wit }}>
          <GridStyleWrapper maxHeight={400} rowAmount={props.werkbonnen.length}>
            <Grid rows={props.werkbonnen} columns={kolommenOpdracht}>
              <DataTypeProvider
                for={[nameOf<IOphalenWerkbonnenResultElement>('Bezoekdatum')]}
                formatterComponent={(formatterProps) => (
                  <span>{format(new Date(formatterProps.value), 'dd-MM-yyyy')}</span>
                )}
              />

              <DataTypeProvider
                for={['__actieBijlage']}
                formatterComponent={(props) => {
                  const rij = props.row as IOphalenWerkbonnenResultElement;

                  if (rij.bron === null) {
                    return <span></span>;
                  }

                  const url = rij.bron.url;

                  return (
                    <DownloadKnop
                      onDownloadAangevraagd={async () => {
                        window.open(url, '_blank');
                      }}
                    />
                  );
                }}
              />

              <DataTypeProvider
                for={['__infoDialoog']}
                formatterComponent={(props) => {
                  const rij = props.row as IOphalenWerkbonnenResultElement;

                  return (
                    <div>
                      <a
                        href="#"
                        className="ml-1"
                        style={{ color: EKleur.LichtBlauw }}
                        onClick={() => {
                          setInfoWerkbonDialoogState({ id: rij.ID });
                        }}
                      >
                        <IconInformatie style={{ width: 15, height: 15, fill: EKleur.Blauw }} />
                      </a>
                    </div>
                  );
                }}
              />

              <VirtualTable
                columnExtensions={kolombreedtesOpdracht}
                messages={{ noData: 'Geen werkbonnen bekend' }}
              />
              <TableHeaderRow />
            </Grid>
          </GridStyleWrapper>
        </div>
      </VeldWeergave>

      {opdrachtInfoDialoogState !== null && (
        <OpdrachtInfoDialoog
          id={opdrachtInfoDialoogState.id}
          open
          onSuccess={() => setOpdrachtInfoDialoogState(null)}
          onAnnuleren={() => setOpdrachtInfoDialoogState(null)}
        />
      )}
      {infoWerkbonDialoogState !== null && (
        <WerkbonInfoDialoog
          open
          id={infoWerkbonDialoogState.id}
          onSuccess={() => {
            setInfoWerkbonDialoogState(null);
          }}
          onAnnuleren={() => setInfoWerkbonDialoogState(null)}
        />
      )}
    </>
  );
};

export default WerkbonnenComp;
