import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import IDialoogProps from '../../../core/IDialoogProps';
import Dialoog from '../../dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import * as Yup from 'yup';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../stores/RootStore';
import Combobox from '../../formulier/Combobox';
import nameof from '../../../core/nameOf';
import {
  IBepaalTransporteurVoorOpdrachtenResult,
  IOphalenOpdrachtenResultElementV2,
} from '../../../../../shared/src/api/v2/transport/opdracht';
import { IOphalenOpdrachtwijzenResultElement } from '../../../../../shared/src/api/v2/dienst';
import { IOphalenDienstenResultElement } from '../../../../../shared/src/api/v2/dienst/transport';
import api from '../../../api';
import { Kleur } from '../../../bedrijfslogica/constanten';
import LoadingSpinner from '../../Gedeeld/LoadingSpinner';
import { EResultType } from '../../../stores/CheckStore';
import _ from 'lodash';
import { EIndicatiePremium, EOpdrachtwijze } from '../../../bedrijfslogica/enums';

interface IDialoogResult {}

interface IProps extends IDialoogProps<IDialoogResult> {
  trsOpdIDs: number[];
}

interface IFormikValues {
  opdrachtwijze: number | null;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  opdrachtwijze: 'Opdrachtwijze',
};

const VersturenDialoog: React.FC<IProps> = observer((props) => {
  const { children, dialoogIndex, onAnnuleren, onSuccess, open, trsOpdIDs } = props;
  const { checkStore } = useContext(RootStoreContext);
  const [opdrachtwijzen, setOpdrachtwijzen] = useState<
    IOphalenOpdrachtwijzenResultElement[] | null
  >(null);

  // Bepaal de transporteur (alle opdrachten moeten voor dezelfde transporteur zijn)
  const [
    bepaaldeTransporteur,
    setTrsDienstID,
  ] = useState<IBepaalTransporteurVoorOpdrachtenResult | null>(null);

  useEffect(() => {
    (async () => {
      const result = await api.v2.transport.opdracht.bepaalTransporteurVoorOpdrachten({
        trsOpdIDs,
      });
      setTrsDienstID(result);
    })();
  }, [trsOpdIDs]);

  // useEffect(() => {
  //   (async () => {
  //     const opdrachten = (
  //       await api.v2.transport.opdracht.ophalenOpdrachtenV2({
  //         filterSchema: { filters: [{ naam: 'IDS', data: trsOpdIDs }] },
  //       })
  //     ).opdrachten;
  //     const dienstID = _.uniq(opdrachten.map((x) => x.dienst.ID))[0];
  //   })();
  // }, [trsOpdIDs]);

  // Haal de transportdienst op voor de gevonden transporteur
  const [dienst, setDienst] = useState<IOphalenDienstenResultElement | null>(null);

  useEffect(() => {
    (async () => {
      if (bepaaldeTransporteur === null) {
        return;
      }

      const dienst = (
        await api.v2.dienst.transport.ophalenDiensten({
          filterSchema: {
            filters: [
              {
                naam: 'IDS',
                data: [bepaaldeTransporteur.TrsDienstID],
              },
            ],
          },
        })
      ).diensten[0];
      setDienst(dienst);
    })();
  }, [bepaaldeTransporteur]);

  // Haal de mogelijke opdrachtwijzen op voor de transportdienst
  useEffect(() => {
    if (bepaaldeTransporteur === null) {
      return;
    }

    (async () => {
      const result = await api.v2.dienst.ophalenOpdrachtwijzenVoorDienst({
        filterSchema: {
          filters: [{ naam: 'TRSDIENST_IDS', data: [bepaaldeTransporteur.TrsDienstID] }],
        },
      });

      setOpdrachtwijzen(result.opdrachtwijzen);
    })();
  }, [bepaaldeTransporteur]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      if (dienst === null || opdrachtwijzen === null) {
        actions.setSubmitting(false);
        return;
      }

      const opdrachtwijze = opdrachtwijzen.find((x) => x.OpdWijzeID === values.opdrachtwijze)!;

      if (
        (opdrachtwijze.NaamEnum === EOpdrachtwijze.XML_FTP ||
          opdrachtwijze.NaamEnum === EOpdrachtwijze.XML_FTP_PDF_Email) &&
        !dienst.FTPToegestaan
      ) {
        await checkStore.melden({
          titel:
            'De gekozen opdrachtwijze heeft een FTP-service nodig. Deze is bij de betreffende transportdienst ingesteld als Niet toegestaan.',
        });
        actions.setSubmitting(false);
        return;
      }

      const checkData = await api.v2.transport.checkVersturenOpdrachten({
        trsOpdIDs,
        opdWijzeID: values.opdrachtwijze!,
      });

      // const checkDataPremium = await api.v2.transport.opdracht.checkToevoegenPremiumregels({
      //   trsOpdIDs,
      //   verkModID: null,
      // });

      // const checkData = {
      //   errors: _.flatten([checkDataVersturen.errors, checkDataPremium.errors]),
      //   warnings: _.flatten([checkDataVersturen.warnings, checkDataPremium.warnings]),
      // };

      const checkResult = await checkStore.controleren({
        checkData,
      });
      if (checkResult.type === EResultType.Annuleren) {
        actions.setSubmitting(false);
        return;
      }

      if (
        (
          await checkStore.bevestigen({
            inhoud: 'Geselecteerde transportopdrachten versturen?',
          })
        ).type === EResultType.Annuleren
      ) {
        actions.setSubmitting(false);
        return;
      }

      // Voor welke opdrachten voegen we mogelijk premiums toe?
      const opdrachten = (
        await api.v2.transport.opdracht.ophalenOpdrachtenV2({
          filterSchema: { filters: [{ naam: 'IDS', data: trsOpdIDs }] },
        })
      ).opdrachten;

      const trsOpdIDsWaarvoorToevoegen = opdrachten
        .filter((x) => x.IndicatiePremium.NaamEnum === EIndicatiePremium.Automatisch)
        .map((x) => x.TrsOpdID);

      // Premiumregels (b.v. Welkomstpakketten) toevoegen
      const premiumsVoorOpdrachtenResult = (
        await api.v2.transport.opdracht.premium.ophalenPremiumsVoorOpdrachten({
          trsOpdIDs: trsOpdIDsWaarvoorToevoegen,
        })
      ).opdrachten;

      const premiumParams = {
        opdrachten: premiumsVoorOpdrachtenResult.map((x) => {
          return { trsOpdID: x.TrsOpdID, premiumIDs: x.premiums.map((x) => x.ID) };
        }),
      };

      await api.v2.transport.opdracht.toevoegenPremiumregels(premiumParams);

      // Opdrachten versturen
      await api.v2.transport.versturenOpdrachten({
        trsOpdIDs,
        opdWijzeID: values.opdrachtwijze!,
      });

      props.onSuccess({});
      actions.setSubmitting(false);
    },
    [dienst, opdrachtwijzen],
  );

  const initialValues = useMemo<IFormikValues>(() => {
    return {
      opdrachtwijze: dienst !== null && dienst.OpdWijzeID !== undefined ? dienst.OpdWijzeID : null,
    };
  }, [dienst]);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        opdrachtwijze: Yup.number().required(),
      }),
    [],
  );

  return (
    <Dialoog index={dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Versturen opdrachten</ModalTitle>
      </ModalHeader>
      <Formik<IFormikValues>
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        isInitialValid
        render={(formikProps: FormikProps<IFormikValues>) => {
          const { submitForm, isValid, isSubmitting } = formikProps;

          return (
            <>
              <ModalBody>
                <div className="form-group">
                  <div className="row">
                    <div className="col-12 d-flex flex-column">
                      <label>Transporteur</label>
                      <div
                        className="font-weight-bold flex-fill"
                        style={{
                          border: `1px solid ${Kleur.LichtGrijs}`,
                          padding: '4px 8px',
                          borderRadius: 3,
                        }}
                      >
                        {dienst === null ? (
                          <LoadingSpinner />
                        ) : dienst.Naam !== null ? (
                          dienst.Naam
                        ) : (
                          dienst.relatie!.weergavenaam
                        )}
                      </div>
                    </div>

                    <div className="col-12 mt-2">
                      <label>{veldnamen.opdrachtwijze}</label>
                      <Field
                        name={nameof<IFormikValues>('opdrachtwijze')}
                        render={(fieldProps: FieldProps<IFormikValues>) => {
                          const { field, form } = fieldProps;

                          if (opdrachtwijzen === null) {
                            return <LoadingSpinner />;
                          }

                          return (
                            <Combobox
                              geselecteerd={field.value}
                              opties={opdrachtwijzen!.map((opdrachtwijze) => {
                                return {
                                  id: opdrachtwijze.OpdWijzeID,
                                  label: opdrachtwijze.Naam,
                                };
                              })}
                              onSelectieChange={(x) => form.setFieldValue(field.name, x)}
                            />
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
              </ModalBody>
              <ModalFooter className="d-flex flex-row justify-content-start">
                <button
                  className="btn btn-primary"
                  onClick={submitForm}
                  style={{ width: 100 }}
                  disabled={!isValid || isSubmitting}
                >
                  Ok
                </button>
                <button
                  className="btn btn-secondary"
                  onClick={onAnnuleren}
                  style={{ width: 100 }}
                  disabled={isSubmitting}
                >
                  Annuleren
                </button>
              </ModalFooter>
            </>
          );
        }}
      />
    </Dialoog>
  );
});

export default VersturenDialoog;
