import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import {
  DXTableCheckboxComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import {
  Grid,
  Table,
  TableColumnResizing,
  TableHeaderRow,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import { DataTypeProvider, SelectionState, TableRowDetail } from '@devexpress/dx-react-grid';
import { format } from 'date-fns';
import FormatteerBedrag from '../../../../components/MutatieBedrag';
import DownloadKnop from '../../../../components/DownloadKnop';
import api from '../../../../api';
import TabelInspringBlok from '../../../../components/layout/TabelInspringBlok';
import { IOphalenMagazijnenResultElement } from '../../../../../../shared/src/api/v2/magazijn';
import {
  IDocument,
  IOphalenProceduresResultElement,
} from '../../../../../../shared/src/api/v2/relatie/acceptatie';
import nameof from '../../../../core/nameOf';

interface IProps extends TableRowDetail.ContentProps {}

export interface IRij extends IDocument {}

const DetailComp: React.FC<IProps> = (props) => {
  const documenten: IRij[] = props.row.documenten;

  const keyExtractor = useCallback((row: IRij) => row.ID, []);

  const kolommen = useMemo<TypedColumn<IRij>[]>(() => {
    return [
      // {
      //   name: 'ID',
      //   title: 'ID',
      // },
      {
        name: '__naam' as any,
        title: 'Naam',
      },
      {
        name: 'Toelichting',
        title: 'Toelichting',
      },
      {
        name: 'Ontvangen',
        title: 'Ontvangen',
      },
    ];
  }, []);

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRij>[]>(() => {
    return [
      // {
      //   columnName: 'ID',
      //   width: 100,
      // },
      {
        columnName: '__naam' as any,
        width: 250,
      },
      {
        columnName: 'Toelichting',
        width: 400,
      },
      {
        columnName: 'Ontvangen',
        width: 100,
      },
    ];
  }, []);

  return (
    <>
      <div className="d-flex">
        <TabelInspringBlok />
        <GridStyleWrapper maxHeight={500} rowAmount={documenten.length}>
          <Grid getRowId={keyExtractor} rows={documenten} columns={kolommen}>
            <DataTypeProvider
              for={['__naam']}
              formatterComponent={(formatterProps) => {
                const rij: IRij = formatterProps.row;
                return <span>{rij.Naam}</span>;
              }}
            />

            <DataTypeProvider
              for={[nameof<IRij>('Ontvangen')]}
              formatterComponent={(formatterProps) => {
                return <span>{formatterProps.value ? 'Ja' : 'Nee'}</span>;
              }}
            />

            <VirtualTable messages={{ noData: 'Geen documenten' }} />
            <TableColumnResizing />
            <TableHeaderRow />
            <TableColumnResizing defaultColumnWidths={kolomBreedtes as any} />
          </Grid>
        </GridStyleWrapper>
      </div>
    </>
  );
};

export default DetailComp;
