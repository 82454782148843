import * as React from 'react';
import TegelBasis from '../../../../../../../components/tegel/TegelBasis';
import FormatteerBedrag from '../../../../../../../components/MutatieBedrag';
import { Kleur } from '../../../../../../../bedrijfslogica/constanten';
import { IOphalenInformatieVoorOverzichtBankopdracht } from '../../../../../../../../../shared/src/api/v2/relatie';
import { format } from 'date-fns';
import NavigatiePijl from '../NavigatiePijl';
import { genereerUrlStateQueryParam } from '../../../../../../../core/useUrlState';
import {
  IBankzakenBankopdrachtenUrlState,
  defaultBankzakenBankopdrachtenUrlState,
} from '../../../../../../../components/kaart/entiteiten/Bankzaken/Bankopdrachten';

interface IProps {
  relID: number;
  data: IOphalenInformatieVoorOverzichtBankopdracht;
}

const Bankopdrachttegel = (props: IProps) => {
  return (
    <TegelBasis titel="Bankopdrachten">
      <div>
        <table>
          <tbody>
            {props.data.bankopdrachten.map((bankopdracht) => {
              return (
                <tr key={bankopdracht.ID}>
                  <td style={{ width: 100 }}>
                    {bankopdracht.uitvoerdatum === null
                      ? 'z.s.m.'
                      : format(new Date(bankopdracht.uitvoerdatum), 'dd-MM-yyyy')}
                  </td>
                  <td style={{ width: 90 }}>
                    <FormatteerBedrag bedrag={Math.abs(bankopdracht.bedrag)} />
                  </td>
                  <td style={{ width: 100 }}>
                    {bankopdracht.bedrag >= 0 ? 'Incasso' : 'Betaling'}
                  </td>

                  {props.data.bankopdrachten.some((x) => x.gestorneerd) && (
                    <td className="pr-2" style={{ width: 450 }}>
                      {bankopdracht.gestorneerd ? (
                        <span style={{ color: Kleur.Rood }}>
                          Stornering
                          {bankopdracht.retourReden !== undefined &&
                            ` (${bankopdracht.retourReden})`}
                        </span>
                      ) : (
                        <span></span>
                      )}
                    </td>
                  )}

                  <td>
                    <NavigatiePijl
                      relID={props.relID}
                      pathWithStateBuilder={() => {
                        const state = genereerUrlStateQueryParam<IBankzakenBankopdrachtenUrlState>({
                          ...defaultBankzakenBankopdrachtenUrlState,
                          selectie: [bankopdracht.ID],
                        });
                        return `bankzaken/bankopdrachten?state=${state}`;
                      }}
                    />
                  </td>
                </tr>
              );
            })}
            {/*<tr>*/}
            {/*  <td style={{ width: 100 }}>14-11-2021</td>*/}
            {/*  <td style={{ width: 90 }}>*/}
            {/*    <MutatieBedrag bedrag={10} />*/}
            {/*  </td>*/}
            {/*  <td style={{ width: 75 }}>Betalen</td>*/}
            {/*  <td />*/}
            {/*</tr>*/}
            {/*<tr>*/}
            {/*  <td>10-11-2021</td>*/}
            {/*  <td>*/}
            {/*    <MutatieBedrag bedrag={99.95} />*/}
            {/*  </td>*/}
            {/*  <td>Incasso</td>*/}
            {/*  <td />*/}
            {/*</tr>*/}
            {/*<tr>*/}
            {/*  <td>08-11-2021</td>*/}
            {/*  <td>*/}
            {/*    <MutatieBedrag bedrag={8975} />*/}
            {/*  </td>*/}
            {/*  <td>Incasso</td>*/}
            {/*  <td>*/}
            {/*    <span style={{ color: Kleur.Rood }}>Stornering (Onvoldoende saldo)</span>*/}
            {/*  </td>*/}
            {/*</tr>*/}
          </tbody>
        </table>
      </div>
    </TegelBasis>
  );
};

export default Bankopdrachttegel;
