import { EFilter } from '..';
import { IWeergaveProps } from '../../../../../components/FilterBalkV2';
import React from 'react';
import LoadingSpinner from '../../../../../components/Gedeeld/LoadingSpinner';
import Combobox from '../../../../../components/formulier/Combobox';
import { IOphalenGroepenResult } from '../../../../../../../shared/src/api/v2/faq';

interface IProps extends IWeergaveProps<any> {
  groepen: IOphalenGroepenResult;
}

const FAQGroepFilter = (props: IProps) => {
  return (
    <div className="d-flex align-items-center">
      <span>Groep</span>
      <span className="ml-3">
        {props.groepen === null ? (
          <LoadingSpinner />
        ) : (
          <Combobox
            geselecteerd={props.data}
            onSelectieChange={(x) => {
              props.onDataChange(x!);
              props.setIsActief(true);
              props.toepassen();
            }}
            opties={props.groepen.groepen.map((groep: any) => ({
              id: groep.ID,
              label: groep.Naam,
            }))}
          />
        )}
      </span>
    </div>
  );
};

export default FAQGroepFilter;
