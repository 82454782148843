import {
  IMuterenContactVanContactverzoekParams,
  IMuterenContactVanContactverzoekResult,
  IOphalenContactverzoekenParams,
  IOphalenContactverzoekenResult,
} from '../../../../../shared/src/api/v2/contactverzoek';
import api from '../../index';

const contactverzoek = {
  ophalenContactverzoeken: async (
    params: IOphalenContactverzoekenParams,
  ): Promise<IOphalenContactverzoekenResult> => {
    return await api.post('v2/contactverzoek/ophalen-contactverzoeken', params);
  },
  muterenContactVanContactverzoek: async (
    params: IMuterenContactVanContactverzoekParams,
  ): Promise<IMuterenContactVanContactverzoekResult> => {
    return await api.post('v2/contactverzoek/muteren-contact-van-contactverzoek', params);
  },
};

export default contactverzoek;
