import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import IDialoogProps from '../../../../core/IDialoogProps';
import Dialoog from '../../../../components/dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import * as Yup from 'yup';
import { IOphalenProductsoortenResultElement } from '../../../../../../shared/src/api/v2/product/soort';
import { IOphalenDienstenVoorTypeResultElement } from '../../../../../../shared/src/api/v2/inkoop/opdracht/nieuw';
import { IOphalenOpdrachtenResultElement } from '../../../../../../shared/src/api/v2/inkoop/opdracht';
import api from '../../../../api';
import nameof from '../../../../core/nameOf';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../../stores/RootStore';
import Combobox from '../../../../components/formulier/Combobox';
import { EResultType } from '../../../../stores/CheckStore';

import MultiCombobox, { IKolom } from '../../../../components/formulier/MultiCombobox';
import NumeriekVeld from '../../../../components/formulier/NumeriekVeld';
import teksten from '../../../../bedrijfslogica/teksten';
import DatumKiezer from '../../../../components/formulier/DatumKiezer';
import { format } from 'date-fns';
import VinkVeld from '../../../../components/formulier/VinkVeld';
import { IOphalenMaterialenResultElement } from '../../../../../../shared/src/api/v2/sponsoring/materiaal';

interface IProps extends IDialoogProps<null> {
  id: number;
}

interface IFormikValues {
  naam: string;
  omschrijving: string | null;
  actief: boolean;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  naam: 'Naam',
  omschrijving: 'Omschrijving',
  actief: 'Actief',
};

const WijzigenDialoog: React.FC<IProps> = observer((props) => {
  const { dialoogIndex, onAnnuleren, onSuccess } = props;
  const { checkStore } = useContext(RootStoreContext);
  const formikRef = useRef<Formik<IFormikValues>>(null);

  const [materiaal, setMateriaal] = useState<IOphalenMaterialenResultElement | null>(null);

  const ophalenMateriaal = useCallback(async () => {
    const result = (
      await api.v2.sponsoring.materiaal.ophalenMaterialen({
        filterSchema: { filters: [{ naam: 'IDS', data: [props.id] }] },
      })
    ).materialen[0];
    setMateriaal(result);
  }, [props.id]);

  useEffect(() => {
    ophalenMateriaal();
  }, [ophalenMateriaal]);

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (materiaal === null) {
      return null;
    }
    return {
      naam: materiaal.Naam,
      omschrijving: materiaal.Omschrijving,
      actief: materiaal.Actief,
    };
  }, [materiaal]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      if (materiaal === null) {
        return;
      }
      actions.setSubmitting(true);

      const params = {
        id: props.id,
        naam: values.naam,
        omschrijving: values.omschrijving,
        actief: values.actief,
        bestandID: materiaal.bestand!.ID,
      };
      await api.v2.sponsoring.materiaal.wijzigenMateriaal(params);

      actions.setSubmitting(false);
      onSuccess(null);
    },
    [materiaal],
  );

  const validationSchema = useMemo(() => {
    const fields: Record<keyof IFormikValues, any> = {
      naam: Yup.string().required(),
      omschrijving: Yup.string().required(),
      actief: Yup.boolean(),
    };
    return Yup.object().shape(fields);
  }, []);

  return (
    <Dialoog index={dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Wijzigen materiaal</ModalTitle>
      </ModalHeader>
      {initialValues === null || materiaal === null ? (
        <LoadingSpinner />
      ) : (
        <Formik<IFormikValues>
          ref={formikRef}
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
          render={(formikProps: FormikProps<IFormikValues>) => {
            const { submitForm, isSubmitting, isValid } = formikProps;
            return (
              <>
                <ModalBody>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-12 mt-2">
                        <label>{veldnamen.naam}</label>
                        <Field
                          name={nameof<IFormikValues>('naam')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            return (
                              <input type="text" className="form-control" {...fieldProps.field} />
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-2">
                        <label>{veldnamen.omschrijving}</label>
                        <Field
                          name={nameof<IFormikValues>('omschrijving')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            return <textarea {...field} className="form-control" rows={2} />;
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <span className="d-flex align-items-center">
                          <Field
                            name="actief"
                            render={(x: FieldProps<IFormikValues>) => (
                              <VinkVeld
                                aangevinkt={x.field.value}
                                onGewijzigd={(aangevinkt: boolean) =>
                                  x.form.setFieldValue(x.field.name, aangevinkt)
                                }
                              />
                            )}
                          />
                          <span className="ml-2">{veldnamen.actief}</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </ModalBody>

                <ModalFooter className="d-flex flex-row justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    style={{ width: 100 }}
                    disabled={isSubmitting || !isValid}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={onAnnuleren}
                    style={{ width: 100 }}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Dialoog>
  );
});

export default WijzigenDialoog;
