/**
 * Uses canvas.measureText to compute and return the width of the given text of given font in pixels.
 *
 * @param {String} text The text to be rendered.
 * @param {String} font The css font descriptor that text is to be rendered with (e.g. "bold 14px verdana").
 *
 * @see https://stackoverflow.com/questions/118241/calculate-text-width-with-javascript/21015393#21015393
 */
let canvas: HTMLCanvasElement | null = null;

export function getTextWidth(text: string, font: string) {
  // re-use canvas object for better performance
  const c = canvas ?? (canvas = document.createElement('canvas'));
  const context = c.getContext('2d')!;
  context.font = font;
  const metrics = context.measureText(text);
  return metrics.width;
}

function getCssStyle(element: HTMLElement, prop: any) {
  return window.getComputedStyle(element, null).getPropertyValue(prop);
}

export function getCanvasFont(el = document.body) {
  const fontWeight = getCssStyle(el, 'font-weight') || 'normal';
  const fontSize = getCssStyle(el, 'font-size') || '16px';
  const fontFamily = getCssStyle(el, 'font-family') || 'Times New Roman';

  return {
    fontWeight,
    fontSize,
    fontFamily,
  };

  // return `${fontWeight} ${fontSize} ${fontFamily}`;
}

export interface ITekstMetSleutel {
  tekst: string;
  sleutel: number;
}

export interface IBepaalTekstOvereenkomingParams {
  gelijkheidspercentage: number;
  tekstenMetSleutel: ITekstMetSleutel[];
}

export interface IBepaalTekstOvereenkomingOutput {
  overeenkomingIndexMap: { [sleutel: number]: number };
  overeenkomingen: { [overeenkomingIndex: number]: { [charIndex: number]: boolean } };
}

/**
 * Bepaalt de onderscheidende tekst op basis van een gelijkheidspercentage op een lijst van gesorteerde teksten.
 * @param params
 */
export const bepaalTekstOvereenkomingen = (
  params: IBepaalTekstOvereenkomingParams,
): IBepaalTekstOvereenkomingOutput => {
  const overeenkomingIndexMap: { [sleutel: number]: number } = {};
  const overeenkomingen: { [overeenkomingIndex: number]: { [charIndex: number]: boolean } } = {};
  const overeenkomingTeksten: { [overeenkomingIndex: number]: string[] } = {};

  let huidigeOvereenkomingIndex = 0;
  for (const tekstMetSleutel of params.tekstenMetSleutel) {
    const { tekst, sleutel } = tekstMetSleutel;
    const chars = tekst.split('');
    const aantalKarakters = chars.length;

    const toevoegenNieuweOvereenkoming = () => {
      overeenkomingIndexMap[sleutel] = huidigeOvereenkomingIndex;
      overeenkomingen[huidigeOvereenkomingIndex] = chars.reduce((acc, char, charIndex) => {
        acc[charIndex] = true;
        return acc;
      }, {} as { [charIndex: number]: boolean });
      overeenkomingTeksten[huidigeOvereenkomingIndex] = [tekst];
      huidigeOvereenkomingIndex++;
    };

    const vorigeOvereenkomingIdx = huidigeOvereenkomingIndex - 1;
    let overeenkoming = overeenkomingen[vorigeOvereenkomingIdx];
    if (overeenkoming === undefined || Object.keys(overeenkoming).length !== aantalKarakters) {
      toevoegenNieuweOvereenkoming();
      continue;
    }

    const vorigeOvereenkomingTeksten = overeenkomingTeksten[vorigeOvereenkomingIdx];
    let wijktTeVerAfVanVorigeOvereenkoming = false;
    for (const vorigeTekst of vorigeOvereenkomingTeksten) {
      const vorigeChars = vorigeTekst.split('');
      const overeenkomendeChars = chars.map((char, charIndex) => {
        return char === vorigeChars[charIndex];
      });
      const overeenkomendPercentage = overeenkomendeChars.filter((x) => x).length / aantalKarakters;
      if (overeenkomendPercentage < params.gelijkheidspercentage) {
        wijktTeVerAfVanVorigeOvereenkoming = true;
        break;
      }

      overeenkoming = Object.keys(overeenkoming)
        .map(Number)
        .reduce<{ [charIndex: number]: boolean }>(
          (acc, charIndex) => ({
            ...acc,
            [charIndex]: acc[charIndex] && overeenkomendeChars[charIndex],
          }),
          overeenkoming,
        );
    }

    if (wijktTeVerAfVanVorigeOvereenkoming) {
      toevoegenNieuweOvereenkoming();
      continue;
    }

    overeenkomingIndexMap[sleutel] = vorigeOvereenkomingIdx;
    overeenkomingen[vorigeOvereenkomingIdx] = overeenkoming;
    overeenkomingTeksten[vorigeOvereenkomingIdx].push(tekst);
  }

  // Filter alle entries die volledig overeenkomen
  const indexesMetVolledigeOvereenkomst = Object.keys(overeenkomingen)
    .map(Number)
    .filter((idx) => {
      const overeenkoming = overeenkomingen[idx];
      return Object.values(overeenkoming).every((x) => x);
    });

  for (const idx of indexesMetVolledigeOvereenkomst) {
    delete overeenkomingen[idx];
  }

  const sleutelsMetVolledigeOvereenkomst = Object.keys(overeenkomingIndexMap)
    .map(Number)
    .filter((sleutel) => {
      const idx = overeenkomingIndexMap[sleutel];
      return indexesMetVolledigeOvereenkomst.includes(idx);
    });

  for (const sleutel of sleutelsMetVolledigeOvereenkomst) {
    delete overeenkomingIndexMap[sleutel];
  }

  return {
    overeenkomingIndexMap,
    overeenkomingen,
  };
};
