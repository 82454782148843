import React from 'react';
import styled from 'styled-components';
import { IData, IMergeDialoogVeld } from '../../types';

const Root = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  padding: 0 12px;
`;

const Label = styled.div`
  margin-left: 3px;
  margin-right: 10px;
  font-size: 13px;
  min-width: 100px;
`;

const InputContainer = styled.div`
  margin-top: 3px;
  flex: 1;
`;

interface IProps<TData> {
  veld: IMergeDialoogVeld<TData>;
  waarde: IData<TData>;
  onDataChange: (data: IData<TData>) => void;
  labelWidth?: number;
}

const FormulierVeld = <TData extends any>(props: IProps<TData>) => {
  const { veld } = props;

  const Input = veld.input;

  return (
    <Root style={{ height: veld.height }}>
      <Label>{veld.label}</Label>
      <InputContainer>
        <Input data={props.waarde} onDataChange={props.onDataChange} />
      </InputContainer>
    </Root>
  );
};

export default FormulierVeld;
