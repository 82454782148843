import {
  IOphalenProductenParams,
  IOphalenProductenResult,
} from '../../../../../shared/src/api/v2/revisie';
import api from '../../index';

const revisie = {
  ophalenProducten: async (params: IOphalenProductenParams): Promise<IOphalenProductenResult> => {
    return await api.post('/v2/revisie/ophalen-producten', params);
  },
};

export default revisie;
