import React from 'react';
import styled from 'styled-components';
import { Kleur } from '../../../../../../../../../bedrijfslogica/constanten';

const Root = styled.div<{ kleur: string }>`
  background-color: ${(props) => props.kleur};
  //width: 25,
  color: transparent;
  text-orientation: mixed;
  writing-mode: vertical-rl;
  letter-spacing: 2.5px;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 5px;
  max-width: 5px;
  overflow: hidden;
  transition-timing-function: ease-in-out;
  transition: max-width 500ms, min-width 500ms, color 300ms;

  &:hover {
    color: ${Kleur.Wit};
    max-width: 25px;
    min-width: 25px;
  }
`;

interface IProps {
  naam: string;
  kleur: string;
}

const StatusLabel = (props: IProps) => {
  return <Root kleur={props.kleur}>{props.naam}</Root>;
};

export default StatusLabel;
