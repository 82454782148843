import React, { useContext, useMemo } from 'react';
import { IProps } from '@tinymce/tinymce-react/lib/cjs/main/ts/components/Editor';
import { Field, FieldProps } from 'formik';
import VinkVeld from '../../../../../../../../../components/formulier/VinkVeld';
import { IFormikValues, veldnamen } from '../index';
import nameof from '../../../../../../../../../core/nameOf';
import Combobox from '../../../../../../../../../components/formulier/Combobox';
import { AantalGebruikersKeuze } from '../TarievenTab';
import DatumKiezer from '../../../../../../../../../components/formulier/DatumKiezer';
import MultiCombobox, {
  IKolom,
} from '../../../../../../../../../components/formulier/MultiCombobox';
import { IOphalenContractenResultElementV2 } from '../../../../../../../../../../../shared/src/api/v2/contract';
import ContractContext, { IContext } from '../Context/ContractContext';
import { addDays, format } from 'date-fns';

const ContractTab: React.FC<IProps> = (props) => {
  const { wisselContractenOpties } = useContext(ContractContext);

  const contractwisselKolommen = useMemo<IKolom<IOphalenContractenResultElementV2>[]>(
    () => [
      {
        key: '__Contractnummer' as any,
        label: 'Cnt.nr',
        breedte: 75,
        formatFabriek: (rij) => rij.basis.Basisnummer + '.' + rij.Volgnummer,
      },

      {
        key: '__Ingangsdatum' as any,
        label: 'Ing.datum',
        breedte: 85,
        formatFabriek: (rij) => {
          const datum = format(new Date(rij.Ingangsdatum), 'dd-MM-yyyy');
          return datum;
        },
      },
      {
        key: '__Einddatum' as any,
        label: 'Einddatum',
        breedte: 85,
        formatFabriek: (rij) => {
          const datum =
            rij.Einddatum !== null ? format(new Date(rij.Einddatum!), 'dd-MM-yyyy') : '';
          return datum;
        },
      },

      {
        key: '__modelcode' as any,
        label: 'Model',
        breedte: 100,
        formatFabriek: (rij) => {
          return rij.basis.productmodel.Modelcode;
        },
      },
      {
        key: '__productsoortnaamKort' as any,
        label: 'Soort',
        breedte: 60,
        formatFabriek: (rij) => {
          return rij.basis.productmodel.ProductsoortnaamKort;
        },
      },
      // { key: 'relatienaam', label: 'Relatie', breedte: 100 },
      {
        key: '__Locatie' as any,
        label: 'Locatie',
        breedte: 250,
        formatFabriek: (rij) => {
          return (
            rij.basis.locatie.Straatnaam +
            ' ' +
            rij.basis.locatie.Huisnummer +
            (rij.basis.locatie.Bisnummer !== null ? ` ${rij.basis.locatie.Bisnummer}` : '')
          );
        },
      },
    ],
    [],
  );

  return (
    <>
      <div className="p-3">
        <div className="form-group">
          <div className="row mb-3">
            <div className="col-6">
              <label>{veldnamen.aantalGebruikersMax}</label>

              <Field
                name={nameof<IFormikValues>('aantalGebruikersMax')}
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;
                  return (
                    <Combobox
                      opties={AantalGebruikersKeuze}
                      onSelectieChange={(x) => form.setFieldValue(field.name, x)}
                      geselecteerd={field.value}
                    />
                  );
                }}
              />
            </div>
            <div className="col-6">
              <label>{veldnamen.aantalGebruikersTarief}</label>
              <Field
                name={nameof<IFormikValues>('aantalGebruikersTarief')}
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;
                  return (
                    <Combobox
                      opties={AantalGebruikersKeuze}
                      onSelectieChange={(x) => form.setFieldValue(field.name, x)}
                      geselecteerd={field.value}
                    />
                  );
                }}
              />
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-3">
              <label>{veldnamen.ingangsdatum}</label>
              <Field
                name={nameof<IFormikValues>('ingangsdatum')}
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;
                  return (
                    <div>
                      <DatumKiezer
                        isClearable={true}
                        waarde={field.value}
                        onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                        determinePreviousValidDate="ONBEGRENST"
                        determineNextValidDate="ONBEGRENST"
                      />
                    </div>
                  );
                }}
              />
            </div>

            <div className="col-3">
              <label>{veldnamen.einddatum}</label>
              <Field
                name={nameof<IFormikValues>('einddatum')}
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;
                  return (
                    <div>
                      <DatumKiezer
                        isClearable={true}
                        waarde={field.value}
                        onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                        determinePreviousValidDate="ONBEGRENST"
                        determineNextValidDate="ONBEGRENST"
                      />
                    </div>
                  );
                }}
              />
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-6">
              <label>{veldnamen.contractwissel_CntID}</label>
              <Field
                name="contractwissel_CntID"
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;

                  return (
                    <MultiCombobox<number, IOphalenContractenResultElementV2>
                      sleutelExtractor={(row) => row.CntID}
                      // representatieFabriek={(row) =>
                      //   row.basis.Basisnummer +
                      //   '.' +
                      //   row.Volgnummer +
                      //   ' - ' +
                      //   row.basis.productmodel.Modelcode
                      // }
                      representatieFabriek={(x) => x.basis.Basisnummer + '.' + x.Volgnummer}
                      waarde={field.value}
                      onWaardeChange={(x) => form.setFieldValue(field.name, x)}
                      opties={wisselContractenOpties}
                      kolommen={contractwisselKolommen}
                      isWisbaar
                      options={{
                        geenWaardeBericht: 'Geen wissel',
                      }}
                    />
                  );
                }}
              />
            </div>
            <div className="row col-6 mt-4">
              <div className="d-flex align-items-center">
                <Field
                  name={nameof<IFormikValues>('kostenVtbNietToepassen')}
                  render={(x: FieldProps<IFormikValues>) => (
                    <VinkVeld
                      aangevinkt={x.field.value}
                      onGewijzigd={(aangevinkt: boolean) =>
                        x.form.setFieldValue(x.field.name, aangevinkt)
                      }
                    />
                  )}
                />
                <span className="ml-2">{veldnamen.kostenVtbNietToepassen}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContractTab;
