import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import IDialoogProps from '../../../../../core/IDialoogProps';
import Dialoog from '../../../../../components/dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import LoadingSpinner from '../../../../../components/Gedeeld/LoadingSpinner';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import * as Yup from 'yup';
import { IOphalenProductsoortenResultElement } from '../../../../../../../shared/src/api/v2/product/soort';
import {
  IOphalenDienstenVoorTypeResultElement,
  IOphalenProducttypenResultElement,
} from '../../../../../../../shared/src/api/v2/inkoop/opdracht/nieuw';
import api from '../../../../../api';
import nameof from '../../../../../core/nameOf';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../../../stores/RootStore';
import Combobox from '../../../../../components/formulier/Combobox';

import MultiCombobox, { IKolom } from '../../../../../components/formulier/MultiCombobox';
import NumeriekVeld from '../../../../../components/formulier/NumeriekVeld';
import teksten from '../../../../../bedrijfslogica/teksten';
import * as _ from 'lodash';
import VinkVeld from '../../../../../components/formulier/VinkVeld';
import { IFilterSchemaFilter } from '../../../../../../../shared/src/models/filter';
import { EResultType } from '../../../../../stores/CheckStore';

interface IProps extends IDialoogProps<null> {}

interface IFormikValues {
  prodSrtID: number | null;
  typeID: number | null;
  aantalMinimaal: number;
  aanvullenTot: number;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  prodSrtID: 'Productsoort',
  typeID: 'Type',
  aantalMinimaal: 'Ondergrens',
  aanvullenTot: 'Aanvullen tot',
};

const NieuwDialoog: React.FC<IProps> = observer((props) => {
  const { dialoogIndex, onAnnuleren, onSuccess } = props;
  const { checkStore } = useContext(RootStoreContext);
  const formikRef = useRef<Formik<IFormikValues>>(null);

  const [productsoorten, setProductsoorten] = useState<
    IOphalenProductsoortenResultElement[] | null
  >(null);
  const [producttypen, setProducttypen] = useState<IOphalenProducttypenResultElement[] | null>(
    null,
  );
  const [typeID, setTypeID] = useState<number | null>(null);
  const [prodSrtID, setProdSrtID] = useState<number | null>(null);

  // Rijbron met productsoorten
  useEffect(() => {
    (async () => {
      const results = await api.v2.product.soort.ophalenProductsoorten({
        filterSchema: {
          filters: [prodSrtID !== null ? { naam: 'ACTIEF', data: true } : null].filter(
            (x) => x !== null,
          ) as IFilterSchemaFilter[],
        },
      });

      setProductsoorten(results);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (productsoorten === null) {
        return;
      }
      setProdSrtID(productsoorten[0].ProdSrtID);
    })();
  }, [productsoorten]);

  // Rijbron met producttypen
  useEffect(() => {
    (async () => {
      if (prodSrtID === null) {
        return;
      }
      const results = (
        await api.v2.product.type.ophalenProducttypen({
          filterSchema: {
            filters: [
              { naam: 'PRODSRT_IDS', data: [prodSrtID] },
              { naam: 'INKOOP_TOEGESTAAN', data: true },
            ],
          },
        })
      ).producttypen;
      const producttypen = _.orderBy(results, ['Merknaam', 'Typenaam'], ['asc', 'asc']);

      setProducttypen(producttypen);
    })();
  }, [prodSrtID]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      const instellingen = await api.v2.instelling.ophalenInstellingen({});

      const params = {
        magID: instellingen.Voorraad_MagID!,
        typeID: values.typeID!,
        aantalMinimaal: values.aantalMinimaal,
        aanvullenTot: values.aanvullenTot,
      };

      // const checkData = await api.v2.inkoop.parameters.toevoegenTypeParameters(checkParams);

      // const controleResult = await checkStore.controleren({
      //   checkData,
      // });
      // if (controleResult.type === EResultType.Annuleren) {
      //   return;
      // }

      const result = await api.v2.inkoop.parameters.toevoegenTypeParameters(params);

      actions.setSubmitting(false);
      onSuccess(null);
    },
    [],
  );

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (prodSrtID === null) {
      return null;
    }

    return {
      prodSrtID,
      typeID: null,
      aantalMinimaal: 1,
      aanvullenTot: 1,
    };
  }, [prodSrtID]);

  const validationSchema = useMemo(() => {
    const fields: Record<keyof IFormikValues, any> = {
      prodSrtID: Yup.number(),
      typeID: Yup.number().required(),
      aantalMinimaal: Yup.number()
        .min(
          1,
          teksten.formulier.E_GETAL_MINIMAAL({ veldnaam: veldnamen.aantalMinimaal, aantal: 1 }),
        )
        .max(
          250,
          teksten.formulier.E_GETAL_MAXIMAAL({ veldnaam: veldnamen.aantalMinimaal, aantal: 250 }),
        ),
      aanvullenTot: Yup.number()
        .min(1, teksten.formulier.E_GETAL_MINIMAAL({ veldnaam: veldnamen.aanvullenTot, aantal: 1 }))
        .max(
          250,
          teksten.formulier.E_GETAL_MAXIMAAL({ veldnaam: veldnamen.aanvullenTot, aantal: 250 }),
        ),
    };
    return Yup.object().shape(fields);
  }, []);

  const typenKolommen = useMemo<IKolom<IOphalenProducttypenResultElement>[]>(() => {
    const breedteSoort = prodSrtID === null ? 75 : 0;
    return [
      {
        key: 'Merknaam',
        label: 'Merk',
        breedte: 100,
      },
      {
        key: 'Typenaam',
        label: 'Type',
        breedte: 200,
      },
      {
        key: 'ProductsoortnaamKort',
        label: 'Soort',
        breedte: breedteSoort,
      },
      {
        key: 'Kenmerk',
        label: 'Kenmerk',
        breedte: 200,
      },
    ];
  }, [prodSrtID]);

  const leveranciersKolommen = useMemo<IKolom<IOphalenDienstenVoorTypeResultElement>[]>(() => {
    return [
      {
        key: 'LeveranciernaamKort',
        label: 'Leverancier',
        breedte: 200,
      },
      {
        key: 'Prioriteit',
        formatFabriek: (row) => {
          return <span>{row.Prioriteit ? 'Ja' : ''}</span>;
        },
        label: 'Prio.lev.',
        breedte: 100,
      },
      {
        key: 'Inkoopprijs',
        label: 'Ink.prijs',
        breedte: 100,
      },
      {
        key: 'InkoopprijsActie',
        label: 'Actieprijs',
        breedte: 100,
      },
      {
        key: 'ActieMinimaalAantal',
        label: 'Min.#',
        breedte: 75,
      },
    ];
  }, []);

  return (
    <Dialoog index={dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Toevoegen producttype</ModalTitle>
        {/* <span>{JSON.stringify(producttypen)}</span> */}
      </ModalHeader>
      {initialValues === null || productsoorten === null || producttypen === null ? (
        <LoadingSpinner />
      ) : (
        <Formik<IFormikValues>
          ref={formikRef}
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
          render={(formikProps: FormikProps<IFormikValues>) => {
            const { submitForm, isSubmitting, isValid, values } = formikProps;
            return (
              <>
                <ModalBody>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-12 mt-2">
                        <label>{veldnamen.prodSrtID}</label>

                        <Field
                          name={nameof<IFormikValues>('prodSrtID')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            return (
                              <Combobox
                                isWisbaar
                                legeOptieTonen
                                geselecteerd={prodSrtID}
                                onSelectieChange={(x) => {
                                  form.setFieldValue(field.name, x);
                                  form.setFieldValue(nameof<IFormikValues>('typeID'), null);
                                  setProdSrtID(x);
                                }}
                                opties={productsoorten!.map((x) => {
                                  return {
                                    id: x.ProdSrtID,
                                    label: x.Naam,
                                  };
                                })}
                              />
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-2">
                        <label>{veldnamen.typeID}</label>

                        <Field
                          name={nameof<IFormikValues>('typeID')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            return (
                              <MultiCombobox<number, any>
                                sleutelExtractor={(row: IOphalenProducttypenResultElement) =>
                                  row.TypeID
                                }
                                onWaardeChange={(waarde: number | null) => {
                                  fieldProps.form.setFieldValue(field.name, waarde);
                                  setTypeID(waarde);
                                }}
                                representatieFabriek={(row: IOphalenProducttypenResultElement) =>
                                  `${row.Merknaam} - ${row.Typenaam}`
                                }
                                waarde={typeID}
                                opties={
                                  producttypen !== null
                                    ? producttypen.map((x) => {
                                        return {
                                          TypeID: x.TypeID,
                                          Typenaam: x.Typenaam,
                                          Merknaam: x.Merknaam,
                                          ProductsoortnaamKort: x.ProductsoortnaamKort,
                                          Kenmerk: x.Kenmerk,
                                        };
                                      })
                                    : []
                                }
                                kolommen={typenKolommen}
                              />
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 d-flex align-items-center">
                        <div className="col-3 mt-2">
                          <label>{veldnamen.aantalMinimaal}</label>
                          <Field
                            name={nameof<IFormikValues>('aantalMinimaal')}
                            render={(fieldProps: FieldProps<IFormikValues>) => {
                              const { field, form } = fieldProps;
                              return (
                                <NumeriekVeld
                                  waarde={field.value}
                                  onChange={(x) => {
                                    form.setFieldValue(field.name, x);
                                    values.aanvullenTot = x;
                                  }}
                                  min={1}
                                  max={250}
                                />
                              );
                            }}
                          />
                        </div>
                      </div>

                      <div className="col-12 d-flex align-items-center">
                        <div className="col-3 mt-2">
                          <label>{veldnamen.aanvullenTot}</label>
                          <Field
                            name={nameof<IFormikValues>('aanvullenTot')}
                            render={(fieldProps: FieldProps<IFormikValues>) => {
                              const { field, form } = fieldProps;
                              return (
                                <NumeriekVeld
                                  waarde={field.value}
                                  onChange={(x) => form.setFieldValue(field.name, x)}
                                  min={1}
                                  max={250}
                                />
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </ModalBody>

                <ModalFooter className="d-flex flex-row justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    style={{ width: 100 }}
                    disabled={isSubmitting || !isValid}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={onAnnuleren}
                    style={{ width: 100 }}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Dialoog>
  );
});

export default NieuwDialoog;
