import React, { useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Tabblad, { ITabblad } from '../../../components/layout/Tabblad';
import Product from './Producten';
import Beheer from './Beheer';
import Groepen from './Groepen';

export enum ETabblad {
  Producten,
  Beheer,
  Groepen,
}

const pathMap: Record<ETabblad, string> = {
  [ETabblad.Producten]: '/producten',
  [ETabblad.Beheer]: '/beheer',
  [ETabblad.Groepen]: '/groepen',
};

interface IProps extends RouteComponentProps {}

const Specificaties: React.FC<IProps> = (props) => {
  const tabblad = useMemo<ETabblad | null>(() => {
    const matchKey = Object.keys(pathMap).find((key) => {
      const path = pathMap[Number(key) as ETabblad]!;
      return props.location.pathname.endsWith(path);
    });
    if (matchKey === undefined) {
      props.history.push(`${props.match.path}${pathMap[ETabblad.Producten]}`);
      return null;
    }
    return Number(matchKey) as ETabblad;
  }, [props.location.pathname]);

  const tabbladen = useMemo<ITabblad<ETabblad>[]>(
    () => [
      {
        id: ETabblad.Producten,
        label: 'Producten',
        content: Product,
      },
      {
        id: ETabblad.Beheer,
        label: 'Beheer',
        content: Beheer,
      },
      {
        id: ETabblad.Groepen,
        label: 'Groepen',
        content: Groepen,
      },
    ],
    [],
  );

  return (
    <div className="d-flex flex-fill flex-column">
      {tabblad !== null && (
        <Tabblad<ETabblad>
          geselecteerd={tabblad}
          onSelectieChange={(tab) => {
            props.history.push(`${props.match.path}${pathMap[tab]}`);
          }}
          tabbladen={tabbladen}
        />
      )}
    </div>
  );
};

export default Specificaties;
