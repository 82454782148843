import React, { useContext, useEffect, useMemo, useState } from 'react';
import { ISectieProps } from '..';
import { IContract } from '../../../../../../../../../../../../../shared/src/api/v2/relatie/contract';
import { RootStoreContext } from '../../../../../../../../../../../stores/RootStore';
import api from '../../../../../../../../../../../api';
import { IBerekenHuurprolongatieParams } from '../../../../../../../../../../../../../shared/src/api/v2/prolongatie';
import { formatteerBedrag } from '../../../../../../../../../../../helpers';
import { IDataBijID } from '../../../../index';
import GegevensLayout from '../../../../../../../../../../../components/layout/GegevensLayout';
import { IconInformatie } from '../../../../../../../../../../../components/Icons';
import { Kleur } from '../../../../../../../../../../../bedrijfslogica/constanten';
import VerticaleScheidingslijn from '../../../../../../../../../../../components/layout/VerticaleScheidingslijn';
import { format } from 'date-fns';
import { EContractStatus } from '../../../../../Overzicht/ContractTegel/ContractStatus';
import ProductmodelInfoDialoog from '../../../../../../../../../../../components/productmodel/ProductmodelInfoDialoog';
import TariefactieInfoDialoog from '../../../../../../../../../../../components/Aanbod/Tarieven/Actie/InfoDialoog';
import HorizontaleScheidingslijn from '../../../../../../../../../../../components/layout/HorizontaleScheidingslijn';
import { IBepalenProlongatiegegevensMiddelsRelatie } from '../../../../../../../../../../../../../shared/src/api/v2/prolongatieV2';

interface IProps extends ISectieProps {}

const Details: React.FC<IProps> = (props) => {
  const { checkStore } = useContext(RootStoreContext);

  const contract = props.contract!;

  const [restbedragProlongatie, setRestbedragProlongatie] = useState<number | null>(null);
  const [prodModID, setProdModID] = useState<number | null>(null);
  const [tariefactieInfoDialoogState, setTariefactieInfoDialoogState] = useState<{
    id: number;
  } | null>(null);

  useEffect(() => {
    (async () => {
      if (contract.ProlongatieEind === null) {
        setRestbedragProlongatie(0);
        return;
      }

      const params = {
        middels: {
          type: 'relatie',
          relID: contract.RelID,
        } as IBepalenProlongatiegegevensMiddelsRelatie,
        prolongatiedatum: contract.ProlongatieEind,
      };
      const result = await api.v2.prolongatieV2.bepalenProlongatiegegevens(params);

      setRestbedragProlongatie(result.totaalProlongatiebedrag);
    })();
  }, [contract]);

  const eenmaligBedrag =
    formatteerBedrag(
      contract.EenmaligBedragActie !== null
        ? contract.EenmaligBedragActie
        : contract.EenmaligBedrag,
    ) +
    (contract.EenmaligBedragActie !== null
      ? ' (' + formatteerBedrag(contract.EenmaligBedrag) + ')'
      : '');

  // const producten = contract.producten.map((x: any) => {
  //   return (
  //     <>
  //       <div style={{ width: 300 }}>
  //         <GegevensLayout
  //           gegevens={[
  //             {
  //               label: 'Type/merk',
  //               waarde: `${x.Typenaam} ${x.Merknaam}`,
  //             },
  //             {
  //               label: 'Soort',
  //               waarde: x.Productsoortnaam,
  //             },
  //             {
  //               label: 'Ref.code',
  //               waarde: x.Referentiecode,
  //             },
  //             {
  //               label: 'leeftijd',
  //               waarde: `${x.leeftijd !== null ? maandenNaarJaren(x.leeftijd) : ''}`,
  //             },
  //             {
  //               label: 'Locatie',
  //               waarde: `${
  //                 x.locatie !== null
  //                   ? formatteerAdresV2({
  //                       bisnummer: x.locatie.Bisnummer,
  //                       straatnaam: x.locatie.Straatnaam,
  //                       huisnummer: x.locatie.Huisnummer,
  //                       postcode: x.locatie.Postcode,
  //                       plaatsnaam: x.locatie.Plaatsnaam,
  //                     })
  //                   : ''
  //               }`,
  //             },
  //           ]}
  //         />
  //       </div>
  //       <div style={{ height: '100%' }} className="ml-4 mr-4 d-flex align-items-center">
  //         <VerticaleScheidingslijn height={80} />
  //       </div>
  //     </>
  //   );
  // });

  const { klantkaartStore } = useContext(RootStoreContext);
  klantkaartStore.ophalenRelatie(contract.RelID);
  const factuurniveau = klantkaartStore.relatie!.debiteur!.FactuurNiveau;

  // Rekening bepalen (Afwijkend of Standaard?)
  let rekening: { iban: string; rekeningnaam: string; afwijkend: boolean } | null = null;
  if (contract.RelRekID !== null) {
    const relRek = props.dataBijID.relatieRekeningenBijID[contract.RelRekID];

    rekening = {
      iban: relRek.IBAN,
      rekeningnaam: relRek.Rekeningnaam,
      afwijkend: true,
    };
  } else {
    const rekeningen = klantkaartStore.relatie!.financieel.rekeningen;
    const standaardRelRekID = klantkaartStore.relatie!.financieel.StandaardRekening_RelRekID;
    const standaardRekening = rekeningen.find((x) => x.RelRekID === standaardRelRekID) ?? null;
    rekening =
      standaardRekening !== null
        ? {
            iban: standaardRekening.IBAN,
            rekeningnaam: standaardRekening.Rekeningnaam,
            afwijkend: false,
          }
        : null;
  }

  // const restbedrag: IGegeven | null =
  //   restbedragProlongatie !== null
  //     ? {
  //         label: 'Restbedrag huur',
  //         waarde: formatteerBedrag(restbedragProlongatie),
  //       }
  //     : null;

  const tariefActie = useMemo(
    () =>
      contract.TarActieID === null ? null : props.dataBijID.tariefActiesBijID[contract.TarActieID],
    [props.dataBijID, contract.TarActieID],
  );
  const contractStatus = useMemo(() => props.dataBijID.contractStatussenBijID[contract.CntStatID], [
    props.dataBijID,
    contract.CntStatID,
  ]);
  const actueleHuur = useMemo(() => {
    return props.dataBijID.actueelContractbasisHuurBijCntBasisID[props.contract.CntBasisID] ?? null;
  }, [props.dataBijID.actueelContractbasisHuurBijCntBasisID, props.contract.CntBasisID]);

  return (
    <>
      <div className="d-flex pt-2 pb-3 pl-4 pr-4">
        <div>
          <div>
            <div className="d-flex">
              <div style={{ minWidth: 135 }}>
                <GegevensLayout
                  gegevens={[
                    {
                      label: 'Maandhuur',
                      waarde: actueleHuur === null ? '?' : formatteerBedrag(actueleHuur.Maandhuur),
                    },
                    {
                      label: 'Gebr.toeslag',
                      waarde: `${formatteerBedrag(contract.gebruikerstoeslag)}`,
                    },
                    {
                      label: 'Eenmalig',
                      waarde: `${eenmaligBedrag}`,
                    },
                    null,
                    {
                      label: 'Actienr',
                      waarde:
                        contract.CntActieID !== null ? (
                          tariefActie !== null ? (
                            <>
                              {' '}
                              <span className="mr-1">{tariefActie.Nummer}</span>
                              <span>
                                {' '}
                                <a
                                  href="#"
                                  onClick={() =>
                                    setTariefactieInfoDialoogState({
                                      id: tariefActie.TarActieID,
                                    })
                                  }
                                >
                                  <IconInformatie
                                    style={{ width: 15, height: 15, fill: Kleur.Blauw }}
                                  />
                                </a>
                              </span>
                            </>
                          ) : (
                            <>Onbekend</>
                          )
                        ) : (
                          <span>Geen</span>
                        ),
                    },
                    {
                      label: 'Actiehuur',
                      waarde: `${
                        contract.CntActieID !== null && contract.MaandhuurActie !== null
                          ? `${formatteerBedrag(contract.MaandhuurActie)} ${
                              contract.Actieduur !== 0 ? ' (' + contract.Actieduur + ' mnd)' : ''
                            }`
                          : ''
                      }`,
                    },
                    {
                      label: 'Actie tot',
                      waarde: `${
                        contract.CntActieID !== null && contract.MaandhuurActie !== null
                          ? contract.datumTotActie !== null
                            ? format(new Date(contract.datumTotActie), 'dd-MM-yyyy')
                            : 'Looptijd'
                          : ''
                      }`,
                    },
                    // {
                    //   label: 'Actienr',
                    //   waarde: (
                    //     <>
                    //       {contract.basis.actie !== null &&
                    //       contract.basis.actie.TarActieID !== null
                    //         ? contract.basis.actie.tariefactie!.Nummer
                    //         : ''}
                    //       <span>
                    //         {' '}
                    //         <a
                    //           href="#"
                    //           onClick={() => setTariefactieInfoDialoogState({ id: 10 })}
                    //         >
                    //           <IconInformatie
                    //             style={{ width: 15, height: 15, fill: EKleur.Blauw }}
                    //           />
                    //         </a>
                    //       </span>
                    //     </>
                    //   ),
                    // },
                  ]}
                />
              </div>

              <div style={{ height: '100%' }} className="ml-4 mr-4 d-flex align-items-center">
                <VerticaleScheidingslijn height={150} />
              </div>

              <div style={{ minWidth: 145 }}>
                <GegevensLayout
                  gegevens={[
                    {
                      label: 'Prol. vanaf',
                      waarde:
                        contract.ProlongatieStart === null
                          ? ''
                          : format(new Date(contract.ProlongatieStart), 'dd-MM-yyyy'),
                    },
                    {
                      label: 'Geprol. tot',
                      waarde:
                        contract.GeprolongeerdTot === null
                          ? ''
                          : format(new Date(contract.GeprolongeerdTot), 'dd-MM-yyyy'),
                    },
                    {
                      label: 'Prol. eind',
                      waarde:
                        contract.ProlongatieEind === null
                          ? ''
                          : format(new Date(contract.ProlongatieEind), 'dd-MM-yyyy'),
                    },
                    null,
                    {
                      label: 'Restbedrag',
                      waarde: `${
                        restbedragProlongatie !== null
                          ? formatteerBedrag(restbedragProlongatie)
                          : '-'
                      }`,
                    },
                    {
                      label: 'Kosten VTB',
                      waarde: `${
                        contract.KostenVtb !== null ? formatteerBedrag(contract.KostenVtb) : '-'
                      }`,
                    },
                    {
                      label: 'Toepassen',
                      waarde: `${contract.KostenVtbNietToepassen ? 'Nee' : 'Ja'}`,
                    },
                  ]}
                />
              </div>

              <div style={{ height: '100%' }} className="ml-4 mr-4 d-flex align-items-center">
                <VerticaleScheidingslijn height={125} />
              </div>

              <div style={{ minWidth: 120 }}>
                <GegevensLayout
                  gegevens={[
                    {
                      label: 'Aantal gebr.',
                      waarde: `${contract.AantalGebruikers}`,
                    },
                    {
                      label: 'Max. tarief',
                      waarde: `${contract.AantalGebruikersTarief}`,
                    },
                    {
                      label: 'Max. toegest.',
                      waarde: `${contract.AantalGebruikersMax}`,
                    },
                    null,
                    {
                      label: 'Primo lev.',
                      waarde: `${
                        contract.DatumGeleverd !== null
                          ? format(new Date(contract.DatumGeleverd), 'dd-MM-yyyy')
                          : '-'
                      }`,
                    },
                    {
                      label: 'Ultimo ret.',
                      waarde: `${
                        contract.DatumRetour !== null
                          ? format(new Date(contract.DatumRetour), 'dd-MM-yyyy')
                          : '-'
                      }`,
                    },
                  ]}
                />
              </div>

              <div style={{ height: '100%' }} className="ml-4 mr-4 d-flex align-items-center">
                <VerticaleScheidingslijn height={125} />
              </div>
              <div style={{ minWidth: 150 }}>
                <GegevensLayout
                  gegevens={[
                    {
                      label: 'Min. einddatum',
                      waarde: (
                        <span>{format(new Date(contract.minimaleEinddatum), 'dd-MM-yyyy')}</span>
                      ),
                    },
                    {
                      label: 'Kosten VTB toepassen',
                      waarde: <span>{contract.KostenVtbNietToepassen ? 'Nee' : 'Ja'}</span>,
                    },
                    contract.AangebodenOvernameOp !== null
                      ? {
                          label: 'Aangeboden op',
                          waarde: (
                            <span>
                              {format(new Date(contract.AangebodenOvernameOp), 'dd-MM-yyyy HH:mm')}
                            </span>
                          ),
                        }
                      : null,

                    contractStatus.NaamEnum === EContractStatus.Beeindigd
                      ? {
                          label: 'Reden beeindiging',
                          waarde:
                            contract.CntRdEndID !== null
                              ? props.dataBijID.contractRedenBeeindigingenBijID[contract.CntRdEndID]
                                  .Naam
                              : 'Niet opgegeven',
                        }
                      : contractStatus.NaamEnum === EContractStatus.Geannuleerd
                      ? {
                          label: 'Reden annulering',
                          waarde:
                            contract.CntRdAnnID !== null
                              ? props.dataBijID.contractRedenAnnuleringenBijID[contract.CntRdAnnID]
                                  .Naam
                              : 'Niet opgegeven',
                        }
                      : contractStatus.NaamEnum === EContractStatus.Afgekeurd
                      ? {
                          label: 'Reden afgekeurd',
                          waarde:
                            contract.CntRdAfkID !== null
                              ? props.dataBijID.contractRedenAfkeuringenBijID[contract.CntRdAfkID]
                                  .Naam
                              : 'Niet opgegeven',
                        }
                      : contractStatus.NaamEnum === EContractStatus.Overgenomen
                      ? {
                          label: 'Reden Overname',
                          waarde:
                            contract.CntRdOvdID !== null
                              ? props.dataBijID.contractRedenOvernamesBijID[contract.CntRdOvdID]
                                  .Naam
                              : 'Niet opgegeven',
                        }
                      : contractStatus.NaamEnum === EContractStatus.Lopend
                      ? null
                      : null,
                  ]}
                />
              </div>
            </div>

            <div className="mb-3 mt-3">
              <HorizontaleScheidingslijn />
            </div>

            <div className="d-flex">
              <div style={{ width: 280 }}>
                {rekening !== null ? (
                  <>
                    {/* {rekening.afwijkend ? (
                        <div>Afwijkende rekening:</div>
                      ) : (
                        <div>Standaard rekening:</div>
                      )} */}

                    <GegevensLayout
                      gegevens={[
                        {
                          label: `IBAN`,
                          waarde: `${rekening !== null ? `${rekening.iban}` : ``}`,
                        },
                        {
                          label: `Naam`,
                          waarde: `${rekening !== null ? `${rekening.rekeningnaam}` : ''}`,
                        },
                        {
                          label: 'Factuurkenmerk',
                          waarde: `${
                            contract.FactKenID !== null
                              ? `${
                                  props.dataBijID.factuurKenmerkenBijID[contract.FactKenID].Kenmerk
                                }`
                              : 'Niet opgegeven'
                          }`,
                        },
                        // {
                        //   label: 'Fact.kenmerk',
                        //   waarde: `${
                        //     contract.factuurkenmerk !== null
                        //       ? `${contract.factuurkenmerk.Kenmerk}`
                        //       : 'Niet opgegeven'
                        //   }`,
                        // },
                      ]}
                    />
                  </>
                ) : (
                  <span>Er is geen rekening bekend</span>
                )}
              </div>

              <div style={{ height: '100%' }} className="ml-4 mr-4 d-flex align-items-center">
                <VerticaleScheidingslijn height={65} />
              </div>

              <div style={{ minWidth: 230 }}>
                <GegevensLayout
                  gegevens={[
                    {
                      label: 'Interne locatie',
                      waarde:
                        contract.LocIntID !== null
                          ? props.dataBijID.locatiesInternBijID[contract.LocIntID].Naam
                          : null,
                    },
                    {
                      label: 'Verdieping',
                      waarde:
                        contract.Verdieping !== null
                          ? props.dataBijID.locatieNiveausBijVerdieping[contract.Verdieping].Naam
                          : null,
                    },
                    {
                      label: 'Trekschakelaar',
                      waarde:
                        contract.AansluitenTrekschakelaar !== null &&
                        contract.AansluitenTrekschakelaar
                          ? 'Ja'
                          : 'Nee',
                    },
                  ]}
                />
              </div>

              <div style={{ height: '100%' }} className="ml-4 mr-4 d-flex align-items-center">
                <VerticaleScheidingslijn height={65} />
              </div>

              <div style={{ minWidth: 225 }}>
                <GegevensLayout
                  gegevens={[
                    {
                      label: 'Geregistreerd',
                      waarde:
                        contract.RecordToegevoegd !== null
                          ? format(new Date(contract.RecordToegevoegd), 'dd-MM-yyyy HH:mm')
                          : 'Niet vastgelegd',
                    },
                    {
                      label: 'Bevestigd',
                      waarde:
                        contract.DatumBevestigd !== null
                          ? format(new Date(contract.DatumBevestigd), 'dd-MM-yyyy HH:mm')
                          : 'Nee',
                    },
                    {
                      label: 'Gefiatteerd',
                      waarde:
                        contract.DatumGefiatteerd !== null
                          ? format(new Date(contract.DatumGefiatteerd), 'dd-MM-yyyy HH:mm')
                          : 'Nee',
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {prodModID !== null && (
        <ProductmodelInfoDialoog
          open
          onSuccess={() => setProdModID(null)}
          onAnnuleren={() => setProdModID(null)}
          prodModID={prodModID}
        />
      )}
      {tariefactieInfoDialoogState !== null && (
        <TariefactieInfoDialoog
          open
          onSuccess={() => setTariefactieInfoDialoogState(null)}
          onAnnuleren={() => setTariefactieInfoDialoogState(null)}
          id={tariefactieInfoDialoogState.id}
        />
      )}
    </>
  );
};

export default Details;
