import React from 'react';
import Avatar from '../../personalia/Avatar';
import {
  EGebruikersStatus,
  gebruikersStatusKleurMap,
  Kleur,
} from '../../../bedrijfslogica/constanten';

interface IProps {
  // Maak een keuze uit initialen of foto
  initialen?: string;
  fotoImageSrc?: string;
  status: EGebruikersStatus;
}

const GebruikersAvatar: React.FC<IProps> = (props) => {
  return (
    <div style={{ position: 'relative' }}>
      <Avatar backgroundColor={Kleur.Grijs} color="white" imageSrc={props.fotoImageSrc}>
        {props.fotoImageSrc === undefined && props.initialen}
      </Avatar>
      <div
        style={{
          position: 'absolute',
          bottom: 1,
          right: 0,
          backgroundColor: gebruikersStatusKleurMap[props.status],
          width: 9,
          height: 9,
          borderRadius: '50%',
          // border: `.5px solid ${Kleur.Wit}`,
        }}
      />
    </div>
  );
};

export default GebruikersAvatar;
