import React, { useContext, useMemo } from 'react';
import { DashboardContext, ILopendeActieregel } from '../index';
import TegelBasis from '../../../components/tegel/TegelBasis';
import { ERemoteDataState } from '../../../models/IRemoteData';
import LoadingSpinner from '../../../components/Gedeeld/LoadingSpinner';
import {
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../helpers/dxTableGrid';
import {
  Grid,
  VirtualTable,
  TableHeaderRow,
  TableColumnResizing,
} from '@devexpress/dx-react-grid-bootstrap4';
import * as _ from 'lodash';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { addDays, format } from 'date-fns';
import { IActie } from '../../../../../shared/src/api/v2/aanbod/tarieven/acties';
import nameOf from '../../../core/nameOf';

interface IProps {}

const LopendeActies: React.FC<IProps> = (props) => {
  const context = useContext(DashboardContext);
  const acties: ILopendeActieregel[] = _.flatten(context.lopendeActies.data!);

  const kolommen = useMemo<TypedColumn<ILopendeActieregel>[]>(
    () => [
      {
        name: 'Nummer',
        title: 'Nummer',
      },
      {
        name: 'Naam',
        title: 'Naam',
      },
      {
        name: '__ingangsdatum' as any,
        title: 'Ingangsdatum',
      },
      {
        name: '__einddatum' as any,
        title: 'Einddatum',
      },
      {
        name: '__abonnementen' as any,
        title: 'Abonnementen',
      },
      {
        name: '__actiecodes' as any,
        title: 'Actiecodes',
      },
      {
        name: '__isHoofdactie' as any,
        title: 'Homepage',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<ILopendeActieregel>[]>(
    () => [
      {
        columnName: 'Nummer',
        width: 80,
      },
      {
        columnName: 'Naam',
        width: 250,
      },
      {
        columnName: '__ingangsdatum' as any,
        width: 125,
      },
      {
        columnName: '__einddatum' as any,
        width: 125,
      },
      {
        columnName: '__actiecodes' as any,
        width: 175,
      },
      {
        columnName: '__abonnementen' as any,
        width: 175,
      },
      {
        columnName: '__isHoofdactie' as any,
        width: 100,
      },
    ],
    [],
  );

  return (
    true && (
      <TegelBasis titel="Lopende acties (openbaar)">
        {context.inkoopopdrachtenVerwacht.state === ERemoteDataState.Pending ? (
          <div className="d-flex flex-fill justify-content-center align-items-center">
            <LoadingSpinner />
          </div>
        ) : (
          <div>
            {acties.length !== 0 ? (
              <GridStyleWrapper rowAmount={acties.length} maxHeight={200}>
                <Grid rows={acties} columns={kolommen}>
                  <DataTypeProvider
                    for={[nameOf<ILopendeActieregel>('Naam')]}
                    formatterComponent={(formatterProps) => {
                      const rij: IActie = formatterProps.row;
                      return <span>{rij.Naam}</span>;
                    }}
                  />

                  <DataTypeProvider
                    for={['__actiecodes']}
                    formatterComponent={(formatterProps) => {
                      const rij: ILopendeActieregel = formatterProps.row;
                      if (rij.actieCodes.length === 0) {
                        return <span></span>;
                      }
                      return <span>{rij.actieCodes.map((x) => x.Actiecode).join(',')}</span>;
                    }}
                  />

                  <DataTypeProvider
                    for={['__abonnementen']}
                    formatterComponent={(formatterProps) => {
                      const rij: ILopendeActieregel = formatterProps.row;
                      if (rij.abonnementen.length === 0) {
                        return <span />;
                      }
                      const abonnementen = rij.abonnementen.map((x) => x.NaamKort).join(', ');
                      return <span>{abonnementen}</span>;
                    }}
                  />

                  <DataTypeProvider
                    for={['__ingangsdatum']}
                    formatterComponent={(formatterProps) => {
                      const rij: ILopendeActieregel = formatterProps.row;
                      const peildatum = new Date();
                      const periode = rij.actiePerioden.find((x) =>
                        new Date(x.Ingangsdatum) <= peildatum && x.Einddatum === null
                          ? true
                          : new Date(x.Einddatum!) > peildatum,
                      );
                      if (periode === undefined) {
                        return <span />;
                      }
                      return <span>{format(new Date(periode.Ingangsdatum), 'dd-MM-yyyy')}</span>;
                    }}
                  />

                  <DataTypeProvider
                    for={['__einddatum']}
                    formatterComponent={(formatterProps) => {
                      const rij: ILopendeActieregel = formatterProps.row;
                      const peildatum = new Date();
                      const periode = rij.actiePerioden.find((x) =>
                        new Date(x.Ingangsdatum) <= peildatum && x.Einddatum === null
                          ? true
                          : new Date(x.Einddatum!) > peildatum,
                      );
                      if (periode === undefined) {
                        return <span />;
                      }
                      if (periode.Einddatum === null) {
                        return <span />;
                      }
                      return <span>{format(new Date(periode.Einddatum), 'dd-MM-yyyy')}</span>;
                    }}
                  />

                  <DataTypeProvider
                    for={['__isHoofdactie']}
                    formatterComponent={(formatterProps) => {
                      const rij: ILopendeActieregel = formatterProps.row;

                      return <span>{rij.isHoofdactie ? 'Ja' : ''}</span>;
                    }}
                  />

                  <VirtualTable messages={{ noData: 'Er zijn momenteel geen lopende acties' }} />
                  <TableColumnResizing defaultColumnWidths={kolomBreedtes} />

                  <TableHeaderRow />
                </Grid>
              </GridStyleWrapper>
            ) : (
              <span>Er zijn momenteel geen lopende acties</span>
            )}
          </div>
        )}
      </TegelBasis>
    )
  );
};

export default LopendeActies;
