import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import MenuLayout from '../../../../components/MenuLayout';
import ConcurrentSelectie from './ConcurrentSelectie';
import useUrlState from '../../../../core/useUrlState';
import { LoadingSpinnerCenter } from '../../../../components/Gedeeld/LoadingSpinner';
import api from '../../../../api';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../models/IRemoteData';
import {
  IOphalenVergelijkingspuntXConcurrentiesResult,
  IVergelijkingspunt,
  IVergelijkingspuntWaardeTekstOpgeslagen,
  IVergelijkingspuntXConcurrentie,
  VergelijkingspuntWaardeOpgeslagen,
} from '../../../../../../shared/src/api/v2/concurrent/vergelijkingspunt';
import ASPTabel, { VerwijderenRijConfirmatieFn } from '../../../../components/tabel/ASPTabel';
import useBezig from '../../../../core/useBezig';
import { ASPKolom, EAspKolomBreedteType } from '../../../../components/tabel/ASPTabel/types';
import { formatteerBoolean } from '../../../../helpers';
import FormatteerBedrag from '../../../../components/MutatieBedrag';
import { IOphalenTalenResultElement } from '../../../../../../shared/src/api/v2/taal';
import { IOphalenTekstenResultElement } from '../../../../../../shared/src/api/v2/tekst';
import Skeleton from 'react-loading-skeleton';
import { RootStoreContext } from '../../../../stores/RootStore';
import { GlobaleRendererContext } from '../../../../one-off-components/GlobaleRenderer';
import { EResultType } from '../../../../stores/CheckStore';
import MuterenDialoog from './MuterenDialoog';
import {
  IOphalenVergelijkingspuntVoetnootXConcurrentiesResult,
  IVergelijkingspuntVoetnootXConcurrentie,
  IVoetnoot,
} from '../../../../../../shared/src/api/v2/concurrent/vergelijkingspunt/voetnoot';

interface IUrlState {
  concurrentID: number | null;
}

interface IData {
  vergelijkingspunten: IVergelijkingspunt[];
  talenBijTaalID: { [taalID: number]: IOphalenTalenResultElement };
  voetnotenBijID: { [voetnootID: number]: IVoetnoot };
}

const Concurrentgegevens = (props: RouteComponentProps) => {
  const defaultUrlState = useMemo<IUrlState>(
    () => ({
      concurrentID: null,
    }),
    [],
  );
  const [urlState, setUrlState, setUrlStateSync] = useUrlState<IUrlState>(props, defaultUrlState);

  const [data, setData] = useState<IRemoteData<IData>>(createPendingRemoteData());
  const bepalenData = useCallback(async () => {
    const [vergelijkingspuntenResult, talen, voetnotenResult] = await Promise.all([
      api.v2.concurrentie.vergelijkingspunt.ophalenVergelijkingspunten({
        orderSchema: {
          orders: [{ naam: 'SORT_NR', richting: 'ASC' }],
        },
      }),
      api.v2.taal.ophalen({}),
      api.v2.concurrentie.vergelijkingspunt.voetnoot.ophalenVoetnoten({}),
    ]);

    const talenBijTaalID: { [taalID: number]: IOphalenTalenResultElement } = talen.reduce(
      (acc, taal) => {
        acc[taal.TaalID] = taal;
        return acc;
      },
      {} as { [taalID: number]: IOphalenTalenResultElement },
    );

    const voetnotenBijID = voetnotenResult.voetnoten.reduce<IData['voetnotenBijID']>(
      (acc, voetnoot) => {
        acc[voetnoot.ID] = voetnoot;
        return acc;
      },
      {},
    );

    const data: IData = {
      vergelijkingspunten: vergelijkingspuntenResult.vergelijkingspunten,
      talenBijTaalID,
      voetnotenBijID,
    };
    setData(createReadyRemoteData(data));
  }, []);
  useEffect(() => {
    bepalenData();
  }, []);

  return (
    <MenuLayout
      menu={
        <div className="d-flex align-items-center">
          <div className="d-flex align-items-center">
            <span className="mr-2">Concurrent</span>
            <ConcurrentSelectie
              concurrentID={urlState.concurrentID}
              onConcurrentIDChange={(id) => setUrlStateSync('concurrentID', id)}
            />
          </div>
        </div>
      }
      body={
        urlState.concurrentID === null ? (
          <div className="d-flex flex-fill align-items-center justify-content-center">
            <span>Kies een concurrent om de gegevens weer te geven.</span>
          </div>
        ) : data.state === ERemoteDataState.Pending ? (
          <LoadingSpinnerCenter />
        ) : (
          <Inner concurrentID={urlState.concurrentID} data={data.data!} />
        )
      }
    />
  );
};

interface IInnerProps {
  concurrentID: number;
  data: IData;
}

interface IInnerData {
  vergelijkingspuntXConcurrentiesResult: IOphalenVergelijkingspuntXConcurrentiesResult;
  vergelijkingspuntVoetnootXConcurrentiesBijVergelijkingspuntXConcurrentieID: {
    [vergelijkingspuntXConcurrentieID: number]: IVergelijkingspuntVoetnootXConcurrentie[];
  };
}

interface IRow {
  vergelijkingspunt: IVergelijkingspunt;
  vergelijkingspuntXConcurrentie: IVergelijkingspuntXConcurrentie | null;
  waarde: VergelijkingspuntWaardeOpgeslagen | null;
}

type Kolom = 'naam' | 'weergeven' | 'type' | 'waarde' | 'talen' | 'voetnoten';

const Inner = (props: IInnerProps) => {
  const { checkStore } = useContext(RootStoreContext);
  const globaleRenderer = useContext(GlobaleRendererContext);
  const { isBezig, scopeBezig } = useBezig();
  const [innerData, setInnerData] = useState<IRemoteData<IInnerData>>(createPendingRemoteData());
  const bepalenInnerData = useCallback(async () => {
    await scopeBezig(async () => {
      const vergelijkingspuntXConcurrentiesResult = await api.v2.concurrentie.vergelijkingspunt.ophalenVergelijkingspuntXConcurrenties(
        {
          filterSchema: {
            filters: [
              {
                naam: 'CONCURRENT_IDS',
                data: [props.concurrentID],
              },
            ],
          },
        },
      );

      const ids = vergelijkingspuntXConcurrentiesResult.items.map((x) => x.ID);

      const vergelijkingspuntVoetnootXConcurrenties = await api.v2.concurrentie.vergelijkingspunt.voetnoot.ophalenVergelijkingspuntVoetnootXConcurrenties(
        {
          filterSchema: {
            filters: [
              {
                naam: 'VERGELIJKINGSPUNT_X_CONCURRENTIE_IDS',
                data: ids,
              },
            ],
          },
        },
      );

      const vergelijkingspuntVoetnootXConcurrentiesBijVergelijkingspuntXConcurrentieID = vergelijkingspuntVoetnootXConcurrenties.items.reduce<
        IInnerData['vergelijkingspuntVoetnootXConcurrentiesBijVergelijkingspuntXConcurrentieID']
      >((acc, curr) => {
        const arr = acc[curr.VergelijkingspuntXConcurrentieID] ?? [];
        arr.push(curr);
        acc[curr.VergelijkingspuntXConcurrentieID] = arr;
        return acc;
      }, {});

      const innerData: IInnerData = {
        vergelijkingspuntXConcurrentiesResult,
        vergelijkingspuntVoetnootXConcurrentiesBijVergelijkingspuntXConcurrentieID,
      };

      setInnerData(createReadyRemoteData(innerData));
    });
  }, [props.concurrentID]);

  useEffect(() => {
    bepalenInnerData();
  }, [bepalenInnerData]);

  const opgeslagenwaarden = useMemo<
    IRemoteData<{
      [vergelijkingspuntXConcurrentieID: number]: IVergelijkingspuntWaardeTekstOpgeslagen;
    }>
  >(() => {
    if (innerData.state === ERemoteDataState.Pending) {
      return createPendingRemoteData();
    }
    const data = innerData.data!;
    const waarden = data.vergelijkingspuntXConcurrentiesResult.items.reduce<{
      [vergelijkingspuntXConcurrentieID: number]: IVergelijkingspuntWaardeTekstOpgeslagen;
    }>((acc, curr) => {
      acc[curr.ConcurrentieVergelijkingspuntID] = JSON.parse(curr.Data);
      return acc;
    }, {});

    return createReadyRemoteData(waarden);
  }, [innerData]);

  const vergelijkingspuntXConcurrentieBijConcurrentieVergelijkingspuntID = useMemo<
    IRemoteData<{
      [concurrentieVergelijkingspuntID: number]: IVergelijkingspuntXConcurrentie;
    }>
  >(() => {
    if (innerData.state === ERemoteDataState.Pending) {
      return createPendingRemoteData();
    }
    const obj = innerData.data!.vergelijkingspuntXConcurrentiesResult.items.reduce<{
      [concurrentieVergelijkingspuntID: number]: IVergelijkingspuntXConcurrentie;
    }>((acc, curr) => {
      acc[curr.ConcurrentieVergelijkingspuntID] = curr;
      return acc;
    }, {});

    return createReadyRemoteData(obj);
  }, [innerData]);

  const [tekstenBijTekstID, setTekstenBijTekstID] = useState<
    IRemoteData<{ [tekstID: number]: IOphalenTekstenResultElement[] }>
  >(createPendingRemoteData());
  const bepalenTekstenBijTekstID = useCallback(async () => {
    if (opgeslagenwaarden.state === ERemoteDataState.Pending) {
      setTekstenBijTekstID(createPendingRemoteData());
      return;
    }
    const tekstIDs = new Set(
      Object.values(opgeslagenwaarden.data!)
        .map((waarde) => {
          if (waarde.type === 'tekst') {
            return waarde.tekstID;
          }
          return null;
        })
        .filter((tekstID) => tekstID !== null) as number[],
    );
    const tekstenResult =
      tekstIDs.size === 0
        ? null
        : await api.v2.tekst.ophalenTekstenInAlleTalen({
            tekstIDs: Array.from(tekstIDs),
          });
    const data: { [tekstID: number]: IOphalenTekstenResultElement[] } =
      tekstenResult === null
        ? {}
        : tekstenResult.teksten.reduce((acc, tekst) => {
            const arr = acc[tekst.TekstID] ?? [];
            arr.push(tekst);
            acc[tekst.TekstID] = arr;
            return acc;
          }, {} as { [tekstID: number]: IOphalenTekstenResultElement[] });

    setTekstenBijTekstID(createReadyRemoteData(data));
  }, [opgeslagenwaarden]);

  useEffect(() => {
    bepalenTekstenBijTekstID();
  }, [bepalenTekstenBijTekstID]);

  const rijen = useMemo<IRemoteData<IRow[]>>(() => {
    if (
      vergelijkingspuntXConcurrentieBijConcurrentieVergelijkingspuntID.state ===
        ERemoteDataState.Pending ||
      opgeslagenwaarden.state === ERemoteDataState.Pending
    ) {
      return createPendingRemoteData();
    }

    const rows = props.data.vergelijkingspunten.map(
      (punt): IRow => {
        const vergelijkingspuntXConcurrentie =
          vergelijkingspuntXConcurrentieBijConcurrentieVergelijkingspuntID.data![punt.ID] ?? null;
        const waarde =
          vergelijkingspuntXConcurrentie === null
            ? null
            : opgeslagenwaarden.data![
                vergelijkingspuntXConcurrentie.ConcurrentieVergelijkingspuntID
              ];

        return {
          vergelijkingspunt: punt,
          vergelijkingspuntXConcurrentie,
          waarde,
        };
      },
    );
    return createReadyRemoteData(rows);
  }, [
    props.data,
    vergelijkingspuntXConcurrentieBijConcurrentieVergelijkingspuntID,
    opgeslagenwaarden,
  ]);

  const keyExtractor = useCallback((row: IRow) => row.vergelijkingspunt.ID, []);

  const kolommen = useMemo<ASPKolom<Kolom, IRow>[]>(
    () => [
      {
        key: 'naam',
        label: 'Naam',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 400,
        renderer: (row) => row.vergelijkingspunt.Naam,
      },
      {
        key: 'weergeven',
        label: 'Weergeven',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 90,
        renderer: (row) =>
          row.vergelijkingspuntXConcurrentie === null
            ? '- -'
            : formatteerBoolean(row.vergelijkingspuntXConcurrentie.Weergeven),
      },
      {
        key: 'type',
        label: 'Type',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 100,
        renderer: (rij) => {
          if (rij.waarde === null) {
            return '- -';
          }

          switch (rij.waarde.type) {
            case 'niet_van_toepassing':
              return 'n.v.t.';
            case 'getal':
              return 'Getal';
            case 'tekst':
              return 'Tekst';
            case 'ja_nee':
              return 'Ja / Nee';
            case 'bedrag':
              return 'Bedrag';
          }
        },
      },
      {
        key: 'waarde',
        label: 'Waarde',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 250,
        renderer: (rij) => {
          if (rij.waarde === null) {
            return <span className="text-muted font-italic">Onbekend</span>;
          }

          switch (rij.waarde.type) {
            case 'niet_van_toepassing':
              return '- -';
            case 'getal':
              return rij.waarde.waarde;
            case 'tekst':
              if (tekstenBijTekstID.state === ERemoteDataState.Pending) {
                return <Skeleton />;
              }
              const teksten = tekstenBijTekstID.data![rij.waarde.tekstID];
              if (teksten === undefined) {
                return <Skeleton />;
              }

              const tekst = teksten.find((x) => x.Toepassen)!;
              return tekst.Tekst;
            case 'ja_nee':
              return formatteerBoolean(rij.waarde.waarde);
            case 'bedrag':
              return <FormatteerBedrag bedrag={rij.waarde.waarde} />;
          }
        },
      },
      {
        key: 'talen',
        label: 'Talen',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 125,
        renderer: (rij) => {
          if (rij.waarde === null) {
            return '- -';
          }

          if (tekstenBijTekstID.state === ERemoteDataState.Pending) {
            return <Skeleton />;
          }

          if (rij.waarde.type === 'tekst') {
            const teksten = tekstenBijTekstID.data![rij.waarde.tekstID];

            if (teksten === undefined) {
              return <Skeleton />;
            }

            return teksten
              .map((tekst) => {
                const taal = props.data.talenBijTaalID[tekst.TaalID];
                return taal.iso639_1.toUpperCase();
              })
              .join(', ');
          }

          return null;
        },
      },
      {
        key: 'voetnoten',
        label: 'Voetnoten',
        breedteType: EAspKolomBreedteType.Flex,
        flex: 1,
        renderer: (rij) => {
          if (rij.vergelijkingspuntXConcurrentie === null) {
            return '- -';
          }
          const items =
            innerData.data!
              .vergelijkingspuntVoetnootXConcurrentiesBijVergelijkingspuntXConcurrentieID[
              rij.vergelijkingspuntXConcurrentie!.ID
            ] ?? [];

          if (items.length === 0) {
            return '- -';
          }

          const voetnoten = items.map(
            (x) => props.data.voetnotenBijID[x.VergelijkingspuntVoetnootID],
          );

          return voetnoten.map((voetnoot) => voetnoot.Naam).join(', ');
        },
      },
    ],
    [tekstenBijTekstID, innerData, props.data],
  );

  const handleWijzigenRij = useCallback(
    async (rij: IRow) => {
      const result = await globaleRenderer.render<boolean>((renderProps) => (
        <MuterenDialoog
          concurrentID={props.concurrentID}
          vergelijkingspunt={rij.vergelijkingspunt}
          id={rij.vergelijkingspuntXConcurrentie?.ID ?? null}
          open
          onSuccess={() => renderProps.destroy(true)}
          onAnnuleren={() => renderProps.destroy(false)}
        />
      ));

      if (!result) {
        return;
      }

      await bepalenInnerData();
    },
    [props.concurrentID],
  );

  const handleVerwijderenRij = useCallback(
    async (rij: IRow) => {
      await scopeBezig(async () => {
        await api.v2.concurrentie.vergelijkingspunt.verwijderenVergelijkingspuntXConcurrentie({
          id: rij.vergelijkingspuntXConcurrentie!.ID,
        });
        await bepalenInnerData();
      });
    },
    [bepalenInnerData],
  );

  const magRijVerwijderen = useCallback(
    (rij: IRow) => rij.vergelijkingspuntXConcurrentie !== null,
    [],
  );

  const verwijderenRijConfirmatie = useCallback<VerwijderenRijConfirmatieFn<IRow>>(
    async (rij, verwijderen) => {
      const checkResult = await checkStore.bevestigen({
        inhoud: `Confirmatie voor het onbekend maken van de gegevens van vergelijkingspunt '${rij.vergelijkingspunt.Naam}' voor de gekozen concurrent.`,
      });

      if (checkResult.type === EResultType.Annuleren) {
        return;
      }

      await verwijderen();
    },
    [],
  );

  if (rijen.state === ERemoteDataState.Pending || innerData.state === ERemoteDataState.Pending) {
    return <LoadingSpinnerCenter />;
  }

  return (
    <ASPTabel
      rijen={rijen.data!}
      kolommen={kolommen}
      keyExtractor={keyExtractor}
      isBezig={isBezig}
      onWijzigenRij={handleWijzigenRij}
      onVerwijderenRij={handleVerwijderenRij}
      magRijVerwijderen={magRijVerwijderen}
      verwijderenRijConfirmatie={verwijderenRijConfirmatie}
    />
  );
};

export default Concurrentgegevens;
