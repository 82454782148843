import api from '../../../index';
import {
  IOphalenLeveringsvoorwaardenBijlagenParams,
  IOphalenLeveringsvoorwaardenBijlagenResult,
  IOphalenLeveringsvoorwaardenParams,
  IOphalenLeveringsvoorwaardenResult,
  IToevoegenLeveringsvoorwaardenParams,
  IToevoegenLeveringsvoorwaardenResult,
  IVerwijderenLeveringsvoorwaardenParams,
  IVerwijderenLeveringsvoorwaardenResult,
  IWijzigenLeveringsvoorwaardenBijlagenParams,
  IWijzigenLeveringsvoorwaardenBijlagenResult,
  IWijzigenLeveringsvoorwaardenParams,
  IWijzigenLeveringsvoorwaardenResult,
} from '../../../../../../shared/src/api/v2/relatie/leveringsvoorwaarden';
import ICheckData from '../../../../../../shared/src/models/ICheckData';

const leveringsvoorwaarden = {
  ophalenLeveringsvoorwaarden: async (
    params: IOphalenLeveringsvoorwaardenParams,
  ): Promise<IOphalenLeveringsvoorwaardenResult> => {
    return await api.post('v2/relatie/leveringsvoorwaarden/ophalen-leveringsvoorwaarden', params);
  },
  wijzigenLeveringsvoorwaarden: async (
    params: IWijzigenLeveringsvoorwaardenParams,
  ): Promise<IWijzigenLeveringsvoorwaardenResult> => {
    return await api.post('v2/relatie/leveringsvoorwaarden/wijzigen-leveringsvoorwaarden', params);
  },
  toevoegenLeveringsvoorwaarden: async (
    params: IToevoegenLeveringsvoorwaardenParams,
  ): Promise<IToevoegenLeveringsvoorwaardenResult> => {
    return await api.post('v2/relatie/leveringsvoorwaarden/toevoegen-leveringsvoorwaarden', params);
  },
  checkVerwijderenLeveringsvoorwaarden: async (
    params: IVerwijderenLeveringsvoorwaardenParams,
  ): Promise<ICheckData> => {
    return await api.post(
      'v2/relatie/leveringsvoorwaarden/check-verwijderen-leveringsvoorwaarden',
      params,
    );
  },
  verwijderenLeveringsvoorwaarden: async (
    params: IVerwijderenLeveringsvoorwaardenParams,
  ): Promise<IVerwijderenLeveringsvoorwaardenResult> => {
    return await api.post(
      'v2/relatie/leveringsvoorwaarden/verwijderen-leveringsvoorwaarden',
      params,
    );
  },
  ophalenLeveringsvoorwaardenBijlagen: async (
    params: IOphalenLeveringsvoorwaardenBijlagenParams,
  ): Promise<IOphalenLeveringsvoorwaardenBijlagenResult> => {
    return await api.post(
      'v2/relatie/leveringsvoorwaarden/ophalen-leveringsvoorwaarden-bijlagen',
      params,
    );
  },
  wijzigenLeveringsvoorwaardenBijlagen: async (
    params: IWijzigenLeveringsvoorwaardenBijlagenParams,
  ): Promise<IWijzigenLeveringsvoorwaardenBijlagenResult> => {
    return await api.post(
      'v2/relatie/leveringsvoorwaarden/wijzigen-leveringsvoorwaarden-bijlagen',
      params,
    );
  },
};

export default leveringsvoorwaarden;
