import { RefObject, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { RootStoreContext } from '../stores/RootStore';
import { format } from 'date-fns';

export interface IUseGebruikersStempelParams<T extends HTMLTextAreaElement> {
  inputRef: RefObject<T>;
  text: string;
  onTextChange: (text: string) => void;
}

export interface IPlaatsStempelOutput {
  doorvoeren: () => void;
  herstellen: () => void;
}

export interface IUseGebruikersStempelOutput {
  plaatsStempel: () => IPlaatsStempelOutput;
  wijzigenZonderStempel: () => IPlaatsStempelOutput;
  bezigMetStempelen: boolean;
}

const useGebruikersStempel = <T extends HTMLTextAreaElement>(
  params: IUseGebruikersStempelParams<T>,
): IUseGebruikersStempelOutput => {
  const { gebruikerStore } = useContext(RootStoreContext);

  const [bezigMetStempelen, setBezigMetStempelen] = useState(false);
  const moetStempelFocussenRef = useRef<boolean>(false);
  const stempelInhoudRef = useRef<string | null>(null);

  const plaatsStempel = useCallback((): IPlaatsStempelOutput => {
    if (gebruikerStore.gebruiker === null) {
      throw new Error();
    }

    const gebruiker = gebruikerStore.gebruiker.NaamKort;
    const timestamp = `${format(Date.now(), 'dd-MM-yyyy')}`;
    const stempelInhoud = `${timestamp} ${gebruiker} `;
    stempelInhoudRef.current = stempelInhoud;
    moetStempelFocussenRef.current = true;

    const oldText = params.text;

    // const heeftNogGeldigeStempel = (() => {
    //   const lijnen = oldText.split('\n');
    //   const eersteLijn = lijnen[0];
    //   if (eersteLijn === undefined) {
    //     return false;
    //   }
    //   return eersteLijn === stempelInhoud;
    // })();

    // const newText = heeftNogGeldigeStempel
    //   ? oldText
    //   : params.text.length === 0
    //     ? stempelInhoud
    //     : `${stempelInhoud}\n${params.text}`;

    const newText = params.text.length === 0 ? stempelInhoud : `${stempelInhoud}\n${params.text}`;
    params.onTextChange(newText);
    setBezigMetStempelen(true);

    return {
      doorvoeren: () => {
        setBezigMetStempelen(false);
      },
      herstellen: () => {
        params.onTextChange(oldText);
        setBezigMetStempelen(false);
      },
    };
  }, [gebruikerStore.gebruiker, params.text, params.onTextChange]);

  const wijzigenZonderStempel = useCallback((): IPlaatsStempelOutput => {
    stempelInhoudRef.current = '';
    moetStempelFocussenRef.current = true;

    const oldText = params.text;

    setBezigMetStempelen(true);

    return {
      doorvoeren: () => {
        setBezigMetStempelen(false);
      },
      herstellen: () => {
        params.onTextChange(oldText);
        setBezigMetStempelen(false);
      },
    };
  }, [gebruikerStore.gebruiker, params.text, params.onTextChange]);

  useEffect(() => {
    if (!moetStempelFocussenRef.current) {
      return;
    }
    moetStempelFocussenRef.current = false;

    const input = params.inputRef.current;
    if (input) {
      const cursorPositie = stempelInhoudRef.current!.length;
      input.setSelectionRange(cursorPositie, cursorPositie);

      input.focus();
    }
  }, [bezigMetStempelen]);

  return {
    plaatsStempel,
    wijzigenZonderStempel,
    bezigMetStempelen,
  };
};

export default useGebruikersStempel;
