import styled from 'styled-components';
import { Kleur } from '../../../bedrijfslogica/constanten';

interface IRootProps {
  bevestigd: boolean;
}

export const Root = styled.div<IRootProps>`
  //border-radius: 10px;
  //border: 1px solid #ededed;
  background-color: ${Kleur.Wit};
  // background-color: ${(props) =>
    props.bevestigd ? 'rgb(230, 230, 230)' : 'rgb(252, 252, 252)'};

  display: flex;
  min-height: 40px;

  .group-container {
    display: flex;
    //align-items: center;
    justify-content: center;
    background-color: ${Kleur.HeelLichtGrijs};
    min-width: 40px;
    padding-top: 15px;
  }
`;

export const Container = styled.div`
  padding: 10px 10px 10px 0;
  display: flex;
  flex: 1;

  .title-container {
    display: flex;
  }

  .title {
    color: #4e4e4e;
    //color: ${Kleur.Blauw};
    font-size: 13px;
    font-weight: bolder;
    flex: 1;
  }

  .body {
    margin-top: 2px;
    font-size: 12px;
    white-space: pre-wrap;
    color: ${Kleur.Grijs};
  }

  .datum {
    margin-top: 3px;
    font-size: 11px;
    color: #a9a9a9;
  }
`;
