import React from 'react';
import { Route, Redirect, RouteComponentProps } from 'react-router-dom';
import WhatsApp from './WhatsApp';
import Email from './Email';
import SMS from './SMS';
import Telefoon from './Telefoon';
import Contactverzoek from './Contactverzoek';
import Terugbelverzoek from './Terugbelverzoek';

const Communicatie = ({ match }: RouteComponentProps) => (
  <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
    <Route path={`${match.path}/whatsapp`} component={WhatsApp} />
    <Route path={`${match.path}/sms`} component={SMS} />
    <Route path={`${match.path}/email`} component={Email} />
    <Route path={`${match.path}/telefoon`} component={Telefoon} />
    <Route path={`${match.path}/contactverzoek`} component={Contactverzoek} />
    <Route path={`${match.path}/terugbelverzoek`} component={Terugbelverzoek} />
    <Route
      exact={true}
      path={match.path}
      render={() => <Redirect to={`${match.path}/whatsapp`} />}
    />
  </div>
);

export default Communicatie;
