import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { RootStoreContext } from '../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import { ERemoteDataState } from '../../../models/IRemoteData';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  IUrlState as IFacturenOverzichtUrlState,
  defaultUrlState as facturenOverzichtDefaultUrlState,
} from '../../../paginas/Leverancier/Entiteiten/entiteiten/Facturen/Overzicht';
import { genereerUrlStateQueryParam } from '../../../core/useUrlState';
import { Kleur } from '../../../bedrijfslogica/constanten';
import { OverlayTrigger } from 'react-bootstrap';
import InfoCard from './InfoCard';
import { IconOpenInNew } from '../../Icons';
import { IOphalenFacturenResultElement } from '../../../../../shared/src/api/v2/inkoopfactuur';

interface IProps extends RouteComponentProps {
  inkFactID: number;
  linkToepassen?: boolean;
}

const InkoopfactuurVisualisatie: React.FC<IProps> = observer((props) => {
  const { inkoopfactuurStore } = useContext(RootStoreContext);
  const linkToepassen = props.linkToepassen ?? true;
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (
      inkoopfactuurStore.facturen.state === ERemoteDataState.Pending ||
      inkoopfactuurStore.facturen.data![props.inkFactID] === undefined
    ) {
      inkoopfactuurStore.enqueueOphalenFacturen([props.inkFactID]);
    }
  }, [props.inkFactID]);

  const factuur = useMemo(() => {
    if (
      inkoopfactuurStore.facturen.state === ERemoteDataState.Pending ||
      inkoopfactuurStore.facturen.data![props.inkFactID] === undefined
    ) {
      return null;
    }
    return inkoopfactuurStore.facturen.data![props.inkFactID]!;
  }, [props.inkFactID, inkoopfactuurStore.facturen]);

  const [showInfoCardOverlay, setShowInfoCardOverlay] = useState(false);
  const showInfoCardOverlayTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const handleMouseEnterInfoCardOverlay = useCallback(() => {
    if (showInfoCardOverlayTimeoutRef.current !== null) {
      clearTimeout(showInfoCardOverlayTimeoutRef.current);
    }
    showInfoCardOverlayTimeoutRef.current = setTimeout(() => setShowInfoCardOverlay(true), 800);
  }, []);
  const handleMouseLeaveInfoCardOverlay = useCallback(() => {
    if (showInfoCardOverlayTimeoutRef.current !== null) {
      clearTimeout(showInfoCardOverlayTimeoutRef.current);
    }
    showInfoCardOverlayTimeoutRef.current = setTimeout(() => setShowInfoCardOverlay(false), 400);
  }, []);
  const renderInfoCardOverlay = useCallback(
    (p: any) => {
      return (
        <div
          id={`inkfact-visualisatie-overlay-${props.inkFactID}`}
          {...p}
          onMouseEnter={handleMouseEnterInfoCardOverlay}
          onMouseLeave={handleMouseLeaveInfoCardOverlay}
          style={{
            ...p.style,
            zIndex: 99999,
            border: `1px solid ${Kleur.LichtGrijs}`,
            borderRadius: 5,
          }}
        >
          <InfoCard inkFactID={props.inkFactID} />
        </div>
      );
    },
    [props.inkFactID, handleMouseEnterInfoCardOverlay, handleMouseLeaveInfoCardOverlay],
  );

  const navigatieLink = useMemo(() => {
    if (factuur === null) {
      return null;
    }
    const urlState: IFacturenOverzichtUrlState = {
      ...facturenOverzichtDefaultUrlState,
      selectie: [factuur.InkFactID],
    };
    const query = genereerUrlStateQueryParam(urlState);

    return `/leverancier/${factuur.RelID}/facturen/overzicht?state=${query}`;
  }, [factuur]);

  const Weergave = linkToepassen ? LinkWeergave : StandaardWeergave;

  if (factuur === null) {
    return <span>Laden...</span>;
  }

  return (
    <OverlayTrigger
      trigger="hover"
      placement="auto-start"
      overlay={renderInfoCardOverlay}
      show={showInfoCardOverlay}
      popperConfig={
        {
          modifiers: {
            name: 'offset',
            options: {
              offset: [0, 5],
            },
          },
        } as any
      }
    >
      <div
        className="d-flex align-items-center"
        onMouseEnter={() => {
          setIsHovered(true);
          handleMouseEnterInfoCardOverlay();
        }}
        onMouseLeave={() => {
          setIsHovered(false);
          handleMouseLeaveInfoCardOverlay();
        }}
      >
        <Weergave factuur={factuur!} link={navigatieLink!} {...props} />
        {isHovered && (
          <IconOpenInNew
            style={{
              width: 16,
              height: 16,
              marginLeft: 5,
              cursor: 'pointer',
              fill: Kleur.Grijs,
            }}
            onClick={(ev) => {
              window.open(navigatieLink!, '_blank');
            }}
          />
        )}
      </div>
    </OverlayTrigger>
  );
});

interface IWeergaveProps extends RouteComponentProps {
  factuur: IOphalenFacturenResultElement;
  link: string;
}

const LinkWeergave = (props: IWeergaveProps) => {
  return (
    <a
      href="#"
      onClick={(ev) => {
        ev.preventDefault();

        props.history.push(props.link);
      }}
    >
      {props.factuur.Factuurnummer}
    </a>
  );
};

const StandaardWeergave = (props: IWeergaveProps) => {
  return <span>{props.factuur.Factuurnummer}</span>;
};

export default withRouter(InkoopfactuurVisualisatie);
