import { Field, FieldProps } from 'formik';
import React, { useCallback, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IFormikValues } from '..';
import FormikVeldFout from '../../../../components/formulier/FormikVeldFout';
import UitklapFormulier from '../../../../components/formulier/UitklapFormulier';
import nameOf from '../../../../core/nameOf';
import useUrlState from '../../../../core/useUrlState';
import { IconKruis } from '../../../../components/Icons';
import { Kleur as EKleur } from '../../../../bedrijfslogica/constanten';
import api from '../../../../api';
import VinkVeld from '../../../../components/formulier/VinkVeld';
import NumeriekVeld from '../../../../components/formulier/NumeriekVeld';
import { toast } from 'react-hot-toast';
import Combobox from '../../../../components/formulier/Combobox';
import MeertaligTekstveld from '../../../../components/formulier/MeertaligTekstveld';

interface IUrlState {
  algemeenUitgeklapt: boolean;
  logistiekUitgeklapt: boolean;
  appsUitgeklapt: boolean;
}

const defaultUrlState: IUrlState = {
  algemeenUitgeklapt: true,
  logistiekUitgeklapt: false,
  appsUitgeklapt: false,
};

interface IProps extends RouteComponentProps {}

const Website: React.FC<IProps> = (props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);

  const [isBezigMetCacheLegen, setIsBezigMetCacheLegen] = useState(false);

  const handleCacheLegenClick = useCallback(async () => {
    setIsBezigMetCacheLegen(true);

    try {
      await api.v2.extern.leegCache({});
    } catch (err) {
      toast.error('Cache van website legen mislukt (zie logs voor meer info)');
      setIsBezigMetCacheLegen(false);
      return;
    }

    setIsBezigMetCacheLegen(false);

    toast.success('Cache van website geleegd');
  }, []);

  return (
    <div className="p-3">
      <UitklapFormulier
        label={`Algemeen`}
        uitgeklapt={urlState.algemeenUitgeklapt}
        onUitgeklaptChange={(x) => setUrlStateSync('algemeenUitgeklapt', x)}
        formulier={
          <div>
            <div className="row">
              <div className="col-12 d-flex align-items-center">
                <Field
                  name={nameOf<IFormikValues>('inloggenToegestaan')}
                  render={(fieldProps: FieldProps<IFormikValues>) => {
                    const { field, form } = fieldProps;

                    return (
                      <VinkVeld
                        aangevinkt={field.value}
                        onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                      />
                    );
                  }}
                />
                <span className="ml-2">Inloggen toegestaan</span>
              </div>
              <div className="col-12 d-flex align-items-center mt-3">
                <Field
                  name={nameOf<IFormikValues>('websiteMelding')}
                  render={(fieldProps: FieldProps<IFormikValues>) => {
                    const { field, form } = fieldProps;

                    return (
                      <VinkVeld
                        aangevinkt={field.value}
                        onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                      />
                    );
                  }}
                />
                <span className="ml-2 mr-4">Website melding weergeven:</span>
                <div style={{ width: 800 }}>
                  <Field
                    name={nameOf<IFormikValues>('WebsiteMeldingTekst')}
                    render={(fieldProps: FieldProps<IFormikValues>) => {
                      const { field, form } = fieldProps;

                      return (
                        <MeertaligTekstveld
                          waarden={field.value}
                          onChange={(x) => fieldProps.form.setFieldValue(fieldProps.field.name, x)}
                          inputComponent="multiline"
                          multilineConfig={{
                            rows: 3,
                          }}
                        />
                      );
                    }}
                  />
                </div>
              </div>
              <div className="col-12 d-flex align-items-center mt-3">
                <Field
                  name={nameOf<IFormikValues>('websiteNietWerkend')}
                  render={(fieldProps: FieldProps<IFormikValues>) => {
                    const { field, form } = fieldProps;

                    return (
                      <VinkVeld
                        aangevinkt={field.value}
                        onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                      />
                    );
                  }}
                />
                <span className="ml-2">Website is niet werkend</span>
              </div>
              <div className="col-12 d-flex align-items-center mt-3">
                <Field
                  name={nameOf<IFormikValues>('reviewsWeergeven')}
                  render={(fieldProps: FieldProps<IFormikValues>) => {
                    const { field, form } = fieldProps;

                    return (
                      <VinkVeld
                        aangevinkt={field.value}
                        onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                      />
                    );
                  }}
                />
                <span className="ml-2">Reviews weergeven</span>
              </div>
              <div className="col-12 d-flex align-items-center mt-3">
                <Field
                  name={nameOf<IFormikValues>('automatischProductenReserveren')}
                  render={(fieldProps: FieldProps<IFormikValues>) => {
                    const { field, form } = fieldProps;

                    return (
                      <VinkVeld
                        aangevinkt={field.value}
                        onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                      />
                    );
                  }}
                />
                <span className="ml-2">Automatisch producten reserveren</span>
              </div>
              {/* <div className="col-12 mt-3">
                <label>Automatisch fiatteren overnamecontracten</label>
                <Field
                  name={nameOf<IFormikValues>('automatischFiatterenOvernameContracten')}
                  render={(fieldProps: FieldProps<IFormikValues>) => {
                    const { field, form } = fieldProps;

                    const opties = [
                      { id: 0, label: 'Niet' },
                      { id: 1, label: 'Voorwaardelijk' },
                      { id: 2, label: 'Onvoorwaardelijk' },
                    ];

                    return (
                      <Combobox
                        geselecteerd={fieldProps.field.value}
                        opties={opties}
                        onSelectieChange={(id) => {
                          fieldProps.form.setFieldValue(fieldProps.field.name, id);
                        }}
                      />
                    );
                  }}
                />
              </div> */}
              <div className="col-3 mt-3">
                <label>Bezoekdagen maximaal</label>
                <Field
                  name={nameOf<IFormikValues>('bezoekdagenMaximaal')}
                  render={(fieldProps: FieldProps<IFormikValues>) => {
                    const { field, form } = fieldProps;

                    return (
                      <NumeriekVeld
                        waarde={field.value}
                        onChange={(x) => form.setFieldValue(field.name, x)}
                        min={1}
                        max={180}
                      />
                    );
                  }}
                />
              </div>
              <div className="col-3 mt-3">
                <label>Blog items maximaal aantal maanden oud</label>
                <Field
                  name={nameOf<IFormikValues>('blogMaxAantalMaandenOud')}
                  render={(fieldProps: FieldProps<IFormikValues>) => {
                    const { field, form } = fieldProps;

                    return (
                      <NumeriekVeld
                        waarde={field.value}
                        onChange={(x) => form.setFieldValue(field.name, x)}
                        min={0}
                        max={102}
                      />
                    );
                  }}
                />
              </div>
              <div className="mt-2" />
            </div>
          </div>
        }
      />

      {/* <div className="p-3"> */}

      <div className="mt-2" />

      <UitklapFormulier
        label={`Apps`}
        uitgeklapt={urlState.appsUitgeklapt}
        onUitgeklaptChange={(x) => setUrlStateSync('appsUitgeklapt', x)}
        formulier={
          <div>
            <div className="row">
              <div className="col-12 d-flex align-items-center">
                <Field
                  name={nameOf<IFormikValues>('appStoreBeschikbaar')}
                  render={(fieldProps: FieldProps<IFormikValues>) => {
                    const { field, form } = fieldProps;

                    return (
                      <VinkVeld
                        aangevinkt={field.value}
                        onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                      />
                    );
                  }}
                />
                <span className="ml-2">AppStore is beschikbaar</span>
              </div>

              <div className="col-12 d-flex align-items-center mt-3">
                <Field
                  name={nameOf<IFormikValues>('googlePlayBeschikbaar')}
                  render={(fieldProps: FieldProps<IFormikValues>) => {
                    const { field, form } = fieldProps;

                    return (
                      <VinkVeld
                        aangevinkt={field.value}
                        onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                      />
                    );
                  }}
                />
                <span className="ml-2">GooglePlay is beschikbaar</span>
              </div>

              <div className="mt-2" />
            </div>
          </div>
        }
      />

      <div className="mt-2" />

      <UitklapFormulier
        label={`Logistiek`}
        uitgeklapt={urlState.logistiekUitgeklapt}
        onUitgeklaptChange={(x) => setUrlStateSync('logistiekUitgeklapt', x)}
        formulier={<div>TODO</div>}
      />

      <button
        className="btn btn-sm btn-light d-flex align-items-center justify-content-center mt-3"
        style={{
          border: `1px solid ${EKleur.LichtGrijs}`,
        }}
        onClick={handleCacheLegenClick}
        disabled={isBezigMetCacheLegen}
      >
        <IconKruis style={{ width: 15, height: 15, fill: EKleur.Grijs }} />
        <span className="ml-2" style={{ marginTop: 1 }}>
          Cache legen van website
        </span>
      </button>
    </div>
  );
};

export default withRouter(Website);
