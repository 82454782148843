import React, { useMemo, useState, useEffect } from 'react';
import Dialoog from '../../../../dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import { ModalBody, ModalTitle, ModalFooter } from 'react-bootstrap';
import IDialoogProps from '../../../../../core/IDialoogProps';
import { Formik, FormikProps, Field, FieldProps } from 'formik';
import Combobox from '../../../../formulier/Combobox';
import VinkVeld from '../../../../formulier/VinkVeld';
import api from '../../../../../api';
import { IWijzigenRelatieGerelateerdParams } from '../../../../../../../shared/src/api/v2/persoon/persoon';
import { IOphalenContactpersonenResultElement } from '../../../../../../../shared/src/api/v2/relatie/contactpersoon';
import nameOf from '../../../../../core/nameOf';
import RelatieBetrekkingSelectie from '../../../../formulier/RelatieBetrekkingSelectie';

interface IProps extends IDialoogProps<null> {
  persID: number;
  relID: number;
}

interface IFormikValues {
  tekenbevoegd: boolean;
  interneLocatie: string | null;
  actief: boolean;
  relatieBetrekkingID: number | null;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  tekenbevoegd: 'Tekenbevoegd',
  interneLocatie: 'Interne locatie',
  actief: 'Actief',
  relatieBetrekkingID: 'Betrekking',
};

const WijzigenRelatieGerelateerdDialoog: React.FC<IProps> = (props) => {
  const { children, dialoogIndex, onAnnuleren, onSuccess, open, relID, persID } = props;

  const [
    relatieGerelateerd,
    setRelatieGerelateerd,
  ] = useState<IOphalenContactpersonenResultElement | null>(null);

  useEffect(() => {
    (async () => {
      const contactpersonenResult = await api.v2.relatie.ophalenContactpersonen({
        filterSchema: {
          filters: [
            { naam: 'REL_IDS', data: [relID] },
            { naam: 'PERS_IDS', data: [persID] },
          ],
        },
      });
      const contactpersoon = contactpersonenResult.contactpersonen[0];
      setRelatieGerelateerd(contactpersoon);
    })();
  }, [persID, relID]);

  const initieleWaarden = useMemo<IFormikValues | null>(() => {
    if (relatieGerelateerd === null) {
      return null;
    }

    return {
      tekenbevoegd: relatieGerelateerd.Tekenbevoegd,
      interneLocatie: relatieGerelateerd.InterneLocatie,
      actief: relatieGerelateerd.InactiefOp === null,
      relatieBetrekkingID: relatieGerelateerd.RelatieBetrekkingID,
    };
  }, [relatieGerelateerd]);

  const handleSubmit = async (values: IFormikValues) => {
    const { tekenbevoegd, interneLocatie, actief, relatieBetrekkingID } = values;

    const params: IWijzigenRelatieGerelateerdParams = {
      persID,
      relID,
      tekenbevoegd,
      interneLocatie,
      actief,
      relatieBetrekkingID,
    };
    const resultaat = await api.v2.persoon.wijzigenRelatieGerelateerd(params);

    onSuccess(null);
  };

  return (
    <>
      <Dialoog index={1}>
        <ModalHeader>
          <ModalTitle>Wijzigen relatiegerelateerd</ModalTitle>
        </ModalHeader>

        {initieleWaarden !== null && (
          <Formik<IFormikValues>
            onSubmit={handleSubmit}
            initialValues={initieleWaarden!}
            render={(formikProps: FormikProps<IFormikValues>) => {
              const { submitForm, isSubmitting, isValid, values } = formikProps;
              return (
                <>
                  <ModalBody>
                    <div className="row">
                      <div className="col-12">
                        <Field
                          name={nameOf<IFormikValues>('tekenbevoegd')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <label className="d-flex">
                                <VinkVeld
                                  aangevinkt={field.value}
                                  onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                />
                                <span className="ml-2">{veldnamen.tekenbevoegd}</span>
                              </label>
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-2">
                        <Field
                          name={nameOf<IFormikValues>('actief')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <label className="d-flex">
                                <VinkVeld
                                  aangevinkt={field.value}
                                  onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                />
                                <span className="ml-2">{veldnamen.actief}</span>
                              </label>
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-2">
                        <label>{veldnamen.interneLocatie}</label>
                        <Field
                          name={nameOf<IFormikValues>('interneLocatie')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <>
                                <input
                                  type="text"
                                  className="form-control"
                                  {...field}
                                  placeholder="B.v. een kameraanduiding"
                                />
                                {/* <FormikVeldFout fieldProps={fieldProps} /> */}
                              </>
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-2">
                        <label>{veldnamen.relatieBetrekkingID}</label>
                        <Field
                          name={nameOf<IFormikValues>('relatieBetrekkingID')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <RelatieBetrekkingSelectie
                                id={field.value}
                                onChange={(id) => form.setFieldValue(field.name, id)}
                                dialoogIndex={props.dialoogIndex}
                              />
                            );
                          }}
                        />
                      </div>
                    </div>
                  </ModalBody>
                  <ModalFooter className="d-flex flex-row justify-content-start">
                    <button
                      className="btn btn-primary"
                      style={{ width: 100 }}
                      disabled={isSubmitting}
                      onClick={submitForm}
                    >
                      Ok
                    </button>
                    <button
                      className="btn btn-secondary"
                      onClick={onAnnuleren}
                      style={{ width: 100 }}
                    >
                      Annuleren
                    </button>
                  </ModalFooter>
                </>
              );
            }}
          />
        )}
      </Dialoog>
    </>
  );
};

export default WijzigenRelatieGerelateerdDialoog;
