import api from '../../../index';
import nieuw from './nieuw';
import uitstaand from './uitstaand';
import afgehandeld from './afgehandeld';
import {
  IInitVersturenOpdrachtenParams,
  IInitVersturenOpdrachtenResult,
} from '../../../../../../shared/src/api/v2/inkoopopdracht';
import {
  IVersturenOpdrachtenParams,
  IVersturenOpdrachtenResult,
  IOphalenOpdrachtParams,
  IOphalenOpdrachtResult,
  IOphalenOpdrachtenParams,
  IOphalenOpdrachtenResult,
  IVerwijderenOpdrachtenParams,
  IVerwijderenOpdrachtenResult,
  IOphalenInfoVerwachteLeveringenParams,
  IOphalenInfoVerwachteLeveringenResult,
  IVersturenVooraanmeldingenParams,
  IVersturenVooraanmeldingenResult,
  IToevoegenOpdrachtenVoorstelParams,
  IToevoegenOpdrachtenVoorstelResult,
  IVersturenAnnuleringOpdrachtenParams,
  IVersturenAnnuleringOpdrachtenResult,
  IOphalenInkoopopdrachtBestandenParams,
  IOphalenInkoopopdrachtBestandenResult,
  IMuterenInkoopopdrachtBestandenParams,
  IMuterenInkoopopdrachtBestandenResult,
  IStatusverzoekInkoopleveringenAanMagazijndienstParams,
  IStatusverzoekInkoopleveringenAanMagazijndienstResult,
} from '../../../../../../shared/src/api/v2/inkoop/opdracht';
import ICheckData from '../../../../../../shared/src/models/ICheckData';

const opdracht = {
  nieuw,
  uitstaand,
  afgehandeld,
  checkVerwijderenOpdrachten: async (params: IVerwijderenOpdrachtenParams): Promise<ICheckData> => {
    return await api.post('v2/inkoop/opdracht/check-verwijderen-opdrachten', params);
  },
  verwijderenOpdrachten: async (
    params: IVerwijderenOpdrachtenParams,
  ): Promise<IVerwijderenOpdrachtenResult> => {
    return await api.post('v2/inkoop/opdracht/verwijderen-opdrachten', params);
  },
  checkVersturenOpdrachten: async (params: IVersturenOpdrachtenParams): Promise<ICheckData> => {
    return await api.post('v2/inkoop/opdracht/check-versturen-opdrachten', params);
  },
  versturenOpdrachten: async (
    params: IVersturenOpdrachtenParams,
  ): Promise<IVersturenOpdrachtenResult> => {
    return await api.post('v2/inkoop/opdracht/versturen-opdrachten', params);
  },
  initVersturenOpdrachten: async (
    params: IInitVersturenOpdrachtenParams,
  ): Promise<IInitVersturenOpdrachtenResult> => {
    return await api.post('v2/inkoop/opdracht/init-versturen-opdrachten', params);
  },
  ophalenOpdracht: async (params: IOphalenOpdrachtParams): Promise<IOphalenOpdrachtResult> => {
    return await api.post('v2/inkoop/opdracht/ophalen-opdracht', params);
  },
  ophalenOpdrachten: async (
    params: IOphalenOpdrachtenParams,
  ): Promise<IOphalenOpdrachtenResult> => {
    return await api.post('v2/inkoop/opdracht/ophalen-opdrachten', params);
  },
  ophalenInfoVerwachteLeveringen: async (
    params: IOphalenInfoVerwachteLeveringenParams,
  ): Promise<IOphalenInfoVerwachteLeveringenResult> => {
    return await api.post('v2/inkoop/opdracht/ophalen-info-verwachte-leveringenn', params);
  },
  checkVersturenVooraanmeldingen: async (
    params: IVersturenVooraanmeldingenParams,
  ): Promise<ICheckData> => {
    return await api.post('v2/inkoop/opdracht/check-versturen-vooraanmeldingen', params);
  },
  versturenVooraanmeldingen: async (
    params: IVersturenVooraanmeldingenParams,
  ): Promise<IVersturenVooraanmeldingenResult> => {
    return await api.post('v2/inkoop/opdracht/versturen-vooraanmeldingen', params);
  },
  checkToevoegenOpdrachtenVoorstel: async (
    params: IToevoegenOpdrachtenVoorstelParams,
  ): Promise<ICheckData> => {
    return await api.post('v2/inkoop/opdracht/check-toevoegen-opdrachten-voorstel', params);
  },
  toevoegenOpdrachtenVoorstel: async (
    params: IToevoegenOpdrachtenVoorstelParams,
  ): Promise<IToevoegenOpdrachtenVoorstelResult> => {
    return await api.post('v2/inkoop/opdracht/toevoegen-opdrachten-voorstel', params);
  },
  versturenAnnuleringOpdrachten: async (
    params: IVersturenAnnuleringOpdrachtenParams,
  ): Promise<IVersturenAnnuleringOpdrachtenResult> => {
    return await api.post('v2/inkoop/opdracht/versturen-annulering-opdrachten', params);
  },
  ophalenInkoopopdrachtBestanden: async (
    params: IOphalenInkoopopdrachtBestandenParams,
  ): Promise<IOphalenInkoopopdrachtBestandenResult> => {
    return await api.post('v2/inkoop/opdracht/ophalen-inkoopopdracht-bestanden', params);
  },
  muterenInkoopopdrachtBestanden: async (
    params: IMuterenInkoopopdrachtBestandenParams,
  ): Promise<IMuterenInkoopopdrachtBestandenResult> => {
    return await api.post('v2/inkoop/opdracht/muteren-inkoopopdracht-bestanden', params);
  },
  chekcStatusverzoekInkoopleveringenAanMagazijndienst: async (
    params: IStatusverzoekInkoopleveringenAanMagazijndienstParams,
  ): Promise<ICheckData> => {
    return await api.post(
      'v2/inkoop/opdracht/check-statusverzoek-inkoopleveringen-aan-magazijndienst',
      params,
    );
  },
  statusverzoekInkoopleveringenAanMagazijndienst: async (
    params: IStatusverzoekInkoopleveringenAanMagazijndienstParams,
  ): Promise<IStatusverzoekInkoopleveringenAanMagazijndienstResult> => {
    return await api.post(
      'v2/inkoop/opdracht/statusverzoek-inkoopleveringen-aan-magazijndienst',
      params,
    );
  },
};

export default opdracht;
