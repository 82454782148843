import api from '../../..';
import {
  ICheckSelectieToevoegenHerincassozaakParams,
  IOphalenHerincassozakenParams,
  IOphalenHerincassozakenResult,
  IToevoegenHerincassozaakParams,
  IToevoegenHerincassozaakResult,
  IVersturenHerincassoOpdrachtenParams,
  IVersturenHerincassoOpdrachtenResult,
  IVerwijderenHerincassozakenParams,
  IVerwijderenHerincassozakenResult,
  IWijzigenHerincassozaakParams,
  IWijzigenHerincassozaakResult,
} from '../../../../../../shared/src/api/v2/debiteur/herincassozaak';
import ICheckData from '../../../../../../shared/src/models/ICheckData';

const zaak = {
  ophalenHerincassozaken: async (
    params: IOphalenHerincassozakenParams,
  ): Promise<IOphalenHerincassozakenResult> => {
    return await api.post('/v2/debiteur/herincassozaak/ophalen-herincassozaken', params);
  },
  checkVerwijderenHerincassozaken: async (
    params: IVerwijderenHerincassozakenParams,
  ): Promise<ICheckData> => {
    return await api.post('/v2/debiteur/herincassozaak/check-verwijderen-herincassozaken', params);
  },
  verwijderenHerincassozaken: async (
    params: IVerwijderenHerincassozakenParams,
  ): Promise<IVerwijderenHerincassozakenResult> => {
    return await api.post('/v2/debiteur/herincassozaak/verwijderen-herincassozaken', params);
  },
  checkVersturenHerincassozaken: async (
    params: IVersturenHerincassoOpdrachtenParams,
  ): Promise<ICheckData> => {
    return await api.post('/v2/debiteur/herincassozaak/check-versturen-herincassozaken', params);
  },
  versturenHerincassozaken: async (
    params: IVersturenHerincassoOpdrachtenParams,
  ): Promise<IVersturenHerincassoOpdrachtenResult> => {
    return await api.post('/v2/debiteur/herincassozaak/versturen-herincassozaken', params);
  },
  checkWijzigenHerincassozaak: async (
    params: IWijzigenHerincassozaakParams,
  ): Promise<ICheckData> => {
    return await api.post('/v2/debiteur/herincassozaak/check-wijzigen-herincassozaak', params);
  },
  wijzigenHerincassozaak: async (
    params: IWijzigenHerincassozaakParams,
  ): Promise<IWijzigenHerincassozaakResult> => {
    return await api.post('/v2/debiteur/herincassozaak/wijzigen-herincassozaak', params);
  },
  checkSelectieToevoegenHerincassozaak: async (
    params: ICheckSelectieToevoegenHerincassozaakParams,
  ): Promise<ICheckData> => {
    return await api.post(
      '/v2/debiteur/herincassozaak/check-toevoegen-facturen-aan-herincassozaak',
      params,
    );
  },
  toevoegenHerincassozaak: async (
    params: IToevoegenHerincassozaakParams,
  ): Promise<IToevoegenHerincassozaakResult> => {
    return await api.post(
      '/v2/debiteur/herincassozaak/toevoegen-facturen-aan-herincassozaak',
      params,
    );
  },
};

export default zaak;
