import api from '../index';
import {
  IOphalenOrganisatiesParams,
  IOphalenOrganisatiesResult,
} from '../../../../shared/src/api/v2/organisatie/organisatie';

const organisatie = {
  ophalenOrganisaties: async (
    params: IOphalenOrganisatiesParams,
    signal?: AbortSignal,
  ): Promise<IOphalenOrganisatiesResult> => {
    return await api.post('v2/organisatie/ophalen-organisaties', params, { signal });
  },
};

export default organisatie;
