import React, { useCallback, useMemo, useRef } from 'react';
import IDialoogProps from '../../../core/IDialoogProps';
import { EDataType, IFilter, IVeldOmschrijving, Omschrijving, Vergelijking } from '../types';
import Dialoog from '../../dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { Field, FieldProps, Formik, FormikActions, FormikErrors, FormikProps } from 'formik';
import ModalBody from 'react-bootstrap/ModalBody';
import nameof from '../../../core/nameOf';
import Combobox, { IOptie } from '../../formulier/Combobox';
import VinkVeld from '../../formulier/VinkVeld';
import DatumKiezer from '../../formulier/DatumKiezer';
import VergelijkingInput from './VergelijkingInput';
import teksten from '../../../bedrijfslogica/teksten';
import FormikVeldFout from '../../formulier/FormikVeldFout';
import NumeriekVeld from '../../formulier/NumeriekVeld';

interface IFilterToevoegenResult<TId, TEntiteit extends {}> {
  filter: IFilter<TId, TEntiteit>;
}

interface IFormikValues<TEntiteit extends {}> {
  veld: keyof TEntiteit | null;
  vergelijking: Vergelijking | null;
  waarde: any | null;
}

const veldnamen: Record<keyof IFormikValues<any>, string> = {
  veld: 'Veld',
  vergelijking: 'Vergelijking',
  waarde: 'Waarde',
};

interface IProps<TId, TEntiteit> extends IDialoogProps<IFilterToevoegenResult<TId, TEntiteit>> {
  omschrijving: Omschrijving<TEntiteit>;
}

const FilterToevoegenDialoog = <TId, TEntiteit extends {}>(props: IProps<TId, TEntiteit>) => {
  const { dialoogIndex, onAnnuleren, onSuccess, omschrijving } = props;
  const formikRef = useRef<Formik<IFormikValues<TEntiteit>>>(null);

  const handleValidate = useCallback(
    async (values: IFormikValues<TEntiteit>): Promise<FormikErrors<IFormikValues<TEntiteit>>> => {
      // Twee simpele checks of veld en vergelijking zijn ingevuld
      if (values.veld === null) {
        return {
          // @ts-ignore
          veld: teksten.formulier.E_VERPLICHT_VELD({ veldnaam: veldnamen.veld }),
        };
      }
      if (values.vergelijking === null) {
        return {
          vergelijking: teksten.formulier.E_VERPLICHT_VELD({ veldnaam: veldnamen.vergelijking }),
        };
      }

      // De waarde check is iets complexer, aangezien het gebaseerd is op het datatype en de daarbijhorende inhoud
      const veldOmschrijving = omschrijving[values.veld]!;

      switch (veldOmschrijving.dataType) {
        case EDataType.Number:
          const val = Number(values.waarde);
          if (values.waarde === null || isNaN(val)) {
            return {
              waarde: teksten.formulier.E_GEEN_GETAL({ veldnaam: veldnamen.waarde }),
            };
          }
          break;
        case EDataType.String:
          if (values.waarde === null) {
            return {
              waarde: teksten.formulier.E_VERPLICHT_VELD({ veldnaam: veldnamen.waarde }),
            };
          }
          break;
        case EDataType.Date:
          if (values.waarde === null) {
            return {
              waarde: teksten.formulier.E_VERPLICHT_VELD({ veldnaam: veldnamen.waarde }),
            };
          }
          break;
        case EDataType.Boolean:
          if (values.waarde === null) {
            return {
              waarde: teksten.formulier.E_VERPLICHT_VELD({ veldnaam: veldnamen.waarde }),
            };
          }
          break;
      }
      return {};
    },
    [omschrijving],
  );

  const handleSubmit = useCallback(
    async (values: IFormikValues<TEntiteit>, actions: FormikActions<IFormikValues<TEntiteit>>) => {
      actions.setSubmitting(true);

      const errors = await handleValidate(values);
      if (Object.keys(errors).length > 0) {
        formikRef.current!.setErrors(errors);
        actions.setSubmitting(false);
        return;
      }

      // const veldOmschrijving = omschrijving[values.veld!];

      const filter: IFilter<TId, TEntiteit> = {
        veld: values.veld!,
        vergelijking: values.vergelijking!,
        waarde: values.waarde,
      };
      onSuccess({
        filter,
      });

      actions.setSubmitting(false);
    },
    [onSuccess, formikRef.current, handleValidate],
  );

  const initialValues = useMemo<IFormikValues<TEntiteit>>(() => {
    return {
      veld: Object.keys(omschrijving)[0] as keyof TEntiteit,
      waarde: null,
      vergelijking: null,
    };
  }, [omschrijving]);

  const veldOpties = useMemo<IOptie<keyof TEntiteit>[]>(() => {
    return Object.keys(omschrijving).map((key) => {
      const entKey = key as keyof TEntiteit;
      const veld: IVeldOmschrijving<TEntiteit> = omschrijving[entKey]!;
      return {
        id: entKey,
        label: veld.weergaveNaam,
      };
    });
  }, [omschrijving]);

  return (
    <Dialoog index={dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Filter toevoegen</ModalTitle>
      </ModalHeader>
      <Formik<IFormikValues<TEntiteit>>
        ref={formikRef}
        onSubmit={handleSubmit}
        initialValues={initialValues}
        render={(formikProps: FormikProps<IFormikValues<TEntiteit>>) => {
          const { submitForm, isSubmitting, isValid, values } = formikProps;
          return (
            <>
              <ModalBody>
                <div className="form-group">
                  <div className="row">
                    <div className="col-12">
                      <label>{veldnamen.veld}</label>
                      <Field
                        name={nameof<IFormikValues<any>>('veld')}
                        render={(fieldProps: FieldProps<IFormikValues<any>>) => {
                          const { field, form } = fieldProps;
                          return (
                            <>
                              <Combobox
                                geselecteerd={field.value}
                                opties={veldOpties}
                                onSelectieChange={(x) => {
                                  let waarde: any = null;
                                  if (x !== null) {
                                    const veldOmschrijving = omschrijving[x as keyof TEntiteit]!;
                                    switch (veldOmschrijving.dataType) {
                                      case EDataType.String:
                                        waarde = '';
                                        break;
                                      case EDataType.Number:
                                        waarde = 0;
                                        break;
                                      case EDataType.Date:
                                        waarde = new Date();
                                        break;
                                      case EDataType.Boolean:
                                        waarde = true;
                                        break;
                                    }
                                  }
                                  // Alle overigen waardes moeten uitgepoets worden omdat deze
                                  // mogelijk niet meer kloppen dmv wijzigen veld -> datatype
                                  form.setValues({
                                    veld: x,
                                    vergelijking: null,
                                    waarde,
                                  });
                                }}
                              />
                              <FormikVeldFout fieldProps={fieldProps} directTonen />
                            </>
                          );
                        }}
                      />
                    </div>

                    {(() => {
                      // Als er nog geen waarde is geselecteerd is het niet mogelijk om een
                      // waarde formulier veld te tonen, omdat het data type niet niet bekend is.
                      if (values.veld === null) {
                        return null;
                      }
                      const veldOmschrijving = omschrijving[values.veld]!;
                      return (
                        <>
                          <div className="col-12 mt-2">
                            <label>{veldnamen.vergelijking}</label>
                            <Field
                              name={nameof<IFormikValues<any>>('vergelijking')}
                              render={(fieldProps: FieldProps<IFormikValues<any>>) => {
                                const { field, form } = fieldProps;
                                return (
                                  <>
                                    <VergelijkingInput
                                      veldOmschrijving={veldOmschrijving}
                                      value={field.value}
                                      onChange={(x) => form.setFieldValue(field.name, x)}
                                    />
                                    <FormikVeldFout fieldProps={fieldProps} directTonen />
                                  </>
                                );
                              }}
                            />
                          </div>

                          <div className="col-12 mt-2">
                            <label>{veldnamen.waarde}</label>
                            <Field
                              name={nameof<IFormikValues<any>>('waarde')}
                              render={(fieldProps: FieldProps<IFormikValues<any>>) => {
                                const { field, form } = fieldProps;
                                switch (veldOmschrijving.dataType) {
                                  case EDataType.Number:
                                    return (
                                      <>
                                        <NumeriekVeld
                                          waarde={field.value}
                                          onChange={(x) => form.setFieldValue(field.name, x)}
                                        />
                                        <FormikVeldFout fieldProps={fieldProps} directTonen />
                                      </>
                                    );
                                  case EDataType.String:
                                    return (
                                      <>
                                        <input {...field} className="form-control" />

                                        <FormikVeldFout fieldProps={fieldProps} directTonen />
                                      </>
                                    );
                                  case EDataType.Date:
                                    return (
                                      <>
                                        <DatumKiezer
                                          waarde={field.value}
                                          onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                        />
                                        <FormikVeldFout fieldProps={fieldProps} directTonen />
                                      </>
                                    );
                                  case EDataType.Boolean:
                                    return (
                                      <>
                                        <VinkVeld
                                          aangevinkt={field.value}
                                          onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                        />
                                        <FormikVeldFout fieldProps={fieldProps} directTonen />
                                      </>
                                    );
                                }
                                throw new Error(
                                  'Kan waarde formulier niet bouwen door missende implementatie van veld data type',
                                );
                              }}
                            />
                          </div>
                        </>
                      );
                    })()}
                  </div>
                </div>
              </ModalBody>
              <ModalFooter className="d-flex flex-row justify-content-start">
                <button
                  className="btn btn-primary"
                  onClick={submitForm}
                  style={{ width: 100 }}
                  disabled={isSubmitting || !isValid}
                >
                  Ok
                </button>
                <button
                  className="btn btn-secondary"
                  onClick={onAnnuleren}
                  style={{ width: 100 }}
                  disabled={isSubmitting}
                >
                  Annuleren
                </button>
              </ModalFooter>
            </>
          );
        }}
      />
    </Dialoog>
  );
};

export default FilterToevoegenDialoog;
