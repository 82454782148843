import {
  IOphalenProducttypenInkoopParams,
  OphalenProducttypenInkoopResult,
  IOphalenLeveranciersVoorProductParams,
  IOphalenLeveranciersVoorProductResult,
  INieuwProductParams,
  INieuwProductResult,
  IOphalenInkoopgegevensParams,
  IOphalenInkoopgegevensResult,
  IWijzigenInkoopgegevensParams,
  IWijzigenInkoopgegevensResult,
  IVerwijderenInkoopgegevensParams,
  IVerwijderenInkoopgegevensResult,
  IToevoegenInkoopgegevensParams,
  IToevoegenInkoopgegevensResult,
  IWijzigenInkoopproductParams,
  IWijzigenInkoopproductResult,
  IMuterenAfbeeldingenProducttypeParams,
  IMuterenAfbeeldingenProducttypeResult,
  IOphalenAfbeeldingenVanProducttypeParams,
  IOphalenAfbeeldingenVanProducttypeResult,
  IOphalenProducttypenInkoopdienstenParams,
  IOphalenProducttypenInkoopdienstenResult,
  IVerwijderenInkoopproductenParams,
  IVerwijderenInkoopproductenResult,
} from '../../../../../shared/src/api/v2/inkoop/aanbod';
import ICheckData from '../../../../../shared/src/models/ICheckData';
import api from '../../index';
import { IMuterenAfbeeldingenMeldingResult } from '../../../../../shared/src/api/v2/service/melding';

const aanbod = {
  ophalenProductenInAanbod: async (
    params: IOphalenProducttypenInkoopParams,
  ): Promise<OphalenProducttypenInkoopResult> => {
    return await api.post('v2/inkoop/aanbod/ophalen-producttypen-inkoop', params);
  },
  ophalenProducttypenInkoopdiensten: async (
    params: IOphalenProducttypenInkoopdienstenParams,
  ): Promise<IOphalenProducttypenInkoopdienstenResult> => {
    return await api.post('v2/inkoop/aanbod/ophalen-producttypen-inkoopdiensten', params);
  },
  ophalenLeveranciersVoorProduct: async (
    params: IOphalenLeveranciersVoorProductParams,
  ): Promise<IOphalenLeveranciersVoorProductResult> => {
    return await api.post('v2/inkoop/aanbod/ophalen-leveranciers-voor-product', params);
  },
  checkNieuwProduct: async (params: INieuwProductParams): Promise<ICheckData> => {
    return await api.post('v2/inkoop/aanbod/check-nieuw-product', params);
  },
  nieuwProduct: async (params: INieuwProductParams): Promise<INieuwProductResult> => {
    return await api.post('v2/inkoop/aanbod/nieuw-product', params);
  },
  ophalenInkoopgegevens: async (
    params: IOphalenInkoopgegevensParams,
  ): Promise<IOphalenInkoopgegevensResult> => {
    return await api.post('v2/inkoop/aanbod/ophalen-inkoopgegevens', params);
  },
  wijzigenInkoopgegevens: async (
    params: IWijzigenInkoopgegevensParams,
  ): Promise<IWijzigenInkoopgegevensResult> => {
    return await api.post('v2/inkoop/aanbod/wijzigen-inkoopgegevens', params);
  },
  verwijderenInkoopgegevens: async (
    params: IVerwijderenInkoopgegevensParams,
  ): Promise<IVerwijderenInkoopgegevensResult> => {
    return await api.post('v2/inkoop/aanbod/verwijderen-inkoopgegevens', params);
  },
  toevoegenInkoopgegevens: async (
    params: IToevoegenInkoopgegevensParams,
  ): Promise<IToevoegenInkoopgegevensResult> => {
    return await api.post('v2/inkoop/aanbod/toevoegen-inkoopgegevens', params);
  },
  wijzigenInkoopproduct: async (
    params: IWijzigenInkoopproductParams,
  ): Promise<IWijzigenInkoopproductResult> => {
    return await api.post('v2/inkoop/aanbod/wijzigen-inkoopproduct', params);
  },
  muterenAfbeeldingenProducttype: async (
    params: IMuterenAfbeeldingenProducttypeParams,
  ): Promise<IMuterenAfbeeldingenProducttypeResult> => {
    return await api.post('v2/inkoop/aanbod/muteren-afbeeldingen-producttype', params);
  },
  ophalenAfbeeldingenVanProducttype: async (
    params: IOphalenAfbeeldingenVanProducttypeParams,
  ): Promise<IOphalenAfbeeldingenVanProducttypeResult> => {
    return await api.post('v2/inkoop/aanbod/ophalen-afbeeldingen-van-producttype', params);
  },
  checkVerwijderenInkoopproducten: async (
    params: IVerwijderenInkoopproductenParams,
  ): Promise<ICheckData> => {
    return await api.post('v2/inkoop/aanbod/check-verwijderen-inkoopproducten', params);
  },
  verwijderenInkoopproducten: async (
    params: IVerwijderenInkoopproductenParams,
  ): Promise<IVerwijderenInkoopproductenResult> => {
    return await api.post('v2/inkoop/aanbod/verwijderen-inkoopproducten', params);
  },
};

export default aanbod;
