import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { IconContactTelefoon, IconKruis, IconUitklappen } from '../../../../../../components/Icons';
import { Kleur } from '../../../../../../bedrijfslogica/constanten';
import styled from 'styled-components';
import { RootStoreContext } from '../../../../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../../../models/IRemoteData';
import Skeleton from 'react-loading-skeleton';
import api from '../../../../../../api';
import { Overlay } from 'react-bootstrap';
import ASPTabel from '../../../../../../components/tabel/ASPTabel';
import { ASPKolom, EAspKolomBreedteType } from '../../../../../../components/tabel/ASPTabel/types';
import { EResultType } from '../../../../../../stores/CheckStore';
import ASPGebruikerVisualisatie, {
  EModus,
} from '../../../../../../components/ASPGebruiker/ASPGebruikerVisualisatie';
import { IVoipAccount } from '../../../../../../../../shared/src/api/v2/telefonie';

const Root = styled.button`
  padding: 0.5rem 1rem 0.5rem 1.5rem;
  border: 0;
  background: 0;
  outline: 0;
  border-right: 1px solid ${Kleur.LichtGrijs};
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: ${Kleur.DoorschijnendLichtBlauw};
  }

  :disabled {
    cursor: not-allowed;
    background: rgba(0, 0, 0, 0.1);
  }
`;

enum EKolom {
  Nummer,
  Naam,
  Gebruiker,
  Actie,
}

interface IProps {}

const Toestelknop = observer((props: IProps) => {
  const { gebruikerStore, voipStore, checkStore } = useContext(RootStoreContext);
  const target = useRef<HTMLButtonElement | null>(null);
  const [isSelectieOpen, setIsSelectieOpen] = useState(false);
  const [isBezig, setIsBezig] = useState(false);

  const [voipAccounts, setVoipAccounts] = useState<IRemoteData<IVoipAccount[]>>(
    createPendingRemoteData(),
  );
  useEffect(() => {
    api.v2.telefonie.ophalenVoipAccounts({}).then((result) => {
      setVoipAccounts(createReadyRemoteData(result.voipAccounts));
    });
  }, []);

  const voipAccID = useMemo(() => {
    if (gebruikerStore.gebruiker === null || voipStore.voipAccountsVigerend === null) {
      return null;
    }

    return (
      voipStore.voipAccountsVigerend.find(
        (x) => x.AspGebrID === gebruikerStore.gebruiker!.AspGebrID,
      )?.VoipAccID ?? null
    );
  }, [voipStore.voipAccountsVigerend, gebruikerStore.gebruiker]);

  const voipAccount = useMemo<IRemoteData<IVoipAccount | null>>(() => {
    if (voipAccID === null) {
      return createReadyRemoteData(null);
    }
    if (voipAccounts.state === ERemoteDataState.Ready) {
      return createReadyRemoteData(
        voipAccounts.data!.find((acc) => acc.VoipAccID === voipAccID) ?? null,
      );
    }
    return createPendingRemoteData();
  }, [voipAccounts, voipAccID]);

  const rijen = useMemo<Record<number, IVoipAccount> | null>(() => {
    if (voipAccounts.state === ERemoteDataState.Pending) {
      return null;
    }

    return voipAccounts.data!.reduce((acc, curr, i) => {
      return {
        ...acc,
        [i]: curr,
      };
    }, {});
  }, [voipAccounts]);

  const keyExtractor = useCallback((item: IVoipAccount) => item.VoipAccID, []);

  const kolommen = useMemo<ASPKolom<EKolom, IVoipAccount>[]>(
    () => [
      {
        key: EKolom.Nummer,
        label: 'Nummer',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 80,
        renderer: (voipAccount) => voipAccount.TelefoonIntern,
      },
      {
        key: EKolom.Naam,
        label: 'Naam',
        breedteType: EAspKolomBreedteType.Flex,
        flex: 1,
        renderer: (voipAccount) => voipAccount.Naam,
      },
      {
        key: EKolom.Gebruiker,
        label: 'In gebruik door',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 150,
        renderer: (voipAccount) => {
          const voipAccountVigerend =
            voipStore.voipAccountsVigerend?.find((x) => x.VoipAccID === voipAccount.VoipAccID) ??
            null;
          if (voipAccountVigerend === null) {
            return null;
          }
          return (
            <ASPGebruikerVisualisatie
              aspGebrID={voipAccountVigerend.AspGebrID}
              modus={EModus.Volledig}
            />
          );
        },
      },
      {
        key: EKolom.Actie,
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 120,
        renderer: (voipAccount) => {
          if (gebruikerStore.gebruiker === null) {
            return <Skeleton width="100%" height="100%" />;
          }

          if (isBezig) {
            return <span />;
          }

          const voipAccountVigerend =
            voipStore.voipAccountsVigerend?.find((x) => x.VoipAccID === voipAccount.VoipAccID) ??
            null;

          if (
            voipAccountVigerend !== null &&
            voipAccountVigerend.AspGebrID === gebruikerStore.gebruiker!.AspGebrID
          ) {
            // Deze is van jezelf... kiezen is nutteloos
            return null;
          }

          return (
            <a
              href="#"
              onClick={async (ev) => {
                ev.preventDefault();

                setIsSelectieOpen(false);
                setIsBezig(true);

                // const result = await api.v2.aspGebruiker.ophalenGebruikersprofielen({
                //   filterSchema: {
                //     filters: [
                //       {
                //         naam: 'ASP_GEBR_IDS_IN',
                //         data: [gebruikerStore.gebruiker!.AspGebrID],
                //       },
                //     ],
                //   },
                // });
                //
                // await api.v2.aspGebruiker.wijzigenGebruikersprofiel({
                //   profielen: result.profielen.map((profiel) => {
                //     if (profiel.ID === gebruikerStore.aspGebruikersprofiel!.ID) {
                //       return {
                //         ...profiel,
                //         VoipAccID: voipAccount.VoipAccID,
                //       };
                //     }
                //
                //     return profiel;
                //   }),
                // });
                // await api.v2.telefoon.instellenVoipAccountVigerend({
                //   aspGebrID: gebruikerStore.gebruiker!.AspGebrID,
                //   voipAccountID: voipAccount.VoipAccID,
                // });

                // Controleren of er al een andere gebruiker dit voip account in gebruikt heeft
                const bestaandeVigerendeVoipAccountVanAndereGebruiker =
                  voipStore.voipAccountsVigerend!.find(
                    (x) =>
                      x.VoipAccID === voipAccount.VoipAccID &&
                      x.AspGebrID !== gebruikerStore.gebruiker!.AspGebrID,
                  ) ?? null;
                if (bestaandeVigerendeVoipAccountVanAndereGebruiker !== null) {
                  const bevestigenResult = await checkStore.bevestigen({
                    inhoud: (
                      <div>
                        <p>Het gekozen VOIP-account is al in gebruik door een andere gebruiker.</p>
                        <p>Wil je deze toe-eigenen aan jezelf?</p>
                      </div>
                    ),
                  });
                  if (bevestigenResult.type === EResultType.Ok) {
                    await api.v2.telefonie.instellenVoipAccountVigerend({
                      aspGebrID: gebruikerStore.gebruiker!.AspGebrID,
                      voipAccountID: voipAccount.VoipAccID,
                    });
                  }
                } else {
                  await api.v2.telefonie.instellenVoipAccountVigerend({
                    aspGebrID: gebruikerStore.gebruiker!.AspGebrID,
                    voipAccountID: voipAccount.VoipAccID,
                  });
                }

                setIsBezig(false);
              }}
              style={{ textAlign: 'right' }}
            >
              {voipAccountVigerend === null ? 'Kies' : 'Toe-eigenen'}
            </a>
          );
        },
      },
    ],
    [gebruikerStore.gebruiker?.AspGebrID, isBezig, voipStore.voipAccountsVigerend],
  );

  return (
    <>
      <Root ref={target} onClick={() => setIsSelectieOpen((open) => !open)} disabled={isBezig}>
        <IconContactTelefoon
          style={{
            fill: Kleur.Grijs,
            width: 16,
            height: 16,
          }}
        />
        {voipAccount.state === ERemoteDataState.Pending ? (
          <span
            style={{
              marginLeft: 15,
              marginRight: 10,
            }}
          >
            <Skeleton width={75} height={15} />
          </span>
        ) : voipAccount.data === null ? (
          <span
            style={{
              marginLeft: 15,
              marginRight: 10,
            }}
            className="text-muted"
          >
            Kies toestel
          </span>
        ) : (
          <span
            style={{
              marginLeft: 15,
              marginRight: 10,
            }}
          >
            {voipAccount.data!.Naam}
            <span className="text-muted ml-2">{voipAccount.data!.TelefoonIntern}</span>
          </span>
        )}
        <IconUitklappen
          style={{
            fill: Kleur.Grijs,
            width: 20,
            height: 20,
            position: 'relative',
            top: 1,
          }}
        />
      </Root>
      <Overlay
        target={target.current}
        show={isSelectieOpen}
        placement="bottom-start"
        rootClose
        onHide={() => setIsSelectieOpen(false)}
      >
        {(overlayProps) => (
          <div
            {...overlayProps}
            style={{
              ...overlayProps.style,
              zIndex: 1000,
              background: Kleur.Wit,
              border: `1px solid ${Kleur.LichtGrijs}`,
              height: 500,
              width: 550,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {rijen === null ? (
              <ASPTabel
                rijen={{}}
                kolommen={kolommen}
                totaalAantalRijen={15}
                keyExtractor={keyExtractor}
              />
            ) : (
              <ASPTabel
                rijen={rijen}
                kolommen={kolommen}
                totaalAantalRijen={Object.keys(rijen).length}
                keyExtractor={keyExtractor}
              />
            )}
            {voipAccID !== null && (
              <button
                className="btn btn-light d-flex align-items-center justify-content-center"
                onClick={async () => {
                  setIsBezig(true);
                  setIsSelectieOpen(false);
                  await api.v2.telefonie.instellenVoipAccountVigerend({
                    aspGebrID: gebruikerStore.gebruiker!.AspGebrID,
                    voipAccountID: null,
                  });
                  setIsBezig(false);
                }}
              >
                <IconKruis
                  style={{ fill: Kleur.Grijs, width: 18, height: 18, position: 'relative', top: 2 }}
                />
                <span className="ml-2">Toestel ontkoppelen</span>
              </button>
            )}
          </div>
        )}
      </Overlay>
    </>
  );
});

export default Toestelknop;
