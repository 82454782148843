import React, { useCallback, useContext, useMemo } from 'react';
import {
  ASPKolom,
  AspKolomRenderer,
  EAspKolomBreedteType,
  IASPKolomBase,
} from '../../../../tabel/ASPTabel/types';
import PersoonVisualisatie from '../../../../personalia/PersoonVisualisatie';
import UitlegTooltip from '../../../../formulier/UitlegTooltip';
import {
  IconBellen,
  IconEmail,
  IconInformatie,
  IconKlantkaart,
  IconSjablonen,
  IconSMS,
  IconSter,
  IconTekenbevoegd,
  IconWhatsapp,
} from '../../../../Icons';
import { Kleur } from '../../../../../bedrijfslogica/constanten';
import TelefoonComponent from '../../../../communicatie/TelefoonComponent';
import { setCommunicatieOverlayState } from '../../../../../one-off-components/CommunicatieOverlay';
import { ECommunicatieTabblad } from '../../../../../one-off-components/CommunicatieOverlay/MenuHandle';
import { defaultOpstellenFormulierFormikValues } from '../../../../communicatie/EmailWerkblad/OpstellenFormulier';
import {
  ECommunicatiekanaalSelectieType,
  EOpgegevenContextSelectieType,
  ESjabloonOplosserModus,
  ESMSVerstuurModusType,
  ETaalSelectieType,
  EVerstuurModusKanaal,
  IEmailVerstuurModus,
  IWhatsAppVerstuurModus,
  SjabloonOplosserVerstuurModusKanalen,
  SMSVerstuurModus,
} from '../../../../SjabloonOplosser/types';
import SjabloonOplosser, { ISjabloonOplosserOutput } from '../../../../SjabloonOplosser';
import { GlobaleRendererContext } from '../../../../../one-off-components/GlobaleRenderer';
import ActieMenuKnop, { IActie } from '../../../../ActieMenuKnop';
import api from '../../../../../api';
import { EResultType } from '../../../../../stores/CheckStore';
import { RootStoreContext } from '../../../../../stores/RootStore';
import Skeleton from 'react-loading-skeleton';
import { format } from 'date-fns';
import ASPTabel from '../../../../tabel/ASPTabel';
import TableData, { ITableDataProps } from '../../../../tabel/ASPTabel/Body/TableData';
import WijzigenTableData, {
  IWijzigenTableDataProps,
} from '../../../../tabel/ASPTabel/Body/WijzigenTableData';
import PersoonWijzigenDialoog from '../../../../dialogen/PersoonWijzigenDialoog';
import WijzigenRelatieGerelateerdDialoog from '../WijzigenRelatieGerelateerdDialoog';
import KredietwaardigheidToetsDialoog from '../KredietwaardigheidToetsDialoog';
import ToevoegenAccountDialoog from '../ToevoegenAccountDialoog';
import PersooninfoDialoog from '../../../../personalia/PersooninfoDialoog';

export interface IContactpersonenTabelRegel {
  id: number;
  persID: number;
  isPersoonPrimair: boolean;
  tekenbevoegd: boolean;
  isRelatieVoor_RelIDs: number[];
  taalID: number;
  telefoonMobiel: string | null;
  whatsAppSesID: number | null;
  email: string | null;
  achternaam: string;
  relatieBetrekkingID: number | null;
  notities: string | null;
  recordGewijzigd: Date | string | null;
  inactiefOp: Date | string | null;
}

type Kolom =
  | 'naam'
  | 'indicaties'
  | 'persoon_info'
  | 'taal'
  // | 'heeft_account'
  | 'telefoon_mobiel'
  | 'communicatie'
  | 'acties'
  | 'notities'
  | 'betrekking'
  | 'laatst_gewijzigd';

const kolomNaarLabel: Partial<Record<Kolom, string>> = {
  naam: 'Naam',
  indicaties: 'Indicaties',
  taal: 'Taal',
  // heeft_account: 'Heeft account',
  telefoon_mobiel: 'Telefoon mobiel',
  communicatie: 'Communicatie',
  acties: 'Acties',
  notities: 'Notities',
  betrekking: 'Betrekking',
  laatst_gewijzigd: 'Laatst gewijzigd',
};

interface IKolombreedte {
  breedte?: number;
  flex?: number;
  minimaleBreedte?: number;
}

const kolomNaarBreedte: Record<Kolom, IKolombreedte> = {
  naam: { breedte: 210 },
  indicaties: { breedte: 100 },
  persoon_info: { breedte: 40 },
  taal: { breedte: 35 },
  telefoon_mobiel: { breedte: 150 },
  communicatie: { breedte: 155 },
  acties: { breedte: 70 },
  betrekking: { breedte: 110 },
  notities: { flex: 1, minimaleBreedte: 200 },
  // heeft_account: { breedte: 80 },
  laatst_gewijzigd: { breedte: 145 },
};

type Acties =
  | 'wijzigen'
  | 'ontkoppelen'
  | 'account_toevoegen'
  | 'krediettoets'
  | 'wijzigen_relatie';

export interface IContactpersonenTabelTaal {
  naamKort: string;
}

export interface IContactpersonenTabelRelatieBetrekking {
  Naam: string;
}

export interface IContactpersonenTabelData {
  contactpersonen: Partial<IContactpersonenTabelRegel>[];
  talenBijID: { [taalID: number]: IContactpersonenTabelTaal } | undefined;
  relatieBetrekkingenBijID:
    | {
        [relatieBetrekkingID: number]: IContactpersonenTabelRelatieBetrekking;
      }
    | undefined;
}

type CustomActiesBuilder = (rij: Partial<IContactpersonenTabelRegel>) => IActie[];

interface IProps {
  relID: number;
  kolommen: Kolom[];
  acties: Acties[];
  customActiesBuilder?: CustomActiesBuilder;
  data: IContactpersonenTabelData;
  verversen: () => Promise<void>;
}

const indicatieIconFiller = <div style={{ minWidth: 20, minHeight: 20 }} className="mr-2" />;
const communicatieIconFiller = <div style={{ minWidth: 17, minHeight: 17 }} className="mr-3" />;

const ContactpersonenTabel = (props: IProps) => {
  const globaleRenderer = useContext(GlobaleRendererContext);
  const { checkStore } = useContext(RootStoreContext);

  const acties = useMemo(() => new Set(props.acties), [props.acties]);

  const kolomNaarRenderer = useMemo<
    Partial<Record<Kolom, AspKolomRenderer<Partial<IContactpersonenTabelRegel>>>>
  >(
    () => ({
      naam: (rij) => {
        if (rij.persID === undefined) {
          throw new Error(`PersID is verplicht voor kolom 'naam'`);
        }
        return <PersoonVisualisatie persID={rij.persID} />;
      },
      indicaties: (rij) => {
        return (
          <div className="d-flex align-items-center">
            {rij.isPersoonPrimair ? (
              <UitlegTooltip inhoud="Primair persoon">
                <IconSter style={{ width: 20, height: 20, fill: Kleur.Blauw }} className="mr-2" />
              </UitlegTooltip>
            ) : (
              indicatieIconFiller
            )}
            {rij.tekenbevoegd ? (
              <UitlegTooltip inhoud="Tekenbevoegd">
                <IconTekenbevoegd
                  style={{ width: 20, height: 20, fill: Kleur.Grijs }}
                  className="mr-2"
                />
              </UitlegTooltip>
            ) : (
              indicatieIconFiller
            )}

            {rij.isRelatieVoor_RelIDs !== undefined && rij.isRelatieVoor_RelIDs.length !== 0 ? (
              <UitlegTooltip inhoud="Contactpersoon is tevens relatie">
                <IconKlantkaart style={{ width: 20, height: 20, fill: Kleur.Grijs }} />
              </UitlegTooltip>
            ) : (
              indicatieIconFiller
            )}
          </div>
        );
      },
      persoon_info: (rij) => {
        return (
          <a
            href="#"
            onClick={async (ev) => {
              if (rij.persID === undefined) {
                throw new Error(`PersID is verplicht voor kolom 'persoon_info'`);
              }
              ev.preventDefault();

              await globaleRenderer.render(({ destroy }) => (
                <PersooninfoDialoog
                  open
                  persID={rij.persID!}
                  onSuccess={() => {
                    destroy();
                  }}
                  onAnnuleren={() => destroy()}
                />
              ));
            }}
          >
            <IconInformatie style={{ width: 15, height: 15, fill: Kleur.Blauw }} />
          </a>
        );
      },
      taal: (rij) => {
        if (rij.taalID === undefined) {
          throw new Error(`TaalID is verplicht voor kolom 'taal'`);
        }
        if (props.data.talenBijID === undefined) {
          throw new Error(`Talen bij ID is verplicht voor kolom 'taal'`);
        }
        const taal = props.data.talenBijID[rij.taalID];
        return taal.naamKort;
      },
      telefoon_mobiel: (rij) => {
        if (rij.telefoonMobiel === null || rij.telefoonMobiel === undefined) {
          return;
        }

        return (
          <TelefoonComponent
            telefoonNummer={rij.telefoonMobiel}
            options={{
              icoonWeergeven: false,
            }}
          />
        );
      },
      communicatie: (rij) => {
        return (
          <div className="d-flex align-items-center">
            {rij.telefoonMobiel !== null ? (
              <button
                onClick={() => {
                  setCommunicatieOverlayState!((state) => ({
                    ...state,
                    geselecteerdTabblad: ECommunicatieTabblad.Telefoon,
                    telefoonContext: {
                      ...state.telefoonContext,
                      telefoonnummer: rij.telefoonMobiel!,
                    },
                  }));
                }}
                className="mr-3"
                style={{
                  border: 0,
                  background: 0,
                  padding: 0,
                  margin: 0,
                  outline: 0,
                }}
              >
                <IconBellen
                  style={{
                    width: 17,
                    height: 17,
                    fill: Kleur.Grijs,
                  }}
                />
              </button>
            ) : (
              communicatieIconFiller
            )}
            {rij.whatsAppSesID !== null ? (
              <button
                className="mr-3"
                onClick={() => {
                  setCommunicatieOverlayState!((state) => ({
                    ...state,
                    geselecteerdTabblad: ECommunicatieTabblad.Whatsapp,
                    whatsappContext: {
                      ...state.whatsappContext,
                      chatSessieID: rij.whatsAppSesID!,
                    },
                  }));
                }}
                style={{
                  border: 0,
                  background: 0,
                  padding: 0,
                  margin: 0,
                  outline: 0,
                }}
              >
                <IconWhatsapp
                  style={{
                    width: 17,
                    height: 17,
                    fill: Kleur.Grijs,
                  }}
                />
              </button>
            ) : (
              communicatieIconFiller
            )}
            {rij.telefoonMobiel !== null ? (
              <button
                className="mr-3"
                onClick={() => {
                  setCommunicatieOverlayState!((state) => ({
                    ...state,
                    geselecteerdTabblad: ECommunicatieTabblad.Sms,
                    smsContext: {
                      ...state.smsContext,
                      telefoonnummer: rij.telefoonMobiel!,
                      persID: rij.persID,
                    },
                  }));
                }}
                style={{
                  border: 0,
                  background: 0,
                  padding: 0,
                  margin: 0,
                  outline: 0,
                }}
              >
                <IconSMS
                  style={{
                    width: 17,
                    height: 17,
                    fill: Kleur.Grijs,
                  }}
                />
              </button>
            ) : (
              communicatieIconFiller
            )}
            {rij.email !== null ? (
              <button
                className="mr-3"
                onClick={() => {
                  setCommunicatieOverlayState!((state) => ({
                    ...state,
                    geselecteerdTabblad: ECommunicatieTabblad.Email,
                    emailContext: {
                      ...state.emailContext,
                      formulier: {
                        ...defaultOpstellenFormulierFormikValues,
                        recipients: [
                          {
                            emailAdres: rij.email!,
                            persID: rij.persID,
                            // relID: props.relID,
                            orgID: null,
                          },
                        ],
                      },
                    },
                  }));
                }}
                style={{
                  border: 0,
                  background: 0,
                  padding: 0,
                  margin: 0,
                  outline: 0,
                }}
              >
                <IconEmail
                  style={{
                    width: 17,
                    height: 17,
                    fill: Kleur.Grijs,
                  }}
                />
              </button>
            ) : (
              communicatieIconFiller
            )}
            {rij.whatsAppSesID !== null || rij.telefoonMobiel !== null || rij.email !== null ? (
              <button
                onClick={async () => {
                  const whatsappVerstuurModusKanaal: IWhatsAppVerstuurModus | null =
                    rij.whatsAppSesID === null || rij.whatsAppSesID === undefined
                      ? null
                      : {
                          whatsAppSesID: rij.whatsAppSesID,
                        };
                  const smsVerstuurModusKanaal: SMSVerstuurModus | null =
                    rij.telefoonMobiel === null ||
                    rij.telefoonMobiel === undefined ||
                    rij.persID === undefined
                      ? null
                      : {
                          type: ESMSVerstuurModusType.Telefoonnummer,
                          persID: rij.persID,
                          telefoonnummer: rij.telefoonMobiel,
                        };
                  const emailVerstuurModusKanaal: IEmailVerstuurModus | null =
                    rij.email === null || rij.email === undefined || rij.persID === undefined
                      ? null
                      : ({
                          aan: [
                            {
                              persID: rij.persID,
                              emailAdres: rij.email,
                            },
                          ],
                          cc: [],
                          bcc: [],
                          bijlagen: [],
                          contextRelIDs: [props.relID],
                        } as IEmailVerstuurModus);

                  const kanalen: SjabloonOplosserVerstuurModusKanalen = {
                    [EVerstuurModusKanaal.Email]: emailVerstuurModusKanaal ?? undefined,
                    [EVerstuurModusKanaal.SMS]: smsVerstuurModusKanaal ?? undefined,
                    [EVerstuurModusKanaal.WhatsApp]: whatsappVerstuurModusKanaal ?? undefined,
                  };

                  const result = await globaleRenderer.render<ISjabloonOplosserOutput>(
                    (renderProps) => (
                      <SjabloonOplosser
                        taalSelectie={{
                          type: ETaalSelectieType.AutomatischBepalenViaContexten,
                          isMuteerbaar: false,
                        }}
                        communicatiekanaalSelectie={{
                          type: ECommunicatiekanaalSelectieType.Opgegeven,
                          kanaalID: null,
                          isMuteerbaar: true,
                          magVoorvullen: true,
                        }}
                        modus={{
                          type: ESjabloonOplosserModus.Verstuur,
                          kanalen,
                        }}
                        open
                        onSuccess={(result) => renderProps.destroy(result)}
                        onAnnuleren={() => renderProps.destroy()}
                        defaultOpgegevenContexten={[
                          {
                            selectie: {
                              type: EOpgegevenContextSelectieType.NaamEnum,
                              naamEnum: 'PERSOON',
                            },
                            data: { persID: rij.persID },
                          },
                          {
                            selectie: {
                              type: EOpgegevenContextSelectieType.NaamEnum,
                              naamEnum: 'RELATIE',
                            },
                            data: { relID: props.relID },
                          },
                        ]}
                      />
                    ),
                  );
                }}
                style={{
                  border: 0,
                  background: 0,
                  padding: 0,
                  margin: 0,
                  outline: 0,
                }}
                title="Vanuit sjabloon initiëren"
              >
                <IconSjablonen
                  style={{
                    width: 17,
                    height: 17,
                    fill: Kleur.Grijs,
                  }}
                />
              </button>
            ) : (
              communicatieIconFiller
            )}
          </div>
        );
      },
      acties: (rij) => {
        const customActies = props.customActiesBuilder?.(rij) ?? [];

        return (
          <ActieMenuKnop
            acties={
              [
                acties.has('ontkoppelen') &&
                rij.persID !== undefined &&
                rij.achternaam !== undefined
                  ? {
                      text: 'Ontkoppelen',
                      onClick: async () => {
                        const params = { relID: props.relID, persID: rij.persID! };
                        const checkData = await api.v2.relatie.checkOntkoppelenContactpersoon(
                          params,
                        );
                        const controleResultaat = await checkStore.controleren({
                          checkData,
                        });
                        if (controleResultaat.type === EResultType.Annuleren) {
                          // setIsSubmitting(false);
                          return;
                        }
                        const resultaat = await checkStore.bevestigen({
                          inhoud: `Bevestig ontkoppelen ${rij.achternaam}?`,
                        });
                        if (resultaat.type === EResultType.Annuleren) {
                          return;
                        }
                        await api.v2.relatie.ontkoppelenContactpersoon(params);
                        await props.verversen();
                      },
                    }
                  : null,
                acties.has('account_toevoegen') && rij.persID !== undefined
                  ? {
                      text: 'Account toevoegen',
                      onClick: async () => {
                        await globaleRenderer.render(({ destroy }) => (
                          <ToevoegenAccountDialoog
                            open
                            relID={props.relID}
                            persID={rij.persID!}
                            onSuccess={() => destroy()}
                            onAnnuleren={() => destroy()}
                          />
                        ));
                      },
                    }
                  : null,
                acties.has('krediettoets')
                  ? {
                      text: 'Kredietwaardigheid toetsen',
                      onClick: async () => {
                        await globaleRenderer.render(({ destroy }) => (
                          <KredietwaardigheidToetsDialoog
                            open
                            onSuccess={() => {
                              destroy();
                            }}
                            onAnnuleren={() => destroy()}
                            persID={rij.persID}
                          />
                        ));
                      },
                    }
                  : null,
                acties.has('wijzigen_relatie') && rij.persID !== undefined
                  ? {
                      text: 'Wijzigen Relatie gerelateerd',
                      onClick: async () => {
                        await globaleRenderer.render(({ destroy }) => (
                          <WijzigenRelatieGerelateerdDialoog
                            open
                            relID={props.relID}
                            persID={rij.persID!}
                            onSuccess={async () => {
                              await props.verversen();
                              destroy();
                            }}
                            onAnnuleren={() => destroy()}
                          />
                        ));
                      },
                    }
                  : null,
                ...customActies,
              ].filter((x) => x !== null) as IActie[]
            }
          />
        );
      },
      betrekking: (rij) => {
        if (props.data.relatieBetrekkingenBijID === undefined) {
          throw new Error(`Relatie betrekkingen bij ID is verplicht voor kolom 'betrekking'`);
        }
        if (rij.relatieBetrekkingID === null || rij.relatieBetrekkingID === undefined) {
          return <span>-&nbsp;-</span>;
        }
        const relBet = props.data.relatieBetrekkingenBijID[rij.relatieBetrekkingID];
        if (relBet === undefined) {
          return <Skeleton />;
        }
        return <span>{relBet.Naam}</span>;
      },
      notities: (rij) => {
        return (
          <span
            style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
            title={rij.notities ?? '- -'}
          >
            {rij.notities ?? '- -'}
          </span>
        );
      },
      laatst_gewijzigd: (rij) =>
        rij.recordGewijzigd === null || rij.recordGewijzigd === undefined
          ? null
          : format(new Date(rij.recordGewijzigd), 'dd-MM-yyyy HH:mm'),
    }),
    [props.data.talenBijID],
  );

  const kolommen = useMemo<ASPKolom<Kolom, Partial<IContactpersonenTabelRegel>>[]>(() => {
    return props.kolommen.map((kolom) => {
      const label = kolomNaarLabel[kolom];
      const breedte = kolomNaarBreedte[kolom];
      const renderer = kolomNaarRenderer[kolom];

      const base: IASPKolomBase<Kolom, Partial<IContactpersonenTabelRegel>> = {
        key: kolom,
        label,
        renderer,
      };

      if (breedte.flex !== undefined) {
        const k: ASPKolom<Kolom, Partial<IContactpersonenTabelRegel>> = {
          ...base,
          breedteType: EAspKolomBreedteType.Flex,
          flex: breedte.flex,
          minimaleVasteBreedte: breedte.minimaleBreedte,
        };
        return k;
      }

      if (breedte.breedte !== undefined) {
        const k: ASPKolom<Kolom, Partial<IContactpersonenTabelRegel>> = {
          ...base,
          breedteType: EAspKolomBreedteType.Vast,
          vasteBreedte: breedte.breedte,
        };
        return k;
      }

      throw new Error(`Onbekende breedte voor kolom ${kolom}`);
    });
  }, [props.kolommen, kolomNaarRenderer]);

  const keyExtractor = useCallback((row: Partial<IContactpersonenTabelRegel>) => {
    if (row.persID === undefined) {
      throw new Error(`PersID is verplicht bij key extractie`);
    }
    return row.persID;
  }, []);

  const handleWijzigenRij = useCallback(
    async (rij: Partial<IContactpersonenTabelRegel>, idx: number) => {
      if (rij.persID === undefined) {
        throw new Error(`PersID is verplicht bij wijzigen rij`);
      }

      await globaleRenderer.render(({ destroy }) => (
        <PersoonWijzigenDialoog
          open
          onSuccess={async () => {
            await api.v2.persoon.bijwerkenLijstRecent({
              persID: rij.persID!,
            });
            await props.verversen();
            destroy();
          }}
          onAnnuleren={() => destroy()}
          persID={rij.persID!}
        />
      ));
    },
    [],
  );

  return (
    <ASPTabel
      rijen={props.data.contactpersonen}
      kolommen={kolommen}
      keyExtractor={keyExtractor}
      onWijzigenRij={acties.has('wijzigen') ? handleWijzigenRij : undefined}
      tdComponent={TdComponent}
      wijzigenTdComponent={WijzigenTdComponent}
    />
  );
};

const TdComponent = (props: ITableDataProps<Kolom, Partial<IContactpersonenTabelRegel>>) => {
  const persoon = props.row!;
  return (
    <TableData
      {...props}
      // style={{ backgroundColor: persoon.InactiefOp !== null ? 'rgb(238,238,238)' : undefined }}
      style={{ backgroundColor: persoon.inactiefOp !== null ? 'rgb(241, 218, 218)' : undefined }}
    />
  );
};

const WijzigenTdComponent = (
  props: IWijzigenTableDataProps<Partial<IContactpersonenTabelRegel>>,
) => {
  const persoon = props.row!;
  return (
    <WijzigenTableData
      {...props}
      style={{ backgroundColor: persoon.inactiefOp !== null ? 'rgb(238,238,238)' : undefined }}
    />
  );
};

export default ContactpersonenTabel;
