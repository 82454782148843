import React from 'react';
import { Redirect } from 'react-router';
import { Route, RouteComponentProps } from 'react-router-dom';
import Afbeeldingen from './Afbeeldingen';
import Webteksten from './Webteksten';
import Webpagina from './Webpagina';
import WebReroutes from './WebReroutes';
import Faq from './Faq';
import Blog from './Blog';
import Themas from './Themas';
import Enquete from './Enquete';
import Landingpaginas from './Landingspaginas';

interface IProps extends RouteComponentProps<{}> {}

const Transport: React.FC<IProps> = (props) => {
  const { match } = props;
  return (
    <>
      <Route path={`${match.path}/webteksten`} component={Webteksten} />
      <Route path={`${match.path}/website-afbeeldingen`} component={Afbeeldingen} />
      <Route path={`${match.path}/webpagina`} component={Webpagina} />
      <Route path={`${match.path}/webreroutes`} component={WebReroutes} />
      <Route path={`${match.path}/faq`} component={Faq} />
      <Route path={`${match.path}/blog`} component={Blog} />
      <Route path={`${match.path}/themas`} component={Themas} />
      <Route path={`${match.path}/enquete`} component={Enquete} />
      <Route path={`${match.path}/landingspaginas`} component={Landingpaginas} />

      <Route exact path={match.path} render={() => <Redirect to={`${match.path}/webteksten`} />} />
    </>
  );
};

export default Transport;
