import React, { useContext, useEffect, useMemo } from 'react';
import { RootStoreContext } from '../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import { ERemoteDataState } from '../../../models/IRemoteData';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { genereerUrlStateQueryParam } from '../../../core/useUrlState';
import {
  IUrlState as IProducttypenUrlState,
  defaultUrlState as producttypenDefaultUrlState,
} from '../../../paginas/Inkoop/Aanbod/Producten';

export enum EWeergave {
  MerknaamEnTypenaam = 1,
  Merknaam = 2,
  Typenaam = 3,
}

interface IProps extends RouteComponentProps {
  typeID: number;
  weergave?: EWeergave;
}

const ProducttypeVisualisatie: React.FC<IProps> = observer((props) => {
  const { producttypeStore } = useContext(RootStoreContext);

  useEffect(() => {
    if (
      producttypeStore.producttypen.state === ERemoteDataState.Pending ||
      producttypeStore.producttypen.data![props.typeID] === undefined
    ) {
      producttypeStore.enqueueOphalenProducttypen([props.typeID]);
    }
  }, [props.typeID]);

  const producttype = useMemo(() => {
    if (
      producttypeStore.producttypen.state === ERemoteDataState.Pending ||
      producttypeStore.producttypen.data![props.typeID] === undefined
    ) {
      return null;
    }
    return producttypeStore.producttypen.data![props.typeID]!;
  }, [props.typeID, producttypeStore.producttypen]);

  if (producttype === null) {
    return <span>Laden...</span>;
  }

  return (
    <a
      href="#"
      className="d-flex align-items-center"
      onClick={(ev) => {
        ev.preventDefault();

        const urlState: IProducttypenUrlState = {
          ...producttypenDefaultUrlState,
          selectie: [producttype.TypeID],
          // filterData: [],
        };
        const query = genereerUrlStateQueryParam(urlState);

        props.history.push(`/inkoop/aanbod/producten?state=${query}`);
      }}
    >
      {props.weergave === undefined || props.weergave === EWeergave.MerknaamEnTypenaam ? (
        <span>
          {producttype.Merknaam} {producttype.Typenaam}
        </span>
      ) : props.weergave === EWeergave.Merknaam ? (
        <span>{producttype.Merknaam}</span>
      ) : (
        <span>{producttype.Typenaam}</span>
      )}
    </a>
  );
});

export default withRouter(ProducttypeVisualisatie);
