import React, { useCallback, useContext, useMemo } from 'react';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import { IconKruis } from '../../../Icons';
import styled from 'styled-components';
import { ISjabloonContext } from '../../../../../../shared/src/api/v2/Sjabloon/sjabloon';
import { RootStoreContext } from '../../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import { EResultType } from '../../../../stores/CheckStore';
import { ESjablooncontextNaamEnum, IOpgegevenContext } from '../../types';
import PersoonInhoud from './inhouden/PersoonInhoud';
import ContractInhoud from './inhouden/ContractInhoud';
import RelatieInhoud from './inhouden/RelatieInhoud';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${Kleur.LichtGrijs};
  border-radius: 5px;
  // box-shadow card
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12), 0 1px 1px rgba(0, 0, 0, 0.15);
  background-color: ${Kleur.Wit};
`;

const Kop = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${Kleur.LichtGrijs};
  padding: 4px 7px 4px 12px;
`;

const ContextNaam = styled.span`
  flex: 1;
`;

const ContextAlias = styled.span`
  font-size: 13px;
  margin-right: 5px;
  color: ${Kleur.Grijs};
  font-weight: bold;
`;

const VerwijderenKnop = styled.button`
  border: 0;
  background: transparent;
  outline: 0;
  padding: 0;
`;

const Inhoud = styled.div`
  padding: 6px 12px;
`;

interface IProps {
  opgegevenContext: IOpgegevenContext;
  context: ISjabloonContext;
  onVerwijderen: () => void;
  magOpgegevenContextenMuteren: boolean;
  isBezig: boolean;
}

const ContextWeergave = observer((props: IProps) => {
  const { checkStore } = useContext(RootStoreContext);
  const handleVerwijderenKnopClick = useCallback(async () => {
    const result = await checkStore.bevestigen({
      inhoud: `Bevestig verwijderen context '${props.context.Naam}'`,
    });
    if (result.type === EResultType.Annuleren) {
      return;
    }
    props.onVerwijderen();
  }, [props.context, props.onVerwijderen]);

  const InhoudComp = useMemo<React.ComponentType<{ data: any }> | null>(() => {
    switch (props.context.NaamEnum as ESjablooncontextNaamEnum) {
      case ESjablooncontextNaamEnum.Persoon:
        return PersoonInhoud;
      case ESjablooncontextNaamEnum.Contract:
        return ContractInhoud;
      case ESjablooncontextNaamEnum.Relatie:
        return RelatieInhoud;
    }
    return null;
  }, [props.context.NaamEnum]);

  return (
    <Root>
      <Kop>
        <ContextNaam>{props.context.Naam}</ContextNaam>
        {props.opgegevenContext.alias !== undefined && (
          <ContextAlias>{props.opgegevenContext.alias}</ContextAlias>
        )}
        {props.magOpgegevenContextenMuteren && (
          <VerwijderenKnop onClick={handleVerwijderenKnopClick} disabled={props.isBezig}>
            <IconKruis
              style={{
                fill: props.isBezig ? Kleur.LichtGrijs : Kleur.Grijs,
                width: 19,
                height: 19,
                position: 'relative',
                top: -1,
              }}
            />
          </VerwijderenKnop>
        )}
      </Kop>
      <Inhoud>
        {InhoudComp === null ? (
          <span className="text-muted" style={{ fontSize: 13 }}>
            Deze context heeft geen visualisatie implementatie
          </span>
        ) : (
          <InhoudComp data={props.opgegevenContext.data} />
        )}
      </Inhoud>
    </Root>
  );
});

export default ContextWeergave;
