import React, { useCallback, useEffect, useMemo, useState } from 'react';
import IDialoogProps from '../../../core/IDialoogProps';
import Dialoog from '../Dialoog';
import { ModalBody, ModalFooter, ModalTitle } from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/esm/ModalHeader';
import LoadingSpinner from '../../Gedeeld/LoadingSpinner';
import ContactSelectie, { EType } from '../../formulier/ContactSelectie';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../models/IRemoteData';
import api from '../../../api';
import {
  IOphalenAfdelingenResult,
  IOphalenAfdelingenResultElement,
} from '../../../../../shared/src/api/v2/relatie/afdeling';
import ASPTabel from '../../tabel/ASPTabel';
import { ASPKolom, EAspKolomBreedteType } from '../../tabel/ASPTabel/types';

enum EKolom {
  Naam,
  Telefoonnummer,
  KiesActie,
}

export interface IDialoogResult {
  afdeling: IOphalenAfdelingenResultElement;
}

interface IProps extends IDialoogProps<IDialoogResult> {
  orgID?: number;
}

const OrganisatieAfdelingSelectieDialoog = (props: IProps) => {
  const [isBezig, setIsBezig] = useState(false);
  const [orgID, setOrgID] = useState<number | null>(props.orgID ?? null);

  const [afdelingenResult, setAfdelingenResult] = useState<
    IRemoteData<IOphalenAfdelingenResult | null>
  >(createPendingRemoteData());
  const ophalenAfdelingen = useCallback(async () => {
    if (orgID === null) {
      setAfdelingenResult(createReadyRemoteData(null));
      return;
    }
    setAfdelingenResult(createPendingRemoteData());
    const result = await api.v2.relatie.afdeling.ophalenAfdelingen({
      filterSchema: {
        filters: [
          {
            naam: 'ORG_IDS',
            data: [orgID],
          },
        ],
      },
    });
    setAfdelingenResult(createReadyRemoteData(result));
  }, [orgID]);
  useEffect(() => {
    ophalenAfdelingen();
  }, [ophalenAfdelingen]);

  const afdelingenKeyExtractor = useCallback((rij: IOphalenAfdelingenResultElement) => rij.ID, []);

  const afdelingenKolommen = useMemo<ASPKolom<EKolom, IOphalenAfdelingenResultElement>[]>(
    () => [
      {
        key: EKolom.Naam,
        label: 'Naam',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 200,
        renderer: (rij) => rij.Naam,
      },
      {
        key: EKolom.Telefoonnummer,
        label: 'Telefoonnummer',
        breedteType: EAspKolomBreedteType.Flex,
        flex: 1,
        renderer: (rij) => rij.Telefoon ?? '- -',
      },
      {
        key: EKolom.KiesActie,
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 75,
        renderer: (rij) =>
          rij.Telefoon === null ? null : (
            <a
              href="#"
              onClick={async (ev) => {
                ev.preventDefault();

                await props.onSuccess({
                  afdeling: rij,
                });
              }}
            >
              Kies
            </a>
          ),
      },
    ],
    [props.onSuccess],
  );

  const afdelingenRijen = useMemo<Record<number, IOphalenAfdelingenResultElement> | null>(() => {
    if (afdelingenResult.state === ERemoteDataState.Pending || afdelingenResult.data === null) {
      return null;
    }
    return afdelingenResult.data!.afdelingen.reduce(
      (acc, curr, i) => ({
        ...acc,
        [i]: curr,
      }),
      {},
    );
  }, [afdelingenResult]);

  return (
    <Dialoog index={props.dialoogIndex ?? 0}>
      <ModalHeader>
        <ModalTitle>Selecteer een afdeling van een organisatie</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <div className="form-group">
          <label>Organisatie</label>
          <ContactSelectie
            value={
              orgID === null
                ? null
                : {
                    orgID,
                    persID: null,
                  }
            }
            onChange={(contact) => setOrgID(contact?.organisatie?.OrgID ?? null)}
            options={{
              types: [EType.Organisatie],
            }}
          />
          {afdelingenResult.state === ERemoteDataState.Pending ? (
            <div className="d-flex align-items-center justify-content-center p-3">
              <LoadingSpinner />
            </div>
          ) : afdelingenResult.data === null ? null : (
            <div className="mt-3">
              <label>Afdeling</label>
              <div style={{ height: 150, width: '100%' }} className="d-flex flex-fill">
                <ASPTabel
                  rijen={afdelingenRijen!}
                  kolommen={afdelingenKolommen}
                  keyExtractor={afdelingenKeyExtractor}
                  leegComponent={(leegProps) => (
                    <div
                      style={{ ...leegProps }}
                      className="d-flex align-items-center justify-content-center"
                    >
                      Geen afdelingen gevonden voor deze organisatie
                    </div>
                  )}
                />
              </div>
            </div>
          )}
        </div>
      </ModalBody>
      <ModalFooter className="d-flex flex-row justify-content-start">
        <button
          className="btn btn-secondary"
          onClick={props.onAnnuleren}
          style={{ width: 100 }}
          disabled={isBezig}
        >
          Annuleren
        </button>
      </ModalFooter>
    </Dialoog>
  );
};

export default OrganisatieAfdelingSelectieDialoog;
