import React, { useMemo } from 'react';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import ModalHeader from 'react-bootstrap/ModalHeader';
import IDialoogProps from '../../../../../../../../../../../core/IDialoogProps';
import Dialoog from '../../../../../../../../../../../components/dialogen/Dialoog';
import CodeBewerker from '../../../../../../../../../../../components/formulier/CodeBewerker';

interface IProps extends IDialoogProps<null> {
  bron: string;
}

const BronResultaatDialoog: React.FC<IProps> = (props) => {
  const { children, dialoogIndex, onAnnuleren, onSuccess, open } = props;

  const bron = useMemo(() => {
    try {
      const parsed = JSON.parse(props.bron);
      return JSON.stringify(parsed, null, 2);
    } catch (err) {
      // noop
    }
    return props.bron;
  }, []);

  return (
    <Dialoog
      index={dialoogIndex || 0}
      modalProps={{
        size: 'xl',
      }}
    >
      <ModalHeader>
        <ModalTitle>Bron resultaat</ModalTitle>
      </ModalHeader>
      <ModalBody className="p-0" style={{ height: 800 }}>
        <CodeBewerker
          code={bron}
          editorOptionsBuilder={(options) => ({
            ...options,
            readOnly: true,
          })}
          language="json"
        />
      </ModalBody>
      <ModalFooter className="d-flex flex-row justify-content-start">
        <button className="btn btn-primary" onClick={() => onSuccess(null)} style={{ width: 100 }}>
          Ok
        </button>
        <button className="btn btn-secondary" onClick={onAnnuleren} style={{ width: 100 }}>
          Annuleren
        </button>
      </ModalFooter>
    </Dialoog>
  );
};

export default BronResultaatDialoog;
