import React, { useMemo } from 'react';
import Tabblad, { ITabblad } from '../../../components/layout/Tabblad';
import { matchPath, Redirect, Route, RouteComponentProps } from 'react-router-dom';
import { Root } from '../../Inkoop/Aanbod/style';
import Vragen from './Vragen';
import Groepen from './Groepen';
import PaginaPlaatsing from './PaginaPlaatsing';

export enum ETabblad {
  Vragen = 1,
  Groepen = 2,
  PaginaPlaatsing = 3,
}

const urlTabbladMap: Record<ETabblad, string> = {
  [ETabblad.Vragen]: 'vragen',
  [ETabblad.Groepen]: 'groepen',
  [ETabblad.PaginaPlaatsing]: 'webpagina',
};

interface IProps extends RouteComponentProps {}

const Faq: React.FC<IProps> = (props) => {
  const tabbladen = useMemo<ITabblad<ETabblad>[]>(
    () => [
      {
        id: ETabblad.Vragen,
        label: 'Vragen',
        content: Vragen,
      },
      {
        id: ETabblad.Groepen,
        label: 'Groepen',
        content: Groepen,
      },
      {
        id: ETabblad.PaginaPlaatsing,
        label: 'Plaatsing per pagina',
        content: PaginaPlaatsing,
      },
    ],
    [],
  );

  const tabblad = useMemo<ETabblad | null>(() => {
    const paths = Object.keys(urlTabbladMap).map((key) => {
      const tabblad = Number(key) as ETabblad;
      const url = urlTabbladMap[tabblad];
      return {
        tabblad,
        path: `${props.match.path}/${url}`,
      };
    });
    for (const pathObj of paths) {
      const match = matchPath(props.location.pathname, pathObj.path);
      if (match !== null) {
        return pathObj.tabblad;
      }
    }

    return null;
  }, [props.location.pathname]);

  return (
    <>
      <Route path={`${props.match.path}/groep`} component={Faq} />
      <Route
        exact
        path={props.match.path}
        component={() => <Redirect to={`${props.match.path}/${urlTabbladMap[ETabblad.Vragen]}`} />}
      />
      {tabblad === null ? (
        <Redirect to={`${props.match.path}/${urlTabbladMap[ETabblad.Vragen]}`} />
      ) : (
        <Root>
          <Tabblad
            geselecteerd={tabblad}
            onSelectieChange={(identifier) =>
              props.history.push(`${props.match.path}/${urlTabbladMap[identifier]}`)
            }
            tabbladen={tabbladen}
          />
        </Root>
      )}
    </>
  );
};

export default Faq;
