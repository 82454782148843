import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import IDialoogProps from '../../../../../../../core/IDialoogProps';
import { ModalBody, ModalFooter, ModalTitle } from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/ModalHeader';
import { Formik, FormikActions, FormikErrors } from 'formik';
import api from '../../../../../../../api';
import LoadingSpinner from '../../../../../../../components/Gedeeld/LoadingSpinner';
import { EResultType } from '../../../../../../../stores/CheckStore';
import { RootStoreContext } from '../../../../../../../stores/RootStore';
import Dialoog from '../../../../../../../components/dialogen/Dialoog';
import { dagDatum } from '../../../../../../../helpers/datum';
import {
  IOphalenPartnershipsResultElement,
  IWijzigenPartnershipParams,
} from '../../../../../../../../../shared/src/api/v2/partnership';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../../../../models/IRemoteData';
import teksten from '../../../../../../../bedrijfslogica/teksten';
import { IOphalenTekstenInAlleTalenResult } from '../../../../../../../../../shared/src/api/v2/tekst';
import ITaalTekst from '../../../../../../../../../shared/src/models/talen/ITaalTekst';
import Tabblad, { ITabblad } from '../../../../../../../components/layout/Tabblad';
import AlgemeenTabblad from './AlgemeenTabblad';
import TekstenTabblad from './TekstenTabblad';
import MediaTabblad from './MediaTabblad';
import { Bestand, BestandType } from '../../../../../../../components/BijlagenContainer';
import { IOphalenBestandenResult } from '../../../../../../../../../shared/src/api/v2/bestand/bestand';

enum ETabblad {
  Algemeen,
  Teksten,
  Media,
}

export interface IFormikValues {
  ingangsdatum: Date;
  einddatum: Date | null;
  identificatiecode: string;
  toegangscode: string;
  actiecode: string;
  slug: string;
  token: string;
  actief: boolean;
  titel: ITaalTekst[];
  inhoud: ITaalTekst[];
  toegangscodeInhoud: ITaalTekst[];
  bannerLinks: Bestand | null;
  bannerRechts: Bestand | null;
}

export const veldnamen: Record<keyof IFormikValues, string> = {
  ingangsdatum: 'Ingangsdatum',
  einddatum: 'Einddatum',
  identificatiecode: 'Identificatie',
  toegangscode: 'Deelnamecode',
  actiecode: 'Actiecode',
  slug: 'Slug',
  token: 'Token',
  actief: 'Actief',
  titel: 'Titel',
  inhoud: 'Inhoud',
  toegangscodeInhoud: 'Toegangscode inhoud',
  bannerLinks: 'Banner links',
  bannerRechts: 'Banner rechts',
};

interface IDialoogResult {}

interface IProps extends IDialoogProps<IDialoogResult> {
  id?: number;
}

const MutatieDialoog: React.FC<IProps> = (props) => {
  const { checkStore } = useContext(RootStoreContext);
  const { onAnnuleren, onSuccess, open } = props;

  const [tabblad, setTabblad] = useState<ETabblad>(ETabblad.Algemeen);

  const [partnership, setPartnership] = useState<
    IRemoteData<IOphalenPartnershipsResultElement | null>
  >(createPendingRemoteData());
  const [tekstenResult, setTekstenResult] = useState<IRemoteData<IOphalenTekstenInAlleTalenResult>>(
    createPendingRemoteData(),
  );
  const [bestandenResult, setBestandenResult] = useState<IRemoteData<IOphalenBestandenResult>>(
    createPendingRemoteData(),
  );

  const ophalenPartnerships = useCallback(async () => {
    const partnership = (
      await api.v2.partnership.ophalenPartnerships({
        filterSchema: { filters: [{ naam: 'REL_IDS', data: [props.id] }] },
        // orderSchema: {
        //   orders: [
        //     { naam: 'INGANGSDATUM', richting: 'DESC' },
        //     { naam: 'BASISNUMMER', richting: 'DESC' },
        //   ],
        // },
      })
    ).partnerships[0];

    setPartnership(createReadyRemoteData(partnership));
  }, [props.id]);

  useEffect(() => {
    ophalenPartnerships();
  }, [ophalenPartnerships]);

  const ophalenTeksten = useCallback(async () => {
    if (partnership.state === ERemoteDataState.Pending) {
      if (tekstenResult.state === ERemoteDataState.Ready) {
        setTekstenResult(createPendingRemoteData());
      }
      return;
    }

    const tekstIDs = [
      partnership.data!.Titel_TekstID,
      partnership.data!.Inhoud_TekstID,
      partnership.data!.ToegangscodeInhoud_TekstID,
    ];

    const result = await api.v2.tekst.ophalenTekstenInAlleTalen({
      tekstIDs,
    });
    setTekstenResult(createReadyRemoteData(result));
  }, [partnership]);

  useEffect(() => {
    ophalenTeksten();
  }, [ophalenTeksten]);

  const ophalenBestanden = useCallback(async () => {
    if (partnership.state === ERemoteDataState.Pending) {
      if (bestandenResult.state === ERemoteDataState.Ready) {
        setBestandenResult(createPendingRemoteData());
      }
      return;
    }

    const bestandIDs = [
      partnership.data!.BannerLinks_BestandID,
      partnership.data!.BannerRechts_BestandID,
    ];

    const result = await api.v2.bestand.ophalenBestanden({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: bestandIDs,
          },
        ],
      },
    });
    setBestandenResult(createReadyRemoteData(result));
  }, [partnership]);

  useEffect(() => {
    ophalenBestanden();
  }, [ophalenBestanden]);

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (props.id === undefined) {
      // Toevoegen
      return {
        ingangsdatum: dagDatum(new Date()),
        einddatum: null,
        identificatiecode: '',
        toegangscode: '',
        actiecode: '',
        slug: '',
        token: '',
        actief: false,
        titel: [],
        inhoud: [],
        toegangscodeInhoud: [],
        bannerLinks: null,
        bannerRechts: null,
      };
    }

    if (
      partnership.state === ERemoteDataState.Pending ||
      tekstenResult.state === ERemoteDataState.Pending ||
      bestandenResult.state === ERemoteDataState.Pending
    ) {
      return null;
    }

    const taalTeksten = tekstenResult.data!.teksten.map((tekst): ITaalTekst & {
      tekstID: number;
    } => ({
      tekst: tekst.Tekst ?? '',
      taalID: tekst.TaalID,
      toepassen: tekst.Toepassen,
      tekstID: tekst.TekstID,
    }));

    const bestandLinks = bestandenResult.data!.bestanden.find(
      (x) => x.ID === partnership.data!.BannerLinks_BestandID,
    )!;
    const bestandRechts = bestandenResult.data!.bestanden.find(
      (x) => x.ID === partnership.data!.BannerRechts_BestandID,
    )!;

    return {
      ingangsdatum: dagDatum(new Date(partnership.data!.Ingangsdatum)),
      einddatum:
        partnership.data!.Einddatum !== null
          ? dagDatum(new Date(partnership.data!.Einddatum))
          : null,
      identificatiecode: partnership.data!.Identificatiecode ?? '',
      toegangscode: partnership.data!.Toegangscode ?? '',
      actiecode: partnership.data!.Actiecode ?? '',
      slug: partnership.data!.Slug ?? '',
      token: partnership.data!.Token ?? '',
      actief: partnership.data!.Actief,
      titel: taalTeksten.filter((x) => x.tekstID === partnership.data!.Titel_TekstID),
      inhoud: taalTeksten.filter((x) => x.tekstID === partnership.data!.Inhoud_TekstID),
      toegangscodeInhoud: taalTeksten.filter(
        (x) => x.tekstID === partnership.data!.ToegangscodeInhoud_TekstID,
      ),
      bannerLinks: {
        type: BestandType.ASPDrive,
        bestand: bestandLinks,
      },
      bannerRechts: {
        type: BestandType.ASPDrive,
        bestand: bestandRechts,
      },
    };
  }, [props.id, partnership, tekstenResult, bestandenResult]);

  const handleValidate = useCallback(
    (values: IFormikValues) => {
      const errors: FormikErrors<IFormikValues> = {};

      if (values.slug.trim().length === 0) {
        errors.actiecode = teksten.formulier.E_VERPLICHT_VELD({ veldnaam: veldnamen.slug });
      }
      if (values.token.trim().length === 0) {
        errors.actiecode = teksten.formulier.E_VERPLICHT_VELD({ veldnaam: veldnamen.token });
      }
      if (values.actiecode.trim().length === 0) {
        errors.actiecode = teksten.formulier.E_VERPLICHT_VELD({ veldnaam: veldnamen.actiecode });
      }
      if (values.toegangscode.trim().length === 0) {
        errors.toegangscode = teksten.formulier.E_VERPLICHT_VELD({
          veldnaam: veldnamen.toegangscode,
        });
      }

      return errors;
    },
    [teksten],
  );

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      if (values.bannerLinks === null || values.bannerRechts === null) {
        throw new Error('Banners zijn verplicht');
      }

      const token = values.token.trim();
      const slug = values.slug.trim();
      const identificatieCode = values.identificatiecode.trim();
      const toegangscode = values.toegangscode.trim();
      const actiecode = values.actiecode.trim();

      // tslint:disable-next-line:variable-name
      let bannerLinks_BestandID: number;
      if (values.bannerLinks.type === BestandType.Lokaal) {
        const uploadResult = await api.v2.bestand.upload({
          file: values.bannerLinks.file,
          url: values.bannerLinks.url,
          grootte: values.bannerLinks.grootte,
          mediaType: values.bannerLinks.mediaType,
          naam: values.bannerLinks.naam,
        });
        bannerLinks_BestandID = uploadResult.ID;
      } else {
        bannerLinks_BestandID = values.bannerLinks.bestand.ID;
      }

      // tslint:disable-next-line:variable-name
      let bannerRechts_BestandID: number;
      if (values.bannerRechts.type === BestandType.Lokaal) {
        const uploadResult = await api.v2.bestand.upload({
          file: values.bannerRechts.file,
          url: values.bannerRechts.url,
          grootte: values.bannerRechts.grootte,
          mediaType: values.bannerRechts.mediaType,
          naam: values.bannerRechts.naam,
        });
        bannerRechts_BestandID = uploadResult.ID;
      } else {
        bannerRechts_BestandID = values.bannerRechts.bestand.ID;
      }

      if (props.id !== undefined) {
        const params: IWijzigenPartnershipParams = {
          id: props.id,
          ingangsdatum: values.ingangsdatum,
          einddatum: values.einddatum,
          actief: values.actief,
          token,
          slug,
          identificatiecode: identificatieCode.length === 0 ? null : identificatieCode,
          toegangscode,
          actiecode,
          titel: values.titel,
          inhoud: values.inhoud,
          toegangscodeInhoud: values.toegangscodeInhoud,
          bannerLinks_BestandID,
          bannerRechts_BestandID,
        };

        const checkData = await api.v2.partnership.checkWijzigenPartnership(params);
        if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
          actions.setSubmitting(false);
          return;
        }

        await api.v2.partnership.wijzigenPartnership(params);
      }

      actions.setSubmitting(false);
      onSuccess({});
    },
    [],
  );

  const tabbladen = useMemo<ITabblad<ETabblad>[]>(
    () => [
      {
        id: ETabblad.Algemeen,
        label: 'Algemeen',
        content: AlgemeenTabblad,
      },
      {
        id: ETabblad.Teksten,
        label: 'Teksten',
        content: TekstenTabblad,
      },
      {
        id: ETabblad.Media,
        label: 'Media',
        content: MediaTabblad,
      },
    ],
    [],
  );

  return (
    <Dialoog index={props.dialoogIndex || 0} modalProps={{ size: 'lg' }}>
      <ModalHeader>
        <ModalTitle>
          {props.id !== undefined ? 'Wijzigen partnership' : 'Toevoegen partnership'}
        </ModalTitle>
      </ModalHeader>
      {initialValues === null ? (
        <ModalBody>
          <LoadingSpinner />
        </ModalBody>
      ) : (
        <Formik<IFormikValues>
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validate={handleValidate}
          render={(FormikProps) => {
            const { submitForm, isSubmitting, values, errors, isValid } = FormikProps;
            return (
              <>
                <ModalBody className="p-0">
                  <Tabblad
                    geselecteerd={tabblad}
                    onSelectieChange={setTabblad}
                    tabbladen={tabbladen}
                  />
                </ModalBody>
                <ModalFooter className="d-flex flex-row justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    style={{ width: 100 }}
                    disabled={isSubmitting || !isValid}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={onAnnuleren}
                    style={{ width: 100 }}
                    disabled={isSubmitting}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Dialoog>
  );
};

export default MutatieDialoog;
