import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import ASPTabel from '../../tabel/ASPTabel';
import {
  IProlongatieRunAfgeleideData,
  IProlongatieRunV2,
} from '../../../../../shared/src/api/v2/prolongatie';
import {
  IBepalenEnVastleggenProlongatiesResult,
  IVerwerkenProlongatiesParams,
  IVerwerkenProlongatiesResult,
  IVerwijderenProlongatieRunsParams,
} from '../../../../../shared/src/api/v2/prolongatieV2';
import {
  ASPKolom,
  EAspKolomBreedteType,
  ESorteringModus,
  IAspKolomSorteringItem,
} from '../../tabel/ASPTabel/types';
import { format } from 'date-fns';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../models/IRemoteData';
import api, { IPaginatiePositie } from '../../../api';
import MenuLayout from '../../MenuLayout';
import LoadingSpinner from '../../Gedeeld/LoadingSpinner';
import RelatieVisualisatie from '../../personalia/RelatieVisualisatie';
import ProlongatieDetail, { ProlongatieContractenDataProvider } from './ProlongatieDetail';
import FormatteerBedrag from '../../MutatieBedrag';
import { Kleur as EKleur } from '../../../bedrijfslogica/constanten';
import { IconPijlVolgend, IconToevoegen, IconVerwijderen } from '../../Icons';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../stores/RootStore';
import { EResultType } from '../../../stores/CheckStore';
import NieuwRunDialoog, { IBetaaldagModus, IRelatieModus, NieuweRunModus } from './NieuwRunDialoog';
import { GlobaleRendererContext } from '../../../one-off-components/GlobaleRenderer';
import ConfigurerenDialoog from './ConfigurerenDialoog';
import FilterBalkV2, { IFilter, IFilterData, maakFilterSchema } from '../../FilterBalkV2';
import VinkVeld from '../../formulier/VinkVeld';
import { IFilterSchema } from '../../../../../shared/src/models/filter';
import InactiefOverlay from '../../InactiefOverlay';
import { achtergrondProcesAfwachten } from '../../../core/achtergrondProces';
import { RealtimeContext } from '../../../one-off-components/realtime/RealtimeIntegratie';
import VerwerkenRunDialoog from './VerwerkenRunDialoog';

export interface IData {
  runs: Record<number, IProlongatieRunV2>;
  afgeleideDatas: Record<number, IProlongatieRunAfgeleideData>;
  totaalAantalRuns: number;
}

export interface IDataProviderParams {
  filterSchema?: IFilterSchema;
  paginatie: IPaginatiePositie;
  basis: IData | null;
}

export interface IDataProviderOutput {
  data: IData;
}

export type DataProviderFn = (params: IDataProviderParams) => Promise<IDataProviderOutput>;

export enum ERunTabelV2Kolom {
  Rundatum,
  Prolongatienummer,
  Prolongatiedatum,
  Betaaldag,
  TotaalProlongatie,
  TotaalHuur,
  TotaalActie,
  TotaalGebruikerstoeslag,
  TotaalServicetoeslag,
  AantalSignaleringen,
  AantalContracten,
  Relatie,
  IsVerwerkt,
  VoorkomendeBetaaldagen,
}

const STANDAARD_PAGINATIE_AANTAL = 50;

export interface IRunTabelV2Context {
  prolongatieContractenDataProvider?: ProlongatieContractenDataProvider;
  relatieKolomVerstoppen?: boolean;
  prolongatierunAfgeleideDataHerbepalen: (prolRunID: number) => Promise<void>;
  relID?: number;
  permissieMagRunVerwerken?: boolean;
  verversenData: () => Promise<void>;
}

export const RunTabelV2Context = React.createContext<IRunTabelV2Context>(null as any);

export enum EFilter {
  IsVerwerkt = 'IS_VERWERKT',
}

interface IProps {
  dataProvider: DataProviderFn;
  relID?: number;
  prolongatieContractenDataProvider?: ProlongatieContractenDataProvider;
  relatieKolomVerstoppen?: boolean;
  paginatieAantal?: number;
  selectie: number[];
  onSelectieChange: (selectie: number[]) => void;
  sortering: IAspKolomSorteringItem<ERunTabelV2Kolom>[];
  onSorteringChange: (sortering: IAspKolomSorteringItem<ERunTabelV2Kolom>[]) => void;
  uitgeklapt: number[];
  onUitgeklaptChange: (uitgeklapt: number[]) => void;
  alleenNietVerwerktInitieel?: boolean;
  permissieMagRunToevoegen?: boolean;
  permissieMagRunVerwerken?: boolean;
  permissieMagRunVerwijderen?: boolean;
}

const RunTabelV2 = observer((props: IProps) => {
  const { checkStore } = useContext(RootStoreContext);
  const globaleRenderer = useContext(GlobaleRendererContext);
  const realtimeContext = useContext(RealtimeContext);
  const [isBezig, setIsBezig] = useState(false);

  const dataRef = useRef<IRemoteData<IData>>(createPendingRemoteData());
  const [dataState, setDataState] = useState<IRemoteData<IData>>(dataRef.current);
  const data = useMemo(() => dataState, [dataState]);
  const setData = useCallback(
    (data: IRemoteData<IData>) => {
      dataRef.current = data;
      setDataState(data);
    },
    [setDataState],
  );

  const paginatieAantal = props.paginatieAantal ?? STANDAARD_PAGINATIE_AANTAL;

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.IsVerwerkt,
        weergave: (weergaveProps) => (
          <div className="d-flex align-items-center">
            <VinkVeld
              aangevinkt={weergaveProps.isActief}
              onGewijzigd={(x) => {
                weergaveProps.setIsActief(x);
                weergaveProps.toepassen();
              }}
            />
            <span className="ml-2">Alleen niet verwerkt</span>
          </div>
        ),
        altijdWeergevenInBalk: true,
        actiefMuteerbaar: false,
      },
    ],
    [],
  );
  const [filterData, setFilterData] = useState<IFilterData<EFilter>[]>([
    {
      naam: EFilter.IsVerwerkt,
      data: false,
      isActief:
        props.alleenNietVerwerktInitieel === undefined ? true : props.alleenNietVerwerktInitieel,
    },
  ]);
  const filterSchema = useMemo(() => maakFilterSchema(filterData), [filterData]);

  const bepalenData = useCallback(
    async (paginatie: IPaginatiePositie, uitbreiden: boolean) => {
      const basis = uitbreiden ? dataRef.current.data : null;
      const result = await props.dataProvider({ paginatie, basis, filterSchema });
      setData(createReadyRemoteData(result.data));
    },
    [props.dataProvider, setData, filterSchema],
  );

  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    bepalenData({ index: 0, aantal: paginatieAantal }, false);
  }, [bepalenData, paginatieAantal]);

  const handleExtraRijenAangevraagd = useCallback(
    async (paginatie: IPaginatiePositie) => {
      await bepalenData(paginatie, true);
    },
    [bepalenData],
  );

  const verversenData = useCallback(async () => {
    await bepalenData({ index: 0, aantal: paginatieAantal }, false);
  }, [bepalenData]);

  const keyExtractor = useCallback((item: IProlongatieRunV2) => item.ID, []);
  const kolommen = useMemo<ASPKolom<ERunTabelV2Kolom, IProlongatieRunV2>[]>(
    () => [
      {
        key: ERunTabelV2Kolom.Rundatum,
        label: 'Rundatum',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 150,
        renderer: (run) => format(new Date(run.DatumRun), 'dd-MM-yyyy HH:mm'),
      },
      {
        key: ERunTabelV2Kolom.Prolongatienummer,
        label: 'Prol.nr.',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 90,
        renderer: (run) => run.Prolongatienummer,
      },
      {
        key: ERunTabelV2Kolom.Prolongatiedatum,
        label: 'Prol.datum',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 115,
        renderer: (run) => {
          return format(new Date(run.Prolongatiedatum), 'dd-MM-yyyy');
        },
      },
      {
        key: ERunTabelV2Kolom.IsVerwerkt,
        label: 'Verwerkt',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 90,
        renderer: (run) =>
          run.IsVerwerkt ? (
            <span style={{ color: EKleur.Groen }}>Ja</span>
          ) : (
            <span style={{ color: EKleur.Blauw }}>Nee</span>
          ),
      },
      {
        key: ERunTabelV2Kolom.AantalSignaleringen,
        label: '# Signaleringen',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 130,
        renderer: (run) => {
          const afgeleideData = data.data?.afgeleideDatas[run.ID];
          if (afgeleideData === undefined) {
            return '- -';
          }
          const aantal = afgeleideData.aantalSignaleringen;
          return <span style={{ color: aantal > 0 ? EKleur.Blauw : undefined }}>{aantal}</span>;
        },
      },
      // {
      //   key: ERunTabelV2Kolom.Betaaldag,
      //   label: 'Betaaldag',
      //   breedteType: EAspKolomBreedteType.Vast,
      //   vasteBreedte: 90,
      //   renderer: (run) => (run.Betaaldag === -1 ? 'Stufi' : run.Betaaldag),
      // },
      {
        key: ERunTabelV2Kolom.TotaalProlongatie,
        label: 'Tot. prol.',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 115,
        renderer: (run) => {
          const afgeleideData = data.data?.afgeleideDatas[run.ID];
          const totaalProlongatie = afgeleideData?.totaalProlongatie ?? null;
          return totaalProlongatie === null ? (
            '- -'
          ) : (
            <FormatteerBedrag bedrag={totaalProlongatie} />
          );
        },
      },
      {
        key: ERunTabelV2Kolom.TotaalHuur,
        label: 'Tot. Huur',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 115,
        renderer: (run) => {
          const afgeleideData = data.data?.afgeleideDatas[run.ID];
          const totaalHuur = afgeleideData?.totaalHuur ?? null;
          return totaalHuur === null ? (
            '- -'
          ) : (
            <FormatteerBedrag bedrag={totaalHuur} style={{ opacity: 0.5 }} />
          );
        },
      },
      {
        key: ERunTabelV2Kolom.TotaalActie,
        label: 'Tot. Actie',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 115,
        renderer: (run) => {
          const afgeleideData = data.data?.afgeleideDatas[run.ID];
          const totaalActie = afgeleideData?.totaalActie ?? null;
          return totaalActie === null || totaalActie === 0 ? (
            '- -'
          ) : (
            <FormatteerBedrag bedrag={totaalActie} style={{ opacity: 0.5 }} />
          );
        },
      },
      {
        key: ERunTabelV2Kolom.TotaalGebruikerstoeslag,
        label: 'Tot. Gebr.toesl.',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 125,
        renderer: (run) => {
          const afgeleideData = data.data?.afgeleideDatas[run.ID];
          const totaalGebruikerstoeslag = afgeleideData?.totaalGebruikerstoeslag ?? null;
          return totaalGebruikerstoeslag === null || totaalGebruikerstoeslag === 0 ? (
            '- -'
          ) : (
            <FormatteerBedrag bedrag={totaalGebruikerstoeslag} style={{ opacity: 0.5 }} />
          );
        },
      },
      {
        key: ERunTabelV2Kolom.TotaalServicetoeslag,
        label: 'Tot. Serv.toesl.',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 125,
        renderer: (run) => {
          const afgeleideData = data.data?.afgeleideDatas[run.ID];
          const totaalServicetoeslag = afgeleideData?.totaalServicetoeslag ?? null;
          return totaalServicetoeslag === null || totaalServicetoeslag === 0 ? (
            '- -'
          ) : (
            <FormatteerBedrag bedrag={totaalServicetoeslag} style={{ opacity: 0.5 }} />
          );
        },
      },
      {
        key: ERunTabelV2Kolom.AantalContracten,
        label: '# Cnt.',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 85,
        renderer: (run) => {
          const afgeleideData = data.data?.afgeleideDatas[run.ID];
          return afgeleideData?.aantalContracten;
        },
      },
      {
        key: ERunTabelV2Kolom.VoorkomendeBetaaldagen,
        label: 'Betaaldag',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 120,
        renderer: (run) => {
          const afgeleideData = data.data?.afgeleideDatas[run.ID];
          return (
            afgeleideData?.voorkomendeBetaaldagen
              .map((betaaldag) => (betaaldag === -1 ? 'Stufi' : betaaldag))
              .join(', ') ?? '- -'
          );
        },
      },
      {
        key: ERunTabelV2Kolom.Relatie,
        label: () => (
          <span title="De relatie of het aantal relaties van de onderliggende contracten">
            Relatie (/#)
          </span>
        ),
        breedteType: EAspKolomBreedteType.Flex,
        flex: 1,
        minimaleVasteBreedte: 150,
        renderer: (run) => {
          const afgeleideData = data.data?.afgeleideDatas[run.ID];
          if (afgeleideData === undefined) {
            return '- -';
          }
          if (afgeleideData.eenduidig_RelID === null) {
            return <span>{afgeleideData.AantalRelaties}</span>;
          }
          return <RelatieVisualisatie relID={afgeleideData.eenduidig_RelID} />;
        },
      },
    ],
    [data],
  );

  const rijen = useMemo(() => {
    if (data.state === ERemoteDataState.Pending) {
      return null;
    }
    return data.data!.runs;
  }, [data]);
  const totaalAantalRijen = useMemo(() => {
    if (data.state === ERemoteDataState.Pending) {
      return null;
    }
    return data.data!.totaalAantalRuns;
  }, [data.data?.totaalAantalRuns]);

  const verwijderProlRunIDs = useCallback(
    async (prolongatieRunIDs: number[]) => {
      setIsBezig(true);

      // Optimistisch updaten
      // const huidigeData = dataRef.current.data!;
      // const nieuweData: IData = {
      //   totaalAantalRuns: huidigeData.totaalAantalRuns - prolRunIDs.length,
      //   afgeleideDatas: Object.keys(huidigeData.afgeleideDatas)
      //     .map(Number)
      //     .reduce((acc, runID) => {
      //       if (prolRunIDs.includes(runID)) {
      //         return acc;
      //       }
      //       return {
      //         ...acc,
      //         [runID]: huidigeData.afgeleideDatas[runID],
      //       };
      //     }, {}),
      //   runs: Object.keys(huidigeData.runs)
      //     .map(Number)
      //     .reduce(
      //       (acc, idx) => {
      //         const run = huidigeData.runs[idx];
      //         if (prolRunIDs.includes(run.ID)) {
      //           return {
      //             ...acc,
      //             verwijderd: acc.verwijderd + 1,
      //           };
      //         }
      //
      //         return {
      //           ...acc,
      //           [idx - acc.verwijderd]: run,
      //         };
      //       },
      //       {
      //         runs: {},
      //         verwijderd: 0,
      //       },
      //     ).runs,
      // };
      // setData(createReadyRemoteData(nieuweData));

      const verwijderenParams: IVerwijderenProlongatieRunsParams = {
        prolongatieRunIDs,
      };
      const checkData = await api.v2.prolongatieV2.checkVerwijderenProlongatieRuns(
        verwijderenParams,
      );
      if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
        // setData(createReadyRemoteData(huidigeData));
        setIsBezig(false);
        return;
      }

      if (
        (
          await checkStore.bevestigen({
            inhoud: 'Bevestig verwijderen run(s)',
          })
        ).type === EResultType.Annuleren
      ) {
        setIsBezig(false);
        return;
      }

      await api.v2.prolongatieV2.verwijderenProlongatieRuns(verwijderenParams);

      // Selectie legen en uitgeklapte rijen die verwijderd zijn sluiten
      props.onSelectieChange([]);
      if (props.uitgeklapt.some((runID) => prolongatieRunIDs.includes(runID))) {
        const nieuwUitgeklapt = props.uitgeklapt.filter(
          (runID) => !prolongatieRunIDs.includes(runID),
        );
        props.onUitgeklaptChange(nieuwUitgeklapt);
      }

      await bepalenData({ index: 0, aantal: paginatieAantal }, false);

      setIsBezig(false);
    },
    [setIsBezig, props.selectie, props.onSelectieChange, bepalenData, paginatieAantal],
  );

  const prolongatierunAfgeleideDataHerbepalen = useCallback(
    async (prolRunID: number) => {
      const result = await api.v2.prolongatie.ophalenProlongatieRunAfgeleideDatas({
        prolRunIDs: [prolRunID],
      });
      setDataState((prev) => {
        if (prev.state === ERemoteDataState.Pending) {
          return createPendingRemoteData();
        }

        const nieuweAfgeleideDatas = { ...prev.data!.afgeleideDatas, ...result.afgeleideDatas };
        return createReadyRemoteData({
          ...prev.data!,
          afgeleideDatas: nieuweAfgeleideDatas,
        });
      });
    },
    [setDataState],
  );

  const context = useMemo<IRunTabelV2Context>(
    () => ({
      prolongatieContractenDataProvider: props.prolongatieContractenDataProvider,
      relatieKolomVerstoppen: props.relatieKolomVerstoppen,
      prolongatierunAfgeleideDataHerbepalen,
      relID: props.relID,
      permissieMagRunVerwerken: props.permissieMagRunVerwerken,
      verversenData: verversenData,
    }),
    [
      props.prolongatieContractenDataProvider,
      props.relatieKolomVerstoppen,
      prolongatierunAfgeleideDataHerbepalen,
      props.relID,
      props.permissieMagRunVerwerken,
      verversenData,
    ],
  );

  return (
    <RunTabelV2Context.Provider value={context}>
      <InactiefOverlay
        isInactief={isBezig}
        rootStyle={{ flex: 1 }}
        element={
          <MenuLayout
            menu={
              <div className="d-flex align-items-center">
                <div className="d-flex align-items-center mr-4">
                  <button
                    className="btn btn-sm btn-light d-flex align-items-center"
                    style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                    disabled={isBezig || props.permissieMagRunToevoegen === false}
                    onClick={async () => {
                      setIsBezig(true);

                      let modus: NieuweRunModus;
                      if (props.relID !== undefined) {
                        modus = {
                          type: 'relatie',
                          relID: props.relID,
                        } as IRelatieModus;
                      } else {
                        modus = {
                          type: 'betaaldag',
                        } as IBetaaldagModus;
                      }

                      await globaleRenderer.render<IBepalenEnVastleggenProlongatiesResult | null>(
                        (renderProps) => (
                          <NieuwRunDialoog
                            open
                            onSuccess={async (result) => {
                              return renderProps.destroy(result);
                            }}
                            onAnnuleren={() => renderProps.destroy(null)}
                            modus={modus}
                          />
                        ),
                      );
                      await bepalenData({ index: 0, aantal: paginatieAantal }, false);
                      setIsBezig(false);
                    }}
                    title={
                      props.permissieMagRunToevoegen === false
                        ? 'Je hebt geen rechten om een nieuwe run toe te voegen'
                        : undefined
                    }
                  >
                    <IconToevoegen style={{ fill: EKleur.Grijs, width: 16, height: 16 }} />
                    <span className="ml-2">Nieuwe run</span>
                  </button>
                  {/*<button*/}
                  {/*  className="btn btn-sm btn-light d-flex align-items-center ml-3"*/}
                  {/*  style={{ border: `1px solid ${EKleur.LichtGrijs}` }}*/}
                  {/*  disabled={*/}
                  {/*    isBezig ||*/}
                  {/*    props.selectie.length !== 1 ||*/}
                  {/*    props.permissieMagRunVerwerken === false*/}
                  {/*  }*/}
                  {/*  onClick={async (ev) => {*/}
                  {/*    setIsBezig(true);*/}
                  {/*    const params: IVerwerkenProlongatiesParams = {*/}
                  {/*      relID: props.relID ?? null,*/}
                  {/*      prolongatieRunIDs: props.selectie,*/}
                  {/*    };*/}
                  {/*    const checkData = await api.v2.prolongatieV2.checkVerwerkenProlongaties(*/}
                  {/*      params,*/}
                  {/*    );*/}
                  {/*    if (*/}
                  {/*      (await checkStore.controleren({ checkData })).type === EResultType.Annuleren*/}
                  {/*    ) {*/}
                  {/*      setIsBezig(false);*/}
                  {/*      return;*/}
                  {/*    }*/}
                  {/*    const isSucccess = await globaleRenderer.render((renderProps) => (*/}
                  {/*      <VerwerkenRunDialoog*/}
                  {/*        open*/}
                  {/*        onSuccess={() => renderProps.destroy(true)}*/}
                  {/*        onAnnuleren={() => renderProps.destroy(false)}*/}
                  {/*        relID={params.relID}*/}
                  {/*        prolongatieRunIDs={params.prolongatieRunIDs}*/}
                  {/*      />*/}
                  {/*    ));*/}
                  {/*    if (!isSucccess) {*/}
                  {/*      setIsBezig(false);*/}
                  {/*      return;*/}
                  {/*    }*/}
                  {/*    props.onSelectieChange([]);*/}
                  {/*    await bepalenData({ index: 0, aantal: paginatieAantal }, false);*/}
                  {/*    // const runID = props.selectie[0];*/}
                  {/*    // const verwerkt = await globaleRenderer.render<boolean>((renderProps) => (*/}
                  {/*    //   <ConfigurerenDialoog*/}
                  {/*    //     prolRunID={runID}*/}
                  {/*    //     open*/}
                  {/*    //     onSuccess={(result) => renderProps.destroy(true)}*/}
                  {/*    //     onAnnuleren={() => renderProps.destroy(false)}*/}
                  {/*    //   />*/}
                  {/*    // ));*/}
                  {/*    // if (verwerkt) {*/}
                  {/*    //   props.onSelectieChange([]);*/}
                  {/*    //   await bepalenData({ index: 0, aantal: paginatieAantal }, false);*/}
                  {/*    // }*/}
                  {/*    setIsBezig(false);*/}
                  {/*  }}*/}
                  {/*  title={*/}
                  {/*    props.permissieMagRunVerwerken === false*/}
                  {/*      ? 'Je hebt geen rechten om een run te verwerken'*/}
                  {/*      : undefined*/}
                  {/*  }*/}
                  {/*>*/}
                  {/*  <IconPijlVolgend style={{ fill: EKleur.Grijs, width: 16, height: 16 }} />*/}
                  {/*  <span className="ml-2">Verwerken run</span>*/}
                  {/*</button>*/}

                  <button
                    className="btn btn-sm btn-light d-flex align-items-center ml-3"
                    style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                    disabled={
                      isBezig ||
                      props.selectie.length === 0 ||
                      props.permissieMagRunVerwijderen === false
                    }
                    onClick={async (ev) => {
                      // if (
                      //   (
                      //     await checkStore.bevestigen({
                      //       inhoud: 'Bevestig verwijderen run(s)',
                      //     })
                      //   ).type === EResultType.Annuleren
                      // ) {
                      //   return;
                      // }

                      await verwijderProlRunIDs(props.selectie);
                    }}
                    title={
                      props.permissieMagRunVerwijderen === false
                        ? 'Je hebt geen rechten om een run te verwijderen'
                        : undefined
                    }
                  >
                    <IconVerwijderen style={{ fill: EKleur.Grijs, width: 16, height: 16 }} />
                    <span className="ml-2">Verwijderen</span>
                  </button>
                </div>

                <div
                  className="flex-fill pl-2"
                  style={{ borderLeft: `1px solid ${EKleur.LichtGrijs}` }}
                >
                  <FilterBalkV2
                    filters={filters}
                    filterData={filterData}
                    onFilterDataChange={setFilterData}
                  />
                </div>
              </div>
            }
            body={
              rijen === null ? (
                <div className="d-flex flex-column flex-fill align-items-center justify-content-center">
                  <LoadingSpinner />
                </div>
              ) : (
                <ASPTabel
                  keyExtractor={keyExtractor}
                  kolommen={kolommen}
                  rijen={rijen!}
                  totaalAantalRijen={totaalAantalRijen!}
                  onExtraRijenAangevraagd={handleExtraRijenAangevraagd}
                  selectie={props.selectie}
                  onSelectieChange={props.onSelectieChange}
                  sortering={props.sortering}
                  onSorteringChange={props.onSorteringChange}
                  sorteringOpties={{
                    modus: ESorteringModus.Whitelist,
                  }}
                  uitgeklapt={props.uitgeklapt}
                  onUitgeklaptChange={props.onUitgeklaptChange}
                  uitgeklapteRijComponent={ProlongatieDetail}
                  uitgeklapteRijHoogte={800}
                  onWijzigenRij={async (rij) => {
                    const opgeslagen = await globaleRenderer.render<boolean>((renderProps) => (
                      <ConfigurerenDialoog
                        prolRunID={rij.ID}
                        open
                        onSuccess={() => renderProps.destroy(true)}
                        onAnnuleren={() => renderProps.destroy(false)}
                      />
                    ));
                    if (!opgeslagen) {
                      return;
                    }

                    await bepalenData({ index: 0, aantal: paginatieAantal }, false);
                  }}
                />
              )
            }
          />
        }
      />
    </RunTabelV2Context.Provider>
  );
});

export default RunTabelV2;
