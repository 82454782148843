import React, { forwardRef, useCallback, useContext, useMemo, useState, useEffect } from 'react';
import Tabblad from '../../../../components/layout/Tabblad';
import { CommunicatieOverlayContext } from '../../index';
import RecentTabblad from './tabbladen/Recent';
import TelefoonboekTabblad from './tabbladen/Telefoonboek';
import CentraalVlak from './CentraalVlak';
import HorizontaleScheidingslijn from '../../../../components/layout/HorizontaleScheidingslijn';
import ActueelTabblad from './tabbladen/ActueelTabblad';
import InternTabblad from './tabbladen/InternTabblad';
import { RootStoreContext } from '../../../../stores/RootStore';
import api from '../../../../api';
import { observer } from 'mobx-react-lite';
import { ERemoteDataState } from '../../../../models/IRemoteData';

export enum ETelefoonTabblad {
  Actueel,
  Recent,
  Telefoonboek,
  Intern,
}

export interface ITelefoonTabbladContext {
  bellenDisabled: boolean;
  bellen: (nummer: string) => Promise<void>;
}

export const TelefoonTabbladContext = React.createContext<ITelefoonTabbladContext>(null as any);

interface IProps {}

const TelefoonTabblad = observer(
  forwardRef((props, forwardedRef) => {
    const { voipStore, gebruikerStore, checkStore, telefoonStore } = useContext(RootStoreContext);
    const { telefoonContext, width } = useContext(CommunicatieOverlayContext);

    const [bellenDisabled, setBellenDisabled] = useState(false);
    const bellen = useCallback(async (nummer: string) => {
      setBellenDisabled(true);

      const voipAccount = voipStore.voipAccountsVigerend!.find(
        (x) => x.AspGebrID === gebruikerStore.gebruiker!.AspGebrID,
      )!;
      const result = await api.v2.telefonie.verzoekOproep({
        telefoonNummer: nummer,
        voipAccID: voipAccount.VoipAccID,
        weergevenNummer: null,
      });
      if (result.err !== undefined) {
        await checkStore.melden({
          titel: 'Bellen is mislukt.',
        });
      }
    }, []);

    const mijnUitgaandeOproepen = useMemo(() => {
      if (telefoonStore.actieveOproepen.state === ERemoteDataState.Pending) {
        return null;
      }
      const oproepen = telefoonStore.actieveOproepen.data!;
      const opr = oproepen.filter((oproep) => {
        return oproep.Richting === 'U' && oproep.AspGebrID === gebruikerStore.gebruiker?.AspGebrID;
      });

      return opr;
    }, [telefoonStore.actieveOproepen, gebruikerStore.gebruiker?.AspGebrID]);

    useEffect(() => {
      setBellenDisabled(false);
    }, [mijnUitgaandeOproepen]);

    const context = useMemo<ITelefoonTabbladContext>(
      () => ({
        bellen,
        bellenDisabled,
      }),
      [bellenDisabled, bellen],
    );

    return (
      <TelefoonTabbladContext.Provider value={context}>
        <div className="flex-fill d-flex flex-column">
          <CentraalVlak />
          <HorizontaleScheidingslijn />

          <Tabblad
            geselecteerd={telefoonContext.tabblad}
            onSelectieChange={(x) => telefoonContext.onTabbladChange(x)}
            tabbladen={[
              {
                id: ETelefoonTabblad.Actueel,
                label: 'Actueel',
                content: ActueelTabblad,
              },
              {
                id: ETelefoonTabblad.Recent,
                label: 'Recent',
                content: RecentTabblad,
              },
              {
                id: ETelefoonTabblad.Telefoonboek,
                label: 'Telefoonboek',
                content: TelefoonboekTabblad,
              },
              {
                id: ETelefoonTabblad.Intern,
                label: 'Intern',
                content: InternTabblad,
              },
            ]}
          />
        </div>
      </TelefoonTabbladContext.Provider>
    );
  }),
) as React.ForwardRefExoticComponent<IProps>;

export default TelefoonTabblad;
