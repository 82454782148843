import React, { useState, useMemo } from 'react';
import Tabblad, { ITabblad } from '../../../../components/layout/Tabblad';
import Contracten from './Contracten';
import Facturen from './Facturen';
import useSegmentTabblad from '../../../../hooks/useSegmentTabblad';
import { RouteComponentProps } from 'react-router';

export enum ETabblad {
  Facturen,
  Contracten,
}

interface IProps extends RouteComponentProps {}

const Sponsoring: React.FC<IProps> = (props) => {
  const { tabblad, setSegmentTabblad } = useSegmentTabblad<ETabblad>(
    useMemo(
      () => ({
        props,
        defaultTabblad: ETabblad.Facturen,
        pathMap: {
          [ETabblad.Facturen]: 'facturen',
          [ETabblad.Contracten]: 'contracten',
        },
        segmentIndex: 1,
      }),
      [props],
    ),
  );

  const tabbladen = useMemo<ITabblad<ETabblad>[]>(
    () => [
      {
        id: ETabblad.Facturen,
        label: 'Facturen',
        content: Facturen,
      },
      {
        id: ETabblad.Contracten,
        label: 'Contracten',
        content: Contracten,
      },
    ],
    [],
  );

  return (
    <div className="flex-fill d-flex flex-column">
      <Tabblad geselecteerd={tabblad} onSelectieChange={setSegmentTabblad} tabbladen={tabbladen} />
    </div>
  );
};

export default Sponsoring;
