import { RootStore } from './RootStore';
import {
  IOphalenPersonenResultElementV2,
  IPersoonAttributen,
  IPersoonGewijzigdMessageData,
} from '../../../shared/src/api/v2/persoon/persoon';
import api from '../api';
import { makeObservable, observable } from 'mobx';

export default class PersoonStore {
  public personen: { [key: number]: IOphalenPersonenResultElementV2 } = {};
  public persoonAttributen: { [persID: number]: IPersoonAttributen } = {};
  public personenOphaalQueue: number[] = [];
  // tslint:disable-next-line:variable-name
  private _persIDQueueTimeout: number | null = null;
  // tslint:disable-next-line:variable-name
  private _rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this._rootStore = rootStore;

    makeObservable(this, {
      personen: observable,
      persoonAttributen: observable,
      personenOphaalQueue: observable,
    });
  }

  public enqueuePersoonOphalen = (persID: number) => {
    this.personenOphaalQueue = [...this.personenOphaalQueue, persID];
    if (this._persIDQueueTimeout !== null) {
      clearTimeout(this._persIDQueueTimeout);
    }
    // @ts-ignore
    this._persIDQueueTimeout = setTimeout(this.personenOphalen, 300);
  };

  private personenOphalen = async () => {
    const queue = this.personenOphaalQueue;
    if (queue.length === 0) {
      return;
    }
    this.personenOphaalQueue = [];

    const ophalenPersonen = async () => {
      const personenResult = await api.v2.persoon.ophalenPersonen({
        filterSchema: { filters: [{ naam: 'IDS', data: [queue] }] },
      });

      this.personen = personenResult.personen.reduce(
        (acc, curr) => ({
          ...acc,
          [curr.PersID]: curr,
        }),
        this.personen,
      );
    };

    const ophalenAttributen = async () => {
      const attributenResult = await api.v2.persoon.ophalenPersoonAttributen({
        persIDs: queue,
      });

      this.persoonAttributen = {
        ...this.persoonAttributen,
        ...attributenResult.attributen,
      };
    };

    await Promise.all([ophalenPersonen(), ophalenAttributen()]);
  };

  public handlePersoonGewijzigd = async (data: IPersoonGewijzigdMessageData) => {};
}
