import React from 'react';
import { Redirect, Route, RouteComponentProps } from 'react-router';
import Beltaken from './Beltaken';
import Duplicaten from './Duplicaten';

interface IProps extends RouteComponentProps<{}> {}

const Backoffice: React.FC<IProps> = (props) => {
  const { match } = props;
  return (
    <div className="d-flex flex-fill flex-column">
      <Route path={`${match.path}/beltaken`} component={Beltaken} />

      <Route path={`${match.path}/duplicaten`} component={Duplicaten} />

      <Route
        exact={true}
        path={match.path}
        render={() => <Redirect to={`${match.path}/duplicaten`} />}
      />
    </div>
  );
};

export default Backoffice;
