import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Route, Link, RouteComponentProps, NavLink, Redirect } from 'react-router-dom';

import {
  GridStyleWrapper,
  TypedColumn,
  TypedColumnExtension,
  DXTableCheckboxComponent,
  DXTableToggleCellComponent,
} from '../../../helpers/dxTableGrid';
import {
  Grid,
  VirtualTable,
  TableHeaderRow,
  TableSelection,
  TableRowDetail,
} from '@devexpress/dx-react-grid-bootstrap4';
import { Row } from 'react-bootstrap';
import nameof from '../../../core/nameOf';
import { DataTypeProvider, SelectionState, RowDetailState } from '@devexpress/dx-react-grid';
import { format } from 'date-fns';
import useUrlState from '../../../core/useUrlState';
import {
  IEntiteit,
  IOphalenImportExportEntiteitenResult,
} from '../../../../../shared/src/api/v2/ImportExport';
import api from '../../../api';
import LoadingSpinner from '../../../components/Gedeeld/LoadingSpinner';
import ImportEntiteitDialoog from './ImportEntiteitDialoog';
import { Helmet } from 'react-helmet';

interface IRow {
  id: number;
  naam: string;
  naamEnum: string;
  omschrijving: string | null;
  toegestaneActies: number;
}

interface IURLState {
  selectie: number[];
  uitgeklapteRijen: number[];
  importerenEntiteitId: number | null;
}

// const rows: IRow[] = [
//   { id: 12, naam: `Webteksten`, datum: new Date(), omschrijving: `Dit is de omschrijving!` },
//   { id: 13, naam: `Sjablonen`, datum: new Date(), omschrijving: `Dit nog een omschrijving!` },
// ];

const defaultUrlState: IURLState = {
  selectie: [],
  uitgeklapteRijen: [],
  importerenEntiteitId: null,
};

interface IProps extends RouteComponentProps {}

const enum EActies {
  EXPORT_EN_IMPORT = 0,
  EXPORT = 1,
  IMPORT = 2,
}

const ImportExport = (props: IProps) => {
  const [entiteitenResult, setEntiteiten] = useState<IOphalenImportExportEntiteitenResult | null>(
    null,
  );
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);

  const ophalenEntiteiten = async () => {
    const resultaat = await api.v2.importExport.ophalenImportExportEntiteiten({});

    setEntiteiten(resultaat);
  };

  useEffect(() => {
    ophalenEntiteiten();
  }, []);

  const kolommen: TypedColumn<IRow>[] = useMemo(() => {
    return [
      {
        name: 'naam',
        title: 'Naam',
      },
      {
        name: 'omschrijving',
        title: 'Omschrijving',
      },
      {
        name: '__acties' as any,
        title: ' ',
      },
    ];
  }, []);

  const keyExtractor = useCallback((row: IRow) => row.id, []);

  // const kolommen: TypedColumn<IRow>[] = ;

  const kolomExtensies: TypedColumnExtension<IRow>[] = useMemo(() => {
    return [
      {
        columnName: 'naam',
        width: 200,
      },
      {
        columnName: 'omschrijving',
        width: 400,
      },
      {
        columnName: '__acties' as any,
        width: 200,
      },
    ];
  }, []);

  const rows = useMemo<IRow[] | null>(() => {
    if (entiteitenResult === null) {
      return null;
    }

    return entiteitenResult.entiteiten.map(
      (entiteit): IRow => {
        return {
          id: entiteit.ID,
          naam: entiteit.Naam,
          naamEnum: entiteit.NaamEnum,
          omschrijving: entiteit.Omschrijving,
          toegestaneActies: entiteit.ToegestaneActies,
        };
      },
    );
  }, [entiteitenResult]);

  const handleExporteren = useCallback(async (naamEnum: string) => {
    const result = await api.v2.importExport.exporterenEntiteiten({
      entiteiten: [
        {
          naamEnum,
          // bestandsformaatMediaType?: string;
        },
      ],
    });
    const exportResult = result.exportResults[0];
    // const bestandsnaam = `${naamEnum}_${exportResult.signatuurHash}`;
    window.open(exportResult.bestand.url, '_blank');
  }, []);

  return (
    <>
      <Helmet>
        <title>Import/Export</title>
      </Helmet>
      <div>
        {rows === null ? (
          <LoadingSpinner />
        ) : (
          <GridStyleWrapper>
            <Grid rows={rows} columns={kolommen} getRowId={keyExtractor}>
              <DataTypeProvider
                for={['__acties']}
                formatterComponent={(formatterProps) => {
                  const row: IRow = formatterProps.row;

                  return (
                    <span>
                      {(row.toegestaneActies === EActies.EXPORT_EN_IMPORT ||
                        row.toegestaneActies === EActies.EXPORT) && (
                        <a href="#" onClick={() => handleExporteren(row.naamEnum)}>
                          Exporteren
                        </a>
                      )}

                      {(row.toegestaneActies === EActies.EXPORT_EN_IMPORT ||
                        row.toegestaneActies === EActies.IMPORT) && (
                        <a
                          className="ml-4 inline-block"
                          onClick={() => {
                            setUrlStateSync('importerenEntiteitId', row.id);
                          }}
                          href="#"
                        >
                          Importeren
                        </a>
                      )}
                    </span>
                  );
                }}
              />

              <SelectionState
                selection={urlState.selectie}
                onSelectionChange={(value) => setUrlStateSync('selectie', value as number[])}
              />

              <RowDetailState
                expandedRowIds={urlState.uitgeklapteRijen}
                onExpandedRowIdsChange={(value) =>
                  setUrlStateSync('uitgeklapteRijen', value as number[])
                }
              />

              <VirtualTable columnExtensions={kolomExtensies} />
              <TableHeaderRow />
              <TableRowDetail
                toggleCellComponent={DXTableToggleCellComponent}
                contentComponent={(contentProps) => {
                  return <div>asdadasdasd</div>;
                }}
              />
              <TableSelection cellComponent={DXTableCheckboxComponent} />
            </Grid>
          </GridStyleWrapper>
        )}
      </div>

      {urlState.importerenEntiteitId !== null && (
        <ImportEntiteitDialoog
          open
          onSuccess={() => setUrlStateSync('importerenEntiteitId', null)}
          onAnnuleren={() => setUrlStateSync('importerenEntiteitId', null)}
        />
      )}
    </>
  );
};

export default ImportExport;
