import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IOphalenPersoonDuplicaatSuggestiesResult } from '../../../../../../../shared/src/api/v2/persoon/duplicaat';
import api from '../../../../../api';
import LoadingSpinner from '../../../../../components/Gedeeld/LoadingSpinner';
import SuggestieItem from './SuggestieItem';
import useUrlState from '../../../../../core/useUrlState';
import { RouteComponentProps, withRouter } from 'react-router';
import { Helmet } from 'react-helmet';

export interface IDuplicatenPersoonSuggestiesUrlState {
  uitgeklapt: number[];
  persIDFilter: number | null;
}

interface IProps extends RouteComponentProps {}

const Suggesties: React.FC<IProps> = (props) => {
  const defaultUrlState = useMemo<IDuplicatenPersoonSuggestiesUrlState>(
    () => ({
      uitgeklapt: [],
      persIDFilter: null,
    }),
    [],
  );
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(
    props,
    defaultUrlState,
    'suggestiesState',
  );

  const [
    suggestiesResult,
    setSuggestiesResult,
  ] = useState<IOphalenPersoonDuplicaatSuggestiesResult | null>(null);

  const ophalenSuggesties = useCallback(async () => {
    const result = await api.v2.persoon.duplicaat.ophalenPersoonDuplicaatSuggesties({
      filterSchema:
        urlState.persIDFilter === null
          ? undefined
          : {
              filters: [
                {
                  naam: 'PERS_IDS',
                  data: [urlState.persIDFilter],
                },
              ],
            },
      orderSchema: {
        orders: [
          {
            naam: 'PERSOON_GEWIJZIGD_OF_TOEGEVOEGD_DATUM',
            richting: 'DESC',
          },
        ],
      },
      paginatie: {
        index: 0,
        aantal: 100,
      },
    });
    setSuggestiesResult(result);
  }, [urlState.persIDFilter]);
  useEffect(() => {
    ophalenSuggesties();
  }, [ophalenSuggesties]);

  const handleVerversenAangevraagd = useCallback(async () => {
    await ophalenSuggesties();
  }, [ophalenSuggesties]);

  return (
    <div
      className="flex-fill d-flex flex-column"
      style={{
        overflowY: 'auto',
        height: 'calc(100vh - 36px)',
      }}
    >
      {suggestiesResult === null ? (
        <div className="d-flex flex-fill align-items-center justify-content-center">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          <Helmet>
            <title>Persoon-duplicaten</title>
          </Helmet>
          <div className="row">
            {suggestiesResult.suggesties.map((suggestie) => {
              const uitgeklapt = urlState.uitgeklapt.indexOf(suggestie.PersDupSugID) !== -1;
              return (
                <div key={suggestie.PersDupSugID} className="col-12">
                  <div className="p-3 pl-4 pr-4">
                    <SuggestieItem
                      suggestie={suggestie}
                      uitgeklapt={uitgeklapt}
                      onUitgeklaptChange={(x) => {
                        if (x) {
                          setUrlStateSync('uitgeklapt', [
                            ...urlState.uitgeklapt,
                            suggestie.PersDupSugID,
                          ]);
                        } else {
                          setUrlStateSync(
                            'uitgeklapt',
                            urlState.uitgeklapt.filter((x) => x !== suggestie.PersDupSugID),
                          );
                        }
                      }}
                      onVerversenAangevraagd={handleVerversenAangevraagd}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default withRouter(Suggesties);
