import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import IDialoogProps from '../../core/IDialoogProps';
import Dialoog from '../dialogen/Dialoog';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import LoadingSpinner from '../Gedeeld/LoadingSpinner';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
} from '../../models/IRemoteData';
import FormulierVlak from './FormulierVlak';
import styled from 'styled-components';
import EntiteitenVlak from './EntiteitenVlak';
import {
  EDataType,
  IBepaaldData,
  IData,
  IEntiteit,
  IMergeDialoogVeld,
  IVastGegeven,
} from './types';
import useBijGewijzigdEffect from '../../core/useBijGewijzigdEffect';
import { RootStoreContext } from '../../stores/RootStore';
import { EResultType } from '../../stores/CheckStore';

const BodyRoot = styled.div`
  display: flex;
  flex: 1;
  max-height: 850px;
  overflow-y: auto;
`;

export interface IMergeDialoogOutput<TVelden extends string | number | symbol> {
  waarden: Record<TVelden, any>;
  toegepasteEntiteitenKeys: Array<string | number>;
}

interface IProps<TVelden extends string | number | symbol, TVasteGegevens = {}>
  extends IDialoogProps<IMergeDialoogOutput<TVelden>> {
  vasteGegevens?: IVastGegeven<TVelden, TVasteGegevens>[];
  velden: Record<TVelden, IMergeDialoogVeld<any>>;
  entiteiten: Array<IEntiteit<TVelden, TVasteGegevens>>;
  valideren: (waarden: Record<TVelden, any>) => Promise<boolean>;
  labelWidth?: number;
  formulierWidth?: number;
}

const MergeDialoog = <TVelden extends string | number | symbol, TVasteGegevens = {}>(
  props: IProps<TVelden, TVasteGegevens>,
) => {
  const { checkStore } = useContext(RootStoreContext);

  const initieleWaarden = useMemo<Record<TVelden, IData<any>>>(() => {
    return Object.keys(props.velden).reduce<Record<TVelden, IData<any>>>((acc, veldStr) => {
      const veld = veldStr as TVelden;

      const waarden = props.entiteiten
        .filter((x) => x.toepassen)
        .map((entiteit) => entiteit.data[veld]);
      const waardenZonderNull = waarden.filter((x) => x !== null);

      if (waardenZonderNull.length === 0) {
        return {
          ...acc,
          [veld]: {
            type: EDataType.Bepaald,
            data: null,
          },
        };
      }
      const alleWaardenGelijk = waardenZonderNull.every(
        (waarde) => waarde === waardenZonderNull[0],
      );

      return {
        ...acc,
        [veld]: alleWaardenGelijk
          ? { type: EDataType.Bepaald, data: waardenZonderNull[0] }
          : { type: EDataType.Onbepaald },
      };
    }, {} as Record<TVelden, IData<any>>);
  }, [props.velden, props.entiteiten]);

  const [waarden, setWaarden] = useState<Record<TVelden, IData<any>>>(initieleWaarden);
  const [entiteiten, setEntiteiten] = useState<Array<IEntiteit<TVelden, TVasteGegevens>>>(
    props.entiteiten,
  );
  const [isAanHetSubmitten, setIsAanHetSubmitten] = useState(false);
  const [isGeldig, setIsGeldig] = useState<IRemoteData<boolean>>(createPendingRemoteData());

  useBijGewijzigdEffect(() => {
    const nieuweWaarden = Object.keys(props.velden).reduce<Record<TVelden, IData<any>>>(
      (acc, veldStr) => {
        const veld = veldStr as TVelden;

        const huidigeWaarde = waarden[veld];
        if (huidigeWaarde.type === EDataType.Bepaald) {
          return {
            ...acc,
            [veld]: huidigeWaarde,
          };
        }

        const entiteitWaarden = entiteiten
          .filter((x) => x.toepassen)
          .map((entiteit) => entiteit.data[veld]);

        const waardenZonderNull = entiteitWaarden.filter((x) => x !== null);
        if (waardenZonderNull.length === 0) {
          return {
            ...acc,
            [veld]: {
              type: EDataType.Bepaald,
              data: null,
            },
          };
        }
        const alleWaardenGelijk = waardenZonderNull.every(
          (waarde) => waarde === waardenZonderNull[0],
        );

        return {
          ...acc,
          [veld]: alleWaardenGelijk
            ? { type: EDataType.Bepaald, data: waardenZonderNull[0] }
            : { type: EDataType.Onbepaald },
        };
      },
      {} as Record<TVelden, IData<any>>,
    );

    setWaarden(nieuweWaarden);
  }, [entiteiten]);

  useEffect(() => {
    if (
      Object.keys(waarden).some((key) => {
        const waarde = waarden[key as TVelden];
        return waarde.type === EDataType.Onbepaald;
      })
    ) {
      setIsGeldig(createReadyRemoteData(false));
      return;
    }

    setIsGeldig(createPendingRemoteData());
    const waardenVoorValideren = Object.keys(waarden).reduce<Record<TVelden, any>>(
      (acc, curr) => ({
        ...acc,
        [curr]: (waarden[curr as TVelden] as IBepaaldData<any>).data,
      }),
      {} as Record<TVelden, any>,
    );
    props.valideren(waardenVoorValideren).then((isGeldig) => {
      setIsGeldig(createReadyRemoteData(isGeldig));
    });
  }, [waarden, props.valideren]);

  const magSubmitten = useMemo(() => isGeldig.data === true && !isAanHetSubmitten, [
    isGeldig,
    isAanHetSubmitten,
  ]);

  const handleSubmit = useCallback(async () => {
    setIsAanHetSubmitten(true);

    if (
      (await checkStore.bevestigen({ inhoud: 'De aangegeven personen samenvoegen?' })).type ===
      EResultType.Annuleren
    ) {
      setIsAanHetSubmitten(false);
      return;
    }

    const output: IMergeDialoogOutput<TVelden> = {
      waarden: Object.entries(waarden).reduce<Record<TVelden, any>>(
        (acc, [veld, data]) => ({
          ...acc,
          [veld as TVelden]: (data as IBepaaldData<any>).data,
        }),
        {} as Record<TVelden, any>,
      ),
      toegepasteEntiteitenKeys: entiteiten.filter((x) => x.toepassen).map((x) => x.key),
    };
    await props.onSuccess(output);

    setIsAanHetSubmitten(false);
  }, [waarden, entiteiten]);

  return (
    <Dialoog index={props.dialoogIndex ?? 0} modalProps={{ dialogClassName: 'modal-mergedialoog' }}>
      <ModalBody style={{ padding: 0 }}>
        <BodyRoot>
          <FormulierVlak<TVelden, TVasteGegevens>
            waarden={waarden}
            onWaardenChange={setWaarden}
            velden={props.velden}
            formulierWidth={props.formulierWidth}
            labelWidth={props.labelWidth}
            vasteGegevens={props.vasteGegevens}
          />
          <EntiteitenVlak<TVelden, TVasteGegevens>
            entiteiten={entiteiten}
            onEntiteitenChange={setEntiteiten}
            velden={props.velden}
            waarden={waarden}
            onWaardenChange={setWaarden}
            dialoogIndex={props.dialoogIndex ?? 0}
            vasteGegevens={props.vasteGegevens}
          />
        </BodyRoot>
      </ModalBody>
      <ModalFooter className="d-flex flex-row justify-content-start">
        <button
          className="btn btn-primary d-flex align-items-center justify-content-center"
          onClick={handleSubmit}
          style={{ width: 100 }}
          disabled={!magSubmitten}
        >
          {isAanHetSubmitten && (
            <span className="mr-2">
              <LoadingSpinner soort="light" grootte="15px" dikte="2px" />
            </span>
          )}
          Ok
        </button>
        <button
          className="btn btn-secondary"
          onClick={props.onAnnuleren}
          style={{ width: 100 }}
          disabled={isAanHetSubmitten}
        >
          Annuleren
        </button>
      </ModalFooter>
    </Dialoog>
  );
};

export default MergeDialoog;
