import React, { useCallback, useContext, useMemo, useRef } from 'react';
import MultiComboboxV2, {
  EnkeleProvider,
  IOverlayContainerProps,
  IOverlayOptions,
  IRepresentatieProps,
  Provider,
} from '../MultiComboboxV2';
import { IOphalenRelatieBetrekkingenResultElement } from '../../../../../shared/src/api/v2/relatie/contactpersoon';
import api from '../../../api';
import { ASPKolom, EAspKolomBreedteType } from '../../tabel/ASPTabel/types';
import styled from 'styled-components';
import Zoeken from '../Zoeken';
import useBijGewijzigdEffect from '../../../core/useBijGewijzigdEffect';
import { IFilterSchemaFilter } from '../../../../../shared/src/models/filter';
import { ITekstPrecisieData } from '../../../../../shared/src/api/sql';
import { IconToevoegen } from '../../Icons';
import { Kleur } from '../../../bedrijfslogica/constanten';
import { ERemoteDataState } from '../../../models/IRemoteData';
import { GlobaleRendererContext } from '../../../one-off-components/GlobaleRenderer';
import RelatieBetrekkingMuterenDialoog, {
  IRelatieBetrekkingMuterenDialoogOutput,
} from '../../dialogen/RelatieBetrekkingMuterenDialoog';

type Kolom = 'naam';

interface IProps {
  id: number | null;
  onChange: (id: number | null) => void;
  dialoogIndex?: number;
}

interface IOverlayState {
  term: string;
}

const RelatieBetrekkingSelectie = (props: IProps): JSX.Element => {
  const keyExtractor = useCallback((item: IOphalenRelatieBetrekkingenResultElement) => item.ID, []);
  const representatieComponent = useCallback(
    (reprProps: IRepresentatieProps<IOphalenRelatieBetrekkingenResultElement>) => (
      <span>{reprProps.entiteit.Naam}</span>
    ),
    [],
  );
  const enkeleProvider = useMemo<EnkeleProvider<number, IOphalenRelatieBetrekkingenResultElement>>(
    () => async (id) => {
      const result = await api.v2.relatie.ophalenRelatieBetrekkingen({
        filterSchema: {
          filters: [
            {
              naam: 'IDS',
              data: [id],
            },
          ],
        },
      });
      return result.betrekkingen[0];
    },
    [],
  );
  const provider = useMemo<
    Provider<Kolom, IOphalenRelatieBetrekkingenResultElement, IOverlayState>
  >(
    () => async (params) => {
      const trimmedTerm = params.overlayContainerState.term.trim();
      const filters: IFilterSchemaFilter[] = [];
      if (trimmedTerm.length !== 0) {
        filters.push({
          naam: 'NAAM_TEKST_PRECISIE',
          data: {
            precisie: 'ONGEVEER',
            waarde: trimmedTerm,
          } as ITekstPrecisieData,
        });
      }

      const result = await api.v2.relatie.ophalenRelatieBetrekkingen({
        paginatie: params.paginatie,
        filterSchema: {
          filters,
        },
        orderSchema: {
          orders: [
            {
              naam: 'NAAM',
              richting: 'ASC',
            },
          ],
        },
      });
      const betrekkingen = result.betrekkingen.reduce(
        (acc, curr, i) => ({
          ...acc,
          [i + params.paginatie.index]: curr,
        }),
        params.huidigeBron,
      );
      return {
        totaalAantal: result.totaalAantal,
        items: betrekkingen,
      };
    },
    [],
  );
  const kolommen = useMemo<ASPKolom<Kolom, IOphalenRelatieBetrekkingenResultElement>[]>(
    () => [
      {
        key: 'naam',
        label: 'Naam',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 350,
        renderer: (item) => item.Naam,
      },
    ],
    [],
  );
  const overlayOptions = useMemo<
    IOverlayOptions<IOverlayState, IOphalenRelatieBetrekkingenResultElement, number>
  >(
    () => ({
      overlayContainer: {
        initialState: {
          term: '',
        },
        overlayContainerComponent: OverlayContainer,
      },
    }),
    [],
  );

  return (
    <MultiComboboxV2<number, Kolom, IOphalenRelatieBetrekkingenResultElement, IOverlayState>
      provider={provider}
      enkeleProvider={enkeleProvider}
      keyExtractor={keyExtractor}
      waarde={props.id}
      onChange={props.onChange}
      representatieComponent={representatieComponent}
      kolommen={kolommen}
      overlayOptions={overlayOptions}
      wisbaar
      dialoogIndex={props.dialoogIndex}
    />
  );
};

const OverlayContainerRoot = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const OverlayContainer = (
  props: IOverlayContainerProps<IOverlayState, IOphalenRelatieBetrekkingenResultElement, number>,
) => {
  const globaleRenderer = useContext(GlobaleRendererContext);

  const zoekenTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  useBijGewijzigdEffect(() => {
    if (zoekenTimeoutRef.current) {
      clearTimeout(zoekenTimeoutRef.current);
    }
    zoekenTimeoutRef.current = setTimeout(() => {
      // noinspection JSIgnoredPromiseFromCall
      props.rijbronHerbepalen();
    }, 300);
  }, [props.state.term]);

  const magToevoegen =
    props.data.state === ERemoteDataState.Ready && props.data.data!.totaalAantal === 0;
  const toevoegenEnabled = magToevoegen && !props.isAanHetHerbepalen;

  const handleToevoegenClick = useCallback(async () => {
    props.sluiten();

    const result = await globaleRenderer.render<IRelatieBetrekkingMuterenDialoogOutput | null>(
      (renderProps) => (
        <RelatieBetrekkingMuterenDialoog
          id={null}
          open
          onSuccess={(result) => {
            renderProps.destroy(result);
          }}
          onAnnuleren={() => renderProps.destroy(null)}
          dialoogIndex={(props.dialoogIndex ?? 0) + 2}
          initialValues={{
            // Eerste letter hoofdletter
            naam: props.state.term.charAt(0).toUpperCase() + props.state.term.slice(1),
          }}
        />
      ),
    );
    if (result === null) {
      return;
    }
    props.onChange(result!.betrekking.ID);
  }, [props.dialoogIndex, props.state.term]);

  return (
    <OverlayContainerRoot onClick={(ev) => ev.stopPropagation()}>
      <div className="p-2 d-flex">
        <Zoeken
          input={props.state.term}
          onInputChange={(value) =>
            props.onStateChange((prev) => ({
              ...prev,
              term: value,
            }))
          }
          placeholder="Zoeken..."
          autoFocus
          onEnter={() => {
            if (props.isAanHetHerbepalen || props.data.state === ERemoteDataState.Pending) {
              return;
            }
            const items = props.data.data!.items;
            if (Object.keys(items).length !== 1) {
              return;
            }
            const item = Object.values(items)[0];
            props.onStateChange((prev) => ({
              ...prev,
              term: '',
            }));
            props.onChange(item.ID);
            props.sluiten();
          }}
        />
        <button
          className="btn btn-sm btn-light d-flex align-items-center ml-2"
          style={{
            border: `1px solid ${Kleur.LichtGrijs}`,
          }}
          disabled={!toevoegenEnabled}
          title={
            props.isAanHetHerbepalen
              ? 'Even geduld...'
              : magToevoegen
              ? undefined
              : 'Je kan alleen een nieuwe betrekkingen toevoegen als er nog geen betrekking met de gezochte naam bestaat.'
          }
          onClick={handleToevoegenClick}
        >
          <IconToevoegen style={{ fill: Kleur.Grijs, width: 17, height: 17 }} />
          <span className="ml-2">Toevoegen</span>
        </button>
      </div>

      <div className="d-flex flex-fill">{props.children}</div>
    </OverlayContainerRoot>
  );
};

export default RelatieBetrekkingSelectie;
