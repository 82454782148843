import {
  IBepalenBulkverzenderOverzichtDataParams,
  IBepalenBulkverzenderOverzichtDataResult,
  IBulkberichtReviewAccepterenParams,
  IBulkberichtReviewAccepterenResult,
  IBulkberichtReviewWeigerenParams,
  IBulkberichtReviewWeigerenResult,
  IOphalenBulkVerzendBerichtEmailsParams,
  IOphalenBulkVerzendBerichtEmailsResult,
  IOphalenBulkVerzendBerichtenParams,
  IOphalenBulkVerzendBerichtenResult,
  IOphalenBulkVerzendBerichtPrioriteitenParams,
  IOphalenBulkVerzendBerichtPrioriteitenResult,
  IOphalenBulkVerzendBerichtSmsParams,
  IOphalenBulkVerzendBerichtSmsResult,
  IOphalenBulkVerzendBerichtTijdvakDagenParams,
  IOphalenBulkVerzendBerichtTijdvakDagenResult,
  IOphalenBulkVerzendBerichtTijdvakkenParams,
  IOphalenBulkVerzendBerichtTijdvakkenResult,
  IOphalenBulkVerzenderErrorParams,
  IOphalenBulkVerzenderErrorResult,
  IResetErrorParams,
  IResetErrorResult,
} from '../../../../../shared/src/api/v2/bulkVerzending';
import api from '../../index';

const bulkVerzending = {
  ophalenBulkVerzendBerichten: async (
    params: IOphalenBulkVerzendBerichtenParams,
  ): Promise<IOphalenBulkVerzendBerichtenResult> => {
    return await api.post('/v2/bulk-verzending/ophalen-bulk-verzend-berichten', params);
  },
  ophalenBulkVerzendBerichtEmails: async (
    params: IOphalenBulkVerzendBerichtEmailsParams,
  ): Promise<IOphalenBulkVerzendBerichtEmailsResult> => {
    return await api.post('/v2/bulk-verzending/ophalen-bulk-verzend-bericht-emails', params);
  },
  ophalenBulkVerzendBerichtSms: async (
    params: IOphalenBulkVerzendBerichtSmsParams,
  ): Promise<IOphalenBulkVerzendBerichtSmsResult> => {
    return await api.post('/v2/bulk-verzending/ophalen-bulk-verzend-bericht-sms', params);
  },
  bulkberichtReviewAccepteren: async (
    params: IBulkberichtReviewAccepterenParams,
  ): Promise<IBulkberichtReviewAccepterenResult> => {
    return await api.post('/v2/bulk-verzending/bulkbericht-review-accepteren', params);
  },
  bulkberichtReviewWeigeren: async (
    params: IBulkberichtReviewWeigerenParams,
  ): Promise<IBulkberichtReviewWeigerenResult> => {
    return await api.post('/v2/bulk-verzending/bulkbericht-review-weigeren', params);
  },
  bepalenBulkverzenderOverzichtData: async (
    params: IBepalenBulkverzenderOverzichtDataParams,
  ): Promise<IBepalenBulkverzenderOverzichtDataResult> => {
    return await api.post('/v2/bulk-verzending/bepalen-bulkverzender-overzicht-data', params);
  },
  ophalenBulkVerzenderError: async (
    params: IOphalenBulkVerzenderErrorParams,
  ): Promise<IOphalenBulkVerzenderErrorResult> => {
    return await api.post('/v2/bulk-verzending/ophalen-bulk-verzender-error', params);
  },
  resetError: async (params: IResetErrorParams): Promise<IResetErrorResult> => {
    return await api.post('/v2/bulk-verzending/reset-error', params);
  },
  ophalenBulkVerzendBerichtPrioriteiten: async (
    params: IOphalenBulkVerzendBerichtPrioriteitenParams,
  ): Promise<IOphalenBulkVerzendBerichtPrioriteitenResult> => {
    return await api.post('/v2/bulk-verzending/ophalen-bulk-verzend-bericht-prioriteiten', params);
  },
  ophalenBulkVerzendBerichtTijdvakken: async (
    params: IOphalenBulkVerzendBerichtTijdvakkenParams,
  ): Promise<IOphalenBulkVerzendBerichtTijdvakkenResult> => {
    return await api.post('/v2/bulk-verzending/ophalen-bulk-verzend-bericht-tijdvakken', params);
  },
  ophalenBulkVerzendBerichtTijdvakDagen: async (
    params: IOphalenBulkVerzendBerichtTijdvakDagenParams,
  ): Promise<IOphalenBulkVerzendBerichtTijdvakDagenResult> => {
    return await api.post('/v2/bulk-verzending/ophalen-bulk-verzend-bericht-tijdvak-dagen', params);
  },
};

export default bulkVerzending;
