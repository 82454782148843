import React from 'react';
import {
  IOphalenContractenResultElement,
  IOphalenContractenResultElementV2,
} from '../../../../../../../../../../../shared/src/api/v2/contract';
import { IOphalenLocatieNiveausResultElement } from '../../../../../../../../../../../shared/src/api/v2/locatie/locatie';
import { IOphalenFactuurkenmerkenResultElement } from '../../../../../../../../../../../shared/src/api/v2/factuur';
import { IOphalenRekeningenResultElement } from '../../../../../../../../../../../shared/src/api/v2/relatie';
import { IOphalenAanbodResultElement } from '../../../../../../../../../../../shared/src/api/v2/aanbod/index';
import { IOphalenProductmodellenResultElement } from '../../../../../../../../../../../shared/src/api/v2/aanbod/productmodel';

export interface IContext {
  contract: IOphalenContractenResultElementV2 | null;
  productmodellenTarieven: IOphalenAanbodResultElement[] | null;
  rekeningen: IOphalenRekeningenResultElement[] | null;
  cntID: number[] | null;
  locatieNiveaus: IOphalenLocatieNiveausResultElement[] | null;
  wisselContractenOpties: IOphalenContractenResultElementV2[] | null;
  factuurkenmerken: IOphalenFactuurkenmerkenResultElement[] | null;
  nieuweFactuurkenmerkVaststellen: (kenmerk: string) => void;
  vastgesteldeFactuurkenmerken: string[];
  productmodellen: IOphalenProductmodellenResultElement[] | null;
}

const ContractContext = React.createContext<IContext>({
  contract: null,
  productmodellenTarieven: null,
  rekeningen: null,
  cntID: null,
  locatieNiveaus: null,
  wisselContractenOpties: null,
  factuurkenmerken: null,
  nieuweFactuurkenmerkVaststellen: null as any,
  vastgesteldeFactuurkenmerken: null as any,
  productmodellen: null as any,
});

export default ContractContext;
