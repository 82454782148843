import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
  IBulkVerzendBericht,
  IBulkVerzendBerichtEmail,
  IBulkVerzendBerichtPrioriteit,
  IBulkVerzendBerichtSms,
  IBulkVerzendBerichtTijdvak,
  IBulkVerzendBerichtTijdvakDag,
} from '../../../../../../shared/src/api/v2/bulkVerzending';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../models/IRemoteData';
import api, { IPaginatiePositie } from '../../../../api';
import MenuLayout from '../../../../components/MenuLayout';
import ASPTabel from '../../../../components/tabel/ASPTabel';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import { ASPKolom, EAspKolomBreedteType } from '../../../../components/tabel/ASPTabel/types';
import EmailComponent from '../../../../components/communicatie/EmailComponent';
import TelefoonComponent from '../../../../components/communicatie/TelefoonComponent';
import { format } from 'date-fns';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import {
  IconKruis,
  IconLijst,
  IconPijlOmhoog,
  IconPijlOmlaag,
  IconPlay,
  IconTimer,
  IconVink,
} from '../../../../components/Icons';
import UitgeklapteRij from './UitgeklapteRij';
import FilterBalkV2, { IFilter, IFilterData } from '../../../../components/FilterBalkV2';
import StatusFilter from './StatusFilter';
import { IFilterSchemaFilter } from '../../../../../../shared/src/models/filter';
import { IBulkbericht } from '../../../../../../shared/src/api/v2/bulkbericht';
import BulkberichtFilter from './BulkberichtFilter';
import VerticaleScheidingslijn from '../../../../components/layout/VerticaleScheidingslijn';
import { RootStoreContext } from '../../../../stores/RootStore';
import { EResultType } from '../../../../stores/CheckStore';
import BulkBerichtTijdvakVisualisatie, {
  IBulkBerichtTijdvakVisualisatieData,
} from '../../../../components/entiteitVisualisaties/BulkBerichtTijdvakVisualisatie';

const STANDAARD_PAGINATIE: IPaginatiePositie = {
  index: 0,
  aantal: 50,
};

export type BerichtenKolom =
  | 'type'
  | 'bulkbericht'
  | 'status'
  | 'prioriteit'
  | 'tijdvak'
  | 'aan'
  | 'preview'
  | 'toegevoegd_op';

export type BerichtenFilter = 'status' | 'bulkbericht';

interface IData {
  berichten: { [index: number]: IBulkVerzendBericht };
  totaalAantal: number;
  emailBerichtBijBulkVerzenderQueueId: { [id: number]: IBulkVerzendBerichtEmail };
  smsBerichtBijBulkVerzenderQueueId: { [id: number]: IBulkVerzendBerichtSms };
  bulkberichtenBijId: { [id: number]: IBulkbericht };
  prioriteitenBijID: { [id: number]: IBulkVerzendBerichtPrioriteit };
  tijdvakkenBijID: { [id: number]: IBulkVerzendBerichtTijdvak };
  tijdvakDagenBijTijdvakID: { [id: number]: IBulkVerzendBerichtTijdvakDag[] };
}

export interface IBerichtenContext {
  data: IRemoteData<IData>;
}

export const BerichtenContext = React.createContext<IBerichtenContext>(null!);

interface IProps extends RouteComponentProps {}

const Berichten = (props: IProps) => {
  const { checkStore } = useContext(RootStoreContext);
  const [data, setData] = useState<IRemoteData<IData>>(createPendingRemoteData());
  const dataRef = useRef(data);
  const setDataRef = useCallback((data: IRemoteData<IData>) => {
    dataRef.current = data;
    setData(data);
  }, []);

  const [isBezig, setIsBezig] = useState(false);
  const [uitgeklapt, setUitgeklapt] = useState<number[]>([]);
  const [selectie, setSelectie] = useState<number[]>([]);

  const [filterData, setFilterData] = useState<IFilterData<BerichtenFilter>[]>([
    {
      naam: 'bulkbericht',
      data: null,
      isActief: false,
    },
    {
      naam: 'status',
      data: 'in_review',
      isActief: false,
    },
  ]);

  const ophalenData = useCallback(
    async (paginatie: IPaginatiePositie, uitbreiden = false) => {
      setIsBezig(true);

      const filters = filterData
        .filter((filter) => filter.isActief && !filter.isLokaleFilter)
        .map(
          (data): IFilterSchemaFilter => {
            switch (data.naam) {
              case 'status': {
                return {
                  naam: 'STATI',
                  data: [data.data],
                };
              }
              case 'bulkbericht': {
                return {
                  naam: 'BULKBERICHT_IDS',
                  data: data.data === null ? [] : [data.data],
                };
              }
            }
          },
        );

      const berichtenResult = await api.v2.bulkVerzending.ophalenBulkVerzendBerichten({
        paginatie,
        filterSchema: {
          filters,
        },
      });
      const berichtIDs = berichtenResult.items.map((item) => item.ID);
      const tijdvakIDs = berichtenResult.items
        .map((item) => item.TijdvakID)
        .filter((x) => x !== null);
      const bulkberichtIDs = berichtenResult.items
        .map((item) => item.BulkberichtID)
        .filter((x) => {
          if (x === null) {
            return false;
          }
          // Controleer of we dit bulkbericht al op hebben gehaald, zo wel dan niet opnieuw ophalen
          return dataRef.current.data?.bulkberichtenBijId[x] === undefined;
        }) as number[];

      const bepalenEmailBerichten = async () => {
        if (berichtIDs.length === 0) {
          return null;
        }

        return await api.v2.bulkVerzending.ophalenBulkVerzendBerichtEmails({
          filterSchema: {
            filters: [
              {
                naam: 'BULK_VERZENDER_QUEUE_IDS',
                data: berichtIDs,
              },
            ],
          },
        });
      };

      const bepalenSmsBerichten = async () => {
        if (berichtIDs.length === 0) {
          return null;
        }

        return await api.v2.bulkVerzending.ophalenBulkVerzendBerichtSms({
          filterSchema: {
            filters: [
              {
                naam: 'BULK_VERZENDER_QUEUE_IDS',
                data: berichtIDs,
              },
            ],
          },
        });
      };

      const bepaalBulkberichten = async () => {
        if (bulkberichtIDs.length === 0) {
          return null;
        }

        return await api.v2.bulkbericht.ophalenBulkberichten({
          filterSchema: {
            filters: [
              {
                naam: 'IDS',
                data: bulkberichtIDs,
              },
            ],
          },
        });
      };

      const bepaalPrioriteiten = async () => {
        return await api.v2.bulkVerzending.ophalenBulkVerzendBerichtPrioriteiten({});
      };

      const bepaalTijdvakkenEnDagen = async () => {
        if (tijdvakIDs.length === 0) {
          return null;
        }

        const [tijdvakkenResult, tijdvakDagenResult] = await Promise.all([
          api.v2.bulkVerzending.ophalenBulkVerzendBerichtTijdvakken({
            filterSchema: {
              filters: [
                {
                  naam: 'IDS',
                  data: tijdvakIDs,
                },
              ],
            },
          }),
          api.v2.bulkVerzending.ophalenBulkVerzendBerichtTijdvakDagen({
            filterSchema: {
              filters: [
                {
                  naam: 'TIJDVAK_IDS',
                  data: tijdvakIDs,
                },
              ],
            },
          }),
        ]);

        return {
          tijdvakkenResult,
          tijdvakDagenResult,
        };
      };

      const [
        emailBerichtenResult,
        smsBerichtenResult,
        bulkberichtenResult,
        prioriteitenResult,
        bepaalTijdvakkenEnDagenOutput,
      ] = await Promise.all([
        bepalenEmailBerichten(),
        bepalenSmsBerichten(),
        bepaalBulkberichten(),
        bepaalPrioriteiten(),
        bepaalTijdvakkenEnDagen(),
      ]);

      const base = uitbreiden ? dataRef.current.data?.berichten ?? {} : {};
      const berichten = berichtenResult.items.reduce(
        (acc, item, i) => {
          const index = paginatie.index + i;
          acc[index] = item;
          return acc;
        },
        { ...base },
      );

      const emailBerichtBijBulkVerzenderQueueIdBase = uitbreiden
        ? dataRef.current.data?.emailBerichtBijBulkVerzenderQueueId ?? {}
        : {};
      const emailBerichtBijBulkVerzenderQueueId =
        emailBerichtenResult === null
          ? emailBerichtBijBulkVerzenderQueueIdBase
          : emailBerichtenResult.items.reduce((acc, item) => {
              acc[item.BulkVerzenderQueueID] = item;
              return acc;
            }, emailBerichtBijBulkVerzenderQueueIdBase);

      const smsBerichtBijBulkVerzenderQueueIdBase = uitbreiden
        ? dataRef.current.data?.smsBerichtBijBulkVerzenderQueueId ?? {}
        : {};
      const smsBerichtBijBulkVerzenderQueueId =
        smsBerichtenResult === null
          ? smsBerichtBijBulkVerzenderQueueIdBase
          : smsBerichtenResult.items.reduce((acc, item) => {
              acc[item.BulkVerzenderQueueID] = item;
              return acc;
            }, smsBerichtBijBulkVerzenderQueueIdBase);

      const bulkberichtenBijIdBase = uitbreiden
        ? dataRef.current.data?.bulkberichtenBijId ?? {}
        : {};
      const bulkberichtenBijId =
        bulkberichtenResult === null
          ? bulkberichtenBijIdBase
          : bulkberichtenResult.items.reduce((acc, item) => {
              acc[item.ID] = item;
              return acc;
            }, bulkberichtenBijIdBase);

      const prioriteitenBijIDBase = uitbreiden ? dataRef.current.data?.prioriteitenBijID ?? {} : {};
      const prioriteitenBijID =
        prioriteitenResult === null
          ? prioriteitenBijIDBase
          : prioriteitenResult.items.reduce((acc, item) => {
              acc[item.ID] = item;
              return acc;
            }, prioriteitenBijIDBase);

      const tijdvakkenBijIDBase = uitbreiden ? dataRef.current.data?.tijdvakkenBijID ?? {} : {};
      const tijdvakkenBijID =
        bepaalTijdvakkenEnDagenOutput === null
          ? tijdvakkenBijIDBase
          : bepaalTijdvakkenEnDagenOutput.tijdvakkenResult.items.reduce((acc, item) => {
              acc[item.ID] = item;
              return acc;
            }, tijdvakkenBijIDBase);

      const tijdvakDagenBijTijdvakIDBase = uitbreiden
        ? dataRef.current.data?.tijdvakDagenBijTijdvakID ?? {}
        : {};
      const tijdvakDagenBijTijdvakID =
        bepaalTijdvakkenEnDagenOutput === null
          ? tijdvakDagenBijTijdvakIDBase
          : bepaalTijdvakkenEnDagenOutput.tijdvakDagenResult.items.reduce((acc, item) => {
              const arr = acc[item.TijdvakID] ?? [];
              arr.push(item);
              acc[item.TijdvakID] = arr;
              return acc;
            }, tijdvakDagenBijTijdvakIDBase);

      setDataRef(
        createReadyRemoteData({
          berichten,
          totaalAantal: berichtenResult.totaalAantal,
          emailBerichtBijBulkVerzenderQueueId,
          smsBerichtBijBulkVerzenderQueueId,
          bulkberichtenBijId,
          prioriteitenBijID,
          tijdvakkenBijID,
          tijdvakDagenBijTijdvakID,
        }),
      );

      setIsBezig(false);
    },
    [filterData],
  );

  useEffect(() => {
    ophalenData(STANDAARD_PAGINATIE);
  }, [ophalenData]);

  const verversenData = useCallback(async () => {
    await ophalenData(STANDAARD_PAGINATIE, true);
  }, [ophalenData]);

  const tijdvakVisualisatieDatas = useMemo<
    IRemoteData<{
      [tijdvakId: number]: IBulkBerichtTijdvakVisualisatieData;
    }>
  >(() => {
    if (dataRef.current.state === ERemoteDataState.Pending) {
      return createPendingRemoteData();
    }
    const { tijdvakkenBijID, tijdvakDagenBijTijdvakID } = dataRef.current.data!;
    const data = Object.keys(tijdvakkenBijID).reduce<{
      [tijdvakId: number]: IBulkBerichtTijdvakVisualisatieData;
    }>((acc, key) => {
      const id = parseInt(key, 10);

      const tijdvak = tijdvakkenBijID[id];
      const dagen = tijdvakDagenBijTijdvakID[id] ?? [];

      acc[id] = {
        tijdvak,
        dagen,
      };

      return acc;
    }, {});

    return createReadyRemoteData(data);
  }, [dataRef.current.data?.tijdvakkenBijID, dataRef.current.data?.tijdvakDagenBijTijdvakID]);

  const keyExtractor = useCallback((row: IBulkVerzendBericht) => row.ID, []);

  const kolommen = useMemo<ASPKolom<BerichtenKolom, IBulkVerzendBericht>[]>(
    () => [
      {
        key: 'type',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 125,
        label: 'Type',
        renderer: (row) => {
          const emailBericht = dataRef.current.data!.emailBerichtBijBulkVerzenderQueueId[row.ID];
          const smsBericht = dataRef.current.data!.smsBerichtBijBulkVerzenderQueueId[row.ID];

          if (emailBericht !== undefined) {
            return 'Email';
          }

          if (smsBericht !== undefined) {
            return 'SMS';
          }

          throw new Error(`Onbekend bericht type voor bulk bericht queue id '${row.ID}'`);
        },
      },
      {
        key: 'bulkbericht',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 125,
        label: 'Bulkbericht',
        renderer: (row) => {
          const bulkbericht =
            row.BulkberichtID === null
              ? null
              : dataRef.current.data!.bulkberichtenBijId[row.BulkberichtID] ?? null;

          if (bulkbericht === null) {
            return '- -';
          }

          return bulkbericht.Nummer;
        },
      },
      {
        key: 'status',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 175,
        label: 'Status',
        renderer: (row) => {
          switch (row.Status) {
            case 'in_review':
              return (
                <div
                  className="d-flex align-items-center"
                  title="Moet handmatig worden goedgekeurd"
                >
                  <IconLijst style={{ fill: Kleur.Paars, width: 18, height: 18 }} />
                  <span style={{ color: Kleur.Paars }} className="ml-2">
                    In review
                  </span>
                </div>
              );
            case 'in_afwachting':
              return (
                <div
                  className="d-flex align-items-center"
                  title="Klaar voor het oppakken voor de verzender"
                >
                  <IconTimer style={{ fill: Kleur.Blauw, width: 18, height: 18 }} />
                  <span style={{ color: Kleur.Blauw }} className="ml-2">
                    In afwachting
                  </span>
                </div>
              );
            case 'in_behandeling':
              return (
                <div
                  className="d-flex align-items-center"
                  title="Wordt momenteel door de verzender in behandeling genomen"
                >
                  <IconPlay style={{ fill: Kleur.Oranje, width: 18, height: 18 }} />
                  <span style={{ color: Kleur.Oranje }} className="ml-2">
                    In behandeling
                  </span>
                </div>
              );
            case 'gelukt':
              return (
                <div className="d-flex align-items-center" title="Bericht is verstuurd">
                  <IconVink style={{ fill: Kleur.Groen, width: 18, height: 18 }} />
                  <span style={{ color: Kleur.Groen }} className="ml-2">
                    Gelukt
                  </span>
                </div>
              );
            case 'mislukt':
              return (
                <div
                  className="d-flex align-items-center"
                  title="Bericht verzenden is afgebroken met een foutmelding"
                >
                  <IconKruis style={{ fill: Kleur.Rood, width: 18, height: 18 }} />
                  <span style={{ color: Kleur.Rood }} className="ml-2">
                    Mislukt
                  </span>
                </div>
              );
          }
        },
      },
      {
        key: 'prioriteit',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 125,
        label: 'Prioriteit',
        renderer: (row) => {
          if (row.PrioriteitID === null) {
            return '- -';
          }
          const prioriteit = dataRef.current.data!.prioriteitenBijID[row.PrioriteitID]!;
          switch (prioriteit.NaamEnum) {
            case 'LAAG':
              return (
                <div className="d-flex align-items-center">
                  <span className="mr-2">Laag</span>
                  <IconPijlOmlaag style={{ fill: Kleur.Grijs, width: 16, height: 16 }} />
                </div>
              );
            case 'NORMAAL':
              return 'Normaal';
            case 'HOOG':
              return (
                <div className="d-flex align-items-center">
                  <span className="mr-2">Hoog</span>
                  <IconPijlOmhoog style={{ fill: Kleur.Grijs, width: 16, height: 16 }} />
                </div>
              );
          }
        },
      },
      {
        key: 'tijdvak',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 150,
        label: 'Tijdvak',
        renderer: (row) => {
          const visualisatieData =
            row.TijdvakID === null ? null : tijdvakVisualisatieDatas.data![row.TijdvakID];
          if (visualisatieData === null) {
            return '- -';
          }
          return (
            <BulkBerichtTijdvakVisualisatie
              tijdvakID={visualisatieData.tijdvak.ID}
              data={visualisatieData}
            />
          );
        },
      },
      {
        key: 'aan',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 300,
        label: 'Aan',
        renderer: (row) => {
          const emailBericht = dataRef.current.data!.emailBerichtBijBulkVerzenderQueueId[row.ID];
          const smsBericht = dataRef.current.data!.smsBerichtBijBulkVerzenderQueueId[row.ID];

          if (emailBericht !== undefined) {
            return <EmailComponent emailAdres={emailBericht.Emailadres} />;
          }

          if (smsBericht !== undefined) {
            return <TelefoonComponent telefoonNummer={smsBericht.Telefoonnummer} />;
          }

          throw new Error(`Onbekend bericht type voor bulk bericht queue id '${row.ID}'`);
        },
      },
      {
        key: 'preview',
        breedteType: EAspKolomBreedteType.Flex,
        flex: 1,
        label: 'Preview',
        renderer: (row) => {
          const emailBericht = dataRef.current.data!.emailBerichtBijBulkVerzenderQueueId[row.ID];
          const smsBericht = dataRef.current.data!.smsBerichtBijBulkVerzenderQueueId[row.ID];

          if (emailBericht !== undefined) {
            return <span>{emailBericht.Onderwerp}</span>;
          }

          if (smsBericht !== undefined) {
            return <span>{smsBericht.Bericht}</span>;
          }

          throw new Error(`Onbekend bericht type voor bulk bericht queue id '${row.ID}'`);
        },
      },
      {
        key: 'toegevoegd_op',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 200,
        label: 'Toegevoegd op',
        renderer: (row) => {
          if (row.RecordToegevoegd === null) {
            return null;
          }

          return format(new Date(row.RecordToegevoegd), 'dd-MM-yyyy HH:mm:ss');
        },
      },
    ],
    [tijdvakVisualisatieDatas],
  );

  const handleExtraRijenAangevraagd = useCallback(
    async (paginatie: IPaginatiePositie) => {
      await ophalenData(paginatie, true);
    },
    [ophalenData],
  );

  const filters = useMemo<IFilter<BerichtenFilter>[]>(
    () => [
      {
        naam: 'bulkbericht',
        altijdWeergevenInBalk: true,
        weergave: BulkberichtFilter,
      },
      {
        naam: 'status',
        altijdWeergevenInBalk: true,
        weergave: StatusFilter,
      },
    ],
    [],
  );

  const berichtenContext = useMemo<IBerichtenContext>(() => ({ data }), [data]);

  const bulkberichtFilter = useMemo(() => {
    const bulkberichtFilterIndex = filterData.findIndex((x) => x.naam === 'bulkbericht');
    if (bulkberichtFilterIndex === -1) {
      throw new Error('Bulkbericht filter niet gevonden');
    }
    return filterData[bulkberichtFilterIndex];
  }, [filterData]);

  const bulkberichtFilterIsAlsEnigeActief = useMemo(() => {
    return (
      bulkberichtFilter.isActief &&
      !filterData.some((x) => x.isActief && x.naam !== bulkberichtFilter.naam)
    );
  }, [bulkberichtFilter, filterData]);

  const reviewknoppenData = useMemo<{
    knopEnabled: boolean;
    disabledTitle: string | undefined;
  }>(() => {
    if (isBezig) {
      return {
        knopEnabled: false,
      };
    }
    if (data.state === ERemoteDataState.Pending) {
      return {
        knopEnabled: false,
      };
    }
    if (!bulkberichtFilterIsAlsEnigeActief) {
      return {
        knopEnabled: false,
        disabledTitle:
          'Je dient eerst een bulkbericht te selecteren om deze actie uit te voeren (alleen mogelijk als er geen andere filters actief zijn)',
      };
    }
    if (data.data!.totaalAantal === 0) {
      return {
        knopEnabled: false,
        disabledTitle: 'Er zijn geen berichten om te reviewen',
      };
    }
    if (Object.values(data.data!.berichten)[0].Status !== 'in_review') {
      return {
        knopEnabled: false,
        disabledTitle: 'De bijhorende berichten zijn niet in review status',
      };
    }

    return {
      knopEnabled: true,
    };
  }, [bulkberichtFilterIsAlsEnigeActief, data, isBezig]);

  const reviewAccepterenClick = useCallback(async () => {
    const bulkberichtID = Object.values(data.data!.berichten)[0].BulkberichtID!;

    if (
      (
        await checkStore.bevestigen({
          inhoud: 'Bevestig accepteren berichten van bulkbericht',
          asynchroneActieNaBevestigingFn: async () => {
            await api.v2.bulkVerzending.bulkberichtReviewAccepteren({
              bulkberichtID,
            });
            await verversenData();
          },
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }
  }, [data, verversenData]);

  const reviewAfkeurenClick = useCallback(async () => {
    const bulkberichtID = Object.values(data.data!.berichten)[0].BulkberichtID!;

    if (
      (
        await checkStore.bevestigen({
          inhoud: 'Bevestig afkeuren berichten van bulkbericht',
          asynchroneActieNaBevestigingFn: async () => {
            await api.v2.bulkVerzending.bulkberichtReviewWeigeren({
              bulkberichtID,
            });
            await verversenData();
          },
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }
  }, [data, verversenData]);

  if (dataRef.current.state === ERemoteDataState.Pending) {
    return (
      <div className="flex-fill d-flex align-items-center justify-content-center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <BerichtenContext.Provider value={berichtenContext}>
      <MenuLayout
        menu={
          <div className="d-flex align-items-center">
            <button
              className="btn btn-sm btn-light"
              style={{
                border: `1px solid ${Kleur.LichtGrijs}`,
                width: 180,
              }}
              disabled={!reviewknoppenData.knopEnabled}
              title={
                reviewknoppenData.knopEnabled
                  ? 'Accepteer alle berichten van dit bulkbericht (verplaatsen naar de volgende stap)'
                  : reviewknoppenData.disabledTitle
              }
              onClick={reviewAccepterenClick}
            >
              <IconVink style={{ fill: Kleur.Groen, width: 16, height: 16 }} />
              <span className="ml-2">Review accepteren</span>
            </button>
            <button
              className="btn btn-sm btn-light ml-3"
              style={{
                border: `1px solid ${Kleur.LichtGrijs}`,
                width: 180,
              }}
              disabled={!reviewknoppenData.knopEnabled}
              title={
                reviewknoppenData.knopEnabled
                  ? 'Verwijder alle berichten van dit bulkbericht'
                  : reviewknoppenData.disabledTitle
              }
              onClick={reviewAfkeurenClick}
            >
              <IconKruis style={{ fill: Kleur.Rood, width: 16, height: 16 }} />
              <span className="ml-2">Review afkeuren</span>
            </button>
            <VerticaleScheidingslijn className="ml-4 mr-2" />
            <FilterBalkV2
              filters={filters}
              filterData={filterData}
              onFilterDataChange={setFilterData}
            />
          </div>
        }
        body={
          <ASPTabel
            keyExtractor={keyExtractor}
            kolommen={kolommen}
            rijen={dataRef.current.data!.berichten}
            totaalAantalRijen={dataRef.current.data!.totaalAantal}
            onExtraRijenAangevraagd={handleExtraRijenAangevraagd}
            uitgeklapt={uitgeklapt}
            onUitgeklaptChange={setUitgeklapt}
            selectie={selectie}
            onSelectieChange={setSelectie}
            uitgeklapteRijComponent={UitgeklapteRij}
            uitgeklapteRijHoogte={800}
            statusbalkWeergeven
          />
        }
      />
    </BerichtenContext.Provider>
  );
};

export default Berichten;
