import { makeObservable, observable } from 'mobx';

class ToetsenbordStore {
  public shift: boolean = false;
  public ctrl: boolean = false;
  public alt: boolean = false;
  public meta: boolean = false;
  public key: string | null = null;

  constructor() {
    makeObservable(this, {
      shift: observable,
      ctrl: observable,
      alt: observable,
      meta: observable,
      key: observable,
    });
  }

  public setShift = (pressed: boolean) => (this.shift = pressed);
  public setCtrl = (pressed: boolean) => (this.ctrl = pressed);
  public setAlt = (pressed: boolean) => (this.ctrl = pressed);
  public setMeta = (pressed: boolean) => (this.meta = pressed);
  public setKey = (key: string | null) => {
    this.key = key;
  };
}

export default ToetsenbordStore;
