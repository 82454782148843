import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ITabbladProps } from '../../KoppelVoorraadProductDialoog';
import {
  DXTableCheckboxComponent,
  DXTableToggleCellComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../../../helpers/dxTableGrid';
import LoadingSpinner from '../../../../../../components/Gedeeld/LoadingSpinner';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import { RowDetailState, SelectionState, DataTypeProvider } from '@devexpress/dx-react-grid';
import { IOphalenVerkoopmodellenResultElement } from '../../../../../../../../shared/src/api/v2/aanbod/verkoop';
import nameOf from '../../../../../../core/nameOf';
import FormatteerBedrag from '../../../../../../components/MutatieBedrag';

interface IProps extends ITabbladProps {}

const geenData = {
  noData: 'Geen bulkproducten gevonden',
};

const Bulk: React.FC<IProps> = (props) => {
  const { verkModID, onVerkModIDChange, bulkproducten } = props;

  const kolommen = useMemo<TypedColumn<IOphalenVerkoopmodellenResultElement>[]>(
    () => [
      {
        name: 'Naam',
        title: 'Naam',
      },
      {
        name: 'Prijs',
        title: 'Prijs',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IOphalenVerkoopmodellenResultElement>[]>(
    () => [
      {
        columnName: 'Naam',
        width: 350,
      },
      {
        columnName: 'Prijs',
        width: 150,
      },
    ],
    [],
  );

  const keyExtractor = useCallback(
    (row: IOphalenVerkoopmodellenResultElement) => row.VerkModID,
    [],
  );

  return bulkproducten === null ? (
    <LoadingSpinner />
  ) : (
    <GridStyleWrapper maxHeight={300}>
      <Grid columns={kolommen} getRowId={keyExtractor} rows={bulkproducten}>
        <DataTypeProvider
          for={[nameOf<IOphalenVerkoopmodellenResultElement>('Prijs')]}
          formatterComponent={(formatterProps) => {
            if (formatterProps.value === null) {
              return <span></span>;
            }
            return <FormatteerBedrag bedrag={formatterProps.value} />;
          }}
        />

        <VirtualTable messages={geenData} />
        <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
        <TableHeaderRow />
        {/* <RowDetailState defaultExpandedRowIds={[]} />
        <TableRowDetail toggleCellComponent={DXTableToggleCellComponent} /> */}

        <SelectionState
          selection={verkModID === null ? [] : [verkModID]}
          // Maar een element selecteren per keer, dus pakken bij een deselectie, een lege array
          // of bij een selectie het laatst geselecteerde element als het enige element
          onSelectionChange={(x) =>
            onVerkModIDChange((x.length > 0 ? x[x.length - 1] : null) as number)
          }
        />

        <TableSelection cellComponent={DXTableCheckboxComponent} />
      </Grid>
    </GridStyleWrapper>
  );
};

export default Bulk;
