import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import HorizontaleScheidingslijn from '../../../layout/HorizontaleScheidingslijn';
import * as _ from 'lodash';
import api from '../../../../api';
import { converteerString } from '../../../../core/veldConversie';
import { IOphalenPersonenResultElementV2 } from '../../../../../../shared/src/api/v2/persoon/persoon';
import { formatteerPersoonNaam } from '../../../../helpers';
import PersoonFormulier, { IFormValues } from '../../../formulier/PersoonFormulier';
import { format } from 'date-fns';
import { ITabbladProps, PersoonSelectieDialoogContext } from '../index';

const NieuwOfSelecterenContent = observer((props: ITabbladProps) => {
  const persoonSelectieDialoogContext = useContext(PersoonSelectieDialoogContext);
  const {
    onPersIDChange,
    onFormulierChange,
    onFormulierIsValidChange,
    defaultFormulierWaarden,
  } = persoonSelectieDialoogContext;

  const [formulierWaarden, setFormulierWaarden] = useState<IFormValues>(
    defaultFormulierWaarden ?? {
      email: '',
      telefoon: null,
      telefoonExtra: null,
      achternaam: '',
      voorletters: '',
      voorvoegsel: '',
      voornaam: '',
      geslacht: null,
      taalID: null,
      overleden: false,
      geboortedatum: null,
      geboorteplaats: '',
      aanheftype: 0,
      notities: '',
    },
  );
  const [selectie, setSelectie] = useState<IOphalenPersonenResultElementV2[] | null>(null);

  const throttle = useRef(null);

  const selecterenPersonen = useCallback(async () => {
    const {
      achternaam,
      email,
      geboortedatum,
      geboorteplaats,
      geslacht,
      overleden,
      taalID,
      telefoon,
      voorletters,
      voornaam,
      voorvoegsel,
    } = formulierWaarden;
    const params = {
      voorvoegsel: converteerString(voorvoegsel),
      achternaam: converteerString(achternaam),
      email: converteerString(email),
      geboortedatum: null,
      geslacht,
      paginatie: {
        index: 0,
        aantal: 5,
      },
      telefoonnummer: converteerString(telefoon ?? ''),
      voorletters: converteerString(voorletters),
      voornaam: converteerString(voornaam),
    };
    // const selectie = await api.v2.persoon.selecterenPersonen(params);

    const zoekParams = {
      geslacht: geslacht !== null ? geslacht : undefined,
      voornaam: voornaam !== null && voornaam !== '' ? converteerString(voornaam) : undefined,
      voorletters:
        voorletters !== null && voorletters !== '' ? converteerString(voorletters) : undefined,
      voorvoegsel:
        voorvoegsel !== null && voorvoegsel !== '' ? converteerString(voorvoegsel) : undefined,
      achternaam:
        achternaam !== null && achternaam !== '' ? converteerString(achternaam) : undefined,
      email: email !== null && email !== '' ? converteerString(email) : undefined,
      telefoonnummer: telefoon !== null && telefoon !== '' ? converteerString(telefoon) : undefined,
      geboortedatum: undefined,
    };

    // const filter = [{ naam: 'ZOEKCRITERIA', data: zoekParams }];
    const filter =
      geslacht === null &&
      (voornaam === null || voornaam === '') &&
      (voorletters === null || voorletters === '') &&
      (voorvoegsel === null || voorvoegsel === '') &&
      (achternaam === null || achternaam === '') &&
      (email === null || email === '') &&
      (telefoon === null || telefoon === '') &&
      geboortedatum === null
        ? [
            { naam: 'ZOEKCRITERIA', data: null },
            { naam: 'IS_VERVANGEN', data: false },
          ]
        : [
            { naam: 'ZOEKCRITERIA', data: zoekParams },
            { naam: 'IS_VERVANGEN', data: false },
          ];

    const personenResult = await api.v2.persoon.ophalenPersonen({
      filterSchema: {
        filters: filter,
      },
      paginatie: {
        index: 0,
        aantal: 5,
      },
    });

    setSelectie(personenResult.personen);
    props.onSelectieBepaald(personenResult.personen);
  }, [formulierWaarden, setSelectie, props.onSelectieBepaald]);

  useEffect(() => {
    if (throttle.current !== null) {
      (throttle.current as any).cancel();
    }
    throttle.current = _.throttle(selecterenPersonen, 1000);
    (throttle.current as any)();
  }, [formulierWaarden]);

  const handlePersoonFormulierChange = useCallback(
    (formulier: IFormValues) => {
      setFormulierWaarden(formulier);
      onFormulierChange(formulier);
    },
    [setFormulierWaarden, onFormulierChange],
  );

  return (
    <div className="p-3">
      <PersoonFormulier
        values={formulierWaarden}
        onIsValidChange={onFormulierIsValidChange}
        onChange={handlePersoonFormulierChange}
      />
      {selectie !== null && selectie.length > 0 && (
        <>
          <div className="mb-3 mt-3">
            <HorizontaleScheidingslijn />
          </div>
          <span>Bedoel je misschien?</span>
          <div style={{ width: '100%', overflow: 'auto' }}>
            <table style={{ width: '100%' }}>
              {selectie.map((p) => (
                <tr key={p.PersID}>
                  <td>
                    <a href="#" onClick={() => onPersIDChange(p.PersID)}>
                      {formatteerPersoonNaam({
                        aanhefKort: p.geslacht.AanhefKort,
                        achternaam: p.Achternaam,
                        voorletters: p.Voorletters,
                        voornaam: p.Voornaam,
                        voorvoegsel: p.Voorvoegsel,
                      })}
                    </a>
                  </td>
                  <td>{p.TelefoonMobiel}</td>
                  <td>{p.Email}</td>
                  <td>
                    {p.Geboortedatum !== null
                      ? format(new Date(p.Geboortedatum), 'dd-MM-yyyy')
                      : ''}
                  </td>
                </tr>
              ))}
            </table>
          </div>
        </>
      )}
    </div>
  );
});

export default NieuwOfSelecterenContent;
