import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import IDialoogProps from '../../../../../core/IDialoogProps';
import Dialoog from '../../../../dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import ModalBody from 'react-bootstrap/ModalBody';
import api from '../../../../../api';
import nameof from '../../../../../core/nameOf';
import * as Yup from 'yup';
import FormikVeldFout from '../../../../formulier/FormikVeldFout';
import teksten from '../../../../../bedrijfslogica/teksten';
import DatumKiezer from '../../../../formulier/DatumKiezer';
import Combobox, { IOptie } from '../../../../formulier/Combobox';
import { IOphalenPersonenResultElementV2 } from '../../../../../../../shared/src/api/v2/persoon/persoon';
import LoadingSpinner from '../../../../Gedeeld/LoadingSpinner';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../../../stores/RootStore';
import { EResultType } from '../../../../../stores/CheckStore';

interface IProps extends IDialoogProps<null> {
  persID?: number;
  orgID?: number;
}

enum EGeslacht {
  Onbekend,
  Man,
  Vrouw,
}

interface IFormikValues {
  initialen: string;
  achternaam: string;
  geboortedatum: Date | null;
  geslacht: EGeslacht;
  huisnummer: string;
  postcode: string;
  voorvoegsel: string;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  initialen: 'Initialen',
  achternaam: 'Achternaam',
  geboortedatum: 'Geboortedatum',
  geslacht: 'Geslacht',
  huisnummer: 'Huisnummer',
  postcode: 'Postcode',
  voorvoegsel: 'Voorvoegsel',
};

const initialenRegexp = /([A-Z].)+/g;

const KredietwaardigheidToetsDialoog: React.FC<IProps> = observer((props) => {
  const { children, dialoogIndex, onAnnuleren, onSuccess, open, orgID, persID } = props;
  const { klantkaartStore, checkStore } = useContext(RootStoreContext);

  const [persoon, setPersoon] = useState<IOphalenPersonenResultElementV2 | null>(null);

  useEffect(() => {
    if (persID === undefined) {
      return;
    }

    (async () => {
      const resultPersonen = await api.v2.persoon.ophalenPersonen({
        filterSchema: { filters: [{ naam: 'IDS', data: [persID] }] },
      });
      setPersoon(resultPersonen.personen[0]);
    })();
  }, [persID]);

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (persoon !== null) {
      return {
        initialen: persoon.Voorletters || '',
        achternaam: persoon.Achternaam || '',
        geboortedatum: persoon.Geboortedatum === null ? null : new Date(persoon.Geboortedatum),
        geslacht: persoon.geslacht.Geslacht,
        // Postcode en huisnummer worden niet opgeslagen bij een persoon
        // daarom gebruiken we de waarden die bij de huidige relatie staan
        huisnummer: String(klantkaartStore.relatie!!.adres!.Huisnummer!),
        postcode: klantkaartStore.relatie!!.adres!.Postcode!,
        voorvoegsel: persoon.Voorvoegsel || '',
      };
    }
    if (persID !== undefined || orgID !== undefined) {
      return null;
    }
    return {
      initialen: '',
      achternaam: '',
      geboortedatum: null,
      geslacht: EGeslacht.Onbekend,
      huisnummer: '',
      postcode: '',
      voorvoegsel: '',
    };
  }, [persoon, persID, orgID]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      if (
        (
          await checkStore.bevestigen({
            inhoud: (
              <span>
                Krediettoets maken?
                <br />
                <br />
                Let op: hieraan zijn (geringe) kosten verbonden.
              </span>
            ),
          })
        ).type === EResultType.Annuleren
      ) {
        return;
      }

      actions.setSubmitting(true);

      const result = await api.v2.kredietwaardigheid.controleerKredietwaardigheid({
        initialen: values.initialen,
        achternaam: values.achternaam,
        geboortedatum: values.geboortedatum === null ? null : values.geboortedatum.toISOString(),
        geslacht:
          values.geslacht === EGeslacht.Onbekend
            ? null
            : values.geslacht === EGeslacht.Man
            ? 'MAN'
            : 'VROUW',
        huisnummer: Number(values.huisnummer),
        postcode: values.postcode,
        persID: props.persID,
        orgID: props.orgID,
        tussenvoegsel: values.voorvoegsel,
      });

      onSuccess(null);
      actions.setSubmitting(false);
    },
    [props.persID, props.orgID, onSuccess],
  );

  const validationSchema = useMemo(() => {
    const fields: Partial<Record<keyof IFormikValues, any>> = {
      initialen: Yup.string().min(
        1,
        teksten.formulier.E_VERPLICHT_VELD({ veldnaam: veldnamen.initialen }),
      ),
      //   .matches(initialenRegexp, {
      //   message: teksten.formulier.E_VOLDOET_NIET_AAN_FORMAAT({
      //     veldnaam: veldnamen.initialen,
      //     voorbeeldFormaat: 'J.',
      //   }),
      //   excludeEmptyString: true,
      // }),
      achternaam: Yup.string().min(
        1,
        teksten.formulier.E_VERPLICHT_VELD({ veldnaam: veldnamen.achternaam }),
      ),
      geboortedatum: Yup.date().nullable(),
      geslacht: Yup.number().nullable(),
      huisnummer: Yup.string().min(
        1,
        teksten.formulier.E_VERPLICHT_VELD({ veldnaam: veldnamen.huisnummer }),
      ),
      //   .matches(/d+/g, {
      //   message: teksten.formulier.E_GEEN_GETAL({ veldnaam: veldnamen.huisnummer }),
      //   excludeEmptyString: true,
      // }),
      postcode: Yup.string().min(
        1,
        teksten.formulier.E_VERPLICHT_VELD({ veldnaam: veldnamen.postcode }),
      ),
      // .matches(REGEX_POSTCODE_NL, {
      // message: teksten.formulier.E_VOLDOET_NIET_AAN_FORMAAT({
      //   veldnaam: veldnamen.postcode,
      //   voorbeeldFormaat: '3512 NH',
      // }),
      // excludeEmptyString: true,
      // }),
      voorvoegsel: Yup.string().nullable(),
    };
    return Yup.object().shape(fields);
  }, []);

  const geslachtOpties = useMemo<IOptie<EGeslacht>[]>(
    () => [
      {
        id: EGeslacht.Onbekend,
        label: 'Onbekend',
      },
      {
        id: EGeslacht.Man,
        label: 'Man',
      },
      {
        id: EGeslacht.Vrouw,
        label: 'Vrouw',
      },
    ],
    [],
  );

  return (
    <Dialoog index={dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Kredietwaardigheid toetsen</ModalTitle>
      </ModalHeader>

      {initialValues === null ? (
        <LoadingSpinner />
      ) : (
        <Formik<IFormikValues>
          enableReinitialize
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          isInitialValid
          render={(formikProps: FormikProps<IFormikValues>) => {
            const { submitForm, isSubmitting, isValid } = formikProps;
            return (
              <>
                <ModalBody>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-3">
                        <label>{veldnamen.initialen}</label>
                        <Field
                          name={nameof<IFormikValues>('initialen')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="J."
                                  {...field}
                                />
                                <FormikVeldFout fieldProps={fieldProps} />
                              </>
                            );
                          }}
                        />
                      </div>
                      <div className="col-12 mt-2">
                        <label>{veldnamen.achternaam}</label>
                        <Field
                          name={nameof<IFormikValues>('achternaam')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <>
                                <input
                                  type="text"
                                  className="form-control"
                                  // placeholder="J."
                                  {...field}
                                />
                                <FormikVeldFout fieldProps={fieldProps} />
                              </>
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-2">
                        <label>{veldnamen.geboortedatum}</label>
                        <Field
                          name={nameof<IFormikValues>('geboortedatum')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <>
                                <DatumKiezer
                                  isClearable
                                  waarde={field.value}
                                  onGewijzigd={(data) => form.setFieldValue(field.name, data)}
                                />
                                <FormikVeldFout fieldProps={fieldProps} />
                              </>
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-2">
                        <label>{veldnamen.geslacht}</label>
                        <Field
                          name={nameof<IFormikValues>('geslacht')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <>
                                <Combobox<EGeslacht>
                                  geselecteerd={field.value}
                                  onSelectieChange={(id) => form.setFieldValue(field.name, id)}
                                  opties={geslachtOpties}
                                />
                                <FormikVeldFout fieldProps={fieldProps} />
                              </>
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-2">
                        <label>{veldnamen.huisnummer}</label>
                        <Field
                          name={nameof<IFormikValues>('huisnummer')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <>
                                <input type="text" className="form-control" {...field} />
                                <FormikVeldFout fieldProps={fieldProps} />
                              </>
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-2">
                        <label>{veldnamen.postcode}</label>
                        <Field
                          name={nameof<IFormikValues>('postcode')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <>
                                <input type="text" className="form-control" {...field} />
                                <FormikVeldFout fieldProps={fieldProps} />
                              </>
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-2">
                        <label>{veldnamen.voorvoegsel}</label>
                        <Field
                          name={nameof<IFormikValues>('voorvoegsel')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <>
                                <input type="text" className="form-control" {...field} />
                                <FormikVeldFout fieldProps={fieldProps} />
                              </>
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </ModalBody>
                <ModalFooter className="d-flex flex-row justify-content-start">
                  <button
                    className="btn btn-primary"
                    style={{ width: 100 }}
                    disabled={isSubmitting || !isValid}
                    onClick={submitForm}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={onAnnuleren}
                    style={{ width: 100 }}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Dialoog>
  );
});

export default KredietwaardigheidToetsDialoog;
