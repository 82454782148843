import React, { useCallback } from 'react';
import { ConnectDropTarget, DropTargetMonitor, useDrop } from 'react-dnd';
import {
  Box,
  DropTargetElement,
  ImportSourceContainer,
  SContainer,
  SItem,
  SItemDeleteButton,
  SItemIcon,
  SItemImage,
  SItemImageContainer,
  SItemLabel,
  SUploadProgress,
  SUploadProgressWrapper,
} from './styles';
import { getIconFile, IconCloud, IconKruis } from '../../Icons';
import { arrayMove, SortableContainer, SortableElement } from 'react-sortable-hoc';
import {
  EBestandDragAndDropZoneBronModus,
  IBestandDragAndZoneBestand,
  IUploadVoortgangVanLokaleBestanden,
} from '../index';
import { EBestandDragAndDropZoneSelectieModus } from '..';
import BijlagenContainer, { BestandType } from '../../BijlagenContainer';
import { NativeTypes } from 'react-dnd-html5-backend';

export interface TargetBoxProps {
  accepts: string[];
  onDrop: (item: { files: any[] }) => void;
  bestanden: IBestandDragAndZoneBestand[];
  onBestandenChange: (bestanden: IBestandDragAndZoneBestand[]) => void;
  uploadProgresses?: IUploadVoortgangVanLokaleBestanden;
  bronmodus: EBestandDragAndDropZoneBronModus;
  onRequestASPDriveSelection: () => void;
  onRequestFileBrowse: () => void;
  allowedFileTypes?: {
    weergaveNaam: string;
    mediaType: string;
  }[];
  selectieModus: EBestandDragAndDropZoneSelectieModus;
}

const dropTargetStyle: React.CSSProperties = {
  display: 'flex',
  flex: 1,
  overflowY: 'auto',
};

interface ISortableItemProps {
  bestand: IBestandDragAndZoneBestand;
  onVerwijderen: () => void;
  uploadProgress: number | null;
}

const filenameRegex = /(?:\.([^.]+))?$/;

const getViewableFilename = (fullFilename: string) => {
  let availableSpace = 10;

  if (fullFilename.length <= availableSpace) {
    return fullFilename;
  }

  const fileParts = filenameRegex.exec(fullFilename);
  const filename: string = fullFilename.replace(fileParts![0], '');
  const extension: string | undefined = fileParts![1];

  if (extension !== undefined) {
    // Bestand heeft geen extensie
    availableSpace = 3;
  }

  const filenameStart = filename.substr(0, availableSpace);
  const lastFilenameCharacter = filename.substr(filename.length - 1, 1);

  let viewableFilename = `${filenameStart}...${lastFilenameCharacter}`;
  if (extension !== undefined) {
    viewableFilename += `.${extension}`;
  }

  return viewableFilename;
};

const TargetBox: React.FC<TargetBoxProps> = (props) => {
  const {
    onDrop,
    bestanden,
    onBestandenChange,
    uploadProgresses,
    bronmodus,
    onRequestASPDriveSelection,
    onRequestFileBrowse,
    allowedFileTypes,
    selectieModus,
  } = props;

  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      drop(item: { files: any[] }) {
        if (onDrop) {
          onDrop(item);
        }
      },
      canDrop(item: any) {
        // console.log('canDrop', item.files, item.items);
        return true;
      },
      hover(item: any) {
        // console.log('hover', item.files, item.items);
      },
      collect: (monitor: DropTargetMonitor) => {
        const item = monitor.getItem() as any;
        if (item) {
          // console.log('collect', item.files, item.items);
        }

        return {
          isOver: monitor.isOver(),
          canDrop: monitor.canDrop(),
        };
      },
    }),
    [props],
  );

  const isActive = canDrop && isOver;

  const onSortEnd = useCallback(
    ({ oldIndex, newIndex }) => {
      onBestandenChange(arrayMove(bestanden, oldIndex, newIndex));
    },
    [bestanden, onBestandenChange],
  );

  const handleVerwijderen = useCallback(
    (index: number) => {
      bestanden.splice(index, 1);
      onBestandenChange([...bestanden]);
    },
    [bestanden, onBestandenChange],
  );

  let boxContent = null;
  let boxStyle: React.CSSProperties = {};
  const bestandenTekst =
    selectieModus === EBestandDragAndDropZoneSelectieModus.Enkel
      ? 'Sleep een bestand'
      : 'Sleep bestanden';

  const toevoegenContent = (
    <div className="d-flex flex-column flex-fill">
      <div className="flex-fill">
        {bronmodus === EBestandDragAndDropZoneBronModus.Alle ? (
          <span>
            <p style={{ fontSize: 15, fontWeight: 'bold' }}>
              {bestandenTekst},&nbsp;
              <span style={{ color: '#5d78ff', cursor: 'pointer' }} onClick={onRequestFileBrowse}>
                blader
              </span>
              &nbsp;of importeer van
            </p>
            <div style={{ fontSize: 13, display: 'flex', justifyContent: 'center' }}>
              <ImportSourceContainer onClick={onRequestASPDriveSelection}>
                <IconCloud />
                <span style={{ marginTop: 5 }}>ASP Drive</span>
              </ImportSourceContainer>
            </div>
          </span>
        ) : bronmodus === EBestandDragAndDropZoneBronModus.DriveBestanden ? (
          <>
            <span>Importeer van</span>
            <div style={{ fontSize: 13, display: 'flex', justifyContent: 'center' }}>
              <ImportSourceContainer onClick={onRequestASPDriveSelection}>
                <IconCloud />
                <span style={{ marginTop: 5 }}>ASP Drive</span>
              </ImportSourceContainer>
            </div>
          </>
        ) : (
          <p style={{ fontSize: 15, fontWeight: 'bold' }}>
            {bestandenTekst}&nbsp;of&nbsp;
            <span style={{ color: '#5d78ff', cursor: 'pointer' }} onClick={onRequestFileBrowse}>
              blader
            </span>
          </p>
        )}
      </div>
      <div>
        {allowedFileTypes !== undefined ? (
          <span style={{ fontSize: 11 }}>
            Alleen&nbsp;
            {allowedFileTypes.map((fileType, i) => {
              const hasNext = allowedFileTypes[i + 1] !== undefined;
              return (
                <span key={fileType.mediaType}>
                  {fileType.weergaveNaam}
                  {hasNext && <span>,&nbsp;</span>}
                </span>
              );
            })}
            &nbsp;bestanden toegestaan.
          </span>
        ) : (
          <span style={{ fontSize: 11 }}>Alle bestandstypen toegestaan.</span>
        )}
      </div>
    </div>
  );

  if (bestanden.length === 0) {
    boxStyle = { justifyContent: 'center' };
    boxContent = (
      <div
        style={{
          border: '1px dashed rgb(223, 223, 223)',
          width: '100%',
          height: '100%',
          padding: '10px 5px',
        }}
        className="d-flex flex-column flex-fill"
      >
        {isActive ? (
          <div
            style={{
              display: 'flex',
              width: '100%',
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <p style={{}}>Laat los om toe te voegen.</p>
          </div>
        ) : (
          toevoegenContent
        )}
      </div>
    );
  } else {
    boxContent = (
      <div
        style={{
          border: '1px dashed rgb(223, 223, 223)',
          width: '100%',
          height: '100%',
          padding: '10px 5px',
        }}
      >
        {toevoegenContent}
        <div className="mt-1">
          <BijlagenContainer
            bestanden={bestanden.map((x) =>
              x.aspDrive !== undefined
                ? {
                    type: BestandType.ASPDrive,
                    bestand: x.aspDrive!,
                  }
                : {
                    type: BestandType.Lokaal,
                    naam: x.lokaal!.naam,
                    mediaType: x.lokaal!.mediaType,
                    url: x.lokaal!.url,
                    file: x.lokaal!.file,
                    grootte: x.lokaal!.grootte,
                  },
            )}
            uploadProgresses={uploadProgresses}
            onBestandenChange={(x) => {
              onBestandenChange(
                x.map((best) => ({
                  lokaal:
                    best.type === BestandType.Lokaal
                      ? {
                          naam: best.naam,
                          mediaType: best.mediaType,
                          url: best.url,
                          file: best.file,
                          grootte: best.grootte,
                        }
                      : undefined,
                  aspDrive: undefined,
                  // aspDrive: best.type === BestandType.ASPDrive ? {
                  //   Naam: best.
                  // } : undefined,
                })),
              );
              // setBestanden(x);
            }}
            bestandenMuterenToegestaan={true}
            herordenenToestaan
            geenBestandenWeergaveComponent={() => null}
            // bestandenMuterenToegestaan={!isLoading && !isSubmitting}
          />
        </div>
        {/*<Container*/}
        {/*  onSortEnd={onSortEnd}*/}
        {/*  axis="xy"*/}
        {/*  // Zo staan we click events toe, zie: https://github.com/clauderic/react-sortable-hoc#click-events-being-swallowed*/}
        {/*  distance={5}*/}
        {/*>*/}
        {/*  {bestanden.map((bestand, index) => {*/}
        {/*    let uploadProgress: number | null = null;*/}
        {/*    if (*/}
        {/*      bestand.lokaal !== undefined &&*/}
        {/*      uploadProgresses !== undefined &&*/}
        {/*      uploadProgresses[bestand.lokaal!.url] !== undefined*/}
        {/*    ) {*/}
        {/*      // upload bezig*/}
        {/*      uploadProgress = uploadProgresses[bestand.lokaal!.url];*/}
        {/*    }*/}

        {/*    return (*/}
        {/*      <SortableItem*/}
        {/*        key={`item-${index}`}*/}
        {/*        index={index}*/}
        {/*        bestand={bestand}*/}
        {/*        onVerwijderen={() => handleVerwijderen(index)}*/}
        {/*        uploadProgress={uploadProgress}*/}
        {/*      />*/}
        {/*    );*/}
        {/*  })}*/}
        {/*</Container>*/}
      </div>
    );
  }

  return (
    <div ref={drop} style={{ flex: 1, display: 'flex' }}>
      <DropTargetElement style={dropTargetStyle}>
        <Box isActive={isActive} style={boxStyle}>
          {boxContent}
        </Box>
      </DropTargetElement>
    </div>
  );
};

export default TargetBox;
