import { Redirect, Route, RouteComponentProps } from 'react-router';
import React from 'react';
import PaginaHeader from '../PaginaHeader';
import { CommunicatieNavigatie } from '../Communicatie/styles';
import { NavLink } from 'react-router-dom';
import { IconSjablonen } from '../../components/Icons';

import Beheer from './Beheer';

interface IProps extends RouteComponentProps<{}> {}

const Diensten: React.FC<IProps> = (props) => {
  const { match } = props;
  return (
    <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
      <PaginaHeader>
        <CommunicatieNavigatie>
          <li>
            <NavLink
              to={`${match.url}/beheer`}
              style={{ paddingLeft: 20, paddingRight: 20 }}
              className="d-flex align-items-center justify-content-center"
            >
              <IconSjablonen />
              &nbsp; Beheer diensten
            </NavLink>
          </li>
        </CommunicatieNavigatie>
      </PaginaHeader>

      <Route path={`${match.path}/beheer`} component={Beheer} />

      <Route
        exact={true}
        path={match.path}
        render={() => <Redirect to={`${match.path}/beheer`} />}
      />
    </div>
  );
};

export default Diensten;
