import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { Field, FieldProps, Formik, FormikActions, FormikErrors, FormikProps } from 'formik';
import Combobox, { IOptie } from '../../../../../components/formulier/Combobox';
import { RootStoreContext } from '../../../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import api from '../../../../../api';
import * as Yup from 'yup';
import FormikVeldFout from '../../../../../components/formulier/FormikVeldFout';
import Dialoog from '../../../../../components/dialogen/Dialoog';
import _ from 'lodash';
import IDialoogProps from '../../../../../core/IDialoogProps';
import VeldWeergave from '../../../../../components/formulier/VeldWeergave';
import teksten from '../../../../../bedrijfslogica/teksten';
import { IOphalenVerlofMutatieSoortenResultElement } from '../../../../../../../shared/src/api/v2/medewerker/verlof';
import nameOf from '../../../../../core/nameOf';
import { dagDatum } from '../../../../../helpers/datum';
import { addDays } from 'date-fns';
import DatumKiezer from '../../../../../components/formulier/DatumKiezer';
import { EVerlofMutatiesoort } from '../../../../../bedrijfslogica/enums';
import { IOphalenMedewerkersResultElement } from '../../../../../../../shared/src/api/v2/medewerker';
import { EResultType } from '../../../../../stores/CheckStore';

interface IProps extends IDialoogProps<{}> {
  mdwID: number;
}

interface IFormikValues {
  urenPerWeek: number;
  mutatiedatum: Date;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  urenPerWeek: 'Uren/week',
  mutatiedatum: 'Mutatiedatum',
};

const ToevoegenVoorwaardeDialoog = observer((props: IProps) => {
  const { onAnnuleren, onSuccess, open, mdwID: relID } = props;
  const { checkStore } = useContext(RootStoreContext);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const formikRef = useRef<Formik<IFormikValues>>(null);

  const [mutatiesoorten, setMutatiesoorten] = useState<
    IOphalenVerlofMutatieSoortenResultElement[] | null
  >(null);
  const [medewerker, setMedewerker] = useState<IOphalenMedewerkersResultElement | null>(null);

  const ophalenMedewerker = useCallback(async () => {
    const medewerkersResult = (
      await api.v2.medewerker.ophalenMedewerkers({
        filterSchema: {
          filters: [{ naam: 'IDS', data: [props.mdwID] }],
        },
      })
    ).medewerkers;

    setMedewerker(medewerkersResult[0]);
  }, [props.mdwID]);

  useEffect(() => {
    ophalenMedewerker();
  }, [ophalenMedewerker]);

  const ophalenMutatiesoorten = useCallback(async () => {
    const result = await api.v2.medewerker.verlof.ophalenVerlofMutatieSoorten({
      filterSchema: {
        filters: [{ naam: 'NIET_IN_NAAM_ENUMS', data: [EVerlofMutatiesoort.Saldo2014] }],
      },
      orderSchema: { orders: [{ naam: 'SORTNR', richting: 'ASC' }] },
    });

    setMutatiesoorten(result.soorten);
  }, []);

  useEffect(() => {
    ophalenMutatiesoorten();
  }, [ophalenMutatiesoorten]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      // setHasSubmitted(true);

      // // Controle stap validatie (voor foutmeldingen na OK)
      // const errors = await actions.validateForm(values);
      // const hasError = Object.keys(errors).some((key) => (errors as any)[key] !== null);
      // if (hasError) {
      //   console.error(errors);
      //   return;
      // }

      actions.setSubmitting(true);

      const params = {
        mdwID: props.mdwID,
        standaardUren: values.urenPerWeek,
        mutatiedatum: values.mutatiedatum,
      };

      const checkData = await api.v2.medewerker.checkToevoegenMedewerkerVoorwaarde(params);

      if (
        (
          await checkStore.controleren({
            checkData,
          })
        ).type === EResultType.Annuleren
      ) {
        actions.setSubmitting(false);
        return;
      }

      if (
        (
          await checkStore.bevestigen({
            inhoud: <span>Wil je de voorwaarden toevoegen?</span>,
          })
        ).type === EResultType.Annuleren
      ) {
        return;
      }

      await api.v2.medewerker.toevoegenMedewerkerVoorwaarde(params);

      onSuccess({});
      actions.setSubmitting(false);
    },
    [onSuccess, relID, setHasSubmitted],
  );

  const redenenOpties = useMemo<IOptie<number>[] | null>(() => {
    if (mutatiesoorten === null) {
      return null;
    }

    return mutatiesoorten.map(
      (x): IOptie<number> => ({
        id: x.ID,
        label: x.Naam,
      }),
    );
  }, [mutatiesoorten]);

  const initieleWaarden = useMemo<IFormikValues | null>(() => {
    if (mutatiesoorten === null) {
      return null;
    }

    const mutatiesoort =
      mutatiesoorten.find((x) => x.NaamEnum === EVerlofMutatiesoort.OpnameVerlof) ?? null;
    const mutSrtID = mutatiesoort !== null ? mutatiesoort.ID : mutatiesoorten[0].ID;

    return {
      mutSrtID,
      urenPerWeek: 0,
      mutatiedatum: dagDatum(new Date()),
      datumTem: null,
      notities: null,
    };
  }, [mutatiesoorten]);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape<IFormikValues>({
        mutatiedatum: Yup.date(),
        urenPerWeek: Yup.number().moreThan(0),
      }),
    [],
  );

  if (initieleWaarden === null || medewerker === null) {
    return <span />;
  }

  return (
    <Dialoog index={props.dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>
          Toevoegen voorwaarden voor {medewerker.persoon!.Voornaam}{' '}
          {medewerker.persoon!.Voorvoegsel !== null ? medewerker.persoon!.Voorvoegsel + ' ' : ''}
          {medewerker.persoon!.Achternaam}
        </ModalTitle>
      </ModalHeader>
      <Formik<IFormikValues>
        onSubmit={handleSubmit}
        initialValues={initieleWaarden}
        ref={formikRef}
        isInitialValid
        // validationSchema={validationSchema}
        render={(formikProps: FormikProps<IFormikValues>) => {
          const { submitForm, isSubmitting, values, isValid } = formikProps;
          return (
            <>
              <ModalBody>
                <div className="form-group">
                  <div className="row">
                    <div className="row col-12">
                      <div className="col-4">
                        <label>{veldnamen.mutatiedatum}</label>
                        <Field
                          name={nameOf<IFormikValues>('mutatiedatum')}
                          render={({ field, form }: FieldProps<IFormikValues>) => {
                            const minimaleDatum = addDays(new Date(), -400);
                            const maximaleDatum = addDays(new Date(), +32);

                            return (
                              <div className="d-flex ">
                                <DatumKiezer
                                  onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                  waarde={field.value}
                                  // determineValidDate={(date) => {
                                  //   return date >= minimaleDatum && date <= maximaleDatum;
                                  // }}
                                  // determineNextValidDate={(date) => {
                                  //   const nieuweDatum = addDays(date, +1);
                                  //   if (nieuweDatum <= maximaleDatum) {
                                  //     return nieuweDatum;
                                  //   }
                                  //   return null;
                                  // }}
                                  // determinePreviousValidDate={(date) => {
                                  //   const nieuweDatum = addDays(date, -1);
                                  //   if (nieuweDatum >= minimaleDatum) {
                                  //     return nieuweDatum;
                                  //   }
                                  //   return null;
                                  // }}
                                />
                              </div>
                            );
                          }}
                        />
                      </div>
                    </div>

                    <div className="col-4 mt-3">
                      <label>{veldnamen.urenPerWeek}</label>
                      <Field
                        name={nameOf<IFormikValues>('urenPerWeek')}
                        render={(x: FieldProps<IFormikValues>) => (
                          <>
                            <input type="number" className="form-control" {...x.field} min={0} />
                            <FormikVeldFout fieldProps={x} />
                          </>
                        )}
                      />
                    </div>
                  </div>
                </div>
              </ModalBody>
              <ModalFooter className="d-flex justify-content-start">
                <button
                  className="btn btn-primary"
                  onClick={submitForm}
                  disabled={isSubmitting || !isValid}
                  // disabled={isSubmitting}
                  style={{ width: 100 }}
                >
                  Ok
                </button>
                <button className="btn btn-secondary" onClick={onAnnuleren} style={{ width: 100 }}>
                  Annuleren
                </button>
              </ModalFooter>
            </>
          );
        }}
      />
    </Dialoog>
  );
});

export default ToevoegenVoorwaardeDialoog;
