import { TableRowDetail } from '@devexpress/dx-react-grid-bootstrap4';
import { format } from 'date-fns';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { IRegel } from '..';
import { IOphalenOpdrachtenResultElement } from '../../../../../../../shared/src/api/v2/bank/opdracht/opdracht';
import RowDetailFacturen from '../../../../../components/bankopdracht/RowDetailFacturen';
import {
  EStoplichtStatus,
  StoplichtIndicatie,
} from '../../../../../components/formulier/StoplichtIndicatie';
import GegevensLayout from '../../../../../components/layout/GegevensLayout';
import TabelInspringBlok from '../../../../../components/layout/TabelInspringBlok';
import VerticaleScheidingslijn from '../../../../../components/layout/VerticaleScheidingslijn';
import MenuLayout from '../../../../../components/MenuLayout';
import { TableRowDetailWrapper } from '../../../../../helpers/dxTableGrid';
import { BankopdrachtContext } from '../index';

const DetailComp = (props: TableRowDetail.ContentProps) => {
  const row: IRegel = props.row;

  const boc = useContext(BankopdrachtContext);

  const { onVerversenAangevraagd } = useContext(BankopdrachtContext);

  const heeftSignaleringen = useMemo(() => row.warnings.length + row.errors.length > 0, [
    row.warnings,
    row.errors,
  ]);

  const signaleringen = heeftSignaleringen ? (
    <div className="mb-3">
      <div className="font-weight-bold">Signaleringen</div>
      <div>
        {[...row.errors].map((signalering, i) => (
          <div key={i} className="d-flex align-items-center">
            <span>
              <StoplichtIndicatie status={EStoplichtStatus.Rood} />
            </span>
            <span className="ml-2" style={{ marginTop: 2 }}>
              {signalering}
            </span>
          </div>
        ))}
      </div>
      <div>
        {[...row.warnings].map((signalering, i) => (
          <div key={i} className="d-flex align-items-center">
            <span>
              <StoplichtIndicatie status={EStoplichtStatus.Oranje} />
            </span>
            <span className="ml-2" style={{ marginTop: 2 }}>
              {signalering}
            </span>
          </div>
        ))}
      </div>
    </div>
  ) : (
    <span></span>
  );

  return (
    <TableRowDetailWrapper>
      <MenuLayout
        // menu={<div className="d-flex"></div>}
        body={
          <>
            <div className="d-flex">
              <TabelInspringBlok />
              <div>
                <div className="d-flex p-3">
                  <GegevensLayout
                    gegevens={[
                      {
                        label: 'IBAN',
                        waarde: row.IBAN,
                      },
                      {
                        label: 'Rekeningnaam',
                        waarde: row.Rekeningnaam,
                      },
                      {
                        label: 'Betalingskenmerk',
                        waarde: row.Betalingskenmerk,
                      },
                      null,
                    ]}
                  />
                  <div className="ml-3 mr-3">
                    <VerticaleScheidingslijn />
                  </div>
                  <GegevensLayout
                    gegevens={[
                      {
                        label: 'Oude IBAN',
                        waarde: row.OudeIBAN,
                      },
                      {
                        label: 'Wijz.soort',
                        waarde:
                          row.WijzigingIBAN === null
                            ? ''
                            : row.WijzigingIBAN === 1
                            ? 'Binnen zelfde bank'
                            : row.WijzigingIBAN === 2
                            ? 'Naar andere bank'
                            : 'Onbekend',
                      },
                      null,
                      null,
                    ]}
                  />
                  <div className="ml-3 mr-3">
                    <VerticaleScheidingslijn />
                  </div>

                  <GegevensLayout
                    gegevens={[
                      {
                        label: 'Stornodatum',
                        waarde:
                          row.stornomutatie !== null
                            ? format(new Date(row.stornomutatie.Mutatiedatum), 'dd-MM-yyyy')
                            : null,
                      },
                      {
                        label: 'Stornocode',
                        waarde: row.stornomutatie !== null ? row.stornomutatie.Returncode : null,
                      },
                      {
                        label: 'Stornoreden',
                        waarde:
                          row.stornomutatie !== null ? row.stornomutatie?.stornoreden!.Naam : null,
                      },
                      {
                        label: 'Stornobericht',
                        waarde:
                          row.StornoberichtVerstuurdOp !== null
                            ? 'Verstuurd'
                            : row.StornoberichtVersturen
                            ? 'Versturen'
                            : 'Niet versturen',
                      },
                    ]}
                  />

                  <div className="ml-3 mr-3">
                    <VerticaleScheidingslijn />
                  </div>

                  <GegevensLayout
                    gegevens={[
                      {
                        label: 'Mandaat',
                        waarde: row.Mandaat,
                      },
                      {
                        label: 'Datum',
                        waarde:
                          row.MandaatDatum !== null
                            ? format(new Date(row.MandaatDatum), 'dd-MM-yyyy')
                            : null,
                      },
                      null,
                      null,
                    ]}
                  />

                  <div className="ml-3 mr-3">
                    <VerticaleScheidingslijn />
                  </div>

                  <GegevensLayout
                    gegevens={[
                      {
                        label: 'SEPA-land',
                        waarde: row.sepaLand !== null ? row.sepaLand.Landnaam : 'Geen',
                      },
                      {
                        label: 'AdresTag vereist',
                        waarde:
                          row.sepaLand !== null ? (row.AdresTagVereist ? 'Ja' : 'Nee') : 'nvt',
                      },
                      {
                        label: 'Adresregel 1',
                        waarde: row.AdresTagAdresregel1,
                      },
                      {
                        label: 'Adresregel 2',
                        waarde: row.AdresTagAdresregel2,
                      },
                      {
                        label: 'Landcode',
                        waarde: row.AdresTagLandcode,
                      },
                    ]}
                  />
                </div>
                <div className="ml-3">
                  <div>{signaleringen}</div>
                </div>
                <div>
                  <RowDetailFacturen
                    {...props}
                    onVerversenAangevraagd={() => onVerversenAangevraagd()}
                  />
                </div>
              </div>
            </div>
          </>
        }
      />
    </TableRowDetailWrapper>
  );
};

export default DetailComp;
