import relatie from './relatie';
import derden from './derden';
import productsoort from './product/soort';
import debiteur from './debiteur';
import relatieZoeken from './relatieZoeken';
import geslacht from './geslacht';
import taal from './taal';
import contract from './contract';
import sjabloon from './sjabloon';
import aspdrive from './aspdrive';
import bestand from './bestand';
import mediaType from './mediaType';
import aanbod from './aanbod';
import bericht from './bericht';
import instelling from './instelling';
import telefonie from './telefonie';
import land from './land';
import dienst from './dienst/index';
import gebruiker from './gebruiker';
import locatie from './locatie';
import selectie from './selectie';
import persoon from './persoon';
import bank from './bank';
import email from './email';
import transport from './transport';
import account from './account';
import contact from './contact';
import organisatie from './organisatie';
import notificatie from './notificatie';
import magazijn from './magazijn';
import service from './service/service';
import taken from './taken';
import datum from './datum';
import inkoop from './inkoop/Inkoop';
import product from './product';
import control from './control';
import contactverzoek from './contactverzoek';
import verkoop from './verkoop';
import signalering from './signalering';
import factuur from './factuur';
import vordering from './vordering';
import prolongatie from './prolongatie';
import technisch from './technisch';
import bedrijf from './bedrijf';
import communicatie from './communicatie';
import aanmaning from './aanmaning';
import importExport from './importExport';
import voorraad from './voorraad';
import retourverwerking from './retourverwerking';
import pendel from './pendel';
import revisie from './revisie';
import crediteur from './crediteur';
import tekst from './tekst';
import boekhouding from './boekhouding';
import appEvent from './appEvent';
import aspGebruiker from './aspGebruiker';
import abonnement from './abonnement';
import auth from './auth';
import inkoopfactuur from './inkoopfactuur';
// import actie from './actie';
import klantactie from './klantactie';
import review from './review';
import extern from './website';
import bestelling from './bestelling';
import betaalwijze from './betaalwijze';
import btw from './btw';
import dashboard from './dashboard';
import medewerker from './medewerker';
import sponsoring from './sponsoring';
import sponsoringv2 from './sponsoringv2';
import kredietwaardigheid from './kredietwaardigheid';
import boeking from './boekhouding/boeking';
import memo from './memo';
import winkelwagen from './winkelwagen';
import applicatie from './applicatie';
import autorisatie from './autorisatie';
import onderwijsinstelling from './onderwijsinstelling';
import externeData from './externeData';
import telefoonnotitie from './telefoonnotitie';
import meldingIndicatie from './meldingIndicatie';
import kwaliteitsbeheer from './kwaliteitsbeheer';
import infoIndicatie from './infoIndicatie';
import beltaak from './beltaak';
import openingstijd from './openingstijd';
import pdf from './pdf';
import webtekst from './webtekst';
import faq from './faq';
import indexering from './indexering';
import partnership from './partnership';
import zoek from './zoek';
import stambeheer from './stambeheer';
import emailV2 from './email-v2';
import faqGroep from './faqGroep';
import blog from './blog';
import bulkbericht from './bulkbericht';
import relatieLocatie from './relatieLocatie';
import website from './website';
import opvolging from './opvolging';
import concurrentie from './concurrentie';
import prolongatieV2 from './prolongatie-v2';
import bulkVerzending from './bulkVerzending';
import terugbelverzoek from './terugbelverzoek';
import activiteitsmonitor from './activiteitsmonitor';
import escalatie from './escalatie';
import enquete from './enquete';

const v2 = {
  escalatie,
  opvolging,
  website,
  stambeheer,
  partnership,
  indexering,
  openingstijd,
  beltaak,
  kwaliteitsbeheer,
  onderwijsinstelling,
  winkelwagen,
  memo,
  kredietwaardigheid,
  sponsoring,
  sponsoringv2,
  medewerker,
  btw,
  betaalwijze,
  bestelling,
  enquete,
  review,
  klantactie,
  inkoopfactuur,
  abonnement,
  appEvent,
  aanmaning,
  prolongatie,
  prolongatieV2,
  vordering,
  factuur,
  debiteur,
  land,
  locatie,
  productsoort,
  relatie,
  relatieZoeken,
  contract,
  geslacht,
  taal,
  mediaType,
  sjabloon,
  selectie,
  derden,
  aspdrive,
  bestand,
  aanbod,
  bericht,
  instelling,
  telefonie,
  dienst,
  gebruiker,
  persoon,
  bank,
  email,
  transport,
  account,
  contact,
  organisatie,
  notificatie,
  magazijn,
  service,
  taken,
  datum,
  inkoop,
  product,
  control,
  contactverzoek,
  verkoop,
  signalering,
  technisch,
  bedrijf,
  communicatie,
  importExport,
  voorraad,
  retourverwerking,
  pendel,
  revisie,
  crediteur,
  tekst,
  boekhouding,
  aspGebruiker,
  auth,
  autorisatie,
  extern,
  dashboard,
  boeking,
  applicatie,
  externeData,
  telefoonnotitie,
  meldingIndicatie,
  infoIndicatie,
  zoek,
  pdf,
  webtekst,
  faq,
  faqGroep,
  emailV2,
  blog,
  relatieLocatie,
  bulkbericht,
  concurrentie,
  bulkVerzending,
  terugbelverzoek,
  activiteitsmonitor,
};

export default v2;
