import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import IDialoogProps from '../../../../core/IDialoogProps';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import api from '../../../../api';
import nameOf from '../../../../core/nameOf';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../models/IRemoteData';
import Dialoog from '../../../../components/dialogen/Dialoog';
import { EResultType } from '../../../../stores/CheckStore';
import { RootStoreContext } from '../../../../stores/RootStore';
import * as Yup from 'yup';
import FormikVeldFout from '../../../../components/formulier/FormikVeldFout';

export interface IDialoogResult {}

interface IFormikValues {
  postcodeVan: string;
  postcodeTot: string;
  landID: number;
  servDienstID: number;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  postcodeVan: 'Postcode Van',
  postcodeTot: 'Postcode Tot',
  landID: 'Land',
  servDienstID: 'Servicedienst',
};

interface IProps extends IDialoogProps<IDialoogResult> {
  id?: number;
  servDienstID?: number; // Verplicht als id === undefined
}

const MuterenDialoog = (props: IProps) => {
  const { checkStore } = useContext(RootStoreContext);

  const [initialValues, setInitialValues] = useState<IRemoteData<IFormikValues>>(
    createPendingRemoteData(),
  );

  const [voorkeurgebied, setVoorkeurgebied] = useState<IRemoteData<any>>(createPendingRemoteData());

  useEffect(() => {
    (async () => {
      if (props.id === undefined) {
        return;
      }

      const voorkeurgebiedenResult = await api.v2.service.voorkeurgebied.ophalenVoorkeurgebieden({
        filterSchema: {
          filters: [
            {
              naam: 'IDS',
              data: [props.id],
            },
          ],
        },
      });

      const voorkeurgebied = voorkeurgebiedenResult.gebieden[0];

      setVoorkeurgebied(createReadyRemoteData(voorkeurgebied));
    })();
  }, [props.id]);

  useEffect(() => {
    (async () => {
      if (props.id === undefined) {
        // Nieuw gebied
        const landID = 1; // TODO
        const servDienstID = props.servDienstID!;

        setInitialValues(
          createReadyRemoteData({
            postcodeVan: '',
            postcodeTot: '',
            landID,
            servDienstID,
          }),
        );
        return;
      }

      const voorkeurgebiedenResult = await api.v2.service.voorkeurgebied.ophalenVoorkeurgebieden({
        filterSchema: {
          filters: [
            {
              naam: 'IDS',
              data: [props.id],
            },
          ],
        },
      });

      const voorkeurgebied = voorkeurgebiedenResult.gebieden[0];

      setInitialValues(
        createReadyRemoteData({
          postcodeVan: voorkeurgebied.PostcodeVan,
          postcodeTot: voorkeurgebied.PostcodeTot,
          landID: voorkeurgebied.LandID,
          servDienstID: voorkeurgebied.ServDienstID,
        }),
      );
    })();
  }, []);

  // const [landen, setLanden] = useState<IOphalenLandenVoorLijstResult | null>(null);

  // const ophalenLanden = useCallback(async () => {
  //   const result = await api.v2.land.ophalen({
  //     filterSchema: {
  //       filters: [],
  //     },
  //   });
  //   setLanden(result);
  // }, []);

  // useEffect(() => {
  //   ophalenLanden();
  // }, [ophalenLanden]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);
      if (props.id === undefined) {
        const params = {
          servDienstID: props.servDienstID!,
          landID: values.landID,
          postcodeVan: values.postcodeVan,
          postcodeTot: values.postcodeTot,
        };

        const checkData = await api.v2.service.voorkeurgebied.checkToevoegenVoorkeurgebied(params);
        const checkResult = await checkStore.controleren({
          checkData,
        });
        if (checkResult.type === EResultType.Annuleren) {
          actions.setSubmitting(false);
          return;
        }

        await api.v2.service.voorkeurgebied.toevoegenVoorkeurgebied(params);
      } else {
        if (voorkeurgebied.state !== ERemoteDataState.Ready) {
          actions.setSubmitting(false);
          return;
        }
        const params = {
          id: props.id,
          servDienstID: voorkeurgebied.data.ServDienstID,
          landID: values.landID,
          postcodeVan: values.postcodeVan,
          postcodeTot: values.postcodeTot,
        };
        const checkData = await api.v2.service.voorkeurgebied.checkWijzigenVoorkeurgebied(params);
        const checkResult = await checkStore.controleren({
          checkData,
        });
        if (checkResult.type === EResultType.Annuleren) {
          actions.setSubmitting(false);
          return;
        }

        await api.v2.service.voorkeurgebied.wijzigenVoorkeurgebied(params);
      }

      props.onSuccess({});

      actions.setSubmitting(false);
    },
    [props, voorkeurgebied],
  );

  const validationSchema = useMemo(() => {
    const fields: Partial<Record<keyof IFormikValues, any>> = {
      postcodeVan: Yup.string().required('Verplicht'),
      postcodeTot: Yup.string().required('Verplicht'),
    };
    return Yup.object().shape(fields);
  }, []);

  return (
    <Dialoog index={props.dialoogIndex ?? 0}>
      <ModalHeader>
        <ModalTitle>Voorkeurgebied {props.id === undefined ? 'toevoegen' : 'wijzigen'}</ModalTitle>
      </ModalHeader>
      {initialValues.state === ERemoteDataState.Pending ? (
        <ModalBody>
          <LoadingSpinner />
        </ModalBody>
      ) : (
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialValues.data!}
          render={(formikProps) => <MuterenInner {...props} formikProps={formikProps} />}
          validationSchema={validationSchema}
          isInitialValid={false}
        />
      )}
    </Dialoog>
  );
};

const MuterenInner = (props: IProps & { formikProps: FormikProps<IFormikValues> }) => {
  const formikProps = props.formikProps;
  const { isSubmitting, submitForm } = formikProps;

  return (
    <>
      <ModalBody>
        <div className="form-group">
          <div className="row">
            <div className="col-12">
              <label>Postcode Van</label>
              <Field
                name={nameOf<IFormikValues>('postcodeVan')}
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;
                  return (
                    <>
                      <input
                        {...field}
                        className="form-control"
                        type="text"
                        onChange={(ev) =>
                          form.setFieldValue(field.name, ev.target.value.toUpperCase())
                        }
                        placeholder="3581 AA"
                      />
                      <FormikVeldFout fieldProps={fieldProps} />
                    </>
                  );
                }}
              />
            </div>
            <div className="col-12 mt-3">
              <label>Postcode Tot</label>
              <Field
                name={nameOf<IFormikValues>('postcodeTot')}
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;
                  return (
                    <>
                      <input
                        {...field}
                        className="form-control"
                        type="text"
                        onChange={(ev) =>
                          form.setFieldValue(field.name, ev.target.value.toUpperCase())
                        }
                        placeholder="3581 ZZ"
                      />
                      <FormikVeldFout fieldProps={fieldProps} />
                    </>
                  );
                }}
              />
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter className="d-flex flex-row justify-content-start">
        <button
          className="btn btn-primary d-flex align-items-center justify-content-center"
          onClick={submitForm}
          style={{ width: 100 }}
          disabled={isSubmitting}
        >
          {isSubmitting && (
            <span className="mr-2">
              <LoadingSpinner soort="light" grootte="15px" dikte="2px" />
            </span>
          )}
          Ok
        </button>
        <button
          className="btn btn-secondary"
          onClick={props.onAnnuleren}
          style={{ width: 100 }}
          disabled={isSubmitting}
        >
          Annuleren
        </button>
      </ModalFooter>
    </>
  );
};

export default MuterenDialoog;
