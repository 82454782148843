import styled from 'styled-components';
import { Kleur } from '../../../bedrijfslogica/constanten';

interface IRootProps {
  color?: string;
  backgroundColor?: string;
  size?: string;
  imageSrc?: string;
  fontSize?: string | number;
  letterSpacing?: string | number;
}

export const Root = styled.div<IRootProps>`
  background-color: ${(props) => props.backgroundColor || '#cbcbcb'};
  ${(props) => (props.imageSrc === undefined ? '' : `background-image: url(${props.imageSrc});`)}
  background-size: cover;
  background-position: center;
  color: ${(props) => props.color || Kleur.Wit};
  border-radius: 50%;
  width: ${(props) => props.size || '28px'};
  height: ${(props) => props.size || '28px'};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${(props) => props.fontSize || '11px'};
  letter-spacing: ${(props) => props.letterSpacing || '0.5px'};
`;
