import React, { useContext, useMemo, useRef, useState, useEffect } from 'react';
import { ITabbladProps } from '../index';
import Tabblad, { ITabblad } from '../../../../../components/layout/Tabblad';
import LoadingSpinner from '../../../../../components/Gedeeld/LoadingSpinner';
import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';
import { IOphalenTalenResultElement } from '../../../../../../../shared/src/api/v2/taal';
import { EResultType } from '../../../../../stores/CheckStore';
import { RootStoreContext } from '../../../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import TaalFormulier, { ITaalFormulierTabbladProps } from './TaalFormulier';
import { SjablonenContext, defaultSysteemDataContext } from '../../index';
import Combobox, { IOptie } from '../../../../../components/formulier/Combobox';
import { v4 as uuidv4 } from 'uuid';

interface IProps extends ITabbladProps {}

const KanaalFormulier: React.FC<IProps> = observer((props) => {
  const { checkStore, instellingStore } = useContext(RootStoreContext);
  const tabToevoegenRef = useRef(null);
  const { talen, sjabloonBasissen } = useContext(SjablonenContext)!;
  const [gekozenTaalID, setGekozenTaalID] = useState<number | null>(
    talen === null || talen.length === 0 ? null : talen[0].TaalID,
  );
  const [taalToevoegenTonen, setTaalToevoegenTonen] = useState(false);

  const popoverId = useMemo(() => uuidv4(), []);

  const tabbladOpties = useMemo<ITabblad<any>[] | null>(() => {
    if (props.inhouden === null) {
      return null;
    }

    return props.inhouden.map((x) => {
      const taal = talen.find((y) => y.TaalID === x.taalID)!;
      return {
        id: x.taalID,
        label: taal.Naam,
        content: TaalFormulier,
      };
    });
  }, [talen, props.inhouden && props.inhouden.map((x) => x.taalID)]);

  const toevoegbareTalen = useMemo<IOphalenTalenResultElement[] | null>(() => {
    if (props.inhouden === null) {
      return null;
    }
    return talen.filter((x) => props.inhouden!.findIndex((y) => y.taalID === x.TaalID) === -1);
  }, [talen, props.inhouden]);

  const gekozenTaalKlaarVoorGebruik = useMemo<boolean | null>(() => {
    if (props.inhouden === null) {
      return null;
    }
    if (gekozenTaalID === null) {
      return true;
    }

    return props.inhouden.some((x) => x.taalID === gekozenTaalID);
  }, [gekozenTaalID, props.inhouden]);

  useEffect(() => {
    if (!gekozenTaalKlaarVoorGebruik && props.inhouden !== null) {
      if (props.inhouden.length === 0) {
        setGekozenTaalID(null);
        return;
      }
      setGekozenTaalID(talen === null || talen.length === 0 ? null : props.inhouden[0].taalID);
    }
  }, [props.inhouden, gekozenTaalID, gekozenTaalKlaarVoorGebruik]);

  const tabbladComponentProps = useMemo<ITaalFormulierTabbladProps | null>(() => {
    if (props.inhouden === null || gekozenTaalID === null || !gekozenTaalKlaarVoorGebruik) {
      return null;
    }
    const inhoud = props.inhouden.find((x) => x.taalID === gekozenTaalID)!;

    return {
      taalID: gekozenTaalID!,
      comKanID: props.comKanID,
      comKanNaamEnum: props.comKanNaamEnum,
      sjabBasisID: props.sjabBasisID,
      formValues: {
        onderwerp: inhoud.onderwerp,
        inhoud: inhoud.inhoud,
        bestanden: inhoud.bestanden,
        toepassen: inhoud.toepassen,
        systeemDataContext: inhoud.systeemDataContext ?? defaultSysteemDataContext,
      },
      onChange: (formValues) => {
        props.onInhoudenChange(
          props.inhouden!.map((inhoud) => {
            if (inhoud.taalID === gekozenTaalID) {
              return {
                ...inhoud,
                ...formValues,
              };
            }
            return inhoud;
          }),
        );
      },
    };
  }, [
    gekozenTaalID,
    props.inhouden,
    gekozenTaalKlaarVoorGebruik,
    props.comKanID,
    props.comKanNaamEnum,
    props.sjabBasisID,
  ]);

  const sjabloonBasisOpties = useMemo<IOptie<number>[]>(() => {
    return sjabloonBasissen.map((basis) => ({
      id: basis.ID,
      label: basis.Naam,
    }));
  }, [sjabloonBasissen]);

  return (
    <>
      <div className="d-flex flex-column flex-fill" style={{ width: '100%' }}>
        {tabbladOpties === null || !gekozenTaalKlaarVoorGebruik ? (
          <div className="d-flex align-items-center justify-items-center p-3">
            {/*<LoadingSpinner />*/}
          </div>
        ) : (
          <div>
            <div className="p-3">
              <div className="d-flex align-items-center flex-fill">
                <span>Basis</span>
                <div className="ml-2 flex-fill" style={{ maxWidth: 200 }}>
                  <Combobox
                    geselecteerd={props.sjabBasisID}
                    onSelectieChange={(x) => props.onSjabBasisIDChange(x)}
                    opties={sjabloonBasisOpties}
                    legeOptieTonen
                    options={{
                      legeOptieTekst: 'Geen sjabloon basis',
                    }}
                  />
                </div>
              </div>
            </div>
            <Tabblad<number, ITaalFormulierTabbladProps>
              geselecteerd={gekozenTaalID}
              onSelectieChange={(x) => setGekozenTaalID(x)}
              tabbladen={tabbladOpties}
              onTabToevoegen={() => setTaalToevoegenTonen(true)}
              onTabVerwijderen={async (taalID) => {
                const bevestigResult = await checkStore.bevestigen({
                  inhoud: 'Bevestig verwijderen taal implementatie van sjabloon',
                });
                if (bevestigResult.type === EResultType.Annuleren) {
                  return;
                }
                const nieuweInhouden = props.inhouden!.filter((x) => x.taalID !== taalID);
                if (gekozenTaalID === taalID) {
                  if (nieuweInhouden.length === 0) {
                    setGekozenTaalID(null);
                  } else {
                    setGekozenTaalID(nieuweInhouden[0].taalID);
                  }
                }
                props.onInhoudenChange(nieuweInhouden);
              }}
              toegepasteTabbladen={props.inhouden!.filter((x) => x.toepassen).map((x) => x.taalID)}
              onTabToepassenChange={(id, toepassen) => {
                props.onInhoudenChange(
                  props.inhouden!.map((inhoud) => {
                    if (inhoud.taalID === id) {
                      return { ...inhoud, toepassen };
                    }
                    return inhoud;
                  }),
                );
              }}
              options={{
                smalleWeergave: true,
                onTabToevoegenKnopRef: tabToevoegenRef,
                tabbladComponentProps,
                toegepasteTabbladenUitgeslotenTabbladen: [instellingStore.TaalID!],
              }}
            />
          </div>
        )}
        {gekozenTaalID === null && (
          <div className="p-4 d-flex align-items-center justify-content-center flex-fill">
            Er is nog geen taal gekozen, klik op een tab of voeg een taal toe.
          </div>
        )}
      </div>
      <Overlay
        target={tabToevoegenRef.current || (undefined as any)}
        show={taalToevoegenTonen}
        rootClose
        placement="left"
        onHide={() => setTaalToevoegenTonen(false)}
      >
        <Popover id={popoverId}>
          {toevoegbareTalen === null ? (
            <div className="p-3 align-items-center justify-items-center">
              <LoadingSpinner />
            </div>
          ) : toevoegbareTalen.length === 0 ? (
            <div className="p-3">Er zijn geen andere talen beschikbaar.</div>
          ) : (
            <div className="list-group">
              {toevoegbareTalen.map((taal) => (
                <li
                  key={taal.TaalID}
                  className="list-group-item dx-g-bs4-cursor-pointer"
                  onClick={() => {
                    setTaalToevoegenTonen(false);
                    setGekozenTaalID(taal.TaalID);
                    const nieuweInhouden = [
                      ...props.inhouden!,
                      {
                        taalID: taal.TaalID!,
                        onderwerp_TekstID: null,
                        inhoud_TekstID: null,
                        onderwerp: '',
                        inhoud: '',
                        bestanden: [],
                        toepassen: true,
                        systeemDataContext: defaultSysteemDataContext,
                      },
                    ].sort((a, b) => {
                      // Sorteer inhouden op volgorde van de talen
                      const taalA = talen.find((x) => x.TaalID === a.taalID)!;
                      const taalB = talen.find((x) => x.TaalID === b.taalID)!;
                      return taalA.SortNr - taalB.SortNr;
                    });
                    props.onInhoudenChange(nieuweInhouden);
                  }}
                >
                  {taal.Naam}
                </li>
              ))}
            </div>
          )}
        </Popover>
      </Overlay>
    </>
  );
});

export default KanaalFormulier;
