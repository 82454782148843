import React, { useCallback, useContext, useMemo } from 'react';
import { IProps } from '@tinymce/tinymce-react/lib/cjs/main/ts/components/Editor';
import { IContext, BoekingContext } from '../index';
import LoadingSpinner from '../../../../Gedeeld/LoadingSpinner';
import { format } from 'date-fns';
import FormatteerBedrag from '../../../../MutatieBedrag';
import nameOf from '../../../../../core/nameOf';
import VeldWeergave from '../../../../formulier/VeldWeergave';
import GegevensLayout from '../../../../layout/GegevensLayout';
import { Kleur as EKleur } from '../../../../../bedrijfslogica/constanten';
import InkoopfactuurVisualisatie from '../../../../entiteitVisualisaties/InkoopfactuurVisualisatie';
import FactuurVisualisatie from '../../../../entiteitVisualisaties/FactuurVisualisatie';

const BoekingTab: React.FC<IProps> = (props) => {
  const { boeking } = useContext<IContext>(BoekingContext);

  if (boeking === null) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <div className="d-flex flex-column">
        <div className="row">
          <div className="col-12 d-flex mt-3">
            <div className="col-6">
              <VeldWeergave>
                <GegevensLayout
                  gegevens={[
                    { label: 'Boeknummer', waarde: boeking.Boeknummer.toString() },
                    {
                      label: 'Definitief',
                      waarde: boeking.Definitief ? (
                        <span>Ja</span>
                      ) : (
                        <span style={{ color: EKleur.Rood }}>Nee</span>
                      ),
                    },
                    {
                      label: 'Boekdatum',
                      waarde: format(new Date(boeking.Boekdatum), 'dd-MM-yyyy'),
                    },
                    null,
                    {
                      label: 'Geregistreerd',
                      waarde:
                        boeking.RecordToegevoegd !== null
                          ? format(new Date(boeking.RecordToegevoegd), 'dd-MM-yyyy HH:mm')
                          : 'Niet bekend',
                    },
                  ]}
                />
              </VeldWeergave>
            </div>
            <div className="col-6">
              <VeldWeergave>
                <GegevensLayout
                  gegevens={[
                    { label: 'Dagboek', waarde: boeking.dagboek.Naam },
                    { label: 'Dagboeknummer', waarde: boeking.dagboek.Nummer.toString() },
                    { label: 'Dagboeksoort', waarde: boeking.dagboek.dagboekSoort.Naam },
                    null,
                    {
                      label: 'Geexporteerd',
                      waarde: !boeking.NietExporteren
                        ? boeking.BoekingExportID !== null
                          ? 'Ja'
                          : 'Nee'
                        : 'Niet exporteren',
                    },
                  ]}
                />
              </VeldWeergave>
            </div>
          </div>

          <div className="col-12">
            <div className="mt-2 pl-3 pr-3 pt-2 mb-4">
              <VeldWeergave>
                {' '}
                <GegevensLayout gegevens={[]} />
              </VeldWeergave>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BoekingTab;
