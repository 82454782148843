import React, { useCallback, useContext, useMemo, useState } from 'react';
import IDialoogProps from '../../../../core/IDialoogProps';
import Dialoog from '../../../dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import { ModalFooter } from 'react-bootstrap';
import {
  EOpgegevenContextSelectieType,
  ESjablooncontextNaamEnum,
  IOpgegevenContext,
} from '../../types';
import MultiComboboxV2, {
  EnkeleProvider,
  IRepresentatieProps,
  Provider,
} from '../../../formulier/MultiComboboxV2';
import { ISjabloonContext } from '../../../../../../shared/src/api/v2/Sjabloon/sjabloon';
import { ASPKolom, EAspKolomBreedteType } from '../../../tabel/ASPTabel/types';
import PersoonDataFormulier from './PersoonDataFormulier';
import ContractDataFormulier from './ContractDataFormulier';
import RelatieDataFormulier from './RelatieDataFormulier';
import { RootStoreContext } from '../../../../stores/RootStore';
import { observer } from 'mobx-react-lite';

export interface IOpvoerenOutput {
  opgegevenContext: IOpgegevenContext;
}

enum EContextKolom {
  Naam,
  Omschrijving,
}

export interface IData<T> {
  data: T;
  isGeldig: boolean;
}

export interface IDataFormulierProps<T> {
  dialoogIndex: number;
  data: IData<T>;
  onDataChange: (data: IData<T>) => void;
}

interface IProps extends IDialoogProps<IOpvoerenOutput> {
  defaultSjabCtxID?: number;
  contexten: ISjabloonContext[];
  opgegevenContexten: IOpgegevenContext[];
}

const OpvoerenDialoog = observer((props: IProps) => {
  const { checkStore } = useContext(RootStoreContext);

  const [isBezig, setIsBezig] = useState(false);

  const [sjabCtxID, setSjabCtxID] = useState<number | null>(props.defaultSjabCtxID ?? null);
  const [alias, setAlias] = useState<string>('');

  const beschikbareContexten = useMemo<ISjabloonContext[]>(
    () =>
      [
        ESjablooncontextNaamEnum.Persoon,
        ESjablooncontextNaamEnum.Contract,
        ESjablooncontextNaamEnum.Relatie,
      ].map((naamEnum) => props.contexten.find((ctx) => ctx.NaamEnum === naamEnum)!),
    [props.contexten],
  );

  const [datas, setDatas] = useState<Record<number, IData<any>>>(
    useMemo(
      () =>
        beschikbareContexten.reduce((acc, curr) => {
          let initieleWaarde = null;
          let isGeldig = false;
          const naamEnum = curr.NaamEnum as ESjablooncontextNaamEnum;
          switch (naamEnum) {
            case ESjablooncontextNaamEnum.Persoon:
              initieleWaarde = { persID: null };
              break;
            case ESjablooncontextNaamEnum.Contract:
              initieleWaarde = { cntID: null };
              break;
            case ESjablooncontextNaamEnum.Relatie:
              initieleWaarde = { relID: null };
              break;
          }

          return {
            ...acc,
            [curr.SjabCtxID]: {
              data: initieleWaarde,
              isGeldig,
            },
          };
        }, {}),
      [beschikbareContexten],
    ),
  );

  const contextEnkeleProvider = useMemo<EnkeleProvider<number, ISjabloonContext>>(
    () => async (id) => {
      return beschikbareContexten.find((x) => x.SjabCtxID === id)!;
    },
    [beschikbareContexten],
  );

  const contextProvider = useMemo<Provider<EContextKolom, ISjabloonContext>>(
    () => async (params) => {
      const items = beschikbareContexten.reduce(
        (acc, context, index) => ({
          ...acc,
          [index]: context,
        }),
        {},
      );
      return {
        items,
        totaalAantal: beschikbareContexten.length,
      };
    },
    [beschikbareContexten],
  );

  const contextKeyExtractor = useCallback((ctx: ISjabloonContext) => ctx.SjabCtxID, []);

  const contextRepresentatie = useMemo<React.ComponentType<IRepresentatieProps<ISjabloonContext>>>(
    () => (reprProps) => {
      return <span>{reprProps.entiteit.Naam}</span>;
    },
    [props.contexten],
  );

  const contextKolommen = useMemo<ASPKolom<EContextKolom, ISjabloonContext>[]>(
    () => [
      {
        key: EContextKolom.Naam,
        label: 'Naam',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 200,
        renderer: (item) => item.Naam,
      },
      {
        key: EContextKolom.Omschrijving,
        label: 'Omschrijving',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 450,
        renderer: (item) => item.Omschrijving ?? '- -',
      },
    ],
    [],
  );

  const DataFormulierComponent = useMemo<React.ComponentType<
    IDataFormulierProps<any>
  > | null>(() => {
    if (sjabCtxID === null) {
      return null;
    }
    const context = props.contexten.find((x) => x.SjabCtxID === sjabCtxID)!;
    const naamEnum = context.NaamEnum as ESjablooncontextNaamEnum;
    switch (naamEnum) {
      case ESjablooncontextNaamEnum.Persoon:
        return PersoonDataFormulier;
      case ESjablooncontextNaamEnum.Contract:
        return ContractDataFormulier;
      case ESjablooncontextNaamEnum.Relatie:
        return RelatieDataFormulier;
    }
  }, [sjabCtxID, props.contexten]);

  const isGeldig = useMemo(() => {
    if (sjabCtxID === null) {
      return null;
    }
    const data = datas[sjabCtxID];
    return data.isGeldig;
  }, [sjabCtxID, datas]);

  const dialoogIndex = useMemo(() => props.dialoogIndex ?? 0, [props.dialoogIndex]);

  const handleSubmit = useCallback(async () => {
    setIsBezig(true);

    if (alias === '') {
      const contextKomtAlVoorZonderAlias = props.opgegevenContexten.some((x) => {
        if (x.alias !== undefined) {
          return false;
        }

        switch (x.selectie.type) {
          case EOpgegevenContextSelectieType.NaamEnum: {
            const naamEnum = x.selectie.naamEnum;
            const ctx = props.contexten.find((ctx) => ctx.NaamEnum === naamEnum)!;
            return ctx.SjabCtxID === sjabCtxID!;
          }
          case EOpgegevenContextSelectieType.SjabCtxID:
            return x.selectie.sjabCtxID === sjabCtxID!;
        }
      });
      if (contextKomtAlVoorZonderAlias) {
        await checkStore.controleren({
          checkData: {
            errors: [
              'De context die is gekozen, komt al voor (misschien had je een alias toe willen passen?).',
            ],
            warnings: [],
          },
          options: {
            dialoogIndex,
          },
        });

        setIsBezig(false);
        return;
      }
    } else {
      const aliasIsAlInGebruik = props.opgegevenContexten.some((x) => x.alias === alias);
      if (aliasIsAlInGebruik) {
        await checkStore.controleren({
          checkData: {
            errors: ['De alias die is opgegeven is al in gebruik.'],
            warnings: [],
          },
          options: {
            dialoogIndex,
          },
        });

        setIsBezig(false);
        return;
      }
    }

    const data = datas[sjabCtxID!];

    const opgegevenContext: IOpgegevenContext = {
      data: data.data,
      alias: alias === '' ? undefined : alias,
      selectie: {
        type: EOpgegevenContextSelectieType.SjabCtxID,
        sjabCtxID: sjabCtxID!,
      },
    };

    props.onSuccess({
      opgegevenContext,
    });

    setIsBezig(false);
  }, [
    sjabCtxID,
    alias,
    datas,
    props.opgegevenContexten,
    props.contexten,
    props.onSuccess,
    dialoogIndex,
  ]);

  return (
    <Dialoog index={dialoogIndex}>
      <ModalHeader>
        <ModalTitle>Toevoegen context</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <div>
          <label>Context</label>
          <MultiComboboxV2<number, EContextKolom, ISjabloonContext, null>
            provider={contextProvider}
            enkeleProvider={contextEnkeleProvider}
            keyExtractor={contextKeyExtractor}
            waarde={sjabCtxID}
            onChange={setSjabCtxID}
            representatieComponent={contextRepresentatie}
            kolommen={contextKolommen}
          />
        </div>
        <div className="mt-3">
          <label>Alias</label>
          <input
            type="text"
            className="form-control"
            value={alias}
            onChange={(ev) => setAlias(ev.target.value.trim())}
            placeholder="Geen alias toepassen"
          />
        </div>
        {DataFormulierComponent !== null && (
          <DataFormulierComponent
            dialoogIndex={dialoogIndex}
            data={datas[sjabCtxID!]}
            onDataChange={(data) =>
              setDatas((curr) => ({
                ...curr,
                [sjabCtxID!]: data,
              }))
            }
          />
        )}
      </ModalBody>
      <ModalFooter className="d-flex flex-row justify-content-start">
        <button
          className="btn btn-primary d-flex align-items-center justify-content-center"
          style={{ width: 100 }}
          disabled={isBezig || !isGeldig}
          onClick={handleSubmit}
        >
          Ok
        </button>
        <button
          className="btn btn-secondary"
          onClick={props.onAnnuleren}
          style={{ width: 100 }}
          disabled={isBezig}
        >
          Annuleren
        </button>
      </ModalFooter>
    </Dialoog>
  );
});

export default OpvoerenDialoog;
