import api from '../..';
import {
  OphalenRedenenBeeindigingResult,
  IOphalenMinimaleEinddatumParams,
  IOphalenMinimaleEinddatumResult,
  IOphalenKostenVtbParams,
  IOphalenKostenVtbResult,
  ICheckSelectieBeeindigenContractenParams,
  IBeeindigenContractenParams,
  IBeeindigenContractenResult,
  ITerugdraaienBeeindigingContractenParams,
  ITerugdraaienBeeindigingContractenResult,
  IVersturenBevestigingBeeindigingParams,
  IVersturenBevestigingBeeindigingResult,
} from '../../../../../shared/src/api/v2/contract/beeindiging';
import ICheckData from '../../../../../shared/src/models/ICheckData';

const beeindiging = {
  ophalenRedenenBeeindiging: async (params: any): Promise<OphalenRedenenBeeindigingResult> => {
    return await api.post('/v2/contract/beeindiging/ophalen-redenen-beeindiging', params);
  },

  ophalenMinimaleEinddatum: async (
    params: IOphalenMinimaleEinddatumParams,
  ): Promise<IOphalenMinimaleEinddatumResult> => {
    return await api.post('/v2/contract/beeindiging/ophalen-minimale-einddatum', params);
  },
  ophalenKostenVtb: async (params: IOphalenKostenVtbParams): Promise<IOphalenKostenVtbResult> => {
    return await api.post('/v2/contract/beeindiging/ophalen-kosten-vtb', params);
  },
  checkSelectieBeeindigenContracten: async (
    params: ICheckSelectieBeeindigenContractenParams,
  ): Promise<ICheckData> => {
    return await api.post('/v2/contract/beeindiging/check-selectie-beeindigen-contracten', params);
  },
  beeindigenContracten: async (
    params: IBeeindigenContractenParams,
  ): Promise<IBeeindigenContractenResult> => {
    return await api.post('/v2/contract/beeindiging/beeindigen-contracten', params);
  },
  checkTerugdraaienBeeindigingContracten: async (
    params: ITerugdraaienBeeindigingContractenParams,
  ): Promise<ICheckData> => {
    return await api.post(
      '/v2/contract/beeindiging/check-terugdraaien-beeindiging-contracten',
      params,
    );
  },
  terugdraaienBeeindigingContracten: async (
    params: ITerugdraaienBeeindigingContractenParams,
  ): Promise<ITerugdraaienBeeindigingContractenResult> => {
    return await api.post('/v2/contract/beeindiging/terugdraaien-beeindiging-contracten', params);
  },
  checkVersturenBevestiging: async (
    params: IVersturenBevestigingBeeindigingParams,
  ): Promise<ICheckData> => {
    return await api.post('/v2/contract/beeindiging/check-versturen-bevestiging', params);
  },
  versturenBevestiging: async (
    params: IVersturenBevestigingBeeindigingParams,
  ): Promise<IVersturenBevestigingBeeindigingResult> => {
    return await api.post('/v2/contract/beeindiging/versturen-bevestiging', params);
  },
};

export default beeindiging;
