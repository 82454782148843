import { IOphalenGeneriekParams } from '../../../../../../shared/src/api/v2/generiek';
import {
  IOphalenItemsResult,
  IToevoegenItemParams,
  IToevoegenItemResult,
  IWijzigenItemResult,
  IVerwijderenItemsParams,
  IVerwijderenItemsResult,
} from '../../../../../../shared/src/api/v2/sponsoring/item';
import { ISponsorItem } from '../../../../../../shared/src/api/v2/sponsoringv2/item';
import ICheckData from '../../../../../../shared/src/models/ICheckData';
import api from '../../../index';

const item = {
  ophalenItems: async (params: IOphalenGeneriekParams): Promise<IOphalenItemsResult> => {
    return await api.post('/v2/sponsoring/item/ophalen-items', params);
  },
  checkToevoegenItem: async (params: IToevoegenItemParams): Promise<ICheckData> => {
    return await api.post('/v2/sponsoring/item/check-toevoegen-item', params);
  },
  toevoegenItem: async (params: IToevoegenItemParams): Promise<IToevoegenItemResult> => {
    return await api.post('/v2/sponsoring/item/toevoegen-item', params);
  },
  checkWijzigenItem: async (params: ISponsorItem): Promise<ICheckData> => {
    return await api.post('/v2/sponsoring/item/check-wijzigen-item', params);
  },
  wijzigenItem: async (params: ISponsorItem): Promise<IWijzigenItemResult> => {
    return await api.post('/v2/sponsoring/item/wijzigen-item', params);
  },
  checkVerwijderenItems: async (params: IVerwijderenItemsParams): Promise<ICheckData> => {
    return await api.post('/v2/sponsoring/item/check-verwijderen-items', params);
  },
  verwijderenItems: async (params: IVerwijderenItemsParams): Promise<IVerwijderenItemsResult> => {
    return await api.post('/v2/sponsoring/item/verwijderen-items', params);
  },
};

export default item;
