import React, { useCallback, useEffect, useMemo, useState } from 'react';
import IDialoogProps from '../../../../core/IDialoogProps';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import api from '../../../../api';
import nameOf from '../../../../core/nameOf';
import FormikVeldFout from '../../../../components/formulier/FormikVeldFout';
import VinkVeld from '../../../../components/formulier/VinkVeld';
import Combobox from '../../../../components/formulier/Combobox';
import Dialoog from '../../../../components/dialogen/Dialoog';
import NumeriekVeld from '../../../../components/formulier/NumeriekVeld';
import { IOphalenFeestdagenResultElement } from '../../../../../../shared/src/api/v2/feestdag';
import DatumKiezer from '../../../../components/formulier/DatumKiezer';
import { dagDatum } from '../../../../helpers/datum';
import * as Yup from 'yup';

interface IProps extends IDialoogProps<null> {
  id: number | null;
}

export interface IFormikValues {
  naam: string;
  datum: Date | string;
  // openTijdVan: string | null;
  // openTijdTot: string | null;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  naam: 'Naam',
  datum: 'Datum',
};

const MutatieDialoog: React.FC<IProps> = (props) => {
  const { onAnnuleren, onSuccess, open, dialoogIndex } = props;

  const [feestdag, setFeestdag] = useState<IOphalenFeestdagenResultElement | null>(null);

  const ophalenFeestdag = useCallback(async () => {
    if (props.id === null) {
      return;
    }

    const result = await api.v2.stambeheer.feestdag.ophalenFeestdagen({
      filterSchema: { filters: [{ naam: 'IDS', data: [props.id] }] },
    });

    setFeestdag(result.feestdagen[0]);
  }, [props.id]);

  useEffect(() => {
    ophalenFeestdag();
  }, [ophalenFeestdag]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      if (props.id !== null) {
        const params = {
          id: props.id,
          naam: values.naam,
          datum: values.datum,
        };
        await api.v2.stambeheer.feestdag.wijzigenFeestdag(params);
      } else {
        const params = {
          naam: values.naam,
          datum: values.datum,
        };
        await api.v2.stambeheer.feestdag.toevoegenFeestdag(params);
      }

      onSuccess(null);
    },
    [],
  );

  const initialValues = useMemo<IFormikValues>(() => {
    if (props.id !== null && feestdag !== null) {
      return {
        naam: feestdag.Naam,
        datum: dagDatum(new Date(feestdag.Datum)),
      };
    }

    return {
      naam: '',
      datum: dagDatum(new Date()),
    };
  }, [props.id, feestdag]);

  const validationSchema = useMemo(() => {
    const fields: Record<keyof IFormikValues, any> = {
      naam: Yup.string().required(),
      datum: Yup.date(),
    };
    return Yup.object().shape(fields);
  }, []);

  return (
    <Dialoog index={dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>{props.id !== null ? 'Wijzigen' : 'Toevoegen'} feestdag</ModalTitle>
      </ModalHeader>
      <ModalBody>
        {props.id !== null && feestdag === null ? (
          <LoadingSpinner />
        ) : (
          <Formik<IFormikValues>
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            render={(formikProps: FormikProps<IFormikValues | null>) => {
              const { submitForm, isSubmitting, isValid, values } = formikProps;
              return (
                <>
                  <ModalBody>
                    <div className="row">
                      <div className="col-12">
                        <label>{veldnamen.datum}</label>
                        <Field
                          name={nameOf<IFormikValues>('datum')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <>
                                <DatumKiezer
                                  waarde={field.value}
                                  onGewijzigd={(data) => form.setFieldValue(field.name, data)}
                                  determinePreviousValidDate="ONBEGRENST"
                                  determineNextValidDate="ONBEGRENST"
                                />
                                <FormikVeldFout fieldProps={fieldProps} />
                              </>
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3 mb-3">
                        <label>{veldnamen.naam}</label>
                        <Field
                          name={nameOf<IFormikValues>('naam')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            return <input {...field} className="form-control" type="text" />;
                          }}
                        />
                      </div>
                    </div>
                  </ModalBody>
                  <ModalFooter className="d-flex justify-content-start">
                    <button
                      className="btn btn-primary"
                      onClick={submitForm}
                      disabled={isSubmitting}
                      style={{ width: 100 }}
                    >
                      Ok
                    </button>
                    <button
                      className="btn btn-secondary"
                      onClick={onAnnuleren}
                      style={{ width: 100 }}
                    >
                      Annuleren
                    </button>
                  </ModalFooter>
                </>
              );
            }}
          />
        )}
      </ModalBody>
    </Dialoog>
  );
};

export default MutatieDialoog;
