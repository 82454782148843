import React from 'react';

interface IProps {
  kleur: string;
  naam: string;
  onClick: () => void;
}

const StatusOptie: React.FC<IProps> = (props) => {
  return (
    <button
      className="p-2 d-flex align-items-center"
      style={{ outline: 0, border: 0, background: 0 }}
      onClick={props.onClick}
    >
      <span className="mr-3">
        <div
          style={{
            width: 9,
            height: 9,
            backgroundColor: props.kleur,
            borderRadius: '50%',
          }}
        />
      </span>
      <span>{props.naam}</span>
    </button>
  );
};

export default StatusOptie;
