import React, { useMemo } from 'react';
import useUpload from '../../../../core/useUpload';
import BijlagenContainer, {
  BestandType,
  EWeergaveFocus,
} from '../../../../components/BijlagenContainer';
import BijlageKnop, { EOrientatie, ESelectieModus } from '../../../../components/BijlageKnop';
import { IOphalenAfbeeldingenResultElement } from '../../../../../../shared/src/api/v2/website/afbeelding';
import api from '../../../../api';

interface IProps {
  afbeelding: IOphalenAfbeeldingenResultElement;
}

const AfbeeldingKolomWeergave: React.FC<IProps> = (props) => {
  const { isBezigMetUploaden, uploadProgresses, muteerBestanden, bestanden } = useUpload(
    useMemo(
      () => ({
        automatischUploaden: true,
        onAlleBestandenGeuploaded: async (ids) => {
          await api.v2.extern.afbeelding.wijzigenAfbeelding({
            id: props.afbeelding.ID,
            naam: props.afbeelding.Naam,
            naamEnum: props.afbeelding.NaamEnum,
            bestand_ID: ids.length === 0 ? null : ids[0],
          });
        },
        initieleBestanden:
          props.afbeelding.bestand === null
            ? []
            : [
                {
                  type: BestandType.ASPDrive as BestandType.ASPDrive,
                  bestand: props.afbeelding.bestand,
                },
              ],
      }),
      [props.afbeelding],
    ),
  );

  return (
    <div className="d-flex">
      <BijlageKnop
        onBijgevoegd={(bijlagen) => muteerBestanden((bestanden) => [bijlagen[0]])}
        orientatie={EOrientatie.Verticaal}
        selectieModus={ESelectieModus.Enkel}
        disabled={isBezigMetUploaden}
      />
      <BijlagenContainer
        bestanden={bestanden}
        uploadProgresses={uploadProgresses}
        onBestandenChange={(bestanden) => {
          muteerBestanden((x) => bestanden);
        }}
        weergaveFocus={EWeergaveFocus.Visualisatie}
        bestandenMuterenToegestaan={!isBezigMetUploaden}
        geenBestandenWeergaveComponent={() => <span>Nog geen afbeelding gekozen</span>}
      />
    </div>
  );
};

export default AfbeeldingKolomWeergave;
