import React, { useCallback, useContext, useEffect, useMemo, useState, useRef } from 'react';
import IDialoogProps from '../../../../../core/IDialoogProps';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { Field, FieldProps, Formik, FormikActions, FormikErrors, FormikProps } from 'formik';
import { RootStoreContext } from '../../../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import VinkVeld from '../../../../../components/formulier/VinkVeld';
import api from '../../../../../api';
import { EResultType } from '../../../../../stores/CheckStore';
import nameof from '../../../../../core/nameOf';
import { IOphalenOpdrachtenResultElement } from '../../../../../../../shared/src/api/v2/bank/opdracht/opdracht';
import Dialoog from '../../../../../components/dialogen/Dialoog';
import LoadingSpinner from '../../../../../components/Gedeeld/LoadingSpinner';
import _ from 'lodash';

interface IProps extends IDialoogProps<null> {
  bankOpdIDs: number[];
}

interface IFormikValues {
  stornoberichtVersturen: boolean;
}

const veldnamen = {
  stornoberichtVersturen: 'Stornobericht versturen',
};

const WijzigBerichtindicatieDialoog = observer((props: IProps) => {
  const { dialoogIndex, onAnnuleren, onSuccess } = props;
  const { checkStore } = useContext(RootStoreContext);

  // const [opdracht, setOpdracht] = useState<IOphalenOpdrachtenResultElement | null>(null);
  const [initieelStornoberichtVersturen, setInitieelStornoberichtVersturen] = useState<
    boolean | null
  >(null);

  const ophalenOpdracht = useCallback(async () => {
    const opdrachtenResult = (
      await api.v2.bank.opdracht.ophalenOpdrachten({
        filterSchema: { filters: [{ naam: 'IDS', data: [props.bankOpdIDs] }] },
      })
    ).opdrachten;

    const indicaties: boolean[] = _.uniq(opdrachtenResult.map((x) => x.StornoberichtVersturen));

    setInitieelStornoberichtVersturen(indicaties.length === 1 ? indicaties[0] : false);

    // setOpdracht(opdracht);
  }, [props.bankOpdIDs]);

  useEffect(() => {
    ophalenOpdracht();
  }, [ophalenOpdracht]);

  const initieleWaarden = useMemo<IFormikValues | null>(() => {
    if (initieelStornoberichtVersturen === null) {
      return null;
    }

    return {
      stornoberichtVersturen: initieelStornoberichtVersturen,
    };
  }, [initieelStornoberichtVersturen]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      const params = {
        bankOpdIDs: props.bankOpdIDs,
        stornoberichtVersturen: values.stornoberichtVersturen,
      };

      // const resultaat = await checkStore.bevestigen({
      //   titel: `Wijzigingen vastleggen?`,
      // });
      // if (resultaat.type === EResultType.Annuleren) {
      //   return;
      // }

      const result = await api.v2.bank.opdracht.wijzigenStornoberichtVersturenIndicaties(params);

      onSuccess(null);

      actions.setSubmitting(false);
    },
    [onSuccess, props.bankOpdIDs],
  );

  return (
    <Dialoog index={dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Wijzigen indicatie Stornobericht versturen</ModalTitle>
      </ModalHeader>

      {initieleWaarden === null ? (
        <LoadingSpinner />
      ) : (
        <>
          <Formik<IFormikValues>
            isInitialValid
            initialValues={initieleWaarden}
            //   validate={handleValidate}
            onSubmit={handleSubmit}
            render={(FormikProps) => {
              const { submitForm, isSubmitting, values, errors } = FormikProps;
              return (
                <>
                  <ModalBody>
                    <div className="row">
                      <div className="col-12 mt-3 mb-2">
                        <span className="d-flex align-items-center">
                          <Field
                            name={nameof<IFormikValues>('stornoberichtVersturen')}
                            render={(fieldProps: FieldProps) => {
                              const { field, form } = fieldProps;
                              return (
                                <VinkVeld
                                  aangevinkt={field.value}
                                  onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                />
                              );
                            }}
                          />
                          <span className="ml-2">{veldnamen.stornoberichtVersturen}</span>
                        </span>
                      </div>
                    </div>
                  </ModalBody>
                  <ModalFooter className="d-flex flex-row justify-content-start">
                    <button
                      className="btn btn-primary"
                      disabled={isSubmitting}
                      onClick={submitForm}
                      style={{ width: 100 }}
                    >
                      Ok
                    </button>
                    <button
                      className="btn btn-secondary"
                      onClick={onAnnuleren}
                      style={{ width: 100 }}
                    >
                      Annuleren
                    </button>
                  </ModalFooter>
                </>
              );
            }}
          />
        </>
      )}
    </Dialoog>
  );
});

export default WijzigBerichtindicatieDialoog;
