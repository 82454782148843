import { Formik } from 'formik';
import React, { useCallback, useContext, useState, useMemo, useEffect } from 'react';
import { ModalFooter, ModalTitle } from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/ModalHeader';
import { IOphalenRelatiesResultElementV2 } from '../../../../../shared/src/api/v2/relatie';
import api from '../../../api';
import IDialoogProps from '../../../core/IDialoogProps';
import Dialoog from '../../dialogen/Dialoog';
import LoadingSpinner from '../../Gedeeld/LoadingSpinner';
import Tabblad, { ITabblad } from '../../layout/Tabblad';
import FactuurTab from './FactuurTab';
import BankopdrachtTab from './BankopdrachtTab';
import BoekingTab from './BoekingTab';
import { IBerekenVerkoopbedragResult } from '../../../../../shared/src/api/v2/product/verkoop';
import _ from 'lodash';
import { IOphalenFacturenBasisResultElement } from '../../../../../shared/src/api/v2/factuur';
import { IOphalenOpdrachtenResultElement } from '../../../../../shared/src/api/v2/bank/opdracht/opdracht';
import { IOphalenAanmaningenResultElement } from '../../../../../shared/src/api/v2/aanmaning';
import {
  IOphalenBoekingenResultElement,
  IOphalenBoekingRegelsResultElement,
} from '../../../../../shared/src/api/v2/boekhouding/boeking';
import TechnischeInformatieKnop from '../../TechnischeInformatieKnop';
import VeldWeergave from '../../formulier/VeldWeergave';
import ZakenTab from './ZakenTab';
import { IOphalenIncassozakenResultElement } from '../../../../../shared/src/api/v2/debiteur/incassozaak';
import { IOphalenHerincassozakenResultElement } from '../../../../../shared/src/api/v2/debiteur/herincassozaak';
import { IOphalenRechtzakenResultElement } from '../../../../../shared/src/api/v2/debiteur/rechtzaak';

interface IProps extends IDialoogProps<{}> {
  factID: number;
}

enum ETablad {
  Factuur,
  Bankopdracht,
  Zaken,
  Boeking,
}

export interface IContext {
  factuur: IOphalenFacturenBasisResultElement | null;
  aanmaningen: IOphalenAanmaningenResultElement[] | null;
  relatie: IOphalenRelatiesResultElementV2 | null;
  bankopdrachten: IOphalenOpdrachtenResultElement[];
  boekingregels: IOphalenBoekingRegelsResultElement[] | null;
  boekingen: IOphalenBoekingenResultElement[] | null;
  incassozaken: IOphalenIncassozakenResultElement[] | null;
  herincassozaken: IOphalenHerincassozakenResultElement[] | null;
  rechtzaken: IOphalenRechtzakenResultElement[] | null;
}

export const FactuurContext = React.createContext<IContext>({
  factuur: null,
  aanmaningen: null,
  relatie: null,
  bankopdrachten: [],
  boekingregels: null,
  boekingen: null,
  incassozaken: null,
  herincassozaken: null,
  rechtzaken: null,
});

export interface IFormikValues {}

export const veldnamen: Record<keyof IFormikValues, string> = {};

const FactuurinfoDialoog: React.FC<IProps> = (props) => {
  const { dialoogIndex, onAnnuleren, onSuccess, open, factID } = props;

  const [tabblad, setTabblad] = useState<ETablad>(ETablad.Factuur);
  const [factuur, setFactuur] = useState<IOphalenFacturenBasisResultElement | null>(null);
  const [aanmaningen, setAanmaningen] = useState<IOphalenAanmaningenResultElement[] | null>(null);
  const [bankopdrachten, setBankopdrachten] = useState<IOphalenOpdrachtenResultElement[]>([]);
  const [relatie, setRelatie] = useState<IOphalenRelatiesResultElementV2 | null>(null);
  const [boekingregels, setBoekingregels] = useState<IOphalenBoekingRegelsResultElement[] | null>(
    null,
  );
  const [boekingen, setBoekingen] = useState<IOphalenBoekingenResultElement[] | null>(null);
  const [incassozaken, setIncassozaken] = useState<IOphalenIncassozakenResultElement[] | null>(
    null,
  );
  const [herincassozaken, setHerincassozaken] = useState<
    IOphalenHerincassozakenResultElement[] | null
  >(null);
  const [rechtzaken, setRechtzaken] = useState<IOphalenRechtzakenResultElement[] | null>(null);

  useEffect(() => {
    (async () => {
      if (factuur === null) {
        return;
      }
      const relatie = (
        await api.v2.relatie.ophalenRelaties({
          filterSchema: {
            filters: [{ naam: 'IDS', data: [factuur.RelID] }],
          },
        })
      ).relaties[0];

      setRelatie(relatie);
    })();
  }, [factuur]);

  useEffect(() => {
    (async () => {
      const factuur = (
        await api.v2.factuur.ophalenFacturenBasis({
          filterSchema: {
            filters: [{ naam: 'IDS', data: [factID] }],
          },
        })
      ).facturen[0];

      setFactuur(factuur);
    })();
  }, [factID]);

  useEffect(() => {
    (async () => {
      if (factuur === null) {
        return;
      }
      const aanmaningen = (
        await api.v2.aanmaning.ophalenAanmaningen({
          filterSchema: {
            filters: [{ naam: 'FACT_IDS', data: [factID] }],
          },
          orderSchema: { orders: [{ naam: 'AANMANINGDATUM', richting: 'DESC' }] },
        })
      ).aanmaningen;

      setAanmaningen(aanmaningen);
    })();
  }, [factuur]);

  useEffect(() => {
    (async () => {
      const bankopdrachten = (
        await api.v2.bank.opdracht.ophalenOpdrachten({
          filterSchema: {
            filters: [{ naam: 'FACT_IDS', data: [factID] }],
          },
        })
      ).opdrachten;

      setBankopdrachten(bankopdrachten);
    })();
  }, [factID]);

  useEffect(() => {
    (async () => {
      if (factuur === null) {
        return;
      }
      const incassozaken = (
        await api.v2.debiteur.incassozaak.ophalenIncassozaken({
          filterSchema: {
            filters: [{ naam: 'FACT_IDS', data: [factID] }],
          },
        })
      ).incassozaken;

      setIncassozaken(incassozaken);
    })();
  }, [factuur]);

  useEffect(() => {
    (async () => {
      if (factuur === null) {
        return;
      }
      const herincassozaken = (
        await api.v2.debiteur.herincassozaak.ophalenHerincassozaken({
          filterSchema: {
            filters: [{ naam: 'FACT_IDS', data: [factID] }],
          },
        })
      ).herincassozaken;

      setHerincassozaken(herincassozaken);
    })();
  }, [factuur]);

  useEffect(() => {
    (async () => {
      if (factuur === null) {
        return;
      }
      const rechtzaken = (
        await api.v2.debiteur.rechtzaak.ophalenRechtzaken({
          filterSchema: {
            filters: [{ naam: 'FACT_IDS', data: [factID] }],
          },
        })
      ).rechtzaken;

      setRechtzaken(rechtzaken);
    })();
  }, [factuur]);

  const ophalenBoekingregels = useCallback(async () => {
    const regelsResult = (
      await api.v2.boeking.ophalenBoekingregels({
        filterSchema: {
          filters: [
            {
              naam: 'FACT_IDS',
              data: [factID],
            },
          ],
        },
        orderSchema: {
          orders: [
            { naam: 'BOEKDATUM', richting: 'DESC' },
            { naam: 'BOEKNUMMER', richting: 'DESC' },
            { naam: 'REGELNUMMER', richting: 'DESC' },
          ],
        },
      })
    ).regels;

    setBoekingregels(regelsResult);
  }, [factID]);

  useEffect(() => {
    ophalenBoekingregels();
  }, [ophalenBoekingregels]);

  const ophalenBoekingen = useCallback(async () => {
    if (boekingregels === null) {
      return;
    }

    const regelsResult = (
      await api.v2.boeking.ophalenBoekingen({
        filterSchema: {
          filters: [
            {
              naam: 'IDS',
              data: _.uniq(boekingregels.map((x) => x.BoekingID)),
            },
          ],
        },
      })
    ).boekingen;

    setBoekingen(regelsResult);
  }, [boekingregels]);

  useEffect(() => {
    ophalenBoekingen();
  }, [ophalenBoekingen]);

  const tabbladen = useMemo<ITabblad<ETablad>[]>(
    () => [
      {
        id: ETablad.Factuur,
        label: 'Factuur',
        content: FactuurTab,
      },
      {
        id: ETablad.Bankopdracht,
        label: 'Bankopdrachten',
        content: BankopdrachtTab,
      },
      {
        id: ETablad.Zaken,
        label: 'Zaken',
        content: ZakenTab,
      },
      {
        id: ETablad.Boeking,
        label: 'Boekingen',
        content: BoekingTab,
      },
    ],
    [onSuccess],
  );

  const initialFormikValues = useMemo<IFormikValues | null>(() => {
    if (factuur === null || relatie === null) {
      return null;
    }

    return {};
  }, [factuur, relatie]);

  return (
    <Dialoog index={dialoogIndex || 0} modalProps={{ size: `xl` }}>
      <ModalHeader>
        <ModalTitle>Factuurinformatie</ModalTitle>
      </ModalHeader>
      <FactuurContext.Provider
        value={{
          factuur,
          aanmaningen,
          relatie,
          bankopdrachten,
          boekingregels,
          boekingen,
          incassozaken,
          herincassozaken,
          rechtzaken,
        }}
      >
        <div className="d-flex flex-column flex-fill" style={{ minHeight: `600px` }}>
          {initialFormikValues === null ? (
            <div className="d-flex flex-fill align-items-center justify-content-center">
              <LoadingSpinner />
            </div>
          ) : (
            <>
              <Formik<IFormikValues>
                isInitialValid
                initialValues={initialFormikValues}
                //   validate={handleValidate}
                onSubmit={() => null}
                render={(FormikProps) => {
                  const { submitForm, isSubmitting, values, errors } = FormikProps;
                  return (
                    <>
                      <div className="d-flex flex-column flex-fill">
                        <Tabblad
                          tabbladen={tabbladen}
                          geselecteerd={tabblad}
                          onSelectieChange={(id) => {
                            setTabblad(id);
                          }}
                        />
                      </div>
                      <ModalFooter className="d-flex flex-row justify-content-start">
                        <button
                          className="btn btn-primary"
                          onClick={onAnnuleren}
                          style={{ width: 100 }}
                        >
                          Sluiten
                        </button>
                        <div className="flex-fill" />
                        <TechnischeInformatieKnop
                          dialoogIndex={(props.dialoogIndex ?? 0) + 1}
                          content={() => <div>FactID: {factID}</div>}
                        />
                      </ModalFooter>
                    </>
                  );
                }}
              />
            </>
          )}
        </div>
      </FactuurContext.Provider>
    </Dialoog>
  );
};

export default FactuurinfoDialoog;
