import React, { useCallback, useContext, useMemo } from 'react';
import {
  DXTableCheckboxComponent,
  DXTableToggleCellComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../helpers/dxTableGrid';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import { DataTypeProvider, RowDetailState, SelectionState } from '@devexpress/dx-react-grid';
import RelatieVisualisatie from '../../personalia/RelatieVisualisatie';
import { format } from 'date-fns';
import BooleanWeergave from '../../tabel/BooleanWeergave';
import RowDetail from './RowDetail';
import _ from 'lodash';
import MenuLayout from '../../../components/MenuLayout';
import { IconSend, IconTransport, IconVerwijderen, IconVlag } from '../../Icons';
import { Kleur as EKleur } from '../../../bedrijfslogica/constanten';
import api from '../../../api';
import { EResultType } from '../../../stores/CheckStore';
import { RootStoreContext } from '../../../stores/RootStore';
import {
  IOphalenWinkelwagensResultElement,
  IRegelContract,
} from '../../../../../shared/src/api/v2/winkelwagen';
import nameOf from '../../../core/nameOf';
import { IOphalenAccountsResultElement } from '../../../../../shared/src/api/v2/account/account';
import { IOphalenContractenResultElement } from '../../../../../shared/src/api/v2/contract';

export interface IRow extends IOphalenWinkelwagensResultElement {
  account: IOphalenAccountsResultElement | null;
  contracten?: IOphalenContractenResultElement[];
}

export enum ESoort {
  Product = 1,
  Verkoopmodel = 2,
  Contractbeeindiging = 3,
}

interface IProps {
  winkelwagens: IRow[];
  selectie: number[];
  onSelectieChange: (selectie: number[]) => void;
  uitgeklapt: number[];
  onUitgeklaptChange: (uitgeklapt: number[]) => void;
  geenRelatieWeergeven?: boolean;
  onRequestRefresh: () => void;
  filterbalk?: JSX.Element;
}

const WinkelwagenTabel: React.FC<IProps> = (props) => {
  const { checkStore } = useContext(RootStoreContext);

  // const rows = useMemo<IRow[]>(() => {
  //   const winkelwagens = props.winkelwagens.map((winkelwagen) => ({
  //     ...winkelwagen,
  //   }));

  //   // const bestellingenGesorteerd = _.orderBy(winkelwagens, ['RecordGeregistreerd'], ['desc']);

  //   return winkelwagens;
  // }, [props.winkelwagens]);

  const handleVersturenBevestiging = useCallback(async (cartID: number) => {
    if (
      (
        await checkStore.bevestigen({
          inhoud: `Bevestiging versturen?`,
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }
    await api.v2.extern.bestelling.versturenBevestigingBestelling({
      cartID,
    });
  }, []);

  const handleVerwijderen = useCallback(async () => {
    const params = { cartIDs: props.selectie };

    const checkData = await api.v2.bestelling.checkVerwijderenBestellingen(params);
    if (
      (
        await checkStore.controleren({
          checkData,
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    if (
      (
        await checkStore.bevestigen({
          inhoud: `Geselecteerde bestellingen verwijderen?`,
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    await api.v2.bestelling.verwijderenBestellingen(params);
    props.onSelectieChange([]);
    props.onRequestRefresh();
  }, [props.selectie]);

  const handleVerwerken = useCallback(async () => {
    const params = { cartIDs: props.selectie };

    // const checkData = await api.v2.winkelwagen.(params);
    // if (
    //   (
    //     await checkStore.controleren({
    //       checkData,
    //     })
    //   ).type === EResultType.Annuleren
    // ) {
    //   return;
    // }

    if (
      (
        await checkStore.bevestigen({
          inhoud: `Geselecteerde bestellingen verwerken?`,
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    await api.v2.winkelwagen.verwerkWinkelwagens(params);

    props.onRequestRefresh();
  }, [props.selectie]);

  const kolommen = useMemo<TypedColumn<IRow>[]>(
    () =>
      [
        {
          name: 'RecordGewijzigd',
          title: 'Laatst gewijzigd',
        },
        {
          name: 'RecordToegevoegd',
          title: 'Registratie',
        },
        props.geenRelatieWeergeven
          ? null
          : {
              name: 'Besteldatum',
              title: 'Besteldatum',
            },
        {
          name: 'DatumVerwerkt',
          title: 'Verwerkt',
        },
        props.geenRelatieWeergeven
          ? null
          : {
              name: '__relatie' as any,
              title: 'Relatie',
            },
        {
          name: '__locatiePlaats' as any,
          title: 'Plaatsnaam',
        },
        {
          name: 'Bezoekdatum',
          title: 'Bezoekdatum',
        },
        {
          name: 'AantalAfvoer',
          title: '# Afv.',
        },
        {
          name: 'Ingelogd',
          title: 'Ingelogd',
        },
        {
          name: 'Geblokkeerd',
          title: 'Geblok.',
        },
        {
          name: '__wissel' as any,
          title: 'Wis.',
        },
        {
          name: 'Actiecode',
          title: 'Actiecode',
        },
        // {
        //   name: 'Wervingscode',
        //   title: 'Wervingscode',
        // },
        {
          name: 'Opmerking',
          title: 'Opmerking',
        },
      ].filter((x) => x !== null) as TypedColumn<IRow>[],
    [props.geenRelatieWeergeven],
  );

  const keyExtractor = useCallback((row: IRow) => row.ID, []);

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRow>[]>(
    () => [
      {
        columnName: 'RecordToegevoegd',
        width: 150,
      },
      {
        columnName: 'RecordGewijzigd',
        width: 150,
      },
      {
        columnName: 'Besteldatum',
        width: 150,
      },
      {
        columnName: 'DatumVerwerkt',
        width: 150,
      },
      {
        columnName: '__relatie' as any,
        width: 225,
      },
      {
        columnName: '__locatiePlaats' as any,
        width: 135,
      },
      {
        columnName: 'Bezoekdatum',
        width: 125,
      },
      {
        columnName: 'AantalAfvoer',
        width: 80,
      },
      {
        columnName: 'Geblokkeerd',
        width: 100,
      },
      {
        columnName: '__wissel' as any,
        width: 80,
      },
      {
        columnName: 'Ingelogd',
        width: 100,
      },
      {
        columnName: 'Actiecode',
        width: 150,
      },
      // {
      //   columnName: 'Wervingscode',
      //   width: 100,
      // },
      {
        columnName: 'Opmerking',
        width: 400,
      },
    ],
    [],
  );

  return (
    <>
      <MenuLayout
        menu={
          <div className="d-flex align-items-center">
            <div className="d-flex align-items-center">
              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-3"
                style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                disabled={props.selectie.length !== 1}
                onClick={() => {
                  const cartID = props.selectie[0];
                  handleVersturenBevestiging(cartID);
                }}
              >
                <span>
                  <IconSend style={{ width: 16, height: 16, fill: EKleur.Grijs }} />
                </span>
                <span className="ml-2">Bevestiging versturen</span>
              </button>
              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-3"
                style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                disabled={props.selectie.length === 0}
                onClick={() => {
                  handleVerwijderen();
                }}
              >
                <span>
                  <IconVerwijderen style={{ width: 16, height: 16, fill: EKleur.Grijs }} />
                </span>
                <span className="ml-2">Verwijderen</span>
              </button>
              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-3"
                style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                disabled={props.selectie.length === 0}
                onClick={() => {
                  handleVerwerken();
                }}
              >
                <span>
                  <IconVlag style={{ width: 16, height: 16, fill: EKleur.Rood }} />
                </span>
                <span className="ml-2">Verwerken</span>
              </button>
              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-3"
                style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                disabled={props.selectie.length !== 1}
                onClick={async () => {
                  const params = { cartIDs: [props.selectie[0]] };
                  if (
                    (
                      await checkStore.bevestigen({
                        inhoud: `Transportopdrachten maken voor de geselecteerde winkelwagen?`,
                      })
                    ).type === EResultType.Annuleren
                  ) {
                    return;
                  }

                  await api.v2.winkelwagen.makenTransportopdrachtenVoorWinkelwagens(params);
                }}
              >
                <span>
                  <IconTransport style={{ width: 16, height: 16, fill: EKleur.Grijs }} />
                </span>
                <span className="ml-2">Transportopdrachten maken</span>
              </button>
            </div>
            <div className="d-flex flex-fill ml-2">{props.filterbalk}</div>
          </div>
        }
        body={
          <GridStyleWrapper height={'calc(100vh - 100px)'}>
            <Grid rows={props.winkelwagens} columns={kolommen} getRowId={keyExtractor}>
              <DataTypeProvider
                for={['__relatie']}
                formatterComponent={(formatterProps) => {
                  const row = formatterProps.row as IRow;
                  if (row.account === null) {
                    return <span />;
                  }
                  return <RelatieVisualisatie relID={row.account.RelID} />;
                }}
              />
              <DataTypeProvider
                for={[nameOf<IRow>('RecordToegevoegd'), nameOf<IRow>('RecordGewijzigd')]}
                formatterComponent={(formatterProps) => {
                  const datum = new Date(formatterProps.value);
                  return <span>{format(datum, 'dd-MM-yyyy HH:mm')}</span>;
                }}
              />
              <DataTypeProvider
                for={[nameOf<IRow>('Besteldatum')]}
                formatterComponent={(formatterProps) => {
                  const row = formatterProps.row as IRow;
                  if (formatterProps.value === null) {
                    return <span>Nog niet afgerond</span>;
                  }
                  const datum = new Date(formatterProps.value);
                  return <span>{format(datum, 'dd-MM-yyyy HH:mm')}</span>;
                }}
              />
              <DataTypeProvider
                for={['Bezoekdatum']}
                formatterComponent={(formatterProps) => {
                  const row = formatterProps.row as IRow;
                  if (row.Bezoekdatum === null) {
                    return <span>Niet opgegeven</span>;
                  }
                  const datum = new Date(row.Bezoekdatum);
                  return <span>{format(datum, 'dd-MM-yyyy')}</span>;
                }}
              />
              <DataTypeProvider
                for={['DatumVerwerkt']}
                formatterComponent={(formatterProps) => {
                  const row = formatterProps.row as IRow;

                  if (row.Besteldatum === null) {
                    return <span>-</span>;
                  }

                  if (row.DatumVerwerkt === null) {
                    return <span style={{ color: EKleur.Rood }}>Niet verwerkt</span>;
                  }
                  const datum = new Date(row.DatumVerwerkt);
                  return <span>{format(datum, 'dd-MM-yyyy HH:mm')}</span>;
                }}
              />

              <DataTypeProvider
                for={['Ingelogd']}
                formatterComponent={(formatterProps) => {
                  const row = formatterProps.row as IRow;
                  if (row.Ingelogd === null) {
                    return <span>Onbekend</span>;
                  }
                  return <BooleanWeergave waarde={row.Ingelogd} />;
                }}
              />

              <DataTypeProvider
                for={['__locatiePlaats']}
                formatterComponent={(formatterProps) => {
                  const row = formatterProps.row as IRow;
                  return <span>{row.locatie.Plaatsnaam}</span>;
                }}
              />

              <DataTypeProvider
                for={['Geblokkeerd']}
                formatterComponent={(formatterProps) => {
                  const row = formatterProps.row as IRow;
                  return <span style={{ color: EKleur.Rood }}>{row.Geblokkeerd ? 'Ja' : ''}</span>;
                }}
              />

              <DataTypeProvider
                for={['__wissel']}
                formatterComponent={(formatterProps) => {
                  const row = formatterProps.row as IRow;

                  return (
                    <span>
                      {row.regels.some((x) => (x as IRegelContract).Soort === 3) ? 'Ja' : ''}
                    </span>
                  );
                }}
              />
              <SelectionState
                selection={props.selectie}
                onSelectionChange={(value) => props.onSelectieChange(value as number[])}
              />
              <RowDetailState
                expandedRowIds={props.uitgeklapt}
                onExpandedRowIdsChange={(value) => props.onUitgeklaptChange(value as number[])}
              />
              <VirtualTable
                messages={{
                  noData: 'Geen winkelwagens',
                }}
              />
              <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
              <TableHeaderRow />
              <TableRowDetail
                toggleCellComponent={DXTableToggleCellComponent}
                contentComponent={RowDetail}
              />
              <TableSelection cellComponent={DXTableCheckboxComponent} />
            </Grid>
          </GridStyleWrapper>
        }
      />
    </>
  );
};

export default WinkelwagenTabel;
