import { Redirect, Route, RouteComponentProps } from 'react-router';
import React from 'react';
import PaginaHeader from '../PaginaHeader';
import { CommunicatieNavigatie } from '../Communicatie/styles';
import { NavLink } from 'react-router-dom';
import { IconSettings, IconPersonen, IconImportExport } from '../../components/Icons';
import Instellingen from '../Beheer/Instellingen';
import Gebruikers from '../Beheer/Gebruikers';
import RollenEnPermissies from '../Beheer/RollenEnPermissies';

interface IProps extends RouteComponentProps<{}> {}

const Beheer: React.FC<IProps> = (props) => {
  const { match } = props;
  return (
    <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
      <PaginaHeader>
        <CommunicatieNavigatie>
          <li>
            <NavLink
              to={`${match.url}/instellingen`}
              style={{ paddingLeft: 20, paddingRight: 20 }}
              className="d-flex align-items-center justify-content-center"
            >
              <IconSettings />
              &nbsp; Instellingen
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`${match.url}/gebruikers`}
              style={{ paddingLeft: 20, paddingRight: 20 }}
              className="d-flex align-items-center justify-content-center"
            >
              <IconPersonen />
              &nbsp; Gebruikers
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`${match.url}/rollen`}
              style={{ paddingLeft: 20, paddingRight: 20 }}
              className="d-flex align-items-center justify-content-center"
            >
              <IconSettings />
              &nbsp; Rollen
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`${match.url}/bedrijfsprofiel`}
              style={{ paddingLeft: 20, paddingRight: 20 }}
              className="d-flex align-items-center justify-content-center"
            >
              <IconSettings />
              &nbsp; Bedrijfsprofiel
            </NavLink>
          </li>
        </CommunicatieNavigatie>
      </PaginaHeader>

      <Route path={`${match.path}/instellingen`} component={Instellingen} />
      <Route path={`${match.path}/gebruikers`} component={Gebruikers} />
      <Route path={`${match.path}/rollen`} component={RollenEnPermissies} />

      <Route
        exact={true}
        path={match.path}
        render={() => <Redirect to={`${match.path}/instellingen`} />}
      />
    </div>
  );
};

export default Beheer;
