import React, { useCallback, useContext, useEffect, useState } from 'react';
import useUrlState from '../../../../../../core/useUrlState';
import { RootStoreContext } from '../../../../../../stores/RootStore';
import api from '../../../../../../api';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import LoadingSpinner from '../../../../../../components/Gedeeld/LoadingSpinner';
import { IEntiteitProps } from '../../../../../../components/kaart/EntiteitContainer';
import WinkelwagenTabel, {
  IRow as IRegel,
} from '../../../../../../components/winkelwagen/WinkelwagenTabel';
import _ from 'lodash';

interface IProps extends IEntiteitProps, RouteComponentProps {}

// export interface IRegel extends IOphalenWinkelwagensResultElement {}

export interface IUrlState {
  selectie: number[];
  uitgeklapt: number[];
}

export const defaultUrlState: IUrlState = {
  selectie: [],
  uitgeklapt: [],
};

const Winkelwagens: React.FC<IProps> = (props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const { klantkaartStore } = useContext(RootStoreContext);

  const [winkelwagensResult, setWinkelwagensResult] = useState<IRegel[] | null>(null);

  // const ophalenBestellingen = useCallback(async () => {
  //   if (klantkaartStore.relatie === null) {
  //     setBestellingenResult(null);
  //     return;
  //   }
  //   const result = await api.v2.website.bestelling.ophalenBestellingen({
  //     filterSchema: {
  //       filters: [
  //         {
  //           naam: 'REL_IDS',
  //           data: [props.relID],
  //         },
  //       ],
  //     },
  //   });

  //   setBestellingenResult(result);
  // }, [props.relID]);

  // useEffect(() => {
  //   ophalenBestellingen();
  // }, [ophalenBestellingen]);

  const ophalenWinkelwagens = useCallback(async () => {
    if (klantkaartStore.relatie === null) {
      setWinkelwagensResult(null);
      return;
    }
    const winkelwagensResult = (
      await api.v2.winkelwagen.ophalenWinkelwagens({
        filterSchema: {
          filters: [
            {
              naam: 'REL_IDS',
              data: [props.relID],
            },
          ],
        },
      })
    ).winkelwagens;

    const accIDs = _.uniq(winkelwagensResult.map((x) => x.AccID));
    const accountsResult = await api.v2.account.ophalenAccounts({
      filterSchema: { filters: [{ naam: 'IDS', data: accIDs }] },
    });

    const winkelwagens = winkelwagensResult.map((x) => {
      const account = x.AccID !== null ? accountsResult.find((a) => a.AccID === x.AccID)! : null;
      return { ...x, account };
    });

    setWinkelwagensResult(winkelwagens);
  }, [props.relID]);

  useEffect(() => {
    ophalenWinkelwagens();
  }, [ophalenWinkelwagens]);

  return (
    <div className="d-flex flex-fill flex-column" style={{ width: '100%', height: '100%' }}>
      {winkelwagensResult === null ? (
        <LoadingSpinner />
      ) : (
        // <BestellingenTabel
        //   bestellingen={bestellingenResult.bestellingen}
        //   selectie={urlState.selectie}
        //   onSelectieChange={(x) => setUrlStateSync('selectie', x)}
        //   uitgeklapt={urlState.uitgeklapt}
        //   onUitgeklaptChange={(x) => setUrlStateSync('uitgeklapt', x)}
        //   geenRelatieWeergeven
        //   onRequestRefresh={() => ophalenBestellingen()}
        // />
        <WinkelwagenTabel
          winkelwagens={winkelwagensResult}
          selectie={urlState.selectie}
          onSelectieChange={(x) => setUrlStateSync('selectie', x)}
          uitgeklapt={urlState.uitgeklapt}
          onUitgeklaptChange={(x) => setUrlStateSync('uitgeklapt', x)}
          geenRelatieWeergeven
          onRequestRefresh={() => ophalenWinkelwagens()}
        />
      )}
    </div>
  );
};

export default withRouter(Winkelwagens);
