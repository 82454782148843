import React, { useMemo } from 'react';
import { matchPath, Redirect, Route, RouteComponentProps } from 'react-router-dom';
import Tabblad, { ITabblad } from '../../../components/layout/Tabblad';
import { Root } from '../../Inkoop/Aanbod/style';
import Concurrenten from './Concurrenten';
import Producten from './Producten';
import Data from './Data';
import Overzicht from './Overzicht';
import Scans from './Scans';

export enum ETabblad {
  Overzicht = 1,
  Scans = 2,
  Concurrenten = 3,
  Producten = 4,
  Data = 5,
}

const urlTabbladMap: Record<ETabblad, string> = {
  [ETabblad.Overzicht]: 'overzicht',
  [ETabblad.Scans]: 'scans',
  [ETabblad.Concurrenten]: 'concurrenten',
  [ETabblad.Producten]: 'producten',
  [ETabblad.Data]: 'data',
};

interface IProps extends RouteComponentProps {}

const Aanbod: React.FC<IProps> = (props) => {
  const tabbladen = useMemo<ITabblad<ETabblad>[]>(
    () => [
      {
        id: ETabblad.Overzicht,
        label: 'Overzicht',
        content: Overzicht,
      },
      {
        id: ETabblad.Scans,
        label: 'Scans',
        content: Scans,
      },
      {
        id: ETabblad.Concurrenten,
        label: 'Concurrenten',
        content: Concurrenten,
      },
      {
        id: ETabblad.Producten,
        label: 'Producten',
        content: Producten,
      },
      {
        id: ETabblad.Data,
        label: 'Data',
        content: Data,
      },
    ],
    [],
  );

  const tabblad = useMemo<ETabblad | null>(() => {
    const paths = Object.keys(urlTabbladMap).map((key) => {
      const tabblad = Number(key) as ETabblad;
      const url = urlTabbladMap[tabblad];
      return {
        tabblad,
        path: `${props.match.path}/${url}`,
      };
    });
    for (const pathObj of paths) {
      const match = matchPath(props.location.pathname, pathObj.path);
      if (match !== null) {
        return pathObj.tabblad;
      }
    }

    return null;
  }, [props.location.pathname]);

  return (
    <>
      <Route
        exact
        path={props.match.path}
        component={() => (
          <Redirect to={`${props.match.path}/${urlTabbladMap[ETabblad.Overzicht]}`} />
        )}
      />
      {tabblad === null ? (
        <Redirect to={`${props.match.path}/${urlTabbladMap[ETabblad.Overzicht]}`} />
      ) : (
        <Root>
          <Tabblad
            geselecteerd={tabblad}
            onSelectieChange={(identifier) =>
              props.history.push(`${props.match.path}/${urlTabbladMap[identifier]}`)
            }
            tabbladen={tabbladen}
          />
        </Root>
      )}
    </>
  );
};

export default Aanbod;
