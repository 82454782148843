import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { RouteComponentProps } from 'react-router-dom';
import MenuLayout from '../../../components/MenuLayout';
import { IconToevoegen } from '../../../components/Icons';
import { Kleur as EKleur } from '../../../bedrijfslogica/constanten';
import { IOphalenMagazijnenResultElement } from '../../../../../shared/src/api/v2/magazijn';
import api from '../../../api';

import LoadingSpinner from '../../../components/Gedeeld/LoadingSpinner';

import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  TableSelection,
  TableEditColumn,
  VirtualTable,
  TableRowDetail,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  DXCommandComponent,
  DXTableCheckboxComponent,
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
  DXTableToggleCellComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../helpers/dxTableGrid';
import {
  DataTypeProvider,
  EditingState,
  IntegratedSorting,
  RowDetailState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import useUrlState from '../../../core/useUrlState';
import AdresVisualisatie from '../../../components/locatie/AdresVisualisatie';
import { formatteerAdresV2 } from '../../../helpers';
import ToevoegenDialoog from './ToevoegenDialoog';
import { RootStoreContext } from '../../../stores/RootStore';
import { EResultType } from '../../../stores/CheckStore';
import WijzigenDialoog from './WijzigenDialoog';
import FilterBalkV2, {
  IFilter,
  IFilterData,
  maakFilterSchema,
} from '../../../components/FilterBalkV2';

interface IProps extends RouteComponentProps<{}> {}

export interface IToevoegenDialoogState {}

export interface IWijzigenDialoogState {
  magID: number;
}

const enum EFilter {
  Actief = 'IS_ACTIEF',
}

interface IUrlState {
  selectie: number[];
  toevoegenDialoogState: IToevoegenDialoogState | null;
  wijzigenDialoogState: IWijzigenDialoogState | null;
  filterData: IFilterData<EFilter>[];
}

const defaultUrlState: IUrlState = {
  selectie: [],
  toevoegenDialoogState: null,
  wijzigenDialoogState: null,
  filterData: [
    {
      naam: EFilter.Actief,
      data: true,
      isActief: true,
    },
  ],
};

const Beheer: React.FC<IProps> = (props) => {
  const { checkStore } = useContext(RootStoreContext);
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);

  const [magazijnen, setMagazijnen] = useState<IOphalenMagazijnenResultElement[] | null>(null);

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.Actief,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return <span>Alleen actieve magazijnen</span>;
        },
      },
    ],
    [],
  );

  const [filterSchema, setFilterSchema] = useState(maakFilterSchema(urlState.filterData));

  const ophalenMagazijnen = useCallback(async () => {
    const resultaat = await api.v2.magazijn.ophalenMagazijnen({
      filterSchema,
      orderSchema: { orders: [{ naam: 'NAAM_KORT', richting: 'ASC' }] },
    });
    setMagazijnen(resultaat.magazijnen);
  }, [filterSchema]);

  useEffect(() => {
    ophalenMagazijnen();
  }, [ophalenMagazijnen]);

  const keyExtractor = useCallback((row: IOphalenMagazijnenResultElement) => row.MagID, []);

  const kolommen = useMemo<TypedColumn<IOphalenMagazijnenResultElement>[]>(
    () => [
      {
        name: 'NaamKort',
        title: 'Naam kort',
        getCellValue: (x) => x.NaamKort,
      },
      {
        name: 'Naam',
        title: 'Naam',
        getCellValue: (x) => x.Naam,
      },
      {
        name: '__locatie' as any,
        title: 'Adres',
        getCellValue: (x) => (
          <AdresVisualisatie
            weergaveTekst={formatteerAdresV2({
              bisnummer: x.locatie.Bisnummer,
              straatnaam: x.locatie.Straatnaam,
              huisnummer: x.locatie.Huisnummer,
              postcode: x.locatie.Postcode,
              plaatsnaam: x.locatie.Plaatsnaam,
              landnaamKort: x.locatie.LandnaamKort,
              landnaamEnum: x.locatie.LandnaamEnum,
            })}
          />
        ),
      },
      {
        name: 'VoorraadMagazijn',
        title: 'Voorraadmagazijn',
        getCellValue: (x) => (x.VoorraadMagazijn ? 'Ja' : ''),
      },
      {
        name: 'RevisieMagazijn',
        title: 'Revisiemagazijn',
        getCellValue: (x) => (x.RevisieMagazijn ? 'Ja' : ''),
      },
      {
        name: 'Actief',
        title: 'Actief',
        getCellValue: (x) => (x.Actief ? 'Ja' : 'Nee'),
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IOphalenMagazijnenResultElement>[]>(
    () => [
      {
        columnName: 'NaamKort' as any,
        width: 165,
      },
      {
        columnName: 'Naam' as any,
        width: 200,
      },
      {
        columnName: '__locatie' as any,
        width: 400,
      },
      {
        columnName: 'VoorraadMagazijn' as any,
        width: 175,
      },
      {
        columnName: 'RevisieMagazijn' as any,
        width: 150,
      },
      {
        columnName: 'Actief',
        width: 125,
      },
    ],
    [],
  );

  return (
    <>
      <div>
        <Helmet>Beheer</Helmet>
        <MenuLayout
          menu={
            <>
              <div className="d-flex align-items-center">
                <button
                  className="btn btn-sm btn-light d-flex align-items-center"
                  style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                  onClick={() => {
                    setUrlStateSync('toevoegenDialoogState', {});
                  }}
                >
                  <IconToevoegen style={{ width: 16, height: 16, fill: EKleur.Grijs }} />
                  <span className="ml-2">Toevoegen magazijn</span>
                </button>

                <div className="ml-3 d-flex flex-fill">
                  <FilterBalkV2
                    filters={filters}
                    filterData={urlState.filterData}
                    onFilterDataChange={(x) => setUrlStateSync('filterData', x)}
                    onFilterSchemaChange={(x) => setFilterSchema(x)}
                  />
                </div>
              </div>
            </>
          }
          body={
            <>
              {magazijnen === null ? (
                <LoadingSpinner />
              ) : (
                <div className="bg-white" style={{ height: 'calc(100vh - 40px)' }}>
                  <GridStyleWrapper height="calc(100vh - 150px)">
                    <Grid rows={magazijnen} columns={kolommen} getRowId={keyExtractor}>
                      {/* <DataTypeProvider
                      for={['__relatie']}
                      formatterComponent={(formatterProps) => {
                        const rij: IOphalenDienstenResultElement = formatterProps.row;
                        return (
                          <span className="d-flex">
                            <RelatieVisualisatie relID={formatterProps.row.RelID} />
                            {rij.Naam !== null && <span>&nbsp;({rij.Naam}) </span>}
                          </span>
                        );
                      }}
                    /> */}

                      <RowDetailState defaultExpandedRowIds={[]} />

                      <SortingState defaultSorting={[]} />
                      <IntegratedSorting />

                      <VirtualTable />
                      <TableColumnResizing defaultColumnWidths={kolomBreedtes} />

                      <EditingState
                        onCommitChanges={async (changes) => {
                          if (changes.deleted === undefined) {
                            return;
                          }
                          const deleted = changes.deleted;
                          const id = deleted[deleted.length - 1] as number;

                          const params = { magIDs: [id] };
                          const checkData = await api.v2.magazijn.checkVerwijderenMagazijnen(
                            params,
                          );
                          if (
                            (
                              await checkStore.controleren({
                                checkData,
                              })
                            ).type === EResultType.Annuleren
                          ) {
                            return;
                          }

                          if (
                            (
                              await checkStore.bevestigen({
                                inhoud: <span>Wil je dit magazijn verwijderen?</span>,
                              })
                            ).type === EResultType.Annuleren
                          ) {
                            return;
                          }

                          await api.v2.magazijn.verwijderenMagazijnen(params);
                          ophalenMagazijnen();
                        }}
                        onEditingRowIdsChange={(rowIds) => {
                          const id = rowIds[rowIds.length - 1] as number;
                          setUrlStateSync('wijzigenDialoogState', {
                            magID: id,
                          });
                        }}
                      />

                      <TableEditColumn
                        width={65}
                        showEditCommand
                        showDeleteCommand
                        cellComponent={DXTableEditColumnCellComponent}
                        commandComponent={DXTableEditColumnCommandComponent}
                      />
                      <TableHeaderRow showSortingControls />
                      <SelectionState
                        selection={urlState.selectie}
                        onSelectionChange={(x) => {
                          // setUrlStateSync('selectie', x as number[]);
                        }}
                      />
                      {/* <TableRowDetail
                  contentComponent={DetailComp}
                  toggleCellComponent={DXTableToggleCellComponent}
                /> */}
                      <TableSelection cellComponent={DXTableCheckboxComponent} />
                    </Grid>
                  </GridStyleWrapper>
                </div>
              )}
            </>
          }
        ></MenuLayout>
      </div>
      {urlState.toevoegenDialoogState !== null && (
        <ToevoegenDialoog
          onSuccess={() => {
            setUrlStateSync('toevoegenDialoogState', null);
            ophalenMagazijnen();
          }}
          onAnnuleren={() => setUrlStateSync('toevoegenDialoogState', null)}
          open={true}
        />
      )}
      {urlState.wijzigenDialoogState !== null && (
        <WijzigenDialoog
          magID={urlState.wijzigenDialoogState.magID}
          onSuccess={() => {
            setUrlStateSync('wijzigenDialoogState', null);
            ophalenMagazijnen();
          }}
          onAnnuleren={() => setUrlStateSync('wijzigenDialoogState', null)}
          open={true}
        />
      )}
    </>
  );
};

export default Beheer;
