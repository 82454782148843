import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import MenuLayout from '../../../../components/MenuLayout';
import useUrlState from '../../../../core/useUrlState';
import SelectieVak from '../../../../components/SelectieVak';
import FilterBalkV2, {
  IFilter,
  IFilterData,
  maakFilterSchema,
} from '../../../../components/FilterBalkV2';
import {
  IEigenschapProduct,
  IOphalenEigenschapProductenResult,
  IVerwijderenEigenschapProductenParams,
  IToevoegenEigenschapProductParams,
  IWijzigenEigenschapProductParams,
  IEigenschapProductEntiteit,
  IOphalenEigenschapGroepenResult,
} from '../../../../../../shared/src/api/v2/aanbod/index';
import api from '../../../../api';
import {
  DXTableCheckboxComponent,
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
  DXTableToggleCellComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import {
  Grid,
  VirtualTable,
  TableHeaderRow,
  TableColumnResizing,
  TableSelection,
  TableRowDetail,
  TableEditColumn,
} from '@devexpress/dx-react-grid-bootstrap4';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import {
  DataTypeProvider,
  SelectionState,
  RowDetailState,
  EditingState,
} from '@devexpress/dx-react-grid';
import nameof from '../../../../core/nameOf';
import TekstVisualisatie from '../../../../components/TekstVisualisatie';
import { IconKruis, IconVink } from '../../../../components/Icons';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import { RootStoreContext } from '../../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import { EResultType } from '../../../../stores/CheckStore';
import MuterenDialoog, { IFormikValues } from './MuterenDialoog';
import { IOphalenTalenResult } from '../../../../../../shared/src/api/v2/taal';
import VinkVeld from '../../../../components/formulier/VinkVeld';
import VerticaleScheidingslijn from '../../../../components/layout/VerticaleScheidingslijn';
import Combobox from '../../../../components/formulier/Combobox';
import _ from 'lodash';
import ITaalTekst from '../../../../../../shared/src/models/talen/ITaalTekst';
import { Helmet } from 'react-helmet';

enum EFilter {
  CodeLike = 'CODE_LIKE',
  NaamLikeStandaardTaal = 'NAAM_LIKE_STANDAARD_TAAL',
  EenheidLikeStandaardTaal = 'EENHEID_LIKE_STANDAARD_TAAL',
  InfoLikeStandaardTaal = 'INFO_LIKE_STANDAARD_TAAL',
  TonenOpWebsite = 'TONEN_OP_WEBSITE',
  Groepen = 'GROEPEN',
}

interface IWijzigenState {
  eigProdID: number;
  initialValues: IFormikValues;
}

interface IUrlState {
  selectie: number[];
  wijzigenState: IWijzigenState | null;
  nieuwState: boolean;
  filterData: IFilterData<EFilter>[];
  meertaligeWeergave: boolean;
}

const defaultUrlState: IUrlState = {
  selectie: [],
  wijzigenState: null,
  nieuwState: false,
  meertaligeWeergave: false,
  filterData: [
    {
      naam: EFilter.CodeLike,
      data: '',
      isActief: false,
    },
    {
      naam: EFilter.NaamLikeStandaardTaal,
      data: '',
      isActief: false,
    },
    {
      naam: EFilter.EenheidLikeStandaardTaal,
      data: '',
      isActief: false,
    },
    {
      naam: EFilter.InfoLikeStandaardTaal,
      data: '',
      isActief: false,
    },
    {
      naam: EFilter.TonenOpWebsite,
      data: true,
      isActief: false,
    },
    {
      naam: EFilter.Groepen,
      data: null,
      isActief: false,
    },
  ],
};

interface IProps extends RouteComponentProps {}

const Beheer: React.FC<IProps> = observer((props) => {
  const { checkStore, tekstStore } = useContext(RootStoreContext);
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const [filterSchema, setFilterSchema] = useState(maakFilterSchema(urlState.filterData));
  const [
    eigenschapGroepenResult,
    setEigenschapGroepenResult,
  ] = useState<IOphalenEigenschapGroepenResult | null>(null);
  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.CodeLike,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => (
          <div className="d-flex align-items-center">
            <span className="mr-2">Code</span>
            <input
              type="text"
              className="form-control"
              value={weergaveProps.data}
              onChange={(ev) => weergaveProps.onDataChange(ev.target.value)}
              onKeyUp={(ev) => {
                if (ev.key === 'Enter') {
                  weergaveProps.toepassen();
                }
              }}
            />
          </div>
        ),
      },
      {
        naam: EFilter.NaamLikeStandaardTaal,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => (
          <div className="d-flex align-items-center">
            <span className="mr-2">Naam</span>
            <input
              type="text"
              className="form-control"
              value={weergaveProps.data}
              onChange={(ev) => weergaveProps.onDataChange(ev.target.value)}
              onKeyUp={(ev) => {
                if (ev.key === 'Enter') {
                  weergaveProps.toepassen();
                }
              }}
            />
          </div>
        ),
      },
      {
        naam: EFilter.EenheidLikeStandaardTaal,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => (
          <div className="d-flex align-items-center">
            <span className="mr-2">Eenheid</span>
            <input
              type="text"
              className="form-control"
              value={weergaveProps.data}
              onChange={(ev) => weergaveProps.onDataChange(ev.target.value)}
              onKeyUp={(ev) => {
                if (ev.key === 'Enter') {
                  weergaveProps.toepassen();
                }
              }}
            />
          </div>
        ),
      },
      {
        naam: EFilter.InfoLikeStandaardTaal,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => (
          <div className="d-flex align-items-center">
            <span className="mr-2">Info</span>
            <input
              type="text"
              className="form-control"
              value={weergaveProps.data}
              onChange={(ev) => weergaveProps.onDataChange(ev.target.value)}
              onKeyUp={(ev) => {
                if (ev.key === 'Enter') {
                  weergaveProps.toepassen();
                }
              }}
            />
          </div>
        ),
      },
      {
        naam: EFilter.Groepen,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return (
            <div className="d-flex align-items-center">
              <span className="mr-2">Groep</span>
              {eigenschapGroepenResult === null ? (
                <LoadingSpinner />
              ) : (
                <Combobox
                  geselecteerd={weergaveProps.data}
                  onSelectieChange={(x) => {
                    weergaveProps.onDataChange(x);
                    weergaveProps.toepassen();
                  }}
                  opties={eigenschapGroepenResult.groepen.map((groep) => ({
                    id: groep.ID,
                    label: <TekstVisualisatie tekstID={groep.Naam_TekstID} />,
                  }))}
                />
              )}
            </div>
          );
        },
      },
      {
        naam: EFilter.TonenOpWebsite,
        altijdWeergevenInBalk: true,
        weergave: () => <span>Tonen op website</span>,
      },
    ],
    [eigenschapGroepenResult],
  );
  const [
    eigenschapProductenResult,
    setEigenschapProductenResult,
  ] = useState<IOphalenEigenschapProductenResult | null>(null);
  const ophalenEigenschapProducten = useCallback(async () => {
    const result = await api.v2.aanbod.specificatie.ophalenEigenschapProducten({
      filterSchema,
    });
    setEigenschapProductenResult(result);
  }, [filterSchema]);
  const ophalenEigenschapGroepen = useCallback(async () => {
    const result = await api.v2.aanbod.specificatie.ophalenEigenschapGroepen({
      filterSchema: {
        filters: [],
      },
    });
    setEigenschapGroepenResult(result);
  }, []);
  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    ophalenEigenschapProducten();
  }, [ophalenEigenschapProducten]);
  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    ophalenEigenschapGroepen();
  }, [ophalenEigenschapGroepen]);
  const [talen, setTalen] = useState<IOphalenTalenResult | null>(null);
  useEffect(() => {
    (async () => {
      const result = await api.v2.taal.ophalen({ filterSchema: { filters: [] } });
      setTalen(result);
    })();
  }, []);

  const keyExtractor = useCallback((row: IEigenschapProduct) => row.ID, []);
  // useEffect(() => {
  //   if (urlState.selectie.length === 0) {
  //     return;
  //   }
  //   if (eigenschapProductenResult === null) {
  //     setUrlStateSync('selectie', []);
  //     return;
  //   }
  //   const mogelijkeKeys = eigenschapProductenResult.producten.map((x) => keyExtractor(x));
  //
  //   const nietMogelijkeKeysDieVoorkomen = urlState.selectie.filter(
  //     (x) => !mogelijkeKeys.some((y) => y === x),
  //   );
  //   if (nietMogelijkeKeysDieVoorkomen.length > 0) {
  //     const nieuweSelectie = urlState.selectie.filter((x) =>
  //       nietMogelijkeKeysDieVoorkomen.every((y) => y !== x),
  //     );
  //     setUrlStateSync('selectie', nieuweSelectie);
  //   }
  // }, [eigenschapProductenResult]);

  const kolommen = useMemo<TypedColumn<IEigenschapProduct>[]>(
    () => [
      {
        name: 'Code',
        title: 'Code',
      },
      {
        name: 'Naam_TekstID',
        title: 'Naam',
      },
      {
        name: 'Eenheid_TekstID',
        title: 'Eenheid',
      },
      {
        name: 'Info_TekstID',
        title: 'Info',
      },
      {
        name: 'TonenOpWebsite',
        title: 'Website',
      },
      {
        name: 'eigGrpIDs',
        title: 'Groepen',
      },
    ],
    [],
  );
  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IEigenschapProduct>[]>(
    () => [
      {
        columnName: 'Code',
        width: 80,
      },
      {
        columnName: 'Naam_TekstID',
        width: 350,
      },
      {
        columnName: 'Eenheid_TekstID',
        width: 100,
      },
      {
        columnName: 'Info_TekstID',
        width: 400,
      },
      {
        columnName: 'TonenOpWebsite',
        width: 70,
      },
      {
        columnName: 'eigGrpIDs',
        width: 350,
      },
    ],
    [],
  );

  return (
    <>
      <Helmet>
        <title>Specificaties Beheer</title>
      </Helmet>
      <MenuLayout
        menu={
          <div className="d-flex flex-column">
            <div className="d-flex align-items-center">
              <FilterBalkV2
                filters={filters}
                filterData={urlState.filterData}
                onFilterDataChange={(value) => setUrlStateSync('filterData', value)}
                onFilterSchemaChange={(x) => setFilterSchema(x)}
              />
            </div>
            <div className="d-flex align-items-center mt-3">
              <SelectieVak
                aantal={urlState.selectie.length}
                totaalAantal={
                  eigenschapProductenResult === null
                    ? null
                    : eigenschapProductenResult.producten.length
                }
                onChange={(allesGeselecteerd) => {
                  if (allesGeselecteerd) {
                    if (eigenschapProductenResult === null) {
                      return;
                    }
                    setUrlStateSync(
                      'selectie',
                      eigenschapProductenResult.producten.map((x) => keyExtractor(x)),
                    );
                  } else {
                    setUrlStateSync('selectie', []);
                  }
                }}
                entiteitEnkelvoud="specificatie"
                entiteitMeervoud="specificaties"
              />

              <div className="ml-3 mr-3">
                <VerticaleScheidingslijn height={30} />
              </div>

              <div className="d-flex align-items-center">
                <VinkVeld
                  aangevinkt={urlState.meertaligeWeergave}
                  onGewijzigd={(x) => setUrlStateSync('meertaligeWeergave', x)}
                />
                <span className="ml-2">Meertalige weergave</span>
              </div>
            </div>
          </div>
        }
        body={
          <div
            className="d-flex flex-fill align-items-center justify-items-center"
            style={{ width: '100%' }}
          >
            {eigenschapProductenResult === null ? (
              <LoadingSpinner />
            ) : (
              <GridStyleWrapper height="calc(100vh - 205px)">
                <Grid
                  getRowId={keyExtractor}
                  rows={eigenschapProductenResult.producten}
                  columns={kolommen}
                >
                  <DataTypeProvider
                    for={[nameof<IEigenschapProduct>('Naam_TekstID')]}
                    formatterComponent={(formatterProps) => {
                      const tekstID = formatterProps.value;
                      if (tekstID === null) {
                        return <span />;
                      }
                      if (!urlState.meertaligeWeergave) {
                        return <TekstVisualisatie tekstID={tekstID} />;
                      }

                      if (talen === null) {
                        return <span />;
                      }

                      return (
                        <div className="d-flex flex-column">
                          {talen!.map((taal) => {
                            return (
                              <div key={taal.TaalID}>
                                <span>{taal.NaamKort}</span>
                                <span className="ml-2">
                                  <TekstVisualisatie
                                    tekstID={tekstID}
                                    gewensteTaalID={taal.TaalID}
                                  />
                                </span>
                              </div>
                            );
                          })}
                        </div>
                      );
                    }}
                  />
                  <DataTypeProvider
                    for={[
                      nameof<IEigenschapProduct>('Eenheid_TekstID'),
                      nameof<IEigenschapProduct>('Info_TekstID'),
                    ]}
                    formatterComponent={(formatterProps) => {
                      const tekstID = formatterProps.value;
                      if (tekstID === null) {
                        return <span />;
                      }
                      if (!urlState.meertaligeWeergave) {
                        return <TekstVisualisatie tekstID={tekstID} />;
                      }

                      if (talen === null) {
                        return <span />;
                      }

                      return (
                        <div className="d-flex flex-column">
                          {talen!.map((taal) => {
                            return (
                              <div key={taal.TaalID}>
                                <span>
                                  <TekstVisualisatie
                                    tekstID={tekstID}
                                    gewensteTaalID={taal.TaalID}
                                  />
                                </span>
                              </div>
                            );
                          })}
                        </div>
                      );
                    }}
                  />
                  <DataTypeProvider
                    for={[nameof<IEigenschapProduct>('TonenOpWebsite')]}
                    formatterComponent={(formatterProps) => {
                      const tonen = formatterProps.value;
                      return (
                        <span>
                          {tonen ? (
                            <IconVink style={{ fill: Kleur.Grijs, width: 16, height: 16 }} />
                          ) : (
                            <IconKruis style={{ fill: Kleur.Rood, width: 16, height: 16 }} />
                          )}
                        </span>
                      );
                    }}
                  />
                  <DataTypeProvider
                    for={[nameof<IEigenschapProduct>('eigGrpIDs')]}
                    formatterComponent={(formatterProps) => {
                      const eigGrpIDs: number[] = formatterProps.value;
                      if (eigenschapGroepenResult === null) {
                        return <span />;
                      }

                      const groepen = eigGrpIDs.map(
                        (eigGrpID) =>
                          eigenschapGroepenResult!.groepen.find((res) => res.ID === eigGrpID)!,
                      );
                      const groepenGesorteerd = _.orderBy(groepen, ['SortNr'], ['asc']);

                      return (
                        <span>
                          {groepenGesorteerd.map((groep: IEigenschapProduct, i: number) => {
                            return (
                              <span key={groep.ID}>
                                <TekstVisualisatie tekstID={groep.Naam_TekstID} />
                                {i !== groepen.length - 1 && <span>,&nbsp;</span>}
                              </span>
                            );
                          })}
                        </span>
                      );
                    }}
                  />

                  <SelectionState
                    selection={urlState.selectie}
                    onSelectionChange={(x) => setUrlStateSync('selectie', x as number[])}
                  />
                  <EditingState
                    onCommitChanges={async (changeset) => {
                      if (changeset.deleted !== undefined && changeset.deleted.length > 0) {
                        if (
                          (await checkStore.bevestigen({ inhoud: 'Verwijderen?' })).type ===
                          EResultType.Annuleren
                        ) {
                          return;
                        }

                        const eigProdIDs = changeset.deleted as number[];
                        const params: IVerwijderenEigenschapProductenParams = {
                          eigProdIDs,
                        };
                        const checkData = await api.v2.aanbod.specificatie.checkVerwijderenEigenschapProducten(
                          params,
                        );
                        if (
                          (await checkStore.controleren({ checkData })).type ===
                          EResultType.Annuleren
                        ) {
                          return;
                        }

                        await api.v2.aanbod.specificatie.verwijderenEigenschapProducten(params);
                        await ophalenEigenschapProducten();
                      }
                    }}
                    onEditingRowIdsChange={async (ids) => {
                      const id = ids[ids.length - 1] as number;
                      const prod = eigenschapProductenResult!.producten.find((x) => x.ID === id)!;

                      const tekstIDs = [
                        prod.Naam_TekstID,
                        prod.Eenheid_TekstID,
                        prod.Info_TekstID,
                      ].filter((x) => x !== null) as number[];
                      const tekstenInAlleTalenResult = await api.v2.tekst.ophalenTekstenInAlleTalen(
                        { tekstIDs },
                      );

                      setUrlStateSync('wijzigenState', {
                        eigProdID: id,
                        initialValues: {
                          code: prod.Code || '',
                          naam: tekstenInAlleTalenResult.teksten
                            .filter((x) => x.TekstID === prod.Naam_TekstID)
                            .map(
                              (x): ITaalTekst => ({
                                tekst: x.Tekst || '',
                                toepassen: x.Toepassen,
                                taalID: x.TaalID,
                              }),
                            ),
                          eenheid: tekstenInAlleTalenResult.teksten
                            .filter((x) => x.TekstID === prod.Eenheid_TekstID)
                            .map(
                              (x): ITaalTekst => ({
                                tekst: x.Tekst || '',
                                toepassen: x.Toepassen,
                                taalID: x.TaalID,
                              }),
                            ),
                          info: tekstenInAlleTalenResult.teksten
                            .filter((x) => x.TekstID === prod.Info_TekstID)
                            .map(
                              (x): ITaalTekst => ({
                                tekst: x.Tekst || '',
                                toepassen: x.Toepassen,
                                taalID: x.TaalID,
                              }),
                            ),
                          tonenOpWebsite: prod.TonenOpWebsite,
                          naamEnum: prod.NaamEnum || '',
                          eigGrpIDs: prod.eigGrpIDs,
                        },
                      });
                    }}
                    onAddedRowsChange={() => setUrlStateSync('nieuwState', true)}
                  />

                  <VirtualTable />
                  <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
                  <TableEditColumn
                    width={65}
                    showEditCommand
                    showDeleteCommand
                    showAddCommand
                    cellComponent={DXTableEditColumnCellComponent}
                    commandComponent={DXTableEditColumnCommandComponent}
                  />
                  <TableSelection cellComponent={DXTableCheckboxComponent} />
                  <TableHeaderRow />
                </Grid>
              </GridStyleWrapper>
            )}
          </div>
        }
      />
      {urlState.nieuwState && (
        <MuterenDialoog
          open
          onSuccess={async (data) => {
            const params: IToevoegenEigenschapProductParams = {
              code: data.code,
              naam: data.naam,
              eenheid: data.eenheid,
              info: data.info,
              tonenOpWebsite: data.tonenOpWebsite,
              naamEnum: data.naamEnum,
              eigGrpIDs: data.eigGrpIDs,
            };
            const checkData = await api.v2.aanbod.specificatie.checkToevoegenEigenschapProduct(
              params,
            );
            if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
              return;
            }
            await api.v2.aanbod.specificatie.toevoegenEigenschapProduct(params);
            setUrlStateSync('nieuwState', false);
            await ophalenEigenschapProducten();
          }}
          onAnnuleren={() => setUrlStateSync('nieuwState', false)}
        />
      )}
      {urlState.wijzigenState !== null && (
        <MuterenDialoog
          open
          onSuccess={async (data) => {
            const params: IWijzigenEigenschapProductParams = {
              eigProdID: urlState.wijzigenState!.eigProdID,
              code: data.code,
              naam: data.naam,
              eenheid: data.eenheid,
              info: data.info,
              tonenOpWebsite: data.tonenOpWebsite,
              naamEnum: data.naamEnum,
              eigGrpIDs: data.eigGrpIDs,
            };
            const checkData = await api.v2.aanbod.specificatie.checkWijzigenEigenschapProduct(
              params,
            );
            if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
              return;
            }
            await api.v2.aanbod.specificatie.wijzigenEigenschapProduct(params);
            setUrlStateSync('wijzigenState', null);
            await ophalenEigenschapProducten();
          }}
          onAnnuleren={() => setUrlStateSync('wijzigenState', null)}
          initialValues={urlState.wijzigenState.initialValues}
        />
      )}
    </>
  );
});

export default withRouter(Beheer);
