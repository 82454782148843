import * as React from 'react';
import { useCallback } from 'react';
import { IconRadioChecked, IconRadioUnchecked } from '../../Icons';
import { Kleur } from '../../../bedrijfslogica/constanten';

interface IProps extends React.HTMLProps<HTMLDivElement> {
  aangevinkt: boolean;
  onAangevinkt: (aangevinkt: boolean) => void;
}

const svgStyle: React.CSSProperties = {
  marginRight: 3,
  marginTop: 1,
  width: 20,
  height: 20,
  fill: Kleur.Blauw,
  cursor: 'pointer',
};

const RadioKnop: React.FC<IProps> = (props) => {
  return (
    <>
      {props.aangevinkt ? (
        <IconRadioChecked
          onClick={() => props.onAangevinkt(!props.aangevinkt)}
          style={
            {
              ...props.style,
              ...svgStyle,
            } as any
          }
        />
      ) : (
        <IconRadioUnchecked
          onClick={() => props.onAangevinkt(!props.aangevinkt)}
          style={
            {
              ...props.style,
              ...svgStyle,
            } as any
          }
        />
      )}
      <input type="radio" checked={props.aangevinkt} hidden />
    </>
  );
};

export default RadioKnop;
