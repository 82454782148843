import React, { useContext, useMemo } from 'react';
import { IProps } from '@tinymce/tinymce-react/lib/cjs/main/ts/components/Editor';
import { IContext, FactuurContext } from '../index';
import VeldWeergave from '../../../formulier/VeldWeergave';
import LoadingSpinner from '../../../Gedeeld/LoadingSpinner';
import { format } from 'date-fns';
import GegevensLayout from '../../../layout/GegevensLayout';
import FormatteerBedrag from '../../../MutatieBedrag';
import {
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import {
  Grid,
  TableHeaderRow,
  VirtualTable,
  Table,
  TableColumnResizing,
} from '@devexpress/dx-react-grid-bootstrap4';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { IOphalenOpdrachtenResultElement } from '../../../../../../shared/src/api/v2/bank/opdracht/opdracht';

const BoekingTab: React.FC<IProps> = (props) => {
  const { factuur, relatie, bankopdrachten } = useContext<IContext>(FactuurContext);

  const kolommenBankopdrachten = useMemo<TypedColumn<IOphalenOpdrachtenResultElement>[]>(
    () => [
      {
        name: 'Soort',
        title: 'Soort',
      },
      {
        name: '__bedrag' as any,
        title: 'Bedrag',
      },
      {
        name: 'Uitvoerdatum',
        title: 'Uitv.datum',
      },
      {
        name: 'IBAN',
        title: 'IBAN',
      },
      // {
      //   name: 'Rekeningnaam',
      //   title: 'Rekeningnaam',
      // },
      {
        name: '__status' as any,
        title: 'Status',
      },
      // {
      //   name: '__batch' as any,
      //   title: 'Batch',
      // },
      {
        name: '__stornodatum' as any,
        title: 'Stornodatum',
      },
      {
        name: '__stornocode' as any,
        title: 'Code',
      },
      {
        name: '__stornoreden' as any,
        title: 'Naam',
      },
      {
        name: '__batchnummer' as any,
        title: 'Batch',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IOphalenOpdrachtenResultElement>[]>(
    () => [
      {
        columnName: 'Soort',
        width: 70,
      },
      {
        columnName: '__batch' as any,
        width: 70,
      },
      {
        columnName: '__bedrag' as any,
        width: 85,
      },
      {
        columnName: 'Uitvoerdatum',
        width: 100,
      },
      {
        columnName: 'IBAN',
        width: 175,
      },
      {
        columnName: 'Rekeningnaam',
        width: 115,
      },
      {
        columnName: '__status',
        width: 200,
      },
      {
        columnName: '__stornodatum' as any,
        width: 120,
      },
      {
        columnName: '__stornocode' as any,
        width: 80,
      },
      {
        columnName: '__stornoreden' as any,
        width: 200,
      },
      {
        columnName: '__batchnummer' as any,
        width: 70,
      },
    ],
    [],
  );

  return (
    <>
      {factuur === null ? (
        <LoadingSpinner />
      ) : (
        <>
          {/* <div className="row"> */}
          {/* <div className="col-12 d-flex"> */}
          <div className="d-flex flex-column flex-fill" style={{ minHeight: 700 }}>
            <div className="col-12 mt-3">
              <GridStyleWrapper maxHeight={500} rowAmount={bankopdrachten.length}>
                <Grid rows={bankopdrachten} columns={kolommenBankopdrachten}>
                  <DataTypeProvider
                    for={['Soort']}
                    formatterComponent={(formatterProps) => {
                      const rij: IOphalenOpdrachtenResultElement = formatterProps.row;

                      return <span>{rij.Bedrag > 0 ? 'Inc.' : 'Bet.'}</span>;
                    }}
                  />

                  <DataTypeProvider
                    for={['__batch']}
                    formatterComponent={(formatterProps) => {
                      const rij: IOphalenOpdrachtenResultElement = formatterProps.row;
                      return rij.batch !== null ? (
                        <span>{rij.batch.Batchnummer}</span>
                      ) : (
                        <span></span>
                      );
                    }}
                  />

                  <DataTypeProvider
                    for={['__bedrag']}
                    formatterComponent={(formatterProps) => {
                      const rij: IOphalenOpdrachtenResultElement = formatterProps.row;
                      const debiteurFactuur = rij.debiteurFacturen.find(
                        (x) => x.FactID === factuur.FactID,
                      )!;

                      return <FormatteerBedrag bedrag={debiteurFactuur.Bedrag} />;
                    }}
                  />

                  <DataTypeProvider
                    for={['Uitvoerdatum']}
                    formatterComponent={(formatterProps) => (
                      <span>
                        {formatterProps.value === null
                          ? null
                          : format(new Date(formatterProps.value), 'dd-MM-yyyy')}
                      </span>
                    )}
                  />

                  <DataTypeProvider
                    for={['__status']}
                    formatterComponent={(formatterProps) => {
                      const rij: IOphalenOpdrachtenResultElement = formatterProps.row;
                      if (rij.batch === null) {
                        return <span>Nog verzamelen</span>;
                      }

                      if (rij.batch.UitgevoerdOp !== null) {
                        return (
                          <span>
                            Uitgevoerd op {format(new Date(rij.batch.UitgevoerdOp), 'dd-MM-yyyy')}
                          </span>
                        );
                      }

                      if (rij.batch.DatumVerstuurd !== null) {
                        return (
                          <span>
                            Verstuurd op {format(new Date(rij.batch.DatumVerstuurd!), 'dd-MM-yyyy')}
                          </span>
                        );
                      }
                      return <span>Nog versturen</span>;
                    }}
                  />

                  <DataTypeProvider
                    for={['__stornodatum']}
                    formatterComponent={(props) => {
                      const rij: IOphalenOpdrachtenResultElement = props.row;
                      if (rij.stornomutatie === null) {
                        return <span></span>;
                      }
                      const stornodatum = format(
                        new Date(rij.stornomutatie.Mutatiedatum),
                        'dd-MM-yyyy',
                      );
                      return <span>{stornodatum}</span>;
                    }}
                  />

                  <DataTypeProvider
                    for={['__stornocode']}
                    formatterComponent={(props) => {
                      const rij: IOphalenOpdrachtenResultElement = props.row;
                      if (rij.stornomutatie === null || rij.stornomutatie.stornoreden === null) {
                        return <span></span>;
                      }
                      return <span>{rij.stornomutatie.stornoreden.Code}</span>;
                    }}
                  />

                  <DataTypeProvider
                    for={['__stornoreden']}
                    formatterComponent={(props) => {
                      const rij: IOphalenOpdrachtenResultElement = props.row;
                      if (rij.stornomutatie === null || rij.stornomutatie.stornoreden === null) {
                        return <span></span>;
                      }
                      return <span>{rij.stornomutatie.stornoreden.Naam}</span>;
                    }}
                  />

                  <DataTypeProvider
                    for={['__batchnummer']}
                    formatterComponent={(props) => {
                      const rij: IOphalenOpdrachtenResultElement = props.row;
                      if (rij.batch === null) {
                        return <span></span>;
                      }
                      return <span>{rij.batch.Batchnummer}</span>;
                    }}
                  />

                  <VirtualTable
                    // columnExtensions={kolomExtensiesBankopdrachten}
                    messages={{ noData: 'Geen bankopdrachten' }}
                  />
                  <TableColumnResizing defaultColumnWidths={kolomBreedtes} />

                  <TableHeaderRow />
                </Grid>
              </GridStyleWrapper>
            </div>
          </div>
          {/* </div> */}
          {/* </div> */}
        </>
      )}
    </>
  );
};

export default BoekingTab;
