import { EFilter } from '..';
import { IWeergaveProps } from '../../../../../components/FilterBalkV2';
import React from 'react';
import LoadingSpinner from '../../../../../components/Gedeeld/LoadingSpinner';
import Combobox from '../../../../../components/formulier/Combobox';
import {
  IOphalenWebPaginasResult,
  IWebPagina,
} from '../../../../../../../shared/src/api/v2/website/pagina';
import { IOphalenProductsoortenResult } from '../../../../../../../shared/src/api/v2/product/soort';

interface IProps extends IWeergaveProps<any> {
  webpaginas: IOphalenWebPaginasResult;
}

const WebpaginaFilter = (props: IProps) => {
  return (
    <div className="d-flex align-items-center">
      <span>Webpagina</span>
      <span className="ml-3">
        {props.webpaginas === null ? (
          <LoadingSpinner />
        ) : (
          <Combobox
            geselecteerd={props.data}
            onSelectieChange={(x) => {
              props.onDataChange(x!);
              props.setIsActief(true);
              props.toepassen();
            }}
            opties={props.webpaginas.webPaginas.map((soort: any) => ({
              id: soort.ID,
              label: soort.NaamEnum,
            }))}
          />
        )}
      </span>
    </div>
  );
};

export default WebpaginaFilter;
