import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { DropTargetMonitor, useDrop } from 'react-dnd';
import {
  BijlageKnopContext,
  EBronModus,
  EOrientatie,
  ESelectieModus,
  IBestandstype,
  IBijlageKnopButtonComponentProps,
} from '../index';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { IconAttachment, IconCloud, IconUitklappen, IconZoeken } from '../../Icons';
import { BestandType, ILokaalBestand } from '../../BijlagenContainer';
import { Kleur } from '../../../bedrijfslogica/constanten';
import { NativeTypes } from 'react-dnd-html5-backend';
import DriveBestandsselectieDialoog from '../../drive/DriveBestandsselectieDialoog';
import Overlay from 'react-bootstrap/Overlay';

interface IProps {
  accepts: string[];
  onDrop: (item: { files: any[] }) => void;
  selectieModus: ESelectieModus;
  onBestandIDs: (bestandIDs: number[]) => Promise<void>;
  canDrop?: (item: { files: any[]; items: { kind: 'file' | 'string'; type: string }[] }) => boolean;
  toegestaneBestandstypes?: IBestandstype[];
  buttonComponent?: React.ComponentType<
    IBijlageKnopButtonComponentProps & React.RefAttributes<any>
  >;
  disabled?: boolean;
}

const DragAndDropTarget: React.FC<IProps> = (props) => {
  const { onDrop } = props;
  const buttonRef = useRef<any | null>(null);
  const [showOverlay, setShowOverlay] = useState(false);
  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      drop(item: { files: any[] }) {
        if (onDrop) {
          onDrop(item);
        }
      },
      canDrop(item: { files: any[]; items: { kind: 'file' | 'string'; type: string }[] }) {
        if (props.canDrop !== undefined) {
          return props.canDrop(item);
        }
        return true;
      },
      hover(item: any) {
        // console.log('hover', item.files, item.items);
      },
      collect: (monitor: DropTargetMonitor) => {
        const item = monitor.getItem() as any;
        if (item) {
          // console.log('collect', item.files, item.items);
        }

        return {
          isOver: monitor.isOver(),
          canDrop: monitor.canDrop(),
        };
      },
    }),
    [props],
  );
  const {
    bronModus,
    selectieModus,
    orientatie,
    onBijgevoegd,
    toegestaneBestandstypes,
  } = useContext(BijlageKnopContext);
  const [dialoogTonen, setDialoogTonen] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleBijlagenToevoegenLokaal = useCallback(() => {
    setShowOverlay(false);
    fileInputRef.current!.click();
  }, [fileInputRef.current]);

  const handleBijlagenToevoegenASPDrive = useCallback((ev) => {
    // Close popover
    setShowOverlay(false);

    setDialoogTonen(true);
  }, []);

  const handleLokaalBestandenGeselecteerd = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      fileInputRef.current!.files = null;

      // @ts-ignore
      const files = [...ev.target.files!];
      const lokaleBestanden = files.map((x) => {
        return {
          type: BestandType.Lokaal,
          naam: x.name,
          grootte: x.size,
          file: x,
          url: URL.createObjectURL(x),
          mediaType: x.type,
        };
      }) as ILokaalBestand[];

      onBijgevoegd(lokaleBestanden);
    },
    [fileInputRef.current!, onBijgevoegd],
  );
  // const handleASPDriveBestandenGeselecteerd = useCallback(
  //   (bestanden: IOphalenBestandenResultElement[]) => {
  //     setDialoogTonen(false);
  //
  //     const externeBestanden = bestanden.map((b) => ({
  //       type: BestandType.ASPDrive,
  //       bestID: b.ID,
  //     }));
  //
  //     // @ts-ignore
  //     onBijgevoegd(externeBestanden);
  //   },
  //   [onBijgevoegd],
  // );

  const fileInputAccept = useMemo(
    () =>
      toegestaneBestandstypes === undefined
        ? undefined
        : toegestaneBestandstypes.map((x) => x.mediaType).join(','),
    [toegestaneBestandstypes],
  );

  const ButtonComp = useMemo<
    React.ComponentType<IBijlageKnopButtonComponentProps & React.RefAttributes<any>>
  >(() => {
    if (props.buttonComponent !== undefined) {
      return props.buttonComponent;
    }

    return React.forwardRef((props, ref) => {
      const { canDrop, orientatie, onClick } = props;

      return (
        <button
          ref={ref}
          className="btn btn-light btn-sm d-flex align-items-center"
          style={{
            color: '#333333',
            border: canDrop ? `1px dashed ${Kleur.DonkerGrijs}` : `1px solid ${Kleur.LichtGrijs}`,
            backgroundColor: canDrop ? Kleur.HeelLichtBlauw : undefined,
            height: orientatie === EOrientatie.Horizontaal ? undefined : '100%',
          }}
          onClick={(ev) => {
            ev.stopPropagation();

            onClick();
          }}
        >
          <IconAttachment style={{ width: 18, height: 18, fill: '#575757' }} />
          {orientatie === EOrientatie.Horizontaal && (
            <>
              &nbsp; Bijvoegen &nbsp;
              <IconUitklappen style={{ width: 18, height: 18, fill: '#575757' }} />
            </>
          )}
        </button>
      );
    });
  }, [props.buttonComponent]);

  useEffect(() => {
    const listener = (ev: MouseEvent) => {
      setShowOverlay(false);
    };
    window.document.addEventListener('click', listener);
    return () => {
      window.document.removeEventListener('click', listener);
    };
  }, []);

  return (
    <div
      ref={drop}
      style={{
        height: orientatie === EOrientatie.Horizontaal ? undefined : '100%',
      }}
    >
      <Overlay target={buttonRef.current} show={showOverlay} placement="auto-start">
        {(overlayProps) => {
          return (
            <div
              {...overlayProps}
              style={{
                ...overlayProps.style,
                border: '1px solid #e2e2e2',
                background: 'white',
                zIndex: 9999,
              }}
            >
              <div className="list-group list-group-flush">
                {(bronModus === EBronModus.Alle || bronModus === EBronModus.Lokaal) && (
                  <div
                    className="list-group-item list-group-item-action"
                    style={{ cursor: 'pointer' }}
                    onClick={handleBijlagenToevoegenLokaal}
                  >
                    <IconZoeken style={{ width: 20, height: 20 }} />
                    &nbsp; Op deze computer zoeken
                  </div>
                )}
                {(bronModus === EBronModus.Alle || bronModus === EBronModus.Drive) && (
                  <div
                    className="list-group-item list-group-item-action"
                    style={{ cursor: 'pointer' }}
                    onClick={handleBijlagenToevoegenASPDrive}
                  >
                    <IconCloud style={{ width: 18, height: 18 }} /> &nbsp; In ASP Drive zoeken
                  </div>
                )}
              </div>
            </div>
          );
        }}
      </Overlay>
      <ButtonComp
        ref={buttonRef}
        canDrop={canDrop}
        orientatie={orientatie}
        onClick={() => {
          console.log(buttonRef);
          setShowOverlay(true);
        }}
        disabled={props.disabled}
      />
      <input
        ref={fileInputRef}
        type="file"
        multiple={selectieModus === ESelectieModus.Meerdere}
        style={{ display: 'none' }}
        onChange={handleLokaalBestandenGeselecteerd}
        accept={fileInputAccept}
      />
      {dialoogTonen && (
        <DriveBestandsselectieDialoog
          open
          onSuccess={async (output) => {
            await props.onBestandIDs(output.bestandIDs);
            setDialoogTonen(false);
          }}
          onAnnuleren={() => setDialoogTonen(false)}
          selectieModus={props.selectieModus}
          magBestandSelecteren={(bestandItem) => {
            if (props.toegestaneBestandstypes === undefined) {
              return true;
            }

            const mediaType = bestandItem.bestand.MediaType;

            if (
              !props.toegestaneBestandstypes.some((t) => {
                const mediaTypeRegexStr = t.mediaType.replace('/', '\\/').replace('*', '\\w+');
                const regex = new RegExp(mediaTypeRegexStr);
                return regex.test(mediaType);
              })
            ) {
              return false;
            }

            return true;
          }}
        />
        // <ModalASPDriveBestandSelecteren
        //   onAnnuleren={() => setDialoogTonen(false)}
        //   onBestandenGeselecteerd={handleASPDriveBestandenGeselecteerd}
        // />
      )}
    </div>
  );
};

export default DragAndDropTarget;
