import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { IConcurrentProductData } from '../../../../../../shared/src/api/v2/concurrent';
import api from '../../../../api';
import { IFilterData } from '../../../../components/FilterBalkV2';
import MenuLayout from '../../../../components/MenuLayout';
import ASPTabel from '../../../../components/tabel/ASPTabel';
import TableData, { ITableDataProps } from '../../../../components/tabel/ASPTabel/Body/TableData';
import { ASPKolom, EAspKolomBreedteType } from '../../../../components/tabel/ASPTabel/types';
import useUrlState from '../../../../core/useUrlState';
import { EResultType } from '../../../../stores/CheckStore';
import { RootStoreContext } from '../../../../stores/RootStore';
import IPaginatiePositie from '../../../../../../shared/src/models/IPaginatiePositie';
import pagina from '../../../../api/v2/website/pagina/indes';

type Kolom = 'naam' | 'scan-id' | 'data';

export enum EFilter {
  Zoekterm = 'ZOEKTERM',
}

interface IProps extends RouteComponentProps {}

interface IToevoegenDialoogState {}
interface IWijzigenDialoogState {
  id: number;
}
interface IUrlState {
  selectie: number[];
  filterdata: IFilterData<EFilter>[];
  toevoegenDialoogState: IToevoegenDialoogState | null;
  wijzigenDialoogState: IWijzigenDialoogState | null;
}

const defaultUrlState: IUrlState = {
  selectie: [],
  filterdata: [],
  toevoegenDialoogState: null,
  wijzigenDialoogState: null,
};

const Item: React.FC<IProps> = (props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);

  const [rijen, setRijen] = useState<IConcurrentProductData[] | null>(null);

  const [paginatie, setPaginatie] = useState<IPaginatiePositie>({ index: 0, aantal: 100 });

  const ophalenRijen = useCallback(async () => {
    console.log(urlState);
    console.log('ophalen...');
    const results = await api.v2.concurrentie.ophalenProductData({
      paginatie,
    });
    console.log('resultaat:');
    console.log(results);
    setRijen(results);
  }, []);

  useEffect(() => {
    ophalenRijen();
  }, [ophalenRijen, paginatie]);

  const handleExtraRijenAangevraagd = useCallback(async (paginatie: IPaginatiePositie) => {
    console.log('extra rijen aangevraagd');
    setPaginatie({ index: 0, aantal: paginatie.aantal + 100 });
  }, []);

  const keyExtractor = useCallback((row: IConcurrentProductData) => (row.ID == null ? 0 : row.ID), [
    rijen,
  ]);

  const kolommen = useMemo<ASPKolom<Kolom, IConcurrentProductData>[]>(
    () => [
      {
        key: 'scan-id',
        label: 'Scan- nummer',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 200,
        renderer: (x) => x.ConcurrentAanbodscanID,
      },
      {
        key: 'naam',
        label: 'Naam',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 300,
        renderer: (x) => x.IdentificerendeNaam,
      },
      {
        key: 'data',
        label: 'Data JSON',
        breedteType: EAspKolomBreedteType.Flex,
        flex: 3,
        renderer: (x) => x.DataJSON,
      },
    ],
    [],
  );

  return (
    <>
      <Helmet>
        <title>Gescande producten</title>
      </Helmet>
      <MenuLayout
        body={
          rijen == null ? (
            <span></span>
          ) : (
            <ASPTabel
              rijen={rijen}
              kolommen={kolommen}
              keyExtractor={keyExtractor}
              selectie={urlState.selectie}
              tdComponent={TdComponent}
              onExtraRijenAangevraagd={handleExtraRijenAangevraagd}
            />
          )
        }
      />
    </>
  );
};

const TdComponent = (props: ITableDataProps<Kolom, IConcurrentProductData>) => {
  return (
    <TableData
      {...props}
      style={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
        display: 'block',
        paddingTop: '8px',
      }}
    />
  );
};

export default withRouter(Item);
