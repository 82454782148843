import React, { HTMLProps, useContext, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../stores/RootStore';
import Avatar from '../../personalia/Avatar';

export enum EModus {
  NaamKort,
  Naam,
  Avatar,
  Volledig,
  VolledigNaamKort,
}

interface IProps {
  aspGebrID: number;
  modus?: EModus;
  rootStyle?: React.CSSProperties;
  avatarGrootte?: number;
  naamComponent?: React.ComponentType<HTMLProps<HTMLSpanElement>>;
}

const ASPGebruikerVisualisatie: React.FC<IProps> = observer((props) => {
  const { gebruikerStore, persoonStore } = useContext(RootStoreContext);

  const modus = useMemo(() => props.modus ?? EModus.NaamKort, [props.modus]);

  const gebruiker = useMemo(() => {
    if (gebruikerStore.gebruikers === null) {
      return null;
    }
    const gebr = gebruikerStore.gebruikers.find((x) => x.AspGebrID === props.aspGebrID);
    if (gebr === undefined) {
      throw new Error(
        `Gebruiker met id: ${props.aspGebrID} komt niet voor in lijst met gebruikers van gebruiker store`,
      );
    }
    return gebr;
  }, [props.aspGebrID]);

  const persoon = useMemo(() => {
    if ((gebruiker?.PersID ?? null) === null) {
      return null;
    }
    return persoonStore.personen[gebruiker!.PersID!] ?? null;
  }, [gebruiker?.PersID, persoonStore.personen]);

  useEffect(() => {
    if ((gebruiker?.PersID ?? null) === null) {
      return;
    }
    if (persoon !== null) {
      return;
    }
    persoonStore.enqueuePersoonOphalen(gebruiker!.PersID!);
  }, [gebruiker?.PersID]);

  const avatarGrootteStr = useMemo(() => `${props.avatarGrootte ?? 18}px`, [props.avatarGrootte]);

  if (gebruiker === null) {
    return null;
  }

  const Naam = props.naamComponent ?? 'span';

  const weergave = (() => {
    switch (modus) {
      case EModus.NaamKort: {
        return <Naam>{gebruiker.NaamKort}</Naam>;
      }
      case EModus.Naam: {
        return <Naam>{gebruiker.GebruikersNaam}</Naam>;
      }
      case EModus.Avatar: {
        const avatarUrl = persoon?.profielAfbeelding?.url ?? null;
        if (avatarUrl === null) {
          return <Avatar size={avatarGrootteStr} />;
        }
        return <Avatar size={avatarGrootteStr} imageSrc={avatarUrl} />;
      }
      case EModus.Volledig: {
        const avatarUrl = persoon?.profielAfbeelding?.url ?? null;

        return (
          <div className="d-flex align-items-center">
            {avatarUrl !== null && <Avatar size={avatarGrootteStr} imageSrc={avatarUrl} />}
            <Naam style={{ marginLeft: 7 }}>{gebruiker.GebruikersNaam}</Naam>
          </div>
        );
      }
      case EModus.VolledigNaamKort: {
        const avatarUrl = persoon?.profielAfbeelding?.url ?? null;

        return (
          <div className="d-flex align-items-center">
            {avatarUrl !== null && <Avatar size={avatarGrootteStr} imageSrc={avatarUrl} />}
            <Naam style={{ marginLeft: 7 }}>{gebruiker.NaamKort}</Naam>
          </div>
        );
      }
    }
  })();

  return <div style={{ fontSize: 12, ...props.rootStyle }}>{weergave}</div>;
});

export default ASPGebruikerVisualisatie;
