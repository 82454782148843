import React, { useContext, useMemo } from 'react';
import { RouteComponentProps } from 'react-router';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../stores/RootStore';
import TelefoonHistorie, { ERichting } from '../../../components/communicatie/TelefoonHistorie';
import useUrlState from '../../../core/useUrlState';

export interface ICommunicatieTelefoonUrlState {
  richting: ERichting;
  vanGebruiker: 'IEDEREEN' | number | null;
  laatsteVanGebruikerSelectie: 'IEDEREEN' | number | null;
  vigerend: number | null;
}

interface IProps extends RouteComponentProps {}

const Telefoon: React.FC<IProps> = observer((props) => {
  const { gebruikerStore, telefoonHistorieCommunicatieGlobaalStore } = useContext(RootStoreContext);

  const defaultUrlState = useMemo<ICommunicatieTelefoonUrlState>(
    () => ({
      richting: ERichting.Beide,
      vanGebruiker: 'IEDEREEN',
      laatsteVanGebruikerSelectie: null,
      vigerend: null,
    }),
    [gebruikerStore.gebruiker],
  );
  const [urlState, setUrlState, setUrlStateSync] = useUrlState<ICommunicatieTelefoonUrlState>(
    props,
    defaultUrlState,
  );

  return (
    <TelefoonHistorie
      telefoonHistorieStore={telefoonHistorieCommunicatieGlobaalStore}
      richting={urlState.richting}
      onRichtingChange={(x) => setUrlStateSync('richting', x)}
      vanGebruiker={urlState.vanGebruiker}
      laatsteVanGebruikerSelectie={urlState.laatsteVanGebruikerSelectie}
      onVanGebruikerChange={(x) => {
        setUrlStateSync('vanGebruiker', x);
        setUrlStateSync(
          'laatsteVanGebruikerSelectie',
          x === urlState.vanGebruiker
            ? urlState.laatsteVanGebruikerSelectie
            : urlState.vanGebruiker,
        );
      }}
      vigerend={urlState.vigerend ?? undefined}
    />
  );
});

export default Telefoon;
