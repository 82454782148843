import React, { useContext, useMemo } from 'react';
import { DashboardContext, TegelBasis } from '../index';
import * as _ from 'lodash';
import { format } from 'date-fns';
import GegevensLayout from '../../../../../components/layout/GegevensLayout';
import FormatteerBedrag from '../../../../../components/MutatieBedrag';

interface IProps {}

const AlgemeneInfo: React.FC<IProps> = (props) => {
  const context = useContext(DashboardContext);

  const instellingen = context.instellingen.data!;

  const info = context.dashboardInfo.data!.info;

  // const lijst = !instellingen.PremiumLeveren ? (
  //   <li>Er worden op dit moment geen premiums toegevoegd</li>
  // ) : (
  //   '' +
  //   (!instellingen.website.InloggenToegestaan ? (
  //     <li>Inloggen op de website is niet toegestaan</li>
  //   ) : (
  //     ''
  //   ))
  // );

  const lijst = (
    <>
      {/* {!instellingen.PremiumLeveren && <li>Er worden op dit moment geen premiums toegevoegd</li>}{' '} */}
      {!instellingen.website.InloggenToegestaan && (
        <li>Inloggen op de website is niet toegestaan</li>
      )}
    </>
  );

  return (
    <TegelBasis titel="Algemene informatie">
      <div className="d-flex">
        <div style={{ width: 300 }}>
          {/* <VerticaleScheidingslijn className="ml-3 mr-3" height={80} /> */}
          <GegevensLayout
            gegevens={[
              {
                label: 'Totaal openstaand',
                waarde: <FormatteerBedrag bedrag={info.totaalOpenstaand} />,
              },
              {
                label: 'Totaal vervallen',
                waarde: <FormatteerBedrag bedrag={info.totaalVervallen} />,
              },
              // {
              //   label: 'Omzet tot nu toe dit jaar',
              //   waarde: <MutatieBedrag bedrag={info.omzetDitJaar} />,
              // },
              // {
              //   label: 'Voortschrijdend',
              //   waarde: <MutatieBedrag bedrag={info.omzetVoortschrijdend} />,
              // },
            ]}
          />
        </div>
      </div>
    </TegelBasis>
  );
};

export default AlgemeneInfo;
