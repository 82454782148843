import { DataTypeProvider } from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableColumnResizing,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-bootstrap4';
import { IProps } from '@tinymce/tinymce-react/lib/cjs/main/ts/components/Editor';
import { format } from 'date-fns';
import _ from 'lodash';
import React, { useContext, useMemo } from 'react';
import { BetalingsregelingContext, IContext } from '..';
import { IFactuur } from '../../../../../../shared/src/api/v2/debiteur/betalingsregeling';
import { Kleur as EKleur } from '../../../../bedrijfslogica/constanten';
import {
  EDagboeknaam,
  EDagboeksoort,
  EGrootboekrekeningNaam,
} from '../../../../bedrijfslogica/enums';
import nameOf from '../../../../core/nameOf';
import {
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import VeldWeergave from '../../../formulier/VeldWeergave';
import GegevensLayout from '../../../layout/GegevensLayout';
import FormatteerBedrag from '../../../MutatieBedrag';
import RelatieVisualisatie from '../../../personalia/RelatieVisualisatie';

const BetalingsregelingTab: React.FC<IProps> = (props) => {
  const { betalingsregeling, boekingregelsVoorRegeling } = useContext<IContext>(
    BetalingsregelingContext,
  );

  const facturen = betalingsregeling!.facturen;

  // Resultaat tot nu toe
  const resultaat: {
    bedragVoldaan: number;
    bedragNogVoldoen: number;
    bedragTeBoeken: number;
    laatsteBetaling: Date | string | null;
  } = useMemo(() => {
    const bedragVoldaan = -_.sum(
      boekingregelsVoorRegeling!
        .filter(
          (x) =>
            x.boeking.dagboek.dagboekSoort.NaamEnum === EDagboeksoort.BANK ||
            x.boeking.dagboek.NaamEnum === EDagboeknaam.BUCKAROO_BETALINGEN,
        )
        .map((x) => x.Bedrag),
    );

    const bedragNogVoldoen = betalingsregeling!.Totaalbedrag - bedragVoldaan;

    const bedragTeBoeken =
      -1 *
      (_.sum(
        boekingregelsVoorRegeling!
          .filter(
            (x) => x.dbRekening.NaamEnum === EGrootboekrekeningNaam.DebiteurenBetalingsregeling,
          )
          .map((x) => x.Bedrag),
      ) +
        _.sum(
          boekingregelsVoorRegeling!
            .filter(
              (x) => x.crRekening.NaamEnum === EGrootboekrekeningNaam.DebiteurenBetalingsregeling,
            )
            .map((x) => -x.Bedrag),
        ));

    const meestRecenteBoekdatumVoorBank =
      _.max(
        boekingregelsVoorRegeling!
          .filter(
            (x) =>
              x.boeking.dagboek.dagboekSoort.NaamEnum === EDagboeksoort.BANK ||
              x.boeking.dagboek.Nummer === 18, // Memoriaal Buckaroo TODO Naar NaamEnum
          )
          .map((x) => x.boeking.Boekdatum),
      ) ?? null;

    const resultaat = {
      bedragVoldaan,
      bedragNogVoldoen,
      bedragTeBoeken,
      laatsteBetaling: meestRecenteBoekdatumVoorBank,
    };
    return resultaat;
  }, [boekingregelsVoorRegeling, betalingsregeling]);

  const kolommenVerkoopfacturen = useMemo<TypedColumn<IFactuur>[]>(
    () => [
      {
        name: 'Factuurnummer',
        title: 'Fact.nr.',
      },
      {
        name: 'Factuurdatum',
        title: 'Factuurdatum',
      },
      {
        name: 'Bedrag',
        title: 'Fact.bedrag',
      },
      {
        name: 'BedragInRegeling',
        title: 'Bedrag in rgl.',
      },
      {
        name: 'Openstaand',
        title: 'Openst.',
      },
    ],
    [],
  );

  const kolombreedtesVerkoopfacturen = useMemo<TypedTableColumnWidthInfo<IFactuur>[]>(
    () => [
      {
        columnName: 'Factuurnummer',
        width: 100,
      },
      {
        columnName: 'Factuurdatum',
        width: 125,
      },
      {
        columnName: 'BedragInRegeling',
        width: 110,
      },
      {
        columnName: 'Bedrag',
        width: 110,
      },
      {
        columnName: 'Openstaand',
        width: 110,
      },
    ],
    [],
  );

  return (
    <>
      <div className="d-flex flex-column">
        <div className="row mt-3">
          <div className="col-12 d-flex">
            <div className="col-6">
              <VeldWeergave>
                <GegevensLayout
                  gegevens={[
                    {
                      label: 'Relatie',
                      waarde: <RelatieVisualisatie relID={betalingsregeling!.RelID} />,
                    },
                    {
                      label: 'Referentie',
                      waarde: `${betalingsregeling!.Referentie}`,
                    },
                    {
                      label: 'Datum regeling',
                      waarde:
                        betalingsregeling!.Datum !== null
                          ? `${format(new Date(betalingsregeling!.Datum), 'dd-MM-yyyy')}`
                          : '',
                    },
                    {
                      label: 'Vastgelegd',
                      waarde:
                        betalingsregeling!.RecordToegevoegd !== null
                          ? `${format(
                              new Date(betalingsregeling!.RecordToegevoegd),
                              'dd-MM-yyyy HH:mm',
                            )}`
                          : '',
                    },
                    {
                      label: 'Status',
                      waarde: `${betalingsregeling!.StatusNaam}`,
                    },
                  ]}
                />
              </VeldWeergave>
            </div>
            <div className="col-6">
              <VeldWeergave>
                <GegevensLayout
                  gegevens={[
                    {
                      label: 'Eerste vervaldatum',
                      waarde:
                        betalingsregeling!.Datum !== null
                          ? `${format(
                              new Date(betalingsregeling!.EersteVervaldatum),
                              'dd-MM-yyyy',
                            )}`
                          : '',
                    },
                    {
                      label: 'Betaalwijze',
                      waarde: `${betalingsregeling!.BetaalwijzeNaam}`,
                    },
                    null,
                    null,
                  ]}
                />
              </VeldWeergave>
            </div>
          </div>
          <div className="col-12 d-flex mt-3">
            <div className="col-4">
              <VeldWeergave>
                <GegevensLayout
                  gegevens={[
                    {
                      label: 'Totaalbedrag',
                      waarde: <FormatteerBedrag bedrag={betalingsregeling!.Totaalbedrag} />,
                    },
                    {
                      label: 'Termijnbedrag',
                      waarde: <FormatteerBedrag bedrag={betalingsregeling!.Termijnbedrag} />,
                    },
                    {
                      label: 'Termijnen',
                      waarde: betalingsregeling!.AantalTermijnen.toString(),
                    },
                    {
                      label: 'Laatste termijn',
                      waarde: (
                        <FormatteerBedrag
                          bedrag={
                            betalingsregeling!.Totaalbedrag -
                            betalingsregeling!.Termijnbedrag *
                              (betalingsregeling!.AantalTermijnen - 1)
                          }
                        />
                      ),
                    },
                  ]}
                />
              </VeldWeergave>
            </div>
            <div className="col-4">
              <VeldWeergave>
                <GegevensLayout
                  gegevens={[
                    {
                      label: 'Reeds voldaan',
                      waarde: <FormatteerBedrag bedrag={resultaat.bedragVoldaan} />,
                    },
                    {
                      label: 'Nog voldoen',
                      waarde: <FormatteerBedrag bedrag={resultaat.bedragNogVoldoen} />,
                    },
                    null,
                    {
                      label: 'Laatste betaling',
                      waarde:
                        resultaat.laatsteBetaling !== null
                          ? format(new Date(resultaat.laatsteBetaling), 'dd-MM-yyyy')
                          : 'Geen',
                    },
                  ]}
                />
              </VeldWeergave>
            </div>
            <div className="col-4">
              <VeldWeergave>
                <GegevensLayout
                  gegevens={[
                    {
                      label: 'Openst. facturen',
                      waarde: (
                        <FormatteerBedrag
                          bedrag={_.sum(betalingsregeling!.facturen.map((x) => x.Openstaand))}
                        />
                      ),
                    },
                    {
                      label: 'Te boeken',
                      waarde: <FormatteerBedrag bedrag={resultaat.bedragTeBoeken} />,
                    },
                    null,
                    {
                      label: 'Openst. na boeken',
                      waarde: (
                        <span>
                          <b>
                            <FormatteerBedrag
                              bedrag={
                                _.sum(betalingsregeling!.facturen.map((x) => x.Openstaand)) -
                                resultaat.bedragTeBoeken
                              }
                            />
                          </b>
                        </span>
                      ),
                    },
                  ]}
                />
              </VeldWeergave>
            </div>
          </div>

          <div className="col-12 d-flex mt-3">
            <div className="col-12">
              <VeldWeergave>
                <div className="p-1 font-weight-bold">Facturen</div>
                <div className="mt-1" style={{ backgroundColor: EKleur.Wit }}>
                  <GridStyleWrapper maxHeight={400} rowAmount={facturen.length}>
                    <Grid rows={facturen} columns={kolommenVerkoopfacturen}>
                      <DataTypeProvider
                        for={[nameOf<IFactuur>('Factuurdatum')]}
                        formatterComponent={(formatterProps) => {
                          const rij: IFactuur = formatterProps.row;

                          return <span>{format(new Date(rij.Factuurdatum), 'dd-MM-yyyy')}</span>;
                        }}
                      />

                      <DataTypeProvider
                        for={[
                          nameOf<IFactuur>('Bedrag'),
                          nameOf<IFactuur>('BedragInRegeling'),
                          nameOf<IFactuur>('Openstaand'),
                        ]}
                        formatterComponent={(formatterProps) => {
                          return <FormatteerBedrag bedrag={formatterProps.value} />;
                        }}
                      />

                      <Table messages={{ noData: 'Geen facturen' }} />
                      <TableColumnResizing defaultColumnWidths={kolombreedtesVerkoopfacturen} />
                      <TableHeaderRow />
                    </Grid>
                  </GridStyleWrapper>
                </div>
              </VeldWeergave>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BetalingsregelingTab;
