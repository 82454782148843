import { makeObservable, observable } from 'mobx';
import { RootStore } from './RootStore';
import api from '../api';
import { IOphalenBestandenResultElement } from '../../../shared/src/api/v2/bestand/bestand';

class BestandStore {
  public bestandinfos: Record<number, IOphalenBestandenResultElement> = {};

  // tslint:disable-next-line:variable-name
  private _bestandenOphalenTimeoutId: number | null = null;
  // tslint:disable-next-line:variable-name
  private _bestandenOphalenBestandIds: number[] = [];
  // tslint:disable-next-line:variable-name
  private _rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this._rootStore = rootStore;

    makeObservable(this, {
      bestandinfos: observable,
    });
  }

  public setBestandinfo = (bestandID: number, info: IOphalenBestandenResultElement) => {
    this.bestandinfos = {
      ...this.bestandinfos,
      [bestandID]: info,
    };
  };

  public scheduleOphalenBestandinfos = (bestIDs: number[]) => {
    if (this._bestandenOphalenTimeoutId !== null) {
      clearTimeout(this._bestandenOphalenTimeoutId);
    }
    this._bestandenOphalenBestandIds = [
      ...new Set([...this._bestandenOphalenBestandIds, ...bestIDs]),
    ];
    this._bestandenOphalenTimeoutId = setTimeout(async () => {
      const bestIDs = [...this._bestandenOphalenBestandIds];
      const result = await api.v2.bestand.ophalenBestanden({
        filterSchema: {
          filters: [
            {
              naam: 'IDS',
              data: bestIDs,
            },
          ],
        },
      });
      this.bestandinfos = result.bestanden.reduce(
        (acc, curr) => ({
          ...acc,
          [curr.ID]: curr,
        }),
        this.bestandinfos,
      );
    }, 500) as any;
  };
}

export default BestandStore;
