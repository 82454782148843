import React, { useContext, useMemo } from 'react';
import { IProps } from '@tinymce/tinymce-react/lib/cjs/main/ts/components/Editor';
import { IContext, OpdrachtContext } from '../index';
import VeldWeergave from '../../../formulier/VeldWeergave';
import LoadingSpinner from '../../../Gedeeld/LoadingSpinner';
import {
  Grid,
  Table,
  TableColumnResizing,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-bootstrap4';
import GegevensLayout from '../../../layout/GegevensLayout';
import { Kleur as EKleur } from '../../../../bedrijfslogica/constanten';
import {
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import nameOf from '../../../../core/nameOf';
import { format } from 'date-fns';
import FormatteerBedrag from '../../../MutatieBedrag';
import RelatieVisualisatie from '../../../personalia/RelatieVisualisatie';
import { IVerkoopfactuur } from '../../../../../../shared/src/api/v2/bank/opdracht/opdracht';

const OpdrachtTab: React.FC<IProps> = (props) => {
  const { bankopdracht, betalingsregeling } = useContext<IContext>(OpdrachtContext);

  // const verkoopfacturen = bankopdracht.debiteurFacturen;

  const kolommenVerkoopfacturen = useMemo<TypedColumn<IVerkoopfactuur>[]>(
    () => [
      {
        name: 'Factuurnummer',
        title: 'Fact.nr.',
      },
      {
        name: 'Factuurdatum',
        title: 'Factuurdatum',
      },
      {
        name: 'Bedrag',
        title: 'Bedrag',
      },
      {
        name: 'Openstaand',
        title: 'Openst.',
      },
      {
        name: '__relatie' as any,
        title: 'Debiteur',
      },
    ],
    [],
  );

  const kolombreedtesVerkoopfacturen = useMemo<TypedTableColumnWidthInfo<IVerkoopfactuur>[]>(
    () => [
      {
        columnName: 'Factuurnummer',
        width: 100,
      },
      {
        columnName: 'Factuurdatum',
        width: 125,
      },
      {
        columnName: 'Bedrag',
        width: 100,
      },
      {
        columnName: 'Openstaand',
        width: 100,
      },
      {
        columnName: '__relatie' as any,
        width: 200,
      },
    ],
    [],
  );

  if (bankopdracht === null) {
    return <LoadingSpinner />;
  }

  const wijzigingIBAN =
    bankopdracht.WijzigingIBAN !== null
      ? bankopdracht.WijzigingIBAN === 1
        ? 'Bij zelfde bank'
        : bankopdracht.WijzigingIBAN === 2
        ? 'Bij andere bank'
        : 'Bij zelfde/andere bank'
      : 'Nee';

  return (
    <>
      <div className="d-flex flex-column p-3 flex-fill" style={{ rowGap: 10 }}>
        <div className="d-flex">
          <div style={{ flex: 5 }}>
            <VeldWeergave>
              <GegevensLayout
                gegevens={[
                  {
                    label: 'Uitvoerdatum',
                    waarde: `${
                      bankopdracht.Uitvoerdatum !== null
                        ? format(new Date(bankopdracht.Uitvoerdatum), 'dd-MM-yyyy')
                        : ''
                    }`,
                  },
                  {
                    label: 'Soort',
                    waarde: `${bankopdracht.Bedrag > 0 ? 'Incasso' : 'Betaling'}`,
                  },
                  {
                    label: 'Bedrag',
                    waarde: <FormatteerBedrag bedrag={Math.abs(bankopdracht.Bedrag)} />,
                  },
                  {
                    label: 'IBAN',
                    waarde: `${bankopdracht.IBAN}`,
                  },
                  {
                    label: 'Rekeningnaam',
                    waarde: `${bankopdracht.Rekeningnaam}`,
                  },
                  {
                    label: 'Specificatie verstuurd',
                    waarde: `${
                      bankopdracht.SpecificatieVerstuurd !== null
                        ? `${format(
                            new Date(bankopdracht.SpecificatieVerstuurd!),
                            'dd-MM-yyyy HH:mm',
                          )}`
                        : bankopdracht.relatie !== null &&
                          bankopdracht.relatie.crediteur !== null &&
                          bankopdracht.relatie.crediteur.BetaalspecificatieVersturen
                        ? 'Nog niet'
                        : '-'
                    }`,
                  },
                  // {
                  //   label: 'Specificatie verstuurd',
                  //   waarde: `${
                  //     bankopdracht.relatie !== null &&
                  //     bankopdracht.relatie.crediteur !== null &&
                  //     bankopdracht.relatie.crediteur.BetaalspecificatieVersturen
                  //       ? bankopdracht.SpecificatieVerstuurd !== null
                  //         ? `${format(new Date(bankopdracht.SpecificatieVerstuurd!), 'dd-MM-yyyy')}`
                  //         : 'Nee'
                  //       : '-'
                  //   }`,
                  // },
                ]}
              />
            </VeldWeergave>
          </div>
          <div style={{ minWidth: 20 }} />
          <div style={{ flex: 5 }}>
            <VeldWeergave>
              <GegevensLayout
                gegevens={[
                  {
                    label: 'Geregistreerd',
                    waarde: `${
                      bankopdracht.RecordToegevoegd !== null
                        ? format(new Date(bankopdracht.RecordToegevoegd), 'dd-MM-yyyy HH:mm')
                        : 'Onbekend'
                    }`,
                  },
                  {
                    label: 'Rel./medew.',
                    waarde:
                      bankopdracht.RelID !== null ? (
                        <span>
                          <RelatieVisualisatie
                            relID={bankopdracht.RelID}
                            options={{ geenLinkToepassen: true }}
                          />
                        </span>
                      ) : bankopdracht.MdwID !== null ? (
                        <span>TODO Mdw</span>
                      ) : (
                        <span></span>
                      ),
                  },
                  {
                    label: 'Groep',
                    waarde: `${
                      bankopdracht.Groep === 'D'
                        ? 'Debiteur'
                        : bankopdracht.Groep === 'C'
                        ? 'Crediteur'
                        : 'Medewerker'
                    }`,
                  },
                  bankopdracht.Bedrag > 0
                    ? {
                        label: 'Mandaat',
                        waarde: `${bankopdracht.Mandaat ?? 'Niet opgegeven'}`,
                      }
                    : null,

                  {
                    label: 'Wijziging IBAN',
                    waarde: `${wijzigingIBAN}`,
                  },
                  {
                    label: 'Oude IBAN',
                    waarde: `${
                      bankopdracht.WijzigingIBAN !== null
                        ? bankopdracht.OudeIBAN ?? 'Niet opgegeven'
                        : '-'
                    }`,
                  },

                  // {
                  //   label: 'Betaalspecificatie',
                  //   waarde: `${
                  //     bankopdracht.relatie !== null && bankopdracht.relatie.crediteur !== null
                  //       ? bankopdracht.relatie.crediteur.BetaalspecificatieVersturen
                  //         ? 'Ja'
                  //         : 'Nee'
                  //       : ''
                  //   }`,
                  // },
                  // {
                  //   label: 'Specificatie verstuurd',
                  //   waarde: `${
                  //     bankopdracht.relatie !== null &&
                  //     bankopdracht.relatie.crediteur !== null &&
                  //     bankopdracht.relatie.crediteur.BetaalspecificatieVersturen
                  //       ? bankopdracht.SpecificatieVerstuurd !== null
                  //         ? `${format(new Date(bankopdracht.SpecificatieVerstuurd!), 'dd-MM-yyyy')}`
                  //         : 'Nee'
                  //       : '-'
                  //   }`,
                  // },
                ]}
              />
            </VeldWeergave>
          </div>
        </div>

        <div className="d-flex">
          <div style={{ flex: 1 }} className="d-flex">
            {/* <label></label> */}
            <VeldWeergave style={{ flex: 1 }}>
              <GegevensLayout
                gegevens={[
                  {
                    label: 'Omschrijving',
                    waarde: `${bankopdracht.Omschrijving}`,
                  },
                  {
                    label: 'Betalingskenmerk',
                    waarde: `${bankopdracht.Betalingskenmerk}`,
                  },
                ]}
              />
            </VeldWeergave>
          </div>
        </div>

        <div className="d-flex">
          <div style={{ flex: 1 }} className="d-flex">
            {/* <label></label> */}
            <VeldWeergave style={{ flex: 1 }}>
              <GegevensLayout
                gegevens={[
                  {
                    label: 'Batch',
                    waarde: `${bankopdracht.batch !== null ? bankopdracht.batch.Batchnummer : '-'}`,
                  },
                  {
                    label: 'Verstuurd',
                    waarde: `${
                      bankopdracht.batch !== null
                        ? bankopdracht.batch.DatumVerstuurd !== null
                          ? format(new Date(bankopdracht.batch.DatumVerstuurd), 'dd-MM-yyyy HH:mm')
                          : 'Niet'
                        : '-'
                    }`,
                  },
                  {
                    label: 'Uitgevoerd',
                    waarde: `${
                      bankopdracht.batch !== null
                        ? bankopdracht.batch.UitgevoerdOp !== null
                          ? format(new Date(bankopdracht.batch.UitgevoerdOp), 'dd-MM-yyyy')
                          : 'Niet'
                        : '-'
                    }`,
                  },
                ]}
              />
            </VeldWeergave>
          </div>
        </div>

        {betalingsregeling !== null && (
          <div style={{ flex: 1 }} className="d-flex">
            <VeldWeergave style={{ flex: 1 }}>
              <span>
                Op grond van betalingsregeling d.d.{' '}
                {format(new Date(betalingsregeling.Datum), 'dd-MM-yyyy')},{' '}
                {betalingsregeling.AantalTermijnen} termijnen
              </span>
            </VeldWeergave>
          </div>
        )}
      </div>

      {bankopdracht.crediteurFacturen.length === 0 && bankopdracht.debiteurFacturen.length === 0 ? (
        <div className="mt-2 pl-3 pr-3 pt-2 mb-4">
          <VeldWeergave>Er zijn geen gekoppelde facturen aanwezig voor deze opdracht </VeldWeergave>
        </div>
      ) : (
        <>
          {bankopdracht.crediteurFacturen.length !== 0 && (
            <div className="mt-2 pl-3 pr-3 pt-2 mb-4">
              <VeldWeergave>
                <div className="p-1 font-weight-bold">Inkoopfacturen</div>
                <div className="mt-1" style={{ backgroundColor: EKleur.Wit }}>
                  <GridStyleWrapper
                    maxHeight={400}
                    rowAmount={bankopdracht.crediteurFacturen.length}
                  >
                    <Grid rows={bankopdracht.crediteurFacturen} columns={kolommenVerkoopfacturen}>
                      <DataTypeProvider
                        for={[nameOf<IVerkoopfactuur>('Factuurdatum')]}
                        formatterComponent={(formatterProps) => {
                          const rij: IVerkoopfactuur = formatterProps.row;

                          return <span>{format(new Date(rij.Factuurdatum), 'dd-MM-yyyy')}</span>;
                        }}
                      />

                      <DataTypeProvider
                        for={[nameOf<IVerkoopfactuur>('Bedrag')]}
                        formatterComponent={(formatterProps) => {
                          return <FormatteerBedrag bedrag={formatterProps.value} />;
                        }}
                      />

                      <DataTypeProvider
                        for={[nameOf<IVerkoopfactuur>('Openstaand')]}
                        formatterComponent={(formatterProps) => {
                          return <FormatteerBedrag bedrag={formatterProps.value} />;
                        }}
                      />

                      <DataTypeProvider
                        for={['__relatie']}
                        formatterComponent={(formatterProps) => {
                          const rij: IVerkoopfactuur = formatterProps.row;

                          return <RelatieVisualisatie relID={rij.RelID} />;
                        }}
                      />

                      <Table messages={{ noData: 'Geen inkoopfacturen' }} />
                      <TableColumnResizing defaultColumnWidths={kolombreedtesVerkoopfacturen} />
                      <TableHeaderRow />
                    </Grid>
                  </GridStyleWrapper>
                </div>
              </VeldWeergave>
            </div>
          )}

          {bankopdracht.debiteurFacturen.length !== 0 && (
            <div className="mt-2 pl-3 pr-3 pt-2 mb-4">
              <VeldWeergave>
                <div className="p-1 font-weight-bold">Verkoopfacturen</div>
                <div className="mt-1" style={{ backgroundColor: EKleur.Wit }}>
                  <GridStyleWrapper
                    maxHeight={400}
                    rowAmount={bankopdracht.debiteurFacturen.length}
                  >
                    <Grid rows={bankopdracht.debiteurFacturen} columns={kolommenVerkoopfacturen}>
                      <DataTypeProvider
                        for={[nameOf<IVerkoopfactuur>('Factuurdatum')]}
                        formatterComponent={(formatterProps) => {
                          const rij: IVerkoopfactuur = formatterProps.row;

                          return <span>{format(new Date(rij.Factuurdatum), 'dd-MM-yyyy')}</span>;
                        }}
                      />

                      <DataTypeProvider
                        for={[nameOf<IVerkoopfactuur>('Bedrag')]}
                        formatterComponent={(formatterProps) => {
                          return <FormatteerBedrag bedrag={formatterProps.value} />;
                        }}
                      />

                      <DataTypeProvider
                        for={[nameOf<IVerkoopfactuur>('Openstaand')]}
                        formatterComponent={(formatterProps) => {
                          return <FormatteerBedrag bedrag={formatterProps.value} />;
                        }}
                      />

                      <DataTypeProvider
                        for={['__relatie']}
                        formatterComponent={(formatterProps) => {
                          const rij: IVerkoopfactuur = formatterProps.row;

                          return <RelatieVisualisatie relID={rij.RelID} />;
                        }}
                      />

                      <Table messages={{ noData: 'Geen verkoopfacturen' }} />
                      <TableColumnResizing defaultColumnWidths={kolombreedtesVerkoopfacturen} />
                      <TableHeaderRow />
                    </Grid>
                  </GridStyleWrapper>
                </div>
              </VeldWeergave>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default OpdrachtTab;
