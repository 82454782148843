import React from 'react';

export interface IMergeDialoogVeld<TData> {
  label: string;
  input: React.ComponentType<IMergeDialoogVeldInputProps<TData>>;
  formatter: React.ComponentType<IMergeDialoogVeldFormatterProps<TData>>;
  height: number;
}

export enum EDataType {
  Onbepaald,
  Bepaald,
}

export interface IOnbepaaldData {
  type: EDataType.Onbepaald;
}

export interface IBepaaldData<TData> {
  type: EDataType.Bepaald;
  data: TData;
}

export type IData<TData> = IOnbepaaldData | IBepaaldData<TData>;

export interface IEntiteit<TVelden extends string | number | symbol, TVasteGegevens = {}> {
  key: string | number;
  toepassen: boolean;
  header: React.ComponentType<IMergeDialoogEntiteitHeaderProps>;
  data: Record<TVelden, any>;
  vasteGegevens: TVasteGegevens;
  breedte?: number;
}

export interface IMergeDialoogVeldInputProps<TData> {
  data: IData<TData>;
  onDataChange: (data: IData<TData>) => void;
}

export interface IMergeDialoogVeldFormatterProps<TData> {
  data: TData;
  andereDatas: TData[];
}

export interface IMergeDialoogEntiteitHeaderProps {
  dialoogIndex: number;
}

export interface IMergeDialoogVastGegevenFormatterProps<
  TVelden extends string | number | symbol,
  TVasteGegevens
> {
  entiteit: IEntiteit<TVelden, TVasteGegevens>;
}

export interface IVastGegeven<TVelden extends string | number | symbol, TVasteGegevens> {
  label: string;
  height: number;
  formatter: React.ComponentType<IMergeDialoogVastGegevenFormatterProps<TVelden, TVasteGegevens>>;
}
