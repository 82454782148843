import api from '../../../index';
import {
  ICheckToevoegenRekeningParams,
  IOphalenRekeningenParams,
  IOphalenRekeningenResult,
  IWijzigenIndicatieRekeningIsStandaardParams,
  IWijzigenIndicatieRekeningIsStandaardResult,
} from '../../../../../../shared/src/api/v2/relatie';
import ICheckData from '../../../../../../shared/src/models/ICheckData';
import {
  IKoppelenIBANAanRelatieParams,
  IKoppelenIBANAanRelatieResult,
} from '../../../../../../shared/src/api/v2/bank/mutaties';

const rekening = {
  ophalenRekeningen: async (
    params: IOphalenRekeningenParams,
  ): Promise<IOphalenRekeningenResult> => {
    return await api.post('v2/relatie/rekening/ophalen-rekeningen', params);
  },
  wijzigenIndicatieRekeningIsStandaard: async (
    params: IWijzigenIndicatieRekeningIsStandaardParams,
  ): Promise<IWijzigenIndicatieRekeningIsStandaardResult> => {
    return await api.post('v2/relatie/rekening/wijzigen-indicatie-rekening-is-standaard', params);
  },
  checkToevoegenRekening: async (params: ICheckToevoegenRekeningParams): Promise<ICheckData> => {
    return await api.post('v2/relatie/rekening/check-toevoegen-rekening', params);
  },
  checkKoppelenIBANAanRelatie: async (
    params: IKoppelenIBANAanRelatieParams,
  ): Promise<ICheckData> => {
    return await api.post('v2/bank/mutatie/check-koppelen-iban-aan-relatie', params);
  },
  koppelenIBANAanRelatieParams: async (
    params: IKoppelenIBANAanRelatieParams,
  ): Promise<IKoppelenIBANAanRelatieResult> => {
    return await api.post('v2/bank/mutatie/koppelen-iban-aan-relatie', params);
  },
};

export default rekening;
