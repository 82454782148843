import React from 'react';
import PersoonVisualisatie from '../../../../../personalia/PersoonVisualisatie';

interface IProps {
  data: { persID: number };
}

const PersoonInhoud = (props: IProps) => {
  return <PersoonVisualisatie persID={props.data.persID} />;
};

export default PersoonInhoud;
