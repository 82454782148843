import React, { useContext, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import { RootStoreContext } from '../../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import Placeholder from './Placeholder';
import Data from './Data';
import IRemoteData, { ERemoteDataState } from '../../../../models/IRemoteData';
import { IOphalenContactverzoekenResultElement } from '../../../../../../shared/src/api/v2/contactverzoek';

interface IProps {
  ctcVerzoekID: number; // bijv 'persID', nog hernoemen
}

const Root = styled.div`
  background: ${Kleur.Wit};
  border: 1px solid ${Kleur.LichtGrijs};
  border-radius: 4px;
  padding: 12px 16px;
  flex: 1;
  width: 500px;
`;

const InfoCard = observer((props: IProps) => {
  const { contactverzoekStore } = useContext(RootStoreContext);
  const contactverzoek = useMemo<IRemoteData<IOphalenContactverzoekenResultElement> | null>(() => {
    return contactverzoekStore.contactverzoeken[props.ctcVerzoekID] ?? null;
  }, [contactverzoekStore.contactverzoeken, props.ctcVerzoekID]);
  useEffect(() => {
    if (contactverzoek !== null) {
      return;
    }
    contactverzoekStore.enqueueOphalenContactverzoeken([props.ctcVerzoekID]);
  }, [contactverzoek, props.ctcVerzoekID]);

  return (
    <Root onClick={(ev) => ev.stopPropagation()}>
      {contactverzoek === null || contactverzoek.state === ERemoteDataState.Pending ? (
        <Placeholder />
      ) : (
        <Data contactverzoek={contactverzoek.data!} />
      )}
    </Root>
  );
});

export default InfoCard;
