import React, { useEffect, useMemo, useState } from 'react';
import { FormikProps } from 'formik';
import { ETablad, IFormikValues } from './index';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import Tabblad, { ITabblad } from '../../../../../../../../components/layout/Tabblad';
import MeldingTab from './MeldingTab';
import OpdrachtTab from './OpdrachtTab';
import nameof from '../../../../../../../../core/nameOf';
import { IOphalenProductenResultElementV2 } from '../../../../../../../../../../shared/src/api/v2/product';
import { IFilterSchemaFilter } from '../../../../../../../../../../shared/src/models/filter';
import api from '../../../../../../../../api';
import { IOphalenServicedienstenResultElement } from '../../../../../../../../../../shared/src/api/v2/service/opdracht';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
} from '../../../../../../../../models/IRemoteData';

interface IProps {
  formikProps: FormikProps<IFormikValues>;
  onAnnuleren: () => void;
  tabblad: ETablad;
  onTabbladChange: (tabblad: ETablad) => void;
  relID: number | null;
  prodID: number | null;
  locIDs: number[] | null;
  cntID: number | null;
  product: IOphalenProductenResultElementV2 | null;
  onProductChange: (product: IRemoteData<IOphalenProductenResultElementV2 | null>) => void;
  bepaalProduct: (prodID: number) => Promise<IOphalenProductenResultElementV2>;
  bepaalTelefoonnummerEnEmail: (
    persID: number,
  ) => Promise<{ telefoonnummer: string | null; email: string | null }>;
}

export interface IFormulierContext {
  values: IFormikValues;
  producten: IOphalenProductenResultElementV2[] | null;
  servicediensten: IOphalenServicedienstenResultElement[] | null;
  relID: number | null;
}

export const FormulierContext = React.createContext<IFormulierContext>(null as any);

const Formulier = (props: IProps) => {
  const { onAnnuleren, tabblad, onTabbladChange, relID, product } = props;
  const { values, submitForm, isSubmitting, isValid, setFieldValue } = props.formikProps;

  // Rijbron Producten ophalen
  const [producten, setProducten] = useState<IOphalenProductenResultElementV2[] | null>(null);
  useEffect(() => {
    (async () => {
      const filters = [
        props.prodID !== null ? { naam: 'IDS', data: [props.prodID] } : null,
        props.relID !== null ? { naam: 'REL_IDS', data: [props.relID] } : null,
        props.locIDs !== null ? { naam: 'LOC_IDS', data: props.locIDs } : null,
        props.cntID !== null ? { naam: 'CNT_IDS', data: [props.cntID] } : null,
      ].filter((x) => x !== null) as IFilterSchemaFilter[];

      const producten = (
        await api.v2.product.ophalenProductenV2({
          filterSchema: {
            filters,
          },
          paginatie: {
            index: 0,
            aantal: 50,
          },
        })
      ).producten;

      setProducten(producten);
    })();
  }, [props.prodID, props.relID, props.locIDs, props.cntID]);

  const [servicediensten, setServicediensten] = useState<
    IOphalenServicedienstenResultElement[] | null
  >(null);

  useEffect(() => {
    if (values.locID === null) {
      setServicediensten(null);
      return;
    }

    (async () => {
      setFieldValue(nameof<IFormikValues>('servDienstID'), createPendingRemoteData());
      const result = await api.v2.service.ophalenServicediensten({
        garantie: values.garantie,
        merkID: product === null ? null : product.producttype.MerkID,
        locID: values.locID!,
        relID: props.relID !== null ? props.relID : undefined,
        overigeDienstenWeergeven: true,
      });
      const diensten = result.diensten;

      setServicediensten(diensten);
      setFieldValue(nameof<IFormikValues>('servDienstID'), createReadyRemoteData(diensten[0].ID));
    })();
  }, [values.locID, values.garantie, product]);

  const tabbladen = useMemo<ITabblad<ETablad>[]>(
    () => [
      {
        id: ETablad.Melding,
        label: 'Melding',
        content: MeldingTab,
      },
      {
        id: ETablad.Opdracht,
        label: 'Rep.opdracht',
        content: OpdrachtTab,
      },
    ],
    [],
  );

  useEffect(() => {
    (async () => {
      const result =
        values.melder_PersID === null
          ? null
          : await props.bepaalTelefoonnummerEnEmail(values.melder_PersID);
      setFieldValue(nameof<IFormikValues>('telefoonnummer'), result?.telefoonnummer ?? null);
      setFieldValue(nameof<IFormikValues>('email'), result?.email ?? '');
    })();
  }, [values.melder_PersID]);

  useEffect(() => {
    if (props.formikProps.values.prodID === null) {
      return;
    }
    if (product !== null && product.ProdID === props.formikProps.values.prodID) {
      return;
    }
    (async () => {
      const result = await props.bepaalProduct(props.formikProps.values.prodID!);
      props.onProductChange(createReadyRemoteData(result));
    })();
  }, [props.formikProps.values.prodID, product?.ProdID ?? null]);

  const context = useMemo<IFormulierContext>(() => {
    return {
      values,
      relID,
      producten,
      servicediensten,
    };
  }, [relID, producten, values, servicediensten]);

  return (
    <FormulierContext.Provider value={context}>
      <ModalBody style={{ padding: 0 }}>
        <div style={{ minHeight: `550px` }} className="d-flex flex-fill flex-column">
          <Tabblad<ETablad>
            tabbladen={tabbladen}
            geselecteerd={tabblad}
            onSelectieChange={onTabbladChange}
          />
        </div>
      </ModalBody>
      <ModalFooter className="d-flex flex-row justify-content-start">
        <button
          className="btn btn-primary d-flex align-items-center justify-content-center"
          onClick={submitForm}
          style={{ width: 100 }}
          disabled={isSubmitting}
        >
          Ok
        </button>
        <button
          className="btn btn-secondary"
          onClick={onAnnuleren}
          style={{ width: 100 }}
          disabled={isSubmitting}
        >
          Annuleren
        </button>
      </ModalFooter>
    </FormulierContext.Provider>
  );
};

export default Formulier;
