import React, { useContext, useEffect, useMemo } from 'react';
import { RootStoreContext } from '../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import { ERemoteDataState } from '../../../models/IRemoteData';
import { RouteComponentProps, withRouter } from 'react-router-dom';
// import {
//   IUrlState as IFacturenOverzichtUrlState,
//   defaultUrlState as facturenOverzichtDefaultUrlState,
// } from '../../../paginas/Klant/Klantkaart/Entiteiten/entiteiten/Facturen/Overzicht';
import { genereerUrlStateQueryParam } from '../../../core/useUrlState';
import {
  IUrlState as IVerkoopvoorstellenOverzichtUrlState,
  defaultUrlState as verkoopvoorstellenOverzichtDefaultUrlState,
} from '../../../paginas/Klant/Klantkaart/Entiteiten/entiteiten/Contracten/Verkoop';

interface IProps extends RouteComponentProps {
  verkVrstID: number;
}

const VerkoopvoorstelVisualisatie: React.FC<IProps> = observer((props) => {
  const { verkoopvoorstellenStore } = useContext(RootStoreContext);

  useEffect(() => {
    if (
      verkoopvoorstellenStore.verkoopvoorstellen.state === ERemoteDataState.Pending ||
      verkoopvoorstellenStore.verkoopvoorstellen.data![props.verkVrstID] === undefined
    ) {
      verkoopvoorstellenStore.enqueueOphalenVerkoopvoorstellen([props.verkVrstID]);
    }
  }, [props.verkVrstID]);

  const verkoopvoorstel = useMemo(() => {
    if (
      verkoopvoorstellenStore.verkoopvoorstellen.state === ERemoteDataState.Pending ||
      verkoopvoorstellenStore.verkoopvoorstellen.data![props.verkVrstID] === undefined
    ) {
      return null;
    }
    return verkoopvoorstellenStore.verkoopvoorstellen.data![props.verkVrstID]!;
  }, [props.verkVrstID, verkoopvoorstellenStore.verkoopvoorstellen]);

  if (verkoopvoorstel === null) {
    return <span>Laden...</span>;
  }

  return (
    <a
      href="#"
      className="d-flex align-items-center"
      onClick={(ev) => {
        ev.preventDefault();

        const urlState: IVerkoopvoorstellenOverzichtUrlState = {
          ...verkoopvoorstellenOverzichtDefaultUrlState,
          selectie: [verkoopvoorstel.ID],
        };
        const query = genereerUrlStateQueryParam(urlState);
        // props.history.push(`/klant/${factuur.RelID}/facturen/overzicht?state=${query}`);

        props.history.push(`/klant/${verkoopvoorstel.RelID}/contracten/verkoop?state=${query}`);
      }}
    >
      <span>
        {verkoopvoorstel.Merknaam} {verkoopvoorstel.Typenaam}
      </span>
    </a>
  );
});

export default withRouter(VerkoopvoorstelVisualisatie);
