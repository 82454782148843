import React, { useCallback, useContext, useMemo } from 'react';
import Zoeken from '../../../formulier/Zoeken';
import { ProducttypeSelectieDialoogContext } from '../index';
import MultiComboboxV2, {
  EnkeleProvider,
  IRepresentatieProps,
  Provider,
} from '../../../formulier/MultiComboboxV2';
import { IOphalenProductsoortenResultElement } from '../../../../../../shared/src/api/v2/product/soort';
import api from '../../../../api';
import { ASPKolom, EAspKolomBreedteType, ESortering } from '../../../tabel/ASPTabel/types';
import ASPTabel from '../../../tabel/ASPTabel';
import { ERemoteDataState } from '../../../../models/IRemoteData';
import LoadingSpinner from '../../../Gedeeld/LoadingSpinner';
import { IOphalenProducttypenResultElement } from '../../../../../../shared/src/api/v2/product/type';
import { Kleur } from '../../../../bedrijfslogica/constanten';

enum EProductsoortKey {
  Naam,
}

enum EProducttypeKey {
  Typenaam,
  Merknaam,
  Soortnaam,
  Kies,
}

const ZoekenTabblad = (): JSX.Element => {
  const context = useContext(ProducttypeSelectieDialoogContext);

  const productsoortProvider = useMemo<
    Provider<EProductsoortKey, IOphalenProductsoortenResultElement>
  >(
    () => async (params) => {
      const result = await api.v2.productsoort.ophalenProductsoorten({
        // We hebben geen totaal aantal
        // paginatie: params.paginatie,
        orderSchema: {
          orders: params.sortering.map((x) => {
            let naam: string;
            switch (x.key) {
              case EProductsoortKey.Naam:
                naam = 'NAAM';
                break;
            }

            return {
              naam,
              richting: x.sortering === ESortering.Ascending ? 'ASC' : 'DESC',
            };
          }),
        },
      });

      const items = result.reduce(
        (acc, curr, i) => ({
          ...acc,
          [i]: curr,
        }),
        params.huidigeBron,
      );

      return {
        totaalAantal: result.length,
        items,
      };
    },
    [],
  );

  const enkeleProductsoortProvider = useMemo<
    EnkeleProvider<number, IOphalenProductsoortenResultElement>
  >(
    () => async (id) => {
      const result = await api.v2.productsoort.ophalenProductsoorten({
        filterSchema: {
          filters: [
            {
              naam: 'IDS',
              data: [id],
            },
          ],
        },
      });

      return result[0];
    },
    [],
  );

  const productsoortKeyExtractor = useMemo<(item: IOphalenProductsoortenResultElement) => number>(
    () => (item) => item.ProdSrtID,
    [],
  );

  const productsoortRepresentatieComponent = useMemo<
    React.ComponentType<IRepresentatieProps<IOphalenProductsoortenResultElement>>
  >(
    () => (props) => {
      return <span>{props.entiteit.Naam}</span>;
    },
    [],
  );

  const productsoortKolommen = useMemo<
    ASPKolom<EProductsoortKey, IOphalenProductsoortenResultElement>[]
  >(
    () => [
      {
        key: EProductsoortKey.Naam,
        label: 'Naam',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 300,
        renderer: (item) => <span>{item.Naam}</span>,
      },
    ],
    [],
  );

  const producttypenKeyExtractor = useCallback(
    (item: IOphalenProducttypenResultElement) => item.TypeID,
    [],
  );
  const producttypenKolommen = useMemo<
    ASPKolom<EProducttypeKey, IOphalenProducttypenResultElement>[]
  >(
    () => [
      {
        key: EProducttypeKey.Typenaam,
        label: 'Type',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 150,
        renderer: (item) => <span>{item.Typenaam}</span>,
      },
      {
        key: EProducttypeKey.Merknaam,
        label: 'Merk',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 125,
        renderer: (item) => <span>{item.Merknaam}</span>,
      },
      {
        key: EProducttypeKey.Soortnaam,
        label: 'Soort',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 125,
        renderer: (item) => <span>{item.Productsoortnaam}</span>,
      },
      {
        key: EProducttypeKey.Kies,
        breedteType: EAspKolomBreedteType.Flex,
        flex: 1,
        renderer: (item) => (
          <a
            href="#"
            onClick={(ev) => {
              ev.preventDefault();
              context.onProdTypeIDGekozen(item.TypeID);
            }}
          >
            Kies
          </a>
        ),
      },
    ],
    [],
  );

  return (
    <div className="flex-fill d-flex flex-column">
      <div className="pt-3 pb-2 pl-3 pr-3">
        <div className="pb-2">
          <Zoeken
            input={context.zoekterm}
            onInputChange={context.setZoekterm}
            placeholder="Zoeken..."
          />
        </div>
        <MultiComboboxV2
          provider={productsoortProvider}
          enkeleProvider={enkeleProductsoortProvider}
          keyExtractor={productsoortKeyExtractor}
          waarde={context.prodSrtID}
          onChange={context.setProdSrtID}
          representatieComponent={productsoortRepresentatieComponent}
          kolommen={productsoortKolommen}
          wisbaar
          className="mt-2"
        />
      </div>

      <div
        className="flex-fill d-flex flex-column mt-2 w-100"
        style={{ height: 300, borderTop: `1px solid ${Kleur.LichtGrijs}` }}
      >
        {context.producttypenResult.state === ERemoteDataState.Pending ? (
          <div className="flex-fill d-flex flex-column justify-content-center align-items-center">
            <LoadingSpinner />
          </div>
        ) : (
          <ASPTabel
            rijen={context.producttypenResult.data!.producttypen}
            kolommen={producttypenKolommen}
            keyExtractor={producttypenKeyExtractor}
          />
        )}
      </div>
    </div>
  );
};

export default ZoekenTabblad;
