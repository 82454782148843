import React, { useCallback, useEffect, useMemo, useState } from 'react';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../models/IRemoteData';
import ASPTabel from '../../tabel/ASPTabel';
import { ASPKolom, EAspKolomBreedteType } from '../../tabel/ASPTabel/types';
import { EVerkennerItemType } from '../TabelWeergave/types';
import { getIconFile, IconMap } from '../../Icons';
import { Kleur } from '../../../bedrijfslogica/constanten';
import Skeleton from 'react-loading-skeleton';
import {
  IOphalenMediaTypesV2Result,
  IOphalenMediaTypesV2ResultElement,
} from '../../../../../shared/src/api/v2/mediatype';
import api from '../../../api';
import filesize from 'filesize';
import { IOphalenBestandsmappenResultElement } from '../../../../../shared/src/api/v2/bestand/bestand';
import { vanVerkennerItemId } from '../helpers';
import { IVerkennerItem } from '../types';
import TableData from '../../tabel/ASPTabel/Body/TableData';

enum EKolom {
  Soort,
  Naam,
  Grootte,
  Type,
  DatumToegevoegd,
  Opslagdienst,
}

interface IProps {
  itemsData: IRemoteData<{
    totaalAantal: number;
    items: Record<string, IVerkennerItem>;
  }>;
  onItemGekozen: (item: IVerkennerItem) => void;
}

const ZoekresultatenWeergave = (props: IProps) => {
  const [mediatypenResult, setMediatypenResult] = useState<IRemoteData<IOphalenMediaTypesV2Result>>(
    createPendingRemoteData(),
  );
  useEffect(() => {
    api.v2.mediaType
      .ophalenMediaTypes({})
      .then((result) => setMediatypenResult(createReadyRemoteData(result)));
  }, []);

  const mediatypenMap = useMemo<Record<number, IOphalenMediaTypesV2ResultElement> | null>(() => {
    if (mediatypenResult.state === ERemoteDataState.Pending) {
      return null;
    }
    return mediatypenResult.data!.mediaTypes.reduce((acc, curr) => {
      return {
        ...acc,
        [curr.ID]: curr,
      };
    }, {});
  }, [mediatypenResult]);

  const [bestandsmappen, setBestandsmappen] = useState<
    Record<number, IOphalenBestandsmappenResultElement>
  >({});

  const handleBestandensmappenOphalen = useCallback(async () => {
    const ids = Object.keys(props.itemsData.data!.items)
      .map((key) => props.itemsData.data!.items[key])
      .map((item) => vanVerkennerItemId(item.id));
    const bestandsmapIDs = ids.filter((x) => x.type === EVerkennerItemType.Map).map((x) => x.id);
    const bestandIDs = ids.filter((x) => x.type === EVerkennerItemType.Bestand).map((x) => x.id);

    const mappenPromise = api.v2.bestand.ophalenBovenliggendeBestandsmappenVoorBestandsmappen({
      bestandsmapIDs,
    });
    const bestandenPromise = api.v2.bestand.ophalenBovenliggendeBestandsmappenVoorBestanden({
      bestandIDs,
    });

    const [mappenResult, bestandenResult] = await Promise.all([mappenPromise, bestandenPromise]);
    const alleBestandsmapIDs = [
      ...new Set([...mappenResult.bestandsmapIDs, ...bestandenResult.bestandsmapIDs]),
    ];
    const bestandsmappenResult = await api.v2.bestand.ophalenBestandsmappen({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: alleBestandsmapIDs,
          },
        ],
      },
    });
    const nieuweData = bestandsmappenResult.mappen.reduce((acc, curr) => {
      return {
        ...acc,
        [curr.ID]: curr,
      };
    }, bestandsmappen);
    setBestandsmappen(nieuweData);
  }, [props.itemsData]);

  useEffect(() => {
    if (props.itemsData.state === ERemoteDataState.Pending) {
      setBestandsmappen({});
      return;
    }
    handleBestandensmappenOphalen();
  }, [handleBestandensmappenOphalen, props.itemsData]);

  const kolommen = useMemo<ASPKolom<EKolom, IVerkennerItem>[]>(
    () => [
      {
        key: EKolom.Soort,
        label: null,
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 60,
        renderer: (row) => {
          switch (row.type) {
            case EVerkennerItemType.Bestand: {
              const mediaType = mediatypenMap![row.bestand.MediaTypeID];
              if (mediaType === undefined) {
                return <Skeleton />;
              }
              const Svg = getIconFile(mediaType.MediaType);
              return <Svg style={{ width: 28, height: 28 }} />;
            }
            case EVerkennerItemType.Map: {
              return <IconMap style={{ width: 34, height: 34, fill: Kleur.ZwembadBlauw }} />;
            }
          }
        },
      },
      {
        key: EKolom.Naam,
        label: 'Naam',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 750,
        tdComponent: (tdProps) => (
          <TableData
            {...tdProps}
            style={{ cursor: 'pointer' }}
            title="Dubbelklik om te navigeren naar item"
            onDoubleClick={(ev) => {
              if (tdProps.row === undefined) {
                return;
              }
              props.onItemGekozen(tdProps.row);
            }}
          />
        ),
        renderer: (row) => {
          let naam: string;
          let bovenliggendeBestandsmapID: number | null = null;
          switch (row.type) {
            case EVerkennerItemType.Bestand:
              naam = row.bestand.Naam;
              bovenliggendeBestandsmapID = row.bestand.BestandsmapID;
              break;
            case EVerkennerItemType.Map:
              naam = row.map.Naam;
              bovenliggendeBestandsmapID = row.map.Bovenliggende_BestandsmapID;
              break;
          }

          const recursiefPadBepalen = (
            bestandsmapID: number | null,
            curr?: number[],
          ): number[] | null => {
            if (bestandsmapID === null) {
              return curr ?? [];
            }
            const bestandsmap = bestandsmappen[bestandsmapID] ?? null;
            if (bestandsmap === null) {
              return null;
            }
            const boom = recursiefPadBepalen(bestandsmap.Bovenliggende_BestandsmapID);
            if (boom === null) {
              return null;
            }
            return [...boom, bestandsmapID];
          };
          const padArr: number[] | null = recursiefPadBepalen(bovenliggendeBestandsmapID);
          const pad =
            padArr === null ? null : padArr.map((id) => bestandsmappen[id]!.Naam).join('/');

          return (
            <div className="d-flex flex-column">
              <span
                title={naam}
                style={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                }}
              >
                {naam}
              </span>
              <span className="text-muted">/{pad}</span>
            </div>
          );
        },
      },
      {
        key: EKolom.Grootte,
        label: 'Grootte',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 200,
        renderer: (row) => {
          switch (row.type) {
            case EVerkennerItemType.Bestand: {
              return (
                <span style={{ fontSize: 12 }}>
                  {row.bestand.Grootte === null ? '- -' : filesize(row.bestand.Grootte)}
                </span>
              );
            }
            case EVerkennerItemType.Map: {
              return (
                <span style={{ fontSize: 12 }}>
                  {row.map.aantalBestanden + row.map.aantalMappen} items
                </span>
              );
            }
          }
        },
      },
      {
        key: EKolom.Type,
        label: 'Type',
        breedteType: EAspKolomBreedteType.Flex,
        flex: 1,
        renderer: (row) => {
          switch (row.type) {
            case EVerkennerItemType.Bestand: {
              const mediaType = mediatypenMap![row.bestand.MediaTypeID];
              if (mediaType === undefined) {
                return <Skeleton />;
              }
              return mediaType.Naam ?? mediaType.MediaType;
            }
            case EVerkennerItemType.Map: {
              return 'Map';
            }
          }
        },
      },
    ],
    [mediatypenMap, bestandsmappen],
  );

  return (
    <div className="d-flex flex-fill flex-column">
      {props.itemsData.state === ERemoteDataState.Pending ? (
        <div className="d-flex flex-fill flex-column align-items-center justify-content-center">
          <h4>Voer een zoekterm in om te beginnen...</h4>
        </div>
      ) : (
        <ASPTabel<string, EKolom, IVerkennerItem>
          keyExtractor={(item) => item.id}
          rijen={props.itemsData.data!.items}
          kolommen={kolommen}
          totaalAantalRijen={props.itemsData.data!.totaalAantal}
          rijHoogte={55}
        />
      )}
    </div>
  );
};

export default ZoekresultatenWeergave;
