import React, { useContext, useEffect, useMemo, useState, useCallback } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { observer } from 'mobx-react-lite';
import api from '../../../api';
import {
  DXTableCheckboxComponent,
  DXTableToggleCellComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../helpers/dxTableGrid';
import LoadingSpinner from '../../../components/Gedeeld/LoadingSpinner';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  DataTypeProvider,
  IntegratedFiltering,
  IntegratedSorting,
  SearchState,
  Sorting,
  SortingState,
  VirtualTable as VirtualTableBase,
} from '@devexpress/dx-react-grid';
import { RowDetailState, SelectionState } from '@devexpress/dx-react-grid';
import {
  IOphalenVorderingenResultElement,
  IOphalenWOPInfoVoorVorderingenResultElement,
} from '../../../../../shared/src/api/v2/vordering';
import FormatteerBedrag from '../../../components/MutatieBedrag';
import RelatieVisualisatie from '../../../components/personalia/RelatieVisualisatie';
import { Kleur } from '../../../bedrijfslogica/constanten';
import useUrlState from '../../../core/useUrlState';
import { format } from 'date-fns';
import FactuurVoorstelDialoog from './FactuurVoorstelDialoog';
import { RootStoreContext } from '../../../stores/RootStore';
import { EResultType } from '../../../stores/CheckStore';
import WijzigenVorderingDialoog from './WijzigenVorderingDialoog';
import SelectieVak from '../../../components/SelectieVak';
import RowDetailComp from './RowDetailComp';
import _ from 'lodash';
import { ERegelstatusVordering } from '../../../bedrijfslogica/enums';
import ContractVisualisatie from '../../../components/entiteitVisualisaties/ContractVisualisatie';
import { IOphalenRelatiesResultElementV2 } from '../../../../../shared/src/api/v2/relatie';
import { Helmet } from 'react-helmet';

interface IProps extends RouteComponentProps {}

interface IUrlState {
  selection: number[];
  sortering: Sorting[];
}

const defaultUrlState: IUrlState = {
  selection: [],
  sortering: [
    {
      columnName: '__status',
      direction: 'desc',
    },
    {
      columnName: '__recordGeregistreerd',
      direction: 'desc',
    },
  ],
};

export interface IRegel extends IOphalenVorderingenResultElement {
  relatie: IOphalenRelatiesResultElementV2;
}

const Vorderingen: React.FC<IProps> = observer((props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);

  const { checkStore } = useContext(RootStoreContext);
  const [vorderingen, setVorderingen] = useState<IRegel[] | null>(null);
  const [wopInfos, setWOPInfos] = useState<IOphalenWOPInfoVoorVorderingenResultElement[] | null>(
    null,
  );

  const [voorstelDialoogTonen, setVoorstelDialoogTonen] = useState<boolean>(false);
  const [wijzigVorderingRelID, setWijzigVorderingRelID] = useState<number>(0);

  const keyExtractor = useCallback((row: IRegel) => row.FactRegID, []);

  const ophalenvorderingen = useCallback(async () => {
    const vorderingenResult = (
      await api.v2.vordering.ophalenVorderingen({
        filterSchema: { filters: [] },
        orderSchema: {
          orders: [
            { naam: 'REGELSTATUS', richting: 'DESC' },
            { naam: 'RECORD_TOEGEVOEGD', richting: 'DESC' },
          ],
        },
      })
    ).vorderingen;

    const relIDs = _.uniq(vorderingenResult.map((x) => x.RelID));
    const relaties = (
      await api.v2.relatie.ophalenRelaties({
        filterSchema: { filters: [{ naam: 'IDS', data: relIDs }] },
      })
    ).relaties;

    const vorderingen = vorderingenResult.map((vordering) => {
      const relatie = relaties.find((x) => x.RelID === vordering.RelID)!;
      return { ...vordering, relatie };
    });

    setVorderingen(vorderingen);

    // Haal eventuele WOP-info erbij
    const factRegIDs = vorderingen.map((x) => x.FactRegID);
    const wopInfos = (await api.v2.vordering.ophalenWOPInfoVoorVorderingen({ factRegIDs }))
      .wopInfos;
    setWOPInfos(wopInfos);
  }, []);

  useEffect(() => {
    ophalenvorderingen();
  }, []);

  const handleVerwijderen = useCallback(async () => {
    const checkData = await api.v2.vordering.checkVerwijderenVorderingen({
      factRegIDs: urlState.selection,
    });
    if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
      return;
    }
    if (
      (
        await checkStore.bevestigen({
          inhoud: `Wil je de ${urlState.selection.length} vorderingen verwijderen?`,
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    await api.v2.vordering.verwijderenVorderingen({
      factRegIDs: urlState.selection,
    });

    setUrlStateSync('selection', []);

    ophalenvorderingen();
  }, [urlState.selection]);

  const kolommen = useMemo<TypedColumn<IRegel>[]>(
    () => [
      {
        name: '__relatie' as any,
        title: 'Relatie',
        getCellValue: (x: any) => {
          return x.relatie.weergavenaamRelatie;
        },
      },
      {
        name: '__betaaldag' as any,
        title: 'Inc.dag',
        getCellValue: (x: IRegel) => {
          const betaaldag = x.relatie.debiteur!.Betaaldag;
          return betaaldag !== -1 ? betaaldag : 24;
        },
      },
      {
        name: 'Bedrag',
        title: 'Bedrag',
      },
      {
        name: 'Omschrijving',
        title: 'Omschrijving',
      },
      {
        name: '__status' as any,
        title: 'Status',
        getCellValue: (x: any) => x.regelstatus.Status,
      },
      {
        name: '__contractnummer' as any,
        title: 'Cnt.nr.',
        getCellValue: (x) => {
          return x.contract !== null ? x.contract.Basisnummer + '.' + x.contract.Volgnummer : null;
        },
      },
      {
        name: '__periode' as any,
        title: 'Periode',
      },
      // {
      //   name: '__periodeTot' as any,
      //   title: 'Tot',
      // },
      // {
      //   name: '__prolNr' as any,
      //   title: 'Prol.run',
      //   getCellValue: (x) => {
      //     return x.prolongatierun !== null ? x.prolongatierun.ProlNr : null;
      //   },
      // },
      {
        name: '__recordGeregistreerd' as any,
        title: 'Registratie',
        getCellValue: (x) => {
          return x.RecordToegevoegd;
        },
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRegel>[]>(
    () => [
      {
        columnName: '__relatie' as any,
        width: 250,
      },
      {
        columnName: '__betaaldag' as any,
        width: 85,
      },
      {
        columnName: 'Omschrijving',
        width: 500,
      },
      {
        columnName: '__contractnummer' as any,
        width: 85,
      },
      {
        columnName: '__periode' as any,
        width: 200,
      },
      // {
      //   columnName: '__periodeTot' as any,
      //   width: 100,
      // },
      {
        columnName: 'Bedrag',
        width: 100,
      },
      {
        columnName: '__recordGeregistreerd',
        width: 150,
      },
      {
        columnName: '__prolNr' as any,
        width: 90,
      },
      {
        columnName: '__status' as any,
        width: 90,
      },
    ],
    [],
  );

  const kolomExtensies: VirtualTableBase.ColumnExtension[] = useMemo(() => {
    return [
      // { columnName: 'Bedrag', align: 'right' }
    ];
  }, []);

  return (
    <>
      {vorderingen === null || wopInfos === null ? (
        <div className="flex-fill d-flex align-items-center justify-content-center p-5">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          <Helmet>
            <title>Vorderingen</title>
          </Helmet>
          <div
            className="d-flex flex-column p-3"
            style={{
              backgroundColor: Kleur.HeelLichtGrijs,
              borderBottom: `1px solid ${Kleur.LichtGrijs}`,
            }}
          >
            <div className="d-flex">
              <button
                className="btn btn-sm btn-light d-flex align-items-center"
                style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                // disabled={urlState.selection.length === 0}
                onClick={async () => {
                  // const checkData = await api.v2.vordering.checkSelectieMakenFactuurVoorstel({
                  //   factRegIDs: urlState.selection,
                  // });

                  // if (
                  //   (await checkStore.controleren({ checkData })).type === EResultType.Annuleren
                  // ) {
                  //   return;
                  // }

                  setVoorstelDialoogTonen(true);
                }}
              >
                {/* <IcoonToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} /> */}
                <span className="ml-2">Facturen maken</span>
              </button>

              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-2"
                style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                disabled={urlState.selection.length === 0}
                onClick={() => handleVerwijderen()}
              >
                {/* <IcoonToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} /> */}
                <span className="ml-2">Verwijderen vorderingen</span>
              </button>

              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-2"
                style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                onClick={() => {
                  setWijzigVorderingRelID(urlState.selection[0]);
                }}
                disabled={urlState.selection.length !== 1}
              >
                <span className="ml-2">Wijzig vordering</span>
              </button>
            </div>
            <div className="mt-3 d-flex align-items-center">
              <>
                <SelectieVak
                  totaalAantal={vorderingen.length}
                  aantal={urlState.selection.length}
                  entiteitEnkelvoud="vordering"
                  entiteitMeervoud="vorderingen"
                  onChange={(allesGeselecteerd) => {
                    if (allesGeselecteerd) {
                      setUrlStateSync('selection', vorderingen!.map(keyExtractor));
                    } else {
                      setUrlStateSync('selection', []);
                    }
                  }}
                />
                <span className="ml-2">Totaal {vorderingen.length} vorderingen</span>
              </>
            </div>
          </div>
          <GridStyleWrapper height="calc(100vh - 150px)">
            <Grid columns={kolommen} getRowId={keyExtractor} rows={vorderingen!}>
              <DataTypeProvider
                for={['__prolNr']}
                formatterComponent={(props) => {
                  const prolNr =
                    props.row.prolongatierun !== null ? props.row.prolongatierun.ProlNr : '';
                  return <span>{prolNr}</span>;
                }}
              />

              <DataTypeProvider
                for={['__periodeVan']}
                formatterComponent={(props) => {
                  const van =
                    props.row.periodeVan !== null
                      ? format(new Date(props.row.periodeVan), 'dd-MM-yyyy')
                      : '';
                  return <span>{van}</span>;
                }}
              />

              <DataTypeProvider
                for={['__periodeTot']}
                formatterComponent={(props) => {
                  const tot =
                    props.row.periodeTot !== null
                      ? format(new Date(props.row.periodeTot), 'dd-MM-yyyy')
                      : '';
                  return <span>{tot}</span>;
                }}
              />

              <DataTypeProvider
                for={['__periode']}
                formatterComponent={(props) => {
                  const van =
                    props.row.periodeVan !== null
                      ? format(new Date(props.row.periodeVan), 'dd-MM-yyyy')
                      : '';
                  const tot =
                    props.row.periodeTot !== null
                      ? format(new Date(props.row.periodeTot), 'dd-MM-yyyy')
                      : '';
                  return <span>{van !== '' || tot !== '' ? `${van} tot ${tot}` : ``}</span>;
                }}
              />

              <DataTypeProvider
                for={['Bedrag']}
                formatterComponent={(props) => <FormatteerBedrag bedrag={props.value} />}
              />

              <DataTypeProvider
                for={['__status']}
                formatterComponent={(props) => {
                  const rij: IRegel = props.row;

                  const status = rij.regelstatus.Naam;

                  const wopInfo = wopInfos.find((x) => x.factRegID === rij.FactRegID) ?? null;

                  if (rij.regelstatus.Status === ERegelstatusVordering.WOP) {
                    return (
                      <span
                        style={{
                          color:
                            wopInfo === null
                              ? undefined
                              : wopInfo.prolongatie
                              ? Kleur.Groen
                              : Kleur.Rood,
                        }}
                      >
                        {status}
                      </span>
                    );
                  }
                  return <span>{status}</span>;
                }}
              />

              <DataTypeProvider
                for={['__relatie']}
                formatterComponent={(formatterProps) => {
                  const rij = formatterProps.row;
                  return rij.RelID !== null ? (
                    <RelatieVisualisatie
                      relID={rij.RelID}
                      weergaveNaam={rij.relatie.weergavenaamRelatie}
                    />
                  ) : (
                    // <RelatieVisualisatie relID={rij.RelID} />
                    <span></span>
                  );
                }}
              />

              <DataTypeProvider
                for={['__betaaldag']}
                formatterComponent={(formatterProps) => {
                  const rij: IRegel = formatterProps.row;
                  const betaaldag = rij.relatie.debiteur!.Betaaldag;

                  return <span>{betaaldag !== -1 ? betaaldag + 'e' : 'Stufi'}</span>;
                }}
              />

              <DataTypeProvider
                for={['__recordGeregistreerd']}
                formatterComponent={(props) => {
                  const datum =
                    props.row.RecordToegevoegd !== null
                      ? format(new Date(props.row.RecordToegevoegd), 'dd-MM-yyyy HH:mm')
                      : '';
                  return <span>{datum}</span>;
                }}
              />

              <DataTypeProvider
                for={['__contractnummer']}
                formatterComponent={(formatterProps) => {
                  const rij: IRegel = formatterProps.row;

                  const contract = rij.contract;

                  if (contract === null) {
                    return <span></span>;
                  }

                  return <ContractVisualisatie cntID={contract.CntID} />;
                }}
              />

              <RowDetailState defaultExpandedRowIds={[]} />
              {/* <SortingState defaultSorting={[]} /> */}
              <SortingState
                sorting={urlState.sortering}
                onSortingChange={(value) => setUrlStateSync('sortering', value)}
              />
              <IntegratedSorting />
              <VirtualTable columnExtensions={kolomExtensies} />

              <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
              <TableHeaderRow showSortingControls />
              <TableRowDetail
                contentComponent={RowDetailComp}
                toggleCellComponent={DXTableToggleCellComponent}
              />
              <SelectionState
                selection={urlState.selection}
                onSelectionChange={(value) => setUrlStateSync('selection', value as number[])}
              />
              <TableSelection cellComponent={DXTableCheckboxComponent} />
            </Grid>
          </GridStyleWrapper>
          {voorstelDialoogTonen && (
            <FactuurVoorstelDialoog
              open
              factRegIDs={urlState.selection}
              onAnnuleren={() => setVoorstelDialoogTonen(false)}
              onSuccess={() => {
                setVoorstelDialoogTonen(false);
                setUrlStateSync('selection', []);
                ophalenvorderingen();
              }}
            />
          )}

          {wijzigVorderingRelID > 0 && (
            <WijzigenVorderingDialoog
              factRegID={wijzigVorderingRelID}
              open={wijzigVorderingRelID > 0}
              onAnnuleren={() => setWijzigVorderingRelID(0)}
              onSuccess={() => {
                setWijzigVorderingRelID(0);
                ophalenvorderingen();
              }}
            />
          )}
        </>
      )}
    </>
  );
});

export default withRouter(Vorderingen);
