import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import IDialoogProps from '../../../../core/IDialoogProps';
import Dialoog from '../../../../components/dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import * as Yup from 'yup';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../../stores/RootStore';
import Combobox from '../../../../components/formulier/Combobox';
import nameOf from '../../../../core/nameOf';
import { IOphalenOpdrachtwijzenResultElement } from '../../../../../../shared/src/api/v2/dienst';
import {
  IOphalenOpdrachtenResultElement,
  IVersturenOpdrachtParams,
} from '../../../../../../shared/src/api/v2/pendel';
import { IOphalenDienstenResultElement } from '../../../../../../shared/src/api/v2/dienst/pendel';
import api from '../../../../api';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import { EResultType } from '../../../../stores/CheckStore';
import _ from 'lodash';
import VinkVeld from '../../../../components/formulier/VinkVeld';
import { setCommunicatieOverlayState } from '../../../../one-off-components/CommunicatieOverlay';
import { ECommunicatieTabblad } from '../../../../one-off-components/CommunicatieOverlay/MenuHandle';
import { BestandType } from '../../../../components/BijlagenContainer';
import { defaultOpstellenFormulierFormikValues } from '../../../../components/communicatie/EmailWerkblad/OpstellenFormulier';

interface IDialoogResult {}

interface IProps extends IDialoogProps<IDialoogResult> {
  penOpdID: number;
}

interface IFormikValues {
  opdrachtwijze: number | null;
  emailOpdracht: string | null;
  emailOpdrachtCC: string | null;
  emailNaarConcept: boolean;
  bijlagesoort: number;
  metHerkomst: boolean;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  opdrachtwijze: 'Opdrachtwijze',
  emailOpdracht: 'Email',
  emailOpdrachtCC: 'Email CC',
  emailNaarConcept: 'Email niet direct versturen',
  bijlagesoort: 'Bijlage',
  metHerkomst: 'Herkomst bij producten weergeven',
};

const VersturenDialoog: React.FC<IProps> = observer((props) => {
  const { children, dialoogIndex, onAnnuleren, onSuccess, open, penOpdID } = props;
  const { checkStore } = useContext(RootStoreContext);
  const [opdrachtwijzen, setOpdrachtwijzen] = useState<
    IOphalenOpdrachtwijzenResultElement[] | null
  >(null);

  // const [sjablonen, setSjablonen] = useState<IOphalenSjablonenResult | null>(null);

  const [dienst, setDienst] = useState<IOphalenDienstenResultElement | null>(null);

  const bijlageSjabloonOpties = [
    { id: 1, label: 'Zonder notities' },
    { id: 2, label: 'Met notities' },
  ];

  // const bijlageSjabloonOpties = useMemo<IOptie<number>[] | null>(() => {
  //   if (sjablonen === null) {
  //     return null;
  //   }

  //   const opties = sjablonen.sjablonen.map((x) => {
  //     return { id: x.ID, label: x.Naam };
  //   });

  //   return opties;
  // }, [sjablonen]);

  // useEffect(() => {
  //   (async () => {
  //     const result = await api.v2.sjabloon.ophalenSjablonen({
  //       filterSchema: {
  //         filters: [
  //           {
  //             naam: 'NAAM_ENUMS',
  //             data: [
  //               ESjablooncode.PendelopdrachtbestandZonderNotities,
  //               ESjablooncode.PendelopdrachtbestandMetNotities,
  //             ],
  //           },
  //         ],
  //       },
  //     });

  //     setSjablonen(result);
  //   })();
  // }, []);

  useEffect(() => {
    (async () => {
      const result = await api.v2.pendel.ophalenOpdrachten({
        filterSchema: { filters: [{ naam: 'IDS', data: [penOpdID] }] },
      });
      const opdrachten: IOphalenOpdrachtenResultElement[] = result.opdrachten;
      const penDienstIDs = _.uniq(opdrachten.map((x) => x.dienst!.ID));
      const penDienstID = penDienstIDs[0];

      const dienstenResult = await api.v2.dienst.pendel.ophalenDiensten({
        filterSchema: { filters: [{ naam: 'IDS', data: [penDienstID] }] },
      });

      setDienst(dienstenResult.diensten[0]);
    })();
  }, [penOpdID]);

  useEffect(() => {
    if (dienst === null) {
      return;
    }

    (async () => {
      const result = await api.v2.dienst.ophalenOpdrachtwijzenVoorDienst({
        filterSchema: {
          filters: [{ naam: 'PENDIENST_IDS', data: [dienst.ID] }],
        },
      });

      setOpdrachtwijzen(result.opdrachtwijzen);
    })();
  }, [dienst]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      if (opdrachtwijzen === null) {
        return;
      }

      actions.setSubmitting(true);

      if (
        (
          await checkStore.bevestigen({
            titel: `Pendelopdracht opstellen?`,
            inhoud: `De pendelopdracht wordt gemarkeerd als verstuurd, maar er wordt een email opstellen formulier geopend die je kan gebruiken om nog wijzigingen aan te brengen.`,
          })
        ).type === EResultType.Annuleren
      ) {
        actions.setSubmitting(false);
        return;
      }

      const opdrachtwijze = opdrachtwijzen.find((x) => x.OpdWijzeID === values.opdrachtwijze)!;

      let emailOpdracht = undefined;
      let emailOpdrachtCC = undefined;
      if (opdrachtwijze.NaamEnum.indexOf('EMAIL') !== -1) {
        emailOpdracht = values.emailOpdracht!;
        emailOpdrachtCC = values.emailOpdrachtCC !== '' ? values.emailOpdrachtCC! : null;
      }

      const params: IVersturenOpdrachtParams = {
        penOpdID: props.penOpdID,
        opdWijzeID: values.opdrachtwijze!,
        emailOpdracht,
        emailOpdrachtCC,
        emailNaarConcept: values.emailNaarConcept,
        metNotities: values.bijlagesoort === 1 ? false : true,
        metHerkomst: values.metHerkomst,
      };

      const result = await api.v2.pendel.versturenOpdracht(params);

      if (result.email !== null) {
        setCommunicatieOverlayState!((curr) => ({
          ...curr,
          geselecteerdTabblad: ECommunicatieTabblad.Email,
          emailContext: {
            ...curr.emailContext,
            formulier: {
              ...defaultOpstellenFormulierFormikValues,
              recipients: result.email!.aan,
              CC: result.email!.cc,
              BCC: result.email!.bcc,
              subject: result.email!.onderwerp,
              body: result.email!.inhoud,
              bijlagen: result.email!.bijlagen.map((x) => ({
                type: BestandType.ASPDrive,
                bestand: x,
              })),
            },
          },
        }));
      }

      props.onSuccess({});
      actions.setSubmitting(false);
    },
    [opdrachtwijzen],
  );

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (dienst === null || opdrachtwijzen === null || bijlageSjabloonOpties === null) {
      return null;
    }

    return {
      opdrachtwijze: dienst.OpdWijzeID !== null ? dienst.OpdWijzeID! : opdrachtwijzen[0].OpdWijzeID,
      emailOpdracht: dienst.EmailOpdracht,
      emailOpdrachtCC: dienst.EmailOpdrachtCC,
      emailNaarConcept: false,
      bijlagesoort: 1,
      metHerkomst: true,
    };
  }, [dienst, opdrachtwijzen]);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        opdrachtwijze: Yup.number().required(),
      }),
    [],
  );

  return (
    <Dialoog index={dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Versturen opdracht</ModalTitle>
      </ModalHeader>
      {initialValues === null || bijlageSjabloonOpties === null ? (
        <div className="flex-fill d-flex align-items-center justify-content-center m-5">
          <LoadingSpinner />
        </div>
      ) : (
        <Formik<IFormikValues>
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
          // enableReinitialize
          isInitialValid
          render={(formikProps: FormikProps<IFormikValues>) => {
            const { submitForm, isValid, isSubmitting } = formikProps;

            return (
              <>
                <ModalBody>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-12 d-flex flex-column">
                        <label>Transporteur</label>
                        <div
                          className="font-weight-bold flex-fill"
                          style={{
                            border: `1px solid ${Kleur.LichtGrijs}`,
                            padding: '4px 8px',
                            borderRadius: 3,
                          }}
                        >
                          {dienst === null ? <LoadingSpinner /> : dienst.relatie!.weergavenaam}
                        </div>
                      </div>

                      <div className="col-12 mt-3">
                        <label>{veldnamen.opdrachtwijze}</label>
                        <Field
                          name={nameOf<IFormikValues>('opdrachtwijze')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            if (opdrachtwijzen === null) {
                              return <LoadingSpinner />;
                            }

                            return (
                              <Combobox
                                geselecteerd={field.value}
                                opties={opdrachtwijzen!.map((opdrachtwijze) => {
                                  return {
                                    id: opdrachtwijze.OpdWijzeID,
                                    label: opdrachtwijze.Naam,
                                  };
                                })}
                                onSelectieChange={(x) => form.setFieldValue(field.name, x)}
                              />
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <label>{veldnamen.emailOpdracht}</label>
                        <Field
                          name={nameOf<IFormikValues>('emailOpdracht')}
                          render={(fieldProps: FieldProps) => {
                            const { field, form } = fieldProps;
                            return (
                              <div>
                                <input className="form-control" {...fieldProps.field} />
                                {/* <FormikVeldFout fieldProps={fieldProps} /> */}
                              </div>
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <label>{veldnamen.emailOpdrachtCC}</label>
                        <Field
                          name={nameOf<IFormikValues>('emailOpdrachtCC')}
                          render={(fieldProps: FieldProps) => {
                            const { field, form } = fieldProps;
                            return (
                              <div>
                                <input className="form-control" {...fieldProps.field} />
                                {/* <FormikVeldFout fieldProps={fieldProps} /> */}
                              </div>
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <label>{veldnamen.bijlagesoort}</label>
                        <Field
                          name={nameOf<IFormikValues>('bijlagesoort')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            if (bijlageSjabloonOpties === null) {
                              return <LoadingSpinner />;
                            }

                            return (
                              <Combobox
                                geselecteerd={field.value}
                                opties={bijlageSjabloonOpties.map((x) => {
                                  return {
                                    id: x.id,
                                    label: x.label,
                                  };
                                })}
                                onSelectieChange={(x) => form.setFieldValue(field.name, x)}
                              />
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <Field
                          name={nameOf<IFormikValues>('metHerkomst')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <div className="d-flex align-items-center">
                                <VinkVeld
                                  aangevinkt={field.value}
                                  onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                />
                                <span className="ml-2">{veldnamen.metHerkomst}</span>
                              </div>
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </ModalBody>
                <ModalFooter className="d-flex flex-row justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    style={{ width: 100 }}
                    disabled={!isValid || isSubmitting}
                  >
                    Opstellen
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={onAnnuleren}
                    style={{ width: 100 }}
                    disabled={isSubmitting}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Dialoog>
  );
});

export default VersturenDialoog;
