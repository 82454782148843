import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import styled from 'styled-components';
import { Kleur } from '../../../../../bedrijfslogica/constanten';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
} from '../../../../../models/IRemoteData';
import { AutoSizer } from 'react-virtualized';

const Root = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

const GeenInhoudOpgegeven = styled.div`
  font-size: 14px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Container = styled.iframe`
  background-color: ${Kleur.Wit};
  border: 1px solid ${Kleur.LichtGrijs};
  border-radius: 5px;
  margin: 0 20px 20px 20px;
  padding: 20px;
  width: calc(100% - 40px);
`;

interface IProps {
  inhoud: string | null;
  isHTML: boolean;
}

const Inhoud = (props: IProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerHeight, setContainerHeight] = useState<IRemoteData<number>>(
    createPendingRemoteData(),
  );
  useEffect(() => {
    setContainerHeight(createPendingRemoteData());
  }, [props.inhoud]);

  const handleLoad = useCallback(
    (ev: React.SyntheticEvent) => {
      const frame = ev.target as HTMLIFrameElement;
      const height = frame.contentWindow!.document.documentElement.scrollHeight + 40;
      setContainerHeight(createReadyRemoteData(height));
    },
    [setContainerHeight],
  );

  const inhoud = useMemo(() => {
    if (props.inhoud === null) {
      return null;
    }

    const inhoudMetBreaks = props.isHTML ? props.inhoud : props.inhoud.replace(/\n/g, '<br/>');
    const injected = `
      <style>
          body {
              font-family: "Muli", sans-serif;
              font-size: 14px;
              -moz-osx-font-smoothing: grayscale;
              -webkit-font-smoothing: antialiased;
              color: ${Kleur.DonkerGrijs};
              padding: 0;
              margin: 0;
          }
      </style>

      <base target="_blank" />
    `;

    return injected + inhoudMetBreaks;
  }, [props.inhoud, props.isHTML]);

  if (inhoud === null) {
    return (
      <Root>
        <GeenInhoudOpgegeven>Bij deze email is geen inhoud opgegeven</GeenInhoudOpgegeven>
      </Root>
    );
  }

  return (
    <Root ref={containerRef}>
      <AutoSizer className="flex-fill">
        {({ height, width }) => (
          <div style={{ maxHeight: height, width, overflowY: 'auto' }}>
            <Container
              srcDoc={inhoud!}
              onLoad={handleLoad}
              style={{
                height: containerHeight.data ?? undefined,
              }}
              scrolling="no"
            />
          </div>
        )}
      </AutoSizer>
    </Root>
  );
};

export default Inhoud;
