import { RootStore } from './RootStore';
import { makeObservable, observable } from 'mobx';
import { v4 as uuidv4 } from 'uuid';

enum EIntrasessieMessageType {
  Toetreden,
}

interface IToetredenMessage {
  type: EIntrasessieMessageType.Toetreden;
}

interface IMessageBase {
  id: string;
}

type IntrasessieMessage = IMessageBase & IToetredenMessage;

class IntrasessieStore {
  public rootStore: RootStore;
  public isHoofdSessie: boolean = false;
  private id: string = uuidv4();
  private channel: BroadcastChannel | null = null;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    // create random key variable
    const randomKey = Math.random() * 10000;
    // set key if it does not exist
    if (localStorage.getItem('tabKey') === null) {
      this.isHoofdSessie = true;
      localStorage.setItem('tabKey', randomKey.toString());
    }

    // when main tab closes, remove item from localStorage
    window.addEventListener('unload', () => {
      const tabKeyItem = localStorage.getItem('tabKey');
      if (tabKeyItem !== null && Number(tabKeyItem) === randomKey) {
        localStorage.removeItem('tabKey');
      }
    });

    // when non-main tabs receive the "close" event,
    // the first one will set the `tabKey` to its `randomKey`
    window.addEventListener('storage', (evt) => {
      if (evt.key === 'tabKey' && evt.newValue === null) {
        if (localStorage.getItem('tabKey') === null) {
          localStorage.setItem('tabKey', randomKey.toString());
          this.isHoofdSessie = true;
        }
      }
    });

    makeObservable(this, {
      isHoofdSessie: observable,
    });
  }

  public async initialiseren() {
    this.channel = new BroadcastChannel('intrasessie');
    this.channel.onmessage = this.onMessage;

    console.log('Intrasessie geinitialiseerd');
    this.sendMessage({
      id: this.id,
      type: EIntrasessieMessageType.Toetreden,
    });
  }

  private sendMessage(message: IntrasessieMessage) {
    this.channel?.postMessage(message);
  }

  private async onMessage(event: MessageEvent) {
    console.log('intrasessie msg: ', event);
    const message = event.data as IntrasessieMessage;
    switch (message.type) {
      case EIntrasessieMessageType.Toetreden:
        break;
    }
  }
}

export default IntrasessieStore;
