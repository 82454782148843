import React, { useCallback, useContext, useMemo, useState } from 'react';
import IDialoogProps from '../../../core/IDialoogProps';
import Dialoog from '../../dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import { IValues } from '../../formulier/AdresFormulier';
import { Formik, FormikActions, FormikProps } from 'formik';
import Tabblad, { ITabblad } from '../../layout/Tabblad';
import SelecterenTabblad from './SelecterenTabblad';
import RecentTabblad from './RecentTabblad';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import * as Yup from 'yup';
import teksten from '../../../bedrijfslogica/teksten';
import api from '../../../api';
import HorizontaleScheidingslijn from '../../layout/HorizontaleScheidingslijn';
import { RootStoreContext } from '../../../stores/RootStore';
import { EResultType } from '../../../stores/CheckStore';

export enum ETabblad {
  Selecteren = 1,
  Recent,
}

export interface IDialoogResult {
  locID: number;
}

export interface ILocatieSelecterenOfOpvoerenDialoogFormikValues {
  adres: IValues | null;
  bijzonderheden: string;
  bezoekinstructies: string;
  liftAanwezig: boolean;
}

export const veldnamen: Record<keyof ILocatieSelecterenOfOpvoerenDialoogFormikValues, string> = {
  adres: 'Adres',
  bijzonderheden: 'Notities locatie',
  liftAanwezig: 'Lift aanwezig',
  bezoekinstructies: 'Bezoekinstructies',
};

export interface ILocatieSelecterenOfOpvoerenDialoogContext {
  renderProps: FormikProps<ILocatieSelecterenOfOpvoerenDialoogFormikValues>;
  onSuccess: (result: IDialoogResult) => void;
}
export const LocatieSelecterenOfOpvoerenDialoogContext = React.createContext<
  ILocatieSelecterenOfOpvoerenDialoogContext
>({
  renderProps: null as any,
  onSuccess: null as any,
});

interface IProps extends IDialoogProps<IDialoogResult> {
  initieelTabblad?: ETabblad;
  titel?: string | JSX.Element;
  uitbreiding?: JSX.Element;
  initialValues?: ILocatieSelecterenOfOpvoerenDialoogFormikValues;
}

const LocatieSelecterenOfOpvoerenDialoog: React.FC<IProps> = (props) => {
  const { checkStore } = useContext(RootStoreContext);

  const [tabblad, setTabblad] = useState(props.initieelTabblad || ETabblad.Selecteren);
  const tabbladen = useMemo<ITabblad<ETabblad>[]>(
    () => [
      {
        id: ETabblad.Selecteren,
        label: 'Selecteren',
        content: SelecterenTabblad,
      },
      {
        id: ETabblad.Recent,
        label: 'Recent',
        content: RecentTabblad,
      },
    ],
    [],
  );

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      adres: Yup.object().required('Adres gegevens zijn incorrect'),
      bezoekinstructies: Yup.string().max(
        250,
        teksten.formulier.E_TEKST_MAXIMAAL({
          veldnaam: veldnamen.bezoekinstructies,
          aantal: 250,
        }),
      ),
    });
  }, []);

  const initialValues = useMemo<ILocatieSelecterenOfOpvoerenDialoogFormikValues>(
    () =>
      props.initialValues ?? {
        adres: null,
        bijzonderheden: '',
        bezoekinstructies: '',
        liftAanwezig: false,
      },
    [props.initialValues],
  );

  const handleSubmit = useCallback(
    async (
      values: ILocatieSelecterenOfOpvoerenDialoogFormikValues,
      actions: FormikActions<ILocatieSelecterenOfOpvoerenDialoogFormikValues>,
    ) => {
      actions.setSubmitting(true);

      if (
        (
          await checkStore.bevestigen({
            inhoud: `Locatie wijzigen?`,
          })
        ).type === EResultType.Annuleren
      ) {
        actions.setSubmitting(false);
        return;
      }

      const adres = values.adres!;
      const bezoekinstructies = (() => {
        const val = values.bezoekinstructies.trim();
        return val === '' ? null : val;
      })();
      const notities = (() => {
        const val = values.bijzonderheden.trim();
        return val === '' ? null : val;
      })();

      const muterenResult = await api.v2.locatie.muterenLocatie({
        straatnaam: adres.straatnaam,
        bezoekinstructies,
        bisnummer: adres.bisnummer === '' ? null : adres.bisnummer,
        huisnummer: adres.huisnummer,
        landID: adres.landID,
        liftAanwezig: values.liftAanwezig,
        notities,
        plaatsnaam: adres.plaatsnaam,
        postcode: adres.postcode,
      });

      props.onSuccess({
        locID: muterenResult.LocID,
      });

      actions.setSubmitting(false);
    },
    [props.onSuccess],
  );

  return (
    <Dialoog index={props.dialoogIndex || 0} modalProps={{ size: undefined }}>
      <ModalHeader>
        <ModalTitle>
          {props.titel !== undefined ? props.titel : 'Locatie selecteren of opvoeren'}
        </ModalTitle>
      </ModalHeader>
      <Formik<ILocatieSelecterenOfOpvoerenDialoogFormikValues>
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        render={(renderProps) => {
          const { submitForm, isSubmitting, isValid } = renderProps;

          return (
            <LocatieSelecterenOfOpvoerenDialoogContext.Provider
              value={{ renderProps, onSuccess: props.onSuccess }}
            >
              <ModalBody style={{ padding: 0 }}>
                <div className="d-flex flex-column">
                  <Tabblad
                    geselecteerd={tabblad}
                    onSelectieChange={setTabblad}
                    tabbladen={tabbladen}
                  />
                  {props.uitbreiding !== undefined && (
                    <>
                      <HorizontaleScheidingslijn />
                      <div className="d-flex flex-column">{props.uitbreiding}</div>
                    </>
                  )}
                </div>
              </ModalBody>
              <ModalFooter className="d-flex flex-row justify-content-start">
                <button
                  className="btn btn-primary d-flex align-items-center justify-content-center"
                  onClick={submitForm}
                  style={{ width: 100 }}
                  disabled={isSubmitting || !isValid}
                >
                  Ok
                </button>
                <button
                  className="btn btn-secondary"
                  onClick={props.onAnnuleren}
                  style={{ width: 100 }}
                  disabled={isSubmitting}
                >
                  Annuleren
                </button>
              </ModalFooter>
            </LocatieSelecterenOfOpvoerenDialoogContext.Provider>
          );
        }}
      />
    </Dialoog>
  );
};

export default LocatieSelecterenOfOpvoerenDialoog;
