import CheckStore, { EResultType } from '../../stores/CheckStore';
import { vanVerkennerItemId } from './helpers';
import { EVerkennerItemType } from './TabelWeergave/types';
import api from '../../api';
import {
  IVerwijderenBestandenParams,
  IVerwijderenBestandsmappenParams,
} from '../../../../shared/src/api/v2/bestand/bestand';
import ICheckData from '../../../../shared/src/models/ICheckData';
import { IVerkennerItem } from './types';

interface IData {
  totaalAantal: number;
  items: Record<number, IVerkennerItem>;
}

interface IVerkennerItemsVerwijderenParams {
  ids: string[];
  checkStore: CheckStore;
  data: IData;
  onDataChange: (data: IData) => void;
}

const verkennerItemsVerwijderen = async (params: IVerkennerItemsVerwijderenParams) => {
  const parsedIds = params.ids.map(vanVerkennerItemId);

  const bestandIds = parsedIds
    .filter((x) => x.type === EVerkennerItemType.Bestand)
    .map((x) => x.id);
  const bestandsmapIds = parsedIds
    .filter((x) => x.type === EVerkennerItemType.Map)
    .map((x) => x.id);

  const newItems = Object.keys(params.data.items)
    .map(Number)
    .reduce(
      (acc, idx) => {
        const item = params.data.items[idx];
        if (params.ids.indexOf(item.id) !== -1) {
          return {
            ...acc,
            offset: acc.offset + 1,
          };
        }

        return {
          ...acc,
          data: {
            ...acc.data,
            [idx - acc.offset]: item,
          },
        };
      },
      {
        data: {},
        offset: 0,
      },
    ).data;

  // Optimistisch verwijderen van items
  params.onDataChange({
    items: newItems,
    totaalAantal: params.data.totaalAantal - params.ids.length,
  });

  const verwijderenBestandenParams: IVerwijderenBestandenParams | null =
    bestandIds.length > 0
      ? {
          bestandIDs: bestandIds,
        }
      : null;
  const verwijderenBestandsmappenParams: IVerwijderenBestandsmappenParams | null =
    bestandsmapIds.length > 0
      ? {
          bestandsmapIDs: bestandsmapIds,
        }
      : null;

  // Controleren of verwijderen mogelijk is en/of er waarschuwingen zijn
  const [bestandenCheckData, bestandsmappenCheckData] = await Promise.all([
    verwijderenBestandenParams !== null
      ? api.v2.bestand.checkVerwijderenBestanden(verwijderenBestandenParams)
      : ({
          errors: [],
          warnings: [],
        } as ICheckData),
    verwijderenBestandsmappenParams !== null
      ? api.v2.bestand.checkVerwijderenBestandsmappen(verwijderenBestandsmappenParams)
      : ({
          errors: [],
          warnings: [],
        } as ICheckData),
  ]);

  // Maak een gezamenlijke check, zodat er maar een dialoog hoeft te worden weergegeven
  const mergedCheckData: ICheckData = {
    errors: [...bestandenCheckData.errors, ...bestandsmappenCheckData.errors],
    warnings: [...bestandenCheckData.warnings, ...bestandsmappenCheckData.warnings],
  };

  // Mocht er een fout zijn, of de gebruiker heeft geannuleerd, dan worden de items terug gezet
  if (
    (await params.checkStore.controleren({ checkData: mergedCheckData })).type ===
    EResultType.Annuleren
  ) {
    params.onDataChange(params.data);
    return;
  }

  // noinspection ES6MissingAwait
  const verwijderenBestandenPromise =
    verwijderenBestandenParams !== null
      ? api.v2.bestand.verwijderenBestanden(verwijderenBestandenParams)
      : null;
  // noinspection ES6MissingAwait
  const verwijderenBestandsmappenPromise =
    verwijderenBestandsmappenParams !== null
      ? api.v2.bestand.verwijderenBestandsmappen(verwijderenBestandsmappenParams)
      : null;

  await Promise.all(
    [verwijderenBestandenPromise, verwijderenBestandsmappenPromise].filter((x) => x !== null),
  );
};

export default verkennerItemsVerwijderen;
