import * as React from 'react';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import Dialoog from '../dialogen/Dialoog';
import IDialoogProps from '../../core/IDialoogProps';
import ModalFooter from 'react-bootstrap/ModalFooter';
import LinkerDeel from './LinkerDeel';
import RechterDeel from './RechterDeel';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
  mapRemoteData,
} from '../../models/IRemoteData';
import {
  IResolveResult,
  ISjabloon,
  ISjabloonCategorie,
  ISjabloonContext,
  ISjabloonInhoud,
  ISjabloonKanaal,
} from '../../../../shared/src/api/v2/Sjabloon/sjabloon';
import api from '../../api';
import { ICommunicatiekanaal } from '../../../../shared/src/api/v2/bericht/Bericht';
import { IOphalenTalenResultElement } from '../../../../shared/src/api/v2/taal';
import { IFilterData, maakFilterSchema } from '../FilterBalkV2';
import useBijGewijzigdEffect from '../../core/useBijGewijzigdEffect';
import { IFilterSchema, IFilterSchemaFilter } from '../../../../shared/src/models/filter';
import { ITekstPrecisieData } from '../../../../shared/src/api/sql';
import {
  CommunicatiekanaalSelectie,
  ECommunicatiekanaalSelectieType,
  EFilter,
  EOpgegevenContextSelectieType,
  ESjabloonOplosserModus,
  ESMSVerstuurModusType,
  ETaalSelectieType,
  ETaalViaOpgegevenContextenBepalerOutputType,
  EVerstuurModusKanaal,
  IOpgegevenContext,
  ISjabloonOplosserVerstuurModus,
  ITaalViaOpgegevenContextenBepaler,
  SjabloonOplosserModus,
  TaalSelectie,
  TaalViaOpgegevenContextenBepalerOutput,
} from './types';
import * as _ from 'lodash';
import { GlobaleRendererContext } from '../../one-off-components/GlobaleRenderer';
import PreviewDialoog, {
  EPreviewDialoogOutputType,
  EPreviewModus,
  IPreviewDialoogOutput,
} from './PreviewDialoog';
import toast from 'react-hot-toast';
import { IOphalenPersonenResultElementV2 } from '../../../../shared/src/api/v2/persoon/persoon';
import { RootStoreContext } from '../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import { IOphalenRelatiesResultElementV2 } from '../../../../shared/src/api/v2/relatie';
import { setCommunicatieOverlayState } from '../../one-off-components/CommunicatieOverlay';
import { BestandType } from '../BijlagenContainer';
import { IOphalenBestandenResult } from '../../../../shared/src/api/v2/bestand/bestand';
import { ECommunicatieTabblad } from '../../one-off-components/CommunicatieOverlay/MenuHandle';
import { EUitstelling } from '../communicatie/EmailWerkblad/OpstellenFormulier/VersturenKnop';
import LinkerdeelIngeklapt from './LinkerdeelIngeklapt';

export interface ISjabloonOplosserOutput {
  sjabloon: ISjabloon;
  taal: IOphalenTalenResultElement;
  communicatiekanaal: ICommunicatiekanaal;
  opgegevenContexten: IOpgegevenContext[];
  contexten: { [key: string]: any };
  resolveResult: IResolveResult | null;
  sjabloonkanaal: ISjabloonKanaal;
}

interface IProps extends IDialoogProps<ISjabloonOplosserOutput> {
  modus?: SjabloonOplosserModus;
  taalSelectie: TaalSelectie;
  communicatiekanaalSelectie: CommunicatiekanaalSelectie;
  magOpgegevenContextenMuteren?: boolean;
  defaultOpgegevenContexten?: IOpgegevenContext[];
  defaultGenereerbaarAangestuurdeSelectie?: boolean;
  defaultFilterSjabloonCategorieIDs?: number[];
  defaultFilterSjabloonCategorieIDsActief?: boolean;
  defaultFilterSjabloonkanaalIDs?: number[];
  defaultFilterSjabloonkanaalIDsActief?: boolean;
  defaultFilterTaalIDs?: number[];
  defaultFilterTaalIDsActief?: boolean;
  defaultFilterVoldoetAanSjabCtxIDs?: number[];
  defaultFilterVoldoetAanSjabCtxIDsActief?: boolean;
  defaultIngeklapt?: boolean;
  sjabloonBasisNietToepassen?: boolean;
  taalViaOpgegevenContextenBepaler?: ITaalViaOpgegevenContextenBepaler;
  magGenereerbaarAangestuurdeSelectieMuteren?: boolean;
  magFilterSjabloonCategorieIDsMuteren?: boolean;
  magFilterSjabloonkanaalIDsMuteren?: boolean;
  magFilterTaalIDsMuteren?: boolean;
  magFilterVoldoetAanSjabCtxIDsMuteren?: boolean;
}

const SjabloonOplosser = observer((props: IProps) => {
  const { instellingStore, whatsappStore } = useContext(RootStoreContext);
  const globaleRenderer = useContext(GlobaleRendererContext);

  const modus = useMemo<SjabloonOplosserModus>(
    () =>
      props.modus ?? {
        type: ESjabloonOplosserModus.Genereer,
      },
    [props.modus],
  );

  const [isBezig, setIsBezig] = useState(false);
  const [zoektermInput, setZoektermInput] = useState('');
  const [actieveZoekterm, setActieveZoekterm] = useState<string | null>(null);
  const [genereerbaarAangestuurdeSelectie, setGenereerbaarAangestuurdeSelectie] = useState<boolean>(
    props.defaultGenereerbaarAangestuurdeSelectie ?? true,
  );
  const [opgegevenContexten, setOpgegevenContexten] = useState<IOpgegevenContext[]>(
    props.defaultOpgegevenContexten ?? [],
  );
  const [sjablonen, setSjablonen] = useState<IRemoteData<ISjabloon[]>>(createPendingRemoteData());
  const [sjabloonKanalen, setSjabloonKanalen] = useState<IRemoteData<ISjabloonKanaal[]>>(
    createPendingRemoteData,
  );
  const [sjabloonCategorieen, setSjabloonCategorieen] = useState<IRemoteData<ISjabloonCategorie[]>>(
    createPendingRemoteData(),
  );
  const [sjabloonInhouden, setSjabloonInhouden] = useState<IRemoteData<ISjabloonInhoud[]>>(
    createPendingRemoteData(),
  );
  const [communicatiekanalen, setCommunicatiekanalen] = useState<
    IRemoteData<ICommunicatiekanaal[]>
  >(createPendingRemoteData());
  const [talen, setTalen] = useState<IRemoteData<IOphalenTalenResultElement[]>>(
    createPendingRemoteData(),
  );
  const [contexten, setContexten] = useState<IRemoteData<ISjabloonContext[]>>(
    createPendingRemoteData(),
  );
  const [isIngeklapt, setIsIngeklapt] = useState(props.defaultIngeklapt ?? true);

  const actieveZoektermDebounceTimeoutRef = useRef<number | null>(null);
  useBijGewijzigdEffect(() => {
    if (actieveZoektermDebounceTimeoutRef.current) {
      clearTimeout(actieveZoektermDebounceTimeoutRef.current);
    }
    const nieuweZoekterm = zoektermInput.trim() ?? null;
    actieveZoektermDebounceTimeoutRef.current = window.setTimeout(() => {
      setActieveZoekterm(nieuweZoekterm);
    }, 500);
  }, [zoektermInput]);

  const taalViaOpgegevenContextenBepaler = useMemo<IRemoteData<ITaalViaOpgegevenContextenBepaler>>(
    () =>
      props.taalViaOpgegevenContextenBepaler !== undefined
        ? createReadyRemoteData(props.taalViaOpgegevenContextenBepaler)
        : contexten.state === ERemoteDataState.Pending || talen.state === ERemoteDataState.Pending
        ? createPendingRemoteData()
        : createReadyRemoteData({
            bepaal: async (bepaalParams): Promise<TaalViaOpgegevenContextenBepalerOutput> => {
              const kandidaatContextNaamEnums = ['PERSOON', 'RELATIE'];
              const kandidaten = opgegevenContexten
                .map((oc) => {
                  const ctx = contexten.data!.find((ctx) => {
                    switch (oc.selectie.type) {
                      case EOpgegevenContextSelectieType.NaamEnum:
                        return ctx.NaamEnum === oc.selectie.naamEnum;
                      case EOpgegevenContextSelectieType.SjabCtxID:
                        return ctx.SjabCtxID === oc.selectie.sjabCtxID;
                    }
                  })!;

                  return {
                    opgegevenContext: oc,
                    context: ctx,
                  };
                })
                .filter((oc) => {
                  return kandidaatContextNaamEnums.includes(oc.context.NaamEnum);
                });

              if (kandidaten.length === 0) {
                return {
                  type: ETaalViaOpgegevenContextenBepalerOutputType.NietBepaald,
                };
              }

              const relatiesBepalenViaRelIDs = async (
                relIDs: number[],
              ): Promise<IOphalenRelatiesResultElementV2[]> => {
                if (relIDs.length === 0) {
                  return [];
                }
                const relatiesResult = await api.v2.relatie.ophalenRelaties({
                  filterSchema: {
                    filters: [
                      {
                        naam: 'IDS',
                        data: relIDs,
                      },
                    ],
                  },
                });
                const relatiesBijID: Record<
                  number,
                  IOphalenRelatiesResultElementV2
                > = relatiesResult.relaties.reduce(
                  (acc, curr) => ({
                    ...acc,
                    [curr.RelID]: curr,
                  }),
                  {},
                );
                return relIDs.map((relID) => relatiesBijID[relID]);
              };

              const personenBepalenViaPersIDs = async (
                persIDs: number[],
              ): Promise<IOphalenPersonenResultElementV2[]> => {
                if (persIDs.length === 0) {
                  return [];
                }
                const personenResult = await api.v2.persoon.ophalenPersonen({
                  filterSchema: {
                    filters: [
                      {
                        naam: 'IDS',
                        data: persIDs,
                      },
                    ],
                  },
                });
                const personenBijID: Record<
                  number,
                  IOphalenPersonenResultElementV2
                > = personenResult.personen.reduce(
                  (acc, curr) => ({
                    ...acc,
                    [curr.PersID]: curr,
                  }),
                  {},
                );
                return persIDs.map((persID) => personenBijID[persID]!);
              };

              const relIDs = kandidaten
                .filter((x) => x.context.NaamEnum === 'RELATIE')
                .map((x) => (x.opgegevenContext.data as { relID: number }).relID);
              const relaties = relIDs.length === 0 ? null : await relatiesBepalenViaRelIDs(relIDs);
              const persIDs = [
                ...kandidaten
                  .filter((x) => x.context.NaamEnum === 'PERSOON')
                  .map((x) => (x.opgegevenContext.data as { persID: number }).persID),
                ...(relaties ?? []).filter((x) => x.persoon !== null).map((x) => x.persoon!.PersID),
              ];
              const personen =
                persIDs.length === 0 ? null : await personenBepalenViaPersIDs(persIDs);

              const taalIDs = _.uniq([...(personen ?? []).map((x) => x.taal.TaalID)]);
              if (taalIDs.length === 1) {
                return {
                  type: ETaalViaOpgegevenContextenBepalerOutputType.Bepaald,
                  taalID: taalIDs[0],
                  eenduidigBepaald: true,
                };
              }

              const taal = talen.data!.find((x) => x.NaamEnum === 'TAAL_E')!;

              return {
                type: ETaalViaOpgegevenContextenBepalerOutputType.Bepaald,
                taalID: taal.TaalID,
                eenduidigBepaald: false,
              };
            },
          }),
    [props.taalViaOpgegevenContextenBepaler, contexten, opgegevenContexten, talen],
  );

  const [taalID, setTaalID] = useState<IRemoteData<number | null>>(createPendingRemoteData());
  const [
    taalViaOpgegevenContextenBepalerOutput,
    setTaalViaOpgegevenContextenBepalerOutput,
  ] = useState<IRemoteData<TaalViaOpgegevenContextenBepalerOutput | null>>(
    createPendingRemoteData(),
  );
  useEffect(() => {
    if (
      props.taalSelectie.type === ETaalSelectieType.Opgegeven &&
      (taalID.state === ERemoteDataState.Pending || taalID.data !== props.taalSelectie.taalID)
    ) {
      if (taalViaOpgegevenContextenBepalerOutput.state === ERemoteDataState.Pending) {
        setTaalViaOpgegevenContextenBepalerOutput(createReadyRemoteData(null));
      }
      setTaalID(createReadyRemoteData(props.taalSelectie.taalID));
    } else if (
      props.taalSelectie.type === ETaalSelectieType.AutomatischBepalenViaContexten &&
      taalViaOpgegevenContextenBepaler.state === ERemoteDataState.Ready
    ) {
      (async () => {
        const output = await taalViaOpgegevenContextenBepaler.data!.bepaal({
          opgegevenContexten,
        });
        setTaalViaOpgegevenContextenBepalerOutput(createReadyRemoteData(output));
        if (output.type === ETaalViaOpgegevenContextenBepalerOutputType.NietBepaald) {
          const taal = talen.data!.find((x) => x.Terugval_TaalID === null)!;
          setTaalID(createReadyRemoteData(taal.TaalID));
          return;
        }
        setTaalID(createReadyRemoteData(output.taalID));
      })();
    }
  }, [
    JSON.stringify(props.taalSelectie),
    taalViaOpgegevenContextenBepaler,
    opgegevenContexten,
    talen,
  ]);

  const [comKanID, setComKanID] = useState<IRemoteData<number | null>>(createPendingRemoteData());

  useEffect(() => {
    const s = props.communicatiekanaalSelectie;
    if (s.type === ECommunicatiekanaalSelectieType.Opgegeven) {
      // Als het communicatiekanaal ingevuld is, en hetzelfde, dan terug
      if (comKanID.state === ERemoteDataState.Ready && s.kanaalID === comKanID.data) {
        return;
      }
      // Als we een waarde hebben, of niet mogen voorvullen, dan gewoon direct zetten
      if (s.kanaalID !== null || !s.magVoorvullen) {
        setComKanID(createReadyRemoteData(s.kanaalID));
        return;
      }
      // Als we mogen voorvullen, dan moeten we wachten tot de communicatiekanalen bekend zijn
      if (communicatiekanalen.state === ERemoteDataState.Pending) {
        return;
      }
      const emailComKan = communicatiekanalen.data!.find((x) => x.NaamEnum === 'EMAIL');
      if (emailComKan === undefined) {
        setComKanID(createReadyRemoteData(null));
        return;
      }
      setComKanID(createReadyRemoteData(emailComKan.ComKanID));
    }
  }, [JSON.stringify(props.communicatiekanaalSelectie), communicatiekanalen]);

  const defaultFilterData = useMemo<IFilterData<EFilter>[]>(
    () => [
      {
        naam: EFilter.HeeftSjabloonCommunicatiekanaalIds,
        data: props.defaultFilterSjabloonkanaalIDs ?? [],
        isActief: props.defaultFilterSjabloonkanaalIDsActief ?? false,
      },
      {
        naam: EFilter.HeeftTaalIds,
        data: props.defaultFilterTaalIDs ?? [],
        isActief: props.defaultFilterTaalIDsActief ?? false,
      },
      {
        naam: EFilter.SjabloonCategorieIds,
        data: props.defaultFilterSjabloonCategorieIDs ?? [],
        isActief: props.defaultFilterSjabloonCategorieIDsActief ?? false,
      },
      {
        naam: EFilter.VoldoetAanSjabloonContextIds,
        data: props.defaultFilterVoldoetAanSjabCtxIDs ?? [],
        isActief: props.defaultFilterVoldoetAanSjabCtxIDsActief ?? false,
      },
    ],
    [],
  );

  const [filterData, setFilterData] = useState<IRemoteData<IFilterData<EFilter>[]>>(
    createPendingRemoteData(),
  );
  const [filterSchema, setFilterSchema] = useState<IRemoteData<IFilterSchema>>(
    useMemo(() => mapRemoteData(filterData, (data) => maakFilterSchema(data)), []),
  );

  useEffect(() => {
    if (!genereerbaarAangestuurdeSelectie) {
      // Als het geen aangestuurde selectie is,
      // dan moeten we de huidige filter data behouden
      // en open stellen voor mutatie, of deze
      // initialiseren met de default waarden.
      if (filterData.state === ERemoteDataState.Ready) {
        return;
      }

      setFilterData((curr) => {
        // Extra check om te voorkomen dat we
        // de filter data overschrijven met
        // de default waarden als deze al
        // geïnitialiseerd is.
        if (curr.state === ERemoteDataState.Ready) {
          return curr;
        }
        setFilterSchema(createReadyRemoteData(maakFilterSchema(defaultFilterData)));
        return createReadyRemoteData(defaultFilterData);
      });
      return;
    }

    if (taalID.state === ERemoteDataState.Pending || contexten.state === ERemoteDataState.Pending) {
      return;
    }

    setFilterData((curr) => {
      const baseFilterData = curr.data ?? defaultFilterData;
      const newFilterData = baseFilterData.map((currFilterDataItem) => {
        switch (currFilterDataItem.naam) {
          case EFilter.SjabloonCategorieIds:
            return currFilterDataItem;
          case EFilter.HeeftSjabloonCommunicatiekanaalIds:
            return {
              ...currFilterDataItem,
              isActief: comKanID.data !== null,
              data: comKanID.data === null ? [] : [comKanID.data!],
            };
          case EFilter.HeeftTaalIds: {
            return {
              ...currFilterDataItem,
              isActief: taalID.data !== null,
              data: taalID.data === null ? [] : [taalID.data!],
            };
          }
          case EFilter.VoldoetAanSjabloonContextIds: {
            const sjabCtxIDs: number[] = _.uniq(
              opgegevenContexten.map((opgegevenContext) => {
                if (opgegevenContext.selectie.type === EOpgegevenContextSelectieType.SjabCtxID) {
                  return opgegevenContext.selectie.sjabCtxID;
                }
                const context = contexten.data!.find((ctx) => {
                  switch (opgegevenContext.selectie.type) {
                    case EOpgegevenContextSelectieType.NaamEnum:
                      return ctx.NaamEnum === opgegevenContext.selectie.naamEnum;
                    case EOpgegevenContextSelectieType.SjabCtxID:
                      return ctx.SjabCtxID === opgegevenContext.selectie.sjabCtxID;
                  }
                })!;
                return context.SjabCtxID;
              }),
            );
            return {
              ...currFilterDataItem,
              isActief: true,
              data: sjabCtxIDs,
            };
          }
        }
      });

      setFilterSchema(createReadyRemoteData(maakFilterSchema(newFilterData)));

      return createReadyRemoteData(newFilterData);
    });
  }, [opgegevenContexten, genereerbaarAangestuurdeSelectie, taalID, comKanID, contexten]);

  useEffect(() => {
    (async () => {
      const result = await api.v2.sjabloon.ophalenSjabloonKanalen({
        filterSchema: {
          filters: [],
        },
      });
      setSjabloonKanalen(createReadyRemoteData(result.kanalen));
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const result = await api.v2.taal.ophalen({
        filterSchema: { filters: [] },
      });
      setTalen(createReadyRemoteData(result));
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const result = await api.v2.sjabloon.ophalenSjabloonContexten({
        filterSchema: {
          filters: [],
        },
      });
      setContexten(createReadyRemoteData(result.contexten));
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const result = await api.v2.sjabloon.ophalenSjabloonCategorieen({
        filterSchema: {
          filters: [],
        },
        orderSchema: {
          orders: [{ naam: 'NAAM', richting: 'ASC' }],
        },
      });
      setSjabloonCategorieen(createReadyRemoteData(result.categorieen));
    })();
  }, []);

  useEffect(() => {
    if (sjabloonKanalen.state === ERemoteDataState.Pending) {
      return;
    }
    (async () => {
      const result = await api.v2.sjabloon.ophalenSjabloonInhouden({
        filterSchema: {
          filters: [
            {
              naam: 'SJAB_KAN_IDS',
              data: sjabloonKanalen.data!.map((x) => x.ID),
            },
          ],
        },
      });
      setSjabloonInhouden(createReadyRemoteData(result.inhouden));
    })();
  }, [sjabloonKanalen]);

  useEffect(() => {
    (async () => {
      const result = await api.v2.bericht.ophalenCommunicatiekanalen({
        filterSchema: {
          filters: [],
        },
      });
      setCommunicatiekanalen(createReadyRemoteData(result.kanalen));
    })();
  }, []);

  const ophalenSjablonen = useCallback(async () => {
    if (filterSchema.state === ERemoteDataState.Pending) {
      setSjablonen(createPendingRemoteData());
      return;
    }

    const result = await api.v2.sjabloon.ophalenSjablonen({
      filterSchema: {
        filters: [
          ...(filterSchema.data!.filters ?? []),
          {
            naam: 'IS_HOOFD_SJABLOON',
            data: true,
          },
          {
            naam: 'IS_CONCEPT',
            data: false,
          },
          modus.type === ESjabloonOplosserModus.Selecteer
            ? null
            : {
                naam: 'IS_SYSTEEM_SJABLOON',
                data: false,
              },
          actieveZoekterm === null
            ? null
            : {
                naam: 'TEKST_PRECISIE_HEEFT_INHOUD',
                data: {
                  precisie: 'ONGEVEER',
                  waarde: actieveZoekterm,
                } as ITekstPrecisieData,
              },
        ].filter((x) => x !== null) as IFilterSchemaFilter[],
      },
      orderSchema: {
        orders: [
          {
            naam: 'NAAM',
            richting: 'ASC',
          },
        ],
      },
    });

    setSjablonen(createReadyRemoteData(result.sjablonen));
  }, [filterSchema, actieveZoekterm, modus]);

  useEffect(() => {
    ophalenSjablonen();
  }, [ophalenSjablonen]);

  const heeftBenodigdeInformatieVoorGenereren = useMemo(() => {
    return (
      genereerbaarAangestuurdeSelectie &&
      taalID.state === ERemoteDataState.Ready &&
      taalID.data !== null
    );
  }, [genereerbaarAangestuurdeSelectie, taalID]);

  const dialoogIndex = useMemo(() => props.dialoogIndex ?? 0, []);

  const genereerSjabloon = useCallback(
    async (
      sjabloon: ISjabloon,
      sjabloonBasisNietToepassen: boolean,
    ): Promise<ISjabloonOplosserOutput | null> => {
      let bepaaldComKanID: number | null = null;
      let sjabloonkanaal: ISjabloonKanaal | null = null;
      if (comKanID.data !== null) {
        bepaaldComKanID = comKanID.data;
        sjabloonkanaal = sjabloonKanalen.data!.find(
          (x) => x.ComKanID === bepaaldComKanID && x.SjabID === sjabloon.ID,
        )!;
      } else {
        const sjabloonkanalen = sjabloonKanalen.data!.filter((x) => x.SjabID === sjabloon.ID);
        if (sjabloonkanalen.length === 1) {
          sjabloonkanaal = sjabloonkanalen[0];
          bepaaldComKanID = sjabloonkanalen[0].ComKanID;
        }
      }
      if (bepaaldComKanID === null) {
        toast.error('Kan geen (eenduidig) communicatiekanaal bepalen');
        return null;
      }

      toast.loading('Contextgegevens bepalen...');
      const contextdatasResult = await api.v2.sjabloon.ophalenContextdatas({
        taalID: taalID.data!,
        contextBepaalData: opgegevenContexten.map((ctx) => {
          const context = contexten.data!.find((x) => {
            switch (ctx.selectie.type) {
              case EOpgegevenContextSelectieType.NaamEnum:
                return x.NaamEnum === ctx.selectie.naamEnum;
              case EOpgegevenContextSelectieType.SjabCtxID: {
                return x.SjabCtxID === ctx.selectie.sjabCtxID;
              }
            }
          })!;
          return {
            naamEnum: context.NaamEnum,
            alias: ctx.alias,
            data: ctx.data,
          };
        }),
      });

      toast.loading('Sjabloon genereren...');
      let resolveResult: IResolveResult;
      try {
        resolveResult = await api.v2.sjabloon.resolve({
          sjabloonSelectie: {
            sjabID: sjabloon.ID,
          },
          communicatieKanaalSelectie: {
            comKanID: bepaaldComKanID,
          },
          gewensteTaalID: taalID.data!,
          contexten: contextdatasResult.contexten,
          basisNietToepassen: sjabloonBasisNietToepassen,
        });
      } catch (err) {
        console.error(err);
        toast.error('Sjabloon kon niet worden gegenereerd');
        return null;
      }

      const communicatiekanaal = communicatiekanalen.data!.find(
        (x) => x.ComKanID === comKanID.data!,
      )!;
      const taal = talen.data!.find((x) => x.TaalID === taalID.data!)!;

      return {
        sjabloon,
        communicatiekanaal,
        taal,
        opgegevenContexten,
        contexten: contextdatasResult.contexten,
        resolveResult,
        sjabloonkanaal: sjabloonkanaal!,
      };
    },
    [opgegevenContexten, taalID, comKanID, contexten, communicatiekanalen, talen, sjabloonKanalen],
  );

  const handleSjabloonGenereren = useCallback(
    async (sjabloon: ISjabloon) => {
      setIsBezig(true);

      const output = await genereerSjabloon(sjabloon, props.sjabloonBasisNietToepassen ?? false);
      if (output === null) {
        setIsBezig(false);
        return;
      }

      props.onSuccess(output);
      setIsBezig(false);
    },
    [genereerSjabloon, props.onSuccess, props.sjabloonBasisNietToepassen],
  );

  const handleSjabloonOpstellen = useCallback(
    async (sjabloon: ISjabloon) => {
      setIsBezig(true);

      const output = await genereerSjabloon(sjabloon, true);
      if (output === null) {
        setIsBezig(false);
        return;
      }

      const m = modus as ISjabloonOplosserVerstuurModus;
      const kanaal = communicatiekanalen.data!.find((x) => x.ComKanID === comKanID.data!)!;

      switch (kanaal.NaamEnum) {
        case 'EMAIL': {
          const email = m.kanalen[EVerstuurModusKanaal.Email]!;

          const bijlagenBestanden = [
            ...email.bijlagen,
            ...output.resolveResult!.bestanden.map((x) => ({
              bestandID: x.ID,
            })),
          ];
          let bestandenResult: IOphalenBestandenResult | null = null;
          if (bijlagenBestanden.length > 0) {
            toast.loading('Bijlagen ophalen...');
            bestandenResult = await api.v2.bestand.ophalenBestanden({
              filterSchema: {
                filters: [
                  {
                    naam: 'IDS',
                    data: bijlagenBestanden.map((x) => x.bestandID),
                  },
                ],
              },
            });
          }

          setCommunicatieOverlayState!((prevState) => ({
            ...prevState,
            geselecteerdTabblad: ECommunicatieTabblad.Email,
            emailContext: {
              ...prevState.emailContext,
              formulier: {
                recipients: email.aan,
                CC: email.cc,
                BCC: email.bcc,
                subject: output.resolveResult!.onderwerp ?? '',
                body: output.resolveResult!.inhoud ?? '',
                bijlagen:
                  bestandenResult === null
                    ? []
                    : bestandenResult.bestanden.map((x) => ({
                        type: BestandType.ASPDrive,
                        bestand: x,
                      })),
                contextRelIDs: email.contextRelIDs,
                taalID: output.resolveResult!.taalID,
                sjabBasisID: output.resolveResult!.sjabBasisID,
                emailBerID: null,
                fromEmailGebrID: prevState.emailContext.formulier?.fromEmailGebrID ?? null,
                uitstelling: prevState.emailContext.formulier?.uitstelling ?? EUitstelling.Direct,
              },
            },
          }));
          break;
        }
        case 'SMS': {
          const sms = m.kanalen[EVerstuurModusKanaal.SMS]!;

          let telefoonnummer: string;
          let persID: number | null = null;
          switch (sms.type) {
            case ESMSVerstuurModusType.Contact: {
              toast.loading('SMS contact ophalen...');
              const ophalenResult = await api.v2.dienst.sms.ophalenContacten({
                filterSchema: {
                  filters: [
                    {
                      naam: 'IDS',
                      data: [sms.smsContactID],
                    },
                  ],
                },
              });
              telefoonnummer = ophalenResult[0].Telefoonnummer;
              persID = ophalenResult[0].PersID;
              break;
            }
            case ESMSVerstuurModusType.Telefoonnummer: {
              telefoonnummer = sms.telefoonnummer;
              persID = sms.persID;
              break;
            }
          }

          setCommunicatieOverlayState!((prevState) => ({
            ...prevState,
            geselecteerdTabblad: ECommunicatieTabblad.Sms,
            smsContext: {
              ...prevState.smsContext,
              telefoonnummer,
              persID: persID ?? undefined,
              bericht: output.resolveResult!.inhoud ?? '',
            },
          }));
          break;
        }
        case 'WHATSAPP': {
          const whatsapp = m.kanalen[EVerstuurModusKanaal.WhatsApp]!;

          whatsappStore.setChatsessieState(whatsapp.whatsAppSesID, {
            tekst: output.resolveResult!.inhoud ?? '',
          });
          setCommunicatieOverlayState!((prevState) => ({
            ...prevState,
            geselecteerdTabblad: ECommunicatieTabblad.Whatsapp,
            whatsappContext: {
              ...prevState.whatsappContext,
              chatSessieID: whatsapp.whatsAppSesID,
            },
          }));
          break;
        }
      }

      props.onAnnuleren();

      setIsBezig(false);
    },
    [
      genereerSjabloon,
      props.onSuccess,
      modus,
      comKanID,
      communicatiekanalen,
      whatsappStore.setChatsessieState,
    ],
  );
  const handleSjabloonVersturen = useCallback(
    async (sjabloon: ISjabloon) => {
      setIsBezig(true);

      const output = await genereerSjabloon(sjabloon, false);
      if (output === null) {
        setIsBezig(false);
        return;
      }

      const m = modus as ISjabloonOplosserVerstuurModus;
      const kanaal = communicatiekanalen.data!.find((x) => x.ComKanID === comKanID.data!)!;

      switch (kanaal.NaamEnum) {
        case 'EMAIL': {
          const email = m.kanalen[EVerstuurModusKanaal.Email]!;

          try {
            await api.v2.email.versturen({
              van_EmailGebrID: null,
              email: {
                aan: email.aan,
                cc: email.cc,
                bcc: email.bcc,
                bijlagen: [
                  ...email.bijlagen,
                  ...output.resolveResult!.bestanden.map((x) => ({
                    bestandID: x.ID,
                  })),
                ],
                contextRelIDs: email.contextRelIDs,
                onderwerp: output.resolveResult!.onderwerp,
                inhoud: output.resolveResult!.inhoud,
                isHTML: true,
              },
            });
          } catch (err) {
            console.error(err);
            toast.error('Email kon niet worden verstuurd');
            setIsBezig(false);
            return;
          }
          toast.success('Email verstuurd');
          break;
        }
        case 'SMS': {
          const sms = m.kanalen[EVerstuurModusKanaal.SMS]!;

          try {
            switch (sms.type) {
              case ESMSVerstuurModusType.Contact: {
                await api.v2.dienst.sms.versturenBericht({
                  smsContactID: sms.smsContactID,
                  inhoud: output.resolveResult!.inhoud ?? '',
                });
                break;
              }
              case ESMSVerstuurModusType.Telefoonnummer: {
                await api.v2.dienst.sms.versturenBijNieuwContact({
                  persID: sms.persID,
                  telefoonNummer: sms.telefoonnummer,
                  tempId: '',
                  inhoud: output.resolveResult!.inhoud ?? '',
                });
                break;
              }
            }
          } catch (err) {
            console.error(err);
            toast.error('SMS kon niet worden verstuurd');
            setIsBezig(false);
            return;
          }
          toast.success('SMS bericht verstuurd');
          break;
        }
        case 'WHATSAPP': {
          const whatsapp = m.kanalen[EVerstuurModusKanaal.WhatsApp]!;

          try {
            toast.loading('Bepalen mag whatsapp bericht versturen...');
            const bepalenMagVersturenResult = await api.v2.dienst.whatsapp.bepalenMagBerichtVersturen(
              {
                sesID: whatsapp.whatsAppSesID,
              },
            );
            if (!bepalenMagVersturenResult.magVersturen) {
              toast.error('WhatsApp bericht mag niet worden verstuurd i.v.m. het 24-uurs beleid');
              setIsBezig(false);
              return;
            }

            await api.v2.dienst.whatsapp.verstuurBericht({
              sesID: whatsapp.whatsAppSesID,
              inhoud: output.resolveResult!.inhoud ?? '',
              custom: null,
              media: null,
            });
          } catch (err) {
            console.error(err);
            toast.error('WhatsApp kon niet worden verstuurd');
            setIsBezig(false);
            return;
          }
          toast.success('WhatsApp bericht verstuurd');
          break;
        }
      }

      setIsBezig(false);
    },
    [genereerSjabloon, props.onSuccess, modus, comKanID, communicatiekanalen],
  );

  const handleSjabloonSelecteren = useCallback(
    async (sjabloon: ISjabloon) => {
      setIsBezig(true);

      let bepaaldComKanID: number | null = null;
      let sjabloonkanaal: ISjabloonKanaal | null = null;
      if (comKanID.data !== null) {
        bepaaldComKanID = comKanID.data;
        sjabloonkanaal = sjabloonKanalen.data!.find(
          (x) => x.ComKanID === bepaaldComKanID && x.SjabID === sjabloon.ID,
        )!;
      } else {
        const sjabloonkanalen = sjabloonKanalen.data!.filter((x) => x.SjabID === sjabloon.ID);
        if (sjabloonkanalen.length === 1) {
          sjabloonkanaal = sjabloonkanalen[0];
          bepaaldComKanID = sjabloonkanalen[0].ComKanID;
        }
      }
      if (bepaaldComKanID === null) {
        toast.error('Kan geen (eenduidig) communicatiekanaal bepalen');
        return;
      }

      const taal = talen.data!.find((x) => x.TaalID === taalID.data!)!;
      const communicatiekanaal = communicatiekanalen.data!.find(
        (x) => x.ComKanID === bepaaldComKanID,
      )!;

      props.onSuccess({
        sjabloon,
        communicatiekanaal,
        taal,
        opgegevenContexten,
        contexten: [],
        resolveResult: null,
        sjabloonkanaal: sjabloonkanaal!,
      });
      setIsBezig(false);
    },
    [props.onSuccess, comKanID, sjabloonKanalen, talen, taalID, communicatiekanalen],
  );

  const handlePreviewAangevraagd = useCallback(
    async (sjabloon: ISjabloon) => {
      setIsBezig(true);

      let bepaaldComKanID: number | null = null;
      let sjabloonkanaal: ISjabloonKanaal | null = null;
      if (comKanID.data !== null) {
        bepaaldComKanID = comKanID.data;
        sjabloonkanaal = sjabloonKanalen.data!.find(
          (x) => x.ComKanID === bepaaldComKanID && x.SjabID === sjabloon.ID,
        )!;
      } else {
        const sjabloonkanalen = sjabloonKanalen.data!.filter((x) => x.SjabID === sjabloon.ID);
        if (sjabloonkanalen.length === 1) {
          sjabloonkanaal = sjabloonkanalen[0];
          bepaaldComKanID = sjabloonkanalen[0].ComKanID;
        }
      }
      if (bepaaldComKanID === null) {
        toast.error('Kan geen (eenduidig) communicatiekanaal bepalen');
        return;
      }

      toast.loading('Contextgegevens bepalen...');
      const contextdatasResult = await api.v2.sjabloon.ophalenContextdatas({
        taalID: taalID.data!,
        contextBepaalData: opgegevenContexten.map((ctx) => {
          const context = contexten.data!.find((x) => {
            switch (ctx.selectie.type) {
              case EOpgegevenContextSelectieType.NaamEnum:
                return x.NaamEnum === ctx.selectie.naamEnum;
              case EOpgegevenContextSelectieType.SjabCtxID: {
                return x.SjabCtxID === ctx.selectie.sjabCtxID;
              }
            }
          })!;
          return {
            naamEnum: context.NaamEnum,
            alias: ctx.alias,
            data: ctx.data,
          };
        }),
      });

      toast.loading('Sjabloon genereren...');
      let resolveResult: IResolveResult;
      try {
        resolveResult = await api.v2.sjabloon.resolve({
          sjabloonSelectie: {
            sjabID: sjabloon.ID,
          },
          communicatieKanaalSelectie: {
            comKanID: bepaaldComKanID,
          },
          gewensteTaalID: taalID.data!,
          contexten: contextdatasResult.contexten,
        });
      } catch (err) {
        console.error(err);
        toast.error('Sjabloon kon niet worden gegenereerd');
        setIsBezig(false);
        return;
      }

      let previewModus: EPreviewModus;
      const communicatiekanaal = communicatiekanalen.data!.find(
        (x) => x.ComKanID === bepaaldComKanID,
      )!;
      switch (communicatiekanaal.NaamEnum) {
        case 'EMAIL': {
          previewModus = EPreviewModus.Email;
          break;
        }
        case 'SMS': {
          previewModus = EPreviewModus.Chatbubbel;
          break;
        }
        case 'WHATSAPP': {
          previewModus = EPreviewModus.Chatbubbel;
          break;
        }
        case 'BESTAND_HTML': {
          previewModus = EPreviewModus.Document;
          break;
        }
        default:
          throw new Error(
            'Niet ondersteund communicatiekanaal voor preview: ' + communicatiekanaal.NaamEnum,
          );
      }

      const output = await globaleRenderer.render<IPreviewDialoogOutput>((renderProps) => (
        <PreviewDialoog
          open
          onSuccess={(x) => renderProps.destroy(x)}
          onAnnuleren={() => renderProps.destroy()}
          dialoogIndex={dialoogIndex + 1}
          onderwerp={resolveResult.onderwerp ?? null}
          inhoud={resolveResult.inhoud ?? null}
          modus={previewModus}
          sjabloonOplosserModus={modus}
        />
      ));
      if (output === undefined) {
        setIsBezig(false);
        return;
      }

      switch (output.type) {
        case EPreviewDialoogOutputType.DirectKiezen: {
          if (!props.sjabloonBasisNietToepassen) {
            const taal = talen.data!.find((x) => x.TaalID === taalID.data!)!;
            props.onSuccess({
              sjabloon,
              communicatiekanaal,
              taal,
              opgegevenContexten,
              contexten: contextdatasResult.contexten,
              resolveResult,
              sjabloonkanaal: sjabloonkanaal!,
            });
            return;
          }
          await handleSjabloonGenereren(sjabloon);
          break;
        }
        case EPreviewDialoogOutputType.Opstellen:
          await handleSjabloonOpstellen(sjabloon);
          break;
        case EPreviewDialoogOutputType.Versturen:
          await handleSjabloonVersturen(sjabloon);
          break;
        case EPreviewDialoogOutputType.Selecteren:
          await handleSjabloonSelecteren(sjabloon);
          break;
      }
    },
    [
      dialoogIndex,
      opgegevenContexten,
      taalID,
      comKanID,
      contexten,
      communicatiekanalen,
      talen,
      props.sjabloonBasisNietToepassen,
      handleSjabloonGenereren,
      handleSjabloonOpstellen,
      handleSjabloonVersturen,
      handleSjabloonSelecteren,
      modus,
    ],
  );

  return (
    <Dialoog
      index={dialoogIndex}
      modalProps={{
        dialogClassName:
          modus.type === ESjabloonOplosserModus.Selecteer
            ? 'modal-sjabloondialoog-selecteer'
            : isIngeklapt
            ? 'modal-sjabloondialoog-ingeklapt'
            : 'modal-sjabloondialoog',
      }}
    >
      <div style={{ height: 800 }} className="d-flex">
        {modus.type !== ESjabloonOplosserModus.Selecteer && isIngeklapt ? (
          <LinkerdeelIngeklapt isBezig={isBezig} uitklappen={() => setIsIngeklapt(false)} />
        ) : (
          <LinkerDeel
            dialoogIndex={dialoogIndex}
            magGenereerbaarAangestuurdeSelectieMuteren={
              props.magGenereerbaarAangestuurdeSelectieMuteren
            }
            genereerbaarAangestuurdeSelectie={genereerbaarAangestuurdeSelectie}
            onGenereerbaarAangestuurdeSelectieChange={setGenereerbaarAangestuurdeSelectie}
            taalID={taalID}
            onTaalIDChange={(taalID) => setTaalID(createReadyRemoteData(taalID))}
            opgegevenContexten={opgegevenContexten}
            onOpgegevenContextenChange={setOpgegevenContexten}
            contexten={contexten}
            magOpgegevenContextenMuteren={props.magOpgegevenContextenMuteren ?? true}
            comKanID={comKanID}
            onComKanIDChange={(kanaalID) => setComKanID(createReadyRemoteData(kanaalID))}
            communicatiekanalen={communicatiekanalen}
            isBezig={isBezig}
            taalSelectie={props.taalSelectie}
            communicatiekanaalSelectie={props.communicatiekanaalSelectie}
            taalViaOpgegevenContextenBepalerOutput={taalViaOpgegevenContextenBepalerOutput}
            modus={modus}
            inklappen={() => setIsIngeklapt(true)}
          />
        )}
        <RechterDeel
          zoekterm={zoektermInput}
          onZoektermChange={setZoektermInput}
          sjablonen={sjablonen}
          sjabloonKanalen={sjabloonKanalen}
          sjabloonCategorieen={sjabloonCategorieen}
          sjabloonInhouden={sjabloonInhouden}
          communicatiekanalen={communicatiekanalen}
          talen={talen}
          contexten={contexten}
          onFilterDataChange={(x) => setFilterData(createReadyRemoteData(x))}
          onFilterSchemaChange={(x) => setFilterSchema(createReadyRemoteData(x))}
          filterData={filterData}
          magTaalFilterWijzigen={
            !genereerbaarAangestuurdeSelectie &&
            (props.magFilterTaalIDsMuteren === undefined || props.magFilterTaalIDsMuteren)
          }
          magSjabloonCommunicatiekanaalFilterWijzigen={
            !genereerbaarAangestuurdeSelectie &&
            (props.magFilterSjabloonkanaalIDsMuteren === undefined ||
              props.magFilterSjabloonkanaalIDsMuteren)
          }
          magVoldoetAanSjabloonContextFilterWijzigen={
            !genereerbaarAangestuurdeSelectie &&
            (props.magFilterVoldoetAanSjabCtxIDsMuteren === undefined ||
              props.magFilterVoldoetAanSjabCtxIDsMuteren)
          }
          magSjablooncategorieFilterWijzigen={
            props.magFilterSjabloonCategorieIDsMuteren === undefined ||
            props.magFilterSjabloonCategorieIDsMuteren
          }
          genereerbaarAangestuurdeSelectie={genereerbaarAangestuurdeSelectie}
          taalID={taalID}
          comKanID={comKanID}
          heeftBenodigdeInformatieVoorGenereren={heeftBenodigdeInformatieVoorGenereren}
          onPreviewAangevraagd={handlePreviewAangevraagd}
          isBezig={isBezig}
          onSjabloonGenereren={handleSjabloonGenereren}
          onSjabloonOpstellen={handleSjabloonOpstellen}
          onSjabloonVersturen={handleSjabloonVersturen}
          onSjabloonSelecteren={handleSjabloonSelecteren}
          modus={modus}
        />
      </div>
      <ModalFooter className="d-flex align-items-center justify-content-start">
        <button
          className="btn btn-secondary"
          onClick={props.onAnnuleren}
          style={{ width: 100 }}
          disabled={isBezig}
        >
          Annuleren
        </button>
      </ModalFooter>
    </Dialoog>
  );
});

export default SjabloonOplosser;
