import { Field, FieldProps, Formik, FormikActions, FormikErrors, FormikProps } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import * as Yup from 'yup';
import ITaalTekst from '../../../../../../../shared/src/models/talen/ITaalTekst';
import api from '../../../../../api';
import teksten from '../../../../../bedrijfslogica/teksten';
import Dialoog from '../../../../../components/dialogen/Dialoog';
import MeertaligTekstveld from '../../../../../components/formulier/MeertaligTekstveld';
import IDialoogProps from '../../../../../core/IDialoogProps';
import nameof from '../../../../../core/nameOf';
import { dagDatum } from '../../../../../helpers/datum';
import { EResultType } from '../../../../../stores/CheckStore';
import { RootStoreContext } from '../../../../../stores/RootStore';
import {
  IOphalenBlogCategoriesInternResultElement,
  IToevoegenBlogitemParams,
} from '../../../../../../../shared/src/api/v2/blog';
import { IOphalenBestandenResult } from '../../../../../../../shared/src/api/v2/bestand/bestand';
import { IOphalenTekstenInAlleTalenResult } from '../../../../../../../shared/src/api/v2/tekst';
import IRemoteData, {
  createPendingRemoteData,
  ERemoteDataState,
  createReadyRemoteData,
} from '../../../../../models/IRemoteData';
import Combobox, { IOptie } from '../../../../../components/formulier/Combobox';
import Bijlage from '../../../../../components/formulier/BijlageKiezer';
import LoadingSpinner from '../../../../../components/Gedeeld/LoadingSpinner';
import nameOf from '../../../../../core/nameOf';
import DatumKiezer from '../../../../../components/formulier/DatumKiezer';

interface IDialoogResult {}

interface IProps extends IDialoogProps<IDialoogResult> {}

interface IFormikValues {
  titel: ITaalTekst[];
  inhoud: ITaalTekst[];
  trefwoorden: ITaalTekst[];
  slug: ITaalTekst[];
  categorie: number | null;
  bestandID: number | undefined;
  datum: string | Date;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  titel: 'Naam',
  inhoud: 'Inhoud',
  trefwoorden: 'Trefwoorden',
  slug: 'Slug',
  categorie: 'Categorie',
  bestandID: 'Afbeelding',
  datum: 'Publicatiedatum',
};

const ToevoegenDialoog: React.FC<IProps> = observer((props) => {
  const { children, dialoogIndex, onAnnuleren, onSuccess } = props;
  const { checkStore } = useContext(RootStoreContext);
  const formikRef = useRef<Formik<IFormikValues>>(null);

  const [categorieen, setCategorieen] = useState<
    IOphalenBlogCategoriesInternResultElement[] | null
  >(null);
  const [taalTeksten, setTeksten] = useState<IRemoteData<IOphalenTekstenInAlleTalenResult>>(
    createPendingRemoteData(),
  );

  const [bestanden, setBestandenResult] = useState<IOphalenBestandenResult | null>(null);

  const ophalenTeksten = useCallback(async () => {
    if (categorieen == null) {
      if (taalTeksten.state === ERemoteDataState.Ready) {
        setTeksten(createPendingRemoteData());
      }
      return;
    }

    const tekstIDs = [...categorieen.map((x) => x.Naam_TekstID).filter((x) => x !== null)].filter(
      (x) => x !== null,
    ) as number[];

    const result = await api.v2.tekst.ophalenTekstenInAlleTalen({
      tekstIDs,
    });
    setTeksten(createReadyRemoteData(result));
  }, [categorieen]);

  const ophalenCategorieen = useCallback(async () => {
    const result = await api.v2.blog.ophalenBlogCategorie({});

    setCategorieen(result.categorieen);
  }, []);

  useEffect(() => {
    ophalenCategorieen();
  }, [ophalenCategorieen]);

  useEffect(() => {
    ophalenTeksten();
  }, [ophalenTeksten]);

  const ophalenBestanden = async (bestandID: number) => {
    const result = await api.v2.bestand.ophalenBestanden({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: bestandID,
          },
        ],
      },
    });
    setBestandenResult(result);
  };

  const ophalenCategorieOpties = useCallback(() => {
    if (categorieen == null || taalTeksten == null) return [];
    const result: IOptie<number>[] =
      categorieen == null
        ? []
        : categorieen
            .map((cat) => {
              return {
                id: cat.ID,
                label:
                  cat.Naam_TekstID == null
                    ? ''
                    : taalTeksten.data!.teksten.find((x) => x.TekstID === cat.Naam_TekstID)!.Tekst!,
              };
            })
            .filter((optie) => optie.label != '');
    return result;
  }, [categorieen, taalTeksten]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      const params: IToevoegenBlogitemParams = {
        Datum: values.datum,
        Titel: values.titel,
        Inhoud: values.inhoud,
        Trefwoorden: values.trefwoorden,
        Slug: values.slug,
        BlogItemCategorieID: values.categorie,
        BestandID: values.bestandID,
      };

      const checkData = await api.v2.blog.checkToevoegenBlogitem({});
      const checkResult = await checkStore.controleren({
        checkData,
      });
      if (checkResult.type === EResultType.Annuleren) {
        onAnnuleren();
        return;
      }

      await api.v2.blog.toevoegenBlogitem(params);

      props.onSuccess({});
      actions.setSubmitting(false);
    },
    [],
  );

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (taalTeksten == null || taalTeksten.data == null) return null;

    return {
      titel: [],
      inhoud: [],
      trefwoorden: [],
      slug: [],
      categorie: null,
      bestandID: undefined,
      datum: dagDatum(new Date()),
    };
  }, [taalTeksten, categorieen]);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        ID: Yup.string().required(),
      }),
    [],
  );

  const validate = useCallback((values: IFormikValues) => {
    const errors: FormikErrors<IFormikValues> = {};
    if (values.titel.length === 0 || values.titel.every((t) => t.tekst == '')) {
      errors.titel = teksten.formulier.E_VERPLICHT_VELD({ veldnaam: 'Naam' }) as any;
    }
    if (values.inhoud.length === 0 || values.inhoud.every((t) => t.tekst == '')) {
      errors.inhoud = teksten.formulier.E_VERPLICHT_VELD({ veldnaam: 'Inhoud' }) as any;
    }
    if (values.slug.length === 0 || values.slug.every((t) => t.tekst == '')) {
      errors.slug = teksten.formulier.E_VERPLICHT_VELD({ veldnaam: 'Slug' }) as any;
    }
    if (values.categorie == null) {
      errors.categorie = teksten.formulier.E_VERPLICHT_VELD({ veldnaam: 'Categorie' }) as any;
    }
    return errors;
  }, []);

  return (
    <Dialoog index={dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Toevoegen Item</ModalTitle>
      </ModalHeader>
      {initialValues !== null && (
        <Formik<IFormikValues>
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validate={validate}
          enableReinitialize
          render={(formikProps: FormikProps<IFormikValues>) => {
            const { submitForm, isValid, isSubmitting } = formikProps;

            return (
              <>
                <ModalBody>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-12 mt-3">
                        <label>{veldnamen.datum}</label>
                        <Field
                          name={nameOf<IFormikValues>('datum')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <DatumKiezer
                                waarde={field.value}
                                onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                determineNextValidDate="ONBEGRENST"
                                determinePreviousValidDate="ONBEGRENST"
                              />
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <label>Titel</label>
                        <Field
                          name={nameof<IFormikValues>('titel')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            return (
                              <MeertaligTekstveld
                                waarden={fieldProps.field.value}
                                onChange={(x) =>
                                  fieldProps.form.setFieldValue(fieldProps.field.name, x)
                                }
                              />
                            );
                          }}
                        />
                      </div>
                      <div className="col-12 mt-3">
                        <label>Inhoud</label>
                        <Field
                          name={nameof<IFormikValues>('inhoud')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            return (
                              <MeertaligTekstveld
                                waarden={fieldProps.field.value}
                                onChange={(x) =>
                                  fieldProps.form.setFieldValue(fieldProps.field.name, x)
                                }
                              />
                            );
                          }}
                        />
                      </div>
                      <div className="col-12 mt-3">
                        <label>Trefwoorden</label>
                        <Field
                          name={nameof<IFormikValues>('trefwoorden')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            return (
                              <MeertaligTekstveld
                                waarden={fieldProps.field.value}
                                onChange={(x) =>
                                  fieldProps.form.setFieldValue(fieldProps.field.name, x)
                                }
                              />
                            );
                          }}
                        />
                      </div>
                      <div className="col-12 mt-3">
                        <label>Slug</label>
                        <Field
                          name={nameof<IFormikValues>('slug')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            return (
                              <MeertaligTekstveld
                                waarden={fieldProps.field.value}
                                onChange={(x) =>
                                  fieldProps.form.setFieldValue(fieldProps.field.name, x)
                                }
                              />
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <label>Afbeelding</label>
                        <Field
                          name={nameof<IFormikValues>('bestandID')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            return (
                              <Bijlage
                                bestand={bestanden?.bestanden[0]}
                                onBestIDChange={async (bestID) => {
                                  console.log('bestand veranderd:' + bestID);
                                  fieldProps.form.setFieldValue(fieldProps.field.name, bestID);
                                  if (bestID != null) ophalenBestanden(bestID);
                                  else setBestandenResult(null);
                                }}
                              />
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <label>Categorie</label>
                        <Field
                          name={nameof<IFormikValues>('categorie')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            return (
                              <Combobox
                                geselecteerd={fieldProps.field.value}
                                opties={ophalenCategorieOpties()}
                                onSelectieChange={(x) =>
                                  fieldProps.form.setFieldValue(fieldProps.field.name, x)
                                }
                              />
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </ModalBody>

                <ModalFooter className="d-flex flex-row justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    style={{ width: 100 }}
                    disabled={!isValid || isSubmitting}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={onAnnuleren}
                    style={{ width: 100 }}
                    disabled={isSubmitting}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Dialoog>
  );
});

export default ToevoegenDialoog;
