import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  IOphalenTaakEventsResult,
  IOphalenTaakEventsResultTaakEventElement,
} from '../../../../../../shared/src/api/v2/taken';
import api from '../../../../api';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import EventRegel from './EventRegel';
import HorizontaleScheidingslijn from '../../../../components/layout/HorizontaleScheidingslijn';
import { List, AutoSizer, CellMeasurerCache, CellMeasurer } from 'react-virtualized';

export enum TaakEventType {
  Gestart = 1,
  Gestopt = 2,
  Error = 3,
  Log = 4,
  Geannuleerd = 5,
}

export const typeTekst: Record<TaakEventType, string> = {
  [TaakEventType.Gestart]: 'GESTART',
  [TaakEventType.Gestopt]: 'GESTOPT',
  [TaakEventType.Error]: 'ERROR',
  [TaakEventType.Log]: 'LOG',
  [TaakEventType.Geannuleerd]: 'GEANNULEERD',
};

interface IProps {
  taakID: number;
}

const EventsWeergave: React.FC<IProps> = (props) => {
  const [events, setEvents] = useState<IOphalenTaakEventsResultTaakEventElement[]>([]);
  const [eventsResult, setEventsResult] = useState<IOphalenTaakEventsResult | null>(null);

  const ophalenEvents = useCallback(async () => {
    const result = await api.v2.taken.ophalenTaakEvents({
      filterSchema: {
        filters: [
          {
            naam: 'TAAK_IDS',
            data: [props.taakID],
          },
        ],
      },
      orderSchema: {
        orders: [
          {
            naam: 'RECORD_TOEGEVOEGD',
            richting: 'DESC',
          },
        ],
      },
    });
    setEventsResult(result);
  }, [props.taakID]);
  useEffect(() => {
    ophalenEvents();
  }, [ophalenEvents]);

  useEffect(() => {
    if (eventsResult === null) {
      return;
    }
    setEvents(eventsResult.taakEvents);
  }, [eventsResult]);

  const cache = useMemo(
    () =>
      new CellMeasurerCache({
        defaultHeight: 32,
        fixedWidth: true,
      }),
    [],
  );

  const rowRenderer = useCallback(
    (rowProps) => {
      const event = events[rowProps.index]!;
      return (
        <CellMeasurer
          cache={cache}
          columnIndex={0}
          key={rowProps.key}
          parent={rowProps.parent}
          rowIndex={rowProps.index}
        >
          {({ measure }) => (
            <div style={rowProps.style}>
              <EventRegel event={event} height={cache.rowHeight(rowProps.index)} />
              {rowProps.index !== events.length - 1 && <HorizontaleScheidingslijn />}
            </div>
          )}
        </CellMeasurer>
      );
    },
    [events, cache],
  );

  if (eventsResult === null) {
    return (
      <div className="d-flex flex-fill align-items-center justify-content-center">
        <LoadingSpinner />
      </div>
    );
  }

  if (events.length === 0) {
    return (
      <div className="d-flex flex-fill align-items-center justify-content-center">
        <span>Er zijn nog geen events geregistreerd voor deze taak.</span>
      </div>
    );
  }

  return (
    <div className="d-flex flex-fill flex-column">
      <AutoSizer>
        {({ width, height }) => (
          <List
            deferredMeasurementCache={cache}
            overscanRowCount={10}
            rowCount={events.length}
            rowHeight={cache.rowHeight}
            width={width}
            height={height}
            rowRenderer={rowRenderer}
          />
        )}
      </AutoSizer>
    </div>
  );
};

export default EventsWeergave;
