import React, { useContext, useMemo } from 'react';
import { IProps } from '@tinymce/tinymce-react/lib/cjs/main/ts/components/Editor';
import { IContext, VoorraadContext } from '../index';
import LoadingSpinner from '../../../Gedeeld/LoadingSpinner';
import {
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import {
  Grid,
  Table,
  TableColumnResizing,
  TableHeaderRow,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import nameOf from '../../../../core/nameOf';
import { IOphalenOpdrachtregelsResultElementV2 } from '../../../../../../shared/src/api/v2/transport/opdracht';
import RelatieVisualisatie from '../../../personalia/RelatieVisualisatie';
import { format } from 'date-fns';

export interface IRegel extends IOphalenOpdrachtregelsResultElementV2 {}

const DetailTab: React.FC<IProps> = (props) => {
  const { type, reserveringen, transportopdrachtregels, transportopdrachten } = useContext<
    IContext
  >(VoorraadContext);

  const kolommen = useMemo<TypedColumn<IRegel>[]>(
    () => [
      {
        name: '__bezoekdatum' as any,
        title: 'Bezoekdatum',
      },
      {
        name: 'RelID',
        title: 'Relatie',
      },
      {
        name: '__soort' as any,
        title: 'Srt.',
      },
      {
        name: '__status' as any,
        title: 'Status',
      },
    ],
    [],
  );

  const kolombreedtes = useMemo<TypedTableColumnWidthInfo<IRegel>[]>(
    () => [
      {
        columnName: 'RelID',
        width: 200,
      },
      {
        columnName: '__bezoekdatum' as any,
        width: 115,
      },
      {
        columnName: '__reservering' as any,
        width: 80,
      },
      {
        columnName: '__soort' as any,
        width: 80,
      },
      {
        columnName: '__status' as any,
        width: 110,
      },
    ],
    [],
  );

  const kolomExtensies = useMemo<Table.ColumnExtension[]>(
    () => [
      // {
      //   columnName: 'Aantal',
      //   width: 60,
      // },
    ],
    [],
  );

  return (
    <>
      <div className="d-flex flex-column flex-fill">
        {transportopdrachtregels === null || transportopdrachten === null ? (
          <div className="d-flex flex-fill align-items-center justify-content-center">
            <LoadingSpinner />
          </div>
        ) : (
          <>
            <GridStyleWrapper maxHeight={500} rowAmount={transportopdrachtregels.length}>
              <Grid rows={transportopdrachtregels} columns={kolommen} getRowId={(x) => x.TrsRegID}>
                <DataTypeProvider
                  for={[nameOf<IRegel>('RelID')]}
                  formatterComponent={(formatterProps) => {
                    const rij: IRegel = formatterProps.row;
                    return <RelatieVisualisatie relID={formatterProps.value} />;
                  }}
                />

                <DataTypeProvider
                  for={['__bezoekdatum']}
                  formatterComponent={(formatterProps) => {
                    const rij: IRegel = formatterProps.row;
                    const opdracht = transportopdrachten.find((x) => x.TrsOpdID === rij.TrsOpdID)!;

                    if (opdracht.Bezoekdatum === null) {
                      return <span>Z.s.m.</span>;
                    }
                    return <span>{format(new Date(opdracht.Bezoekdatum), 'dd-MM-yyyy')}</span>;
                  }}
                />

                <DataTypeProvider
                  for={['__reservering']}
                  formatterComponent={(formatterProps) => {
                    const rij: IRegel = formatterProps.row;

                    return <span>{rij.type!.Gereserveerd ? 'Geres.' : 'IR'}</span>;
                  }}
                />

                <DataTypeProvider
                  for={['__status']}
                  formatterComponent={(formatterProps) => {
                    const rij: IRegel = formatterProps.row;

                    return <span>{rij.status.Naam}</span>;
                  }}
                />

                <DataTypeProvider
                  for={['__soort']}
                  formatterComponent={(formatterProps) => {
                    const rij: IRegel = formatterProps.row;

                    return <span>{rij.regelsoort.NaamKort}</span>;
                  }}
                />

                <Table columnExtensions={kolomExtensies} />
                <TableColumnResizing defaultColumnWidths={kolombreedtes} />
                {/* <TableHeaderRow showSortingControls /> */}
                <TableHeaderRow />
              </Grid>
            </GridStyleWrapper>
          </>
        )}
      </div>
    </>
  );
};

export default DetailTab;
