import React, { ReactEventHandler, useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { IWhatsappBericht, IWhatsappMedia } from '../../../../../../api/services/v1/whatsapp';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../../../models/IRemoteData';
import { useWhatsappV2Store } from '../../../store';
import { format } from 'date-fns';
import { Kleur } from '../../../../../../bedrijfslogica/constanten';
import useBijGewijzigdEffect from '../../../../../../core/useBijGewijzigdEffect';
import MediaWeergave, { IMediaGrootte } from './MediaWeergave';
import {
  CustomData,
  customNaarCustomData,
  datumNaarStringLang,
  formatDatum,
  formatTijd,
  sanitizeBerichtInhoud,
} from '../../../helpers';
import ContactWeergave, { IContactData } from './ContactWeergave';

const Root = styled.div<{ richting: 'I' | 'U' }>`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.richting === 'I' ? 'flex-start' : 'flex-end')};
  width: 100%;
`;

const OngelezenBerichten = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 100%;
  margin-bottom: 10px;
  margin-top: 10px;
  font-weight: bold;
  color: ${Kleur.Grijs};
  font-size: 12px;
`;

const TijdAanduidingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 100%;
  margin-bottom: 10px;
  margin-top: 10px;
`;

const TijdAanduiding = styled.div`
  font-weight: bold;
  color: ${Kleur.Grijs};
  font-size: 12px;
  background-color: rgba(175, 175, 175, 0.2);
  padding: 3px 20px;
  border-radius: 15px;
`;

const Bubbel = styled.div<{ richting: 'I' | 'U' }>`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background-color: ${(props) =>
    props.richting === 'I' ? 'rgb(245, 245, 245)' : 'rgb(220, 248, 198)'};
  max-width: 80%;
  width: fit-content;
  word-wrap: break-word;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.13) 0 1px 0.5px;
  margin-left: 20px;
  margin-right: 20px;
`;

const Onder = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
`;

const Tekst = styled.span`
  font-size: 14px;
  color: ${Kleur.DonkerGrijs};
  flex: 1;

  .geen-tekst {
    color: ${Kleur.Grijs};
    font-size: 13px;
  }
`;

const TijdContainer = styled.div`
  display: flex;
  align-items: end;
`;

const Tijd = styled.span`
  font-size: 11px;
  color: rgb(161, 161, 161);
  margin-left: 10px;
  font-weight: bold;
`;

interface IProps {
  style?: React.CSSProperties;
  onLoad?: ReactEventHandler<HTMLDivElement>;
  isEersteBericht: boolean;
  isLaatsteBericht: boolean;
  bericht: IWhatsappBericht;
  vorigBericht: IRemoteData<IWhatsappBericht | null>;
  onLayoutHerberekenen: () => void;
}

const Bericht = React.forwardRef<HTMLDivElement, IProps>((props: IProps, ref) => {
  const store = useWhatsappV2Store();

  const datum = useMemo(() => new Date(props.bericht.datum), [props.bericht.datum]);

  const media = useMemo<IRemoteData<IWhatsappMedia | null>>(() => {
    if (props.bericht.whatsappMediaId === null) {
      return createReadyRemoteData(null);
    }
    const media = store.mediaBijID[props.bericht.whatsappMediaId];
    if (media === undefined) {
      return createPendingRemoteData();
    }
    return createReadyRemoteData(media);
  }, [props.bericht.whatsappMediaId, store.mediaBijID]);

  const tijdAanduiding = useMemo<string | null>(() => {
    if (props.isEersteBericht) {
      return datumNaarStringLang(datum);
    }
    if (props.vorigBericht.data === null) {
      return null;
    }

    const tekstHuidig = datumNaarStringLang(datum);
    const tekstVorig = datumNaarStringLang(new Date(props.vorigBericht.data!.datum));

    if (tekstHuidig === tekstVorig) {
      return null;
    }
    return tekstHuidig;
  }, [datum, props.vorigBericht.data, props.isEersteBericht]);

  const paddingTop = useMemo(() => {
    const eersteBerichtPadding = tijdAanduiding === null && props.isEersteBericht ? 15 : 0;
    const vorigBerichtPadding =
      tijdAanduiding !== null ||
      props.vorigBericht.state === ERemoteDataState.Pending ||
      props.vorigBericht.data === null ||
      props.vorigBericht.data.richting === props.bericht.richting
        ? 0
        : 10;
    return eersteBerichtPadding + vorigBerichtPadding;
  }, [props.isEersteBericht, props.vorigBericht, props.bericht, tijdAanduiding]);

  const paddingBottom = useMemo(() => {
    return 5 + (props.isLaatsteBericht ? 10 : 0);
  }, [props.isLaatsteBericht]);

  const tekst = useMemo<IRemoteData<string | null>>(() => {
    if (media.state === ERemoteDataState.Pending) {
      return createPendingRemoteData();
    }
    return createReadyRemoteData(
      sanitizeBerichtInhoud(
        media.data !== null ? media.data!.titel : props.bericht.inhoud,
      )?.replace(/\n/g, '<br>') ?? null,
    );
  }, [props.bericht.inhoud, media]);

  const [mediaGrootte, setMediaGrootte] = useState<IMediaGrootte | null>(null);
  const handleMediaGrootteBepaald = useCallback(
    (mediaGrootte: IMediaGrootte) => setMediaGrootte(mediaGrootte),
    [],
  );

  const bubbelMaxWidth = useMemo<number | null>(() => {
    if (mediaGrootte === null) {
      return null;
    }
    const MAX_HEIGHT = 500;
    const height = Math.min(mediaGrootte.height, MAX_HEIGHT);
    const ratio = height / mediaGrootte.height;
    return mediaGrootte.width * ratio + 10;
  }, [mediaGrootte?.width, mediaGrootte?.height]);

  const tekstWeergeven = useMemo(() => props.bericht.custom === null, [props.bericht.custom]);

  const customData = useMemo<CustomData | null>(() => {
    if (props.bericht.custom === null) {
      return null;
    }

    return customNaarCustomData(props.bericht.custom);
  }, [props.bericht.custom]);

  const customDataWeergave = useMemo(() => {
    if (customData === null) {
      return null;
    }

    switch (customData.type) {
      case 'onbekend':
        return (
          <div className="flex-fill d-flex flex-column">
            <span>Onbekend bericht type</span>
            <code>{JSON.stringify(props.bericht.custom)}</code>
          </div>
        );
      case 'contact':
        return <ContactWeergave data={customData.data} />;
    }
  }, [customData]);

  // const verstuurdDoor = useMemo(() => {
  //   if (props.bericht.verstuurdDoorAspGebrId === null) {
  //     return null;
  //   }
  //   return (
  //     <VerstuurdDoorContainer>
  //       <ASPGebruikerVisualisatie
  //         aspGebrID={props.bericht.verstuurdDoorAspGebrId}
  //         modus={EModus.VolledigNaamKort}
  //       />
  //     </VerstuurdDoorContainer>
  //   );
  // }, [props.bericht.verstuurdDoorAspGebrId]);

  useBijGewijzigdEffect(() => {
    props.onLayoutHerberekenen();
  }, [paddingTop, paddingBottom, tekst, bubbelMaxWidth, tijdAanduiding]);

  return (
    <Root
      ref={ref}
      style={{
        ...props.style,
        paddingTop,
        paddingBottom,
      }}
      onLoad={props.onLoad}
      richting={props.bericht.richting}
    >
      {/*<OngelezenBerichten>1 ONGELEZEN BERICHT</OngelezenBerichten>*/}
      {tijdAanduiding !== null && (
        <TijdAanduidingContainer>
          <TijdAanduiding title={formatDatum(datum)}>{tijdAanduiding}</TijdAanduiding>
        </TijdAanduidingContainer>
      )}
      <Bubbel
        richting={props.bericht.richting}
        style={{
          maxWidth: bubbelMaxWidth ?? undefined,
          backgroundColor:
            props.bericht.whatsappBerichttypeId === null ? undefined : 'rgb(255,215,249)',
        }}
      >
        {customDataWeergave}
        {props.bericht.whatsappMediaId !== null && (
          <MediaWeergave
            media={media as IRemoteData<IWhatsappMedia>}
            onLayoutHerberekenen={props.onLayoutHerberekenen}
            onMediaGrootteBepaald={handleMediaGrootteBepaald}
          />
        )}
        <Onder>
          <Tekst>
            {!tekstWeergeven ? (
              <React.Fragment />
            ) : tekst.data === null ? (
              <span className="geen-tekst">Geen tekst opgegeven</span>
            ) : (
              <span
                dangerouslySetInnerHTML={{
                  __html: tekst.data,
                }}
              />
            )}
          </Tekst>
          <TijdContainer>
            <Tijd title={format(datum, 'dd-MM-yyyy HH:mm')}>{formatTijd(datum)}</Tijd>
          </TijdContainer>
        </Onder>
      </Bubbel>
    </Root>
  );
});

export default Bericht;
