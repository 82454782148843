import React, { useContext, useMemo } from 'react';
import { DashboardContext } from '../index';
import TegelBasis from '../../../components/tegel/TegelBasis';
import { ERemoteDataState } from '../../../models/IRemoteData';
import LoadingSpinner from '../../../components/Gedeeld/LoadingSpinner';
import { format } from 'date-fns';
import GegevensLayout from '../../../components/layout/GegevensLayout';
import VeldWeergave from '../../../components/formulier/VeldWeergave';
import nlLocale from 'date-fns/locale/nl';

interface IProps {}

const FinancieleinformatieTegel: React.FC<IProps> = (props) => {
  const context = useContext(DashboardContext);

  const datum = context.financieleInfo.data!.bankmutatiesBijgewerktTot;

  const datumtekst =
    datum !== null
      ? format(new Date(datum), 'EEEE (dd-MM-yyyy)', {
          locale: nlLocale,
        })
      : null;

  return (
    <TegelBasis titel="Bankmutaties">
      <div>
        <GegevensLayout
          gegevens={[
            {
              label: 'Ingelezen t/m',
              waarde: `${
                datumtekst !== null ? datumtekst.charAt(0).toUpperCase() + datumtekst.slice(1) : ''
              }`,
            },
          ].filter((x) => x !== null)}
        />
      </div>
    </TegelBasis>
  );
};

export default FinancieleinformatieTegel;
