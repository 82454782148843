import { makeObservable, observable } from 'mobx';
import { notificatie } from './storeHelpers';
import { RootStore } from './RootStore';
import api from '../api';
import {
  IToevoegenRelatieParams,
  IOphalenRelatiesResultElementV2,
  IRelatieAttributen,
} from '../../../shared/src/api/v2/relatie';
import { IPersoonGewijzigdMessageData } from '../../../shared/src/api/v2/persoon/persoon';

class RelatieStore {
  public rootStore: RootStore;
  public toevoegenConcept: IToevoegenRelatieParams | null = null;
  public adresVoorPostcodeAPI: string | null = null;
  public huisnummerToevoegingSuggesties: string[] | null = null;
  public relaties: { [key: number]: IOphalenRelatiesResultElementV2 } = {};
  public relatieAttributen: { [relID: number]: IRelatieAttributen } = {};
  public relatieOphaalQueue: number[] = [];
  // tslint:disable-next-line:variable-name
  private _relIDQueueTimeout: number | null = null;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    makeObservable(this, {
      toevoegenConcept: observable,
      adresVoorPostcodeAPI: observable,
      relaties: observable,
      relatieOphaalQueue: observable,
      huisnummerToevoegingSuggesties: observable,
      relatieAttributen: observable,
    });
  }

  public opslaanAdresVoorPostcodeAPI = (adres: string) => {
    this.adresVoorPostcodeAPI = adres;
  };

  public conceptOpslaan = (values: IToevoegenRelatieParams) => {
    this.toevoegenConcept = values;
    notificatie('success', 'concept opgeslagen');
  };

  public deleteConcept = () => {
    this.toevoegenConcept = null;
  };

  // public ophalenSuggestiesHuisnummerToevoeging = async (formValues: any) => {
  //   const params = {
  //     postcode: formValues.postcode,
  //     huisnummer: formValues.huisnummer,
  //     landID: formValues.landID,
  //   };

  //   try {
  //     const resultaat: IOphalenHuisnummerToevoegingenResult = await api.v2.derden.ophalenHuisnummerToevoegingen(
  //       params,
  //     );
  //     this.huisnummerToevoegingSuggesties = resultaat;
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  public toevoegenRelatie = async (formValues: any) => {
    const params: IToevoegenRelatieParams = {
      ...formValues,
      huisnummer: formValues.huisnummer.length === 0 ? null : formValues.huisnummer,
    };
    try {
      return await api.v2.relatie.toevoegenRelatie(params);
    } catch (err) {
      throw err;
    }
  };

  public handlePersoonGewijzigd = async (msg: IPersoonGewijzigdMessageData) => {};

  public enqueueRelatieOphalen = (relID: number) => {
    this.relatieOphaalQueue = [...this.relatieOphaalQueue, relID];
    if (this._relIDQueueTimeout !== null) {
      clearTimeout(this._relIDQueueTimeout);
    }
    // @ts-ignore
    this._relIDQueueTimeout = setTimeout(this.relatiesOphalen, 300);
  };

  private relatiesOphalen = async () => {
    if (this.relatieOphaalQueue.length === 0) {
      return;
    }
    const queue = [...this.relatieOphaalQueue];
    this.relatieOphaalQueue = [];

    const ophalenRelaties = async () => {
      const result = await api.v2.relatie.ophalenRelaties({
        filterSchema: {
          filters: [{ naam: 'IDS', data: [queue] }],
        },
      });
      const relaties = { ...this.relaties };
      result.relaties.forEach((relatie) => {
        relaties[relatie.RelID] = relatie;
      });
      this.relaties = relaties;
    };

    const ophalenRelatieAttributen = async () => {
      const result = await api.v2.relatie.ophalenRelatieAttributen({
        relIDs: queue,
      });
      this.relatieAttributen = {
        ...this.relatieAttributen,
        ...result.attributen,
      };
    };

    await Promise.all([ophalenRelaties(), ophalenRelatieAttributen()]);
  };
}

export default RelatieStore;
