import React, { useContext, useEffect, useMemo, useState, useCallback } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { observer } from 'mobx-react-lite';
import api from '../../../api';
import { Kleur as EKleur } from '../../../bedrijfslogica/constanten';
import useUrlState from '../../../core/useUrlState';
import { RootStoreContext } from '../../../stores/RootStore';
import _ from 'lodash';
import { Helmet } from 'react-helmet';
import { IOphalenIndexeringenResultElement } from '../../../../../shared/src/api/v2/indexering';
import MenuLayout from '../../../components/MenuLayout';
import ASPTabel from '../../../components/tabel/ASPTabel';
import LoadingSpinner from '../../../components/Gedeeld/LoadingSpinner';
import { ASPKolom, EAspKolomBreedteType } from '../../../components/tabel/ASPTabel/types';
import { format } from 'date-fns';
import ToevoegenDialoog from './ToevoegenDialoog';
import FormatteerBedrag from '../../../components/MutatieBedrag';
import { EBtwsoort } from '../../../bedrijfslogica/enums';
import { EResultType } from '../../../stores/CheckStore';
import { IconEmail, IconHerstel, IconToevoegen } from '../../../components/Icons';
import { IOphalenBtwTarievenResultElement } from '../../../../../shared/src/api/v2/btw';

interface IProps extends RouteComponentProps {}

interface IToevoegenDialoogState {}

interface IUrlState {
  selectie: number[];
  toevoegenDialoogState: IToevoegenDialoogState | null;
}

const defaultUrlState: IUrlState = {
  selectie: [],
  toevoegenDialoogState: null,
};

enum EKolom {
  Indexeringsdatum,
  Percentage,
  VerwerktOp,
  AantalContracten,
  TotaalGeindexeerdBedrag,
  TotaalGeindexeerdBedragExcl,
}

export interface IRegel extends IOphalenIndexeringenResultElement {}

const Indexeringen: React.FC<IProps> = observer((props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);

  const { checkStore } = useContext(RootStoreContext);
  const [indexeringen, setIndexeringen] = useState<IRegel[] | null>(null);

  const [btwTarieven, setBtwTarieven] = useState<IOphalenBtwTarievenResultElement[] | null>(null);

  const keyExtractor = useCallback((row: IRegel) => row.ID, []);

  const ophalenIndexeringen = useCallback(async () => {
    const indexeringenResult = (
      await api.v2.indexering.ophalenIndexeringen({
        orderSchema: { orders: [{ naam: 'INDEXERINGSDATUM', richting: 'DESC' }] },
      })
    ).indexeringen;

    setIndexeringen(indexeringenResult);
  }, []);

  useEffect(() => {
    ophalenIndexeringen();
  }, [ophalenIndexeringen]);

  const ophalenBtwTarief = useCallback(async () => {
    const btwTarievenResult = await api.v2.btw.ophalenBtwTarieven({
      filterSchema: {
        filters: [
          { naam: 'NAAM_ENUM', data: [EBtwsoort.AfdragenHoog] },
          // { naam: 'PEILDATUM', data: null },
        ],
      },
    });
    setBtwTarieven(btwTarievenResult.btwTarieven);
  }, []);

  useEffect(() => {
    ophalenBtwTarief();
  }, [ophalenBtwTarief]);

  const handleVerwijderen = useCallback(async (rij: IRegel) => {
    const params = { indexeringIDs: [rij.ID] };

    await api.v2.indexering.verwijderenIndexeringen(params);

    setUrlStateSync('selectie', []);

    ophalenIndexeringen();
  }, []);

  const verwijderenRijConfirmatie = useCallback(
    async (rij: IRegel, verwijderen: () => Promise<void>) => {
      const params = { indexeringIDs: [rij.ID] };
      const checkData = await api.v2.indexering.checkVerwijderenIndexeringen(params);
      if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
        return;
      }

      if (
        (
          await checkStore.bevestigen({
            inhoud: `Wil je de indexering verwijderen?`,
          })
        ).type === EResultType.Annuleren
      ) {
        return;
      }

      await verwijderen();
    },
    [],
  );

  const kolommen = useMemo<ASPKolom<EKolom, IRegel>[]>(
    () => [
      {
        key: EKolom.Indexeringsdatum,
        label: 'Indexeringsdatum',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 150,
        renderer: (item) => format(new Date(item.Indexeringsdatum), 'dd-MM-yyyy'),
      },
      {
        key: EKolom.Percentage,
        label: 'Percentage',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 115,
        renderer: (item) => item.Percentage,
      },
      {
        key: EKolom.AantalContracten,
        label: 'Aantal contracten',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 150,
        renderer: (item) => item.contracten.length,
      },
      {
        key: EKolom.TotaalGeindexeerdBedrag,
        label: 'Totaal geindexeerd',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 150,
        renderer: (item) => {
          const bedrag = _.sum(item.contracten.map((x) => x.MaandhuurNieuw - x.MaandhuurOud));
          return <FormatteerBedrag bedrag={bedrag} />;
        },
      },
      {
        key: EKolom.TotaalGeindexeerdBedragExcl,
        label: 'Excl. btw',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 150,
        renderer: (item) => {
          if (btwTarieven === null) {
            return <span></span>;
          }

          const bedrag = _.sum(item.contracten.map((x) => x.MaandhuurNieuw - x.MaandhuurOud));

          const btwtarief = btwTarieven.find(
            (x) =>
              x.Ingangsdatum <= item.Indexeringsdatum &&
              (x.Einddatum === null || x.Einddatum > item.Indexeringsdatum),
          );
          const bedragExcl = bedrag / (1 + btwtarief!.Percentage / 100);

          return <FormatteerBedrag bedrag={bedragExcl} />;
        },
      },
      {
        key: EKolom.VerwerktOp,
        label: 'Verwerkt op',
        breedteType: EAspKolomBreedteType.Flex,
        flex: 1,
        renderer: (item) =>
          item.VerwerktOp !== null ? (
            format(new Date(item.VerwerktOp), 'dd-MM-yyyy HH:mm')
          ) : (
            <span style={{ color: EKleur.Blauw }}>Nog niet</span>
          ),
      },
    ],
    [btwTarieven],
  );

  if (indexeringen === null) {
    return (
      <div className="d-flex flex-fill justify-content-center align-items-center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Indexeringen</title>
      </Helmet>
      <MenuLayout
        menu={
          <>
            <div className="mt-2 d-flex align-items-center">
              <button
                className="btn btn-sm btn-light d-flex align-items-center"
                style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                // disabled={urlState.selection.length === 0}
                onClick={async () => {
                  setUrlStateSync('toevoegenDialoogState', {});
                }}
              >
                <IconToevoegen style={{ width: 16, height: 16, fill: EKleur.Grijs }} />
                <span className="ml-2">Nieuwe indexering</span>
              </button>

              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-3"
                style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                disabled={urlState.selectie.length !== 1}
                onClick={async () => {
                  const params = { indexeringID: urlState.selectie[0] };
                  const checkData = await api.v2.indexering.checkVerwerkenIndexering(params);

                  if (
                    (await checkStore.controleren({ checkData })).type === EResultType.Annuleren
                  ) {
                    return;
                  }
                  if (
                    (
                      await checkStore.bevestigen({
                        inhoud: (
                          <span>
                            Wil je de geselecteerde indexering verwerken?
                            <br />
                            <br />
                            Hiermee wordt de nieuwe maandhuur bij de contracten vastgelegd.
                          </span>
                        ),
                      })
                    ).type === EResultType.Annuleren
                  ) {
                    return;
                  }

                  await api.v2.indexering.verwerkenIndexering(params);
                  ophalenIndexeringen();
                }}
              >
                {/* <IconVerwerken style={{ width: 16, height: 16, fill: Kleur.Grijs }} /> */}
                <span className="ml-2">Verwerken indexering</span>
              </button>

              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-3"
                style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                disabled={urlState.selectie.length !== 1}
                onClick={async () => {
                  const params = { indexeringID: urlState.selectie[0] };
                  const checkData = await api.v2.indexering.checkOngedaanMakenVerwerkenIndexering(
                    params,
                  );

                  if (
                    (await checkStore.controleren({ checkData })).type === EResultType.Annuleren
                  ) {
                    return;
                  }
                  if (
                    (
                      await checkStore.bevestigen({
                        inhoud: (
                          <span>
                            Wil je de geselecteerde verwerking terugdraaien?
                            <br />
                            <br />
                            De maandhuur mutaties zullen bij de contracten verwijderd worden.
                          </span>
                        ),
                      })
                    ).type === EResultType.Annuleren
                  ) {
                    return;
                  }

                  await api.v2.indexering.ongedaanMakenVerwerkenIndexering(params);
                  ophalenIndexeringen();
                }}
              >
                {/* <IconVerwerken style={{ width: 16, height: 16, fill: Kleur.Grijs }} /> */}
                <span className="ml-2">Verwerking ongedaan maken</span>
              </button>

              {/* <button
                className="btn btn-sm btn-light d-flex align-items-center ml-3"
                style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                disabled={urlState.selectie.length !== 1}
                onClick={async () => {
                  const params = { indexeringID: urlState.selectie[0] };
                  const checkData = await api.v2.indexering.checkMakenIndexeringsberichten(params);

                  if (
                    (await checkStore.controleren({ checkData })).type === EResultType.Annuleren
                  ) {
                    return;
                  }
                  if (
                    (
                      await checkStore.bevestigen({
                        inhoud: (
                          <span>
                            Wil je voor de geselecteerde indexering indexeringsberichten maken?
                            <br />
                            <br />
                            De berichten worden niet direct verstuurd, dit dient bij de
                            Bulkberichten te gebeuren
                          </span>
                        ),
                      })
                    ).type === EResultType.Annuleren
                  ) {
                    return;
                  }

                  await api.v2.indexering.checkMakenIndexeringsberichten(params);
                  ophalenIndexeringen();
                }}
              >
                <span className="ml-2">Indexeringsberichten maken</span>
              </button>

              {/* <FilterBalkV2
                filters={filters}
                filterData={urlState.filterdata}
                onFilterDataChange={(x) => setUrlStateSync('filterdata', x)}
                onFilterSchemaChange={setFilterSchema}
              /> */}
            </div>
          </>
        }
        body={
          <ASPTabel
            rijen={indexeringen}
            kolommen={kolommen}
            keyExtractor={keyExtractor}
            selectie={urlState.selectie}
            onSelectieChange={(selectie) => setUrlStateSync('selectie', selectie)}
            // sortering={urlState.sortering}
            // onSorteringChange={(sortering) => setUrlStateSync('sortering', sortering)}
            // onWijzigenRij={handleWijzigenRij}
            onVerwijderenRij={handleVerwijderen}
            verwijderenRijConfirmatie={verwijderenRijConfirmatie}
            // rijHoogte={200}
            leegComponent={(leegProps) => (
              <div
                style={{
                  width: leegProps.width,
                  height: leegProps.height,
                }}
                className="d-flex align-items-center justify-content-center"
              >
                <h4>Geen indexeringen gevonden</h4>
              </div>
            )}
          />
        }
      />
      {urlState.toevoegenDialoogState !== null && (
        <ToevoegenDialoog
          open
          onSuccess={() => {
            ophalenIndexeringen();
            setUrlStateSync('toevoegenDialoogState', null);
          }}
          onAnnuleren={() => [setUrlStateSync('toevoegenDialoogState', null)]}
        />
      )}
    </>
  );
});

export default withRouter(Indexeringen);
