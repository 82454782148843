import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import IDialoogProps from '../../../../core/IDialoogProps';
import Dialoog from '../../../dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import { ModalBody, ModalFooter, ModalTitle } from 'react-bootstrap';
import AdresFormulier, { IValues as IAdresValues } from '../../../formulier/AdresFormulier';
import api from '../../../../api';
import { IOphalenBetaaldagenResult } from '../../../../../../shared/src/api/v2/debiteur';
import { Field, FieldProps, Formik, FormikActions, FormikErrors, FormikProps } from 'formik';
import LoadingSpinner from '../../../Gedeeld/LoadingSpinner';
import nameof from '../../../../core/nameOf';
import ContactSelectie, { EType } from '../../../formulier/ContactSelectie';
import FormikVeldFout from '../../../formulier/FormikVeldFout';
import Combobox, { IOptie } from '../../../formulier/Combobox';
import IbanVeld from '../../../formulier/IbanVeld';
import VinkVeld from '../../../formulier/VinkVeld';
import teksten from '../../../../bedrijfslogica/teksten';
import { IAdres, IToevoegenRelatieParams } from '../../../../../../shared/src/api/v2/relatie';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../../stores/RootStore';
import { EResultType } from '../../../../stores/CheckStore';
import { IOphalenRechtvormenResultElement } from '../../../../../../shared/src/api/v2/website/basis';
import UitklapFormulier from '../../../formulier/UitklapFormulier';
import { IGevalideerdFormulierWaarde } from '../../../formulier/helpers';

interface IDialoogResult {
  relID: number;
}

interface IProps extends IDialoogProps<IDialoogResult> {}

interface IFormikValues {
  adres: IAdresValues | null;
  iban: IGevalideerdFormulierWaarde<string>;
  tenaamstelling: string;
  isOrganisatie: boolean;
  organisatie: {
    naam: string;
    naamkort: string;
    kvk: string;
    btw: string;
    rechtsvormID: number | null;
  };
}

const NieuweLeverancierDialoog = observer((props: IProps) => {
  const { checkStore } = useContext(RootStoreContext);
  const formikRef = useRef<Formik<IFormikValues>>(null);
  const [betaaldagen, setBetaaldagen] = useState<IOphalenBetaaldagenResult | null>(null);
  const [rechtsvormen, setRechtsvormen] = useState<IOphalenRechtvormenResultElement[] | null>(null);
  const [detailsWeergeven, setDetailsWeergeven] = useState<boolean>(false);

  const ophalenBetaaldagen = useCallback(async () => {
    const resultaat = await api.v2.debiteur.ophalenBetaaldagen({});
    setBetaaldagen(resultaat);
  }, []);

  const ophalenRechtsvormen = useCallback(async () => {
    const resultaat = await api.v2.relatie.ophalenRechtsvormen();
    setRechtsvormen(resultaat);
  }, []);

  useEffect(() => {
    ophalenBetaaldagen();
  }, [ophalenBetaaldagen]);

  useEffect(() => {
    ophalenRechtsvormen();
  }, [ophalenRechtsvormen]);

  const initialValues: IFormikValues | null = useMemo(() => {
    if (betaaldagen === null || rechtsvormen === null) {
      return null;
    }

    return {
      adres: null,
      iban: {
        isValide: false,
        waarde: '',
      },
      tenaamstelling: '',
      isOrganisatie: true,
      organisatie: {
        naam: '',
        naamkort: '',
        kvk: '',
        btw: '',
        rechtsvormID: rechtsvormen.length > 0 ? rechtsvormen[0].ID : null,
      },
    };
  }, [betaaldagen, rechtsvormen]);

  const rechtsvormenOpties: IOptie<number>[] | null = useMemo(() => {
    if (rechtsvormen === null) {
      return null;
    }

    return rechtsvormen.map(
      (rechtsvorm): IOptie<number> => {
        return {
          id: rechtsvorm.ID,
          label: rechtsvorm.Naam,
        };
      },
    );
  }, [rechtsvormen]);

  const handleValidate = useCallback((values: IFormikValues) => {
    const errors: FormikErrors<IFormikValues> = {};

    // if (values.adres === null) {
    //   errors.adres = teksten.formulier.E_VERPLICHT_VELD({ veldnaam: 'Adres' });
    // }

    if (values.iban.waarde.length > 0 && !values.iban.isValide) {
      errors.iban = teksten.formulier.E_VOLDOET_NIET_AAN_FORMAAT({ veldnaam: 'IBAN' }) as any;
    }

    if (values.iban.waarde.length > 0 || values.tenaamstelling.trim().length !== 0) {
      if (values.iban.waarde.length === 0) {
        errors.iban = teksten.formulier.E_VERPLICHT_VELD({ veldnaam: 'IBAN' }) as any;
      }
      if (values.tenaamstelling.trim().length === 0) {
        errors.tenaamstelling = teksten.formulier.E_VERPLICHT_VELD({
          veldnaam: 'Tenaamstelling',
        });
      }
    }

    if (values.isOrganisatie) {
      errors.organisatie = {};

      if (values.organisatie.naam.trim().length === 0) {
        errors.organisatie.naam = teksten.formulier.E_VERPLICHT_VELD({
          veldnaam: 'Tenaamstelling',
        });
      }

      if (values.organisatie.naamkort.trim().length === 0) {
        errors.organisatie.naamkort = teksten.formulier.E_VERPLICHT_VELD({ veldnaam: 'naamkort' });
      }

      // if (values.organisatie.kvk.trim().length === 0) {
      //   errors.organisatie.kvk = teksten.formulier.E_VERPLICHT_VELD({ veldnaam: 'kvk' });
      // }
      //
      // if (values.organisatie.btw.trim().length === 0) {
      //   errors.organisatie.btw = teksten.formulier.E_VERPLICHT_VELD({ veldnaam: 'btw' });
      // }

      if (values.organisatie.rechtsvormID === null) {
        errors.organisatie.rechtsvormID = teksten.formulier.E_VERPLICHT_VELD({
          veldnaam: 'Rechtsvorm',
        });
      }

      if (Object.keys(errors.organisatie).length === 0) {
        delete errors.organisatie;
      }
    }

    return errors;
  }, []);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      const validatieResultaat = await actions.validateForm(values);
      console.log(validatieResultaat);

      if (Object.keys(validatieResultaat).length > 0) {
        actions.setSubmitting(false);
        return;
      }

      const adres: IAdres | undefined =
        values.adres === null
          ? undefined
          : {
              straatnaam: values.adres!.straatnaam,
              plaatsnaam: values.adres!.plaatsnaam,
              postcode: values.adres!.postcode,
              huisnummer: values.adres!.huisnummer,
              bisnummer: values.adres!.bisnummer,
              landID: values.adres!.landID,
            };

      const params: IToevoegenRelatieParams = {
        gebruikersnaam: null,
        relatiesoort: values.isOrganisatie ? 'O' : 'P',
        hoedanigheid: 'LEVERANCIER',
        organisatie: values.isOrganisatie
          ? {
              btwnummer:
                values.organisatie.btw.trim().length === 0 ? null : values.organisatie.btw.trim(),
              kvknummer:
                values.organisatie.kvk.trim().length === 0 ? null : values.organisatie.kvk.trim(),
              naam: values.organisatie.naam.trim(),
              naamKort: values.organisatie.naamkort.trim(),
              rechtsvormID: values.organisatie.rechtsvormID!,
            }
          : null,
        rekening:
          values.iban.waarde.length > 0
            ? {
                iban: values.iban!.waarde,
                naam: values.tenaamstelling,
              }
            : null,
        incassomachtiging: false,
        adres,
      };

      const checkData = await api.v2.relatie.checkToevoegenRelatie(params);

      if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
        actions.setSubmitting(false);
        return;
      }

      if (
        (
          await checkStore.bevestigen({
            inhoud: 'Nieuwe leverancier vastleggen?',
          })
        ).type === EResultType.Annuleren
      ) {
        actions.setSubmitting(false);
        return;
      }

      const resultaat = await api.v2.relatie.toevoegenRelatie(params);

      props.onSuccess({
        relID: resultaat.relID,
      });

      actions.setSubmitting(false);
    },
    [props.onSuccess],
  );

  return (
    <Dialoog index={props.dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Nieuwe leverancier</ModalTitle>
      </ModalHeader>
      {initialValues === null ? (
        <LoadingSpinner />
      ) : (
        <Formik
          ref={formikRef}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validate={handleValidate}
          render={(formikProps: FormikProps<IFormikValues>) => {
            const { values, isSubmitting, isValid, submitForm, setFieldValue } = formikProps;

            return (
              <>
                <ModalBody>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-12">
                        <label>Naam</label>
                        <Field
                          name={'organisatie.naam'}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field } = fieldProps;

                            return (
                              <>
                                <input type="text" className="form-control" {...field} />
                                <FormikVeldFout fieldProps={fieldProps} />
                              </>
                            );
                          }}
                        />
                      </div>
                      <div className="col-12 mt-3">
                        <label>Naam kort</label>
                        <Field
                          name={'organisatie.naamkort'}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field } = fieldProps;

                            return (
                              <>
                                <input type="text" className="form-control" {...field} />
                                <FormikVeldFout fieldProps={fieldProps} />
                              </>
                            );
                          }}
                        />
                      </div>
                      <div className="col-12 mt-3">
                        <label>Kvk-nummer</label>
                        <Field
                          name={'organisatie.kvk'}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field } = fieldProps;

                            return (
                              <>
                                <input type="text" className="form-control" {...field} />
                                <FormikVeldFout fieldProps={fieldProps} />
                              </>
                            );
                          }}
                        />
                      </div>
                      <div className="col-12 mt-3">
                        <label>BTW-nummer</label>
                        <Field
                          name={'organisatie.btw'}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field } = fieldProps;

                            return (
                              <>
                                <input type="text" className="form-control" {...field} />
                                <FormikVeldFout fieldProps={fieldProps} />
                              </>
                            );
                          }}
                        />
                      </div>
                      <div className="col-12 mt-2">
                        <label>Rechtsvorm</label>
                        <Field
                          name={'organisatie.rechtsvormID'}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            return (
                              <>
                                <Combobox
                                  geselecteerd={field.value}
                                  opties={rechtsvormenOpties!}
                                  onSelectieChange={(x) => form.setFieldValue(field.name, x)}
                                />
                                <FormikVeldFout fieldProps={fieldProps} />
                              </>
                            );
                          }}
                        />
                      </div>
                    </div>

                    <div className="mt-3">
                      <UitklapFormulier
                        uitgeklapt={detailsWeergeven}
                        onUitgeklaptChange={(uitgeklapt) => setDetailsWeergeven(uitgeklapt)}
                        label="Details"
                        formulier={
                          <div className="row">
                            <div className="col-12">
                              <Field
                                name={nameof<IFormikValues>('adres')}
                                render={(fieldProps: FieldProps<IFormikValues>) => {
                                  const { field, form } = fieldProps;
                                  return (
                                    <>
                                      <AdresFormulier
                                        values={field.value}
                                        onChange={(x) => form.setFieldValue(field.name, x)}
                                      />
                                      <FormikVeldFout fieldProps={fieldProps} />
                                    </>
                                  );
                                }}
                              />
                            </div>
                            <div className="col-6 mt-3">
                              <label>IBAN</label>
                              <Field
                                name={nameof<IFormikValues>('iban')}
                                render={(fieldProps: FieldProps<IFormikValues>) => {
                                  const { field, form } = fieldProps;

                                  return (
                                    <>
                                      <IbanVeld
                                        iban={field.value}
                                        onChange={(x) => form.setFieldValue(field.name, x)}
                                      />
                                      <FormikVeldFout
                                        fieldProps={fieldProps}
                                        directTonen
                                        options={{ objectAlsVeldBeschouwen: true }}
                                      />
                                    </>
                                  );
                                }}
                              />
                            </div>
                            <div className="col-6 mt-3">
                              <label>Tenaamstelling</label>
                              <Field
                                name={nameof<IFormikValues>('tenaamstelling')}
                                render={(fieldProps: FieldProps<IFormikValues>) => {
                                  const { field } = fieldProps;

                                  return (
                                    <>
                                      <input type="text" className="form-control" {...field} />
                                      <FormikVeldFout fieldProps={fieldProps} />
                                    </>
                                  );
                                }}
                              />
                            </div>
                          </div>
                        }
                      />
                    </div>
                  </div>
                </ModalBody>
                <ModalFooter className="d-flex flex-row justify-content-start">
                  <button
                    className="btn btn-primary"
                    disabled={isSubmitting}
                    onClick={submitForm}
                    style={{ width: 100 }}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    disabled={isSubmitting}
                    onClick={props.onAnnuleren}
                    style={{ width: 100 }}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Dialoog>
  );
});

export default NieuweLeverancierDialoog;
