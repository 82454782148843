import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Root } from './style';
import { ISectieProps } from '../index';
import api from '../../../../../../../../api';
import { IOphalenLoggingResultElement } from '../../../../../../../../../../shared/src/api/v2/relatie/account';
import LoadingSpinner from '../../../../../../../../components/Gedeeld/LoadingSpinner';
import {
  DXTableCheckboxComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../../../../../helpers/dxTableGrid';
import {
  Grid,
  Table,
  VirtualTable,
  TableColumnResizing,
  TableHeaderRow,
  TableSelection,
} from '@devexpress/dx-react-grid-bootstrap4';
import { DataTypeProvider, SelectionState } from '@devexpress/dx-react-grid';
import { format } from 'date-fns';
import { EAccLogSoort } from '../../../../../../../../bedrijfslogica/enums';

interface IRow {
  id: number;
  datum: Date;
  soort: number;
}

interface IProps extends ISectieProps {}

const Logging: React.FC<IProps> = (props) => {
  const { accID } = props;

  const [logging, setLogging] = useState<IOphalenLoggingResultElement[] | null>(null);

  useEffect(() => {
    (async () => {
      const result = await api.v2.account.ophalenLogging({
        filterSchema: { filters: [{ naam: 'ACC_IDS', data: [accID] }] },
      });
      setLogging(result.logging);
    })();
  }, [accID]);

  const rows = useMemo<IRow[] | null>(() => {
    if (logging === null) {
      return null;
    }

    return logging.map((x) => ({
      id: x.ID,
      datum: new Date(x.Registratiedatum),
      soort: x.Soort,
    }));
  }, [logging]);

  const keyExtractor = useCallback((row: IRow) => row.id, []);

  const kolommen = useMemo<TypedColumn<IRow>[]>(() => {
    return [
      {
        name: 'datum',
        title: 'Datum',
      },
      {
        name: '__soort' as any,
        title: 'Soort',
      },
    ];
  }, []);

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRow>[]>(() => {
    return [
      {
        columnName: 'datum',
        width: 165,
      },
      {
        columnName: '__soort' as any,
        width: 500,
      },
    ];
  }, []);

  return (
    <Root>
      {rows === null ? (
        <LoadingSpinner />
      ) : (
        <GridStyleWrapper maxHeight={500} rowAmount={rows.length}>
          <Grid getRowId={keyExtractor} rows={rows} columns={kolommen}>
            <DataTypeProvider
              for={['datum']}
              formatterComponent={(formatterProps) => {
                const row: IRow = formatterProps.row;

                return <span>{format(row.datum, 'dd-MM-yyyy HH:mm:ss')}</span>;
              }}
            />

            <DataTypeProvider
              for={['__soort']}
              formatterComponent={(formatterProps) => {
                const row: IRow = formatterProps.row;

                let soort;
                switch (row.soort) {
                  case EAccLogSoort.Inloggen:
                    soort = 'Ingelogd';
                    break;
                  case EAccLogSoort.Activeren:
                    soort = 'Geactiveerd';
                    break;
                  case EAccLogSoort.NieuwWachtwoord:
                    soort = 'Nieuw wachtwoord';
                    break;
                  case EAccLogSoort.Uitloggen:
                    soort = 'Uitgelogd';
                    break;
                  case EAccLogSoort.WachtwoordGewijzigd:
                    soort = 'Wachtwoord gewijzigd';
                    break;
                  case EAccLogSoort.WachtwoordVergeten:
                    soort = 'Wachtwoord vergeten';
                    break;
                  default:
                    soort = '';
                    break;
                }

                return <span>{soort}</span>;
              }}
            />

            <Table />
            <TableColumnResizing />
            <TableHeaderRow />
            <TableColumnResizing defaultColumnWidths={kolomBreedtes as any} />
          </Grid>
        </GridStyleWrapper>
      )}
    </Root>
  );
};

export default Logging;
