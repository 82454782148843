import React, { useContext, useMemo, useState } from 'react';
import { IProps } from '@tinymce/tinymce-react/lib/cjs/main/ts/components/Editor';
import { IContext, FactuurContext } from '../index';
import VeldWeergave from '../../../formulier/VeldWeergave';
import LoadingSpinner from '../../../Gedeeld/LoadingSpinner';
import { format } from 'date-fns';
import GegevensLayout from '../../../layout/GegevensLayout';
import FormatteerBedrag from '../../../MutatieBedrag';
import {
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import nameOf from '../../../../core/nameOf';
import { Kleur as EKleur } from '../../../../bedrijfslogica/constanten';
import { IconInformatie } from '../../../Icons';
import WeergavetabelIncassozaken from '../../../incassozaak/Weergavetabel';
import WeergavetabelHerincassozaken from '../../../herincassozaak/Weergavetabel';
import WeergavetabelRechtzaken from '../../../rechtzaak/Weergavetabel';

const ZakenTab: React.FC<IProps> = (props) => {
  const { incassozaken, herincassozaken, rechtzaken } = useContext<IContext>(FactuurContext);

  if (incassozaken === null || herincassozaken === null || rechtzaken === null) {
    return (
      <div className="flex-fill d-flex align-items-center justify-content-center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="d-flex flex-column flex-fill" style={{ overflowY: 'auto', minHeight: 700 }}>
      <div className="mt-2 pl-3 pr-3 pt-2 mb-4">
        <WeergavetabelIncassozaken incassozaken={incassozaken} />
      </div>
      <div className="mt-2 pl-3 pr-3 pt-2 mb-4">
        <WeergavetabelHerincassozaken herincassozaken={herincassozaken} />
      </div>
      <div className="mt-2 pl-3 pr-3 pt-2 mb-4">
        <WeergavetabelRechtzaken rechtzaken={rechtzaken} />
      </div>
    </div>
  );
};

export default ZakenTab;
