import { IBodyProps } from '../../../tabel/ASPTabel/Body';
import * as React from 'react';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { useContext } from 'react';
import { TabelWeergaveContext } from '..';

const BodyComponent = (props: IBodyProps) => {
  const { onBestandenDrop } = useContext(TabelWeergaveContext);

  const [{ isDropActive }, drop] = useDrop(
    () => ({
      accept: NativeTypes.FILE,
      collect: (monitor) => ({
        isDropActive: monitor.canDrop() && monitor.isOver(),
      }),
      canDrop: (object, monitor) => {
        return true;
      },
      async drop(object, monitor) {
        const monitorId = monitor.getHandlerId()! as string;
        const n = Number(monitorId.split('T')[1]);
        if (n % 2 === 0) {
          // Is oneven, dus tweede keer, lelijke workaround
          return;
        }

        const files = (object as any).files as File[];
        await onBestandenDrop(files);
      },
    }),
    [onBestandenDrop],
  );

  return (
    <div ref={drop} style={{ width: props.width, height: props.height }}>
      {props.children}
    </div>
  );
};

export default BodyComponent;
