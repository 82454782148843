import React, { useContext, useEffect, useMemo, useState, useCallback } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { observer } from 'mobx-react-lite';
import api from '../../../../api';
import { IOphalenFacturenBasisResultElement } from '../../../../../../shared/src/api/v2/factuur';
import {
  DXTableCheckboxComponent,
  DXTableToggleCellComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import { RowDetailState, SelectionState } from '@devexpress/dx-react-grid';
import {
  DataTypeProvider,
  IntegratedFiltering,
  IntegratedSorting,
  SearchState,
  SortingState,
} from '@devexpress/dx-react-grid';
import FormatteerBedrag from '../../../../components/MutatieBedrag';
import { addDays, addMonths, format } from 'date-fns';
import FactuurregelsComp from '../Factuurregels';
import RelatieVisualisatie from '../../../../components/personalia/RelatieVisualisatie';
import { ITabbladProps } from '../index';
import SelectieVak from '../../../../components/SelectieVak';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import { RootStoreContext } from '../../../../stores/RootStore';
import { EResultType } from '../../../../stores/CheckStore';
import VinkVeld from '../../../../components/formulier/VinkVeld';
import { IFilterSchema, IFilterSchemaFilter } from '../../../../../../shared/src/models/filter';
import FilterBalkV2, {
  IFilter,
  IFilterData,
  maakFilterSchema,
} from '../../../../components/FilterBalkV2';
import DatumKiezer from '../../../../components/formulier/DatumKiezer';
import useUrlState from '../../../../core/useUrlState';
import { Helmet } from 'react-helmet';

interface IProps extends RouteComponentProps, ITabbladProps {}

export enum EFilter {
  Factuurdatum = 'FACTUURDATUM',
}

interface IUrlState {
  filterData: IFilterData<EFilter>[];
}

const defaultUrlState: IUrlState = {
  filterData: [
    {
      naam: EFilter.Factuurdatum,
      data: false,
      isActief: true,
    },
  ],
};

const Definitief: React.FC<IProps> = observer((props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);

  const [facturen, setFacturen] = useState<IOphalenFacturenBasisResultElement[] | null>(null);
  const keyExtractor = useCallback((row: IOphalenFacturenBasisResultElement) => row.FactID, []);
  const { checkStore } = useContext(RootStoreContext);
  const [filterProlongatieFacturen, setFilterProlongatieFacturen] = useState<boolean>(true);

  // const filters = useMemo<IFilter<EFilter>[]>(
  //   () => [
  //     {
  //       naam: EFilter.Factuurdatum,
  //       altijdWeergevenInBalk: true,
  //       weergave: (weergaveProps) => {
  //         const waarde = weergaveProps.data === null ? null : new Date(weergaveProps.data);
  //         return (
  //           <div className="d-flex align-items-center">
  //             <span className="mr-2">Factuurdatum</span>
  //             <DatumKiezer
  //               waarde={waarde}
  //               onGewijzigd={(date) => {
  //                 weergaveProps.onDataChange(date === null ? null : date.toISOString());
  //                 weergaveProps.toepassen();
  //               }}
  //             />
  //           </div>
  //         );
  //       },
  //     },
  //   ],
  //   [],
  // );

  // const [filterSchema, setFilterSchema] = useState<IFilterSchema>(
  //   useMemo(() => {
  //     return maakFilterSchema(urlState.filterData);
  //   }, []),
  // );

  const ophalenFacturen = useCallback(async () => {
    const datum = format(addDays(new Date(), -7), 'yyyy-MM-dd');

    const { facturen } = await api.v2.factuur.ophalenFacturenBasis({
      filterSchema: {
        filters: [
          { naam: 'DEFINITIEF', data: true },
          { naam: 'FACTUURDATUM_VANAF', data: datum },
          // filterProlongatieFacturen ? { naam: 'PROLONGATIEFACTUUR', data: true } : null,
        ].filter((x) => x !== null) as IFilterSchemaFilter[],
      },
      orderSchema: {
        orders: [
          { naam: 'FACTUURDATUM', richting: 'DESC' },
          { naam: 'FACTUURNUMMER', richting: 'DESC' },
        ],
      },
      paginatie: {
        index: 0,
        aantal: 7500,
      },
    });

    setFacturen(facturen);
  }, [filterProlongatieFacturen]);

  useEffect(() => {
    ophalenFacturen();
  }, [ophalenFacturen]);

  const handleHerstellenDefinitief = useCallback(async (factIDs: number[]) => {
    const checkData = await api.v2.factuur.checkHerstellenDefinitiefMaken({
      factIDs,
    });
    if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
      return;
    }
    if (
      (
        await checkStore.bevestigen({
          inhoud: <span>Definitief ongedaan maken voor de geselecteerde facturen?</span>,
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    await api.v2.factuur.herstellenDefinitiefMaken({
      factIDs,
    });

    props.onDefinitiefSelectieChange([]);

    ophalenFacturen();
  }, []);

  const kolommen = useMemo<TypedColumn<IOphalenFacturenBasisResultElement>[]>(
    () => [
      {
        name: 'Factuurnummer',
        title: 'Fact.nr',
      },
      {
        name: 'Factuurdatum',
        title: 'Factuurdatum',
      },
      {
        name: 'Vervaldatum',
        title: 'Vervaldatum',
      },
      {
        name: 'Bedrag',
        title: 'Bedrag',
      },
      {
        name: 'Openstaand',
        title: 'Openstaand',
      },
      {
        name: '__debiteur' as any,
        title: 'Debiteur',
      },
      {
        name: '__versturen' as any,
        title: 'Versturen',
      },
      {
        name: 'RecordToegevoegd',
        title: 'Geregistreerd',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IOphalenFacturenBasisResultElement>[]>(
    () => [
      {
        columnName: 'Factuurnummer',
        width: 100,
      },
      {
        columnName: 'Factuurdatum',
        width: 135,
      },
      {
        columnName: 'Vervaldatum',
        width: 125,
      },
      {
        columnName: 'Bedrag',
        width: 110,
      },
      {
        columnName: 'Openstaand',
        width: 120,
      },
      {
        columnName: '__debiteur' as any,
        width: 225,
      },
      {
        columnName: '__versturen' as any,
        width: 125,
      },
      {
        columnName: 'RecordToegevoegd',
        width: 150,
      },
    ],
    [],
  );

  return (
    <>
      {facturen === null ? (
        <div className="d-flex flex-fill align-items-center justify-content-center">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          <Helmet>
            <title>Facturen Definitief</title>
          </Helmet>
          <div
            className="d-flex flex-column p-3"
            style={{
              backgroundColor: Kleur.HeelLichtGrijs,
              borderBottom: `1px solid ${Kleur.LichtGrijs}`,
            }}
          >
            <div className="d-flex">
              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-2"
                style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                disabled={props.definitiefSelectie.length === 0}
                onClick={() => handleHerstellenDefinitief(props.definitiefSelectie)}
              >
                {/* <IcoonToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} /> */}
                <span className="ml-2">Definitief ongedaan maken</span>
              </button>

              {/* <div className="d-flex align-items-center ml-3">
                <VinkVeld
                  aangevinkt={filterProlongatieFacturen}
                  onGewijzigd={(x) => {
                    setFilterProlongatieFacturen(x);
                  }}
                />
                <span className="ml-2">Alleen Prolongatiefacturen</span>
              </div> */}
            </div>

            {/* <div className="d-flex flex-fill ml-3">
              <FilterBalkV2
                filters={filters}
                filterData={urlState.filterData}
                onFilterDataChange={(x) => setUrlStateSync('filterData', x)}
                onFilterSchemaChange={(x) => setFilterSchema(x)}
              />
            </div> */}

            <div className="mt-3 ml-3">
              <SelectieVak
                aantal={props.definitiefSelectie.length}
                totaalAantal={facturen.length}
                onChange={(alles) => {
                  if (alles) {
                    props.onDefinitiefSelectieChange(facturen.map((x) => x.FactID));
                  } else {
                    props.onDefinitiefSelectieChange([]);
                  }
                }}
                entiteitEnkelvoud="factuur"
                entiteitMeervoud="facturen"
              />
            </div>
          </div>
          <GridStyleWrapper height="calc(100vh - 150px)">
            <Grid columns={kolommen} getRowId={keyExtractor} rows={facturen!}>
              <DataTypeProvider
                for={['Factuurdatum']}
                formatterComponent={(props) => (
                  <span>{format(new Date(props.value), 'dd-MM-yyyy')}</span>
                )}
              />
              <DataTypeProvider
                for={['Vervaldatum']}
                formatterComponent={(props) => (
                  <span>{format(new Date(props.value), 'dd-MM-yyyy')}</span>
                )}
              />
              <DataTypeProvider
                for={['Bedrag']}
                formatterComponent={(props) => <FormatteerBedrag bedrag={props.value} />}
              />
              <DataTypeProvider
                for={['Openstaand']}
                formatterComponent={(props) => <FormatteerBedrag bedrag={props.value} />}
              />
              <DataTypeProvider
                for={['__debiteur']}
                formatterComponent={(props) =>
                  props.row.debiteur.RelID !== null ? (
                    <RelatieVisualisatie relID={props.row.debiteur.RelID} />
                  ) : (
                    <span></span>
                  )
                }
              />

              <DataTypeProvider
                for={['__versturen']}
                formatterComponent={(formatterProps) => {
                  const rij: IOphalenFacturenBasisResultElement = formatterProps.row;
                  return <span>{rij.Versturen ? 'Ja' : 'Nee'}</span>;
                }}
              />

              <DataTypeProvider
                for={['RecordToegevoegd']}
                formatterComponent={(props) => {
                  const datum =
                    props.value !== null ? format(new Date(props.value), 'dd-MM-yyyy HH:mm') : '';
                  return <span>{datum}</span>;
                }}
              />

              <SortingState defaultSorting={[]} />
              <IntegratedSorting />

              <SelectionState
                selection={props.definitiefSelectie}
                onSelectionChange={(value) => props.onDefinitiefSelectieChange(value as number[])}
              />
              <RowDetailState defaultExpandedRowIds={[]} />
              <VirtualTable />
              <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
              <TableHeaderRow showSortingControls />
              <TableRowDetail
                contentComponent={FactuurregelsComp}
                toggleCellComponent={DXTableToggleCellComponent}
              />
              <TableSelection cellComponent={DXTableCheckboxComponent} />
            </Grid>
          </GridStyleWrapper>
        </>
      )}
    </>
  );
});

export default withRouter(Definitief);
