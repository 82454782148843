import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import useUrlState from '../../../../core/useUrlState';
import api from '../../../../api';
import { IOphalenRegisterItemsResultElement } from '../../../../../../shared/src/api/v2/boekhouding/activaregister/register';
import {
  DXTableCheckboxComponent,
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
  DXTableToggleCellComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import {
  DataTypeProvider,
  EditingState,
  IntegratedSorting,
  RowDetailState,
  SelectionState,
  SortingState,
  VirtualTable as VirtualTableBase,
} from '@devexpress/dx-react-grid';
import MenuLayout from '../../../../components/MenuLayout';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import {
  Grid,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import nameOf from '../../../../core/nameOf';
import { addMonths, format } from 'date-fns';
import FormatteerBedrag from '../../../../components/MutatieBedrag';
import DetailComp from './DetailComp';
import _ from 'lodash';
import { IconVerwijderen } from '../../../../components/Icons';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import { RootStoreContext } from '../../../../stores/RootStore';
import { EResultType } from '../../../../stores/CheckStore';
import SelectieVak from '../../../../components/SelectieVak';
import FilterBalkV2, {
  IFilter,
  IFilterData,
  maakFilterSchema,
} from '../../../../components/FilterBalkV2';
import StatusFilter from './StatusFilter';
import { IFilterSchema } from '../../../../../../shared/src/models/filter';
import { Helmet } from 'react-helmet';
import DatumKiezer from '../../../../components/formulier/DatumKiezer';
import { dagDatum } from '../../../../helpers/datum';
import WijzigDialoog from './WijzigDialoog';
import ProductinfoDialoog from '../../../../components/product/ProductinfoDialoog';
import { GlobaleRendererContext } from '../../../../one-off-components/GlobaleRenderer';

interface IProps extends RouteComponentProps {}

export enum ERegisterFilter {
  Status = 'STATUS',
  GeactiveerdVanaf = 'DATUM_GEACTIVEERD_VEM',
  GeactiveerdTot = 'DATUM_GEACTIVEERD_TOT',
  GedeactiveerdVanaf = 'DATUM_GEDEACTIVEERD_VEM',
  GedeactiveerdTot = 'DATUM_GEDEACTIVEERD_TEM',
  NotitieGevuld = 'NOTITIE_GEVULD',
}

interface IUrlState {
  selectie: number[];
  regelsSelectie: number[];
  uitgeklapt: number[];
  filterData: IFilterData<ERegisterFilter>[];
}

const defaultUrlState: IUrlState = {
  selectie: [],
  regelsSelectie: [],
  uitgeklapt: [],
  filterData: [
    {
      naam: ERegisterFilter.Status,
      data: 1,
      isActief: false,
    },
    {
      naam: ERegisterFilter.GeactiveerdVanaf,
      isActief: true,
      data: dagDatum(addMonths(new Date(), -1)),
    },
    {
      naam: ERegisterFilter.GeactiveerdTot,
      isActief: true,
      data: dagDatum(new Date()),
    },
    {
      naam: ERegisterFilter.GedeactiveerdVanaf,
      isActief: false,
      data: dagDatum(addMonths(new Date(), -1)),
    },
    {
      naam: ERegisterFilter.GedeactiveerdTot,
      isActief: false,
      data: dagDatum(new Date()),
    },
    {
      naam: ERegisterFilter.NotitieGevuld,
      isActief: false,
      data: true,
    },
  ],
};

interface IWijzigRegisterItemDialoogState {
  id: number;
}

// const DetailRegel = (props: TableRowDetail.ContentProps) => {
//   return <span>Detailinfo</span>;
// };

const Register: React.FC<IProps> = (props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState<IUrlState>(props, defaultUrlState);
  const { checkStore } = useContext(RootStoreContext);
  const globaleRenderer = useContext(GlobaleRendererContext);

  const [items, setItems] = useState<IOphalenRegisterItemsResultElement[] | null>(null);

  const [
    wijzigRegisterItemDialoogState,
    setWijzigRegisterItemDialoogState,
  ] = useState<IWijzigRegisterItemDialoogState | null>(null); //

  const [filterSchema, setFilterSchema] = useState<IFilterSchema>(
    useMemo(() => maakFilterSchema(urlState.filterData), []),
  );

  const ophalenItems = useCallback(async () => {
    const itemsResult = await api.v2.boekhouding.activaregister.register.ophalenRegisterItems({
      filterSchema,
      paginatie: {
        index: 0,
        aantal: 1000,
      },
      orderSchema: { orders: [{ naam: 'DATUM_GEACTIVEERD', richting: 'DESC' }] },
    });

    // const itemsGesorteerd = _.orderBy(itemsResult.registerItems, ['DatumGeactiveerd'], ['desc']);

    setItems(itemsResult.registerItems);
  }, [filterSchema]);

  useEffect(() => {
    ophalenItems();
  }, [ophalenItems]);

  const handleVerwijderen = useCallback(async () => {
    const checkData = await api.v2.boekhouding.activaregister.register.checkVerwijderenRegisterItems(
      {
        ids: urlState.selectie,
      },
    );
    const controlerenResult = await checkStore.controleren({
      checkData,
    });
    if (controlerenResult.type === EResultType.Annuleren) {
      return;
    }

    if (
      (
        await checkStore.bevestigen({
          inhoud: (
            <span>
              Geselecteerde item(s) verwijderen?
              <br />
              Het gekoppelde product moet daarna opnieuw geactiveerd worden
            </span>
          ),
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }
    await api.v2.boekhouding.activaregister.register.verwijderenRegisterItems({
      ids: urlState.selectie,
    });

    setUrlStateSync('selectie', []);
    ophalenItems();
  }, [urlState.selectie]);

  const handleOngedaanMakenDeactiveren = useCallback(async () => {
    if (items === null) {
      return;
    }
    if (items.some((x) => x.product === null)) {
      await checkStore.melden({
        titel:
          'Deactivatie van items zonder een gekoppeld product kunnen niet ongedaan gemaakt worden',
      });
      return;
    }

    // Bepaal de ProdIDs van de geselecteerde activa-items (de sleutel is de ID van de tabel)
    const prodIDs = items
      .filter((x) => urlState.selectie.indexOf(x.ID) !== -1)
      .map((x) => x.product!.ProdID);

    const params = { prodIDs };
    const checkData = await api.v2.boekhouding.activaregister.deactiveren.checkOngedaanMakenDeactiverenProducten(
      params,
    );
    const controlerenResult = await checkStore.controleren({
      checkData,
    });
    if (controlerenResult.type === EResultType.Annuleren) {
      return;
    }

    if (
      (
        await checkStore.bevestigen({
          inhoud: 'Deactiveren voor de geselecteerde items ongedaan maken?',
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }
    await api.v2.boekhouding.activaregister.deactiveren.ongedaanMakenDeactiverenProducten(params);

    setUrlStateSync('selectie', []);
    ophalenItems();
  }, [items, urlState.selectie]);

  const keyExtractor = useCallback((rij: IOphalenRegisterItemsResultElement) => rij.ID, []);
  const kolommen = useMemo<TypedColumn<IOphalenRegisterItemsResultElement>[]>(
    () => [
      {
        name: 'DatumGeactiveerd',
        title: 'Geactiveerd',
      },
      {
        name: '__product' as any,
        title: 'Product',
        getCellValue: (x: any) =>
          x.product === null ? x.Omschrijving : x.product.Merknaam + ' ' + x.product.Typenaam,
      },
      {
        name: '__inkoopReferentie' as any,
        title: 'Ink.ref.',
      },
      {
        name: '__referentiecode' as any,
        title: 'Ref.code',
      },
      {
        name: '__productsoortnaamKort' as any,
        title: 'Cat.',
      },
      {
        name: 'Aanschafwaarde',
        title: 'Aanschafw.',
      },
      {
        name: 'Restwaarde',
        title: 'Restw.',
      },
      // {
      //   name: 'Afschrijvingstermijn',
      //   title: 'Trm.',
      // },
      {
        name: 'DatumGedeactiveerd',
        title: 'Gedeactiveerd',
      },
      {
        name: 'RecordToegevoegd',
        title: 'Geregistreerd',
      },
      {
        name: 'RecordGewijzigd',
        title: 'Gewijzigd',
      },
      {
        name: 'Notities',
        title: 'Notities registeritem',
      },
    ],
    [],
  );
  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IOphalenRegisterItemsResultElement>[]>(
    () => [
      {
        columnName: 'DatumGeactiveerd',
        width: 125,
      },
      {
        columnName: '__product' as any,
        width: 225,
      },
      {
        columnName: '__referentiecode' as any,
        width: 100,
      },
      {
        columnName: '__inkoopReferentie' as any,
        width: 100,
      },
      {
        columnName: '__productsoortnaamKort' as any,
        width: 80,
      },
      {
        columnName: 'Aanschafwaarde',
        width: 115,
      },
      {
        columnName: 'Restwaarde',
        width: 110,
      },
      {
        columnName: 'Afschrijvingstermijn',
        width: 75,
      },
      {
        columnName: 'DatumGedeactiveerd',
        width: 135,
      },
      {
        columnName: 'RecordToegevoegd',
        width: 135,
      },
      {
        columnName: 'RecordGewijzigd',
        width: 110,
      },
      {
        columnName: 'Notities',
        width: 175,
      },
    ],
    [],
  );

  const kolomExtensies: VirtualTableBase.ColumnExtension[] = useMemo(() => {
    return [
      // {
      //   columnName: 'Aanschafwaarde',
      //   align: 'right',
      // },
      // {
      //   columnName: 'Restwaarde',
      //   align: 'right',
      // },
    ];
  }, []);

  const filters = useMemo<IFilter<ERegisterFilter>[]>(
    () => [
      {
        naam: ERegisterFilter.Status,
        altijdWeergevenInBalk: true,
        weergave: StatusFilter,
      },
      {
        naam: ERegisterFilter.GeactiveerdVanaf,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return (
            <span className="d-flex align-items-center">
              <span className="mr-2">Geactiveerd van</span>
              <DatumKiezer
                waarde={weergaveProps.data === null ? null : new Date(weergaveProps.data)}
                onGewijzigd={(x) => {
                  weergaveProps.onDataChange(x === null ? null : x.toISOString());
                  weergaveProps.setIsActief(true);
                  weergaveProps.toepassen();
                }}
                determineValidDate={() => {
                  return true;
                }}
                determinePreviousValidDate="ONBEGRENST"
                determineNextValidDate="ONBEGRENST"
              />
            </span>
          );
        },
      },
      {
        naam: ERegisterFilter.GeactiveerdTot,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return (
            <span className="d-flex align-items-center">
              <span className="mr-2">t/m</span>
              <DatumKiezer
                waarde={weergaveProps.data === null ? null : new Date(weergaveProps.data)}
                onGewijzigd={(x) => {
                  weergaveProps.onDataChange(x === null ? null : x.toISOString());
                  weergaveProps.setIsActief(true);
                  weergaveProps.toepassen();
                }}
                determineValidDate={() => {
                  return true;
                }}
                determinePreviousValidDate="ONBEGRENST"
                determineNextValidDate="ONBEGRENST"
              />
            </span>
          );
        },
      },
      {
        naam: ERegisterFilter.GedeactiveerdVanaf,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return (
            <span className="d-flex align-items-center">
              <span className="mr-2">Gedeactiveerd van</span>
              <DatumKiezer
                waarde={weergaveProps.data === null ? null : new Date(weergaveProps.data)}
                onGewijzigd={(x) => {
                  weergaveProps.onDataChange(x === null ? null : x.toISOString());
                  weergaveProps.setIsActief(true);
                  weergaveProps.toepassen();
                }}
                determineValidDate={() => {
                  return true;
                }}
                determinePreviousValidDate="ONBEGRENST"
                determineNextValidDate="ONBEGRENST"
              />
            </span>
          );
        },
      },
      {
        naam: ERegisterFilter.GedeactiveerdTot,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return (
            <span className="d-flex align-items-center">
              <span className="mr-2">t/m</span>
              <DatumKiezer
                waarde={weergaveProps.data === null ? null : new Date(weergaveProps.data)}
                onGewijzigd={(x) => {
                  weergaveProps.onDataChange(x === null ? null : x.toISOString());
                  weergaveProps.setIsActief(true);
                  weergaveProps.toepassen();
                }}
                determineValidDate={() => {
                  return true;
                }}
                determinePreviousValidDate="ONBEGRENST"
                determineNextValidDate="ONBEGRENST"
              />
            </span>
          );
        },
      },
      {
        naam: ERegisterFilter.NotitieGevuld,
        altijdWeergevenInBalk: false,
        weergave: (weergaveProps) => {
          return (
            <div className="d-flex align-items-center">
              <span>Alleen met notitie</span>
            </div>
          );
        },
      },
    ],
    [],
  );

  return (
    <>
      <Helmet>
        <title>Register</title>
      </Helmet>
      <MenuLayout
        menu={
          <div className="d-flex">
            {items !== null && (
              <div className="d-flex align-items-center">
                <SelectieVak
                  totaalAantal={items.length}
                  aantal={urlState.selectie.length}
                  entiteitEnkelvoud="tarief"
                  entiteitMeervoud="tarieven"
                  onChange={(allesGeselecteerd) => {
                    if (allesGeselecteerd) {
                      setUrlStateSync('selectie', items!.map(keyExtractor));
                    } else {
                      setUrlStateSync('selectie', []);
                    }
                  }}
                />
                {/* <span className="ml-2">Totaal {producten!.length} tarieven</span> */}
              </div>
            )}
            <button
              className="btn btn-sm btn-light d-flex align-items-center ml-3"
              style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
              disabled={urlState.selectie.length === 0}
              onClick={() => handleOngedaanMakenDeactiveren()}
            >
              {/* <IconVerwijderen style={{ width: 16, height: 16, fill: Kleur.Grijs }} /> */}
              <span className="ml-2">Deactiveren ongedaan maken</span>
            </button>
            <button
              className="btn btn-sm btn-light d-flex align-items-center ml-3"
              style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
              disabled={urlState.selectie.length === 0}
              onClick={() => handleVerwijderen()}
            >
              <IconVerwijderen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
              <span className="ml-2">Verwijderen</span>
            </button>

            <div className="d-flex flex-fill ml-3">
              <FilterBalkV2
                filters={filters}
                filterData={urlState.filterData}
                onFilterDataChange={(x) => setUrlStateSync('filterData', x)}
                onFilterSchemaChange={(x) => {
                  setFilterSchema(x);
                }}
              />
            </div>
          </div>
        }
        body={
          items === null ? (
            <div className="flex-fill d-flex align-items-center justify-content-center">
              <LoadingSpinner />
            </div>
          ) : (
            <GridStyleWrapper height={'calc(100vh - 150px)'}>
              <Grid rows={items} columns={kolommen} getRowId={keyExtractor}>
                {/* <DataTypeProvider
                for={['__product']}
                formatterComponent={(formatterProps) => {
                  const rij = formatterProps.row as IOphalenRegisterItemsResultElement;
                  const product =
                    rij.product === null
                      ? rij.Omschrijving
                      : rij.product.Merknaam + ' ' + rij.product.Typenaam;
                  return <span>{product}</span>;
                }}
              /> */}

                <DataTypeProvider
                  for={['__productsoortnaamKort']}
                  formatterComponent={(formatterProps) => {
                    const rij = formatterProps.row as IOphalenRegisterItemsResultElement;
                    const categorie =
                      rij.product !== null ? rij.product.producttype.ProductsoortnaamKort : null;
                    return <span>{categorie}</span>;
                  }}
                />

                <DataTypeProvider
                  for={['__inkoopReferentie']}
                  formatterComponent={(formatterProps) => {
                    const rij = formatterProps.row as IOphalenRegisterItemsResultElement;

                    if (rij.product!.inkoopopdracht === null) {
                      return <span></span>;
                    }

                    return <span>{rij.product!.inkoopopdracht.Referentie}</span>;
                  }}
                />

                <DataTypeProvider
                  for={['__referentiecode']}
                  formatterComponent={(formatterProps) => {
                    const rij = formatterProps.row as IOphalenRegisterItemsResultElement;

                    return (
                      <span>
                        {rij.product !== null && rij.product.Referentiecode !== null
                          ? rij.product.Referentiecode
                          : ''}
                      </span>
                    );
                  }}
                />

                <DataTypeProvider
                  for={['__product']}
                  formatterComponent={(formatterProps) => {
                    const rij = formatterProps.row as IOphalenRegisterItemsResultElement;
                    const product = rij.product;

                    if (product === null) {
                      return <span></span>;
                    }
                    return (
                      <span>
                        <a
                          href="#"
                          onClick={async (ev) => {
                            ev.preventDefault();

                            await globaleRenderer.render((renderProps) => (
                              <ProductinfoDialoog
                                id={product.ProdID}
                                open
                                onSuccess={() => renderProps.destroy()}
                                onAnnuleren={() => renderProps.destroy()}
                              />
                            ));
                          }}
                          className="mr-1"
                        >
                          {product.producttype.Merknaam} {product.producttype.Typenaam}
                        </a>
                      </span>
                    );
                  }}
                />

                <DataTypeProvider
                  for={[nameOf<IOphalenRegisterItemsResultElement>('DatumGeactiveerd')]}
                  formatterComponent={(formatterProps) => {
                    const rij = formatterProps.row as IOphalenRegisterItemsResultElement;
                    return (
                      <span>
                        {rij.DatumGeactiveerd === null
                          ? ''
                          : format(new Date(rij.DatumGeactiveerd), 'dd-MM-yyyy')}
                      </span>
                    );
                  }}
                />

                <DataTypeProvider
                  for={[nameOf<IOphalenRegisterItemsResultElement>('RecordToegevoegd')]}
                  formatterComponent={(formatterProps) => {
                    const rij = formatterProps.row as IOphalenRegisterItemsResultElement;
                    if (rij.RecordToegevoegd === null) {
                      return <span></span>;
                    }
                    return <span>{format(new Date(rij.RecordToegevoegd), 'dd-MM-yyyy')}</span>;
                  }}
                />

                <DataTypeProvider
                  for={[nameOf<IOphalenRegisterItemsResultElement>('RecordGewijzigd')]}
                  formatterComponent={(formatterProps) => {
                    const rij = formatterProps.row as IOphalenRegisterItemsResultElement;
                    if (rij.RecordGewijzigd === null) {
                      return <span></span>;
                    }
                    return <span>{format(new Date(rij.RecordGewijzigd), 'dd-MM-yyyy')}</span>;
                  }}
                />

                <DataTypeProvider
                  for={[nameOf<IOphalenRegisterItemsResultElement>('DatumGedeactiveerd')]}
                  formatterComponent={(formatterProps) => {
                    const rij = formatterProps.row as IOphalenRegisterItemsResultElement;
                    return (
                      <span>
                        {rij.DatumGedeactiveerd === null
                          ? ''
                          : format(new Date(rij.DatumGedeactiveerd), 'dd-MM-yyyy')}
                      </span>
                    );
                  }}
                />

                <DataTypeProvider
                  for={['Aanschafwaarde']}
                  formatterComponent={(props) => (
                    <span>
                      <FormatteerBedrag bedrag={props.value} />
                    </span>
                  )}
                />

                <DataTypeProvider
                  for={['Restwaarde']}
                  formatterComponent={(props) => (
                    <span>
                      <FormatteerBedrag bedrag={props.value} />
                    </span>
                  )}
                />

                <SortingState defaultSorting={[]} />
                <IntegratedSorting />

                {/* <RowDetailState
                  expandedRowIds={urlState.uitgeklapt}
                  onExpandedRowIdsChange={(x) => setUrlStateSync('uitgeklapt', x as number[])}
                /> */}

                <VirtualTable columnExtensions={kolomExtensies} />
                <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
                <TableHeaderRow showSortingControls />

                <EditingState
                  onCommitChanges={(changes) => {
                    if (changes.deleted === undefined) {
                      return;
                    }
                    const deleted = changes.deleted;
                    const id = deleted[deleted.length - 1];
                    alert(id);
                  }}
                  onEditingRowIdsChange={(rowIds) => {
                    const id = rowIds[rowIds.length - 1] as number;
                    setWijzigRegisterItemDialoogState({ id });
                    // alert(id);
                  }}
                />

                <TableEditColumn
                  width={35}
                  showEditCommand
                  cellComponent={DXTableEditColumnCellComponent}
                  commandComponent={DXTableEditColumnCommandComponent}
                />

                {/* <TableRowDetail
                  contentComponent={DetailComp}
                  toggleCellComponent={DXTableToggleCellComponent}
                /> */}

                <SelectionState
                  selection={urlState.selectie}
                  onSelectionChange={(x) => setUrlStateSync('selectie', x as number[])}
                />
                <TableSelection cellComponent={DXTableCheckboxComponent} />
              </Grid>
            </GridStyleWrapper>
          )
        }
      />
      {wijzigRegisterItemDialoogState && (
        <WijzigDialoog
          id={wijzigRegisterItemDialoogState.id}
          onSuccess={async () => {
            ophalenItems();
            setWijzigRegisterItemDialoogState(null);
          }}
          onAnnuleren={() => setWijzigRegisterItemDialoogState(null)}
          open
        />
      )}
    </>
  );
};

export default withRouter(Register);
