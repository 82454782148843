import React from 'react';
import { Kleur } from '../../../bedrijfslogica/constanten';
import UitlegTooltip from '../../../components/formulier/UitlegTooltip';

interface IProps {
  status: EStoplichtStatus;
  naam: string;
}

interface IPropsIndicatie {
  status: EStoplichtStatus;
}

export enum EStoplichtStatus {
  Rood = 'ROOD',
  Oranje = 'ORANJE',
  Groen = 'GROEN',
  Blauw = 'Blauw',
}

const kleurMap: { [status: string]: string } = {
  [EStoplichtStatus.Rood]: Kleur.Rood,
  [EStoplichtStatus.Oranje]: Kleur.Oranje,
  [EStoplichtStatus.Groen]: Kleur.Groen,
  [EStoplichtStatus.Blauw]: Kleur.Blauw,
};

const uitlegMap: Record<EStoplichtStatus, string> = {
  [EStoplichtStatus.Rood]: 'Fout',
  [EStoplichtStatus.Oranje]: 'Waarschuwing',
  [EStoplichtStatus.Groen]: 'Ok',
  [EStoplichtStatus.Blauw]: '',
};

export const StoplichtIndicatie: React.FC<IPropsIndicatie> = (props) => {
  return (
    <UitlegTooltip inhoud={uitlegMap[props.status]}>
      <div
        style={{
          width: 12,
          height: 12,
          borderRadius: '50%',
          backgroundColor: kleurMap[props.status],
        }}
      />
    </UitlegTooltip>
  );
};

const ContractStatus: React.FC<IProps> = (props) => {
  return (
    <div className="d-flex align-items-center">
      <div
        style={{
          width: 14,
          height: 14,
          borderRadius: '50%',
          backgroundColor: kleurMap[props.status],
        }}
      />
      <span className="ml-2">{props.naam}</span>
    </div>
  );
};

export default ContractStatus;
