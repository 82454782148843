import React, { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';
import { ModalBody, ModalFooter, ModalTitle } from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/ModalHeader';
import IDialoogProps from '../../../core/IDialoogProps';
import Dialoog from '../../dialogen/Dialoog';
import Tabblad, { ITabblad } from '../../layout/Tabblad';
import { ETabblad as EMagazijnBeheerTabblad } from '../../../paginas/Magazijn/Depot';
import SelecterenTabblad, { IFormikValues } from './SelecterenTabblad';
import api from '../../../api';
import { ETekstPrecisie } from '../../formulier/TekstPrecisieSelectie';
import RecentTabblad from './RecentTabblad';

export interface IProductSelectieDialoogResult {
  prodID: number;
}

export enum ETabblad {
  Selecteren,
  Recent,
}

export interface IKoppelOpties {
  defaultSelecterenValues?: IFormikValues;
}

export const defaultSelecterenValues: IFormikValues = {
  referentiecode: '',
  referentiecodeTekstprecisie: ETekstPrecisie.Exact,
  postcode: '',
  typenaam: '',
  typenaamTekstprecisie: ETekstPrecisie.StartMet,
  ookProductenUitBedrijf: true,
  notities: '',
  notitiesTekstprecisie: ETekstPrecisie.Ongeveer,
};

const defaultKoppelOpties: IKoppelOpties = {
  defaultSelecterenValues,
};

interface IProps extends IDialoogProps<IProductSelectieDialoogResult> {
  prodID?: number;
  defaultTabblad?: ETabblad;
  koppelOpties?: IKoppelOpties;
}

export interface IProductSelectieDialoogContext {
  koppelOpties: IKoppelOpties;
  selecterenValues: IFormikValues;
  setSelecterenValues: Dispatch<SetStateAction<IFormikValues>>;
  onGekozen: (relID: number) => void;
}

export const ProductSelectieDialoogContext = React.createContext<IProductSelectieDialoogContext>(
  null as any,
);

const ProductSelectieDialoog: React.FC<IProps> = (props) => {
  const { children, onAnnuleren, onSuccess, open } = props;

  const [tabblad, setTabblad] = useState<ETabblad>(
    props.defaultTabblad === undefined ? ETabblad.Selecteren : props.defaultTabblad,
  );

  const tabbladen = useMemo<ITabblad<ETabblad>[]>(
    () => [
      {
        id: ETabblad.Selecteren,
        label: 'Selecteren',
        content: SelecterenTabblad,
      },
      {
        id: ETabblad.Recent,
        label: 'Recent gewijzigd',
        content: RecentTabblad,
      },
    ],
    [onSuccess],
  );

  const koppelOpties = useMemo(() => ({ ...defaultKoppelOpties, ...props.koppelOpties }), [
    props.koppelOpties,
  ]);

  const [selecterenValues, setSelecterenValues] = useState(
    useMemo<IFormikValues>(() => koppelOpties.defaultSelecterenValues!, [koppelOpties]),
  );

  const productSelectieDialoogContext = useMemo<IProductSelectieDialoogContext>(() => {
    return {
      koppelOpties,
      selecterenValues,
      setSelecterenValues,
      onGekozen: async (prodID) => {
        // const result = await api.v2.product.ophalenProductenV2({
        //   filterSchema: {
        //     filters: [
        //       {
        //         naam: 'IDS',
        //         data: [prodID],
        //       },
        //     ],
        //   },
        // });
        // const product = result.producten[0]!;

        await api.v2.product.bijwerkenLijstRecent({
          prodID,
          aspGebrID: null,
        });

        props.onSuccess({ prodID });
      },
    };
  }, [koppelOpties, selecterenValues, setSelecterenValues]);

  return (
    <ProductSelectieDialoogContext.Provider value={productSelectieDialoogContext}>
      <Dialoog modalProps={{ size: 'xl' }} index={props.dialoogIndex ?? 0}>
        <ModalHeader>
          <ModalTitle>Product selecteren</ModalTitle>
        </ModalHeader>
        <ModalBody className="p-0">
          <Tabblad
            geselecteerd={tabblad}
            onSelectieChange={(id) => setTabblad(id)}
            tabbladen={tabbladen}
          />
        </ModalBody>
        <ModalFooter className="d-flex justify-content-start mt-2">
          <button
            className="btn btn-primary d-flex align-items-center justify-content-center"
            // onClick={() =>
            //   props.onSuccess({
            //     prodID: 26244,
            //     // magID: 4,
            //     // tabblad: EMagazijnBeheerTabblad.Retouren,
            //   })
            // }
          >
            Ok
          </button>
          <button className="btn btn-secondary" style={{ width: 100 }} onClick={onAnnuleren}>
            Annuleren
          </button>
        </ModalFooter>
      </Dialoog>
    </ProductSelectieDialoogContext.Provider>
  );
};

export default ProductSelectieDialoog;
