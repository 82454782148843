import { Formik } from 'formik';
import React, { useCallback, useContext, useState, useMemo, useEffect } from 'react';
import { ModalFooter, ModalTitle } from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/ModalHeader';
import api from '../../../api';
import IDialoogProps from '../../../core/IDialoogProps';
import Dialoog from '../../dialogen/Dialoog';
import _ from 'lodash';
import LoadingSpinner from '../../Gedeeld/LoadingSpinner';
import Tabblad, { ITabblad } from '../../layout/Tabblad';
import { IOphalenResult } from '../../../../../shared/src/api/v2/bedrijf';
import {
  IOphalenBatchesResultElement,
  IOphalenOpdrachtenResultElement,
} from '../../../../../shared/src/api/v2/bank/opdracht/opdracht';
import BatchTab from './BatchTab';
import OpdrachtenTab from './OpdrachtenTab';

interface IProps extends IDialoogProps<{}> {
  bankBatchID: number;
}

enum ETablad {
  Batch,
  Opdrachten,
}

export interface IContext {
  batch: IOphalenBatchesResultElement | null;
  opdrachten: IOphalenOpdrachtenResultElement[] | null;
}
export const BatchContext = React.createContext<IContext>({
  batch: null,
  opdrachten: null,
});

export interface IFormikValues {
  modelcode: string;
}

export const veldnamen: Record<keyof IFormikValues, string> = {
  modelcode: 'Modelcode',
};

const BatchInfoDialoog: React.FC<IProps> = (props) => {
  const { dialoogIndex, onAnnuleren, onSuccess, open, bankBatchID } = props;

  const [tabblad, setTabblad] = useState<ETablad>(ETablad.Batch);
  const [batch, setBatch] = useState<IOphalenBatchesResultElement | null>(null);
  const [opdrachten, setOpdrachten] = useState<IOphalenOpdrachtenResultElement[] | null>(null);

  const tabbladen = useMemo<ITabblad<ETablad>[]>(
    () => [
      {
        id: ETablad.Batch,
        label: 'Batch',
        content: BatchTab,
      },
      {
        id: ETablad.Opdrachten,
        label: 'Opdrachten',
        content: OpdrachtenTab,
      },
    ],
    [onSuccess],
  );

  const [bedrijf, setBedrijf] = useState<IOphalenResult | null>(null);

  const ophalenBedrijf = useCallback(async () => {
    const bedrijf = await api.v2.bedrijf.ophalenBedrijf({});

    setBedrijf(bedrijf);
  }, []);

  useEffect(() => {
    ophalenBedrijf();
  }, [ophalenBedrijf]);

  const ophalenBatch = useCallback(async () => {
    const batchResult = await api.v2.bank.opdracht.ophalenBatches({
      filterSchema: { filters: [{ naam: 'IDS', data: [bankBatchID] }] },
    });
    setBatch(batchResult[0]);
  }, [bankBatchID]);

  useEffect(() => {
    ophalenBatch();
  }, [ophalenBatch]);

  const ophalenOpdrachten = useCallback(async () => {
    const opdrachtenResult = await api.v2.bank.opdracht.ophalenOpdrachten({
      filterSchema: { filters: [{ naam: 'BATCH_IDS', data: [bankBatchID] }] },
    });
    setOpdrachten(opdrachtenResult.opdrachten);
  }, [bankBatchID]);

  useEffect(() => {
    ophalenOpdrachten();
  }, [ophalenOpdrachten]);

  // const initialFormikValues = useMemo<IFormikValues | null>(() => {
  //   if (productmodel === null || bedrijf === null) {
  //     return null;
  //   }

  //   return {
  //     modelcode: productmodel.Modelcode,
  //     instellingen: bedrijf,
  //   };
  // }, [productmodel, bedrijf]);

  return (
    <Dialoog index={dialoogIndex || 0} modalProps={{ size: `lg` }}>
      <ModalHeader>
        <ModalTitle>Batchinformatie</ModalTitle>
      </ModalHeader>
      <BatchContext.Provider
        value={{
          batch,
          opdrachten,
        }}
      >
        <div className="d-flex flex-column flex-fill" style={{ minHeight: `650px` }}>
          {batch === null || opdrachten === null ? (
            <div className="d-flex flex-fill align-items-center justify-content-center">
              <LoadingSpinner />
            </div>
          ) : (
            <>
              <div className="d-flex flex-column flex-fill">
                <Tabblad
                  tabbladen={tabbladen}
                  geselecteerd={tabblad}
                  onSelectieChange={(id) => {
                    setTabblad(id);
                  }}
                />
              </div>
              <ModalFooter className="d-flex flex-row justify-content-start">
                <button className="btn btn-primary" onClick={onAnnuleren} style={{ width: 100 }}>
                  Sluiten
                </button>
              </ModalFooter>
            </>
          )}
        </div>
      </BatchContext.Provider>
    </Dialoog>
  );
};

export default BatchInfoDialoog;
