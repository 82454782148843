import React from 'react';
import styled from 'styled-components';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import { IconMenu } from '../../../Icons';

const Root = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 15px;
  border-bottom: 1px solid ${Kleur.LichtGrijs};
`;

interface IProps {
  mappenSectieWeergeven: boolean;
  onMappenSectieWeergevenChange: (mappenWeergeven: boolean) => void;
}

const ActieBalk = (props: IProps) => {
  return (
    <Root>
      <button
        onClick={() => props.onMappenSectieWeergevenChange(!props.mappenSectieWeergeven)}
        className="btn btn-light d-flex align-items-center"
        style={{
          border: `1px solid ${Kleur.LichtGrijs}`,
        }}
      >
        <IconMenu style={{ width: 23, height: 23, fill: Kleur.Grijs }} />
        <span style={{ marginLeft: 12 }}>Mappen</span>
      </button>
    </Root>
  );
};

export default ActieBalk;
