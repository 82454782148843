import React, { useCallback, useContext, useMemo, useState } from 'react';
import DienstFormulier, {
  IFormikValues as IDienstFormulierFormikValues,
  validationSchema as dienstFormulierValidationSchema,
} from '../../../DienstFormulier';
import { LeverancierContext } from '../../../../index';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import api from '../../../../../../api';
import { EResultType } from '../../../../../../stores/CheckStore';
import { RootStoreContext } from '../../../../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import { IWijzigenDienstParams } from '../../../../../../../../shared/src/api/v2/dienst/inkoop';
import UitklapFormulier from '../../../../../../components/formulier/UitklapFormulier';
import { Kleur } from '../../../../../../bedrijfslogica/constanten';
import HorizontaleScheidingslijn from '../../../../../../components/layout/HorizontaleScheidingslijn';
import nameOf from '../../../../../../core/nameOf';
import NumeriekVeld from '../../../../../../components/formulier/NumeriekVeld';
import FormikVeldFout from '../../../../../../components/formulier/FormikVeldFout';
import TijdVeld, { ITijdVeldWaarde } from '../../../../../../components/formulier/TijdVeld';
import * as Yup from 'yup';
import VinkVeld from '../../../../../../components/formulier/VinkVeld';

interface IFormikValues extends IDienstFormulierFormikValues {
  tijdUiterlijkOpdracht: ITijdVeldWaarde;
  emailOpdracht: string | null;
  emailOpdrachtCC: string | null;
  levertermijn: number | null;
  koppelenProductenAanFactuur: boolean;
  emailRetouraanmelding: string | null;
  portalURL: string | null;
}

export const veldnamen: Partial<Record<keyof IFormikValues, string>> = {
  tijdUiterlijkOpdracht: 'Uiterlijke tijd opdracht',
  emailOpdracht: 'Email tbv opdrachten',
  emailOpdrachtCC: 'Email CC',
  levertermijn: 'Levertermijn',
  koppelenProductenAanFactuur: 'Producten koppelen aan inkoopfactuur',
  emailRetouraanmelding: 'Email tbv retouraanmeldingen',
  portalURL: 'URL naar het portal waar je een opdracht kunt invoeren',
};

interface IProps {}

const Dienst: React.FC<IProps> = observer((props) => {
  const { checkStore } = useContext(RootStoreContext);
  const context = useContext(LeverancierContext);
  const inkoopdienst = context.inkoopdienst.data!;
  const [dienstUitgeklapt, setDienstUitgeklapt] = useState(false);
  const [inkoopUitgeklapt, setInkoopUitgeklapt] = useState(true);

  const initialValues = useMemo<IFormikValues>(() => {
    return {
      actief: inkoopdienst.Actief,
      naam: inkoopdienst.Naam || '',
      naamEnum: inkoopdienst.NaamEnum ?? '',
      opdWijzeID: inkoopdienst.OpdWijzeID ?? null,
      parameters: inkoopdienst.Parameters || '',
      tijdUiterlijkOpdracht: {
        resultaat: inkoopdienst.TijdUiterlijkOpdracht,
        tekst:
          inkoopdienst.TijdUiterlijkOpdracht === null ? '' : inkoopdienst.TijdUiterlijkOpdracht,
      },
      emailOpdracht: inkoopdienst.EmailOpdracht,
      emailOpdrachtCC: inkoopdienst.EmailOpdrachtCC,
      levertermijn: inkoopdienst.Levertermijn,
      koppelenProductenAanFactuur: inkoopdienst.KoppelenProductenAanFactuur,
      emailRetouraanmelding:
        inkoopdienst.EmailRetouraanmelding === null ? '' : inkoopdienst.EmailRetouraanmelding,
      portalURL: inkoopdienst.PortalURL,
    };
  }, [inkoopdienst]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);
      const params: IWijzigenDienstParams = {
        relID: context.relID!,
        parameters: values.parameters === '' ? null : values.parameters,
        opdWijzeID: values.opdWijzeID,
        naam: values.naam,
        naamEnum: values.naamEnum,
        actief: values.actief,

        inkDienstID: inkoopdienst.ID,

        levertermijn: values.levertermijn!,
        emailOpdracht: values.emailOpdracht === '' ? null : values.emailOpdracht,
        emailOpdrachtCC: values.emailOpdrachtCC === '' ? null : values.emailOpdrachtCC,
        tijdUiterlijkOpdracht: values.tijdUiterlijkOpdracht.resultaat!,
        koppelenProductenAanFactuur: values.koppelenProductenAanFactuur,
        emailRetouraanmelding:
          values.emailRetouraanmelding === '' ? null : values.emailRetouraanmelding,
        portalURL: values.portalURL,
      };

      const checkData = await api.v2.dienst.inkoop.checkWijzigenDienst(params);
      if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
        actions.setSubmitting(false);
        return;
      }

      await api.v2.dienst.inkoop.wijzigenDienst(params);
      context.onVerversenInkoopdienstAangevraagd();
      actions.setSubmitting(false);
    },
    [context.relID, inkoopdienst, context.onVerversenInkoopdienstAangevraagd],
  );

  const validationSchema = useMemo(
    () =>
      dienstFormulierValidationSchema.concat(
        Yup.object().shape({
          tijdUiterlijkOpdracht: Yup.object().shape({
            resultaat: Yup.string().required(),
          }),
          emailOpdracht: Yup.string().nullable(),
          emailOpdrachtCC: Yup.string().nullable(),
          levertermijn: Yup.number().required(),
          koppelenProductenAanFactuur: Yup.boolean(),
        }),
      ),
    [],
  );

  return (
    <div className="d-flex flex-column flex-fill">
      <Formik
        enableReinitialize
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
        render={(formikProps: FormikProps<IFormikValues>) => {
          const { submitForm, isSubmitting, isValid, resetForm, dirty } = formikProps;
          return (
            <>
              <div className="d-flex flex-column flex-fill p-3">
                <UitklapFormulier
                  label="Dienst"
                  uitgeklapt={dienstUitgeklapt}
                  onUitgeklaptChange={setDienstUitgeklapt}
                  formulier={<DienstFormulier />}
                />
                <div className="p-2" />
                <UitklapFormulier
                  label="Inkoop"
                  uitgeklapt={inkoopUitgeklapt}
                  onUitgeklaptChange={setInkoopUitgeklapt}
                  formulier={
                    <div className="p-3">
                      <div className="row mb-3">
                        <div className="col-6">
                          <label>{veldnamen.levertermijn}</label>
                          <Field
                            name={nameOf<IFormikValues>('levertermijn')}
                            render={(fieldProps: FieldProps<IFormikValues>) => {
                              const { field, form } = fieldProps;
                              return (
                                <div>
                                  <NumeriekVeld
                                    waarde={field.value}
                                    onChange={(x) => form.setFieldValue(field.name, x)}
                                    min={0}
                                    max={99}
                                  />
                                  <FormikVeldFout fieldProps={fieldProps} />
                                </div>
                              );
                            }}
                          />
                        </div>
                        <div className="col-6">
                          <label>{veldnamen.tijdUiterlijkOpdracht}</label>
                          <Field
                            name={nameOf<IFormikValues>('tijdUiterlijkOpdracht')}
                            render={(fieldProps: FieldProps) => {
                              const { field, form } = fieldProps;
                              return (
                                <div>
                                  <TijdVeld
                                    waarde={field.value}
                                    onWaardeChange={(x) => form.setFieldValue(field.name, x)}
                                  />
                                  <FormikVeldFout fieldProps={fieldProps} />
                                </div>
                              );
                            }}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-6">
                          <label>{veldnamen.emailOpdracht}</label>
                          <Field
                            name={nameOf<IFormikValues>('emailOpdracht')}
                            render={(fieldProps: FieldProps) => {
                              const { field, form } = fieldProps;
                              return (
                                <div>
                                  <input className="form-control" {...fieldProps.field} />
                                  <FormikVeldFout fieldProps={fieldProps} />
                                </div>
                              );
                            }}
                          />
                        </div>

                        <div className="col-6">
                          <label>{veldnamen.emailOpdrachtCC}</label>
                          <Field
                            name={nameOf<IFormikValues>('emailOpdrachtCC')}
                            render={(fieldProps: FieldProps) => {
                              const { field, form } = fieldProps;
                              return (
                                <div>
                                  <input className="form-control" {...fieldProps.field} />
                                  <FormikVeldFout fieldProps={fieldProps} />
                                </div>
                              );
                            }}
                          />
                        </div>
                      </div>

                      <div className="row mt-3">
                        <div className="col-6">
                          <label>{veldnamen.emailRetouraanmelding}</label>
                          <Field
                            name={nameOf<IFormikValues>('emailRetouraanmelding')}
                            render={(fieldProps: FieldProps) => {
                              const { field, form } = fieldProps;
                              return (
                                <div>
                                  <input
                                    className="form-control"
                                    {...fieldProps.field}
                                    placeholder="Indien leeg dan wordt het emailadres voor de opdrachten aangehouden"
                                  />
                                  <FormikVeldFout fieldProps={fieldProps} />
                                </div>
                              );
                            }}
                          />
                        </div>
                      </div>

                      <div className="row mt-3">
                        <div className="col-6">
                          <label>{veldnamen.portalURL}</label>
                          <Field
                            name={nameOf<IFormikValues>('portalURL')}
                            render={(fieldProps: FieldProps) => {
                              const { field, form } = fieldProps;
                              return (
                                <div>
                                  <input className="form-control" {...fieldProps.field} />
                                  <FormikVeldFout fieldProps={fieldProps} />
                                </div>
                              );
                            }}
                          />
                        </div>
                      </div>

                      <div className="row col-12 mt-3">
                        <Field
                          name={nameOf<IFormikValues>('koppelenProductenAanFactuur')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <div className="d-flex align-items-center">
                                <VinkVeld
                                  aangevinkt={field.value}
                                  onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                />
                                <span className="ml-2">
                                  {veldnamen.koppelenProductenAanFactuur}
                                </span>
                              </div>
                            );
                          }}
                        />
                      </div>
                    </div>
                  }
                />
              </div>
              <HorizontaleScheidingslijn color={Kleur.LichtGrijs} />
              <div className="d-flex p-3">
                <button
                  className="btn btn-sm btn-primary d-flex align-items-center justify-content-center"
                  style={{ width: 100 }}
                  disabled={!isValid || isSubmitting}
                  onClick={submitForm}
                >
                  Ok
                </button>
                <button
                  className="btn btn-sm btn-secondary d-flex align-items-center justify-content-center ml-2"
                  style={{ width: 100 }}
                  disabled={!dirty || isSubmitting}
                  onClick={() => resetForm()}
                >
                  Annuleren
                </button>
              </div>
            </>
          );
        }}
      />
    </div>
  );
});

export default Dienst;
