import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import {
  DXTableCheckboxComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import {
  Grid,
  Table,
  VirtualTable,
  TableColumnResizing,
  TableHeaderRow,
  TableSelection,
} from '@devexpress/dx-react-grid-bootstrap4';
import { DataTypeProvider, SelectionState, TableRowDetail } from '@devexpress/dx-react-grid';
import { format } from 'date-fns';
import FormatteerBedrag from '../../../../components/MutatieBedrag';
import { RechtzakenContext } from '../index';
import { IOphalenRechtzakenResultElement } from '../../../../../../shared/src/api/v2/debiteur/rechtzaak';
import { IOphalenFacturenBasisResultElement } from '../../../../../../shared/src/api/v2/factuur';
import DownloadKnop from '../../../../components/DownloadKnop';
import api from '../../../../api';
import TabelInspringBlok from '../../../../components/layout/TabelInspringBlok';

interface IRij {
  FactID: number;
  Bedrag: number;
  Factuurdatum: Date;
  Vervaldatum: Date;
  Factuurnummer: string;
}

interface IProps extends TableRowDetail.ContentProps {}

const DetailComp: React.FC<IProps> = (props) => {
  const row: IOphalenRechtzakenResultElement = props.row;

  const rechtzakenContext = useContext(RechtzakenContext);
  const facturenBasis = rechtzakenContext.facturen;

  const facturen: any[] = row.facturen.map((x) => {
    const factuur = facturenBasis.find((f) => f.FactID === x.FactID);
    return {
      FactID: x.FactID,
      Bedrag: x.Bedrag,
      Factuurdatum: factuur.Factuurdatum,
      Vervaldatum: factuur.Vervaldatum,
      Factuurnummer: factuur.Factuurnummer,
    };
  });

  const keyExtractor = useCallback((row: IRij) => row.FactID, []);

  const kolommen = useMemo<TypedColumn<IRij>[]>(() => {
    return [
      {
        name: 'Factuurnummer',
        title: 'Fact.nr',
      },
      {
        name: 'Factuurdatum',
        title: 'Fact.datum',
      },
      {
        name: 'Vervaldatum',
        title: 'Vervaldatum',
      },
      {
        name: 'Bedrag',
        title: 'Bedrag',
      },
      // {
      //   name: 'bedragInDossier',
      //   title: 'In dossier',
      // },
      {
        name: '__downloadActie' as any,
        title: ' ',
      },
    ];
  }, []);

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRij>[]>(() => {
    return [
      {
        columnName: 'Factuurnummer',
        width: 100,
      },
      {
        columnName: 'Factuurdatum',
        width: 100,
      },
      {
        columnName: 'Vervaldatum',
        width: 100,
      },
      {
        columnName: 'Bedrag',
        width: 100,
      },
      // {
      //   columnName: 'bedragInDossier',
      //   width: 100,
      // },
      {
        columnName: '__downloadActie' as any,
        width: 100,
      },
    ];
  }, []);

  return (
    <>
      <div className="d-flex">
        <TabelInspringBlok />
        <GridStyleWrapper maxHeight={500} rowAmount={facturen.length}>
          <Grid getRowId={keyExtractor} rows={facturen} columns={kolommen}>
            <DataTypeProvider
              for={['Factuurdatum']}
              formatterComponent={(formatterProps) => {
                const row: IRij = formatterProps.row;

                return <span>{format(new Date(row.Factuurdatum), 'dd-MM-yyyy')}</span>;
              }}
            />

            <DataTypeProvider
              for={['Vervaldatum']}
              formatterComponent={(formatterProps) => {
                const row: IRij = formatterProps.row;

                return <span>{format(new Date(row.Vervaldatum), 'dd-MM-yyyy')}</span>;
              }}
            />

            <DataTypeProvider
              for={['Bedrag']}
              formatterComponent={(props) => (
                <span>
                  <FormatteerBedrag bedrag={props.value} />
                </span>
              )}
            />

            <DataTypeProvider
              for={['bedragInDossier']}
              formatterComponent={(props) => (
                <span>
                  <FormatteerBedrag bedrag={props.value} />
                </span>
              )}
            />

            <DataTypeProvider
              for={['__downloadActie']}
              formatterComponent={(props) => {
                const row = props.row as IOphalenFacturenBasisResultElement;
                const factID = row.FactID;

                return (
                  <DownloadKnop
                    onDownloadAangevraagd={async () => {
                      const result = await api.v2.factuur.genereerFactuurPDF({
                        factID,
                      });
                      const downloadLink = result.bestand.url;
                      window.open(downloadLink, '_blank');
                    }}
                  />
                );
              }}
            />

            <Table />
            <TableColumnResizing />
            <TableHeaderRow />
            <TableColumnResizing defaultColumnWidths={kolomBreedtes as any} />
          </Grid>
        </GridStyleWrapper>
      </div>
    </>
  );
};

export default DetailComp;
