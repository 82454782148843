import React, { useCallback } from 'react';
import styled from 'styled-components';
import IRemoteData, {
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../models/IRemoteData';
import LoadingSpinner from '../../../Gedeeld/LoadingSpinner';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import Heading from './Heading';
import Inhoud from './Inhoud';
import {
  IOphalenRelatiesResultElementV2,
  IOphalenRelPersResultElement,
} from '../../../../../../shared/src/api/v2/relatie';
import api from '../../../../api';
import { IOphalenPersonenResultElementV2 } from '../../../../../../shared/src/api/v2/persoon/persoon';
import { IOphalenOrganisatiesResultElement } from '../../../../../../shared/src/api/v2/organisatie/organisatie';
import { IEmail } from '../../../../../../shared/src/api/v2/email-v2';
import { IOphalenEmailAccountsResultElement } from '../../../../../../shared/src/api/v2/dienst/email/email';

const Root = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  border-left: 1px solid ${Kleur.LichtGrijs};
`;

interface IProps {
  email: IRemoteData<IEmail | null>;
  emailAccount: IOphalenEmailAccountsResultElement;
  relatiesCache: Record<number, IRemoteData<IOphalenRelatiesResultElementV2>>;
  personenCache: Record<number, IRemoteData<IOphalenPersonenResultElementV2>>;
  organisatiesCache: Record<number, IRemoteData<IOphalenOrganisatiesResultElement>>;
  relPersCache: Record<number, IRemoteData<IOphalenRelPersResultElement[]>>;
}

const DetailSectie = (props: IProps) => {
  const handleContextRelIDsChange = useCallback(
    async (relIDs: number[]) => {
      if (props.email.state === ERemoteDataState.Pending) {
        throw new Error('Email is nog niet geladen');
      }
      await api.v2.emailV2.muterenEmailRelatieContext({
        emailBerID: props.email.data!.ID,
        relIDs,
      });
    },
    [props.email],
  );

  if (props.email.state === ERemoteDataState.Pending) {
    return (
      <Root>
        <div className="d-flex flex-fill align-items-center justify-content-center">
          <LoadingSpinner />
        </div>
      </Root>
    );
  }

  if (props.email.data === null) {
    return <Root />;
  }

  const email = props.email.data!;

  return (
    <Root>
      <Heading
        email={email}
        relatiesCache={props.relatiesCache}
        onContextRelIDsChange={handleContextRelIDsChange}
        personenCache={props.personenCache}
        organisatiesCache={props.organisatiesCache}
        avatarImageSrc={createReadyRemoteData(null)}
        relPersCache={props.relPersCache}
        emailAccount={props.emailAccount}
      />
      <Inhoud inhoud={email.Inhoud} isHTML={email.IsHTML} />
    </Root>
  );
};

export default DetailSectie;
