import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import MutatieTabel from '../../../../../../../components/relatie/MutatieTabel';
import api from '../../../../../../../api';
import { IOphalenMutatiesResultElement } from '../../../../../../../../../shared/src/api/v2/relatie/mutatie';
import useUrlState from '../../../../../../../core/useUrlState';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { RootStoreContext } from '../../../../../../../stores/RootStore';
import ActieclaimTabel from '../../../../../../../components/kwk/actieclaimTabel';
import { IOphalenActieclaimsResultElement } from '../../../../../../../../../shared/src/api/v2/actie/klantWerftKlant';
import { KlantContext } from '../index';
import _ from 'lodash';
import { IOphalenContractenResultElementV2 } from '../../../../../../../../../shared/src/api/v2/contract';

interface IProps extends RouteComponentProps {
  relID: number;
}

export interface IUrlState {
  actieclaimsSelectie: number[];
  regelsSelectie: number[];
}

export const defaultUrlState: IUrlState = {
  actieclaimsSelectie: [],
  regelsSelectie: [],
};

export interface IRegel extends IOphalenActieclaimsResultElement {
  contracten: IOphalenContractenResultElementV2[];
}

const Mutaties: React.FC<IProps> = observer((props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const { klantkaartStore } = useContext(RootStoreContext);
  const klantContext = useContext(KlantContext);

  const [actieclaims, setActieclaims] = useState<IOphalenActieclaimsResultElement[] | null>(null);

  const ophalenActieclaims = useCallback(async () => {
    const actieclaimsResult = await api.v2.klantactie.klantWerftKlant.ophalenActieclaims({
      filterSchema: {
        filters: [],
        uitgebreideFilter: {
          or: [
            {
              filter: {
                naam: 'AANBRENGER_REL_IDS',
                data: [klantContext.relID],
              },
            },
            {
              filter: {
                naam: 'AANGEBRACHTE_REL_IDS',
                data: [klantContext.relID],
              },
            },
          ],
        },
      },
    });

    const cntIDs = _.flatten(actieclaimsResult.actieclaims.map((x) => x.cntIDs));
    const contractenResult = (
      await api.v2.contract.ophalenContractenV2({
        filterSchema: { filters: [{ naam: 'IDS', data: cntIDs }] },
      })
    ).contracten;

    const actieclaims = actieclaimsResult.actieclaims.map((claim) => {
      const contracten = contractenResult.filter((x) => claim.cntIDs.indexOf(x.CntID) !== -1);
      return {
        ...claim,
        contracten,
      };
    });

    setActieclaims(actieclaims);
  }, []);

  useEffect(() => {
    ophalenActieclaims();
  }, [ophalenActieclaims]);

  return (
    <ActieclaimTabel
      relID={props.relID}
      actieclaims={actieclaims}
      actieclaimsSelectie={urlState.actieclaimsSelectie}
      onActieclaimsSelectieChange={(value: number[]) =>
        setUrlStateSync('actieclaimsSelectie', value)
      }
      regelsSelectie={[]}
      onRegelsSelectieChange={(value: number[]) => setUrlStateSync('regelsSelectie', value)}
      onRequestRefresh={() => ophalenActieclaims()}
    />
  );
});

export default withRouter(Mutaties);
