/* tslint:disable */
import { makeObservable, observable } from 'mobx';
import { createContext } from 'react';
import { IOphalenContractLocatiesResult } from '../../../../../../../shared/src/api/v2/contract';
import {
  IContract,
  ILocatieWijzigenDialoogState,
  INieuwContractDialoogTonenDialoogState,
  INieuwContractwisselDialoogTonenDialoogState,
  INieuweTransportopdrachtDialoogState,
  INieuwVerkoopvoorstelDialoogState,
  IKEContractenOverzichtUrlState,
  IInterneLocatieWijzigenDialoogState,
  IModelEnAbonnementWijzigenDialoogState,
  IStandaardLeveropdrachtenDialoogState,
  IToepassenActieDialoogState,
} from '../../../../../paginas/Klant/Klantkaart/Entiteiten/entiteiten/Contracten/Overzicht';
import api from '../../../../../api';
import _ from 'lodash';
import { IFilterSchemaFilter } from '../../../../../../../shared/src/models/filter';
import { IFilterData, maakFilterSchema } from '../../../../../components/FilterBalkV2';

export enum EFilter {
  AlleenActieveContracten = 'ALLEEN_ACTIEVE_CONTRACTEN',
  LOC_IDS = 'LOC_IDS',
  STATUS_NAAMENUM = 'STATUS_NAAM_ENUMS',
  IS_ACTUEEL = 'IS_ACTUEEL',
  ZOEKTERM = 'ZOEKTERM',
  LOCATIE_VERDIEPINGEN = 'LOCATIE_VERDIEPINGEN',
  REL_PERS_IDS = 'REL_PERS_IDS',
  INTERNE_LOCATIE_NAMEN = 'INTERNE_LOCATIE_NAMEN',
  FACTUURKENMERK = 'FACTKEN_IDS',
  COMPLEX = 'COMPLEX_IDS',
}

const defaultFilterData = [
  // {
  //   naam: EFilter.AlleenActieveContracten,
  //   data: null,
  //   isActief: true,
  // },
  {
    naam: EFilter.LOC_IDS,
    data: [],
    isActief: false,
  },
  {
    naam: EFilter.INTERNE_LOCATIE_NAMEN,
    data: [],
    isActief: false,
  },
  {
    naam: EFilter.STATUS_NAAMENUM,
    data: ['CONCEPT'],
    isActief: false,
  },
  {
    naam: EFilter.IS_ACTUEEL,
    data: true,
    isActief: false,
  },
  {
    naam: EFilter.ZOEKTERM,
    data: '',
    isActief: false,
  },
  {
    naam: EFilter.LOCATIE_VERDIEPINGEN,
    data: [1],
    isActief: false,
  },
  {
    naam: EFilter.REL_PERS_IDS,
    data: [],
    isActief: false,
  },
  {
    naam: EFilter.FACTUURKENMERK,
    data: [],
    isActief: false,
  },
  {
    naam: EFilter.COMPLEX,
    data: [],
    isActief: false,
  },
];

class KEContractenOverzichtStore {
  public relID: number | null = null;
  public contracten: IContract[] | null = null;
  public contractLocatiesResult: IOphalenContractLocatiesResult | null = null;
  public selectie: number[] = [];
  public filterData: IFilterData<EFilter>[] = defaultFilterData;
  public aanbiedenOvernameDialoogTonen: boolean = false;
  public nieuwContractDialoogState: INieuwContractDialoogTonenDialoogState | null = null;
  public nieuwContractwisselDialoogState: INieuwContractwisselDialoogTonenDialoogState | null = null;
  public nieuweTransportopdrachtDialoogState: INieuweTransportopdrachtDialoogState | null = null;
  public afkeurenContractDialoogTonen: boolean = false;
  public overnemenContractDialoogTonen: boolean = false;
  public annulerenContractenDialoogTonen: boolean = false;
  public beeindigContractenDialoogTonen: boolean = false;
  public versturenUitnodigingTonen: boolean = false;
  public leveropdrachtTonen: boolean = false;
  public fiatterenNieuwDialoogTonen: boolean = false;
  public fiatterenOvernameDialoogTonen: boolean = false;
  public contractlocatiesUitgeklapt: number[] = [];
  public locatieWijzigenDialoogState: ILocatieWijzigenDialoogState | null = null;
  public toepassenActieDialoogState: IToepassenActieDialoogState | null = null;
  public interneLocatieWijzigenDialoogState: IInterneLocatieWijzigenDialoogState | null = null;
  public nieuwVerkoopvoorstelDialoogState: INieuwVerkoopvoorstelDialoogState | null = null;
  public modelEnAbonnementWijzigenDialoogState: IModelEnAbonnementWijzigenDialoogState | null = null;
  public standaardLeveropdrachtenDialoogState: IStandaardLeveropdrachtenDialoogState | null = null;

  constructor() {
    makeObservable(this, {
      contracten: observable,
      contractLocatiesResult: observable,
      selectie: observable,
      aanbiedenOvernameDialoogTonen: observable,
      nieuwContractDialoogState: observable,
      nieuweTransportopdrachtDialoogState: observable,
      afkeurenContractDialoogTonen: observable,
      overnemenContractDialoogTonen: observable,
      annulerenContractenDialoogTonen: observable,
      beeindigContractenDialoogTonen: observable,
      versturenUitnodigingTonen: observable,
      leveropdrachtTonen: observable,
      fiatterenNieuwDialoogTonen: observable,
      fiatterenOvernameDialoogTonen: observable,
      contractlocatiesUitgeklapt: observable,
      locatieWijzigenDialoogState: observable,
      toepassenActieDialoogState: observable,
      interneLocatieWijzigenDialoogState: observable,
      nieuwVerkoopvoorstelDialoogState: observable,
      modelEnAbonnementWijzigenDialoogState: observable,
      standaardLeveropdrachtenDialoogState: observable,
    });
  }

  public initialiseerMetRelIDEnUrlState = async (
    relID: number,
    urlState: IKEContractenOverzichtUrlState,
  ) => {
    if (this.relID !== relID) {
      this.urlStateToepassen(urlState);
      this.relID = relID;
      await this.ophalenGegevens();
    }
  };

  private stateResetten = () => {
    this.relID = null;
    this.contracten = null;
    this.contractLocatiesResult = null;
    this.selectie = [];
    this.filterData = defaultFilterData;
    this.aanbiedenOvernameDialoogTonen = false;
    this.nieuwContractDialoogState = null;
    this.nieuweTransportopdrachtDialoogState = null;
    this.afkeurenContractDialoogTonen = false;
    this.overnemenContractDialoogTonen = false;
    this.annulerenContractenDialoogTonen = false;
    this.beeindigContractenDialoogTonen = false;
    this.versturenUitnodigingTonen = false;
    this.leveropdrachtTonen = false;
    this.fiatterenNieuwDialoogTonen = false;
    this.fiatterenOvernameDialoogTonen = false;
    this.contractlocatiesUitgeklapt = [];
    this.locatieWijzigenDialoogState = null;
    this.toepassenActieDialoogState = null;
    this.modelEnAbonnementWijzigenDialoogState = null;
    this.standaardLeveropdrachtenDialoogState = null;
  };

  public urlStateToepassen = (urlState: IKEContractenOverzichtUrlState) => {
    this.selectie = urlState.selectie ?? [];
    this.filterData = urlState.filterData ?? defaultFilterData;
    this.aanbiedenOvernameDialoogTonen =
      urlState.aanbiedenOvernameDialoogTonen ?? this.aanbiedenOvernameDialoogTonen;
    this.nieuwContractDialoogState =
      urlState.nieuwContractenDialoogTonen ?? this.nieuwContractDialoogState;
    this.nieuweTransportopdrachtDialoogState =
      urlState.nieuweTransportopdrachtDialoogState ?? this.nieuweTransportopdrachtDialoogState;
    this.afkeurenContractDialoogTonen =
      urlState.afkeurenContractDialoogTonen ?? this.afkeurenContractDialoogTonen;
    this.overnemenContractDialoogTonen =
      urlState.overnemenContractDialoogTonen ?? this.overnemenContractDialoogTonen;
    this.beeindigContractenDialoogTonen =
      urlState.beeindigContractenDialoogTonen ?? this.beeindigContractenDialoogTonen;
    this.versturenUitnodigingTonen =
      urlState.versturenUitnodigingTonen ?? this.versturenUitnodigingTonen;
    this.leveropdrachtTonen = urlState.leveropdrachtTonen ?? this.leveropdrachtTonen;
    this.fiatterenNieuwDialoogTonen =
      urlState.fiatterenNieuwDialoogTonen ?? this.fiatterenNieuwDialoogTonen;
    this.fiatterenOvernameDialoogTonen =
      urlState.fiatterenOvernameDialoogTonen ?? this.fiatterenOvernameDialoogTonen;
    this.contractlocatiesUitgeklapt =
      urlState.contractlocatiesUitgeklapt ?? this.contractlocatiesUitgeklapt;
    this.locatieWijzigenDialoogState =
      urlState.locatieWijzigenDialoogState ?? this.locatieWijzigenDialoogState;

    this.toepassenActieDialoogState =
      urlState.toepassenActieDialoogState ?? this.toepassenActieDialoogState;

    this.modelEnAbonnementWijzigenDialoogState =
      urlState.modelEnAbonnementWijzigenDialoogState ?? this.modelEnAbonnementWijzigenDialoogState;

    this.standaardLeveropdrachtenDialoogState =
      urlState.standaardLeveropdrachtenDialoogState ?? this.standaardLeveropdrachtenDialoogState;
  };

  public ophalenGegevens = async () => {
    await Promise.all([this.ophalenContracten(), this.ophalenContractLocaties()]);
  };

  public ophalenContracten = async () => {
    const filterSchema = maakFilterSchema(this.filterData);
    const filters: IFilterSchemaFilter[] = [
      {
        naam: 'REL_IDS',
        data: [this.relID],
      },
      ...filterSchema.filters!,
    ];

    const contractenResult = (
      await api.v2.contract.ophalenContractenV2({
        filterSchema: {
          filters,
        },
        orderSchema: {
          orders: [
            {
              naam: 'BASISNUMMER',
              richting: 'DESC',
            },
            {
              naam: 'VOLGNUMMER',
              richting: 'DESC',
            },
          ],
        },
      })
    ).contracten;

    // Voeg de data toe van eventuele wisselcontracten (Door en Voor)
    const wisselContractDoorCntIDs = contractenResult
      .filter((x) => x.ContractwisselDoor_CntID !== null)
      .map((x) => x.ContractwisselDoor_CntID);

    const wisselContractVoorCntIDs = contractenResult
      .filter((x) => x.basis.Contractwissel_CntID !== null)
      .map((x) => x.basis.Contractwissel_CntID);

    const wisselCntIDs: number[] = _.uniq([
      ...wisselContractDoorCntIDs,
      ...wisselContractVoorCntIDs,
    ]);

    const wisselContractenResult = (
      await api.v2.contract.ophalenContractenV2({
        filterSchema: {
          filters: [
            {
              naam: 'IDS',
              data: wisselCntIDs,
            },
          ],
        },
      })
    ).contracten;

    this.contracten = contractenResult.map((x) => {
      const gewisseldDoorContract =
        x.ContractwisselDoor_CntID != null
          ? wisselContractenResult.find((w) => w.CntID === x.ContractwisselDoor_CntID)!
          : null;
      const gewisseldVoorContract =
        x.basis.Contractwissel_CntID != null
          ? wisselContractenResult.find((w) => w.CntID === x.basis.Contractwissel_CntID)!
          : null;

      return {
        ...x,
        gewisseldDoorContract,
        gewisseldVoorContract,
      };
    });
  };

  public ophalenContractLocaties = async () => {
    const result = await api.v2.contract.ophalenContractLocaties({
      filterSchema: {
        filters: [
          {
            naam: 'REL_IDS',
            data: [this.relID],
          },
        ],
      },
    });
    this.contractLocatiesResult = result;
  };

  public setFilterData = (filterData: IFilterData<EFilter>[]) => {
    this.filterData = filterData;
  };
  public setSelectie = (selectie: number[]) => {
    this.selectie = selectie;
  };
  public setAanbiedenOvernameDialoogTonen = (aanbiedenOvernameDialoogTonen: boolean) => {
    this.aanbiedenOvernameDialoogTonen = aanbiedenOvernameDialoogTonen;
  };
  public setNieuwContractDialoogState = (
    nieuwContractDialoogState: INieuwContractDialoogTonenDialoogState | null,
  ) => {
    this.nieuwContractDialoogState = nieuwContractDialoogState;
  };
  public setNieuwContractwisselDialoogState = (
    nieuwContractwisselDialoogState: INieuwContractwisselDialoogTonenDialoogState | null,
  ) => {
    this.nieuwContractwisselDialoogState = nieuwContractwisselDialoogState;
  };
  public setNieuweTransportopdrachtDialoogState = (
    nieuweTransportopdrachtDialoogState: INieuweTransportopdrachtDialoogState | null,
  ) => {
    this.nieuweTransportopdrachtDialoogState = nieuweTransportopdrachtDialoogState;
  };
  public setAfkeurenContractDialoogTonen = (afkeurenContractDialoogTonen: boolean) => {
    this.afkeurenContractDialoogTonen = afkeurenContractDialoogTonen;
  };
  public setOvernemenContractDialoogTonen = (overnemenContractDialoogTonen: boolean) => {
    this.overnemenContractDialoogTonen = overnemenContractDialoogTonen;
  };
  public setAnnulerenContractenDialoogTonen = (annulerenContractenDialoogTonen: boolean) => {
    this.annulerenContractenDialoogTonen = annulerenContractenDialoogTonen;
  };
  public setBeeindigContractenDialoogTonen = (beeindigContractenDialoogTonen: boolean) => {
    this.beeindigContractenDialoogTonen = beeindigContractenDialoogTonen;
  };
  public setVersturenUitnodigingTonen = (versturenUitnodigingTonen: boolean) => {
    this.versturenUitnodigingTonen = versturenUitnodigingTonen;
  };
  public setLeveropdrachtTonen = (leveropdrachtTonen: boolean) => {
    this.leveropdrachtTonen = leveropdrachtTonen;
  };
  public setFiatterenNieuwDialoogTonen = (fiatterenNieuwDialoogTonen: boolean) => {
    this.fiatterenNieuwDialoogTonen = fiatterenNieuwDialoogTonen;
  };
  public setFiatterenOvernameDialoogTonen = (fiatterenOvernameDialoogTonen: boolean) => {
    this.fiatterenOvernameDialoogTonen = fiatterenOvernameDialoogTonen;
  };
  public setContractlocatiesUitgeklapt = (contractlocatiesUitgeklapt: number[]) => {
    this.contractlocatiesUitgeklapt = contractlocatiesUitgeklapt;
  };

  public setLocatieWijzigenDialoogState = (
    locatieWijzigenDialoogState: ILocatieWijzigenDialoogState | null,
  ) => {
    this.locatieWijzigenDialoogState = locatieWijzigenDialoogState;
  };
  public setToepassenActieDialoogState = (
    toepassenActieDialoogState: IToepassenActieDialoogState | null,
  ) => {
    this.toepassenActieDialoogState = toepassenActieDialoogState;
  };

  public setInterneLocatieWijzigenDialoogState = (
    interneLocatieWijzigenDialoogState: IInterneLocatieWijzigenDialoogState | null,
  ) => {
    this.interneLocatieWijzigenDialoogState = interneLocatieWijzigenDialoogState;
  };
  public setNieuweVerkoopvoorstelDialoogState = (
    nieuweVerkoopvoorstelDialoogState: INieuwVerkoopvoorstelDialoogState | null,
  ) => {
    this.nieuwVerkoopvoorstelDialoogState = nieuweVerkoopvoorstelDialoogState;
  };
  public setModelEnAbonnementWijzigenDialoogState = (
    modelEnAbonnementDialoogState: IModelEnAbonnementWijzigenDialoogState | null,
  ) => {
    this.modelEnAbonnementWijzigenDialoogState = modelEnAbonnementDialoogState;
  };
  public setStandaardLeveropdrachtenDialoogTonen = (
    standaardLeveropdrachtenDialoogState: IStandaardLeveropdrachtenDialoogState | null,
  ) => {
    this.standaardLeveropdrachtenDialoogState = standaardLeveropdrachtenDialoogState;
  };
}

export const storeInstance = new KEContractenOverzichtStore();
export const KEContractenOverzichtStoreContext = createContext(storeInstance);

export default KEContractenOverzichtStore;
