import React, { useState, useEffect, useCallback, useMemo, useContext } from 'react';
import api from '../../../../api';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import useUrlState from '../../../../core/useUrlState';
import {
  ASPKolom,
  EAspKolomBreedteType,
  ESortering,
  IAspKolomSorteringItem,
} from '../../../../components/tabel/ASPTabel/types';
import MenuLayout from '../../../../components/MenuLayout';
import ASPTabel from '../../../../components/tabel/ASPTabel';
import { format } from 'date-fns';
import {
  IOphalenInventarisatieregelsResultElement,
  IOphalenInventarisatiesResultElement,
} from '../../../../../../shared/src/api/v2/magazijn/inventarisatie';
import { ITabbladComponentProps } from '..';
import _ from 'lodash';
import { Kleur as EKleur } from '../../../../bedrijfslogica/constanten';
import FilterBalkV2 from '../../../../components/FilterBalkV2';
import { RootStoreContext } from '../../../../stores/RootStore';
import { EResultType } from '../../../../stores/CheckStore';
import { IOphalenMutatiesResultElement } from '../../../../../../shared/src/api/v2/magazijn/mutatie';
import { EMutatiesoort } from '../../../../bedrijfslogica/enums';
import { IFilterSchemaFilter } from '../../../../../../shared/src/models/filter';
import toast from 'react-hot-toast';

interface IProps extends ITabbladComponentProps, RouteComponentProps {}

enum EKolom {
  Mutatiedatum,
  Producttype,
  Productmerk,
  Referentiecode,
  Nieuw,
  Aantal,
}

interface IUrlState {
  selectie: number[];
  sortering: IAspKolomSorteringItem<EKolom>[];
}

const defaultUrlState: IUrlState = {
  selectie: [],
  sortering: [
    { key: EKolom.Mutatiedatum, sortering: ESortering.Ascending },
    // {
    //   key: EKolom.Productmerk,
    //   sortering: ESortering.Ascending,
    // },
    // {
    //   key: EKolom.Producttype,
    //   sortering: ESortering.Ascending,
    // },
  ],
};

const MutatiesZoekmeldingen: React.FC<IProps> = (props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(
    props,
    defaultUrlState,
    'mutatiesZoekmeldingen',
  );

  const { checkStore } = useContext(RootStoreContext);

  const [inventarisatie, setInventarisatie] = useState<IOphalenInventarisatiesResultElement | null>(
    null,
  );

  const ophalenInventarisatie = useCallback(async () => {
    const result = await api.v2.magazijn.inventarisatie.ophalenInventarisaties({
      filterSchema: {
        filters: [{ naam: 'IDS', data: [props.inventarisatieID] }],
      },
    });

    setInventarisatie(result.inventarisaties[0]);
  }, [props.inventarisatieID]);

  useEffect(() => {
    ophalenInventarisatie();
  }, [ophalenInventarisatie]);

  const [mutaties, setMutaties] = useState<IOphalenMutatiesResultElement[] | null>(null);

  const ophalenMutaties = useCallback(async () => {
    if (inventarisatie === null) {
      return;
    }

    const result = await api.v2.magazijn.mutatie.ophalenMutaties({
      filterSchema: {
        filters: [
          { naam: 'MAG_IDS', data: [inventarisatie.magazijn.MagID] },
          { naam: 'MUTATIESOORT_NAAM_ENUMS', data: [EMutatiesoort.Zoekmelding] },
          { naam: 'MUTATIEDATUM_VEM', data: inventarisatie.Inventarisatiedatum },
          inventarisatie.DatumAfgehandeld !== null
            ? { naam: 'MUTATIEDATUM_TEM', data: inventarisatie.DatumAfgehandeld }
            : null,
        ].filter((x) => x !== null) as IFilterSchemaFilter[],
      },
    });

    setMutaties(result.mutaties);
  }, [inventarisatie]);

  useEffect(() => {
    ophalenMutaties();
  }, [ophalenMutaties]);

  const keyExtractor = useCallback((item: IOphalenMutatiesResultElement) => item.ID, []);

  const kolommen = useMemo<ASPKolom<EKolom, IOphalenMutatiesResultElement>[]>(
    () => [
      {
        key: EKolom.Mutatiedatum,
        label: 'Mutatiedatum',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 150,
        renderer: (item: IOphalenMutatiesResultElement) => {
          return <span>{format(new Date(item.Mutatiedatum!), 'dd-MM-yyyy HH:mm')}</span>;
        },
      },
      {
        key: EKolom.Productmerk,
        label: 'Merk',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 100,
        renderer: (item: IOphalenMutatiesResultElement) => {
          if (item.producttype !== null) {
            return <span>{item.producttype.Merknaam}</span>;
          }
          return <span>{item.product!.producttype.Merknaam}</span>;
        },
        vergelijkingswaarde: (item) => {
          // if (producttypen === null || producten === null) {
          //   return null;
          // }
          // if (item.TypeID !== null) {
          //   const producttype = producttypen.find((x) => x.TypeID === item.TypeID)!;
          //   return producttype.Merknaam;
          // }
          // if (item.ProdID !== null) {
          //   const product = producten.find((x) => x.ProdID === item.ProdID)!;
          //   return product.producttype.Merknaam;
          // }
          return null;
        },
      },
      {
        key: EKolom.Producttype,
        label: 'Type',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 150,
        renderer: (item: IOphalenMutatiesResultElement) => {
          if (item.producttype !== null) {
            return <span>{item.producttype.Typenaam}</span>;
          }
          return <span>{item.product!.producttype.Typenaam}</span>;
        },
        vergelijkingswaarde: (item) => {
          // if (producttypen === null || producten === null) {
          //   return null;
          // }
          // if (item.TypeID !== null) {
          //   const producttype = producttypen.find((x) => x.TypeID === item.TypeID)!;
          //   return producttype.Typenaam;
          // }
          // if (item.ProdID !== null) {
          //   const product = producten.find((x) => x.ProdID === item.ProdID)!;
          //   return product.producttype.Typenaam;
          // }
          return null;
        },
      },
      {
        key: EKolom.Nieuw,
        label: 'N/G',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 75,
        renderer: (item: IOphalenMutatiesResultElement) => {
          return <span>{item.Nieuw ? 'N' : 'G'}</span>;
        },
      },
      {
        key: EKolom.Referentiecode,
        label: 'Referentiecode',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 125,
        renderer: (item: IOphalenMutatiesResultElement) => {
          return <span>{item.Referentiecode !== null ? item.Referentiecode : ''}</span>;
        },
      },
      {
        key: EKolom.Aantal,
        label: 'Zoek/Gevonden',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 105,
        renderer: (item: IOphalenMutatiesResultElement) => {
          return <span>{item.Aantal < 0 ? 'Zoekgemeld' : 'Gevonden'}</span>;
        },
      },
    ],
    [],
  );

  return (
    <>
      <MenuLayout
        menu={
          <div className="d-flex align-items-center">
            <button
              className="btn btn-sm btn-light d-flex align-items-center"
              style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
              disabled={urlState.selectie.length !== 1}
              onClick={async () => {
                if (inventarisatie === null || mutaties === null) {
                  return;
                }

                if (
                  (
                    await checkStore.bevestigen({
                      inhoud: <span>Geselecteerde producten als gevonden aanmerken?</span>,
                    })
                  ).type === EResultType.Annuleren
                ) {
                  return;
                }

                const prodID = mutaties.find((x) => x.ID === urlState.selectie[0])!.product!.ProdID;

                await api.v2.retourverwerking.gevondenMeldenProducten({
                  magID: inventarisatie.magazijn.MagID,
                  prodIDs: [prodID],
                });

                toast.success('Gevonden markeren is gelukt');

                return;
              }}
            >
              {/* <IconSend style={{ width: 16, height: 16, fill: Kleur.Grijs }} /> */}
              <span className="ml-2">Gevonden</span>
            </button>

            {/* <div className="d-flex flex-fill ml-3">
              <FilterBalkV2
                filters={filters}
                filterData={urlState.filterData}
                onFilterDataChange={(x) => setUrlStateSync('filterData', x)}
                onFilterSchemaChange={(x) => setFilterSchema(x)}
              />
            </div> */}
          </div>
        }
        body={
          <div className="d-flex flex-column flex-fill">
            {mutaties === null ? (
              <div className="flex-fill d-flex align-items-center justify-content-center">
                <LoadingSpinner />
              </div>
            ) : (
              <div className="d-flex flex-column flex-fill">
                <ASPTabel
                  rijen={mutaties}
                  kolommen={kolommen}
                  keyExtractor={keyExtractor}
                  selectie={urlState.selectie}
                  onSelectieChange={(selectie) => setUrlStateSync('selectie', selectie)}
                  sortering={urlState.sortering}
                  onSorteringChange={(sortering) => setUrlStateSync('sortering', sortering)}
                  // onWijzigenRij={async (x) => {}}
                  // onVerwijderenRij={async (x) => {
                  //   await new Promise((resolve) => setTimeout(resolve, 3000));
                  // }}
                  lokaalSorteren
                />
              </div>
            )}
          </div>
        }
      />
    </>
  );
};

export default withRouter(MutatiesZoekmeldingen);
