import { RouteComponentProps } from 'react-router';
import { useCallback, useMemo, useRef } from 'react';

/**
 * Zet de state op synchrone wijze, zodat het geen andere state overschrijft die ook
 * rondom hetzelfde moment wordt gezet. (datarace issue)
 */
export type SetUrlStateSyncFn<TState> = (path: keyof TState, value: TState[keyof TState]) => TState;

export type UseUrlStateResult<TState> = [
  TState,
  (state: TState) => void,
  SetUrlStateSyncFn<TState>,
];

export const genereerUrlStateQueryParam = <TState extends {}>(state: TState) => {
  const jsonString = JSON.stringify(state);
  const base64String = btoa(jsonString);
  return encodeURIComponent(base64String);
};

export const getUrlStateFromURLSearchParams = <TState extends {}>(
  urlSearchParams: URLSearchParams,
  key: string = 'state',
): TState | null => {
  const data = urlSearchParams.get(key);
  if (data === null) {
    return null;
  }
  const decodedString = decodeURIComponent(data);
  const jsonString = atob(decodedString);
  return JSON.parse(jsonString);
};

export interface IKeyOptions {
  getter?: (value: any) => any;
}

const useUrlState = <TState extends {}>(
  props: RouteComponentProps,
  defaultState: TState,
  key: string = 'state',
  keyOptions?: Partial<Record<keyof TState, IKeyOptions>>,
): UseUrlStateResult<TState> => {
  const defaultStateCached = useMemo(() => defaultState, []);
  const {
    location: { search, pathname },
    history,
  } = props;
  const urlSearchParams = useMemo(() => new URLSearchParams(search), [search]);

  const stateResult = getUrlStateFromURLSearchParams<TState>(urlSearchParams, key);

  let state: TState;
  if (stateResult === null) {
    state = defaultStateCached;
  } else {
    state = stateResult;
    if (keyOptions !== undefined) {
      const keys = Object.keys(keyOptions) as (keyof TState)[];
      for (const key of keys) {
        const keyOption = keyOptions[key];
        if (keyOption !== undefined) {
          const getter = keyOption.getter;
          if (getter !== undefined) {
            state[key] = getter(state[key]);
          }
        }
      }
    }
  }

  const lastState = useRef<TState>(state);

  const setter = useCallback(
    (state: TState) => {
      lastState.current = state;

      const encodedString = genereerUrlStateQueryParam(state);
      urlSearchParams.set(key, encodedString);

      history.replace({
        pathname,
        search: urlSearchParams.toString(),
      });
    },
    [urlSearchParams, key, history.push, pathname, lastState.current],
  );

  const syncSetter = useCallback(
    (path: keyof TState, value: TState[keyof TState]) => {
      const newValue: TState = {
        // @ts-ignore
        ...lastState.current,
        [path]: value,
      };
      setter(newValue);
      return newValue;
    },
    [setter, lastState.current],
  );

  return [state, setter, syncSetter];
};

export default useUrlState;
