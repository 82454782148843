import {
  IExternLoginResult,
  ILoginParams,
  ILogoutParams,
  ILogoutResult,
} from '../../../../../shared/src/api/v2/auth';
import api from '../../index';

const auth = {
  login: async (params: ILoginParams): Promise<IExternLoginResult> => {
    return await api.post('v2/auth/login', params);
  },
  logout: async (params: ILogoutParams): Promise<ILogoutResult> => {
    return await api.post('v2/auth/logout', params);
  },
};

export default auth;
