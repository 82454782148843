import api from '..';
import { IOphalenGeslachtenResult } from '../../../../shared/src/api/v2/geslacht';

const geslacht = {
  ophalen: async (): Promise<IOphalenGeslachtenResult> => {
    return await api.post('/v2/geslacht/ophalen-geslachten');
  },
};

export default geslacht;
