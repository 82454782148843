import _ from 'lodash';
import React, { useCallback, useContext, useState, useMemo, useEffect } from 'react';
import { ModalFooter, ModalTitle } from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/esm/ModalHeader';
import {
  IOphalenBoekingenResultElement,
  IOphalenBoekingRegelsResultElement,
  IOphalenExportsResultElement,
} from '../../../../../../shared/src/api/v2/boekhouding/boeking';
import { IOphalenFacturenBasisResultElement } from '../../../../../../shared/src/api/v2/factuur';
import { IOphalenFacturenResultElement } from '../../../../../../shared/src/api/v2/inkoopfactuur';
import api from '../../../../api';
import IDialoogProps from '../../../../core/IDialoogProps';
import Dialoog from '../../../dialogen/Dialoog';
import LoadingSpinner from '../../../Gedeeld/LoadingSpinner';
import Tabblad, { ITabblad } from '../../../layout/Tabblad';
import TechnischeInformatieKnop from '../../../TechnischeInformatieKnop';
import BoekingregelsTab from './BoekingregelsTab';
import BoekingTab from './BoekingTab';

interface IProps extends IDialoogProps<{}> {
  boekingID: number;
}

enum ETabblad {
  Boeking,
  Regels,
}

export interface IContext {
  boeking: IOphalenBoekingenResultElement | null;
  boekingregels: IOphalenBoekingRegelsResultElement[] | null;
  exports: IOphalenExportsResultElement[] | null;
  facturen: IOphalenFacturenBasisResultElement[] | null;
  inkoopfacturen: IOphalenFacturenResultElement[] | null;
}

export const BoekingContext = React.createContext<IContext>({
  boeking: null,
  boekingregels: null,
  exports: null,
  facturen: null,
  inkoopfacturen: null,
});

const BoekingInfoDialoog: React.FC<IProps> = (props) => {
  const { dialoogIndex, onAnnuleren, onSuccess, open } = props;
  const [tabblad, setTabblad] = useState<ETabblad>(ETabblad.Boeking);

  const [boeking, setBoeking] = useState<IOphalenBoekingenResultElement | null>(null);
  const [boekingregels, setBoekingregels] = useState<IOphalenBoekingRegelsResultElement[] | null>(
    null,
  );
  const [exports, setExports] = useState<IOphalenExportsResultElement[] | null>(null);
  const [facturen, setFacturen] = useState<IOphalenFacturenBasisResultElement[] | null>(null);
  const [inkoopfacturen, setInkoopfacturen] = useState<IOphalenFacturenResultElement[] | null>(
    null,
  );

  const ophalenBoeking = useCallback(async () => {
    const regelsResult = await api.v2.boeking.ophalenBoekingen({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: props.boekingID,
          },
        ],
      },
    });

    const exportsResult = await api.v2.boeking.ophalenExports({
      filterSchema: {
        filters: [{ naam: 'IDS', data: regelsResult.boekingen.map((x) => x.BoekingExportID) }],
      },
    });
    setExports(exportsResult.exports);

    setBoeking(regelsResult.boekingen[0]);
  }, [props.boekingID]);

  useEffect(() => {
    ophalenBoeking();
  }, [ophalenBoeking]);

  const ophalenBoekingregels = useCallback(async () => {
    if (boeking === null) {
      return;
    }

    const regelsResult = await api.v2.boeking.ophalenBoekingregels({
      filterSchema: {
        filters: [
          {
            naam: 'BOEKING_IDS',
            data: [boeking.ID],
          },
        ],
      },
      orderSchema: {
        orders: [
          //   { naam: 'DAGBOEKNUMMER', richting: 'ASC' },
          { naam: 'BOEKDATUM', richting: 'DESC' },
          { naam: 'BOEKNUMMER', richting: 'DESC' },
          { naam: 'REGELNUMMER', richting: 'ASC' },
        ],
      },
    });

    setBoekingregels(regelsResult.regels);
  }, [boeking]);

  useEffect(() => {
    ophalenBoekingregels();
  }, [ophalenBoekingregels]);

  const ophalenFacturen = useCallback(async () => {
    if (boekingregels === null) {
      return;
    }

    const result = await api.v2.factuur.ophalenFacturenBasis({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: _.uniq(boekingregels.filter((x) => x.FactID !== null).map((x) => x.FactID)),
          },
        ],
      },
    });

    setFacturen(result.facturen);
  }, [boekingregels]);

  useEffect(() => {
    ophalenFacturen();
  }, [ophalenFacturen]);

  const ophalenInkoopfacturen = useCallback(async () => {
    if (boekingregels === null) {
      return;
    }

    const result = await api.v2.inkoopfactuur.ophalenFacturen({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: _.uniq(boekingregels.filter((x) => x.InkFactID !== null).map((x) => x.InkFactID)),
          },
        ],
      },
    });

    setInkoopfacturen(result.facturen);
  }, [boekingregels]);

  useEffect(() => {
    ophalenInkoopfacturen();
  }, [ophalenInkoopfacturen]);

  const tabbladen = useMemo<ITabblad<ETabblad>[]>(
    () => [
      {
        id: ETabblad.Boeking,
        label: 'Boeking',
        content: BoekingTab,
      },
      // {
      //   id: ETabblad.Regels,
      //   label: 'Regels',
      //   content: BoekingregelsTab,
      // },
    ],
    [],
  );

  if (
    boeking === null ||
    boekingregels === null ||
    exports === null ||
    facturen === null ||
    inkoopfacturen === null
  ) {
    return <LoadingSpinner />;
  }

  return (
    <Dialoog index={props.dialoogIndex ?? 0} modalProps={{ size: 'xl' }}>
      <ModalHeader>
        <ModalTitle>Boekinginformatie</ModalTitle>
      </ModalHeader>
      <BoekingContext.Provider
        value={{
          boeking,
          boekingregels,
          exports,
          facturen,
          inkoopfacturen,
        }}
      >
        <div className="d-flex flex-column flex-fill" style={{ minHeight: `550px` }}>
          <>
            <div className="d-flex flex-column flex-fill">
              <Tabblad
                tabbladen={tabbladen}
                geselecteerd={tabblad}
                onSelectieChange={(id) => {
                  setTabblad(id);
                }}
              />
            </div>
            <ModalFooter className="d-flex flex-row justify-content-start">
              <button className="btn btn-primary" onClick={onAnnuleren} style={{ width: 100 }}>
                Sluiten
              </button>
              <div className="flex-fill" />
              <TechnischeInformatieKnop content={() => <div>BoekingID: {props.boekingID}</div>} />
            </ModalFooter>
          </>
        </div>
      </BoekingContext.Provider>
    </Dialoog>
  );
};

export default BoekingInfoDialoog;
