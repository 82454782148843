import React, { useCallback, useContext, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import MenuLayout from '../../../../components/MenuLayout';
import { IconPlay, IconStop } from '../../../../components/Icons';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import { RootStoreContext } from '../../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import api from '../../../../api';
import { useBulkverzenderStore } from '../../../../stores/BulkverzenderStore';
import { LoadingSpinnerCenter } from '../../../../components/Gedeeld/LoadingSpinner';
import TegelBasis from '../../../../components/tegel/TegelBasis';
import { EResultType } from '../../../../stores/CheckStore';

const ActivatieKnop = styled.button<{ disabled?: boolean; isActief: boolean }>`
  background-color: ${({ disabled, isActief }) =>
    disabled ? '#b0b0b0' : isActief ? Kleur.Rood : Kleur.Groen};
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  font-size: 14px;
  font-weight: bold;
  transition: background-color 0.2s;
  margin-right: 20px;

  &:hover {
    background-color: ${({ disabled, isActief }) =>
      disabled ? '#b0b0b0' : isActief ? Kleur.DonkerRood : Kleur.DonkerGroen};
  }
`;

// const initielePaginatie: IPaginatiePositie = {
//   index: 0,
//   aantal: 50,
// };
//
// interface IData {
//   totaalAantal: number;
//   berichten: { [index: number]: IBulkVerzendBericht };
// }

interface IProps extends RouteComponentProps {}

const Overzicht = observer((props: IProps) => {
  const bulkverzenderStore = useBulkverzenderStore();
  const { checkStore } = useContext(RootStoreContext);
  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    bulkverzenderStore.initialiseer();
  }, [bulkverzenderStore.initialiseer]);
  // const globaleRenderer = useContext(GlobaleRendererContext);
  // tslint:disable-next-line:variable-name
  // const [data, _setData] = useState<IRemoteData<IData>>(createPendingRemoteData());
  // const dataRef = useRef(data);
  // const setData = useCallback((data: IRemoteData<IData>) => {
  //   dataRef.current = data;
  //   _setData(data);
  // }, []);

  // const [uitgeklapt, setUitgeklapt] = useState<number[]>([]);

  const [isBezig, setIsBezig] = useState(false);

  const { instellingStore } = useContext(RootStoreContext);
  useEffect(() => {
    instellingStore.ophalenInstellingen();
  }, []);

  const isActief = instellingStore.IsBulkverzenderActief;

  const handleIsActiefMuterenClick = useCallback(async () => {
    setIsBezig(true);

    await api.v2.instelling.deelsWijzigenInstellingen({
      applicatie: {
        isBulkverzenderActief: !isActief,
      },
    });
    await instellingStore.ophalenInstellingen();

    setIsBezig(false);
  }, [isActief]);

  const handleProbleemVerholpenClick = useCallback(async () => {
    setIsBezig(true);

    if (
      (
        await checkStore.bevestigen({
          titel: 'Bevestig probleem verholpen',
          inhoud:
            'Door het probleem als verholpen te markeren, zal de fout worden verwijderd en de bulkverzender weer actief worden. Weet u zeker dat u dit wilt doen?',
        })
      ).type === EResultType.Annuleren
    ) {
      setIsBezig(false);
      return;
    }

    await api.v2.bulkVerzending.resetError({});

    setIsBezig(false);
  }, []);

  // const ophalenData = useCallback(async (paginatie: IPaginatiePositie, uitbreiden = false) => {
  //   const result = await api.v2.bulkVerzending.ophalenBulkVerzendBerichten({
  //     paginatie,
  //   });
  //
  //   const berichten = result.items.reduce(
  //     (acc, item, i) => {
  //       acc[paginatie.index + i] = item;
  //       return acc;
  //     },
  //     uitbreiden ? dataRef.current.data?.berichten ?? {} : {},
  //   );
  //
  //   setData(
  //     createReadyRemoteData({
  //       totaalAantal: result.totaalAantal,
  //       berichten,
  //     }),
  //   );
  // }, []);
  //
  // const dataVerversen = useCallback(async () => {
  //   await ophalenData(initielePaginatie);
  // }, [ophalenData]);
  //
  // useEffect(() => {
  //   dataVerversen();
  // }, []);

  if (bulkverzenderStore.data === null || bulkverzenderStore.data === 'laden') {
    return <LoadingSpinnerCenter />;
  }

  return (
    <MenuLayout
      menu={
        <div className="d-flex align-items-center">
          <ActivatieKnop
            onClick={handleIsActiefMuterenClick}
            isActief={isActief}
            disabled={isBezig}
          >
            {isActief ? (
              <>
                <IconStop style={{ width: 18, height: 18, fill: Kleur.Wit }} />
                <span className="ml-2">Deactiveren verzender</span>
              </>
            ) : (
              <>
                <IconPlay style={{ width: 18, height: 18, fill: Kleur.Wit }} />
                <span className="ml-2">Activeren verzender</span>
              </>
            )}
          </ActivatieKnop>

          {isActief ? (
            <span style={{ color: Kleur.Groen }} className="font-weight-bold">
              De bulkverzender is actief en pakt berichten op die in afwachting zijn.
            </span>
          ) : (
            <span style={{ color: Kleur.Rood }} className="font-weight-bold">
              De bulkverzender is niet actief.
            </span>
          )}
        </div>
      }
      body={
        <div className="p-3">
          <div className="row">
            <div className="col-4">
              <TegelBasis titel="Status">
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <span
                    style={{
                      fontSize: 31,
                      fontWeight: 'bold',
                    }}
                  >
                    {bulkverzenderStore.data!.teVerzendenBerichtenAantal}
                  </span>
                  <span>berichten te verzenden</span>
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center mt-3 mb-2">
                  <span
                    style={{
                      fontSize: 31,
                      fontWeight: 'bold',
                    }}
                  >
                    {bulkverzenderStore.data!.teReviewenBerichtenAantal}
                  </span>
                  <span>berichten te reviewen</span>
                </div>
              </TegelBasis>
            </div>
          </div>
          <TegelBasis titel="Fouten">
            {bulkverzenderStore.data!.error === null ? (
              <span>Er zijn momenteel geen fouten aanwezig.</span>
            ) : (
              <div>
                <span style={{ color: Kleur.Rood }}>{bulkverzenderStore.data!.error.Error}</span>
                <button
                  className="btn btn-sm btn-light mt-2"
                  style={{
                    border: `1px solid ${Kleur.Grijs}`,
                  }}
                  onClick={handleProbleemVerholpenClick}
                  disabled={isBezig}
                >
                  <span>Ik heb het probleem verholpen</span>
                </button>
              </div>
            )}
          </TegelBasis>
        </div>
      }
    />
  );
});

export default Overzicht;
