import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import IDialoogProps from '../../../../../core/IDialoogProps';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import LoadingSpinner from '../../../../../components/Gedeeld/LoadingSpinner';
import api from '../../../../../api';
import { IOphalenProductmerkenResultElement } from '../../../../../../../shared/src/api/v2/product/merk';
import nameof from '../../../../../core/nameOf';
import FormikVeldFout from '../../../../../components/formulier/FormikVeldFout';
import VinkVeld from '../../../../../components/formulier/VinkVeld';
import Combobox from '../../../../../components/formulier/Combobox';
import { IOphalenDienstenResultElement } from '../../../../../../../shared/src/api/v2/dienst/service';
import Dialoog from '../../../../../components/dialogen/Dialoog';
import nameOf from '../../../../../core/nameOf';
import NumeriekVeld from '../../../../../components/formulier/NumeriekVeld';
import * as Yup from 'yup';
import { RootStoreContext } from '../../../../../stores/RootStore';
import { EResultType } from '../../../../../stores/CheckStore';

interface IProps extends IDialoogProps<null> {}

export interface IFormikValues {
  Actief: boolean;
  GarantiePeriode: number;
  Merkloos: boolean;
  Merknaam: string;
  ServDienstID_Garantie: number | null;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  Actief: 'Actief',
  GarantiePeriode: 'Garantieperiode (maanden)',
  Merkloos: 'Merkloos',
  Merknaam: 'Merknaam',
  ServDienstID_Garantie: 'Garantieleverancier',
};

const ToevoegenDialoog: React.FC<IProps> = (props) => {
  const { onAnnuleren, onSuccess, open, dialoogIndex } = props;
  const { checkStore } = useContext(RootStoreContext);

  const [servicediensten, setServicediensten] = useState<IOphalenDienstenResultElement[] | null>(
    null,
  );

  useEffect(() => {
    (async () => {
      const diensten = (
        await api.v2.dienst.service.ophalenDiensten({
          filterSchema: {
            filters: [
              {
                naam: 'IS_ACTIEF',
                data: true,
              },
            ],
          },
        })
      ).diensten;

      setServicediensten(diensten);
    })();
  }, []);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      const params = {
        // merkID: props.merkID,
        merknaam: values.Merknaam,
        merkloos: values.Merkloos,
        garantiePeriode: values.GarantiePeriode,
        servDienstID_Garantie: values.ServDienstID_Garantie,
        actief: values.Actief,
        logoBestand_BestID: null,
      };

      const checkData = await api.v2.product.merk.checkToevoegen(params);
      const checkResult = await checkStore.controleren({
        checkData,
      });
      if (checkResult.type === EResultType.Annuleren) {
        actions.setSubmitting(false);
        return;
      }

      await api.v2.product.merk.toevoegen(params);

      onSuccess(null);
      actions.setSubmitting(false);
    },
    [],
  );

  const initialValues = useMemo<IFormikValues>(() => {
    return {
      Actief: true,
      Merknaam: '',
      GarantiePeriode: 24,
      Merkloos: false,
      // LogoAfbeeldingToken: productMerk !== null ? productMerk.LogoAfbeeldingToken : '',
      ServDienstID_Garantie: null,
    };
  }, []);

  const validationSchema = useMemo(() => {
    const fields: Partial<Record<keyof IFormikValues, any>> = {
      Merknaam: Yup.string().required(),
    };

    return Yup.object().shape(fields);
  }, []);

  return (
    <Dialoog index={dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Toevoegen productmerk</ModalTitle>
      </ModalHeader>

      {servicediensten === null ? (
        <div className="d-flex flex-fill align-items-center justify-content-center">
          <LoadingSpinner />
        </div>
      ) : (
        <Formik<IFormikValues>
          // enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          render={(formikProps: FormikProps<IFormikValues | null>) => {
            const { submitForm, isSubmitting, isValid, values } = formikProps;
            return (
              <>
                <ModalBody>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-6">
                        <label>{veldnamen.Merknaam}</label>
                        <Field
                          name={nameof<IFormikValues>('Merknaam')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            return <input {...field} className="form-control" type="text" />;
                          }}
                        />
                      </div>
                      <div className="col-6">
                        <label>{veldnamen.Merkloos}</label>
                        <Field
                          name={nameof<IFormikValues>('Merkloos')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            return (
                              <VinkVeld
                                aangevinkt={field.value}
                                onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                              />
                            );
                          }}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 mt-2">
                        <label>{veldnamen.ServDienstID_Garantie}</label>

                        <Field
                          name={nameof<IFormikValues>('ServDienstID_Garantie')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            return (
                              <Combobox
                                legeOptieTonen
                                geselecteerd={field.value}
                                onSelectieChange={(x) => form.setFieldValue(field.name, x)}
                                opties={servicediensten.map((x) => {
                                  return {
                                    id: x.ID,
                                    label: x.NaamIdent!,
                                  };
                                })}
                                isWisbaar
                              />
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <div className="row col-6">
                          <label>{veldnamen.GarantiePeriode}</label>
                          <Field
                            name={nameOf<IFormikValues>('GarantiePeriode')}
                            render={(fieldProps: FieldProps<IFormikValues>) => {
                              const { field, form } = fieldProps;
                              return (
                                <NumeriekVeld
                                  waarde={field.value}
                                  onChange={(x) => form.setFieldValue(field.name, x)}
                                  min={0}
                                  max={120}
                                />
                              );
                            }}
                          />
                        </div>
                      </div>

                      <div className="col-12 mt-3">
                        <label>{veldnamen.Actief}</label>
                        <Field
                          name={nameof<IFormikValues>('Actief')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <VinkVeld
                                aangevinkt={field.value}
                                onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                              />
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </ModalBody>
                <ModalFooter className="d-flex justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    disabled={isSubmitting || !isValid}
                    style={{ width: 100 }}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={onAnnuleren}
                    style={{ width: 100 }}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Dialoog>
  );
};

export default ToevoegenDialoog;
