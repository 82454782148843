import styled from 'styled-components';

export const Root = styled.div`
  width: 100%;
  min-height: 21px;
  font-size: 13px;
  display: flex;
  align-items: center;

  .ongekoppeld-container {
    display: flex;
    align-items: center;
    flex: 1;

    a {
      flex: 1;
    }
  }

  .gekoppeld-container {
    display: flex;
    align-items: center;

    .dropdown {
      .dropdown-toggle {
        padding: 0;
        background-color: transparent;
        border: 0;
        margin-left: 5px;
        padding-right: 5px;
        &:after {
          display: none;
        }
      }
    }
  }
`;
