import React from 'react';
import { IDataFormulierProps } from '../index';

const PersoonDataFormulier = (props: IDataFormulierProps<{ persID: number | null }>) => {
  return (
    <div className="mt-3">
      <label>Persoon</label>
    </div>
  );
};

export default PersoonDataFormulier;
