import {
  IOphalenEnergielabelsParams,
  IOphalenEnergielabelsResult,
} from '../../../../../shared/src/api/v2/product/energielabel';
import ICheckData from '../../../../../shared/src/models/ICheckData';
import api from '../../index';

const energielabel = {
  ophalenEnergielabels: async (
    params: IOphalenEnergielabelsParams,
  ): Promise<IOphalenEnergielabelsResult> => {
    return await api.post('v2/product/energielabel/ophalen-energielabels', params);
  },
};

export default energielabel;
