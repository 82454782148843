import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Root } from './style';
import { OverlayLink } from '../PersoonVisualisatie/style';
import { RootStoreContext } from '../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import {
  IOphalenRelatiesResultElementV2,
  IRelatieAttributen,
} from '../../../../../shared/src/api/v2/relatie';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Kleur } from '../../../bedrijfslogica/constanten';
import { EHoedanigheid } from '../RelatieSelectieDialoog';
import { IconOpenInNew, IconWarning } from '../../Icons';
import Skeleton from 'react-loading-skeleton';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import InfoCard from './InfoCard';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../models/IRemoteData';

export interface IOptions {
  geenLinkToepassen?: boolean;
  linkStyle?: React.CSSProperties;
  relatienummerWeergeven?: boolean;
  infoCardOffset?: [number, number];
}

const defaultOptions: IOptions = {
  geenLinkToepassen: false,
  relatienummerWeergeven: false,
};

export interface INaamComponentProps {
  relatie: IOphalenRelatiesResultElementV2 | null;
  naam: string | null;
}

interface IProps extends RouteComponentProps {
  relID: number;
  weergaveNaam?: string;
  options?: IOptions;
  onGenavigeerd?: () => void;
  relatieLinkBuilder?: (hoedanigheid: EHoedanigheid, relID: number) => string;
  naamComponent?: React.ComponentType<INaamComponentProps>;
  rootStyle?: React.CSSProperties;
}

const RelatieVisualisatie: React.FC<IProps> = observer((props) => {
  const options: IOptions = useMemo(() => ({ ...defaultOptions, ...props.options }), [
    props.options,
  ]);
  const { toetsenbordStore, relatieStore } = useContext(RootStoreContext);
  const [isHovered, setIsHovered] = useState(false);
  const relatie = useMemo<IOphalenRelatiesResultElementV2 | null>(() => {
    const rel = relatieStore.relaties[props.relID];
    if (rel === undefined) {
      return null;
    }
    return rel;
  }, [relatieStore.relaties, props.relID]);
  const attributen = useMemo<IRemoteData<IRelatieAttributen>>(() => {
    const item = relatieStore.relatieAttributen[props.relID];
    if (item === undefined) {
      return createPendingRemoteData();
    }
    return createReadyRemoteData(item);
  }, [props.relID, relatieStore.relatieAttributen]);

  useEffect(() => {
    if (relatie !== null) {
      return;
    }
    relatieStore.enqueueRelatieOphalen(props.relID);
  }, [props.relID, props.weergaveNaam]);

  const isAanHetOphalen = useMemo(() => {
    return relatieStore.relatieOphaalQueue.findIndex((id) => id === props.relID) !== -1;
  }, [relatieStore.relatieOphaalQueue, props.relID]);

  const geformatteerdeNaam = useMemo(() => {
    if (props.weergaveNaam !== undefined) {
      return props.weergaveNaam;
    }
    if (relatie === null) {
      return null;
    }

    switch (relatie.Relatiesoort) {
      case 'O':
        return relatie.weergavenaam;
        // if (relatie.organisatie !== null) {
        //   const organisatie = relatie.organisatie;
        //   return formatteerOrganisatieNaam({ naam: organisatie.Naam });
        // }
        break;
      case 'P':
        if (relatie.persoon !== null) {
          return relatie.weergavenaam;

          // const persoon = relatie.persoon;
          // return formatteerRelatieNaam({
          //   voorvoegsel: persoon.Voorvoegsel,
          //   voornaam: persoon.Voornaam,
          //   voorletters: persoon.Voorletters,
          //   achternaam: persoon.Achternaam || undefined,
          //   aanhefKort: persoon.geslacht.AanhefKort || undefined,
          //   organisatienaam: relatie.organisatie !== null ? relatie.organisatie.Naam : null,
          //   relatiesoort: relatie.Relatiesoort,
          // });
        }
        break;
    }
    throw new Error(
      `Relatie (relID: ${relatie.RelID}) kan niet worden weergeven. Relatie of organisatie is niet gevuld voor de bijhorende relatiesoort (${relatie.Relatiesoort})`,
    );
  }, [relatie, props.weergaveNaam]);

  const linkToepassen = useMemo(() => !options.geenLinkToepassen && relatie !== null, [
    options.geenLinkToepassen,
    relatie,
  ]);

  const link = useMemo(() => {
    if (relatie === null) {
      return null;
    }
    return (
      props.relatieLinkBuilder?.(
        relatie.hoedanigheid!.NaamEnum === 'KLANT'
          ? EHoedanigheid.Klant
          : EHoedanigheid.Leverancier,
        relatie.RelID,
      ) ??
      `/${relatie.hoedanigheid!.NaamEnum === 'KLANT' ? 'klant' : 'leverancier'}/${relatie.RelID}`
    );
  }, [relatie]);

  const NaamComp = useMemo(() => {
    return (
      props.naamComponent ??
      ((naamProps: INaamComponentProps) => {
        return (
          <span
            style={{
              color:
                naamProps.relatie === null
                  ? undefined
                  : naamProps.relatie.hoedanigheid!.NaamEnum === 'LEVERANCIER'
                  ? Kleur.Leverancier
                  : Kleur.Klant,
              textDecoration:
                naamProps.relatie !== null && naamProps.relatie.Vervangende_RelID !== null
                  ? 'line-through'
                  : undefined,
              ...options.linkStyle,
            }}
          >
            {naamProps.naam}
          </span>
        );
      })
    );
  }, [props.naamComponent]);

  const [showOverlay, setShowOverlay] = useState(false);
  const showOverlayTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const handleMouseEnterOverlay = useCallback(() => {
    if (showOverlayTimeoutRef.current !== null) {
      clearTimeout(showOverlayTimeoutRef.current);
    }
    showOverlayTimeoutRef.current = setTimeout(() => setShowOverlay(true), 800);
  }, []);
  const handleMouseLeaveOverlay = useCallback(() => {
    if (showOverlayTimeoutRef.current !== null) {
      clearTimeout(showOverlayTimeoutRef.current);
    }
    showOverlayTimeoutRef.current = setTimeout(() => setShowOverlay(false), 400);
  }, []);
  const renderOverlay = useCallback(
    (p: any) => {
      return (
        <div
          id={`relatie-visualisatie-overlay-${props.relID}`}
          {...p}
          onMouseEnter={handleMouseEnterOverlay}
          onMouseLeave={handleMouseLeaveOverlay}
          style={{
            ...p.style,
            zIndex: 99999,
            border: `1px solid ${
              relatie === null
                ? Kleur.Grijs
                : relatie.hoedanigheid!.NaamEnum === 'LEVERANCIER'
                ? Kleur.Leverancier
                : Kleur.Klant
            }`,
            borderRadius: 5,
          }}
        >
          <InfoCard relID={props.relID} onSluiten={() => setShowOverlay(false)} />
        </div>
      );
    },
    [props.relID, handleMouseEnterOverlay, handleMouseLeaveOverlay, relatie],
  );

  const infoCardOffset = useMemo<[number, number]>(() => {
    if (options.infoCardOffset === undefined) {
      return [0, 5];
    }
    return [options.infoCardOffset[0], options.infoCardOffset[1] + 5];
  }, [options]);

  return (
    <OverlayTrigger
      trigger="hover"
      // placement="bottom-start"
      placement="auto-start"
      overlay={renderOverlay}
      show={showOverlay}
      popperConfig={
        {
          modifiers: {
            name: 'offset',
            options: {
              offset: infoCardOffset,
            },
          },
        } as any
      }
    >
      <Root
        onMouseEnter={() => {
          setIsHovered(true);
          handleMouseEnterOverlay();
        }}
        onMouseLeave={() => {
          setIsHovered(false);
          handleMouseLeaveOverlay();
        }}
        style={props.rootStyle}
      >
        {isAanHetOphalen && geformatteerdeNaam === null ? (
          <div className="ongekoppeld-container">
            <Skeleton width={150} />
          </div>
        ) : (
          <div className="gekoppeld-container">
            {linkToepassen ? (
              <div className="d-flex align-items-center">
                <OverlayLink
                  onClick={(ev) => {
                    ev.stopPropagation();
                    if (relatie === null) {
                      return;
                    }

                    if (toetsenbordStore.shift) {
                      window.open(link!, '_blank');
                      // alert('TODO');
                      return;
                    } else {
                      props.history.push(link!);
                    }

                    if (props.onGenavigeerd) {
                      props.onGenavigeerd();
                    }
                  }}
                >
                  <NaamComp relatie={relatie} naam={geformatteerdeNaam} />
                </OverlayLink>
                {attributen.state === ERemoteDataState.Ready &&
                  attributen.data!.heeftDuplicaatSuggestie && (
                    <div
                      style={{
                        marginLeft: 5,
                        position: 'relative',
                        top: -1,
                      }}
                    >
                      <IconWarning
                        style={{
                          fill: Kleur.LichtGeel,
                          width: 16,
                          height: 16,
                        }}
                      />
                    </div>
                  )}
                {isHovered && (
                  <IconOpenInNew
                    style={{
                      width: 16,
                      height: 16,
                      marginLeft: 5,
                      marginTop: 3,
                      cursor: 'pointer',
                      fill: Kleur.Grijs,
                    }}
                    onClick={(ev) => {
                      window.open(link!, '_blank');
                    }}
                  />
                )}
              </div>
            ) : (
              <span>{geformatteerdeNaam}</span>
            )}
            {options.relatienummerWeergeven && relatie !== null && (
              <span>&nbsp;-&nbsp;{relatie.Relatienummer}</span>
            )}
            <div className="flex-fill" />
          </div>
        )}
      </Root>
    </OverlayTrigger>
  );
});

export default withRouter(RelatieVisualisatie);
