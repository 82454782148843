import React, { useCallback, useContext, useMemo, useState } from 'react';
import { IUitgeklapteRijProps } from '../../../../../components/tabel/ASPTabel/Body/UitgeklapteRij';
import {
  IAanbiedenVoorVerzendenParams,
  IBulkbericht,
} from '../../../../../../../shared/src/api/v2/bulkbericht';
import { BulkberichtenContext, Kolom } from '../index';
import RelatiesTabel from './RelatiesTabel';
import TabelInspringBlok from '../../../../../components/layout/TabelInspringBlok';
import CodeBewerker from '../../../../../components/formulier/CodeBewerker';
import FilterBalkV2, {
  IFilter,
  IFilterData,
  IWeergaveProps,
} from '../../../../../components/FilterBalkV2';
import MultiComboboxV2, {
  EnkeleProvider,
  IRepresentatieProps,
  Provider,
} from '../../../../../components/formulier/MultiComboboxV2';
import { ASPKolom, EAspKolomBreedteType } from '../../../../../components/tabel/ASPTabel/types';
import styled from 'styled-components';
import { IconPlay } from '../../../../../components/Icons';
import { Kleur } from '../../../../../bedrijfslogica/constanten';
import { GlobaleRendererContext } from '../../../../../one-off-components/GlobaleRenderer';
import AanbiedenVoorVerzendenProgressieDialoog from './AanbiedenVoorVerzendenProgressieDialoog';
import { RootStoreContext } from '../../../../../stores/RootStore';
import { EResultType } from '../../../../../stores/CheckStore';
import api from '../../../../../api';
import EmailadressenTabel from './EmailadressenTabel';
import AanbiedenVoorVerzendenDialoog, {
  IAanbiedenVoorVerzendenDialoogResult,
} from './AanbiedenVoorVerzendenDialoog';

const AanbiedenKnop = styled.button<{ disabled?: boolean }>`
  background-color: ${({ disabled }) => (disabled ? '#b0b0b0' : Kleur.Groen)};
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  font-size: 14px;
  font-weight: bold;
  transition: background-color 0.2s;

  &:hover {
    background-color: ${({ disabled }) => (disabled ? '#b0b0b0' : Kleur.DonkerGroen)};
  }
`;

export interface ITabelProps {
  selectieQuery: string;
  voorkeurID: number | null;
  filterData: IFilterData<BulkberichtUitgeklapteRijFilters>[];
}

export type BulkberichtUitgeklapteRijFilters = 'voorkeur';

const BulkberichtUitgeklapteRij = (props: IUitgeklapteRijProps<Kolom, IBulkbericht>) => {
  const globaleRenderer = useContext(GlobaleRendererContext);
  const { checkStore } = useContext(RootStoreContext);
  const bulkberichtenContext = useContext(BulkberichtenContext);
  const bulkbericht = props.regel;
  // Bestaat hier altijd, anders zou de regel niet bestaan
  const data = bulkberichtenContext.data.data!;

  const voorkeur = bulkbericht.VoorkeurID === null ? null : data.voorkeuren[bulkbericht.VoorkeurID];
  const ontvangersselectie =
    bulkbericht.OntvangersselectieID === null
      ? null
      : data.ontvangersselecties[bulkbericht.OntvangersselectieID];
  const maatwerkselectie =
    bulkbericht.MaatwerkselectieID === null
      ? null
      : data.maatwerkselecties[bulkbericht.MaatwerkselectieID];
  const sjabloon = data.sjablonen[bulkbericht.SjabID];
  const ontvangerssoort = useMemo(() => {
    if (ontvangersselectie !== null) {
      return data.ontvangerssoorten[ontvangersselectie.OntvangerssoortID];
    }
    if (maatwerkselectie !== null) {
      return data.ontvangerssoorten[maatwerkselectie.OntvangerssoortID];
    }
    throw new Error('Geen ontvangersselectie of maatwerkselectie');
  }, [ontvangersselectie, maatwerkselectie, data.ontvangerssoorten]);
  const selectieQuery = useMemo(() => {
    if (ontvangersselectie !== null) {
      return ontvangersselectie.Query;
    }
    if (maatwerkselectie !== null) {
      return maatwerkselectie.Query;
    }
    throw new Error('Geen ontvangersselectie of maatwerkselectie');
  }, [ontvangersselectie, maatwerkselectie]);

  const Tabel = useMemo<React.ComponentType<ITabelProps>>(() => {
    switch (ontvangerssoort.NaamEnum) {
      case 'RELATIES': {
        return RelatiesTabel;
      }
      case 'E-MAILADRESSEN':
        return EmailadressenTabel;
    }

    throw new Error('Niet ondersteunde ontvangerssoort');
  }, [ontvangerssoort.NaamEnum]);

  const [filterData, setFilterData] = useState<IFilterData<BulkberichtUitgeklapteRijFilters>[]>([
    {
      naam: 'voorkeur',
      data: 'voldoet_aan_voorkeur',
      isActief: voorkeur !== null,
    },
  ]);
  const filters = useMemo<IFilter<BulkberichtUitgeklapteRijFilters>[]>(
    () => [
      {
        naam: 'voorkeur',
        altijdWeergevenInBalk: true,
        weergave: VoorkeurFilter,
        disabled: voorkeur === null,
      },
    ],
    [voorkeur],
  );

  const handleAanbiedenVoorVerzendenClick = useCallback(async () => {
    const aanbiedenResult = await globaleRenderer.render<IAanbiedenVoorVerzendenDialoogResult | null>(
      (renderProps) => (
        <AanbiedenVoorVerzendenDialoog
          open
          onSuccess={(x) => renderProps.destroy(x)}
          onAnnuleren={() => renderProps.destroy(null)}
        />
      ),
    );
    if (aanbiedenResult === null) {
      return;
    }

    const params: IAanbiedenVoorVerzendenParams = {
      id: bulkbericht.ID,
      directVersturen: aanbiedenResult!.directVersturen,
      prioriteitID: aanbiedenResult!.prioriteitID,
      tijdvakID: aanbiedenResult!.tijdvakID,
    };

    const checkData = await api.v2.bulkbericht.checkAanbiedenVoorVerzenden(params);
    if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
      return;
    }

    const achtergrondProces = await api.v2.bulkbericht.aanbiedenVoorVerzendenAchtergrondproces(
      params,
    );

    const isSuccess = await globaleRenderer.render((renderProps) => (
      <AanbiedenVoorVerzendenProgressieDialoog
        open
        onSuccess={() => renderProps.destroy(true)}
        onAnnuleren={() => renderProps.destroy(false)}
        achtergrondProces={achtergrondProces}
      />
    ));
    if (!isSuccess) {
      return;
    }

    await bulkberichtenContext.dataVerversen();
  }, [bulkbericht.ID, bulkberichtenContext.dataVerversen]);

  return (
    <div className="d-flex flex-fill">
      <TabelInspringBlok />
      <div className="d-flex flex-fill flex-column">
        <div className="flex-fill d-flex">
          <div className="flex-fill d-flex flex-column">
            <div className="d-flex align-items-center mt-3 ml-3">
              <AanbiedenKnop
                disabled={bulkbericht.IsAangebodenVoorVerzendenOp !== null}
                onClick={handleAanbiedenVoorVerzendenClick}
              >
                <IconPlay style={{ width: 18, height: 18, fill: Kleur.Wit }} />
                <span className="ml-2">Aanbieden voor verzenden</span>
              </AanbiedenKnop>
            </div>

            <div className="mt-3 ml-3 mb-2">
              <h5>Ontvangers</h5>
              <FilterBalkV2
                filters={filters}
                filterData={filterData}
                onFilterDataChange={setFilterData}
              />
            </div>
            <Tabel
              selectieQuery={selectieQuery}
              voorkeurID={voorkeur?.ID ?? null}
              filterData={filterData}
            />
          </div>
          {maatwerkselectie !== null && (
            <div className="d-flex flex-column ml-2" style={{ maxWidth: 750, minWidth: 750 }}>
              <h5 className="mt-3 ml-3 mb-3">Maatwerk query</h5>
              <CodeBewerker
                code={maatwerkselectie.Query}
                language="sql"
                editorOptionsBuilder={(curr) => ({ ...curr, readOnly: true })}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const VoorkeurFilter = (props: IWeergaveProps<BulkberichtUitgeklapteRijFilters>) => {
  type Kolommen = 'naam';

  interface IVoldoetAanVoorkeurOptie {
    type: 'voldoet_aan_voorkeur';
    naam: string;
  }

  interface IVoldoetNietAanVoorkeurOptie {
    type: 'voldoet_niet_aan_voorkeur';
    naam: string;
  }

  type Optie = IVoldoetAanVoorkeurOptie | IVoldoetNietAanVoorkeurOptie;

  const voorkeurFilterOpties: Optie[] = [
    {
      type: 'voldoet_aan_voorkeur',
      naam: 'Voldoet aan voorkeur',
    },
    {
      type: 'voldoet_niet_aan_voorkeur',
      naam: 'Voldoet niet aan voorkeur',
    },
  ];

  const provider = useMemo<Provider<Kolommen, Optie>>(
    () => async () => {
      const items = voorkeurFilterOpties.reduce<Record<number, Optie>>((acc, item, i) => {
        acc[i] = item;
        return acc;
      }, {});

      return {
        items,
        totaalAantal: voorkeurFilterOpties.length,
      };
    },
    [],
  );

  const enkeleProvider = useMemo<EnkeleProvider<Optie['type'], Optie>>(
    () => async (type) => {
      return voorkeurFilterOpties.find((x) => x.type === type)!;
    },
    [],
  );

  const keyExtractor = useCallback((optie: Optie) => optie.type, []);

  const kolommen = useMemo<ASPKolom<Kolommen, Optie>[]>(
    () => [
      {
        key: 'naam',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 300,
        label: 'Naam',
        renderer: (item) => item.naam,
      },
    ],
    [],
  );

  const RepresentatieComponent = useMemo<React.ComponentType<IRepresentatieProps<Optie>>>(
    () => (reprProps: IRepresentatieProps<Optie>) => <span>{reprProps.entiteit.naam}</span>,
    [],
  );

  return (
    <div className="d-flex align-items-center">
      <span className="mr-2" style={{ minWidth: 60 }}>
        Voorkeur
      </span>
      <MultiComboboxV2
        waarde={props.data}
        onChange={props.onDataChange}
        provider={provider}
        enkeleProvider={enkeleProvider}
        keyExtractor={keyExtractor}
        kolommen={kolommen}
        representatieComponent={RepresentatieComponent}
      />
    </div>
  );
};

export default BulkberichtUitgeklapteRij;
