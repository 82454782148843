import { format } from 'date-fns';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { IOphalenContractenResultElement } from '../../../../../shared/src/api/v2/sponsoring/contract';
import api from '../../../api';
import { Kleur } from '../../../bedrijfslogica/constanten';
import LoadingSpinner from '../../../components/Gedeeld/LoadingSpinner';
import { IconToevoegen } from '../../../components/Icons';
import MenuLayout from '../../../components/MenuLayout';
import FormatteerBedrag from '../../../components/MutatieBedrag';
import nameof from '../../../core/nameOf';
import {
  DXTableCheckboxComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
  DXCommandComponent,
  DXTableToggleCellComponent,
} from '../../../helpers/dxTableGrid';
import {
  Grid,
  VirtualTable,
  TableHeaderRow,
  TableColumnResizing,
  TableSelection,
  TableEditColumn,
  TableRowDetail,
} from '@devexpress/dx-react-grid-bootstrap4';

import {
  DataTypeProvider,
  EditingState,
  IntegratedSorting,
  RowDetailState,
  SelectionState,
  SortingState,
  VirtualTable as VirtualTableBase,
} from '@devexpress/dx-react-grid';

import DetailComp from './DetailComp';
import { IOphalenItemsResultElement } from '../../../../../shared/src/api/v2/sponsoring/item';
import RelatieVisualisatie from '../../../components/personalia/RelatieVisualisatie';
import BijlageKnop, { EOrientatie } from '../../../components/BijlageKnop';
import BijlagenContainer from '../../../components/BijlagenContainer';
import useUpload from '../../../core/useUpload';
import ToevoegenDialoog from './ToevoegenDialoog';

interface IProps extends RouteComponentProps<{}> {}

export interface IRegel extends IOphalenContractenResultElement {}

export interface IContractenContext {
  verversen: () => void;
  items: IOphalenItemsResultElement[] | null;
}

export const ContractenContext = React.createContext<IContractenContext>(null as any);

export interface IToevoegenContractDialoogState {}

const Contracten: React.FC<IProps> = (props) => {
  const [contracten, setContracten] = useState<IRegel[] | null>(null);

  const [
    toevoegenContractDialoogState,
    setToevoegenContractDialoogState,
  ] = useState<IToevoegenContractDialoogState | null>(null);

  const ophalenContracten = useCallback(async () => {
    const contractenResult = await api.v2.sponsoring.contract.ophalenContracten({
      filterSchema: {
        filters: [].filter((x) => x !== null),
      },
      orderSchema: {
        orders: [
          { naam: 'REGISTRATIEDATUM', richting: 'DESC' },
          { naam: 'ID', richting: 'DESC' },
        ],
      },
    });

    // const contractenGesorteerd = _.orderBy(
    //   contractenResult.contracten,
    //   [
    //     (x: IRegel) => {
    //       return x.Contractdatum !== null ? x.Contractdatum : x.Registratiedatum;
    //     },
    //   ],
    //   ['desc'],
    // );

    setContracten(contractenResult.contracten);
  }, []);

  useEffect(() => {
    ophalenContracten();
  }, [ophalenContracten]);

  const [items, setItems] = useState<IOphalenItemsResultElement[] | null>(null);

  const ophalenItems = useCallback(async () => {
    const itemsResult = (
      await api.v2.sponsoring.item.ophalenItems({
        filterSchema: {
          filters: [].filter((x) => x !== null),
        },
      })
    ).items;
    // const itemsGesorteerd = _.orderBy(itemsResult, [''], ['asc']);

    setItems(itemsResult);
  }, [contracten]);

  useEffect(() => {
    ophalenItems();
  }, [ophalenItems]);

  const keyExtractor = useCallback((row: IRegel) => row.ID, []);
  const kolommen = useMemo<TypedColumn<IRegel>[]>(
    () => [
      {
        name: 'Contractdatum',
        title: 'Contractdatum',
      },
      {
        name: '__relatie' as any,
        title: 'Relatie',
        getCellValue: (x) => {
          return x.dienst.Naam;
        },
      },
      {
        name: 'Definitief',
        title: 'Def.',
      },
      {
        name: 'Inhoud',
        title: 'Inhoud',
      },
      {
        name: '__aantalItems' as any,
        title: '# Items',
      },
      {
        name: '__aantalBestanden' as any,
        title: '# Bijl.',
      },
      {
        name: 'BedragTotaal',
        title: 'Bedrag',
      },
      {
        name: 'BedragBtw',
        title: 'Btw',
      },
      {
        name: 'BedragVoorstel',
        title: 'Bedrag voorstel',
      },
    ],
    [],
  );
  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRegel>[]>(
    () => [
      {
        columnName: 'Definitief',
        width: 65,
      },
      {
        columnName: '__relatie',
        width: 200,
      },
      {
        columnName: 'Contractdatum',
        width: 150,
        getCellValue: (x: IRegel) => {
          return x.Contractdatum !== null ? x.Contractdatum : x.Registratiedatum;
        },
      },
      {
        columnName: 'Inhoud',
        width: 450,
      },
      {
        columnName: 'BedragTotaal',
        width: 100,
      },
      {
        columnName: 'BedragBtw',
        width: 100,
      },
      {
        columnName: 'BedragVoorstel',
        width: 150,
      },
      {
        columnName: '__aantalItems' as any,
        width: 100,
      },
      {
        columnName: '__aantalBestanden' as any,
        width: 100,
      },
    ],
    [],
  );

  const kolomExtensies: VirtualTableBase.ColumnExtension[] = useMemo(() => {
    return [
      {
        columnName: 'BedragTotaal',
        align: 'right',
      },
      {
        columnName: 'BedragBtw',
        align: 'right',
      },
      {
        columnName: 'BedragVoorstel',
        align: 'right',
      },
    ];
  }, []);

  const context = useMemo<IContractenContext>(() => {
    return {
      verversen: () => ophalenContracten(),
      items: items,
    };
  }, [ophalenContracten, items]);

  return (
    <ContractenContext.Provider value={context}>
      <>
        <MenuLayout
          menu={
            <>
              <div className="d-flex">
                <button
                  className="btn btn-sm btn-light d-flex align-items-center"
                  style={{
                    border: `1px solid ${Kleur.LichtGrijs}`,
                  }}
                  onClick={() => {
                    setToevoegenContractDialoogState({});
                  }}
                >
                  <IconToevoegen
                    style={{
                      width: 16,
                      height: 16,
                      fill: Kleur.Grijs,
                    }}
                  />
                  <span className="ml-2">Nieuw contract</span>
                </button>
              </div>
            </>
          }
          body={
            <div>
              {contracten === null || items === null ? (
                <div className="d-flex flex-fill align-items-center justify-content-center p-5 pl-4 pr-4">
                  <LoadingSpinner />
                </div>
              ) : (
                <>
                  <GridStyleWrapper height="calc(100vh - 88px)">
                    <Grid rows={contracten} columns={kolommen} getRowId={keyExtractor}>
                      <DataTypeProvider
                        for={['__relatie']}
                        formatterComponent={(formatterProps) => {
                          const rij: IRegel = formatterProps.row;
                          return <RelatieVisualisatie relID={rij.dienst.RelID} />;
                        }}
                      />

                      <DataTypeProvider
                        for={[nameof('Definitief')]}
                        formatterComponent={(formatterProps) => {
                          return <span>{formatterProps ? 'Ja' : 'Nee'}</span>;
                        }}
                      />

                      <DataTypeProvider
                        for={[nameof('Contractdatum')]}
                        formatterComponent={(formatterProps) => {
                          const rij: IRegel = formatterProps.row;
                          return (
                            <span>
                              {rij.Contractdatum !== null
                                ? format(new Date(rij.Contractdatum), 'dd-MM-yyyy')
                                : '(' + format(new Date(rij.Registratiedatum), 'dd-MM-yyyy') + ')'}
                            </span>
                          );
                        }}
                      />

                      <DataTypeProvider
                        for={[nameof('BedragTotaal')]}
                        formatterComponent={(formatterProps) => {
                          return <FormatteerBedrag bedrag={formatterProps.value} rechtsUitlijnen />;
                        }}
                      />

                      <DataTypeProvider
                        for={[nameof('BedragBtw')]}
                        formatterComponent={(formatterProps) => {
                          if (formatterProps.value === null) {
                            return <span></span>;
                          }
                          return <FormatteerBedrag bedrag={formatterProps.value} rechtsUitlijnen />;
                        }}
                      />

                      <DataTypeProvider
                        for={[nameof('BedragVoorstel')]}
                        formatterComponent={(formatterProps) => {
                          if (formatterProps.value === null) {
                            return <span></span>;
                          }
                          return <FormatteerBedrag bedrag={formatterProps.value} rechtsUitlijnen />;
                        }}
                      />

                      <DataTypeProvider
                        for={['__aantalItems']}
                        formatterComponent={(formatterProps) => {
                          const rij: IRegel = formatterProps.row;
                          const aantal = items.filter((x) => x.SponContractID === rij.ID).length;
                          return <span>{aantal !== 0 ? aantal : ''}</span>;
                        }}
                      />

                      <DataTypeProvider
                        for={['__aantalBestanden']}
                        formatterComponent={(formatterProps) => {
                          const rij: IRegel = formatterProps.row;
                          const aantal = rij.bestanden.length;
                          return <span>{aantal !== 0 ? aantal : ''}</span>;
                        }}
                      />

                      <SortingState defaultSorting={[]} />
                      <IntegratedSorting />

                      <VirtualTable columnExtensions={kolomExtensies} />
                      <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
                      <TableHeaderRow showSortingControls />
                      <RowDetailState defaultExpandedRowIds={[]} />

                      <TableRowDetail
                        contentComponent={DetailComp}
                        toggleCellComponent={DXTableToggleCellComponent}
                      />

                      {/* <TableSelection cellComponent={DXTableCheckboxComponent} /> */}
                    </Grid>
                  </GridStyleWrapper>
                </>
              )}
            </div>
          }
        />
        {toevoegenContractDialoogState !== null && (
          <ToevoegenDialoog
            open={true}
            onSuccess={() => {
              ophalenContracten();
              setToevoegenContractDialoogState(null);
            }}
            onAnnuleren={() => setToevoegenContractDialoogState(null)}
          />
        )}
      </>
    </ContractenContext.Provider>
  );
};

export default Contracten;
