import React, { useContext } from 'react';
import { DashboardContext } from '../index';
import TegelBasis from '../../../components/tegel/TegelBasis';
import { ERemoteDataState } from '../../../models/IRemoteData';
import {
  EFunctioneleIcon,
  functioneleIconMap,
  IconBellen,
  IconReview,
  IconWhatsapp,
} from '../../../components/Icons';
import VerticaleScheidingslijn from '../../../components/layout/VerticaleScheidingslijn';
import UitlegTooltip from '../../../components/formulier/UitlegTooltip';
import Skeleton from 'react-loading-skeleton';
import { Kleur as EKleur } from '../../../bedrijfslogica/constanten';

interface IProps {}

const IconDocumentatie = functioneleIconMap[EFunctioneleIcon.Documentatie];

const StandVanZaken: React.FC<IProps> = (props) => {
  const context = useContext(DashboardContext);

  return (
    <TegelBasis titel="Stand van zaken">
      <div className="d-flex">
        <div className="d-flex flex-column align-items-start justify-content-between">
          {context.standVanZaken.state === ERemoteDataState.Pending ? (
            <h4 className="w-100">
              <Skeleton />
            </h4>
          ) : (
            <h4>{context.standVanZaken.data!.aantalContracten.toString()}</h4>
          )}
          <span>Contracten</span>
        </div>
        <div className="ml-3">
          <VerticaleScheidingslijn />
        </div>
        <div className="d-flex flex-column align-items-start justify-content-between ml-3">
          {context.standVanZaken.state === ERemoteDataState.Pending ? (
            <h4 className="w-100">
              <Skeleton />
            </h4>
          ) : (
            <h4>{context.standVanZaken.data!.aantalKlanten.toString()}</h4>
          )}
          <span>Klanten</span>
        </div>
        <div className="ml-3">
          <VerticaleScheidingslijn />
        </div>
        <div className="d-flex flex-column align-items-start justify-content-between ml-3">
          {context.standVanZaken.state === ERemoteDataState.Pending ? (
            <h4 className="w-100">
              <Skeleton />
            </h4>
          ) : (
            <h4>{context.standVanZaken.data!.reviewscore.toFixed(2)}</h4>
          )}
          <UitlegTooltip inhoud="Gemiddelde score over de laatste 100 dagen">
            <IconReview style={{ height: 15, marginBottom: 2 }} />
          </UitlegTooltip>
        </div>
        <div className="ml-3">
          <VerticaleScheidingslijn />
        </div>
        <div className="ml-3 d-flex flex-column align-items-center justify-content-between">
          {context.standVanZaken.state === ERemoteDataState.Pending ? (
            <h4 className="w-100">
              <Skeleton />
            </h4>
          ) : (
            <h4>{context.standVanZaken.data!.aantalTelefoonoproepen.toString()}</h4>
          )}
          <UitlegTooltip inhoud="Inkomende telefoonoproepen vandaag">
            <IconBellen style={{ height: 15, marginBottom: 2 }} />
          </UitlegTooltip>
        </div>
        <div className="ml-3">
          <VerticaleScheidingslijn />
        </div>
        <div className="ml-3 d-flex flex-column align-items-center justify-content-between">
          {context.standVanZaken.state === ERemoteDataState.Pending ? (
            <h4 className="w-100">
              <Skeleton />
            </h4>
          ) : (
            <h4>{context.standVanZaken.data!.aantalWhatsAppSes.toString()}</h4>
          )}
          <UitlegTooltip inhoud="Inkomende WhatsApp-berichten vandaag">
            <IconWhatsapp style={{ height: 15, marginBottom: 2 }} />
          </UitlegTooltip>
        </div>
        <div className="ml-3">
          <VerticaleScheidingslijn />
        </div>

        <div className="d-flex flex-column align-items-start justify-content-between ml-3">
          {context.standVanZaken.state === ERemoteDataState.Pending ? (
            <h4 className="w-100">
              <Skeleton />
            </h4>
          ) : (
            <h4>{context.standVanZaken.data!.aantalTaken.toString()}</h4>
          )}
          <UitlegTooltip inhoud={'Lopende taken'}>
            <span>Taken</span>
          </UitlegTooltip>
        </div>

        {/* <div className="ml-3">
          <VerticaleScheidingslijn />
        </div> */}

        {/* <div className="d-flex flex-column align-items-start justify-content-between ml-3">
          {context.standVanZaken.state === ERemoteDataState.Pending ? (
            <h4 className="w-100">
              <Skeleton />
            </h4>
          ) : (
            <h4>{context.standVanZaken.data!.aantalPotentieelOntvangenItems.toString()}</h4>
          )}
          <UitlegTooltip inhoud={'Potentieel ontvangen items (lopend)'}>
            <span>Ext.data</span>
          </UitlegTooltip>
        </div> */}
      </div>
    </TegelBasis>
  );
};

export default StandVanZaken;
