import React, { useContext, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import { RootStoreContext } from '../../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import { IOphalenFacturenResultElement } from '../../../../../../shared/src/api/v2/inkoopfactuur';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../models/IRemoteData';
import Placeholder from './Placeholder';
import Data from './Data';

interface IProps {
  inkFactID: number;
}

const Root = styled.div`
  background: ${Kleur.Wit};
  border: 1px solid ${Kleur.LichtGrijs};
  border-radius: 4px;
  padding: 12px 16px;
  flex: 1;
  width: 600px;
`;

const InfoCard = observer((props: IProps) => {
  const { inkoopfactuurStore } = useContext(RootStoreContext);

  const inkoopfactuur = useMemo<IRemoteData<IOphalenFacturenResultElement>>(() => {
    if (inkoopfactuurStore.facturen.state === ERemoteDataState.Pending) {
      return createPendingRemoteData();
    }

    const item = inkoopfactuurStore.facturen.data![props.inkFactID];
    if (item === undefined) {
      return createPendingRemoteData();
    }
    return createReadyRemoteData(item);
  }, [inkoopfactuurStore.facturen, props.inkFactID]);

  useEffect(() => {
    if (inkoopfactuur !== null) {
      return;
    }
    inkoopfactuurStore.enqueueOphalenFacturen([props.inkFactID]);
  }, [inkoopfactuur, props.inkFactID]);

  return (
    <Root onClick={(ev) => ev.stopPropagation()}>
      {inkoopfactuur.state === ERemoteDataState.Pending ? (
        <Placeholder />
      ) : (
        <Data inkoopfactuur={inkoopfactuur.data!} />
      )}
    </Root>
  );
});

export default InfoCard;
