import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { Field, FieldProps, Formik, FormikActions, FormikErrors, FormikProps } from 'formik';
import Combobox, { IOptie } from '../../../../components/formulier/Combobox';
import { RootStoreContext } from '../../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import api from '../../../../api';
import * as Yup from 'yup';
import FormikVeldFout from '../../../../components/formulier/FormikVeldFout';
import Dialoog from '../../../../components/dialogen/Dialoog';
import _ from 'lodash';
import IDialoogProps from '../../../../core/IDialoogProps';
import VeldWeergave from '../../../../components/formulier/VeldWeergave';
import teksten from '../../../../bedrijfslogica/teksten';
import {
  IOphalenVerlofMutatieSoortenResultElement,
  IOphalenVerlofMutatiesResultElement,
} from '../../../../../../shared/src/api/v2/medewerker/verlof';
import { dagDatum } from '../../../../helpers/datum';
import { addDays } from 'date-fns';
import DatumKiezer from '../../../../components/formulier/DatumKiezer';
import { EVerlofMutatiesoort } from '../../../../bedrijfslogica/enums';
import { IOphalenMedewerkersResultElement } from '../../../../../../shared/src/api/v2/medewerker';
import nameOf from '../../../../core/nameOf';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import MultiCombobox, { IKolom } from '../../../../components/formulier/MultiCombobox';
import VinkVeld from '../../../../components/formulier/VinkVeld';
import { EResultType } from '../../../../stores/CheckStore';

interface IProps extends IDialoogProps<{}> {
  id: number;
}

interface IFormikValues {
  mutSrtID: number;
  uren: number;
  datumVan: Date;
  datumTem: Date | null;
  notities: string | null;
  correctie: boolean;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  mutSrtID: 'Reden',
  uren: 'Uren (> 0)',
  datumVan: 'Vanaf',
  datumTem: 'Tot en met',
  notities: 'Notities',
  correctie: 'Betreft correctie',
};

const WijzigenMutatieDialoog = observer((props: IProps) => {
  const { onAnnuleren, onSuccess, open } = props;
  const { checkStore } = useContext(RootStoreContext);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const formikRef = useRef<Formik<IFormikValues>>(null);

  const [mutatiesoorten, setMutatiesoorten] = useState<
    IOphalenVerlofMutatieSoortenResultElement[] | null
  >(null);

  const [mutatie, setMutatie] = useState<IOphalenVerlofMutatiesResultElement | null>(null);

  const ophalenVerlofmutatie = useCallback(async () => {
    const result = await api.v2.medewerker.verlof.ophalenVerlofMutaties({
      filterSchema: {
        filters: [{ naam: 'IDS', data: [props.id] }],
      },
    });

    setMutatie(result.mutaties[0]);
  }, [props.id]);

  useEffect(() => {
    ophalenVerlofmutatie();
  }, [ophalenVerlofmutatie]);

  const ophalenMutatiesoorten = useCallback(async () => {
    const result = await api.v2.medewerker.verlof.ophalenVerlofMutatieSoorten({
      filterSchema: {
        filters: [{ naam: 'NIET_IN_NAAM_ENUMS', data: [EVerlofMutatiesoort.Saldo2014] }],
      },
      orderSchema: { orders: [{ naam: 'SORTNR', richting: 'ASC' }] },
    });

    setMutatiesoorten(result.soorten);
  }, []);

  useEffect(() => {
    ophalenMutatiesoorten();
  }, [ophalenMutatiesoorten]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      const params = {
        id: props.id,
        mutSrtID: values.mutSrtID,
        aantalUren: values.correctie ? -values.uren : values.uren,
        notities: values.notities,
        datumVem: values.datumVan,
        datumTem: values.datumTem,
      };

      // const checkData = await api.v2.medewerker.verlof.checkToevoegenMutatie(params);

      // if (
      //   (
      //     await checkStore.controleren({
      //       checkData,
      //     })
      //   ).type === EResultType.Annuleren
      // ) {
      //   actions.setSubmitting(false);
      //   return;
      // }

      if (
        (
          await checkStore.bevestigen({
            inhoud: <span>Mutatie vastleggen?</span>,
          })
        ).type === EResultType.Annuleren
      ) {
        actions.setSubmitting(false);
        return;
      }

      await api.v2.medewerker.verlof.wijzigenVerlofmutatie(params);

      onSuccess({});
      actions.setSubmitting(false);
    },
    [onSuccess, setHasSubmitted, props.id],
  );

  const redenenOpties = useMemo<IOptie<number>[] | null>(() => {
    if (mutatiesoorten === null) {
      return null;
    }

    return mutatiesoorten.map(
      (x): IOptie<number> => ({
        id: x.ID,
        label: x.Naam + (x.ProForma ? ' (Pro Forma)' : ''),
      }),
    );
  }, [mutatiesoorten]);

  const initieleWaarden = useMemo<IFormikValues | null>(() => {
    if (mutatie === null) {
      return null;
    }

    return {
      mutSrtID: mutatie.MutSrtID,
      uren: Math.abs(mutatie.AantalUren),
      datumVan: dagDatum(new Date(mutatie.DatumVem)),
      datumTem: mutatie.DatumTem !== null ? dagDatum(new Date(mutatie.DatumTem)) : null,
      notities: mutatie.Notities,
      correctie: mutatie.AantalUren < 0 ? true : false,
    };
  }, [mutatie]);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape<IFormikValues>({
        mutSrtID: Yup.number().required(
          teksten.formulier.E_VERPLICHT_VELD({ veldnaam: veldnamen.mutSrtID }),
        ),
        datumVan: Yup.date(),
        datumTem: Yup.date().nullable(),
        uren: Yup.number().min(0, 'Moet > 0'),
        notities: Yup.string().nullable(),
        correctie: Yup.boolean(),
      }),
    [],
  );

  if (initieleWaarden === null) {
    return <span />;
  }

  return (
    <Dialoog index={props.dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Wijzigen verlofmutatie</ModalTitle>
      </ModalHeader>
      <Formik<IFormikValues>
        onSubmit={handleSubmit}
        initialValues={initieleWaarden}
        ref={formikRef}
        // isInitialValid
        validationSchema={validationSchema}
        render={(formikProps: FormikProps<IFormikValues>) => {
          const { submitForm, isSubmitting, values, isValid } = formikProps;
          return (
            <>
              <ModalBody>
                <div className="form-group">
                  <div className="row">
                    {/* <div className="col-12 mt-3">
                      <label>{veldnamen.mutSrtID}</label>
                      <Field
                        name={nameOf<IFormikValues>('mutSrtID')}
                        render={(fieldProps: FieldProps<IFormikValues>) => (
                          <>
                            <Combobox
                              geselecteerd={fieldProps.field.value}
                              opties={redenenOpties === null ? [] : redenenOpties}
                              onSelectieChange={(id) => {
                                fieldProps.form.setFieldValue(fieldProps.field.name, id);
                              }}
                              legeOptieTonen
                              // options={{
                              //   legeOptieTekst: 'Geen reden',
                              // }}
                            />
                            <FormikVeldFout fieldProps={fieldProps} directTonen={hasSubmitted} />
                          </>
                        )}
                      />
                    </div> */}

                    <div className="col-12 mt-3">
                      <label>{veldnamen.mutSrtID}</label>
                      <Field
                        name={nameOf<IFormikValues>('mutSrtID')}
                        render={(fieldProps: FieldProps<IFormikValues>) => (
                          <>
                            <Combobox
                              geselecteerd={fieldProps.field.value}
                              opties={redenenOpties === null ? [] : redenenOpties}
                              onSelectieChange={(id) => {
                                fieldProps.form.setFieldValue(fieldProps.field.name, id);
                              }}
                              legeOptieTonen
                              // options={{
                              //   legeOptieTekst: 'Geen reden',
                              // }}
                            />
                            <FormikVeldFout fieldProps={fieldProps} directTonen={hasSubmitted} />
                          </>
                        )}
                      />
                    </div>

                    <div className="row col-12">
                      <div className="col-5 mt-3">
                        <label>{veldnamen.datumVan}</label>
                        <Field
                          name={nameOf<IFormikValues>('datumVan')}
                          render={({ field, form }: FieldProps<IFormikValues>) => {
                            const minimaleDatum = addDays(new Date(), -365);
                            const maximaleDatum = addDays(new Date(), +365);

                            return (
                              <div className="d-flex ">
                                <DatumKiezer
                                  onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                  waarde={field.value}
                                  // determineValidDate={(date) => {
                                  //   return date >= minimaleDatum && date <= maximaleDatum;
                                  // }}
                                  // determineNextValidDate={(date) => {
                                  //   const nieuweDatum = addDays(date, +1);
                                  //   if (nieuweDatum <= maximaleDatum) {
                                  //     return nieuweDatum;
                                  //   }
                                  //   return null;
                                  // }}
                                  // determinePreviousValidDate={(date) => {
                                  //   const nieuweDatum = addDays(date, -1);
                                  //   if (nieuweDatum >= minimaleDatum) {
                                  //     return nieuweDatum;
                                  //   }
                                  //   return null;
                                  // }}
                                />
                              </div>
                            );
                          }}
                        />
                      </div>

                      <div className="col-5 mt-3">
                        <label>{veldnamen.datumTem}</label>
                        <Field
                          name={nameOf<IFormikValues>('datumTem')}
                          render={({ field, form }: FieldProps<IFormikValues>) => {
                            const minimaleDatum = addDays(new Date(), -1);
                            const maximaleDatum = addDays(new Date(), +32);

                            return (
                              <div className="d-flex ">
                                <DatumKiezer
                                  onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                  waarde={field.value}
                                  isClearable
                                  // determineValidDate={(date) => {
                                  //   return date >= minimaleDatum && date <= maximaleDatum;
                                  // }}
                                  // determineNextValidDate={(date) => {
                                  //   const nieuweDatum = addDays(date, +1);
                                  //   if (nieuweDatum <= maximaleDatum) {
                                  //     return nieuweDatum;
                                  //   }
                                  //   return null;
                                  // }}
                                  // determinePreviousValidDate={(date) => {
                                  //   const nieuweDatum = addDays(date, -1);
                                  //   if (nieuweDatum >= minimaleDatum) {
                                  //     return nieuweDatum;
                                  //   }
                                  //   return null;
                                  // }}
                                />
                              </div>
                            );
                          }}
                        />
                      </div>
                    </div>

                    <div className="col-12 mt-3">
                      <label>{veldnamen.uren}</label>
                      <Field
                        name={nameOf<IFormikValues>('uren')}
                        render={(x: FieldProps<IFormikValues>) => (
                          <>
                            <div className="d-flex flexfill">
                              <input
                                type="number"
                                className="form-control"
                                {...x.field}
                                min={0}
                                style={{ width: 140 }}
                              />
                              <span className="ml-3">
                                <FormikVeldFout fieldProps={x} />
                              </span>
                              <span className="ml-3" style={{ minWidth: 1000 }}>
                                (15 min. = 0,25 uur, 30 min. = 0,5 uur)
                              </span>
                            </div>
                          </>
                        )}
                      />
                    </div>

                    <div className="col-12 d-flex align-items-center mt-3">
                      <Field
                        name={nameOf<IFormikValues>('correctie')}
                        render={(fieldProps: FieldProps<IFormikValues>) => {
                          const { field, form } = fieldProps;
                          return (
                            <VinkVeld
                              aangevinkt={field.value}
                              onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                            />
                          );
                        }}
                      />
                      <span className="ml-2">{veldnamen.correctie}</span>
                    </div>

                    <div className="col-12 mt-3">
                      <label>{veldnamen.notities}</label>
                      <Field
                        name={nameOf<IFormikValues>('notities')}
                        render={(fieldProps: FieldProps<IFormikValues>) => {
                          const { field, form } = fieldProps;
                          return <textarea {...field} className="form-control" rows={2} />;
                        }}
                      />
                    </div>
                    {/*
                    <div className="col-12 mt-3">
                      <VeldWeergave>{JSON.stringify(validationSchema.shape)}</VeldWeergave>
                    </div> */}
                  </div>
                </div>
              </ModalBody>
              <ModalFooter className="d-flex justify-content-start">
                <button
                  className="btn btn-primary"
                  onClick={submitForm}
                  disabled={isSubmitting || !isValid}
                  // disabled={isSubmitting}
                  style={{ width: 100 }}
                >
                  Ok
                </button>
                <button className="btn btn-secondary" onClick={onAnnuleren} style={{ width: 100 }}>
                  Annuleren
                </button>
              </ModalFooter>
            </>
          );
        }}
      />
    </Dialoog>
  );
});

export default WijzigenMutatieDialoog;
