import api from '../../index';
import { IAfdrukkenParams, IAfdrukkenResult } from '../../../../../shared/src/api/v2/pdf';

const pdf = {
  afdrukken: async (params: IAfdrukkenParams): Promise<IAfdrukkenResult> => {
    return await api.post('/v2/pdf/afdrukken', params);
  },
};

export default pdf;
