import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import IDialoogProps from '../../../core/IDialoogProps';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import LoadingSpinner from '../../Gedeeld/LoadingSpinner';
import Dialoog from '../Dialoog';
import { RootStoreContext } from '../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import { IOphalenGebruikersResultElement } from '../../../../../shared/src/api/v2/gebruiker';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../models/IRemoteData';
import api from '../../../api';
import { ASPKolom, EAspKolomBreedteType } from '../../tabel/ASPTabel/types';
import Skeleton from 'react-loading-skeleton';
import { Overlay } from 'react-bootstrap';
import { Kleur } from '../../../bedrijfslogica/constanten';
import ASPTabel from '../../tabel/ASPTabel';
import { IVoipAccount } from '../../../../../shared/src/api/v2/telefonie';

enum EKolom {
  Nummer,
  Naam,
  Actie,
}

export enum EResultType {
  Toeeigenen,
  AnderToestelGekozen,
  ZonderToestelDoorgaan,
}

export interface IToeeigenenDialoogResult {
  type: EResultType.Toeeigenen;
}

export interface IAnderToestelGekozenDialoogResult {
  type: EResultType.AnderToestelGekozen;
  voipAccountID: number;
}

export interface IZonderToestelDoorgaanDialoogResult {
  type: EResultType.ZonderToestelDoorgaan;
}

export type DialoogResult =
  | IToeeigenenDialoogResult
  | IAnderToestelGekozenDialoogResult
  | IZonderToestelDoorgaanDialoogResult;

interface IProps extends IDialoogProps<DialoogResult> {
  gewenstVoipAccountID: number;
}

const CorrigeerVoipAccountVigerendDialoog = observer((props: IProps) => {
  const { gebruikerStore, voipStore } = useContext(RootStoreContext);
  const target = useRef<HTMLButtonElement | null>(null);
  const [isSelectieOpen, setIsSelectieOpen] = useState(false);

  const [voipAccounts, setVoipAccounts] = useState<IRemoteData<IVoipAccount[]>>(
    createPendingRemoteData(),
  );
  useEffect(() => {
    api.v2.telefonie.ophalenVoipAccounts({}).then((result) => {
      setVoipAccounts(createReadyRemoteData(result.voipAccounts));
    });
  }, []);

  const gewenstVoipAccount = useMemo(() => {
    return voipStore.voipAccounts?.find((x) => x.VoipAccID === props.gewenstVoipAccountID) ?? null;
  }, [voipStore.voipAccounts, props.gewenstVoipAccountID]);

  const wordtAlGebruiktDoorAspGebrID = useMemo(() => {
    return (
      voipStore.voipAccountsVigerend?.find((x) => x.VoipAccID === props.gewenstVoipAccountID) ??
      null
    );
  }, [props.gewenstVoipAccountID]);

  const wordtAlGebruiktDoorAspGebr = useMemo<IOphalenGebruikersResultElement | null>(() => {
    return (
      gebruikerStore.gebruikers?.find(
        (x) => x.AspGebrID === wordtAlGebruiktDoorAspGebrID?.AspGebrID,
      ) ?? null
    );
  }, [wordtAlGebruiktDoorAspGebrID, gebruikerStore.gebruikers]);

  const rijen = useMemo<Record<number, IVoipAccount> | null>(() => {
    if (voipAccounts.state === ERemoteDataState.Pending) {
      return null;
    }

    return voipAccounts
      .data!.filter(
        (x) =>
          voipStore.voipAccountsVigerend!.find((vav) => vav.VoipAccID === x.VoipAccID) ===
          undefined,
      )
      .reduce((acc, curr, i) => {
        return {
          ...acc,
          [i]: curr,
        };
      }, {});
  }, [voipAccounts, voipStore.voipAccountsVigerend]);

  const keyExtractor = useCallback((item: IVoipAccount) => item.VoipAccID, []);

  const kolommen = useMemo<ASPKolom<EKolom, IVoipAccount>[]>(
    () => [
      {
        key: EKolom.Nummer,
        label: 'Nummer',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 80,
        renderer: (voipAccount) => voipAccount.TelefoonIntern,
      },
      {
        key: EKolom.Naam,
        label: 'Naam',
        breedteType: EAspKolomBreedteType.Flex,
        flex: 1,
        renderer: (voipAccount) => voipAccount.Naam,
      },
      {
        key: EKolom.Actie,
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 70,
        renderer: (voipAccount) => {
          return (
            <a
              href="#"
              onClick={async (ev) => {
                ev.preventDefault();
                setIsSelectieOpen(false);

                props.onSuccess({
                  type: EResultType.AnderToestelGekozen,
                  voipAccountID: voipAccount.VoipAccID,
                });
              }}
            >
              Kies
            </a>
          );
        },
      },
    ],
    [gebruikerStore.gebruiker?.AspGebrID, gebruikerStore.aspGebruikersprofiel?.ID, props.onSuccess],
  );

  if (gewenstVoipAccount === null || wordtAlGebruiktDoorAspGebr === null) {
    return null;
  }

  return (
    <>
      <Dialoog index={props.dialoogIndex ?? 0}>
        <ModalHeader>
          <ModalTitle>Corrigeer vigerend VOIP-Account</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <p>
            Op moment wordt het VOIP-account&nbsp;
            <span className="font-weight-bold">
              {gewenstVoipAccount.TelefoonIntern}&nbsp;&ndash;&nbsp;{gewenstVoipAccount.Naam}
            </span>
            &nbsp; wat aan jouw profiel gekoppeld is al gebruikt door &nbsp;
            <span className="font-weight-bold">{wordtAlGebruiktDoorAspGebr.GebruikersNaam}</span>.
          </p>
          <p>
            Dit kan wel eens voorkomen als een medewerker nog ingelogd blijft na werktijd, of als
            jij hetzelfde toestel deelt met een ander op verschillende werkdagen.
          </p>
          <p>Wil je deze toe-eigenen, een ander toestel kiezen, of zonder toestel door gaan?</p>
          <span className="font-weight-bold" style={{ fontSize: 12 }}>
            Let op: Als je zonder toestel door gaat, dan zal het gebruik van telefonisch
            gerelateerde functionaliteiten beperkt worden. Dit is vaak niet gewenst.
          </span>
        </ModalBody>
        <ModalFooter className="d-flex align-items-center">
          <button
            className="btn btn-primary d-flex align-items-center"
            onClick={() =>
              props.onSuccess({
                type: EResultType.Toeeigenen,
              })
            }
            style={{ width: 100 }}
          >
            Toe-eigenen
          </button>
          <button
            ref={target}
            className="btn btn-secondary"
            onClick={() => setIsSelectieOpen(true)}
          >
            Ander toestel kiezen
          </button>
          <div style={{ flex: 1 }} />
          <a
            href="#"
            onClick={async (ev) => {
              ev.preventDefault();
              await props.onSuccess({
                type: EResultType.ZonderToestelDoorgaan,
              });
            }}
          >
            Zonder toestel door gaan
          </a>
        </ModalFooter>
      </Dialoog>
      <Overlay
        target={target.current}
        show={isSelectieOpen}
        placement="bottom-start"
        rootClose
        onHide={() => setIsSelectieOpen(false)}
      >
        {(overlayProps) => (
          <div
            {...overlayProps}
            style={{
              ...overlayProps.style,
              zIndex: 9999 + (props.dialoogIndex ?? 0),
              background: Kleur.Wit,
              border: `1px solid ${Kleur.LichtGrijs}`,
              height: 500,
              width: 470,
              display: 'flex',
            }}
          >
            {rijen === null ? (
              <ASPTabel
                rijen={{}}
                kolommen={kolommen}
                totaalAantalRijen={15}
                keyExtractor={keyExtractor}
              />
            ) : (
              <ASPTabel
                rijen={rijen}
                kolommen={kolommen}
                totaalAantalRijen={Object.keys(rijen).length}
                keyExtractor={keyExtractor}
              />
            )}
          </div>
        )}
      </Overlay>
    </>
  );
});

export default CorrigeerVoipAccountVigerendDialoog;
