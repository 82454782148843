export enum ERegelboekstukType {
  Factuur,
  Inkoopfactuur,
  Bankmutatie,
  Betalingsregeling,
}

export interface IRegelboekstukFactuur {
  type: ERegelboekstukType.Factuur;
  factID: number;
}

export interface IRegelboekstukInkoopfactuur {
  type: ERegelboekstukType.Inkoopfactuur;
  inkFactID: number;
}

export interface IRegelboekstukBankmutatie {
  type: ERegelboekstukType.Bankmutatie;
  bankMutID: number;
}

export interface IRegelboekstukBetalingsregeling {
  type: ERegelboekstukType.Betalingsregeling;
  betRglID: number;
}

export type Regelboekstuk =
  | IRegelboekstukFactuur
  | IRegelboekstukInkoopfactuur
  | IRegelboekstukBankmutatie
  | IRegelboekstukBetalingsregeling;

export enum ERegelIDType {
  Lokaal,
  Extern,
}

export interface IRegelIDLokaal {
  type: ERegelIDType.Lokaal;
  waarde: string;
}

export interface IRegelIDExtern {
  type: ERegelIDType.Extern;
  waarde: number;
}

export type RegelID = IRegelIDLokaal | IRegelIDExtern;

export enum ERegelstate {
  Weergave,
  Muteren,
}

export interface IWeergaveRegel {
  id: RegelID;
  state: ERegelstate.Weergave;
  grootboekID: number;
  relID: number | null;
  regelboekstuk: Regelboekstuk | null;
  bedrag: number;
  storno: boolean;
  btwSoortID: number | null;
  werkkostenregelingID: number | null;
  omschrijving: string | null;
}

export interface IMuterenRegel {
  id: RegelID;
  state: ERegelstate.Muteren;
  grootboekID: number | null;
  relID: number | null;
  regelboekstuk: Regelboekstuk | null;
  bedrag: number;
  storno: boolean;
  btwSoortID: number | null;
  werkkostenregelingID: number | null;
  omschrijving: string | null;
}

export type Regel = IWeergaveRegel | IMuterenRegel;

export interface IBoekstuksaldoFactuur {
  type: ERegelboekstukType.Factuur;
  factID: number;
  saldo: number;
}

export interface IBoekstuksaldoInkoopfactuur {
  type: ERegelboekstukType.Inkoopfactuur;
  inkFactID: number;
  saldo: number;
}

export interface IBoekstuksaldoBankmutatie {
  type: ERegelboekstukType.Bankmutatie;
  bankMutID: number;
  saldo: number;
}

export interface IBoekstuksaldoBetalingsregeling {
  type: ERegelboekstukType.Betalingsregeling;
  betRglID: number;
  saldo: number;
}

export type Boekstuksaldo =
  | IBoekstuksaldoFactuur
  | IBoekstuksaldoInkoopfactuur
  | IBoekstuksaldoBankmutatie
  | IBoekstuksaldoBetalingsregeling;

export enum ERegelfocusbaarOp {
  Grootboek,
  Bedrag,
  Omschrijving,
}

export interface IRegelfocusbaar {
  regelID: RegelID;
  op: ERegelfocusbaarOp;
}
