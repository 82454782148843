import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import IDialoogProps from '../../../../../../../../core/IDialoogProps';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { Field, FieldProps, Formik, FormikActions, FormikErrors, FormikProps } from 'formik';
import Combobox, { IOptie } from '../../../../../../../../components/formulier/Combobox';
import { RootStoreContext } from '../../../../../../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import VinkVeld from '../../../../../../../../components/formulier/VinkVeld';
import api from '../../../../../../../../api';
import { EResultType } from '../../../../../../../../stores/CheckStore';
import * as Yup from 'yup';
import teksten from '../../../../../../../../bedrijfslogica/teksten';
import FormikVeldFout from '../../../../../../../../components/formulier/FormikVeldFout';
import ContactSelectie, {
  EType,
} from '../../../../../../../../components/formulier/ContactSelectie';
import { IOphalenRedenenAfkeuringResultElement } from '../../../../../../../../../../shared/src/api/v2/contract/afkeuring';
import Dialoog from '../../../../../../../../components/dialogen/Dialoog';
import _ from 'lodash';
import VeldWeergave from '../../../../../../../../components/formulier/VeldWeergave';

interface IProps extends IDialoogProps<{}> {
  relID: number;
  cntIDs: number[];
  cntRdAfkID?: number;
}

interface IFormikValues {
  cntRdAfkID: number | null;
  persID: number | null;
  bevestigingSturen: boolean;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  cntRdAfkID: 'Reden',
  persID: 'Persoon',
  bevestigingSturen: 'Bevestiging sturen',
};

const AfkeurenContractDialoog = observer((props: IProps) => {
  const { onAnnuleren, onSuccess, open, relID, cntIDs } = props;
  const { checkStore } = useContext(RootStoreContext);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const formikRef = useRef<Formik<IFormikValues>>(null);
  const [redenen, setRedenen] = useState<IOphalenRedenenAfkeuringResultElement[] | null>(null);

  useEffect(() => {
    (async () => {
      // Haal de contacten op om te bepalen welke persoon voorgevuld gaat worden
      const contracten = (
        await api.v2.contract.ophalenContractenV2({
          filterSchema: { filters: [{ naam: 'IDS', data: [props.cntIDs] }] },
        })
      ).contracten;

      const persIDs = _.uniq(
        contracten.filter((x) => x.persoon !== null).map((x) => x.persoon!.PersID),
      );
      const persID = persIDs.length === 1 ? persIDs[0] : null;
      formikRef.current!.setFieldValue('persID', persID);
    })();
  }, [props.cntIDs]);

  useEffect(() => {
    (async () => {
      const redenenResult = await api.v2.contract.afkeuring.ophalenRedenenAfkeuring({});

      setRedenen(redenenResult.redenen);
    })();
  }, []);

  // useEffect(() => {
  //   if (contractBeeindigingStore.redenenBeeindiging !== null) {
  //     return;
  //   }
  //
  //   contractBeeindigingStore.ophalenRedenenBeeindiging();
  // }, [contractBeeindigingStore.redenenBeeindiging]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      // setHasSubmitted(true);

      // // Controle stap validatie (voor foutmeldingen na OK)
      // const errors = await actions.validateForm(values);
      // const hasError = Object.keys(errors).some((key) => (errors as any)[key] !== null);
      // if (hasError) {
      //   console.error(errors);
      //   return;
      // }

      actions.setSubmitting(true);

      const params = {
        relID,
        cntIDs,
        cntRdAfkID: values.cntRdAfkID!,
      };
      const checkData = await api.v2.contract.afkeuring.checkAfkeurenContracten(params);

      if (
        (
          await checkStore.controleren({
            checkData,
          })
        ).type === EResultType.Annuleren
      ) {
        actions.setSubmitting(false);
        return;
      }

      if (
        (
          await checkStore.bevestigen({
            inhoud: (
              <span>
                Wil je de geselecteerqde contracten afkeuren?
                <br />
                Dit kan niet ongedaan gemaakt worden
              </span>
            ),
          })
        ).type === EResultType.Annuleren
      ) {
        return;
      }

      await api.v2.contract.afkeuring.afkeurenContracten(params);

      onSuccess({});
      actions.setSubmitting(false);
    },
    [onSuccess, relID, cntIDs, setHasSubmitted],
  );

  const redenenOpties = useMemo<IOptie<number>[] | null>(() => {
    if (redenen === null) {
      return null;
    }

    return redenen.map(
      (reden): IOptie<number> => ({
        id: reden.CntRdAfkID,
        label: reden.Naam,
      }),
    );
  }, [redenen]);

  const initieleWaarden = useMemo<IFormikValues>(() => {
    return {
      cntRdAfkID: null,
      persID: null,
      bevestigingSturen: false,
    };
  }, []);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        cntRdAfkID: Yup.number().required(
          teksten.formulier.E_VERPLICHT_VELD({ veldnaam: veldnamen.cntRdAfkID }),
        ),
        persID: Yup.number().nullable(true),
        // .required(teksten.formulier.E_VERPLICHT_VELD({ veldnaam: veldnamen.persID })),
      }),
    [],
  );

  return (
    <Dialoog index={props.dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Afkeuren contracten</ModalTitle>
      </ModalHeader>
      <Formik<IFormikValues>
        onSubmit={handleSubmit}
        initialValues={initieleWaarden}
        ref={formikRef}
        validationSchema={validationSchema}
        render={(formikProps: FormikProps<IFormikValues>) => {
          const { submitForm, isSubmitting, values, isValid } = formikProps;
          return (
            <>
              <ModalBody>
                <div className="form-group">
                  <div className="row">
                    <div className="col-12">
                      <label>{veldnamen.cntRdAfkID}</label>
                      <Field
                        name="cntRdAfkID"
                        render={(fieldProps: FieldProps<IFormikValues>) => (
                          <>
                            <Combobox
                              geselecteerd={fieldProps.field.value}
                              opties={redenenOpties === null ? [] : redenenOpties}
                              onSelectieChange={(id) => {
                                fieldProps.form.setFieldValue(fieldProps.field.name, id);
                              }}
                              legeOptieTonen
                              options={{
                                legeOptieTekst: 'Geen reden',
                              }}
                            />
                            <FormikVeldFout fieldProps={fieldProps} directTonen={hasSubmitted} />
                          </>
                        )}
                      />
                    </div>

                    {/* <div className="col-12 mt-3 d-flex align-items-center">
                      <Field
                        name="bevestigingSturen"
                        render={(fieldProps: FieldProps<IFormikValues>) => (
                          <>
                            <VinkVeld
                              aangevinkt={fieldProps.field.value}
                              onGewijzigd={(x) =>
                                fieldProps.form.setFieldValue(fieldProps.field.name, x)
                              }
                            />
                            <FormikVeldFout fieldProps={fieldProps} directTonen={hasSubmitted} />
                          </>
                        )}
                      />
                      <label className="ml-2 mt-1">{veldnamen.bevestigingSturen}</label>
                    </div> */}

                    {/* {values.bevestigingSturen && (
                      <div className="col-12 mt-2">
                        <label>{veldnamen.persID}</label>
                        <Field
                          name="persID"
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <>
                                <ContactSelectie
                                  alleenVoorRelIDs={[relID]}
                                  value={
                                    field.value === null
                                      ? null
                                      : {
                                          persID: field.value,
                                          orgID: null,
                                        }
                                  }
                                  onChange={(x) =>
                                    form.setFieldValue(field.name, x?.persoon?.PersID ?? null)
                                  }
                                  options={{
                                    types: [EType.Persoon],
                                  }}
                                />
                                <FormikVeldFout
                                  fieldProps={fieldProps}
                                  directTonen={hasSubmitted}
                                />
                              </>
                            );
                          }}
                        />
                      </div>
                    )} */}

                    <div className="col-12 mt-3">
                      <VeldWeergave>
                        Een eventueel bericht kan via een acceptatie-procedure verstuurd worden
                      </VeldWeergave>
                    </div>
                  </div>
                </div>
              </ModalBody>
              <ModalFooter className="d-flex justify-content-start">
                <button
                  className="btn btn-primary"
                  onClick={submitForm}
                  disabled={isSubmitting || !isValid}
                  // disabled={isSubmitting}
                  style={{ width: 100 }}
                >
                  Ok
                </button>
                <button className="btn btn-secondary" onClick={onAnnuleren} style={{ width: 100 }}>
                  Annuleren
                </button>
              </ModalFooter>
            </>
          );
        }}
      />
    </Dialoog>
  );
});

export default AfkeurenContractDialoog;
