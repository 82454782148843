import React, { useCallback, useContext, useMemo, useState } from 'react';
import DienstFormulier, {
  IFormikValues as IDienstFormulierFormikValues,
  validationSchema as dienstFormulierValidationSchema,
} from '../../../DienstFormulier';
import { LeverancierContext } from '../../../../index';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import api from '../../../../../../api';
import { EResultType } from '../../../../../../stores/CheckStore';
import { RootStoreContext } from '../../../../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import { IWijzigenDienstParams } from '../../../../../../../../shared/src/api/v2/dienst/magazijn';
import UitklapFormulier from '../../../../../../components/formulier/UitklapFormulier';
import { Kleur } from '../../../../../../bedrijfslogica/constanten';
import HorizontaleScheidingslijn from '../../../../../../components/layout/HorizontaleScheidingslijn';
import nameof from '../../../../../../core/nameOf';
import NumeriekVeld from '../../../../../../components/formulier/NumeriekVeld';
import FormikVeldFout from '../../../../../../components/formulier/FormikVeldFout';
import TijdVeld, { ITijdVeldWaarde } from '../../../../../../components/formulier/TijdVeld';
import * as Yup from 'yup';

interface IFormikValues extends IDienstFormulierFormikValues {
  emailInventarisatie: string | null;
  emailAfhaalopdrachten: string | null;
  emailVooraanmelding: string | null;
}

export const veldnamen: Partial<Record<keyof IFormikValues, string>> = {
  emailInventarisatie: 'Email tbv inventarisaties',
  emailAfhaalopdrachten: 'Email tbv afhaalopdrachten',
  emailVooraanmelding: 'Email tbv vooraanmeldingen',
};

interface IProps {}

const Dienst: React.FC<IProps> = observer((props) => {
  const { checkStore } = useContext(RootStoreContext);
  const context = useContext(LeverancierContext);
  const magazijndienst = context.magazijndienst.data!;
  const [dienstUitgeklapt, setDienstUitgeklapt] = useState(false);
  const [magazijnUitgeklapt, setMagazijnUitgeklapt] = useState(true);

  const initialValues = useMemo<IFormikValues>(() => {
    return {
      actief: magazijndienst.Actief,
      naam: magazijndienst.Naam || '',
      naamEnum: magazijndienst.NaamEnum ?? '',
      opdWijzeID: magazijndienst.opdrachtwijze!.OpdWijzeID,
      parameters: magazijndienst.Parameters || '',
      emailInventarisatie: magazijndienst.EmailInventarisatie,
      emailAfhaalopdrachten: magazijndienst.EmailAfhaalopdrachten,
      emailVooraanmelding: magazijndienst.EmailVooraanmelding,
    };
  }, [magazijndienst]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);
      const params: IWijzigenDienstParams = {
        relID: context.relID!,
        parameters: values.parameters === '' ? null : values.parameters,
        opdWijzeID: values.opdWijzeID!,
        naam: values.naam,
        naamEnum: values.naamEnum,
        actief: values.actief,

        id: magazijndienst.ID,

        emailInventarisatie: values.emailInventarisatie === '' ? null : values.emailInventarisatie,
        emailAfhaalopdrachten:
          values.emailAfhaalopdrachten === '' ? null : values.emailAfhaalopdrachten,
        emailVooraanmelding: values.emailVooraanmelding === '' ? null : values.emailVooraanmelding,
      };

      const checkData = await api.v2.dienst.magazijn.checkWijzigenDienst(params);
      if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
        actions.setSubmitting(false);
        return;
      }

      await api.v2.dienst.magazijn.wijzigenDienst(params);
      context.onVerversenMagazijndienstAangevraagd();
      actions.setSubmitting(false);
    },
    [context.relID, magazijndienst, context.onVerversenMagazijndienstAangevraagd],
  );

  const validationSchema = useMemo(() => {
    return dienstFormulierValidationSchema.concat(
      Yup.object().shape({
        emailInventarisatie: Yup.string(),
        // emailOpdrachtCC: Yup.string(),
      }),
    );
  }, []);

  return (
    <div className="d-flex flex-column flex-fill">
      <Formik
        enableReinitialize
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
        render={(formikProps: FormikProps<IFormikValues>) => {
          const { submitForm, isSubmitting, isValid, resetForm, dirty } = formikProps;
          return (
            <>
              <div className="d-flex flex-column flex-fill p-3">
                <UitklapFormulier
                  label="Dienst"
                  uitgeklapt={dienstUitgeklapt}
                  onUitgeklaptChange={setDienstUitgeklapt}
                  formulier={<DienstFormulier />}
                />
                <div className="p-2" />
                <UitklapFormulier
                  label="Magazijn"
                  uitgeklapt={magazijnUitgeklapt}
                  onUitgeklaptChange={setMagazijnUitgeklapt}
                  formulier={
                    <div className="p-3">
                      <div className="row mb-3"></div>
                      <div className="row">
                        <div className="col-12">
                          <label>{veldnamen.emailInventarisatie}</label>
                          <Field
                            name={nameof<IFormikValues>('emailInventarisatie')}
                            render={(fieldProps: FieldProps) => {
                              const { field, form } = fieldProps;
                              return (
                                <div>
                                  <input className="form-control" {...fieldProps.field} />
                                  <FormikVeldFout fieldProps={fieldProps} />
                                </div>
                              );
                            }}
                          />
                        </div>
                        <div className="col-12 mt-3">
                          <label>{veldnamen.emailAfhaalopdrachten}</label>
                          <Field
                            name={nameof<IFormikValues>('emailAfhaalopdrachten')}
                            render={(fieldProps: FieldProps) => {
                              const { field, form } = fieldProps;
                              return (
                                <div>
                                  <input className="form-control" {...fieldProps.field} />
                                  <FormikVeldFout fieldProps={fieldProps} />
                                </div>
                              );
                            }}
                          />
                        </div>
                        <div className="col-12 mt-3">
                          <label>{veldnamen.emailVooraanmelding}</label>
                          <Field
                            name={nameof<IFormikValues>('emailVooraanmelding')}
                            render={(fieldProps: FieldProps) => {
                              const { field, form } = fieldProps;
                              return (
                                <div>
                                  <input className="form-control" {...fieldProps.field} />
                                  <FormikVeldFout fieldProps={fieldProps} />
                                </div>
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  }
                />
              </div>
              <HorizontaleScheidingslijn color={Kleur.LichtGrijs} />
              <div className="d-flex p-3">
                <button
                  className="btn btn-sm btn-primary d-flex align-items-center justify-content-center"
                  style={{ width: 100 }}
                  disabled={!isValid || isSubmitting}
                  onClick={submitForm}
                >
                  Ok
                </button>
                <button
                  className="btn btn-sm btn-secondary d-flex align-items-center justify-content-center ml-2"
                  style={{ width: 100 }}
                  disabled={!dirty || isSubmitting}
                  onClick={() => resetForm()}
                >
                  Annuleren
                </button>
              </div>
            </>
          );
        }}
      />
    </div>
  );
});

export default Dienst;
