import api from '../../index';
import {
  ICheckContractVoorWisselenParams,
  IMarkerenContractenVoorWisselParams,
  IMarkerenContractenVoorWisselResult,
} from '../../../../../shared/src/api/v2/contract/wissel';
import ICheckData from '../../../../../shared/src/models/ICheckData';

const wissel = {
  checkMarkerenContractenVoorWissel: async (
    params: IMarkerenContractenVoorWisselParams,
  ): Promise<ICheckData> => {
    return await api.post('/v2/contract/wissel/check-markeren-contracten-voor-wissel', params);
  },
  checkContractVoorWisselen: async (
    params: ICheckContractVoorWisselenParams,
  ): Promise<ICheckData> => {
    return await api.post('/v2/contract/wissel/check-contract-voor-wissel', params);
  },
  markerenContractenVoorWissel: async (
    params: IMarkerenContractenVoorWisselParams,
  ): Promise<IMarkerenContractenVoorWisselResult> => {
    return await api.post('/v2/contract/wissel/markeren-contracten-voor-wissel', params);
  },
  terugdraaienMarkerenContractenVoorWissel: async (
    params: IMarkerenContractenVoorWisselParams,
  ): Promise<IMarkerenContractenVoorWisselResult> => {
    return await api.post(
      '/v2/contract/wissel/terugdraaien-markeren-contracten-voor-wissel',
      params,
    );
  },
};

export default wissel;
