import React, { useCallback, useContext, useState } from 'react';
import styled from 'styled-components';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import { GlobaleRendererContext } from '../../../../one-off-components/GlobaleRenderer';
import ProductSelectieDialoog, {
  IProductSelectieDialoogResult,
} from '../../ProductSelectieDialoog';
import UitlegTooltip from '../../../formulier/UitlegTooltip';
import api from '../../../../api';

const Root = styled.div`
  padding: 10px 20px 14px 20px;
  border-bottom: 1px solid ${Kleur.LichtGrijs};

  button {
    height: calc(1.5em + 0.75rem + 4px);
  }
`;

interface IProps {
  onProdIDGekozen: (prodID: number) => void;
}

interface IEnqueueZoekActieParams {
  soort: 'referentiecode' | 'uuid';
  waarde: string;
}

const SelectieSectie = (props: IProps) => {
  const globaleRenderer = useContext(GlobaleRendererContext);

  const referentiecodeInputRef = React.useRef<HTMLInputElement>(null);
  const [referentiecodeInput, setReferentiecodeInput] = useState<string>('');
  const scannerinvoerInputRef = React.useRef<HTMLInputElement>(null);
  const [scannerinvoerInput, setScannerinvoerInput] = useState<string>('');

  const zoekactieTimeoutRef = React.useRef<NodeJS.Timeout | null>(null);
  const [isBezigMetZoeken, setIsBezigMetZoeken] = useState<boolean>(false);
  const [zoekactieFout, setZoekactieFout] = useState<string | null>(null);
  const enqueueZoekactie = useCallback(
    (params: IEnqueueZoekActieParams) => {
      if (zoekactieTimeoutRef.current !== null) {
        clearTimeout(zoekactieTimeoutRef.current);
      }
      const zoeken = async () => {
        setIsBezigMetZoeken(true);

        const result = await api.v2.product.ophalenProductenV2({
          filterSchema: {
            filters: [
              params.soort === 'referentiecode'
                ? {
                    naam: 'REFERENTIECODES',
                    data: [params.waarde],
                  }
                : {
                    naam: 'PRODREF_UUIDS',
                    data: [params.waarde],
                  },
            ],
          },
          paginatie: {
            index: 0,
            aantal: 1,
          },
        });

        setIsBezigMetZoeken(false);

        switch (params.soort) {
          case 'referentiecode':
            referentiecodeInputRef.current?.focus();
            referentiecodeInputRef.current?.select();
            break;
          case 'uuid':
            scannerinvoerInputRef.current?.focus();
            scannerinvoerInputRef.current?.select();
            break;
        }

        if (result.producten.length === 0) {
          let soort;
          switch (params.soort) {
            case 'referentiecode':
              soort = 'referentiecode';
              break;
            case 'uuid':
              soort = 'scannerinvoer';
              break;
          }
          setZoekactieFout(`Er is geen product gevonden met deze ${soort}`);
          return;
        }
        setZoekactieFout(null);

        const product = result.producten[0]!;
        props.onProdIDGekozen(product.ProdID);
      };
      zoekactieTimeoutRef.current = setTimeout(zoeken, 200);
    },
    [props.onProdIDGekozen],
  );

  const handleReferentiecodeChange = useCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
    const trimmedValue = ev.target.value.trim();
    if (trimmedValue.length === 0) {
      setReferentiecodeInput(trimmedValue);
      return;
    }
    if (trimmedValue.length > 6) {
      return;
    }
    // Check if value only contains digits
    const regex = /^\d+$/;
    if (!regex.test(trimmedValue)) {
      return;
    }

    setReferentiecodeInput(trimmedValue);

    if (trimmedValue.length !== 6) {
      return;
    }

    const referentiecode = trimmedValue;
    enqueueZoekactie({
      soort: 'referentiecode',
      waarde: referentiecode,
    });
  }, []);

  const handleScannerinvoerChange = useCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
    const trimmedValue = ev.target.value.trim();
    setScannerinvoerInput(trimmedValue);

    // Voorbeeld van een QR-code:
    // https://splash.nl/ref/prod/64b20637-b84f-4f8b-b015-60c4131c3dfd

    if (!trimmedValue.startsWith('https://splash.nl/ref/prod/')) {
      return;
    }
    const uuid = trimmedValue.substring('https://splash.nl/ref/prod/'.length);
    if (uuid.length !== 36) {
      return;
    }
    enqueueZoekactie({
      soort: 'uuid',
      waarde: uuid,
    });
  }, []);

  const handleOpenSelectieModaalClick = useCallback(async () => {
    const result = await globaleRenderer.render<IProductSelectieDialoogResult>((renderProps) => (
      <ProductSelectieDialoog
        open
        onSuccess={(result) => renderProps.destroy(result)}
        onAnnuleren={() => renderProps.destroy()}
      />
    ));
    if (result === undefined) {
      return;
    }

    props.onProdIDGekozen(result.prodID);
  }, [props.onProdIDGekozen]);

  return (
    <Root>
      <div className="d-flex">
        <div>
          <label>Referentiecode</label>
          <input
            ref={referentiecodeInputRef}
            className="form-control"
            type="text"
            style={{ width: 200 }}
            value={referentiecodeInput}
            onChange={handleReferentiecodeChange}
            onFocus={(ev) => ev.target.select()}
          />
        </div>

        <div className="ml-3">
          <label className="d-flex align-items-center">
            <span className="mr-2">Scannerinvoer</span>
            <UitlegTooltip inhoud="Invoer ter behoeve van een scanapparaat voor de QR- en barcodes op onze producten." />
          </label>
          <input
            ref={scannerinvoerInputRef}
            className="form-control"
            type="text"
            style={{ width: 375 }}
            value={scannerinvoerInput}
            onChange={handleScannerinvoerChange}
            onFocus={(ev) => ev.target.select()}
          />
        </div>

        <div className="flex-fill d-flex align-items-end justify-content-end">
          <button
            className="btn btn-sm btn-light d-flex align-items-center justify-content-center ml-3"
            style={{ border: `1px solid ${Kleur.LichtGrijs}`, width: '100%' }}
            onClick={handleOpenSelectieModaalClick}
          >
            Open selectiemodaal
          </button>
        </div>
      </div>
      {zoekactieFout !== null && (
        <div className="mt-2">
          <span style={{ color: Kleur.Rood }}>{zoekactieFout}</span>
        </div>
      )}
    </Root>
  );
};

export default SelectieSectie;
