import React from 'react';
import styled from 'styled-components';
import { INavigatieItem } from '../../../../shared';
import { Kleur } from '../../../../../../bedrijfslogica/constanten';
import Highlighted from '../../Highlighted';

const Root = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Naam = styled.span`
  font-size: 14px;
  color: #8a8a8a;
`;

interface IProps {
  navigatieItem: INavigatieItem;
  termen: string[];
}

const NavigatiePadItem = (props: IProps) => {
  const { naam, icoon } = props.navigatieItem;

  const Icoon = icoon;

  return (
    <Root>
      {Icoon !== undefined && (
        <div style={{ position: 'relative', top: -2 }}>
          <Icoon hoogte={14} breedte={14} kleur="rgb(172,172,172)" />
        </div>
      )}
      <Naam style={{ marginLeft: Icoon !== undefined ? 5 : 0 }}>
        <Highlighted tekst={naam} termen={props.termen} />
      </Naam>
    </Root>
  );
};

export default NavigatiePadItem;
