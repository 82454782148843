import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { IRapportageDetailProps } from '../../index';
import { Kleur } from '../../../../../../bedrijfslogica/constanten';
import ASPTabel from '../../../../../../components/tabel/ASPTabel';
import { ASPKolom, EAspKolomBreedteType } from '../../../../../../components/tabel/ASPTabel/types';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../../../models/IRemoteData';
import {
  IWinstEnVerliesrekeningResult,
  IWinstEnVerliesrekeningResultElement,
} from '../../../../../../../../shared/src/api/v2/boekhouding/rapportage';
import api from '../../../../../../api';
import HorizontaleScheidingslijn from '../../../../../../components/layout/HorizontaleScheidingslijn';
import LoadingSpinner from '../../../../../../components/Gedeeld/LoadingSpinner';
import FormatteerBedrag from '../../../../../../components/MutatieBedrag';

const Root = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const GridsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Opsomming = styled.div`
  background-color: ${Kleur.HeelLichtGrijs};
  display: flex;
  align-items: center;
  padding: 12px 30px;
  border-top: 1px solid ${Kleur.LichtGrijs};
`;

enum EKolom {
  Nummer,
  Naam,
  Saldo,
  Percentage,
}

const WinstEnVerliesRekening = (props: IRapportageDetailProps) => {
  const [winstEnVerliesrekening, setWinstEnVerliesrekening] = useState<
    IRemoteData<IWinstEnVerliesrekeningResult>
  >(createPendingRemoteData());
  const ophalenWinstEnVerliesrekening = useCallback(async () => {
    const result = await api.v2.boekhouding.rapportage.winstEnVerliesrekening({
      jaar: props.jaar,
      maandVem: props.maandVem,
      maandTem: props.maandTem,
    });
    setWinstEnVerliesrekening(createReadyRemoteData(result));
  }, [props.jaar, props.maandVem, props.maandTem]);
  useEffect(() => {
    ophalenWinstEnVerliesrekening();
  }, [ophalenWinstEnVerliesrekening]);

  const keyExtractor = useCallback((regel: IWinstEnVerliesrekeningResultElement) => regel.id, []);
  const kolommen = useMemo<ASPKolom<EKolom, IWinstEnVerliesrekeningResultElement>[]>(
    () => [
      {
        key: EKolom.Nummer,
        label: 'Nummer',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 100,
        renderer: (rij) => rij.nummer,
      },
      {
        key: EKolom.Naam,
        label: 'Naam',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 275,
        renderer: (rij) => rij.naam,
      },
      {
        key: EKolom.Saldo,
        label: 'Saldo',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 150,
        renderer: (rij) => <FormatteerBedrag bedrag={rij.saldo} />,
      },
      {
        key: EKolom.Percentage,
        label: 'Percentage',
        breedteType: EAspKolomBreedteType.Flex,
        flex: 1,
        renderer: (rij) => Math.round(rij.percentage * 100) / 100,
      },
    ],
    [],
  );

  const batenRijen = useMemo<IRemoteData<IWinstEnVerliesrekeningResultElement[]>>(() => {
    if (winstEnVerliesrekening.state === ERemoteDataState.Pending) {
      return createPendingRemoteData();
    }

    const regels = winstEnVerliesrekening.data!.regels.filter((regel) => regel.soort === 'B');
    return createReadyRemoteData(regels);
  }, [winstEnVerliesrekening]);

  const lastenRijen = useMemo<IRemoteData<IWinstEnVerliesrekeningResultElement[]>>(() => {
    if (winstEnVerliesrekening.state === ERemoteDataState.Pending) {
      return createPendingRemoteData();
    }

    const regels = winstEnVerliesrekening.data!.regels.filter((regel) => regel.soort === 'L');
    return createReadyRemoteData(regels);
  }, [winstEnVerliesrekening]);

  const saldoBaten = useMemo<IRemoteData<number>>(() => {
    if (batenRijen.state === ERemoteDataState.Pending) {
      return createPendingRemoteData();
    }
    const sum = batenRijen.data!.reduce((acc, cur) => acc + cur.saldo, 0);
    return createReadyRemoteData(sum);
  }, [batenRijen]);

  const saldoLasten = useMemo<IRemoteData<number>>(() => {
    if (lastenRijen.state === ERemoteDataState.Pending) {
      return createPendingRemoteData();
    }
    const sum = lastenRijen.data!.reduce((acc, cur) => acc + cur.saldo, 0);
    return createReadyRemoteData(sum);
  }, [lastenRijen]);

  const saldo = useMemo<IRemoteData<number>>(() => {
    if (
      batenRijen.state === ERemoteDataState.Pending ||
      lastenRijen.state === ERemoteDataState.Pending
    ) {
      return createPendingRemoteData();
    }
    const sumBaten = batenRijen.data!.reduce((acc, cur) => acc + cur.saldo, 0);
    const sumLasten = lastenRijen.data!.reduce((acc, cur) => acc + cur.saldo, 0);
    const saldo = sumBaten + sumLasten;

    return createReadyRemoteData(saldo);
  }, [batenRijen, lastenRijen]);

  return (
    <Root>
      {winstEnVerliesrekening.state === ERemoteDataState.Pending ? (
        <div className="flex-fill d-flex align-items-center justify-content-center">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          <GridsContainer>
            <div className="d-flex flex-column" style={{ flex: 1 }}>
              <h5 className="ml-3 mt-2 mb-2">Baten</h5>
              <ASPTabel rijen={batenRijen.data!} kolommen={kolommen} keyExtractor={keyExtractor} />
            </div>
            <HorizontaleScheidingslijn />
            <div className="d-flex flex-column" style={{ flex: 4 }}>
              <h5 className="ml-3 mt-2 mb-2">Lasten</h5>
              <ASPTabel rijen={lastenRijen.data!} kolommen={kolommen} keyExtractor={keyExtractor} />
            </div>
          </GridsContainer>
          <Opsomming>
            <span className="mr-2">Saldo baten:</span>
            <FormatteerBedrag bedrag={saldoBaten.data!} />
            <span className="ml-5 mr-2">Saldo lasten:</span>
            <FormatteerBedrag bedrag={saldoLasten.data!} />
            <span className="ml-5 mr-2">{saldo.data! < 0 ? 'Winst' : 'Verlies'}:</span>
            <FormatteerBedrag bedrag={Math.abs(saldo.data!)} />
          </Opsomming>
        </>
      )}
    </Root>
  );
};

export default WinstEnVerliesRekening;
