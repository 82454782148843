import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Dialoog from '../../../../components/dialogen/Dialoog';
import IDialoogProps from '../../../../core/IDialoogProps';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import { Field, FieldProps, Formik, FormikActions, FormikErrors } from 'formik';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import nameof from '../../../../core/nameOf';
import FormikVeldFout from '../../../../components/formulier/FormikVeldFout';
import api from '../../../../api';
import teksten from '../../../../bedrijfslogica/teksten';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import {
  IAspGebruiker,
  IAspGebruikersprofiel,
  IOphalenAspGebruikerStatussenResultElement,
} from '../../../../../../shared/src/api/v2/aspGebruiker';
import VinkVeld from '../../../../components/formulier/VinkVeld';
import Combobox from '../../../../components/formulier/Combobox';

interface IDialoogResult {}

interface IProps extends IDialoogProps<IDialoogResult> {
  aspGebrID: number;
}

interface IFormikValues {
  gebruikersnaam: string;
  naamKort: string;
  isAdministrator: boolean;
  apiSleutel: string | null;
  standaardProfiel: number;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  gebruikersnaam: 'Gebruikersnaam',
  naamKort: 'Korte naam',
  isAdministrator: 'Is beheerder',
  apiSleutel: 'API-sleutel',
  standaardProfiel: 'Standaard profiel',
};

const WijzigenGebruikerDialoog: React.FC<IProps> = (props) => {
  const [gebruiker, setGebruiker] = useState<IAspGebruiker | null>(null);
  const [statussen, setStatussen] = useState<IOphalenAspGebruikerStatussenResultElement[] | null>(
    null,
  );
  const [profielen, setProfielen] = useState<IAspGebruikersprofiel[] | null>(null);

  const ophalenGebruiker = useCallback(async () => {
    const gebruikersResult = await api.v2.aspGebruiker.ophalenGebruikers({
      filterSchema: { filters: [{ naam: 'ASP_GEBR_IDS', data: [props.aspGebrID] }] },
    });
    const gebruiker = gebruikersResult.gebruikers.find((x) => x.AspGebrID === props.aspGebrID)!;

    setGebruiker(gebruiker);
  }, [props.aspGebrID]);

  useEffect(() => {
    ophalenGebruiker();
  }, [ophalenGebruiker]);

  const ophalenStatussen = useCallback(async () => {
    const result = await api.v2.aspGebruiker.ophalenAspGebruikerStatussen({});

    setStatussen(result.statussen);
  }, []);

  useEffect(() => {
    ophalenStatussen();
  }, [ophalenStatussen]);

  const ophalenProfielen = useCallback(async () => {
    if (gebruiker === null) {
      return;
    }
    const result = await api.v2.aspGebruiker.ophalenGebruikersprofielen({
      filterSchema: { filters: [{ naam: 'ASP_GEBR_IDS_IN', data: [gebruiker.AspGebrID] }] },
    });

    setProfielen(result.profielen);
  }, [gebruiker]);

  useEffect(() => {
    ophalenProfielen();
  }, [ophalenProfielen]);

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (gebruiker === null) {
      return null;
    }
    return {
      gebruikersnaam: gebruiker.GebruikersNaam,
      naamKort: gebruiker.NaamKort,
      apiSleutel: gebruiker.ApiSleutel,
      isAdministrator: gebruiker.IsAdministrator,
      standaardProfiel: gebruiker.AspGebrProfielID,
    };
  }, [gebruiker]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      await api.v2.aspGebruiker.wijzigenGebruiker({
        aspGebrID: props.aspGebrID,
        gebruikersnaam: values.gebruikersnaam,
        naamKort: values.naamKort,
        apiSleutel: values.apiSleutel,
        isAdministrator: values.isAdministrator,
        aspGebrProfielID: values.standaardProfiel,
      });

      props.onSuccess({});
    },
    [],
  );

  const validate = useCallback((values: IFormikValues): FormikErrors<IFormikValues> => {
    const errors: FormikErrors<IFormikValues> = {};

    if (values.gebruikersnaam === null) {
      errors.gebruikersnaam = teksten.formulier.E_VERPLICHT_VELD({ veldnaam: 'Gebruikersnaam' });
    }

    return errors;
  }, []);

  if (initialValues === null || statussen === null || profielen === null) {
    return <LoadingSpinner />;
  }

  return (
    <Dialoog index={props.dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>ASP-Gebruiker wijzigen</ModalTitle>
      </ModalHeader>
      <Formik<IFormikValues>
        onSubmit={handleSubmit}
        validate={validate}
        initialValues={initialValues}
        render={(formikProps) => {
          const { submitForm, isSubmitting } = formikProps;

          if (gebruiker === null) {
            return <LoadingSpinner />;
          }

          return (
            <>
              <ModalBody>
                <div className="row">
                  <div className="col-8">
                    <label>{veldnamen.gebruikersnaam}</label>
                    <Field
                      name={nameof<IFormikValues>('gebruikersnaam')}
                      render={(fieldProps: FieldProps<IFormikValues>) => {
                        const { field, form } = fieldProps;
                        return (
                          <div>
                            <input type="text" className="form-control" {...field} />
                            <FormikVeldFout fieldProps={fieldProps} />
                          </div>
                        );
                      }}
                    />
                  </div>

                  <div className="col-4">
                    <label>{veldnamen.naamKort}</label>
                    <Field
                      name={nameof<IFormikValues>('naamKort')}
                      render={(fieldProps: FieldProps<IFormikValues>) => {
                        const { field, form } = fieldProps;
                        return (
                          <div>
                            <input type="text" className="form-control" {...field} />
                            <FormikVeldFout fieldProps={fieldProps} />
                          </div>
                        );
                      }}
                    />
                  </div>

                  <div className="col-12 mt-3">
                    <label>{veldnamen.apiSleutel}</label>
                    <Field
                      name={nameof<IFormikValues>('apiSleutel')}
                      render={(fieldProps: FieldProps<IFormikValues>) => {
                        const { field, form } = fieldProps;
                        return (
                          <div>
                            <input type="text" className="form-control" {...field} />
                            <FormikVeldFout fieldProps={fieldProps} />
                          </div>
                        );
                      }}
                    />
                  </div>

                  <div className="col-12 mt-3">
                    <label>{veldnamen.standaardProfiel}</label>
                    <Field
                      name={nameof<IFormikValues>('standaardProfiel')}
                      render={(fieldProps: FieldProps<IFormikValues>) => {
                        const { field, form } = fieldProps;

                        if (profielen === null) {
                          return <LoadingSpinner />;
                        }

                        return (
                          <div>
                            <Combobox
                              legeOptieTonen
                              geselecteerd={field.value}
                              onSelectieChange={(x) => form.setFieldValue(field.name, x)}
                              opties={profielen.map((x: any) => {
                                return {
                                  id: x.ID,
                                  label: x.Naam,
                                };
                              })}
                            />
                            <FormikVeldFout fieldProps={fieldProps} />
                          </div>
                        );
                      }}
                    />
                  </div>

                  <div className="col-12 mt-3">
                    <Field
                      name={nameof<IFormikValues>('isAdministrator')}
                      render={(fieldProps: FieldProps<IFormikValues>) => {
                        const { field, form } = fieldProps;

                        return (
                          <>
                            <div className="d-flex align-items-center">
                              <VinkVeld
                                aangevinkt={field.value}
                                onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                              />
                              <span className="ml-2">Is beheerder</span>
                            </div>
                            <FormikVeldFout fieldProps={fieldProps} />
                          </>
                        );
                      }}
                    />
                  </div>
                </div>
              </ModalBody>
              <ModalFooter className="d-flex flex-row justify-content-start">
                <button
                  className="btn btn-primary d-flex align-items-center justify-content-center"
                  onClick={submitForm}
                  style={{ width: 100 }}
                  disabled={isSubmitting}
                >
                  Ok
                </button>
                <button
                  className="btn btn-secondary"
                  onClick={props.onAnnuleren}
                  style={{ width: 100 }}
                  disabled={isSubmitting}
                >
                  Annuleren
                </button>
              </ModalFooter>
            </>
          );
        }}
      />
    </Dialoog>
  );
};

export default WijzigenGebruikerDialoog;
