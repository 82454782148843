import api from '../../../index';
import extern from './extern';
import intern from './intern';

const lijst = {
  intern,
  extern,
};

export default lijst;
