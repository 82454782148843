import React, { useContext } from 'react';
import { Document, Page } from 'react-pdf';
import Skeleton from 'react-loading-skeleton';
import { IconUitvergroten } from '../../../../Icons';
import { Kleur } from '../../../../../bedrijfslogica/constanten';
import MediaWeergaveDialoog from '../../../../dialogen/MediaWeergaveDialoog';
import { EMediaWeergaveType } from '../../../../MediaWeergave';
import { observer } from 'mobx-react-lite';
import { GlobaleRendererContext } from '../../../../../one-off-components/GlobaleRenderer';

interface IProps {
  src: string;
  width: number;
  height: number;
}

const PDF = observer((props: IProps) => {
  const rendererContext = useContext(GlobaleRendererContext);
  const [isHovered, setIsHovered] = React.useState(false);

  const w = props.width * (2 / 3);
  const h = props.height * (2 / 3);

  const pageWidth = w * (2 / 3);
  const pageHeight = pageWidth * 1.41;

  return (
    <div
      style={{
        width: w,
        height: h,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      onClick={(ev) => {
        ev.stopPropagation();
      }}
    >
      <div
        onMouseEnter={() => {
          setIsHovered(true);
        }}
        onMouseLeave={() => {
          setIsHovered(false);
        }}
        style={{
          cursor: 'pointer',
          position: 'relative',
        }}
      >
        <Document
          file={props.src}
          loading={() => {
            return <Skeleton width={pageWidth} height={pageHeight} />;
          }}
          error={() => {
            return (
              <div
                style={{ width: pageWidth, height: pageHeight }}
                className="d-flex align-items-center justify-content-center"
              >
                Fout bij laden
              </div>
            );
          }}
        >
          <Page
            pageNumber={1}
            width={pageWidth}
            height={pageHeight}
            renderAnnotationLayer={false}
            renderTextLayer={false}
            loading={() => {
              return <Skeleton width={pageWidth} height={pageHeight} />;
            }}
          />
        </Document>
        {isHovered && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              backgroundColor: 'rgba(0,0,0,.3)',
            }}
            className="d-flex align-items-center justify-content-center"
            onClick={async (ev) => {
              ev.stopPropagation();

              await rendererContext.render((renderProps) => {
                return (
                  <MediaWeergaveDialoog
                    current={1}
                    mediaWeergaven={[
                      {
                        id: 1,
                        src: props.src,
                        mediaType: 'application/pdf',
                        type: EMediaWeergaveType.PDF,
                      },
                    ]}
                    open
                    onSuccess={() => renderProps.destroy()}
                    onAnnuleren={() => renderProps.destroy()}
                  />
                );
              });
            }}
          >
            <IconUitvergroten style={{ fill: Kleur.Wit, width: 32, height: 32 }} />
          </div>
        )}
      </div>
    </div>
  );
});

export default PDF;
