import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import md5 from 'md5';
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-loading-skeleton/dist/skeleton.css';
import moment from 'moment';

// Maak globaal beschikbaar om makkelijk te kunnen testen
(window as any).md5 = md5;

moment.locale('nl');

// @ts-ignore
//const root = ReactDOM.createRoot(document.getElementById('root'));
//root.render(<App />);
ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
