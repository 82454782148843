import React, { useContext } from 'react';
import { RootStoreContext } from '../../../stores/RootStore';
import GegevensLayout from '../../layout/GegevensLayout';
import { format } from 'date-fns';
import VerticaleScheidingslijn from '../../layout/VerticaleScheidingslijn';
import BooleanWeergave from '../../tabel/BooleanWeergave';
import { Kleur } from '../../../bedrijfslogica/constanten';
import { EResultType } from '../../../stores/CheckStore';
import api from '../../../api';
import { observer } from 'mobx-react-lite';
import {
  IOphalenOpdrachtenResultElement,
  IOphalenOpdrachtregelsResultElement,
} from '../../../../../shared/src/api/v2/transport/opdracht';
import { IconKlok } from '../../Icons';

export interface IRegel extends IOphalenOpdrachtregelsResultElement {
  opdracht: IOphalenOpdrachtenResultElement;
}

interface IProps {
  regel: IRegel;
  onRequestVerversRegel: () => void;
}

const TransportregelDetail: React.FC<IProps> = observer((props) => {
  const { checkStore } = useContext(RootStoreContext);

  const bezoektijdenActueel = (
    <span>
      {props.regel.opdracht.BezoektijdenActueel ? (
        <IconKlok style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
      ) : (
        ''
      )}
    </span>
  );

  return (
    <div className="p-2 d-flex">
      <GegevensLayout
        gegevens={[
          {
            label: 'Bezoekdatum',
            waarde:
              props.regel.opdracht.Bezoekdatum === null
                ? 'Niet opgegeven'
                : format(new Date(props.regel.opdracht.Bezoekdatum), 'dd-MM-yyyy'),
          },
          {
            label: 'Bezoektijd van',
            waarde:
              props.regel.opdracht.BezoekdatumVan === null ? (
                <span>Niet opgegeven</span>
              ) : (
                <span>
                  {format(new Date(props.regel.opdracht.BezoekdatumVan), 'HH:mm')}
                  &nbsp;&nbsp;
                  {bezoektijdenActueel}
                </span>
              ),
          },
          {
            label: 'Bezoektijd tot',
            waarde:
              props.regel.opdracht.BezoekdatumTot === null
                ? 'Niet opgegeven'
                : format(new Date(props.regel.opdracht.BezoekdatumTot), 'HH:mm'),
          },
        ]}
      />
      <div className="ml-3 mr-3">
        <VerticaleScheidingslijn />
      </div>
      <GegevensLayout
        gegevens={[
          {
            label: 'Trekschakelaar',
            waarde:
              props.regel.AansluitenTrekschakelaar === null ? (
                'n.v.t.'
              ) : (
                <BooleanWeergave waarde={props.regel.AansluitenTrekschakelaar} />
              ),
          },
        ]}
      />
      <div className="ml-3 mr-3">
        <VerticaleScheidingslijn />
      </div>
      <GegevensLayout
        gegevens={[
          {
            label: 'Bulkproduct',
            waarde:
              props.regel.type === null ? (
                'n.v.t.'
              ) : (
                <BooleanWeergave waarde={props.regel.type.Bulkproduct} />
              ),
          },
          {
            label: 'Nieuw geleverd',
            waarde: <BooleanWeergave waarde={props.regel.NieuwGeleverd} />,
          },
        ]}
      />
      <div className="ml-3 mr-3">
        <VerticaleScheidingslijn />
      </div>
      <div>
        <button
          className="btn btn-sm btn-light d-flex align-items-center justify-content-center"
          style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
          onClick={async () => {
            if (
              (await checkStore.bevestigen({ inhoud: 'Bezoektijden ophalen?' })).type ===
              EResultType.Annuleren
            ) {
              return;
            }

            await api.v2.transport.bijwerkenBezoektijden({
              trsOpdIDs: [props.regel.TrsOpdID],
              tijdenActualiseren: true,
            });
            props.onRequestVerversRegel();
          }}
        >
          Bezoektijden ophalen
        </button>
      </div>
    </div>
  );
});

export default TransportregelDetail;
