import React, { ReactNode } from 'react';
import { ITableDataProps } from './Body/TableData';
import { IUitgeklapteRijProps } from './Body/UitgeklapteRij';
import { ISelectieTableDataProps } from './Body/SelectieTableData';
import { IUitklapTableDataProps } from './Body/UitklapTableData';

export enum EAspKolomBreedteType {
  Vast,
  Flex,
}

export interface IASPKolomVasteBreedte {
  breedteType: EAspKolomBreedteType.Vast;
  vasteBreedte: number;
}

export interface IASPKolomFlexBreedte {
  breedteType: EAspKolomBreedteType.Flex;
  flex: number;
  minimaleVasteBreedte?: number;
  maximaleVasteBreedte?: number;
}

export type AspKolomRenderer<TRow> = (
  row: TRow,
) => JSX.Element | string | number | null | undefined;

export interface IASPKolomBase<TKey extends keyof any, TRow> {
  key: TKey;
  label?: React.VoidFunctionComponent | string | null;
  renderer?: AspKolomRenderer<TRow>;
  tdComponent?: React.ComponentType<ITableDataProps<TKey, TRow>>;
  /*
   * De vergelijkingsfunctie wordt toegepast tijdens het lokaal sorteren van de rijen
   */
  vergelijkingsfunctie?: (links: TRow, rechts: TRow) => -1 | 0 | 1;
  /*
   * De waarde wordt toegepast tijdens het lokaal sorteren van de rijen
   */
  vergelijkingswaarde?: (row: TRow) => any;
}

export type ASPKolom<TKey extends keyof any, TRow> = IASPKolomBase<TKey, TRow> &
  (IASPKolomVasteBreedte | IASPKolomFlexBreedte);

export enum ESortering {
  Ascending = 'ASC',
  Descending = 'DESC',
}

export interface IAspKolomSorteringItem<TKey extends keyof any> {
  key: TKey;
  sortering: ESortering;
}

export interface IASPTabelSorteringOpties<TKey extends keyof any> {
  modus?: ESorteringModus;
  kolomOpties?: Partial<Record<TKey, IASPTabelSorteringKolomOpties>>;
}

export enum ESorteringModus {
  Whitelist,
  Blacklist,
}

export interface IASPTabelSorteringKolomOpties {
  magSorteren?: boolean;
}
