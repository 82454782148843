import React from 'react';
import { TableRowDetail } from '@devexpress/dx-react-grid-bootstrap4';
import { IEigenschapProductEntiteit } from '../../../../../../../shared/src/api/v2/aanbod/index';

const RowDetailComp = (props: TableRowDetail.RowProps) => {
  const row: IEigenschapProductEntiteit = props.row;

  return <div>{row.ID}</div>;
};

export default RowDetailComp;
