import React, { Component } from 'react';

interface IProps {
  waarde: boolean;
}

const BooleanWeergave: React.FC<IProps> = (props) => {
  return <span>{props.waarde ? 'Ja' : 'Nee'}</span>;
};

export default BooleanWeergave;
