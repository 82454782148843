import api from '../index';
import {
  IBijwerkenLijstRecentParams,
  IOphalenGepindeRelatiesParams,
  IOphalenGepindeRelatiesResult,
  IOphalenLijstRecentParams,
  IOphalenLijstRecentResult,
  IPinRelatieParams,
  IPinRelatieResult,
  ISelecterenRelatiesParams,
  ISelecterenRelatiesResult,
} from '../../../../shared/src/api/v2/relatie/Selectie';
import IPaginatiePositie from '../../../../shared/src/models/IPaginatiePositie';

const relatieZoeken = {
  zoeken: async (params: ISelecterenRelatiesParams): Promise<ISelecterenRelatiesResult> => {
    return await api.post('/v2/relatie/selecteren-relaties', params);
  },
  ophalenLijstRecent: async (params: IOphalenLijstRecentParams) => {
    return await api.post<IOphalenLijstRecentResult>('/v2/relatie/ophalen-lijst-recent', params);
  },
  bijwerkenLijstRecent: async (params: IBijwerkenLijstRecentParams) => {
    return await api.post<any>('/v2/relatie/bijwerken-lijst-recent', params);
  },
  ophalenGepindeRelaties: async (params: IOphalenGepindeRelatiesParams) => {
    return await api.post<IOphalenGepindeRelatiesResult>(
      '/v2/relatie/ophalen-gepinde-relaties',
      params,
    );
  },
  pinRelatie: async (params: IPinRelatieParams): Promise<IPinRelatieResult> => {
    return await api.post('/v2/relatie/pin-relatie', params);
  },
};

export default relatieZoeken;
