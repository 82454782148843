import * as React from 'react';
import TegelBasis from '../../../../../../../components/tegel/TegelBasis';
import NavigatiePijl from '../NavigatiePijl';
import { IOphalenInformatieVoorOverzichtContactverzoek } from '../../../../../../../../../shared/src/api/v2/relatie';
import { genereerUrlStateQueryParam } from '../../../../../../../core/useUrlState';

import {
  IUrlState as ICommunicatieUrlState,
  defaultUrlState as defaultCommunicatieUrlState,
} from '../../../../../../../components/kaart/entiteiten/Communicatie/Overzicht';
import { format } from 'date-fns';

interface IProps {
  relID: number;
  data: IOphalenInformatieVoorOverzichtContactverzoek;
}

const Contacttegel = (props: IProps) => {
  const datum = props.data.datum;

  let tekst = '';
  if (format(new Date(datum), 'yyyy-MM-dd') === format(new Date(), 'yyyy-MM-dd')) {
    tekst = 'Er is vandaag via de website een contactverzoek gedaan';
  } else {
    tekst = `Er is recentelijk op de website een contactverzoek gedaan`;
  }

  return (
    <TegelBasis titel="Contactverzoek">
      <div>
        <div className="d-flex align-items-center">
          {/* <span>Account {props.data.gebruikersnaam} dient nog te worden geactiveerd</span> */}
          <span>{tekst}</span>
          <span className="ml-2">
            <NavigatiePijl
              relID={props.relID}
              pathWithStateBuilder={() => {
                const state = genereerUrlStateQueryParam<ICommunicatieUrlState>({
                  ...defaultCommunicatieUrlState,
                  // uitgeklapt: [],
                });
                return `communicatie/overzicht?state=${state}`;
              }}
            />
          </span>
        </div>
      </div>
    </TegelBasis>
  );
};

export default Contacttegel;
