import { IOphalenDagboekenResultElement } from '../../../../../../../shared/src/api/v2/boekhouding/boeking/dagboek';
import api from '../../../../../api';

export interface IDagboekenProvider {
  provide: () => Promise<IOphalenDagboekenResultElement[]>;
}

export const standaardDagboekenProvider: IDagboekenProvider = {
  provide: async () => {
    const result = await api.v2.boeking.dagboek.ophalenDagboeken({
      filterSchema: {
        filters: [],
      },
    });

    return result.dagboeken;
  },
};
