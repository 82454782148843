import axios, { AxiosRequestConfig, AxiosInstance } from 'axios';
import v2 from './v2';
// import IPaginatiePositie from '../../../shared/src/models/IPaginatiePositie';

import { rootStoreInstance } from '../stores/RootStore';
import services from './services';

export const apiBaseURL = process.env.REACT_APP_API_BASE_URL;

export interface IPaginatiePositie {
  index: number;
  aantal: number;
}

const baseConfig: AxiosRequestConfig = {
  baseURL: apiBaseURL,
};

const getKey = (): string | null => {
  return null;
  // const gebruiker: any = localStorage.getItem('ASPGebruiker');
  // if (gebruiker) {
  //   return JSON.parse(gebruiker).ApiSleutel;
  // } else {
  //   return null;
  // }
};

const createAxiosInstance = (withoutInterceptor = false): AxiosInstance => {
  let headers = {};
  const key = getKey();
  if (key !== null) {
    headers = {
      'x-api-key': getKey(),
    };
  }

  const client = axios.create({
    ...baseConfig,
    headers,
    withCredentials: true,
  });
  if (!withoutInterceptor) {
    client.interceptors.response.use(
      (x) => x,
      (err) => {
        if (axios.isAxiosError(err)) {
          if (axios.isCancel(err)) {
            throw err;
          }

          if (err.code === 'ERR_NETWORK') {
            rootStoreInstance.apiStore.traceerBereikbaarheid();
            throw err;
          }

          const response = err.response;
          // Als response status 401 is (unauthorized) hoeven we niet te kijken of de verbinding
          // weer lukt, want er is wel verbinding mogelijk, maar de gebruiker moet gewoon opnieuw inloggen.
          if (response !== undefined && response.status === 401) {
            // console.log('auth fout');
            throw err;
          }
          // noinspection JSIgnoredPromiseFromCall
        }

        throw err;
      },
    );
  }

  return client;
};

let axiosInstance: AxiosInstance = createAxiosInstance();

export const resetAxios = () => {
  axiosInstance = createAxiosInstance();
};

export const paginatiePositieOphogen = (paginatie: IPaginatiePositie): IPaginatiePositie => {
  return {
    ...paginatie,
    index: paginatie.index + paginatie.aantal,
  };
};

const api = {
  v2,
  check: async (): Promise<any> => {
    return await createAxiosInstance(true).post('/v2/check');
  },
  post: async <TData = any>(
    apiUrl: string,
    data?: any,
    config?: AxiosRequestConfig,
  ): Promise<TData> => {
    // if (!rootStoreInstance.apiStore.bereikbaar) {
    //   // Oneindig blijven wachten tot window reload
    //   await new Promise<TData>(() => null);
    // }
    const response = await axiosInstance.post(apiUrl, data, config);

    const apiResult = response.data;
    if (apiResult.error) {
      throw new Error(apiResult.error.message);
    }
    return apiResult.data as TData;
  },
  services,
};

export default api;
