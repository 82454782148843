import { dagDatum } from '../../../../../helpers/datum';

export interface IBoekdatumProvider {
  provide: () => Promise<Date | null>;
}

export const standaardBoekdatumProvider: IBoekdatumProvider = {
  provide: async () => {
    return dagDatum(new Date());
  },
};
