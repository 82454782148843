import activaregister from './activaregister';
import boeking from './boeking';
import api from '../..';
import {
  IBepalenRelatieMuterenBronParams,
  IBepalenRelatieMuterenBronResult,
} from '../../../../../shared/src/api/v2/boekhouding/index';
import controle from './controle';
import rapportage from './rapportage';

const index = {
  activaregister,
  boeking,
  controle,
  rapportage,
  bepalenRelatieMuterenBron: async (
    params: IBepalenRelatieMuterenBronParams,
  ): Promise<IBepalenRelatieMuterenBronResult> => {
    return await api.post('/v2/boekhouding/bepalen-relatie-muteren-bron', params);
  },
};

export default index;
