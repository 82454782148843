import { Formik } from 'formik';
import React, { useCallback, useContext, useState, useMemo, useEffect } from 'react';
import { ModalFooter, ModalTitle } from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/ModalHeader';
import {
  IOphalenOpdrachtenResultElement,
  IOphalenOpdrachtregelsResultElement,
} from '../../../../../shared/src/api/v2/transport/opdracht';
import IDialoogProps from '../../../core/IDialoogProps';
import Dialoog from '../../dialogen/Dialoog';
import LoadingSpinner from '../../Gedeeld/LoadingSpinner';
import Tabblad, { ITabblad } from '../../layout/Tabblad';
import ProducttypenTab from './ContractenTab';
import ContractenTab from './ContractenTab';

import _ from 'lodash';
import { IOphalenProductmodellenResultElement } from '../../../../../shared/src/api/v2/aanbod/productmodel';
import api from '../../../api';
import { IOphalenContractenResultElementV2 } from '../../../../../shared/src/api/v2/contract';
import { addDays } from 'date-fns';

interface IProps extends IDialoogProps<{}> {
  prodModID: number;
}

enum ETablad {
  Contracten,
  Producttypen,
}

export interface IRegel extends IOphalenOpdrachtregelsResultElement {
  opdracht: IOphalenOpdrachtenResultElement;
}
export interface IContext {
  productmodel: IOphalenProductmodellenResultElement | null;
  contracten: IOphalenContractenResultElementV2[] | null;
  peildatum: Date | null;
}

export const ProductContext = React.createContext<IContext>({
  productmodel: null,
  contracten: null,
  peildatum: null,
});

export interface IFormikValues {}

const aantalDagen = 30; // Zoveel dagen terug voor het overzicht

export const veldnamen: Record<keyof IFormikValues, string> = {};

const ModelInfoDialoog: React.FC<IProps> = (props) => {
  const { dialoogIndex, onAnnuleren, onSuccess, open } = props;

  const [tabblad, setTabblad] = useState<ETablad>(ETablad.Contracten);
  const [productmodel, setProductmodel] = useState<IOphalenProductmodellenResultElement | null>(
    null,
  );
  const [contracten, setContracten] = useState<IOphalenContractenResultElementV2[] | null>(null);

  const peildatum = addDays(new Date(), -aantalDagen);

  const tabbladen = useMemo<ITabblad<ETablad>[]>(
    () => [
      {
        id: ETablad.Contracten,
        label: 'Contracten',
        content: ContractenTab,
      },
      // {
      //   id: ETablad.Producttypen,
      //   label: 'Producttypen',
      //   content: ProducttypenTab,
      // },
    ],
    [onSuccess],
  );

  const ophalenProductmodel = useCallback(async () => {
    const productmodel = (
      await api.v2.product.model.ophalenProductmodellen({
        filterSchema: { filters: [{ naam: 'IDS', data: [props.prodModID] }] },
      })
    ).modellen[0];

    setProductmodel(productmodel);
  }, [props.prodModID]);

  useEffect(() => {
    ophalenProductmodel();
  }, [ophalenProductmodel]);

  const ophalenContracten = useCallback(async () => {
    if (productmodel === null) {
      return;
    }

    const contracten = (
      await api.v2.contract.ophalenContractenV2({
        filterSchema: {
          filters: [
            { naam: 'PRODMOD_IDS', data: [productmodel.ProdModID] },
            { naam: 'INGANGSDATUM_VANAF', data: peildatum },
          ],
        },
        orderSchema: { orders: [{ naam: 'INGANGSDATUM', richting: 'DESC' }] },
      })
    ).contracten;

    setContracten(contracten);
  }, [productmodel]);

  useEffect(() => {
    ophalenContracten();
  }, [ophalenContracten]);

  const initialFormikValues = useMemo<IFormikValues>(() => {
    return {};
  }, [productmodel]);

  return (
    <Dialoog index={dialoogIndex || 0} modalProps={{ dialogClassName: `dialog-width-550` }}>
      <ModalHeader>
        <ModalTitle>Modelinformatie</ModalTitle>
      </ModalHeader>
      <ProductContext.Provider
        value={{
          productmodel,
          contracten,
          peildatum,
        }}
      >
        <div className="d-flex flex-column flex-fill" style={{ minHeight: `600px` }}>
          {initialFormikValues === null ? (
            <div className="d-flex flex-fill align-items-center justify-content-center">
              <LoadingSpinner />
            </div>
          ) : (
            <>
              <Formik<IFormikValues>
                isInitialValid
                initialValues={initialFormikValues}
                onSubmit={() => null}
                render={(FormikProps) => {
                  const { submitForm, isSubmitting, values, errors } = FormikProps;
                  return (
                    <>
                      <div className="d-flex flex-column flex-fill">
                        <Tabblad
                          tabbladen={tabbladen}
                          geselecteerd={tabblad}
                          onSelectieChange={(id) => {
                            setTabblad(id);
                          }}
                        />
                      </div>
                      <ModalFooter className="d-flex flex-row justify-content-start">
                        <button
                          className="btn btn-primary"
                          onClick={onAnnuleren}
                          style={{ width: 100 }}
                        >
                          Sluiten
                        </button>
                      </ModalFooter>
                    </>
                  );
                }}
              />
            </>
          )}
        </div>
      </ProductContext.Provider>
    </Dialoog>
  );
};

export default ModelInfoDialoog;
