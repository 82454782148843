import api from '../../index';
import {
  IOphalenProductmodellenParams,
  IOphalenProductmodellenResult,
  IToevoegenProductmodelParams,
  IToevoegenProductmodelResult,
  IWijzigenProductmodelParams,
  IWijzigenProductmodelResult,
  IVerwijderenProductmodelParams,
  IVerwijderenProductmodelResult,
  IOphalenAlternatieveProductmodellenParams,
  IOphalenAlternatieveProductmodellenResult,
  IOphalenProductmodellenInLopendeContractenParams,
} from '../../../../../shared/src/api/v2/aanbod/productmodel';
import ICheckData from '../../../../../shared/src/models/ICheckData';
import alternatief from './model/alternatief';

const productmodel = {
  alternatief,
  ophalenProductmodellen: async (
    params: IOphalenProductmodellenParams,
  ): Promise<IOphalenProductmodellenResult> => {
    return await api.post('v2/product/model/ophalen-productmodellen', params);
  },
  checkToevoegenProductmodel: async (params: IToevoegenProductmodelParams): Promise<ICheckData> => {
    return await api.post('v2/product/model/check-toevoegen-productmodel', params);
  },
  toevoegenProductmodel: async (
    params: IToevoegenProductmodelParams,
  ): Promise<IToevoegenProductmodelResult> => {
    return await api.post('v2/product/model/toevoegen-productmodel', params);
  },
  checkWijzigenProductmodel: async (params: IWijzigenProductmodelParams): Promise<ICheckData> => {
    return await api.post('v2/product/model/check-wijzigen-productmodel', params);
  },
  wijzigenProductmodel: async (
    params: IWijzigenProductmodelParams,
  ): Promise<IWijzigenProductmodelResult> => {
    return await api.post('v2/product/model/wijzigen-productmodel', params);
  },
  checkVerwijderenProductmodellen: async (
    params: IVerwijderenProductmodelParams,
  ): Promise<ICheckData> => {
    return await api.post('v2/product/model/check-verwijderen-productmodellen', params);
  },
  verwijderenProductmodellen: async (
    params: IVerwijderenProductmodelParams,
  ): Promise<IVerwijderenProductmodelResult> => {
    return await api.post('v2/product/model/verwijderen-productmodellen', params);
  },
  ophalenAlternatieveProductmodellen: async (
    params: IOphalenAlternatieveProductmodellenParams,
  ): Promise<IOphalenAlternatieveProductmodellenResult> => {
    return await api.post('v2/product/model/ophalen-alternatieve-productmodellen', params);
  },
  ophalenProductmodellenInLopendeContracten: async (
    params: IOphalenProductmodellenInLopendeContractenParams,
  ): Promise<number[]> => {
    return await api.post('v2/product/model/ophalen-productmodellen-in-lopende-contracten', params);
  },
};

export default productmodel;
