import {
  IMuterenDocumentenParams,
  IMuterenDocumentenResult,
  IOphalenDocumentenParams,
  IOphalenDocumentenResult,
} from '../../../../../shared/src/api/v2/relatie/document';
import api from '../../index';

const document = {
  ophalenDocumenten: async (
    params: IOphalenDocumentenParams,
  ): Promise<IOphalenDocumentenResult> => {
    return await api.post('v2/relatie/document/ophalen-documenten', params);
  },
  muterenDocumenten: async (
    params: IMuterenDocumentenParams,
  ): Promise<IMuterenDocumentenResult> => {
    return await api.post('v2/relatie/document/muteren-documenten', params);
  },
};

export default document;
