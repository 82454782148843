import { Formik, FormikActions, FormikProps, Field, FieldProps } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useContext, useRef, useState, useCallback, useEffect, useMemo } from 'react';
import { ModalTitle, ModalBody, ModalFooter } from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/esm/ModalHeader';
import api from '../../../../../../../api';
import LoadingSpinner from '../../../../../../../components/Gedeeld/LoadingSpinner';
import Dialoog from '../../../../../../../components/dialogen/Dialoog';
import IDialoogProps from '../../../../../../../core/IDialoogProps';
import nameOf from '../../../../../../../core/nameOf';
import IRemoteData, {
  createPendingRemoteData,
  ERemoteDataState,
  createReadyRemoteData,
} from '../../../../../../../models/IRemoteData';
import { EResultType } from '../../../../../../../stores/CheckStore';
import { RootStoreContext } from '../../../../../../../stores/RootStore';
import {
  IDienstAfwezigheid,
  IWijzigenDienstAfwezigheidParams,
} from '../../../../../../../../../shared/src/api/v2/dienst/service/afwezigheid';
import * as Yup from 'yup';
import DatumKiezer from '../../../../../../../components/formulier/DatumKiezer';
import { dagDatum } from '../../../../../../../helpers/datum';
import moment from 'moment';

interface IDialoogResult {}

interface IProps extends IDialoogProps<IDialoogResult> {
  id: number;
  dienstID: number;
}

interface IFormikValues {
  ID: number;
  afwezigVan: Date | string;
  afwezigTem: Date | string;
}

const WijzigenDialoog: React.FC<IProps> = observer((props) => {
  const { dialoogIndex, onAnnuleren, onSuccess } = props;
  const { checkStore } = useContext(RootStoreContext);

  const [afwezigheid, setAfwezigheid] = useState<IRemoteData<IDienstAfwezigheid>>(
    createPendingRemoteData(),
  );

  const ophalenAfwezigheden = useCallback(async () => {
    let filterSchema = {
      filters: [{ naam: 'IDS', data: [props.id] }],
    };

    const result = await api.v2.dienst.service.afwezigheid.ophalenDienstAfwezigheden({
      filterSchema,
    });

    setAfwezigheid(createReadyRemoteData(result.afwezigheden[0]));
  }, []);

  useEffect(() => {
    ophalenAfwezigheden();
  }, [ophalenAfwezigheden]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      const params: IWijzigenDienstAfwezigheidParams = {
        ID: props.id,
        ServDienstID: props.dienstID,
        AfwezigVan: values.afwezigVan,
        AfwezigTem: values.afwezigTem,
      };

      const checkData = await api.v2.dienst.service.afwezigheid.checkWijzigenDienstAfwezigheid(
        params,
      );
      const checkResult = await checkStore.controleren({
        checkData,
      });
      if (checkResult.type === EResultType.Annuleren) {
        actions.setSubmitting(false);
        return;
      }

      await api.v2.dienst.service.afwezigheid.wijzigenDienstAfwezigheid(params);

      actions.setSubmitting(false);
      props.onSuccess({});
    },
    [props.id, afwezigheid],
  );

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (afwezigheid.state == ERemoteDataState.Pending || afwezigheid.data == null) {
      return {
        ID: props.id,
        ServDienstID: -1,
        afwezigVan: dagDatum(new Date()),
        afwezigTem: dagDatum(new Date()),
      };
    }

    const van = moment(afwezigheid.data.AfwezigVan).toDate();
    const tot = moment(afwezigheid.data.AfwezigTem).toDate();

    return {
      ID: props.id,
      ServDienstID: afwezigheid.data.ServDienstID,
      afwezigVan: dagDatum(van),
      afwezigTem: dagDatum(tot),
    };
  }, [afwezigheid]);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        ID: Yup.string().required(),
      }),
    [],
  );

  return (
    <Dialoog index={dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Wijzigen Afwezigheidsperiode</ModalTitle>
      </ModalHeader>
      {initialValues === null ? (
        <LoadingSpinner />
      ) : (
        <Formik<IFormikValues>
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize
          isInitialValid
          render={(formikProps: FormikProps<IFormikValues>) => {
            const { submitForm, isValid, isSubmitting } = formikProps;

            return (
              <>
                <ModalBody>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-12 mt-3">
                        <label>Afwezig Van</label>
                        <Field
                          name={nameOf<IFormikValues>('afwezigVan')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <DatumKiezer
                                waarde={field.value}
                                onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                determineNextValidDate="ONBEGRENST"
                                determinePreviousValidDate="ONBEGRENST"
                              />
                            );
                          }}
                        />
                      </div>
                      <div className="col-12 mt-3">
                        <label>Afwezig Tem</label>
                        <Field
                          name={nameOf<IFormikValues>('afwezigTem')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <DatumKiezer
                                waarde={field.value}
                                onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                determineNextValidDate="ONBEGRENST"
                                determinePreviousValidDate="ONBEGRENST"
                              />
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </ModalBody>

                <ModalFooter className="d-flex flex-row justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    style={{ width: 100 }}
                    disabled={!isValid || isSubmitting}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={onAnnuleren}
                    style={{ width: 100 }}
                    disabled={isSubmitting}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Dialoog>
  );
});

export default WijzigenDialoog;
