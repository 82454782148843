import { IOphalenAfdelingenResultElement } from '../../../shared/src/api/v2/relatie/afdeling';
import IRemoteData, { createPendingRemoteData, createReadyRemoteData } from '../models/IRemoteData';
import { makeObservable, observable } from 'mobx';
import * as _ from 'lodash';
import api from '../api';

class AfdelingStore {
  public afdelingen: { [orgAfdID: number]: IRemoteData<IOphalenAfdelingenResultElement> } = {};
  private enqueueBepalenAfdelingenOrgAfdIDsQueue: number[] = [];
  private enqueueBepalenAfdelingenTimeout: NodeJS.Timeout | null = null;

  constructor() {
    makeObservable(this, {
      afdelingen: observable,
    });
  }

  public enqueueBepalenAfdelingen = (orgAfdIDs: number[]) => {
    if (this.enqueueBepalenAfdelingenTimeout !== null) {
      clearTimeout(this.enqueueBepalenAfdelingenTimeout);
    }
    const orgAfdIDsOngequeued: number[] = _.difference(
      orgAfdIDs,
      this.enqueueBepalenAfdelingenOrgAfdIDsQueue,
    );
    const orgAfdIDsNogOphalen: number[] = orgAfdIDsOngequeued.filter(
      (orgAfdID) => this.afdelingen[orgAfdID] === undefined,
    );
    this.enqueueBepalenAfdelingenOrgAfdIDsQueue = [
      ...this.enqueueBepalenAfdelingenOrgAfdIDsQueue,
      ...orgAfdIDsNogOphalen,
    ];
    if (this.enqueueBepalenAfdelingenOrgAfdIDsQueue.length === 0) {
      return;
    }
    this.enqueueBepalenAfdelingenTimeout = setTimeout(async () => {
      const orgAfdIDs = this.enqueueBepalenAfdelingenOrgAfdIDsQueue;
      this.enqueueBepalenAfdelingenOrgAfdIDsQueue = [];

      this.afdelingen = orgAfdIDs.reduce(
        (acc, orgAfdID) => ({
          ...acc,
          [orgAfdID]: createPendingRemoteData(),
        }),
        this.afdelingen,
      );

      const result = await api.v2.relatie.afdeling.ophalenAfdelingen({
        filterSchema: {
          filters: [
            {
              naam: 'IDS',
              data: orgAfdIDs,
            },
          ],
        },
      });

      this.afdelingen = result.afdelingen.reduce(
        (acc, afdeling) => ({
          ...acc,
          [afdeling.ID]: createReadyRemoteData(afdeling),
        }),
        this.afdelingen,
      );
    }, 5);
  };
}

export default AfdelingStore;
