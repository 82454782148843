import * as React from 'react';
import { HTMLProps, useContext, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import Avatar from '../../../../personalia/Avatar';
import { Kleur } from '../../../../../bedrijfslogica/constanten';
import {
  getIconFile,
  IconArchive,
  IconContact,
  IconInkomend,
  IconLopend,
  IconOppakken,
  IconTimer,
  IconUitgaand,
} from '../../../../Icons';
import { IWhatsappChatsessie, IWhatsappMedia } from '../../../../../api/services/v1/whatsapp';
import { useWhatsappV2Store } from '../../store';
import {
  CustomData,
  customNaarCustomData,
  datumNaarStringKort,
  formatDatumTijd,
  naamNaarAvatarKleur,
  naamNaarInitialen,
  sanitizeBerichtInhoud,
} from '../../helpers';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../../models/IRemoteData';
import { IOphalenBestandenResultElement } from '../../../../../../../shared/src/api/v2/bestand/bestand';
import { IOphalenMediaTypesV2ResultElement } from '../../../../../../../shared/src/api/v2/mediatype';
import Skeleton from 'react-loading-skeleton';
import api from '../../../../../api';
import PersoonKoppelComponent from '../../../../personalia/PersoonKoppelComponent';
import ASPGebruikerVisualisatie, {
  EModus,
} from '../../../../ASPGebruiker/ASPGebruikerVisualisatie';
import { RootStoreContext } from '../../../../../stores/RootStore';
import { EResultType } from '../../../../../stores/CheckStore';
import Overlay from 'react-bootstrap/Overlay';
import ToekennenAanLijst from './ToekennenAanLijst';
import TelefoonComponent from '../../../TelefoonComponent';
import { EWhatsappTabblad } from '../../types';

const Root = styled.div`
  width: 100%;
  padding: 2px 8px;
  display: flex;
  align-items: stretch;
`;

const Container = styled.div<{ actief: boolean }>`
  flex: 1;
  padding: 10px 14px;
  background-color: ${(props) => (props.actief ? 'rgba(100, 100, 255, 0.1)' : 'transparent')};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  min-width: 0;

  &:hover {
    background-color: rgba(100, 100, 100, 0.1);
  }
`;

const BovenContainer = styled.div`
  display: flex;
  align-items: center;
  min-width: 0;
  flex: 1;
`;

const OnderContainer = styled.div`
  display: flex;
  align-items: center;
  min-width: 0;
  margin-top: 5px;
`;

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 12px;
  min-width: 0;
`;

const TopContentContainer = styled.div`
  width: 100%;
  display: flex;
`;
const BottomContentContainer = styled.div`
  margin-top: 1px;
  width: 100%;
  display: flex;
`;

// const NameOngekoppeld = styled.a`
//   font-size: 14px;
//   font-weight: bold;
//   white-space: nowrap;
//   overflow: hidden;
//   text-overflow: ellipsis;
//   color: ${Kleur.Oranje};
// `;
//
// const NamePersoon = styled.span`
//   font-size: 14px;
//   font-weight: bold;
//   white-space: nowrap;
//   overflow: hidden;
//   text-overflow: ellipsis;
//   color: ${Kleur.DonkerGroen};
// `;

const Time = styled.span`
  font-size: 12px;
  font-weight: bold;
  color: ${Kleur.Grijs};
  min-width: 65px;
  text-align: right;

  &.unread {
    color: ${Kleur.LichtBlauw};
  }
`;

const MessageTextContainer = styled.div`
  flex: 1;
  min-width: 0;
  display: flex;
  align-items: center;
`;

const MessageTextTijdvensterVerstrekenIndicatieContainer = styled.div`
  margin-left: 5px;
`;

const MessageTextRichtingContainer = styled.div`
  margin-left: 5px;
`;

const MessageText = styled.span`
  flex: 1;
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${Kleur.Grijs};
`;

const GreyItalicMessageText = styled.span`
  flex: 1;
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${Kleur.Grijs};
  font-style: italic;
`;

const BijlageMessageTextContainer = styled.div`
  display: flex;
  align-items: center;
  min-width: 0;
  flex: 1;

  .titel {
    font-size: 13px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 5px;
    color: ${Kleur.Grijs};
  }

  .onbekend {
    font-style: italic;
  }
`;

const NotificatieAantal = styled.span`
  font-size: 12px;
  font-weight: bold;
  color: ${Kleur.Grijs};
  min-width: 20px;
  text-align: right;
  color: ${Kleur.LichtBlauw};
`;

const StatusActieKnop = styled.button`
  width: 40px;
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 5px;
  border-radius: 4px;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: rgba(100, 100, 100, 0.1);
  }

  &:focus {
    outline: none;
  }
`;

const NaamComponent = (props: HTMLProps<HTMLSpanElement>) => {
  return (
    <span
      {...props}
      style={{
        ...props.style,
        fontSize: 13,
        fontWeight: 'bold',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        minWidth: 0,
      }}
    />
  );
};

const OngekoppeldContainerComponent = (props: HTMLProps<HTMLDivElement>) => {
  return <div {...props} style={{ ...props.style, fontSize: 13, fontWeight: 'bold' }} />;
};

const ASPGebruikerVisualisatieNaamComponent = (props: HTMLProps<HTMLSpanElement>) => (
  <span {...props} style={{ ...props.style, fontSize: 12 }} />
);

const TelefoonComponentContent = (props: HTMLProps<HTMLSpanElement>) => (
  <span
    {...props}
    style={{ ...props.style, fontSize: 12, height: 21, display: 'flex', alignItems: 'center' }}
  />
);

interface IProps {
  chatsessie: IWhatsappChatsessie;
  actief: boolean;
  style?: React.CSSProperties;
  onClick?: () => void;
  tabblad?: EWhatsappTabblad;
  onTabbladChange?: (tabblad: EWhatsappTabblad) => void;
  onChatsessieGekozen: () => void;
  isZoekenWeergave?: boolean;
}

const ChatsessieItem = (props: IProps) => {
  const { checkStore, gebruikerStore } = useContext(RootStoreContext);
  const store = useWhatsappV2Store();
  const laatsteBerichtID = store.chatsessieBerichtIDBijChatsessieId[props.chatsessie.id]!;
  const laatsteBericht = store.berichtBijID[laatsteBerichtID];

  const laatsteBerichtDatum = new Date(laatsteBericht.datum);
  const laatsteBerichtDatumTekst = useMemo(() => {
    return datumNaarStringKort(new Date(laatsteBericht.datum));
  }, [laatsteBericht.datum]);

  const laatsteBerichtMedia = useMemo<IRemoteData<IWhatsappMedia | null>>(() => {
    if (laatsteBericht.whatsappMediaId === null) {
      return createReadyRemoteData(null);
    }
    const media = store.mediaBijID[laatsteBericht.whatsappMediaId];
    if (media === undefined) {
      return createPendingRemoteData();
    }
    return createReadyRemoteData(media);
  }, [laatsteBericht.whatsappMediaId, store.mediaBijID]);

  const laatsteBerichtBestand = useMemo<IRemoteData<IOphalenBestandenResultElement | null>>(() => {
    if (laatsteBerichtMedia.state === ERemoteDataState.Pending) {
      return createPendingRemoteData();
    }
    if (laatsteBerichtMedia.data === null || laatsteBerichtMedia.data!.bestandId === null) {
      return createReadyRemoteData(null);
    }
    const bestand = store.bestandBijID[laatsteBerichtMedia.data!.bestandId];
    if (bestand === undefined) {
      return createPendingRemoteData();
    }
    return createReadyRemoteData(bestand);
  }, [laatsteBerichtMedia, store.bestandBijID]);

  const laatsteBerichtMediaType = useMemo<
    IRemoteData<IOphalenMediaTypesV2ResultElement | null>
  >(() => {
    if (laatsteBerichtBestand.state === ERemoteDataState.Pending) {
      return createPendingRemoteData();
    }
    if (laatsteBerichtBestand.data === null || laatsteBerichtBestand.data!.MediaTypeID === null) {
      return createReadyRemoteData(null);
    }
    const mediaType = store.mediaTypeBijID[laatsteBerichtBestand.data!.MediaTypeID];
    if (mediaType === undefined) {
      return createPendingRemoteData();
    }
    return createReadyRemoteData(mediaType);
  }, [laatsteBerichtBestand, store.mediaTypeBijID]);

  const avatar = useMemo(() => {
    return props.chatsessie.naam === null
      ? null
      : {
          initialen: naamNaarInitialen(props.chatsessie.naam),
          kleur: naamNaarAvatarKleur(props.chatsessie.naam),
        };
  }, [props.chatsessie.naam]);

  const customData = useMemo<CustomData | null>(() => {
    if (laatsteBericht.custom === null) {
      return null;
    }

    return customNaarCustomData(laatsteBericht.custom);
  }, [laatsteBericht.custom]);

  const tijdvensterIsVerlopen = useMemo<IRemoteData<boolean | null>>(() => {
    const tijdvenster = store.tijdvensterBijChatsessieId[props.chatsessie.id];
    if (tijdvenster === undefined) {
      return createPendingRemoteData();
    }
    const nu = new Date();
    const isVerlopen = tijdvenster.totEnMet <= nu;
    return createReadyRemoteData(isVerlopen);
  }, [store.tijdvensterBijChatsessieId, props.chatsessie.id]);

  const laatsteBerichtWeergave = useMemo(() => {
    const tijdvensterVerstrekenIndicatie =
      tijdvensterIsVerlopen.state === ERemoteDataState.Pending ||
      tijdvensterIsVerlopen.data === false ? null : (
        <MessageTextTijdvensterVerstrekenIndicatieContainer title="Tijdvenster verstreken">
          <IconTimer style={{ fill: Kleur.Oranje, width: 15, height: 15 }} />
        </MessageTextTijdvensterVerstrekenIndicatieContainer>
      );

    const richting = (
      <MessageTextRichtingContainer
        title={laatsteBericht.richting === 'I' ? 'Inkomend bericht' : 'Uitgaand bericht'}
      >
        {laatsteBericht.richting === 'U' ? (
          <IconUitgaand
            style={{
              width: 15,
              height: 15,
              fill: Kleur.Blauw,
              transform: 'rotate(45deg)',
            }}
          />
        ) : (
          <IconInkomend
            style={{
              width: 15,
              height: 15,
              fill: Kleur.DonkerGroen,

              transform: 'rotate(45deg)',
            }}
          />
        )}
      </MessageTextRichtingContainer>
    );

    if (customData !== null) {
      if (customData.type === 'contact') {
        const formattedNames = customData.data.map((x) => x.name.formatted_name);
        return (
          <MessageTextContainer>
            <IconContact style={{ fill: Kleur.Grijs, width: 13, height: 13, marginRight: 5 }} />
            <MessageText>{formattedNames.join(', ')}</MessageText>
            {tijdvensterVerstrekenIndicatie}
            {richting}
          </MessageTextContainer>
        );
      }

      return (
        <MessageTextContainer>
          <GreyItalicMessageText>Onbekend custom bericht</GreyItalicMessageText>
          {tijdvensterVerstrekenIndicatie}
          {richting}
        </MessageTextContainer>
      );
    }

    if (laatsteBerichtMediaType.state === ERemoteDataState.Pending) {
      return (
        <Skeleton className="flex-fill w-100 h-100" containerClassName="flex-fill w-100 h-100" />
      );
    }

    if (laatsteBerichtMedia.data === null) {
      const bericht = sanitizeBerichtInhoud(laatsteBericht.inhoud);

      if (bericht === null) {
        return (
          <MessageTextContainer>
            <GreyItalicMessageText>Geen tekst opgegeven</GreyItalicMessageText>
            {tijdvensterVerstrekenIndicatie}
            {richting}
          </MessageTextContainer>
        );
      }

      return (
        <MessageTextContainer>
          <MessageText title={laatsteBericht.inhoud}>{bericht}</MessageText>
          {tijdvensterVerstrekenIndicatie}
          {richting}
        </MessageTextContainer>
      );
    }

    const Icon = getIconFile(laatsteBerichtMediaType.data?.MediaType ?? '');

    const titel = laatsteBerichtMedia.data?.titel ?? laatsteBerichtBestand.data?.Naam ?? null;

    return (
      <MessageTextContainer>
        <BijlageMessageTextContainer title={titel ?? undefined}>
          <Icon style={{ fill: Kleur.Grijs, width: 16, height: 16 }} />
          <span className={`titel ${titel === null ? 'onbekend' : ''}`}>{titel ?? 'Bijlage'}</span>
        </BijlageMessageTextContainer>
        {richting}
      </MessageTextContainer>
    );
  }, [
    laatsteBerichtMedia,
    laatsteBerichtBestand,
    laatsteBerichtMediaType,
    laatsteBericht,
    tijdvensterIsVerlopen,
  ]);

  const statusActieKnop = useMemo(() => {
    if (props.isZoekenWeergave) {
      return null;
    }

    switch (props.chatsessie.status) {
      case 'nieuw':
        return (
          <StatusActieKnop
            title="Verplaatsen naar Lopend"
            onClick={async (ev) => {
              ev.stopPropagation();

              await api.services.v1.whatsapp.wijzigenChatsessieStatus({
                chatsessieId: props.chatsessie.id,
                status: 'lopend',
                metOpgepaktAspGebrId: gebruikerStore.gebruiker!.AspGebrID,
              });

              props.onChatsessieGekozen();
              if (props.tabblad !== EWhatsappTabblad.Lopend) {
                props.onTabbladChange?.(EWhatsappTabblad.Lopend);
              }
            }}
          >
            <IconOppakken style={{ fill: Kleur.Grijs, width: 21, height: 21 }} />
          </StatusActieKnop>
        );
      case 'lopend':
        return (
          <div style={{ width: 40, height: '100%' }} className="d-flex flex-column">
            <StatusActieKnop
              className="flex-fill"
              title="Verplaatsen naar Archief"
              onClick={async (ev) => {
                ev.stopPropagation();

                if (
                  props.chatsessie.opgepaktAspGebrId !== null &&
                  gebruikerStore.gebruiker!.AspGebrID !== props.chatsessie.opgepaktAspGebrId
                ) {
                  const checkResult = await checkStore.bevestigen({
                    inhoud:
                      'Bevestigen verplaatsen naar archief van chatsessie die door een andere gebruiker is opgepakt',
                  });
                  if (checkResult.type === EResultType.Annuleren) {
                    return;
                  }
                }

                await api.services.v1.whatsapp.wijzigenChatsessieStatus({
                  chatsessieId: props.chatsessie.id,
                  status: 'afgehandeld',
                  metOpgepaktAspGebrId: null,
                });

                if (props.tabblad !== EWhatsappTabblad.Nieuw) {
                  props.onTabbladChange?.(EWhatsappTabblad.Nieuw);
                }
              }}
            >
              <IconArchive style={{ fill: Kleur.Grijs, width: 21, height: 21 }} />
            </StatusActieKnop>

            <StatusActieKnop
              style={{ marginTop: 3, minHeight: 35 }}
              title="Terugplaatsen naar Nieuw"
              onClick={async (ev) => {
                ev.stopPropagation();

                if (
                  props.chatsessie.opgepaktAspGebrId !== null &&
                  gebruikerStore.gebruiker!.AspGebrID !== props.chatsessie.opgepaktAspGebrId
                ) {
                  const checkResult = await checkStore.bevestigen({
                    inhoud:
                      'Bevestigen verplaatsen naar nieuw van chatsessie die door een andere gebruiker is opgepakt',
                  });
                  if (checkResult.type === EResultType.Annuleren) {
                    return;
                  }
                }

                await api.services.v1.whatsapp.wijzigenChatsessieStatus({
                  chatsessieId: props.chatsessie.id,
                  status: 'nieuw',
                  metOpgepaktAspGebrId: gebruikerStore.gebruiker!.AspGebrID,
                });
              }}
            >
              <IconOppakken
                style={{
                  fill: Kleur.LichtGrijs,
                  width: 21,
                  height: 21,
                  transform: 'rotate(180deg)',
                }}
              />
            </StatusActieKnop>
          </div>
        );
      case 'afgehandeld':
        return (
          <StatusActieKnop
            title="Terugplaatsen naar Lopend"
            onClick={async (ev) => {
              ev.stopPropagation();

              await api.services.v1.whatsapp.wijzigenChatsessieStatus({
                chatsessieId: props.chatsessie.id,
                status: 'lopend',
                metOpgepaktAspGebrId: gebruikerStore.gebruiker!.AspGebrID,
              });

              props.onChatsessieGekozen();
              if (props.tabblad !== EWhatsappTabblad.Lopend) {
                props.onTabbladChange?.(EWhatsappTabblad.Lopend);
              }
            }}
          >
            <IconLopend style={{ fill: Kleur.Grijs, width: 21, height: 21 }} />
          </StatusActieKnop>
        );
    }
  }, [
    props.chatsessie.status,
    props.chatsessie.id,
    props.tabblad,
    props.onTabbladChange,
    props.onChatsessieGekozen,
    gebruikerStore.gebruiker?.AspGebrID,
    props.isZoekenWeergave,
  ]);

  const toekennenAanLinkRef = useRef<HTMLAnchorElement | null>(null);
  const [toekennenAanOpen, setToekennenAanOpen] = useState(false);
  useEffect(() => {
    const handler = (ev: MouseEvent) => {
      setToekennenAanOpen(false);
    };
    document.body.addEventListener('click', handler);
    return () => {
      document.body.removeEventListener('click', handler);
    };
  }, []);

  return (
    <>
      <Root style={props.style} onClick={props.onClick}>
        <Container actief={props.actief}>
          <BovenContainer>
            <Avatar size="42px" fontSize="15px" backgroundColor={avatar?.kleur}>
              {avatar?.initialen}
            </Avatar>
            <ContentContainer>
              <TopContentContainer>
                <PersoonKoppelComponent
                  persID={props.chatsessie.persId}
                  onPersIDChange={async (persID) => {
                    await api.services.v1.whatsapp.wijzigenChatsessiePersoon({
                      chatsessieId: props.chatsessie.id,
                      persoonId: persID,
                    });
                  }}
                  weergaveNaamPersoonOnbekend={
                    props.chatsessie.naam ?? props.chatsessie.telefoonnummer
                  }
                  naamComponent={NaamComponent}
                  ongekoppeldContainerComponent={OngekoppeldContainerComponent}
                  persoonSelectieDialoogDefaultFormValues={{
                    telefoon: props.chatsessie.telefoonnummer,
                  }}
                  magMuteren={!props.isZoekenWeergave}
                />
                <Time title={formatDatumTijd(laatsteBerichtDatum)}>{laatsteBerichtDatumTekst}</Time>
                {/*<Time className="unread">Gisteren</Time>*/}
              </TopContentContainer>
              <div>
                <TelefoonComponent
                  telefoonNummer={props.chatsessie.telefoonnummer}
                  options={{ icoonWeergeven: false }}
                  persID={props.chatsessie.persId ?? undefined}
                  contentComponent={TelefoonComponentContent}
                />
              </div>
              <BottomContentContainer>
                {laatsteBerichtWeergave}
                {/*<NotificatieAantal>2</NotificatieAantal>*/}
              </BottomContentContainer>
            </ContentContainer>
          </BovenContainer>
          {!props.isZoekenWeergave && props.chatsessie.status === 'lopend' && (
            <OnderContainer>
              {props.chatsessie.opgepaktAspGebrId === null ? (
                <span className="text-muted" style={{ fontSize: 12, marginRight: 5 }}>
                  Niet opgepakt
                </span>
              ) : (
                <>
                  <span className="text-muted" style={{ fontSize: 12, marginRight: 5 }}>
                    Opgepakt door
                  </span>
                  <ASPGebruikerVisualisatie
                    aspGebrID={props.chatsessie.opgepaktAspGebrId}
                    modus={EModus.Volledig}
                    avatarGrootte={18}
                    naamComponent={ASPGebruikerVisualisatieNaamComponent}
                  />
                </>
              )}
              <div className="flex-fill d-flex align-items-center justify-content-end">
                <a
                  href="#"
                  style={{ fontSize: 12 }}
                  onClick={async (ev) => {
                    ev.preventDefault();
                    ev.stopPropagation();

                    if (props.chatsessie.opgepaktAspGebrId !== null) {
                      const bericht =
                        props.chatsessie.opgepaktAspGebrId === gebruikerStore.gebruiker!.AspGebrID
                          ? 'Bevestig vrijgeven chatsessie'
                          : 'Bevestig overnemen chatsessie';
                      const result = await checkStore.bevestigen({
                        inhoud: bericht,
                      });
                      if (result.type === EResultType.Annuleren) {
                        return;
                      }
                    }

                    await api.services.v1.whatsapp.wijzigenChatsessieOpgepakt({
                      chatsessieId: props.chatsessie.id,
                      opgepaktAspGebrId:
                        props.chatsessie.opgepaktAspGebrId === gebruikerStore.gebruiker!.AspGebrID
                          ? null
                          : gebruikerStore.gebruiker!.AspGebrID,
                    });
                  }}
                >
                  {props.chatsessie.opgepaktAspGebrId === null
                    ? 'Oppakken'
                    : props.chatsessie.opgepaktAspGebrId === gebruikerStore.gebruiker!.AspGebrID
                    ? 'Vrijgeven'
                    : 'Overnemen'}
                </a>
                <span className="ml-2 mr-2">-</span>
                <a
                  ref={toekennenAanLinkRef}
                  href="#"
                  style={{ fontSize: 12 }}
                  onClick={(ev) => {
                    ev.preventDefault();
                    ev.stopPropagation();

                    setToekennenAanOpen(true);
                  }}
                >
                  Toekennen aan
                </a>
              </div>
            </OnderContainer>
          )}
        </Container>
        {statusActieKnop}
      </Root>
      <Overlay target={toekennenAanLinkRef.current} placement="auto" show={toekennenAanOpen}>
        {(overlayProps) => {
          return (
            <div
              {...overlayProps}
              style={{
                ...overlayProps.style,
                zIndex: 9999,
              }}
            >
              <ToekennenAanLijst
                uitsluitenAspGebrIDs={
                  [gebruikerStore.gebruiker!.AspGebrID, props.chatsessie.opgepaktAspGebrId].filter(
                    (x) => x !== null,
                  ) as number[]
                }
                onGebruikerGekozen={async (aspGebrID) => {
                  await api.services.v1.whatsapp.wijzigenChatsessieOpgepakt({
                    chatsessieId: props.chatsessie.id,
                    opgepaktAspGebrId: aspGebrID,
                  });
                }}
              />
            </div>
          );
        }}
      </Overlay>
    </>
  );
};

export default ChatsessieItem;
