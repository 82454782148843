import React, { useContext, useMemo } from 'react';
import { IProps } from '@tinymce/tinymce-react/lib/cjs/main/ts/components/Editor';
import { Field, FieldProps } from 'formik';
import VinkVeld from '../../../../../../../../../components/formulier/VinkVeld';
import { IFormikValues, veldnamen } from '../index';
import nameOf from '../../../../../../../../../core/nameOf';
import Combobox from '../../../../../../../../../components/formulier/Combobox';
import LoadingSpinner from '../../../../../../../../../components/Gedeeld/LoadingSpinner';
import ContractContext, { IContext } from '../Context/ContractContext';
import MultiSelect from '../../../../../../../../../components/formulier/MultiSelect';
import ContactSelectie, {
  EType,
} from '../../../../../../../../../components/formulier/ContactSelectie';
import MultiCombobox, {
  IKolom,
} from '../../../../../../../../../components/formulier/MultiCombobox';
import { IOphalenRekeningenResultElement } from '../../../../../../../../../../../shared/src/api/v2/relatie';
import ProductmodelSelectieCombobox from '../../../../../../../../../components/formulier/ProductmodelSelectieCombobox';
import UitlegTooltip from '../../../../../../../../../components/formulier/UitlegTooltip';

const OverigTab: React.FC<IProps> = (props) => {
  const {
    locatieNiveaus,
    factuurkenmerken,
    nieuweFactuurkenmerkVaststellen,
    vastgesteldeFactuurkenmerken,
    rekeningen,
    contract,
    productmodellen,
  } = useContext<IContext>(ContractContext);

  const rekeningKolommen = useMemo<IKolom<IOphalenRekeningenResultElement>[]>(() => {
    return [
      {
        key: 'IBAN',
        label: 'IBAN',
        breedte: 200,
      },
      {
        key: 'Rekeningnaam',
        label: 'Rekeningnaam',
        breedte: 175,
      },
      {
        key: 'NietGebruiken',
        label: 'Niet gebruiken',
        breedte: 150,
        formatFabriek: (x) => (x.NietGebruiken ? 'Ja' : 'Nee'),
      },
    ];
  }, []);

  return (
    <div className="p-2 pl-4 pr-4">
      <div className="row">
        <div className="col-12 mt-3">
          <label>{veldnamen.verdieping}</label>
          <Field
            name={nameOf<IFormikValues>('verdieping')}
            render={(fieldProps: FieldProps<IFormikValues>) => {
              const { field, form } = fieldProps;

              if (locatieNiveaus === null) {
                return <LoadingSpinner />;
              }

              return (
                <>
                  <Combobox
                    legeOptieTonen
                    geselecteerd={field.value}
                    onSelectieChange={(x) => {
                      // setVerdieping(x);
                      return form.setFieldValue(field.name, x);
                    }}
                    opties={locatieNiveaus!.map((x) => {
                      return {
                        id: x.Verdieping,
                        label: x.Naam,
                      };
                    })}
                  />
                </>
              );
            }}
          />
        </div>

        <div className="col-12 mt-3">
          <Field
            name={nameOf<IFormikValues>('aansluitenTrekschakelaar')}
            render={(fieldProps: FieldProps<IFormikValues>) => {
              const { field, form } = fieldProps;

              return (
                <>
                  <div className="d-flex align-items-center ml-3">
                    <VinkVeld
                      aangevinkt={field.value}
                      onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                    />
                    <span className="ml-2">{veldnamen.aansluitenTrekschakelaar}</span>
                  </div>
                  {/* <FormikVeldFout fieldProps={fieldProps} /> */}
                </>
              );
            }}
          />
        </div>

        <div className="col-12 mt-3">
          <label>{veldnamen.factuurkenmerkKey}</label>
          <Field
            name={nameOf<IFormikValues>('factuurkenmerkKey')}
            render={(fieldProps: FieldProps<IFormikValues>) => {
              const { field, form } = fieldProps;

              if (factuurkenmerken === null) {
                return <LoadingSpinner />;
              }

              const opties = [
                ...factuurkenmerken.map((x) => ({
                  key: x.ID,
                  weergave: x.Naam,
                })),
                ...vastgesteldeFactuurkenmerken.map((x) => ({
                  key: x,
                  weergave: x,
                })),
              ];

              return (
                <>
                  <MultiSelect
                    value={field.value === null ? [] : [field.value]}
                    onChange={(x) => {
                      const key = x.length === 0 ? null : x[0];
                      form.setFieldValue(field.name, key);
                    }}
                    opties={opties}
                    onToegevoegd={(tekst) => {
                      nieuweFactuurkenmerkVaststellen(tekst);
                      form.setFieldValue(field.name, tekst);
                    }}
                    isVerwijderbaar
                  />
                </>
              );
            }}
          />
        </div>

        <div className="col-12 mt-3">
          <label>{veldnamen.relRekID}</label>
          <Field
            name={nameOf<IFormikValues>('relRekID')}
            render={(fieldProps: FieldProps<IFormikValues>) => {
              const { field, form } = fieldProps;

              if (rekeningen === null) {
                return <LoadingSpinner />;
              }

              return (
                <>
                  <MultiCombobox<number, IOphalenRekeningenResultElement>
                    sleutelExtractor={(row: IOphalenRekeningenResultElement) => row.RelRekID}
                    onWaardeChange={(waarde: number | null) => {
                      fieldProps.form.setFieldValue(field.name, waarde);
                    }}
                    representatieFabriek={(row: IOphalenRekeningenResultElement) =>
                      `${row.IBAN} - ${row.Rekeningnaam}`
                    }
                    waarde={fieldProps.field.value}
                    isWisbaar
                    opties={rekeningen}
                    kolommen={rekeningKolommen}
                  />

                  {/* <Combobox
                    legeOptieTonen
                    geselecteerd={field.value}
                    onSelectieChange={(x) => {
                      return form.setFieldValue(field.name, x);
                    }}
                    opties={rekeningen.map((x) => {
                      return {
                        id: x.RelRekID,
                        label: x.IBAN,
                      };
                    })}
                  /> */}
                </>
              );
            }}
          />
        </div>

        <div className="col-12 mt-2">
          <label className="d-flex align-items-center">
            {veldnamen.prodModID}
            <span className="ml-2">
              <UitlegTooltip
                inhoud={
                  <div>
                    Alle abonnementsgegevens en tarieven blijven hiermee ongewijzigd. Alleen het
                    productmodel wordt gewijzigd.
                  </div>
                }
              />
            </span>
          </label>

          <Field
            name={nameOf<IFormikValues>('prodModID')}
            render={(fieldProps: FieldProps<IFormikValues>) => {
              const { field, form } = fieldProps;

              if (productmodellen === null) {
                return <span></span>;
              }

              return (
                <>
                  <ProductmodelSelectieCombobox
                    prodModID={fieldProps.field.value}
                    productmodellen={productmodellen}
                    onChange={(x) => {
                      form.setFieldValue(field.name, x);
                    }}
                  />
                </>
              );
            }}
          />
        </div>

        <div className="col-12 mt-3">
          <label>{veldnamen.aanvragerPersID}</label>
          <Field
            name="aanvragerPersID"
            render={(fieldProps: FieldProps<IFormikValues>) => {
              const { field, form } = fieldProps;
              return (
                <ContactSelectie
                  value={field.value === null ? null : { persID: field.value, orgID: null }}
                  onChange={(value) =>
                    form.setFieldValue(field.name, value?.persoon?.PersID ?? null)
                  }
                  alleenVoorRelIDs={[contract!.RelID]}
                  options={{
                    bijEnkeleDirectVoorselecteren: true,
                    types: [EType.Persoon],
                    // voorselectieRelatieContactpersoonType:
                    //   EVoorselectieRelatieContactpersoonType.Administratief,
                  }}
                />
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default OverigTab;
