import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import MenuLayout from '../../../../MenuLayout';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import FilterBalkV2, { IFilter, IFilterData, maakFilterSchema } from '../../../../FilterBalkV2';
import { IFilterSchema } from '../../../../../../../shared/src/models/filter';
import useUrlState from '../../../../../core/useUrlState';
import {
  IOphalenCommunicatieItemsResult,
  IOphalenCommunicatieItemsResultElement,
} from '../../../../../../../shared/src/api/v2/communicatie';
import api from '../../../../../api';
import LoadingSpinner from '../../../../Gedeeld/LoadingSpinner';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  TableRowDetail,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import { DXTableToggleCellComponent, GridStyleWrapper } from '../../../../../helpers/dxTableGrid';
import { RootStoreContext } from '../../../../../stores/RootStore';
import { DataTypeProvider, RowDetailState } from '@devexpress/dx-react-grid';
import nameof from '../../../../../core/nameOf';
import { format } from 'date-fns';
import PersoonVisualisatie from '../../../../personalia/PersoonVisualisatie';
import {
  EFunctioneleIcon,
  functioneleIconMap,
  IconBellen,
  IconEmail,
  IconInkomend,
  IconOrganisatie,
  IconPersoon,
  IconSms,
  IconUitgaand,
  IconWhatsapp,
} from '../../../../Icons';
import { Kleur } from '../../../../../bedrijfslogica/constanten';
import OrganisatieVisualisatie from '../../../../personalia/OrganisatieVisualisatie';
import Highlighter from 'react-highlight-words';
import { IOphalenTelefoonnummersResult } from '../../../../../../../shared/src/api/v2/bedrijf';
import { ITabbladComponentProps } from '../index';
import EmailV2 from './EmailV2';
import { setCommunicatieOverlayState } from '../../../../../one-off-components/CommunicatieOverlay';
import { ECommunicatieTabblad } from '../../../../../one-off-components/CommunicatieOverlay/MenuHandle';
import TelefoonOproep from './TelefoonOproep';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
} from '../../../../../models/IRemoteData';
import UitlegTooltip from '../../../../formulier/UitlegTooltip';
import RowDetail from './RowDetail';
import { ASPKolom, EAspKolomBreedteType } from '../../../../tabel/ASPTabel/types';
import ASPTabel from '../../../../tabel/ASPTabel';
import {
  IOphalenTelefoonoproepContextenResult,
  IOphalenTelefoonoproepXContextResult,
  IOproep,
} from '../../../../../../../shared/src/api/v2/telefonie';

export enum EKolom {
  Datum,
  Richting,
  Type,
  Weergave,
  Geadresseerden,
}

const IconCommunicatie = functioneleIconMap[EFunctioneleIcon.Communicatie];

export enum EFilterKey {
  InhoudBevat = 'INHOUD_BEVAT',
}

interface IProps extends RouteComponentProps, ITabbladComponentProps {}

export interface IUrlState {
  filterData: IFilterData<EFilterKey>[];
  state: Record<string, any>;
  uitgeklapt: string[];
}

export const defaultUrlState: IUrlState = {
  filterData: [
    {
      naam: EFilterKey.InhoudBevat,
      isActief: true,
      data: '',
    },
  ],
  state: {},
  uitgeklapt: [],
};

const iconMap: Record<string, React.ComponentType> = {
  TELEFOON_OPROEP: () => <IconBellen style={{ width: 20, height: 20, fill: Kleur.Grijs }} />,
  WHATSAPP_BERICHT: () => <IconWhatsapp style={{ width: 20, height: 20, fill: Kleur.Grijs }} />,
  SMS_BERICHT: () => <IconSms style={{ width: 20, height: 20, fill: Kleur.Grijs }} />,
  EMAIL: () => <IconEmail style={{ width: 20, height: 20, fill: Kleur.Grijs }} />,
  CONTACTVERZOEK: () => <IconCommunicatie style={{ width: 20, height: 20, fill: Kleur.Grijs }} />,
};

export interface IOverzichtContext {
  telefoonnummersResult: IOphalenTelefoonnummersResult | null;
  contextenResult: IRemoteData<IOphalenTelefoonoproepContextenResult>;
  telefoonoproepenXContextenResult: IRemoteData<IOphalenTelefoonoproepXContextResult>;
  verversenTelefoonoproepXContexten: () => Promise<void>;
  verversenContexten: () => Promise<void>;
}
export const OverzichtContext = React.createContext<IOverzichtContext>(null as any);

export interface IWeergaveProps {
  filterData: IFilterData<EFilterKey>[];
  communicatieItem: IOphalenCommunicatieItemsResultElement;
  state: any;
  onStateChange: (state: any) => void;
}

const Overzicht: React.FC<IProps> = (props) => {
  const { klantkaartStore } = useContext(RootStoreContext);
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const [schema, setSchema] = useState<IFilterSchema>(maakFilterSchema(urlState.filterData));

  const [
    telefoonnummersResult,
    setTelefoonnummersResult,
  ] = useState<IOphalenTelefoonnummersResult | null>(null);
  useEffect(() => {
    (async () => {
      const result = await api.v2.bedrijf.ophalenTelefoonnummers({});
      setTelefoonnummersResult(result);
    })();
  }, []);

  const [contextenResult, setContextenResult] = useState<
    IRemoteData<IOphalenTelefoonoproepContextenResult>
  >(createPendingRemoteData());

  const ophalenContexten = useCallback(async () => {
    const result = await api.v2.telefonie.ophalenTelefoonoproepContexten({});
    setContextenResult(createReadyRemoteData(result));
  }, []);

  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    ophalenContexten();
  }, [ophalenContexten]);

  const [telefoonoproepenXContextenResult, setTelefoonoproepenXContextenResult] = useState<
    IRemoteData<IOphalenTelefoonoproepXContextResult>
  >(createPendingRemoteData());

  const weergaveMap = useMemo<Record<string, React.ComponentType<IWeergaveProps>> | null>(() => {
    if (telefoonnummersResult === null) {
      return null;
    }
    return {
      TELEFOON_OPROEP: TelefoonOproep,
      WHATSAPP_BERICHT: (x) => {
        const filterItem = x.filterData.find((fd) => fd.naam === EFilterKey.InhoudBevat)!;
        return (
          <span className="d-flex align-items-start justify-content-between">
            {/*{filterItem.isActief ? (*/}
            {/*  <Highlighter*/}
            {/*    className="text-justify"*/}
            {/*    activeStyle={{ color: Kleur.Geel }}*/}
            {/*    activeIndex={-1}*/}
            {/*    highlightStyle={{ fontWeight: 'normal' }}*/}
            {/*    searchWords={(x.communicatieItem.data.Inhoud || '')*/}
            {/*      .split(/\s/)*/}
            {/*      .filter(*/}
            {/*        (word: string) =>*/}
            {/*          filterItem.data !== '' &&*/}
            {/*          word.toLowerCase().indexOf(filterItem.data.toLowerCase()) !== -1,*/}
            {/*      )}*/}
            {/*    textToHighlight={x.communicatieItem.data.Inhoud || ''}*/}
            {/*  />*/}
            {/*) : (*/}
            {/*  <span*/}
            {/*    style={{*/}
            {/*      textAlign: 'justify',*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    {x.communicatieItem.data.Inhoud}*/}
            {/*  </span>*/}
            {/*)}*/}
            <span
              style={{
                textAlign: 'justify',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                lineClamp: 2,
                maxWidth: 'calc(100% - 100px)',
              }}
              title={x.communicatieItem.data.Inhoud ?? undefined}
            >
              {x.communicatieItem.data.Inhoud}
            </span>

            <a
              href="#"
              onClick={() => {
                setCommunicatieOverlayState!((curr) => ({
                  ...curr,
                  whatsappContext: {
                    ...curr.whatsappContext,
                    chatSessieID: x.communicatieItem.data.WhatsAppSes_ID,
                  },
                  geselecteerdTabblad: ECommunicatieTabblad.Whatsapp,
                }));
              }}
              style={{
                maxWidth: 100,
              }}
            >
              Ga naar bericht
            </a>
          </span>
        );
      },
      SMS_BERICHT: (x) => {
        const filterItem = x.filterData.find((fd) => fd.naam === EFilterKey.InhoudBevat)!;
        return (
          <span
            style={{
              textAlign: 'justify',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              lineClamp: 2,
            }}
            title={x.communicatieItem.data.Inhoud ?? undefined}
          >
            {x.communicatieItem.data.Inhoud}
          </span>
        );
      },
      EMAIL: EmailV2,
      CONTACTVERZOEK: (x) => {
        const filterItem = x.filterData.find((fd) => fd.naam === EFilterKey.InhoudBevat)!;
        return (
          // <span className="text-wrap">
          //   {filterItem.isActief ? (
          //     <Highlighter
          //       activeStyle={{ color: Kleur.Geel }}
          //       activeIndex={-1}
          //       highlightStyle={{ fontWeight: 'normal' }}
          //       searchWords={(x.communicatieItem.data.Vraag || '')
          //         .split(/\s/)
          //         .filter(
          //           (word: string) =>
          //             filterItem.data !== '' &&
          //             word.toLowerCase().indexOf(filterItem.data.toLowerCase()) !== -1,
          //         )}
          //       textToHighlight={x.communicatieItem.data.Vraag || ''}
          //     />
          //   ) : (
          //     <span>{x.communicatieItem.data.Vraag}</span>
          //   )}
          // </span>

          <span
            style={{
              textAlign: 'justify',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              lineClamp: 2,
            }}
            title={x.communicatieItem.data.Vraag ?? undefined}
          >
            {x.communicatieItem.data.Vraag}
          </span>
        );
      },
    };
  }, [telefoonnummersResult]);

  const filters = useMemo<IFilter<EFilterKey>[]>(() => {
    return [
      {
        naam: EFilterKey.InhoudBevat,
        altijdWeergevenInBalk: true,
        weergave: (p) => (
          <span className="d-flex align-items-center">
            Inhoud bevat
            <input
              className="ml-2"
              value={p.state || ''}
              onChange={(ev) => {
                const val = ev.target.value;
                p.onStateChange(val);
              }}
              onKeyUp={(ev) => {
                if (ev.key === 'Enter') {
                  p.onDataChange(p.state);
                  p.setIsActief(true);
                  p.toepassen();
                }
              }}
            />
          </span>
        ),
      },
    ];
  }, []);

  const [
    communicatieItems,
    setCommunicatieItems,
  ] = useState<IOphalenCommunicatieItemsResult | null>(null);
  const ophalenCommunicatieItems = useCallback(async () => {
    const result = await api.v2.communicatie.ophalenCommunicatieItems({
      schema,
      relID: props.relID,
    });
    setCommunicatieItems(result);
  }, [setCommunicatieItems, schema, props.relID]);
  useEffect(() => {
    ophalenCommunicatieItems();
  }, [schema, props.relID]);

  const ophalenTelefoonoproepXContexten = useCallback(async () => {
    if (communicatieItems === null) {
      return;
    }
    const oproepenItems = communicatieItems.items.filter((x) => x.type === 'TELEFOON_OPROEP');

    const result = await api.v2.telefonie.ophalenTelefoonoproepXContext({
      filterSchema: {
        filters: [
          {
            naam: 'TEL_OPR_IDS',
            data: oproepenItems.map((x) => (x.data as IOproep).ID),
          },
        ],
      },
    });
    setTelefoonoproepenXContextenResult(createReadyRemoteData(result));
  }, [communicatieItems]);

  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    ophalenTelefoonoproepXContexten();
  }, [ophalenTelefoonoproepXContexten]);

  const keyExtractor = useCallback((row: IOphalenCommunicatieItemsResultElement) => row.id, []);

  const kolommen = useMemo<ASPKolom<EKolom, IOphalenCommunicatieItemsResultElement>[]>(
    () => [
      {
        key: EKolom.Datum,
        label: 'Datum',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 150,
        renderer: (item) => format(new Date(item.datum), 'dd-MM-yyyy HH:mm'),
      },
      {
        key: EKolom.Richting,
        label: 'Richting',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 60,
        renderer: (item) => {
          if (item.richting === null) {
            return (
              <UitlegTooltip inhoud="De richting is onbekend">
                <div className="d-flex align-items-center justify-content-center">
                  <span style={{ fontWeight: 'bold', fontSize: 18 }}>?</span>
                </div>
              </UitlegTooltip>
            );
          }

          const isUitgaand = item.richting === 'U';
          return (
            <UitlegTooltip inhoud={isUitgaand ? 'Uitgaand' : 'Inkomend'}>
              <div className="d-flex align-items-center justify-content-center">
                <div style={{ marginLeft: 0 }}>
                  {isUitgaand ? (
                    <IconUitgaand
                      style={{
                        width: 18,
                        height: 18,
                        fill: Kleur.Blauw,
                        transform: 'rotate(45deg)',
                      }}
                    />
                  ) : (
                    <IconInkomend
                      style={{
                        width: 18,
                        height: 18,
                        fill: Kleur.DonkerGroen,

                        transform: 'rotate(45deg)',
                      }}
                    />
                  )}
                </div>
              </div>
            </UitlegTooltip>
          );
        },
      },
      {
        key: EKolom.Type,
        label: 'Type',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 50,
        renderer: (item) => {
          const Icon = iconMap[item.type];
          return <Icon />;
        },
      },
      {
        key: EKolom.Weergave,
        label: 'Weergave',
        breedteType: EAspKolomBreedteType.Flex,
        flex: 1,
        renderer: (item) => {
          if (weergaveMap === null) {
            return <span />;
          }
          const Weergave = weergaveMap[item.type];
          const state = urlState.state[item.id] || {};
          return (
            <Weergave
              communicatieItem={item}
              filterData={urlState.filterData}
              state={state}
              onStateChange={(x) =>
                setUrlStateSync('state', {
                  ...state,
                  [item.id]: x,
                })
              }
            />
          );
        },
      },
      {
        key: EKolom.Geadresseerden,
        label: 'Geadresseerden',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 225,
        renderer: (item) => {
          return (
            <span className="d-flex flex-column">
              {[
                ...item.persIDs.map((persID) => (
                  <span className="d-flex align-items-center">
                    <IconPersoon style={{ width: 15, height: 15, fill: Kleur.Grijs }} />
                    <span className="ml-1">
                      <PersoonVisualisatie
                        key={persID}
                        persID={persID}
                        // relID={props.relID}
                      />
                    </span>
                  </span>
                )),
                ...item.orgIDs.map((id) => (
                  <span className="d-flex align-items-center">
                    <IconOrganisatie style={{ width: 15, height: 15, fill: Kleur.Grijs }} />
                    <span className="ml-1">
                      <OrganisatieVisualisatie key={id} orgID={id} />
                    </span>
                  </span>
                )),
              ]}
            </span>
          );
        },
      },
    ],
    [weergaveMap],
  );

  const rijen = useMemo<Record<number, IOphalenCommunicatieItemsResultElement> | null>(() => {
    if (communicatieItems === null) {
      return null;
    }
    return communicatieItems.items.reduce((acc, item, i) => ({ ...acc, [i]: item }), {});
  }, [communicatieItems]);

  const magRijUitklappen = useCallback(
    (row: IOphalenCommunicatieItemsResultElement) =>
      row.type === 'EMAIL' || row.type === 'SMS_BERICHT',
    [],
  );

  const getUitgeklapteRijHoogte = useCallback((row: IOphalenCommunicatieItemsResultElement) => {
    switch (row.type) {
      case 'SMS_BERICHT': {
        return 100;
      }
    }

    return 450;
  }, []);

  const contextValue = useMemo<IOverzichtContext>(
    () => ({
      telefoonnummersResult,
      contextenResult,
      telefoonoproepenXContextenResult,
      verversenContexten: async () => await ophalenContexten(),
      verversenTelefoonoproepXContexten: async () => await ophalenTelefoonoproepXContexten(),
    }),
    [
      telefoonnummersResult,
      contextenResult,
      telefoonoproepenXContextenResult,
      ophalenContexten,
      ophalenTelefoonoproepXContexten,
    ],
  );

  return (
    <OverzichtContext.Provider value={contextValue}>
      <MenuLayout
        menu={
          <div>
            <FilterBalkV2
              filters={filters}
              filterData={urlState.filterData}
              onFilterDataChange={(value) => setUrlStateSync('filterData', value)}
              onFilterSchemaChange={(value) => setSchema(value)}
            />
          </div>
        }
        body={
          communicatieItems === null ? (
            <div className="d-flex flex-fill align-items-center justify-content-center">
              <LoadingSpinner />
            </div>
          ) : (
            <ASPTabel
              rijen={rijen ?? {}}
              kolommen={kolommen}
              keyExtractor={keyExtractor}
              rijHoogte={50}
              uitgeklapt={urlState.uitgeklapt}
              onUitgeklaptChange={(value) => setUrlStateSync('uitgeklapt', value)}
              uitgeklapteRijComponent={RowDetail}
              uitgeklapteRijHoogte={getUitgeklapteRijHoogte}
              magRijUitklappen={magRijUitklappen}
            />
          )
        }
      />
    </OverzichtContext.Provider>
  );
};

export default withRouter(Overzicht);
