import useAudio from './useAudio';
import { useCallback, useContext } from 'react';
import { RootStoreContext } from '../stores/RootStore';

const useIntrasessieAudio = (url: string): [boolean, () => void] => {
  const { intrasessieStore } = useContext(RootStoreContext);
  const [isPlaying, togglePlay] = useAudio(url);

  const customTogglePlay = useCallback(() => {
    if (intrasessieStore.isHoofdSessie || isPlaying) {
      togglePlay();
    }
  }, [togglePlay, isPlaying, intrasessieStore.isHoofdSessie]);

  return [isPlaying, customTogglePlay];
};

export default useIntrasessieAudio;
