import IDialoogProps from '../../../core/IDialoogProps';
import Dialoog from '../../dialogen/Dialoog';
import { ModalBody, ModalTitle } from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/ModalHeader';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import ModalFooter from 'react-bootstrap/ModalFooter';
import LoadingSpinner from '../../Gedeeld/LoadingSpinner';
import api from '../../../api';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../stores/RootStore';
import {
  IOphalenBestandsmappenResultElement,
  IToevoegenBestandsmapParams,
} from '../../../../../shared/src/api/v2/bestand/bestand';
import { EResultType } from '../../../stores/CheckStore';

export interface IDialoogResult {
  map: IOphalenBestandsmappenResultElement;
}

interface IProps extends IDialoogProps<IDialoogResult> {
  binnenBestandsmapID: number | null;
}

const MapToevoegenDialoog = observer((props: IProps) => {
  const { checkStore } = useContext(RootStoreContext);

  const inputRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  const [isAanvraagBezig, setIsAanvraagBezig] = useState(false);
  const [naam, setNaam] = useState('');

  const canSubmit = useMemo(() => {
    return naam.trim().length > 0;
  }, [naam]);

  const handleOkClick = useCallback(async () => {
    setIsAanvraagBezig(true);
    const value = naam.trim();

    const params: IToevoegenBestandsmapParams = {
      bovenliggendeBestandsmapID: props.binnenBestandsmapID,
      naam: value,
    };
    const checkData = await api.v2.bestand.checkToevoegenBestandsmap(params);
    if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
      setIsAanvraagBezig(false);
      return;
    }
    const result = await api.v2.bestand.toevoegenBestandsmap(params);

    await props.onSuccess({
      map: result.map,
    });
    setIsAanvraagBezig(false);
  }, [props.binnenBestandsmapID, naam]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <Dialoog index={props.dialoogIndex ?? 0}>
      <ModalHeader>
        <ModalTitle>Map toevoegen</ModalTitle>
      </ModalHeader>

      <ModalBody>
        <div className="form-group">
          <label htmlFor="naam">Naam</label>
          <input
            ref={inputRef}
            type="text"
            className="form-control"
            id="naam"
            placeholder="Naam"
            value={naam}
            onChange={(e) => setNaam(e.target.value)}
            disabled={isAanvraagBezig}
            onKeyUp={async (e) => {
              if (e.key === 'Enter' && canSubmit) {
                await handleOkClick();
              }
            }}
          />
        </div>
      </ModalBody>

      <ModalFooter className="d-flex flex-row justify-content-start">
        <button
          className="btn btn-primary d-flex align-items-center justify-content-center"
          onClick={handleOkClick}
          style={{ width: 100 }}
          disabled={isAanvraagBezig || !canSubmit}
        >
          {isAanvraagBezig && (
            <span className="mr-2">
              <LoadingSpinner soort="light" grootte="15px" dikte="2px" />
            </span>
          )}
          Ok
        </button>
        <button
          className="btn btn-secondary"
          onClick={props.onAnnuleren}
          style={{ width: 100 }}
          disabled={isAanvraagBezig}
        >
          Annuleren
        </button>
      </ModalFooter>
    </Dialoog>
  );
});

export default MapToevoegenDialoog;
