import { format } from 'date-fns';
import _ from 'lodash';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import api from '../../../../api';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import MenuLayout from '../../../../components/MenuLayout';
import SelectieVak from '../../../../components/SelectieVak';
import useUrlState from '../../../../core/useUrlState';
import {
  DXTableCheckboxComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import {
  DataTypeProvider,
  EditingState,
  IntegratedSorting,
  RowDetailState,
  SelectionState,
  SortingState,
  VirtualTable as VirtualTableBase,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import { EResultType } from '../../../../stores/CheckStore';
import { RootStoreContext } from '../../../../stores/RootStore';
import { IOphalenProductenResultElementV2 } from '../../../../../../shared/src/api/v2/product';
import ProductinfoDialoog from '../../../../components/product/ProductinfoDialoog';
import { IconInformatie } from '../../../../components/Icons';
import { maandenNaarJaren } from '../../../../bedrijfslogica/teksten';
import { IOphalenRegisterItemsResultElement } from '../../../../../../shared/src/api/v2/boekhouding/activaregister/register';
import { Helmet } from 'react-helmet';

interface IProps extends RouteComponentProps {}
export interface IProductinfoDialoogState {
  id: number;
}

interface IUrlState {
  selectie: number[];
  uitgeklapt: number[];
  productinfoDialoogState: IProductinfoDialoogState | null;
}

const defaultUrlState: IUrlState = {
  selectie: [],
  uitgeklapt: [],
  productinfoDialoogState: null,
};

const Deactiveren: React.FC<IProps> = (props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState<IUrlState>(props, defaultUrlState);
  const { checkStore } = useContext(RootStoreContext);

  const [producten, setProducten] = useState<IOphalenProductenResultElementV2[] | null>(null);
  const [registeritems, setRegisteritems] = useState<IOphalenRegisterItemsResultElement[] | null>(
    null,
  );

  const ophalenProductenSelectie = useCallback(async () => {
    const result = await api.v2.boekhouding.activaregister.deactiveren.ophalenProductenUitBedrijf({
      paginatie: { index: 0, aantal: 1000 },
    });
    const prodIDs = result.prodIDs;

    const producten = (
      await api.v2.product.ophalenProductenV2({
        filterSchema: { filters: [{ naam: 'IDS', data: prodIDs }] },
        orderSchema: { orders: [{ naam: 'DATUM_UIT_BEDRIJF', richting: 'DESC' }] },
      })
    ).producten;

    setProducten(producten);
  }, []);

  useEffect(() => {
    ophalenProductenSelectie();
  }, [ophalenProductenSelectie]);

  const ophalenRegisteritems = useCallback(async () => {
    if (producten === null) {
      return;
    }
    const result = await api.v2.boekhouding.activaregister.register.ophalenRegisterItems({
      filterSchema: { filters: [{ naam: 'PROD_IDS', data: producten.map((x) => x.ProdID) }] },
    });

    setRegisteritems(result.registerItems);
  }, [producten]);

  useEffect(() => {
    ophalenRegisteritems();
  }, [ophalenRegisteritems]);

  const handleDeactiveren = useCallback(async () => {
    if (producten === null) {
      return;
    }

    const params = {
      producten: producten
        .filter((x) => urlState.selectie.indexOf(x.ProdID) !== -1)
        .map((x) => {
          return {
            prodID: x.ProdID,
            restwaarde: 0, //x.facturen[0].Bedrag,
            datumGedeactiveerd: new Date(x.DatumUitBedrijf!),
          };
        }),
    };

    const checkData = await api.v2.boekhouding.activaregister.deactiveren.checkDeactiverenProducten(
      params,
    );
    const controlerenResult = await checkStore.controleren({
      checkData,
    });
    if (controlerenResult.type === EResultType.Annuleren) {
      return;
    }

    if (
      (
        await checkStore.bevestigen({
          inhoud: 'Geselecteerde producten deactiveren?',
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }
    await api.v2.boekhouding.activaregister.deactiveren.deactiverenProducten(params);

    setUrlStateSync('selectie', []);
    ophalenProductenSelectie();
  }, [urlState.selectie]);

  const keyExtractor = useCallback((rij: IOphalenProductenResultElementV2) => rij.ProdID, []);
  const kolommen = useMemo<TypedColumn<IOphalenProductenResultElementV2>[]>(
    () => [
      {
        name: '__merknaam' as any,
        title: 'Merk',
        getCellValue: (x) => {
          return x.producttype.Merknaam;
        },
      },
      {
        name: '__typenaam' as any,
        title: 'Type',
        getCellValue: (x) => {
          return x.producttype.Typenaam;
        },
      },
      {
        name: '__referentiecode',
        title: 'Ref.code',
      },
      {
        name: '__productsoortnaamKort' as any,
        title: 'Cat.',
        getCellValue: (x) => {
          return x.producttype.ProductsoortnaamKort;
        },
      },
      {
        name: '__leeftijd' as any,
        title: 'Lft.',
      },
      // {
      //   name: 'DatumInBedrijf' as any,
      //   title: 'In bedrijf',
      //   getCellValue: (x) => {
      //     return format(new Date(x.DatumInBedrijf), 'dd-MM-yyyy');
      //   },
      // },
      {
        name: '__productinfo' as any,
        title: ' ',
      },
      {
        name: 'DatumUitBedrijf' as any,
        title: 'Uit bedrijf',
        // getCellValue: (x) => {
        //   return format(new Date(x.product.DatumUitBedrijf), 'dd-MM-yyyy');
        // },
      },
      {
        name: '__redenUB' as any,
        title: 'Reden',
      },
      {
        name: '__datumGeactiveerd' as any,
        title: 'Geactiveerd op',
      },
    ],
    [],
  );
  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IOphalenProductenResultElementV2>[]>(
    () => [
      {
        columnName: '__merknaam' as any,
        width: 125,
      },
      {
        columnName: '__typenaam' as any,
        width: 175,
      },
      {
        columnName: '__referentiecode' as any,
        width: 100,
      },
      {
        columnName: '__productsoortnaamKort' as any,
        width: 90,
      },
      {
        columnName: '__leeftijd' as any,
        width: 100,
      },
      {
        columnName: '__productinfo' as any,
        width: 60,
      },
      {
        columnName: 'DatumInBedrijf' as any,
        width: 125,
      },
      {
        columnName: 'DatumUitBedrijf' as any,
        width: 125,
      },
      {
        columnName: '__redenUB' as any,
        width: 175,
      },
      {
        columnName: 'Bedrag',
        width: 100,
      },
      {
        columnName: '__factuurnummer' as any,
        width: 125,
      },
      {
        columnName: '__factuurdatum' as any,
        width: 125,
      },
      {
        columnName: '__relatie' as any,
        width: 200,
      },
      {
        columnName: '__downloadActie',
        width: 50,
      },
      {
        columnName: '__datumGeactiveerd' as any,
        width: 150,
      },
    ],
    [],
  );
  const kolomExtensies: VirtualTableBase.ColumnExtension[] = useMemo(() => {
    return [
      {
        columnName: 'Bedrag',
        align: 'right',
      },
    ];
  }, []);

  return (
    <>
      <Helmet>
        <title>Deativeren</title>
      </Helmet>
      <MenuLayout
        menu={
          <div>
            <button
              className="btn btn-sm btn-light d-flex align-items-center"
              style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
              disabled={urlState.selectie.length === 0}
              onClick={() => handleDeactiveren()}
            >
              {/* <IconVerwijderen style={{ width: 16, height: 16, fill: Kleur.Grijs }} /> */}
              <span className="ml-2">Deactiveren producten</span>
            </button>

            {producten !== null && (
              <div className="mt-2 d-flex align-items-center">
                <SelectieVak
                  totaalAantal={producten.length}
                  aantal={urlState.selectie.length}
                  entiteitEnkelvoud="tarief"
                  entiteitMeervoud="tarieven"
                  onChange={(allesGeselecteerd) => {
                    if (allesGeselecteerd) {
                      setUrlStateSync('selectie', producten!.map(keyExtractor));
                    } else {
                      setUrlStateSync('selectie', []);
                    }
                  }}
                />
                {/* <span className="ml-2">Totaal {producten!.length} tarieven</span> */}
              </div>
            )}
          </div>
        }
        body={
          producten === null || registeritems === null ? (
            <div className="flex-fill d-flex align-items-center justify-content-center">
              <LoadingSpinner />
            </div>
          ) : (
            <GridStyleWrapper height={'calc(100vh - 200px)'}>
              <Grid rows={producten} columns={kolommen} getRowId={keyExtractor}>
                <DataTypeProvider
                  for={['__productinfo']}
                  formatterComponent={(props) => {
                    const rij: IOphalenProductenResultElementV2 = props.row;

                    return (
                      <a
                        href="#"
                        // style={{ color: Kleur.DonkerGrijs }}
                        onClick={() => {
                          setUrlStateSync('productinfoDialoogState', { id: rij.ProdID });
                        }}
                      >
                        <IconInformatie style={{ width: 15, height: 15, fill: Kleur.Blauw }} />
                      </a>
                    );
                  }}
                />

                <DataTypeProvider
                  for={['__productsoortnaamKort']}
                  formatterComponent={(props) => {
                    const rij: IOphalenProductenResultElementV2 = props.row;
                    return <span>{rij.producttype.ProductsoortnaamKort}</span>;
                  }}
                />

                <DataTypeProvider
                  for={['__leeftijd']}
                  formatterComponent={(formatterProps) => {
                    const rij: IOphalenProductenResultElementV2 = formatterProps.row;

                    if (rij.leeftijd === null) {
                      return <span></span>;
                    }
                    return <span>{maandenNaarJaren(rij.leeftijd)}</span>;
                  }}
                />

                <DataTypeProvider
                  for={['__referentiecode']}
                  formatterComponent={(props) => {
                    const rij: IOphalenProductenResultElementV2 = props.row;
                    return <span>{rij.Referentiecode}</span>;
                  }}
                />

                <DataTypeProvider
                  for={['__redenUB']}
                  formatterComponent={(props) => {
                    const rij: IOphalenProductenResultElementV2 = props.row;
                    return <span>{rij.redenUB!.Naam}</span>;
                  }}
                />

                <DataTypeProvider
                  for={['DatumUitBedrijf']}
                  formatterComponent={(props) => {
                    const rij: IOphalenProductenResultElementV2 = props.row;

                    if (rij.DatumUitBedrijf === null) {
                      return <span>Niet opgegeven</span>;
                    }
                    return <span>{format(new Date(rij.DatumUitBedrijf), 'dd-MM-yyyy')}</span>;
                  }}
                />

                <DataTypeProvider
                  for={['__datumGeactiveerd']}
                  formatterComponent={(props) => {
                    const rij: IOphalenProductenResultElementV2 = props.row;

                    const item = registeritems.find(
                      (x: IOphalenRegisterItemsResultElement) => x.product!.ProdID === rij.ProdID,
                    );

                    return (
                      <span>
                        {item !== undefined
                          ? format(new Date(item.DatumGeactiveerd), 'dd-MM-yyyy')
                          : ''}
                      </span>
                    );
                  }}
                />

                <SortingState defaultSorting={[]} />
                <IntegratedSorting />

                <RowDetailState
                  expandedRowIds={urlState.uitgeklapt}
                  onExpandedRowIdsChange={(x) => setUrlStateSync('uitgeklapt', x as number[])}
                />

                <VirtualTable columnExtensions={kolomExtensies} />
                <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
                <TableHeaderRow showSortingControls />

                <EditingState
                  onCommitChanges={(changes) => {
                    if (changes.deleted === undefined) {
                      return;
                    }
                    const deleted = changes.deleted;
                    const id = deleted[deleted.length - 1];
                    alert(id);
                  }}
                  // onEditingRowIdsChange={(rowIds) => {
                  //   const id = rowIds[rowIds.length - 1] as number;
                  //   props.onWijzigenOpdrachtDialoogStateChange({ trsOpdID: id });
                  //   // alert(id);
                  // }}
                />

                {/* <TableEditColumn
                width={35}
                showEditCommand
                cellComponent={DXTableEditColumnCellComponent}
                commandComponent={DXTableEditColumnCommandComponent}
              /> */}

                {/* <TableRowDetail
                contentComponent={DetailComp}
                toggleCellComponent={DXTableToggleCellComponent}
              /> */}

                <SelectionState
                  selection={urlState.selectie}
                  onSelectionChange={(x) => setUrlStateSync('selectie', x as number[])}
                />
                <TableSelection cellComponent={DXTableCheckboxComponent} />
              </Grid>
            </GridStyleWrapper>
          )
        }
      />
      {urlState.productinfoDialoogState !== null && (
        <ProductinfoDialoog
          open
          id={urlState.productinfoDialoogState.id}
          onSuccess={() => {
            setUrlStateSync('productinfoDialoogState', null);
          }}
          onAnnuleren={() => setUrlStateSync('productinfoDialoogState', null)}
        />
      )}
    </>
  );
};

export default withRouter(Deactiveren);
