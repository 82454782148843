import * as React from 'react';
import TegelBasis from '../../../../../../../components/tegel/TegelBasis';
import { IOphalenInformatieVoorOverzichtAcceptatie } from '../../../../../../../../../shared/src/api/v2/relatie';
import { useMemo } from 'react';
import { format } from 'date-fns';
import NavigatiePijl from '../NavigatiePijl';
import { genereerUrlStateQueryParam } from '../../../../../../../core/useUrlState';
import { IAcceptatieProcedureUrlState } from '../../Acceptatie/Procedure';
import { Kleur as EKleur } from '../../../../../../../bedrijfslogica/constanten';

interface IProps {
  relID: number;
  data: IOphalenInformatieVoorOverzichtAcceptatie;
}

const Acceptatieproceduretegel = (props: IProps) => {
  const bericht = useMemo(() => {
    if (props.data.status === 'CONCEPT' || props.data.status === 'UITSTAAND') {
      return `Er is een lopende acceptatieprocedure op ${format(
        new Date(props.data.datum),
        'dd-MM-yyyy',
      )}.`;
    }
    if (props.data.status === 'GEWEIGERD') {
      return (
        <span style={{ color: EKleur.Rood }}>
          Klant is geweigerd op {format(new Date(props.data.datum), 'dd-MM-yyyy')}
        </span>
      );
    }
    if (props.data.status === 'GEEN_REACTIE') {
      return `Geen reactie op verzoek acceptatie vereisten op ${format(
        new Date(props.data.datum),
        'dd-MM-yyyy',
      )}.`;
    }

    return null;
  }, [props.relID, props.data]);

  return (
    <TegelBasis titel="Acceptatie">
      <div className="pl-2 pr-2 pb-2">
        <div className="d-flex align-items-center">
          <span>{bericht}</span>
          <span className="ml-2">
            <NavigatiePijl
              relID={props.relID}
              pathWithStateBuilder={() => {
                const state = genereerUrlStateQueryParam<IAcceptatieProcedureUrlState>({
                  toevoegenProcedureDialoogState: null,
                  wijzigenStatusDialoogState: null,
                  selectie: [props.data.aprProcID],
                  uitgeklapt: [props.data.aprProcID],
                  versturenDocumenten: null,
                });
                return `acceptatie/procedure?state=${state}`;
              }}
            />
          </span>
        </div>
      </div>
    </TegelBasis>
  );
};

export default Acceptatieproceduretegel;
