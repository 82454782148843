import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 15px;
  flex: 1;

  .attributen-links {
  }

  .attributen-rechts {
  }

  .invoer-veld {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
    flex: 1;
  }
`;
