import React, { useCallback, useEffect, useMemo, useState } from 'react';
import IDialoogProps from '../../../../../core/IDialoogProps';
import Dialoog from '../../../../../components/dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import { Field, FieldProps, Formik, FormikActions, FormikErrors, FormikProps } from 'formik';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../../models/IRemoteData';
import {
  IVergelijkingspunt,
  IVergelijkingspuntWaardeTekstVoorMuteren,
  IVergelijkingspuntXConcurrentie,
  VergelijkingspuntWaardeOpgeslagen,
  VergelijkingspuntWaardeVoorMuteren,
} from '../../../../../../../shared/src/api/v2/concurrent/vergelijkingspunt';
import api from '../../../../../api';
import { IOphalenTekstenResultElement } from '../../../../../../../shared/src/api/v2/tekst';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import nameOf from '../../../../../core/nameOf';
import FormikVeldFout from '../../../../../components/formulier/FormikVeldFout';
import { LoadingSpinnerCenter } from '../../../../../components/Gedeeld/LoadingSpinner';
import VinkVeld from '../../../../../components/formulier/VinkVeld';
import WaardeTypeSelectie from '../../WaardeTypeSelectie';
import WaardeVeld from '../../WaardeVeld';
import { Kleur } from '../../../../../bedrijfslogica/constanten';
import MultiSelect, { IOptie } from '../../../../../components/formulier/MultiSelect';
import {
  IOphalenVoetnotenResult,
  IVergelijkingspuntVoetnootXConcurrentie,
  IVoetnoot,
} from '../../../../../../../shared/src/api/v2/concurrent/vergelijkingspunt/voetnoot';
import teksten from '../../../../../bedrijfslogica/teksten';

interface IProps extends IDialoogProps {
  concurrentID: number;
  id: number | null;
  vergelijkingspunt: IVergelijkingspunt;
}

interface IWijzigenData {
  vergelijkingspuntXConcurrentie: IVergelijkingspuntXConcurrentie;
  tekstenTekstWaarde: IOphalenTekstenResultElement[];
  vergelijkingspuntVoetnootXConcurrenties: IVergelijkingspuntVoetnootXConcurrentie[];
}

interface IFormValues {
  waarde: VergelijkingspuntWaardeVoorMuteren;
  voetnootIDs: number[];
  weergeven: boolean;
}

const MuterenDialoog = (props: IProps) => {
  const vergelijkingspuntWaarde: VergelijkingspuntWaardeOpgeslagen = useMemo(
    () => JSON.parse(props.vergelijkingspunt.Data),
    [props.vergelijkingspunt.Data],
  );

  const [voetnotenResult, setVoetnotenResult] = useState<IRemoteData<IOphalenVoetnotenResult>>(
    createPendingRemoteData(),
  );
  const ophalenVoetnotenResult = useCallback(async () => {
    const result = await api.v2.concurrentie.vergelijkingspunt.voetnoot.ophalenVoetnoten({});
    setVoetnotenResult(createReadyRemoteData(result));
  }, []);
  useEffect(() => {
    ophalenVoetnotenResult();
  }, []);

  const [wijzigenData, setWijzigenData] = useState<IRemoteData<IWijzigenData | null>>(
    props.id === null ? createReadyRemoteData(null) : createPendingRemoteData(),
  );

  const bepalenWijzigenData = useCallback(async () => {
    if (props.id === null) {
      if (wijzigenData.state === ERemoteDataState.Pending || wijzigenData.data !== null) {
        setWijzigenData(createReadyRemoteData(null));
      }
      return;
    }

    const [result, vergelijkingspuntVoetnootXConcurrentiesResult] = await Promise.all([
      api.v2.concurrentie.vergelijkingspunt.ophalenVergelijkingspuntXConcurrenties({
        filterSchema: {
          filters: [
            {
              naam: 'IDS',
              data: [props.id],
            },
          ],
        },
      }),
      api.v2.concurrentie.vergelijkingspunt.voetnoot.ophalenVergelijkingspuntVoetnootXConcurrenties(
        {
          filterSchema: {
            filters: [
              {
                naam: 'VERGELIJKINGSPUNT_X_CONCURRENTIE_IDS',
                data: [props.id],
              },
            ],
          },
        },
      ),
    ]);
    const [vergelijkingspuntXConcurrentie] = result.items;

    const d = JSON.parse(vergelijkingspuntXConcurrentie.Data) as VergelijkingspuntWaardeOpgeslagen;
    let tekstWaardeTekstID: number | null = null;
    if (d.type === 'tekst') {
      tekstWaardeTekstID = d.tekstID;
    }

    const tekstIDs = [tekstWaardeTekstID].filter((x) => x !== null) as number[];

    const tekstenResult =
      tekstIDs.length === 0
        ? null
        : await api.v2.tekst.ophalenTekstenInAlleTalen({
            tekstIDs,
          });

    const tekstenTekstWaarde =
      tekstWaardeTekstID === null
        ? []
        : tekstenResult?.teksten.filter((tekst) => tekst.TekstID === tekstWaardeTekstID) ?? [];

    const data: IWijzigenData = {
      vergelijkingspuntXConcurrentie,
      tekstenTekstWaarde,
      vergelijkingspuntVoetnootXConcurrenties: vergelijkingspuntVoetnootXConcurrentiesResult.items,
    };
    setWijzigenData(createReadyRemoteData(data));
  }, [props.id]);

  useEffect(() => {
    bepalenWijzigenData();
  }, [bepalenWijzigenData]);

  const initialValues = useMemo<IRemoteData<IFormValues>>(() => {
    if (wijzigenData.state === ERemoteDataState.Pending) {
      return createPendingRemoteData();
    }
    if (wijzigenData.data === null) {
      const values: IFormValues = {
        waarde: {
          type: 'ja_nee',
          waarde: true,
        },
        voetnootIDs: [],
        weergeven: true,
      };
      return createReadyRemoteData(values);
    }

    const data = wijzigenData.data!;
    const vergelijkingspuntWaarde: VergelijkingspuntWaardeOpgeslagen = JSON.parse(
      data.vergelijkingspuntXConcurrentie.Data,
    );
    let waarde: IFormValues['waarde'];
    switch (vergelijkingspuntWaarde.type) {
      case 'tekst':
        waarde = {
          type: 'tekst',
          tekstID: vergelijkingspuntWaarde.tekstID,
          taalTeksten: data.tekstenTekstWaarde.map((tekst) => ({
            taalID: tekst.TaalID,
            tekst: tekst.Tekst ?? '',
            toepassen: tekst.Toepassen,
          })),
        };
        break;
      case 'niet_van_toepassing':
      case 'ja_nee':
      case 'bedrag':
      case 'getal':
        waarde = vergelijkingspuntWaarde;
        break;
    }

    const voetnootIDs = data.vergelijkingspuntVoetnootXConcurrenties.map(
      (x) => x.VergelijkingspuntVoetnootID,
    );

    const values: IFormValues = {
      waarde,
      voetnootIDs,
      weergeven: data.vergelijkingspuntXConcurrentie.Weergeven,
    };
    return createReadyRemoteData(values);
  }, [wijzigenData]);

  const validate = useCallback((values: IFormValues): FormikErrors<IFormValues> => {
    if (values.waarde.type === 'tekst') {
      const taalTeksten = values.waarde.taalTeksten;

      if (taalTeksten.filter((x) => x.toepassen).length === 0) {
        return {
          waarde: teksten.formulier.E_VERPLICHT_VELD({
            veldnaam: 'Waarde teksten',
          }) as any,
        };
      }
      const eersteTekst = taalTeksten.find((x) => x.toepassen)!;
      if (eersteTekst.tekst.trim().length === 0) {
        return {
          waarde: `Waarde teksten moet minimaal een geldige tekst bevatten.` as any,
        };
      }
    }

    return {};
  }, []);

  const handleSubmit = useCallback(
    async (values: IFormValues, actions: FormikActions<IFormValues>) => {
      actions.setSubmitting(true);
      const errors = await actions.validateForm(values);
      if (Object.keys(errors).length > 0) {
        actions.setSubmitting(false);
        return;
      }

      let waarde: VergelijkingspuntWaardeVoorMuteren;
      switch (values.waarde.type) {
        case 'niet_van_toepassing':
          waarde = {
            type: 'niet_van_toepassing',
          };
          break;
        case 'getal':
          waarde = {
            type: 'getal',
            waarde: values.waarde.waarde as number,
          };
          break;
        case 'tekst':
          const w = values.waarde as IVergelijkingspuntWaardeTekstVoorMuteren;
          waarde = w;
          break;
        case 'ja_nee':
          waarde = {
            type: 'ja_nee',
            waarde: values.waarde.waarde as boolean,
          };
          break;
        case 'bedrag':
          waarde = {
            type: 'bedrag',
            waarde: values.waarde.waarde as number,
          };
          break;
      }
      if (wijzigenData.data === null) {
        await api.v2.concurrentie.vergelijkingspunt.toevoegenVergelijkingspuntXConcurrentie({
          concurrentID: props.concurrentID,
          vergelijkingspuntID: props.vergelijkingspunt.ID,
          waarde,
          voetnootIDs: values.voetnootIDs,
          weergeven: values.weergeven,
        });
      } else {
        await api.v2.concurrentie.vergelijkingspunt.wijzigenVergelijkingspuntXConcurrentie({
          id: props.id!,
          waarde,
          voetnootIDs: values.voetnootIDs,
          weergeven: values.weergeven,
        });
      }

      props.onSuccess(null);
      actions.setSubmitting(false);
    },
    [wijzigenData, props.onSuccess, props.concurrentID, props.vergelijkingspunt, props.id],
  );

  return (
    <Dialoog index={props.dialoogIndex ?? 0}>
      <ModalHeader>
        <ModalTitle>
          {props.id === null ? 'Toevoegen' : 'Wijzigen'}&nbsp;vergelijkingspuntgegevens van
          concurrent
        </ModalTitle>
      </ModalHeader>
      {initialValues.state === ERemoteDataState.Pending ||
      voetnotenResult.state === ERemoteDataState.Pending ? (
        <ModalBody>
          <LoadingSpinnerCenter />
        </ModalBody>
      ) : (
        <Formik
          initialValues={initialValues.data!}
          onSubmit={handleSubmit}
          validate={validate}
          render={(formikProps) => (
            <MuterenDialoogInner
              {...props}
              formikProps={formikProps}
              vergelijkingspuntWaarde={vergelijkingspuntWaarde}
              voetnoten={voetnotenResult.data!.voetnoten}
            />
          )}
        />
      )}
    </Dialoog>
  );
};

interface IInnerProps {
  formikProps: FormikProps<IFormValues>;
  vergelijkingspuntWaarde: VergelijkingspuntWaardeOpgeslagen;
  voetnoten: IVoetnoot[];
}

const MuterenDialoogInner = (props: IProps & IInnerProps) => {
  const { formikProps, onAnnuleren, voetnoten } = props;
  const { isSubmitting, submitForm, values } = formikProps;

  return (
    <>
      <ModalBody>
        <div className="form-group">
          <label>Type</label>
          <Field
            name={nameOf<IFormValues>('waarde')}
            render={(fieldProps: FieldProps<IFormValues>) => {
              const { field, form } = fieldProps;

              return (
                <WaardeTypeSelectie
                  waarde={field.value}
                  onChange={(waarde) => form.setFieldValue(field.name, waarde)}
                />
              );
            }}
          />

          {values.waarde.type !== props.vergelijkingspuntWaarde.type && (
            <div className="mt-2" style={{ color: Kleur.DonkerGeel }}>
              Let op: Het gekozen type wijkt af van het type ({props.vergelijkingspuntWaarde.type})
              wat wordt gebruikt bij de vergelijkingspuntgegevens van Splash.
              <br />
              In de meeste gevallen is dit niet de bedoeling.
            </div>
          )}

          {values.waarde.type !== 'niet_van_toepassing' && (
            <>
              <label className="mt-3">Waarde</label>
              <Field
                name={nameOf<IFormValues>('waarde')}
                render={(fieldProps: FieldProps<IFormValues>) => {
                  const { field, form } = fieldProps;

                  const veld = (
                    <WaardeVeld
                      waarde={field.value}
                      onChange={(value) => form.setFieldValue(field.name, value)}
                    />
                  );

                  return (
                    <div className="d-flex flex-column">
                      {veld}
                      <FormikVeldFout
                        fieldProps={fieldProps}
                        options={{ objectAlsVeldBeschouwen: true }}
                      />
                    </div>
                  );
                }}
              />
            </>
          )}

          <label className="mt-3">Voetnoten</label>
          <Field
            name={nameOf<IFormValues>('voetnootIDs')}
            render={(fieldProps: FieldProps<IFormValues>) => {
              const { field, form } = fieldProps;

              const opties: IOptie<number>[] = voetnoten.map((voetnoot) => {
                return {
                  key: voetnoot.ID,
                  weergave: voetnoot.Naam,
                };
              });

              return (
                <MultiSelect<number>
                  value={field.value}
                  onChange={(x) => form.setFieldValue(field.name, x)}
                  opties={opties}
                />
              );
            }}
          />

          <Field
            name={nameOf<IFormValues>('weergeven')}
            render={(fieldProps: FieldProps<IFormValues>) => {
              const { field, form } = fieldProps;

              return (
                <div className="d-flex align-items-center mt-3">
                  <VinkVeld
                    aangevinkt={field.value}
                    onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                  />
                  <span className="ml-2">Weergeven</span>
                </div>
              );
            }}
          />
        </div>
      </ModalBody>
      <ModalFooter className="d-flex flex-row justify-content-start">
        <button
          className="btn btn-primary d-flex align-items-center justify-content-center"
          onClick={submitForm}
          style={{ width: 100 }}
          disabled={isSubmitting}
        >
          Ok
        </button>
        <button
          className="btn btn-secondary"
          onClick={onAnnuleren}
          style={{ width: 100 }}
          disabled={isSubmitting}
        >
          Annuleren
        </button>
      </ModalFooter>
    </>
  );
};

export default MuterenDialoog;
