import React, { useCallback, useEffect, useMemo, useState } from 'react';
import IDialoogProps from '../../../../../core/IDialoogProps';
import { Field, FieldProps, Formik, FormikActions, FormikErrors, FormikProps } from 'formik';
import teksten from '../../../../../bedrijfslogica/teksten';
import Dialoog from '../../../../../components/dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import { ModalBody } from 'react-bootstrap';
import nameof from '../../../../../core/nameOf';
import FormikVeldFout from '../../../../../components/formulier/FormikVeldFout';
import MeertaligTekstveld from '../../../../../components/formulier/MeertaligTekstveld';
import ModalFooter from 'react-bootstrap/ModalFooter';
import * as Yup from 'yup';
import VinkVeld from '../../../../../components/formulier/VinkVeld';
import MultiSelect, { IOptie } from '../../../../../components/formulier/MultiSelect';
import { IOphalenEigenschapGroepenResult } from '../../../../../../../shared/src/api/v2/aanbod/index';
import api from '../../../../../api';
import TekstVisualisatie from '../../../../../components/TekstVisualisatie';
import LoadingSpinner from '../../../../../components/Gedeeld/LoadingSpinner';
import ITaalTekst from '../../../../../../../shared/src/models/talen/ITaalTekst';

export interface IFormikValues {
  code: string;
  naam: ITaalTekst[];
  eenheid: ITaalTekst[];
  info: ITaalTekst[];
  tonenOpWebsite: boolean;
  eigGrpIDs: number[];
  naamEnum: string;
}

export interface IDialoogResult {
  code: string | null;
  naam: ITaalTekst[];
  eenheid: ITaalTekst[] | null;
  info: ITaalTekst[] | null;
  tonenOpWebsite: boolean;
  eigGrpIDs: number[];
  naamEnum: string | null;
}

interface IProps extends IDialoogProps<IDialoogResult> {
  initialValues?: IFormikValues;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  code: 'Code',
  naam: 'Naam',
  eenheid: 'Eenheid',
  info: 'Info',
  tonenOpWebsite: 'Tonen op website',
  eigGrpIDs: 'Groepen',
  naamEnum: 'Naam enum',
};

const MuterenDialoog: React.FC<IProps> = (props) => {
  const initialValues = useMemo(() => {
    if (props.initialValues !== undefined) {
      return props.initialValues;
    }

    return {
      code: '',
      naam: [],
      eenheid: [],
      info: [],
      tonenOpWebsite: true,
      eigGrpIDs: [],
      naamEnum: '',
    };
  }, [props.initialValues]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);
      // const errors = await actions.validateForm(values);
      // if (Object.keys(errors).length > 0) {
      //   actions.setSubmitting(false);
      //   return;
      // }

      props.onSuccess({
        code: values.code.trim(),
        naam: values.naam!,
        // Als geen van de velden op toepassen staat, dan beschouwen als niet ingevuld
        eenheid: values.eenheid.every((x) => !x.toepassen) ? null : values.eenheid,
        // Als geen van de velden op toepassen staat, dan beschouwen als niet ingevuld
        info: values.info.every((x) => !x.toepassen) ? null : values.info,
        tonenOpWebsite: values.tonenOpWebsite,
        eigGrpIDs: values.eigGrpIDs,
        naamEnum: values.naamEnum.trim() === '' ? null : values.naamEnum.trim(),
      });
      actions.setSubmitting(false);
    },
    [props.onSuccess],
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        code: Yup.string()
          .required(teksten.formulier.E_VERPLICHT_VELD({ veldnaam: veldnamen.code }))
          .min(
            1,
            teksten.formulier.E_MINIMAAL_AANTAL_KARAKTERS_BEVATTEN({
              veldnaam: veldnamen.code,
              aantal: 1,
            }),
          ),
        naam: Yup.array()
          .min(1)
          .required(teksten.formulier.E_VERPLICHT_VELD({ veldnaam: veldnamen.code })),
        eenheid: Yup.array(),
        // .min(1)
        // .required(teksten.formulier.E_VERPLICHT_VELD({ veldnaam: veldnamen.code })),
        info: Yup.array(),
        // naamEnum: Yup.string()
        //   .notRequired()
        //   .min(
        //     1,
        //     teksten.formulier.E_MINIMAAL_AANTAL_KARAKTERS_BEVATTEN({
        //       veldnaam: veldnamen.code,
        //       aantal: 1,
        //     }),
        //   ),
      }),
    [],
  );

  const [
    eigenschapGroepenResult,
    setEigenschapGroepenResult,
  ] = useState<IOphalenEigenschapGroepenResult | null>(null);
  useEffect(() => {
    (async () => {
      const result = await api.v2.aanbod.specificatie.ophalenEigenschapGroepen({
        filterSchema: { filters: [] },
      });
      setEigenschapGroepenResult(result);
    })();
  }, []);
  const groepenOpties = useMemo<IOptie<number>[] | null>(() => {
    if (eigenschapGroepenResult === null) {
      return null;
    }
    return eigenschapGroepenResult.groepen.map((groep) => {
      return {
        key: groep.ID,
        weergave: <TekstVisualisatie tekstID={groep.Naam_TekstID} />,
      };
    });
  }, [eigenschapGroepenResult]);

  return (
    <Dialoog index={props.dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Specificatie</ModalTitle>
      </ModalHeader>
      <Formik<IFormikValues>
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        render={(formikProps: FormikProps<IFormikValues>) => {
          const { isSubmitting, submitForm, values } = formikProps;
          return (
            <>
              <ModalBody>
                <div className="form-group">
                  <div className="row">
                    <div className="col-6">
                      <label>{veldnamen.code}</label>
                      <Field
                        name={nameof<IFormikValues>('code')}
                        render={(fieldProps: FieldProps<IFormikValues>) => {
                          const { field, form } = fieldProps;
                          return (
                            <>
                              <input type="text" className="form-control" {...field} />
                              <FormikVeldFout fieldProps={fieldProps} />
                            </>
                          );
                        }}
                      />
                    </div>

                    <div className="col-6">
                      <label>{veldnamen.naamEnum}</label>
                      <Field
                        name={nameof<IFormikValues>('naamEnum')}
                        render={(fieldProps: FieldProps<IFormikValues>) => {
                          const { field, form } = fieldProps;
                          return (
                            <>
                              <input type="text" className="form-control" {...field} />
                              <FormikVeldFout fieldProps={fieldProps} />
                            </>
                          );
                        }}
                      />
                    </div>

                    <div className="col-12 mt-3">
                      <label>{veldnamen.eigGrpIDs}</label>
                      <Field
                        name={nameof<IFormikValues>('eigGrpIDs')}
                        render={(fieldProps: FieldProps<IFormikValues>) => {
                          const { field, form } = fieldProps;
                          if (groepenOpties === null) {
                            return <LoadingSpinner />;
                          }

                          return (
                            <>
                              <MultiSelect
                                value={field.value}
                                onChange={(x) => form.setFieldValue(field.name, x)}
                                isVerwijderbaar={false}
                                opties={groepenOpties}
                              />
                              <FormikVeldFout fieldProps={fieldProps} />
                            </>
                          );
                        }}
                      />
                    </div>

                    <div className="col-8 mt-3">
                      <label>{veldnamen.naam}</label>
                      <Field
                        name={nameof<IFormikValues>('naam')}
                        render={(fieldProps: FieldProps<IFormikValues>) => {
                          const { field, form } = fieldProps;
                          return (
                            <>
                              <MeertaligTekstveld
                                waarden={field.value}
                                onChange={(x) => form.setFieldValue(field.name, x)}
                              />
                              <FormikVeldFout fieldProps={fieldProps} />
                            </>
                          );
                        }}
                      />
                    </div>

                    <div className="col-4 mt-3">
                      <label>{veldnamen.eenheid}</label>
                      <Field
                        name={nameof<IFormikValues>('eenheid')}
                        render={(fieldProps: FieldProps<IFormikValues>) => {
                          const { field, form } = fieldProps;
                          return (
                            <>
                              <MeertaligTekstveld
                                waarden={field.value}
                                onChange={(x) => form.setFieldValue(field.name, x)}
                                standaardtaalOptioneel
                              />
                              <FormikVeldFout fieldProps={fieldProps} />
                            </>
                          );
                        }}
                      />
                    </div>

                    <div className="col-12 mt-3">
                      <label>{veldnamen.info}</label>
                      <Field
                        name={nameof<IFormikValues>('info')}
                        render={(fieldProps: FieldProps<IFormikValues>) => {
                          const { field, form } = fieldProps;
                          return (
                            <>
                              <MeertaligTekstveld
                                waarden={field.value}
                                onChange={(x) => form.setFieldValue(field.name, x)}
                                standaardtaalOptioneel
                              />
                              <FormikVeldFout fieldProps={fieldProps} />
                            </>
                          );
                        }}
                      />
                    </div>

                    <div className="col-12 mt-3">
                      <Field
                        name={nameof<IFormikValues>('tonenOpWebsite')}
                        render={(fieldProps: FieldProps<IFormikValues>) => {
                          const { field, form } = fieldProps;
                          return (
                            <>
                              <div className="d-flex align-items-center">
                                <VinkVeld
                                  aangevinkt={field.value}
                                  onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                />
                                <span className="ml-2">{veldnamen.tonenOpWebsite}</span>
                              </div>
                              <FormikVeldFout fieldProps={fieldProps} />
                            </>
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
              </ModalBody>
              <ModalFooter className="d-flex flex-row justify-content-start">
                <button
                  className="btn btn-primary"
                  onClick={submitForm}
                  style={{ width: 100 }}
                  disabled={isSubmitting}
                >
                  Ok
                </button>
                <button
                  className="btn btn-secondary"
                  onClick={props.onAnnuleren}
                  style={{ width: 100 }}
                  disabled={isSubmitting}
                >
                  Annuleren
                </button>
              </ModalFooter>
            </>
          );
        }}
      />
    </Dialoog>
  );
};

export default MuterenDialoog;
