import * as React from 'react';
import VinkVeld from '../../../../../../components/formulier/VinkVeld';

interface IProps {
  naam: string;
  omschrijving?: string;
  gegeven: boolean;
  onGegevenChange: (aan: boolean) => void;
}

const PermissieItem = (props: IProps) => {
  return (
    <div className="d-flex justify-content-between align-items-center">
      <div className="d-flex flex-column">
        <span style={{ fontSize: 16 }}>{props.naam}</span>
        {props.omschrijving && (
          <span className="text-muted mt-1" style={{ fontSize: 13 }}>
            {props.omschrijving}
          </span>
        )}
      </div>
      <VinkVeld aangevinkt={props.gegeven} onGewijzigd={props.onGegevenChange} />
    </div>
  );
};

export default PermissieItem;
