import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import IDialoogProps from '../../../../../../core/IDialoogProps';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import Dialoog from '../../../../../../components/dialogen/Dialoog';
import VinkVeld from '../../../../../../components/formulier/VinkVeld';
import UitlegTooltip from '../../../../../../components/formulier/UitlegTooltip';
import Combobox, { IOptie } from '../../../../../../components/formulier/Combobox';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../../../models/IRemoteData';
import api from '../../../../../../api';
import {
  IBulkVerzendBerichtPrioriteit,
  IBulkVerzendBerichtTijdvak,
  IBulkVerzendBerichtTijdvakDag,
  IOphalenBulkVerzendBerichtPrioriteitenResult,
  IOphalenBulkVerzendBerichtTijdvakDagenResult,
  IOphalenBulkVerzendBerichtTijdvakkenResult,
} from '../../../../../../../../shared/src/api/v2/bulkVerzending';
import { LoadingSpinnerCenter } from '../../../../../../components/Gedeeld/LoadingSpinner';
import BulkBerichtTijdvakVisualisatie, {
  IBulkBerichtTijdvakVisualisatieData,
} from '../../../../../../components/entiteitVisualisaties/BulkBerichtTijdvakVisualisatie';

export interface IAanbiedenVoorVerzendenDialoogResult {
  directVersturen: boolean;
  prioriteitID: number;
  tijdvakID: number | null;
}

interface IData {
  prioriteiten: IBulkVerzendBerichtPrioriteit[];
  tijdenvakken: IBulkVerzendBerichtTijdvak[];
  tijdvakdagenBijTijdvakID: { [tijdvakID: number]: IBulkVerzendBerichtTijdvakDag[] };
}

interface IProps extends IDialoogProps<IAanbiedenVoorVerzendenDialoogResult> {}

const AanbiedenVoorVerzendenDialoog = (props: IProps) => {
  const [prioriteitID, setPrioriteitID] = useState<number | null>(null);
  const [tijdvakID, setTijdvakID] = useState<number | null>(null);
  const [directVersturen, setDirectVersturen] = useState(false);

  const [data, setData] = useState<IRemoteData<IData>>(createPendingRemoteData());
  const dataRef = useRef(data);
  const setDataRef = useCallback((data: IRemoteData<IData>) => {
    dataRef.current = data;
    setData(data);
  }, []);

  const ophalenData = useCallback(async () => {
    const [prioriteitenResult, tijdvakkenResult, tijdvakdagenResult] = (await Promise.all([
      api.v2.bulkVerzending.ophalenBulkVerzendBerichtPrioriteiten({}),
      api.v2.bulkVerzending.ophalenBulkVerzendBerichtTijdvakken({}),
      api.v2.bulkVerzending.ophalenBulkVerzendBerichtTijdvakDagen({}),
    ])) as [
      IOphalenBulkVerzendBerichtPrioriteitenResult,
      IOphalenBulkVerzendBerichtTijdvakkenResult,
      IOphalenBulkVerzendBerichtTijdvakDagenResult,
    ];

    const tijdvakdagenBijTijdvakID = tijdvakdagenResult.items.reduce<{
      [tijdvakID: number]: IBulkVerzendBerichtTijdvakDag[];
    }>((acc, curr) => {
      const arr = acc[curr.TijdvakID] ?? [];
      arr.push(curr);
      acc[curr.TijdvakID] = arr;
      return acc;
    }, {});

    const data: IData = {
      prioriteiten: prioriteitenResult.items,
      tijdenvakken: tijdvakkenResult.items,
      tijdvakdagenBijTijdvakID,
    };

    setDataRef(createReadyRemoteData(data));
    const normalePrioriteit = prioriteitenResult.items.find((x) => x.NaamEnum === 'NORMAAL');
    setPrioriteitID(normalePrioriteit?.ID ?? null);
  }, []);

  useEffect(() => {
    ophalenData();
  }, []);

  const tijdvakOpties = useMemo<IRemoteData<IOptie<number>[]>>(() => {
    if (data.state === ERemoteDataState.Pending) {
      return createPendingRemoteData();
    }

    const d = data.data!;
    const opties: IOptie<number>[] = d.tijdenvakken.map((tijdvak) => {
      const dagen = d.tijdvakdagenBijTijdvakID[tijdvak.ID] ?? [];
      const data: IBulkBerichtTijdvakVisualisatieData = {
        tijdvak,
        dagen,
      };
      return {
        id: tijdvak.ID,
        label: <BulkBerichtTijdvakVisualisatie tijdvakID={tijdvak.ID} data={data} />,
      };
    });

    return createReadyRemoteData(opties);
  }, [data]);

  const handleOkClick = useCallback(() => {
    props.onSuccess({
      directVersturen,
      prioriteitID: prioriteitID!,
      tijdvakID,
    });
  }, [directVersturen, prioriteitID, tijdvakID]);

  return (
    <Dialoog index={props.dialoogIndex ?? 0}>
      <ModalHeader>
        <ModalTitle>Aanbieden voor verzenden</ModalTitle>
      </ModalHeader>

      {tijdvakOpties.state === ERemoteDataState.Pending ? (
        <ModalBody>
          <LoadingSpinnerCenter />
        </ModalBody>
      ) : (
        <>
          <ModalBody style={{ padding: '2rem', fontSize: 15 }}>
            <p>Bevestig dat je de berichten wilt aanbieden voor verzenden.</p>
            <p>De berichten zullen daarop door de verzendservice worden verstuurd.</p>
            <div className="d-flex align-items-center mt-4">
              <span className="mr-1">Prioriteit</span>
              <UitlegTooltip inhoud="De prioriteit bepaald de voorrang van de berichten tegenover andere berichten." />
              <Combobox
                className="ml-2"
                geselecteerd={prioriteitID}
                onSelectieChange={(x) => setPrioriteitID(x!)}
                opties={data.data!.prioriteiten.map((prio) => {
                  return {
                    id: prio.ID,
                    label: prio.Naam,
                  };
                })}
              />
            </div>

            <div className="d-flex align-items-center mt-4">
              <span className="mr-1">Tijdvak</span>
              <UitlegTooltip inhoud="Door een tijdvak te selecteren wordt het bericht alleen verstuurd binnen de opgegeven momenten van het tijdvak" />
              <Combobox
                className="ml-2"
                geselecteerd={tijdvakID}
                onSelectieChange={(x) => setTijdvakID(x)}
                opties={tijdvakOpties.data!}
                legeOptieTonen
                isWisbaar
                options={{
                  legeOptieTekst: 'Geen tijdvak',
                }}
              />
            </div>

            <div className="d-flex align-items-center mt-4">
              <VinkVeld aangevinkt={directVersturen} onGewijzigd={setDirectVersturen} />
              <span className="ml-2 mr-2">Direct versturen</span>
              <UitlegTooltip inhoud="Bij direct versturen worden de berichten op status in afwachting gezet, waardoor ze meteen beschikbaar worden gesteld aan de verzender. Bij niet direct versturen dienen de berichten eerst nog reviewed worden bij de verzender." />
            </div>
          </ModalBody>
          <ModalFooter className="d-flex flex-row justify-content-start">
            <button
              className="btn btn-primary d-flex align-items-center justify-content-center"
              onClick={handleOkClick}
              style={{ width: 100 }}
              disabled={prioriteitID === null}
            >
              Ok
            </button>
            <button
              className="btn btn-secondary"
              onClick={props.onAnnuleren}
              style={{ width: 100 }}
            >
              Annuleren
            </button>
          </ModalFooter>
        </>
      )}
    </Dialoog>
  );
};

export default AanbiedenVoorVerzendenDialoog;
