import React, { useMemo } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { IActieProps } from '../index';
import TelefoonoproepVisualisatie, {
  EWeergaveModus,
} from '../../../components/communicatie/TelefoonoproepVisualisatie';

interface IData {
  telOprID: number;
}

const TELEFOONOPROEP_GEMIST: React.VoidFunctionComponent<IActieProps> = (props: IActieProps) => {
  const data = useMemo(() => JSON.parse(props.controltaak.Data!) as IData, [
    props.controltaak.Data,
  ]);

  return (
    <TelefoonoproepVisualisatie
      telOprID={data.telOprID}
      weergaveModus={EWeergaveModus.NummerEnContact}
    />
  );
};

export default TELEFOONOPROEP_GEMIST;
