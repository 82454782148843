import React, { useCallback, useContext, useMemo, useState } from 'react';
import { IOphalenAanmaningenResultElement } from '../../../../../shared/src/api/v2/aanmaning';
import {
  DXTableCheckboxComponent,
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
  DXTableToggleCellComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../helpers/dxTableGrid';
import {
  Grid,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  DataTypeProvider,
  EditingState,
  IntegratedSorting,
  RowDetailState,
  SelectionState,
  SortingState,
  VirtualTable as VirtualTableBase,
} from '@devexpress/dx-react-grid';
import RelatieVisualisatie from '../../personalia/RelatieVisualisatie';
import { format } from 'date-fns';
import FormatteerBedrag, { StandaardMutatieBedragOpmaker } from '../../MutatieBedrag';
import api from '../../../api';
import { EResultType } from '../../../stores/CheckStore';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../stores/RootStore';
import WijzigenDialoog from './WijzigenDialoog';
import DetailComp from './DetailComp';
import _ from 'lodash';
import { Kleur as EKleur, Kleur } from '../../../bedrijfslogica/constanten';
import nameOf from '../../../core/nameOf';
import { IconSend, IconToevoegen } from '../../Icons';
import KeuzeDialoog, { EKeuzeType, IOptie, ISelectieResult } from '../../dialogen/KeuzeDialoog';
import { EKanaal } from '../../../bedrijfslogica/enums';
import { EHoedanigheid } from '../../personalia/RelatieSelectieDialoog';

const geenData = {
  noData: 'Geen aanmaningen gevonden',
};

export interface IWijzigenBetalingsregelingDialoogState {
  ID: number;
}

interface IVersturenAanmaningenDialoogState {}

interface IProps {
  aanmaningen: IOphalenAanmaningenResultElement[] | null;
  selectie: number[];
  onSelectieChange: (selectie: number[]) => void;
  onVerversenAangevraagd: () => void;
  onRelatieClick?: (relID: number) => void;
  relID?: number;
}

const AanmaningenTabel: React.FC<IProps> = observer((props) => {
  const { checkStore } = useContext(RootStoreContext);
  const keyExtractor = useCallback((rij: IOphalenAanmaningenResultElement) => rij.AanmID, []);

  const [
    versturenAanmaningenDialoogState,
    setVersturenAanmaningenDialoogState,
  ] = useState<IVersturenAanmaningenDialoogState | null>(null);

  const kolommen = useMemo<TypedColumn<IOphalenAanmaningenResultElement>[]>(
    () =>
      [
        {
          name: 'Aanmaningdatum',
          title: 'Datum',
          getCellValue: (x: IOphalenAanmaningenResultElement) => x.Aanmaningdatum,
        },
        {
          name: 'Vervaldatum',
          title: 'Vervaldatum',
          getCellValue: (x: IOphalenAanmaningenResultElement) => x.Vervaldatum,
        },
        {
          name: 'Bedrag',
          title: 'Aanm.bedrag',
        },
        {
          name: '__kosten' as any,
          title: 'Incl. kosten',
          getCellValue: (x: IOphalenAanmaningenResultElement) =>
            _.sum(x.facturen.map((x) => x.KostenAanmaning)),
        },
        {
          name: '__aantalFacturen' as any,
          title: 'Atl. fact.',
        },
        {
          name: '__status' as any,
          title: 'Status',
        },
        {
          name: 'ProForma',
          title: ' ',
        },
        {
          name: 'DatumVerstuurd',
          title: 'Verstuurd op',
        },
        // {
        //   name: '__actueelOpenstaand' as any,
        //   title: 'Actueel openst.',
        //   getCellValue: (x: IOphalenAanmaningenResultElement) => {
        //     return _.sum(x.facturen.map((x) => x.Openstaand));
        //   },
        // },
        props.relID !== undefined
          ? null
          : {
              name: '__relatie' as any,
              title: 'Debiteur',
            },
      ].filter((x) => x !== null) as TypedColumn<IOphalenAanmaningenResultElement>[],
    [props.relID],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IOphalenAanmaningenResultElement>[]>(
    () => [
      {
        columnName: 'Aanmaningdatum',
        width: 100,
      },
      {
        columnName: 'Vervaldatum',
        width: 140,
      },
      {
        columnName: '__relatie' as any,
        width: 200,
      },
      {
        columnName: 'Bedrag',
        width: 125,
      },
      {
        columnName: 'ProForma',
        width: 75,
      },
      {
        columnName: '__kosten' as any,
        width: 110,
      },
      {
        columnName: '__aantalFacturen' as any,
        width: 90,
      },
      {
        columnName: '__status' as any,
        width: 80,
      },
      {
        columnName: '__actueelOpenstaand' as any,
        width: 165,
      },
      {
        columnName: 'RecordToegevoegd',
        width: 135,
      },
      {
        columnName: 'DatumVerstuurd',
        width: 135,
      },
    ],
    [],
  );

  const kolomExtensies: VirtualTableBase.ColumnExtension[] = useMemo(() => {
    return [
      // {
      //   columnName: `Bedrag`,
      //   align: 'right',
      // },
      // {
      //   columnName: `__kosten`,
      //   align: 'right',
      // },
      // {
      //   columnName: `__actueelOpenstaand`,
      //   align: 'right',
      // },
    ];
  }, []);

  const handleToevoegenAanmaningen = useCallback(async () => {
    // Bepaal de facturen die in aanmerking komen voor aanmanen
    const factIDs = (
      await api.v2.aanmaning.selecterenFacturenVoorAanmaning({ relIDs: [props.relID!] })
    ).factIDs;

    if (factIDs.length === 0) {
      await checkStore.melden({
        titel: (
          <span>
            Er zijn geen facturen gevonden die voor aanmanen in aanmerking komen. Het kan zijn dat
            de facturen reeds in een aanmaning of in een dossier zitten, of dat er een bankopdracht
            voor gemaakt is.
          </span>
        ),
      });
      return;
    }

    const resultaat = await checkStore.bevestigen({
      inhoud: (
        <span>
          Aanmaning maken?
          <br />
          <br />
          De aanmaning wordt hier als Pro forma aangemerkt en telt daarom niet mee voor het bepalen
          van de WIK-procedure.
        </span>
      ),
    });
    if (resultaat.type === EResultType.Annuleren) {
      return;
    }

    // Maak de aanmaningen voor de gevonden facturen
    const aanmaningenResult = await api.v2.aanmaning.makenAanmaningen({
      factIDs,
      directDefinitiefMaken: true,
      proForma: false,
    });

    props.onVerversenAangevraagd();
  }, []);

  const handleVersturen = useCallback(
    async (result: ISelectieResult<EKanaal>) => {
      const params = { aanmIDs: props.selectie, kanalen: result.keuze };
      await api.v2.aanmaning.versturenAanmaningen(params);

      setVersturenAanmaningenDialoogState(null);
      props.onVerversenAangevraagd();
    },
    [setVersturenAanmaningenDialoogState, props.selectie],
  );

  const handleVerwijderen = useCallback(
    async (aanmIDs: number[]) => {
      const checkData = await api.v2.aanmaning.checkVerwijderenAanmaningen({
        AanmIDs: aanmIDs,
      });
      const controleResult = await checkStore.controleren({ checkData });
      if (controleResult.type === EResultType.Annuleren) {
        return;
      }

      const resultaat = await checkStore.bevestigen({
        inhoud: `Aanmaning(en) verwijderen?`,
      });
      if (resultaat.type === EResultType.Annuleren) {
        return;
      }

      await api.v2.aanmaning.verwijderenAanmaningen({
        AanmIDs: aanmIDs,
      });

      props.onVerversenAangevraagd();
    },
    [props.selectie],
  );

  const kanaalOpties = useMemo<IOptie<EKanaal>[]>(() => {
    return [
      {
        id: EKanaal.Email,
        label: 'Email',
      },

      {
        id: EKanaal.SMS,
        label: 'SMS',
      },
    ];
  }, []);

  return (
    <>
      {props.aanmaningen === null ? (
        <div />
      ) : (
        <>
          <div
            className="d-flex flex-column p-3"
            style={{
              backgroundColor: Kleur.HeelLichtGrijs,
              borderBottom: `1px solid ${Kleur.LichtGrijs}`,
            }}
          >
            <div className="d-flex">
              {props.relID !== undefined && (
                <button
                  className="btn btn-sm btn-light d-flex align-items-center mr-2"
                  style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                  // disabled={props.selectie.length === 0}
                  onClick={async () => {
                    await handleToevoegenAanmaningen();
                  }}
                >
                  <IconToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                  <span className="ml-2">Aanmaning maken</span>
                </button>
              )}

              <button
                className="btn btn-sm btn-light d-flex align-items-center"
                style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                disabled={props.selectie.length === 0}
                onClick={async () => {
                  const checkData = await api.v2.aanmaning.checkVersturenAanmaningen({
                    aanmIDs: props.selectie,
                  });
                  const checkResult = await checkStore.controleren({
                    checkData,
                  });
                  if (checkResult.type === EResultType.Annuleren) {
                    return;
                  }

                  return setVersturenAanmaningenDialoogState({});
                }}
              >
                <IconSend style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                <span className="ml-2">Versturen</span>
              </button>
            </div>
          </div>
          <GridStyleWrapper height={'calc(100vh - 100px)'}>
            <Grid rows={props.aanmaningen} getRowId={keyExtractor} columns={kolommen}>
              <DataTypeProvider
                for={['__relatie']}
                formatterComponent={(formatterProps) => {
                  const row: IOphalenAanmaningenResultElement = formatterProps.row;
                  return (
                    <a
                      href="#"
                      onClick={(ev) => {
                        ev.stopPropagation();
                        if (props.onRelatieClick !== undefined) {
                          props.onRelatieClick(row.RelID);
                        }
                      }}
                    >
                      <RelatieVisualisatie
                        relID={row.RelID}
                        options={{ geenLinkToepassen: true }}
                        relatieLinkBuilder={(hoedanigheid, relID) =>
                          `/${
                            hoedanigheid === EHoedanigheid.Klant ? 'klant' : 'leverancier'
                          }/${relID}/debiteur/aanmaningen`
                        }
                      />
                    </a>
                  );
                }}
              />

              <DataTypeProvider
                for={[nameOf<IOphalenAanmaningenResultElement>('Aanmaningdatum')]}
                formatterComponent={(formatterProps) => {
                  const rij: IOphalenAanmaningenResultElement = formatterProps.row;
                  if (rij.Aanmaningdatum === null) {
                    return <span />;
                  }
                  return <span>{format(new Date(rij.Aanmaningdatum), 'dd-MM-yyyy')}</span>;
                }}
              />

              <DataTypeProvider
                for={[nameOf<IOphalenAanmaningenResultElement>('DatumVerstuurd')]}
                formatterComponent={(formatterProps) => {
                  if (formatterProps.value === null) {
                    return <span></span>;
                  }
                  return <span>{format(new Date(formatterProps.value), 'dd-MM-yyyy HH:mm')}</span>;
                }}
              />

              <DataTypeProvider
                for={[nameOf<IOphalenAanmaningenResultElement>('RecordToegevoegd')]}
                formatterComponent={(formatterProps) => {
                  if (formatterProps.value === null) {
                    return <span style={{ color: EKleur.Blauw }}>Nog versturen</span>;
                  }
                  return <span>{format(new Date(formatterProps.value), 'dd-MM-yyyy HH:mm')}</span>;
                }}
              />

              <DataTypeProvider
                for={[nameOf<IOphalenAanmaningenResultElement>('Bedrag')]}
                formatterComponent={(formatterProps) => {
                  const rij: IOphalenAanmaningenResultElement = formatterProps.row;
                  return <FormatteerBedrag bedrag={rij.Bedrag} />;
                }}
              />

              <DataTypeProvider
                for={[nameOf<IOphalenAanmaningenResultElement>('ProForma')]}
                formatterComponent={(formatterProps) => {
                  return <span>{formatterProps.value ? 'Pro forma' : ''}</span>;
                }}
              />

              <DataTypeProvider
                for={['__kosten']}
                formatterComponent={(formatterProps) => {
                  const rij = formatterProps.row as IOphalenAanmaningenResultElement;

                  const kosten = _.sum(rij.facturen.map((x) => x.KostenAanmaning));
                  if (kosten === 0) {
                    return <span></span>;
                  }

                  return <FormatteerBedrag bedrag={kosten} />;
                }}
              />

              <DataTypeProvider
                for={['__actueelOpenstaand']}
                formatterComponent={(formatterProps) => {
                  const rij: IOphalenAanmaningenResultElement = formatterProps.row;

                  const openstaand = _.sum(rij.facturen.map((x) => x.Openstaand));

                  if (openstaand === 0) {
                    return <span></span>;
                  }

                  return (
                    <div style={{ color: EKleur.Rood }}>
                      <FormatteerBedrag
                        bedrag={openstaand}
                        rechtsUitlijnen
                        opmaakComponent={(opmaakProps) => {
                          return (
                            <StandaardMutatieBedragOpmaker {...opmaakProps} color={EKleur.Rood} />
                          );
                        }}
                      />
                    </div>
                  );
                }}
              />

              <DataTypeProvider
                for={['__aantalFacturen']}
                formatterComponent={(props) => {
                  const rij: IOphalenAanmaningenResultElement = props.row;
                  return <span>{rij.facturen.length}</span>;
                }}
              />

              <DataTypeProvider
                for={['__status']}
                formatterComponent={(props) => {
                  const rij: IOphalenAanmaningenResultElement = props.row;

                  if (!rij.Definitief) {
                    return <span style={{ color: EKleur.Blauw }}>Voorstel</span>;
                  }

                  return <span>Definitief</span>;
                }}
              />

              <DataTypeProvider
                for={[nameOf<IOphalenAanmaningenResultElement>('Vervaldatum')]}
                formatterComponent={(props) => {
                  const rij: IOphalenAanmaningenResultElement = props.row;
                  if (rij.Vervaldatum === null) {
                    return <span />;
                  }
                  return <span>{format(new Date(rij.Vervaldatum), 'dd-MM-yyyy')}</span>;
                }}
              />

              <EditingState
                onCommitChanges={async (changes) => {
                  if (changes.deleted === undefined) {
                    return;
                  }
                  const deleted = changes.deleted;
                  const id = deleted[deleted.length - 1] as number;
                  await handleVerwijderen([id]);
                }}
                onEditingRowIdsChange={(rowIds) => {
                  const id = rowIds[rowIds.length - 1] as number;
                }}
              />

              <SortingState defaultSorting={[]} />
              <IntegratedSorting />

              <RowDetailState defaultExpandedRowIds={[]} />

              <VirtualTable messages={geenData} columnExtensions={kolomExtensies} />
              <TableColumnResizing defaultColumnWidths={kolomBreedtes} />

              <TableEditColumn
                width={35}
                // showEditCommand
                showDeleteCommand
                cellComponent={DXTableEditColumnCellComponent}
                commandComponent={DXTableEditColumnCommandComponent}
              />

              <TableHeaderRow showSortingControls />
              <TableRowDetail
                contentComponent={DetailComp}
                toggleCellComponent={DXTableToggleCellComponent}
              />
              <SelectionState
                selection={props.selectie}
                onSelectionChange={(x) => props.onSelectieChange(x as number[])}
              />
              <TableSelection cellComponent={DXTableCheckboxComponent} />
            </Grid>
          </GridStyleWrapper>
        </>
      )}
      {/* {props.wijzigenBetalingsregelingDialoogState !== null && (
        <WijzigenDialoog
          open
          betRglID={props.wijzigenBetalingsregelingDialoogState.ID}
          onSuccess={async () => {
            props.onWijzigenBetalingsregelingDialoogStateChange(null);
            props.onVerversenAangevraagd();
          }}
          onAnnuleren={() => props.onWijzigenBetalingsregelingDialoogStateChange(null)}
        />
      )} */}
      {versturenAanmaningenDialoogState && (
        <KeuzeDialoog
          titel="Aanmaningen versturen"
          type={EKeuzeType.Enkel}
          opties={kanaalOpties}
          open
          onSuccess={handleVersturen}
          onAnnuleren={() => setVersturenAanmaningenDialoogState(null)}
          // meerOptions={{
          //   beginSelectie: [EKanaal.Email],
          // }}
        />
      )}
    </>
  );
});

export default AanmaningenTabel;
