import api from '../../..';
import {
  IOphalenNiveausParams,
  IOphalenNiveausResult,
} from '../../../../../../shared/src/api/v2/service/niveau';
import ICheckData from '../../../../../../shared/src/models/ICheckData';

const niveau = {
  ophalenNiveaus: async (params: IOphalenNiveausParams): Promise<IOphalenNiveausResult> => {
    return await api.post('v2/service/niveau/ophalen-niveaus', params);
  },
};

export default niveau;
