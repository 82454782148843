import {
  IMarkerenUitstaandParams,
  IMarkerenUitstaandResult,
} from '../../../../../../shared/src/api/v2/inkoop/opdracht/afgehandeld';
import ICheckData from '../../../../../../shared/src/models/ICheckData';
import api from '../../../index';

const afgehandeld = {
  markerenUitstaand: async (
    params: IMarkerenUitstaandParams,
  ): Promise<IMarkerenUitstaandResult> => {
    return await api.post('v2/inkoop/opdracht/afgehandeld/markeren-uitstaand', params);
  },
};

export default afgehandeld;
