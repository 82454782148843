import api from '../../../..';
import {
  IOphalenProductenUitBedrijfParams,
  IOphalenProductenUitBedrijfResult,
  IDeactiverenProductenParams,
  IDeactiverenProductenResult,
  IOngedaanMakenDeactiverenProductenParams,
  IOngedaanMakenDeactiverenProductenResult,
} from '../../../../../../../shared/src/api/v2/boekhouding/activaregister/deactiveren';
import ICheckData from '../../../../../../../shared/src/models/ICheckData';

const deactiveren = {
  ophalenProductenUitBedrijf: async (
    params: IOphalenProductenUitBedrijfParams,
  ): Promise<IOphalenProductenUitBedrijfResult> => {
    return await api.post(
      '/v2/boekhouding/activaregister/deactiveren/ophalen-producten-uit-bedrijf',
      params,
    );
  },
  checkDeactiverenProducten: async (params: IDeactiverenProductenParams): Promise<ICheckData> => {
    return await api.post(
      '/v2/boekhouding/activaregister/deactiveren/check-deactiveren-producten',
      params,
    );
  },
  deactiverenProducten: async (
    params: IDeactiverenProductenParams,
  ): Promise<IDeactiverenProductenResult> => {
    return await api.post(
      '/v2/boekhouding/activaregister/deactiveren/deactiveren-producten',
      params,
    );
  },
  checkOngedaanMakenDeactiverenProducten: async (
    params: IOngedaanMakenDeactiverenProductenParams,
  ): Promise<ICheckData> => {
    return await api.post(
      '/v2/boekhouding/activaregister/deactiveren/check-ongedaan-maken-deactiveren-producten',
      params,
    );
  },
  ongedaanMakenDeactiverenProducten: async (
    params: IOngedaanMakenDeactiverenProductenParams,
  ): Promise<IOngedaanMakenDeactiverenProductenResult> => {
    return await api.post(
      '/v2/boekhouding/activaregister/deactiveren/ongedaan-maken-deactiveren-producten',
      params,
    );
  },
};

export default deactiveren;
