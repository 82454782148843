import api from '../../index';
import {
  IOphalenProductenParams,
  IOphalenProductstatussenParams,
  IOphalenProductstatussenResult,
  IWijzigenProductParams,
  IWijzigenProductResult,
  IOphalenRedenenUitBedrijfParams,
  IOphalenRedenenUitBedrijfResult,
  IWijzigenLocatieVanProductenParams,
  IWijzigenLocatieVanProductenResult,
  IOphalenProductenParamsV2,
  IOphalenProductenResultV2,
  IOphalenMutatiesReferentiecodeParams,
  IOphalenMutatiesReferentiecodeResult,
  IBijwerkenLijstRecentParams,
  IOphalenLijstRecentParams,
  IOphalenLijstRecentResult,
  IMarkerenUitBedrijfParams,
  IMarkerenUitBedrijfResult,
  IMarkerenInBedrijfParams,
  IMarkerenInBedrijfResult,
  IOphalenInkoopfacturenVoorProductenParams,
  IOphalenInkoopfacturenVoorProductenResult,
  IToevoegenProductParams,
  IToevoegenProductResult,
} from '../../../../../shared/src/api/v2/product';

import soort from './soort';
import merk from './merk';
import type from './type';
import model from './model';
import verkoop from './verkoop';
import documentatie from './documentatie';
import advies from './advies';

import ICheckData from '../../../../../shared/src/models/ICheckData';
import energielabel from './energielabel';
import { IPotentieelOntvangenInslagReferentieItem } from '../../../../../shared/src/api/v2/externeData';
import { ILeegGeneriekResult } from '../../../../../shared/src/api/v2/generiek';

const product = {
  energielabel,
  documentatie,
  verkoop,
  model,
  soort,
  merk,
  type,
  advies,
  // ophalenProducten: async (params: IOphalenProductenParams): Promise<IOphalenProductenResult> => {
  //   return await api.post('v2/product/ophalen-producten', params);
  // },
  ophalenProductenV2: async (
    params: IOphalenProductenParamsV2,
  ): Promise<IOphalenProductenResultV2> => {
    return await api.post('v2/product/ophalen-producten-v2', params);
  },
  ophalenProductstatussen: async (
    params: IOphalenProductstatussenParams,
  ): Promise<IOphalenProductstatussenResult> => {
    return await api.post('v2/product/ophalen-productstatussen', params);
  },
  checkWijzigenProduct: async (params: IWijzigenProductParams): Promise<ICheckData> => {
    return await api.post('v2/product/check-wijzigen-product', params);
  },
  wijzigenProduct: async (params: IWijzigenProductParams): Promise<IWijzigenProductResult> => {
    return await api.post('v2/product/wijzigen-product', params);
  },
  ophalenRedenenUitBedrijf: async (
    params: IOphalenRedenenUitBedrijfParams,
  ): Promise<IOphalenRedenenUitBedrijfResult> => {
    return await api.post('v2/product/ophalen-redenen-uit-bedrijf', params);
  },
  wijzigenLocatieVanProducten: async (
    params: IWijzigenLocatieVanProductenParams,
  ): Promise<IWijzigenLocatieVanProductenResult> => {
    return await api.post('v2/product/wijzigen-locatie-van-producten', params);
  },
  ophalenMutatiesReferentiecode: async (
    params: IOphalenMutatiesReferentiecodeParams,
  ): Promise<IOphalenMutatiesReferentiecodeResult> => {
    return await api.post('v2/product/ophalen-mutaties-referentiecode', params);
  },
  koppelenInslagReferentie: async (params: IPotentieelOntvangenInslagReferentieItem) => {
    return await api.post<ILeegGeneriekResult>('/v2/product/koppelen-inslag-referentie', params);
  },
  bijwerkenLijstRecent: async (params: IBijwerkenLijstRecentParams) => {
    return await api.post('v2/product/bijwerken-lijst-recent', params);
  },
  ophalenLijstRecent: async (params: IOphalenLijstRecentParams) => {
    return await api.post<IOphalenLijstRecentResult>('/v2/product/ophalen-lijst-recent', params);
  },
  checkMarkerenUitBedrijf: async (params: IMarkerenUitBedrijfParams) => {
    return await api.post<ICheckData>('/v2/product/check-markeren-uit-bedrijf', params);
  },
  markerenUitBedrijf: async (params: IMarkerenUitBedrijfParams) => {
    return await api.post<IMarkerenUitBedrijfResult>('/v2/product/markeren-uit-bedrijf', params);
  },
  checkMarkerenInBedrijf: async (params: IMarkerenInBedrijfParams) => {
    return await api.post<ICheckData>('/v2/product/check-markeren-in-bedrijf', params);
  },
  markerenInBedrijf: async (params: IMarkerenInBedrijfParams) => {
    return await api.post<IMarkerenInBedrijfResult>('/v2/product/markeren-in-bedrijf', params);
  },
  ophalenInkoopfacturenVoorProducten: async (params: IOphalenInkoopfacturenVoorProductenParams) => {
    return await api.post<IOphalenInkoopfacturenVoorProductenResult>(
      '/v2/product/ophalen-inkoopfacturen-voor-producten',
      params,
    );
  },
  checkToevoegenProduct: async (params: IToevoegenProductParams) => {
    return await api.post<ICheckData>('/v2/product/check-toevoegen-product', params);
  },
  toevoegenProduct: async (params: IToevoegenProductParams) => {
    return await api.post<IToevoegenProductResult>('/v2/product/toevoegen-product', params);
  },
};

export default product;
