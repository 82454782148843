import { Formik } from 'formik';
import React, { useCallback, useContext, useState, useMemo, useEffect } from 'react';
import { ModalFooter, ModalTitle } from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/ModalHeader';
import api from '../../../../../api';
import IDialoogProps from '../../../../../core/IDialoogProps';
import Dialoog from '../../../../dialogen/Dialoog';
import LoadingSpinner from '../../../../Gedeeld/LoadingSpinner';
import Tabblad, { ITabblad } from '../../../../layout/Tabblad';
import { IActie } from '../../../../../../../shared/src/api/v2/aanbod/tarieven/acties';
import ActieTab from './ActieTab';

interface IProps extends IDialoogProps<{}> {
  id: number;
}

export interface ITabbladProps {}

export interface IContext {
  tariefactie: IActie | null;
}

export const TariefactieContext = React.createContext<IContext>({
  tariefactie: null,
});

enum ETablad {
  Actie,
  Detail,
}

export interface IFormikValues {
  naam: string;
  nummer: string;
}

const TariefactieInfoDialoog: React.FC<IProps> = (props) => {
  const { dialoogIndex, onAnnuleren, onSuccess, open } = props;
  const [tabblad, setTabblad] = useState<ETablad>(ETablad.Actie);

  const [tariefactie, setTariefactie] = useState<IActie | null>(null);

  const ophalenTariefactie = useCallback(async () => {
    const result = await api.v2.aanbod.tarieven.acties.ophalenActies({
      filterSchema: {
        filters: [{ naam: 'IDS', data: [props.id] }],
      },
    });

    setTariefactie(result.acties[0]);
  }, []);

  useEffect(() => {
    ophalenTariefactie();
  }, [ophalenTariefactie]);

  const tabbladen = useMemo<ITabblad<ETablad>[]>(
    () => [
      {
        id: ETablad.Actie,
        label: 'Actie',
        content: ActieTab,
      },
      {
        id: ETablad.Detail,
        label: 'Overig',
        content: () => <span />, //DetailTab,
      },
    ],
    [],
  );

  const initialvalues = useMemo<IFormikValues | null>(() => {
    if (tariefactie === null) {
      return null;
    }

    return {
      naam: tariefactie.Naam!,
      nummer: tariefactie.Nummer!.toString(),
    };
  }, [tariefactie]);

  return (
    <Dialoog index={dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Tariefactie info</ModalTitle>
      </ModalHeader>

      <div className="d-flex flex-column flex-fill" style={{ minHeight: `500px` }}>
        {initialvalues === null ? (
          <div className="d-flex flex-fill align-items-center justify-content-center">
            <LoadingSpinner />
          </div>
        ) : (
          <>
            <TariefactieContext.Provider
              value={{
                tariefactie,
              }}
            >
              <Formik<IFormikValues>
                isInitialValid
                initialValues={initialvalues}
                onSubmit={() => null}
                render={(FormikProps) => {
                  const { submitForm, isSubmitting, values, errors } = FormikProps;
                  return (
                    <>
                      <div className="d-flex flex-column flex-fill">
                        <Tabblad
                          tabbladen={tabbladen}
                          geselecteerd={tabblad}
                          onSelectieChange={(id) => {
                            setTabblad(id);
                          }}
                          options={{
                            tabbladComponentProps: {
                              onAnnuleren: props.onAnnuleren,
                            },
                          }}
                        />
                      </div>
                      <ModalFooter className="d-flex flex-row justify-content-start">
                        <button
                          className="btn btn-primary"
                          onClick={onAnnuleren}
                          style={{ width: 100 }}
                        >
                          Sluiten
                        </button>
                      </ModalFooter>
                    </>
                  );
                }}
              />
            </TariefactieContext.Provider>
          </>
        )}
      </div>
    </Dialoog>
  );
};

export default TariefactieInfoDialoog;
