import React, { useCallback, useMemo } from 'react';
import { ESoort, IRow } from '../../index';
import { IRegelVerkoop } from '../../../../../../../shared/src/api/v2/winkelwagen';
import {
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../../helpers/dxTableGrid';
import {
  Grid,
  Table,
  TableColumnResizing,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-bootstrap4';
import { DataTypeProvider, VirtualTable as VirtualTableBase } from '@devexpress/dx-react-grid';
import FormatteerBedrag from '../../../../MutatieBedrag';

interface IProps {
  row: IRow;
}

const Verkoopmodel: React.FC<IProps> = (props) => {
  const verkoopmodelregels = (props.row.regels as IRegelVerkoop[]).filter(
    (x) => x.Soort === ESoort.Verkoopmodel,
  );

  const keyExtractor = useCallback((row: IRegelVerkoop) => row.ID, []);
  const kolommen = useMemo<TypedColumn<IRegelVerkoop>[]>(
    () => [
      {
        name: '__afbeelding',
        title: 'Afb.',
      },
      {
        name: '__naam' as any,
        title: 'Accessoire',
      },
      {
        name: '__prijsPerEenheid' as any,
        title: 'Prijs/stuk',
      },
      {
        name: '__aantal' as any,
        title: 'Aantal',
      },
      {
        name: '__prijs' as any,
        title: 'Totaalprijs',
      },
    ],
    [],
  );
  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRegelVerkoop>[]>(
    () => [
      {
        columnName: '__naam' as any,
        width: 300,
      },
      {
        columnName: '__afbeelding' as any,
        width: 70,
      },
      {
        columnName: '__prijsPerEenheid' as any,
        width: 85,
      },
      {
        columnName: '__prijs' as any,
        width: 100,
      },
      {
        columnName: '__aantal' as any,
        width: 80,
      },
    ],
    [],
  );
  const kolomExtensies: VirtualTableBase.ColumnExtension[] = useMemo(() => {
    return [
      {
        columnName: '__prijsPerEenheid' as any,
        align: 'right',
      },
      {
        columnName: '__prijs' as any,
        align: 'right',
      },
      {
        columnName: '__aantal' as any,
        align: 'right',
      },
    ];
  }, []);

  return (
    <GridStyleWrapper rowAmount={verkoopmodelregels.length} maxHeight={500}>
      <Grid rows={verkoopmodelregels} columns={kolommen} getRowId={keyExtractor}>
        <DataTypeProvider
          for={['__naam']}
          formatterComponent={(formatterProps) => {
            const regel: IRegelVerkoop = formatterProps.row;

            return <span>{regel.Verkoopmodelnaam}</span>;
          }}
        />
        <DataTypeProvider
          for={['__prijsPerEenheid']}
          formatterComponent={(formatterProps) => {
            const regel: IRegelVerkoop = formatterProps.row;

            return <FormatteerBedrag bedrag={regel.PrijsPerEenheid} rechtsUitlijnen />;
          }}
        />
        <DataTypeProvider
          for={['__prijs']}
          formatterComponent={(formatterProps) => {
            const regel: IRegelVerkoop = formatterProps.row;

            return <FormatteerBedrag bedrag={regel.Prijs} rechtsUitlijnen />;
          }}
        />
        <DataTypeProvider
          for={['__aantal']}
          formatterComponent={(formatterProps) => {
            const regel: IRegelVerkoop = formatterProps.row;

            return <span>{regel.Aantal}</span>;
          }}
        />

        <DataTypeProvider
          for={['__afbeelding']}
          formatterComponent={(formatterProps) => {
            const regel: IRegelVerkoop = formatterProps.row;

            // TODO: Gilbert moet naar bestand IOphalenBestandenResultElement
            if (regel.AfbeeldingToken === null) {
              return <span></span>;
            }
            return <img src={'TODO'} style={{ maxHeight: 20 }} />;
          }}
        />

        <Table
          columnExtensions={kolomExtensies}
          messages={{
            noData: 'Geen accessoires',
          }}
        />
        <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
        <TableHeaderRow />
      </Grid>
    </GridStyleWrapper>
  );
};

export default Verkoopmodel;
