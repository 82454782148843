import React, { useCallback, useMemo, useState, useContext, useEffect, useRef } from 'react';
import api from '../../../../api';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import useUrlState from '../../../../core/useUrlState';
import UitstaandTabel, {
  IOpdrachtEventsDialoogState,
  IAfmeldenOpdrachtDialoogState,
  IBezoektijdenDialoogState,
  IVersturenOpdrachtenDialoogState,
  IWijzigenOpdrachtDialoogState,
  IVersturenHerinneringenDialoogState,
  IOpdracht,
  ITransportopdrachtInfoDialoogState,
} from '../../../../components/transport/UitstaandTabel';
import { selectieCheck } from '../../../../helpers/selectie';
import FilterBalkV2, {
  IFilter,
  IFilterData,
  maakFilterSchema,
} from '../../../../components/FilterBalkV2';
import { dagDatum } from '../../../../helpers/datum';
import { IFilterSchema } from '../../../../../../shared/src/models/filter';
import DatumKiezer from '../../../../components/formulier/DatumKiezer';
import { addDays, addWeeks } from 'date-fns';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import { EOpdrachtstatusTransport, ERegelstatusTransport } from '../../../../bedrijfslogica/enums';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import {
  IOphalenOpdrachtenResultElementV2,
  IOphalenOpdrachtregelsResultElementV2,
} from '../../../../../../shared/src/api/v2/transport/opdracht';
import { IOphalenRelatiesResultElementV2 } from '../../../../../../shared/src/api/v2/relatie';
import _ from 'lodash';
import { IOphalenTransportopdrachtenMetGewijzigdeBezoektijdenResult } from '../../../../../../shared/src/api/v2/transport/bezoek';

enum EFilter {
  Bezoekdatum = 'BEZOEKDATUM',
}

interface IProps extends RouteComponentProps {}

export interface ITransportOpdrachtenUitstaandUrlState {
  opdrachtenSelectie: number[];
  uitgeklapteOpdrachten: number[];
  regelsSelectie: number[];
  opdrachtEventsDialoogState: IOpdrachtEventsDialoogState | null;
  afmeldenDialoogState: IAfmeldenOpdrachtDialoogState | null;
  bezoektijdenDialoogState: IBezoektijdenDialoogState | null;
  versturenOpdrachtenDialoogState: IVersturenOpdrachtenDialoogState | null;
  wijzigenOpdrachtDialoogState: IWijzigenOpdrachtDialoogState | null;
  versturenHerinneringenDialoogState: IVersturenHerinneringenDialoogState | null;
  transportopdrachtInfoDialoogState: ITransportopdrachtInfoDialoogState | null;
  filterData: IFilterData<EFilter>[] | null;
}

export const defaultTransportOpdrachtenUitstaandUrlState: ITransportOpdrachtenUitstaandUrlState = {
  opdrachtenSelectie: [],
  uitgeklapteOpdrachten: [],
  regelsSelectie: [],
  opdrachtEventsDialoogState: null,
  afmeldenDialoogState: null,
  bezoektijdenDialoogState: null,
  versturenOpdrachtenDialoogState: null,
  wijzigenOpdrachtDialoogState: null,
  versturenHerinneringenDialoogState: null,
  transportopdrachtInfoDialoogState: null,
  filterData: [],
};

export interface IWrapperOutput {
  initieelBezoekdatumFilter: Date;
}

const Uitstaand: React.FC<IProps & IWrapperOutput> = (props) => {
  const [opdrachten, setOpdrachten] = useState<IOpdracht[] | null>(null);
  // const [standaardBezoekdatum, setStandaardBezoekdatum] = useState<Date | null>(null);
  const defaultUrlStateMetBezoekdatumFilter = useMemo<ITransportOpdrachtenUitstaandUrlState>(
    () => ({
      ...defaultTransportOpdrachtenUitstaandUrlState,
      filterData: [
        {
          naam: EFilter.Bezoekdatum,
          isActief: true,
          data: dagDatum(props.initieelBezoekdatumFilter),
        },
      ],
    }),
    [props.initieelBezoekdatumFilter],
  );
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(
    props,
    defaultUrlStateMetBezoekdatumFilter,
  );
  const [filterSchema, setFilterSchema] = useState<IFilterSchema | null>(
    urlState.filterData === null ? null : maakFilterSchema(urlState.filterData),
  );

  // useEffect(() => {
  //   if (urlState.filterData !== null) {
  //     return;
  //   }
  //
  //   (async () => {
  //     const result = await api.v2.datum.ophalenWerkdagen({
  //       datumVan: new Date(),
  //       datumTot: addWeeks(new Date(), 7),
  //     });
  //
  //     const eersteVolgendeDatum = result[0];
  //
  //     if (eersteVolgendeDatum === undefined) {
  //       throw new Error('Kan geen eerst volgende werkdag bepalen');
  //     }
  //     const filterData = [
  //       {
  //         naam: EFilter.Bezoekdatum,
  //         isActief: true,
  //         data: dagDatum(new Date(eersteVolgendeDatum)),
  //       },
  //     ];
  //     setUrlStateSync('filterData', filterData);
  //     setFilterSchema(maakFilterSchema(filterData));
  //   })();
  // }, []);

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.Bezoekdatum,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return (
            <div className="d-flex align-items-center">
              <span>Bezoekdatum</span>
              <span className="ml-2">
                <DatumKiezer
                  waarde={weergaveProps.data === null ? null : new Date(weergaveProps.data)}
                  onGewijzigd={(x) => {
                    weergaveProps.onDataChange(x === null ? null : x.toISOString());
                    weergaveProps.toepassen();
                  }}
                  determineValidDate={(date) => {
                    return date >= addDays(new Date(), -7);
                  }}
                  determineNextValidDate="ONBEGRENST"
                  determinePreviousValidDate={(date) => {
                    const minDate = addDays(new Date(), -7);
                    const newDate = addDays(date, -1);
                    if (newDate >= minDate) {
                      return newDate;
                    }
                    return null;
                  }}
                />
              </span>
            </div>
          );
        },
      },
    ],
    [],
  );

  const ophalenOpdrachtenAbortController = useRef<AbortController | null>(null);
  const ophalenOpdrachten = useCallback(async () => {
    if (filterSchema === null) {
      return;
    }
    if (ophalenOpdrachtenAbortController.current !== null) {
      ophalenOpdrachtenAbortController.current?.abort();
    }

    ophalenOpdrachtenAbortController.current = new AbortController();

    let opdrachtenResult: IOphalenOpdrachtenResultElementV2[];
    try {
      const ophalenOpdrachtenResult = await api.v2.transport.opdracht.ophalenOpdrachtenV2(
        {
          filterSchema: {
            filters: [
              ...filterSchema.filters!,
              { naam: 'STATUSSEN', data: [EOpdrachtstatusTransport.Uitstaand] },
            ].filter((x) => x !== null),
          },
        },
        ophalenOpdrachtenAbortController.current?.signal,
      );
      opdrachtenResult = ophalenOpdrachtenResult.opdrachten;
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      }
      throw err;
    }

    if (opdrachtenResult.length === 0) {
      setOpdrachten([]);
      return;
    }

    const trsOpdIDs = opdrachtenResult.map((x) => x.TrsOpdID);

    let opdrachtregelsResult: IOphalenOpdrachtregelsResultElementV2[];
    try {
      const result = await api.v2.transport.opdracht.ophalenOpdrachtregelsV2(
        {
          filterSchema: {
            filters: [{ naam: 'TRSOPD_IDS', data: trsOpdIDs }],
          },
          orderSchema: {
            orders: [
              {
                naam: 'REGELNUMMER',
                richting: 'ASC',
              },
            ],
          },
        },
        ophalenOpdrachtenAbortController.current?.signal,
      );
      opdrachtregelsResult = result.regels;
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      }
      throw err;
    }

    const relIDs = opdrachtregelsResult.filter((x) => x.RelID !== null).map((x) => x.RelID);

    let relatiesResult: IOphalenRelatiesResultElementV2[];
    try {
      const result = await api.v2.relatie.ophalenRelaties(
        {
          filterSchema: { filters: [{ naam: 'IDS', data: relIDs }] },
        },
        ophalenOpdrachtenAbortController.current?.signal,
      );
      relatiesResult = result.relaties;
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      }
      throw err;
    }

    const opdrachten = opdrachtenResult.map((opdracht) => {
      const regelsVoorOpdracht = opdrachtregelsResult.filter(
        (x) => x.TrsOpdID === opdracht.TrsOpdID,
      );

      const regels = regelsVoorOpdracht.map((regel) => {
        const relatie =
          regel.RelID !== null ? relatiesResult.find((x) => x.RelID === regel.RelID)! : null;
        return { ...regel, relatie };
      });

      return { ...opdracht, regels };
    });

    {
      const selectie = selectieCheck(
        opdrachten.map((x) => x.TrsOpdID),
        urlState.opdrachtenSelectie,
      );
      if (selectie !== null) {
        setUrlStateSync('opdrachtenSelectie', selectie);
      }
    }
    {
      const selectie = selectieCheck(
        opdrachten.map((x) => x.regels.map((x) => x.TrsRegID)).flat(),
        urlState.regelsSelectie,
      );
      if (selectie !== null) {
        setUrlStateSync('regelsSelectie', selectie);
      }
    }
    setOpdrachten(opdrachten);
  }, [
    JSON.stringify(urlState.opdrachtenSelectie),
    JSON.stringify(urlState.regelsSelectie),
    filterSchema,
  ]);

  useEffect(() => {
    ophalenOpdrachten();

    return () => ophalenOpdrachtenAbortController.current?.abort();
  }, [ophalenOpdrachten]);

  const [
    transportopdrachtenMetGewijzigdeBezoektijden,
    setTransportopdrachtenMetGewijzigdeBezoektijden,
  ] = useState<IOphalenTransportopdrachtenMetGewijzigdeBezoektijdenResult | null>(null);

  const ophalenTransportopdrachtenMetGewijzigdeBezoektijden = useCallback(async () => {
    if (opdrachten === null) {
      return;
    }
    const trsOpdIDs = opdrachten.map((x) => x.TrsOpdID);

    const opdrachtenResult = await api.v2.transport.bezoek.ophalenTransportopdrachtenMetGewijzigdeBezoektijden(
      {
        filterSchema: {
          filters: [{ naam: 'TRSOPD_IDS', data: trsOpdIDs }],
        },
      },
    );

    setTransportopdrachtenMetGewijzigdeBezoektijden(opdrachtenResult);
  }, [opdrachten]);

  useEffect(() => {
    ophalenTransportopdrachtenMetGewijzigdeBezoektijden();
  }, [ophalenTransportopdrachtenMetGewijzigdeBezoektijden]);

  return (
    <>
      <Helmet>
        <title>Uitstaand - Transport</title>
      </Helmet>
      <UitstaandTabel
        opdrachten={opdrachten}
        opdrachtenMetGewijzigdeBezoektijden={transportopdrachtenMetGewijzigdeBezoektijden}
        opdrachtenSelectie={urlState.opdrachtenSelectie}
        onOpdrachtenSelectieChange={(value: number[]) =>
          setUrlStateSync('opdrachtenSelectie', value)
        }
        uitgeklapteOpdrachten={urlState.uitgeklapteOpdrachten}
        onUitgeklapteOpdrachtenChange={(value: number[]) =>
          setUrlStateSync('uitgeklapteOpdrachten', value)
        }
        regelsSelectie={urlState.regelsSelectie}
        onRegelsSelectieChange={(value: number[]) => setUrlStateSync('regelsSelectie', value)}
        opdrachtEventsDialoogState={urlState.opdrachtEventsDialoogState}
        onOpdrachtEventsDialoogStateChange={(value: IOpdrachtEventsDialoogState | null) =>
          setUrlStateSync('opdrachtEventsDialoogState', value)
        }
        afmeldenDialoogState={urlState.afmeldenDialoogState}
        onAfmeldenDialoogStateChange={(value: IAfmeldenOpdrachtDialoogState | null) =>
          setUrlStateSync('afmeldenDialoogState', value)
        }
        bezoektijdenDialoogState={urlState.bezoektijdenDialoogState}
        onBezoektijdenDialoogStateChange={(value: IBezoektijdenDialoogState | null) =>
          setUrlStateSync('bezoektijdenDialoogState', value)
        }
        versturenOpdrachtenDialoogState={urlState.versturenOpdrachtenDialoogState}
        onVersturenOpdrachtenDialoogStateChange={(value: IVersturenOpdrachtenDialoogState | null) =>
          setUrlStateSync('versturenOpdrachtenDialoogState', value)
        }
        wijzigenOpdrachtDialoogState={urlState.wijzigenOpdrachtDialoogState}
        onWijzigenOpdrachtDialoogStateChange={(value: IWijzigenOpdrachtDialoogState | null) =>
          setUrlStateSync('wijzigenOpdrachtDialoogState', value)
        }
        versturenHerinneringenDialoogState={urlState.versturenHerinneringenDialoogState}
        onVersturenHerinneringenDialoogStateChange={(
          value: IVersturenHerinneringenDialoogState | null,
        ) => setUrlStateSync('versturenHerinneringenDialoogState', value)}
        onRequestRefresh={() => ophalenOpdrachten()}
        transportopdrachtInfoDialoogState={urlState.transportopdrachtInfoDialoogState}
        onTransportopdrachtInfoDialoogStateChange={(
          value: ITransportopdrachtInfoDialoogState | null,
        ) => setUrlStateSync('transportopdrachtInfoDialoogState', value)}
        filterbalk={
          urlState.filterData === null ? (
            <LoadingSpinner />
          ) : (
            <FilterBalkV2
              filters={filters}
              filterData={urlState.filterData}
              onFilterDataChange={(x) => setUrlStateSync('filterData', x)}
              onFilterSchemaChange={(x) => setFilterSchema(x)}
            />
          )
        }
      />
    </>
  );
};

const UitstaandWithRouter = withRouter(Uitstaand);

const UitstaandWrapper: React.FC<IProps> = (props) => {
  const [oudsteOpenstaandeBezoekdatum, setOudsteOpenstaandeBezoekdatum] = useState<Date | null>(
    null,
  );

  useEffect(() => {
    (async () => {
      const opdrachtenResult = await api.v2.transport.opdracht.ophalenOpdrachtenV2({
        paginatie: {
          index: 0,
          aantal: 1,
        },
        orderSchema: {
          orders: [
            {
              naam: 'BEZOEKDATUM',
              richting: 'ASC',
            },
          ],
        },
        filterSchema: {
          filters: [
            {
              naam: 'BEZOEKDATUM',
              data: null,
              inverteren: true,
            },
            { naam: 'STATUSSEN', data: [EOpdrachtstatusTransport.Uitstaand] },
          ],
        },
      });

      const opdracht = opdrachtenResult.opdrachten[0];
      if (opdracht === undefined) {
        const result = await api.v2.datum.ophalenWerkdagen({
          datumVan: new Date(),
          datumTot: addWeeks(new Date(), 7),
        });

        setOudsteOpenstaandeBezoekdatum(new Date(result[0]));
        return;
      }
      setOudsteOpenstaandeBezoekdatum(new Date(opdracht.Bezoekdatum!));
    })();
  }, []);

  if (oudsteOpenstaandeBezoekdatum === null) {
    return (
      <div className="flex-fill d-flex align-items-center justify-content-center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <UitstaandWithRouter {...props} initieelBezoekdatumFilter={oudsteOpenstaandeBezoekdatum} />
  );
};

export default UitstaandWrapper;
