import api from '../../../index';
import {
  IOphalenHoedanighedenParams,
  IOphalenHoedanighedenResult,
  IToevoegenRelatieHoedanigheidParams,
  IToevoegenRelatieHoedanigheidResult,
} from '../../../../../../shared/src/api/v2/relatie/hoedanigheid';
import ICheckData from '../../../../../../shared/src/models/ICheckData';

const hoedanigheid = {
  ophalenHoedanigheden: async (
    params: IOphalenHoedanighedenParams,
  ): Promise<IOphalenHoedanighedenResult> => {
    return await api.post('v2/relatie/hoedanigheid/ophalen-hoedanigheden', params);
  },

  checkToevoegenRelatieHoedanigheid: async (
    params: IToevoegenRelatieHoedanigheidParams,
  ): Promise<ICheckData> => {
    return await api.post('v2/relatie/hoedanigheid/check-toevoegen-hoedanigheid', params);
  },

  toevoegenRelatieHoedanigheid: async (
    params: IToevoegenRelatieHoedanigheidParams,
  ): Promise<IToevoegenRelatieHoedanigheidResult> => {
    return await api.post('v2/relatie/hoedanigheid/toevoegen-hoedanigheid', params);
  },
};

export default hoedanigheid;
