import { makeObservable, observable } from 'mobx';
import {
  IControlTaakNieuwMessageData,
  IControlTakenAfgehandeldMessageData,
  IOphalenControlTakenResultElement,
} from '../../../shared/src/api/v2/control/control';
import { RootStore } from './RootStore';
import api, { paginatiePositieOphogen } from '../api';
import IPaginatiePositie from '../../../shared/src/models/IPaginatiePositie';

class ControlStore {
  public controlTaken: IOphalenControlTakenResultElement[] | null = null;

  // tslint:disable-next-line:variable-name
  private _rootStore: RootStore;

  // tslint:disable-next-line:variable-name
  private _controlTakenPaginatiePositie: IPaginatiePositie = {
    aantal: 50,
    index: 0,
  };

  constructor(rootStore: RootStore) {
    this._rootStore = rootStore;

    makeObservable(this, {
      controlTaken: observable,
    });
  }

  public ophalenControlTaken = async () => {
    const result = await api.v2.control.ophalenControlTaken({
      paginatie: this._controlTakenPaginatiePositie,
    });

    this._controlTakenPaginatiePositie = paginatiePositieOphogen(
      this._controlTakenPaginatiePositie,
    );
    const huidigeControlTaken = this.controlTaken === null ? [] : this.controlTaken;
    this.controlTaken = [...huidigeControlTaken, ...result.taken];
  };

  public handleControlTaakNieuw = async (data: IControlTaakNieuwMessageData) => {
    if (
      this.controlTaken === null ||
      this.controlTaken.findIndex((ct) => ct.ID === data.controlTaak.ID) !== -1
    ) {
      return;
    }
    this.controlTaken = [data.controlTaak, ...this.controlTaken];
  };

  public handleControlTakenAfgehandeld = async (data: IControlTakenAfgehandeldMessageData) => {
    if (this.controlTaken === null) {
      return;
    }
    // Geen van deze control taken zitten bij ons in de lijst
    if (this.controlTaken.every((controlTaak) => data.ids.indexOf(controlTaak.ID) === -1)) {
      return;
    }
    this.controlTaken = this.controlTaken.filter(
      (controlTaak) => data.ids.indexOf(controlTaak.ID) === -1,
    );
  };
}

export default ControlStore;
