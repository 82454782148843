import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import IDialoogProps from '../../../core/IDialoogProps';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import nameOf from '../../../core/nameOf';
import * as Yup from 'yup';
import api from '../../../api';
import { IInstellenBezigheidParams } from '../../../../../shared/src/api/v2/gebruiker';
import VinkVeld from '../../../components/formulier/VinkVeld';
import Dialoog from '../../../components/dialogen/Dialoog';
import RelatieSelectie from '../../../components/formulier/RelatieSelectie';
import DatumKiezer from '../../../components/formulier/DatumKiezer';
import { dagDatum } from '../../../helpers/datum';
import Combobox from '../../../components/formulier/Combobox';
import { IOphalenEscalatiecontextenResultElement } from '../../../../../shared/src/api/v2/escalatie';
import LoadingSpinner from '../../../components/Gedeeld/LoadingSpinner';
import { EResultType } from '../../../stores/CheckStore';
import { RootStoreContext } from '../../../stores/RootStore';
import { addDays } from 'date-fns';

interface IDialoogResult {}

interface IFormikValues {
  ontstaansdatum: Date | null;
  relID: number | null;
  korteOmschrijving: string;
  soort: number | null;
}

interface IProps extends IDialoogProps<IDialoogResult> {
  initieelRelatie?: {
    relID: number;
    muterenViaFormulierToestaan: boolean;
  };
  aspGebrID?: number;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  ontstaansdatum: 'Ontstaansdatum',
  relID: 'Relatie',
  korteOmschrijving: 'Korte omschrijving',
  soort: 'Soort',
};

const ToevoegenEscalatieDialoog: React.FC<IProps> = (props) => {
  const { checkStore } = useContext(RootStoreContext);

  const [bezigheid, setBezigheid] = useState<IInstellenBezigheidParams | null>(null);

  const [escalatiecontexten, setEscalatiecontexten] = useState<
    IOphalenEscalatiecontextenResultElement[] | null
  >(null);

  const ophalenEscalatiecontexten = useCallback(async () => {
    const result = await api.v2.escalatie.ophalenEscalatiecontexten({
      orderSchema: { orders: [{ naam: 'NAAM', richting: 'ASC' }] },
    });
    setEscalatiecontexten(result.contexten);
  }, []);

  useEffect(() => {
    ophalenEscalatiecontexten();
  }, [ophalenEscalatiecontexten]);

  const initialValues = useMemo<IFormikValues>(
    () => ({
      relID: props.initieelRelatie !== undefined ? props.initieelRelatie.relID : null,
      ontstaansdatum: dagDatum(new Date()),
      korteOmschrijving: '',
      soort: null,
    }),
    [props.initieelRelatie],
  );
  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      const params = {
        relID: values.relID!,
        ontstaansdatum: values.ontstaansdatum,
        korteOmschrijving: values.korteOmschrijving !== '' ? values.korteOmschrijving : null,
        escalatiecontextID: values.soort,
      };

      actions.setSubmitting(true);

      const checkData = await api.v2.escalatie.checkToevoegenEscalatie(params);
      if (
        (
          await checkStore.controleren({
            checkData,
          })
        ).type === EResultType.Annuleren
      ) {
        actions.setSubmitting(false);
        return;
      }

      if (
        (
          await checkStore.bevestigen({
            inhoud: `Wil je de escalatie vastleggen?`,
          })
        ).type === EResultType.Annuleren
      ) {
        actions.setSubmitting(false);
        return;
      }

      const toevoegenResult = await api.v2.escalatie.toevoegenEscalatie(params);

      await props.onSuccess({});

      actions.setSubmitting(false);
    },
    [props.onSuccess, props.initieelRelatie, bezigheid],
  );

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      [nameOf<IFormikValues>('relID')]: Yup.number().required(),
      [nameOf<IFormikValues>('korteOmschrijving')]: Yup.string().required(),
    });
  }, []);

  const notitiesRef = useRef<HTMLTextAreaElement>(null);
  useEffect(() => {
    notitiesRef.current?.focus();
  }, []);

  return (
    <Dialoog index={props.dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Toevoegen escalatie</ModalTitle>
      </ModalHeader>
      <Formik<IFormikValues>
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
        render={(formikProps: FormikProps<IFormikValues>) => {
          const { isValid, isSubmitting, submitForm, values } = formikProps;
          const relatieVeldWeergeven =
            props.initieelRelatie === undefined ||
            props.initieelRelatie.muterenViaFormulierToestaan;

          return (
            <>
              <ModalBody>
                {escalatiecontexten !== null && (
                  <>
                    {' '}
                    <div className="row">
                      {relatieVeldWeergeven && (
                        <div className="col-12">
                          <label>{veldnamen.relID}</label>
                          <Field
                            name={nameOf<IFormikValues>('relID')}
                            render={(fieldProps: FieldProps<IFormikValues>) => {
                              const { field, form } = fieldProps;

                              return (
                                <RelatieSelectie
                                  relID={field.value}
                                  onChange={(x) => form.setFieldValue(field.name, x)}
                                  isWisbaar
                                />
                              );
                            }}
                          />
                        </div>
                      )}
                      <div className={`col-12 ${relatieVeldWeergeven ? 'mt-3' : ''}`}>
                        <label>{veldnamen.ontstaansdatum}</label>
                        <Field
                          name={nameOf<IFormikValues>('ontstaansdatum')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            const minimaleDatum = addDays(new Date(), -60);
                            const maximaleDatum = addDays(new Date(), +1);

                            return (
                              <DatumKiezer
                                isClearable
                                waarde={field.value}
                                onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                determineValidDate={(date) => {
                                  return date >= minimaleDatum && date <= maximaleDatum;
                                }}
                                determineNextValidDate={(date) => {
                                  const nieuweDatum = addDays(date, +1);
                                  if (nieuweDatum <= maximaleDatum) {
                                    return nieuweDatum;
                                  }
                                  return null;
                                }}
                                determinePreviousValidDate={(date) => {
                                  const nieuweDatum = addDays(date, -1);
                                  if (nieuweDatum >= minimaleDatum) {
                                    return nieuweDatum;
                                  }
                                  return null;
                                }}
                              />
                            );
                          }}
                        />
                      </div>
                      <div className="col-12 mt-3">
                        <label>{veldnamen.korteOmschrijving}</label>
                        <Field
                          name={nameOf<IFormikValues>('korteOmschrijving')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field } = fieldProps;

                            return (
                              <>
                                <textarea {...field} className="form-control" rows={1} />
                              </>
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <label>{veldnamen.soort}</label>
                        <Field
                          name={nameOf<IFormikValues>('soort')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            if (escalatiecontexten === null) {
                              return <LoadingSpinner />;
                            }

                            return (
                              <Combobox
                                isWisbaar
                                geselecteerd={field.value}
                                onSelectieChange={(x) => {
                                  form.setFieldValue(field.name, x);
                                }}
                                opties={escalatiecontexten.map((x) => {
                                  return {
                                    id: x.ID,
                                    label: x.Naam,
                                  };
                                })}
                              />
                            );
                          }}
                        />
                      </div>

                      {/*               
                  <div className="col-12 mt-3">
                    <label>Notities</label>
                    <Field
                      name={nameOf<IFormikValues>('notities')}
                      render={(fieldProps: FieldProps<IFormikValues>) => {
                        const { field, form } = fieldProps;

                        return (
                          <textarea
                            ref={notitiesRef}
                            {...field}
                            className="form-control"
                            rows={3}
                            placeholder={'Hier een verplicht een notitie invoeren'}
                          />
                        );
                      }}
                    />
                  </div> */}
                    </div>
                  </>
                )}
              </ModalBody>
              <ModalFooter className="d-flex flex-row justify-content-start">
                <button
                  className="btn btn-primary d-flex align-items-center justify-content-center"
                  onClick={submitForm}
                  style={{ width: 100 }}
                  disabled={isSubmitting || !isValid}
                >
                  Ok
                </button>
                <button
                  className="btn btn-secondary"
                  onClick={props.onAnnuleren}
                  style={{ width: 100 }}
                  disabled={isSubmitting}
                >
                  Annuleren
                </button>
              </ModalFooter>
            </>
          );
        }}
      />
    </Dialoog>
  );
};

export default ToevoegenEscalatieDialoog;
