import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../stores/RootStore';
import Dialoog from '../Dialoog';
import IDialoogProps from '../../../core/IDialoogProps';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import LoadingSpinner from '../../Gedeeld/LoadingSpinner';
import {
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../helpers/dxTableGrid';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  VirtualTable,
  Table,
} from '@devexpress/dx-react-grid-bootstrap4';
import { DataTypeProvider, IntegratedSorting, SortingState } from '@devexpress/dx-react-grid';
import { IOphalenGebruikersResultElement } from '../../../../../shared/src/api/v2/gebruiker';
import ModalFooter from 'react-bootstrap/ModalFooter';
import Avatar from '../../personalia/Avatar';
import { IOphalenPersonenResult } from '../../../../../shared/src/api/v2/persoon/persoon';
import api from '../../../api';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../models/IRemoteData';

export interface IASPGebruikerSelectieDialoogResult {
  aspGebrID: number;
}

interface IProps extends IDialoogProps<IASPGebruikerSelectieDialoogResult> {
  uitgeslotenAspGebrIDs?: number[];
}

const ASPGebruikerSelectieDialoog: React.FC<IProps> = observer((props) => {
  const { gebruikerStore } = useContext(RootStoreContext);

  const gebruikers = useMemo(
    () =>
      gebruikerStore.gebruikers === null
        ? null
        : props.uitgeslotenAspGebrIDs === undefined
        ? gebruikerStore.gebruikers
        : gebruikerStore.gebruikers.filter(
            (x) => props.uitgeslotenAspGebrIDs!.indexOf(x.AspGebrID) === -1,
          ),
    [gebruikerStore.gebruikers, props.uitgeslotenAspGebrIDs],
  );

  const [personen, setPersonen] = useState<IRemoteData<IOphalenPersonenResult | null>>(
    createPendingRemoteData(),
  );
  const ophalenPersonen = useCallback(async () => {
    if (gebruikers === null) {
      setPersonen(createPendingRemoteData());
      return;
    }
    const persIDs = gebruikers.map((x) => x.PersID).filter((x) => x !== null);
    if (persIDs.length === 0) {
      setPersonen(createReadyRemoteData(null));
      return;
    }

    const result = await api.v2.persoon.ophalenPersonen({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: persIDs,
          },
        ],
      },
    });
    setPersonen(createReadyRemoteData(result));
  }, [gebruikers]);
  useEffect(() => {
    ophalenPersonen();
  }, [ophalenPersonen]);

  const keyExtractor = useCallback((row: IOphalenGebruikersResultElement) => row.AspGebrID, []);
  const kolommen = useMemo<TypedColumn<IOphalenGebruikersResultElement>[]>(
    () => [
      {
        name: '__avatar',
        title: ' ',
      },
      {
        name: 'NaamKort',
        title: 'Naam',
      },
      {
        name: '__kies_actie' as any,
        title: ' ',
      },
    ],
    [],
  );
  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IOphalenGebruikersResultElement>[]>(
    () => [
      {
        columnName: '__avatar' as any,
        width: 50,
      },
      {
        columnName: 'NaamKort',
        width: 100,
      },
      {
        columnName: '__kies_actie' as any,
        width: 50,
      },
    ],
    [],
  );

  const onGekozen = useCallback((aspGebrID: number) => props.onSuccess({ aspGebrID }), [
    props.onSuccess,
  ]);

  return (
    <Dialoog index={props.dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Selecteer een gebruiker...</ModalTitle>
      </ModalHeader>
      <ModalBody className="p-0">
        {gebruikers === null ? (
          <LoadingSpinner />
        ) : (
          <GridStyleWrapper height={500}>
            <Grid getRowId={keyExtractor} columns={kolommen} rows={gebruikers}>
              <DataTypeProvider
                for={['__avatar']}
                formatterComponent={(props) => {
                  const row: IOphalenGebruikersResultElement = props.row;

                  const persoon =
                    row.PersID === null ||
                    personen.state === ERemoteDataState.Pending ||
                    personen.data === null
                      ? null
                      : personen.data!.personen.find((x) => x.PersID === row.PersID)!;

                  if (persoon !== null && persoon.profielAfbeelding !== null) {
                    return <Avatar imageSrc={persoon.profielAfbeelding.url} />;
                  }

                  return <Avatar>{row.NaamKort.slice(0, 1)}</Avatar>;
                  // const iconStyle: React.CSSProperties = {
                  //   width: 18,
                  //   height: 18,
                  //   fill: Kleur.Grijs,
                  // };
                  // const entiteit = props.row as TEntiteit;
                  // return (
                  //   <span className="ml-2">
                  //   {entiteit.Relatiesoort === 'P' ? (
                  //     <IconPersoon style={iconStyle} />
                  //   ) : (
                  //     <IconOrganisatie style={iconStyle} />
                  //   )}
                  // </span>
                  // );
                }}
              />

              <DataTypeProvider
                for={['__kies_actie']}
                formatterComponent={(formatterProps) => {
                  const row: IOphalenGebruikersResultElement = formatterProps.row;
                  return (
                    <a
                      href="#"
                      onClick={(ev) => {
                        ev.stopPropagation();
                        onGekozen(row.AspGebrID);
                      }}
                    >
                      Kies
                    </a>
                  );
                }}
              />

              <SortingState defaultSorting={[]} />
              <IntegratedSorting />
              <Table
                rowComponent={(rowProps) => (
                  <tr
                    {...rowProps}
                    // className=""
                    style={{
                      cursor: 'pointer',
                    }}
                    onDoubleClick={() => {
                      const row: IOphalenGebruikersResultElement = rowProps.row;
                      onGekozen(row.AspGebrID);
                    }}
                  >
                    {rowProps.children}
                  </tr>
                )}
              />

              <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
              <TableHeaderRow showSortingControls />
            </Grid>
          </GridStyleWrapper>
        )}
      </ModalBody>
      <ModalFooter className="d-flex justify-content-start mt-2">
        <button className="btn btn-secondary" style={{ width: 100 }} onClick={props.onAnnuleren}>
          Annuleren
        </button>
      </ModalFooter>
    </Dialoog>
  );
});

export default ASPGebruikerSelectieDialoog;
