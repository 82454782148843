import React, { useMemo } from 'react';
import { IconBellen, IconEmail, IconSMS, IconWhatsapp } from '../../../components/Icons';
import { Kleur } from '../../../bedrijfslogica/constanten';
import HorizontaleScheidingslijn from '../../../components/layout/HorizontaleScheidingslijn';
import styled from 'styled-components';
import { setCommunicatieOverlayState } from '../../../one-off-components/CommunicatieOverlay';
import { ECommunicatieTabblad } from '../../../one-off-components/CommunicatieOverlay/MenuHandle';
import useActieveOproep from '../../../helpers/communicatie/telefoon/useActieveOproep';
import useInkomendeOfRinkelendeOproepen from '../../../helpers/communicatie/telefoon/useInkomendeOfRinkelendeOproepen';
import TelefoongesprekActueel from './TelefoongesprekActueel';
import TelefoongesprekInkomendeOfRinkelende from './TelefoongesprekRinkelende';
import { observer } from 'mobx-react-lite';

export const Root = styled.div`
  width: 100%;
  background: #034783
    linear-gradient(to right, #034783 0%, rgb(115, 2, 122) 20%, #034783 40%, #034783 100%) no-repeat;
  background-size: 800px 200px;
  display: inline-block;
  position: relative;
  animation: shimmer;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;

  @keyframes shimmer {
    0% {
      background-position: -468px 0;
    }

    100% {
      background-position: 750px 0;
    }
  }
`;

const CommunicatieSectie = observer(() => {
  const actieveOproepOutput = useActieveOproep();
  const inkomendeOfRinkelendeOproepenOutput = useInkomendeOfRinkelendeOproepen();

  const telefoonoproepWeergave = useMemo(() => {
    const w =
      actieveOproepOutput !== null ? (
        <TelefoongesprekActueel actieveOproepOutput={actieveOproepOutput} />
      ) : inkomendeOfRinkelendeOproepenOutput !== null ? (
        <TelefoongesprekInkomendeOfRinkelende output={inkomendeOfRinkelendeOproepenOutput} />
      ) : null;

    if (w === null) {
      return null;
    }

    // return (
    //   <div className="d-flex flex-column align-items-center p-3 w-100">
    //     <span>de Baat, G.</span>
    //     <span className="text-muted">belt voor</span>
    //     <span>Splash Lease</span>
    //   </div>
    // );

    return <div className="d-flex flex-column align-items-center w-100">{w}</div>;
  }, [actieveOproepOutput, inkomendeOfRinkelendeOproepenOutput]);

  return (
    <div className="d-flex flex-column w-100">
      {telefoonoproepWeergave && (
        <>
          {telefoonoproepWeergave}
          <HorizontaleScheidingslijn color="rgba(136, 136, 136, 0.2)" />
        </>
      )}

      <div
        className="d-flex align-items-center w-100"
        style={{ justifyContent: 'space-evenly', padding: '8px 5px' }}
      >
        <button
          style={{ outline: 0, border: 0, background: 0 }}
          onClick={() => {
            setCommunicatieOverlayState!((curr) => ({
              ...curr,
              geselecteerdTabblad: ECommunicatieTabblad.Telefoon,
            }));
          }}
        >
          <IconBellen style={{ fill: Kleur.Wit, width: 20, height: 20 }} />
        </button>
        <button
          style={{ outline: 0, border: 0, background: 0 }}
          onClick={() => {
            setCommunicatieOverlayState!((curr) => ({
              ...curr,
              geselecteerdTabblad: ECommunicatieTabblad.Whatsapp,
            }));
          }}
        >
          <IconWhatsapp style={{ fill: Kleur.Wit, width: 20, height: 20 }} />
        </button>
        <button
          style={{ outline: 0, border: 0, background: 0 }}
          onClick={() => {
            setCommunicatieOverlayState!((curr) => ({
              ...curr,
              geselecteerdTabblad: ECommunicatieTabblad.Sms,
            }));
          }}
        >
          <IconSMS style={{ fill: Kleur.Wit, width: 20, height: 20 }} />
        </button>
        <button
          style={{ outline: 0, border: 0, background: 0 }}
          onClick={() => {
            setCommunicatieOverlayState!((curr) => ({
              ...curr,
              geselecteerdTabblad: ECommunicatieTabblad.Email,
            }));
          }}
        >
          <IconEmail style={{ fill: Kleur.Wit, width: 20, height: 20 }} />
        </button>
      </div>
    </div>
  );
});

export default CommunicatieSectie;
