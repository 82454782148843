import React, { useContext, useMemo } from 'react';
import { IProps } from '@tinymce/tinymce-react/lib/cjs/main/ts/components/Editor';
import { IContext, VoorraadContext } from '../index';
import VeldWeergave from '../../../formulier/VeldWeergave';
import LoadingSpinner from '../../../Gedeeld/LoadingSpinner';
import { format } from 'date-fns';
import GegevensLayout from '../../../layout/GegevensLayout';
import FormatteerBedrag from '../../../MutatieBedrag';
import {
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import { Grid, Table, TableHeaderRow, VirtualTable } from '@devexpress/dx-react-grid-bootstrap4';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import nameof from '../../../../core/nameOf';
import { IOphalenInkoopopdrachtenResultElement } from '../../../../../../shared/src/api/v2/product';
import { Kleur as EKleur, Kleur } from '../../../../bedrijfslogica/constanten';

export interface IRegel extends IOphalenInkoopopdrachtenResultElement {}

const DetailTab: React.FC<IProps> = (props) => {
  const { type, voorraadInfo, inkoopopdrachten, inkoopdiensten } = useContext<IContext>(
    VoorraadContext,
  );

  const prioDienst = type!.diensten.find((x) => x.isPrio) ?? null;

  const prioInkoopdienst =
    prioDienst !== null ? inkoopdiensten!.find((x) => x.ID === prioDienst.ID)! : null;

  const kolommen = useMemo<TypedColumn<IRegel>[]>(
    () => [
      // {
      //   name: 'Aantal',
      //   title: 'Besteld',
      // },
      {
        name: 'DatumOpdracht',
        title: 'Opd.datum',
      },
      {
        name: 'Referentie',
        title: 'Ref.',
      },
      {
        name: '__nogLeveren' as any,
        title: 'Te lev.',
      },
      {
        name: 'DatumVerwacht',
        title: 'Verwacht',
      },
      {
        name: 'Notities',
        title: 'Notities',
      },
    ],
    [],
  );

  const kolombreedtes = useMemo<TypedTableColumnWidthInfo<IRegel>[]>(
    () => [
      // {
      //   columnName: 'Aantal',
      //   width: 70,
      // },
      {
        columnName: '__nogLeveren' as any,
        width: 65,
      },
      {
        columnName: 'DatumOpdracht',
        width: 110,
      },
      {
        columnName: 'Referentie',
        width: 90,
      },
      {
        columnName: 'DatumVerwacht',
        width: 100,
      },
      {
        columnName: 'Notities',
        width: 200,
      },
    ],
    [],
  );

  const kolomExtensies = useMemo<Table.ColumnExtension[]>(
    () => [
      {
        columnName: 'Aantal',
        width: 60,
      },
    ],
    [],
  );

  return (
    <>
      <div className="d-flex flex-column">
        {type === null || voorraadInfo === null || inkoopopdrachten === null ? (
          <LoadingSpinner />
        ) : (
          <>
            <div className="row mt-2 pl-3 pr-3">
              <div className="col-12">
                <VeldWeergave>
                  <div className="row">
                    <div className="col-6">
                      <GegevensLayout
                        gegevens={[
                          {
                            label: 'Merk',
                            waarde: type.Merknaam,
                          },
                          {
                            label: 'Type',
                            waarde: type.Typenaam,
                          },
                          {
                            label: 'Categorie',
                            waarde: type.Productsoortnaam,
                          },
                        ]}
                      />
                    </div>
                    <div className="col-6">
                      <GegevensLayout
                        gegevens={[
                          {
                            label: 'Inkoop toegestaan',
                            waarde: type.InkoopToegestaan ? (
                              <span>Ja</span>
                            ) : (
                              <span style={{ color: Kleur.Rood }}>Nee</span>
                            ),
                          },
                          {
                            label: 'Leverancier',
                            waarde:
                              prioInkoopdienst !== null
                                ? prioInkoopdienst.relatie!.weergavenaam
                                : '',
                          },
                          null,
                        ]}
                      />
                    </div>
                  </div>
                </VeldWeergave>
              </div>

              <div className="col-12">
                <VeldWeergave>
                  <GegevensLayout
                    gegevens={[
                      {
                        label: 'Kenmerk',
                        waarde: type.Kenmerk,
                      },
                    ]}
                  />
                </VeldWeergave>
              </div>
            </div>

            <div className="mt-2 pl-3 pr-3">
              <VeldWeergave>
                {/* <div className="mb-1">Aantallen:</div> */}
                <div>
                  <div className="row">
                    <div className="col-6">
                      <GegevensLayout
                        gegevens={[
                          {
                            label: 'Aantal vrij',
                            waarde: (
                              <span
                                style={{
                                  color: voorraadInfo.aantalVrij > 0 ? EKleur.Groen : EKleur.Rood,
                                }}
                              >
                                {voorraadInfo.aantalVrij.toString()}
                              </span>
                            ),
                          },
                          {
                            label: 'Totaal in voorraad',
                            waarde: voorraadInfo.aantalVoorraad.toString(),
                          },
                          {
                            label: 'Nog te leveren',
                            waarde: voorraadInfo.aantalInkoop.toString(),
                          },
                        ]}
                      />
                    </div>

                    <div className="col-6">
                      <GegevensLayout
                        gegevens={[
                          {
                            label: 'Planning, Gereserveerd',
                            waarde: voorraadInfo.aantalPlanningGereserveerd.toString(),
                          },
                          {
                            label: 'Planning, Inkoopreserveringen',
                            waarde: voorraadInfo.aantalInkoopGereserveerdPlanning.toString(),
                          },
                          {
                            label: 'Uitstaand, Gereserveerd',
                            waarde: voorraadInfo.aantalUitstaandGereserveerd.toString(),
                          },
                          {
                            label: 'Uitstaand, Inkoopreserveringen',
                            waarde: voorraadInfo.aantalInkoopGereserveerdUitstaand.toString(),
                          },
                        ]}
                      />
                    </div>
                  </div>
                </div>
              </VeldWeergave>
            </div>

            <div className="mt-2 pl-3 pr-3">
              <VeldWeergave>
                <div className="p-1 font-weight-bold">Inkoopopdrachten voor type</div>
                <div className="mt-1" style={{ backgroundColor: Kleur.Wit }}>
                  <GridStyleWrapper maxHeight={200} rowAmount={inkoopopdrachten.length}>
                    <Grid rows={inkoopopdrachten} columns={kolommen}>
                      <DataTypeProvider
                        for={[nameof<IRegel>('Aantal')]}
                        formatterComponent={(formatterProps) => {
                          const rij: IRegel = formatterProps.row;

                          return <span>{formatterProps.value}</span>;
                        }}
                      />

                      <DataTypeProvider
                        for={['__nogLeveren']}
                        formatterComponent={(formatterProps) => {
                          const rij: IRegel = formatterProps.row;

                          return <span>{rij.Aantal - rij.AantalGeleverdeProducten}</span>;
                        }}
                      />

                      <DataTypeProvider
                        for={[nameof<IRegel>('DatumOpdracht')]}
                        formatterComponent={(formatterProps) => {
                          const rij: IRegel = formatterProps.row;
                          if (formatterProps.value === null) {
                            return <span>Nog versturen</span>;
                          }
                          return (
                            <span>{format(new Date(formatterProps.value), 'dd-MM-yyyy')}</span>
                          );
                        }}
                      />

                      <DataTypeProvider
                        for={[nameof<IRegel>('DatumVerwacht')]}
                        formatterComponent={(formatterProps) => {
                          const rij: IRegel = formatterProps.row;

                          const datumVerwacht =
                            rij.DatumVerwacht !== null ? rij.DatumVerwacht : rij.DatumGewenst;

                          if (datumVerwacht === null) {
                            return <span></span>;
                          }

                          return <span>{format(new Date(datumVerwacht), 'dd-MM-yyyy')}</span>;
                        }}
                      />

                      <VirtualTable
                        columnExtensions={kolombreedtes}
                        messages={{ noData: 'Geen inkoopopdrachten' }}
                      />
                      <TableHeaderRow />
                    </Grid>
                  </GridStyleWrapper>
                </div>
              </VeldWeergave>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default DetailTab;
