import {
  IOphalenPostcodeDataParams,
  IOphalenPostcodeDataResult,
} from '../../../../../shared/src/api/v2/dienst/postcodedata';
import api from '../../index';

const postcode = {
  ophalenPostcodeData: async (
    params: IOphalenPostcodeDataParams,
  ): Promise<IOphalenPostcodeDataResult> => {
    return await api.post('v2/dienst/postcodedata/ophalen-postcode-data', params);
  },
};

export default postcode;
