import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import MultiCombobox, { IKolom } from '../MultiCombobox';
import { IFilterSchemaFilter } from '../../../../../shared/src/models/filter';
import { IOphalenProductmodellenResultElement } from '../../../../../shared/src/api/v2/aanbod/productmodel';

interface IProps {
  prodModID: number | null;
  productmodellen: IOphalenProductmodellenResultElement[];
  filters?: IFilterSchemaFilter[];
  onChange: (prodModID: number | null) => void;
  options?: IOptions;
}

interface IOptions {
  bijEnkeleDirectVoorselecteren?: boolean;
}

const defaultOptions: IOptions = {
  bijEnkeleDirectVoorselecteren: false,
};

const ProductmodelSelectieCombobox: React.FC<IProps> = (props) => {
  const options = useMemo(() => ({ ...defaultOptions, ...props.options }), [props.options]);

  const { onChange, productmodellen, prodModID } = props;

  const kolommen = useMemo<IKolom<IOphalenProductmodellenResultElement>[]>(() => {
    return [
      {
        key: 'Modelnaam',
        label: 'Modelnaam',
        breedte: 200,
      },
      {
        key: 'Modelcode',
        label: 'Code',
        breedte: 125,
      },
      {
        key: 'Kenmerk',
        label: 'Kenmerk',
        breedte: 250,
      },
      {
        key: 'Categorie' as any,
        label: 'Categorie',
        breedte: 145,
      },
    ];
  }, []);

  return (
    <>
      <MultiCombobox<number, any>
        sleutelExtractor={(row) => row.ProdModID}
        representatieFabriek={(row) => {
          const model: IOphalenProductmodellenResultElement = row;
          if (model === null) {
            return null;
          }

          return model.Modelcode + ' - ' + model.Modelnaam;
        }}
        waarde={prodModID}
        onWaardeChange={onChange}
        kolommen={kolommen}
        opties={productmodellen.map((x) => {
          return {
            ProdModID: x.ProdModID,
            Modelcode: x.Modelcode,
            Modelnaam: x.Modelnaam,
            Kenmerk: x.Kenmerk,
            Categorie: x.productsoort.Naam,
          };
        })}
        options={{
          geenWaardeBericht: 'Kies een productmodel',
        }}
      />
    </>
  );
};

export default ProductmodelSelectieCombobox;
