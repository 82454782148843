import React, { useCallback, useContext, useMemo } from 'react';
import MultiComboboxV2, { EnkeleProvider, IRepresentatieProps, Provider } from '../MultiComboboxV2';
import { ASPKolom, EAspKolomBreedteType } from '../../tabel/ASPTabel/types';

export enum EMaand {
  Januari = 1,
  Februari,
  Maart,
  April,
  Mei,
  Juni,
  Juli,
  Augustus,
  September,
  Oktober,
  November,
  December,
}

export interface IMaandInfo {
  weergaveNaam: string;
}

export const maandInfos: Record<EMaand, IMaandInfo> = {
  [EMaand.Januari]: {
    weergaveNaam: 'Januari',
  },
  [EMaand.Februari]: {
    weergaveNaam: 'Februari',
  },
  [EMaand.Maart]: {
    weergaveNaam: 'Maart',
  },
  [EMaand.April]: {
    weergaveNaam: 'April',
  },
  [EMaand.Mei]: {
    weergaveNaam: 'Mei',
  },
  [EMaand.Juni]: {
    weergaveNaam: 'Juni',
  },
  [EMaand.Juli]: {
    weergaveNaam: 'Juli',
  },
  [EMaand.Augustus]: {
    weergaveNaam: 'Augustus',
  },
  [EMaand.September]: {
    weergaveNaam: 'September',
  },
  [EMaand.Oktober]: {
    weergaveNaam: 'Oktober',
  },
  [EMaand.November]: {
    weergaveNaam: 'November',
  },
  [EMaand.December]: {
    weergaveNaam: 'December',
  },
};

type Kolom = 'nummer' | 'naam';

export enum EWeergave {
  Tekst,
  Getal,
}

export const bepaalHuidigeMaand = (): EMaand => (new Date().getMonth() + 1) as EMaand;

interface IProps {
  maand: EMaand | null;
  onMaandChange: (maand: EMaand | null) => void;
  weergave: EWeergave;
  opties?: EMaand[];
  disabled?: boolean;
}

const tekstWeergave = (x: EMaand): string => maandInfos[x].weergaveNaam;
const getalWeergave = (x: EMaand): string =>
  String(Object.keys(EMaand).indexOf(String(x)) + 1).padStart(2, '0');

interface IRepresentatieContext {
  weergave: EWeergave;
}

const RepresentatieContext = React.createContext<IRepresentatieContext>(null!);

const MaandSelectie = (props: IProps) => {
  const keyExtractor = useCallback((x: EMaand) => x, []);

  const kolommen = useMemo<ASPKolom<Kolom, EMaand>[]>(
    () => [
      {
        key: 'nummer',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 35,
        renderer: (row) => getalWeergave(row),
      },
      {
        key: 'naam',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 100,
        renderer: (row) => tekstWeergave(row),
      },
    ],
    [],
  );

  const enkeleProvider = useMemo<EnkeleProvider<EMaand, EMaand>>(
    () => async (maand: EMaand) => maand,
    [],
  );
  const provider = useMemo<Provider<Kolom, EMaand>>(
    () => async (params) => {
      const maanden =
        props.opties ??
        Object.keys(maandInfos).map((maandInfoKeyStr) => Number(maandInfoKeyStr) as EMaand);
      const items = maanden.reduce((acc, maand, i) => {
        acc[i] = maand;
        return acc;
      }, params.huidigeBron);
      const totaalAantal = maanden.length;

      return {
        items,
        totaalAantal,
      };
    },
    [JSON.stringify(props.opties)],
  );

  const context = useMemo<IRepresentatieContext>(
    () => ({
      weergave: props.weergave,
    }),
    [props.weergave],
  );

  return (
    <RepresentatieContext.Provider value={context}>
      <MultiComboboxV2<EMaand, Kolom, EMaand, null>
        waarde={props.maand}
        onChange={props.onMaandChange}
        kolommen={kolommen}
        disabled={props.disabled}
        representatieComponent={Representatie}
        enkeleProvider={enkeleProvider}
        provider={provider}
        keyExtractor={keyExtractor}
      />
    </RepresentatieContext.Provider>
  );
};

const Representatie = (props: IRepresentatieProps<EMaand>) => {
  const context = useContext(RepresentatieContext);
  const tekst = useMemo(() => {
    switch (context.weergave) {
      case EWeergave.Tekst:
        return tekstWeergave(props.entiteit);
      case EWeergave.Getal:
        return getalWeergave(props.entiteit);
    }
  }, [context.weergave, props.entiteit]);

  return <span>{tekst}</span>;
};

export default MaandSelectie;
