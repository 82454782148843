import React from 'react';
import VerticaleNavigatieBalk, { ELink, ILink, Link } from '../../navigatie/VerticaleNavigatieBalk';
import { Kleur } from '../../../bedrijfslogica/constanten';

interface IProps<TGroepId extends string | number | symbol> {
  links: Link<TGroepId>[];
  uitgeklapteGroepLinks: Partial<Record<TGroepId, boolean>>;
  onUitgeklapteGroepLinksChange: (
    uitgeklapteGroepLinks: Partial<Record<TGroepId, boolean>>,
  ) => void;
  pathPrefix: string;
}

const KaartNavigatie = <TGroepId extends string | number | symbol>(props: IProps<TGroepId>) => {
  return (
    <div
      style={{
        backgroundColor: Kleur.Wit,
        color: Kleur.Grijs,
        border: `1px solid ${Kleur.LichtGrijs}`,
        minWidth: 210,
        maxHeight: '100vh',
      }}
      className="d-flex flex-column"
    >
      <VerticaleNavigatieBalk
        links={props.links}
        uitgeklapteGroepLinks={props.uitgeklapteGroepLinks as any}
        onUitgeklapteGroepLinksChange={props.onUitgeklapteGroepLinksChange as any}
        pathPrefix={props.pathPrefix}
        menuBalkIconKleur={Kleur.Grijs}
      />
    </div>
  );

  // return (
  //   <Root>
  //     {props.items.map((item, i) => (
  //       <Item key={i} item={item} />
  //     ))}
  //   </Root>
  // );
};

export default KaartNavigatie;
