import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { IRapportageDetailProps } from '../../index';
import GegevensLayout from '../../../../../../components/layout/GegevensLayout';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../../../models/IRemoteData';
import { IGegevensVoorJaarwerkResult } from '../../../../../../../../shared/src/api/v2/boekhouding/rapportage';
import api from '../../../../../../api';
import LoadingSpinner from '../../../../../../components/Gedeeld/LoadingSpinner';
import FormatteerBedrag from '../../../../../../components/MutatieBedrag';
import { format } from 'date-fns';

const Root = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const GegevensTenBehoeveVanJaarwerk = (props: IRapportageDetailProps) => {
  const [gegevensVoorJaarWerk, setGegevensVoorJaarwerk] = useState<
    IRemoteData<IGegevensVoorJaarwerkResult>
  >(createPendingRemoteData());
  const ophalenGegevensVoorJaarwerk = useCallback(async () => {
    const result = await api.v2.boekhouding.rapportage.gegevensVoorJaarwerk({
      jaar: props.jaar,
    });
    setGegevensVoorJaarwerk(createReadyRemoteData(result));
  }, [props.jaar]);
  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    ophalenGegevensVoorJaarwerk();
  }, [ophalenGegevensVoorJaarwerk]);

  return (
    <Root>
      {gegevensVoorJaarWerk.state === ERemoteDataState.Pending ? (
        <div className="flex-fill d-flex align-items-center justify-content-center">
          <LoadingSpinner />
        </div>
      ) : (
        <div
          style={{
            width: 500,
          }}
        >
          <GegevensLayout
            gegevens={[
              {
                label: 'Peildatum',
                waarde: format(new Date(gegevensVoorJaarWerk.data!.peildatum), 'dd-MM-yyyy'),
              },
              {
                label: 'Aantal actieve contracten',
                waarde: gegevensVoorJaarWerk.data!.aantalActieveContracten.toString(),
              },
              // {
              //   label: 'Aantal lopende contracten',
              //   waarde: gegevensVoorJaarWerk.data!.aantalLopendeContracten.toString(),
              // },
              {
                label: 'Aantal producten',
                waarde: gegevensVoorJaarWerk.data!.aantalProducten.toString(),
              },
              {
                label: 'Omzet overhevelen naar volgend jaar',
                waarde: (
                  <FormatteerBedrag bedrag={gegevensVoorJaarWerk.data!.omzetOverTeHevelen ?? 0} />
                ),
              },
            ]}
          />
        </div>
      )}
    </Root>
  );
};

export default GegevensTenBehoeveVanJaarwerk;
