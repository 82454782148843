import dompurify from 'dompurify';
import { addDays, format } from 'date-fns';
import localeNL from 'date-fns/locale/nl';
import { IContactData } from './Conversatie/Berichten/Bericht/ContactWeergave';

export type ZoekenFocus = 'chats' | 'berichten';

export const naamNaarInitialen = (naam: string) => {
  const woorden = naam.split(' ');
  const eersteWoord = woorden[0] ?? null;
  const laatsteWoord = woorden.length > 1 ? woorden[woorden.length - 1] ?? null : null;
  const initialenWoorden = [eersteWoord, laatsteWoord].filter((x) => x !== null) as string[];
  if (initialenWoorden.length === 0) {
    return '';
  }
  const initialen = initialenWoorden.map((woord) => {
    if (woord.length === 0) {
      return '';
    }
    return woord[0].toUpperCase();
  });
  return initialen.join('');
};

const AVATAR_KLEUREN: string[] = [
  '#ff7575',
  '#ff9e75',
  '#54a950',
  '#5bc4a8',
  '#65a1d0',
  '#a372d9',
  '#ea77cc',
];

const strHashCode = (str: string) => {
  let hash = 0;
  let i;
  let chr;
  if (str.length === 0) return hash;
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

export const naamNaarAvatarKleur = (naam: string) => {
  const hashCode = strHashCode(naam);
  const index = Math.abs(hashCode) % AVATAR_KLEUREN.length;
  return AVATAR_KLEUREN[index];
};

export const sanitizeBerichtInhoud = (tekst: string | null | undefined): string | null => {
  if (!tekst) {
    return null;
  }

  const trimmed = tekst.trim();
  const sanitized = dompurify.sanitize(trimmed, {
    USE_PROFILES: { html: false },
  });
  if (!sanitized) {
    return null;
  }
  return sanitized;
};

export const formatTijd = (datum: Date) => {
  return format(datum, 'HH:mm', { locale: localeNL });
};

export const formatDatum = (datum: Date) => {
  return format(datum, 'dd-MM-yyyy', { locale: localeNL });
};

export const formatDatumTijd = (datum: Date) => {
  return format(datum, 'dd-MM-yyyy HH:mm', { locale: localeNL });
};

export const datumNaarStringKort = (datum: Date) => {
  const vorigBerichtAlleenDatum = new Date(datum);
  vorigBerichtAlleenDatum.setHours(0, 0, 0, 0);

  const nuAlleendatum = new Date();
  nuAlleendatum.setHours(0, 0, 0, 0);

  // Als datum niet gisteren is, toon HH:mm
  if (vorigBerichtAlleenDatum.getTime() === nuAlleendatum.getTime()) {
    return format(datum, 'HH:mm', {
      locale: localeNL,
    });
  }

  const gisterenAlleenDatum = addDays(new Date(), -1);
  gisterenAlleenDatum.setHours(0, 0, 0, 0);
  // Als datum gisteren is, toon alleen 'Gisteren'
  if (vorigBerichtAlleenDatum.getTime() === gisterenAlleenDatum.getTime()) {
    return 'Gisteren';
  }

  // Als datum minder dan een week geleden is
  if (datum.getTime() > addDays(new Date(), -7).getTime()) {
    return format(datum, 'EEEE', {
      locale: localeNL,
    });
  }

  // Als datum langer geleden is, toon alleen de datum
  return format(datum, 'dd-MM-yyyy', {
    locale: localeNL,
  });
};

export const datumNaarStringLang = (datum: Date) => {
  const vorigBerichtAlleenDatum = new Date(datum);
  vorigBerichtAlleenDatum.setHours(0, 0, 0, 0);

  const nuAlleendatum = new Date();
  nuAlleendatum.setHours(0, 0, 0, 0);

  // // Als datum niet gisteren is, toon HH:mm
  // if (vorigBerichtAlleenDatum.getTime() === nuAlleendatum.getTime()) {
  //   return format(datum, 'HH:mm', { locale: localeNL });
  // }

  const gisterenAlleenDatum = addDays(new Date(), -1);
  gisterenAlleenDatum.setHours(0, 0, 0, 0);
  // Als datum gisteren is, toon alleen 'Gisteren'
  if (vorigBerichtAlleenDatum.getTime() === gisterenAlleenDatum.getTime()) {
    return 'Gisteren';
  }

  const isLangerDanEenJaarGeleden = datum.getTime() < addDays(new Date(), -365).getTime();
  const formatStr = `EEEE d MMMM${isLangerDanEenJaarGeleden ? ' yyyy' : ''}`;
  return format(datum, formatStr, {
    locale: localeNL,
  });
};

export interface IOnbekendCustomData {
  type: 'onbekend';
}

export interface IContactCustomData {
  type: 'contact';
  data: IContactData[];
}

export type CustomData = IContactCustomData | IOnbekendCustomData;

export const customNaarCustomData = (custom: string): CustomData => {
  try {
    const data = JSON.parse(custom);

    if (data.contacts !== undefined) {
      return {
        type: 'contact',
        data: data.contacts,
      };
    }

    return {
      type: 'onbekend',
    };
  } catch (err) {
    console.log(err);

    return {
      type: 'onbekend',
    };
  }
};
