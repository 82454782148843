import * as React from 'react';
import Modal from 'react-bootstrap/Modal';
import ModalFooter from 'react-bootstrap/ModalFooter';
import ModalBody from 'react-bootstrap/ModalBody';
import { useMemo } from 'react';
import IDialoogProps from '../../../core/IDialoogProps';

interface IProps extends Omit<IDialoogProps<null>, 'onAnnuleren'> {
  body?: string | JSX.Element;
}

const defaultProps: Partial<IProps> = {
  body: 'Weet je het zeker ;)?',
};

const OkDialoog: React.FC<IProps> = (props) => {
  const p = useMemo(() => ({ ...defaultProps, ...props }), [props]);

  return (
    <Modal show={p.open}>
      <ModalBody style={{ padding: '2rem' }}>{p.body}</ModalBody>
      <ModalFooter className="d-flex flex-row justify-content-start">
        <button
          className="btn btn-primary"
          onClick={() => p.onSuccess(null)}
          style={{ width: 100 }}
        >
          Ok
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default OkDialoog;
