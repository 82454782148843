import {
  DataTypeProvider,
  EditingState,
  SelectionState,
  Table as TableBase,
  TableRowDetail,
} from '@devexpress/dx-react-grid';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { RootStoreContext } from '../../../../stores/RootStore';
import api from '../../../../api';
import { EResultType } from '../../../../stores/CheckStore';
import {
  DXTableCheckboxComponent,
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import { Kleur as EKleur } from '../../../../bedrijfslogica/constanten';
import {
  Grid,
  Table,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  TableSelection,
} from '@devexpress/dx-react-grid-bootstrap4';
import { IconHerstel, IconKruis, IconPijlVolgend, IconVerwijderen } from '../../../Icons';
import ActieMenuKnop from '../../../ActieMenuKnop';
import RelatieVisualisatie from '../../../personalia/RelatieVisualisatie';
import ReserverenVoorraadDialoog, {
  ETabblad,
} from '../../../../paginas/Transport/Opdrachten/reservering/KoppelVoorraadProductDialoog';
import ReserverenProductDialoog from '../../../../paginas/Transport/Opdrachten/reservering/KoppelLocatieProductDialoog';
import { IRow, UitstaandTabelContext } from '../index';
import WijzigenProductDialoog from '../../../../paginas/Magazijn/Depot/WijzigenProductDialoog';
import AfmeldenDialoog from '../AfmeldenDialoog';
import { format } from 'date-fns';
import AnnulerenDialoog from './AnnulerenDialoog';
import useUpload from '../../../../core/useUpload';
import BijlagenContainer, { BestandType } from '../../../BijlagenContainer';
import BijlageKnop, { EBronModus, ESelectieModus } from '../../../BijlageKnop';
import MenuLayout from '../../../MenuLayout';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../models/IRemoteData';
import {
  IOphalenOpdrachtregelsResultElementV2,
  IOphalenTransportopdrachtBestandenResult,
} from '../../../../../../shared/src/api/v2/transport/opdracht';
import LoadingSpinner from '../../../Gedeeld/LoadingSpinner';
import MultiSelect from '../../../formulier/MultiSelect';
import nameOf from '../../../../core/nameOf';
import {
  ERegelstatusTransport,
  ETransportopdrachtRegelsoort,
} from '../../../../bedrijfslogica/enums';
import ContractnummerComp from '../../../kolomveld/contract/ContractnummerComp';
import KoppelVoorraadDialoog from '../../../../paginas/Transport/Opdrachten/reservering/KoppelVoorraadProductDialoog';
import KoppelLocatieProductDialoog from '../../../../paginas/Transport/Opdrachten/reservering/KoppelLocatieProductDialoog';
import ReserveringProductComp from '../../../kolomveld/transportopdrachtregel/ReserveringProductComp';
import ProductinfoDialoog from '../../../product/ProductinfoDialoog';
import VoorraadTypeInfoDialoog from '../../VoorraadTypeInfoDialoog';
import NieuwOfGebruiktComp from '../../../kolomveld/transportopdrachtregel/NieuwOfGebruiktComp';
import { IOphalenBestandenResult } from '../../../../../../shared/src/api/v2/bestand/bestand';
import ToevoegenRegelsDialoog from '../../ToevoegenRegelsDialoog';
import UitlegTooltip from '../../../formulier/UitlegTooltip';
import ProductWeergaveComp from '../../../kolomveld/transportopdrachtregel/ProductWeergaveComp';
import ReferentieProductComp from '../../../kolomveld/transportopdrachtregel/ReferentieProductComp';

interface IRegelRow extends IOphalenOpdrachtregelsResultElementV2 {}

export interface IAfmeldenOpdrachtDialoogState {
  trsOpdID: number;
  trsRegID: number;
}
export interface IAnnulerenOpdrachtregelsState {
  trsRegIDs: number[];
}

export interface IKoppelVoorraadDialoogState {
  trsRegID: number;
  cntID: number | null;
}

export interface IKoppelLocatieproductDialoogState {
  trsRegID: number;
  locID: number | null;
  cntID: number | null;
}

export interface IKoppelVoorraadDialoogState {
  trsRegID: number;
  cntID: number | null;
}

export interface IKoppelLocatieproductDialoogState {
  trsRegID: number;
  locID: number | null;
  cntID: number | null;
}

export interface IVoorraadTypeInfoDialoogState {
  typeID: number;
  magID: number;
}

export interface IProductinfoDialoogState {
  id: number;
}

const CellComponent = (props: TableBase.DataCellProps) => {
  const isGekoppeldBestandKolom = props.column.name === '__gekoppeldBestand';
  return (
    <td style={{ overflow: isGekoppeldBestandKolom ? 'visible' : undefined }}>{props.children}</td>
  );
};

const Regels = (props: TableRowDetail.ContentProps) => {
  const row = props.row as IRow; // row = Opdracht + regels
  const { checkStore } = useContext(RootStoreContext);
  const uitstaandTabelContext = useContext(UitstaandTabelContext);

  const regelsSelectie = useMemo(
    () =>
      uitstaandTabelContext.regelsSelectie.filter((x) =>
        row.regels.some((regel) => regel.TrsRegID === x),
      ),
    [uitstaandTabelContext.regelsSelectie, row.regels],
  );
  const andereRegelsSelectie = useMemo(
    () =>
      uitstaandTabelContext.regelsSelectie.filter(
        (x) => !row.regels.some((regel) => regel.TrsRegID === x),
      ),
    [uitstaandTabelContext.regelsSelectie, row.regels],
  );

  const handleRegelsSelectieChange = useCallback(
    (trsRegIDs: number[]) => {
      const nieuweSelectie = [...andereRegelsSelectie, ...trsRegIDs];
      uitstaandTabelContext.onRegelsSelectieChange(nieuweSelectie);
    },
    [andereRegelsSelectie, uitstaandTabelContext.onRegelsSelectieChange],
  );

  const [reserverenVoorraadDialoogTonen, setReserverenVoorraadDialoogTonen] = useState<boolean>(
    false,
  );
  const [reserverenProductDialoogTonen, setReserverenProductDialoogTonen] = useState<number | null>(
    null,
  );
  const [wijzigenProductDialoogTonen, setWijzigenProductDialoogTonen] = useState<number | null>(
    null,
  );
  const [
    koppelVoorraadDialoogState,
    setKoppelVoorraadDialoogState,
  ] = useState<IKoppelVoorraadDialoogState | null>(null);

  const [
    koppelLocatieproductDialoog,
    setKoppelLocatieproductDialoogState,
  ] = useState<IKoppelLocatieproductDialoogState | null>(null);

  const [
    voorraadTypeInfoDialoogState,
    setVoorraadTypeInfoDialoogState,
  ] = useState<IVoorraadTypeInfoDialoogState | null>(null);

  const [
    productinfoDialoogState,
    setProductinfoDialoogState,
  ] = useState<IProductinfoDialoogState | null>(null);

  const [cntID, setCntID] = useState<number | null>(null);
  const [trsRegID, setTrsRegID] = useState<number | null>(null);

  const [
    afmeldenOpdrachtDialoogState,
    onAfmeldenOpdrachtDialoogStateChange,
  ] = useState<IAfmeldenOpdrachtDialoogState | null>(null);
  const [
    annulerenOpdrachtregelsDialoogState,
    onAnnulerenOpdrachtregelsDialoogStateChange,
  ] = useState<IAnnulerenOpdrachtregelsState | null>(null);

  const [toevoegenTrsOpdID, setToevoegenTrsOpdID] = useState<number | null>(null);

  const [transportopdrachtBestanden, setTransportopdrachtBestanden] = useState<
    IRemoteData<IOphalenTransportopdrachtBestandenResult>
  >(createPendingRemoteData());
  const ophalenTransportopdrachtBestanden = useCallback(async () => {
    const result = await api.v2.transport.opdracht.ophalenTransportopdrachtBestanden({
      filterSchema: {
        filters: [
          {
            naam: 'TRS_OPD_IDS',
            data: [row.TrsOpdID],
          },
        ],
      },
    });
    setTransportopdrachtBestanden(createReadyRemoteData(result));
  }, [row.TrsOpdID]);
  useEffect(() => {
    ophalenTransportopdrachtBestanden();
  }, [ophalenTransportopdrachtBestanden]);

  const [transportopdrachtBestandinfos, setTransportopdrachtBestandinfos] = useState<
    IRemoteData<IOphalenBestandenResult>
  >(createPendingRemoteData());
  const ophalenTransportopdrachtBestandinfos = useCallback(async () => {
    if (transportopdrachtBestanden.state === ERemoteDataState.Pending) {
      setTransportopdrachtBestandinfos((curr) =>
        curr.state === ERemoteDataState.Pending ? curr : createPendingRemoteData(),
      );
      return;
    }
    if (transportopdrachtBestanden.data!.bestanden.length === 0) {
      setTransportopdrachtBestandinfos(createReadyRemoteData({ bestanden: [], totaalAantal: 0 }));
      return;
    }

    const result = await api.v2.bestand.ophalenBestanden({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: transportopdrachtBestanden.data!.bestanden.map((x) => x.BestandID),
          },
        ],
      },
    });
    setTransportopdrachtBestandinfos(createReadyRemoteData(result));
  }, [transportopdrachtBestanden]);
  useEffect(() => {
    ophalenTransportopdrachtBestandinfos();
  }, [ophalenTransportopdrachtBestandinfos]);

  // Map de velden van de meegegeven regels naar de regels van het grid
  const gridData = useMemo<IRegelRow[]>(() => row.regels.map((regel) => ({ ...regel })), [
    row.regels,
  ]);

  // Koppelt het geselecteerde type (Nieuw) of product (Gebruikt) aan de transportregel
  const reserverenProduct = useCallback(
    async (soort: number, typeID: number | null, prodID: number | null) => {
      await api.v2.transport.reservering.levering.koppelenProduct({
        trsRegID: trsRegID!,
        soortReservering: soort === 1 ? 'T' : 'P',
        typeID,
        prodID,
      });
    },
    [trsRegID],
  );

  // Koppelt het geselecteerde product aan de transportregel
  const koppelenProduct = useCallback(
    async (prodID: number) => {
      if (reserverenProductDialoogTonen === null) {
        return;
      }
      const trsRegID = reserverenProductDialoogTonen;

      await api.v2.transport.reservering.retour.reserverenProduct({
        trsRegID,
        prodID,
      });
    },
    [reserverenProductDialoogTonen],
  );

  const handlKoppelenOmruil = useCallback(
    async (trsRegIDs: number[]) => {
      await api.v2.transport.opdracht.omruil.koppelenOmruil({
        regels: [{ trsRegID: trsRegIDs[0], koppelAan_TrsRegID: trsRegIDs[1] }],
      });

      uitstaandTabelContext.onRequestRefresh();
    },
    [uitstaandTabelContext.onRequestRefresh],
  );

  const handlVerwijderenOmruil = useCallback(
    async (trsRegID: number) => {
      const bevestigenResult = await checkStore.bevestigen({
        inhoud: 'Koppeling omruil verwijderen?',
      });
      if (bevestigenResult.type === EResultType.Annuleren) {
        return;
      }

      await api.v2.transport.opdracht.omruil.verwijderenOmruil({
        trsRegID,
      });

      uitstaandTabelContext.onRequestRefresh();
    },
    [uitstaandTabelContext.onRequestRefresh],
  );

  const handlHerstelenAfmelding = useCallback(
    async (trsRegID: number) => {
      const regel = row.regels.find((x) => x.TrsRegID === trsRegID)!;

      const isRetourMetContractwissel =
        regel.contract !== null &&
        regel.regelsoort.NaamEnum === ETransportopdrachtRegelsoort.Retour &&
        regel.OmruilID !== null;

      const bevestigenResult = await checkStore.bevestigen({
        inhoud: (
          <span>
            Wil je de afmelding voor deze regel ongedaan maken?
            {isRetourMetContractwissel && (
              <>
                <br />
                <br />
                Let op: een eventuele vordering voor de kosten VTB wordt niet ongedaan gemaakt. Dit
                dient dan zelf nog gedaan te worden.
              </>
            )}
          </span>
        ),
      });
      if (bevestigenResult.type === EResultType.Annuleren) {
        return;
      }

      // De status Geannuleerd apart afhandelen
      const params = { trsRegIDs: [trsRegID] };
      const checkData = await api.v2.transport.opdracht.uitstaand.checkHerstellenAnnulerenOpdrachtregels(
        params,
      );
      if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
        return;
      }

      if (row.opdrachtstatus.Status === ERegelstatusTransport.GEANNULEERD) {
        const resultaat = await checkStore.bevestigen({
          inhoud: `Annulering ongedaan maken?`,
        });
        if (resultaat.type === EResultType.Annuleren) {
          return;
        }
        await api.v2.transport.opdracht.uitstaand.herstellenAnnulerenOpdrachtregels(params);
        uitstaandTabelContext.onRequestRefresh();
        return;
      }

      // Herstel afmelden
      const resultaat = await checkStore.bevestigen({
        inhoud: `Afmelden ongedaan maken?`,
      });
      if (resultaat.type === EResultType.Annuleren) {
        return;
      }

      await api.v2.transport.herstellenAfmeldenOpdrachtregels({
        trsRegIDs: [trsRegID],
      });

      uitstaandTabelContext.onRequestRefresh();
    },
    [row.opdrachtstatus.Status, uitstaandTabelContext.onRequestRefresh],
  );

  const handlWijzigenProduct = useCallback(async (trsRegID: number) => {
    const regelsResult = await api.v2.transport.opdracht.ophalenOpdrachtregels({
      filterSchema: { filters: [{ naam: 'IDS', data: [trsRegID] }] },
    });

    if (regelsResult.regels[0].product === null) {
      return null;
    }
    setWijzigenProductDialoogTonen(regelsResult.regels[0].product.ProdID);
  }, []);

  const handlVerwijderenOpdrachtregels = useCallback(
    async (trsRegIDs: number[]) => {
      const bevestigenResult = await checkStore.bevestigen({
        inhoud: (
          <span>
            Geselecteerde regels verwijderen?
            <br />
            Dit kan niet ongedaan gemaakt worden.
          </span>
        ),
      });
      if (bevestigenResult.type === EResultType.Annuleren) {
        return;
      }

      await api.v2.transport.opdracht.verwijderenOpdrachtregels({
        trsRegIDs,
      });

      uitstaandTabelContext.onRequestRefresh();
    },
    [uitstaandTabelContext.onRequestRefresh],
  );

  const handleVerwijderen = useCallback(
    async (regel: IRegelRow) => {
      const bevestigenResult = await checkStore.bevestigen({
        inhoud: 'Koppeling verwijderen?',
      });
      if (bevestigenResult.type === EResultType.Annuleren) {
        return;
      }

      // Afhandeling hangt af de regelsoort
      if (regel.regelsoort.Code === 'L') {
        const checkData = await api.v2.transport.reservering.levering.checkVerwijderenProductReservering(
          {
            trsRegIDs: [regel.TrsRegID],
          },
        );
        const controleerResult = await checkStore.controleren({
          checkData,
        });
        if (controleerResult.type === EResultType.Annuleren) {
          return;
        }

        await api.v2.transport.reservering.levering.verwijderenProductReservering({
          trsRegIDs: [regel.TrsRegID],
        });
      } else {
        const checkData = await api.v2.transport.reservering.levering.checkVerwijderenProductReservering(
          {
            trsRegIDs: [regel.TrsRegID],
          },
        );
        const controleerResult = await checkStore.controleren({
          checkData,
        });
        if (controleerResult.type === EResultType.Annuleren) {
          return;
        }
        await api.v2.transport.reservering.retour.verwijderenProductReservering({
          trsRegIDs: [regel.TrsRegID],
        });
      }

      uitstaandTabelContext.onRequestRefresh();
    },
    [uitstaandTabelContext.onRequestRefresh],
  );

  const handleAnnuleren = useCallback(
    async (trsRegIDs: number[]) => {
      const checkData = await api.v2.transport.opdracht.uitstaand.checkAnnulerenOpdrachtregels({
        trsRegIDs,
      });
      const controleerResult = await checkStore.controleren({
        checkData,
      });
      if (controleerResult.type === EResultType.Annuleren) {
        return;
      }

      onAnnulerenOpdrachtregelsDialoogStateChange({ trsRegIDs });

      uitstaandTabelContext.onRequestRefresh();
    },
    [uitstaandTabelContext.onRequestRefresh],
  );

  const keyExtractor = useCallback((row: IRegelRow) => row.TrsRegID, []);

  const kolommen = useMemo<TypedColumn<IRegelRow>[]>(
    () => [
      {
        name: '__soort' as any,
        title: 'Srt.',
      },
      {
        name: 'Aantal' as any,
        title: '#',
      },
      {
        name: '__product' as any,
        title: 'Merk/type',
      },
      {
        name: '__productsoort' as any,
        title: 'Cat.',
      },
      {
        name: '__nieuw' as any,
        title: (
          <UitlegTooltip inhoud={'N(ieuw) of G(ebruikt) product'}>
            <span>N/G</span>
          </UitlegTooltip>
        ) as any,
      },
      {
        name: '__referentie' as any,
        title: 'Ref.',
      },
      {
        name: '__voorraadReservering' as any,
        title: 'Res.',
      },
      {
        name: '__voorraadInfo' as any,
        title: 'Vrd.',
      },
      {
        name: '__contractnummer' as any,
        title: 'Cnt.nr',
      },
      {
        name: '__relatienaam' as any,
        title: 'Relatienaam',
      },
      {
        name: '__afmeldenOpdracht',
        title: 'Status',
      },
      {
        name: 'Statusdatum',
        title: 'Datum',
      },
      {
        name: '__gekoppeldBestand' as any,
        title: 'Gekoppeld bestand',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRegelRow>[]>(
    () => [
      {
        columnName: '__soort' as any,
        width: 60,
      },
      {
        columnName: 'Aantal',
        width: 30,
      },
      {
        columnName: '__product' as any,
        width: 250,
      },
      {
        columnName: '__nieuw' as any,
        width: 50,
      },
      {
        columnName: '__productsoort' as any,
        width: 60,
      },
      {
        columnName: '__referentie' as any,
        width: 85,
      },
      {
        columnName: '__voorraadReservering' as any,
        width: 75,
      },
      {
        columnName: '__voorraadInfo' as any,
        width: 50,
      },
      {
        columnName: '__contractnummer' as any,
        width: 100,
      },
      {
        columnName: '__relatienaam' as any,
        width: 250,
      },
      {
        columnName: '__afmeldenOpdracht',
        width: 100,
      },
      {
        columnName: 'Statusdatum',
        width: 125,
      },
      {
        columnName: '__gekoppeldBestand' as any,
        width: 200,
      },
    ],
    [],
  );

  const { isBezigMetUploaden, muteerBestanden, uploadProgresses, bestanden } = useUpload(
    useMemo(
      () => ({
        automatischUploaden: true,
        initieleBestanden: row.bestanden.map((x) => ({
          type: BestandType.ASPDrive,
          bestand: x,
        })),
        onAlleBestandenGeuploaded: async (bestandIDs) => {
          await api.v2.transport.muterenBestandenOpdracht({
            trsOpdID: row.TrsOpdID,
            bestIDs: bestandIDs,
          });
          await ophalenTransportopdrachtBestanden();
        },
      }),
      [row.bestanden, row.TrsOpdID, transportopdrachtBestanden],
    ),
  );

  const [transportregelKoppelenKoppeling, setTransportregelKoppelenKoppeling] = useState<
    Record<number, boolean>
  >({});

  if (
    transportopdrachtBestanden.state === ERemoteDataState.Pending ||
    transportopdrachtBestandinfos.state === ERemoteDataState.Pending
  ) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <div
        className="d-flex flex-column p-3"
        style={{
          backgroundColor: EKleur.HeelLichtGrijs,
          borderBottom: `1px solid ${EKleur.LichtGrijs}`,
        }}
      >
        <MenuLayout
          menu={
            <div className="d-flex">
              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-2"
                style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                disabled={regelsSelectie.length !== 2}
                onClick={() => handlKoppelenOmruil(regelsSelectie)}
              >
                {/* <IcoonToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} /> */}
                <span className="ml-2">Koppelen omruil</span>
              </button>

              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-2"
                style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                disabled={regelsSelectie.length !== 1}
                onClick={() => handlVerwijderenOmruil(regelsSelectie[0])}
              >
                {/* <IcoonVerwijderen style={{ width: 16, height: 16, fill: Kleur.Grijs }} /> */}
                <span className="ml-2">Verwijderen omruil</span>
              </button>

              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-2"
                style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                disabled={regelsSelectie.length !== 1}
                onClick={() => handlWijzigenProduct(regelsSelectie[0])}
              >
                {/* <IcoonVerwijderen style={{ width: 16, height: 16, fill: Kleur.Grijs }} /> */}
                <span className="ml-2">Productgegevens wijzigen</span>
              </button>

              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-2"
                style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                disabled={regelsSelectie.length === 0}
                onClick={() => handlVerwijderenOpdrachtregels(regelsSelectie)}
              >
                <IconVerwijderen style={{ width: 16, height: 16, fill: EKleur.Grijs }} />
                <span className="ml-2">Verwijderen regels</span>
              </button>

              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-2"
                style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                disabled={regelsSelectie.length === 0}
                onClick={() => handleAnnuleren(regelsSelectie)}
              >
                {/* <IcoonVerwijderen style={{ width: 16, height: 16, fill: Kleur.Grijs }} /> */}
                <span className="ml-2">Annuleren</span>
              </button>

              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-2"
                style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                disabled={regelsSelectie.length !== 1}
                onClick={() => handlHerstelenAfmelding(regelsSelectie[0])}
              >
                <IconHerstel style={{ width: 16, height: 16, fill: EKleur.Grijs }} />
                <span className="ml-2">Ongedaan maken afmelding/annulering</span>
              </button>

              {/* <button
                className="btn btn-sm btn-light d-flex align-items-center ml-2"
                style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                // disabled={true}
                disabled={regelsSelectie.length === 0}
                onClick={async () => {
                  if (
                    (
                      await checkStore.bevestigen({
                        titel: `Wil je voor de geselecteerde regels een nieuwe opdracht maken?`,
                      })
                    ).type === EResultType.Annuleren
                  ) {
                    return;
                  }

                  const result = await api.v2.transport.opdracht.kopieerenOpdrachtregels({
                    trsRegIDs: regelsSelectie,
                    trsOpdID: null,
                  });
                }}
              >
                <span className="ml-2">Kopie planning maken</span>
              </button> */}

              <div className="flex-fill" />
              <BijlageKnop
                selectieModus={ESelectieModus.Meerdere}
                onBijgevoegd={(bijlagen) =>
                  muteerBestanden((bestanden) => [...bestanden, ...bijlagen])
                }
                bronModus={EBronModus.Alle}
                disabled={isBezigMetUploaden}
              />
            </div>
          }
          body={
            <div>
              {bestanden !== null && bestanden.length > 0 && (
                <div className="p-2">
                  <BijlagenContainer
                    bestanden={bestanden}
                    uploadProgresses={uploadProgresses}
                    onBestandenChange={(x) => muteerBestanden((_) => x)}
                    bestandenMuterenToegestaan={!isBezigMetUploaden}
                    bevestigenVerwijdering={async (bestandID) => {
                      const bestandenWaarbijTransportopdrachtBestandenNietVoorkomen = transportopdrachtBestanden.data!.bestanden.filter(
                        (x) => x.TrsRegID !== null && x.BestandID === bestandID,
                      );
                      if (bestandenWaarbijTransportopdrachtBestandenNietVoorkomen.length > 0) {
                        const bevestigenResult = await checkStore.bevestigen({
                          inhoud:
                            'Er zijn een of meerdere regels die aan een verwijderd bestand gekoppeld zijn. Doorgaan?',
                        });
                        if (bevestigenResult.type === EResultType.Annuleren) {
                          return false;
                        }
                        // Ontkoppelen bestanden die worden verwijderd
                        await api.v2.transport.opdracht.bijwerkenBestandskoppelingenVanTransportopdracht(
                          {
                            trsOpdID: row.TrsOpdID,
                            koppelingen: transportopdrachtBestanden
                              .data!.bestanden.filter(
                                (x) => x.TrsRegID !== null && x.BestandID !== bestandID,
                              )
                              .map((x) => ({
                                bestandID: x.BestandID,
                                trsRegID: x.TrsRegID!,
                              })),
                          },
                        );
                      }

                      return true;
                    }}
                  />
                </div>
              )}
              <GridStyleWrapper maxHeight={500} rowAmount={gridData.length}>
                <Grid rows={gridData} getRowId={keyExtractor} columns={kolommen}>
                  <DataTypeProvider
                    for={['__soort']}
                    formatterComponent={(props) => {
                      const rij: IRegelRow = props.row;
                      return (
                        <>
                          <span hidden>TrsRegID: {rij.TrsRegID}</span>
                          <span>
                            {rij.regelsoort.Code} {rij.OmruilID !== null ? '/O' : ''}
                          </span>
                          {/* <span>{rij.indicaties.isVermoedelijkOmruil ? ' (/O?)' : ''}</span> */}
                        </>
                      );
                    }}
                  />

                  <DataTypeProvider
                    for={[nameOf<IRegelRow>('Aantal')]}
                    formatterComponent={(props) => {
                      const rij: IRegelRow = props.row;
                      return <>{rij.Aantal}</>;
                    }}
                  />

                  <DataTypeProvider
                    for={['__productsoort']}
                    formatterComponent={(formatterProps) => {
                      const rij: IRegelRow = formatterProps.row;
                      const product =
                        rij.type !== null ? (
                          <span>{rij.type.ProductsoortnaamKort}</span>
                        ) : rij.product !== null ? (
                          <span>{rij.product.producttype.ProductsoortnaamKort}</span>
                        ) : null;
                      return <span>{product}</span>;
                    }}
                  />

                  {/* <DataTypeProvider
                    for={['__referentie']}
                    formatterComponent={(formatterProps) => {
                      const rij: IRegelRow = formatterProps.row;

                      if (rij.type !== null) {
                        if (rij.type.Bulkproduct) {
                          return <span>Bulk</span>;
                        }

                        if (rij.status.Status !== ERegelstatusTransport.NIET_AFGEMELD) {
                          // if (rij.GeenReferentiecode) {
                          //   return <span></span>;
                          // }

                          return (
                            <span>
                              <IconPijlVolgend
                                style={{ width: 15, height: 15, fill: EKleur.Grijs }}
                              />
                              {rij.Referentiecode !== null ? rij.Referentiecode : '?'}
                            </span>
                          );
                        }
                      }

                      if (rij.product !== null) {
                        return (
                          <span>
                            {rij.product.Referentiecode !== null ? rij.product.Referentiecode : '?'}
                          </span>
                        );
                      }

                      return <span></span>;
                    }}
                  /> */}

                  <DataTypeProvider
                    for={['__referentie']}
                    formatterComponent={(formatterProps) => {
                      const rij: IRegelRow = formatterProps.row;
                      return <ReferentieProductComp rij={rij} />;
                    }}
                  />

                  <DataTypeProvider
                    for={['__nieuw']}
                    formatterComponent={(formatterProps) => {
                      const rij: IRegelRow = formatterProps.row;
                      return <NieuwOfGebruiktComp rij={rij} />;
                    }}
                  />

                  <DataTypeProvider
                    for={['__voorraadInfo']}
                    formatterComponent={(formatterProps) => {
                      const rij: IRegelRow = formatterProps.row;

                      if (rij.status.Status !== ERegelstatusTransport.NIET_AFGEMELD) {
                        return <span></span>;
                      }

                      if (rij.type !== null) {
                        if (rij.type.Bulkproduct) {
                          // Bulkproduct
                          return <span></span>;
                        }
                        // Type
                        return (
                          <div className="d-flex">
                            <a
                              href="#"
                              onClick={() => {
                                setVoorraadTypeInfoDialoogState({
                                  typeID: rij.type!.TypeID,
                                  magID: row.magazijn.MagID,
                                });
                              }}
                            >
                              {/* <VoorraadIndicatie
                            status={gereserveerd ? EVoorraadStatus.Groen : EVoorraadStatus.Rood}
                          /> */}
                              <span>Info</span>
                            </a>
                          </div>
                        );
                      }

                      if (rij.product !== null) {
                        // Product
                        return (
                          <a
                            href="#"
                            // style={{ color: Kleur.DonkerGrijs }}
                            onClick={() => {
                              setProductinfoDialoogState({ id: rij.product!.ProdID! });
                            }}
                          >
                            <span>Info</span>
                          </a>
                        );
                      }

                      return <span></span>;
                    }}
                  />

                  {/* <DataTypeProvider
                    for={['__voorraad']}
                    formatterComponent={(props) => {
                      const rij: IRegelRow = props.row;
                      if (!(rij.type !== null && !rij.type.Bulkproduct)) {
                        return <span></span>;
                      }

                      const gereserveerd = rij.type.Gereserveerd !== null && rij.type.Gereserveerd;
                      // const voorraad = voorraadInfo!.aantalVrij < 0;

                      return (
                        <div className="d-flex align-items-center mt-1">
                          <a
                            href="#"
                            onClick={() => {
                              // setVoorraadDialoogState({ id: rij.TrsRegID });
                            }}
                          >
                            <VoorraadIndicatie
                              status={gereserveerd ? EVoorraadStatus.Groen : EVoorraadStatus.Rood}
                            />
                          </a>
                        </div>
                      );
                    }}
                  /> */}

                  <DataTypeProvider
                    for={['__voorraadReservering']}
                    formatterComponent={(formatterProps) => {
                      const rij: IRegelRow = formatterProps.row;

                      if (rij.type !== null && rij.type.Bulkproduct) {
                        return <span></span>;
                      }

                      if (rij.status.Status !== ERegelstatusTransport.NIET_AFGEMELD) {
                        return <span></span>;
                      }

                      return (
                        <ReserveringProductComp
                          rij={formatterProps.row}
                          magID={row.magazijn.MagID}
                          onVernieuwen={uitstaandTabelContext.onRequestRefresh}
                        />
                      );
                    }}
                  />

                  <DataTypeProvider
                    for={['__contractnummer']}
                    formatterComponent={(formatterProps) => {
                      const rij: IRegelRow = formatterProps.row;
                      return (
                        <ContractnummerComp
                          cntID={rij.contract !== null ? rij.contract.CntID : null}
                        />
                      );
                    }}
                  />

                  <DataTypeProvider
                    for={['__retourstatus']}
                    formatterComponent={(formatterProps) => {
                      const rij: IRegelRow = formatterProps.row;
                      if (rij.product === null || rij.product.retourstatus === null) {
                        return <span></span>;
                      }

                      return <span>{rij.product.retourstatus.Naam}</span>;
                    }}
                  />

                  <DataTypeProvider
                    for={['__product']}
                    formatterComponent={(formatterProps) => {
                      const rij: IRegelRow = formatterProps.row;
                      return <ProductWeergaveComp rij={rij} />;
                    }}
                  />

                  <DataTypeProvider
                    for={['XXX__product']}
                    formatterComponent={(formatterProps) => {
                      const rij: IRegelRow = formatterProps.row;

                      // Tekst die aangeeft welk product gekoppeld is/moet worden
                      // Bij geen koppeling en Levering wordt het primaire type weergegeven

                      let koppeltekst = <span>Nog Product/Type koppelen</span>;

                      if (rij.product !== null) {
                        // Product
                        koppeltekst = (
                          <span>
                            {rij.product.producttype.Merknaam +
                              ' ' +
                              rij.product.producttype.Typenaam}
                          </span>
                        );
                      } else {
                        if (rij.type !== null) {
                          // Type (bulk en geen bulk)
                          koppeltekst = (
                            <span className="d-flex align-items-center">
                              {(rij.type.Merkloos ? '' : rij.type.Merknaam + ' ') +
                                rij.type.Typenaam}{' '}
                            </span>
                          );
                        } else {
                          if (rij.contract !== null) {
                            if (rij.regelsoort.NaamEnum === 'LEVERING') {
                              const primairType = rij.contract.basis.productmodel.producttypen[0];
                              koppeltekst = (
                                <span>
                                  [{primairType.Merknaam} {primairType.Typenaam}]
                                </span>
                              );
                            } else {
                              koppeltekst = <span></span>;
                            }
                          } else {
                            koppeltekst = <span></span>;
                          }
                        }
                      }

                      // const cntID = rij.contract !== null ? rij.contract.CntID : null;

                      return <span>{koppeltekst}</span>;

                      // return (
                      //   <div className="d-flex">
                      //     <a
                      //       href="#"
                      //       // style={{ color: Kleur.DonkerGrijs }}
                      //       onClick={() => {
                      //         // Levering of Retour
                      //         switch (rij.regelsoort.NaamEnum) {
                      //           case ETransportopdrachtRegelsoort.Retour:
                      //             setKoppelLocatieproductDialoogState({
                      //               trsRegID: rij.TrsRegID,
                      //               locID:
                      //                 rij.contract !== null
                      //                   ? rij.contract.basis.locatie.LocID
                      //                   : null,
                      //               cntID,
                      //             });
                      //             break;
                      //           case ETransportopdrachtRegelsoort.Levering:
                      //             setKoppelVoorraadDialoogState({
                      //               trsRegID: rij.TrsRegID,
                      //               cntID,
                      //             });
                      //             break;
                      //         }
                      //       }}
                      //     >
                      //       <UitlegTooltip inhoud="Klik hier om een product te selecteren">
                      //         <div>{koppeltekst}</div>
                      //       </UitlegTooltip>
                      //     </a>
                      //     {(rij.type !== null || rij.product !== null) && (
                      //       <IconButton
                      //         className="hover-only ml-2"
                      //         onClick={async (ev) => {
                      //           ev.stopPropagation();
                      //           if (
                      //             (
                      //               await checkStore.bevestigen({
                      //                 titel: 'Product ontkoppelen?',
                      //               })
                      //             ).type === EResultType.Annuleren
                      //           ) {
                      //             return;
                      //           }
                      //           await api.v2.transport.reservering.levering.verwijderenProductKoppeling(
                      //             {
                      //               trsRegIDs: [rij.TrsRegID],
                      //             },
                      //           );
                      //           uitstaandTabelContext.onRequestRefresh();
                      //         }}
                      //       >
                      //         {' '}
                      //         <IconKruis
                      //           style={{
                      //             width: 18,
                      //             height: 18,
                      //             position: 'relative',
                      //             bottom: 1,
                      //           }}
                      //         />
                      //       </IconButton>
                      //     )}
                      //   </div>
                      // );
                    }}
                  />

                  <DataTypeProvider
                    for={['__ontkoppelActie']}
                    formatterComponent={(props) => {
                      const row: IRegelRow = props.row;
                      const actieOntkoppelenTonen = row.product !== null || row.type !== null;

                      if (actieOntkoppelenTonen) {
                        return (
                          <a href="#" onClick={() => handleVerwijderen(row)}>
                            <IconKruis style={{ width: 18, height: 18, fill: EKleur.Rood }} />
                          </a>
                        );
                      }

                      return null;
                    }}
                  />

                  <DataTypeProvider
                    for={['__actieMenu']}
                    formatterComponent={(props) => {
                      return (
                        <ActieMenuKnop
                          acties={[
                            {
                              text: 'Wijzigen',
                              onClick: () => null,
                            },
                          ]}
                        />
                      );
                    }}
                  />

                  <DataTypeProvider
                    for={['__relatienaam']}
                    formatterComponent={(p) => {
                      const rij: IRegelRow = p.row;
                      if (rij.RelID !== null) {
                        return <RelatieVisualisatie relID={rij.RelID} />;
                      } else {
                        return <span />;
                      }
                    }}
                  />

                  <DataTypeProvider
                    for={['__afmeldenOpdracht']}
                    formatterComponent={(props) => {
                      const row: IRegelRow = props.row;
                      const kleur: EKleur =
                        row.status.Status === ERegelstatusTransport.UITGEVOERD
                          ? EKleur.Groen
                          : row.status.Status === ERegelstatusTransport.MISLUKT
                          ? EKleur.Rood
                          : row.status.Status === ERegelstatusTransport.GEANNULEERD
                          ? EKleur.Oranje
                          : EKleur.Blauw;
                      //return <span style={{ color: kleur }}>{row.statusnaam}</span>;
                      return row.status.Status === ERegelstatusTransport.NIET_AFGEMELD ? (
                        // Afmelden
                        <a
                          href="#"
                          // onClick={() => setAfmeldenDialoogTonen(row.trsRegID)}
                          onClick={() =>
                            onAfmeldenOpdrachtDialoogStateChange({
                              trsOpdID: row.TrsOpdID,
                              trsRegID: row.TrsRegID,
                            })
                          }
                          style={{ color: kleur }}
                        >
                          <UitlegTooltip inhoud="Klik hier om de regel af te melden">
                            <div>Afmelden</div>
                          </UitlegTooltip>
                        </a>
                      ) : (
                        // Herstel afmelden/geannuleerd
                        <a
                          href="#"
                          onClick={async () => {
                            handlHerstelenAfmelding(row.TrsRegID);
                          }}
                          style={{ color: kleur }}
                        >
                          <UitlegTooltip inhoud="Klik hier om de afmelding terug te draaien">
                            <div>{row.status.Naam}</div>
                          </UitlegTooltip>
                        </a>
                      );
                    }}
                  />

                  <DataTypeProvider
                    for={['Statusdatum']}
                    formatterComponent={(props) => {
                      const rij: IRegelRow = props.row;
                      const datum =
                        rij.status.Statusdatum !== null
                          ? format(new Date(rij.status.Statusdatum), 'dd-MM-yyyy')
                          : '';
                      return <span>{datum}</span>;
                    }}
                  />

                  <DataTypeProvider
                    for={['__gekoppeldBestand']}
                    formatterComponent={(props) => {
                      const regelRow: IRegelRow = props.row;

                      if (transportregelKoppelenKoppeling[regelRow.TrsRegID]) {
                        return (
                          <MultiSelect
                            value={[]}
                            isMulti={false}
                            opties={transportopdrachtBestandinfos.data!.bestanden.map((info) => ({
                              key: info.ID,
                              weergave: info.Naam,
                            }))}
                            onChange={async ([bestandID]) => {
                              setTransportregelKoppelenKoppeling((curr) => ({
                                [regelRow.TrsRegID]: false,
                              }));

                              await api.v2.transport.opdracht.bijwerkenBestandskoppelingenVanTransportopdracht(
                                {
                                  trsOpdID: row.TrsOpdID,
                                  koppelingen: [
                                    ...transportopdrachtBestanden
                                      .data!.bestanden.filter((x) => x.TrsRegID !== null)
                                      .map((x) => ({
                                        trsRegID: x.TrsRegID!,
                                        bestandID: x.BestandID,
                                      })),
                                    {
                                      bestandID,
                                      trsRegID: regelRow.TrsRegID,
                                    },
                                  ],
                                },
                              );
                              await ophalenTransportopdrachtBestanden();
                            }}
                            autoFocus
                            onBlur={() => {
                              setTransportregelKoppelenKoppeling((curr) => ({
                                [regelRow.TrsRegID]: false,
                              }));
                            }}
                          />
                        );
                      }

                      const koppeling = transportopdrachtBestanden.data!.bestanden.find(
                        (x) => x.TrsRegID === regelRow.TrsRegID,
                      );

                      if (koppeling === undefined) {
                        return (
                          <a
                            href="#"
                            onClick={(ev) => {
                              ev.preventDefault();

                              setTransportregelKoppelenKoppeling((curr) => ({
                                ...curr,
                                [regelRow.TrsRegID]: true,
                              }));
                            }}
                          >
                            Bestand koppelen
                          </a>
                        );
                      }
                      const bestand = transportopdrachtBestandinfos.data!.bestanden.find(
                        (x) => x.ID === koppeling.BestandID,
                      );
                      if (bestand === undefined) {
                        return <span>Laden...</span>;
                      }
                      return (
                        <span className="d-flex align-items-center">
                          <span>{bestand.Naam}</span>
                          <button
                            className="ml-1 d-flex align-items-center justify-content-center"
                            style={{
                              border: 0,
                              outline: 0,
                              background: 0,
                              padding: 0,
                            }}
                            onClick={async () => {
                              await api.v2.transport.opdracht.bijwerkenBestandskoppelingenVanTransportopdracht(
                                {
                                  trsOpdID: row.TrsOpdID,
                                  koppelingen: transportopdrachtBestanden
                                    .data!.bestanden.filter(
                                      (x) =>
                                        x.TrsRegID !== null && x.TrsRegID !== regelRow.TrsRegID,
                                    )
                                    .map((x) => ({
                                      trsRegID: x.TrsRegID!,
                                      bestandID: x.BestandID,
                                    })),
                                },
                              );
                              await ophalenTransportopdrachtBestanden();
                            }}
                          >
                            <IconKruis style={{ width: 16, height: 16, fill: EKleur.Grijs }} />
                          </button>
                        </span>
                      );
                    }}
                  />

                  <SelectionState
                    selection={regelsSelectie}
                    onSelectionChange={(x) => handleRegelsSelectieChange(x as number[])}
                  />
                  <EditingState
                    onAddedRowsChange={() => {
                      setToevoegenTrsOpdID(row.TrsOpdID);
                    }}
                    onCommitChanges={(changes) => {
                      if (changes.deleted === undefined) {
                        return;
                      }
                      const deleted = changes.deleted;
                      const id = deleted[deleted.length - 1];
                    }}
                    onEditingRowIdsChange={(rowIds) => {
                      const id = rowIds[rowIds.length - 1] as number;
                    }}
                  />
                  <Table cellComponent={CellComponent} />
                  <TableColumnResizing defaultColumnWidths={kolomBreedtes as any} />
                  <TableEditColumn
                    width={35}
                    showEditCommand
                    // showAddCommand
                    // showDeleteCommand
                    cellComponent={DXTableEditColumnCellComponent}
                    commandComponent={DXTableEditColumnCommandComponent}
                  />
                  <TableSelection cellComponent={DXTableCheckboxComponent} />
                  <TableHeaderRow />
                </Grid>
              </GridStyleWrapper>
            </div>
          }
        />
      </div>

      {afmeldenOpdrachtDialoogState !== null && (
        <AfmeldenDialoog
          open
          onSuccess={() => {
            onAfmeldenOpdrachtDialoogStateChange(null);
            uitstaandTabelContext.onRequestRefresh();
          }}
          onAnnuleren={() => onAfmeldenOpdrachtDialoogStateChange(null)}
          trsOpdID={afmeldenOpdrachtDialoogState.trsOpdID}
          trsRegIDs={[afmeldenOpdrachtDialoogState.trsRegID]}
        />
      )}
      {toevoegenTrsOpdID !== null && (
        <ToevoegenRegelsDialoog
          open
          trsOpdID={toevoegenTrsOpdID}
          onSuccess={() => {
            setToevoegenTrsOpdID(null);
            uitstaandTabelContext.onRequestRefresh();
          }}
          onAnnuleren={() => setToevoegenTrsOpdID(null)}
        />
      )}
      {reserverenVoorraadDialoogTonen && (
        <ReserverenVoorraadDialoog
          open
          onSuccess={(data) => {
            reserverenProduct(data.tabblad, data.typeID, data.prodID);
            setReserverenVoorraadDialoogTonen(false);
            uitstaandTabelContext.onRequestRefresh();
          }}
          onAnnuleren={() => setReserverenVoorraadDialoogTonen(false)}
          cntID={cntID!}
        />
      )}
      {reserverenProductDialoogTonen !== null && (
        <ReserverenProductDialoog
          open
          onSuccess={(data) => {
            koppelenProduct(data);
            setReserverenProductDialoogTonen(null);
            uitstaandTabelContext.onRequestRefresh();
          }}
          onAnnuleren={() => setReserverenProductDialoogTonen(null)}
          locID={row.locatie.LocID}
        />
      )}
      {wijzigenProductDialoogTonen !== null && (
        <WijzigenProductDialoog
          open
          prodID={wijzigenProductDialoogTonen}
          onSuccess={(data) => {
            setWijzigenProductDialoogTonen(null);
            uitstaandTabelContext.onRequestRefresh();
          }}
          onAnnuleren={() => setWijzigenProductDialoogTonen(null)}
        />
      )}
      {annulerenOpdrachtregelsDialoogState !== null && (
        <AnnulerenDialoog
          open
          trsRegIDs={regelsSelectie}
          onSuccess={() => {
            onAnnulerenOpdrachtregelsDialoogStateChange(null);
            uitstaandTabelContext.onRequestRefresh();
          }}
          onAnnuleren={() => onAnnulerenOpdrachtregelsDialoogStateChange(null)}
        />
      )}
      {koppelVoorraadDialoogState && (
        <KoppelVoorraadDialoog
          open
          cntID={
            koppelVoorraadDialoogState.cntID !== null ? koppelVoorraadDialoogState.cntID : undefined
          }
          onSuccess={async (result) => {
            let type = null;
            let product = null;

            switch (result.tabblad) {
              case ETabblad.Ingepakt:
                // Bepaal of er vrije voorraad is
                const voorraad = (
                  await api.v2.voorraad.ophalenVoorraadinfo({
                    magID: row.magazijn.MagID,
                    typeIDs: [result.typeID!],
                  })
                ).voorraad[0];

                type = {
                  typeID: result.typeID!,
                  bulkproduct: false,
                  gereserveerd: voorraad.aantalVrij > 0 ? true : false,
                };
                break;
              case ETabblad.Uitgepakt:
                product = { prodID: result.prodID! };
                break;
              case ETabblad.Bulk:
                type = { typeID: result.bulkTypeID!, bulkproduct: true };
                break;
            }

            const params = { trsRegID: koppelVoorraadDialoogState.trsRegID, type, product };
            await api.v2.transport.opdracht.koppelenProductVoorTransportregel(params);

            uitstaandTabelContext.onRequestRefresh();

            setKoppelVoorraadDialoogState(null);
          }}
          onAnnuleren={() => setKoppelVoorraadDialoogState(null)}
        />
      )}
      {koppelLocatieproductDialoog !== null && (
        <KoppelLocatieProductDialoog
          open
          locID={
            koppelLocatieproductDialoog.locID !== null
              ? koppelLocatieproductDialoog.locID
              : undefined
          }
          cntID={
            koppelLocatieproductDialoog.cntID !== null
              ? koppelLocatieproductDialoog.cntID
              : undefined
          }
          onSuccess={async (prodID) => {
            const params = {
              trsRegID: koppelLocatieproductDialoog.trsRegID,
              type: null,
              product: { prodID },
            };
            await api.v2.transport.opdracht.koppelenProductVoorTransportregel(params);
            setKoppelLocatieproductDialoogState(null);
          }}
          onAnnuleren={() => setKoppelLocatieproductDialoogState(null)}
        />
      )}
      {productinfoDialoogState !== null && (
        <ProductinfoDialoog
          open
          id={productinfoDialoogState.id}
          onSuccess={() => {
            setProductinfoDialoogState(null);
            uitstaandTabelContext.onRequestRefresh();
          }}
          onAnnuleren={() => setProductinfoDialoogState(null)}
        />
      )}
      {voorraadTypeInfoDialoogState !== null && (
        <VoorraadTypeInfoDialoog
          open
          typeID={voorraadTypeInfoDialoogState.typeID}
          magID={voorraadTypeInfoDialoogState.magID}
          onSuccess={() => setVoorraadTypeInfoDialoogState(null)}
          onAnnuleren={() => setVoorraadTypeInfoDialoogState(null)}
        />
      )}
      {koppelVoorraadDialoogState && (
        <KoppelVoorraadDialoog
          open
          cntID={
            koppelVoorraadDialoogState.cntID !== null ? koppelVoorraadDialoogState.cntID : undefined
          }
          onSuccess={async (result) => {
            let type = null;
            let product = null;

            switch (result.tabblad) {
              case ETabblad.Ingepakt:
                // Bepaal of er vrije voorraad is
                const voorraad = (
                  await api.v2.voorraad.ophalenVoorraadinfo({
                    magID: row.magazijn.MagID,
                    typeIDs: [result.typeID!],
                  })
                ).voorraad[0];

                type = {
                  typeID: result.typeID!,
                  bulkproduct: false,
                  gereserveerd: voorraad.aantalVrij > 0 ? true : false,
                };
                break;
              case ETabblad.Uitgepakt:
                product = { prodID: result.prodID! };
                break;
              case ETabblad.Bulk:
                type = { typeID: result.bulkTypeID!, bulkproduct: true };
                break;
            }

            const params = { trsRegID: koppelVoorraadDialoogState.trsRegID, type, product };
            await api.v2.transport.opdracht.koppelenProductVoorTransportregel(params);

            uitstaandTabelContext.onRequestRefresh();

            setKoppelVoorraadDialoogState(null);
          }}
          onAnnuleren={() => setKoppelVoorraadDialoogState(null)}
        />
      )}
      {koppelLocatieproductDialoog !== null && (
        <KoppelLocatieProductDialoog
          open
          locID={
            koppelLocatieproductDialoog.locID !== null
              ? koppelLocatieproductDialoog.locID
              : undefined
          }
          cntID={
            koppelLocatieproductDialoog.cntID !== null
              ? koppelLocatieproductDialoog.cntID
              : undefined
          }
          onSuccess={async (prodID) => {
            const params = {
              trsRegID: koppelLocatieproductDialoog.trsRegID,
              type: null,
              product: { prodID },
            };
            await api.v2.transport.opdracht.koppelenProductVoorTransportregel(params);
            setKoppelLocatieproductDialoogState(null);
          }}
          onAnnuleren={() => setKoppelLocatieproductDialoogState(null)}
        />
      )}
    </>
  );
};

export default Regels;
