import React from 'react';
import TableData, { ITableDataProps } from '../TableData';
import { IconChevronRight } from '../../../../Icons';
import { Kleur } from '../../../../../bedrijfslogica/constanten';
import styled from 'styled-components';

const Container = styled.div`
  animation: animate 2s ease-in-out infinite;

  @keyframes animate {
    0% {
      transform: translateX(-5px);
      opacity: 0;
    }
    80% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      transform: translateX(8px);
    }
  }
`;

export interface IVigerendTableDataProps<TRow> {
  row?: TRow;
  isGefocust?: boolean;
  isGehovered?: boolean;
  heeftUitgeklapteRijOnderZich: boolean;
  isVigerend: boolean;
  rowIndex: number;
  style?: React.CSSProperties;
}

const StandaardVigerendTableData = <TRow extends any>(props: IVigerendTableDataProps<TRow>) => {
  if (!props.isVigerend) {
    return (
      <TableData
        tableKey={null}
        heeftUitgeklapteRijOnderZich={props.heeftUitgeklapteRijOnderZich}
        style={{
          padding: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          ...props.style,
        }}
        isGehovered={props.isGehovered}
        isGefocust={props.isGefocust}
        rowIndex={props.rowIndex}
      />
    );
  }

  return (
    <TableData
      tableKey={null}
      heeftUitgeklapteRijOnderZich={props.heeftUitgeklapteRijOnderZich}
      style={{
        padding: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...props.style,
      }}
      isGehovered={props.isGehovered}
      isGefocust={props.isGefocust}
      rowIndex={props.rowIndex}
    >
      <Container>
        <IconChevronRight
          style={{ fill: Kleur.Grijs, width: 24, height: 24, position: 'relative', top: 1 }}
        />
      </Container>
    </TableData>
  );
};

export default StandaardVigerendTableData;
