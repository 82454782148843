import React from 'react';
import VinkVeld from '../../formulier/VinkVeld';
import { IFilter } from '../index';
import { Kleur } from '../../../bedrijfslogica/constanten';
import InactiefOverlay from '../../InactiefOverlay';
import { IconKruis } from '../../Icons';

interface IProps<TNaam extends string> {
  filter: IFilter<TNaam>;
  data: any;
  onDataChange: (value: any) => void;
  state?: any;
  onStateChange: (value: any) => void;
  toepassen: () => void;
  isActief: boolean;
  onIsActiefChange: (actief: boolean) => void;
  setFilterState: (filter: TNaam, value: any) => void;
  setFilterData: (filter: TNaam, value: any) => void;
  setFilterIsActief: (filter: TNaam, actief: boolean) => void;
  getFilterIsActief: (filter: TNaam) => boolean;
  enkelProgrammatischAangestuurd: boolean;
}

const FilterItem = <TNaam extends string>(props: IProps<TNaam>) => {
  const {
    toepassen,
    data,
    filter,
    onDataChange,
    isActief,
    onIsActiefChange,
    state,
    onStateChange,
  } = props;
  const { altijdWeergevenInBalk, naam, weergave } = filter;

  const Weergave = weergave;

  return (
    <div
      className="d-flex align-items-center"
      style={{
        background: props.enkelProgrammatischAangestuurd ? 'rgb(234, 244, 255)' : undefined,
        borderRadius: 3,
        border: props.enkelProgrammatischAangestuurd ? `1px solid ${Kleur.LichtBlauw}` : undefined,
        minHeight: 40,
      }}
    >
      {filter.actiefMuteerbaar !== false && (
        <div className="ml-2">
          {filter.altijdWeergevenInBalk ? (
            <VinkVeld aangevinkt={isActief || false} onGewijzigd={onIsActiefChange} />
          ) : (
            <button
              style={{ border: 0, outline: 0, background: 0, padding: 0 }}
              onClick={() => onIsActiefChange(false)}
            >
              <IconKruis style={{ width: 22, height: 22, fill: Kleur.Grijs }} />
            </button>
          )}
        </div>
      )}
      <div className="ml-1 d-flex flex-column flex-fill">
        {/*<InactiefOverlay*/}
        {/*  onClick={() => onIsActiefChange(true)}*/}
        {/*  isInactief={!isActief}*/}
        {/*  element={*/}
        <div className={'p-1 pr-2 pl-2 d-flex flex-column flex-fill justify-content-center'}>
          <Weergave
            data={data}
            onDataChange={onDataChange}
            toepassen={isActief ? toepassen : () => {}}
            state={state}
            onStateChange={onStateChange}
            isActief={isActief}
            setIsActief={onIsActiefChange}
            getFilterIsActief={props.getFilterIsActief}
            setFilterState={props.setFilterState}
            setFilterData={props.setFilterData}
            setFilterIsActief={props.setFilterIsActief}
          />
        </div>
        {/*  }*/}
        {/*/>*/}
      </div>
      {/*{onVerwijderenClick !== undefined && (*/}
      {/*  <a href="#" onClick={onVerwijderenClick}>*/}
      {/*    <IcoonKruis*/}
      {/*      style={{*/}
      {/*        fill: Kleur.Grijs,*/}
      {/*        width: 16,*/}
      {/*        height: 16,*/}
      {/*        position: 'relative',*/}
      {/*        bottom: 1,*/}
      {/*        marginRight: 2,*/}
      {/*      }}*/}
      {/*    />*/}
      {/*  </a>*/}
      {/*)}*/}
      {/*<div className="ml-2"></div>*/}
    </div>
  );
};

export default FilterItem;
