import React, { SVGProps, useContext, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { EFunctioneleIcon, functioneleIconMap, IconKruis } from '../../../Icons';
import { Kleur as EKleur } from '../../../../bedrijfslogica/constanten';
import UitlegTooltip from '../../../formulier/UitlegTooltip';
import {
  IOphalenOpdrachtenResultElementV2,
  IOphalenOpdrachtregelsResultElementV2,
} from '../../../../../../shared/src/api/v2/transport/opdracht';
import { IconButton } from '../../../locatie/AdresVisualisatie/style';
import api from '../../../../api';
import { RootStoreContext } from '../../../../stores/RootStore';
import { EResultType } from '../../../../stores/CheckStore';
import { ETransportopdrachtRegelsoort } from '../../../../bedrijfslogica/enums';
import { EVoorraadTabblad } from '../../../../paginas/Transport/Opdrachten/reservering/KoppelenProductDialoog/VoorraadTabblad';
import { EKoppelenProductDialoogTabblad } from '../../../../paginas/Transport/Opdrachten/reservering/KoppelenProductDialoog';

/**
 * Hiermee selecteer je een product (in voorraad bij een levering of op een locatie bij een retour) en koppel
 * je deze aan een transportopdrachtregel.
 */

export interface IRegelXXX extends IOphalenOpdrachtregelsResultElementV2 {
  opdracht: IOphalenOpdrachtenResultElementV2;
}

interface IProps extends RouteComponentProps {
  rij: IRegelXXX;
  onRequestRefresh: () => void;
  koppelDialoogState: IKoppelDialoogState | null;
  onKoppelDialoogStateChange: (state: IKoppelDialoogState | null) => void;
}

export interface IKoppelDialoogState {
  trsRegID: number;
  cntID: number | null;
  locID: number | null;
  tabblad: EKoppelenProductDialoogTabblad;
  voorraadTabblad?: EVoorraadTabblad;
  magID?: number;
}

const ProductReservering: React.FC<IProps> = observer((props) => {
  const { checkStore } = useContext(RootStoreContext);

  const rij = props.rij;

  // const [
  //   koppelVoorraadDialoogState,
  //   setKoppelVoorraadDialoogState,
  // ] = useState<IKoppelVoorraadDialoogState | null>(null);
  //
  // const [
  //   koppelLocatieproductDialoog,
  //   setKoppelLocatieproductDialoogState,
  // ] = useState<IKoppelLocatieproductDialoogState | null>(null);

  let koppeltekst = <span>Nog Product/Type koppelen</span>;

  if (rij.product !== null) {
    // Product
    koppeltekst = (
      <span>{rij.product.producttype.Merknaam + ' ' + rij.product.producttype.Typenaam}</span>
    );
  } else {
    if (rij.type !== null) {
      // Type (bulk en geen bulk)
      koppeltekst = (
        <span className="d-flex align-items-center">
          {(rij.type.Merkloos ? '' : rij.type.Merknaam + ' ') + rij.type.Typenaam}{' '}
        </span>
      );
    } else {
      if (rij.contract !== null) {
        if (rij.regelsoort.NaamEnum === 'LEVERING') {
          const primairType =
            rij.contract.basis.productmodel.producttypen.length !== 0
              ? rij.contract.basis.productmodel.producttypen[0]
              : null;
          if (primairType !== null) {
            koppeltekst = (
              <span>
                [{primairType.Merknaam} {primairType.Typenaam}]
              </span>
            );
          } else {
            koppeltekst = <span>[{rij.contract.basis.productmodel.Modelcode}]</span>;
          }
        } else {
          koppeltekst = <span></span>;
        }
      } else {
        koppeltekst = <span>Selecteer product</span>;
      }
    }
  }

  const cntID = rij.contract !== null ? rij.contract.CntID : null;

  return (
    <>
      <div className="d-flex">
        <a
          href="#"
          // style={{ color: Kleur.DonkerGrijs }}
          onClick={() => {
            // Levering of Retour
            switch (rij.regelsoort.NaamEnum) {
              case ETransportopdrachtRegelsoort.Retour:
                props.onKoppelDialoogStateChange({
                  trsRegID: rij.TrsRegID,
                  locID: rij.contract !== null ? rij.contract.basis.locatie.LocID : null,
                  cntID,
                  tabblad: EKoppelenProductDialoogTabblad.Locatie,
                  magID: rij.opdracht.magazijn.MagID,
                });
                break;
              case ETransportopdrachtRegelsoort.Levering:
                props.onKoppelDialoogStateChange({
                  trsRegID: rij.TrsRegID,
                  cntID,
                  voorraadTabblad:
                    cntID !== null ? EVoorraadTabblad.Uitgepakt : EVoorraadTabblad.Bulk,
                  locID: rij.contract !== null ? rij.contract.basis.locatie.LocID : null,
                  tabblad: EKoppelenProductDialoogTabblad.Voorraad,
                  magID: rij.opdracht.magazijn.MagID,
                });
                break;
            }
          }}
        >
          <span>{koppeltekst}</span>
        </a>
        {(rij.type !== null || rij.product !== null) && (
          <IconButton
            className="hover-only ml-2"
            onClick={async (ev) => {
              ev.stopPropagation();
              if (
                (
                  await checkStore.bevestigen({
                    inhoud: 'Product ontkoppelen?',
                  })
                ).type === EResultType.Annuleren
              ) {
                return;
              }
              await api.v2.transport.reservering.levering.verwijderenProductKoppeling({
                trsRegIDs: [rij.TrsRegID],
              });
              props.onRequestRefresh();
            }}
          >
            {' '}
            <IconKruis
              style={{
                width: 18,
                height: 18,
                position: 'relative',
                bottom: 1,
              }}
            />
          </IconButton>
        )}
      </div>
    </>
  );
});

export default withRouter(ProductReservering);
