import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useMemo } from 'react';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import * as Yup from 'yup';
import api from '../../../../../../api';
import LoadingSpinner from '../../../../../../components/Gedeeld/LoadingSpinner';
import Dialoog from '../../../../../../components/dialogen/Dialoog';
import SponsorEventSelectie from '../../../../../../components/formulier/SponsorEventSelectie';
import IDialoogProps from '../../../../../../core/IDialoogProps';
import nameOf from '../../../../../../core/nameOf';
import { ISponsorItem } from '../../../../../../../../shared/src/api/v2/sponsoringv2/item';
import SponsorItemSelectie from '../../../../../../components/formulier/SponsorItemSelectie';

interface IDialoogResult {}

interface IProps extends IDialoogProps<IDialoogResult> {
  koppelBasisID: number;
}

interface IFormikValues {
  itemcategorie: number | null;
  naam: string;
}

const KoppelenDialoog: React.FC<IProps> = observer((props) => {
  const { dialoogIndex, onAnnuleren } = props;
  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      let params: ISponsorItem = {
        EventID: props.koppelBasisID,
        Itemcategorie: values.itemcategorie,
        Naam: values.naam,
      };

      await api.v2.sponsoringv2.item.toevoegenOfWijzigenItem(params);

      actions.setSubmitting(false);
      props.onSuccess({});
    },
    [],
  );

  const initialValues = useMemo<IFormikValues | null>(() => {
    return {
      itemcategorie: null,
      naam: '',
    };
  }, []);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        // itemcategorie: Yup.number().required(),
        naam: Yup.string().required(),
      }),
    [],
  );

  return (
    <Dialoog index={dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Koppelen item</ModalTitle>
      </ModalHeader>
      {initialValues === null ? (
        <LoadingSpinner />
      ) : (
        <Formik<IFormikValues>
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
          render={(formikProps: FormikProps<IFormikValues>) => {
            const { submitForm, isValid, isSubmitting } = formikProps;

            return (
              <>
                <ModalBody>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-12 mt-3">
                        <label>Naam</label>
                        <Field
                          name={nameOf<IFormikValues>('naam')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field } = fieldProps;

                            return (
                              <>
                                <input
                                  type="text"
                                  className="form-control"
                                  {...field}
                                  placeholder="B.v. Vermelding op TV-scherm of Evenement op universiteitsterrein"
                                />
                              </>
                            );
                          }}
                        />
                      </div>
                      <div className="col-12 mt-3">
                        <label>Item categorie</label>
                        <Field
                          name={nameOf<IFormikValues>('itemcategorie')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <>
                                <SponsorItemSelectie
                                  id={field.value}
                                  onChange={(value) => form.setFieldValue(field.name, value)}
                                ></SponsorItemSelectie>
                              </>
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </ModalBody>

                <ModalFooter className="d-flex flex-row justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    style={{ width: 100 }}
                    disabled={!isValid || isSubmitting}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={onAnnuleren}
                    style={{ width: 100 }}
                    disabled={isSubmitting}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Dialoog>
  );
});

export default KoppelenDialoog;
