import { Formik } from 'formik';
import React, { useCallback, useContext, useState, useMemo, useEffect } from 'react';
import { ModalFooter, ModalTitle } from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/ModalHeader';
import { IOphalenPersonenResultElementV2 } from '../../../../../shared/src/api/v2/persoon/persoon';
import { IOphalenContactpersonenResultElement } from '../../../../../shared/src/api/v2/relatie/contactpersoon';
import api from '../../../api';
import IDialoogProps from '../../../core/IDialoogProps';
import Dialoog from '../../dialogen/Dialoog';
import LoadingSpinner from '../../Gedeeld/LoadingSpinner';
import Tabblad, { ITabblad } from '../../layout/Tabblad';
import PersoonTab from './PersoonTab';
import DetailTab from './DetailTab';
import TechnischeInformatieKnop from '../../TechnischeInformatieKnop';
import VervangtTab from './VervangtTab';

interface IProps extends IDialoogProps<{}> {
  persID: number;
}

export interface IContext {
  persoon: IOphalenPersonenResultElementV2 | null;
  persoonRelaties: IOphalenContactpersonenResultElement[] | null;
}

export const PersoonContext = React.createContext<IContext>({
  persoon: null,
  persoonRelaties: null,
});

enum ETablad {
  Persoon,
  Detail,
  Vervangen,
}

export interface IFormikValues {
  // notities: string | null;
}

const PersooninfoDialoog: React.FC<IProps> = (props) => {
  const { dialoogIndex, onAnnuleren, onSuccess, open } = props;
  const [tabblad, setTabblad] = useState<ETablad>(ETablad.Persoon);

  const [persoon, setPersoon] = useState<IOphalenPersonenResultElementV2 | null>(null);
  const [persoonRelaties, setPersoonRelaties] = useState<
    IOphalenContactpersonenResultElement[] | null
  >(null);

  const ophalenPersoon = useCallback(async () => {
    const personenResult = await api.v2.persoon.ophalenPersonen({
      filterSchema: {
        filters: [{ naam: 'IDS', data: [props.persID] }],
      },
    });

    setPersoon(personenResult.personen[0]);
  }, []);

  useEffect(() => {
    ophalenPersoon();
  }, [ophalenPersoon]);

  const ophalenPersoonRelaties = useCallback(async () => {
    if (persoon === null) {
      return;
    }
    const persoonRelaties = await api.v2.relatie.ophalenContactpersonen({
      filterSchema: { filters: [{ naam: 'PERS_IDS', data: [persoon.PersID] }] },
    });

    setPersoonRelaties(persoonRelaties.contactpersonen);
  }, [persoon]);

  useEffect(() => {
    ophalenPersoonRelaties();
  }, [ophalenPersoonRelaties]);

  const tabbladen = useMemo<ITabblad<ETablad>[]>(
    () => [
      {
        id: ETablad.Persoon,
        label: 'Persoon',
        content: PersoonTab,
      },
      {
        id: ETablad.Detail,
        label: 'Overig',
        content: DetailTab,
      },
      {
        id: ETablad.Vervangen,
        label: 'Vervangt',
        content: VervangtTab,
      },
    ],
    [],
  );

  const initialvalues = useMemo<IFormikValues | null>(() => {
    if (persoon === null || persoonRelaties === null) {
      return null;
    }

    return {
      // notities: persoon.Notities,
    };
  }, [persoon, persoonRelaties]);

  return (
    <Dialoog index={dialoogIndex || 0} modalProps={{ size: 'lg' }}>
      <ModalHeader>
        <ModalTitle>Persooninformatie</ModalTitle>
      </ModalHeader>

      <div className="d-flex flex-column flex-fill" style={{ minHeight: `500px` }}>
        {initialvalues === null ? (
          <div className="d-flex flex-fill align-items-center justify-content-center">
            <LoadingSpinner />
          </div>
        ) : (
          <>
            <PersoonContext.Provider
              value={{
                persoon,
                persoonRelaties,
              }}
            >
              <Formik<IFormikValues>
                isInitialValid
                initialValues={initialvalues}
                onSubmit={() => null}
                render={(FormikProps) => {
                  const { submitForm, isSubmitting, values, errors } = FormikProps;
                  return (
                    <>
                      <div className="d-flex flex-column flex-fill">
                        <Tabblad
                          tabbladen={tabbladen}
                          geselecteerd={tabblad}
                          onSelectieChange={(id) => {
                            setTabblad(id);
                          }}
                          options={{
                            tabbladComponentProps: {
                              onAnnuleren: props.onAnnuleren,
                            },
                          }}
                        />
                      </div>
                      <ModalFooter className="d-flex flex-row justify-content-start">
                        <button
                          className="btn btn-primary"
                          onClick={onAnnuleren}
                          style={{ width: 100 }}
                        >
                          Sluiten
                        </button>
                        <div className="flex-fill" />
                        <TechnischeInformatieKnop
                          dialoogIndex={(props.dialoogIndex ?? 0) + 1}
                          content={() => <div>PersID: {props.persID}</div>}
                        />
                      </ModalFooter>
                    </>
                  );
                }}
              />
            </PersoonContext.Provider>
          </>
        )}
      </div>
    </Dialoog>
  );
};

export default PersooninfoDialoog;
