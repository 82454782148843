import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import api from '../../../../api';
import {
  DataTypeProvider,
  EditingState,
  IntegratedSorting,
  RowDetailState,
  SelectionState,
  SortingState,
  VirtualTable as VirtualTableBase,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import { format } from 'date-fns';
import _ from 'lodash';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import { IconToevoegen, IconVerwijderen } from '../../../../components/Icons';
import MenuLayout from '../../../../components/MenuLayout';
import {
  DXTableCheckboxComponent,
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
  DXTableToggleCellComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import RelatieVisualisatie from '../../../../components/personalia/RelatieVisualisatie';
import { IOphalenDienstenResultElement } from '../../../../../../shared/src/api/v2/dienst/sponsoring';
import { IOphalenRelatiesResultElementV2 } from '../../../../../../shared/src/api/v2/relatie';
import { IOphalenContractenResultElement } from '../../../../../../shared/src/api/v2/sponsoring/contract';
import DetailComp from './DetailComp';
import FilterBalkV2, {
  IFilter,
  IFilterData,
  maakFilterSchema,
} from '../../../../components/FilterBalkV2';
import useUrlState from '../../../../core/useUrlState';
import { IFilterSchema } from '../../../../../../shared/src/models/filter';
import { RootStoreContext } from '../../../../stores/RootStore';
import { EResultType } from '../../../../stores/CheckStore';
import MutatieDialoog from './MutatieDialoog';

interface IProps extends RouteComponentProps {}

export enum EFilter {
  isActief = 'IS_ACTIEF',
}

interface INieuwSponsoringDialoogState {}

interface IWijzigenSponsoringDialoogState {
  id: number | null;
}

interface IUrlState {
  selectie: number[];
  filterData: IFilterData<EFilter>[];
  nieuweSponsoringDialoogState: INieuwSponsoringDialoogState | null;
  wijzigenSponsoringDialoogState: IWijzigenSponsoringDialoogState | null;
}

const defaultUrlState: IUrlState = {
  selectie: [],
  filterData: [
    {
      naam: EFilter.isActief,
      data: true,
      isActief: true,
    },
  ],
  nieuweSponsoringDialoogState: null,
  wijzigenSponsoringDialoogState: null,
};

export interface IRegel extends IOphalenDienstenResultElement {}

export interface IDienstenContext {
  verversen: () => void;
}

export const DienstenContext = React.createContext<IDienstenContext>(null as any);

const Diensten: React.FC<IProps> = (props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const { checkStore } = useContext(RootStoreContext);

  const [diensten, setDiensten] = useState<IRegel[] | null>(null);
  const [relaties, setRelaties] = useState<IOphalenRelatiesResultElementV2[] | null>(null);
  const [contracten, setContracten] = useState<IOphalenContractenResultElement[] | null>(null);

  const ophalenDiensten = useCallback(async () => {
    const dienstenResult = await api.v2.dienst.sponsoring.ophalenDiensten({
      filterSchema: {
        filters: [...filterSchema.filters!],
      },
    });

    const dienstenGesorteerd = _.orderBy(
      dienstenResult.diensten,
      [
        (x: any) => {
          return x.relatie !== null ? x.relatie.weergavenaam : x.Naam;
        },
      ],
      ['asc'],
    );

    setDiensten(dienstenGesorteerd);
  }, []);

  useEffect(() => {
    ophalenDiensten();
  }, [ophalenDiensten]);

  const ophalenRelaties = useCallback(async () => {
    if (diensten === null) {
      return;
    }
    const relIDs = diensten.map((x) => x.RelID);

    const relaties = (
      await api.v2.relatie.ophalenRelaties({
        filterSchema: { filters: [{ naam: 'IDS', data: relIDs }] },
      })
    ).relaties;

    setRelaties(relaties);
  }, [diensten]);

  useEffect(() => {
    ophalenRelaties();
  }, [ophalenRelaties]);

  const keyExtractor = useCallback((row: IRegel) => row.ID, []);
  const kolommen = useMemo<TypedColumn<IRegel>[]>(
    () => [
      {
        name: '__relatie' as any,
        title: 'Relatie',
        getCellValue: (x) => {
          if (relaties === null) return null;
          const relatie = relaties!.find((r) => r.RelID === x.RelID) ?? null;
          return relatie !== null ? relatie.weergavenaam : x.Naam;
        },
      },
      {
        name: '__relatienummer' as any,
        title: 'Rel.nr.',
        getCellValue: (x) => {
          if (relaties === null) return null;
          const relatie = relaties!.find((r) => r.RelID === x.RelID) ?? null;
          return relatie !== null ? relatie.Relatienummer : '';
        },
      },
      {
        name: 'Plaatsnaam' as any,
        title: 'Plaats',
        getCellValue: (x) => {
          if (relaties === null) return null;
          const relatie = relaties!.find((r) => r.RelID === x.RelID) ?? null;
          return relatie !== null && relatie.adres !== null ? relatie.adres.Plaatsnaam : null;
        },
      },
      {
        name: '__aantalContracten' as any,
        title: '# Cnt.',
      },
      {
        name: '__aantalBestanden' as any,
        title: '# Bijl.',
      },
      {
        name: '__laatsteContract' as any,
        title: 'Laatste contract op',
      },
      {
        name: 'slug' as any,
        title: 'Slug',
      },
      {
        name: 'LandingsPaginaTekst' as any,
        title: 'Landingspagina tekst',
      },
    ],
    [relaties],
  );
  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRegel>[]>(
    () => [
      {
        columnName: '__relatie' as any,
        width: 400,
      },
      // {
      //   columnName: '__relatienaam' as any,
      //   width: 250,
      // },
      {
        columnName: '__relatienummer' as any,
        width: 125,
      },
      {
        columnName: 'Plaatsnaam' as any,
        width: 150,
      },
      {
        columnName: '__aantalContracten' as any,
        width: 90,
      },
      {
        columnName: '__aantalBestanden' as any,
        width: 100,
      },
      {
        columnName: '__laatsteContract' as any,
        width: 175,
      },
      {
        columnName: 'slug' as any,
        width: 200,
      },
      {
        columnName: 'LandingsPaginaTekst' as any,
        width: 300,
      },
    ],
    [],
  );

  const handleVerwijderen = useCallback(async () => {
    if (!urlState.selectie.length) {
      return;
    }

    const checkData = await api.v2.dienst.sponsoring.checkVerwijderenDiensten({
      ids: urlState.selectie,
    });

    const controlerenResult = await checkStore.controleren({
      checkData,
    });
    if (controlerenResult.type === EResultType.Annuleren) {
      return;
    }

    if (
      (
        await checkStore.bevestigen({
          inhoud: 'Wil je deze sponsordiensten verwijderen?',
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    await api.v2.dienst.sponsoring.verwijderenDiensten({
      ids: urlState.selectie,
    });
  }, [urlState.selectie]);

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.isActief,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return (
            <div className="d-flex align-items-center">
              <span>Alleen actieve</span>
            </div>
          );
        },
      },
    ],
    [],
  );

  const [filterSchema, setFilterSchema] = useState<IFilterSchema>(
    useMemo(() => maakFilterSchema(urlState.filterData), []),
  );

  return (
    <MenuLayout
      menu={
        <>
          <div className="d-flex align-items-center">
            <button
              className="btn btn-sm btn-light d-flex align-items-center"
              style={{
                border: `1px solid ${Kleur.LichtGrijs}`,
              }}
              onClick={() => {
                setUrlStateSync('nieuweSponsoringDialoogState', {});
              }}
            >
              <IconToevoegen
                style={{
                  width: 16,
                  height: 16,
                  fill: Kleur.Grijs,
                }}
              />
              <span className="ml-2">Nieuwe sponsordienst</span>
            </button>
            <button
              className="btn btn-sm btn-light d-flex align-items-center ml-3"
              style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
              disabled={urlState.selectie.length === 0}
              onClick={handleVerwijderen}
            >
              <IconVerwijderen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
              <span className="ml-2">Verwijderen</span>
            </button>
            <div className="d-flex flex-fill ml-3">
              <FilterBalkV2
                filters={filters}
                filterData={urlState.filterData}
                onFilterDataChange={(x) => setUrlStateSync('filterData', x)}
                onFilterSchemaChange={(x) => setFilterSchema(x)}
              />
            </div>
          </div>
        </>
      }
      body={
        <div>
          {diensten === null || relaties === null ? (
            <LoadingSpinner />
          ) : (
            <GridStyleWrapper height="calc(100vh - 88px)">
              <Grid rows={diensten} columns={kolommen} getRowId={keyExtractor}>
                <DataTypeProvider
                  for={['__relatie']}
                  formatterComponent={(formatterProps) => {
                    const rij = formatterProps.row as IRegel;
                    if (rij.relatie === null) {
                      return <span>{rij.Naam}</span>;
                    }
                    return (
                      <span className="d-flex">
                        <RelatieVisualisatie relID={formatterProps.row.RelID} />
                        {rij.Naam !== null && <span>&nbsp;({rij.Naam}) </span>}
                      </span>
                    );
                  }}
                />

                <DataTypeProvider
                  for={['Plaatsnaam']}
                  formatterComponent={(formatterProps) => {
                    const rij: IRegel = formatterProps.row;
                    const relatie = relaties.find((x) => x.RelID === rij.RelID) ?? null;
                    if (relatie === null) {
                      return <span></span>;
                    }
                    return <span>{relatie.adres !== null ? relatie.adres.Plaatsnaam : ''}</span>;
                  }}
                />

                <DataTypeProvider
                  for={['__aantalContracten']}
                  formatterComponent={(formatterProps) => {
                    if (contracten === null) {
                      return <span></span>;
                    }
                    const rij = formatterProps.row as IRegel;
                    const contractenVoorDienst = contracten.filter(
                      (x) => x.SponDienstID === rij.ID,
                    );
                    const aantal = contractenVoorDienst.length;
                    return <span>{aantal}</span>;
                  }}
                />

                <DataTypeProvider
                  for={['__aantalBestanden']}
                  formatterComponent={(formatterProps) => {
                    const rij: IRegel = formatterProps.row;
                    const aantal = rij.bestanden.length;
                    return <span>{aantal !== 0 ? aantal : ''}</span>;
                  }}
                />

                <DataTypeProvider
                  for={['__laatsteContract']}
                  formatterComponent={(formatterProps) => {
                    if (contracten === null) {
                      return <span></span>;
                    }
                    const rij = formatterProps.row as IRegel;
                    const contractenVoorDienst = contracten.filter(
                      (x) => x.SponDienstID === rij.ID,
                    );
                    if (contractenVoorDienst.length === 0) {
                      return <span></span>;
                    }
                    const contract = _.orderBy(contractenVoorDienst, ['Contractdatum'], ['asc'])[0];
                    const datum =
                      contract.Contractdatum !== null
                        ? format(new Date(contract.Contractdatum), 'dd-MM-yyyy')
                        : '';
                    return <span>{datum}</span>;
                  }}
                />

                <DataTypeProvider
                  for={['slug']}
                  formatterComponent={(formatterProps) => {
                    const rij: IRegel = formatterProps.row;
                    const dienst = diensten.find((d) => d.ID == rij.ID);
                    if (dienst === null) {
                      return <span></span>;
                    }
                    return <span>{dienst?.slug ?? ''}</span>;
                  }}
                />

                <DataTypeProvider
                  for={['LandingsPaginaTekst']}
                  formatterComponent={(formatterProps) => {
                    const rij: IRegel = formatterProps.row;
                    const dienst = diensten.find((d) => d.ID == rij.ID);
                    if (dienst === null) {
                      return <span></span>;
                    }
                    return <span>{dienst?.LandingsPaginaTekst ?? ''}</span>;
                  }}
                />

                <SortingState defaultSorting={[]} />
                <IntegratedSorting />

                <VirtualTable />
                <TableColumnResizing defaultColumnWidths={kolomBreedtes} />

                {/* <EditingState
                  onCommitChanges={async (changes) => {}}
                  onEditingRowIdsChange={(rowIds) => {
                    const id = rowIds[rowIds.length - 1] as number;
                    // setUrlStateSync('wijzigenInkoopDialoogState', {
                    //   id,
                    // });
                  }}
                /> */}

                <EditingState
                  onCommitChanges={async (changes) => {}}
                  onEditingRowIdsChange={(rowIds) => {
                    const id = rowIds[rowIds.length - 1] as number;
                    setUrlStateSync('wijzigenSponsoringDialoogState', {
                      id,
                    });
                  }}
                />

                <TableEditColumn
                  width={35}
                  showEditCommand
                  cellComponent={DXTableEditColumnCellComponent}
                  commandComponent={DXTableEditColumnCommandComponent}
                />

                <TableHeaderRow showSortingControls />
                <RowDetailState defaultExpandedRowIds={[]} />

                <TableRowDetail
                  contentComponent={DetailComp}
                  toggleCellComponent={DXTableToggleCellComponent}
                />

                <SelectionState
                  selection={urlState.selectie}
                  onSelectionChange={(x) => {
                    setUrlStateSync('selectie', x as number[]);
                  }}
                />
                <TableSelection cellComponent={DXTableCheckboxComponent} />
              </Grid>
            </GridStyleWrapper>
          )}
          {urlState.wijzigenSponsoringDialoogState !== null && (
            <MutatieDialoog
              onSuccess={async () => {
                setUrlStateSync('wijzigenSponsoringDialoogState', null);
                await ophalenDiensten();
              }}
              id={urlState.wijzigenSponsoringDialoogState.id}
              onAnnuleren={() => setUrlStateSync('wijzigenSponsoringDialoogState', null)}
              open={true}
            />
          )}
          {urlState.nieuweSponsoringDialoogState && (
            <MutatieDialoog
              onSuccess={async () => {
                setUrlStateSync('nieuweSponsoringDialoogState', null);
                await ophalenDiensten();
              }}
              id={null}
              onAnnuleren={() => setUrlStateSync('nieuweSponsoringDialoogState', null)}
              open={true}
            />
          )}
        </div>
      }
    />
  );
};

export default Diensten;
