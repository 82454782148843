import React from 'react';

const EntiteitHeading: React.FC = (props) => {
  return (
    <div className="d-flex w-100 mb-1 p-1 pl-2 pr-2">
      <span style={{ fontSize: 16 }} className="font-weight-bold">
        {props.children}
      </span>
    </div>
  );
};

export default EntiteitHeading;
