import React from 'react';
import styled from 'styled-components';
import LoadingSpinner from '../../../Gedeeld/LoadingSpinner';

const Root = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transition: opacity 600ms ease-in-out;
  pointer-events: none;
  opacity: 0;
  background-color: rgba(50, 50, 50, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;

  &.bezig {
    opacity: 1;
    pointer-events: inherit;
  }
`;

interface IProps {
  isBezig: boolean;
}

const BezigOverlay = (props: React.PropsWithChildren<IProps>) => {
  return (
    <Root>
      {props.children}
      <Overlay className={props.isBezig ? 'bezig' : undefined}>
        <LoadingSpinner />
      </Overlay>
    </Root>
  );
};

export default BezigOverlay;
