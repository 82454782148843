import React, { forwardRef, useContext, useEffect } from 'react';
import OpstellenFormulier, {
  IOpstellenFormulierRef,
} from '../../../../components/communicatie/EmailWerkblad/OpstellenFormulier';
import { CommunicatieOverlayContext, setCommunicatieOverlayState } from '../../index';
import { EUitstelling } from '../../../../components/communicatie/EmailWerkblad/OpstellenFormulier/VersturenKnop';

const MIN_WIDTH = 800;
const MIN_HEIGHT = 600;

interface IProps {}

const EmailTabblad = forwardRef<IOpstellenFormulierRef, IProps>((props, forwardedRef) => {
  const { emailContext, width, height, onRequestSize } = useContext(CommunicatieOverlayContext);

  useEffect(() => {
    const w = width < MIN_WIDTH ? MIN_WIDTH : width;
    const h = height < MIN_HEIGHT ? MIN_HEIGHT : height;

    if (w !== width || h !== height) {
      onRequestSize(w, h);
    }
  }, []);

  return (
    <OpstellenFormulier
      ref={forwardedRef}
      emailBerID={null}
      onAutosaveFormulierChange={() => {}}
      onFormulierChange={emailContext.onFormulierChange}
      formulier={emailContext.formulier}
      defaultFromEmailGebrID={null}
      defaultSubject=""
      defaultBody=""
      defaultRecipients={[]}
      defaultCC={[]}
      defaultBCC={[]}
      defaultBijlagen={[]}
      defaultSjabBasisID={null}
      defaultTaalID={null}
      defaultContextRelIDs={[]}
      defaultUitstelling={EUitstelling.Direct}
      onSluiten={() =>
        setCommunicatieOverlayState!((state) => ({
          ...state,
          geselecteerdTabblad: null,
        }))
      }
    />
  );
});

export default EmailTabblad;
