import React, { useCallback, useMemo } from 'react';
import {
  DataTypeProvider,
  TableRowDetail,
  VirtualTable as VirtualTableBase,
} from '@devexpress/dx-react-grid';
import { IOphalenFacturenResultElement } from '../../../../../../../../../shared/src/api/v2/inkoopfactuur';

type IProps = TableRowDetail.ContentProps;

const RegelsDetailComp: React.FC<IProps> = (props) => {
  const row: IOphalenFacturenResultElement = props.row;

  return (
    <>
      <div>{row.Onderwerp}</div>
      <div>{row.Notities}</div>
    </>
  );
};

export default RegelsDetailComp;
