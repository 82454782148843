export const colors = {
  navyBlue: '#202432',
  navyBlueDark: '#0b0c11',
  lightBlue: '#e2e5f2',
  primaryBlue: '#1279fb',
  text: '#555',
  textgrey: '#a8acbb',
  textGreyDark: '#555f81',
  backgroundGrey: '#f0f0f0',
  backgroundGrey2: '#eeeff5',
  borderGrey: '#e2e5ec',
  formError: '#d72e3d',
  iconGrey: 'grey',
  lightGrey: '#f3f4f6',
  inputBorder: '#ced4da',
  hoverBlue: '#e2e5f2',
  green: '#2aa649',
  limeGreen: 'limegreen',
  primaryGreen: '#2aa649',
  activeNavGrey: '#f6f7f9',
  activeItemGrey: '#e3e4e9',
  activeNavBlue: '#e8f1fe',
  backgroundLightgreen: '#eaf9f8',
  backgroundLightBlue: '#e8f1fe',
  backgroundLightGrey: '#f5f6f8',
  backgroundLightGrey2: '#f1f2f5',
  backgroundLightGrey3: '#f8f9fa',
  inputBorderColor: '#e2e5ec',
  textGreen: '#1C8779',
  primaryRed: '#fd316f',
};

export const paddingModalContent = '1.2rem';

export const padding = {
  modalHeader: '1.4rem',
  modalContent: '1.2rem',
};

export const standaardMargin = '10px';
export const standaardPadding = '10px';
export const paddingContractenSectie = '20px 0px 0px 20px';
export const boxShadow = 'rgba(14, 14, 14, 0.27) 0 8px 20px -10px';
export const standaardBoxShadow = '0px 0px 13px 0px rgba(82, 63, 105, 0.05)';
export const tegelBoxShadow =
  '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12);';
export const tegelBoxShadow2 = '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15);';

// keen kleuren
// https://keenthemes.com/keen/preview/demo2/components/custom/colors.html
