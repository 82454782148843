import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Kleur } from '../../../../../bedrijfslogica/constanten';
import Kop from './Kop';
import Onderwerp from './Onderwerp';
import Preview from './Preview';
import { EIdentiteitType, Identiteit } from '../../types';
import IRemoteData, { ERemoteDataState, mapRemoteData } from '../../../../../models/IRemoteData';
import { IOphalenRelatiesResultElementV2 } from '../../../../../../../shared/src/api/v2/relatie';
import { IOphalenPersonenResultElementV2 } from '../../../../../../../shared/src/api/v2/persoon/persoon';
import { IOphalenOrganisatiesResultElement } from '../../../../../../../shared/src/api/v2/organisatie/organisatie';
import Avatar from '../../../../personalia/Avatar';
import { IEmailBijlageBestand } from '../../../../../../../shared/src/api/v2/communicatie/email';
import Bijlagen from './Bijlagen';
import EmailAvatar from '../../EmailAvatar';
import { IEmailBerBest } from '../../../../../../../shared/src/api/v2/email-v2';

interface IRootProps {
  isActief: boolean;
}

const Root = styled.div<IRootProps>`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${(props) => (props.isActief ? Kleur.DoorschijnendLichtBlauw : Kleur.Wit)};
  padding: 10px 15px;
  border-bottom: 1px solid ${Kleur.LichtGrijs};
  transition: background-color 100ms ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: ${(props) =>
      props.isActief ? Kleur.DoorschijnendLichtBlauw : Kleur.HeelLichtGrijs};
  }
`;

interface IProps {
  isActief: boolean;
  onClick?: () => void;
  identiteiten: IRemoteData<Identiteit[]>;
  onderwerp: IRemoteData<string | null>;
  datum: IRemoteData<Date>;
  preview: IRemoteData<string | null>;
  avatarImageSrc: IRemoteData<string | null>;
  relatiesCache: Record<number, IRemoteData<IOphalenRelatiesResultElementV2>>;
  personenCache: Record<number, IRemoteData<IOphalenPersonenResultElementV2>>;
  organisatiesCache: Record<number, IRemoteData<IOphalenOrganisatiesResultElement>>;
  bijlagen: IEmailBerBest[];
  onHerberekenenGrootte: () => void;
}

const Item = (props: IProps) => {
  const avatar = useMemo(() => {
    const identiteit = mapRemoteData(props.identiteiten, (identiteiten) => identiteiten[0] ?? null);

    return (
      <EmailAvatar
        avatarImageSrc={props.avatarImageSrc}
        identiteit={identiteit}
        relatiesCache={props.relatiesCache}
        personenCache={props.personenCache}
        organisatiesCache={props.organisatiesCache}
        avatarGrootte={32}
        avatarFontSize={13}
      />
    );
  }, [
    props.avatarImageSrc,
    props.identiteiten,
    props.relatiesCache,
    props.personenCache,
    props.organisatiesCache,
  ]);

  const bijlagen = useMemo(() => props.bijlagen.filter((x) => !x.IsInline), [props.bijlagen]);

  return (
    <Root isActief={props.isActief} onClick={props.onClick}>
      <Kop
        identeiten={props.identiteiten}
        relatiesCache={props.relatiesCache}
        personenCache={props.personenCache}
        organisatiesCache={props.organisatiesCache}
        heeftBijlage={bijlagen.length > 0}
        onHerberekenenGrootte={props.onHerberekenenGrootte}
      />
      <div className="d-flex align-items-center flex-fill">
        <div style={{ minWidth: 32, minHeight: 32, marginRight: 12 }}>{avatar}</div>
        <div className="flex-fill d-flex flex-column" style={{ minWidth: 0 }}>
          <Onderwerp onderwerp={props.onderwerp} datum={props.datum} />
          <Preview preview={props.preview} datum={props.datum} />
        </div>
      </div>
      {bijlagen.length > 0 && <Bijlagen bijlagen={bijlagen} />}
    </Root>
  );
};

export default Item;
