import api from '../../index';
import {
  IOphalenEventsParams,
  IOphalenEventsResult,
  IWijzigenEventtypeParams,
  IWijzigenEventtypeResult,
  IOphalenEventtypenParams,
  IOphalenEventtypenResult,
} from '../../../../../shared/src/api/v2/appEvent';
import ICheckData from '../../../../../shared/src/models/ICheckData';

const appEvent = {
  ophalenEvents: async (params: IOphalenEventsParams): Promise<IOphalenEventsResult> => {
    return await api.post('v2/appevent/ophalen-events', params);
  },
  chekcWijzigenEventtype: async (params: IWijzigenEventtypeParams): Promise<ICheckData> => {
    return await api.post('v2/appevent/check-wijzigen-eventtype', params);
  },
  wijzigenEventtype: async (
    params: IWijzigenEventtypeParams,
  ): Promise<IWijzigenEventtypeResult> => {
    return await api.post('v2/appevent/wijzigen-eventtype', params);
  },
  ophalenEventtypen: async (
    params: IOphalenEventtypenParams,
  ): Promise<IOphalenEventtypenResult> => {
    return await api.post('v2/appevent/ophalen-eventtypen', params);
  },
};

export default appEvent;
