import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  TableSelection,
  TableEditColumn,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  IOphalenDienstenResultElement,
  IVerwijderenDienstenParams,
} from '../../../../../../shared/src/api/v2/dienst/inkoop';
import api from '../../../../api';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import Dialoog from '../../../../components/dialogen/Dialoog';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import { IconToevoegen, IconVerwijderen } from '../../../../components/Icons';
import {
  DXCommandComponent,
  DXTableCheckboxComponent,
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import {
  DataTypeProvider,
  EditingState,
  IntegratedSorting,
  RowDetailState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import RelatieVisualisatie from '../../../../components/personalia/RelatieVisualisatie';
import PersoonVisualisatie from '../../../../components/personalia/PersoonVisualisatie';
import MutatieDialoog from './MutatieDialoog';
import { RootStoreContext } from '../../../../stores/RootStore';
import { EResultType } from '../../../../stores/CheckStore';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import useUrlState from '../../../../core/useUrlState';
import FilterBalkV2, {
  IFilter,
  IFilterData,
  maakFilterSchema,
} from '../../../../components/FilterBalkV2';
import { IFilterSchema } from '../../../../../../shared/src/models/filter';
import MenuLayout from '../../../../components/MenuLayout';
import nameOf from '../../../../core/nameOf';
import { Helmet } from 'react-helmet';

interface IProps extends RouteComponentProps {}

export enum EFilter {
  isActief = 'IS_ACTIEF',
}

interface IWijzigenInkoopDialoogState {
  id: number | null;
}

interface INieuwInkoopDialoogState {}

interface IUrlState {
  wijzigenInkoopDialoogState: IWijzigenInkoopDialoogState | null;
  nieuwInkoopDialoogState: INieuwInkoopDialoogState | null;
  selectie: number[];
  filterData: IFilterData<EFilter>[];
}

const defaultUrlState: IUrlState = {
  wijzigenInkoopDialoogState: null,
  nieuwInkoopDialoogState: null,
  selectie: [],
  filterData: [
    {
      naam: EFilter.isActief,
      data: true,
      isActief: true,
    },
  ],
};

// export interface IRow extends IOphalenDienstenResultElement {}

const Inkoop: React.FC<IProps> = (props) => {
  const [inkoop, setInkoop] = useState<IOphalenDienstenResultElement[] | null>(null);
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);

  const { checkStore } = useContext(RootStoreContext);

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.isActief,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return (
            <div className="d-flex align-items-center">
              <span>Alleen actieve</span>
            </div>
          );
        },
      },
    ],
    [],
  );

  const [filterSchema, setFilterSchema] = useState<IFilterSchema>(
    useMemo(() => maakFilterSchema(urlState.filterData), []),
  );

  const ophalenDiensten = useCallback(async () => {
    const resultaat = await api.v2.dienst.inkoop.ophalenDiensten({
      filterSchema: {
        filters: [...filterSchema.filters!],
      },
    });

    setInkoop(resultaat.diensten);
  }, [filterSchema, setInkoop]);

  useEffect(() => {
    (async () => {
      ophalenDiensten();
    })();
  }, [ophalenDiensten]);

  const keyExtractor = useCallback((row: IOphalenDienstenResultElement) => row.ID, []);

  const kolommen = useMemo<TypedColumn<IOphalenDienstenResultElement>[]>(
    () => [
      {
        name: '__relatie' as any,
        title: 'Leverancier',
        getCellValue: (x) => (x.relatie !== null ? x.relatie!.weergavenaam : null),
      },
      {
        name: '__opdrachtwijze' as any,
        title: 'Opdrachtwijze',
        getCellValue: (x) => {
          if (x.opdrachtwijze === null) {
            return null;
          }
          return x.opdrachtwijze!.Naam;
        },
      },
      {
        name: '__actief' as any,
        title: 'Actief',
        getCellValue: (x) => {
          return x.Actief;
        },
      },
      {
        name: 'Levertermijn',
        title: 'Lev.trm (dgn)',
      },
      {
        name: 'TijdUiterlijkOpdracht',
        title: 'Uiterlijk voor',
        getCellValue: (x) => (x.Levertermijn !== 99 ? x.TijdUiterlijkOpdracht : null),
      },
      {
        name: 'KoppelenProductenAanFactuur',
        title: 'Koppel producten',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IOphalenDienstenResultElement>[]>(
    () => [
      {
        columnName: 'Actief',
        width: 75,
      },
      {
        columnName: 'Levertermijn',
        width: 125,
      },
      {
        columnName: '__opdrachtwijze' as any,
        width: 150,
      },
      {
        columnName: '__actief' as any,
        width: 90,
      },
      {
        columnName: '__relatie' as any,
        width: 225,
      },
      {
        columnName: 'TijdUiterlijkOpdracht',
        width: 125,
      },
      {
        columnName: 'KoppelenProductenAanFactuur',
        width: 150,
      },
    ],
    [],
  );

  const handleVerwijderen = useCallback(async () => {
    if (!urlState.selectie.length) {
      return;
    }

    const checkData = await api.v2.dienst.inkoop.checkVerwijderenDiensten({
      ids: urlState.selectie,
    });

    const controlerenResult = await checkStore.controleren({
      checkData,
    });
    if (controlerenResult.type === EResultType.Annuleren) {
      return;
    }

    if (
      (
        await checkStore.bevestigen({
          inhoud: 'Wil je deze inkoopdiensten verwijderen?',
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    await api.v2.dienst.inkoop.verwijderenDiensten({
      ids: urlState.selectie,
    });
  }, [urlState.selectie]);

  return (
    <>
      <Helmet>
        <title>Inkoopdiensten</title>
      </Helmet>
      <MenuLayout
        menu={
          <>
            <div className="d-flex align-items-center">
              <button
                className="btn btn-sm btn-light d-flex align-items-center"
                style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                onClick={() => {
                  setUrlStateSync('nieuwInkoopDialoogState', {});
                }}
              >
                <IconToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                <span className="ml-2">Nieuwe inkoopdienst</span>
              </button>
              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-3"
                style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                disabled={urlState.selectie.length === 0}
                onClick={handleVerwijderen}
              >
                <IconVerwijderen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                <span className="ml-2">Verwijderen</span>
              </button>
              <div className="d-flex flex-fill ml-3">
                <FilterBalkV2
                  filters={filters}
                  filterData={urlState.filterData}
                  onFilterDataChange={(x) => setUrlStateSync('filterData', x)}
                  onFilterSchemaChange={(x) => setFilterSchema(x)}
                />
              </div>
            </div>
          </>
        }
        body={
          <>
            {inkoop === null ? (
              <LoadingSpinner />
            ) : (
              <div className="bg-white" style={{ height: 'calc(100vh - 40px)' }}>
                <GridStyleWrapper height="calc(100vh - 150px)">
                  <Grid rows={inkoop} columns={kolommen} getRowId={keyExtractor}>
                    <DataTypeProvider
                      for={['__relatie']}
                      formatterComponent={(formatterProps) => {
                        const rij: IOphalenDienstenResultElement = formatterProps.row;

                        return (
                          <span className="d-flex">
                            <RelatieVisualisatie relID={formatterProps.row.RelID} />
                            {rij.Naam !== null && <span>&nbsp;({rij.Naam}) </span>}
                          </span>
                        );
                      }}
                    />

                    <DataTypeProvider
                      for={['__opdrachtwijze']}
                      formatterComponent={(props) => <span>{props.row.opdrachtwijze.Naam}</span>}
                    />

                    <DataTypeProvider
                      for={['__actief']}
                      formatterComponent={(props) => <span>{props.row.Actief ? 'Ja' : 'Nee'}</span>}
                    />

                    <DataTypeProvider
                      for={['Levertermijn']}
                      formatterComponent={(props) => (
                        <span>{props.value === 99 ? '' : props.value}</span>
                      )}
                    />

                    <DataTypeProvider
                      for={['TijdUiterlijkOpdracht']}
                      formatterComponent={(props) => (
                        <span>{props.row.Levertermijn !== 99 ? props.value : ''}</span>
                      )}
                    />

                    <DataTypeProvider
                      for={[nameOf<IOphalenDienstenResultElement>('KoppelenProductenAanFactuur')]}
                      formatterComponent={(formatterProps) => (
                        <span>{formatterProps.value ? 'Ja' : 'Nee'}</span>
                      )}
                    />

                    <RowDetailState defaultExpandedRowIds={[]} />

                    <SortingState defaultSorting={[]} />
                    <IntegratedSorting />

                    <VirtualTable />
                    <TableColumnResizing defaultColumnWidths={kolomBreedtes} />

                    <EditingState
                      onCommitChanges={async (changes) => {}}
                      onEditingRowIdsChange={(rowIds) => {
                        const id = rowIds[rowIds.length - 1] as number;
                        setUrlStateSync('wijzigenInkoopDialoogState', {
                          id,
                        });
                      }}
                    />

                    <TableEditColumn
                      width={35}
                      showEditCommand
                      cellComponent={DXTableEditColumnCellComponent}
                      commandComponent={DXTableEditColumnCommandComponent}
                    />
                    <TableHeaderRow showSortingControls />
                    <SelectionState
                      selection={urlState.selectie}
                      onSelectionChange={(x) => {
                        setUrlStateSync('selectie', x as number[]);
                      }}
                    />
                    <TableSelection cellComponent={DXTableCheckboxComponent} />
                  </Grid>
                </GridStyleWrapper>
              </div>
            )}
            {urlState.wijzigenInkoopDialoogState !== null && (
              <MutatieDialoog
                onSuccess={async () => {
                  setUrlStateSync('wijzigenInkoopDialoogState', null);
                  await ophalenDiensten();
                }}
                id={urlState.wijzigenInkoopDialoogState.id}
                onAnnuleren={() => setUrlStateSync('wijzigenInkoopDialoogState', null)}
                open={true}
              />
            )}
            {urlState.nieuwInkoopDialoogState && (
              <MutatieDialoog
                onSuccess={async () => {
                  setUrlStateSync('nieuwInkoopDialoogState', null);
                  await ophalenDiensten();
                }}
                id={null}
                onAnnuleren={() => setUrlStateSync('nieuwInkoopDialoogState', null)}
                open={true}
              />
            )}
          </>
        }
      />
    </>
  );
};

export default withRouter(Inkoop);
