import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import IDialoogProps from '../../../../../../../../core/IDialoogProps';
import Dialoog from '../../../../../../../../components/dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import * as Yup from 'yup';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../../../../../../stores/RootStore';
import Combobox from '../../../../../../../../components/formulier/Combobox';
import nameOf from '../../../../../../../../core/nameOf';
import LoadingSpinner from '../../../../../../../../components/Gedeeld/LoadingSpinner';
import { EResultType } from '../../../../../../../../stores/CheckStore';
import _ from 'lodash';
import VinkVeld from '../../../../../../../../components/formulier/VinkVeld';
import RadioKnop from '../../../../../../../../components/formulier/RadioKnop';
import { EKanaal } from '../../../../../../../../bedrijfslogica/enums';
import api from '../../../../../../../../api';
import { ISjabloon } from '../../../../../../../../../../shared/src/api/v2/Sjabloon/sjabloon';

interface IDialoogResult {
  verzendwijze: EKanaal;
  sjabID: number;
}

interface IProps extends IDialoogProps<IDialoogResult> {
  id: number;
}

interface IFormikValues {
  sjabloon: number;
  verzendwijze: EKanaal;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  sjabloon: 'Sjabloon',
  verzendwijze: 'Verzendwijze',
};

const VersturenVoorstelDialoog: React.FC<IProps> = observer((props) => {
  const { children, dialoogIndex, onAnnuleren, onSuccess, open } = props;
  const { checkStore } = useContext(RootStoreContext);

  const [sjablonen, setSjablonen] = useState<ISjabloon[] | null>(null);

  const ophalenSjablonen = useCallback(async () => {
    const result = await api.v2.sjabloon.ophalenSjablonen({
      filterSchema: {
        filters: [
          {
            naam: 'NAAM_ENUMS',
            data: ['verkoopvoorstel.nav-beeindiging', 'verkoopvoorstel.nav-verzoek'],
          },
        ],
      },
    });
    setSjablonen(result.sjablonen);
  }, []);

  useEffect(() => {
    ophalenSjablonen();
  }, [ophalenSjablonen]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      // if (
      //   (
      //     await checkStore.bevestigen({
      //       inhoud: `Verkoopvoorstel versturen?`,
      //     })
      //   ).type === EResultType.Annuleren
      // ) {
      //   actions.setSubmitting(false);
      //   return;
      // }

      // await api.v2.pendel.versturenOpdracht(params);

      props.onSuccess({ verzendwijze: values.verzendwijze, sjabID: values.sjabloon });
      actions.setSubmitting(false);
    },
    [],
  );

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (sjablonen === null) {
      return null;
    }
    return {
      sjabloon: sjablonen.find((x) => x.NaamEnum === 'verkoopvoorstel.nav-beeindiging')!.ID,
      verzendwijze: EKanaal.Email,
    };
  }, [sjablonen]);

  // const validationSchema = useMemo(
  //   () =>
  //     Yup.object().shape({
  //       opdrachtwijze: Yup.number().required(),
  //     }),
  //   [],
  // );

  return (
    <Dialoog index={dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Versturen verkoopvoorstel</ModalTitle>
      </ModalHeader>
      {initialValues === null || sjablonen === null ? (
        <LoadingSpinner />
      ) : (
        <Formik<IFormikValues>
          onSubmit={handleSubmit}
          initialValues={initialValues}
          // validationSchema={validationSchema}
          // enableReinitialize
          isInitialValid
          render={(formikProps: FormikProps<IFormikValues>) => {
            const { submitForm, isValid, isSubmitting } = formikProps;

            return (
              <>
                <ModalBody>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-12">
                        <label>{veldnamen.sjabloon}</label>
                        <Field
                          name={nameOf<IFormikValues>('sjabloon')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            return (
                              <Combobox
                                geselecteerd={field.value}
                                opties={sjablonen.map((x) => {
                                  return { id: x.ID, label: x.Naam };
                                })}
                                onSelectieChange={(x) => form.setFieldValue(field.name, x)}
                              />
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-4 d-flex">
                        <label>{veldnamen.verzendwijze}</label>
                        <Field
                          name="verzendwijze"
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <>
                                <div className="d-flex" style={{ width: 250 }}>
                                  <span className="d-flex align-items-center ml-3">
                                    <RadioKnop
                                      aangevinkt={field.value === 'EMAIL'}
                                      onAangevinkt={() => form.setFieldValue(field.name, 'EMAIL')}
                                    />
                                    Email
                                  </span>
                                  <span className="d-flex align-items-center ml-3">
                                    <RadioKnop
                                      aangevinkt={field.value === 'SMS'}
                                      onAangevinkt={() => form.setFieldValue(field.name, 'SMS')}
                                    />
                                    SMS
                                  </span>
                                </div>
                              </>
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </ModalBody>
                <ModalFooter className="d-flex flex-row justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    style={{ width: 100 }}
                    disabled={!isValid || isSubmitting}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={onAnnuleren}
                    style={{ width: 100 }}
                    disabled={isSubmitting}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Dialoog>
  );
});

export default VersturenVoorstelDialoog;
