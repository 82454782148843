import React, { useEffect, useMemo, useRef, useState } from 'react';
import useNotifyChanges from '../../helpers/useNotifyChanges';

interface IShouldRerenderParams<TData> {
  data: TData;
  setData: (data: TData) => void;
}

interface IProps<TData> {
  initialData: TData;
  intervalMs: number;
  shouldRerender: (params: IShouldRerenderParams<TData>) => boolean;
  render: React.ComponentType<{ data: TData }>;
}

const RerenderCheck = <TData extends {}>(props: IProps<TData>) => {
  const data = useRef(props.initialData);
  const shouldRerenderParams = useMemo<IShouldRerenderParams<TData>>(
    () => ({
      data: data.current,
      setData: (x) => (data.current = x),
    }),
    [data.current],
  );
  const notifyChanges = useNotifyChanges();

  useEffect(() => {
    const handle = setInterval(() => {
      if (props.shouldRerender(shouldRerenderParams)) {
        notifyChanges();
      }
    }, props.intervalMs);
    return () => clearInterval(handle);
  }, [props.intervalMs, props.shouldRerender, notifyChanges]);

  return <props.render data={data.current} />;
};

export default RerenderCheck;
