import styled from 'styled-components';
import { Kleur } from '../../../../../bedrijfslogica/constanten';

export interface ITableDataProps<TKey extends keyof any, TRow> {
  tableKey: TKey | null;
  row?: TRow;
  isGefocust?: boolean;
  isGehovered?: boolean;
  heeftUitgeklapteRijOnderZich?: boolean;
  rowIndex: number;
}

const TableData = styled.div<ITableDataProps<any, any>>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 15px;
  border-bottom: ${(props) =>
    props.heeftUitgeklapteRijOnderZich ? '1px solid transparent' : `1px solid ${Kleur.LichtGrijs}`};
  background-color: ${(props) =>
    props.isGefocust
      ? Kleur.DoorschijnendLichtBlauw
      : props.isGehovered
      ? Kleur.HeelLichtGrijs
      : 'inherit'};

  .naam {
    color: ${Kleur.Zwart};
    font-size: 14px;
  }
`;

export default TableData;
