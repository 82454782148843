import api from '../../..';
import {
  IOphalenBetalingsregelingenParams,
  IOphalenBetalingsregelingenResult,
  IOphalenStatussenParams,
  IOphalenStatussenResult,
  IToevoegenBetalingsregelingParams,
  IToevoegenBetalingsregelingResult,
  IWijzigenBetalingsregelingParams,
  IWijzigenBetalingsregelingResult,
  IVerwijderenBetalingsregelingenParams,
  IVerwijderenBetalingsregelingenResult,
  ISelectieToevoegenBetalingsregelingParams,
  IMakenBankopdrachtenParams,
  IMakenBankopdrachtenResult,
  IVersturenBetalingsregelingParams,
  IVersturenBetalingsregelingResult,
} from '../../../../../../shared/src/api/v2/debiteur//betalingsregeling';
import ICheckData from '../../../../../../shared/src/models/ICheckData';

const betalingsregeling = {
  ophalenBetalingsregelingen: async (
    params: IOphalenBetalingsregelingenParams,
  ): Promise<IOphalenBetalingsregelingenResult> => {
    return await api.post('/v2/debiteur/betalingsregeling/ophalen-betalingsregelingen', params);
  },
  ophalenStatussen: async (params: IOphalenStatussenParams): Promise<IOphalenStatussenResult> => {
    return await api.post('/v2/debiteur/betalingsregeling/ophalen-statussen', params);
  },
  checkSelectieToevoegenBetalingsregeling: async (
    params: ISelectieToevoegenBetalingsregelingParams,
  ): Promise<ICheckData> => {
    return await api.post(
      '/v2/debiteur/betalingsregeling/check-selectie-toevoegen-betalingsregeling',
      params,
    );
  },
  checkToevoegenBetalingsregeling: async (
    params: IToevoegenBetalingsregelingParams,
  ): Promise<ICheckData> => {
    return await api.post(
      '/v2/debiteur/betalingsregeling/check-toevoegen-betalingsregeling',
      params,
    );
  },
  toevoegenBetalingsregeling: async (
    params: IToevoegenBetalingsregelingParams,
  ): Promise<IToevoegenBetalingsregelingResult> => {
    return await api.post('/v2/debiteur/betalingsregeling/toevoegen-betalingsregeling', params);
  },
  checkWijzigenBetalingsregeling: async (
    params: IWijzigenBetalingsregelingParams,
  ): Promise<ICheckData> => {
    return await api.post(
      '/v2/debiteur/betalingsregeling/check-wijzigen-betalingsregeling',
      params,
    );
  },
  wijzigenBetalingsregeling: async (
    params: IWijzigenBetalingsregelingParams,
  ): Promise<IWijzigenBetalingsregelingResult> => {
    return await api.post('/v2/debiteur/betalingsregeling/wijzigen-betalingsregeling', params);
  },
  checkVerwijderenBetalingsregelingen: async (
    params: IVerwijderenBetalingsregelingenParams,
  ): Promise<ICheckData> => {
    return await api.post(
      '/v2/debiteur/betalingsregeling/check-verwijderen-betalingsregelingen',
      params,
    );
  },
  verwijderenBetalingsregelingen: async (
    params: IVerwijderenBetalingsregelingenParams,
  ): Promise<IVerwijderenBetalingsregelingenResult> => {
    return await api.post('/v2/debiteur/betalingsregeling/verwijderen-betalingsregelingen', params);
  },
  checkMakenBankopdrachten: async (params: IMakenBankopdrachtenParams): Promise<ICheckData> => {
    return await api.post('/v2/debiteur/betalingsregeling/check-maken-bankopdrachten', params);
  },
  makenBankopdrachten: async (
    params: IMakenBankopdrachtenParams,
  ): Promise<IMakenBankopdrachtenResult> => {
    return await api.post('/v2/debiteur/betalingsregeling/maken-bankopdrachten', params);
  },
  versturenBetalingsregeling: async (
    params: IVersturenBetalingsregelingParams,
  ): Promise<IVersturenBetalingsregelingResult> => {
    return await api.post('/v2/debiteur/betalingsregeling/versturen-betalingsregeling', params);
  },
};

export default betalingsregeling;
