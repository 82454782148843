import { makeObservable, observable } from 'mobx';
import { RootStore } from './RootStore';
import { IOphalenEmailAccountsResultAccountElement } from '../../../shared/src/api/v2/communicatie/email';
import api from '../api';
import * as _ from 'lodash';
import { IEmail } from '../../../shared/src/api/v2/email-v2';

class EmailStore {
  public emailAccounts: IOphalenEmailAccountsResultAccountElement[] | null = null;
  public emails: IEmail[] | null = null;
  public emailsVanRelatie: { [relID: number]: IEmail[] } = {};

  public alleEmails: Record<number, IEmail> = {};
  // tslint:disable-next-line:variable-name
  private _rootStore: RootStore;
  // tslint:disable-next-line:variable-name
  private _ophalenEmailsIndex = 0;
  // tslint:disable-next-line:variable-name
  // private _ophalenEmailsVoorEmailGebrIDIndex: { [emailGebrID: number]: number } = {};
  // noinspection SpellCheckingInspection
  // tslint:disable-next-line:variable-name
  private _queueOphalenEmail: number[] = [];
  // tslint:disable-next-line:variable-name
  private _queueOphalenEmailTimeoutId: number | null = null;

  constructor(rootStore: RootStore) {
    this._rootStore = rootStore;

    makeObservable(this, {
      emails: observable,
      emailsVanRelatie: observable,
      emailAccounts: observable,
      alleEmails: observable,
    });
  }

  public ophalenEmailAccounts = async () => {
    const result = await api.v2.email.ophalenEmailAccounts({});
    this.emailAccounts = result.accounts;
  };

  public ophalenEmails = async () => {
    const result = await api.v2.email.ophalenEmails({
      paginatie: {
        aantal: 50,
        index: this._ophalenEmailsIndex,
      },
    });
    this.emails = result.emails;

    this._ophalenEmailsIndex++;
  };

  public ophalenVolgendeEmails = async () => {
    const result = await api.v2.email.ophalenEmails({
      paginatie: {
        aantal: 50,
        index: this._ophalenEmailsIndex,
      },
    });
    this.emails = [...(this.emails || []), ...result.emails];

    this._ophalenEmailsIndex++;
  };

  // public async ophalenEmailsVoorEmailGebrID(emailGebrID: number) {
  //   const result = await api.v2.email.ophalenEmailsVoorEmailGebrID({
  //     emailGebrID,
  //     paginatie: {
  //       aantal: 50,
  //       index: this._ophalenEmailsVoorEmailGebrIDIndex[emailGebrID] || 0,
  //     },
  //   });
  //   this.
  // }

  public ophalenEmailsVanRelatie = async (relID: number) => {
    const result = await api.v2.email.ophalenEmailsVanRelatie({ relID });
    this.emailsVanRelatie = { ...this.emailsVanRelatie, [relID]: result.emails };
  };

  public enqueueOphalenEmail = (emailBerID: number) => {
    this._queueOphalenEmail = _.uniq([...this._queueOphalenEmail, emailBerID]);
    if (this._queueOphalenEmailTimeoutId !== null) {
      clearInterval(this._queueOphalenEmailTimeoutId);
    }
    this._queueOphalenEmailTimeoutId = setTimeout(async () => {
      const ids = this._queueOphalenEmail;
      this._queueOphalenEmail = [];
      this._queueOphalenEmailTimeoutId = null;

      const result = await api.v2.email.ophalenEmails({
        filterSchema: {
          filters: [
            {
              naam: 'IDS',
              data: ids,
            },
          ],
        },
        paginatie: {
          index: 0,
          aantal: 999999,
        },
      });

      this.alleEmails = result.emails.reduce((acc: Record<number, IEmail>, curr) => {
        return {
          ...acc,
          [curr.ID]: curr,
        };
      }, this.alleEmails);
    }, 100) as any;
  };
}

export default EmailStore;
