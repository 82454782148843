import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IOphalenSelectieGebruiktResultElement } from '../../../../../../../../shared/src/api/v2/transport/reservering/voorraad';
import api from '../../../../../../api';
import {
  DXTableCheckboxComponent,
  DXTableToggleCellComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../../../helpers/dxTableGrid';
import LoadingSpinner from '../../../../../../components/Gedeeld/LoadingSpinner';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import { SelectionState, DataTypeProvider } from '@devexpress/dx-react-grid';
import { maandenNaarJaren } from '../../../../../../bedrijfslogica/teksten';
import _ from 'lodash';
import ProductinfoDialoog from '../../../../../../components/product/ProductinfoDialoog';
import { IconInformatie } from '../../../../../../components/Icons';
import { Kleur as EKleur } from '../../../../../../bedrijfslogica/constanten';
import { differenceInDays } from 'date-fns';
import { ITabbladProps } from './index';
import styled from 'styled-components';

interface IProps extends ITabbladProps {}

const geenData = {
  noData: 'Geen producten gevonden',
};

export interface IProductinfoDialoogState {
  prodID: number;
}

const Gebruikt: React.FC<IProps> = (props) => {
  const {
    magID,
    productmodel,
    prodID,
    onProdIDChange,
    alleenVoorMerk,
    prodSrtID,
    productmodelSelectie,
    zoekterm,
    staat,
  } = props;

  // De te selecteren producten ophalen
  const [producten, setProducten] = useState<IOphalenSelectieGebruiktResultElement[]>([]);

  const [
    productinfoDialoogState,
    setProductinfoDialoogState,
  ] = useState<IProductinfoDialoogState | null>(null);

  interface IPrioriteitTrProps {
    kleuren?: boolean;
  }

  const PrioriteitTr = styled.tr<IPrioriteitTrProps>`
    td {
      ${(props) => (props.kleuren ? `background-color: ${EKleur.HeelLichtGeel} !important;` : '')}
    }
  `;

  const ophalenProducten = useCallback(async () => {
    if (productmodel === null) {
      return;
    }
    const productenResult = await api.v2.transport.reservering.levering.ophalenSelectieGebruikt({
      magID,
      prodSrtID: !productmodelSelectie ? prodSrtID : null,
      prodModID: productmodelSelectie ? productmodel!.ProdModID : null,
      zoekterm,
      staat,
    });
    const producten =
      alleenVoorMerk && !productmodel.Huismodel
        ? productenResult.filter((x) => x.MerkID === productmodel.MerkID)
        : productenResult;

    const resultGesorteerd = _.sortBy(producten, [
      'prioriteit',
      'Merknaam',
      'Typenaam',
      'leeftijd',
      'Referentiecode',
    ]);

    setProducten(resultGesorteerd);
  }, [magID, staat, productmodel, alleenVoorMerk, prodSrtID, productmodelSelectie, zoekterm]);

  useEffect(() => {
    ophalenProducten();
  }, [ophalenProducten]);

  const kolommen = useMemo<TypedColumn<IOphalenSelectieGebruiktResultElement>[]>(
    () => [
      {
        name: 'prioriteit',
        title: 'Prio',
      },
      {
        name: 'Merknaam',
        title: 'Merk',
      },
      {
        name: 'Typenaam',
        title: 'Type',
      },
      {
        name: 'ProductsoortnaamKort',
        title: 'Cat.',
      },
      {
        name: 'Nieuw',
        title: 'N/G',
      },
      {
        name: 'Referentiecode',
        title: 'Ref.',
      },
      {
        name: '__productinfo' as any,
        title: ' ',
      },
      {
        name: '__leeftijd' as any,
        title: 'Lft.',
      },
      {
        name: '__pendelLosdatum' as any,
        title: 'Mag.dgn.',
      },
      {
        name: 'Kenmerk',
        title: 'Kenmerk',
      },
      // {
      //   name: '__inGekoppeldeProducttypenVoorProductmodel' as any,
      //   title: 'In model',
      // },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IOphalenSelectieGebruiktResultElement>[]>(
    () => [
      {
        columnName: 'prioriteit',
        width: 50,
      },
      {
        columnName: 'Typenaam',
        width: 175,
      },
      {
        columnName: 'Merknaam',
        width: 100,
      },
      {
        columnName: 'Referentiecode',
        width: 85,
      },
      {
        columnName: 'ProductsoortnaamKort',
        width: 60,
      },
      {
        columnName: 'Nieuw',
        width: 50,
      },
      {
        columnName: '__leeftijd' as any,
        width: 75,
      },
      {
        columnName: '__pendelLosdatum' as any,
        width: 75,
      },
      {
        columnName: 'Kenmerk',
        width: 235,
      },
      {
        columnName: '__productinfo' as any,
        width: 55,
      },
      {
        columnName: '__inGekoppeldeProducttypenVoorProductmodel' as any,
        width: 100,
      },
    ],
    [],
  );

  const keyExtractor = useCallback((row: IOphalenSelectieGebruiktResultElement) => row.ProdID, []);

  return producten === null || productmodel === null ? (
    <div className="flex-fill d-flex align-items-center justify-content-center">
      <LoadingSpinner />
    </div>
  ) : (
    <>
      <GridStyleWrapper maxHeight={300}>
        <Grid columns={kolommen} getRowId={keyExtractor} rows={producten}>
          <DataTypeProvider
            for={['Nieuw']}
            formatterComponent={(formatterProps) => {
              const rij: IOphalenSelectieGebruiktResultElement = formatterProps.row;
              return <span>{rij.Nieuw ? 'N' : 'G'}</span>;
            }}
          />

          <DataTypeProvider
            for={['Referentiecode']}
            formatterComponent={(formatterProps) => {
              return <span>{formatterProps.value !== null ? formatterProps.value : '?'}</span>;
            }}
          />

          <DataTypeProvider
            for={['__leeftijd']}
            formatterComponent={(formatterProps) => {
              const rij: IOphalenSelectieGebruiktResultElement = formatterProps.row;
              return <span>{maandenNaarJaren(rij.leeftijd)}</span>;
            }}
          />

          <DataTypeProvider
            for={['__pendelLosdatum']}
            formatterComponent={(formatterProps) => {
              const rij: IOphalenSelectieGebruiktResultElement = formatterProps.row;

              const dagen =
                rij.pendelLosdatum !== null
                  ? differenceInDays(new Date(), new Date(rij.pendelLosdatum))
                  : null;

              return <span>{dagen}</span>;
            }}
          />

          <DataTypeProvider
            for={['__productinfo']}
            formatterComponent={(formatterProps) => {
              const rij: IOphalenSelectieGebruiktResultElement = formatterProps.row;

              return (
                <div className="mb-1">
                  <a
                    href="#"
                    onClick={() => {
                      setProductinfoDialoogState({
                        prodID: rij.ProdID,
                      });
                    }}
                  >
                    <IconInformatie style={{ width: 15, height: 15, fill: EKleur.Blauw }} />
                  </a>
                </div>
              );
            }}
          />

          <DataTypeProvider
            for={['__inGekoppeldeProducttypenVoorProductmodel']}
            formatterComponent={(formatterProps) => {
              const rij: IOphalenSelectieGebruiktResultElement = formatterProps.row;

              const inTypenVoorModel = productmodel.producttypen.some(
                (x) => x.TypeID === rij.TypeID,
              );
              return <span>{inTypenVoorModel ? 'Ja' : 'Nee'}</span>;
            }}
          />

          <VirtualTable
            messages={geenData}
            rowComponent={(rowProps) => {
              const row: IOphalenSelectieGebruiktResultElement = rowProps.row;

              const inModel = productmodel.producttypen.some((x) => x.TypeID === row.TypeID);

              if (inModel) {
                return <PrioriteitTr kleuren={true}>{rowProps.children}</PrioriteitTr>;
              }
              return <tr>{rowProps.children}</tr>;
            }}
          />
          <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
          <TableHeaderRow />
          {/* <RowDetailState defaultExpandedRowIds={[]} />
        <TableRowDetail toggleCellComponent={DXTableToggleCellComponent} /> */}

          <SelectionState
            selection={prodID === null ? [] : [prodID]}
            // Maar een element selecteren per keer, dus pakken bij een deselectie, een lege array
            // of bij een selectie het laatst geselecteerde element als het enige element
            onSelectionChange={(x) =>
              onProdIDChange((x.length > 0 ? x[x.length - 1] : null) as number)
            }
          />

          <TableSelection cellComponent={DXTableCheckboxComponent} />
        </Grid>
      </GridStyleWrapper>

      {productinfoDialoogState !== null && (
        <ProductinfoDialoog
          open
          id={productinfoDialoogState.prodID}
          onSuccess={() => {
            setProductinfoDialoogState(null);
          }}
          onAnnuleren={() => setProductinfoDialoogState(null)}
        />
      )}
    </>
  );
};

export default Gebruikt;
