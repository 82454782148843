import {
  IOphalenVoorstellenParams,
  IOphalenVoorstellenResult,
  IKoppelenRelatieParams,
  IKoppelenRelatieResult,
  IToevoegenVoorstelParams,
  IToevoegenVoorstelResult,
  IWijzigenVoorstelParams,
  IWijzigenVoorstelResult,
  IVerwijderenVoorstellenParams,
  IVerwijderenVoorstellenResult,
  IOphalenSponsoringVoorstelPersonenParams,
  IOphalenSponsoringVoorstelPersonenResult,
  IToevoegenSponsoringVoorstelPersoonParams,
  IToevoegenSponsoringVoorstelPersoonResult,
  IVerwijderenSponsoringVoorstelPersonenParams,
  IVerwijderenSponsoringVoorstelPersonenResult,
  IWijzigenSponsoringVoorstelPersoonParams,
  IWijzigenSponsoringVoorstelPersoonResult,
} from '../../../../../../shared/src/api/v2/sponsoring/voorstel';
import ICheckData from '../../../../../../shared/src/models/ICheckData';
import api from '../../../index';

const voorstel = {
  ophalenVoorstellen: async (
    params: IOphalenVoorstellenParams,
  ): Promise<IOphalenVoorstellenResult> => {
    return await api.post('/v2/sponsoring/voorstel/ophalen-voorstellen', params);
  },
  koppelenRelatie: async (params: IKoppelenRelatieParams): Promise<IKoppelenRelatieResult> => {
    return await api.post('/v2/sponsoring/voorstel/koppelen-relatie', params);
  },
  checkToevoegenVoorstel: async (params: IToevoegenVoorstelParams): Promise<ICheckData> => {
    return await api.post('/v2/sponsoring/Voorstel/check-toevoegen-voorstel', params);
  },
  toevoegenVoorstel: async (
    params: IToevoegenVoorstelParams,
  ): Promise<IToevoegenVoorstelResult> => {
    return await api.post('/v2/sponsoring/Voorstel/toevoegen-voorstel', params);
  },
  checkWijzigenVoorstel: async (params: IWijzigenVoorstelParams): Promise<ICheckData> => {
    return await api.post('/v2/sponsoring/Voorstel/check-wijzigen-voorstel', params);
  },
  wijzigenVoorstel: async (params: IWijzigenVoorstelParams): Promise<IWijzigenVoorstelResult> => {
    return await api.post('/v2/sponsoring/Voorstel/wijzigen-voorstel', params);
  },
  checkVerwijderenVoorstellen: async (
    params: IVerwijderenVoorstellenParams,
  ): Promise<ICheckData> => {
    return await api.post('/v2/sponsoring/Voorstel/check-verwijderen-voorstellen', params);
  },
  verwijderenVoorstellen: async (
    params: IVerwijderenVoorstellenParams,
  ): Promise<IVerwijderenVoorstellenResult> => {
    return await api.post('/v2/sponsoring/Voorstel/verwijderen-voorstellen', params);
  },
  ophalenSponsoringVoorstellenPersonen: async (
    params: IOphalenSponsoringVoorstelPersonenParams,
  ): Promise<IOphalenSponsoringVoorstelPersonenResult> => {
    return await api.post('/v2/sponsoring/voorstel/ophalen-sponsoring-voorstel-personen', params);
  },
  toevoegenSponsoringVoorstelPersoon: async (
    params: IToevoegenSponsoringVoorstelPersoonParams,
  ): Promise<IToevoegenSponsoringVoorstelPersoonResult> => {
    return await api.post('/v2/sponsoring/voorstel/toevoegen-sponsoring-voorstel-persoon', params);
  },
  wijzigenSponsoringVoorstelPersoon: async (
    params: IWijzigenSponsoringVoorstelPersoonParams,
  ): Promise<IWijzigenSponsoringVoorstelPersoonResult> => {
    return await api.post('/v2/sponsoring/voorstel/wijzigen-sponsoring-voorstel-persoon', params);
  },
  verwijderenSponsoringVoorstelPersonen: async (
    params: IVerwijderenSponsoringVoorstelPersonenParams,
  ): Promise<IVerwijderenSponsoringVoorstelPersonenResult> => {
    return await api.post(
      '/v2/sponsoring/voorstel/verwijderen-sponsoring-voorstel-personen',
      params,
    );
  },
};

export default voorstel;
