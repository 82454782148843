import React, { useContext, useMemo } from 'react';
import { IProps } from '@tinymce/tinymce-react/lib/cjs/main/ts/components/Editor';
import { IContext, ProductContext } from '../index';
import VeldWeergave from '../../../formulier/VeldWeergave';
import LoadingSpinner from '../../../Gedeeld/LoadingSpinner';
import { format } from 'date-fns';
import GegevensLayout from '../../../layout/GegevensLayout';
import FormatteerBedrag from '../../../MutatieBedrag';
import { GridStyleWrapper, TypedColumn } from '../../../../helpers/dxTableGrid';
import { Grid, Table, TableHeaderRow } from '@devexpress/dx-react-grid-bootstrap4';
import { Kleur as EKleur } from '../../../../bedrijfslogica/constanten';

interface IProducttype {
  PrioNr: number;
  Merknaam: string;
  Typenaam: string;
}

const DetailTab: React.FC<IProps> = (props) => {
  const { productmodel } = useContext<IContext>(ProductContext);

  const producttypen =
    productmodel !== null
      ? productmodel.producttypen.map((x) => {
          return { PrioNr: x.PrioNr, Merknaam: x.Merknaam, Typenaam: x.Typenaam };
        })
      : [];

  const kolommen = useMemo<TypedColumn<IProducttype>[]>(
    () => [
      {
        name: 'PrioNr',
        title: 'Prio.',
      },
      {
        name: 'Merknaam',
        title: 'Merk',
      },
      {
        name: 'Typenaam',
        title: 'Type',
      },
    ],
    [],
  );

  const columnExtensions = useMemo<Table.ColumnExtension[]>(
    () => [
      {
        columnName: 'PrioNr',
        width: 70,
      },
      {
        columnName: 'Merknaam',
        width: 110,
      },
      {
        columnName: 'Typenaam',
        width: 200,
      },
    ],
    [],
  );

  if (productmodel === null) {
    return <LoadingSpinner />;
  }

  return (
    <div className="d-flex flex-column">
      <div className="mt-2 pl-3 pr-3 pt-2 mb-4">
        <VeldWeergave>
          <div className="p-1 font-weight-bold">Producttypen</div>
          <div className="mt-1" style={{ backgroundColor: EKleur.Wit }}>
            <GridStyleWrapper maxHeight={500} rowAmount={producttypen.length}>
              <Grid rows={producttypen} columns={kolommen}>
                <Table columnExtensions={columnExtensions} />
                <TableHeaderRow />
              </Grid>
            </GridStyleWrapper>
          </div>
        </VeldWeergave>
      </div>
    </div>
  );
};

export default DetailTab;
