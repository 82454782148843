import React, { useCallback, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import InfoCard from '../../../../components/personalia/PersoonVisualisatie/InfoCard';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

const WIDTH_FACTOR = 1.8;
const HEIGHT_FACTOR = 1.5;
const BORDER_WIDTH_FACTOR = 0.1;

const Root = styled.span<{ kleur: string; grootte: number }>`
  font-size: ${(props) => `${props.grootte}px`};
  font-weight: bold;
  color: ${(props) => props.kleur};
  min-width: ${(props) => `${props.grootte * WIDTH_FACTOR}px`};
  min-height: ${(props) => `${props.grootte * HEIGHT_FACTOR}px`};
  max-width: ${(props) => `${props.grootte * WIDTH_FACTOR}px`};
  max-height: ${(props) => `${props.grootte * HEIGHT_FACTOR}px`};
  display: flex;
  align-items: center;
  justify-content: center;
  border: ${(props) => `${props.grootte * BORDER_WIDTH_FACTOR}px solid ${props.kleur}`};
  border-radius: 3px;
`;

export type Kredietscore = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;

interface IProps {
  score: Kredietscore | null;
  style?: React.CSSProperties;
  className?: string;
  grootte?: number;
  zonderOverlay?: boolean;
}

const scoreNaarKleur = (score: Kredietscore | null): string => {
  if (score === null) {
    return Kleur.Grijs;
  }

  switch (score) {
    case 1:
    case 2:
      return Kleur.Rood;
    case 3:
    case 4:
      return Kleur.Oranje;
    case 5:
    case 6:
      return Kleur.DonkerGeel;
    case 7:
    case 8:
    case 9:
    case 10:
      return Kleur.Groen;
  }
};
const scoreNaarRisicoTekst = (score: Kredietscore | null): string => {
  if (score === null) {
    return 'Onbekend risico';
  }

  switch (score) {
    case 1:
    case 2:
      return 'Zeer hoog risico';
    case 3:
      return 'Hoog risico';
    case 4:
      return 'Groot risico';
    case 5:
      return 'Matig risico';
    case 6:
      return 'Klein risico';
    case 7:
      return 'Laag risico';
    case 8:
    case 9:
    case 10:
      return 'Zeer laag risico';
  }
};

type RisicoTekstMap = {
  [K in Kredietscore]: string;
};

const risicoTekstMap: RisicoTekstMap = new Array(10).fill(null).reduce((acc, _, i) => {
  const score = (i + 1) as Kredietscore;
  acc[score] = scoreNaarRisicoTekst(score);
  return acc;
}, {});

interface IRisicoTekstNaarScoreMap {
  [risicoTekst: string]: Kredietscore[];
}

const risicoTekstNaarScoreMap: IRisicoTekstNaarScoreMap = Object.entries(risicoTekstMap).reduce<
  IRisicoTekstNaarScoreMap
>((acc, [key, value]) => {
  const score = Number(key) as Kredietscore;
  const risicoTekst = value;

  if (acc[risicoTekst] === undefined) {
    acc[risicoTekst] = [];
  }
  acc[risicoTekst].push(score);

  return acc;
}, {});

const KredietwaardigheidIndicatie = (props: IProps) => {
  const kleur = useMemo<string>(() => {
    return scoreNaarKleur(props.score);
  }, [props.score]);

  const risicoTekst = useMemo(() => {
    return scoreNaarRisicoTekst(props.score);
  }, [props.score]);

  const [overlayOpen, setOverlayOpen] = useState(false);
  const overlayTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const handleMouseEnterOverlay = useCallback(() => {
    if (overlayTimeoutRef.current !== null) {
      clearTimeout(overlayTimeoutRef.current);
    }
    overlayTimeoutRef.current = setTimeout(() => setOverlayOpen(true), 800);
  }, []);
  const handleMouseLeaveOverlay = useCallback(() => {
    if (overlayTimeoutRef.current !== null) {
      clearTimeout(overlayTimeoutRef.current);
    }
    overlayTimeoutRef.current = setTimeout(() => setOverlayOpen(false), 400);
  }, []);

  const renderOverlay = useCallback(
    (p: any) => {
      return (
        <div
          // id={`kredietwaardigheid-indicatie-overlay`}
          {...p}
          onMouseEnter={handleMouseEnterOverlay}
          onMouseLeave={handleMouseLeaveOverlay}
          style={{
            ...p.style,
            zIndex: 99999,
            border: `1px solid ${Kleur.Grijs}`,
            borderRadius: 5,
          }}
        >
          <div className="p-3" style={{ backgroundColor: Kleur.Wit, borderRadius: 5 }}>
            <div className="pl-1 pr-1">
              <div className="font-weight-bold" style={{ fontSize: 15 }}>
                Kredietwaardigheidstoetsing
              </div>
              <div style={{ fontSize: 15 }}>{risicoTekst}</div>
            </div>
            <div
              className="mt-3"
              style={{
                backgroundColor: Kleur.HeelLichtGrijs,
                borderRadius: 5,
                padding: '8px 12px',
              }}
            >
              <div
                style={{
                  marginBottom: 5,
                  fontSize: 14,
                }}
                className="font-weight-bold"
              >
                Legenda
              </div>
              <table>
                <tbody>
                  {Object.entries(risicoTekstNaarScoreMap).map(([risicoTekst, scores], i) => {
                    return (
                      <tr key={i}>
                        <td className="d-flex align-items-center pr-4" style={{ marginBottom: 4 }}>
                          {scores.map((score, i) => {
                            return (
                              <div key={score} className="d-flex align-items-center">
                                <KredietwaardigheidIndicatie
                                  key={score}
                                  score={score}
                                  zonderOverlay
                                />

                                {i < scores.length - 1 && <span className="ml-1 mr-1">-</span>}
                              </div>
                            );
                          })}
                        </td>
                        <td style={{ verticalAlign: 'top' }}>
                          <span className="text-muted pr-3">{risicoTekst}</span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      );
    },
    [handleMouseEnterOverlay, handleMouseLeaveOverlay, risicoTekst, risicoTekstNaarScoreMap],
  );

  const grootte = props.grootte ?? 12;

  const root = (
    <Root
      style={props.style}
      className={props.className}
      kleur={kleur}
      grootte={grootte}
      onMouseEnter={() => {
        handleMouseEnterOverlay();
      }}
      onMouseLeave={() => {
        handleMouseLeaveOverlay();
      }}
    >
      R
      <span style={{ fontSize: props.score === 10 ? grootte - 2 : grootte }}>
        {props.score === null ? '?' : props.score}
      </span>
    </Root>
  );

  if (props.zonderOverlay) {
    return root;
  }

  return (
    <OverlayTrigger
      trigger="hover"
      placement="auto-start"
      overlay={renderOverlay}
      show={overlayOpen}
      popperConfig={
        {
          modifiers: {
            name: 'offset',
            options: {
              offset: [0, 5],
            },
          },
        } as any
      }
    >
      {root}
    </OverlayTrigger>
  );
};

export default KredietwaardigheidIndicatie;
