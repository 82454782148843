import React, { useCallback, useMemo, useState } from 'react';
import api from '../../api';
import { IconDownload } from '../Icons';
import { Kleur } from '../../bedrijfslogica/constanten';
import LoadingSpinner from '../Gedeeld/LoadingSpinner';

export enum EDownloadKnopVariant {
  Klein,
  Groot,
}

interface IProps {
  onDownloadAangevraagd: () => Promise<void>;
  variant?: EDownloadKnopVariant;
  disabled?: boolean;
}

const DownloadKnop: React.FC<IProps> = (props) => {
  const [loading, setLoading] = useState(false);
  const variant = useMemo(() => {
    return props.variant ?? EDownloadKnopVariant.Klein;
  }, [props.variant]);

  const handleClick = useCallback(async () => {
    setLoading(true);
    await props.onDownloadAangevraagd();
    setLoading(false);
  }, [props.onDownloadAangevraagd]);

  const disabled = useMemo(() => props.disabled || loading, [props.disabled, loading]);

  if (variant === EDownloadKnopVariant.Klein) {
    return (
      <div
        style={{ cursor: disabled ? 'inherit' : 'pointer' }}
        onClick={handleClick}
        className="d-flex align-items-center"
      >
        <IconDownload
          style={{
            width: 17,
            height: 17,
            fill: disabled ? Kleur.Grijs : Kleur.Blauw,
            marginLeft: 15,
          }}
        />

        {loading && (
          <span className="ml-2">
            <LoadingSpinner grootte="18px" dikte="2px" />
          </span>
        )}
      </div>
    );
  }

  return (
    <button
      className="btn btn-sm btn-light"
      style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
      onClick={handleClick}
      disabled={disabled}
    >
      <IconDownload
        style={{
          width: 16,
          height: 16,
          fill: disabled ? Kleur.Grijs : Kleur.Blauw,
        }}
      />
      <span className="ml-2">Downloaden</span>
    </button>
  );
};

export default DownloadKnop;
