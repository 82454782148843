import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../stores/RootStore';
import { IconEmail } from '../../Icons';
import { Kleur, Kleur as EKleur } from '../../../bedrijfslogica/constanten';
import { Overlay, Popover, OverlayTrigger } from 'react-bootstrap';
import HorizontaleScheidingslijn from '../../layout/HorizontaleScheidingslijn';
import InfoCard from './InfoCard';

interface IProps {
  emailBerID: number;
}

const EmailBerichtVisualisatie: React.FC<IProps> = observer((props) => {
  const { emailStore } = useContext(RootStoreContext);
  const email = useMemo(() => emailStore.alleEmails[props.emailBerID] ?? null, [
    props.emailBerID,
    emailStore.alleEmails,
  ]);

  useEffect(() => {
    if (email !== null) {
      return;
    }
    emailStore.enqueueOphalenEmail(props.emailBerID);
  }, [props.emailBerID]);

  const inhoud = useMemo(() => {
    if (email === null) {
      return null;
    }

    const tekst =
      email.Inhoud === null
        ? '<i>Geen inhoud beschikbaar.</i>'
        : email.IsHTML
        ? email.Inhoud
        : email.Inhoud.replace(/\n/g, '<br/>');
    return `
        <style>
        body {
            font-family: "Lato", sans-serif;
            font-size: 14px;
            -moz-osx-font-smoothing: grayscale;
            -webkit-font-smoothing: antialiased;
            color: ${EKleur.DonkerGrijs};
            padding: 0;
            margin: 0;
        }
        </style>
        <link href="https://fonts.googleapis.com/css?family=Lato:400,700&amp;display=swap" rel="stylesheet">
        ${tekst}
    `;
  }, [email]);

  const iframeRef = useRef<HTMLIFrameElement>(null);

  const overlay = useCallback(
    (props: any) => {
      return (
        <Popover {...props}>
          <div className="d-flex flex-column flex-fill p-2">
            <h5 className="ml-2 mr-2">{email.Onderwerp}</h5>
            <HorizontaleScheidingslijn />
            <div className="mt-1">
              <iframe
                ref={iframeRef}
                onLoad={() => {
                  iframeRef.current!.style.minHeight =
                    iframeRef.current!.contentWindow!.document.body.scrollHeight + 'px';
                }}
                srcDoc={inhoud!}
                style={{
                  outline: 0,
                  border: 0,
                  width: '100%',
                  // maxHeight: 300,
                  margin: 0,
                }}
                frameBorder={0}
                // scrolling="no"
              />
            </div>
          </div>
        </Popover>
      );
    },
    [email, inhoud],
  );
  const [showOverlay, setShowOverlay] = useState(false);
  const targetRef = useRef<HTMLElement>(null);

  const [showInfoCardOverlay, setShowInfoCardOverlay] = useState(false);
  const showInfoCardOverlayTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const handleMouseEnterInfoCardOverlay = useCallback(() => {
    if (showInfoCardOverlayTimeoutRef.current !== null) {
      clearTimeout(showInfoCardOverlayTimeoutRef.current);
    }
    showInfoCardOverlayTimeoutRef.current = setTimeout(() => setShowInfoCardOverlay(true), 800);
  }, []);
  const handleMouseLeaveInfoCardOverlay = useCallback(() => {
    if (showInfoCardOverlayTimeoutRef.current !== null) {
      clearTimeout(showInfoCardOverlayTimeoutRef.current);
    }
    showInfoCardOverlayTimeoutRef.current = setTimeout(() => setShowInfoCardOverlay(false), 400);
  }, []);
  const renderInfoCardOverlay = useCallback(
    (p: any) => {
      return (
        <div
          id={`emailber-visualisatie-overlay-${props.emailBerID}`}
          {...p}
          onMouseEnter={handleMouseEnterInfoCardOverlay}
          onMouseLeave={handleMouseLeaveInfoCardOverlay}
          style={{
            ...p.style,
            zIndex: 99999,
            border: `1px solid ${Kleur.LichtGrijs}`,
            borderRadius: 5,
          }}
        >
          <InfoCard emailBerID={props.emailBerID} />
        </div>
      );
    },
    [props.emailBerID, handleMouseEnterInfoCardOverlay, handleMouseLeaveInfoCardOverlay],
  );

  if (email === null) {
    return <span>Laden...</span>;
  }

  return (
    <OverlayTrigger
      trigger="hover"
      placement="auto-start"
      overlay={renderInfoCardOverlay}
      show={showInfoCardOverlay}
      popperConfig={
        {
          modifiers: {
            name: 'offset',
            options: {
              offset: [0, 5],
            },
          },
        } as any
      }
    >
      <span
        className="d-flex align-items-center"
        onMouseEnter={handleMouseEnterInfoCardOverlay}
        onMouseLeave={handleMouseLeaveInfoCardOverlay}
      >
        <IconEmail
          style={{ fill: EKleur.Interactief, width: 14, height: 14, position: 'relative', top: 1 }}
        />
        <span
          ref={targetRef}
          className="ml-2"
          style={{ color: EKleur.Interactief }}
          onClick={() => setShowOverlay(true)}
        >
          Email
        </span>

        <Overlay
          rootClose
          show={showOverlay}
          onHide={() => setShowOverlay(false)}
          target={targetRef.current!}
          placement="auto"
        >
          {overlay}
        </Overlay>
      </span>
    </OverlayTrigger>
  );
});

export default EmailBerichtVisualisatie;
