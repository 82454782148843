import React, { useCallback, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { genereerUrlStateQueryParam } from '../../../core/useUrlState';
import { Kleur } from '../../../bedrijfslogica/constanten';
import InfoCard from './InfoCard';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { IUrlState } from '../../../paginas/Communicatie/Contactverzoek';

interface IProps extends RouteComponentProps {
  ctcVerzoekID: number;
}

const ContactverzoekVisualisatie: React.FC<IProps> = observer((props) => {
  const [isHovered, setIsHovered] = useState(false);

  const [showInfoCardOverlay, setShowInfoCardOverlay] = useState(false);
  const showInfoCardOverlayTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const handleMouseEnterInfoCardOverlay = useCallback(() => {
    if (showInfoCardOverlayTimeoutRef.current !== null) {
      clearTimeout(showInfoCardOverlayTimeoutRef.current);
    }
    showInfoCardOverlayTimeoutRef.current = setTimeout(() => setShowInfoCardOverlay(true), 800);
  }, []);
  const handleMouseLeaveInfoCardOverlay = useCallback(() => {
    if (showInfoCardOverlayTimeoutRef.current !== null) {
      clearTimeout(showInfoCardOverlayTimeoutRef.current);
    }
    showInfoCardOverlayTimeoutRef.current = setTimeout(() => setShowInfoCardOverlay(false), 400);
  }, []);
  const renderInfoCardOverlay = useCallback(
    (p: any) => {
      return (
        <div
          id={`contactverzoek-visualisatie-overlay-${props.ctcVerzoekID}`}
          {...p}
          onMouseEnter={handleMouseEnterInfoCardOverlay}
          onMouseLeave={handleMouseLeaveInfoCardOverlay}
          style={{
            ...p.style,
            zIndex: 99999,
            border: `1px solid ${Kleur.LichtGrijs}`,
            borderRadius: 5,
          }}
        >
          <InfoCard ctcVerzoekID={props.ctcVerzoekID} />
        </div>
      );
    },
    [props.ctcVerzoekID, handleMouseEnterInfoCardOverlay, handleMouseLeaveInfoCardOverlay],
  );

  return (
    <OverlayTrigger
      trigger="hover"
      placement="auto-start"
      overlay={renderInfoCardOverlay}
      show={showInfoCardOverlay}
      popperConfig={
        {
          modifiers: {
            name: 'offset',
            options: {
              offset: [0, 5],
            },
          },
        } as any
      }
    >
      <a
        href="#"
        className="d-flex align-items-center"
        onMouseEnter={() => {
          setIsHovered(true);
          handleMouseEnterInfoCardOverlay();
        }}
        onMouseLeave={() => {
          setIsHovered(false);
          handleMouseLeaveInfoCardOverlay();
        }}
        onClick={(ev) => {
          ev.preventDefault();

          const state = genereerUrlStateQueryParam<IUrlState>({
            selectie: [props.ctcVerzoekID],
            uitgeklapt: [props.ctcVerzoekID],
            scrollenNaarID: props.ctcVerzoekID,
          });
          props.history.push(`/communicatie/contactverzoek?state=${state}`);
        }}
      >
        <span>Naar contactverzoek</span>
      </a>
    </OverlayTrigger>
  );
});

export default withRouter(ContactverzoekVisualisatie);
