import React from 'react';
import { Route, Link, RouteComponentProps, NavLink, Redirect } from 'react-router-dom';
import PaginaHeader from '../PaginaHeader';
import { IconFactuur, IconLink, IconOverzicht } from '../../components/Icons';
import Inkoopfacturen from './Inkoopfacturen';
import NogTeVerwerkenInkoopfacturen from './NogTeVerwerkenInkoopfacturen';
import Overzicht from './Overzicht';
import Koppelen from './Koppelen';

interface IProps extends RouteComponentProps {}

const Crediteuren = (props: IProps) => {
  const { match } = props;

  return (
    <div className="w-100 h-100 d-flex flex-fill flex-column">
      <PaginaHeader>
        <ul>
          <li>
            <NavLink
              to={`${match.url}/inkoopfacturen`}
              style={{ paddingLeft: 20, paddingRight: 20 }}
              className="d-flex align-items-center justify-content-center"
            >
              <IconFactuur style={{ width: 20, height: 20 }} />
              &nbsp; Inkoopfacturen
            </NavLink>
          </li>
          {/* <li>
            <NavLink
              to={`${match.url}/ontvangen-inkoopfacturen`}
              style={{ paddingLeft: 20, paddingRight: 20 }}
              className="d-flex align-items-center justify-content-center"
            >
              <IconFactuur style={{ width: 20, height: 20 }} />
              &nbsp; Ontvangen inkoopfacturen
            </NavLink>
          </li> */}
          <li>
            <NavLink
              to={`${match.url}/koppelen`}
              style={{ paddingLeft: 20, paddingRight: 20 }}
              className="d-flex align-items-center justify-content-center"
            >
              <IconLink style={{ width: 20, height: 20 }} />
              &nbsp; Koppelen
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`${match.url}/overzicht`}
              style={{ paddingLeft: 20, paddingRight: 20 }}
              className="d-flex align-items-center justify-content-center"
            >
              <IconOverzicht style={{ width: 20, height: 20 }} />
              &nbsp; Openstaande crediteuren
            </NavLink>
          </li>
        </ul>
      </PaginaHeader>

      <Route path={`${match.path}/inkoopfacturen`} component={Inkoopfacturen} />
      <Route
        path={`${match.path}/ontvangen-inkoopfacturen`}
        component={NogTeVerwerkenInkoopfacturen}
      />
      <Route path={`${match.path}/overzicht`} component={Overzicht} />
      <Route path={`${match.path}/koppelen`} component={Koppelen} />

      <Route
        exact={true}
        path={match.path}
        render={() => <Redirect to={`${match.path}/inkoopfacturen`} />}
      />
    </div>
  );
};

export default Crediteuren;
