import {
  DataTypeProvider,
  IntegratedSorting,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import { format } from 'date-fns';
import React, { useCallback, useMemo, useState } from 'react';
import { IOphalenNogTeKoppelenProductenResultElement } from '../../../../../../../../../shared/src/api/v2/inkoopfactuur';
import { IOphalenProductenResultElementV2 } from '../../../../../../../../../shared/src/api/v2/product';
import RelatieVisualisatie from '../../../../../../../components/personalia/RelatieVisualisatie';
import {
  DXTableCheckboxComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../../../../helpers/dxTableGrid';

interface IProps {
  producten: IOphalenProductenResultElementV2[];
  selectie: number[];
  onSelectieChange: (selectie: number[]) => void;
}

export interface IRegel extends IOphalenProductenResultElementV2 {}

const PerProductWeergave: React.FC<IProps> = (props) => {
  const producten = props.producten;

  const kolommen = useMemo<TypedColumn<IRegel>[]>(
    () => [
      {
        name: 'DatumInBedrijf',
        title: 'In bedrijf',
      },
      {
        name: '__relatie' as any,
        title: 'Leverancier',
      },
      {
        name: '__merknaam' as any,
        title: 'Merk',
      },
      {
        name: '__typenaam' as any,
        title: 'Type',
      },
      {
        name: '__inkoopreferentie' as any,
        title: 'Ink.ref.',
        getCellValue: (x) => (x.inkoopopdracht !== null ? x.inkoopopdracht.Referentie : null),
      },
      {
        name: 'Referentiecode',
        title: 'Ref.code',
      },
      {
        name: '__zoekgemeld' as any,
        title: 'Zoek',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRegel>[]>(
    () => [
      {
        columnName: '__merknaam' as any,
        width: 85,
      },
      {
        columnName: '__typenaam' as any,
        width: 175,
      },
      {
        columnName: 'DatumInBedrijf',
        width: 100,
      },
      {
        columnName: '__inkoopreferentie' as any,
        width: 100,
      },
      {
        columnName: '__relatie' as any,
        width: 125,
      },
      {
        columnName: 'Referentiecode',
        width: 100,
      },
      {
        columnName: '__zoekgemeld' as any,
        width: 125,
      },
    ],
    [],
  );

  return (
    <>
      <GridStyleWrapper rowAmount={producten.length}>
        <Grid columns={kolommen} rows={producten}>
          <DataTypeProvider
            for={['DatumInBedrijf']}
            formatterComponent={(formatterProps) => {
              const rij: IRegel = formatterProps.row;
              return <span>{format(new Date(rij.DatumInBedrijf), 'dd-MM-yyyy')}</span>;
            }}
          />

          <DataTypeProvider
            for={['__relatie']}
            formatterComponent={(formatterProps) => {
              const rij: IOphalenProductenResultElementV2 = formatterProps.row;
              if (rij.inkoopopdracht === null) {
                return <span></span>;
              }
              const relID = rij.inkoopopdracht.dienst.RelID;
              return <RelatieVisualisatie relID={relID} options={{ geenLinkToepassen: true }} />;
            }}
          />

          <DataTypeProvider
            for={['__productsoortnaam']}
            formatterComponent={(formatterProps) => {
              const rij: IOphalenProductenResultElementV2 = formatterProps.row;
              return <span>{rij.producttype.Productsoortnaam}</span>;
            }}
          />

          <DataTypeProvider
            for={['__typenaam']}
            formatterComponent={(formatterProps) => {
              const rij: IOphalenProductenResultElementV2 = formatterProps.row;
              return <span>{rij.producttype.Typenaam}</span>;
            }}
          />

          <DataTypeProvider
            for={['__merknaam']}
            formatterComponent={(formatterProps) => {
              const rij: IOphalenProductenResultElementV2 = formatterProps.row;
              return <span>{rij.producttype.Merknaam}</span>;
            }}
          />

          <DataTypeProvider
            for={['__inkoopreferentie']}
            formatterComponent={(formatterProps) => {
              const rij: IOphalenProductenResultElementV2 = formatterProps.row;
              if (rij.inkoopopdracht === null) {
                return <span></span>;
              }
              return <span>{rij.inkoopopdracht.Referentie}</span>;
            }}
          />

          <DataTypeProvider
            for={['__zoekgemeld']}
            formatterComponent={(formatterProps) => {
              const rij: IOphalenProductenResultElementV2 = formatterProps.row;

              return <span>{rij.ZoekGemeld !== null ? `Ja` : ``}</span>;
            }}
          />

          <SortingState defaultSorting={[]} />
          <IntegratedSorting />

          <VirtualTable messages={{ noData: 'Geen producten' }} />
          <TableColumnResizing defaultColumnWidths={kolomBreedtes} />

          <TableHeaderRow showSortingControls />

          <SelectionState
            selection={props.selectie}
            onSelectionChange={(x) => props.onSelectieChange(x as number[])}
          />
          <TableSelection cellComponent={DXTableCheckboxComponent} />
        </Grid>
      </GridStyleWrapper>
    </>
  );
};

export default PerProductWeergave;
