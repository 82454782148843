import React, { useCallback, useRef } from 'react';
import styled from 'styled-components';
import { useWhatsappV2Store } from '../../../store';
import { ListRowRenderer } from 'react-virtualized/dist/es/List';
import { ERemoteDataState } from '../../../../../../models/IRemoteData';
import Skeleton from 'react-loading-skeleton';
import { AutoSizer, Index, IndexRange, InfiniteLoader, List } from 'react-virtualized';
import LoadingSpinner from '../../../../../Gedeeld/LoadingSpinner';
import { IWhatsappBericht, IWhatsappChatsessie } from '../../../../../../api/services/v1/whatsapp';
import BerichtItem from '../BerichtItem';

const Root = styled.div`
  flex: 1;
  position: relative;
`;

const ListContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-top: 5px;
  padding-bottom: 5px;
`;

interface IProps {
  onBerichtGekozen: (bericht: IWhatsappBericht, chatsessie: IWhatsappChatsessie) => void;
}

const BerichtFocusWeergave = (props: IProps) => {
  const store = useWhatsappV2Store();

  const rowRenderer = useCallback<ListRowRenderer>(
    ({ index, key, style }) => {
      const data = store.zoekResultaat.data!.berichtenData.data!;
      const berichtIdData = data.berichtIDBijIndex[index];

      if (
        berichtIdData === undefined ||
        berichtIdData.state === ERemoteDataState.Pending ||
        store.chatsessieBijID.state === ERemoteDataState.Pending
      ) {
        return (
          <div key={key} style={style} className="d-flex p-2 pl-4 pr-4">
            <Skeleton
              className="flex-fill w-100 h-100"
              containerClassName="flex-fill w-100 h-100"
            />
          </div>
        );
      }
      const berichtId = berichtIdData.data!;
      const bericht = store.berichtBijID[berichtId];
      if (bericht === undefined) {
      }
      const chatsessieId = bericht.whatsappChatsessieId;
      const chatsessie = store.chatsessieBijID.data![chatsessieId];

      return (
        <BerichtItem
          key={key}
          style={style}
          chatsessie={chatsessie}
          bericht={bericht}
          onClick={() => {
            props.onBerichtGekozen(bericht, chatsessie);
          }}
        />
      );
    },
    [
      store.zoekResultaat.data?.berichtenData,
      props.onBerichtGekozen,
      store.chatsessieBijID,
      store.berichtBijID,
    ],
  );

  const isRijGeladen = useCallback(
    (index: Index) => {
      const heeftEntry =
        store.zoekResultaat.state === ERemoteDataState.Ready &&
        store.zoekResultaat.data!.berichtenData.state === ERemoteDataState.Ready &&
        store.zoekResultaat.data!.berichtenData.data!.berichtIDBijIndex[index.index] !== undefined;
      if (!heeftEntry) {
        console.log(store.zoekResultaat.data!.berichtenData.data!);
        console.log('isRijGeladen', index, heeftEntry);
      }
      return heeftEntry;
    },
    [store.zoekResultaat.data?.berichtenData],
  );
  const laadMeerRijenTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const laadMeerRijen = useCallback(
    async (range: IndexRange) => {
      if (laadMeerRijenTimeoutRef.current !== null) {
        clearTimeout(laadMeerRijenTimeoutRef.current);
      }

      laadMeerRijenTimeoutRef.current = setTimeout(async () => {
        await store.meerZoekResultatenBerichtenLaden({
          index: range.startIndex,
          aantal: range.stopIndex - range.startIndex + 1,
        });
      }, 100);
    },
    [store.meerZoekResultatenBerichtenLaden],
  );

  const rowHeight = 95;

  return (
    <Root>
      <ListContainer>
        <AutoSizer>
          {({ width, height }) => {
            if (
              store.zoekResultaat.state === ERemoteDataState.Pending ||
              store.zoekResultaat.data === null ||
              store.zoekResultaat.data!.berichtenData.state === ERemoteDataState.Pending
            ) {
              return (
                <div
                  style={{ width, height }}
                  className="d-flex align-items-center justify-content-center"
                >
                  <LoadingSpinner />
                </div>
              );
            }
            const data = store.zoekResultaat.data!.berichtenData.data!;
            if (data.totaalAantal === 0) {
              return (
                <div
                  style={{ width, height }}
                  className="d-flex align-items-center justify-content-center"
                >
                  <span className="text-muted">Er zijn geen berichten beschikbaar.</span>
                </div>
              );
            }

            return (
              <InfiniteLoader
                loadMoreRows={laadMeerRijen}
                isRowLoaded={isRijGeladen}
                rowCount={data.totaalAantal}
                minimumBatchSize={20}
                threshold={20}
              >
                {(ilp) => (
                  <List
                    ref={(ref) => {
                      ilp.registerChild(ref);
                    }}
                    rowCount={data.totaalAantal}
                    height={height}
                    rowHeight={rowHeight}
                    rowRenderer={rowRenderer}
                    width={width}
                    overscanRowCount={10}
                    onRowsRendered={ilp.onRowsRendered}
                  />
                )}
              </InfiniteLoader>
            );
          }}
        </AutoSizer>
      </ListContainer>
    </Root>
  );
};

export default BerichtFocusWeergave;
