import React, { useState, useEffect, useCallback, useMemo, useContext } from 'react';
import api from '../../../../../../../api';
import LoadingSpinner from '../../../../../../../components/Gedeeld/LoadingSpinner';
import { observer } from 'mobx-react-lite';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import useUrlState from '../../../../../../../core/useUrlState';
import {
  IOphalenVorderingenResultElement,
  IOphalenWOPInfoVoorVorderingenResultElement,
} from '../../../../../../../../../shared/src/api/v2/vordering';
import { RootStoreContext } from '../../../../../../../stores/RootStore';
import {
  TypedColumn,
  TypedTableColumnWidthInfo,
  GridStyleWrapper,
  DXTableCheckboxComponent,
  DXTableToggleCellComponent,
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
} from '../../../../../../../helpers/dxTableGrid';
import { Kleur } from '../../../../../../../bedrijfslogica/constanten';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  VirtualTable,
  TableEditColumn,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  DataTypeProvider,
  EditingState,
  SelectionState,
  VirtualTable as VirtualTableBase,
  RowDetailState,
  SortingState,
  IntegratedSorting,
} from '@devexpress/dx-react-grid';
import { format } from 'date-fns';
import FormatteerBedrag from '../../../../../../../components/MutatieBedrag';
import { EResultType } from '../../../../../../../stores/CheckStore';
import { IconVerwijderen, IconToevoegen } from '../../../../../../../components/Icons';
import WijzigenVorderingDialoog from '../../../../../../Facturering/Vorderingen/WijzigenVorderingDialoog';
import FactuurVoorstelDialoog from '../../../../../../Facturering/Vorderingen/FactuurVoorstelDialoog';
import NieuweVorderingDialoog from '../../../../../../Facturering/Vorderingen/NieuweVorderingDialoog';
import SelectieVak from '../../../../../../../components/SelectieVak';
import _ from 'lodash';
import { ERegelstatusVordering } from '../../../../../../../bedrijfslogica/enums';
import ContractVisualisatie from '../../../../../../../components/entiteitVisualisaties/ContractVisualisatie';

interface IProps extends RouteComponentProps {
  relID: number;
}

export interface IUrlState {
  selectie: number[];
}

const defaultUrlState: IUrlState = {
  selectie: [],
};

const geenData = {
  noData: 'Geen vorderingen gevonden',
};

const Vorderingen: React.FC<IProps> = observer((props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState<IUrlState>(props, defaultUrlState);
  const [vorderingen, setVorderingen] = useState<IOphalenVorderingenResultElement[] | null>(null);
  const { klantkaartStore, checkStore } = useContext(RootStoreContext);
  const [wijzigenID, setWijzigenID] = useState<number | null>(null);
  const [nieuweDialoogTonen, setNieuweDialoogTonen] = useState<boolean>(false);
  const [voorstelDialoogTonen, setVoorstelDialoogTonen] = useState<boolean>(false);
  const [wopInfos, setWOPInfos] = useState<IOphalenWOPInfoVoorVorderingenResultElement[] | null>(
    null,
  );

  const relID = props.relID;

  const ophalenVorderingen = useCallback(async () => {
    const { vorderingen } = await api.v2.vordering.ophalenVorderingen({
      filterSchema: {
        filters: [{ naam: 'REL_IDS', data: [relID] }],
      },
      // orderSchema: {
      //   orders: [
      //     {
      //       naam: 'RECORD_GEWUJZIGD',
      //       richting: 'DESC',
      //     },
      //   ],
      // },
    });

    const vorderingenGesorteerd = _.orderBy(vorderingen, ['RecordGewijzigd'], ['desc']);

    setVorderingen(vorderingenGesorteerd);

    // Haal eventuele WOP-info erbij
    const factRegIDs = vorderingen.map((x) => x.FactRegID);
    const wopInfos = (await api.v2.vordering.ophalenWOPInfoVoorVorderingen({ factRegIDs }))
      .wopInfos;
    setWOPInfos(wopInfos);
  }, [relID]);

  useEffect(() => {
    ophalenVorderingen();
  }, [relID]);

  const handleVerwijderen = useCallback(async () => {
    const checkData = await api.v2.vordering.checkVerwijderenVorderingen({
      factRegIDs: urlState.selectie,
    });
    if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
      return;
    }
    if (
      (
        await checkStore.bevestigen({
          inhoud: (
            <span>
              Wil je de vorderingen verwijderen?
              <br />
              <br />
              Let op: Dit kan niet meer ongedaan gemaakt worden{' '}
            </span>
          ),
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    await api.v2.vordering.verwijderenVorderingen({
      factRegIDs: urlState.selectie,
    });

    setUrlStateSync('selectie', []);

    ophalenVorderingen();
  }, [urlState.selectie]);

  const handleNieuweVordering = () => {
    setNieuweDialoogTonen(true);
  };

  const keyExtractor = useCallback((row: IOphalenVorderingenResultElement) => row.FactRegID, []);

  const kolommen = useMemo<TypedColumn<IOphalenVorderingenResultElement>[]>(
    () => [
      {
        name: 'Omschrijving',
        title: 'Omschrijving',
      },
      {
        name: 'Bedrag',
        title: 'Bedrag',
      },
      {
        name: '__status' as any,
        title: 'Status',
      },
      {
        name: '__contractnummer' as any,
        title: 'Cnt.nr.',
      },
      {
        name: '__periodeVan' as any,
        title: 'Van',
      },
      {
        name: '__periodeTot' as any,
        title: 'Tot',
      },
      {
        name: 'Kenmerk',
        title: 'Kenmerk',
      },
      {
        name: '__prolongatieVordering' as any,
        title: 'Prol.',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IOphalenVorderingenResultElement>[]>(
    () => [
      {
        columnName: 'Omschrijving',
        width: 275,
      },
      {
        columnName: '__status' as any,
        width: 90,
      },
      {
        columnName: '__contractnummer' as any,
        width: 90,
      },
      {
        columnName: '__periodeVan' as any,
        width: 100,
      },
      {
        columnName: '__periodeTot' as any,
        width: 100,
      },
      {
        columnName: 'Bedrag',
        width: 100,
      },
      {
        columnName: 'Kenmerk',
        width: 100,
      },
      {
        columnName: '__prolongatieVordering' as any,
        width: 80,
      },
    ],
    [],
  );

  const kolomExtensies: VirtualTableBase.ColumnExtension[] = useMemo(() => {
    return [
      {
        columnName: `Bedrag`,
        align: 'right',
      },
    ];
  }, []);

  return (
    <>
      {vorderingen === null || wopInfos === null ? (
        <div className="flex-fill d-flex align-items-center justify-content-center p-4">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          <div
            className="d-flex flex-column p-3"
            style={{
              backgroundColor: Kleur.HeelLichtGrijs,
              borderBottom: `1px solid ${Kleur.LichtGrijs}`,
            }}
          >
            <div className="d-flex">
              <button
                className="btn btn-sm btn-light d-flex align-items-center"
                style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                onClick={() => {
                  return handleNieuweVordering();
                }}
              >
                <IconToevoegen style={{ height: 16, width: 16, fill: Kleur.Grijs }} />
                <span className="ml-2">Nieuwe vordering</span>
              </button>

              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-2"
                style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                disabled={urlState.selectie.length === 0}
                onClick={async () => {
                  const checkData = await api.v2.vordering.checkSelectieMakenFactuurVoorstel({
                    factRegIDs: urlState.selectie,
                  });
                  const checkResult = await checkStore.controleren({ checkData });
                  if (checkResult.type === EResultType.Annuleren) {
                    return;
                  }
                  setVoorstelDialoogTonen(true);
                }}
              >
                {/* <IcoonToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} /> */}
                <span className="ml-2">Facturen maken</span>
              </button>

              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-2"
                style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                disabled={urlState.selectie.length === 0}
                onClick={() => handleVerwijderen()}
              >
                <IconVerwijderen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                <span className="ml-2">Verwijderen vorderingen</span>
              </button>
            </div>
            <div className="mt-2 d-flex align-items-center">
              <>
                <SelectieVak
                  totaalAantal={vorderingen.length}
                  aantal={urlState.selectie.length}
                  entiteitEnkelvoud="vordering"
                  entiteitMeervoud="vorderingen"
                  onChange={(allesGeselecteerd) => {
                    if (allesGeselecteerd) {
                      setUrlStateSync('selectie', vorderingen!.map(keyExtractor));
                    } else {
                      setUrlStateSync('selectie', []);
                    }
                  }}
                />
                {/* <span className="ml-2">Totaal {vorderingen.length} vorderingen</span> */}
              </>
            </div>
          </div>
          <GridStyleWrapper height="calc(100vh - 150px)">
            <Grid columns={kolommen} getRowId={keyExtractor} rows={vorderingen!}>
              <DataTypeProvider
                for={['__contractnummer']}
                formatterComponent={(formatterProps) => {
                  const rij: IOphalenVorderingenResultElement = formatterProps.row;

                  if (rij.contract === null) {
                    return <span></span>;
                  }
                  return <ContractVisualisatie cntID={rij.contract.CntID} />;
                }}
              />

              <DataTypeProvider
                for={['__prolongatieVordering']}
                formatterComponent={(props) => {
                  return <span>{props.row.prolongatierun !== null ? 'Ja' : 'Nee'}</span>;
                }}
              />

              <DataTypeProvider
                for={['__periodeVan']}
                formatterComponent={(props) => {
                  const van =
                    props.row.periodeVan !== null
                      ? format(new Date(props.row.periodeVan), 'dd-MM-yyyy')
                      : '';
                  return <span>{van}</span>;
                }}
              />

              <DataTypeProvider
                for={['__periodeTot']}
                formatterComponent={(props) => {
                  const tot =
                    props.row.periodeTot !== null
                      ? format(new Date(props.row.periodeTot), 'dd-MM-yyyy')
                      : '';
                  return <span>{tot}</span>;
                }}
              />

              <DataTypeProvider
                for={['Bedrag']}
                formatterComponent={(props) => (
                  <FormatteerBedrag bedrag={props.value} rechtsUitlijnen />
                )}
              />

              <DataTypeProvider
                for={['__status']}
                formatterComponent={(props) => {
                  const rij: IOphalenVorderingenResultElement = props.row;

                  const status = rij.regelstatus.Naam;

                  const wopInfo = wopInfos.find((x) => x.factRegID === rij.FactRegID) ?? null;

                  if (rij.regelstatus.Status === ERegelstatusVordering.WOP) {
                    return (
                      <span
                        style={{
                          color:
                            wopInfo === null
                              ? undefined
                              : wopInfo.prolongatie
                              ? Kleur.Groen
                              : Kleur.Rood,
                        }}
                      >
                        {status}
                      </span>
                    );
                  }
                  return <span>{status}</span>;
                }}
              />

              <EditingState
                onAddedRowsChange={() => {
                  alert('Plus');
                }}
                onCommitChanges={() => null}
                onEditingRowIdsChange={(rowIds) => {
                  const id = rowIds[rowIds.length - 1] as number;
                  setWijzigenID(id);
                }}
              />

              <SortingState defaultSorting={[]} />
              <IntegratedSorting />

              <VirtualTable messages={geenData} columnExtensions={kolomExtensies} />
              <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
              <TableEditColumn
                width={35}
                // showAddCommand={true}
                showEditCommand
                cellComponent={DXTableEditColumnCellComponent}
                commandComponent={DXTableEditColumnCommandComponent}
              />
              <TableHeaderRow showSortingControls />

              {/* <RowDetailState defaultExpandedRowIds={[]} />
              <TableRowDetail
                contentComponent={() => {
                  return <span>Detail</span>;
                }}
                toggleCellComponent={DXTableToggleCellComponent}
              /> */}
              <SelectionState
                selection={urlState.selectie}
                onSelectionChange={(value) => setUrlStateSync('selectie', value as number[])}
              />
              <TableSelection cellComponent={DXTableCheckboxComponent} />
            </Grid>
          </GridStyleWrapper>
          {voorstelDialoogTonen && (
            <FactuurVoorstelDialoog
              factRegIDs={urlState.selectie}
              // relID={relID}
              open
              onAnnuleren={() => setVoorstelDialoogTonen(false)}
              onSuccess={() => {
                setVoorstelDialoogTonen(false);
                setUrlStateSync('selectie', []);
                ophalenVorderingen();
                klantkaartStore.verversRelatie();
              }}
            />
          )}

          {wijzigenID !== null && (
            <WijzigenVorderingDialoog
              factRegID={wijzigenID!}
              open
              onAnnuleren={() => setWijzigenID(null)}
              onSuccess={() => {
                setWijzigenID(null);
                ophalenVorderingen();
                klantkaartStore.verversRelatie();
              }}
            />
          )}
          {nieuweDialoogTonen && (
            <NieuweVorderingDialoog
              relID={relID}
              open={nieuweDialoogTonen}
              onAnnuleren={() => setNieuweDialoogTonen(false)}
              onSuccess={() => {
                setNieuweDialoogTonen(false);
                ophalenVorderingen();
                klantkaartStore.verversRelatie();
              }}
            />
          )}
        </>
      )}
    </>
  );
});

export default withRouter(Vorderingen);
