import styled from 'styled-components';
import { Kleur } from '../../bedrijfslogica/constanten';

interface IRootProps {
  containerWidth: number;
  uitgklapt: boolean;
}
interface IContainerProps {
  containerWidth: number;
  containerHeight: number;
}

export const Root = styled.div<IRootProps>`
  //position: absolute;
  //z-index: 1000;
  display: flex;
  flex: 1;
  flex-direction: column;
  background: transparent;
  pointer-events: none;


  //bottom: 0px;
    // right: -${(props) => (props.uitgklapt ? 0 : props.containerWidth)}px;
`;

export const Container = styled.div<IContainerProps>`
  min-width: ${(p) => p.containerWidth}px;
  max-height: ${(p) => p.containerHeight}px;
  pointer-events: all;
  display: flex;
  flex-direction: column;
  flex: 1;

    // border-left: 1px solid ${Kleur.LichtGrijs};
    // border-top: 1px solid ${Kleur.LichtGrijs};

  background-color: white;
`;
