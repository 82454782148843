import React, { useState } from 'react';
import { IAfbeeldingPreviewItem } from '../../index';
import { AutoSizer } from 'react-virtualized';
import ReactImageMagnify from 'react-image-magnify';

interface IProps {
  previewItem: IAfbeeldingPreviewItem;
}

const AfbeeldingPreview = (props: IProps) => {
  const [smallImageDimensions, setSmallImageDimensions] = useState<{
    width: number;
    height: number;
  } | null>(null);

  return (
    <AutoSizer className="flex-fill d-flex flex-column w-100 h-100 align-items-center justify-content-center">
      {({ width, height }) => {
        return (
          <ReactImageMagnify
            {...{
              smallImage: {
                isFluidWidth: true,
                src: props.previewItem.bestand.url,
                sizes: '(max-width: 480px) 100vw, (max-width: 1200px) 30vw, 360px',
                onLoad: ((ev: React.SyntheticEvent<HTMLImageElement>) => {
                  setSmallImageDimensions({
                    width: ev.currentTarget.width,
                    height: ev.currentTarget.height,
                  });
                }) as any,
              },
              largeImage: {
                src: props.previewItem.bestand.url,
                width: smallImageDimensions === null ? 0 : smallImageDimensions.width * 3,
                height: smallImageDimensions === null ? 0 : smallImageDimensions.height * 3,
              },
              enlargedImageContainerDimensions: {
                width: 400,
                height: '75%',
              },
              lensStyle: { backgroundColor: 'rgba(0,0,0,.1)' },
              imageStyle: {
                maxWidth: width,
                maxHeight: height,
                width,
                height,
                objectFit: 'contain',
                // ...props.imageStyle,
              },
              enlargedImageStyle: {
                objectFit: 'contain',
              },
              enlargedImagePosition: 'over',
            }}
          />
        );

        // return (
        //   <img
        //     src={props.previewItem.bestand.url}
        //     style={{ width, height, objectFit: 'contain' }}
        //   />
        // );
      }}
    </AutoSizer>
  );
};

export default AfbeeldingPreview;
