import { DataTypeProvider, IntegratedSorting, SortingState } from '@devexpress/dx-react-grid';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import { format } from 'date-fns';
import _ from 'lodash';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { ProductSelectieDialoogContext } from '..';
import { ITekstPrecisieData } from '../../../../../../shared/src/api/sql';
import { IOphalenProductenResultElementV2 } from '../../../../../../shared/src/api/v2/product';
import { IFilterSchemaFilter } from '../../../../../../shared/src/models/filter';
import api from '../../../../api';
import nameOf from '../../../../core/nameOf';
import {
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../models/IRemoteData';
import TekstPrecisieSelectie, { ETekstPrecisie } from '../../../formulier/TekstPrecisieSelectie';
import VinkVeld from '../../../formulier/VinkVeld';
import LoadingSpinner from '../../../Gedeeld/LoadingSpinner';

export interface IFormikValues {
  referentiecode: string;
  referentiecodeTekstprecisie: ETekstPrecisie;
  postcode: string;
  typenaam: string;
  typenaamTekstprecisie: ETekstPrecisie;
  ookProductenUitBedrijf: boolean;
  notities: string;
  notitiesTekstprecisie: ETekstPrecisie;
}

interface IProps {}

const maxAantalResultaten = 250;

const SelecterenTabblad: React.FC<IProps> = (props) => {
  const { koppelOpties, selecterenValues, setSelecterenValues, onGekozen } = useContext(
    ProductSelectieDialoogContext,
  );

  const referentieCodeInputRef = useRef<HTMLInputElement>(null);

  const v = selecterenValues;
  const setV = setSelecterenValues;

  const [suggesties, setSuggesties] = useState<
    IRemoteData<
      | {
          type: 'RESULTAAT';
          result: IOphalenProductenResultElementV2[];
        }
      | {
          type: 'TE_VEEL_RESULTATEN';
          aantalNumber: number;
        }
      | {
          type: 'WACHTEN_OP_ACTIE';
        }
    >
  >(
    createReadyRemoteData({
      type: 'WACHTEN_OP_ACTIE',
    }),
  );

  const ophalenSuggesties = useCallback(async () => {
    const referentiecode = v.referentiecode.trim();
    const postcode = v.postcode.trim();
    const typenaam = v.typenaam.trim();
    const ookProductenUitBedrijf = v.ookProductenUitBedrijf;
    const notities = v.notities.trim();

    const filters: Array<IFilterSchemaFilter | null> = [
      // referentiecode.length === 0
      //   ? null
      //   : {
      //       naam: 'REFERENTIECODES',
      //       data: {
      //         waarde: referentiecode,
      //         precisie: v.referentiecodeTekstprecisie,
      //       } as ITekstPrecisieData,
      //     },
      referentiecode.length === 0
        ? null
        : {
            naam: 'REFERENTIECODES_MET_MUTATIES',
            data: [referentiecode],
          },
      postcode.length === 0
        ? null
        : {
            naam: 'LOCATIE_POSTCODES',
            data: [postcode],
          },
      typenaam.length === 0
        ? null
        : {
            naam: 'TYPENAAM_UITGEBREID',
            data: {
              waarde: typenaam,
              precisie: v.typenaamTekstprecisie,
            } as ITekstPrecisieData,
          },
      notities.length === 0
        ? null
        : {
            naam: 'NOTITIES_UITGEBREID',
            data: {
              waarde: notities,
              precisie: v.notitiesTekstprecisie,
            } as ITekstPrecisieData,
          },
      ookProductenUitBedrijf
        ? null
        : {
            naam: 'DATUM_UIT_BEDRIJF_IS_LEEG',
            data: true,
          },
    ];

    const f = filters.filter((x) => x !== null) as IFilterSchemaFilter[];
    if (f.length === 0) {
      setSuggesties(
        createReadyRemoteData({
          type: 'WACHTEN_OP_ACTIE',
        }),
      );
      return;
    }
    setSuggesties(createPendingRemoteData());

    const productenResult = (
      await api.v2.product.ophalenProductenV2({
        filterSchema: {
          filters: f,
        },
      })
    ).producten;

    if (productenResult.length > maxAantalResultaten) {
      setSuggesties(
        createReadyRemoteData({
          type: 'TE_VEEL_RESULTATEN',
          aantalNumber: productenResult.length,
        }),
      );
      return;
    }

    if (productenResult.length === 0) {
      setSuggesties(
        createReadyRemoteData({
          type: 'RESULTAAT',
          result: productenResult,
        }),
      );
      return;
    }

    setSuggesties(
      createReadyRemoteData({
        type: 'RESULTAAT',
        result: productenResult,
      }),
    );
  }, [v]);

  const debouncedOphalenSuggesties = useRef<any>(null);

  useEffect(() => {
    if (debouncedOphalenSuggesties.current !== null) {
      debouncedOphalenSuggesties.current.cancel();
      debouncedOphalenSuggesties.current = null;
    }
    debouncedOphalenSuggesties.current = _.debounce(ophalenSuggesties, 500);
    debouncedOphalenSuggesties.current();
  }, [v]);

  const sleutelExtractor = useCallback((row: IOphalenProductenResultElementV2) => row.ProdID, []);

  const kolommen = useMemo<TypedColumn<IOphalenProductenResultElementV2>[]>(
    () => [
      {
        name: '__merknaam' as any,
        title: 'Merk',
      },
      {
        name: '__typenaam' as any,
        title: 'Type',
      },
      {
        name: 'Referentiecode',
        title: 'Ref.code',
      },
      {
        name: 'DatumInBedrijf',
        title: 'In bedrijf',
      },
      {
        name: 'DatumUitBedrijf',
        title: 'Uit bedrijf',
      },
      {
        name: '__locatie' as any,
        title: 'Locatie',
      },
      {
        name: 'Notities',
        title: 'Notities',
      },
      {
        name: '__kies_actie' as any,
        title: ' ',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IOphalenProductenResultElementV2>[]>(
    () => [
      { columnName: '__merknaam' as any, width: 90 },
      { columnName: '__typenaam' as any, width: 165 },
      {
        columnName: 'Referentiecode',
        width: 95,
      },
      {
        columnName: 'DatumInBedrijf',
        width: 105,
      },
      {
        columnName: 'DatumUitBedrijf',
        width: 105,
      },
      {
        columnName: '__locatie' as any,
        width: 300,
      },
      {
        columnName: 'Notities',
        width: 150,
      },
      {
        columnName: '__kies_actie' as any,
        width: 40,
      },
    ],
    [],
  );

  useEffect(() => {
    referentieCodeInputRef.current!.focus();
  }, []);

  return (
    <>
      <div className="flex-fill d-flex flex-column position-relative">
        <div className="p-3">
          <div className="row">
            <div className="col-4">
              <div className="col-10">
                <label className="d-flex align-items-center justify-content-between">
                  <span>Ref.code</span>
                  <TekstPrecisieSelectie
                    precisie={v.referentiecodeTekstprecisie}
                    onPrecisieChange={(precisie) => {
                      setV((x) => ({
                        ...x,
                        referentiecodeTekstprecisie: precisie,
                      }));
                    }}
                  />
                </label>
                <input
                  ref={referentieCodeInputRef}
                  className="form-control"
                  value={v.referentiecode}
                  onChange={(ev) => {
                    const value = ev.target.value;
                    setV((x) => ({
                      ...x,
                      referentiecode: value,
                    }));
                  }}
                />
              </div>

              <div className="col-10 mt-2">
                <label className="d-flex align-items-center justify-content-between">
                  <span>Typenaam</span>
                  <TekstPrecisieSelectie
                    precisie={v.typenaamTekstprecisie}
                    onPrecisieChange={(precisie) => {
                      setV((x) => ({
                        ...x,
                        typenaamTekstprecisie: precisie,
                      }));
                    }}
                  />
                </label>
                <input
                  // ref={postcodeRxef}
                  className="form-control"
                  value={v.typenaam}
                  onChange={(ev) => {
                    const value = ev.target.value;
                    setV((x) => ({
                      ...x,
                      typenaam: value,
                    }));
                  }}
                />
              </div>
            </div>
            <div className="col-4">
              <div className="col-10">
                <label>Postcode</label>
                <input
                  // ref={postcodeRef}
                  className="form-control"
                  value={v.postcode}
                  onChange={(ev) => {
                    const value = ev.target.value;
                    setV((x) => ({
                      ...x,
                      postcode: value,
                    }));
                  }}
                />
              </div>

              <div className="col-10 mt-2">
                <label className="d-flex align-items-center justify-content-between">
                  <span>Notities</span>
                  <TekstPrecisieSelectie
                    precisie={v.notitiesTekstprecisie}
                    onPrecisieChange={(precisie) => {
                      setV((x) => ({
                        ...x,
                        notitiesTekstprecisie: precisie,
                      }));
                    }}
                  />
                </label>
                <input
                  className="form-control"
                  value={v.notities}
                  onChange={(ev) => {
                    const value = ev.target.value;
                    setV((x) => ({
                      ...x,
                      notities: value,
                    }));
                  }}
                />
              </div>
            </div>
            <div className="col-4"></div>
          </div>

          <div className="row col-12">
            <div className="row">
              <div className="col-12 mt-3">
                <div className="d-flex align-items-center">
                  <VinkVeld
                    aangevinkt={v.ookProductenUitBedrijf}
                    onGewijzigd={(aangevinkt: boolean) =>
                      setV((x) => ({
                        ...x,
                        ookProductenUitBedrijf: aangevinkt,
                      }))
                    }
                  />
                  <span className="ml-2">Ook zoeken in Uit bedrijf</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        {suggesties.state === ERemoteDataState.Pending ? (
          <div className="p-2 d-flex align-items-center justify-content-center">
            <LoadingSpinner />
          </div>
        ) : suggesties.data!.type === 'WACHTEN_OP_ACTIE' ? (
          <div className="p-2 d-flex flex-column align-items-center justify-content-center pb-3">
            <div>Voer een of meer selectiecriteria in</div>
          </div>
        ) : suggesties.data!.type === 'TE_VEEL_RESULTATEN' ? (
          <div className="p-2 d-flex flex-column align-items-center justify-content-center">
            <div className="font-weight-bold">
              {(suggesties.data! as any).aantalNumber} resultaten
            </div>
            <div>Vernauw / wijzig de selectiecriteria om resultaten weer te geven</div>
          </div>
        ) : suggesties.data!.type === 'RESULTAAT' ? (
          <div className="d-flex flex-column align-items-center justify-content-center">
            <span style={{ fontSize: 12 }} className="text-muted w-100 p-1 pl-3 pr-3">
              {(suggesties.data! as any).result.length} resultaten
            </span>
            <GridStyleWrapper
              maxHeight={500}
              rowAmount={
                ((suggesties.data! as any).result as IOphalenProductenResultElementV2[]).length
              }
            >
              <Grid
                getRowId={sleutelExtractor}
                columns={kolommen}
                rows={(suggesties.data! as any).result as IOphalenProductenResultElementV2[]}
              >
                <DataTypeProvider
                  for={[nameOf<IOphalenProductenResultElementV2>('Referentiecode')]}
                  formatterComponent={(formatterProps) => {
                    return <span>{formatterProps.value}</span>;
                  }}
                />

                <DataTypeProvider
                  for={['__merknaam']}
                  formatterComponent={(formatterProps) => {
                    const rij: IOphalenProductenResultElementV2 = formatterProps.row;
                    return <span>{rij.producttype.Merknaam}</span>;
                  }}
                />

                <DataTypeProvider
                  for={['__typenaam']}
                  formatterComponent={(formatterProps) => {
                    const rij: IOphalenProductenResultElementV2 = formatterProps.row;
                    return <span>{rij.producttype.Typenaam}</span>;
                  }}
                />

                <DataTypeProvider
                  for={[nameOf<IOphalenProductenResultElementV2>('DatumInBedrijf')]}
                  formatterComponent={(formatterProps) => {
                    return <span>{format(new Date(formatterProps.value), 'dd-MM-yyyy')}</span>;
                  }}
                />

                <DataTypeProvider
                  for={[nameOf<IOphalenProductenResultElementV2>('DatumUitBedrijf')]}
                  formatterComponent={(formatterProps) => {
                    if (formatterProps.value === null) {
                      return <span></span>;
                    }
                    return <span>{format(new Date(formatterProps.value), 'dd-MM-yyyy')}</span>;
                  }}
                />

                <DataTypeProvider
                  for={['__locatie']}
                  formatterComponent={(formatterProps) => {
                    const rij: IOphalenProductenResultElementV2 = formatterProps.row;
                    if (rij.locatie === null) {
                      return <span></span>;
                    }

                    const locatie =
                      rij.locatie.Straatnaam +
                      ' ' +
                      rij.locatie.Huisnummer +
                      (rij.locatie.Bisnummer !== null ? ' ' + rij.locatie.Bisnummer : '') +
                      ', ' +
                      rij.locatie.Plaatsnaam;
                    return <span>{locatie}</span>;
                  }}
                />

                <DataTypeProvider
                  for={['__kies_actie']}
                  formatterComponent={(formatterProps) => {
                    const rij: IOphalenProductenResultElementV2 = formatterProps.row;
                    return (
                      <a
                        href="#"
                        onClick={(ev) => {
                          ev.stopPropagation();
                          onGekozen(rij.ProdID);
                        }}
                      >
                        Kies
                      </a>
                    );
                  }}
                />

                <SortingState defaultSorting={[]} />
                <IntegratedSorting />

                <VirtualTable
                  rowComponent={(rowProps) => {
                    const rij: IOphalenProductenResultElementV2 = rowProps.row;
                    return (
                      <tr
                        key={rowProps.tableRow.key}
                        style={{ height: rowProps.tableRow.height, cursor: 'pointer' }}
                        onDoubleClick={() => onGekozen(rij.ProdID)}
                      >
                        {rowProps.children}
                      </tr>
                    );
                  }}
                />

                <TableColumnResizing columnWidths={kolomBreedtes} />
                <TableHeaderRow showSortingControls />
              </Grid>
            </GridStyleWrapper>
          </div>
        ) : (
          <div>Niet geimplementeerd</div>
        )}
      </div>
    </>
  );
};

export default SelecterenTabblad;
