import styled from 'styled-components';

export const Root = styled.div`
  user-select: none;
  display: flex;
  flex: 1;
  flex-direction: row;
  background-color: #f7f8fa;
  border: 1px solid #ebedf2;
  border-radius: 5px;
  min-height: 175px;
`;

const ActionButton = styled.div`
  cursor: pointer;
  background-color: white;
  border-radius: 3px;
  margin-right: 1px;
  margin-bottom: 5px;

  &:hover {
  }
`;

export const ASPDriveButton = styled(ActionButton)``;

export const LocalFileButton = styled(ActionButton)``;

export const DisabledOverlay = styled.div`
  position: relative;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  margin-top: -200px;
  //height: 200px;
`;

export const ActionsMenu = styled.div`
  //border: 1px solid #ebebeb;
  display: flex;
  flex-direction: column;
  padding: 8px 8px;
`;
