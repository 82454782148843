import { RootStore } from './RootStore';
import { makeObservable, observable } from 'mobx';
import { OphalenContractenResult } from '../../../shared/src/api/v2/contract';
import api from '../api';
import { IFilterSchema, IFilterSchemaFilter } from '../../../shared/src/models/filter';

// tslint:disable no-console
class ContractenStore {
  public contracten: { [relID: number]: OphalenContractenResult } = {};

  // tslint:disable-next-line:variable-name
  private _rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this._rootStore = rootStore;

    makeObservable(this, {
      contracten: observable,
    });
  }

  public ophalenContracten = async (relID: number, filterSchema: IFilterSchema) => {
    // if (this.contracten[relID] !== undefined) {
    //   const copy = { ...this.contracten };
    //   delete copy[relID];
    //   this.contracten = copy;
    // }

    const relatieFilters: IFilterSchemaFilter[] = [
      {
        naam: 'REL_IDS',
        data: [relID],
      },
    ];

    const result = (
      await api.v2.contract.ophalenContractenV2({
        filterSchema: {
          filters: [...relatieFilters, ...filterSchema.filters!],
        },
      })
    ).contracten;

    this.contracten = {
      ...this.contracten,
      [relID]: result,
    };
  };
}

export default ContractenStore;
