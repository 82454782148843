import { RootStore } from './RootStore';
import api from '../api';
import { makeObservable, observable } from 'mobx';
import * as _ from 'lodash';
import { addDays } from 'date-fns';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../models/IRemoteData';
import { IFilterSchema } from '../../../shared/src/models/filter';
import {
  IOproep,
  IWijzigenTelefoonOproepResult,
  IVerzoekOproepResult,
} from '../../../shared/src/api/v2/telefonie';

class TelefoonStore {
  public oproepenTotEenHalfjaarTerug: Array<IOproep> | null = null;
  public oproepen: Record<number, IOproep> = {};

  public actieveOproepen: IRemoteData<Array<IOproep>> = createPendingRemoteData();

  public telefoonoproepenOphaalQueue: number[] = [];
  // tslint:disable-next-line:variable-name
  private _telOprIDQueueTimeout: number | null = null;

  // tslint:disable-next-line:variable-name
  private _rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this._rootStore = rootStore;

    makeObservable(this, {
      oproepenTotEenHalfjaarTerug: observable,
      actieveOproepen: observable,
      oproepen: observable,
    });
  }

  public ophalenOproepenTotEenHalfjaarTerug = async (filterschema?: IFilterSchema) => {
    const result = await api.v2.telefonie.ophalenTelefoonOproepen({
      filterSchema: {
        filters: [
          {
            naam: 'DATUM_VAN',
            data: addDays(new Date(), -180),
          },
          ...(filterschema?.filters ?? []),
        ],
      },
    });

    this.oproepenTotEenHalfjaarTerug = result.oproepen;
  };

  public ophalenActieveOproepen = async () => {
    const result = await api.v2.telefonie.ophalenTelefoonOproepen({
      filterSchema: {
        filters: [
          {
            naam: 'IS_BEEINDIGD',
            data: false,
          },
          {
            naam: 'HEEFT_EVENTS',
            data: true,
          },
          // {
          //   naam: 'DATUM_VAN',
          //   data: addDays(new Date(), -2),
          // },
        ],
      },
    });

    this.actieveOproepen = createReadyRemoteData(result.oproepen);
  };

  public enqueueTelefoonoproepOphalen = async (telOprID: number) => {
    this.telefoonoproepenOphaalQueue = [...this.telefoonoproepenOphaalQueue, telOprID];
    if (this._telOprIDQueueTimeout !== null) {
      clearTimeout(this._telOprIDQueueTimeout);
    }
    // @ts-ignore
    this._telOprIDQueueTimeout = setTimeout(this.telefoonoproepenOphalen, 300);
  };

  private telefoonoproepenOphalen = async () => {
    const queue = this.telefoonoproepenOphaalQueue;
    if (queue.length === 0) {
      return;
    }

    const result = await api.v2.telefonie.ophalenTelefoonOproepen({
      filterSchema: { filters: [{ naam: 'IDS', data: [queue] }] },
    });

    this.oproepen = result.oproepen.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.ID]: curr,
      }),
      this.oproepen,
    );
    this.telefoonoproepenOphaalQueue = [];
  };

  public ophalenOproepenVanPersonen = async (persIDs: number[]) => {
    const result = await api.v2.telefonie.ophalenTelefoonOproepenVanPersonen({
      persIDs,
    });
    if (this.oproepenTotEenHalfjaarTerug === null) {
      this.oproepenTotEenHalfjaarTerug = result.oproepen;
    } else {
      this.oproepenTotEenHalfjaarTerug = _.uniqBy(
        [...this.oproepenTotEenHalfjaarTerug, ...result.oproepen],
        'ID',
      );
    }
  };

  public handleTelefoonOproepGewijzigd = async (data: IWijzigenTelefoonOproepResult) => {
    if (this.actieveOproepen.state === ERemoteDataState.Ready) {
      await this.ophalenActieveOproepen();
      // this.actieveOproepen.data = this.actieveOproepen.data!.map((opr) => {
      //   if (opr.ID === data.oproep.ID) {
      //     return data.oproep;
      //   }
      //
      //   return opr;
      // });
    }
    if (this.oproepenTotEenHalfjaarTerug === null) {
      return;
    }

    this.oproepenTotEenHalfjaarTerug = this.oproepenTotEenHalfjaarTerug.map((opr) => {
      if (opr.ID === data.oproep.ID) {
        return data.oproep;
      }

      return opr;
    });
  };

  public handleTelefoonOproep = async (data: IVerzoekOproepResult) => {
    if (this.oproepenTotEenHalfjaarTerug === null) {
      return;
    }
    if (data.err !== undefined) {
      return;
    }

    this.oproepenTotEenHalfjaarTerug = [data.data!.oproep, ...this.oproepenTotEenHalfjaarTerug];
  };
}

export default TelefoonStore;
