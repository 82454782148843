import React, { useCallback, useContext, useState, useMemo, useEffect } from 'react';
import { ModalFooter, ModalTitle } from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/esm/ModalHeader';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IOphalenOpdrachtenResultElement as IOphalenBankopdrachtenResultElement } from '../../../../../shared/src/api/v2/bank/opdracht/opdracht';
import { IOphalenBoekingRegelsResultElement } from '../../../../../shared/src/api/v2/boekhouding/boeking';
import { IOphalenBetalingsregelingenResultElement } from '../../../../../shared/src/api/v2/debiteur/betalingsregeling';
import api from '../../../api';
import IDialoogProps from '../../../core/IDialoogProps';
import Dialoog from '../../dialogen/Dialoog';
import LoadingSpinner from '../../Gedeeld/LoadingSpinner';
import Tabblad, { ITabblad } from '../../layout/Tabblad';
import TechnischeInformatieKnop from '../../TechnischeInformatieKnop';
import BankopdrachtTab from './BankopdrachtTab';
import BetalingsregelingTab from './BetalingsregelingTab';
import BoekingTab from './BoekingTab';

interface IProps extends RouteComponentProps, IDialoogProps<{}> {
  id: number;
}

export interface IContext {
  betalingsregeling: IOphalenBetalingsregelingenResultElement | null;
  boekingregelsVoorRegeling: IOphalenBoekingRegelsResultElement[] | null;
  bankopdrachten: IOphalenBankopdrachtenResultElement[] | null;
}

export const BetalingsregelingContext = React.createContext<IContext>({
  betalingsregeling: null,
  boekingregelsVoorRegeling: null,
  bankopdrachten: null,
});

enum ETablad {
  Betalingsregeling,
  Bankopdrachten,
  Boekingen,
}

const BetalingsregelingInfoDialoog: React.FC<IProps> = (props) => {
  const { dialoogIndex, onAnnuleren, onSuccess, open } = props;

  const [
    betalingsregeling,
    setBetalingsregeling,
  ] = useState<IOphalenBetalingsregelingenResultElement | null>(null);

  const ophalenBetalingsregeling = useCallback(async () => {
    const result = (
      await api.v2.debiteur.betalingsregeling.ophalenBetalingsregelingen({
        filterSchema: {
          filters: [
            {
              naam: 'IDS',
              data: [props.id],
            },
          ],
        },
      })
    ).betalingsregelingen[0];

    setBetalingsregeling(result);
  }, [props.id]);

  useEffect(() => {
    ophalenBetalingsregeling();
  }, [ophalenBetalingsregeling]);

  const [boekingregels, setBoekingregels] = useState<IOphalenBoekingRegelsResultElement[] | null>(
    null,
  );
  const [bankopdrachten, setBankopdrachten] = useState<
    IOphalenBankopdrachtenResultElement[] | null
  >(null);

  const ophalenBankopdrachten = useCallback(async () => {
    if (betalingsregeling === null) {
      return;
    }

    const result = (
      await api.v2.bank.opdracht.ophalenOpdrachten({
        filterSchema: {
          filters: [
            {
              naam: 'BETRGL_IDS',
              data: [betalingsregeling.ID],
            },
          ],
        },
        orderSchema: { orders: [{ naam: 'UITVOERDATUM', richting: 'ASC' }] },
      })
    ).opdrachten;

    setBankopdrachten(result);
  }, [betalingsregeling]);

  useEffect(() => {
    ophalenBankopdrachten();
  }, [ophalenBankopdrachten]);

  const ophalenBoekingregels = useCallback(async () => {
    if (betalingsregeling === null) {
      return;
    }

    const regelsResult = (
      await api.v2.boeking.ophalenBoekingregels({
        filterSchema: {
          filters: [
            // {
            //   naam: 'REL_IDS',
            //   data: [betalingsregeling!.RelID],
            // },
            { naam: 'BETRGL_IDS', data: [props.id] },
          ],
        },
      })
    ).regels;

    setBoekingregels(regelsResult);
  }, [props.id, betalingsregeling]);

  useEffect(() => {
    ophalenBoekingregels();
  }, [ophalenBoekingregels]);

  const [tabblad, setTabblad] = useState<ETablad>(ETablad.Betalingsregeling);

  const tabbladen = useMemo<ITabblad<ETablad>[]>(
    () => [
      {
        id: ETablad.Betalingsregeling,
        label: 'Betalingsregeling',
        content: BetalingsregelingTab,
      },
      {
        id: ETablad.Bankopdrachten,
        label: 'Bankopdrachten',
        content: BankopdrachtTab,
      },
      {
        id: ETablad.Boekingen,
        label: 'Boekingen',
        content: BoekingTab,
      },
    ],
    [],
  );

  if (betalingsregeling === null || boekingregels === null || bankopdrachten === null) {
    return (
      <div className="d-flex flex-fill align-items-center justify-content-center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <Dialoog index={props.dialoogIndex ?? 0} modalProps={{ size: 'lg' }}>
      <ModalHeader>
        <ModalTitle>Betalingsregeling info</ModalTitle>
      </ModalHeader>
      <BetalingsregelingContext.Provider
        value={{ betalingsregeling, boekingregelsVoorRegeling: boekingregels, bankopdrachten }}
      >
        <div className="d-flex flex-column flex-fill" style={{ minHeight: `550px` }}>
          <>
            <div className="d-flex flex-column flex-fill">
              <Tabblad
                tabbladen={tabbladen}
                geselecteerd={tabblad}
                onSelectieChange={(id) => {
                  setTabblad(id);
                }}
              />
            </div>
            <ModalFooter className="d-flex flex-row justify-content-start">
              <button className="btn btn-primary" onClick={onAnnuleren} style={{ width: 100 }}>
                Sluiten
              </button>
              <div className="flex-fill" />
              <TechnischeInformatieKnop
                dialoogIndex={(props.dialoogIndex ?? 0) + 1}
                content={() => <div>BetRglID: {props.id}</div>}
              />
            </ModalFooter>
          </>
        </div>
      </BetalingsregelingContext.Provider>
    </Dialoog>
  );
};

export default withRouter(BetalingsregelingInfoDialoog);
