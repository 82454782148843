import api from '../../index';
import {
  IBerekenHuurprolongatieParams,
  IBerekenHuurprolongatieResult,
  IVerwijderenProlongatieRunsParams,
  IVerwijderenProlongatieRunsResult,
  IMakenProlongatiesParams,
  IMakenProlongatiesResult,
  IDefinitiefMakenProlongatiesParams,
  IDefinitiefMakenProlongatiesResult,
  IVerwijderenProlongatiesParams,
  IVerwijderenProlongatiesResult,
  IVerwerkenVoorstelProlongatiesParams,
  IVerwerkenVoorstelProlongatiesResult,
  IOphalenProlongatieRunsParamsV2,
  IOphalenProlongatieRunsResultV2,
  IOphalenProlongatieContractVorderingenParams,
  IOphalenProlongatieRunAfgeleideDatasParams,
  IOphalenProlongatieRunAfgeleideDatasResult,
  IOphalenProlongatieContractenResult,
  IOphalenProlongatieContractVorderingenResult,
  IWijzigenProlongatieRunParams,
  IWijzigenProlongatieRunResult,
  IOphalenProlongatieContractAfgeleideDatasParams,
  IOphalenProlongatieContractAfgeleideDatasResult,
  IOphalenProlongatieContractenParams,
} from '../../../../../shared/src/api/v2/prolongatie';
import ICheckData from '../../../../../shared/src/models/ICheckData';
import { IAchtergrondProces } from '../../../../../shared/src/api/v2/achtergrondProces';

export const prolongatie = {
  berekenHuurprolongatie: async (
    params: IBerekenHuurprolongatieParams,
  ): Promise<IBerekenHuurprolongatieResult> => {
    return await api.post('v2/prolongatie/bereken-huurprolongatie', params);
  },
  ophalenProlongatieRunsV2: async (
    params: IOphalenProlongatieRunsParamsV2,
  ): Promise<IOphalenProlongatieRunsResultV2> => {
    return await api.post('v2/prolongatie/ophalen-prolongatie-runs-v2', params);
  },
  ophalenProlongatieRunAfgeleideDatas: async (
    params: IOphalenProlongatieRunAfgeleideDatasParams,
  ): Promise<IOphalenProlongatieRunAfgeleideDatasResult> => {
    return await api.post('v2/prolongatie/ophalen-prolongatie-run-afgeleide-datas', params);
  },
  ophalenProlongatieContracten: async (
    params: IOphalenProlongatieContractenParams,
  ): Promise<IOphalenProlongatieContractenResult> => {
    return await api.post('v2/prolongatie/ophalen-prolongatie-contracten', params);
  },
  ophalenProlongatieContractAfgeleideDatas: async (
    params: IOphalenProlongatieContractAfgeleideDatasParams,
  ): Promise<IOphalenProlongatieContractAfgeleideDatasResult> => {
    return await api.post('v2/prolongatie/ophalen-prolongatie-contract-afgeleide-datas', params);
  },
  ophalenProlongatieContractVorderingen: async (
    params: IOphalenProlongatieContractVorderingenParams,
  ): Promise<IOphalenProlongatieContractVorderingenResult> => {
    return await api.post('v2/prolongatie/ophalen-prolongatie-contract-vorderingen', params);
  },
  checkVerwijderenProlongatieRuns: async (
    params: IVerwijderenProlongatieRunsParams,
  ): Promise<ICheckData> => {
    return await api.post('v2/prolongatie/check-verwijderen-prolongatie-runs', params);
  },
  verwijderenProlongatieRuns: async (
    params: IVerwijderenProlongatieRunsParams,
  ): Promise<IVerwijderenProlongatieRunsResult> => {
    return await api.post('v2/prolongatie/verwijderen-prolongatie-runs', params);
  },
  checkVerwijderenProlongaties: async (
    params: IVerwijderenProlongatiesParams,
  ): Promise<ICheckData> => {
    return await api.post('v2/prolongatie/check-verwijderen-prolongaties', params);
  },
  verwijderenProlongaties: async (
    params: IVerwijderenProlongatiesParams,
  ): Promise<IVerwijderenProlongatiesResult> => {
    return await api.post('v2/prolongatie/verwijderen-prolongaties', params);
  },
  makenProlongatieRun: async (
    params: IMakenProlongatiesParams,
  ): Promise<IMakenProlongatiesResult> => {
    return await api.post('v2/prolongatie/maken-prolongatie-run', params);
  },
  makenProlongatieRunAchtergrondProces: async (
    params: IMakenProlongatiesParams,
  ): Promise<IAchtergrondProces<IMakenProlongatiesResult>> => {
    return await api.post('v2/prolongatie/maken-prolongatie-run-achtergrond-proces', params);
  },
  checkDefinitiefMakenProlongaties: async (
    params: IDefinitiefMakenProlongatiesParams,
  ): Promise<ICheckData> => {
    return await api.post('v2/prolongatie/check-definitief-maken-prolongaties', params);
  },
  definitiefMakenProlongaties: async (
    params: IDefinitiefMakenProlongatiesParams,
  ): Promise<IDefinitiefMakenProlongatiesResult> => {
    return await api.post('v2/prolongatie/definitief-maken-prolongaties', params);
  },
  // ophalenProlongaties: async (
  //   params: IOphalenProlongatiesParams,
  // ): Promise<IOphalenProlongatiesResult> => {
  //   return await api.post('v2/prolongatie/ophalen-prolongaties', params);
  // },
  checkVerwerkenVoorstelProlongaties: async (
    params: IVerwerkenVoorstelProlongatiesParams,
  ): Promise<ICheckData> => {
    return await api.post('v2/prolongatie/check-verwerken-voorstel-prolongaties', params);
  },
  verwerkenVoorstelProlongaties: async (
    params: IVerwerkenVoorstelProlongatiesParams,
  ): Promise<IVerwerkenVoorstelProlongatiesResult> => {
    return await api.post('v2/prolongatie/verwerken-voorstel-prolongaties', params);
  },
  verwerkenVoorstelProlongatiesAchtergrondProces: async (
    params: IVerwerkenVoorstelProlongatiesParams,
  ): Promise<IAchtergrondProces<IVerwerkenVoorstelProlongatiesResult>> => {
    return await api.post(
      'v2/prolongatie/verwerken-voorstel-prolongaties-achtergrond-proces',
      params,
    );
  },
  wijzigenProlongatieRun: async (
    params: IWijzigenProlongatieRunParams,
  ): Promise<IWijzigenProlongatieRunResult> => {
    return await api.post('v2/prolongatie/wijzigen-prolongatie-run', params);
  },
};

export default prolongatie;
