import {
  ICheckOpenDialoogToevoegenEscalatieParams,
  IOphalenEscalatiecontextenParams,
  IOphalenEscalatiecontextenResult,
  IOphalenEscalatiesParams,
  IOphalenEscalatiesResult,
  IToevoegenEscalatieParams,
  IToevoegenEscalatieResult,
  IVerwijderenEscalatiesParams,
  IVerwijderenEscalatiesResult,
  IWijzigenEscalatieParams,
  IWijzigenEscalatieResult,
} from '../../../../../shared/src/api/v2/escalatie';
import api from '../../index';
import ICheckData from '../../../../../shared/src/models/ICheckData';

const escalatie = {
  ophalenEscalaties: async (
    params: IOphalenEscalatiesParams,
  ): Promise<IOphalenEscalatiesResult> => {
    return await api.post('/v2/escalatie/ophalen-escalaties', params);
  },
  ophalenEscalatiecontexten: async (
    params: IOphalenEscalatiecontextenParams,
  ): Promise<IOphalenEscalatiecontextenResult> => {
    return await api.post('/v2/escalatie/ophalen-escalatie-contexten', params);
  },
  checkOpenDialoogToevoegenEscalatie: async (
    params: ICheckOpenDialoogToevoegenEscalatieParams,
  ): Promise<ICheckData> => {
    return await api.post('/v2/escalatie/check-open-dialoog-toevoegen-escalatie', params);
  },
  checkToevoegenEscalatie: async (params: IToevoegenEscalatieParams): Promise<ICheckData> => {
    return await api.post('/v2/escalatie/check-toevoegen-escalatie', params);
  },
  toevoegenEscalatie: async (
    params: IToevoegenEscalatieParams,
  ): Promise<IToevoegenEscalatieResult> => {
    return await api.post('/v2/escalatie/toevoegen-escalatie', params);
  },
  checkWijzigenEscalatie: async (params: IWijzigenEscalatieParams): Promise<ICheckData> => {
    return await api.post('/v2/escalatie/check-wijzigen-escalatie', params);
  },
  wijzigenEscalatie: async (
    params: IWijzigenEscalatieParams,
  ): Promise<IWijzigenEscalatieResult> => {
    return await api.post('/v2/escalatie/wijzigen-escalatie', params);
  },
  checkVerwijderenEscalaties: async (params: IVerwijderenEscalatiesParams): Promise<ICheckData> => {
    return await api.post('/v2/escalatie/check-verwijderen-escalaties', params);
  },
  verwijderenEscalaties: async (
    params: IVerwijderenEscalatiesParams,
  ): Promise<IVerwijderenEscalatiesResult> => {
    return await api.post('/v2/escalatie/verwijderen-escalaties', params);
  },
};

export default escalatie;
