import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Klant from '../paginas/Klant';
import Transport from '../paginas/Transport';
import NietGevonden from '../paginas/NietGevonden';
import Communicatie from '../paginas/Communicatie';
import Boekhouding from '../paginas/Boekhouding';
import Takenplanner from '../paginas/Takenplanner';
import Inkoop from '../paginas/Inkoop';
import Dashboard from '../paginas/Dashboard';
import Facturering from '../paginas/Facturering';
import Debiteuren from '../paginas/Debiteuren';
import Crediteuren from '../paginas/Crediteuren';
import Service from '../paginas/Service';
import Beheer from '../paginas/Beheer';
import Magazijn from '../paginas/Magazijn';
import Sponsoring from '../paginas/Sponsoring';
import Sponsoringv2 from '../paginas/Sponsoringv2';
import Leverancier from '../paginas/Leverancier';
import Opvolging from '../paginas/Opvolging';
import Website from '../paginas/Website';
import Bedrijfsinfo from '../paginas/Bedrijfsinfo';
import Monitoring from '../paginas/Monitoring';
import Diensten from '../paginas/Diensten';
import Bankzaken from '../paginas/Bankzaken';
import Taken from '../paginas/Taken';
import Verlof from '../paginas/Personeelszaken';
import ASPGebruikers from '../paginas/ASPGebruikers';
import Applicatie from '../paginas/Applicatie';
import ASPDrive from '../paginas/ASPDrive';
import ExterneData from '../paginas/ExterneData';
import Facilitair from '../paginas/Facilitair';
import Voorraad from '../paginas/Voorraad';
import Kwaliteitsbeheer from '../paginas/Kwaliteitsbeheer';
import Backoffice from '../paginas/Backoffice';
import Reviews from '../paginas/Reviews';
import Signaleringen from '../paginas/Signaleringen';
import Medewerkers from '../paginas/Personeelszaken/Medewerkers';
import Stambeheer from '../paginas/Stambeheer';
import Indexering from '../paginas/Indexering';
import Aanbod from '../paginas/Aanbod';
import Escalaties from '../paginas/Escalaties';
import Concurrentie from '../paginas/Concurrentie';

const AppRouter = () => (
  <Switch>
    <Route component={Indexering} path="/indexering" />
    <Route component={Kwaliteitsbeheer} path="/kwaliteitsbeheer" />
    <Route component={Voorraad} path="/voorraad" />
    <Route component={Facilitair} path="/facilitair" />
    <Route component={Monitoring} path="/monitoring" />
    <Route component={Opvolging} path="/opvolging" />
    <Route component={ExterneData} path="/externe-data" />
    <Route component={Diensten} path="/diensten" />
    <Route component={Crediteuren} path="/crediteuren" />
    <Route component={Debiteuren} path="/debiteuren" />
    <Route component={Facturering} path="/facturering" />
    <Route component={Inkoop} path="/inkoop" />
    <Route component={Takenplanner} path="/takenplanner" />
    <Route component={ASPDrive} path="/asp-drive" />
    <Route component={Aanbod} path="/aanbod" />
    <Route component={Communicatie} path="/communicatie" />
    <Route component={Boekhouding} path="/boekhouding" />
    <Route component={Klant} path="/klant/:relID?" />
    <Route component={Transport} path="/transport" />
    <Route component={Dashboard} exact path="/dashboard" />
    <Route component={Service} path="/service" />
    <Route component={Beheer} path="/beheer" />
    <Route component={Magazijn} path="/magazijn" />
    <Route component={Sponsoring} path="/sponsoring" />
    <Route component={Concurrentie} path="/concurrentie" />
    <Route component={Sponsoringv2} path="/sponsoringv2" />
    <Route component={Leverancier} path="/leverancier/:relID?" />
    <Route component={Website} path="/website" />
    <Route component={Bedrijfsinfo} path="/bedrijfsinfo" />
    <Route component={Bankzaken} path="/bankzaken" />
    <Route component={Taken} path="/taken" />
    <Route component={Verlof} path="/verlof" />
    <Route component={Medewerkers} path="/medewerkers" />
    <Route component={ASPGebruikers} path="/asp-gebruikers" />
    <Route component={Applicatie} path="/applicatie" />
    <Route component={Backoffice} path="/backoffice" />
    <Route component={Reviews} path="/reviews" />
    <Route component={Signaleringen} path="/signaleringen" />
    <Route component={Stambeheer} path="/stambeheer" />
    <Route component={Escalaties} path="/escalaties" />

    <Route component={() => <Redirect to="/dashboard" />} path="/" exact />

    <Route component={NietGevonden} path="/" />
  </Switch>
);

export default AppRouter;
