import api from '../../../index';
import {
  IOphalenGeneriekParams,
  IToevoegenGeneriekResult,
  ILeegGeneriekResult,
  ISelectieGeneriekParams,
} from '../../../../../../shared/src/api/v2/generiek';
import {
  ISponsorEvent,
  ISponsorEventPersoon,
  ISponsorEventPlaats,
  IVerwijderenEventsParams,
  IVerwijderenEventsResult,
} from '../../../../../../shared/src/api/v2/sponsoringv2/event';
import ICheckData from '../../../../../../shared/src/models/ICheckData';

const event = {
  ophalenEvents: async (params: IOphalenGeneriekParams): Promise<ISponsorEvent[]> => {
    return await api.post('/v2/sponsoringv2/event/ophalen-events', params);
  },
  toevoegenOfWijzigenEvent: async (params: ISponsorEvent): Promise<IToevoegenGeneriekResult> => {
    return await api.post('/v2/sponsoringv2/event/toevoegen-of-wijzigen-event', params);
  },
  checkVerwijderenEvents: async (params: IVerwijderenEventsParams): Promise<ICheckData> => {
    return await api.post('/v2/sponsoringv2/event/check-verwijderen-events', params);
  },
  verwijderenEvents: async (
    params: IVerwijderenEventsParams,
  ): Promise<IVerwijderenEventsResult> => {
    return await api.post('/v2/sponsoringv2/event/verwijderen-events', params);
  },

  // koppelen personen

  ophalenPersonen: async (params: IOphalenGeneriekParams): Promise<ISponsorEventPersoon[]> => {
    return await api.post('/v2/sponsoringv2/event/ophalen-personen', params);
  },
  toevoegenPersoon: async (params: ISponsorEventPersoon): Promise<ILeegGeneriekResult> => {
    return await api.post('/v2/sponsoringv2/event/toevoegen-persoon', params);
  },
  verwijderenPersonen: async (params: ISelectieGeneriekParams): Promise<ILeegGeneriekResult> => {
    return await api.post('/v2/sponsoringv2/event/verwijderen-personen', params);
  },

  // koppelen plaatsen

  ophalenPlaatsen: async (params: IOphalenGeneriekParams): Promise<ISponsorEventPlaats[]> => {
    return await api.post('/v2/sponsoringv2/event/ophalen-plaatsen', params);
  },
  toevoegenPlaats: async (params: ISponsorEventPlaats): Promise<ILeegGeneriekResult> => {
    return await api.post('/v2/sponsoringv2/event/toevoegen-plaats', params);
  },
  verwijderenPlaatsen: async (params: ISelectieGeneriekParams): Promise<ILeegGeneriekResult> => {
    return await api.post('/v2/sponsoringv2/event/verwijderen-plaatsen', params);
  },
};

export default event;
