import styled from 'styled-components';

export const Root = styled.table`
  //tr > td:nth-child(1) {
  //  padding-left: 10px;
  //}

  td {
    padding: 0;
  }
`;
