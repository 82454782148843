import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Helmet } from 'react-helmet';
import MenuLayout from '../../../../components/MenuLayout';
import { Kleur as EKleur, Kleur } from '../../../../bedrijfslogica/constanten';
import { GlobaleRendererContext } from '../../../../one-off-components/GlobaleRenderer';
import BestandenDragAndDropDialoog, {
  IBestandenDragAndDropDialoogResult,
} from '../../../../components/BestandenDragAndDropDialoog';
import api from '../../../../api';
import { EBestandDragAndDropZoneSelectieModus } from '../../../../components/BestandDragAndDropZone';
import { IFilterSchema, IFilterSchemaFilter } from '../../../../../../shared/src/models/filter';
import FilterBalkV2, {
  IFilter,
  IFilterData,
  maakFilterSchema,
} from '../../../../components/FilterBalkV2';
import useUrlState from '../../../../core/useUrlState';
import { IOphalenFacturenResultElement } from '../../../../../../shared/src/api/v2/inkoopfactuur';
import _ from 'lodash';
import {
  DXCommandComponent,
  DXTableCheckboxComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import { AutoSizer } from 'react-virtualized';
import {
  Grid,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  DataTypeProvider,
  EditingState,
  IntegratedSorting,
  RowDetailState,
  SelectionState,
  SortingState,
  VirtualTable as VirtualTableBase,
} from '@devexpress/dx-react-grid';
import RelatieVisualisatie from '../../../../components/personalia/RelatieVisualisatie';
import FormatteerBedrag from '../../../../components/MutatieBedrag';
import { EHoedanigheid } from '../../../../components/personalia/RelatieSelectieDialoog';
import { format } from 'date-fns';
import { IconAttachment, IconUitvergroten } from '../../../../components/Icons';
import DownloadKnop from '../../../../components/DownloadKnop';
import downloadUrl from '../../../../core/downloadUrl';
import WijzigenFactuurDialoog from '../../../../components/inkoopfactuur/WijzigenFactuurDialoog';
import { IOphalenGekoppeldeTransportopdrachtenResultElement } from '../../../../../../shared/src/api/v2/inkoopfactuur/transport';
import { IOphalenRelatiesResultElementV2 } from '../../../../../../shared/src/api/v2/relatie';
import { formatteerBedrag } from '../../../../helpers';
import { mediaTypeNaarMediaWeergaveType } from '../../../../components/MediaWeergave';
import MediaWeergaveDialoog from '../../../../components/dialogen/MediaWeergaveDialoog';

interface IProps extends RouteComponentProps {}

enum EFilter {
  TransportGekoppeld = 'TRANSPORTOPDRACHTEN_GEKOPPELD',
}

export interface IWijzigenFactuurDialoogState {
  inkFactID: number;
}

export interface IBestandenUitvergrotenDialoogState {
  inkFactID: number;
}

export interface IUrlState {
  selectie: number[];
  uitgeklapt: number[];
  // koppelProductenDialoogState: IKoppelProductenDialoogState | null;
  wijzigenFactuurDialoogState: IWijzigenFactuurDialoogState | null;
  bestandenUitvergrotenDialoogState: IBestandenUitvergrotenDialoogState | null;
  filterData: IFilterData<EFilter>[];
}

const defaultUrlState: IUrlState = {
  selectie: [],
  uitgeklapt: [],
  //   koppelProductenDialoogState: null,
  wijzigenFactuurDialoogState: null,
  bestandenUitvergrotenDialoogState: null,
  filterData: [
    {
      naam: EFilter.TransportGekoppeld,
      isActief: true,
      data: false,
    },
  ],
};

const geenData = {
  noData: 'Geen inkoopfacturen gevonden',
};

export interface IRow extends IOphalenFacturenResultElement {
  relatie: IOphalenRelatiesResultElementV2;
  gekoppeldeTransportopdrachten: IOphalenGekoppeldeTransportopdrachtenResultElement[];
}

const Transport: React.FC<IProps> = observer((props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);

  const globaleRenderer = useContext(GlobaleRendererContext);

  const [filterSchema, setFilterSchema] = useState<IFilterSchema>(
    maakFilterSchema(urlState.filterData),
  );

  const [facturen, setFacturen] = useState<IRow[] | null>(null);

  const ophalenFacturen = useCallback(async () => {
    const facturenResult = await api.v2.inkoopfactuur.ophalenFacturen({
      filterSchema: {
        filters: [
          ...(filterSchema.filters ?? []),
          {
            naam: 'IS_GEFIATTEERD',
            data: true,
          },
          {
            naam: 'KOPPELEN_TRANSPORTOPDRACHTEN',
            data: true,
          },
        ].filter((x) => x !== null) as IFilterSchemaFilter[],
      },
      orderSchema: { orders: [{ naam: 'FACTUURDATUM', richting: 'DESC' }] },
      paginatie: {
        index: 0,
        aantal: 500,
      },
    });

    const inkFactIDs = _.uniq(facturenResult.facturen.map((x) => x.InkFactID));

    // Ophalen relatie-gegevens
    const relIDs = _.uniq(facturenResult.facturen.map((x) => x.RelID));
    const relatiesResult = await api.v2.relatie.ophalenRelaties({
      filterSchema: { filters: [{ naam: 'IDS', data: relIDs }] },
    });

    // Gekoppelde transportopdrachten ophalen
    const gekoppeldeTransportopdrachtenResult = (
      await api.v2.inkoopfactuur.transport.ophalenGekoppeldeTransportopdrachten({
        filterSchema: { filters: [{ naam: 'INKFACT_IDS', data: inkFactIDs }] },
      })
    ).gekoppeldeTransportopdrachten;

    const facturen = facturenResult.facturen.map((x: any) => {
      const relatie = relatiesResult.relaties.find((r) => r.RelID === x.RelID)!;
      const gekoppeldeTransportopdrachten = gekoppeldeTransportopdrachtenResult.filter(
        (f) => f.InkFactID === x.InkFactID,
      );
      return { ...x, relatie, gekoppeldeTransportopdrachten };
    });

    setFacturen(facturen);
  }, [filterSchema]);

  useEffect(() => {
    ophalenFacturen();
  }, [ophalenFacturen]);

  const handleInlezen = useCallback(async () => {
    // TODO: Guido Hier selectie dialoog maken voor selectie van de relatie van de transportdienst

    // alert('TODO: Selectie relatie transportdienst');
    const relID = undefined;

    // Importeer het bestand

    const result = await globaleRenderer.render<IBestandenDragAndDropDialoogResult>(
      (renderProps) => (
        <BestandenDragAndDropDialoog
          open
          onSuccess={(r) => renderProps.destroy(r)}
          onAnnuleren={() => renderProps.destroy()}
          toegestaneBestandstypen={[
            {
              mediaType: 'text/csv',
              weergaveNaam: 'CSV',
            },
          ]}
          selectieModus={EBestandDragAndDropZoneSelectieModus.Enkel}
        />
      ),
    );
    if (result === undefined) {
      return;
    }

    await api.v2.inkoopfactuur.transport.inlezenTransportopdrachten({
      bestandID: result.bestanden[0].ID,
      relID,
    });
  }, []);

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.TransportGekoppeld,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return (
            <div className="d-flex align-items-center">
              <span>Alleen nog te koppelen facturen</span>
            </div>
          );
        },
      },
    ],
    [],
  );

  const keyExtractor = useCallback((row: IRow) => row.InkFactID, []);
  const kolommen = useMemo<TypedColumn<IRow>[]>(
    () => [
      {
        name: 'Factuurdatum',
        title: 'Fact.datum',
      },
      {
        name: 'Factuurnummer',
        title: 'Fact.nr.',
      },
      {
        name: 'Bedrag',
        title: 'Bedrag (incl.)',
      },
      {
        name: 'BedragBtw',
        title: 'Btw',
      },
      {
        name: '__BedragExclBtw' as any,
        title: 'Excl. btw',
      },
      {
        name: '__relatie' as any,
        title: 'Crediteur',
      },
      {
        name: 'Onderwerp',
        title: 'Onderwerp',
      },
      {
        name: '__heeftBestanden' as any,
        title: ' ',
      },
      // {
      //   name: '__koppelen' as any,
      //   title: ' ',
      // },
      //   {
      //     name: '__aantalGekoppeld' as any,
      //     title: '# Bonnen',
      //   },
      {
        name: 'TransportopdrachtenGekoppeld',
        title: 'Volledig gekop.',
      },
      {
        name: '__totaalGekoppeldBedrag' as any,
        title: 'Gekop. bedrag (ex.btw)',
      },
      {
        name: '__verschil' as any,
        title: 'Verschil (ex.btw)',
      },
    ],
    [],
  );
  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRow>[]>(
    () => [
      {
        columnName: 'Factuurdatum',
        width: 115,
      },
      {
        columnName: 'Factuurnummer',
        width: 125,
      },
      {
        columnName: '__relatie' as any,
        width: 135,
      },
      {
        columnName: 'Bedrag',
        width: 120,
      },
      {
        columnName: 'BedragBtw',
        width: 100,
      },
      {
        columnName: '__BedragExclBtw' as any,
        width: 110,
      },
      {
        columnName: 'Onderwerp',
        width: 300,
      },
      {
        columnName: '__heeftBestanden' as any,
        width: 80,
      },
      {
        columnName: '__koppelen',
        width: 90,
      },
      {
        columnName: '__aantalGekoppeld',
        width: 100,
      },
      {
        columnName: '__Geboekt100',
        width: 125,
      },
      {
        columnName: 'TransportopdrachtenGekoppeld',
        width: 135,
      },
      {
        columnName: '__nogKoppelen' as any,
        width: 125,
      },
      {
        columnName: '__totaalGekoppeldBedrag' as any,
        width: 200,
      },
      {
        columnName: '__verschil' as any,
        width: 185,
      },
    ],
    [],
  );

  const kolomExtensies: VirtualTableBase.ColumnExtension[] = useMemo(() => {
    return [
      // {
      //   columnName: `Bedrag`,
      //   align: 'right',
      // },
      // {
      //   columnName: `__nogKoppelen`,
      //   align: 'right',
      // },
    ];
  }, []);

  return (
    <>
      <Helmet>
        <title>Koppelen transportopdrachten</title>
      </Helmet>
      <MenuLayout
        menu={
          <div className="d-flex align-items-center">
            <button
              className="btn btn-sm btn-light d-flex align-items-center"
              style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
              disabled={urlState.selectie.length !== 0}
              onClick={() => handleInlezen()}
            >
              <span className="ml-2">Inlezen factuurbijlage bestand</span>
            </button>

            <div className="d-flex flex-fill ml-3">
              <FilterBalkV2
                filters={filters}
                filterData={urlState.filterData}
                onFilterDataChange={(x) => setUrlStateSync('filterData', x)}
                onFilterSchemaChange={(x) => setFilterSchema(x)}
              />
            </div>
          </div>
        }
        body={
          <>
            <div className="d-flex flex-column flex-fill">
              {facturen === null ? (
                <div className="d-flex flex-column flex-fill align-items-center justify-content-center">
                  <LoadingSpinner />
                </div>
              ) : (
                <AutoSizer style={{ flex: 1, height: '100%', width: '100%' }}>
                  {(size) => (
                    <GridStyleWrapper height={size.height}>
                      <Grid columns={kolommen} rows={facturen || []} getRowId={keyExtractor}>
                        {/* <DataTypeProvider
                          for={['__heeftBestanden']}
                          formatterComponent={(formatterProps) => {
                            const rij: IRow = formatterProps.row;
                            const heeftBestanden = rij.bestanden.length > 0 ? true : false;
                            return (
                              <span>
                                {heeftBestanden ? (
                                  <IconAttachment
                                    style={{ width: 20, height: 20, fill: Kleur.Grijs }}
                                  />
                                ) : null}
                              </span>
                            );
                          }}
                        /> */}

                        <DataTypeProvider
                          for={['Factuurdatum']}
                          formatterComponent={(formatterProps) => {
                            const rij: IRow = formatterProps.row;
                            const datum = format(new Date(rij.Factuurdatum), 'dd-MM-yyyy');
                            return <span>{datum}</span>;
                          }}
                        />

                        <DataTypeProvider
                          for={['__relatie']}
                          formatterComponent={(formatterProps) => {
                            const rij = formatterProps.row as IRow;
                            return (
                              <RelatieVisualisatie
                                relID={rij.RelID}
                                relatieLinkBuilder={(hoedanigheid, relID) =>
                                  `/${
                                    hoedanigheid === EHoedanigheid.Klant ? 'klant' : 'leverancier'
                                  }/${relID}/facturen/overzicht`
                                }
                              />
                            );
                          }}
                        />

                        <DataTypeProvider
                          for={['Bedrag', 'BedragBtw']}
                          formatterComponent={(formatterProps) => (
                            <FormatteerBedrag bedrag={formatterProps.value} />
                          )}
                        />

                        <DataTypeProvider
                          for={['__BedragExclBtw']}
                          formatterComponent={(formatterProps) => {
                            const rij = formatterProps.row as IRow;

                            const bedragExcl = rij.Bedrag - rij.BedragBtw;
                            return <FormatteerBedrag bedrag={bedragExcl} />;
                          }}
                        />

                        <DataTypeProvider
                          for={['__heeftBestanden']}
                          formatterComponent={(formatterProps) => {
                            const rij: IRow = formatterProps.row;
                            const heeftBestanden = rij.bestanden.length > 0;
                            return (
                              <>
                                {heeftBestanden && (
                                  <div className="d-flex align-items-center">
                                    <button
                                      style={{
                                        outline: 0,
                                        background: 0,
                                        border: 0,
                                        padding: 0,
                                        marginTop: 1,
                                      }}
                                      onClick={() => {
                                        setUrlStateSync('bestandenUitvergrotenDialoogState', {
                                          inkFactID: rij.InkFactID,
                                        });
                                      }}
                                    >
                                      <IconUitvergroten
                                        style={{ width: 20, height: 20, fill: Kleur.Blauw }}
                                      />
                                    </button>

                                    <DownloadKnop
                                      onDownloadAangevraagd={async () => {
                                        await downloadUrl(
                                          rij.bestanden[0].url,
                                          rij.bestanden[0].Naam,
                                        );
                                      }}
                                    />
                                  </div>
                                )}
                              </>
                            );
                          }}
                        />

                        <DataTypeProvider
                          for={['__totaalGekoppeldBedrag']}
                          formatterComponent={(formatterProps) => {
                            const rij: IRow = formatterProps.row;
                            const bedrag = _.sum(
                              rij.gekoppeldeTransportopdrachten.map((x) => x.BedragTeFactureren),
                            );
                            return <span>{formatteerBedrag(bedrag)}</span>;
                          }}
                        />

                        <DataTypeProvider
                          for={['__verschil']}
                          formatterComponent={(formatterProps) => {
                            const rij: IRow = formatterProps.row;

                            const bedragGekoppeld = _.sum(
                              rij.gekoppeldeTransportopdrachten.map((x) => x.BedragTeFactureren),
                            );

                            const verschil = Number(
                              (rij.Bedrag - rij.BedragBtw - bedragGekoppeld).toFixed(2),
                            );

                            return (
                              <span
                                style={{
                                  color:
                                    verschil === 0
                                      ? EKleur.Groen
                                      : verschil > 0
                                      ? EKleur.Rood
                                      : EKleur.Oranje,
                                }}
                              >
                                {formatteerBedrag(verschil)}
                              </span>
                            );
                          }}
                        />

                        <DataTypeProvider
                          for={['TransportopdrachtenGekoppeld']}
                          formatterComponent={(formatterProps) => {
                            return (
                              <span
                                style={{ color: formatterProps.value ? EKleur.Groen : EKleur.Rood }}
                              >
                                {formatterProps.value ? 'Ja' : 'Nee'}
                              </span>
                            );
                          }}
                        />

                        {/* <DataTypeProvider
                          for={['__aantalGekoppeld']}
                          formatterComponent={(formatterProps) => {
                            const rij = formatterProps.row as IRow;

                            const gekoppeldeProducten = werkbonnen.filter(
                              (x) => x.InkFactID === rij.InkFactID,
                            );

                            return <span>{gekoppeldeProducten.length}</span>;
                          }}
                        />



                        <DataTypeProvider
                          for={['__koppelen']}
                          formatterComponent={(formatterProps) => {
                            const rij = formatterProps.row as IRow;
                            return (
                              <>
                                <a
                                  href="#"
                                  style={{ color: EKleur.Blauw }}
                                  onClick={() =>
                                    setUrlStateSync('koppelProductenDialoogState', {
                                      inkFactID: rij.InkFactID,
                                    })
                                  }
                                >
                                  Koppelen
                                </a>
                              </>
                            );
                          }}
                        /> */}

                        <RowDetailState
                          expandedRowIds={urlState.uitgeklapt}
                          onExpandedRowIdsChange={(x) =>
                            setUrlStateSync('uitgeklapt', x as number[])
                          }
                        />
                        <SortingState defaultSorting={[]} />
                        <IntegratedSorting />

                        <VirtualTable
                          // estimatedRowHeight={43}
                          columnExtensions={kolomExtensies}
                          messages={geenData}
                        />

                        <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
                        <TableHeaderRow showSortingControls />

                        <EditingState
                          onAddedRowsChange={() => {}}
                          onEditingRowIdsChange={(x) => {
                            const id = x[x.length - 1] as number;
                            setUrlStateSync('wijzigenFactuurDialoogState', { inkFactID: id });
                          }}
                          onCommitChanges={() => null}
                        />
                        <TableEditColumn
                          width={35}
                          // showAddCommand={true}
                          showEditCommand
                          commandComponent={DXCommandComponent}
                        />

                        {/* <TableRowDetail
                          contentComponent={RowDetailComp}
                          toggleCellComponent={DXTableToggleCellComponent}
                        /> */}

                        <SelectionState
                          selection={urlState.selectie}
                          onSelectionChange={(x) => setUrlStateSync('selectie', x as number[])}
                        />
                        <TableSelection cellComponent={DXTableCheckboxComponent} />
                      </Grid>
                    </GridStyleWrapper>
                  )}
                </AutoSizer>
              )}

              {/* {urlState.koppelProductenDialoogState !== null && (
                <KoppelWerkbonnenDialoog
                  open
                  inkFactID={urlState.koppelProductenDialoogState.inkFactID}
                  onSuccess={() => {}}
                  onAnnuleren={() => {
                    setUrlStateSync('koppelProductenDialoogState', null);
                    ophalenFacturen();
                  }}
                />
              )} */}
              {urlState.wijzigenFactuurDialoogState !== null && (
                <WijzigenFactuurDialoog
                  open
                  inkFactID={urlState.wijzigenFactuurDialoogState.inkFactID}
                  onSuccess={() => {
                    setUrlStateSync('wijzigenFactuurDialoogState', null);
                    ophalenFacturen();
                  }}
                  onAnnuleren={() => setUrlStateSync('wijzigenFactuurDialoogState', null)}
                />
              )}
              {urlState.bestandenUitvergrotenDialoogState !== null &&
                facturen !== null &&
                (() => {
                  const factuur = facturen.find(
                    (x) => x.InkFactID === urlState.bestandenUitvergrotenDialoogState!.inkFactID,
                  )!;
                  const mediaWeergaven = factuur.bestanden.map((x) => ({
                    id: x.ID,
                    title: x.Naam,
                    src: x.url,
                    type: mediaTypeNaarMediaWeergaveType(x.MediaType)!,
                    mediaType: x.MediaType,
                  }));

                  return (
                    <MediaWeergaveDialoog
                      mediaWeergaven={mediaWeergaven}
                      defaultCurrent={mediaWeergaven[0].id}
                      open
                      onSuccess={() => setUrlStateSync('bestandenUitvergrotenDialoogState', null)}
                      onAnnuleren={() => setUrlStateSync('bestandenUitvergrotenDialoogState', null)}
                    />
                  );
                })()}
            </div>
          </>
        }
      />
    </>
  );
});

export default Transport;
