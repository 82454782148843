import React, { useMemo } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { genereerUrlStateQueryParam } from '../../../core/useUrlState';
import { IUrlState } from '../../Communicatie/Contactverzoek';
import { IActieProps } from '../index';
import { ITerugbelverzoekUrlState } from '../../Communicatie/Terugbelverzoek';

interface IData {
  id: number;
}

const TERUGBELVERZOEK_OPVOLGEN: React.VoidFunctionComponent<IActieProps> = (props: IActieProps) => {
  const data = useMemo(() => JSON.parse(props.controltaak.Data!) as IData, [
    props.controltaak.Data,
  ]);

  const pad = useMemo(() => {
    const state = genereerUrlStateQueryParam<ITerugbelverzoekUrlState>({
      focusRijID: data.id,
      selectie: [],
    });
    return `/communicatie/terugbelverzoek?state=${state}`;
  }, [data.id]);

  return (
    <a
      href={pad}
      onClick={(ev) => {
        ev.preventDefault();
        props.history.push(pad);
      }}
    >
      Naar het terugbelverzoek
    </a>
  );
};

export default TERUGBELVERZOEK_OPVOLGEN;
