import React, { useContext, useMemo } from 'react';
import { IContext, IRegel, ITabbladProps, ProductContext } from '../index';
import VeldWeergave from '../../../formulier/VeldWeergave';
import LoadingSpinner from '../../../Gedeeld/LoadingSpinner';
import { format } from 'date-fns';
import GegevensLayout from '../../../layout/GegevensLayout';
import { maandenNaarJaren } from '../../../../bedrijfslogica/teksten';
import { IOphalenMeldingenResultElement } from '../../../../../../shared/src/api/v2/service/melding';
import { EContractstatus, EProductstatus } from '../../../../bedrijfslogica/enums';
import { Kleur as EKleur } from '../../../../bedrijfslogica/constanten';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import ContractVisualisatie from '../../../entiteitVisualisaties/ContractVisualisatie';
import { genereerUrlStateQueryParam } from '../../../../core/useUrlState';
import { IOphalenContractenResultElementV2 } from '../../../../../../shared/src/api/v2/contract';
import {
  ERetourenFilter,
  EWeergave,
  IRetourenUrlState,
} from '../../../../paginas/Magazijn/Depot/Retouren';
import {
  EFilter as EVoorraadFilter,
  IUrlState as IVoorraadUrlState,
} from '../../../../paginas/Magazijn/Depot/Voorraad';
import {
  EFilter as ERevisieFilter,
  IUrlState as IRevisieUrlState,
} from '../../../../paginas/Magazijn/Depot/Revisie';
import {
  EFilter as EAfvoerFilter,
  IUrlState as IAfvoerUrlState,
} from '../../../../paginas/Magazijn/Depot/Afvoer';
import {
  EFilter as ERetourLeverancierFilter,
  IUrlState as IRetourLeverancierUrlState,
} from '../../../../paginas/Magazijn/Depot/RetourLeverancier';
import {
  EFilter as EUitBedrijfFilter,
  IUrlState as IUitBedrijfUrlState,
} from '../../../../paginas/Magazijn/Depot/UitBedrijf';
import {
  EFilter as EGeparkeerdFilter,
  IUrlState as IGeparkeerdUrlState,
} from '../../../../paginas/Magazijn/Depot/Geparkeerd';
import TransportopdrachtregelVisualisatie from '../../../entiteitVisualisaties/TransportopdrachtregelVisualisatie';
import {
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import { Grid, TableHeaderRow, VirtualTable } from '@devexpress/dx-react-grid-bootstrap4';
import { IOphalenMutatiesReferentiecodeResultElement } from '../../../../../../shared/src/api/v2/product';
import { IOphalenMagazijnenResultElement } from '../../../../../../shared/src/api/v2/magazijn';

interface IProps extends ITabbladProps, RouteComponentProps {}

const ProductTab: React.FC<IProps> = (props) => {
  const {
    product,
    servicemeldingen,
    transportopdrachtregels,
    contracten,
    mutatiesReferentiecode,
    relaties,
    magazijnen,
  } = useContext<IContext>(ProductContext);

  const ProductTab = useMemo<IOphalenContractenResultElementV2 | null>(() => {
    if (contracten === null || contracten.length === 0) {
      return null;
    }

    const contract = contracten.find((x) => x.status.NaamEnum === EContractstatus.Lopend) ?? null;

    return contract;
  }, [contracten]);

  const melding = useMemo<IOphalenMeldingenResultElement | null>(() => {
    if (servicemeldingen === null || servicemeldingen.length === 0) {
      return null;
    }
    const melding = servicemeldingen[0];

    return melding;
  }, [servicemeldingen]);

  const magazijn = useMemo<IOphalenMagazijnenResultElement | null>(() => {
    if (product === null || product.locatie === null || magazijnen === null) {
      return null;
    }
    const magazijn = magazijnen.find((x) => x.LocID === product.locatie!.LocID) ?? null;

    return magazijn;
  }, [magazijnen]);

  const transportopdrachtregel = useMemo<IRegel | null>(() => {
    if (transportopdrachtregels === null || transportopdrachtregels.length === 0) {
      return null;
    }
    const transportregel = transportopdrachtregels[0];

    return transportregel;
  }, [transportopdrachtregels]);

  // const Productinfo: React.FC<{}> = () => {
  //   if (product === null) {
  //     return <span></span>;
  //   }
  //   let info;
  //   switch (product.StatusnaamEnum) {
  //     case EProductstatus.Verhuur:
  //       info = <span>Verhuur {product.locatie!.Straatnaam}</span>;
  //       break;
  //     case EProductstatus.UitBedrijf:
  //       info = <span>Uit bedrijf</span>;
  //       break;
  //     default:
  //       info = <span>Geen info</span>;
  //       break;
  //   }
  //   return <VeldWeergave>{info}</VeldWeergave>;
  // };
  interface IContractProps {
    contracten: IOphalenContractenResultElementV2[] | null;
  }

  const ContractLink: React.FC<IContractProps> = (contractProps) => {
    if (contractProps.contracten === null || contractProps.contracten.length === 0) {
      return <span></span>;
    }

    return (
      <ContractVisualisatie
        cntID={contractProps.contracten[contractProps.contracten.length - 1].CntID}
        onClick={(ev) => {
          props.onAnnuleren();
        }}
      />
    );
  };

  interface ITransportopdrachtregelsProps {
    transportopdrachtregels: IRegel[];
  }

  const TransportopdrachtregelLink: React.FC<ITransportopdrachtregelsProps> = (
    ITransportopdrachtregelsProps,
  ) => {
    if (ITransportopdrachtregelsProps.transportopdrachtregels.length === 0) {
      return <span></span>;
    }

    return (
      <TransportopdrachtregelVisualisatie
        trsRegID={ITransportopdrachtregelsProps.transportopdrachtregels[0].TrsRegID}
        onClick={props.onAnnuleren}
      />
    );
  };

  interface IMagazijnProps {}

  const MagazijnLink: React.FC<IMagazijnProps> = (magazijnProps) => {
    return (
      <>
        {' '}
        <a
          href="#"
          onClick={(ev) => {
            ev.preventDefault();

            const urlBasis = '/magazijn/depot';

            const status: EProductstatus = product!.productstatus.NaamEnum as EProductstatus;

            // Sluit dialoog
            props.onAnnuleren();

            // Bepaal de juiste tab
            switch (status) {
              case EProductstatus.Retouren: {
                const state = genereerUrlStateQueryParam<IRetourenUrlState>({
                  selectie: [`P:${product!.ProdID}`],
                  filterData: [
                    {
                      naam: ERetourenFilter.ProdIDs,
                      data: [product!.ProdID],
                      isActief: true,
                    },
                    {
                      naam: ERetourenFilter.Doelstatus,
                      data: [],
                      isActief: false,
                    },
                    {
                      naam: ERetourenFilter.NietInLopendePendelopdracht,
                      data: false,
                      isActief: false,
                    },
                    {
                      naam: ERetourenFilter.ZoekGemeld,
                      data: false,
                      isActief: false,
                    },
                    {
                      naam: ERetourenFilter.Productstaat,
                      data: 'NIEUW',
                      isActief: false,
                    },
                  ],
                  weergave: EWeergave.NietGegroepeerd,
                });
                props.history.push(`${urlBasis}/retouren?state=${state}`);
                return;
              }

              case EProductstatus.Voorraad: {
                const state = genereerUrlStateQueryParam<IVoorraadUrlState>({
                  // ...defaultVoorraadUrlState,
                  selectie: [`P:${product!.ProdID}`],
                  filterData: [
                    // ...defaultVoorraadUrlState.filterData.filter(
                    //   (x) => x.naam !== EVoorraadFilter.ProdIDs,
                    // ),
                    {
                      naam: EVoorraadFilter.ProdIDs,
                      data: [product!.ProdID],
                      isActief: true,
                    },
                    {
                      naam: EVoorraadFilter.Doelstatus,
                      data: [],
                      isActief: false,
                    },
                    {
                      naam: EVoorraadFilter.Productstaat,
                      data: 'NIEUW',
                      isActief: false,
                    },
                    {
                      naam: EVoorraadFilter.ZoekGemeld,
                      data: false,
                      isActief: true,
                    },
                  ],
                  weergave: EWeergave.NietGegroepeerd,
                });
                props.history.push(`${urlBasis}/voorraad?state=${state}`);
                return;
              }

              case EProductstatus.Revisie: {
                const state = genereerUrlStateQueryParam<IRevisieUrlState>({
                  selectie: [`P:${product!.ProdID}`],
                  filterData: [
                    {
                      naam: ERevisieFilter.ProdIDs,
                      data: [product!.ProdID],
                      isActief: true,
                    },
                    {
                      naam: ERevisieFilter.InLopendePendelopdracht,
                      data: true,
                      isActief: false,
                    },
                    {
                      naam: ERevisieFilter.ZoekGemeld,
                      data: false,
                      isActief: true,
                    },
                  ],
                  weergave: EWeergave.NietGegroepeerd,
                });
                props.history.push(`${urlBasis}/revisie?state=${state}`);
                return;
              }

              case EProductstatus.Afvoer: {
                const state = genereerUrlStateQueryParam<IAfvoerUrlState>({
                  selectie: [`P:${product!.ProdID}`],
                  filterData: [
                    {
                      naam: EAfvoerFilter.ProdIDs,
                      data: [product!.ProdID],
                      isActief: true,
                    },
                    {
                      naam: EAfvoerFilter.ZoekGemeld,
                      data: false,
                      isActief: true,
                    },
                  ],
                  weergave: EWeergave.NietGegroepeerd,
                });
                props.history.push(`${urlBasis}/afvoer?state=${state}`);
                return;
              }

              case EProductstatus.RetLev: {
                const state = genereerUrlStateQueryParam<IRetourLeverancierUrlState>({
                  selectie: [`P:${product!.ProdID}`],
                  filterData: [
                    {
                      naam: ERetourLeverancierFilter.ProdIDs,
                      data: [product!.ProdID],
                      isActief: true,
                    },
                    {
                      naam: ERetourLeverancierFilter.ZoekGemeld,
                      data: false,
                      isActief: true,
                    },
                  ],
                  weergave: EWeergave.NietGegroepeerd,
                });
                props.history.push(`${urlBasis}/retour-leverancier?state=${state}`);
                return;
              }

              case EProductstatus.Verhuur:
                break;

              case EProductstatus.UitBedrijf: {
                const state = genereerUrlStateQueryParam<IUitBedrijfUrlState>({
                  selectie: [`P:${product!.ProdID}`],
                  filterData: [
                    {
                      naam: EUitBedrijfFilter.ProdIDs,
                      data: [product!.ProdID],
                      isActief: true,
                    },
                    {
                      naam: EUitBedrijfFilter.Productstaat,
                      data: 'NIEUW',
                      isActief: false,
                    },
                    {
                      naam: EUitBedrijfFilter.RedenUB,
                      data: [], //redenenUB !== null ? [redenenUB[0].ID] : [],
                      isActief: false,
                    },
                  ],
                });
                props.history.push(`${urlBasis}/uit-bedrijf?state=${state}`);
                return;
              }

              case EProductstatus.Parkeren: {
                const state = genereerUrlStateQueryParam<IGeparkeerdUrlState>({
                  selectie: [`P:${product!.ProdID}`],
                  filterData: [
                    {
                      naam: EGeparkeerdFilter.ProdIDs,
                      data: [product!.ProdID],
                      isActief: true,
                    },
                    {
                      naam: EGeparkeerdFilter.Productstaat,
                      data: 'NIEUW',
                      isActief: false,
                    },
                  ],
                  weergave: EWeergave.NietGegroepeerd,
                });
                props.history.push(`${urlBasis}/geparkeerd?state=${state}`);
                return;
              }
            }

            // Bouw url state voor deze tab

            // Navigeren naar de juiste tab
          }}
        >
          Magazijn
        </a>
      </>
    );
  };

  const kolommen = useMemo<TypedColumn<IOphalenMutatiesReferentiecodeResultElement>[]>(
    () => [
      {
        name: 'ReferentiecodeNieuw',
        title: 'Ref. nieuw',
      },
      {
        name: 'ReferentiecodeOud',
        title: 'Ref. oud',
        getCellValue: (x) => (x.ReferentiecodeOud !== null ? x.ReferentiecodeOud : '-'),
      },
      {
        name: 'RecordToegevoegd',
        title: 'Mutatiedatum',
        getCellValue: (x) => format(new Date(x.RecordToegevoegd!), 'dd-MM-yyyy HH:mm'),
      },
    ],
    [],
  );

  const kolombreedtes = useMemo<
    TypedTableColumnWidthInfo<IOphalenMutatiesReferentiecodeResultElement>[]
  >(
    () => [
      {
        columnName: 'ReferentiecodeOud',
        width: 100,
      },
      {
        columnName: 'ReferentiecodeNieuw',
        width: 100,
      },
      {
        columnName: 'RecordToegevoegd',
        width: 150,
      },
    ],
    [],
  );

  return (
    <div className="d-flex flex-column flex-fill">
      {product === null || magazijnen === null ? (
        <div className="d-flex flex-fill align-items-center justify-content-center">
          <LoadingSpinner />
        </div>
      ) : (
        <div className="d-flex flex-column p-3 flex-fill" style={{ rowGap: 10 }}>
          {/* <div className="d-flex">
            <div style={{ flex: 1 }}>
              <VeldWeergave>
                <div>
                  {product.producttype.Merknaam} {product.producttype.Typenaam}
                </div>
              </VeldWeergave>
            </div>
            <div style={{ minWidth: 20 }} />
            <div style={{ flex: 1 }}>
              <VeldWeergave>{product.producttype.Productsoortnaam}</VeldWeergave>
            </div>
          </div> */}

          <div className="d-flex">
            <div style={{ flex: 1 }} className="d-flex">
              {/* <label></label> */}
              <VeldWeergave style={{ flex: 1 }}>
                <GegevensLayout
                  labelBreedte="100px"
                  gegevens={[
                    {
                      label: 'Merk/type',
                      waarde: `${product.producttype.Merknaam} ${product.producttype.Typenaam}`,
                    },
                    {
                      label: 'Categorie',
                      waarde: `${product.producttype.Productsoortnaam}`,
                    },
                    {
                      label: 'Ref.code',
                      waarde: `${product.Referentiecode !== null ? product.Referentiecode : '?'}`,
                    },
                    {
                      label: 'Serienummer',
                      waarde: `${product.Serienummer !== null ? product.Serienummer : ''}`,
                    },
                    {
                      label: 'Leeftijd',
                      waarde: `${
                        product.Nieuw
                          ? 'Nieuw'
                          : product.leeftijd !== null
                          ? maandenNaarJaren(product.leeftijd)
                          : '?'
                      }`,
                    },
                  ]}
                />
              </VeldWeergave>
            </div>
            <div style={{ minWidth: 20 }} />
            <div style={{ flex: 1 }} className="d-flex">
              {/* <label>Totaal te betalen</label> */}
              <VeldWeergave style={{ flex: 1 }}>
                <GegevensLayout
                  // labelBreedte="100px"
                  gegevens={[
                    {
                      label: 'Locatie',
                      waarde: `${
                        product.productstatus.NaamEnum === EProductstatus.UitBedrijf
                          ? 'Onbekend'
                          : product.locatie !== null
                          ? magazijn !== null
                            ? `Magazijn ${magazijn.NaamKort}`
                            : product.locatie.Straatnaam +
                              ' ' +
                              product.locatie.Huisnummer +
                              (product.locatie.Bisnummer !== null
                                ? ' ' + product.locatie.Bisnummer
                                : '') +
                              ', ' +
                              product.locatie.Postcode +
                              ' ' +
                              product.locatie.Plaatsnaam
                          : 'Onbekend'
                      }`,
                    },
                    {
                      label: 'Huurder',
                      waarde: `${
                        relaties !== null && relaties.length === 1
                          ? relaties[0].persoon?.Achternaam
                          : '-'
                      }`,
                    },
                    null,
                    {
                      label: 'Geregistereerd',
                      waarde:
                        product.RecordToegevoegd !== null
                          ? `${format(new Date(product.RecordToegevoegd), 'dd-MM-yyyy HH:mm')}`
                          : '',
                    },
                    {
                      label: 'Ltst. gewijzigd',
                      waarde:
                        product.RecordGewijzigd !== null
                          ? `${format(new Date(product.RecordGewijzigd), 'dd-MM-yyyy HH:mm')}`
                          : '',
                    },
                  ]}
                />
              </VeldWeergave>
            </div>
          </div>

          <div className="d-flex">
            <div style={{ flex: 1 }} className="d-flex">
              <VeldWeergave style={{ flex: 1 }}>
                <GegevensLayout
                  labelBreedte="100px"
                  gegevens={[
                    {
                      label: 'Status',
                      waarde: `${product.productstatus.Naam}`,
                    },
                    {
                      label: 'Doelstatus',
                      waarde: `${
                        product.productstatus.NaamEnum === EProductstatus.UitBedrijf
                          ? '-'
                          : product.retourstatus !== null
                          ? product.retourstatus.Naam
                          : 'Geen'
                      }`,
                    },
                    {
                      label: 'Defect',
                      waarde: `${product.Defect !== null ? 'Ja' : 'Nee'}`,
                    },
                    {
                      label: 'Zoekgemeld',
                      waarde: (
                        <span
                          style={{
                            color: product.ZoekGemeld !== null ? EKleur.Rood : undefined,
                          }}
                        >
                          {product.productstatus.NaamEnum === EProductstatus.UitBedrijf
                            ? '-'
                            : product.ZoekGemeld !== null
                            ? `${format(new Date(product.ZoekGemeld), 'dd-MM-yyyy HH:mm')}`
                            : 'Nee'}
                        </span>
                      ),
                    },
                  ]}
                />
              </VeldWeergave>
            </div>
            <div style={{ minWidth: 20 }} />
            <div style={{ flex: 1 }} className="d-flex">
              <VeldWeergave style={{ flex: 1 }}>
                <GegevensLayout
                  gegevens={[
                    {
                      label: 'In bedrijf',
                      waarde: `${format(new Date(product.DatumInBedrijf), 'dd-MM-yyyy')}`,
                    },
                    {
                      label: 'Uit bedrijf',
                      waarde: `${
                        product.DatumUitBedrijf !== null
                          ? format(new Date(product.DatumUitBedrijf), 'dd-MM-yyyy')
                          : ''
                      }`,
                    },
                    product.DatumUitBedrijf !== null
                      ? {
                          label: 'Reden',
                          waarde: `${
                            product.productstatus.NaamEnum === 'UITBEDRIJF'
                              ? product.redenUB !== null
                                ? product.redenUB.Naam
                                : ''
                              : ''
                          }`,
                        }
                      : null,
                  ]}
                />
              </VeldWeergave>
            </div>
          </div>

          <div className="d-flex">
            <div className="flex-fill">
              {/* <label>
                  <span>
                    {melding !== null
                      ? `Laatste servicemelding op ${format(
                          new Date(melding.Melddatum),
                          'dd-MM-yyyy',
                        )}   `
                      : 'Er is geen servicemelding bekend'}
                  </span>
                </label> */}

              {/* {melding !== null && (
                  <VeldWeergave>
                    <div>
                      <div>
                        {melding !== null
                          ? `Laatste servicemelding op ${format(
                              new Date(melding.Melddatum),
                              'dd-MM-yyyy',
                            )}   `
                          : 'Er is geen servicemelding bekend'}
                      </div>
                      <div>{melding.Omschrijving}</div>
                    </div>
                  </VeldWeergave>
                )} */}
            </div>
          </div>

          {product.Notities !== null && (
            <div className="d-flex">
              <div className="flex-fill">
                <VeldWeergave>
                  {product.Notities !== null ? (
                    <div>{product.Notities}</div>
                  ) : (
                    <span>Er zijn geen notities voor dit product</span>
                  )}
                </VeldWeergave>
              </div>
            </div>
          )}

          <div className="d-flex">
            <div className="flex-fill">
              <VeldWeergave>
                <div className="p-1 font-weight-bold">Referentie mutaties</div>
                <div className="mt-1" style={{ backgroundColor: EKleur.Wit }}>
                  <GridStyleWrapper maxHeight={400} rowAmount={mutatiesReferentiecode!.length}>
                    <Grid rows={mutatiesReferentiecode!} columns={kolommen}>
                      <VirtualTable
                        columnExtensions={kolombreedtes}
                        messages={{ noData: 'Geen mutaties bekend' }}
                      />
                      <TableHeaderRow />
                    </Grid>
                  </GridStyleWrapper>
                </div>
              </VeldWeergave>
            </div>
          </div>

          <div className="flex-fill" />

          <div className="d-flex flex-column">
            <span style={{ fontSize: 16 }}>Ga naar</span>
            <div className="d-flex">
              {true && (
                <span className="mr-3">
                  <MagazijnLink />
                </span>
              )}

              {contracten !== null && (
                <span className="mr-3">
                  <ContractLink contracten={contracten} />
                </span>
              )}

              {transportopdrachtregels !== null && transportopdrachtregels.length !== 0 && (
                <TransportopdrachtregelLink transportopdrachtregels={transportopdrachtregels} />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default withRouter(ProductTab);
