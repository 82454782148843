import React, { useCallback, useContext, useMemo, useState } from 'react';
import { RootStoreContext } from '../../../../../../stores/RootStore';
import { DetailRegelContext, IKenmerk, IProducttype } from '../index';
import {
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../../../helpers/dxTableGrid';
import {
  Grid,
  Table,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  DataTypeProvider,
  EditingState,
  IntegratedSorting,
  SortingState,
} from '@devexpress/dx-react-grid';
import { EResultType } from '../../../../../../stores/CheckStore';
import { arrayMove, SortableContainer, SortableElement } from 'react-sortable-hoc';
import DragHandle from '../../../../../../components/tabel/DragHandle';
import nameof from '../../../../../../core/nameOf';
import MuterenDialoog, { IFormikValues } from './MuterenDialoog';
import { v4 as uuidv4 } from 'uuid';

interface IKenmerkToevoegenDialoogState {}
interface IKenmerkWijzigenDialoogState {
  id: number | string;
}

interface IProps {}

const KenmerkenField: React.FC<IProps> = (props) => {
  const { checkStore, instellingStore } = useContext(RootStoreContext);
  const { values, setFieldValue, kenmerkenSortering, onKenmerkensorteringChange } = useContext(
    DetailRegelContext,
  );

  const kolommen = useMemo<TypedColumn<IKenmerk>[]>(
    () => [
      {
        name: '__dragHandle' as any,
        title: ' ',
      },
      {
        name: 'sortNr',
        title: 'Sort.',
      },
      {
        name: 'opOverzicht',
        title: 'Ovz.',
      },
      {
        name: '__kenmerk' as any,
        title: 'Kenmerk',
        getCellValue: (row: IKenmerk) => {
          const tekst = row.teksten.find((x) => x.taalID === instellingStore.TaalID!);
          return tekst!.tekst;
        },
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IKenmerk>[]>(
    () => [
      {
        columnName: '__dragHandle',
        width: 50,
      },
      {
        columnName: 'sortNr',
        width: 75,
      },
      {
        columnName: 'opOverzicht',
        width: 90,
      },
      {
        columnName: '__kenmerk' as any,
        width: 250,
      },
    ],
    [],
  );

  const keyExtractor = useCallback((row: IKenmerk) => row.prodModKenID, []);
  const [
    kenmerkToevoegenDialoogState,
    setKenmerkToevoegenDialoogState,
  ] = useState<IKenmerkToevoegenDialoogState | null>(null);
  const [
    kenmerkWijzigenDialoogState,
    setKenmerkWijzigenDialoogState,
  ] = useState<IKenmerkWijzigenDialoogState | null>(null);

  return (
    <>
      <GridStyleWrapper maxHeight={400} rowAmount={values.kenmerken.length}>
        <Grid rows={values.kenmerken} getRowId={keyExtractor} columns={kolommen}>
          <DataTypeProvider
            for={['__dragHandle']}
            formatterComponent={(formatterProps) => {
              if (
                kenmerkenSortering.length !== 1 ||
                kenmerkenSortering.findIndex(
                  (x) => x.columnName === nameof<IKenmerk>('sortNr') && x.direction === 'asc',
                ) === -1
              ) {
                return <span />;
              }

              return <DragHandle />;
            }}
          />
          <DataTypeProvider
            for={[nameof<IKenmerk>('opOverzicht')]}
            formatterComponent={(formatterProps) => {
              const row: IKenmerk = formatterProps.row;
              return <span>{row.opOverzicht ? 'Ja' : 'Nee'}</span>;
            }}
          />
          <SortingState
            sorting={kenmerkenSortering}
            onSortingChange={(x) => onKenmerkensorteringChange(x)}
          />
          <EditingState
            onCommitChanges={async (changes) => {
              if (changes.deleted !== undefined && changes.deleted.length > 0) {
                const bevestigenResult = await checkStore.bevestigen({
                  inhoud: 'Bevestig verwijderen kenmerk uit lijst',
                });
                if (bevestigenResult.type === EResultType.Annuleren) {
                  return;
                }

                const newKenmerken = values.kenmerken
                  .filter((x) => !changes.deleted!.includes(x.prodModKenID))
                  .map((x, i) => ({ ...x, sortNr: i + 1 }));

                setFieldValue('kenmerken', newKenmerken);
              }
            }}
            onAddedRowsChange={() => setKenmerkToevoegenDialoogState({})}
            onEditingRowIdsChange={(ids) => {
              const id = ids[ids.length - 1];
              setKenmerkWijzigenDialoogState({
                id,
              });
            }}
          />
          <IntegratedSorting />

          <Table
            messages={{ noData: 'Geen kenmerken' }}
            rowComponent={({ row, ...restProps }) => {
              const TableRow = SortableElement(Table.Row);
              const r: IKenmerk = row;
              return (
                <TableRow
                  {...restProps}
                  row={r}
                  index={values.kenmerken.findIndex(
                    (kenmerk: IKenmerk) => kenmerk.prodModKenID === r.prodModKenID,
                  )}
                />
              );
            }}
            bodyComponent={({ row, ...restProps }: any) => {
              const TableBody = SortableContainer(Table.TableBody);
              return (
                <TableBody
                  {...restProps}
                  onSortEnd={async (x) => {
                    const nieuwKenmerk = { ...values.kenmerken[x.newIndex]! };
                    const oudKenmerk = { ...values.kenmerken[x.oldIndex]! };

                    let newKenmerken = [...values.kenmerken];
                    newKenmerken[x.oldIndex].sortNr = nieuwKenmerk.sortNr;
                    newKenmerken[x.newIndex].sortNr = oudKenmerk.sortNr;
                    newKenmerken = arrayMove(newKenmerken, x.oldIndex, x.newIndex);

                    setFieldValue('kenmerken', newKenmerken);
                  }}
                  useDragHandle
                />
              );
            }}
          />
          <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
          <TableHeaderRow showSortingControls />
          <TableEditColumn
            width={60}
            commandComponent={DXTableEditColumnCommandComponent}
            cellComponent={DXTableEditColumnCellComponent}
            showDeleteCommand
            showAddCommand
            showEditCommand
          />
        </Grid>
      </GridStyleWrapper>
      {kenmerkToevoegenDialoogState !== null && (
        <MuterenDialoog
          open
          onSuccess={(result) => {
            const newKenmerken: IKenmerk[] = [
              ...values.kenmerken,
              {
                prodModKenID: uuidv4(),
                sortNr: values.kenmerken.length + 1,
                opOverzicht: result.opOverzicht,
                teksten: result.kenmerken,
              },
            ];
            setFieldValue('kenmerken', newKenmerken);
            setKenmerkToevoegenDialoogState(null);
          }}
          onAnnuleren={() => setKenmerkToevoegenDialoogState(null)}
        />
      )}
      {kenmerkWijzigenDialoogState !== null &&
        (() => {
          const kenmerk = values.kenmerken.find(
            (x) => x.prodModKenID === kenmerkWijzigenDialoogState.id,
          )!;
          return (
            <MuterenDialoog
              open
              initialValues={{
                opOverzicht: kenmerk.opOverzicht,
                kenmerken: kenmerk.teksten,
              }}
              onSuccess={(result) => {
                const newKenmerken: IKenmerk[] = values.kenmerken.map((k) => {
                  if (kenmerk.prodModKenID !== k.prodModKenID) {
                    return k;
                  }
                  return {
                    ...kenmerk,
                    opOverzicht: result.opOverzicht,
                    teksten: result.kenmerken,
                  };
                });
                setFieldValue('kenmerken', newKenmerken);
                setKenmerkWijzigenDialoogState(null);
              }}
              onAnnuleren={() => setKenmerkWijzigenDialoogState(null)}
            />
          );
        })()}
    </>
  );
};

export default KenmerkenField;
