import React, { useMemo } from 'react';
import Tabblad, { ITabblad } from '../../../../components/layout/Tabblad';
import { RouteComponentProps } from 'react-router';
import useUrlState from '../../../../core/useUrlState';
import Suggesties from './Suggesties';
import Vervangen from './Vervangen';

interface IProps extends RouteComponentProps {}

enum ETabblad {
  Suggesties,
  Vervangen,
}

interface IUrlState {
  tabblad: ETabblad;
}

const PersoonDuplicaten = (props: IProps) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(
    props,
    useMemo<IUrlState>(
      () => ({
        tabblad: ETabblad.Suggesties,
      }),
      [],
    ),
    'pdState',
  );
  const tabbladen = useMemo<ITabblad<ETabblad>[]>(
    () => [
      {
        id: ETabblad.Suggesties,
        label: 'Suggesties',
        content: Suggesties,
      },
      {
        id: ETabblad.Vervangen,
        label: 'Vervangen',
        content: Vervangen,
      },
    ],
    [],
  );

  return (
    <Tabblad
      geselecteerd={urlState.tabblad}
      onSelectieChange={(x) => setUrlStateSync('tabblad', x)}
      tabbladen={tabbladen}
    />
  );
};

export default PersoonDuplicaten;
