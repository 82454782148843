import * as React from 'react';
import { IOphalenInformatieVoorOverzichtResult } from '../../../../../../../../../shared/src/api/v2/relatie';
import GegevensLayout from '../../../../../../../components/layout/GegevensLayout';
import { differenceInMonths, differenceInYears, format } from 'date-fns';
import { EAccLogSoort, EBetaalwijze } from '../../../../../../../bedrijfslogica/enums';
import { Kleur as EKleur } from '../../../../../../../bedrijfslogica/constanten';
import KopieerNaarKlembord from '../../../../../../../components/KopieerNaarKlembord';
import { OverzichtTitel } from '../index';
import { RootStoreContext } from '../../../../../../../stores/RootStore';
import { useContext, useEffect, useMemo, useState } from 'react';
import FormatteerBedrag, {
  StandaardMutatieBedragOpmaker,
} from '../../../../../../../components/MutatieBedrag';
import HorizontaleScheidingslijn from '../../../../../../../components/layout/HorizontaleScheidingslijn';
import _ from 'lodash';
import { maandenNaarJaren } from '../../../../../../../bedrijfslogica/teksten';
import { RouteComponentProps, withRouter } from 'react-router';
import { IconInklappen, IconUitklappen } from '../../../../../../../components/Icons';
import VerticaleScheidingslijn from '../../../../../../../components/layout/VerticaleScheidingslijn';
import classNames from 'classnames';
import { IOphalenFinancieleInformatieResult } from '../../../../../../../../../shared/src/api/v2/debiteur';
import api from '../../../../../../../api';

interface IProps extends RouteComponentProps {
  informatie: IOphalenInformatieVoorOverzichtResult;
  relID: number;
}

const Header = (props: IProps) => {
  // const { klantkaartStore } = useContext(RootStoreContext);

  const { informatie } = props;
  const { relatie, producten, accountlogging } = informatie;

  const [meerOpen, setMeerOpen] = useState(true);

  // const datumOudsteProduct =
  //   producten.length !== 0 ? _.min(producten.map((x) => x.DatumInBedrijf)) : null;

  const datumLaatstIngelogd =
    _.max(
      accountlogging
        .filter((x) => x.Soort === EAccLogSoort.Inloggen)
        .map((x) => x.Registratiedatum),
    ) ?? null;

  const rekening = useMemo(() => {
    if (relatie === null) {
      return null;
    }

    const standaardrekening_RelRekID = relatie.financieel.StandaardRekening_RelRekID;
    const rekening =
      standaardrekening_RelRekID !== null
        ? relatie.financieel.rekeningen.find((x) => x.RelRekID === standaardrekening_RelRekID)!
        : null;

    return rekening;
  }, [relatie]);

  const signaleringen = React.useMemo<{
    hoog: Array<string | JSX.Element>;
    laag: Array<string | JSX.Element>;
  } | null>(() => {
    // if (producten === null) {
    //   return null;
    // }

    const hoog: Array<string | JSX.Element> = [];
    const laag: Array<string | JSX.Element> = [];

    if (relatie.GeenEigenGebruik) {
      laag.push(`Huurt niet voor eigen gebruik`);
    }

    if (relatie.debiteur !== null && relatie.debiteur!.ProlongatieTermijn !== 1) {
      laag.push(`Afwijkende prol.termijn (${relatie.debiteur!.ProlongatieTermijn} mnd.)`);
    }

    if (informatie.bijzonderheden.review !== null) {
      if (informatie.bijzonderheden.aantalToegevoegdeRelaties !== 0) {
        laag.push(
          `Heeft ${informatie.bijzonderheden.aantalToegevoegdeRelaties} toegevoegde relaties`,
        );
      }

      laag.push(
        `Review ${format(new Date(informatie.bijzonderheden.review.datum), 'dd-MM-yyyy')}, score ${
          informatie.bijzonderheden.review.score
        }`,
      );
      {
        /* <span
            style={{ color: Kleur.Blauw, cursor: 'pointer' }}
            onClick={(ev) => {
              ev.stopPropagation();
              props.history.push(`/klant/${relatie.RelID}/klant/review`);
            }}
          >
            {' '}
            bekijken
          </span> */
      }
    }

    if (informatie.bijzonderheden.oudProduct !== null) {
      const product = informatie.bijzonderheden.oudProduct;
      const leeftijd = differenceInMonths(new Date(), new Date(product.productdatum));
      laag.push(`Heeft oud product (${maandenNaarJaren(leeftijd)})`);
    }

    return { hoog, laag };
  }, []);

  const [
    debiteurInformatie,
    setDebiteurInformatie,
  ] = useState<IOphalenFinancieleInformatieResult | null>(null);
  useEffect(() => {
    api.v2.debiteur
      .ophalenFinancieleInformatie({
        relID: props.relID,
      })
      .then((result) => {
        setDebiteurInformatie(result);
      });
  }, [props.relID]);

  return (
    <div className="d-flex flex-column p-3 pl-4 pr-4">
      {/*<OverzichtTitel>Relatie</OverzichtTitel>*/}

      {informatie.relatie.Geblokkeerd && (
        <div
          className="d-flex justify-content-center p-4"
          style={{
            color: EKleur.Rood,
            fontSize: 16,
            fontWeight: 'bold',
            backgroundColor: EKleur.HeelLichtGrijs,
          }}
        >
          DEZE RELATIE IS GEBLOKKEERD
        </div>
      )}

      <div className="d-flex">
        {/* <div style={{ width: 240 }}>
          <GegevensLayout
            gegevens={[
              {
                label: 'Huren sinds',
                waarde: `${
                  informatie.datumOudsteContract !== null
                    ? maandenNaarJaren(
                        differenceInMonths(new Date(), new Date(informatie.datumOudsteContract!)),
                      )
                    : '-'
                }`,
              },
              {
                label: 'Aantal gebruikers',
                waarde: `${
                  informatie.aantalGebruikers !== null
                    ? informatie.aantalGebruikers
                    : 'Zie contracten'
                }`,
              },
              {
                label: 'Actieve contracten',
                waarde: `${informatie.actiefAantalContracten}`,
              },
              {
                label: 'Totaal contracten',
                waarde: `${informatie.totaalAantalContracten}`,
              },
              {
                label: 'Minimaal huren tot',
                waarde:
                  informatie.bijzonderheden.minimaalHurenTot !== null &&
                  new Date(informatie.bijzonderheden.minimaalHurenTot) > new Date()
                    ? format(new Date(informatie.bijzonderheden.minimaalHurenTot), 'dd-MM-yyyy')
                    : '-',
              },
              // {
              //   label: 'Laatst ingelogd',
              //   waarde:
              //     datumLaatstIngelogd !== null
              //       ? format(new Date(datumLaatstIngelogd), 'dd-MM-yyyy')
              //       : 'Nvt',
              // },
            ]}
          />
        </div> */}

        {/* <div style={{ width: 300 }}>
          <GegevensLayout
            gegevens={[
              {
                label: 'IBAN',
                waarde:
                  rekening !== null ? (
                    !rekening.NietGebruiken ? (
                      <span>{rekening.IBAN}</span>
                    ) : (
                      <span style={{ color: EKleur.Rood }}>{rekening.IBAN}</span>
                    )
                  ) : (
                    ''
                  ),
              },
              {
                label: 'Rek.naam ',
                waarde:
                  rekening !== null ? (
                    !rekening.NietGebruiken ? (
                      <span>{rekening.Rekeningnaam}</span>
                    ) : (
                      <span>{rekening.Rekeningnaam}</span>
                    )
                  ) : (
                    ''
                  ),
              },
              {
                label: 'Betaalwijze',
                waarde:
                  relatie.debiteur !== null ? (
                    <>
                      <span style={{ textAlign: 'right' }}>
                        {relatie.debiteur.betaalwijze.Naam}&nbsp;
                        {relatie.debiteur.betaalwijze.NaamEnum === EBetaalwijze.Incasso
                        // &&
                        // relatie.debiteur.Betaaldag === -1
                        //   ? 'Stufidatum'
                        //   : relatie.debiteur!.Betaaldag.toString() + 'e v/d mnd'
                        }
                      </span>
                    </>
                  ) : (
                    <span></span>
                  ),
              },
              {
                label: 'Betaaldag',
                waarde:
                  relatie.debiteur !== null ? (
                    <span>
                      {relatie.debiteur!.Betaaldag === -1
                        ? 'Stufidag'
                        : relatie.debiteur!.Betaaldag === 0
                        ? 'Standaard'
                        : relatie.debiteur!.Betaaldag + 'e'}
                    </span>
                  ) : (
                    <span></span>
                  ),
              },
              {
                label: 'Bet.termijn',
                waarde:
                  relatie.debiteur !== null ? (
                    <span>
                      {relatie.debiteur!.Betalingstermijn === 0
                        ? 'Standaard'
                        : relatie.debiteur!.Betalingstermijn + ' dagen'}
                    </span>
                  ) : (
                    <span></span>
                  ),
              },
              // relatie.debiteur !== null && relatie.debiteur!.ProlongatieTermijn !== 1
              //   ? {
              //       label: 'Prol.termijn',
              //       waarde:
              //         relatie.debiteur !== null ? (
              //           <span>
              //             {relatie.debiteur!.ProlongatieTermijn === 1
              //               ? 'Standaard'
              //               : relatie.debiteur!.ProlongatieTermijn + ' maanden'}
              //           </span>
              //         ) : (
              //           <span></span>
              //         ),
              //     }
              //   : null,
            ]}
          />
        </div> */}

        {/* <div style={{ width: 170 }}>
          <GegevensLayout
            gegevens={[
              {
                label: 'Openstaand',
                waarde:
                  debiteurInformatie !== null ? (
                    <>
                      <FormatteerBedrag
                        bedrag={debiteurInformatie!.facturen.bedragOpenstaand}
                        opmaakComponent={(opmaakProps) => {
                          return (
                            <StandaardMutatieBedragOpmaker
                              {...opmaakProps}
                              color={
                                debiteurInformatie!.facturen.bedragOpenstaand > 0
                                  ? EKleur.Rood
                                  : undefined
                              }
                            />
                          );
                        }}
                      />
                    </>
                  ) : (
                    <span />
                  ),
              },
              {
                label: 'Vervallen',
                waarde:
                  debiteurInformatie !== null ? (
                    <>
                      <FormatteerBedrag
                        bedrag={debiteurInformatie!.facturen.bedragVervallen}
                        opmaakComponent={(opmaakProps) => {
                          return (
                            <StandaardMutatieBedragOpmaker
                              {...opmaakProps}
                              color={
                                debiteurInformatie!.facturen.bedragVervallen > 0
                                  ? EKleur.Rood
                                  : undefined
                              }
                            />
                          );
                        }}
                      />
                    </>
                  ) : (
                    <span />
                  ),
              },
              {
                label: 'Saldo VU',
                waarde:
                  debiteurInformatie !== null ? (
                    <>
                      <FormatteerBedrag bedrag={debiteurInformatie!.saldoVooruitbetaald} />
                    </>
                  ) : (
                    <span />
                  ),
              },
            ]}
          />
        </div> */}

        {signaleringen !== null && signaleringen?.laag.length !== 0 && (
          <>
            <VerticaleScheidingslijn />

            <div className="ml-0">
              {signaleringen.laag.length !== 0 && (
                <>
                  <div
                    style={{
                      // backgroundColor: Kleur.HeelLichtGrijs,
                      color: EKleur.BeetjeDonkerGrijs,
                    }}
                    className={classNames([
                      'pb-2 pl-4 pr-4',
                      signaleringen.hoog.length > 0 ? 'pt-2' : '',
                    ])}
                  >
                    {/*<IcoonWarning style={{ fill: Kleur.Grijs, width: 18, height: 18 }} />*/}
                    <ul className="p-0 mb-0 ml-3">
                      {signaleringen.laag.map((signalering, i) => (
                        <li key={i}>{signalering}</li>
                      ))}
                    </ul>
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </div>

      {/* <div className="d-flex flex-column align-items-center">
        <a
          className="d-flex align-items-center text-decoration-none"
          href="#"
          onClick={() => setMeerOpen((curr) => !curr)}
        >
          <span>{meerOpen ? 'Minder' : 'Meer'} info</span>
          {meerOpen ? (
            <IconInklappen style={{ fill: EKleur.SelectieBlauw, width: 18, height: 18 }} />
          ) : (
            <IconUitklappen style={{ fill: EKleur.SelectieBlauw, width: 18, height: 18 }} />
          )}
        </a>
      </div> */}

      {meerOpen && (
        <div className="d-flex mt-2">
          {/* <div style={{ width: 275 }}>
            <GegevensLayout
              gegevens={[
                {
                  label: 'Wervingscode',
                  waarde: (
                    <div className="d-flex">
                      <span
                        className="font-weight-bold d-flex align-items-center"
                        style={{
                          color:
                            relatie.Wervingscode !== null && relatie.WervingscodeOngeldig
                              ? EKleur.Rood
                              : undefined,
                        }}
                      >
                        {relatie.Wervingscode !== null ? relatie.Wervingscode : 'Onbekend'}
                      </span>
                      {relatie.Wervingscode !== null && (
                        <span className="ml-2">
                          <KopieerNaarKlembord waarde={relatie.Wervingscode!} />
                        </span>
                      )}
                    </div>
                  ),
                },
                {
                  label: 'Overnamecode',
                  waarde: (
                    <div className="d-flex">
                      <span className="font-weight-bold d-flex align-items-center">
                        {relatie.Overnamecode !== null ? relatie.Overnamecode : 'Onbekend'}
                      </span>
                      {relatie.Overnamecode !== null && (
                        <span className="ml-2">
                          <KopieerNaarKlembord waarde={relatie.Overnamecode!} />
                        </span>
                      )}
                    </div>
                  ),
                },
              ]}
            />
          </div> */}
          <div style={{ width: 275 }}>
            <GegevensLayout
              gegevens={[
                {
                  label: 'Geregistreerd',
                  waarde: `${
                    relatie.RecordToegevoegd !== null
                      ? format(new Date(relatie.RecordToegevoegd), 'dd-MM-yyyy HH:mm')
                      : 'Onbekend'
                  }`,
                },
                {
                  label: 'Laatst ingelogd',
                  waarde:
                    datumLaatstIngelogd !== null
                      ? format(new Date(datumLaatstIngelogd), 'dd-MM-yyyy')
                      : 'Nvt',
                },
                {
                  label: 'Hoedanigheid',
                  waarde: `${relatie.hoedanigheid!.Naam}`,
                },
              ]}
            />
          </div>
        </div>
      )}

      {/* <div className="d-flex mt-3">
        <div
          style={{
            color: EKleur.Blauw,
            cursor: 'pointer',
          }}
          onClick={(ev) => props.history.push(`/klant/${relatie.RelID}/signaleringen`)}
        >
          <span>Heeft signaleringen --{'>'}</span>
        </div>
      </div> */}
    </div>
  );
};

export default withRouter(Header);
