import * as React from 'react';
import { TableRowDetailWrapper } from '../../../../../../helpers/dxTableGrid';
import TabelInspringBlok from '../../../../../layout/TabelInspringBlok';
import { TableRowDetail } from '@devexpress/dx-react-grid';
import { IOphalenCommunicatieItemsResultElement } from '../../../../../../../../shared/src/api/v2/communicatie';
import { useMemo } from 'react';
import EmailRowDetail from './Email';
import { IUitgeklapteRijProps } from '../../../../../tabel/ASPTabel/Body/UitgeklapteRij';
import { EKolom } from '..';
import SmsRowDetail from './SmsRowDetail';

const RowDetail = (props: IUitgeklapteRijProps<EKolom, IOphalenCommunicatieItemsResultElement>) => {
  const row = props.regel;

  const detail = useMemo(() => {
    switch (row.type) {
      case 'EMAIL':
        return <EmailRowDetail item={row} data={row.data} />;
      case 'SMS_BERICHT':
        return <SmsRowDetail item={row} data={row.data} />;
    }

    throw new Error('Niet ondersteund communicatie type voor row detail');
  }, [row]);

  return (
    <TableRowDetailWrapper>
      <div className="d-flex flex-fill">
        <TabelInspringBlok />
        {detail}
      </div>
    </TableRowDetailWrapper>
  );
};

export default RowDetail;
