import React from 'react';

export interface IUitgeklapteRijProps<TKey extends keyof any, TRow> {
  style: React.CSSProperties;
  regel: TRow;
}

const StandaardUitgeklapteRij = <TKey extends keyof any, TRow>(
  props: IUitgeklapteRijProps<TKey, TRow>,
) => {
  return (
    <div style={props.style}>
      <span className="text-muted">Implementeer het uitgeklapte rij component...</span>
    </div>
  );
};

export default StandaardUitgeklapteRij;
