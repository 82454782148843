import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  Column,
  DataTypeProvider,
  IntegratedSorting,
  RowDetailState,
  SelectionState,
  SortingState,
  TableColumnWidthInfo,
  EditingState,
  VirtualTable as VirtualTableBase,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  TableSelection,
  VirtualTable,
  TableRowDetail,
} from '@devexpress/dx-react-grid-bootstrap4';
import { RouteComponentProps } from 'react-router-dom';
import useUrlState from '../../../core/useUrlState';
import FilterBalkV2, {
  IFilter,
  IFilterData,
  maakFilterSchema,
} from '../../../components/FilterBalkV2';
import LoadingSpinner from '../../../components/Gedeeld/LoadingSpinner';
import api from '../../../api';
import {
  DXCommandComponent,
  DXTableCheckboxComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../helpers/dxTableGrid';
import { Helmet } from 'react-helmet';
import MenuLayout from '../../../components/MenuLayout';
import { Kleur as EKleur } from '../../../bedrijfslogica/constanten';
import { IconToevoegen, IconVerwijderen } from '../../../components/Icons';
import { IOphalenDienstenResultElement } from '../../../../../shared/src/api/v2/dienst/service';
import _ from 'lodash';
import { IOphalenVoorkeurgebiedenResultElement } from '../../../../../shared/src/api/v2/service/voorkeurgebied';
import { RootStoreContext } from '../../../stores/RootStore';
import { EResultType } from '../../../stores/CheckStore';
import MuterenDialoog from './MuterenDialoog';
import Combobox from '../../../components/formulier/Combobox';
import { IOphalenLandenVoorLijstResult } from '../../../../../shared/src/api/v2/land';

enum EFilter {
  Servicedienst = 'SERVDIENST_IDS',
}

export interface IMuterenDialoogState {
  id?: number;
  servDienstID?: number;
}

export interface IUrlState {
  filterdata: IFilterData<EFilter>[];
  selectie: number[];
  muterenDialoogState: IMuterenDialoogState | null;
}

interface IProps extends RouteComponentProps {}

interface IRegel extends IOphalenVoorkeurgebiedenResultElement {}

const Voorkeurgebieden: React.FC<IProps> = (props) => {
  const [gebieden, setGebieden] = useState<IOphalenVoorkeurgebiedenResultElement[] | null>(null);
  const [diensten, setDiensten] = useState<IOphalenDienstenResultElement[] | null>(null);
  const [landen, setLanden] = useState<IOphalenLandenVoorLijstResult | null>(null);

  const ophalenLanden = useCallback(async () => {
    const result = await api.v2.land.ophalen({
      filterSchema: {
        filters: [],
      },
    });
    setLanden(result);
  }, []);

  useEffect(() => {
    ophalenLanden();
  }, [ophalenLanden]);

  const defaultUrlState: IUrlState = useMemo(() => {
    return {
      filterdata: [
        {
          naam: EFilter.Servicedienst,
          data: [44],
          isActief: true,
        },
      ],
      selectie: [],
      muterenDialoogState: null,
    };
  }, [diensten]);

  // const defaultUrlState: IUrlState = {
  //   filterdata: [
  //     {
  //       naam: EFilter.Servicedienst,
  //       data: [44],
  //       isActief: true,
  //     },
  //   ],
  //   selectie: [],
  //   muterenDialoogState: null,
  // };

  const [urlState, setUrlState, setUrlStateSync] = useUrlState<IUrlState>(props, defaultUrlState);
  const { checkStore } = useContext(RootStoreContext);

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.Servicedienst,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          if (diensten === null) {
            return null;
          }
          const data: number[] = weergaveProps.data;

          return (
            <div className="d-flex align-items-center">
              <span className="mr-2">Servicedienst</span>
              <Combobox
                geselecteerd={data.length === 0 ? null : data[0]}
                onSelectieChange={(x) => {
                  const newData = x === null ? [] : [x];
                  weergaveProps.onDataChange(newData);
                  weergaveProps.setIsActief(true);
                  weergaveProps.toepassen();
                }}
                opties={diensten.map((x) => {
                  return { id: x.ID, label: x.NaamIdent! };
                })}
              />
            </div>
          );
        },
      },
    ],
    [diensten],
  );

  const [filterSchema, setFilterSchema] = useState(maakFilterSchema(urlState.filterdata));

  const ophalenGebieden = useCallback(async () => {
    const result = await api.v2.service.voorkeurgebied.ophalenVoorkeurgebieden({
      filterSchema: {
        filters: [...filterSchema.filters!],
      },
      // orderSchema: { orders: [{ naam: '', richting: 'DESC' }] },
    });

    setGebieden(result.gebieden);
  }, [filterSchema]);

  useEffect(() => {
    ophalenGebieden();
  }, [ophalenGebieden]);

  const ophalenDiensten = useCallback(async () => {
    if (gebieden === null) {
      return;
    }

    const ids = _.uniq(gebieden.map((x) => x.ServDienstID));
    const result = await api.v2.dienst.service.ophalenDiensten({
      filterSchema: {
        filters: [
          { naam: 'IS_ACTIEF', data: true },
          { naam: 'IS_HOOFDAANNEMER', data: true },
        ],
      },
      orderSchema: { orders: [{ naam: 'NAAM', richting: 'ASC' }] },
    });

    setDiensten(result.diensten);
  }, [gebieden]);

  useEffect(() => {
    ophalenDiensten();
  }, [ophalenDiensten]);

  const keyExtractor = useCallback((rij: IRegel) => rij.ID, []);
  const kolommen = useMemo<TypedColumn<IRegel>[]>(() => {
    return [
      {
        name: 'PostcodeVan',
        title: 'Postcode Van',
        getCellValue: (x) => {
          return x.PostcodeVan;
        },
      },
      {
        name: 'PostcodeTot',
        title: 'Postcode Tot',
        getCellValue: (x) => {
          return x.PostcodeTot;
        },
      },
      {
        name: '__land' as any,
        title: 'Land',
      },
      {
        name: '__dienst' as any,
        title: 'Servicedienst',
      },
    ];
  }, []);

  const kolombreedtes = useMemo<TypedTableColumnWidthInfo<IRegel>[]>(() => {
    return [
      {
        columnName: 'PostcodeVan',
        width: 125,
      },
      {
        columnName: 'PostcodeTot',
        width: 125,
      },
      {
        columnName: '__land' as any,
        width: 135,
      },
      {
        columnName: '__dienst' as any,
        width: 300,
      },
    ];
  }, []);

  const handleVerwijderen = useCallback(async () => {
    // const checkData = await api.v2.service.voorkeurgebied.verwijderenVoorkeurgebieden({
    //   ids: urlState.selectie,
    // });
    // if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
    //   return;
    // }

    if (
      (
        await checkStore.bevestigen({
          inhoud: `Wil je de ${urlState.selectie.length} gebieden verwijderen?`,
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    await api.v2.service.voorkeurgebied.verwijderenVoorkeurgebieden({
      ids: urlState.selectie,
    });

    setUrlStateSync('selectie', []);
    ophalenGebieden();
  }, [urlState.selectie]);

  return gebieden === null ||
    diensten === null ||
    filterSchema.filters === undefined ||
    landen === null ? (
    <div className="flex-fill d-flex align-items-center justify-content-center">
      <LoadingSpinner />
    </div>
  ) : (
    <>
      <Helmet>
        <title>Voorkeurgebieden</title>
      </Helmet>
      <MenuLayout
        menu={
          <>
            <div>
              <div className="d-flex align-items-center">
                <button
                  className="btn btn-sm btn-light d-flex align-items-center"
                  style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                  disabled={
                    filterSchema.filters.find((x) => x.naam === EFilter.Servicedienst) === undefined
                  }
                  onClick={() => {
                    const data = filterSchema.filters!.find(
                      (x) => x.naam === EFilter.Servicedienst,
                    )!;
                    return setUrlStateSync('muterenDialoogState', { servDienstID: data.data[0] });
                  }}
                >
                  <span>
                    <IconToevoegen style={{ width: 16, height: 16, fill: EKleur.Grijs }} />
                  </span>
                  <span className="ml-2">Toevoegen</span>
                </button>
                <button
                  className="btn btn-sm btn-light d-flex align-items-center ml-3"
                  style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                  disabled={urlState.selectie.length === 0}
                  onClick={() => handleVerwijderen()}
                >
                  <span>
                    <IconVerwijderen style={{ width: 16, height: 16, fill: EKleur.Grijs }} />
                  </span>
                  <span className="ml-2">Verwijderen</span>
                </button>

                <div className="d-flex flex-fill ml-3">
                  <FilterBalkV2
                    filters={filters}
                    filterData={urlState.filterdata}
                    onFilterDataChange={(x) => setUrlStateSync('filterdata', x)}
                    onFilterSchemaChange={setFilterSchema}
                  />
                </div>
              </div>
            </div>
          </>
        }
        body={
          <>
            <GridStyleWrapper height="calc(100vh - 150px)">
              <Grid rows={gebieden} columns={kolommen} getRowId={keyExtractor}>
                <DataTypeProvider
                  for={['postcodeVan', 'postcodeTot']}
                  formatterComponent={(formatterProps) => {
                    return <span>{formatterProps.value}</span>;
                  }}
                />

                <DataTypeProvider
                  for={['__dienst']}
                  formatterComponent={(formatterProps) => {
                    const dienst = diensten.find((x) => x.ID === formatterProps.row.ServDienstID)!;
                    if (dienst === undefined) {
                      return <span></span>;
                    }
                    return <span>{dienst.relatie!.organisatie!.Naam}</span>;
                  }}
                />

                <DataTypeProvider
                  for={['__land']}
                  formatterComponent={(formatterProps) => {
                    const land = landen.find((x) => x.LandID === formatterProps.row.LandID)!;
                    return <span>{land.Naam}</span>;
                  }}
                />

                {/* <DataTypeProvider
                  for={[nameOf<IRegel>('RecordToegevoegd')]}
                  formatterComponent={(x) => {
                    if (x.value === null) {
                      return <span></span>;
                    }
                    return <span>{format(new Date(x.value), 'dd-MM-yyyy HH:mm')}</span>;
                  }}
                /> */}

                <SortingState defaultSorting={[]} />
                <IntegratedSorting />
                <VirtualTable />
                <TableColumnResizing
                  defaultColumnWidths={kolombreedtes as TableColumnWidthInfo[]}
                />
                <TableHeaderRow showSortingControls />
                <EditingState
                  onAddedRowsChange={() => {}}
                  onEditingRowIdsChange={(x) => {
                    const id = x[x.length - 1] as number;
                    setUrlStateSync('muterenDialoogState', { id });
                  }}
                  onCommitChanges={() => null}
                />
                <TableEditColumn width={35} showEditCommand commandComponent={DXCommandComponent} />
                <SelectionState
                  selection={urlState.selectie}
                  onSelectionChange={(value) => setUrlStateSync('selectie', value as number[])}
                />
                <TableSelection cellComponent={DXTableCheckboxComponent} />
              </Grid>
            </GridStyleWrapper>
          </>
        }
      />
      {urlState.muterenDialoogState !== null && (
        <MuterenDialoog
          open
          id={urlState.muterenDialoogState.id}
          servDienstID={urlState.muterenDialoogState.servDienstID}
          onSuccess={async () => {
            setUrlStateSync('muterenDialoogState', null);
            ophalenGebieden();
          }}
          onAnnuleren={() => setUrlStateSync('muterenDialoogState', null)}
        />
      )}
    </>
  );
};

export default Voorkeurgebieden;
