import React, { useCallback, useMemo } from 'react';
import { ESoort, IRow } from '../../index';
import {
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../../helpers/dxTableGrid';
import {
  Grid,
  Table,
  TableColumnResizing,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-bootstrap4';
// import { IRegel, IRegelProduct } from '../../../../../../../shared/src/api/v2/website/bestelling';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import RelatieVisualisatie from '../../../../personalia/RelatieVisualisatie';
import {
  IRegelProduct,
  IRegelContract,
  IRegelVerkoop,
} from '../../../../../../../shared/src/api/v2/winkelwagen';
import ContractVisualisatie from '../../../../entiteitVisualisaties/ContractVisualisatie';

interface IProps {
  row: IRow;
}

const Product: React.FC<IProps> = (props) => {
  const productregels = (props.row.regels as IRegelProduct[]).filter(
    (x) => x.Soort === ESoort.Product,
  );

  const keyExtractor = useCallback((row: IRegelProduct) => row.ID, []);
  const kolommen = useMemo<TypedColumn<IRegelProduct>[]>(
    () => [
      {
        name: '__afbeelding' as any,
        title: 'Afb.',
      },
      {
        name: '__modelcode' as any,
        title: 'Modelcode',
      },
      {
        name: '__modelnaam' as any,
        title: 'Modelnaam',
      },
      {
        name: '__abonnement' as any,
        title: 'Abon.',
      },
      {
        name: '__aantalGebruikers' as any,
        title: '# Gebr.',
      },
      {
        name: 'AansluitenTrekschakelaar',
        title: 'Treksch.',
      },
      {
        name: '__contractnummer' as any,
        title: 'Contractwissel voor',
      },
      {
        name: '__gekoppeldContract' as any,
        title: 'Gekoppeld contract',
      },
      {
        name: '__wissel_Relatie' as any,
        title: ' ',
      },
    ],
    [],
  );
  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRegelProduct>[]>(
    () => [
      {
        columnName: '__modelcode' as any,
        width: 125,
      },
      {
        columnName: '__modelnaam' as any,
        width: 225,
      },
      {
        columnName: '__afbeelding' as any,
        width: 70,
      },
      {
        columnName: '__abonnement' as any,
        width: 100,
      },
      {
        columnName: '__aantalGebruikers' as any,
        width: 75,
      },
      {
        columnName: 'AansluitenTrekschakelaar',
        width: 75,
      },
      {
        columnName: '__contractnummer' as any,
        width: 150,
      },
      {
        columnName: '__gekoppeldContract' as any,
        width: 150,
      },
      {
        columnName: '__wissel_Relatie' as any,
        width: 225,
      },
    ],
    [],
  );

  if (productregels.length === 0) {
    return (
      <div className="p-4 d-flex align-items-center justify-content-center flex-fill">
        Geen producten.
      </div>
    );
  }
  return (
    <GridStyleWrapper rowAmount={productregels.length} maxHeight={500}>
      <Grid rows={productregels} columns={kolommen} getRowId={keyExtractor}>
        <DataTypeProvider
          for={['AansluitenTrekschakelaar']}
          formatterComponent={(formatterProps) => {
            const regel: IRegelProduct = formatterProps.row;
            return (
              <span>
                {regel.AansluitenTrekschakelaar !== null
                  ? regel.AansluitenTrekschakelaar
                    ? 'Ja'
                    : 'Nee'
                  : '-'}
              </span>
            );
          }}
        />
        <DataTypeProvider
          for={['__modelcode']}
          formatterComponent={(formatterProps) => {
            const regel: IRegelProduct = formatterProps.row;
            return <span>{regel.productmodel.Modelcode}</span>;
          }}
        />
        <DataTypeProvider
          for={['__modelnaam']}
          formatterComponent={(formatterProps) => {
            const regel: IRegelProduct = formatterProps.row;
            return <span>{regel.productmodel.Modelnaam}</span>;
          }}
        />
        <DataTypeProvider
          for={['__abonnement']}
          formatterComponent={(formatterProps) => {
            const regel: IRegelProduct = formatterProps.row;
            return <span>{regel.abonnement.Naam}</span>;
          }}
        />
        <DataTypeProvider
          for={['__aantalGebruikers']}
          formatterComponent={(formatterProps) => {
            const regel: IRegelProduct = formatterProps.row;
            return <span>{regel.AantalGebruikers}</span>;
          }}
        />

        <DataTypeProvider
          for={['__contractnummer']}
          formatterComponent={(formatterProps) => {
            const regel: IRegelProduct = formatterProps.row;
            const cntID = regel.contractwisselVoor !== null ? regel.contractwisselVoor.CntID : null;

            if (cntID === null) {
              return <span></span>;
            }
            return <ContractVisualisatie cntID={cntID} />;
          }}
        />

        <DataTypeProvider
          for={['__gekoppeldContract']}
          formatterComponent={(formatterProps) => {
            const regel: IRegelProduct = formatterProps.row;

            if (regel.GekoppeldContract_CntID === null) {
              return <span></span>;
            }
            return <ContractVisualisatie cntID={regel.GekoppeldContract_CntID} />;
          }}
        />

        <DataTypeProvider
          for={['__afbeelding']}
          formatterComponent={(formatterProps) => {
            const regel: IRegelProduct = formatterProps.row;

            const bestand =
              regel.productmodel.bestanden.length !== 0
                ? regel.productmodel.bestanden[0].bestand
                : null;

            if (bestand === null) {
              return <span></span>;
            }
            return <img src={bestand.url} style={{ maxHeight: 20 }} />;
          }}
        />

        <Table
          messages={{
            noData: 'Geen producten',
          }}
        />
        <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
        <TableHeaderRow />
      </Grid>
    </GridStyleWrapper>
  );
};

export default Product;
