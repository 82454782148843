import { Field, FieldProps, Formik } from 'formik';
import React, { useCallback, useContext, useState, useMemo, useEffect } from 'react';
import { ModalFooter, ModalTitle } from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/ModalHeader';
import api from '../../../api';
import IDialoogProps from '../../../core/IDialoogProps';
import Dialoog from '../../dialogen/Dialoog';
import LoadingSpinner from '../../Gedeeld/LoadingSpinner';
import Tabblad, { ITabblad } from '../../layout/Tabblad';
import _ from 'lodash';
import { IOphalenMagazijnenResultElement } from '../../../../../shared/src/api/v2/magazijn';
import {
  IOphalenVoorraadinfoResult,
  IOphalenVoorraadinfoResultElement,
} from '../../../../../shared/src/api/v2/voorraad';
import {
  IOphalenInkoopopdrachtenResultElement,
  IOphalenProductenResultElementV2,
} from '../../../../../shared/src/api/v2/product';
import nameof from '../../../core/nameOf';
import MultiCombobox, { IKolom } from '../../formulier/MultiCombobox';
import { IOphalenDienstenResultElement } from '../../../../../shared/src/api/v2/dienst/inkoop';
import { IOphalenProducttypenInkoopResultElement } from '../../../../../shared/src/api/v2/inkoop/aanbod';
import {
  IOphalenOpdrachtenResultElementV2,
  IOphalenOpdrachtregelsResultElementV2,
} from '../../../../../shared/src/api/v2/transport/opdracht';
import ReserveringTab from './ReserveringTab';
import DetailTab from './DetailTab';
import GeplaatsteProductenTab from './GeplaatsteProductenTab';

interface IProps extends IDialoogProps<{}> {
  typeID: number;
  magID?: number;
}

enum ETablad {
  Reservering,
  Detail,
  Geplaatst,
}

export interface IFormikValues {
  magID: number | null;
  notities: string | null;
}

export const veldnamen: Record<keyof IFormikValues, string> = {
  magID: 'Magazijn',
  notities: 'Notities',
};

export interface IGeplaatstProduct extends IOphalenProductenResultElementV2 {}

export interface IContext {
  type: IOphalenProducttypenInkoopResultElement | null;
  reserveringen: any[] | null;
  voorraadInfo: IOphalenVoorraadinfoResultElement | null;
  inkoopopdrachten: IOphalenInkoopopdrachtenResultElement[] | null;
  inkoopdiensten: IOphalenDienstenResultElement[] | null;
  transportopdrachtregels: IOphalenOpdrachtregelsResultElementV2[] | null;
  transportopdrachten: IOphalenOpdrachtenResultElementV2[] | null;
  geplaatsteProducten: IGeplaatstProduct[] | null;
}

export const VoorraadContext = React.createContext<IContext>({
  type: null,
  reserveringen: null,
  voorraadInfo: null,
  inkoopopdrachten: null,
  inkoopdiensten: null,
  transportopdrachtregels: null,
  transportopdrachten: null,
  geplaatsteProducten: null,
});

const VoorraadTypeInfoDialoog: React.FC<IProps> = (props) => {
  const { dialoogIndex, onAnnuleren, onSuccess, open } = props;

  const [tabblad, setTabblad] = useState<ETablad>(ETablad.Detail);
  const [type, setType] = useState<IOphalenProducttypenInkoopResultElement | null>(null);
  const [reserveringen, setReserveringen] = useState<any[] | null>(null);
  const [magID, setMagID] = useState<number | null>(null);
  const [magazijnen, setMagazijnen] = useState<IOphalenMagazijnenResultElement[] | null>(null);
  const typeID = useMemo<number | null>(() => (props.typeID !== undefined ? props.typeID : null), [
    props.typeID,
  ]);
  const [inkoopdiensten, setInkoopdiensten] = useState<IOphalenDienstenResultElement[] | null>(
    null,
  );
  const [geplaatsteProducten, setGeplaatsteProducten] = useState<IGeplaatstProduct[] | null>(null);

  const tabbladen = useMemo<ITabblad<ETablad>[]>(
    () => [
      {
        id: ETablad.Detail,
        label: 'Detail',
        content: DetailTab,
      },
      {
        id: ETablad.Reservering,
        label: 'Reservering',
        content: ReserveringTab,
      },
      // {
      //   id: ETablad.Geplaatst,
      //   label: 'Geplaatste producten',
      //   content: GeplaatsteProductenTab,
      // },
    ],
    [onSuccess],
  );

  useEffect(() => {
    (async () => {
      if (props.magID !== undefined) {
        setMagID(props.magID);
        return;
      }

      const magID = (
        await api.v2.magazijn.ophalenMagazijnen({
          filterSchema: { filters: [{ naam: 'IS_STANDAARD_VOORRAAD', data: null }] },
        })
      ).magazijnen[0].MagID;

      setMagID(magID);
    })();
  }, [props.magID]);

  // Rijbron van magazijnen
  useEffect(() => {
    (async () => {
      const magazijnen = (
        await api.v2.magazijn.ophalenMagazijnen({
          filterSchema: { filters: [{ naam: 'IS_VOORRAAD', data: true }] },
        })
      ).magazijnen;

      setMagazijnen(magazijnen);
    })();
  }, []);

  const [inkoopopdrachten, setInkoopopdrachten] = useState<
    IOphalenInkoopopdrachtenResultElement[] | null
  >(null);

  const ophalenInkoopopdrachten = useCallback(async () => {
    const inkoopopdrachtenResult = await api.v2.inkoop.opdracht.ophalenOpdrachten({
      filterSchema: {
        filters: [
          {
            naam: 'MAG_IDS',
            data: [magID],
          },
          {
            naam: 'TYPE_IDS',
            data: [typeID],
          },
          {
            naam: 'NOG_TE_LEVEREN',
            data: true,
          },
          {
            naam: 'IS_AFGEHANDELD',
            data: false,
          },
        ],
      },
    });

    setInkoopopdrachten(inkoopopdrachtenResult.inkoopopdrachten);
  }, [typeID, magID]);

  useEffect(() => {
    ophalenInkoopopdrachten();
  }, [ophalenInkoopopdrachten]);

  const ophalenGeplaatsteProducten = useCallback(async () => {
    const productenResult = await api.v2.product.ophalenProductenV2({
      filterSchema: {
        filters: [
          {
            naam: 'TYPE_IDS',
            data: [typeID],
          },
        ],
      },
      paginatie: { index: 0, aantal: 100 },
    });

    setGeplaatsteProducten(productenResult.producten);
  }, [typeID, magID]);

  useEffect(() => {
    ophalenGeplaatsteProducten();
  }, [ophalenGeplaatsteProducten]);

  // Producttype
  const ophalenType = useCallback(async () => {
    const producttypenResult = await api.v2.inkoop.aanbod.ophalenProductenInAanbod({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: [typeID],
          },
        ],
      },
    });

    setType(producttypenResult[0]);
  }, [typeID]);

  useEffect(() => {
    ophalenType();
  }, [ophalenType]);

  // Leveropdrachtregels
  const [transportopdrachtregels, setTransportopdrachtregels] = useState<
    IOphalenOpdrachtregelsResultElementV2[] | null
  >(null);

  const ophalenTransportopdrachtregels = useCallback(async () => {
    if (typeID === null || magID === null) {
      return;
    }

    const result = (
      await api.v2.transport.opdracht.ophalenOpdrachtregelsV2({
        filterSchema: {
          filters: [
            { naam: 'TYPE_IDS', data: [typeID] },
            { naam: 'MAG_IDS', data: [magID] },
            { naam: 'IS_BULKPRODUCT', data: false },
            { naam: 'REGELSTATUSSEN', data: [0] },
            { naam: 'OPDRACHTSTATUSSEN', data: [1, 2] },
          ],
        },
        orderSchema: { orders: [{ naam: 'BEZOEKDATUM', richting: 'DESC' }] },
        // paginatie: { index: 0, aantal: 50 },
      })
    ).regels;

    if (result.length === 0) {
      setTransportopdrachtregels([]);
      return;
    }

    setTransportopdrachtregels(result);
  }, [typeID, magID]);

  useEffect(() => {
    ophalenTransportopdrachtregels();
  }, [ophalenTransportopdrachtregels]);

  const [transportopdrachten, setTransportopdrachten] = useState<
    IOphalenOpdrachtenResultElementV2[] | null
  >(null);

  const ophalenOpdrachten = useCallback(async () => {
    if (transportopdrachtregels === null) {
      return;
    }
    const trsOpdIDs = _.uniq(transportopdrachtregels.map((x) => x.TrsOpdID));
    const opdrachtenResult = (
      await api.v2.transport.opdracht.ophalenOpdrachtenV2({
        filterSchema: {
          filters: [{ naam: 'IDS', data: trsOpdIDs }],
        },
      })
    ).opdrachten;

    setTransportopdrachten(opdrachtenResult);
  }, [transportopdrachtregels]);

  useEffect(() => {
    ophalenOpdrachten();
  }, [ophalenOpdrachten]);

  // Voorraadinfo
  const [voorraadInfo, setVoorraadInfo] = useState<IOphalenVoorraadinfoResultElement | null>(null);

  const ophalenVoorraadInfo = useCallback(async () => {
    if (typeID === null || magID === null) {
      return;
    }
    const voorraadInfoResult: IOphalenVoorraadinfoResult = await api.v2.voorraad.ophalenVoorraadinfo(
      {
        magID,
        typeIDs: [typeID],
      },
    );

    setVoorraadInfo(voorraadInfoResult.voorraad[0]);
  }, [typeID, magID]);

  useEffect(() => {
    ophalenVoorraadInfo();
  }, [ophalenVoorraadInfo]);

  useEffect(() => {
    ophalenVoorraadInfo();
  }, [ophalenVoorraadInfo]);

  // Inkoopdiensten
  useEffect(() => {
    (async () => {
      const diensten = (
        await api.v2.dienst.inkoop.ophalenDiensten({
          filterSchema: { filters: [{ naam: 'IS_ACTIEF', data: true }] },
        })
      ).diensten;

      setInkoopdiensten(diensten);
    })();
  }, []);

  const initialFormikValues = useMemo<IFormikValues | null>(() => {
    return {
      magID: props.magID ?? null,
      notities: null,
    };
  }, []);

  const magazijnKolommen = useMemo<IKolom<IOphalenMagazijnenResultElement>[]>(() => {
    return [
      {
        key: 'NaamKort',
        label: 'Naam',
        breedte: 200,
      },
    ];
  }, []);

  return (
    <Dialoog
      index={dialoogIndex || 0}
      modalProps={{
        // dialogClassName: `dialog-width-550`,
        size: 'lg',
      }}
    >
      <ModalHeader>
        <ModalTitle>
          Voorraadinformatie{' '}
          {type !== null ? type.productsoort.Naam + ' ' + type.Merknaam + ' ' + type.Typenaam : ''}
        </ModalTitle>
      </ModalHeader>
      <VoorraadContext.Provider
        value={{
          type,
          reserveringen,
          voorraadInfo,
          inkoopopdrachten,
          inkoopdiensten,
          transportopdrachtregels,
          transportopdrachten,
          geplaatsteProducten,
        }}
      >
        <div
          className="d-flex flex-column flex-fill"
          style={{
            minHeight: `600px`,
          }}
        >
          {initialFormikValues === null ||
          inkoopdiensten === null ||
          type === null ||
          inkoopopdrachten === null ||
          voorraadInfo === null ? (
            <div className="d-flex flex-fill align-items-center justify-content-center">
              <LoadingSpinner />
            </div>
          ) : (
            <>
              <Formik<IFormikValues>
                isInitialValid
                initialValues={initialFormikValues}
                //   validate={handleValidate}
                onSubmit={() => null}
                render={(FormikProps) => {
                  const { submitForm, isSubmitting, values, errors } = FormikProps;
                  return (
                    <>
                      <div className="col-6 mt-2">
                        <label>{veldnamen.magID}</label>

                        <Field
                          name={nameof<IFormikValues>('magID')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            if (magazijnen === null) {
                              return <LoadingSpinner />;
                            }

                            return (
                              <MultiCombobox<number, any>
                                sleutelExtractor={(row: IOphalenMagazijnenResultElement) =>
                                  row.MagID
                                }
                                onWaardeChange={(waarde: number | null) => {
                                  fieldProps.form.setFieldValue(field.name, waarde);
                                  setMagID(waarde);
                                }}
                                representatieFabriek={(row: IOphalenMagazijnenResultElement) =>
                                  `${row.NaamKort} - ${row.locatie.Plaatsnaam}`
                                }
                                waarde={magID}
                                opties={magazijnen}
                                kolommen={magazijnKolommen}
                              />
                            );
                          }}
                        />
                      </div>
                      <div className="d-flex flex-column flex-fill mt-3">
                        <Tabblad
                          tabbladen={tabbladen}
                          geselecteerd={tabblad}
                          onSelectieChange={(id) => {
                            setTabblad(id);
                          }}
                        />
                      </div>
                      <ModalFooter className="d-flex flex-row justify-content-start">
                        {/* <button
                        className="btn btn-primary"
                        disabled={isSubmitting}
                        onClick={submitForm}
                        style={{ width: 100 }}
                      >
                        Ok
                      </button> */}
                        <button
                          className="btn btn-primary"
                          onClick={onAnnuleren}
                          style={{
                            width: 100,
                          }}
                        >
                          Sluiten
                        </button>
                      </ModalFooter>
                    </>
                  );
                }}
              />
            </>
          )}
        </div>
      </VoorraadContext.Provider>
    </Dialoog>
  );
};

export default VoorraadTypeInfoDialoog;
