import React from 'react';
import { Route, Link, RouteComponentProps, NavLink, Redirect } from 'react-router-dom';
import PaginaHeader from '../PaginaHeader';
import Boekingen from './Boekingen';
import Activaregister from './Activaregister';
import Beheer from './Beheer';
import Controle from './Controle';
import { IconVink } from '../../components/Icons';
import Rapportage from './Rapportage';

const Boekhouding = ({ match }: RouteComponentProps) => (
  <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
    <PaginaHeader>
      <ul>
        <li>
          <NavLink
            to={`${match.url}/boekingen`}
            style={{ paddingLeft: 20, paddingRight: 20 }}
            className="d-flex align-items-center justify-content-center"
          >
            {/* <IconTransport style={{ width: 20, height: 20 }} /> */}
            &nbsp; Boekingen
          </NavLink>
        </li>

        <li>
          <NavLink
            to={`${match.url}/activaregister`}
            style={{ paddingLeft: 20, paddingRight: 20 }}
            className="d-flex align-items-center justify-content-center"
          >
            {/* <IconTransport style={{ width: 20, height: 20 }} /> */}
            &nbsp; Activaregister
          </NavLink>
        </li>

        <li>
          <NavLink
            to={`${match.url}/controle`}
            style={{ paddingLeft: 20, paddingRight: 20 }}
            className="d-flex align-items-center justify-content-center"
          >
            {/* <IconVink style={{ width: 15, height: 15 }} /> */}
            &nbsp; Controle
          </NavLink>
        </li>
        <li>
          <NavLink
            to={`${match.url}/beheer`}
            style={{ paddingLeft: 20, paddingRight: 20 }}
            className="d-flex align-items-center justify-content-center"
          >
            {/* <IconVink style={{ width: 15, height: 15 }} /> */}
            &nbsp; Beheer
          </NavLink>
        </li>
        <li>
          <NavLink
            to={`${match.url}/rapportage`}
            style={{ paddingLeft: 20, paddingRight: 20 }}
            className="d-flex align-items-center justify-content-center"
          >
            &nbsp; Rapportage
          </NavLink>
        </li>
      </ul>
    </PaginaHeader>

    <Route exact path={match.path} component={() => <Redirect to={`${match.path}/boekingen`} />} />

    <Route path={`${match.path}/boekingen`} component={Boekingen} />
    <Route path={`${match.path}/activaregister`} component={Activaregister} />
    <Route path={`${match.path}/controle`} component={Controle} />
    <Route path={`${match.path}/beheer`} component={Beheer} />
    <Route path={`${match.path}/rapportage`} component={Rapportage} />

    {/* <Route path={`${match.path}/export`} component={Boekingen} /> */}
    <Route exact={true} path={match.path} render={() => <h3>selecteer een subpagina</h3>} />
  </div>
);

export default Boekhouding;
