import React, { HTMLProps, useMemo, useState } from 'react';
import { Root } from './style';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Kleur } from '../../../bedrijfslogica/constanten';
import PersoonSelectieDialoog, { ETabblad } from '../../dialogen/PersoonSelectieDialoog';
import PersoonVisualisatie from '../PersoonVisualisatie';
import { IOphalenPersonenResultElementV2 } from '../../../../../shared/src/api/v2/persoon/persoon';
import ActieMenuKnop from '../../ActieMenuKnop';
import { IFormValues } from '../../formulier/PersoonFormulier';

interface IProps extends RouteComponentProps<{}> {
  persID: number | null;
  onPersIDChange?: (relID: number | null) => void;
  weergaveNaamPersoonOnbekend?: string;
  persoon?: IOphalenPersonenResultElementV2;
  persoonSelectieDialoogDefaultFormValues?: Partial<IFormValues>;
  naamComponent?: React.ComponentType<HTMLProps<HTMLSpanElement>>;
  ongekoppeldContainerComponent?: React.ComponentType<HTMLProps<HTMLDivElement>>;
  magMuteren?: boolean;
}

const PersoonKoppelComponent: React.FC<IProps> = (props) => {
  const [toonKoppelModal, setToonKoppelModal] = useState(false);
  const [koppelModalTabblad, setKoppelModalTabblad] = useState<ETabblad>(
    ETabblad.NieuwOfSelecteren,
  );

  // useEffect(() => {
  //   if (props.persID === null) {
  //     return;
  //   }
  //   persoonStore.enqueuePersoonOphalen(props.persID);
  // }, [props.persID]);

  const OngekoppeldComponent = props.magMuteren === false ? 'span' : 'a';
  const OngekoppeldContainerComponent = props.ongekoppeldContainerComponent ?? 'div';

  return (
    <>
      <Root>
        {props.persID === null ? (
          <OngekoppeldContainerComponent>
            <OngekoppeldComponent
              href="#"
              style={{
                color: Kleur.Oranje,
              }}
              onClick={(ev) => {
                ev.stopPropagation();
                if (props.magMuteren === false) {
                  return;
                }

                setToonKoppelModal(true);
              }}
            >
              {props.weergaveNaamPersoonOnbekend !== undefined
                ? props.weergaveNaamPersoonOnbekend
                : 'Persoon koppelen'}
            </OngekoppeldComponent>
          </OngekoppeldContainerComponent>
        ) : (
          <div className="d-flex" style={{ minWidth: 0 }}>
            <PersoonVisualisatie persID={props.persID!} naamComponent={props.naamComponent} />
            {(props.magMuteren === undefined || props.magMuteren) && (
              <ActieMenuKnop
                acties={[
                  {
                    text: 'Andere persoon koppelen',
                    onClick: () => setToonKoppelModal(true),
                  },
                  {
                    text: 'Ontkoppelen',
                    onClick: () => props.onPersIDChange?.(null),
                  },
                ]}
                iconStyle={{
                  width: 16,
                  height: 16,
                }}
              />
            )}
          </div>
        )}
      </Root>
      {toonKoppelModal && (
        <PersoonSelectieDialoog
          open
          onSuccess={(result) => {
            props.onPersIDChange?.(result.persID);
            setToonKoppelModal(false);
          }}
          onAnnuleren={() => setToonKoppelModal(false)}
          tabblad={koppelModalTabblad}
          onTabbladChange={(x) => setKoppelModalTabblad(x)}
          defaultFormValues={props.persoonSelectieDialoogDefaultFormValues}
        />
      )}
    </>
  );
};

export default withRouter(PersoonKoppelComponent);
