import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import useUrlState from '../../../../core/useUrlState';
import api from '../../../../api';
import { IOphalenProductenResultElement } from '../../../../../../shared/src/api/v2/boekhouding/activaregister/activeren';
import {
  DXTableCheckboxComponent,
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
  DXTableToggleCellComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import {
  DataTypeProvider,
  EditingState,
  IntegratedSorting,
  RowDetailState,
  SelectionState,
  SortingState,
  VirtualTable as VirtualTableBase,
} from '@devexpress/dx-react-grid';
import MenuLayout from '../../../../components/MenuLayout';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import {
  Grid,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import { format } from 'date-fns';
import FormatteerBedrag from '../../../../components/MutatieBedrag';
import _ from 'lodash';
import { EResultType } from '../../../../stores/CheckStore';
import { RootStoreContext } from '../../../../stores/RootStore';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import SelectieVak from '../../../../components/SelectieVak';
import RelatieVisualisatie from '../../../../components/personalia/RelatieVisualisatie';
import DownloadKnop from '../../../../components/DownloadKnop';
import { IOphalenProductenResultElementV2 } from '../../../../../../shared/src/api/v2/product';
import { IOphalenFacturenResultElement } from '../../../../../../shared/src/api/v2/inkoopfactuur';
import { IOphalenOpdrachtenResultElement } from '../../../../../../shared/src/api/v2/inkoop/opdracht';
import FilterBalkV2, {
  IFilter,
  IFilterData,
  maakFilterSchema,
} from '../../../../components/FilterBalkV2';
import InkoopfactuurVisualisatie from '../../../../components/entiteitVisualisaties/InkoopfactuurVisualisatie';
import { Helmet } from 'react-helmet';

interface IProps extends RouteComponentProps {}

enum EFilter {
  ActiverenMogelijk = 'ACTIVEREN_MOGELIJK',
}

interface IUrlState {
  selectie: number[];
  uitgeklapt: number[];
  filterdata: IFilterData<EFilter>[];
}

const defaultUrlState: IUrlState = {
  selectie: [],
  uitgeklapt: [],
  filterdata: [
    {
      naam: EFilter.ActiverenMogelijk,
      isActief: true,
      data: true,
    },
  ],
};

export interface IRegel extends IOphalenProductenResultElementV2 {
  inkoopdata: {
    Inkoopprijs: number;
    factuur: IOphalenFacturenResultElement;
  } | null;
}

const Activeren: React.FC<IProps> = (props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState<IUrlState>(props, defaultUrlState);
  const { checkStore } = useContext(RootStoreContext);

  const [productenTeActiveren, setProductenTeActiveren] = useState<IRegel[] | null>(null);
  const [inkoopopdrachten, setInkoopopdrachten] = useState<
    IOphalenOpdrachtenResultElement[] | null
  >(null);
  const [filterSchema, setFilterSchema] = useState(maakFilterSchema(urlState.filterdata));

  const ophalenTeActiverenProducten = useCallback(async () => {
    const productenResult = (
      await api.v2.product.ophalenProductenV2({
        filterSchema: {
          filters: [
            { naam: 'NIET_ACTIVEREN', data: false },
            { naam: 'IS_GEACTIVEERD', data: false },
            { naam: 'IS_ZOEK', data: false },
            { naam: 'DATUM_IN_BEDRIJF_VANAF', data: '2015-01-01' },
          ],
        },
        orderSchema: { orders: [{ naam: 'DATUM_IN_BEDRIJF', richting: 'DESC' }] },
      })
    ).producten;

    if (productenResult.length === 0) {
      setProductenTeActiveren([]);
      return;
    }

    const inkoopdataResult = (
      await api.v2.boekhouding.activaregister.activeren.ophalenInkoopprijsProducten({
        prodIDs: productenResult.map((x) => x.ProdID),
      })
    ).inkoopdata;

    const inkFactIDs = _.uniq(inkoopdataResult.map((x) => x.InkFactID));

    const facturen: IOphalenFacturenResultElement[] = (
      await api.v2.inkoopfactuur.ophalenFacturen({
        filterSchema: { filters: [{ naam: 'IDS', data: inkFactIDs }] },
      })
    ).facturen;

    const producten = productenResult.map((product) => {
      const inkoopdataVoorProduct =
        inkoopdataResult.find((y) => y.ProdID === product.ProdID) ?? null;

      const inkoopdata =
        inkoopdataVoorProduct !== null
          ? {
              Inkoopprijs: inkoopdataVoorProduct.Inkoopprijs,
              factuur: facturen.find((x) => x.InkFactID === inkoopdataVoorProduct.InkFactID)!,
            }
          : null;

      return { ...product, inkoopdata };
    });

    // Extra filter op activeren mogelijk
    const filterData = urlState.filterdata.find((x) => x.naam === EFilter.ActiverenMogelijk)!;
    if (filterData.isActief) {
      const filterResult = producten.filter(
        (x) => x.inkoopdata !== null && x.inkoopdata.factuur.ProductenGekoppeld,
      );
      setProductenTeActiveren(filterResult);
      return;
    }

    setProductenTeActiveren(producten);
  }, [filterSchema, JSON.stringify(urlState.filterdata)]);

  useEffect(() => {
    ophalenTeActiverenProducten();
  }, [ophalenTeActiverenProducten]);

  const ophalenInkoopopdrachten = useCallback(async () => {
    if (productenTeActiveren === null) {
      return;
    }

    if (productenTeActiveren.length === 0) {
      setInkoopopdrachten([]);
      return;
    }

    const inkoopopdrachtenResult = await api.v2.inkoop.opdracht.ophalenOpdrachten({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: [
              _.uniq(
                productenTeActiveren.map(
                  (x) => x.inkoopopdracht !== null && x.inkoopopdracht.InkOpdID,
                ),
              ),
            ],
          },
        ],
      },
    });

    setInkoopopdrachten(inkoopopdrachtenResult.inkoopopdrachten);
  }, [productenTeActiveren]);

  useEffect(() => {
    ophalenInkoopopdrachten();
  }, [ophalenInkoopopdrachten]);

  const handleActiveren = useCallback(async () => {
    if (productenTeActiveren === null) {
      return;
    }

    const params = {
      producten: productenTeActiveren
        .filter((x) => urlState.selectie.indexOf(x.ProdID) !== -1)
        .map((x) => {
          return {
            prodID: x.ProdID,
            bedrag: x.inkoopdata !== null ? x.inkoopdata.Inkoopprijs : null,
            datumGeactiveerd: x.DatumInBedrijf,
          };
        }),
    };

    const checkData = await api.v2.boekhouding.activaregister.activeren.checkActiverenProducten(
      params,
    );
    const controlerenResult = await checkStore.controleren({
      checkData,
    });
    if (controlerenResult.type === EResultType.Annuleren) {
      return;
    }

    if (
      (
        await checkStore.bevestigen({
          inhoud: 'Geselecteerde producten activeren?',
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }
    await api.v2.boekhouding.activaregister.activeren.activerenProducten(params);

    setUrlStateSync('selectie', []);
    ophalenTeActiverenProducten();
  }, [urlState.selectie, productenTeActiveren]);

  const keyExtractor = useCallback((rij: IOphalenProductenResultElement) => rij.ProdID, []);
  const kolommen = useMemo<TypedColumn<IOphalenProductenResultElementV2>[]>(
    () => [
      {
        name: '__merknaam' as any,
        title: 'Merk',
        getCellValue: (x) => {
          return x.producttype.Merknaam;
        },
      },
      {
        name: '__typenaam' as any,
        title: 'Type',
        getCellValue: (x) => {
          return x.producttype.Typenaam;
        },
      },
      {
        name: '__productsoortnaamKort' as any,
        title: 'Cat.',
        getCellValue: (x) => {
          return x.producttype.ProductsoortnaamKort;
        },
      },
      {
        name: 'DatumInBedrijf' as any,
        title: 'In bedrijf',
        getCellValue: (x) => {
          return format(new Date(x.DatumInBedrijf), 'dd-MM-yyyy');
        },
      },
      {
        name: '__relatie' as any,
        title: 'Leverancier',
      },
      {
        name: '__inkoopopdrachtReferentie' as any,
        title: 'Ink.ref.',
      },
      {
        name: '__inkoopprijs' as any,
        title: 'Inkoopprijs',
      },
      {
        name: '__factuurnummer' as any,
        title: 'Fact.nr',
      },
      {
        name: '__factuurdatum' as any,
        title: 'Fact.datum',
      },
      {
        name: '__gekoppeld' as any,
        title: 'Factuur volledig gekoppeld',
      },
      // {
      //   name: '__downloadActie' as any,
      //   title: ' ',
      // },
      // {
      //   name: '__inkoopreferentie' as any,
      //   title: 'Ink.ref.',
      //   getCellValue: (x) => {
      //     return <span>ink ref</span>;
      //   },
      // },
      // {
      //   name: '__leverancier' as any,
      //   title: 'Leverancier',
      //   getCellValue: (row) => {
      //     return row.weergavenaamRelatie;
      //   },
      // },
    ],
    [],
  );
  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IOphalenProductenResultElementV2>[]>(
    () => [
      {
        columnName: '__merknaam' as any,
        width: 125,
      },
      {
        columnName: '__typenaam' as any,
        width: 175,
      },
      {
        columnName: '__productsoortnaamKort' as any,
        width: 90,
      },
      {
        columnName: 'DatumInBedrijf',
        width: 125,
      },
      {
        columnName: '__inkoopprijs' as any,
        width: 125,
      },
      {
        columnName: '__factuurnummer' as any,
        width: 125,
      },
      {
        columnName: '__factuurdatum' as any,
        width: 125,
      },
      {
        columnName: '__inkoopopdrachtReferentie' as any,
        width: 100,
      },
      {
        columnName: '__relatie' as any,
        width: 150,
      },
      {
        columnName: '__gekoppeld' as any,
        width: 250,
      },
      // {
      //   columnName: '__downloadActie',
      //   width: 50,
      // },
      // {
      //   columnName: '__inkoopreferentie',
      //   width: 150,
      // },
    ],
    [],
  );
  const kolomExtensies: VirtualTableBase.ColumnExtension[] = useMemo(() => {
    return [
      // {
      //   columnName: 'Bedrag',
      //   align: 'right',
      // },
    ];
  }, []);

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.ActiverenMogelijk,
        altijdWeergevenInBalk: true,
        actiefMuteerbaar: true,
        weergave: (weergaveProps) => {
          return <span>Alleen waarvoor factuur volledig gekoppeld is</span>;
        },
      },
    ],
    [],
  );

  return (
    <>
      <Helmet>
        <title>Activeren</title>
      </Helmet>
      <MenuLayout
        menu={
          <>
            <div className="d-flex">
              <div className="d-flex align-items-center">
                <button
                  className="btn btn-sm btn-light d-flex align-items-center"
                  style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                  disabled={urlState.selectie.length === 0}
                  onClick={() => handleActiveren()}
                >
                  {/* <IconVerwijderen style={{ width: 16, height: 16, fill: Kleur.Grijs }} /> */}
                  <span className="ml-2">Activeren producten</span>
                </button>
              </div>

              <div className="d-flex flex-fill ml-2">
                <FilterBalkV2
                  filters={filters}
                  filterData={urlState.filterdata}
                  onFilterDataChange={(x) => setUrlStateSync('filterdata', x)}
                  onFilterSchemaChange={setFilterSchema}
                />
              </div>
            </div>
            {productenTeActiveren !== null && (
              <div className="mt-2 d-flex align-items-center">
                <SelectieVak
                  totaalAantal={productenTeActiveren.length}
                  aantal={urlState.selectie.length}
                  entiteitEnkelvoud="tarief"
                  entiteitMeervoud="tarieven"
                  onChange={(allesGeselecteerd) => {
                    if (allesGeselecteerd) {
                      setUrlStateSync(
                        'selectie',
                        productenTeActiveren!.map((x) => x.ProdID),
                      );
                    } else {
                      setUrlStateSync('selectie', []);
                    }
                  }}
                />
                {/* <span className="ml-2">Totaal {producten!.length} tarieven</span> */}
              </div>
            )}
          </>
        }
        body={
          productenTeActiveren === null || inkoopopdrachten === null ? (
            <div className="flex-fill d-flex align-items-center justify-content-center">
              <LoadingSpinner />
            </div>
          ) : (
            <GridStyleWrapper height={'calc(100vh - 200px)'}>
              <Grid rows={productenTeActiveren} columns={kolommen} getRowId={keyExtractor}>
                <DataTypeProvider
                  for={['__productsoortnaamKort']}
                  formatterComponent={(props) => {
                    const rij: IOphalenProductenResultElementV2 = props.row;
                    return <span>{rij.producttype.productsoort.NaamKort}</span>;
                  }}
                />

                {/* <DataTypeProvider
                for={['Bedrag']}
                formatterComponent={(props) => {
                  const rij: IOphalenProductenResultElementV2 = props.row;
                  if (rij.facturen.length > 1) {
                    return <span></span>;
                  }
                  return <MutatieBedrag bedrag={rij.facturen[0].Bedrag} />;
                }}
              /> */}

                <DataTypeProvider
                  for={['__inkoopopdrachtReferentie']}
                  formatterComponent={(props) => {
                    const rij: IOphalenProductenResultElementV2 = props.row;

                    const inkoopopdracht =
                      rij.inkoopopdracht !== null
                        ? inkoopopdrachten.find((x) => x.InkOpdID === rij.inkoopopdracht!.InkOpdID)
                        : null;

                    if (inkoopopdracht === null) {
                      return <span></span>;
                    }
                    return <span>{inkoopopdracht?.Referentie}</span>;
                  }}
                />

                <DataTypeProvider
                  for={['__inkoopprijs']}
                  formatterComponent={(props) => {
                    const rij: IRegel = props.row;

                    if (rij.inkoopdata === null) {
                      return <span></span>;
                    }
                    if (!rij.inkoopdata.factuur.ProductenGekoppeld) {
                      return (
                        <span className="d-flex">
                          (<FormatteerBedrag bedrag={rij.inkoopdata.Inkoopprijs} />)
                        </span>
                      );
                    }

                    return <FormatteerBedrag bedrag={rij.inkoopdata.Inkoopprijs} />;
                  }}
                />

                <DataTypeProvider
                  for={['__factuurnummer']}
                  formatterComponent={(props) => {
                    const rij: IRegel = props.row;

                    if (rij.inkoopdata === null) {
                      return <span></span>;
                    }
                    return (
                      <InkoopfactuurVisualisatie inkFactID={rij.inkoopdata.factuur.InkFactID} />
                    );
                  }}
                />

                <DataTypeProvider
                  for={['__factuurdatum']}
                  formatterComponent={(props) => {
                    const rij: IRegel = props.row;

                    if (rij.inkoopdata === null) {
                      return <span></span>;
                    }
                    return (
                      <span>
                        {format(new Date(rij.inkoopdata.factuur.Factuurdatum), 'dd-MM-yyyy')}
                      </span>
                    );
                  }}
                />

                <DataTypeProvider
                  for={['__relatie']}
                  formatterComponent={(props) => {
                    const rij: IRegel = props.row;
                    const dienst = rij.inkoopopdracht !== null ? rij.inkoopopdracht.dienst : null;

                    if (rij.inkoopdata === null) {
                      // Als de crediteur nog niet bekend is dan de leverancier nemen
                      return dienst !== null ? (
                        <RelatieVisualisatie relID={dienst.RelID} />
                      ) : (
                        <span></span>
                      );
                    }

                    return <RelatieVisualisatie relID={rij.inkoopdata.factuur.RelID} />;
                  }}
                />

                <DataTypeProvider
                  for={['__downloadActie']}
                  formatterComponent={(props) => {
                    const rij = props.row as IOphalenProductenResultElement;

                    if (rij.facturen.length !== 1) {
                      return <span></span>;
                    }
                    const bestand =
                      rij.facturen[0].bestand !== null ? rij.facturen[0].bestand : null;
                    if (bestand === null) {
                      return <span></span>;
                    }

                    return (
                      <DownloadKnop
                        onDownloadAangevraagd={async () => {
                          window.open(bestand!.url, '_blank');
                        }}
                      />
                    );
                  }}
                />

                {/* <DataTypeProvider
                for={['__gekoppeld']}
                formatterComponent={(props) => {
                  const rij: IOphalenProductenResultElementV2 = props.row;
                  return <span>{}</span>;
                }}
              /> */}

                <DataTypeProvider
                  for={['__gekoppeld']}
                  formatterComponent={(props) => {
                    const rij: IRegel = props.row;

                    if (rij.inkoopdata === null) {
                      return <span></span>;
                    }
                    return <span>{rij.inkoopdata.factuur.ProductenGekoppeld ? 'Ja' : 'Nee'}</span>;
                  }}
                />

                <SortingState defaultSorting={[]} />
                <IntegratedSorting />

                <RowDetailState
                  expandedRowIds={urlState.uitgeklapt}
                  onExpandedRowIdsChange={(x) => setUrlStateSync('uitgeklapt', x as number[])}
                />

                <VirtualTable
                  columnExtensions={kolomExtensies}
                  messages={{ noData: 'Geen producten gevonden' }}
                />
                <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
                <TableHeaderRow showSortingControls />

                <EditingState
                  onCommitChanges={(changes) => {
                    if (changes.deleted === undefined) {
                      return;
                    }
                    const deleted = changes.deleted;
                    const id = deleted[deleted.length - 1];
                    alert(id);
                  }}
                  // onEditingRowIdsChange={(rowIds) => {
                  //   const id = rowIds[rowIds.length - 1] as number;
                  //   props.onWijzigenOpdrachtDialoogStateChange({ trsOpdID: id });
                  //   // alert(id);
                  // }}
                />

                {/* <TableEditColumn
                width={35}
                showEditCommand
                cellComponent={DXTableEditColumnCellComponent}
                commandComponent={DXTableEditColumnCommandComponent}
              /> */}

                {/* <TableRowDetail
                contentComponent={DetailComp}
                toggleCellComponent={DXTableToggleCellComponent}
              /> */}

                <SelectionState
                  selection={urlState.selectie}
                  onSelectionChange={(x) => setUrlStateSync('selectie', x as number[])}
                />
                <TableSelection cellComponent={DXTableCheckboxComponent} />
              </Grid>
            </GridStyleWrapper>
          )
        }
      />
    </>
  );
};

export default withRouter(Activeren);
