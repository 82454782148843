import { Field, FieldProps, Formik, FormikActions, FormikErrors, FormikProps } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useContext, useMemo, useRef } from 'react';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import * as Yup from 'yup';
import ITaalTekst from '../../../../../../../shared/src/models/talen/ITaalTekst';
import api from '../../../../../api';
import teksten from '../../../../../bedrijfslogica/teksten';
import Dialoog from '../../../../../components/dialogen/Dialoog';
import MeertaligTekstveld from '../../../../../components/formulier/MeertaligTekstveld';
import IDialoogProps from '../../../../../core/IDialoogProps';
import nameof from '../../../../../core/nameOf';
import { dagDatum } from '../../../../../helpers/datum';
import { EResultType } from '../../../../../stores/CheckStore';
import { RootStoreContext } from '../../../../../stores/RootStore';

interface IDialoogResult {}

interface IProps extends IDialoogProps<IDialoogResult> {}

interface IFormikValues {
  naam: ITaalTekst[];
  subtitel: ITaalTekst[];
}

const veldnamen: Record<keyof IFormikValues, string> = {
  naam: 'Naam',
  subtitel: 'Subtitel',
};

const ToevoegenDialoog: React.FC<IProps> = observer((props) => {
  const { children, dialoogIndex, onAnnuleren, onSuccess } = props;
  const { checkStore } = useContext(RootStoreContext);
  const formikRef = useRef<Formik<IFormikValues>>(null);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      const params = { Datum: dagDatum(new Date()), Naam: values.naam, Subtitel: values.subtitel };

      const checkData = await api.v2.blog.checkToevoegenBlogCategorie({});
      const checkResult = await checkStore.controleren({
        checkData,
      });
      if (checkResult.type === EResultType.Annuleren) {
        onAnnuleren();
        return;
      }

      await api.v2.blog.toevoegenBlogCategorie(params);

      props.onSuccess({});
      actions.setSubmitting(false);
    },
    [],
  );

  const initialValues = useMemo<IFormikValues>(() => {
    return {
      naam: [],
      subtitel: [],
    };
  }, []);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        ID: Yup.string().required(),
      }),
    [],
  );

  const validate = useCallback((values: IFormikValues) => {
    const errors: FormikErrors<IFormikValues> = {};
    if (values.naam.length === 0) {
      errors.naam = teksten.formulier.E_VERPLICHT_VELD({ veldnaam: 'Naam' }) as any;
    }
    return errors;
  }, []);

  return (
    <Dialoog index={dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Toevoegen Categorie</ModalTitle>
      </ModalHeader>
      {initialValues !== null && (
        <Formik<IFormikValues>
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validate={validate}
          enableReinitialize
          render={(formikProps: FormikProps<IFormikValues>) => {
            const { submitForm, isValid, isSubmitting } = formikProps;

            return (
              <>
                <ModalBody>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-12 mt-3">
                        <label>Naam</label>
                        <Field
                          name={nameof<IFormikValues>('naam')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            return (
                              <MeertaligTekstveld
                                waarden={fieldProps.field.value}
                                onChange={(x) =>
                                  fieldProps.form.setFieldValue(fieldProps.field.name, x)
                                }
                              />
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <label>Subtitel</label>
                        <Field
                          name={nameof<IFormikValues>('subtitel')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            return (
                              <MeertaligTekstveld
                                waarden={fieldProps.field.value}
                                onChange={(x) =>
                                  fieldProps.form.setFieldValue(fieldProps.field.name, x)
                                }
                              />
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </ModalBody>

                <ModalFooter className="d-flex flex-row justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    style={{ width: 100 }}
                    disabled={!isValid || isSubmitting}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={onAnnuleren}
                    style={{ width: 100 }}
                    disabled={isSubmitting}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Dialoog>
  );
});

export default ToevoegenDialoog;
