import React, { useCallback, useMemo, useState } from 'react';
import IDialoogProps from '../../../../../../../core/IDialoogProps';
import Dialoog from '../../../../../../../components/dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/esm/ModalHeader';
import { ModalTitle, ModalBody, ModalFooter } from 'react-bootstrap';
import TelefoonnummerInput from '../../../../../../../components/formulier/TelefoonnummerInput';
import LoadingSpinner from '../../../../../../../components/Gedeeld/LoadingSpinner';

export interface IDialoogResult {
  telefoonnummer: string;
  label: string | null;
}

interface IProps extends IDialoogProps<IDialoogResult> {
  telefoonnummer?: string;
  label?: string | null;
}

const HandmatigOpvoerenDialoog = (props: IProps) => {
  const [isBezig, setIsBezig] = useState(false);
  const [telefoonnummer, setTelefoonnummer] = useState<string | null>(props.telefoonnummer ?? null);
  const [label, setLabel] = useState<string | null>(props.label ?? null);

  const isFormulierGeldig = useMemo(() => telefoonnummer !== null, [telefoonnummer]);

  const handleSubmit = useCallback(async () => {
    setIsBezig(true);

    props.onSuccess({
      telefoonnummer: telefoonnummer!,
      label: label === null ? null : label.trim(),
    });

    setIsBezig(false);
  }, [setIsBezig, telefoonnummer, label]);

  return (
    <Dialoog index={props.dialoogIndex ?? 0}>
      <ModalHeader>
        <ModalTitle>Selecteer een afdeling van een organisatie</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <div className="form-group">
          <label>Telefoonnummer</label>
          <TelefoonnummerInput telefoonnummer={telefoonnummer} onChange={setTelefoonnummer} />
          <label className="mt-3">Label</label>
          <input
            className="form-control"
            type="text"
            value={label ?? ''}
            onChange={(ev) => {
              const value = ev.target.value.length === 0 ? null : ev.target.value;
              setLabel(value);
            }}
          />
        </div>
      </ModalBody>
      <ModalFooter className="d-flex flex-row justify-content-start">
        <button
          className="btn btn-primary d-flex align-items-center justify-content-center"
          onClick={handleSubmit}
          style={{ width: 100 }}
          disabled={isBezig || !isFormulierGeldig}
        >
          {isBezig && (
            <span className="mr-2">
              <LoadingSpinner soort="light" grootte="15px" dikte="2px" />
            </span>
          )}
          Ok
        </button>
        <button
          className="btn btn-secondary"
          onClick={props.onAnnuleren}
          style={{ width: 100 }}
          disabled={isBezig}
        >
          Annuleren
        </button>
      </ModalFooter>
    </Dialoog>
  );
};

export default HandmatigOpvoerenDialoog;
