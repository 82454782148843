import React, { useMemo, useRef } from 'react';
import MultiComboboxV2, { IOverlayContainerProps, IOverlayOptions, IProps } from '../index';
import Zoeken from '../../Zoeken';
import useBijGewijzigdEffect from '../../../../core/useBijGewijzigdEffect';

// type Props<TId extends string | number, TKey extends keyof any, TEntity> = Omit<
//   IProps<TId, TKey, TEntity, IOverlayState>,
//   'overlayOptions'
// >;
type Props<TId extends string | number, TKey extends keyof any, TEntity> = IProps<
  TId,
  TKey,
  TEntity,
  IMultiComboboxV2MetZoekenOverlayState
>;

export interface IMultiComboboxV2MetZoekenOverlayState {
  zoekterm: string;
}

const MultiComboboxV2MetZoeken = <TId extends string | number, TKey extends keyof any, TEntity>(
  props: Props<TId, TKey, TEntity>,
) => {
  const overlayOptions = useMemo(
    () => ({
      ...props.overlayOptions,
      overlayContainer: {
        overlayContainerComponent: OverlayContainer,
        initialState: { zoekterm: '' },
      },
    }),
    [props.overlayOptions],
  );

  return <MultiComboboxV2 {...props} overlayOptions={overlayOptions} />;
};

const OverlayContainer = (
  props: IOverlayContainerProps<IMultiComboboxV2MetZoekenOverlayState, any, any>,
) => {
  const zoekenDebounceTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  useBijGewijzigdEffect(() => {
    if (zoekenDebounceTimeoutRef.current !== null) {
      clearTimeout(zoekenDebounceTimeoutRef.current);
    }
    zoekenDebounceTimeoutRef.current = setTimeout(() => {
      // noinspection JSIgnoredPromiseFromCall
      props.rijbronHerbepalen();
    }, 250);
  }, [props.state.zoekterm]);
  //
  return (
    <div className="flex-fill d-flex flex-column">
      <div className="p-2">
        <Zoeken
          input={props.state.zoekterm}
          onInputChange={(x) =>
            props.onStateChange((prev) => ({
              ...prev,
              zoekterm: x.trim(),
            }))
          }
          autoFocus
        />
      </div>
      <div className="flex-fill d-flex">{props.children}</div>
    </div>
  );
};

export default MultiComboboxV2MetZoeken;
