import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import IDialoogProps from '../../../../../../../../core/IDialoogProps';
import Dialoog from '../../../../../../../../components/dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import * as Yup from 'yup';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../../../../../../stores/RootStore';
import Combobox from '../../../../../../../../components/formulier/Combobox';
import nameOf from '../../../../../../../../core/nameOf';
import { IOphalenDienstenResultElement } from '../../../../../../../../../../shared/src/api/v2/dienst/incasso';
import api from '../../../../../../../../api';
import { Kleur } from '../../../../../../../../bedrijfslogica/constanten';
import LoadingSpinner from '../../../../../../../../components/Gedeeld/LoadingSpinner';
import { EResultType } from '../../../../../../../../stores/CheckStore';
import BedragInput from '../../../../../../../../components/formulier/BedragInput';
import { IOphalenIncassozakenResultElement } from '../../../../../../../../../../shared/src/api/v2/debiteur/incassozaak';
import Skeleton from 'react-loading-skeleton';
import MultiCombobox, { IKolom } from '../../../../../../../../components/formulier/MultiCombobox';
import { format } from 'date-fns';
import VinkVeld from '../../../../../../../../components/formulier/VinkVeld';
import { EIncassozaakStatus } from '../../../../../../../../bedrijfslogica/enums';

interface IDialoogResult {}

interface IProps extends IDialoogProps<IDialoogResult> {
  relID: number;
  factIDs: number[];
}

interface IFormikValues {
  incDienstID: number;
  incZaakID: number | null;
  directVersturen: boolean;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  incDienstID: 'Dienst',
  incZaakID: 'Nieuw of toevoegen aan incassozaak',
  directVersturen: 'Direct versturen',
};

const ToevoegenIncassozaakDialoog: React.FC<IProps> = observer((props) => {
  const { children, dialoogIndex, onAnnuleren, onSuccess, open, factIDs } = props;
  const { checkStore, klantkaartStore } = useContext(RootStoreContext);

  const [diensten, setDiensten] = useState<IOphalenDienstenResultElement[] | null>(null);
  const [dienstIDGeselecteerd, setDienstIDGeselecteerd] = useState<number | null>(null);
  const [incassozaken, setIncassozaken] = useState<IOphalenIncassozakenResultElement[] | null>(
    null,
  );
  const [incassozaakIDGeselecteerd, setIncassozaakIDGeselecteerd] = useState<number | null>(null);

  useEffect(() => {
    (async () => {
      const result = await api.v2.dienst.incasso.ophalenDiensten({
        filterSchema: { filters: [{ naam: 'IS_ACTIEF', data: true }] },
      });
      setDiensten(result.diensten);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (diensten === null) {
        return;
      }
      setDienstIDGeselecteerd(diensten[0].ID);
    })();
  }, [diensten]);

  useEffect(() => {
    (async () => {
      if (dienstIDGeselecteerd === null) {
        return;
      }

      const result = await api.v2.debiteur.incassozaak.ophalenIncassozaken({
        filterSchema: {
          filters: [
            { naam: 'INCASSODIENST_IDS', data: [dienstIDGeselecteerd] },
            { naam: 'REL_IDS', data: [props.relID] },
          ],
        },
        orderSchema: { orders: [{ naam: 'ZAAKNUMMER', richting: 'DESC' }] },
      });

      setIncassozaken(result.incassozaken);
    })();
  }, [dienstIDGeselecteerd, props.relID]);

  useEffect(() => {
    (async () => {
      if (incassozaken === null) {
        return;
      }
      setIncassozaakIDGeselecteerd(incassozaken.length !== 0 ? incassozaken[0].ID : null);
    })();
  }, [incassozaken]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      const params = {
        relID: props.relID,
        factIDs: props.factIDs,
        incDienstID: values.incDienstID,
        incZaakID: values.incZaakID !== null ? values.incZaakID : undefined,
      };

      const checkData = await api.v2.debiteur.incassozaak.checkSelectieToevoegenIncassozaak(params);
      const checkResult = await checkStore.controleren({
        checkData,
      });
      if (checkResult.type === EResultType.Annuleren) {
        actions.setSubmitting(false);
        return;
      }

      const inhoud = (
        <span>
          {values.incZaakID !== null
            ? 'Wil je de geselecteerde facturen toevoegen aan de geselecteerde zaak?'
            : 'Wil je voor de geselecteerde facturen een nieuwe zaak maken ?'}
        </span>
      );

      if (
        (
          await checkStore.bevestigen({
            inhoud,
          })
        ).type === EResultType.Annuleren
      ) {
        actions.setSubmitting(false);
        return;
      }

      const result = await api.v2.debiteur.incassozaak.toevoegenIncassozaak(params);

      if (values.directVersturen) {
        await api.v2.debiteur.incassozaak.versturenIncassoOpdrachten({ ids: [result.ID] });

        await api.v2.debiteur.incassozaak.wijzigenDossiersstatus({
          ids: [result.ID],
          status: EIncassozaakStatus.Uithanden,
        });
      }

      props.onSuccess({});
      actions.setSubmitting(false);
    },
    [props.relID, props.factIDs],
  );

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (dienstIDGeselecteerd === null) {
      return null;
    }

    return {
      incDienstID: dienstIDGeselecteerd,
      incZaakID: incassozaakIDGeselecteerd,
      directVersturen: true,
    };
  }, [dienstIDGeselecteerd, incassozaakIDGeselecteerd]);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        incDienstID: Yup.number().required(),
      }),
    [],
  );

  const incassozakenKolommen = useMemo<IKolom<IOphalenIncassozakenResultElement>[]>(() => {
    return [
      {
        key: 'Zaaknummer',
        label: 'Zaaknr.',
        breedte: 150,
      },
      {
        key: 'Zaakdatum',
        label: 'Datum',
        breedte: 150,
        formatFabriek: (x) => format(new Date(x.Zaakdatum), 'dd-MM-yyyy'),
      },
    ];
  }, []);

  return (
    <Dialoog index={dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Nieuwe incassozaak</ModalTitle>
      </ModalHeader>
      {initialValues !== null && (
        <Formik<IFormikValues>
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize
          isInitialValid
          render={(formikProps: FormikProps<IFormikValues>) => {
            const { submitForm, isValid, isSubmitting } = formikProps;

            return (
              <>
                <ModalBody>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-12">
                        <label>{veldnamen.incDienstID}</label>
                        <Field
                          name={nameOf<IFormikValues>('incDienstID')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            if (diensten === null) {
                              return <LoadingSpinner />;
                            }

                            return (
                              <Combobox
                                geselecteerd={field.value}
                                opties={diensten.map((dienst) => {
                                  return {
                                    id: dienst.ID,
                                    label: dienst.Leveranciernaam,
                                  };
                                })}
                                onSelectieChange={(x) => {
                                  setDienstIDGeselecteerd(x);
                                  form.setFieldValue(field.name, x);
                                }}
                              />
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <label>{veldnamen.incZaakID}</label>
                        <Field
                          name={nameOf<IFormikValues>('incZaakID')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            if (incassozaken === null) {
                              return <Skeleton />;
                            }

                            return (
                              <MultiCombobox<number, IOphalenIncassozakenResultElement>
                                sleutelExtractor={(row) => row.ID}
                                onWaardeChange={(x) => {
                                  setIncassozaakIDGeselecteerd(x);
                                  form.setFieldValue(field.name, x);
                                }}
                                representatieFabriek={(row) =>
                                  `#${row.Zaaknummer} - ${format(
                                    new Date(row.Zaakdatum),
                                    'dd-MM-yyyy',
                                  )}`
                                }
                                waarde={field.value}
                                opties={incassozaken}
                                kolommen={incassozakenKolommen}
                                isWisbaar
                                options={{ geenWaardeBericht: 'Nieuwe incassozaak aanmaken' }}
                              />
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <Field
                          name={nameOf<IFormikValues>('directVersturen')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <>
                                <span className="d-flex align-items-center">
                                  <VinkVeld
                                    aangevinkt={field.value}
                                    onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                  />
                                  <span className="ml-2">{veldnamen.directVersturen}</span>
                                </span>
                              </>
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </ModalBody>

                <ModalFooter className="d-flex flex-row justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    style={{ width: 100 }}
                    disabled={!isValid || isSubmitting}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={onAnnuleren}
                    style={{ width: 100 }}
                    disabled={isSubmitting}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Dialoog>
  );
});

export default ToevoegenIncassozaakDialoog;
