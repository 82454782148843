import { IOphalenBtwTarievenResultElement } from '../../../../../../../shared/src/api/v2/btw';
import api from '../../../../../api';

export interface IBtwTarievenProvider {
  provide: () => Promise<IOphalenBtwTarievenResultElement[]>;
}

export const standaardBtwTarievenProvider: IBtwTarievenProvider = {
  provide: async () => {
    const result = await api.v2.btw.ophalenBtwTarieven({});
    return result.btwTarieven;
  },
};
