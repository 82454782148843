import { Formik } from 'formik';
import React, { useCallback, useContext, useState, useMemo, useEffect } from 'react';
import { ModalFooter, ModalTitle } from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/ModalHeader';
import api from '../../../api';
import IDialoogProps from '../../../core/IDialoogProps';
import Dialoog from '../../dialogen/Dialoog';
import _ from 'lodash';
import LoadingSpinner from '../../Gedeeld/LoadingSpinner';
import Tabblad, { ITabblad } from '../../layout/Tabblad';
import ProductmodelTab from './ProductmodelTab';
import AfbeeldingTab from './AfbeeldingTab';
import { IOphalenProductmodellenResultElement } from '../../../../../shared/src/api/v2/aanbod/productmodel';
import { IOphalenParams, IOphalenResult } from '../../../../../shared/src/api/v2/bedrijf';
import ProducttypenTab from './ProducttypenTab';
import TechnischeInformatieKnop from '../../TechnischeInformatieKnop';

interface IProps extends IDialoogProps<{}> {
  prodModID: number;
}

enum ETablad {
  Productmodel,
  Producttypen,
  Afbeelding,
}

export interface IContext {
  productmodel: IOphalenProductmodellenResultElement | null;
  bedrijf: IOphalenResult | null;
}
export const ProductContext = React.createContext<IContext>({
  productmodel: null,
  bedrijf: null,
});

export interface IFormikValues {
  modelcode: string;
}

export const veldnamen: Record<keyof IFormikValues, string> = {
  modelcode: 'Modelcode',
};

const ProductmodelInfoDialoog: React.FC<IProps> = (props) => {
  const { dialoogIndex, onAnnuleren, onSuccess, open, prodModID } = props;

  const [tabblad, setTabblad] = useState<ETablad>(ETablad.Productmodel);
  const [productmodel, setProductmodel] = useState<IOphalenProductmodellenResultElement | null>(
    null,
  );

  const tabbladen = useMemo<ITabblad<ETablad>[]>(
    () => [
      {
        id: ETablad.Productmodel,
        label: 'Productmodel',
        content: ProductmodelTab,
      },
      {
        id: ETablad.Producttypen,
        label: 'Producttypen',
        content: ProducttypenTab,
      },
      {
        id: ETablad.Afbeelding,
        label: 'Afbeeldingen',
        content: AfbeeldingTab,
      },
    ],
    [onSuccess],
  );

  const [bedrijf, setBedrijf] = useState<IOphalenResult | null>(null);

  const ophalenBedrijf = useCallback(async () => {
    const bedrijf = await api.v2.bedrijf.ophalenBedrijf({});

    setBedrijf(bedrijf);
  }, []);

  useEffect(() => {
    ophalenBedrijf();
  }, [ophalenBedrijf]);

  // Productmodel gegegens ophalen
  const ophalenProductmodel = useCallback(async () => {
    const productenmodellenResult = await api.v2.product.model.ophalenProductmodellen({
      filterSchema: { filters: [{ naam: 'IDS', data: [prodModID] }] },
    });
    const productmodel = productenmodellenResult.modellen[0];
    setProductmodel(productmodel);
  }, [prodModID]);

  useEffect(() => {
    ophalenProductmodel();
  }, [ophalenProductmodel]);

  const initialFormikValues = useMemo<IFormikValues | null>(() => {
    if (productmodel === null || bedrijf === null) {
      return null;
    }

    return {
      modelcode: productmodel.Modelcode,
      instellingen: bedrijf,
    };
  }, [productmodel, bedrijf]);

  return (
    <Dialoog index={dialoogIndex || 0} modalProps={{ size: `lg` }}>
      <ModalHeader>
        <ModalTitle>Productmodelinformatie</ModalTitle>
      </ModalHeader>
      <ProductContext.Provider
        value={{
          productmodel,
          bedrijf,
        }}
      >
        <div className="d-flex flex-column flex-fill" style={{ minHeight: `650px` }}>
          {initialFormikValues === null ? (
            <div className="d-flex flex-fill align-items-center justify-content-center">
              <LoadingSpinner />
            </div>
          ) : (
            <>
              <Formik<IFormikValues>
                isInitialValid
                initialValues={initialFormikValues}
                onSubmit={() => null}
                render={(FormikProps) => {
                  const { submitForm, isSubmitting, values, errors } = FormikProps;
                  return (
                    <>
                      <div className="d-flex flex-column flex-fill">
                        <Tabblad
                          tabbladen={tabbladen}
                          geselecteerd={tabblad}
                          onSelectieChange={(id) => {
                            setTabblad(id);
                          }}
                        />
                      </div>
                      <ModalFooter className="d-flex flex-row justify-content-start">
                        <button
                          className="btn btn-primary"
                          onClick={onAnnuleren}
                          style={{ width: 100 }}
                        >
                          Sluiten
                        </button>
                        <div className="flex-fill" />
                        <TechnischeInformatieKnop
                          dialoogIndex={(props.dialoogIndex ?? 0) + 1}
                          content={() => <div>ProdModID: {props.prodModID}</div>}
                        />
                      </ModalFooter>
                    </>
                  );
                }}
              />
            </>
          )}
        </div>
      </ProductContext.Provider>
    </Dialoog>
  );
};

export default ProductmodelInfoDialoog;
