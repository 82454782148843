import React from 'react';
import { Kleur } from '../../../bedrijfslogica/constanten';

interface IProps {
  color?: string;
  width?: string | number;
  height?: string | number;
  className?: string;
}

const HorizontaleScheidingslijn = (props: IProps) => {
  const { color, height, width } = props;
  return (
    <div
      className={props.className}
      style={{
        borderTop: `${height || 1}px solid ${color || Kleur.LichtGrijs}`,
        // width: width || '100%',
        minWidth: width || '100%',
        // minHeight: height || 1,
      }}
    />
  );
};

export default HorizontaleScheidingslijn;
