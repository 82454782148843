import React, { useContext, useMemo, useState, useCallback } from 'react';
import { DashboardContext } from '../index';
import TegelBasis from '../../../components/tegel/TegelBasis';
import { ERemoteDataState } from '../../../models/IRemoteData';
import LoadingSpinner from '../../../components/Gedeeld/LoadingSpinner';
import * as _ from 'lodash';
import { format } from 'date-fns';
import GegevensLayout from '../../../components/layout/GegevensLayout';
import { locale } from 'moment';
import { nl } from 'date-fns/locale';
import {
  EFunctioneleIcon,
  functioneleIconMap,
  IconLink,
  IconMenuBook,
  IconKruis,
} from '../../../components/Icons';
import { Kleur as EKleur } from '../../../bedrijfslogica/constanten';
import api from '../../../api';
import { toast } from 'react-hot-toast';
import { RootStoreContext } from '../../../stores/RootStore';
import { EResultType } from '../../../stores/CheckStore';
import { genereerUrlStateQueryParam } from '../../../core/useUrlState';
import {
  IUrlState as IOpeningstijdUrlState,
  defaultUrlState as openingstijdDefaultUrlState,
} from '../../../paginas/Beheer/Instellingen/Applicatie';
import { RouteComponentProps, withRouter } from 'react-router-dom';

interface IProps extends RouteComponentProps {}

const IconDocumentatie = functioneleIconMap[EFunctioneleIcon.Documentatie];

const AlgemeneInfo: React.FC<IProps> = (props) => {
  const context = useContext(DashboardContext);
  const { checkStore } = useContext(RootStoreContext);

  const instellingen = context.instellingen.data!;
  const bedrijf = context.dashboardInfo.data!.bedrijf;
  const financieleInfo = context.financieleInfo;
  const website = bedrijf.website;
  const webURL = bedrijf.website.url;
  const intranetURL = bedrijf.IntranetURL;

  const algemeneVoorwaardenURL =
    website.Protocol +
    '://' +
    website.Domein +
    (website.Poort !== null ? ':' + website.Poort : '') +
    '/algemene-voorwaarden';

  const actieVoorwaardenURL =
    website.Protocol +
    '://' +
    website.Domein +
    (website.Poort !== null ? ':' + website.Poort : '') +
    '/actie-voorwaarden';

  const dagnaam = format(new Date(), 'EEEE', { locale: nl });
  const dagnummer = format(new Date(), 'd');
  const maandnaam = format(new Date(), 'MMMM', { locale: nl });
  const weeknummer = format(new Date(), 'I'); // ISO

  const lijst = (
    <>
      {/* {!instellingen.PremiumLeveren && <li>Er worden op dit moment geen premiums toegevoegd</li>}{' '} */}
      {!instellingen.website.InloggenToegestaan && (
        <li>Inloggen op de website is niet toegestaan</li>
      )}
    </>
  );

  const [isBezigMetCacheLegen, setIsBezigMetCacheLegen] = useState(false);

  const handleCacheLegenClick = useCallback(async () => {
    setIsBezigMetCacheLegen(true);

    if (
      (
        await checkStore.bevestigen({
          inhoud: (
            <span>
              Hiermee wordt de cache van de website geleegd waarmee de actuele waarden van
              toepassing worden.
              <br />
              <br />
              Cache legen?
            </span>
          ),
        })
      ).type === EResultType.Annuleren
    ) {
      setIsBezigMetCacheLegen(false);
      return;
    }

    try {
      await api.v2.extern.leegCache({});
    } catch (err) {
      toast.error('Cache van website legen mislukt (zie logs voor meer info)');
      setIsBezigMetCacheLegen(false);
      return;
    }

    setIsBezigMetCacheLegen(false);

    toast.success('Cache van website geleegd');
  }, []);

  const kleurOpeningsmodus = context.openingsinfo.data!.isOpen ? EKleur.Groen : EKleur.Rood;

  return (
    <TegelBasis titel={'Bedrijfsinformatie'}>
      <div className="row">
        <div className="col-4 d-flex mb-2">
          <div>
            {bedrijf.Bedrijfsnaam}
            <br />
            {bedrijf.Straatnaam} {bedrijf.Huisnummer} {bedrijf.Bisnummer}
            <br />
            {bedrijf.Postcode} {bedrijf.Plaatsnaam}
          </div>
        </div>

        <div className="col-4">
          KvK-nr: {bedrijf.KvKnummer}
          <br />
          Btw-nr: {bedrijf.Btwnummer}
          <br />
          {
            financieleInfo.data!.rekeningen.find(
              (x) => x.BankRekID === instellingen.DebiteurenBetalingen_BankRekID,
            )!.IBAN
          }
        </div>

        <div className="col-4">
          {bedrijf.TelefoonKlanten}
          <br />
          {bedrijf.EmailKlanten}
        </div>

        {/* <div className="col-2"></div> */}

        {/* <div>
          {dagnaam.charAt(0).toUpperCase() + dagnaam.slice(1)} {dagnummer} {maandnaam}, week{' '}
          {weeknummer}.
        </div> */}
      </div>

      <div className="flex-fill" />

      <div className="d-flex align-items-center">
        <span className="d-flex align-items-center">
          <IconLink style={{ width: 15, height: 15, fill: EKleur.Blauw, marginTop: 2 }} />
          <a href={webURL} target="_blank" className="ml-2">
            Website
          </a>
        </span>
        {intranetURL !== null && (
          <span className="d-flex align-items-center ml-3">
            <IconLink style={{ width: 15, height: 15, fill: EKleur.Blauw, marginTop: 2 }} />
            <a href={intranetURL} target="_blank" className="ml-2">
              Intranet
            </a>
          </span>
        )}
        <span className="d-flex align-items-center ml-3">
          <IconLink style={{ width: 15, height: 15, fill: EKleur.Blauw, marginTop: 2 }} />
          <a href={algemeneVoorwaardenURL} target="_blank" className="ml-2">
            AV
          </a>
        </span>
        <span className="d-flex align-items-center ml-3">
          <IconLink style={{ width: 15, height: 15, fill: EKleur.Blauw, marginTop: 2 }} />
          <a href={actieVoorwaardenURL} target="_blank" className="ml-2">
            Actievoorwaarden
          </a>
        </span>
        {/* <span className="d-flex align-items-center ml-3">
          <IconDocumentatie style={{ width: 15, height: 15, fill: EKleur.Blauw, marginTop: 2 }} />
          <a href={'https://splash-lease.gitbook.io/asp-systeem/'} target="_blank" className="ml-2">
            Help
          </a>
        </span> */}

        <div className="flex-fill" />

        <button
          className="d-flex align-items-center justify-content-center"
          style={{
            color: kleurOpeningsmodus,
            backgroundColor: 'transparent',
            border: `1px solid ${kleurOpeningsmodus}`,
            padding: '1px 7px',
          }}
          onClick={() => {
            const urlState: IOpeningstijdUrlState = {
              ...openingstijdDefaultUrlState,
              openingstijdUitgeklapt: true,
            };
            const query = genereerUrlStateQueryParam(urlState);
            const link = `/beheer/instellingen/applicatie?state=${query}`;
            props.history.push(link);
          }}
        >
          {context.openingsinfo.data!.isOpen ? 'Geopend' : 'Gesloten'}{' '}
          {instellingen.OpeningstijdenModus !== 0 ? '(handmatig)' : ''}
        </button>

        {/* <button
          className="btn btn-sm btn-light d-flex align-items-center justify-content-center"
          style={{
            border: `1px solid ${Kleur.LichtGrijs}`,
            padding: '1px 7px',
          }}
          onClick={handleCacheLegenClick}
          disabled={isBezigMetCacheLegen}
        >
          Cache legen van website
        </button> */}
      </div>
    </TegelBasis>
  );
};

export default withRouter(AlgemeneInfo);
