import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import MenuLayout from '../../../../components/MenuLayout';
import { IconToevoegen } from '../../../../components/Icons';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import { IVoetnoot } from '../../../../../../shared/src/api/v2/concurrent/vergelijkingspunt/voetnoot';
import api, { IPaginatiePositie } from '../../../../api';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../models/IRemoteData';
import ASPTabel, { VerwijderenRijConfirmatieFn } from '../../../../components/tabel/ASPTabel';
import { LoadingSpinnerCenter } from '../../../../components/Gedeeld/LoadingSpinner';
import { ASPKolom, EAspKolomBreedteType } from '../../../../components/tabel/ASPTabel/types';
import { GlobaleRendererContext } from '../../../../one-off-components/GlobaleRenderer';
import MuterenDialoog from './MuterenDialoog';
import { RootStoreContext } from '../../../../stores/RootStore';
import { EResultType } from '../../../../stores/CheckStore';
import useBezig from '../../../../core/useBezig';
import { IOphalenTekstenResultElement } from '../../../../../../shared/src/api/v2/tekst';
import { IOphalenTalenResultElement } from '../../../../../../shared/src/api/v2/taal';

interface IData {
  voetnoten: { [index: number]: IVoetnoot };
  totaalAantal: number;
  teksten: { [tekstID: number]: { [taalID: number]: IOphalenTekstenResultElement } };
  talen: { [taalID: number]: IOphalenTalenResultElement };
}

type Kolom = 'naam' | 'teksten' | 'teksten_talen';

const Voetnoten = (props: RouteComponentProps) => {
  const { checkStore } = useContext(RootStoreContext);
  const globaleRenderer = useContext(GlobaleRendererContext);

  const { isBezig, scopeBezig } = useBezig();

  const [data, setData] = useState<IRemoteData<IData>>(createPendingRemoteData());
  const dataRef = useRef<IRemoteData<IData>>(data);
  const setDataRef = useCallback(
    (data: IRemoteData<IData>) => {
      dataRef.current = data;
      setData(data);
    },
    [setData],
  );
  interface IOphalenParams {
    paginatie: IPaginatiePositie;
    uitbreiden: boolean;
  }
  const ophalenData = useCallback(
    async (params?: IOphalenParams) => {
      await scopeBezig(async () => {
        const defaultOphalenParams: IOphalenParams = {
          paginatie: { index: 0, aantal: 50 },
          uitbreiden: false,
        };
        const { paginatie, uitbreiden } = { ...defaultOphalenParams, ...params };

        const [result, talenResult] = await Promise.all([
          api.v2.concurrentie.vergelijkingspunt.voetnoot.ophalenVoetnoten({}),
          uitbreiden ? null : api.v2.taal.ophalen({}),
        ]);
        const voetnoten = result.voetnoten.reduce<IData['voetnoten']>(
          (acc, voetnoet, i) => {
            acc[paginatie.index + i] = voetnoet;
            return acc;
          },
          uitbreiden ? dataRef.current!.data?.voetnoten ?? {} : {},
        );

        const tekstIDs = new Set(result.voetnoten.map((x) => x.Naam_TekstID));
        const tekstenResult =
          tekstIDs.size === 0
            ? null
            : await api.v2.tekst.ophalenTekstenInAlleTalen({ tekstIDs: Array.from(tekstIDs) });

        const teksten =
          tekstenResult === null
            ? {}
            : tekstenResult.teksten.reduce<IData['teksten']>((acc, curr) => {
                const taalMap = acc[curr.TekstID] ?? {};
                taalMap[curr.TaalID] = curr;
                acc[curr.TekstID] = taalMap;
                return acc;
              }, {});

        const talen = uitbreiden
          ? dataRef.current.data!.talen
          : talenResult!.reduce<{ [taalID: number]: IOphalenTalenResultElement }>((acc, curr) => {
              acc[curr.TaalID] = curr;
              return acc;
            }, {});

        const newData: IData = {
          voetnoten,
          totaalAantal: result.totaalAantal,
          teksten,
          talen,
        };
        setDataRef(createReadyRemoteData(newData));
      });
    },
    [setDataRef, scopeBezig],
  );
  useEffect(() => {
    ophalenData();
  }, []);

  const keyExtractor = useCallback((row: IVoetnoot) => row.ID, []);

  const kolommen = useMemo<ASPKolom<Kolom, IVoetnoot>[]>(
    () => [
      {
        key: 'naam',
        label: 'Naam',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 500,
        renderer: (voetnoot) => voetnoot.Naam,
      },
      {
        key: 'teksten',
        label: 'Teksten',
        breedteType: EAspKolomBreedteType.Flex,
        flex: 1,
        renderer: (voetnoot) => {
          const teksten = data.data!.teksten[voetnoot.Naam_TekstID];
          const eersteTekst = Object.values(teksten).find((x) => x.Toepassen);
          if (eersteTekst === undefined) {
            return '- -';
          }
          return (
            <span
              title={eersteTekst.Tekst ?? ''}
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {eersteTekst.Tekst}
            </span>
          );
        },
      },
      {
        key: 'teksten_talen',
        label: 'Teksten talen',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 200,
        renderer: (voetnoot) => {
          const teksten = data.data!.teksten[voetnoot.Naam_TekstID];
          const talen = Object.values(teksten).map((x) =>
            data.data!.talen[x.TaalID].iso639_1.toUpperCase(),
          );
          if (talen.length === 0) {
            return '- -';
          }

          return talen.join(', ');
        },
      },
    ],
    [data],
  );

  const handleToevoegen = useCallback(async () => {
    const result = await globaleRenderer.render<boolean>((renderProps) => (
      <MuterenDialoog
        id={null}
        open
        onSuccess={() => renderProps.destroy(true)}
        onAnnuleren={() => renderProps.destroy(false)}
      />
    ));
    if (!result) {
      return;
    }
    await ophalenData();
  }, [ophalenData]);

  const handleWijzigenRij = useCallback(
    async (rij: IVoetnoot) => {
      const result = await globaleRenderer.render<boolean>((renderProps) => (
        <MuterenDialoog
          id={rij.ID}
          open
          onSuccess={() => renderProps.destroy(true)}
          onAnnuleren={() => renderProps.destroy(false)}
        />
      ));
      if (!result) {
        return;
      }
      await ophalenData();
    },
    [ophalenData],
  );

  const verwijderenRijConfirmatie = useCallback<VerwijderenRijConfirmatieFn<IVoetnoot>>(
    async (rij, verwijderen) => {
      await scopeBezig(async () => {
        const checkData = await api.v2.concurrentie.vergelijkingspunt.voetnoot.checkVerwijderenVoetnoot(
          {
            id: rij.ID,
          },
        );
        const controlerenResult = await checkStore.controleren({ checkData });
        if (controlerenResult.type === EResultType.Annuleren) {
          return;
        }

        await checkStore.bevestigen({
          inhoud: 'Bevestig verwijderen voetnoot',
          asynchroneActieNaBevestigingFn: verwijderen,
        });
      });
    },
    [scopeBezig],
  );

  const handleVerwijderenRij = useCallback(
    async (rij: IVoetnoot) => {
      await api.v2.concurrentie.vergelijkingspunt.voetnoot.verwijderenVoetnoot({
        id: rij.ID,
      });
      await ophalenData();
    },
    [ophalenData],
  );

  return (
    <MenuLayout
      menu={
        <div className="d-flex align-items-center">
          <button
            className="btn btn-sm btn-light d-flex align-items-center"
            onClick={handleToevoegen}
          >
            <IconToevoegen style={{ width: 18, height: 18, fill: Kleur.Grijs }} />
            <span className="ml-2">Toevoegen</span>
          </button>
        </div>
      }
      body={
        data.state === ERemoteDataState.Pending ? (
          <LoadingSpinnerCenter />
        ) : (
          <ASPTabel
            keyExtractor={keyExtractor}
            kolommen={kolommen}
            rijen={data.data!.voetnoten}
            totaalAantalRijen={data.data!.totaalAantal}
            onWijzigenRij={handleWijzigenRij}
            verwijderenRijConfirmatie={verwijderenRijConfirmatie}
            onVerwijderenRij={handleVerwijderenRij}
            isBezig={isBezig}
          />
        )
      }
    />
  );
};

export default Voetnoten;
