import React from 'react';
import LoadingSpinner from '../index';

interface IProps {}

const KnopLoadingSpinner: React.FC<IProps> = () => {
  return <LoadingSpinner grootte="15px" dikte="2px" />;
};

export default KnopLoadingSpinner;
