import React, { useCallback, useContext, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { IOphalenMutatiesResultElement } from '../../../../../shared/src/api/v2/relatie/mutatie';
import _ from 'lodash';
import MenuLayout from '../../MenuLayout';
import { Kleur } from '../../../bedrijfslogica/constanten';
import LoadingSpinner from '../../Gedeeld/LoadingSpinner';
import {
  DXTableCheckboxComponent,
  DXTableToggleCellComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../helpers/dxTableGrid';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  DataTypeProvider,
  IntegratedSorting,
  RowDetailState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import nameof from '../../../core/nameOf';
import { format } from 'date-fns';
import DetailComp from './DetailComp';
import { mutatiebronIconMap } from '../../../bedrijfslogica/constanten';
import { EResultType } from '../../../stores/CheckStore';
import api from '../../../api';
import { RootStoreContext } from '../../../stores/RootStore';
import { AutoSizer } from 'react-virtualized';
import { EMutatiebron } from '../../../bedrijfslogica/enums';

interface IProps {
  mutaties: IOphalenMutatiesResultElement[] | null;
  mutatiesSelectie: number[];
  onMutatiesSelectieChange: (value: number[]) => void;
  uitgeklapteMutaties: number[];
  onUitgeklapteMutatiesChange: (value: number[]) => void;
  onRequestRefresh: () => void;
}

export interface IRow extends IOphalenMutatiesResultElement {}

const geenData = {
  noData: 'Geen mutaties aanwezig',
};

const MutatieTabel: React.FC<IProps> = observer((props) => {
  const { checkStore } = useContext(RootStoreContext);

  const rows = useMemo<IRow[] | null>(() => {
    if (props.mutaties === null) {
      return null;
    }

    const mutaties = props.mutaties.map((mutatie) => ({
      ...mutatie,
    }));

    return _.orderBy(mutaties, ['Datum'], ['desc']);
  }, [props.mutaties]);

  const handleHerstellen = useCallback(async (id: number, set: 'O' | 'N') => {
    if (
      (
        await checkStore.bevestigen({
          inhoud: 'Deze gegevens terugzetten?',
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }
    await api.v2.relatie.herstellenData({ id, set });

    props.onRequestRefresh();
  }, []);

  const keyExtractor = useCallback((row: IRow) => row.ID, []);

  const kolommen = useMemo<TypedColumn<IRow>[]>(
    () => [
      {
        name: 'Datum',
        title: 'Datum',
      },
      {
        name: 'Mutatiebron',
        title: 'Bron',
      },
      {
        name: 'EventsoortNaam',
        title: 'Soort',
      },
      {
        name: 'IsHerstelling',
        title: 'Type',
      },
      {
        name: '__herstelactie' as any,
        title: 'Actie',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRow>[]>(
    () => [
      {
        columnName: 'Datum',
        width: 150,
      },
      {
        columnName: 'EventsoortNaam',
        width: 180,
      },
      {
        columnName: 'Mutatiebron',
        width: 100,
      },
      {
        columnName: '__herstelactie' as any,
        width: 100,
      },
      {
        columnName: 'IsHerstelling',
        width: 100,
      },
    ],
    [],
  );

  if (rows === null) {
    return (
      <div className="d-flex flex-fill align-items-center justify-content-center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <AutoSizer className="flex-fill w-100 h-100">
      {(size) => (
        <GridStyleWrapper height={size.height}>
          <Grid rows={rows} columns={kolommen} getRowId={keyExtractor}>
            <DataTypeProvider
              for={[nameof<IRow>('Datum')]}
              formatterComponent={(formatterProps) => {
                const row = formatterProps.row as IRow;
                return (
                  <span>
                    {row.Datum === null ? '' : format(new Date(row.Datum), 'dd-MM-yyyy HH:mm')}
                  </span>
                );
              }}
            />

            <DataTypeProvider
              for={[nameof<IRow>('Mutatiebron')]}
              formatterComponent={(formatterProps) => {
                const row: IRow = formatterProps.row;
                const Icon = mutatiebronIconMap[row.Mutatiebron as EMutatiebron];
                return (
                  <span>
                    <Icon style={{ width: 18, height: 18, fill: Kleur.Grijs }} />
                  </span>
                );
              }}
            />

            <DataTypeProvider
              for={['__herstelactie']}
              formatterComponent={(formatterProps) => {
                const row: IRow = formatterProps.row;

                if (!row.Herstelbaar) {
                  return <span>Geen</span>;
                }

                return (
                  <span className="d-flex align-items-center">
                    <a
                      href="#"
                      onClick={async () => {
                        await handleHerstellen(row.ID, 'O');
                      }}
                    >
                      Herstel
                    </a>
                  </span>
                );
              }}
            />

            <DataTypeProvider
              for={['IsHerstelling']}
              formatterComponent={(formatterProps) => {
                const row: IRow = formatterProps.row;
                return <span>{row.IsHerstelling ? 'Herstelling' : ''}</span>;
              }}
            />

            {/* <IntegratedSorting /> */}

            <VirtualTable messages={geenData} />
            <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
            <SortingState defaultSorting={[]} />
            <TableHeaderRow showSortingControls />

            {/* <SelectionState
                selection={props.mutatiesSelectie}
                onSelectionChange={(value) => props.onMutatiesSelectieChange(value as number[])}
              /> */}

            {/* <EditingState
                onCommitChanges={(changes) => {
                  if (changes.deleted === undefined) {
                    return;
                  }
                  const deleted = changes.deleted;
                  const id = deleted[deleted.length - 1];
                }}
                onEditingRowIdsChange={(rowIds) => {
                  const id = rowIds[rowIds.length - 1] as number;
                  props.onWijzigenOpdrachtDialoogStateChange({ trsOpdID: id });
                }}
              /> */}

            {/* <TableEditColumn
                width={35}
                showEditCommand
                cellComponent={DXTableEditColumnCellComponent}
                commandComponent={DXTableEditColumnCommandComponent}
              /> */}

            <RowDetailState
              expandedRowIds={props.uitgeklapteMutaties}
              onExpandedRowIdsChange={(value) =>
                props.onUitgeklapteMutatiesChange(value as number[])
              }
            />
            <TableRowDetail
              toggleCellComponent={DXTableToggleCellComponent}
              contentComponent={DetailComp}
            />
            {/* <TableSelection cellComponent={DXTableCheckboxComponent} /> */}
          </Grid>
        </GridStyleWrapper>
      )}
    </AutoSizer>
  );
});

export default MutatieTabel;
