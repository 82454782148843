import React, { useCallback, useMemo } from 'react';
import IDialoogProps from '../../../../core/IDialoogProps';
import Dialoog from '../../../dialogen/Dialoog';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../models/IRemoteData';
import { LoadingSpinnerCenter } from '../../../Gedeeld/LoadingSpinner';
import ModalBody from 'react-bootstrap/ModalBody';
import { IWhatsappChatsessie } from '../../../../api/services/v1/whatsapp';
import { useWhatsappV2Store } from '../store';
import VinkVeld from '../../../formulier/VinkVeld';
import nameOf from '../../../../core/nameOf';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import UitlegTooltip from '../../../formulier/UitlegTooltip';
import api from '../../../../api';

interface IProps extends IDialoogProps {
  chatsessieID: number;
}

interface IValues {
  ontvangstbevestigingSturen: boolean;
}

const ChatsessieInstellingenDialoog = (props: IProps) => {
  const store = useWhatsappV2Store();
  const chatsessie = useMemo<IRemoteData<IWhatsappChatsessie | null>>(() => {
    if (props.chatsessieID === null) {
      return createReadyRemoteData(null);
    }
    if (store.chatsessieBijID.state === ERemoteDataState.Pending) {
      return createPendingRemoteData();
    }
    const chatsessie = store.chatsessieBijID.data![props.chatsessieID];
    if (!chatsessie) {
      return createPendingRemoteData();
    }
    return createReadyRemoteData(chatsessie);
  }, [props.chatsessieID, store.chatsessieBijID]);

  const initialValues = useMemo<IRemoteData<IValues>>(() => {
    if (chatsessie.state === ERemoteDataState.Pending) {
      return createPendingRemoteData();
    }
    const initialValues: IValues = {
      ontvangstbevestigingSturen: chatsessie.data!.ontvangstbevestigingSturen,
    };
    return createReadyRemoteData(initialValues);
  }, [chatsessie]);

  const handleSubmit = useCallback(
    async (values: IValues, actions: FormikActions<IValues>) => {
      actions.setSubmitting(true);

      await api.services.v1.whatsapp.wijzigenChatsessieInstellingen({
        chatsessieId: props.chatsessieID,
        ontvangstbevestigingSturen: values.ontvangstbevestigingSturen,
      });
      props.onSuccess(null);

      actions.setSubmitting(false);
    },
    [props.onSuccess, props.chatsessieID],
  );

  return (
    <Dialoog index={props.dialoogIndex ?? 0}>
      <ModalHeader>
        <ModalTitle>Chatsessie instellingen</ModalTitle>
      </ModalHeader>
      {initialValues.state === ERemoteDataState.Pending ? (
        <LoadingSpinnerCenter />
      ) : (
        <Formik
          initialValues={initialValues.data!}
          onSubmit={handleSubmit}
          render={(formikProps) => <Formulier {...props} formikProps={formikProps} />}
        />
      )}
    </Dialoog>
  );
};

interface IFormulierProps extends IProps {
  formikProps: FormikProps<IValues>;
}

const Formulier = (props: IFormulierProps) => {
  const { formikProps } = props;
  const { isSubmitting, isValid, submitForm } = formikProps;

  return (
    <>
      <ModalBody>
        <Field
          name={nameOf<IValues>('ontvangstbevestigingSturen')}
          render={({ field, form }: FieldProps<IValues>) => (
            <div className="d-flex align-items-center">
              <VinkVeld
                aangevinkt={field.value}
                onGewijzigd={(value) => form.setFieldValue(field.name, value)}
              />
              <span className="ml-2 mr-2">Ontvangstbevestiging sturen</span>
              <UitlegTooltip
                inhoud={
                  <div>
                    <p>
                      Als iemand ons een bericht stuurt, dan kunnen we afhankelijk van de situatie
                      automatisch reageren met dat we het bericht hebben ontvangen.
                    </p>
                    <p>Middels deze instelling kan je deze functionaliteit uit- en inschakelen.</p>
                  </div>
                }
              />
            </div>
          )}
        />
      </ModalBody>
      <ModalFooter className="d-flex flex-row justify-content-start">
        <button
          className="btn btn-primary d-flex align-items-center justify-content-center"
          onClick={submitForm}
          style={{ width: 100 }}
          disabled={isSubmitting || !isValid}
        >
          Ok
        </button>
        <button
          className="btn btn-secondary"
          onClick={props.onAnnuleren}
          style={{ width: 100 }}
          disabled={isSubmitting}
        >
          Annuleren
        </button>
      </ModalFooter>
    </>
  );
};

export default ChatsessieInstellingenDialoog;
