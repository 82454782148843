import React, { useCallback, useMemo } from 'react';
import {
  IRepresentatieProps,
  Provider,
} from '../../../../../../../components/formulier/MultiComboboxV2';
import MultiComboboxV2MetZoeken, {
  IMultiComboboxV2MetZoekenOverlayState,
} from '../../../../../../../components/formulier/MultiComboboxV2/MetZoeken';
import api from '../../../../../../../api';
import {
  ASPKolom,
  EAspKolomBreedteType,
} from '../../../../../../../components/tabel/ASPTabel/types';
import { IContextProvider } from '../../../../../../../../../shared/src/api/v2/bulkbericht/contextProvider';

type Kolom = 'naam' | 'omschrijving';

interface IProps {
  providerID: number | null;
  onProviderIDChange: (id: number | null) => void;
  sjabCtxID: number;
  ontvangerssoortID: number;
}

const ContextProviderSelectie = (props: IProps) => {
  const provider = useMemo<
    Provider<Kolom, IContextProvider, IMultiComboboxV2MetZoekenOverlayState>
  >(
    () => async (params) => {
      const result = await api.v2.bulkbericht.contextProvider.ophalenContextProviders({
        paginatie: params.paginatie,
        filterSchema: {
          filters: [
            {
              naam: 'SJAB_CTX_IDS',
              data: [props.sjabCtxID],
            },
            {
              naam: 'ONTVANGERSSOORT_IDS',
              data: [props.ontvangerssoortID],
            },
          ],
        },
      });

      const items = result.items.reduce((acc, item, i) => {
        acc[params.paginatie.index + i] = item;
        return acc;
      }, params.huidigeBron);

      return {
        items,
        totaalAantal: result.totaalAantal,
      };
    },
    [props.sjabCtxID, props.ontvangerssoortID],
  );
  const enkeleProvider = useMemo(
    () => async (providerID: number) => {
      const result = await api.v2.bulkbericht.contextProvider.ophalenContextProviders({
        filterSchema: {
          filters: [
            {
              naam: 'IDS',
              data: [providerID],
            },
            {
              naam: 'SJAB_CTX_IDS',
              data: [props.sjabCtxID],
            },
            {
              naam: 'ONTVANGERSSOORT_IDS',
              data: [props.ontvangerssoortID],
            },
          ],
        },
      });
      return result.items[0];
    },
    [props.sjabCtxID, props.ontvangerssoortID],
  );
  const keyExtractor = useCallback((item: IContextProvider) => item.ID, []);

  const kolommen = useMemo<ASPKolom<Kolom, IContextProvider>[]>(
    () => [
      {
        key: 'naam',
        label: 'Naam',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 350,
        renderer: (item) => item.Naam,
      },
      {
        key: 'omschrijving',
        label: 'omschrijving',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 500,
        renderer: (item) => item.Omschrijving,
      },
    ],
    [],
  );

  return (
    <MultiComboboxV2MetZoeken
      provider={provider}
      enkeleProvider={enkeleProvider}
      keyExtractor={keyExtractor}
      waarde={props.providerID}
      onChange={props.onProviderIDChange}
      representatieComponent={Representatie}
      kolommen={kolommen}
    />
  );
};

const Representatie = (props: IRepresentatieProps<IContextProvider>) => {
  return <span>{props.entiteit.Naam}</span>;
};

export default ContextProviderSelectie;
