import React, { useCallback, useMemo, useState } from 'react';
import { LeftPaneContainer, RightPaneContainer, Root } from './style';
import SplitPane from 'react-split-pane';
import ContactsSection, { IShouldFetchMoreContactsResult } from './ContactsSection';
import ConversationSection from './ConversationSection';
import { IAsyncObservableAction } from '../../../core/asyncObservableAction';

export interface IMessage {
  id: number;
  isIncoming: boolean;
  content: string;
  time: Date;
}

export interface IContact {
  id: number;
  phoneNumber: string;
  lastMessage: IMessage | null;
  persID: number | null;
}

export interface INewContact {
  tempId: string;
  phoneNumber: string;
  persID: number | null;
}

export interface IConversation {
  contact: IContact | INewContact;
  messages: IMessage[];
}

export interface IOptions {
  contacten?: {
    topBar?: {
      opstellenVerbergen?: boolean;
    };
  };
}

const defaultOptions: IOptions = {
  contacten: {
    topBar: {
      opstellenVerbergen: false,
    },
  },
};

interface IProps {
  contacts: (IContact | INewContact)[] | null;
  conversation: IConversation | null;
  selectedContactNumber: string | null;
  onSelectedContactNumberChange: (value: string | null) => void;
  defaultMessage: string | null;
  onNewContact: (contact: INewContact) => void;
  onContactChange: (contact: IContact | INewContact) => void;
  shouldFetchMoreContacts: (
    action: IAsyncObservableAction<IShouldFetchMoreContactsResult, any>,
  ) => void;
  onRequestFilter: (search: string | null) => void;
  options?: IOptions;
}

const SMSWerkblad: React.FC<IProps> = (props) => {
  const [message, setMessage] = useState(props.defaultMessage ?? '');
  const options = useMemo(() => {
    return {
      ...defaultOptions,
      ...props.options,
    };
  }, [props.options]);

  const handleOnNewContact = useCallback(
    (contact: INewContact) => {
      props.onNewContact(contact);
      props.onSelectedContactNumberChange(contact.phoneNumber);
    },
    [props.onNewContact, props.onSelectedContactNumberChange],
  );

  return (
    <Root>
      <SplitPane
        split="vertical"
        minSize={350}
        maxSize={400}
        defaultSize={350}
        style={{ position: 'inherit' }}
        pane1Style={{ display: 'flex' }}
        pane2Style={{ display: 'flex' }}
      >
        <LeftPaneContainer>
          <ContactsSection
            onNewContact={handleOnNewContact}
            contacts={props.contacts}
            selectedContactNumber={props.selectedContactNumber}
            onSelectedContactNumberChange={props.onSelectedContactNumberChange}
            onContactChange={props.onContactChange}
            shouldFetchMoreContacts={props.shouldFetchMoreContacts}
            onRequestFilter={props.onRequestFilter}
            options={options}
          />
        </LeftPaneContainer>
        <RightPaneContainer>
          <ConversationSection
            conversation={props.conversation}
            selectedContactNumber={props.selectedContactNumber}
            message={message}
            onMessageChange={setMessage}
          />
        </RightPaneContainer>
      </SplitPane>
    </Root>
  );
};

export default SMSWerkblad;
