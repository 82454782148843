import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Root } from './style';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../../../../../stores/RootStore';
import api from '../../../../../../../api';
import LoadingSpinner from '../../../../../../../components/Gedeeld/LoadingSpinner';
import { Kleur } from '../../../../../../../bedrijfslogica/constanten';
import MenuLayout from '../../../../../../../components/MenuLayout';
import {
  EFunctioneleIcon,
  functioneleIconMap,
  IconCancel,
  IconDuimOmlaag,
  IconLocatie,
  IconSend,
  IconToevoegen,
  IconTransport,
  IconVernieuwen,
  IconVerwijderen,
  IconVink,
  IconVlag,
} from '../../../../../../../components/Icons';
import { RouteComponentProps, withRouter } from 'react-router';
import useUrlState from '../../../../../../../core/useUrlState';
import VersturenUitnodigingenOvernameDialoog from './VersturenUitnodigingenOvernameDialoog';
import AanbiedenOvernameDialoog from './AanbiedenOvernameDialoog';
import BeeindigenContractDialoog from './BeeindigenContractDialoog';
import SelectieVak from '../../../../../../../components/SelectieVak';
import ActieMenuKnop from '../../../../../../../components/ActieMenuKnop';
import AnnulerenContractDialoog from './AnnulerenContractDialoog';
import AfkeurenContractDialoog from './AfkeurenContractDialoog';
import FiatterenNieuwDialoog from './FiatterenNieuwDialoog';
import FiatterenOvernameDialoog from './FiatterenOvernameDialoog';
import {
  IOphalenContractenResultElementV2,
  IOphalenContractLocatiesResult,
  IOphalenContractstatussenResult,
} from '../../../../../../../../../shared/src/api/v2/contract';
import { EResultType } from '../../../../../../../stores/CheckStore';
import classNames from 'classnames';
import FilterBalkV2, { IFilter, IFilterData } from '../../../../../../../components/FilterBalkV2';
import * as _ from 'lodash';
import LocatieFilter from './LocatieFilter';
import StatusFilter from './StatusFilter';
import LocatieContractenTabel, { ILocatieContracten } from './LocatieContractenTabel';
import { IVersturenBevestigingsverzoekParams } from '../../../../../../../../../shared/src/api/v2/contract/nieuw/nieuw';
import ZoektermFilter from './ZoektermFilter';
import VerticaleScheidingslijn from '../../../../../../../components/layout/VerticaleScheidingslijn';
import NieuweTransportopdrachtDialoog, {
  ESoortOpdracht,
} from '../../../../../../../components/transport/NieuweOpdrachtDialoog';
import LocatieSelecterenOfOpvoerenDialoog, {
  ILocatieSelecterenOfOpvoerenDialoogFormikValues,
} from '../../../../../../../components/locatie/LocatieSelecterenOfOpvoerenDialoog';
import VerdiepingFilter from './VerdiepingFilter';
import { IOphalenLocatieNiveausResultElement } from '../../../../../../../../../shared/src/api/v2/locatie/locatie';
import { EContractStatus } from './ContractTegel/ContractStatus';
import VinkVeld from '../../../../../../../components/formulier/VinkVeld';
import UitlegTooltip from '../../../../../../../components/formulier/UitlegTooltip';
import {
  EKanaal,
  EProductstatus,
  ETransportopdrachtRegelsoort,
} from '../../../../../../../bedrijfslogica/enums';
import {
  EFilter,
  KEContractenOverzichtStoreContext,
} from '../../../../../../../stores/klantkaart/entiteiten/contracten/overzicht/KEContractenOverzichtStore';
import NieuwContractDialoogV2 from './NieuwContractDialoogV2';
import { IRegel } from '../../../../../../../../../shared/src/api/v2/transport/opdracht/nieuw';
import { IKoppelenOmruilParams } from '../../../../../../../../../shared/src/api/v2/transport/opdracht/omruil';
import NieuwVerkoopvoorstelDialoog from './NieuwVerkoopvoorstelDialoog';
import InterneLocatieSelectieDialoog from '../../../../../../../components/dialogen/InterneLocatieSelectieDialoog';
import ModelEnAbonnementWijzigenDialoog from './ModelEnAbonnementWijzigenDialoog';
import md5 from 'md5';
import { IVersturenBevestigingBeeindigingParams } from '../../../../../../../../../shared/src/api/v2/contract/beeindiging';
import ToevoegenStandaardLeveropdrachtenDialoog from '../../../../../../../components/transport/ToevoegenStandaardLeveropdrachtenDialoog';
import OvernemenContractDialoog from './OvernemenContractDialoog';
import ActieSelectieDialoog from '../../../../../../../components/Aanbod/Tarieven/Actie/SelectieDialoog';
import PersoonFilter from './PersoonFilter';
import useBijGewijzigdEffect from '../../../../../../../core/useBijGewijzigdEffect';
import { IVersturenBevestigingAnnuleringParams } from '../../../../../../../../../shared/src/api/v2/contract/annulering';

interface IProps extends RouteComponentProps {
  relID: number;
}

export interface INieuwContractDialoogState {
  contractwissel_CntID?: number;
}

export interface IModelEnAbonnementWijzigenDialoogState {
  relID: number;
  cntIDs: number[];
}

export interface ILocatieWijzigenDialoogState {
  cntIDs: number[];
  locatieVanProductenNietMeeWijzigen: boolean;
  correspondentieadresMeeWijzigen: boolean;
  initialValues: ILocatieSelecterenOfOpvoerenDialoogFormikValues;
}

export interface IToepassenActieDialoogState {
  cntIDs: number[];
}

export interface IInterneLocatieWijzigenDialoogState {
  cntIDs: number[];
}

export interface INieuweTransportopdrachtDialoogState {
  soortopdracht: number;
}

export interface INieuwContractDialoogTonenDialoogState {
  aantalGebruikers: number | null;
  verdieping: number | null;
  persID: number | null;
  locID: number | null;
  abonID: number | null;
}

export interface INieuwContractwisselDialoogTonenDialoogState {
  wisselcontract_CntID: number;
}

export interface INieuwVerkoopvoorstelDialoogState {
  relID: number;
  prodIDs: number[];
}

export interface IStandaardLeveropdrachtenDialoogState {
  cntIDs: number[];
}

export interface IKEContractenOverzichtUrlState {
  selectie?: number[];
  filterData?: IFilterData<EFilter>[];
  aanbiedenOvernameDialoogTonen?: boolean;
  nieuwContractenDialoogTonen?: INieuwContractDialoogTonenDialoogState | null;
  nieuwContractwisselDialoogTonen?: INieuwContractwisselDialoogTonenDialoogState | null;
  nieuweTransportopdrachtDialoogState?: INieuweTransportopdrachtDialoogState | null;
  afkeurenContractDialoogTonen?: boolean;
  overnemenContractDialoogTonen?: boolean;
  annulerenContractenDialoogTonen?: boolean;
  beeindigContractenDialoogTonen?: boolean;
  versturenUitnodigingTonen?: boolean;
  leveropdrachtTonen?: boolean;
  fiatterenNieuwDialoogTonen?: boolean;
  fiatterenOvernameDialoogTonen?: boolean;
  contractlocatiesUitgeklapt?: number[];
  locatieWijzigenDialoogState?: ILocatieWijzigenDialoogState | null;
  toepassenActieDialoogState?: IToepassenActieDialoogState | null;

  interneLocatieWijzigenDialoogState?: IInterneLocatieWijzigenDialoogState | null;
  nieuwVerkoopvoorstelDialoogState?: INieuwVerkoopvoorstelDialoogState | null;
  modelEnAbonnementWijzigenDialoogState?: IModelEnAbonnementWijzigenDialoogState | null;
  standaardLeveropdrachtenDialoogState?: IStandaardLeveropdrachtenDialoogState | null;
}

export const defaultKEContractenOverzichtUrlState: IKEContractenOverzichtUrlState = {};

export interface IContractenOverzichtContext {
  contractLocaties: IOphalenContractLocatiesResult | null;
  contractstatussenResult: IOphalenContractstatussenResult | null;
  locatieNiveaus: IOphalenLocatieNiveausResultElement[] | null;
  relID: number;
}

export const ContractenOverzichtContext = React.createContext<IContractenOverzichtContext>(
  null as any,
);

const IconAanbiedenOvername = functioneleIconMap[EFunctioneleIcon.AanbiedenOvername];
const IconContractovername = functioneleIconMap[EFunctioneleIcon.Contractovername];
const IconContractwissel = functioneleIconMap[EFunctioneleIcon.Contractwissel];
const IconVerkoopvoorstel = functioneleIconMap[EFunctioneleIcon.Verkoopvoorstel];
const IconInterneLocatie = functioneleIconMap[EFunctioneleIcon.InterneLocatie];
const IconTariefactie = functioneleIconMap[EFunctioneleIcon.Tariefactie];

export interface IContract extends IOphalenContractenResultElementV2 {
  gewisseldDoorContract: IOphalenContractenResultElementV2 | null;
  gewisseldVoorContract: IOphalenContractenResultElementV2 | null;
}

export const handleTransportopdrachtenMaken = async (params: {
  relID: number;
  cntID: number;
  wisselcontractCntID: number | null;
  trsOpdID: number | null;
}) => {
  const contract = (
    await api.v2.contract.ophalenContractenV2({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: [params.cntID],
          },
        ],
      },
    })
  ).contracten[0];

  const wisselcontract =
    params.wisselcontractCntID !== null
      ? (
          await api.v2.contract.ophalenContractenV2({
            filterSchema: {
              filters: [
                {
                  naam: 'IDS',
                  data: [params.wisselcontractCntID],
                },
              ],
            },
          })
        ).contracten[0]
      : null;

  // Bepaal het primaire type tbv de reservering in de leveropdracht
  const productmodel = (
    await api.v2.product.model.ophalenProductmodellen({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: [contract.basis.productmodel.ProdModID],
          },
        ],
      },
    })
  ).modellen[0];

  const primairType =
    productmodel.producttypen.find((x) => x.PrioNr === 0 && x.InkoopToegestaan) || null;

  // Voorraadinfo voor het primaire type
  const voorraadVoorPrimairType =
    primairType !== null
      ? (
          await api.v2.voorraad.ophalenVoorraadinfo({
            typeIDs: [primairType.TypeID],
          })
        ).voorraad[0]
      : null;

  // Bepaal of er al een opdracht in planning aanwezig is voor de locatie
  // const opdrachtenResult = (
  //   await api.v2.transport.opdracht.ophalenOpdrachtenV2({
  //     filterSchema: {
  //       filters: [
  //         {
  //           naam: 'LOC_IDS',
  //           data: [contract.basis.locatie.LocID],
  //         },
  //         {
  //           naam: 'STATUSSEN',
  //           data: [EOpdrachtstatusTransport.Planning],
  //         },
  //       ],
  //     },
  //   })
  // ).opdrachten;

  let trsOpdID;
  if (params.trsOpdID === null) {
    // Voeg een opdracht toe
    const opdrachtenParams = {
      opdrachten: [
        {
          trsDienstID: null,
          magID: null,
          locID: contract.basis.locatie.LocID,
          persID: contract.PersID!,
          telefoon:
            contract.persoon !== null && contract.persoon.TelefoonMobiel !== null
              ? contract.persoon.TelefoonMobiel
              : undefined,
          email:
            contract.persoon !== null && contract.persoon.Email !== null
              ? contract.persoon.Email
              : undefined,
          bezoekdatum: null,
          aantalAfvoer: 0,
        },
      ],
    };

    const toevoegenOpdrachtenResult = await api.v2.transport.opdracht.toevoegenOpdrachten(
      opdrachtenParams,
    );
    trsOpdID = toevoegenOpdrachtenResult.opdrachten.map((x) => x.TrsOpdID)[0];
  } else {
    trsOpdID = params.trsOpdID;
  }

  let regels: IRegel[] = [];

  // Leveropdrachtregel maken
  const leveringRegel = {
    regelsoortNaamEnum: ETransportopdrachtRegelsoort.Levering,
    typeID: primairType !== null ? primairType.TypeID : undefined,
    gereserveerd: voorraadVoorPrimairType !== null && voorraadVoorPrimairType.aantalVrij > 0,
    relID: params.relID,
    cntID: params.cntID,
    aansluitenTrekschakelaar: contract.basis.AansluitenTrekschakelaar!,
    verdieping:
      contract.basis.locatieniveau !== null ? contract.basis.locatieniveau.Verdieping : undefined,
  };

  regels.push(leveringRegel);

  // Contractwissel? Dan ook een retouropdracht maken
  if (wisselcontract !== null && wisselcontract.producten.length === 1) {
    const product = wisselcontract.producten[0];
    const retourRegel = {
      regelsoortNaamEnum: ETransportopdrachtRegelsoort.Retour,
      prodID: product.ProdID,
      relID: params.relID,
      cntID: wisselcontract.CntID,
      // omruilID?: number;
      verdieping:
        contract.basis.locatieniveau !== null ? contract.basis.locatieniveau.Verdieping : undefined,
    };
    regels.push(retourRegel);
  }

  const regelParams = {
    opdrachten: [
      {
        trsOpdID,
        regels,
      },
    ],
  };

  const result = await api.v2.transport.opdracht.toevoegenOpdrachtregels(regelParams);

  // Bij een contractwissel de regels koppelen voor omruil
  if (wisselcontract !== null) {
    const levering_trsRegID = result.regels[0].TrsRegID;
    const retour_trsRegID = result.regels[1].TrsRegID;
    const omruilParams: IKoppelenOmruilParams = {
      regels: [
        {
          trsRegID: levering_trsRegID,
          koppelAan_TrsRegID: retour_trsRegID,
        },
      ],
    };
    await api.v2.transport.opdracht.omruil.koppelenOmruil(omruilParams);
  }
};

const OverzichtComp: React.FC<IProps> = observer((props) => {
  const { klantkaartStore, checkStore } = useContext(RootStoreContext);
  const context = useContext(KEContractenOverzichtStoreContext);

  const [_verder_niet_gebruiken_urlState, _setUrlState, _setUrlStateSync] = useUrlState<
    IKEContractenOverzichtUrlState
  >(props, defaultKEContractenOverzichtUrlState);
  useEffect(() => {
    (async () => {
      await context.initialiseerMetRelIDEnUrlState(props.relID, _verder_niet_gebruiken_urlState);
    })();
  }, [props.relID]);
  useBijGewijzigdEffect(() => {
    context.urlStateToepassen(_verder_niet_gebruiken_urlState);
  }, [md5(JSON.stringify(_verder_niet_gebruiken_urlState))]);
  // const [filterSchema, setFilterSchema] = useState(maakFilterSchema(urlState.filterData));

  useBijGewijzigdEffect(() => {
    context.ophalenContracten();
  }, [JSON.stringify(context.filterData)]);

  const setSelectie = useCallback(
    (selectie: number[]) => {
      context.setSelectie(selectie);
      _setUrlStateSync('selectie', selectie);
    },
    [context.setSelectie, _setUrlStateSync],
  );

  const setAanbiedenOvernameDialoogTonen = useCallback(
    (tonen: boolean) => {
      context.setAanbiedenOvernameDialoogTonen(tonen);
      _setUrlStateSync('aanbiedenOvernameDialoogTonen', tonen);
    },
    [context.setAanbiedenOvernameDialoogTonen, _setUrlState],
  );

  const setNieuwContractDialoogTonen = useCallback(
    (tonen: INieuwContractDialoogTonenDialoogState | null) => {
      context.setNieuwContractDialoogState(tonen);
      _setUrlStateSync('nieuwContractenDialoogTonen', tonen);
    },
    [context.setNieuwContractDialoogState, _setUrlStateSync],
  );

  const setNieuwContractwisselDialoogTonen = useCallback(
    (tonen: INieuwContractwisselDialoogTonenDialoogState | null) => {
      context.setNieuwContractwisselDialoogState(tonen);
      _setUrlStateSync('nieuwContractwisselDialoogTonen', tonen);
    },
    [context.setNieuwContractDialoogState, _setUrlStateSync],
  );

  const setNieuweTransportopdrachtDialoogTonen = useCallback(
    (state: INieuweTransportopdrachtDialoogState | null) => {
      context.setNieuweTransportopdrachtDialoogState(state);
      _setUrlStateSync('nieuweTransportopdrachtDialoogState', state);
    },
    [context.setNieuweTransportopdrachtDialoogState, _setUrlStateSync],
  );

  const setAfkeurenContractDialoogTonen = useCallback(
    (tonen: boolean) => {
      context.setAfkeurenContractDialoogTonen(tonen);
      _setUrlStateSync('afkeurenContractDialoogTonen', tonen);
    },
    [context.setAfkeurenContractDialoogTonen, _setUrlStateSync],
  );

  const setOvernemenContractDialoogTonen = useCallback(
    (tonen: boolean) => {
      context.setOvernemenContractDialoogTonen(tonen);
      _setUrlStateSync('overnemenContractDialoogTonen', tonen);
    },
    [context.setOvernemenContractDialoogTonen, _setUrlStateSync],
  );

  const setAnnulerenContractenDialoogTonen = useCallback(
    (tonen: boolean) => {
      context.setAnnulerenContractenDialoogTonen(tonen);
      _setUrlStateSync('annulerenContractenDialoogTonen', tonen);
    },
    [context.setAnnulerenContractenDialoogTonen, _setUrlStateSync],
  );

  const setBeeindigenContractenDialoogTonen = useCallback(
    (tonen: boolean) => {
      context.setBeeindigContractenDialoogTonen(tonen);
      _setUrlStateSync('beeindigContractenDialoogTonen', tonen);
    },
    [context.setBeeindigContractenDialoogTonen, _setUrlStateSync],
  );

  const setContractlocatiesUitgeklapt = useCallback(
    (uitgeklapt: number[]) => {
      context.setContractlocatiesUitgeklapt(uitgeklapt);
      _setUrlStateSync('contractlocatiesUitgeklapt', uitgeklapt);
    },
    [context.setContractlocatiesUitgeklapt, _setUrlStateSync],
  );

  const setVersturenUitnodigingTonen = useCallback(
    (tonen: boolean) => {
      context.setVersturenUitnodigingTonen(tonen);
      _setUrlStateSync('versturenUitnodigingTonen', tonen);
    },
    [context.setVersturenUitnodigingTonen, _setUrlStateSync],
  );

  const setFiatterenOvernameDialoogTonen = useCallback(
    (tonen: boolean) => {
      context.setFiatterenOvernameDialoogTonen(tonen);
      _setUrlStateSync('fiatterenOvernameDialoogTonen', tonen);
    },
    [context.setFiatterenOvernameDialoogTonen, _setUrlStateSync],
  );

  const setFiatterenNieuwDialoogTonen = useCallback(
    (tonen: boolean) => {
      context.setFiatterenNieuwDialoogTonen(tonen);
      _setUrlStateSync('fiatterenNieuwDialoogTonen', tonen);
    },
    [context.setFiatterenNieuwDialoogTonen, _setUrlStateSync],
  );

  const setNieuwVerkoopvoorstelDialoogState = useCallback(
    (state: INieuwVerkoopvoorstelDialoogState | null) => {
      context.setNieuweVerkoopvoorstelDialoogState(state);
      _setUrlStateSync('nieuwVerkoopvoorstelDialoogState', state);
    },
    [context.setNieuweVerkoopvoorstelDialoogState, _setUrlStateSync],
  );

  const setLocatieWijzigenDialoogState = useCallback(
    (state: ILocatieWijzigenDialoogState | null) => {
      context.setLocatieWijzigenDialoogState(state);
      _setUrlStateSync('locatieWijzigenDialoogState', state);
    },
    [context.setLocatieWijzigenDialoogState, _setUrlStateSync],
  );

  const setToepassenActieDialoogState = useCallback(
    (state: IToepassenActieDialoogState | null) => {
      context.setToepassenActieDialoogState(state);
      _setUrlStateSync('toepassenActieDialoogState', state);
    },
    [context.setToepassenActieDialoogState, _setUrlStateSync],
  );

  const setInterneLocatieWijzigenDialoogState = useCallback(
    (state: IInterneLocatieWijzigenDialoogState | null) => {
      context.setInterneLocatieWijzigenDialoogState(state);
      _setUrlStateSync('interneLocatieWijzigenDialoogState', state);
    },
    [context.setInterneLocatieWijzigenDialoogState, _setUrlStateSync],
  );

  const setModelEnAbonnementWijzigenDialoogState = useCallback(
    (state: IModelEnAbonnementWijzigenDialoogState | null) => {
      context.setModelEnAbonnementWijzigenDialoogState(state);
      _setUrlStateSync('modelEnAbonnementWijzigenDialoogState', state);
    },
    [context.setModelEnAbonnementWijzigenDialoogState, _setUrlStateSync],
  );

  const setStandaardLeveropdrachtenDialoogTonen = useCallback(
    (state: IStandaardLeveropdrachtenDialoogState | null) => {
      context.setStandaardLeveropdrachtenDialoogTonen(state);
      _setUrlStateSync('standaardLeveropdrachtenDialoogState', state);
    },
    [context.setNieuweTransportopdrachtDialoogState, _setUrlStateSync],
  );

  const setFilterData = useCallback(
    (filterData: IFilterData<EFilter>[]) => {
      context.setFilterData(filterData);
      _setUrlStateSync('filterData', filterData);
    },
    [context.setFilterData, _setUrlStateSync],
  );

  const [bezig, setBezig] = useState(false);
  const relID = props.relID;

  const locatieContracten = useMemo<ILocatieContracten[] | null>(() => {
    if (context.contracten === null) {
      return null;
    }
    const grouped: Record<number, IContract[]> = _.groupBy(
      context.contracten,
      (x: any) => x.basis.locatie.LocID,
    );
    return Object.keys(grouped)
      .map(Number)
      .map((locID) => {
        return {
          locID,
          contracten: grouped[locID],
        };
      });
  }, [context.contracten]);

  const initieelUitgeklapt = useRef(false);
  useEffect(() => {
    if (
      initieelUitgeklapt.current ||
      context.contracten === null ||
      context.contractLocatiesResult === null
    ) {
      return;
    }

    initieelUitgeklapt.current = true;
    if (context.contractlocatiesUitgeklapt.length > 0) {
      return;
    }

    if (context.contracten.length <= 10) {
      const locIDs = _.uniq(context.contractLocatiesResult.locaties.map((x) => x.LocID));
      setContractlocatiesUitgeklapt(locIDs.slice(0, 10));
    }
  }, [context.contracten, context.contractLocatiesResult, setContractlocatiesUitgeklapt]);

  useEffect(() => {
    if (
      !initieelUitgeklapt.current ||
      context.contracten === null ||
      context.contractlocatiesUitgeklapt.length > 0
    ) {
      return;
    }

    const alleContractenOnderDezelfdeLocatie =
      context.contracten.length > 0 &&
      context.contracten.every((x, i, arr) => {
        if (i === 0) {
          return true;
        }
        return x.basis.locatie.LocID === arr[0].basis.locatie.LocID;
      });
    const locIDs = alleContractenOnderDezelfdeLocatie
      ? [context.contracten[0].basis.locatie.LocID]
      : [];
    setContractlocatiesUitgeklapt(locIDs);
  }, [context.contracten]);

  const verversen = useCallback(async () => {
    await context.ophalenGegevens();
  }, [context.ophalenGegevens]);

  const handleNieuwContractDialoogSuccess = useCallback(async () => {
    await verversen();
    setNieuwContractDialoogTonen(null);
  }, [setNieuwContractDialoogTonen, verversen]);

  const handleAanbiedenOvernameDialoogSuccess = useCallback(async () => {
    await verversen();
    setAanbiedenOvernameDialoogTonen(false);
  }, [verversen, setAanbiedenOvernameDialoogTonen, props.relID]);

  const handleBeeindigenContractenDialoogSuccess = useCallback(async () => {
    await verversen();
    setBeeindigenContractenDialoogTonen(false);
  }, [setBeeindigenContractenDialoogTonen, verversen]);

  const [
    contractstatussenResult,
    setContractstatussenResult,
  ] = useState<IOphalenContractstatussenResult | null>(null);
  useEffect(() => {
    (async () => {
      const result = await api.v2.contract.ophalenContractstatussen({
        filterSchema: {
          filters: [],
        },
      });

      setContractstatussenResult(result);
    })();
  }, []);

  const [locatieNiveaus, setLocatieNiveausResult] = useState<
    IOphalenLocatieNiveausResultElement[] | null
  >(null);
  useEffect(() => {
    (async () => {
      const result = await api.v2.locatie.ophalenLocatieNiveaus({
        filterSchema: {
          filters: [],
        },
      });
      setLocatieNiveausResult(result.locatieniveaus);
    })();
  }, []);

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.IS_ACTUEEL,
        altijdWeergevenInBalk: true,
        weergave: () => <span>Actueel</span>,
      },
      {
        naam: EFilter.LOC_IDS,
        altijdWeergevenInBalk: true,
        weergave: LocatieFilter,
      },
      {
        naam: EFilter.REL_PERS_IDS,
        altijdWeergevenInBalk: true,
        weergave: PersoonFilter,
      },
      {
        naam: EFilter.ZOEKTERM,
        altijdWeergevenInBalk: true,
        weergave: ZoektermFilter,
      },
      {
        naam: EFilter.STATUS_NAAMENUM,
        altijdWeergevenInBalk: false,
        weergave: StatusFilter,
      },
      {
        naam: EFilter.LOCATIE_VERDIEPINGEN,
        altijdWeergevenInBalk: false,
        weergave: VerdiepingFilter,
      },
    ],
    [],
  );

  // Bepalen of er contracten zijn die bevestigd moeten worden
  // Zo ja dan de knop Bevestingsverzoek enablen
  // const [bevestigingsverzoekVereist, setBevestingsverzoekVereist] = useState<boolean>(false);

  // const bepalenBevestigingsverzoekVereist = useCallback(async () => {
  //   const result = await api.v2.contract.nieuw.bepalenContractenVoorBevestiging({ relID: relID! });
  //   setBevestingsverzoekVereist(result.cntIDs.length !== 0);
  // }, [relID]);

  // useEffect(() => {
  //   bepalenBevestigingsverzoekVereist();
  // }, [bepalenBevestigingsverzoekVereist]);

  const handleBevestigingsverzoek = useCallback(async () => {
    const params: IVersturenBevestigingsverzoekParams = {
      relID: relID!,
      cntIDs: context.selectie,
      directVersturen: true,
    };
    const checkData = await api.v2.contract.nieuw.checkVersturenBevestigingsverzoek(params);
    if (
      (
        await checkStore.controleren({
          checkData,
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    if (
      (
        await checkStore.bevestigen({
          inhoud: <span>Bevestigingsverzoek sturen?</span>,
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    await api.v2.contract.nieuw.versturenBevestigingsverzoek(params);
    // await bepalenBevestigingsverzoekVereist();
  }, [relID, context.selectie]);

  const handleBevestigingBeeindigingVersturen = useCallback(async () => {
    const params: IVersturenBevestigingBeeindigingParams = {
      relID: relID!,
      persID: null,
      cntIDs: context.selectie,
    };

    const checkData = await api.v2.contract.beeindiging.checkVersturenBevestiging(params);
    if (
      (
        await checkStore.controleren({
          checkData,
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }
    if (
      (
        await checkStore.bevestigen({
          inhoud: <span>Bevestiging beeindiging contracten versturen?</span>,
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    await api.v2.contract.beeindiging.versturenBevestiging(params);

    await verversen();
  }, [relID, context.selectie]);

  const handleBevestigingAnnuleringVersturen = useCallback(async () => {
    const params: IVersturenBevestigingAnnuleringParams = {
      relID: relID!,
      persID: null,
      cntIDs: context.selectie,
      taalID: null,
      kanalen: ['EMAIL'],
    };

    const checkData = await api.v2.contract.annulering.checkVersturenBevestigingAnnulering(params);
    if (
      (
        await checkStore.controleren({
          checkData,
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }
    if (
      (
        await checkStore.bevestigen({
          inhoud: <span>Bevestiging annulering contracten versturen?</span>,
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    await api.v2.contract.annulering.versturenBevestigingAnnulering(params);

    await verversen();
  }, [relID, context.selectie]);

  const handleBeeindigenContracten = useCallback(async () => {
    const checkData = await api.v2.contract.beeindiging.checkSelectieBeeindigenContracten({
      cntIDs: context.selectie,
    });

    if (
      (
        await checkStore.controleren({
          checkData,
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    setBeeindigenContractenDialoogTonen(true);
  }, [context.selectie]);

  const handleAfkeurenContract = useCallback(async () => {
    const checkData = await api.v2.contract.afkeuring.checkSelectieAfkeurenContracten({
      relID,
      cntIDs: context.selectie,
    });

    if (
      (
        await checkStore.controleren({
          checkData,
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    setAfkeurenContractDialoogTonen(true);
  }, [context.selectie]);

  const handleOvernemenContract = useCallback(async () => {
    // const checkData = await api.v2.contract.afkeuring.checkSelectieAfkeurenContracten({
    //   relID,
    //   cntIDs: context.selectie,
    // });

    // if (
    //   (
    //     await checkStore.controleren({
    //       checkData,
    //     })
    //   ).type === EResultType.Annuleren
    // ) {
    //   return;
    // }

    setOvernemenContractDialoogTonen(true);
  }, [context.selectie]);

  const handleStandaardLeveropdrachten = useCallback(async () => {
    // const checkData = await api.v2.contract.afkeuring.checkSelectieAfkeurenContracten({
    //   relID,
    //   cntIDs: context.selectie,
    // });

    // if (
    //   (
    //     await checkStore.controleren({
    //       checkData,
    //     })
    //   ).type === EResultType.Annuleren
    // ) {
    //   return;
    // }

    setStandaardLeveropdrachtenDialoogTonen({ cntIDs: context.selectie });
  }, [context.selectie]);

  const handleAnnulerenContract = useCallback(async () => {
    // const checkData = await api.v2.contract.annulering.checkAnnulerenContracten({
    //   relID: relID!,
    //   cntIDs: context.selectie,
    //   annuleringsdatum: null,
    // });

    // if (
    //   (
    //     await checkStore.controleren({
    //       checkData,
    //     })
    //   ).type === EResultType.Annuleren
    // ) {
    //   return;
    // }

    setAnnulerenContractenDialoogTonen(true);
  }, [context.selectie]);

  const handleAanbiedenVoorContractwissel = useCallback(async () => {
    const checkData = await api.v2.contract.wissel.checkMarkerenContractenVoorWissel({
      cntIDs: context.selectie,
    });

    if (
      (
        await checkStore.controleren({
          checkData,
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    if (
      (
        await checkStore.bevestigen({
          inhoud: <span>Contract(en) aanbieden voor contractwissel ?</span>,
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    await api.v2.contract.wissel.markerenContractenVoorWissel({
      cntIDs: context.selectie,
    });
    await verversen();
  }, [context.selectie, verversen]);

  const handleAanbiedenContract = useCallback(async () => {
    const checkData = await api.v2.contract.checkSelectieAanbiedenContracten({
      relID: relID!,
      cntIDs: context.selectie,
    });

    if (
      (
        await checkStore.controleren({
          checkData,
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    setAanbiedenOvernameDialoogTonen(true);
  }, [context.selectie]);

  const bepaalTypeFiatterenContracten = useCallback(() => {
    if (
      context.contracten === null ||
      context.contracten.length === 0 ||
      context.selectie.length === 0
    ) {
      return;
    }
    // Trek alle Volgnummers uit de selectie. Als deze allemaal 0 zijn betreft het basiscontracten,
    // als ze allemaal > 0 zijn dan volgcontracten, anders fout
    const volgnummers = context.selectie.map((cntID) => {
      const volgnummer = context.contracten!.find((x) => x.CntID === cntID)!.Volgnummer;
      return volgnummer;
    });
    const volgnummer =
      volgnummers.filter((x) => x === 0).length === volgnummers.length
        ? 0
        : volgnummers.filter((x) => x !== 0).length === volgnummers.length
        ? 1
        : null;

    return volgnummer;
  }, [context.contracten, context.selectie]);

  const handleFiatterenContracten = useCallback(async () => {
    if (bepaalTypeFiatterenContracten() === 0) {
      const checkData = await api.v2.contract.nieuw.checkFiatterenContracten({
        relID: props.relID,
        cntIDs: context.selectie,
      });

      if (
        (
          await checkStore.controleren({
            checkData,
          })
        ).type === EResultType.Annuleren
      ) {
        return;
      }

      setFiatterenNieuwDialoogTonen(true);
    } else {
      if (bepaalTypeFiatterenContracten() === 1) {
        const checkData = await api.v2.contract.overname.checkFiatterenContracten({
          relID,
          cntIDs: context.selectie,
        });

        if (
          (
            await checkStore.controleren({
              checkData,
            })
          ).type === EResultType.Annuleren
        ) {
          return;
        }
        setFiatterenOvernameDialoogTonen(true);
      }
    }
  }, [
    props.relID,
    bepaalTypeFiatterenContracten,
    setFiatterenOvernameDialoogTonen,
    setFiatterenNieuwDialoogTonen,
  ]);

  const handleTransportopdracht = useCallback(async () => {
    if (context.contracten === null) {
      return;
    }

    const contract = (
      await api.v2.contract.ophalenContractenV2({
        filterSchema: {
          filters: [
            {
              naam: 'IDS',
              data: [context.selectie[0]],
            },
          ],
        },
      })
    ).contracten[0];

    const producten = (
      await api.v2.product.ophalenProductenV2({
        filterSchema: {
          filters: [
            {
              naam: 'CNT_IDS',
              data: [context.selectie[0]],
            },
            {
              naam: 'PROD_STAT_NAAM_ENUMS',
              data: [EProductstatus.Verhuur],
            },
          ],
        },
      })
    ).producten;

    const soortopdracht =
      producten.length === 0
        ? ESoortOpdracht.Levering
        : producten.length === 1 && contract.status.NaamEnum === EContractStatus.Lopend
        ? ESoortOpdracht.Omruil
        : ESoortOpdracht.Retour;

    setNieuweTransportopdrachtDialoogTonen({
      soortopdracht,
    });
  }, [
    context.contracten,
    JSON.stringify(context.selectie),
    setNieuweTransportopdrachtDialoogTonen,
  ]);

  const handleVersturenBevestiging = useCallback(async () => {
    const params = {
      relID: props.relID,
      cntIDs: context.selectie,
    };
    const checkData = await api.v2.contract.nieuw.checkVersturenFiatteringsbericht(params);

    if (
      (
        await checkStore.controleren({
          checkData,
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    if (
      (
        await checkStore.bevestigen({
          inhoud: (
            <div>Wil je een fiatteringsbericht versturen voor de geselecteerde contracten?</div>
          ),
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    await api.v2.contract.nieuw.versturenFiatteringsbericht(params);
  }, [props.relID, context.selectie]);

  const handleVersturenBevestigingOvernameNieuweHuurder = useCallback(async () => {
    const params = {
      relID: props.relID,
      cntIDs: context.selectie,
      persID: null,
      kanaal: EKanaal.Email,
    };

    // const checkData = await api.v2.contract.overname.checkversturenBevestigingNieuweHuurder(params);

    // if (
    //   (
    //     await checkStore.controleren({
    //       checkData,
    //     })
    //   ).type === EResultType.Annuleren
    // ) {
    //   return;
    // }

    if (
      (
        await checkStore.bevestigen({
          inhoud: (
            <div>
              Wil je voor de geselecteerde contracten een overname-bevestiging sturen naar de nieuwe
              huurder?
            </div>
          ),
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    await api.v2.contract.overname.versturenBevestigingNieuweHuurder(params);
  }, [props.relID, context.selectie]);

  const handleMakenVerkoopvoorstellen = useCallback(async () => {
    // Haal de producten op die aan de vigerende contracten gekoppeld zijn
    const result = await api.v2.product.verkoop.selecterenVerkoopProducten({
      peildatum: null,
      filterSchema: {
        filters: [
          {
            naam: 'CNT_IDS',
            data: context.selectie,
          },
          {
            naam: 'VERKOOP_VOORSTEL_AANWEZIG',
            data: false,
          },
        ],
      },
    });

    if (result.prodIDs.length === 0) {
      await checkStore.melden({
        titel: (
          <span>
            Er zijn geen producten gevonden waarvoor een verkoopvoorstel gemaakt kan worden. Dit kan
            zijn omdat:
            <br />
            <br />
            <ul>
              <li>Voor één of meer producten is reeds een lopend verkoopvoorstel aanwezig</li>
              <li>De ingangsdatum voor een contract ligt in de toekomst</li>
            </ul>
          </span>
        ),
      });
      return;
    }

    const params = {
      relID: props.relID!,
      persID: null,
      prodIDs: result.prodIDs,
      voorsteldatum: null,
    };

    const checkResult = await api.v2.product.verkoop.checkMakenVerkoopVoorstellen(params);
    const controlerenResult = await checkStore.controleren({
      checkData: checkResult,
    });

    if (controlerenResult.type === EResultType.Annuleren) {
      return;
    }

    if (
      (
        await checkStore.bevestigen({
          inhoud: (
            <span>
              Verkoopvoorstellen maken voor de geselecteerde contracten?
              <br /> <br />
              Na aanmaken kun je het voorstel aanpassen voordat je het naar de klant verstuurt.
            </span>
          ),
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    await api.v2.product.verkoop.makenVerkoopVoorstellen(params);

    props.history.push(`/klant/${props.relID}/contracten/verkoop`);

    // setNieuwVerkoopvoorstelDialoogState({ relID: props.relID, prodIDs: result.prodIDs });
  }, [context.selectie, props.relID]);

  const handleVerwijderenContracten = useCallback(async () => {
    if (context.contracten === null) {
      return;
    }

    // Maak onderscheid tussen verwjderen van basis- en volgcontracten

    const contractenBasis_CntIDs = context.contracten
      .filter((x) => x.Volgnummer === 0)
      .filter((x) => context.selectie.indexOf(x.CntID) !== -1)
      .map((x) => x.CntID);
    const contractenVolg_CntIDs = context.contracten
      .filter((x) => x.Volgnummer !== 0)
      .filter((x) => context.selectie.indexOf(x.CntID) !== -1)
      .map((x) => x.CntID);

    // Volgcontracten
    if (contractenVolg_CntIDs.length !== 0) {
      const cntIDs = contractenVolg_CntIDs;
      const checkData = await api.v2.contract.overname.checkVerwijderenOvernameContracten({
        cntIDs,
      });
      const controleResult = await checkStore.controleren({ checkData });
      if (controleResult.type === EResultType.Annuleren) {
        return;
      }

      const resultaat = await checkStore.bevestigen({
        inhoud: `Overnamecontract(en) verwijderen?`,
      });
      if (resultaat.type === EResultType.Annuleren) {
        return;
      }

      await api.v2.contract.overname.verwijderenOvernameContracten({
        cntIDs,
      });
    }

    // Basiscontracten

    if (contractenBasis_CntIDs.length !== 0) {
      const cntIDs = contractenBasis_CntIDs;
      const checkData = await api.v2.contract.nieuw.checkVerwijderenContracten({
        cntIDs,
      });
      const controleResult = await checkStore.controleren({ checkData });
      if (controleResult.type === EResultType.Annuleren) {
        return;
      }

      const resultaat = await checkStore.bevestigen({
        inhoud: `Contract(en) verwijderen?`,
      });
      if (resultaat.type === EResultType.Annuleren) {
        return;
      }

      await api.v2.contract.nieuw.verwijderenContracten({
        cntIDs,
      });
    }

    setSelectie([]);
    await verversen();
  }, [context.selectie, verversen, setSelectie]);

  const [bodyHeight, setBodyHeight] = useState<number | null>(null);

  // const heeftAangebodenContracten = useMemo<boolean | null>(() => {
  //   if (contracten === null) {
  //     return null;
  //   }
  //   return contracten.some((x) => x.AangebodenOvernameOp !== null);
  // }, [contracten]);

  const contextValue = useMemo<IContractenOverzichtContext>(() => {
    return {
      contractLocaties: context.contractLocatiesResult,
      contractstatussenResult,
      locatieNiveaus,
      relID,
    };
  }, [context.contractLocatiesResult, contractstatussenResult, locatieNiveaus, relID]);

  return (
    <ContractenOverzichtContext.Provider value={contextValue}>
      <Root>
        {context.contracten === null ? (
          <div className="d-flex flex-fill align-items-center justify-content-center">
            <LoadingSpinner />
          </div>
        ) : (
          <MenuLayout
            menu={
              <>
                <div className="d-flex align-items-center">
                  <div
                    style={{
                      width: 85,
                    }}
                  >
                    <SelectieVak
                      aantal={context.selectie.length}
                      totaalAantal={context.contracten.length}
                      onChange={(alles) => {
                        if (alles) {
                          setSelectie(context.contracten!.map((x) => x.CntID));
                        } else {
                          setSelectie([]);
                        }
                      }}
                      entiteitEnkelvoud="contract"
                      entiteitMeervoud="contracten"
                    />
                  </div>

                  <div className="mr-3">
                    <VerticaleScheidingslijn height={20} />
                  </div>

                  <button
                    style={{
                      border: `1px solid ${Kleur.LichtGrijs}`,
                    }}
                    className="btn btn-sm d-flex align-items-center"
                    disabled={bezig}
                    onClick={() => {
                      // Gegevens van het meest recente contract
                      const contract =
                        context.contracten!.length !== 0 ? context.contracten![0] : null;

                      let aantalGebruikers = 0;
                      let verdieping = null;
                      let persID = null;
                      let locID = null;
                      let abonID = null;

                      if (contract !== null) {
                        aantalGebruikers = contract.basis.AantalGebruikers;
                        verdieping = contract.basis.Verdieping;
                        persID = contract.PersID;
                        locID = contract.basis.locatie.LocID;
                        abonID = contract.basis.abonnement.AbonID;
                      }

                      return setNieuwContractDialoogTonen({
                        aantalGebruikers,
                        verdieping,
                        persID,
                        locID,
                        abonID,
                      });
                    }}
                  >
                    <IconToevoegen
                      style={{
                        width: 16,
                        height: 16,
                        fill: Kleur.Grijs,
                        position: 'relative',
                        top: 1,
                      }}
                    />
                    &nbsp; Nieuw contract
                  </button>

                  <button
                    style={{
                      border: `1px solid ${Kleur.LichtGrijs}`,
                    }}
                    className="btn btn-sm d-flex align-items-center ml-3"
                    disabled={bezig || context.selectie.length !== 1}
                    onClick={async () => {
                      const wisselcontract = (
                        await api.v2.contract.ophalenContractenV2({
                          filterSchema: {
                            filters: [
                              {
                                naam: 'IDS',
                                data: [context.selectie[0]],
                              },
                            ],
                          },
                        })
                      ).contracten[0];

                      const checkData = await api.v2.contract.wissel.checkContractVoorWisselen({
                        cntID: wisselcontract.CntID,
                      });
                      const checkResult = await checkStore.controleren({
                        checkData,
                      });
                      if (checkResult.type === EResultType.Annuleren) {
                        return;
                      }

                      return setNieuwContractwisselDialoogTonen({
                        wisselcontract_CntID: wisselcontract.CntID,
                      });
                    }}
                  >
                    <IconContractwissel
                      style={{
                        width: 16,
                        height: 16,
                        fill: Kleur.Grijs,
                        position: 'relative',
                        top: 1,
                      }}
                    />
                    &nbsp; Contractwissel
                  </button>

                  {context.contracten.some(
                    (x) => x.status.NaamEnum === EContractStatus.Concept,
                  ) && (
                    <button
                      style={{
                        border: `1px solid ${Kleur.LichtGrijs}`,
                      }}
                      className={classNames(['btn btn-sm ml-2 btn-light'])}
                      disabled={bezig || context.selectie.length === 0}
                      onClick={() => handleBevestigingsverzoek()}
                    >
                      Bevestigingsverzoek
                    </button>
                  )}

                  {context.contracten.some(
                    (x) => x.status.NaamEnum === EContractStatus.Concept,
                  ) && (
                    <button
                      style={{
                        border: `1px solid ${Kleur.LichtGrijs}`,
                      }}
                      className={classNames(['btn btn-sm ml-2 btn-light'])}
                      disabled={bezig || context.selectie.length === 0}
                      onClick={() => handleAnnulerenContract()}
                    >
                      <IconCancel
                        style={{
                          width: 16,
                          height: 16,
                          fill: Kleur.Grijs,
                        }}
                      />
                      &nbsp; Annuleren
                    </button>
                  )}

                  {context.contracten.some(
                    (x) => x.status.NaamEnum === EContractStatus.Concept,
                  ) && (
                    <button
                      style={{
                        border: `1px solid ${Kleur.LichtGrijs}`,
                      }}
                      className="btn btn-sm btn-light ml-2 d-flex align-items-center"
                      disabled={
                        bezig ||
                        context.selectie.length === 0 ||
                        bepaalTypeFiatterenContracten() === null
                      }
                      onClick={() => {
                        handleFiatterenContracten();
                      }}
                    >
                      <IconVink
                        style={{
                          width: 16,
                          height: 16,
                          fill: Kleur.Grijs,
                        }}
                      />
                      &nbsp; Fiatteren
                    </button>
                  )}

                  {context.contracten.some((x) => x.status.NaamEnum === EContractStatus.Lopend) && (
                    <button
                      style={{
                        border: `1px solid ${Kleur.LichtGrijs}`,
                      }}
                      className="btn btn-sm btn-light ml-2"
                      disabled={bezig || context.selectie.length === 0}
                      onClick={() => handleBeeindigenContracten()}
                    >
                      <IconVlag
                        style={{
                          width: 15,
                          height: 15,
                          position: 'relative',
                          bottom: 2,
                          fill: Kleur.Grijs,
                        }}
                      />
                      &nbsp; Beeïndigen
                    </button>
                  )}

                  <button
                    style={{
                      border: `1px solid ${Kleur.LichtGrijs}`,
                    }}
                    className={classNames(['btn btn-sm ml-2 btn-light'])}
                    disabled={bezig || context.selectie.length !== 1}
                    onClick={() => handleTransportopdracht()}
                  >
                    <IconTransport
                      style={{
                        width: 16,
                        height: 16,
                        fill: Kleur.Grijs,
                        position: 'relative',
                        top: -1,
                      }}
                    />
                    &nbsp; Transportopdracht
                  </button>

                  <div className="flex-fill" />

                  <ActieMenuKnop
                    acties={[
                      // {
                      //   text: 'Model/abonnement wijzigen',
                      //   onClick: () => {
                      //     setModelEnAbonnementWijzigenDialoogState({
                      //       relID: props.relID,
                      //       cntIDs: context.selectie,
                      //     });
                      //   },
                      //   disabled: bezig || context.selectie.length === 0,
                      //   icon: <IconAanbod style={{ width: 15, height: 15 }} />,
                      // },
                      {
                        text: 'Aanbieden voor overname',
                        onClick: () => handleAanbiedenContract(),
                        disabled: bezig || context.selectie.length === 0,
                        icon: (
                          <IconAanbiedenOvername
                            style={{
                              width: 15,
                              height: 15,
                            }}
                          />
                        ),
                      },
                      // {
                      //   text: 'Aanbieden voor contractwissel',
                      //   onClick: () => handleAanbiedenVoorContractwissel(),
                      //   disabled: bezig || context.selectie.length === 0,
                      //   icon: <IconContractwissel style={{ width: 15, height: 15 }} />,
                      // },
                      {
                        text: 'Verkoopvoorstellen maken',
                        onClick: () => handleMakenVerkoopvoorstellen(),
                        disabled: bezig || context.selectie.length === 0,
                        icon: (
                          <IconVerkoopvoorstel
                            style={{
                              width: 15,
                              height: 15,
                            }}
                          />
                        ),
                      },
                      {
                        text: 'Annuleren contracten',
                        onClick: () => handleAnnulerenContract(),
                        disabled: bezig || context.selectie.length === 0,
                        icon: (
                          <IconCancel
                            style={{
                              width: 15,
                              height: 15,
                            }}
                          />
                        ),
                      },
                      {
                        text: 'Afkeuren contracten',
                        onClick: () => handleAfkeurenContract(),
                        disabled: bezig || context.selectie.length === 0,
                        icon: (
                          <IconDuimOmlaag
                            style={{
                              width: 15,
                              height: 15,
                            }}
                          />
                        ),
                      },
                      {
                        text: 'Overnemen contracten',
                        onClick: () => {
                          handleOvernemenContract();
                        },
                        icon: (
                          <IconContractovername
                            style={{
                              width: 15,
                              height: 15,
                            }}
                          />
                        ),
                      },
                      {
                        text: 'Bevestiging beeïndiging versturen',
                        onClick: () => handleBevestigingBeeindigingVersturen(),
                        disabled: bezig || context.selectie.length === 0,
                        icon: (
                          <IconSend
                            style={{
                              width: 15,
                              height: 15,
                            }}
                          />
                        ),
                      },
                      {
                        text: 'Bevestiging nieuwe contracten versturen',
                        onClick: () => handleVersturenBevestiging(),
                        disabled: bezig || context.selectie.length === 0,
                        icon: (
                          <IconSend
                            style={{
                              width: 15,
                              height: 15,
                            }}
                          />
                        ),
                      },
                      {
                        text: 'Bevestiging overname nieuwe huurder versturen',
                        onClick: () => handleVersturenBevestigingOvernameNieuweHuurder(),
                        disabled: bezig || context.selectie.length === 0,
                        icon: (
                          <IconSend
                            style={{
                              width: 15,
                              height: 15,
                            }}
                          />
                        ),
                      },
                      {
                        text: 'Locatie wijzigen',
                        onClick: () => {
                          const contracten = context.selectie.map(
                            (id) => context.contracten!.find((c) => c.CntID === id)!,
                          )!;
                          const allemaalDezelfdeLocatie = contracten.every(
                            (c) => c.basis.locatie.LocID === contracten[0].basis.locatie.LocID,
                          );
                          const loc = contracten[0].basis.locatie;

                          setLocatieWijzigenDialoogState({
                            cntIDs: context.selectie,
                            locatieVanProductenNietMeeWijzigen: false,
                            correspondentieadresMeeWijzigen: false,
                            initialValues: {
                              adres: allemaalDezelfdeLocatie
                                ? {
                                    bisnummer: loc.Bisnummer ?? '',
                                    huisnummer: loc.Huisnummer,
                                    plaatsnaam: loc.Plaatsnaam,
                                    postcode: loc.Postcode,
                                    landID: loc.LandID,
                                    straatnaam: loc.Straatnaam,
                                  }
                                : null,
                              bezoekinstructies: '',
                              bijzonderheden: '',
                              liftAanwezig: false,
                            },
                          });
                        },
                        disabled: bezig || context.selectie.length === 0,
                        icon: (
                          <IconLocatie
                            style={{
                              width: 15,
                              height: 15,
                            }}
                          />
                        ),
                      },
                      {
                        text: 'Interne locatie wijzigen',
                        onClick: () => {
                          setInterneLocatieWijzigenDialoogState({
                            cntIDs: context.selectie,
                          });
                        },
                        disabled:
                          bezig ||
                          context.selectie.length === 0 ||
                          // Als de locaties verschillend zijn, dan mag deze knop niet gebruikt worden.
                          context.selectie
                            .map((cntID) => context.contracten!.find((x) => x.CntID === cntID))
                            .some((contract, _, geselecteerdeContracten) => {
                              // Als de selectie wordt veranderd, dan kan het zijn dat de contracten op dat moment nog niet zijn bijgewerkt.
                              // Waardoor er een selectie ontstaat van contracten die nog niet opgehaald zijn.
                              // Dan willen we dus tijdelijk dat de knop niet beschikbaar is.
                              if (contract === undefined) {
                                return true;
                              }

                              return (
                                contract.basis.locatie.LocID !==
                                geselecteerdeContracten[0]!.basis.locatie.LocID
                              );
                            }),
                        icon: (
                          <IconInterneLocatie
                            style={{
                              width: 15,
                              height: 15,
                            }}
                          />
                        ),
                      },
                      // {
                      //   text: 'Standaard leveropdracht',
                      //   onClick: () => handleLeveropdrachten('L'),
                      //   disabled: bezig || context.selectie.length === 0,
                      // },
                      // {
                      //   text: 'Standaard retouropdracht',
                      //   onClick: () => handleLeveropdrachten('R'),
                      //   disabled: bezig || context.selectie.length === 0,
                      // },
                      {
                        text: 'Toepassen actie op contract(en)',
                        onClick: () => {
                          setToepassenActieDialoogState({
                            cntIDs: context.selectie,
                          });

                          return;
                        },
                        disabled: bezig || context.selectie.length === 0,
                        icon: (
                          <IconTariefactie
                            style={{
                              width: 15,
                              height: 15,
                            }}
                          />
                        ),
                      },
                      {
                        text: 'Verwijderen actie voor contract(en)',
                        onClick: async () => {
                          const params = {
                            cntIDs: context.selectie,
                          };

                          const checkData = await api.v2.contract.checkVerwijderenActieOpContracten(
                            params,
                          );
                          if (
                            (
                              await checkStore.controleren({
                                checkData,
                              })
                            ).type === EResultType.Annuleren
                          ) {
                            return;
                          }

                          if (
                            (
                              await checkStore.bevestigen({
                                inhoud: `Wil je de acties voor de geselecteerde contracten verwijderen?`,
                              })
                            ).type === EResultType.Annuleren
                          ) {
                            return;
                          }

                          await api.v2.contract.verwijderenActieOpContracten(params);

                          await verversen();

                          return;
                        },
                        disabled: bezig || context.selectie.length === 0,
                        icon: (
                          <IconVerwijderen
                            style={{
                              width: 15,
                              height: 15,
                            }}
                          />
                        ),
                      },
                      {
                        text: 'Verwijderen contracten',
                        onClick: () => handleVerwijderenContracten(),
                        disabled: bezig || context.selectie.length === 0,
                        icon: (
                          <IconVerwijderen
                            style={{
                              width: 15,
                              height: 15,
                            }}
                          />
                        ),
                      },
                      {
                        text: 'Standaard leveropdrachten maken',
                        onClick: () => handleStandaardLeveropdrachten(),
                        disabled: bezig || context.selectie.length === 0,
                        icon: (
                          <IconTransport
                            style={{
                              width: 15,
                              height: 15,
                            }}
                          />
                        ),
                      },
                      {
                        text: 'Verversen',
                        onClick: () => verversen(),
                        disabled: bezig,
                        icon: (
                          <IconVernieuwen
                            style={{
                              width: 15,
                              height: 15,
                            }}
                          />
                        ),
                      },
                    ]}
                  />
                </div>
                <div
                  className="d-flex align-items-center mt-2"
                  style={{
                    position: 'relative',
                    left: -7,
                  }}
                >
                  <FilterBalkV2
                    filters={filters}
                    filterData={context.filterData}
                    onFilterDataChange={async (x) => {
                      setFilterData(x);
                    }}
                  />
                </div>
              </>
            }
            body={
              <div className="d-flex flex-fill">
                <LocatieContractenTabel
                  locatieContracten={locatieContracten!}
                  contractSelectie={context.selectie}
                  onContractSelectieChange={(x) => setSelectie(x)}
                  locatiesUitgeklapt={context.contractlocatiesUitgeklapt}
                  onLocatiesUitgeklaptChange={(x) => setContractlocatiesUitgeklapt(x)}
                  onRequestRefresh={verversen}
                />
              </div>
            }
          />
        )}
      </Root>
      {context.versturenUitnodigingTonen &&
        klantkaartStore.relatie !== null &&
        klantkaartStore.relatie.RelID === relID && (
          <VersturenUitnodigingenOvernameDialoog
            open
            relID={relID!}
            persID={klantkaartStore.relatie.persoon!.PersID}
            onSuccess={() => setVersturenUitnodigingTonen(false)}
            onAnnuleren={() => setVersturenUitnodigingTonen(false)}
          />
        )}
      {context.aanbiedenOvernameDialoogTonen && (
        <AanbiedenOvernameDialoog
          open
          relID={relID!}
          cntIDs={context.selectie}
          onSuccess={handleAanbiedenOvernameDialoogSuccess}
          onAnnuleren={() => setAanbiedenOvernameDialoogTonen(false)}
        />
      )}

      {/* {context.nieuwContractenDialoogState !== null && (
        <NieuwContractDialoog
          open
          onSuccess={handleNieuwContractDialoogSuccess}
          onAnnuleren={() => setNieuwContractDialoogTonen(null)}
          contractwissel_CntID={42474}
          relID={relID!}
          prodModID={21}
          abonID={context.nieuwContractenDialoogState.abonID}
          locID={context.nieuwContractenDialoogState.locID}
          persID={context.nieuwContractenDialoogState.persID}
          aantalGebruikers={context.nieuwContractenDialoogState.aantalGebruikers}
          aansluitenTrekschakelaar={null}
          verdieping={context.nieuwContractenDialoogState.verdieping}
        />
      )} */}

      {context.nieuwContractDialoogState !== null && (
        <NieuwContractDialoogV2
          open
          onSuccess={async (result) => {
            if (result.leveropdrachtMaken) {
              await handleTransportopdrachtenMaken({
                relID: relID,
                cntID: result.cntID,
                wisselcontractCntID: null,
                trsOpdID: result.trsOpdID,
              });
            }
            await verversen();
            setNieuwContractDialoogTonen(null);
          }}
          onAnnuleren={() => setNieuwContractDialoogTonen(null)}
          relID={relID!}
          // prodModID={21}
          // abonID={context.nieuwContractenDialoogState.abonID}
          // locID={context.nieuwContractenDialoogState.locID}
          // persID={context.nieuwContractenDialoogState.persID}
          // aantalGebruikers={context.nieuwContractenDialoogState.aantalGebruikers}
          // aansluitenTrekschakelaar={null}
          // verdieping={context.nieuwContractenDialoogState.verdieping}
        />
      )}

      {context.nieuwContractwisselDialoogState !== null && (
        <NieuwContractDialoogV2
          open
          onSuccess={async (result) => {
            if (result.leveropdrachtMaken) {
              await handleTransportopdrachtenMaken({
                relID: relID,
                cntID: result.cntID,
                wisselcontractCntID: result.wisselcontract_CntID,
                trsOpdID: result.trsOpdID,
              });
            }
            await verversen();
            setNieuwContractwisselDialoogTonen(null);
          }}
          onAnnuleren={() => setNieuwContractwisselDialoogTonen(null)}
          wisselcontract_CntID={context.nieuwContractwisselDialoogState.wisselcontract_CntID}
          relID={relID!}
        />
      )}

      {context.annulerenContractenDialoogTonen && (
        <AnnulerenContractDialoog
          open
          onSuccess={async () => {
            await verversen();
            setAnnulerenContractenDialoogTonen(false);
          }}
          onAnnuleren={() => {
            setAnnulerenContractenDialoogTonen(false);
            // handleRequestRefresh();
          }}
          relID={relID!}
          cntIDs={context.selectie}
        />
      )}
      {context.afkeurenContractDialoogTonen && (
        <AfkeurenContractDialoog
          open
          onSuccess={async () => {
            await verversen();
            setAfkeurenContractDialoogTonen(false);
          }}
          onAnnuleren={() => {
            setAfkeurenContractDialoogTonen(false);
            // handleRequestRefresh();
          }}
          relID={relID!}
          cntIDs={context.selectie}
        />
      )}
      {context.beeindigContractenDialoogTonen && (
        <BeeindigenContractDialoog
          open
          relID={relID!}
          cntIDs={context.selectie}
          onSuccess={handleBeeindigenContractenDialoogSuccess}
          onAnnuleren={() => setBeeindigenContractenDialoogTonen(false)}
        />
      )}
      {context.fiatterenNieuwDialoogTonen && (
        <FiatterenNieuwDialoog
          open
          relID={relID!}
          cntIDs={context.selectie}
          onSuccess={async () => {
            await verversen();
            setFiatterenNieuwDialoogTonen(false);
          }}
          onAnnuleren={() => setFiatterenNieuwDialoogTonen(false)}
        />
      )}
      {context.nieuwVerkoopvoorstelDialoogState !== null && (
        <NieuwVerkoopvoorstelDialoog
          open
          relID={context.nieuwVerkoopvoorstelDialoogState.relID!}
          prodIDs={context.nieuwVerkoopvoorstelDialoogState.prodIDs}
          onSuccess={async () => {
            await verversen();
            setNieuwVerkoopvoorstelDialoogState(null);
          }}
          onAnnuleren={() => setNieuwVerkoopvoorstelDialoogState(null)}
        />
      )}
      {context.fiatterenOvernameDialoogTonen && (
        <FiatterenOvernameDialoog
          open
          relID={relID!}
          cntIDs={context.selectie}
          onSuccess={async () => {
            await verversen();
            setFiatterenOvernameDialoogTonen(false);
          }}
          onAnnuleren={() => setFiatterenOvernameDialoogTonen(false)}
        />
      )}
      {context.nieuweTransportopdrachtDialoogState !== null && (
        <NieuweTransportopdrachtDialoog
          open
          relID={relID}
          contractID={context.selectie.length === 1 ? context.selectie[0] : undefined}
          soortOpdracht={context.nieuweTransportopdrachtDialoogState.soortopdracht}
          leverProduct={null}
          onSuccess={async () => {
            await verversen();
            setNieuweTransportopdrachtDialoogTonen(null);
          }}
          onAnnuleren={() => setNieuweTransportopdrachtDialoogTonen(null)}
        />
      )}
      {context.toepassenActieDialoogState !== null && (
        <ActieSelectieDialoog
          open
          onSuccess={async (data) => {
            const params = {
              tarActieID: data.tarActieID,
              cntIDs: context.selectie,
            };
            const checkData = await api.v2.contract.checkToepassenActieOpContracten(params);
            const controleResult = await checkStore.controleren({
              checkData,
            });
            if (controleResult.type === EResultType.Annuleren) {
              return;
            }

            if (
              (
                await checkStore.bevestigen({
                  inhoud: 'Wil je de actie toepassen op de geselecteerde contracten?',
                })
              ).type === EResultType.Annuleren
            ) {
              return;
            }

            await api.v2.contract.toepassenActieOpContracten(params);

            await verversen();
            setToepassenActieDialoogState(null);
          }}
          onAnnuleren={() => setToepassenActieDialoogState(null)}
        />
      )}
      {context.locatieWijzigenDialoogState !== null && (
        <LocatieSelecterenOfOpvoerenDialoog
          open
          initialValues={context.locatieWijzigenDialoogState.initialValues}
          onSuccess={async (result) => {
            await api.v2.contract.wijzigenLocatieVanContracten({
              locID: result.locID,
              cntIDs: context.locatieWijzigenDialoogState!.cntIDs,
            });

            // Locatie van gekopppelde producten ook meewijzigen?
            if (!context.locatieWijzigenDialoogState!.locatieVanProductenNietMeeWijzigen) {
              const contracten = context.contracten!.filter(
                (x) => context.locatieWijzigenDialoogState!.cntIDs.indexOf(x.CntID) !== -1,
              );
              const prodIDs = _.flatten(contracten.map((x) => x.producten.map((x) => x.ProdID)));

              await api.v2.product.wijzigenLocatieVanProducten({
                locID: result.locID,
                prodIDs,
              });
            }

            // Correspondentieadres meewijzigen?
            if (context.locatieWijzigenDialoogState!.correspondentieadresMeeWijzigen) {
              const contracten = context.contracten!.filter(
                (x) => context.locatieWijzigenDialoogState!.cntIDs.indexOf(x.CntID) !== -1,
              );
              const relIDs = _.uniq(contracten.map((x) => x.RelID));

              const locatie = (
                await api.v2.locatie.ophalenLocaties({
                  filterSchema: {
                    filters: [
                      {
                        naam: 'IDS',
                        data: [result.locID],
                      },
                    ],
                  },
                })
              ).locaties[0];

              if (relIDs.length === 1) {
                await api.v2.relatie.wijzigenCorrespondentieadres({
                  relID: relIDs[0],
                  adres: {
                    straatnaam: locatie.Straatnaam,
                    huisnummer: locatie.Huisnummer,
                    bisnummer: locatie.Bisnummer,
                    postcode: locatie.Postcode,
                    plaatsnaam: locatie.Plaatsnaam,
                    landID: locatie.LandID,
                  },
                });
              }
            }

            setLocatieWijzigenDialoogState(null);
            await verversen();
          }}
          onAnnuleren={() => setLocatieWijzigenDialoogState(null)}
          titel="Locatie wijzigen van contracten"
          uitbreiding={
            <div className="p-3">
              <div className="d-flex align-items-center">
                <VinkVeld
                  aangevinkt={context.locatieWijzigenDialoogState.correspondentieadresMeeWijzigen}
                  onGewijzigd={(x) => {
                    setLocatieWijzigenDialoogState({
                      ...context.locatieWijzigenDialoogState!,
                      correspondentieadresMeeWijzigen: x,
                    });
                  }}
                />
                <span className="ml-2">Correspondentieadres meewijzigen</span>
                {/* <span className="ml-2">
                  <UitlegTooltip
                    inhoud={
                      <div>
                        <p>
                          Aan ieder contracten zitten producten gekoppeld, de locatie kan in
                          principe niet overeenkomen met de contractlocatie; echter is dit vaak niet
                          het geval.
                        </p>
                        <p>
                          Door dit veld aan te vinken geef je aan dat de locatie van het product
                          niet mee moet veranderen met de locatie van het contract.
                        </p>
                      </div>
                    }
                  />
                </span> */}
              </div>

              <div className="d-flex align-items-center mt-2">
                <VinkVeld
                  aangevinkt={
                    context.locatieWijzigenDialoogState.locatieVanProductenNietMeeWijzigen
                  }
                  onGewijzigd={(x) => {
                    setLocatieWijzigenDialoogState({
                      ...context.locatieWijzigenDialoogState!,
                      locatieVanProductenNietMeeWijzigen: x,
                    });
                  }}
                />
                <span className="ml-2">Locatie van producten niet mee wijzigen</span>
                <span className="ml-2">
                  <UitlegTooltip
                    inhoud={
                      <div>
                        <p>
                          Aan ieder contracten zitten producten gekoppeld, de locatie kan in
                          principe niet overeenkomen met de contractlocatie; echter is dit vaak niet
                          het geval.
                        </p>
                        <p>
                          Door dit veld aan te vinken geef je aan dat de locatie van het product
                          niet mee moet veranderen met de locatie van het contract.
                        </p>
                      </div>
                    }
                  />
                </span>
              </div>
            </div>
          }
        />
      )}
      {context.interneLocatieWijzigenDialoogState !== null &&
        context.contracten !== null &&
        (() => {
          const contracten = context.contracten!.filter(
            (cnt) => context.interneLocatieWijzigenDialoogState!.cntIDs.indexOf(cnt.CntID) !== -1,
          );
          // Elk contract moet dezelfde locatie hebben vandaar dat het eerste contract voldoet
          const locIDs = [contracten[0].basis.locatie.LocID];

          const voorTeVullenLocIntID = contracten.every(
            (cnt) =>
              // Zijn alle interne locaties gelijk?
              cnt.basis.interneLocatie?.ID === contracten[0].basis.interneLocatie?.ID,
          )
            ? contracten[0].basis.interneLocatie?.ID ?? null
            : null;

          return (
            <InterneLocatieSelectieDialoog
              interneLocatiesProvider={async () => {
                const result = await api.v2.locatie.ophalenInterneLocaties({
                  filterSchema: {
                    filters: [
                      {
                        naam: 'LOC_IDS',
                        data: locIDs,
                      },
                    ],
                  },
                });

                return result.interneLocaties;
              }}
              initieelLocIntIDProvider={async () => {
                return voorTeVullenLocIntID;
              }}
              open
              onSuccess={async (result) => {
                setInterneLocatieWijzigenDialoogState(null);

                await api.v2.contract.wijzigenInterneLocatieVanContracten({
                  locIntID: result.locIntID,
                  cntIDs: contracten.map((x) => x.CntID),
                });
                await verversen();

                // TODO vastleggen van de interne locatie voor deze contracten
                // await api.v2.locatie.inte
              }}
              onAnnuleren={() => setInterneLocatieWijzigenDialoogState(null)}
            />
          );
        })()}
      {context.modelEnAbonnementWijzigenDialoogState !== null && (
        <ModelEnAbonnementWijzigenDialoog
          open
          relID={context.modelEnAbonnementWijzigenDialoogState.relID}
          cntIDs={context.modelEnAbonnementWijzigenDialoogState.cntIDs}
          onSuccess={() => setModelEnAbonnementWijzigenDialoogState(null)}
          onAnnuleren={() => setModelEnAbonnementWijzigenDialoogState(null)}
        />
      )}
      {context.standaardLeveropdrachtenDialoogState !== null && (
        <ToevoegenStandaardLeveropdrachtenDialoog
          open
          cntIDs={context.standaardLeveropdrachtenDialoogState.cntIDs}
          onSuccess={() => setStandaardLeveropdrachtenDialoogTonen(null)}
          onAnnuleren={() => setStandaardLeveropdrachtenDialoogTonen(null)}
        />
      )}
      {context.overnemenContractDialoogTonen && klantkaartStore.relatie !== null && (
        <OvernemenContractDialoog
          open
          relID={relID!}
          persID={klantkaartStore.relatie.persoon!.PersID}
          onSuccess={async () => {
            await verversen();
            setOvernemenContractDialoogTonen(false);
          }}
          onAnnuleren={() => setOvernemenContractDialoogTonen(false)}
        />
      )}
    </ContractenOverzichtContext.Provider>
  );
});

const Overzicht = withRouter(OverzichtComp);
export default Overzicht;
