import * as React from 'react';
import TegelBasis from '../../../../../../../components/tegel/TegelBasis';
import FormatteerBedrag from '../../../../../../../components/MutatieBedrag';
import { Kleur as EKleur } from '../../../../../../../bedrijfslogica/constanten';
import { IOphalenInformatieVoorOverzichtContract } from '../../../../../../../../../shared/src/api/v2/relatie';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import NavigatiePijl from '../NavigatiePijl';
import { genereerUrlStateQueryParam } from '../../../../../../../core/useUrlState';
import {
  IKEContractenOverzichtUrlState,
  defaultKEContractenOverzichtUrlState,
} from '../../Contracten/Overzicht';
import VerticaleScheidingslijn from '../../../../../../../components/layout/VerticaleScheidingslijn';
import { format } from 'date-fns';
import ContractStatus, {
  ContractStatusIndicatie,
  EContractStatus,
} from '../../Contracten/Overzicht/ContractTegel/ContractStatus';
import { EContractstatus } from '../../../../../../../bedrijfslogica/enums';

interface IProps extends RouteComponentProps {
  relID: number;
  data: IOphalenInformatieVoorOverzichtContract;
}

const Contracttegel = (props: IProps) => {
  const aangebodenVoorOvername = false; //props.data.contracten.some((x) => x.aangebodenOvernameOp !== null);
  // const nogBevestigen = props.data.contracten.some(
  //   (x) => x.status === 'CONCEPT' && x.accID === null && x.datumBevestigd === null,
  // );
  const nogBevestigen = false;

  // Lopende of Concept contracten
  // const contracten = props.data.contracten.filter(
  //   (x) =>
  //     x.statusNaamEnum === EContractStatus.Concept || x.statusNaamEnum === EContractStatus.Lopend,
  // );
  const contracten = props.data.contracten;

  const maxAantalWeerTeGeven = 15;

  return (
    <TegelBasis titel="Contracten">
      <div className="d-flex">
        <div>
          {props.data.contracten.length === 0 ? (
            <span>Er zijn geen contracten aangegaan</span>
          ) : contracten.length === 0 ? (
            <span>Er zijn geen actieve contracten</span>
          ) : (
            <>
              <table>
                <tbody>
                  {contracten
                    .filter((x, i) => i < maxAantalWeerTeGeven)
                    .map((contract) => (
                      <tr key={contract.cntID}>
                        <td style={{ width: 80 }}>{contract.contractnummer}</td>
                        <td style={{ width: 100 }}>
                          {format(new Date(contract.ingangsdatum), 'dd-MM-yyyy')}
                        </td>
                        <td style={{ width: 200 }}>{contract.modelnaam}</td>
                        <td style={{ width: 90 }}>
                          <FormatteerBedrag bedrag={contract.maandhuur} />
                        </td>
                        <td style={{ width: 200 }}>
                          {(
                            contract.locatie.Straatnaam +
                            ' ' +
                            contract.locatie.Huisnummer +
                            ' ' +
                            (contract.locatie.Bisnummer !== null ? contract.locatie.Bisnummer : '')
                          ).trim()}
                        </td>

                        <td style={{ width: 140 }}>
                          <ContractStatus
                            status={contract.statusNaamEnum as EContractStatus}
                            naam={contract.statusNaam}
                          />
                        </td>
                        {/* <td style={{ width: 100 }}>
                          <span className="d-flex align-items-center">
                            <div
                              style={{
                                width: 12,
                                height: 12,
                                borderRadius: '50%',
                                background:
                                  contract.status === EContractstatus.Concept
                                    ? EKleur.Blauw
                                    : EKleur.Groen,
                              }}
                            />
                            <span className="ml-2">
                              {contract.status === EContractstatus.Concept ? 'Concept' : 'Lopend'}
                            </span>
                          </span>
                        </td> */}
                        <td>
                          <NavigatiePijl
                            relID={props.relID}
                            pathWithStateBuilder={() => {
                              const state = genereerUrlStateQueryParam({
                                selectieCntIDs: [contract.cntID],
                                uitgeklapteLocIDs: [contract.locID],
                              });
                              return `contracten/overzicht?state=${state}`;
                            }}
                          />
                        </td>

                        <td style={{ width: 200, paddingLeft: '20px' }}>
                          {contract.aangebodenOvernameOp !== null ? `Aangeboden overname` : ''}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>

              {contracten.length > maxAantalWeerTeGeven && (
                <div className="mt-2">
                  <a
                    href="#"
                    onClick={(ev) => {
                      ev.preventDefault();

                      props.history.push(`/klant/${props.relID}/contracten/overzicht`);
                    }}
                  >
                    Er zijn nog {contracten.length - maxAantalWeerTeGeven}
                    &nbsp;andere contracten...
                  </a>
                </div>
              )}
            </>
          )}
        </div>

        {(aangebodenVoorOvername || nogBevestigen) && (
          <div style={{ height: '90%' }} className="ml-4 mr-4 d-flex align-items-center">
            <VerticaleScheidingslijn />
            <ul>
              {nogBevestigen && <li>Eén of meer contracten moeten bevestigd worden</li>}
              {aangebodenVoorOvername && (
                <li>Eén of meer contracten zijn aangeboden voor overname</li>
              )}
            </ul>
          </div>
        )}
      </div>
    </TegelBasis>
  );
};

export default withRouter(Contracttegel);
