import React, { useMemo } from 'react';
// import { Root } from './Opdrachten/style';
import { RouteComponentProps, withRouter } from 'react-router';
import Tabblad, { ITabblad } from '../../../components/layout/Tabblad';
import Persoon from './Persoon';
import Organisatie from './Persoon';

export enum ETabblad {
  Persoon = 1,
  Organisatie = 2,
}

const pathMap: Record<ETabblad, string> = {
  [ETabblad.Persoon]: '/persoon',
  [ETabblad.Organisatie]: '/organisatie',
};

interface IProps extends RouteComponentProps {}

const Contacten: React.FC<IProps> = (props) => {
  const tabblad = useMemo<ETabblad | null>(() => {
    const matchKey = Object.keys(pathMap).find((key) => {
      const path = pathMap[Number(key) as ETabblad]!;
      return props.location.pathname.endsWith(path);
    });
    if (matchKey === undefined) {
      props.history.push(`${props.match.path}${pathMap[ETabblad.Persoon]}`);
      return null;
    }
    return Number(matchKey) as ETabblad;
  }, [props.location.pathname]);

  const tabbladen = useMemo<ITabblad<ETabblad>[]>(
    () => [
      {
        id: ETabblad.Persoon,
        label: 'Personen',
        content: Persoon,
      },
      {
        id: ETabblad.Organisatie,
        label: 'Organisaties',
        content: Organisatie,
      },
    ],
    [],
  );

  return (
    <>
      {tabblad !== null && (
        <Tabblad<ETabblad>
          geselecteerd={tabblad}
          onSelectieChange={(tab) => {
            props.history.push(`${props.match.path}${pathMap[tab]}`);
          }}
          tabbladen={tabbladen}
        />
      )}
    </>
  );
};

export default withRouter(Contacten);
