import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import useUrlState from '../../../core/useUrlState';
import { RouteComponentProps } from 'react-router-dom';
import LoadingSpinner from '../../../components/Gedeeld/LoadingSpinner';
import api from '../../../api';
import { IOphalenContactverzoekenResultElement } from '../../../../../shared/src/api/v2/contactverzoek';
import {
  DXTableCheckboxComponent,
  DXTableToggleCellComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../helpers/dxTableGrid';
import {
  Grid,
  VirtualTable,
  TableHeaderRow,
  TableSelection,
  TableColumnResizing,
  TableRowDetail,
} from '@devexpress/dx-react-grid-bootstrap4';
import { Kleur } from '../../../bedrijfslogica/constanten';
import { DataTypeProvider, RowDetailState, SelectionState } from '@devexpress/dx-react-grid';
import { format } from 'date-fns';
import nameof from '../../../core/nameOf';
import TelefoonComponent from '../../../components/communicatie/TelefoonComponent';
import { formatteerDatum } from '../../../helpers/datum';
import EmailComponent from '../../../components/communicatie/EmailComponent';
import _ from 'lodash';
import ContactKoppelComponent from '../../../components/ContactKoppelComponent';
import { defaultFormValues } from '../../../components/formulier/PersoonFormulier';
import { Helmet } from 'react-helmet';

export interface IElement {
  id: number;
  relID: number | null;
  datum: Date;
  datumGeannuleerd: Date | null;
  naam: string;
  vraag: string;
  persoon: {
    PersID: number;
    Voornaam: string | null;
    Voorletters: string | null;
    Voorvoegsel: string | null;
    Achternaam: string;
  } | null;
  organisatie: {
    OrgID: number;
  } | null;
  telefoonNummer: string | null;
  terugbellen: {
    datum: Date | null;
    van: string | null;
    tot: string | null;
  } | null;
  email: string | null;
}

interface IProps extends RouteComponentProps {}

export interface IUrlState {
  selectie: number[];
  uitgeklapt: number[];
  scrollenNaarID?: number;
}

const defaultUrlState: IUrlState = {
  selectie: [],
  uitgeklapt: [],
  scrollenNaarID: undefined,
};

const Contactverzoek: React.FC<IProps> = (props) => {
  const virtualTableRef = useRef<typeof VirtualTable>(null);

  const [urlState, setUrlState, setUrlStateSync] = useUrlState<IUrlState>(props, defaultUrlState);
  const [contactverzoeken, setContactverzoeken] = useState<
    IOphalenContactverzoekenResultElement[] | null
  >(null);

  const ophalenContactverzoeken = useCallback(async () => {
    const contactverzoekenResult = await api.v2.contactverzoek.ophalenContactverzoeken({
      filterSchema: { filters: [] },
    });

    const contactverzoekenGesorteerd = _.orderBy(
      contactverzoekenResult.contactverzoeken,
      ['Datum'],
      ['desc'],
    );
    setContactverzoeken(contactverzoekenGesorteerd);
  }, []);

  useEffect(() => {
    ophalenContactverzoeken();
  }, []);

  const elementen = useMemo<IElement[] | null>(() => {
    if (contactverzoeken === null) {
      return null;
    }
    return contactverzoeken.map(
      (cv: IOphalenContactverzoekenResultElement): IElement => ({
        id: cv.ID,
        relID: cv.RelID,
        datum: new Date(cv.Datum),
        datumGeannuleerd: cv.DatumGeannuleerd,
        naam: cv.Naam,
        vraag: cv.Vraag,
        email: cv.Email,
        telefoonNummer: cv.Telefoon,

        terugbellen: cv.Terugbellen
          ? {
              datum: cv.TerugbelDatum === null ? null : new Date(cv.TerugbelDatum),
              tot: cv.TerugbelTijdTot,
              van: cv.TerugbelTijdVan,
            }
          : null,
        persoon:
          cv.persoon !== null
            ? {
                PersID: cv.persoon.PersID,
                Voornaam: cv.persoon.Voornaam,
                Voorletters: cv.persoon.Voorletters,
                Voorvoegsel: cv.persoon.Voorvoegsel,
                Achternaam: cv.persoon.Achternaam,
              }
            : null,
        organisatie:
          cv.organisatie !== null
            ? {
                OrgID: cv.organisatie.OrgID,
              }
            : null,
      }),
    );
  }, [contactverzoeken]);

  const keyExtractor = useCallback((element: IElement) => element.id, []);

  const kolommen = useMemo<Array<TypedColumn<IElement>>>(
    () => [
      {
        name: 'datum',
        title: 'Datum verzoek',
      },
      // {
      //   name: 'datumGeannuleerd',
      //   title: 'Ann.',
      // },
      {
        name: '__contact' as any,
        title: 'Contact',
      },
      // {
      //   name: 'naam',
      //   title: 'Opgegeven naam',
      // },
      {
        name: 'vraag',
        title: 'Vraag',
      },
      {
        name: 'terugbellen',
        title: 'Terugbellen',
      },
      {
        name: 'telefoonNummer',
        title: 'Telefoon',
      },
      {
        name: 'email',
        title: 'Email',
      },
      // {
      //   name: '__emailOfTelefoon',
      //   title: 'Telefoon/Email',
      // },
    ],
    [],
  );

  const kolomBreedtes = useMemo<Array<TypedTableColumnWidthInfo<IElement>>>(
    () => [
      {
        columnName: 'datum',
        width: 150,
      },
      // {
      //   columnName: 'datumGeannuleerd',
      //   width: 60,
      // },
      // {
      //   columnName: 'naam',
      //   width: 135,
      // },
      {
        columnName: '__contact' as any,
        width: 275,
      },
      {
        columnName: 'vraag',
        width: 475,
      },
      {
        columnName: 'telefoonNummer',
        width: 135,
      },
      {
        columnName: 'terugbellen',
        width: 200,
      },
      {
        columnName: 'email',
        width: 225,
      },
      // {
      //   columnName: '__emailOfTelefoon',
      //   width: 150,
      // },
    ],
    [],
  );

  useEffect(() => {
    if (urlState.scrollenNaarID === undefined) {
      return;
    }
    const intervalHandle = setInterval(() => {
      if (!virtualTableRef.current) {
        return;
      }
      clearInterval(intervalHandle);
      virtualTableRef.current!.scrollToRow(urlState.scrollenNaarID!);
    }, 100);
  }, [urlState.scrollenNaarID, virtualTableRef.current]);

  return (
    <>
      <Helmet>
        <title>Contactverzoek</title>
      </Helmet>
      <div className="d-flex flex-column flex-fill" style={{ backgroundColor: Kleur.Wit }}>
        {elementen == null ? (
          <div className="flex-fill d-flex justify-content-center align-items-center">
            <LoadingSpinner />
          </div>
        ) : (
          <GridStyleWrapper height={'calc(100vh - 60px)'} nietMiddenUitlijnen>
            <Grid getRowId={keyExtractor} rows={elementen} columns={kolommen}>
              <DataTypeProvider
                for={['__contact']}
                formatterComponent={(formatterProps) => {
                  const row = formatterProps.row as IElement;
                  // if (row.persoon === null) {
                  //   return <span>{row.naam}</span>;
                  // }

                  return (
                    <>
                      <ContactKoppelComponent
                        persID={row.persoon === null ? null : row.persoon.PersID}
                        orgID={row.organisatie === null ? null : row.organisatie.OrgID}
                        onChange={async (persID, orgID) => {
                          await api.v2.contactverzoek.muterenContactVanContactverzoek({
                            ctcVerzID: row.id,
                            persID,
                            orgID,
                          });
                          await ophalenContactverzoeken();
                        }}
                        ongekoppeldComponent={(p) => (
                          <a
                            href="#"
                            onClick={(ev) => {
                              ev.preventDefault();

                              p.showKoppelDialoog();
                            }}
                          >
                            {row.naam}
                          </a>
                        )}
                        persoonDefaultFormValues={{
                          ...defaultFormValues,
                          telefoon: row.telefoonNummer,
                          email: row.email ?? '',
                          // Eerste letter hoofdletter
                          voornaam: `${row.naam[0].toUpperCase()}${row.naam.slice(1)}`,
                        }}
                        // weergaveNaamPersoonOnbekend={row.naam}
                      />
                      {/*{row.persoon === null ? <span>{row.naam}</span> : <span></span>}*/}
                    </>
                  );

                  // return (
                  //   <PersoonVisualisatie
                  //     persID={row.persoon.PersID}
                  //     relID={row.relID === null ? undefined : row.relID}
                  //   />
                  // );
                }}
              />

              <DataTypeProvider
                for={[nameof<IElement>('datum')]}
                formatterComponent={(formatterProps) => {
                  const row = formatterProps.row as IElement;
                  return <span>{format(row.datum, 'dd-MM-yyyy HH:mm')}</span>;
                }}
              />

              <DataTypeProvider
                for={[nameof<IElement>('datumGeannuleerd')]}
                formatterComponent={(formatterProps) => {
                  const geannuleerd = formatterProps.value !== null;
                  return <span style={{ color: Kleur.Rood }}>{geannuleerd ? 'Ja' : ''}</span>;
                }}
              />

              <DataTypeProvider
                for={[nameof<IElement>('terugbellen')]}
                formatterComponent={(formatterProps) => {
                  const row = formatterProps.row as IElement;
                  if (row.terugbellen === null) {
                    return <span />;
                  }
                  return (
                    <span className="d-flex">
                      {row.terugbellen.datum === null ? (
                        <span>zo spoedig mogelijk</span>
                      ) : (
                        <span className="ml-1">
                          {row.terugbellen.datum > new Date()
                            ? formatteerDatum(row.terugbellen.datum, 'EEEEEE')
                            : formatteerDatum(row.terugbellen.datum, 'dd-MM-yyyy')}
                          &nbsp;&nbsp;
                          {row.terugbellen.van} - {row.terugbellen.tot}
                        </span>
                      )}
                    </span>
                  );
                }}
              />

              <DataTypeProvider
                for={['__emailOfTelefoon']}
                formatterComponent={(formatterProps) => {
                  const row = formatterProps.row as IElement;

                  if (row.datumGeannuleerd !== null) {
                    return <span style={{ color: Kleur.Rood }}>Geannuleerd</span>;
                  }

                  if (row.terugbellen && row.telefoonNummer !== null) {
                    return (
                      <TelefoonComponent
                        telefoonNummer={row.telefoonNummer}
                        options={{ icoonWeergeven: false }}
                      />
                    );
                  }

                  if (row.email === null) {
                    return <span />;
                  }
                  return (
                    <EmailComponent
                      emailAdres={row.email}
                      persID={row.persoon === null ? undefined : row.persoon.PersID!}
                      options={{ icoonWeergeven: false }}
                      defaultSubject="RE: Uw contactverzoek"
                    />
                  );
                }}
              />

              <DataTypeProvider
                for={[nameof<IElement>('telefoonNummer')]}
                formatterComponent={(formatterProps) => {
                  const row = formatterProps.row as IElement;
                  if (row.telefoonNummer === null) {
                    return <span />;
                  }
                  return (
                    <TelefoonComponent
                      telefoonNummer={row.telefoonNummer}
                      options={{ icoonWeergeven: false }}
                    />
                  );
                }}
              />

              <DataTypeProvider
                for={[nameof<IElement>('email')]}
                formatterComponent={(formatterProps) => {
                  const row = formatterProps.row as IElement;
                  if (row.email === null) {
                    return <span />;
                  }
                  return (
                    <EmailComponent
                      emailAdres={row.email}
                      persID={row.persoon === null ? undefined : row.persoon.PersID!}
                      options={{ icoonWeergeven: false }}
                      defaultSubject="RE: Uw contactverzoek"
                    />
                  );
                }}
              />

              <SelectionState
                selection={urlState.selectie}
                onSelectionChange={(x) => setUrlStateSync('selectie', x as number[])}
              />
              {/* <RowDetailState
              expandedRowIds={urlState.uitgeklapt}
              onExpandedRowIdsChange={(x) => setUrlStateSync('uitgeklapt', x as number[])}
            /> */}

              <VirtualTable
                ref={virtualTableRef}
                // Benodigd voor contact koppel component anders valt het dropdown binnen het veld (overflow)
                cellComponent={(cellProps) => <td>{cellProps.children || cellProps.value}</td>}
              />
              <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
              <TableHeaderRow />
              {/* <TableRowDetail
              contentComponent={DetailComp}
              toggleCellComponent={DXTableToggleCellComponent}
            /> */}
              <TableSelection cellComponent={DXTableCheckboxComponent} />
            </Grid>
          </GridStyleWrapper>
        )}
      </div>
    </>
  );
};

export default Contactverzoek;
