import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import DienstFormulier, {
  IFormikValues as IDienstFormulierFormikValues,
  validationSchema as validatieSchema,
} from '../../../DienstFormulier';
import { LeverancierContext } from '../../../../index';
import { Formik, FormikActions, FormikProps, Field, FieldProps } from 'formik';
import { RootStoreContext } from '../../../../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import UitklapFormulier from '../../../../../../components/formulier/UitklapFormulier';
import { Kleur } from '../../../../../../bedrijfslogica/constanten';
import HorizontaleScheidingslijn from '../../../../../../components/layout/HorizontaleScheidingslijn';
import * as Yup from 'yup';
import api from '../../../../../../api';
import { EResultType } from '../../../../../../stores/CheckStore';
import { IWijzigenDienstParams } from '../../../../../../../../shared/src/api/v2/dienst/service';
import nameOf from '../../../../../../core/nameOf';
import FormikVeldFout from '../../../../../../components/formulier/FormikVeldFout';
import VinkVeld from '../../../../../../components/formulier/VinkVeld';
import BedragInput from '../../../../../../components/formulier/BedragInput';
import { IOphalenInstellingenResult } from '../../../../../../../../shared/src/api/v2/instelling';
import LoadingSpinner from '../../../../../../components/Gedeeld/LoadingSpinner';
import FormatteerBedrag from '../../../../../../components/MutatieBedrag';
import UitlegTooltip from '../../../../../../components/formulier/UitlegTooltip';

interface IFormikValues extends IDienstFormulierFormikValues {
  emailOpdracht: string;
  emailOpdrachtCC: string;
  fabrieksservice: boolean;
  isHoofdaannemer: boolean;
  isUitvoerend: boolean;
  telefoonKlanten: string | null;
  emailKlanten: string | null;
  koppelenWerkbonnen: boolean;
  portalURL: string | null;
  inkoopfactuurBijOpdracht: boolean;
  grensbedragService: number | null;
}

export const veldnamen: Partial<Record<keyof IFormikValues, string>> = {
  emailOpdracht: 'Email tbv opdrachten',
  emailOpdrachtCC: 'Email CC',
  fabrieksservice: 'Fabrieksservice',
  isUitvoerend: 'Uitvoerend',
  isHoofdaannemer: 'Hoofdaannemer',
  telefoonKlanten: 'Telefoon tbv klanten',
  emailKlanten: 'Email tbv klanten',
  koppelenWerkbonnen: 'Werkbonnen koppelen aan inkoopfactuur',
  portalURL: 'URL naar het portal waar je een opdracht kunt invoeren',
  inkoopfactuurBijOpdracht: 'Inkoopfactuur meesturen bij opdracht',
  grensbedragService: 'Grensbedrag toestemming reparatie',
};

interface IProps {}

const Dienst: React.FC<IProps> = observer((props) => {
  const { checkStore } = useContext(RootStoreContext);
  const context = useContext(LeverancierContext);
  const servicedienst = context.servicedienst.data!;
  const [dienstUitgeklapt, setDienstUitgeklapt] = useState(false);
  const [serviceUitgeklapt, setServiceUitgeklapt] = useState(true);

  const [instelling, setInstelling] = useState<IOphalenInstellingenResult | null>(null);

  const ophalenInstellingen = useCallback(async () => {
    const result = await api.v2.instelling.ophalenInstellingen({});
    setInstelling(result);
  }, []);

  useEffect(() => {
    ophalenInstellingen();
  }, [ophalenInstellingen]);

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (instelling === null) {
      return null;
    }

    return {
      actief: servicedienst.Actief,
      naam: servicedienst.Naam ?? '',
      naamEnum: servicedienst.NaamEnum ?? '',
      opdWijzeID: servicedienst.OpdWijzeID!,
      parameters: servicedienst.Parameters ?? '',
      emailOpdracht: servicedienst.EmailOpdracht ?? '',
      emailOpdrachtCC: servicedienst.EmailOpdrachtCC ?? '',
      fabrieksservice: servicedienst.Fabrieksservice,
      isHoofdaannemer: servicedienst.IsHoofdaannemer,
      isUitvoerend: servicedienst.IsUitvoerend,
      telefoonKlanten: servicedienst.TelefoonKlanten,
      emailKlanten: servicedienst.EmailKlanten,
      koppelenWerkbonnen: servicedienst.KoppelenWerkbonnenAanFactuur,
      portalURL: servicedienst.PortalURL,
      inkoopfactuurBijOpdracht: servicedienst.InkoopfactuurBijOpdracht,
      grensbedragService: servicedienst.GrensbedragService ?? 0,

      // emailOpdracht: servicedienst.EmailOpdracht,
      // emailOpdrachtCC: servicedienst.EmailOpdrachtCC,
    };
  }, [servicedienst, instelling]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      const naam = values.naam.trim();
      const parameters = values.parameters.trim();
      const emailOpdracht = values.emailOpdracht.trim();
      const emailOpdrachtCC = values.emailOpdrachtCC.trim();

      const params: IWijzigenDienstParams = {
        id: servicedienst.ID,
        relID: context.relID!,
        parameters: parameters === '' ? null : parameters,
        opdWijzeID: values.opdWijzeID,
        naam: naam.length === 0 ? null : naam,
        naamEnum: values.naamEnum,
        actief: values.actief,
        emailOpdracht: emailOpdracht.length === 0 ? null : emailOpdracht,
        emailOpdrachtCC: emailOpdrachtCC.length === 0 ? null : emailOpdrachtCC,
        fabrieksservice: values.fabrieksservice,
        isHoofdaannemer: values.isHoofdaannemer,
        isUitvoerend: values.isUitvoerend,
        telefoonKlanten: values.telefoonKlanten,
        emailKlanten: values.emailKlanten,
        koppelenWerkbonnenAanFactuur: values.koppelenWerkbonnen,
        portalURL: values.portalURL,
        inkoopfactuurBijOpdracht: values.inkoopfactuurBijOpdracht,
        grensbedragService: values.grensbedragService !== 0 ? values.grensbedragService : null,
        prioNr: servicedienst.PrioNr,
      };

      const checkData = await api.v2.dienst.service.checkWijzigenDienst(params);
      if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
        actions.setSubmitting(false);
        return;
      }

      await api.v2.dienst.service.wijzigenDienst(params);
      context.onVerversenServicedienstAangevraagd();

      actions.setSubmitting(false);
    },
    [context.relID, servicedienst, context.onVerversenServicedienstAangevraagd],
  );

  const validationSchema = useMemo(
    () =>
      validatieSchema.concat(
        Yup.object().shape({
          emailOpdracht: Yup.string(),
          emailOpdrachtCC: Yup.string(),
          fabrieksservice: Yup.boolean().required(),
          isHoofdaannemer: Yup.boolean().required(),
          isUitvoerend: Yup.boolean().required(),
        }),
      ),
    [],
  );

  if (initialValues === null) {
    return <LoadingSpinner />;
  }

  return (
    <div className="d-flex flex-column flex-fill">
      <Formik
        enableReinitialize
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
        render={(formikProps: FormikProps<IFormikValues>) => {
          const { submitForm, isSubmitting, isValid, resetForm, dirty } = formikProps;
          return (
            <>
              <div className="d-flex flex-column flex-fill p-3">
                <UitklapFormulier
                  label="Dienst"
                  uitgeklapt={dienstUitgeklapt}
                  onUitgeklaptChange={setDienstUitgeklapt}
                  formulier={<DienstFormulier />}
                />
                <div className="p-2" />
                <UitklapFormulier
                  label="Service"
                  uitgeklapt={serviceUitgeklapt}
                  onUitgeklaptChange={setServiceUitgeklapt}
                  formulier={
                    <div className="p-3">
                      <div className="row">
                        <div className="col-6">
                          <label>{veldnamen.emailOpdracht}</label>
                          <Field
                            name={nameOf<IFormikValues>('emailOpdracht')}
                            render={(fieldProps: FieldProps) => {
                              const { field, form } = fieldProps;
                              return (
                                <div>
                                  <input className="form-control" {...fieldProps.field} />
                                  <FormikVeldFout fieldProps={fieldProps} />
                                </div>
                              );
                            }}
                          />
                        </div>
                        <div className="col-6">
                          <label>{veldnamen.emailOpdrachtCC}</label>
                          <Field
                            name={nameOf<IFormikValues>('emailOpdrachtCC')}
                            render={(fieldProps: FieldProps) => {
                              const { field, form } = fieldProps;
                              return (
                                <div>
                                  <input className="form-control" {...fieldProps.field} />
                                  <FormikVeldFout fieldProps={fieldProps} />
                                </div>
                              );
                            }}
                          />
                        </div>
                      </div>

                      <div className="row mt-3">
                        <div className="col-6">
                          <Field
                            name={nameOf<IFormikValues>('isHoofdaannemer')}
                            render={(fieldProps: FieldProps) => {
                              const { field, form } = fieldProps;
                              return (
                                <div>
                                  <div className="d-flex align-items-center">
                                    <VinkVeld
                                      aangevinkt={field.value}
                                      onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                    />
                                    <span className="ml-2">{veldnamen.isHoofdaannemer}</span>
                                  </div>
                                  <FormikVeldFout fieldProps={fieldProps} />
                                </div>
                              );
                            }}
                          />
                        </div>
                        <div className="col-6">
                          <Field
                            name={nameOf<IFormikValues>('fabrieksservice')}
                            render={(fieldProps: FieldProps) => {
                              const { field, form } = fieldProps;
                              return (
                                <div>
                                  <div className="d-flex align-items-center">
                                    <VinkVeld
                                      aangevinkt={field.value}
                                      onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                    />
                                    <span className="ml-2">{veldnamen.fabrieksservice}</span>
                                  </div>
                                  <FormikVeldFout fieldProps={fieldProps} />
                                </div>
                              );
                            }}
                          />
                        </div>
                      </div>

                      <div className="row mt-3">
                        <div className="col-6">
                          <Field
                            name={nameOf<IFormikValues>('isUitvoerend')}
                            render={(fieldProps: FieldProps) => {
                              const { field, form } = fieldProps;
                              return (
                                <div>
                                  <div className="d-flex align-items-center">
                                    <VinkVeld
                                      aangevinkt={field.value}
                                      onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                    />
                                    <span className="ml-2">{veldnamen.isUitvoerend}</span>
                                  </div>
                                  <FormikVeldFout fieldProps={fieldProps} />
                                </div>
                              );
                            }}
                          />
                        </div>
                      </div>

                      <div className="row mt-3">
                        <div className="col-6">
                          <Field
                            name={nameOf<IFormikValues>('koppelenWerkbonnen')}
                            render={(fieldProps: FieldProps) => {
                              const { field, form } = fieldProps;
                              return (
                                <div>
                                  <div className="d-flex align-items-center">
                                    <VinkVeld
                                      aangevinkt={field.value}
                                      onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                    />
                                    <span className="ml-2">{veldnamen.koppelenWerkbonnen}</span>
                                  </div>
                                  <FormikVeldFout fieldProps={fieldProps} />
                                </div>
                              );
                            }}
                          />
                        </div>
                      </div>

                      <div className="row mt-3">
                        <div className="col-6">
                          <Field
                            name={nameOf<IFormikValues>('inkoopfactuurBijOpdracht')}
                            render={(fieldProps: FieldProps) => {
                              const { field, form } = fieldProps;
                              return (
                                <div>
                                  <div className="d-flex align-items-center">
                                    <VinkVeld
                                      aangevinkt={field.value}
                                      onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                    />
                                    <span className="ml-2">
                                      {veldnamen.inkoopfactuurBijOpdracht}
                                    </span>
                                  </div>
                                  <FormikVeldFout fieldProps={fieldProps} />
                                </div>
                              );
                            }}
                          />
                        </div>
                      </div>

                      <div className="row mt-3">
                        <div className="col-6">
                          <label>{veldnamen.telefoonKlanten}</label>
                          <Field
                            name={nameOf<IFormikValues>('telefoonKlanten')}
                            render={(fieldProps: FieldProps) => {
                              const { field, form } = fieldProps;
                              return (
                                <div>
                                  <input className="form-control" {...fieldProps.field} />
                                  <FormikVeldFout fieldProps={fieldProps} />
                                </div>
                              );
                            }}
                          />
                        </div>
                      </div>

                      <div className="row mt-3">
                        <div className="col-6">
                          <label>{veldnamen.emailKlanten}</label>
                          <Field
                            name={nameOf<IFormikValues>('emailKlanten')}
                            render={(fieldProps: FieldProps) => {
                              const { field, form } = fieldProps;
                              return (
                                <div>
                                  <input className="form-control" {...fieldProps.field} />
                                  <FormikVeldFout fieldProps={fieldProps} />
                                </div>
                              );
                            }}
                          />
                        </div>
                      </div>

                      <div className="row mt-3">
                        <div className="col-6">
                          <div className="d-flex">
                            <label>{veldnamen.grensbedragService} (0 = standaardbedrag </label>
                            <span>
                              <FormatteerBedrag bedrag={instelling!.GrensbedragService} />
                            </span>
                            <span>)</span>
                          </div>
                          <Field
                            name={nameOf<IFormikValues>('grensbedragService')}
                            render={(x: FieldProps<IFormikValues>) => {
                              return (
                                <>
                                  <div>
                                    <BedragInput
                                      value={x.field.value}
                                      onChange={(bedrag) => {
                                        x.form.setFieldValue(x.field.name, bedrag);
                                      }}
                                    />
                                  </div>
                                  <FormikVeldFout fieldProps={x} />
                                </>
                              );
                            }}
                          />
                        </div>
                      </div>

                      <div className="row mt-3">
                        <div className="col-6">
                          <label>{veldnamen.portalURL}</label>
                          <Field
                            name={nameOf<IFormikValues>('portalURL')}
                            render={(fieldProps: FieldProps) => {
                              const { field, form } = fieldProps;
                              return (
                                <div>
                                  <input className="form-control" {...fieldProps.field} />
                                  <FormikVeldFout fieldProps={fieldProps} />
                                </div>
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  }
                />
              </div>
              <HorizontaleScheidingslijn color={Kleur.LichtGrijs} />
              <div className="d-flex p-3">
                <button
                  className="btn btn-sm btn-primary d-flex align-items-center justify-content-center"
                  style={{ width: 100 }}
                  disabled={!isValid || isSubmitting}
                  onClick={submitForm}
                >
                  Ok
                </button>
                <button
                  className="btn btn-sm btn-secondary d-flex align-items-center justify-content-center ml-2"
                  style={{ width: 100 }}
                  disabled={!dirty || isSubmitting}
                  onClick={() => resetForm()}
                >
                  Annuleren
                </button>
              </div>
            </>
          );
        }}
      />
    </div>
  );
});

export default Dienst;
