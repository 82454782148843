import React, { useState } from 'react';
import BronResultaatDialoog from './BronResultaatDialoog';
import { format } from 'date-fns';
import { IconOpenInNew } from '../../../../../../../../../../components/Icons';
import Tegel from '../../../../../../../../../../components/tegel/Tegel';
import { Kleur } from '../../../../../../../../../../bedrijfslogica/constanten';
import VerticaleScheidingslijn from '../../../../../../../../../../components/layout/VerticaleScheidingslijn';
import { IOphalenKrediettoetsenResultElement } from '../../../../../../../../../../../../shared/src/api/v2/kredietwaardigheid';
import KredietwaardigheidIndicatie from '../../../../../../../../../Debiteuren/Beheer/KredietwaardigheidIndicatie';

interface IProps {
  toetsing: IOphalenKrediettoetsenResultElement;
}

const Toetsing: React.FC<IProps> = (props) => {
  const {
    BronResultaat,
    CmgDienstID,
    Datum,
    ID,
    Score,
    persoon,
    Besluit,
    Resultaat,
  } = props.toetsing;

  const [bronResultaatTonen, setBronResultaatTonen] = useState(false);

  return (
    <>
      <Tegel>
        <div className="d-flex">
          {/*<span*/}
          {/*  style={{*/}
          {/*    fontSize: '2.5rem',*/}
          {/*    // padding: '0 25px',*/}
          {/*  }}*/}
          {/*  className="d-flex justify-content-center"*/}
          {/*>*/}
          {/*  <span className="d-flex justify-content-center" style={{ width: 50 }}>*/}
          {/*    {Score === null ? '?' : Score}*/}
          {/*  </span>*/}
          {/*</span>*/}
          <div className="d-flex align-items-center justify-content-center">
            <KredietwaardigheidIndicatie score={Score} grootte={22} />
          </div>
          <div className="ml-2 mr-2">
            <VerticaleScheidingslijn />
          </div>
          <div className="ml-2">
            <div style={{ fontSize: '1.1rem' }}>
              {Besluit === null ? (
                <span className="font-italic">Geen besluit doorgegeven</span>
              ) : (
                <span>{Besluit}</span>
              )}
            </div>
            <div className="mt-2">{Resultaat}</div>
          </div>
          <div className="flex-fill" />
          {/*<div className="ml-2 mr-2">*/}
          {/*  <VerticaleScheidingslijn />*/}
          {/*</div>*/}
          <div className="d-flex flex-column align-items-end">
            <a
              href="#"
              className="btn btn-light d-flex align-items-center"
              onClick={() => setBronResultaatTonen(true)}
              style={{ height: 30 }}
            >
              <IconOpenInNew style={{ width: 16, height: 16, fill: Kleur.Grijs, marginTop: 1 }} />
              <span className="ml-2">Bron</span>
            </a>

            <div className="flex-fill" />
            <span className="text-muted mt-2" style={{ fontSize: '0.8rem' }}>
              {format(new Date(Datum), 'dd-MM-yyyy HH:mm:ss')}
            </span>
          </div>
        </div>
      </Tegel>
      {bronResultaatTonen && (
        <BronResultaatDialoog
          bron={BronResultaat}
          open
          onSuccess={() => setBronResultaatTonen(false)}
          onAnnuleren={() => setBronResultaatTonen(false)}
        />
      )}
    </>
  );
};

export default Toetsing;
