import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import Skeleton from 'react-loading-skeleton';
import {
  EFunctioneleIcon,
  functioneleIconMap,
  IconBellen,
  IconEmail,
  IconKruis,
  IconOrganisatie,
  IconPersoon,
  IconTaal,
  IconWarning,
} from '../../../../Icons';
import { Kleur } from '../../../../../bedrijfslogica/constanten';
import { IOphalenRelatiesResultElementV2 } from '../../../../../../../shared/src/api/v2/relatie';
import { formatteerAdresV2, formatteerRelatieNaam } from '../../../../../helpers';
import EmailComponent from '../../../../communicatie/EmailComponent';
import TelefoonComponent from '../../../../communicatie/TelefoonComponent';
import VeldWeergave from '../../../../formulier/VeldWeergave';
import {
  IOphalenDebiteurenBetaalgedragResultElement,
  IOphalenFinancieleInformatieResult,
} from '../../../../../../../shared/src/api/v2/debiteur';
import api from '../../../../../api';
import { BetaalgedragIndicatie } from '../../../../../paginas/Debiteuren/Beheer/BetaalgedragIndicatie';
import Memo, { ISuggestie } from '../../../../Memo';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../../models/IRemoteData';
import { IOphalenMemosResultElement } from '../../../../../../../shared/src/api/v2/memo';
import { RootStoreContext } from '../../../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import PinKnop from '../../../../kaart/KlantInformatieSectie/Identiteit/PinKnop';
import SjabloonOplosserOpenenKnop from '../../../../kaart/KlantInformatieSectie/Identiteit/SjabloonOplosserOpenenKnop';
import { IOphalenRelatieDuplicaatSuggestiesResult } from '../../../../../../../shared/src/api/v2/relatie/duplicaat';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import RelatieVisualisatie from '../../index';
import { IOphalenKrediettoetsenResultElement } from '../../../../../../../shared/src/api/v2/kredietwaardigheid';
import KredietwaardigheidIndicatie, {
  Kredietscore,
} from '../../../../../paginas/Debiteuren/Beheer/KredietwaardigheidIndicatie';
import { IOphalenContractenResultV4 } from '../../../../../../../shared/src/api/v2/contract';
import relatie from '../../../../../api/v2/relatie';
import FormatteerBedrag from '../../../../MutatieBedrag';
import classNames from 'classnames';

const Root = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Naam = styled.span`
  font-size: 17.5px;
  font-weight: 700;
`;

const Relatienummer = styled.span`
  font-size: 13px;
  font-weight: bold;
`;

const Adres = styled.span`
  font-size: 14px;
`;

const Taal = styled.span``;

interface IProps extends RouteComponentProps {
  relatie: IOphalenRelatiesResultElementV2;
  onSluiten: () => void;
}

const Data = observer((props: IProps) => {
  const { memoStore, gebruikerStore } = useContext(RootStoreContext);

  const [duplicaatSuggestiesResult, setDuplicaatSuggestiesResult] = useState<
    IRemoteData<IOphalenRelatieDuplicaatSuggestiesResult>
  >(createPendingRemoteData());
  const ophalenDuplicaatSuggesties = useCallback(async () => {
    const result = await api.v2.relatie.duplicaat.ophalenRelatieDuplicaatSuggesties({
      filterSchema: {
        filters: [
          {
            naam: 'REL_IDS',
            data: [props.relatie.RelID],
          },
        ],
      },
    });
    setDuplicaatSuggestiesResult(createReadyRemoteData(result));
  }, [props.relatie.RelID]);
  useEffect(() => {
    ophalenDuplicaatSuggesties();
  }, [ophalenDuplicaatSuggesties]);

  const [
    betaalgedrag,
    setBetaalgedrag,
  ] = useState<IOphalenDebiteurenBetaalgedragResultElement | null>(null);
  const [krediettoets, setKrediettoets] = useState<
    IRemoteData<IOphalenKrediettoetsenResultElement | null>
  >(createPendingRemoteData());

  useEffect(() => {
    (async () => {
      const result = (
        await api.v2.debiteur.ophalenDebiteurenBetaalgedrag({
          relIDs: [props.relatie.RelID],
        })
      ).debiteuren[0];

      setBetaalgedrag(result);
    })();
  }, [props.relatie.RelID]);

  const [
    debiteurInformatie,
    setDebiteurInformatie,
  ] = useState<IOphalenFinancieleInformatieResult | null>(null);
  useEffect(() => {
    api.v2.debiteur
      .ophalenFinancieleInformatie({
        relID: props.relatie.RelID,
      })
      .then((result) => {
        setDebiteurInformatie(result);
      });
  }, [props.relatie.RelID]);

  useEffect(() => {
    (async () => {
      const result = await api.v2.kredietwaardigheid.ophalenKrediettoetsen({
        filterSchema: {
          filters: [
            {
              naam: 'REL_IDS',
              data: [props.relatie.RelID],
            },
          ],
        },
        orderSchema: {
          orders: [
            {
              naam: 'DATUM',
              richting: 'DESC',
            },
          ],
        },
        paginatie: {
          index: 0,
          aantal: 1,
        },
      });
      const toets = result.toetsen[0] ?? null;
      setKrediettoets(createReadyRemoteData(toets));
    })();
  }, [props.relatie.RelID]);

  const [suggesties, setSuggesties] = useState<ISuggestie[] | null>(null);

  const ophalenMemoLabels = useCallback(async () => {
    const result = await api.v2.memo.ophalenMemoLabels({});

    setSuggesties(
      result.labels.map((x: any) => {
        return { label: x.Label, inhoud: x.Inhoud };
      }),
    );
  }, []);

  useEffect(() => {
    ophalenMemoLabels();
  }, [ophalenMemoLabels]);

  const MemoIcon = useMemo(() => functioneleIconMap[EFunctioneleIcon.Memo], []);

  const memo = useMemo<IRemoteData<IOphalenMemosResultElement | null>>(() => {
    if (memoStore.memos.state === ERemoteDataState.Pending) {
      return createPendingRemoteData();
    }
    const x = memoStore.memos.data!.find(
      (memo) =>
        memo.AspGebrID === gebruikerStore.gebruiker!.AspGebrID &&
        memo.RelID === props.relatie.RelID,
    );
    return x === undefined ? createReadyRemoteData(null) : createReadyRemoteData(x);
  }, [memoStore.memos, gebruikerStore.gebruiker, props.relatie.RelID]);

  const adres = props.relatie.adres;

  const persID = useMemo(() => {
    return props.relatie.PersoonPrimair_PersID ?? null;
  }, [props.relatie]);
  const email = useMemo(() => {
    switch (props.relatie.Relatiesoort) {
      case 'P':
        return props.relatie.persoon?.Email ?? null;
      case 'O':
        return props.relatie.organisatie?.Email ?? null;
    }
  }, [props.relatie]);
  const telefoon = useMemo(() => {
    switch (props.relatie.Relatiesoort) {
      case 'P':
        return props.relatie.persoon?.TelefoonMobiel ?? null;
      case 'O':
        return props.relatie.organisatie?.Telefoonnummer ?? null;
    }
  }, [props.relatie]);
  const taal = useMemo(() => {
    return props.relatie.persoon?.taal ?? null;
  }, [props.relatie]);

  const [lopendeContracten, setLopendeContracten] = useState<
    IRemoteData<IOphalenContractenResultV4>
  >(createPendingRemoteData());

  const ophalenLopendeContracten = useCallback(async () => {
    const result = await api.v2.contract.ophalenContractenV4({
      filterSchema: {
        filters: [
          {
            naam: 'REL_IDS',
            data: [props.relatie.RelID],
          },
          {
            naam: 'STATUS_NAAM_ENUMS',
            data: ['LOPEND'],
          },
        ],
      },
    });
    setLopendeContracten(createReadyRemoteData(result));
  }, [props.relatie.RelID]);

  useEffect(() => {
    ophalenLopendeContracten();
  }, [ophalenLopendeContracten]);

  return (
    <Root>
      <div className="d-flex align-items-center">
        <div style={{ width: 26 }}>
          {props.relatie.Relatiesoort === 'P' ? (
            <IconPersoon style={{ fill: Kleur.Grijs, width: 26, height: 26 }} />
          ) : (
            <IconOrganisatie style={{ fill: Kleur.Grijs, width: 26, height: 26 }} />
          )}
        </div>
        <div className="flex-fill" style={{ marginLeft: 10 }}>
          <Naam>
            {formatteerRelatieNaam({
              aanhefKort: props.relatie.persoon?.geslacht.AanhefKort,
              achternaam: props.relatie.persoon?.Achternaam,
              organisatienaam: props.relatie.organisatie?.Naam,
              relatiesoort: props.relatie.Relatiesoort,
              voorletters: props.relatie.persoon?.Voorletters,
              voornaam: props.relatie.persoon?.Voornaam,
              voorvoegsel: props.relatie.persoon?.Voorvoegsel,
            })}
          </Naam>
        </div>
        <div style={{ width: 50, marginLeft: 10 }}>
          <Relatienummer>{props.relatie.Relatienummer}</Relatienummer>
        </div>
      </div>
      <div className="d-flex">
        <div className="d-flex flex-column flex-fill">
          <div style={{ marginTop: 5 }}>
            <Adres>
              {formatteerAdresV2({
                bisnummer: adres?.Bisnummer ?? null,
                huisnummer: adres?.Huisnummer ?? null,
                landnaamEnum: adres?.LandnaamEnum ?? null,
                landnaamKort: adres?.LandnaamKort ?? null,
                plaatsnaam: adres?.Plaatsnaam ?? null,
                postcode: adres?.Postcode ?? null,
                straatnaam: adres?.Straatnaam ?? null,
              })}
            </Adres>
          </div>

          {email !== null && (
            <div className="d-flex align-items-center" style={{ marginTop: 5 }}>
              <IconEmail style={{ fill: Kleur.Grijs, width: 16, height: 16 }} />
              <div className="flex-fill" style={{ marginLeft: 10 }}>
                <EmailComponent
                  persID={persID ?? undefined}
                  emailAdres={email}
                  options={{
                    icoonWeergeven: false,
                  }}
                />
              </div>
            </div>
          )}

          {telefoon && (
            <div className="d-flex align-items-center" style={{ marginTop: 5 }}>
              <IconBellen style={{ fill: Kleur.Grijs, width: 16, height: 16 }} />
              <div className="flex-fill" style={{ marginLeft: 10 }}>
                <TelefoonComponent telefoonNummer={telefoon} options={{ icoonWeergeven: false }} />
              </div>
            </div>
          )}

          {debiteurInformatie !== null && (
            <div className="d-flex align-items-center" style={{ marginTop: 5 }}>
              <div className="d-flex flex-fill">
                Openstaand bedrag:{' '}
                <FormatteerBedrag bedrag={debiteurInformatie.facturen.bedragOpenstaand} />
              </div>
            </div>
          )}

          {duplicaatSuggestiesResult.state === ERemoteDataState.Ready &&
            duplicaatSuggestiesResult.data!.suggesties.length > 0 && (
              <div className="d-flex align-items-center" style={{ marginTop: 5 }}>
                <IconWarning style={{ fill: Kleur.LichtGeel, width: 16, height: 16 }} />
                <a
                  href="#"
                  className="flex-fill"
                  style={{ marginLeft: 10 }}
                  onClick={(ev) => {
                    ev.preventDefault();
                    props.history.push(`/backoffice/duplicaten/relatie`);
                  }}
                >
                  Er zijn duplicaat suggesties aanwezig
                </a>
              </div>
            )}
          {props.relatie.Vervangende_RelID !== null && (
            <div className="d-flex align-items-center" style={{ marginTop: 5 }}>
              <IconKruis style={{ fill: Kleur.LichtRood, width: 18, height: 19 }} />
              <span style={{ marginLeft: 10, marginRight: 5 }}>Deze relatie is vervangen door</span>
              <RelatieVisualisatie relID={props.relatie.Vervangende_RelID} />
            </div>
          )}
        </div>

        <div className="d-flex flex-column">
          <div className="flex-fill d-flex flex-column align-items-end">
            <PinKnop relID={props.relatie.RelID} />

            <div onClick={props.onSluiten} style={{ marginTop: 2 }}>
              <SjabloonOplosserOpenenKnop relID={props.relatie.RelID} relatie={props.relatie} />
            </div>

            {memo.state === ERemoteDataState.Ready && memo.data === null && (
              <button
                style={{
                  border: 0,
                  outline: 0,
                  background: 0,
                  marginTop: 2,
                }}
                onClick={async (ev) => {
                  ev.preventDefault();

                  await api.v2.memo.toevoegenMemo({
                    inhoud: '',
                    relID: props.relatie.RelID,
                    aspGebrID: gebruikerStore.gebruiker!.AspGebrID,
                  });
                }}
              >
                <MemoIcon style={{ fill: Kleur.DonkerOranje, width: 17, height: 17 }} />
              </button>
            )}
          </div>

          <div className="d-flex align-items-center">
            {krediettoets.state === ERemoteDataState.Ready && krediettoets.data !== null && (
              <KredietwaardigheidIndicatie
                score={krediettoets.data!.Score! as Kredietscore}
                className="mr-2"
              />
            )}

            {betaalgedrag === null ? (
              <Skeleton width={28} height={22} />
            ) : (
              <BetaalgedragIndicatie status={betaalgedrag.indicatie} />
            )}
          </div>
        </div>
      </div>
      <div style={{ height: 3 }} />
      {suggesties !== null && memo.state === ERemoteDataState.Ready && memo.data !== null && (
        <div className="mt-2">
          <div
            style={{
              display: 'flex',
              marginBottom: 5,
              alignItems: 'flex-end',
            }}
          >
            <div
              style={{
                fontWeight: 'bold',
                alignSelf: 'flex-end',
              }}
            >
              Memo
            </div>
            <div className="flex-fill" />
            <a
              href="#"
              style={{ fontSize: 12 }}
              className="mr-2"
              onClick={async (ev) => {
                ev.preventDefault();
                await memoStore.verwijderMemo(memo.data!.ID);
              }}
            >
              Memo verwijderen
            </a>
          </div>
          <Memo
            memo={memo.data!.Inhoud || ''}
            onMemoChange={(x) => {
              memoStore.wijzigMemo(memo.data!.ID, {
                ...memo.data!,
                Inhoud: x,
              });
            }}
            disabled={memoStore.memoIDsInVerwerking.findIndex((x) => x === memo.data!.ID) !== -1}
            suggesties={suggesties}
          />
        </div>
      )}
      {lopendeContracten.state === ERemoteDataState.Ready &&
        (lopendeContracten.data!.contracten.length === 0 ? (
          <span className="text-muted">Geen lopende contracten</span>
        ) : (
          <span className="text-muted">
            {lopendeContracten.data!.contracten.length} lopende contracten
          </span>
        ))}
      {props.relatie.VasteInfo !== null && (
        <>
          <div
            style={{ borderTop: `1px solid ${Kleur.LichtGrijs}`, marginTop: 8, marginBottom: 8 }}
          />

          <div style={{ marginBottom: 5 }}>
            <VeldWeergave>{props.relatie.VasteInfo}</VeldWeergave>
          </div>
        </>
      )}
      {props.relatie.Geblokkeerd && (
        <span style={{ color: Kleur.Rood }}>
          <b>G E B L O K K E E R D</b>
        </span>
      )}
      <div style={{ borderTop: `1px solid ${Kleur.LichtGrijs}`, marginTop: 5, marginBottom: 8 }} />
      <div style={{ marginBottom: 5 }}>
        <textarea className="form-control" rows={11} disabled>
          {props.relatie.Notities ?? ''}
        </textarea>
      </div>
    </Root>
  );
});

export default withRouter(Data);
