import {
  IBewarenParams,
  IBewarenResult,
  IOphalenEmailAccountsParams,
  IOphalenEmailAccountsResult,
  IVersturenParams,
  IVersturenResult,
} from '../../../../shared/src/api/v2/communicatie/email';
import api from '../index';
import {
  IOphalenGeadresseerdenSuggestiesParams,
  IOphalenGeadresseerdenSuggestiesResult,
} from '../../../../shared/src/api/v2/dienst/email/email';
import {
  IMuterenEmailRelatieContextParams,
  IMuterenEmailRelatieContextResult,
  IOphalenEmailsParams,
  IOphalenEmailsResult,
  IOphalenEmailsVanRelatieParams,
  IOphalenEmailsVanRelatieResult,
} from '../../../../shared/src/api/v2/email-v2';

const email = {
  versturen: async (params: IVersturenParams): Promise<IVersturenResult> => {
    return await api.post('v2/email/versturen', params);
  },
  bewaren: async (params: IBewarenParams): Promise<IBewarenResult> => {
    return await api.post('v2/email/bewaren', params);
  },
  ophalenEmails: async (params: IOphalenEmailsParams): Promise<IOphalenEmailsResult> => {
    return await api.post('v2/email/ophalen-emails', params);
  },
  ophalenEmailsVanRelatie: async (
    params: IOphalenEmailsVanRelatieParams,
  ): Promise<IOphalenEmailsVanRelatieResult> => {
    return await api.post('v2/email/ophalen-emails-van-relatie', params);
  },
  ophalenEmailAccounts: async (
    params: IOphalenEmailAccountsParams,
  ): Promise<IOphalenEmailAccountsResult> => {
    return await api.post('/v2/email/ophalen-email-accounts', params);
  },
  ophalenGeadresseerdenSuggesties: async (
    params: IOphalenGeadresseerdenSuggestiesParams,
  ): Promise<IOphalenGeadresseerdenSuggestiesResult> => {
    return await api.post('/v2/email/ophalen-geadresseerden-suggesties', params);
  },
  muterenEmailRelatieContext: async (
    params: IMuterenEmailRelatieContextParams,
  ): Promise<IMuterenEmailRelatieContextResult> => {
    return await api.post('/v2/email/muteren-email-relatie-context', params);
  },
};

export default email;
