import React, { useEffect } from 'react';
import {
  EBooleanVergelijking,
  EDataType,
  EDateVergelijking,
  ENumberVergelijking,
  EStringVergelijking,
  IVeldOmschrijving,
  Vergelijking,
} from '../../types';
import Combobox, { IOptie } from '../../../formulier/Combobox';

interface IProps {
  veldOmschrijving: IVeldOmschrijving<any>;
  value: Vergelijking | null;
  onChange: (vergelijking: Vergelijking | null) => void;
}

const stringOpties: IOptie<EStringVergelijking>[] = [
  {
    id: EStringVergelijking.Gelijk,
    label: 'Gelijk aan',
  },
  {
    id: EStringVergelijking.ZitIn,
    label: 'Zit in',
  },
  {
    id: EStringVergelijking.BegintMet,
    label: 'Begint met',
  },
  {
    id: EStringVergelijking.EindigtMet,
    label: 'Eindigt met',
  },
];

const numberOpties: IOptie<ENumberVergelijking>[] = [
  {
    id: ENumberVergelijking.Gelijk,
    label: 'Gelijk aan',
  },
  {
    id: ENumberVergelijking.GroterDan,
    label: 'Groter dan',
  },
  {
    id: ENumberVergelijking.KleinerDan,
    label: 'Kleiner dan',
  },
];

const dateOpties: IOptie<EDateVergelijking>[] = [
  {
    id: EDateVergelijking.Gelijk,
    label: 'Gelijk aan',
  },
  {
    id: EDateVergelijking.Voor,
    label: 'Voor',
  },
  {
    id: EDateVergelijking.Na,
    label: 'Na',
  },
];

const booleanOpties: IOptie<EBooleanVergelijking>[] = [
  {
    id: EBooleanVergelijking.Gelijk,
    label: 'Gelijk aan',
  },
];

const VergelijkingInput: React.FC<IProps> = (props) => {
  const { onChange, value, veldOmschrijving } = props;
  const selected = value == null ? null : value.vergelijking;

  useEffect(() => {
    if (selected !== null) {
      return;
    }

    // Voorselectie als er maar een optie is, of de eerste
    switch (veldOmschrijving.dataType) {
      case EDataType.String: {
        const optie = stringOpties[0];
        if (optie !== undefined) {
          onChange({
            type: EDataType.String,
            vergelijking: optie.id,
          });
        }
        break;
      }
      case EDataType.Number: {
        const optie = numberOpties[0];
        if (optie !== undefined) {
          onChange({
            type: EDataType.Number,
            vergelijking: optie.id,
          });
        }
        break;
      }
      case EDataType.Date: {
        // const optie = d[0];
        // if (optie !== undefined) {
        //   onChange({
        //     type: EDataType.Number,
        //     vergelijking: optie.id,
        //   });

        break;
      }
      case EDataType.Boolean: {
        const optie = booleanOpties[0];
        if (optie !== undefined) {
          onChange({
            type: EDataType.Boolean,
            vergelijking: optie.id,
          });
        }
        break;
      }
    }
  }, [selected, veldOmschrijving.dataType]);

  switch (veldOmschrijving.dataType) {
    case EDataType.String:
      return (
        <Combobox
          geselecteerd={selected as EStringVergelijking}
          opties={stringOpties}
          onSelectieChange={(stringVergelijking: EStringVergelijking | null) => {
            onChange(
              stringVergelijking === null
                ? null
                : {
                    type: EDataType.String,
                    vergelijking: stringVergelijking,
                  },
            );
          }}
        />
      );
    case EDataType.Number:
      return (
        <Combobox
          geselecteerd={selected as ENumberVergelijking}
          opties={numberOpties}
          onSelectieChange={(numberVergelijking: ENumberVergelijking | null) => {
            onChange(
              numberVergelijking === null
                ? null
                : {
                    type: EDataType.Number,
                    vergelijking: numberVergelijking,
                  },
            );
          }}
        />
      );
    case EDataType.Date:
      return (
        <Combobox
          geselecteerd={selected as EDateVergelijking}
          opties={dateOpties}
          onSelectieChange={(v: EDateVergelijking | null) => {
            onChange(
              v === null
                ? null
                : {
                    type: EDataType.Date,
                    vergelijking: v,
                  },
            );
          }}
        />
      );
    case EDataType.Boolean:
      return (
        <Combobox
          geselecteerd={selected as EBooleanVergelijking}
          opties={booleanOpties}
          onSelectieChange={(v: EBooleanVergelijking | null) => {
            onChange(
              v === null
                ? null
                : {
                    type: EDataType.Boolean,
                    vergelijking: v,
                  },
            );
          }}
        />
      );
  }
  throw new Error('Not implemented');
};

export default VergelijkingInput;
