import { makeObservable, observable } from 'mobx';
import IRemoteData, { createPendingRemoteData, createReadyRemoteData } from '../models/IRemoteData';
import * as _ from 'lodash';
import api from '../api';
import { IOphalenOpdrachtenResultElement } from '../../../shared/src/api/v2/service/opdracht';

class ServiceopdrachtStore {
  public serviceopdrachten: IRemoteData<
    Record<number, IOphalenOpdrachtenResultElement>
  > = createPendingRemoteData();
  private ophalenServiceopdrachtenQueue: number[] = [];
  private ophalenServiceopdrachtenQueueDebounceHandle: number | null = 0;

  constructor() {
    makeObservable(this, {
      serviceopdrachten: observable,
    });
  }

  public enqueueOphalenServiceopdrachten = (servOpdIDs: number[]) => {
    if (this.ophalenServiceopdrachtenQueueDebounceHandle !== null) {
      clearTimeout(this.ophalenServiceopdrachtenQueueDebounceHandle);
    }
    this.ophalenServiceopdrachtenQueue = _.uniq([
      ...this.ophalenServiceopdrachtenQueue,
      ...servOpdIDs,
    ]);
    this.ophalenServiceopdrachtenQueueDebounceHandle = setTimeout(
      this.ophalenQueuedServiceopdrachten,
      100,
    ) as any;
  };
  private ophalenQueuedServiceopdrachten = async () => {
    this.ophalenServiceopdrachtenQueueDebounceHandle = null;
    const servOpdIDs = this.ophalenServiceopdrachtenQueue;

    if (servOpdIDs.length === 0) {
      return;
    }

    this.ophalenServiceopdrachtenQueue = [];

    const result = await api.v2.service.ophalenOpdrachten({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: servOpdIDs,
          },
        ],
      },
    });
    this.serviceopdrachten = createReadyRemoteData(
      result.opdrachten.reduce((acc, curr) => {
        return {
          ...acc,
          [curr.ServOpdID]: curr,
        };
      }, this.serviceopdrachten.data ?? {}),
    );
  };
}

export default ServiceopdrachtStore;
