import React from 'react';
import LijstItemBasis from '../LijstItemBasis';
import { IPDFPreviewItem } from '../..';
import { AutoSizer } from 'react-virtualized';
import { Document, Page } from 'react-pdf';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';

const StyleWrapper = styled.div<{ width: number; pageWidth: number }>`
  margin-top: 10px;
  min-width: ${(props) => props.width}px;
  max-width: ${(props) => props.width}px;

  .react-pdf__Page {
    left: ${(props) => (props.width - props.pageWidth) / 2}px;
    max-width: ${(props) => props.pageWidth}px;
  }
`;

interface IProps {
  previewItem: IPDFPreviewItem;
  isGeselecteerd: boolean;
  onGeselecteerd: () => void;
}

const PDFLijstItem = (props: IProps) => {
  return (
    <LijstItemBasis isGeselecteerd={props.isGeselecteerd} onGeselecteerd={props.onGeselecteerd}>
      <AutoSizer className="flex-fill d-flex flex-column w-100 h-100">
        {({ width, height }) => {
          const pageWidth = width * 0.6;
          const pageHeight = pageWidth * 1.41;

          return (
            <StyleWrapper pageWidth={pageWidth} width={width}>
              <Document
                file={props.previewItem.bestand.url}
                loading={() => {
                  return <Skeleton width={pageWidth} height={pageHeight} />;
                }}
                error={() => {
                  return (
                    <div
                      style={{ width: pageWidth, height: pageHeight }}
                      className="flex-fill d-flex align-items-center justify-content-center w-100 h-100"
                    >
                      PDF fout
                    </div>
                  );
                }}
              >
                <Page
                  pageNumber={1}
                  width={pageWidth}
                  // height={pageHeight}
                  renderAnnotationLayer={false}
                  renderTextLayer={false}
                  loading={() => {
                    return <Skeleton width={pageWidth} height={pageHeight} />;
                  }}
                />
              </Document>
            </StyleWrapper>
          );
        }}
      </AutoSizer>
    </LijstItemBasis>
  );
};

export default PDFLijstItem;
