import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Grid, Table, TableRowDetail } from '@devexpress/dx-react-grid-bootstrap4';
import {
  DataTypeProvider,
  EditingState,
  IntegratedSorting,
  RowDetailState,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  DXTableToggleCellComponent,
  GridStyleWrapper,
  TypedColumn,
} from '../../../../../../../../helpers/dxTableGrid';
import { formatteerAdresV2 } from '../../../../../../../../helpers';
import { IContract } from '../index';
import RowDetail from './RowDetail';
import LocatieMutatieDialoog, {
  ELocatieMutatieDialoogType,
} from '../../../../../../../../components/formulier/LocatieSelectie/LocatieMutatieDialoog';
import api from '../../../../../../../../api';
import AdresVisualisatie from '../../../../../../../../components/locatie/AdresVisualisatie';
import { Kleur } from '../../../../../../../../bedrijfslogica/constanten';
import BezoekdagenInfoDialoog from '../../../../../../../../components/transport/BezoekdagenInfoDialoog';
import { IconDatumreeks, IconKaart, IconPersoon } from '../../../../../../../../components/Icons';
import { IconButton } from '../../../../../../../../components/locatie/AdresVisualisatie/style';
import UitlegTooltip from '../../../../../../../../components/formulier/UitlegTooltip';
import { AutoSizer } from 'react-virtualized';
import { GlobaleRendererContext } from '../../../../../../../../one-off-components/GlobaleRenderer';
import BeheerPersonenVanLocatieDialoog, {
  IBeheerPersonenVanLocatieDialoogOutput,
} from '../../../../../../../../components/dialogen/BeheerPersonenVanLocatieDialoog';

interface IContactpersoon {
  PersID: number;
}

export interface ILocatieContracten {
  locID: number;
  contracten: IContract[];
  contactpersonen: IContactpersoon[];
}

interface IMuterenLocatieDialoogState {
  locID: number;
}

interface IBezoekdagenInfoDialoogState {
  postcode?: string;
}

interface IProps {
  locatieContracten: ILocatieContracten[];
  contractSelectie: number[];
  onContractSelectieChange: (selectie: number[]) => void;
  locatiesUitgeklapt: number[];
  onLocatiesUitgeklaptChange: (uitgeklapt: number[]) => void;
  onRequestRefresh: () => void;
}

export interface ILocatieContractenTabelContext {
  selectie: number[];
  onSelectieChange: (selectie: number[]) => void;
  onRequestRefresh: () => void;
}

interface ILocatieCellValue {
  tekst: string;
  locatie: {
    LocID: number;
    Straatnaam: string;
    Huisnummer: number;
    Bisnummer: string | null;
    Postcode: string;
    Plaatsnaam: string | null;
    LandID: number;
    LiftAanwezig: boolean;
    Bezoekinstructies: string | null;
    Notities: string | null;
    LandnaamKort: string;
    LandnaamEnum: string;
  };
}

export const LocatieContractenTabelContext = React.createContext<ILocatieContractenTabelContext>(
  null as any,
);

const LocatieContractenTabel: React.FC<IProps> = (props) => {
  const globaleRenderer = useContext(GlobaleRendererContext);

  const kolommen = useMemo<TypedColumn<ILocatieContracten>[]>(() => {
    return [
      {
        name: '__locatie' as any,
        title: 'Locatie',
        getCellValue: (row): ILocatieCellValue => {
          const eersteContract = row.contracten[0];
          const locatie = eersteContract.basis.locatie;
          return {
            tekst: formatteerAdresV2({
              bisnummer: locatie.Bisnummer,
              huisnummer: locatie.Huisnummer,
              landnaamEnum: locatie.LandnaamEnum,
              landnaamKort: locatie.LandnaamKort,
              plaatsnaam: locatie.Plaatsnaam,
              postcode: locatie.Postcode,
              straatnaam: locatie.Straatnaam,
            }),
            locatie,
          };
        },
      },
      {
        name: '__aantalContracten' as any,
        title: '#',
      },
      // {
      //   name: '__bezoekdagen',
      //   title: 'Bezoekdagen',
      // },
      {
        name: '__liftAanwezig',
        title: 'Lift',
      },
      {
        name: '__locatieNotitie',
        title: 'Notitie',
      },
    ];
  }, []);

  // const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<ILocatieContracten>[]>(
  //   () => [
  //     {
  //       columnName: '__locatie' as any,
  //       width: 375,
  //     },
  //     {
  //       columnName: '__aantalContracten' as any,
  //       width: 100,
  //     },
  //     {
  //       columnName: '__locatieNotitie' as any,
  //       width: '100px',
  //     },
  //   ],
  //   [],
  // );

  const keyExtractor = useCallback((row: ILocatieContracten) => row.locID, []);

  const [
    muterenLocatieDialoogState,
    setMuterenLocatieDialoogState,
  ] = useState<IMuterenLocatieDialoogState | null>(null);
  const [
    bezoekdagenInfoDialoogState,
    setBezoekdagenInfoDialoogState,
  ] = useState<IBezoekdagenInfoDialoogState | null>(null);

  const integratedSortingColumnExtensions = useMemo(
    () => [
      {
        columnName: '__locatie',
        compare: (a: ILocatieCellValue, b: ILocatieCellValue) => {
          const aTekst = formatteerAdresV2({
            bisnummer: null,
            huisnummer: 0,
            landnaamEnum: a.locatie.LandnaamEnum,
            landnaamKort: a.locatie.LandnaamKort,
            plaatsnaam: a.locatie.Plaatsnaam,
            postcode: a.locatie.Postcode,
            straatnaam: a.locatie.Straatnaam,
          });
          const bTekst = formatteerAdresV2({
            bisnummer: null,
            huisnummer: 0,
            landnaamEnum: b.locatie.LandnaamEnum,
            landnaamKort: b.locatie.LandnaamKort,
            plaatsnaam: b.locatie.Plaatsnaam,
            postcode: b.locatie.Postcode,
            straatnaam: b.locatie.Straatnaam,
          });
          const tekstCmp = aTekst === bTekst ? 0 : aTekst > bTekst ? 1 : -1;
          // Eerst adres vergelijken, als deze anders zijn, die sortering gebruiken
          if (tekstCmp !== 0) {
            return tekstCmp;
          }
          // Adressen zijn hetzelfde met verschillende huisnummers
          return a.locatie.Huisnummer === b.locatie.Huisnummer
            ? 0
            : a.locatie.Huisnummer > b.locatie.Huisnummer
            ? 1
            : -1;
        },
      },
    ],
    [],
  );

  const columnExtensions = useMemo<Table.ColumnExtension[]>(
    () => [
      {
        columnName: '__locatie',
        width: 390,
      },
      {
        columnName: '__aantalContracten',
        width: 70,
      },
      // {
      //   columnName: '__bezoekdagen',
      //   width: 50,
      // },
      {
        columnName: '__locatieNotitie',
        width: 'auto',
      },
      {
        columnName: '__liftAanwezig',
        width: 50,
      },
    ],
    [],
  );

  const context = useMemo<ILocatieContractenTabelContext>(() => {
    return {
      selectie: props.contractSelectie,
      onSelectieChange: props.onContractSelectieChange,
      onRequestRefresh: props.onRequestRefresh,
    };
  }, [props.contractSelectie, props.onContractSelectieChange, props.onRequestRefresh]);

  return (
    <LocatieContractenTabelContext.Provider value={context}>
      <AutoSizer className="flex-fill">
        {(size) => (
          <GridStyleWrapper height={size.height}>
            <Grid rows={props.locatieContracten} columns={kolommen} getRowId={keyExtractor}>
              <DataTypeProvider
                for={['__locatie']}
                formatterComponent={(formatterProps) => {
                  const row: ILocatieContracten = formatterProps.row;
                  const eersteContract = row.contracten[0];
                  const locatie = eersteContract.basis.locatie;

                  const value: ILocatieCellValue = formatterProps.value;
                  return (
                    <b>
                      <AdresVisualisatie
                        locID={value.locatie.LocID}
                        weergaveTekst={value.tekst}
                        extraActies={[
                          {
                            text: 'Bezoekdagen',
                            icon: (
                              <IconDatumreeks
                                style={{ width: 17, height: 17, position: 'relative', bottom: 1 }}
                              />
                            ),
                            onClick: () => {
                              setBezoekdagenInfoDialoogState({ postcode: locatie.Postcode });
                            },
                          },
                          {
                            text: 'Beheer personen',
                            icon: (
                              <IconPersoon
                                style={{
                                  width: 17,
                                  height: 17,
                                  position: 'relative',
                                  bottom: 1,
                                }}
                              />
                            ),
                            onClick: async () => {
                              const output = await globaleRenderer.render<
                                IBeheerPersonenVanLocatieDialoogOutput
                              >(({ destroy }) => (
                                <BeheerPersonenVanLocatieDialoog
                                  relID={eersteContract.RelID}
                                  locID={locatie.LocID}
                                  open
                                  onSuccess={(output) => destroy(output)}
                                  onAnnuleren={() => destroy()}
                                />
                              ));
                              if (output === undefined) {
                                return;
                              }
                            },
                          },
                        ]}
                        onCorrectieUitgevoerd={async () => await props.onRequestRefresh()}
                      />
                    </b>
                  );
                }}
              />

              <DataTypeProvider
                for={['__aantalContracten']}
                formatterComponent={(formatterProps) => {
                  const row: ILocatieContracten = formatterProps.row;
                  return <span>{row.contracten.length} cnt.</span>;
                }}
              />

              <DataTypeProvider
                for={['__locatieNotitie']}
                formatterComponent={(formatterProps) => {
                  const row: ILocatieContracten = formatterProps.row;
                  const eersteContract = row.contracten[0];
                  const locatie = eersteContract.basis.locatie;
                  return <span>{locatie.Notities}</span>;
                }}
              />

              {/*<DataTypeProvider*/}
              {/*  for={['__bezoekdagen']}*/}
              {/*  formatterComponent={(formatterProps) => {*/}
              {/*    const row: ILocatieContracten = formatterProps.row;*/}
              {/*    const eersteContract = row.contracten[0];*/}
              {/*    const locatie = eersteContract.locatie;*/}

              {/*    return (*/}
              {/*      <span className="ml-2">*/}
              {/*        <a*/}
              {/*          href="#"*/}
              {/*          onClick={() => setBezoekdagenInfoDialoogState({ postcode: locatie.Postcode })}*/}
              {/*        >*/}
              {/*          <IconDatumreeks style={{ fill: Kleur.Grijs, width: 18, height: 18 }} />*/}
              {/*        </a>*/}
              {/*      </span>*/}
              {/*    );*/}
              {/*  }}*/}
              {/*/>*/}

              <DataTypeProvider
                for={['__liftAanwezig']}
                formatterComponent={(formatterProps) => {
                  const row: ILocatieContracten = formatterProps.row;
                  const eersteContract = row.contracten[0];
                  const locatie = eersteContract.basis.locatie;
                  if (locatie.LiftAanwezig) {
                    return <span style={{ color: Kleur.Blauw }}>LIFT</span>;
                  }
                  return <span></span>;
                }}
              />

              <RowDetailState
                expandedRowIds={props.locatiesUitgeklapt}
                onExpandedRowIdsChange={(x) => props.onLocatiesUitgeklaptChange(x as number[])}
              />
              <EditingState
                onCommitChanges={() => {}}
                onEditingRowIdsChange={(ids) => {
                  const id = ids[ids.length - 1]! as number;
                  setMuterenLocatieDialoogState({
                    locID: id,
                  });
                }}
              />
              <SortingState defaultSorting={[{ columnName: '__locatie', direction: 'asc' }]} />
              <IntegratedSorting columnExtensions={integratedSortingColumnExtensions} />

              <Table
                rowComponent={(rowProps) => (
                  <tr
                    {...rowProps}
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      const row: ILocatieContracten = rowProps.row;
                      props.onLocatiesUitgeklaptChange(
                        props.locatiesUitgeklapt.includes(row.locID)
                          ? props.locatiesUitgeklapt.filter((x) => x !== row.locID)
                          : [...props.locatiesUitgeklapt, row.locID],
                      );
                    }}
                  />
                )}
                columnExtensions={columnExtensions}
                messages={{
                  noData: 'Er zijn geen contracten',
                }}
              />
              {/*<TableColumnResizing defaultColumnWidths={kolomBreedtes} />*/}
              {/*<TableHeaderRow showSortingControls />*/}
              {/*<TableEditColumn width={30} showEditCommand commandComponent={DXCommandComponent} />*/}
              <TableRowDetail
                toggleCellComponent={DXTableToggleCellComponent}
                contentComponent={RowDetail}
              />
            </Grid>
          </GridStyleWrapper>
        )}
      </AutoSizer>
      {muterenLocatieDialoogState !== null && (
        <LocatieMutatieDialoog
          type={ELocatieMutatieDialoogType.Muteren}
          locID={muterenLocatieDialoogState.locID}
          open
          onSuccess={async (result) => {
            await api.v2.contract.wijzigenLocatieVanContracten({
              locID: result.locID,
              cntIDs: props.locatieContracten
                .find((x) => x.locID === muterenLocatieDialoogState!.locID)!
                .contracten.map((x) => x.CntID),
            });
            props.onRequestRefresh();
            setMuterenLocatieDialoogState(null);
          }}
          onAnnuleren={() => setMuterenLocatieDialoogState(null)}
        />
      )}
      {bezoekdagenInfoDialoogState !== null && (
        <BezoekdagenInfoDialoog
          postcode={bezoekdagenInfoDialoogState.postcode}
          open
          onSuccess={async (result) => {
            setBezoekdagenInfoDialoogState(null);
          }}
          onAnnuleren={() => setBezoekdagenInfoDialoogState(null)}
        />
      )}
    </LocatieContractenTabelContext.Provider>
  );
};

export default LocatieContractenTabel;
