import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { BrowserRouter as Router } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { GlobalStyle } from './globalstyles';
import { colors } from './globalstyles/variables';
import { RootStoreContext } from './stores/RootStore';
import AppRouter from './routers/AppRouter';
import PrimaireNavigatie from './paginas/Navigatie';
import Login from './paginas/Login';
import RealtimeIntegratie from './one-off-components/realtime/RealtimeIntegratie';
import NotificatieOverlay from './one-off-components/NotificatieOverlay';
import ProfielOverlay from './one-off-components/ProfielOverlay';
import CheckDialoogRenderer from './one-off-components/CheckDialoogRenderer';
import RouterContextProvider from './routers/RouterContextProvider';
import LoadingSpinner from './components/Gedeeld/LoadingSpinner';
import CommunicatieOverlay from './one-off-components/CommunicatieOverlay';
import GlobaleRenderer, { GlobaleRendererContext } from './one-off-components/GlobaleRenderer';
import SnelkoppelingAfhandelaar from './one-off-components/SnelkoppelingAfhandelaar';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Toaster } from 'react-hot-toast';
import { Helmet } from 'react-helmet';
import api from './api';
import CorrigeerVoipAccountVigerendDialoog, {
  DialoogResult,
  EResultType,
} from './components/dialogen/CorrigeerVoipAccountVigerendDialoog';
import ProfielVoipAccountChecker from './one-off-components/ProfielVoipAccountChecker';
import ZoekOverlay from './one-off-components/ZoekOverlay';
import Activiteitsmonitor from './one-off-components/Activiteitsmonitor';

const PrimaireLayout = styled.div`
    //background-color: ${colors.backgroundGrey};
  display: flex;
  min-height: 100vh;
`;

const PrimaireContent = styled.main`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: calc(100vw - 205px);
`;

const PrimaireFooter = styled.footer`
  background-color: lightcyan;
`;

const Wrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

export interface IAppContext {
  globalCss: Record<string, string>;
  setGlobalCssValue: (key: string, value: string | null) => void;
}

export const AppContext = React.createContext<IAppContext>(null as any);

const App = () => {
  const [globalCss, setGlobalCss] = useState<Record<string, string>>({});
  const globalCssCode = useMemo(() => {
    return Object.keys(globalCss)
      .map((key) => globalCss[key])
      .join('\n');
  }, [globalCss]);

  const setGlobalCssValue = useCallback(
    (key, value) => {
      setGlobalCss((curr) => {
        if (value === null) {
          const copy = { ...curr };
          delete copy[key];
          return copy;
        }
        return {
          ...curr,
          [key]: value,
        };
      });
    },
    [setGlobalCss],
  );

  const appContext = useMemo<IAppContext>(() => {
    return {
      globalCss,
      setGlobalCssValue,
    };
  }, [globalCss, setGlobalCssValue]);

  return (
    <AppContext.Provider value={appContext}>
      <GlobalStyle customCss={globalCssCode} />

      <Router>
        <DndProvider backend={HTML5Backend}>
          <RealtimeIntegratie>
            <GlobaleRenderer>
              <AppInner />
            </GlobaleRenderer>
          </RealtimeIntegratie>
        </DndProvider>
      </Router>
    </AppContext.Provider>
  );
};

const AppInner = observer(() => {
  const [isAanhetLaden, setIsAanhetLaden] = useState(true);
  const rootStore = useContext(RootStoreContext);
  const globaleRendererContext = useContext(GlobaleRendererContext);
  const { gebruikerStore, notificatieStore, profielStore, apiStore, toetsenbordStore } = useContext(
    RootStoreContext,
  );

  useEffect(() => {
    (async () => {
      setIsAanhetLaden(true);
      // gebruiker ophalen
      await gebruikerStore.getCurrentUser();
      setIsAanhetLaden(false);
    })();
  }, []);

  useEffect(() => {
    if (gebruikerStore.gebruiker === null) {
      return;
    }

    setIsAanhetLaden(true);
    (async () => {
      await rootStore.instellingStore.ophalenInstellingen();
      await Promise.all([
        // oud
        // rootStore.mediaTypeStore.ophalenMediaTypes(),
        // rootStore.formulierDataStore.ophalenTalen(),
        // rootStore.formulierDataStore.ophalenGeslachten(),

        // standaard waarden
        rootStore.standaardWaardeStore.ophalenTaalID(),
        rootStore.standaardWaardeStore.ophalenLandID(),
        rootStore.berichtStore.ophalenBerichtContexten(),
        rootStore.gebruikerStore.ophalenGebruikers(),
        // rootStore.notificatieStore.ophalenNotificatieGroepen(),
        // rootStore.notificatieStore.ophalenNotificaties(),
        // rootStore.notificatieStore.ophalenMijnNotificaties(),
        rootStore.telefoonStore.ophalenActieveOproepen(),
        rootStore.voipStore.ophalenVoipAccounts(),
        rootStore.voipStore.ophalenVoipAccountsVigerend(),
        rootStore.memoStore.ophalenMemos(),
        rootStore.autorisatieStore.initialiseren(),
        rootStore.intrasessieStore.initialiseren(),
        rootStore.meldingIndicatieStore.initialiseer(),
      ]);

      setIsAanhetLaden(false);

      const bestaandeVigerendeVoorGebruiker =
        rootStore.voipStore.voipAccountsVigerend!.find(
          (x) => x.AspGebrID === rootStore.gebruikerStore.gebruiker!.AspGebrID,
        ) ?? null;
      if (bestaandeVigerendeVoorGebruiker === null) {
        // Instellen vigerende voip account
        const profiel = rootStore.gebruikerStore.aspGebruikersprofiel ?? null;
        if (profiel !== null) {
          if (profiel.VoipAccID !== null) {
            // Controleren of er al een andere gebruiker dit voip account in gebruikt heeft
            const bestaandeVigerendeVoipAccountVanAndereGebruiker =
              rootStore.voipStore.voipAccountsVigerend!.find(
                (x) =>
                  x.VoipAccID === profiel.VoipAccID &&
                  x.AspGebrID !== rootStore.gebruikerStore.gebruiker!.AspGebrID,
              ) ?? null;
            if (bestaandeVigerendeVoipAccountVanAndereGebruiker !== null) {
              // Vraag aan de gebruiker hoe we dit gaan corrigeren:
              // We kunnen of:
              // - Een ander voip account kiezen om mee aan de slag te gaan
              // - Dit voip account kiezen en de andere gebruiker er af halen
              const result = await globaleRendererContext.render<DialoogResult>((renderProps) => (
                <CorrigeerVoipAccountVigerendDialoog
                  open
                  gewenstVoipAccountID={profiel.VoipAccID!}
                  onSuccess={(result) => renderProps.destroy(result)}
                  onAnnuleren={() =>
                    renderProps.destroy({
                      type: EResultType.ZonderToestelDoorgaan,
                    })
                  }
                />
              ));

              if (result !== undefined) {
                switch (result.type) {
                  case EResultType.Toeeigenen: {
                    await api.v2.telefonie.instellenVoipAccountVigerend({
                      aspGebrID: rootStore.gebruikerStore.gebruiker!.AspGebrID,
                      voipAccountID: profiel.VoipAccID!,
                    });
                    break;
                  }
                  case EResultType.AnderToestelGekozen: {
                    await api.v2.telefonie.instellenVoipAccountVigerend({
                      aspGebrID: rootStore.gebruikerStore.gebruiker!.AspGebrID,
                      voipAccountID: result.voipAccountID,
                    });
                    break;
                  }
                  case EResultType.ZonderToestelDoorgaan:
                    break;
                }
              }
            } else {
              await api.v2.telefonie.instellenVoipAccountVigerend({
                aspGebrID: rootStore.gebruikerStore.gebruiker!.AspGebrID,
                voipAccountID: profiel.VoipAccID!,
              });
            }
          }
        }
      }
    })();
  }, [gebruikerStore.gebruiker?.AspGebrID, rootStore]);

  useEffect(() => {
    const handler = (ev: KeyboardEvent) => {
      if (ev.altKey !== toetsenbordStore.alt) {
        toetsenbordStore.setAlt(ev.altKey);
        return true;
      }
      if (ev.ctrlKey !== toetsenbordStore.ctrl) {
        toetsenbordStore.setCtrl(ev.ctrlKey);
        return true;
      }
      if (ev.shiftKey !== toetsenbordStore.shift) {
        toetsenbordStore.setShift(ev.shiftKey);
        return true;
      }
      if (ev.metaKey !== toetsenbordStore.meta) {
        toetsenbordStore.setMeta(ev.metaKey);
        return true;
      }
      return false;
    };

    const keydownHandler = (ev: KeyboardEvent) => {
      const handled = handler(ev);
      if (handled) {
        return;
      }
      const key = ev.key.toLowerCase();
      if (key === toetsenbordStore.key) {
        return;
      }
      toetsenbordStore.setKey(key);
    };
    const keyupHandler = (ev: KeyboardEvent) => {
      const handled = handler(ev);
      if (handled) {
        return;
      }
      toetsenbordStore.setKey(null);
    };

    window.addEventListener('keydown', keydownHandler);
    window.addEventListener('keyup', keyupHandler);
    return () => {
      window.removeEventListener('keydown', keydownHandler);
      window.removeEventListener('keyup', keyupHandler);
    };
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <title>ASP Systeem</title>
      </Helmet>

      <Toaster position="bottom-right" toastOptions={{ duration: 3000 }} />
      {!apiStore.bereikbaar ? (
        <div
          className="d-flex align-items-center justify-content-center flex-column"
          style={{ width: '100vw', height: '100vh' }}
        >
          <h2>Er wordt verbinding gezocht met de API</h2>
          <span className="mt-2">
            Zodra deze tot stand komt zal de applicatie automatisch verversen
          </span>
          <div className="mt-5">
            <LoadingSpinner />
          </div>
        </div>
      ) : isAanhetLaden ? (
        <div
          className="d-flex align-items-center justify-content-center flex-column"
          style={{ width: '100vw', height: '100vh' }}
        >
          <LoadingSpinner />
        </div>
      ) : (
        <div
          onClick={() => {
            if (notificatieStore.overlayState.zichtbaar) {
              notificatieStore.setOverlayZichtbaar(false);
            }
            if (profielStore.overlayState.zichtbaar) {
              profielStore.setOverlayState(false);
            }
          }}
        >
          <RouterContextProvider>
            {gebruikerStore.gebruiker && (
              <>
                <NotificatieOverlay />
                <ProfielOverlay />
                <PrimaireLayout>
                  <PrimaireNavigatie />
                  <PrimaireContent>
                    <Wrapper>
                      <AppRouter />
                    </Wrapper>
                    <PrimaireFooter />
                  </PrimaireContent>
                </PrimaireLayout>
                <CommunicatieOverlay />
                <ZoekOverlay />
                <Activiteitsmonitor aspGebrID={gebruikerStore.gebruiker.AspGebrID} />
              </>
            )}
            {!gebruikerStore.gebruiker && !isAanhetLaden && <Login />}
            <CheckDialoogRenderer />
            <SnelkoppelingAfhandelaar />
            <ProfielVoipAccountChecker />
          </RouterContextProvider>
        </div>
      )}
    </React.Fragment>
  );
});

export default App;
