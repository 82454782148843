import api from '../../../../..';
import {
  ILadenInterneInventarisatieParams,
  ILadenInterneInventarisatieResult,
  IOphalenInventarisatiesParams,
  IOphalenInventarisatiesResult,
} from '../../../../../../../../shared/src/api/v2/magazijn/inventarisatie/lijst/intern';

const intern = {
  ophalenInventarisaties: async (
    params: IOphalenInventarisatiesParams,
  ): Promise<IOphalenInventarisatiesResult> => {
    return await api.post(
      '/v2/magazijn/inventarisatie/lijst/intern/ophalen-inventarisaties',
      params,
    );
  },
  ladenInterneInventarisatie: async (
    params: ILadenInterneInventarisatieParams,
  ): Promise<ILadenInterneInventarisatieResult> => {
    return await api.post(
      '/v2/magazijn/inventarisatie/lijst/intern/laden-interne-inventarisatie',
      params,
    );
  },
};

export default intern;
