import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { IUitgeklapteRijProps } from '../../../../../components/tabel/ASPTabel/Body/UitgeklapteRij';
import { BerichtenContext, BerichtenKolom } from '../index';
import {
  IBulkVerzendBericht,
  IBulkVerzendBerichtEmail,
  IBulkVerzendBerichtSms,
} from '../../../../../../../shared/src/api/v2/bulkVerzending';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
  mapRemoteData,
} from '../../../../../models/IRemoteData';
import LoadingSpinner from '../../../../../components/Gedeeld/LoadingSpinner';
import styled from 'styled-components';
import { Kleur } from '../../../../../bedrijfslogica/constanten';
import { AutoSizer } from 'react-virtualized';

const Root = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

const EmailContainer = styled.iframe`
  background-color: ${Kleur.Wit};
  border: 1px solid ${Kleur.LichtGrijs};
  border-radius: 5px;
  margin: 20px 20px 20px 20px;
  padding: 20px;
  width: calc(100% - 40px);
`;

const UitgeklapteRij = (props: IUitgeklapteRijProps<BerichtenKolom, IBulkVerzendBericht>) => {
  const berichtenContext = useContext(BerichtenContext);
  const bericht = props.regel;

  const emailBericht = useMemo<IRemoteData<IBulkVerzendBerichtEmail | null>>(
    () =>
      mapRemoteData(berichtenContext.data, (data) => {
        return data.emailBerichtBijBulkVerzenderQueueId[bericht.ID] ?? null;
      }),
    [berichtenContext.data.data?.emailBerichtBijBulkVerzenderQueueId],
  );
  const smsBericht = useMemo<IRemoteData<IBulkVerzendBerichtSms | null>>(
    () =>
      mapRemoteData(berichtenContext.data, (data) => {
        return data.smsBerichtBijBulkVerzenderQueueId[bericht.ID] ?? null;
      }),
    [berichtenContext.data.data?.smsBerichtBijBulkVerzenderQueueId],
  );

  const containerRef = useRef<HTMLDivElement>(null);
  const [containerHeight, setContainerHeight] = useState<IRemoteData<number>>(
    createPendingRemoteData(),
  );

  const handleLoad = useCallback(
    (ev: React.SyntheticEvent) => {
      const frame = ev.target as HTMLIFrameElement;
      const height = frame.contentWindow!.document.documentElement.scrollHeight + 40;
      setContainerHeight(createReadyRemoteData(height));
    },
    [setContainerHeight],
  );

  if (
    emailBericht.state === ERemoteDataState.Pending ||
    smsBericht.state === ERemoteDataState.Pending
  ) {
    return (
      <div className="flex-fill d-flex align-items-center justify-content-center">
        <LoadingSpinner />
      </div>
    );
  }

  if (emailBericht.data !== null) {
    return (
      <Root ref={containerRef}>
        <AutoSizer className="flex-fill">
          {({ height, width }) => (
            <div style={{ maxHeight: height, width, overflowY: 'auto' }}>
              <EmailContainer
                srcDoc={emailBericht.data!.Inhoud ?? ''}
                onLoad={handleLoad}
                style={{
                  height: containerHeight.data ?? undefined,
                }}
                scrolling="no"
              />
            </div>
          )}
        </AutoSizer>
      </Root>
    );
  }

  return <span>{bericht.ID}</span>;
};

export default UitgeklapteRij;
