import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import api from '../../../api';
import { Kleur as EKleur } from '../../../bedrijfslogica/constanten';
import useUrlState from '../../../core/useUrlState';
import { IOphalenOpdrachtregelsResultElement } from '../../../../../shared/src/api/v2/transport/opdracht';
import _ from 'lodash';
import {
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../helpers/dxTableGrid';
import LoadingSpinner from '../../../components/Gedeeld/LoadingSpinner';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  DataTypeProvider,
  IntegratedSorting,
  RowDetailState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import RelatieVisualisatie from '../../../components/personalia/RelatieVisualisatie';
import { format } from 'date-fns';
import MultiCombobox, { IKolom } from '../../../components/formulier/MultiCombobox';
import { IOphalenMagazijnenResultElement } from '../../../../../shared/src/api/v2/magazijn';
import { IOphalenVoorraadinfoResultElement } from '../../../../../shared/src/api/v2/voorraad';
import VoorraadIndicatie, { EVoorraadStatus } from '../../../components/VoorraadIndicatie';
import { IFilterSchema, IFilterSchemaFilter } from '../../../../../shared/src/models/filter';
import { RootStoreContext } from '../../../stores/RootStore';
import { EResultType } from '../../../stores/CheckStore';
import ReserveringProductComp from '../../kolomveld/transportopdrachtregel/ReserveringProductComp';
import {
  IOphalenOpdrachtenResultElementV2,
  IOphalenOpdrachtregelsResultElementV2,
} from '../../../../../shared/src/api/v2/transport/opdracht';
import VoorraadTypeInfoDialoog from '../../transport/VoorraadTypeInfoDialoog';
import { IOphalenRelatiesResultElementV2 } from '../../../../../shared/src/api/v2/relatie';
import { IconInformatie } from '../../Icons';
import TransportopdrachtregelVisualisatie from '../../entiteitVisualisaties/TransportopdrachtregelVisualisatie';
import { IOphalenOpdrachtenResultElement } from '../../../../../shared/src/api/v2/inkoop/opdracht';
import FilterBalkV2, {
  IFilter,
  IFilterData,
  maakFilterSchema,
} from '../../../components/FilterBalkV2';
import OpdrachtStatusFilter from '../../../components/kaart/entiteiten/Transport/Opdrachten/OpdrachtStatusFilter';
import { EOpdrachtstatusTransport } from '../../../bedrijfslogica/enums';
import OpdrachtStatus from '../../../components/kaart/entiteiten/Transport/OpdrachtStatus';
import MenuLayout from '../../MenuLayout';
import { AutoSizer } from 'react-virtualized';
import { Helmet } from 'react-helmet';
import { IOphalenProducttypenResultElement } from '../../../../../shared/src/api/v2/product/type';
import { IOphalenProductsoortenResultElement } from '../../../../../shared/src/api/v2/product/soort';

export enum EFilter {
  Magazijn = 'MAG_IDS',
  AlleenMetInkoopreservering = 'IS_GERESERVEERD',
  Opdrachtstatus = 'OPDRACHTSTATUSSEN',
  Producttype = 'TYPE_IDS',
  Productsoort = 'PRODSRT_IDS',
}

interface IProps extends RouteComponentProps {}

interface IUrlState {
  selectie: number[];
  filterData: IFilterData<EFilter>[];
}
const defaultUrlState: IUrlState = {
  selectie: [],
  filterData: [
    {
      naam: EFilter.Magazijn,
      data: [],
      isActief: false,
    },
    {
      naam: EFilter.AlleenMetInkoopreservering,
      data: false,
      isActief: false,
    },
    {
      naam: EFilter.Opdrachtstatus,
      data: [2],
      isActief: false,
    },
    {
      naam: EFilter.Producttype,
      data: [],
      isActief: false,
    },
    // {
    //   naam: EFilter.Productsoort,
    //   data: [],
    //   isActief: false,
    // },
  ],
};

export interface IRegel extends IOphalenOpdrachtregelsResultElementV2 {
  opdracht: IOphalenOpdrachtenResultElementV2;
}

export interface IVoorraadTypeInfoDialoogState {
  typeID: number;
  magID: number;
}

const Reserveringen: React.FC<IProps> = (props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState<IUrlState>(props, defaultUrlState);
  const { checkStore } = useContext(RootStoreContext);

  const [regels, setRegels] = useState<IRegel[] | null>(null);
  const [magID, setMagID] = useState<number | null>(4); // TODO hard gecodeerd
  const [magazijnen, setMagazijnen] = useState<IOphalenMagazijnenResultElement[] | null>(null);
  const [relaties, setRelaties] = useState<IOphalenRelatiesResultElementV2[] | null>(null);
  const [inkoopopdrachten, setInkoopopdrachten] = useState<
    IOphalenOpdrachtenResultElement[] | null
  >(null);

  const [producttypen, setProducttypen] = useState<IOphalenProducttypenResultElement[] | null>(
    null,
  );
  const [productsoorten, setProductsoorten] = useState<
    IOphalenProductsoortenResultElement[] | null
  >(null);

  const [filterSchema, setFilterSchema] = useState<IFilterSchema>(
    useMemo(() => maakFilterSchema(urlState.filterData), []),
  );

  // const [filterNogReserveren, setFilterNogReserveren] = useState<boolean>(false);
  const [
    voorraadTypeInfoDialoogState,
    setVoorraadTypeInfoDialoogState,
  ] = useState<IVoorraadTypeInfoDialoogState | null>(null);

  const ophalenMagazijnen = useCallback(async () => {
    const result = await api.v2.magazijn.ophalenMagazijnen({
      filterSchema: {
        filters: [],
      },
    });
    setMagazijnen(result.magazijnen);
  }, []);

  useEffect(() => {
    ophalenMagazijnen();
  }, [ophalenMagazijnen]);

  const ophalenRelaties = useCallback(async () => {
    if (regels === null) {
      return;
    }

    const relIDs = regels.filter((x) => x.RelID !== null).map((x) => x.RelID);
    const result = await api.v2.relatie.ophalenRelaties({
      filterSchema: {
        filters: [{ naam: 'IDS', data: relIDs }],
      },
    });
    setRelaties(result.relaties);
  }, [regels]);

  useEffect(() => {
    ophalenRelaties();
  }, [ophalenRelaties]);

  useEffect(() => {
    ophalenMagazijnen();
  }, [ophalenMagazijnen]);

  const ophalenTransportopdrachtregels = useCallback(async () => {
    // setRelaties(null);

    const regelsResult = (
      await api.v2.transport.opdracht.ophalenOpdrachtregelsV2({
        filterSchema: {
          filters: [
            ...filterSchema.filters!,
            { naam: 'REGELSTATUSSEN', data: [0] },
            { naam: 'REGELSOORTEN', data: ['LEVERING'] },
            { naam: 'IS_INGEPAKT_PRODUCT', data: true },
            { naam: 'IS_BULKPRODUCT', data: false },
          ].filter((x) => x !== null) as IFilterSchemaFilter[],
        },
      })
    ).regels;

    if (regelsResult.length === 0) {
      setRegels([]);
      return;
    }

    const opdrachten = (
      await api.v2.transport.opdracht.ophalenOpdrachtenV2({
        filterSchema: {
          filters: [{ naam: 'IDS', data: [regelsResult.map((x) => x.TrsOpdID)] }],
        },
      })
    ).opdrachten;

    const regels = regelsResult.map((x) => {
      const opdracht = opdrachten.find((o) => o.TrsOpdID === x.TrsOpdID)!;
      return { ...x, opdracht };
    });

    const opdrachtenGesorteerd = _.orderBy(
      regels,
      [
        (x: IRegel) => {
          return x.opdracht.opdrachtstatus.SortNr;
        },
        (x: IRegel) => {
          return x.opdracht.Bezoekdatum !== null ? x.opdracht.Bezoekdatum : '1990-01-01';
        },
      ],
      ['asc', 'asc'],
    );

    setRegels(opdrachtenGesorteerd);
  }, [magID, filterSchema.filters]);

  useEffect(() => {
    ophalenTransportopdrachtregels();
  }, [ophalenTransportopdrachtregels]);

  const [voorraadInfo, setVoorraadInfo] = useState<IOphalenVoorraadinfoResultElement[] | null>(
    null,
  );
  const ophalenVoorraadInfo = useCallback(async () => {
    if (magID === null || regels === null) {
      return;
    }

    const typeIDs = _.uniq(regels.map((x) => x.type!.TypeID));
    const voorraadInfoResult = await api.v2.voorraad.ophalenVoorraadinfo({
      magID,
      typeIDs,
    });

    setVoorraadInfo(voorraadInfoResult.voorraad);
  }, [magID, regels]);

  useEffect(() => {
    ophalenVoorraadInfo();
  }, [ophalenVoorraadInfo]);

  const wijzigReservering = useCallback(async (trsRegID: number, gereserveerd: boolean) => {
    //  Schakelt de reservering van de opgegeven transportopdrachtregel om

    const params = {
      trsRegID,
      gereserveerd,
    };

    await api.v2.transport.opdracht.wijzigenOpdrachtregelVoorraad(params);

    ophalenTransportopdrachtregels();
  }, []);

  const keyExtractor = useCallback(
    (row: IOphalenOpdrachtregelsResultElementV2) => row.TrsRegID,
    [],
  );

  // Inkoopopdrachten
  const ophalenOpdrachten = useCallback(async () => {
    const inkoopopdrachten = (
      await api.v2.inkoop.opdracht.ophalenOpdrachten({
        filterSchema: {
          filters: [
            { naam: 'IS_VERSTUURD', data: true },
            { naam: 'IS_AFGEHANDELD', data: false },
          ],
        },
      })
    ).inkoopopdrachten;

    setInkoopopdrachten(inkoopopdrachten);
  }, []);

  useEffect(() => {
    ophalenOpdrachten();
  }, []);

  const ophalenProducttypen = useCallback(async () => {
    const result = await api.v2.product.type.ophalenProducttypen({
      filterSchema: { filters: [{ naam: 'INKOOP_TOEGESTAAN', data: true }] },
    });

    const producttypen = result.producttypen;

    const producttypenGesorteerd = _.orderBy(
      producttypen,
      ['Typenaam', 'Merknaam'],
      ['asc', 'asc'],
    );

    setProducttypen(producttypenGesorteerd);
  }, []);

  useEffect(() => {
    ophalenProducttypen();
  }, [ophalenProducttypen]);

  const ophalenProductsoorten = useCallback(async () => {
    const result = await api.v2.product.soort.ophalenProductsoorten({
      // filterSchema: { filters: [{ naam: 'INKOOP_TOEGESTAAN', data: true }] },
    });

    const productsoorten = result;

    setProductsoorten(productsoorten);
  }, []);

  useEffect(() => {
    ophalenProductsoorten();
  }, [ophalenProductsoorten]);

  const producttypenKolommen = useMemo<IKolom<IOphalenProducttypenResultElement>[]>(() => {
    return [
      {
        key: 'Typenaam',
        label: 'Type',
        breedte: 150,
      },
      {
        key: 'Merknaam',
        label: 'Merk',
        breedte: 100,
      },
      {
        key: 'Productsoortnaam',
        label: 'Cat.',
        breedte: 150,
      },
    ];
  }, []);

  const productsoortenKolommen = useMemo<IKolom<IOphalenProductsoortenResultElement>[]>(() => {
    return [
      {
        key: 'Naam',
        label: 'Cat.',
        breedte: 150,
      },
    ];
  }, []);

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.Magazijn,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return (
            <div className="d-flex align-items-center">
              <span className="mr-2">Magazijn</span>
              <MultiCombobox<number, IOphalenMagazijnenResultElement>
                sleutelExtractor={(x) => x.MagID}
                representatieFabriek={(x) => x.NaamKort}
                waarde={weergaveProps.data.length === 0 ? null : weergaveProps.data[0]}
                // waarde={magID}
                onWaardeChange={(x) => {
                  weergaveProps.onDataChange(x === null ? [] : [x]);
                  weergaveProps.setIsActief(true);
                  weergaveProps.toepassen();
                }}
                // onWaardeChange={(x) => {
                //   return setMagID(x!);
                // }}
                opties={magazijnen}
                kolommen={[
                  {
                    key: 'NaamKort',
                    label: 'Naam',
                    breedte: 125,
                  },
                  // {
                  //   key: 'PlaatsNaam',
                  //   label: 'Plaats',
                  //   breedte: 200,
                  // },
                ]}
              />
            </div>
          );
        },
      },
      {
        naam: EFilter.Opdrachtstatus,
        altijdWeergevenInBalk: true,
        weergave: OpdrachtStatusFilter,
      },
      // {
      //   naam: EFilter.Opdrachtstatus,
      //   altijdWeergevenInBalk: true,
      //   weergave: (weergaveProps) => {
      //     return (
      //       <div className="d-flex align-items-center">
      //         <span className="mr-2">Opdrachtstatus</span>
      //         <Combobox
      //           geselecteerd={weergaveProps.data}
      //           onSelectieChange={(x) => {
      //             weergaveProps.onDataChange(x);
      //             // weergaveProps.setIsActief(true);
      //             weergaveProps.toepassen();
      //           }}
      //           opties={[
      //             { ID: 0, Naam: 'Planning' },
      //             { ID: 1, Naam: 'Uitstaand' },
      //           ].map((status) => ({
      //             id: status.ID,
      //             label: status.Naam,
      //           }))}
      //         />
      //       </div>
      //     );
      //   },
      // },
      {
        naam: EFilter.AlleenMetInkoopreservering,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return <span>Alleen met inkoopreservering</span>;
        },
      },
      {
        naam: EFilter.Producttype,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          const { data, onDataChange, toepassen } = weergaveProps;
          const [typeID] = data;

          if (producttypen === null) {
            return <span></span>;
          }

          return (
            <div className="d-flex align-items-center">
              <span className="mr-2">Producttype</span>

              {/* <Combobox
                geselecteerd={typeID === undefined ? null : typeID}
                onSelectieChange={(x) => {
                  onDataChange(x === null ? [] : [x]);
                  toepassen();
                }}
                opties={producttypen.map((x) => {
                  return {
                    id: x.TypeID,
                    label: x.Typenaam + ' ' + x.Merknaam,
                  };
                })}
              /> */}

              <MultiCombobox<number, IOphalenProducttypenResultElement>
                sleutelExtractor={(row) => row.TypeID}
                onWaardeChange={(x) => {
                  onDataChange(x === null ? [] : [x]);
                  weergaveProps.setIsActief(true);
                  toepassen();
                }}
                representatieFabriek={(row) => `${row.Typenaam} - ${row.Merknaam}`}
                waarde={typeID === undefined ? null : typeID}
                opties={producttypen}
                kolommen={producttypenKolommen}
              />
            </div>
          );
        },
      },
      // {
      //   naam: EFilter.Productsoort,
      //   altijdWeergevenInBalk: true,
      //   weergave: (weergaveProps) => {
      //     const { data, onDataChange, toepassen } = weergaveProps;
      //     const [typeID] = data;

      //     if (productsoorten === null) {
      //       return <span></span>;
      //     }

      //     return (
      //       <div className="d-flex align-items-center">
      //         <span className="mr-2">Productsoort</span>

      //         {/* <Combobox
      //           geselecteerd={typeID === undefined ? null : typeID}
      //           onSelectieChange={(x) => {
      //             onDataChange(x === null ? [] : [x]);
      //             toepassen();
      //           }}
      //           opties={producttypen.map((x) => {
      //             return {
      //               id: x.TypeID,
      //               label: x.Typenaam + ' ' + x.Merknaam,
      //             };
      //           })}
      //         /> */}

      //         <MultiCombobox<number, IOphalenProductsoortenResultElement>
      //           sleutelExtractor={(row) => row.ProdSrtID}
      //           onWaardeChange={(x) => {
      //             onDataChange(x === null ? [] : [x]);
      //             weergaveProps.setIsActief(true);
      //             toepassen();
      //           }}
      //           representatieFabriek={(row) => `${row.Naam}`}
      //           waarde={typeID === undefined ? null : typeID}
      //           opties={productsoorten}
      //           kolommen={productsoortenKolommen}
      //         />
      //       </div>
      //     );
      //   },
      // },
    ],
    [magazijnen, productsoorten, producttypen],
  );

  const kolommen = useMemo<TypedColumn<IRegel>[]>(
    () => [
      {
        name: '__opdrachtnummer' as any,
        title: 'Opd.nr',
      },
      {
        name: '__magazijn' as any,
        title: 'Mag.',
      },
      // {
      //   name: '__opdrachtregel' as any,
      //   title: ' ',
      // },
      {
        name: '__opdrachtstatus' as any,
        title: 'Opd.status',
        getCellValue: (x) => {
          return x.opdracht.opdrachtstatus.Naam;
        },
      },
      {
        name: '__contractnummer' as any,
        title: 'Contract',
      },
      {
        name: '__productsoort' as any,
        title: 'Cat.',
        getCellValue: (x) => {
          return x.type!.ProductsoortnaamKort;
        },
      },
      // {
      //   name: '__product' as any,
      //   title: 'Merk/type',
      //   getCellValue: (rij) => {
      //     return rij.type !== null
      //       ? (rij.type.Merkloos ? '' : rij.type.Merknaam + ' ') + rij.type.Typenaam
      //       : rij.product !== null
      //       ? rij.product.producttype.Merknaam + ' ' + rij.product.producttype.Typenaam
      //       : '';
      //   },
      // },
      {
        name: '__merk' as any,
        title: 'Merk',
        getCellValue: (rij) =>
          rij.type !== null
            ? rij.type.Merkloos
              ? ''
              : rij.type.Merknaam
            : rij.product !== null
            ? rij.product.producttype.Merknaam
            : '',
      },
      {
        name: '__type' as any,
        title: 'Type',
        getCellValue: (rij) =>
          rij.type !== null
            ? rij.type.Typenaam
            : rij.product !== null
            ? rij.product.producttype.Typenaam
            : '',
      },
      {
        name: '__voorraadInfo' as any,
        title: 'Vrd.info',
      },
      {
        name: '__bezoekdatum' as any,
        title: 'Bezoekdatum',
        getCellValue: (x) => x.opdracht.Bezoekdatum,
      },
      {
        name: '__relatienaam' as any,
        title: 'Relatienaam',
        getCellValue: (x: IRegel) => {
          if (relaties === null || x.RelID === null) {
            return '';
          }
          return relaties.find((r) => r.RelID === x.RelID)?.weergavenaam ?? '';
        },
      },
      {
        name: '__voorraadReservering' as any,
        title: 'Res./IR',
        getCellValue: (x) => {
          return x.type !== null && !x.type.Bulkproduct && x.type.Gereserveerd ? '1' : '0';
        },
      },
      // {
      //   name: '__gereserveerd' as any,
      //   title: ' ',
      // },
      {
        name: '__aantalVrij' as any,
        title: 'Vrij',
        getCellValue: (x) => {
          if (voorraadInfo === null) {
            return 0;
          }
          const info = voorraadInfo.find((i) => i.TypeID === x.type!.TypeID)!;
          const aantal = info !== undefined ? info.aantalVrij : 0;
          return aantal;
        },
      },
      {
        name: '__inkoopopdrachtAanwezig' as any,
        title: 'In best.',
      },
    ],
    [voorraadInfo, relaties],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IOphalenOpdrachtregelsResultElement>[]>(
    () => [
      {
        columnName: '__opdrachtnummer' as any,
        width: 100,
      },
      {
        columnName: '__magazijn' as any,
        width: 85,
      },
      {
        columnName: '__opdrachtregel' as any,
        width: 85,
      },
      {
        columnName: '__opdrachtstatus' as any,
        width: 110,
      },
      {
        columnName: '__contractnummer' as any,
        width: 100,
      },
      {
        columnName: '__productsoort' as any,
        width: 80,
      },
      // {
      //   columnName: '__product' as any,
      //   width: 250,
      // },
      {
        columnName: '__merk' as any,
        width: 85,
      },
      {
        columnName: '__type' as any,
        width: 175,
      },
      {
        columnName: '__bezoekdatum' as any,
        width: 130,
      },
      {
        columnName: '__relatienaam' as any,
        width: 250,
      },
      // {
      //   columnName: '__gereserveerd' as any,
      //   width: 60,
      // },
      {
        columnName: '__voorraadReservering' as any,
        width: 85,
      },
      {
        columnName: '__aantalVrij' as any,
        width: 100,
      },
      {
        columnName: '__voorraadInfo' as any,
        width: 100,
      },
      {
        columnName: '__inkoopopdrachtAanwezig' as any,
        width: 150,
      },
    ],
    [],
  );

  return (
    <>
      <Helmet>
        <title>Transport - Reserveringen</title>
      </Helmet>
      <MenuLayout
        menu={
          <>
            <div className="d-flex align-items-center">
              <button
                className="btn btn-sm btn-light d-flex align-items-center"
                style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                // disabled={urlState.selectie.length !== 0}
                onClick={async () => {
                  if (
                    (
                      await checkStore.bevestigen({
                        inhoud: (
                          <span>
                            Automatisch reserveren?
                            <br />
                            <br />
                            Hierbij worden inkoopreserveringen omgezet naar een voorraadreservering
                            indien er voorraad is.
                            <br />
                            Dit gebeurt op volgorde van bezoekdatum (meest recente eerst).
                          </span>
                        ),
                      })
                    ).type === EResultType.Annuleren
                  ) {
                    return;
                  }

                  const result = await api.v2.transport.reservering.levering.reserverenProducten({
                    filterSchema: { filters: [{ naam: 'MAG_IDS', data: [magID] }] },
                  });

                  ophalenTransportopdrachtregels();

                  await checkStore.melden({
                    titel: `Er zijn ${
                      result.aantalGereserveerd !== 0 ? result.aantalGereserveerd : 'geen'
                    } producten gereserveerd`,
                  });
                }}
              >
                {/* <IconSend style={{ width: 16, height: 16, fill: Kleur.Grijs }} /> */}
                <span className="ml-2">Automatisch reserveren</span>
              </button>

              {regels !== null && (
                <div className="d-flex flexfill ml-3">
                  <FilterBalkV2
                    filters={filters}
                    filterData={urlState.filterData}
                    onFilterDataChange={(x) => setUrlStateSync('filterData', x)}
                    onFilterSchemaChange={(x) => setFilterSchema(x)}
                  />
                </div>
              )}
            </div>
          </>
        }
        body={
          <>
            {regels === null ||
            voorraadInfo === null ||
            magID === null ||
            relaties === null ||
            inkoopopdrachten === null ? (
              <div className="d-flex flex-fill align-items-center justify-content-center p-5 pl-4 pr-4">
                <LoadingSpinner />
              </div>
            ) : (
              <AutoSizer style={{ flex: 1, height: '100%', width: '100%' }}>
                {(size) => (
                  <GridStyleWrapper height={size.height}>
                    <Grid columns={kolommen} getRowId={keyExtractor} rows={regels}>
                      <DataTypeProvider
                        for={['__opdrachtnummer']}
                        formatterComponent={(formatterProps) => {
                          const rij: IRegel = formatterProps.row;

                          return (
                            <span>
                              {rij.opdracht.Opdrachtnummer}-{rij.Regelnummer}
                            </span>
                          );
                        }}
                      />

                      <DataTypeProvider
                        for={['__magazijn']}
                        formatterComponent={(formatterProps) => {
                          const rij: IRegel = formatterProps.row;
                          return <span>{rij.opdracht.magazijn.NaamKort}</span>;
                        }}
                      />

                      <DataTypeProvider
                        for={['__opdrachtregel']}
                        formatterComponent={(formatterProps) => {
                          const rij: IRegel = formatterProps.row;
                          return <TransportopdrachtregelVisualisatie trsRegID={rij.TrsRegID} />;
                        }}
                      />

                      {/* <DataTypeProvider
                  for={['__opdrachtstatus']}
                  formatterComponent={(formatterProps) => {
                    const rij: IRegel = formatterProps.row;
                    return <span>{rij.opdracht.opdrachtstatus.Naam}</span>;

                  }}
                /> */}

                      <DataTypeProvider
                        for={['__opdrachtstatus']}
                        formatterComponent={(formatterProps) => {
                          const rij = formatterProps.row as IRegel;

                          const naam =
                            rij.opdracht.opdrachtstatus.Status === EOpdrachtstatusTransport.Planning
                              ? 'Planning'
                              : rij.opdracht.opdrachtstatus.Status ===
                                EOpdrachtstatusTransport.Uitstaand
                              ? 'Uitstaand'
                              : rij.opdracht.opdrachtstatus.Status ===
                                EOpdrachtstatusTransport.Afgehandeld
                              ? 'Afgehandeld'
                              : '';

                          return (
                            <OpdrachtStatus
                              status={rij.opdracht.opdrachtstatus.Status}
                              naam={naam}
                            />
                          );
                        }}
                      />

                      <DataTypeProvider
                        for={['__voorraadReservering']}
                        formatterComponent={(formatterProps) => {
                          const rij: IOphalenOpdrachtregelsResultElement = formatterProps.row;

                          return (
                            <ReserveringProductComp
                              rij={formatterProps.row}
                              magID={magID}
                              onVernieuwen={ophalenTransportopdrachtregels}
                            />
                          );
                        }}
                      />

                      <DataTypeProvider
                        for={['__gereserveerd']}
                        formatterComponent={(formatterProps) => {
                          const rij: IOphalenOpdrachtregelsResultElement = formatterProps.row;

                          return (
                            <VoorraadIndicatie
                              status={
                                rij.type!.Gereserveerd
                                  ? EVoorraadStatus.Groen
                                  : EVoorraadStatus.Rood
                              }
                            />
                          );
                        }}
                      />

                      {/* <DataTypeProvider
                  for={['__gereserveerd']}
                  formatterComponent={(formatterProps) => {
                    const rij: IOphalenOpdrachtregelsResultElement = formatterProps.row;

                    // return <span>{rij.type!.Gereserveerd ? 'Ja' : 'Nee'}</span>;

                    return (
                      <div className="d-flex align-items-center mb-1">
                        <a
                          href="#"
                          onClick={() => {
                            wijzigReservering(rij.TrsRegID, !rij.type?.Gereserveerd!);
                          }}
                        >
                          <VoorraadIndicatie
                            status={
                              rij.type!.Gereserveerd ? EVoorraadStatus.Groen : EVoorraadStatus.Rood
                            }
                          />
                        </a>
                      </div>
                    );
                  }}
                /> */}

                      <DataTypeProvider
                        for={['__aantalVrij']}
                        formatterComponent={(formatterProps) => {
                          const rij: IOphalenOpdrachtregelsResultElement = formatterProps.row;

                          const info = voorraadInfo.find((x) => x.TypeID === rij.type!.TypeID)!;
                          const aantal = info !== undefined ? info.aantalVrij : '';
                          return (
                            <span style={{ color: aantal < 0 ? EKleur.Rood : EKleur.Grijs }}>
                              {aantal}
                            </span>
                          );
                        }}
                      />

                      <DataTypeProvider
                        for={['__voorraadInfo']}
                        formatterComponent={(formatterProps) => {
                          const rij: IOphalenOpdrachtregelsResultElement = formatterProps.row;

                          return (
                            <div className="d-flex">
                              <a
                                href="#"
                                onClick={() => {
                                  setVoorraadTypeInfoDialoogState({
                                    typeID: rij.type!.TypeID,
                                    magID: 4, // TODO rij.magazijn.MagID,
                                  });
                                }}
                              >
                                <IconInformatie
                                  style={{ width: 15, height: 15, fill: EKleur.Blauw }}
                                />
                              </a>
                            </div>
                          );
                        }}
                      />

                      <DataTypeProvider
                        for={['__contractnummer']}
                        formatterComponent={(formatterProps) => {
                          const rij: IOphalenOpdrachtregelsResultElement = formatterProps.row;
                          if (rij.contract === null) {
                            return <span></span>;
                          }
                          return (
                            <div className="d-flex  align-items-center">
                              {/* <ContractStatusIndicatie
                          status={rij.contract.StatusnaamEnum as EContractStatus}
                        /> */}

                              <div className="ml-2 ">
                                {rij.contract.basis.Basisnummer + '.' + rij.contract.Volgnummer}
                              </div>
                            </div>
                          );
                        }}
                      />

                      <DataTypeProvider
                        for={['__productsoort']}
                        formatterComponent={(formatterProps) => {
                          const rij: IOphalenOpdrachtregelsResultElement = formatterProps.row;
                          const product =
                            rij.type !== null ? (
                              <span>{rij.type.ProductsoortnaamKort}</span>
                            ) : rij.product !== null ? (
                              <span>{rij.product.ProductsoortnaamKort}</span>
                            ) : null;
                          return <span>{product}</span>;
                        }}
                      />

                      <DataTypeProvider
                        for={['__product']}
                        formatterComponent={(formatterProps) => {
                          const rij: IOphalenOpdrachtregelsResultElementV2 = formatterProps.row;
                          const product =
                            rij.type !== null ? (
                              <span>
                                {(rij.type.Merkloos ? '' : rij.type.Merknaam + ' ') +
                                  rij.type.Typenaam}
                              </span>
                            ) : rij.product !== null ? (
                              <span>
                                {rij.product.producttype.Merknaam +
                                  ' ' +
                                  rij.product.producttype.Typenaam}
                              </span>
                            ) : (
                              <span></span>
                            );

                          return (
                            <a
                              href="#"
                              style={{ color: EKleur.DonkerGrijs }}
                              onClick={() => {
                                //   rij.type !== null
                                //     ? alert('Productinfo nieuw')
                                //     : setProductinfoDialoogState({ id: rij.product!.ProdID });
                              }}
                            >
                              <span>{product}</span>
                            </a>
                          );
                        }}
                      />

                      <DataTypeProvider
                        for={['__merk']}
                        formatterComponent={(formatterProps) => {
                          const rij: IRegel = formatterProps.row;

                          return rij.type !== null ? (
                            <span>{rij.type.Merkloos ? '' : rij.type.Merknaam}</span>
                          ) : rij.product !== null ? (
                            <span>{rij.product.producttype.Merknaam}</span>
                          ) : (
                            <span></span>
                          );
                        }}
                      />

                      <DataTypeProvider
                        for={['__type']}
                        formatterComponent={(formatterProps) => {
                          const rij: IRegel = formatterProps.row;

                          return rij.type !== null ? (
                            <span>{rij.type.Typenaam}</span>
                          ) : rij.product !== null ? (
                            <span>{rij.product.producttype.Typenaam}</span>
                          ) : (
                            <span></span>
                          );
                        }}
                      />

                      <DataTypeProvider
                        for={['__bezoekdatum']}
                        formatterComponent={(formatterProps) => {
                          const rij: IRegel = formatterProps.row;
                          const bezoekdatum =
                            rij.opdracht.Bezoekdatum !== null
                              ? new Date(new Date(rij.opdracht.Bezoekdatum))
                              : null;

                          return (
                            <span>
                              {bezoekdatum === null
                                ? 'Nog opgeven'
                                : format(bezoekdatum, 'dd-MM-yyyy')}
                            </span>
                          );
                        }}
                      />

                      <DataTypeProvider
                        for={['__relatienaam']}
                        formatterComponent={(formatterProps) => {
                          const rij: IOphalenOpdrachtregelsResultElementV2 = formatterProps.row;
                          if (rij.RelID !== null) {
                            return <RelatieVisualisatie relID={rij.RelID} />;
                          } else {
                            return <span />;
                          }
                        }}
                      />

                      <DataTypeProvider
                        for={['__inkoopopdrachtAanwezig']}
                        formatterComponent={(formatterProps) => {
                          const rij: IRegel = formatterProps.row;

                          if (rij.type === null || rij.type.Bulkproduct) {
                            return <span></span>;
                          }

                          const typeID = rij.type.TypeID; // TypeID van leveropdracht

                          const voorraad = voorraadInfo.find((x) => x.TypeID === typeID);
                          const aantalInkoop = voorraad !== undefined ? voorraad.aantalInkoop : 0;
                          return <span>{aantalInkoop !== 0 ? aantalInkoop : ''}</span>;

                          // const aanwezig = inkoopopdrachten.some(
                          //   (o) =>
                          //     o.magazijn.MagID === rij.opdracht.magazijn.MagID &&
                          //     typeID ===
                          //       (o.producttypeVerwacht !== null
                          //         ? o.producttypeVerwacht.TypeID
                          //         : o.producttype.TypeID),
                          // );
                          // return <span>{aanwezig ? 'Ja' : ''}</span>;
                        }}
                      />

                      <SelectionState
                        selection={urlState.selectie}
                        onSelectionChange={(x) => setUrlStateSync('selectie', x as number[])}
                      />
                      <RowDetailState defaultExpandedRowIds={[]} />
                      <SortingState defaultSorting={[]} />
                      <IntegratedSorting />
                      <VirtualTable />
                      <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
                      <TableHeaderRow showSortingControls />
                    </Grid>
                  </GridStyleWrapper>
                )}
              </AutoSizer>
            )}
          </>
        }
      />

      {voorraadTypeInfoDialoogState !== null && (
        <VoorraadTypeInfoDialoog
          open
          typeID={voorraadTypeInfoDialoogState.typeID}
          magID={voorraadTypeInfoDialoogState.magID}
          onSuccess={() => setVoorraadTypeInfoDialoogState(null)}
          onAnnuleren={() => setVoorraadTypeInfoDialoogState(null)}
        />
      )}
    </>
  );
};

export default withRouter(Reserveringen);
