import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Tegel from '../../../../components/tegel/Tegel';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../models/IRemoteData';
import api from '../../../../api';
import { IOphalenOpdrachtenResultElement } from '../../../../../../shared/src/api/v2/inkoop/opdracht';
import TopDebiteuren from './Top 5 debiteuren';
import AlgemeneInformatie from './AlgemeneInformatie';
import { IOphalenInstellingenResult } from '../../../../../../shared/src/api/v2/instelling';
import { addDays, format } from 'date-fns';
import { IOphalenBatchesResultElement } from '../../../../../../shared/src/api/v2/bank/opdracht/opdracht';
import FinancieleInformatie from './FinancieleInformatie';
import { IOphalenDebiteurenResultElement } from '../../../../../../shared/src/api/v2/debiteur';
import { EBankopdrachtsoort } from '../../../../bedrijfslogica/enums';
import { IOphalenDashboardInfoResult } from '../../../../../../shared/src/api/v2/debiteur/overzicht';
import { Helmet } from 'react-helmet';

export const EntiteitHeading: React.FC = (props) => {
  return (
    <div className="d-flex w-100 mb-2 p-1 pl-2 pr-2">
      <span style={{ fontSize: 16 }} className="font-weight-bold">
        {props.children}
      </span>
    </div>
  );
};

export interface ITegelBasisProps {
  titel: JSX.Element | string;
}

export const TegelBasis: React.FC<ITegelBasisProps> = (props) => {
  return (
    <div className="pt-2 pb-2 h-100">
      <Tegel rootProps={{ style: { height: '100%', backgroundColor: Kleur.Wit } }}>
        <EntiteitHeading>{props.titel}</EntiteitHeading>
        <div className="flex-fill d-flex flex-column w-100 h-100">{props.children}</div>
      </Tegel>
    </div>
  );
};

export interface IDashboardContext {
  dashboardInfo: IRemoteData<IOphalenDashboardInfoResult>;
  inkoopopdrachten: IRemoteData<IOphalenOpdrachtenResultElement[]>;
  instellingen: IRemoteData<IOphalenInstellingenResult>;
  lopendeBankbatches: IRemoteData<IOphalenBatchesResultElement[]>;
  topDebiteuren: IRemoteData<IOphalenDebiteurenResultElement[]>;
}
export const DashboardContext = React.createContext<IDashboardContext>(null as any);

const Dashboard = () => {
  const [instellingen, setInstellingen] = useState<IRemoteData<IOphalenInstellingenResult>>(
    createPendingRemoteData(),
  );

  const [dashboardInfo, setDashboardInfo] = useState<IRemoteData<IOphalenDashboardInfoResult>>(
    createPendingRemoteData(),
  );
  const [inkoopopdrachten, setInkoopopdrachten] = useState<
    IRemoteData<IOphalenOpdrachtenResultElement[]>
  >(createPendingRemoteData());

  const [lopendeBankbatches, setLopendeBankbatches] = useState<
    IRemoteData<IOphalenBatchesResultElement[]>
  >(createPendingRemoteData());

  const ophalenInstellingen = useCallback(async () => {
    const result = await api.v2.instelling.ophalenInstellingen({});
    setInstellingen(createReadyRemoteData(result));
  }, []);

  useEffect(() => {
    ophalenInstellingen();
  }, [ophalenInstellingen]);

  // Top 5 debiteuren
  const [topDebiteuren, setTopDebiteuren] = useState<
    IRemoteData<IOphalenDebiteurenResultElement[]>
  >(createPendingRemoteData());
  const ophalenTopDebiteuren = useCallback(async () => {
    const result = (
      await api.v2.debiteur.ophalenDebiteuren({
        filterSchema: { filters: [{ naam: 'IDS', data: [1, 2] }] },
      })
    ).debiteuren;
    setTopDebiteuren(createReadyRemoteData(result));
  }, []);

  useEffect(() => {
    ophalenTopDebiteuren();
  }, [ophalenTopDebiteuren]);

  const ophalenDashboardInfo = useCallback(async () => {
    const result = await api.v2.debiteur.overzicht.ophalenDashboardInfo({});
    setDashboardInfo(createReadyRemoteData(result));
  }, []);

  useEffect(() => {
    ophalenDashboardInfo();
  }, [ophalenDashboardInfo]);

  const ophalenInkoopopdrachten = useCallback(async () => {
    const peildatum = format(addDays(new Date(), 0), 'dd-MM-yyyy');

    const result = await api.v2.inkoop.opdracht.ophalenOpdrachten({
      filterSchema: {
        filters: [
          { naam: 'IS_VERSTUURD', data: true },
          { naam: 'NOG_TE_LEVEREN', data: true },
          { naam: 'IS_AFGEHANDELD', data: false },
        ],
      },
      orderSchema: {
        orders: [
          {
            naam: 'DATUM_VERWACHT',
            richting: 'ASC',
          },
          {
            naam: 'DATUM_GEWENST',
            richting: 'ASC',
          },
        ],
      },
    });

    const leveringenVandaag = result.inkoopopdrachten
      .filter((x) => x.DatumVerwacht !== null || x.DatumGewenst !== null)
      .filter((x) => {
        return (
          format(
            new Date((x.DatumVerwacht !== null ? x.DatumVerwacht : x.DatumGewenst)!),
            'dd-MM-yyyy',
          ) === peildatum
        );
      });

    setInkoopopdrachten(createReadyRemoteData(leveringenVandaag));
  }, []);

  useEffect(() => {
    ophalenInkoopopdrachten();
  }, [ophalenInkoopopdrachten]);

  const ophalenUitstaandeBankbatches = useCallback(async () => {
    const result = await api.v2.bank.opdracht.ophalenBatches({
      filterSchema: {
        filters: [
          { naam: 'IS_UITGEVOERD', data: false },
          { naam: 'SOORT', data: EBankopdrachtsoort.Incasso },
        ],
      },
      orderSchema: { orders: [{ naam: 'UITVOERDATUM', richting: 'ASC' }] },
    });
    setLopendeBankbatches(createReadyRemoteData(result));
  }, []);

  useEffect(() => {
    ophalenUitstaandeBankbatches();
  }, [ophalenUitstaandeBankbatches]);

  const contextValue = useMemo<IDashboardContext>(() => {
    return {
      dashboardInfo,
      inkoopopdrachten,
      instellingen,
      lopendeBankbatches: lopendeBankbatches,
      topDebiteuren,
    };
  }, [dashboardInfo, inkoopopdrachten, instellingen]);

  return (
    <DashboardContext.Provider value={contextValue}>
      <Helmet>
        <title>Dashboard Debiteuren</title>
      </Helmet>
      <div className="d-flex flex-column flex-fill p-3">
        <div className="row">
          {instellingen.state === ERemoteDataState.Ready &&
            dashboardInfo.state === ERemoteDataState.Ready && (
              <div className="col-4">
                <AlgemeneInformatie />
              </div>
            )}

          {/* {inkoopopdrachten.state === ERemoteDataState.Ready && inkoopopdrachten.data!.length > 0 && ( */}
          <div className="col-8">
            <TopDebiteuren />
          </div>
          {/* )} */}

          {/* <div className="col-8">
            <FinancieleInformatie />
          </div> */}
        </div>
      </div>
    </DashboardContext.Provider>
  );
};

export default Dashboard;
