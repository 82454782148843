import {
  IKoppelenOmruilParams,
  IKoppelenOmruilResult,
  IVerwijderenOmruilParams,
  IVerwijderenOmruilResult,
} from '../../../../../../shared/src/api/v2/transport/opdracht/omruil';
import ICheckData from '../../../../../../shared/src/models/ICheckData';
import api from '../../../index';

const omruil = {
  checkKoppelenOmruil: async (params: IKoppelenOmruilParams): Promise<ICheckData> => {
    return await api.post('v2/transport/opdracht/omruil/check-koppelen-omruil', params);
  },
  koppelenOmruil: async (params: IKoppelenOmruilParams): Promise<IKoppelenOmruilResult> => {
    return await api.post('v2/transport/opdracht/omruil/koppelen-omruil', params);
  },
  verwijderenOmruil: async (
    params: IVerwijderenOmruilParams,
  ): Promise<IVerwijderenOmruilResult> => {
    return await api.post('v2/transport/opdracht/omruil/verwijderen-omruil', params);
  },
};

export default omruil;
