import LocatieContent from './contents/LocatieContent';
import PersoonContent from './contents/PersoonContent';
import RelatieContent from './contents/RelatieContent';
import WhatsAppBerichtContent from './contents/WhatsAppBerichtContent';
import BestandContent from './contents/BestandContent';
import BestandsmapContent from './contents/BestandsmapContent';
import InkoopfactuurContent from './contents/InkoopfactuurContent';
import ContractContent from './contents/ContractContent';
import React from 'react';
import {
  EFunctioneleIcon,
  EyeIcon,
  functioneleIconMap,
  IconAccountCircle,
  IconCloud,
  IconContract,
  IconKlantkaart,
  IconOverzicht,
  IconPersoon,
  IconService,
  IconTransport,
  IconVink,
} from '../../components/Icons';
import { RouteComponentProps } from 'react-router-dom';
import { RootStore } from '../../stores/RootStore';
import api from '../../api';

export enum EEntiteit {
  Product = 'PRODUCT',
  Contract = 'CONTRACT',
  Locatie = 'LOCATIE',
  Relatie = 'RELATIE',
  Persoon = 'PERSOON',
  Organisatie = 'ORGANISATIE',
  WhatsAppBericht = 'WHATSAPP_BERICHT',
  Email = 'EMAIL',
  Transportopdracht = 'TRANSPORTOPDRACHT',
  Pendelopdracht = 'PENDELOPDRACHT',
  Productmodel = 'PRODUCTMODEL',
  Servicemelding = 'SERVICEMELDING',
  Bankmutatie = 'BANKMUTATIE',
  SjabloonInhoud = 'SJABLOON_INHOUD',
  Inkoopopdracht = 'INKOOPOPDRACHT',
  ProductDocumentatie = 'PRODUCT_DOCUMENTATIE',
  Verkoopvoorstel = 'VERKOOPVOORSTEL',
  Actieclaim = 'ACTIECLAIM',
  Review = 'REVIEW',
  Telefoonoproep = 'TELEFOONOPROEP',
  Inkoopfactuur = 'INKOOPFACTUUR',
  Bestand = 'BESTAND',
  Bestandsmap = 'BESTANDSMAP',
}

export interface IContentProps {
  ids: number[];
}

export interface IEntiteit {
  key: EEntiteit;
  naam: string;
  content?: React.ComponentType<IContentProps>;
}

export const entiteiten = [
  {
    key: EEntiteit.Product,
    naam: 'Producten',
  },
  {
    key: EEntiteit.Contract,
    naam: 'Contracten',
    content: ContractContent,
  },
  {
    key: EEntiteit.Locatie,
    naam: 'Locaties',
    content: LocatieContent,
  },
  {
    key: EEntiteit.Relatie,
    naam: 'Relaties',
    content: RelatieContent,
  },
  {
    key: EEntiteit.Persoon,
    naam: 'Personen',
    content: PersoonContent,
  },
  {
    key: EEntiteit.Organisatie,
    naam: 'Organisaties',
  },
  {
    key: EEntiteit.WhatsAppBericht,
    naam: 'WhatsApp berichten',
    content: WhatsAppBerichtContent,
  },
  {
    key: EEntiteit.Email,
    naam: 'E-mails',
  },
  {
    key: EEntiteit.Transportopdracht,
    naam: 'Transportopdrachten',
  },
  {
    key: EEntiteit.Pendelopdracht,
    naam: 'Pendelopdrachten',
  },
  {
    key: EEntiteit.Productmodel,
    naam: 'Productmodellen',
  },
  {
    key: EEntiteit.Servicemelding,
    naam: 'Servicemeldingen',
  },
  {
    key: EEntiteit.Bankmutatie,
    naam: 'Bankmutaties',
  },
  {
    key: EEntiteit.SjabloonInhoud,
    naam: 'Sjablooninhouden',
  },
  {
    key: EEntiteit.Inkoopopdracht,
    naam: 'Inkoopopdrachten',
  },
  {
    key: EEntiteit.ProductDocumentatie,
    naam: 'Productdocumentatie',
  },
  {
    key: EEntiteit.Verkoopvoorstel,
    naam: 'Verkoopvoorstellen',
  },
  {
    key: EEntiteit.Actieclaim,
    naam: 'Actieclaims',
  },
  {
    key: EEntiteit.Review,
    naam: 'Reviews',
  },
  {
    key: EEntiteit.Telefoonoproep,
    naam: 'Telefoonoproepen',
  },
  {
    key: EEntiteit.Inkoopfactuur,
    naam: 'Inkoopfacturen',
    content: InkoopfactuurContent,
  },
  {
    key: EEntiteit.Bestand,
    naam: 'Bestanden',
    content: BestandContent,
  },
  {
    key: EEntiteit.Bestandsmap,
    naam: 'Bestandsmappen',
    content: BestandsmapContent,
  },
];

export const entiteitenBijKey = entiteiten.reduce<Record<EEntiteit, IEntiteit>>(
  (acc, entiteit) => ({ ...acc, [entiteit.key]: entiteit }),
  {} as any,
);

export interface INavigatieItemIcoonProps {
  breedte: number;
  hoogte: number;
  kleur: string;
}

export type NavigatieItemKey =
  | 'dashboard'
  | 'klant'
  | 'klant_overzicht'
  | 'klant_bestanden'
  | 'klant_personen'
  | 'klant_communicatie'
  | 'klant_contracten'
  | 'klant_contracten_overzicht'
  | 'klant_contracten_aangeboden'
  | 'klant_contracten_producten'
  | 'klant_contracten_accessoires'
  | 'klant_contracten_verkoopvoorstellen'
  | 'klant_contracten_prolongaties'
  | 'klant_transport'
  | 'klant_transport_opdrachten'
  | 'klant_transport_bezoektijden'
  | 'klant_transport_diensten'
  | 'klant_service'
  | 'klant_service_meldingen'
  | 'klant_service_rep.opdrachten'
  | 'klant_service_werkbonnen'
  | 'klant_accounts'
  | 'klant_acceptatie'
  | 'klant_acceptatie_procedure'
  | 'klant_acceptatie_kredietwaardigheid'
  | 'klant_winkelwagens'
  | 'klant_facturen'
  | 'klant_facturen_overzicht'
  | 'klant_facturen_vorderingen'
  | 'klant_facturen_aanmaningen'
  | 'klant_bankzaken'
  | 'klant_bankzaken_bankopdrachten'
  | 'klant_debiteur'
  | 'klant_debiteur_betalingsregelingen'
  | 'klant_debiteur_incassozaken'
  | 'klant_debiteur_herincassozaken'
  | 'klant_debiteur_rechtzaken'
  | 'klant_boekingen'
  | 'klant_webbetalingen'
  | 'klant_rekeningen'
  | 'klant_rubrieken'
  | 'klant_rubrieken_factuurkenmerken'
  | 'klant_rubrieken_kostenplaatsen'
  | 'klant_rubrieken_budgetcodes'
  | 'klant_financieel_overzicht'
  | 'front_office'
  | 'front_office_taken'
  | 'front_office_signaleringen'
  | 'front_office_externe_data'
  | 'front_office_opvolging'
  | 'front_office_opvolging_acceptatie'
  | 'front_office_opvolging_concept_contracten'
  | 'front_office_opvolging_overdracht'
  | 'front_office_opvolging_actieclaims'
  | 'front_office_opvolging_klantacties'
  | 'front_office_opvolging_verkoopvoorstellen'
  | 'front_office_opvolging_accounts_activeren'
  | 'front_office_opvolging_feedbacks'
  | 'back_office'
  | 'back_office_beltaken'
  | 'back_office_reviews'
  | 'back_office_takenplanner'
  | 'back_office_duplicaten'
  | 'back_office_duplicaten_relaties'
  | 'back_office_duplicaten_personen'
  | 'back_office_monitoring'
  | 'back_office_monitoring_winkelwagens'
  | 'back_office_monitoring_contacten'
  | 'back_office_monitoring_website'
  | 'back_office_monitoring_accounts'
  | 'back_office_monitoring_klantmutaties'
  | 'back_office_monitoring_events'
  | 'communicatie'
  | 'communicatie_whatsapp'
  | 'communicatie_email'
  | 'communicatie_telefoon'
  | 'communicatie_sms'
  | 'communicatie_contactverzoek'
  | 'leverancier'
  | 'transport'
  | 'transport_opdrachten'
  | 'transport_opdrachten_reserveringen'
  | 'transport_opdrachten_planning'
  | 'transport_opdrachten_uitstaand'
  | 'transport_opdrachten_afgehandeld'
  | 'transport_opdrachten_referenties'
  | 'transport_bezoektijden'
  | 'transport_diensten'
  | 'service'
  | 'service_meldingen'
  | 'service_opdrachten'
  | 'service_werkbonnen'
  | 'service_diensten'
  | 'voorraad'
  | 'voorraad_ingepakt'
  | 'voorraad_uitgepakt'
  | 'voorraad_mutaties'
  | 'drive'
  | 'inkoop'
  | 'inkoop_opdrachten'
  | 'inkoop_opdrachten_nieuw'
  | 'inkoop_opdrachten_uitstaand'
  | 'inkoop_opdrachten_afgehandeld'
  | 'inkoop_opdrachten_inslag_mutaties'
  | 'inkoop_opdrachten_reserveringen'
  | 'inkoop_opdrachten_parameters'
  | 'inkoop_voorraad'
  | 'inkoop_voorraad_ingepakt'
  | 'inkoop_voorraad_uitgepakt'
  | 'inkoop_voorraad_mutaties'
  | 'inkoop_aanbod'
  | 'inkoop_aanbod_producten'
  | 'inkoop_aanbod_productmerken'
  | 'inkoop_diensten'
  | 'magazijn'
  | 'magazijn_beheer'
  | 'magazijn_pendelopdrachten'
  | 'magazijn_inventarisatie'
  | 'magazijn_mutaties'
  | 'magazijn_magazijndiensten'
  | 'magazijn_pendeldiensten'
  | 'aanbod'
  | 'aanbod_tarieven'
  | 'aanbod_tarieven_verhuur'
  | 'aanbod_tarieven_acties'
  | 'aanbod_tarieven_verkoop'
  | 'aanbod_tarieven_overige'
  | 'aanbod_producten'
  | 'aanbod_producten_productmodellen'
  | 'aanbod_producten_abonnementen'
  | 'aanbod_producten_productsoorten'
  | 'aanbod_producten_premiums'
  | 'aanbod_producten_alternatieven'
  | 'aanbod_specificaties'
  | 'aanbod_specificaties_producten'
  | 'aanbod_specificaties_beheer'
  | 'aanbod_specificaties_groepen'
  | 'financieel_facturering'
  | 'financieel'
  | 'financieel_facturering_polongaties'
  | 'financieel_facturering_vorderingen'
  | 'financieel_facturering_facturen'
  | 'financieel_facturering_indexeringen'
  | 'financieel_debiteuren'
  | 'financieel_debiteuren_beheer'
  | 'financieel_debiteuren_verrekeningen'
  | 'financieel_debiteuren_aanmaningen'
  | 'financieel_debiteuren_betalingsregelingen'
  | 'financieel_debiteuren_incassozaken'
  | 'financieel_debiteuren_herincassozaken'
  | 'financieel_debiteuren_rechtzaken'
  | 'financieel_debiteuren_overzicht'
  | 'financieel_debiteuren_incassodiensten'
  | 'financieel_crediteuren'
  | 'financieel_crediteuren_inkoopfacturen'
  | 'financieel_crediteuren_inkoopfacturen_inboeken'
  | 'financieel_crediteuren_inkoopfacturen_betalen'
  | 'financieel_crediteuren_inkoopfacturen_overzicht'
  | 'financieel_crediteuren_koppelen_producten'
  | 'financieel_crediteuren_koppelen_werkbonnen'
  | 'financieel_crediteuren_koppelen_sponsoring'
  | 'financieel_crediteuren_koppelen_transport'
  | 'financieel_crediteuren_koppelen_sponsoring_facturen'
  | 'financieel_crediteuren_koppelen_sponsoring_contracten'
  | 'financieel_crediteuren_koppelen'
  | 'financieel_crediteuren_overzicht'
  | 'financieel_bankzaken'
  | 'financieel_bankzaken_bankopdrachten'
  | 'financieel_bankzaken_bankopdrachten_opdrachten'
  | 'financieel_bankzaken_bankopdrachten_batches'
  | 'financieel_bankzaken_bankopdrachten_stornering'
  | 'financieel_bankzaken_bankopdrachten_specificaties'
  | 'financieel_bankzaken_bankopdrachten_stornocodes'
  | 'financieel_bankzaken_bankmutaties'
  | 'financieel_bankzaken_bankmutaties_concept'
  | 'financieel_bankzaken_bankmutaties_definitief'
  | 'financieel_boekhouding'
  | 'financieel_boekhouding_boekingen'
  | 'financieel_boekhouding_boekingen_inkoop'
  | 'financieel_boekhouding_boekingen_verkoop'
  | 'financieel_boekhouding_boekingen_memoriaal'
  | 'financieel_boekhouding_boekingen_bank'
  | 'financieel_boekhouding_boekingen_export'
  | 'financieel_boekhouding_activaregister'
  | 'financieel_boekhouding_activaregister_activeren'
  | 'financieel_boekhouding_activaregister_deactiveren'
  | 'financieel_boekhouding_activaregister_afschrijvingen'
  | 'financieel_boekhouding_activaregister_register'
  | 'financieel_boekhouding_controle'
  | 'financieel_boekhouding_controle_banksaldi'
  | 'financieel_boekhouding_beheer'
  | 'financieel_boekhouding_beheer_grootboekrekeningen'
  | 'financieel_boekhouding_beheer_dagboeken'
  | 'financieel_boekhouding_beheer_btwsoorten'
  | 'financieel_boekhouding_beheer_btwtarieven'
  | 'financieel_boekhouding_rapportage'
  | 'marketing'
  | 'marketing_sponsoring'
  | 'marketing_sponsoring_voorstellen'
  | 'marketing_sponsoring_contracten'
  | 'marketing_sponsoring_materiaal'
  | 'marketing_sponsoring_diensten'
  | 'marketing_bedrijfsinformatie'
  | 'marketing_concurrentie'
  | 'kwaliteitsbeheer'
  | 'kwaliteitsbeheer_productadvies'
  | 'kwaliteitsbeheer_productadvies_productstaat'
  | 'facilitair'
  | 'facilitair_sjablonen'
  | 'facilitair_documentatie'
  | 'facilitair_import_export'
  | 'website_webteksten'
  | 'website_afbeeldingen'
  | 'administratie'
  | 'administratie_verlof'
  | 'administratie_verlof_overzicht'
  | 'administratie_verlof_registratie'
  | 'administratie_medewerkers'
  | 'administratie_bedrijfsgegevens'
  | 'beheer'
  | 'beheer_instellingen'
  | 'beheer_instellingen_applicatie'
  | 'beheer_instellingen_website'
  | 'beheer_technisch'
  | 'beheer_technisch_logs';

export interface INavigerenParams {
  routeProps: RouteComponentProps;
  rootStore: RootStore;
}

export interface INavigatieItem {
  key: NavigatieItemKey;
  naam: string;
  icoon?: React.ComponentType<INavigatieItemIcoonProps>;
  onderliggendeItems?: INavigatieItem[];
  navigeren?: (params: INavigerenParams) => Promise<void>;
}

export const navigatieItems: INavigatieItem[] = [
  {
    key: 'dashboard',
    naam: 'Dashboard',
    icoon: ({ breedte, hoogte, kleur }) => {
      const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
      return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
    },
    navigeren: async (params) => {
      params.routeProps.history.push('/dashboard');
    },
  },
  {
    key: 'klant',
    naam: 'Klant',
    icoon: ({ breedte, hoogte, kleur }) => (
      <IconKlantkaart
        style={{
          width: breedte,
          height: hoogte,
          fill: kleur,
        }}
      />
    ),
    onderliggendeItems: [
      {
        key: 'klant_overzicht',
        naam: 'Overzicht',
        icoon: ({ breedte, hoogte, kleur }) => (
          <IconOverzicht
            style={{
              width: breedte,
              height: hoogte,
              fill: kleur,
            }}
          />
        ),
        navigeren: async (params) => {
          const relID = await bepaalKlantRelIDNavigeren(params);
          params.routeProps.history.push(`/klant/${relID}/overzicht`);
        },
      },
      {
        key: 'klant_bestanden',
        naam: 'Bestanden',
        icoon: ({ breedte, hoogte, kleur }) => (
          <IconCloud
            style={{
              width: breedte,
              height: hoogte,
              fill: kleur,
            }}
          />
        ),
        navigeren: async (params) => {
          const relID = await bepaalKlantRelIDNavigeren(params);
          params.routeProps.history.push(`/klant/${relID}/bestanden`);
        },
      },
      {
        key: 'klant_personen',
        naam: 'Personen',
        icoon: ({ breedte, hoogte, kleur }) => (
          <IconPersoon
            style={{
              width: breedte,
              height: hoogte,
              fill: kleur,
            }}
          />
        ),
        navigeren: async (params) => {
          const relID = await bepaalKlantRelIDNavigeren(params);
          params.routeProps.history.push(`/klant/${relID}/contactpersonen`);
        },
      },
      {
        key: 'klant_communicatie',
        naam: 'Communicatie',
        icoon: ({ breedte, hoogte, kleur }) => {
          const IconCommunicatie = functioneleIconMap[EFunctioneleIcon.Communicatie];
          return (
            <IconCommunicatie
              style={{
                width: breedte,
                height: hoogte,
                fill: kleur,
              }}
            />
          );
        },
        navigeren: async (params) => {
          const relID = await bepaalKlantRelIDNavigeren(params);
          params.routeProps.history.push(`/klant/${relID}/communicatie`);
        },
      },
      {
        key: 'klant_contracten',
        naam: 'Contracten',
        icoon: ({ breedte, hoogte, kleur }) => (
          <IconContract
            style={{
              width: breedte,
              height: hoogte,
              fill: kleur,
            }}
          />
        ),
        navigeren: async (params) => {
          const relID = await bepaalKlantRelIDNavigeren(params);
          params.routeProps.history.push(`/klant/${relID}/contracten`);
        },
        onderliggendeItems: [
          {
            key: 'klant_contracten_overzicht',
            naam: 'Overzicht',
            icoon: ({ breedte, hoogte, kleur }) => (
              <IconOverzicht
                style={{
                  width: breedte,
                  height: hoogte,
                  fill: kleur,
                }}
              />
            ),
            navigeren: async (params) => {
              const relID = await bepaalKlantRelIDNavigeren(params);
              params.routeProps.history.push(`/klant/${relID}/contracten/overzicht`);
            },
          },
          {
            key: 'klant_contracten_aangeboden',
            naam: 'Aangeboden',
            navigeren: async (params) => {
              const relID = await bepaalKlantRelIDNavigeren(params);
              params.routeProps.history.push(`/klant/${relID}/contracten/aangeboden`);
            },
          },
          {
            key: 'klant_contracten_producten',
            naam: 'Producten',
            navigeren: async (params) => {
              const relID = await bepaalKlantRelIDNavigeren(params);
              params.routeProps.history.push(`/klant/${relID}/contracten/producten`);
            },
          },
          {
            key: 'klant_contracten_accessoires',
            naam: 'Accessoires',
            navigeren: async (params) => {
              const relID = await bepaalKlantRelIDNavigeren(params);
              params.routeProps.history.push(`/klant/${relID}/contracten/verkoopmodellen`);
            },
          },
          {
            key: 'klant_contracten_verkoopvoorstellen',
            naam: 'Verkoopvoorstellen',
            navigeren: async (params) => {
              const relID = await bepaalKlantRelIDNavigeren(params);
              params.routeProps.history.push(`/klant/${relID}/contracten/verkoopvoorstellen`);
            },
          },
          {
            key: 'klant_contracten_prolongaties',
            naam: 'Prolongaties',
            navigeren: async (params) => {
              const relID = await bepaalKlantRelIDNavigeren(params);
              params.routeProps.history.push(`/klant/${relID}/contracten/prolongaties`);
            },
          },
        ],
      },
      {
        key: 'klant_transport',
        naam: 'Transport',
        icoon: ({ breedte, hoogte, kleur }) => (
          <IconTransport
            style={{
              width: breedte,
              height: hoogte,
              fill: kleur,
            }}
          />
        ),
        navigeren: async (params) => {
          params.routeProps.history.push(`/transport`);
        },
        onderliggendeItems: [
          {
            key: 'klant_transport_opdrachten',
            naam: 'Opdrachten',
            navigeren: async (params) => {
              params.routeProps.history.push(`/transport/opdrachten`);
            },
          },
          {
            key: 'klant_transport_bezoektijden',
            naam: 'Bezoektijden',
            navigeren: async (params) => {
              params.routeProps.history.push(`/transport/bezoektijden`);
            },
          },
          {
            key: 'klant_transport_diensten',
            naam: 'Diensten',
            navigeren: async (params) => {
              params.routeProps.history.push(`/transport/diensten`);
            },
          },
        ],
      },
      {
        key: 'klant_service',
        naam: 'Service',
        icoon: ({ breedte, hoogte, kleur }) => (
          <IconService
            style={{
              width: breedte,
              height: hoogte,
              fill: kleur,
            }}
          />
        ),
        navigeren: async (params) => {
          params.routeProps.history.push(`/service`);
        },
        onderliggendeItems: [
          {
            key: 'klant_service_meldingen',
            naam: 'Meldingen',
            navigeren: async (params) => {
              const relID = await bepaalKlantRelIDNavigeren(params);
              params.routeProps.history.push(`/klant/${relID}/service/melding`);
            },
          },
          {
            key: 'klant_service_rep.opdrachten',
            naam: 'Reparatie opdrachten',
            navigeren: async (params) => {
              const relID = await bepaalKlantRelIDNavigeren(params);
              params.routeProps.history.push(`/klant/${relID}/service/opdracht`);
            },
          },
          {
            key: 'klant_service_werkbonnen',
            naam: 'Werkbonnen',
            navigeren: async (params) => {
              const relID = await bepaalKlantRelIDNavigeren(params);
              params.routeProps.history.push(`/klant/${relID}/service/werkbon`);
            },
          },
        ],
      },
      {
        key: 'klant_accounts',
        naam: 'Accounts',
        icoon: ({ breedte, hoogte, kleur }) => (
          <IconAccountCircle
            style={{
              width: breedte,
              height: hoogte,
              fill: kleur,
            }}
          />
        ),
        navigeren: async (params) => {
          const relID = await bepaalKlantRelIDNavigeren(params);
          params.routeProps.history.push(`/klant/${relID}/accounts`);
        },
      },
      {
        key: 'klant_acceptatie',
        naam: 'Acceptatie',
        icoon: ({ breedte, hoogte, kleur }) => (
          <IconVink
            style={{
              width: breedte,
              height: hoogte,
              fill: kleur,
            }}
          />
        ),
        navigeren: async (params) => {
          const relID = await bepaalKlantRelIDNavigeren(params);
          params.routeProps.history.push(`/klant/${relID}/acceptatie`);
        },
        onderliggendeItems: [
          {
            key: 'klant_acceptatie_procedure',
            naam: 'Procedure',
            navigeren: async (params) => {
              const relID = await bepaalKlantRelIDNavigeren(params);
              params.routeProps.history.push(`/klant/${relID}/acceptatie/procedure`);
            },
          },
          {
            key: 'klant_acceptatie_kredietwaardigheid',
            naam: 'Kredietwaardigheid',
            navigeren: async (params) => {
              const relID = await bepaalKlantRelIDNavigeren(params);
              params.routeProps.history.push(`/klant/${relID}/acceptatie/kredietwaardigheid`);
            },
          },
        ],
      },
      {
        key: 'klant_winkelwagens',
        naam: 'Winkelwagens',
        icoon: ({ breedte, hoogte, kleur }) => (
          <IconAccountCircle
            style={{
              width: breedte,
              height: hoogte,
              fill: kleur,
            }}
          />
        ),
        navigeren: async (params) => {
          const relID = await bepaalKlantRelIDNavigeren(params);
          params.routeProps.history.push(`/klant/${relID}/winkelwagens`);
        },
      },
      {
        key: 'klant_facturen',
        naam: 'Facturen',
        icoon: ({ breedte, hoogte, kleur }) => (
          <IconAccountCircle
            style={{
              width: breedte,
              height: hoogte,
              fill: kleur,
            }}
          />
        ),
        navigeren: async (params) => {
          const relID = await bepaalKlantRelIDNavigeren(params);
          params.routeProps.history.push(`/klant/${relID}/facturen`);
        },
        onderliggendeItems: [
          {
            key: 'klant_facturen_overzicht',
            naam: 'Overzicht',
            navigeren: async (params) => {
              const relID = await bepaalKlantRelIDNavigeren(params);
              params.routeProps.history.push(`/klant/${relID}/facturen/overzicht`);
            },
          },
          {
            key: 'klant_facturen_vorderingen',
            naam: 'Vorderingen',
            navigeren: async (params) => {
              const relID = await bepaalKlantRelIDNavigeren(params);
              params.routeProps.history.push(`/klant/${relID}/facturen/vorderingen`);
            },
          },
          {
            key: 'klant_facturen_aanmaningen',
            naam: 'Aanmaningen',
            navigeren: async (params) => {
              const relID = await bepaalKlantRelIDNavigeren(params);
              params.routeProps.history.push(`/klant/${relID}/facturen/aanmaningen`);
            },
          },
        ],
      },
      {
        key: 'klant_debiteur',
        naam: 'Debiteur',
        icoon: ({ breedte, hoogte, kleur }) => (
          <IconAccountCircle
            style={{
              width: breedte,
              height: hoogte,
              fill: kleur,
            }}
          />
        ),
        navigeren: async (params) => {
          const relID = await bepaalKlantRelIDNavigeren(params);
          params.routeProps.history.push(`/klant/${relID}/debiteur`);
        },
        onderliggendeItems: [
          {
            key: 'klant_debiteur_betalingsregelingen',
            naam: 'Betalingsregelingen',
            icoon: ({ breedte, hoogte, kleur }) => (
              <IconAccountCircle
                style={{
                  width: breedte,
                  height: hoogte,
                  fill: kleur,
                }}
              />
            ),
            navigeren: async (params) => {
              const relID = await bepaalKlantRelIDNavigeren(params);
              params.routeProps.history.push(`/klant/${relID}/debiteur/betalingsregelingen`);
            },
          },
          {
            key: 'klant_debiteur_incassozaken',
            naam: 'Incassozaken',
            icoon: ({ breedte, hoogte, kleur }) => (
              <IconAccountCircle
                style={{
                  width: breedte,
                  height: hoogte,
                  fill: kleur,
                }}
              />
            ),
            navigeren: async (params) => {
              const relID = await bepaalKlantRelIDNavigeren(params);
              params.routeProps.history.push(`/klant/${relID}/debiteur/incassozaken`);
            },
          },
          {
            key: 'klant_debiteur_herincassozaken',
            naam: 'Herincassozaken',
            icoon: ({ breedte, hoogte, kleur }) => (
              <IconAccountCircle
                style={{
                  width: breedte,
                  height: hoogte,
                  fill: kleur,
                }}
              />
            ),
            navigeren: async (params) => {
              const relID = await bepaalKlantRelIDNavigeren(params);
              params.routeProps.history.push(`/klant/${relID}/debiteur/herincassozaken`);
            },
          },
          {
            key: 'klant_debiteur_rechtzaken',
            naam: 'Rechtzaken',
            icoon: ({ breedte, hoogte, kleur }) => (
              <IconAccountCircle
                style={{
                  width: breedte,
                  height: hoogte,
                  fill: kleur,
                }}
              />
            ),
            navigeren: async (params) => {
              const relID = await bepaalKlantRelIDNavigeren(params);
              params.routeProps.history.push(`/klant/${relID}/debiteur/rechtzaken`);
            },
          },
        ],
      },
      {
        key: 'klant_bankzaken',
        naam: 'Bankzaken',
        icoon: ({ breedte, hoogte, kleur }) => (
          <IconAccountCircle
            style={{
              width: breedte,
              height: hoogte,
              fill: kleur,
            }}
          />
        ),
        navigeren: async (params) => {
          const relID = await bepaalKlantRelIDNavigeren(params);
          params.routeProps.history.push(`/klant/${relID}/bankzaken`);
        },
        onderliggendeItems: [
          {
            key: 'klant_bankzaken_bankopdrachten',
            naam: 'Bankopdrachten',
            icoon: ({ breedte, hoogte, kleur }) => (
              <IconAccountCircle
                style={{
                  width: breedte,
                  height: hoogte,
                  fill: kleur,
                }}
              />
            ),
            navigeren: async (params) => {
              const relID = await bepaalKlantRelIDNavigeren(params);
              params.routeProps.history.push(`/klant/${relID}/bankzaken/bankopdrachten`);
            },
          },
        ],
      },
      {
        key: 'klant_boekingen',
        naam: 'Boekingen',
        icoon: ({ breedte, hoogte, kleur }) => (
          <IconAccountCircle
            style={{
              width: breedte,
              height: hoogte,
              fill: kleur,
            }}
          />
        ),
        navigeren: async (params) => {
          const relID = await bepaalKlantRelIDNavigeren(params);
          params.routeProps.history.push(`/klant/${relID}/boekingen`);
        },
      },
      {
        key: 'klant_webbetalingen',
        naam: 'Webbetalingen',
        icoon: ({ breedte, hoogte, kleur }) => (
          <IconAccountCircle
            style={{
              width: breedte,
              height: hoogte,
              fill: kleur,
            }}
          />
        ),
        navigeren: async (params) => {
          const relID = await bepaalKlantRelIDNavigeren(params);
          params.routeProps.history.push(`/klant/${relID}/webbetalingen`);
        },
      },
      {
        key: 'klant_rekeningen',
        naam: 'Rekeningen',
        icoon: ({ breedte, hoogte, kleur }) => (
          <IconAccountCircle
            style={{
              width: breedte,
              height: hoogte,
              fill: kleur,
            }}
          />
        ),
        navigeren: async (params) => {
          const relID = await bepaalKlantRelIDNavigeren(params);
          params.routeProps.history.push(`/klant/${relID}/rekeningen`);
        },
      },
    ],
  },
  {
    key: 'front_office',
    naam: 'Frontoffice',
    icoon: ({ breedte, hoogte, kleur }) => {
      const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
      return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
    },
    navigeren: async (params) => {
      params.routeProps.history.push('/front-office');
    },
    onderliggendeItems: [
      {
        key: 'front_office_signaleringen',
        naam: 'Signaleringen',
        icoon: ({ breedte, hoogte, kleur }) => {
          const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
          return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
        },
        navigeren: async (params) => {
          params.routeProps.history.push('/signaleringen');
        },
      },
      {
        key: 'front_office_taken',
        naam: 'Taken',
        icoon: ({ breedte, hoogte, kleur }) => {
          const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
          return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
        },
        navigeren: async (params) => {
          params.routeProps.history.push('/taken');
        },
      },
      {
        key: 'front_office_externe_data',
        naam: 'Externe data',
        icoon: ({ breedte, hoogte, kleur }) => {
          const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
          return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
        },
        navigeren: async (params) => {
          params.routeProps.history.push('/externe-data');
        },
      },

      {
        key: 'front_office_opvolging',
        naam: 'Opvolging',
        icoon: ({ breedte, hoogte, kleur }) => {
          const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
          return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
        },
        navigeren: async (params) => {
          params.routeProps.history.push('/opvolging');
        },
        onderliggendeItems: [
          {
            key: 'front_office_opvolging_accounts_activeren',
            naam: 'Accounts',
            icoon: ({ breedte, hoogte, kleur }) => {
              const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
              return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
            },
            navigeren: async (params) => {
              params.routeProps.history.push('/opvolging/accounts/activeren');
            },
          },
          {
            key: 'front_office_opvolging_concept_contracten',
            naam: 'Conceptcontracten',
            icoon: ({ breedte, hoogte, kleur }) => {
              const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
              return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
            },
            navigeren: async (params) => {
              params.routeProps.history.push('/opvolging/concept-contracten');
            },
          },
          {
            key: 'front_office_opvolging_overdracht',
            naam: 'Overdracht',
            icoon: ({ breedte, hoogte, kleur }) => {
              const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
              return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
            },
            navigeren: async (params) => {
              params.routeProps.history.push('/opvolging/overdracht');
            },
          },
          {
            key: 'front_office_opvolging_actieclaims',
            naam: 'Actieclaims',
            icoon: ({ breedte, hoogte, kleur }) => {
              const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
              return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
            },
            navigeren: async (params) => {
              params.routeProps.history.push('/opvolging/actieclaims');
            },
          },
          {
            key: 'front_office_opvolging_klantacties',
            naam: 'Klantacties',
            icoon: ({ breedte, hoogte, kleur }) => {
              const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
              return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
            },
            navigeren: async (params) => {
              params.routeProps.history.push('/opvolging/klantacties');
            },
          },
          {
            key: 'front_office_opvolging_verkoopvoorstellen',
            naam: 'Verkoopvoorstellen',
            icoon: ({ breedte, hoogte, kleur }) => {
              const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
              return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
            },
            navigeren: async (params) => {
              params.routeProps.history.push('/opvolging/verkoopvoorstellen');
            },
          },
          {
            key: 'front_office_opvolging_feedbacks',
            naam: 'Feedbacks',
            icoon: ({ breedte, hoogte, kleur }) => {
              const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
              return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
            },
            navigeren: async (params) => {
              params.routeProps.history.push('/opvolging/feedbacks');
            },
          },
        ],
      },
    ],
  },
  {
    key: 'back_office',
    naam: 'Backoffice',
    icoon: ({ breedte, hoogte, kleur }) => {
      const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
      return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
    },
    navigeren: async (params) => {
      params.routeProps.history.push('/');
    },
    onderliggendeItems: [
      {
        key: 'back_office_beltaken',
        naam: 'Beltaken',
        icoon: ({ breedte, hoogte, kleur }) => {
          const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
          return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
        },
        navigeren: async (params) => {
          params.routeProps.history.push('/backoffice/beltaken');
        },
      },
      {
        key: 'back_office_reviews',
        naam: 'Reviews',
        icoon: ({ breedte, hoogte, kleur }) => {
          const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
          return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
        },
        navigeren: async (params) => {
          params.routeProps.history.push('/reviews/voorstel');
        },
      },
      {
        key: 'back_office_takenplanner',
        naam: 'Takenplanner',
        icoon: ({ breedte, hoogte, kleur }) => {
          const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
          return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
        },
        navigeren: async (params) => {
          params.routeProps.history.push('/takenplanner/lijst');
        },
      },
      {
        key: 'back_office_duplicaten',
        naam: 'Duplicaten',
        icoon: ({ breedte, hoogte, kleur }) => {
          const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
          return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
        },
        navigeren: async (params) => {
          params.routeProps.history.push('/');
        },
        onderliggendeItems: [
          {
            key: 'back_office_duplicaten_relaties',
            naam: 'Relaties',
            icoon: ({ breedte, hoogte, kleur }) => {
              const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
              return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
            },
            navigeren: async (params) => {
              params.routeProps.history.push('/backoffice/duplicaten/relatie');
            },
          },
          {
            key: 'back_office_duplicaten_personen',
            naam: 'Personen',
            icoon: ({ breedte, hoogte, kleur }) => {
              const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
              return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
            },
            navigeren: async (params) => {
              params.routeProps.history.push('/backoffice/duplicaten/persoon');
            },
          },
        ],
      },
      {
        key: 'back_office_monitoring',
        naam: 'Monitoring',
        icoon: ({ breedte, hoogte, kleur }) => {
          const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
          return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
        },
        navigeren: async (params) => {
          params.routeProps.history.push('/');
        },
        onderliggendeItems: [
          {
            key: 'back_office_monitoring_winkelwagens',
            naam: 'Winkelwagens',
            icoon: ({ breedte, hoogte, kleur }) => {
              const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
              return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
            },
            navigeren: async (params) => {
              params.routeProps.history.push('/monitoring/winkelwagens');
            },
            onderliggendeItems: [],
          },
        ],
      },
    ],
  },
  {
    key: 'communicatie',
    naam: 'Communicatie',
    icoon: ({ breedte, hoogte, kleur }) => {
      const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
      return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
    },
    navigeren: async (params) => {
      params.routeProps.history.push('/communicatie');
    },
    onderliggendeItems: [
      {
        key: 'communicatie_whatsapp',
        naam: 'WhatsApp',
        icoon: ({ breedte, hoogte, kleur }) => {
          const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
          return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
        },
        navigeren: async (params) => {
          params.routeProps.history.push('/communicatie/whatsapp');
        },
      },
      {
        key: 'communicatie_email',
        naam: 'Email',
        icoon: ({ breedte, hoogte, kleur }) => {
          const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
          return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
        },
        navigeren: async (params) => {
          params.routeProps.history.push('/communicatie/email');
        },
      },
      {
        key: 'communicatie_telefoon',
        naam: 'Telefoon',
        icoon: ({ breedte, hoogte, kleur }) => {
          const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
          return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
        },
        navigeren: async (params) => {
          params.routeProps.history.push('/communicatie/telefoon');
        },
      },
      {
        key: 'communicatie_sms',
        naam: 'SMS',
        icoon: ({ breedte, hoogte, kleur }) => {
          const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
          return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
        },
        navigeren: async (params) => {
          params.routeProps.history.push('/communicatie/sms');
        },
      },
    ],
  },
  {
    key: 'transport',
    naam: 'Transport',
    icoon: ({ breedte, hoogte, kleur }) => {
      const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
      return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
    },
    navigeren: async (params) => {
      params.routeProps.history.push('/transport');
    },
    onderliggendeItems: [
      {
        key: 'transport_opdrachten',
        naam: 'Opdrachten',
        icoon: ({ breedte, hoogte, kleur }) => {
          const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
          return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
        },
        navigeren: async (params) => {
          params.routeProps.history.push('/transport/opdrachten');
        },
        onderliggendeItems: [
          {
            key: 'transport_opdrachten_reserveringen',
            naam: 'Reserveringen',
            icoon: ({ breedte, hoogte, kleur }) => {
              const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
              return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
            },
            navigeren: async (params) => {
              params.routeProps.history.push('/transport/opdrachten/reserveringen');
            },
          },
          {
            key: 'transport_opdrachten_planning',
            naam: 'Planning',
            icoon: ({ breedte, hoogte, kleur }) => {
              const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
              return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
            },
            navigeren: async (params) => {
              params.routeProps.history.push('/transport/opdrachten/planning');
            },
          },
          {
            key: 'transport_opdrachten_uitstaand',
            naam: 'Uitstaand',
            icoon: ({ breedte, hoogte, kleur }) => {
              const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
              return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
            },
            navigeren: async (params) => {
              params.routeProps.history.push('/transport/opdrachten/uitstaand');
            },
          },
          {
            key: 'transport_opdrachten_afgehandeld',
            naam: 'Afgehandeld',
            icoon: ({ breedte, hoogte, kleur }) => {
              const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
              return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
            },
            navigeren: async (params) => {
              params.routeProps.history.push('/transport/opdrachten/afgehandeld');
            },
          },
          {
            key: 'transport_opdrachten_referenties',
            naam: 'Referenties',
            icoon: ({ breedte, hoogte, kleur }) => {
              const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
              return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
            },
            navigeren: async (params) => {
              params.routeProps.history.push('/transport/opdrachten/referenties');
            },
          },
        ],
      },
      {
        key: 'transport_bezoektijden',
        naam: 'Bezoekdiensten',
        icoon: ({ breedte, hoogte, kleur }) => {
          const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
          return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
        },
        navigeren: async (params) => {
          params.routeProps.history.push('/transport/bezoektijden');
        },
      },
      {
        key: 'transport_diensten',
        naam: 'Diensten',
        icoon: ({ breedte, hoogte, kleur }) => {
          const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
          return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
        },
        navigeren: async (params) => {
          params.routeProps.history.push('/transport/diensten');
        },
      },
    ],
  },
  {
    key: 'voorraad',
    naam: 'Voorraad',
    icoon: ({ breedte, hoogte, kleur }) => {
      const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
      return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
    },
    navigeren: async (params) => {
      params.routeProps.history.push('/voorraad');
    },
    onderliggendeItems: [
      {
        key: 'voorraad_ingepakt',
        naam: 'Ingepakt',
        icoon: ({ breedte, hoogte, kleur }) => {
          const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
          return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
        },
        navigeren: async (params) => {
          params.routeProps.history.push('/voorraad/ingepakt');
        },
      },
      {
        key: 'voorraad_uitgepakt',
        naam: 'Uitgepakt',
        icoon: ({ breedte, hoogte, kleur }) => {
          const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
          return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
        },
        navigeren: async (params) => {
          params.routeProps.history.push('/voorraad/uitgepakt');
        },
      },
      {
        key: 'voorraad_mutaties',
        naam: 'Mutaties',
        icoon: ({ breedte, hoogte, kleur }) => {
          const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
          return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
        },
        navigeren: async (params) => {
          params.routeProps.history.push('/voorraad/mutaties');
        },
      },
    ],
  },
  {
    key: 'drive',
    naam: 'Drive',
    icoon: ({ breedte, hoogte, kleur }) => {
      const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
      return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
    },
    navigeren: async (params) => {
      params.routeProps.history.push('/drive');
    },
  },
  {
    key: 'inkoop',
    naam: 'Inkoop',
    icoon: ({ breedte, hoogte, kleur }) => {
      const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
      return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
    },
    navigeren: async (params) => {
      params.routeProps.history.push('/inkoop');
    },
    onderliggendeItems: [
      {
        key: 'inkoop_opdrachten',
        naam: 'Opdrachten',
        icoon: ({ breedte, hoogte, kleur }) => {
          const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
          return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
        },
        navigeren: async (params) => {
          params.routeProps.history.push('/inkoop/opdrachten');
        },
        onderliggendeItems: [
          {
            key: 'inkoop_opdrachten_nieuw',
            naam: 'Nieuw',
            icoon: ({ breedte, hoogte, kleur }) => {
              const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
              return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
            },
            navigeren: async (params) => {
              params.routeProps.history.push('/inkoop/opdrachten/nieuw');
            },
          },
          {
            key: 'inkoop_opdrachten_uitstaand',
            naam: 'Uitstaand',
            icoon: ({ breedte, hoogte, kleur }) => {
              const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
              return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
            },
            navigeren: async (params) => {
              params.routeProps.history.push('/inkoop/opdrachten/uitstaand');
            },
          },
          {
            key: 'inkoop_opdrachten_afgehandeld',
            naam: 'Afgehandeld',
            icoon: ({ breedte, hoogte, kleur }) => {
              const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
              return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
            },
            navigeren: async (params) => {
              params.routeProps.history.push('/inkoop/opdrachten/afgehandeld');
            },
          },
          {
            key: 'inkoop_opdrachten_inslag_mutaties',
            naam: 'Inslagmutaties',
            icoon: ({ breedte, hoogte, kleur }) => {
              const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
              return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
            },
            navigeren: async (params) => {
              params.routeProps.history.push('/inkoop/opdrachten/inslag-mutaties');
            },
          },
          {
            key: 'inkoop_opdrachten_reserveringen',
            naam: 'Reserveringen',
            icoon: ({ breedte, hoogte, kleur }) => {
              const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
              return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
            },
            navigeren: async (params) => {
              params.routeProps.history.push('/inkoop/opdrachten/reserveringen');
            },
          },
          {
            key: 'inkoop_opdrachten_parameters',
            naam: 'Parameters',
            icoon: ({ breedte, hoogte, kleur }) => {
              const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
              return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
            },
            navigeren: async (params) => {
              params.routeProps.history.push('/inkoop/opdrachten/parameters');
            },
          },
        ],
      },
      {
        key: 'inkoop_voorraad',
        naam: 'Voorraad',
        icoon: ({ breedte, hoogte, kleur }) => {
          const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
          return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
        },
        navigeren: async (params) => {
          params.routeProps.history.push('/inkoop/voorraad');
        },
        onderliggendeItems: [],
      },
      {
        key: 'inkoop_aanbod',
        naam: 'Aanbod',
        icoon: ({ breedte, hoogte, kleur }) => {
          const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
          return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
        },
        navigeren: async (params) => {
          params.routeProps.history.push('/inkoop/aanbod');
        },
        onderliggendeItems: [],
      },
      {
        key: 'inkoop_diensten',
        naam: 'Diensten',
        icoon: ({ breedte, hoogte, kleur }) => {
          const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
          return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
        },
        navigeren: async (params) => {
          params.routeProps.history.push('/inkoop/diensten');
        },
      },
    ],
  },
  {
    key: 'magazijn',
    naam: 'Magazijn',
    icoon: ({ breedte, hoogte, kleur }) => {
      const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
      return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
    },
    navigeren: async (params) => {
      params.routeProps.history.push('/magazijn');
    },
    onderliggendeItems: [
      {
        key: 'magazijn_beheer',
        naam: 'Beheer',
        icoon: ({ breedte, hoogte, kleur }) => {
          const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
          return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
        },
        navigeren: async (params) => {
          params.routeProps.history.push('/magazijn/beheer');
        },
      },
      {
        key: 'magazijn_pendelopdrachten',
        naam: 'Pendelopdrachten',
        icoon: ({ breedte, hoogte, kleur }) => {
          const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
          return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
        },
        navigeren: async (params) => {
          params.routeProps.history.push('/magazijn/pendelopdrachten');
        },
      },
      {
        key: 'magazijn_inventarisatie',
        naam: 'Inventarisatie',
        icoon: ({ breedte, hoogte, kleur }) => {
          const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
          return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
        },
        navigeren: async (params) => {
          params.routeProps.history.push('/magazijn/inventarisatie');
        },
      },
      {
        key: 'magazijn_mutaties',
        naam: 'Mutaties',
        icoon: ({ breedte, hoogte, kleur }) => {
          const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
          return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
        },
        navigeren: async (params) => {
          params.routeProps.history.push('/magazijn/mutaties');
        },
      },
      {
        key: 'magazijn_magazijndiensten',
        naam: 'Magazijndiensten',
        icoon: ({ breedte, hoogte, kleur }) => {
          const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
          return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
        },
        navigeren: async (params) => {
          params.routeProps.history.push('/magazijn/magazijndiensten');
        },
      },
      {
        key: 'magazijn_pendeldiensten',
        naam: 'Magazijndiensten',
        icoon: ({ breedte, hoogte, kleur }) => {
          const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
          return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
        },
        navigeren: async (params) => {
          params.routeProps.history.push('/magazijn/magazijndiensten');
        },
      },
    ],
  },
  {
    key: 'aanbod',
    naam: 'Aanbod',
    icoon: ({ breedte, hoogte, kleur }) => {
      const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
      return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
    },
    navigeren: async (params) => {
      params.routeProps.history.push('/aanbod');
    },
    onderliggendeItems: [
      {
        key: 'aanbod_tarieven',
        naam: 'Tarieven',
        icoon: ({ breedte, hoogte, kleur }) => {
          const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
          return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
        },
        navigeren: async (params) => {
          params.routeProps.history.push('/aanbod/tarieven');
        },
        onderliggendeItems: [
          {
            key: 'aanbod_tarieven_verhuur',
            naam: 'Verhuur',
            icoon: ({ breedte, hoogte, kleur }) => {
              const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
              return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
            },
            navigeren: async (params) => {
              params.routeProps.history.push('/aanbod/tarieven/verhuur');
            },
          },
          {
            key: 'aanbod_tarieven_acties',
            naam: 'Acties',
            icoon: ({ breedte, hoogte, kleur }) => {
              const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
              return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
            },
            navigeren: async (params) => {
              params.routeProps.history.push('/aanbod/tarieven/acties');
            },
          },
          {
            key: 'aanbod_tarieven_verkoop',
            naam: 'Verkoop',
            icoon: ({ breedte, hoogte, kleur }) => {
              const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
              return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
            },
            navigeren: async (params) => {
              params.routeProps.history.push('/aanbod/tarieven/verkoop');
            },
          },
          {
            key: 'aanbod_tarieven_overige',
            naam: 'Overige',
            icoon: ({ breedte, hoogte, kleur }) => {
              const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
              return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
            },
            navigeren: async (params) => {
              params.routeProps.history.push('/aanbod/tarieven/overige');
            },
          },
        ],
      },
      {
        key: 'aanbod_producten',
        naam: 'Producten',
        icoon: ({ breedte, hoogte, kleur }) => {
          const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
          return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
        },
        navigeren: async (params) => {
          params.routeProps.history.push('/aanbod/producten');
        },
        onderliggendeItems: [
          {
            key: 'aanbod_producten_productmodellen',
            naam: 'Productmodellen',
            icoon: ({ breedte, hoogte, kleur }) => {
              const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
              return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
            },
            navigeren: async (params) => {
              params.routeProps.history.push('/aanbod/producten/productmodellen');
            },
          },
          {
            key: 'aanbod_producten_abonnementen',
            naam: 'Abonnementen',
            icoon: ({ breedte, hoogte, kleur }) => {
              const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
              return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
            },
            navigeren: async (params) => {
              params.routeProps.history.push('/aanbod/producten/abonnementen');
            },
          },
          {
            key: 'aanbod_producten_productsoorten',
            naam: 'Productsoorten',
            icoon: ({ breedte, hoogte, kleur }) => {
              const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
              return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
            },
            navigeren: async (params) => {
              params.routeProps.history.push('/aanbod/producten/productsoorten');
            },
          },
          {
            key: 'aanbod_producten_premiums',
            naam: 'Premiums',
            icoon: ({ breedte, hoogte, kleur }) => {
              const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
              return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
            },
            navigeren: async (params) => {
              params.routeProps.history.push('/aanbod/producten/premiums');
            },
          },
          {
            key: 'aanbod_producten_alternatieven',
            naam: 'Alternatieven',
            icoon: ({ breedte, hoogte, kleur }) => {
              const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
              return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
            },
            navigeren: async (params) => {
              params.routeProps.history.push('/aanbod/producten/alternatieven');
            },
          },
        ],
      },
      {
        key: 'aanbod_specificaties',
        naam: 'Specificaties',
        icoon: ({ breedte, hoogte, kleur }) => {
          const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
          return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
        },
        navigeren: async (params) => {
          params.routeProps.history.push('/aanbod/specificaties');
        },
        onderliggendeItems: [
          {
            key: 'aanbod_specificaties_producten',
            naam: 'Specificaties',
            icoon: ({ breedte, hoogte, kleur }) => {
              const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
              return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
            },
            navigeren: async (params) => {
              params.routeProps.history.push('/aanbod/specificaties');
            },
          },
          {
            key: 'aanbod_specificaties_beheer',
            naam: 'Beheer',
            icoon: ({ breedte, hoogte, kleur }) => {
              const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
              return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
            },
            navigeren: async (params) => {
              params.routeProps.history.push('/aanbod/specificaties/beheer');
            },
          },
          {
            key: 'aanbod_specificaties_groepen',
            naam: 'Groepen',
            icoon: ({ breedte, hoogte, kleur }) => {
              const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
              return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
            },
            navigeren: async (params) => {
              params.routeProps.history.push('/aanbod/specificaties/groepen');
            },
          },
        ],
      },
    ],
  },
  {
    key: 'financieel',
    naam: 'Financieel',
    onderliggendeItems: [
      {
        key: 'financieel_facturering',
        naam: 'Facturering',
        icoon: ({ breedte, hoogte, kleur }) => {
          const Icon = functioneleIconMap[EFunctioneleIcon.Instellingen];
          return (
            <Icon
              style={{
                width: breedte,
                height: hoogte,
                fill: kleur,
              }}
            />
          );
        },
        onderliggendeItems: [
          {
            key: 'financieel_facturering_polongaties',
            naam: 'Prolongaties',
            icoon: ({ breedte, hoogte, kleur }) => {
              const Icon = functioneleIconMap[EFunctioneleIcon.Instellingen];
              return (
                <Icon
                  style={{
                    width: breedte,
                    height: hoogte,
                    fill: kleur,
                  }}
                />
              );
            },
            navigeren: async (params) => {
              params.routeProps.history.push('/facturering/prolongaties');
            },
          },
          {
            key: 'financieel_facturering_vorderingen',
            naam: 'Vorderingen',
            icoon: ({ breedte, hoogte, kleur }) => {
              const Icon = functioneleIconMap[EFunctioneleIcon.Instellingen];
              return (
                <Icon
                  style={{
                    width: breedte,
                    height: hoogte,
                    fill: kleur,
                  }}
                />
              );
            },
            navigeren: async (params) => {
              params.routeProps.history.push('/facturering/vorderingen');
            },
          },
          {
            key: 'financieel_facturering_facturen',
            naam: 'Vorderingen',
            icoon: ({ breedte, hoogte, kleur }) => {
              const Icon = functioneleIconMap[EFunctioneleIcon.Instellingen];
              return (
                <Icon
                  style={{
                    width: breedte,
                    height: hoogte,
                    fill: kleur,
                  }}
                />
              );
            },
            navigeren: async (params) => {
              params.routeProps.history.push('/facturering/facturen');
            },
          },
          {
            key: 'financieel_facturering_indexeringen',
            naam: 'Indexeringen',
            icoon: ({ breedte, hoogte, kleur }) => {
              const Icon = functioneleIconMap[EFunctioneleIcon.Instellingen];
              return (
                <Icon
                  style={{
                    width: breedte,
                    height: hoogte,
                    fill: kleur,
                  }}
                />
              );
            },
            navigeren: async (params) => {
              params.routeProps.history.push('/facturering/indexeringen');
            },
          },
        ],
      },
      {
        key: 'financieel_debiteuren',
        naam: 'Debiteuren',
        onderliggendeItems: [
          {
            key: 'financieel_debiteuren_beheer',
            naam: 'Beheer',
            icoon: ({ breedte, hoogte, kleur }) => (
              <EyeIcon
                style={{
                  width: breedte,
                  height: hoogte,
                  fill: kleur,
                }}
              />
            ),
            navigeren: async (params) => {
              params.routeProps.history.push('/debiteuren/beheer');
            },
          },
          {
            key: 'financieel_debiteuren_verrekeningen',
            naam: 'Verrekeningen',
            icoon: ({ breedte, hoogte, kleur }) => (
              <EyeIcon
                style={{
                  width: breedte,
                  height: hoogte,
                  fill: kleur,
                }}
              />
            ),
            navigeren: async (params) => {
              params.routeProps.history.push('/debiteuren/verrekeningen');
            },
          },
          {
            key: 'financieel_debiteuren_aanmaningen',
            naam: 'Aanmaningen',
            icoon: ({ breedte, hoogte, kleur }) => (
              <EyeIcon
                style={{
                  width: breedte,
                  height: hoogte,
                  fill: kleur,
                }}
              />
            ),
            navigeren: async (params) => {
              params.routeProps.history.push('/debiteuren/aanmaningen');
            },
          },
          {
            key: 'financieel_debiteuren_betalingsregelingen',
            naam: 'Betalingsregelingen',
            icoon: ({ breedte, hoogte, kleur }) => (
              <EyeIcon
                style={{
                  width: breedte,
                  height: hoogte,
                  fill: kleur,
                }}
              />
            ),
            navigeren: async (params) => {
              params.routeProps.history.push('/debiteuren/betalingsregelingen');
            },
          },
          {
            key: 'financieel_debiteuren_incassozaken',
            naam: 'Incassozaken',
            icoon: ({ breedte, hoogte, kleur }) => (
              <EyeIcon
                style={{
                  width: breedte,
                  height: hoogte,
                  fill: kleur,
                }}
              />
            ),
            navigeren: async (params) => {
              params.routeProps.history.push('/debiteuren/incassozaken');
            },
          },
          {
            key: 'financieel_debiteuren_herincassozaken',
            naam: 'Herincassozaken',
            icoon: ({ breedte, hoogte, kleur }) => (
              <EyeIcon
                style={{
                  width: breedte,
                  height: hoogte,
                  fill: kleur,
                }}
              />
            ),
            navigeren: async (params) => {
              params.routeProps.history.push('/debiteuren/herincassozaken');
            },
          },
          {
            key: 'financieel_debiteuren_rechtzaken',
            naam: 'Rechtzaken',
            icoon: ({ breedte, hoogte, kleur }) => (
              <EyeIcon
                style={{
                  width: breedte,
                  height: hoogte,
                  fill: kleur,
                }}
              />
            ),
            navigeren: async (params) => {
              params.routeProps.history.push('/debiteuren/rechtzaken');
            },
          },
          {
            key: 'financieel_debiteuren_overzicht',
            naam: 'Overzicht',
            icoon: ({ breedte, hoogte, kleur }) => (
              <EyeIcon
                style={{
                  width: breedte,
                  height: hoogte,
                  fill: kleur,
                }}
              />
            ),
            navigeren: async (params) => {
              params.routeProps.history.push('/debiteuren/overzicht');
            },
          },
          {
            key: 'financieel_debiteuren_incassodiensten',
            naam: 'Incassodiensten',
            icoon: ({ breedte, hoogte, kleur }) => (
              <EyeIcon
                style={{
                  width: breedte,
                  height: hoogte,
                  fill: kleur,
                }}
              />
            ),
            navigeren: async (params) => {
              params.routeProps.history.push('/debiteuren/diensten');
            },
          },
        ],
      },
      {
        key: 'financieel_crediteuren',
        naam: 'Crediteuren',
        onderliggendeItems: [
          {
            key: 'financieel_crediteuren_inkoopfacturen',
            naam: 'Inkoopfacturen',
            icoon: ({ breedte, hoogte, kleur }) => (
              <EyeIcon
                style={{
                  width: breedte,
                  height: hoogte,
                  fill: kleur,
                }}
              />
            ),
            navigeren: async (params) => {
              params.routeProps.history.push('/crediteuren/inkoopfacturen');
            },
            onderliggendeItems: [
              {
                key: 'financieel_crediteuren_inkoopfacturen_inboeken',
                naam: 'Inboeken',
                icoon: ({ breedte, hoogte, kleur }) => (
                  <EyeIcon
                    style={{
                      width: breedte,
                      height: hoogte,
                      fill: kleur,
                    }}
                  />
                ),
                navigeren: async (params) => {
                  params.routeProps.history.push('/crediteuren/inkoopfacturen/inboeken');
                },
              },
              {
                key: 'financieel_crediteuren_inkoopfacturen_betalen',
                naam: 'Betalen',
                icoon: ({ breedte, hoogte, kleur }) => (
                  <EyeIcon
                    style={{
                      width: breedte,
                      height: hoogte,
                      fill: kleur,
                    }}
                  />
                ),
                navigeren: async (params) => {
                  params.routeProps.history.push('/crediteuren/inkoopfacturen/betalen');
                },
              },
              {
                key: 'financieel_crediteuren_inkoopfacturen_overzicht',
                naam: 'Overzicht',
                icoon: ({ breedte, hoogte, kleur }) => (
                  <EyeIcon
                    style={{
                      width: breedte,
                      height: hoogte,
                      fill: kleur,
                    }}
                  />
                ),
                navigeren: async (params) => {
                  params.routeProps.history.push('/crediteuren/inkoopfacturen/overzicht');
                },
              },
            ],
          },
          {
            key: 'financieel_crediteuren_koppelen',
            naam: 'Koppelen',
            icoon: ({ breedte, hoogte, kleur }) => (
              <EyeIcon
                style={{
                  width: breedte,
                  height: hoogte,
                  fill: kleur,
                }}
              />
            ),
            navigeren: async (params) => {
              params.routeProps.history.push('/crediteuren/koppelen');
            },
            onderliggendeItems: [
              {
                key: 'financieel_crediteuren_koppelen_producten',
                naam: 'Producten',
                icoon: ({ breedte, hoogte, kleur }) => (
                  <EyeIcon
                    style={{
                      width: breedte,
                      height: hoogte,
                      fill: kleur,
                    }}
                  />
                ),
                navigeren: async (params) => {
                  params.routeProps.history.push('/crediteuren/koppelen/producten');
                },
              },
              {
                key: 'financieel_crediteuren_koppelen_werkbonnen',
                naam: 'Werkbonnen',
                icoon: ({ breedte, hoogte, kleur }) => (
                  <EyeIcon
                    style={{
                      width: breedte,
                      height: hoogte,
                      fill: kleur,
                    }}
                  />
                ),
                navigeren: async (params) => {
                  params.routeProps.history.push('/crediteuren/koppelen/werkbonnen');
                },
              },
              {
                key: 'financieel_crediteuren_koppelen_sponsoring',
                naam: 'Sponsoring',
                icoon: ({ breedte, hoogte, kleur }) => (
                  <EyeIcon
                    style={{
                      width: breedte,
                      height: hoogte,
                      fill: kleur,
                    }}
                  />
                ),
                navigeren: async (params) => {
                  params.routeProps.history.push('/crediteuren/koppelen/sponsoring');
                },
                onderliggendeItems: [
                  {
                    key: 'financieel_crediteuren_koppelen_sponsoring_facturen',
                    naam: 'Facturen',
                    icoon: ({ breedte, hoogte, kleur }) => (
                      <EyeIcon
                        style={{
                          width: breedte,
                          height: hoogte,
                          fill: kleur,
                        }}
                      />
                    ),
                    navigeren: async (params) => {
                      params.routeProps.history.push('/crediteuren/koppelen/sponsoring/facturen');
                    },
                  },
                  {
                    key: 'financieel_crediteuren_koppelen_sponsoring_contracten',
                    naam: 'Contracten',
                    icoon: ({ breedte, hoogte, kleur }) => (
                      <EyeIcon
                        style={{
                          width: breedte,
                          height: hoogte,
                          fill: kleur,
                        }}
                      />
                    ),
                    navigeren: async (params) => {
                      params.routeProps.history.push('/crediteuren/koppelen/sponsoring/contracten');
                    },
                  },
                ],
              },
              {
                key: 'financieel_crediteuren_koppelen_transport',
                naam: 'Transport',
                icoon: ({ breedte, hoogte, kleur }) => (
                  <EyeIcon
                    style={{
                      width: breedte,
                      height: hoogte,
                      fill: kleur,
                    }}
                  />
                ),
                navigeren: async (params) => {
                  params.routeProps.history.push('/crediteuren/koppelen/transport');
                },
              },
            ],
          },
          {
            key: 'financieel_crediteuren_overzicht',
            naam: 'Koppelen',
            icoon: ({ breedte, hoogte, kleur }) => (
              <EyeIcon
                style={{
                  width: breedte,
                  height: hoogte,
                  fill: kleur,
                }}
              />
            ),
            navigeren: async (params) => {
              params.routeProps.history.push('/crediteuren/overzicht');
            },
            onderliggendeItems: [],
          },
        ],
      },
      {
        key: 'financieel_bankzaken',
        naam: 'Bankzaken',
        icoon: ({ breedte, hoogte, kleur }) => (
          <EyeIcon
            style={{
              width: breedte,
              height: hoogte,
              fill: kleur,
            }}
          />
        ),
        navigeren: async (params) => {
          params.routeProps.history.push('/bankzaken');
        },
        onderliggendeItems: [
          {
            key: 'financieel_bankzaken_bankopdrachten',
            naam: 'Bankopdrachten',
            icoon: ({ breedte, hoogte, kleur }) => (
              <EyeIcon
                style={{
                  width: breedte,
                  height: hoogte,
                  fill: kleur,
                }}
              />
            ),
            navigeren: async (params) => {
              params.routeProps.history.push('/bankzaken/bankopdrachten');
            },
            onderliggendeItems: [
              {
                key: 'financieel_bankzaken_bankopdrachten_opdrachten',
                naam: 'Bankopdrachten',
                icoon: ({ breedte, hoogte, kleur }) => (
                  <EyeIcon
                    style={{
                      width: breedte,
                      height: hoogte,
                      fill: kleur,
                    }}
                  />
                ),
                navigeren: async (params) => {
                  params.routeProps.history.push('/bankzaken/bankopdrachten/opdrachten');
                },
              },
              {
                key: 'financieel_bankzaken_bankopdrachten_batches',
                naam: 'Batches',
                icoon: ({ breedte, hoogte, kleur }) => (
                  <EyeIcon
                    style={{
                      width: breedte,
                      height: hoogte,
                      fill: kleur,
                    }}
                  />
                ),
                navigeren: async (params) => {
                  params.routeProps.history.push('/bankzaken/bankopdrachten/batches');
                },
              },
              {
                key: 'financieel_bankzaken_bankopdrachten_stornering',
                naam: 'Storneringen',
                icoon: ({ breedte, hoogte, kleur }) => (
                  <EyeIcon
                    style={{
                      width: breedte,
                      height: hoogte,
                      fill: kleur,
                    }}
                  />
                ),
                navigeren: async (params) => {
                  params.routeProps.history.push('/bankzaken/bankopdrachten/stornering');
                },
              },
              {
                key: 'financieel_bankzaken_bankopdrachten_specificaties',
                naam: 'Specificaties',
                icoon: ({ breedte, hoogte, kleur }) => (
                  <EyeIcon
                    style={{
                      width: breedte,
                      height: hoogte,
                      fill: kleur,
                    }}
                  />
                ),
                navigeren: async (params) => {
                  params.routeProps.history.push('/bankzaken/bankopdrachten/specificaties');
                },
              },
              {
                key: 'financieel_bankzaken_bankopdrachten_stornocodes',
                naam: 'Stornoredenen',
                icoon: ({ breedte, hoogte, kleur }) => (
                  <EyeIcon
                    style={{
                      width: breedte,
                      height: hoogte,
                      fill: kleur,
                    }}
                  />
                ),
                navigeren: async (params) => {
                  params.routeProps.history.push('/bankzaken/bankopdrachten/stornocodes');
                },
              },
            ],
          },
          {
            key: 'financieel_bankzaken_bankmutaties',
            naam: 'Bankmutaties',
            icoon: ({ breedte, hoogte, kleur }) => (
              <EyeIcon
                style={{
                  width: breedte,
                  height: hoogte,
                  fill: kleur,
                }}
              />
            ),
            navigeren: async (params) => {
              params.routeProps.history.push('/bankzaken/bankmutaties');
            },
            onderliggendeItems: [
              {
                key: 'financieel_bankzaken_bankmutaties_concept',
                naam: 'Concept',
                icoon: ({ breedte, hoogte, kleur }) => (
                  <EyeIcon
                    style={{
                      width: breedte,
                      height: hoogte,
                      fill: kleur,
                    }}
                  />
                ),
                navigeren: async (params) => {
                  params.routeProps.history.push('/bankzaken/bankmutaties/concept');
                },
              },
              {
                key: 'financieel_bankzaken_bankmutaties_definitief',
                naam: 'Definitief',
                icoon: ({ breedte, hoogte, kleur }) => (
                  <EyeIcon
                    style={{
                      width: breedte,
                      height: hoogte,
                      fill: kleur,
                    }}
                  />
                ),
                navigeren: async (params) => {
                  params.routeProps.history.push('/bankzaken/bankmutaties/definitief');
                },
              },
            ],
          },
        ],
      },
      {
        key: 'financieel_boekhouding',
        naam: 'Boekhouding',
        icoon: ({ breedte, hoogte, kleur }) => (
          <EyeIcon
            style={{
              width: breedte,
              height: hoogte,
              fill: kleur,
            }}
          />
        ),
        navigeren: async (params) => {
          params.routeProps.history.push('/boekhouding');
        },
        onderliggendeItems: [
          {
            key: 'financieel_boekhouding_boekingen',
            naam: 'Boekingen',
            icoon: ({ breedte, hoogte, kleur }) => (
              <EyeIcon
                style={{
                  width: breedte,
                  height: hoogte,
                  fill: kleur,
                }}
              />
            ),
            navigeren: async (params) => {
              params.routeProps.history.push('/boekhouding/boekingen');
            },
            onderliggendeItems: [
              {
                key: 'financieel_boekhouding_boekingen_inkoop',
                naam: 'Inkoop',
                icoon: ({ breedte, hoogte, kleur }) => (
                  <EyeIcon
                    style={{
                      width: breedte,
                      height: hoogte,
                      fill: kleur,
                    }}
                  />
                ),
                navigeren: async (params) => {
                  params.routeProps.history.push('/boekhouding/boekingen/inkoop');
                },
                onderliggendeItems: [],
              },
              {
                key: 'financieel_boekhouding_boekingen_verkoop',
                naam: 'Verkoop',
                icoon: ({ breedte, hoogte, kleur }) => (
                  <EyeIcon
                    style={{
                      width: breedte,
                      height: hoogte,
                      fill: kleur,
                    }}
                  />
                ),
                navigeren: async (params) => {
                  params.routeProps.history.push('/boekhouding/boekingen/verkoop');
                },
                onderliggendeItems: [],
              },
              {
                key: 'financieel_boekhouding_boekingen_memoriaal',
                naam: 'Memoriaal',
                icoon: ({ breedte, hoogte, kleur }) => (
                  <EyeIcon
                    style={{
                      width: breedte,
                      height: hoogte,
                      fill: kleur,
                    }}
                  />
                ),
                navigeren: async (params) => {
                  params.routeProps.history.push('/boekhouding/boekingen/memoriaal');
                },
                onderliggendeItems: [],
              },
              {
                key: 'financieel_boekhouding_boekingen_bank',
                naam: 'Bank',
                icoon: ({ breedte, hoogte, kleur }) => (
                  <EyeIcon
                    style={{
                      width: breedte,
                      height: hoogte,
                      fill: kleur,
                    }}
                  />
                ),
                navigeren: async (params) => {
                  params.routeProps.history.push('/boekhouding/boekingen/bank');
                },
                onderliggendeItems: [],
              },
              {
                key: 'financieel_boekhouding_boekingen_export',
                naam: 'Export',
                icoon: ({ breedte, hoogte, kleur }) => (
                  <EyeIcon
                    style={{
                      width: breedte,
                      height: hoogte,
                      fill: kleur,
                    }}
                  />
                ),
                navigeren: async (params) => {
                  params.routeProps.history.push('/boekhouding/boekingen/export');
                },
                onderliggendeItems: [],
              },
            ],
          },
          {
            key: 'financieel_boekhouding_activaregister',
            naam: 'Activaregister',
            icoon: ({ breedte, hoogte, kleur }) => (
              <EyeIcon
                style={{
                  width: breedte,
                  height: hoogte,
                  fill: kleur,
                }}
              />
            ),
            navigeren: async (params) => {
              params.routeProps.history.push('/boekhouding/activaregister');
            },
            onderliggendeItems: [
              {
                key: 'financieel_boekhouding_activaregister_activeren',
                naam: 'Activeren',
                icoon: ({ breedte, hoogte, kleur }) => (
                  <EyeIcon
                    style={{
                      width: breedte,
                      height: hoogte,
                      fill: kleur,
                    }}
                  />
                ),
                navigeren: async (params) => {
                  params.routeProps.history.push('/boekhouding/activaregister/activeren');
                },
                onderliggendeItems: [],
              },
              {
                key: 'financieel_boekhouding_activaregister_deactiveren',
                naam: 'Deactiveren',
                icoon: ({ breedte, hoogte, kleur }) => (
                  <EyeIcon
                    style={{
                      width: breedte,
                      height: hoogte,
                      fill: kleur,
                    }}
                  />
                ),
                navigeren: async (params) => {
                  params.routeProps.history.push('/boekhouding/activaregister/deactiveren');
                },
                onderliggendeItems: [],
              },
              {
                key: 'financieel_boekhouding_activaregister_afschrijvingen',
                naam: 'Afschrijvingen',
                icoon: ({ breedte, hoogte, kleur }) => (
                  <EyeIcon
                    style={{
                      width: breedte,
                      height: hoogte,
                      fill: kleur,
                    }}
                  />
                ),
                navigeren: async (params) => {
                  params.routeProps.history.push('/boekhouding/activaregister/afschrijvingen');
                },
                onderliggendeItems: [],
              },
              {
                key: 'financieel_boekhouding_activaregister_register',
                naam: 'Register',
                icoon: ({ breedte, hoogte, kleur }) => (
                  <EyeIcon
                    style={{
                      width: breedte,
                      height: hoogte,
                      fill: kleur,
                    }}
                  />
                ),
                navigeren: async (params) => {
                  params.routeProps.history.push('/boekhouding/activaregister/register');
                },
                onderliggendeItems: [],
              },
            ],
          },
          {
            key: 'financieel_boekhouding_controle',
            naam: 'Controle',
            icoon: ({ breedte, hoogte, kleur }) => (
              <EyeIcon
                style={{
                  width: breedte,
                  height: hoogte,
                  fill: kleur,
                }}
              />
            ),
            navigeren: async (params) => {
              params.routeProps.history.push('/boekhouding/controle');
            },
            onderliggendeItems: [
              {
                key: 'financieel_boekhouding_controle_banksaldi',
                naam: 'Banksaldi',
                icoon: ({ breedte, hoogte, kleur }) => (
                  <EyeIcon
                    style={{
                      width: breedte,
                      height: hoogte,
                      fill: kleur,
                    }}
                  />
                ),
                navigeren: async (params) => {
                  params.routeProps.history.push('/boekhouding/controle/banksaldi');
                },
                onderliggendeItems: [],
              },
            ],
          },
          {
            key: 'financieel_boekhouding_beheer',
            naam: 'Beheer',
            icoon: ({ breedte, hoogte, kleur }) => (
              <EyeIcon
                style={{
                  width: breedte,
                  height: hoogte,
                  fill: kleur,
                }}
              />
            ),
            navigeren: async (params) => {
              params.routeProps.history.push('/boekhouding/beheer');
            },
            onderliggendeItems: [
              {
                key: 'financieel_boekhouding_beheer_grootboekrekeningen',
                naam: 'Grootboekrekeningen',
                icoon: ({ breedte, hoogte, kleur }) => (
                  <EyeIcon
                    style={{
                      width: breedte,
                      height: hoogte,
                      fill: kleur,
                    }}
                  />
                ),
                navigeren: async (params) => {
                  params.routeProps.history.push('/boekhouding/beheer/grootboekrekeningen');
                },
                onderliggendeItems: [],
              },
              {
                key: 'financieel_boekhouding_beheer_dagboeken',
                naam: 'Dagboeken',
                icoon: ({ breedte, hoogte, kleur }) => (
                  <EyeIcon
                    style={{
                      width: breedte,
                      height: hoogte,
                      fill: kleur,
                    }}
                  />
                ),
                navigeren: async (params) => {
                  params.routeProps.history.push('/boekhouding/beheer/dagboeken');
                },
                onderliggendeItems: [],
              },
              {
                key: 'financieel_boekhouding_beheer_btwsoorten',
                naam: 'BTW-soorten',
                icoon: ({ breedte, hoogte, kleur }) => (
                  <EyeIcon
                    style={{
                      width: breedte,
                      height: hoogte,
                      fill: kleur,
                    }}
                  />
                ),
                navigeren: async (params) => {
                  params.routeProps.history.push('/boekhouding/beheer/btwsoorten');
                },
                onderliggendeItems: [],
              },
              {
                key: 'financieel_boekhouding_beheer_btwtarieven',
                naam: 'BTW-tarieven',
                icoon: ({ breedte, hoogte, kleur }) => (
                  <EyeIcon
                    style={{
                      width: breedte,
                      height: hoogte,
                      fill: kleur,
                    }}
                  />
                ),
                navigeren: async (params) => {
                  params.routeProps.history.push('/boekhouding/beheer/btwtarieven');
                },
                onderliggendeItems: [],
              },
            ],
          },
          {
            key: 'financieel_boekhouding_rapportage',
            naam: 'Rapportage',
            icoon: ({ breedte, hoogte, kleur }) => (
              <EyeIcon
                style={{
                  width: breedte,
                  height: hoogte,
                  fill: kleur,
                }}
              />
            ),
            navigeren: async (params) => {
              params.routeProps.history.push('/boekhouding/rapportage');
            },
            onderliggendeItems: [],
          },
        ],
      },
    ],
  },
  {
    key: 'marketing',
    naam: 'Marketing',
    icoon: ({ breedte, hoogte, kleur }) => {
      const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
      return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
    },
    navigeren: async (params) => {
      params.routeProps.history.push('/marketing');
    },
    onderliggendeItems: [
      {
        key: 'marketing_sponsoring',
        naam: 'Marketing',
        icoon: ({ breedte, hoogte, kleur }) => {
          const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
          return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
        },
        navigeren: async (params) => {
          params.routeProps.history.push('/sponsoring/voorstellen');
        },
        onderliggendeItems: [
          {
            key: 'marketing_concurrentie',
            naam: 'concurrentie',
            icoon: ({ breedte, hoogte, kleur }) => {
              const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
              return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
            },
            navigeren: async (params) => {
              params.routeProps.history.push('/concurrentie');
            },
          },
          {
            key: 'marketing_sponsoring_voorstellen',
            naam: 'Voorstellen',
            icoon: ({ breedte, hoogte, kleur }) => {
              const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
              return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
            },
            navigeren: async (params) => {
              params.routeProps.history.push('/sponsoring/voorstellen');
            },
          },
          {
            key: 'marketing_sponsoring_contracten',
            naam: 'Contracten',
            icoon: ({ breedte, hoogte, kleur }) => {
              const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
              return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
            },
            navigeren: async (params) => {
              params.routeProps.history.push('/sponsoring/contracten');
            },
          },
          {
            key: 'marketing_sponsoring_materiaal',
            naam: 'Materiaal',
            icoon: ({ breedte, hoogte, kleur }) => {
              const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
              return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
            },
            navigeren: async (params) => {
              params.routeProps.history.push('/sponsoring/materiaal');
            },
          },
          {
            key: 'marketing_sponsoring_diensten',
            naam: 'Diensten',
            icoon: ({ breedte, hoogte, kleur }) => {
              const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
              return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
            },
            navigeren: async (params) => {
              params.routeProps.history.push('/sponsoring/diensten');
            },
          },
        ],
      },
      {
        key: 'marketing_bedrijfsinformatie',
        naam: 'Bedrijfsinformatie',
        icoon: ({ breedte, hoogte, kleur }) => {
          const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
          return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
        },
        navigeren: async (params) => {
          params.routeProps.history.push('/bedrijfsinfo/dashboard');
        },
      },
    ],
  },
  {
    key: 'kwaliteitsbeheer',
    naam: 'Kwaliteitsbeheer',
    icoon: ({ breedte, hoogte, kleur }) => {
      const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
      return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
    },
    navigeren: async (params) => {
      params.routeProps.history.push('/kwaliteitsbeheer');
    },
    onderliggendeItems: [
      {
        key: 'kwaliteitsbeheer_productadvies',
        naam: 'Productadvies',
        icoon: ({ breedte, hoogte, kleur }) => {
          const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
          return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
        },
        navigeren: async (params) => {
          params.routeProps.history.push('/kwaliteitsbeheer/productadvies');
        },
        onderliggendeItems: [
          {
            key: 'kwaliteitsbeheer_productadvies_productstaat',
            naam: 'Productstaat',
            icoon: ({ breedte, hoogte, kleur }) => {
              const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
              return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
            },
            navigeren: async (params) => {
              params.routeProps.history.push('/kwaliteitsbeheer/productadvies/staat');
            },
          },
        ],
      },
    ],
  },
  {
    key: 'facilitair',
    naam: 'Facilitair',
    icoon: ({ breedte, hoogte, kleur }) => {
      const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
      return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
    },
    navigeren: async (params) => {
      params.routeProps.history.push('/facilitair');
    },
    onderliggendeItems: [
      {
        key: 'facilitair_sjablonen',
        naam: 'Sjablonen',
        icoon: ({ breedte, hoogte, kleur }) => {
          const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
          return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
        },
        navigeren: async (params) => {
          params.routeProps.history.push('/facilitair/sjablonen');
        },
        onderliggendeItems: [],
      },
      {
        key: 'facilitair_documentatie',
        naam: 'Documentatie',
        icoon: ({ breedte, hoogte, kleur }) => {
          const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
          return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
        },
        navigeren: async (params) => {
          params.routeProps.history.push('/facilitair/documentatie');
        },
        onderliggendeItems: [],
      },
      {
        key: 'website_webteksten',
        naam: 'Webteksten',
        icoon: ({ breedte, hoogte, kleur }) => {
          const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
          return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
        },
        navigeren: async (params) => {
          params.routeProps.history.push('/website/webteksten');
        },
        onderliggendeItems: [],
      },
      {
        key: 'website_afbeeldingen',
        naam: 'Website afbeeldingen',
        icoon: ({ breedte, hoogte, kleur }) => {
          const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
          return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
        },
        navigeren: async (params) => {
          params.routeProps.history.push('/website/afbeeldingen');
        },
        onderliggendeItems: [],
      },
      {
        key: 'facilitair_import_export',
        naam: 'Import/export',
        icoon: ({ breedte, hoogte, kleur }) => {
          const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
          return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
        },
        navigeren: async (params) => {
          params.routeProps.history.push('/facilitair/import-export');
        },
        onderliggendeItems: [],
      },
    ],
  },
  {
    key: 'administratie',
    naam: 'Administratie',
    icoon: ({ breedte, hoogte, kleur }) => {
      const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
      return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
    },
    navigeren: async (params) => {
      params.routeProps.history.push('/administratie');
    },
    onderliggendeItems: [
      {
        key: 'administratie_verlof',
        naam: 'Verlof',
        icoon: ({ breedte, hoogte, kleur }) => {
          const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
          return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
        },
        navigeren: async (params) => {
          params.routeProps.history.push('/administratie/verlof');
        },
        onderliggendeItems: [
          {
            key: 'administratie_verlof_overzicht',
            naam: 'Overzicht',
            icoon: ({ breedte, hoogte, kleur }) => {
              const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
              return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
            },
            navigeren: async (params) => {
              params.routeProps.history.push('/administratie/verlof/overzicht');
            },
          },
          {
            key: 'administratie_verlof_registratie',
            naam: 'Registratie',
            icoon: ({ breedte, hoogte, kleur }) => {
              const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
              return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
            },
            navigeren: async (params) => {
              params.routeProps.history.push('/administratie/verlof/registratie');
            },
          },
        ],
      },
      {
        key: 'administratie_medewerkers',
        naam: 'Medewerkers',
        icoon: ({ breedte, hoogte, kleur }) => {
          const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
          return <IconDashboard style={{ width: breedte, height: hoogte, fill: kleur }} />;
        },
        navigeren: async (params) => {
          params.routeProps.history.push('/administratie/medewerkers');
        },
      },
    ],
  },
  {
    key: 'beheer',
    naam: 'Beheer',
    onderliggendeItems: [
      {
        key: 'beheer_instellingen',
        naam: 'Instellingen',
        icoon: ({ breedte, hoogte, kleur }) => {
          const Icon = functioneleIconMap[EFunctioneleIcon.Instellingen];
          return (
            <Icon
              style={{
                width: breedte,
                height: hoogte,
                fill: kleur,
              }}
            />
          );
        },
        onderliggendeItems: [
          {
            key: 'beheer_instellingen_applicatie',
            naam: 'Applicatie',
            icoon: ({ breedte, hoogte, kleur }) => {
              const Icon = functioneleIconMap[EFunctioneleIcon.Instellingen];
              return (
                <Icon
                  style={{
                    width: breedte,
                    height: hoogte,
                    fill: kleur,
                  }}
                />
              );
            },
            navigeren: async (params) => {
              params.routeProps.history.push('/beheer/instellingen/applicatie');
            },
          },
          {
            key: 'beheer_instellingen_website',
            naam: 'Website',
            icoon: ({ breedte, hoogte, kleur }) => {
              const Icon = functioneleIconMap[EFunctioneleIcon.Instellingen];
              return (
                <Icon
                  style={{
                    width: breedte,
                    height: hoogte,
                    fill: kleur,
                  }}
                />
              );
            },
            navigeren: async (params) => {
              params.routeProps.history.push('/beheer/instellingen/website');
            },
          },
        ],
      },
      {
        key: 'beheer_technisch',
        naam: 'Technisch',
        onderliggendeItems: [
          {
            key: 'beheer_technisch_logs',
            naam: 'Logs',
            icoon: ({ breedte, hoogte, kleur }) => (
              <EyeIcon
                style={{
                  width: breedte,
                  height: hoogte,
                  fill: kleur,
                }}
              />
            ),
            navigeren: async (params) => {
              params.routeProps.history.push('/beheer/technisch/logs');
            },
          },
        ],
      },
    ],
  },
];

export const parentNavigatieItemBijKey = navigatieItems.reduce((acc, curr) => {
  const fn = (
    acc: Partial<Record<NavigatieItemKey, INavigatieItem>>,
    navigatieItem: INavigatieItem,
    parent: INavigatieItem | null,
  ): Partial<Record<NavigatieItemKey, INavigatieItem>> => {
    if (navigatieItem.onderliggendeItems === undefined) {
      if (parent === null) {
        return acc;
      }

      return {
        ...acc,
        [navigatieItem.key]: parent,
      };
    }

    if (parent !== null) {
      acc = {
        ...acc,
        [navigatieItem.key]: parent,
      };
    }

    return navigatieItem.onderliggendeItems.reduce((a, c) => fn(a, c, navigatieItem), acc);
  };

  return fn(acc, curr, null);
}, {}) as Record<NavigatieItemKey, INavigatieItem>;

export const alleNavigatieItemsEnkelNiveau = navigatieItems.reduce((acc, curr) => {
  const fn = (
    acc: Partial<Record<NavigatieItemKey, INavigatieItem>>,
    curr: INavigatieItem,
  ): Partial<Record<NavigatieItemKey, INavigatieItem>> => {
    if (curr.onderliggendeItems === undefined) {
      return acc;
    }

    for (const onderliggendItem of curr.onderliggendeItems) {
      acc[onderliggendItem.key] = onderliggendItem;
      acc = fn(acc, onderliggendItem);
    }

    return acc;
  };

  return {
    ...acc,
    [curr.key]: curr,
    ...fn(acc, curr),
  };
}, {}) as Record<NavigatieItemKey, INavigatieItem>;

const bepaalKlantRelIDNavigeren = async (params: INavigerenParams): Promise<number> => {
  if (
    params.rootStore.klantkaartStore.relatie !== null &&
    params.rootStore.klantkaartStore.relatie.RelID !== -1
  ) {
    return params.rootStore.klantkaartStore.relatie!.RelID;
  }

  const lijstRecent = await api.v2.relatieZoeken.ophalenLijstRecent({
    aspGebrID: params.rootStore.gebruikerStore.gebruiker!.AspGebrID,
    filterSchema: {
      filters: [
        {
          naam: 'HOEDANIGHEID_NAAM_ENUMS',
          data: ['KLANT'],
        },
      ],
    },
    paginatie: {
      index: 0,
      aantal: 1,
    },
  });

  if (lijstRecent.relaties.length === 0) {
    return 1;
  }

  return lijstRecent.relaties[0].RelID;
};
