import api from '../../index';

import service from './service';

const kwaliteitsbeheer = {
  // productadvies,
  service,
};

export default kwaliteitsbeheer;
