import React, { useState, useEffect, useCallback, useMemo, useContext } from 'react';
import api from '../../../../api';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import { RouteComponentProps, withRouter } from 'react-router';
import {
  TypedColumn,
  TypedTableColumnWidthInfo,
  GridStyleWrapper,
  DXTableToggleCellComponent,
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
  DXTableCheckboxComponent,
} from '../../../../helpers/dxTableGrid';
import {
  Grid,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  TableSelection,
  VirtualTable,
  TableRowDetail,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  DataTypeProvider,
  EditingState,
  SelectionState,
  RowDetailState,
  VirtualTable as VirtualTableBase,
  SortingState,
  IntegratedSorting,
} from '@devexpress/dx-react-grid';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import useUrlState from '../../../../core/useUrlState';
import { IconToevoegen, IconVerwijderen } from '../../../../components/Icons';
import { formatteerBedrag } from '../../../../helpers';
import FormatteerBedrag from '../../../../components/MutatieBedrag';
import { RootStoreContext } from '../../../../stores/RootStore';
import { EResultType } from '../../../../stores/CheckStore';
import { IOphalenOverigeTarievenResultElement } from '../../../../../../shared/src/api/v2/aanbod/tarieven/overige';
import { Helmet } from 'react-helmet';
import WijzigenDialoog from './WijzigenDialoog';
import nameOf from '../../../../core/nameOf';
import FilterBalkV2, {
  IFilter,
  IFilterData,
  maakFilterSchema,
} from '../../../../components/FilterBalkV2';
import Combobox from '../../../../components/formulier/Combobox';
import { IFilterSchema, IFilterSchemaFilter } from '../../../../../../shared/src/models/filter';
import MenuLayout from '../../../../components/MenuLayout';
import UitlegTooltip from '../../../../components/formulier/UitlegTooltip';
import ToevoegenDialoog from './ToevoegenDialoog';

interface IProps extends RouteComponentProps {}

interface IWijzigenDialoogState {
  id: number;
}
interface IToevoegenDialoogState {}

enum EFilter {
  Bereik = 'BEREIK',
}

interface IUrlState {
  selectie: number[];
  uitgeklapt: number[];
  wijzigenDialoogState: IWijzigenDialoogState | null;
  toevoegenDialoogState: IToevoegenDialoogState | null;

  filterData: IFilterData<EFilter>[];
}

const defaultUrlState: IUrlState = {
  selectie: [],
  uitgeklapt: [],
  wijzigenDialoogState: null,
  toevoegenDialoogState: null,
  filterData: [
    {
      naam: EFilter.Bereik,
      data: 1,
      isActief: false,
    },
  ],
};

export interface IOverigeTarievenContext {
  onVerversenAangevraagd: () => void;
}
export const OverigeTarievenContext = React.createContext<IOverigeTarievenContext>(null as any);

export interface IRow extends IOphalenOverigeTarievenResultElement {}

const geenData = {
  noData: 'Geen tarieven gevonden',
};

const Overige: React.FC<IProps> = (props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const { checkStore } = useContext(RootStoreContext);

  const [selectie, setSelectie] = useState<number[]>([]);
  const [overigeTarieven, setOverigeTarieven] = useState<
    IOphalenOverigeTarievenResultElement[] | null
  >(null);

  const [filterSchema, setFilterSchema] = useState<IFilterSchema>(
    useMemo(() => maakFilterSchema(urlState.filterData), []),
  );

  const ophalenOverigeTarieven = useCallback(async () => {
    const bereikFilter = urlState.filterData.find((x) => x.naam === EFilter.Bereik)!;
    const result = await api.v2.aanbod.tarieven.overige.ophalenOverigeTarieven({
      filterSchema: {
        filters: [
          bereikFilter.isActief
            ? { naam: 'IS_OPENBAAR', data: bereikFilter.data === 1 ? true : false }
            : null,
          // ...(filterSchema.filters ?? []),
          // {
          //   naam: 'IS_DEFINITIEF',
          //   data: true,
          // },
        ].filter((x) => x !== null) as IFilterSchemaFilter[],
      },
    });
    setOverigeTarieven(result);
  }, [JSON.stringify(filterSchema), JSON.stringify(urlState.filterData)]);

  useEffect(() => {
    ophalenOverigeTarieven();
  }, [ophalenOverigeTarieven]);

  // const handleVerwijderen = useCallback(async (ids: number[]) => {
  //   const params = { verkModIDs: ids };
  //   const checkData = await api.v2.aanbod.verkoop.checkVerwijderenVerkoopmodellen(params);

  //   if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
  //     return;
  //   }
  //   if (
  //     (
  //       await checkStore.bevestigen({
  //         titel: `Wil je deze tarieven verwijderen?`,
  //       })
  //     ).type === EResultType.Annuleren
  //   ) {
  //     return;
  //   }

  //   await api.v2.aanbod.verkoop.verwijderenVerkoopmodellen(params);
  //   ophalenOverigeTarieven();
  // }, []);

  const sleutelExtractor = useCallback((row: IRow) => row.ID, []);
  const kolommen = useMemo<TypedColumn<IRow>[]>(
    () => [
      {
        name: 'Naam',
        title: 'Naam',
      },
      {
        name: 'Frequentie',
        title: 'Frequentie',
      },
      {
        name: 'Bedrag',
        title: 'Bedrag',
      },
      {
        name: 'BedragBis',
        title: 'Bedrag Bis',
      },
      {
        name: 'Percentage',
        title: 'Percentage',
      },
      {
        name: 'Openbaar',
        title: (
          <UitlegTooltip
            inhoud={'Openbaar houdt in dat het tarief op de website wordt weergegeven'}
          >
            <span>Bereik</span>
          </UitlegTooltip>
        ) as any,
      },

      // {
      //   name: 'NaamEnum',
      //   title: 'NaamEnum',
      // },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRow>[]>(
    () => [
      {
        columnName: 'Naam',
        width: 280,
      },
      {
        columnName: 'Frequentie',
        width: 110,
      },
      {
        columnName: 'Bedrag',
        width: 100,
      },
      {
        columnName: 'BedragBis',
        width: 110,
      },
      {
        columnName: 'Percentage',
        width: 110,
      },
      {
        columnName: 'Openbaar',
        width: 150,
      },
      {
        columnName: 'NaamEnum',
        width: 325,
      },
    ],
    [],
  );

  // const kolomExtensies: VirtualTableBase.ColumnExtension[] = useMemo(() => {
  //   return [{ columnName: 'Bedrag', align: 'right' }];
  // }, []);

  const overigeTarievenContextValue = useMemo<IOverigeTarievenContext>(
    () => ({
      onVerversenAangevraagd: async () => {
        await ophalenOverigeTarieven();
      },
    }),
    [ophalenOverigeTarieven],
  );

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.Bereik,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          const id = weergaveProps.data as number;
          return (
            <div className="d-flex align-items-center">
              <span className="mr-2">Bereik</span>
              <Combobox
                geselecteerd={weergaveProps.data}
                onSelectieChange={(x) => {
                  weergaveProps.onDataChange(x);
                  weergaveProps.toepassen();
                }}
                legeOptieTonen
                opties={[
                  { id: 1, label: 'Openbaar' },
                  { id: 2, label: 'Niet openbaar' },
                ]}
              />
            </div>
          );
        },
      },
    ],
    [],
  );

  return (
    <OverigeTarievenContext.Provider value={overigeTarievenContextValue}>
      <Helmet>
        <title>Overige tarieven</title>
      </Helmet>
      <MenuLayout
        menu={
          <>
            <div className="d-flex align-items-center">
              <button
                className="btn btn-sm btn-light d-flex align-items-center"
                disabled={!true}
                style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                onClick={() => setUrlStateSync('toevoegenDialoogState', {})}
              >
                <IconToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                <span className="ml-2">Nieuw tarief</span>
              </button>

              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-3"
                disabled={selectie.length === 0}
                style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                onClick={async () => {
                  const params = { ids: selectie };

                  const checkData = await api.v2.aanbod.tarieven.overige.checkVerwijderenTarieven(
                    params,
                  );
                  if (
                    (await checkStore.controleren({ checkData })).type === EResultType.Annuleren
                  ) {
                    return;
                  }

                  if (
                    (
                      await checkStore.bevestigen({
                        inhoud: (
                          <span>
                            Wil je dit tarief verwijderen?
                            <br />
                            Let op: dit kan consequenties hebben voor de werking van het systeem en
                            kan niet teruggedraaid worden.
                          </span>
                        ),
                      })
                    ).type === EResultType.Annuleren
                  ) {
                    return;
                  }

                  const result = await api.v2.aanbod.tarieven.overige.verwijderenTarieven(params);

                  ophalenOverigeTarieven();
                }}
              >
                <IconVerwijderen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />

                <span className="ml-2">Verwijderen</span>
              </button>

              <div className="d-flex flex-fill ml-3">
                <FilterBalkV2
                  filters={filters}
                  filterData={urlState.filterData}
                  onFilterDataChange={(x) => setUrlStateSync('filterData', x)}
                  onFilterSchemaChange={(x) => setFilterSchema(x)}
                />
              </div>
            </div>
          </>
        }
        body={
          <>
            {overigeTarieven === null ? (
              <div className="flex-fill d-flex align-items-center justify-content-center">
                <LoadingSpinner />
              </div>
            ) : (
              <GridStyleWrapper height={'calc(100vh - 100px)'}>
                <Grid rows={overigeTarieven} getRowId={sleutelExtractor} columns={kolommen}>
                  <DataTypeProvider
                    for={[nameOf<IRow>('Bedrag')]}
                    formatterComponent={(formatterProps) => {
                      if (formatterProps.value === null) {
                        return <span>-</span>;
                      }
                      return <FormatteerBedrag bedrag={formatterProps.value} />;
                    }}
                  />

                  <DataTypeProvider
                    for={[nameOf<IRow>('Openbaar')]}
                    formatterComponent={(formatterProps) => (
                      <span>{formatterProps.value ? 'Openbaar' : 'Niet openbaar'}</span>
                    )}
                  />

                  <DataTypeProvider
                    for={[nameOf<IRow>('Frequentie')]}
                    formatterComponent={(formatterProps) => (
                      <span>{formatterProps.value === 'P' ? 'Periodiek' : 'Eenmalig'}</span>
                    )}
                  />

                  <DataTypeProvider
                    for={[nameOf<IRow>('Percentage')]}
                    formatterComponent={(formatterProps) => {
                      if (formatterProps.value === null) {
                        return <span>-</span>;
                      }
                      return <span>{formatterProps.value}%</span>;
                    }}
                  />

                  <EditingState
                    onCommitChanges={async (changes) => {
                      if (changes.deleted === undefined) {
                        return;
                      }
                      const deleted = changes.deleted;
                      const id = deleted[deleted.length - 1] as number;
                      // alert(id);
                    }}
                    onEditingRowIdsChange={(rowIds) => {
                      const id = rowIds[rowIds.length - 1] as number;
                      setUrlStateSync('wijzigenDialoogState', { id });
                    }}
                  />

                  <RowDetailState
                    expandedRowIds={urlState.uitgeklapt}
                    onExpandedRowIdsChange={(x) => setUrlStateSync('uitgeklapt', x as number[])}
                  />

                  <SortingState defaultSorting={[]} />
                  <IntegratedSorting />

                  <VirtualTable messages={geenData} />
                  <TableColumnResizing defaultColumnWidths={kolomBreedtes} />

                  <TableEditColumn
                    width={45}
                    showEditCommand
                    cellComponent={DXTableEditColumnCellComponent}
                    commandComponent={DXTableEditColumnCommandComponent}
                  />
                  <TableHeaderRow showSortingControls />

                  <SelectionState
                    selection={selectie}
                    onSelectionChange={(x) => {
                      setSelectie(x as any);
                    }}
                  />
                  <TableSelection cellComponent={DXTableCheckboxComponent} />
                </Grid>
              </GridStyleWrapper>
            )}

            {urlState.wijzigenDialoogState !== null && (
              <WijzigenDialoog
                id={urlState.wijzigenDialoogState.id}
                open
                onSuccess={async () => {
                  ophalenOverigeTarieven();
                  setUrlStateSync('wijzigenDialoogState', null);
                }}
                onAnnuleren={() => {
                  setUrlStateSync('wijzigenDialoogState', null);
                }}
              />
            )}
            {urlState.toevoegenDialoogState !== null && (
              <ToevoegenDialoog
                open
                onSuccess={async () => {
                  ophalenOverigeTarieven();
                  setUrlStateSync('toevoegenDialoogState', null);
                }}
                onAnnuleren={() => {
                  setUrlStateSync('toevoegenDialoogState', null);
                }}
              />
            )}
          </>
        }
      ></MenuLayout>
    </OverigeTarievenContext.Provider>
  );
};

export default withRouter(Overige);
