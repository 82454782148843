import styled from 'styled-components';
import { colors } from '../../../../globalstyles/variables';

export const NotitieSectie = styled.div`
  padding: 8px 15px 15px 15px;
`;

export const Header = styled.header`
  display: flex;
  margin-bottom: 5px;
  align-items: flex-end;
  //font-size: 13px;

  button {
    svg {
      height: 15px;
      margin: 0;
      fill: blue;
    }
    &:hover {
      svg {
        fill: white;
      }
    }
  }
`;

export interface IBodyProps {
  wijzigen?: boolean;
}

export const Body = styled.div<IBodyProps>`
  textarea {
    padding: 3px;
    font-size: 15px;
    overflow-x: hidden;
    &:disabled {
      background-color: ${colors.lightGrey};
    }
  }
`;

export const ButtonGroep = styled.div`
  button {
    margin-left: 20px;
  }
`;

export const Titel = styled.div`
  font-weight: bold;
  align-self: flex-end;
`;

export const VasteInfoSectie = styled.div`
  margin-top: 1rem;
`;
