import { makeObservable, observable } from 'mobx';
import { RootStore } from './RootStore';
import { IOphalenRedenenAanbiedingResultElement } from '../../../shared/src/api/v2/contract/aanbieding';
import api from '../api';

class OverdrachtStore {
  public redenenAanbieding: IOphalenRedenenAanbiedingResultElement[] | null = null;
  // tslint:disable-next-line:variable-name
  private _rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this._rootStore = rootStore;

    makeObservable(this, {
      redenenAanbieding: observable,
    });
  }

  public ophalenRedenenAanbieding = async () => {
    this.redenenAanbieding = await api.v2.contract.aanbieding.ophalenRedenenAanbieding();
  };
}

export default OverdrachtStore;
