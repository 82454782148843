import React, { useState } from 'react';
import Dialoog from '../dialogen/Dialoog';
import IDialoogProps from '../../core/IDialoogProps';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import ContactSelectie, { EType } from '../formulier/ContactSelectie';
import { IOphalenContactenResultElement } from '../../../../shared/src/api/v2/relatie';

export interface IDialoogResult {
  contact: IOphalenContactenResultElement;
}

interface IProps extends IDialoogProps<IDialoogResult> {}

const ContactSelecterenDialoogV2: React.FC<IProps> = (props) => {
  const [contact, setContact] = useState<IOphalenContactenResultElement | null>(null);

  return (
    <Dialoog index={props.dialoogIndex || 0}>
      <ModalBody style={{ padding: '2rem' }}>
        <ContactSelectie
          value={
            contact === null
              ? null
              : {
                  persID: contact.persoon?.PersID ?? null,
                  orgID: contact.organisatie?.OrgID ?? null,
                }
          }
          onChange={setContact}
        />
      </ModalBody>
      <ModalFooter className="d-flex flex-row justify-content-start">
        <button
          className="btn btn-primary d-flex align-items-center justify-content-center"
          onClick={() =>
            props.onSuccess({
              contact: contact!,
            })
          }
          style={{ width: 100 }}
          disabled={contact === null}
        >
          Ok
        </button>
        <button className="btn btn-secondary" onClick={props.onAnnuleren} style={{ width: 100 }}>
          Annuleren
        </button>
      </ModalFooter>
    </Dialoog>
  );
};

export default ContactSelecterenDialoogV2;
