import * as React from 'react';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { EResultType } from '../../../../../stores/CheckStore';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../../../stores/RootStore';
import {
  IOphalenRollenResultElement,
  IVerwijderenRollenParams,
} from '../../../../../../../shared/src/api/v2/autorisatie/rol';
import api from '../../../../../api';

interface IProps {
  rol: IOphalenRollenResultElement;
  onGemuteerd: () => void;
  onVerwijderd: () => void;
}

const RolGegevensWeergave = observer((props: IProps) => {
  const { checkStore } = useContext(RootStoreContext);

  const [naam, setNaam] = useState(props.rol.Naam);
  const [omschrijving, setOmschrijving] = useState(props.rol.Omschrijving ?? '');

  const opslaan = useCallback(async () => {
    const naamValue = naam.trim();

    if (naamValue.length === 0) {
      await checkStore.melden({
        titel: 'Naam is verplicht',
      });
      return;
    }

    const omschrijvingValue = omschrijving.trim();

    await api.v2.autorisatie.rol.wijzigenRol({
      ID: props.rol.ID,
      Naam: naamValue,
      Omschrijving: omschrijvingValue.length === 0 ? null : omschrijvingValue,
    });
    props.onGemuteerd();
  }, [props.onGemuteerd, naam, omschrijving, props.rol.ID]);

  const debounceRef = useRef<number | null>(null);
  const debounceOpslaan = useCallback(() => {
    if (debounceRef.current !== null) {
      clearTimeout(debounceRef.current!);
    }
    debounceRef.current = setTimeout(() => opslaan(), 500) as any;
  }, [opslaan]);

  const firstUpdate = useRef(true);
  useEffect(() => {
    firstUpdate.current = true;

    setNaam(props.rol.Naam);
    setOmschrijving(props.rol.Omschrijving ?? '');
  }, [props.rol]);
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    debounceOpslaan();
  }, [naam, omschrijving]);

  return (
    <div className="d-flex flex-column flex-fill p-3">
      <div className="d-flex justify-content-between">
        <h4>Rol</h4>
        <a
          href="#"
          onClick={async (ev) => {
            ev.preventDefault();

            if (
              (await checkStore.bevestigen({ inhoud: 'Bevestig verwijderen rol' })).type ===
              EResultType.Annuleren
            ) {
              return;
            }

            const params: IVerwijderenRollenParams = {
              ids: [props.rol.ID],
            };
            const checkData = await api.v2.autorisatie.rol.checkVerwijderenRollen(params);
            if (
              (
                await checkStore.controleren({
                  checkData,
                })
              ).type === EResultType.Annuleren
            ) {
              return;
            }
            await api.v2.autorisatie.rol.verwijderenRollen(params);

            props.onVerwijderd();
          }}
        >
          Verwijderen rol
        </a>
      </div>
      <div className="d-flex flex-column flex-fill mt-2 pl-2 pr-2" style={{ rowGap: 15 }}>
        <div>
          <label>Naam</label>
          <input
            type="text"
            className="form-control"
            value={naam}
            onChange={(ev) => {
              setNaam(ev.target.value);
            }}
          />
        </div>

        <div>
          <label>Omschrijving</label>
          <textarea
            className="form-control"
            value={omschrijving}
            onChange={(ev) => {
              setOmschrijving(ev.target.value);
            }}
            rows={5}
          />
        </div>
      </div>
    </div>
  );
});

export default RolGegevensWeergave;
