import React, { useMemo, useState } from 'react';
import VeldWeergave from '../../formulier/VeldWeergave';
import { format } from 'date-fns';
import {
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../helpers/dxTableGrid';
import { Kleur as EKleur } from '../../../bedrijfslogica/constanten';
import { Grid, TableHeaderRow, VirtualTable } from '@devexpress/dx-react-grid-bootstrap4';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import nameOf from '../../../core/nameOf';

import FormatteerBedrag from '../../MutatieBedrag';
import { IOphalenHerincassozakenResultElement } from '../../../../../shared/src/api/v2/debiteur/herincassozaak';

interface IProps {
  herincassozaken: IOphalenHerincassozakenResultElement[];
}

const Weergavetabel: React.FC<IProps> = (props) => {
  const kolommenOpdracht = useMemo<TypedColumn<IOphalenHerincassozakenResultElement>[]>(
    () => [
      {
        name: 'DossierDatum',
        title: 'Zaakdatum',
      },
      {
        name: 'Zaaknummer',
        title: 'Nummer',
      },
      {
        name: 'Hoofdsom',
        title: 'Hoofdsom',
      },
      {
        name: '__status' as any,
        title: 'Status',
      },
      {
        name: '__dienst' as any,
        title: 'Dienst',
      },
      {
        name: '__externeReferentie' as any,
        title: 'Ext.ref.',
      },
      {
        name: 'Afgehandeld',
        title: 'Afgehandeld',
      },
      {
        name: 'Notities',
        title: 'Notities',
      },
    ],
    [],
  );

  const kolombreedtesOpdracht = useMemo<
    TypedTableColumnWidthInfo<IOphalenHerincassozakenResultElement>[]
  >(
    () => [
      {
        columnName: 'DossierDatum',
        width: 90,
      },
      {
        columnName: 'Zaaknummer',
        width: 90,
      },
      {
        columnName: 'Hoofdsom',
        width: 90,
      },
      {
        columnName: '__externeReferentie' as any,
        width: 125,
      },
      {
        columnName: '__status' as any,
        width: 150,
      },
      {
        columnName: '__dienst' as any,
        width: 150,
      },
      {
        columnName: 'Notities',
        width: 150,
      },
      {
        columnName: 'Afgehandeld',
        width: 150,
      },
    ],
    [],
  );

  return (
    <>
      <VeldWeergave>
        <div className="p-1 font-weight-bold">Herincassozaken</div>
        <div className="mt-1" style={{ backgroundColor: EKleur.Wit }}>
          <GridStyleWrapper maxHeight={400} rowAmount={props.herincassozaken.length}>
            <Grid rows={props.herincassozaken} columns={kolommenOpdracht}>
              <DataTypeProvider
                for={[nameOf<IOphalenHerincassozakenResultElement>('DossierDatum')]}
                formatterComponent={(formatterProps) => (
                  <span>{format(new Date(formatterProps.value), 'dd-MM-yyyy')}</span>
                )}
              />

              <DataTypeProvider
                for={[nameOf<IOphalenHerincassozakenResultElement>('Hoofdsom')]}
                formatterComponent={(formatterProps) => (
                  <span>
                    <FormatteerBedrag bedrag={formatterProps.value} />
                  </span>
                )}
              />

              <DataTypeProvider
                for={['__dienst']}
                formatterComponent={(formatterProps) => {
                  const rij = formatterProps.row as IOphalenHerincassozakenResultElement;
                  return <span>{rij.incassodienst!.relatie!.weergavenaam}</span>;
                }}
              />

              <DataTypeProvider
                for={['__externeReferentie']}
                formatterComponent={(formatterProps) => {
                  const rij = formatterProps.row as IOphalenHerincassozakenResultElement;
                  return <span>-</span>;
                }}
              />

              <DataTypeProvider
                for={['__status']}
                formatterComponent={(formatterProps) => {
                  const rij = formatterProps.row as IOphalenHerincassozakenResultElement;
                  return <span>{rij.Status}</span>;
                }}
              />

              <VirtualTable
                columnExtensions={kolombreedtesOpdracht}
                messages={{ noData: 'Geen herincassozaken bekend' }}
              />
              <TableHeaderRow />
            </Grid>
          </GridStyleWrapper>
        </div>
      </VeldWeergave>
    </>
  );
};

export default Weergavetabel;
