import React, { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import { IUseActieveOproepOutput } from '../../../../helpers/communicatie/telefoon/useActieveOproep';
import useNotifyChanges from '../../../../helpers/useNotifyChanges';
import { ETelefooneventStatus } from '../../../../bedrijfslogica/enums';
import ContactKoppelComponent from '../../../../components/ContactKoppelComponent';
import { intervalToDuration } from 'date-fns';
import PersoonVisualisatie from '../../../../components/personalia/PersoonVisualisatie';
import OrganisatieVisualisatie from '../../../../components/personalia/OrganisatieVisualisatie';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import styled from 'styled-components';
import AfdelingVisualisatie from '../../../../components/personalia/AfdelingVisualisatie';

const Root = styled.div`
  & {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    position: relative;
    overflow: hidden;
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 50%;
    min-width: 800px;
    min-height: 800px;
    background-color: rgba(150, 150, 150, 0.3);
    animation-name: rotate;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    top: 120%;
  }

  &:before {
    bottom: 15vh;
    border-radius: 45%;
    animation-duration: 10s;
  }

  &:after {
    bottom: 12vh;
    opacity: 0.5;
    border-radius: 47%;
    animation-duration: 10s;
  }

  @keyframes rotate {
    0% {
      transform: translate(-50%, 0) rotateZ(0deg);
    }
    50% {
      transform: translate(-50%, -2%) rotateZ(180deg);
    }
    100% {
      transform: translate(-50%, 0%) rotateZ(360deg);
    }
  }
`;

interface IProps {
  actieveOproepOutput: IUseActieveOproepOutput;
}

const TelefoongesprekActueel: React.FC<IProps> = observer((props) => {
  const { actieveOproepOutput } = props;
  const notifyChanges = useNotifyChanges();
  useEffect(() => {
    const intervalId = setInterval(() => {
      notifyChanges();
    }, 1000);
    return () => clearInterval(intervalId);
  }, [notifyChanges]);

  // const events = useMemo(() => {
  //   const events = [...actieveOproepOutput.actieveOproep.events];
  //   return events.sort((lhs, rhs) => new Date(lhs.Datum).getTime() - new Date(rhs.Datum).getTime());
  // }, [actieveOproepOutput.actieveOproep.events]);
  // const eersteEvent = useMemo(() => events[0], [events]);

  const beantwoordEvent = useMemo(
    () =>
      actieveOproepOutput.actieveOproep.events.find(
        (x) => x.Status === ETelefooneventStatus.Beantwoord,
      ),
    [actieveOproepOutput.actieveOproep.events],
  );
  if (!beantwoordEvent) {
    return null;
  }

  const duration = intervalToDuration({
    start: new Date(beantwoordEvent.Datum),
    end: new Date(),
  });

  return (
    <Root className="p-3 w-100">
      <div className="d-flex flex-column align-items-center justify-content-center">
        {actieveOproepOutput.actieveOproep.PersID !== null ? (
          <PersoonVisualisatie
            persID={actieveOproepOutput.actieveOproep.PersID}
            naamComponent={(naamProps) => (
              <span
                className="w-100 flex-fill d-flex align-items-center justify-content-center"
                style={{ color: Kleur.LichtGeel }}
              >
                {naamProps.children}
              </span>
            )}
          />
        ) : actieveOproepOutput.actieveOproep.OrgID !== null ? (
          <div className="d-flex flex-column">
            <OrganisatieVisualisatie
              orgID={actieveOproepOutput.actieveOproep.OrgID}
              naamComponent={(naamProps) => (
                <span
                  className="w-100 flex-fill d-flex align-items-center justify-content-center"
                  style={{ color: Kleur.LichtGeel }}
                >
                  {naamProps.children}
                </span>
              )}
            />
            {actieveOproepOutput.actieveOproep.OrgAfdID !== null && (
              <div className="d-flex flex-fill align-items-center justify-content-center">
                <span className="mr-2 font-weight-bold">Afd.</span>
                <AfdelingVisualisatie orgAfdID={actieveOproepOutput.actieveOproep.OrgAfdID} />
              </div>
            )}
          </div>
        ) : (
          <span>
            {actieveOproepOutput.actieveOproep.Nummer === null
              ? 'Anoniem nummer'
              : actieveOproepOutput.actieveOproep.Nummer}
          </span>
        )}
      </div>
      <span
        style={{
          position: 'relative',
          marginLeft: 7,
          color: 'white',
          top: 2,
          marginRight: 5,
          fontSize: 13,
        }}
        className="font-weight-bold"
      >
        {duration.hours !== 0 && `${duration.hours!.toString().padStart(2, '0')}:`}
        {(duration.minutes || 0).toString().padStart(2, '0')}:
        {(duration.seconds || 0).toString().padStart(2, '0')}
      </span>
    </Root>
  );
});

export default TelefoongesprekActueel;
