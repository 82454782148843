import React, { SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import AfbeeldingKader from '../weergave/AfbeeldingKader';
import ReactImageMagnify from 'react-image-magnify';
import PDFWeergever from '../PDFWeergever';
import { Document, Page } from 'react-pdf';
import LoadingSpinner from '../Gedeeld/LoadingSpinner';
import { Kleur } from '../../bedrijfslogica/constanten';

export type MediaID = number | string;

export enum EMediaWeergaveType {
  Afbeelding,
  Video,
  PDF,
}

export const mediaTypeNaarMediaWeergaveType = (mediaType: string): EMediaWeergaveType | null => {
  if (mediaType.startsWith('image/')) {
    return EMediaWeergaveType.Afbeelding;
  }
  if (mediaType.startsWith('video/')) {
    return EMediaWeergaveType.Video;
  }
  if (mediaType === 'application/pdf') {
    return EMediaWeergaveType.PDF;
  }

  return null;
};

export interface IMediaWeergaveBase {
  id: MediaID;
  title?: string;
  mediaType: string;
}

export interface IAfbeeldingMediaWeergavePartial {
  type: EMediaWeergaveType.Afbeelding;
  src: string;
}

export interface IVideoMediaWeergavePartial {
  type: EMediaWeergaveType.Video;
  src: string;
}

export interface IPDFediaWeergavePartial {
  type: EMediaWeergaveType.PDF;
  src: string;
}

export type MediaWeergaveItem = IMediaWeergaveBase &
  (IAfbeeldingMediaWeergavePartial | IVideoMediaWeergavePartial | IPDFediaWeergavePartial);

interface IProps {
  mediaWeergaven: MediaWeergaveItem[];
  current?: MediaID;
  onCurrentChange?: (id: MediaID) => void;
  defaultCurrent?: MediaID;
  navigatieTonenMetEnkeleMediaWeergave?: boolean;
  imageStyle?: React.CSSProperties;
}

const MediaWeergave: React.FC<IProps> = (props) => {
  const {
    children,
    current,
    defaultCurrent,
    mediaWeergaven,
    onCurrentChange,
    navigatieTonenMetEnkeleMediaWeergave,
  } = props;
  const firstMediaWeergave = useMemo(() => mediaWeergaven[0], [mediaWeergaven]);

  const [curr, setCurr] = useState<MediaID>(current || defaultCurrent || firstMediaWeergave.id);
  useEffect(() => {
    if (current === undefined) {
      return;
    }
    setCurr(current);
  }, [current]);

  const setCurrent = useCallback(
    (id: MediaID) => {
      if (onCurrentChange !== undefined) {
        onCurrentChange(id);
        return;
      }
      setCurr(id);
    },
    [onCurrentChange, setCurr],
  );

  const currentMediaWeergave = useMemo(() => mediaWeergaven.find((x) => x.id === curr)!, [
    curr,
    mediaWeergaven,
  ]);

  const [smallImageDimensions, setSmallImageDimensions] = useState<{
    width: number;
    height: number;
  } | null>(null);
  const mouseMoveTimeoutRef = useRef<number | null>(null);
  const [zoomToegestaan, setZoomToegestaan] = useState(false);

  return (
    <div
      className="d-flex flex-column flex-fill"
      style={{ height: 800 + (mediaWeergaven.length > 1 ? 105 : 0) /*maxHeight: 662.5*/ }}
    >
      <div
        className="d-flex flex-fill"
        onMouseMove={
          !zoomToegestaan
            ? () => {
                if (mouseMoveTimeoutRef.current !== null) {
                  clearTimeout(mouseMoveTimeoutRef.current);
                  mouseMoveTimeoutRef.current = null;
                }
                mouseMoveTimeoutRef.current = setTimeout(() => {
                  setZoomToegestaan(true);
                  mouseMoveTimeoutRef.current = null;
                }, 100) as any;
              }
            : undefined
        }
      >
        {currentMediaWeergave.type === EMediaWeergaveType.Afbeelding ? (
          <div className="d-flex flex-fill justify-content-center align-items-center">
            <div style={{ pointerEvents: zoomToegestaan ? undefined : 'none' }}>
              <ReactImageMagnify
                {...{
                  smallImage: {
                    isFluidWidth: true,
                    src: currentMediaWeergave.src,
                    sizes: '(max-width: 480px) 100vw, (max-width: 1200px) 30vw, 360px',
                    onLoad: ((ev: SyntheticEvent<HTMLImageElement>) => {
                      setSmallImageDimensions({
                        width: ev.currentTarget.width,
                        height: ev.currentTarget.height,
                      });
                    }) as any,
                  },
                  largeImage: {
                    src: currentMediaWeergave.src,
                    width: smallImageDimensions === null ? 0 : smallImageDimensions.width * 3,
                    height: smallImageDimensions === null ? 0 : smallImageDimensions.height * 3,
                  },
                  enlargedImageContainerDimensions: {
                    width: 400,
                    height: '75%',
                  },
                  lensStyle: { backgroundColor: 'rgba(0,0,0,.1)' },
                  imageStyle: {
                    objectFit: 'contain',
                    ...props.imageStyle,
                  },
                  enlargedImageStyle: {
                    objectFit: 'contain',
                  },
                }}
              />
            </div>
          </div>
        ) : currentMediaWeergave.type === EMediaWeergaveType.Video ? (
          <div>
            <video width="100%" height="100%" controls>
              <source src={currentMediaWeergave.src} type={currentMediaWeergave.mediaType} />
            </video>
          </div>
        ) : currentMediaWeergave.type === EMediaWeergaveType.PDF ? (
          <PDFWeergever url={currentMediaWeergave.src} pageWidth={750} />
        ) : (
          <div>Geen implementatie</div>
        )}
      </div>
      {(mediaWeergaven.length > 1 || props.navigatieTonenMetEnkeleMediaWeergave) && (
        <div
          className="d-flex mt-2 pb-2 ml-2 mr-2"
          style={{
            overflowX: 'auto',
          }}
        >
          {mediaWeergaven.map((mediaWeergave, i) => {
            return (
              <div
                className={i === 0 ? '' : 'ml-2'}
                onClick={() => {
                  setCurrent(mediaWeergave.id);
                }}
                style={{ cursor: 'pointer' }}
              >
                {mediaWeergave.type === EMediaWeergaveType.Afbeelding ? (
                  <AfbeeldingKader
                    key={i}
                    afbeelding={
                      <img
                        alt="Afbeelding"
                        src={mediaWeergave.src}
                        width={85}
                        height={85}
                        style={{
                          objectFit: 'contain',
                        }}
                      />
                    }
                    width={85}
                    height={100}
                    isActief={curr === mediaWeergave.id}
                  />
                ) : mediaWeergave.type === EMediaWeergaveType.Video ? (
                  <AfbeeldingKader
                    key={i}
                    afbeelding={<div style={{ width: 85, height: 85 }} />}
                    width={85}
                    height={100}
                    isActief={curr === mediaWeergave.id}
                  />
                ) : mediaWeergave.type === EMediaWeergaveType.PDF ? (
                  <div
                    style={{
                      backgroundColor: Kleur.LichtGrijs,
                      border: `1px solid ${curr === mediaWeergave.id ? Kleur.Blauw : Kleur.Grijs}`,
                    }}
                  >
                    <div style={{ position: 'relative', width: 100, height: 100 }}>
                      <div
                        className="d-flex flex-fill justify-content-center"
                        style={{
                          position: 'absolute',
                          bottom: 0,
                          top: 0,
                          left: -1,
                          right: 0,
                          overflow: 'hidden',
                          padding: 5,
                          paddingBottom: 0,
                        }}
                      >
                        <Document
                          file={mediaWeergave.src}
                          loading={() => <LoadingSpinner />}
                          error={() => <span>PDF preview mislukt</span>}
                        >
                          <Page pageNumber={1} width={85} />
                        </Document>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>Geen implementatie voor dit type</div>
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default MediaWeergave;
