import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IOphalenUitnodigingenResultElement } from '../../../../../shared/src/api/v2/review/klant';
import MenuLayout from '../../../components/MenuLayout';
import {
  DXTableCheckboxComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../helpers/dxTableGrid';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  DataTypeProvider,
  IntegratedSorting,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import LoadingSpinner from '../../../components/Gedeeld/LoadingSpinner';
import nameof from '../../../core/nameOf';
import api from '../../../api';
import useUrlState from '../../../core/useUrlState';
import { addMonths, format } from 'date-fns';
import _ from 'lodash';
import RelatieVisualisatie from '../../../components/personalia/RelatieVisualisatie';
import { IconDownload, IconSend, IconVerwijderen } from '../../../components/Icons';
import { Kleur } from '../../../bedrijfslogica/constanten';
import FilterBalkV2, {
  IFilter,
  IFilterData,
  maakFilterSchema,
} from '../../../components/FilterBalkV2';
import { RootStoreContext } from '../../../stores/RootStore';
import { EResultType } from '../../../stores/CheckStore';
import SelectieVak from '../../../components/SelectieVak';
import { IOphalenRelatiesResultElementV2 } from '../../../../../shared/src/api/v2/relatie';
import useSelectieCheck from '../../../core/useSelectieCheck';
import PersoonVisualisatie from '../../../components/personalia/PersoonVisualisatie';
import { IOphalenDienstenResultElement } from '../../../../../shared/src/api/v2/dienst/review';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../models/IRemoteData';
import { IOphalenPersonenResultElementV2 } from '../../../../../shared/src/api/v2/persoon/persoon';
import { EHoedanigheid } from '../../../components/personalia/RelatieSelectieDialoog';
import { Helmet } from 'react-helmet';
import VersturenDialoog from './VersturenDialoog';

interface IProps extends RouteComponentProps {}

enum EFilter {
  NogUitnodigen = 'NOG_UITNODIGEN',
}
interface IUrlState {
  selectie: number[];
  filterData: IFilterData<EFilter>[];
  versturenDialoogState: IVersturenDialoogState | null;
}

const defaultUrlState: IUrlState = {
  selectie: [],
  filterData: [
    {
      naam: EFilter.NogUitnodigen,
      data: true,
      isActief: true,
    },
  ],
  versturenDialoogState: null,
};

export interface IVersturenDialoogState {}

const geenData = {
  noData: 'Geen uitnodigingen gevonden',
};

export interface IRegel extends IOphalenUitnodigingenResultElement {}

export interface IUitnodigingenData {
  uitnodigingen: IOphalenUitnodigingenResultElement[];
  diensten: IOphalenDienstenResultElement[];
  personen: IOphalenPersonenResultElementV2[];
}

const Uitnodigingen: React.FC<IProps> = (props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const { checkStore } = useContext(RootStoreContext);

  const [filterSchema, setFilterSchema] = useState(maakFilterSchema(urlState.filterData));

  const [uitnodigingenData, setUitnodigingenData] = useState<IRemoteData<IUitnodigingenData>>(
    createPendingRemoteData(),
  );
  const [relaties, setRelaties] = useState<IOphalenRelatiesResultElementV2[] | null>(null);

  const ophalenUitnodigingen = useCallback(async () => {
    const peildatum = addMonths(new Date(), -12);

    setRelaties(null);

    const uitnodigingen = (
      await api.v2.review.ophalenUitnodigingen({
        filterSchema: {
          filters: [
            ...filterSchema.filters!,
            {
              naam: 'RECORD_TOEGEVOEGD_VANAF',
              data: peildatum,
            },
          ],
        },
      })
    ).uitnodigingen;

    // Reviewdiensten
    const reviewDienstIDs = _.uniq(uitnodigingen.map((x) => x.ReviewDienstID));
    const diensten = (
      await api.v2.dienst.review.ophalenDiensten({
        filterSchema: {
          filters: [
            {
              naam: 'IDS',
              data: reviewDienstIDs,
            },
          ],
        },
      })
    ).diensten;

    // Personen
    const persIDs = _.uniq(uitnodigingen.map((x) => x.PersID));
    const personen = (
      await api.v2.persoon.ophalenPersonen({
        filterSchema: {
          filters: [{ naam: 'IDS', data: persIDs }],
        },
      })
    ).personen;

    const uitnodigingenGesorteerd = _.orderBy(
      uitnodigingen,
      [
        (x: IOphalenUitnodigingenResultElement) => {
          return x.DatumUitgenodigd;
        },
      ],
      ['desc'],
    );

    setUitnodigingenData(
      createReadyRemoteData({
        uitnodigingen: uitnodigingenGesorteerd,
        diensten,
        personen,
      }),
    );
  }, [filterSchema]);

  useEffect(() => {
    ophalenUitnodigingen();
  }, [ophalenUitnodigingen]);

  const ophalenRelaties = useCallback(async () => {
    if (uitnodigingenData.state === ERemoteDataState.Pending) {
      return;
    }

    const relaties = (
      await api.v2.relatie.ophalenRelaties({
        filterSchema: {
          filters: [
            {
              naam: 'IDS',
              data: uitnodigingenData!.data!.uitnodigingen.map((x) => x.RelID),
            },
          ],
        },
      })
    ).relaties;

    setRelaties(relaties);
  }, [uitnodigingenData]);

  useEffect(() => {
    ophalenRelaties();
  }, [ophalenRelaties]);

  const handleVersturen = useCallback(async () => {
    const params = { ids: urlState.selectie };

    const checkData = await api.v2.review.checkVersturenUitnodigingen(params);
    const checkResult = await checkStore.controleren({ checkData });
    if (checkResult.type === EResultType.Annuleren) {
      return;
    }

    // Welke reviewdienst gebruiken?
    const instelling = await api.v2.instelling.ophalenInstellingen({});
    const reviewdienst = (
      await api.v2.dienst.review.ophalenDiensten({
        filterSchema: { filters: [{ naam: 'IDS', data: [instelling.ReviewDienstID] }] },
      })
    ).diensten[0];

    const resultaat = await checkStore.bevestigen({
      inhoud: (
        <span>
          Uitnodigingen versturen?
          <br />
          <br />
          <b>
            {reviewdienst.NaamIdent!}{' '}
            {reviewdienst.UitnodigingViaAPI ? 'via API' : 'via uitnodigingslink'}
          </b>
        </span>
      ),
    });
    if (resultaat.type === EResultType.Annuleren) {
      return;
    }

    await api.v2.review.versturenUitnodigingen(params);

    setUrlStateSync('selectie', []);

    ophalenUitnodigingen();
  }, [urlState.selectie]);

  const handleVersturenV2 = useCallback(
    async (dienstID: number) => {
      const params = { ids: urlState.selectie, dienstID };

      const checkData = await api.v2.review.checkVersturenUitnodigingen(params);
      const checkResult = await checkStore.controleren({ checkData });
      if (checkResult.type === EResultType.Annuleren) {
        return;
      }

      // Welke reviewdienst gebruiken?
      // const instelling = await api.v2.instelling.ophalenInstellingen({});
      const reviewdienst = (
        await api.v2.dienst.review.ophalenDiensten({
          filterSchema: { filters: [{ naam: 'IDS', data: [dienstID] }] },
        })
      ).diensten[0];

      const resultaat = await checkStore.bevestigen({
        inhoud: (
          <span>
            Uitnodigingen versturen?
            <br />
            <br />
            <b>
              {reviewdienst.NaamIdent!}{' '}
              {reviewdienst.UitnodigingViaAPI ? 'via API' : 'via uitnodigingslink'}
            </b>
          </span>
        ),
      });
      if (resultaat.type === EResultType.Annuleren) {
        return;
      }

      await api.v2.review.versturenUitnodigingen(params);
    },
    [urlState.selectie],
  );

  const handleVerwijderen = useCallback(async () => {
    const checkData = await api.v2.review.checkVerwijderenUitnodigingen({
      ids: urlState.selectie,
    });
    const checkResult = await checkStore.controleren({
      checkData,
    });
    if (checkResult.type === EResultType.Annuleren) {
      return;
    }

    if (
      (
        await checkStore.bevestigen({
          inhoud: (
            <span>
              Uitnodigingen verwijderen?
              <br />
              <br />
              Als een uitnodiging uit een voorstel kwam dan wordt dit voorstel weer actief.
            </span>
          ),
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    await api.v2.review.verwijderenUitnodigingen({
      ids: urlState.selectie,
    });

    ophalenUitnodigingen();
  }, [urlState.selectie]);

  const keyExtractor = useCallback((row: IRegel) => row.ID, []);
  const kolommen = useMemo<TypedColumn<IRegel>[]>(
    () => [
      {
        name: 'Email',
        title: 'Email',
      },
      {
        name: '__relatie' as any,
        title: 'Relatie / Persoon',
      },
      // {
      //   name: '__persoon' as any,
      //   title: 'Persoon',
      // },
      {
        name: 'RecordToegevoegd',
        title: 'Geregistreerd',
      },
      {
        name: 'DatumUitgenodigd',
        title: 'Uitgenodigd op',
      },
      {
        name: '__dienst' as any,
        title: 'Dienst',
      },
      {
        name: '__uitnodigingViaApi' as any,
        title: 'Medium',
      },
    ],
    [],
  );
  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRegel>[]>(
    () => [
      {
        columnName: 'Email',
        width: 250,
      },
      {
        columnName: '__persoon' as any,
        width: 225,
      },
      {
        columnName: '__relatie' as any,
        width: 300,
      },
      {
        columnName: 'DatumUitgenodigd',
        width: 150,
      },
      {
        columnName: '__dienst' as any,
        width: 120,
      },
      {
        columnName: '__uitnodigingViaApi' as any,
        width: 90,
      },
      {
        columnName: 'RecordToegevoegd',
        width: 135,
      },
    ],
    [],
  );

  useSelectieCheck(
    useMemo(
      () => ({
        selectie: urlState.selectie,
        onSelectieChange: (x: number[]) => setUrlStateSync('selectie', x),
        sleutels:
          uitnodigingenData.state === ERemoteDataState.Pending
            ? null
            : uitnodigingenData.data!.uitnodigingen.map(keyExtractor),
      }),
      [uitnodigingenData],
    ),
  );

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.NogUitnodigen,
        altijdWeergevenInBalk: true,
        actiefMuteerbaar: true,
        weergave: (weergaveProps) => {
          return <span>Alleen nog uit te nodigen</span>;
        },
      },
    ],
    [],
  );

  return uitnodigingenData.state === ERemoteDataState.Pending || relaties === null ? (
    <div className="flex-fill d-flex align-items-center justify-content-center">
      <LoadingSpinner />
    </div>
  ) : (
    <>
      <Helmet>
        <title>Review Uitnodigingen</title>
      </Helmet>
      <MenuLayout
        menu={
          <>
            <div className="d-flex flex-column">
              <div className="d-flex align-items-center">
                <button
                  className="btn btn-sm btn-light d-flex align-items-center"
                  style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                  disabled={urlState.selectie.length === 0}
                  // onClick={async () => handleVersturenV2(1)}
                  onClick={async () => setUrlStateSync('versturenDialoogState', {})}
                >
                  <IconSend style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                  <span className="ml-2">Uitnodigingen versturen</span>
                </button>
                <button
                  className="btn btn-sm btn-light d-flex align-items-center ml-2"
                  style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                  disabled={urlState.selectie.length === 0}
                  onClick={async () => null}
                >
                  <IconDownload style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                  <span className="ml-2">Exportbestand maken</span>
                </button>
                <button
                  className="btn btn-sm btn-light d-flex align-items-center ml-2"
                  style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                  disabled={urlState.selectie.length === 0}
                  onClick={async () => handleVerwijderen()}
                >
                  <IconVerwijderen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                  <span className="ml-2">Verwijderen</span>
                </button>

                <div className="d-flex flex-fill ml-3">
                  <FilterBalkV2
                    filters={filters}
                    filterData={urlState.filterData}
                    onFilterDataChange={(x) => setUrlStateSync('filterData', x)}
                    onFilterSchemaChange={(x) => setFilterSchema(x)}
                  />
                </div>
              </div>

              <div className="mt-3">
                <SelectieVak
                  aantal={urlState.selectie.length}
                  totaalAantal={uitnodigingenData.data!.uitnodigingen.length}
                  onChange={(alles) => {
                    if (alles) {
                      setUrlStateSync(
                        'selectie',
                        uitnodigingenData.data!.uitnodigingen.map((x) => x.ID),
                      );
                    } else {
                      setUrlStateSync('selectie', []);
                    }
                  }}
                />
              </div>
            </div>
          </>
        }
        body={
          <GridStyleWrapper
            rowAmount={uitnodigingenData.data!.uitnodigingen.length}
            height={'calc(100vh - 150px)'}
          >
            <Grid
              rows={uitnodigingenData.data!.uitnodigingen}
              columns={kolommen}
              getRowId={keyExtractor}
            >
              <DataTypeProvider
                for={['__dienst']}
                formatterComponent={(formatterProps) => {
                  const rij: IRegel = formatterProps.row;
                  if (rij.ReviewDienstID === null) {
                    return <span></span>;
                  }
                  const dienst = uitnodigingenData.data!.diensten.find(
                    (x) => x.ID === rij.ReviewDienstID,
                  )!;
                  return <span>{dienst.Naam}</span>;
                }}
              />

              <DataTypeProvider
                for={['__uitnodigingViaApi']}
                formatterComponent={(formatterProps) => {
                  const rij: IRegel = formatterProps.row;

                  if (rij.UitnodigingViaAPI === null) {
                    return <span></span>;
                  }

                  return <span>{rij.UitnodigingViaAPI ? 'API' : 'Email'}</span>;
                }}
              />

              <DataTypeProvider
                for={['__relatie']}
                formatterComponent={(formatterProps) => {
                  const rij: IRegel = formatterProps.row;

                  const relatie =
                    relaties.find((x) => x.RelID !== null && x.RelID === rij.RelID) ?? null;

                  if (
                    relatie !== null
                    // relatie.Relatiesoort === 'O'
                    // relatie.persoon !== null &&
                    // relatie.persoon.PersID !== rij.PersID
                  ) {
                    // return <span>{relatie.organisatie!.Naam}</span>;
                    return (
                      <span className="d-flex">
                        <RelatieVisualisatie
                          relID={relatie.RelID}
                          relatieLinkBuilder={(hoedanigheid, relID) =>
                            `/${
                              hoedanigheid === EHoedanigheid.Klant ? 'klant' : 'leverancier'
                            }/${relID}/klant/reviews`
                          }
                        />
                        {relatie.Relatiesoort === 'O' && (
                          <span className="d-flex ml-2">
                            (<PersoonVisualisatie persID={rij.PersID!} />)
                          </span>
                        )}
                      </span>
                    );
                  }

                  return <PersoonVisualisatie persID={rij.PersID!} />;
                }}
              />

              {/* <DataTypeProvider
              for={['__persoon']}
              formatterComponent={(formatterProps) => {
                const rij: IRegel = formatterProps.row;

                if (rij.RelID === null) {
                  return <span></span>;
                }
                const relatie = relaties.find((x) => x.RelID !== null && x.RelID === rij.RelID)!;

                if (relatie.Relatiesoort === 'O') {
                  return <PersoonVisualisatie persID={rij.PersID!} />;
                }
                return <span></span>;
              }}
            /> */}

              <DataTypeProvider
                for={[nameof<IRegel>('DatumUitgenodigd')]}
                formatterComponent={(props) => {
                  const rij: IRegel = props.row;
                  if (rij.DatumUitgenodigd === null) {
                    return <span>Nog uitnodigen</span>;
                  }
                  return <span>{format(new Date(rij.DatumUitgenodigd), 'dd-MM-yyyy HH:mm')}</span>;
                }}
              />

              <DataTypeProvider
                for={[nameof<IRegel>('RecordToegevoegd')]}
                formatterComponent={(formatterProps) => {
                  return <span>{format(new Date(formatterProps.value), 'dd-MM-yyyy HH:mm')}</span>;
                }}
              />

              <DataTypeProvider
                for={['__actieFiatteren']}
                formatterComponent={(formatterProps) => {
                  const row: IRegel = formatterProps.row;
                  return (
                    <span className="ml-2">
                      <a
                        href="#"
                        onClick={async () => {
                          await api.v2.review.toggleFiatStatus({ ids: [row.ID] });
                          ophalenUitnodigingen();
                        }}
                      >
                        Wijzig
                      </a>
                    </span>
                  );
                }}
              />

              <SortingState defaultSorting={[]} />
              <IntegratedSorting />

              <VirtualTable
                messages={{ noData: 'Geen uitnodigingen gevonden voor het ingestelde filter' }}
              />
              <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
              <TableHeaderRow showSortingControls />
              <SelectionState
                selection={urlState.selectie}
                onSelectionChange={(x) => setUrlStateSync('selectie', x as number[])}
              />
              <TableSelection cellComponent={DXTableCheckboxComponent} />
            </Grid>
          </GridStyleWrapper>
        }
      />
      {urlState.versturenDialoogState !== null && (
        <VersturenDialoog
          open
          onSuccess={async (data) => {
            await handleVersturenV2(data.id);
            setUrlStateSync('selectie', []);
            ophalenUitnodigingen();
            setUrlStateSync('versturenDialoogState', null);
          }}
          onAnnuleren={() => {
            setUrlStateSync('versturenDialoogState', null);
          }}
        />
      )}
    </>
  );
};

export default withRouter(Uitnodigingen);
