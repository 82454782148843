import api from '../../index';
import {
  IOphalenVoorraadNieuwParams,
  OphalenVoorraadNieuwResult,
  IOphalenVoorraadGebruiktParams,
  OphalenVoorraadGebruiktResult,
} from '../../../../../shared/src/api/v2/inkoop/voorraad';
import {
  IOphalenVoorraadinfoParams,
  IOphalenVoorraadinfoResult,
  IOphalenStatistischeInfoParams,
  IOphalenStatistischeInfoResult,
  IOphalenProducttypenInMutatiesParams,
  IOphalenProducttypenInMutatiesResult,
  IOphalenVoorraadUitgepaktParams,
  IOphalenVoorraadUitgepaktResult,
} from '../../../../../shared/src/api/v2/voorraad';

const voorraad = {
  ophalenVoorraadNieuw: async (
    params: IOphalenVoorraadNieuwParams,
  ): Promise<OphalenVoorraadNieuwResult> => {
    return await api.post('/v2/voorraad/ophalen-voorraad-nieuw', params);
  },
  ophalenVoorraadGebruikt: async (
    params: IOphalenVoorraadGebruiktParams,
  ): Promise<OphalenVoorraadGebruiktResult> => {
    return await api.post('/v2/voorraad/ophalen-voorraad-gebruikt', params);
  },
  ophalenVoorraadinfo: async (
    params: IOphalenVoorraadinfoParams,
  ): Promise<IOphalenVoorraadinfoResult> => {
    return await api.post('v2/voorraad/ophalen-voorraadinfo', params);
  },
  ophalenStatistischeInfo: async (
    params: IOphalenStatistischeInfoParams,
  ): Promise<IOphalenStatistischeInfoResult> => {
    return await api.post('v2/voorraad/ophalen-statistische-info', params);
  },
  ophalenProducttypenInMutaties: async (
    params: IOphalenProducttypenInMutatiesParams,
  ): Promise<IOphalenProducttypenInMutatiesResult> => {
    return await api.post('v2/voorraad/ophalen-producttypen-in-mutaties', params);
  },
  ophalenVoorraadUitgepakt: async (
    params: IOphalenVoorraadUitgepaktParams,
  ): Promise<IOphalenVoorraadUitgepaktResult> => {
    return await api.post('v2/voorraad/ophalen-voorraad-uitgepakt', params);
  },
};

export default voorraad;
