import React, { useState, useEffect, useCallback, useMemo, useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { LeverancierContext } from '../../../../index';
import {
  IOphalenBezoeksetregelsResultElement,
  IOphalenBezoeksetsResultElement,
} from '../../../../../../../../shared/src/api/v2/transport/bezoek';
import api from '../../../../../../api';
import MenuLayout from '../../../../../../components/MenuLayout';
import { IconToevoegen } from '../../../../../../components/Icons';
import { Kleur } from '../../../../../../bedrijfslogica/constanten';
import LoadingSpinner from '../../../../../../components/Gedeeld/LoadingSpinner';
import {
  DXCommandComponent,
  DXTableToggleCellComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../../../helpers/dxTableGrid';
import { DataTypeProvider, EditingState, RowDetailState } from '@devexpress/dx-react-grid';

import {
  Grid,
  TableHeaderRow,
  VirtualTable,
  TableSelection,
  TableColumnResizing,
  TableRowDetail,
  TableGroupRow,
  TableEditColumn,
} from '@devexpress/dx-react-grid-bootstrap4';
import { format } from 'date-fns';
import nameOf from '../../../../../../core/nameOf';
import DetailComp from './DetailComp';
import _ from 'lodash';

interface IProps extends RouteComponentProps {}

export interface IBezoekset extends IOphalenBezoeksetsResultElement {
  regels: IOphalenBezoeksetregelsResultElement[];
}

const Bezoekschema: React.FC<IProps> = observer((props) => {
  const context = useContext(LeverancierContext);

  const [bezoeksets, setBezoeksets] = useState<IBezoekset[]>([]);

  const ophalenBezoeksets = useCallback(async () => {
    const bezoeksetsResult = await api.v2.transport.bezoek.ophalenBezoeksets({
      filterSchema: {
        filters: [
          {
            naam: 'TRSDIENST_IDS',
            data: [context.transportdienst.data!.ID],
          },
        ],
      },
    });
    const ids = bezoeksetsResult.bezoeksets.map((x) => x.ID);

    const bezoeksetsGesorteerd = _.orderBy(bezoeksetsResult.bezoeksets, ['Ingangsdatum'], ['desc']);

    const regelsResult = (
      await api.v2.transport.bezoek.ophalenBezoeksetregels({
        filterSchema: {
          filters: [
            {
              naam: 'TRSBEZSET_IDS',
              data: ids,
            },
          ],
        },
      })
    ).regels;

    // Sets + regels
    const bezoeksets = bezoeksetsGesorteerd.map((x: any) => {
      const regels = regelsResult
        .filter((r) => r.TrsBezSetID === x.ID)
        .map((x) => {
          return { ...x };
        });

      return { ...x, regels };
    });

    setBezoeksets(bezoeksets);
  }, []);

  useEffect(() => {
    ophalenBezoeksets();
  }, [ophalenBezoeksets]);

  const kolommen = useMemo<TypedColumn<IOphalenBezoeksetsResultElement>[]>(
    () => [
      {
        name: 'Ingangsdatum',
        title: 'Ingangsdatum',
      },
      {
        name: 'Einddatum',
        title: 'Einddatum',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IOphalenBezoeksetsResultElement>[]>(
    () => [
      {
        columnName: 'Ingangsdatum',
        width: 125,
      },
      {
        columnName: 'Einddatum',
        width: 125,
      },
    ],
    [],
  );

  return (
    <>
      <MenuLayout
        // menu={
        //   <div className="d-flex">
        //     <button
        //       className="btn btn-sm btn-light d-flex align-items-center"
        //       style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
        //       onClick={() => null!}
        //     >
        //       <IconToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
        //       <span className="ml-2">Knop</span>
        //     </button>
        //   </div>
        // }
        body={
          <div>
            {bezoeksets === null ? (
              <LoadingSpinner />
            ) : (
              <GridStyleWrapper height="calc(100vh - 125px)">
                <Grid columns={kolommen} rows={bezoeksets || []} getRowId={(x) => x.ID}>
                  <DataTypeProvider
                    for={[nameOf<IOphalenBezoeksetsResultElement>('Ingangsdatum')]}
                    formatterComponent={(formatterProps) => {
                      return <span>{format(new Date(formatterProps.value), 'dd-MM-yyyy')}</span>;
                    }}
                  />

                  <DataTypeProvider
                    for={[nameOf<IOphalenBezoeksetsResultElement>('Einddatum')]}
                    formatterComponent={(formatterProps) => {
                      if (formatterProps.value === null) {
                        return <span></span>;
                      }
                      return <span>{format(new Date(formatterProps.value), 'dd-MM-yyyy')}</span>;
                    }}
                  />

                  <RowDetailState defaultExpandedRowIds={[]} />
                  <VirtualTable estimatedRowHeight={43} messages={{ noData: 'Geen bezoeksets' }} />

                  <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
                  <TableHeaderRow />

                  <EditingState
                    onAddedRowsChange={() => {}}
                    onEditingRowIdsChange={(x) => {
                      const id = x[x.length - 1] as number;
                    }}
                    onCommitChanges={() => null}
                  />
                  {/* <TableEditColumn
                    width={65}
                    // showAddCommand={true}
                    showEditCommand
                    // showDeleteCommand
                    commandComponent={DXCommandComponent}
                  /> */}

                  <TableRowDetail
                    contentComponent={DetailComp}
                    toggleCellComponent={DXTableToggleCellComponent}
                  />

                  {/* <SelectionState
                    selection={urlState.selectie}
                    onSelectionChange={(x) => setUrlStateSync('selectie', x as number[])}
                  />
                  <TableSelection cellComponent={DXTableCheckboxComponent} /> */}
                </Grid>
              </GridStyleWrapper>
            )}
          </div>
        }
      />
    </>
  );
});

export default withRouter(Bezoekschema);
