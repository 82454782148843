import * as React from 'react';
import TegelBasis from '../../../../../../../components/tegel/TegelBasis';
import FormatteerBedrag from '../../../../../../../components/MutatieBedrag';
import { Kleur as EKleur } from '../../../../../../../bedrijfslogica/constanten';
import { IOphalenInformatieVoorOverzichtActieclaims } from '../../../../../../../../../shared/src/api/v2/relatie';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { format } from 'date-fns';
import NavigatiePijl from '../NavigatiePijl';
import { genereerUrlStateQueryParam } from '../../../../../../../core/useUrlState';
import { IUrlState, defaultUrlState } from '../../Klant/KlantWerftKlant';

interface IProps extends RouteComponentProps {
  relID: number;
  data: IOphalenInformatieVoorOverzichtActieclaims;
}

const KwkClaimtegel = (props: IProps) => {
  const kwkClaims = props.data.kwkClaims;

  const maxAantalWeerTeGeven = 3;

  return (
    <TegelBasis titel="Beoordelen Klant-werft-klant actie(s)">
      <div className="d-flex">
        <div>
          <>
            <table>
              <tbody>
                {kwkClaims
                  .filter((x, i) => i < maxAantalWeerTeGeven)
                  .map((claim) => (
                    <tr key={claim.ID}>
                      <td style={{ width: 115 }}>
                        {format(new Date(claim.Claimdatum), 'dd-MM-yyyy')}
                      </td>
                      <td style={{ width: 100 }}>{claim.Wervingscode}</td>

                      <td>
                        <NavigatiePijl
                          relID={props.relID}
                          pathWithStateBuilder={() => {
                            const state = genereerUrlStateQueryParam<IUrlState>({
                              ...defaultUrlState,
                              actieclaimsSelectie: [claim.ID],
                              regelsSelectie: [],
                            });
                            return `klant/klant-werft-klant?state=${state}`;
                          }}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>

            {kwkClaims.length > maxAantalWeerTeGeven && (
              <div className="mt-2">
                <a
                  href="#"
                  onClick={(ev) => {
                    ev.preventDefault();

                    props.history.push(`/klant/${props.relID}/klant/klant-werft-klant`);
                  }}
                >
                  Er zijn nog {kwkClaims.length - maxAantalWeerTeGeven}
                  &nbsp;andere kwk-Claims...
                </a>
              </div>
            )}
          </>
        </div>
      </div>
    </TegelBasis>
  );
};

export default withRouter(KwkClaimtegel);
