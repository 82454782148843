import React, { useCallback, useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Kleur } from '../../../bedrijfslogica/constanten';
import classNames from 'classnames';
import ZoekresultatenWeergave from '../ZoekresultatenWeergave';
import api from '../../../api';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
} from '../../../models/IRemoteData';
import { IVerkennerItem } from '../types';
import { bepalenVerkennerItemsVanMappenEnBestandenResult } from '../bepalenVerkennerItemsVanMappenEnBestanden';

const Root = styled.div<{ pointerEvents: boolean }>`
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex: 1;
  flex-direction: column;
  pointer-events: ${({ pointerEvents }) => (pointerEvents ? 'all' : 'none')};

  .zoekvenster-container {
    transition: all 250ms ease-in;
    background-color: transparent;
    opacity: 0;
  }

  .actief {
    //pointer-events: all;
    background-color: ${Kleur.Wit};
    opacity: 1;
  }
`;

interface IProps {
  isActief: boolean;
  zoekterm: string;
  onItemGekozen: (item: IVerkennerItem) => void;
}

const Zoekvenster = (props: IProps) => {
  const debounceTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [itemsData, setItemsData] = useState<
    IRemoteData<{
      totaalAantal: number;
      items: Record<string, IVerkennerItem>;
    }>
  >(createPendingRemoteData());
  const [isAanHetZoeken, setIsAanHetZoeken] = useState(false);

  const zoeken = useCallback(async () => {
    setIsAanHetZoeken(true);

    const paginatie = {
      index: 0,
      aantal: 200,
    };
    const bestandenEnMappenResult = await api.v2.bestand.ophalenBestandenEnMappen({
      filterSchema: {
        filters: [
          {
            naam: 'NAAM_UITGEBREID',
            data: {
              precisie: 'ONGEVEER',
              waarde: props.zoekterm,
            },
          },
        ],
      },
      paginatie,
    });
    const result = await bepalenVerkennerItemsVanMappenEnBestandenResult(
      bestandenEnMappenResult,
      paginatie,
      {},
    );
    setItemsData(createReadyRemoteData(result));

    setIsAanHetZoeken(false);
  }, [props.zoekterm]);

  useEffect(() => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }
    if (props.zoekterm.trim().length === 0) {
      setItemsData(createPendingRemoteData());
      return;
    }
    debounceTimeoutRef.current = setTimeout(zoeken, 500);
  }, [props.zoekterm, zoeken]);

  return (
    <Root pointerEvents={props.isActief}>
      <div
        className={classNames([
          'd-flex flex-column flex-fill pt-2 pb-2 zoekvenster-container',
          props.isActief && 'actief',
        ])}
      >
        <h2 className="ml-4 mt-2">Zoekresultaten</h2>
        <ZoekresultatenWeergave itemsData={itemsData} onItemGekozen={props.onItemGekozen} />
      </div>
    </Root>
  );
};

export default Zoekvenster;
