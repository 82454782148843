import styled from 'styled-components';
import { Kleur } from '../../../../bedrijfslogica/constanten';

export const Root = styled.div`
  padding: 10px 20px;
  cursor: pointer;
  &:hover {
    background-color: ${Kleur.DoorschijnendLichtBlauw};
  }
`;
