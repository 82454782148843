import React, { useMemo } from 'react';
import { IDataFormulierProps } from '../index';
import RelatieSelectieV2, { ERelatieSelectieKolom } from '../../../../formulier/RelatieSelectieV2';
import { Provider } from '../../../../formulier/MultiComboboxV2';
import { IOphalenRelatiesResultElementV2 } from '../../../../../../../shared/src/api/v2/relatie';
import api from '../../../../../api';

const RelatieDataFormulier = (props: IDataFormulierProps<{ relID: number | null }>) => {
  const provider = useMemo<Provider<ERelatieSelectieKolom, IOphalenRelatiesResultElementV2>>(
    () => async (params) => {
      const result = await api.v2.relatie.ophalenRelaties({
        paginatie: params.paginatie,
        filterSchema: {},
      });
      const items = result.relaties.reduce(
        (acc, curr, idx) => ({
          ...acc,
          [params.paginatie.index + idx]: curr,
        }),
        params.huidigeBron,
      );

      return {
        items,
        totaalAantal: result.totaalAantal,
      };
    },
    [],
  );

  return (
    <div className="mt-3">
      <label>Relatie</label>
      <RelatieSelectieV2
        relID={props.data.data.relID}
        onChange={(relID) => props.onDataChange({ data: { relID }, isGeldig: relID !== null })}
        provider={provider}
        dialoogIndex={props.dialoogIndex + 1}
      />
    </div>
  );
};

export default RelatieDataFormulier;
