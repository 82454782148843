import {
  IMarkerenOpdrachtenUitstaandParams,
  IMarkerenOpdrachtenUitstaandResult,
} from '../../../../../../shared/src/api/v2/transport/opdracht/afgehandeld';
import ICheckData from '../../../../../../shared/src/models/ICheckData';
import api from '../../../index';

const afgehandeld = {
  checkMarkerenOpdrachtenUitstaand: async (
    params: IMarkerenOpdrachtenUitstaandParams,
  ): Promise<ICheckData> => {
    return await api.post(
      'v2/transport/opdracht/afgehandeld/check-markeren-opdrachten-uitstaand',
      params,
    );
  },
  markerenOpdrachtenUitstaand: async (
    params: IMarkerenOpdrachtenUitstaandParams,
  ): Promise<IMarkerenOpdrachtenUitstaandResult> => {
    return await api.post(
      'v2/transport/opdracht/afgehandeld/markeren-opdrachten-uitstaand',
      params,
    );
  },
};

export default afgehandeld;
