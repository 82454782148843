import styled from 'styled-components';
import { Kleur } from '../../../../bedrijfslogica/constanten';

export const Root = styled.div`
  background: ${Kleur.Wit};
  height: calc(100vh - 40px);
  //max-width: 95%;
  //margin: 20px auto;
  //box-shadow: rgba(14, 14, 14, 0.27) 0 8px 20px -10px;
  //border-radius: 7px;
`;
