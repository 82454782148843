import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import IDialoogProps from '../../../core/IDialoogProps';
import Dialoog from '../../../components/dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import * as Yup from 'yup';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../stores/RootStore';
import { EResultType } from '../../../stores/CheckStore';
import api from '../../../api';
import Combobox from '../../../components/formulier/Combobox';
import nameOf from '../../../core/nameOf';
import { IOphalenOpdrachtwijzenResultElement } from '../../../../../shared/src/api/v2/dienst';
import { IOphalenDienstenResultElement } from '../../../../../shared/src/api/v2/dienst/inkoop';
import { IOphalenMagazijnenResultElement } from '../../../../../shared/src/api/v2/magazijn';
import LoadingSpinner from '../../../components/Gedeeld/LoadingSpinner';
import { Kleur } from '../../../bedrijfslogica/constanten';
import { IInitVersturenOpdrachtenResult } from '../../../../../shared/src/api/v2/inkoopopdracht';

interface IDialoogResult {}

interface IProps extends IDialoogProps<IDialoogResult> {
  inkOpdIDs: number[];
}

interface IFormikValues {
  opdrachtwijze: number | null;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  opdrachtwijze: 'Opdrachtwijze',
};

const VersturenDialoog: React.FC<IProps> = observer((props) => {
  const { dialoogIndex, onAnnuleren, onSuccess, open, inkOpdIDs } = props;
  const { checkStore } = useContext(RootStoreContext);
  const [opdrachtwijzen, setOpdrachtwijzen] = useState<
    IOphalenOpdrachtwijzenResultElement[] | null
  >(null);
  const [dienst, setDienst] = useState<IOphalenDienstenResultElement | null>(null);
  const [magazijn, setMagazijn] = useState<IOphalenMagazijnenResultElement | null>(null);

  const [initData, setInitData] = useState<IInitVersturenOpdrachtenResult | null>(null);

  useEffect(() => {
    (async () => {
      const result = await api.v2.inkoop.opdracht.initVersturenOpdrachten({
        inkOpdIDs,
      });

      const { diensten } = await api.v2.dienst.inkoop.ophalenDiensten({
        filterSchema: { filters: [{ naam: 'IDS', data: [result.InkDienstID] }] },
      });
      const dienst = diensten[0];

      const opdrachtwijzenVoorDienstResult = await api.v2.dienst.ophalenOpdrachtwijzenVoorDienst({
        filterSchema: { filters: [{ naam: 'INKDIENST_IDS', data: [dienst.ID] }] },
      });
      const opdrachtwijzenVoorDienstIDs = opdrachtwijzenVoorDienstResult.opdrachtwijzen.map(
        (x) => x.OpdWijzeID,
      );

      if (dienst.opdrachtwijze !== null) {
        // Het kan zijn dat de opdrachtwijze van de dienst niet in de lijst van opdrachtwijzen voor de dienst zit.
        opdrachtwijzenVoorDienstIDs.push(dienst.opdrachtwijze!.OpdWijzeID);
      }

      const opdrachtwijzenResult = await api.v2.dienst.ophalenOpdrachtwijzen({
        filterSchema: { filters: [{ naam: 'IDS', data: opdrachtwijzenVoorDienstIDs }] },
      });
      const { magazijnen } = await api.v2.magazijn.ophalenMagazijnen({
        filterSchema: { filters: [{ naam: 'IDS', data: [result.MagID] }] },
      });
      const magazijn = magazijnen[0];

      setDienst(dienst);
      setOpdrachtwijzen(opdrachtwijzenResult.opdrachtwijzen);
      setMagazijn(magazijn);
      setInitData(result);
    })();
  }, [inkOpdIDs]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      if (values.opdrachtwijze === null) {
        actions.setSubmitting(false);
        return;
      }

      const params = {
        inkOpdIDs,
        opdWijzeID: values.opdrachtwijze!,
      };
      const checkData = await api.v2.inkoop.opdracht.checkVersturenOpdrachten(params);

      const checkResult = await checkStore.controleren({
        checkData,
      });
      if (checkResult.type === EResultType.Annuleren) {
        actions.setSubmitting(false);
        return;
      }

      if (
        (
          await checkStore.bevestigen({
            inhoud: `Opdracht(en) versturen?`,
          })
        ).type === EResultType.Annuleren
      ) {
        actions.setSubmitting(false);
        return;
      }

      await api.v2.inkoop.opdracht.versturenOpdrachten(params);

      props.onSuccess({});
      actions.setSubmitting(false);
    },
    [],
  );

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (dienst === null) {
      return null;
    }

    return {
      opdrachtwijze: dienst.OpdWijzeID!,
    };
  }, [dienst]);

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      opdrachtwijze: Yup.number().required(),
    });
  }, []);

  return (
    <>
      {initialValues === null || dienst === null || magazijn === null || opdrachtwijzen === null ? (
        <LoadingSpinner />
      ) : (
        <Dialoog index={dialoogIndex || 0}>
          <ModalHeader>
            <ModalTitle>Versturen opdrachten</ModalTitle>
          </ModalHeader>
          <Formik<IFormikValues>
            onSubmit={handleSubmit}
            initialValues={initialValues}
            validationSchema={validationSchema}
            isInitialValid
            render={(formikProps: FormikProps<IFormikValues>) => {
              const { submitForm, isValid, isSubmitting } = formikProps;

              return (
                <>
                  <ModalBody>
                    <div className="form-group">
                      <div className="row">
                        <div className="col-12 d-flex flex-column">
                          <label>Leverancier / magazijn</label>
                          <div
                            className="flex-fill"
                            style={{
                              border: `1px solid ${Kleur.LichtGrijs}`,
                              padding: '4px 8px',
                              borderRadius: 3,
                            }}
                          >
                            {initData === null ? (
                              <LoadingSpinner />
                            ) : (
                              dienst.relatie!.weergavenaam + ' | ' + magazijn.NaamKort
                            )}
                          </div>
                        </div>

                        <div className="col-12 mt-3">
                          <label>{veldnamen.opdrachtwijze}</label>
                          <Field
                            name={nameOf<IFormikValues>('opdrachtwijze')}
                            render={(fieldProps: FieldProps<IFormikValues>) => {
                              const { field, form } = fieldProps;

                              if (opdrachtwijzen === null) {
                                return <LoadingSpinner />;
                              }

                              return (
                                <Combobox
                                  geselecteerd={field.value}
                                  opties={opdrachtwijzen!.map((opdrachtwijze) => {
                                    return {
                                      id: opdrachtwijze.OpdWijzeID,
                                      label: opdrachtwijze.Naam,
                                    };
                                  })}
                                  onSelectieChange={(x) => form.setFieldValue(field.name, x)}
                                />
                              );
                            }}
                          />
                        </div>
                        {/* <div className="col-12 mt-3">
                          {dienst.PortalURL !== null ? (
                            <span>
                              Er is een URL voor een PORTAL opgegeven:
                              <br />
                              <a href={dienst.PortalURL} target="_blank">
                                {dienst.PortalURL}
                              </a>
                            </span>
                          ) : (
                            ''
                          )}
                        </div> */}
                      </div>
                    </div>
                  </ModalBody>
                  <ModalFooter className="d-flex flex-row justify-content-start">
                    <button
                      className="btn btn-primary"
                      onClick={submitForm}
                      style={{ width: 100 }}
                      disabled={!isValid || isSubmitting}
                    >
                      Ok
                    </button>
                    <button
                      className="btn btn-secondary"
                      onClick={onAnnuleren}
                      style={{ width: 100 }}
                      disabled={isSubmitting}
                    >
                      Annuleren
                    </button>
                  </ModalFooter>
                </>
              );
            }}
          />
        </Dialoog>
      )}
    </>
  );
});

export default VersturenDialoog;
