import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import api from '../../../../../api';
import Modal from 'react-bootstrap/Modal';
import IDialoogProps from '../../../../../core/IDialoogProps';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { Field, FieldProps, Formik, FormikActions, FormikErrors, FormikProps } from 'formik';
import { RootStoreContext } from '../../../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import { EResultType } from '../../../../../stores/CheckStore';
import * as Yup from 'yup';
import teksten from '../../../../../bedrijfslogica/teksten';
import BankrekeningSelectieCombobox from '../../../../../components/formulier/BankrekeningSelectieCombobox';
import { IOphalenFacturenResultElement } from '../../../../../../../shared/src/api/v2/inkoopfactuur';
import _ from 'lodash';
import FormatteerBedrag from '../../../../../components/MutatieBedrag';
import Dialoog from '../../../../../components/dialogen/Dialoog';
import DatumKiezer from '../../../../../components/formulier/DatumKiezer';
import { dagDatum } from '../../../../../helpers/datum';
import nameof from '../../../../../core/nameOf';
import VinkVeld from '../../../../../components/formulier/VinkVeld';
import { format } from 'date-fns';
import VeldWeergave from '../../../../../components/formulier/VeldWeergave';
import { IOphalenRekeningenResultElement } from '../../../../../../../shared/src/api/v2/bank/bank';
import LoadingSpinner from '../../../../../components/Gedeeld/LoadingSpinner';

interface IFormikValues {
  bankRekID: number | null;
  betaaldatum: Date | null;
  kortingToepassen: boolean;
}

interface IProps extends IDialoogProps<null> {
  inkFactIDs: number[];
  bankRekID: number | null;
}

const veldnamen = {
  bankRekID: 'Bankrekening',
  betaaldatum: 'Datum betaling',
  kortingToepassen: 'Betalingskorting toepassen',
};

const BetaalopdrachtDialoog = observer((props: IProps) => {
  const { onAnnuleren, onSuccess, open, inkFactIDs } = props;
  const { overdrachtStore, checkStore } = useContext(RootStoreContext);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const formikRef = useRef<Formik<IFormikValues>>(null);

  const [bankRekID, setBankRekID] = useState<number | null>(props.bankRekID);
  const [facturen, setFacturen] = useState<IOphalenFacturenResultElement[] | null>(null);
  const [betaaldatum, setBetaaldatum] = useState<Date | null>(dagDatum(new Date()));
  const [totaalOpenstaand, setTotaalOpenstaand] = useState<number>(0);
  const [totaalKorting, setTotaalKorting] = useState<number | null>(null);
  const [rekening, setRekening] = useState<IOphalenRekeningenResultElement | null>(null);

  const ophalenFacturen = useCallback(async () => {
    const facturenResultaat = await api.v2.inkoopfactuur.ophalenFacturen({
      filterSchema: { filters: [{ naam: 'IDS', data: inkFactIDs }] },
    });
    const facturen = facturenResultaat.facturen;

    // Bereken het totale bedrag van de facturen
    const totaalOpenstaand = _.sum(facturen.map((x) => x.Openstaand));

    setFacturen(facturen);
    setTotaalOpenstaand(totaalOpenstaand);
  }, []);

  useEffect(() => {
    ophalenFacturen();
  }, [ophalenFacturen]);

  const ophalenBedragKorting = useCallback(async () => {
    if (facturen === null || betaaldatum === null) {
      return;
    }

    const totaalKorting = _.sum(
      facturen
        .filter(
          (x) =>
            x.VervaldatumKorting !== null &&
            format(betaaldatum, 'yyyy-MM-dd') <
              format(new Date(x.VervaldatumKorting), 'yyyy-MM-dd'),
        )
        .map((x) => x.BedragKorting),
    );

    setTotaalKorting(totaalKorting);
  }, [facturen, betaaldatum]);

  useEffect(() => {
    ophalenBedragKorting();
  }, [ophalenBedragKorting]);

  const ophalenRekening = useCallback(async () => {
    if (bankRekID === null) {
      return;
    }

    const rekening = await api.v2.bank.ophalenRekeningen({
      filterSchema: { filters: [{ naam: 'IDS', data: [bankRekID] }] },
    });

    setRekening(rekening.rekeningen[0]);
  }, [bankRekID]);

  useEffect(() => {
    ophalenRekening();
  }, [ophalenRekening]);

  const [laatsteInleesdatum, setLaatsteInleesdatum] = useState<Date | null>(null);

  const bepalenLaatsteInleesdatum = useCallback(async () => {
    if (bankRekID === null) {
      return;
    }
    const result = await api.v2.bank.mutatie.bepalenLaatsteInleesdatum({
      bankRekIDs: [bankRekID],
    });

    setLaatsteInleesdatum(result.laatsteInleesdatum);
  }, [bankRekID]);

  useEffect(() => {
    bepalenLaatsteInleesdatum();
  }, [bepalenLaatsteInleesdatum]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      if (bankRekID === null) {
        return;
      }

      const params = {
        inkFactIDs,
        bankRekID,
        betaaldatum: values.betaaldatum!,
        kortingAltijdToepassen: values.kortingToepassen,
      };
      const checkData = await api.v2.inkoopfactuur.checkBetaalopdrachtInkoopfacturen(params);

      if (
        (
          await checkStore.controleren({
            checkData,
          })
        ).type === EResultType.Annuleren
      ) {
        actions.setSubmitting(false);
        return;
      }

      if (
        (
          await checkStore.bevestigen({
            inhoud: 'Betaalopdracht maken?',
          })
        ).type === EResultType.Annuleren
      ) {
        actions.setSubmitting(false);
        return;
      }

      const result = await api.v2.inkoopfactuur.betaalopdrachtInkoopfacturen(params);

      onSuccess(null);

      actions.setSubmitting(false);
    },
    [onSuccess, inkFactIDs, bankRekID],
  );

  const initieleWaarden = useMemo<IFormikValues>(() => {
    return {
      bankRekID: null,
      betaaldatum,
      kortingToepassen: true,
    };
  }, [betaaldatum]);

  const validatie = useCallback((values: IFormikValues): FormikErrors<IFormikValues> => {
    const errors: FormikErrors<IFormikValues> = {};

    return errors;
  }, []);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        bankRekID: Yup.number().required(
          teksten.formulier.E_VERPLICHT_VELD({
            veldnaam: veldnamen.bankRekID,
          }),
        ),
      }),
    [],
  );

  return (
    <Dialoog index={props.dialoogIndex || 0} modalProps={{ size: 'lg' }}>
      <ModalHeader>
        <ModalTitle>Betaalopdracht</ModalTitle>
      </ModalHeader>
      {bankRekID === null ||
      facturen === null ||
      rekening === null ||
      laatsteInleesdatum === null ? (
        <div className="flex-fill d-flex align-items-center justify-content-center m-5">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          <Formik<IFormikValues>
            ref={formikRef}
            isInitialValid
            onSubmit={handleSubmit}
            initialValues={initieleWaarden}
            // validationSchema={validationSchema}
            validateOnChange
            validateOnBlur
            render={(formikProps: FormikProps<IFormikValues>) => {
              const { submitForm, isSubmitting, values, isValid } = formikProps;
              return (
                <>
                  <ModalBody style={{ minHeight: 375 }}>
                    <div className="form-group">
                      <div className="row">
                        <div className="col-12">
                          <label>{veldnamen.bankRekID}</label>
                          <Field
                            name="bankRekID"
                            render={(fieldProps: FieldProps<IFormikValues>) => {
                              return (
                                <>
                                  <BankrekeningSelectieCombobox
                                    filters={[{ naam: 'IS_ACTIEF', data: true }]}
                                    bankRekID={bankRekID}
                                    onChange={(x) => {
                                      setBankRekID(x);
                                      return fieldProps.form.setFieldValue(
                                        fieldProps.field.name,
                                        x,
                                      );
                                    }}
                                  />
                                </>
                              );
                            }}
                          />
                        </div>

                        <div className="col-12 mt-3">
                          <div className="row">
                            <div className="col-4">
                              <label>Beschikbaar saldo</label>
                              <VeldWeergave>
                                <FormatteerBedrag bedrag={rekening.Saldo} rechtsUitlijnen={false} />
                              </VeldWeergave>
                            </div>

                            {totaalKorting !== null && (
                              <div className="col-4">
                                <label>Ingelezen t/m</label>
                                <VeldWeergave>
                                  {format(new Date(laatsteInleesdatum), 'dd-MM-yyyy')}
                                </VeldWeergave>
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="col-12 mt-3">
                          <label>{veldnamen.betaaldatum}</label>
                          <Field
                            name="betaaldatum"
                            render={(fieldProps: FieldProps<IFormikValues>) => {
                              const { field, form } = fieldProps;
                              return (
                                <DatumKiezer
                                  waarde={field.value}
                                  onGewijzigd={(x) => {
                                    setBetaaldatum(x);
                                    return form.setFieldValue(field.name, x);
                                  }}
                                />
                              );
                            }}
                          />
                        </div>

                        <div className="col-12 mt-3">
                          <div className="row">
                            <div className="col-4">
                              <label>Totaal te betalen</label>
                              <VeldWeergave>
                                <FormatteerBedrag
                                  bedrag={totaalOpenstaand}
                                  rechtsUitlijnen={false}
                                />
                              </VeldWeergave>
                            </div>

                            {totaalKorting !== null && (
                              <div className="col-4">
                                <label>Kortingsbedrag</label>
                                <VeldWeergave>
                                  <FormatteerBedrag
                                    bedrag={totaalKorting}
                                    rechtsUitlijnen={false}
                                  />
                                </VeldWeergave>
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="col-12 mt-4">
                          <Field
                            name={nameof<IFormikValues>('kortingToepassen')}
                            render={(fieldProps: FieldProps<IFormikValues>) => {
                              const { field, form } = fieldProps;

                              return (
                                <>
                                  <div className="d-flex align-items-center">
                                    <VinkVeld
                                      aangevinkt={field.value}
                                      onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                    />
                                    <span className="ml-2">{veldnamen.kortingToepassen}</span>
                                  </div>
                                </>
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </ModalBody>
                  <ModalFooter className="d-flex justify-content-start">
                    <button
                      className="btn btn-primary"
                      onClick={submitForm}
                      disabled={isSubmitting || !isValid}
                      style={{ width: 100 }}
                    >
                      Ok
                    </button>
                    <button
                      className="btn btn-secondary"
                      onClick={onAnnuleren}
                      style={{ width: 100 }}
                    >
                      Annuleren
                    </button>
                  </ModalFooter>
                </>
              );
            }}
          />
        </>
      )}
    </Dialoog>
  );
});

export default BetaalopdrachtDialoog;
