import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import IDialoogProps from '../../../../core/IDialoogProps';
import Dialoog from '../../../dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../models/IRemoteData';
import api from '../../../../api';
import {
  IBestandslabel,
  IOphalenBestandslabelsResult,
} from '../../../../../../shared/src/api/v2/bestand/label';
import ASPTabel from '../../../tabel/ASPTabel';
import {
  ASPKolom,
  EAspKolomBreedteType,
  ESortering,
  IAspKolomSorteringItem,
} from '../../../tabel/ASPTabel/types';
import UitlegTooltip from '../../../formulier/UitlegTooltip';
import { IOrderSchemaOrder } from '../../../../../../shared/src/models/order';
import { IFilterSchemaUitgebreideFilter } from '../../../../../../shared/src/models/filter';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../../stores/RootStore';
import { EResultType } from '../../../../stores/CheckStore';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import { IconToevoegen } from '../../../Icons';
import LabelMuterenDialoog from './LabelMuterenDialoog';

enum EKolom {
  Kleur,
  Naam,
  Relatie,
}

interface ILabelMuterenDialoogState {
  bestandslabelID: number | null;
}

export interface IDialoogResult {
  bestandslabelIDs: number[];
}

interface IProps extends IDialoogProps<IDialoogResult> {
  bestandslabelIDs?: number[];
  relID?: number;
}

const LabelSelectieDialoog = observer((props: IProps) => {
  const { checkStore } = useContext(RootStoreContext);

  const [isAanHetSubmitten, setIsAanHetSubmitten] = useState(false);
  const [
    labelMuterenDialoogState,
    setLabelMuterenDialoogState,
  ] = useState<ILabelMuterenDialoogState | null>(null);

  const [labelsResult, setLabelsResult] = useState<IRemoteData<IOphalenBestandslabelsResult>>(
    createPendingRemoteData(),
  );
  const [selectie, setSelectie] = useState<number[]>(props.bestandslabelIDs ?? []);
  const [sortering, setSortering] = useState<IAspKolomSorteringItem<EKolom>[]>([
    {
      key: EKolom.Relatie,
      sortering: ESortering.Descending,
    },
    {
      key: EKolom.Naam,
      sortering: ESortering.Ascending,
    },
  ]);

  const ophalenBestandslabels = useCallback(async () => {
    const result = await api.v2.bestand.label.ophalenBestandslabels({
      filterSchema: {
        uitgebreideFilter: {
          or: [
            {
              filter: {
                naam: 'REL_ID',
                data: null,
              },
            },
            props.relID === undefined
              ? null
              : {
                  filter: {
                    naam: 'REL_ID',
                    data: props.relID,
                  },
                },
          ].filter((x) => x !== null) as IFilterSchemaUitgebreideFilter[],
        },
      },
      orderSchema: {
        orders: sortering.map((s) => {
          switch (s.key) {
            case EKolom.Kleur: {
              return {
                naam: 'KLEURCODE',
                richting: s.sortering,
              };
            }
            case EKolom.Naam: {
              return {
                naam: 'NAAM',
                richting: s.sortering,
              };
            }
            case EKolom.Relatie: {
              return {
                naam: 'REL_ID',
                richting: s.sortering,
              };
            }
          }
        }) as IOrderSchemaOrder[],
      },
    });
    setLabelsResult(createReadyRemoteData(result));
    return result;
  }, [props.relID, sortering]);

  useEffect(() => {
    ophalenBestandslabels();
  }, [ophalenBestandslabels]);

  const keyExtractor = useCallback((item: IBestandslabel) => item.ID, []);
  const rijen = useMemo<Record<number, IBestandslabel>>(() => {
    if (labelsResult.state === ERemoteDataState.Pending) {
      return {};
    }
    return labelsResult.data!.labels.reduce((acc, label, i) => {
      return {
        ...acc,
        [i]: label,
      };
    }, {});
  }, [labelsResult]);

  const kolommen = useMemo<ASPKolom<EKolom, IBestandslabel>[]>(
    () => [
      {
        key: EKolom.Kleur,
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 30,
        renderer: (item: IBestandslabel) => (
          <div
            style={{
              width: 15,
              height: 15,
              borderRadius: '50%',
              backgroundColor: item.Kleurcode ?? '#FFF',
              position: 'relative',
              top: 1,
              border: `1px solid ${Kleur.LichtGrijs}`,
            }}
          />
        ),
        // tdComponent: (tdProps) => <TableData {...tdProps} style={{ padding: '0 0 0 3px' }} />,
      },
      {
        key: EKolom.Naam,
        label: 'Naam',
        breedteType: EAspKolomBreedteType.Flex,
        flex: 1,
        renderer: (item: IBestandslabel) => item.Naam,
      },
      {
        key: EKolom.Relatie,
        label: 'Relatie',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 120,
        renderer: (item: IBestandslabel) =>
          item.RelID === null ? null : (
            <UitlegTooltip inhoud="Dit label wordt exclusief voor deze relatie gebruikt">
              <span className="text-muted" style={{ fontSize: 12 }}>
                EXCLUSIEF
              </span>
            </UitlegTooltip>
          ),
      },
    ],
    [props.onSuccess],
  );

  const handleWijzigenRij = useCallback(
    async (rij: IBestandslabel) => {
      setLabelMuterenDialoogState({ bestandslabelID: rij.ID });
    },
    [setLabelMuterenDialoogState],
  );
  const handleVerwijderenRij = useCallback(
    async (rij: IBestandslabel) => {
      await api.v2.bestand.label.verwijderenBestandslabels({
        bestandlabelIDs: [rij.ID],
      });
      await ophalenBestandslabels();
    },
    [ophalenBestandslabels],
  );

  const handleVerwijderenRijConfirmatie = useCallback(async (rij: IBestandslabel, verwijderen) => {
    const checkData = await api.v2.bestand.label.checkVerwijderenBestandslabels({
      bestandlabelIDs: [rij.ID],
    });

    const controlerenResult = await checkStore.controleren({ checkData });
    if (controlerenResult.type === EResultType.Annuleren) {
      return;
    }

    if (controlerenResult.heeftGebruikersinteractieGehad) {
      await verwijderen();
      return;
    }
    await checkStore.bevestigen({
      inhoud: `Confirmatie verwijderen label "${rij.Naam}"`,
      asynchroneActieNaBevestigingFn: verwijderen,
    });
  }, []);

  const handleSubmit = useCallback(async () => {
    setIsAanHetSubmitten(true);
    await props.onSuccess({
      bestandslabelIDs: selectie,
    });
    setIsAanHetSubmitten(false);
  }, [selectie, setIsAanHetSubmitten]);

  return (
    <>
      <Dialoog index={props.dialoogIndex ?? 0}>
        <ModalHeader>
          <ModalTitle>Selecteer labels</ModalTitle>
        </ModalHeader>
        <ModalBody style={{ padding: 0 }}>
          <div className="pl-3 pr-3 pt-2 pb-2">
            <button
              className="btn btn-sm btn-light d-flex align-items-center"
              style={{
                border: `1px solid ${Kleur.LichtGrijs}`,
              }}
              onClick={() => setLabelMuterenDialoogState({ bestandslabelID: null })}
            >
              <IconToevoegen style={{ fill: Kleur.Grijs, width: 18, height: 18 }} />
              <span className="ml-2">Label toevoegen</span>
            </button>
          </div>
          <div className="d-flex flex-fill w-100" style={{ height: 600 }}>
            <ASPTabel
              rijen={rijen}
              kolommen={kolommen}
              keyExtractor={keyExtractor}
              totaalAantalRijen={
                labelsResult.state === ERemoteDataState.Pending ? 10 : Object.keys(rijen).length
              }
              selectie={selectie}
              onSelectieChange={setSelectie}
              sortering={sortering}
              onSorteringChange={setSortering}
              onWijzigenRij={handleWijzigenRij}
              onVerwijderenRij={handleVerwijderenRij}
              verwijderenRijConfirmatie={handleVerwijderenRijConfirmatie}
            />
          </div>
        </ModalBody>
        <ModalFooter className="d-flex flex-row justify-content-start">
          <button
            className="btn btn-primary d-flex align-items-center justify-content-center"
            onClick={handleSubmit}
            style={{ width: 100 }}
            disabled={isAanHetSubmitten}
          >
            Ok
          </button>
          <button
            className="btn btn-secondary"
            onClick={props.onAnnuleren}
            style={{ width: 100 }}
            disabled={isAanHetSubmitten}
          >
            Annuleren
          </button>
        </ModalFooter>
      </Dialoog>
      {labelMuterenDialoogState !== null && (
        <LabelMuterenDialoog
          open
          onSuccess={async (result) => {
            await ophalenBestandslabels();
            // Bij het toevoegen van een label wordt de selectie automatisch uitgebreid met de nieuwe label.
            if (labelMuterenDialoogState!.bestandslabelID === null) {
              setSelectie((selectie) => [...selectie, result.bestandslabel.ID]);
            }
            setLabelMuterenDialoogState(null);
          }}
          onAnnuleren={() => setLabelMuterenDialoogState(null)}
          dialoogIndex={(props.dialoogIndex ?? 0) + 1}
          bestandslabelID={labelMuterenDialoogState.bestandslabelID ?? undefined}
          relID={props.relID}
        />
      )}
    </>
  );
});

export default LabelSelectieDialoog;
