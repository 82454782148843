import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import IDialoogProps from '../../../../core/IDialoogProps';
import Dialoog from '../../../dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import * as Yup from 'yup';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../../stores/RootStore';
import Combobox from '../../../formulier/Combobox';
import nameof from '../../../../core/nameOf';
import { IOphalenBetaalwijzenResultElement } from '../../../../../../shared/src/api/v2/betaalwijze';
import {
  IOphalenBetalingsregelingenResultElement,
  IOphalenStatussenResultElement,
} from '../../../../../../shared/src/api/v2/debiteur/betalingsregeling';

import api from '../../../../api';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import LoadingSpinner from '../../../Gedeeld/LoadingSpinner';
import { EResultType } from '../../../../stores/CheckStore';
import DatumKiezer from '../../../formulier/DatumKiezer';
import { dagDatum } from '../../../../helpers/datum';
import BedragInput from '../../../formulier/BedragInput';
import { addDays } from 'date-fns';
import VinkVeld from '../../../formulier/VinkVeld';
import { EBetaalwijze } from '../../../../bedrijfslogica/enums';

interface IDialoogResult {}

interface IProps extends IDialoogProps<IDialoogResult> {
  betRglID: number;
}

interface IFormikValues {
  betwijzeID: number | null;
  termijnbedrag: number;
  eersteVervaldatum: Date;
  status: number;
  afgehandeld: boolean;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  betwijzeID: 'Betaalwijze',
  termijnbedrag: 'Termijnbedrag',
  eersteVervaldatum: 'Eerste vervaldatum',
  status: 'Status',
  afgehandeld: 'Afgehandeld',
};

const WijzigenDialoog: React.FC<IProps> = observer((props) => {
  const { children, dialoogIndex, onAnnuleren, onSuccess, open, betRglID } = props;
  const { checkStore } = useContext(RootStoreContext);
  const formikRef = useRef<Formik<IFormikValues>>(null);

  const [aantalTermijnen, setAantalTermijnen] = useState<number>(0);
  const [betaalwijzen, setBetaalwijzen] = useState<IOphalenBetaalwijzenResultElement[] | null>(
    null,
  );
  const [
    betalingsregeling,
    setBetalingsregeling,
  ] = useState<IOphalenBetalingsregelingenResultElement | null>(null);

  useEffect(() => {
    (async () => {
      const betalingsregelingenResult = await api.v2.debiteur.betalingsregeling.ophalenBetalingsregelingen(
        {
          filterSchema: { filters: [{ naam: 'IDS', data: [props.betRglID] }] },
        },
      );
      const betalingsregeling = betalingsregelingenResult.betalingsregelingen[0];
      setBetalingsregeling(betalingsregeling);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const result = await api.v2.betaalwijze.ophalenBetaalwijzen({
        filterSchema: { filters: [] },
      });

      setBetaalwijzen(result.betaalwijzen);
    })();
  }, []);

  const [statussen, setStatussen] = useState<IOphalenStatussenResultElement[] | null>(null);
  const ophalenStatussen = useCallback(async () => {
    const result = await api.v2.debiteur.betalingsregeling.ophalenStatussen({});
    setStatussen(result.statussen);
  }, []);

  useEffect(() => {
    ophalenStatussen();
  }, []);

  // useEffect(() => {
  //   (async () => {
  //
  //     if (betalingsregeling === null || formikRef.current === null) {
  //       return;
  //     }

  //     const termijnbedrag = formikRef.current.getFormikBag().values.termijnbedrag;
  //     const aantalTermijnen = Math.ceil(betalingsregeling.Totaalbedrag / termijnbedrag);

  //     setAantalTermijnen(betalingsregeling.Totaalbedrag / aantalTermijnen);
  //   })();
  // }, [betalingsregeling, formikRef.current]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      const termijnbedrag = values.termijnbedrag;
      const aantalTermijnen = Math.ceil(betalingsregeling!.Totaalbedrag / termijnbedrag);

      const params = {
        id: props.betRglID,
        termijnbedrag: values.termijnbedrag,
        betWijzeID: values.betwijzeID!,
        eersteVervaldatum: values.eersteVervaldatum,
        aantalTermijnen,
        status: values.status,
        afgehandeld: values.afgehandeld,
      };

      const checkData = await api.v2.debiteur.betalingsregeling.checkWijzigenBetalingsregeling(
        params,
      );
      const checkResult = await checkStore.controleren({
        checkData,
      });
      if (checkResult.type === EResultType.Annuleren) {
        actions.setSubmitting(false);
        return;
      }

      await api.v2.debiteur.betalingsregeling.wijzigenBetalingsregeling(params);

      props.onSuccess({});
      actions.setSubmitting(false);
    },
    [betalingsregeling],
  );

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (betaalwijzen === null || betalingsregeling === null) {
      return null;
    }

    return {
      betwijzeID: betalingsregeling.BetWijzeID,
      termijnbedrag: betalingsregeling.Termijnbedrag,
      eersteVervaldatum: dagDatum(new Date(betalingsregeling.EersteVervaldatum)),
      aantalTermijnen: betalingsregeling.AantalTermijnen,
      status: betalingsregeling.Status,
      afgehandeld: betalingsregeling.Afgehandeld,
    };
  }, [betaalwijzen, betalingsregeling]);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        betwijzeID: Yup.number().required(),
        eersteVervaldatum: Yup.date().required(),
      }),
    [],
  );

  return (
    <Dialoog index={dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Wijzigen betalingsregeling</ModalTitle>
      </ModalHeader>
      {initialValues !== null && betaalwijzen !== null && (
        <Formik<IFormikValues>
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize
          isInitialValid
          render={(formikProps: FormikProps<IFormikValues>) => {
            const { submitForm, isValid, isSubmitting, values } = formikProps;

            return (
              <>
                <ModalBody>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-12 mt-2">
                        <label>{veldnamen.betwijzeID}</label>
                        <Field
                          name={nameof<IFormikValues>('betwijzeID')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            if (betaalwijzen === null) {
                              return <LoadingSpinner />;
                            }

                            return (
                              <Combobox
                                geselecteerd={field.value}
                                opties={betaalwijzen.map((betaalwijze) => {
                                  return {
                                    id: betaalwijze.BetWijzeID,
                                    label: betaalwijze.Naam,
                                  };
                                })}
                                onSelectieChange={(x) => form.setFieldValue(field.name, x)}
                              />
                            );
                          }}
                        />
                      </div>

                      <div className="col-3 mt-2">
                        <label>Termijnbedrag</label>
                        <Field
                          name={nameof<IFormikValues>('termijnbedrag')}
                          render={(x: FieldProps<IFormikValues>) => {
                            return (
                              <div>
                                <BedragInput
                                  value={x.field.value}
                                  onChange={(bedrag) => {
                                    x.form.setFieldValue(x.field.name, bedrag);
                                  }}
                                />
                              </div>
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-2">
                        <label>{veldnamen.eersteVervaldatum}</label>
                        <Field
                          name="eersteVervaldatum"
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            const minimaleDatum = addDays(new Date(), -1);
                            const maximaleDatum = addDays(new Date(), +60);
                            return (
                              <DatumKiezer
                                waarde={field.value}
                                onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                determineValidDate={(datum) => {
                                  return datum >= minimaleDatum && datum < maximaleDatum;
                                }}
                                determinePreviousValidDate={(date) => {
                                  const newDate = addDays(date, -1);
                                  if (newDate > minimaleDatum) {
                                    return newDate;
                                  }
                                  return null;
                                }}
                                determineNextValidDate={(date) => {
                                  const newDate = addDays(date, +1);
                                  if (newDate < maximaleDatum) {
                                    return newDate;
                                  }
                                  return null;
                                }}
                              />
                            );
                          }}
                        />
                      </div>

                      <div className="col-6 mt-2">
                        <label>{veldnamen.status}</label>
                        <Field
                          name={nameof<IFormikValues>('status')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            if (statussen === null) {
                              return <LoadingSpinner />;
                            }

                            return (
                              <Combobox
                                geselecteerd={field.value}
                                opties={statussen.map((x) => {
                                  return {
                                    id: x.Status,
                                    label: x.Naam,
                                  };
                                })}
                                onSelectieChange={(x) => form.setFieldValue(field.name, x)}
                              />
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <Field
                          name={nameof<IFormikValues>('afgehandeld')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <div className="d-flex align-items-center">
                                <VinkVeld
                                  aangevinkt={field.value}
                                  onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                />
                                <span className="ml-2">{veldnamen.afgehandeld}</span>
                              </div>
                            );
                          }}
                        />
                      </div>

                      {betaalwijzen.find((x) => x.BetWijzeID === values.betwijzeID)!.NaamEnum ===
                        EBetaalwijze.Incasso && (
                        <div className="col-12 mt-3 d-flex flex-column">
                          <span
                            className="font-weight-bold text-danger"
                            style={{ fontSize: '1.05rem' }}
                          >
                            Let op:
                          </span>
                          <span className="text-danger" style={{ fontSize: '0.95rem' }}>
                            Wijzigingen van het bedrag en/of eerste vervaldatum worden niet
                            automatisch doorgevoerd aan eventueel aanwezige incasso-opdrachten. Deze
                            dien je dan zelf nog aan te passen.
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </ModalBody>

                <ModalFooter className="d-flex flex-row justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    style={{ width: 100 }}
                    disabled={!isValid || isSubmitting}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={onAnnuleren}
                    style={{ width: 100 }}
                    disabled={isSubmitting}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Dialoog>
  );
});

export default WijzigenDialoog;
