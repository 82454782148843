import * as React from 'react';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import { IconKruis, IconToevoegen } from '../../../../components/Icons';
import RolItem from './RolItem';
import { useCallback, useEffect, useRef, useState } from 'react';
import VerticaleScheidingslijn from '../../../../components/layout/VerticaleScheidingslijn';
import { IOphalenRollenResultElement } from '../../../../../../shared/src/api/v2/autorisatie/rol';
import api from '../../../../api';

interface IProps {
  actieveRolID: number | null;
  onActieveRolIDChange: (id: number | null) => void;
  rollen: IOphalenRollenResultElement[];
  onVerversenAangevraagd: () => Promise<void>;
}

const Sidebar = (props: IProps) => {
  const [toevoegenVeldWeergeven, setToevoegenVeldWeergeven] = useState(false);
  const [isAanHetToevoegen, setIsAanHetToevoegen] = useState(false);
  const [naam, setNaam] = useState('');

  useEffect(() => {
    if (!toevoegenVeldWeergeven) {
      setNaam('');
      return;
    }
  }, [toevoegenVeldWeergeven]);

  const handleToevoegen = useCallback(async (naam: string) => {
    setIsAanHetToevoegen(true);
    const result = await api.v2.autorisatie.rol.toevoegenRol({
      naam,
      omschrijving: null,
    });
    await props.onVerversenAangevraagd();
    props.onActieveRolIDChange(result.rol.ID);
    setToevoegenVeldWeergeven(false);
    setIsAanHetToevoegen(false);
  }, []);

  return (
    <div
      style={{
        minWidth: 250,
        background: Kleur.HeelLichtGrijs,
        height: '100%',
        borderRight: `1px solid ${Kleur.LichtGrijs}`,
      }}
    >
      <div className="d-flex justify-content-end mr-2 mt-2 ml-2 mb-1" style={{ height: 40 }}>
        {toevoegenVeldWeergeven ? (
          <div className="d-flex align-items-center flex-fill">
            <input
              type="text"
              className="form-control"
              autoFocus
              onChange={(ev) => setNaam(ev.target.value)}
              value={naam}
              onKeyDown={async (ev) => {
                const currentNaam = naam.trim();
                if (currentNaam.length === 0) {
                  return;
                }

                if (ev.key === 'Enter') {
                  await handleToevoegen(currentNaam);
                  setToevoegenVeldWeergeven(false);
                }
              }}
              disabled={isAanHetToevoegen}
            />
            <div className="flex-fill" />
            <button
              style={{
                outline: 0,
                border: 0,
                background: 0,
              }}
              onClick={() => setToevoegenVeldWeergeven(false)}
              disabled={isAanHetToevoegen}
            >
              <IconKruis style={{ width: 24, height: 24, fill: Kleur.Grijs }} />
            </button>
          </div>
        ) : (
          <button
            style={{
              outline: 0,
              border: 0,
              background: 0,
            }}
            onClick={() => setToevoegenVeldWeergeven(true)}
            disabled={isAanHetToevoegen}
          >
            <IconToevoegen style={{ width: 24, height: 24, fill: Kleur.Grijs }} />
          </button>
        )}
      </div>

      <div className="d-flex flex-fill h-100 w-100 flex-column">
        {props.rollen.map((rol) => (
          <RolItem
            key={rol.ID}
            naam={rol.Naam}
            actief={rol.ID === props.actieveRolID}
            onClick={() => props.onActieveRolIDChange(rol.ID)}
          />
        ))}
      </div>
    </div>
  );
};

export default Sidebar;
