import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { formatteerBedrag } from '../../../helpers';
import { clamp } from '../../../helpers/getal';

interface IProps {
  value: number;
  onChange: (value: number) => void;
  min?: number;
  max?: number;
  inputStyle?: React.CSSProperties;
  autoFocus?: boolean;
}

const BedragInput: React.FC<IProps> = (props) => {
  const { children, onChange, value, min, max } = props;
  const inputRef = useRef<HTMLInputElement>(null);
  const [text, setText] = useState(value === 0 ? '' : String(value));
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    const textNum = text === '' ? 0 : Number(text.replace(',', '.'));
    if (isNaN(textNum)) {
      setText(String(value));
      return;
    }
    if (textNum === value) {
      return;
    }
    setText(String(value));
  }, [value]);

  const trySetValue = useCallback(
    (value: number) => {
      const newValue = Number(
        clamp(
          value,
          min === undefined ? -Number.MAX_VALUE : min,
          max === undefined ? Number.MAX_VALUE : max,
        ).toFixed(2),
      );

      if (props.value !== newValue) {
        onChange(newValue);
      }
    },
    [min, max, onChange, props.value],
  );

  useEffect(() => {
    const replaced = text.replace(',', '.');
    const textNum = Number(replaced);
    if (isNaN(textNum)) {
      return;
    }
    trySetValue(textNum);
  }, [text]);

  const geenFocusRepresentatie = useMemo<string | null>(() => {
    if (focused) {
      return null;
    }

    return formatteerBedrag(value);
  }, [focused, value]);

  useEffect(() => {
    if (focused) {
      inputRef.current!.select();
    }
  }, [focused]);

  return (
    <input
      ref={inputRef}
      style={props.inputStyle}
      type="text"
      className="form-control"
      value={focused ? text.replace('.', ',') : geenFocusRepresentatie!}
      onChange={(ev) => {
        setText(ev.target.value);
      }}
      autoFocus={props.autoFocus}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      onKeyUp={(ev) => {
        if (ev.key === 'ArrowDown') {
          trySetValue(value - 1);
        } else if (ev.key === 'ArrowUp') {
          trySetValue(value + 1);
        }
      }}
    />
  );
};

export default BedragInput;
