import React, { useCallback, useEffect, useMemo, useState } from 'react';
import IDialoogProps from '../../../../../core/IDialoogProps';
import Dialoog from '../../../../dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import LoadingSpinner from '../../../../Gedeeld/LoadingSpinner';
import { Field, FieldProps, Formik, FormikActions, FormikErrors, FormikProps } from 'formik';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../../models/IRemoteData';
import api from '../../../../../api';
import nameOf from '../../../../../core/nameOf';
import { IOphalenAfdelingenResultElement } from '../../../../../../../shared/src/api/v2/relatie/afdeling';
import { dagDatum } from '../../../../../helpers/datum';
import DatumKiezer from '../../../../formulier/DatumKiezer';
import FormikVeldFout from '../../../../formulier/FormikVeldFout';

export interface IDialoogResult {}

interface IFormikValues {
  periode: string;
  titel: string;
  inhoud: string;
  ingangsdatum: Date;
  einddatum: Date | null;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  periode: 'Periode',
  titel: 'Titel (b.v. Algemene voorwaarden of Prijsafspraken)',
  inhoud: 'Inhoud',
  ingangsdatum: 'Ingangsdatum',
  einddatum: 'Einddatum',
};

interface IProps extends IDialoogProps<IDialoogResult> {
  relID: number;
  id?: number;
}

const MuterenDialoog = (props: IProps) => {
  const [initialValues, setInitialValues] = useState<IRemoteData<IFormikValues>>(
    createPendingRemoteData(),
  );

  useEffect(() => {
    if (props.id === undefined) {
      setInitialValues(
        createReadyRemoteData({
          periode: '',
          titel: '',
          inhoud: '',
          ingangsdatum: dagDatum(new Date()),
          einddatum: null,
        }),
      );
      return;
    }

    api.v2.relatie.leveringsvoorwaarden
      .ophalenLeveringsvoorwaarden({
        filterSchema: {
          filters: [
            {
              naam: 'IDS',
              data: [props.id],
            },
          ],
        },
      })
      .then((result) => {
        const voorwaarden = result.leveringsvoorwaarden[0];
        setInitialValues(
          createReadyRemoteData({
            periode: voorwaarden.Periode ?? '',
            titel: voorwaarden.Titel ?? '',
            inhoud: voorwaarden.Inhoud ?? '',
            ingangsdatum: dagDatum(new Date(voorwaarden.Ingangsdatum)),
            einddatum:
              voorwaarden.Einddatum !== null ? dagDatum(new Date(voorwaarden.Einddatum)) : null,
          }),
        );
      });
  }, []);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      let afdeling: IOphalenAfdelingenResultElement;

      if (props.id === undefined) {
        const toevoegenResult = await api.v2.relatie.leveringsvoorwaarden.toevoegenLeveringsvoorwaarden(
          {
            relID: props.relID,
            periode: values.periode,
            titel: values.titel,
            inhoud: values.inhoud,
            ingangsdatum: values.ingangsdatum,
            einddatum: values.einddatum,
          },
        );
      } else {
        const wijzigenResult = await api.v2.relatie.leveringsvoorwaarden.wijzigenLeveringsvoorwaarden(
          {
            id: props.id,
            periode: values.periode,
            titel: values.titel,
            inhoud: values.inhoud,
            ingangsdatum: values.ingangsdatum,
            einddatum: values.einddatum,
          },
        );
      }

      props.onSuccess({});

      actions.setSubmitting(false);
    },
    [props.id],
  );

  const validate = useCallback((values: IFormikValues) => {
    const errors: FormikErrors<IFormikValues> = {};
    if (values.titel.length === 0) {
      errors.titel = 'Verplicht veld';
    }
    if (values.inhoud.length === 0) {
      errors.inhoud = 'Verplicht veld';
    }

    return errors;
  }, []);

  return (
    <Dialoog index={props.dialoogIndex ?? 0}>
      <ModalHeader>
        <ModalTitle>Voorwaarden {props.id === undefined ? 'toevoegen' : 'wijzigen'}</ModalTitle>
      </ModalHeader>
      {initialValues.state === ERemoteDataState.Pending ? (
        <ModalBody>
          <LoadingSpinner />
        </ModalBody>
      ) : (
        <Formik
          onSubmit={handleSubmit}
          validate={validate}
          initialValues={initialValues.data!}
          render={(formikProps) => <VoorwaardenMuterenInner {...props} formikProps={formikProps} />}
        />
      )}
    </Dialoog>
  );
};

const VoorwaardenMuterenInner = (props: IProps & { formikProps: FormikProps<IFormikValues> }) => {
  const formikProps = props.formikProps;
  const { isSubmitting, submitForm } = formikProps;

  return (
    <>
      <ModalBody>
        <div>
          <label>{veldnamen.titel}</label>
          <Field
            name={nameOf<IFormikValues>('titel')}
            render={(fieldProps: FieldProps<IFormikValues>) => {
              const { field } = fieldProps;
              return (
                <>
                  <input {...field} className="form-control" type="text" />
                  <FormikVeldFout fieldProps={fieldProps} />
                </>
              );
            }}
          />
        </div>

        <div className="mt-3">
          <label>{veldnamen.inhoud}</label>
          <Field
            name={nameOf<IFormikValues>('inhoud')}
            render={(fieldProps: FieldProps<IFormikValues>) => {
              const { field } = fieldProps;
              return (
                <div>
                  <textarea className="form-control" {...field} rows={5} />
                  <FormikVeldFout fieldProps={fieldProps} />
                </div>
              );
            }}
          />
        </div>

        <div className="mt-3">
          <label>{veldnamen.periode}</label>
          <Field
            name={nameOf<IFormikValues>('periode')}
            render={(fieldProps: FieldProps<IFormikValues>) => {
              const { field } = fieldProps;
              return (
                <>
                  <input {...field} className="form-control" type="text" />
                </>
              );
            }}
          />
        </div>

        <div className="row mt-3">
          <div className="col-4">
            <label>Ingangsdatum</label>
            <Field
              name={nameOf<IFormikValues>('ingangsdatum')}
              render={(fieldProps: FieldProps<IFormikValues>) => {
                const { field, form } = fieldProps;
                return (
                  <DatumKiezer
                    waarde={field.value}
                    onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                    determineNextValidDate="ONBEGRENST"
                    determinePreviousValidDate="ONBEGRENST"
                  />
                );
              }}
            />
          </div>
          <div className="col-4">
            <label>Einddatum</label>
            <Field
              name={nameOf<IFormikValues>('einddatum')}
              render={(fieldProps: FieldProps<IFormikValues>) => {
                const { field, form } = fieldProps;
                return (
                  <DatumKiezer
                    waarde={field.value}
                    onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                    determineNextValidDate="ONBEGRENST"
                    determinePreviousValidDate="ONBEGRENST"
                    isClearable
                  />
                );
              }}
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter className="d-flex flex-row justify-content-start">
        <button
          className="btn btn-primary d-flex align-items-center justify-content-center"
          onClick={submitForm}
          style={{ width: 100 }}
          disabled={isSubmitting}
        >
          {isSubmitting && (
            <span className="mr-2">
              <LoadingSpinner soort="light" grootte="15px" dikte="2px" />
            </span>
          )}
          Ok
        </button>
        <button
          className="btn btn-secondary"
          onClick={props.onAnnuleren}
          style={{ width: 100 }}
          disabled={isSubmitting}
        >
          Annuleren
        </button>
      </ModalFooter>
    </>
  );
};

export default MuterenDialoog;
