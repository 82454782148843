import {
  IOphalenProceduresParams,
  IOphalenProceduresResult,
  IOphalenProcedureStatussenParams,
  IOphalenProcedureStatussenResult,
  IToevoegenProcedureParams,
  IToevoegenProcedureResult,
  IWijzigenProcedureParams,
  IWijzigenProcedureResult,
  IVerwijderenProceduresParams,
  IVerwijderenProceduresResult,
  IOphalenDocumentenParams,
  IOphalenDocumentenResult,
  IVersturenVerzoekDocumentenParams,
  IVersturenVerzoekDocumentenResult,
  IVerwijderenDocumentenUitProcedureParams,
  IVerwijderenDocumentenUitProcedureResult,
  IVersturenAfwijzingParams,
  IVersturenAfwijzingResult,
  IWijzigenStatusOntvangenParams,
  IWijzigenStatusOntvangenResult,
  IToevoegenDocumentenAanProcedureParams,
  IToevoegenDocumentenAanProcedureResult,
  IOphalenDubieuzeAdressenParams,
  IOphalenDubieuzeAdressenResult,
  IToevoegenDubieusAdresParams,
  IToevoegenDubieusAdresResult,
  IVerwijderenDubieuzeAdressenParams,
  IVerwijderenDubieuzeAdressenResult,
  IWijzigenDubieusAdresResult,
  IWijzigenDubieusAdresParams,
} from '../../../../../../shared/src/api/v2/relatie/acceptatie';
import ICheckData from '../../../../../../shared/src/models/ICheckData';
import api from '../../../index';

const acceptatie = {
  ophalenProcedures: async (
    params: IOphalenProceduresParams,
  ): Promise<IOphalenProceduresResult> => {
    return await api.post('/v2/relatie/acceptatie/ophalen-procedures', params);
  },
  ophalenDocumenten: async (
    params: IOphalenDocumentenParams,
  ): Promise<IOphalenDocumentenResult> => {
    return await api.post('/v2/relatie/acceptatie/ophalen-documenten', params);
  },
  ophalenProcedureStatussen: async (
    params: IOphalenProcedureStatussenParams,
  ): Promise<IOphalenProcedureStatussenResult> => {
    return await api.post('/v2/relatie/acceptatie/ophalen-procedure-statussen', params);
  },
  toevoegenProcedure: async (
    params: IToevoegenProcedureParams,
  ): Promise<IToevoegenProcedureResult> => {
    return await api.post('/v2/relatie/acceptatie/toevoegen-procedure', params);
  },
  wijzigenProcedure: async (
    params: IWijzigenProcedureParams,
  ): Promise<IWijzigenProcedureResult> => {
    return await api.post('/v2/relatie/acceptatie/wijzigen-procedure', params);
  },
  checkVerwijderenProcedures: async (params: IVerwijderenProceduresParams): Promise<ICheckData> => {
    return await api.post('/v2/relatie/acceptatie/check-verwijderen-procedures', params);
  },
  verwijderenProcedures: async (
    params: IVerwijderenProceduresParams,
  ): Promise<IVerwijderenProceduresResult> => {
    return await api.post('/v2/relatie/acceptatie/verwijderen-procedures', params);
  },
  checkVersturenVerzoekDocumenten: async (
    params: IVersturenVerzoekDocumentenParams,
  ): Promise<ICheckData> => {
    return await api.post('/v2/relatie/acceptatie/check-versturen-verzoek-documenten', params);
  },
  versturenVerzoekDocumenten: async (
    params: IVersturenVerzoekDocumentenParams,
  ): Promise<IVersturenVerzoekDocumentenResult> => {
    return await api.post('/v2/relatie/acceptatie/versturen-verzoek-documenten', params);
  },
  checkVerwijderenDocumentenUitProcedure: async (
    params: IVerwijderenDocumentenUitProcedureParams,
  ): Promise<ICheckData> => {
    return await api.post(
      '/v2/relatie/acceptatie/check-verwijderen-documenten-uit-procedure',
      params,
    );
  },
  verwijderenDocumentenUitProcedure: async (
    params: IVerwijderenDocumentenUitProcedureParams,
  ): Promise<IVerwijderenDocumentenUitProcedureResult> => {
    return await api.post('/v2/relatie/acceptatie/verwijderen-documenten-uit-procedure', params);
  },
  checkVersturenAfwijzing: async (params: IVersturenAfwijzingParams): Promise<ICheckData> => {
    return await api.post('/v2/relatie/acceptatie/check-versturen-afwijzing', params);
  },
  versturenAfwijzing: async (
    params: IVersturenAfwijzingParams,
  ): Promise<IVersturenAfwijzingResult> => {
    return await api.post('/v2/relatie/acceptatie/versturen-afwijzing', params);
  },
  wijzigenStatusOntvangen: async (
    params: IWijzigenStatusOntvangenParams,
  ): Promise<IWijzigenStatusOntvangenResult> => {
    return await api.post('/v2/relatie/acceptatie/wijzigen-status-ontvangen', params);
  },
  toevoegenDocumentenAanProcedure: async (
    params: IToevoegenDocumentenAanProcedureParams,
  ): Promise<IToevoegenDocumentenAanProcedureResult> => {
    return await api.post('/v2/relatie/acceptatie/toevoegen-documenten-aan-procedure', params);
  },

  ophalenDubieuzeAdressen: async (
    params: IOphalenDubieuzeAdressenParams,
  ): Promise<IOphalenDubieuzeAdressenResult> => {
    return await api.post('/v2/relatie/acceptatie/ophalen-dubieuze-adressen', params);
  },
  checkToevoegenDubieusAdres: async (params: IToevoegenDubieusAdresParams): Promise<ICheckData> => {
    return await api.post('/v2/relatie/acceptatie/check-toevoegen-dubieus-adres', params);
  },
  toevoegenDubieusAdres: async (
    params: IToevoegenDubieusAdresParams,
  ): Promise<IToevoegenDubieusAdresResult> => {
    return await api.post('/v2/relatie/acceptatie/toevoegen-dubieus-adres', params);
  },
  verwijderenDubieuzeAdressen: async (
    params: IVerwijderenDubieuzeAdressenParams,
  ): Promise<IVerwijderenDubieuzeAdressenResult> => {
    return await api.post('/v2/relatie/acceptatie/verwijderen-dubieuze-adressen', params);
  },
  wijzigenDubieusAdres: async (
    params: IWijzigenDubieusAdresParams,
  ): Promise<IWijzigenDubieusAdresResult> => {
    return await api.post('/v2/relatie/acceptatie/wijzigen-adres', params);
  },
};

export default acceptatie;
