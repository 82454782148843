import React, { useCallback, useState, useEffect, useMemo, useContext } from 'react';
import useUrlState from '../../../core/useUrlState';
import { Redirect, Route, RouteComponentProps } from 'react-router';
import api from '../../../api';
import { IFilterSchemaFilter } from '../../../../../shared/src/models/filter';
import LoadingSpinner from '../../../components/Gedeeld/LoadingSpinner';
import {
  Grid,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  DXCommandComponent,
  DXTableCheckboxComponent,
  DXTableToggleCellComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../helpers/dxTableGrid';
import {
  DataTypeProvider,
  EditingState,
  RowDetailState,
  SelectionState,
} from '@devexpress/dx-react-grid';
import { format } from 'date-fns';
import BijwerkenAfhaaldatumDialoog from './BijwerkenAfhaaldatumDialoog';
import { RootStoreContext } from '../../../stores/RootStore';
import { EResultType } from '../../../stores/CheckStore';
import MenuLayout from '../../../components/MenuLayout';
import { Kleur } from '../../../bedrijfslogica/constanten';
import { IconLock, IconSend, IconVerwijderen } from '../../../components/Icons';
import VersturenOpdrachtDialoog from './VersturenOpdrachtDialoog';
import { IOphalenAfhaalOpdrachtenResultElement } from '../../../../../shared/src/api/v2/magazijn/afhaal';
import RelatieVisualisatie from '../../../components/personalia/RelatieVisualisatie';
import SelectieVak from '../../../components/SelectieVak';
import nameOf from '../../../core/nameOf';
import TransportopdrachtregelVisualisatie from '../../../components/entiteitVisualisaties/TransportopdrachtregelVisualisatie';

interface IProps extends RouteComponentProps<{}> {}

export interface IVersturenOpdrachtDialoogState {
  penOpdID: number;
}

export interface IBijwerkenAfhaaldatumDialoogState {
  ids: number[];
}

interface IUrlState {
  selectie: number[];
  versturenOpdrachtDialoogState: IVersturenOpdrachtDialoogState | null;
  bijwerkenAfhaaldatumDialoogState: IBijwerkenAfhaaldatumDialoogState | null;
}

const defaultUrlState: IUrlState = {
  selectie: [],
  versturenOpdrachtDialoogState: null,
  bijwerkenAfhaaldatumDialoogState: null,
};

interface IPendelsContext {
  onVerversenAangevraagd: () => void;
}

export const PendelsContext = React.createContext<IPendelsContext | null>(null);

export interface IRegel extends IOphalenAfhaalOpdrachtenResultElement {}

const Pendels: React.FC<IProps> = (props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const { checkStore } = useContext(RootStoreContext);

  const [opdrachten, setOpdrachten] = useState<IRegel[] | null>(null);

  const ophalenAfhaalOpdrachten = useCallback(async () => {
    const result = await api.v2.magazijn.afhaal.ophalenAfhaalOpdrachten({
      filterSchema: {
        filters: [],
      },
      orderSchema: {
        orders: [
          {
            naam: 'ID',
            richting: 'ASC',
          },
        ],
      },
    });
    const opdrachten = result.afhaalopdrachten.map((x) => {
      return { ...x };
    });

    setOpdrachten(opdrachten);
  }, []);

  useEffect(() => {
    ophalenAfhaalOpdrachten();
  }, [ophalenAfhaalOpdrachten]);

  const handleVerwijderen = useCallback(async () => {
    const params = { ids: urlState.selectie };
    const checkData = await api.v2.magazijn.afhaal.checkVerwijderenAfhaalOpdrachten(params);
    if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
      return;
    }
    if (
      (
        await checkStore.bevestigen({
          inhoud: `Geselecteerde afhaalopdrachten verwijderen?`,
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    await api.v2.magazijn.afhaal.verwijderenAfhaalOpdrachten(params);

    setUrlStateSync('selectie', []);

    ophalenAfhaalOpdrachten();
  }, [urlState.selectie]);

  const keyExtractor = useCallback((row: IRegel) => row.ID, []);

  const kolommen = useMemo<TypedColumn<IRegel>[]>(
    () => [
      {
        name: '__opdrachtnummer' as any,
        title: 'Opd.nr.',
      },
      {
        name: '__merknaam' as any,
        title: 'Merk',
      },
      {
        name: '__typenaam' as any,
        title: 'Type',
      },
      {
        name: '__productsoortnaamKort' as any,
        title: 'Cat.',
      },
      {
        name: '__referentiecode' as any,
        title: 'Ref.code',
      },
      {
        name: 'Afhaaldatum',
        title: 'Afhaaldatum',
      },
      {
        name: '__pendeldienst' as any,
        title: 'Pendeldienst',
      },
      {
        name: 'OpdrachtVerstuurdOp',
        title: 'Laatst verstuurd',
      },
      {
        name: 'Versturen',
        title: 'Versturen',
      },
      {
        name: 'OpdrachtBijgewerktOp',
        title: 'Bijgewerkt op',
      },
      {
        name: '__transportopdrachtregel' as any,
        title: 'Gekoppelde opdracht',
      },
      {
        name: 'RecordToegevoegd',
        title: 'Geregistreerd',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRegel>[]>(
    () => [
      {
        columnName: '__merknaam' as any,
        width: 105,
      },
      {
        columnName: '__typenaam' as any,
        width: 155,
      },
      {
        columnName: '__productsoortnaamKort' as any,
        width: 80,
      },
      {
        columnName: '__referentiecode' as any,
        width: 100,
      },
      {
        columnName: 'Afhaaldatum',
        width: 125,
      },
      {
        columnName: '__pendeldienst' as any,
        width: 150,
      },
      {
        columnName: 'Versturen',
        width: 90,
      },
      {
        columnName: 'OpdrachtVerstuurdOp',
        width: 135,
      },
      {
        columnName: 'OpdrachtBijgewerktOp',
        width: 135,
      },
      {
        columnName: '__opdrachtnummer' as any,
        width: 130,
      },
      {
        columnName: '__transportopdrachtregel' as any,
        width: 160,
      },
      {
        columnName: 'RecordToegevoegd',
        width: 135,
      },
    ],
    [],
  );

  return (
    <PendelsContext.Provider
      value={{
        onVerversenAangevraagd: async () => await ophalenAfhaalOpdrachten(),
      }}
    >
      <MenuLayout
        menu={
          <>
            <div className="d-flex">
              <button
                className="btn btn-sm btn-light d-flex align-items-center"
                style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                disabled={urlState.selectie.length === 0}
                onClick={() => {
                  return setUrlStateSync('bijwerkenAfhaaldatumDialoogState', {
                    ids: urlState.selectie,
                  });
                }}
              >
                <IconSend style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                <span className="ml-2">Bijwerken afhaaldatum</span>
              </button>
              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-2"
                style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                disabled={urlState.selectie.length === 0}
                onClick={async () => {
                  // return setUrlStateSync('versturenOpdrachtDialoogState', {
                  //   penOpdID: urlState.selectie[0],
                  // });

                  const params = { ids: urlState.selectie };
                  const checkData = await api.v2.magazijn.afhaal.checkVersturenAfhaalOpdrachten(
                    params,
                  );
                  if (
                    (await checkStore.controleren({ checkData })).type === EResultType.Annuleren
                  ) {
                    return;
                  }

                  if (
                    (
                      await checkStore.bevestigen({
                        inhoud: <span>Geselecteerde opdrachten versturen?</span>,
                      })
                    ).type === EResultType.Annuleren
                  ) {
                    return;
                  }

                  const result = await api.v2.magazijn.afhaal.versturenAfhaalOpdrachten(params);

                  await ophalenAfhaalOpdrachten();
                }}
              >
                <IconSend style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                <span className="ml-2">Versturen opdracht</span>
              </button>
              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-2"
                style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                disabled={urlState.selectie.length === 0}
                onClick={() => {
                  handleVerwijderen();
                }}
              >
                <IconVerwijderen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                <span className="ml-2">Verwijderen</span>
              </button>
            </div>
            {opdrachten !== null && (
              <div className="mt-3">
                <SelectieVak
                  aantal={urlState.selectie.length}
                  totaalAantal={opdrachten!.length}
                  onChange={(alles) => {
                    if (alles) {
                      setUrlStateSync(
                        'selectie',
                        opdrachten.map((x) => x.ID),
                      );
                    } else {
                      setUrlStateSync('selectie', []);
                    }
                  }}
                  // entiteitEnkelvoud="opdracht"
                  // entiteitMeervoud="opdrachten"
                />
              </div>
            )}
          </>
        }
        body={
          <>
            {opdrachten === null ? (
              <LoadingSpinner />
            ) : (
              <>
                <GridStyleWrapper height={'calc(100vh - 100px)'}>
                  <Grid rows={opdrachten} columns={kolommen} getRowId={keyExtractor}>
                    <DataTypeProvider
                      for={['__opdrachtnummer']}
                      formatterComponent={(formatterprops) => {
                        const rij: IRegel = formatterprops.row;
                        return <span>SPLASH-AFH-{rij.Opdrachtnummer}</span>;
                      }}
                    />

                    <DataTypeProvider
                      for={['__referentiecode']}
                      formatterComponent={(formatterprops) => {
                        const rij: IRegel = formatterprops.row;
                        return <span>{rij.product.Referentiecode}</span>;
                      }}
                    />

                    <DataTypeProvider
                      for={[nameOf<IRegel>('Versturen')]}
                      formatterComponent={(formatterprops) => {
                        const rij: IRegel = formatterprops.row;
                        return <span>{rij.Versturen ? 'Ja' : 'Nee'}</span>;
                      }}
                    />

                    <DataTypeProvider
                      for={['__productsoortnaamKort']}
                      formatterComponent={(formatterprops) => {
                        const rij: IRegel = formatterprops.row;
                        return <span>{rij.product.producttype.ProductsoortnaamKort}</span>;
                      }}
                    />

                    <DataTypeProvider
                      for={['__merknaam']}
                      formatterComponent={(formatterprops) => {
                        const rij: IRegel = formatterprops.row;
                        return <span>{rij.product.producttype.Merknaam}</span>;
                      }}
                    />

                    <DataTypeProvider
                      for={['__typenaam']}
                      formatterComponent={(formatterprops) => {
                        const rij: IRegel = formatterprops.row;
                        return <span>{rij.product.producttype.Typenaam}</span>;
                      }}
                    />

                    <DataTypeProvider
                      for={[nameOf<IRegel>('Afhaaldatum')]}
                      formatterComponent={(formatterprops) => {
                        const rij: IRegel = formatterprops.row;
                        return (
                          <span>
                            {formatterprops.value !== null
                              ? format(new Date(formatterprops.value), 'dd-MM-yyyy')
                              : ''}
                          </span>
                        );
                      }}
                    />

                    <DataTypeProvider
                      for={['__pendeldienst']}
                      formatterComponent={(formatterprops) => {
                        const rij: IRegel = formatterprops.row;
                        return (
                          <span>
                            {rij.pendeldienst !== null ? (
                              <RelatieVisualisatie relID={rij.pendeldienst.RelID} />
                            ) : (
                              <span></span>
                            )}
                          </span>
                        );
                      }}
                    />

                    <DataTypeProvider
                      for={[
                        nameOf<IRegel>('OpdrachtVerstuurdOp'),
                        nameOf<IRegel>('OpdrachtBijgewerktOp'),
                      ]}
                      formatterComponent={(formatterprops) => {
                        const rij: IRegel = formatterprops.row;
                        return (
                          <span>
                            {formatterprops.value !== null
                              ? format(new Date(formatterprops.value), 'dd-MM-yyyy HH:mm')
                              : ''}
                          </span>
                        );
                      }}
                    />

                    {/* <DataTypeProvider
                      for={['__OpdrachtReferentie']}
                      formatterComponent={(formatterprops) => {
                        const rij: IRegel = formatterprops.row;
                        return <span>{rij.OpdrachtReferentie}</span>;
                      }}
                    /> */}

                    <DataTypeProvider
                      for={[nameOf<IRegel>('RecordToegevoegd')]}
                      formatterComponent={(formatterprops) => {
                        const rij: IRegel = formatterprops.row;
                        return (
                          <span>{format(new Date(formatterprops.value), 'dd-MM-yyyy HH:mm')}</span>
                        );
                      }}
                    />

                    <DataTypeProvider
                      for={['__transportopdrachtregel']}
                      formatterComponent={(formatterprops) => {
                        const rij: IRegel = formatterprops.row;
                        if (rij.TrsRegID === null) {
                          return <span></span>;
                        }
                        return <TransportopdrachtregelVisualisatie trsRegID={rij.TrsRegID} />;
                      }}
                    />

                    <VirtualTable messages={{ noData: 'Geen afhaalopdrachten' }} />
                    <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
                    <TableHeaderRow />

                    <EditingState
                      onAddedRowsChange={() => {}}
                      onEditingRowIdsChange={(x) => {
                        const id = x[x.length - 1] as number;
                      }}
                      onCommitChanges={async (changes) => {
                        if (changes.deleted === undefined) {
                          return;
                        }
                        const deleted = changes.deleted;
                        const id = deleted[deleted.length - 1] as number;
                      }}
                    />
                    <TableEditColumn
                      width={65}
                      showEditCommand
                      // showDeleteCommand
                      commandComponent={DXCommandComponent}
                    />

                    <SelectionState
                      selection={urlState.selectie}
                      onSelectionChange={(value) => setUrlStateSync('selectie', value as number[])}
                    />
                    <TableSelection cellComponent={DXTableCheckboxComponent} />
                  </Grid>
                </GridStyleWrapper>

                {urlState.bijwerkenAfhaaldatumDialoogState !== null && (
                  <BijwerkenAfhaaldatumDialoog
                    open
                    ids={urlState.bijwerkenAfhaaldatumDialoogState.ids}
                    onSuccess={() => {
                      ophalenAfhaalOpdrachten();
                      setUrlStateSync('bijwerkenAfhaaldatumDialoogState', null);
                    }}
                    onAnnuleren={() => {
                      setUrlStateSync('bijwerkenAfhaaldatumDialoogState', null);
                    }}
                  />
                )}

                {urlState.versturenOpdrachtDialoogState !== null && (
                  <VersturenOpdrachtDialoog
                    open
                    penOpdID={urlState.versturenOpdrachtDialoogState.penOpdID}
                    onSuccess={() => {
                      setUrlStateSync('versturenOpdrachtDialoogState', null);
                      ophalenAfhaalOpdrachten();
                    }}
                    onAnnuleren={() => {
                      setUrlStateSync('versturenOpdrachtDialoogState', null);
                    }}
                  />
                )}
              </>
            )}
          </>
        }
      />
    </PendelsContext.Provider>
  );
};

export default Pendels;
