import React, { useEffect, useMemo, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { observer } from 'mobx-react-lite';
import api from '../../../../api';
import useUrlState from '../../../../core/useUrlState';
import BoekingTabel from '../../../../components/boekhouding/BoekingTabel';
import FilterBalkV2, { IFilterData, maakFilterSchema } from '../../../../components/FilterBalkV2';
import useBasisBoekingregelsFilters, { EFilter } from '../useBasisBoekingregelsFilters';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import { EDagboeksoort } from '../../../../bedrijfslogica/enums';
import useBoekingregels from '../useBoekingregels';
import { IDagboekenProvider } from '../../../../components/boekhouding/boeking/BoekingDialoogV3/providers/dagboeken';
import {
  IDagboekVoorselectieProvider,
  standaardDagboekVoorselectieProvider,
} from '../../../../components/boekhouding/boeking/BoekingDialoogV3/providers/dagboekVoorselectie';
import {
  IRegelsProvider,
  standaardRegelsProvider,
} from '../../../../components/boekhouding/boeking/BoekingDialoogV3/providers/regels';
import { maakNieuweRegel } from '../../../../components/boekhouding/boeking/BoekingDialoogV3/helpers';
import { Helmet } from 'react-helmet';

const regelsProvider: IRegelsProvider = {
  provide: async (boekingID, dagboek) => {
    if (boekingID === null) {
      const grootboekenResult = await api.v2.boeking.grootboek.ophalenGrootboeken({
        filterSchema: {
          filters: [
            {
              naam: 'NAAM_ENUMS',
              data: ['DEBITEUREN'],
            },
          ],
        },
      });
      const grootboek = grootboekenResult.grootboeken[0];

      const regel = maakNieuweRegel();
      regel.grootboekID = grootboek.ID;
      regel.btwSoortID = grootboek.BtwSrtID ?? dagboek.dagboekSoort.BtwSrtID;

      return [regel];
    }

    return await standaardRegelsProvider.provide(boekingID, dagboek);
  },
};

interface IProps extends RouteComponentProps {}

interface IUrlState {
  regelsSelectie: number[];
  uitgeklapteOpdrachten: number[];
  filterData: IFilterData<EFilter>[] | null;
}

const dagboekenProvider: IDagboekenProvider = {
  provide: async () => {
    const result = await api.v2.boeking.dagboek.ophalenDagboeken({
      filterSchema: {
        filters: [
          {
            naam: 'DAGBOEKSOORT_NAAM_ENUMS',
            data: [EDagboeksoort.VERKOOP],
          },
        ],
      },
    });

    return result.dagboeken;
  },
};

const Verkoop: React.FC<IProps> = observer((props) => {
  const { boekingregelFilters, defaultFilterData, dagboeksoort } = useBasisBoekingregelsFilters({
    dagboeksoort: EDagboeksoort.VERKOOP,
  });
  const defaultUrlState = useMemo<IUrlState>(
    () => ({
      regelsSelectie: [],
      uitgeklapteOpdrachten: [],
      filterData: defaultFilterData,
    }),
    [defaultFilterData],
  );
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  useEffect(() => {
    setUrlStateSync('filterData', defaultFilterData);
  }, [defaultFilterData]);

  const [filterSchema, setFilterSchema] = useState(
    urlState.filterData === null ? null : maakFilterSchema(urlState.filterData),
  );
  useEffect(() => {
    if (filterSchema === null && urlState.filterData !== null) {
      setFilterSchema(maakFilterSchema(urlState.filterData));
    }
  }, [urlState.filterData]);

  // const cache = useMemo(() => createRowCache(99999), []);
  // const [paginatie, setPaginatie] = useState<IPaginatiePositie>({
  //   index: 0,
  //   aantal: 100,
  // });
  const {
    regels,
    ophalenBoekingregels,
    bezigMetOphalen,
    totaalBoekingsregels,
    huidigeBoekingsregelsPaginatieIndex,
  } = useBoekingregels(EDagboeksoort.VERKOOP, filterSchema);

  const dagboekVoorselectieProvider = useMemo<IDagboekVoorselectieProvider>(
    () => ({
      provide: async (dagboeken) => {
        if (urlState.filterData === null) {
          return await standaardDagboekVoorselectieProvider.provide(dagboeken);
        }

        const dagboekFilter = urlState.filterData.find((x) => x.naam === EFilter.Dagboeken)!;
        return dagboekFilter.data[0] as number;
      },
    }),
    [urlState.filterData],
  );

  return (
    <>
      {dagboeksoort === null ? (
        <LoadingSpinner />
      ) : (
        <>
          <Helmet>
            <title>Boekingen Verkoop</title>
          </Helmet>
          <BoekingTabel
            totaalBoekingsregels={totaalBoekingsregels}
            bezigMetOphalenBoekingsregels={bezigMetOphalen}
            dagboekSrtID={dagboeksoort.ID}
            boekingsregels={regels}
            huidigeBoekingsregelsPaginatieIndex={huidigeBoekingsregelsPaginatieIndex}
            boekingsregelsSelectie={urlState.regelsSelectie}
            onBoekingsregelsSelectieChange={(value: number[]) =>
              setUrlStateSync('regelsSelectie', value)
            }
            onRequestRefresh={async () => {
              await ophalenBoekingregels(0, 500, false);
            }}
            // paginatieData={{
            //   paginatie,
            //   isAanHetLaden: false,
            //   totaalAantalRijen: 999999,
            //   onUitbreidenAangevraagd: () => ophalenJournaalpostregels(),
            // }}
            filterbalk={
              urlState.filterData === null ? (
                <LoadingSpinner />
              ) : (
                <FilterBalkV2
                  filters={boekingregelFilters}
                  filterData={urlState.filterData}
                  onFilterDataChange={(x) => setUrlStateSync('filterData', x)}
                  onFilterSchemaChange={(x) => setFilterSchema(x)}
                />
              )
            }
            boekingdialoogRegelsProvider={regelsProvider}
            boekingdialoogDagboekenProvider={dagboekenProvider}
            boekingdialoogDagboekVoorselectieProvider={dagboekVoorselectieProvider}
          />
        </>
      )}
    </>
  );
});

export default withRouter(Verkoop);
