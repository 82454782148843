import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import MenuLayout from '../../../components/MenuLayout';
import { RouteComponentProps } from 'react-router';
import { IProductsoortLandingspagina } from '../../../../../shared/src/api/v2/product/soort/landingspagina';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../models/IRemoteData';
import api from '../../../api';
import { IOphalenTekstenResultElement } from '../../../../../shared/src/api/v2/tekst';
import { IOphalenBestandenResultElement } from '../../../../../shared/src/api/v2/bestand/bestand';
import ASPTabel from '../../../components/tabel/ASPTabel';
import { LoadingSpinnerCenter } from '../../../components/Gedeeld/LoadingSpinner';
import { ASPKolom, EAspKolomBreedteType } from '../../../components/tabel/ASPTabel/types';
import { Kleur } from '../../../bedrijfslogica/constanten';
import { IconToevoegen } from '../../../components/Icons';
import useBezig from '../../../core/useBezig';
import { GlobaleRendererContext } from '../../../one-off-components/GlobaleRenderer';
import MuterenDialoog from './MuterenDialoog';

interface IData {
  landingspaginas: IProductsoortLandingspagina[];
  tekstenBijTekstID: Record<number, IOphalenTekstenResultElement[]>;
  bestandenBijID: Record<number, IOphalenBestandenResultElement>;
}

type Kolom = 'url_segment' | 'plaatsnaam' | 'specialisatie' | 'afbeelding';

interface IProps extends RouteComponentProps {}

const Landingpaginas = (props: IProps) => {
  const globaleRenderer = useContext(GlobaleRendererContext);
  const { isBezig, scopeBezig } = useBezig();
  const [data, setData] = useState<IRemoteData<IData>>(createPendingRemoteData());

  const ophalenData = useCallback(async () => {
    await scopeBezig(async () => {
      const landingspaginasResult = await api.v2.product.soort.landingspagina.ophalenLandingspaginas(
        {},
      );

      const tekstIDs = landingspaginasResult.landingspaginas
        .flatMap((x) => [x.Plaatsnaam_TekstID, x.Specialisatie_TekstID, x.UrlSegment_TekstID])
        .filter((x) => x !== null) as number[];

      const bestandIDs = landingspaginasResult.landingspaginas
        .map((x) => x.Afbeelding_BestandID)
        .filter((x) => x !== null) as number[];

      const [tekstenResult, bestandenResult] = await Promise.all([
        tekstIDs.length === 0 ? null : api.v2.tekst.ophalenTekstenInAlleTalen({ tekstIDs }),
        bestandIDs.length === 0
          ? null
          : api.v2.bestand.ophalenBestanden({
              filterSchema: {
                filters: [
                  {
                    naam: 'IDS',
                    data: bestandIDs,
                  },
                ],
              },
            }),
      ]);

      const tekstenBijTekstID =
        tekstenResult === null
          ? {}
          : tekstenResult.teksten.reduce<IData['tekstenBijTekstID']>((acc, x) => {
              const arr = acc[x.TekstID] ?? [];
              arr.push(x);
              acc[x.TekstID] = arr;
              return acc;
            }, {});

      const bestandenBijID =
        bestandenResult === null
          ? {}
          : bestandenResult.bestanden.reduce<IData['bestandenBijID']>((acc, x) => {
              acc[x.ID] = x;
              return acc;
            }, {});

      const data: IData = {
        landingspaginas: landingspaginasResult.landingspaginas,
        tekstenBijTekstID,
        bestandenBijID,
      };
      setData(createReadyRemoteData(data));
    });
  }, [scopeBezig]);

  useEffect(() => {
    ophalenData();
  }, [ophalenData]);

  const keyExtractor = useCallback((x: IProductsoortLandingspagina) => x.ID, []);

  const kolommen = useMemo<ASPKolom<Kolom, IProductsoortLandingspagina>[]>(
    () => [
      {
        key: 'url_segment',
        label: 'Url segment',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 200,
        renderer: (x) => {
          const teksten = data.data!.tekstenBijTekstID[x.UrlSegment_TekstID] ?? [];
          const tekst = teksten[0] ?? null;
          if (tekst === null) {
            return null;
          }

          return tekst.Tekst;
        },
      },
      {
        key: 'plaatsnaam',
        label: 'Plaatsnaam',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 200,
        renderer: (x) => {
          const teksten = data.data!.tekstenBijTekstID[x.Plaatsnaam_TekstID] ?? [];
          const tekst = teksten[0] ?? null;
          if (tekst === null) {
            return null;
          }

          return tekst.Tekst;
        },
      },
      {
        key: 'specialisatie',
        label: 'Specialisatie',
        breedteType: EAspKolomBreedteType.Flex,
        flex: 1,
        renderer: (x) => {
          if (x.Specialisatie_TekstID === null) {
            return null;
          }
          const teksten = data.data!.tekstenBijTekstID[x.Specialisatie_TekstID] ?? [];
          const tekst = teksten[0] ?? null;
          if (tekst === null) {
            return null;
          }

          return (
            <span
              style={{
                display: '-webkit-box',
                WebkitLineClamp: 5,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
              }}
            >
              {tekst.Tekst}
            </span>
          );
        },
      },
      {
        key: 'afbeelding',
        label: 'Afbeelding',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 200,
        renderer: (x) => {
          if (x.Afbeelding_BestandID === null) {
            return null;
          }

          const bestand = data.data!.bestandenBijID[x.Afbeelding_BestandID];
          if (bestand === undefined) {
            return null;
          }

          return (
            <img
              src={bestand.url}
              alt="afbeelding"
              style={{ width: 150, height: 150, objectFit: 'contain' }}
            />
          );
        },
      },
    ],
    [data.data?.tekstenBijTekstID],
  );

  const handleToevoegen = useCallback(async () => {
    const gelukt = await globaleRenderer.render<boolean>(({ destroy }) => (
      <MuterenDialoog open onSuccess={() => destroy(true)} onAnnuleren={() => destroy(false)} />
    ));
    if (!gelukt) {
      return;
    }

    await ophalenData();
  }, [globaleRenderer, ophalenData]);

  const handleWijzigenRij = useCallback(
    async (rij: IProductsoortLandingspagina) => {
      const gelukt = await globaleRenderer.render<boolean>(({ destroy }) => (
        <MuterenDialoog
          open
          id={rij.ID}
          onSuccess={() => destroy(true)}
          onAnnuleren={() => destroy(false)}
        />
      ));
      if (!gelukt) {
        return;
      }

      await ophalenData();
    },
    [globaleRenderer, ophalenData],
  );

  const handleVerwijderenRij = useCallback(
    async (rij: IProductsoortLandingspagina) => {
      await scopeBezig(async () => {
        await api.v2.product.soort.landingspagina.verwijderenLandingspagina({
          id: rij.ID,
        });
        await ophalenData();
      });
    },
    [ophalenData, scopeBezig],
  );

  return (
    <MenuLayout
      menu={
        <div className="d-flex align-items-center">
          <button
            className="btn btn-sm btn-light d-flex align-items-center"
            style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
            onClick={handleToevoegen}
          >
            <IconToevoegen
              style={{
                width: 18,
                height: 18,
                fill: Kleur.Grijs,
              }}
            />
            <span className="ml-2">Toevoegen</span>
          </button>
        </div>
      }
      body={
        data.state === ERemoteDataState.Pending ? (
          <LoadingSpinnerCenter />
        ) : (
          <ASPTabel
            keyExtractor={keyExtractor}
            kolommen={kolommen}
            rijen={data.data!.landingspaginas}
            isBezig={isBezig}
            onWijzigenRij={handleWijzigenRij}
            onVerwijderenRij={handleVerwijderenRij}
            rijHoogte={125}
          />
        )
      }
    />
  );
};

export default Landingpaginas;
