import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import Tabblad, { EOrientatie, ITabblad } from '../../../../../components/layout/Tabblad';
import { IAspGebruikerprofielFormData, InstellingenDialoogFormikContext } from '../index';
import ProfielForm, { IProfielFormProps } from './ProfielForm';
import { RootStoreContext } from '../../../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import { EResultType } from '../../../../../stores/CheckStore';
import { v4 as uuidv4 } from 'uuid';

interface IProps {}

const ProfielenTabblad = observer((props: IProps) => {
  const { checkStore } = useContext(RootStoreContext);
  const { formikProps } = useContext(InstellingenDialoogFormikContext);

  const [tabblad, setTabblad] = useState<number | string | null>(null);
  const tabbladen = useMemo<ITabblad<number | string, IProfielFormProps>[]>(() => {
    return formikProps.values.profielen.map((profielData) => {
      return {
        id: profielData.localID,
        label: profielData.naam,
        content: ProfielForm,
      };
    });
  }, [formikProps.values.profielen]);

  const checkTabblad = useCallback(() => {
    if (tabbladen.length === 0) {
      setTabblad(null);
      return;
    }
    if (tabblad !== null && tabbladen.findIndex((x) => x.id === tabblad) !== -1) {
      return;
    }
    const tabbladId = tabbladen[0].id;
    setTabblad(tabbladId);
  }, [tabblad, tabbladen, setTabblad]);

  // Voorselecteren eerste tabblad
  useEffect(() => {
    checkTabblad();
  }, [tabbladen]);

  const geselecteerd = useMemo(
    () => (tabbladen.findIndex((x) => x.id === tabblad) === -1 ? null : tabblad),
    [tabbladen, tabblad],
  );

  const tabbladComponentProps = useMemo<IProfielFormProps | null>(() => {
    if (tabblad === null) {
      return null;
    }
    return {
      formData: formikProps.values.profielen.find((x) => x.localID === tabblad)!,
      onFormDataChange: (data) =>
        formikProps.setFieldValue(
          'profielen',
          formikProps.values.profielen.map((x) => {
            if (x.localID === data.localID) {
              return data;
            }
            return x;
          }),
        ),
    };
  }, [tabblad, formikProps.values.profielen]);

  return (
    <div>
      <Tabblad<number | string, IProfielFormProps>
        geselecteerd={geselecteerd}
        onSelectieChange={(x) => setTabblad(x)}
        tabbladen={tabbladen}
        onTabToevoegen={() => {
          const profielData: IAspGebruikerprofielFormData = {
            localID: uuidv4(),
            naam: 'Naamloos',
            aspLocID: null,
            voipAccID: null,
          };
          formikProps.setFieldValue('profielen', [...formikProps.values.profielen, profielData]);
          setTabblad(profielData.localID);
        }}
        onTabVerwijderen={async (id) => {
          if (
            (await checkStore.bevestigen({ inhoud: 'Bevestig verwijderen gebruikersprofiel' }))
              .type === EResultType.Annuleren
          ) {
            return;
          }

          formikProps.setFieldValue(
            'profielen',
            formikProps.values.profielen.filter((x) => x.localID !== id),
          );
        }}
        orientatie={EOrientatie.Verticaal}
        verticaleBreedte={200}
        options={{
          tabbladComponentProps: tabbladComponentProps!,
        }}
      />
    </div>
  );
});

export default ProfielenTabblad;
