import React, { useCallback, useContext, useMemo, useState } from 'react';
import Tegel from '../../../../../components/tegel/Tegel';
import {
  IAfwijzenDuplicaatSuggestieParams,
  IRegistreerDuplicatenParams,
  IRelatieDuplicaatSuggestie,
} from '../../../../../../../shared/src/api/v2/relatie/duplicaat';
import { Kleur } from '../../../../../bedrijfslogica/constanten';
import RelatieVisualisatie from '../../../../../components/personalia/RelatieVisualisatie';
import { IconInklappen, IconKruis, IconUitklappen } from '../../../../../components/Icons';
import VerticaleScheidingslijn from '../../../../../components/layout/VerticaleScheidingslijn';
import HorizontaleScheidingslijn from '../../../../../components/layout/HorizontaleScheidingslijn';
import DuplicatenTabel from './DuplicatenTabel';
import api from '../../../../../api';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../../../stores/RootStore';
import { EResultType } from '../../../../../stores/CheckStore';
import { format } from 'date-fns';

interface IProps {
  suggestie: IRelatieDuplicaatSuggestie;
  uitgeklapt: boolean;
  onUitgeklaptChange: (uitgeklapt: boolean) => void;
  onVerversenAangevraagd: () => void;
}

const SuggestieItem: React.FC<IProps> = observer((props) => {
  const { suggestie, uitgeklapt, onUitgeklaptChange, onVerversenAangevraagd } = props;
  const { checkStore } = useContext(RootStoreContext);

  const [selectie, setSelectie] = useState<number[]>([]);
  const [vigerendeRelID, setVigerendeRelID] = useState<number | null>(null);

  const handleRegistreerDuplicaten = useCallback(async () => {
    const vervangenRelIDs = selectie.filter((x) => x !== vigerendeRelID!);

    const params: IRegistreerDuplicatenParams = {
      relDupSugID: suggestie.RelDupSugID,
      vigerendeRelID: vigerendeRelID!,
      vervangenRelIDs,
    };
    const checkData = await api.v2.relatie.duplicaat.checkRegistreerDuplicaten(params);
    if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
      return;
    }

    if (
      (
        await checkStore.bevestigen({
          inhoud: (
            <span>
              <p>Relatie-duplicaten:</p>
              <ul>
                {vervangenRelIDs.map((relID) => (
                  <li key={relID}>
                    <RelatieVisualisatie relID={relID} options={{ relatienummerWeergeven: true }} />
                  </li>
                ))}
              </ul>
              <span className="d-flex align-items-center">
                worden beschouwd als &nbsp;
                <RelatieVisualisatie
                  relID={vigerendeRelID!}
                  options={{ relatienummerWeergeven: true }}
                />
              </span>
            </span>
          ),
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    await api.v2.relatie.duplicaat.registreerDuplicaten(params);
    await onVerversenAangevraagd();
  }, [suggestie, selectie, vigerendeRelID, onVerversenAangevraagd]);

  const handleAfwijzen = useCallback(
    async (ev) => {
      ev.preventDefault();

      if (
        (
          await checkStore.bevestigen({
            inhoud: (
              <div className="d-flex flex-column">
                <h5>Confirmatie afwijzen suggestie</h5>
                <span className="text-muted mt-1">
                  Let op: Door het af te wijzen van de suggestie worden alle relaties die bij deze
                  suggestie horen beschouwd als géén duplicaat van ieder ander.
                </span>
                <span className="text-muted mt-1">
                  Als er dus bepaalde relaties in deze suggestie wel duplicaten zijn van een ander,
                  dan kan je deze los selecteren en toewijzen, en vervolgens de overgebleven
                  relaties afwijzen d.m.v. deze knop.
                </span>
              </div>
            ),
          })
        ).type === EResultType.Annuleren
      ) {
        return;
      }
      const params: IAfwijzenDuplicaatSuggestieParams = {
        relDupSugID: suggestie.RelDupSugID,
      };
      await api.v2.relatie.duplicaat.afwijzenDuplicaatSuggestie(params);
      await onVerversenAangevraagd();
    },
    [onVerversenAangevraagd, suggestie],
  );

  const magDuplicatenRegistreren = useMemo(() => {
    return vigerendeRelID !== null && selectie.length > 1;
  }, [vigerendeRelID, selectie]);

  return (
    <Tegel
      rootProps={{
        style: { backgroundColor: Kleur.Wit },
      }}
    >
      <div className="d-flex align-items-center">
        <div className="d-flex flex-fill">
          <div className="d-flex flex-fill align-items-center flex-wrap">
            {suggestie.duplicaten
              .map((duplicaat) => <RelatieVisualisatie relID={duplicaat.RelID} />)
              .map((el, i) => {
                if (i !== suggestie.duplicaten.length - 1) {
                  return <div className="d-flex">{el}&nbsp;–&nbsp;</div>;
                }
                return el;
              })}
          </div>
          <div>{format(new Date(suggestie.RecordGeregistreerd), 'dd-MM-yyyy HH:mm')}</div>
          <a
            href="#"
            className="d-flex align-items-center justify-content-center ml-2"
            onClick={handleAfwijzen}
          >
            Duplicaatsuggestie afwijzen
          </a>
        </div>
        <VerticaleScheidingslijn className="ml-3" />
        <button
          className="d-flex align-items-center justify-content-center ml-2"
          style={{
            outline: 0,
            background: 0,
            border: 0,
          }}
          onClick={() => onUitgeklaptChange(!uitgeklapt)}
        >
          {uitgeklapt ? (
            <IconInklappen style={{ fill: Kleur.Grijs, width: 24, height: 24 }} />
          ) : (
            <IconUitklappen style={{ fill: Kleur.Grijs, width: 24, height: 24 }} />
          )}
        </button>
      </div>
      {uitgeklapt && (
        <>
          <HorizontaleScheidingslijn className="mt-2" />
          <div className="flex-fill d-flex flex-column mt-2">
            <div className="flex-fill d-flex flex-column">
              <DuplicatenTabel
                duplicaten={suggestie.duplicaten}
                selectie={selectie}
                onSelectieChange={setSelectie}
                vigerendeRelID={vigerendeRelID}
                onVigerendeRelIDChange={setVigerendeRelID}
              />
            </div>
            <div className="mt-3 d-flex align-items-center">
              <button
                className="btn btn-light"
                style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                onClick={handleRegistreerDuplicaten}
                disabled={!magDuplicatenRegistreren}
              >
                Registreer duplicaten
              </button>
            </div>
          </div>
        </>
      )}
    </Tegel>
  );
});

export default SuggestieItem;
