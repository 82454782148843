import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import IDialoogProps from '../../../core/IDialoogProps';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { Field, FieldProps, Formik, FormikActions, FormikErrors, FormikProps } from 'formik';
import Combobox, { IOptie } from '../../../components/formulier/Combobox';
import { RootStoreContext } from '../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import api from '../../../api';
import * as Yup from 'yup';
import FormikVeldFout from '../../../components/formulier/FormikVeldFout';
import { IOphalenOpdrachtregelsResultElement } from '../../../../../shared/src/api/v2/transport/opdracht';
import Dialoog from '../../../components/dialogen/Dialoog';
import LoadingSpinner from '../../../components/Gedeeld/LoadingSpinner';
import nameof from '../../../core/nameOf';
import VinkVeld from '../../formulier/VinkVeld';
import { EResultType } from '../../../stores/CheckStore';

interface IProps extends IDialoogProps<{}> {
  id: number;
}

interface IFormikValues {
  aansluitenTrekschakelaar: boolean | null;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  aansluitenTrekschakelaar: 'Trekschakelaar',
};

const WijzigenRegelDialoog = observer((props: IProps) => {
  const { onAnnuleren, onSuccess, open } = props;
  const { checkStore } = useContext(RootStoreContext);
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const [regel, setRegel] = useState<IOphalenOpdrachtregelsResultElement | null>(null);

  useEffect(() => {
    (async () => {
      const result = await api.v2.transport.opdracht.ophalenOpdrachtregels({
        filterSchema: { filters: [{ naam: 'IDS', data: [props.id] }] },
      });
      setRegel(result.regels[0]);
    })();
  }, [props.id]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      const params = {
        trsRegID: props.id,
        verdieping: null, // TODO
        aansluitenTrekschakelaar: values.aansluitenTrekschakelaar,
        product:
          regel!.product === null
            ? null
            : { typeID: regel!.product.TypeID, prodID: regel!.product.ProdID },
      };
      // const checkData = await api.v2.transport.opdracht.check(params);
      // if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
      //   return;
      // }

      await api.v2.transport.opdracht.wijzigenOpdrachtregel(params);

      onSuccess({});
      actions.setSubmitting(false);
    },
    [onSuccess],
  );

  const initieleWaarden = useMemo<IFormikValues | null>(() => {
    if (regel === null) {
      return null;
    }

    return {
      aansluitenTrekschakelaar: regel.AansluitenTrekschakelaar,
    };
  }, [regel]);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        aansluitenTrekschakelaar: Yup.boolean(),
      }),
    [],
  );

  return (
    <Dialoog index={props.dialoogIndex || 0}>
      {regel === null ? (
        <LoadingSpinner />
      ) : (
        <>
          <ModalHeader>
            <ModalTitle>Wijzigen transportregel</ModalTitle>
          </ModalHeader>
          <Formik<IFormikValues>
            onSubmit={handleSubmit}
            initialValues={initieleWaarden!}
            isInitialValid
            enableReinitialize
            // validationSchema={validationSchema}
            render={(formikProps: FormikProps<IFormikValues>) => {
              const { submitForm, isSubmitting, values, isValid } = formikProps;
              return (
                <>
                  <ModalBody>
                    <div className="form-group">
                      <div className="row">
                        <div className="col-12 mt-3">
                          <div className="col-6">
                            <div className="d-flex align-items-center">
                              <Field
                                name={nameof<IFormikValues>('aansluitenTrekschakelaar')}
                                render={(fieldProps: FieldProps<IFormikValues>) => {
                                  const { field, form } = fieldProps;
                                  return (
                                    <VinkVeld
                                      aangevinkt={field.value}
                                      onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                    />
                                  );
                                }}
                              />
                              <label>&nbsp;</label>
                              <label>{veldnamen.aansluitenTrekschakelaar}</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ModalBody>
                  <ModalFooter className="d-flex justify-content-start">
                    <button
                      className="btn btn-primary"
                      onClick={submitForm}
                      disabled={isSubmitting || !isValid}
                      style={{ width: 100 }}
                    >
                      Ok
                    </button>
                    <button
                      className="btn btn-secondary"
                      onClick={onAnnuleren}
                      style={{ width: 100 }}
                    >
                      Annuleren
                    </button>
                  </ModalFooter>
                </>
              );
            }}
          />
        </>
      )}
    </Dialoog>
  );
});

export default WijzigenRegelDialoog;
