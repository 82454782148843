import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import IDialoogProps from '../../../../core/IDialoogProps';
import Dialoog from '../../../../components/dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import * as Yup from 'yup';
import api from '../../../../api';
import nameOf from '../../../../core/nameOf';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../../stores/RootStore';
import VinkVeld from '../../../../components/formulier/VinkVeld';
import {
  IOphalenPremiumsResultElement,
  IWijzigenPremiumParams,
} from '../../../../../../shared/src/api/v2/transport/opdracht/premium';
import Combobox from '../../../../components/formulier/Combobox';
import { EProductsoort } from '../../../../bedrijfslogica/enums';
import { IOphalenProducttypenResultElement } from '../../../../../../shared/src/api/v2/product/type';
import ITaalTekst from '../../../../../../shared/src/models/talen/ITaalTekst';
import MeertaligTekstveld from '../../../../components/formulier/MeertaligTekstveld';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../models/IRemoteData';
import { IOphalenTekstenResultElement } from '../../../../../../shared/src/api/v2/tekst';
import {
  IOphalenLeveropdrachtenResultElement,
  IWijzigenOpvolgingParams,
} from '../../../../../../shared/src/api/v2/opvolging/leveropdracht';
import FormikVeldFout from '../../../../components/formulier/FormikVeldFout';

interface IProps extends IDialoogProps<null> {
  id: number;
}

interface IFormikValues {
  notities: string;
  nietOpvolgen: boolean;
  isAfgehandeld: boolean;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  notities: 'Notities',
  nietOpvolgen: 'Niet opvolgen',
  isAfgehandeld: 'Afgehandeld',
};

const WijzigenDialoog: React.FC<IProps> = observer((props) => {
  const { dialoogIndex, onAnnuleren, onSuccess } = props;
  const { checkStore } = useContext(RootStoreContext);
  const formikRef = useRef<Formik<IFormikValues>>(null);

  const [opvolging, setOpvolging] = useState<IOphalenLeveropdrachtenResultElement | null>(null);

  const ophalenProducttypen = useCallback(async () => {
    const result = await api.v2.opvolging.leveropdracht.ophalenOpvolgingen({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: [props.id],
          },
        ],
      },
    });

    setOpvolging(result.opvolgingen[0]);
  }, [props.id]);

  useEffect(() => {
    ophalenProducttypen();
  }, [ophalenProducttypen]);

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (opvolging === null) {
      return null;
    }
    return {
      notities: opvolging.Notities ?? '',
      nietOpvolgen: opvolging.NietOpvolgen,
      isAfgehandeld: opvolging.IsAfgehandeld,
    };
  }, [opvolging]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      if (opvolging === null) {
        return;
      }
      actions.setSubmitting(true);

      const params: IWijzigenOpvolgingParams = {
        id: props.id,
        notities: values.notities,
        nietOpvolgen: values.nietOpvolgen,
        isAfgehandeld: values.isAfgehandeld,
      };

      await api.v2.opvolging.leveropdracht.wijzigenOpvolging(params);

      actions.setSubmitting(false);
      onSuccess(null);
    },
    [opvolging],
  );

  const validationSchema = useMemo(() => {
    const fields: Partial<Record<keyof IFormikValues, any>> = {
      // notities: Yup.string().required(),
    };
    return Yup.object().shape(fields);
  }, []);

  return (
    <Dialoog index={dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Wijzigen opvolging</ModalTitle>
      </ModalHeader>
      {initialValues === null ? (
        <div className="flex-fill d-flex align-items-center justify-content-center p-3">
          <LoadingSpinner />
        </div>
      ) : (
        <Formik<IFormikValues>
          ref={formikRef}
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
          render={(formikProps: FormikProps<IFormikValues>) => {
            const { submitForm, isSubmitting, isValid } = formikProps;
            return (
              <>
                <ModalBody>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-12">
                        <label>{veldnamen.notities}</label>
                        <Field
                          name={nameOf<IFormikValues>('notities')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return <textarea {...field} className="form-control" rows={2} />;
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <Field
                          name={nameOf<IFormikValues>('nietOpvolgen')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            return (
                              <>
                                <div className="d-flex align-items-center">
                                  <VinkVeld
                                    aangevinkt={field.value}
                                    onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                  />
                                  <span className="ml-2">Niet opvolgen</span>
                                </div>
                                <FormikVeldFout fieldProps={fieldProps} />
                              </>
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <Field
                          name={nameOf<IFormikValues>('isAfgehandeld')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            return (
                              <>
                                <div className="d-flex align-items-center">
                                  <VinkVeld
                                    aangevinkt={field.value}
                                    onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                  />
                                  <span className="ml-2">Afgehandeld</span>
                                </div>
                                <FormikVeldFout fieldProps={fieldProps} />
                              </>
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </ModalBody>

                <ModalFooter className="d-flex flex-row justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    style={{
                      width: 100,
                    }}
                    disabled={isSubmitting || !isValid}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={onAnnuleren}
                    style={{
                      width: 100,
                    }}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Dialoog>
  );
});

export default WijzigenDialoog;
