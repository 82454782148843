import React, { useContext, useEffect, useMemo } from 'react';
import { RootStoreContext } from '../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import { ERemoteDataState } from '../../../models/IRemoteData';
import {
  IUrlState as ITransportopdrachtenOverzichtUrlState,
  defaultUrlState as transportopdrachtenOverzichtDefaultUrlState,
} from '../../../components/kaart/entiteiten/Transport/Opdrachten';
import { genereerUrlStateQueryParam } from '../../../core/useUrlState';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  defaultTransportOpdrachtenPlanningUrlState,
  ITransportOpdrachtenPlanningUrlState,
} from '../../../paginas/Transport/Opdrachten/Planning';
import {
  defaultTransportOpdrachtenUitstaandUrlState,
  ITransportOpdrachtenUitstaandUrlState,
} from '../../../paginas/Transport/Opdrachten/Uitstaand';
import {
  defaultTransportOpdrachtenAfgehandeldUrlState,
  ITransportOpdrachtenAfgehandeldUrlState,
} from '../../../paginas/Transport/Opdrachten/Afgehandeld';

interface IProps extends RouteComponentProps {
  trsOpdID: number;
  relID?: number;
}

export enum EOpdrachtstatus {
  Planning = 1,
  Uitstaand = 2,
  Afgehandeld = 3,
}

const TransportopdrachtVisualisatie: React.FC<IProps> = observer((props) => {
  const { transportopdrachtStore } = useContext(RootStoreContext);

  useEffect(() => {
    if (
      transportopdrachtStore.transportopdrachten.state === ERemoteDataState.Pending ||
      transportopdrachtStore.transportopdrachten.data![props.trsOpdID] === undefined
    ) {
      transportopdrachtStore.enqueueOphalenTransportopdrachten([props.trsOpdID]);
    }
  }, [props.trsOpdID]);

  const transportopdracht = useMemo(() => {
    if (
      transportopdrachtStore.transportopdrachten.state === ERemoteDataState.Pending ||
      transportopdrachtStore.transportopdrachten.data![props.trsOpdID] === undefined
    ) {
      return null;
    }
    return transportopdrachtStore.transportopdrachten.data![props.trsOpdID]!;
  }, [props.trsOpdID, transportopdrachtStore.transportopdrachten]);

  return (
    <span>
      {transportopdracht === null ? (
        <span>Laden...</span>
      ) : (
        <a
          href="#"
          className="d-flex align-items-center"
          onClick={(ev) => {
            ev.preventDefault();

            if (props.relID === undefined) {
              switch (transportopdracht!.Opdrachtstatus as EOpdrachtstatus) {
                case EOpdrachtstatus.Planning: {
                  const urlState: ITransportOpdrachtenPlanningUrlState = {
                    ...defaultTransportOpdrachtenPlanningUrlState,
                    opdrachtenSelectie: [transportopdracht!.TrsOpdID],
                  };

                  const query = genereerUrlStateQueryParam(urlState);
                  props.history.push(`/transport/opdrachten/planning?state=${query}`);
                  break;
                }
                case EOpdrachtstatus.Uitstaand: {
                  const urlState: ITransportOpdrachtenUitstaandUrlState = {
                    ...defaultTransportOpdrachtenUitstaandUrlState,
                    opdrachtenSelectie: [transportopdracht!.TrsOpdID],
                  };

                  const query = genereerUrlStateQueryParam(urlState);
                  props.history.push(`/transport/opdrachten/uitstaand?state=${query}`);
                  break;
                }
                case EOpdrachtstatus.Afgehandeld: {
                  const urlState: ITransportOpdrachtenAfgehandeldUrlState = {
                    ...defaultTransportOpdrachtenAfgehandeldUrlState,
                    opdrachtenSelectie: [transportopdracht!.TrsOpdID],
                  };

                  const query = genereerUrlStateQueryParam(urlState);
                  props.history.push(`/transport/opdrachten/afgehandeld?state=${query}`);
                  break;
                }
              }
              return;
            }

            const urlState: ITransportopdrachtenOverzichtUrlState = {
              ...transportopdrachtenOverzichtDefaultUrlState,
              uitgeklapteOpdrachten: [transportopdracht!.TrsOpdID],
              opdrachtenSelectie: [transportopdracht!.TrsOpdID],
            };
            const query = genereerUrlStateQueryParam(urlState);
            props.history.push(`/klant/${props.relID}/transport/opdracht?state=${query}`);
          }}
        >
          {/* <ContractStatusIndicatie status={contract.StatusnaamEnum as EContractStatus} /> */}
          <span className="ml-2">{transportopdracht.Opdrachtnummer}</span>
        </a>
      )}
    </span>
  );
});

export default withRouter(TransportopdrachtVisualisatie);
