import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import IDialoogProps from '../../../../core/IDialoogProps';
import Dialoog from '../../../../components/dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import * as Yup from 'yup';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../../stores/RootStore';
import Combobox from '../../../../components/formulier/Combobox';
import nameOf from '../../../../core/nameOf';
import api from '../../../../api';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import { EResultType } from '../../../../stores/CheckStore';
import Skeleton from 'react-loading-skeleton';
import { format } from 'date-fns';
import VinkVeld from '../../../../components/formulier/VinkVeld';

interface IDialoogResult {}

interface IProps extends IDialoogProps<IDialoogResult> {
  ids: number[];
}

interface IFormikValues {
  directVersturen: boolean;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  directVersturen: 'Direct versturen',
};

const VersturenDialoog: React.FC<IProps> = observer((props) => {
  const { children, dialoogIndex, onAnnuleren, onSuccess, open } = props;
  const { checkStore, klantkaartStore } = useContext(RootStoreContext);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      const params = {
        ids: props.ids,
      };

      const checkData = await api.v2.inkoop.retour.checkVersturenAanmeldingen(params);
      const checkResult = await checkStore.controleren({
        checkData,
      });
      if (checkResult.type === EResultType.Annuleren) {
        actions.setSubmitting(false);
        return;
      }

      const inhoud = <span>Wil je de geselecteerde producten aanmelden voor Retour?</span>;
      if (
        (
          await checkStore.bevestigen({
            inhoud,
          })
        ).type === EResultType.Annuleren
      ) {
        actions.setSubmitting(false);
        return;
      }

      if (values.directVersturen) {
        await api.v2.inkoop.retour.versturenAanmeldingen(params);
      } else {
        await api.v2.inkoop.retour.versturenAanmeldingen(params);
      }

      props.onSuccess({});
      actions.setSubmitting(false);
    },
    [props.ids],
  );

  const initialValues = useMemo<IFormikValues | null>(() => {
    return {
      directVersturen: true,
    };
  }, []);

  const validationSchema = useMemo(() => Yup.object().shape({}), []);

  return (
    <Dialoog index={dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Versturen Retouraanmelding</ModalTitle>
      </ModalHeader>
      {initialValues !== null && (
        <Formik<IFormikValues>
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize
          isInitialValid
          render={(formikProps: FormikProps<IFormikValues>) => {
            const { submitForm, isValid, isSubmitting } = formikProps;

            return (
              <>
                <ModalBody>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-12 mt-3">
                        <Field
                          name={nameOf<IFormikValues>('directVersturen')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <>
                                <span className="d-flex align-items-center">
                                  <VinkVeld
                                    aangevinkt={field.value}
                                    onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                    disabled
                                  />
                                  <span className="ml-2">{veldnamen.directVersturen}</span>
                                </span>
                              </>
                            );
                          }}
                        
                        />
                      </div>
                    </div>
                  </div>
                </ModalBody>

                <ModalFooter className="d-flex flex-row justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    style={{ width: 100 }}
                    disabled={!isValid || isSubmitting}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={onAnnuleren}
                    style={{ width: 100 }}
                    disabled={isSubmitting}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Dialoog>
  );
});

export default VersturenDialoog;
