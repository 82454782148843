import React, { HTMLProps, useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import NotificatieItem from '../NotificatieItem';
import { IOphalenNotificatiesResultElement } from '../../../../../shared/src/api/v2/notificaties/notificaties';
import { signaleringTimeoutMs } from '../../../stores/NotificatieStore';
import { notificationTransitionTimeMs } from '../style';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../stores/RootStore';
import useAudio from '../../../core/useAudio';
import useIntrasessieAudio from '../../../core/useIntrasessieAudio';

interface IProps {
  notificatie: IOphalenNotificatiesResultElement;
  props?: HTMLProps<HTMLDivElement>;
}

const SignaleringWrapper: React.FC<IProps> = observer((props) => {
  const notif = props.notificatie;
  const { notificatieStore } = useContext(RootStoreContext);
  const [playing, togglePlay] = useIntrasessieAudio('/audio/notificatie.mp3');

  const [tonen, setTonen] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      togglePlay();
      setTonen(true);
    }, 50);
    setTimeout(() => setTonen(false), signaleringTimeoutMs - notificationTransitionTimeMs);
  }, []);

  return (
    <div
      key={notif.ID}
      className={classNames(['notificatie', tonen ? 'notificatie-tonen' : null])}
      {...props.props}
      onClick={async () => {
        setTonen(false);
        await new Promise((resolve) => setTimeout(() => resolve(), notificationTransitionTimeMs));
        notificatieStore.signaleringVerwijderen(notif.ID);
      }}
    >
      <NotificatieItem notificatie={notif} signaleringModus />
    </div>
  );
});

export default SignaleringWrapper;
