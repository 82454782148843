import * as React from 'react';
import { useRef, useState } from 'react';
import InactiefOverlay from '../../InactiefOverlay';
import { IconKruis, IconZoeken } from '../../Icons';
import { Kleur } from '../../../bedrijfslogica/constanten';
import styled from 'styled-components';

const Input = styled.input`
  background: transparent;
  border: 0;
  width: 100%;
  height: 30px;
  margin-left: 3px;
  color: ${Kleur.DonkerGrijs};
  outline: 0;
`;

interface IProps {
  zoekterm: string;
  onZoektermChange: (zoekterm: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
}

const ZoekVeld = (props: IProps) => {
  const [isSearching, setIsSearching] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [hovered, setHovered] = useState(false);

  return (
    <InactiefOverlay
      isInactief={isSearching}
      element={
        <div
          className="d-flex justify-content-center align-items-center flex-fill"
          style={{
            position: 'relative',
            backgroundColor: `rgba(150,150,150,0.2)`,
            borderRadius: 3,
            border: hovered ? `3px solid ${Kleur.SelectieBlauw}` : 'none',
            boxSizing: 'border-box',
            padding: hovered ? 0 : 2,
            width: 350,
            height: 34,
          }}
        >
          <div
            className="d-flex flex-column justify-content-center align-items-center"
            style={{
              height: '100%',
              marginLeft: 7,
            }}
          >
            <IconZoeken
              style={{ fill: Kleur.Grijs, width: 18, height: 18, position: 'relative', top: 1 }}
            />
          </div>
          <Input
            ref={inputRef}
            className="flex-fill"
            value={props.zoekterm}
            onChange={(ev) => props.onZoektermChange(ev.target.value)}
            onKeyUp={async (ev) => {
              if (ev.key === 'Enter') {
                // await performSearch();
              }
            }}
            placeholder="Zoek naar bestanden en mappen"
            onFocus={() => {
              setHovered(true);
              props.onFocus?.();
            }}
            onBlur={() => {
              setHovered(false);
              props.onBlur?.();
            }}
          />
          {props.zoekterm.length !== 0 && (
            <button
              className="d-flex justify-content-center align-items-center"
              style={{
                position: 'absolute',
                right: 0,
                outline: 0,
                border: 0,
                background: 0,
                height: '100%',
              }}
              onClick={() => props.onZoektermChange('')}
            >
              <IconKruis
                style={{
                  fill: Kleur.Grijs,
                  height: 18,
                  width: 18,
                  position: 'relative',
                  top: 1,
                  right: 4,
                }}
              />
            </button>
          )}
        </div>
      }
    />
  );
};

export default ZoekVeld;
