import React, { useMemo } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import api from '../../../api';
import { genereerUrlStateQueryParam } from '../../../core/useUrlState';
import { IUrlState } from '../../Communicatie/Contactverzoek';
import { IActieProps } from '../index';

interface IData {
  webbetalingID: number;
}

const WEBBETALING_MET_AFWIJKENDE_IBAN: React.VoidFunctionComponent<IActieProps> = (
  props: IActieProps,
) => {
  const data = useMemo(() => JSON.parse(props.controltaak.Data!) as IData, [
    props.controltaak.Data,
  ]);

  return (
    <a
      href="#"
      onClick={async () => {
        const webbetaling = (
          await api.v2.extern.betaling.ophalenBetalingen({
            filterSchema: { filters: [{ naam: 'IDS', data: [data.webbetalingID] }] },
          })
        ).betalingen[0];

        const account = (
          await api.v2.account.ophalenAccounts({
            filterSchema: { filters: [{ naam: 'IDS', data: [webbetaling.AccID] }] },
          })
        )[0];

        props.history.push(`/klant/${account.RelID}/webbetalingen`);
      }}
    >
      Naar website-betaling
    </a>
  );
};

export default WEBBETALING_MET_AFWIJKENDE_IBAN;
