import React, { useContext, useEffect, useMemo, useState, useCallback } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import useUrlState from '../../../../core/useUrlState';
import api from '../../../../api';
import _ from 'lodash';
import { IOphalenWebbetalingenResultElement } from '../../../../../../shared/src/api/v2/website/betaling';
import {
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import MenuLayout from '../../../../components/MenuLayout';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  DataTypeProvider,
  RowDetailState,
  VirtualTable as VirtualTableBase,
} from '@devexpress/dx-react-grid';
import nameof from '../../../../core/nameOf';
import RelatieVisualisatie from '../../../../components/personalia/RelatieVisualisatie';
import { format } from 'date-fns';
import { IOphalenAccountsResultElement } from '../../../../../../shared/src/api/v2/account/account';
import FormatteerBedrag from '../../../../components/MutatieBedrag';
import { Kleur as EKleur } from '../../../../bedrijfslogica/constanten';
import FactuurVisualisatie from '../../../../components/entiteitVisualisaties/FactuurVisualisatie';
import UitlegTooltip from '../../../../components/formulier/UitlegTooltip';

interface IProps extends RouteComponentProps {}

interface IUrlState {}

const defaultUrlState: IUrlState = {};

export interface IRegel extends IOphalenWebbetalingenResultElement {}

const Betalingen: React.FC<IProps> = observer((props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);

  const [betalingen, setBetalingen] = useState<IRegel[] | null>(null);
  const [accounts, setAccounts] = useState<IOphalenAccountsResultElement[] | null>(null);

  const ophalenBetalingen = useCallback(async () => {
    const betalingen = (
      await api.v2.extern.betaling.ophalenBetalingen({
        filterSchema: {
          filters: [],
        },
        orderSchema: {
          orders: [
            {
              naam: 'STARTDATUM',
              richting: 'DESC',
            },
          ],
        },
      })
    ).betalingen;

    setBetalingen(betalingen);
  }, []);

  useEffect(() => {
    ophalenBetalingen();
  }, [ophalenBetalingen]);

  const ophalenAccounts = useCallback(async () => {
    if (betalingen === null) {
      return;
    }
    const accountsResult = await api.v2.account.ophalenAccounts({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: betalingen.map((x) => x.AccID),
          },
        ],
      },
    });
    setAccounts(accountsResult);
  }, [betalingen]);

  useEffect(() => {
    ophalenAccounts();
  }, [ophalenAccounts]);

  const keyExtractor = useCallback((row: IRegel) => row.ID, []);

  const kolommen = useMemo<TypedColumn<IRegel>[]>(
    () => [
      {
        name: 'Startdatum',
        title: 'Startdatum',
      },
      {
        name: '__ingelogdAls' as any,
        title: 'Ingelogd als',
      },
      {
        name: 'BetaalMethode',
        title: 'Methode',
      },
      {
        name: 'BetaalKenmerk',
        title: 'Betaalkenmerk',
      },
      {
        name: 'Bedrag',
        title: 'Bedrag',
      },
      {
        name: '__x' as any, // dummie
        title: ' ',
      },
      {
        name: '__laatsteStatus' as any,
        title: 'Status',
      },
      {
        name: '__facturen' as any,
        title: 'Facturen',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRegel>[]>(
    () => [
      {
        columnName: 'Startdatum',
        width: 135,
      },
      {
        columnName: '__ingelogdAls' as any,
        width: 300,
      },
      {
        columnName: 'BetaalMethode',
        width: 85,
      },
      {
        columnName: 'Bedrag',
        width: 135,
      },
      {
        columnName: '__x' as any,
        width: 50,
      },
      {
        columnName: 'BetaalKenmerk',
        width: 175,
      },
      {
        columnName: '__laatsteStatus' as any,
        width: 135,
      },
      {
        columnName: '__facturen' as any,
        width: 750,
      },
    ],
    [],
  );

  const kolomExtensies: VirtualTableBase.ColumnExtension[] = useMemo(() => {
    return [{ columnName: 'Bedrag', align: 'right' }];
  }, []);

  return (
    <>
      {betalingen === null || accounts === null ? (
        <div className="d-flex flex-column flex-fill align-items-center justify-content-center">
          <LoadingSpinner />
        </div>
      ) : (
        <MenuLayout
          body={
            <GridStyleWrapper height="calc(100vh - 55px)">
              <Grid rows={betalingen} columns={kolommen} getRowId={keyExtractor}>
                <DataTypeProvider
                  for={['__ingelogdAls']}
                  formatterComponent={(formatterProps) => {
                    const rij = formatterProps.row as IRegel;

                    if (rij.AccID === null) {
                      return (
                        <div className="d-flex align-items-center">
                          <span className="font-italic text-muted mr-2">
                            Gebruiker niet ingelogd
                          </span>
                          <UitlegTooltip inhoud="Als er bijv. wordt betaald via een link, dan hoeft de gebruiker niet in te loggen." />
                        </div>
                      );
                    }

                    const relID = accounts.find((x) => x.AccID === rij.AccID)!.RelID;
                    return <RelatieVisualisatie relID={relID} />;
                  }}
                />

                <DataTypeProvider
                  for={[nameof<IRegel>('Startdatum')]}
                  formatterComponent={(formatterProps) => {
                    return (
                      <span>{format(new Date(formatterProps.value), 'dd-MM-yyyy HH:mm')}</span>
                    );
                  }}
                />

                <DataTypeProvider
                  for={[nameof<IRegel>('Bedrag')]}
                  formatterComponent={(formatterProps) => {
                    return <FormatteerBedrag bedrag={formatterProps.value} rechtsUitlijnen />;
                  }}
                />

                <DataTypeProvider
                  for={['__laatsteStatus']}
                  formatterComponent={(formatterProps) => {
                    const rij = formatterProps.row as IRegel;

                    const status =
                      rij.events.length > 0 ? rij.events[rij.events.length - 1].Status : '';

                    const statusnaam =
                      status === 1
                        ? 'Betaald'
                        : status === 2
                        ? 'Geannuleerd'
                        : status === 3
                        ? 'Verlopen'
                        : status === 4
                        ? 'Mislukt'
                        : 'Onbekend';

                    return (
                      <span style={{ color: status === 1 ? EKleur.Grijs : EKleur.Rood }}>
                        {statusnaam}
                      </span>
                    );
                  }}
                />

                <DataTypeProvider
                  for={['__facturen']}
                  formatterComponent={(formatterProps) => {
                    const rij = formatterProps.row as IRegel;

                    return (
                      <span className="d-flex align-items-center" style={{ columnGap: 10 }}>
                        {rij.facturen.map((factuur) => (
                          <FactuurVisualisatie key={factuur.FactID} factID={factuur.FactID} />
                        ))}
                      </span>
                    );
                  }}
                />

                <VirtualTable
                  columnExtensions={kolomExtensies}
                  messages={{
                    noData: 'Geen webbetalingen',
                  }}
                />
                <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
                <TableHeaderRow />

                <RowDetailState defaultExpandedRowIds={[]} />
                {/* <TableRowDetail
                  toggleCellComponent={DXTableToggleCellComponent}
                  contentComponent={DetailComp}
                /> */}
              </Grid>
            </GridStyleWrapper>
          }
        />
      )}
    </>
  );
});

export default withRouter(Betalingen);
