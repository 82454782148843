import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import IDialoogProps from '../../../../core/IDialoogProps';
import Dialoog from '../../../../components/dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import * as Yup from 'yup';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../../stores/RootStore';
import nameof from '../../../../core/nameOf';
import api from '../../../../api';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import { EResultType } from '../../../../stores/CheckStore';
import { IOphalenAfbeeldingenResultElement } from '../../../../../../shared/src/api/v2/website/afbeelding';
import Combobox from '../../../../components/formulier/Combobox';
import { IOphalenDocumentsoortenResultElement } from '../../../../../../shared/src/api/v2/product/documentatie';
import TalenSelectie from '../../../../components/formulier/TalenSelectie';
import VinkVeld from '../../../../components/formulier/VinkVeld';

interface IDialoogResult {}

interface IProps extends IDialoogProps<IDialoogResult> {}

interface IFormikValues {
  naamEnum: string | null;
  naam: string;
  documentURL: string | null;
  prodDocSrtID: number;
  taalIDs: number[];
  meesturenNaLevering: boolean;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  naamEnum: 'NaamEnum',
  naam: 'Naam',
  documentURL: 'Bron URL',
  prodDocSrtID: 'Soort document',
  taalIDs: 'Talen',
  meesturenNaLevering: 'Meesturen na levering',
};

const ToevoegenDocumentDialoog: React.FC<IProps> = observer((props) => {
  const { children, dialoogIndex, onAnnuleren, onSuccess } = props;
  const { checkStore } = useContext(RootStoreContext);
  const formikRef = useRef<Formik<IFormikValues>>(null);
  const [soorten, setSoorten] = useState<IOphalenDocumentsoortenResultElement[] | null>(null);

  const ophalenSoorten = useCallback(async () => {
    const soortenResult = (
      await api.v2.product.documentatie.ophalenDocumentsoorten({
        filterSchema: { filters: [] },
        // taalID: null,
      })
    ).soorten;
    setSoorten(soortenResult);
  }, []);

  useEffect(() => {
    ophalenSoorten();
  }, [ophalenSoorten]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      const params = {
        naam: values.naam,
        naamEnum: values.naamEnum,
        documentURL: values.documentURL,
        prodDocSrtID: values.prodDocSrtID,
        taalIDs: values.taalIDs,
        meesturenNaLevering: values.meesturenNaLevering,
      };

      const checkData = await api.v2.product.documentatie.checkToevoegenDocument(params);
      const checkResult = await checkStore.controleren({
        checkData,
      });
      if (checkResult.type === EResultType.Annuleren) {
        onAnnuleren();
        return;
      }

      await api.v2.product.documentatie.toevoegenDocument(params);

      await props.onSuccess({});
      actions.setSubmitting(false);
    },
    [],
  );

  const initialValues = useMemo<IFormikValues>(() => {
    return {
      naamEnum: '',
      naam: '',
      documentURL: '',
      prodDocSrtID: 1,
      taalIDs: [],
      meesturenNaLevering: true,
    };
  }, []);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        naam: Yup.string().required(),
        naamEnum: Yup.string(),
      }),
    [],
  );

  return (
    <Dialoog index={dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Toevoegen document</ModalTitle>
      </ModalHeader>
      {initialValues !== null && (
        <Formik<IFormikValues>
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize
          // isInitialValid
          render={(formikProps: FormikProps<IFormikValues>) => {
            const { submitForm, isValid, isSubmitting } = formikProps;

            return (
              <>
                <ModalBody>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-12 mt-3">
                        {soorten !== null && (
                          <>
                            <label>{veldnamen.prodDocSrtID}</label>
                            <Field
                              name={nameof<IFormikValues>('prodDocSrtID')}
                              render={(fieldProps: FieldProps<IFormikValues>) => {
                                const { field, form } = fieldProps;

                                if (soorten === null) {
                                  return <LoadingSpinner />;
                                }

                                return (
                                  <Combobox
                                    legeOptieTonen
                                    geselecteerd={field.value}
                                    onSelectieChange={(x) => form.setFieldValue(field.name, x)}
                                    opties={soorten.map((x) => {
                                      return {
                                        id: x.ID,
                                        label: x.Naam,
                                      };
                                    })}
                                  />
                                );
                              }}
                            />
                          </>
                        )}
                      </div>

                      <div className="col-12 mt-3">
                        <label>{veldnamen.naam}</label>
                        <Field
                          name={nameof<IFormikValues>('naam')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field } = fieldProps;

                            return (
                              <>
                                <input type="text" className="form-control" {...field} />
                                {/* <FormikVeldFout fieldProps={fieldProps} /> */}
                              </>
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <label>{veldnamen.naamEnum}</label>
                        <Field
                          name={nameof<IFormikValues>('naamEnum')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field } = fieldProps;

                            return (
                              <>
                                <input type="text" className="form-control" {...field} />
                                {/* <FormikVeldFout fieldProps={fieldProps} /> */}
                              </>
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <label>{veldnamen.documentURL}</label>
                        <Field
                          name={nameof<IFormikValues>('documentURL')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field } = fieldProps;

                            return (
                              <>
                                <input type="text" className="form-control" {...field} />
                                {/* <FormikVeldFout fieldProps={fieldProps} /> */}
                              </>
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <label>{veldnamen.taalIDs}</label>
                        <Field
                          name={nameof<IFormikValues>('taalIDs')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            return (
                              <>
                                <TalenSelectie
                                  taalIDs={field.value}
                                  onChange={(taalIDs) => form.setFieldValue('taalIDs', taalIDs)}
                                  standaardTaalVoorselecteren
                                />
                                {/* <FormikVeldFout fieldProps={fieldProps} /> */}
                              </>
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 d-flex align-items-center mt-3">
                        <Field
                          name={nameof<IFormikValues>('meesturenNaLevering')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <VinkVeld
                                aangevinkt={field.value}
                                onGewijzigd={(x) => {
                                  form.setFieldValue(field.name, x);
                                }}
                              />
                            );
                          }}
                        />
                        <span className="ml-2">{veldnamen.meesturenNaLevering}</span>
                      </div>
                    </div>
                  </div>
                </ModalBody>

                <ModalFooter className="d-flex flex-row justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    style={{ width: 100 }}
                    disabled={!isValid || isSubmitting}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={onAnnuleren}
                    style={{ width: 100 }}
                    disabled={isSubmitting}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Dialoog>
  );
});

export default ToevoegenDocumentDialoog;
