import { create } from 'zustand';
import { IEmail, IMailMap, IEmailGebrXAspGebr } from '../../../../../shared/src/api/v2/email-v2';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
} from '../../../models/IRemoteData';
import api from '../../../api';
import {
  IOphalenDienstenResultElement,
  IOphalenEmailAccountsResultElement,
} from '../../../../../shared/src/api/v2/dienst/email/email';

export interface IEmailWerkbladV2Store {
  emailDienstenBijIndex: IRemoteData<Record<number, IOphalenDienstenResultElement>>;
  emailDienstenBijID: IRemoteData<Record<number, IOphalenDienstenResultElement>>;
  emailsBijIndex: IRemoteData<Record<number, IEmail>>;
  mailMappenBijIndex: IRemoteData<Record<number, IMailMap>>;
  mailMappenBijID: IRemoteData<Record<number, IMailMap>>;
  emailAccountsBijIndex: IRemoteData<Record<number, IOphalenEmailAccountsResultElement>>;
  emailAccountsBijID: IRemoteData<Record<number, IOphalenEmailAccountsResultElement>>;
  emailAccountsBijEmailDienstID: IRemoteData<Record<number, IOphalenEmailAccountsResultElement[]>>;
  emailGebrXAspGebrsVoorMij: IRemoteData<IEmailGebrXAspGebr[]>;
  ophalenEmailDiensten: () => Promise<void>;
  ophalenMailMappen: () => Promise<void>;
  ophalenEmailAccounts: () => Promise<void>;
  ophalenEmailGebrXAspGebrsVoorMij: (mijnAspGebrID: number) => Promise<void>;
}

export const useEmailWerkbladV2Store = create<IEmailWerkbladV2Store>((set, get) => ({
  emailDienstenBijIndex: createPendingRemoteData(),
  emailDienstenBijID: createPendingRemoteData(),
  emailsBijIndex: createPendingRemoteData(),
  mailMappenBijIndex: createPendingRemoteData(),
  mailMappenBijID: createPendingRemoteData(),
  emailAccountsBijIndex: createPendingRemoteData(),
  emailAccountsBijID: createPendingRemoteData(),
  emailAccountsBijEmailDienstID: createPendingRemoteData(),
  emailGebrXAspGebrsVoorMij: createPendingRemoteData(),
  ophalenEmailDiensten: async () => {
    const result = await api.v2.dienst.email.ophalenDiensten({});
    const data = result.diensten.reduce(
      (acc, curr, i) => ({
        ...acc,
        [i]: curr,
      }),
      {},
    );
    const dataBijID = result.diensten.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.EmailDienstID]: curr,
      }),
      {},
    );
    set({
      emailDienstenBijIndex: createReadyRemoteData(data),
      emailDienstenBijID: createReadyRemoteData(dataBijID),
    });
  },
  ophalenMailMappen: async () => {
    const result = await api.v2.emailV2.ophalenMailMappen({});
    const data = result.mailMappen.reduce(
      (acc, curr, i) => ({
        ...acc,
        [i]: curr,
      }),
      {},
    );
    const dataBijID = result.mailMappen.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.ID]: curr,
      }),
      {},
    );
    set({
      mailMappenBijIndex: createReadyRemoteData(data),
      mailMappenBijID: createReadyRemoteData(dataBijID),
    });
  },
  ophalenEmailAccounts: async () => {
    const result = await api.v2.email.ophalenEmailAccounts({});
    const dataBijIndex = result.accounts.reduce(
      (acc, curr, i) => ({
        ...acc,
        [i]: curr,
      }),
      {},
    );
    const dataBijID = result.accounts.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.EmailGebrID]: curr,
      }),
      {},
    );
    const dataBijEmailDienstID = result.accounts.reduce<
      Record<number, IOphalenEmailAccountsResultElement[]>
    >(
      (acc, curr) => ({
        ...acc,
        [curr.EmailDienstID]: [...(acc[curr.EmailDienstID] || []), curr],
      }),
      {},
    );
    set({
      emailAccountsBijIndex: createReadyRemoteData(dataBijIndex),
      emailAccountsBijID: createReadyRemoteData(dataBijID),
      emailAccountsBijEmailDienstID: createReadyRemoteData(dataBijEmailDienstID),
    });
  },
  ophalenEmailGebrXAspGebrsVoorMij: async (mijnAspGebrID) => {
    const result = await api.v2.emailV2.ophalenEmailGebrXAspGebrs({
      filterSchema: {
        filters: [
          {
            naam: 'ASP_GEBR_IDS',
            data: [mijnAspGebrID],
          },
        ],
      },
    });
    set({
      emailGebrXAspGebrsVoorMij: createReadyRemoteData(result.emailGebrXAspGebrs),
    });
  },
}));
