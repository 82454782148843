import React, { useCallback, useMemo } from 'react';
import { IOphalenRekeningenResultElement } from '../../../../../../../shared/src/api/v2/bank/bank';
import api from '../../../../../api';
import MultiComboboxV2, {
  EnkeleProvider,
  IRepresentatieProps,
  Provider,
} from '../../../../../components/formulier/MultiComboboxV2';
import { ASPKolom, EAspKolomBreedteType } from '../../../../../components/tabel/ASPTabel/types';
import FormatteerBedrag from '../../../../../components/MutatieBedrag';

enum ERekeningKolom {
  IBAN,
  Saldo,
}

interface IProps {
  bankRekID: number | null;
  onBankRekIDChange: (bankRekID: number | null) => void;
}

const RekeningCombobox = (props: IProps) => {
  const keyExtractor = useCallback((item: IOphalenRekeningenResultElement) => item.BankRekID, []);
  const enkeleProvider = useMemo<EnkeleProvider<number, IOphalenRekeningenResultElement>>(
    () => async (id) => {
      const result = await api.v2.bank.ophalenRekeningen({
        filterSchema: {
          filters: [
            {
              naam: 'IDS',
              data: [id],
            },
          ],
        },
      });
      return result.rekeningen[0];
    },
    [],
  );
  const provider = useMemo<Provider<number, IOphalenRekeningenResultElement>>(
    () => async (params) => {
      const result = await api.v2.bank.ophalenRekeningen({
        paginatie: params.paginatie,
      });

      const items = result.rekeningen.reduce(
        (acc, item, idx) => ({
          ...acc,
          [params.paginatie.index + idx]: item,
        }),
        params.huidigeBron,
      );

      return {
        items,
        totaalAantal: result.totaalAantal,
      };
    },
    [],
  );
  const representatieComponent = useMemo<
    React.ComponentType<IRepresentatieProps<IOphalenRekeningenResultElement>>
  >(() => (reprProps) => <span>{reprProps.entiteit.IBAN}</span>, []);
  const kolommen = useMemo<ASPKolom<ERekeningKolom, IOphalenRekeningenResultElement>[]>(
    () => [
      {
        key: ERekeningKolom.IBAN,
        label: 'IBAN',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 200,
        renderer: (item) => item.IBAN,
      },
      {
        key: ERekeningKolom.Saldo,
        label: 'Definitief Saldo',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 150,
        renderer: (item) => <FormatteerBedrag bedrag={item.Saldo} />,
      },
    ],
    [],
  );

  return (
    <MultiComboboxV2<number, ERekeningKolom, IOphalenRekeningenResultElement, null>
      keyExtractor={keyExtractor}
      enkeleProvider={enkeleProvider}
      provider={provider}
      waarde={props.bankRekID}
      onChange={props.onBankRekIDChange}
      representatieComponent={representatieComponent}
      kolommen={kolommen}
      overlayOptions={{
        height: 225,
      }}
    />
  );
};

export default RekeningCombobox;
