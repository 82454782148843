import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import UitBedrijfDialoog from './UitBedrijfDialoog';
import { ISectieProps } from '../index';
import { RootStoreContext } from '../../../../../../../../../../../stores/RootStore';
import api from '../../../../../../../../../../../api';
import { IOphalenProductenResultElementV2 } from '../../../../../../../../../../../../../shared/src/api/v2/product';
import { IBerekenVerkoopbedragResult } from '../../../../../../../../../../../../../shared/src/api/v2/product/verkoop';
import GegevensLayout from '../../../../../../../../../../../components/layout/GegevensLayout';
import { maandenNaarJaren } from '../../../../../../../../../../../bedrijfslogica/teksten';
import VerticaleScheidingslijn from '../../../../../../../../../../../components/layout/VerticaleScheidingslijn';
import { EProductstatus } from '../../../../../../../../../../../bedrijfslogica/enums';
import { format } from 'date-fns';
import { formatteerAdresV2, formatteerBedrag } from '../../../../../../../../../../../helpers';
import { Kleur } from '../../../../../../../../../../../bedrijfslogica/constanten';
import { IconToevoegen, IconWijzigen } from '../../../../../../../../../../../components/Icons';
import { EResultType } from '../../../../../../../../../../../stores/CheckStore';
import WijzigenProductDialoog from '../../../../../../../../../../Magazijn/Depot/WijzigenProductDialoog';
import VersturenDocumentatieDialoog from '../../../../../../../../../../../components/product/VersturenDocumentatieDialoog';
import NieuweMeldingDialoog from '../../../../../../Service/Meldingen/NieuweMeldingDialoog';
import LoadingSpinner from '../../../../../../../../../../../components/Gedeeld/LoadingSpinner';

interface IProps extends ISectieProps {}

interface IServicemeldingDialoogState {
  prodID: number;
}

interface IServicemeldingDialoogState2 {
  prodID: number;
}

interface IVersturenDocumentatieDialoogState {
  prodID: number;
}

interface IProductUitBedrijfDialoogStateState {
  prodID: number;
}

interface IProductMetVerkoopprijs extends IOphalenProductenResultElementV2 {
  verkoopprijs: number | null;
}

const Product: React.FC<IProps> = (props) => {
  const { checkStore } = useContext(RootStoreContext);

  const contract = props.contract;

  const producten = useMemo(
    () => props.dataBijID.productenBijCntBasisID[contract.CntBasisID] ?? [],
    [props.dataBijID, contract],
  );
  const [opgehaaldeProducten, setOpgehaaldeProducten] = useState<
    IOphalenProductenResultElementV2[] | null
  >(null);
  const bepalenOpgehaaldeProducten = useCallback(async () => {
    if (producten.length === 0) {
      setOpgehaaldeProducten([]);
      return;
    }
    const prodIDs = producten.map((x) => x.ProdID);
    const opgehaaldeProducten = await api.v2.product.ophalenProductenV2({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: prodIDs,
          },
        ],
      },
    });
    setOpgehaaldeProducten(opgehaaldeProducten.producten);
  }, [producten]);
  useEffect(() => {
    bepalenOpgehaaldeProducten();
  }, [bepalenOpgehaaldeProducten]);

  const [
    servicemeldingDialoogState,
    setServicemeldingDialoogState,
  ] = useState<IServicemeldingDialoogState | null>(null);

  const [wijzigenProdID, setWijzigenProdID] = useState<number | null>(null);

  const [
    versturenDocumentatieDialoogState,
    setVersturenDocumentatieDialoogState,
  ] = useState<IVersturenDocumentatieDialoogState | null>(null);

  const [
    productUitBedrijfDialoogState,
    setProductUitBedrijfDialoogStateState,
  ] = useState<IProductUitBedrijfDialoogStateState | null>(null);

  const [verkoopbedragen, setVerkoopbedragen] = useState<IBerekenVerkoopbedragResult | null>(null);
  const ophalenVerkoopbedragen = useCallback(async () => {
    const verkoopbedragen = await api.v2.product.verkoop.berekenVerkoopbedrag({
      prodIDs: producten.map((x) => x.ProdID),
    });
    setVerkoopbedragen(verkoopbedragen);
  }, [producten]);
  useEffect(() => {
    ophalenVerkoopbedragen();
  }, [ophalenVerkoopbedragen]);

  const productenMetVerkoopbedragen = useMemo<IProductMetVerkoopprijs[] | null>(() => {
    if (verkoopbedragen === null || opgehaaldeProducten === null) {
      return null;
    }

    return opgehaaldeProducten.map((product) => {
      const verkoopprijsResult = verkoopbedragen.verkoopbedragen.find(
        (x) => x.ProdID === product.ProdID,
      )!;

      return {
        ...product,
        verkoopprijs: verkoopprijsResult.bedrag,
      };
    });
  }, [opgehaaldeProducten, verkoopbedragen]);

  // const handleVersturenDocumentatie = useCallback(async (prodID: number, persID: number) => {
  //   const params = { prodID, persID };
  //   if (
  //     (
  //       await checkStore.bevestigen({
  //         titel: <div>Wil je productdocumentatie versturen?</div>,
  //       })
  //     ).type === EResultType.Annuleren
  //   ) {
  //     return;
  //   }

  //   await api.v2.product.documentatie.versturenProductdocumentatie(params);
  // }, []);

  if (productenMetVerkoopbedragen === null) {
    return <LoadingSpinner />;
  }

  if (productenMetVerkoopbedragen.length === 0) {
    return <div className="pl-4 pr-4 pt-2 pb-2">Er is geen gekoppeld product.</div>;
  }

  return (
    <>
      <div className="d-flex flex-column pt-2 pb-3 pl-4 pr-4">
        {productenMetVerkoopbedragen.map((product: IProductMetVerkoopprijs, i) => {
          return (
            <div
              key={product.ProdID}
              className={productenMetVerkoopbedragen!.length - 1 === i ? '' : 'mb-4'}
            >
              {productenMetVerkoopbedragen!.length > 1 && (
                <div className="font-weight-bold mb-1">
                  {product.producttype.Merknaam} {product.producttype.Typenaam}{' '}
                  {product.Referentiecode}
                </div>
              )}
              <div className="d-flex">
                <div style={{ width: 225 }}>
                  <GegevensLayout
                    gegevens={[
                      {
                        label: 'Merk/type',
                        waarde: `${product.producttype.Merknaam} ${product.producttype.Typenaam}`,
                      },
                      {
                        label: 'Soort',
                        waarde: product.producttype.Productsoortnaam,
                      },
                      {
                        label: 'Ref.code',
                        waarde: product.Referentiecode,
                      },
                      {
                        label: 'leeftijd',
                        waarde: `${
                          product.leeftijd !== null ? maandenNaarJaren(product.leeftijd) : ''
                        }`,
                      },
                    ]}
                  />
                </div>

                <div style={{ height: '100%' }} className="ml-4 mr-4 d-flex align-items-center">
                  <VerticaleScheidingslijn height={85} />
                </div>

                <div style={{ width: 175 }}>
                  <GegevensLayout
                    gegevens={[
                      {
                        label: 'Status',
                        waarde: `${product.productstatus.Naam}`,
                      },
                      product.productstatus.Naam === EProductstatus.UitBedrijf
                        ? {
                            label: 'Reden UB',
                            waarde: `${product.redenUB !== null ? product.redenUB : ''}`,
                          }
                        : null,
                      // {
                      //   label: 'Doelstatus',
                      //   waarde: `${
                      //     x.retourstatus !== null ? x.retourstatus.Retourstatusnaam : '-'
                      //   }`,
                      // },
                      {
                        label: 'In bedrijf',
                        waarde: `${format(new Date(product.DatumInBedrijf), 'dd-MM-yyyy')}`,
                      },
                      {
                        label: 'Uit bedrijf',
                        waarde: `${
                          product.DatumUitBedrijf !== null
                            ? format(new Date(product.DatumUitBedrijf), 'dd-MM-yyyy')
                            : ''
                        }`,
                      },
                      // {
                      //   label: 'Inkoopprijs',
                      //   waarde: `${formatteerBedrag(x.InkoopPrijs)}`,
                      // },
                    ]}
                  />
                </div>

                <div style={{ height: '100%' }} className="ml-4 mr-4 d-flex align-items-center">
                  <VerticaleScheidingslijn height={85} />
                </div>

                <div style={{ width: 150 }}>
                  <GegevensLayout
                    gegevens={[
                      {
                        label: 'Ink.prijs ex.',
                        waarde: `${
                          product.Inkoopprijs !== null ? formatteerBedrag(product.Inkoopprijs) : ''
                        }`,
                      },
                      {
                        label: 'Verk.prijs inc.',
                        waarde: `${
                          product.verkoopprijs !== null
                            ? formatteerBedrag(product.verkoopprijs)
                            : ''
                        }`,
                      },
                    ]}
                  />
                </div>

                <div style={{ height: '100%' }} className="ml-4 mr-4 d-flex align-items-center">
                  <VerticaleScheidingslijn height={85} />
                </div>

                <div style={{ width: 250 }}>
                  <div>
                    {product.locatie !== null
                      ? formatteerAdresV2({
                          bisnummer: product.locatie.Bisnummer,
                          straatnaam: product.locatie.Straatnaam,
                          huisnummer: product.locatie.Huisnummer,
                          postcode: product.locatie.Postcode,
                          plaatsnaam: product.locatie.Plaatsnaam,
                          landnaamEnum: product.locatie.LandnaamEnum,
                        })
                      : ''}
                  </div>
                  <div>{product.Notities !== null ? product.Notities : 'Geen notities'}</div>
                </div>
              </div>
              <div className="mt-3">
                <div className="d-flex align-items-center">
                  <button
                    className="btn btn-sm btn-light d-flex align-items-center"
                    style={{
                      border: `1px solid ${Kleur.LichtGrijs}`,
                    }}
                    onClick={() => setWijzigenProdID(product.ProdID)}
                  >
                    <IconWijzigen
                      style={{
                        width: 16,
                        height: 16,
                        fill: Kleur.Grijs,
                      }}
                    />
                    &nbsp; Wijzigen product
                  </button>
                  <button
                    className="btn btn-sm btn-light d-flex align-items-center ml-3"
                    style={{
                      border: `1px solid ${Kleur.LichtGrijs}`,
                    }}
                    onClick={() => {
                      if (product.locatie === null) {
                        return;
                      }
                      return setServicemeldingDialoogState({
                        prodID: product.ProdID,
                      });
                    }}
                  >
                    <IconToevoegen
                      style={{
                        width: 16,
                        height: 16,
                        fill: Kleur.Grijs,
                      }}
                    />
                    &nbsp; Servicemelding
                  </button>

                  <button
                    className="btn btn-sm btn-light d-flex align-items-center ml-3"
                    style={{
                      border: `1px solid ${Kleur.LichtGrijs}`,
                    }}
                    onClick={() => setVersturenDocumentatieDialoogState({ prodID: product.ProdID })}
                  >
                    <IconToevoegen
                      style={{
                        width: 16,
                        height: 16,
                        fill: Kleur.Grijs,
                      }}
                    />
                    &nbsp; Documentatie
                  </button>

                  {product.productstatus.NaamEnum === EProductstatus.Verhuur && (
                    <button
                      className="btn btn-sm btn-light d-flex align-items-center ml-3"
                      style={{
                        border: `1px solid ${Kleur.LichtGrijs}`,
                      }}
                      onClick={() =>
                        setProductUitBedrijfDialoogStateState({ prodID: product.ProdID })
                      }
                    >
                      <IconToevoegen
                        style={{
                          width: 16,
                          height: 16,
                          fill: Kleur.Grijs,
                        }}
                      />
                      &nbsp; Uit bedrijf melden
                    </button>
                  )}

                  {product.productstatus.NaamEnum !== EProductstatus.Verhuur && (
                    <button
                      className="btn btn-sm btn-light d-flex align-items-center ml-3"
                      style={{
                        border: `1px solid ${Kleur.LichtGrijs}`,
                      }}
                      onClick={async () => {
                        const params = {
                          prodIDs: [product.ProdID],
                        };
                        const checkData = await api.v2.product.checkMarkerenInBedrijf(params);

                        const controleResult = await checkStore.controleren({ checkData });
                        if (controleResult.type === EResultType.Annuleren) {
                          return;
                        }

                        if (
                          (
                            await checkStore.bevestigen({
                              inhoud: (
                                <span>
                                  Wil je het product weer In bedrijf melden?
                                  <br />
                                  Het product komt daarmee weer met status Verhuur In bedrijf.
                                </span>
                              ),
                            })
                          ).type === EResultType.Annuleren
                        ) {
                          return;
                        }

                        await api.v2.product.markerenInBedrijf(params);

                        await props.vernieuwenContracten();
                      }}
                    >
                      <IconToevoegen
                        style={{
                          width: 16,
                          height: 16,
                          fill: Kleur.Grijs,
                        }}
                      />
                      &nbsp; In bedrijf melden
                    </button>
                  )}

                  {(product.productstatus.NaamEnum === EProductstatus.Verhuur ||
                    product.productstatus.NaamEnum === EProductstatus.UitBedrijf) && (
                    <button
                      className="btn btn-sm btn-light d-flex align-items-center ml-3"
                      style={{
                        border: `1px solid ${Kleur.LichtGrijs}`,
                      }}
                      onClick={async () => {
                        const params = {
                          prodIDs: [product.ProdID],
                        };
                        const checkData = await api.v2.contract.product.checkOntkoppelenProducten(
                          params,
                        );
                        if (
                          (await checkStore.controleren({ checkData })).type ===
                          EResultType.Annuleren
                        ) {
                          return;
                        }

                        if (
                          (
                            await checkStore.bevestigen({
                              inhoud: (
                                <span>
                                  Wil je het product ontkoppelen van het contract?
                                  <br />
                                  <br />
                                  {product.productstatus.NaamEnum === EProductstatus.Verhuur
                                    ? 'Het product kom daarna in het standaard voorraadmagazijn en krijgt de status Geparkeerd.'
                                    : product.productstatus.NaamEnum === EProductstatus.UitBedrijf
                                    ? ''
                                    : ''}
                                </span>
                              ),
                            })
                          ).type === EResultType.Annuleren
                        ) {
                          return;
                        }

                        await api.v2.contract.product.ontkoppelenProducten(params);

                        props.vernieuwenContracten();
                      }}
                    >
                      <IconToevoegen
                        style={{
                          width: 16,
                          height: 16,
                          fill: Kleur.Grijs,
                        }}
                      />
                      &nbsp; Ontkoppelen
                    </button>
                  )}

                  {/* <button
                          className="btn btn-sm btn-light d-flex align-items-center ml-3"
                          style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                          onClick={() => null}
                        >
                          &nbsp; Verkoopvoorstel
                        </button> */}
                  {/* <button
                  className="btn btn-sm btn-light d-flex align-items-center ml-3"
                  style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                  onClick={() => null}
                >
                  <IconTransport style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                  &nbsp; Omruil product
                </button> */}
                </div>
              </div>
              {/*<div className="mb-3 mt-3">*/}
              {/*  <HorizontaleScheidingslijn />*/}
              {/*</div>*/}
            </div>
          );
        })}
      </div>

      {servicemeldingDialoogState !== null && (
        <NieuweMeldingDialoog
          open
          prodID={servicemeldingDialoogState.prodID}
          cntID={contract.CntID}
          relID={contract.RelID}
          // locID={servicemeldingDialoogState.locID}
          onSuccess={async () => {
            setServicemeldingDialoogState(null);
            await props.vernieuwenContracten();
          }}
          onAnnuleren={() => setServicemeldingDialoogState(null)}
        />
      )}

      {wijzigenProdID !== null && (
        <WijzigenProductDialoog
          open
          prodID={wijzigenProdID}
          onSuccess={async () => {
            setWijzigenProdID(null);
            await props.vernieuwenContracten();
          }}
          onAnnuleren={() => {
            setWijzigenProdID(null);
          }}
        />
      )}
      {versturenDocumentatieDialoogState !== null && (
        <VersturenDocumentatieDialoog
          open
          prodID={versturenDocumentatieDialoogState.prodID}
          relID={contract.RelID}
          onSuccess={async () => {
            setVersturenDocumentatieDialoogState(null);
            await props.vernieuwenContracten();
          }}
          onAnnuleren={() => {
            setVersturenDocumentatieDialoogState(null);
          }}
        />
      )}
      {productUitBedrijfDialoogState !== null && (
        <UitBedrijfDialoog
          open
          prodIDs={[productUitBedrijfDialoogState.prodID]}
          // redenUB_NaamEnum={ERedenUitBedrijf.NietRetourKlant}
          onSuccess={async () => {
            setProductUitBedrijfDialoogStateState(null);
            await props.vernieuwenContracten();
          }}
          onAnnuleren={() => {
            setProductUitBedrijfDialoogStateState(null);
          }}
        />
      )}
    </>
  );
};

export default Product;
