import api from '../../../index';

import {
  IOphalenServiceaandeelPerProducttypeParams,
  IOphalenServiceaandeelPerProducttypeResult,
} from '../../../../../../shared/src/api/v2/kwaliteitsbeheer/product';

const service = {
  ophalenServiceaandeelPerProducttype: async (
    params: IOphalenServiceaandeelPerProducttypeParams,
  ): Promise<IOphalenServiceaandeelPerProducttypeResult> => {
    return await api.post(
      'v2/kwaliteitsbeheer/service/ophalen-serviceaandeel-per-producttype',
      params,
    );
  },
};

export default service;
