import React, { SVGProps, useMemo, useRef, useState } from 'react';
import { Regel } from '../../types';
import MultiComboboxV2, {
  EnkeleProvider,
  IOverlayContainerProps,
  IOverlayOptions,
  Provider,
  IRepresentatieProps,
} from '../../../../../formulier/MultiComboboxV2';
import { IOphalenGrootboekenResultElement } from '../../../../../../../../shared/src/api/v2/boekhouding/boeking/grootboek';
import api from '../../../../../../api';
import { Kleur } from '../../../../../../bedrijfslogica/constanten';
import Zoeken from '../../../../../formulier/Zoeken';
import useBijGewijzigdEffect from '../../../../../../core/useBijGewijzigdEffect';
import { ASPKolom, EAspKolomBreedteType } from '../../../../../tabel/ASPTabel/types';
import RelatieSelectieDialoog from '../../../../../personalia/RelatieSelectieDialoog';
import { IconLeverancier, IconOrganisatie, IconPersoon, IconZoeken } from '../../../../../Icons';
import { IBepalenRelatieMuterenBronResultElement } from '../../../../../../../../shared/src/api/v2/boekhouding';
import FormatteerBedrag from '../../../../../MutatieBedrag';
import { ERelatiehoedanigheid } from '../../../../../../bedrijfslogica/enums';
import TableData from '../../../../../tabel/ASPTabel/Body/TableData';

export enum ERelatieSelectieKolom {
  Relatiesoort,
  Hoedanigheid,
  Naam,
  Relatienummer,
  OpenstaandBedrag,
}

interface ISelectieDialoogState {}

interface IOverlayContainerState {
  zoekenInput: string;
}

const OverlayContainer = (
  props: IOverlayContainerProps<
    IOverlayContainerState,
    IBepalenRelatieMuterenBronResultElement,
    number
  >,
) => {
  const rijbronHerbepalenDebounce = useRef<NodeJS.Timeout | null>(null);

  useBijGewijzigdEffect(() => {
    if (rijbronHerbepalenDebounce.current !== null) {
      clearTimeout(rijbronHerbepalenDebounce.current);
      rijbronHerbepalenDebounce.current = null;
    }

    rijbronHerbepalenDebounce.current = setTimeout(async () => {
      await props.rijbronHerbepalen();
    }, 500);
  }, [props.state.zoekenInput]);

  return (
    <div className="flex-fill d-flex flex-column">
      <div
        onClick={(ev) => ev.stopPropagation()}
        className="p-2"
        style={{ borderBottom: `1px solid ${Kleur.LichtGrijs}` }}
      >
        <Zoeken
          input={props.state.zoekenInput}
          onInputChange={(input) =>
            props.onStateChange((prev) => ({
              ...prev,
              zoekenInput: input,
            }))
          }
          placeholder="Zoeken..."
          autoFocus
        />
      </div>
      {props.children}
    </div>
  );
};

const RepresentatieComponent: React.ComponentType<IRepresentatieProps<
  IBepalenRelatieMuterenBronResultElement
>> = (props) => {
  return <span>{props.entiteit.weergavenaam}</span>;
};

interface IProps {
  relID: number | null;
  onRelIDChange: (relID: number | null) => void;
  regel: Regel;
  grootboek: IOphalenGrootboekenResultElement;
  disabled?: boolean;
  wisbaar?: boolean;
}

const RelatieMuteren = (props: IProps) => {
  const enkeleProvider = useMemo<EnkeleProvider<number, IBepalenRelatieMuterenBronResultElement>>(
    () => async (relID: number) => {
      // const relatieHoedanigheidNaamEnum = props.grootboek.RelatieHoedanigheidNaamEnum ?? undefined;
      const result = await api.v2.boekhouding.bepalenRelatieMuterenBron({
        zoekterm: null,
        paginatie: {
          index: 0,
          aantal: 1,
        },
        relID,
        // relatieHoedanigheidNaamEnum,
      });
      return result.resultaten[0];
    },
    [props.grootboek],
  );

  const provider = useMemo<
    Provider<ERelatieSelectieKolom, IBepalenRelatieMuterenBronResultElement, IOverlayContainerState>
  >(
    () => async (params) => {
      let zoekterm: string | null = params.overlayContainerState.zoekenInput.trim();
      if (zoekterm.length === 0) {
        zoekterm = null;
      }

      const relatieHoedanigheidNaamEnum = props.grootboek.RelatieHoedanigheidNaamEnum ?? undefined;
      const result = await api.v2.boekhouding.bepalenRelatieMuterenBron({
        zoekterm,
        paginatie: params.paginatie,
        relatieHoedanigheidNaamEnum,
      });

      const items = result.resultaten.reduce(
        (acc, curr, i) => ({
          ...acc,
          [params.paginatie.index + i]: curr,
        }),
        params.huidigeBron,
      );

      return {
        items,
        totaalAantal: result.totaalAantal,
      };
    },
    [props.grootboek],
  );

  const overlayOptions = useMemo<
    Partial<
      IOverlayOptions<IOverlayContainerState, IBepalenRelatieMuterenBronResultElement, number>
    >
  >(
    () => ({
      overlayContainer: {
        initialState: {
          zoekenInput: '',
        },
        overlayContainerComponent: OverlayContainer,
      },
      height: 300,
    }),
    [],
  );

  const [selectieDialoogState, setSelectieDialoogState] = useState<ISelectieDialoogState | null>(
    null,
  );

  const keyExtractor = useMemo(
    () => (row: IBepalenRelatieMuterenBronResultElement) => row.RelID,
    [],
  );
  const kolommen = useMemo<
    ASPKolom<ERelatieSelectieKolom, IBepalenRelatieMuterenBronResultElement>[]
  >(
    () => [
      {
        key: ERelatieSelectieKolom.Relatiesoort,
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 25,
        renderer: (row) => {
          const style: React.CSSProperties = {
            fill: Kleur.Grijs,
            width: 18,
            height: 18,
          };

          switch (row.Relatiesoort) {
            case 'P':
              return <IconPersoon style={style} />;
            case 'O':
              return <IconOrganisatie style={style} />;
          }
        },
        tdComponent: (tdProps) => (
          <TableData {...tdProps} style={{ padding: 0, alignItems: 'center' }} />
        ),
      },
      {
        key: ERelatieSelectieKolom.Hoedanigheid,
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 25,
        renderer: (row) => {
          if (row.RelatieHoedanigheidNaamEnum === null) {
            return null;
          }
          switch (row.RelatieHoedanigheidNaamEnum as ERelatiehoedanigheid) {
            case ERelatiehoedanigheid.Klant:
              return (
                <span title="Debiteur" className="font-weight-bold">
                  D
                </span>
              );
            case ERelatiehoedanigheid.Leverancier:
              return (
                <span title="Crediteur" className="font-weight-bold">
                  C
                </span>
              );
          }
        },
        tdComponent: (tdProps) => (
          <TableData {...tdProps} style={{ padding: 0, alignItems: 'center' }} />
        ),
      },
      {
        key: ERelatieSelectieKolom.Naam,
        label: 'Naam',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 400,
        renderer: (row) => row.weergavenaam,
      },
      {
        key: ERelatieSelectieKolom.Relatienummer,
        label: 'Relatienummer',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 125,
        renderer: (row) => row.Relatienummer,
      },
      {
        key: ERelatieSelectieKolom.OpenstaandBedrag,
        label: 'Openstaand',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 125,
        renderer: (row) => <FormatteerBedrag bedrag={row.OpenstaandBedrag} />,
      },
    ],
    [],
  );

  return (
    <>
      <div className="d-flex">
        <MultiComboboxV2
          provider={provider}
          enkeleProvider={enkeleProvider}
          keyExtractor={keyExtractor}
          waarde={props.relID}
          onChange={props.onRelIDChange}
          representatieComponent={RepresentatieComponent}
          kolommen={kolommen}
          disabled={props.disabled}
          wisbaar={props.wisbaar}
          overlayOptions={overlayOptions}
        />
        <button
          style={{
            background: 0,
            outline: 0,
            border: `1px solid ${Kleur.LichtGrijs}`,
            borderTopRightRadius: 5,
            borderBottomRightRadius: 5,
            borderLeft: 0,
            width: 25,
            backgroundColor: props.disabled ? 'rgb(197, 197, 197)' : Kleur.HeelLichtGrijs,
          }}
          className="flex-fill d-flex align-items-center justify-content-center"
          onClick={() => setSelectieDialoogState({})}
        >
          <IconZoeken
            style={{ fill: Kleur.Grijs, width: 16, height: 16, position: 'relative', top: 1 }}
            className="flex-fill"
          />
        </button>
      </div>

      {selectieDialoogState !== null && (
        <RelatieSelectieDialoog
          open
          onSuccess={(result) => {
            setSelectieDialoogState(null);

            props.onRelIDChange(result.relID);
          }}
          onAnnuleren={() => setSelectieDialoogState(null)}
        />
      )}
    </>
  );
};

export default RelatieMuteren;
