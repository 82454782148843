import React, { useMemo } from 'react';
import styled from 'styled-components';
import { IOphalenInventarisatiesResultElement } from '../../../../../../shared/src/api/v2/magazijn/inventarisatie';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import { format } from 'date-fns';
import GegevensLayout from '../../../../components/layout/GegevensLayout';
import { IconVerwijderen, IconVink, IconWijzigen } from '../../../../components/Icons';

const Root = styled.div<{ isActief: boolean }>`
  display: flex;
  align-items: stretch;
  background-color: ${(props) => (props.isActief ? Kleur.DoorschijnendLichtBlauw : Kleur.Wit)};
`;

const KleurBalk = styled.div<{ kleur: string }>`
  min-width: 10px;
  max-width: 10px;
  background-color: ${(props) => props.kleur};
  border-right: 1px solid ${Kleur.LichtGrijs};
`;

const Container = styled.div`
  flex: 1;
  padding: 15px 20px;
  display: flex;
`;

const LinkerKolom = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  //padding-right: 10px;
`;

const RechterKolom = styled.div`
  display: flex;
  flex-direction: column;
`;

const Nummer = styled.span`
  font-size: 16px;
  font-weight: bold;
`;

const Magazijn = styled.span`
  font-size: 14px;
  margin-left: 10px;
`;

const Datum = styled.span`
  font-size: 14px;
  flex: 1;
  text-align: right;
`;

interface IProps {
  inventarisatie: IOphalenInventarisatiesResultElement;
  isActief: boolean;
  onClick: () => void;
  onVerwijderen: () => Promise<void>;
  onWijzigen: () => Promise<void>;
  disabled?: boolean;
}

const InventarisatieTegel = (props: IProps) => {
  const nummer = useMemo(() => ('00' + props.inventarisatie.Inventarisatienummer).slice(-3), [
    props.inventarisatie.Inventarisatienummer,
  ]);
  const datumText = useMemo(() => {
    return format(new Date(props.inventarisatie.Inventarisatiedatum), 'dd-MM-yyyy');
  }, [props.inventarisatie.Inventarisatiedatum]);
  const totaalAantalProducten = useMemo(() => {
    return props.inventarisatie.regels.reduce((acc, cur) => {
      return acc + cur.AantalIntern;
    }, 0);
  }, [props.inventarisatie.regels]);
  const aanwezig = useMemo(() => {
    return props.inventarisatie.regels.reduce((acc, cur) => {
      return acc + (cur.AantalAanwezig ?? 0);
    }, 0);
  }, [props.inventarisatie.regels]);
  const aantalNogNietDefinitief = useMemo(() => {
    return props.inventarisatie.regels.reduce((acc, cur) => {
      return acc + (cur.IsDefinitief ? 0 : 1);
    }, 0);
  }, [props.inventarisatie.regels]);
  const verschil = useMemo(() => {
    return Math.abs(totaalAantalProducten - aanwezig);
  }, [aanwezig, totaalAantalProducten]);
  const kleur = useMemo(() => {
    if (aantalNogNietDefinitief > 0) {
      return Kleur.Oranje;
    }
    if (verschil === 0) {
      return Kleur.Groen;
    }
    return Kleur.Rood;
  }, [verschil, aantalNogNietDefinitief]);

  return (
    <Root isActief={props.isActief} onClick={props.onClick}>
      <KleurBalk kleur={kleur} />
      <Container>
        <LinkerKolom>
          <div className="d-flex align-items-center">
            <Nummer>{nummer}</Nummer>
            <Magazijn>{props.inventarisatie.magazijn.NaamKort}</Magazijn>
            <div className="flex-fill" />
            {props.inventarisatie.DatumAfgehandeld !== null && (
              <span title="Afgehandeld" style={{ position: 'relative', top: -3 }} className="mr-2">
                <IconVink style={{ fill: Kleur.Groen, width: 20, height: 20 }} />
              </span>
            )}
          </div>
          <GegevensLayout
            gegevens={[
              {
                label: 'Totaal aantal producten',
                waarde: totaalAantalProducten,
              },
              {
                label: 'Aanwezig',
                waarde: aanwezig,
              },
              {
                label: 'Verschil',
                waarde: verschil,
              },
              aantalNogNietDefinitief > 0
                ? {
                    label: 'Nog niet definitief',
                    waarde: aantalNogNietDefinitief,
                  }
                : null,
            ].filter((x) => x !== null)}
          />
        </LinkerKolom>
        <RechterKolom>
          <Datum>{datumText}</Datum>
          <div className="flex-fill d-flex align-items-end justify-content-end">
            <button
              onClick={async (ev) => {
                ev.stopPropagation();

                await props.onWijzigen();
              }}
              style={{
                border: 'none',
                backgroundColor: 'transparent',
                padding: 0,
                margin: 0,
                outline: 'none',
              }}
              className="ml-2"
            >
              <IconWijzigen style={{ fill: Kleur.Grijs, width: 17, height: 17 }} />
            </button>
            <button
              onClick={async (ev) => {
                ev.stopPropagation();

                await props.onVerwijderen();
              }}
              style={{
                border: 'none',
                backgroundColor: 'transparent',
                padding: 0,
                margin: 0,
                outline: 'none',
              }}
              className="ml-2"
            >
              <IconVerwijderen style={{ fill: Kleur.Grijs, width: 18, height: 18 }} />
            </button>
          </div>
        </RechterKolom>
      </Container>
    </Root>
  );
};

export default InventarisatieTegel;
