import {
  IMuterenProductmodelAlternatiefParams,
  IMuterenProductmodelAlternatiefResult,
  IOphalenProductmodelAlternatievenParams,
  IOphalenProductmodelAlternatievenResult,
  ISorterenProductmodelAlternatiefParams,
  ISorterenProductmodelAlternatiefResult,
  IToevoegenProductmodelAlternatiefParams,
  IToevoegenProductmodelAlternatiefResult,
} from '../../../../../../../shared/src/api/v2/product/model/alternatieven';
import api from '../../../../index';

const alternatief = {
  ophalenProductmodelAlternatieven: async (
    params: IOphalenProductmodelAlternatievenParams,
  ): Promise<IOphalenProductmodelAlternatievenResult> => {
    return api.post('v2/product/model/alternatief/ophalen-productmodel-alternatieven', params);
  },
  toevoegenProductmodelAlternatief: async (
    params: IToevoegenProductmodelAlternatiefParams,
  ): Promise<IToevoegenProductmodelAlternatiefResult> => {
    return api.post('v2/product/model/alternatief/toevoegen-productmodel-alternatief', params);
  },
  muterenProductmodelAlternatief: async (
    params: IMuterenProductmodelAlternatiefParams,
  ): Promise<IMuterenProductmodelAlternatiefResult> => {
    return api.post('v2/product/model/alternatief/muteren-productmodel-alternatief', params);
  },
  sorterenProductmodelAlternatief: async (
    params: ISorterenProductmodelAlternatiefParams,
  ): Promise<ISorterenProductmodelAlternatiefResult> => {
    return api.post('v2/product/model/alternatief/sorteren-productmodel-alternatief', params);
  },
};

export default alternatief;
