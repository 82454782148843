import React, { useCallback, useMemo } from 'react';
import IDialoogProps from '../../../../../core/IDialoogProps';
import Dialoog from '../../../../../components/dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import { Field, FieldProps, Formik, FormikActions, FormikErrors, FormikProps } from 'formik';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import MeertaligTekstveld from '../../../../../components/formulier/MeertaligTekstveld';
import ITaalTekst from '../../../../../../../shared/src/models/talen/ITaalTekst';
import nameof from '../../../../../core/nameOf';
import FormikVeldFout from '../../../../../components/formulier/FormikVeldFout';

export interface IFormikValues {
  naam: ITaalTekst[];
  naamEnum: string;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  naam: 'Naam',
  naamEnum: 'Naam enum',
};

export interface IDialoogResult {
  naam: ITaalTekst[];
  naamEnum: string | null;
}

interface IProps extends IDialoogProps<IDialoogResult> {
  initialValues?: IFormikValues;
}

const MuterenDialoog: React.FC<IProps> = (props) => {
  const initialValues = useMemo(() => {
    if (props.initialValues !== undefined) {
      return props.initialValues;
    }
    return {
      naam: [],
      naamEnum: '',
    };
  }, [props.initialValues]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);
      const errors = await actions.validateForm(values);
      if (Object.keys(errors).length > 0) {
        actions.setSubmitting(false);
        return;
      }

      props.onSuccess({
        naam: values.naam,
        naamEnum: values.naamEnum.trim() === '' ? null : values.naamEnum.trim(),
      });
      actions.setSubmitting(false);
    },
    [props.onSuccess],
  );

  const validate = useCallback((values: IFormikValues) => {
    const errors: FormikErrors<IFormikValues> = {};

    return errors;
  }, []);

  return (
    <Dialoog index={props.dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Groep</ModalTitle>
      </ModalHeader>
      <Formik<IFormikValues>
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validate={validate}
        render={(formikProps: FormikProps<IFormikValues>) => {
          const { submitForm, isSubmitting } = formikProps;
          return (
            <>
              <ModalBody>
                <div className="row">
                  <div className="col-12">
                    <label>{veldnamen.naam}</label>
                    <Field
                      name={nameof<IFormikValues>('naam')}
                      render={(fieldProps: FieldProps<IFormikValues>) => {
                        const { field, form } = fieldProps;
                        return (
                          <>
                            <MeertaligTekstveld
                              waarden={field.value}
                              onChange={(x) => form.setFieldValue(field.name, x)}
                            />
                            <FormikVeldFout fieldProps={fieldProps} />
                          </>
                        );
                      }}
                    />
                  </div>

                  <div className="col-12 mt-3">
                    <label>{veldnamen.naamEnum}</label>
                    <Field
                      name={nameof<IFormikValues>('naamEnum')}
                      render={(fieldProps: FieldProps<IFormikValues>) => {
                        const { field, form } = fieldProps;
                        return (
                          <>
                            <input type="text" className="form-control" {...field} />
                            <FormikVeldFout fieldProps={fieldProps} />
                          </>
                        );
                      }}
                    />
                  </div>
                </div>
              </ModalBody>
              <ModalFooter className="d-flex flex-row justify-content-start">
                <button
                  className="btn btn-primary"
                  onClick={submitForm}
                  style={{ width: 100 }}
                  disabled={isSubmitting}
                >
                  Ok
                </button>
                <button
                  className="btn btn-secondary"
                  onClick={props.onAnnuleren}
                  style={{ width: 100 }}
                  disabled={isSubmitting}
                >
                  Annuleren
                </button>
              </ModalFooter>
            </>
          );
        }}
      />
    </Dialoog>
  );
};

export default MuterenDialoog;
