export enum EVerkennerItemKolom {
  // Selectie,
  SoortIcon,
  Naam,
  Inzien,
  Grootte,
  Type,
  Referenties,
  Labels,
  DatumToegevoegd,
  Opslagdienst,
  Acties,
}

export enum EVerkennerItemType {
  Bestand,
  Map,
}
