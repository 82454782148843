import {
  IVerwerkBestellingenParams,
  IVerwerkBestellingenResult,
  IVerwijderenBestellingenParams,
  IVerwijderenBestellingenResult,
  IVersturenBevestigingParams,
  IVersturenBevestigingResult,
} from '../../../../../shared/src/api/v2/bestelling';
import api from '../../index';
import ICheckData from '../../../../../shared/src/models/ICheckData';

const bestelling = {
  checkVerwerkBestellingen: async (params: IVerwerkBestellingenParams): Promise<ICheckData> => {
    return await api.post('/v2/bestelling/check-verwerk-bestellingen', params);
  },
  verwerkBestellingen: async (
    params: IVerwerkBestellingenParams,
  ): Promise<IVerwerkBestellingenResult> => {
    return await api.post('/v2/bestelling/verwerk-bestellingen', params);
  },
  checkVerwijderenBestellingen: async (
    params: IVerwijderenBestellingenParams,
  ): Promise<ICheckData> => {
    return await api.post('/v2/bestelling/check-verwijderen-bestellingen', params);
  },
  verwijderenBestellingen: async (
    params: IVerwijderenBestellingenParams,
  ): Promise<IVerwijderenBestellingenResult> => {
    return await api.post('/v2/bestelling/verwijderen-bestellingen', params);
  },
  checkVersturenBevestiging: async (params: IVersturenBevestigingParams): Promise<ICheckData> => {
    return await api.post('/v2/bestelling/check-versturen-bevestiging', params);
  },
  versturenBevestiging: async (
    params: IVersturenBevestigingParams,
  ): Promise<IVersturenBevestigingResult> => {
    return await api.post('/v2/bestelling/versturen-bevestiging', params);
  },
};

export default bestelling;
