import React, { useCallback, useMemo, useState } from 'react';
import {
  DXTableCheckboxComponent,
  DXTableToggleCellComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  DataTypeProvider,
  IntegratedSorting,
  RowDetailState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import { IOphalenStatistischeInfoResultElement } from '../../../../../../shared/src/api/v2/voorraad';
import { IRowModel } from '../index';
import _ from 'lodash';
import DetailComp from './DetailComp';
import ModelInfoDialoog from '../../../../components/voorraad/ModelInfoDialoog';
import { Kleur as EKleur } from '../../../../bedrijfslogica/constanten';
import nameOf from '../../../../core/nameOf';
import ProductmodelInfoDialoog from '../../../../components/productmodel/ProductmodelInfoDialoog';
import { IconInformatie } from '../../../../components/Icons';

const geenData = {
  noData: 'Geen voorraad gevonden voor de selectie',
};

interface IProps {
  rows: IRowModel[];
  infos: IOphalenStatistischeInfoResultElement[];
  selectie: number[];
  onSelectieChange: (selectie: number[]) => void;
}

export interface IModelInfoInfoDialoogState {
  prodModID: number;
}

export interface IProductmodelInfoDialoogState {
  prodModID: number;
}

const ModelTabel: React.FC<IProps> = (props) => {
  const [
    modelInfoDialoogState,
    setModelInfoDialoogState,
  ] = useState<IModelInfoInfoDialoogState | null>(null);
  const [
    productmodelInfoDialoogState,
    setProductmodelInfoDialoogState,
  ] = useState<IProductmodelInfoDialoogState | null>(null);

  const keyExtractor = useCallback((row: IRowModel) => row.ProdModID, []);
  const kolommen = useMemo<TypedColumn<IRowModel>[]>(
    () => [
      {
        name: 'Modelcode',
        title: 'Modelcode',
      },
      {
        name: 'Modelnaam',
        title: 'Modelnaam',
      },
      {
        name: '__productsoortnaamKort' as any,
        title: 'Soort',
      },
      {
        name: 'Kenmerk',
        title: 'Kenmerk',
      },
      {
        name: 'OpenbaarAanbod',
        title: 'Openb.',
      },
      {
        name: '__modelInfo' as any,
        title: ' ',
      },
      // {
      //   name: 'Actief',
      //   title: 'Actief',
      // },
      {
        name: '__aantalVrij' as any,
        title: 'Vrij',
        getCellValue: (x) => {
          const aantal = _.sum(x.voorraadinfo.map((x) => x.aantalVrij));
          return aantal;
        },
      },
      {
        name: '__aantalVrijUitgepakt' as any,
        title: 'Uitg.',
        getCellValue: (x) => {
          const aantal = _.sum(x.voorraadinfo.map((x) => x.aantalVoorraadUitgepaktVrij));
          return aantal;
        },
      },
      {
        name: '__aantalNogTeLeveren' as any,
        title: 'In best.',
        getCellValue: (x) => {
          const aantalInkoop = _.sum(x.voorraadinfo.map((x) => x.aantalInkoop));
          // const aantalGeleverd = _.sum(x.voorraadinfo.map((x) => x.aantalGeleverd));
          const aantal = aantalInkoop;
          return aantal;
        },
      },
      {
        name: '__aantalInkoopreservering' as any,
        title: 'Atl. IR',
        getCellValue: (x) => {
          const aantalPlanning = _.sum(
            x.voorraadinfo.map((x) => x.aantalInkoopGereserveerdPlanning),
          );
          const aantalUitstaand = _.sum(
            x.voorraadinfo.map((x) => x.aantalInkoopGereserveerdUitstaand),
          );
          const aantal = aantalPlanning + aantalUitstaand;
          return aantal;
        },
      },
      {
        name: '__aantalVoorraad' as any,
        title: 'Tot. Vrd.',
        getCellValue: (x) => {
          const aantal = _.sum(x.voorraadinfo.map((x) => x.aantalVoorraad));
          return aantal;
        },
      },
      {
        name: '__aantalGereserveerdPlanning' as any,
        title: 'Plan Res.',
        getCellValue: (x) => {
          const aantal = _.sum(x.voorraadinfo.map((x) => x.aantalPlanningGereserveerd));
          return aantal;
        },
      },
      // {
      //   name: '__aantalInkoopreserveringPlanning' as any,
      //   title: 'Plan IR',
      //   getCellValue: (x) => {
      //     const aantal = _.sum(x.voorraadinfo.map((x) => x.aantalInkoopGereserveerdPlanning));
      //     return aantal;
      //   },
      // },
      {
        name: '__aantalGereserveerdUitstaand' as any,
        title: 'Uitst. Res.',
        getCellValue: (x) => {
          const aantal = _.sum(x.voorraadinfo.map((x) => x.aantalUitstaandGereserveerd));
          return aantal;
        },
      },
      // {
      //   name: '__aantalInkoopreserveringUitstaand' as any,
      //   title: 'Uitst. IR',
      //   getCellValue: (x) => {
      //     const aantal = _.sum(x.voorraadinfo.map((x) => x.aantalInkoopGereserveerdUitstaand));
      //     return aantal;
      //   },
      // },
      {
        name: '__aantalContracten' as any,
        title: (<span title={'Aantal contracten de laatste 30 dagen'}># Cnt.</span>) as any,
        getCellValue: (x) => {
          const aantal = x.info !== null ? x.info.AantalContracten : '';
          return aantal;
        },
      },
      {
        name: '__info' as any,
        title: 'Gebruik',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRowModel>[]>(
    () => [
      {
        columnName: 'Modelcode',
        width: 125,
      },
      {
        columnName: 'Modelnaam',
        width: 225,
      },
      {
        columnName: '__productsoortnaamKort' as any,
        width: 85,
      },
      {
        columnName: 'Kenmerk',
        width: 225,
      },
      {
        columnName: 'OpenbaarAanbod',
        width: 85,
      },
      {
        columnName: '__modelInfo' as any,
        width: 75,
      },
      {
        columnName: 'Actief',
        width: 70,
      },
      {
        columnName: '__aantalVoorraad' as any,
        width: 100,
      },
      {
        columnName: '__aantalInkoopreservering' as any,
        width: 105,
      },
      {
        columnName: '__aantalGereserveerdPlanning' as any,
        width: 105,
      },
      {
        columnName: '__aantalInkoopreserveringPlanning' as any,
        width: 105,
      },
      {
        columnName: '__aantalGereserveerdUitstaand' as any,
        width: 110,
      },
      {
        columnName: '__aantalInkoopreserveringUitstaand' as any,
        width: 105,
      },
      {
        columnName: '__aantalVrij' as any,
        width: 80,
      },
      {
        columnName: '__aantalVrijUitgepakt' as any,
        width: 80,
      },
      {
        columnName: '__aantalNogTeLeveren' as any,
        width: 90,
      },
      {
        columnName: '__aantalContracten' as any,
        width: 90,
      },
      {
        columnName: '__info' as any,
        width: 100,
      },
    ],
    [],
  );

  return (
    <>
      <GridStyleWrapper height="calc(100vh - 150px)">
        <Grid columns={kolommen} getRowId={keyExtractor} rows={props.rows}>
          <DataTypeProvider
            for={['__productsoortnaamKort']}
            formatterComponent={(formatterProps) => {
              const rij: IRowModel = formatterProps.row;

              return (
                <span style={{ color: !rij.productsoort.Actief ? EKleur.Rood : undefined }}>
                  {rij.productsoort.NaamKort}
                </span>
              );
            }}
          />

          <DataTypeProvider
            for={[nameOf<IRowModel>('Actief')]}
            formatterComponent={(formatterprops) => {
              return <span>{formatterprops.value ? 'Ja' : 'Nee'}</span>;
            }}
          />

          <DataTypeProvider
            for={[nameOf<IRowModel>('OpenbaarAanbod')]}
            formatterComponent={(formatterprops) => {
              return <span>{formatterprops.value ? 'Ja' : 'Nee'}</span>;
            }}
          />

          <DataTypeProvider
            for={['__aantalVoorraad']}
            formatterComponent={(formatterprops) => {
              const row: IRowModel = formatterprops.row;
              const aantal = _.sum(row.voorraadinfo.map((x) => x.aantalVoorraad));
              return (
                <>
                  <span>{aantal}</span>
                </>
              );
            }}
          />

          <DataTypeProvider
            for={['__aantalInkoopreservering']}
            formatterComponent={(formatterprops) => {
              const row: IRowModel = formatterprops.row;
              const aantalPlanning = _.sum(
                row.voorraadinfo.map((x) => x.aantalInkoopGereserveerdPlanning),
              );
              const aantalUitstaand = _.sum(
                row.voorraadinfo.map((x) => x.aantalInkoopGereserveerdUitstaand),
              );
              const aantal = aantalPlanning + aantalUitstaand;
              return (
                <>
                  <span>{aantal !== 0 ? aantal : ''}</span>
                </>
              );
            }}
          />

          <DataTypeProvider
            for={['__aantalGereserveerdPlanning']}
            formatterComponent={(formatterprops) => {
              const row: IRowModel = formatterprops.row;
              const aantal = _.sum(row.voorraadinfo.map((x) => x.aantalPlanningGereserveerd));
              return (
                <>
                  <span>{aantal !== 0 ? aantal : ''}</span>
                </>
              );
            }}
          />

          <DataTypeProvider
            for={['__aantalInkoopreserveringPlanning']}
            formatterComponent={(formatterprops) => {
              const row: IRowModel = formatterprops.row;
              const aantal = _.sum(row.voorraadinfo.map((x) => x.aantalInkoopGereserveerdPlanning));
              return (
                <>
                  <span>{aantal !== 0 ? aantal : ''}</span>
                </>
              );
            }}
          />

          <DataTypeProvider
            for={['__aantalGereserveerdUitstaand']}
            formatterComponent={(formatterprops) => {
              const row: IRowModel = formatterprops.row;
              const aantal = _.sum(row.voorraadinfo.map((x) => x.aantalUitstaandGereserveerd));
              return (
                <>
                  <span>{aantal !== 0 ? aantal : ''}</span>
                </>
              );
            }}
          />

          <DataTypeProvider
            for={['__aantalInkoopreserveringUitstaand']}
            formatterComponent={(formatterprops) => {
              const row: IRowModel = formatterprops.row;
              const aantal = _.sum(
                row.voorraadinfo.map((x) => x.aantalInkoopGereserveerdUitstaand),
              );
              return (
                <>
                  <span>{aantal !== 0 ? aantal : ''}</span>
                </>
              );
            }}
          />

          <DataTypeProvider
            for={['__aantalVrij']}
            formatterComponent={(formatterprops) => {
              const row: IRowModel = formatterprops.row;
              const aantal = _.sum(row.voorraadinfo.map((x) => x.aantalVrij));
              return (
                <>
                  <span style={{ color: aantal >= 0 ? EKleur.Groen : EKleur.Rood }}>{aantal}</span>
                </>
              );
            }}
          />

          <DataTypeProvider
            for={['__aantalContracten']}
            formatterComponent={(formatterprops) => {
              const rij: IRowModel = formatterprops.row;
              const aantal = rij.info !== null ? rij.info.AantalContracten : '';

              return <span>{aantal}</span>;
            }}
          />

          <DataTypeProvider
            for={['__info']}
            formatterComponent={(formatterprops) => {
              const rij: IRowModel = formatterprops.row;
              const aantal = rij.info !== null ? rij.info.AantalContracten : '';

              return (
                <div className="d-flex">
                  <a
                    href="#"
                    onClick={() => {
                      setModelInfoDialoogState({
                        prodModID: rij.ProdModID,
                      });
                    }}
                  >
                    {/* <VoorraadIndicatie
                          status={gereserveerd ? EVoorraadStatus.Groen : EVoorraadStatus.Rood}
                        /> */}
                    <span className="ml-2">info</span>
                  </a>
                </div>
              );
            }}
          />

          <DataTypeProvider
            for={['__aantalNogTeLeveren']}
            formatterComponent={(formatterprops) => {
              const row: IRowModel = formatterprops.row;
              const aantalInkoop = _.sum(row.voorraadinfo.map((x) => x.aantalInkoop));
              // const aantalGeleverd = _.sum(row.voorraadinfo.map((x) => x.aantalGeleverd));
              const aantal = aantalInkoop;
              return (
                <>
                  <span>{aantal !== 0 ? aantal : ''}</span>
                </>
              );
            }}
          />

          <DataTypeProvider
            for={['__modelInfo']}
            formatterComponent={(formatterProps) => {
              const rij: IRowModel = formatterProps.row;

              return (
                <a
                  href="#"
                  onClick={async () =>
                    setProductmodelInfoDialoogState({
                      prodModID: rij.ProdModID,
                    })
                  }
                >
                  <IconInformatie style={{ width: 15, height: 15, fill: EKleur.Blauw }} />
                </a>
              );
            }}
          />

          <SortingState defaultSorting={[]} />
          <IntegratedSorting />

          <VirtualTable messages={geenData} />
          <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
          <RowDetailState defaultExpandedRowIds={[]} />
          <TableHeaderRow showSortingControls />
          <TableRowDetail
            toggleCellComponent={DXTableToggleCellComponent}
            contentComponent={DetailComp}
          />
          <SelectionState
            selection={props.selectie}
            onSelectionChange={(x) => props.onSelectieChange(x as number[])}
          />
          <TableSelection cellComponent={DXTableCheckboxComponent} />
        </Grid>
      </GridStyleWrapper>
      {modelInfoDialoogState !== null && (
        <ModelInfoDialoog
          open
          prodModID={modelInfoDialoogState.prodModID}
          onSuccess={() => setModelInfoDialoogState(null)}
          onAnnuleren={() => setModelInfoDialoogState(null)}
        />
      )}
      {productmodelInfoDialoogState !== null && (
        <ProductmodelInfoDialoog
          open
          prodModID={productmodelInfoDialoogState.prodModID}
          onSuccess={() => setProductmodelInfoDialoogState(null)}
          onAnnuleren={() => setProductmodelInfoDialoogState(null)}
        />
      )}
    </>
  );
};

export default ModelTabel;
