import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  TableRowDetail,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import React, { useCallback, useContext, useMemo } from 'react';
import GegevensLayout from '../../../layout/GegevensLayout';
import TabelInspringBlok from '../../../layout/TabelInspringBlok';
import VerticaleScheidingslijn from '../../../layout/VerticaleScheidingslijn';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import { RootStoreContext } from '../../../../stores/RootStore';
import { EResultType } from '../../../../stores/CheckStore';
import api from '../../../../api';
import {
  GridStyleWrapper,
  TableRowDetailWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import { IOphalenActieclaimsResultElement } from '../../../../../../shared/src/api/v2/actie/klantWerftKlant';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { IRow } from '..';
import {
  IOphalenContractenResultElement,
  IOphalenContractenResultElementV2,
} from '../../../../../../shared/src/api/v2/contract';
import ContractVisualisatie from '../../../entiteitVisualisaties/ContractVisualisatie';
import nameof from '../../../../core/nameOf';
import { format } from 'date-fns';
import FormatteerBedrag from '../../../MutatieBedrag';
import { VirtualTable as VirtualTableBase } from '@devexpress/dx-react-grid';

interface IProps extends TableRowDetail.ContentProps {}

const DetailComp: React.FC<IProps> = (props) => {
  const row: IRow = props.row;
  const { checkStore } = useContext(RootStoreContext);

  const contracten: IOphalenContractenResultElementV2[] = row.contracten;

  const kolommen = useMemo<TypedColumn<IOphalenContractenResultElementV2>[]>(
    () => [
      {
        name: '__contractnummer' as any,
        title: 'Contractnr.',
      },
      {
        name: 'Ingangsdatum',
        title: 'Ingangsdatum',
      },
      {
        name: '__modelnaam' as any,
        title: 'Model',
      },
      // {
      //   name: '__maandhuur' as any,
      //   title: 'Maandhuur',
      // },
      // {
      //   name: 'Abonnementnaam',
      //   title: 'Abonnement',
      // },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IOphalenContractenResultElementV2>[]>(
    () => [
      {
        columnName: '__contractnummer' as any,
        width: 100,
      },
      {
        columnName: 'Ingangsdatum',
        width: 125,
      },
      {
        columnName: '__modelnaam' as any,
        width: 225,
      },
      {
        columnName: 'Abonnementnaam',
        width: 125,
      },
      {
        columnName: '__maandhuur' as any,
        width: 125,
      },
    ],
    [],
  );

  const kolomExtensies: VirtualTableBase.ColumnExtension[] = useMemo(() => {
    return [
      // {
      //   columnName: `EenmaligBedrag`,
      //   align: 'right',
      // },
      // {
      //   columnName: `__maandhuur`,
      //   align: 'right',
      // },
    ];
  }, []);

  const keyExtractor = useCallback((x: IOphalenContractenResultElementV2) => x.CntID, []);

  return (
    <TableRowDetailWrapper>
      <div className="d-flex">
        <TabelInspringBlok />
        <GridStyleWrapper maxHeight={500} rowAmount={contracten.length}>
          <Grid rows={contracten} columns={kolommen} getRowId={keyExtractor}>
            <DataTypeProvider
              for={['__contractnummer']}
              formatterComponent={(formatterProps) => {
                const rij: IOphalenContractenResultElement = formatterProps.row;
                return <ContractVisualisatie cntID={rij.CntID} />;
              }}
            />

            <DataTypeProvider
              for={[
                nameof<IOphalenContractenResultElementV2>('Contractdatum'),
                nameof<IOphalenContractenResultElementV2>('Ingangsdatum'),
                nameof<IOphalenContractenResultElementV2>('Einddatum'),
              ]}
              formatterComponent={(formatterProps) => {
                return <span>{format(new Date(formatterProps.value), 'dd-MM-yyyy')}</span>;
              }}
            />

            <DataTypeProvider
              for={['__modelnaam']}
              formatterComponent={(formatterProps) => {
                const rij: IOphalenContractenResultElementV2 = formatterProps.row;

                return (
                  <div className="d-flex  align-items-center">
                    <div>{rij.basis.productmodel.Modelnaam}</div>
                  </div>
                );
              }}
            />

            <DataTypeProvider
              for={['__maandhuur']}
              formatterComponent={(formatterProps) => {
                const rij: IOphalenContractenResultElementV2 = formatterProps.row;

                return <FormatteerBedrag bedrag={rij.basis.Maandhuur} />;
              }}
            />

            <DataTypeProvider
              for={['__contractstatus']}
              formatterComponent={(formatterProps) => {
                const rij: IOphalenContractenResultElementV2 = formatterProps.row;

                return (
                  <div className="d-flex  align-items-center">
                    <div>{rij.status.Naam}</div>
                  </div>
                );
              }}
            />

            <VirtualTable columnExtensions={kolomExtensies} />
            <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
            <TableHeaderRow />
          </Grid>
        </GridStyleWrapper>
      </div>
    </TableRowDetailWrapper>
  );
};

export default DetailComp;
