import React, { useCallback, useContext, useState, useMemo, useEffect } from 'react';
import { ModalBody, ModalFooter, ModalTitle } from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/ModalHeader';
import api from '../../../api';
import IDialoogProps from '../../../core/IDialoogProps';
import Dialoog from '../../dialogen/Dialoog';
import LoadingSpinner from '../../Gedeeld/LoadingSpinner';
import Tabblad, { ITabblad } from '../../layout/Tabblad';
import OpdrachtTab from './OpdrachtTab';
import RegelsTab from './RegelsTab';
import _ from 'lodash';
import {
  IOphalenOpdrachtenResultElementV2,
  IOphalenOpdrachteventsResultElement,
  IOphalenOpdrachtregelsResultElementV2,
} from '../../../../../shared/src/api/v2/transport/opdracht';
import BezoektijdenTab from './BezoektijdenTab';
import { IOphalenBezoektijdenResultElement } from '../../../../../shared/src/api/v2/transport/bezoek';
import { IOphalenPremiumsResultElement } from '../../../../../shared/src/api/v2/transport/opdracht/premium';
import TechnischeInformatieKnop from '../../TechnischeInformatieKnop';
import { IOphalenGekoppeldeTransportopdrachtenResultElement } from '../../../../../shared/src/api/v2/inkoopfactuur/transport';

interface IProps extends IDialoogProps<{}> {
  trsOpdID: number;
}

enum ETablad {
  Opdracht,
  Regels,
  Bezoekinfo,
}

export interface IContext {
  opdracht: IOphalenOpdrachtenResultElementV2 | null;
  regels: IOphalenOpdrachtregelsResultElementV2[] | null;
  bezoektijden: IOphalenBezoektijdenResultElement[] | null;
  events: IOphalenOpdrachteventsResultElement[] | null;
  premiums: IOphalenPremiumsResultElement[] | null;
  gekoppeldeInkoopfacturen: IOphalenGekoppeldeTransportopdrachtenResultElement[] | null;
}

export const OpdrachtContext = React.createContext<IContext>({
  opdracht: null,
  regels: null,
  bezoektijden: null,
  events: null,
  premiums: null,
  gekoppeldeInkoopfacturen: null,
});

const OpdrachtinfoDialoog: React.FC<IProps> = (props) => {
  const { dialoogIndex, onAnnuleren, onSuccess, open } = props;
  const [tabblad, setTabblad] = useState<ETablad>(ETablad.Opdracht);
  const [opdracht, setOpdracht] = useState<IOphalenOpdrachtenResultElementV2 | null>(null);
  const [regels, setRegels] = useState<IOphalenOpdrachtregelsResultElementV2[] | null>(null);
  const [bezoektijden, setBezoektijden] = useState<IOphalenBezoektijdenResultElement[] | null>(
    null,
  );
  const [events, setEvents] = useState<IOphalenOpdrachteventsResultElement[] | null>(null);
  const [premiums, setPremiums] = useState<IOphalenPremiumsResultElement[] | null>(null);
  const [gekoppeldeInkoopfacturen, setGekoppeldeInkoopfacturen] = useState<
    IOphalenGekoppeldeTransportopdrachtenResultElement[] | null
  >(null);

  const tabbladen = useMemo<ITabblad<ETablad>[]>(
    () => [
      {
        id: ETablad.Opdracht,
        label: 'Opdracht',
        content: OpdrachtTab,
      },
      {
        id: ETablad.Bezoekinfo,
        label: 'Bezoekinfo',
        content: BezoektijdenTab,
      },
      {
        id: ETablad.Regels,
        label: 'Regels',
        content: RegelsTab,
      },
    ],
    [onSuccess],
  );

  const ophalenGekoppeldeInkoopfacturen = useCallback(async () => {
    const opdrachten = (
      await api.v2.inkoopfactuur.transport.ophalenGekoppeldeTransportopdrachten({
        filterSchema: { filters: [{ naam: 'TRSOPD_IDS', data: [props.trsOpdID] }] },
      })
    ).gekoppeldeTransportopdrachten;

    setGekoppeldeInkoopfacturen(opdrachten);
  }, [props.trsOpdID]);

  useEffect(() => {
    ophalenGekoppeldeInkoopfacturen();
  }, []);

  const ophalenOpdracht = useCallback(async () => {
    const opdracht = (
      await api.v2.transport.opdracht.ophalenOpdrachtenV2({
        filterSchema: { filters: [{ naam: 'IDS', data: [props.trsOpdID] }] },
      })
    ).opdrachten[0];

    setOpdracht(opdracht);
  }, [props.trsOpdID]);

  useEffect(() => {
    ophalenOpdracht();
  }, []);

  const ophalenPremiums = useCallback(async () => {
    const premiumsVoorOpdrachtenResult = await api.v2.transport.opdracht.premium.ophalenPremiumsVoorOpdrachten(
      {
        trsOpdIDs: [props.trsOpdID],
      },
    );

    setPremiums(_.flatten(premiumsVoorOpdrachtenResult.opdrachten.map((x) => x.premiums)));
  }, [props.trsOpdID]);

  useEffect(() => {
    ophalenPremiums();
  }, []);

  //  const premiumsVoorOpdrachtenResult = (
  //    await api.v2.transport.opdracht.premium.ophalenPremiumsVoorOpdrachten({
  //      trsOpdIDs: trsOpdIDsWaarvoorToevoegen,
  //    })
  //  ).opdrachten;

  const ophalenTransportopdrachtregels = useCallback(async () => {
    if (opdracht === null) {
      return;
    }

    const regels = (
      await api.v2.transport.opdracht.ophalenOpdrachtregelsV2({
        filterSchema: { filters: [{ naam: 'TRSOPD_IDS', data: [opdracht.TrsOpdID] }] },
      })
    ).regels;

    // const regels = _.orderBy(regelsResult.regels, ['TrsRegID'], ['desc']);

    setRegels(regels);
  }, [opdracht]);

  useEffect(() => {
    ophalenTransportopdrachtregels();
  }, [ophalenTransportopdrachtregels]);

  const ophalenBezoektijden = useCallback(async () => {
    if (opdracht === null) {
      return;
    }

    const bezoektijden = (
      await api.v2.transport.bezoek.ophalenBezoektijden({
        filterSchema: { filters: [{ naam: 'TRSOPD_IDS', data: [opdracht.TrsOpdID] }] },
      })
    ).bezoektijden;

    setBezoektijden(bezoektijden);
  }, [opdracht]);

  useEffect(() => {
    ophalenBezoektijden();
  }, [ophalenBezoektijden]);

  const ophalenEvents = useCallback(async () => {
    const events = (
      await api.v2.transport.opdracht.ophalenOpdrachtevents({
        filterSchema: { filters: [{ naam: 'TRSOPD_IDS', data: [props.trsOpdID] }] },
        orderSchema: {
          orders: [
            { naam: 'DATUM', richting: 'DESC' },
            { naam: 'ID', richting: 'DESC' },
          ],
        },
      })
    ).opdrachtevents;

    setEvents(events);
  }, [props.trsOpdID]);

  useEffect(() => {
    ophalenEvents();
  }, [ophalenEvents]);

  return (
    <Dialoog index={dialoogIndex || 0} modalProps={{ size: `lg` }}>
      <ModalHeader>
        <ModalTitle>Info transportopdracht</ModalTitle>
      </ModalHeader>
      <div className="d-flex flex-column flex-fill" style={{ minHeight: `600px` }}>
        {opdracht === null || regels === null || bezoektijden === null || events === null ? (
          <div className="flex-fill d-flex align-items-center justify-content-center">
            <LoadingSpinner />
          </div>
        ) : (
          <>
            <OpdrachtContext.Provider
              value={{
                opdracht,
                regels,
                bezoektijden,
                events,
                premiums,
                gekoppeldeInkoopfacturen,
              }}
            >
              <ModalBody style={{ padding: 0 }}>
                <Tabblad
                  tabbladen={tabbladen}
                  geselecteerd={tabblad}
                  onSelectieChange={(id) => {
                    setTabblad(id);
                  }}
                />
              </ModalBody>
            </OpdrachtContext.Provider>

            <ModalFooter className="d-flex justify-content-start">
              <button className="btn btn-secondary" onClick={onAnnuleren} style={{ width: 100 }}>
                Sluiten
              </button>
              <div className="flex-fill" />
              <TechnischeInformatieKnop
                dialoogIndex={(props.dialoogIndex ?? 0) + 1}
                content={() => (
                  <div>
                    TrsOpdID: {opdracht.TrsOpdID}
                    <br />
                    LocID: {opdracht.locatie.LocID}
                    <br />
                    PersID: {opdracht.persoon !== null ? opdracht.persoon.PersID : null}
                    <br />
                    TrsRegIDs: {regels.map((x) => x.TrsRegID).join(',')}
                  </div>
                )}
              />
            </ModalFooter>
          </>
        )}
      </div>
    </Dialoog>
  );
};

export default OpdrachtinfoDialoog;
