import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import MultiCombobox, { IKolom } from '../MultiCombobox';
import api from '../../../api';
import { IOphalenRekeningenResultElement } from '../../../../../shared/src/api/v2/bank/bank';
import VinkVeld from '../VinkVeld';
import VerticaleScheidingslijn from '../../layout/VerticaleScheidingslijn';
import { IFilterSchemaFilter } from '../../../../../shared/src/models/filter';

interface IProps {
  bankRekID: number | null;
  filters?: IFilterSchemaFilter[];
  onChange: (bankRekID: number | null) => void;
  options?: IOptions;
}

interface IOptions {
  bijEnkeleDirectVoorselecteren?: boolean;
}

const defaultOptions: IOptions = {
  bijEnkeleDirectVoorselecteren: false,
};

interface IRow extends IOphalenRekeningenResultElement {
  NaamKort: string;
  IBAN: string;
}

const BankrekeningSelectie: React.FC<IProps> = (props) => {
  const options = useMemo(() => ({ ...defaultOptions, ...props.options }), [props.options]);

  const { onChange, bankRekID } = props;

  const [rekeningen, setRekeningen] = useState<IRow[] | null>(null);

  useEffect(() => {
    (async () => {
      const filters = props.filters !== undefined ? props.filters : [];
      const rekeningenResult = await api.v2.bank.ophalenRekeningen({
        filterSchema: { filters },
      });

      setRekeningen(rekeningenResult.rekeningen);
    })();
  }, [props.filters]);

  const kolommen = useMemo<IKolom<IRow>[]>(() => {
    return [
      {
        key: 'NaamKort',
        label: 'Naam',
        breedte: 100,
      },
      {
        key: 'IBAN',
        label: 'IBAN',
        breedte: 200,
      },
    ];
  }, []);

  return (
    <>
      <MultiCombobox<number, IRow>
        sleutelExtractor={(row) => row.BankRekID}
        representatieFabriek={(row) => {
          const rekening: IRow = row;
          if (rekening === null) {
            return null;
          }

          return rekening.NaamKort + ' - ' + rekening.IBAN;
        }}
        waarde={bankRekID}
        onWaardeChange={onChange}
        kolommen={kolommen}
        opties={rekeningen}
        options={{
          geenWaardeBericht: 'Geen rekening',
          // popoverComp: (props: PropsWithChildren<any>) => {
          //   const {
          //     zoekterm,
          //     onZoektermChange,
          //     relID,
          //     alleenVoorRelatieTonen,
          //     onAlleenVoorRelatieTonenChange,
          //   } = props;
          //   return (
          //     <div className="d-flex flex-column">
          //       <div className="d-flex align-items-center p-2">
          //         <input
          //           className="flex-fill"
          //           placeholder="Zoeken..."
          //           value={zoekterm}
          //           // onChange={(ev) => setZoekterm(ev.target.value)}
          //         />

          //         {relID !== undefined && (
          //           <>
          //             <VerticaleScheidingslijn className="ml-3 mr-2" height={25} />
          //             <div className="d-flex align-items-center justify-content-center">
          //               <VinkVeld
          //                 aangevinkt={alleenVoorRelatieTonen}
          //                 // onGewijzigd={(x) => setAlleenVoorRelatieTonen(x)}
          //               />
          //               <span className="ml-1">Alleen voor relatie tonen</span>
          //             </div>
          //           </>
          //         )}
          //       </div>

          //       {props.children}
          //     </div>
          //   );
          // },
        }}
      />
    </>
  );
};

export default BankrekeningSelectie;
