import React, { useMemo } from 'react';
import Editor from '@monaco-editor/react';
import { editor } from 'monaco-editor';
type IEditorOptions = editor.IEditorOptions;

const defaultEditorOptions: IEditorOptions = {
  selectOnLineNumbers: true,
  fontSize: 13,
  minimap: {
    enabled: false,
  },
};

interface IProps {
  code: string;
  onCodeChange?: (code: string) => void;
  width?: string | number;
  height?: string | number;
  language?: string;
  theme?: string;
  editorOptionsBuilder?: (currentOptions: IEditorOptions) => IEditorOptions;
}

const CodeBewerker: React.FC<IProps> = (props) => {
  const editorOptions = useMemo<IEditorOptions>(
    () =>
      props.editorOptionsBuilder === undefined
        ? defaultEditorOptions
        : props.editorOptionsBuilder(defaultEditorOptions),
    [props.editorOptionsBuilder],
  );

  return (
    <Editor
      width={props.width}
      height={props.height === undefined ? '100%' : props.height}
      language={props.language}
      theme={props.theme === undefined ? 'vs-light' : props.theme}
      options={editorOptions}
      value={props.code}
      onChange={(value) => {
        props.onCodeChange?.(value || '');
      }}
    />
  );
};

export default CodeBewerker;
