import React, { useState, useEffect, useCallback, useMemo, useContext } from 'react';
import IDialoogProps from '../../../../../core/IDialoogProps';
import Dialoog from '../../../../../components/dialogen/Dialoog';
import { ModalFooter, ModalTitle, ModalBody } from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/ModalHeader';
import { Formik, Field, FieldProps, FormikActions, FormikProps, FormikErrors } from 'formik';
import api from '../../../../../api';
import LoadingSpinner from '../../../../../components/Gedeeld/LoadingSpinner';
import nameOf from '../../../../../core/nameOf';
import FormikVeldFout from '../../../../../components/formulier/FormikVeldFout';
import VinkVeld from '../../../../../components/formulier/VinkVeld';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../../../stores/RootStore';
import { EResultType } from '../../../../../stores/CheckStore';
import MeertaligTekstveld from '../../../../../components/formulier/MeertaligTekstveld';
import ITaalTekst from '../../../../../../../shared/src/models/talen/ITaalTekst';
import {
  IOphalenOverigeTarievenResultElement,
  IToevoegenTariefParams,
} from '../../../../../../../shared/src/api/v2/aanbod/tarieven/overige';
import BedragInput from '../../../../../components/formulier/BedragInput';
import * as Yup from 'yup';

interface IProps extends IDialoogProps {}

interface IFormikValues {
  naam: string;
  naamTaal: ITaalTekst[];
  omschrijvingTaal: ITaalTekst[];
  naamEnum: string;
  betreftPercentage: boolean;
  bedrag: number | null;
  percentage: number | null;
  openbaar: boolean;
}

const ToevoegenDialoog: React.FC<IProps> = observer((props) => {
  const { checkStore } = useContext(RootStoreContext);
  const [tarief, setTarief] = useState<IOphalenOverigeTarievenResultElement | null>(null);

  const initialValues: IFormikValues | null = useMemo(() => {
    return {
      naam: '',
      naamEnum: '',
      naamTaal: [],
      omschrijvingTaal: [],
      betreftPercentage: false,
      bedrag: 0,
      percentage: null,
      openbaar: false,
    };
  }, []);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      const params: IToevoegenTariefParams = {
        naam: values.naam,
        naamEnum: values.naamEnum,
        naamTaal: values.naamTaal,
        omschrijvingTaal: values.omschrijvingTaal,
        bedrag: values.betreftPercentage ? null : values.bedrag,
        percentage: values.betreftPercentage ? values.percentage : null,
        frequentie: 'E',
        openbaar: values.openbaar,
        betreftPercentage: values.betreftPercentage,
      };

      const checkData = await api.v2.aanbod.tarieven.overige.checkToevoegenTarief(params);
      if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
        actions.setSubmitting(false);
        return;
      }

      if (
        (
          await checkStore.bevestigen({
            inhoud: `Tarief toevoegen?`,
          })
        ).type === EResultType.Annuleren
      ) {
        actions.setSubmitting(false);
        return;
      }

      await api.v2.aanbod.tarieven.overige.toevoegenTarief(params);

      props.onSuccess(null);
      actions.setSubmitting(false);
    },
    [props.onSuccess],
  );

  // const validate = useCallback((values: IFormikValues) => {
  //   // const schema = Yup.object().shape({
  //   //   naam: Yup.string().required('Naam is verplicht'),
  //   //   percentage: Yup.string(),
  //   // });

  //   // const errors = schema.validateSync(values);
  //   // if (Object.keys(errors).length > 0) {
  //   //   return errors;
  //   // }

  //   const errors: FormikErrors<IFormikValues> = {};

  //   if (values.naam === '') {
  //     errors.naam = 'Naam is verplicht';
  //   }
  //   if (values.betreftPercentage && values.percentage === null) {
  //     errors.percentage = 'Percentage is verplicht bij de gekozen optie';
  //   }
  //   if (!values.betreftPercentage && values.bedrag === null) {
  //     errors.bedrag = 'Bedrag is verplicht bij de gekozen optie';
  //   }

  //   return errors;
  // }, []);

  return (
    <Dialoog index={props.dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Nieuw tarief</ModalTitle>
      </ModalHeader>

      {
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialValues}
          // validate={validate}
          render={(formikProps: FormikProps<IFormikValues>) => {
            const { submitForm, isSubmitting, values } = formikProps;
            return (
              <>
                <ModalBody>
                  <div className="">
                    <div className="row">
                      <div className="col-12">
                        <label>Naam</label>
                        <Field
                          name={nameOf<IFormikValues>('naam')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field } = fieldProps;
                            return (
                              <>
                                <input type="text" {...field} className="form-control" />
                                <FormikVeldFout fieldProps={fieldProps} />
                              </>
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <label>NaamEnum</label>
                        <Field
                          name={nameOf<IFormikValues>('naamEnum')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { form, field } = fieldProps;
                            return (
                              <>
                                <input
                                  type="text"
                                  {...field}
                                  className="form-control"
                                  onChange={(ev) =>
                                    form.setFieldValue(field.name, ev.target.value.toUpperCase())
                                  }
                                />
                                <FormikVeldFout fieldProps={fieldProps} />
                              </>
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <label>Naam</label>
                        <Field
                          name={nameOf<IFormikValues>('naamTaal')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <>
                                <MeertaligTekstveld
                                  waarden={field.value}
                                  onChange={(x) => form.setFieldValue(field.name, x)}
                                />
                                <FormikVeldFout fieldProps={fieldProps} />
                              </>
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <label>Omschrijving</label>
                        <Field
                          name={nameOf<IFormikValues>('omschrijvingTaal')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <>
                                <MeertaligTekstveld
                                  waarden={field.value}
                                  onChange={(x) => form.setFieldValue(field.name, x)}
                                />
                                <FormikVeldFout fieldProps={fieldProps} />
                              </>
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <Field
                          name={nameOf<IFormikValues>('betreftPercentage')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <>
                                <span className="d-flex align-items-center">
                                  <VinkVeld
                                    aangevinkt={field.value}
                                    onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                  />
                                  <span className="ml-2">betreftPercentage</span>
                                </span>
                                <FormikVeldFout fieldProps={fieldProps} />
                              </>
                            );
                          }}
                        />
                      </div>

                      {!values.betreftPercentage && (
                        <div className="col-6 mt-3">
                          <label>Bedrag</label>
                          <Field
                            name={nameOf<IFormikValues>('bedrag')}
                            render={(fieldProps: FieldProps<IFormikValues>) => {
                              const { field, form } = fieldProps;

                              return (
                                <div>
                                  <BedragInput
                                    value={fieldProps.field.value}
                                    onChange={(bedrag) => {
                                      fieldProps.form.setFieldValue(fieldProps.field.name, bedrag);
                                    }}
                                    min={0}
                                  />
                                </div>
                              );
                            }}
                          />
                        </div>
                      )}

                      {values.betreftPercentage && (
                        <div className="col-6 mt-3">
                          <label>Percentage</label>
                          <Field
                            name={nameOf<IFormikValues>('percentage')}
                            render={(fieldProps: FieldProps<IFormikValues>) => {
                              const { field, form } = fieldProps;

                              return (
                                <>
                                  <input
                                    type="number"
                                    className="form-control"
                                    {...fieldProps.field}
                                    min={0}
                                  />
                                  <FormikVeldFout fieldProps={fieldProps} />
                                </>
                              );
                            }}
                          />
                        </div>
                      )}

                      <div className="col-12 mt-3">
                        <Field
                          name={nameOf<IFormikValues>('openbaar')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <>
                                <span className="d-flex align-items-center">
                                  <VinkVeld
                                    aangevinkt={field.value}
                                    onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                  />
                                  <span className="ml-2">Openbaar</span>
                                </span>
                                <FormikVeldFout fieldProps={fieldProps} />
                              </>
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </ModalBody>
                <ModalFooter className="d-flex flex-row justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    style={{ width: 100 }}
                    disabled={isSubmitting}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={props.onAnnuleren}
                    style={{ width: 100 }}
                    disabled={isSubmitting}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      }
    </Dialoog>
  );
});

export default ToevoegenDialoog;
