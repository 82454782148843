import React, { useCallback, useMemo } from 'react';
import Dialoog from '../../../../../../../components/dialogen/Dialoog';
import IDialoogProps from '../../../../../../../core/IDialoogProps';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import { Field, FieldProps, Formik, FormikActions, FormikErrors } from 'formik';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import nameOf from '../../../../../../../core/nameOf';
import FormikVeldFout from '../../../../../../../components/formulier/FormikVeldFout';
import ProducttypeSelectie from '../../../../../../../components/formulier/ProducttypeSelectie';
import api from '../../../../../../../api';
import { IFilterSchemaFilter } from '../../../../../../../../../shared/src/models/filter';
import teksten from '../../../../../../../bedrijfslogica/teksten';

interface IDialoogResult {
  typeID: number;
}

interface IProps extends IDialoogProps<IDialoogResult> {
  prodSrtID: number | null;
  merkID: number | null;
  uitgeslotenTypeIDs: number[];
}

interface IFormikValues {
  typeID: number | null;
}

const ToevoegenDialoog: React.FC<IProps> = (props) => {
  const initialValues = useMemo<IFormikValues>(() => {
    return {
      typeID: null,
    };
  }, []);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      const errors = await actions.validateForm(values);
      if (Object.keys(errors).length > 0) {
        return;
      }

      props.onSuccess({
        typeID: values.typeID!,
      });
    },
    [],
  );

  const validate = useCallback((values: IFormikValues): FormikErrors<IFormikValues> => {
    const errors: FormikErrors<IFormikValues> = {};

    if (values.typeID === null) {
      errors.typeID = teksten.formulier.E_VERPLICHT_VELD({ veldnaam: 'Type' });
    }

    return errors;
  }, []);

  const producttypeSelectieResolve = useCallback(async () => {
    return await api.v2.product.type.ophalenProducttypen({
      filterSchema: {
        filters: [
          props.prodSrtID === null
            ? null
            : {
                naam: 'PRODSRT_IDS',
                data: [props.prodSrtID],
              },
          props.merkID === null
            ? null
            : {
                naam: 'MERK_IDS',
                data: [props.merkID],
              },
          props.uitgeslotenTypeIDs.length === 0
            ? null
            : {
                naam: 'IDS',
                data: props.uitgeslotenTypeIDs,
                inverteren: true,
              },
        ].filter((x) => x !== null) as IFilterSchemaFilter[],
      },
    });
  }, [props.prodSrtID, props.merkID, props.uitgeslotenTypeIDs]);

  return (
    <Dialoog index={props.dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Producttype toevoegen</ModalTitle>
      </ModalHeader>
      <Formik<IFormikValues>
        onSubmit={handleSubmit}
        validate={validate}
        initialValues={initialValues}
        render={(formikProps) => {
          const { submitForm, isSubmitting } = formikProps;
          return (
            <>
              <ModalBody>
                <Field
                  name={nameOf<IFormikValues>('typeID')}
                  render={(fieldProps: FieldProps<IFormikValues>) => {
                    const { field, form } = fieldProps;
                    return (
                      <>
                        <ProducttypeSelectie
                          typeID={field.value}
                          onChange={(id) => form.setFieldValue(field.name, id)}
                          resolve={producttypeSelectieResolve}
                        />
                        <FormikVeldFout fieldProps={fieldProps} />
                      </>
                    );
                  }}
                />
              </ModalBody>
              <ModalFooter className="d-flex flex-row justify-content-start">
                <button
                  className="btn btn-primary d-flex align-items-center justify-content-center"
                  onClick={submitForm}
                  style={{ width: 100 }}
                  disabled={isSubmitting}
                >
                  Ok
                </button>
                <button
                  className="btn btn-secondary"
                  onClick={props.onAnnuleren}
                  style={{ width: 100 }}
                  disabled={isSubmitting}
                >
                  Annuleren
                </button>
              </ModalFooter>
            </>
          );
        }}
      />
    </Dialoog>
  );
};

export default ToevoegenDialoog;
