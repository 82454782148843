import styled from 'styled-components';
import { Kleur as EKleur } from '../../bedrijfslogica/constanten';

interface IRootProps {
  isNegatief: boolean;
  color?: string;
  fontSize: number;
}

export const Root = styled.span<IRootProps>`
  display: flex;
  align-items: center;
  color: ${(props) =>
    props.color !== undefined ? props.color : props.isNegatief ? '#ff7979' : '#505050'};
  font-size: ${(props) => props.fontSize}px;
  // props.color !== undefined ? props.color : props.isNegatief ? EKleur.LichtRood : '#505050'};

  .valuta {
    //margin-top: 3px;
  }
`;

export const Euros = styled.span<{ fontSize: number }>`
  font-size: ${(props) => props.fontSize}px;
`;
export const Centen = styled.span<{ fontSize: number }>`
  //position: relative;
  //top: 2px;
  font-size: ${(props) => props.fontSize * 0.85}px;
  margin-top: ${(props) => props.fontSize * 0.1}px;
  //height: 21px;
  display: flex;
  align-items: center;
`;
