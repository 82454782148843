import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Kleur } from '../../../../../bedrijfslogica/constanten';
import { IconBellen } from '../../../../../components/Icons';
import { Root } from './style';
import api from '../../../../../api';
import { RootStoreContext } from '../../../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import { ERemoteDataState } from '../../../../../models/IRemoteData';
import LoadingSpinner from '../../../../../components/Gedeeld/LoadingSpinner';
import useActieveOproep from '../../../../../helpers/communicatie/telefoon/useActieveOproep';
import { CommunicatieOverlayContext } from '../../../index';
import styled from 'styled-components';
import TelefoonnummerInput from './TelefoonnummerInput';
import Toestelknop from './Toestelknop';
import useInkomendeOfRinkelendeOproepen from '../../../../../helpers/communicatie/telefoon/useInkomendeOfRinkelendeOproepen';
import { ETelefooneventStatus } from '../../../../../bedrijfslogica/enums';
import { TelefoonTabbladContext } from '../index';
import { IOphalenVoipAccountsResult } from '../../../../../../../shared/src/api/v2/telefonie';

const StyledBelButton = styled.button`
  padding: 0.5rem 2rem;
  border: 0;
  background: 0;
  outline: 0;
  border-left: 1px solid ${Kleur.LichtGrijs};

  &:hover {
    background: ${Kleur.DoorschijnendLichtBlauw};
  }

  :disabled {
    cursor: not-allowed;
    background: rgba(0, 0, 0, 0.1);
  }
`;

interface IProps {}

const CentraalVlak: React.FC<IProps> = observer((props) => {
  const { gebruikerStore, telefoonStore, voipStore, checkStore } = useContext(RootStoreContext);
  const { telefoonContext, width } = useContext(CommunicatieOverlayContext);
  const telefoonTabbladContext = useContext(TelefoonTabbladContext);

  const actieveOproepOutput = useActieveOproep();

  const inputGeldig = useMemo(() => {
    return telefoonContext.telefoonnummer !== null;
    // return REGEX_TELEFOON.test(telefoonContext.telefoonnummer);
    // return matchResult !== null && matchResult.length === 1;
  }, [telefoonContext.telefoonnummer]);

  const handleBellenClick = useCallback(async () => {
    await telefoonTabbladContext.bellen(telefoonContext.telefoonnummer!);
  }, [telefoonContext.telefoonnummer]);

  const heeftVoipAccountGekoppeld = useMemo(
    () =>
      voipStore.voipAccountsVigerend !== null &&
      gebruikerStore.gebruiker !== null &&
      voipStore.voipAccountsVigerend.some(
        (x) => x.AspGebrID === gebruikerStore.gebruiker!.AspGebrID,
      ),
    [gebruikerStore.gebruiker?.AspGebrID, voipStore.voipAccountsVigerend],
  );

  const bellenToegestaan = useMemo(
    () => !telefoonTabbladContext.bellenDisabled && inputGeldig && heeftVoipAccountGekoppeld,
    [inputGeldig, heeftVoipAccountGekoppeld, telefoonTabbladContext.bellenDisabled],
  );

  const [voipAccountsResult, setVoipAccountsResult] = useState<IOphalenVoipAccountsResult | null>(
    null,
  );
  const ophalenVoipAccounts = useCallback(async () => {
    const result = await api.v2.telefonie.ophalenVoipAccounts({
      filterSchema: {
        uitgebreideFilter: {
          or: [
            {
              filter: {
                naam: 'ASP_GEBR_ID',
                // Null om voip accounts te selecteren die voor elke gebruiker mogelijk is...
                data: null,
              },
            },
            {
              filter: {
                naam: 'ASP_GEBR_IDS_IN',
                // En die voor onze gebruiker mogelijk zijn
                data: [gebruikerStore.gebruiker!.AspGebrID],
              },
            },
          ],
        },
      },
    });
    setVoipAccountsResult(result);
  }, [gebruikerStore.gebruiker!.AspGebrID]);
  useEffect(() => {
    ophalenVoipAccounts();
  }, [ophalenVoipAccounts]);

  const voipAccount = useMemo(
    () =>
      voipAccountsResult === null ||
      gebruikerStore.aspGebruikersprofiel === null ||
      gebruikerStore.aspGebruikersprofiel!.VoipAccID === null
        ? null
        : voipAccountsResult.voipAccounts.find(
            (x) => x.VoipAccID === gebruikerStore.aspGebruikersprofiel!.VoipAccID,
          ) || null,
    [voipAccountsResult, gebruikerStore.aspGebruikersprofiel],
  );

  return (
    <Root className="d-flex align-items-center">
      {telefoonStore.actieveOproepen.state === ERemoteDataState.Pending ? (
        <div className="d-flex flex-fill align-items-center justify-content-center">
          <LoadingSpinner soort="light" />
        </div>
      ) : actieveOproepOutput !== null ? (
        <div className="d-flex flex-fill  pl-3 pr-3 pt-2 pb-2">
          <input
            type="text"
            placeholder="Vul hier notities in van het telefoongesprek..."
            className="notities-input"
          />
        </div>
      ) : (
        <div className="flex-fill d-flex">
          <Toestelknop />

          <div className="d-flex flex-fill pl-3 pr-3 pt-2 pb-2">
            <TelefoonnummerInput
              telefoonnummer={telefoonContext.telefoonnummer}
              onChange={telefoonContext.onTelefoonnumerChange}
            />
          </div>

          <StyledBelButton
            className="d-flex align-items-center justify-content-center"
            disabled={!bellenToegestaan}
            onClick={handleBellenClick}
          >
            <IconBellen
              style={{ fill: Kleur.Grijs, width: 15, height: 15, position: 'relative', top: 1 }}
            />
            <span style={{ marginLeft: 10 }}>Bellen</span>
          </StyledBelButton>
        </div>
      )}
    </Root>
  );
});

export default CentraalVlak;
