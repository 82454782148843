import React, { useContext, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { IOphalenBestandenResultElement } from '../../../../../shared/src/api/v2/bestand/bestand';
import { Kleur } from '../../../bedrijfslogica/constanten';
import BijlageKnop, { ESelectieModus } from '../../BijlageKnop';
import BijlagenContainer, { BestandType, EWeergaveFocus } from '../../BijlagenContainer';
import LoadingSpinner from '../../Gedeeld/LoadingSpinner';
import { IconUitvergroten, IconDownload, IconKruis } from '../../Icons';
import { mediaTypeNaarMediaWeergaveType } from '../../MediaWeergave';
import MediaWeergaveDialoog from '../../dialogen/MediaWeergaveDialoog';
import UitlegTooltip from '../UitlegTooltip';
import downloadUrl from '../../../core/downloadUrl';
import useUpload from '../../../core/useUpload';
import { EResultType } from '../../../stores/CheckStore';
import { RootStoreContext } from '../../../stores/RootStore';
import bestand from '../../../api/v2/bestand';

interface IProps {
  bestand: IOphalenBestandenResultElement | undefined;
  onBestIDChange: (id: number | null) => void;
}

const Bijlage: React.FC<IProps> = observer((props) => {
  const { checkStore } = useContext(RootStoreContext);
  const {
    isBezigMetUploaden,
    moetNogUploaden,
    muteerBestanden,
    upload,
    uploadProgresses,
    bestanden,
  } = useUpload(
    useMemo(
      () => ({
        automatischUploaden: true,
        initieleBestanden:
          props.bestand == undefined
            ? []
            : [
                {
                  type: BestandType.ASPDrive,
                  bestand: props.bestand!,
                },
              ],
        onAlleBestandenGeuploaded: (bestIDs) => {
          props.onBestIDChange(bestIDs.length === 0 ? null : bestIDs[0]);
        },
      }),
      [props.onBestIDChange],
    ),
  );

  const handleVerwijderen = async () => {
    if (
      (await checkStore.bevestigen({ inhoud: 'Confirmatie verwijderen bijlage' })).type ===
      EResultType.Annuleren
    ) {
      return;
    }
    await muteerBestanden((_) => []);
  };

  const [mediaWeergaveOpen, setMediaWeergaveOpen] = useState(false);

  return (
    <>
      <div className="flex-fill w-100 d-flex align-items-center justify-content-start">
        {isBezigMetUploaden ? (
          <LoadingSpinner />
        ) : props.bestand == null || bestanden == null || bestanden.length == 0 ? (
          <BijlageKnop
            onBijgevoegd={async (x) => {
              await muteerBestanden(() => x);
            }}
            selectieModus={ESelectieModus.Enkel}
            toegestaneBestandstypes={[
              {
                mediaType: 'image/*',
                weergaveNaam: 'Afbeelding',
              },
            ]}
          />
        ) : (
          <div>
            <BijlagenContainer
              bestanden={bestanden}
              weergaveFocus={EWeergaveFocus.Visualisatie}
              uploadProgresses={uploadProgresses}
              bestandenMuterenToegestaan={!isBezigMetUploaden}
              onBestandenChange={async (bestanden) => {
                muteerBestanden((_) => bestanden);
              }}
            />

            <div className="d-flex align-items-center ml-2">
              <UitlegTooltip inhoud="Downloaden">
                <button
                  style={{
                    outline: 0,
                    background: 0,
                    border: 0,
                    padding: 0,
                    position: 'relative',
                    top: -1,
                  }}
                  onClick={async () => {
                    await downloadUrl(props.bestand!.url, props.bestand!.Naam);
                  }}
                  className="ml-2"
                >
                  <IconDownload style={{ width: 15, height: 15, fill: Kleur.Grijs }} />
                </button>
              </UitlegTooltip>
              <UitlegTooltip inhoud="Verwijderen">
                <button
                  className="ml-2"
                  style={{
                    outline: 0,
                    background: 0,
                    border: 0,
                    padding: 0,
                  }}
                  onClick={handleVerwijderen}
                >
                  <IconKruis style={{ width: 19, height: 19, fill: Kleur.Grijs }} />
                </button>
              </UitlegTooltip>
            </div>
          </div>
        )}
      </div>
      {mediaWeergaveOpen && (
        <MediaWeergaveDialoog
          current={1}
          mediaWeergaven={
            props.bestand == undefined
              ? []
              : [
                  {
                    id: 1,
                    src: props.bestand.url,
                    type: mediaTypeNaarMediaWeergaveType(props.bestand.MediaType)!,
                    title: props.bestand.Naam,
                    mediaType: props.bestand.MediaType,
                  },
                ]
          }
          open
          onSuccess={() => setMediaWeergaveOpen(false)}
          onAnnuleren={() => setMediaWeergaveOpen(false)}
        />
      )}
    </>
  );
});

export default Bijlage;
