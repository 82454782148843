import React, { useCallback, useContext, useMemo } from 'react';
import {
  DataTypeProvider,
  EditingState,
  TableRowDetail,
  VirtualTable as VirtualTableBase,
} from '@devexpress/dx-react-grid';
import {
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
  GridStyleWrapper,
  TableRowDetailWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import FormatteerBedrag from '../../../../components/MutatieBedrag';
import { format } from 'date-fns';
import { IRegel } from '../index';
import GegevensLayout from '../../../../components/layout/GegevensLayout';
import VerticaleScheidingslijn from '../../../../components/layout/VerticaleScheidingslijn';
import TabelInspringBlok from '../../../../components/layout/TabelInspringBlok';
import api from '../../../../api';
import { ContractenContext } from '../index';
import MenuLayout from '../../../../components/MenuLayout';
import {
  Grid,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import { IOphalenItemsResultElement } from '../../../../../../shared/src/api/v2/sponsoring/item';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import BijlageKnop, { EOrientatie } from '../../../../components/BijlageKnop';
import useUpload from '../../../../core/useUpload';
import BijlagenContainer, {
  BestandType,
  IExternBestand,
} from '../../../../components/BijlagenContainer';

type IProps = TableRowDetail.ContentProps;

const DetailComp: React.FC<IProps> = (props) => {
  const rij: IRegel = props.row;
  const contractenContext = useContext(ContractenContext);
  const items = contractenContext.items!.filter((x) => x.SponContractID === rij.ID);

  // const betanden: IOphalenBestandenResultElement[] = [];

  const kolommen = useMemo<TypedColumn<IOphalenItemsResultElement>[]>(
    () => [
      {
        name: 'Omschrijving',
        title: 'Omschrijving',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IOphalenItemsResultElement>[]>(
    () => [
      {
        columnName: 'Omschrijving',
        width: 250,
      },
    ],
    [],
  );

  // const kolomExtensies: VirtualTableBase.ColumnExtension[] = useMemo(() => {
  //   return [
  //     {
  //       columnName: ``,
  //       align: 'right',
  //     },
  //   ];
  // }, []);

  const {
    moetNogUploaden,
    isBezigMetUploaden,
    uploadProgresses,
    bestanden,
    muteerBestanden,
  } = useUpload(
    useMemo(
      () => ({
        initieleBestanden: rij.bestanden.map(
          (bestand): IExternBestand => ({
            type: BestandType.ASPDrive,
            bestand: bestand,
          }),
        ),
        automatischUploaden: true,
        onAlleBestandenGeuploaded: async (ids: number[]) => {
          await api.v2.sponsoring.contract.wijzigenBestanden({
            ID: rij.ID,
            bestandIDs: ids,
          });
        },
      }),
      [rij.bestanden],
    ),
  );

  return (
    <div className="d-flex">
      <TabelInspringBlok />
      <MenuLayout
        menu={<></>}
        body={
          <div>
            <>
              <div className="row">
                <div className="col-2 d-flex">
                  <div style={{ height: '100%' }} className="mr-2">
                    <BijlageKnop
                      disabled={isBezigMetUploaden}
                      onBijgevoegd={(bijlagen) =>
                        muteerBestanden((bestanden) => [...bestanden, ...bijlagen])
                      }
                      orientatie={EOrientatie.Verticaal}
                    />
                  </div>
                  <div>
                    <BijlagenContainer
                      bestanden={bestanden}
                      uploadProgresses={uploadProgresses}
                      onBestandenChange={(bijlagen) => muteerBestanden((_) => bijlagen)}
                      bestandenMuterenToegestaan={!isBezigMetUploaden}
                    />
                  </div>
                </div>
                <GridStyleWrapper maxHeight={500} rowAmount={items.length}>
                  <Grid columns={kolommen} rows={items} getRowId={(x) => x.ID}>
                    <EditingState
                      onCommitChanges={async (changes) => {
                        if (changes.deleted === undefined) {
                          return;
                        }
                        const deleted = changes.deleted;
                        const id = deleted[deleted.length - 1] as number;
                      }}
                      onEditingRowIdsChange={(rowIds) => {
                        const id = rowIds[rowIds.length - 1] as number;
                      }}
                    />

                    <VirtualTable messages={{ noData: 'Geen sponsoritems' }} />
                    {/* <TableColumnResizing defaultColumnWidths={kolomBreedtes} /> */}
                    <TableEditColumn
                      width={65}
                      showEditCommand
                      showDeleteCommand
                      cellComponent={DXTableEditColumnCellComponent}
                      commandComponent={DXTableEditColumnCommandComponent}
                    />

                    <TableHeaderRow />
                  </Grid>
                </GridStyleWrapper>
              </div>
            </>
          </div>
        }
      />
    </div>
  );
};

export default DetailComp;
