import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { IFilterSchemaFilter } from '../../../../../../shared/src/models/filter';
import { format } from 'date-fns';
import {
  EHoedanigheid,
  ERelatieSoort,
  hoedanigheidKeyMap,
  hoedanigheidMap,
  RelatieSelectieDialoogContext,
} from '../index';
import {
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import Combobox, { IOptie } from '../../../formulier/Combobox';
import { RootStoreContext } from '../../../../stores/RootStore';
import { DataTypeProvider, IntegratedSorting, SortingState } from '@devexpress/dx-react-grid';
import { IconOrganisatie, IconPersoon, IconPin } from '../../../Icons';
import { observer } from 'mobx-react-lite';
import api from '../../../../api';
import RadioKnop from '../../../formulier/RadioKnop';
import LoadingSpinner from '../../../Gedeeld/LoadingSpinner';
import { Kleur as EKleur } from '../../../../bedrijfslogica/constanten';
import {
  formatteerAdresV2,
  formatteerOrganisatieNaam,
  formatteerPersoonNaam,
  formatteerRelatieNaam,
} from '../../../../helpers';
import {
  VirtualTable,
  TableHeaderRow,
  TableColumnResizing,
  Grid,
} from '@devexpress/dx-react-grid-bootstrap4';
import { IOphalenRelatiesResultElementV2 } from '../../../../../../shared/src/api/v2/relatie';
import { EResultType } from '../../../../stores/CheckStore';

interface IResult {
  relID: number;
  persID?: number;
}

type TEntiteit = IOphalenRelatiesResultElementV2;

interface IProps {}

enum EPinStatus {
  Ongepind,
  Gepind,
}

const GepindTabblad: React.FC<IProps> = observer((props) => {
  const { gebruikerStore, checkStore } = useContext(RootStoreContext);
  const { koppelOpties, onGekozen, hoedanigheid, onHoedanigheidChange } = useContext(
    RelatieSelectieDialoogContext,
  );

  const [gebruikerId, setGebruikerId] = useState<number | null>(
    gebruikerStore.gebruiker!.AspGebrID,
  );
  const [relaties, setRelaties] = useState<TEntiteit[] | null>(null);

  const [pinStatus, setPinStatus] = useState<EPinStatus | null>(null);

  const ophalenRelaties = useCallback(async () => {
    const filters: IFilterSchemaFilter[] = [];
    if (koppelOpties.relatieSoort !== ERelatieSoort.Beide) {
      filters.push({
        naam: 'RELATIESOORT',
        data: koppelOpties.relatieSoort === ERelatieSoort.Persoon ? 'PERSOON' : 'ORGANISATIE',
      });
    }
    if (hoedanigheid !== EHoedanigheid.Beide) {
      const filter = {
        naam: 'HOEDANIGHEID_NAAM_ENUMS',
        data: [hoedanigheidKeyMap[hoedanigheid]!],
      };
      filters.push(filter);
    }

    const gepindeRelatiesResult = await api.v2.relatieZoeken.ophalenGepindeRelaties({
      filterSchema: {
        filters: [
          {
            naam: 'ASP_GEBR_IDS',
            data: [gebruikerId],
          },
        ],
      },
    });

    if (gepindeRelatiesResult.relatiePins.length === 0) {
      setRelaties([]);
      return;
    }
    const relatiesResult = await api.v2.relatie.ophalenRelaties({
      filterSchema: {
        filters: [
          ...filters,
          {
            naam: 'IDS',
            data: gepindeRelatiesResult.relatiePins.map((x) => x.RelID),
          },
        ],
      },
    });
    setRelaties(relatiesResult.relaties);
  }, [setRelaties, gebruikerId, hoedanigheid, koppelOpties.relatieFilterSchema]);

  useEffect(() => {
    ophalenRelaties();
  }, [ophalenRelaties]);

  const gebruikersRijbron = useMemo(() => {
    if (gebruikerStore.gebruikers === null) {
      return null;
    }

    return gebruikerStore.gebruikers.filter(
      (gebr) => gebr.AspGebrID !== gebruikerStore.gebruiker!.AspGebrID,
    );
  }, [gebruikerStore.gebruikers, gebruikerStore.gebruiker]);

  const comboboxOpties = useMemo<IOptie<number>[] | null>(() => {
    if (gebruikersRijbron === null) {
      return null;
    }

    const opties = gebruikersRijbron.map((gebr) => ({
      id: gebr.AspGebrID,
      label: gebr.NaamKort,
    }));

    return opties;
  }, [gebruikersRijbron]);

  const sleutelExtractor = useCallback((row: TEntiteit) => row.RelID, []);
  const kolommen = useMemo<TypedColumn<TEntiteit>[]>(
    () => [
      {
        name: 'Relatiesoort' as any,
        title: ' ',
      },
      {
        name: '__NAAM' as any,
        title: 'Naam',
        getCellValue: (rij) => {
          if (relaties === null) {
            return;
          }

          const relatie = relaties.find((x) => x.RelID === rij.RelID)!;

          if (relatie.persoon === null) {
            return <span>{relatie.organisatie!.NaamKort}</span>;
          }

          return formatteerRelatieNaam({
            voorvoegsel: relatie.persoon!.Voorvoegsel,
            voornaam: relatie.persoon!.Voornaam,
            voorletters: relatie.persoon!.Voorletters,
            achternaam: relatie.persoon!.Achternaam || undefined,
            aanhefKort: relatie.persoon!.geslacht.AanhefKort || undefined,
            organisatienaam: relatie.organisatie !== null ? relatie.organisatie.NaamKort : null,
            relatiesoort: relatie.Relatiesoort,
          });
        },
      },
      {
        name: 'adres',
        title: 'Adres',
        getCellValue: (row, columnName) => {
          const adres = row.adres;
          if (adres === null) {
            return '';
          }

          const adresString =
            adres.Straatnaam +
            adres.Huisnummer.toString() +
            (adres.Bisnummer !== null ? adres.Bisnummer : '');
          return adresString;
        },
      },
      {
        name: 'Relatienummer',
        title: 'Rel.nr.',
      },
      {
        name: '__unpin',
        title: ' ',
      },
      {
        name: '__kies_actie',
        title: ' ',
      },
    ],
    [relaties],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<TEntiteit>[]>(
    () => [
      {
        columnName: 'Relatienummer',
        width: 90,
      },
      {
        columnName: 'Relatiesoort',
        width: 40,
      },
      {
        columnName: '__NAAM' as any,
        width: 225,
      },
      {
        columnName: 'adres',
        width: 300,
      },
      {
        columnName: 'Referentiedatum',
        width: 125,
      },
      {
        columnName: '__unpin' as any,
        width: 60,
      },
      {
        columnName: '__kies_actie' as any,
        width: 80,
      },
    ],
    [],
  );

  const hoedanigheidOpties = useMemo<IOptie<EHoedanigheid>[]>(() => {
    return Object.keys(EHoedanigheid)
      .map((x) => Number(x) as EHoedanigheid)
      .filter((x) => !isNaN(x))
      .map((hoedanigheid) => ({
        id: hoedanigheid,
        label: hoedanigheidMap[hoedanigheid],
      }));
  }, [gebruikersRijbron]);

  if (gebruikersRijbron === null) {
    return <LoadingSpinner />;
  }

  if (gebruikersRijbron.length === 0) {
    return (
      <span>Er moeten mininaal twee gebruikers zijn voor het functioneren van dit component</span>
    );
  }

  return (
    <div className="flex-fill d-flex flex-column mt-2">
      <div className="d-flex">
        {/* Van mij */}
        <div
          className="d-flex align-items-center p-2 pl-4 pr-4"
          style={{ borderRight: `1px solid ${EKleur.LichtGrijs}` }}
        >
          <div className="d-flex align-items-center">
            <span>
              <RadioKnop
                aangevinkt={gebruikerStore.gebruiker!.AspGebrID === gebruikerId}
                onAangevinkt={() => setGebruikerId(gebruikerStore.gebruiker!.AspGebrID)}
              />
            </span>
            <span className="ml-1">Van mij</span>
          </div>
        </div>

        {/* Iemand anders */}
        <div className="d-flex align-items-center p-2 pl-4 pr-4">
          <div className="d-flex align-items-center">
            <span>
              <RadioKnop
                aangevinkt={gebruikerStore.gebruiker!.AspGebrID !== gebruikerId}
                onAangevinkt={() => {
                  const gebrID = gebruikersRijbron[0].AspGebrID;
                  setGebruikerId(gebrID);
                }}
              />
            </span>
            <span className="ml-1">Iemand anders</span>
            <span className="ml-3" style={{ width: 100 }}>
              {comboboxOpties === null ? (
                <LoadingSpinner />
              ) : (
                <Combobox<number>
                  geselecteerd={
                    gebruikerId === gebruikerStore.gebruiker!.AspGebrID
                      ? gebruikersRijbron[0].AspGebrID
                      : gebruikerId
                  }
                  opties={comboboxOpties}
                  onSelectieChange={(id) => setGebruikerId(id)}
                  legeOptieTonen={false}
                />
              )}
            </span>
          </div>
        </div>

        <div className="flex-fill" />
        <div className="d-flex align-items-center p-2 pr-4">
          <div className="d-flex align-items-center">
            <span>Hoedanigheid</span>
          </div>
          <div className="d-flex align-items-center ml-2">
            <Combobox<EHoedanigheid>
              geselecteerd={hoedanigheid}
              onSelectieChange={(x) => onHoedanigheidChange(x!)}
              legeOptieTonen={false}
              opties={hoedanigheidOpties}
            />
          </div>
        </div>
      </div>

      <div className="p-0 mt-2">
        {relaties === null ? (
          <LoadingSpinner />
        ) : (
          <GridStyleWrapper maxHeight={500} rowAmount={relaties.length}>
            <Grid getRowId={sleutelExtractor} columns={kolommen as any} rows={relaties}>
              <DataTypeProvider
                for={['Relatiesoort']}
                formatterComponent={(props) => {
                  const iconStyle: React.CSSProperties = {
                    width: 18,
                    height: 18,
                    fill: EKleur.Grijs,
                  };
                  const entiteit = props.row as TEntiteit;
                  return (
                    <span className="ml-2">
                      {entiteit.Relatiesoort === 'P' ? (
                        <IconPersoon style={iconStyle} />
                      ) : (
                        <IconOrganisatie style={iconStyle} />
                      )}
                    </span>
                  );
                }}
              />

              <DataTypeProvider
                for={['adres']}
                formatterComponent={(props) => {
                  const entiteit = props.row as TEntiteit;
                  const a = entiteit.adres;
                  return a === null ? (
                    <span>-</span>
                  ) : (
                    <span>
                      {formatteerAdresV2({
                        plaatsnaam: a.Plaatsnaam,
                        landnaamKort: a.LandnaamKort,
                        landnaamEnum: a.LandnaamEnum,
                        huisnummer: a.Huisnummer,
                        straatnaam: a.Straatnaam,
                        postcode: a.Postcode,
                        bisnummer: a.Bisnummer,
                      })}
                    </span>
                  );
                }}
              />

              <DataTypeProvider
                for={['__kies_actie']}
                formatterComponent={(formatterProps) => {
                  const entiteit = formatterProps.row as TEntiteit;
                  return (
                    <a
                      href="#"
                      onClick={(ev) => {
                        ev.stopPropagation();
                        onGekozen(entiteit.RelID);
                      }}
                    >
                      Kies
                    </a>
                  );
                }}
              />

              <DataTypeProvider
                for={['__unpin']}
                formatterComponent={(formatterProps) => {
                  const entiteit = formatterProps.row as TEntiteit;
                  return (
                    <a
                      href="#"
                      onClick={async () => {
                        if (
                          (
                            await checkStore.bevestigen({
                              inhoud: `Pin verwijderen voor deze relatie?`,
                            })
                          ).type === EResultType.Annuleren
                        ) {
                          return;
                        }

                        const status =
                          pinStatus === EPinStatus.Ongepind
                            ? EPinStatus.Gepind
                            : EPinStatus.Ongepind;
                        setPinStatus(status);

                        await api.v2.relatieZoeken.pinRelatie({
                          relID: entiteit.RelID,
                          aspGebrID: gebruikerStore.gebruiker!.AspGebrID,
                          gepind: status === EPinStatus.Gepind,
                        });

                        await ophalenRelaties();
                      }}
                    >
                      Unpin
                    </a>
                  );
                }}
              />

              <SortingState
                defaultSorting={[
                  {
                    columnName: '__NAAM',
                    direction: 'asc',
                  },
                ]}
              />

              <IntegratedSorting />
              <VirtualTable
                messages={{ noData: 'Geen resultaat' }}
                rowComponent={(rowProps) => (
                  <tr
                    // className=""
                    style={{ cursor: 'pointer' }}
                    onDoubleClick={() => {
                      const entiteit: TEntiteit = rowProps.row;
                      onGekozen(entiteit.RelID);
                    }}
                  >
                    {rowProps.children}
                  </tr>
                )}
              />

              <TableColumnResizing columnWidths={kolomBreedtes} />
              <TableHeaderRow showSortingControls />
            </Grid>
          </GridStyleWrapper>
        )}
      </div>
    </div>
  );
});

export default GepindTabblad;
