import {
  Column,
  DataTypeProvider,
  IntegratedSorting,
  RowDetailState,
  SelectionState,
  SortingState,
  TableColumnWidthInfo,
  EditingState,
  VirtualTable as VirtualTableBase,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  TableSelection,
  VirtualTable,
  TableRowDetail,
} from '@devexpress/dx-react-grid-bootstrap4';
import { format } from 'date-fns';
import _ from 'lodash';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { IOphalenDienstenResultElement } from '../../../../../shared/src/api/v2/dienst/service';
import { IOphalenOpdrachtenResultElement } from '../../../../../shared/src/api/v2/service/opdracht';
import { IOphalenWerkbonnenResultElement } from '../../../../../shared/src/api/v2/service/werkbon';
import api from '../../../api';
import LoadingSpinner from '../../../components/Gedeeld/LoadingSpinner';
import MenuLayout from '../../../components/MenuLayout';
import nameof from '../../../core/nameOf';
import useUrlState from '../../../core/useUrlState';
import {
  DXCommandComponent,
  DXTableCheckboxComponent,
  DXTableToggleCellComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../helpers/dxTableGrid';
import { IOphalenMeldingenResultElement } from '../../../../../shared/src/api/v2/service/melding';
import FilterBalkV2, {
  IFilter,
  IFilterData,
  maakFilterSchema,
} from '../../../components/FilterBalkV2';
import ZoektermFilter from './ZoektermFilter';
import Bijlage from './Bijlage';
import {
  IOphalenDocumentenResultElement,
  IOphalenDocumentsoortenResultElement,
} from '../../../../../shared/src/api/v2/product/documentatie';
import { IconToevoegen, IconVerwijderen } from '../../../components/Icons';
import { Kleur } from '../../../bedrijfslogica/constanten';
import { EResultType } from '../../../stores/CheckStore';
import { RootStoreContext } from '../../../stores/RootStore';
import SelectieVak from '../../../components/SelectieVak';
import ToevoegenDocumentDialoog from './ToevoegenDocumentDialoog';
import Combobox, { IOptie } from '../../../components/formulier/Combobox';
import DetailComp from './DetailProducttypeComp';
import WijzigenDocumentDialoog from './WijzigenDocumentDialoog';
import { Helmet } from 'react-helmet';
import { findLastIndex } from 'lodash';
import { IOphalenProductsoortenResult } from '../../../../../shared/src/api/v2/product/soort';

enum EFilter {
  Zoekterm = 'ZOEKTERM',
  GekoppeldDocument = 'GEKOPPELD_DOCUMENT',
  Documentsoort = 'PRODDOCSRT_IDS',
  Productsoort = 'PRODSRT_IDS',
}

interface IProps extends RouteComponentProps {}

export interface IToevoegenDocumentDialoogState {}

export interface IWijzigenDocumentDialoogState {
  id: number;
}

export interface IUrlState {
  filterdata: IFilterData<EFilter>[];
  selectie: number[];
  toevoegenDocumentDialoogState: IToevoegenDocumentDialoogState | null;
  wijzigenDocumentDialoogState: IWijzigenDocumentDialoogState | null;
}

const defaultUrlState: IUrlState = {
  filterdata: [
    {
      naam: EFilter.Zoekterm,
      data: '',
      isActief: false,
    },
    {
      naam: EFilter.GekoppeldDocument,
      data: 1,
      isActief: false,
    },
    {
      naam: EFilter.Documentsoort,
      data: 1, // TODO Gil is hard gecodeerde ID
      isActief: true,
    },
    {
      naam: EFilter.Productsoort,
      data: 11, // TODO Gil is hard gecodeerde ID
      isActief: false,
    },
  ],
  selectie: [],
  toevoegenDocumentDialoogState: null,
  wijzigenDocumentDialoogState: null,
};

interface IState {
  werkbonnen: IOphalenWerkbonnenResultElement[];
  opdrachten: IOphalenOpdrachtenResultElement[];
  diensten: IOphalenDienstenResultElement[];
  meldingen: IOphalenMeldingenResultElement[];
}

export interface IRegel extends IOphalenDocumentenResultElement {}

export interface IMutatieWerkbonDialoogState {
  werkbonID: number;
}

export interface IDocumentatieContext {
  ophalenDocumenten: () => void;
}

export const DocumentatieContext = React.createContext<IDocumentatieContext>(null as any);

const Documentatie: React.FC<IProps> = (props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState<IUrlState>(props, defaultUrlState);
  const { checkStore } = useContext(RootStoreContext);

  const [documenten, setDocumenten] = useState<IOphalenDocumentenResultElement[] | null>(null);
  const [documentsoorten, setDocumentsoorten] = useState<
    IOphalenDocumentsoortenResultElement[] | null
  >(null);

  const [productsoorten, setProductsoorten] = useState<IOphalenProductsoortenResult | null>(null);

  const ophalenProductsoorten = useCallback(async () => {
    const result = await api.v2.product.soort.ophalenProductsoorten({
      filterSchema: { filters: [{ naam: 'ACTIEF', data: true }] },
    });

    setProductsoorten(result);
  }, []);

  useEffect(() => {
    ophalenProductsoorten();
  }, [ophalenProductsoorten]);

  const groepFilterOpties = useMemo<IOptie<number>[]>(
    () => [
      {
        id: 0,
        label: 'Gekoppeld en niet gekoppeld',
      },
      {
        id: 1,
        label: 'Gekoppeld',
      },
      {
        id: 2,
        label: 'Niet gekoppeld',
      },
    ],
    [],
  );

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.Zoekterm,
        altijdWeergevenInBalk: true,
        weergave: ZoektermFilter,
      },
      {
        naam: EFilter.GekoppeldDocument,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return (
            <div className="d-flex align-items-center">
              <span className="mr-2">Document</span>
              <Combobox
                geselecteerd={weergaveProps.data}
                onSelectieChange={(x) => {
                  weergaveProps.onDataChange(x);
                  weergaveProps.setIsActief(true);
                  weergaveProps.toepassen();
                }}
                opties={groepFilterOpties}
              />
            </div>
          );
        },
      },
      {
        naam: EFilter.Documentsoort,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return (
            <div className="d-flex align-items-center">
              <span className="mr-2">Soort</span>
              <Combobox
                geselecteerd={weergaveProps.data}
                onSelectieChange={(x) => {
                  weergaveProps.onDataChange(x);
                  weergaveProps.setIsActief(true);
                  weergaveProps.toepassen();
                }}
                opties={documentsoorten!.map((x) => {
                  return { id: x.ID, label: x.Naam };
                })}
              />
            </div>
          );
        },
      },
      {
        naam: EFilter.Productsoort,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return (
            <div className="d-flex align-items-center">
              <span className="mr-2">Cat.</span>
              {productsoorten === null ? (
                <LoadingSpinner />
              ) : (
                <Combobox
                  geselecteerd={weergaveProps.data}
                  onSelectieChange={(x) => {
                    weergaveProps.onDataChange(x);
                    weergaveProps.setIsActief(true);
                    weergaveProps.toepassen();
                  }}
                  opties={productsoorten.map((x) => {
                    return { id: x.ProdSrtID, label: x.Naam };
                  })}
                />
              )}
            </div>
          );
        },
      },
    ],
    [documentsoorten],
  );
  const [filterSchema, setFilterSchema] = useState(maakFilterSchema(urlState.filterdata));

  const ophalenDocumenten = useCallback(async () => {
    const documentenResult = (
      await api.v2.product.documentatie.ophalenDocumenten({
        filterSchema: {
          filters: [...filterSchema.filters!],
        },
      })
    ).documenten;

    const documentenGesorteerd = _.orderBy(documentenResult, ['Naam'], ['asc']);
    setDocumenten(documentenGesorteerd);
  }, [filterSchema]);

  useEffect(() => {
    ophalenDocumenten();
  }, [ophalenDocumenten]);

  const ophalenDocumentsoorten = useCallback(async () => {
    const documentsoortenResult = (
      await api.v2.product.documentatie.ophalenDocumentsoorten({
        filterSchema: {
          filters: [],
        },
      })
    ).soorten;

    setDocumentsoorten(documentsoortenResult);
  }, [filterSchema]);

  useEffect(() => {
    ophalenDocumentsoorten();
  }, [ophalenDocumentsoorten]);

  const keyExtractor = useCallback((rij: IRegel) => rij.ID, []);

  const kolommen = useMemo<TypedColumn<IRegel>[]>(() => {
    return [
      {
        name: 'Naam',
        title: 'Naam',
      },
      {
        name: '__soort' as any,
        title: 'Soort',
        getCellValue: (x) => {
          return x.documentsoort.Naam;
        },
      },
      {
        name: '__talen',
        title: 'Talen',
      },
      {
        name: '__gekoppeldOp' as any,
        title: 'Gekoppeld op',
        getCellValue: (x) => {
          return x.productsoorten.length !== 0 ? 1 : x.producttypen.length !== 0 ? 2 : 3;
        },
      },
      // {
      //   name: '__aantalTypen' as any,
      //   title: 'Atl. typen',
      //   getCellValue: (x) => {
      //     return x.producttypen.length;
      //   },
      // },
      {
        name: '__aantalProducten' as any,
        title: '# Producten',
        getCellValue: (x) => {
          return _.sum(x.producttypen.map((p) => p.aantalProducten));
        },
      },
      // {
      //   name: 'Productsoortnamen',
      //   title: 'Categorieen',
      //   getCellValue: (x) => {
      //     return x.documentsoort.Naam;
      //   },
      // },
      {
        name: 'MeesturenNaLevering' as any,
        title: 'Meesturen',
      },
      {
        name: '__bijlage' as any,
        title: 'Document',
      },
      {
        name: '__registratiedatum' as any,
        title: 'Geregistreerd',
        getCellValue: (x) => {
          return x.RecordToegevoegd;
        },
      },
    ];
  }, []);

  const kolombreedtes = useMemo<TypedTableColumnWidthInfo<IRegel>[]>(() => {
    return [
      {
        columnName: 'Naam',
        width: 275,
      },
      {
        columnName: '__soort' as any,
        width: 200,
      },
      {
        columnName: '__talen' as any,
        width: 90,
      },
      {
        columnName: '__aantalTypen' as any,
        width: 100,
      },
      {
        columnName: '__aantalProducten' as any,
        width: 150,
      },
      {
        columnName: '__gekoppeldOp' as any,
        width: 125,
      },
      // {
      //   columnName: 'Productsoortnamen' as any,
      //   width: 200,
      // },
      {
        columnName: '__bijlage' as any,
        width: 150,
      },
      {
        columnName: '__registratiedatum' as any,
        width: 175,
      },
      {
        columnName: 'MeesturenNaLevering',
        width: 125,
      },
    ];
  }, []);

  const kolomExtensies: VirtualTableBase.ColumnExtension[] = useMemo(() => {
    return [
      {
        columnName: `__bijlage`,
        align: 'right',
      },
      {
        columnName: `__registratiedatum`,
        align: 'right',
      },
    ];
  }, []);

  const contextValue = useMemo<IDocumentatieContext>(() => {
    return {
      ophalenDocumenten,
    };
  }, []);

  return documenten === null || documentsoorten === null || productsoorten === null ? (
    <div className="flex-fill d-flex align-items-center justify-content-center">
      <LoadingSpinner />
    </div>
  ) : (
    <>
      <Helmet>
        <title>Documentatie</title>
      </Helmet>
      <DocumentatieContext.Provider value={contextValue}>
        <MenuLayout
          menu={
            <div className="d-flex flex-column">
              <div className="d-flex">
                <div className="mt-2 d-flex align-items-center">
                  <button
                    className="btn btn-sm btn-light d-flex align-items-center ml-2"
                    style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                    onClick={async () => {
                      setUrlStateSync('toevoegenDocumentDialoogState', {});
                    }}
                  >
                    <IconToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                    <span className="ml-2">Toevoegen</span>
                  </button>
                  <button
                    className="btn btn-sm btn-light d-flex align-items-center ml-2"
                    style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                    disabled={urlState.selectie.length === 0}
                    onClick={async () => {
                      const checkData = await api.v2.product.documentatie.checkVerwijderenDocumenten(
                        {
                          ids: urlState.selectie,
                        },
                      );
                      const checkResult = await checkStore.controleren({
                        checkData,
                      });
                      if (checkResult.type === EResultType.Annuleren) {
                        return;
                      }

                      if (
                        (
                          await checkStore.bevestigen({
                            inhoud: 'Geselecteerde documenten verwijderen?',
                          })
                        ).type === EResultType.Annuleren
                      ) {
                        return;
                      }

                      const result = await api.v2.product.documentatie.verwijderenDocumenten({
                        ids: urlState.selectie,
                      });

                      setUrlStateSync('selectie', []);

                      ophalenDocumenten();
                    }}
                  >
                    <IconVerwijderen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                    <span className="ml-2">Verwijderen</span>
                  </button>
                </div>

                <div className="mt-2 ml-3 flex-fill">
                  <FilterBalkV2
                    filters={filters}
                    filterData={urlState.filterdata}
                    onFilterDataChange={(x) => setUrlStateSync('filterdata', x)}
                    onFilterSchemaChange={setFilterSchema}
                  />
                </div>
              </div>

              {documenten !== null && (
                <div className="d-flex align-items-center mt-2">
                  <SelectieVak
                    totaalAantal={documenten.length}
                    aantal={urlState.selectie.length}
                    onChange={(allesGeselecteerd) => {
                      if (allesGeselecteerd) {
                        setUrlStateSync('selectie', documenten!.map(keyExtractor));
                      } else {
                        setUrlStateSync('selectie', []);
                      }
                    }}
                  />
                  {/* <span className="ml-2">Totaal {producten!.length} tarieven</span> */}
                </div>
              )}
            </div>
          }
          body={
            <GridStyleWrapper height="calc(100vh - 150px)">
              <Grid rows={documenten} columns={kolommen} getRowId={keyExtractor}>
                <DataTypeProvider
                  for={[nameof<IRegel>('Naam')]}
                  formatterComponent={(formatterProps) => {
                    const rij: IRegel = formatterProps.row;
                    return <span>{rij.Naam}</span>;
                  }}
                />

                <DataTypeProvider
                  for={[nameof<IRegel>('MeesturenNaLevering')]}
                  formatterComponent={(formatterProps) => {
                    return <span>{formatterProps.value ? 'Ja' : ''}</span>;
                  }}
                />

                <DataTypeProvider
                  for={['__soort']}
                  formatterComponent={(formatterProps) => {
                    const rij: IRegel = formatterProps.row;
                    return <span>{rij.documentsoort.Naam}</span>;
                  }}
                />

                <DataTypeProvider
                  for={['__talen']}
                  formatterComponent={(formatterProps) => {
                    const rij: IRegel = formatterProps.row;
                    return <span>{rij.talen.map((x) => x.NaamKort).join(',')}</span>;
                  }}
                />

                <DataTypeProvider
                  for={['__gekoppeldOp']}
                  formatterComponent={(formatterProps) => {
                    const rij: IRegel = formatterProps.row;

                    let tekst = '';
                    tekst += rij.productsoorten.length !== 0 ? 'Soort, ' : '';
                    tekst += rij.producttypen.length !== 0 ? 'Type, ' : '';

                    return <span>{tekst.slice(0, -2)}</span>;
                  }}
                />

                <DataTypeProvider
                  for={['__aantalProducten']}
                  formatterComponent={(formatterProps) => {
                    const rij: IRegel = formatterProps.row;
                    const aantal = _.sum(rij.producttypen.map((x) => x.aantalProducten));
                    return <span>{aantal}</span>;
                  }}
                />

                <DataTypeProvider
                  for={['__bijlage']}
                  formatterComponent={(formatterProps) => {
                    const x: IRegel = formatterProps.row;
                    return (
                      <Bijlage
                        bestand={x.bestand}
                        onBestIDChange={async (bestID) => {
                          await api.v2.product.documentatie.wijzigenDocument({
                            id: x.ID,
                            naam: x.Naam,
                            documentURL: x.DocumentURL,
                            naamEnum: x.NaamEnum,
                            prodDocSrtID: x.documentsoort.ID,
                            taalIDs: x.talen.map((x) => x.TaalID),
                            documentBestandID: bestID,
                            prodSrtIDs: x.productsoorten.map((x) => x.ProdSrtID),
                            meesturenNaLevering: x.MeesturenNaLevering,
                          });
                          await ophalenDocumenten();
                        }}
                      />
                    );
                  }}
                />

                <DataTypeProvider
                  for={['__registratiedatum']}
                  formatterComponent={(formatterProps) => {
                    const rij: IRegel = formatterProps.row;
                    return (
                      <span>{format(new Date(rij.RecordToegevoegd), 'dd-MM-yyyy HH:mm')}</span>
                    );
                  }}
                />

                {/* <SortingState defaultSorting={[]} /> */}
                <SortingState
                  defaultSorting={[
                    {
                      columnName: '__registratiedatum',
                      direction: 'desc',
                    },
                  ]}
                />
                <IntegratedSorting />

                <VirtualTable columnExtensions={kolomExtensies} />

                <TableColumnResizing
                  defaultColumnWidths={kolombreedtes as TableColumnWidthInfo[]}
                />
                <TableHeaderRow showSortingControls />

                <RowDetailState />
                <TableRowDetail
                  contentComponent={DetailComp}
                  toggleCellComponent={DXTableToggleCellComponent}
                />

                <EditingState
                  onAddedRowsChange={() => {}}
                  onEditingRowIdsChange={(x) => {
                    const id = x[x.length - 1] as number;

                    setUrlStateSync('wijzigenDocumentDialoogState', { id });
                  }}
                  onCommitChanges={() => null}
                />
                <TableEditColumn
                  width={35}
                  showEditCommand
                  // showDeleteCommand
                  commandComponent={DXCommandComponent}
                />

                {/* <RowDetailState
                expandedRowIds={urlState.uitgeklapt}
                onExpandedRowIdsChange={(x) => setUrlStateSync('uitgeklapt', x as number[])}
              /> */}

                <SelectionState
                  selection={urlState.selectie}
                  onSelectionChange={(value) => setUrlStateSync('selectie', value as number[])}
                />

                <TableSelection cellComponent={DXTableCheckboxComponent} />
              </Grid>
            </GridStyleWrapper>
          }
        />
      </DocumentatieContext.Provider>
      {urlState.toevoegenDocumentDialoogState !== null && (
        <ToevoegenDocumentDialoog
          open
          onSuccess={async () => {
            await ophalenDocumenten();
            setUrlStateSync('toevoegenDocumentDialoogState', null);
          }}
          onAnnuleren={() => setUrlStateSync('toevoegenDocumentDialoogState', null)}
        />
      )}
      {urlState.wijzigenDocumentDialoogState !== null && (
        <WijzigenDocumentDialoog
          open
          id={urlState.wijzigenDocumentDialoogState.id}
          onSuccess={async () => {
            await ophalenDocumenten();
            setUrlStateSync('wijzigenDocumentDialoogState', null);
          }}
          onAnnuleren={() => setUrlStateSync('wijzigenDocumentDialoogState', null)}
        />
      )}
    </>
  );
};

export default Documentatie;
