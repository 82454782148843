import { Formik } from 'formik';
import React, { useCallback, useContext, useState, useMemo, useEffect } from 'react';
import { ModalFooter, ModalTitle } from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/ModalHeader';
import { IOphalenRelatiesResultElementV2 } from '../../../../../shared/src/api/v2/relatie';
import api from '../../../api';
import IDialoogProps from '../../../core/IDialoogProps';
import Dialoog from '../../dialogen/Dialoog';
import LoadingSpinner from '../../Gedeeld/LoadingSpinner';
import Tabblad, { ITabblad } from '../../layout/Tabblad';
import CrediteurTab from './CrediteurTab';
import DetailTab from './DetailTab';

interface IProps extends IDialoogProps<{}> {
  relID: number;
}

export interface IContext {
  relatie: IOphalenRelatiesResultElementV2 | null;
}

export const CrediteurContext = React.createContext<IContext>({
  relatie: null,
});

enum ETablad {
  Crediteur,
  Detail,
}

export interface IFormikValues {
  // notities: string | null;
}

const CrediteurInfoDialoog: React.FC<IProps> = (props) => {
  const { dialoogIndex, onAnnuleren, onSuccess, open } = props;
  const [tabblad, setTabblad] = useState<ETablad>(ETablad.Crediteur);

  const [relatie, setRelatie] = useState<IOphalenRelatiesResultElementV2 | null>(null);

  const ophalenRelatie = useCallback(async () => {
    const relatiesResult = await api.v2.relatie.ophalenRelaties({
      filterSchema: {
        filters: [{ naam: 'IDS', data: [props.relID] }],
      },
    });

    setRelatie(relatiesResult.relaties[0]);
  }, []);

  useEffect(() => {
    ophalenRelatie();
  }, [ophalenRelatie]);

  const tabbladen = useMemo<ITabblad<ETablad>[]>(
    () => [
      {
        id: ETablad.Crediteur,
        label: 'Crediteur',
        content: CrediteurTab,
      },
      {
        id: ETablad.Detail,
        label: 'Overig',
        content: DetailTab,
      },
    ],
    [],
  );

  const initialvalues = useMemo<IFormikValues | null>(() => {
    if (relatie === null) {
      return null;
    }

    return {
      // notities: persoon.Notities,
    };
  }, [relatie]);

  return (
    <Dialoog index={dialoogIndex || 0} modalProps={{ size: 'lg' }}>
      <ModalHeader>
        <ModalTitle>Crediteurinformatie</ModalTitle>
      </ModalHeader>

      <div className="d-flex flex-column flex-fill" style={{ minHeight: `500px` }}>
        {initialvalues === null ? (
          <div className="d-flex flex-fill align-items-center justify-content-center">
            <LoadingSpinner />
          </div>
        ) : (
          <>
            <CrediteurContext.Provider
              value={{
                relatie: relatie,
              }}
            >
              <Formik<IFormikValues>
                isInitialValid
                initialValues={initialvalues}
                onSubmit={() => null}
                render={(FormikProps) => {
                  const { submitForm, isSubmitting, values, errors } = FormikProps;
                  return (
                    <>
                      <div className="d-flex flex-column flex-fill">
                        <Tabblad
                          tabbladen={tabbladen}
                          geselecteerd={tabblad}
                          onSelectieChange={(id) => {
                            setTabblad(id);
                          }}
                          options={{
                            tabbladComponentProps: {
                              onAnnuleren: props.onAnnuleren,
                            },
                          }}
                        />
                      </div>
                      <ModalFooter className="d-flex flex-row justify-content-start">
                        <button
                          className="btn btn-primary"
                          onClick={onAnnuleren}
                          style={{ width: 100 }}
                        >
                          Sluiten
                        </button>
                      </ModalFooter>
                    </>
                  );
                }}
              />
            </CrediteurContext.Provider>
          </>
        )}
      </div>
    </Dialoog>
  );
};

export default CrediteurInfoDialoog;
