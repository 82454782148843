import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ISectieProps } from '../index';
import api from '../../../../../../../../../api';
import LoadingSpinner from '../../../../../../../../../components/Gedeeld/LoadingSpinner';
import Tabel, {
  IKoppelLocatieProductDialoogState,
  IKoppelVoorraadProductDialoogState,
  IWijzigenOpdrachtDialoogState,
  IWijzigenRegelDialoogState,
  IToevoegenOpdrachtregelDialoogState,
  IProductreserveringDialoogState,
} from './Tabel';
import _ from 'lodash';
import ResizeObserver from 'react-resize-observer';
import {
  IOphalenOpdrachtenResultElementV2,
  IOphalenOpdrachtregelsResultElementV2,
} from '../../../../../../../../../../../shared/src/api/v2/transport/opdracht';

export interface IRegel extends IOphalenOpdrachtregelsResultElementV2 {
  opdracht: IOphalenOpdrachtenResultElementV2;
}

const Transport: React.FC<ISectieProps> = (props) => {
  const [opdrachtRegels, setOpdrachtRegels] = useState<IRegel[] | null>(null);
  const [uitgeklapteRegels, setUitgklapteRegels] = useState<number[]>([]);

  const [
    productreserveringDialoogState,
    setProductreserveringDialoogState,
  ] = useState<IProductreserveringDialoogState | null>(null);

  const [
    reserverenVoorraadDialoogState,
    setReserverenVoorraadDialoogState,
  ] = useState<IKoppelVoorraadProductDialoogState | null>(null);
  const [
    reserverenProductDialoogState,
    setReserverenProductDialoogState,
  ] = useState<IKoppelLocatieProductDialoogState | null>(null);
  const [
    toevoegenOpdrachtregelDialoogState,
    setToevoegenOpdrachtregelDialoogState,
  ] = useState<IToevoegenOpdrachtregelDialoogState | null>(null);
  const [
    wijzigenRegelDialoogState,
    setWijzigenRegelDialoogState,
  ] = useState<IWijzigenRegelDialoogState | null>(null);
  const [
    wijzigenOpdrachtDialoogState,
    setWijzigenOpdrachtDialoogState,
  ] = useState<IWijzigenOpdrachtDialoogState | null>(null);

  const ophalenOpdrachtregels = useCallback(async () => {
    const regelsResult = (
      await api.v2.transport.opdracht.ophalenOpdrachtregelsV2({
        filterSchema: {
          filters: [
            {
              naam: 'CNTBASIS_IDS',
              data: [props.contract.basis.CntBasisID],
            },
          ],
        },
      })
    ).regels;

    const trsOpdIDs = regelsResult.map((x) => x.TrsOpdID);

    const opdrachten = (
      await api.v2.transport.opdracht.ophalenOpdrachtenV2({
        filterSchema: {
          filters: [
            {
              naam: 'IDS',
              data: trsOpdIDs,
            },
          ],
        },
      })
    ).opdrachten;

    const regels = regelsResult.map((x) => {
      const opdracht = opdrachten.find((o) => o.TrsOpdID === x.TrsOpdID)!;
      return { ...x, opdracht };
    });

    // const regelsGesorteerd = _.orderBy(
    //   regelsResult.regels,
    //   // [(x: any) => x.opdracht.Bezoekdatum, 'Opdrachtnummer', 'Soort'],
    //   [(x: any) => x.opdracht.Opdrachtnummer, 'Soort'],
    //   ['desc', 'asc'],
    // );

    setOpdrachtRegels(regels);
  }, [props.contract.CntID]);

  useEffect(() => {
    ophalenOpdrachtregels();
  }, [ophalenOpdrachtregels]);

  const [height, setHeight] = useState<number | null>(null);

  return (
    <div className="d-flex flex-column">
      {opdrachtRegels === null ? (
        <div className="p-4 d-flex align-items-center justify-content-center">
          <LoadingSpinner />
        </div>
      ) : (
        <div style={{ position: 'relative', height: height || 0 }}>
          <div style={{ position: 'absolute', top: 0, left: 0, width: '100%' }}>
            <Tabel
              contract={props.contract}
              regels={opdrachtRegels}
              uitgeklapteRegels={uitgeklapteRegels}
              onUitgeklapteRegelsChange={(ids) => setUitgklapteRegels(ids)}
              productreserveringDialoogState={productreserveringDialoogState}
              onProductreserveringDialoogStateChange={setProductreserveringDialoogState}
              koppelVoorraadProductDialoogState={reserverenVoorraadDialoogState}
              onKoppelVoorraadProductDialoogStateStateChange={setReserverenVoorraadDialoogState}
              koppelLocatieProductDialoogState={reserverenProductDialoogState}
              onKoppelLocatieProductDialoogStateChange={setReserverenProductDialoogState}
              toevoegenOpdrachtregelDialoogState={toevoegenOpdrachtregelDialoogState}
              onToevoegenOpdrachtregelDialoogStateChange={setToevoegenOpdrachtregelDialoogState}
              wijzigenRegelDialoogState={wijzigenRegelDialoogState}
              onWijzigenRegelDialoogStateChange={setWijzigenRegelDialoogState}
              wijzigenOpdrachtDialoogState={wijzigenOpdrachtDialoogState}
              onWijzigenOpdrachtDialoogStateChange={setWijzigenOpdrachtDialoogState}
              onVerversenAangevraagd={() => ophalenOpdrachtregels()}
            />
            <ResizeObserver onResize={(rect) => setHeight(rect.height)} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Transport;
