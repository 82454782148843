import { RootStore } from './RootStore';
import { makeObservable, observable } from 'mobx';
import { OphalenRedenenBeeindigingResult } from '../../../shared/src/api/v2/contract/beeindiging';
import api from '../api';

// tslint:disable no-console
class ContractBeeindigingStore {
  public redenenBeeindiging: OphalenRedenenBeeindigingResult | null = null;

  // tslint:disable-next-line:variable-name
  private _rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this._rootStore = rootStore;

    makeObservable(this, {
      redenenBeeindiging: observable,
    });
  }

  public ophalenRedenenBeeindiging = async () => {
    const result = await api.v2.contract.beeindiging.ophalenRedenenBeeindiging({});

    this.redenenBeeindiging = result;
  };
}

export default ContractBeeindigingStore;
