import React, { useCallback, useContext, useState, useMemo, useEffect } from 'react';
import { Field, FieldProps } from 'formik';
import Combobox from '../../../../../../../../../components/formulier/Combobox';
import api from '../../../../../../../../../api';
import { IOphalenAbonnementenVoorModelResultElement } from '../../../../../../../../../../../shared/src/api/v2/contract';
import VinkVeld from '../../../../../../../../../components/formulier/VinkVeld';
import BedragInput from '../../../../../../../../../components/formulier/BedragInput';
import { veldnamen, IFormikValues, initialValues } from '../index';
import nameOf from '../../../../../../../../../core/nameOf';
import InactiefOverlay from '../../../../../../../../../components/InactiefOverlay';
import ContractContext, { IContext } from '../Context/ContractContext';
import MultiCombobox, {
  IKolom,
} from '../../../../../../../../../components/formulier/MultiCombobox';
import FormikVeldFout from '../../../../../../../../../components/formulier/FormikVeldFout';
import { values } from 'lodash';

interface IProps {}

interface IGebruikerAantal {
  id: number;
  label: string;
}

const actieKeuzes = [
  {
    id: 0,
    label: 'Looptijd contract',
  },
  {
    id: 1,
    label: '1 maand',
  },
  {
    id: 2,
    label: '2 maanden',
  },
  {
    id: 3,
    label: '3 maanden',
  },
  {
    id: 4,
    label: '4 maanden',
  },
  {
    id: 5,
    label: '5 maanden',
  },
  {
    id: 6,
    label: '1/2 jaar',
  },
  {
    id: 9,
    label: '9 maanden',
  },
  {
    id: 12,
    label: '1 jaar',
  },
];

export const AantalGebruikersKeuze = new Array(21).fill(null).map((_, i) => ({
  id: i,
  label: i === 0 ? '0' : String(i),
}));

const TarievenTab: React.FC<IProps> = (props) => {
  const [abonnementen, setAbonnementen] = useState<
    IOphalenAbonnementenVoorModelResultElement[] | null
  >(null);

  const kolommen = useMemo<IKolom<IOphalenAbonnementenVoorModelResultElement>[]>(
    () => [
      {
        key: 'Naam',
        label: 'Naam',
        breedte: 200,
      },
      {
        key: 'MinimaleTermijn',
        label: 'MinimaleTermijn',
        breedte: 200,
      },
    ],
    [],
  );

  const { contract, productmodellenTarieven } = useContext<IContext>(ContractContext);

  useEffect(() => {
    ophalenAbonnementen();
  }, [contract]);

  const ophalenAbonnementen = useCallback(async () => {
    if (contract === null) {
      return;
    }

    const result = await api.v2.contract.ophalenAbonnementenVoorModel({
      prodModID: contract.basis.productmodel.ProdModID,
      abonID: contract.basis.abonnement.AbonID,
    });

    const abonnementen = result.abonnementen.map((abonnement) => {
      return abonnement;
    });
    setAbonnementen(abonnementen);
  }, []);

  return (
    <div className="p-3">
      <div className="form-group">
        <div className="row mb-3">
          <div className="col-12">
            <label htmlFor="">Abonnement</label>
            <Field
              name={nameOf<IFormikValues>('abonnementID')}
              render={(fieldProps: FieldProps<IFormikValues>) => {
                const values = fieldProps.form.values;
                return (
                  <MultiCombobox<number, IOphalenAbonnementenVoorModelResultElement>
                    sleutelExtractor={(row) => row.AbonID}
                    onWaardeChange={(waarde: number | null) => {
                      const tariefset = productmodellenTarieven!.find(
                        (x) => x.ProdModID === contract?.basis.productmodel.ProdModID,
                      );

                      if (tariefset !== undefined) {
                        const tarieven = tariefset.tarieven.find((x) => x.AbonID === waarde)!;
                        values.maandhuur = tarieven.Maandhuur;
                        if (tarieven.MaandhuurActie !== null) {
                          values.maandhuurActie = tarieven.MaandhuurActie;
                          values.actieduur = tarieven.Actieduur;
                          values.maandhuurActieAangevinkt = true;
                        } else {
                          values.maandhuurActie = 0;
                          values.actieduur = 0;
                          values.maandhuurActieAangevinkt = false;
                        }
                      }
                      fieldProps.form.setFieldValue(fieldProps.field.name, waarde);
                      return;
                    }}
                    waarde={fieldProps.field.value}
                    opties={abonnementen}
                    kolommen={kolommen}
                    representatieFabriek={(row) => <div>{row.Naam}</div>}
                  />
                );
              }}
            />
          </div>
        </div>
      </div>
      <div className="form-group">
        <div className="row mb-3">
          <div className="col-3">
            <label>Maandhuur</label>
            <Field
              name={nameOf<IFormikValues>('maandhuur')}
              render={(x: FieldProps<IFormikValues>) => {
                return (
                  <div>
                    <InactiefOverlay
                      isInactief={false}
                      element={
                        <BedragInput
                          value={x.field.value}
                          onChange={(bedrag) => {
                            x.form.setFieldValue(x.field.name, bedrag);
                          }}
                        />
                      }
                    ></InactiefOverlay>
                  </div>
                );
              }}
            />
          </div>
          <div className="col-2">
            <label htmlFor="">Is actie?</label>
            <Field
              name={nameOf<IFormikValues>('maandhuurActieAangevinkt')}
              render={(x: FieldProps<IFormikValues>) => (
                <InactiefOverlay
                  isInactief={true}
                  element={
                    <VinkVeld
                      aangevinkt={x.field.value}
                      onGewijzigd={(aangevinkt: boolean) =>
                        x.form.setFieldValue(x.field.name, aangevinkt)
                      }
                    />
                  }
                ></InactiefOverlay>
              )}
            />
          </div>
          <div className="col-3">
            <label>Maandhuur actie</label>

            <Field
              name={nameOf<IFormikValues>('maandhuurActie')}
              render={(x: FieldProps<IFormikValues>) => (
                <div>
                  <InactiefOverlay
                    // isInactief={!x.form.values.maandhuurActieAangevinkt}
                    isInactief={true}
                    element={
                      <BedragInput
                        value={x.field.value === null ? 0 : x.field.value}
                        onChange={(bedrag) => {
                          x.form.setFieldValue(x.field.name, bedrag);
                        }}
                      />
                    }
                  ></InactiefOverlay>
                </div>
              )}
            />
          </div>
          <div className="col-4">
            <label className="ml-2">{veldnamen.actieduur}</label>
            <div className="d-flex items-align-center">
              <Field
                name={nameOf<IFormikValues>('actieduur')}
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;

                  return (
                    <div>
                      <InactiefOverlay
                        isInactief={fieldProps.form.values.maandhuurActieAangevinkt === false}
                        element={
                          <Combobox
                            onSelectieChange={(x) => {
                              form.setFieldValue(field.name, x);
                              // form.setFieldValue(
                              //   nameof<IFormikValues>('maandhuurActieAangevinkt'),
                              //   x > 0,
                              // );
                            }}
                            geselecteerd={field.value !== null ? field.value : actieKeuzes[0].id}
                            opties={actieKeuzes}
                          />
                        }
                      ></InactiefOverlay>
                      <FormikVeldFout fieldProps={fieldProps} />
                    </div>
                  );
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row form-group">
        <div className="col-4">
          <label>Aantal gebruikers</label>
          <Field
            name={nameOf<IFormikValues>('aantalGebruikers')}
            render={(fieldProps: FieldProps<IFormikValues>) => {
              const { field, form } = fieldProps;

              return (
                <>
                  <Combobox
                    opties={AantalGebruikersKeuze}
                    onSelectieChange={(x) => form.setFieldValue(field.name, x)}
                    geselecteerd={field.value}
                  />
                </>
              );
            }}
          />
        </div>
        <div className="col-4">
          <label>{veldnamen.gebruikerstoeslagTarief}</label>
          <Field
            name={nameOf<IFormikValues>('gebruikerstoeslagTarief')}
            render={(x: FieldProps<IFormikValues>) => {
              return (
                <div>
                  <BedragInput
                    value={x.field.value}
                    onChange={(bedrag) => {
                      x.form.setFieldValue(x.field.name, bedrag);
                    }}
                  />
                </div>
              );
            }}
          />
        </div>
        <div className="col-3">
          <label></label>
        </div>
      </div>
      <div className="row form-group">
        <div className="col-4">
          <label htmlFor="">{veldnamen.eenmaligBedrag}</label>
          <Field
            name={nameOf<IFormikValues>('eenmaligBedrag')}
            render={(x: FieldProps<IFormikValues>) => {
              return (
                <div>
                  <BedragInput
                    value={x.field.value}
                    onChange={(bedrag) => {
                      x.form.setFieldValue(x.field.name, bedrag);
                    }}
                  />
                </div>
              );
            }}
          />
        </div>
        <div className="col-2">
          <label htmlFor="">{veldnamen.eenmaligBedragAangevinkt}</label>
          <Field
            name="eenmaligBedragAangevinkt"
            render={(x: FieldProps<IFormikValues>) => (
              <VinkVeld
                aangevinkt={x.field.value}
                onGewijzigd={(aangevinkt: boolean) =>
                  x.form.setFieldValue(x.field.name, aangevinkt)
                }
              />
            )}
          />
        </div>
        <div>
          <label htmlFor="">{veldnamen.eenmaligBedragActie}</label>
          <Field
            name={nameOf<IFormikValues>('eenmaligBedragActie')}
            render={(x: FieldProps<IFormikValues>) => {
              return (
                <div>
                  <InactiefOverlay
                    isInactief={x.form.values.eenmaligBedragAangevinkt === false}
                    element={
                      <BedragInput
                        value={x.field.value === null ? 0 : x.field.value}
                        onChange={(bedrag) => {
                          x.form.setFieldValue(x.field.name, bedrag);
                        }}
                      />
                    }
                  ></InactiefOverlay>
                </div>
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default TarievenTab;
