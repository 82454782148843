import React from 'react';
import { Kleur } from '../../../../../../../../bedrijfslogica/constanten';
import UitlegTooltip from '../../../../../../../../components/formulier/UitlegTooltip';

interface IProps {
  status: EContractStatus;
  naam: string;
}

interface IPropsIndicatie {
  status: EContractStatus;
}

export enum EContractStatus {
  Concept = 'CONCEPT',
  Afgekeurd = 'AFGEKEURD',
  Lopend = 'LOPEND',
  Geannuleerd = 'GEANNULEERD',
  Overgenomen = 'OVERGENOMEN',
  Beeindigd = 'BEEINDIGD',
}

export const contractstatusKleurMap: { [status: string]: string } = {
  [EContractStatus.Concept]: Kleur.LichtBlauw,
  [EContractStatus.Afgekeurd]: Kleur.Zwart,
  [EContractStatus.Lopend]: Kleur.Groen,
  [EContractStatus.Geannuleerd]: Kleur.LichtGeel,
  [EContractStatus.Overgenomen]: Kleur.Oranje,
  [EContractStatus.Beeindigd]: Kleur.Rood,
};

const uitlegMap: Record<EContractStatus, string> = {
  [EContractStatus.Concept]: 'Concept',
  [EContractStatus.Afgekeurd]: 'Afgekeurd',
  [EContractStatus.Lopend]: 'Lopend',
  [EContractStatus.Geannuleerd]: 'Geannuleerd',
  [EContractStatus.Overgenomen]: 'Overgenomen',
  [EContractStatus.Beeindigd]: 'Beeïndigd',
};

export const ContractStatusIndicatie: React.FC<IPropsIndicatie> = (props) => {
  return (
    <UitlegTooltip inhoud={uitlegMap[props.status]}>
      <div
        className="d-flex align-items-center"
        style={{
          position: 'relative',
          backgroundColor: Kleur.HeelLichtGrijs,
          // border: `1px solid ${Kleur.LichtGrijs}`,
          // width: 85,
        }}
      >
        <div
          style={{
            width: 10,
            height: 10,
            backgroundColor: contractstatusKleurMap[props.status],
          }}
        />
      </div>
    </UitlegTooltip>
  );
};

const ContractStatus: React.FC<IProps> = (props) => {
  return (
    <div className="d-flex align-items-center">
      <div
        style={{
          width: 14,
          height: 14,
          borderRadius: '50%',
          backgroundColor: contractstatusKleurMap[props.status],
        }}
      />

      <span className="ml-2">{props.naam}</span>
    </div>
  );
};

export default ContractStatus;
