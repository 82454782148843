import React, { useEffect, useMemo, useState } from 'react';
import Opdrachten from './Opdrachten';
import Batches from './Batches';
import Stornering from './Stornering';
import Tabblad, { ITabblad } from '../../../components/layout/Tabblad';
import { RouteComponentProps, withRouter } from 'react-router';
import { IOphalenBatchesResultElement } from '../../../../../shared/src/api/v2/bank/opdracht/opdracht';
import useSegmentTabblad from '../../../hooks/useSegmentTabblad';
import Specificaties from './Specificaties';
import Stornoredenen from './Stornoredenen';

export enum ETabblad {
  Batches,
  Opdrachten,
  Storneringen,
  Specificaties,
  Stornoredenen,
}

interface IProps extends RouteComponentProps {}

// Onderstaand is een proef
export enum EBatchstatus {
  Verzamelen,
  Uitgevoerd,
  Verstuurd,
  InlezenBank,
  BestandMaken,
}

export const bepaalStatus = (el: IOphalenBatchesResultElement | null): EBatchstatus => {
  return el === null
    ? EBatchstatus.Verzamelen
    : el.IsUitgevoerd
    ? EBatchstatus.Uitgevoerd
    : el.DatumVerstuurd !== null
    ? EBatchstatus.Verstuurd
    : el.BestandID !== null
    ? EBatchstatus.InlezenBank
    : EBatchstatus.BestandMaken;
};

export const statusNaam: { [status: number]: string } = {
  [EBatchstatus.Verzamelen]: 'Verzamelen',
  [EBatchstatus.Uitgevoerd]: 'Uitgevoerd',
  [EBatchstatus.Verstuurd]: 'Batch verstuurd',
  [EBatchstatus.InlezenBank]: 'Bij bank inlezen en versturen',
  [EBatchstatus.BestandMaken]: 'Bestand maken',
};

const Bankopdrachten: React.FC<IProps> = (props) => {
  const { tabblad, setSegmentTabblad } = useSegmentTabblad<ETabblad>(
    useMemo(
      () => ({
        props,
        defaultTabblad: ETabblad.Opdrachten,
        pathMap: {
          [ETabblad.Batches]: 'batches',
          [ETabblad.Opdrachten]: 'opdrachten',
          [ETabblad.Storneringen]: 'stornering',
          [ETabblad.Specificaties]: 'specificaties',
          [ETabblad.Stornoredenen]: 'stornocodes',
        },
      }),
      [props],
    ),
  );

  const tabbladen = useMemo<ITabblad<ETabblad>[]>(
    () => [
      {
        id: ETabblad.Opdrachten,
        label: 'Opdrachten',
        content: Opdrachten,
      },
      {
        id: ETabblad.Batches,
        label: 'Batches',
        content: Batches,
      },
      {
        id: ETabblad.Storneringen,
        label: 'Storneringen (incasso)',
        content: Stornering,
      },
      {
        id: ETabblad.Specificaties,
        label: 'Specificaties',
        content: Specificaties,
      },
      {
        id: ETabblad.Stornoredenen,
        label: 'Stornoredenen',
        content: Stornoredenen,
      },
    ],
    [],
  );

  return (
    <>
      {tabblad !== null && (
        <Tabblad<ETabblad>
          geselecteerd={tabblad}
          onSelectieChange={(tab) => setSegmentTabblad(tab)}
          tabbladen={tabbladen}
        />
      )}
    </>
  );
};

export default withRouter(Bankopdrachten);
