import IRemoteData from '../../../models/IRemoteData';
import { IEmail } from '../../../../../shared/src/api/v2/email-v2';

export interface IEmailsData {
  emails: Record<number, IRemoteData<IEmail>>;
  totaalAantal: number;
}

export enum EIdentiteitType {
  Relatie,
  Persoon,
  Organisatie,
  EmailGeadresseerde,
}

export interface IRelatieIdentiteit {
  type: EIdentiteitType.Relatie;
  // relatie: IOphalenRelatiesResultElementV2;
  relID: number;
}

export interface IPersoonIdentiteit {
  type: EIdentiteitType.Persoon;
  // persoon: IOphalenPersonenResultElementV2;
  persID: number;
}

export interface IOrganisatieIdentiteit {
  type: EIdentiteitType.Organisatie;
  // organisatie: IOphalenOrganisatiesResultElement;
  orgID: number;
}

export interface IEmailGeaddresseerdeIdentiteit {
  type: EIdentiteitType.EmailGeadresseerde;
  emailAdres: string;
}

export type Identiteit =
  | IRelatieIdentiteit
  | IPersoonIdentiteit
  | IOrganisatieIdentiteit
  | IEmailGeaddresseerdeIdentiteit;

export enum EMap {
  Inbox = 'INBOX',
  Concepten = 'DRAFTS',
  Verzonden = 'SENT',
}
