import { useContext, useMemo } from 'react';
import { RootStoreContext } from '../../../stores/RootStore';
import { ERemoteDataState } from '../../../models/IRemoteData';
import { ETelefooneventStatus } from '../../../bedrijfslogica/enums';
import { IOproep } from '../../../../../shared/src/api/v2/telefonie';

export interface IUseActieveOproepParams {
  // Wanneer niet opgegeven, gebruik id van ingelogde gebruiker
  aspGebrID?: number;
}

export interface IUseActieveOproepOutput {
  actieveOproep: IOproep;
}

const useActieveOproep = (params?: IUseActieveOproepParams): IUseActieveOproepOutput | null => {
  // return {
  //   actieveOproep: ({
  //     Nummer: 'asasd',
  //     PersID: 1,
  //   } as IOproep) as any,
  // };
  const { telefoonStore, gebruikerStore } = useContext(RootStoreContext);
  params = params === undefined ? {} : params;
  const aspGebrID = useMemo(
    () =>
      params!.aspGebrID !== undefined
        ? params!.aspGebrID
        : gebruikerStore.gebruiker !== null
        ? gebruikerStore.gebruiker.AspGebrID
        : null,
    [params, gebruikerStore.gebruiker && gebruikerStore.gebruiker.AspGebrID],
  );

  const actieveOproep = useMemo(() => {
    if (aspGebrID === null || telefoonStore.actieveOproepen.state === ERemoteDataState.Pending) {
      return null;
    }
    return (
      telefoonStore.actieveOproepen.data!.find((x) => {
        if (x.events.length === 0) {
          return false;
        }
        const laatsteEvent = x.events[x.events.length - 1];
        return x.AspGebrID === aspGebrID && laatsteEvent.Status === ETelefooneventStatus.Beantwoord;
      }) || null
    );
  }, [aspGebrID, telefoonStore.actieveOproepen]);

  if (actieveOproep === null) {
    return null;
  }

  return {
    actieveOproep,
  };
};

export default useActieveOproep;
