import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import IDialoogProps from '../../../../core/IDialoogProps';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import nameOf from '../../../../core/nameOf';
import * as Yup from 'yup';
import api from '../../../../api';
import Dialoog from '../../../../components/dialogen/Dialoog';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import { IOphalenMutatiesResultElement } from '../../../../../../shared/src/api/v2/bank/mutaties';
import VinkVeld from '../../../../components/formulier/VinkVeld';

interface IDialoogResult {}

interface IFormikValues {
  notities: string | null;
  geboekt: boolean;
}

const veldnamen = {
  notities: 'Notities',
  geboekt: 'Geboekt',
};

interface IProps extends IDialoogProps<IDialoogResult> {
  id: number;
}

const WijzigenBankmutatieDialoog: React.FC<IProps> = (props) => {
  const [bankmutatie, setBankmutatie] = useState<IOphalenMutatiesResultElement | null>(null);

  const ophalenBankmutatie = useCallback(async () => {
    const mutatie = (
      await api.v2.bank.mutatie.ophalenMutaties({
        filterSchema: { filters: [{ naam: 'IDS', data: [props.id] }] },
      })
    ).mutaties[0];

    setBankmutatie(mutatie);
  }, [props.id]);

  useEffect(() => {
    ophalenBankmutatie();
  }, [ophalenBankmutatie]);

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (bankmutatie === null) {
      return null;
    }
    return {
      notities: bankmutatie.Notities,
      geboekt: bankmutatie.Geboekt,
    };
  }, [bankmutatie]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      await api.v2.bank.mutatie.wijzigenBankmutatie({
        bankMutID: props.id,
        notities: values.notities,
        geboekt: values.geboekt,
      });

      await props.onSuccess({});
      actions.setSubmitting(false);
    },
    [props.onSuccess],
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        geboekt: Yup.boolean(),
        notities: Yup.string().nullable(),
      }),
    [],
  );

  const notitiesRef = useRef<HTMLTextAreaElement>(null);
  useEffect(() => {
    notitiesRef.current?.focus();
  }, []);

  if (initialValues === null) {
    return <LoadingSpinner />;
  }

  return (
    <Dialoog index={props.dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Bankmutatie wijzigen</ModalTitle>
      </ModalHeader>
      <Formik<IFormikValues>
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
        render={(formikProps: FormikProps<IFormikValues>) => {
          const { isValid, isSubmitting, submitForm } = formikProps;

          return (
            <>
              <ModalBody>
                <div className="row">
                  <div className="col-12 mt-3">
                    <label>Notities</label>
                    <Field
                      name={nameOf<IFormikValues>('notities')}
                      render={(fieldProps: FieldProps<IFormikValues>) => {
                        const { field, form } = fieldProps;

                        return (
                          <textarea
                            {...field}
                            className="form-control"
                            rows={3}
                            ref={notitiesRef}
                          />
                        );
                      }}
                    />
                  </div>

                  <div className="col-12 d-flex align-items-center mt-3">
                    <Field
                      name={nameOf<IFormikValues>('geboekt')}
                      render={(fieldProps: FieldProps<IFormikValues>) => {
                        const { field, form } = fieldProps;
                        return (
                          <VinkVeld
                            aangevinkt={field.value}
                            onGewijzigd={(x) => {
                              form.setFieldValue(field.name, x);
                            }}
                          />
                        );
                      }}
                    />
                    <span className="ml-2">{veldnamen.geboekt}</span>
                  </div>
                </div>
              </ModalBody>
              <ModalFooter className="d-flex flex-row justify-content-start">
                <button
                  className="btn btn-primary d-flex align-items-center justify-content-center"
                  onClick={submitForm}
                  style={{ width: 100 }}
                  disabled={isSubmitting || !isValid}
                >
                  Ok
                </button>
                <button
                  className="btn btn-secondary"
                  onClick={props.onAnnuleren}
                  style={{ width: 100 }}
                  disabled={isSubmitting}
                >
                  Annuleren
                </button>
              </ModalFooter>
            </>
          );
        }}
      />
    </Dialoog>
  );
};

export default WijzigenBankmutatieDialoog;
