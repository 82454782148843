import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { IUitgeklapteRijProps } from '../../../../../../components/tabel/ASPTabel/Body/UitgeklapteRij';
import {
  IOphalenToegevoegdePersonenResult,
  IOphalenToegevoegdePersonenVervangenLijstResultElement,
} from '../../../../../../../../shared/src/api/v2/persoon/duplicaat';
import TabelInspringBlok from '../../../../../../components/layout/TabelInspringBlok';
import ASPTabel from '../../../../../../components/tabel/ASPTabel';
import {
  IOphalenPersonenResult,
  IOphalenPersonenResultElementV2,
} from '../../../../../../../../shared/src/api/v2/persoon/persoon';
import { ASPKolom, EAspKolomBreedteType } from '../../../../../../components/tabel/ASPTabel/types';
import PersoonVisualisatie from '../../../../../../components/personalia/PersoonVisualisatie';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../../../models/IRemoteData';
import api from '../../../../../../api';
import { format } from 'date-fns';
import InfoKnop from '../../../../../../components/InfoKnop';
import PersooninfoDialoog from '../../../../../../components/personalia/PersooninfoDialoog';
import { GlobaleRendererContext } from '../../../../../../one-off-components/GlobaleRenderer';
import { RootStoreContext } from '../../../../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import { EResultType } from '../../../../../../stores/CheckStore';
import toast from 'react-hot-toast';
import { VervangenContext } from '../index';
import { Kleur } from '../../../../../../bedrijfslogica/constanten';
import Skeleton from 'react-loading-skeleton';

enum EKolom {
  Herstellen,
  VervangenPersoon,
  Email,
  Telefoon,
  Geboortedatum,
  Geboorteplaats,
  Overleden,
  RecordToegevoegd,
  Notities,
}

const UitgeklapteRij = observer(
  (
    props: IUitgeklapteRijProps<
      number,
      Partial<IOphalenToegevoegdePersonenVervangenLijstResultElement>
    >,
  ) => {
    const vervangenContext = useContext(VervangenContext);
    const { checkStore } = useContext(RootStoreContext);
    const globaleRenderer = useContext(GlobaleRendererContext);
    const [isBezig, setIsBezig] = useState(false);
    const [personenResult, setPersonenResult] = useState<IRemoteData<IOphalenPersonenResult>>(
      createPendingRemoteData(),
    );
    const ophalenPersonen = useCallback(async () => {
      const result = await api.v2.persoon.ophalenPersonen({
        filterSchema: {
          filters: [
            {
              naam: 'VERVANGENDE_PERS_IDS',
              data: [props.regel.ToegevoegdAan_PersID!],
            },
          ],
        },
      });
      setPersonenResult(createReadyRemoteData(result));
    }, [props.regel.ToegevoegdAan_PersID!]);
    useEffect(() => {
      ophalenPersonen();
    }, [ophalenPersonen]);
    const [toegevoegdePersonenResult, setToegevoegdePersonenResult] = useState<
      IRemoteData<IOphalenToegevoegdePersonenResult>
    >(createPendingRemoteData());
    const ophalenToegevoegdePersonen = useMemo<IRemoteData<() => Promise<void>>>(
      () =>
        personenResult.state === ERemoteDataState.Pending
          ? createPendingRemoteData()
          : createReadyRemoteData(async () => {
              const result = await api.v2.persoon.duplicaat.ophalenToegevoegdePersonen({
                selectieSchema: {
                  velden: ['ID', 'PERS_ID', 'TOEGEVOEGD_AAN_PERS_ID'],
                },
                filterSchema: {
                  filters: [
                    // {
                    //   naam: 'TOEGEVOEGD_AAN_PERS_IDS',
                    //   data: [props.regel.ToegevoegdAan_PersID!],
                    // },
                    {
                      naam: 'PERS_IDS',
                      data: personenResult.data!.personen.map((p) => p.PersID),
                    },
                  ],
                },
              });
              setToegevoegdePersonenResult(createReadyRemoteData(result));
            }),
      [personenResult],
    );
    useEffect(() => {
      if (ophalenToegevoegdePersonen.state === ERemoteDataState.Pending) {
        return;
      }
      ophalenToegevoegdePersonen.data!();
    }, [ophalenToegevoegdePersonen]);

    const handleHerstellen = useCallback(
      async (persoon: IOphalenPersonenResultElementV2) => {
        setIsBezig(true);
        if (
          (
            await checkStore.bevestigen({
              inhoud: 'Bevestig herstellen vervangen persoon',
            })
          ).type === EResultType.Annuleren
        ) {
          setIsBezig(false);
          return;
        }

        if (personenResult.data!.personen.length === 1) {
          if (
            (
              await checkStore.bevestigen({
                inhoud:
                  'Let op: Als je deze persoon herstelt, wordt de vervanging verwijderd. Aangezien er dan geen vervanging meer geldt voor de vigerende persoon.',
              })
            ).type === EResultType.Annuleren
          ) {
            setIsBezig(false);
            return;
          }
        }

        toast('Controleren herstelbaarheid vervangen persoon...');

        const toegevoegdPersoon = toegevoegdePersonenResult.data!.toegevoegdePersonen.find(
          (x) => x.PersID === persoon.PersID,
        )!;

        const checkData = await api.v2.persoon.duplicaat.checkHerstellenToegevoegdePersoon({
          id: toegevoegdPersoon.ID!,
        });
        const result = await checkStore.controleren({
          checkData,
          titel: (
            <div className="d-flex align-items-center">
              <span className="mr-2">Controleren herstelbaarheid van</span>
              <PersoonVisualisatie persID={toegevoegdPersoon.PersID!} />
              <InfoKnop
                onClick={async () => {
                  await globaleRenderer.render((renderProps) => (
                    <PersooninfoDialoog
                      persID={toegevoegdPersoon.PersID!}
                      open
                      onSuccess={() => renderProps.destroy()}
                      onAnnuleren={() => renderProps.destroy()}
                    />
                  ));
                }}
                style={{ marginLeft: 8, position: 'relative', top: -2 }}
              />
            </div>
          ),
        });

        if (result.type === EResultType.Annuleren) {
          setIsBezig(false);
          return;
        }

        toast('Herstellen vervangen personen...');
        await api.v2.persoon.duplicaat.herstellenToegevoegdePersoon({
          id: toegevoegdPersoon.ID!,
        });

        if (personenResult.data!.personen.length === 1) {
          await vervangenContext.onVigerendePersoonVervallen(props.regel.ToegevoegdAan_PersID!);
        } else {
          await ophalenPersonen();
        }

        toast.success('Vervangen personen hersteld');

        setIsBezig(false);
      },
      [
        personenResult,
        toegevoegdePersonenResult,
        ophalenPersonen,
        vervangenContext.onVigerendePersoonVervallen,
        props.regel.ToegevoegdAan_PersID,
      ],
    );

    const keyExtractor = useCallback((rij: IOphalenPersonenResultElementV2) => rij.PersID, []);
    const kolommen = useMemo<ASPKolom<EKolom, IOphalenPersonenResultElementV2>[]>(
      () => [
        {
          key: EKolom.Herstellen,
          breedteType: EAspKolomBreedteType.Vast,
          vasteBreedte: 100,
          renderer: (rij) => {
            if (toegevoegdePersonenResult.state === ERemoteDataState.Pending) {
              return <Skeleton />;
            }
            const toegevoegdPersoon = toegevoegdePersonenResult.data!.toegevoegdePersonen.find(
              (x) => x.PersID === rij.PersID,
            )!;
            // const toegevoegdPersoonIsToegevoegdAanAnderInLijst = toegevoegdePersonenResult.data!.toegevoegdePersonen.some(
            //   (x) => x.ToegevoegdAan_PersID === rij.PersID,
            // );
            const wordtVervangenDoorAnderInLijst = personenResult.data!.personen.some(
              (x) => x.PersID === toegevoegdPersoon.ToegevoegdAan_PersID,
            );

            return (
              <button
                className="btn btn-sm btn-light"
                style={{ border: `1px solid ${Kleur.LichtGrijs}`, padding: '2px 0' }}
                onClick={() => handleHerstellen(rij)}
                disabled={isBezig || wordtVervangenDoorAnderInLijst}
                title={
                  wordtVervangenDoorAnderInLijst
                    ? 'De bovenliggende persoon van deze toegevoegde persoon is zelf ook als vervangende persoon ingesteld, en moet dus eerst zelf hersteld worden'
                    : undefined
                }
              >
                Herstellen
              </button>
            );
          },
        },
        {
          key: EKolom.VervangenPersoon,
          label: 'Vervangen persoon',
          breedteType: EAspKolomBreedteType.Vast,
          vasteBreedte: 350,
          renderer: (rij) => (
            <div className="d-flex align-items-center">
              <PersoonVisualisatie persID={rij.PersID!} />
              <InfoKnop
                onClick={async () => {
                  await globaleRenderer.render((renderProps) => (
                    <PersooninfoDialoog
                      persID={rij.PersID!}
                      open
                      onSuccess={() => renderProps.destroy()}
                      onAnnuleren={() => renderProps.destroy()}
                    />
                  ));
                }}
                style={{ marginLeft: 8, position: 'relative', top: -2 }}
              />
            </div>
          ),
        },
        {
          key: EKolom.Email,
          label: 'Email',
          breedteType: EAspKolomBreedteType.Vast,
          vasteBreedte: 250,
          renderer: (rij) => rij.Email,
        },
        {
          key: EKolom.Telefoon,
          label: 'Telefoon',
          breedteType: EAspKolomBreedteType.Vast,
          vasteBreedte: 150,
          renderer: (rij) => rij.TelefoonMobiel,
        },
        {
          key: EKolom.Geboortedatum,
          label: 'Geboortedatum',
          breedteType: EAspKolomBreedteType.Vast,
          vasteBreedte: 150,
          renderer: (rij) =>
            rij.Geboortedatum === null ? null : format(new Date(rij.Geboortedatum), 'dd-MM-yyyy'),
        },
        {
          key: EKolom.Geboorteplaats,
          label: 'Geboorteplaats',
          breedteType: EAspKolomBreedteType.Vast,
          vasteBreedte: 200,
          renderer: (rij) => rij.Geboorteplaats,
        },
        {
          key: EKolom.Overleden,
          label: 'Overleden',
          breedteType: EAspKolomBreedteType.Vast,
          vasteBreedte: 150,
          renderer: (rij) => (rij.Overleden ? 'Ja' : null),
        },
        {
          key: EKolom.RecordToegevoegd,
          label: 'Record toegevoegd',
          breedteType: EAspKolomBreedteType.Vast,
          vasteBreedte: 150,
          renderer: (rij) => format(new Date(rij.RecordToegevoegd!), 'dd-MM-yyyy HH:mm'),
        },
        {
          key: EKolom.Notities,
          label: 'Notities',
          breedteType: EAspKolomBreedteType.Flex,
          flex: 1,
          renderer: (rij) => rij.Notities,
        },
      ],
      [handleHerstellen, toegevoegdePersonenResult, isBezig, personenResult],
    );

    return (
      <div className="d-flex flex-fill">
        <TabelInspringBlok />
        <ASPTabel
          keyExtractor={keyExtractor}
          rijen={
            personenResult.state === ERemoteDataState.Pending ? [] : personenResult.data!.personen
          }
          kolommen={kolommen}
          totaalAantalRijen={
            personenResult.state === ERemoteDataState.Pending
              ? 3
              : personenResult.data!.personen.length
          }
        />
      </div>
    );
  },
);

export default UitgeklapteRij;
