import React, { useContext, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../stores/RootStore';
import { MenuBalk, notificationTopOffset, Root, Wrapper } from './style';
import classNames from 'classnames';
import LoadingSpinner from '../../components/Gedeeld/LoadingSpinner';
import NotificatieItem from './NotificatieItem';
import VinkVeld from '../../components/formulier/VinkVeld';
import { IOphalenNotificatiesResultElement } from '../../../../shared/src/api/v2/notificaties/notificaties';
import SignaleringWrapper from './SignaleringWrapper';
import HorizontaleScheidingslijn from '../../components/layout/HorizontaleScheidingslijn';

interface IProps {}

const NotificatieOverlay: React.FC<IProps> = observer((props) => {
  const { notificatieStore, gebruikerStore } = useContext(RootStoreContext);
  const [bevestigdeTonen, setBevestigdeTonen] = useState(false);

  const containerClassnames = useMemo(
    () =>
      classNames(
        notificatieStore.overlayState.zichtbaar
          ? ['container', 'container-zichtbaar']
          : ['container'],
      ),
    [notificatieStore.overlayState],
  );

  const notificatiePredicate = useMemo<
    ((x: IOphalenNotificatiesResultElement) => boolean) | null
  >(() => {
    if (gebruikerStore.gebruiker === null) {
      return null;
    }

    if (bevestigdeTonen) {
      return () => true;
    }

    const aspGebrID = gebruikerStore.gebruiker!.AspGebrID;
    return (x) => {
      const entry = x.aspGebrs.find(
        (entry) => entry.AspGebrID === aspGebrID && entry.DatumBevestigd === null,
      );
      return entry !== undefined;
    };
  }, [bevestigdeTonen, gebruikerStore.gebruiker]);

  if (gebruikerStore.gebruiker === null) {
    return null;
  }

  return (
    <Wrapper>
      {notificatieStore.signaleringNotificaties.map((notif, i) => {
        return (
          <SignaleringWrapper
            key={notif.ID}
            notificatie={notif}
            props={{
              style: {
                top: notificationTopOffset + i * 125,
              },
            }}
          />
        );
      })}
      <Root onClick={(ev) => ev.stopPropagation()}>
        <div className={containerClassnames}>
          <MenuBalk>
            <div className="form-group d-flex justify-content-end">
              <label className="mr-2" style={{ marginTop: 1 }}>
                Alles weergeven
              </label>
              <VinkVeld
                aangevinkt={bevestigdeTonen}
                onGewijzigd={(value) => setBevestigdeTonen(value)}
              />
            </div>
          </MenuBalk>
          <div style={{ marginTop: 35 }} />
          <div className="scroll d-flex flex-column">
            {/*<h5 className="mb-3">Vandaag</h5>*/}
            {notificatieStore.mijnNotificaties === null ? (
              <LoadingSpinner />
            ) : notificatieStore.mijnNotificaties.filter(notificatiePredicate!).length === 0 ? (
              <div className="flex-fill d-flex flex-column align-items-center justify-content-center">
                <span style={{ fontSize: '1.2rem' }}>
                  {bevestigdeTonen
                    ? 'Je hebt geen notificaties.'
                    : 'Je hebt nog geen nieuwe notificaties.'}
                </span>
              </div>
            ) : (
              notificatieStore.mijnNotificaties.filter(notificatiePredicate!).map((notificatie) => {
                return (
                  <div key={notificatie.ID}>
                    <NotificatieItem notificatie={notificatie} />
                    <HorizontaleScheidingslijn />
                  </div>
                );
              })
            )}
          </div>
        </div>
      </Root>
    </Wrapper>
  );
});

export default NotificatieOverlay;
