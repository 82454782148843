import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { ISponsorVerzoek } from '../../../../../shared/src/api/v2/sponsoringv2/verzoek';
import api from '../../../api';
import { Kleur } from '../../../bedrijfslogica/constanten';
import FilterBalkV2, {
  IFilter,
  IFilterData,
  maakFilterSchema,
} from '../../../components/FilterBalkV2';
import {
  IconBevestiging,
  IconDuimOmhoog,
  IconDuimOmlaag,
  IconSend,
  IconToevoegen,
  IconVerwijderen,
} from '../../../components/Icons';
import MenuLayout from '../../../components/MenuLayout';
import ASPTabel from '../../../components/tabel/ASPTabel';
import TableData, { ITableDataProps } from '../../../components/tabel/ASPTabel/Body/TableData';
import { ASPKolom, EAspKolomBreedteType } from '../../../components/tabel/ASPTabel/types';
import useUrlState from '../../../core/useUrlState';
import { EResultType } from '../../../stores/CheckStore';
import { RootStoreContext } from '../../../stores/RootStore';
import MuterenDialoog from './MuterenDialoog';
import { IFilterSchema } from '../../../../../shared/src/models/filter';
import AfwijzenDialoog from './AfwijzenDialoog';

export enum ESponsorVerzoekStatus {
  Nieuw = 1,
  Onderhanden = 2,
  Gepromoveerd = 3,
  Afgewezen = 4,
}

type Kolom =
  | 'Aanvrager'
  | 'EmailAanvrager'
  | 'Onderwerp'
  | 'Omschrijving'
  | 'Notities'
  | 'Status'
  | 'Afgehandeld';

export enum EFilter {
  Afgehandeld = 'AFGEHANDELD',
}

interface IProps extends RouteComponentProps {
  dienstID?: number;
}

interface IToevoegenDialoogState {}
interface IWijzigenDialoogState {
  id: number;
}

interface IAfwijzenDialoogState {
  ids: number[];
}

interface IUrlState {
  selectie: number[];
  filterData: IFilterData<EFilter>[];
  toevoegenDialoogState: IToevoegenDialoogState | null;
  muterenDialoogState: IWijzigenDialoogState | null;
  afwijzenDialoogState: IAfwijzenDialoogState | null;
}

const defaultUrlState: IUrlState = {
  selectie: [],
  filterData: [
    {
      naam: EFilter.Afgehandeld,
      data: true,
      isActief: false,
    },
  ],
  toevoegenDialoogState: null,
  muterenDialoogState: null,
  afwijzenDialoogState: null,
};

const Item: React.FC<IProps> = (props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const { checkStore } = useContext(RootStoreContext);

  const [filterSchema, setFilterSchema] = useState<IFilterSchema>(
    useMemo(() => maakFilterSchema(urlState.filterData), []),
  );

  const [rijen, setRijen] = useState<ISponsorVerzoek[] | null>(null);

  const ophalenRijen = async () => {
    const filterSchema = !props.dienstID
      ? undefined
      : { filters: [{ naam: 'DIENST_IDS', data: [props.dienstID] }] };
    let results = await api.v2.sponsoringv2.verzoek.ophalenVerzoeken({ filterSchema });

    if (!urlState.filterData.find((d) => d.naam == EFilter.Afgehandeld)!.isActief)
      results = results.filter((v) => !v.Afgehandeld);

    setRijen(results);
  };

  useEffect(() => {
    ophalenRijen();
  }, [filterSchema]);

  const handleToevoegen = useCallback(async () => {
    setUrlStateSync('muterenDialoogState', { id: null });
  }, []);

  const handleVerwijderen = useCallback(async () => {
    const params = { ids: urlState.selectie };
    if (
      (
        await checkStore.bevestigen({
          inhoud: <span>Geselecteerde verzoeken verwijderen?</span>,
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    await api.v2.sponsoringv2.verzoek.verwijderenVerzoeken(params);
    setUrlStateSync('selectie', []);

    await ophalenRijen();
  }, [urlState.selectie]);

  const handlePromoveren = useCallback(async () => {
    const params = { ids: urlState.selectie };
    if (
      (
        await checkStore.bevestigen({
          inhoud: (
            <span>
              Geselecteerde verzoeken promoveren? <br /> Dit zal het verzoek omzetten in een event.
            </span>
          ),
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    await api.v2.sponsoringv2.verzoek.promoverenVerzoeken(params);
    setUrlStateSync('selectie', []);

    await ophalenRijen();
  }, [urlState.selectie]);

  const handleAfwijzen = useCallback(async () => {
    const params = { ids: urlState.selectie };
    setUrlStateSync('afwijzenDialoogState', params);
  }, [urlState.selectie]);

  const handleAfwijsBerichtenVersturen = useCallback(async () => {
    const params = { ids: urlState.selectie };
    if (
      (
        await checkStore.bevestigen({
          inhoud: (
            <span>
              Afwijsbericht sturen voor {params.ids.length} verzoek
              {params.ids.length > 1 ? 'en' : ''}?
              <br />
              <br />
              Dit stuurt alleen een emailbericht. De verzoeken worden niet afgehandeld en de status
              wordt niet gewijzigd.
            </span>
          ),
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    await api.v2.sponsoringv2.verzoek.afwijsBerichtenVersturen(params);
    setUrlStateSync('selectie', []);

    await ophalenRijen();
  }, [urlState.selectie]);

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.Afgehandeld,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return (
            <div className="d-flex align-items-center">
              <span>Afgehandelde verzoeken weergeven</span>
            </div>
          );
        },
      },
    ],
    [],
  );

  const keyExtractor = useCallback((row: ISponsorVerzoek) => (row.ID == null ? 0 : row.ID), [
    rijen,
  ]);

  const kolommen = useMemo<ASPKolom<Kolom, ISponsorVerzoek>[]>(
    () => [
      {
        key: 'Aanvrager',
        label: 'Aanvrager',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 300,
        renderer: (x) => x.NaamAanvrager,
      },
      {
        key: 'EmailAanvrager',
        label: 'Email aanvrager',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 300,
        renderer: (x) => x.EmailAanvrager,
      },
      {
        key: 'Onderwerp',
        label: 'Onderwerp',
        breedteType: EAspKolomBreedteType.Flex,
        flex: 1,
        renderer: (x) => x.Onderwerp,
      },
      {
        key: 'Omschrijving',
        label: 'Omschrijving',
        breedteType: EAspKolomBreedteType.Flex,
        flex: 1,
        renderer: (x) => x.Omschrijving,
      },
      {
        key: 'Notities',
        label: 'Notities',
        breedteType: EAspKolomBreedteType.Flex,
        flex: 1,
        renderer: (x) => x.Notities,
      },
      {
        key: 'Status',
        label: 'Status',
        breedteType: EAspKolomBreedteType.Flex,
        flex: 1,
        renderer: (x) =>
          x.VerzoekstatusID === null ? '- -' : ESponsorVerzoekStatus[x.VerzoekstatusID],
      },
      {
        key: 'Afgehandeld',
        label: 'Afgehandeld',
        breedteType: EAspKolomBreedteType.Flex,
        flex: 1,
        renderer: (x) => (x.Afgehandeld ? 'ja' : 'nee'),
      },
    ],
    [],
  );

  return (
    <>
      <Helmet>
        <title>Sponsorverzoeken</title>
      </Helmet>
      <MenuLayout
        menu={
          <div className="d-flex align-items-center">
            <button
              className="btn btn-sm btn-light d-flex align-items-center"
              style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
              disabled={false}
              onClick={() => handleToevoegen()}
            >
              <IconToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
              <span className="ml-2">Toevoegen</span>
            </button>

            <button
              className="btn btn-sm btn-light d-flex align-items-center ml-3"
              style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
              disabled={urlState.selectie.length === 0}
              onClick={() => handlePromoveren()}
            >
              <IconDuimOmhoog style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
              <span className="ml-2">Promoveren</span>
            </button>

            <button
              className="btn btn-sm btn-light d-flex align-items-center ml-3"
              style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
              disabled={urlState.selectie.length === 0}
              onClick={() => handleAfwijzen()}
            >
              <IconDuimOmlaag style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
              <span className="ml-2">Afwijzen</span>
            </button>

            <button
              className="btn btn-sm btn-light d-flex align-items-center ml-3"
              style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
              disabled={urlState.selectie.length === 0}
              onClick={() => handleAfwijsBerichtenVersturen()}
            >
              <IconSend style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
              <span className="ml-2">Afwijsbericht versturen</span>
            </button>

            <button
              className="btn btn-sm btn-light d-flex align-items-center ml-3"
              style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
              disabled={urlState.selectie.length === 0}
              onClick={() => handleVerwijderen()}
            >
              <IconVerwijderen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
              <span className="ml-2">Verwijderen</span>
            </button>

            <div className="d-flex flex-fill ml-3">
              <FilterBalkV2
                filters={filters}
                filterData={urlState.filterData}
                onFilterDataChange={(x) => setUrlStateSync('filterData', x)}
                onFilterSchemaChange={(x) => setFilterSchema(x)}
              />
            </div>
          </div>
        }
        body={
          rijen == null ? (
            <span></span>
          ) : (
            <ASPTabel
              rijen={rijen}
              kolommen={kolommen}
              keyExtractor={keyExtractor}
              selectie={urlState.selectie}
              onSelectieChange={(selectie) => setUrlStateSync('selectie', selectie)}
              onWijzigenRij={async (rij) => {
                setUrlStateSync('muterenDialoogState', { id: rij.ID });
              }}
              tdComponent={TdComponent}
            />
          )
        }
      />
      {urlState.muterenDialoogState !== null && (
        <MuterenDialoog
          open
          id={urlState.muterenDialoogState.id}
          dienstID={props.dienstID}
          onSuccess={async () => {
            setUrlStateSync('muterenDialoogState', null);
            await ophalenRijen();
          }}
          onAnnuleren={() => setUrlStateSync('muterenDialoogState', null)}
        />
      )}
      {urlState.afwijzenDialoogState !== null && (
        <AfwijzenDialoog
          open
          ids={urlState.afwijzenDialoogState.ids}
          onSuccess={async () => {
            setUrlStateSync('afwijzenDialoogState', null);
            setUrlStateSync('selectie', []);
            await ophalenRijen();
          }}
          onAnnuleren={() => setUrlStateSync('afwijzenDialoogState', null)}
        />
      )}
    </>
  );
};

const TdComponent = (props: ITableDataProps<Kolom, ISponsorVerzoek>) => {
  return (
    <TableData
      {...props}
      style={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
        display: 'block',
        paddingTop: '8px',
      }}
    />
  );
};

export default withRouter(Item);
