import React, {
  MouseEventHandler,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../stores/RootStore';
import { IOphalenContractenResultElementV2 } from '../../../../../shared/src/api/v2/contract';
import {
  ContractStatusIndicatie,
  EContractStatus,
} from '../../../paginas/Klant/Klantkaart/Entiteiten/entiteiten/Contracten/Overzicht/ContractTegel/ContractStatus';
import { RouteComponentProps, withRouter } from 'react-router';
import { genereerUrlStateQueryParam } from '../../../core/useUrlState';
import { IconOpenInNew } from '../../Icons';
import { Kleur } from '../../../bedrijfslogica/constanten';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../models/IRemoteData';
import { OverlayTrigger } from 'react-bootstrap';
import InfoCard from './InfoCard';
import { IContractOverzichtV2UrlState } from '../../../paginas/Klant/Klantkaart/Entiteiten/entiteiten/Contracten/OverzichtV2/store';

enum EWeergave {
  Tekst,
}

interface IOptions {
  weergave?: EWeergave;
}

const defaultOptions: IOptions = {
  weergave: EWeergave.Tekst,
};

interface IProps extends RouteComponentProps {
  cntID: number;
  options?: IOptions;
  onClick?: MouseEventHandler<any>;
}

const ContractVisualisatie: React.FC<IProps> = observer((props: IProps) => {
  const options = useMemo(() => {
    return { ...defaultOptions, ...props.options };
  }, [props.options]);

  const [isHovered, setIsHovered] = useState(false);

  const { contractenStoreV2 } = useContext(RootStoreContext);

  const contract = useMemo<IOphalenContractenResultElementV2 | null>(() => {
    if (contractenStoreV2.contracten[props.cntID] !== undefined) {
      return contractenStoreV2.contracten[props.cntID];
    }

    return null;
  }, [contractenStoreV2.contracten, props.cntID]);

  useEffect(() => {
    if (contractenStoreV2.contracten[props.cntID] !== undefined) {
      return;
    }

    contractenStoreV2.enqueueOphalenContracten([props.cntID]);
  }, [props.cntID]);

  const link = useMemo<IRemoteData<string>>(() => {
    if (contract === null) {
      return createPendingRemoteData();
    }

    const urlState: IContractOverzichtV2UrlState = {
      uitgeklapteLocIDs: [contract.basis.locatie.LocID],
      selectieCntIDs: [contract.CntID],
    };
    const query = genereerUrlStateQueryParam(urlState);
    const link = `/klant/${contract!.RelID}/contracten/overzicht?state=${query}`;
    return createReadyRemoteData(link);
  }, [contract]);

  const handleClick = useCallback<MouseEventHandler<any>>(
    (ev) => {
      if (props.onClick !== undefined) {
        props.onClick(ev);
        if (ev.defaultPrevented) {
          return;
        }
      }
      ev.preventDefault();

      if (link.state === ERemoteDataState.Pending) {
        return;
      }

      props.history.push(link.data!);
    },
    [link],
  );

  const [showInfoCardOverlay, setShowInfoCardOverlay] = useState(false);
  const showInfoCardOverlayTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const handleMouseEnterInfoCardOverlay = useCallback(() => {
    if (showInfoCardOverlayTimeoutRef.current !== null) {
      clearTimeout(showInfoCardOverlayTimeoutRef.current);
    }
    showInfoCardOverlayTimeoutRef.current = setTimeout(() => setShowInfoCardOverlay(true), 800);
  }, []);
  const handleMouseLeaveInfoCardOverlay = useCallback(() => {
    if (showInfoCardOverlayTimeoutRef.current !== null) {
      clearTimeout(showInfoCardOverlayTimeoutRef.current);
    }
    showInfoCardOverlayTimeoutRef.current = setTimeout(() => setShowInfoCardOverlay(false), 400);
  }, []);
  const renderInfoCardOverlay = useCallback(
    (p: any) => {
      return (
        <div
          id={`contract-visualisatie-overlay-${props.cntID}`}
          {...p}
          onMouseEnter={handleMouseEnterInfoCardOverlay}
          onMouseLeave={handleMouseLeaveInfoCardOverlay}
          style={{
            ...p.style,
            zIndex: 99999,
            border: `1px solid ${Kleur.LichtGrijs}`,
            borderRadius: 5,
          }}
        >
          <InfoCard cntID={props.cntID} />
        </div>
      );
    },
    [props.cntID, handleMouseEnterInfoCardOverlay, handleMouseLeaveInfoCardOverlay],
  );

  return (
    <OverlayTrigger
      trigger="hover"
      placement="auto-start"
      overlay={renderInfoCardOverlay}
      show={showInfoCardOverlay}
      popperConfig={
        {
          modifiers: {
            name: 'offset',
            options: {
              offset: [0, 5],
            },
          },
        } as any
      }
    >
      <span
        className="d-flex align-items-center"
        onMouseEnter={() => {
          setIsHovered(true);
          handleMouseEnterInfoCardOverlay();
        }}
        onMouseLeave={() => {
          setIsHovered(false);
          handleMouseLeaveInfoCardOverlay();
        }}
      >
        {contract === null ? (
          <span>Laden...</span>
        ) : (
          <>
            <a href="#" className="d-flex align-items-center" onClick={handleClick}>
              <ContractStatusIndicatie status={contract.status.NaamEnum as EContractStatus} />
              <span className="ml-2">
                {contract.basis.Basisnummer}.{contract.Volgnummer}
              </span>
            </a>
            {isHovered && (
              <IconOpenInNew
                style={{
                  width: 16,
                  height: 16,
                  marginLeft: 5,
                  marginTop: 3,
                  cursor: 'pointer',
                  fill: Kleur.Grijs,
                }}
                onClick={(ev) => {
                  if (link.state === ERemoteDataState.Pending) {
                    return;
                  }

                  window.open(link.data!, '_blank');
                }}
              />
            )}
          </>
        )}
      </span>
    </OverlayTrigger>
  );
});

export default withRouter(ContractVisualisatie);
