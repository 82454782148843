import api from '../../..';
import {
  IOphalenDebiteurenMetVerrekeningenParams,
  IOphalenDebiteurenMetVerrekeningenResult,
} from '../../../../../../shared/src/api/v2/debiteur/verrekening';
import ICheckData from '../../../../../../shared/src/models/ICheckData';

const verrekening = {
  ophalenDebiteurenMetVerrekeningen: async (
    params: IOphalenDebiteurenMetVerrekeningenParams,
  ): Promise<IOphalenDebiteurenMetVerrekeningenResult> => {
    return await api.post('/v2/debiteur/ophalen-debiteuren-met-verrekeningen', params);
  },
};

export default verrekening;
