import styled from 'styled-components';
import style from 'dom-helpers/style';
import React from 'react';

export const Root = styled.div`
  width: 270px;
`;

export const InputWrapper = styled.div`
  //margin: 5px 0;
  //padding: 0 10px;
`;

export const Input = styled.input`
  width: 100%;
  padding: 10px 15px;
  border: 0;
  outline: 0;
  border-bottom: 1px solid #c6c6c6;
`;

export const BeginWithSearchingMessageContainer = styled.div`
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #a8a8a8;
`;

export const PhonenumberInputContainer = styled.div`
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoadingContainer = styled.div`
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SuggestionsContainer = styled.div`
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const SuggestionListItem = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;

  .display-name {
    font-weight: bold;
  }

  .phone-number {
  }
`;
