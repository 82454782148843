import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { CommunicatieOverlayContext } from '../../../../index';
import TelefoonHistorie from '../../../../../../components/communicatie/TelefoonHistorie';
import { RootStoreContext } from '../../../../../../stores/RootStore';

interface IProps {}

const RecentTabblad: React.FC<IProps> = observer((props) => {
  const { telefoonContext } = useContext(CommunicatieOverlayContext);
  const { telefoonHistorieCommunicatieLayoutStore } = useContext(RootStoreContext);

  return (
    <TelefoonHistorie
      telefoonHistorieStore={telefoonHistorieCommunicatieLayoutStore}
      richting={telefoonContext.richting}
      onRichtingChange={telefoonContext.onRichtingChange}
      vanGebruiker={telefoonContext.vanGebruiker}
      laatsteVanGebruikerSelectie={telefoonContext.laatsteVanGebruikerSelectie}
      onVanGebruikerChange={telefoonContext.onVanGebruikerChange}
    />
  );
});

export default RecentTabblad;
