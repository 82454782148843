import React, { useMemo } from 'react';
import FactuurSelectieV2, {
  EFactuurSelectieKolom,
} from '../../../../../formulier/FactuurSelectieV2';
import { Regel } from '../../types';
import { Provider } from '../../../../../formulier/MultiComboboxV2';
import { IOphalenFacturenBasisResultElement } from '../../../../../../../../shared/src/api/v2/factuur';
import { addDays, addMonths } from 'date-fns';
import api from '../../../../../../api';
import { IFilterSchemaUitgebreideFilter } from '../../../../../../../../shared/src/models/filter';
import { IOphalenFacturenDossiersResultElement } from '../../../../../../../../shared/src/api/v2/factuur';

interface IProps {
  factID: number | null;
  onFactIDChange: (factID: number | null) => void;
  regel: Regel;
}

export interface IFacturenMuteren extends IOphalenFacturenBasisResultElement {
  dossiers: IOphalenFacturenDossiersResultElement;
}

const FactuurMuteren = (props: IProps) => {
  const provider = useMemo<Provider<EFactuurSelectieKolom, IFacturenMuteren>>(
    () => async (params) => {
      // Max aantal dagen terug voor facturen die niet openstaan
      const peildatum = addMonths(new Date(), -30);

      const facturenResult = await api.v2.factuur.ophalenFacturenBasis({
        filterSchema: {
          uitgebreideFilter: {
            and: [
              props.regel.relID === null || props.regel.relID === undefined
                ? null
                : {
                    filter: {
                      naam: 'REL_IDS',
                      data: [props.regel.relID],
                    },
                  },
              {
                or: [
                  {
                    filter: {
                      naam: 'OPENSTAAND',
                      data: true,
                    },
                  },
                  {
                    filter: {
                      naam: 'FACTUURDATUM_VANAF',
                      data: peildatum,
                    },
                  },
                ],
              },
            ].filter((x) => x !== null) as IFilterSchemaUitgebreideFilter[],
          },
        },
        orderSchema: {
          orders: [
            {
              naam: 'FACTUURDATUM',
              richting: 'DESC',
            },
            {
              naam: 'FACTUURNUMMER',
              richting: 'DESC',
            },
          ],
        },
        paginatie: params.paginatie,
      });

      const factIDs = facturenResult.facturen.map((x) => x.FactID);

      // Dossiers ophalen
      const dossiersResult = await api.v2.factuur.ophalenFacturenDossiers({
        factIDs,
      });

      const facturen = facturenResult.facturen.map((x) => {
        const dossiers = dossiersResult.facturen.find((d) => d.FactID === x.FactID)!;
        return { ...x, dossiers };
      });

      const nieuweItems = facturen.reduce(
        (acc, curr, i) => ({
          ...acc,
          [params.paginatie.index + i]: curr,
        }),
        params.huidigeBron,
      );

      return {
        items: nieuweItems,
        totaalAantal: facturenResult.totaalAantal,
      };
    },
    [props.regel.relID],
  );

  return (
    <FactuurSelectieV2
      factID={props.factID}
      onFactIDChange={props.onFactIDChange}
      provider={provider}
    />
  );
};

export default FactuurMuteren;
