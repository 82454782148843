import * as React from 'react';
import IDialoogProps from '../../../../../core/IDialoogProps';
import Modal from 'react-bootstrap/Modal';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { useEffect, useMemo, useCallback } from 'react';
import api from '../../../../../api';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalHeader from 'react-bootstrap/ModalHeader';
import AbonnementFormulier, { IFormikValues } from '../AbonnementFormulier';

const NieuwDialoog: React.FC<IDialoogProps> = (props) => {
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const [huidigFormulier, setHuidigFormulier] = React.useState<IFormikValues | null>(null);

  const initieleWaarden = useMemo<IFormikValues | null>(() => {
    return {
      actief: false,
      eenmaligBedrag: 0,
      minimaleTermijn: 0,
      naam: '',
      naamKort: '',
    };
  }, []);

  const handleToevoegenClick = useCallback(async () => {
    setIsSubmitting(true);

    const resultaat = await api.v2.abonnement.toevoegenAbonnement({
      naam: huidigFormulier!.naam,
      naamKort: huidigFormulier!.naamKort,
      minimaleTermijn: huidigFormulier!.minimaleTermijn,
      eenmaligBedrag: huidigFormulier!.eenmaligBedrag,
      actief: huidigFormulier!.actief,
    });

    setIsSubmitting(false);
    props.onSuccess(resultaat);
  }, [huidigFormulier, setIsSubmitting, props.onSuccess]);

  const handleAnnulerenClick = useCallback(() => {
    props.onAnnuleren();
  }, [props.onAnnuleren]);

  return (
    <Modal show={props.open}>
      <ModalHeader>
        <ModalTitle>Nieuw abonnement toevoegen</ModalTitle>
      </ModalHeader>
      <ModalBody style={{ padding: '2rem' }}>
        <AbonnementFormulier
          initieleWaarden={initieleWaarden!}
          onChange={(waarden: IFormikValues | null) => {
            setHuidigFormulier(waarden);
          }}
        />
      </ModalBody>
      <ModalFooter className="d-flex flex-row justify-content-start">
        <button
          className="btn btn-primary"
          onClick={handleToevoegenClick}
          style={{ width: 100 }}
          disabled={isSubmitting || huidigFormulier === null}
        >
          Ok
        </button>
        <button className="btn btn-secondary" onClick={handleAnnulerenClick} style={{ width: 100 }}>
          Annuleren
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default NieuwDialoog;
