import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../stores/RootStore';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import MenuLayout from '../../../components/MenuLayout';
import FilterBalkV2, {
  IFilter,
  IFilterData,
  maakFilterSchema,
} from '../../../components/FilterBalkV2';
import useUrlState from '../../../core/useUrlState';
import MultiCombobox, { IKolom } from '../../../components/formulier/MultiCombobox';
import { IOphalenRekeningenResultElement } from '../../../../../shared/src/api/v2/bank/bank';
import LoadingSpinner from '../../../components/Gedeeld/LoadingSpinner';
import FormatteerBedrag from '../../../components/MutatieBedrag';
import api from '../../../api';
import {
  IBepalenSaldoMutatiesResult,
  IOphalenMutatiesResult,
  IOphalenMutatiesStatusData,
} from '../../../../../shared/src/api/v2/bank/mutaties';
import Tabblad, { ITabblad } from '../../../components/layout/Tabblad';
import ConceptTabblad, { EFilter as EConceptFilter } from './ConceptTabblad';
import BoekenTabblad, { EFilter as EBoekenFilter } from './DefinitiefTabblad';
import OnbekendeIBANsTabblad from './OnbekendeIBANsTabblad';
import { addMonths } from 'date-fns';
import { dagDatum } from '../../../helpers/datum';

enum ETabblad {
  Concept,
  Definitief,
  OnbekendeIBANs,
}

export interface IBankmutatiesContext {
  selectie: number[];
  onSelectieChange: (selectie: number[]) => void;
  uitgeklapt: number[];
  onUitgeklaptChange: (uitgeklapt: number[]) => void;
  gekozenRekening: IOphalenRekeningenResultElement | null;
  inlezenMutatiesState: IInlezenMutatiesState | null;
  onInlezenMutatiesStateChange: (state: IInlezenMutatiesState | null) => void;
  bepalenSaldoMutatiesResult: IBepalenSaldoMutatiesResult | null;
  laatsteInleesdatum: Date | null;
  onVerversenAangevraagd: () => Promise<void>;
  filterdata: IFilterData<EFilter>[];
  conceptFilterData: IFilterData<EConceptFilter>[];
  onConceptFilterDataChange: (filterData: IFilterData<EConceptFilter>[]) => void;
  boekenFilterData: IFilterData<EBoekenFilter>[];
  onBoekenFilterDataChange: (filterData: IFilterData<EBoekenFilter>[]) => void;

  // saldoCorrigerenDialoogState: ISaldoCorrigerenDialoogState | null;
  // onSaldoCorrigerenDialoogState: (state: ISaldoCorrigerenDialoogState | null) => void;
}

export const BankmutatiesContext = React.createContext<IBankmutatiesContext>(null as any);

enum EFilter {
  RekIDs = 'BANK_REK_IDS',
}

export interface IInlezenMutatiesState {}

// export interface ISaldoCorrigerenDialoogState {
//   bankRekID: number;
// }

interface IUrlState {
  filterData: IFilterData<EFilter>[];
  conceptFilterData: IFilterData<EConceptFilter>[];
  boekenFilterData: IFilterData<any>[];
  selectie: number[];
  uitgeklapt: number[];
  inlezenMutatiesState: IInlezenMutatiesState | null;
  tabblad: ETabblad;
  // saldoCorrigerenDialoogState: ISaldoCorrigerenDialoogState | null;
}

interface IProps extends RouteComponentProps {}

const Bankmutaties: React.FC<IProps> = observer((props) => {
  const { bankStore, instellingStore, checkStore } = useContext(RootStoreContext);

  const defaultUrlState = useMemo<IUrlState>(() => {
    return {
      filterData: [
        {
          naam: EFilter.RekIDs,
          data: [instellingStore.BankRekID],
          isActief: true,
        },
      ],
      conceptFilterData: [
        {
          naam: EConceptFilter.Status,
          data: {
            status: 'STATUS',
            inleesStatusCode: 'CONCEPT',
          } as IOphalenMutatiesStatusData,
          isActief: true,
        },
      ],
      boekenFilterData: [
        {
          naam: EBoekenFilter.Status,
          data: {
            status: 'STATUS',
            inleesStatusCode: 'DEFINITIEF',
          } as IOphalenMutatiesStatusData,
          isActief: true,
        },
        {
          naam: EBoekenFilter.GekoppeldAanBankopdracht,
          data: [1, 2],
          isActief: false,
        },
        {
          naam: EBoekenFilter.Geboekt,
          data: false,
          isActief: true,
        },
        {
          naam: EBoekenFilter.Zoekterm,
          data: '',
          isActief: false,
        },
        {
          naam: EBoekenFilter.Bedrag,
          data: '',
          isActief: false,
        },
        {
          naam: EBoekenFilter.DatumVanaf,
          data: dagDatum(addMonths(new Date(), -1)),
          isActief: false,
        },
        {
          naam: EBoekenFilter.DatumTot,
          data: dagDatum(new Date()),
          isActief: false,
        },
      ],
      selectie: [],
      uitgeklapt: [],
      inlezenMutatiesState: null,
      tabblad: ETabblad.Concept,
      saldoCorrigerenDialoogState: null,
    };
  }, [instellingStore.BankRekID]);

  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const [
    bepalenSaldoMutatiesResult,
    setBepalenSaldoMutatiesResult,
  ] = useState<IBepalenSaldoMutatiesResult | null>(null);
  const [laatsteInleesdatum, setLaatsteInleesdatum] = useState<Date | null>(null);

  useEffect(() => {
    if (bankStore.rekeningen !== null) {
      return;
    }
    // noinspection JSIgnoredPromiseFromCall
    bankStore.ophalenRekeningen();
  }, []);

  const rekeningenKolommen = useMemo<IKolom<IOphalenRekeningenResultElement>[]>(
    () => [
      {
        key: 'IBAN',
        label: 'IBAN',
        breedte: 200,
      },
      {
        key: 'Saldo',
        label: 'Definitief Saldo',
        breedte: 150,
        formatFabriek: (x) => <FormatteerBedrag bedrag={x.Saldo} />,
      },
    ],
    [],
  );

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.RekIDs,
        altijdWeergevenInBalk: true,
        actiefMuteerbaar: false,
        weergave: (weergaveProps) => {
          const data: number[] = weergaveProps.data;
          const waarde = data.length === 0 ? null : data[0];

          return (
            <div className="d-flex align-items-center">
              <span>Rekening</span>
              <span className="ml-2">
                {bankStore.rekeningen === null ? (
                  <LoadingSpinner />
                ) : (
                  <MultiCombobox<number, IOphalenRekeningenResultElement>
                    sleutelExtractor={(x) => x.BankRekID}
                    representatieFabriek={(x) => (
                      <div className="d-flex flex-fill align-items-center">
                        <span>{x.IBAN}</span>
                        {/*<span style={{ marginLeft: 15, marginRight: 15 }}>-</span>*/}
                        {/*<span>*/}
                        {/*  <MutatieBedrag bedrag={x.Saldo} />*/}
                        {/*</span>*/}
                      </div>
                    )}
                    waarde={waarde}
                    onWaardeChange={(x) => {
                      weergaveProps.onDataChange(x === null ? [] : [x]);
                      weergaveProps.toepassen();
                    }}
                    opties={bankStore.rekeningen}
                    kolommen={rekeningenKolommen}
                  />
                )}
              </span>
            </div>
          );
        },
      },
    ],
    [bankStore.rekeningen, rekeningenKolommen],
  );

  const heeftRekeningGekozen = useMemo(() => {
    const filterItem = urlState.filterData.find((x) => x.naam === EFilter.RekIDs)!;
    return filterItem.isActief && (filterItem.data as number[]).length > 0;
  }, [urlState.filterData]);

  const bepalenSaldoMutaties = useCallback(async () => {
    const rekIDsFilterItem = urlState.filterData.find((x) => x.naam === EFilter.RekIDs);
    if (rekIDsFilterItem === undefined) {
      setBepalenSaldoMutatiesResult(null);
      return;
    }
    const result = await api.v2.bank.mutatie.bepalenSaldoMutaties({
      filterSchema: {
        filters: [rekIDsFilterItem, { naam: 'IS_DEFINITIEF', data: false }],
      },
    });
    setBepalenSaldoMutatiesResult(result);
  }, [JSON.stringify(urlState.filterData)]);

  useEffect(() => {
    bepalenSaldoMutaties();
  }, [bepalenSaldoMutaties]);

  const bepalenLaatsteInleesdatum = useCallback(async () => {
    const rekIDsFilterItem = urlState.filterData.find((x) => x.naam === EFilter.RekIDs);
    if (rekIDsFilterItem === undefined) {
      setLaatsteInleesdatum(null);
      return;
    }
    const result = await api.v2.bank.mutatie.bepalenLaatsteInleesdatum({
      bankRekIDs: rekIDsFilterItem.data,
    });

    setLaatsteInleesdatum(result.laatsteInleesdatum);
  }, [JSON.stringify(urlState.filterData)]);

  useEffect(() => {
    bepalenLaatsteInleesdatum();
  }, [bepalenLaatsteInleesdatum]);

  const verversen = useCallback(async () => {
    await Promise.all([
      bepalenSaldoMutaties(),
      bankStore.ophalenRekeningen(),
      bepalenLaatsteInleesdatum(),
    ]);
  }, [bepalenSaldoMutaties, bepalenLaatsteInleesdatum]);

  const gekozenRekening = useMemo<IOphalenRekeningenResultElement | null>(() => {
    if (bankStore.rekeningen === null || !heeftRekeningGekozen) {
      return null;
    }
    const filterItem = urlState.filterData.find((x) => x.naam === EFilter.RekIDs)!;
    const [rekID] = filterItem.data as number[];
    return bankStore.rekeningen.find((x) => x.BankRekID === rekID)!;
  }, [heeftRekeningGekozen, urlState.filterData, bankStore.rekeningen]);

  const tabbladen = useMemo<ITabblad<ETabblad>[]>(
    () => [
      {
        id: ETabblad.Concept,
        label: 'Concept',
        content: ConceptTabblad,
      },
      {
        id: ETabblad.Definitief,
        label: 'Definitief',
        content: BoekenTabblad,
      },
      // {
      //   id: ETabblad.OnbekendeIBANs,
      //   label: 'Betalingen met onbekende IBAN',
      //   content: OnbekendeIBANsTabblad,
      // },
    ],
    [],
  );
  const bankmutatiesContextValue = useMemo<IBankmutatiesContext>(() => {
    return {
      selectie: urlState.selectie,
      onSelectieChange: (selectie) => setUrlStateSync('selectie', selectie),
      uitgeklapt: urlState.uitgeklapt,
      onUitgeklaptChange: (uitgeklapt) => setUrlStateSync('uitgeklapt', uitgeklapt),
      gekozenRekening,
      onVerversenAangevraagd: verversen,
      inlezenMutatiesState: urlState.inlezenMutatiesState,
      onInlezenMutatiesStateChange: (state) => setUrlStateSync('inlezenMutatiesState', state),
      bepalenSaldoMutatiesResult,
      laatsteInleesdatum,
      filterdata: urlState.filterData,
      conceptFilterData: urlState.conceptFilterData,
      onConceptFilterDataChange: (filterData) => setUrlStateSync('conceptFilterData', filterData),
      boekenFilterData: urlState.boekenFilterData,
      onBoekenFilterDataChange: (filterData) => setUrlStateSync('boekenFilterData', filterData),
    };
  }, [
    urlState.selectie,
    urlState.uitgeklapt,
    setUrlStateSync,
    gekozenRekening,
    verversen,
    urlState.inlezenMutatiesState,
    bepalenSaldoMutatiesResult,
    laatsteInleesdatum,
    urlState.filterData,
    urlState.conceptFilterData,
    urlState.boekenFilterData,
  ]);

  return (
    <BankmutatiesContext.Provider value={bankmutatiesContextValue}>
      <MenuLayout
        menu={
          <FilterBalkV2
            filterData={urlState.filterData}
            filters={filters}
            onFilterDataChange={(x) => setUrlStateSync('filterData', x)}
            onFilterSchemaChange={() => {}}
          />
        }
        body={
          <Tabblad
            geselecteerd={urlState.tabblad}
            onSelectieChange={(x) => setUrlStateSync('tabblad', x as ETabblad)}
            tabbladen={tabbladen}
          />
        }
      />
    </BankmutatiesContext.Provider>
  );
});

export default withRouter(Bankmutaties);
