import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { Kleur } from '../../../bedrijfslogica/constanten';
import { EyeIcon, IconKruis } from '../../../components/Icons';
import {
  IOphalenTakenResult,
  IOphalenTakenResultElement,
  IWijzigenTaakParams,
} from '../../../../../shared/src/api/v2/taken';
import api from '../../../api';
import {
  DXTableCheckboxComponent,
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
  DXTableToggleCellComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../helpers/dxTableGrid';
import {
  Grid,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import LoadingSpinner from '../../../components/Gedeeld/LoadingSpinner';
import {
  DataTypeProvider,
  EditingState,
  IntegratedSorting,
  RowDetailState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import useUrlState from '../../../core/useUrlState';
import WijzigenDialoog from './WijzigenDialoog';
import { RootStoreContext } from '../../../stores/RootStore';
import { EResultType } from '../../../stores/CheckStore';
import UitlegTooltip from '../../../components/formulier/UitlegTooltip';
import nameof from '../../../core/nameOf';
import Detailregel from './Detailregel';
import FilterBalkV2, {
  IFilter,
  IFilterData,
  maakFilterSchema,
} from '../../../components/FilterBalkV2';
import { IFilterSchema } from '../../../../../shared/src/models/filter';
import { Helmet } from 'react-helmet';
import Combobox from '../../../components/formulier/Combobox';

export enum EFilter {
  // IS_ACTIEF = 'IS_ACTIEF',
  IS_ACTIEF = 'STATUS_ACTIEF',
}

interface IUrlState {
  selectie: number[];
  wijzigenID: number | null;
  filterdata: IFilterData<EFilter>[];
}

const defaultUrlState: IUrlState = {
  selectie: [],
  wijzigenID: null,
  filterdata: [
    {
      naam: EFilter.IS_ACTIEF,
      data: 1,
      isActief: true,
    },
  ],
};

interface IProps extends RouteComponentProps {}

const Lijst: React.FC<IProps> = (props) => {
  const [taken, setTaken] = useState<IOphalenTakenResult | null>(null);

  const [urlState, _, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const { checkStore } = useContext(RootStoreContext);

  const [filterSchema, setFilterSchema] = useState<IFilterSchema>(
    useMemo(() => maakFilterSchema(urlState.filterdata), []),
  );

  const ophalenTaken = useCallback(async () => {
    const result = await api.v2.taken.ophalenTaken({
      filterSchema: {
        filters: [...filterSchema.filters!],
      },
      orderSchema: { orders: [{ naam: 'NAAM', richting: 'ASC' }] },
    });

    setTaken(result);
  }, [filterSchema]);

  useEffect(() => {
    ophalenTaken();

    const intervalId = setInterval(() => ophalenTaken(), 30000);
    return () => clearInterval(intervalId);
  }, [ophalenTaken]);

  const kolommen = useMemo<TypedColumn<IOphalenTakenResultElement>[]>(
    () => [
      {
        name: '__actiefKleur' as any,
        title: ' ',
      },
      {
        name: 'IsLopend',
        title: ' ',
      },
      {
        name: 'Naam',
        title: 'Naam',
      },
      {
        name: 'Actief',
        title: 'Actief',
      },
      {
        name: 'Visualiseren',
        title: 'Visualiseren',
      },
      {
        name: 'Uitvoeren',
        title: ' ',
      },
      // {
      //   name: 'NaamEnum',
      //   title: 'Enum',
      // },
      // {
      //   name: 'Omschrijving',
      //   title: 'Omschrijving',
      // },
      {
        name: 'SessieUniekeNaam',
        title: 'Sessie unieke naam',
      },
      // {
      //   name: 'CrontabSyntax',
      //   title: 'Crontab',
      // },
      {
        name: 'crontabSyntaxLeesbaar',
        title: 'Crontab Leesbaar',
      },
      {
        name: 'crontabSyntaxLeesbaarEerstVolgend',
        title: 'Eerst volgend',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IOphalenTakenResultElement>[]>(
    () => [
      {
        columnName: '__actiefKleur' as any,
        width: 20,
      },
      {
        columnName: 'IsLopend',
        width: 50,
      },
      {
        columnName: 'Naam',
        width: 325,
      },
      // {
      //   columnName: 'NaamEnum',
      //   width: 400,
      // },
      {
        columnName: 'Uitvoeren',
        width: 150,
      },
      // {
      //   columnName: 'Omschrijving',
      //   width: 350,
      // },
      {
        columnName: 'SessieUniekeNaam',
        width: 200,
      },
      {
        columnName: 'CrontabSyntax',
        width: 150,
      },
      {
        columnName: 'crontabSyntaxLeesbaar',
        width: 300,
      },
      {
        columnName: 'crontabSyntaxLeesbaarEerstVolgend',
        width: 250,
      },
      {
        columnName: 'Actief',
        width: 150,
      },
      {
        columnName: 'Visualiseren',
        width: 100,
      },
    ],
    [],
  );

  const keyExtractor = useCallback((taak: IOphalenTakenResultElement) => taak.ID, []);

  const handleLopendeTaakAnnuleren = useCallback(
    async (taakID: number) => {
      const taak = taken?.taken.find((t) => t.ID === taakID) ?? null;
      const result = await checkStore.bevestigen({
        inhoud: `Bevestig annuleren taak${taak === null ? '' : ` '${taak.Naam}'`}`,
      });
      if (result.type === EResultType.Annuleren) {
        return;
      }

      await api.v2.taken.annuleerLopendeTaken({
        taakIDs: [taakID],
      });
      await ophalenTaken();
    },
    [ophalenTaken],
  );

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.IS_ACTIEF,
        weergave: (weergaveProps) => {
          return (
            <div className="d-flex align-items-center">
              <span className="mr-2">Status</span>
              <Combobox
                geselecteerd={weergaveProps.data}
                onSelectieChange={(x) => {
                  weergaveProps.onDataChange(x);
                  weergaveProps.toepassen();
                }}
                opties={[
                  { id: 1, label: 'Actief' },
                  { id: 2, label: 'Niet Actief' },
                ]}
              />
            </div>
          );
        },
        altijdWeergevenInBalk: true,
      },
    ],
    [],
  );

  return (
    <>
      <Helmet>
        <title>Takenplanner</title>
      </Helmet>
      <div style={{ backgroundColor: 'white' }}>
        <div>
          <div
            className="d-flex flex-column p-3"
            style={{
              backgroundColor: Kleur.HeelLichtGrijs,
              borderBottom: `1px solid ${Kleur.LichtGrijs}`,
            }}
          >
            <FilterBalkV2
              filters={filters}
              filterData={urlState.filterdata}
              onFilterDataChange={(x) => setUrlStateSync('filterdata', x)}
              onFilterSchemaChange={(x) => setFilterSchema(x)}
            />
          </div>
        </div>
        {/* <div
          className="d-flex flex-column p-3"
          style={{
            backgroundColor: Kleur.HeelLichtGrijs,
            borderBottom: `1px solid ${Kleur.LichtGrijs}`,
          }}
        >
          <div className="d-flex">
            <button
              className="btn btn-sm btn-light d-flex align-items-center"
              style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
              disabled
            >
              <IconToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
              <span className="ml-2">Toevoegen</span>
            </button>
          </div>
        </div> */}
        {taken === null ? (
          <div className="d-flex align-items-center justify-content-center flex-fill">
            <LoadingSpinner />
          </div>
        ) : (
          <GridStyleWrapper height="calc(100vh - 100px)">
            <Grid rows={taken.taken} getRowId={keyExtractor} columns={kolommen}>
              <DataTypeProvider
                for={['__actiefKleur']}
                formatterComponent={(formatterProps) => {
                  const row: IOphalenTakenResultElement = formatterProps.row;

                  return (
                    <div
                      style={{
                        width: 4,
                        height: 27,
                        backgroundColor: row.Actief ? Kleur.Groen : Kleur.Rood,
                      }}
                    />
                  );
                }}
              />

              <DataTypeProvider
                for={[nameof<IOphalenTakenResultElement>('IsLopend')]}
                formatterComponent={(formatterProps) => {
                  const row: IOphalenTakenResultElement = formatterProps.row;
                  return row.IsLopend ? (
                    <div className="d-flex flex-fill align-items-center justify-content-center ml-1">
                      <LoadingSpinner grootte="16px" style={{ position: 'relative', top: 1 }} />
                      <UitlegTooltip inhoud="Lopende taak annuleren">
                        <button
                          style={{
                            outline: 0,
                            border: 0,
                            background: 0,
                            // padding: 0,
                          }}
                          className="ml-1"
                          onClick={() => handleLopendeTaakAnnuleren(row.ID)}
                        >
                          <IconKruis style={{ fill: Kleur.Rood, width: 20, height: 20 }} />
                        </button>
                      </UitlegTooltip>
                    </div>
                  ) : null;
                }}
              />

              <DataTypeProvider
                for={['Visualiseren']}
                formatterComponent={(formatterProps) => {
                  const row: IOphalenTakenResultElement = formatterProps.row;
                  return row.Visualiseren ? (
                    <EyeIcon style={{ fill: Kleur.Grijs, width: 20, height: 20, marginTop: 3 }} />
                  ) : null;
                }}
              />

              <DataTypeProvider
                for={[nameof<IOphalenTakenResultElement>('SessieUniekeNaam')]}
                formatterComponent={(formatterProps) => {
                  const row: IOphalenTakenResultElement = formatterProps.row;
                  return row.ParallelToestaan ? (
                    <span style={{ color: Kleur.LichtGrijs }}>{row.SessieUniekeNaam}</span>
                  ) : (
                    <span>{row.SessieUniekeNaam}</span>
                  );
                }}
              />

              <DataTypeProvider
                for={['Actief']}
                formatterComponent={(formatterProps) => {
                  const row: IOphalenTakenResultElement = formatterProps.row;

                  return (
                    <div className="d-flex align-items-center">
                      <div style={{ width: 75 }}>{row.Actief ? 'Ja' : 'Nee'}</div>
                      <button
                        className="btn btn-sm btn-light ml-3"
                        style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                        onClick={async () => {
                          const params: IWijzigenTaakParams = {
                            id: row.ID,
                            parallelToestaan: row.ParallelToestaan,
                            omschrijving: row.Omschrijving,
                            crontabSyntax: row.CrontabSyntax,
                            actief: !row.Actief,
                            naam: row.Naam,
                            naamEnum: row.NaamEnum,
                            visualiseren: row.Visualiseren,
                          };
                          const checkData = await api.v2.taken.checkWijzigenTaak(params);
                          if (
                            (await checkStore.controleren({ checkData })).type ===
                            EResultType.Annuleren
                          ) {
                            return;
                          }
                          await api.v2.taken.wijzigenTaak(params);
                          await ophalenTaken();
                        }}
                      >
                        {row.Actief ? 'Deactiveren' : 'Activeren'}
                      </button>
                    </div>
                  );
                }}
              />

              <DataTypeProvider
                for={['Uitvoeren']}
                formatterComponent={(formatterProps) => {
                  const row: IOphalenTakenResultElement = formatterProps.row;

                  return (
                    <div className="d-flex align-items-center">
                      <button
                        className="btn btn-sm btn-light"
                        style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                        onClick={async () => {
                          if (!row.ParallelToestaan) {
                            const bevestigenResult = await checkStore.bevestigen({
                              inhoud: `Voor deze taak geldt dat Parallel uitvoeren niet toegestaan is. Als je de taak nu uitvoert kan dat negatieve consequenties hebben. Toch doorgaan?`,
                            });
                            if (bevestigenResult.type === EResultType.Annuleren) {
                              return;
                            }
                          }

                          const bevestigenResult = await checkStore.bevestigen({
                            inhoud: `Wil je de taak ${row.Naam} direct uitvoeren?`,
                          });
                          if (bevestigenResult.type === EResultType.Annuleren) {
                            return;
                          }

                          const achergrondProces = await api.v2.taken.directUitvoeren({
                            naamEnum: row.NaamEnum,
                          });
                          await ophalenTaken();
                        }}
                      >
                        Direct uitvoeren
                      </button>
                    </div>
                  );
                }}
              />

              <DataTypeProvider
                for={['Omschrijving']}
                formatterComponent={(formatterProps) => {
                  const row: IOphalenTakenResultElement = formatterProps.row;

                  if (row.Omschrijving === null) {
                    return <span>{row.Omschrijving}</span>;
                  }

                  return (
                    <UitlegTooltip inhoud={row.Omschrijving}>
                      <span>{row.Omschrijving}</span>
                    </UitlegTooltip>
                  );
                }}
              />

              {/*<DataTypeProvider*/}
              {/*  for={['crontabSyntaxLeesbaarEerstVolgend']}*/}
              {/*  formatterComponent={(formatterProps) => {*/}
              {/*    const row: IOphalenTakenResultElement = formatterProps.row;*/}

              {/*    return (*/}
              {/*      <span className={row.Actief ? '' : 'text-muted'}>*/}
              {/*        {row.crontabSyntaxLeesbaarEerstVolgend}*/}
              {/*      </span>*/}
              {/*    );*/}
              {/*  }}*/}
              {/*/>*/}

              {/* <SortingState defaultSorting={[]} /> */}
              <SortingState
                defaultSorting={[
                  // {
                  //   columnName: 'crontabSyntaxLeesbaar',
                  //   direction: 'asc',
                  // },
                  {
                    columnName: 'Naam',
                    direction: 'asc',
                  },
                ]}
              />
              <IntegratedSorting />

              <SelectionState
                selection={urlState.selectie}
                onSelectionChange={(x) => setUrlStateSync('selectie', x as number[])}
              />

              <EditingState
                onCommitChanges={() => null}
                onEditingRowIdsChange={(rowIds) => {
                  const id = rowIds[rowIds.length - 1] as number;
                  setUrlStateSync('wijzigenID', id);
                }}
              />

              <VirtualTable
                columnExtensions={[
                  {
                    columnName: 'Visualiseren',
                    align: 'center',
                  },
                ]}
              />
              <TableColumnResizing defaultColumnWidths={kolomBreedtes} />

              <TableEditColumn
                width={35}
                showEditCommand
                cellComponent={DXTableEditColumnCellComponent}
                commandComponent={DXTableEditColumnCommandComponent}
              />
              <RowDetailState defaultExpandedRowIds={[]} />

              <TableRowDetail
                contentComponent={Detailregel}
                toggleCellComponent={DXTableToggleCellComponent}
              />
              <TableHeaderRow showSortingControls />
              <TableSelection cellComponent={DXTableCheckboxComponent} />
            </Grid>
          </GridStyleWrapper>
        )}
      </div>
      {urlState.wijzigenID !== null && (
        <WijzigenDialoog
          taakID={urlState.wijzigenID}
          open
          onSuccess={async () => {
            await ophalenTaken();
            setUrlStateSync('wijzigenID', null);
          }}
          onAnnuleren={() => setUrlStateSync('wijzigenID', null)}
        />
      )}
    </>
  );
};

export default Lijst;
