// http://react-dnd.github.io/react-dnd/examples/other/native-files

import * as React from 'react';
import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { NativeTypes } from 'react-dnd-html5-backend';
import { DisabledOverlay, Root } from './styles';
import TargetBox from './TargetBox';
import ILokaalBestand from '../../models/ILokaalBestand';
import api from '../../api';
import { IOphalenBestandenResultElement } from '../../../../shared/src/api/v2/bestand/bestand';
import DriveBestandsselectieDialoog from '../drive/DriveBestandsselectieDialoog';
import { ESelectieModus } from '../BijlageKnop';

const { FILE } = NativeTypes;

export enum EBestandDragAndDropZoneSelectieModus {
  Enkel,
  Meerdere,
}

export enum EBestandDragAndDropZoneBronModus {
  LokaleBestanden,
  DriveBestanden,
  Alle,
}

export interface IBestandDragAndZoneBestand {
  lokaal?: ILokaalBestand;
  aspDrive?: IOphalenBestandenResultElement;
}

export interface IUploadVoortgangVanLokaleBestanden {
  [key: string]: number;
}

interface IProps {
  bestanden: IBestandDragAndZoneBestand[];
  onBestandenChange: (bestanden: IBestandDragAndZoneBestand[]) => void;
  selectieModus?: EBestandDragAndDropZoneSelectieModus;
  bronModus?: EBestandDragAndDropZoneBronModus;
  disabled?: boolean;
  uploadVoortgangen?: IUploadVoortgangVanLokaleBestanden;
  toegestaneBestandstypes?: {
    weergaveNaam: string;
    mediaType: string;
  }[];
  automatischUploaden?: boolean;
}

const defaultProps: Partial<IProps> = {
  disabled: false,
  selectieModus: EBestandDragAndDropZoneSelectieModus.Meerdere,
  bronModus: EBestandDragAndDropZoneBronModus.Alle,
  uploadVoortgangen: {
    // a: 55,
  },
  automatischUploaden: false,
  // allowsDuplicateFiles: false
};

const BestandDragAndDropZone: React.FC<IProps> = (props) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  // const [errors, setErrors] = useState<string[]>([]);

  const {
    bestanden,
    selectieModus,
    bronModus,
    uploadVoortgangen,
    automatischUploaden,
    // allowsDuplicateFiles
  } = useMemo(() => ({ ...defaultProps, ...props }), [props]);
  const bestandenRef = useRef<IBestandDragAndZoneBestand[]>(bestanden);
  const onBestandenChange = useCallback(
    (x: IBestandDragAndZoneBestand[]) => {
      bestandenRef.current! = x;
      props.onBestandenChange(x);
    },
    [props.onBestandenChange],
  );
  useEffect(() => {
    bestandenRef.current = bestanden;
  }, [bestanden]);

  const [aspDriveSelectieOpen, setAspDriveSelectieOpen] = useState(false);
  const [automatischUploadenVoortgangen, setAutomatischUploadenVoortgangen] = useState<
    IUploadVoortgangVanLokaleBestanden
  >({});

  const dndAccepts = useMemo(() => {
    switch (bronModus) {
      case EBestandDragAndDropZoneBronModus.Alle:
        return [FILE];
      case EBestandDragAndDropZoneBronModus.DriveBestanden:
        return [];
      case EBestandDragAndDropZoneBronModus.LokaleBestanden:
        return [FILE];
      default:
        return [];
    }
  }, [bronModus]);

  // Wegens unieke bestandsnaam
  const allowsDuplicateFiles = false;

  const bestandenToevoegen = useCallback(
    (nieuweBestanden: IBestandDragAndZoneBestand[]) => {
      // const duplicates = bestanden.filter(
      //   (file) => nieuweBestanden.findIndex((x) => x.name === file.name) !== -1,
      // );
      // if (duplicates.length !== 0 && !allowsDuplicateFiles) {
      //   const errors = duplicates.map((file) => `Het bestand '${file.name}' is al toegevoegd`);
      //   alert(errors.join('\n'));
      //   // setErrors(errors);
      //   // return;
      //   // Verwijder duplicates van de toevoegen lijst
      //   nieuweBestanden = nieuweBestanden.filter(
      //     (x) => duplicates.findIndex((y) => y.name === x.name) === -1,
      //   );
      // }
      if (props.toegestaneBestandstypes !== undefined) {
        if (
          nieuweBestanden.findIndex((bestand) => {
            const mediaType =
              bestand.aspDrive === undefined
                ? bestand.lokaal!.mediaType
                : bestand.aspDrive!.MediaType;
            return (
              props.toegestaneBestandstypes!.findIndex(
                (allowedType) => allowedType.mediaType === mediaType,
              ) === -1
            );
          }) !== -1
        ) {
          // Bestand met onjuist media type
          alert('Een bestand voldoet niet aan de type eisen die worden gesteld');
          return;
        }
      }

      if (selectieModus === EBestandDragAndDropZoneSelectieModus.Enkel) {
        onBestandenChange(nieuweBestanden);
      } else if (selectieModus === EBestandDragAndDropZoneSelectieModus.Meerdere) {
        onBestandenChange([...bestanden, ...nieuweBestanden]);
      }
    },
    [
      bestanden,
      onBestandenChange,
      selectieModus,
      allowsDuplicateFiles,
      props.toegestaneBestandstypes,
    ],
  );

  const handleFileDrop = useCallback(
    (item: any) => {
      if (item) {
        const files: File[] = item.files;

        const bestanden = files.map((file) => {
          const bestand: ILokaalBestand = {
            file,
            url: URL.createObjectURL(file),
            mediaType: file.type,
            naam: file.name,
            grootte: file.size,
          };
          return { lokaal: bestand } as IBestandDragAndZoneBestand;
        });

        bestandenToevoegen(bestanden);
      }
    },
    [bestandenToevoegen],
  );

  const handleClick = useCallback(
    (ev: React.MouseEvent<HTMLDivElement>) => {
      ev.stopPropagation();
    },
    [fileInputRef],
  );

  const handleFileInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files!.length === 0) {
        // Geen bestand(en) geselecteerd
        return;
      }
      const files: File[] = Array.from(e.target.files!);

      bestandenToevoegen(
        files
          .map(
            (file) =>
              ({
                ...file,
                file,
                url: URL.createObjectURL(file),
                naam: file.name,
                mediaType: file.type,
                grootte: file.size,
              } as ILokaalBestand),
          )
          .map((x) => ({ lokaal: x } as IBestandDragAndZoneBestand)),
      );
      fileInputRef.current!.value = '';
    },
    [bestandenToevoegen, fileInputRef],
  );

  const handleASPDriveBestandenGeselecteerd = (bestanden: IOphalenBestandenResultElement[]) => {
    bestandenToevoegen(bestanden.map((x) => ({ aspDrive: x } as IBestandDragAndZoneBestand)));
    setAspDriveSelectieOpen(false);
  };

  const startAutomatischeUploadBestand = useCallback(
    async (bestand: ILokaalBestand) => {
      const uploadResult = await api.v2.bestand.upload(bestand, (percentage) => {
        setAutomatischUploadenVoortgangen((prevState) => ({
          ...prevState,
          [bestand.url]: percentage,
        }));
      });

      onBestandenChange(
        bestandenRef.current!.map((x) => {
          if (x.lokaal !== undefined && x.lokaal.url === bestand.url) {
            return {
              lokaal: bestand,
              aspDrive: uploadResult,
            };
          }
          return x;
        }),
      );
    },
    [setAutomatischUploadenVoortgangen, onBestandenChange],
  );

  useEffect(() => {
    if (!automatischUploaden) {
      return;
    }
    const lokaleBestandenDieNogMoetenWordenGeuploaded = props.bestanden.filter(
      (bestand) =>
        bestand.aspDrive === undefined &&
        automatischUploadenVoortgangen[bestand.lokaal!.url] === undefined,
    );
    if (lokaleBestandenDieNogMoetenWordenGeuploaded.length === 0) {
      return;
    }

    lokaleBestandenDieNogMoetenWordenGeuploaded.forEach((bestand) => {
      startAutomatischeUploadBestand(bestand.lokaal!);
    });
  }, [props.bestanden, automatischUploaden]);

  const uploadProgresses = useMemo(
    () => ({
      ...uploadVoortgangen,
      ...automatischUploadenVoortgangen,
    }),
    [uploadVoortgangen, automatischUploadenVoortgangen],
  );

  return (
    <Root onClick={handleClick}>
      {aspDriveSelectieOpen && (
        <DriveBestandsselectieDialoog
          selectieModus={
            props.selectieModus === EBestandDragAndDropZoneSelectieModus.Enkel
              ? ESelectieModus.Enkel
              : ESelectieModus.Meerdere
          }
          open
          onSuccess={async (result) => {
            const bestandenResult = await api.v2.bestand.ophalenBestanden({
              filterSchema: {
                filters: [
                  {
                    naam: 'IDS',
                    data: result.bestandIDs,
                  },
                ],
              },
            });
            handleASPDriveBestandenGeselecteerd(bestandenResult.bestanden);
            setAspDriveSelectieOpen(false);
          }}
          onAnnuleren={() => setAspDriveSelectieOpen(false)}
          magBestandSelecteren={(bestandItem) => {
            if (props.toegestaneBestandstypes === undefined) {
              return true;
            }

            const mediaType = bestandItem.bestand.MediaType;

            if (
              !props.toegestaneBestandstypes.some((t) => {
                const mediaTypeRegexStr = t.mediaType.replace('/', '\\/').replace('*', '\\w+');
                const regex = new RegExp(mediaTypeRegexStr);
                return regex.test(mediaType);
              })
            ) {
              return false;
            }

            return true;
          }}
        />
      )}
      <input
        ref={fileInputRef}
        type="file"
        multiple={selectieModus === EBestandDragAndDropZoneSelectieModus.Meerdere}
        style={{ display: 'none' }}
        onChange={handleFileInputChange}
      />

      {/*<DragDropContextProvider backend={HTML5Backend} context={HTML5DragDropContext}>*/}
      <TargetBox
        accepts={dndAccepts}
        onDrop={handleFileDrop}
        bestanden={bestanden}
        onBestandenChange={onBestandenChange}
        uploadProgresses={uploadProgresses}
        bronmodus={bronModus!}
        onRequestASPDriveSelection={() => setAspDriveSelectieOpen(true)}
        onRequestFileBrowse={() => fileInputRef.current!.click()}
        allowedFileTypes={props.toegestaneBestandstypes}
        selectieModus={selectieModus!}
      />
      {/*</DragDropContextProvider>*/}
      {/*<ErrorList errors={errors} />*/}
      {props.disabled && <DisabledOverlay />}
    </Root>
  );
};

export default BestandDragAndDropZone;
