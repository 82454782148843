import api from '../index';
import {
  IOphalenRelatieSelectieRecentParams,
  IOphalenRelatieSelectieRecentResult,
} from '../../../../shared/src/api/v2/relatie/Selectie';

const selectie = {
  ophalenRelatieselectieRecent: async (
    params: IOphalenRelatieSelectieRecentParams,
  ): Promise<IOphalenRelatieSelectieRecentResult> => {
    return await api.post('v2/relatie/selectie/ophalen-relatieselectie-recent', params);
  },
};

export default selectie;
