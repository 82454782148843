import React, { useContext, useRef, useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { RootStoreContext } from '../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import Links from './Links';
import Rechts from './Rechts';
import { useSnelkoppeling } from '../SnelkoppelingAfhandelaar';
import zoek from '../../api/v2/zoek';
import InactiefOverlay from '../../components/InactiefOverlay';

const Root = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  //backdrop-filter: blur(10px);
  //background: rgba(255, 255, 255, 0.85);
  background-color: rgb(255, 255, 255);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease-in-out;
  z-index: 9999;
  display: flex;
  flex-direction: column;

  &.open {
    pointer-events: all;
    opacity: 1;
  }
`;

const Container = styled.div`
  flex: 1 1 auto;
  display: flex;
`;

interface IProps {}

const ZoekOverlay = observer((props: IProps) => {
  const { zoekStore } = useContext(RootStoreContext);

  useSnelkoppeling(
    useMemo(
      () => ({
        activatietoets: 'f',
        stuurtoetsen: {
          ctrl: true,
          shift: true,
        },
        handler: () => zoekStore.open(),
      }),
      [zoekStore.open],
    ),
  );

  useSnelkoppeling(
    useMemo(
      () => ({
        activatietoets: 'escape',
        stuurtoetsen: {},
        handler: () => {
          if (!zoekStore.isOpen || zoekStore.isBezig) {
            return;
          }
          zoekStore.sluiten();
        },
      }),
      [zoekStore.sluiten, zoekStore.isBezig],
    ),
  );

  // useEffect(() => {
  //   setInterval(() => console.log(document.activeElement), 1000);
  // }, []);

  return (
    <Root className={zoekStore.isOpen ? 'open' : ''}>
      <InactiefOverlay
        isInactief={zoekStore.isBezig}
        element={
          <Container>
            <Links />
            <Rechts />
          </Container>
        }
      />
    </Root>
  );
});

export default ZoekOverlay;
