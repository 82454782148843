import * as React from 'react';
import {
  Grid,
  Table,
  TableColumnResizing,
  TableHeaderRow,
  TableRowDetail,
} from '@devexpress/dx-react-grid-bootstrap4';
import { IOphalenMeldingenResultElement } from '../../../../../../../../../../../../shared/src/api/v2/service/melding';
import { IOphalenOpdrachtenResultElement } from '../../../../../../../../../../../../shared/src/api/v2/service/opdracht';
import {
  GridStyleWrapper,
  TableRowDetailWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../../../../../../../helpers/dxTableGrid';
import TabelInspringBlok from '../../../../../../../../../../components/layout/TabelInspringBlok';
import api from '../../../../../../../../../../api';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { format } from 'date-fns';

const RowDetailComp = (props: TableRowDetail.ContentProps) => {
  const melding: IOphalenMeldingenResultElement = props.row;
  const [opdrachten, setOpdrachten] = useState<IOphalenOpdrachtenResultElement[]>([]);

  useEffect(() => {
    (async () => {
      const opdrachten = (
        await api.v2.service.ophalenOpdrachten({
          filterSchema: { filters: [{ naam: 'SERVMELD_IDS', data: [melding.ID] }] },
        })
      ).opdrachten;

      setOpdrachten(opdrachten);
    })();
  }, [melding.ID]);

  const kolommen = useMemo<TypedColumn<IOphalenOpdrachtenResultElement>[]>(
    () => [
      {
        name: '__dienst' as any,
        title: 'Rep.dienst',
      },
      {
        name: 'DatumVerstuurd' as any,
        title: 'Verstuurd',
        getCellValue: (x) => {
          const datum = x.DatumVerstuurd !== null ? x.DatumVerstuurd : new Date('2099-01-01');
          return datum;
        },
      },
      {
        name: 'Garantieclaim',
        title: 'Gar.',
      },
      {
        name: 'Spoed',
        title: 'Spoed',
      },
      //   {
      //     name: 'Omschrijving',
      //     title: 'Omschrijving',
      //   },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IOphalenOpdrachtenResultElement>[]>(
    () => [
      {
        columnName: '__dienst' as any,
        width: 100,
      },
      {
        columnName: 'DatumVerstuurd',
        width: 150,
      },
      {
        columnName: 'Garantieclaim',
        width: 50,
      },
      {
        columnName: 'Spoed',
        width: 60,
      },
      //   {
      //     columnName: 'Omschrijving',
      //     width: 250,
      //   },
    ],
    [],
  );

  return (
    <div className="d-flex">
      <TabelInspringBlok />
      <GridStyleWrapper maxHeight={500} rowAmount={opdrachten.length}>
        <Grid
          rows={opdrachten}
          columns={kolommen}
          getRowId={(x: IOphalenOpdrachtenResultElement) => x.ServOpdID}
        >
          <DataTypeProvider
            for={['__dienst']}
            formatterComponent={(formatterProps) => {
              const rij: IOphalenOpdrachtenResultElement = formatterProps.row;
              return <span>{rij.dienst.relatie!.weergavenaam}</span>;
            }}
          />

          <DataTypeProvider
            for={['DatumVerstuurd']}
            formatterComponent={(formatterProps) => {
              const row: IOphalenOpdrachtenResultElement = formatterProps.row!;

              return (
                <span>
                  {row.DatumVerstuurd === null ? (
                    <span>Nog versturen</span>
                  ) : (
                    format(new Date(row.DatumVerstuurd), 'dd-MM-yyyy HH:mm')
                  )}
                </span>
              );
            }}
          />

          <DataTypeProvider
            for={['Garantieclaim']}
            formatterComponent={(formatterProps) => {
              const row: IOphalenOpdrachtenResultElement = formatterProps.row!;

              return <span>{row.Garantieclaim ? 'Ja' : 'Nee'}</span>;
            }}
          />

          <DataTypeProvider
            for={['Spoed']}
            formatterComponent={(formatterProps) => {
              const row: IOphalenOpdrachtenResultElement = formatterProps.row!;

              return <span>{row.Spoed ? 'Ja' : 'Nee'}</span>;
            }}
          />

          <Table
            messages={{
              noData: 'Geen reparatieopdrachten',
            }}
          />
          <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
          <TableHeaderRow />
        </Grid>
      </GridStyleWrapper>
    </div>
  );
};

export default RowDetailComp;
