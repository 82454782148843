import {
  IOphalenGekoppeldeWerkbonnenParams,
  IOphalenGekoppeldeWerkbonnenResult,
  IToevoegenGekoppeldeWerkbonnenParams,
  IToevoegenGekoppeldeWerkbonnenResult,
  IVerwijderenGekoppeldeWerkbonnenParams,
  IVerwijderenGekoppeldeWerkbonnenResult,
} from '../../../../../../shared/src/api/v2/inkoopfactuur/werkbon';
import api from '../../..';
import ICheckData from '../../../../../../shared/src/models/ICheckData';

const werkbon = {
  ophalenGekoppeldeWerkbonnen: async (
    params: IOphalenGekoppeldeWerkbonnenParams,
  ): Promise<IOphalenGekoppeldeWerkbonnenResult> => {
    return await api.post('/v2/inkoopfactuur/werkbon/ophalen-gekoppelde-werkbonnen', params);
  },
  toevoegenGekoppeldeWerkbonnen: async (
    params: IToevoegenGekoppeldeWerkbonnenParams,
  ): Promise<IToevoegenGekoppeldeWerkbonnenResult> => {
    return await api.post('/v2/inkoopfactuur/werkbon/toevoegen-gekoppelde-werkbonnen', params);
  },
  verwijderenGekoppeldeWerkbonnen: async (
    params: IVerwijderenGekoppeldeWerkbonnenParams,
  ): Promise<IVerwijderenGekoppeldeWerkbonnenResult> => {
    return await api.post('/v2/inkoopfactuur/werkbon/verwijderen-gekoppelde-werkbonnen', params);
  },
};

export default werkbon;
