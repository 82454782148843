import React, { useCallback, useMemo } from 'react';
import { Regel } from '../../types';
import MultiComboboxV2, {
  EnkeleProvider,
  IRepresentatieProps,
  Provider,
} from '../../../../../formulier/MultiComboboxV2';
import { IOphalenBetalingsregelingenResultElement } from '../../../../../../../../shared/src/api/v2/debiteur/betalingsregeling';
import api from '../../../../../../api';
import { ASPKolom, EAspKolomBreedteType } from '../../../../../tabel/ASPTabel/types';
import FormatteerBedrag from '../../../../../MutatieBedrag';
import { IFilterSchemaFilter } from '../../../../../../../../shared/src/models/filter';

enum EKolom {
  Referentie,
  Totaalbedrag,
  Termijnbedrag,
}

interface IProps {
  betRglID: number | null;
  onBetRglIDChange: (betRglID: number | null) => void;
  regel: Regel;
}

const BetalingsregelingMuteren = ({ betRglID, onBetRglIDChange, regel }: IProps): JSX.Element => {
  const provider = useMemo<Provider<EKolom, IOphalenBetalingsregelingenResultElement>>(
    () => async (params) => {
      const result = await api.v2.debiteur.betalingsregeling.ophalenBetalingsregelingen({
        paginatie: params.paginatie,
        filterSchema: {
          filters: [
            {
              naam: 'IS_AFGEHANDELD',
              data: false,
            },
            regel.relID === null
              ? null
              : {
                  naam: 'REL_IDS',
                  data: [regel.relID],
                },
          ].filter((x) => x !== null) as IFilterSchemaFilter[],
        },
      });

      const nieuweItems = result.betalingsregelingen.reduce(
        (acc, curr, i) => ({
          ...acc,
          [params.paginatie.index + i]: curr,
        }),
        params.huidigeBron,
      );

      return {
        items: nieuweItems,
        totaalAantal: result.totaalAantal,
      };
    },
    [regel.relID],
  );

  const enkeleProvider = useMemo<EnkeleProvider<number, IOphalenBetalingsregelingenResultElement>>(
    () => async (id: number) => {
      const result = await api.v2.debiteur.betalingsregeling.ophalenBetalingsregelingen({
        filterSchema: {
          filters: [
            {
              naam: 'IDS',
              data: [id],
            },
          ],
        },
      });

      return result.betalingsregelingen[0];
    },
    [],
  );

  const keyExtractor = useCallback((item: IOphalenBetalingsregelingenResultElement) => item.ID, []);

  const representatieComponent = useMemo(
    () => ({ entiteit }: IRepresentatieProps<IOphalenBetalingsregelingenResultElement>) => {
      return <span>{entiteit.Referentie}</span>;
    },
    [],
  );

  const kolommen = useMemo<ASPKolom<EKolom, IOphalenBetalingsregelingenResultElement>[]>(
    () => [
      {
        key: EKolom.Referentie,
        label: 'Referentie',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 150,
        renderer: ({ Referentie }) => <span>{Referentie}</span>,
      },
      {
        key: EKolom.Totaalbedrag,
        label: 'Totaalbedrag',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 150,
        renderer: ({ Totaalbedrag }) => <FormatteerBedrag bedrag={Totaalbedrag} />,
      },
      {
        key: EKolom.Termijnbedrag,
        label: 'Termijnbedrag',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 150,
        renderer: ({ Termijnbedrag }) => <FormatteerBedrag bedrag={Termijnbedrag} />,
      },
    ],
    [],
  );

  return (
    <MultiComboboxV2
      provider={provider}
      enkeleProvider={enkeleProvider}
      keyExtractor={keyExtractor}
      waarde={betRglID}
      onChange={onBetRglIDChange}
      representatieComponent={representatieComponent}
      kolommen={kolommen}
    />
  );
};

export default BetalingsregelingMuteren;
