import React, { SVGProps } from 'react';
import { Kleur } from '../../../../bedrijfslogica/constanten';

interface IProps {
  onGeselecteerd?: () => void;
  geselecteerd: boolean;
  icon: React.ComponentType<SVGProps<any>>;
  isFirst: boolean;
  isLast: boolean;
}

const CommunicatieTabblad: React.FC<IProps> = (props) => {
  const Icon = props.icon;
  return (
    <div
      className="flex-fill d-flex align-items-center justify-content-center p-2 pl-3 pr-3"
      style={{
        backgroundColor: props.geselecteerd ? `rgba(76,114,255,0.76)` : undefined,
        cursor: 'pointer',
        // borderTopLeftRadius: props.isFirst ? 5 : undefined,
        // borderBottomLeftRadius: props.isLast ? 5 : undefined,
      }}
      onClick={props.onGeselecteerd}
      // onMouseUp={props.onGeselecteerd}
    >
      <Icon style={{ width: 20, height: 20, fill: Kleur.Wit }} />
    </div>
  );
};

export default CommunicatieTabblad;
