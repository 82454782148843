import * as React from 'react';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  Grid,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import api from '../../../../../api';
import LoadingSpinner from '../../../../../components/Gedeeld/LoadingSpinner';
import {
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../../helpers/dxTableGrid';
import {
  Column,
  DataTypeProvider,
  EditingState,
  IntegratedSorting,
  SortingState,
  TableColumnWidthInfo,
  VirtualTable as VirtualTableBase,
} from '@devexpress/dx-react-grid';
import {
  IOphalenAfbeeldingenVanProducttypeResult,
  IOphalenLeveranciersVoorProductResultElement,
  IOphalenProducttypenInkoopResultElement,
} from '../../../../../../../shared/src/api/v2/inkoop/aanbod';
import FormatteerBedrag from '../../../../../components/MutatieBedrag';
import WijzigenInkoopgegevensDialoog from './WijzigenInkoopgegevensDialoog';
import { EResultType } from '../../../../../stores/CheckStore';
import { RootStoreContext } from '../../../../../stores/RootStore';
import ToevoegenInkoopgegevensDialoog from './ToevoegenInkoopgegevensDialoog';
import TabelInspringBlok from '../../../../../components/layout/TabelInspringBlok';
import useUpload from '../../../../../core/useUpload';
import BijlageKnop from '../../../../../components/BijlageKnop';
import BijlagenContainer, {
  BestandType,
  EWeergaveFocus,
} from '../../../../../components/BijlagenContainer';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../../models/IRemoteData';
import HorizontaleScheidingslijn from '../../../../../components/layout/HorizontaleScheidingslijn';
import { Kleur } from '../../../../../bedrijfslogica/constanten';
import { Simulate } from 'react-dom/test-utils';
import { ProductContext } from '../index';

type Props = any; // TableSummaryRow.ContentProps;

const tableMessages = {
  noData: 'Geen leveranciers voor dit product aanwezig',
};

const RowDetailComp: React.FunctionComponent<Props> = (props) => {
  const row = props.row as IOphalenProducttypenInkoopResultElement;

  const productContext = useContext(ProductContext);

  const { checkStore } = useContext(RootStoreContext);
  const [wijzigenID, setWijzigenID] = useState<{ typeID: number; inkDienstID: number } | null>(
    null,
  );
  const [toevoegenTonen, setToevoegenTonen] = useState<boolean>(false);

  const [leveranciers, setLeveranciers] = useState<
    IOphalenLeveranciersVoorProductResultElement[] | null
  >(null);
  const [afbeeldingenResult, setAfbeeldingenResult] = useState<
    IRemoteData<IOphalenAfbeeldingenVanProducttypeResult>
  >(createPendingRemoteData());

  const ophalenLeveranciers = useCallback(() => {
    (async () => {
      const result = await api.v2.inkoop.aanbod.ophalenLeveranciersVoorProduct({
        filterSchema: { filters: [{ naam: 'TYPE_IDS', data: [row.TypeID] }] },
      });

      setLeveranciers(result.inkoopdiensttypen);
    })();
  }, [row.TypeID]);

  useEffect(() => {
    ophalenLeveranciers();
  }, [ophalenLeveranciers]);

  const ophalenAfbeeldingen = useCallback(() => {
    (async () => {
      const result = await api.v2.inkoop.aanbod.ophalenAfbeeldingenVanProducttype({
        typeIDs: [row.TypeID],
      });

      setAfbeeldingenResult(createReadyRemoteData(result));
    })();
  }, [row.TypeID]);

  useEffect(() => {
    ophalenAfbeeldingen();
  }, [ophalenAfbeeldingen]);

  const handleVerwijderenLeverancier = React.useCallback(
    async (typeID: number, inkDienstID: number) => {
      if (
        (
          await checkStore.bevestigen({
            inhoud: `Wil je deze inkoopgegevens verwijderen?`,
          })
        ).type === EResultType.Annuleren
      ) {
        return;
      }

      await api.v2.inkoop.aanbod.verwijderenInkoopgegevens({
        typeID,
        inkDienstID,
      });

      ophalenLeveranciers();
    },
    [],
  );

  const kolommen = useMemo<TypedColumn<IOphalenLeveranciersVoorProductResultElement>[]>(() => {
    return [
      {
        name: 'LeveranciernaamKort',
        title: 'Leverancier',
      },
      {
        name: 'Prioriteit',
        title: 'Prio.',
      },
      {
        name: 'Inkoopprijs',
        title: 'Ink.prijs',
      },
      {
        name: 'InkoopprijsActie',
        title: 'Actieprijs',
      },
      {
        name: 'ActieMinimaalAantal',
        title: 'Actie #',
      },
      {
        name: 'DirectLeverbaar',
        title: 'Leverbaar',
      },
    ];
  }, []);

  const kolomBreedtes = useMemo<
    TypedTableColumnWidthInfo<IOphalenLeveranciersVoorProductResultElement>[]
  >(() => {
    return [
      {
        columnName: 'LeveranciernaamKort',
        width: 175,
      },
      {
        columnName: 'Prioriteit',
        width: 75,
      },
      {
        columnName: 'Inkoopprijs',
        width: 115,
      },
      {
        columnName: 'InkoopprijsActie',
        width: 115,
      },
      {
        columnName: 'ActieMinimaalAantal',
        width: 115,
      },
      {
        columnName: 'DirectLeverbaar',
        width: 125,
      },
    ];
  }, []);

  const kolomExtensies: VirtualTableBase.ColumnExtension[] = useMemo(() => {
    return [
      // { columnName: 'Inkoopprijs', align: 'right' },
      // { columnName: 'InkoopprijsActie', align: 'right' },
    ];
  }, []);

  const keyExtractor = useCallback(
    (row: IOphalenLeveranciersVoorProductResultElement) => row.ID,
    [],
  );

  const { uploadProgresses, bestanden, isBezigMetUploaden, muteerBestanden } = useUpload(
    useMemo(
      () => ({
        initieleBestanden:
          afbeeldingenResult.state === ERemoteDataState.Pending
            ? null
            : afbeeldingenResult.data!.bestanden.map((bestand) => ({
                type: BestandType.ASPDrive as BestandType.ASPDrive,
                bestand: bestand.bestand,
              })),
        automatischUploaden: true,
        onAlleBestandenGeuploaded: async (bestIDs: number[]) => {
          await api.v2.inkoop.aanbod.muterenAfbeeldingenProducttype({
            typeID: row.TypeID,
            bestIDs,
          });
        },
      }),
      [row.TypeID, afbeeldingenResult],
    ),
  );

  return (
    <div className="flex-fill d-flex">
      <TabelInspringBlok />
      <div className="flex-fill">
        <div>
          {leveranciers === null ? (
            <LoadingSpinner />
          ) : (
            <GridStyleWrapper maxHeight={200} rowAmount={leveranciers.length}>
              <Grid
                columns={kolommen as Column[]}
                rows={leveranciers || []}
                getRowId={keyExtractor}
              >
                {/* <DataTypeProvider
              for={['Rekeninghouder']}
              formatterComponent={(props) => <span>{JSON.stringify(props.value)}</span>}
            /> */}

                <DataTypeProvider
                  for={['Inkoopprijs']}
                  formatterComponent={(props) => (
                    <span>
                      <span>
                        {props.value !== null ? (
                          <FormatteerBedrag bedrag={props.value} />
                        ) : (
                          'Niet opgegeven'
                        )}
                      </span>
                    </span>
                  )}
                />

                <DataTypeProvider
                  for={['InkoopprijsActie']}
                  formatterComponent={(props) => (
                    <span>
                      {props.value !== null ? <FormatteerBedrag bedrag={props.value} /> : ''}
                    </span>
                  )}
                />

                <DataTypeProvider
                  for={['DirectLeverbaar']}
                  formatterComponent={(props) => <span>{props.value ? 'Ja' : 'Nee'}</span>}
                />

                <DataTypeProvider
                  for={['Prioriteit']}
                  formatterComponent={(props) => <span>{props.value ? 'Ja' : 'Nee'}</span>}
                />

                <SortingState defaultSorting={[]} />
                <EditingState
                  onAddedRowsChange={() => {
                    setToevoegenTonen(true);
                  }}
                  onEditingRowIdsChange={(rowIds) => {
                    const id = rowIds[rowIds.length - 1] as number;
                    const rij: IOphalenLeveranciersVoorProductResultElement = leveranciers.find(
                      (x) => x.ID === id,
                    )!;

                    setWijzigenID({ typeID: rij.TypeID, inkDienstID: rij.InkDienstID });
                  }}
                  onCommitChanges={async (changes) => {
                    if (changes.deleted === undefined) {
                      return;
                    }
                    const deleted = changes.deleted;
                    const id = deleted[deleted.length - 1] as number;
                    const rij: IOphalenLeveranciersVoorProductResultElement = leveranciers.find(
                      (x) => x.ID === id,
                    )!;
                    await handleVerwijderenLeverancier(rij.TypeID, rij.InkDienstID);
                  }}
                />
                <IntegratedSorting />
                <VirtualTable
                  estimatedRowHeight={10}
                  columnExtensions={kolomExtensies}
                  messages={tableMessages}
                />
                <TableColumnResizing
                  defaultColumnWidths={kolomBreedtes as TableColumnWidthInfo[]}
                />

                <TableHeaderRow showSortingControls />
                <TableEditColumn
                  width={65}
                  showEditCommand
                  showAddCommand
                  showDeleteCommand
                  cellComponent={DXTableEditColumnCellComponent}
                  commandComponent={DXTableEditColumnCommandComponent}
                />
              </Grid>
            </GridStyleWrapper>
          )}
        </div>

        <HorizontaleScheidingslijn color={Kleur.LichtGrijs} />

        <div className="col-12 d-flex flex-column mt-2">
          <div className="flex-fill d-flex flex-column p-2">
            <div className="d-flex align-items-center mb-2">
              <h5 className="mr-4" style={{ position: 'relative', top: 2 }}>
                Productafbeeldingen
              </h5>
              <BijlageKnop
                disabled={
                  isBezigMetUploaden || afbeeldingenResult.state === ERemoteDataState.Pending
                }
                onBijgevoegd={(bestanden) => muteerBestanden((x) => [...x, ...bestanden])}
                toegestaneBestandstypes={[
                  {
                    weergaveNaam: 'Afbeelding',
                    mediaType: 'image/*',
                  },
                ]}
              />
            </div>
            <div className="mt-1 flex-fill d-flex flex-column">
              <BijlagenContainer
                bestanden={bestanden}
                bestandenMuterenToegestaan={!isBezigMetUploaden}
                uploadProgresses={uploadProgresses}
                onBestandenChange={(bestanden) => muteerBestanden((_) => bestanden)}
                weergaveFocus={EWeergaveFocus.Visualisatie}
                herordenenToestaan
              />
            </div>
          </div>
        </div>
      </div>
      {toevoegenTonen && (
        <ToevoegenInkoopgegevensDialoog
          open
          TypeID={row.TypeID}
          onSuccess={() => {
            setToevoegenTonen(false);
            ophalenLeveranciers();
            productContext.verversenProduct();
          }}
          onAnnuleren={() => {
            setToevoegenTonen(false);
          }}
        />
      )}
      {wijzigenID !== null && (
        <WijzigenInkoopgegevensDialoog
          open
          TypeID={wijzigenID.typeID}
          InkDienstID={wijzigenID.inkDienstID}
          onSuccess={() => {
            setWijzigenID(null);
            ophalenLeveranciers();
            productContext.verversenProduct();
          }}
          onAnnuleren={() => {
            setWijzigenID(null);
          }}
        />
      )}
    </div>
  );
};

export default RowDetailComp;
