import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../stores/RootStore';
import { Root } from './style';
import KlantInformatieSectie from '../../../components/kaart/KlantInformatieSectie';
import LoadingSpinner from '../../../components/Gedeeld/LoadingSpinner';
import Navigatie from './Navigatie';
import Entiteiten from './Entiteiten';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { RouterContext } from '../../../routers/RouterContextProvider';
import { EKaartType } from '../../../components/kaart/root';
import api from '../../../api';
import { IOphalenLijstRecentResult } from '../../../../../shared/src/api/v2/relatie/Selectie';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
} from '../../../models/IRemoteData';
import { IOphalenRelatiesResultElementV2 } from '../../../../../shared/src/api/v2/relatie';
import { Helmet } from 'react-helmet';
import { formatteerOrganisatieNaam, formatteerPersoonNaam } from '../../../helpers';

interface IProps extends RouteComponentProps {
  relID: number;
}

const Klantkaart: React.FC<IProps> = observer((props) => {
  const { klantkaartStore, gebruikerStore } = useContext(RootStoreContext);
  const { navigeerKlantkaartNaarRelID } = useContext(RouterContext);

  const [recenteReferenties, setRecenteReferenties] = useState<IOphalenLijstRecentResult | null>(
    null,
  );

  const ophalenRecenteReferenties = useCallback<
    () => Promise<IOphalenLijstRecentResult>
  >(async () => {
    return await api.v2.relatieZoeken.ophalenLijstRecent({
      aspGebrID: gebruikerStore.gebruiker!.AspGebrID,
      filterSchema: {
        filters: [
          {
            naam: 'HOEDANIGHEID_NAAM_ENUMS',
            data: ['KLANT'],
          },
        ],
      },
      paginatie: {
        index: 0,
        aantal: 25,
      },
    });
  }, []);

  useEffect(() => {
    (async () => {
      const result = await ophalenRecenteReferenties();
      setRecenteReferenties(result);

      // Als er minimaal een recente referentie is en we zitten nog niet bij een relatie
      if (result.relaties.length > 0 && props.relID === null) {
        await navigeerKlantkaartNaarRelID(result.relaties[0].RelID);
      }
    })();
  }, []);

  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    klantkaartStore.ophalenRelatie(props.relID);

    (async () => {
      await api.v2.relatieZoeken.bijwerkenLijstRecent({
        relID: props.relID,
        aspGebrID: null,
        hoedanigheid: 'KLANT',
      });
    })();
  }, [props.relID]);

  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    klantkaartStore.ophalenRelatie(props.relID);
  }, [props.relID]);

  const [zoekterm, setZoekterm] = useState('');

  const relatie = useMemo<IRemoteData<IOphalenRelatiesResultElementV2>>(() => {
    if (klantkaartStore.relatie === null || klantkaartStore.relatie.RelID !== props.relID) {
      return createPendingRemoteData();
    }
    return createReadyRemoteData(klantkaartStore.relatie);
  }, [props.relID, klantkaartStore.relatie]);

  const handleVerversRelatie = useCallback(async () => {
    await klantkaartStore.verversRelatie();
  }, [klantkaartStore.verversRelatie]);

  return (
    <Root>
      {klantkaartStore.relatie! === null ||
      klantkaartStore.relatie!.RelID === -1 ||
      klantkaartStore.debiteurInformatie === null ? (
        <div className="flex-fill d-flex align-items-center justify-content-center p-2">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          <div className="d-flex flex-fill">
            <div className="d-flex flex-fill">
              <KlantInformatieSectie
                relID={props.relID}
                relatie={klantkaartStore.relatie!}
                debiteurInformatie={klantkaartStore.debiteurInformatie}
                type={EKaartType.Klant}
                onVerversenAangevraagd={() => klantkaartStore.verversRelatie()}
                hoedanigheid={'KLANT'}
                recenteReferenties={recenteReferenties?.relaties ?? null}
                ophalenRecenteReferenties={ophalenRecenteReferenties}
                zoekterm={zoekterm}
                onZoektermChange={setZoekterm}
                navigeerNaarRelID={navigeerKlantkaartNaarRelID}
              />
              <Navigatie relatie={klantkaartStore.relatie!} />
              <Entiteiten
                relID={props.relID}
                relatie={relatie}
                verversRelatie={handleVerversRelatie}
              />
            </div>
          </div>
        </>
      )}
    </Root>
  );
});

export default withRouter(Klantkaart);
