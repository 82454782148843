import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { IOphalenProductsoortenResultElement } from '../../../../../../shared/src/api/v2/product/soort';
import { IOphalenPremiumsResultElement } from '../../../../../../shared/src/api/v2/transport/opdracht/premium';
import api from '../../../../api';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import { EFunctioneleIcon, functioneleIconMap } from '../../../../components/Icons';
import MenuLayout from '../../../../components/MenuLayout';
import useUrlState from '../../../../core/useUrlState';
import { EResultType } from '../../../../stores/CheckStore';
import { RootStoreContext } from '../../../../stores/RootStore';
import ToevoegenDialoog from './ToevoegenDialoog';
import WijzigenDialoog from './WijzigenDialoog';
import { ASPKolom, EAspKolomBreedteType } from '../../../../components/tabel/ASPTabel/types';
import ASPTabel from '../../../../components/tabel/ASPTabel';
import BijlagenKolomWeergave from '../../../../components/BijlagenKolomWeergave';
import { ESelectieModus } from '../../../../components/BijlageKnop';
import { Helmet } from 'react-helmet';

enum EKolom {
  Naam,
  NaamKort,
  Producttype,
  Criterium,
  Actief,
  OpWebsite,
  Bijlage,
}

interface IProps extends RouteComponentProps {}

export interface IRegel extends IOphalenProductsoortenResultElement {}

interface IWijzigenDialoogState {
  id: number;
}

interface IToevoegenDialoogState {}

interface IUrlState {
  selectie: number[];
  wijzigDialoogState: IWijzigenDialoogState | null;
  toevoegenDialoogState: IToevoegenDialoogState | null;
}
const defaultUrlState: IUrlState = {
  selectie: [],
  wijzigDialoogState: null,
  toevoegenDialoogState: null,
};

const messages = {
  noData: 'Geen premiums',
};
const IconToevoegen = functioneleIconMap[EFunctioneleIcon.Toevoegen];

const Premiums: React.FC<IProps> = (props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const { checkStore } = useContext(RootStoreContext);

  const [premiums, setPremiums] = useState<IOphalenPremiumsResultElement[] | null>(null);

  const ophalenPremiums = useCallback(async () => {
    const result = await api.v2.transport.opdracht.premium.ophalenPremiums({
      filterSchema: { filters: [] },
      orderSchema: { orders: [{ naam: 'NAAM', richting: 'ASC' }] },
    });

    setPremiums(result.premiums);
  }, []);

  useEffect(() => {
    ophalenPremiums();
  }, [ophalenPremiums]);

  const handleVerwijderen = useCallback(async (id: number) => {}, []);

  const keyExtractor = useCallback((row: IOphalenPremiumsResultElement) => row.ID, []);

  const kolommen = useMemo<ASPKolom<EKolom, IOphalenPremiumsResultElement>[]>(
    () => [
      {
        key: EKolom.Naam,
        label: 'Naam',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 200,
        renderer: (row: IOphalenPremiumsResultElement) => row.Naam,
      },
      {
        key: EKolom.NaamKort,
        label: 'Korte naam',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 125,
        renderer: (row: IOphalenPremiumsResultElement) => row.NaamKort,
      },
      {
        key: EKolom.Producttype,
        label: 'Producttype',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 200,
        renderer: (row: IOphalenPremiumsResultElement) => row.producttype?.Typenaam,
      },
      {
        key: EKolom.Criterium,
        label: 'Criterium',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 200,
        renderer: (row: IOphalenPremiumsResultElement) => {
          switch (row.Criterium) {
            case 1: {
              return 'Atijd meegeven';
            }
            case 2: {
              return 'Alleen bij bestelling';
            }
          }
          return null;
        },
      },
      {
        key: EKolom.Actief,
        label: 'Actief',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 90,
        renderer: (row: IOphalenPremiumsResultElement) => (row.Actief ? 'Ja' : 'Nee'),
      },
      {
        key: EKolom.OpWebsite,
        label: 'Op website',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 110,
        renderer: (row: IOphalenPremiumsResultElement) => (row.OpWebsite ? 'Ja' : 'Nee'),
      },
      {
        key: EKolom.Bijlage,
        label: 'Bijlage',
        breedteType: EAspKolomBreedteType.Flex,
        flex: 1,
        renderer: (row: IOphalenPremiumsResultElement) => (
          <BijlagenKolomWeergave
            bijlagen={row.Afbeelding_Bestand === null ? [] : [row.Afbeelding_Bestand]}
            onBestandIDsChange={async (bestIDs) => {
              const bestID = bestIDs[0];

              await api.v2.transport.opdracht.premium.wijzigenPremium({
                id: row.ID,
                naam: row.Naam,
                naamKort: row.NaamKort,
                naamEnum: row.NaamEnum,
                typeID: row.producttype?.TypeID ?? null,
                criterium: row.Criterium,
                opWebsite: row.OpWebsite,
                actief: row.Actief,
                afbeelding_BestandID: bestID,
              });
              await ophalenPremiums();
            }}
            selectiemodus={ESelectieModus.Enkel}
          />
        ),
      },
    ],
    [ophalenPremiums],
  );

  const rijen = useMemo<Record<number, IOphalenPremiumsResultElement>>(() => {
    if (premiums === null) {
      return {};
    }

    return premiums.reduce((acc, row, i) => {
      return {
        ...acc,
        [i]: row,
      };
    }, {});
  }, [premiums]);

  const handleWijzigenRij = useCallback(async (rij: IOphalenPremiumsResultElement) => {
    setUrlStateSync('wijzigDialoogState', {
      id: rij.ID,
    });
  }, []);

  const verwijderenRijConfirmatie = useCallback(
    async (rij: IOphalenPremiumsResultElement, verwijderen: () => Promise<void>) => {
      const checkData = await api.v2.transport.opdracht.premium.checkVerwijderenPremiums({
        ids: [rij.ID],
      });
      if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
        return;
      }
      if (
        (
          await checkStore.bevestigen({
            inhoud: `Wil je deze premium verwijderen?`,
          })
        ).type === EResultType.Annuleren
      ) {
        return;
      }

      await verwijderen();
    },
    [],
  );

  const handleVerwijderenRij = useCallback(
    async (rij: IOphalenPremiumsResultElement) => {
      await api.v2.transport.opdracht.premium.verwijderenPremiums({
        ids: [rij.ID],
      });

      await ophalenPremiums();
    },
    [ophalenPremiums],
  );

  return (
    <>
      <Helmet>
        <title>Premiums</title>
      </Helmet>
      <MenuLayout
        menu={
          <>
            <div className="mt-2 d-flex align-items-center">
              <button
                className="btn btn-sm btn-light d-flex align-items-center"
                style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                onClick={() => setUrlStateSync('toevoegenDialoogState', {})}
              >
                <span>
                  <IconToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                </span>
                <span className="ml-2">Toevoegen premium</span>
              </button>

              {/* <FilterBalkV2
                filters={filters}
                filterData={urlState.filterdata}
                onFilterDataChange={(x) => setUrlStateSync('filterdata', x)}
                onFilterSchemaChange={setFilterSchema}
              /> */}
            </div>
          </>
        }
        body={
          <ASPTabel
            rijen={rijen}
            kolommen={kolommen}
            keyExtractor={keyExtractor}
            onWijzigenRij={handleWijzigenRij}
            onVerwijderenRij={handleVerwijderenRij}
            verwijderenRijConfirmatie={verwijderenRijConfirmatie}
            rijHoogte={200}
          />
        }
      />
      {urlState.toevoegenDialoogState !== null && (
        <ToevoegenDialoog
          open
          onSuccess={() => {
            ophalenPremiums();
            setUrlStateSync('toevoegenDialoogState', null);
          }}
          onAnnuleren={() => [setUrlStateSync('toevoegenDialoogState', null)]}
        />
      )}
      {urlState.wijzigDialoogState !== null && (
        <WijzigenDialoog
          open
          id={urlState.wijzigDialoogState.id}
          onSuccess={() => {
            ophalenPremiums();
            setUrlStateSync('wijzigDialoogState', null);
          }}
          onAnnuleren={() => [setUrlStateSync('wijzigDialoogState', null)]}
        />
      )}
    </>
  );
};

export default withRouter(Premiums);
