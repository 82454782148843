import { useEffect } from 'react';
import { ERemoteDataState } from '../models/IRemoteData';

/**
 * Controleert of de geselecteerde items ook nog echt voorkomen binnen de mogelijke element ids
 * Als dit niet het geval is, geef dan de ids terug die wel voorkomen binnen de lijst
 * @param elementIds
 * @param selectieIds
 * @returns De nieuwe selectie waarde of `null` als de huidige selectie geldig is
 */
export const selectieCheck = <T extends any>(elementIds: T[], selectieIds: T[]): T[] | null => {
  if (selectieIds.every((selectieId) => elementIds.includes(selectieId))) {
    return null;
  }

  return selectieIds.filter((selectieId) => elementIds.includes(selectieId));
};

// export interface IUseSelectieCheckParams<T> {
//   elementIds: T[] | null;
//   selectieIds: T[];
//   onSelectieChange: (selectieIds: T[]) => void;
// }
//
// export const useSelectieCheck = <T extends any>(params: IUseSelectieCheckParams<T>) => {
//   useEffect(() => {
//     if (params.elementIds === null) {
//       return;
//     }
//
//     const result = selectieCheck(params.elementIds, params.selectieIds);
//     if (result !== null) {
//       params.onSelectieChange(result);
//     }
//   }, [params.elementIds, params.selectieIds, params.onSelectieChange]);
// };
