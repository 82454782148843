import React, { useCallback, useContext, useMemo } from 'react';
import { IOphalenBetalingsregelingenResultElement } from '../../../../../shared/src/api/v2/debiteur/betalingsregeling';
import {
  DXTableCheckboxComponent,
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
  DXTableToggleCellComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../helpers/dxTableGrid';
import {
  Grid,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  DataTypeProvider,
  EditingState,
  IntegratedSorting,
  RowDetailState,
  SelectionState,
  SortingState,
  VirtualTable as VirtualTableBase,
} from '@devexpress/dx-react-grid';
import RelatieVisualisatie from '../../personalia/RelatieVisualisatie';
import { format } from 'date-fns';
import FormatteerBedrag from '../../MutatieBedrag';
import api from '../../../api';
import { EResultType } from '../../../stores/CheckStore';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../stores/RootStore';
import WijzigenDialoog from './WijzigenDialoog';
import DetailComp from './DetailComp';
import LoadingSpinner from '../../Gedeeld/LoadingSpinner';
import { IconBoeking, IconInformatie, IconSend, IconToevoegen } from '../../Icons';
import { Kleur } from '../../../bedrijfslogica/constanten';
import {
  EBetalingsregelingStatus,
  EDagboeknaam,
  EDagboeksoort,
  EGrootboekrekeningNaam,
} from '../../../bedrijfslogica/enums';
import nameOf from '../../../core/nameOf';
import { EHoedanigheid } from '../../personalia/RelatieSelectieDialoog';
import BoekingInfoDialoog from '../../relatie/BoekingInfoDialoog';
import BetalingsregelingInfoDialoog from '../BetalingsregelingInfoDialoog';
import { IOphalenBoekingRegelsResultElement } from '../../../../../shared/src/api/v2/boekhouding/boeking';
import _ from 'lodash';

const geenData = {
  noData: 'Geen dossiers gevonden',
};

export interface IWijzigenBetalingsregelingDialoogState {
  ID: number;
}

export interface IBoekingInfoDialoogState {
  relID: number;
}
export interface IBetalingsregelingInfoDialoogState {
  id: number;
}

export interface IBetalingsregeling extends IOphalenBetalingsregelingenResultElement {
  boekingsregels: IOphalenBoekingRegelsResultElement[];
}

export enum EKolomnaam {
  TeBoeken,
  Relatie,
  ReedsVoldaan,
}

interface IProps {
  betalingsregelingen: IBetalingsregeling[] | null;
  selectie: number[];
  onSelectieChange: (selectie: number[]) => void;

  boekingInfoDialoogState: IBoekingInfoDialoogState | null;
  onWijzigenBoekingInfoDialoogState: (state: IBoekingInfoDialoogState | null) => void;

  betalingsregelingInfoDialoogState: IBetalingsregelingInfoDialoogState | null;
  onWijzigenBetalingsregelingInfoDialoogState: (
    state: IBetalingsregelingInfoDialoogState | null,
  ) => void;

  wijzigenBetalingsregelingDialoogState: IWijzigenBetalingsregelingDialoogState | null;
  onWijzigenBetalingsregelingDialoogStateChange: (
    state: IWijzigenBetalingsregelingDialoogState | null,
  ) => void;

  onVerversenAangevraagd: () => void;
  onRelatieClick?: (relID: number) => void;
  menuComponent?: React.ComponentType;
  nietWeerTeGevenKolommen?: EKolomnaam[];
}

const BetalingsregelingTabel: React.FC<IProps> = observer((props) => {
  const { checkStore } = useContext(RootStoreContext);
  const keyExtractor = useCallback((rij: IBetalingsregeling) => rij.ID, []);

  const kolommen = useMemo<TypedColumn<IBetalingsregeling>[]>(
    () =>
      [
        {
          name: 'Datum',
          title: 'Datum',
        },
        {
          name: 'Referentie',
          title: 'Ref.',
        },
        {
          name: '__info' as any,
          title: ' ',
        },
        props.nietWeerTeGevenKolommen === undefined ||
        props.nietWeerTeGevenKolommen.indexOf(EKolomnaam.Relatie) === -1
          ? {
              name: '__relatie' as any,
              title: 'Debiteur',
              getCellValue: (x: any) => {
                return x.weergavenaam;
              },
            }
          : null,
        {
          name: 'Totaalbedrag',
          title: 'Tot.bedrag',
        },
        {
          name: 'AantalTermijnen',
          title: '# Trm',
        },
        {
          name: 'Termijnbedrag',
          title: 'Termijn',
        },
        {
          name: 'EersteVervaldatum',
          title: '1e Verv.datum',
        },
        {
          name: '__laatsteBetaling' as any,
          title: 'Ltst. betaling',
        },
        {
          name: '__status' as any,
          title: 'Status',
        },
        props.nietWeerTeGevenKolommen === undefined ||
        props.nietWeerTeGevenKolommen.indexOf(EKolomnaam.ReedsVoldaan) === -1
          ? {
              name: '__reedsVoldaan' as any,
              title: 'Reeds voldaan',
            }
          : null,
        {
          name: '__nogVoldoen' as any,
          title: 'Nog voldoen',
        },
        props.nietWeerTeGevenKolommen === undefined ||
        props.nietWeerTeGevenKolommen.indexOf(EKolomnaam.TeBoeken) === -1
          ? {
              name: '__teBoeken' as any,
              title: 'Te boeken',
            }
          : null,
        {
          name: 'Afgehandeld',
          title: 'Afgeh.',
        },
        // {
        //   name: '__boekingInfo' as any,
        //   title: 'Boekinfo',
        // },
      ].filter((x) => x !== null) as TypedColumn<IBetalingsregeling>[],
    [props.nietWeerTeGevenKolommen],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IBetalingsregeling>[]>(
    () => [
      {
        columnName: 'Datum',
        width: 100,
      },
      {
        columnName: 'Referentie',
        width: 90,
      },
      {
        columnName: '__relatie' as any,
        width: 200,
      },
      {
        columnName: 'Totaalbedrag',
        width: 105,
      },
      {
        columnName: 'AantalTermijnen',
        width: 75,
      },
      {
        columnName: 'Termijnbedrag',
        width: 90,
      },
      {
        columnName: '__restbedrag' as any,
        width: 115,
      },
      {
        columnName: 'BetaalwijzeNaam',
        width: 125,
      },
      {
        columnName: 'EersteVervaldatum',
        width: 130,
      },
      {
        columnName: '__status' as any,
        width: 125,
      },
      {
        columnName: '__reedsVoldaan' as any,
        width: 130,
      },
      {
        columnName: '__nogVoldoen' as any,
        width: 130,
      },
      {
        columnName: '__teBoeken' as any,
        width: 130,
      },
      {
        columnName: '__laatsteBetaling' as any,
        width: 130,
      },
      {
        columnName: 'Afgehandeld',
        width: 90,
      },
      // {
      //   columnName: '__boekingInfo' as any,
      //   width: 110,
      // },
      {
        columnName: '__info' as any,
        width: 60,
      },
    ],
    [],
  );

  const kolomExtensies: VirtualTableBase.ColumnExtension[] = useMemo(() => {
    return [
      // {
      //   columnName: `AantalTermijnen`,
      //   align: 'right',
      // },
      // {
      //   columnName: `Totaalbedrag`,
      //   align: 'right',
      // },
      // {
      //   columnName: `Termijnbedrag`,
      //   align: 'right',
      // },
      // {
      //   columnName: `__restbedrag`,
      //   align: 'right',
      // },
    ];
  }, []);

  const handleVerwijderen = useCallback(async (ID: number) => {
    const params = { betRglIDs: [ID] };
    const checkData = await api.v2.debiteur.betalingsregeling.checkVerwijderenBetalingsregelingen(
      params,
    );

    if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
      return;
    }

    if (
      (
        await checkStore.bevestigen({
          inhoud: `Wil je de betalingsregeling verwijderen?`,
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    await api.v2.debiteur.betalingsregeling.verwijderenBetalingsregelingen(params);

    props.onSelectieChange([]);
    props.onVerversenAangevraagd();
  }, []);

  const handleBankopdrachten = useCallback(async (id: number) => {
    const params = { betRglIDs: [id] };

    const checkData = await api.v2.debiteur.betalingsregeling.checkMakenBankopdrachten(params);

    if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
      return;
    }
    if (
      (
        await checkStore.bevestigen({
          inhoud: `Wil je incasso-opdrachten maken voor de geselecteerde betalingsregeling?`,
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    await api.v2.debiteur.betalingsregeling.makenBankopdrachten(params);

    // props.onSelectieChange([]);
    props.onVerversenAangevraagd();
  }, []);

  const handleVersturenBetalingsregeling = useCallback(async (id: number) => {
    const params = { id };

    // const checkData = await api.v2.debiteur.betalingsregeling.checkVersturenBetalingsregeling(params);

    // if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
    //   return;
    // }
    if (
      (
        await checkStore.bevestigen({
          inhoud: (
            <span>
              Wil je betalingsregeling naar de klant versturen?
              <br />
              De status Concept wordt dan gewijzigd in Lopend.
            </span>
          ),
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    await api.v2.debiteur.betalingsregeling.versturenBetalingsregeling(params);

    // props.onSelectieChange([]);
    props.onVerversenAangevraagd();
  }, []);

  const handleBoekingenMaken = useCallback(async (ids: number[]) => {
    const params = { betRglIDs: ids };

    const checkData = await api.v2.boekhouding.boeking.checkBoekenVerkoopfacturenInBetalingsregeling(
      params,
    );

    if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
      return;
    }

    if (
      (
        await checkStore.bevestigen({
          inhoud: (
            <span>
              Hiermee worden de facturen voor de geselecteerde betalingsregelingen afgeboekt met het
              beschikbare af te boeken bedrag.
              <br />
              <br />
              Dit kan hier niet ongedaan gemaakt worden.
            </span>
          ),
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    await api.v2.boekhouding.boeking.boekenVerkoopfacturenInBetalingsregeling(params);

    // props.onSelectieChange([]);
    props.onVerversenAangevraagd();
  }, []);

  const Menu = useMemo<React.ComponentType>(
    () =>
      props.menuComponent ??
      ((p) => {
        return <div className="d-flex">{p.children}</div>;
      }),
    [props.menuComponent],
  );

  return (
    <>
      {props.betalingsregelingen === null ? (
        <div className="d-flex flex-fill align-items-center justify-content-center pt-4">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          <div
            className="d-flex flex-column p-3"
            style={{
              backgroundColor: Kleur.HeelLichtGrijs,
              borderBottom: `1px solid ${Kleur.LichtGrijs}`,
            }}
          >
            <Menu>
              <button
                className="btn btn-sm btn-light d-flex align-items-center"
                style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                disabled={props.selectie.length !== 1}
                onClick={() => handleVersturenBetalingsregeling(props.selectie[0])}
              >
                <IconSend style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                <span className="ml-2">Bevestiging versturen</span>
              </button>
              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-2"
                style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                disabled={props.selectie.length !== 1}
                onClick={() => handleBankopdrachten(props.selectie[0])}
              >
                <IconToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                <span className="ml-2">Bankopdrachten maken</span>
              </button>
              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-2"
                style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                disabled={props.selectie.length === 0}
                onClick={() => handleBoekingenMaken(props.selectie)}
              >
                <IconBoeking style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                <span className="ml-2">Boekingen maken</span>
              </button>
            </Menu>
          </div>
          <GridStyleWrapper height={'calc(100vh - 100px)'}>
            <Grid rows={props.betalingsregelingen} getRowId={keyExtractor} columns={kolommen}>
              <DataTypeProvider
                for={['__relatie']}
                formatterComponent={(formatterProps) => {
                  const row: IBetalingsregeling = formatterProps.row;
                  return (
                    <RelatieVisualisatie
                      relID={row.RelID!}
                      // weergaveNaam={row.weergavenaam}
                      relatieLinkBuilder={(hoedanigheid, relID) =>
                        `/${
                          hoedanigheid === EHoedanigheid.Klant ? 'klant' : 'leverancier'
                        }/${relID}/debiteur/betalingsregelingen`
                      }
                    />
                  );
                }}
              />

              <DataTypeProvider
                for={['Datum']}
                formatterComponent={(formatterProps) => {
                  const rij: IBetalingsregeling = formatterProps.row;
                  if (rij.Datum === null) {
                    return <span />;
                  }
                  return <span>{format(new Date(rij.Datum), 'dd-MM-yyyy')}</span>;
                }}
              />

              <DataTypeProvider
                for={['Totaalbedrag']}
                formatterComponent={(formatterProps) => {
                  const rij: IBetalingsregeling = formatterProps.row;
                  return <FormatteerBedrag bedrag={rij.Totaalbedrag} />;
                }}
              />

              <DataTypeProvider
                for={['Termijnbedrag']}
                formatterComponent={(formatterProps) => {
                  const rij: IBetalingsregeling = formatterProps.row;
                  return <FormatteerBedrag bedrag={rij.Termijnbedrag} />;
                }}
              />

              <DataTypeProvider
                for={['__restbedrag']}
                formatterComponent={(formatterProps) => {
                  const rij: IBetalingsregeling = formatterProps.row;
                  const restbedrag =
                    rij.Totaalbedrag - rij.Termijnbedrag * (rij.AantalTermijnen - 1);
                  const restbedragAfgerond = Math.round(restbedrag * 100) / 100;

                  if (restbedragAfgerond === rij.Termijnbedrag) {
                    return <span></span>;
                  }
                  return <FormatteerBedrag bedrag={restbedrag} />;
                }}
              />

              <DataTypeProvider
                for={['EersteVervaldatum']}
                formatterComponent={(formatterProps) => {
                  const rij: IBetalingsregeling = formatterProps.row;
                  if (rij.EersteVervaldatum === null) {
                    return <span />;
                  }
                  return <span>{format(new Date(rij.EersteVervaldatum), 'dd-MM-yyyy')}</span>;
                }}
              />

              <DataTypeProvider
                for={['__status']}
                formatterComponent={(formatterProps) => {
                  const rij: IBetalingsregeling = formatterProps.row;
                  if (rij.StatusNaamEnum === EBetalingsregelingStatus.GeheelNagekomen) {
                    return <span style={{ color: Kleur.Groen }}>{rij.StatusNaam}</span>;
                  }
                  if (rij.StatusNaamEnum === EBetalingsregelingStatus.DeelsNagekomen) {
                    return <span style={{ color: Kleur.Oranje }}>{rij.StatusNaam}</span>;
                  }
                  if (rij.StatusNaamEnum === EBetalingsregelingStatus.NietNagekomen) {
                    return <span style={{ color: Kleur.Rood }}>{rij.StatusNaam}</span>;
                  }
                  return <span>{rij.StatusNaam}</span>;
                }}
              />

              <DataTypeProvider
                for={['__reedsVoldaan']}
                formatterComponent={(formatterProps) => {
                  const rij: IBetalingsregeling = formatterProps.row;

                  const boekingsregels = rij.boekingsregels.filter((x) => x.BetRglID === rij.ID);
                  const bedragBankmutaties = -_.sum(
                    boekingsregels
                      .filter(
                        (x) =>
                          x.boeking.dagboek.dagboekSoort.NaamEnum === EDagboeksoort.BANK ||
                          (x.boeking.dagboek.NaamEnum !== null &&
                            x.boeking.dagboek.NaamEnum === EDagboeknaam.BUCKAROO_BETALINGEN),
                      )
                      .map((x) => x.Bedrag),
                  );

                  return <FormatteerBedrag bedrag={bedragBankmutaties} />;
                }}
              />

              <DataTypeProvider
                for={['__nogVoldoen']}
                formatterComponent={(formatterProps) => {
                  const rij: IBetalingsregeling = formatterProps.row;

                  const boekingsregels = rij.boekingsregels.filter((x) => x.BetRglID === rij.ID);
                  const bedragBankmutaties = -_.sum(
                    boekingsregels
                      .filter(
                        (x) =>
                          x.boeking.dagboek.dagboekSoort.NaamEnum === EDagboeksoort.BANK ||
                          (x.boeking.dagboek.NaamEnum !== null &&
                            x.boeking.dagboek.NaamEnum === EDagboeknaam.BUCKAROO_BETALINGEN),
                      )
                      .map((x) => x.Bedrag),
                  );

                  const bedrag = rij.Totaalbedrag - bedragBankmutaties;

                  return <FormatteerBedrag bedrag={bedrag} />;
                }}
              />

              <DataTypeProvider
                for={['__laatsteBetaling']}
                formatterComponent={(formatterProps) => {
                  const rij: IBetalingsregeling = formatterProps.row;

                  const boekingsregels = rij.boekingsregels.filter((x) => x.BetRglID === rij.ID);

                  const meestRecenteBoekdatumVoorBank =
                    _.max(
                      boekingsregels
                        .filter(
                          (x) =>
                            x.boeking.dagboek.dagboekSoort.NaamEnum === EDagboeksoort.BANK ||
                            (x.boeking.dagboek.NaamEnum !== null &&
                              x.boeking.dagboek.NaamEnum === EDagboeknaam.BUCKAROO_BETALINGEN),
                        )
                        .map((x) => x.boeking.Boekdatum),
                    ) ?? null;

                  return (
                    <span>
                      {meestRecenteBoekdatumVoorBank !== null
                        ? format(new Date(meestRecenteBoekdatumVoorBank), 'dd-MM-yyyy')
                        : null}
                    </span>
                  );
                }}
              />

              <DataTypeProvider
                for={['__teBoeken']}
                formatterComponent={(formatterProps) => {
                  const rij: IBetalingsregeling = formatterProps.row;

                  const teBoekenBedrag =
                    -1 *
                    (_.sum(
                      rij.boekingsregels
                        .filter(
                          (x) =>
                            x.BetRglID === rij.ID &&
                            x.dbRekening.NaamEnum ===
                              EGrootboekrekeningNaam.DebiteurenBetalingsregeling,
                        )
                        .map((x) => x.Bedrag),
                    ) +
                      _.sum(
                        rij.boekingsregels
                          .filter(
                            (x) =>
                              x.BetRglID === rij.ID &&
                              x.crRekening.NaamEnum ===
                                EGrootboekrekeningNaam.DebiteurenBetalingsregeling,
                          )
                          .map((x) => -x.Bedrag),
                      ));

                  return <FormatteerBedrag bedrag={teBoekenBedrag} />;
                }}
              />

              <DataTypeProvider
                for={['__boekingInfo']}
                formatterComponent={(formatterProps) => {
                  const rij: IBetalingsregeling = formatterProps.row;

                  return (
                    <a
                      href="#"
                      onClick={() => props.onWijzigenBoekingInfoDialoogState({ relID: rij.RelID })}
                    >
                      <IconInformatie style={{ width: 17, height: 17, fill: Kleur.Blauw }} />
                    </a>
                  );
                }}
              />

              <DataTypeProvider
                for={['__info']}
                formatterComponent={(formatterProps) => {
                  const rij: IBetalingsregeling = formatterProps.row;

                  return (
                    <a
                      href="#"
                      onClick={() =>
                        props.onWijzigenBetalingsregelingInfoDialoogState({ id: rij.ID })
                      }
                    >
                      <IconInformatie style={{ width: 17, height: 17, fill: Kleur.Blauw }} />
                    </a>
                  );
                }}
              />

              <DataTypeProvider
                for={[nameOf<IBetalingsregeling>('Afgehandeld')]}
                formatterComponent={(formatterProps) => {
                  return <span>{formatterProps.value ? 'Ja' : 'Nee'}</span>;
                }}
              />

              <EditingState
                onCommitChanges={async (changes) => {
                  if (changes.deleted === undefined) {
                    return;
                  }
                  const deleted = changes.deleted;
                  const id = deleted[deleted.length - 1] as number;
                  await handleVerwijderen(id);
                }}
                onEditingRowIdsChange={(rowIds) => {
                  const id = rowIds[rowIds.length - 1] as number;
                  props.onWijzigenBetalingsregelingDialoogStateChange({
                    ID: id,
                  });
                }}
              />
              {/* <SortingState
              defaultSorting={[
                {
                  columnName: '__relatie',
                  direction: 'desc',
                },
              ]}
            /> */}
              <SortingState defaultSorting={[]} />
              <IntegratedSorting />

              <RowDetailState defaultExpandedRowIds={[]} />

              <VirtualTable messages={geenData} columnExtensions={kolomExtensies} />
              <TableColumnResizing defaultColumnWidths={kolomBreedtes} />

              <TableEditColumn
                width={65}
                showEditCommand
                showDeleteCommand
                cellComponent={DXTableEditColumnCellComponent}
                commandComponent={DXTableEditColumnCommandComponent}
              />

              <TableHeaderRow showSortingControls />
              <TableRowDetail
                contentComponent={DetailComp}
                toggleCellComponent={DXTableToggleCellComponent}
              />
              <SelectionState
                selection={props.selectie}
                onSelectionChange={(x) => props.onSelectieChange(x as number[])}
              />
              <TableSelection cellComponent={DXTableCheckboxComponent} />
            </Grid>
          </GridStyleWrapper>
        </>
      )}
      {props.wijzigenBetalingsregelingDialoogState !== null && (
        <WijzigenDialoog
          open
          betRglID={props.wijzigenBetalingsregelingDialoogState.ID}
          onSuccess={async () => {
            props.onWijzigenBetalingsregelingDialoogStateChange(null);
            props.onVerversenAangevraagd();
          }}
          onAnnuleren={() => props.onWijzigenBetalingsregelingDialoogStateChange(null)}
        />
      )}
      {props.boekingInfoDialoogState !== null && (
        <BoekingInfoDialoog
          open
          relID={props.boekingInfoDialoogState.relID}
          onSuccess={async () => {
            props.onWijzigenBoekingInfoDialoogState(null);
            props.onVerversenAangevraagd();
          }}
          onAnnuleren={() => props.onWijzigenBoekingInfoDialoogState(null)}
        />
      )}
      {props.betalingsregelingInfoDialoogState !== null && (
        <BetalingsregelingInfoDialoog
          open
          id={props.betalingsregelingInfoDialoogState.id}
          onSuccess={async () => {
            props.onWijzigenBetalingsregelingInfoDialoogState(null);
          }}
          onAnnuleren={() => props.onWijzigenBetalingsregelingInfoDialoogState(null)}
        />
      )}
    </>
  );
});

export default BetalingsregelingTabel;
