import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import * as Yup from 'yup';
import IDialoogProps from '../../../../../../../../core/IDialoogProps';
import { IOphalenFeestdagenResultElement } from '../../../../../../../../../../shared/src/api/v2/feestdag';
import api from '../../../../../../../../api';
import LoadingSpinner from '../../../../../../../../components/Gedeeld/LoadingSpinner';
import Dialoog from '../../../../../../../../components/dialogen/Dialoog';
import DatumKiezer from '../../../../../../../../components/formulier/DatumKiezer';
import FormikVeldFout from '../../../../../../../../components/formulier/FormikVeldFout';
import nameOf from '../../../../../../../../core/nameOf';
import { dagDatum } from '../../../../../../../../helpers/datum';
import { IOphalenFactuurkenmerkenResultElement } from '../../../../../../../../../../shared/src/api/v2/factuur';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
} from '../../../../../../../../models/IRemoteData';
import { RootStoreContext } from '../../../../../../../../stores/RootStore';
import { EResultType } from '../../../../../../../../stores/CheckStore';

interface IProps extends IDialoogProps<null> {
  id: number | null;
  relID: number;
}

export interface IFormikValues {
  naam: string;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  naam: 'Naam',
};

const MutatieDialoog: React.FC<IProps> = (props) => {
  const { checkStore } = useContext(RootStoreContext);
  const { onAnnuleren, onSuccess, open, dialoogIndex } = props;

  const [factuurkenmerk, setFactuurkenmerk] = useState<
    IRemoteData<IOphalenFactuurkenmerkenResultElement>
  >(createPendingRemoteData());

  const ophalenFactuurkenmerk = useCallback(async () => {
    if (props.id === null) {
      return;
    }

    const result = await api.v2.factuur.rubriek.factuurkenmerk.ophalenFactuurkenmerken({
      filterSchema: { filters: [{ naam: 'IDS', data: [props.id] }] },
    });

    setFactuurkenmerk(createReadyRemoteData(result.factuurkenmerken[0]));
  }, [props.id]);

  useEffect(() => {
    ophalenFactuurkenmerk();
  }, [ophalenFactuurkenmerk]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      if (props.id !== null) {
        const params = {
          factuurkenmerk: {
            ID: props.id,
            naam: values.naam,
            relID: props.relID,
          },
        };

        const checkData = await api.v2.factuur.rubriek.factuurkenmerk.checkWijzigenFactuurkenmerk(
          params,
        );
        if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
          actions.setSubmitting(false);
          return;
        }

        await api.v2.factuur.rubriek.factuurkenmerk.wijzigenFactuurkenmerk(params);
      } else {
        const params = {
          factuurkenmerk: {
            naam: values.naam,
            relID: props.relID,
          },
        };

        const checkData = await api.v2.factuur.rubriek.factuurkenmerk.checkToevoegenFactuurkenmerk(
          params,
        );
        if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
          actions.setSubmitting(false);
          return;
        }

        await api.v2.factuur.rubriek.factuurkenmerk.toevoegenFactuurkenmerk(params);
      }
      onSuccess(null);
    },
    [],
  );

  const initialValues = useMemo<IFormikValues>(() => {
    console.log(factuurkenmerk);
    if (props.id !== null && factuurkenmerk !== null && factuurkenmerk.data?.Naam != null) {
      return {
        naam: factuurkenmerk.data.Naam,
      };
    }

    return {
      naam: '',
    };
  }, [props.id, factuurkenmerk]);

  const validationSchema = useMemo(() => {
    const fields: Record<keyof IFormikValues, any> = {
      naam: Yup.string().required(),
    };
    return Yup.object().shape(fields);
  }, []);

  return (
    <Dialoog index={dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>{props.id !== null ? 'Wijzigen' : 'Toevoegen'} factuurkenmerk</ModalTitle>
      </ModalHeader>
      <ModalBody>
        {props.id !== null && factuurkenmerk === null ? (
          <LoadingSpinner />
        ) : (
          <Formik<IFormikValues>
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            render={(formikProps: FormikProps<IFormikValues | null>) => {
              const { submitForm, isSubmitting, isValid, values } = formikProps;
              return (
                <>
                  <ModalBody>
                    <div className="row">
                      <div className="col-12 mb-3">
                        <label>{veldnamen.naam}</label>
                        <Field
                          name={nameOf<IFormikValues>('naam')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            return <input {...field} className="form-control" type="text" />;
                          }}
                        />
                      </div>
                    </div>
                  </ModalBody>
                  <ModalFooter className="d-flex justify-content-start">
                    <button
                      className="btn btn-primary"
                      onClick={submitForm}
                      disabled={isSubmitting || !isValid}
                      style={{ width: 100 }}
                    >
                      Ok
                    </button>
                    <button
                      className="btn btn-secondary"
                      onClick={onAnnuleren}
                      style={{ width: 100 }}
                    >
                      Annuleren
                    </button>
                  </ModalFooter>
                </>
              );
            }}
          />
        )}
      </ModalBody>
    </Dialoog>
  );
};

export default MutatieDialoog;
