import styled from 'styled-components';
import { Kleur } from '../../../../../bedrijfslogica/constanten';

export const Root = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  flex: 1;

  .geadresseerden {
    display: flex;
    //justify-content: center;
    align-items: center;
    //background-color: rgba(211, 211, 211, 0.15);
    border-bottom: 1px solid ${Kleur.LichtGrijs};
    //padding-left: 10px;
    flex-wrap: wrap;
    flex: 1;

    .geadresseerde {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 7px;
      margin-right: 3px;
      font-size: 13px;
      border-radius: 5px;
      background-color: ${Kleur.DoorschijnendLichtBlauw};
      color: ${Kleur.DonkerGrijs};

      button {
        margin-left: 5px;
        padding: 0;
        border: 0;
        outline: 0;
        background-color: transparent;
      }
    }
  }
`;
