import React from 'react';
import { IconInformatie } from '../Icons';
import { Kleur } from '../../bedrijfslogica/constanten';

interface IProps {
  onClick: () => void;
  style?: React.CSSProperties;
  iconStyle?: React.CSSProperties;
}

const InfoKnop = (props: IProps) => {
  return (
    <button
      onClick={props.onClick}
      style={{
        background: 0,
        border: 0,
        padding: 0,
        margin: 0,
        outline: 0,
        ...props.style,
      }}
    >
      <IconInformatie style={{ fill: Kleur.Blauw, width: 15, height: 15, ...props.iconStyle }} />
    </button>
  );
};

export default InfoKnop;
