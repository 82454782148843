import React, { useMemo } from 'react';
import { IActieProps } from '../index';
import { RouteComponentProps, withRouter } from 'react-router';
import api from '../../../api';
import { genereerUrlStateQueryParam } from '../../../core/useUrlState';
// import { IUrlState as KlantkaartServiceUrlState } from '../../Klant/Klantkaart/Entiteiten/entiteiten/Service/Melding';
import { ITransportOpdrachtenUitstaandUrlState as TransportopdrachtenUrlState } from '../../Transport/Opdrachten/Uitstaand';

interface IData {
  trsOpdID: number;
}

const BEZOEKTIJD_NIET_GEHAALD: React.VoidFunctionComponent<IActieProps> = (props: IActieProps) => {
  const { controltaak } = props;
  const data = useMemo(() => JSON.parse(controltaak.Data!) as IData, [controltaak.Data]);
  return (
    <a
      href="#"
      onClick={async () => {
        const state = genereerUrlStateQueryParam<TransportopdrachtenUrlState>({
          opdrachtenSelectie: [data.trsOpdID],
          uitgeklapteOpdrachten: [],
          regelsSelectie: [],
          opdrachtEventsDialoogState: null,
          afmeldenDialoogState: null,
          bezoektijdenDialoogState: null,
          versturenOpdrachtenDialoogState: null,
          wijzigenOpdrachtDialoogState: null,
          versturenHerinneringenDialoogState: null,
          transportopdrachtInfoDialoogState: null,
          filterData: null,
        });
        // props.history.push(`/service/meldingen?state=${state}`);

        props.history.push(`/transport/opdrachten/uitstaand?state=${state}`);
      }}
    >
      Ga naar transportopdracht
    </a>
  );
};

export default BEZOEKTIJD_NIET_GEHAALD;
