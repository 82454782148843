import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { BankmutatiesContext } from '../index';
import ResizeObserver from 'react-resize-observer';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import MutatiesTabel from './MutatiesTabel';
import {
  IOphalenDuplicaatMutatiesResult,
  IOphalenMutatiesResult,
  IOphalenMutatiesStatusData,
  IVerwijderenMutatiesParams,
} from '../../../../../../shared/src/api/v2/bank/mutaties';
import api from '../../../../api';
import { Kleur as EKleur } from '../../../../bedrijfslogica/constanten';
import { IconPijlVolgend, IconToevoegen } from '../../../../components/Icons';
import { EResultType } from '../../../../stores/CheckStore';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../../stores/RootStore';
import BestandenDragAndDropDialoog from '../../../../components/BestandenDragAndDropDialoog';
import { EBestandDragAndDropZoneSelectieModus } from '../../../../components/BestandDragAndDropZone';
import GegevensLayout from '../../../../components/layout/GegevensLayout';
import FormatteerBedrag, {
  StandaardMutatieBedragOpmaker,
} from '../../../../components/MutatieBedrag';
import FilterBalkV2, {
  IFilter,
  IFilterData,
  maakFilterSchema,
} from '../../../../components/FilterBalkV2';
import Tegel from '../../../../components/tegel/Tegel';
import RadioKnop from '../../../../components/formulier/RadioKnop';
import Combobox from '../../../../components/formulier/Combobox';
import { IFilterSchema } from '../../../../../../shared/src/models/filter';
import SelectieVak from '../../../../components/SelectieVak';
import { format } from 'date-fns';
import { Helmet } from 'react-helmet';
import { AutoSizer } from 'react-virtualized';

export enum EFilter {
  Status = 'STATUS',
}

interface IProps {}

const ConceptTabblad: React.FC<IProps> = observer((props) => {
  const { checkStore } = useContext(RootStoreContext);
  const bankmutatiesContext = useContext(BankmutatiesContext);
  const {
    selectie,
    onSelectieChange,
    uitgeklapt,
    onUitgeklaptChange,
    gekozenRekening,
    onVerversenAangevraagd,
    inlezenMutatiesState,
    onInlezenMutatiesStateChange,
    bepalenSaldoMutatiesResult,
    laatsteInleesdatum,
    conceptFilterData,
    onConceptFilterDataChange,
  } = bankmutatiesContext;

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.Status,
        altijdWeergevenInBalk: true,
        actiefMuteerbaar: false,
        weergave: (weergaveProps) => {
          const data = weergaveProps.data as IOphalenMutatiesStatusData;

          const setStatusCode = (statusCode: IOphalenMutatiesStatusData['inleesStatusCode']) => {
            weergaveProps.onDataChange({
              ...data,
              inleesStatusCode: statusCode,
            } as IOphalenMutatiesStatusData);
            weergaveProps.toepassen();
          };

          return (
            <div className="d-flex align-items-center">
              <span>Status</span>
              <div className="ml-2">
                <Combobox<'ALLES' | 'CONCEPT' | 'DEFINITIEF'>
                  geselecteerd={data.inleesStatusCode!}
                  opties={[
                    {
                      id: 'ALLES',
                      label: 'Alles',
                    },
                    {
                      id: 'CONCEPT',
                      label: 'Concept',
                    },
                    {
                      id: 'DEFINITIEF',
                      label: 'Definitief',
                    },
                  ]}
                  onSelectieChange={(code) => setStatusCode(code!)}
                  disabled={data.status !== 'STATUS'}
                />
              </div>
            </div>
          );
        },
      },
    ],
    [],
  );
  const [filterSchema, setFilterSchema] = useState(
    maakFilterSchema(
      useMemo(() => [...conceptFilterData], [
        // filterdata,
        // bankmutatiesContext.filterdata,
      ]),
    ),
  );

  const [mutatiesResult, setMutatiesResult] = useState<IOphalenMutatiesResult | null>(null);
  const [
    duplicaatMutaties,
    setDuplicaatMutaties,
  ] = useState<IOphalenDuplicaatMutatiesResult | null>(null);

  const ophalenMutaties = useCallback(async () => {
    const x = maakFilterSchema(bankmutatiesContext.filterdata);
    // console.log(filterData);
    const mergedFilterSchema: IFilterSchema = {
      filters: [...(filterSchema.filters || []), ...(x.filters || [])],
    };

    const result = await api.v2.bank.mutatie.ophalenMutaties({
      filterSchema: mergedFilterSchema,
      paginatie: {
        index: 0,
        aantal: 500,
      },
    });
    setMutatiesResult(result);

    const bankMutIDs = result.mutaties.map((x) => x.BankMutID);
    const duplicatenResult = await api.v2.bank.mutatie.ophalenDuplicaatMutaties({ bankMutIDs });
    setDuplicaatMutaties(duplicatenResult);
  }, [filterSchema, JSON.stringify(bankmutatiesContext.filterdata)]);
  useEffect(() => {
    ophalenMutaties();
  }, [ophalenMutaties]);

  const verversen = useCallback(async () => {
    await Promise.all([ophalenMutaties(), onVerversenAangevraagd()]);
  }, [ophalenMutaties, onVerversenAangevraagd]);

  const handleDoorvoeren = useCallback(async () => {
    const params = { bankRekID: gekozenRekening!.BankRekID };
    const checkData = await api.v2.bank.mutatie.checkDoorvoerenMutaties(params);
    const checkResult = await checkStore.controleren({ checkData });
    if (checkResult.type === EResultType.Annuleren) {
      return;
    }

    if (
      (
        await checkStore.bevestigen({
          inhoud: (
            <span>
              Conceptmutaties als Definitief markeren?
              <br />
              <br />
              Doe dit alleen indien het nieuwe saldo overeenkomt met wat bij de bank is aangegeven.
            </span>
          ),
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }
    await api.v2.bank.mutatie.doorvoerenMutaties(params);

    await verversen();
  }, [gekozenRekening, verversen]);

  const handleVerwijderen = useCallback(async () => {
    const params: IVerwijderenMutatiesParams = { bankMutIDs: selectie };
    const checkData = await api.v2.bank.mutatie.checkVerwijderenMutaties(params);
    if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
      return;
    }
    if (
      (await checkStore.bevestigen({ inhoud: 'Geselecteerde mutaties verwijderen?' })).type ===
      EResultType.Annuleren
    ) {
      return;
    }

    await api.v2.bank.mutatie.verwijderenMutaties(params);

    onSelectieChange([]);
    await verversen();
  }, [selectie, verversen]);

  return (
    <>
      <Helmet>
        <title>Bankmutaties Concept</title>
      </Helmet>
      <div className="d-flex flex-fill flex-column">
        <div className="p-2 pl-3 pr-3 d-flex">
          <div className="d-flex align-items-center">
            <button
              className="btn btn-sm btn-light d-flex align-items-center"
              style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
              onClick={() => onInlezenMutatiesStateChange({})}
            >
              <IconToevoegen style={{ width: 16, height: 16, fill: EKleur.Grijs }} />
              <span className="ml-2">Inlezen mutaties</span>
            </button>
            <button
              className="btn btn-sm btn-light d-flex align-items-center ml-3"
              style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
              onClick={handleVerwijderen}
              disabled={selectie.length === 0 || gekozenRekening === null}
            >
              <IconPijlVolgend style={{ width: 16, height: 16, fill: EKleur.Grijs }} />
              <span className="ml-2">Verwijderen geselecteerd</span>
            </button>
            <button
              className="btn btn-sm btn-light d-flex align-items-center ml-3"
              style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
              onClick={async () => {
                const mutaties = (
                  await api.v2.bank.mutatie.ophalenMutaties({
                    filterSchema: {
                      filters: [
                        { naam: 'BANK_REK_IDS', data: [gekozenRekening!.BankRekID] },
                        { naam: 'IS_DEFINITIEF', data: false },
                      ],
                    },
                  })
                ).mutaties;

                if (mutaties.length === 0) {
                  await checkStore.melden({
                    titel: `Er zijn geen mutaties om te verwijderen`,
                  });
                  return;
                }

                if (
                  (
                    await checkStore.bevestigen({
                      inhoud: <span>Alle ingelezen mutaties verwijderen?</span>,
                    })
                  ).type === EResultType.Annuleren
                ) {
                  return;
                }

                const params: IVerwijderenMutatiesParams = {
                  bankMutIDs: mutaties.map((x) => x.BankMutID),
                };
                await api.v2.bank.mutatie.verwijderenMutaties(params);

                onSelectieChange([]);
                await verversen();
              }}
              disabled={selectie.length !== 0 || gekozenRekening === null}
            >
              <IconPijlVolgend style={{ width: 16, height: 16, fill: EKleur.Grijs }} />
              <span className="ml-2">Alles verwijderen</span>
            </button>
            <button
              className="btn btn-sm btn-light d-flex align-items-center ml-3"
              style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
              onClick={handleDoorvoeren}
              disabled={selectie.length !== 0 || gekozenRekening === null}
            >
              <IconPijlVolgend style={{ width: 16, height: 16, fill: EKleur.Grijs }} />
              <span className="ml-2">Doorvoeren</span>
            </button>
          </div>

          <div className="d-flex align-items-center ml-4">
            {gekozenRekening !== null &&
              bepalenSaldoMutatiesResult !== null &&
              (bepalenSaldoMutatiesResult.saldo === 0 ? (
                <GegevensLayout
                  gegevens={[
                    {
                      label: 'Saldo',
                      waarde: <FormatteerBedrag bedrag={gekozenRekening.Saldo} />,
                    },
                  ]}
                />
              ) : (
                <div className="d-flex align-items-center">
                  <div>
                    <GegevensLayout
                      gegevens={[
                        {
                          label: 'Vorig Saldo',
                          waarde: <FormatteerBedrag bedrag={gekozenRekening.Saldo} />,
                        },
                      ]}
                    />
                  </div>
                  <div className="ml-3">
                    <GegevensLayout
                      gegevens={[
                        {
                          label: 'Nieuw Saldo',
                          waarde: (
                            <FormatteerBedrag
                              bedrag={bepalenSaldoMutatiesResult.saldo + gekozenRekening.Saldo}
                              opmaakComponent={(opmaakProps) => {
                                return (
                                  <StandaardMutatieBedragOpmaker
                                    {...opmaakProps}
                                    color={EKleur.Blauw}
                                  />
                                );
                              }}
                            />
                          ),
                        },
                      ]}
                    />
                  </div>
                  <div className="ml-3">
                    <GegevensLayout
                      gegevens={[
                        {
                          label:
                            bepalenSaldoMutatiesResult.saldo >= 0 ? (
                              <span style={{ color: EKleur.Groen }}>Bij</span>
                            ) : (
                              <span style={{ color: EKleur.Rood }}>Af</span>
                            ),
                          waarde: (
                            <FormatteerBedrag
                              bedrag={Math.abs(bepalenSaldoMutatiesResult.saldo)}
                              // opmaakComponent={(opmaakProps) => {
                              //   return (
                              //     <StandaardMutatieBedragOpmaker
                              //       {...opmaakProps}
                              //       color={
                              //         bepalenSaldoMutatiesResult.saldo >= 0
                              //           ? EKleur.Groen
                              //           : EKleur.Rood
                              //       }
                              //     />
                              //   );
                              // }}
                            />
                          ),
                        },
                      ]}
                    />
                  </div>
                  <div className="ml-3">
                    <GegevensLayout
                      gegevens={[
                        {
                          label: 'Aantal ingelezen',
                          waarde: <span>{bepalenSaldoMutatiesResult.aantalOpdrachten}</span>,
                        },
                      ]}
                    />
                  </div>
                </div>
              ))}

            {laatsteInleesdatum !== null && (
              <div className="ml-3">
                <GegevensLayout
                  gegevens={[
                    {
                      label: 'Ingelezen t/m',
                      waarde: format(new Date(laatsteInleesdatum), 'dd-MM-yyyy'),
                    },
                  ]}
                />
              </div>
            )}
          </div>

          <div className="flex-fill" />

          <div>
            <FilterBalkV2
              filterData={conceptFilterData}
              filters={filters}
              onFilterDataChange={(x) => onConceptFilterDataChange(x)}
              onFilterSchemaChange={(x) => setFilterSchema(x)}
            />
          </div>
        </div>

        {mutatiesResult !== null && (
          <div className="d-flex align-items-center ml-3 mb-2">
            <SelectieVak
              totaalAantal={mutatiesResult.mutaties.length}
              aantal={selectie.length}
              onChange={(allesGeselecteerd) => {
                if (allesGeselecteerd) {
                  onSelectieChange(mutatiesResult.mutaties.map((x) => x.BankMutID));
                } else {
                  onSelectieChange([]);
                }
              }}
            />
            {/* <span className="ml-2">Totaal {producten!.length} tarieven</span> */}
          </div>
        )}

        <div className="d-flex flex-fill justify-content-center align-items-center flex-column">
          {mutatiesResult === null || duplicaatMutaties === null ? (
            <LoadingSpinner />
          ) : (
            <div className="flex-fill d-flex flex-column">
              <AutoSizer className="flex-fill w-100 h-100">
                {(size) => (
                  <MutatiesTabel
                    mutaties={mutatiesResult.mutaties}
                    duplicaatMutaties={duplicaatMutaties}
                    height={size.height}
                    onVerversenAangevraagd={verversen}
                    selectie={selectie}
                    uitgeklapt={uitgeklapt}
                    onSelectieChange={onSelectieChange}
                    onUitgeklaptChange={onUitgeklaptChange}
                  />
                )}
              </AutoSizer>
            </div>
          )}
        </div>
      </div>
      {inlezenMutatiesState !== null && (
        <BestandenDragAndDropDialoog
          open
          onSuccess={async (result) => {
            if (result.bestanden.length !== 1) {
              await checkStore.melden({ titel: 'Er dient een bestand geselecteerd te worden' });
              return;
            }
            onInlezenMutatiesStateChange(null);

            const bestand = result.bestanden[0];
            const bestandImportResult = await api.v2.bank.mutatie.toevoegenBestandImport({
              bestandID: bestand.ID,
            });
            try {
              const result = await api.v2.bank.mutatie.inlezenBestandImport({
                id: bestandImportResult.bestandImport.ID,
              });

              if (result.checkData.warnings.length !== 0) {
                await checkStore.melden({
                  titel: `${result.checkData.warnings[0]}`,
                });
              }

              await verversen();

              // Haal de vastgelegde mutaties op
              const resultMutaties = (
                await api.v2.bank.mutatie.ophalenMutaties({
                  filterSchema: { filters: [{ naam: 'IDS', data: result.bankMutIDs }] },
                })
              ).mutaties;

              if (resultMutaties.length === 0) {
                await checkStore.melden({
                  titel: 'Er zijn geen mutaties gevonden in het mutatiebestand',
                });
                return;
              }

              // Rekening waarvoor de mutaties vastgelegd zijn
              const bankRekID = resultMutaties[0].BankRekID;

              const rekening = (
                await api.v2.bank.ophalenRekeningen({
                  filterSchema: { filters: [{ naam: 'IDS', data: [bankRekID] }] },
                })
              ).rekeningen[0];

              // Als er mutaties ingelezen zijn voor een andere bankrekening dan momenteel geselecteerd dan een melding geven

              if (gekozenRekening!.BankRekID !== bankRekID) {
                await checkStore.melden({
                  titel: (
                    <span>
                      Er zijn mutaties ingelezen voor en andere rekening ({rekening.NaamKort}) dan
                      thans geselecteerd&nbsp;
                    </span>
                  ),
                });
              }
            } catch (err) {
              await checkStore.melden({
                titel: `Het inlezen van het import bestand is mislukt.\nFoutmelding: ${err}`,
              });
              return;
            }
          }}
          onAnnuleren={() => onInlezenMutatiesStateChange(null)}
          toegestaneBestandstypen={[
            {
              mediaType: 'text/xml',
              weergaveNaam: 'XML',
            },
          ]}
          selectieModus={EBestandDragAndDropZoneSelectieModus.Enkel}
        />
      )}
    </>
  );
});

export default ConceptTabblad;
