import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Dialoog from '../../dialogen/Dialoog';
import IDialoogProps from '../../../core/IDialoogProps';
import LoadingSpinner from '../../Gedeeld/LoadingSpinner';
import api from '../../../api';
import { IOphalenOpdrachtenResultElement } from '../../../../../shared/src/api/v2/service/opdracht';
import _ from 'lodash';
import OpdrachtenComp from '../OpdrachtenComp';
import VeldWeergave from '../../formulier/VeldWeergave';
import GegevensLayout from '../../layout/GegevensLayout';
import { format } from 'date-fns';
import ModalHeader from 'react-bootstrap/ModalHeader';
import { ModalBody, ModalFooter, ModalTitle } from 'react-bootstrap';
import FormatteerBedrag from '../../MutatieBedrag';
import WerkbonnenComp from '../WerkbonnenComp';
import { maandenNaarJaren } from '../../../bedrijfslogica/teksten';
import TechnischeInformatieKnop from '../../TechnischeInformatieKnop';

interface IProps extends IDialoogProps {
  id: number;
}

const OpdrachtInfoDialoog: React.FC<IProps> = (props) => {
  const { dialoogIndex, onAnnuleren, onSuccess, open } = props;

  const [opdracht, setOpdracht] = useState<IOphalenOpdrachtenResultElement | null>(null);

  const ophalenOpdracht = useCallback(async () => {
    const opdracht = (
      await api.v2.service.ophalenOpdrachten({
        filterSchema: {
          filters: [{ naam: 'IDS', data: [props.id] }],
        },
      })
    ).opdrachten[0];

    setOpdracht(opdracht);
  }, [props.id]);

  useEffect(() => {
    ophalenOpdracht();
  }, [ophalenOpdracht]);

  return (
    <>
      <Dialoog index={dialoogIndex || 0} modalProps={{ size: 'lg' }}>
        <ModalHeader>
          <ModalTitle>Informatie serviceopdracht</ModalTitle>
        </ModalHeader>
        <ModalBody>
          {opdracht === null ? (
            <LoadingSpinner />
          ) : (
            <>
              <div className="row d-flex flex-column flex-fill">
                <div className="col-12">
                  <VeldWeergave>
                    <GegevensLayout
                      gegevens={[
                        {
                          label: 'Opdrachtnr',
                          waarde: opdracht.melding.Meldnummer + '-' + opdracht.Volgnummer,
                        },
                        {
                          label: 'Opdrachtdatum',
                          waarde: format(new Date(opdracht.DatumVerstuurd!), 'dd-MM-yyyy'),
                        },
                        {
                          label: 'Melddatum',
                          waarde: format(new Date(opdracht.melding.Melddatum), 'dd-MM-yyyy'),
                        },
                        null,
                        {
                          label: 'Servicedienst',
                          waarde: opdracht.dienst.NaamIdent!,
                        },
                        {
                          label: 'Klacht',
                          waarde: opdracht.Omschrijving,
                        },
                        {
                          label: 'Status',
                          waarde: opdracht.status.Naam,
                        },
                        {
                          label: 'Afgehandeld',
                          waarde: opdracht.Afgehandeld ? 'Ja' : 'Nee',
                        },
                      ]}
                    />
                  </VeldWeergave>
                </div>

                <div className="col-12 mt-3">
                  <VeldWeergave>
                    <GegevensLayout
                      gegevens={[
                        {
                          label: 'Merk/type',
                          waarde:
                            opdracht.melding.product !== null
                              ? opdracht.melding.product.producttype.Merknaam +
                                ' ' +
                                opdracht.melding.product.producttype.Typenaam
                              : '',
                        },
                        {
                          label: 'Categorie',
                          waarde:
                            opdracht.melding.product !== null
                              ? opdracht.melding.product.producttype.productsoort.Naam
                              : '',
                        },
                        {
                          label: 'Ref.code',
                          waarde:
                            opdracht.melding.product !== null
                              ? opdracht.melding.product.Referentiecode
                              : '',
                        },
                        {
                          label: 'Leeftijd',
                          waarde:
                            opdracht.melding.product !== null &&
                            opdracht.melding.product.leeftijd !== null
                              ? maandenNaarJaren(opdracht.melding.product.leeftijd)
                              : '',
                        },
                      ]}
                    />
                  </VeldWeergave>
                </div>

                <div className="col-12 mt-3">
                  <VeldWeergave>
                    <div className="row">
                      <div className="col-4">
                        <GegevensLayout
                          gegevens={[
                            {
                              label: 'Grensbedrag',
                              waarde: <FormatteerBedrag bedrag={opdracht.GrensbedragService} />,
                            },
                            {
                              label: 'Garantieclaim',
                              waarde: opdracht.Garantieclaim ? 'Ja' : 'Nee',
                            },
                            {
                              label: 'Spoed',
                              waarde: opdracht.Spoed ? 'Ja' : 'Nee',
                            },
                          ]}
                        />
                      </div>
                      <div className="col-8">
                        <GegevensLayout
                          gegevens={[
                            {
                              label: 'Telefoon',
                              waarde: opdracht.Telefoon,
                            },
                            {
                              label: 'Email',
                              waarde: opdracht.Email,
                            },
                          ]}
                        />
                      </div>
                    </div>
                  </VeldWeergave>
                </div>

                <div className="col-12 mt-3">
                  <VeldWeergave>
                    <GegevensLayout
                      gegevens={[
                        {
                          label: 'Uitv. monteursdienst',
                          waarde:
                            opdracht.uitvoerendeDienst !== null
                              ? opdracht.uitvoerendeDienst.relatie!.organisatie!.Naam
                              : '',
                        },
                        {
                          label: 'Telefoon',
                          waarde:
                            opdracht.uitvoerendeDienst !== null
                              ? opdracht.uitvoerendeDienst.relatie!.organisatie!.Telefoonnummer
                              : '',
                        },
                        {
                          label: 'Email',
                          waarde:
                            opdracht.uitvoerendeDienst !== null
                              ? opdracht.uitvoerendeDienst.relatie!.organisatie!.Email
                              : '',
                        },
                      ]}
                    />
                  </VeldWeergave>
                </div>
              </div>

              <div className="mt-3 mb-4">
                <WerkbonnenComp werkbonnen={opdracht.werkbonnen} />
              </div>
            </>
          )}
        </ModalBody>
        <ModalFooter className="d-flex flex-row justify-content-start">
          <button className="btn btn-primary" onClick={onAnnuleren} style={{ width: 100 }}>
            Sluiten
          </button>
          <div className="flex-fill" />
          <TechnischeInformatieKnop
            dialoogIndex={(props.dialoogIndex ?? 0) + 1}
            content={() => (
              <div>
                ServOpdID: {props.id}
                <br />
                ServMeldID: {opdracht?.melding.ID}
              </div>
            )}
          />
        </ModalFooter>
      </Dialoog>
    </>
  );
};

export default OpdrachtInfoDialoog;
