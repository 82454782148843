import React, { useState, useEffect, useCallback, useMemo, useContext } from 'react';
import api from '../../../../../../../api';
import LoadingSpinner from '../../../../../../../components/Gedeeld/LoadingSpinner';
import { observer } from 'mobx-react-lite';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import useUrlState from '../../../../../../../core/useUrlState';
import {
  IOphalenToegevoegdeRelatiesResultElement,
  IOphalenVervangenRelatiesResultElement,
} from '../../../../../../../../../shared/src/api/v2/relatie/duplicaat';
import { RootStoreContext } from '../../../../../../../stores/RootStore';
import {
  TypedColumn,
  TypedTableColumnWidthInfo,
  GridStyleWrapper,
  DXTableCheckboxComponent,
  DXTableToggleCellComponent,
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
} from '../../../../../../../helpers/dxTableGrid';
import { Kleur } from '../../../../../../../bedrijfslogica/constanten';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  VirtualTable,
  TableEditColumn,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  DataTypeProvider,
  EditingState,
  IntegratedSorting,
  RowDetailState,
  SelectionState,
  SortingState,
  VirtualTable as VirtualTableBase,
} from '@devexpress/dx-react-grid';
import { format } from 'date-fns';
import RelatieVisualisatie from '../../../../../../../components/personalia/RelatieVisualisatie';
import _ from 'lodash';
import MenuLayout from '../../../../../../../components/MenuLayout';
import { EResultType } from '../../../../../../../stores/CheckStore';
import RelatieSelectieDialoog, {
  ETabblad,
} from '../../../../../../../components/personalia/RelatieSelectieDialoog';
import { IconTerugdraaien } from '../../../../../../../components/Icons';

interface IProps extends RouteComponentProps {
  relID: number;
}

interface IUrlState {
  selectie: number[];
}
const defaultUrlState: IUrlState = {
  selectie: [],
};

const geenData = {
  noData: 'Geen vervangen relaties gevonden',
};

export interface IRow extends IOphalenVervangenRelatiesResultElement {
  Relatienummer: string;
}

export interface IRelatieSelecterenDialoogState {
  tabblad: ETabblad;
}

const VervangenComp: React.FC<IProps> = observer((props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState<IUrlState>(props, defaultUrlState);

  const { klantkaartStore, checkStore } = useContext(RootStoreContext);
  const [relaties, setRelaties] = useState<IRow[] | null>(null);

  const [
    relatieSelecterenDialoogState,
    setRelatieSelecterenDialoogState,
  ] = useState<IRelatieSelecterenDialoogState | null>(null);

  const ophalenVervangenRelaties = useCallback(async () => {
    const vervangenRelatiesResult = await api.v2.relatie.duplicaat.ophalenVervangenRelaties({
      relID: props.relID,
    });
    const relIDs = vervangenRelatiesResult.relIDs;
    const relatiesResult = await api.v2.relatie.ophalenRelaties({
      filterSchema: { filters: [{ naam: 'IDS', data: relIDs }] },
    });

    // Voeg relatie-gegevens er aan toe
    const relaties = vervangenRelatiesResult.relIDs.map((x: any) => {
      const relatie = relatiesResult.relaties.find((r) => r.RelID === x)!;
      return {
        RelID: x,
        Relatienummer: relatie.Relatienummer,
        // adres:
        //   relatie.adres !== null
        //     ? {
        //         Straatnaam: relatie.adres.Straatnaam,
        //         Huisnummer: relatie.adres.Huisnummer,
        //         Bisnummer: relatie.adres.Bisnummer,
        //         Postcode: relatie.adres.Postcode,
        //         Plaatsnaam: relatie.adres.Plaatsnaam,
        //         LandID: relatie.adres.LandID,
        //       }
        //     : null,
      };
    });

    // const relatiesGsorteerd = _.orderBy(relaties, ['Datum'], ['desc']);

    setRelaties(relaties);
  }, [klantkaartStore.relatie]);

  useEffect(() => {
    ophalenVervangenRelaties();
  }, [ophalenVervangenRelaties]);

  const keyExtractor = useCallback((row: IRow) => row.RelID, []);

  const kolommen = useMemo<TypedColumn<IRow>[]>(
    () => [
      {
        name: '__relatie' as any,
        title: 'Relatie die vervangen is',
      },
      {
        name: 'Relatienummer',
        title: 'Rel.nr.',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRow>[]>(
    () => [
      {
        columnName: '__relatie' as any,
        width: 350,
      },
      {
        columnName: 'Relatienummer',
        width: 90,
      },
    ],
    [],
  );

  return (
    <>
      {relaties === null ? (
        <LoadingSpinner />
      ) : (
        <>
          <MenuLayout
            menu={
              <div className="d-flex align-items-center">
                <button
                  className="btn btn-sm btn-light d-flex align-items-center ml-2"
                  style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                  onClick={async () => {
                    if (
                      (
                        await checkStore.bevestigen({
                          inhoud: (
                            <span>
                              De te selecteren relatie zal als duplicaat voor deze relatie
                              aangemerkt worden. Doorgaan?
                            </span>
                          ),
                        })
                      ).type === EResultType.Annuleren
                    ) {
                      return;
                    }

                    setRelatieSelecterenDialoogState({ tabblad: ETabblad.Recent });
                  }}
                >
                  {/* <IcoonToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} /> */}
                  <span className="ml-2">Selecteer te vervangen relatie</span>
                </button>

                <button
                  className="btn btn-sm btn-light d-flex align-items-center ml-2"
                  style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                  disabled={urlState.selectie.length === 0}
                  onClick={async () => {
                    if (
                      (
                        await checkStore.bevestigen({
                          inhoud: (
                            <span>
                              Wil je de vervanging voor de geselecteerde relatie(s) ingedaan maken?
                              <br />
                              <br />
                              Let op, eventueel toegevoegde entiteiten worden hiermee niet
                              teruggedraaid.
                            </span>
                          ),
                        })
                      ).type === EResultType.Annuleren
                    ) {
                      return;
                    }

                    await api.v2.relatie.duplicaat.verwijderenVervangenRelaties({
                      relIDs: urlState.selectie,
                    });

                    setUrlStateSync('selectie', []);
                    ophalenVervangenRelaties();
                  }}
                >
                  <IconTerugdraaien style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                  <span className="ml-2">Ongedaan maken vervanging</span>
                </button>

                {/* <div className="ml-2 flex-fill">{props.filterbalk}</div> */}
              </div>
            }
            body={
              <>
                <GridStyleWrapper height="calc(100vh - 150px)">
                  <Grid columns={kolommen} getRowId={keyExtractor} rows={relaties}>
                    <DataTypeProvider
                      for={['__relatie']}
                      formatterComponent={(formatterProps) => {
                        const rij = formatterProps.row;
                        return <RelatieVisualisatie relID={rij.RelID} />;
                      }}
                    />

                    {/* <RowDetailState defaultExpandedRowIds={[]} /> */}

                    <SortingState defaultSorting={[]} />
                    <IntegratedSorting />
                    <VirtualTable messages={geenData} />

                    {/* <TableEditColumn
                width={35}
                // showAddCommand={true}
                // showEditCommand
                cellComponent={DXTableEditColumnCellComponent}
                commandComponent={DXTableEditColumnCommandComponent}
              /> */}

                    <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
                    <TableHeaderRow showSortingControls />

                    {/* <RowDetailState defaultExpandedRowIds={[]} /> */}
                    {/* <TableRowDetail
                contentComponent={() => <span>Detailinfo</span>}
                toggleCellComponent={DXTableToggleCellComponent}
              /> */}

                    <SelectionState
                      selection={urlState.selectie}
                      onSelectionChange={(x) => setUrlStateSync('selectie', x as number[])}
                    />
                    <TableSelection cellComponent={DXTableCheckboxComponent} />
                  </Grid>
                </GridStyleWrapper>
              </>
            }
          />
          {/* <div
            className="d-flex flex-column p-3"
            style={{
              backgroundColor: Kleur.HeelLichtGrijs,
              borderBottom: `1px solid ${Kleur.LichtGrijs}`,
            }}
          >
            <div className="d-flex"></div>
          </div> */}
        </>
      )}

      {relatieSelecterenDialoogState !== null && (
        <RelatieSelectieDialoog
          open
          onSuccess={async (result) => {
            setRelatieSelecterenDialoogState(null);

            // Leg de 'suggestie' vast en registreer het duplicaat
            const suggestieResult = await api.v2.relatie.duplicaat.toevoegenDuplicaatSuggestie({
              relID: props.relID,
              duplicaten: [{ relID: result.relID, score: null }],
            });

            const params = {
              relDupSugID: suggestieResult.ID,
              vigerendeRelID: props.relID,
              vervangenRelIDs: [result.relID],
            };
            await api.v2.relatie.duplicaat.registreerDuplicaten(params);

            ophalenVervangenRelaties();
          }}
          onAnnuleren={() => setRelatieSelecterenDialoogState(null)}
          // koppelOpties={{
          //   hoedanigheid:
          //     props.type === EKaartType.Klant ? EHoedanigheid.Klant : EHoedanigheid.Leverancier,
          // }}
          defaultTabblad={relatieSelecterenDialoogState.tabblad}
        />
      )}
    </>
  );
});

export default withRouter(VervangenComp);
