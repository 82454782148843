import React from 'react';
import { IBestandslabel } from '../../../../../../shared/src/api/v2/bestand/label';
import Chip from '../../../Chip';
import { IconKruis } from '../../../Icons';
import { Kleur, Kleur as EKleur } from '../../../../bedrijfslogica/constanten';

interface IProps {
  label: IBestandslabel;
  onRemoveClick: () => Promise<void>;
}

const Bestandslabel = (props: IProps) => {
  const { label } = props;

  return (
    <Chip>
      <div
        style={{
          width: 10,
          height: 10,
          borderRadius: '50%',
          backgroundColor: label.Kleurcode ?? '#FFF',
          marginLeft: 3,
          border: `1px solid ${Kleur.LichtGrijs}`,
        }}
      />
      <span
        style={{
          marginLeft: 5,
          fontSize: 12,

          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        }}
      >
        {label.Naam}
      </span>
      <button
        className="ml-1"
        style={{
          outline: 0,
          border: 0,
          background: 0,
          padding: 0,
          position: 'relative',
          top: -2,
        }}
        onClick={props.onRemoveClick}
      >
        <IconKruis style={{ fill: Kleur.Grijs, width: 14, height: 14 }} />
      </button>
    </Chip>
  );
};

export default Bestandslabel;
