import { Redirect, Route, RouteComponentProps } from 'react-router';
import React from 'react';
import Gebruikers from './Gebruikers';
import RollenEnPermissies from './RollenEnPermissies';
import Instellingen from './Instellingen';
import Technisch from './Technisch';
import SEPALanden from './SEPALanden';

interface IProps extends RouteComponentProps<{}> {}

const Beheer: React.FC<IProps> = (props) => {
  const { match } = props;
  return (
    <>
      <Route path={`${match.path}/gebruikers`} component={Gebruikers} />
      <Route path={`${match.path}/sepalanden`} component={SEPALanden} />
      <Route path={`${match.path}/rollen-en-permissies`} component={RollenEnPermissies} />
      <Route path={`${match.path}/instellingen`} component={Instellingen} />
      <Route path={`${match.path}/technisch`} component={Technisch} />

      <Route
        exact
        path={match.path}
        render={() => <Redirect to={`${match.path}/instellingen`} />}
      />
    </>
  );
};

export default Beheer;
