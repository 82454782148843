import React from 'react';
import { Field, FieldProps } from 'formik';
import nameOf from '../../../../../../../../core/nameOf';
import DatumKiezer from '../../../../../../../../components/formulier/DatumKiezer';
import FormikVeldFout from '../../../../../../../../components/formulier/FormikVeldFout';
import VinkVeld from '../../../../../../../../components/formulier/VinkVeld';
import { IFormikValues, veldnamen } from '..';

const AlgemeenTabblad = () => {
  return (
    <div className="p-3">
      <div className="row justify-content-between"></div>
      <div className="row">
        <div className="col-4">
          <label>{veldnamen.ingangsdatum}</label>
          <Field
            name={nameOf<IFormikValues>('ingangsdatum')}
            render={({ field, form }: FieldProps<IFormikValues>) => {
              return (
                <div className="d-flex ">
                  <DatumKiezer
                    onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                    waarde={field.value}
                    determineValidDate={() => {
                      return true;
                    }}
                    determinePreviousValidDate="ONBEGRENST"
                    determineNextValidDate="ONBEGRENST"
                  />
                </div>
              );
            }}
          />
        </div>

        <div className="col-4">
          <label>{veldnamen.einddatum}</label>
          <Field
            name={nameOf<IFormikValues>('einddatum')}
            render={({ field, form }: FieldProps<IFormikValues>) => {
              return (
                <div className="d-flex ">
                  <DatumKiezer
                    onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                    waarde={field.value}
                    determineValidDate={() => {
                      return true;
                    }}
                    determinePreviousValidDate="ONBEGRENST"
                    determineNextValidDate="ONBEGRENST"
                    isClearable
                  />
                </div>
              );
            }}
          />
        </div>

        <div className="col-4" />

        <div className="col-12 mt-3">
          <label>{veldnamen.identificatiecode}</label>
          <Field
            name={nameOf<IFormikValues>('identificatiecode')}
            render={(fieldProps: FieldProps<IFormikValues>) => {
              const { field, form } = fieldProps;

              return <input {...field} className="form-control" type="text" />;
            }}
          />
        </div>

        <div className="col-12 mt-3">
          <label>{veldnamen.toegangscode}</label>
          <Field
            name={nameOf<IFormikValues>('toegangscode')}
            render={(fieldProps: FieldProps<IFormikValues>) => {
              const { field, form } = fieldProps;

              return <input {...field} className="form-control" type="text" />;
            }}
          />
        </div>

        <div className="col-12 mt-3">
          <label>{veldnamen.actiecode}</label>
          <Field
            name={nameOf<IFormikValues>('actiecode')}
            render={(fieldProps: FieldProps<IFormikValues>) => {
              const { field, form } = fieldProps;

              return (
                <>
                  <input {...field} className="form-control" type="text" />
                  <FormikVeldFout fieldProps={fieldProps} />
                </>
              );
            }}
          />
        </div>

        <div className="col-12 mt-3">
          <label>{veldnamen.token}</label>
          <Field
            name={nameOf<IFormikValues>('token')}
            render={(fieldProps: FieldProps<IFormikValues>) => {
              const { field, form } = fieldProps;

              return <input {...field} className="form-control" type="text" />;
            }}
          />
        </div>

        <div className="col-12 mt-3">
          <Field
            name={nameOf<IFormikValues>('actief')}
            render={(fieldProps: FieldProps<IFormikValues>) => {
              const { field, form } = fieldProps;
              return (
                <div className="d-flex align-items-center">
                  <VinkVeld
                    aangevinkt={field.value}
                    onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                  />
                  <span className="ml-2">{veldnamen.actief}</span>
                </div>
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default AlgemeenTabblad;
