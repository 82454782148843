import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Sidebar from './Sidebar';
import useUrlState from '../../../core/useUrlState';
import { RouteComponentProps } from 'react-router';
import VerticaleScheidingslijn from '../../../components/layout/VerticaleScheidingslijn';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../models/IRemoteData';
import LoadingSpinner from '../../../components/Gedeeld/LoadingSpinner';
import RolWeergave from './RolWeergave';
import { IOphalenRollenResult } from '../../../../../shared/src/api/v2/autorisatie/rol';
import api from '../../../api';
import { IOphalenPermissiesResult } from '../../../../../shared/src/api/v2/autorisatie/permissie';

interface IUrlState {
  actieveRolID: number | null;
}

interface IProps extends RouteComponentProps {}

const RollenEnPermissies = (props: IProps) => {
  const defaultUrlState = useMemo<IUrlState>(() => {
    return {
      actieveRolID: null,
    };
  }, []);
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const [rollenResult, setRollenResult] = useState<IRemoteData<IOphalenRollenResult>>(
    createPendingRemoteData(),
  );
  const ophalenRollen = useCallback(async () => {
    const result = await api.v2.autorisatie.rol.ophalenRollen({});
    setRollenResult(createReadyRemoteData(result));
  }, []);
  useEffect(() => {
    ophalenRollen();
  }, []);

  const [permissiesResult, setPermissiesResult] = useState<IRemoteData<IOphalenPermissiesResult>>(
    createPendingRemoteData(),
  );
  const ophalenPermissies = useCallback(async () => {
    const result = await api.v2.autorisatie.permissie.ophalenPermissies({});
    setPermissiesResult(createReadyRemoteData(result));
  }, []);
  useEffect(() => {
    ophalenPermissies();
  }, []);

  const verversen = useCallback(async () => {
    await Promise.all([ophalenRollen(), ophalenPermissies()]);
  }, [ophalenRollen, ophalenPermissies]);

  const rol = useMemo<any>(() => {
    if (rollenResult.state === ERemoteDataState.Pending || urlState.actieveRolID === null) {
      return null;
    }
    return rollenResult.data!.rollen.find((rol) => rol.ID === urlState.actieveRolID)!;
  }, [rollenResult, urlState.actieveRolID]);

  return (
    <div className="d-flex flex-fill">
      {rollenResult.state === ERemoteDataState.Pending ||
      permissiesResult.state === ERemoteDataState.Pending ? (
        <div className="d-flex flex-fill align-items-center justify-content-center">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          <Sidebar
            actieveRolID={urlState.actieveRolID}
            onActieveRolIDChange={(rolID) => setUrlStateSync('actieveRolID', rolID)}
            rollen={rollenResult.data!.rollen}
            onVerversenAangevraagd={verversen}
          />

          <div className="d-flex flex-column flex-fill">
            {rol === null ? (
              <div className="d-flex flex-fill align-items-center justify-content-center">
                <h3>Selecteer of voeg een nieuwe rol toe om te starten</h3>
              </div>
            ) : (
              <RolWeergave
                rol={rol}
                permissies={permissiesResult.data!.permissies}
                onVerversenAangevraagd={verversen}
                onActieveRolIDChange={(rolID) => setUrlStateSync('actieveRolID', rolID)}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default RollenEnPermissies;
