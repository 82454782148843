import React, { useCallback, useEffect, useMemo, useState, useContext } from 'react';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import { ITabbladProps } from '../../Aanbod';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  DXTableCheckboxComponent,
  DXTableToggleCellComponent,
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import useUrlState from '../../../../core/useUrlState';
// import { IOphalenOpdrachtenResultElement } from '../../../../../shared/src/api/v2/inkoop/opdracht/afgehandeld';
import { IOphalenOpdrachtenResultElement } from '../../../../../../shared/src/api/v2/inkoop/opdracht';
import { IOphalenMutatiesResultElement } from '../../../../../../shared/src/api/v2/magazijn/mutatie';
import api from '../../../../api';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  VirtualTable,
  TableEditColumn,
  Table,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  RowDetailState,
  SelectionState,
  EditingState,
  DataTypeProvider,
  SortingState,
  IntegratedSorting,
} from '@devexpress/dx-react-grid';
import { IconInformatie, IconVerwijderen } from '../../../../components/Icons';
import { EResultType } from '../../../../stores/CheckStore';
import { RootStoreContext } from '../../../../stores/RootStore';
import { format, addMonths } from 'date-fns';
import _ from 'lodash';
import DetailComp from '../DetailComp';
import FilterBalkV2, {
  IFilter,
  IFilterData,
  maakFilterSchema,
} from '../../../../components/FilterBalkV2';
import MultiCombobox from '../../../../components/formulier/MultiCombobox';
import { IFilterSchema } from '../../../../../../shared/src/models/filter';
import { IOphalenDienstenResultElement } from '../../../../../../shared/src/api/v2/dienst/inkoop';
import { IOphalenProducttypenResultElement } from '../../../../../../shared/src/api/v2/product/type';
import MenuLayout from '../../../../components/MenuLayout';
import nameOf from '../../../../core/nameOf';
import VoorraadTypeInfoDialoog from '../../../../components/transport/VoorraadTypeInfoDialoog';
import { Helmet } from 'react-helmet';

export enum EFilter {
  Inkoopdiensten = 'INKDIENST_IDS',
  Producttype = 'TYPE_IDS',
}

interface IProps extends ITabbladProps, RouteComponentProps {}

interface IUrlState {
  selectie: number[];
  filterData: IFilterData<EFilter>[];
}
const defaultUrlState: IUrlState = {
  selectie: [],
  filterData: [
    {
      naam: EFilter.Inkoopdiensten,
      data: [],
      isActief: false,
    },
    {
      naam: EFilter.Producttype,
      data: [],
      isActief: false,
    },
  ],
};

const geenData = {
  noData: 'Geen inkoopopdrachten',
};

export interface IRow extends IOphalenOpdrachtenResultElement {
  magazijnmutaties: IOphalenMutatiesResultElement[];
}

export interface IVoorraadTypeInfoDialoogState {
  typeID: number;
  magID: number;
}

const AfgehandeldTab: React.FC<IProps> = (props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState<IUrlState>(props, defaultUrlState);

  const { checkStore, instellingStore } = useContext(RootStoreContext);
  const [opdrachten, setOpdrachten] = useState<IRow[] | null>(null);

  const [
    voorraadTypeInfoDialoogState,
    setVoorraadTypeInfoDialoogState,
  ] = useState<IVoorraadTypeInfoDialoogState | null>(null);

  const [filterSchema, setFilterSchema] = useState<IFilterSchema>(
    useMemo(() => maakFilterSchema(urlState.filterData), []),
  );

  const keyExtractor = useCallback((row: IRow) => row.InkOpdID, []);

  const datumAfgehandeldVanaf = addMonths(new Date(), -30);

  const ophalenOpdrachten = useCallback(async () => {
    const result = await api.v2.inkoop.opdracht.ophalenOpdrachten({
      filterSchema: {
        filters: [
          ...filterSchema.filters!,
          { naam: 'IS_AFGEHANDELD', data: true },
          { naam: 'DATUM_AFGEHANDELD_VANAF', data: datumAfgehandeldVanaf },
        ],
      },
    });

    // Bijbehorende magazijnmutaties ophalen
    const inkOpdIDs = result.inkoopopdrachten.map((x) => x.InkOpdID);
    const mutatiesResult = await api.v2.magazijn.mutatie.ophalenMutaties({
      filterSchema: { filters: [{ naam: 'INKOPD_IDS', data: inkOpdIDs }] },
    });
    const inkoopopdrachten = result.inkoopopdrachten.map((x) => {
      return {
        ...x,
        magazijnmutaties: mutatiesResult.mutaties.filter(
          (m) => m.inkoopopdracht!.InkOpdID === x.InkOpdID,
        ),
      };
    });

    const opdrachtenGesorteerd = _.orderBy(
      inkoopopdrachten,
      [
        (x: IOphalenOpdrachtenResultElement): string | null => {
          const datum =
            x.DatumAfgehandeld !== null ? format(new Date(x.DatumAfgehandeld), 'yyyy-MM-dd') : null;

          return datum;
        },
      ],
      ['desc'],
    );

    setOpdrachten(opdrachtenGesorteerd);
  }, [filterSchema]);

  useEffect(() => {
    ophalenOpdrachten();
  }, [ophalenOpdrachten]);

  const [inkoopdiensten, setInkoopdiensten] = useState<IOphalenDienstenResultElement[] | null>(
    null,
  );

  useEffect(() => {
    (async () => {
      // Haal alle actieve inkoopdiensten op
      const inkoopdiensten = (
        await api.v2.dienst.inkoop.ophalenDiensten({
          filterSchema: { filters: [{ naam: 'IS_ACTIEF', data: true }] },
        })
      ).diensten;

      setInkoopdiensten(inkoopdiensten);
    })();
  }, []);

  const [producttypen, setProducttypen] = useState<IOphalenProducttypenResultElement[] | null>(
    null,
  );

  // Rijbron met producttypen
  useEffect(() => {
    (async () => {
      // Types die voorkomen in de weergegeven opdrachten

      const opdrachten = (
        await api.v2.inkoop.opdracht.ophalenOpdrachten({
          filterSchema: {
            filters: [
              { naam: 'IS_AFGEHANDELD', data: true },
              { naam: 'DATUM_AFGEHANDELD_VANAF', data: datumAfgehandeldVanaf },
            ],
          },
        })
      ).inkoopopdrachten;

      const typeIDs = _.uniq(
        opdrachten.filter((x) => x.producttype !== null).map((x) => x.producttype.TypeID),
      );

      const producttypen = (
        await api.v2.product.type.ophalenProducttypen({
          filterSchema: { filters: [{ naam: 'IDS', data: typeIDs }] },
          orderSchema: { orders: [{ naam: 'TYPENAAM', richting: 'ASC' }] },
        })
      ).producttypen;

      setProducttypen(producttypen);
    })();
  }, []);

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.Inkoopdiensten,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return (
            <div className="d-flex align-items-center">
              <span className="mr-2">Inkoopdienst</span>
              <MultiCombobox<number, IOphalenDienstenResultElement>
                sleutelExtractor={(x) => x.ID}
                representatieFabriek={(x) => x.relatie!.organisatie!.Naam}
                waarde={weergaveProps.data.length === 0 ? null : weergaveProps.data[0]}
                onWaardeChange={(x) => {
                  weergaveProps.onDataChange(x === null ? [] : [x]);
                  weergaveProps.setIsActief(true);
                  weergaveProps.toepassen();
                }}
                opties={inkoopdiensten}
                kolommen={[
                  {
                    key: '__naam' as any,
                    label: 'Naam',
                    breedte: 250,
                    formatFabriek: (rij) => rij.relatie!.organisatie!.Naam,
                  },
                ]}
                options={{
                  geenWaardeBericht: 'Kies een inkoopdienst',
                }}
              />
            </div>
          );
        },
      },
      {
        naam: EFilter.Producttype,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return (
            <div className="d-flex align-items-center">
              <span className="mr-2">Producttype</span>
              <MultiCombobox<number, IOphalenProducttypenResultElement>
                sleutelExtractor={(x) => x.TypeID}
                representatieFabriek={(x) => x.Typenaam}
                waarde={weergaveProps.data.length === 0 ? null : weergaveProps.data[0]}
                onWaardeChange={(x) => {
                  weergaveProps.onDataChange(x === null ? [] : [x]);
                  weergaveProps.setIsActief(true);
                  weergaveProps.toepassen();
                }}
                opties={producttypen}
                kolommen={[
                  {
                    key: 'Typenaam',
                    label: 'Type',
                    breedte: 200,
                  },
                  {
                    key: 'Merknaam',
                    label: 'Merk',
                    breedte: 135,
                  },
                ]}
                options={{
                  geenWaardeBericht: 'Kies een type',
                }}
              />
            </div>
          );
        },
      },
    ],
    [inkoopdiensten, producttypen],
  );

  const handleMarkerenUitstaand = useCallback(async () => {
    if (
      (
        await checkStore.bevestigen({
          inhoud: 'Inkoopopdrachten markeren als Uitstaand?',
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    await api.v2.inkoop.opdracht.afgehandeld.markerenUitstaand({
      inkOpdIDs: urlState.selectie,
    });

    setUrlStateSync('selectie', []);
    await ophalenOpdrachten();
  }, [urlState.selectie]);

  const kolommen = useMemo<TypedColumn<IRow>[]>(
    () => [
      {
        name: '__merknaam' as any,
        title: 'Merk',
        getCellValue: (x) => {
          return x.producttype.Merknaam;
        },
      },
      {
        name: '__typenaam' as any,
        title: 'Type',
        getCellValue: (x) => {
          return x.producttype.Typenaam;
        },
      },
      {
        name: '__productsoortnaamKort' as any,
        title: 'Cat.',
        getCellValue: (x) => {
          return x.producttype.ProductsoortnaamKort;
        },
      },
      {
        name: '__leveranciernaamKort' as any,
        title: 'Leverancier',
        getCellValue: (x) => {
          return x.dienst.relatie!.weergavenaam;
        },
      },
      {
        name: 'Aantal',
        title: 'Besteld',
      },
      {
        name: '__aantalGeleverdeProducten' as any,
        title: 'Geleverd',
      },
      {
        name: 'Referentie',
        title: 'Referentie',
      },
      // {
      //   name: '__laatsteInslag' as any,
      //   title: 'Laatste inslag',
      // },
      {
        name: 'DatumOpdracht',
        title: 'Opdrachtdatum',
      },
      {
        name: 'DatumAfgehandeld',
        title: 'Afgehandeld op',
      },
      {
        name: '__voorraadInfo' as any,
        title: 'Vrd.info',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRow>[]>(
    () => [
      {
        columnName: '__merknaam' as any,
        width: 115,
      },
      {
        columnName: '__typenaam' as any,
        width: 185,
      },
      {
        columnName: '__productsoortnaamKort' as any,
        width: 100,
      },
      {
        columnName: '__leveranciernaamKort' as any,
        width: 150,
      },
      {
        columnName: 'Aantal',
        width: 100,
      },
      {
        columnName: '__aantalGeleverdeProducten' as any,
        width: 100,
      },
      {
        columnName: 'Referentie',
        width: 150,
      },
      // {
      //   columnName: '__laatsteInslag' as any,
      //   width: 125,
      // },
      {
        columnName: 'DatumOpdracht',
        width: 150,
      },
      {
        columnName: 'DatumAfgehandeld',
        width: 150,
      },
      {
        columnName: '__voorraadInfo' as any,
        width: 95,
      },
    ],
    [],
  );

  return opdrachten === null || inkoopdiensten === null || producttypen === null ? (
    <LoadingSpinner />
  ) : (
    <>
      <Helmet>
        <title>Afgehandelde inkoopopdrachten</title>
      </Helmet>
      <MenuLayout
        menu={
          <>
            <div className="d-flex align-items-center">
              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-2"
                style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                disabled={urlState.selectie.length === 0}
                onClick={() => handleMarkerenUitstaand()}
              >
                <IconVerwijderen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                <span className="ml-2">Markeren Uitstaand</span>
              </button>

              <div className="d-flex flex-fill ml-3">
                <FilterBalkV2
                  filters={filters}
                  filterData={urlState.filterData}
                  onFilterDataChange={(x) => setUrlStateSync('filterData', x)}
                  onFilterSchemaChange={(x) => setFilterSchema(x)}
                />
              </div>
            </div>
          </>
        }
        body={
          <>
            <GridStyleWrapper height="calc(100vh - 150px)">
              <Grid columns={kolommen} getRowId={keyExtractor} rows={opdrachten}>
                <DataTypeProvider
                  for={['__aantalGeleverdeProducten']}
                  formatterComponent={(props) => {
                    return (
                      <span
                        style={{
                          color:
                            props.row.AantalGeleverdeProducten !== props.row.Aantal
                              ? Kleur.Rood
                              : '',
                        }}
                      >
                        {props.row.AantalGeleverdeProducten}
                      </span>
                    );
                  }}
                />

                <DataTypeProvider
                  for={['__leveranciernaamKort']}
                  formatterComponent={(props) => {
                    return <span>{props.row.dienst.LeveranciernaamKort}</span>;
                  }}
                />

                <DataTypeProvider
                  for={['__productsoortnaamKort']}
                  formatterComponent={(props) => {
                    return <span>{props.row.producttype.ProductsoortnaamKort}</span>;
                  }}
                />

                <DataTypeProvider
                  for={['__merknaam']}
                  formatterComponent={(props) => {
                    return <span>{props.row.producttype.Merknaam}</span>;
                  }}
                />
                <DataTypeProvider
                  for={['__typenaam']}
                  formatterComponent={(props) => {
                    return <span>{props.row.producttype.Typenaam}</span>;
                  }}
                />

                {/* <DataTypeProvider
                  for={['__laatsteInslag']}
                  formatterComponent={(props) => {
                    const rij: IRow = props.row;
                    const datum =
                      rij.magazijnmutaties.length !== 0
                        ? format(new Date(rij.magazijnmutaties[0].Mutatiedatum!), 'dd-MM-yyyy')
                        : null;

                    return (
                      <>
                        <span>{datum}</span>
                      </>
                    );
                  }}
                /> */}

                <DataTypeProvider
                  for={[nameOf<IRow>('DatumAfgehandeld')]}
                  formatterComponent={(props) => {
                    const datum =
                      props.value !== null ? format(new Date(props.value), 'dd-MM-yyyy HH:mm') : '';
                    return (
                      <>
                        <span>{datum}</span>
                      </>
                    );
                  }}
                />

                <DataTypeProvider
                  for={[nameOf<IRow>('DatumOpdracht')]}
                  formatterComponent={(props) => {
                    const datum =
                      props.value !== null ? format(new Date(props.value), 'dd-MM-yyyy') : '';
                    return (
                      <>
                        <span>{datum}</span>
                      </>
                    );
                  }}
                />

                <DataTypeProvider
                  for={['__voorraadInfo']}
                  formatterComponent={(formatterProps) => {
                    const rij: IRow = formatterProps.row;

                    return (
                      <div className="d-flex">
                        <a
                          href="#"
                          onClick={() => {
                            setVoorraadTypeInfoDialoogState({
                              typeID: rij.producttype.TypeID,
                              magID: rij.magazijn.MagID,
                            });
                          }}
                        >
                          {/* <VoorraadIndicatie
                            status={gereserveerd ? EVoorraadStatus.Groen : EVoorraadStatus.Rood}
                          /> */}
                          <IconInformatie style={{ width: 15, height: 15, fill: Kleur.Blauw }} />
                        </a>
                      </div>
                    );
                  }}
                />

                <SelectionState
                  selection={urlState.selectie}
                  onSelectionChange={(x) => setUrlStateSync('selectie', x as number[])}
                />

                <SortingState defaultSorting={[]} />
                <IntegratedSorting />

                <VirtualTable messages={geenData} />
                <RowDetailState defaultExpandedRowIds={[]} />

                <TableRowDetail
                  contentComponent={(props) => <DetailComp {...props} zonderCorrigeren />}
                  toggleCellComponent={DXTableToggleCellComponent}
                />
                <TableSelection cellComponent={DXTableCheckboxComponent} />
                <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
                <TableHeaderRow showSortingControls />
                {/* <TableRowDetail contentComponent={opdrachtDetailComp} /> */}
              </Grid>
            </GridStyleWrapper>
          </>
        }
      />
      {voorraadTypeInfoDialoogState !== null && (
        <VoorraadTypeInfoDialoog
          open
          typeID={voorraadTypeInfoDialoogState.typeID}
          magID={voorraadTypeInfoDialoogState.magID}
          onSuccess={() => setVoorraadTypeInfoDialoogState(null)}
          onAnnuleren={() => setVoorraadTypeInfoDialoogState(null)}
        />
      )}
    </>
  );
};

export default withRouter(AfgehandeldTab);
