import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { ModalBody, ModalFooter, ModalTitle } from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/ModalHeader';
import IDialoogProps from '../../../../core/IDialoogProps';
import Dialoog from '../../../../components/dialogen/Dialoog';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import DatumKiezer from '../../../../components/formulier/DatumKiezer';
import MultiCombobox, { IKolom } from '../../../../components/formulier/MultiCombobox';
import VinkVeld from '../../../../components/formulier/VinkVeld';
import nameof from '../../../../core/nameOf';
import api from '../../../../api';
import { IOphalenProducttypenAlternatiefResultElement } from '../../../../../../shared/src/api/v2/inkoop/opdracht/uitstaand';
import { IOphalenOpdrachtenResultElement } from '../../../../../../shared/src/api/v2/inkoop/opdracht';
import BedragInput from '../../../../components/formulier/BedragInput';
import InactiefOverlay from '../../../../components/InactiefOverlay';
import { addDays } from 'date-fns';
import VeldWeergave from '../../../../components/formulier/VeldWeergave';

interface IProps extends IDialoogProps<null> {
  inkOpdID: number;
}

interface IFormikValues {
  notities: string | null;
  datumVerwacht: Date | null;
  typeID: number | null;
  inkoopPrijsBekend: boolean;
  inkoopPrijs: number;
  geenTeLaatMelding: boolean;
}
const veldnamen: Partial<Record<keyof IFormikValues, string>> = {
  notities: 'Notities',
  datumVerwacht: 'Datum verwacht',
  typeID: 'Verwacht type',
  inkoopPrijs: 'Inkoopprijs',
  geenTeLaatMelding: 'Geen melding geven bij te late leveringen',
};

/**
 *
 * Dit WijzigenStatusDialoog wordt gebruikt bij opdrachten in tabblad Uitstaand
 * Voor nog niet-verstuurde opdrachten zie WijzigenOpdrachtDialoog
 *
 * */

const WijzigenStatusDialoog: React.FC<IProps> = (props) => {
  const { dialoogIndex, onAnnuleren, onSuccess, inkOpdID } = props;

  const [opdracht, setOpdracht] = useState<IOphalenOpdrachtenResultElement | null>(null);
  const [producttypen, setProducttypen] = useState<
    IOphalenProducttypenAlternatiefResultElement[] | null
  >(null);

  const ophalenOpdracht = useCallback(async () => {
    const result = await api.v2.inkoop.opdracht.ophalenOpdrachten({
      filterSchema: { filters: [{ naam: 'IDS', data: [inkOpdID] }] },
    });

    setOpdracht(result.inkoopopdrachten[0]);
  }, []);

  useEffect(() => {
    ophalenOpdracht();
  }, [inkOpdID]);

  const ophalenProducttypen = useCallback(async () => {
    if (opdracht === null) {
      return null;
    }
    const result = await api.v2.inkoop.opdracht.uitstaand.ophalenProducttypenAlternatief({
      typeID: opdracht.producttype.TypeID,
      inkDienstID: opdracht.dienst.ID,
    });

    setProducttypen(result);
  }, [opdracht]);

  useEffect(() => {
    ophalenProducttypen();
  }, [opdracht]);

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (opdracht === null) {
      return null;
    }
    return {
      notities: opdracht.Notities,
      datumVerwacht: opdracht.DatumVerwacht !== null ? new Date(opdracht.DatumVerwacht) : null,
      typeID:
        opdracht.producttypeVerwacht !== null
          ? opdracht.producttypeVerwacht.TypeID
          : opdracht.producttype.TypeID,
      inkoopPrijs: opdracht.InkoopPrijs || 0,
      inkoopPrijsBekend: opdracht.InkoopPrijs !== null,
      geenTeLaatMelding: opdracht.GeenTeLaatMelding,
    };
  }, [opdracht]);

  const typenKolommen = useMemo<IKolom<IOphalenProducttypenAlternatiefResultElement>[]>(() => {
    return [
      {
        key: 'Merknaam',
        label: 'Merk',
        breedte: 100,
      },
      {
        key: 'Typenaam',
        label: 'Type',
        breedte: 150,
      },
      {
        key: 'Kenmerk',
        label: 'Kenmerk',
        breedte: 225,
      },
    ];
  }, []);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      const params = {
        inkOpdID,
        datumVerwacht: values.datumVerwacht,
        notities: values.notities === '' ? null : values.notities,
        verwachtType_TypeID: values.typeID, //=== opdracht!.TypeID ? null : values.typeID,
        inkoopPrijs: values.inkoopPrijs,
        geenTeLaatMelding: values.geenTeLaatMelding,
      };

      // // const checkData = await api.v2.inkoop.opdracht.nieuw.checkToevoegenOpdracht(checkParams);
      // // const controleResult = await checkStore.controleren({
      // //   checkData,
      // // });
      // // if (controleResult.type === EResultType.Annuleren) {
      // //   return;
      // // }

      await api.v2.inkoop.opdracht.uitstaand.wijzigenOpdracht(params);

      actions.setSubmitting(false);
      onSuccess(null);
    },
    [opdracht],
  );

  return (
    <Dialoog index={dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Wijzigen inkoopopdracht</ModalTitle>
      </ModalHeader>

      {initialValues === null || opdracht === null ? (
        <LoadingSpinner />
      ) : (
        <Formik<IFormikValues>
          onSubmit={handleSubmit}
          // enableReinitialize
          initialValues={initialValues}
          // validationSchema={validationSchema}
          render={(formikProps: FormikProps<IFormikValues>) => {
            const { submitForm, isSubmitting, isValid } = formikProps;
            return (
              <>
                <ModalBody>
                  {/* <code>{JSON.stringify(formikProps.values.datumVerwacht)}</code> */}

                  <div className="col-12">
                    <VeldWeergave>
                      <span>
                        Besteld type: {opdracht.producttype.Merknaam}{' '}
                        {opdracht.producttype.Typenaam}{' '}
                        {opdracht.producttypeVerwacht !== null &&
                          opdracht.producttype.TypeID !== opdracht.producttypeVerwacht.TypeID &&
                          'AFWIJKEND'}
                      </span>
                    </VeldWeergave>
                  </div>

                  <div className="col-12 mt-3">
                    <label>{veldnamen.typeID}</label>

                    <Field
                      name={nameof<IFormikValues>('typeID')}
                      render={(fieldProps: FieldProps<IFormikValues>) => {
                        const { field, form } = fieldProps;

                        if (producttypen === null) {
                          return <LoadingSpinner />;
                        }

                        return (
                          <MultiCombobox<number, any>
                            sleutelExtractor={(row: IOphalenProducttypenAlternatiefResultElement) =>
                              row.TypeID
                            }
                            onWaardeChange={(waarde: number | null) => {
                              fieldProps.form.setFieldValue(field.name, waarde);
                            }}
                            representatieFabriek={(
                              row: IOphalenProducttypenAlternatiefResultElement,
                            ) => `${row.Typenaam} - ${row.Merknaam}`}
                            waarde={fieldProps.field.value}
                            opties={
                              producttypen !== null
                                ? producttypen.map((x) => {
                                    return {
                                      TypeID: x.TypeID,
                                      Typenaam: x.Typenaam,
                                      Merknaam: x.Merknaam,
                                      Kenmerk: x.Kenmerk,
                                    };
                                  })
                                : []
                            }
                            kolommen={typenKolommen}
                          />
                        );
                      }}
                    />
                  </div>

                  <div className="col-12 mt-3">
                    <label>{veldnamen.datumVerwacht}</label>
                    <Field
                      name="datumVerwacht"
                      render={({ field, form }: FieldProps<IFormikValues>) => {
                        const minimaleDatum = addDays(new Date(), -30);
                        const maximaleDatum = addDays(new Date(), +180);
                        return (
                          <div className="d-flex ">
                            <DatumKiezer
                              onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                              waarde={field.value}
                              isClearable
                              determineValidDate={(date) => {
                                return date >= minimaleDatum && date <= maximaleDatum;
                              }}
                              determineNextValidDate={(date) => {
                                const nieuweDatum = addDays(date, +1);
                                if (nieuweDatum <= maximaleDatum) {
                                  return nieuweDatum;
                                }
                                return null;
                              }}
                              determinePreviousValidDate={(date) => {
                                const nieuweDatum = addDays(date, -1);
                                if (nieuweDatum >= minimaleDatum) {
                                  return nieuweDatum;
                                }
                                return null;
                              }}
                            />
                          </div>
                        );
                      }}
                    />
                  </div>

                  <div className="col-12 mt-2">
                    <label>{veldnamen.notities}</label>
                    <Field
                      name={nameof<IFormikValues>('notities')}
                      render={(fieldProps: FieldProps<IFormikValues>) => {
                        const { field, form } = fieldProps;
                        return <textarea {...field} className="form-control" rows={3} />;
                      }}
                    />
                  </div>

                  <div className="col-6 mt-2">
                    <div>
                      <label>{veldnamen.inkoopPrijs}</label>
                      <span className="d-flex align-items-center">
                        <Field
                          name={nameof<IFormikValues>('inkoopPrijsBekend')}
                          render={(x: FieldProps<IFormikValues>) => {
                            return (
                              <VinkVeld
                                aangevinkt={x.field.value}
                                onGewijzigd={(aangevinkt) => {
                                  x.form.setFieldValue(x.field.name, aangevinkt);
                                }}
                              />
                            );
                          }}
                        />
                        <span className="ml-2">
                          <Field
                            name={nameof<IFormikValues>('inkoopPrijs')}
                            render={(x: FieldProps<IFormikValues>) => {
                              return (
                                <InactiefOverlay
                                  isInactief={!x.form.values.inkoopPrijsBekend}
                                  onClick={() =>
                                    x.form.setFieldValue(
                                      nameof<IFormikValues>('inkoopPrijsBekend'),
                                      true,
                                    )
                                  }
                                  element={
                                    <BedragInput
                                      value={x.field.value}
                                      onChange={(bedrag) => {
                                        x.form.setFieldValue(x.field.name, bedrag);
                                      }}
                                    />
                                  }
                                />
                              );
                            }}
                          />
                        </span>
                      </span>
                    </div>
                  </div>

                  <div className="col-12 mt-3">
                    <span className="d-flex align-items-center">
                      <Field
                        name="geenTeLaatMelding"
                        render={(x: FieldProps<IFormikValues>) => (
                          <VinkVeld
                            aangevinkt={x.field.value}
                            onGewijzigd={(aangevinkt: boolean) =>
                              x.form.setFieldValue(x.field.name, aangevinkt)
                            }
                          />
                        )}
                      />
                      <span className="ml-2">{veldnamen.geenTeLaatMelding}</span>
                    </span>
                  </div>
                </ModalBody>
                <ModalFooter className="d-flex flex-row justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    style={{ width: 100 }}
                    disabled={isSubmitting || !isValid}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={onAnnuleren}
                    style={{ width: 100 }}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Dialoog>
  );
};

export default WijzigenStatusDialoog;
