import React, { useCallback, useContext, useMemo } from 'react';
import { IUitgeklapteRijProps } from '../../../../../../../../../components/tabel/ASPTabel/Body/UitgeklapteRij';
import { ILocatie } from '../../../../../../../../../../../shared/src/api/v2/relatie/contract';
import { LocatieTabelContext, LocatieTabelKolom } from '../index';
import styled from 'styled-components';
import { Kleur } from '../../../../../../../../../bedrijfslogica/constanten';
import ContractTegel from './ContractTegel';
import { useOverzichtV2Store } from '../../store';
import ContactpersonenTabel, {
  IContactpersonenTabelData,
  IContactpersonenTabelRegel,
} from '../../../../../../../../../components/kaart/entiteiten/Contactpersonen/ContactpersonenTabel';
import { IconKruis } from '../../../../../../../../../components/Icons';
import { GlobaleRendererContext } from '../../../../../../../../../one-off-components/GlobaleRenderer';
import { RootStoreContext } from '../../../../../../../../../stores/RootStore';
import api from '../../../../../../../../../api';

const Root = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 8px;
  row-gap: 5px;
  background-color: ${Kleur.HeelLichtGrijs};
`;

const UitgeklapteRij = (props: IUitgeklapteRijProps<LocatieTabelKolom, ILocatie>): JSX.Element => {
  const store = useOverzichtV2Store();
  const { dataBijID, relID } = useContext(LocatieTabelContext)!;
  const { checkStore } = useContext(RootStoreContext);

  const contracten = useMemo(() => {
    const contractenVanLocatie = dataBijID.contractenBijLocID[props.regel.LocID];
    return contractenVanLocatie.filter(
      (cnt) => store.data.data!.result.cntIDsVoorWeergave.indexOf(cnt.CntID) !== -1,
    );
  }, [props.regel.LocID, dataBijID]);

  const contactpersonen = useMemo(() => {}, []);

  const handleVernieuwenContracten = useCallback(async () => {
    await store.verversenContractWeergave();
  }, [store.verversenContractWeergave]);

  const contactpersonenTabelData = useMemo<IContactpersonenTabelData | null>(() => {
    const locXRelPers = dataBijID.locXRelPersBijLocID[props.regel.LocID] ?? [];
    if (locXRelPers.length === 0) {
      return null;
    }
    const contactpersonen = locXRelPers.map((x) => dataBijID.contactpersonenBijID[x.RelPers_ID]);

    const contactpersonenRegels: Partial<IContactpersonenTabelRegel>[] = contactpersonen.map(
      (cp) => {
        const persoon = dataBijID.personenBijID[cp.PersID];

        return {
          id: cp.ID,
          persID: cp.PersID,
          tekenbevoegd: cp.Tekenbevoegd,
          taalID: persoon.TaalID,
          telefoonMobiel: persoon.TelefoonMobiel,
          whatsAppSesID: null, // TODO
          email: persoon.Email,
          achternaam: persoon.Achternaam,
          relatieBetrekkingID: cp.RelatieBetrekkingID,
          notities: persoon.Notities,
          recordGewijzigd: persoon.RecordGewijzigd,
          inactiefOp: cp.InactiefOp,
        };
      },
    );

    const talenBijID = Object.values(dataBijID.talenBijID).reduce<
      IContactpersonenTabelData['talenBijID']
    >((acc, curr) => {
      acc[curr.TaalID] = {
        naamKort: curr.NaamKort,
      };
      return acc;
    }, {});
    const relatieBetrekkingenBijID = Object.values(dataBijID.relatieBetrekkingenBijID).reduce<
      IContactpersonenTabelData['relatieBetrekkingenBijID']
    >((acc, curr) => {
      acc[curr.ID] = {
        Naam: curr.Naam,
      };
      return acc;
    }, {});

    return {
      contactpersonen: contactpersonenRegels,
      talenBijID,
      relatieBetrekkingenBijID,
    };
  }, [props.regel.LocID, dataBijID]);

  const contactpersonenTabelHeight = useMemo(() => {
    if (contactpersonenTabelData === null) {
      return 0;
    }
    const headerHeight = 40;
    const lineHeight = 1;
    const rowHeight = 40;

    return headerHeight + lineHeight + contactpersonenTabelData.contactpersonen.length * rowHeight;
  }, [contactpersonenTabelData]);

  return (
    <Root>
      {contactpersonenTabelData !== null && (
        <div
          className="d-flex flex-column w-100"
          style={{ height: contactpersonenTabelHeight, backgroundColor: Kleur.Wit }}
        >
          <ContactpersonenTabel
            relID={relID}
            kolommen={['naam', 'telefoon_mobiel', 'communicatie', 'notities', 'acties']}
            verversen={handleVernieuwenContracten}
            data={contactpersonenTabelData}
            acties={['wijzigen']}
            customActiesBuilder={(rij) => [
              {
                text: 'Ontkoppelen van locatie',
                icon: <IconKruis style={{ fill: Kleur.Grijs, width: 18, height: 18 }} />,
                onClick: async () => {
                  await checkStore.bevestigen({
                    inhoud: 'Bevestig ontkoppelen persoon van locatie',
                    asynchroneActieNaBevestigingFn: async () => {
                      await api.v2.relatie.ontkoppelenPersonenVanLocatie({
                        locID: props.regel.LocID,
                        relPersIDs: [rij.id!],
                      });
                    },
                  });
                  await handleVernieuwenContracten();
                },
              },
            ]}
          />
        </div>
      )}
      {contracten.map((cnt) => {
        return (
          <ContractTegel
            key={cnt.CntID}
            contract={cnt}
            vernieuwenContracten={handleVernieuwenContracten}
          />
        );
      })}
    </Root>
  );
};

export default UitgeklapteRij;
