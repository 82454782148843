import React, { useMemo } from 'react';
import Toetsingen from './Toetsingen';
import { IOphalenKrediettoetsenResultElement } from '../../../../../../../../../../shared/src/api/v2/kredietwaardigheid';
import Tegel from '../../../../../../../../components/tegel/Tegel';
import VinkVeld from '../../../../../../../../components/formulier/VinkVeld';
import TegelUitklapMenu, {
  ISectie,
} from '../../../../../../../../components/tegel/TegelUitklapMenu';
import {
  IOphalenContactpersonenVoorLijstResultElement,
  IOphalenContactpersonenResultElement,
} from '../../../../../../../../../../shared/src/api/v2/relatie/contactpersoon';
import HorizontaleScheidingslijn from '../../../../../../../../components/layout/HorizontaleScheidingslijn';
import { formatteerPersoonNaam } from '../../../../../../../../helpers';

enum EUitklapSectie {
  Toetsingen,
}

interface IProps {
  geselecteerd: boolean;
  onSelectieChange: (state: boolean) => void;
  contactpersoon: IOphalenContactpersonenResultElement;
  toetsingen: IOphalenKrediettoetsenResultElement[];
  toetsingenZichtbaar: boolean;
  onToetsingenZichtbaarChange: (zichtbaar: boolean) => void;
}

const ContactpersoonTegel: React.FC<IProps> = (props) => {
  const {
    geslacht,
    Achternaam,
    Email,
    IsRelatie,
    PersID,
    taal,
    Tekenbevoegd,
    TelefoonExtra,
    TelefoonMobiel,
    Voorletters,
    Voornaam,
    Voorvoegsel,
  } = props.contactpersoon;

  const uitklapSecties = useMemo<ISectie<EUitklapSectie>[]>(
    () => [
      {
        id: EUitklapSectie.Toetsingen,
        label: `Toetsingen (${props.toetsingen.length})`,
      },
    ],
    [props.toetsingen],
  );

  return (
    <Tegel
      linkerKolom={
        <div className="mr-3">
          <VinkVeld
            aangevinkt={props.geselecteerd}
            onGewijzigd={(x) => props.onSelectieChange(x)}
          />
        </div>
      }
    >
      <span>
        {formatteerPersoonNaam({
          aanhefKort: geslacht.AanhefKort,
          achternaam: Achternaam,
          voorletters: Voorletters,
          voornaam: Voornaam,
          voorvoegsel: Voorvoegsel,
        })}
      </span>
      <TegelUitklapMenu<EUitklapSectie>
        secties={uitklapSecties}
        uitgeklaptId={props.toetsingenZichtbaar ? EUitklapSectie.Toetsingen : null}
        onUitgeklaptIdChange={(id) =>
          props.onToetsingenZichtbaarChange(id === EUitklapSectie.Toetsingen)
        }
      />
      {props.toetsingenZichtbaar && (
        <>
          <div className="mt-2 mb-2">
            <HorizontaleScheidingslijn />
          </div>
          <Toetsingen toetsingen={props.toetsingen} />
        </>
      )}
    </Tegel>
  );
};

export default ContactpersoonTegel;
