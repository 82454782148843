import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../stores/RootStore';
import { IOphalenOrganisatiesResultElement } from '../../../../../shared/src/api/v2/organisatie/organisatie';
import { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router';
import { Kleur } from '../../../bedrijfslogica/constanten';

interface INaamComponentProps {
  navigeerNaarRelatie: () => void;
  geenLinkToepassen: boolean;
}

export interface IProps extends RouteComponentProps {
  orgID: number;
  naamComponent?: React.ComponentType<INaamComponentProps>;
  geenLinkToepassen?: boolean;
}

const OrganisatieVisualisatie: React.FC<IProps> = observer((props) => {
  const { organisatieStore } = useContext(RootStoreContext);

  useEffect(() => {
    if (organisatieStore.organisaties[props.orgID] !== undefined) {
      return;
    }

    organisatieStore.enqueueOrganisatieOphalen(props.orgID);
  }, [props.orgID]);

  const organisatie = useMemo<IOphalenOrganisatiesResultElement | null>(() => {
    return organisatieStore.organisaties[props.orgID] || null;
  }, [props.orgID, organisatieStore.organisaties]);

  const NaamComponent = useMemo<React.ComponentType<INaamComponentProps>>(() => {
    if (props.naamComponent !== undefined) {
      return props.naamComponent;
    }
    return (x) => (
      <a
        href="#"
        onClick={x.geenLinkToepassen ? undefined : () => x.navigeerNaarRelatie()}
        style={{ color: Kleur.Oranje }}
      >
        {x.children}
      </a>
    );
  }, [props.naamComponent]);

  return (
    <span>
      {organisatie === null ? (
        'Laden...'
      ) : (
        <NaamComponent
          navigeerNaarRelatie={() => props.history.push(`/klant/${organisatie!.RelID}`)}
          geenLinkToepassen={props.geenLinkToepassen ?? false}
        >
          {organisatie.Naam}
        </NaamComponent>
      )}
    </span>
  );
});

export default withRouter(OrganisatieVisualisatie);
