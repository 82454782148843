import { Formik, FormikActions, FormikErrors, FormikProps } from 'formik';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import api from '../../../api';
import { IFilterSchemaFilter } from '../../../../../shared/src/models/filter';
import _ from 'lodash';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import IDialoogProps from '../../../core/IDialoogProps';
import Dialoog from '../../dialogen/Dialoog';
import * as Yup from 'yup';
import Tabblad, { ITabblad } from '../../layout/Tabblad';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { checkValidatieSchema } from '../../../core/validatie';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../models/IRemoteData';
import { ITijdvak } from '../../formulier/TijdvakVeld';
import {
  EContractstatus,
  EOpdrachtstatusTransport,
  ETransportopdrachtRegelsoort,
} from '../../../bedrijfslogica/enums';
import { IOphalenOpdrachtenResultElement } from '../../../../../shared/src/api/v2/transport/opdracht';
import { IOphalenDienstenResultElement } from '../../../../../shared/src/api/v2/dienst/transport';
import { IOphalenBezoekdagenResult } from '../../../../../shared/src/api/v2/transport/bezoek';
import { IOphalenMagazijnenResultElement } from '../../../../../shared/src/api/v2/magazijn';
import { IOphalenProducttypenResultElement } from '../../../../../shared/src/api/v2/product/type';
import {
  IOphalenProductenResultElementV2,
  IOphalenProductstatussenResultElement,
} from '../../../../../shared/src/api/v2/product';
import { IOphalenContractenResultElementV2 } from '../../../../../shared/src/api/v2/contract';
import { IOphalenLocatieNiveausResultElement } from '../../../../../shared/src/api/v2/locatie/locatie';
import OpdrachtTab from './OpdrachtTab';
import RegelTab from './RegelTab';
import LoadingSpinner from '../../Gedeeld/LoadingSpinner';
import { IOphalenInstellingenResult } from '../../../../../shared/src/api/v2/instelling';
import { RootStoreContext } from '../../../stores/RootStore';
import { EResultType } from '../../../stores/CheckStore';
// import { IOpdracht, IRegel } from '../../../../../shared/src/api/v2/transport/opdracht';
import { BooleanLiteral } from 'typescript';
import ICheckData from '../../../../../shared/src/models/ICheckData';
import { IOpdracht, IRegel } from '../../../../../shared/src/api/v2/transport/opdracht/nieuw';

export interface IDialoogResult {}
interface IProps extends IDialoogProps<IDialoogResult> {
  relID: number;
  contractID?: number;
  soortOpdracht?: ESoortOpdracht;
  leverProduct?: { typeID: number | null; prodID: number | null; bulk: boolean } | null;
  retourProductID?: number | null;
}

export enum ETabblad {
  Regel,
  Opdracht,
}

export enum ESoortOpdracht {
  Levering = 1,
  Retour = 2,
  Omruil = 3,
}

export interface IFormikValues {
  soortOpdracht: ESoortOpdracht;
  leverProduct: {
    prodID: number | null;
    typeID: number | null;
  } | null;
  retourProductID: number | null;
  locatieID: number | null;
  dienstID: number;
  magazijnID: number;
  bezoekdatum: Date | null;
  opdrachtID: number | null;
  voorkeurTijdvak: ITijdvak | null;
  indicatieAAA: boolean;
  bezoekinstructies: string | null;
  infoPlanning: string | null;
  aansluitenTrekschakelaar: boolean | null;
  verdieping: number | null;
  aantalAfvoer: number;
  persoonID: number | null;
  retourstatus_ProdStatID: number | null;
  defect: boolean | null;
}

export const veldnamen: Record<keyof IFormikValues, string> = {
  soortOpdracht: 'Soort opdracht',
  leverProduct: 'Leverproduct',
  retourProductID: 'Retourproduct',
  locatieID: 'Locatie',
  dienstID: 'Transportdienst',
  magazijnID: 'Magazijn',
  bezoekdatum: 'Bezoekdatum',
  opdrachtID: 'Toevoegen aan',
  voorkeurTijdvak: 'Voorkeurtijden',
  indicatieAAA: 'Indicatie AAA',
  bezoekinstructies: 'Bezoekinstructies',
  infoPlanning: 'Planninginfo',
  aansluitenTrekschakelaar: 'Trekschakelaar',
  verdieping: 'Verdieping',
  aantalAfvoer: 'Aantal afvoer',
  persoonID: 'Aanvrager',
  retourstatus_ProdStatID: 'Retourstatus',
  defect: 'Defect',
};

export interface ILeverProduct {
  typeID: number | null;
  prodID: number | null;
  bulk: boolean | null;
}
export interface IFormulierContext {
  soortOpdracht: number | null;
  relID: number;
  cntID: number | null;
  values: IFormikValues;
  leverProduct: ILeverProduct | null;
  retourProductID: number | null;
  retourproducten: IOphalenProductenResultElementV2[] | null;
  retourstatusID: number | null;
  diensten: IOphalenDienstenResultElement[] | null;
  dienstID: number | null;
  magazijnen: IOphalenMagazijnenResultElement[] | null;
  locatieID: IRemoteData<number | null>;
  bezoekdagen: IOphalenBezoekdagenResult | null;
  opdrachten: IOphalenOpdrachtenResultElement[] | null;
  verdiepingen: IOphalenLocatieNiveausResultElement[] | null;
  retourstatussen: IOphalenProductstatussenResultElement[] | null;
  defect: boolean | null;
  setDefect: (value: any) => void;
  setDienstID: (value: number) => void;
  setFieldValue: (field: string, value: any) => void;
  setSoortOpdracht: (value: any) => void;
  setLeverProduct: (value: any) => void;
  setRetourProductID: (value: any) => void;
  setRetourstatusID: (value: any) => void;
  setLocatieID: (value: any) => void;
}
export const FormulierContext = React.createContext<IFormulierContext>(null as any);

const NieuweOpdrachtDialoog: React.FC<IProps> = (props) => {
  const { dialoogIndex, onAnnuleren, onSuccess, relID } = props;
  const [tabblad, setTabblad] = useState<ETabblad>(ETabblad.Regel);
  const formikRef = useRef<Formik<IFormikValues>>(null);
  const { checkStore } = useContext(RootStoreContext);

  const [soortOpdracht, setSoortOpdracht] = useState<number | null>(null);

  const [diensten, setDiensten] = useState<IOphalenDienstenResultElement[] | null>(null);
  const [dienstID, setDienstID] = useState<number | null>(null);
  const [locatieID, setLocatieID] = useState<IRemoteData<number | null>>(createPendingRemoteData());
  const [bezoekdagen, setBezoekdagen] = useState<IOphalenBezoekdagenResult | null>(null);
  const [magazijnen, setMagazijnen] = useState<IOphalenMagazijnenResultElement[] | null>(null);
  const [instelling, setInstelling] = useState<IOphalenInstellingenResult | null>(null);
  const [opdrachten, setOpdrachten] = useState<IOphalenOpdrachtenResultElement[] | null>(null);
  const [verdiepingen, setVerdiepingen] = useState<IOphalenLocatieNiveausResultElement[] | null>(
    null,
  );
  const [leverProduct, setLeverProduct] = useState<ILeverProduct | null>(
    props.leverProduct !== undefined ? props.leverProduct : null,
  );
  const [defect, setDefect] = useState<boolean | null>(null);
  const [retourProductID, setRetourProductID] = useState<number | null>(null);
  const [retourstatusID, setRetourstatusID] = useState<number | null>(null);
  const [retourstatussen, setRetourstatussen] = useState<
    IOphalenProductstatussenResultElement[] | null
  >(null);
  const [contract, setContract] = useState<IRemoteData<IOphalenContractenResultElementV2 | null>>(
    createPendingRemoteData(),
  );
  const [primairePersoonID, setPrimairePersoonID] = useState<IRemoteData<number | null>>(
    createPendingRemoteData(),
  );

  // Velden van leverProduct
  const [
    leverProductType,
    setLeverProductType,
  ] = useState<IOphalenProducttypenResultElement | null>(null);
  const [
    leverProductProduct,
    setLeverProductProduct,
  ] = useState<IOphalenProductenResultElementV2 | null>(null);

  // Rijbron van retourproducten
  const [retourproducten, setRetourproducten] = useState<IOphalenProductenResultElementV2[] | null>(
    null,
  );

  // Retourstatus
  useEffect(() => {
    (async () => {
      if (retourProductID === null) {
        return;
      }
      const product = (
        await api.v2.product.ophalenProductenV2({
          filterSchema: {
            filters: [
              {
                naam: 'IDS',
                data: [retourProductID],
              },
            ],
          },
        })
      ).producten[0];

      setRetourstatusID(product.retourstatus !== null ? product.retourstatus.ProdStatID : null);
      setLocatieID(createReadyRemoteData(product.locatie !== null ? product.locatie.LocID : null));
      setDefect(product.Defect);
    })();
  }, [retourProductID]);

  // Soort opdracht
  useEffect(() => {
    (async () => {
      if (props.soortOpdracht === undefined) {
        if (props.contractID !== undefined) {
          // afh. van de status van het contract
          const contract = (
            await api.v2.contract.ophalenContractenV2({
              filterSchema: {
                filters: [
                  {
                    naam: 'IDS',
                    data: [props.contractID],
                  },
                ],
              },
            })
          ).contracten[0];

          setSoortOpdracht(
            contract.status.NaamEnum === EContractstatus.Beeindigd
              ? ESoortOpdracht.Retour
              : ESoortOpdracht.Omruil,
          );
          return;
        }
        return setSoortOpdracht(ESoortOpdracht.Levering);
      }
      setSoortOpdracht(props.soortOpdracht!);
    })();
  }, [props.soortOpdracht, props.contractID]);

  useEffect(() => {
    (async () => {
      if (instelling === null) {
        return;
      }
      setDienstID(instelling.TrsDienstID);
    })();
  }, [instelling]);

  // Alle (retour)producten van de relatie/contract
  useEffect(() => {
    (async () => {
      const producten = (
        await api.v2.product.ophalenProductenV2({
          filterSchema: {
            filters: [
              {
                naam: 'REL_IDS',
                data: [relID],
              },
              props.contractID !== undefined
                ? {
                    naam: 'CNT_IDS',
                    data: [props.contractID],
                  }
                : null,
            ].filter((x) => x !== null) as IFilterSchemaFilter[],
          },
        })
      ).producten;

      if (producten.length === 0) {
        setRetourProductID(null);
        return;
      }
      setRetourproducten(producten);
      setRetourProductID(producten[0].ProdID);
    })();
  }, [relID]);

  // Contract ophalen
  useEffect(() => {
    (async () => {
      if (props.contractID === undefined || props.contractID === null) {
        setContract(createReadyRemoteData(null));
        return;
      }
      const contracten = (
        await api.v2.contract.ophalenContractenV2({
          filterSchema: {
            filters: [
              {
                naam: 'IDS',
                data: [props.contractID],
              },
            ],
          },
        })
      ).contracten;

      setContract(createReadyRemoteData(contracten[0]));
    })();
  }, [props.contractID]);

  // Transportdiensten ophalen
  useEffect(() => {
    (async () => {
      const diensten = (
        await api.v2.dienst.transport.ophalenDiensten({
          filterSchema: {
            filters: [
              {
                naam: 'IS_ACTIEF',
                data: true,
              },
            ],
          },
        })
      ).diensten;
      setDiensten(diensten);
    })();
  }, []);

  // Voorraadmagazijnen ophalen
  useEffect(() => {
    (async () => {
      const magazijnen = (
        await api.v2.magazijn.ophalenMagazijnen({
          filterSchema: {
            filters: [
              {
                naam: 'IS_VOORRAAD',
                data: true,
              },
              {
                naam: 'IS_ACTIEF',
                data: true,
              },
            ],
          },
        })
      ).magazijnen;
      setMagazijnen(magazijnen);
    })();
  }, []);

  // Rijbron transportopdrachten voor locatie
  const bepaalOpdrachten = useCallback(
    async (locatieID: number | null, dienstID: number) => {
      if (locatieID === null) {
        setOpdrachten(null);
        return;
      }

      const locatie = (
        await api.v2.locatie.ophalenLocaties({
          filterSchema: {
            filters: [
              {
                naam: 'IDS',
                data: [locatieID],
              },
            ],
          },
        })
      ).locaties[0];

      const locatiesOpAdresZonderBisnummer = (
        await api.v2.locatie.ophalenLocaties({
          filterSchema: {
            filters: [
              {
                naam: 'ADRES_ZONDER_BISNUMMER',
                data: {
                  landID: locatie.LandID,
                  postcode: locatie.Postcode,
                  huisnummer: locatie.Huisnummer,
                },
              },
            ],
          },
        })
      ).locaties;
      const locIDs = locatiesOpAdresZonderBisnummer.map((x) => x.LocID);

      const opdrachten = (
        await api.v2.transport.opdracht.ophalenOpdrachten({
          filterSchema: {
            filters: [
              {
                naam: 'STATUSSEN',
                data: [EOpdrachtstatusTransport.Planning],
              },
              {
                naam: 'TRSDIENST_IDS',
                data: [dienstID],
              },
              {
                naam: 'LOC_IDS',
                data: locIDs,
              },
            ].filter((x) => x !== null) as IFilterSchemaFilter[],
          },
          orderSchema: {
            orders: [
              {
                naam: 'RECORD_TOEGEVOEGD',
                richting: 'DESC',
              },
            ],
          },
        })
      ).opdrachten;

      setOpdrachten(opdrachten);
    },
    [locatieID],
  );

  // Opdrachten
  useEffect(() => {
    (async () => {
      if (locatieID.state === ERemoteDataState.Pending || dienstID === null) {
        return;
      }
      bepaalOpdrachten(locatieID.data, dienstID);
    })();
  }, [bepaalOpdrachten, locatieID.state, locatieID.data, dienstID]);

  // Verdiepingen
  useEffect(() => {
    (async () => {
      const verdiepingen = await api.v2.locatie.ophalenLocatieNiveaus({
        filterSchema: {},
      });

      setVerdiepingen(verdiepingen.locatieniveaus);
    })();
  }, []);

  // Retourstatussen (doelstatus)
  useEffect(() => {
    (async () => {
      const result = await api.v2.product.ophalenProductstatussen({
        filterSchema: {
          filters: [
            {
              naam: 'ENUMS',
              data: ['VOORRAAD', 'REVISIE', 'AFVOER', 'RETLEV'],
            },
          ],
        },
      });
      setRetourstatussen(result.statussen);
    })();
  }, []);

  // Instellingen ophalen
  useEffect(() => {
    (async () => {
      const instelling = await api.v2.instelling.ophalenInstellingen({});
      setInstelling(instelling);
    })();
  }, []);

  const bepaalBezoekdagen = useCallback(
    async (locatieID: number | null, dienstID: number | null) => {
      if (locatieID === null) {
        setBezoekdagen(null);
        return;
      }

      const locatie = (
        await api.v2.locatie.ophalenLocaties({
          filterSchema: {
            filters: [
              {
                naam: 'IDS',
                data: [locatieID],
              },
            ],
          },
        })
      ).locaties[0];

      const bezoekdagen = await api.v2.transport.bezoek.ophalenBezoekdagen({
        postcode: locatie.Postcode,
        landID: locatie.LandID,
        trsDienstID: dienstID !== null ? dienstID : undefined,
      });

      setBezoekdagen(bezoekdagen);
    },
    [],
  );

  useEffect(() => {
    if (locatieID.state === ERemoteDataState.Pending || dienstID === null) {
      return;
    }
    bepaalBezoekdagen(locatieID.data, dienstID);
  }, [locatieID, dienstID]);

  // Bepaal primaire persoon
  useEffect(() => {
    (async () => {
      const relatie = (
        await api.v2.relatie.ophalenRelaties({
          filterSchema: { filters: [{ naam: 'IDS', data: [props.relID] }] },
        })
      ).relaties[0];

      setPrimairePersoonID(createReadyRemoteData(relatie.PersoonPrimair_PersID));
    })();
  }, [props.relID]);

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (
      soortOpdracht === null ||
      diensten === null ||
      dienstID === null ||
      magazijnen === null ||
      instelling === null ||
      contract.state === ERemoteDataState.Pending ||
      primairePersoonID.state === ERemoteDataState.Pending
    ) {
      return null;
    }

    const dienst = diensten.find((x) => x.ID === instelling.TrsDienstID)!;
    const locatieID =
      soortOpdracht === ESoortOpdracht.Levering || ESoortOpdracht.Omruil
        ? contract.data !== null
          ? contract.data.basis.locatie.LocID
          : null
        : null;

    return {
      soortOpdracht,
      leverProduct,
      retourProductID,
      locatieID,
      dienstID,
      magazijnID: dienst.MagID!,
      bezoekdatum: null,
      opdrachtID: null,
      voorkeurTijdvak: null,
      indicatieAAA: false,
      bezoekinstructies: null,
      infoPlanning: null,
      aansluitenTrekschakelaar:
        contract.data !== null ? contract.data.basis.AansluitenTrekschakelaar : false,
      verdieping:
        contract.data !== null && contract.data.basis.locatieniveau != null
          ? contract.data.basis.locatieniveau.Verdieping
          : null,
      aantalAfvoer: 0,
      persoonID: primairePersoonID.data,
      retourstatus_ProdStatID: null,
      defect: null,
    };
  }, [
    diensten,
    soortOpdracht,
    contract.state,
    contract.data,
    props.soortOpdracht,
    props.leverProduct,
    props.retourProductID,
    primairePersoonID.state,
    primairePersoonID.data,
  ]);

  const validationSchema = useMemo(() => {
    const fields: Record<keyof IFormikValues, any> = {
      soortOpdracht: Yup.number(),
      leverProduct: Yup.object().nullable(),
      retourProductID: Yup.number().nullable(),
      locatieID: Yup.number(),
      dienstID: Yup.number(),
      magazijnID: Yup.number(),
      bezoekdatum: Yup.date().nullable(),
      opdrachtID: Yup.number().nullable(),
      voorkeurTijdvak: Yup.object().nullable(),
      indicatieAAA: Yup.boolean(),
      bezoekinstructies: Yup.string().notRequired(),
      infoPlanning: Yup.string().notRequired(),
      aansluitenTrekschakelaar: Yup.boolean().nullable(),
      verdieping: Yup.number().nullable(),
      aantalAfvoer: Yup.number(),
      persoonID: Yup.number(),
      retourstatus_ProdStatID: Yup.number().nullable,
      defect: Yup.boolean,
    };
    return Yup.object().shape(fields);
  }, []);

  // const handleValidate = useCallback((values: IFormikValues) => {
  //   const errors: FormikErrors<IFormikValues> = checkValidatieSchema(validationSchema, values);
  //   return errors;
  // }, []);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      {
        const checkData: ICheckData = {
          errors: [],
          warnings: [],
        };
        if (values.opdrachtID === null && values.bezoekdatum === null) {
          checkData.warnings.push('De bezoekdatum is niet opgegeven');
        }
        const checkResult = await checkStore.controleren({
          checkData,
        });

        if (checkResult.type === EResultType.Annuleren) {
          actions.setSubmitting(false);
          return;
        }

        // if (
        //   (
        //     await checkStore.bevestigen({
        //       inhoud: 'Opdracht vastleggen?',
        //     })
        //   ).type === EResultType.Annuleren
        // ) {
        //   actions.setSubmitting(false);
        //   return;
        // }
      }

      const persoon =
        values.persoonID !== null
          ? (
              await api.v2.persoon.ophalenPersonen({
                filterSchema: {
                  filters: [
                    {
                      naam: 'IDS',
                      data: [values.persoonID],
                    },
                  ],
                },
              })
            ).personen[0]
          : null;

      let opdracht: IOpdracht = {
        trsDienstID: null,
        magID: null,
        locID: values.locatieID!,
        bezoekdatum: values.bezoekdatum,
        indicatieAAA: values.indicatieAAA,
        voorkeurBezoektijdVan:
          values.voorkeurTijdvak !== null ? values.voorkeurTijdvak.begin : null,
        voorkeurBezoektijdTot: values.voorkeurTijdvak !== null ? values.voorkeurTijdvak.eind : null,
        aantalAfvoer: values.aantalAfvoer,
        persID: persoon !== null ? persoon.PersID : undefined,
        telefoon:
          persoon !== null && persoon.TelefoonMobiel !== null ? persoon.TelefoonMobiel : undefined,
        email: persoon !== null && persoon.Email !== null ? persoon.Email : undefined,
        infoPlanning: values.infoPlanning,
        bezoekinstructies: values.bezoekinstructies,
      };

      let regels: IRegel[] = [];

      if (
        values.soortOpdracht === ESoortOpdracht.Levering ||
        values.soortOpdracht === ESoortOpdracht.Omruil
      ) {
        // Bepaal of er direct een productreservering gemaakt kan worden
        // Bepaal daartoe de voorraadinfo voor het te leveren product(type)
        const voorraadVoorPrimairType =
          leverProduct !== null && leverProduct.typeID !== null
            ? (await api.v2.voorraad.ophalenVoorraadinfo({ typeIDs: [leverProduct.typeID] }))
                .voorraad[0]
            : null;

        const gereserveerd =
          voorraadVoorPrimairType !== null
            ? voorraadVoorPrimairType!.aantalVrij > 0
              ? true
              : false
            : false;

        let leverRegel: IRegel = {
          regelsoortNaamEnum: ETransportopdrachtRegelsoort.Levering,
          typeID: leverProduct !== null ? leverProduct.typeID! : undefined,
          prodID: leverProduct !== null ? leverProduct.prodID! : undefined,
          bulkproduct: leverProduct !== null ? leverProduct.bulk! : false,
          gereserveerd,
          aantal: 1,
          relID: props.relID,
          cntID: contract.data !== null ? contract.data.CntID : undefined,
          // omruilID?:
          aansluitenTrekschakelaar: values.aansluitenTrekschakelaar!,
          verdieping: values.verdieping!,
        };

        regels.push(leverRegel);
      }

      if (
        values.soortOpdracht === ESoortOpdracht.Retour ||
        values.soortOpdracht === ESoortOpdracht.Omruil
      ) {
        let retourRegel: IRegel = {
          regelsoortNaamEnum: ETransportopdrachtRegelsoort.Retour,
          typeID: undefined,
          prodID: values.retourProductID!,
          bulkproduct: false,
          // gereserveerd: false,
          aantal: 1,
          relID: props.relID,
          cntID: contract.data !== null ? contract.data.CntID : undefined,
          // omruilID?:
          // aansluitenTrekschakelaar: values.aansluitenTrekschakelaar!,
          verdieping: values.verdieping!,
        };

        regels.push(retourRegel);
      }

      const params = {
        opdrachten: [
          {
            trsOpdID: values.opdrachtID !== null ? values.opdrachtID : undefined,
            opdracht: opdracht,
            regels,
          },
        ],
      };
      const checkData = await api.v2.transport.opdracht.nieuw.checkToevoegenOpdrachten(params);
      const checkResult = await checkStore.controleren({
        checkData,
      });
      if (checkResult.type === EResultType.Annuleren) {
        actions.setSubmitting(false);
        return;
      }

      if (
        (
          await checkStore.bevestigen({
            inhoud: 'Opdracht vastleggen?',
          })
        ).type === EResultType.Annuleren
      ) {
        actions.setSubmitting(false);
        return;
      }

      const opdrachtenResult = await api.v2.transport.opdracht.nieuw.toevoegenOpdrachten(params);

      if (values.soortOpdracht === ESoortOpdracht.Omruil) {
        const opdracht = opdrachtenResult.opdrachten[0];
        const trsRegID = opdracht.regels[0].TrsRegID;
        const koppelAan_TrsRegID = opdracht.regels[1].TrsRegID;

        // Koppel de 2 opdrachtregels als omruil
        await api.v2.transport.opdracht.omruil.koppelenOmruil({
          regels: [
            {
              trsRegID,
              koppelAan_TrsRegID,
            },
          ],
        });
      }

      actions.setSubmitting(false);

      onSuccess({});
    },
    [contract.data, leverProduct],
  );

  // const XhandleSubmit = useCallback(
  //   async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
  //     actions.setSubmitting(true);

  //     // Parameters samenstellen
  //     let leverParams: any;
  //     let retourParams: any;

  //     if (
  //       values.soortOpdracht === ESoortOpdracht.Levering ||
  //       values.soortOpdracht === ESoortOpdracht.Omruil
  //     ) {
  //       leverParams = {
  //         opdracht:
  //           values.opdrachtID === null
  //             ? {
  //                 dienstID: values.dienstID,
  //                 magID: values.magazijnID,
  //                 bezoekdatum: values.bezoekdatum,
  //                 bezoektijden:
  //                   values.voorkeurTijdvak !== null
  //                     ? {
  //                         van: values.voorkeurTijdvak.begin,
  //                         tot: values.voorkeurTijdvak.eind,
  //                       }
  //                     : null,
  //                 indicatieAAA: values.indicatieAAA!,
  //                 verdieping: values.verdieping,
  //                 aantalAfvoer: values.aantalAfvoer,
  //                 bezoekinstructies: values.bezoekinstructies,
  //                 infoPlanning: values.infoPlanning,
  //               }
  //             : undefined,
  //         soort: 'L',
  //         prodID: leverProduct !== null ? leverProduct.prodID : null,
  //         type:
  //           leverProduct !== null
  //             ? {
  //                 typeID: leverProduct.typeID,
  //                 bulkproduct: false,
  //               }
  //             : null,
  //         gebruiktProduct: false,
  //         cntID: contract.data !== null ? contract.data.CntID : null,
  //         relID: props.relID,
  //         locID: values.locatieID,
  //         verdieping: values.verdieping,
  //         opties: {
  //           aansluitenTrekschakelaar: values.aansluitenTrekschakelaar,
  //         },
  //         aantal: 1,
  //       };
  //     }
  //     if (
  //       values.soortOpdracht === ESoortOpdracht.Retour ||
  //       values.soortOpdracht === ESoortOpdracht.Omruil
  //     ) {
  //       retourParams = {
  //         opdracht:
  //           values.opdrachtID === null
  //             ? {
  //                 dienstID: values.dienstID,
  //                 magID: values.magazijnID,
  //                 bezoekdatum: values.bezoekdatum,
  //                 bezoektijden:
  //                   values.voorkeurTijdvak !== null
  //                     ? {
  //                         van: values.voorkeurTijdvak.begin,
  //                         tot: values.voorkeurTijdvak.eind,
  //                       }
  //                     : null,
  //                 indicatieAAA: values.indicatieAAA!,
  //                 verdieping: values.verdieping,
  //                 aantalAfvoer: values.aantalAfvoer,
  //                 bezoekinstructies: values.bezoekinstructies,
  //                 infoPlanning: values.infoPlanning,
  //               }
  //             : undefined,
  //         soort: 'R',
  //         prodID: values.retourProductID,
  //         type: null,
  //         gebruiktProduct: false,
  //         cntID: contract.data !== null ? contract.data.CntID : null,
  //         relID: props.relID,
  //         locID: values.locatieID,
  //         verdieping: values.verdieping,
  //         opties: {
  //           aansluitenTrekschakelaar: null,
  //         },
  //         aantal: 1,
  //         retourstatus_ProdStatID: values.retourstatus_ProdStatID,
  //         defect,
  //       };
  //     }

  //     // Opdracht(en) vastleggen
  //     switch (values.soortOpdracht) {
  //       case ESoortOpdracht.Levering:
  //         {
  //           const checkData = await api.v2.transport.opdracht.nieuw.checkToevoegenRegel({
  //             trsOpdID: values.opdrachtID,
  //             ...leverParams,
  //           });
  //           const checkResult = await checkStore.controleren({
  //             checkData,
  //           });
  //           if (checkResult.type === EResultType.Annuleren) {
  //             actions.setSubmitting(false);
  //             return;
  //           }
  //         }

  //         await api.v2.transport.opdracht.nieuw.toevoegenRegel({
  //           trsOpdID: values.opdrachtID,
  //           ...leverParams,
  //         });
  //         break;

  //       case ESoortOpdracht.Retour:
  //         {
  //           const checkData = await api.v2.transport.opdracht.nieuw.checkToevoegenRegel({
  //             trsOpdID: values.opdrachtID,
  //             ...retourParams,
  //           });
  //           const checkResult = await checkStore.controleren({
  //             checkData,
  //           });
  //           if (checkResult.type === EResultType.Annuleren) {
  //             actions.setSubmitting(false);
  //             return;
  //           }
  //         }

  //         await api.v2.transport.opdracht.nieuw.toevoegenRegel({
  //           trsOpdID: values.opdrachtID,
  //           ...retourParams,
  //         });
  //         break;

  //       case ESoortOpdracht.Omruil:
  //         {
  //           const checkLeverData = await api.v2.transport.opdracht.nieuw.checkToevoegenRegel({
  //             trsOpdID: values.opdrachtID,
  //             ...leverParams,
  //           });
  //           const checkRetourData = await api.v2.transport.opdracht.nieuw.checkToevoegenRegel({
  //             trsOpdID: values.opdrachtID,
  //             ...retourParams,
  //           });
  //           const checkData = {
  //             warnings: [...checkLeverData.warnings, ...checkRetourData.warnings],
  //             errors: [...checkLeverData.errors, ...checkRetourData.errors],
  //           };
  //           const checkResult = await checkStore.controleren({
  //             checkData,
  //           });
  //           if (checkResult.type === EResultType.Annuleren) {
  //             actions.setSubmitting(false);
  //             return;
  //           }
  //         }

  //         const leverResult = await api.v2.transport.opdracht.nieuw.toevoegenRegel({
  //           trsOpdID: values.opdrachtID,
  //           ...leverParams,
  //         });
  //         const retourResult = await api.v2.transport.opdracht.nieuw.toevoegenRegel({
  //           trsOpdID: leverResult.TrsOpdID,
  //           ...retourParams,
  //         });
  //         // Koppel de 2 opdrachten als omruil
  //         await api.v2.transport.opdracht.omruil.koppelenOmruil({
  //           regels: [
  //             {
  //               trsRegID: leverResult.TrsRegID,
  //               koppelAan_TrsRegID: retourResult.TrsRegID,
  //             },
  //           ],
  //         });

  //         break;
  //     }

  //     actions.setSubmitting(false);
  //     onSuccess({});
  //   },
  //   [contract.data, leverProduct, defect],
  // );

  const tabbladen = useMemo<ITabblad<ETabblad>[]>(
    () => [
      {
        id: ETabblad.Regel,
        label: 'Regel',
        content: RegelTab,
      },
      {
        id: ETabblad.Opdracht,
        label: 'Opdracht',
        content: OpdrachtTab,
      },
    ],
    [],
  );

  return (
    <Dialoog
      index={dialoogIndex || 0}
      modalProps={{
        size: 'lg',
      }}
    >
      <ModalHeader>
        <ModalTitle>Nieuwe transportopdracht</ModalTitle>
      </ModalHeader>

      {initialValues === null ? (
        <div className="flex-fill d-flex align-items-center justify-content-center">
          <LoadingSpinner />
        </div>
      ) : (
        <Formik<IFormikValues>
          ref={formikRef}
          onSubmit={handleSubmit}
          initialValues={initialValues}
          // validationSchema={validationSchema}
          isInitialValid
          // validate={handleValidate}
          render={(formikProps: FormikProps<IFormikValues>) => {
            const { values, submitForm, isSubmitting, setFieldValue, isValid } = formikProps;

            const context = {
              soortOpdracht,
              relID,
              cntID: props.contractID !== undefined ? props.contractID : null,
              values,
              leverProduct,
              retourProductID,
              retourproducten,
              retourstatusID,
              diensten,
              dienstID,
              locatieID,
              bezoekdagen,
              magazijnen,
              opdrachten,
              verdiepingen,
              retourstatussen,
              defect,
              setDefect,
              setDienstID,
              setFieldValue,
              setSoortOpdracht,
              setLeverProduct,
              setRetourProductID,
              setRetourstatusID,
              setLocatieID,
            };

            return (
              <FormulierContext.Provider value={context}>
                <Tabblad
                  onSelectieChange={(x) => setTabblad(x)}
                  tabbladen={tabbladen}
                  geselecteerd={tabblad}
                  disabledTabladden={values.opdrachtID === null ? undefined : [ETabblad.Opdracht]}
                />

                <ModalFooter className="d-flex flex-row justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    style={{
                      width: 100,
                    }}
                    disabled={isSubmitting || !isValid}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={onAnnuleren}
                    style={{
                      width: 100,
                    }}
                    disabled={isSubmitting}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </FormulierContext.Provider>
            );
          }}
        />
      )}
    </Dialoog>
  );
};
export default NieuweOpdrachtDialoog;
