import {
  IOphalenSelectieProductenParams,
  IOphalenSelectieProductenResult,
  IReserverenProductParams,
  IReserverenProductResult,
  IVerwijderenProductReserveringParams,
  IVerwijderenProductReserveringResult,
  CheckVerwijderenProductReserveringParams,
} from '../../../../../../shared/src/api/v2/transport/reservering/product';
import api from '../../../index';
import ICheckData from '../../../../../../shared/src/models/ICheckData';

const retour = {
  ophalenSelectieProducten: async (
    params: IOphalenSelectieProductenParams,
  ): Promise<IOphalenSelectieProductenResult> => {
    return await api.post('v2/transport/reservering/retour/ophalen-selectie-producten', params);
  },
  reserverenProduct: async (
    params: IReserverenProductParams,
  ): Promise<IReserverenProductResult> => {
    return await api.post('v2/transport/reservering/retour/reserveren-product', params);
  },
  checkVerwijderenProductReservering: async (
    params: CheckVerwijderenProductReserveringParams,
  ): Promise<ICheckData> => {
    return await api.post(
      'v2/transport/reservering/retour/check-verwijderen-productreservering',
      params,
    );
  },
  verwijderenProductReservering: async (
    params: IVerwijderenProductReserveringParams,
  ): Promise<IVerwijderenProductReserveringResult> => {
    return await api.post('v2/transport/reservering/retour/verwijderen-productreservering', params);
  },
};

export default retour;
