import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import IDialoogProps from '../../../../core/IDialoogProps';
import Dialoog from '../../../../components/dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import * as Yup from 'yup';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../../stores/RootStore';
import Combobox from '../../../../components/formulier/Combobox';
import nameOf from '../../../../core/nameOf';
import { IOphalenOpdrachtwijzenResultElement } from '../../../../../../shared/src/api/v2/dienst';
import { IOphalenOpdrachtenResultElement } from '../../../../../../shared/src/api/v2/pendel';
import { IOphalenDienstenResultElement } from '../../../../../../shared/src/api/v2/dienst/pendel';
import api from '../../../../api';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import { EResultType } from '../../../../stores/CheckStore';
import _ from 'lodash';
import VinkVeld from '../../../../components/formulier/VinkVeld';
import { IOphalenMagazijnenResultElement } from '../../../../../../shared/src/api/v2/magazijn';
import { EMagazijnRichting } from '../../../../bedrijfslogica/enums';

interface IDialoogResult {}

interface IProps extends IDialoogProps<IDialoogResult> {
  penOpdID: number;
}

interface IFormikValues {
  laadmagazijn: boolean;
  losmagazijn: boolean;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  laadmagazijn: 'Laadmagazijn',
  losmagazijn: 'Losmagazijn',
};

const VersturenLaadLosBerichtDialoog: React.FC<IProps> = observer((props) => {
  const { children, dialoogIndex, onAnnuleren, onSuccess, open, penOpdID } = props;
  const { checkStore } = useContext(RootStoreContext);
  const [opdrachtwijzen, setOpdrachtwijzen] = useState<
    IOphalenOpdrachtwijzenResultElement[] | null
  >(null);

  const [dienst, setDienst] = useState<IOphalenDienstenResultElement | null>(null);
  const [opdracht, setOpdracht] = useState<IOphalenOpdrachtenResultElement | null>(null);

  useEffect(() => {
    (async () => {
      const result = await api.v2.pendel.ophalenOpdrachten({
        filterSchema: { filters: [{ naam: 'IDS', data: [penOpdID] }] },
      });
      const opdracht = result.opdrachten[0];

      setOpdracht(opdracht);
    })();
  }, [penOpdID]);

  useEffect(() => {
    (async () => {
      if (opdracht === null) {
        return;
      }
      const penDienstID = opdracht.dienst!.ID;

      const dienstenResult = await api.v2.dienst.pendel.ophalenDiensten({
        filterSchema: { filters: [{ naam: 'IDS', data: [penDienstID] }] },
      });

      setDienst(dienstenResult.diensten[0]);
    })();
  }, [opdracht]);

  // useEffect(() => {
  //   if (dienst === null) {
  //     return;
  //   }

  //   (async () => {
  //     const result = await api.v2.dienst.ophalenOpdrachtwijzenVoorDienst({
  //       filterSchema: {
  //         filters: [{ naam: 'PENDIENST_IDS', data: [dienst.ID] }],
  //       },
  //     });

  //     setOpdrachtwijzen(result.opdrachtwijzen);
  //   })();
  // }, [dienst]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      if (
        (
          await checkStore.bevestigen({
            inhoud: `Laad/los-bericht(en) versturen?`,
          })
        ).type === EResultType.Annuleren
      ) {
        actions.setSubmitting(false);
        return;
      }

      // Laadmagazijn
      if (values.laadmagazijn) {
        const params = {
          penOpdID: props.penOpdID,
          magazijnRichting: EMagazijnRichting.LAAD,
        };
        await api.v2.pendel.versturenLaadLosBericht(params);
      }

      // Losmagazijn
      if (values.losmagazijn) {
        const params = {
          penOpdID: props.penOpdID,
          magazijnRichting: EMagazijnRichting.LOS,
        };
        await api.v2.pendel.versturenLaadLosBericht(params);
      }

      props.onSuccess({});
      actions.setSubmitting(false);
    },
    [opdracht],
  );

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (opdracht === null || dienst === null) {
      return null;
    }

    return { laadmagazijn: false, losmagazijn: false };
  }, [dienst, opdracht]);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        opdrachtwijze: Yup.number().required(),
      }),
    [],
  );

  return (
    <Dialoog index={dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Versturen Laad/Los-bericht</ModalTitle>
      </ModalHeader>
      {initialValues === null || opdracht === null ? (
        <div className="p-4 d-flex align-items-center justify-content-center">
          <LoadingSpinner />
        </div>
      ) : (
        <Formik<IFormikValues>
          onSubmit={handleSubmit}
          initialValues={initialValues}
          // validationSchema={validationSchema}
          // enableReinitialize
          isInitialValid
          render={(formikProps: FormikProps<IFormikValues>) => {
            const { submitForm, isValid, isSubmitting } = formikProps;

            return (
              <>
                <ModalBody>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-12 d-flex flex-column mb-2">
                        <label>Transporteur</label>
                        <div
                          className="font-weight-bold flex-fill"
                          style={{
                            border: `1px solid ${Kleur.LichtGrijs}`,
                            padding: '4px 8px',
                            borderRadius: 3,
                          }}
                        >
                          {dienst === null ? <LoadingSpinner /> : dienst.relatie!.weergavenaam}
                        </div>
                      </div>

                      <div className="col-12 mt-3 d-flex">
                        <Field
                          name={nameOf<IFormikValues>('laadmagazijn')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <div className="d-flex align-items-center">
                                <VinkVeld
                                  aangevinkt={field.value}
                                  onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                />
                                <span className="ml-2">{veldnamen.laadmagazijn}</span>
                              </div>
                            );
                          }}
                        />
                        <span className="ml-2">
                          {opdracht.magazijn.laden.NaamKort} (
                          {opdracht.magazijn.laden.dienst !== null
                            ? opdracht.magazijn.laden.dienst.EmailVooraanmelding ??
                              'Geen email bij dienst'
                            : 'Geen dienst beschikbaar'}
                          )
                        </span>
                      </div>

                      <div className="col-12 mt-3 d-flex">
                        <Field
                          name={nameOf<IFormikValues>('losmagazijn')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <div className="d-flex align-items-center">
                                <VinkVeld
                                  aangevinkt={field.value}
                                  onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                />
                                <span className="ml-2">{veldnamen.losmagazijn}</span>
                              </div>
                            );
                          }}
                        />
                        <span className="ml-2">
                          {opdracht.magazijn.lossen.NaamKort} (
                          {opdracht.magazijn.lossen.dienst !== null
                            ? opdracht.magazijn.lossen.dienst.EmailVooraanmelding ??
                              'Geen email bij dienst'
                            : 'Geen dienst beschikbaar'}
                          )
                        </span>
                      </div>
                    </div>
                  </div>
                </ModalBody>
                <ModalFooter className="d-flex flex-row justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    style={{ width: 100 }}
                    disabled={!isValid || isSubmitting}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={onAnnuleren}
                    style={{ width: 100 }}
                    disabled={isSubmitting}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Dialoog>
  );
});

export default VersturenLaadLosBerichtDialoog;
