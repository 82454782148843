import v1 from './v1';
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { rootStoreInstance } from '../../stores/RootStore';

export const apiServicesBaseURL = process.env.REACT_APP_API_SERVICES_BASE_URL;

const baseConfig: AxiosRequestConfig = {
  baseURL: apiServicesBaseURL,
};

const getKey = (): string | null => {
  return null;
  // const gebruiker: any = localStorage.getItem('ASPGebruiker');
  // if (gebruiker) {
  //   return JSON.parse(gebruiker).ApiSleutel;
  // } else {
  //   return null;
  // }
};

const createAxiosInstance = (withoutInterceptor = false): AxiosInstance => {
  let headers = {};
  const key = getKey();
  if (key !== null) {
    headers = {
      'x-api-key': getKey(),
    };
  }

  const client = axios.create({
    ...baseConfig,
    headers,
    withCredentials: true,
  });
  if (!withoutInterceptor) {
    client.interceptors.response.use(
      (x) => x,
      (err) => {
        if (axios.isAxiosError(err)) {
          if (axios.isCancel(err)) {
            throw err;
          }

          if (err.code === 'ERR_NETWORK') {
            // rootStoreInstance.apiStore.traceerBereikbaarheid();
            throw err;
          }

          const response = err.response;
          // Als response status 401 is (unauthorized) hoeven we niet te kijken of de verbinding
          // weer lukt, want er is wel verbinding mogelijk, maar de gebruiker moet gewoon opnieuw inloggen.
          if (response !== undefined && response.status === 401) {
            // console.log('auth fout');
            throw err;
          }
          // noinspection JSIgnoredPromiseFromCall
        }

        throw err;
      },
    );
  }

  return client;
};

const axiosInstance: AxiosInstance = createAxiosInstance();

const services = {
  v1,
  call: async <TData = any>(
    apiUrl: string,
    data?: any,
    config?: AxiosRequestConfig,
  ): Promise<TData> => {
    const response = await axiosInstance.post(apiUrl, data, config);

    // const apiResult = response.data;
    // if (apiResult.error) {
    //   throw new Error(apiResult.error.message);
    // }
    // return apiResult.data as TData;

    return response.data;
  },
};

export type Filters<K extends string> =
  | {
      enkel: IFilter<K>;
    }
  | {
      en: Filters<K>[];
    }
  | {
      of: Filters<K>[];
    };

export interface IFilter<K extends string> {
  kolom: K;
  filter:
    | {
        nummeriek:
          | {
              gelijkAan: number;
            }
          | {
              groterDan: number;
            }
          | {
              kleinerDan: number;
            }
          | {
              groterDanOfGelijkAan: number;
            }
          | {
              kleinerDanOfGelijkAan: number;
            }
          | {
              in: number[];
            };
      }
    | {
        string:
          | {
              gelijkAan: string;
            }
          | {
              in: string[];
            }
          | {
              bevat: string;
            }
          | {
              begintMet: string;
            }
          | {
              eindigtOp: string;
            };
      }
    | {
        isNull: boolean;
      };
}

export type Sorteringen<K extends string> = ISorteringen<K>[];

export interface ISorteringen<K extends string> {
  kolom: K;
  richting: 'ASC' | 'DESC';
}

export default services;
