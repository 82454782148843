import {
  IOphalenVorderingenParams,
  IOphalenVorderingenResult,
  IMakenFactuurVoorstelParams,
  IMakenFactuurVoorstelResult,
  IVerwijderenVorderingenParams,
  IVerwijderenVorderingenResult,
  IWijzigenVorderingParams,
  IWijzigenVorderingResult,
  IOphalenVorderingParams,
  IOphalenVorderingResult,
  ICheckSelectieWijzigenVorderingParams,
  IOphalenRegelStatussenParams,
  IOphalenRegelStatussenResult,
  IToevoegenVorderingParams,
  IToevoegenVorderingResult,
  ICheckSelectieMakenFactuurVoorstelParams,
  IOphalenWOPInfoVoorVorderingenParams,
  IOphalenWOPInfoVoorVorderingenResult,
} from '../../../../../shared/src/api/v2/vordering';
import api from '../../index';
import ICheckData from '../../../../../shared/src/models/ICheckData';
import { IAchtergrondProces } from '../../../../../shared/src/api/v2/achtergrondProces';

const vordering = {
  checkToevoegenVordering: async (params: IToevoegenVorderingParams): Promise<ICheckData> => {
    return await api.post('v2/vordering/check-toevoegen-vordering', params);
  },
  toevoegenVordering: async (
    params: IToevoegenVorderingParams,
  ): Promise<IToevoegenVorderingResult> => {
    return await api.post('v2/vordering/toevoegen-vordering', params);
  },
  ophalenVorderingen: async (
    params: IOphalenVorderingenParams,
  ): Promise<IOphalenVorderingenResult> => {
    return await api.post('v2/vordering/ophalen-vorderingen', params);
  },
  checkSelectieMakenFactuurVoorstel: async (
    params: ICheckSelectieMakenFactuurVoorstelParams,
  ): Promise<ICheckData> => {
    return await api.post('v2/vordering/check-selectie-maken-factuur-voorstel', params);
  },
  makenFactuurVoorstel: async (
    params: IMakenFactuurVoorstelParams,
  ): Promise<IMakenFactuurVoorstelResult> => {
    return await api.post('v2/vordering/maken-factuur-voorstel', params);
  },
  makenFactuurVoorstelAchtergrondProces: async (
    params: IMakenFactuurVoorstelParams,
  ): Promise<IAchtergrondProces<IMakenFactuurVoorstelResult>> => {
    return await api.post('v2/vordering/maken-factuur-voorstel-achtergrond-proces', params);
  },
  checkVerwijderenVorderingen: async (
    params: IVerwijderenVorderingenParams,
  ): Promise<ICheckData> => {
    return await api.post('v2/vordering/check-verwijderen-vorderingen', params);
  },
  verwijderenVorderingen: async (
    params: IVerwijderenVorderingenParams,
  ): Promise<IVerwijderenVorderingenResult> => {
    return await api.post('v2/vordering/verwijderen-vorderingen', params);
  },
  // ophalenVordering: async (params: IOphalenVorderingParams): Promise<IOphalenVorderingResult> => {
  //   return await api.post('v2/vordering/ophalen-vordering', params);
  // },
  checkSelectieWijzigenVordering: async (
    params: ICheckSelectieWijzigenVorderingParams,
  ): Promise<ICheckData> => {
    return await api.post('v2/vordering/check-selectie-wijzigen-vordering', params);
  },
  checkWijzigenVordering: async (params: IWijzigenVorderingParams): Promise<ICheckData> => {
    return await api.post('v2/vordering/check-wijzigen-vordering', params);
  },
  wijzigenVordering: async (
    params: IWijzigenVorderingParams,
  ): Promise<IWijzigenVorderingResult> => {
    return await api.post('v2/vordering/wijzigen-vordering', params);
  },
  ophalenRegelStatussen: async (
    params: IOphalenRegelStatussenParams,
  ): Promise<IOphalenRegelStatussenResult> => {
    return await api.post('v2/vordering/ophalen-regel-statussen', params);
  },
  ophalenWOPInfoVoorVorderingen: async (
    params: IOphalenWOPInfoVoorVorderingenParams,
  ): Promise<IOphalenWOPInfoVoorVorderingenResult> => {
    return await api.post('v2/vordering/ophalen-wop-info-voor-vorderingen', params);
  },
};

export default vordering;
