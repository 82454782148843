import React, { useContext } from 'react';
import { TableRowDetail } from '@devexpress/dx-react-grid';
import {
  IOphalenOpdrachtenResultElement,
  IOphalenOpdrachtregelsResultElement,
} from '../../../../../../../../../../../../../../../shared/src/api/v2/transport/opdracht';
import TransportregelDetail from '../../../../../../../../../../../../../components/transport/TransportregelDetail';
import { TabelContext } from '../index';

export interface IRegel extends IOphalenOpdrachtregelsResultElement {
  opdracht: IOphalenOpdrachtenResultElement;
}

const RowDetail = (props: TableRowDetail.ContentProps) => {
  const row: IRegel = props.row;
  const context = useContext(TabelContext);
  return (
    <TransportregelDetail regel={row} onRequestVerversRegel={context.onVerversenAangevraagd} />
  );
};

export default RowDetail;
