import React, { useCallback, useContext, useMemo } from 'react';
import {
  IOphalenDuplicaatMutatiesResult,
  IOphalenMutatiesResultElement,
  IVerwijderenMutatiesParams,
} from '../../../../../../../shared/src/api/v2/bank/mutaties';
import {
  DXTableCheckboxComponent,
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
  DXTableToggleCellComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../../helpers/dxTableGrid';
import {
  Grid,
  VirtualTable,
  TableHeaderRow,
  TableColumnResizing,
  TableEditColumn,
  TableSelection,
  TableRowDetail,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  DataTypeProvider,
  EditingState,
  IntegratedSorting,
  RowDetailState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import nameof from '../../../../../core/nameOf';
import FormatteerBedrag from '../../../../../components/MutatieBedrag';
import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../../../stores/RootStore';
import { EResultType } from '../../../../../stores/CheckStore';
import api from '../../../../../api';
import { IconVink } from '../../../../../components/Icons';
import { Kleur as EKleur } from '../../../../../bedrijfslogica/constanten';
import DetailComp from './DetailComp';
import styled from 'styled-components';

interface IProps {
  mutaties: IOphalenMutatiesResultElement[];
  duplicaatMutaties: IOphalenDuplicaatMutatiesResult;
  height: number;
  onVerversenAangevraagd: () => void;
  selectie: number[];
  onSelectieChange: (x: number[]) => void;
  uitgeklapt: number[];
  onUitgeklaptChange: (x: number[]) => void;
}

const geenData = {
  noData: 'Geen bankopdrachten',
};

interface IZoekgemeldTrProps {
  nietKleuren?: boolean;
}

// const ZoekgemeldTr = styled.tr<IZoekgemeldTrProps>`
//   td {
//     ${(props) =>
//       props.nietKleuren
//         ? ''
//         : `
//       color: ${Kleur.Rood} !important;
//       background: #c7a4af;
//     `}
//   }
// `;

const ZoekgemeldTr = styled.tr<IZoekgemeldTrProps>`
  td {
    ${(props) =>
      props.nietKleuren
        ? ''
        : `
      background: #dddadc;
    `}
  }
`;

const MutatiesTabel: React.FC<IProps> = observer((props) => {
  const { checkStore } = useContext(RootStoreContext);

  const keyExtractor = useCallback((row: IOphalenMutatiesResultElement) => row.BankMutID, []);
  const kolommen = useMemo<TypedColumn<IOphalenMutatiesResultElement>[]>(
    () => [
      {
        name: 'Mutatiedatum',
        title: 'Datum',
      },
      {
        name: 'Bedrag',
        title: 'Bedrag',
      },
      {
        name: 'Rekeningnaam',
        title: 'Naam',
      },
      {
        name: 'Omschrijving',
        title: 'Omschrijving',
      },
      {
        name: 'Betalingskenmerk',
        title: 'Betalingskenmerk',
      },
      // {
      //   name: '__soort' as any,
      //   title: 'Bankopd.',
      // },
      {
        name: 'Definitief',
        title: 'Status',
      },
      {
        name: '__duplicaatIndicatie' as any,
        title: 'Dupliaat',
      },
    ],
    [],
  );
  const kolomBreedtes: Array<TypedTableColumnWidthInfo<IOphalenMutatiesResultElement>> = useMemo(
    () => [
      {
        columnName: 'Mutatiedatum',
        width: 100,
      },
      {
        columnName: 'Bedrag',
        width: 100,
      },
      {
        columnName: 'Rekeningnaam',
        width: 275,
      },
      {
        columnName: 'Omschrijving',
        width: 500,
      },
      {
        columnName: 'Betalingskenmerk',
        width: 200,
      },
      {
        columnName: 'Definitief',
        width: 130,
      },
      // {
      //   columnName: 'Geboekt',
      //   width: 100,
      // },
      // {
      //   columnName: 'Transactiecode',
      //   width: 100,
      // },
      // {
      //   columnName: '__soort' as any,
      //   width: 100,
      // },
      {
        columnName: '__duplicaatIndicatie' as any,
        width: 150,
      },
    ],
    [],
  );
  const kolomExtensies: Array<VirtualTable.ColumnExtension> = useMemo(
    () => [
      // {
      //   columnName: nameof<IOphalenMutatiesResultElement>('Bedrag'),
      //   align: 'right',
      // },
    ],
    [],
  );

  return (
    <GridStyleWrapper height={props.height}>
      <Grid getRowId={keyExtractor} rows={props.mutaties} columns={kolommen}>
        <DataTypeProvider
          for={[nameof<IOphalenMutatiesResultElement>('Mutatiedatum')]}
          formatterComponent={(formatterProps) => {
            const datum = new Date(formatterProps.value);
            return <span>{format(datum, 'dd-MM-yyyy')}</span>;
          }}
        />
        <DataTypeProvider
          for={[nameof<IOphalenMutatiesResultElement>('Bedrag')]}
          formatterComponent={(formatterProps) => {
            const bedrag: number = formatterProps.value;
            return <FormatteerBedrag bedrag={bedrag} />;
          }}
        />
        <DataTypeProvider
          for={[nameof<IOphalenMutatiesResultElement>('Definitief')]}
          formatterComponent={(formatterProps) => {
            const isDefinitief: boolean = formatterProps.value;
            return (
              <span style={{ color: isDefinitief ? EKleur.Groen : EKleur.Grijs }}>
                {isDefinitief ? 'Definitief' : 'Concept'}
              </span>
            );
          }}
        />

        {/* <DataTypeProvider
          for={[nameof<IOphalenMutatiesResultElement>('Geboekt')]}
          formatterComponent={(formatterProps) => {
            const isGeboekt: boolean = formatterProps.value;
            return (
              <span>
                {isGeboekt ? (
                  <IconVink style={{ width: 18, height: 18, fill: EKleur.Grijs }} />
                ) : null}
              </span>
            );
          }}
        /> */}

        {/* <DataTypeProvider
          for={['__soort']}
          formatterComponent={(formatterProps) => {
            const rij: IOphalenMutatiesResultElement = formatterProps.row;

            const data = rij.Boekdata !== null ? JSON.parse(rij.Boekdata) : null;

            if (data !== null) {
              if (data.bankOpdID !== null) {
                return <span>Storno</span>;
              }
              if (data.batchID !== null) {
                return <span>Batch</span>;
              }
              if (data.factIDs.length !== 0) {
                return <span>Debiteur</span>;
              }
              if (data.inkFactIDs.length !== 0) {
                return <span>Crediteur</span>;
              }
            }

            return <span></span>;
          }}
        /> */}

        <DataTypeProvider
          for={['__soort']}
          formatterComponent={(formatterProps) => {
            const rij: IOphalenMutatiesResultElement = formatterProps.row;

            if (rij.BankBatchID !== null) {
              return <span>Batch</span>;
            }
            if (rij.BankOpdID !== null) {
              return <span>Storno</span>;
            }

            return <span></span>;
          }}
        />

        <DataTypeProvider
          for={['__duplicaatIndicatie']}
          formatterComponent={(formatterProps) => {
            const rij: IOphalenMutatiesResultElement = formatterProps.row;
            const duplicaat = props.duplicaatMutaties.duplicaten.find(
              (x) => x.nieuwBankMutID === rij.BankMutID,
            );
            return (
              <span style={{ color: EKleur.Rood }}>
                {duplicaat !== undefined ? 'Is eerder ingelezen' : ''}
              </span>
            );
          }}
        />

        <EditingState
          onCommitChanges={async (changeset) => {
            if (changeset.deleted !== undefined && changeset.deleted.length > 0) {
              const ids = changeset.deleted as number[];

              const params: IVerwijderenMutatiesParams = { bankMutIDs: ids };
              const checkData = await api.v2.bank.mutatie.checkVerwijderenMutaties(params);
              if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
                return;
              }
              if (
                (await checkStore.bevestigen({ inhoud: 'Verwijderen?' })).type ===
                EResultType.Annuleren
              ) {
                return;
              }

              await api.v2.bank.mutatie.verwijderenMutaties(params);
              props.onVerversenAangevraagd();
            }
          }}
        />

        <SelectionState
          selection={props.selectie}
          onSelectionChange={(x) => props.onSelectieChange(x as number[])}
        />
        <RowDetailState
          expandedRowIds={props.uitgeklapt}
          onExpandedRowIdsChange={(x) => props.onUitgeklaptChange(x as number[])}
        />

        <SortingState defaultSorting={[]} />
        <IntegratedSorting />

        <VirtualTable
          columnExtensions={kolomExtensies}
          messages={geenData}
          rowComponent={(rowProps) => {
            const rij: IOphalenMutatiesResultElement = rowProps.row;

            const duplicaat = props.duplicaatMutaties.duplicaten.find(
              (x) => x.nieuwBankMutID === rij.BankMutID,
            );

            if (duplicaat !== undefined) {
              return <ZoekgemeldTr nietKleuren={false}>{rowProps.children}</ZoekgemeldTr>;
            }

            return <tr>{rowProps.children}</tr>;
          }}
        />
        <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
        <TableHeaderRow showSortingControls />

        <TableEditColumn
          width={35}
          showDeleteCommand
          cellComponent={DXTableEditColumnCellComponent}
          commandComponent={DXTableEditColumnCommandComponent}
        />

        <TableRowDetail
          contentComponent={DetailComp}
          toggleCellComponent={DXTableToggleCellComponent}
        />
        <TableSelection cellComponent={DXTableCheckboxComponent} />
      </Grid>
    </GridStyleWrapper>
  );
});

export default MutatiesTabel;
