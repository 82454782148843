import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import IDialoogProps from '../../../../../../../../core/IDialoogProps';
import Dialoog from '../../../../../../../../components/dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import * as Yup from 'yup';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../../../../../../stores/RootStore';
import Combobox from '../../../../../../../../components/formulier/Combobox';
import nameof from '../../../../../../../../core/nameOf';
import { IOphalenBetaalwijzenResultElement } from '../../../../../../../../../../shared/src/api/v2/betaalwijze';

import api from '../../../../../../../../api';
import { Kleur } from '../../../../../../../../bedrijfslogica/constanten';
import LoadingSpinner from '../../../../../../../../components/Gedeeld/LoadingSpinner';
import { EResultType } from '../../../../../../../../stores/CheckStore';
import BedragInput from '../../../../../../../../components/formulier/BedragInput';
import _ from 'lodash';
import { IOphalenFacturenBasisResultElement } from '../../../../../../../../../../shared/src/api/v2/factuur';
import VeldWeergave from '../../../../../../../../components/formulier/VeldWeergave';
import FormatteerBedrag from '../../../../../../../../components/MutatieBedrag';
import { EBetaalwijze } from '../../../../../../../../bedrijfslogica/enums';
import DatumKiezer from '../../../../../../../../components/formulier/DatumKiezer';
import { dagDatum } from '../../../../../../../../helpers/datum';
import { addDays } from 'date-fns';

interface IDialoogResult {}

interface IProps extends IDialoogProps<IDialoogResult> {
  relID: number;
  factIDs: number[];
}

interface IFormikValues {
  betwijzeID: number;
  termijnbedrag: number;
  datumEersteIncasso: Date;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  betwijzeID: 'Betaalwijze',
  termijnbedrag: 'Termijnbedrag',
  datumEersteIncasso: 'Datum 1e incassoopdracht',
};

const minimaalTermijnbedrag: number = 10;

const ToevoegenDialoog: React.FC<IProps> = observer((props) => {
  const { children, dialoogIndex, onAnnuleren, onSuccess, open, factIDs } = props;
  const { checkStore, klantkaartStore } = useContext(RootStoreContext);

  const [betaalwijzen, setBetaalwijzen] = useState<IOphalenBetaalwijzenResultElement[] | null>(
    null,
  );
  const [facturen, setFacturen] = useState<IOphalenFacturenBasisResultElement[] | null>(null);
  const [termijnbedragGeselecteerd, setTermijnbedragGeselecteerd] = useState<number | null>(null);
  const [termijninfo, setTermijninfo] = useState<{
    totaalbedrag: number;
    termijnbedrag: number;
    laatsteTermijn: number;
    aantalTermijnen: number;
  } | null>(null);

  useEffect(() => {
    (async () => {
      if (facturen === null) {
        return;
      }
      const totaalbedrag = _.sum(facturen.map((x) => x.Openstaand));
      const termijnbedragGeselecteerd = totaalbedrag / 4;
      setTermijnbedragGeselecteerd(termijnbedragGeselecteerd);
    })();
  }, [facturen]);

  useEffect(() => {
    (async () => {
      const result = await api.v2.betaalwijze.ophalenBetaalwijzen({
        filterSchema: { filters: [] },
      });

      setBetaalwijzen(result.betaalwijzen);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const facturen = (
        await api.v2.factuur.ophalenFacturenBasis({
          filterSchema: { filters: [{ naam: 'IDS', data: props.factIDs }] },
        })
      ).facturen;

      setFacturen(facturen);
    })();
  }, [props.factIDs]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      // Per automatische incasso?
      const betaalwijzen = (await api.v2.betaalwijze.ophalenBetaalwijzen({})).betaalwijzen;
      const automatischeIncasso =
        betaalwijzen.find((x) => x.BetWijzeID === values.betwijzeID)!.NaamEnum ===
        EBetaalwijze.Incasso;

      const params = {
        relID: props.relID,
        factIDs: props.factIDs,
        termijnbedrag: values.termijnbedrag,
        betWijzeID: values.betwijzeID,
        eersteVervaldatum: automatischeIncasso ? values.datumEersteIncasso : null,
      };
      const checkData = await api.v2.debiteur.betalingsregeling.checkToevoegenBetalingsregeling(
        params,
      );

      if (values.termijnbedrag < minimaalTermijnbedrag) {
        checkData.errors.push(`Het termijnbedrag moet minimaal ${minimaalTermijnbedrag} zijn`);
      }

      const checkResult = await checkStore.controleren({
        checkData,
      });
      if (checkResult.type === EResultType.Annuleren) {
        onAnnuleren();
        actions.setSubmitting(false);
        return;
      }

      if (automatischeIncasso) {
        if (
          (
            await checkStore.bevestigen({
              inhoud: (
                <span>
                  De betaalwijze is Aut. incasso, er worden daarom direct incasso-opdrachten
                  gemaakt. Doorgaan?
                </span>
              ),
            })
          ).type === EResultType.Annuleren
        ) {
          actions.setSubmitting(false);
          return;
        }
      } else {
        if (
          (
            await checkStore.bevestigen({
              inhoud: 'Betalingsregeling maken?',
            })
          ).type === EResultType.Annuleren
        ) {
          actions.setSubmitting(false);
          return;
        }
      }

      const result = await api.v2.debiteur.betalingsregeling.toevoegenBetalingsregeling(params);
      if (automatischeIncasso) {
        await api.v2.debiteur.betalingsregeling.makenBankopdrachten({ betRglIDs: [result.ID] });
      }

      props.onSuccess({});
      actions.setSubmitting(false);
    },
    [props.relID, props.factIDs],
  );

  const bepaalTermijnInfo = useCallback(async () => {
    if (
      facturen === null ||
      termijnbedragGeselecteerd === null ||
      termijnbedragGeselecteerd === 0
    ) {
      return null;
    }
    const totaalOpenstaand = _.sum(facturen.map((x) => x.Openstaand));
    const termijnbedrag = termijnbedragGeselecteerd;

    // Aantal termijnen naar beneden afgerond
    const aantalTermijnen = Math.floor(totaalOpenstaand / termijnbedragGeselecteerd);

    const laatsteTermijn = totaalOpenstaand - aantalTermijnen * termijnbedragGeselecteerd;

    const result = {
      totaalbedrag: totaalOpenstaand,
      termijnbedrag,
      laatsteTermijn,
      aantalTermijnen,
    };

    setTermijninfo(result);
  }, [facturen, termijnbedragGeselecteerd]);

  useEffect(() => {
    bepaalTermijnInfo();
  }, [bepaalTermijnInfo]);

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (betaalwijzen === null || termijnbedragGeselecteerd === null) {
      return null;
    }

    const termijnbedrag = termijnbedragGeselecteerd; //Math.round(termijninfo.totaalbedrag / 4);

    return {
      betwijzeID: betaalwijzen[0].BetWijzeID,
      termijnbedrag,
      datumEersteIncasso: dagDatum(new Date()),
    };
  }, [betaalwijzen, facturen, termijninfo]);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        betwijzeID: Yup.number().required(),
      }),
    [],
  );

  return (
    <Dialoog index={dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Nieuwe betalingsregeling</ModalTitle>
      </ModalHeader>
      {initialValues !== null &&
        facturen !== null &&
        termijnbedragGeselecteerd !== null &&
        termijninfo !== null &&
        betaalwijzen !== null && (
          <Formik<IFormikValues>
            onSubmit={handleSubmit}
            initialValues={initialValues}
            validationSchema={validationSchema}
            isInitialValid
            render={(formikProps: FormikProps<IFormikValues>) => {
              const { submitForm, isValid, isSubmitting, values } = formikProps;

              return (
                <>
                  <ModalBody>
                    <div className="form-group">
                      <div className="row">
                        <div className="col-12 d-flex">
                          <VeldWeergave>
                            <div className="d-flex">
                              <div>Totaal te voldoen: </div>
                              <div className="ml-2">
                                <FormatteerBedrag bedrag={termijninfo.totaalbedrag} />
                              </div>
                              <div className="ml-1">({facturen.length} facturen)</div>
                            </div>
                            <div>
                              <span className="d-flex mt-1">
                                Het minimaal termijnbedrag is&nbsp;
                                <FormatteerBedrag bedrag={minimaalTermijnbedrag} />
                              </span>
                            </div>
                          </VeldWeergave>
                        </div>

                        <div className="col-3 mt-3">
                          <div className="form-group">
                            <label>{veldnamen.termijnbedrag}</label>
                            <Field
                              name={nameof<IFormikValues>('termijnbedrag')}
                              render={({ field, form }: FieldProps<IFormikValues>) => {
                                return (
                                  <BedragInput
                                    value={termijnbedragGeselecteerd}
                                    onChange={(bedrag) => {
                                      form.setFieldValue(field.name, bedrag);
                                      setTermijnbedragGeselecteerd(bedrag);
                                    }}
                                    // min={minimaalTermijnbedrag}
                                    min={0}
                                    max={termijninfo.totaalbedrag}
                                  />
                                );
                              }}
                            />
                          </div>
                        </div>

                        <div className="col-12 mt-2">
                          <VeldWeergave>
                            <div>
                              {termijninfo.laatsteTermijn === 0 ? (
                                <div className="d-flex">
                                  <span>{termijninfo.aantalTermijnen} termijnen van&nbsp;</span>
                                  <FormatteerBedrag bedrag={termijninfo.termijnbedrag} />
                                  <span></span>
                                </div>
                              ) : (
                                <div className="d-flex">
                                  <span>{termijninfo.aantalTermijnen} termijnen van&nbsp;</span>
                                  <FormatteerBedrag bedrag={termijninfo.termijnbedrag} />
                                  <span>&nbsp;plus 1 termijn van&nbsp;</span>
                                  <FormatteerBedrag bedrag={termijninfo.laatsteTermijn} />
                                </div>
                              )}
                            </div>
                          </VeldWeergave>
                        </div>

                        <div className="col-12 mt-3">
                          <label>{veldnamen.betwijzeID}</label>
                          <Field
                            name={nameof<IFormikValues>('betwijzeID')}
                            render={(fieldProps: FieldProps<IFormikValues>) => {
                              const { field, form } = fieldProps;

                              if (betaalwijzen === null) {
                                return <LoadingSpinner />;
                              }

                              return (
                                <Combobox
                                  geselecteerd={field.value}
                                  opties={betaalwijzen.map((betaalwijze) => {
                                    return {
                                      id: betaalwijze.BetWijzeID,
                                      label: betaalwijze.Naam,
                                    };
                                  })}
                                  onSelectieChange={(x) => form.setFieldValue(field.name, x)}
                                />
                              );
                            }}
                          />
                        </div>

                        {betaalwijzen.find((x) => x.BetWijzeID === values.betwijzeID)!.NaamEnum ===
                          EBetaalwijze.Incasso && (
                          <div className="col-6 mt-3">
                            <label>{veldnamen.datumEersteIncasso}</label>
                            <Field
                              name={nameof<IFormikValues>('datumEersteIncasso')}
                              render={(fieldProps: FieldProps<IFormikValues>) => {
                                const { field, form } = fieldProps;

                                const minimaleDatum = addDays(new Date(), -1);
                                const maximaleDatum = addDays(new Date(), +60);
                                return (
                                  <>
                                    <DatumKiezer
                                      onGewijzigd={(x) => {
                                        form.setFieldValue(field.name, x);
                                      }}
                                      waarde={field.value}
                                      determineValidDate={(date) => {
                                        return date >= minimaleDatum && date <= maximaleDatum;
                                      }}
                                      determineNextValidDate={(date) => {
                                        const nieuweDatum = addDays(date, +1);
                                        if (nieuweDatum <= maximaleDatum) {
                                          return nieuweDatum;
                                        }
                                        return null;
                                      }}
                                      determinePreviousValidDate={(date) => {
                                        const nieuweDatum = addDays(date, -1);
                                        if (nieuweDatum >= minimaleDatum) {
                                          return nieuweDatum;
                                        }
                                        return null;
                                      }}
                                    />
                                  </>
                                );
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </ModalBody>

                  <ModalFooter className="d-flex flex-row justify-content-start">
                    <button
                      className="btn btn-primary"
                      onClick={submitForm}
                      style={{ width: 100 }}
                      disabled={!isValid || isSubmitting}
                    >
                      Ok
                    </button>
                    <button
                      className="btn btn-secondary"
                      onClick={onAnnuleren}
                      style={{ width: 100 }}
                      disabled={isSubmitting}
                    >
                      Annuleren
                    </button>
                  </ModalFooter>
                </>
              );
            }}
          />
        )}
    </Dialoog>
  );
});

export default ToevoegenDialoog;
