import React, { useCallback, useMemo, useState } from 'react';
import { DataTypeProvider, RowDetailState, TableRowDetail } from '@devexpress/dx-react-grid';
import {
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../../../../../helpers/dxTableGrid';
import {
  Grid,
  Table,
  TableColumnResizing,
  TableHeaderRow,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import { VirtualTable as VirtualTableBase } from '@devexpress/dx-react-grid';
import { format } from 'date-fns';
import TabelInspringBlok from '../../../../../../../../components/layout/TabelInspringBlok';
import { IProductVoorAdvies } from '../../../../../../../../../../shared/src/api/v2/product/advies';
import { IRegel } from '..';
import useUrlState from '../../../../../../../../core/useUrlState';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { maandenNaarJaren } from '../../../../../../../../bedrijfslogica/teksten';

interface IProps extends RouteComponentProps, TableRowDetail.ContentProps {}
interface IUrlState {
  uitgeklapt: number[];
}

const defaultUrlState: IUrlState = {
  uitgeklapt: [],
};

const DetailComp = (props: IProps) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);

  const row: IRegel = props.row;
  const producten: IProductVoorAdvies[] = row.producten;

  const kolommen = useMemo<TypedColumn<IProductVoorAdvies>[]>(
    () => [
      {
        name: '__merknaam' as any,
        title: 'Merk',
      },
      {
        name: '__typenaam' as any,
        title: 'Type',
      },
      {
        name: 'Referentiecode',
        title: 'Ref.code',
      },
      {
        name: '__leeftijd' as any,
        title: 'Leeftijd',
      },
      {
        name: '__grenswaarde' as any,
        title: 'Grenswaarde',
      },
    ],
    [],
  );
  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IProductVoorAdvies>[]>(
    () => [
      {
        columnName: '__merknaam' as any,
        width: 110,
      },
      {
        columnName: '__typenaam' as any,
        width: 150,
      },
      {
        columnName: '__leeftijd' as any,
        width: 150,
      },
      {
        columnName: '__grenswaarde' as any,
        width: 150,
      },
      {
        columnName: 'Referentiecode',
        width: 150,
      },
    ],
    [],
  );

  const keyExtractor = useCallback((x: IProductVoorAdvies) => x.ProdID, []);

  return (
    <div className="d-flex">
      <TabelInspringBlok />
      <GridStyleWrapper maxHeight={500} rowAmount={producten.length}>
        <Grid rows={producten} columns={kolommen} getRowId={keyExtractor}>
          <DataTypeProvider
            for={['__merknaam']}
            formatterComponent={(props) => {
              const rij: IProductVoorAdvies = props.row;
              return <span>{rij.producttype.Merknaam}</span>;
            }}
          />

          <DataTypeProvider
            for={['__typenaam']}
            formatterComponent={(props) => {
              const rij: IProductVoorAdvies = props.row;
              return <span>{rij.producttype.Typenaam}</span>;
            }}
          />

          <DataTypeProvider
            for={['__leeftijd']}
            formatterComponent={(props) => {
              const rij: IProductVoorAdvies = props.row;
              if (rij.leeftijd === null) {
                return <span></span>;
              }
              return <span>{maandenNaarJaren(rij.leeftijd)}</span>;
            }}
          />

          <DataTypeProvider
            for={['__grenswaarde']}
            formatterComponent={(props) => {
              const rij: IProductVoorAdvies = props.row;
              const waarde = rij.producttype.productsoort.LeeftijdGrens;
              return <span>{maandenNaarJaren(waarde)}</span>;
            }}
          />

          <VirtualTable />
          <TableColumnResizing defaultColumnWidths={kolomBreedtes} />

          <RowDetailState
            expandedRowIds={urlState.uitgeklapt}
            onExpandedRowIdsChange={(x) => setUrlStateSync('uitgeklapt', x as number[])}
          />
          <TableHeaderRow />
        </Grid>
      </GridStyleWrapper>
    </div>
  );
};

export default withRouter(DetailComp);
