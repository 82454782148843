import * as React from 'react';
import { useContext, useEffect, useMemo } from 'react';
import { RootStoreContext } from '../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import { GlobaleRendererContext } from '../GlobaleRenderer';
import ProductSelectieDialoog from '../../components/product/ProductSelectieDialoog';
import BezoekdagenInfoDialoog from '../../components/transport/BezoekdagenInfoDialoog';
import ProductinfoDialoog from '../../components/product/ProductinfoDialoog';
import RelatieSelectieDialoog from '../../components/personalia/RelatieSelectieDialoog';
import { RouterContext } from '../../routers/RouterContextProvider';

interface ISnelkoppelingStuurtoetsen {
  ctrl?: boolean;
  shift?: boolean;
  alt?: boolean;
  meta?: boolean;
}

interface IUseSnelkoppelingParams {
  stuurtoetsen: ISnelkoppelingStuurtoetsen;
  activatietoets: string;
  handler: () => void;
  // Als de handler functie iets uitvoert waardoor het venster controle verliest (door bijv. naar een ander venster/tabblad te navigeren),
  // Dan zal de snelkoppeling de laatst ingedrukte toets automatisch uitpoetsen aangezien de browser nooit meer zelf
  // het key up event kan afhandelen.
  verliestControle?: boolean;
}

export const useSnelkoppeling = (params: IUseSnelkoppelingParams) => {
  const { toetsenbordStore } = useContext(RootStoreContext);

  const { handler, stuurtoetsen, activatietoets } = params;
  const stuurtoetsenDeps = useMemo(() => {
    return Object.keys(stuurtoetsen).map((key) => {
      switch (key) {
        case 'ctrl':
          return toetsenbordStore.ctrl;
        case 'shift':
          return toetsenbordStore.shift;
        case 'alt':
          return toetsenbordStore.alt;
        case 'meta':
          return toetsenbordStore.meta;
        default:
          throw new Error('Ongeldige stuurtoets');
      }
    });
  }, [toetsenbordStore.ctrl, toetsenbordStore.shift, toetsenbordStore.alt, toetsenbordStore.meta]);
  const stringifiedDeps = useMemo(() => JSON.stringify(stuurtoetsenDeps), stuurtoetsenDeps);

  useEffect(() => {
    const alleStuurtoetsenIngedrukt =
      stuurtoetsenDeps.length === 0 ? true : stuurtoetsenDeps.every((ingedrukt) => ingedrukt);

    // console.log(
    //   `useSnelkoppeling voor ${activatietoets} (ingedrukt: ${
    //     toetsenbordStore.key === activatietoets ? 'ja' : 'nee'
    //   }): alle stuurtoetsen: ${alleStuurtoetsenIngedrukt ? 'ja' : 'nee'}`,
    // );

    if (!alleStuurtoetsenIngedrukt || toetsenbordStore.key !== activatietoets) {
      return;
    }
    handler();
    // Als verliest controle aan staat, dan uitpoetsen toets
    if (params.verliestControle) {
      // console.log(
      //   'useSnelkoppeling heeft aangegeven controle te verliezen, automatisch uitpoetsen',
      // );
      toetsenbordStore.setKey(null);
    }
  }, [stringifiedDeps, activatietoets, handler, toetsenbordStore.key]);
};

const SnelkoppelingAfhandelaar = observer(() => {
  const globaleRenderer = useContext(GlobaleRendererContext);
  const { navigeerKlantkaartNaarRelID } = useContext(RouterContext);

  useSnelkoppeling(
    useMemo(
      () => ({
        activatietoets: 'p',
        stuurtoetsen: {
          ctrl: true,
          shift: true,
        },
        handler: () => {
          globaleRenderer.render((props) => (
            <ProductSelectieDialoog
              open
              onSuccess={(result) => {
                props.destroy();

                globaleRenderer.render((props) => (
                  <ProductinfoDialoog
                    open
                    id={result.prodID}
                    onSuccess={() => props.destroy()}
                    onAnnuleren={() => props.destroy()}
                  />
                ));
              }}
              onAnnuleren={() => props.destroy()}
            />
          ));
        },
      }),
      [],
    ),
  );

  useSnelkoppeling(
    useMemo(
      () => ({
        activatietoets: 'b',
        stuurtoetsen: {
          ctrl: true,
          shift: true,
        },
        handler: () => {
          globaleRenderer.render((props) => (
            <BezoekdagenInfoDialoog
              open
              onSuccess={() => props.destroy()}
              onAnnuleren={() => props.destroy()}
            />
          ));
        },
      }),
      [],
    ),
  );

  // useSnelkoppeling(
  //   useMemo(
  //     () => ({
  //       activatietoets: 'f',
  //       stuurtoetsen: {
  //         ctrl: true,
  //         shift: true,
  //       },
  //       handler: () => {
  //         globaleRenderer.render((props) => (
  //           <RelatieSelectieDialoog
  //             open
  //             onSuccess={async (result) => {
  //               props.destroy();
  //
  //               // alert(result.relatie.RelID);
  //               await navigeerKlantkaartNaarRelID(result.relatie.RelID);
  //             }}
  //             onAnnuleren={() => props.destroy()}
  //           />
  //         ));
  //       },
  //     }),
  //     [navigeerKlantkaartNaarRelID],
  //   ),
  // );

  useSnelkoppeling(
    useMemo(
      () => ({
        activatietoets: 'h',
        stuurtoetsen: {
          ctrl: true,
          shift: true,
        },
        verliestControle: true,
        handler: () => {
          // const url = `${bedrijf.website.url}/nl/product/${productmodel.ProdModID}`;
          const url = `https://splash-lease.gitbook.io/asp-systeem/`;
          window.open(url, '_blank');
        },
      }),
      [],
    ),
  );

  return null;
});

export default SnelkoppelingAfhandelaar;
