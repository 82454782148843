import styled from 'styled-components';

export const Root = styled.div`
  flex: 1;
  //position: absolute;
  //bottom: 0;
  width: 100%;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  background-color: white;
  padding: 10px 10px;
`;

interface IInputContainerProps {
  maxKaraktersWeergegeven: boolean;
}

export const InputContainer = styled.div<IInputContainerProps>`
  display: flex;
  textarea {
    //border: 0;
    //border-bottom: 1px solid #b8b8b8;
    //outline: 0;
    width: 100%;
    ${(props) => (props.maxKaraktersWeergegeven ? `padding-bottom: 15px;` : '')}
  }
  width: 100%;

  .max-karakters-container {
    min-width: 150px;
    margin-left: -150px;
    position: relative;
    /* text-align: right; */
    right: 10px;
    bottom: 1px;
    /* top: 1px; */
    font-size: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    pointer-events: none;
  }
`;
