import { IWeergaveProps } from '../../../../../../../components/FilterBalkV2';
import React from 'react';

const ZoektermFilter = (p: IWeergaveProps<any>) => {
  // const { contractstatussenResult } = useContext(ContractenOverzichtContext);

  return (
    <span className="d-flex align-items-center" style={{ width: 250 }}>
      <span className="mr-3">Zoekterm</span>
      <input
        placeholder="Bijv. SPL-6145 of WNU78-XT"
        className="form-control"
        value={p.state || ''}
        onChange={(ev) => p.onStateChange(ev.target.value)}
        onKeyUp={(ev) => {
          if (ev.key === 'Enter') {
            p.onDataChange(p.state);
            p.toepassen();
          }
        }}
      />
    </span>
  );
};

export default ZoektermFilter;
