import { Field, FieldProps } from 'formik';
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import ModalBody from 'react-bootstrap/ModalBody';
// import { IFormikValues, veldnamen } from '.';
import nameof from '../../../../../core/nameOf';
import VinkVeld from '../../../../../components/formulier/VinkVeld';
import { GridStyleWrapper, TypedColumn } from '../../../../../helpers/dxTableGrid';
import { Grid, Table, TableHeaderRow } from '@devexpress/dx-react-grid-bootstrap4';
import { IOphalenGekoppeldeProductenResultElement } from '../../../../../../../shared/src/api/v2/inkoopfactuur';

const ProductenTab: React.FC = (props) => {
  const kolommenProducten = useMemo<TypedColumn<IOphalenGekoppeldeProductenResultElement>[]>(
    () => [
      {
        name: '__typenaam' as any,
        title: 'Type',
      },
    ],
    [],
  );

  return (
    <ModalBody>
      {/* <div style={{ minHeight: `400px` }}>
        <div className="form-group">
          <div className="row">
            <div className="col-12">
              <div className="d-flex flex-column flex-fill mt-5">
                <GridStyleWrapper maxHeight={500} rowAmount={1}>
                  <Grid rows={[]} columns={kolommenProducten}>
                    <Table />
                    <TableHeaderRow />
                  </Grid>
                </GridStyleWrapper>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </ModalBody>
  );
};

export default ProductenTab;
