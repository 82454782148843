import api from '../../index';
import {
  ICheckToevoegenFaqGroepParams,
  ICheckWijzigenFaqGroepParams,
  IOphalenFaqGroepParams,
  IOphalenFaqGroepResult,
  IOphalenFaqGroepenParams,
  IOphalenFaqGroepenResult,
  IToevoegenFaqGroepParams,
  IToevoegenFaqGroepResult,
  IVerwijderenFaqGroepParams,
  IWijzigenFaqGroepParams,
} from '../../../../../shared/src/api/v2/faqGroep';
import ICheckData from '../../../../../shared/src/models/ICheckData';
import { ISorterenParams } from '../../../../../shared/src/api/v2/sorteren';

const faqGroep = {
  // ophalen
  ophalenFaqGroep: async (params: IOphalenFaqGroepParams): Promise<IOphalenFaqGroepResult> => {
    return await api.post('/v2/faq-groep/ophalen-faq-groep', params);
  },
  ophalenFaqGroepen: async (
    params: IOphalenFaqGroepenParams,
  ): Promise<IOphalenFaqGroepenResult> => {
    return await api.post('/v2/faq-groep/ophalen-faq-groepen', params);
  },

  // toevoegen
  checkToevoegenFaqGroep: async (params: ICheckToevoegenFaqGroepParams) => {
    return await api.post<ICheckData>('v2/faq-groep/check-toevoegen-faq-groep', params);
  },
  toevoegenFaqGroep: async (params: IToevoegenFaqGroepParams) => {
    return await api.post<IToevoegenFaqGroepResult>('v2/faq-groep/toevoegen-faq-groep', params);
  },

  // wijzigen
  checkWijzigenFaqGroep: async (params: ICheckWijzigenFaqGroepParams) => {
    return await api.post<ICheckData>('v2/faq-groep/check-wijzigen-faq-groep', params);
  },
  wijzigenFaqGroep: async (params: IWijzigenFaqGroepParams) => {
    return await api.post<any>('v2/faq-groep/wijzigen-faq-groep', params);
  },

  // sorteren
  sorterenFaqGroep: async (params: ISorterenParams) => {
    return await api.post<any>('v2/faq-groep/sorteren-faq-groep', params);
  },

  // verwijderen
  checkVerwijderenFaqGroep: async (params: IVerwijderenFaqGroepParams) => {
    return await api.post<ICheckData>('v2/faq-groep/check-verwijderen-faq-groep', params);
  },
  verwijderenFaqGroep: async (params: IVerwijderenFaqGroepParams) => {
    return await api.post<any>('v2/faq-groep/verwijderen-faq-groep', params);
  },
};

export default faqGroep;
