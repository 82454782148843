import React from 'react';
import { ICustomFilter } from '../types';

interface IProps<TCustomFilterId, TId, TEntiteit extends {}> {
  customFilters: ICustomFilter<TCustomFilterId, TId, TEntiteit>[];
  onCustomFilterGekozen: (filter: ICustomFilter<TCustomFilterId, TId, TEntiteit>) => void;
}

const CustomFiltersWeergave = <TCustomFilterId, TId, TEntiteit extends {}>(
  props: IProps<TCustomFilterId, TId, TEntiteit>,
) => {
  const { customFilters, onCustomFilterGekozen } = props;
  return (
    <div style={{ width: '100%' }}>
      {customFilters.length === 0 ? (
        <div className="p-2">Geen filters beschikbaar.</div>
      ) : (
        customFilters.map((filter, i) => {
          return (
            <div key={i} className={i === 0 ? '' : 'mt-2'} style={{ width: '100%' }}>
              <button
                className="btn btn-sm btn-light"
                style={{ width: '100%' }}
                onClick={() => onCustomFilterGekozen(filter)}
              >
                {filter.weergaveNaam}
              </button>
            </div>
          );
        })
      )}
    </div>
  );
};

export default CustomFiltersWeergave;
