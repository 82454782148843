import React, { useEffect, useMemo, useState } from 'react';
import {
  IOphalenProducttypenResult,
  IOphalenProducttypenResultElement,
} from '../../../../../shared/src/api/v2/product/type';
import MultiCombobox, { IKolom } from '../MultiCombobox';
import BooleanWeergave from '../../tabel/BooleanWeergave';

interface IProps {
  typeID: number | null;
  onChange: (typeID: number | null) => void;
  resolve: () => Promise<IOphalenProducttypenResult>;
}

const ProducttypeSelectie: React.FC<IProps> = (props) => {
  const [producttypenResult, setProducttypenResult] = useState<IOphalenProducttypenResult | null>(
    null,
  );

  useEffect(() => {
    (async () => {
      const result = await props.resolve();
      setProducttypenResult(result);
    })();
  }, [props.resolve]);

  const kolommen = useMemo<IKolom<IOphalenProducttypenResultElement>[]>(() => {
    return [
      {
        key: 'Typenaam',
        label: 'Type',
        breedte: 200,
      },
      {
        key: 'Merknaam',
        label: 'Merk',
        breedte: 100,
      },
      {
        key: 'InkoopToegestaan',
        label: 'Inkoopwaardig',
        breedte: 150,
        formatFabriek: (row) => <BooleanWeergave waarde={row.InkoopToegestaan} />,
      },
    ];
  }, []);

  return (
    <MultiCombobox<number, IOphalenProducttypenResultElement>
      sleutelExtractor={(row) => row.TypeID}
      waarde={props.typeID}
      opties={producttypenResult === null ? null : producttypenResult.producttypen}
      kolommen={kolommen}
      onWaardeChange={props.onChange}
      representatieFabriek={(row) => `${row.Merknaam} - ${row.Typenaam}`}
      sorterenToestaan
    />
  );
};

export default ProducttypeSelectie;
