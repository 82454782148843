import api from '../../index';
import { IZoekParams, IZoekResult } from '../../../../../shared/src/api/v2/zoek';

const zoek = {
  zoek: async (params: IZoekParams): Promise<IZoekResult> => {
    return await api.post('v2/zoek/zoek', params);
  },
};

export default zoek;
