import React from 'react';
import { IUitgeklapteInhoudProps } from '../..';
import GegevensLayout from '../../../../components/layout/GegevensLayout';
import { IPotentieelOntvangenTelefoonnotitieItem } from '../../../../../../shared/src/api/v2/externeData';

const TelefoonnotitieUitgeklapteInhoud = <TRow extends any>(
  props: IUitgeklapteInhoudProps<TRow, IPotentieelOntvangenTelefoonnotitieItem>,
) => {
  const naam = props.item.Voornaam || '' + props.item.Achternaam || '';

  return (
    <GegevensLayout
      gegevens={[
        {
          label: 'Bedrijf',
          waarde: props.item.Bedrijfsnaam || '',
        },
        {
          label: 'Naam',
          waarde: naam,
        },
        {
          label: 'Adres 1',
          waarde: (props.item.Straatnaam || '' + ' ' + props.item.Huisnummer || '').trim(),
        },
        {
          label: 'Adres 2',
          waarde: (props.item.Postcode || '' + ' ' + props.item.Plaatsnaam || '').trim(),
        },
        {
          label: 'Email',
          waarde: props.item.Email || '',
        },
        {
          label: 'Telefoon',
          waarde: props.item.Telefoonnummer || '',
        },
      ]}
    />
  );
};

export default TelefoonnotitieUitgeklapteInhoud;
