import React, { useRef, useState } from 'react';
import { Kleur } from '../../../bedrijfslogica/constanten';
import { TwitterPicker } from 'react-color';
import nameof from '../../../core/nameOf';
import { Overlay } from 'react-bootstrap';
import Popover from 'react-bootstrap/Popover';
import { IconVerwijderen } from '../../Icons';

interface IProps {
  kleur: string | null;
  onChange: (kleur: string | null) => void;
}

const KleurSelectie = (props: IProps) => {
  const target = useRef<HTMLButtonElement | null>(null);

  const [toonPicker, setToonPicker] = useState(false);

  return (
    <div className="w-100">
      <button
        ref={target}
        className="d-flex align-items-center justify-content-center"
        style={{
          borderRadius: 3,
          backgroundColor: props.kleur ?? '#FFF',
          border: `1px solid ${Kleur.LichtGrijs}`,
          width: '100%',
          height: 30,
          marginBottom: 18,
          outline: 0,
        }}
        onClick={() => setToonPicker(!toonPicker)}
      >
        {props.kleur === null && <span className="text-muted">Geen kleur gekozen</span>}
      </button>
      <Overlay
        target={target}
        show={toonPicker}
        onHide={() => {
          setToonPicker(false);
        }}
        rootClose
        placement="bottom"
      >
        <Popover id={'kleur-selectie'} onClick={(ev) => ev.stopPropagation()}>
          <TwitterPicker
            color={props.kleur ?? '#FFF'}
            onChangeComplete={(value) => {
              setToonPicker(false);
              props.onChange(value.hex);
            }}
            triangle="hide"
          />
          {props.kleur !== null && (
            <button
              className="btn btn-light d-flex align-items-center justify-content-center w-100"
              style={{
                border: `1px solid ${Kleur.LichtGrijs}`,
                backgroundColor: Kleur.Wit,
              }}
              onClick={() => {
                setToonPicker(false);
                props.onChange(null);
              }}
            >
              <IconVerwijderen style={{ fill: Kleur.Grijs, width: 18, height: 18 }} />
              <span className="ml-2">Kleur verwijderen</span>
            </button>
          )}
        </Popover>
      </Overlay>
    </div>
  );
};

export default KleurSelectie;
