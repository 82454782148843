import { makeObservable, observable } from 'mobx';
import IRemoteData, { createPendingRemoteData, createReadyRemoteData } from '../models/IRemoteData';
import * as _ from 'lodash';
import api from '../api';
import { IOphalenVerkoopVoorstellenResultElement } from '../../../shared/src/api/v2/product/verkoop';

class VerkoopvoorstelStore {
  public verkoopvoorstellen: IRemoteData<
    Record<number, IOphalenVerkoopVoorstellenResultElement>
  > = createPendingRemoteData();
  private ophalenVerkoopvoorstellenQueue: number[] = [];
  private ophalenVerkoopvoorstellenQueueDebounceHandle: number | null = 0;

  constructor() {
    makeObservable(this, {
      verkoopvoorstellen: observable,
    });
  }

  public enqueueOphalenVerkoopvoorstellen = (verkVrstIDs: number[]) => {
    if (this.ophalenVerkoopvoorstellenQueueDebounceHandle !== null) {
      clearTimeout(this.ophalenVerkoopvoorstellenQueueDebounceHandle);
    }
    this.ophalenVerkoopvoorstellenQueue = _.uniq([
      ...this.ophalenVerkoopvoorstellenQueue,
      ...verkVrstIDs,
    ]);
    this.ophalenVerkoopvoorstellenQueueDebounceHandle = setTimeout(
      this.ophalenQueuedVerkoopvoorstellen,
      100,
    ) as any;
  };
  private ophalenQueuedVerkoopvoorstellen = async () => {
    this.ophalenVerkoopvoorstellenQueueDebounceHandle = null;
    const verkVrstIDs = this.ophalenVerkoopvoorstellenQueue;

    if (verkVrstIDs.length === 0) {
      return;
    }

    this.ophalenVerkoopvoorstellenQueue = [];

    const result = await api.v2.product.verkoop.ophalenVerkoopvoorstellen({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: verkVrstIDs,
          },
        ],
      },
    });
    this.verkoopvoorstellen = createReadyRemoteData(
      result.voorstellen.reduce((acc, curr) => {
        return {
          ...acc,
          [curr.ID]: curr,
        };
      }, this.verkoopvoorstellen.data ?? {}),
    );
  };
}

export default VerkoopvoorstelStore;
