import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import Dialoog from '../../../../components/dialogen/Dialoog';
import IDialoogProps from '../../../../core/IDialoogProps';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import { Field, FieldProps, Formik, FormikActions, FormikErrors } from 'formik';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import nameOf from '../../../../core/nameOf';
import FormikVeldFout from '../../../../components/formulier/FormikVeldFout';
import VinkVeld from '../../../../components/formulier/VinkVeld';
import { IOphalenMedewerkersResultElement } from '../../../../../../shared/src/api/v2/medewerker';
import api from '../../../../api';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import { RootStoreContext } from '../../../../stores/RootStore';
import { EResultType } from '../../../../stores/CheckStore';

interface IDialoogResult {}

interface IProps extends IDialoogProps<IDialoogResult> {
  mdwID: number;
}

interface IFormikValues {
  emailPriveGebruiken: boolean;
  emailPrive: string | null;
  iban: string | null;
  rekeningnaam: string | null;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  emailPriveGebruiken: 'Gebruik prive emailadres',
  emailPrive: 'Prive emailadres',
  iban: 'IBAN',
  rekeningnaam: 'Rekeningnaam',
};

const WijzigenDialoog: React.FC<IProps> = (props) => {
  const [medewerker, setMedewerker] = useState<IOphalenMedewerkersResultElement | null>(null);
  const { checkStore, instellingStore } = useContext(RootStoreContext);

  const ophalenMedewerker = useCallback(async () => {
    const medewerkersResult = (
      await api.v2.medewerker.ophalenMedewerkers({
        filterSchema: {
          filters: [{ naam: 'IDS', data: [props.mdwID] }],
        },
      })
    ).medewerkers;

    setMedewerker(medewerkersResult[0]);
  }, [props.mdwID]);

  useEffect(() => {
    ophalenMedewerker();
  }, [ophalenMedewerker]);

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (medewerker === null) {
      return null;
    }

    return {
      emailPriveGebruiken: medewerker.EmailPriveGebruiken,
      emailPrive: medewerker.EmailPrive,
      iban: medewerker.IBAN,
      rekeningnaam: medewerker.Rekeningnaam,
    };
  }, [medewerker]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);
      const params = {
        mdwID: props.mdwID,
        emailPrive: values.emailPrive,
        emailPriveGebruiken: values.emailPriveGebruiken,
        iban: values.iban,
        rekeningnaam: values.rekeningnaam,
      };

      const checkData = await api.v2.medewerker.checkWijzigenMedewerker(params);
      if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
        actions.setSubmitting(false);
        return;
      }
      if (
        (
          await checkStore.bevestigen({
            inhoud: <span>Wijzigingen vastleggen?</span>,
          })
        ).type === EResultType.Annuleren
      ) {
        actions.setSubmitting(false);
        return;
      }

      await api.v2.medewerker.wijzigenMedewerker(params);

      actions.setSubmitting(false);

      props.onSuccess({});
    },
    [],
  );

  if (initialValues === null) {
    return (
      <div className="d-flex flex-fill align-items-center justify-content-center pt-4">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <Dialoog index={props.dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Wijzigen medewerker</ModalTitle>
      </ModalHeader>
      <Formik<IFormikValues>
        onSubmit={handleSubmit}
        initialValues={initialValues}
        render={(formikProps) => {
          const { submitForm, isSubmitting } = formikProps;
          return (
            <>
              <ModalBody>
                <div className="col-12">
                  <label>{veldnamen.iban}</label>
                  <Field
                    name={nameOf<IFormikValues>('iban')}
                    render={(fieldProps: FieldProps) => {
                      const { field, form } = fieldProps;
                      return (
                        <div>
                          <input className="form-control" {...fieldProps.field} />
                          <FormikVeldFout fieldProps={fieldProps} />
                        </div>
                      );
                    }}
                  />
                </div>

                <div className="col-12 mt-3">
                  <label>{veldnamen.rekeningnaam}</label>
                  <Field
                    name={nameOf<IFormikValues>('rekeningnaam')}
                    render={(fieldProps: FieldProps) => {
                      const { field, form } = fieldProps;
                      return (
                        <div>
                          <input className="form-control" {...fieldProps.field} />
                          <FormikVeldFout fieldProps={fieldProps} />
                        </div>
                      );
                    }}
                  />
                </div>

                <div className="col-12 mt-3">
                  <label>{veldnamen.emailPrive}</label>
                  <Field
                    name={nameOf<IFormikValues>('emailPrive')}
                    render={(fieldProps: FieldProps) => {
                      const { field, form } = fieldProps;
                      return (
                        <div>
                          <input className="form-control" {...fieldProps.field} />
                          <FormikVeldFout fieldProps={fieldProps} />
                        </div>
                      );
                    }}
                  />
                </div>

                <div className="col-12 mt-3">
                  <Field
                    name={nameOf<IFormikValues>('emailPriveGebruiken')}
                    render={(fieldProps: FieldProps<IFormikValues>) => {
                      const { field, form } = fieldProps;
                      return (
                        <label className="d-flex">
                          <VinkVeld
                            aangevinkt={field.value}
                            onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                          />
                          <span className="ml-2">{veldnamen.emailPriveGebruiken}</span>
                        </label>
                      );
                    }}
                  />
                </div>
              </ModalBody>
              <ModalFooter className="d-flex flex-row justify-content-start">
                <button
                  className="btn btn-primary d-flex align-items-center justify-content-center"
                  onClick={submitForm}
                  style={{ width: 100 }}
                  disabled={isSubmitting}
                >
                  Ok
                </button>
                <button
                  className="btn btn-secondary"
                  onClick={props.onAnnuleren}
                  style={{ width: 100 }}
                  disabled={isSubmitting}
                >
                  Annuleren
                </button>
              </ModalFooter>
            </>
          );
        }}
      />
    </Dialoog>
  );
};

export default WijzigenDialoog;
