import { makeObservable, observable } from 'mobx';

class MemoryCachedStore {
  public cache: Record<string, any> = {};

  constructor() {
    makeObservable(this, {
      cache: observable,
    });
  }

  public store = (key: string, value: any) => {
    this.cache[key] = value;
  };

  public get = (key: string): any => {
    return this.cache[key];
  };
}

export default MemoryCachedStore;
