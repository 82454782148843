import React, { useCallback, useEffect, useMemo, useState } from 'react';
import IDialoogProps from '../../../core/IDialoogProps';
import Dialoog from '../Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import Tabblad, { ITabblad } from '../../layout/Tabblad';
import ZoekenTabblad from './ZoekenTabblad';
import RecentTabblad from './RecentTabblad';
import api from '../../../api';
import { IFilterSchemaFilter } from '../../../../../shared/src/models/filter';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
} from '../../../models/IRemoteData';
import { IOphalenProducttypenResult } from '../../../../../shared/src/api/v2/product/type';

export interface IDialoogOutput {
  prodTypeID: number;
}

enum ETabblad {
  Zoeken,
  Recent,
}

export interface IProducttypeSelectieDialoogContext {
  zoekterm: string;
  setZoekterm: (zoekterm: string) => void;
  prodSrtID: number | null;
  setProdSrtID: (prodSrtID: number | null) => void;
  producttypenResult: IRemoteData<IOphalenProducttypenResult>;
  onProdTypeIDGekozen: (prodTypeID: number) => void;
}

export const ProducttypeSelectieDialoogContext = React.createContext<
  IProducttypeSelectieDialoogContext
>(null as any);

interface IProps extends IDialoogProps<IDialoogOutput> {
  zoekterm?: string;
}

const ProducttypeSelectieDialoog = (props: IProps): JSX.Element => {
  const [tabblad, setTabblad] = useState<ETabblad>(ETabblad.Zoeken);
  const [zoekterm, setZoekterm] = useState<string>(props.zoekterm ?? '');
  const [prodSrtID, setProdSrtID] = useState<number | null>(null);
  const [producttypenResult, setProducttypenResult] = useState<
    IRemoteData<IOphalenProducttypenResult>
  >(createPendingRemoteData());

  const tabbladen = useMemo<ITabblad<ETabblad>[]>(
    () => [
      {
        id: ETabblad.Zoeken,
        label: 'Zoeken',
        content: ZoekenTabblad,
      },
      {
        id: ETabblad.Recent,
        label: 'Recent',
        content: RecentTabblad,
      },
    ],
    [],
  );

  const handleWaardenGewijzigd = useCallback(async () => {
    const z = zoekterm.trim();

    const result = await api.v2.product.type.ophalenProducttypen({
      filterSchema: {
        filters: [
          z.length === 0
            ? null
            : {
                naam: 'ZOEKTERM',
                data: z,
              },
          prodSrtID === null
            ? null
            : {
                naam: 'PRODSRT_IDS',
                data: [prodSrtID],
              },
        ].filter((x) => x !== null) as IFilterSchemaFilter[],
      },
    });
    setProducttypenResult(createReadyRemoteData(result));
  }, [zoekterm, prodSrtID]);

  useEffect(() => {
    handleWaardenGewijzigd();
  }, [handleWaardenGewijzigd]);

  const context = useMemo<IProducttypeSelectieDialoogContext>(
    () => ({
      zoekterm,
      setZoekterm,
      prodSrtID,
      setProdSrtID,
      producttypenResult,
      onProdTypeIDGekozen: (id) =>
        props.onSuccess({
          prodTypeID: id,
        }),
    }),
    [zoekterm, setZoekterm, prodSrtID, setProdSrtID, producttypenResult, props.onSuccess],
  );

  return (
    <ProducttypeSelectieDialoogContext.Provider value={context}>
      <Dialoog index={props.dialoogIndex ?? 0}>
        <ModalHeader>
          <ModalTitle>Producttype selectie</ModalTitle>
        </ModalHeader>
        <ModalBody className="p-0">
          <Tabblad geselecteerd={tabblad} onSelectieChange={setTabblad} tabbladen={tabbladen} />
        </ModalBody>
        <ModalFooter className="d-flex flex-row justify-content-start">
          {/*<button*/}
          {/*  className="btn btn-primary d-flex align-items-center justify-content-center"*/}
          {/*  onClick={handleOkClick}*/}
          {/*  style={{ width: 100 }}*/}
          {/*>*/}
          {/*  Ok*/}
          {/*</button>*/}
          <button
            className="btn btn-secondary"
            onClick={props.onAnnuleren}
            style={{
              width: 100,
            }}
          >
            Annuleren
          </button>
        </ModalFooter>
      </Dialoog>
    </ProducttypeSelectieDialoogContext.Provider>
  );
};

export default ProducttypeSelectieDialoog;
