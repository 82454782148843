import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import api from '../../../api';
import { RouteComponentProps } from 'react-router';
import LoadingSpinner from '../../Gedeeld/LoadingSpinner';
import _ from 'lodash';
import {
  DXCommandComponent,
  DXTableCheckboxComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../helpers/dxTableGrid';
import {
  DataTypeProvider,
  EditingState,
  RowDetailState,
  SelectionState,
} from '@devexpress/dx-react-grid';
import { format } from 'date-fns';
import {
  Grid,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import useUrlState from '../../../core/useUrlState';
import { Kleur } from '../../../bedrijfslogica/constanten';
import { RootStoreContext } from '../../../stores/RootStore';
import MenuLayout from '../../MenuLayout';
import nameof from '../../../core/nameOf';
import {
  IOphalenMutatiesoortenResultElement,
  IOphalenMutatiesResultElement,
} from '../../../../../shared/src/api/v2/magazijn/mutatie';
import { EMagazijnMutatiesoort } from '../../../bedrijfslogica/enums';
import { withRouter } from 'react-router-dom';
import RelatieVisualisatie from '../../personalia/RelatieVisualisatie';
import FilterBalkV2, { IFilter, IFilterData, maakFilterSchema } from '../../FilterBalkV2';
import { IFilterSchema } from '../../../../../shared/src/models/filter';
import { IOphalenProducttypenInMutatiesResultElement } from '../../../../../shared/src/api/v2/voorraad';
import MultiCombobox, { IKolom } from '../../formulier/MultiCombobox';
import nameOf from '../../../core/nameOf';
import MultiSelect from '../../formulier/MultiSelect';
import ProducttypeFilterCombobox from './ProducttypeFilterCombobox';

interface IProps extends RouteComponentProps {}

export interface IRow extends IOphalenMutatiesResultElement {}

enum EFilter {
  Producttype = 'TYPE_IDS',
  Mutatiesoort = 'MUTSRT_IDS',
}

interface IUrlState {
  selectie: number[];
  filterdata: IFilterData<EFilter>[];
}

const geenData = {
  noData: 'Geen mutaties gevonden',
};

const Mutaties: React.FC<IProps> = (props) => {
  const [mutatiesoorten, setMutatiesoorten] = useState<
    IOphalenMutatiesoortenResultElement[] | null
  >(null);

  const ophalenMutatiesoorten = useCallback(async () => {
    const mutatiesoorten = (
      await api.v2.magazijn.mutatie.ophalenMutatiesoorten({
        // orderSchema: { orders: [{ naam: 'RECORD_TOEGEVOEGD', richting: 'DESC' }] },
      })
    ).mutatiesoorten;

    setMutatiesoorten(mutatiesoorten);
  }, []);

  useEffect(() => {
    ophalenMutatiesoorten();
  }, [ophalenMutatiesoorten]);

  if (mutatiesoorten === null) {
    return <LoadingSpinner />;
  }

  return <MutatiesX {...props} mutatiesoorten={mutatiesoorten} />;
};

interface IMutatiesContent extends RouteComponentProps {
  mutatiesoorten: IOphalenMutatiesoortenResultElement[];
}

const MutatiesX = (props: IMutatiesContent) => {
  const defaultUrlState = useMemo<IUrlState>(
    () => ({
      selectie: [],
      filterdata: [
        {
          naam: EFilter.Producttype,
          isActief: false,
          data: [],
        },
        {
          naam: EFilter.Mutatiesoort,
          isActief: true,
          data: props.mutatiesoorten
            .filter(
              (x) =>
                [
                  EMagazijnMutatiesoort.Zoekmelding,
                  EMagazijnMutatiesoort.Inkoop,
                  EMagazijnMutatiesoort.LeveringKlant,
                ].indexOf(x.NaamEnum as EMagazijnMutatiesoort) !== -1,
            )
            .map((x) => x.ID),
        },
      ],
    }),
    [props.mutatiesoorten],
  );

  const { checkStore } = useContext(RootStoreContext);
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const [mutaties, setMutaties] = useState<IRow[] | null>(null);

  const [filterSchema, setFilterSchema] = useState<IFilterSchema>(
    maakFilterSchema(urlState.filterdata),
  );

  const ophalenMutatiesAbortController = useRef<AbortController | null>(null);
  const ophalenMutaties = useCallback(async () => {
    if (ophalenMutatiesAbortController.current !== null) {
      ophalenMutatiesAbortController.current.abort();
    }
    const abortController = new AbortController();
    ophalenMutatiesAbortController.current = abortController;

    const result = await api.v2.magazijn.mutatie.ophalenMutaties(
      {
        filterSchema,
        orderSchema: { orders: [{ naam: 'RECORD_TOEGEVOEGD', richting: 'DESC' }] },
        paginatie: {
          aantal: 500,
          index: 0,
        },
      },
      abortController.signal,
    );
    const mutaties = result.mutaties;

    setMutaties(mutaties);
  }, [filterSchema]);

  useEffect(() => {
    ophalenMutaties();
  }, [ophalenMutaties]);

  const keyExtractor = useCallback((row: IRow) => row.ID, []);

  const kolommen = useMemo<TypedColumn<IRow>[]>(
    () => [
      {
        name: 'Mutatiedatum',
        title: 'Mut.datum',
      },
      {
        name: '__magazijn',
        title: 'Mag.',
      },
      {
        name: '__merk' as any,
        title: 'Merk',
      },
      {
        name: '__type' as any,
        title: 'Type',
      },
      // {
      //   name: '__zoekgemeld' as any,
      //   title: 'Zoek',
      // },
      {
        name: '__categorie' as any,
        title: 'Cat.',
      },
      {
        name: 'Nieuw',
        title: 'N/G',
      },
      {
        name: 'Referentiecode',
        title: 'Ref.code',
      },
      {
        name: 'Aantal',
        title: 'Aantal',
      },
      {
        name: '__productstatus',
        title: 'Prod.status',
      },
      {
        name: 'MutatiesoortNaam',
        title: 'Soort',
      },
      {
        name: '__entiteit' as any,
        title: ' ',
      },
      // {
      //   name: '__bron',
      //   title: 'Bron',
      // },
      {
        name: 'RecordToegevoegd',
        title: 'Geregisteerd',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRow>[]>(
    () => [
      {
        columnName: 'Mutatiedatum',
        width: 100,
      },
      {
        columnName: '__magazijn',
        width: 100,
      },
      {
        columnName: 'Aantal',
        width: 75,
      },
      {
        columnName: 'MutatiesoortNaam',
        width: 125,
      },
      {
        columnName: '__entiteit' as any,
        width: 175,
      },
      {
        columnName: '__categorie' as any,
        width: 80,
      },
      {
        columnName: '__merk' as any,
        width: 125,
      },
      {
        columnName: '__type' as any,
        width: 175,
      },
      {
        columnName: '__zoekgemeld' as any,
        width: 80,
      },
      {
        columnName: 'Referentiecode',
        width: 115,
      },
      {
        columnName: 'Nieuw',
        width: 85,
      },
      // {
      //   columnName: '__bron' as any,
      //   width: 300,
      // },
      {
        columnName: '__productstatus' as any,
        width: 100,
      },
      {
        columnName: 'RecordToegevoegd',
        width: 150,
      },
    ],
    [],
  );

  // const producttypeFilterSelectieResolver = useCallback(async () => {
  //   if (producttypen === null) {
  //     return { producttypen: [] };
  //   }

  //   const typeIDs = producttypen.map((x) => x.TypeID);
  //   return await api.v2.product.type.ophalenProducttypen({
  //     filterSchema: {
  //       filters: [
  //         {
  //           naam: 'IDS',
  //           data: typeIDs,
  //         },
  //       ],
  //     },
  //   });
  // }, [producttypen]);
  const producttypenKolommen = useMemo<
    IKolom<IOphalenProducttypenInMutatiesResultElement>[]
  >(() => {
    return [
      {
        key: 'Typenaam',
        label: 'Type',
        breedte: 150,
      },
      {
        key: 'Merknaam',
        label: 'Merk',
        breedte: 100,
      },
      {
        key: 'Productsoortnaam',
        label: 'Cat.',
        breedte: 150,
      },
    ];
  }, []);

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.Mutatiesoort,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          const { data, onDataChange, toepassen } = weergaveProps;

          // const [typeID] = data;
          return (
            <div className="d-flex align-items-center">
              <span className="mr-2">Mutatiesoorten</span>

              <MultiSelect
                value={weergaveProps.data}
                onChange={(x) => {
                  weergaveProps.onDataChange(x);
                  weergaveProps.toepassen();
                  weergaveProps.setFilterIsActief(EFilter.Mutatiesoort, true);
                }}
                opties={props.mutatiesoorten.map((x) => ({
                  key: x.ID,
                  weergave: x.Naam,
                }))}
              />
            </div>
          );
        },
      },
      {
        naam: EFilter.Producttype,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          const { data, onDataChange, setIsActief, toepassen } = weergaveProps;
          const typeIDs = data as number[];
          const typeID = typeIDs.length === 1 ? typeIDs[0] : null;

          return (
            <div className="d-flex align-items-center">
              <span className="mr-2">Producttype</span>

              <ProducttypeFilterCombobox
                typeID={typeID}
                onTypeIDChange={(typeID) => {
                  onDataChange(typeID === null ? [] : [typeID]);
                  setIsActief(true);
                  toepassen();
                }}
              />
            </div>
          );
        },
      },
    ],
    [producttypenKolommen, props.mutatiesoorten],
  );

  return (
    <>
      <MenuLayout
        menu={
          <>
            <div className="mt-2 d-flex align-items-center">
              {/* <button
                className="btn btn-sm btn-light d-flex align-items-center"
                style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                disabled={urlState.selectie.length === 0}
                onClick={() => null}
              >
                <span>
                  <Icon style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                </span>
                <span className="ml-2">Versturen</span>
              </button> */}

              <FilterBalkV2
                filters={filters}
                filterData={urlState.filterdata}
                onFilterDataChange={(x) => setUrlStateSync('filterdata', x)}
                onFilterSchemaChange={setFilterSchema}
              />
            </div>
          </>
        }
        body={
          mutaties === null ? (
            <div className="d-flex flex-fill align-items-center justify-content-center">
              <LoadingSpinner />
            </div>
          ) : (
            <GridStyleWrapper height="calc(100vh - 150px)">
              <Grid getRowId={keyExtractor} rows={mutaties} columns={kolommen}>
                <DataTypeProvider
                  for={[nameof<IRow>('Mutatiedatum')]}
                  formatterComponent={(formatterProps) => {
                    return <span>{format(new Date(formatterProps.value), 'dd-MM-yyyy')}</span>;
                  }}
                />

                <DataTypeProvider
                  for={['Aantal']}
                  formatterComponent={(formatterProps) => {
                    const rij: IRow = formatterProps.row;
                    const aantal = rij.Aantal > 0 ? '+' + rij.Aantal : rij.Aantal;
                    if (
                      (Number(aantal) > 0 && rij.mutatiesoort.Teken === -1) ||
                      (Number(aantal) < 0 && rij.mutatiesoort.Teken === +1)
                    ) {
                      return <span style={{ color: Kleur.Rood }}>{aantal}</span>;
                    }

                    return <span>{aantal}</span>;
                  }}
                />

                <DataTypeProvider
                  for={[nameof<IRow>('RecordToegevoegd')]}
                  formatterComponent={(formatterProps) => {
                    if (formatterProps.value === null) {
                      return <span></span>;
                    }
                    return (
                      <span>{format(new Date(formatterProps.value), 'dd-MM-yyyy HH:mm')}</span>
                    );
                  }}
                />

                <DataTypeProvider
                  for={['__magazijn']}
                  formatterComponent={(formatterProps) => {
                    const rij: IRow = formatterProps.row;
                    return <span>{rij.magazijn.NaamKort}</span>;
                  }}
                />

                <DataTypeProvider
                  for={['__categorie']}
                  formatterComponent={(formatterProps) => {
                    const rij: IRow = formatterProps.row;
                    if (rij.product === null && rij.producttype === null) {
                      return <span></span>;
                    }

                    return (
                      <span>
                        {rij.producttype !== null
                          ? rij.producttype.ProductsoortnaamKort
                          : rij.product!.producttype.ProductsoortnaamKort}
                      </span>
                    );
                  }}
                />

                <DataTypeProvider
                  for={['MutatiesoortNaam']}
                  formatterComponent={(formatterProps) => {
                    const rij: IRow = formatterProps.row;

                    return <span>{rij.mutatiesoort.Naam}</span>;
                  }}
                />

                <DataTypeProvider
                  for={['__entiteit']}
                  formatterComponent={(formatterProps) => {
                    const rij: IRow = formatterProps.row;

                    // Relatie (levering of Retour)
                    const relID =
                      rij.mutatiesoort.NaamEnum === EMagazijnMutatiesoort.LeveringKlant ||
                      rij.mutatiesoort.NaamEnum === EMagazijnMutatiesoort.RetourKlant
                        ? rij.transportopdrachtregel !== null &&
                          rij.transportopdrachtregel.relatie !== null
                          ? rij.transportopdrachtregel.relatie.RelID
                          : null
                        : null;

                    // Leverancier
                    const relIDInkoop =
                      rij.mutatiesoort.NaamEnum === EMagazijnMutatiesoort.Inkoop
                        ? rij.inkoopopdracht !== null
                          ? rij.inkoopopdracht.dienst.RelID
                          : null
                        : null;

                    if (relID !== null) {
                      return <RelatieVisualisatie relID={relID} />;
                    }
                    if (relIDInkoop !== null) {
                      return <RelatieVisualisatie relID={relIDInkoop} />;
                    }

                    return <span></span>;
                  }}
                />

                {/* <DataTypeProvider
                  for={['__Product']}
                  formatterComponent={(formatterProps) => {
                    const rij: IRow = formatterProps.row;

                    const product = rij.product !== null ? rij.product.Typenaam : '';
                    return <span>{product}</span>;
                  }}
                /> */}

                <DataTypeProvider
                  for={['__merk']}
                  formatterComponent={(formatterProps) => {
                    const rij: IRow = formatterProps.row;

                    if (rij.product === null && rij.producttype === null) {
                      return <span></span>;
                    }

                    const merk =
                      rij.product !== null
                        ? rij.product.producttype.Merknaam
                        : rij.producttype!.Merknaam;
                    return <span>{merk}</span>;
                  }}
                />

                <DataTypeProvider
                  for={['__type']}
                  formatterComponent={(formatterProps) => {
                    const rij: IRow = formatterProps.row;

                    if (rij.product === null && rij.producttype === null) {
                      return <span></span>;
                    }

                    const merk =
                      rij.product !== null
                        ? rij.product.producttype.Typenaam
                        : rij.producttype!.Typenaam;
                    return <span>{merk}</span>;
                  }}
                />

                {/* <DataTypeProvider
                  for={['__zoekgemeld']}
                  formatterComponent={(formatterProps) => {
                    const rij: IRow = formatterProps.row;

                    if (rij.product === null) {
                      return <span></span>;
                    }

                    return <span>{rij.product.ZoekGemeld !== null ? 'Ja' : ''}</span>;
                  }}
                /> */}

                <DataTypeProvider
                  for={[nameOf<IRow>('Referentiecode')]}
                  formatterComponent={(formatterProps) => {
                    return <span>{formatterProps.value ?? ''}</span>;
                  }}
                />

                <DataTypeProvider
                  for={[nameOf<IRow>('Nieuw')]}
                  formatterComponent={(formatterProps) => {
                    const rij: IRow = formatterProps.row;

                    if (rij.Nieuw === null) {
                      return <span></span>;
                    }

                    return <span>{rij.Nieuw !== null ? (rij.Nieuw ? 'N' : 'G') : ''}</span>;
                  }}
                />

                <DataTypeProvider
                  for={['__productstatus']}
                  formatterComponent={(formatterProps) => {
                    const rij: IRow = formatterProps.row;
                    return <span>{rij.productstatus !== null ? rij.productstatus.Naam : ''}</span>;
                  }}
                />

                <DataTypeProvider
                  for={['__bron']}
                  formatterComponent={(formatterProps) => {
                    const rij: IRow = formatterProps.row;

                    switch (rij.mutatiesoort.NaamEnum) {
                      case EMagazijnMutatiesoort.Inkoop:
                        const referentie = rij.inkoopopdracht!.Referentie;
                        return <span>{referentie}</span>;
                        break;
                      case EMagazijnMutatiesoort.RetourKlant:
                        return <span>Transportopdracht</span>;
                        break;
                      case EMagazijnMutatiesoort.PendelLossen:
                        return <span>Pendelopdracht</span>;
                        break;
                      case EMagazijnMutatiesoort.RetourInkoop:
                        return <span>Retouropdracht inkoop</span>;
                        break;
                      case EMagazijnMutatiesoort.LeveringKlant:
                        const relatie =
                          rij.transportopdrachtregel!.relatie !== null
                            ? rij.transportopdrachtregel!.relatie.weergavenaam
                            : '';
                        return <span>{relatie}</span>;
                        break;
                      case EMagazijnMutatiesoort.PendelLaden:
                        return <span>Pendelopdracht</span>;
                        break;
                      case EMagazijnMutatiesoort.AfvoerIntern:
                        return <span>Afvoer intern</span>;
                        break;
                      case EMagazijnMutatiesoort.Surplus:
                        return <span>Inventarisatie</span>;
                        break;
                      case EMagazijnMutatiesoort.Manco:
                        return <span>Inventarisatie</span>;
                        break;
                      case EMagazijnMutatiesoort.StatusWijziging:
                        return <span>Statuswijziging</span>;
                        break;
                      default:
                        break;
                    }

                    return <span></span>;
                  }}
                />

                <EditingState
                  onAddedRowsChange={() => {}}
                  onEditingRowIdsChange={(x) => {
                    const id = x[x.length - 1] as number;
                  }}
                  onCommitChanges={() => null}
                />

                <RowDetailState defaultExpandedRowIds={[]} />
                {/* <VirtualTable estimatedRowHeight={43} /> */}
                <VirtualTable messages={geenData} />

                <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
                <TableHeaderRow />
                <TableEditColumn
                  width={35}
                  // showAddCommand={true}
                  // showEditCommand
                  // showDeleteCommand
                  commandComponent={DXCommandComponent}
                />
                {/* <TableRowDetail
                  contentComponent={DetailComp}
                  toggleCellComponent={DXTableToggleCellComponent}
                /> */}
                <SelectionState
                  selection={urlState.selectie}
                  onSelectionChange={(x) => setUrlStateSync('selectie', x as number[])}
                />
                <TableSelection cellComponent={DXTableCheckboxComponent} />
              </Grid>
            </GridStyleWrapper>
          )
        }
      />
    </>
  );
};

export default withRouter(Mutaties);
