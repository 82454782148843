import api from '../..';
import {
  IOphalenProductsoortenParams,
  IOphalenProductsoortenResult,
  IToevoegenProductsoortParams,
  IToevoegenProductsoortResult,
  ICheckVerwijderenProductsoortenParams,
  IVerwijderenProductsoortenParams,
  ICheckToevoegenProductsoortParams,
  ICheckWijzigenProductsoortParams,
  IWijzigenProductsoortParams,
  IHerordenenLijstResult,
  IToevoegenAfbeeldingParams,
  IToevoegenAfbeeldingResult,
  IVerwijderenAfbeeldingParams,
  IVerwijderenAfbeeldingResult,
} from '../../../../../shared/src/api/v2/product/soort';
import { IHerordenenLijstParams } from '../../../../../shared/src/api/v2/aanbod/Abonnement';
import landingspagina from './landingspagina';

const productsoort = {
  landingspagina,
  ophalenProductsoorten: async (params: IOphalenProductsoortenParams) => {
    return await api.post<IOphalenProductsoortenResult>(
      'v2/product/soort/ophalen-productsoorten',
      params,
    );
  },
  checkToevoegenProductsoort: async (params: ICheckToevoegenProductsoortParams) => {
    return await api.post<any>('v2/product/soort/check-toevoegen-productsoort', params);
  },
  toevoegenProductsoort: async (params: IToevoegenProductsoortParams) => {
    return await api.post<IToevoegenProductsoortResult>(
      'v2/product/soort/toevoegen-productsoort',
      params,
    );
  },
  checkWijzigenProductsoort: async (params: ICheckWijzigenProductsoortParams) => {
    return await api.post<any>('v2/product/soort/check-wijzigen-productsoort', params);
  },
  wijzigenProductsoort: async (params: IWijzigenProductsoortParams) => {
    return await api.post<any>('v2/product/soort/wijzigen-productsoort', params);
  },
  checkVerwijderenProductsoorten: async (params: IVerwijderenProductsoortenParams) => {
    return await api.post<any>('v2/product/soort/check-verwijderen-productsoorten', params);
  },
  verwijderenProductsoorten: async (params: IVerwijderenProductsoortenParams) => {
    return await api.post<any>('v2/product/soort/verwijderen-productsoorten', params);
  },
  herordenenLijst: async (params: IHerordenenLijstParams) => {
    return await api.post<IHerordenenLijstResult>('v2/product/soort/herordenen-lijst', params);
  },
  toevoegenAfbeelding: async (params: IToevoegenAfbeeldingParams) => {
    return await api.post<IToevoegenAfbeeldingResult>(
      'v2/product/soort/toevoegen-afbeelding',
      params,
    );
  },
  verwijderenAfbeelding: async (params: IVerwijderenAfbeeldingParams) => {
    return await api.post<IVerwijderenAfbeeldingResult>(
      'v2/product/soort/verwijderen-afbeelding',
      params,
    );
  },
};

export default productsoort;
