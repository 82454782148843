import React, { useCallback, useMemo } from 'react';
import { ISponsorCommissie } from '../../../../../shared/src/api/v2/sponsoringv2/event';
import api from '../../../api';
import { ASPKolom, EAspKolomBreedteType } from '../../tabel/ASPTabel/types';
import MultiComboboxV2, { EnkeleProvider, IRepresentatieProps, Provider } from '../MultiComboboxV2';

export enum ESelectieKolom {
  Naam,
}

export const enkeleProvider: EnkeleProvider<number, ISponsorCommissie> = async (id) => {
  const results = await api.v2.sponsoringv2.commissie.ophalenCommissies({
    filterSchema: {
      filters: [
        {
          naam: 'IDS',
          data: [id],
        },
      ],
    },
  });
  return results[0];
};

const StandaardRepresentatieComponent: React.ComponentType<IRepresentatieProps<
  ISponsorCommissie
>> = (props) => {
  return <span>{props.entiteit.Naam}</span>;
};

interface IProps {
  id: number | null;
  dienstID: number;
  onChange: (id: number | null) => void;
  representatieComponent?: React.ComponentType<IRepresentatieProps<ISponsorCommissie>>;
}

const SponsorCommissieSelectie = (props: IProps) => {
  const provider = async () => {
    const commissies = await api.v2.sponsoringv2.commissie.ophalenCommissies({
      filterSchema: {
        filters: [{ naam: 'DIENST_ID', data: props.dienstID }],
      },
    });

    return {
      items: commissies,
      totaalAantal: commissies.length,
    };
  };

  const keyExtractor = (item: ISponsorCommissie) => item?.ID ?? -1;
  const representatieComponent = useMemo(
    () => props.representatieComponent ?? StandaardRepresentatieComponent,
    [props.representatieComponent],
  );

  const kolommen = useMemo<ASPKolom<ESelectieKolom, ISponsorCommissie>[]>(
    () => [
      {
        key: ESelectieKolom.Naam,
        label: 'Naam',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 400,
        renderer: (x) => <span>{x.Naam}</span>,
      },
    ],
    [],
  );

  return (
    <MultiComboboxV2
      provider={provider}
      enkeleProvider={enkeleProvider}
      keyExtractor={keyExtractor}
      waarde={props.id}
      onChange={props.onChange}
      representatieComponent={representatieComponent}
      kolommen={kolommen}
    />
  );
};

export default SponsorCommissieSelectie;
