import React, { useContext, useEffect, useMemo, useState, useCallback } from 'react';
import useUrlState from '../../../core/useUrlState';
import { RouteComponentProps, withRouter } from 'react-router';
import { observer } from 'mobx-react-lite';
import api from '../../../api';
import {
  TypedColumn,
  TypedTableColumnWidthInfo,
  GridStyleWrapper,
  DXTableToggleCellComponent,
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
} from '../../../helpers/dxTableGrid';
import {
  Grid,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  TableSelection,
  VirtualTable,
  TableRowDetail,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  DataTypeProvider,
  EditingState,
  SelectionState,
  RowDetailState,
  SortingState,
  IntegratedSorting,
} from '@devexpress/dx-react-grid';
import { RootStoreContext } from '../../../stores/RootStore';
import { EResultType } from '../../../stores/CheckStore';
import LoadingSpinner from '../../../components/Gedeeld/LoadingSpinner';
import { IOphalenHerincassozakenResultElement } from '../../../../../shared/src/api/v2/debiteur/herincassozaak';
import { IconToevoegen, IconVerwijderen } from '../../../components/Icons';
import { Kleur } from '../../../bedrijfslogica/constanten';
import FormatteerBedrag from '../../../components/MutatieBedrag';
import { format } from 'date-fns';
import DetailComp from '../../Klant/Klantkaart/Entiteiten/entiteiten/Debiteur/Incassozaken/FacturenDetailComp';
import {
  DXTableCheckboxComponent,
  // GridStyleWrapper,
  // TypedColumn,
  // TypedTableColumnWidthInfo,
} from '../../../helpers/dxTableGrid';
// import RelatieVisualisatie from '../../personalia/RelatieVisualisatie';
import RelatieVisualisatie from '../../../components/personalia/RelatieVisualisatie';
import { Helmet } from 'react-helmet';
import WijzigenDialoog from './WijzigenDialoog';
import FilterBalkV2, {
  IFilter,
  IFilterData,
  maakFilterSchema,
} from '../../../components/FilterBalkV2';
import MenuLayout from '../../../components/MenuLayout';

interface IProps extends RouteComponentProps {}

enum EFilter {
  Actief = 'IS_AFGEHANDELD',
}

export interface IWijzigenDialoogState {
  id: number;
}

interface IUrlState {
  selectie: number[];
  wijzgenDialoogState: IWijzigenDialoogState | null;
  filterdata: IFilterData<EFilter>[];
}

const defaultUrlState: IUrlState = {
  selectie: [],
  wijzgenDialoogState: null,
  filterdata: [
    {
      naam: EFilter.Actief,
      data: false,
      isActief: true,
    },
  ],
};

const geenData = {
  noData: 'Geen dossiers gevonden',
};

const Herincassozaken: React.FC<IProps> = observer((props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);

  const [filterSchema, setFilterSchema] = useState(maakFilterSchema(urlState.filterdata));

  const { klantkaartStore, checkStore } = useContext(RootStoreContext);

  const [herincassozaken, setHerincassozaken] = useState<
    IOphalenHerincassozakenResultElement[] | null
  >(null);
  // const [wijzigenID, setWijzigenID] = useState<number | null>(null);

  const ophalenHerincassozaken = useCallback(async () => {
    const { herincassozaken } = await api.v2.debiteur.herincassozaak.ophalenHerincassozaken({
      filterSchema,
    });

    setHerincassozaken(herincassozaken);
  }, [filterSchema]);

  useEffect(() => {
    ophalenHerincassozaken();
  }, [ophalenHerincassozaken]);

  const handleVerwijderen = useCallback(async (id: number) => {
    const checkData = await api.v2.debiteur.herincassozaak.checkVerwijderenHerincassozaken({
      ids: [id],
    });
    if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
      return;
    }
    if (
      (
        await checkStore.bevestigen({
          titel: `Wil je de herincassozaak verwijderen?`,
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    await api.v2.debiteur.herincassozaak.verwijderenHerincassozaken({
      ids: [id],
    });

    // setUrlStateSync('selectie', []);

    ophalenHerincassozaken();
  }, []);

  const keyExtractor = useCallback((rij: IOphalenHerincassozakenResultElement) => rij.ID, []);

  const kolommen = useMemo<TypedColumn<IOphalenHerincassozakenResultElement>[]>(
    () => [
      {
        name: 'Zaaknummer',
        title: 'Zaaknr.',
      },
      {
        name: 'DossierDatum',
        title: 'Dossierdatum',
      },
      {
        name: '__relatie',
        title: 'Debiteur',
      },
      {
        name: 'Hoofdsom',
        title: 'Hoofdsom',
      },
      {
        name: '__incassodienst' as any,
        title: 'Inc.dienst',
      },
      {
        name: 'Afgehandeld',
        title: 'Afgehandeld',
      },
      {
        name: 'Notities',
        title: 'Notities',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IOphalenHerincassozakenResultElement>[]>(
    () => [
      {
        columnName: 'DossierDatum',
        width: 130,
      },
      {
        columnName: 'Zaaknummer',
        width: 90,
      },
      {
        columnName: '__relatie',
        width: 225,
      },
      {
        columnName: 'Hoofdsom',
        width: 150,
      },
      {
        columnName: '__incassodienst' as any,
        width: 125,
      },
      {
        columnName: 'Notities',
        width: 300,
      },
      {
        columnName: 'Afgehandeld',
        width: 120,
      },
    ],
    [],
  );

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.Actief,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return <span>Alleen niet-afgehandeld</span>;
        },
      },
    ],
    [],
  );

  return (
    <>
      <Helmet>
        <title>Herincassozaken</title>
      </Helmet>
      <MenuLayout
        menu={
          <>
            <div className="d-flex align-items-center">
              <button
                className="btn btn-sm btn-light d-flex align-items-center"
                disabled={urlState.selectie.length !== 1}
                style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                onClick={async () => {
                  const params = { herincZaakIDs: urlState.selectie };

                  const checkData = await api.v2.debiteur.herincassozaak.checkVersturenHerincassozaken(
                    params,
                  );
                  const checkResult = await checkStore.controleren({
                    checkData,
                  });
                  if (checkResult.type === EResultType.Annuleren) {
                    return;
                  }

                  if (
                    (
                      await checkStore.bevestigen({
                        inhoud: `Wil je de geselecteerde herincassozaken versturen?`,
                      })
                    ).type === EResultType.Annuleren
                  ) {
                    return;
                  }

                  await api.v2.debiteur.herincassozaak.versturenHerincassozaken(params);

                  ophalenHerincassozaken();
                }}
              >
                {/* <IconV style={{ width: 16, height: 16, fill: Kleur.Grijs }} /> */}
                <span className="ml-2">Versturen </span>
              </button>

              <div className="ml-2 flex-fill">
                <FilterBalkV2
                  filters={filters}
                  filterData={urlState.filterdata}
                  onFilterDataChange={(x) => setUrlStateSync('filterdata', x)}
                  onFilterSchemaChange={(x) => setFilterSchema(x)}
                />
              </div>
            </div>
          </>
        }
        body={
          <>
            {herincassozaken === null ? (
              <div className="flex-fill d-flex align-items-center justify-content-center">
                <LoadingSpinner />
              </div>
            ) : (
              <GridStyleWrapper height={'calc(100vh - 100px)'}>
                <Grid rows={herincassozaken} getRowId={keyExtractor} columns={kolommen}>
                  <DataTypeProvider
                    for={['__relatie']}
                    formatterComponent={(props) => <RelatieVisualisatie relID={props.row.RelID} />}
                  />

                  <DataTypeProvider
                    for={['__incassodienst']}
                    formatterComponent={(formatterProps) => {
                      const rij: IOphalenHerincassozakenResultElement = formatterProps.row;

                      return (
                        <RelatieVisualisatie
                          relID={rij.incassodienst.RelID}
                          options={{ geenLinkToepassen: false }}
                        />
                      );
                    }}
                  />

                  {/* <DataTypeProvider
              for={['__incassoDienst']}
              formatterComponent={(props) => {
                // const incassoDienst =
                //   props.row.StatusnaamEnum === 'INCASSO'
                //     ? props.row.incassoDienst !== null
                //       ? props.row.incassoDienst.Naam
                //       : ''
                //     : '';
                const incassoDienst =
                  props.row.incassoDienst !== null ? props.row.incassoDienst.Naam : '';

                return <span>{incassoDienst}</span>;
              }}
            /> */}

                  <DataTypeProvider
                    for={['DossierDatum']}
                    formatterComponent={(props) => (
                      <span>{format(new Date(props.value), 'dd-MM-yyyy')}</span>
                    )}
                  />

                  <DataTypeProvider
                    for={['Afgehandeld']}
                    formatterComponent={(props) => <span>{props.value ? 'Ja' : 'Nee'}</span>}
                  />

                  <DataTypeProvider
                    for={['Hoofdsom']}
                    formatterComponent={(props) => <FormatteerBedrag bedrag={props.value} />}
                  />

                  <EditingState
                    onCommitChanges={async (changes) => {
                      if (changes.deleted === undefined) {
                        return;
                      }
                      const deleted = changes.deleted;
                      const id = deleted[deleted.length - 1] as number;
                      // alert(id);
                      await handleVerwijderen(id);
                    }}
                    onEditingRowIdsChange={(rowIds) => {
                      const id = rowIds[rowIds.length - 1] as number;
                      setUrlStateSync('wijzgenDialoogState', { id });
                    }}
                  />

                  <SortingState defaultSorting={[]} />
                  <IntegratedSorting />
                  <RowDetailState defaultExpandedRowIds={[]} />

                  <VirtualTable />
                  <VirtualTable messages={geenData} />
                  <TableColumnResizing defaultColumnWidths={kolomBreedtes} />

                  <TableEditColumn
                    width={65}
                    showEditCommand
                    showDeleteCommand
                    cellComponent={DXTableEditColumnCellComponent}
                    commandComponent={DXTableEditColumnCommandComponent}
                  />
                  <TableHeaderRow showSortingControls />
                  <TableRowDetail
                    contentComponent={DetailComp}
                    toggleCellComponent={DXTableToggleCellComponent}
                  />
                  <SelectionState
                    selection={urlState.selectie}
                    onSelectionChange={(x) => setUrlStateSync('selectie', x as number[])}
                  />
                  <TableSelection cellComponent={DXTableCheckboxComponent} />
                </Grid>
              </GridStyleWrapper>
            )}
            {urlState.wijzgenDialoogState !== null && (
              <WijzigenDialoog
                ID={urlState.wijzgenDialoogState.id}
                open
                onAnnuleren={() => {
                  setUrlStateSync('wijzgenDialoogState', null);
                }}
                onSuccess={() => {
                  setUrlStateSync('wijzgenDialoogState', null);
                  ophalenHerincassozaken();
                }}
              />
            )}
          </>
        }
      />
    </>
  );
});

export default withRouter(Herincassozaken);
