import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import useUrlState from '../../../core/useUrlState';
import { RouteComponentProps, withRouter } from 'react-router';
import { observer } from 'mobx-react-lite';
import api from '../../../api';
import {
  DXTableCheckboxComponent,
  DXTableToggleCellComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../helpers/dxTableGrid';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  DataTypeProvider,
  EditingState,
  IntegratedSorting,
  RowDetailState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import { RootStoreContext } from '../../../stores/RootStore';
import LoadingSpinner from '../../../components/Gedeeld/LoadingSpinner';
import { Kleur } from '../../../bedrijfslogica/constanten';
import FormatteerBedrag from '../../../components/MutatieBedrag';
import { differenceInDays, format } from 'date-fns';
import * as _ from 'lodash';
import DetailComp from './FacturenDetailComp';
import RelatieVisualisatie from '../../../components/personalia/RelatieVisualisatie';
import ToevoegenIncassozaakDialoog from './ToevoegenIncassozaakDialoog';
import { IFilterSchemaFilter } from '../../../../../shared/src/models/filter';
import { IOphalenRelatiesResultElementV2 } from '../../../../../shared/src/api/v2/relatie';
import { IOphalenFacturenBasisResultElement } from '../../../../../shared/src/api/v2/factuur';
import { IOphalenAanmaningenResultElement } from '../../../../../shared/src/api/v2/aanmaning';
import SelectieVak from '../../../components/SelectieVak';
import { IOphalenIncassozakenResultElement } from '../../../../../shared/src/api/v2/debiteur/incassozaak';
import { IOphalenRechtzakenResultElement } from '../../../../../shared/src/api/v2/debiteur/rechtzaak';
import { IOphalenBetalingsregelingenResultElement } from '../../../../../shared/src/api/v2/debiteur/betalingsregeling';
import { IOphalenHerincassozakenResultElement } from '../../../../../shared/src/api/v2/debiteur/herincassozaak';
import { EHoedanigheid } from '../../../components/personalia/RelatieSelectieDialoog';
import { AutoSizer } from 'react-virtualized';
import { IOphalenOpdrachtenResultElement } from '../../../../../shared/src/api/v2/bank/opdracht/opdracht';
import FilterBalkV2, {
  IFilter,
  IFilterData,
  maakFilterSchema,
} from '../../../components/FilterBalkV2';
import { Helmet } from 'react-helmet';
import {
  IOphalenOpdrachtenResultElementV2,
  IOphalenOpdrachtregelsResultElementV2,
} from '../../../../../shared/src/api/v2/transport/opdracht';
import { ERegelstatusTransport, ETransportopdrachtRegelsoort } from '../../../bedrijfslogica/enums';
import { IOphalenDebiteurenBetaalgedragResultElement } from '../../../../../shared/src/api/v2/debiteur';
import UitlegTooltip from '../../../components/formulier/UitlegTooltip';
import { BetaalgedragIndicatie } from './BetaalgedragIndicatie';

interface IProps extends RouteComponentProps {}

enum EFilter {
  ActueleVervaldatumVervallen = 'ACTUELE_VERVALDATUM_TOT',
  ZonderFacturenMetIncassoopdracht = 'HEEFT_LOPENDE_BANKOPDRACHT',
  AantalAanmaningenGroterOfGelijkAan = 'AANTAL_AANMANINGEN_GROTER_OF_GELIJK_AAN_MAXIMUM',
  NietInBeltaakDebiteuren = 'IN_BELTAAK_DEBITEUREN',
}

interface IUrlState {
  selectie: number[];
  filterdata: IFilterData<EFilter>[];
}

const geenData = {
  noData: 'Geen debiteuren gevonden voor de ingestelde filters',
};
export interface IFactuur extends IOphalenFacturenBasisResultElement {
  aanmaningen: IOphalenAanmaningenResultElement[];
}
export interface IDebiteur extends IOphalenRelatiesResultElementV2 {
  facturen: IFactuur[];
  betaalgedrag: IOphalenDebiteurenBetaalgedragResultElement | null;
}

export interface ITransportopdrachtregel extends IOphalenOpdrachtregelsResultElementV2 {
  opdracht: IOphalenOpdrachtenResultElementV2;
}

const Overicht: React.FC<IProps> = observer((props) => {
  const { instellingStore } = useContext(RootStoreContext);

  const defaultUrlState = useMemo<IUrlState>(
    () => ({
      selectie: [],
      filterdata: [
        {
          naam: EFilter.ActueleVervaldatumVervallen,
          isActief: true,
          data: new Date(),
        },
        {
          naam: EFilter.ZonderFacturenMetIncassoopdracht,
          isActief: true,
          data: false,
        },
        {
          naam: EFilter.AantalAanmaningenGroterOfGelijkAan,
          isActief: true,
          data: undefined,
        },
        {
          naam: EFilter.NietInBeltaakDebiteuren,
          isActief: true,
          data: false,
        },
      ],
    }),
    [instellingStore],
  );

  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const { klantkaartStore, checkStore } = useContext(RootStoreContext);

  const [facturen, setFacturen] = useState<IFactuur[] | null>(null);
  const [debiteuren, setDebiteuren] = useState<IDebiteur[] | null>(null);
  const [incassoopdrachten, setIncassoopdrachten] = useState<
    IOphalenOpdrachtenResultElement[] | null
  >(null);
  const [transportopdrachtregels, setTransportopdrachtregels] = useState<
    ITransportopdrachtregel[] | null
  >(null);

  const [wijzigenID, setWijzigenID] = useState<number | null>(null);
  const [tonenToevoegenIncassozaakDialoog, setToevoegenIncassozaakDialoog] = useState<boolean>(
    false,
  );
  const [filterAlleenVervallen, setFilterAlleenVervallen] = useState<boolean>(true);
  const [filterAanmaanbaar, setFilterAanmaanbaar] = useState<boolean>(false);

  const vervaldatumGrens = useMemo<string>(() => {
    const vandaag = format(new Date(), 'yyyy-MM-dd');

    return vandaag;
  }, []);

  const [incassozaken, setIncassozaken] = useState<IOphalenIncassozakenResultElement[] | null>(
    null,
  );
  const [herincassozaken, setHerincassozaken] = useState<
    IOphalenHerincassozakenResultElement[] | null
  >(null);

  const [rechtzaken, setRechtzaken] = useState<IOphalenRechtzakenResultElement[] | null>(null);
  const [betalingsregelingen, setBetalingsregelingen] = useState<
    IOphalenBetalingsregelingenResultElement[] | null
  >(null);
  const [filterSchema, setFilterSchema] = useState(maakFilterSchema(urlState.filterdata));

  // Openstaande facturen ophalen die niet in een openstaand dossier zitten
  const ophalenFacturen = useCallback(async () => {
    if (
      incassozaken === null ||
      herincassozaken === null ||
      rechtzaken === null ||
      betalingsregelingen === null
    ) {
      return;
    }

    const alleenVervallen = filterAlleenVervallen;
    const aanmaanbaar = filterAanmaanbaar;

    const facturenBasisResult = (
      await api.v2.factuur.ophalenFacturenBasis({
        filterSchema: {
          filters: [
            ...filterSchema.filters!,
            {
              naam: 'OPENSTAAND',
              data: true,
            },
            aanmaanbaar
              ? {
                  naam: 'IS_AAN_TE_MANEN',
                  data: aanmaanbaar,
                }
              : null,
            // alleenVervallen
            //   ? {
            //       naam: 'VERVALDATUM_TOT',
            //       data: vervaldatumGrens,
            //     }
            //   : null,
            {
              naam: 'VERVALDATUM_TOT',
              data: vervaldatumGrens,
            },
            // filterAlleenVervallen ? { naam: 'ACTUELE_VERVALDATUM_TOT', data: new Date() } : null,
          ].filter((x) => x !== null) as IFilterSchemaFilter[],
        },
      })
    ).facturen;

    // Filter de facturen eruit die in een lopend of vrijgegeven dossier zitten
    const facturenInIncassozaken = _.flatten(incassozaken.map((x) => x.facturen));
    const facturenInHerincassozaken = _.flatten(herincassozaken.map((x) => x.facturen));
    const facturenInRechtzaken = _.flatten(rechtzaken.map((x) => x.facturen));
    const facturenInBetalingsregelingen = _.flatten(betalingsregelingen.map((x) => x.facturen));

    const facturenResult = facturenBasisResult
      .filter((x: any) => facturenInIncassozaken.map((x: any) => x.FactID).indexOf(x.FactID) === -1)
      .filter(
        (x: any) => facturenInHerincassozaken.map((x: any) => x.FactID).indexOf(x.FactID) === -1,
      )
      .filter((x: any) => facturenInRechtzaken.map((x: any) => x.FactID).indexOf(x.FactID) === -1)
      .filter(
        (x: any) =>
          facturenInBetalingsregelingen.map((x: any) => x.FactID).indexOf(x.FactID) === -1,
      );

    // Aanmaningen bij facturen halen
    const aanmaningenResult = (
      await api.v2.aanmaning.ophalenAanmaningen({
        filterSchema: {
          filters: [
            {
              naam: 'FACT_IDS',
              data: facturenResult.map((x) => x.FactID),
            },
          ].filter((x) => x !== null) as IFilterSchemaFilter[],
        },
      })
    ).aanmaningen;

    const facturen = facturenResult.map((x) => {
      const aanmaningenVoorFactuur = aanmaningenResult.filter(
        (a) => a.facturen.map((x) => x.FactID).indexOf(x.FactID) !== -1,
      );

      return {
        ...x,
        aanmaningen: aanmaningenVoorFactuur,
      };
    });

    setFacturen(facturen);
  }, [
    incassozaken,
    herincassozaken,
    rechtzaken,
    betalingsregelingen,
    filterAlleenVervallen,
    filterAanmaanbaar,
    filterSchema.filters!,
  ]);

  useEffect(() => {
    ophalenFacturen();
  }, [ophalenFacturen]);

  const ophalenRelaties = useCallback(async () => {
    if (facturen === null) {
      return;
    }

    const relIDs = _.uniq(facturen.map((x) => x.RelID));

    // Selecteer alle debiteuren met openstaande (optioneel alleen vervallen) facturen
    // const debiteurenResult = await api.v2.debiteur.overzicht.ophalenDebiteuren({
    //   filterSchema: {
    //     filters: [
    //       {
    //         naam: 'HEEFT_OPENSTAANDE_FACTUREN',
    //         data: true,
    //       },
    //       filterAlleenVervallen ? { naam: 'HEEFT_VERVALLEN_FACTUREN', data: true } : null,
    //     ].filter((x) => x !== null) as IFilterSchemaFilter[],
    //   },
    // });

    // if (debiteurenResult.debiteuren.length === 0) {
    //   return;
    // }

    const relatiesResult = (
      await api.v2.relatie.ophalenRelaties({
        filterSchema: {
          filters: [
            {
              naam: 'IDS',
              data: relIDs,
            },
          ].filter((x) => x !== null) as IFilterSchemaFilter[],
        },
      })
    ).relaties;

    const debiteurenBetaalgedrag = await api.v2.debiteur.ophalenDebiteurenBetaalgedrag({ relIDs });

    const debiteuren = relatiesResult.map((debiteur) => {
      const facturenVoorDebiteur = facturen.filter((x) => x.RelID === debiteur.RelID);
      const betaalgedrag =
        debiteurenBetaalgedrag.debiteuren.find((x) => x.relID === debiteur.RelID) ?? null;
      return {
        ...debiteur,
        facturen: facturenVoorDebiteur,
        betaalgedrag,
      };
    });

    // Filter de debiteuren eruit die een Credit-saldo hebben
    const debiteurenMetAlleenDebetsaldo = debiteuren.filter(
      (x) => _.sum(x.facturen.map((x) => x.Openstaand)) > 0,
    );

    setDebiteuren(debiteurenMetAlleenDebetsaldo);
  }, [facturen, filterAlleenVervallen]);

  useEffect(() => {
    ophalenRelaties();
  }, [ophalenRelaties]);

  // Alle niet-afgehandelde betalingsregelingen ophalen
  const ophalenTransportopdrachtregels = useCallback(async () => {
    if (debiteuren === null) {
      return;
    }

    const transportopdrachtregelsResult = await api.v2.transport.opdracht.ophalenOpdrachtregelsV2({
      filterSchema: {
        filters: [
          {
            naam: 'REL_IDS',
            data: debiteuren.map((x) => x.RelID),
          },
        ],
      },
    });

    const trsOpdIDs = transportopdrachtregelsResult.regels.map((x) => x.TrsOpdID);
    const transportopdrachtenResult = await api.v2.transport.opdracht.ophalenOpdrachtenV2({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: trsOpdIDs,
          },
        ],
      },
    });

    const regels = transportopdrachtregelsResult.regels.map((regel) => {
      const opdracht = transportopdrachtenResult.opdrachten.find(
        (x) => x.TrsOpdID === regel.TrsOpdID,
      )!;
      return { ...regel, opdracht };
    });

    setTransportopdrachtregels(regels);
  }, [debiteuren]);

  useEffect(() => {
    ophalenTransportopdrachtregels();
  }, [ophalenTransportopdrachtregels]);

  // Alle niet-afgehandelde betalingsregelingen ophalen
  const ophalenBetalingsregelingen = useCallback(async () => {
    const betalingsregelingen = (
      await api.v2.debiteur.betalingsregeling.ophalenBetalingsregelingen({
        filterSchema: {
          filters: [
            {
              naam: 'IS_AFGEHANDELD',
              data: false,
            },
          ],
        },
      })
    ).betalingsregelingen;

    setBetalingsregelingen(betalingsregelingen);
  }, []);

  useEffect(() => {
    ophalenBetalingsregelingen();
  }, [ophalenBetalingsregelingen]);

  // Alle niet-afgehandelde incassozaken ophalen
  const ophalenIncassozaken = useCallback(async () => {
    const incassozaken = (
      await api.v2.debiteur.incassozaak.ophalenIncassozaken({
        filterSchema: {
          filters: [
            {
              naam: 'IS_AFGEHANDELD',
              data: false,
            },
          ],
        },
      })
    ).incassozaken;

    setIncassozaken(incassozaken);
  }, []);

  useEffect(() => {
    ophalenIncassozaken();
  }, [ophalenIncassozaken]);

  // Alle niet-afgehandelde herincassozaken ophalen
  const ophalenHerincassozaken = useCallback(async () => {
    const herincassozaken = (
      await api.v2.debiteur.herincassozaak.ophalenHerincassozaken({
        filterSchema: {
          filters: [
            {
              naam: 'IS_AFGEHANDELD',
              data: false,
            },
          ],
        },
      })
    ).herincassozaken;

    setHerincassozaken(herincassozaken);
  }, []);

  useEffect(() => {
    ophalenHerincassozaken();
  }, [ophalenHerincassozaken]);

  // Alle niet-afgehandelde rechtzaken ophalen
  const ophalenRechtzaken = useCallback(async () => {
    const rechtzaken = (
      await api.v2.debiteur.rechtzaak.ophalenRechtzaken({
        filterSchema: {
          filters: [
            {
              naam: 'IS_AFGEHANDELD',
              data: false,
            },
          ],
        },
      })
    ).rechtzaken;

    setRechtzaken(rechtzaken);
  }, []);

  useEffect(() => {
    ophalenRechtzaken();
  }, [ophalenRechtzaken]);

  // Alle lopende incasso-opdrachten ophalen
  const ophalenIncassoopdrachten = useCallback(async () => {
    if (facturen === null) {
      return;
    }
    const incassoopdrachtenResult = (
      await api.v2.bank.opdracht.ophalenOpdrachten({
        filterSchema: {
          filters: [
            {
              naam: 'FACT_IDS',
              data: facturen.map((x) => x.FactID),
            },
            { naam: 'IS_UITGEVOERD', data: false },
          ].filter((x) => x !== null) as IFilterSchemaFilter[],
        },
      })
    ).opdrachten;

    setIncassoopdrachten(incassoopdrachtenResult);
  }, [facturen]);

  useEffect(() => {
    ophalenIncassoopdrachten();
  }, [ophalenIncassoopdrachten]);

  const handleVerwijderen = useCallback(async (ID: number) => {
    // const checkData = await api.v2.debiteur.incassozaak.checkVerwijderenDossier({
    //   ID,
    // });
    // if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
    //   return;
    // }
    // if (
    //   (
    //     await checkStore.bevestigen({
    //       titel: `Wil je het dossier verwijderen?`,
    //     })
    //   ).type === EResultType.Annuleren
    // ) {
    //   return;
    // }

    // await api.v2.debiteur.incassozaak.verwijderenDossier({
    //   ID,
    // });

    // setUrlStateSync('selectie', []);

    ophalenRelaties();
  }, []);

  const keyExtractor = useCallback((rij: IDebiteur) => rij.RelID, []);

  const kolommen = useMemo<TypedColumn<IDebiteur>[]>(
    () => [
      {
        name: '__relatie' as any,
        title: 'Debiteur',
        getCellValue: (x) => {
          const relatie = debiteuren!.find((r) => r.RelID === x.RelID);
          return relatie!.weergavenaam;
        },
      },
      {
        name: 'Relatienummer',
        title: 'Rel.nr',
      },
      {
        name: '__totaalOpenstaand' as any,
        title: 'Openstaand',
        getCellValue: (x) => {
          return _.sum(x.facturen.map((x) => x.Openstaand));
        },
      },
      {
        name: '__totaalVervallen' as any,
        title: 'Vervallen',
        getCellValue: (x) => {
          return _.sum(
            x.facturen.filter((x) => x.Vervaldatum < vervaldatumGrens).map((x) => x.Openstaand),
          );
        },
      },
      {
        name: '__laatsteVervaldatum' as any,
        title: (
          <span title={'De meest recente vervaldatum van een factuur of aanmaning'}>
            Vervaldatum
          </span>
        ) as any,
        getCellValue: (x) => {
          const laatsteVervaldatumFacturen = _.max(x.facturen.map((x) => x.Vervaldatum));
          const laatsteVervaldatum = laatsteVervaldatumFacturen;

          const aanmaningen: IOphalenAanmaningenResultElement[] = _.flatten(
            x.facturen.map((x) => {
              return x.aanmaningen;
            }),
          );

          const laatsteVervaldatumAanmaningen = _.max(aanmaningen.map((x) => x.Vervaldatum));

          const actueleVervaldatum =
            laatsteVervaldatumAanmaningen > laatsteVervaldatumFacturen
              ? laatsteVervaldatumAanmaningen
              : laatsteVervaldatumFacturen;

          return actueleVervaldatum;
        },
      },
      {
        name: '__aantalFacturen' as any,
        title: '# Fact.',
        getCellValue: (x) => x.facturen.length,
      },
      {
        name: '__hoogstAantalAanmaningen' as any,
        title: (
          <span title={'Hoogst aantal definitieve aanmaningen voor de facturen van de relatie'}>
            # Amn.
          </span>
        ) as any,
        getCellValue: (x) => {
          if (x.facturen.length === 0) {
            return <span></span>;
          }
          const maximaalAantalAanmaningen = _.max(x.facturen.map((f) => f.aanmaningen.length));
          return maximaalAantalAanmaningen;
        },
      },
      {
        name: '__oudsteOpenstaandeFactuurDagen' as any,
        title: (
          <span
            title={
              'Dit is het aantal dagen wat verlopen is na de vervaldatum van de oudste factuur'
            }
          >
            Dgn.
          </span>
        ) as any,
        getCellValue: (x) => {
          if (x.facturen.length === 0) {
            return <span></span>;
          }
          const datum = _.min(x.facturen.map((x) => x.Vervaldatum));
          const dagen = differenceInDays(new Date(), new Date(datum));
          return dagen;
        },
      },
      // {
      //   name: '__nietAanmanenTot' as any,
      //   title: 'Niet aanm.',
      // },
      {
        name: '__indicatie' as any,
        title: 'Ind.',
        getCellValue: (x: IDebiteur) => (x.betaalgedrag !== null ? x.betaalgedrag.indicatie : -1),
      },
      {
        name: '__heeftRetouropdracht' as any,
        title: 'Ret.opd.',
      },
      {
        name: '__incassoopdrachten' as any,
        title: 'Inc.opd.',
        getCellValue: (x) => {
          if (incassoopdrachten === null) {
            return null;
          }

          const factIDsVanRelatie = x.facturen.map((x) => x.FactID);
          const opdrachten = incassoopdrachten.filter((x) => {
            const factIDsInOpdrachten = x.debiteurFacturen.map((x) => x.FactID);
            return factIDsVanRelatie.some((r) => factIDsInOpdrachten.indexOf(r) >= 0);
          });

          return opdrachten.length !== 0;
        },
      },
      {
        name: '__dossiers' as any,
        title: 'Dossiers',
      },
      {
        name: 'Notities',
        title: 'Notities',
      },
    ],
    [debiteuren, incassoopdrachten],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IDebiteur>[]>(
    () => [
      {
        columnName: '__relatie' as any,
        width: 250,
      },
      {
        columnName: 'Relatienummer',
        width: 90,
      },
      {
        columnName: '__totaalOpenstaand' as any,
        width: 115,
      },
      {
        columnName: '__totaalVervallen' as any,
        width: 115,
      },
      // {
      //   columnName: 'hoogstAantalAanmaningen',
      //   width: 100,
      // },
      {
        columnName: '__oudsteOpenstaandeFactuurDagen' as any,
        width: 75,
      },
      {
        columnName: '__aantalFacturen' as any,
        width: 85,
      },
      {
        columnName: '__hoogstAantalAanmaningen' as any,
        width: 85,
      },
      {
        columnName: '__laatsteVervaldatum' as any,
        width: 125,
      },
      {
        columnName: '__nietAanmanenTot' as any,
        width: 140,
      },
      {
        columnName: '__dossiers' as any,
        width: 110,
      },
      {
        columnName: '__indicatie' as any,
        width: 80,
      },
      {
        columnName: '__heeftRetouropdracht' as any,
        width: 100,
      },
      {
        columnName: 'Notities',
        width: 400,
      },
      {
        columnName: '__incassoopdrachten' as any,
        width: 90,
      },
    ],
    [debiteuren],
  );

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.ActueleVervaldatumVervallen,
        altijdWeergevenInBalk: true,
        actiefMuteerbaar: true,
        weergave: (weergaveProps) => {
          return <span>Actuele vervaldatum vervallen</span>;
        },
      },
      {
        naam: EFilter.ZonderFacturenMetIncassoopdracht,
        altijdWeergevenInBalk: true,
        actiefMuteerbaar: true,
        weergave: (weergaveProps) => {
          return <span>Zonder incassoopdrachten</span>;
        },
      },
      {
        naam: EFilter.AantalAanmaningenGroterOfGelijkAan,
        altijdWeergevenInBalk: true,
        actiefMuteerbaar: true,
        weergave: (weergaveProps) => {
          return (
            // <span>Facturen met minimaal {instellingStore.MaximaalAantalAanmaningen} aanmaningen</span>
            <span>Facturen met max. aantal aanmaningen</span>
          );
        },
      },
      {
        naam: EFilter.NietInBeltaakDebiteuren,
        altijdWeergevenInBalk: true,
        actiefMuteerbaar: true,
        weergave: (weergaveProps) => {
          return <span>Niet in beltaak</span>;
        },
      },
    ],
    [instellingStore],
  );

  return (
    <>
      <Helmet>
        <title>Beheer Debiteuren</title>
      </Helmet>
      <div
        className="d-flex flex-column p-3"
        style={{
          backgroundColor: Kleur.HeelLichtGrijs,
          borderBottom: `1px solid ${Kleur.LichtGrijs}`,
        }}
      >
        <div className="d-flex align-items-center">
          <div className="flex-fill">
            <FilterBalkV2
              filters={filters}
              filterData={urlState.filterdata}
              onFilterDataChange={(x) => setUrlStateSync('filterdata', x)}
              onFilterSchemaChange={setFilterSchema}
            />
          </div>
        </div>
        {debiteuren !== null && (
          <div className="mt-2 d-flex align-items-center">
            <SelectieVak
              totaalAantal={debiteuren.length}
              aantal={urlState.selectie.length}
              onChange={(allesGeselecteerd) => {
                if (allesGeselecteerd) {
                  setUrlStateSync('selectie', debiteuren!.map(keyExtractor));
                } else {
                  setUrlStateSync('selectie', []);
                }
              }}
            />
          </div>
        )}
      </div>

      {debiteuren === null ||
      incassozaken === null ||
      betalingsregelingen === null ||
      incassoopdrachten === null ||
      transportopdrachtregels === null ? (
        <div className="flex-fill d-flex align-items-center justify-content-center">
          <LoadingSpinner />
        </div>
      ) : (
        <div className="d-flex flex-column flex-fill">
          <AutoSizer className="flex-fill" style={{ width: '100%', height: '100%' }}>
            {(size) => {
              return (
                <GridStyleWrapper height={size.height}>
                  <Grid rows={debiteuren} getRowId={keyExtractor} columns={kolommen}>
                    <DataTypeProvider
                      for={['__relatie']}
                      formatterComponent={(props) => (
                        <RelatieVisualisatie
                          relID={props.row.RelID}
                          relatieLinkBuilder={(hoedanigheid, relID) =>
                            `/${
                              hoedanigheid === EHoedanigheid.Klant ? 'klant' : 'leverancier'
                            }/${relID}/facturen/overzicht`
                          }
                        />
                      )}
                    />

                    <DataTypeProvider
                      for={['__totaalOpenstaand']}
                      formatterComponent={(formatterProps) => {
                        const rij: IDebiteur = formatterProps.row;
                        const openstaand = _.sum(rij.facturen.map((x) => x.Openstaand));
                        return <FormatteerBedrag bedrag={openstaand} />;
                      }}
                    />

                    <DataTypeProvider
                      for={['__aantalFacturen']}
                      formatterComponent={(formatterProps) => {
                        const rij: IDebiteur = formatterProps.row;
                        return <span>{rij.facturen.length}</span>;
                      }}
                    />

                    <DataTypeProvider
                      for={['__totaalVervallen']}
                      formatterComponent={(formatterProps) => {
                        const rij: IDebiteur = formatterProps.row;
                        const openstaand = _.sum(
                          rij.facturen
                            .filter((x) => x.Vervaldatum < vervaldatumGrens)
                            .map((x) => x.Openstaand),
                        );
                        return <FormatteerBedrag bedrag={openstaand} />;
                      }}
                    />

                    <DataTypeProvider
                      for={['__oudsteOpenstaandeFactuurDagen']}
                      formatterComponent={(formatterProps) => {
                        const rij: IDebiteur = formatterProps.row;
                        if (rij.facturen.length === 0) {
                          return <span></span>;
                        }

                        const datum = _.min(rij.facturen.map((x) => x.Vervaldatum));
                        const dagen = differenceInDays(new Date(), new Date(datum));
                        return <span>{dagen}</span>;
                      }}
                    />

                    <DataTypeProvider
                      for={['__hoogstAantalAanmaningen']}
                      formatterComponent={(formatterProps) => {
                        const rij: IDebiteur = formatterProps.row;
                        if (rij.facturen.length === 0) {
                          return <span></span>;
                        }
                        const maximaalAantalAanmaningen = _.max(
                          rij.facturen.map((x) => x.aanmaningen.length),
                        );

                        return <span>{maximaalAantalAanmaningen}</span>;
                      }}
                    />

                    <DataTypeProvider
                      for={['__laatsteVervaldatum']}
                      formatterComponent={(formatterProps) => {
                        const rij: IDebiteur = formatterProps.row;
                        if (rij.facturen.length === 0) {
                          return <span></span>;
                        }
                        const laatsteVervaldatumFacturen = _.max(
                          rij.facturen.map((x) => x.Vervaldatum),
                        );
                        const laatsteVervaldatum = laatsteVervaldatumFacturen;
                        const aanmaningen: IOphalenAanmaningenResultElement[] = _.flatten(
                          rij.facturen.map((x) => {
                            return x.aanmaningen;
                          }),
                        );
                        const laatsteVervaldatumAanmaningen = _.max(
                          aanmaningen.map((x) => x.Vervaldatum),
                        );

                        const actueleVervaldatum =
                          laatsteVervaldatumAanmaningen > laatsteVervaldatumFacturen
                            ? laatsteVervaldatumAanmaningen
                            : laatsteVervaldatumFacturen;
                        const peildatum = format(new Date(), 'yyyy-MM-dd');

                        return (
                          <span
                            style={{
                              color: actueleVervaldatum < peildatum ? Kleur.Rood : undefined,
                            }}
                          >
                            {format(new Date(actueleVervaldatum), 'dd-MM-yyyy')}
                          </span>
                        );
                      }}
                    />

                    <DataTypeProvider
                      for={['__nietAanmanenTot']}
                      formatterComponent={(formatterProps) => {
                        const rij: IDebiteur = formatterProps.row;

                        const debiteur = rij.debiteur!;
                        if (debiteur.NietAanmanen) {
                          if (debiteur.DatumNietAanmanen === null) {
                            return <span>Tot nader bericht</span>;
                          }
                          if (debiteur.DatumNietAanmanen > format(new Date(), 'yyyy-MM-dd')) {
                            return (
                              <span>
                                Tot {format(new Date(debiteur.DatumNietAanmanen), 'dd-MM-yyyy')}
                              </span>
                            );
                          }
                          return <span></span>;
                        }
                        return <span></span>;
                      }}
                    />

                    <DataTypeProvider
                      for={['__dossiers']}
                      formatterComponent={(formatterProps) => {
                        const rij: IDebiteur = formatterProps.row;

                        let dossiers = '';

                        const heeftIncassozaak =
                          incassozaken !== null
                            ? incassozaken.some((x) => x.RelID === rij.RelID)
                            : false;
                        const heeftRechtzaak =
                          rechtzaken !== null
                            ? rechtzaken.some((x) => x.RelID === rij.RelID)
                            : false;
                        const heeftBetalingsregeling =
                          betalingsregelingen !== null
                            ? betalingsregelingen.some((x) => x.RelID === rij.RelID)
                            : false;

                        dossiers += heeftIncassozaak ? 'Inc.zaak,' : '';
                        dossiers += heeftRechtzaak ? 'Rechtzaak,' : '';
                        dossiers += heeftBetalingsregeling ? 'Bet.rgl,' : '';

                        return <span>{dossiers.slice(0, -1)}</span>;
                      }}
                    />

                    <DataTypeProvider
                      for={['__incassoopdrachten']}
                      formatterComponent={(formatterProps) => {
                        const rij: IDebiteur = formatterProps.row;

                        const factIDsVanRelatie = rij.facturen.map((x) => x.FactID);
                        const opdrachten = incassoopdrachten.filter((x) => {
                          const factIDsInOpdrachten = x.debiteurFacturen.map((x) => x.FactID);
                          return factIDsVanRelatie.some((r) => factIDsInOpdrachten.indexOf(r) >= 0);
                        });

                        return <span>{opdrachten.length !== 0 ? 'Ja' : ''}</span>;
                      }}
                    />

                    <DataTypeProvider
                      for={['__heeftRetouropdracht']}
                      formatterComponent={(formatterProps) => {
                        const rij: IDebiteur = formatterProps.row;

                        const regels = transportopdrachtregels.filter(
                          (x) =>
                            x.RelID === rij.RelID &&
                            x.regelsoort.NaamEnum === ETransportopdrachtRegelsoort.Retour &&
                            x.status.Status === ERegelstatusTransport.NIET_AFGEMELD &&
                            x.OmruilID === null,
                        );
                        const gedwongen = regels.some((x) => x.opdracht.GedwongenRetour);

                        if (regels.length > 0) {
                          return <span>Ja {gedwongen ? '(gedw.)' : ''}</span>;
                        }
                        return <span></span>;
                      }}
                    />

                    <DataTypeProvider
                      for={['__indicatie']}
                      formatterComponent={(formatterProps) => {
                        const rij: IDebiteur = formatterProps.row;

                        if (rij.betaalgedrag === null) {
                          return <span></span>;
                        }

                        return <BetaalgedragIndicatie status={rij.betaalgedrag.indicatie} />;

                        // return <span>{rij.betaalgedrag.indicatie}</span>;
                      }}
                    />

                    {/* <DataTypeProvider
              for={['__heeftZaken']}
              formatterComponent={(props) => {
                const rij: IOphalenRelatiesResultElementV2 = props.row;
                let zaken = '';
                zaken += rij.heeftBetalingsregeling ? 'Betalingsregeling,' : '';
                zaken += rij.heeftIncassozaken ? 'Incassozaak,' : '';
                zaken += rij.heeftHerincassozaken ? 'Herincasso,' : '';
                zaken += rij.heeftRechtzaken ? 'Rechtzaak,' : '';
                if (zaken.length !== 0) {
                  zaken = zaken.slice(0, -1);
                }
                return <span>{zaken}</span>;
              }}
            /> */}

                    <SortingState defaultSorting={[]} />
                    <IntegratedSorting />

                    <EditingState
                      onCommitChanges={async (changes) => {
                        if (changes.deleted === undefined) {
                          return;
                        }
                        const deleted = changes.deleted;
                        const id = deleted[deleted.length - 1] as number;
                        // alert(id);
                        await handleVerwijderen(id);
                      }}
                      onEditingRowIdsChange={(rowIds) => {
                        const id = rowIds[rowIds.length - 1] as number;
                        // alert(id);
                        setWijzigenID(id);
                      }}
                    />

                    <RowDetailState defaultExpandedRowIds={[]} />

                    <VirtualTable />
                    <VirtualTable messages={geenData} />
                    <TableColumnResizing defaultColumnWidths={kolomBreedtes} />

                    {/* <TableEditColumn
              width={35}
              showEditCommand
              cellComponent={DXTableEditColumnCellComponent}
              commandComponent={DXTableEditColumnCommandComponent}
            /> */}
                    <TableHeaderRow showSortingControls />
                    <TableRowDetail
                      contentComponent={DetailComp}
                      toggleCellComponent={DXTableToggleCellComponent}
                    />
                    <SelectionState
                      selection={urlState.selectie}
                      onSelectionChange={(x) => setUrlStateSync('selectie', x as number[])}
                    />
                    <TableSelection cellComponent={DXTableCheckboxComponent} />
                  </Grid>
                </GridStyleWrapper>
              );
            }}
          </AutoSizer>
        </div>
      )}
      {tonenToevoegenIncassozaakDialoog && (
        <ToevoegenIncassozaakDialoog
          factIDs={[]}
          open
          onSuccess={() => {
            setToevoegenIncassozaakDialoog(false);
          }}
          onAnnuleren={() => {
            setToevoegenIncassozaakDialoog(false);
          }}
        />
      )}
    </>
  );
});

export default withRouter(Overicht);
