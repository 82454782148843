import React, { useContext, useMemo } from 'react';
import { DashboardContext } from '../index';
import TegelBasis from '../../../components/tegel/TegelBasis';
import { ERemoteDataState } from '../../../models/IRemoteData';
import LoadingSpinner from '../../../components/Gedeeld/LoadingSpinner';
import * as _ from 'lodash';
import { format } from 'date-fns';
import GegevensLayout from '../../../components/layout/GegevensLayout';
import { IOphalenBatchesResultElement } from '../../../../../shared/src/api/v2/bank/opdracht/opdracht';
import {
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../helpers/dxTableGrid';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import nameOf from '../../../core/nameOf';
import FormatteerBedrag from '../../../components/MutatieBedrag';

interface IProps {}

export interface IRegel extends IOphalenBatchesResultElement {}

const BankopdrachtenInformatie: React.FC<IProps> = (props) => {
  const context = useContext(DashboardContext);

  const batches: IRegel[] = _.flatten(
    context.lopendeBankbatches.data!.filter((x) => x.Soort === 1 && x.DatumVerstuurd !== null),
  );

  const kolommen = useMemo<TypedColumn<IRegel>[]>(
    () => [
      {
        name: 'DatumVerstuurd',
        title: 'Verstuurd',
      },
      {
        name: 'Uitvoerdatum',
        title: 'Uitvoerdatum',
      },
      {
        name: '__soort' as any,
        title: 'Inc/Bet',
      },
      {
        name: 'Bedrag',
        title: 'Bedrag',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRegel>[]>(
    () => [
      {
        columnName: 'Uitvoerdatum',
        width: 110,
      },
      {
        columnName: 'DatumVerstuurd',
        width: 110,
      },
      {
        columnName: '__soort' as any,
        width: 75,
      },
      {
        columnName: 'Bedrag',
        width: 110,
      },
    ],
    [],
  );

  return (
    true && (
      <TegelBasis titel="Lopende incassobatches">
        {context.lopendeBankbatches.state === ERemoteDataState.Pending ? (
          <div className="d-flex flex-fill justify-content-center align-items-center">
            <LoadingSpinner />
          </div>
        ) : (
          <div>
            <GridStyleWrapper rowAmount={batches.length} maxHeight={150}>
              <Grid rows={batches} columns={kolommen}>
                <DataTypeProvider
                  for={[nameOf<IRegel>('Uitvoerdatum')]}
                  formatterComponent={(formatterProps) => {
                    const rij: IRegel = formatterProps.row;
                    return <span>{format(new Date(formatterProps.value), 'dd-MM-yyyy')}</span>;
                  }}
                />

                <DataTypeProvider
                  for={[nameOf<IRegel>('DatumVerstuurd')]}
                  formatterComponent={(formatterProps) => {
                    const rij: IRegel = formatterProps.row;
                    if (formatterProps.value === null) {
                      return <span>Nog versturen</span>;
                    }
                    return <span>{format(new Date(formatterProps.value), 'dd-MM-yyyy')}</span>;
                  }}
                />

                <DataTypeProvider
                  for={['__soort']}
                  formatterComponent={(formatterProps) => {
                    const rij: IRegel = formatterProps.row;
                    return <span>{rij.Soort === 1 ? 'Inc' : 'Bet'}</span>;
                  }}
                />

                <DataTypeProvider
                  for={[nameOf<IRegel>('Bedrag')]}
                  formatterComponent={(formatterProps) => {
                    return <FormatteerBedrag bedrag={formatterProps.value} />;
                  }}
                />

                <VirtualTable messages={{ noData: 'Er zijn geen lopende batches' }} />
                <TableColumnResizing defaultColumnWidths={kolomBreedtes} />

                <TableHeaderRow />
              </Grid>
            </GridStyleWrapper>
          </div>
        )}
      </TegelBasis>
    )
  );
};

export default BankopdrachtenInformatie;
