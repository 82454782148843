import React, { useCallback, useContext, useMemo } from 'react';
import ASPTabel from '../../../../../../../../components/tabel/ASPTabel';
import { ILocatie } from '../../../../../../../../../../shared/src/api/v2/relatie/contract';
import {
  ASPKolom,
  EAspKolomBreedteType,
} from '../../../../../../../../components/tabel/ASPTabel/types';
import { IDataBijID } from '../index';
import { IOverzichtV2Data, useOverzichtV2Store } from '../store';
import UitgeklapteRij from './UitgeklapteRij';
import { formatteerAdresV2 } from '../../../../../../../../helpers';
import AdresVisualisatie from '../../../../../../../../components/locatie/AdresVisualisatie';
import { IconDatumreeks, IconPersoon } from '../../../../../../../../components/Icons';
import BeheerPersonenVanLocatieDialoog, {
  IBeheerPersonenVanLocatieDialoogOutput,
} from '../../../../../../../../components/dialogen/BeheerPersonenVanLocatieDialoog';
import { GlobaleRendererContext } from '../../../../../../../../one-off-components/GlobaleRenderer';
import BezoekdagenInfoDialoog from '../../../../../../../../components/transport/BezoekdagenInfoDialoog';
import { Kleur, Kleur as EKleur } from '../../../../../../../../bedrijfslogica/constanten';
import UitlegTooltip from '../../../../../../../../components/formulier/UitlegTooltip';
import { difference } from 'lodash';
import { differenceInDays, parse } from 'date-fns';

export interface ILocatieTabelContext {
  data: IOverzichtV2Data;
  dataBijID: IDataBijID;
  relID: number;
}

export const LocatieTabelContext = React.createContext<ILocatieTabelContext | null>(null);

export type LocatieTabelKolom = 'locatie' | 'interne_locaties' | 'aantal_contracten' | 'notities';

interface IProps {
  relID: number;
  data: IOverzichtV2Data;
  dataBijID: IDataBijID;
  uitgeklapt: number[];
  onUitgeklaptChange: (dispatch: (uitgeklapt: number[]) => number[]) => void;
  isBezig: boolean;
}

const LocatieTabel = (props: IProps): JSX.Element => {
  const store = useOverzichtV2Store();
  const globaleRenderer = useContext(GlobaleRendererContext);
  const keyExtractor = useCallback((locatie: ILocatie) => locatie.LocID, []);

  const kolommen = useMemo<ASPKolom<LocatieTabelKolom, ILocatie>[]>(() => {
    const interneLocatieKolomWeergeven = props.data.result.locatiesIntern.length > 0;
    const complexKolomWeergeven = props.data.result.relatieComplexen.length > 0;

    return [
      {
        key: 'locatie',
        label: 'Locatie',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 350,
        renderer: (locatie: ILocatie) => {
          const tekst = formatteerAdresV2({
            bisnummer: locatie.Bisnummer,
            huisnummer: locatie.Huisnummer,
            landnaamEnum: locatie.LandnaamEnum,
            landnaamKort: locatie.LandnaamKort,
            plaatsnaam: locatie.Plaatsnaam,
            postcode: locatie.Postcode,
            straatnaam: locatie.Straatnaam,
          });

          const beheerPersonenActieClick = async () => {
            const output = await globaleRenderer.render<IBeheerPersonenVanLocatieDialoogOutput>(
              ({ destroy }) => (
                <BeheerPersonenVanLocatieDialoog
                  relID={props.relID}
                  locID={locatie.LocID}
                  open
                  onSuccess={(output) => destroy(output)}
                  onAnnuleren={() => destroy()}
                />
              ),
            );
            if (output === undefined) {
              return;
            }

            await store.verversenContractWeergave();
          };

          return (
            <AdresVisualisatie
              weergaveTekst={tekst}
              locID={locatie.LocID}
              tekstStyle={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                color:
                  locatie.Bezoekinstructies !== null ||
                  locatie.BereikbaarVan !== null ||
                  locatie.BereikbaarTot !== null
                    ? EKleur.DonkerBlauw
                    : undefined,
              }}
              onCorrectieUitgevoerd={async () => await store.verversenContractWeergave()}
              extraHoverActies={[
                {
                  icon: (
                    <IconPersoon
                      style={{
                        width: 17,
                        height: 17,
                        position: 'relative',
                        bottom: 1,
                        fill: Kleur.Grijs,
                      }}
                    />
                  ),
                  title: 'Beheer personen',
                  onClick: beheerPersonenActieClick,
                },
              ]}
              extraActies={[
                {
                  text: 'Bezoekdagen',
                  icon: (
                    <IconDatumreeks
                      style={{
                        width: 17,
                        height: 17,
                        position: 'relative',
                        bottom: 1,
                      }}
                    />
                  ),
                  onClick: async () => {
                    await globaleRenderer.render((renderProps) => (
                      <BezoekdagenInfoDialoog
                        open
                        onSuccess={() => renderProps.destroy()}
                        onAnnuleren={() => renderProps.destroy()}
                        postcode={locatie.Postcode}
                        landID={locatie.LandID}
                      />
                    ));
                  },
                },
                {
                  text: 'Beheer personen',
                  icon: (
                    <IconPersoon
                      style={{
                        width: 17,
                        height: 17,
                        position: 'relative',
                        bottom: 1,
                      }}
                    />
                  ),
                  onClick: beheerPersonenActieClick,
                },
              ]}
            />
          );
        },
      },
      interneLocatieKolomWeergeven
        ? {
            key: 'interne_locaties',
            label: 'Intern',
            breedteType: EAspKolomBreedteType.Vast,
            vasteBreedte: 100,
            renderer: (locatie: ILocatie) => {
              const locatiesIntern = props.dataBijID.locatiesInternBijLocID[locatie.LocID] ?? [];
              const tekst = locatiesIntern.map((locatieIntern) => locatieIntern.Naam).join(', ');

              return (
                <div
                  style={{
                    display: 'inline-block',
                    fontSize: 12,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    color: EKleur.BeetjeLichtGrijs,
                  }}
                  title={tekst}
                >
                  {tekst}
                </div>
              );
            },
          }
        : null,
      complexKolomWeergeven
        ? {
            key: 'relatie_complexen',
            label: 'Complex',
            breedteType: EAspKolomBreedteType.Vast,
            vasteBreedte: 125,
            renderer: (locatie: ILocatie) => {
              const relatieLocatie = props.dataBijID.relatieLocatieBijLocID[locatie.LocID] ?? null;
              const complex =
                relatieLocatie === null || relatieLocatie.RelatieComplexID === null
                  ? null
                  : props.dataBijID.relatieComplexBijID[relatieLocatie.RelatieComplexID] ?? null;

              if (complex === null) {
                return null;
              }

              return (
                <div
                  style={{
                    display: 'inline-block',
                    fontSize: 12,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    color: EKleur.BeetjeLichtGrijs,
                  }}
                  title={complex.Naam}
                >
                  {complex.Naam}
                </div>
              );
            },
          }
        : null,
      {
        key: 'aantal_contracten',
        label: 'Cnt',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 80,
        renderer: (locatie: ILocatie) => {
          let aantalContracten = 0;

          const contractenVanLocatie = props.dataBijID.contractenBijLocID[locatie.LocID];
          if (contractenVanLocatie !== undefined) {
            const voorWeergaveContracten = contractenVanLocatie.filter((cnt) =>
              props.data.result.cntIDsVoorWeergave.includes(cnt.CntID),
            );
            aantalContracten = voorWeergaveContracten.length;
          }
          return aantalContracten;
        },
      },
      {
        key: 'notities',
        label: 'Notities',
        breedteType: EAspKolomBreedteType.Flex,
        flex: 1,
        renderer: (locatie: ILocatie) => {
          if (locatie.Notities === null) {
            return null;
          }
          // Regex om te kijken of de notitie een datum bevat
          // in formaat "DD-MM-YYYY"
          const datumRegex = /\b\d{2}-\d{2}-\d{4}\b/;
          const notitiesStart = locatie.Notities.slice(0, 10);
          const datumMatch = datumRegex.exec(notitiesStart);

          const comp = (style?: React.CSSProperties) => {
            return (
              <span
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  ...style,
                }}
                title={locatie.Notities ?? undefined}
              >
                {locatie.Notities}
              </span>
            );
          };

          if (datumMatch === null) {
            return comp();
          }
          const datumStr = datumMatch[0];
          let datum: Date;
          try {
            datum = parse(datumStr, 'dd-MM-yyyy', new Date());
          } catch (err) {
            console.error(err);
            return comp();
          }
          // Bij een recente datum de Notities blauw kleuren
          const datumIsRecent = datum !== null ? differenceInDays(new Date(), datum) <= 5 : false;

          return comp({
            color: datumIsRecent ? EKleur.Blauw : undefined,
          });
        },
      },
    ].filter((x) => x !== null) as ASPKolom<LocatieTabelKolom, ILocatie>[];
  }, [props.dataBijID.contractenBijLocID, props.relID]);

  const berekenUitgeklapteRijHoogte = useCallback(
    (rij: ILocatie) => {
      const contractenVanLocatie = props.dataBijID.contractenBijLocID[rij.LocID];
      const contracten = contractenVanLocatie.filter(
        (cnt) => store.data.data!.result.cntIDsVoorWeergave.indexOf(cnt.CntID) !== -1,
      );
      const contractenMetOpenSectie = contracten.filter((cnt) => {
        const state = store.contractTegelState[cnt.CntID];
        return state !== undefined && state.uitgeklapteSectieId !== null;
      });

      const locXRelPers = props.dataBijID.locXRelPersBijLocID[rij.LocID];
      const aantalContactpersonen = locXRelPers !== undefined ? locXRelPers.length : 0;

      const CONTACTPERSONEN_TABEL_BASE_HEIGHT = 41 + 5;
      const CONTACTPERSOON_REGEL_HEIGHT = 40;
      const contactpersonenHeight =
        aantalContactpersonen === 0
          ? 0
          : CONTACTPERSONEN_TABEL_BASE_HEIGHT + aantalContactpersonen * CONTACTPERSOON_REGEL_HEIGHT;

      return (
        16 +
        contracten.length * 153 +
        (contracten.length - 1) * 5 +
        contractenMetOpenSectie.length * 270 +
        contactpersonenHeight
      );
    },
    [props.dataBijID.contractenBijLocID, store.contractTegelState, store.data],
  );

  const contextValue = useMemo<ILocatieTabelContext>(
    () => ({
      data: props.data,
      dataBijID: props.dataBijID,
      relID: props.relID,
    }),
    [props.data, props.dataBijID, props.relID],
  );

  const locaties = useMemo(() => {
    const contractenVoorWeergave = props.data.result.cntIDsVoorWeergave.map(
      (cntID) => props.dataBijID.contractenBijID[cntID],
    );
    const contractenVoorWeergaveLocIDs = contractenVoorWeergave
      .filter((x) => x.LocID !== null)
      .map((x) => x.LocID!);
    return props.data.result.locaties.filter((loc) =>
      contractenVoorWeergaveLocIDs.includes(loc.LocID),
    );
  }, [
    props.data.result.locaties,
    props.data.result.cntIDsVoorWeergave,
    props.dataBijID.contractenBijID,
  ]);

  return (
    <LocatieTabelContext.Provider value={contextValue}>
      <ASPTabel
        rijen={locaties}
        kolommen={kolommen}
        keyExtractor={keyExtractor}
        uitgeklapt={props.uitgeklapt}
        onUitgeklaptChange={(uitgeklapt) => props.onUitgeklaptChange((curr) => uitgeklapt)}
        uitgeklapteRijComponent={UitgeklapteRij}
        headerHoogte={0}
        uitgeklapteRijHoogte={berekenUitgeklapteRijHoogte}
        isBezig={props.isBezig}
      />
    </LocatieTabelContext.Provider>
  );
};

export default LocatieTabel;
