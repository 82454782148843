import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import IDialoogProps from '../../../../../../../../core/IDialoogProps';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import ModalTitle from 'react-bootstrap/ModalTitle';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import api from '../../../../../../../../api';
import LoadingSpinner from '../../../../../../../../components/Gedeeld/LoadingSpinner';
import { observer } from 'mobx-react-lite';
import Dialoog from '../../../../../../../../components/dialogen/Dialoog';
import * as Yup from 'yup';
import FormikVeldFout from '../../../../../../../../components/formulier/FormikVeldFout';
import { IOphalenProductmodellenResultElement } from '../../../../../../../../../../shared/src/api/v2/aanbod/productmodel';
import _ from 'lodash';
import MultiCombobox, { IKolom } from '../../../../../../../../components/formulier/MultiCombobox';
import {
  IOphalenAanbodResultElement,
  IOphalenAanbodResultElementTariefElement,
} from '../../../../../../../../../../shared/src/api/v2/aanbod/index';
import FormatteerBedrag from '../../../../../../../../components/MutatieBedrag';
import { formatteerBedrag } from '../../../../../../../../helpers';
import { IOphalenRelatiesResultElementV2 } from '../../../../../../../../../../shared/src/api/v2/relatie';
import LocatieSelectie from '../../../../../../../../components/formulier/LocatieSelectie';
import { IOphalenContractenResultElementV2 } from '../../../../../../../../../../shared/src/api/v2/contract';
import { addMonths, format } from 'date-fns';
import ContactSelectie, {
  EType,
} from '../../../../../../../../components/formulier/ContactSelectie';
import { RootStoreContext } from '../../../../../../../../stores/RootStore';
import { EResultType } from '../../../../../../../../stores/CheckStore';
import { IToevoegenContractParams } from '../../../../../../../../../../shared/src/api/v2/contract/nieuw/nieuw';
import teksten from '../../../../../../../../bedrijfslogica/teksten';
import { IOphalenLocatieNiveausResultElement } from '../../../../../../../../../../shared/src/api/v2/locatie/locatie';
import Combobox, { IOptie } from '../../../../../../../../components/formulier/Combobox';
import VinkVeld from '../../../../../../../../components/formulier/VinkVeld';
import { EFunctioneleIcon, functioneleIconMap } from '../../../../../../../../components/Icons';
import { IOphalenOpdrachtenResultElementV2 } from '../../../../../../../../../../shared/src/api/v2/transport/opdracht';
import {
  EMutatiebron,
  EOpdrachtstatusTransport,
} from '../../../../../../../../bedrijfslogica/enums';
import { IFilterSchemaFilter } from '../../../../../../../../../../shared/src/models/filter';
import nameOf from '../../../../../../../../core/nameOf';
import ProductmodelSelectieCombobox from '../../../../../../../../components/formulier/ProductmodelSelectieCombobox';

export interface IDialoogResult {
  cntID: number;
  leveropdrachtMaken: boolean;
  wisselcontract_CntID: number | null;
  trsOpdID: number | null;
}

interface IProps extends IDialoogProps<IDialoogResult> {
  relID: number;
  prodModID?: number;
  wisselcontract_CntID?: number;
}

interface IFormikValues {
  prodModID: number | null;
  abonID: number | null;
  locID: number | null;
  contractwisselCntID: number | null;
  persID: number | null;
  verdieping: number | null;
  aantalGebruikers: number;
  aansluitenTrekschakelaar: boolean;
  transportopdrachtenMaken: boolean;
  trsOpdID: number | null;
  meerContractenWeergeven: boolean;
  filterOpSoort: boolean;
  kostenVtbNietToepassen: boolean;
}

const veldnamen = {
  prodModID: 'Kies een productmodel',
  abonID: 'Abonnement',
  locID: 'Locatie',
  contractwisselCntID: 'Contractwissel voor',
  persID: 'Aanvrager',
  verdieping: 'Verdieping',
  aantalGebruikers: 'Aantal gebruikers',
  aansluitenTrekschakelaar: 'Aansluiten op trekschakelaar',
  transportopdrachtenMaken: 'Transportopdracht(en) maken',
  trsOpdID: 'Toevoegen aan opdracht',
  meerContractenWeergeven: 'Meer contracten',
  filterOpSoort: 'Filter productmodellen op soort',
  kostenVtbNietToepassen:
    'Geen kosten VTB (indien van toepassing) in rekening brengen bij te wisselen contract',
};

interface IWisselContract extends IOphalenContractenResultElementV2 {
  //   relatie: IOphalenRelatiesResultElementV2;
}

const NieuwContractDialoogV2: React.FC<IProps> = observer((props) => {
  const { dialoogIndex, onAnnuleren, onSuccess } = props;
  const { checkStore, instellingStore } = useContext(RootStoreContext);

  const [relatie, setRelatie] = useState<IOphalenRelatiesResultElementV2 | null>(null);
  const [contractenVanRelatie, setContractenVanRelatie] = useState<
    IOphalenContractenResultElementV2[] | null
  >(null);
  const [productmodellen, setProductmodellen] = useState<
    IOphalenProductmodellenResultElement[] | null
  >(null);
  const [productmodellenTarieven, setProductmodellenTarieven] = useState<
    IOphalenAanbodResultElement[] | null
  >(null);
  const [verdiepingen, setVerdiepingen] = useState<IOphalenLocatieNiveausResultElement[] | null>(
    null,
  );
  const [filterOpProductsoort, setFilterOpProductsoort] = useState<boolean>(false);

  const initieleWaarden = useMemo<IFormikValues | null>(() => {
    if (productmodellen === null || relatie === null || contractenVanRelatie === null) {
      return null;
    }

    const persID = relatie.persoon!.PersID;

    // Bepaal initiele waarde van ProdModID
    // 1 - ProdModID meegegeven als prop?
    // 2 - Als er een wisselcontract is meegegeven als prop dan hiervan de ProdModID

    const wisselcontract =
      props.wisselcontract_CntID !== undefined
        ? contractenVanRelatie.find((x) => x.CntID === props.wisselcontract_CntID)!
        : null;

    const productmodel =
      props.prodModID !== undefined
        ? productmodellen.find((x) => x.ProdModID === props.prodModID) ?? null
        : wisselcontract !== null
        ? wisselcontract.basis.productmodel
        : null;

    return {
      prodModID: null, //productmodel !== null ? productmodel.ProdModID : null,  // Men wilde geen voorvullen ...
      abonID: null,
      locID: null,
      contractwisselCntID: props.wisselcontract_CntID ?? null,
      persID,
      verdieping: 0,
      aantalGebruikers: 0,
      aansluitenTrekschakelaar: false,
      transportopdrachtenMaken: true,
      trsOpdID: null,
      meerContractenWeergeven: false,
      filterOpSoort: filterOpProductsoort,
      kostenVtbNietToepassen: false,
    };
  }, [
    productmodellen,
    relatie,
    contractenVanRelatie,
    props.prodModID,
    props.wisselcontract_CntID,
    filterOpProductsoort,
  ]);

  const validationSchema = useMemo(() => {
    const fields: Partial<Record<keyof IFormikValues, any>> = {};

    return Yup.object().shape({
      prodModID: Yup.number().required(
        teksten.formulier.E_VERPLICHT_VELD({
          veldnaam: veldnamen.prodModID,
        }),
      ),
      abonID: Yup.number().required(
        teksten.formulier.E_VERPLICHT_VELD({
          veldnaam: veldnamen.abonID,
        }),
      ),
      locID: Yup.number().required(
        teksten.formulier.E_VERPLICHT_VELD({
          veldnaam: veldnamen.locID,
        }),
      ),
      persID: Yup.number().required(
        teksten.formulier.E_VERPLICHT_VELD({
          veldnaam: veldnamen.persID,
        }),
      ),
    });
  }, []);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      // Controleer of de persoon gekoppeld is en tekenbevoegd
      const contactpersoon = (
        await api.v2.relatie.ophalenContactpersonen({
          filterSchema: {
            filters: [
              { naam: 'REL_IDS', data: [relatie!.RelID] },
              { naam: 'PERS_IDS', data: [values.persID!] },
            ],
          },
        })
      ).contactpersonen[0];

      if (contactpersoon === undefined) {
        await api.v2.relatie.koppelenContactpersoon({
          persID: values.persID!,
          relID: relatie!.RelID,
        });
      }

      const contractData: IToevoegenContractParams = {
        accID: null,
        relID: relatie!.RelID,
        prodModID: values.prodModID!,
        abonID: values.abonID!,
        contractwissel_CntID: values.contractwisselCntID,
        locID: values.locID!,
        aantalGebruikers: values.aantalGebruikers,
        aanvrager_PersID: values.persID!,
        verdieping: values.verdieping,
        aansluitenTrekschakelaar: values.aansluitenTrekschakelaar,
        mutatiebron: EMutatiebron.Intern,
        actiecode: null,
        toepassenEenmaligBedrag:
          values.contractwisselCntID !== null ? instellingStore.EenmaligeKostenBijWissel : true,
        kostenVtbNietToepassen:
          values.contractwisselCntID !== null ? values.kostenVtbNietToepassen : false,
      };

      const checkData = await api.v2.contract.checkToevoegenContract(contractData);
      if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
        actions.setSubmitting(false);
        return;
      }

      if (
        (
          await checkStore.bevestigen({
            inhoud: 'Contract vastleggen?',
          })
        ).type === EResultType.Annuleren
      ) {
        actions.setSubmitting(false);
        return;
      }

      // Nieuw contract vastleggen
      const toevoegenResult = await api.v2.contract.toevoegenContract(contractData);

      // Koppelen persoon aan relatie (indien nog niet gekoppeld)
      await api.v2.relatie.koppelenContactpersoon({
        relID: relatie!.RelID,
        persID: values.persID!,
      });

      // Lijst recent bijwerken voor de RelID
      await api.v2.persoon.bijwerkenLijstRecent({
        persID: values.persID!,
      });

      await onSuccess({
        cntID: toevoegenResult.CntID,
        wisselcontract_CntID: values.contractwisselCntID,
        leveropdrachtMaken: values.transportopdrachtenMaken,
        trsOpdID: values.transportopdrachtenMaken ? values.trsOpdID : null,
      });

      actions.setSubmitting(false);
    },
    [relatie, instellingStore],
  );

  useEffect(() => {
    (async () => {
      const relatie = (
        await api.v2.relatie.ophalenRelaties({
          filterSchema: {
            filters: [
              {
                naam: 'IDS',
                data: [props.relID],
              },
            ],
          },
        })
      ).relaties[0];

      setRelatie(relatie);
    })();
  }, [props.relID]);

  // Contracten van de relatie
  useEffect(() => {
    (async () => {
      const contractenVanRelatie = (
        await api.v2.contract.ophalenContractenV2({
          filterSchema: {
            filters: [
              {
                naam: 'REL_IDS',
                data: [props.relID],
              },
            ],
          },
        })
      ).contracten;

      setContractenVanRelatie(contractenVanRelatie);
    })();
  }, [props.relID]);

  useEffect(() => {
    (async () => {
      if (contractenVanRelatie === null) {
        return;
      }
      let prodSrtID: number | null = null;
      // if (props.wisselcontract_CntID !== undefined) {
      //   prodSrtID = contractenVanRelatie.find((x) => x.CntID === props.wisselcontract_CntID)!.basis
      //     .productmodel.ProdSrtID;
      // }

      const results = (
        await api.v2.product.model.ophalenProductmodellen({
          filterSchema: {
            filters: [
              {
                naam: 'ACTIEF',
                data: true,
              },
              prodSrtID !== null
                ? {
                    naam: 'PRODSRT_IDS',
                    data: [prodSrtID],
                  }
                : null,
            ].filter((x) => x !== null) as IFilterSchemaFilter[],
          },
        })
      ).modellen;

      const productmodellen = _.orderBy(results, ['Merknaam', 'Typenaam'], ['asc', 'asc']);
      setProductmodellen(productmodellen);
    })();
  }, [contractenVanRelatie, props.wisselcontract_CntID]);

  useEffect(() => {
    (async () => {
      const aanbodResult = await api.v2.aanbod.ophalenAanbod({
        filterSchema: {
          filters: [
            {
              naam: 'REL_ID',
              data: [props.relID],
            },
          ],
        },
      });

      setProductmodellenTarieven(aanbodResult);
    })();
  }, [props.relID]);

  // Rijbron met verdiepingen
  useEffect(() => {
    (async () => {
      const resultaat = await api.v2.locatie.ophalenLocatieNiveaus({
        filterSchema: {},
      });
      setVerdiepingen(resultaat.locatieniveaus);
    })();
  }, []);

  // Rijbron met aantallen (gebruikers)
  const aantalGebruikersOpties = useMemo<IOptie<number>[]>(
    () =>
      new Array(21).fill(null).map((_, i) => ({
        id: i,
        label: i === 0 ? 'Onbekend' : String(i),
      })),
    [],
  );

  return (
    <Dialoog
      index={dialoogIndex || 0}
      modalProps={{
        size: `lg`,
      }}
    >
      <ModalHeader>
        <ModalTitle>Nieuw contract</ModalTitle>
        {/* <span>{JSON.stringify(dienstID)}</span> */}
      </ModalHeader>
      {initieleWaarden === null ? (
        <div className="d-flex flex-column flex-fill align-items-center justify-content-center">
          <LoadingSpinner />
        </div>
      ) : (
        <Formik<IFormikValues>
          onSubmit={handleSubmit}
          initialValues={initieleWaarden}
          validationSchema={validationSchema}
          render={(formikProps: FormikProps<IFormikValues>) => {
            if (
              productmodellen === null ||
              productmodellenTarieven === null ||
              relatie === null ||
              contractenVanRelatie === null ||
              verdiepingen === null ||
              aantalGebruikersOpties === null
            ) {
              return <LoadingSpinner />;
            }

            return (
              <Formulier
                formikProps={formikProps}
                onAnnuleren={props.onAnnuleren}
                productmodellen={productmodellen}
                productmodellenTarieven={productmodellenTarieven}
                relatie={relatie}
                contractenVanRelatie={contractenVanRelatie}
                wisselcontract_CntID={props.wisselcontract_CntID ?? null}
                verdiepingen={verdiepingen}
                aantalGebruikersOpties={aantalGebruikersOpties}
              />
            );
          }}
        />
      )}
    </Dialoog>
  );
});

interface IFormulierProps {
  formikProps: FormikProps<IFormikValues>;
  onAnnuleren: () => void;
  productmodellen: IOphalenProductmodellenResultElement[];
  productmodellenTarieven: IOphalenAanbodResultElement[];
  relatie: IOphalenRelatiesResultElementV2;
  contractenVanRelatie: IOphalenContractenResultElementV2[];
  wisselcontract_CntID: number | null;
  verdiepingen: IOphalenLocatieNiveausResultElement[] | null;
  aantalGebruikersOpties: IOptie<number>[];
}

const Formulier = (props: IFormulierProps) => {
  const {
    formikProps,
    productmodellen,
    productmodellenTarieven,
    relatie,
    contractenVanRelatie,
    wisselcontract_CntID,
    verdiepingen,
    aantalGebruikersOpties,
  } = props;
  const { submitForm, isSubmitting, isValid, values, setFieldValue } = formikProps;

  const [abonnementOpties, setAbonnementOpties] = useState<
    IOphalenAanbodResultElementTariefElement[] | null
  >(null);
  const [wisselContracten, setWisselContracten] = useState<IWisselContract[]>([]);
  //   const [verdieping, setVerdieping] = useState<number | null>(null);

  const [transportopdrachten, setTransportopdrachten] = useState<
    IOphalenOpdrachtenResultElementV2[] | null
  >(null);

  useEffect(() => {
    (async () => {
      const tarievenResult =
        values.prodModID !== null
          ? productmodellenTarieven.find((model) => model.ProdModID === values.prodModID)
          : null;

      if (tarievenResult === undefined || tarievenResult === null) {
        setAbonnementOpties([]);
        setFieldValue('abonID', null);
        return;
      }

      const abonnementOpties = tarievenResult.tarieven;
      const abonID = abonnementOpties.length !== 0 ? abonnementOpties[0].AbonID : null;

      setAbonnementOpties(abonnementOpties);
      setFieldValue('abonID', abonID);
    })();
  }, [values.prodModID]);

  // Contracten die voor een wissel in aanmerking komen
  useEffect(() => {
    (async () => {
      if (values.prodModID === null) {
        setWisselContracten([]);
        return;
      }

      // Het gekozen model voor het nieuwe contract
      const productmodel = productmodellen.find((x) => x.ProdModID === values.prodModID);
      if (productmodel === undefined) {
        setWisselContracten([]);
        return;
      }

      const wisselContracten = (
        await api.v2.contract.ophalenContractenV2({
          filterSchema: {
            filters: [
              { naam: 'LOC_IDS', data: [values.locID] },
              !values.meerContractenWeergeven
                ? { naam: 'PRODSRT_IDS', data: [productmodel.ProdSrtID] }
                : null,
              { naam: 'STATUS_NAAM_ENUMS', data: ['LOPEND'] },
            ].filter((x) => x !== null) as IFilterSchemaFilter[],
          },
        })
      ).contracten;

      setWisselContracten(wisselContracten);
    })();
  }, [
    contractenVanRelatie,
    productmodellen,
    values.prodModID,
    values.locID,
    values.meerContractenWeergeven,
  ]);

  useEffect(() => {
    (async () => {
      setFieldValue('contractwisselCntID', wisselcontract_CntID);
    })();
  }, [wisselContracten]);

  useEffect(() => {
    (async () => {
      if (values.contractwisselCntID === null) {
        setFieldValue('verdieping', null);
        return;
      }
      const contract = contractenVanRelatie.find((x) => x.CntID === values.contractwisselCntID);

      if (contract === undefined) {
        // Contract behoort niet tot die van de vigerende relatie
        return;
      }

      setFieldValue(
        'verdieping',
        contract.basis.locatieniveau !== null ? contract.basis.locatieniveau.Verdieping : null,
      );
    })();
  }, [values.contractwisselCntID, contractenVanRelatie]);

  useEffect(() => {
    (async () => {
      if (values.contractwisselCntID === null) {
        setFieldValue('aantalGebruikers', 0);
        return;
      }

      const contract = contractenVanRelatie.find((x) => x.CntID === values.contractwisselCntID);

      if (contract === undefined) {
        // Contract behoort niet tot die van de vigerende relatie
        return;
      }

      setFieldValue('aantalGebruikers', contract.basis.AantalGebruikers!);
    })();
  }, [values.contractwisselCntID, contractenVanRelatie]);

  useEffect(() => {
    (async () => {
      if (values.contractwisselCntID === null) {
        setFieldValue('aansluitenTrekschakelaar', false);
        return;
      }
      const contract = contractenVanRelatie.find((x) => x.CntID === values.contractwisselCntID);

      if (contract === undefined) {
        // Contract behoort niet tot die van de vigerende relatie
        return;
      }

      setFieldValue('aansluitenTrekschakelaar', contract.basis.AansluitenTrekschakelaar);
    })();
  }, [values.contractwisselCntID, contractenVanRelatie]);

  useEffect(() => {
    (async () => {
      const transportopdrachten = (
        await api.v2.transport.opdracht.ophalenOpdrachtenV2({
          filterSchema: {
            filters: [
              { naam: 'LOC_IDS', data: [values.locID] },
              { naam: 'STATUSSEN', data: [EOpdrachtstatusTransport.Planning] },
            ],
          },
        })
      ).opdrachten;

      setTransportopdrachten(transportopdrachten);
    })();
  }, [values.locID]);

  useEffect(() => {
    (async () => {
      if (transportopdrachten === null) {
        setFieldValue('trsOpdID', null);
        return;
      }

      setFieldValue(
        'trsOpdID',
        transportopdrachten.length !== 0 ? transportopdrachten[0].TrsOpdID : null,
      );
    })();
  }, [transportopdrachten]);

  // TODO Dennis
  // Ala het veld Wisselcontract wijzig moet een product opgehaald worden
  useEffect(() => {
    (async () => {
      if (values.contractwisselCntID === null) {
        return;
      }

      const contract = (
        await api.v2.contract.ophalenContractenV2({
          filterSchema: {
            filters: [
              {
                naam: 'IDS',
                data: [values.contractwisselCntID],
              },
            ],
          },
        })
      ).contracten[0];

      // setRelatie(relatie);
    })();
  }, [values.contractwisselCntID]);

  const productmodelKolommen = useMemo<IKolom<IOphalenProductmodellenResultElement>[]>(() => {
    return [
      {
        key: 'Modelnaam',
        label: 'Modelnaam',
        breedte: 200,
      },
      {
        key: 'Modelcode',
        label: 'Code',
        breedte: 125,
      },
      {
        key: 'Kenmerk',
        label: 'Kenmerk',
        breedte: 250,
      },
      {
        key: 'Categorie' as any,
        label: 'Categorie',
        breedte: 145,
      },
    ];
  }, []);

  const abonnementKolommen = useMemo<IKolom<IOphalenAanbodResultElementTariefElement>[]>(() => {
    return [
      {
        key: 'Naam',
        label: 'Naam',
        breedte: 80,
      },
      {
        key: 'MinimaleTermijn',
        label: 'Min. termijn',
        breedte: 100,
      },
      {
        key: 'Maandhuur',
        label: 'Maandhuur',
        breedte: 100,
        formatFabriek: (entiteit) => <FormatteerBedrag bedrag={entiteit.Maandhuur} />,
      },
      {
        key: 'MaandhuurActie',
        label: 'Actie',
        breedte: 100,
        formatFabriek: (entiteit) => {
          const bedrag =
            entiteit.MaandhuurActie !== null ? (
              <FormatteerBedrag bedrag={entiteit.MaandhuurActie} />
            ) : (
              ''
            );
          return bedrag;
        },
      },
      {
        key: 'Actieduur',
        label: 'Actieduur',
        breedte: 100,
        formatFabriek: (entiteit) => {
          if (entiteit.Actieduur === null) {
            return <span></span>;
          }
          return entiteit.Actieduur === 0 ? 'Looptijd' : entiteit.Actieduur;
        },
      },
    ];
  }, []);

  const contractwisselKolommen = useMemo<IKolom<IWisselContract>[]>(
    () => [
      {
        key: '__Contractnummer' as any,
        label: 'Cnt.nr',
        breedte: 75,
        formatFabriek: (rij) => rij.basis.Basisnummer + '.' + rij.Volgnummer,
      },
      {
        key: '__Ingangsdatum' as any,
        label: 'Ing.datum',
        breedte: 85,
        formatFabriek: (rij) => {
          const datum = format(new Date(rij.Ingangsdatum), 'dd-MM-yyyy');
          return datum;
        },
      },
      {
        key: '__status' as any,
        label: 'Status',
        breedte: 90,
        formatFabriek: (rij) => {
          return rij.status.Naam;
        },
      },
      {
        key: '__opzegbaar' as any,
        label: 'Opzegbaar',
        breedte: 100,
        formatFabriek: (rij) => {
          const minimaleDatum = addMonths(
            new Date(rij.IngangsdatumBasis),
            rij.basis.MinimaleTermijn,
          );
          const opzegbaar =
            minimaleDatum > new Date() ? format(minimaleDatum, 'dd-MM-yyyy') : 'Opzegbaar';
          return opzegbaar;
        },
      },
      {
        key: '__modelcode' as any,
        label: 'Model',
        breedte: 100,
        formatFabriek: (rij) => {
          return rij.basis.productmodel.Modelcode;
        },
      },
      //   {
      //     key: 'RelID',
      //     label: 'Relatie',
      //     breedte: 125,
      //     formatFabriek: (rij) => rij.relatie.weergavenaam,
      //   },
      {
        key: '__Locatie' as any,
        label: 'Locatie',
        breedte: 250,
        formatFabriek: (rij) => {
          return (
            rij.basis.locatie.Straatnaam +
            ' ' +
            rij.basis.locatie.Huisnummer +
            (rij.basis.locatie.Bisnummer !== null ? ` ${rij.basis.locatie.Bisnummer}` : '')
          );
        },
      },
    ],
    [],
  );

  const transportopdrachtKolommen = useMemo<IKolom<IOphalenOpdrachtenResultElementV2>[]>(
    () => [
      {
        key: 'Opdrachtnummer',
        label: 'Opdr.nr.',
        breedte: 75,
        formatFabriek: (rij) => rij.Opdrachtnummer,
      },
      {
        key: 'Bezoekdatum',
        label: 'Bezoekdatum',
        breedte: 125,
        formatFabriek: (rij) => {
          const datum =
            rij.Bezoekdatum !== null
              ? format(new Date(rij.Bezoekdatum), 'dd-MM-yyyy')
              : 'Geen datum';
          return datum;
        },
      },
    ],
    [],
  );

  //   if (productmodellen === null || relatie === null) {
  //     return <LoadingSpinner />;
  //   }

  return (
    <>
      <ModalBody>
        {/* <span>{JSON.stringify(values.contractwisselCntID)}</span>; */}
        <div className="form-group">
          <div className="col-12 mb-3">
            <label>{veldnamen.persID}</label>
            <Field
              name="persID"
              render={(fieldProps: FieldProps<IFormikValues>) => {
                const { field, form } = fieldProps;
                return (
                  <ContactSelectie
                    value={field.value === null ? null : { persID: field.value, orgID: null }}
                    onChange={(value) =>
                      form.setFieldValue(field.name, value?.persoon?.PersID ?? null)
                    }
                    alleenVoorRelIDs={[relatie.RelID]}
                    options={{
                      bijEnkeleDirectVoorselecteren: true,
                      types: [EType.Persoon],
                      // voorselectieRelatieContactpersoonType:
                      //   EVoorselectieRelatieContactpersoonType.Administratief,
                    }}
                  />
                );
              }}
            />
          </div>

          <div className="col-12 mt-3">
            <label>{veldnamen.locID}</label>
            <Field
              name="locID"
              render={(fieldProps: FieldProps<IFormikValues>) => {
                const { field, form } = fieldProps;
                return (
                  <LocatieSelectie
                    locID={fieldProps.field.value}
                    onChange={(locID) => {
                      form.setFieldValue(field.name, locID);
                    }}
                    relIDs={[relatie.RelID]}
                    options={{
                      bijEnkeleDirectVoorselecteren: true,
                    }}
                  />
                );
              }}
            />
          </div>

          {/* <div className="col-12 mt-3">
            <label>{veldnamen.prodModID}</label>
            <Field
              name={nameOf<IFormikValues>('prodModID')}
              render={(fieldProps: FieldProps<IFormikValues>) => {
                const { field, form } = fieldProps;

                return (
                  <MultiCombobox<number, any>
                    sleutelExtractor={(rij: IOphalenProductmodellenResultElement) => rij.ProdModID}
                    onWaardeChange={(waarde: number | null) => {
                      form.setFieldValue(field.name, waarde);
                    }}
                    representatieFabriek={(rij: IOphalenProductmodellenResultElement) =>
                      `${rij.Modelcode} - ${rij.Modelnaam}`
                    }
                    waarde={field.value}
                    opties={productmodellen.map((x) => {
                      return {
                        ProdModID: x.ProdModID,
                        Modelcode: x.Modelcode,
                        Modelnaam: x.Modelnaam,
                        Kenmerk: x.Kenmerk,
                        Categorie: x.productsoort.Naam,
                      };
                    })}
                    // opties={[]}
                    kolommen={productmodelKolommen}
                  />
                );
              }}
            />
          </div> */}

          <div className="col-12 mt-2">
            <label className="mt-1">{veldnamen.prodModID}</label>
            <Field
              name={nameOf<IFormikValues>('prodModID')}
              render={(fieldProps: FieldProps<IFormikValues>) => {
                const { field, form } = fieldProps;
                return (
                  <>
                    <ProductmodelSelectieCombobox
                      prodModID={fieldProps.field.value}
                      productmodellen={productmodellen}
                      onChange={(x) => {
                        form.setFieldValue(field.name, x);
                      }}
                    />
                  </>
                );
              }}
            />
          </div>

          {/* <div className="col-12 mt-2">
            <span className="d-flex align-items-center">
              <Field
                name={nameOf<IFormikValues>('filterOpSoort')}
                render={(fieldProps: FieldProps) => {
                  const { field, form } = fieldProps;
                  return (
                    <VinkVeld
                      aangevinkt={field.value}
                      onGewijzigd={(x) => {
                        // setFilterOpProductsoort(x);
                        form.setFieldValue(field.name, x);
                      }}
                    />
                  );
                }}
              />
              <span className="ml-2">{veldnamen.filterOpSoort}</span>
            </span>
          </div> */}

          <div className="col-6 mt-3">
            <label>{veldnamen.abonID}</label>
            <Field
              name="abonID"
              render={(fieldProps: FieldProps<IFormikValues>) => (
                <MultiCombobox<number, IOphalenAanbodResultElementTariefElement>
                  sleutelExtractor={(row) => row.AbonID}
                  onWaardeChange={(waarde: number | null) =>
                    fieldProps.form.setFieldValue(fieldProps.field.name, waarde)
                  }
                  representatieFabriek={(row) => row.Naam + ' - ' + formatteerBedrag(row.Maandhuur)}
                  waarde={fieldProps.field.value}
                  opties={abonnementOpties}
                  kolommen={abonnementKolommen}
                  // Er moet eerst een product model gekozen worden voordat er een
                  // abonnement kan worden geselecteerd
                  disabled={values.prodModID === null}
                />
              )}
            />
          </div>

          <div className="row col-12 d-flex mt-3">
            <div className="col-8">
              <label>{veldnamen.contractwisselCntID}</label>
              <Field
                name="contractwisselCntID"
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;
                  return (
                    <MultiCombobox<number, IWisselContract>
                      sleutelExtractor={(row) => row.CntID}
                      representatieFabriek={(row) => {
                        const minimaleDatum = addMonths(
                          new Date(row.IngangsdatumBasis),
                          row.basis.MinimaleTermijn,
                        );
                        const opzegbaar =
                          minimaleDatum > new Date()
                            ? 'opzegbaar per ' + format(minimaleDatum, 'dd-MM-yyyy')
                            : 'opzegbaar';

                        return (
                          row.basis.Basisnummer +
                          '.' +
                          row.Volgnummer +
                          ' - ' +
                          row.basis.productmodel.Modelcode +
                          ' (' +
                          opzegbaar +
                          ')'
                        );
                      }}
                      waarde={fieldProps.field.value}
                      onWaardeChange={(x) => {
                        return form.setFieldValue(field.name, x);
                      }}
                      opties={wisselContracten!}
                      kolommen={contractwisselKolommen}
                      isWisbaar
                      options={{
                        geenWaardeBericht: 'Geen wissel',
                      }}
                    />
                  );
                }}
              />
            </div>

            <div className="col-4 mt-2">
              <label></label>
              <Field
                name={nameOf<IFormikValues>('meerContractenWeergeven')}
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;
                  return (
                    <>
                      <div className="d-flex align-items-center ml-3">
                        <VinkVeld
                          aangevinkt={field.value}
                          onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                        />
                        <span className="ml-2">{veldnamen.meerContractenWeergeven}</span>
                      </div>
                    </>
                  );
                }}
              />
            </div>
          </div>

          <div className="col-12 mt-3">
            <div className="row col-4">
              <label>{veldnamen.verdieping}</label>
              <Field
                name={nameOf<IFormikValues>('verdieping')}
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;

                  return (
                    <>
                      <Combobox
                        legeOptieTonen
                        geselecteerd={fieldProps.field.value}
                        onSelectieChange={(x) => {
                          return form.setFieldValue(field.name, x);
                        }}
                        opties={verdiepingen!.map((x) => {
                          return {
                            id: x.Verdieping,
                            label: x.Naam,
                          };
                        })}
                      />
                    </>
                  );
                }}
              />
            </div>
          </div>

          <div className="col-12 mt-3">
            <div className="row col-4">
              <label>{veldnamen.aantalGebruikers}</label>
              <Field
                name={nameOf<IFormikValues>('aantalGebruikers')}
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;
                  return (
                    <Combobox
                      geselecteerd={fieldProps.field.value}
                      onSelectieChange={(x) => {
                        return form.setFieldValue(field.name, x);
                      }}
                      opties={aantalGebruikersOpties}
                    />
                  );
                }}
              />
            </div>
          </div>

          <div className="row col-12 mt-3">
            <Field
              name={nameOf<IFormikValues>('aansluitenTrekschakelaar')}
              render={(fieldProps: FieldProps<IFormikValues>) => {
                const { field, form } = fieldProps;
                return (
                  <>
                    <div className="d-flex align-items-center ml-3">
                      <VinkVeld
                        aangevinkt={field.value}
                        onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                      />
                      <span className="ml-2">{veldnamen.aansluitenTrekschakelaar}</span>
                    </div>
                  </>
                );
              }}
            />
          </div>

          <div className="row col-12 mt-3">
            <Field
              name={nameOf<IFormikValues>('kostenVtbNietToepassen')}
              render={(fieldProps: FieldProps<IFormikValues>) => {
                const { field, form } = fieldProps;
                return (
                  <>
                    <div className="d-flex align-items-center ml-3">
                      <VinkVeld
                        aangevinkt={field.value}
                        onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                        disabled={values.contractwisselCntID === null}
                      />
                      <span className="ml-2">{veldnamen.kostenVtbNietToepassen}</span>
                    </div>
                  </>
                );
              }}
            />
          </div>

          <div className="row col-12 mt-3">
            <div className="row col-6">
              <Field
                name={nameOf<IFormikValues>('transportopdrachtenMaken')}
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;
                  return (
                    <>
                      <div className="d-flex align-items-center ml-3">
                        <VinkVeld
                          aangevinkt={field.value}
                          onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                        />
                        <span className="ml-2">
                          {veldnamen.transportopdrachtenMaken}
                          {transportopdrachten !== null &&
                            transportopdrachten.length !== 0 &&
                            ', toevoegen aan:'}
                        </span>
                      </div>
                    </>
                  );
                }}
              />
            </div>

            <div className="row col-6">
              {/* <div>
                <label>{veldnamen.trsOpdID}</label>
              </div> */}

              <div>
                <Field
                  name={nameOf<IFormikValues>('trsOpdID')}
                  render={(fieldProps: FieldProps<IFormikValues>) => {
                    const { field, form } = fieldProps;

                    if (transportopdrachten === null) {
                      return <span></span>;
                    }

                    return (
                      <MultiCombobox<number, IOphalenOpdrachtenResultElementV2>
                        sleutelExtractor={(row) => row.TrsOpdID}
                        representatieFabriek={
                          (row) =>
                            row.Opdrachtnummer +
                            ' - ' +
                            (row.Bezoekdatum !== null
                              ? format(new Date(row.Bezoekdatum), 'dd-MM-yyyy')
                              : 'Geen datum')
                          //   +
                          // ' - ' +
                          // row.locatie.Straatnaam +
                          // ' ' +
                          // row.locatie.Huisnummer +
                          // ' ' +
                          // (row.locatie.Bisnummer ?? '')
                        }
                        waarde={field.value}
                        onWaardeChange={(x) => form.setFieldValue(field.name, x)}
                        opties={transportopdrachten}
                        kolommen={transportopdrachtKolommen}
                        isWisbaar
                        options={{
                          geenWaardeBericht: 'Toevoegen aan nieuwe opdracht',
                        }}
                        disabled={!values.transportopdrachtenMaken}
                      />
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter className="d-flex flex-row justify-content-start">
        <button
          className="btn btn-primary"
          onClick={submitForm}
          style={{
            width: 100,
          }}
          disabled={isSubmitting || !isValid}
        >
          Ok
        </button>
        <button
          className="btn btn-secondary"
          onClick={props.onAnnuleren}
          style={{
            width: 100,
          }}
        >
          Annuleren
        </button>
      </ModalFooter>
    </>
  );
};

export default NieuwContractDialoogV2;
