import React, { useCallback, useContext, useMemo } from 'react';
import { EResultType, IControlerenParams, IControlerenResult } from '../../../stores/CheckStore';
import Modal from 'react-bootstrap/Modal';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { RootStoreContext } from '../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import CheckDataMelding from '../../../components/CheckDataMelding';
import Dialoog from '../../../components/dialogen/Dialoog';

interface IProps {
  id: string;
  data: IControlerenParams;
  resolve: (result: IControlerenResult) => void;
  index: number;
}

const CheckDialoog: React.FC<IProps> = observer((props) => {
  const { checkStore } = useContext(RootStoreContext);
  const { resolve } = props;
  const { titel, checkData } = props.data;

  const okToestaan = useMemo(() => checkData.errors.length === 0, [checkData.errors]);

  const handleOkClick = useCallback(
    (ev) => {
      ev.stopPropagation();

      checkStore.afhandelenCheck(props.id);
      resolve({
        type: EResultType.Ok,
        heeftGebruikersinteractieGehad: true,
      });
    },
    [props.id, resolve],
  );

  const handleAnnulerenClick = useCallback(
    (ev) => {
      ev.stopPropagation();
      checkStore.afhandelenCheck(props.id);
      resolve({
        type: EResultType.Annuleren,
        heeftGebruikersinteractieGehad: true,
      });
    },
    [props.id, resolve],
  );

  return (
    <Dialoog index={props.index}>
      {titel !== undefined && <ModalHeader>{titel}</ModalHeader>}
      <ModalBody>
        <CheckDataMelding checkData={checkData} />
      </ModalBody>
      <ModalFooter className="d-flex justify-content-start">
        <button
          className="btn btn-primary"
          onClick={okToestaan ? handleOkClick : handleAnnulerenClick}
          style={{ width: 100 }}
        >
          Ok
        </button>
        {okToestaan && (
          <button
            className="btn btn-secondary"
            onClick={handleAnnulerenClick}
            style={{ width: 100 }}
          >
            Annuleren
          </button>
        )}
      </ModalFooter>
    </Dialoog>
  );
});

export default CheckDialoog;
