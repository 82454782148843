import { createContext } from 'react';
import FormulierDataStore from './FormulierDataStore';
import GebruikerStore from './GebruikerStore';
import KlantkaartStore from './KlantkaartStore';
import AbonnementenStore from './AbonnementenStore';
import StandaardWaardeStore from './StandaardWaardeStore';
import BerichtStore from './BerichtStore';
import InstellingStore from './InstellingStore';
import RelatieStore from './RelatieStore';
import WhatsappStore from './WhatsappStore';
import ProductsoortenStore from './ProductsoortenStore';
import ContractenStore from './ContractenStore';
import RealtimeStore from './RealtimeStore';
import SMSStore from './SMSStore';
import PersoonStore from './PersoonStore';
import BankStore from './BankStore';
import EmailStore from './EmailStore';
import TelefoonStore from './TelefoonStore';
import ContractActiesStore from './ContractActiesStore';
import AccountStore from './AccountStore';
import ContactenStore from './ContactenStore';
import BankOpdrachtenStore from './BankOpdrachtenStore';
import LocatieStore from './LocatieStore';
import OrganisatieStore from './OrganisatieStore';
import NotificatieStore from './NotificatieStore';
import CheckStore from './CheckStore';
import OverdrachtStore from './OverdrachtStore';
import ContractBeeindigingStore from './ContractBeeindigingStore';
import ControlStore from './ControlStore';
import ContractenStoreV2 from './ContractenStoreV2';
import ProfielStore from './ProfielStore';
import TechnischStore from './TechnischStore';
import TekstStore from './TekstStore';
import ApiStore from './ApiStore';
import MemoryCachedStore from './MemoryCachedStore';
import MemoStore from './MemoStore';
import TransportopdrachtStore from './TransportopdrachtStore';
import FactuurStore from './FactuurStore';
import ToetsenbordStore from './ToetsenbordStore';
import BestandStore from './BestandStore';
import AutorisatieStore from './AutorisatieStore';
import TransportopdrachtregelStore from './TransportopdrachtregelStore';
import VoipStore from './VoipStore';
import SessieStore from './SessieStore';
import VerkoopvoorstelStore from './VerkoopvoorstelStore';
import ServicemeldingStore from './ServicemeldingStore';
import ProducttypeStore from './ProducttypeStore';
import InkoopfactuurStore from './InkoopfactuurStore';
import ServiceopdrachtStore from './ServiceopdrachtStore';
import IntrasessieStore from './IntrasessieStore';
import MeldingIndicatieStore from './MeldingIndicatieStore';
import TelefoonHistorieStore from './TelefoonHistorieStore';
import AfdelingStore from './AfdelingStore';
import InfoIndicatieStore from './InfoIndicatieStore';
import ZoekStore from './ZoekStore';
import BetalingsregelingStore from './BetalingsregelingStore';
import ContactverzoekStore from './ContactverzoekStore';
import ServicedienstStore from './ServicedienstStore';

export class RootStore {
  public gebruikerStore: GebruikerStore;
  public formulierDataStore: FormulierDataStore;
  public klantkaartStore: KlantkaartStore;
  public abonnementenStore: AbonnementenStore;
  public standaardWaardeStore: StandaardWaardeStore;
  public berichtStore: BerichtStore;
  public instellingStore: InstellingStore;
  public relatieStore: RelatieStore;
  public whatsappStore: WhatsappStore;
  public productsoortenStore: ProductsoortenStore;
  public contractenStore: ContractenStore;
  public realtimeStore: RealtimeStore;
  public smsStore: SMSStore;
  public persoonStore: PersoonStore;
  public bankStore: BankStore;
  public emailStore: EmailStore;
  public telefoonStore: TelefoonStore;
  public contractActiesStore: ContractActiesStore;
  public accountStore: AccountStore;
  public bankOpdrachtenStore: BankOpdrachtenStore;
  public contactenStore: ContactenStore;
  public locatieStore: LocatieStore;
  public organisatieStore: OrganisatieStore;
  public notificatieStore: NotificatieStore;
  public checkStore: CheckStore;
  public overdrachtStore: OverdrachtStore;
  public contractBeeindigingStore: ContractBeeindigingStore;
  public controlStore: ControlStore;
  public contractenStoreV2: ContractenStoreV2;
  public profielStore: ProfielStore;
  public technischStore: TechnischStore;
  public tekstStore: TekstStore;
  public apiStore: ApiStore;
  public memoryCachedStore: MemoryCachedStore;
  public memoStore: MemoStore;
  public transportopdrachtStore: TransportopdrachtStore;
  public toetsenbordStore: ToetsenbordStore;
  public bestandStore: BestandStore;
  public autorisatieStore: AutorisatieStore;
  public transportopdrachtregelStore: TransportopdrachtregelStore;
  public voipStore: VoipStore;
  public sessieStore: SessieStore;
  public servicemeldingStore: ServicemeldingStore;
  public verkoopvoorstellenStore: VerkoopvoorstelStore;
  public factuurStore: FactuurStore;
  public producttypeStore: ProducttypeStore;
  public inkoopfactuurStore: InkoopfactuurStore;
  public serviceopdrachtStore: ServiceopdrachtStore;
  public intrasessieStore: IntrasessieStore;
  public meldingIndicatieStore: MeldingIndicatieStore;
  public telefoonHistorieCommunicatieLayoutStore: TelefoonHistorieStore;
  public telefoonHistorieCommunicatieGlobaalStore: TelefoonHistorieStore;
  public afdelingStore: AfdelingStore;
  public infoIndicatieStore: InfoIndicatieStore;
  public zoekStore: ZoekStore;
  public betalingsregelingStore: BetalingsregelingStore;
  public contactverzoekStore: ContactverzoekStore;
  public servicedienstStore: ServicedienstStore;

  constructor() {
    this.apiStore = new ApiStore();
    this.gebruikerStore = new GebruikerStore(this);
    this.formulierDataStore = new FormulierDataStore(this);
    this.klantkaartStore = new KlantkaartStore();
    this.abonnementenStore = new AbonnementenStore();
    this.standaardWaardeStore = new StandaardWaardeStore();
    this.berichtStore = new BerichtStore();
    this.instellingStore = new InstellingStore();
    this.relatieStore = new RelatieStore(this);
    this.whatsappStore = new WhatsappStore(this);
    this.productsoortenStore = new ProductsoortenStore();
    this.contractenStore = new ContractenStore(this);
    this.realtimeStore = new RealtimeStore(this);
    this.smsStore = new SMSStore(this);
    this.persoonStore = new PersoonStore(this);
    this.bankStore = new BankStore(this);
    this.emailStore = new EmailStore(this);
    this.telefoonStore = new TelefoonStore(this);
    this.contractActiesStore = new ContractActiesStore();
    this.accountStore = new AccountStore(this);
    this.bankOpdrachtenStore = new BankOpdrachtenStore(this);
    this.contactenStore = new ContactenStore();
    this.locatieStore = new LocatieStore(this);
    this.notificatieStore = new NotificatieStore(this);
    this.organisatieStore = new OrganisatieStore(this);
    this.checkStore = new CheckStore(this);
    this.overdrachtStore = new OverdrachtStore(this);
    this.contractBeeindigingStore = new ContractBeeindigingStore(this);
    this.controlStore = new ControlStore(this);
    this.contractenStoreV2 = new ContractenStoreV2(this);
    this.profielStore = new ProfielStore();
    this.technischStore = new TechnischStore(this);
    this.tekstStore = new TekstStore(this);
    this.memoryCachedStore = new MemoryCachedStore();
    this.memoStore = new MemoStore();
    this.transportopdrachtStore = new TransportopdrachtStore();
    this.toetsenbordStore = new ToetsenbordStore();
    this.bestandStore = new BestandStore(this);
    this.autorisatieStore = new AutorisatieStore(this);
    this.transportopdrachtregelStore = new TransportopdrachtregelStore();
    this.voipStore = new VoipStore();
    this.sessieStore = new SessieStore();
    this.servicemeldingStore = new ServicemeldingStore();
    this.verkoopvoorstellenStore = new VerkoopvoorstelStore();
    this.factuurStore = new FactuurStore();
    this.producttypeStore = new ProducttypeStore();
    this.inkoopfactuurStore = new InkoopfactuurStore();
    this.serviceopdrachtStore = new ServiceopdrachtStore();
    this.intrasessieStore = new IntrasessieStore(this);
    this.meldingIndicatieStore = new MeldingIndicatieStore(this);
    this.telefoonHistorieCommunicatieLayoutStore = new TelefoonHistorieStore();
    this.telefoonHistorieCommunicatieGlobaalStore = new TelefoonHistorieStore();
    this.afdelingStore = new AfdelingStore();
    this.infoIndicatieStore = new InfoIndicatieStore(this);
    this.zoekStore = new ZoekStore();
    this.betalingsregelingStore = new BetalingsregelingStore();
    this.contactverzoekStore = new ContactverzoekStore(this);
    this.servicedienstStore = new ServicedienstStore(this);
  }
}

export const rootStoreInstance = new RootStore();
export const RootStoreContext = createContext(rootStoreInstance);
