import React, { useContext, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import { RootStoreContext } from '../../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import Placeholder from './Placeholder';
import Data from './Data';

interface IProps {
  emailBerID: number; // bijv 'persID', nog hernoemen
}

const Root = styled.div`
  background: ${Kleur.Wit};
  border: 1px solid ${Kleur.LichtGrijs};
  border-radius: 4px;
  padding: 12px 16px;
  flex: 1;
  width: 500px;
`;

const InfoCard = observer((props: IProps) => {
  // const { persoonStore } = useContext(RootStoreContext);
  // const persoon = useMemo<IOphalenPersonenResultElementV2 | null>(() => {
  //   const pers = persoonStore.personen[props.persID];
  //   if (pers === undefined) {
  //     return null;
  //   }
  //   return pers;
  // }, [persoonStore.personen, props.persID]);
  // useEffect(() => {
  //   if (persoon !== null) {
  //     return;
  //   }
  //   persoonStore.enqueuePersoonOphalen(props.persID);
  // }, [persoon, props.persID]);

  return (
    <Root onClick={(ev) => ev.stopPropagation()}>
      {/*{persoon === null ? <Placeholder /> : <Data persoon={persoon} />}*/}
    </Root>
  );
});

export default InfoCard;
