import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import IDialoogProps from '../../../../../../../../core/IDialoogProps';
import Dialoog from '../../../../../../../../components/dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import * as Yup from 'yup';
import api from '../../../../../../../../api';
import nameOf from '../../../../../../../../core/nameOf';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../../../../../../stores/RootStore';
import VinkVeld from '../../../../../../../../components/formulier/VinkVeld';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../../../../../models/IRemoteData';
import { IOphalenTekstenResultElement } from '../../../../../../../../../../shared/src/api/v2/tekst';
import LoadingSpinner from '../../../../../../../../components/Gedeeld/LoadingSpinner';
import {
  IOphalenVertegenwoordigersResultElement,
  IOphalenVertegenwoordigingHoedanighedenResultElement,
} from '../../../../../../../../../../shared/src/api/v2/relatie/vertegenwoordiger';
import hoedanigheid from '../../../../../../../../api/v2/relatie/hoedanigheid';
import Combobox from '../../../../../../../../components/formulier/Combobox';

interface IProps extends IDialoogProps<null> {
  id: number;
}

interface IFormikValues {
  hoedanigheidID: number;
  facturenDoorsturen: boolean;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  hoedanigheidID: 'Hoedanigheid',
  facturenDoorsturen: 'Facturen doorsturen',
};

const WijzigenDialoog: React.FC<IProps> = observer((props) => {
  const { dialoogIndex, onAnnuleren, onSuccess } = props;
  const { checkStore } = useContext(RootStoreContext);
  const formikRef = useRef<Formik<IFormikValues>>(null);

  const [hoedanigheden, setHoedanigheden] = useState<
    IOphalenVertegenwoordigingHoedanighedenResultElement[] | null
  >(null);

  const [
    vertegenwoordiger,
    setVertegenwoordiger,
  ] = useState<IOphalenVertegenwoordigersResultElement | null>(null);

  const [teksten, setTeksten] = useState<IRemoteData<IOphalenTekstenResultElement[] | null>>(
    createPendingRemoteData(),
  );

  const ophalenVertegenwoordiger = useCallback(async () => {
    const result = await api.v2.relatie.vertegenwoordiging.ophalenVertegenwoordigers({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: [props.id],
          },
        ],
      },
    });

    setVertegenwoordiger(result.vertegenwoordigers[0]);
  }, []);

  useEffect(() => {
    ophalenVertegenwoordiger();
  }, [ophalenVertegenwoordiger]);

  const ophalenHoedanigheden = useCallback(async () => {
    const result = await api.v2.relatie.vertegenwoordiging.ophalenHoedanigheden({
      //  filterSchema: {
      //    filters: [{ naam: 'REL_IDS', data: [props.relID] }],
      //  },
    });

    setHoedanigheden(result.hoedanigheden);
  }, []);

  useEffect(() => {
    ophalenHoedanigheden();
  }, [ophalenHoedanigheden]);

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (vertegenwoordiger === null) {
      return null;
    }

    return {
      facturenDoorsturen: vertegenwoordiger.FacturenDoorsturen,
      hoedanigheidID: vertegenwoordiger.hoedanigheid.ID,
    };
  }, [vertegenwoordiger]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      if (vertegenwoordiger === null) {
        return;
      }
      actions.setSubmitting(true);

      const params = {
        id: props.id,
        facturenDoorsturen: values.facturenDoorsturen,
        hoedanigheidID: values.hoedanigheidID,
      };

      await api.v2.relatie.vertegenwoordiging.wijzigenVertegenwoordiger(params);

      actions.setSubmitting(false);
      onSuccess(null);
    },
    [vertegenwoordiger],
  );

  const validationSchema = useMemo(() => {
    const fields: Partial<Record<keyof IFormikValues, any>> = {
      facturenDoorsturen: Yup.boolean(),
    };
    return Yup.object().shape(fields);
  }, []);

  return (
    <Dialoog index={dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Wijzigen vertegenwoordiging</ModalTitle>
      </ModalHeader>
      {initialValues === null || hoedanigheden === null ? (
        <LoadingSpinner />
      ) : (
        <Formik<IFormikValues>
          ref={formikRef}
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
          render={(formikProps: FormikProps<IFormikValues>) => {
            const { submitForm, isSubmitting, isValid } = formikProps;
            return (
              <>
                <ModalBody>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-12 mt-3">
                        <label>{veldnamen.hoedanigheidID}</label>
                        <Field
                          name={nameOf<IFormikValues>('hoedanigheidID')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            return (
                              <>
                                <Combobox
                                  geselecteerd={field.value}
                                  opties={hoedanigheden.map((x) => {
                                    return {
                                      id: x.ID,
                                      label: x.Naam,
                                    };
                                  })}
                                  onSelectieChange={(x) => form.setFieldValue(field.name, x)}
                                />
                              </>
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <span className="d-flex align-items-center">
                          <Field
                            name={nameOf<IFormikValues>('facturenDoorsturen')}
                            render={(x: FieldProps<IFormikValues>) => (
                              <VinkVeld
                                aangevinkt={x.field.value}
                                onGewijzigd={(aangevinkt: boolean) =>
                                  x.form.setFieldValue(x.field.name, aangevinkt)
                                }
                              />
                            )}
                          />
                          <span className="ml-2">{veldnamen.facturenDoorsturen}</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </ModalBody>

                <ModalFooter className="d-flex flex-row justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    style={{
                      width: 100,
                    }}
                    disabled={isSubmitting || !isValid}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={onAnnuleren}
                    style={{
                      width: 100,
                    }}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Dialoog>
  );
});

export default WijzigenDialoog;
