import IDialoogProps from '../../../../../../core/IDialoogProps';
import Dialoog from '../../../../../../components/dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/esm/ModalHeader';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ProgressieBalk from '../../../../../../components/ProgressieBalk';
import { IAchtergrondProces } from '../../../../../../../../shared/src/api/v2/achtergrondProces';
import { IAanbiedenVoorVerzendenResult } from '../../../../../../../../shared/src/api/v2/bulkbericht';
import { achtergrondProcesAfwachten } from '../../../../../../core/achtergrondProces';
import { RealtimeContext } from '../../../../../../one-off-components/realtime/RealtimeIntegratie';
import ModalFooter from 'react-bootstrap/ModalFooter';

interface IProps extends IDialoogProps<null> {
  achtergrondProces: IAchtergrondProces<IAanbiedenVoorVerzendenResult>;
}

const AanbiedenVoorVerzendenProgressieDialoog = (props: IProps) => {
  const realtimeContext = useContext(RealtimeContext);
  const [percentage, setPercentage] = useState(0);

  const start = useCallback(async () => {
    await achtergrondProcesAfwachten(props.achtergrondProces.id, realtimeContext, (percentage) =>
      setPercentage(percentage),
    );
  }, [props.achtergrondProces.id]);

  useEffect(() => {
    start();
  }, [start]);

  return (
    <Dialoog index={props.dialoogIndex ?? 0}>
      <ModalHeader>
        <ModalTitle>Aanbieden voor verzenden</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <p>De berichten worden aangeboden voor verzenden. Dit kan enige tijd duren.</p>
        <ProgressieBalk percentage={percentage} />
      </ModalBody>
      {percentage >= 1 && (
        <ModalFooter className="d-flex flex-row justify-content-start">
          <button
            className="btn btn-primary"
            onClick={() => props.onSuccess(null)}
            style={{ width: 100 }}
          >
            Sluiten
          </button>
        </ModalFooter>
      )}
    </Dialoog>
  );
};

export default AanbiedenVoorVerzendenProgressieDialoog;
