import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Field, FieldProps, Formik, FormikActions, FormikErrors, FormikProps } from 'formik';
import api from '../../../api';
import IDialoogProps from '../../../core/IDialoogProps';
import { RootStoreContext } from '../../../stores/RootStore';
import Dialoog from '../../../components/dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import nameOf from '../../../core/nameOf';
import VinkVeld from '../../../components/formulier/VinkVeld';
import { EResultType } from '../../../stores/CheckStore';
import {
  IOphalenDienstenResult,
  IOphalenDienstenResultElement,
} from '../../../../../shared/src/api/v2/dienst/service';
import { IOphalenOpdrachtenResultElement } from '../../../../../shared/src/api/v2/service/opdracht';
import _ from 'lodash';
import LoadingSpinner from '../../Gedeeld/LoadingSpinner';

interface IProps extends IDialoogProps<null> {
  servOpdIDs: number[];
}

interface IFormikValues {
  annuleringsberichtVersturen: boolean;
  emailadres: string | '';
  berichtInhoud: string | '';
}

const veldnamen: Record<keyof IFormikValues, string> = {
  annuleringsberichtVersturen: 'Annuleringsbericht versturen',
  emailadres: 'Emailadres',
  berichtInhoud: 'Berichtinhoud',
};

const AnnulerenOpdrachtDialoog: React.FC<IProps> = (props) => {
  const { open, onSuccess, onAnnuleren, dialoogIndex } = props;
  const formikRef = useRef<Formik<IFormikValues>>(null);
  const { checkStore } = useContext(RootStoreContext);
  const [opdrachten, setOpdrachten] = useState<IOphalenOpdrachtenResultElement[] | null>(null);
  const [diensten, setDiensten] = useState<IOphalenDienstenResultElement[] | null>(null);

  const ophalenOpdrachten = useCallback(async () => {
    const opdrachtenResult = await api.v2.service.ophalenOpdrachten({
      filterSchema: {
        filters: [{ naam: 'IDS', data: props.servOpdIDs }],
      },
    });

    setOpdrachten(opdrachtenResult.opdrachten);
  }, [props.servOpdIDs]);

  useEffect(() => {
    ophalenOpdrachten();
  }, [ophalenOpdrachten]);

  const ophalenDiensten = useCallback(async () => {
    if (opdrachten === null) {
      return;
    }

    const dienstenResult = await api.v2.dienst.service.ophalenDiensten({
      filterSchema: {
        filters: [{ naam: 'IDS', data: _.uniq(opdrachten.map((x) => x.dienst.ID)) }],
      },
    });

    setDiensten(dienstenResult.diensten);
  }, [opdrachten]);

  useEffect(() => {
    ophalenDiensten();
  }, [ophalenDiensten]);

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (diensten === null) {
      return null;
    }

    const emailadres = diensten.map((x) => x.EmailOpdracht).join(',');

    return {
      annuleringsberichtVersturen: false,
      emailadres,
      berichtInhoud: '',
    };
  }, [diensten]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      if (diensten === null) {
        return;
      }

      actions.setSubmitting(true);

      if (diensten.some((x) => x.EmailOpdracht === null)) {
        await checkStore.melden({
          titel: (
            <span>
              Er is geen emailadres bekend voor deze servicedienst
              <br />
              Voeg deze eerst toe bij de leverancier/servicedienst
            </span>
          ),
        });
        actions.setSubmitting(false);
        return;
      }

      const resultaat = await checkStore.bevestigen({
        inhoud: (
          <span>
            Opdracht annuleren?
            {values.annuleringsberichtVersturen && (
              <>
                <br />
                Er zal tevens een annuleringsbericht verstuurd worden.
              </>
            )}
          </span>
        ),
      });
      if (resultaat.type === EResultType.Annuleren) {
        actions.setSubmitting(false);
        return;
      }

      const params = { ids: props.servOpdIDs };
      const checkData = await api.v2.service.checkAnnulerenServiceOpdrachten(params);
      if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
        return;
      }

      await api.v2.service.annulerenServiceOpdrachten(params);

      if (values.annuleringsberichtVersturen) {
        await api.v2.service.versturenAnnuleringsbericht({
          ids: props.servOpdIDs,
          berichtInhoud: values.berichtInhoud,
        });
      }

      onSuccess(null);
      actions.setSubmitting(false);
    },
    [onSuccess, diensten],
  );

  // const validationSchema = useCallback(() => {}, []);

  const validate = useCallback(
    (values: IFormikValues) => {
      if (diensten === null) {
        return;
      }
      const errors: FormikErrors<IFormikValues> = {};

      return errors;
    },
    [diensten],
  );

  if (initialValues === null) {
    return (
      <div className="d-flex flex-fill align-items-center justify-content-center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <Dialoog index={dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Annuleren serviceopdracht</ModalTitle>
      </ModalHeader>
      <Formik<IFormikValues>
        ref={formikRef}
        onSubmit={handleSubmit}
        initialValues={initialValues}
        isInitialValid={true}
        enableReinitialize
        // validationSchema={validationSchema}
        validate={validate}
        render={(formikProps: FormikProps<IFormikValues>) => {
          const { submitForm, isSubmitting, isValid, values } = formikProps;

          return (
            <>
              <ModalBody>
                <div className="form-group">
                  <div className="row">
                    <div className="col-12 mt-3">
                      <div className="d-flex align-items-center">
                        <Field
                          name={nameOf<IFormikValues>('annuleringsberichtVersturen')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <VinkVeld
                                aangevinkt={field.value}
                                onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                              />
                            );
                          }}
                        />
                        <span className="ml-2">{veldnamen.annuleringsberichtVersturen}</span>
                      </div>
                    </div>
                    {values.annuleringsberichtVersturen && (
                      <div className="col-12 mt-3">
                        <label>{veldnamen.berichtInhoud}</label>
                        <Field
                          name={nameOf<IFormikValues>('berichtInhoud')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return <textarea {...field} className="form-control" rows={2} />;
                          }}
                        />
                      </div>
                    )}

                    <div className="col-12 mt-3">
                      <label>{veldnamen.emailadres}</label>
                      <Field
                        name={nameOf<IFormikValues>('emailadres')}
                        render={(fieldProps: FieldProps<IFormikValues>) => {
                          const { field, form } = fieldProps;
                          return <input type="text" className="form-control" {...field} disabled />;
                        }}
                      />
                    </div>
                  </div>
                </div>
              </ModalBody>
              <ModalFooter className="d-flex flex-row justify-content-start">
                <button
                  className="btn btn-primary"
                  onClick={submitForm}
                  style={{ width: 100 }}
                  disabled={isSubmitting || !isValid}
                >
                  Ok
                </button>
                <button className="btn btn-secondary" onClick={onAnnuleren} style={{ width: 100 }}>
                  Annuleren
                </button>
              </ModalFooter>
            </>
          );
        }}
      />
    </Dialoog>
  );
};

export default AnnulerenOpdrachtDialoog;
