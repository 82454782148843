import React, { useContext, useEffect, useMemo, useState } from 'react';
import NavigatieOverlay from '../NavigatieOverlay';
import HorizontaleScheidingslijn from '../../../components/layout/HorizontaleScheidingslijn';
import { Kleur } from '../../../bedrijfslogica/constanten';
import {
  IconDatabase,
  IconDatumreeks,
  IconGit,
  IconServer,
  IconToevoegen,
  IconWasmachine,
  IconZoeken,
} from '../../../components/Icons';
import styled from 'styled-components';
import GlobaleRenderer, {
  GlobaleRendererContext,
} from '../../../one-off-components/GlobaleRenderer';
import BezoekdagenInfoDialoog from '../../../components/transport/BezoekdagenInfoDialoog';
import HandmatigControltaakToevoegenDialoog from '../../../components/control/HandmatigControltaakToevoegenDialoog';
import ProductSelectieDialoog from '../../../components/product/ProductSelectieDialoog';
import ProductinfoDialoog from '../../../components/product/ProductinfoDialoog';
import { RootStoreContext } from '../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import LoadingSpinner from '../../../components/Gedeeld/LoadingSpinner';

const Item = styled.div`
  cursor: pointer;
  height: 35px;
  display: flex;
  align-items: center;

  &:hover {
    //background-color: ${Kleur.SelectieGrijs};
    background-color: rgba(1, 1, 1, 0.1);
  }
`;

interface IProps {}

const TechnischeInfoOverlay: React.FC<IProps> = observer((props) => {
  const globaleRenderer = useContext(GlobaleRendererContext);
  const { technischStore, realtimeStore } = useContext(RootStoreContext);

  useEffect(() => {
    if (technischStore.informatie !== null) {
      return;
    }
    technischStore.ophalenInformatie();
  }, []);

  const { informatie } = technischStore;

  return (
    <>
      <NavigatieOverlay>
        <div
          className="d-flex flex-column flex-fill justify-content-center align-items-center p-2 pl-5 pr-5"
          style={{ width: '100%', fontSize: 12 }}
        >
          {informatie === null ? (
            <LoadingSpinner />
          ) : (
            <div className="d-flex flex-column flex-fill align-items-center">
              <div className="d-flex align-items-center" style={{ minWidth: 100 }}>
                <span>
                  <IconDatabase style={{ width: 11, height: 11, fill: Kleur.LichtGrijs }} />
                </span>
                <span className="ml-3">{informatie.database.naam}</span>
              </div>
              <div className="d-flex align-items-center mt-1" style={{ minWidth: 100 }}>
                <span>
                  <IconServer style={{ width: 11, height: 11, fill: Kleur.Wit }} />
                </span>
                <span className="ml-3">{informatie.database.host}</span>
              </div>
              <div className="d-flex align-items-center mt-1" style={{ minWidth: 100 }}>
                <span>
                  <IconGit style={{ width: 11, height: 11, fill: Kleur.Wit }} />
                </span>
                <span className="ml-3">{informatie.git.commitKort}</span>
              </div>
              <div className="d-flex align-items-center mt-1" style={{ minWidth: 100 }}>
                <span>O</span>
                <span className="ml-3">{informatie.omgevingsnaam}</span>
              </div>
            </div>
          )}
          <div className="d-flex align-items-center mt-1" style={{ width: 100 }}>
            Realtime&nbsp;
            {realtimeStore.isConnected ? 'verbonden' : 'verbroken'}
          </div>
        </div>
      </NavigatieOverlay>
    </>
  );
});

export default TechnischeInfoOverlay;
