import React, { useCallback, useEffect, useMemo, useState } from 'react';
import MultiCombobox, { IKolom } from '../MultiCombobox';
import {
  IOphalenMutatiesResult,
  IOphalenMutatiesResultElement,
} from '../../../../../shared/src/api/v2/bank/mutaties';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../models/IRemoteData';
import { format } from 'date-fns';
import FormatteerBedrag from '../../MutatieBedrag';
import api from '../../../api';
import MultiComboboxV2, {
  Provider,
  IRepresentatieProps,
  IOverlayOptions,
  EnkeleProvider,
} from '../MultiComboboxV2';
import { ASPKolom, EAspKolomBreedteType } from '../../tabel/ASPTabel/types';

// export interface IMutatiesProvider {
//   provide: () => Promise<IOphalenMutatiesResultElement[]>;
// }
//
// const standaardMutatiesProvider: IMutatiesProvider = {
//   provide: async () => {
//     const mutatiesResult = await api.v2.bank.mutatie.ophalenMutaties({
//       filterSchema: {
//         filters: [
//           {
//             naam: 'GEBOEKT',
//             data: false,
//           },
//         ],
//       },
//     });
//     return mutatiesResult.mutaties;
//   },
// };
//
// interface IProps {
//   bankMutID: number | null;
//   onBankMutIDChange: (bankMutID: number | null) => void;
//   disabled?: boolean;
//   isWisbaar?: boolean;
//   mutatiesProvider?: IMutatiesProvider;
// }
//
// const BankmutatieSelectie: React.FC<IProps> = (props) => {
//   const mutatiesProvider = useMemo(() => props.mutatiesProvider ?? standaardMutatiesProvider, [
//     props.mutatiesProvider,
//   ]);
//
//   const [bankmutatiesResult, setBankmutatiesResult] = useState<
//     IRemoteData<IOphalenMutatiesResultElement[]>
//   >(createPendingRemoteData());
//   const ophalenBankmutaties = useCallback(async () => {
//     const mutaties = await mutatiesProvider.provide();
//     setBankmutatiesResult(createReadyRemoteData(mutaties));
//   }, [mutatiesProvider]);
//   useEffect(() => {
//     ophalenBankmutaties();
//   }, [ophalenBankmutaties]);
//
//   const sleutelExtractor = useCallback(
//     (bankmutatie: IOphalenMutatiesResultElement) => bankmutatie.BankMutID,
//     [],
//   );
//   const representatieFabriek = useCallback((bankmutatie: IOphalenMutatiesResultElement) => {
//     const mutatiedatumStr = format(new Date(bankmutatie.Mutatiedatum), 'dd-MM-yyyy');
//     return (
//       <span className="d-flex align-items-center">
//         {mutatiedatumStr}
//         &nbsp;&ndash;&nbsp;
//         <FormatteerBedrag bedrag={bankmutatie.Bedrag} />
//       </span>
//     );
//   }, []);
//
//   const kolommen = useMemo<IKolom<IOphalenMutatiesResultElement>[]>(
//     () => [
//       {
//         key: 'Mutatiedatum',
//         label: 'Datum',
//         formatFabriek: (x) => format(new Date(x.Mutatiedatum), 'dd-MM-yyyy'),
//         breedte: 150,
//       },
//       {
//         key: 'Bedrag',
//         label: 'Bedrag',
//         formatFabriek: (x) => <FormatteerBedrag bedrag={x.Bedrag} />,
//         breedte: 150,
//       },
//       {
//         key: 'Geboekt',
//         label: 'Geboekt',
//         breedte: 100,
//         formatFabriek: (x) => (x.Geboekt ? 'Ja' : 'Nee'),
//       },
//       {
//         key: 'Omschrijving',
//         label: 'Omschrijving',
//         breedte: 500,
//         formatFabriek: (x) => x.Omschrijving,
//       },
//     ],
//     [],
//   );
//
//   return (
//     <MultiCombobox<number, IOphalenMutatiesResultElement>
//       sleutelExtractor={sleutelExtractor}
//       representatieFabriek={representatieFabriek}
//       kolommen={kolommen}
//       waarde={props.bankMutID}
//       onWaardeChange={props.onBankMutIDChange}
//       disabled={props.disabled}
//       isWisbaar={props.isWisbaar}
//       onTonenChanged={async (tonen) => {
//         // if (tonen && bankmutatiesResult.state === ERemoteDataState.Pending) {
//         //   await ophalenBankmutaties();
//         // }
//       }}
//       opties={
//         bankmutatiesResult.state === ERemoteDataState.Pending ? null : bankmutatiesResult.data!
//       }
//       options={{
//         entiteitBepaler: async (id) => {
//           const mutatiesResult = await api.v2.bank.mutatie.ophalenMutaties({
//             filterSchema: {
//               filters: [
//                 {
//                   naam: 'IDS',
//                   data: [id],
//                 },
//               ],
//             },
//           });
//           return mutatiesResult.mutaties[0];
//         },
//       }}
//     />
//   );
// };

export enum EBankmutatieSelectieKolom {
  Mutatiedatum,
  Bedrag,
  Geboekt,
  Omschrijving,
}

const standaardProvider: Provider<
  EBankmutatieSelectieKolom,
  IOphalenMutatiesResultElement
> = async (params) => {
  const mutatiesResult = await api.v2.bank.mutatie.ophalenMutaties({
    filterSchema: {
      filters: [
        {
          naam: 'GEBOEKT',
          data: false,
        },
      ],
    },
    paginatie: params.paginatie,
    orderSchema: {
      orders: params.sortering.map((sortering) => {
        switch (sortering.key) {
          case EBankmutatieSelectieKolom.Mutatiedatum: {
            return {
              naam: 'MUTATIEDATUM',
              richting: sortering.sortering,
            };
          }
          case EBankmutatieSelectieKolom.Bedrag: {
            return {
              naam: 'BEDRAG',
              richting: sortering.sortering,
            };
          }
          case EBankmutatieSelectieKolom.Geboekt: {
            return {
              naam: 'GEBOEKT',
              richting: sortering.sortering,
            };
          }
          case EBankmutatieSelectieKolom.Omschrijving: {
            return {
              naam: 'OMSCHRIJVING',
              richting: sortering.sortering,
            };
          }
        }
      }),
    },
  });

  const items = mutatiesResult.mutaties.reduce(
    (acc, curr, idx) => ({
      ...acc,
      [idx + params.paginatie.index]: curr,
    }),
    params.huidigeBron,
  );
  return {
    items,
    totaalAantal: mutatiesResult.totaalAantal,
  };
};

const enkeleProvider: EnkeleProvider<
  EBankmutatieSelectieKolom,
  IOphalenMutatiesResultElement
> = async (bankMutID: number) => {
  const mutatiesResult = await api.v2.bank.mutatie.ophalenMutaties({
    filterSchema: {
      filters: [
        {
          naam: 'IDS',
          data: [bankMutID],
        },
      ],
    },
  });

  return mutatiesResult.mutaties[0];
};

interface IProps {
  bankMutID: number | null;
  onBankMutIDChange: (bankMutID: number | null) => void;
  disabled?: boolean;
  isWisbaar?: boolean;
  provider?: Provider<EBankmutatieSelectieKolom, IOphalenMutatiesResultElement>;
}

const BankmutatieSelectie = (props: IProps) => {
  const provider = useMemo(() => props.provider ?? standaardProvider, [props.provider]);

  const keyExtractor = useCallback((x: IOphalenMutatiesResultElement) => x.BankMutID, []);

  const Representatie = useCallback(
    (reprProps: IRepresentatieProps<IOphalenMutatiesResultElement>) => {
      const mutatiedatumStr = format(new Date(reprProps.entiteit.Mutatiedatum), 'dd-MM-yyyy');
      return (
        <span className="d-flex align-items-center">
          {mutatiedatumStr}
          &nbsp;&ndash;&nbsp;
          <FormatteerBedrag bedrag={reprProps.entiteit.Bedrag} />
        </span>
      );
    },
    [],
  );

  const kolommen = useMemo<ASPKolom<EBankmutatieSelectieKolom, IOphalenMutatiesResultElement>[]>(
    () => [
      {
        key: EBankmutatieSelectieKolom.Mutatiedatum,
        label: 'Datum',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 150,
        renderer: (x) => format(new Date(x.Mutatiedatum), 'dd-MM-yyyy'),
      },
      {
        key: EBankmutatieSelectieKolom.Bedrag,
        label: 'Bedrag',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 150,
        renderer: (x) => <FormatteerBedrag bedrag={x.Bedrag} />,
      },
      {
        key: EBankmutatieSelectieKolom.Geboekt,
        label: 'Geboekt',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 100,
        renderer: (x) => (x.Geboekt ? 'Ja' : 'Nee'),
      },
      {
        key: EBankmutatieSelectieKolom.Omschrijving,
        label: 'Omschrijving',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 300,
        renderer: (x) => (
          <div
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {x.Omschrijving}
          </div>
        ),
      },
    ],
    [],
  );

  return (
    <MultiComboboxV2
      provider={provider}
      enkeleProvider={enkeleProvider}
      keyExtractor={keyExtractor}
      waarde={props.bankMutID}
      onChange={props.onBankMutIDChange}
      representatieComponent={Representatie}
      kolommen={kolommen}
    />
  );
};

export default BankmutatieSelectie;
