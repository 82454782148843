import React from 'react';
import styled from 'styled-components';
import { Kleur } from '../../../../../../../bedrijfslogica/constanten';

const Root = styled.div`
  height: 100px;
  background-color: ${Kleur.HeelLichtGrijs};
  border: 2px solid ${Kleur.LichtGrijs};
  border-radius: 4px;
  transition: border 150ms ease-in-out, background-color 150ms ease-in-out;
  display: flex;
  flex-direction: column;
  cursor: pointer;

  &.geselecteerd {
    border: 2px solid ${Kleur.SelectieBlauw};
    background-color: #eaf2fa;
  }
`;

interface IProps {
  isGeselecteerd: boolean;
  onGeselecteerd: () => void;
}

const LijstItemBasis: React.FC<IProps> = (props) => {
  return (
    <Root
      className={props.isGeselecteerd ? 'geselecteerd' : ''}
      onClick={() => props.onGeselecteerd()}
    >
      {props.children}
    </Root>
  );
};

export default LijstItemBasis;
