import * as React from 'react';
import TegelBasis from '../../../../../../../components/tegel/TegelBasis';
import FormatteerBedrag from '../../../../../../../components/MutatieBedrag';
import { IOphalenInformatieVoorOverzichtFactuur } from '../../../../../../../../../shared/src/api/v2/relatie';
import { format } from 'date-fns';
import NavigatiePijl from '../NavigatiePijl';
import { genereerUrlStateQueryParam } from '../../../../../../../core/useUrlState';
import {
  IUrlState as IFacturenUrlState,
  defaultUrlState as defaultFacturenUrlState,
} from '../../Facturen/Overzicht';
import { RouteComponentProps, withRouter } from 'react-router';

interface IProps extends RouteComponentProps {
  relID: number;
  data: IOphalenInformatieVoorOverzichtFactuur;
}

const Factuurtegel = (props: IProps) => {
  const maxAantalWeerTeGeven = 10;
  const facturen = props.data.facturen.filter((x, i) => i < maxAantalWeerTeGeven);

  return (
    <TegelBasis titel="Facturen" subtitel={<span>Vervallen</span>}>
      <div>
        <table>
          <tbody>
            {facturen.map((factuur) => {
              return (
                <tr key={factuur.factID}>
                  <td style={{ width: 100 }}>
                    {format(new Date(factuur.factuurdatum), 'dd-MM-yyyy')}
                  </td>
                  <td style={{ width: 75 }}>{factuur.factuurnummer}</td>
                  <td style={{ width: 90 }}>
                    <FormatteerBedrag bedrag={Math.abs(factuur.openstaand)} />
                  </td>

                  <td>
                    <NavigatiePijl
                      relID={props.relID}
                      pathWithStateBuilder={() => {
                        const state = genereerUrlStateQueryParam<IFacturenUrlState>({
                          ...defaultFacturenUrlState,
                          selectie: [factuur.factID],
                        });
                        return `facturen/overzicht?state=${state}`;
                      }}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        {props.data.facturen.length > maxAantalWeerTeGeven && (
          <div className="mt-2">
            <a
              href="#"
              onClick={(ev) => {
                ev.preventDefault();

                props.history.push(`/klant/${props.relID}/facturen/overzicht`);
              }}
            >
              Er zijn nog {props.data.facturen.length - maxAantalWeerTeGeven}
              &nbsp;andere vervallen facturen...
            </a>
          </div>
        )}
      </div>
    </TegelBasis>
  );
};

export default withRouter(Factuurtegel);
