import * as Yup from 'yup';

export const checkValidatieSchema = <T extends {}>(
  validationSchema: Yup.ObjectSchema<any>,
  values: T,
): Partial<Record<keyof T, string>> => {
  try {
    validationSchema.validateSync(values);
  } catch (err) {
    return {
      [err.path as keyof T]: err.message as string,
    } as any;
  }

  return {};
};
