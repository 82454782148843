import { Redirect, Route, RouteComponentProps } from 'react-router';
import React from 'react';
import Feestdagen from './Feestdagen';
import TelefoonoproepContexten from './TelefoonoproepContexten';

interface IProps extends RouteComponentProps<{}> {}

const Stambeheer: React.FC<IProps> = (props) => {
  const { match } = props;
  return (
    <>
      <Route path={`${match.path}/feestdagen`} component={Feestdagen} />
      <Route path={`${match.path}/telefoonoproep-contexten`} component={TelefoonoproepContexten} />

      <Route exact path={match.path} render={() => <Redirect to={`${match.path}/feestdagen`} />} />
    </>
  );
};

export default Stambeheer;
