import React, { useContext, useMemo } from 'react';
import Tabblad, { ITabblad } from '../../../../../components/layout/Tabblad';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Dienst from './Dienst';
import { LeverancierContext } from '../../../index';

export enum ETabblad {
  // Smsoproepen,
  Dienst,
}

const pathMap: Record<ETabblad, string> = {
  // [ETabblad.Smsoproepen]: '/smsoproepen',
  [ETabblad.Dienst]: '/dienst',
};

interface IProps extends RouteComponentProps {}

const Emaildienst: React.FC<IProps> = (props) => {
  const { relID, emaildienst } = useContext(LeverancierContext);

  const tabblad = useMemo<ETabblad | null>(() => {
    const matchKey = Object.keys(pathMap).find((key) => {
      const path = pathMap[Number(key) as ETabblad]!;
      return props.location.pathname.endsWith(path);
    });
    if (matchKey === undefined) {
      const path1 = `/leverancier/${relID}/emaildienst${pathMap[ETabblad.Dienst]}`;
      props.history.push(path1);
      return null;
    }
    return Number(matchKey) as ETabblad;
  }, [props.location.pathname, relID]);

  const tabbladen = useMemo<ITabblad<ETabblad>[]>(
    () => [
      // {
      //   id: ETabblad.Smsoproepen,
      //   label: 'SMS-oproepen',
      //   content: Smsoproepen,
      // },
      {
        id: ETabblad.Dienst,
        label: 'Dienst',
        content: Dienst,
      },
    ],
    [],
  );

  return (
    <div className="d-flex flex-column flex-fill flex-fill">
      {tabblad !== null && (
        <Tabblad<ETabblad>
          geselecteerd={tabblad}
          onSelectieChange={(tab) => {
            props.history.push(
              `${props.location.pathname.replace(pathMap[tabblad], '')}${pathMap[tab]}`,
            );
          }}
          tabbladen={tabbladen}
        />
      )}
    </div>
  );
};

export default withRouter(Emaildienst);
