import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import IDialoogProps from '../../../../core/IDialoogProps';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import { RootStoreContext } from '../../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import api from '../../../../api';
import { EResultType } from '../../../../stores/CheckStore';
import * as Yup from 'yup';
import nameOf from '../../../../core/nameOf';
import { addMonths, format } from 'date-fns';
import { dagDatum } from '../../../../helpers/datum';
import {
  IOphalenBetaaldagenResult,
  OphalenBetaaldagenResultElement,
} from '../../../../../../shared/src/api/v2/debiteur';
import { IOphalenRelatiesResultElementV2 } from '../../../../../../shared/src/api/v2/relatie';

import LoadingSpinner from '../../../Gedeeld/LoadingSpinner';
import { achtergrondProcesAfwachten } from '../../../../core/achtergrondProces';
import { RealtimeContext } from '../../../../one-off-components/realtime/RealtimeIntegratie';
import { IMakenProlongatiesResult } from '../../../../../../shared/src/api/v2/prolongatie';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
  mapRemoteData,
} from '../../../../models/IRemoteData';
import MaandSelectie, { EMaand, EWeergave } from '../../../formulier/MaandSelectie';
import NumeriekVeld from '../../../formulier/NumeriekVeld';
import MultiComboboxV2, {
  EnkeleProvider,
  IRepresentatieProps,
  Provider,
} from '../../../formulier/MultiComboboxV2';
import { ASPKolom, EAspKolomBreedteType } from '../../../tabel/ASPTabel/types';
import {
  BepalenProlongatiegegevensMiddels,
  IBepalenEnVastleggenProlongatiesResult,
  IBepalenProlongatiegegevensMiddelsContracten,
  IBepalenProlongatiegegevensMiddelsRelatie,
} from '../../../../../../shared/src/api/v2/prolongatieV2';
import { Weergave } from '../../../FilterBalk/FilterItem/style';
import VeldWeergave from '../../../formulier/VeldWeergave';
import { EModus } from '../../../ASPGebruiker/ASPGebruikerVisualisatie';

interface IFormikValues {
  prolongatieDatum: Date | null;
  maand: EMaand;
  jaar: number;
  betaaldag: number;
}

export interface IRelatieModus {
  type: 'relatie';
  relID: number;
}

export interface IContractenModus {
  type: 'contracten';
  cntIDs: number[];
}

export interface IBetaaldagModus {
  type: 'betaaldag';
}

export type NieuweRunModus = IRelatieModus | IContractenModus | IBetaaldagModus;

interface IProps extends IDialoogProps<IBepalenEnVastleggenProlongatiesResult> {
  modus: NieuweRunModus;
}

const veldnamen = {
  prolongatieDatum: 'Prolongatiedatum',
  maand: 'Maand',
  jaar: 'Jaar',
  betaaldag: 'Voor betaaldag',
};

const NieuwDialoog = observer((props: IProps) => {
  const { onAnnuleren, onSuccess, open } = props;

  const { checkStore } = useContext(RootStoreContext);
  const realtimeContext = useContext(RealtimeContext);
  const formikRef = useRef<Formik<IFormikValues>>(null);

  const [betaaldagen, setBetaaldagen] = useState<IOphalenBetaaldagenResult | null>(null);
  const [relatie, setRelatie] = useState<IRemoteData<IOphalenRelatiesResultElementV2 | null>>(
    createPendingRemoteData(),
  );

  useEffect(() => {
    if (props.modus.type !== 'relatie') {
      setRelatie(createReadyRemoteData(null));
      return;
    }
    const relID = props.modus.relID;

    (async () => {
      const result = await api.v2.relatie.ophalenRelaties({
        filterSchema: { filters: [{ naam: 'IDS', data: [relID] }] },
      });
      const relatie = result.relaties[0];
      setRelatie(createReadyRemoteData(relatie));
    })();
  }, [props.modus]);

  useEffect(() => {
    (async () => {
      const result = await api.v2.debiteur.ophalenBetaaldagen({});
      setBetaaldagen(result);
    })();
  }, []);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      if (
        (
          await checkStore.bevestigen({
            inhoud: (
              <span>
                Prolongatierun maken?
                {props.modus.type === 'betaaldag' && (
                  <>
                    <br />
                    <br />
                    Relaties met betaaldag{' '}
                    <strong>{values.betaaldag !== -1 ? values.betaaldag : 'Stufi'}</strong>
                  </>
                )}
                <span>
                  <br />
                  <br />
                  Prolongatie vanaf datum{' '}
                  <strong>
                    {format(new Date(values.jaar, values.maand - 1, 1), 'dd-MM-yyyy')}
                  </strong>
                </span>
              </span>
            ),
          })
        ).type === EResultType.Annuleren
      ) {
        actions.setSubmitting(false);
        return;
      }

      const ingevuldeDatum = new Date(values.jaar, values.maand - 1, 1);
      const prolongatiedatum = dagDatum(addMonths(ingevuldeDatum, 0));

      let middels: BepalenProlongatiegegevensMiddels;
      switch (props.modus.type) {
        case 'relatie':
          middels = {
            type: 'relatie',
            relID: props.modus.relID,
          } as IBepalenProlongatiegegevensMiddelsRelatie;
          break;
        case 'contracten':
          middels = {
            type: 'contracten',
            cntIDs: props.modus.cntIDs,
          } as IBepalenProlongatiegegevensMiddelsContracten;
          break;
        case 'betaaldag':
          middels = {
            type: 'betaaldag',
            betaaldag: values.betaaldag,
          };
          break;
      }

      const achtergrondProces = await api.v2.prolongatieV2.bepalenEnVastleggenProlongatiesAchtergrondProces(
        {
          bepalenParams: {
            middels,
            prolongatiedatum,
          },
        },
      );

      // const achtergrondproces = await api.v2.prolongatie.makenProlongatieRunAchtergrondProces({
      //   prolongatiedatum: format(values.prolongatieDatum!, 'yyyy-MM-dd'),
      //   prolongatieJaar: values.jaar,
      //   prolongatieMaand: values.maand,
      //   relIDs: props.relID !== undefined ? [props.relID] : undefined,
      //   cntIDs: props.cntIDs,
      //   betaaldag: values.betaaldag,
      // });

      const result = await achtergrondProcesAfwachten<IBepalenEnVastleggenProlongatiesResult>(
        achtergrondProces.id,
        realtimeContext,
      );
      if (result.type === 'ERROR') {
        await checkStore.melden({
          titel: 'Er heeft een fout plaatsgevonden',
        });
        actions.setSubmitting(false);
        return;
      }
      if (result.type === 'TIMEOUT') {
        await checkStore.melden({
          titel: 'Het proces duurde te lang en is daarom afgebroken',
        });
        actions.setSubmitting(false);
        return;
      }

      const data = result.data!;
      if (!data.heeftProlongatiesVastgelegd) {
        await checkStore.melden({
          titel:
            'Er waren geen prolongaties bepaald voor de opgegeven periode, en er is dus niets vastgelegd.',
        });
        actions.setSubmitting(false);
        return;
      }

      onSuccess(result.data!);
      actions.setSubmitting(false);
    },
    [onSuccess, props.modus],
  );

  const initieleWaarden = useMemo<IRemoteData<IFormikValues>>(() => {
    return mapRemoteData(relatie, (relatie) => {
      const nu = new Date();
      const betaaldag = relatie?.debiteur?.Betaaldag ?? 1;
      const maandNu = new Date().getMonth() + 1;
      // TODO Dennis - Voorvullen afh maken van betaaldag
      let maandVoorvullen = (betaaldag !== 1 ? maandNu + 1 : maandNu) + 1;
      maandVoorvullen = maandVoorvullen > 12 ? 1 : maandVoorvullen;

      const maand = maandVoorvullen as EMaand;

      const jaar =
        nu.getMonth() + 1 === 12 && nu.getDate() > 26 ? nu.getFullYear() + 1 : nu.getFullYear();

      return {
        prolongatieDatum: dagDatum(new Date()),
        maand,
        jaar,
        betaaldag,
      };
    });
  }, [relatie]);

  const validatieschema = Yup.object().shape({
    prolongatieDatum: Yup.date().required(),
  });

  type BetaaldagSelectieKolom = 'betaaldag';
  const enkeleProvider = useCallback<EnkeleProvider<number, OphalenBetaaldagenResultElement>>(
    async (nummer) => {
      return nummer;
    },
    [],
  );
  const provider = useCallback<Provider<BetaaldagSelectieKolom, OphalenBetaaldagenResultElement>>(
    async (params) => {
      const betaaldagen = await api.v2.debiteur.ophalenBetaaldagen({});
      const items = betaaldagen.reduce((acc, betaaldag, i) => {
        acc[i] = betaaldag;
        return acc;
      }, params.huidigeBron);
      const totaalAantal = betaaldagen.length;

      return {
        items,
        totaalAantal,
      };
    },
    [],
  );
  const betaaldagKolommen = useMemo<
    ASPKolom<BetaaldagSelectieKolom, OphalenBetaaldagenResultElement>[]
  >(
    () => [
      {
        key: 'betaaldag',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 100,
        renderer: (item) => (item === -1 ? 'Stufi-dag' : item),
      },
    ],
    [],
  );

  return (
    <Modal show={open}>
      <ModalHeader>
        <ModalTitle>Nieuwe prolongatierun</ModalTitle>
      </ModalHeader>
      {initieleWaarden.state === ERemoteDataState.Pending ? (
        <>
          <ModalBody className="p-3 d-flex align-items-center justify-content-center">
            <LoadingSpinner />
          </ModalBody>
          <ModalFooter className="d-flex justify-content-start">
            <button className="btn btn-secondary" onClick={onAnnuleren} style={{ width: 100 }}>
              Annuleren
            </button>
          </ModalFooter>
        </>
      ) : (
        <Formik<IFormikValues>
          ref={formikRef}
          isInitialValid
          enableReinitialize
          onSubmit={handleSubmit}
          initialValues={initieleWaarden.data!}
          validationSchema={validatieschema}
          validateOnChange
          validateOnBlur
          render={(formikProps: FormikProps<IFormikValues>) => {
            const { submitForm, isSubmitting, values, isValid } = formikProps;

            return (
              <>
                <ModalBody>
                  <div className="form-group">
                    {props.modus.type === 'betaaldag' && (
                      <div className="row">
                        <div className="col-4">
                          <label>{veldnamen.betaaldag}</label>
                          <Field
                            name={nameOf<IFormikValues>('betaaldag')}
                            render={(fieldProps: FieldProps<IFormikValues>) => {
                              const { field, form } = fieldProps;

                              if (betaaldagen === null) {
                                return <LoadingSpinner />;
                              }

                              return (
                                <>
                                  <MultiComboboxV2<
                                    number,
                                    BetaaldagSelectieKolom,
                                    OphalenBetaaldagenResultElement,
                                    null
                                  >
                                    disabled={isSubmitting}
                                    onChange={(x) => form.setFieldValue(field.name, x)}
                                    waarde={field.value}
                                    keyExtractor={(x: OphalenBetaaldagenResultElement) => x}
                                    enkeleProvider={enkeleProvider}
                                    provider={provider}
                                    representatieComponent={BetaaldagRepresentatie}
                                    kolommen={betaaldagKolommen}
                                  />
                                </>
                              );
                            }}
                          />
                        </div>

                        {/* <div className="col-12 mt-3">
                      <label>{veldnamen.prolongatieDatum}</label>
                      <Field
                        name={nameof('prolongatieDatum')}
                        render={({ field, form }: FieldProps<IFormikValues>) => {
                          return (
                            <div className="d-flex align-items-center">
                              <DatumKiezer
                                onGewijzigd={(x) => {
                                  form.setFieldValue(field.name, x);
                                  setProlongatieDatum(x);
                                }}
                                waarde={field.value}
                                determineValidDate={(date) => {
                                  return (
                                    date >= addDays(new Date(), -60) &&
                                    date < addDays(new Date(), 65)
                                  );
                                }}
                              />
                            </div>
                          );
                        }}
                      />
                    </div> */}
                      </div>
                    )}

                    <div className="row">
                      <div className="col-4 mt-3">
                        <label>{veldnamen.maand}</label>
                        <Field
                          name={nameOf<IFormikValues>('maand')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            return (
                              <MaandSelectie
                                maand={field.value}
                                onMaandChange={(x) => form.setFieldValue(field.name, x)}
                                weergave={EWeergave.Tekst}
                                disabled={isSubmitting}
                              />
                            );
                          }}
                        />
                      </div>

                      <div className="col-4 mt-3">
                        <label>{veldnamen.jaar}</label>
                        <Field
                          name={nameOf<IFormikValues>('jaar')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            return (
                              <NumeriekVeld
                                waarde={field.value}
                                onChange={(x) => form.setFieldValue(field.name, x)}
                                min={new Date().getFullYear() - 1}
                                disabled={isSubmitting}
                              />
                            );
                          }}
                        />
                      </div>
                    </div>
                    <div className="mt-3">
                      <VeldWeergave>
                        {props.modus.type === 'relatie' ? (
                          <span>
                            Voor de betreffende relatie(s).
                            <br />
                            <br />
                          </span>
                        ) : props.modus.type === 'contracten' ? (
                          <span>
                            Voor de geselecteerde contracten.
                            <br />
                            <br />
                          </span>
                        ) : props.modus.type === 'betaaldag' ? (
                          <span>
                            Voor de opgegeven betaaldag.
                            <br />
                            <br />
                          </span>
                        ) : (
                          <span></span>
                        )}
                        De prolongatie wordt toegepast op de contracten met een datum Geprolongeerd
                        tot de 1e van de hier geselecteerde maand.
                      </VeldWeergave>
                    </div>
                  </div>
                </ModalBody>
                <ModalFooter className="d-flex justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    disabled={isSubmitting || !isValid}
                    style={{ width: 100 }}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={onAnnuleren}
                    disabled={isSubmitting}
                    style={{ width: 100 }}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Modal>
  );
});

const BetaaldagRepresentatie = (props: IRepresentatieProps<OphalenBetaaldagenResultElement>) => {
  return <span>{props.entiteit === -1 ? 'Stufi-dag' : props.entiteit}</span>;
};

export default NieuwDialoog;
