import React, { useCallback, useContext, useMemo, useState } from 'react';
import styled from 'styled-components';
import {
  IContract,
  IContractStatus,
  IProductmodel,
  IProductmodelAfbeelding,
} from '../../../../../../../../../../../../shared/src/api/v2/relatie/contract';
import Tegel from '../../../../../../../../../../components/tegel/Tegel';
import StatusLabel from '../../../../Overzicht/ContractTegel/StatusLabel';
import {
  contractstatusKleurMap,
  EContractStatus,
} from '../../../../Overzicht/ContractTegel/ContractStatus';
import {
  EContractstatus,
  EProductstatus,
  ERegelstatusTransport,
  ETransportopdrachtRegelsoort,
} from '../../../../../../../../../../bedrijfslogica/enums';
import { LocatieTabelContext } from '../../index';
import TegelSelectie from '../../../../../../../../../../components/tegel/TegelSelectie';
import { IContractTegelState, SectieId, useOverzichtV2Store } from '../../../store';
import GegevensLayout from '../../../../../../../../../../components/layout/GegevensLayout';
import { format } from 'date-fns';
import { Kleur, Kleur as EKleur } from '../../../../../../../../../../bedrijfslogica/constanten';
import {
  EFunctioneleIcon,
  functioneleIconMap,
  IconDownload,
  IconInformatie,
  IconTerugdraaien,
  IconUitBedrijf,
  IconWijzigen,
} from '../../../../../../../../../../components/Icons';
import { maandenNaarJaren } from '../../../../../../../../../../bedrijfslogica/teksten';
import ActieMenuKnop from '../../../../../../../../../../components/ActieMenuKnop';
import api from '../../../../../../../../../../api';
import downloadUrl from '../../../../../../../../../../core/downloadUrl';
import LoadingSpinner from '../../../../../../../../../../components/Gedeeld/LoadingSpinner';
import { EResultType } from '../../../../../../../../../../stores/CheckStore';
import { prolongatieRichting } from '../../../../Overzicht/ContractTegel';
import { GlobaleRendererContext } from '../../../../../../../../../../one-off-components/GlobaleRenderer';
import ProductmodelInfoDialoog from '../../../../../../../../../../components/productmodel/ProductmodelInfoDialoog';
import { formatteerBedrag } from '../../../../../../../../../../helpers';
import VoorraadTypeInfoDialoog from '../../../../../../../../../../components/transport/VoorraadTypeInfoDialoog';
import { RootStoreContext } from '../../../../../../../../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import ProductinfoDialoog from '../../../../../../../../../../components/product/ProductinfoDialoog';
import WijzigenProductDialoog from '../../../../../../../../../Magazijn/Depot/WijzigenProductDialoog';
import VersturenDocumentatieDialoog from '../../../../../../../../../../components/product/VersturenDocumentatieDialoog';
import ServiceMeldingDialoog from '../../../../../Service/Meldingen/NieuweMeldingDialoog';
import NieuweOpdrachtDialoog from '../../../../../../../../../../components/transport/NieuweOpdrachtDialoog';
import TransportopdrachtregelVisualisatie from '../../../../../../../../../../components/entiteitVisualisaties/TransportopdrachtregelVisualisatie';
import ServicemeldingVisualisatie from '../../../../../../../../../../components/entiteitVisualisaties/ServicemeldingVisualisatie';
import FormatteerBedrag from '../../../../../../../../../../components/MutatieBedrag';
import RelatieVisualisatie from '../../../../../../../../../../components/personalia/RelatieVisualisatie';
import ContractVisualisatie from '../../../../../../../../../../components/entiteitVisualisaties/ContractVisualisatie';
import WijzigenContractDialoog from '../../../../Overzicht/WijzigenContractDialoog';
import NieuwContractDialoogV2 from '../../../../Overzicht/NieuwContractDialoogV2';
import NieuweVorderingDialoog from '../../../../../../../../../Facturering/Vorderingen/NieuweVorderingDialoog';
import KopierenContractDialoog from '../../../../Overzicht/KopierenContractDialoog';
import ProductSelectieDialoog from '../../../../../../../../../../components/product/ProductSelectieDialoog';
import UitBedrijfDialoog from '../../../../Overzicht/ContractTegel/Product/UitBedrijfDialoog';
import TegelUitklapMenu from '../../../../../../../../../../components/tegel/TegelUitklapMenu';
import DetailsSectie from './DetailsSectie';
import { IDataBijID } from '../../../index';
import * as _ from 'lodash';
import ProductSectie from './ProductSectie';
import ServiceSectie from './ServiceSectie';
import TransportSectie from './TransportSectie';
import FinancieelSectie from './FinancieelSectie';
import TarievenSectie from './TarievenSectie';
import { dagDatum } from '../../../../../../../../../../helpers/datum';
import { handleTransportopdrachtenMaken } from '../../../../Overzicht';

export interface ISectieProps {
  contract: IContract;
  dataBijID: IDataBijID;
  vernieuwenContracten: () => Promise<void>;
}

const IconVordering = functioneleIconMap[EFunctioneleIcon.Vordering];
const IconKopieren = functioneleIconMap[EFunctioneleIcon.kopieeren];
const IconContractwissel = functioneleIconMap[EFunctioneleIcon.Contractwissel];

const Root = styled.div`
  background-color: white;
`;

interface IProps {
  contract: IContract;
  vernieuwenContracten: () => Promise<void>;
}

const ContractTegel = observer(
  (props: IProps): JSX.Element => {
    const store = useOverzichtV2Store();
    const { checkStore, klantkaartStore } = useContext(RootStoreContext);
    const globaleRenderer = useContext(GlobaleRendererContext);
    const { dataBijID } = useContext(LocatieTabelContext)!;

    const contract = props.contract;
    const contractStatus = useMemo<IContractStatus>(() => {
      return dataBijID.contractStatussenBijID[contract.CntStatID];
    }, [contract.CntStatID, dataBijID]);
    const productmodel = useMemo<IProductmodel>(() => {
      return dataBijID.productmodellenBijID[contract.ProdModID];
    }, [contract.ProdModID, dataBijID]);
    const productmodelAfbeeldingen = useMemo<IProductmodelAfbeelding[]>(() => {
      return dataBijID.productmodelAfbeeldingenBijProdModID[contract.ProdModID] ?? [];
    }, [contract.ProdModID, dataBijID]);
    // const prolongaties = useMemo(() => {
    //   return dataBijID.prolongatiesBijCntID[contract.CntID] ?? [];
    // }, [contract.CntID, dataBijID]);
    const producten = useMemo(() => {
      return dataBijID.productenBijCntBasisID[contract.CntBasisID] ?? [];
    }, [contract.CntBasisID, dataBijID]);
    const productmodelAfbeelding = useMemo<IProductmodelAfbeelding | null>(() => {
      return productmodelAfbeeldingen.length > 0 ? productmodelAfbeeldingen[0] : null;
    }, [productmodelAfbeeldingen]);
    const producttypes = useMemo(() => {
      const prodModXTypes = dataBijID.prodModXTypesBijProdModID[contract.ProdModID] ?? [];
      prodModXTypes.sort((a, b) => a.SortNr - b.SortNr);
      return prodModXTypes.map((prodModXType) => dataBijID.productTypesBijID[prodModXType.TypeID]);
    }, [contract.ProdModID, dataBijID]);

    const isGeselecteerd = useMemo(() => store.selectieCntIDs.indexOf(contract.CntID) !== -1, [
      contract.CntID,
      store.selectieCntIDs,
    ]);
    const handleIsGeselecteerdChange = useCallback(
      (geselecteerd: boolean) => {
        store.setSelectieCntIDs((huidigeSelectie: number[]) => {
          if (geselecteerd) {
            return [...huidigeSelectie, props.contract.CntID];
          } else {
            return huidigeSelectie.filter((id) => id !== props.contract.CntID);
          }
        });
      },
      [props.contract.CntID, store.setSelectieCntIDs],
    );

    const [bezigMetContractDownloaden, setBezigMetContractDownloaden] = useState(false);

    const prolongatieTeken = useMemo(() => {
      return prolongatieRichting({
        Start: contract.ProlongatieStart,
        Eind: contract.ProlongatieEind,
        Tot: contract.GeprolongeerdTot,
      });
    }, [contract.ProlongatieStart, contract.ProlongatieEind, contract.GeprolongeerdTot]);

    const maandhuur = useMemo(() => {
      let indicatie: string = '';
      let maandhuur: string = '';

      const actueleHuur =
        dataBijID.actueelContractbasisHuurBijCntBasisID[contract.CntBasisID] ?? null;
      if (actueleHuur === null) {
        return '?';
      }

      indicatie += contract.gebruikerstoeslag !== 0 ? 'T,' : '';

      if (contract.CntActieID === null) {
        indicatie =
          contract.gebruikerstoeslag !== 0
            ? ' T: ' + formatteerBedrag(contract.gebruikerstoeslag)
            : '';
        maandhuur =
          formatteerBedrag(actueleHuur.Maandhuur + contract.gebruikerstoeslag) + indicatie;
      } else {
        if (contract.datumTotActie === null) {
          indicatie =
            ' A' +
            (contract.gebruikerstoeslag !== 0
              ? ' T: ' + formatteerBedrag(contract.gebruikerstoeslag)
              : '');
          maandhuur = formatteerBedrag(actueleHuur.Maandhuur) + indicatie;
        } else {
          if (new Date(contract.datumTotActie).getTime() > new Date().getTime()) {
            indicatie =
              ' (A)' +
              (contract.gebruikerstoeslag !== 0
                ? ' T: ' + formatteerBedrag(contract.gebruikerstoeslag)
                : '');
            maandhuur = formatteerBedrag(actueleHuur.Maandhuur) + indicatie;
          } else {
            indicatie =
              contract.gebruikerstoeslag !== 0
                ? ' T: ' + formatteerBedrag(contract.gebruikerstoeslag)
                : '';
            maandhuur =
              formatteerBedrag(actueleHuur.Maandhuur + contract.gebruikerstoeslag) + indicatie;
          }
        }
      }

      return maandhuur;
    }, [
      contract.gebruikerstoeslag,
      contract.CntActieID,
      contract.MaandhuurActie,
      contract.datumTotActie,
      contract.CntBasisID,
      dataBijID.actueelContractbasisHuurBijCntBasisID,
    ]);

    const verhuurdeProducten = useMemo(() => {
      // Is er een Verhuurd (geplaatst) product gekoppeld?
      return producten.filter((x) => {
        const productStatus = dataBijID.productStatiBijID[x.ProdStatID];
        return productStatus.NaamEnum === EProductstatus.Verhuur;
      });
    }, [producten, dataBijID]);

    const productElement = useMemo(() => {
      // Geeft het geplaatste product weer indien aanwezig
      if (verhuurdeProducten.length === 0 || verhuurdeProducten.length > 1) {
        // return <span>Geen gekoppelde product</span>;
        return <span></span>;
      }
      const product = verhuurdeProducten[0];
      return (
        <div className="d-flex flex-column flex-fill">
          <GegevensLayout
            gegevens={[
              {
                label: 'Ref. code',
                waarde: product.Referentiecode ?? 'Geen',
              },
              {
                label: 'Leeftijd',
                waarde: product.leeftijd !== null ? maandenNaarJaren(product.leeftijd) : 'Onbekend',
              },
              null,
              // {
              //   label: 'Gebruikers',
              //   waarde: contract.basis.AantalGebruikers.toString(),
              // },
            ]}
            labelBreedte={60}
          />
          <div className="flex-fill" />
          <div className="d-flex align-items-center" style={{ marginTop: 2 }}>
            <a
              href="#"
              onClick={async (ev) => {
                ev.preventDefault();

                await globaleRenderer.render((renderProps) => (
                  <ProductinfoDialoog
                    id={product.ProdID}
                    open
                    onSuccess={() => renderProps.destroy()}
                    onAnnuleren={() => renderProps.destroy()}
                  />
                ));
              }}
              className="mr-1"
            >
              Info
            </a>
            <span>&ndash;</span>
            <a
              href="#"
              onClick={async (ev) => {
                ev.preventDefault();

                await globaleRenderer.render((renderProps) => (
                  <WijzigenProductDialoog
                    open
                    prodID={product.ProdID}
                    onSuccess={async () => {
                      await props.vernieuwenContracten();
                      renderProps.destroy();
                    }}
                    onAnnuleren={() => renderProps.destroy()}
                  />
                ));
              }}
              className="ml-1 mr-1"
            >
              Wijz.
            </a>
            <span>&ndash;</span>
            <a
              href="#"
              className="ml-1 mr-1"
              onClick={async (ev) => {
                ev.preventDefault();

                await globaleRenderer.render((renderProps) => (
                  <VersturenDocumentatieDialoog
                    relID={contract.RelID}
                    prodID={product.ProdID}
                    open
                    onSuccess={() => renderProps.destroy()}
                    onAnnuleren={() => renderProps.destroy()}
                  />
                ));
              }}
            >
              Doc.
            </a>
            <span>&ndash;</span>
            <a
              href="#"
              className="ml-1 mr-1"
              onClick={async (ev) => {
                ev.preventDefault();

                await globaleRenderer.render((renderProps) => (
                  <ServiceMeldingDialoog
                    open
                    prodID={product.ProdID}
                    cntID={contract.CntID}
                    relID={contract.RelID}
                    // locID={servicemeldingDialoogState.locID}
                    onSuccess={async () => {
                      await props.vernieuwenContracten();
                      renderProps.destroy();
                    }}
                    onAnnuleren={() => renderProps.destroy()}
                  />
                ));
              }}
            >
              Melding
            </a>
            <span>&ndash;</span>
            <a
              href="#"
              className="ml-1"
              onClick={async (ev) => {
                ev.preventDefault();

                await globaleRenderer.render((renderProps) => (
                  <NieuweOpdrachtDialoog
                    open
                    relID={contract.RelID}
                    contractID={contract.CntID}
                    // soortOpdracht={2}
                    leverProduct={null}
                    onSuccess={async () => {
                      await props.vernieuwenContracten();
                      renderProps.destroy();
                    }}
                    onAnnuleren={() => renderProps.destroy()}
                  />
                ));
              }}
            >
              Omruil
            </a>
          </div>
        </div>
      );
    }, [verhuurdeProducten, contract]);

    const ContractIndicaties = () => {
      const producten = dataBijID.productenBijCntBasisID[contract.CntBasisID] ?? [];
      const product = producten.length === 1 ? producten[0] : null;

      // Niet afgemelde regels die aan het contract gekoppeld zijn
      const opdrachtregels = dataBijID.transportOpdrachtRegelsBijCntID[contract.CntID] ?? [];
      const nietAfgemeldeOpdrachtregels = opdrachtregels.filter(
        (x) => x.Status === ERegelstatusTransport.NIET_AFGEMELD,
      );
      const leveropdrachten = nietAfgemeldeOpdrachtregels.filter((reg) => {
        const regelSoort = dataBijID.transportRegelSoortenBijID[reg.TrsRegSrtID];
        return regelSoort.NaamEnum === ETransportopdrachtRegelsoort.Levering;
      });
      const retouropdrachten = nietAfgemeldeOpdrachtregels.filter((reg) => {
        const regelSoort = dataBijID.transportRegelSoortenBijID[reg.TrsRegSrtID];
        return regelSoort.NaamEnum === ETransportopdrachtRegelsoort.Retour;
      });
      const contractStatus = dataBijID.contractStatussenBijID[contract.CntStatID];
      const contractHeeftProductenMetZeldeBasis = producten.some(
        (x) => x.CntBasisID === contract.CntBasisID,
      );

      // TODO: Gilbert, contrleren of deze cnt basis conditie benodigd is
      // const leveropdrachtMaken =
      //   !contract.producten.some((x) => x.CntBasisID === contract.basis.CntBasisID) &&
      //   contract.status.NaamEnum === EContractStatus.Lopend &&
      //   !transportopdrachtregels.some(
      //     (x) =>
      //       x.contract!.basis.CntBasisID === contract.basis.CntBasisID &&
      //       x.soort.Code === 'L' &&
      //       // x.OmruilID === null &&
      //       x.status.Status === 0, //ERegelstatus.NIET_AFGEMELD,
      //   );

      // const leveropdrachtMaken =
      //   !contractHeeftProductenMetZeldeBasis &&
      //   contractStatus.NaamEnum === EContractStatus.Lopend &&
      //   !nietAfgemeldeOpdrachtregels.some((reg) => {
      //     const regelSoort = dataBijID.transportRegelSoortenBijID[reg.TrsRegSrtID];
      //     return regelSoort.Code === 'L' && reg.Status === ERegelstatusTransport.NIET_AFGEMELD;
      //   });

      const serviceMeldingen = dataBijID.serviceMeldingenBijCntID[contract.CntID] ?? [];
      const meestRecenteServicemelding = serviceMeldingen.find((x) => !x.Afgehandeld) ?? null;

      const verkoopVoorstellen = dataBijID.verkoopVoorstellenBijCntID[contract.CntID] ?? [];
      const verkoopVoorstel = verkoopVoorstellen.length === 0 ? null : verkoopVoorstellen[0];

      const vorigContract =
        contract.vorig_CntID === null
          ? null
          : dataBijID.contractenBijID[contract.vorig_CntID] ?? null;
      const volgendContract =
        contract.volgend_CntID === null
          ? null
          : dataBijID.contractenBijID[contract.volgend_CntID] ?? null;

      const vorigContractStatus =
        vorigContract === null ? null : dataBijID.contractStatussenBijID[vorigContract.CntStatID];
      const volgendContractStatus =
        volgendContract === null
          ? null
          : dataBijID.contractStatussenBijID[volgendContract.CntStatID];

      const interneLocatie =
        contract.LocIntID === null
          ? null
          : dataBijID.locatiesInternBijID[contract.LocIntID] ?? null;

      return (
        <div className="d-flex flex-column">
          <ul className="p-0">
            {contract.Verdieping !== null && (
              <li>
                {contract.Verdieping === 0
                  ? 'Begane grond'
                  : `Verdieping ${contract.Verdieping !== 99 ? ` ${contract.Verdieping}e` : ''}`}
              </li>
            )}
            {interneLocatie !== null && <li>Int. Loc.: {interneLocatie.Naam}</li>}
            {leveropdrachten.length !== 0 && (
              <li>
                <span className="d-flex">
                  {leveropdrachten.length === 1 ? (
                    <span className="d-flex">
                      Leveropdr.&nbsp;&nbsp;
                      <TransportopdrachtregelVisualisatie trsRegID={leveropdrachten[0].TrsRegID} />
                    </span>
                  ) : (
                    'Leveropdrachten'
                  )}
                </span>
              </li>
            )}
            {retouropdrachten.length !== 0 && (
              <li>
                <span className="d-flex">
                  {retouropdrachten.length === 1 ? (
                    <span className="d-flex">
                      Retouropdr.&nbsp;&nbsp;
                      <TransportopdrachtregelVisualisatie trsRegID={retouropdrachten[0].TrsRegID} />
                    </span>
                  ) : (
                    'Retouropdrachten'
                  )}
                </span>
              </li>
            )}
            {verkoopVoorstel !== null &&
              verkoopVoorstel.Status === 0 &&
              dagDatum(new Date(verkoopVoorstel.GeldigTot)) >= dagDatum(new Date()) && (
                <li>
                  <span className="d-flex">
                    <span>
                      Lop. verk.vrst. (t/m {format(new Date(verkoopVoorstel.GeldigTot), 'dd-MM')})
                    </span>
                  </span>
                </li>
              )}

            {contract.AccID === null &&
              contract.DatumBevestigd === null &&
              contractStatus.NaamEnum === EContractStatus.Concept && (
                <li style={{ color: EKleur.Rood }}>Wacht op bevestiging</li>
              )}

            {meestRecenteServicemelding !== null && (
              <>
                <li>
                  <span className="d-flex">
                    <ServicemeldingVisualisatie servMeldID={meestRecenteServicemelding.ID} />
                  </span>
                </li>
              </>
            )}

            {contract.KostenVtb !== null && contract.KostenVtb !== 0 && (
              <li>
                <span className="d-flex">
                  <span>Kosten VTB </span> <FormatteerBedrag bedrag={contract.KostenVtb} />
                </span>
              </li>
            )}
            {contract.AangebodenOvernameOp !== null && <li>Aangeboden ter overname</li>}
            {contract.AangebodenOvernameOp !== null && volgendContract !== null && (
              <li>
                Overnameverzoek door:&nbsp;
                <RelatieVisualisatie
                  relID={volgendContract.RelID}
                  options={{
                    geenLinkToepassen: false,
                  }}
                />
              </li>
            )}

            {vorigContract !== null &&
              contractStatus.NaamEnum === EContractStatus.Concept &&
              contract.Volgnummer > 0 && (
                <li>
                  In overname van:&nbsp;
                  <RelatieVisualisatie
                    relID={vorigContract.RelID}
                    options={{
                      geenLinkToepassen: false,
                    }}
                  />
                </li>
              )}

            {contract.Contractwissel_CntID !== null && (
              <li>
                <span className="d-flex" title="Contractwissel">
                  Wis Voor&nbsp;
                  <ContractVisualisatie
                    cntID={contract.Contractwissel_CntID}
                    onClick={() =>
                      store.setSelectieCntIDs((curr: number[]) =>
                        _.uniq([...curr, contract.Contractwissel_CntID!]),
                      )
                    }
                  />
                </span>
              </li>
            )}

            {dataBijID.contractBijContractwisselCntID[contract.CntID] !== undefined && (
              <li>
                <span className="d-flex" title="Contractwissel">
                  Wis Door&nbsp;
                  <ContractVisualisatie
                    cntID={dataBijID.contractBijContractwisselCntID[contract.CntID].CntID}
                    onClick={() =>
                      store.setSelectieCntIDs((curr: number[]) =>
                        _.uniq([
                          ...curr,
                          dataBijID.contractBijContractwisselCntID[contract.CntID].CntID,
                        ]),
                      )
                    }
                  />
                </span>
              </li>
            )}

            {volgendContract !== null && (
              <li>
                <span className="d-flex" title="Overgenomen door">
                  Ovn Door&nbsp;
                  <ContractVisualisatie cntID={volgendContract.CntID} />
                </span>
              </li>
            )}

            {vorigContract !== null && (
              <li>
                <span className="d-flex" title="Overgenomen van">
                  Ovn Voor&nbsp;
                  <ContractVisualisatie cntID={vorigContract.CntID} />
                </span>
              </li>
            )}

            {/*{contract.Contractwissel_CntID !== null && contract.Volgnummer === 0 && (*/}
            {/*  <li>*/}
            {/*    <span className="d-flex">*/}
            {/*      Wissel&nbsp;Voor&nbsp;*/}
            {/*      <ContractVisualisatie cntID={contract.Contractwissel_CntID} />*/}
            {/*    </span>*/}
            {/*  </li>*/}
            {/*)}*/}

            {contract.InterneMutatie &&
              (contractStatus.NaamEnum === EContractStatus.Beeindigd ? (
                <li>Interne beeindiging</li>
              ) : contractStatus.NaamEnum === EContractStatus.Geannuleerd ? (
                <li>Interne Annulering</li>
              ) : (
                <li>Interne mutatie</li>
              ))}

            {product !== null && product.LocID !== contract.LocID && <li>Afw. locatie product</li>}

            {contract.RelRekID !== null && <li>Afwijkende rekening</li>}

            {contract.FactKenID !== null && (
              <li>
                Factuurkenmerk
                <span className="ml-2 font-weight-bold">
                  {dataBijID.factuurKenmerkenBijID[contract.FactKenID].Kenmerk}
                </span>
              </li>
            )}
          </ul>
        </div>
      );
    };

    const handleWijzigenClick = useCallback(
      async (ev: React.MouseEvent) => {
        ev.preventDefault();

        await globaleRenderer.render((renderProps) => (
          <WijzigenContractDialoog
            open
            relID={contract.RelID}
            cntID={contract.CntID}
            onSuccess={async () => {
              await Promise.all([
                props.vernieuwenContracten(),
                klantkaartStore.ophalenRelatie(contract.RelID),
              ]);
              renderProps.destroy();
            }}
            onAnnuleren={() => renderProps.destroy()}
          />
        ));
      },
      [contract, props.vernieuwenContracten],
    );

    const SectieComp = useMemo<React.ComponentType<ISectieProps> | null>(() => {
      const tegelState = store.contractTegelState[contract.CntID];
      if (tegelState === undefined || tegelState.uitgeklapteSectieId === null) {
        return null;
      }
      switch (tegelState.uitgeklapteSectieId) {
        case 'details':
          return DetailsSectie;
        case 'product':
          return ProductSectie;
        case 'service':
          return ServiceSectie;
        case 'transport':
          return TransportSectie;
        case 'financieel':
          return FinancieelSectie;
        case 'tarieven':
          return TarievenSectie;
      }
      return null;
    }, [store.contractTegelState[contract.CntID]]);

    return (
      <Root>
        <Tegel
          rootProps={{
            style: {
              paddingLeft: 0,
              paddingTop: 0,
              paddingBottom: 0,
            },
          }}
        >
          <div className="d-flex">
            <StatusLabel
              naam={contractStatus.Naam.toUpperCase()}
              kleur={contractstatusKleurMap[contractStatus.NaamEnum as EContractstatus]}
            />

            <div className="flex-fill d-flex" style={{ padding: 10 }}>
              <TegelSelectie
                geselecteerd={isGeselecteerd}
                onGeselecteerdChange={handleIsGeselecteerdChange}
              />

              <div className="flex-fill">
                <div className="d-flex align-items-start">
                  <div
                    className="d-flex flex-column justify-content-center"
                    style={{
                      height: '100%',
                      minWidth: 80,
                    }}
                  >
                    {productmodelAfbeelding !== null ? (
                      <img
                        style={{
                          objectFit: 'contain',
                          objectPosition: 'left',
                          width: 60,
                          height: 80,
                          marginLeft: 5,
                        }}
                        src={productmodelAfbeelding.url}
                        alt="Afbeelding niet in te laden"
                      />
                    ) : (
                      <div
                        style={{
                          width: 60,
                          height: 80,
                          marginLeft: 5,
                        }}
                        className="d-flex align-items-center justify-content-center"
                      >
                        <span className="text-muted text-center" style={{ fontSize: 11 }}>
                          Geen afbeelding beschikbaar
                        </span>
                      </div>
                    )}
                  </div>

                  <div className="d-flex flex-fill pb-1">
                    <div className="d-flex flex-column">
                      <div className="font-weight-bold d-flex justify-content-between align-items-center">
                        <div className="d-flex justify-content-between align-items-center">
                          <div>
                            <span hidden>CntID: {contract.CntID}</span>
                            <span>
                              {contract.Basisnummer}.{contract.Volgnummer} {productmodel.Modelnaam}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div
                        className="d-flex"
                        style={{
                          paddingTop: `2px`,
                          width: `100%`,
                        }}
                      >
                        <div style={{ width: 200 }}>
                          <GegevensLayout
                            gegevens={[
                              {
                                label: 'Basisdatum',
                                waarde: format(new Date(contract.IngangsdatumBasis), 'dd-MM-yyyy'),
                              },
                              {
                                label: 'Ingangsdatum',
                                waarde: format(new Date(contract.Ingangsdatum), 'dd-MM-yyyy'),
                              },
                              {
                                label: 'Einddatum',
                                waarde:
                                  contract.Einddatum === null ? (
                                    new Date(contract.minimaleEinddatum) > new Date() ? (
                                      <span
                                        style={{
                                          color: EKleur.LichtGrijs,
                                        }}
                                      >
                                        {format(new Date(contract.minimaleEinddatum), 'dd-MM-yyyy')}
                                      </span>
                                    ) : (
                                      <span
                                        style={{
                                          color: EKleur.Grijs,
                                        }}
                                      >
                                        Opzegbaar
                                      </span>
                                    )
                                  ) : (
                                    <span>
                                      {format(new Date(contract.Einddatum!), 'dd-MM-yyyy')}
                                    </span>
                                  ),
                              },
                              {
                                label: 'Geprol. tot',
                                waarde: (
                                  <span className="d-flex align-items-center justify-content-center">
                                    {contract.ProlongatieOphouden ? (
                                      <span
                                        style={{
                                          color: Kleur.Rood,
                                        }}
                                      >
                                        Opgehouden
                                      </span>
                                    ) : contract.GeprolongeerdTot !== null ? (
                                      format(new Date(contract.GeprolongeerdTot), 'dd-MM-yyyy')
                                    ) : (
                                      '-'
                                    )}
                                    <span className="ml-1">{prolongatieTeken}</span>
                                  </span>
                                ),
                              },
                            ]}
                          />
                        </div>

                        <div className="ml-2" style={{ width: 185 }}>
                          <GegevensLayout
                            gegevens={[
                              {
                                label: 'Model',
                                waarde: (
                                  <div>
                                    <span
                                      style={{
                                        color: !productmodel.Actief ? EKleur.DonkerRood : undefined,
                                      }}
                                    >
                                      {productmodel.Modelcode}
                                    </span>
                                    <a
                                      href="#"
                                      className="ml-1"
                                      style={{
                                        color: Kleur.LichtBlauw,
                                        position: 'relative',
                                        bottom: 2,
                                      }}
                                      onClick={async () => {
                                        await globaleRenderer.render((renderProps) => (
                                          <ProductmodelInfoDialoog
                                            prodModID={productmodel.ProdModID}
                                            open
                                            onSuccess={() => renderProps.destroy()}
                                            onAnnuleren={() => renderProps.destroy()}
                                          />
                                        ));
                                      }}
                                    >
                                      <IconInformatie
                                        style={{
                                          width: 15,
                                          height: 15,
                                          fill: Kleur.Blauw,
                                        }}
                                      />
                                    </a>
                                  </div>
                                ),
                              },
                              {
                                label: 'Huur',
                                waarde: maandhuur,
                              },
                              {
                                label: 'Abon.',
                                waarde: `${maandenNaarJaren(contract.MinimaleTermijn)}`,
                              },
                              {
                                label: '# Gebr.',
                                waarde: contract.AantalGebruikers.toString(),
                              },
                            ]}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="d-flex flex-fill ml-2">
                      <div
                        className="d-flex flex-column flex-fill ml-2"
                        style={{ minWidth: 250, maxWidth: 250 }}
                      >
                        <span className="ml-2">
                          {(() => {
                            if (verhuurdeProducten === null) {
                              return <span />;
                            }
                            if (verhuurdeProducten.length === 0) {
                              if (
                                contractStatus.NaamEnum === EContractstatus.Beeindigd &&
                                contract.VerkoopProduct
                              ) {
                                return <span>Beeindiging met verkoop</span>;
                              }

                              // Primair type
                              const typeID =
                                producttypes.length > 0 ? producttypes[0].TypeID : null;

                              const type = producttypes.length > 0 ? producttypes[0] : null;
                              const merk =
                                type !== null ? dataBijID.productMerkenBijID[type.MerkID] : null;
                              const melding =
                                type !== null && merk !== null
                                  ? `${merk.Merknaam} ${type.Typenaam}`
                                  : null;

                              if (contractStatus.NaamEnum === EContractstatus.Concept) {
                                return (
                                  <div>
                                    {melding !== null && (
                                      <div>
                                        <div className="mt-0">
                                          <b>Primair type:</b>
                                          <br />
                                          {melding}
                                        </div>
                                        {typeID !== null && (
                                          <div>
                                            <a
                                              href="#"
                                              onClick={async () => {
                                                await globaleRenderer.render((renderProps) => (
                                                  <VoorraadTypeInfoDialoog
                                                    typeID={typeID}
                                                    open
                                                    onSuccess={() => renderProps.destroy()}
                                                    onAnnuleren={() => renderProps.destroy()}
                                                  />
                                                ));
                                              }}
                                            >
                                              <span>Voorraadinfo</span>
                                            </a>
                                          </div>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                );
                              }

                              if (contractStatus.NaamEnum === EContractstatus.Lopend) {
                                return (
                                  <div>
                                    <div>Er is geen gekoppeld product</div>
                                    {melding !== null && (
                                      <div className="mt-0">
                                        Primair type:
                                        <br />
                                        {melding}
                                      </div>
                                    )}
                                  </div>
                                );
                              }

                              return <span></span>;
                            }

                            if (verhuurdeProducten.length > 1) {
                              return (
                                <span>
                                  Er zijn meer dan 1 producten
                                  <br />
                                  Zie daarvoor de productsectie
                                </span>
                              );
                            }
                            const product = verhuurdeProducten[0];
                            const type = dataBijID.productTypesBijID[product.TypeID];
                            const merk = dataBijID.productMerkenBijID[type.MerkID];

                            return (
                              <span className="font-weight-bold">
                                {merk.Merknaam}&nbsp;
                                {type.Typenaam}
                              </span>
                            );
                          })()}
                        </span>

                        <div className="d-flex">
                          <div className="ml-2 d-flex flex-column flex-fill">{productElement}</div>
                        </div>
                      </div>
                      <div className="d-flex flex-fill ml-2">
                        {/*<VerticaleScheidingslijn />*/}
                        <div>
                          <ContractIndicaties />
                        </div>

                        <div className="flex-fill" />

                        <div className="ml-4">
                          <span className="d-flex align-items-center mr-1">
                            <a href="#" onClick={handleWijzigenClick}>
                              <IconWijzigen
                                style={{
                                  width: 15,
                                  height: 15,
                                  fill: EKleur.Grijs,
                                }}
                              />
                            </a>
                          </span>
                        </div>
                        <ActieMenuKnop
                          acties={[
                            {
                              text: 'Contractwissel',
                              onClick: async () => {
                                await globaleRenderer.render((renderProps) => (
                                  <NieuwContractDialoogV2
                                    open
                                    onSuccess={async (result) => {
                                      if (result.leveropdrachtMaken) {
                                        await handleTransportopdrachtenMaken({
                                          relID: contract.RelID,
                                          cntID: result.cntID,
                                          wisselcontractCntID: result.wisselcontract_CntID,
                                          trsOpdID: result.trsOpdID,
                                        });
                                      }
                                      await props.vernieuwenContracten();
                                      renderProps.destroy();
                                    }}
                                    onAnnuleren={() => renderProps.destroy()}
                                    wisselcontract_CntID={contract.CntID}
                                    relID={contract.RelID}
                                  />
                                ));
                              },
                              icon: (
                                <IconContractwissel
                                  style={{
                                    width: 15,
                                    height: 15,
                                  }}
                                />
                              ),
                            },
                            // {
                            //   text: 'Leveropdracht',
                            //   onClick: async () => {
                            //     await globaleRenderer.render((renderProps) => ());
                            //   },
                            // },
                            {
                              text: 'Nieuwe vordering',
                              onClick: async () => {
                                await globaleRenderer.render((renderProps) => (
                                  <NieuweVorderingDialoog
                                    open
                                    relID={contract.RelID}
                                    cntID={contract.CntID}
                                    onSuccess={async () => {
                                      await props.vernieuwenContracten();
                                      renderProps.destroy();
                                    }}
                                    onAnnuleren={() => renderProps.destroy()}
                                  />
                                ));
                              },
                              icon: (
                                <IconVordering
                                  style={{
                                    width: 15,
                                    height: 15,
                                  }}
                                />
                              ),
                            },
                            {
                              text: 'Downloaden contract',
                              onClick: async () => {
                                setBezigMetContractDownloaden(true);
                                const result = await api.v2.contract.genereerContractPDF({
                                  cntID: contract.CntID,
                                });
                                await downloadUrl(
                                  result.bestand.url,
                                  `${contract.Basisnummer}.${contract.Volgnummer}.pdf`,
                                );
                                setBezigMetContractDownloaden(false);
                              },
                              icon: bezigMetContractDownloaden ? (
                                <LoadingSpinner grootte="12px" dikte="2px" />
                              ) : (
                                <IconDownload
                                  style={{
                                    width: 13,
                                    height: 13,
                                  }}
                                />
                              ),
                              disabled: bezigMetContractDownloaden,
                            },
                            {
                              text: 'Kopieren contract',
                              onClick: async () => {
                                await globaleRenderer.render((renderProps) => (
                                  <KopierenContractDialoog
                                    open
                                    cntID={contract.CntID}
                                    onSuccess={async () => {
                                      await props.vernieuwenContracten();
                                      renderProps.destroy();
                                    }}
                                    onAnnuleren={() => renderProps.destroy()}
                                  />
                                ));
                              },
                              icon: (
                                <IconKopieren
                                  style={{
                                    width: 15,
                                    height: 15,
                                    fill: EKleur.Grijs,
                                  }}
                                />
                              ),
                            },
                            {
                              text: 'Product Uit bedrijf markeren',
                              onClick: async () => {
                                const prodID = producten.filter((x) => {
                                  const productStatus = dataBijID.productStatiBijID[x.ProdStatID];
                                  return productStatus.NaamEnum === EProductstatus.Verhuur;
                                })[0].ProdID;

                                await globaleRenderer.render((renderProps) => (
                                  <UitBedrijfDialoog
                                    open
                                    prodIDs={[prodID]}
                                    onSuccess={async () => {
                                      await props.vernieuwenContracten();
                                      renderProps.destroy();
                                    }}
                                    onAnnuleren={() => renderProps.destroy()}
                                  />
                                ));
                              },
                              disabled:
                                producten.filter((x) => {
                                  const productStatus = dataBijID.productStatiBijID[x.ProdStatID];
                                  return productStatus.NaamEnum === EProductstatus.Verhuur;
                                }).length !== 1,
                              icon: (
                                <IconUitBedrijf
                                  style={{
                                    width: 15,
                                    height: 15,
                                    fill: EKleur.Grijs,
                                  }}
                                />
                              ),
                            },
                            'scheiding',
                            {
                              text: 'Product koppelen aan contract',
                              onClick: async () => {
                                await globaleRenderer.render((renderProps) => (
                                  <ProductSelectieDialoog
                                    open
                                    onSuccess={async (result) => {
                                      const params = {
                                        prodID: result.prodID,
                                        cntBasisID: contract.CntBasisID,
                                      };
                                      const checkData = await api.v2.contract.product.checkKoppelenProduct(
                                        params,
                                      );
                                      const checkResult = await checkStore.controleren({
                                        checkData,
                                      });

                                      if (checkResult.type === EResultType.Annuleren) {
                                        return;
                                      }

                                      if (
                                        (
                                          await checkStore.bevestigen({
                                            inhoud:
                                              'Wil je het geselecteerde product koppelen aan het contract?',
                                          })
                                        ).type === EResultType.Annuleren
                                      ) {
                                        return;
                                      }

                                      await api.v2.contract.product.koppelenProduct(params);
                                      await props.vernieuwenContracten();
                                      renderProps.destroy();
                                    }}
                                    onAnnuleren={() => renderProps.destroy()}
                                  />
                                ));
                              },
                            },
                            {
                              text: 'Product ontkoppelen van contract',
                              disabled:
                                producten.filter((x) => {
                                  const productStatus = dataBijID.productStatiBijID[x.ProdStatID];
                                  return productStatus.NaamEnum === EProductstatus.Verhuur;
                                }).length !== 1,
                              onClick: async () => {
                                // Hier kan alleen het product ontkoppelt worden als het 1 Verhuur-product betreft
                                const prodID =
                                  producten.filter((x) => {
                                    const productStatus = dataBijID.productStatiBijID[x.ProdStatID];
                                    return productStatus.NaamEnum === EProductstatus.Verhuur;
                                  })[0].ProdID ?? null;

                                const params = {
                                  prodIDs: [prodID],
                                };

                                const checkData = await api.v2.contract.product.checkOntkoppelenProducten(
                                  params,
                                );
                                if (
                                  (
                                    await checkStore.controleren({
                                      checkData,
                                    })
                                  ).type === EResultType.Annuleren
                                ) {
                                  return;
                                }

                                if (
                                  (
                                    await checkStore.bevestigen({
                                      inhoud: (
                                        <span>
                                          Wil je het product ontkoppelen van het contract?
                                          <br />
                                          <br />
                                          Het product kom daarna in het standaard voorraadmagazijn
                                          en krijgt de status Geparkeerd.
                                        </span>
                                      ),
                                    })
                                  ).type === EResultType.Annuleren
                                ) {
                                  return;
                                }

                                await api.v2.contract.product.ontkoppelenProducten(params);

                                await props.vernieuwenContracten();
                              },
                            },
                            'scheiding',
                            {
                              text: 'Terugdraaien markering contractwissel',
                              onClick: async () => {
                                const resultaat = await checkStore.bevestigen({
                                  titel: `Markering contractwissel verwijderen?`,
                                });
                                if (resultaat.type === EResultType.Annuleren) {
                                  return;
                                }

                                await api.v2.contract.wissel.terugdraaienMarkerenContractenVoorWissel(
                                  {
                                    cntIDs: [contract.CntID],
                                  },
                                );

                                await props.vernieuwenContracten();
                              },
                              icon: (
                                <IconTerugdraaien
                                  style={{
                                    width: 15,
                                    height: 15,
                                  }}
                                />
                              ),
                              // TODO: Indicaties moeten bekend zijn.. ff kijken hoe
                              disabled: true,
                              // disabled:
                              //   contract.indicaties.AangebodenContractwissel !== null
                              //     ? false
                              //     : true,
                            },
                            {
                              text: 'Terugdraaien aanbieding overname',
                              onClick: async () => {
                                const checkData = await api.v2.contract.aanbieding.checkTerugdraaienAanbiedenContracten(
                                  {
                                    cntIDs: [contract.CntID],
                                  },
                                );
                                const controleResult = await checkStore.controleren({ checkData });
                                if (controleResult.type === EResultType.Annuleren) {
                                  return;
                                }

                                const resultaat = await checkStore.bevestigen({
                                  inhoud: `Aanbieding overname verwijderen?`,
                                });
                                if (resultaat.type === EResultType.Annuleren) {
                                  return;
                                }

                                await api.v2.contract.aanbieding.terugdraaienAanbiedenContracten({
                                  cntIDs: [contract.CntID],
                                });

                                await props.vernieuwenContracten();
                              },
                              icon: (
                                <IconTerugdraaien
                                  style={{
                                    width: 15,
                                    height: 15,
                                  }}
                                />
                              ),
                              // TODO: Indicaties moeten bekend zijn.. ff kijken hoe
                              // disabled: false,
                              disabled: contract.AangebodenOvernameOp !== null ? false : true,
                            },
                            {
                              text: 'Terugdraaien annulering',
                              onClick: async () => {
                                const checkData = await api.v2.contract.annulering.checkTerugdraaienAnnulerenContracten(
                                  {
                                    cntIDs: [contract.CntID],
                                  },
                                );
                                if (
                                  (
                                    await checkStore.controleren({
                                      checkData,
                                    })
                                  ).type === EResultType.Annuleren
                                ) {
                                  return;
                                }

                                if (
                                  (
                                    await checkStore.bevestigen({
                                      titel: 'Annulering terugdraaien?',
                                    })
                                  ).type === EResultType.Annuleren
                                ) {
                                  return;
                                }

                                await api.v2.contract.annulering.terugdraaienAnnulerenContracten({
                                  cntIDs: [contract.CntID],
                                });
                                await props.vernieuwenContracten();
                              },
                              icon: (
                                <IconTerugdraaien
                                  style={{
                                    width: 15,
                                    height: 15,
                                  }}
                                />
                              ),
                              disabled:
                                dataBijID.contractStatussenBijID[contract.CntStatID].NaamEnum !==
                                EContractStatus.Geannuleerd,
                            },
                            {
                              text: 'Terugdraaien beeindiging',
                              onClick: async () => {
                                const params = {
                                  cntIDs: [contract.CntID],
                                  relID: contract.RelID,
                                };
                                const checkData = await api.v2.contract.beeindiging.checkTerugdraaienBeeindigingContracten(
                                  params,
                                );
                                const controleResult = await checkStore.controleren({ checkData });
                                if (controleResult.type === EResultType.Annuleren) {
                                  return;
                                }

                                const resultaat = await checkStore.bevestigen({
                                  inhoud: (
                                    <span>
                                      Beeindiging contract(en) terugdraaien?
                                      <br />
                                      Let op: Eventuele vorderingen en/of retouropdrachten moeten
                                      handmatig verwijderd worden.
                                    </span>
                                  ),
                                });
                                if (resultaat.type === EResultType.Annuleren) {
                                  return;
                                }

                                await api.v2.contract.beeindiging.terugdraaienBeeindigingContracten(
                                  params,
                                );

                                await props.vernieuwenContracten();
                              },
                              icon: (
                                <IconTerugdraaien
                                  style={{
                                    width: 15,
                                    height: 15,
                                  }}
                                />
                              ),
                              disabled:
                                dataBijID.contractStatussenBijID[contract.CntStatID].NaamEnum !==
                                EContractStatus.Beeindigd,
                            },
                            {
                              text: 'Terugdraaien afkeuring',
                              onClick: async () => {
                                const params = {
                                  cntIDs: [contract.CntID],
                                  relID: contract.RelID,
                                };
                                const checkData = await api.v2.contract.afkeuring.checkTerugdraaienAfkeurenContracten(
                                  params,
                                );
                                const controleResult = await checkStore.controleren({ checkData });
                                if (controleResult.type === EResultType.Annuleren) {
                                  return;
                                }

                                const resultaat = await checkStore.bevestigen({
                                  inhoud: (
                                    <span>
                                      Afkeuring contract(en) terugdraaien?
                                      <br /><br/>
                                      Let op: Eventuele vorderingen en/of leveropdrachten moeten
                                      handmatig toegevoegd worden.
                                    </span>
                                  ),
                                });
                                if (resultaat.type === EResultType.Annuleren) {
                                  return;
                                }

                                await api.v2.contract.afkeuring.terugdraaienAfkeurenContracten(
                                  params,
                                );

                                await props.vernieuwenContracten();
                              },
                              icon: (
                                <IconTerugdraaien
                                  style={{
                                    width: 15,
                                    height: 15,
                                  }}
                                />
                              ),
                              disabled:
                                dataBijID.contractStatussenBijID[contract.CntStatID].NaamEnum !==
                                EContractStatus.Afgekeurd,
                            },
                            {
                              text: 'Terugdraaien fiatteren nieuw contract',
                              onClick: async () => {
                                const params = {
                                  cntIDs: [contract.CntID],
                                  relID: contract.RelID,
                                };
                                const checkData = await api.v2.contract.nieuw.checkHerstellenFiatterenContracten(
                                  params,
                                );
                                const checkResult = await checkStore.controleren({
                                  checkData,
                                });
                                if (checkResult.type === EResultType.Annuleren) {
                                  return;
                                }

                                if (
                                  (
                                    await checkStore.bevestigen({
                                      inhoud: 'Fiattering contract terugdraaien?',
                                    })
                                  ).type === EResultType.Annuleren
                                ) {
                                  return;
                                }

                                await api.v2.contract.nieuw.herstellenFiatterenContracten(params);

                                await props.vernieuwenContracten();
                              },
                              icon: (
                                <IconTerugdraaien
                                  style={{
                                    width: 15,
                                    height: 15,
                                  }}
                                />
                              ),
                            },
                          ]}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <TegelUitklapMenu<SectieId>
                    uitgeklaptId={
                      store.contractTegelState[contract.CntID]?.uitgeklapteSectieId ?? null
                    }
                    onUitgeklaptIdChange={(sectieId) =>
                      store.setContractTegelStateItem(
                        contract.CntID,
                        (curr: IContractTegelState | null) => ({
                          ...(curr ?? {}),
                          uitgeklapteSectieId: sectieId,
                        }),
                      )
                    }
                    secties={[
                      {
                        id: 'details',
                        label: 'Details',
                      },
                      {
                        id: 'product',
                        label: 'Product',
                      },
                      {
                        id: 'service',
                        label: 'Service',
                      },
                      {
                        id: 'transport',
                        label: 'Transport',
                      },
                      {
                        id: 'financieel',
                        label: 'Facturen',
                      },
                      {
                        id: 'tarieven',
                        label: 'Tarieven',
                      },
                      // {
                      //   id: ESectie.Mutatie,
                      //   label: 'Mutatie',
                      // },
                    ]}
                  />
                </div>
              </div>
            </div>
          </div>

          {SectieComp !== null && (
            <SectieComp
              contract={contract}
              dataBijID={dataBijID}
              vernieuwenContracten={props.vernieuwenContracten}
            />
          )}
        </Tegel>
      </Root>
    );
  },
);

export default ContractTegel;
