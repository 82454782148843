import React, { useContext, useEffect, useMemo, useState, useCallback } from 'react';
import useUrlState from '../../../core/useUrlState';
import { RouteComponentProps, withRouter } from 'react-router';
import { observer } from 'mobx-react-lite';
import api from '../../../api';
import {
  TypedColumn,
  TypedTableColumnWidthInfo,
  GridStyleWrapper,
  DXTableToggleCellComponent,
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
} from '../../../helpers/dxTableGrid';
import {
  Grid,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  TableSelection,
  VirtualTable,
  TableRowDetail,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  DataTypeProvider,
  EditingState,
  SelectionState,
  RowDetailState,
  SortingState,
  IntegratedSorting,
} from '@devexpress/dx-react-grid';
import { RootStoreContext } from '../../../stores/RootStore';
import { EResultType } from '../../../stores/CheckStore';
import LoadingSpinner from '../../../components/Gedeeld/LoadingSpinner';
import { IOphalenRechtzakenResultElement } from '../../../../../shared/src/api/v2/debiteur/rechtzaak';
import { IconToevoegen, IconVerwijderen } from '../../../components/Icons';
import { Kleur } from '../../../bedrijfslogica/constanten';
import FormatteerBedrag from '../../../components/MutatieBedrag';
import { format } from 'date-fns';
import DetailComp from './DetailComp';
import {
  DXTableCheckboxComponent,
  // GridStyleWrapper,
  // TypedColumn,
  // TypedTableColumnWidthInfo,
} from '../../../helpers/dxTableGrid';
import RelatieVisualisatie from '../../../components/personalia/RelatieVisualisatie';
import { IOphalenFacturenBasisResultElement } from '../../../../../shared/src/api/v2/factuur';
import _ from 'lodash';
import { IOphalenRelatiesResultElementV2 } from '../../../../../shared/src/api/v2/relatie';
import nameof from '../../../core/nameOf';
import { Helmet } from 'react-helmet';

interface IProps extends RouteComponentProps {}

interface IUrlState {
  selectie: number[];
}

const defaultUrlState: IUrlState = {
  selectie: [],
};

const geenData = {
  noData: 'Geen dossiers gevonden',
};

export interface IRechtzakenContext {
  facturen: any[];
}
export const RechtzakenContext = React.createContext<IRechtzakenContext>(null as any);

const Rechtzaken: React.FC<IProps> = observer((props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const { klantkaartStore, checkStore } = useContext(RootStoreContext);
  const [rechtzaken, setRechtzaken] = useState<IOphalenRechtzakenResultElement[] | null>(null);
  const [wijzigenID, setWijzigenID] = useState<number | null>(null);
  const [facturen, setFacturen] = useState<IOphalenFacturenBasisResultElement[] | null>(null);
  const [relaties, setRelaties] = useState<IOphalenRelatiesResultElementV2[] | null>(null);

  const ophalenRechtzaken = useCallback(async () => {
    const results = await api.v2.debiteur.rechtzaak.ophalenRechtzaken({
      filterSchema: {
        filters: [],
      },
    });

    const facturenResult = _.flatten(
      results.rechtzaken.map((z) => {
        return [...z.facturen];
      }),
    );
    const factIDs = _.uniq(facturenResult.map((x: any) => x.FactID));
    const facturenResults = await api.v2.factuur.ophalenFacturenBasis({
      filterSchema: {
        filters: [{ naam: 'IDS', data: factIDs }],
      },
    });
    setFacturen(facturenResults.facturen);

    setRechtzaken(results.rechtzaken);
  }, []);

  useEffect(() => {
    ophalenRechtzaken();
  }, [ophalenRechtzaken]);

  const ophalenRelaties = useCallback(async () => {
    if (rechtzaken === null) {
      return;
    }

    const relIDs = _.uniq(rechtzaken.map((x) => x.RelID));
    const relatiesResult = await api.v2.relatie.ophalenRelaties({
      filterSchema: { filters: [{ naam: 'IDS', data: relIDs }] },
    });
    const relaties = relatiesResult.relaties;

    setRelaties(relaties);
  }, [rechtzaken]);

  useEffect(() => {
    ophalenRelaties();
  }, [ophalenRelaties]);

  const handleVerwijderen = useCallback(async (ID: number) => {
    const checkData = await api.v2.debiteur.rechtzaak.checkVerwijderennRechtzaken({
      ids: [ID],
    });
    if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
      return;
    }
    if (
      (
        await checkStore.bevestigen({
          inhoud: (
            <span>
              Wil je de rechtzaak verwijderen?
              <br />
              Let op, de gekoppelde facturen kunnen daarbij vrijkomen.
            </span>
          ),
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    await api.v2.debiteur.rechtzaak.verwijderenRechtzaken({
      ids: [ID],
    });

    setUrlStateSync('selectie', []);

    ophalenRechtzaken();
  }, []);

  const keyExtractor = useCallback((rij: IOphalenRechtzakenResultElement) => rij.ID, []);

  const kolommen = useMemo<TypedColumn<IOphalenRechtzakenResultElement>[]>(
    () => [
      {
        name: 'Dossierdatum',
        title: 'Dossierdatum',
      },
      {
        name: '__relatie' as any,
        title: 'Debiteur',
        getCellValue: (x) => {
          return relaties !== null && relaties!.find((r) => r.RelID === x.RelID)!.weergavenaam;
        },
      },
      {
        name: 'Hoofdsom',
        title: 'Hoofdsom',
      },
      {
        name: '__incassoDienst' as any,
        title: 'Inc.dienst',
      },
      {
        name: 'Notities',
        title: 'Notities',
      },
      {
        name: 'Afgehandeld',
        title: 'Afgehandeld',
      },
      // {
      //   name: 'Statusnaam',
      //   title: 'Dossierstatus',
      // },
    ],
    [relaties],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IOphalenRechtzakenResultElement>[]>(
    () => [
      {
        columnName: 'Dossierdatum',
        width: 135,
      },
      {
        columnName: '__relatie' as any,
        width: 250,
      },
      {
        columnName: 'Hoofdsom',
        width: 125,
      },
      {
        columnName: '__incassoDienst' as any,
        width: 125,
      },
      {
        columnName: 'Notities',
        width: 250,
      },
      {
        columnName: 'Afgehandeld',
        width: 120,
      },
      // {
      //   columnName: 'Statusnaam',
      //   width: 135,
      // },
    ],
    [],
  );

  const contextValue = useMemo<IRechtzakenContext | null>(() => {
    if (facturen === null) {
      return null;
    }
    return {
      facturen,
    };
  }, [facturen]);

  return contextValue === null ? (
    <div className="flex-fill d-flex align-items-center justify-content-center">
      <LoadingSpinner />
    </div>
  ) : (
    <RechtzakenContext.Provider value={contextValue}>
      <>
        <Helmet>
          <title>Rechtzaken</title>
        </Helmet>
        <div
          className="d-flex flex-column p-3"
          style={{
            backgroundColor: Kleur.HeelLichtGrijs,
            borderBottom: `1px solid ${Kleur.LichtGrijs}`,
          }}
        >
          <div className="d-flex">
            <button
              className="btn btn-sm btn-light d-flex align-items-center ml-3"
              disabled={urlState.selectie.length !== 1}
              style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
              onClick={() => {}}
            >
              {/* <IconV style={{ width: 16, height: 16, fill: Kleur.Grijs }} /> */}
              <span className="ml-2">Versturen</span>
            </button>
          </div>
        </div>

        {rechtzaken === null ? (
          <LoadingSpinner />
        ) : (
          <GridStyleWrapper height={'calc(100vh - 100px)'}>
            <Grid rows={rechtzaken} getRowId={keyExtractor} columns={kolommen}>
              <DataTypeProvider
                for={['__relatie']}
                formatterComponent={(props) => <RelatieVisualisatie relID={props.row.RelID} />}
              />

              <DataTypeProvider
                for={['__incassoDienst']}
                formatterComponent={(props) => {
                  // const incassoDienst =
                  //   props.row.StatusnaamEnum === 'INCASSO'
                  //     ? props.row.incassoDienst !== null
                  //       ? props.row.incassoDienst.Naam
                  //       : ''
                  //     : '';
                  const incassoDienst =
                    props.row.incassoDienst !== null ? props.row.incassoDienst.Naam : '';

                  return <span>{incassoDienst}</span>;
                }}
              />

              <DataTypeProvider
                for={['Dossierdatum']}
                formatterComponent={(props) => {
                  const datum =
                    props.value !== null ? (
                      <span>{format(new Date(props.value), 'dd-MM-yyyy')}</span>
                    ) : (
                      <span></span>
                    );
                  return datum;
                }}
              />

              <DataTypeProvider
                for={['Hoofdsom']}
                formatterComponent={(props) => <FormatteerBedrag bedrag={props.value} />}
              />

              <DataTypeProvider
                for={[nameof<IOphalenRechtzakenResultElement>('Afgehandeld')]}
                formatterComponent={(formatterProps) => {
                  return <span>{formatterProps.value ? 'Ja' : 'Nee'}</span>;
                }}
              />

              <SortingState defaultSorting={[]} />
              <IntegratedSorting />

              <EditingState
                onCommitChanges={async (changes) => {
                  if (changes.deleted === undefined) {
                    return;
                  }
                  const deleted = changes.deleted;
                  const id = deleted[deleted.length - 1] as number;
                  await handleVerwijderen(id);
                }}
                onEditingRowIdsChange={(rowIds) => {
                  const id = rowIds[rowIds.length - 1] as number;
                  // alert(id);
                  setWijzigenID(id);
                }}
              />

              <RowDetailState defaultExpandedRowIds={[]} />

              <VirtualTable />
              <VirtualTable messages={geenData} />
              <TableColumnResizing defaultColumnWidths={kolomBreedtes} />

              <TableEditColumn
                width={65}
                showEditCommand
                showDeleteCommand
                cellComponent={DXTableEditColumnCellComponent}
                commandComponent={DXTableEditColumnCommandComponent}
              />
              <TableHeaderRow showSortingControls />
              <TableRowDetail
                contentComponent={DetailComp}
                toggleCellComponent={DXTableToggleCellComponent}
              />
              <SelectionState
                selection={urlState.selectie}
                onSelectionChange={(x) => setUrlStateSync('selectie', x as number[])}
              />
              <TableSelection cellComponent={DXTableCheckboxComponent} />
            </Grid>
          </GridStyleWrapper>
        )}
      </>
    </RechtzakenContext.Provider>
  );
});

export default withRouter(Rechtzaken);
