import api from '../../index';
import ICheckData from '../../../../../shared/src/models/ICheckData';
import {
  IHerstellenMarkerenAfgehandeldParams,
  IHerstellenMarkerenAfgehandeldResult,
  IMakenBeltaakVoorDebiteurenParams,
  IMakenBeltaakVoorDebiteurenResult,
  IMarkerenAfgehandeldParams,
  IMarkerenAfgehandeldResult,
  IOphalenBeltakenParams,
  IOphalenBeltakenResult,
  IVersturenContactpogingDebiteurenParams,
  IVersturenContactpogingDebiteurenResult,
  IVerwijderenBeltakenParams,
  IVerwijderenBeltakenResult,
} from '../../../../../shared/src/api/v2/beltaak/beltaak';
import context from './context';

const beltaak = {
  context,
  ophalenBeltaken: async (params: IOphalenBeltakenParams): Promise<IOphalenBeltakenResult> => {
    return await api.post('/v2/beltaak/ophalen-beltaken', params);
  },
  checkMarkerenAfgehandeld: async (params: IMarkerenAfgehandeldParams): Promise<ICheckData> => {
    return await api.post('/v2/beltaak/check-markeren-afgehandeld', params);
  },
  markerenAfgehandeld: async (
    params: IMarkerenAfgehandeldParams,
  ): Promise<IMarkerenAfgehandeldResult> => {
    return await api.post('/v2/beltaak/markeren-afgehandeld', params);
  },
  checkVerwijderenBeltaken: async (params: IVerwijderenBeltakenParams): Promise<ICheckData> => {
    return await api.post('/v2/beltaak/check-verwijderen-beltaken', params);
  },
  verwijderenBeltaken: async (
    params: IVerwijderenBeltakenParams,
  ): Promise<IVerwijderenBeltakenResult> => {
    return await api.post('/v2/beltaak/verwijderen-beltaken', params);
  },
  checkHerstellenMarkerenAfgehandeld: async (
    params: IHerstellenMarkerenAfgehandeldParams,
  ): Promise<ICheckData> => {
    return await api.post('/v2/beltaak/check-herstellen-markeren-afgehandeld', params);
  },
  herstellenMarkerenAfgehandeld: async (
    params: IHerstellenMarkerenAfgehandeldParams,
  ): Promise<IHerstellenMarkerenAfgehandeldResult> => {
    return await api.post('/v2/beltaak/herstellen-markeren-afgehandeld', params);
  },
  makenBeltaakVoorDebiteuren: async (
    params: IMakenBeltaakVoorDebiteurenParams,
  ): Promise<IMakenBeltaakVoorDebiteurenResult> => {
    return await api.post('/v2/beltaak/maken-beltaak-voor-debiteuren', params);
  },
  versturenContactpogingDebiteuren: async (
    params: IVersturenContactpogingDebiteurenParams,
  ): Promise<IVersturenContactpogingDebiteurenResult> => {
    return await api.post('/v2/beltaak/versturen-contactpoging-debiteuren', params);
  },
};

export default beltaak;
