import * as React from 'react';
import { Formik, Field, FieldProps } from 'formik';
import VinkVeld from '../../../../../components/formulier/VinkVeld';
import * as Yup from 'yup';
import { useMemo } from 'react';
import teksten from '../../../../../bedrijfslogica/teksten';
import FormikVeldFout from '../../../../../components/formulier/FormikVeldFout';

interface IProps {
  initieleWaarden: IFormikValues;
  onChange: (waarden: IFormikValues | null) => void;
}

export interface IFormikValues {
  naam: string;
  naamKort: string;
  minimaleTermijn: number;
  eenmaligBedrag: number;
  actief: boolean;
}

const veldnamen = {
  naam: 'Naam',
  naamKort: 'Naam kort',
  minimaleTermijn: 'Minimale termijn',
  eenmaligBedrag: 'Eenmalig bedrag',
  actief: 'Actief',
};

const AbonnementFormulier: React.FC<IProps> = (props) => {
  const validatieSchema = useMemo(
    () =>
      Yup.object().shape({
        naam: Yup.string()
          .min(
            1,
            teksten.formulier.E_MINIMAAL_AANTAL_KARAKTERS_BEVATTEN({
              veldnaam: veldnamen.naam,
              aantal: 1,
            }),
          )
          .ensure(),
        naamKort: Yup.string()
          .min(
            1,
            teksten.formulier.E_MINIMAAL_AANTAL_KARAKTERS_BEVATTEN({
              veldnaam: veldnamen.naamKort,
              aantal: 1,
            }),
          )
          .ensure(),
        minimaleTermijn: Yup.number()
          .required(
            teksten.formulier.E_VERPLICHT_VELD({
              veldnaam: veldnamen.minimaleTermijn,
            }),
          )
          .integer(
            teksten.formulier.E_AFGEROND_GETAL({
              veldnaam: veldnamen.minimaleTermijn,
            }),
          )
          .min(
            0,
            teksten.formulier.E_GETAL_MINIMAAL({
              veldnaam: veldnamen.minimaleTermijn,
              aantal: 0,
            }),
          ),
        eenmaligBedrag: Yup.number()
          .required(
            teksten.formulier.E_VERPLICHT_VELD({
              veldnaam: veldnamen.eenmaligBedrag,
            }),
          )
          .min(
            0,
            teksten.formulier.E_GETAL_MINIMAAL({
              veldnaam: veldnamen.eenmaligBedrag,
              aantal: 0,
            }),
          ),
        actief: Yup.bool(),
      }),
    [],
  );

  return (
    <Formik<IFormikValues>
      enableReinitialize
      initialValues={props.initieleWaarden}
      validationSchema={validatieSchema}
      onSubmit={() => null}
      render={(formikprops) => {
        if (formikprops.isValid) {
          props.onChange(formikprops.values);
        } else {
          props.onChange(null);
        }

        return (
          <div>
            <div className="form-group">
              <div className="row">
                <div className="col-8">
                  <label>{veldnamen.naam}</label>
                  <Field
                    name="naam"
                    render={(x: FieldProps<IFormikValues>) => (
                      <>
                        <input className="form-control" {...x.field} />
                        <FormikVeldFout fieldProps={x} />
                      </>
                    )}
                  />
                </div>
                <div className="col-4">
                  <label>{veldnamen.naamKort}</label>
                  <Field
                    name="naamKort"
                    render={(x: FieldProps<IFormikValues>) => (
                      <>
                        <input className="form-control" {...x.field} />
                        <FormikVeldFout fieldProps={x} />
                      </>
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="form-group">
              <div className="row">
                <div className="col-4">
                  <label>{veldnamen.minimaleTermijn}</label>
                  <Field
                    name="minimaleTermijn"
                    render={(x: FieldProps<IFormikValues>) => (
                      <>
                        <input type="number" className="form-control" {...x.field} />
                        <FormikVeldFout fieldProps={x} />
                      </>
                    )}
                  />
                </div>

                <div className="col-4">
                  <label>{veldnamen.eenmaligBedrag}</label>
                  <Field
                    name="eenmaligBedrag"
                    render={(x: FieldProps<IFormikValues>) => (
                      <>
                        <input type="number" className="form-control" {...x.field} />
                        <FormikVeldFout fieldProps={x} />
                      </>
                    )}
                  />
                </div>

                <div className="col-2">
                  <label>{veldnamen.actief}</label>
                  <Field
                    name="actief"
                    render={(x: FieldProps<IFormikValues>) => (
                      <VinkVeld
                        aangevinkt={x.field.value}
                        onGewijzigd={(aangevinkt: boolean) =>
                          x.form.setFieldValue(x.field.name, aangevinkt)
                        }
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      }}
    />
  );
};

export default AbonnementFormulier;
