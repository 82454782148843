import { Boekstuksaldo, Regelboekstuk, ERegelboekstukType } from '../types';
import api from '../../../../../api';

export interface IBoekstuksaldiProvider {
  provide: (regelboekstukken: Regelboekstuk[]) => Promise<Boekstuksaldo[]>;
}

export const standaardBoekstuksaldiProvider: IBoekstuksaldiProvider = {
  provide: async (regelboekstukken) => {
    const result = await api.v2.boeking.ophalenBoekstukkensaldo({
      boekstukken: regelboekstukken.map((regelboekstuk) => {
        switch (regelboekstuk.type) {
          case ERegelboekstukType.Factuur: {
            return {
              type: 'FACT',
              factID: regelboekstuk.factID,
            };
          }
          case ERegelboekstukType.Inkoopfactuur: {
            return {
              type: 'INK_FACT',
              inkFactID: regelboekstuk.inkFactID,
            };
          }
          case ERegelboekstukType.Bankmutatie: {
            return {
              type: 'BANK_MUT',
              bankMutID: regelboekstuk.bankMutID,
            };
          }
          case ERegelboekstukType.Betalingsregeling: {
            return {
              type: 'BET_RGL',
              betRglID: regelboekstuk.betRglID,
            };
          }
        }

        throw new Error('Onbekend regelboekstuk type standaard boeksaldi provider ophalen');
      }),
    });

    return result.boekstukkenMetSaldo.map((boekstukMetSaldo) => {
      switch (boekstukMetSaldo.type) {
        case 'INK_FACT': {
          return {
            type: ERegelboekstukType.Inkoopfactuur,
            inkFactID: boekstukMetSaldo.inkFactID,
            saldo: boekstukMetSaldo.saldo,
          };
        }
        case 'FACT': {
          return {
            type: ERegelboekstukType.Factuur,
            factID: boekstukMetSaldo.factID,
            saldo: boekstukMetSaldo.saldo,
          };
        }
        case 'BANK_MUT': {
          return {
            type: ERegelboekstukType.Bankmutatie,
            bankMutID: boekstukMetSaldo.bankMutID,
            saldo: boekstukMetSaldo.saldo,
          };
        }
        case 'BET_RGL': {
          return {
            type: ERegelboekstukType.Betalingsregeling,
            betRglID: boekstukMetSaldo.betRglID,
            saldo: boekstukMetSaldo.saldo,
          };
        }
      }

      throw new Error('Onbekend boekstuk type standaard boeksaldi provider ophalen convert');
    });
  },
};
