import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const CenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const NoConversationMessageContainer = styled.p``;

export interface IContentContainerProps {
  heightOffset: number;
}

export const NewContactContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: #ececec;
`;

export const ContentContainer = styled.div<IContentContainerProps>`
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #ececec;
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100% - ${(props) => props.heightOffset}px);
  width: 100%;
  position: absolute;
  top: 0;
  box-sizing: border-box;
`;
