import api from '../../../index';
import {
  IOphalenMutatiesParams,
  IOphalenMutatiesResult,
  IOphalenMutatiesoortenParams,
  IOphalenMutatiesoortenResult,
} from '../../../../../../shared/src/api/v2/magazijn/mutatie';
import ICheckData from '../../../../../../shared/src/models/ICheckData';

const mutatie = {
  ophalenMutaties: async (
    params: IOphalenMutatiesParams,
    signal?: AbortSignal,
  ): Promise<IOphalenMutatiesResult> => {
    return await api.post('v2/magazijn/mutatie/ophalen-mutaties', params, {
      signal,
    });
  },
  ophalenMutatiesoorten: async (
    params: IOphalenMutatiesoortenParams,
    signal?: AbortSignal,
  ): Promise<IOphalenMutatiesoortenResult> => {
    return await api.post('v2/magazijn/mutatie/ophalen-mutatiesoorten', params, {
      signal,
    });
  },
};

export default mutatie;
