import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import IDialoogProps from '../../../core/IDialoogProps';
import { observer } from 'mobx-react-lite';
import Dialoog from '../../dialogen/Dialoog';
import LoadingSpinner from '../../Gedeeld/LoadingSpinner';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import { Field, FieldProps, Formik, FormikActions, FormikErrors, FormikProps } from 'formik';
import { ModalBody, ModalFooter } from 'react-bootstrap';
import nameof from '../../../core/nameOf';
import ContactSelectie, { EType } from '../../formulier/ContactSelectie';
import FormikVeldFout from '../../formulier/FormikVeldFout';
import RadioKnop from '../../formulier/RadioKnop';
import { EKanaal } from '../../../bedrijfslogica/enums';
import api from '../../../api';
import { RootStoreContext } from '../../../stores/RootStore';
import { EResultType } from '../../../stores/CheckStore';

interface IProps extends IDialoogProps<{}> {
  trsOpdID: number;
}

interface IFormikValues {
  montageOmschrijving: string;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  montageOmschrijving: 'Omschrijving',
};

const ToevoegenMontageRegelDialoog = observer((props: IProps) => {
  const { onAnnuleren, onSuccess, open } = props;
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const { checkStore } = useContext(RootStoreContext);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      const params = {
        trsOpdIDs: values.montageOmschrijving,
      };

      // if (
      //   (
      //     await checkStore.bevestigen({
      //       titel: `Montage-regel toevoegen?`,
      //     })
      //   ).type === EResultType.Annuleren
      // ) {
      //   return;
      // }

      // await api.v2.transport.opdracht.nieuw.toevoegenMontageregel({
      //   trsOpdID: props.trsOpdID,
      //   montageOmschrijving: values.montageOmschrijving,
      // });

      onSuccess({});
      actions.setSubmitting(false);
    },
    [onSuccess, props],
  );

  const initieleWaarden = useMemo<IFormikValues | null>(() => {
    return {
      montageOmschrijving: '',
    };
  }, []);

  return (
    <Dialoog index={props.dialoogIndex || 0}>
      {initieleWaarden === null ? (
        <LoadingSpinner />
      ) : (
        <>
          <ModalHeader>
            <ModalTitle>Toevoegen montage-regel</ModalTitle>
          </ModalHeader>
          <Formik<IFormikValues>
            onSubmit={handleSubmit}
            initialValues={initieleWaarden!}
            isInitialValid
            // validationSchema={validationSchema}
            render={(formikProps: FormikProps<IFormikValues>) => {
              const { submitForm, isSubmitting, values, isValid } = formikProps;
              return (
                <>
                  <ModalBody>
                    <div className="form-group">
                      <div className="row">
                        <div className="col-12 mt-2">
                          <label>{veldnamen.montageOmschrijving}</label>
                          <Field
                            name={nameof<IFormikValues>('montageOmschrijving')}
                            render={(fieldProps: FieldProps<IFormikValues>) => {
                              const { field, form } = fieldProps;
                              return (
                                <div>
                                  <textarea className="form-control" {...field} rows={2} />
                                  <FormikVeldFout fieldProps={fieldProps} />
                                </div>
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </ModalBody>
                  <ModalFooter className="d-flex justify-content-start">
                    <button
                      className="btn btn-primary"
                      onClick={submitForm}
                      disabled={isSubmitting || !isValid}
                      style={{ width: 100 }}
                    >
                      Ok
                    </button>
                    <button
                      className="btn btn-secondary"
                      onClick={onAnnuleren}
                      style={{ width: 100 }}
                    >
                      Annuleren
                    </button>
                  </ModalFooter>
                </>
              );
            }}
          />
        </>
      )}
    </Dialoog>
  );
});

export default ToevoegenMontageRegelDialoog;
