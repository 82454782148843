import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../models/IRemoteData';
import {
  IOphalenPersonenResult,
  IOphalenPersonenResultElementV2,
} from '../../../../../../shared/src/api/v2/persoon/persoon';
import { IContext, PersoonContext } from '../index';
import api from '../../../../api';
import LoadingSpinner from '../../../Gedeeld/LoadingSpinner';
import ASPTabel from '../../../tabel/ASPTabel';
import { ASPKolom, EAspKolomBreedteType } from '../../../tabel/ASPTabel/types';
import { formatteerPersoonNaam } from '../../../../helpers';
import { ILeegComponentProps } from '../../../tabel/ASPTabel/Body';
import { format } from 'date-fns';

// geslacht: 2,
//   achternaam: 4,
//   voorletters: 1,
//   voornaam: 1,
//   geboortedatum: 5,
//   geboorteplaats: 2,
//   email: 4,
//   telefoon: 2,

enum EKolom {
  Naam,
  Email,
  Telefoon,
  Geboorteplaats,
  Geboortedatum,
}

interface IProps {}

const VervangtTab = (props: IProps) => {
  const { persoon, persoonRelaties } = useContext<IContext>(PersoonContext);

  const [vervangenPersonen, setVervangenPersonen] = useState<IRemoteData<IOphalenPersonenResult>>(
    createPendingRemoteData(),
  );
  const ophalenVervangenPersonen = useCallback(async () => {
    if (persoon === null) {
      if (vervangenPersonen.state === ERemoteDataState.Ready) {
        setVervangenPersonen(createPendingRemoteData());
      }
      return;
    }
    const result = await api.v2.persoon.ophalenPersonen({
      filterSchema: {
        filters: [
          {
            naam: 'VERVANGENDE_PERS_IDS',
            data: [persoon.PersID],
          },
        ],
      },
    });
    setVervangenPersonen(createReadyRemoteData(result));
  }, [persoon]);
  useEffect(() => {
    ophalenVervangenPersonen();
  }, [ophalenVervangenPersonen]);

  const keyExtractor = useCallback((rij: IOphalenPersonenResultElementV2) => rij.PersID, []);
  const kolommen = useMemo<ASPKolom<EKolom, IOphalenPersonenResultElementV2>[]>(
    () => [
      {
        key: EKolom.Naam,
        label: 'Naam',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 350,
        renderer: (rij) =>
          formatteerPersoonNaam({
            aanhefKort: rij.geslacht.AanhefKort,
            achternaam: rij.Achternaam,
            voorletters: rij.Voorletters,
            voornaam: rij.Voornaam,
            voorvoegsel: rij.Voorvoegsel,
          }),
      },
      {
        key: EKolom.Email,
        label: 'Email',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 250,
        renderer: (rij) => rij.Email,
      },
      {
        key: EKolom.Telefoon,
        label: 'Telefoon',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 150,
        renderer: (rij) => rij.TelefoonMobiel,
      },
      {
        key: EKolom.Geboorteplaats,
        label: 'Geboorteplaats',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 175,
        renderer: (rij) => rij.Geboorteplaats,
      },
      {
        key: EKolom.Geboortedatum,
        label: 'Geboortedatum',
        breedteType: EAspKolomBreedteType.Flex,
        flex: 1,
        renderer: (rij) =>
          rij.Geboortedatum === null ? null : format(new Date(rij.Geboortedatum), 'dd-MM-yyyy'),
      },
    ],
    [],
  );

  const LeegComponent = useCallback((leegProps: ILeegComponentProps) => {
    return (
      <div
        style={{ width: leegProps.width, height: leegProps.height }}
        className="d-flex align-items-center justify-content-center"
      >
        <span>Deze persoon vervangt geen andere personen.</span>
      </div>
    );
  }, []);

  return (
    <div className="flex-fill d-flex flex-column">
      {vervangenPersonen.state === ERemoteDataState.Pending ? (
        <LoadingSpinner />
      ) : (
        <ASPTabel
          rijen={vervangenPersonen.data!.personen}
          kolommen={kolommen}
          keyExtractor={keyExtractor}
          leegComponent={LeegComponent}
        />
      )}
    </div>
  );
};

export default VervangtTab;
