import {
  IGegevensVoorJaarwerkParams,
  IGegevensVoorJaarwerkResult,
  IWinstEnVerliesrekeningParams,
  IWinstEnVerliesrekeningResult,
  IExporteerParams,
  IExporteerResult,
  ILijstActivaregisterParams,
  ILijstActivaregisterResult,
  IOpenstaandeDebiteurenParams,
  IOpenstaandeDebiteurenResult,
  ILijstProductenUitBedrijfParams,
  ILijstProductenUitBedrijfResult,
  IOpenstaandeCrediteurenParams,
  IOpenstaandeCrediteurenResult,
} from '../../../../../../shared/src/api/v2/boekhouding/rapportage';
import api from '../../..';

const rapportage = {
  gegevensVoorJaarwerk: async (
    params: IGegevensVoorJaarwerkParams,
  ): Promise<IGegevensVoorJaarwerkResult> => {
    return await api.post('/v2/boekhouding/rapportage/gegevens-voor-jaarwerk', params);
  },
  winstEnVerliesrekening: async (
    params: IWinstEnVerliesrekeningParams,
  ): Promise<IWinstEnVerliesrekeningResult> => {
    return await api.post('/v2/boekhouding/rapportage/winst-en-verliesrekening', params);
  },
  lijstActivaregister: async (
    params: ILijstActivaregisterParams,
  ): Promise<ILijstActivaregisterResult> => {
    return await api.post('/v2/boekhouding/rapportage/lijst-activaregister', params);
  },
  openstaandeDebiteuren: async (
    params: IOpenstaandeDebiteurenParams,
  ): Promise<IOpenstaandeDebiteurenResult> => {
    return await api.post('/v2/boekhouding/rapportage/openstaande-debiteuren', params);
  },
  openstaandeCrediteuren: async (
    params: IOpenstaandeCrediteurenParams,
  ): Promise<IOpenstaandeCrediteurenResult> => {
    return await api.post('/v2/boekhouding/rapportage/openstaande-crediteuren', params);
  },
  exporteer: async (params: IExporteerParams): Promise<IExporteerResult> => {
    return await api.post('/v2/boekhouding/rapportage/exporteer', params);
  },
  lijstProductenUitBedrijf: async (
    params: ILijstProductenUitBedrijfParams,
  ): Promise<ILijstProductenUitBedrijfResult> => {
    return await api.post('/v2/boekhouding/rapportage/lijst-producten-uit-bedrijf', params);
  },
};

export default rapportage;
