import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { CartesianGrid, Legend, Line, LineChart, XAxis, YAxis } from 'recharts';
import {
  IOphalenProductmodellenResult,
  IOphalenProductmodellenResultElement,
} from '../../../../../../shared/src/api/v2/aanbod/productmodel';
import { IConcurrent, IConcurrentProduct } from '../../../../../../shared/src/api/v2/concurrent';
import api from '../../../../api';
import { IFilterData } from '../../../../components/FilterBalkV2';
import useUrlState from '../../../../core/useUrlState';
import { RootStoreContext } from '../../../../stores/RootStore';
import MenuLayout from '../../../../components/MenuLayout';
import Combobox, { IOptie } from '../../../../components/formulier/Combobox';
import { x64 } from 'crypto-js';

type Kolom = 'naam' | 'naam-enum' | 'website-url';

export enum EFilter {
  Zoekterm = 'ZOEKTERM',
}

interface IProps extends RouteComponentProps {}

interface IToevoegenDialoogState {}
interface IWijzigenDialoogState {
  id: number;
}
interface IUrlState {
  selectie: number | null;
  filterdata: IFilterData<EFilter>[];
  toevoegenDialoogState: IToevoegenDialoogState | null;
  wijzigenDialoogState: IWijzigenDialoogState | null;
}

const defaultUrlState: IUrlState = {
  selectie: null,
  filterdata: [],
  toevoegenDialoogState: null,
  wijzigenDialoogState: null,
};

const Overzicht: React.FC<IProps> = (props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const { checkStore } = useContext(RootStoreContext);

  const [productmodellen, setProductmodellen] = useState<IOphalenProductmodellenResult | null>(
    null,
  );
  const [producten, setProducten] = useState<IConcurrentProduct[] | null>(null);
  const [concurrenten, setConcurrenten] = useState<IConcurrent[] | null>(null);

  // producten

  const ophalenProducten = useCallback(async () => {
    console.log('ophalenProducten');
    const results = await api.v2.concurrentie.ophalenProducten({});
    setProducten(results);
  }, []);

  useEffect(() => {
    ophalenProducten();
  }, [ophalenProducten]);

  // concurrenten

  const ophalenConcurrenten = useCallback(async () => {
    console.log('ophalenConcurrenten');
    const results = await api.v2.concurrentie.ophalenConcurrenten();
    setConcurrenten(results);
  }, []);

  useEffect(() => {
    ophalenConcurrenten();
  }, [ophalenConcurrenten]);

  // eigen modellen om aan te koppelen

  const ophalenProductmodellen = useCallback(async () => {
    const result = await api.v2.aanbod.productmodel.ophalenProductmodellen({
      filterSchema: {
        filters: [
          {
            naam: 'ACTIEF',
            data: true,
          },
        ],
      },
    });
    setProductmodellen(result);
  }, []);

  useEffect(() => {
    ophalenProductmodellen();
  }, []);

  const productModelOpties = useMemo<IOptie<number>[] | null>(() => {
    if (!productmodellen) return null;

    return productmodellen.modellen.map((model, index) => ({
      id: index,
      label: model.Modelnaam,
    }));
  }, [productmodellen]);

  const geselecteerdModel = useMemo<IOphalenProductmodellenResultElement | null>(() => {
    if (!productmodellen || !urlState.selectie) return null;

    return productmodellen.modellen[urlState.selectie];
  }, [productmodellen, urlState.selectie]);

  const data = [
    {
      date: 'dec 2023',
      uv: 15.99,
      pv: 14,
      amt: 17,
    },
    {
      date: 'jan 2024',
      uv: 15,
      pv: 11,
      amt: 17,
    },
    {
      date: 'feb2024',
      uv: 15,
      pv: 14,
      amt: 17,
    },
    {
      date: 'apr 2024',
      uv: 16,
      pv: 14,
      amt: 17,
    },
    {
      date: 'mei 2024',
      uv: 16,
      pv: 13,
      amt: 17,
    },
    {
      date: 'jun 2024',
      uv: 16,
      pv: 19,
      amt: 17,
    },
    {
      date: 'jul 2024',
      uv: 18,
      pv: 12.66,
      amt: 17,
    },
  ];

  const [opacity, setOpacity] = React.useState({
    uv: 1,
    pv: 1,
  });

  const handleMouseEnter = (o: any) => {
    const { dataKey } = o;

    setOpacity((op) => ({ ...op, [dataKey]: 0.5 }));
  };

  const handleMouseLeave = (o: any) => {
    const { dataKey } = o;

    setOpacity((op) => ({ ...op, [dataKey]: 1 }));
  };

  return (
    <>
      <MenuLayout
        menu={
          <div className="d-flex align-items-center">
            <span style={{ marginRight: 10 }}>Productmodel</span>
            {productModelOpties && (
              <Combobox
                geselecteerd={urlState.selectie}
                opties={productModelOpties!}
                onSelectieChange={(x) => setUrlStateSync('selectie', x)}
                legeOptieTonen
              />
            )}
          </div>
        }
        body={
          <div className="flex-fill d-flex mt-3" style={{ justifyContent: 'center' }}>
            {!geselecteerdModel ? (
              <div>
                <h2 className="mb-3">Selecteer een productmodel om het prijsverloop te bekijken</h2>
              </div>
            ) : (
              <div style={{ width: 1400 }}>
                <h2 className="mb-3">
                  Prijsverloop overzicht {geselecteerdModel.Modelnaam} (Voorbeeld, geen echte data)
                </h2>
                <LineChart
                  width={1400}
                  height={500}
                  data={data}
                  margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
                >
                  <Line
                    name="Splash"
                    type="monotone"
                    dataKey="uv"
                    strokeOpacity={opacity.pv}
                    stroke="#277"
                    activeDot={{ r: 8 }}
                  />
                  <Line
                    name="Homie Pay per Use"
                    type="monotone"
                    dataKey="pv"
                    strokeOpacity={opacity.uv}
                    stroke="#8884d8"
                  />
                  <Legend onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} />
                  <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                  <XAxis dataKey="date" />
                  <YAxis />
                </LineChart>
              </div>
            )}
          </div>
        }
      ></MenuLayout>
    </>
  );
};

export default withRouter(Overzicht);
