import {
  IOphalenFeedbacksParams,
  IOphalenFeedbacksResult,
  IVerwijderenFeedbacksParams,
  IVerwijderenFeedbacksResult,
} from '../../../../../../shared/src/api/v2/feedback';
import ICheckData from '../../../../../../shared/src/models/ICheckData';
import api from '../../../index';

const feedback = {
  ophalenFeedbacks: async (params: IOphalenFeedbacksParams): Promise<IOphalenFeedbacksResult> => {
    return api.post('/v2/extern/feedback/ophalen-feedbacks', params);
  },
  checkVerwijderenFeedbacks: async (params: IVerwijderenFeedbacksParams): Promise<ICheckData> => {
    return api.post('/v2/extern/feedback/check-verwijderen-feedbacks', params);
  },
  verwijderenFeedbacks: async (
    params: IVerwijderenFeedbacksParams,
  ): Promise<IVerwijderenFeedbacksResult> => {
    return api.post('/v2/extern/feedback/verwijderen-feedbacks', params);
  },
};

export default feedback;
