import api from '../../../..';
import {
  IOphalenRegisterItemsParams,
  IOphalenRegisterItemsResult,
  IVerwijderenRegisterItemsParams,
  IVerwijderenRegisterItemsResult,
  IWijzigenRegisterItemParams,
  IWijzigenRegisterItemResult,
} from '../../../../../../../shared/src/api/v2/boekhouding/activaregister/register';
import ICheckData from '../../../../../../../shared/src/models/ICheckData';

const register = {
  ophalenRegisterItems: async (
    params: IOphalenRegisterItemsParams,
  ): Promise<IOphalenRegisterItemsResult> => {
    return await api.post('/v2/boekhouding/activaregister/register/ophalen-register-items', params);
  },
  checkVerwijderenRegisterItems: async (
    params: IVerwijderenRegisterItemsParams,
  ): Promise<ICheckData> => {
    return await api.post(
      '/v2/boekhouding/activaregister/register/check-verwijderen-register-items',
      params,
    );
  },
  verwijderenRegisterItems: async (
    params: IVerwijderenRegisterItemsParams,
  ): Promise<IVerwijderenRegisterItemsResult> => {
    return await api.post(
      '/v2/boekhouding/activaregister/register/verwijderen-register-items',
      params,
    );
  },
  checkWijzigenRegisterItem: async (params: IWijzigenRegisterItemParams): Promise<ICheckData> => {
    return await api.post(
      '/v2/boekhouding/activaregister/register/check-wijzigen-register-item',
      params,
    );
  },
  wijzigenRegisterItem: async (
    params: IWijzigenRegisterItemParams,
  ): Promise<IWijzigenRegisterItemResult> => {
    return await api.post('/v2/boekhouding/activaregister/register/wijzigen-register-item', params);
  },
};

export default register;
