import React, { useState, useEffect } from 'react';
import { REGEX_IBAN } from '../../../bedrijfslogica/validatie';
import { IGevalideerdFormulierWaarde } from '../helpers';

interface IProps {
  iban: IGevalideerdFormulierWaarde<string>;
  onChange: (iban: IGevalideerdFormulierWaarde<string>) => void;
}

const IbanVeld = (props: IProps) => {
  const [tekst, setTekst] = useState(props.iban.waarde);

  useEffect(() => {
    if (tekst === props.iban.waarde) {
      return;
    }

    const matchResult = tekst.match(REGEX_IBAN);
    const isValide = matchResult !== null;

    props.onChange({
      isValide,
      waarde: tekst,
    });
  }, [tekst]);

  useEffect(() => {
    if (props.iban === null || props.iban.waarde === tekst) {
      return;
    }

    setTekst(props.iban.waarde);
  }, [props.iban.waarde]);

  return (
    <input
      type="text"
      className="form-control"
      value={tekst}
      onChange={(event) => setTekst(event.currentTarget.value.toUpperCase())}
    />
  );
};

export default IbanVeld;
