import api from '../../index';
import {
  IOphalenMagazijnenParams,
  IOphalenMagazijnenResult,
  IOphalenMagazijnenVoorraadParams,
  IToevoegenMagazijnParams,
  IToevoegenMagazijnResult,
  IVerwijderenMagazijnenParams,
  IVerwijderenMagazijnenResult,
  IWijzigenMagazijnParams,
  IWijzigenMagazijnResult,
  OphalenMagazijnenVoorraadResult,
} from '../../../../../shared/src/api/v2/magazijn';
import ICheckData from '../../../../../shared/src/models/ICheckData';
import mutatie from './mutatie';
import inventarisatie from '../magazijn/inventarisatie';
import exportD from './export';
import afhaal from './afhaal';

const magazijn = {
  inventarisatie,
  mutatie,
  afhaal,
  export: exportD,
  ophalenMagazijnen: async (
    params: IOphalenMagazijnenParams,
  ): Promise<IOphalenMagazijnenResult> => {
    return await api.post('v2/magazijn/ophalen-magazijnen', params);
  },
  ophalenMagazijnenVoorraad: async (
    params: IOphalenMagazijnenVoorraadParams,
  ): Promise<OphalenMagazijnenVoorraadResult> => {
    return await api.post('v2/magazijn/ophalen-magazijnen-voorraad', params);
  },
  checkToevoegenMagazijn: async (params: IToevoegenMagazijnParams): Promise<ICheckData> => {
    return await api.post('v2/magazijn/check-toevoegen-magazijn', params);
  },
  toevoegenMagazijn: async (
    params: IToevoegenMagazijnParams,
  ): Promise<IToevoegenMagazijnResult> => {
    return await api.post('v2/magazijn/toevoegen-magazijn', params);
  },
  checkWijzigenMagazijn: async (params: IWijzigenMagazijnParams): Promise<ICheckData> => {
    return await api.post('v2/magazijn/check-wijzigen-magazijn', params);
  },
  wijzigenMagazijn: async (params: IWijzigenMagazijnParams): Promise<IWijzigenMagazijnResult> => {
    return await api.post('v2/magazijn/wijzigen-magazijn', params);
  },
  checkVerwijderenMagazijnen: async (params: IVerwijderenMagazijnenParams): Promise<ICheckData> => {
    return await api.post('v2/magazijn/check-verwijderen-magazijnen', params);
  },
  verwijderenMagazijnen: async (params: IVerwijderenMagazijnenParams): Promise<IVerwijderenMagazijnenResult> => {
    return await api.post('v2/magazijn/verwijderen-magazijnen', params);
  },
};

export default magazijn;
