import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import IDialoogProps from '../../../../core/IDialoogProps';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import api from '../../../../api';
import FormikVeldFout from '../../../../components/formulier/FormikVeldFout';
import VinkVeld from '../../../../components/formulier/VinkVeld';
import Combobox from '../../../../components/formulier/Combobox';
import { IOphalenDienstenResultElement } from '../../../../../../shared/src/api/v2/dienst/service';
import Dialoog from '../../../../components/dialogen/Dialoog';
import nameOf from '../../../../core/nameOf';
import NumeriekVeld from '../../../../components/formulier/NumeriekVeld';
import * as Yup from 'yup';
import { RootStoreContext } from '../../../../stores/RootStore';
import { EResultType } from '../../../../stores/CheckStore';
import LocatieSelectie from '../../../../components/formulier/LocatieSelectie';

interface IProps extends IDialoogProps<null> {}

export interface IFormikValues {
  actief: boolean;
  naam: string | null;
  naamKort: string;
  voorraadMagazijn: boolean;
  revisieMagazijn: boolean;
  locID: number | null;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  actief: 'Actief',
  naam: 'Naam',
  naamKort: 'Naam kort',
  voorraadMagazijn: 'Voorraadmagazijn',
  revisieMagazijn: 'Revisiemagazijn',
  locID: 'Locatie',
};

const ToevoegenDialoog: React.FC<IProps> = (props) => {
  const { onAnnuleren, onSuccess, open, dialoogIndex } = props;
  const { checkStore } = useContext(RootStoreContext);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      const params = {
        naam: values.naam === '' ? null : values.naam,
        naamKort: values.naamKort,
        voorraadMagazijn: values.voorraadMagazijn,
        revisieMagazijn: values.revisieMagazijn,
        actief: values.actief,
        locID: values.locID!,
        magDienstID: null,
        params: null,
      };

      const checkData = await api.v2.magazijn.checkToevoegenMagazijn(params);

      const checkResult = await checkStore.controleren({
        checkData,
      });
      if (checkResult.type === EResultType.Annuleren) {
        actions.setSubmitting(false);
        return;
      }

      await api.v2.magazijn.toevoegenMagazijn(params);

      onSuccess(null);
      actions.setSubmitting(false);
    },
    [],
  );

  const initialValues = useMemo<IFormikValues>(() => {
    return {
      naam: '',
      naamKort: '',
      voorraadMagazijn: false,
      revisieMagazijn: false,
      actief: true,
      locID: null,
    };
  }, []);

  const validationSchema = useMemo(() => {
    const fields: Partial<Record<keyof IFormikValues, any>> = {
      naamKort: Yup.string().required(),
      locID: Yup.number().required(),
    };

    return Yup.object().shape(fields);
  }, []);

  return (
    <Dialoog index={dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Toevoegen magazijn</ModalTitle>
      </ModalHeader>

      {initialValues === null ? (
        <div className="d-flex flex-fill align-items-center justify-content-center">
          <LoadingSpinner />
        </div>
      ) : (
        <Formik<IFormikValues>
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          render={(formikProps: FormikProps<IFormikValues | null>) => {
            const { submitForm, isSubmitting, isValid, values } = formikProps;
            return (
              <>
                <ModalBody>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-6">
                        <label>{veldnamen.naamKort}</label>
                        <Field
                          name={nameOf<IFormikValues>('naamKort')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            return <input {...field} className="form-control" type="text" />;
                          }}
                        />
                      </div>
                      <div className="col-6">
                        <label>{veldnamen.naam}</label>
                        <Field
                          name={nameOf<IFormikValues>('naam')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            return <input {...field} className="form-control" type="text" />;
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <label>{veldnamen.locID}</label>
                        <Field
                          name="locID"
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <LocatieSelectie
                                locID={fieldProps.field.value}
                                onChange={(locID) => {
                                  form.setFieldValue(field.name, locID);
                                }}
                                // relIDs={[relatie.RelID]}
                                options={{
                                  bijEnkeleDirectVoorselecteren: true,
                                }}
                              />
                            );
                          }}
                        />
                      </div>

                      <div className="col-6 mt-3">
                        <label>{veldnamen.voorraadMagazijn}</label>
                        <Field
                          name={nameOf<IFormikValues>('voorraadMagazijn')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            return (
                              <VinkVeld
                                aangevinkt={field.value}
                                onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                              />
                            );
                          }}
                        />
                      </div>
                      <div className="col-6 mt-3">
                        <label>{veldnamen.revisieMagazijn}</label>
                        <Field
                          name={nameOf<IFormikValues>('revisieMagazijn')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            return (
                              <VinkVeld
                                aangevinkt={field.value}
                                onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                              />
                            );
                          }}
                        />
                      </div>
                    </div>
                    <div className="row mt-3">
                      {/* <div className="col-12 mt-2">
                        <label>{veldnamen.ServDienstID_Garantie}</label>

                        <Field
                          name={nameOf<IFormikValues>('ServDienstID_Garantie')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            return (
                              <Combobox
                                legeOptieTonen
                                geselecteerd={field.value}
                                onSelectieChange={(x) => form.setFieldValue(field.name, x)}
                                opties={servicediensten.map((x) => {
                                  return {
                                    id: x.ID,
                                    label: x.NaamIdent!,
                                  };
                                })}
                                isWisbaar
                              />
                            );
                          }}
                        />
                      </div> */}

                      {/* <div className="col-12 mt-3">
                        <div className="row col-6">
                          <label>{veldnamen.GarantiePeriode}</label>
                          <Field
                            name={nameOf<IFormikValues>('GarantiePeriode')}
                            render={(fieldProps: FieldProps<IFormikValues>) => {
                              const { field, form } = fieldProps;
                              return (
                                <NumeriekVeld
                                  waarde={field.value}
                                  onChange={(x) => form.setFieldValue(field.name, x)}
                                  min={0}
                                  max={120}
                                />
                              );
                            }}
                          />
                        </div>
                      </div> */}

                      <div className="col-12 mt-3">
                        <label>{veldnamen.actief}</label>
                        <Field
                          name={nameOf<IFormikValues>('actief')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <VinkVeld
                                aangevinkt={field.value}
                                onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                              />
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </ModalBody>
                <ModalFooter className="d-flex justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    disabled={isSubmitting || !isValid}
                    style={{ width: 100 }}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={onAnnuleren}
                    style={{ width: 100 }}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Dialoog>
  );
};

export default ToevoegenDialoog;
