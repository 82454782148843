import React, { useContext, useMemo } from 'react';
import { IProps } from '@tinymce/tinymce-react/lib/cjs/main/ts/components/Editor';
import { ProductContext } from '../index';
import MediaWeergave, { EMediaWeergaveType, MediaWeergaveItem } from '../../../MediaWeergave';

const AfbeeldingTab: React.FC<IProps> = (props) => {
  const { productmodel } = useContext(ProductContext);

  const mediaWeergaven = useMemo<MediaWeergaveItem[] | null>(
    () =>
      productmodel === null
        ? null
        : productmodel.bestanden.map((x) => ({
            id: x.bestand.ID,
            src: x.bestand.url,
            title: x.bestand.Naam || undefined,
            type: EMediaWeergaveType.Afbeelding as any,
            mediaType: x.bestand.MediaType,
          })),
    [productmodel],
  );

  if (mediaWeergaven === null) {
    return null;
  }

  return (
    <div className="d-flex flex-fill flex-column p-2" style={{ maxHeight: `0px` }}>
      {mediaWeergaven.length === 0 ? (
        <div className="d-flex align-items-center justify-content-center flex-fill">
          <span>Er zijn geen afbeeldingen beschikbaar.</span>
        </div>
      ) : (
        <MediaWeergave
          mediaWeergaven={mediaWeergaven}
          imageStyle={{
            maxHeight: 350,
          }}
        />
      )}
    </div>
  );
};

export default AfbeeldingTab;
