import React, { useCallback, useEffect, useMemo, useState, useContext } from 'react';
import {
  DataTypeProvider,
  EditingState,
  SelectionState,
  TableRowDetail,
} from '@devexpress/dx-react-grid';
import GegevensLayout from '../../../../components/layout/GegevensLayout';
import VerticaleScheidingslijn from '../../../../components/layout/VerticaleScheidingslijn';
import { format } from 'date-fns';
import {
  IOphalenInkoopopdrachtBestandenResult,
  IOphalenOpdrachtenResultElement,
} from '../../../../../../shared/src/api/v2/inkoop/opdracht';
import { IOphalenMutatiesResultElement } from '../../../../../../shared/src/api/v2/magazijn/mutatie';
import {
  GridStyleWrapper,
  TableRowDetailWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import TabelInspringBlok from '../../../../components/layout/TabelInspringBlok';
import CorrectieDialoog from '../DetailComp/CorrectieDialoog';
import { InkoopopdrachtenContext } from '..';
import _ from 'lodash';
import BijlageKnop from '../../../../components/BijlageKnop';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../models/IRemoteData';
import BijlagenContainer, {
  BestandType,
  EWeergaveFocus,
} from '../../../../components/BijlagenContainer';
import useUpload from '../../../../core/useUpload';
import api from '../../../../api';

interface IProps extends TableRowDetail.ContentProps {
  zonderCorrigeren?: boolean;
}

interface IRow extends IOphalenOpdrachtenResultElement {
  magazijnmutaties: IOphalenMutatiesResultElement[];
}

const geenData = {
  noData: 'Geen inkoopmutaties',
};

interface ICorrectieDialoogState {
  id: number;
}

const DetailComp: React.FC<IProps> = (props) => {
  const row = props.row as IRow;
  const { verversen } = useContext(InkoopopdrachtenContext);

  const [correctieDialoogState, setCorrectieDialoogState] = useState<ICorrectieDialoogState | null>(
    null,
  );

  const mutaties = _.orderBy(row.magazijnmutaties, ['Mutatiedatum'], ['desc']);

  // Grid Magazijnmutaties
  const keyExtractor = useCallback((row: IOphalenMutatiesResultElement) => row.ID, []);

  const kolommen = useMemo<TypedColumn<IOphalenMutatiesResultElement>[]>(
    () =>
      [
        {
          name: 'Mutatiedatum',
          title: 'Datum',
        },
        {
          name: 'Aantal',
          title: 'Aantal',
        },
        {
          name: '__merknaam' as any,
          title: 'Merk',
        },
        {
          name: '__typenaam' as any,
          title: 'Type',
        },
        {
          name: 'RecordToegevoegd',
          title: 'Geregistreerd',
        },
        props.zonderCorrigeren
          ? null
          : {
              name: '__corrigeren' as any,
              title: 'Corrigeren',
            },
      ].filter((x) => x !== null) as TypedColumn<IOphalenMutatiesResultElement>[],
    [props.zonderCorrigeren],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IOphalenMutatiesResultElement>[]>(
    () => [
      {
        columnName: 'Mutatiedatum',
        width: 150,
      },
      {
        columnName: 'Aantal',
        width: 100,
      },
      {
        columnName: '__merknaam' as any,
        width: 100,
      },
      {
        columnName: '__typenaam' as any,
        width: 150,
      },
      {
        columnName: '__corrigeren' as any,
        width: 120,
      },
      {
        columnName: 'RecordToegevoegd',
        width: 150,
      },
    ],
    [],
  );

  const [bijlagenResult, setBijlagenResult] = useState<
    IRemoteData<IOphalenInkoopopdrachtBestandenResult>
  >(createPendingRemoteData());
  const ophalenBijlagen = useCallback(async () => {
    const result = await api.v2.inkoop.opdracht.ophalenInkoopopdrachtBestanden({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: [row.InkOpdID],
          },
        ],
      },
    });
    setBijlagenResult(createReadyRemoteData(result));
  }, [row.InkOpdID]);
  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    ophalenBijlagen();
  }, [ophalenBijlagen]);

  const { uploadProgresses, bestanden, isBezigMetUploaden, muteerBestanden } = useUpload(
    useMemo(
      () => ({
        initieleBestanden:
          bijlagenResult.state === ERemoteDataState.Pending
            ? null
            : bijlagenResult.data!.bestanden.map((bestand) => ({
                type: BestandType.ASPDrive as BestandType.ASPDrive,
                bestand: bestand.bestand,
              })),
        automatischUploaden: true,
        onAlleBestandenGeuploaded: async (bestIDs: number[]) => {
          await api.v2.inkoop.opdracht.muterenInkoopopdrachtBestanden({
            inkOpdID: row.InkOpdID,
            bestIDs,
          });
        },
      }),
      [row.InkOpdID, bijlagenResult],
    ),
  );

  return (
    <TableRowDetailWrapper>
      <>
        <div className="d-flex">
          <TabelInspringBlok />
          {/* <div className="d-flex">
            <div style={{ width: 200 }}>
              <GegevensLayout
                gegevens={[
                  {
                    label: 'Merk',
                    waarde: `${row.product.Merknaam}`,
                  },
                  {
                    label: 'Type',
                    waarde: `${row.product.Typenaam}`,
                  },
                  {
                    label: 'Aantal',
                    waarde: `${row.Aantal}`,
                  },
                ]}
              />
            </div>
          </div> */}

          <div
            style={{
              backgroundColor: Kleur.Wit,
            }}
            className="d-flex flex-column"
          >
            <h5 className="p-2 pl-3 mt-2">Mutaties</h5>
            {mutaties === null ? (
              <LoadingSpinner />
            ) : (
              <GridStyleWrapper maxHeight={200} rowAmount={mutaties.length}>
                <Grid columns={kolommen} getRowId={keyExtractor} rows={mutaties}>
                  <DataTypeProvider
                    for={['Mutatiedatum']}
                    formatterComponent={(props) => {
                      const datum =
                        props.value !== null ? format(new Date(props.value), 'dd-MM-yyyy') : '';
                      return (
                        <>
                          <span>{datum}</span>
                        </>
                      );
                    }}
                  />

                  <DataTypeProvider
                    for={['__merknaam']}
                    formatterComponent={(props) => {
                      return (
                        <>
                          <span>{props.row.producttype.Merknaam}</span>
                        </>
                      );
                    }}
                  />

                  <DataTypeProvider
                    for={['__typenaam']}
                    formatterComponent={(props) => {
                      return (
                        <>
                          <span>{props.row.producttype.Typenaam}</span>
                        </>
                      );
                    }}
                  />

                  <DataTypeProvider
                    for={['__corrigeren']}
                    formatterComponent={(formatterProps) => {
                      const row: IOphalenMutatiesResultElement = formatterProps.row;
                      if (row.Aantal < 0) {
                        return <span />;
                      }
                      return (
                        <span className="ml-2">
                          <a href="#" onClick={() => setCorrectieDialoogState({ id: row.ID })}>
                            Corrigeren
                          </a>
                        </span>
                      );
                    }}
                  />

                  <DataTypeProvider
                    for={['RecordToegevoegd']}
                    formatterComponent={(props) => {
                      const datum =
                        props.value !== null
                          ? format(new Date(props.value), 'dd-MM-yyyy HH:mm')
                          : '';
                      return (
                        <>
                          <span>{datum}</span>
                        </>
                      );
                    }}
                  />

                  {/* <SelectionState
                selection={urlState.selectie}
                onSelectionChange={(x) => setUrlStateSync('selectie', x as number[])}
              />
              <EditingState
                onCommitChanges={() => null}
                onEditingRowIdsChange={(rowIds) => {
                  const id = rowIds[rowIds.length - 1] as number;
                  setInkOpdID(id);
                  setTonenStatusDialoog(true);
                }}
              /> */}
                  {/*
              <SortingState
                sorting={urlState.sortering}
                onSortingChange={(value) => setUrlStateSync('sortering', value)}
              />
              <IntegratedSorting /> */}
                  {/* <RowDetailState defaultExpandedRowIds={[]} /> */}

                  <VirtualTable messages={geenData} />

                  <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
                  <TableHeaderRow />

                  {/* <TableEditColumn
                width={35}
                showEditCommand
                cellComponent={DXTableEditColumnCellComponent}
                commandComponent={DXTableEditColumnCommandComponent}
              /> */}
                  {/* <TableRowDetail
                contentComponent={DetailComp}
                toggleCellComponent={DXTableToggleCellComponent}
              />
              <TableSelection cellComponent={DXTableCheckboxComponent} /> */}
                </Grid>
              </GridStyleWrapper>
            )}
            <div className="d-flex align-items-center">
              <h5 className="p-2 pl-3 mt-2">Bijlagen</h5>
              {/*<h5 className="mr-4" style={{ position: 'relative', top: 2 }}>*/}
              {/*  Bijlagen*/}
              {/*</h5>*/}
              <BijlageKnop
                disabled={isBezigMetUploaden || bijlagenResult.state === ERemoteDataState.Pending}
                onBijgevoegd={(bestanden) => muteerBestanden((x) => [...x, ...bestanden])}
                toegestaneBestandstypes={[
                  {
                    weergaveNaam: 'Afbeeldingen',
                    mediaType: 'image/*',
                  },
                  {
                    weergaveNaam: 'PDF',
                    mediaType: 'application/pdf',
                  },
                ]}
              />
            </div>
            <div className="mt-1 flex-fill d-flex flex-column ml-4 mb-3">
              <BijlagenContainer
                bestanden={bestanden}
                bestandenMuterenToegestaan={!isBezigMetUploaden}
                uploadProgresses={uploadProgresses}
                onBestandenChange={(bestanden) => muteerBestanden((_) => bestanden)}
                weergaveFocus={EWeergaveFocus.Visualisatie}
                herordenenToestaan
              />
            </div>
          </div>
        </div>
        {correctieDialoogState !== null && (
          <CorrectieDialoog
            id={correctieDialoogState.id}
            open
            onSuccess={() => {
              setCorrectieDialoogState(null);
              // ophalenOpdrachten();
              verversen();
            }}
            onAnnuleren={() => {
              setCorrectieDialoogState(null);
            }}
          />
        )}
      </>
    </TableRowDetailWrapper>
  );
};

export default DetailComp;
