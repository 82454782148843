import { Field, FieldProps, Formik, FormikActions, FormikErrors } from 'formik';
import _ from 'lodash';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import { IOphalenGrootboekenResultElement } from '../../../../../shared/src/api/v2/boekhouding/boeking/grootboek';
import { IOphalenFacturenBasisResultElement } from '../../../../../shared/src/api/v2/factuur';
import api from '../../../api';
import IDialoogProps from '../../../core/IDialoogProps';
import nameOf from '../../../core/nameOf';
import { EResultType } from '../../../stores/CheckStore';
import { RootStoreContext } from '../../../stores/RootStore';
import Dialoog from '../../dialogen/Dialoog';
import BedragInput from '../../formulier/BedragInput';
import Combobox from '../../formulier/Combobox';
import FormikVeldFout from '../../formulier/FormikVeldFout';
import * as Yup from 'yup';
import TekstSuggestieVeld, {
  IExposeData,
  IInputComponentProps,
} from '../../formulier/TekstSuggestieVeld';
import { EGrootboekrekening, EGrootboekrekeningNaam } from '../../../bedrijfslogica/enums';
import VeldWeergave from '../../formulier/VeldWeergave';
import FormatteerBedrag from '../../MutatieBedrag';

interface IProps extends IDialoogProps<null> {
  relID: number;
  factIDs: number[];
}

interface IFormikValues {
  bedragAfboeken: number;
  grbRekID: number;
  omschrijving: string;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  bedragAfboeken: 'Af te boeken bedrag',
  grbRekID: 'Grootboekrekening',
  omschrijving: 'Reden van het afboeken',
};

const omschrijvingSuggesties = [
  'Coulance-afboeking',
  'Oninbaar',
  'Correctie',
  'Omboeking naar incassobedrijf',
]; // TODO Dynamisch maken

const AfboekenDialoog: React.FC<IProps> = (props) => {
  const { checkStore, instellingStore } = useContext(RootStoreContext);
  const formikRef = useRef<Formik<IFormikValues>>(null);

  const [facturen, setFacturen] = useState<IOphalenFacturenBasisResultElement[] | null>(null);
  const [grootboekrekeningen, setGrootboekrekeningen] = useState<
    IOphalenGrootboekenResultElement[] | null
  >(null);
  const [grbRekID, setGrbRekID] = useState<number | null>(null);
  const [bedragAfboeken, setBedragAfboeken] = useState<number | null>(null);
  const [bedragVooruitOntvangen, setBedragVooruitOntvangen] = useState<number | null>(null);
  const [saldiStornokosten, setSaldiStornokosten] = useState<number | null>(null);
  const [saldiAanmaningskosten, setSaldiAanmaningskosten] = useState<number | null>(null);

  // Facturen
  useEffect(() => {
    (async () => {
      const facturenResult = await api.v2.factuur.ophalenFacturenBasis({
        filterSchema: {
          filters: [{ naam: 'IDS', data: props.factIDs }],
        },
      });

      setFacturen(facturenResult.facturen);
    })();
  }, [props.factIDs]);

  // Grootboekrekeningen (rijbron)
  const ophalenGrootboeken = useCallback(async () => {
    const grootboekrekeningen = (
      await api.v2.boeking.grootboek.ophalenGrootboeken({
        filterSchema: {
          filters: [
            {
              naam: 'NUMMERS',
              data:
                props.factIDs.length === 1
                  ? [EGrootboekrekening.Vooruitbetaald, EGrootboekrekening.AfboekenDebiteuren]
                  : [EGrootboekrekening.AfboekenDebiteuren],
            },
          ],
        },
      })
    ).grootboeken;
    setGrootboekrekeningen(grootboekrekeningen);
  }, [props.factIDs]);

  useEffect(() => {
    ophalenGrootboeken();
  }, [ophalenGrootboeken]);

  // Eventueel saldo Vooruitbetaald
  useEffect(() => {
    (async () => {
      if (props.factIDs.length !== 1) {
        setSaldiStornokosten(0);
        setSaldiAanmaningskosten(0);
        return;
      }

      const saldiResult = await api.v2.boekhouding.boeking.ophalenSaldiGrootboekrekeningen({
        filterSchema: { filters: [{ naam: 'REL_IDS', data: [props.relID] }] },
      });

      const saldoVooruitbetaaldResult = saldiResult.saldi.find(
        (x) => x.grootboekrekening.Nummer === EGrootboekrekening.Vooruitbetaald,
      );
      const saldoVooruitbetaald =
        saldoVooruitbetaaldResult !== undefined ? -saldoVooruitbetaaldResult.saldo : 0;

      setBedragVooruitOntvangen(saldoVooruitbetaald);

      // Zijn er saldi op de storno- en/of aanmaningskosten
      const saldiFactuur = (
        await api.v2.boekhouding.boeking.ophalenSaldiGrootboekrekeningen({
          filterSchema: { filters: [{ naam: 'FACT_IDS', data: [props.factIDs] }] },
        })
      ).saldi;

      const saldiStornokosten = saldiFactuur.find(
        (x) => x.grootboekrekening.NaamEnum === EGrootboekrekeningNaam.Stornokosten,
      );
      const saldiAanmaningskosten = saldiFactuur.find(
        (x) => x.grootboekrekening.NaamEnum === EGrootboekrekeningNaam.Aanmaningskosten,
      );

      const stornokosten = saldiStornokosten !== undefined ? -saldiStornokosten.saldo : 0;
      const aanmaningskosten =
        saldiAanmaningskosten !== undefined ? -saldiAanmaningskosten.saldo : 0;

      setSaldiStornokosten(stornokosten);
      setSaldiAanmaningskosten(aanmaningskosten);
    })();
  }, [props.relID, props.factIDs]);

  // Grootboekrekening
  useEffect(() => {
    (async () => {
      if (grootboekrekeningen === null) {
        return;
      }

      if (props.factIDs.length !== 1) {
        setGrbRekID(
          grootboekrekeningen.find((x) => x.Nummer === EGrootboekrekening.AfboekenDebiteuren)!.ID,
        );
      }

      if (bedragVooruitOntvangen === null) {
        return;
      }
      const grbRekID =
        bedragVooruitOntvangen !== 0
          ? grootboekrekeningen.find((x) => x.Nummer === EGrootboekrekening.Vooruitbetaald)!.ID
          : grootboekrekeningen.find((x) => x.Nummer === EGrootboekrekening.AfboekenDebiteuren)!.ID;

      setGrbRekID(grbRekID);
    })();
  }, [props.factIDs, bedragVooruitOntvangen, grootboekrekeningen]);

  // Af te boeken bedrag
  const ophalenBedrag = useCallback(async () => {
    if (props.factIDs.length !== 1) {
      if (facturen === null) {
        return;
      }
      setBedragAfboeken(facturen.reduce((acc, x) => acc + x.Openstaand, 0));
      return;
    }

    if (bedragVooruitOntvangen === null || grootboekrekeningen === null || facturen === null) {
      return;
    }

    const factuur = facturen[0];

    // Als er afgeboekt kan worden op vooruitbetaald dan maximaal het bedrag waarvoor de factuur openstaat
    const maxAfTeBoekenBedragBijVooruitOntvangen =
      factuur.Openstaand > bedragVooruitOntvangen ? bedragVooruitOntvangen : factuur.Openstaand;

    const bedragAfboeken =
      grbRekID ===
      grootboekrekeningen.find((x) => x.Nummer === EGrootboekrekening.Vooruitbetaald)!.ID
        ? maxAfTeBoekenBedragBijVooruitOntvangen
        : factuur.Openstaand;

    setBedragAfboeken(bedragAfboeken);
  }, [props.factIDs, bedragVooruitOntvangen, facturen, grbRekID, grootboekrekeningen]);

  useEffect(() => {
    ophalenBedrag();
  }, [ophalenBedrag]);

  // Initiele waarden
  const initialValues = useMemo<IFormikValues | null>(() => {
    if (grbRekID === null || bedragAfboeken === null) {
      return null;
    }

    return {
      bedragAfboeken,
      grbRekID,
      omschrijving: 'Coulance-afboeking',
    };
  }, [grbRekID, bedragAfboeken]);

  // const validationSchema = useMemo(() => {
  //   const fields: Record<keyof IFormikValues, any> = {
  //     bedragAfboeken: Yup.number().required(),
  //     // omschrijving: Yup.string().required(),
  //     grbRekID: Yup.number().required(),
  //   };
  //   return Yup.object().shape(fields);
  // }, []);

  const handleValidate = useCallback(
    (values: IFormikValues) => {
      const errors: FormikErrors<IFormikValues> = {};

      if (
        grootboekrekeningen!.find((x) => x.ID === grbRekID)!.Nummer ===
          EGrootboekrekening.AfboekenDebiteuren &&
        values.omschrijving === ''
      ) {
        errors.omschrijving = `Verplicht bij grootboek ${EGrootboekrekening.AfboekenDebiteuren}`;
      }

      return errors;
    },
    [grootboekrekeningen, grbRekID],
  );

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      if (facturen === null || grootboekrekeningen === null || grbRekID === null) {
        return;
      }

      actions.setSubmitting(true);

      const omschrijving =
        grootboekrekeningen.find((x) => x.ID === grbRekID)!.NaamEnum ===
        EGrootboekrekeningNaam.VooruitOntvangenDebiteuren
          ? 'Afgeboekt op Vooruitbetaald'
          : values.omschrijving === ''
          ? null
          : values.omschrijving;

      const facturenParams = props.factIDs.map((factID) => {
        const bedrag =
          props.factIDs.length === 1
            ? values.bedragAfboeken
            : facturen.find((x) => x.FactID === factID)!.Openstaand;
        return { factID: factID, bedrag };
      });

      const params = {
        facturen: facturenParams,
        grbRekID: values.grbRekID,
        omschrijving,
      };
      const checkData = await api.v2.boeking.checkAfboekenVerkoopfacturen(params);

      if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
        actions.setSubmitting(false);
        return;
      }

      if (
        (
          await checkStore.bevestigen({
            inhoud: <span>Bedrag afboeken?</span>,
          })
        ).type === EResultType.Annuleren
      ) {
        actions.setSubmitting(false);
        return;
      }

      await api.v2.boeking.afboekenVerkoopfacturen(params);

      actions.setSubmitting(false);
      props.onSuccess(null);
    },
    [grbRekID, grootboekrekeningen, props.factIDs, props.onSuccess, facturen],
  );

  // Suggestie-data
  const omschrijvingInputComponent = useMemo<
    React.ForwardRefRenderFunction<any, IInputComponentProps>
  >(
    () => (inputProps, ref) => (
      <textarea {...(inputProps as any)} ref={ref} className="form-control" rows={1} />
    ),
    [],
  );

  const omschrijvingTekstSuggestieVeldExposeRef = useRef<IExposeData>();

  if (
    initialValues === null ||
    facturen === null ||
    grootboekrekeningen === null ||
    grbRekID === null
  ) {
    return <span></span>;
  }

  return (
    <>
      <Dialoog index={props.dialoogIndex || 0}>
        <ModalHeader>
          <ModalTitle>Afboeken factuur</ModalTitle>
        </ModalHeader>

        <>
          <Formik<IFormikValues>
            ref={formikRef}
            initialValues={initialValues!}
            isInitialValid
            validate={handleValidate}
            onSubmit={handleSubmit}
            render={(formikProps) => {
              return (
                <>
                  <ModalBody>
                    <div className="form-group">
                      <div className="row">
                        <div className="col-6 mt-3">
                          <label>{veldnamen.grbRekID}</label>
                          <Field
                            name={nameOf<IFormikValues>('grbRekID')}
                            render={(fieldProps: FieldProps<IFormikValues>) => {
                              const { field, form } = fieldProps;

                              return (
                                <Combobox
                                  geselecteerd={grbRekID}
                                  onSelectieChange={(x) => {
                                    form.setFieldValue(field.name, x);
                                    setGrbRekID(x);
                                  }}
                                  opties={grootboekrekeningen.map((x) => {
                                    return {
                                      id: x.ID,
                                      label: x.Naam,
                                    };
                                  })}
                                />
                              );
                            }}
                          />
                        </div>

                        {grootboekrekeningen !== null &&
                          grootboekrekeningen.find((x) => x.ID === grbRekID)!.Nummer ===
                            EGrootboekrekening.AfboekenDebiteuren && (
                            <div className="col-12 mt-3">
                              <label>{veldnamen.omschrijving}</label>
                              <Field
                                name={nameOf<IFormikValues>('omschrijving')}
                                render={(fieldProps: FieldProps<IFormikValues>) => {
                                  const { field, form } = fieldProps;

                                  return (
                                    <div>
                                      <TekstSuggestieVeld
                                        waarde={field.value}
                                        onChange={(x) => form.setFieldValue(field.name, x)}
                                        suggestiesResolver={async (waarde) => {
                                          return omschrijvingSuggesties;
                                        }}
                                        inputComponent={omschrijvingInputComponent}
                                        onExpose={(expose) =>
                                          (omschrijvingTekstSuggestieVeldExposeRef.current = expose)
                                        }
                                      />
                                      <FormikVeldFout fieldProps={fieldProps} />
                                    </div>
                                  );
                                }}
                              />
                            </div>
                          )}

                        {bedragAfboeken !== null && (
                          <>
                            <div className="col-6 mt-3">
                              <label>{veldnamen.bedragAfboeken}</label>
                              <Field
                                name={nameOf<IFormikValues>('bedragAfboeken')}
                                render={(x: FieldProps<IFormikValues>) => {
                                  const { field, form } = x;
                                  return (
                                    <div>
                                      {props.factIDs.length === 1 ? (
                                        <>
                                          <BedragInput
                                            value={bedragAfboeken}
                                            onChange={(bedrag) => {
                                              x.form.setFieldValue(x.field.name, bedrag);
                                              setBedragAfboeken(bedrag);
                                            }}
                                          />
                                        </>
                                      ) : (
                                        <>
                                          <FormatteerBedrag bedrag={bedragAfboeken} />
                                        </>
                                      )}
                                    </div>
                                  );
                                }}
                              />
                            </div>
                            {/*
                              {factuur.KostenAanmaning !== 0 && (
                                <div className="col-8 mt-5">
                                  <VeldWeergave>
                                    <div className="d-flex ">
                                      Waarvan aanmaningskosten
                                      <MutatieBedrag bedrag={factuur.KostenAanmaning} />
                                    </div>
                                  </VeldWeergave>
                                </div>
                              )} */}

                            {facturen.length === 1 && (
                              <>
                                <div className="col-12 mt-3">
                                  <VeldWeergave>
                                    <div className="d-flex">
                                      <span className="mr-1">Saldo stornokosten</span>
                                      <FormatteerBedrag bedrag={saldiStornokosten!} />
                                    </div>
                                    <div className="d-flex">
                                      <span className="mr-1">Saldo aanmaningskosten</span>:{' '}
                                      <FormatteerBedrag bedrag={saldiAanmaningskosten!} />
                                    </div>
                                  </VeldWeergave>
                                </div>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </ModalBody>

                  <ModalFooter className="d-flex flex-row justify-content-start">
                    <button
                      className="btn btn-primary"
                      onClick={formikProps.submitForm}
                      style={{ width: 100 }}
                      disabled={!formikProps.isValid || formikProps.isSubmitting}
                    >
                      Ok
                    </button>
                    <button
                      className="btn btn-secondary"
                      onClick={props.onAnnuleren}
                      style={{ width: 100 }}
                      disabled={formikProps.isSubmitting}
                    >
                      Annuleren
                    </button>
                  </ModalFooter>
                </>
              );
            }}
          />
        </>
      </Dialoog>
    </>
  );
};

export default AfboekenDialoog;
