import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import api, { IPaginatiePositie } from '../../../../../api';
import { IOphalenToegevoegdePersonenVervangenLijstResultElement } from '../../../../../../../shared/src/api/v2/persoon/duplicaat';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../../models/IRemoteData';
import ASPTabel from '../../../../../components/tabel/ASPTabel';
import LoadingSpinner from '../../../../../components/Gedeeld/LoadingSpinner';
import { ASPKolom, EAspKolomBreedteType } from '../../../../../components/tabel/ASPTabel/types';
import PersoonVisualisatie from '../../../../../components/personalia/PersoonVisualisatie';
import { format } from 'date-fns';
import { ILeegComponentProps } from '../../../../../components/tabel/ASPTabel/Body';
import { RouteComponentProps } from 'react-router';
import useUrlState from '../../../../../core/useUrlState';
import UitgeklapteRij from './UitgeklapteRij';
import { withRouter } from 'react-router-dom';
import InfoKnop from '../../../../../components/InfoKnop';
import { GlobaleRendererContext } from '../../../../../one-off-components/GlobaleRenderer';
import PersooninfoDialoog from '../../../../../components/personalia/PersooninfoDialoog';

enum EKolom {
  VigerendePersoon,
  MeestRecenteDatum,
}

interface IProps extends RouteComponentProps {}

interface IUrlState {
  uitgeklapt: number[];
}

interface IData {
  rows: Record<number, Partial<IOphalenToegevoegdePersonenVervangenLijstResultElement>>;
  totaalAantal: number;
}

export interface IVervangenContext {
  onVigerendePersoonVervallen: (persID: number) => Promise<void>;
}

export const VervangenContext = React.createContext<IVervangenContext>(null as any);

const Vervangen = (props: IProps) => {
  const globaleRenderer = useContext(GlobaleRendererContext);
  const [urlState, setUrlState, setUrlStateSync] = useUrlState<IUrlState>(
    props,
    useMemo(
      () => ({
        uitgeklapt: [],
      }),
      [],
    ),
    'vervangenState',
  );

  const dataRef = useRef<IRemoteData<IData>>(createPendingRemoteData());
  const [dataState, setDataState] = useState(dataRef.current);
  const data = useMemo(() => dataState, [dataState]);
  const setData = useCallback((data: IRemoteData<IData>) => {
    dataRef.current = data;
    setDataState(data);
  }, []);

  const bepalenData = useCallback(async (paginatie: IPaginatiePositie, uitbreiden: boolean) => {
    const huidigeRows: Record<
      number,
      Partial<IOphalenToegevoegdePersonenVervangenLijstResultElement>
    > = uitbreiden ? dataRef.current.data?.rows ?? {} : {};

    // if (dataRef.current.state === ERemoteDataState.Ready) {
    //   const optimistischeRows = new Array(paginatie.aantal).fill(null).reduce(
    //     (acc, _, index) => ({
    //       ...acc,
    //       [paginatie.index + index]: createPendingRemoteData(),
    //     }),
    //     huidigeRows,
    //   );
    //   setData(
    //     createReadyRemoteData({
    //       rows: optimistischeRows,
    //       totaalAantal: dataRef.current.data!.totaalAantal,
    //     }),
    //   );
    //   huidigeRows = optimistischeRows;
    // }

    const result = await api.v2.persoon.duplicaat.ophalenToegevoegdePersonenVervangenLijst({
      selectieSchema: {
        velden: ['TOEGEVOEGD_AAN_PERS_ID', 'MEEST_RECENTE_DATUM'],
      },
      orderSchema: {
        orders: [
          {
            naam: 'MEEST_RECENTE_DATUM',
            richting: 'DESC',
          },
        ],
      },
      paginatie,
    });

    const rows = result.toevoegdePersonenVervangenLijst.reduce(
      (acc, curr, index) => ({
        ...acc,
        [paginatie.index + index]: curr,
      }),
      huidigeRows,
    );

    setData(
      createReadyRemoteData({
        rows,
        totaalAantal: result.totaalAantal,
      }),
    );
  }, []);

  useEffect(() => {
    bepalenData({ index: 0, aantal: 50 }, false);
  }, [bepalenData]);

  const handleExtraRijenAangevraagd = useCallback(
    async (paginatie: IPaginatiePositie) => {
      await bepalenData(paginatie, true);
    },
    [bepalenData],
  );

  const keyExtractor = useCallback(
    (row: Partial<IOphalenToegevoegdePersonenVervangenLijstResultElement>) =>
      row.ToegevoegdAan_PersID!,
    [],
  );

  const kolommen = useMemo<
    ASPKolom<EKolom, Partial<IOphalenToegevoegdePersonenVervangenLijstResultElement>>[]
  >(
    () => [
      {
        key: EKolom.VigerendePersoon,
        label: 'Vervangende persoon',
        breedteType: EAspKolomBreedteType.Flex,
        flex: 1,
        renderer: (rij) => (
          <div className="d-flex align-items-center">
            <PersoonVisualisatie persID={rij.ToegevoegdAan_PersID!} />
            <InfoKnop
              onClick={async () => {
                await globaleRenderer.render((renderProps) => (
                  <PersooninfoDialoog
                    persID={rij.ToegevoegdAan_PersID!}
                    open
                    onSuccess={() => renderProps.destroy()}
                    onAnnuleren={() => renderProps.destroy()}
                  />
                ));
              }}
              style={{
                marginLeft: 8,
                position: 'relative',
                top: -2,
              }}
            />
          </div>
        ),
      },
      {
        key: EKolom.MeestRecenteDatum,
        label: 'Meest recente datum',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 200,
        renderer: (rij) => format(new Date(rij.MeestRecenteDatum!), 'dd-MM-yyyy HH:mm'),
      },
    ],
    [],
  );

  const LeegComponent = useCallback((leegProps: ILeegComponentProps) => {
    return (
      <div
        style={{
          width: leegProps.width,
          height: leegProps.height,
        }}
        className="d-flex align-items-center justify-content-center"
      >
        <span>Er zijn geen vervangen personen.</span>
      </div>
    );
  }, []);

  const context = useMemo<IVervangenContext>(
    () => ({
      onVigerendePersoonVervallen: async (persID) => {
        setUrlStateSync(
          'uitgeklapt',
          urlState.uitgeklapt.filter((id) => id !== persID),
        );
        const nieuweData: IData = {
          totaalAantal: dataRef.current.data!.totaalAantal - 1,
          rows: Object.keys(dataRef.current.data!.rows)
            .map(Number)
            .reduce(
              (acc, idx) => {
                const row = dataRef.current.data!.rows[idx];
                if (row.ToegevoegdAan_PersID === persID) {
                  return {
                    ...acc,
                    skipped: acc.skipped + 1,
                    rows: acc.rows,
                  };
                }

                return {
                  ...acc,
                  rows: {
                    ...acc.rows,
                    [idx - acc.skipped]: row,
                  },
                };
              },
              {
                rows: {},
                skipped: 0,
              },
            ).rows,
        };
        setData(createReadyRemoteData(nieuweData));
      },
    }),
    [urlState.uitgeklapt],
  );

  if (data.state === ERemoteDataState.Pending) {
    return <LoadingSpinner />;
  }

  return (
    <VervangenContext.Provider value={context}>
      <ASPTabel
        keyExtractor={keyExtractor}
        rijen={data.data!.rows}
        kolommen={kolommen}
        totaalAantalRijen={data.data!.totaalAantal}
        onExtraRijenAangevraagd={handleExtraRijenAangevraagd}
        leegComponent={LeegComponent}
        uitgeklapt={urlState.uitgeklapt}
        onUitgeklaptChange={(uitgeklapt) => setUrlStateSync('uitgeklapt', uitgeklapt)}
        uitgeklapteRijComponent={UitgeklapteRij}
        uitgeklapteRijHoogte={200}
      />
    </VervangenContext.Provider>
  );
};

export default withRouter(Vervangen);
