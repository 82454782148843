import api from '../../index';
import {
  IOphalenEmailAccountsParams,
  IOphalenEmailDetailParams,
  IOphalenEmailDetailResult,
  IOphalenEmailsParams,
  IOphalenGeadresseerdenSuggestiesParams,
  IOphalenGeadresseerdenSuggestiesResult,
  IVerwijderenConceptResult,
  OphalenEmailAccountsResult,
  OphalenEmailsResult,
  IOphalenDienstenParams,
  IOphalenDienstenResult,
  IWijzigenDienstParams,
  IWijzigenDienstResult,
} from '../../../../../shared/src/api/v2/dienst/email/email';
import IConceptEmail from '../../../../../shared/src/models/email/IConceptEmail';
import ICheckData from '../../../../../shared/src/models/ICheckData';
// import EmailID from '../../../../../shared/src/models/email/EmailID';

const email = {
  ophalenEmailAccounts: async (
    data: IOphalenEmailAccountsParams,
  ): Promise<OphalenEmailAccountsResult> => {
    const response = await api.post('v2/dienst/email/ophalen-email-accounts', data);
    return response;
  },
  ophalenEmails: async (data: IOphalenEmailsParams): Promise<OphalenEmailsResult> => {
    return await api.post('v2/dienst/email/ophalen-emails', data);
  },
  conceptOpslaanEmail: async (data: IConceptEmail): Promise<IOphalenEmailDetailResult> => {
    return await api.post('v2/dienst/email/concept-opslaan-email', data);
  },
  ophalenEmailDetail: async (
    data: IOphalenEmailDetailParams,
  ): Promise<IOphalenEmailDetailResult> => {
    return await api.post('v2/dienst/email/ophalen-email-detail', data);
  },
  verwijderenConcept: async (emailBerID: number): Promise<IVerwijderenConceptResult> => {
    return await api.post('v2/dienst/email/verwijderen-concept', { emailBerID });
  },
  ophalenGeadresseerdenSuggesties: async (
    params: IOphalenGeadresseerdenSuggestiesParams,
  ): Promise<IOphalenGeadresseerdenSuggestiesResult> => {
    return await api.post('v2/dienst/email/ophalen-geadresseerden-suggesties', params);
  },
  ophalenDiensten: async (data: IOphalenDienstenParams): Promise<IOphalenDienstenResult> => {
    return await api.post('v2/dienst/email/ophalen-diensten', data);
  },
  checkWijzigenDienst: async (data: IWijzigenDienstParams): Promise<ICheckData> => {
    return await api.post('v2/dienst/email/check-wijzigen-dienst', data);
  },
  wijzigenDienst: async (data: IWijzigenDienstParams): Promise<IWijzigenDienstResult> => {
    return await api.post('v2/dienst/email/wijzigen-dienst', data);
  },
};

export default email;
