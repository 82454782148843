import React, { useCallback, useMemo, useRef } from 'react';
import { IWeergaveProps } from '../index';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IconInklappen, IconOpenInNew, IconUitklappen } from '../../../../../Icons';
import { Kleur } from '../../../../../../bedrijfslogica/constanten';
import api from '../../../../../../api';
import downloadUrl from '../../../../../../core/downloadUrl';

export interface ICommunicatieItemEmailData {
  Onderwerp: string;
  IsHTML: boolean;
  Inhoud: string;
  InhoudPreview: string | null;
  Bron_BestandID: number | null;
  EmailMapNaamEnum: string;
  WebURL: string | null;
}

interface IProps extends IWeergaveProps, RouteComponentProps {}

const EmailV2: React.FC<IProps> = (props) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const data = props.communicatieItem.data as ICommunicatieItemEmailData;
  const { Onderwerp, InhoudPreview, Bron_BestandID, EmailMapNaamEnum, WebURL } = data;

  const handleOpenWebURL = useCallback(async () => {
    const win = window.open(WebURL!, '_blank')!;
    win.focus();
  }, [WebURL]);

  return (
    <div className="flex-fill d-flex">
      <div
        className="flex-fill d-flex flex-column justify-content-center"
        style={{ maxWidth: '100%' }}
      >
        <div className="d-flex align-items-center mt-1">
          <div
            className="font-weight-bold"
            style={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              maxWidth: '100%',
            }}
            title={Onderwerp}
          >
            {EmailMapNaamEnum === 'DRAFTS' && (
              <span style={{ color: Kleur.Rood, marginRight: 5 }}>[Concept]</span>
            )}
            {Onderwerp}
          </div>
          <div className="flex-fill" />
          {WebURL !== null && (
            <button
              style={{ background: 0, border: 0, outline: 0 }}
              onClick={() => handleOpenWebURL()}
              title="Extern openen"
            >
              <IconOpenInNew style={{ fill: Kleur.Grijs, width: 17, height: 17 }} />
            </button>
          )}
        </div>
        <div style={{ maxWidth: '100%' }} className="d-flex flex-fill">
          {Bron_BestandID !== null ? (
            <a
              href="#"
              onClick={async (ev) => {
                ev.preventDefault();

                const bestandenResult = await api.v2.bestand.ophalenBestanden({
                  filterSchema: {
                    filters: [
                      {
                        naam: 'IDS',
                        data: [Bron_BestandID],
                      },
                    ],
                  },
                });
                const bestand = bestandenResult.bestanden[0];
                const url = bestand.url;
                await downloadUrl(url, bestand.Naam);
              }}
            >
              Download bron-bestand
            </a>
          ) : InhoudPreview == null ? (
            <span style={{ fontStyle: 'italic', fontSize: 13 }} className="text-muted">
              Geen preview beschikbaar
            </span>
          ) : (
            <span
              style={{
                fontSize: 13,
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                maxWidth: '100%',
              }}
              className="text-muted d-flex flex-fill"
            >
              {InhoudPreview}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default withRouter(EmailV2);
