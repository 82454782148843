import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { IOphalenIncassozakenResultElement } from '../../../../../shared/src/api/v2/debiteur/incassozaak';

import {
  DXTableCheckboxComponent,
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
  DXTableToggleCellComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../helpers/dxTableGrid';
import {
  Grid,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  DataTypeProvider,
  EditingState,
  IntegratedSorting,
  RowDetailState,
  SelectionState,
  SortingState,
  VirtualTable as VirtualTableBase,
} from '@devexpress/dx-react-grid';
import RelatieVisualisatie from '../../personalia/RelatieVisualisatie';
import { format } from 'date-fns';
import FormatteerBedrag from '../../MutatieBedrag';
import api from '../../../api';
import { EResultType } from '../../../stores/CheckStore';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../stores/RootStore';
import DetailComp from './DetailComp';
import nameOf from '../../../core/nameOf';
import { Kleur as EKleur } from '../../../bedrijfslogica/constanten';
import WijzigenDialoog from './WijzigenIncassozaakDialoog';
import { EHoedanigheid } from '../../personalia/RelatieSelectieDialoog';

const geenData = {
  noData: 'Geen incassozaken gevonden',
};

export interface IWijzigenIncassozaakDialoogState {
  ID: number;
}

interface IProps {
  incassozaken: IOphalenIncassozakenResultElement[] | null;
  selectie: number[];
  onSelectieChange: (selectie: number[]) => void;
  wijzigenIncassozaakDialoogState: IWijzigenIncassozaakDialoogState | null;
  onWijzigenIncassozaakDialoogStateChange: (state: IWijzigenIncassozaakDialoogState | null) => void;
  onVerversenAangevraagd: () => void;
  onRelatieClick?: (relID: number) => void;
  relID?: number;
}

const IncassozakenTabel: React.FC<IProps> = observer((props) => {
  const { checkStore } = useContext(RootStoreContext);
  const keyExtractor = useCallback((rij: IOphalenIncassozakenResultElement) => rij.ID, []);

  const kolommen = useMemo<TypedColumn<IOphalenIncassozakenResultElement>[]>(
    () =>
      [
        {
          name: 'Zaaknummer',
          title: 'Zaaknr.',
        },
        {
          name: 'Zaakdatum',
          title: 'Zaakdatum',
        },
        props.relID !== undefined
          ? null
          : {
              name: 'weergavenaam' as any,
              title: 'Relatie',
              getCellValue: (x: any) => {
                return x.weergavenaam;
              },
            },
        {
          name: 'Hoofdsom',
          title: 'Hoofdsom',
        },
        {
          name: '__incassodienst' as any,
          title: 'Dienst',
        },
        {
          name: 'Statusnaam',
          title: 'Status',
        },
        {
          name: 'Statusdatum',
          title: 'Statusdatum',
          getCellValue: (x: any) => {
            if (x.Statusdatum === null) {
              return <span></span>;
            }
            return x.Statusdatum;
          },
        },
        {
          name: '__resultaatstatus' as any,
          title: 'Resultaat',
        },
        {
          name: 'Afgehandeld',
          title: 'Afgeh.',
        },
        props.relID === undefined
          ? {
              name: 'Notities',
              title: 'Notities dossier',
            }
          : null,
        {
          name: 'ExterneReferentie',
          title: 'Ext.ref.',
        },
      ].filter((x) => x !== null) as TypedColumn<IOphalenIncassozakenResultElement>[],
    [props.relID],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IOphalenIncassozakenResultElement>[]>(
    () => [
      {
        columnName: 'Zaaknummer',
        width: 90,
      },
      {
        columnName: 'Zaakdatum',
        width: 125,
      },
      {
        columnName: 'weergavenaam' as any,
        width: 200,
      },
      {
        columnName: 'Hoofdsom',
        width: 115,
      },
      {
        columnName: 'Statusnaam',
        width: 100,
      },
      {
        columnName: 'Statusdatum',
        width: 125,
      },
      {
        columnName: 'Afgehandeld',
        width: 90,
      },
      {
        columnName: '__incassodienst' as any,
        width: 90,
      },
      {
        columnName: '__resultaatstatus' as any,
        width: 125,
      },
      {
        columnName: 'Notities',
        width: 225,
      },
      {
        columnName: 'ExterneReferentie',
        width: 175,
      },
    ],
    [],
  );

  const kolomExtensies: VirtualTableBase.ColumnExtension[] = useMemo(() => {
    return [
      // {
      //   columnName: `Hoofdsom`,
      //   align: 'right',
      // },
    ];
  }, []);

  const handleVerwijderen = useCallback(async (ID: number) => {
    // const params = { ID };
    // const checkData = await api.v2.debiteur.incassozaak.checkVerwijderenDossier(params);
    // if (
    //   (
    //     await checkStore.controleren({
    //       checkData,
    //     })
    //   ).type === EResultType.Annuleren
    // ) {
    //   return;
    // }
    if (
      (
        await checkStore.bevestigen({
          inhoud: `Wil je de incassozaak verwijderen?`,
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    await api.v2.debiteur.incassozaak.verwijderenDossier({ ID });

    props.onSelectieChange([]);
    props.onVerversenAangevraagd();

    // setUrlStateSync('selectie', []);

    // const params = { betRglIDs: [ID] };
    // const checkData = await api.v2.debiteur.betalingsregeling.checkVerwijderenBetalingsregelingen(
    //   params,
    // );
    // if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
    //   return;
    // }
    // if (
    //   (
    //     await checkStore.bevestigen({
    //       titel: `Wil je de betalingsregeling verwijderen?`,
    //     })
    //   ).type === EResultType.Annuleren
    // ) {
    //   return;
    // }
    // await api.v2.debiteur.betalingsregeling.verwijderenBetalingsregelingen(params);
    // props.onSelectieChange([]);
    // props.onVerversenAangevraagd();
  }, []);

  return (
    <>
      {props.incassozaken === null ? (
        <div />
      ) : (
        <GridStyleWrapper height={'calc(100vh - 100px)'}>
          <Grid rows={props.incassozaken} getRowId={keyExtractor} columns={kolommen}>
            {/* <DataTypeProvider
              for={['__relatie']}
              formatterComponent={(formatterProps) => {
                const row: IOphalenIncassozakenResultElement = formatterProps.row;
                return (
                  <a
                    href="#"
                    onClick={(ev) => {
                      ev.stopPropagation();
                      if (props.onRelatieClick !== undefined) {
                        props.onRelatieClick(row.RelID!);
                      }
                    }}
                  >
                    <RelatieVisualisatie relID={row.RelID!} options={{ geenLinkToepassen: true }} />
                  </a>
                );
              }}
            /> */}

            <DataTypeProvider
              for={['weergavenaam']}
              formatterComponent={(props) => {
                const row: IOphalenIncassozakenResultElement = props.row;
                return (
                  <RelatieVisualisatie
                    relID={row.RelID!}
                    weergaveNaam={row.weergavenaam}
                    relatieLinkBuilder={(hoedanigheid, relID) =>
                      `/${
                        hoedanigheid === EHoedanigheid.Klant ? 'klant' : 'leverancier'
                      }/${relID}/debiteur/incassozaken`
                    }
                  />
                );
              }}
            />

            <DataTypeProvider
              for={[nameOf<IOphalenIncassozakenResultElement>('Statusnaam')]}
              formatterComponent={(formatterProps) => {
                const rij: IOphalenIncassozakenResultElement = formatterProps.row;

                const statusKleur =
                  rij.StatusnaamEnum === 'CONCEPT'
                    ? EKleur.Blauw
                    : rij.StatusnaamEnum === 'UITHANDEN'
                    ? EKleur.Groen
                    : rij.StatusnaamEnum === 'INGETROKKEN'
                    ? EKleur.Oranje
                    : rij.StatusnaamEnum === 'GESLOTEN'
                    ? EKleur.Rood
                    : EKleur.Grijs;

                return <span style={{ color: statusKleur }}>{rij.Statusnaam}</span>;
              }}
            />

            <DataTypeProvider
              for={['__resultaatstatus']}
              formatterComponent={(formatterProps) => {
                const rij: IOphalenIncassozakenResultElement = formatterProps.row;

                if (rij.StatusnaamEnum !== 'GESLOTEN' && rij.StatusnaamEnum !== 'INGETROKKEN') {
                  return <span></span>;
                }

                const statusKleur =
                  rij.ResultaatStatusnaamEnum === 'NIET_OPGEGEVEN'
                    ? EKleur.Blauw
                    : rij.ResultaatStatusnaamEnum === 'NIET_VOLDAAN'
                    ? EKleur.Rood
                    : rij.ResultaatStatusnaamEnum === 'DEELS_VOLDAAN'
                    ? EKleur.Oranje
                    : rij.ResultaatStatusnaamEnum === 'GEHEEL_VOLDAAN'
                    ? EKleur.Groen
                    : EKleur.Grijs;

                return <span style={{ color: statusKleur }}>{rij.ResultaatStatusnaam}</span>;
              }}
            />

            <DataTypeProvider
              for={['__incassodienst']}
              formatterComponent={(formatterProps) => {
                const rij: IOphalenIncassozakenResultElement = formatterProps.row;

                return (
                  <RelatieVisualisatie
                    relID={rij.dienst!.relatie!.RelID}
                    options={{ geenLinkToepassen: false }}
                  />
                );
              }}
            />

            <DataTypeProvider
              for={[nameOf<IOphalenIncassozakenResultElement>('Zaakdatum')]}
              formatterComponent={(formatterProps) => {
                const rij: IOphalenIncassozakenResultElement = formatterProps.row;
                if (rij.Zaakdatum === null) {
                  return <span />;
                }
                return <span>{format(new Date(rij.Zaakdatum), 'dd-MM-yyyy')}</span>;
              }}
            />

            <DataTypeProvider
              for={[nameOf<IOphalenIncassozakenResultElement>('Statusdatum')]}
              formatterComponent={(formatterProps) => {
                const rij: IOphalenIncassozakenResultElement = formatterProps.row;
                if (rij.Statusdatum === null) {
                  return <span />;
                }
                return <span>{format(new Date(rij.Statusdatum), 'dd-MM-yyyy')}</span>;
              }}
            />

            <DataTypeProvider
              for={['Hoofdsom']}
              formatterComponent={(formatterProps) => {
                return <FormatteerBedrag bedrag={formatterProps.value} />;
              }}
            />

            <DataTypeProvider
              for={[nameOf<IOphalenIncassozakenResultElement>('Afgehandeld')]}
              formatterComponent={(formatterProps) => {
                return <span>{formatterProps.value ? 'Ja' : 'Nee'}</span>;
              }}
            />

            <EditingState
              onCommitChanges={async (changes) => {
                if (changes.deleted === undefined) {
                  return;
                }
                const deleted = changes.deleted;
                const id = deleted[deleted.length - 1] as number;

                const params = { ID: id };
                const checkData = await api.v2.debiteur.incassozaak.checkVerwijderenDossier(params);
                if (
                  (
                    await checkStore.controleren({
                      checkData,
                    })
                  ).type === EResultType.Annuleren
                ) {
                  return;
                }

                await handleVerwijderen(id);
              }}
              onEditingRowIdsChange={(rowIds) => {
                const id = rowIds[rowIds.length - 1] as number;
                props.onWijzigenIncassozaakDialoogStateChange({
                  ID: id,
                });
              }}
            />
            {/* <SortingState
              defaultSorting={[
                {
                  columnName: '__relatie',
                  direction: 'desc',
                },
              ]}
            /> */}
            <SortingState defaultSorting={[]} />
            <IntegratedSorting />
            <RowDetailState defaultExpandedRowIds={[]} />
            <VirtualTable messages={geenData} columnExtensions={kolomExtensies} />
            <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
            <TableEditColumn
              width={65}
              showEditCommand
              showDeleteCommand
              cellComponent={DXTableEditColumnCellComponent}
              commandComponent={DXTableEditColumnCommandComponent}
            />
            <TableHeaderRow showSortingControls />
            <TableRowDetail
              contentComponent={DetailComp}
              toggleCellComponent={DXTableToggleCellComponent}
            />
            <SelectionState
              selection={props.selectie}
              onSelectionChange={(x) => props.onSelectieChange(x as number[])}
            />
            <TableSelection cellComponent={DXTableCheckboxComponent} />
          </Grid>
        </GridStyleWrapper>
      )}
      {props.wijzigenIncassozaakDialoogState !== null && (
        <WijzigenDialoog
          open
          ID={props.wijzigenIncassozaakDialoogState.ID}
          onSuccess={async () => {
            props.onWijzigenIncassozaakDialoogStateChange(null);
            props.onVerversenAangevraagd();
          }}
          onAnnuleren={() => props.onWijzigenIncassozaakDialoogStateChange(null)}
        />
      )}
    </>
  );
});

export default IncassozakenTabel;
