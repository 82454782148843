import { SmsBericht } from '../index';
import BerichtItem from '../../../BerichtItem';
import { ERichting, IBerichtComponentProps } from '../../../ConversatieSectie';
import { useMemo } from 'react';
import React from 'react';

const BerichtComponent = (props: IBerichtComponentProps<SmsBericht>) => {
  const rootStyle = useMemo<React.CSSProperties>(() => {
    const min = 600;
    const minPercentage = 95;
    const max = 1900;
    const maxPercentage = 50;

    const currentWidth = Math.max(min, Math.min(max, props.containerWidth));
    const currentPercentage =
      ((currentWidth - min) / (max - min)) * (maxPercentage - minPercentage) + minPercentage;

    return {
      maxWidth: `${currentPercentage}%`,
      // backgroundColor: props.bericht.getRichting() === ERichting.Inkomend ? undefined : '#0e83fd',
      // color: Kleur.Wit,
    };
  }, [props.bericht.getRichting(), props.containerWidth]);

  return (
    <BerichtItem
      isInkomend={props.bericht.getRichting() === ERichting.Inkomend}
      datum={props.bericht.getDatum()}
      rootStyle={rootStyle}
    >
      {props.bericht.base.content}
    </BerichtItem>
  );
};

export default BerichtComponent;
