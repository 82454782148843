import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import IDialoogProps from '../../../core/IDialoogProps';
import { observer } from 'mobx-react-lite';
import Dialoog from '../../dialogen/Dialoog';
import LoadingSpinner from '../../Gedeeld/LoadingSpinner';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import { Field, FieldProps, Formik, FormikActions, FormikErrors, FormikProps } from 'formik';
import { ModalBody, ModalFooter } from 'react-bootstrap';
import nameOf from '../../../core/nameOf';
import {
  IOphalenOpdrachtenResultElementV2,
  IOphalenOpdrachtregelsResultElementV2,
} from '../../../../../shared/src/api/v2/transport/opdracht';
import api from '../../../api';
import MultiCombobox, { IKolom } from '../../formulier/MultiCombobox';
import { format } from 'date-fns';
import VinkVeld from '../../formulier/VinkVeld';
import { IOphalenDienstenResultElement } from '../../../../../shared/src/api/v2/dienst/transport';
import { EOpdrachtstatusTransport } from '../../../bedrijfslogica/enums';
import { RootStoreContext } from '../../../stores/RootStore';
import { EResultType } from '../../../stores/CheckStore';

interface IProps extends IDialoogProps<{}> {
  trsRegIDs: number[];
}

export interface IFormikValues {
  nieuweOpdracht: boolean;
  trsOpdID: number | null;
  dienstID: number | null;
}

export const veldnamen: Record<keyof IFormikValues, string> = {
  nieuweOpdracht: 'Toevoegen aan een nieuwe opdracht',
  trsOpdID: 'Selecteer bestaande opdracht waaraan toe te voegen',
  dienstID: 'Transportdienst',
};

const VerplaatsenOpdrachtregelsDialoog = (props: IProps) => {
  const { dialoogIndex, onAnnuleren, onSuccess, trsRegIDs } = props;
  const { checkStore } = useContext(RootStoreContext);

  const formikRef = useRef<Formik<IFormikValues>>(null);

  const [opdrachtregels, setOpdrachtregels] = useState<
    IOphalenOpdrachtregelsResultElementV2[] | null
  >(null);

  const [
    opdrachtVanRegels,
    setOpdrachtVanRegels,
  ] = useState<IOphalenOpdrachtenResultElementV2 | null>(null);

  const [opdrachtenWaarAanToeTeVoegen, setOpdrachtenWaarAanToeTeVoegen] = useState<
    IOphalenOpdrachtenResultElementV2[] | null
  >(null);

  const [diensten, setDiensten] = useState<IOphalenDienstenResultElement[] | null>(null);

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (opdrachtenWaarAanToeTeVoegen === null || diensten === null || opdrachtVanRegels === null) {
      return null;
    }

    const dienst = diensten.find((x) => x.ID === opdrachtVanRegels.dienst.ID);
    const dienstID = dienst !== undefined ? dienst.ID : diensten[0].ID;

    return {
      nieuweOpdracht: opdrachtenWaarAanToeTeVoegen.length === 0,
      trsOpdID:
        opdrachtenWaarAanToeTeVoegen.length !== 0 ? opdrachtenWaarAanToeTeVoegen[0].TrsOpdID : null,
      dienstID,
    };
  }, [opdrachtenWaarAanToeTeVoegen, diensten, opdrachtVanRegels]);

  useEffect(() => {
    (async () => {
      const result = await api.v2.dienst.transport.ophalenDiensten({
        filterSchema: { filters: [{ naam: 'IS_ACTIEF', data: true }] },
      });

      setDiensten(result.diensten);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const result = await api.v2.transport.opdracht.ophalenOpdrachtregelsV2({
        filterSchema: { filters: [{ naam: 'IDS', data: props.trsRegIDs }] },
      });

      setOpdrachtregels(result.regels);
    })();
  }, [props.trsRegIDs]);

  useEffect(() => {
    (async () => {
      if (opdrachtregels === null) {
        return;
      }

      const trsOpdID = opdrachtregels[0].TrsOpdID; // Alle regels hebben dezelfde opdracht

      const result = await api.v2.transport.opdracht.ophalenOpdrachtenV2({
        filterSchema: { filters: [{ naam: 'IDS', data: [trsOpdID] }] },
      });

      setOpdrachtVanRegels(result.opdrachten[0]);
    })();
  }, [opdrachtregels]);

  useEffect(() => {
    (async () => {
      if (opdrachtVanRegels === null || opdrachtregels === null) {
        return;
      }

      const relIDs = opdrachtregels.filter((x) => x.RelID !== null).map((x) => x.RelID);
      const result = await api.v2.transport.opdracht.ophalenOpdrachtenV2({
        filterSchema: {
          filters: [
            { naam: 'REL_IDS', data: relIDs },
            { naam: 'IDS', data: [opdrachtVanRegels.TrsOpdID], inverteren: true },
            { naam: 'STATUSSEN', data: [EOpdrachtstatusTransport.Planning] },
          ],
        },
      });

      setOpdrachtenWaarAanToeTeVoegen(result.opdrachten);
    })();
  }, [opdrachtVanRegels, opdrachtregels]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      const params = {
        trsRegIDs,
        trsOpdID: values.nieuweOpdracht ? null : values.trsOpdID,
        dienstID: values.nieuweOpdracht ? values.dienstID : null,
      };

      const checkData = await api.v2.transport.opdracht.checkVerplaatsenOpdrachtregels(params);
      if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
        actions.setSubmitting(false);
        return;
      }

      if (
        (
          await checkStore.bevestigen({
            inhoud: `Wil je de geselecteerde regels verplaatsen?`,
          })
        ).type === EResultType.Annuleren
      ) {
        return;
      }

      await api.v2.transport.opdracht.verplaatsenOpdrachtregels(params);

      actions.setSubmitting(false);

      onSuccess({});
    },
    [],
  );

  const opdrachtKolommen = useMemo<IKolom<IOphalenOpdrachtenResultElementV2>[]>(() => {
    return [
      {
        key: 'Opdrachtnummer',
        label: 'Opd.nr.',
        breedte: 90,
      },
      {
        key: 'Bezoekdatum',
        label: 'Bezoekdatum',
        formatFabriek: (rij) => {
          const datum =
            rij.Bezoekdatum !== null ? format(new Date(rij.Bezoekdatum), 'dd-MM-yyyy') : 'Z.s.m.';
          return datum;
        },
        breedte: 125,
      },
      {
        key: '__locatie' as any,
        label: 'Locatie',
        formatFabriek: (rij: IOphalenOpdrachtenResultElementV2) => {
          return (
            rij.locatie.Straatnaam +
            ' ' +
            rij.locatie.Huisnummer +
            (rij.locatie.Bisnummer !== null ? ' ' + rij.locatie.Bisnummer : '')
          );
        },
        breedte: 200,
      },
      // {
      //   key: 'RecordToegevoegd',
      //   label: 'Geregistreerd',
      //   breedte: 125,
      // },
    ];
  }, []);

  return (
    <Dialoog index={props.dialoogIndex || 0}>
      {initialValues === null ||
      opdrachtVanRegels === null ||
      diensten === null ||
      opdrachtenWaarAanToeTeVoegen === null ? (
        <div className="d-flex flex-fill align-items-center justify-content-center p-5">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          <ModalHeader>
            <ModalTitle>Verplaatsen opdrachtregels</ModalTitle>
          </ModalHeader>
          <Formik<IFormikValues>
            onSubmit={handleSubmit}
            initialValues={initialValues!}
            isInitialValid
            // validationSchema={validationSchema}
            render={(formikProps: FormikProps<IFormikValues>) => {
              const { submitForm, isSubmitting, values, isValid } = formikProps;
              return (
                <>
                  <ModalBody>
                    <div className="form-group">
                      <div className="row col-12">
                        <div className="col-12">
                          <Field
                            name={nameOf<IFormikValues>('nieuweOpdracht')}
                            render={(fieldProps: FieldProps<IFormikValues>) => {
                              const { field, form } = fieldProps;

                              return (
                                <>
                                  <div className="d-flex align-items-center">
                                    <VinkVeld
                                      aangevinkt={field.value}
                                      onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                    />
                                    <span className="ml-2">{veldnamen.nieuweOpdracht}</span>
                                  </div>
                                </>
                              );
                            }}
                          />
                        </div>

                        {values.nieuweOpdracht && (
                          <div className="col-12 mt-3">
                            <label>{veldnamen.dienstID}</label>
                            <Field
                              name={nameOf<IFormikValues>('dienstID')}
                              render={(fieldProps: FieldProps<IFormikValues>) => {
                                const { field, form } = fieldProps;

                                return (
                                  <>
                                    <MultiCombobox<number, IOphalenDienstenResultElement>
                                      sleutelExtractor={(x) => x.ID}
                                      representatieFabriek={(x) =>
                                        x.Naam ?? x.relatie!.organisatie!.Naam
                                      }
                                      waarde={field.value}
                                      onWaardeChange={(x) => form.setFieldValue(field.name, x)}
                                      opties={diensten}
                                      kolommen={[
                                        {
                                          key: '__naam' as any,
                                          label: 'Naam',
                                          breedte: 250,
                                          formatFabriek: (rij) => rij.relatie!.organisatie!.Naam,
                                        },
                                      ]}
                                    />
                                  </>
                                );
                              }}
                            />
                          </div>
                        )}

                        {!values.nieuweOpdracht && (
                          <div className="col-12 mt-3">
                            <label>{veldnamen.trsOpdID}</label>

                            <Field
                              name={nameOf<IFormikValues>('trsOpdID')}
                              render={(fieldProps: FieldProps<IFormikValues>) => {
                                const { field, form } = fieldProps;

                                if (opdrachtenWaarAanToeTeVoegen === null) {
                                  return <LoadingSpinner />;
                                }
                                return (
                                  <>
                                    <MultiCombobox<number, IOphalenOpdrachtenResultElementV2>
                                      sleutelExtractor={(x) => x.TrsOpdID}
                                      representatieFabriek={(x) => {
                                        return (
                                          x.Opdrachtnummer +
                                          ' - ' +
                                          (x.Bezoekdatum !== null
                                            ? format(new Date(x.Bezoekdatum), 'dd-MM-yyyy')
                                            : 'Zsm')
                                        );
                                      }}
                                      waarde={field.value}
                                      onWaardeChange={(x) => form.setFieldValue(field.name, x)}
                                      opties={opdrachtenWaarAanToeTeVoegen}
                                      kolommen={opdrachtKolommen!}
                                      options={{
                                        geenWaardeBericht:
                                          opdrachtenWaarAanToeTeVoegen.length !== 0
                                            ? 'Selecteer een opdracht'
                                            : 'Geen opdrachten beschikbaar',
                                      }}
                                      disabled={opdrachtenWaarAanToeTeVoegen.length === 0}
                                    />
                                  </>
                                );
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </ModalBody>
                  <ModalFooter className="d-flex justify-content-start">
                    <button
                      className="btn btn-primary"
                      onClick={submitForm}
                      disabled={
                        isSubmitting ||
                        !isValid ||
                        (!values.nieuweOpdracht && opdrachtenWaarAanToeTeVoegen.length === 0)
                      }
                      style={{
                        width: 100,
                      }}
                    >
                      Ok
                    </button>
                    <button
                      className="btn btn-secondary"
                      onClick={onAnnuleren}
                      style={{
                        width: 100,
                      }}
                    >
                      Annuleren
                    </button>
                  </ModalFooter>
                </>
              );
            }}
          />
        </>
      )}
    </Dialoog>
  );
};

export default VerplaatsenOpdrachtregelsDialoog;
