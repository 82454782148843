import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import MenuLayout from '../../../../components/MenuLayout';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import { IconToevoegen } from '../../../../components/Icons';
import ASPTabel, { VerwijderenRijConfirmatieFn } from '../../../../components/tabel/ASPTabel';
import {
  IVergelijkingspunt,
  VergelijkingspuntWaardeOpgeslagen,
} from '../../../../../../shared/src/api/v2/concurrent/vergelijkingspunt';
import { ASPKolom, EAspKolomBreedteType } from '../../../../components/tabel/ASPTabel/types';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
} from '../../../../models/IRemoteData';
import useBezig from '../../../../core/useBezig';
import api from '../../../../api';
import { GlobaleRendererContext } from '../../../../one-off-components/GlobaleRenderer';
import MuterenDialoog from './MuterenDialoog';
import { formatteerBoolean } from '../../../../helpers';
import FormatteerBedrag from '../../../../components/MutatieBedrag';
import { IOphalenTekstenResultElement } from '../../../../../../shared/src/api/v2/tekst';
import { IOphalenTalenResultElement } from '../../../../../../shared/src/api/v2/taal';
import { IOnRijenHerordenenParams } from '../../../../components/tabel/ASPTabel/Body';
import {
  IVergelijkingspuntXVoetnoot,
  IVoetnoot,
} from '../../../../../../shared/src/api/v2/concurrent/vergelijkingspunt/voetnoot';
import { RootStoreContext } from '../../../../stores/RootStore';
import { EResultType } from '../../../../stores/CheckStore';

interface IRow extends IVergelijkingspunt {
  waarde: VergelijkingspuntWaardeOpgeslagen;
}

interface IData {
  rijen: IRow[];
  tekstenBijTekstID: { [tekstID: number]: IOphalenTekstenResultElement[] };
  talenBijTaalID: { [taalID: number]: IOphalenTalenResultElement };
  voetnotenBijID: { [voetnootID: number]: IVoetnoot };
  vergelijkingsuntXVoetnotenBijVergelijkingspuntID: {
    [vergelijkingspuntID: number]: IVergelijkingspuntXVoetnoot[];
  };
}

type Kolom = 'naam' | 'naam_talen' | 'weergeven' | 'type' | 'waarde' | 'tekst_talen' | 'voetnoten';

const Vergelijkingspunten = (props: RouteComponentProps) => {
  const globaleRenderer = useContext(GlobaleRendererContext);
  const { checkStore } = useContext(RootStoreContext);

  const [data, setData] = useState<IRemoteData<IData>>(createPendingRemoteData());
  const { isBezig, scopeBezig } = useBezig();
  const ophalenData = useCallback(async () => {
    await scopeBezig(async () => {
      const [vergelijkingspuntenResult, talen] = await Promise.all([
        api.v2.concurrentie.vergelijkingspunt.ophalenVergelijkingspunten({
          orderSchema: {
            orders: [{ naam: 'SORT_NR', richting: 'ASC' }],
          },
        }),
        api.v2.taal.ophalen({}),
      ]);

      const vergelijkingspuntIDs = vergelijkingspuntenResult.vergelijkingspunten.map(
        (rij) => rij.ID,
      );

      const rijen = vergelijkingspuntenResult.vergelijkingspunten.map(
        (rij): IRow => {
          const waarde = JSON.parse(rij.Data);
          return {
            ...rij,
            waarde,
          };
        },
      );

      const tekstIDs = new Set([
        ...(rijen.map((rij) => rij.Naam_TekstID).filter((tekstID) => tekstID !== null) as number[]),
        ...(rijen
          .map((rij) => {
            if (rij.waarde.type === 'tekst') {
              return rij.waarde.tekstID;
            }
            return null;
          })
          .filter((tekstID) => tekstID !== null) as number[]),
      ]);

      const [vergelijkingsuntXVoetnotenResult, tekstenResult] = await Promise.all([
        vergelijkingspuntIDs.length === 0
          ? null
          : api.v2.concurrentie.vergelijkingspunt.voetnoot.ophalenVergelijkingspuntXVoetnoten({
              filterSchema: {
                filters: [
                  {
                    naam: 'VERGELIJKINGSPUNT_IDS',
                    data: vergelijkingspuntIDs,
                  },
                ],
              },
            }),
        tekstIDs.size === 0
          ? null
          : await api.v2.tekst.ophalenTekstenInAlleTalen({
              tekstIDs: Array.from(tekstIDs),
            }),
      ]);

      const voetnootIDs = new Set(
        vergelijkingsuntXVoetnotenResult?.items.map((x) => x.VergelijkingspuntVoetnootID) ?? [],
      );

      const voetnotenResult =
        voetnootIDs.size === 0
          ? null
          : await api.v2.concurrentie.vergelijkingspunt.voetnoot.ophalenVoetnoten({
              filterSchema: {
                filters: [
                  {
                    naam: 'IDS',
                    data: Array.from(voetnootIDs),
                  },
                ],
              },
            });

      const tekstenBijTekstID: { [tekstID: number]: IOphalenTekstenResultElement[] } =
        tekstenResult === null
          ? {}
          : tekstenResult.teksten.reduce((acc, tekst) => {
              const arr = acc[tekst.TekstID] ?? [];
              arr.push(tekst);
              acc[tekst.TekstID] = arr;
              return acc;
            }, {} as { [tekstID: number]: IOphalenTekstenResultElement[] });

      const talenBijTaalID: { [taalID: number]: IOphalenTalenResultElement } = talen.reduce(
        (acc, taal) => {
          acc[taal.TaalID] = taal;
          return acc;
        },
        {} as { [taalID: number]: IOphalenTalenResultElement },
      );

      const vergelijkingsuntXVoetnotenBijVergelijkingspuntID =
        vergelijkingsuntXVoetnotenResult === null
          ? {}
          : vergelijkingsuntXVoetnotenResult.items.reduce<
              IData['vergelijkingsuntXVoetnotenBijVergelijkingspuntID']
            >((acc, x) => {
              const arr = acc[x.VergelijkingspuntID] ?? [];
              arr.push(x);
              acc[x.VergelijkingspuntID] = arr;
              return acc;
            }, {});

      const voetnotenBijID =
        voetnotenResult === null
          ? {}
          : voetnotenResult.voetnoten.reduce<IData['voetnotenBijID']>((acc, curr) => {
              acc[curr.ID] = curr;
              return acc;
            }, {});

      const newData: IData = {
        rijen,
        tekstenBijTekstID,
        talenBijTaalID,
        vergelijkingsuntXVoetnotenBijVergelijkingspuntID,
        voetnotenBijID,
      };
      setData(createReadyRemoteData(newData));
    });
  }, [scopeBezig]);
  useEffect(() => {
    ophalenData();
  }, [ophalenData]);

  const handleToevoegen = useCallback(async () => {
    const result = await globaleRenderer.render<boolean>((renderProps) => (
      <MuterenDialoog
        id={null}
        open
        onSuccess={() => renderProps.destroy(true)}
        onAnnuleren={() => renderProps.destroy(false)}
      />
    ));
    if (!result) {
      return;
    }
    await ophalenData();
  }, [ophalenData]);

  const handleWijzigenRij = useCallback(
    async (rij: IVergelijkingspunt) => {
      const result = await globaleRenderer.render<boolean>((renderProps) => (
        <MuterenDialoog
          id={rij.ID}
          open
          onSuccess={() => renderProps.destroy(true)}
          onAnnuleren={() => renderProps.destroy(false)}
        />
      ));
      if (!result) {
        return;
      }
      await ophalenData();
    },
    [ophalenData],
  );

  const verwijderenRijConfirmatie = useCallback<VerwijderenRijConfirmatieFn<IVergelijkingspunt>>(
    async (rij, verwijderen) => {
      await scopeBezig(async () => {
        const checkData = await api.v2.concurrentie.vergelijkingspunt.checkVerwijderenVergelijkingspunt(
          {
            id: rij.ID,
          },
        );

        const checkResult = await checkStore.controleren({ checkData });
        if (checkResult.type === EResultType.Annuleren) {
          return;
        }
        if (!checkResult.heeftGebruikersinteractieGehad) {
          if (
            (await checkStore.bevestigen({ inhoud: 'Bevestig verwijderen vergelijkingspunt' }))
              .type === EResultType.Annuleren
          ) {
            return;
          }
        }

        await verwijderen();
      });
    },
    [],
  );

  const handleVerwijderenRij = useCallback(
    async (rij: IVergelijkingspunt) => {
      await scopeBezig(async () => {
        await api.v2.concurrentie.vergelijkingspunt.verwijderenVergelijkingspunt({
          id: rij.ID,
        });
        await ophalenData();
      });
    },
    [ophalenData],
  );

  const keyExtractor = useCallback((rij: IRow) => rij.ID, []);
  const kolommen = useMemo<ASPKolom<Kolom, IRow>[]>(() => {
    return [
      {
        key: 'naam',
        label: 'Naam',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 400,
        renderer: (rij) => rij.Naam,
      },
      {
        key: 'naam_talen',
        label: 'Naam talen',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 125,
        renderer: (rij) => {
          if (rij.Naam_TekstID === null) {
            return '- -';
          }

          const teksten = data.data!.tekstenBijTekstID[rij.Naam_TekstID];
          return teksten
            .map((tekst) => {
              const taal = data.data!.talenBijTaalID[tekst.TaalID];
              return taal.iso639_1.toUpperCase();
            })
            .join(', ');
        },
      },
      {
        key: 'weergeven',
        label: 'Weergeven',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 90,
        renderer: (rij) => formatteerBoolean(rij.Weergeven),
      },
      {
        key: 'type',
        label: 'Type',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 100,
        renderer: (rij) => {
          switch (rij.waarde.type) {
            case 'niet_van_toepassing':
              return 'n.v.t.';
            case 'getal':
              return 'Getal';
            case 'tekst':
              return 'Tekst';
            case 'ja_nee':
              return 'Ja / Nee';
            case 'bedrag':
              return 'Bedrag';
          }
        },
      },
      {
        key: 'waarde',
        label: 'Waarde',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 250,
        renderer: (rij) => {
          switch (rij.waarde.type) {
            case 'niet_van_toepassing':
              return '- -';
            case 'getal':
              return rij.waarde.waarde;
            case 'tekst':
              const teksten = data.data!.tekstenBijTekstID[rij.waarde.tekstID];
              const tekst = teksten.find((x) => x.Toepassen)!;
              return tekst.Tekst;
            case 'ja_nee':
              return formatteerBoolean(rij.waarde.waarde);
            case 'bedrag':
              return <FormatteerBedrag bedrag={rij.waarde.waarde} />;
          }
        },
      },
      {
        key: 'tekst_talen',
        label: 'Tekst talen',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 125,
        renderer: (rij) => {
          if (rij.waarde.type === 'tekst') {
            const teksten = data.data!.tekstenBijTekstID[rij.waarde.tekstID];
            return teksten
              .map((tekst) => {
                const taal = data.data!.talenBijTaalID[tekst.TaalID];
                return taal.iso639_1.toUpperCase();
              })
              .join(', ');
          }

          return '- -';
        },
      },
      {
        key: 'voetnoten',
        label: 'Voetnoten',
        breedteType: EAspKolomBreedteType.Flex,
        flex: 1,
        renderer: (rij) => {
          const vergelijkingspuntXVoetnoten =
            data.data!.vergelijkingsuntXVoetnotenBijVergelijkingspuntID[rij.ID] ?? [];
          if (vergelijkingspuntXVoetnoten.length === 0) {
            return '- -';
          }

          const voetnoten = vergelijkingspuntXVoetnoten.map(
            (x) => data.data!.voetnotenBijID[x.VergelijkingspuntVoetnootID],
          );

          return voetnoten.map((voetnoot) => voetnoot.Naam).join(', ');
        },
      },
    ];
  }, [data]);

  const handleRijenHerordenen = useCallback(
    async (params: IOnRijenHerordenenParams<IRow>) => {
      const { oud, nieuw } = params;
      const nieuweRijen = [...data.data!.rijen];
      const rij = nieuweRijen.splice(oud.index, 1)[0];
      nieuweRijen.splice(nieuw.index, 0, rij);
      setData(
        createReadyRemoteData({
          ...data.data!,
          rijen: nieuweRijen,
        }),
      );

      await api.v2.concurrentie.vergelijkingspunt.herordenenVergelijkingspunten({
        vanID: oud.rij.ID,
        naarID: nieuw.rij.ID,
      });
    },
    [data],
  );

  return (
    <MenuLayout
      menu={
        <div className="d-flex align-items-center">
          <button
            className="btn btn-sm btn-light d-flex align-items-center"
            style={{
              border: `1px solid ${Kleur.LichtGrijs}`,
            }}
            onClick={handleToevoegen}
          >
            <IconToevoegen style={{ width: 18, height: 18, fill: Kleur.Grijs }} />
            <span className="ml-2">Toevoegen</span>
          </button>
        </div>
      }
      body={
        <ASPTabel
          keyExtractor={keyExtractor}
          kolommen={kolommen}
          rijen={data.data?.rijen ?? []}
          isBezig={isBezig}
          onWijzigenRij={handleWijzigenRij}
          verwijderenRijConfirmatie={verwijderenRijConfirmatie}
          onVerwijderenRij={handleVerwijderenRij}
          onRijenHerordenen={handleRijenHerordenen}
        />
      }
    />
  );
};

export default Vergelijkingspunten;
