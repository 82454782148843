import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import IDialoogProps from '../../../../core/IDialoogProps';
import Dialoog from '../../../../components/dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import * as Yup from 'yup';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../../stores/RootStore';
import nameof from '../../../../core/nameOf';
import api from '../../../../api';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import { EResultType } from '../../../../stores/CheckStore';
import { IOphalenAfbeeldingenResultElement } from '../../../../../../shared/src/api/v2/website/afbeelding';

interface IDialoogResult {}

interface IProps extends IDialoogProps<IDialoogResult> {
  id: number;
}

interface IFormikValues {
  naamEnum: string;
  naam: string | null;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  naamEnum: 'NaamEnum',
  naam: 'Naam',
};

const WijzigenDialoog: React.FC<IProps> = observer((props) => {
  const { children, dialoogIndex, onAnnuleren, onSuccess } = props;
  const { checkStore } = useContext(RootStoreContext);
  const formikRef = useRef<Formik<IFormikValues>>(null);

  const [afbeelding, setAfbeelding] = useState<IOphalenAfbeeldingenResultElement | null>(null);

  useEffect(() => {
    (async () => {
      const result = await api.v2.extern.afbeelding.ophalenAfbeeldingen({
        filterSchema: { filters: [{ naam: 'IDS', data: [props.id] }] },
      });
      setAfbeelding(result.afbeeldingen[0]);
    })();
  }, [props.id]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);
      const params = {
        id: props.id,
        naam: values.naam,
        naamEnum: values.naamEnum,
        bestand_ID: afbeelding!.bestand?.ID ?? null,
      };

      const checkData = await api.v2.extern.afbeelding.checkWijzigenAfbeelding(params);
      const checkResult = await checkStore.controleren({
        checkData,
      });
      if (checkResult.type === EResultType.Annuleren) {
        actions.setSubmitting(false);
        return;
      }

      await api.v2.extern.afbeelding.wijzigenAfbeelding(params);

      props.onSuccess({});
      actions.setSubmitting(false);
    },
    [props.id, afbeelding],
  );

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (afbeelding === null) {
      return null;
    }

    return {
      naamEnum: afbeelding.NaamEnum,
      naam: afbeelding.Naam,
    };
  }, [afbeelding]);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        naamEnum: Yup.string().required(),
      }),
    [],
  );

  return (
    <Dialoog index={dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Wijzigen afbeelding</ModalTitle>
      </ModalHeader>
      {initialValues !== null && (
        <Formik<IFormikValues>
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize
          isInitialValid
          render={(formikProps: FormikProps<IFormikValues>) => {
            const { submitForm, isValid, isSubmitting } = formikProps;

            return (
              <>
                <ModalBody>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-12 mt-3">
                        <label>Naam</label>
                        <Field
                          name={nameof<IFormikValues>('naam')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field } = fieldProps;

                            return (
                              <>
                                <input type="text" className="form-control" {...field} />
                                {/* <FormikVeldFout fieldProps={fieldProps} /> */}
                              </>
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <label>NaamEnum</label>
                        <Field
                          name={nameof<IFormikValues>('naamEnum')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field } = fieldProps;

                            return (
                              <>
                                <input type="text" className="form-control" {...field} />
                                {/* <FormikVeldFout fieldProps={fieldProps} /> */}
                              </>
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </ModalBody>

                <ModalFooter className="d-flex flex-row justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    style={{ width: 100 }}
                    disabled={!isValid || isSubmitting}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={onAnnuleren}
                    style={{ width: 100 }}
                    disabled={isSubmitting}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Dialoog>
  );
});

export default WijzigenDialoog;
