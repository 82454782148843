import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import IDialoogProps from '../../../core/IDialoogProps';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { Field, FieldProps, Formik, FormikActions, FormikErrors, FormikProps } from 'formik';
import Combobox, { IOptie } from '../../../components/formulier/Combobox';
import { RootStoreContext } from '../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import api from '../../../api';
import * as Yup from 'yup';
import FormikVeldFout from '../../../components/formulier/FormikVeldFout';
import {
  IOphalenOpdrachtenResultElement,
  IOphalenOpdrachtregelsResultElement,
} from '../../../../../shared/src/api/v2/transport/opdracht';
import Dialoog from '../../../components/dialogen/Dialoog';
import LoadingSpinner from '../../../components/Gedeeld/LoadingSpinner';
import nameof from '../../../core/nameOf';
import VinkVeld from '../../formulier/VinkVeld';
import { EResultType } from '../../../stores/CheckStore';
import { IOphalenVoorraadinfoResultElement } from '../../../../../shared/src/api/v2/voorraad';
import GegevensLayout from '../../layout/GegevensLayout';

interface IProps extends IDialoogProps<{}> {
  trsRegID: number;
}

interface IFormikValues {}

const veldnamen: Record<keyof IFormikValues, string> = {};

export interface IRegel extends IOphalenOpdrachtregelsResultElement {
  opdracht: IOphalenOpdrachtenResultElement;
}

const ProductreserveringDialoog = observer((props: IProps) => {
  const { onAnnuleren, onSuccess, open } = props;
  const { checkStore } = useContext(RootStoreContext);
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const [regel, setRegel] = useState<IRegel | null>(null);
  const [voorraadInfo, setVoorraadInfo] = useState<IOphalenVoorraadinfoResultElement | null>(null);

  useEffect(() => {
    (async () => {
      const regelsResult = (
        await api.v2.transport.opdracht.ophalenOpdrachtregels({
          filterSchema: { filters: [{ naam: 'IDS', data: [props.trsRegID] }] },
        })
      ).regels;

      const trsOpdIDs = regelsResult.map((x) => x.TrsOpdID);

      const opdrachtenResult = (
        await api.v2.transport.opdracht.ophalenOpdrachten({
          filterSchema: { filters: [{ naam: 'IDS', data: trsOpdIDs }] },
        })
      ).opdrachten;

      const regels = regelsResult.map((x) => {
        const opdracht = opdrachtenResult.find((o) => o.TrsOpdID === x.TrsOpdID)!;
        return { ...x, opdracht };
      });

      setRegel(regels[0]);
    })();
  }, [props.trsRegID]);

  const ophalenVoorraadInfo = useCallback(async () => {
    if (regel === null) {
      return;
    }
    const voorraadInfoResult = await api.v2.voorraad.ophalenVoorraadinfo({
      magID: regel.opdracht.magazijn.MagID,
      typeIDs: [regel.type!.TypeID],
    });

    setVoorraadInfo(voorraadInfoResult.voorraad[0]);
  }, [regel]);

  useEffect(() => {
    ophalenVoorraadInfo();
  }, [ophalenVoorraadInfo]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      const params = {
        trsRegID: props.trsRegID,
        gereserveerd: true,
      };

      const checkData = await api.v2.transport.opdracht.checkWijzigenOpdrachtregelVoorraad(params);
      if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
        return;
      }

      await api.v2.transport.opdracht.wijzigenOpdrachtregelVoorraad(params);

      onSuccess({});
      actions.setSubmitting(false);
    },
    [props.trsRegID, onSuccess],
  );

  const initieleWaarden = useMemo<IFormikValues | null>(() => {
    if (regel === null || regel.type === null) {
      return null;
    }

    return {};
  }, [regel]);

  // const validationSchema = useMemo(
  //   () =>
  //     Yup.object().shape({
  //       gereserveerd: Yup.string().required(),
  //     }),
  //   [],
  // );

  return (
    <Dialoog index={props.dialoogIndex || 0}>
      {regel === null || voorraadInfo === null ? (
        <LoadingSpinner />
      ) : (
        <>
          <ModalHeader>
            <ModalTitle>Reservering maken</ModalTitle>
          </ModalHeader>
          <Formik<IFormikValues>
            onSubmit={handleSubmit}
            initialValues={initieleWaarden!}
            isInitialValid
            // validationSchema={validationSchema}
            render={(formikProps: FormikProps<IFormikValues>) => {
              const { submitForm, isSubmitting, values, isValid } = formikProps;
              return (
                <>
                  <ModalBody>
                    <div className="form-group">
                      <div>
                        <GegevensLayout
                          gegevens={[
                            {
                              label: 'Aantal Voorraad',
                              waarde: <span>{voorraadInfo!.aantalVoorraad}</span>,
                            },
                            {
                              label: 'Aantal Gereserveerd Planning',
                              waarde: <span>{voorraadInfo!.aantalPlanningGereserveerd}</span>,
                            },
                            {
                              label: 'Aantal Gereserveerd Uitstaand',
                              waarde: <span>{voorraadInfo!.aantalUitstaandGereserveerd}</span>,
                            },

                            {
                              label: 'Aantal inkoop',
                              waarde: <span>{voorraadInfo!.aantalInkoop}</span>,
                            },
                            null,
                            {
                              label: 'Aantal vrij',
                              waarde: <span>{voorraadInfo!.aantalVrij}</span>,
                            },
                          ]}
                        />
                      </div>
                      {/* <div>{JSON.stringify(voorraadInfo)}</div> */}
                    </div>
                  </ModalBody>
                  <ModalFooter className="d-flex justify-content-start">
                    <button
                      className="btn btn-primary"
                      onClick={submitForm}
                      disabled={isSubmitting || !isValid}
                      style={{ width: 100 }}
                    >
                      Ok
                    </button>
                    <button
                      className="btn btn-secondary"
                      onClick={onAnnuleren}
                      style={{ width: 100 }}
                    >
                      Annuleren
                    </button>
                  </ModalFooter>
                </>
              );
            }}
          />
        </>
      )}
    </Dialoog>
  );
});

export default ProductreserveringDialoog;
