import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Container, Root } from './style';
import Tabblad from '../../components/layout/Tabblad';
import MenuHandle, { ECommunicatieTabblad } from './MenuHandle';
import WhatsappTabblad from './communicatieTabbladen/WhatsappTabblad';
import { Rnd } from 'react-rnd';
import SmsTabblad from './communicatieTabbladen/SmsTabblad';
import { Kleur } from '../../bedrijfslogica/constanten';
import EmailTabblad from './communicatieTabbladen/EmailTabblad';
import {
  IFormikValues as IEmailWerkbladFormikValues,
  IOpstellenFormulierRef,
} from '../../components/communicatie/EmailWerkblad/OpstellenFormulier';
import TelefoonTabblad, { ETelefoonTabblad } from './communicatieTabbladen/TelefoonTabblad';
import { observer } from 'mobx-react-lite';
import { ERichting } from '../../components/communicatie/TelefoonHistorie';
import { ZoekenFocus } from '../../components/communicatie/WhatsAppWerkbladV2/helpers';
import { EWhatsappTabblad } from '../../components/communicatie/WhatsAppWerkbladV2/types';

const communicatieTabbladen: Record<
  ECommunicatieTabblad,
  React.ForwardRefExoticComponent<IOpstellenFormulierRef | any>
> = {
  [ECommunicatieTabblad.Telefoon]: TelefoonTabblad,
  [ECommunicatieTabblad.Whatsapp]: WhatsappTabblad,
  [ECommunicatieTabblad.Sms]: SmsTabblad,
  [ECommunicatieTabblad.Email]: EmailTabblad,
};

export interface ITelefoonContext {
  tabblad: ETelefoonTabblad;
  onTabbladChange: (tabblad: ETelefoonTabblad) => void;
  richting: ERichting;
  onRichtingChange: (richting: ERichting) => void;
  vanGebruiker: 'IEDEREEN' | number | null;
  laatsteVanGebruikerSelectie: 'IEDEREEN' | number | null;
  onVanGebruikerChange: (vanGebruiker: 'IEDEREEN' | number | null) => void;
  telefoonnummer: string | null;
  onTelefoonnumerChange: (telefoonnummerInput: string | null) => void;
}

export interface IWhatsappContext {
  tabblad: EWhatsappTabblad;
  onTabbladChange: (tabblad: EWhatsappTabblad) => void;
  chatSessieID: number | null;
  onChatSessieIDChange: (id: number | null) => void;
  zoekterm: string;
  onZoektermChange: (zoekterm: string) => void;
  zoekenFocus: ZoekenFocus | null;
  onZoekenFocusChange: (zoekenFocus: ZoekenFocus | null) => void;
}

export interface ISmsContext {
  telefoonnummer: string | null;
  onTelefoonnummerChange: (telefoonnummer: string | null) => void;
  // Deze kan worden gevuld om bij het starten van een nieuw contact de juiste persID toe te wijzen
  persID?: number;
  bericht: string;
  onBerichtChange: (bericht: string) => void;
}

export interface IEmailContext {
  formulier: IEmailWerkbladFormikValues | null;
  onFormulierChange: (formulier: IEmailWerkbladFormikValues | null) => void;
}

export interface ICommunicatieOverlayContext {
  width: number;
  height: number;
  x: number;
  y: number;
  onRequestSize: (width: number, height: number) => void;
  telefoonContext: ITelefoonContext;
  whatsappContext: IWhatsappContext;
  smsContext: ISmsContext;
  emailContext: IEmailContext;
}

export const CommunicatieOverlayContext = React.createContext<ICommunicatieOverlayContext>(
  null as any,
);

const localstorageStateKey = 'COMMUNICATIE_OVERLAY_STATE';

export interface ICommunicatieOverlayState {
  geselecteerdTabblad: ECommunicatieTabblad | null;
  width: number;
  height: number;
  x: number;
  y: number;
  telefoonContext: Partial<ITelefoonContext>;
  whatsappContext: Partial<IWhatsappContext>;
  smsContext: Partial<ISmsContext>;
  emailContext: Partial<IEmailContext>;
}

// const menuHandleWidth = 49;
const menuHandleHeight = 35;
// const geslotenMaxWidth = 250;
const geslotenMaxWidth = 400;

export let setCommunicatieOverlayState: Dispatch<
  SetStateAction<ICommunicatieOverlayState>
> | null = null;

interface IProps {}

const CommunicatieOverlay: React.FC<IProps> = observer((props) => {
  const isInitialRender = useRef(true);
  const rndRef = useRef<Rnd>(null);
  const tabbladRef = useRef<IOpstellenFormulierRef | any>(null);

  const [documentDimensions, setDocumentDimensions] = useState({
    width: document.body.clientWidth,
    height: document.body.clientHeight,
  });
  useEffect(() => {
    const listener = (ev: UIEvent) => {
      setDocumentDimensions({
        width: document.body.clientWidth,
        height: document.body.clientHeight,
      });
    };
    window.addEventListener('resize', listener);
    return () => window.removeEventListener('resize', listener);
  }, []);
  const [isDragging, setIsDragging] = useState(false);

  const initialState = useMemo<ICommunicatieOverlayState>(() => {
    let state: ICommunicatieOverlayState;
    const localstorageData = localStorage.getItem(localstorageStateKey);
    if (localstorageData !== null) {
      state = JSON.parse(localstorageData);
    } else {
      state = {
        geselecteerdTabblad: null,
        width: 1150,
        height: 750,
        x: 0,
        y: documentDimensions.height - 650,
        telefoonContext: {
          tabblad: ETelefoonTabblad.Actueel,
          richting: ERichting.Beide,
          vanGebruiker: 'IEDEREEN',
          laatsteVanGebruikerSelectie: null,
          telefoonnummer: null,
        },
        whatsappContext: {
          tabblad: EWhatsappTabblad.Nieuw,
          chatSessieID: null,
          zoekterm: '',
        },
        smsContext: {
          telefoonnummer: null,
          bericht: '',
        },
        emailContext: {
          formulier: null,
        },
      };
    }
    return state;
  }, []);
  const [state, setState] = useState(initialState);
  const setStateHooked = useCallback(
    (stateOrSetter) => {
      const hook = (
        prevState: ICommunicatieOverlayState,
        newState: ICommunicatieOverlayState,
      ): ICommunicatieOverlayState => {
        const x = newState.x;
        let y = newState.y;
        if (newState.geselecteerdTabblad === null && prevState.geselecteerdTabblad !== null) {
          // Wordt gesloten
          // x = documentDimensions.width;
          y = documentDimensions.height - menuHandleHeight;
        } else if (
          newState.geselecteerdTabblad !== null &&
          prevState.geselecteerdTabblad === null
        ) {
          // Wordt geopend
          // x = documentDimensions.width - state.width;
          y = documentDimensions.height - state.height - menuHandleHeight;
        }

        switch (newState.geselecteerdTabblad) {
          case ECommunicatieTabblad.Email: {
            const formulierRef = tabbladRef.current as IOpstellenFormulierRef | null;
            if (formulierRef !== null && newState.emailContext.formulier) {
              formulierRef.setFormulier(newState.emailContext.formulier);
            }
            break;
          }
        }

        return {
          ...newState,
          x,
          y,
        };
      };

      // Is it a setter?
      if (typeof stateOrSetter === 'function') {
        return setState((prevState) => hook(prevState, stateOrSetter(prevState)));
      }
      const newState = stateOrSetter as ICommunicatieOverlayState;
      return setState(hook(state, newState));
    },
    [state, setState],
  );
  setCommunicatieOverlayState = setStateHooked;
  useEffect(() => {
    if (isInitialRender.current) {
      return;
    }
    localStorage.setItem(localstorageStateKey, JSON.stringify(state));
  }, [state]);
  // Boundscheck
  useEffect(() => {
    if (rndRef.current === null) {
      return;
    }

    let isPositieMogelijkBinnenBounds = true;
    const s = { ...state };
    // De laagste Y verlaatsen zodat het menu tot bovenin de pagina kan verschuiven als er geen tab open is
    // const lowestX = 0; // s.geselecteerdTabblad !== null ? 0 : menuHandleWidth - s.width;
    // const lowestY = 0; // s.geselecteerdTabblad !== null ? 0 : menuHandleHeight - s.height;
    if (s.geselecteerdTabblad === null) {
      // s.x = documentDimensions.width;
      s.y = documentDimensions.height - menuHandleHeight;
    } else {
      const isBenedenOutOfBounds = s.y + menuHandleHeight > documentDimensions.height;
      const isBovenOutOfBounds = s.y < menuHandleHeight;
      if (isBenedenOutOfBounds && isBovenOutOfBounds) {
        isPositieMogelijkBinnenBounds = false;
      } else if (isBenedenOutOfBounds) {
        s.y = documentDimensions.height - menuHandleHeight;
      } else if (isBovenOutOfBounds) {
        s.y = 0;
      }
    }
    const isRechtsOutOfBounds =
      s.x + (s.geselecteerdTabblad === null ? geslotenMaxWidth : s.width) >=
      documentDimensions.width;
    const isLinksOutOfBounds = s.x < 0;
    if (isRechtsOutOfBounds && isLinksOutOfBounds) {
      isPositieMogelijkBinnenBounds = false;
    } else if (isRechtsOutOfBounds) {
      s.x =
        documentDimensions.width - (s.geselecteerdTabblad === null ? geslotenMaxWidth : s.width);
    } else if (isLinksOutOfBounds) {
      s.x = 0;
    }

    const isPositieOfGrootteGemuteerd =
      state.x !== s.x || state.y !== s.y || state.width !== s.width || state.height !== s.height;
    if (isPositieMogelijkBinnenBounds && isPositieOfGrootteGemuteerd) {
      setState((prevState) => ({
        ...prevState,
        x: s.x,
        y: s.y,
        width: s.width,
        height: s.height,
      }));
      //
      // if (state.x !== s.x || state.y !== s.y) {
      //   rndRef.current!.updatePosition({
      //     x: s.x,
      //     y: s.y,
      //   });
      // }
      // if (state.width !== s.width || state.height !== s.height) {
      //   rndRef.current!.updateSize({
      //     width: s.width,
      //     height: s.height,
      //   });
      // }
    }
    // rndRef.current!.updatePosition({
    //   x: s.x,
    //   y: s.y,
    // });
    // rndRef.current!.updateSize({
    //   width: s.width,
    //   height: s.height,
    // });
  }, [state.x, state.y, state.geselecteerdTabblad, documentDimensions]);

  const Tabblad = useMemo(() => {
    if (state.geselecteerdTabblad === null) {
      return null;
    }
    return communicatieTabbladen[state.geselecteerdTabblad];
  }, [state.geselecteerdTabblad]);

  const contextValue = useMemo<ICommunicatieOverlayContext>(() => {
    return {
      width: state.width,
      height: state.height,
      x: state.x,
      y: state.y,
      onRequestSize: (width, height) => {
        setState((prevState) => ({
          ...prevState,
          width,
          height,
        }));
      },
      telefoonContext: {
        ...(state.telefoonContext as ITelefoonContext),
        onTabbladChange: (tabblad) =>
          setState((x) => {
            return {
              ...x,
              telefoonContext: {
                ...x.telefoonContext,
                tabblad,
              },
            };
          }),
        onRichtingChange: (richting) =>
          setState((x) => {
            return {
              ...x,
              telefoonContext: {
                ...x.telefoonContext,
                richting,
              },
            };
          }),
        onVanGebruikerChange: (vanGebruiker) =>
          setState((x) => {
            return {
              ...x,
              telefoonContext: {
                ...x.telefoonContext,
                vanGebruiker,
                laatsteVanGebruikerSelectie:
                  vanGebruiker === x.telefoonContext.vanGebruiker
                    ? x.telefoonContext.laatsteVanGebruikerSelectie
                    : x.telefoonContext.vanGebruiker,
              },
            };
          }),
        onTelefoonnumerChange: (telefoonnummerInput) =>
          setState((x) => ({
            ...x,
            telefoonContext: {
              ...x.telefoonContext,
              telefoonnummer: telefoonnummerInput,
            },
          })),
      },
      whatsappContext: {
        ...(state.whatsappContext as IWhatsappContext),
        onTabbladChange: (tabblad) =>
          setState((x) => {
            return {
              ...x,
              whatsappContext: {
                ...x.whatsappContext,
                tabblad,
              },
            };
          }),
        onChatSessieIDChange: (id) =>
          setState((x) => ({
            ...x,
            whatsappContext: {
              ...x.whatsappContext,
              chatSessieID: id,
            },
          })),
        onZoektermChange: (zoekterm) => {
          setState((x) => ({
            ...x,
            whatsappContext: {
              ...x.whatsappContext,
              zoekterm,
            },
          }));
        },
        onZoekenFocusChange: (zoekenFocus) => {
          setState((x) => ({
            ...x,
            whatsappContext: {
              ...x.whatsappContext,
              zoekenFocus,
            },
          }));
        },
      },
      smsContext: {
        ...(state.smsContext as ISmsContext),
        onTelefoonnummerChange: (telefoonnummer) =>
          setState((x) => ({
            ...x,
            smsContext: {
              ...x.smsContext,
              telefoonnummer,
            },
          })),
        onBerichtChange: (bericht) =>
          setState((x) => ({
            ...x,
            smsContext: {
              ...x.smsContext,
              bericht,
            },
          })),
      },
      emailContext: {
        ...(state.emailContext as IEmailContext),
        onFormulierChange: (formulier) => {
          setState((x) => ({
            ...x,
            emailContext: {
              ...x.emailContext,
              formulier,
            },
          }));
        },
      },
    };
  }, [state]);

  useEffect(() => {
    isInitialRender.current = false;
  }, []);

  if (Tabblad === null) {
    return null;
  }

  return (
    <CommunicatieOverlayContext.Provider value={contextValue}>
      <Rnd
        ref={rndRef}
        position={{
          x: state.x,
          y: state.y,
        }}
        size={{
          width: state.width,
          height: state.height,
        }}
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
        // enableUserSelectHack
        enableResizing={state.geselecteerdTabblad !== null}
        minWidth={550}
        minHeight={600}
        dragHandleClassName="rnd-drag-handle"
        onResize={(ev, dir, ref, delta, position) => {
          setState((state) => ({
            ...state,
            width: ref.offsetWidth,
            height: ref.offsetHeight,

            x: position.x,
            y: position.y,
          }));
        }}
        onDragStart={() => setIsDragging(true)}
        onDragStop={(ev, data) => {
          setIsDragging(false);
          setState((state) => ({
            ...state,
            x: data.lastX + data.deltaX,
            y: state.geselecteerdTabblad === null ? data.lastY : data.lastY + data.deltaY,
          }));
        }}
        dragAxis={state.geselecteerdTabblad === null ? 'x' : 'both'}
      >
        <Root containerWidth={state.width} uitgklapt={state.geselecteerdTabblad !== null}>
          <div
            style={{
              position: 'relative',
              maxWidth: state.geselecteerdTabblad === null ? geslotenMaxWidth : undefined,
              width: state.width,
            }}
            className="d-flex justify-content-end"
          >
            <MenuHandle
              isDragging={isDragging}
              geselecteerdTabblad={state.geselecteerdTabblad}
              onGeselecteerdTabbladChange={(tabblad) =>
                setCommunicatieOverlayState!((state) => {
                  return {
                    ...state,
                    // x,
                    // y,
                    geselecteerdTabblad: tabblad,
                  };
                })
              }
            />
          </div>

          <div
            style={{
              border: `1px solid ${Kleur.Grijs}`,
              boxSizing: 'border-box',
            }}
            className="d-flex flex-column flex-fill"
          >
            <div
              className="d-flex flex-column flex-fill"
              style={{ position: 'relative', cursor: 'default' }}
            >
              <Container
                containerWidth={state.width - 2} // 2px minder ivm border (1px)
                containerHeight={state.height}
              >
                <Tabblad ref={tabbladRef} />
              </Container>
            </div>
          </div>
        </Root>
      </Rnd>
    </CommunicatieOverlayContext.Provider>
  );
});

export default CommunicatieOverlay;
