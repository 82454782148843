import React from 'react';
import { Kleur } from '../../../../../../bedrijfslogica/constanten';

interface IProps {
  links: React.ReactNode;
  rechts: React.ReactNode;
}

const MeerdereLayout = (props: IProps) => {
  return (
    <div className="d-flex flex-fill">
      <div
        className="d-flex flex-column"
        style={{ width: 125, borderRight: `1px solid ${Kleur.LichtGrijs}` }}
      >
        {props.links}
      </div>
      <div className="flex-fill d-flex flex-column">{props.rechts}</div>
    </div>
  );
};

export default MeerdereLayout;
