import React, { useCallback, useContext, useMemo } from 'react';
import {
  DXTableCheckboxComponent,
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
  DXTableToggleCellComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../helpers/dxTableGrid';
import api from '../../../api';
import { EResultType } from '../../../stores/CheckStore';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../stores/RootStore';
import { Kleur as EKleur, Kleur } from '../../../bedrijfslogica/constanten';
import {
  EFunctioneleIcon,
  functioneleIconMap,
  IconInformatie,
  IconSend,
  IconVerwijderen,
} from '../../Icons';
import LoadingSpinner from '../../Gedeeld/LoadingSpinner';
import {
  Grid,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  DataTypeProvider,
  EditingState,
  IntegratedSorting,
  RowDetailState,
  SelectionState,
  Sorting,
  SortingState,
} from '@devexpress/dx-react-grid';
import nameof from '../../../core/nameOf';
import _ from 'lodash';
import RelatiesVisualisaties from '../../personalia/RelatiesVisualisaties';
import { format } from 'date-fns';
import WijzigenDialoog from '../WijzigenOpdrachtDialoog';
import VersturenDialoog from './VersturenDialoog';
import VersturenBevestigingDialoog from '../VersturenBevestigingDialoog';

import Regels from './Regels';
import MenuLayout from '../../MenuLayout';
import {
  IOphalenOpdrachtenResultElementV2,
  IOphalenOpdrachtregelsResultElementV2,
} from '../../../../../shared/src/api/v2/transport/opdracht';
import { IOphalenRelatiesResultElementV2 } from '../../../../../shared/src/api/v2/relatie';
import SoortenIndicatie from '../../kolomveld/transportopdrachtregel/SoortenIndicatieComp';
import Premiums from '../../kolomveld/transportopdrachtregel/PremiumsComp';
import OpgevenPremiumsDialoog from './OpgevenPremiumsDialoog';
import useUrlState from '../../../core/useUrlState';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import SelectieVak from '../../SelectieVak';
import { IOphalenPremiumsResultElement } from '../../../../../shared/src/api/v2/transport/opdracht/premium';
import PlanningStatus from '../PlanningStatus';
import { AutoSizer } from 'react-virtualized';
import { EHoedanigheid } from '../../personalia/RelatieSelectieDialoog';
import RelatieVisualisatie from '../../personalia/RelatieVisualisatie';
import { ITransportopdrachtInfoDialoogState } from '../UitstaandTabel';
import TransportOpdrachtInfoDialoog from '../../../components/transport/OpdrachtInfoDialoog';

export interface IPlanningTabelContext {
  regelsSelectie: number[];
  onRegelsSelectieChange: (value: number[]) => void;
  onRequestRefresh: () => void;
}
export const PlanningTabelContext = React.createContext<IPlanningTabelContext>(null as any);

export interface IVersturenOpdrachtenDialoogState {
  trsOpdIDs: number[];
}
export interface IVersturenBevestigingDialoogState {
  trsOpdIDs: number[];
  persID: number | null;
}

export interface IOpgevenPremiumsDialoogState {
  trsOpdIDs: number[];
}
export interface IWijzigenOpdrachtDialoogState {
  trsOpdID: number;
}

interface IProps extends RouteComponentProps {
  opdrachten: IOpdracht[] | null;
  opdrachtenSelectie: number[];
  onOpdrachtenSelectieChange: (value: number[]) => void;
  uitgeklapteOpdrachten: number[];
  onUitgeklapteOpdrachtenChange: (value: number[]) => void;
  regelsSelectie: number[];
  onRegelsSelectieChange: (value: number[]) => void;
  versturenOpdrachtenDialoogState: IVersturenOpdrachtenDialoogState | null;
  opgevenPremiumsDialoogState: IOpgevenPremiumsDialoogState | null;
  onOpgevenPremiumsDialoogStateChange: (value: IOpgevenPremiumsDialoogState | null) => void;
  onVersturenOpdrachtenDialoogStateChange: (value: IVersturenOpdrachtenDialoogState | null) => void;
  versturenBevestigingDialoogState: IVersturenBevestigingDialoogState | null;
  onVersturenBevestigingDialoogStateChange: (
    value: IVersturenBevestigingDialoogState | null,
  ) => void;
  wijzigenOpdrachtDialoogState: IWijzigenOpdrachtDialoogState | null;
  onWijzigenOpdrachtDialoogStateChange: (value: IWijzigenOpdrachtDialoogState | null) => void;
  transportopdrachtInfoDialoogState: ITransportopdrachtInfoDialoogState | null;
  onWijzigenTransportopdrachtInfoDialoogState: (
    value: ITransportopdrachtInfoDialoogState | null,
  ) => void;
  onRequestRefresh: () => void;
  filterbalk?: JSX.Element;
}

interface IUrlState {
  sortering: Sorting[];
}
const defaultUrlState: IUrlState = {
  sortering: [
    // {
    //   columnName: nameof<IOphalenOpdrachtenResultElement>('Referentie'),
    //   direction: 'desc',
    // },
    {
      columnName: 'Bezoekdatum',
      direction: 'asc',
    },
  ],
};

export interface IRegel extends IOphalenOpdrachtregelsResultElementV2 {
  relatie: IOphalenRelatiesResultElementV2 | null;
}

export interface IOpdracht extends IOphalenOpdrachtenResultElementV2 {
  regels: IRegel[];
  premiums: IOphalenPremiumsResultElement[];
}

export interface IIndicatiesProps {
  opdracht: IOpdracht;
}

export interface IToewijzing {
  opdracht: IOpdracht;
}

export const berekendLocatieniveau = (opdracht: IOpdracht): number | null => {
  const locatieNiveau =
    opdracht.HoogsteVerdieping ??
    _.max(
      opdracht.regels
        // .filter(
        //   (x) =>
        //     !(x.OmruilID !== null && x.regelsoort.NaamEnum === ETransportopdrachtRegelsoort.Retour),
        // )
        .filter((x) => x.Verdieping !== null)
        .map((x) => x.Verdieping),
    ) ??
    null;

  return locatieNiveau;
};

const Toewijzing: React.FC<IToewijzing> = (props) => {
  const nogKoppelen = props.opdracht.regels.some((x) => x.type === null && x.product === null);
  const nogReserveren = props.opdracht.regels.some(
    (x) => x.type !== null && !x.type.Bulkproduct && !x.type.Gereserveerd,
  );

  if (!nogKoppelen && !nogReserveren) {
    return <span style={{ color: EKleur.Groen }}>Ok</span>;
  }

  return (
    <>
      {nogKoppelen && <span style={{ color: EKleur.Rood }}>Reserveren</span>}
      {nogReserveren && <span style={{ color: EKleur.Blauw }}>Ink.res.</span>}
    </>
  );
};

export interface ISoortenIndicatie {
  opdracht: IOpdracht;
}

const IconPremium = functioneleIconMap[EFunctioneleIcon.Premium];

const PlanningTabel: React.FC<IProps> = observer((props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState<IUrlState>(props, defaultUrlState);

  const { checkStore } = useContext(RootStoreContext);
  const keyExtractor = useCallback((row: IOpdracht) => row.TrsOpdID, []);

  const kolomBreedteAantalRegels = 30;
  const kolomBreedteRelatie = 200;

  const rows = useMemo<IOpdracht[] | null>(() => {
    if (props.opdrachten === null) {
      return null;
    }

    const opdrachten = props.opdrachten.map((opdracht) => ({
      ...opdracht,
      bezoekdatum: opdracht.Bezoekdatum === null ? null : new Date(opdracht.Bezoekdatum),
      voorraadinfo: { aantalVrij: 0 },
    }));

    return _.orderBy(opdrachten, ['Opdrachtnummer'], ['desc']);
  }, [props.opdrachten]);

  const kolommen = useMemo<TypedColumn<IOpdracht>[]>(
    () => [
      {
        name: 'Opdrachtnummer',
        title: 'Opd.nr',
      },
      {
        name: '__opdrachtInfo' as any,
        title: ' ',
      },
      {
        name: '__relaties' as any,
        title: 'Relatie(s)',
        getCellValue: (x) => {
          const relatieResult = x.regels.filter((x) => x.relatie !== null);
          const relatie = relatieResult.length !== 0 ? relatieResult[0].relatie : null;
          if (relatie === null) return '';
          return relatie.weergavenaam;
        },
      },
      {
        name: 'locatie',
        title: 'Locatie',
        getCellValue: (row) => {
          const locatie =
            row.locatie.Plaatsnaam +
            ', ' +
            row.locatie.Straatnaam +
            ' ' +
            row.locatie.Huisnummer +
            (row.locatie.Bisnummer !== null ? ' ' + row.locatie.Bisnummer : '');

          return locatie;
        },
      },
      {
        name: '__locatieniveau' as any,
        title: 'Niv. ',
      },
      {
        name: '__soortenIndicatie' as any,
        title: '#/Srt.',
      },
      // {
      //   name: '__premiums' as any,
      //   title: 'Prem.',
      // },
      {
        name: 'Bezoekdatum',
        title: 'Bezoekdatum',
        getCellValue: (x) => {
          return x.Bezoekdatum !== null ? x.Bezoekdatum : null;
        },
      },
      {
        name: '__toewijzing' as any,
        title: 'Toewijzing',
      },
      {
        name: '__indicaties' as any,
        title: 'Indicaties',
      },
      // {
      //   name: 'Notities',
      //   title: 'Notities',
      // },
      { name: '__magazijn', title: 'Mag.' },
      {
        name: '__dienst' as any,
        title: 'Dienst',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IOpdracht>[]>(
    () => [
      {
        columnName: 'Opdrachtnummer',
        width: 90,
      },
      {
        columnName: '__opdrachtInfo' as any,
        width: 65,
      },
      {
        columnName: '__dienst' as any,
        width: 95,
      },
      {
        columnName: '__soortenIndicatie' as any,
        width: 75,
      },
      {
        columnName: '__premiums' as any,
        width: 75,
      },
      // {
      //   columnName: '__aantalRegels' as any,
      //   width: kolomBreedteAantalRegels,
      // },
      {
        columnName: 'locatie',
        width: 315,
      },
      {
        columnName: '__locatieniveau' as any,
        width: 75,
      },
      {
        columnName: '__toewijzing' as any,
        width: 125,
      },
      {
        columnName: '__indicaties' as any,
        width: 300,
      },
      {
        columnName: '__relaties' as any,
        width: kolomBreedteRelatie,
      },
      {
        columnName: 'Bezoekdatum',
        width: 125,
      },
      {
        columnName: 'Notities',
        width: 185,
      },
      { columnName: '__magazijn', width: 100 },
    ],
    [],
  );

  const handleVerwijderenOpdrachten = useCallback(async () => {
    const checkData = await api.v2.transport.opdracht.checkVerwijderenOpdrachten({
      trsOpdIDs: props.opdrachtenSelectie,
    });
    const checkResult = await checkStore.controleren({
      checkData,
    });
    if (checkResult.type === EResultType.Annuleren) {
      return;
    }

    if (
      (
        await checkStore.bevestigen({
          inhoud: (
            <span>
              Geselecteerde transportopdrachten verwijderen?
              <br />
              <br />
              Let op, dit kan niet meer ongedaan gemaakt worden.
            </span>
          ),
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }
    await api.v2.transport.opdracht.verwijderenOpdrachten({
      trsOpdIDs: props.opdrachtenSelectie,
    });

    props.onRequestRefresh();
  }, [props.opdrachtenSelectie, props.onRequestRefresh]);

  const context = useMemo<IPlanningTabelContext>(() => {
    return {
      regelsSelectie: props.regelsSelectie,
      onRegelsSelectieChange: props.onRegelsSelectieChange,
      onRequestRefresh: props.onRequestRefresh,
    };
  }, [props.onRequestRefresh, props.regelsSelectie, props.onRegelsSelectieChange]);

  return (
    <PlanningTabelContext.Provider value={context}>
      <MenuLayout
        menu={
          <>
            <div className="d-flex">
              {/* <button
                className="btn btn-sm btn-light d-flex align-items-center"
                style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                disabled={props.opdrachtenSelectie.length === 0}
                onClick={() =>
                  props.onVersturenBevestigingDialoogStateChange({
                    trsOpdIDs: props.opdrachtenSelectie,
                    persID: null,
                  })
                }
              >
                <IconVerwijderen style={{ width: 16, height: 16, fill: EKleur.Grijs }} />
                <span className="ml-2">Versturen bevestiging</span>
              </button> */}
              <button
                className="btn btn-sm btn-light d-flex align-items-center"
                style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                disabled={props.opdrachtenSelectie.length === 0}
                onClick={async () => {
                  const checkData = await api.v2.transport.checkSelectieVersturenOpdrachten({
                    trsOpdIDs: props.opdrachtenSelectie,
                  });
                  if (
                    (await checkStore.controleren({ checkData })).type === EResultType.Annuleren
                  ) {
                    return;
                  }

                  props.onVersturenOpdrachtenDialoogStateChange({
                    trsOpdIDs: props.opdrachtenSelectie,
                  });
                }}
              >
                <IconSend style={{ width: 16, height: 16, fill: EKleur.Grijs }} />
                <span className="ml-2">Versturen opdrachten</span>
              </button>

              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-2"
                style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                disabled={props.opdrachtenSelectie.length === 0}
                onClick={async () => {
                  props.onOpgevenPremiumsDialoogStateChange({
                    trsOpdIDs: props.opdrachtenSelectie,
                  });
                }}
              >
                <IconPremium style={{ width: 16, height: 16, fill: EKleur.Grijs }} />
                <span className="ml-2">Premiums toevoegen</span>
              </button>

              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-2"
                style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                disabled={props.opdrachtenSelectie.length === 0}
                onClick={() => handleVerwijderenOpdrachten()}
              >
                <IconVerwijderen style={{ width: 16, height: 16, fill: EKleur.Grijs }} />
                <span className="ml-2">Verwijderen</span>
              </button>
            </div>

            {rows !== null && (
              <div className="mt-3 d-flex align-items-center">
                <SelectieVak
                  aantal={props.opdrachtenSelectie.length}
                  totaalAantal={rows.length}
                  onChange={(alles) => {
                    if (alles) {
                      props.onOpdrachtenSelectieChange(props.opdrachten!.map((x) => x.TrsOpdID));
                    } else {
                      props.onOpdrachtenSelectieChange([]);
                    }
                  }}
                  entiteitEnkelvoud="opdracht"
                  entiteitMeervoud="opdrachten"
                />
                <div className="d-flex flex-fill ml-2">{props.filterbalk}</div>
              </div>
            )}
          </>
        }
        body={
          rows === null ? (
            <div className="d-flex flex-fill align-items-center justify-content-center p-5 pl-4 pr-4">
              <LoadingSpinner />
            </div>
          ) : (
            <AutoSizer
              style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              {(size) => (
                <GridStyleWrapper height={size.height}>
                  <Grid rows={rows} columns={kolommen} getRowId={keyExtractor}>
                    <DataTypeProvider
                      for={['__dienst']}
                      formatterComponent={(formatterProps) => {
                        const rij = formatterProps.row as IOpdracht;

                        return (
                          <RelatieVisualisatie
                            relID={rij.dienst!.RelID}
                            options={{ geenLinkToepassen: false }}
                          />
                        );
                      }}
                    />

                    {/* <DataTypeProvider
                      for={['__dienst']}
                      formatterComponent={(formatterProps) => {
                        const row = formatterProps.row as IOpdracht;
                        return (
                          <>
                            <span>{row.dienst.Naam}</span>
                          </>
                        );
                      }}
                    /> */}

                    <DataTypeProvider
                      for={['__opdrachtInfo']}
                      formatterComponent={(formatterProps) => {
                        const rij = formatterProps.row as IOpdracht;

                        return (
                          <div className="mb-1">
                            <a
                              href="#"
                              // style={{ color: Kleur.DonkerGrijs }}
                              onClick={() => {
                                props.onWijzigenTransportopdrachtInfoDialoogState({
                                  trsOpdID: rij.TrsOpdID,
                                });
                              }}
                            >
                              <IconInformatie
                                style={{ width: 17, height: 17, fill: Kleur.Blauw }}
                              />
                            </a>
                            {rij.Notities !== null ? (
                              <span
                                className="m-2"
                                style={{ color: Kleur.Oranje, fontWeight: 'bold' }}
                              >
                                !
                              </span>
                            ) : (
                              <span></span>
                            )}
                          </div>
                        );
                      }}
                    />

                    <DataTypeProvider
                      for={['__soortenIndicatie']}
                      formatterComponent={(formatterProps) => {
                        const rij = formatterProps.row as IOpdracht;
                        return <SoortenIndicatie opdracht={rij} />;
                      }}
                    />

                    {/* <DataTypeProvider
                      for={['__premiums']}
                      formatterComponent={(formatterProps) => {
                        const rij = formatterProps.row as IOpdracht;
                        if (!rij.PremiumLeveren) {
                          return <span></span>;
                        }
                        return <Premiums premiums={rij.premiums} />;
                      }}
                    /> */}

                    {/* <DataTypeProvider
                      for={[nameof<IOpdracht>('locatie')]}
                      formatterComponent={(formatterProps) => {
                        const row = formatterProps.row as IOpdracht;
                        const locatie = row.locatie;
                        const adres =
                          locatie.Straatnaam +
                          ' ' +
                          locatie.Huisnummer.toString() +
                          ' ' +
                          (locatie.Bisnummer ?? '') +
                          ', ' +
                          locatie.Plaatsnaam;
                        return <span>{adres}</span>;

                        // return (
                        //   <AdresVisualisatie
                        //     weergaveTekst={formatteerAdresV2({
                        //       bisnummer: row.locatie.Bisnummer,
                        //       straatnaam: row.locatie.Straatnaam,
                        //       huisnummer: row.locatie.Huisnummer,
                        //       postcode: row.locatie.Postcode,
                        //       plaatsnaam: row.locatie.Plaatsnaam,
                        //       landnaamKort: row.locatie.LandnaamKort,
                        //       landnaamEnum: row.locatie.LandnaamEnum,
                        //     })}
                        //   />
                        // );
                      }}
                    /> */}

                    <DataTypeProvider
                      for={['__relaties']}
                      formatterComponent={(formatterProps) => {
                        const rij = formatterProps.row as IOpdracht;

                        const relIDs: number[] = _.uniq(
                          rij.regels.filter((x) => x.RelID !== null).map((x) => x.RelID),
                        );
                        if (relIDs.length === 0) {
                          return <span />;
                        }
                        return (
                          <RelatiesVisualisaties
                            relIDs={relIDs}
                            relatieLinkBuilder={(hoedanigheid, relID) =>
                              `/${
                                hoedanigheid === EHoedanigheid.Klant ? 'klant' : 'leverancier'
                              }/${relID}/transport/opdracht`
                            }
                          />
                        );
                      }}
                    />

                    <DataTypeProvider
                      for={['__locatieniveau']}
                      formatterComponent={(formatterProps) => {
                        const rij = formatterProps.row as IOpdracht;

                        const locatieNiveau = berekendLocatieniveau(rij);
                        return (
                          <span style={{ color: EKleur.Grijs }}>
                            {locatieNiveau === 99
                              ? 'Vdp'
                              : locatieNiveau === 0
                              ? 'Bgg'
                              : locatieNiveau + 'e'}
                          </span>
                        );
                      }}
                    />

                    <DataTypeProvider
                      for={[nameof<IOpdracht>('Bezoekdatum')]}
                      formatterComponent={(formatterProps) => {
                        const row = formatterProps.row as IOpdracht;

                        if (row.Bezoekdatum === null) {
                          return <span style={{ color: Kleur.Rood }}>Nog opgeven</span>;
                        }
                        return (
                          <span>
                            {format(new Date(row.Bezoekdatum), 'dd-MM-yyyy')}
                            {row.IndicatieAAA ? ' (A)' : ''}
                          </span>
                        );
                      }}
                    />

                    <DataTypeProvider
                      for={['__toewijzing']}
                      formatterComponent={(formatterProps) => {
                        const row = formatterProps.row as IOpdracht;
                        return <Toewijzing opdracht={row} />;
                      }}
                    />

                    <DataTypeProvider
                      for={['__indicaties']}
                      formatterComponent={(formatterProps) => {
                        const row = formatterProps.row as IOpdracht;

                        return <PlanningStatus opdracht={row} />;
                      }}
                    />

                    <DataTypeProvider
                      for={['__magazijn']}
                      formatterComponent={(formatterProps) => {
                        const rij = formatterProps.row as IOpdracht;

                        return <span>{rij.magazijn.NaamKort}</span>;
                      }}
                    />

                    <SelectionState
                      selection={props.opdrachtenSelectie}
                      onSelectionChange={(value) =>
                        props.onOpdrachtenSelectieChange(value as number[])
                      }
                    />

                    <SortingState
                      sorting={urlState.sortering}
                      onSortingChange={(value) => setUrlStateSync('sortering', value)}
                    />

                    <IntegratedSorting
                      columnExtensions={[
                        {
                          columnName: nameof<IOpdracht>('Bezoekdatum'),
                          compare: (links, rechts) => {
                            const linksDatum = links === null ? null : new Date(links);
                            const rechtsDatum = links === null ? null : new Date(rechts);

                            if (linksDatum === null) {
                              return -1;
                            }
                            if (rechtsDatum === null) {
                              return 1;
                            }

                            return linksDatum.getTime() - rechtsDatum.getTime();
                          },
                        },
                      ]}
                    />

                    <RowDetailState
                      expandedRowIds={props.uitgeklapteOpdrachten}
                      onExpandedRowIdsChange={(value) =>
                        props.onUitgeklapteOpdrachtenChange(value as number[])
                      }
                    />

                    <VirtualTable />
                    <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
                    <TableHeaderRow showSortingControls />

                    <EditingState
                      onCommitChanges={(changes) => {
                        if (changes.deleted === undefined) {
                          return;
                        }
                        const deleted = changes.deleted;
                        const id = deleted[deleted.length - 1];
                      }}
                      onEditingRowIdsChange={(rowIds) => {
                        const id = rowIds[rowIds.length - 1] as number;
                        props.onWijzigenOpdrachtDialoogStateChange({ trsOpdID: id });
                      }}
                    />

                    <TableEditColumn
                      width={35}
                      showEditCommand
                      cellComponent={DXTableEditColumnCellComponent}
                      commandComponent={DXTableEditColumnCommandComponent}
                    />
                    <TableRowDetail
                      toggleCellComponent={DXTableToggleCellComponent}
                      contentComponent={Regels}
                    />
                    <TableSelection cellComponent={DXTableCheckboxComponent} />
                  </Grid>
                </GridStyleWrapper>
              )}
            </AutoSizer>
          )
        }
      />

      {props.wijzigenOpdrachtDialoogState !== null && (
        <WijzigenDialoog
          open
          onSuccess={() => {
            props.onWijzigenOpdrachtDialoogStateChange(null);
            props.onRequestRefresh();
          }}
          onAnnuleren={() => props.onWijzigenOpdrachtDialoogStateChange(null)}
          trsOpdID={props.wijzigenOpdrachtDialoogState.trsOpdID}
        />
      )}

      {props.versturenOpdrachtenDialoogState !== null && (
        <VersturenDialoog
          open
          onSuccess={async () => {
            props.onVersturenOpdrachtenDialoogStateChange(null);
            props.onRequestRefresh();
          }}
          onAnnuleren={() => props.onVersturenOpdrachtenDialoogStateChange(null)}
          trsOpdIDs={props.versturenOpdrachtenDialoogState.trsOpdIDs}
        />
      )}
      {props.versturenBevestigingDialoogState !== null && (
        <VersturenBevestigingDialoog
          open
          onSuccess={async () => {
            props.onVersturenBevestigingDialoogStateChange(null);
            props.onRequestRefresh();
          }}
          onAnnuleren={() => props.onVersturenBevestigingDialoogStateChange(null)}
          trsOpdIDs={props.versturenBevestigingDialoogState.trsOpdIDs}
          persID={null}
        />
      )}
      {props.opgevenPremiumsDialoogState !== null && (
        <OpgevenPremiumsDialoog
          open
          onSuccess={async () => {
            props.onOpgevenPremiumsDialoogStateChange(null);
            props.onRequestRefresh();
          }}
          onAnnuleren={() => props.onOpgevenPremiumsDialoogStateChange(null)}
          trsOpdIDs={props.opgevenPremiumsDialoogState.trsOpdIDs}
        />
      )}
      {props.transportopdrachtInfoDialoogState !== null && (
        <TransportOpdrachtInfoDialoog
          open
          trsOpdID={props.transportopdrachtInfoDialoogState.trsOpdID}
          onSuccess={() => props.onWijzigenTransportopdrachtInfoDialoogState(null)}
          onAnnuleren={() => props.onWijzigenTransportopdrachtInfoDialoogState(null)}
        />
      )}
    </PlanningTabelContext.Provider>
  );
});

export default withRouter(PlanningTabel);
