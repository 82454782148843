import api from '../../..';
import {
  IBoekenDubieuzeDebiteurenParams,
  IBoekenDubieuzeDebiteurenResult,
} from '../../../../../../shared/src/api/v2/debiteur/boeking';
import ICheckData from '../../../../../../shared/src/models/ICheckData';

const boeking = {
  boekenDubieuzeDebiteuren: async (
    params: IBoekenDubieuzeDebiteurenParams,
  ): Promise<IBoekenDubieuzeDebiteurenResult> => {
    return await api.post('/v2/debiteur/boeking/boeken-dubieuze-debiteuren', params);
  },
};

export default boeking;
