import React, { useMemo } from 'react';
import Tabblad, { ITabblad } from '../../../../../../components/layout/Tabblad';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Vorderingen from './Vorderingen';
import OverzichtDetailComp from './Overzicht';
import useBepaalUrlTabblad from '../../../../../../core/useUrlTabblad';
import { IEntiteitProps } from '../../../../../../components/kaart/EntiteitContainer';
import Aanmaningen from './Aanmaningen';

interface IProps extends IEntiteitProps, RouteComponentProps {}

export enum ETabblad {
  Overzicht = 1,
  Vorderingen = 2,
  Aanmaningen = 3,
}

const pathMap: Record<ETabblad, string> = {
  [ETabblad.Overzicht]: '/overzicht',
  [ETabblad.Vorderingen]: '/vorderingen',
  [ETabblad.Aanmaningen]: '/aanmaningen',
};

const Facturen: React.FC<IProps> = (props) => {
  const { tabblad, onChange: onChangeTabblad } = useBepaalUrlTabblad<ETabblad>(
    props,
    pathMap,
    ETabblad.Overzicht,
  );

  // const tabblad = useMemo<ETabblad | null>(() => {
  //   const matchKey = Object.keys(pathMap).find((key) => {
  //     const path = pathMap[Number(key) as ETabblad]!;
  //     return props.location.pathname.endsWith(path);
  //   });
  //   if (matchKey === undefined) {
  //     props.history.push(`${props.match.path}${pathMap[ETabblad.Overzicht]}`);
  //     return null;
  //   }
  //   return Number(matchKey) as ETabblad;
  // }, [props.location.pathname]);

  const tabbladen = useMemo<ITabblad<ETabblad>[]>(
    () => [
      {
        id: ETabblad.Overzicht,
        label: 'Overzicht',
        content: () => <OverzichtDetailComp relID={props.relID} />,
      },
      {
        id: ETabblad.Vorderingen,
        label: 'Vorderingen',
        content: () => <Vorderingen relID={props.relID} />,
      },
      {
        id: ETabblad.Aanmaningen,
        label: 'Aanmaningen',
        content: () => <Aanmaningen />,
      },
    ],
    [props.relID],
  );

  return (
    <div className="d-flex flex-column flex-fill flex-fill">
      {tabblad !== null && (
        <Tabblad<ETabblad>
          geselecteerd={tabblad}
          onSelectieChange={(tab) => onChangeTabblad(tab)}
          tabbladen={tabbladen}
        />
      )}
    </div>
  );
};

export default withRouter(Facturen);
