import React from 'react';
import styled from 'styled-components';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import { observer } from 'mobx-react-lite';
import LoadingSpinner from '../../../Gedeeld/LoadingSpinner';
import { useEmailWerkbladV2Store } from '../store';
import { ERemoteDataState } from '../../../../models/IRemoteData';
import Account from './Account';
import { IOphalenEmailAccountsResultElement } from '../../../../../../shared/src/api/v2/dienst/email/email';
import { IEmailGebrXAspGebr } from '../../../../../../shared/src/api/v2/email-v2';

const Root = styled.div`
  width: 400px;
  border-right: 1px solid ${Kleur.LichtGrijs};
  display: flex;
  flex-direction: column;
  background-color: ${Kleur.Wit};
  overflow-y: auto;
  position: absolute;
  top: 0;
  bottom: 0;
`;

export type UitgeklaptAccountsState = Record<number, boolean>;
export type UitgeklaptMappenState = Record<number, boolean>;

interface IProps {
  mailMapID: number | null;
  onMailMapIDChange: (mailMapID: number | null) => void;
  uitgeklaptAccountsState: UitgeklaptAccountsState;
  onUitgeklaptAccountsStateChange: (uitgeklaptState: UitgeklaptAccountsState) => void;
  uitgeklaptMappenState: UitgeklaptMappenState;
  onUitgeklaptMappenStateChange: (uitgeklaptState: UitgeklaptMappenState) => void;
}

const MappenSectie = observer((props: IProps) => {
  const emailWerkbladV2Store = useEmailWerkbladV2Store();

  if (
    emailWerkbladV2Store.emailDienstenBijIndex.state === ERemoteDataState.Pending ||
    emailWerkbladV2Store.emailAccountsBijIndex.state === ERemoteDataState.Pending ||
    emailWerkbladV2Store.emailGebrXAspGebrsVoorMij.state === ERemoteDataState.Pending
  ) {
    return (
      <Root>
        <div className="flex-fill d-flex align-items-center justify-content-center">
          <LoadingSpinner />
        </div>
      </Root>
    );
  }

  return (
    <Root>
      {Object.keys(emailWerkbladV2Store.emailDienstenBijIndex.data).map((key) => {
        const idx = Number(key);
        const dienst = emailWerkbladV2Store.emailDienstenBijIndex.data![idx]!;
        if (!dienst.Actief) {
          return null;
        }

        let emailAccounts: IOphalenEmailAccountsResultElement[] =
          emailWerkbladV2Store.emailAccountsBijEmailDienstID.data![dienst.EmailDienstID] ?? [];
        emailAccounts = emailAccounts.filter((account) =>
          emailWerkbladV2Store.emailGebrXAspGebrsVoorMij.data!.some(
            (x: IEmailGebrXAspGebr) => x.EmailGebrID === account.EmailGebrID,
          ),
        );

        return (
          <div key={dienst.EmailDienstID}>
            <h4 className="pt-3 pb-1 ml-4">{dienst.Naam}</h4>
            <div className="d-flex flex-column">
              {emailAccounts.map((account) => {
                return (
                  <Account
                    key={account.EmailGebrID}
                    account={account}
                    isUitgeklapt={props.uitgeklaptAccountsState[account.EmailGebrID]}
                    onUitgeklaptChange={(uitgeklapt) =>
                      props.onUitgeklaptAccountsStateChange({
                        ...props.uitgeklaptAccountsState,
                        [account.EmailGebrID]: uitgeklapt,
                      })
                    }
                    mailMapID={props.mailMapID}
                    onMailMapIDChange={props.onMailMapIDChange}
                    uitgeklaptMappenState={props.uitgeklaptMappenState}
                    onUitgeklaptMappenStateChange={props.onUitgeklaptMappenStateChange}
                  />
                );
              })}
            </div>
          </div>
        );
      })}
    </Root>
  );
});

export default MappenSectie;
