import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Button, GebruikersSelectieKnop, OpgepaktContainer, Root, ToekennenKnop } from './style';
import { IconUitklappen } from '../Icons';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../stores/RootStore';
import {
  IInstellenBezigheidParams,
  IOphalenBezighedenResultElement,
  IOphalenGebruikersResultElement,
} from '../../../../shared/src/api/v2/gebruiker';
import api from '../../api';
import { v4 as uuid } from 'uuid';
import { bezigheidsleutelNaarString, IBezigheidsleutel, useBezigheidKnopStore } from './store';
import { ERemoteDataState } from '../../models/IRemoteData';

export enum BezigheidStatus {
  Opgepakt = 1,
  Vrijgegeven,
}

type DataOptions =
  | {
      enum: 'WHATSAPP';
      whatsAppSes_ID: number;
    }
  | {
      enum: 'RELATIE';
      relID: number;
    }
  | {
      enum: 'CONTROL_TAAK';
      id: number;
    }
  | {
      enum: 'APP_EVENT';
      eventID: number;
    }
  | {
      enum: 'BELTAAK';
      beltaakID: number;
    }
  | {
      enum: 'SIGNALERING';
      signaleringID: number;
    }
  | {
      enum: 'ESCALATIE';
      escalatieID: number;
    };

export enum EGrootte {
  Normaal,
  Klein,
}

interface IProps {
  interactieToegestaan: boolean;
  data: DataOptions;
  grootte?: EGrootte;
  directToepassen?: boolean;
  aspGebrID?: number | null;
  onBezigheidGekozenNietToegepast?: (bezigheid: IInstellenBezigheidParams) => void;
}

// export const bezigheidPredicate = (x: IOphalenBezighedenResultElement, data: DataOptions) => {
//   switch (data.enum) {
//     case 'RELATIE':
//       return x.RelID === data.relID;
//     case 'WHATSAPP':
//       return x.WhatsAppSes_ID === data.whatsAppSes_ID;
//     case 'APP_EVENT':
//       return x.AppEventID === data.eventID;
//     case 'CONTROL_TAAK':
//       return x.ControlTaakID === data.id;
//     case 'BELTAAK':
//       return x.BeltaakID === data.beltaakID;
//     default:
//       throw new Error();
//   }
// };

const maakBezigheidVanData = (data: DataOptions): IInstellenBezigheidParams['bezigheid'] => {
  switch (data.enum) {
    case 'WHATSAPP':
      return {
        naamEnum: data.enum,
        whatsAppSes_ID: data.whatsAppSes_ID,
      };
    case 'RELATIE':
      return {
        naamEnum: data.enum,
        relID: data.relID,
      };
    case 'APP_EVENT':
      return {
        naamEnum: data.enum,
        eventID: data.eventID,
      };
    case 'CONTROL_TAAK':
      return {
        naamEnum: data.enum,
        id: data.id,
      };
    case 'BELTAAK':
      return {
        naamEnum: data.enum,
        beltaakID: data.beltaakID,
      };
    case 'SIGNALERING':
      return {
        naamEnum: data.enum,
        signaleringID: data.signaleringID,
      };
    case 'ESCALATIE':
      return {
        naamEnum: data.enum,
        escalatieID: data.escalatieID,
      };
  }
  throw new Error();
};

const BezigheidKnop: React.FC<IProps> = observer((props) => {
  const { gebruikerStore } = useContext(RootStoreContext);
  const id = useRef(uuid());
  const [hovered, setHovered] = useState(false);
  const [bezig, setBezig] = useState(false);
  const store = useBezigheidKnopStore();

  const bezigheidsleutel = useMemo<IBezigheidsleutel>(() => {
    let id: number;
    switch (props.data.enum) {
      case 'WHATSAPP':
        id = props.data.whatsAppSes_ID;
        break;
      case 'RELATIE':
        id = props.data.relID;
        break;
      case 'CONTROL_TAAK':
        id = props.data.id;
        break;
      case 'APP_EVENT':
        id = props.data.eventID;
        break;
      case 'BELTAAK':
        id = props.data.beltaakID;
        break;
      case 'SIGNALERING':
        id = props.data.signaleringID;
        break;
      case 'ESCALATIE':
        id = props.data.escalatieID;
        break;
    }

    return {
      naamEnum: props.data.enum,
      id,
    };
  }, [props.data]);

  useEffect(() => {
    store.enqueueOphalenBezigheden([bezigheidsleutel]);
  }, []);

  const bezigheidsleutelString = useMemo(() => bezigheidsleutelNaarString(bezigheidsleutel), [
    bezigheidsleutel,
  ]);

  const huidigeBezigheid = useMemo(() => {
    if (props.directToepassen !== undefined && !props.directToepassen) {
      return null;
    }

    if (gebruikerStore.gebruiker === null) {
      return null;
    }

    const bezigheid = store.bezigheden[bezigheidsleutelString];
    if (
      bezigheid === undefined ||
      bezigheid.state === ERemoteDataState.Pending ||
      bezigheid.data!.Status === 2
    ) {
      return null;
    }

    return bezigheid.data!;
  }, [gebruikerStore.gebruiker, store.bezigheden, props.data, bezigheidsleutelString]);

  const gebruiker: IOphalenGebruikersResultElement | null = useMemo(() => {
    if (props.aspGebrID && gebruikerStore.gebruikers !== null) {
      const gebr = gebruikerStore.gebruikers.find((x) => x.AspGebrID === props.aspGebrID);
      if (gebr === undefined) {
        return null;
      }
      return gebr;
    }

    if (
      huidigeBezigheid === null ||
      gebruikerStore.gebruikers === null ||
      gebruikerStore.gebruikers.length === 0
    ) {
      return null;
    }

    const gebr = gebruikerStore.gebruikers.find((x) => x.AspGebrID === huidigeBezigheid.AspGebrID);
    if (gebr === undefined) {
      return null;
    }

    return gebr;
  }, [gebruikerStore.gebruikers, huidigeBezigheid, props.aspGebrID]);

  const opgepaktDoor = useMemo<string | null>(() => {
    if (gebruiker === null) {
      return null;
    }

    return gebruiker.NaamKort;
  }, [gebruiker]);

  const isEigenaar = useMemo<boolean>(() => {
    if (gebruiker === null) {
      return false;
    }

    return gebruiker.AspGebrID === gebruikerStore.gebruiker!.AspGebrID;
  }, [gebruiker, gebruikerStore.gebruiker]);

  const vrijgeven = useCallback(async () => {
    const bezigheidData = maakBezigheidVanData(props.data);

    if (props.onBezigheidGekozenNietToegepast != null) {
      props.onBezigheidGekozenNietToegepast({
        bezigheid: bezigheidData,
        aspGebrID: -1,
        status: BezigheidStatus.Vrijgegeven,
      });
    }

    if (props.directToepassen !== undefined && !props.directToepassen) {
      return null;
    }

    await api.v2.gebruiker.instellenBezigheid({
      bezigheid: bezigheidData,
      aspGebrID: huidigeBezigheid!.AspGebrID,
      status: BezigheidStatus.Vrijgegeven,
    });
  }, [props.data, huidigeBezigheid]);

  const toekennen = useCallback(
    async (aspGebrID: number) => {
      const bezigheid = maakBezigheidVanData(props.data);

      if (props.onBezigheidGekozenNietToegepast != null) {
        props.onBezigheidGekozenNietToegepast({
          bezigheid,
          aspGebrID,
          status: BezigheidStatus.Opgepakt,
        });
      }

      if (props.directToepassen !== undefined && !props.directToepassen) {
        return null;
      }

      await api.v2.gebruiker.instellenBezigheid({
        bezigheid,
        aspGebrID,
        status: BezigheidStatus.Opgepakt,
      });
    },
    [props.data],
  );

  const popoverGebruikers = useMemo(() => {
    if (gebruikerStore.gebruikers === null) {
      return null;
    }
    if (gebruiker === null) {
      return gebruikerStore.gebruikers;
    }
    return gebruikerStore.gebruikers.filter((x) => x.AspGebrID !== gebruiker!.AspGebrID);
  }, [gebruikerStore.gebruikers, gebruiker]);

  const uitklapPopover = (
    <Popover id={`Bezigheidknop-${id.current!}`}>
      <span
        style={{
          borderBottom: '1px solid #e0e0e0',
          padding: '5px 5px',
        }}
      >
        Toekennen aan
      </span>
      <div style={{ marginTop: 10 }}>
        {popoverGebruikers !== null ? (
          popoverGebruikers.map((x) => (
            <GebruikersSelectieKnop
              key={x.AspGebrID}
              className="btn btn-block"
              style={{ marginBottom: 5, padding: 0 }}
              onClick={async () => {
                document.body.click();
                setBezig(true);
                setHovered(false);
                await toekennen(x.AspGebrID);
                setBezig(false);
              }}
            >
              {x.NaamKort}
            </GebruikersSelectieKnop>
          ))
        ) : (
          <span>Laden...</span>
        )}
      </div>
    </Popover>
  );

  return (
    <Root>
      {opgepaktDoor === null ? (
        <Button
          disabled={!props.interactieToegestaan || bezig}
          onClick={async (ev) => {
            ev.stopPropagation();

            setBezig(true);
            await toekennen(gebruikerStore.gebruiker!.AspGebrID);
            setTimeout(() => setBezig(false), 300);
            setBezig(false);
          }}
          onMouseEnter={() => setHovered((curr) => true)}
          onMouseLeave={() => setHovered((curr) => false)}
        >
          Oppakken
        </Button>
      ) : (
        <OpgepaktContainer
          disabled={!props.interactieToegestaan || bezig}
          isEigenaar={isEigenaar}
          onMouseEnter={() => setHovered((curr) => true)}
          onMouseLeave={() => setHovered((curr) => false)}
          onClick={async () => {
            setBezig(true);
            if (!isEigenaar) {
              await toekennen(gebruikerStore.gebruiker!.AspGebrID);
            } else {
              await vrijgeven();
            }
            setBezig(false);
          }}
        >
          {hovered ? isEigenaar ? <span>Vrijgeven</span> : <span>Overnemen</span> : opgepaktDoor}
        </OpgepaktContainer>
      )}

      <OverlayTrigger overlay={uitklapPopover} trigger="click" rootClose placement="auto-start">
        <ToekennenKnop grootte={props.grootte || EGrootte.Normaal}>
          <IconUitklappen style={{ fill: '#a7a7a7', width: 19, height: 19 }} />
        </ToekennenKnop>
      </OverlayTrigger>
    </Root>
  );
});

export default BezigheidKnop;
