import React from 'react';
import { Redirect, Route, RouteComponentProps } from 'react-router';
import Documentatie from './Documentatie';
import ImportExport from './ImportExport';
import Sjablonen from './Sjablonen';
import Bulkberichten from './Bulkberichten';
import Bulkverzender from './Bulkverzender';

interface IProps extends RouteComponentProps<{}> {}

const Service: React.FC<IProps> = (props) => {
  const { match } = props;
  return (
    <div className="d-flex flex-fill flex-column">
      <Route path={`${match.path}/documentatie`} component={Documentatie} />
      <Route path={`${match.path}/import-export`} component={ImportExport} />
      <Route
        path={`${match.path}/kennisbank`}
        component={() => {
          return <span>TODO</span>;
        }}
      />
      <Route path={`${match.path}/sjablonen`} component={Sjablonen} />
      <Route path={`${match.path}/bulkberichten`} component={Bulkberichten} />
      <Route path={`${match.path}/bulkverzender`} component={Bulkverzender} />
      {/* <Route path={`${match.path}/visualisatie`} component={Visualisatie} /> */}

      <Route
        exact={true}
        path={match.path}
        render={() => <Redirect to={`${match.path}/sjablonen`} />}
      />
    </div>
  );
};

export default Service;
