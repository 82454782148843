import api from '..';
import {
  IOphalenLandenVoorLijstResult,
  IOphalenLandenVoorLijstParams,
  IOphalenStandaardlandParams,
  IOphalenStandaardlandResult,
} from '../../../../shared/src/api/v2/land';

const land = {
  ophalen: async (params: IOphalenLandenVoorLijstParams) => {
    return await api.post<IOphalenLandenVoorLijstResult>(
      'v2/land/ophalen-landen-voor-lijst',
      params,
    );
  },
  ophalenStandaardland: async (params: IOphalenStandaardlandParams) => {
    return await api.post<IOphalenStandaardlandResult>('v2/land/ophalen-standaardland', params);
  },
};

export default land;
