import React, { useMemo } from 'react';
import { IRegelCompProps } from '../index';
import RegelBasis from '../RegelBasis';
import { ERegelboekstukType, IRegelboekstukFactuur } from '../../types';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../../../models/IRemoteData';
import { IOphalenFacturenBasisResultElement } from '../../../../../../../../shared/src/api/v2/factuur';
import Skeleton from 'react-loading-skeleton';
import RelatieVisualisatie from '../../../../../personalia/RelatieVisualisatie';
import FormatteerBedrag from '../../../../../MutatieBedrag';
import { format } from 'date-fns';
import { IconPriorityHigh, IconVink } from '../../../../../Icons';
import { Kleur } from '../../../../../../bedrijfslogica/constanten';
import { dagDatum } from '../../../../../../helpers/datum';

const FactuurRegel = (props: IRegelCompProps) => {
  const factID = (props.regel.regelboekstuk as IRegelboekstukFactuur).factID;

  const factuur = useMemo<IRemoteData<IOphalenFacturenBasisResultElement>>(() => {
    const entry = props.facturenCache[factID];
    if (entry === undefined) {
      return createPendingRemoteData();
    }
    return createReadyRemoteData(entry);
  }, [factID, props.facturenCache]);

  const nogTeBoeken = useMemo<IRemoteData<number>>(() => {
    if (props.boekstuksaldi.state === ERemoteDataState.Pending) {
      return createPendingRemoteData();
    }
    const boekstuksaldo = props.boekstuksaldi.data!.find(
      (x) => x.type === ERegelboekstukType.Factuur && x.factID === factID,
    );
    if (boekstuksaldo === undefined) {
      return createPendingRemoteData();
    }
    return createReadyRemoteData(boekstuksaldo.saldo);
  }, [props.boekstuksaldi, factID]);

  if (factuur.state === ERemoteDataState.Pending) {
    return <Skeleton height={100} />;
  }

  return (
    <RegelBasis isGeselecteerd={props.isGeselecteerd} onGeselecteerd={props.onGeselecteerd}>
      <div className="d-flex align-items-center">
        <h5 style={{ marginBottom: 0 }}>Factuur</h5>
        <span
          className="ml-3"
          style={{
            position: 'relative',
            top: 1,
          }}
        >
          {factuur.data!.Factuurnummer}
        </span>
        <div className="flex-fill" />
        <RelatieVisualisatie relID={factuur.data!.RelID} rootStyle={{ fontSize: 15 }} />
      </div>
      <div className="flex-fill mt-1">
        <span>
          <FormatteerBedrag bedrag={factuur.data!.Bedrag} fontSize={21} />
        </span>
      </div>
      <div
        className="d-flex align-items-center"
        style={{ height: 30, color: '#b4b4b4', fontSize: 15 }}
      >
        {factuur.data!.Onderwerp}
      </div>
      <table style={{ width: '100%' }}>
        <tbody>
          <tr>
            <td style={{ minWidth: 100 }}>Nog te boeken:</td>
            <td style={{ minWidth: 100 }}>
              {nogTeBoeken.state === ERemoteDataState.Pending ? (
                <Skeleton width={50} />
              ) : (
                <span className="d-flex align-items-center">
                  <FormatteerBedrag bedrag={factuur.data!.Bedrag + nogTeBoeken.data!} />
                  {factuur.data!.Bedrag + nogTeBoeken.data! === 0 ? (
                    <IconVink
                      className="ml-2"
                      style={{
                        width: 18,
                        height: 18,
                        fill: Kleur.Groen,
                      }}
                    />
                  ) : (
                    <IconPriorityHigh
                      className="ml-2"
                      style={{
                        width: 18,
                        height: 18,
                        fill: Kleur.Rood,
                      }}
                    />
                  )}
                </span>
              )}
            </td>
            <td style={{ minWidth: 100 }}>
              <span>Openstaand:</span>
            </td>
            <td style={{ minWidth: 100 }}>
              <FormatteerBedrag bedrag={factuur.data!.Openstaand} />
            </td>
            <td style={{ width: '99%' }}>
              <div className="d-flex align-items-center justify-content-end">
                <span className="mr-2">Factuurdatum:</span>
                <span>{format(new Date(factuur.data!.Factuurdatum), 'dd-MM-yyyy')}</span>
                {props.controleerBoekdatum && (
                  <span className="ml-2">
                    {props.boekdatum.state === ERemoteDataState.Pending ||
                    props.boekdatum.data === null ? (
                      <Skeleton width={18} />
                    ) : dagDatum(props.boekdatum.data!).getTime() ===
                      dagDatum(new Date(factuur.data!.Factuurdatum)).getTime() ? (
                      <span
                        title="De boekdatum is gelijk aan de factuurdatum"
                        style={{
                          position: 'relative',
                          top: -2,
                        }}
                      >
                        <IconVink
                          style={{
                            width: 18,
                            height: 18,
                            fill: Kleur.Groen,
                          }}
                        />
                      </span>
                    ) : (
                      <span
                        title="De boekdatum is niet gelijk aan de factuurdatum"
                        style={{
                          position: 'relative',
                          top: -2,
                        }}
                      >
                        <IconPriorityHigh
                          style={{
                            width: 18,
                            height: 18,
                            fill: Kleur.Rood,
                          }}
                        />
                      </span>
                    )}
                  </span>
                )}
              </div>
            </td>
          </tr>
          <tr>
            <td style={{ minWidth: 100 }}>
              <span>Bedrag BTW:</span>
            </td>
            <td style={{ minWidth: 100 }}>
              <FormatteerBedrag
                bedrag={factuur.data!.regels.reduce((acc, curr) => acc + (curr.BtwBedrag ?? 0), 0)}
              />
            </td>
            <td style={{ minWidth: 100 }}></td>
            <td style={{ minWidth: 100 }}></td>
            <td style={{ width: '99%' }}>
              <div className="d-flex align-items-center justify-content-end">
                <span className="mr-2">Vervaldatum:</span>
                <span>{format(new Date(factuur.data!.Vervaldatum), 'dd-MM-yyyy')}</span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </RegelBasis>
  );
};

export default FactuurRegel;
