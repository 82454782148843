import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IContentProps } from '../../shared';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../models/IRemoteData';
import { IOphalenLocatiesResult } from '../../../../../../shared/src/api/v2/locatie/locatie';
import api from '../../../../api';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import { formatteerAdresV2 } from '../../../../helpers';
import { IOphalenContractenResultV3 } from '../../../../../../shared/src/api/v2/contract';
import NavigerenPijl from '../../Rechts/NavigerenPijl';
import InkoopfactuurVisualisatie from '../../../../components/entiteitVisualisaties/InkoopfactuurVisualisatie';

const InkoopfactuurContent = (props: IContentProps) => {
  const handleNavigeren = useCallback(() => {}, []);

  return (
    <div style={{ paddingLeft: 35, paddingRight: 20 }} className="d-flex flex-column">
      {props.ids.map((inkFactID, i) => (
        <div key={inkFactID} className="p-2 d-flex align-items-center">
          <span className="flex-fill">
            <InkoopfactuurVisualisatie inkFactID={inkFactID} />
          </span>
          <NavigerenPijl onClick={handleNavigeren} />
        </div>
      ))}
    </div>
  );
};

export default InkoopfactuurContent;
