import api from '../index';
import {
  IOphalenAccountsParams,
  IOphalenAccountsResult,
  IVersturenActivatielinkParams,
  IVersturenActivatielinkResult,
  IVersturenWachtwoordVergetenlinkParams,
  IVersturenWachtwoordVergetenlinkResult,
  IWijzigenAccountParams,
  IWijzigenAccountResult,
  IVerwijderenAccountsParams,
  IVerwijderenAccountsResult,
  ICheckVerwijderenAccountsParams,
  ICheckVerwijderenAccountsResult,
  IToevoegenAccountParams,
  IVoorstelGebruikersnaamParams,
  IVoorstelGebruikersnaamResult,
} from '../../../../shared/src/api/v2/account/account';
import {
  IOphalenLoggingParams,
  IOphalenLoggingResult,
} from '../../../../shared/src/api/v2/relatie/account';
import ICheckData from '../../../../shared/src/models/ICheckData';

const account = {
  ophalenAccounts: async (
    params: IOphalenAccountsParams,
    signal?: AbortSignal,
  ): Promise<IOphalenAccountsResult> => {
    return await api.post('/v2/account/ophalen-accounts', params, {
      signal,
    });
  },
  versturenActivatielink: async (
    params: IVersturenActivatielinkParams,
  ): Promise<IVersturenActivatielinkResult> => {
    return await api.post('/v2/account/versturen-activatielink', params);
  },
  versturenWachtwoordVergetenLink: async (
    params: IVersturenWachtwoordVergetenlinkParams,
  ): Promise<IVersturenWachtwoordVergetenlinkResult> => {
    return await api.post('/v2/account/versturen-wachtwoord-vergetenlink', params);
  },
  checkWijzigenAccount: async (params: IWijzigenAccountParams): Promise<ICheckData> => {
    return await api.post('/v2/account/check-wijzigen-account', params);
  },
  wijzigenAccount: async (params: IWijzigenAccountParams): Promise<IWijzigenAccountResult> => {
    return await api.post('/v2/account/wijzigen-account', params);
  },
  checkVerwijderenAccounts: async (
    params: ICheckVerwijderenAccountsParams,
  ): Promise<ICheckVerwijderenAccountsResult> => {
    return await api.post('/v2/account/check-verwijderen-accounts', params);
  },
  verwijderenAccounts: async (
    params: IVerwijderenAccountsParams,
  ): Promise<IVerwijderenAccountsResult> => {
    return await api.post('/v2/account/verwijderen-accounts', params);
  },
  toevoegenAccount: async (params: IToevoegenAccountParams): Promise<any> => {
    return await api.post('/v2/account/toevoegen-account', params);
  },
  checkToevoegenAccount: async (params: IToevoegenAccountParams): Promise<ICheckData> => {
    return await api.post('/v2/account/check-toevoegen-account', params);
  },
  voorstelGebruikersnaam: async (
    params: IVoorstelGebruikersnaamParams,
  ): Promise<IVoorstelGebruikersnaamResult> => {
    return await api.post('/v2/account/voorstel-gebruikersnaam', params);
  },
  ophalenLogging: async (params: IOphalenLoggingParams): Promise<IOphalenLoggingResult> => {
    return await api.post('/v2/account/ophalen-logging', params);
  },
};

export default account;
