import {
  IOphalenOntvangerssoortenParams,
  IToevoegenOntvangerssoortParams,
  IToevoegenOntvangerssoortResult,
  IWijzigenOntvangerssoortParams,
  IWijzigenOntvangerssoortResult,
  IVerwijderenOntvangerssoortenParams,
  IVerwijderenOntvangerssoortenResult,
  IOphalenOntvangerssoortenResult,
} from '../../../../../../shared/src/api/v2/bulkbericht/ontvangerssoort';
import api from '../../../index';
import ICheckData from '../../../../../../shared/src/models/ICheckData';
import {
  IOphalenEmailadressenViaSelectieQueryParams,
  IOphalenEmailadressenViaSelectieQueryResult,
} from '../../../../../../shared/src/api/v2/bulkbericht';

const ontvangerssoort = {
  ophalenOntvangerssoorten: async (
    params: IOphalenOntvangerssoortenParams,
  ): Promise<IOphalenOntvangerssoortenResult> => {
    return await api.post('/v2/bulkbericht/ontvangerssoort/ophalen-ontvangerssoorten', params);
  },
  checkToevoegenOntvangerssoort: async (
    params: IToevoegenOntvangerssoortParams,
  ): Promise<ICheckData> => {
    return await api.post(
      '/v2/bulkbericht/ontvangerssoort/check-toevoegen-ontvangerssoort',
      params,
    );
  },
  toevoegenOntvangerssoort: async (
    params: IToevoegenOntvangerssoortParams,
  ): Promise<IToevoegenOntvangerssoortResult> => {
    return await api.post('/v2/bulkbericht/ontvangerssoort/toevoegen-ontvangerssoort', params);
  },
  checkWijzigenOntvangerssoort: async (
    params: IWijzigenOntvangerssoortParams,
  ): Promise<ICheckData> => {
    return await api.post('/v2/bulkbericht/ontvangerssoort/check-wijzigen-ontvangerssoort', params);
  },
  wijzigenOntvangerssoort: async (
    params: IWijzigenOntvangerssoortParams,
  ): Promise<IWijzigenOntvangerssoortResult> => {
    return await api.post('/v2/bulkbericht/ontvangerssoort/wijzigen-ontvangerssoort', params);
  },
  checkVerwijderenOntvangerssoorten: async (
    params: IVerwijderenOntvangerssoortenParams,
  ): Promise<ICheckData> => {
    return await api.post(
      '/v2/bulkbericht/ontvangerssoort/check-verwijderen-ontvangerssoorten',
      params,
    );
  },
  verwijderenOntvangerssoorten: async (
    params: IVerwijderenOntvangerssoortenParams,
  ): Promise<IVerwijderenOntvangerssoortenResult> => {
    return await api.post('/v2/bulkbericht/ontvangerssoort/verwijderen-ontvangerssoorten', params);
  },
  ophalenEmailadressenViaSelectieQuery: async (
    params: IOphalenEmailadressenViaSelectieQueryParams,
  ): Promise<IOphalenEmailadressenViaSelectieQueryResult> => {
    return await api.post(
      '/v2/bulkbericht/ontvangerssoort/ophalen-emailadressen-via-selectie-query',
      params,
    );
  },
};

export default ontvangerssoort;
