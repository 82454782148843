import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import Dialoog from '../../../../components/dialogen/Dialoog';
import IDialoogProps from '../../../../core/IDialoogProps';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import { Field, FieldProps, Formik, FormikActions, FormikErrors } from 'formik';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import FormikVeldFout from '../../../../components/formulier/FormikVeldFout';
import api from '../../../../api';
import { RootStoreContext } from '../../../../stores/RootStore';
import { EResultType } from '../../../../stores/CheckStore';
import nameOf from '../../../../core/nameOf';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import Combobox from '../../../../components/formulier/Combobox';
import { IOphalenDienstenResultElement } from '../../../../../../shared/src/api/v2/dienst/sponsoring';
import VinkVeld from '../../../../components/formulier/VinkVeld';
import { dagDatum } from '../../../../helpers/datum';
import DatumKiezer from '../../../../components/formulier/DatumKiezer';
import BedragInput from '../../../../components/formulier/BedragInput';

interface IDialoogResult {}

interface IProps extends IDialoogProps<IDialoogResult> {}

interface IFormikValues {
  inhoud: string | null;
  dienstID: number | null;
  definitief: boolean;
  contractdatum: Date;
  totaalBedrag: number;
  bedragBtw: number | null;
  bedragVoorstel: number | null;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  inhoud: 'Inhoud',
  dienstID: 'Sponsordienst',
  definitief: 'Definitief',
  contractdatum: 'Contractdatum',
  totaalBedrag: 'Totaalbedrag contract',
  bedragBtw: 'Btw-bedrag',
  bedragVoorstel: 'Voorstelbedrag',
};

const ToevoegenDialoog: React.FC<IProps> = (props) => {
  const { checkStore } = useContext(RootStoreContext);

  const [diensten, setDiensten] = useState<IOphalenDienstenResultElement[] | null>(null);

  const ophalenDiensten = useCallback(async () => {
    const dienstenResult = await api.v2.dienst.sponsoring.ophalenDiensten({
      filterSchema: {
        filters: [].filter((x) => x !== null),
      },
      orderSchema: { orders: [{ naam: 'NAAM', richting: 'ASC' }] },
    });

    setDiensten(dienstenResult.diensten);
  }, []);

  useEffect(() => {
    ophalenDiensten();
  }, [ophalenDiensten]);

  const initialValues = useMemo<IFormikValues>(() => {
    return {
      inhoud: null,
      dienstID: null,
      definitief: true,
      contractdatum: dagDatum(new Date()),
      totaalBedrag: 0,
      bedragBtw: null,
      bedragVoorstel: null,
    };
  }, []);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      if (values.dienstID === null) {
        return;
      }
      const params = {
        sponDienstID: values.dienstID!,
        definitief: values.definitief,
        registratiedatum: new Date(),
        contractdatum: new Date(),
        inhoud: values.inhoud!,
        bedragTotaal: values.totaalBedrag,
        bedragBtw: values.bedragBtw,
        bedragVoorstel: values.bedragVoorstel,
      };

      const checkData = await api.v2.sponsoring.contract.checkToevoegenContract(params);

      const controleResult = await checkStore.controleren({ checkData });
      if (controleResult.type === EResultType.Annuleren) {
        return;
      }

      const resultaat = await checkStore.bevestigen({
        inhoud: `Contract toevoegen?`,
      });
      if (resultaat.type === EResultType.Annuleren) {
        return;
      }

      await api.v2.sponsoring.contract.toevoegenContract(params);

      props.onSuccess({});
    },
    [],
  );

  const validate = useCallback((values: IFormikValues): FormikErrors<IFormikValues> => {
    const errors: FormikErrors<IFormikValues> = {};

    return errors;
  }, []);

  return (
    <Dialoog index={props.dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Nieuw sponsorcontract</ModalTitle>
      </ModalHeader>
      <Formik<IFormikValues>
        onSubmit={handleSubmit}
        validate={validate}
        initialValues={initialValues}
        render={(formikProps) => {
          const { submitForm, isSubmitting } = formikProps;
          return (
            <>
              <ModalBody>
                <div className="col-12">
                  <label>{veldnamen.dienstID}</label>
                  <Field
                    name={nameOf<IFormikValues>('dienstID')}
                    render={(fieldProps: FieldProps<IFormikValues>) => {
                      const { field, form } = fieldProps;

                      if (diensten === null) {
                        return <LoadingSpinner />;
                      }

                      return (
                        <div>
                          <Combobox
                            legeOptieTonen
                            geselecteerd={field.value}
                            onSelectieChange={(x) => form.setFieldValue(field.name, x)}
                            opties={diensten.map((x: any) => {
                              return {
                                id: x.ID,
                                label: x.Naam,
                              };
                            })}
                          />
                          <FormikVeldFout fieldProps={fieldProps} />
                        </div>
                      );
                    }}
                  />
                </div>

                <div className="col-12 mt-3">
                  <label>{veldnamen.contractdatum}</label>
                  <Field
                    name={nameOf<IFormikValues>('contractdatum')}
                    render={(fieldProps: FieldProps<IFormikValues>) => {
                      const { field, form } = fieldProps;
                      return (
                        <>
                          <DatumKiezer
                            isClearable
                            waarde={field.value}
                            onGewijzigd={(data) => form.setFieldValue(field.name, data)}
                          />
                          <FormikVeldFout fieldProps={fieldProps} />
                        </>
                      );
                    }}
                  />
                </div>

                <div className="col-12 mt-3">
                  <label>{veldnamen.inhoud}</label>
                  <Field
                    name={nameOf<IFormikValues>('inhoud')}
                    render={(fieldProps: FieldProps<IFormikValues>) => {
                      const { field, form } = fieldProps;
                      return (
                        <div>
                          <textarea className="form-control" {...field} rows={3} />
                          <FormikVeldFout fieldProps={fieldProps} />
                        </div>
                      );
                    }}
                  />
                </div>

                <div className="col-6 mt-3">
                  <label>{veldnamen.totaalBedrag}</label>
                  <Field
                    name={nameOf<IFormikValues>('totaalBedrag')}
                    render={(x: FieldProps<IFormikValues>) => {
                      return (
                        <div>
                          <BedragInput
                            value={x.field.value}
                            onChange={(bedrag) => {
                              x.form.setFieldValue(x.field.name, bedrag);
                            }}
                            min={0}
                          />
                        </div>
                      );
                    }}
                  />
                </div>

                <div className="col-6 mt-3">
                  <label>{veldnamen.bedragBtw}</label>
                  <Field
                    name={nameOf<IFormikValues>('bedragBtw')}
                    render={(x: FieldProps<IFormikValues>) => {
                      return (
                        <div>
                          <BedragInput
                            value={x.field.value}
                            onChange={(bedrag) => {
                              x.form.setFieldValue(x.field.name, bedrag);
                            }}
                            min={0}
                          />
                        </div>
                      );
                    }}
                  />
                </div>

                <div className="col-12 d-flex align-items-center mt-3">
                  <Field
                    name={nameOf<IFormikValues>('definitief')}
                    render={(fieldProps: FieldProps<IFormikValues>) => {
                      const { field, form } = fieldProps;
                      return (
                        <VinkVeld
                          aangevinkt={field.value}
                          onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                        />
                      );
                    }}
                  />
                  <span className="ml-2">{veldnamen.definitief}</span>
                </div>
              </ModalBody>
              <ModalFooter className="d-flex flex-row justify-content-start">
                <button
                  className="btn btn-primary d-flex align-items-center justify-content-center"
                  onClick={submitForm}
                  style={{ width: 100 }}
                  disabled={isSubmitting}
                >
                  Ok
                </button>
                <button
                  className="btn btn-secondary"
                  onClick={props.onAnnuleren}
                  style={{ width: 100 }}
                  disabled={isSubmitting}
                >
                  Annuleren
                </button>
              </ModalFooter>
            </>
          );
        }}
      />
    </Dialoog>
  );
};

export default ToevoegenDialoog;
