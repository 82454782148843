import React, { SVGProps, useMemo } from 'react';
import { Kleur } from '../../../bedrijfslogica/constanten';
import {
  IconBellen,
  IconEmail,
  IconKruis,
  IconSlepen,
  IconSms,
  IconWhatsapp,
} from '../../../components/Icons';
import CommunicatieTabblad from './CommunicatieTabblad';
import { observer } from 'mobx-react-lite';

export enum ECommunicatieTabblad {
  Telefoon,
  Whatsapp,
  Sms,
  Email,
}

export interface ITabblad {
  id: ECommunicatieTabblad;
  icon: React.ComponentType<SVGProps<any>>;
}

interface IProps {
  geselecteerdTabblad: ECommunicatieTabblad | null;
  onGeselecteerdTabbladChange: (tabblad: ECommunicatieTabblad | null) => void;
  isDragging: boolean;
}

const MenuHandle: React.FC<IProps> = observer((props) => {
  const tabbladen = useMemo<ITabblad[]>(
    () => [
      {
        id: ECommunicatieTabblad.Telefoon,
        icon: IconBellen,
      },
      {
        id: ECommunicatieTabblad.Whatsapp,
        icon: IconWhatsapp,
      },
      {
        id: ECommunicatieTabblad.Sms,
        icon: IconSms,
      },
      {
        id: ECommunicatieTabblad.Email,
        icon: IconEmail,
      },
    ],
    [],
  );

  return (
    <div
      className="d-flex flex-fill"
      style={{
        pointerEvents: 'all',
      }}
    >
      {/*<div*/}
      {/*  style={{ minWidth: props.geselecteerdTabblad === null ? 0 : 300, maxWidth: 300 }}*/}
      {/*  className="d-flex flex-column"*/}
      {/*>*/}
      {/*  {actieveOproepOutput !== null ? (*/}
      {/*    <TelefoongesprekActueel actieveOproepOutput={actieveOproepOutput} />*/}
      {/*  ) : rinkelendeOproepenOutput !== null ? (*/}
      {/*    <TelefoongesprekRinkelende rinkelendeOproepenOutput={rinkelendeOproepenOutput} />*/}
      {/*  ) : props.geselecteerdTabblad !== null ? (*/}
      {/*    <TelefoongesprekInactief />*/}
      {/*  ) : null}*/}
      {/*</div>*/}
      <div
        className="d-flex flex-fill"
        style={{
          borderLeft: `1px solid ${Kleur.Grijs}`,
          borderTop: `1px solid ${Kleur.Grijs}`,
          borderRight: `1px solid ${Kleur.Grijs}`,
          pointerEvents: 'all',
          backgroundColor: 'rgb(67,2,71)',
          // borderTopLeftRadius: 5,
          // borderTopRightRadius: 5,
        }}
      >
        <div
          className="d-flex"
          style={{
            backgroundColor: '#73027a',
          }}
        >
          {tabbladen.map((tabblad, i) => {
            const isGeselecteerd = props.geselecteerdTabblad === tabblad.id;
            return (
              <CommunicatieTabblad
                key={tabblad.id}
                geselecteerd={isGeselecteerd}
                icon={tabblad.icon}
                isFirst={i === 0}
                isLast={i === tabbladen.length - 1}
                onGeselecteerd={() =>
                  props.onGeselecteerdTabbladChange(isGeselecteerd ? null : tabblad.id)
                }
              />
            );
          })}
        </div>

        <div className="flex-fill d-flex">
          <div
            className="flex-fill d-flex align-items-center justify-content-center p-2 pl-3 pr-3 rnd-drag-handle"
            style={{ cursor: props.isDragging ? 'grabbing' : 'grab' }}
          >
            <IconSlepen style={{ width: 20, height: 20, fill: Kleur.Wit }} />
          </div>
          {props.geselecteerdTabblad !== null && (
            <div
              className="d-flex align-items-center justify-content-center p-2 pl-3 pr-3 "
              style={{ cursor: 'pointer', backgroundColor: Kleur.Rood }}
              onClick={() => props.onGeselecteerdTabbladChange(null)}
            >
              <IconKruis style={{ width: 19, height: 19, fill: Kleur.Wit }} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
});

export default MenuHandle;
