import React, { useCallback, useMemo, useRef, useState } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { Kleur } from '../../../bedrijfslogica/constanten';
import Popover from 'react-bootstrap/Popover';

import { v4 as uuid } from 'uuid';
import InfoCard from '../../personalia/PersoonVisualisatie/InfoCard';

interface IProps {
  inhoud: string | JSX.Element | JSX.Element[];
  innerContainerProps?: React.HTMLAttributes<HTMLDivElement>;
}

const UitlegTooltip: React.FC<IProps> = (props) => {
  const id = useMemo(() => uuid(), []);

  const [showOverlay, setShowOverlay] = useState(false);
  const showOverlayTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const handleMouseEnterOverlay = useCallback(() => {
    if (showOverlayTimeoutRef.current !== null) {
      clearTimeout(showOverlayTimeoutRef.current);
    }
    showOverlayTimeoutRef.current = setTimeout(() => setShowOverlay(true), 800);
  }, []);
  const handleMouseLeaveOverlay = useCallback(() => {
    if (showOverlayTimeoutRef.current !== null) {
      clearTimeout(showOverlayTimeoutRef.current);
    }
    showOverlayTimeoutRef.current = setTimeout(() => setShowOverlay(false), 400);
  }, []);
  const renderOverlay = useCallback(
    (p: any) => {
      return (
        <Popover
          id={id}
          {...p}
          onMouseEnter={handleMouseEnterOverlay}
          onMouseLeave={handleMouseLeaveOverlay}
          style={{
            ...p.style,
            // backgroundColor: Kleur.Wit,
            // zIndex: 99999,
            // border: `1px solid ${Kleur.Grijs}`,
            // borderRadius: 5,
          }}
        >
          <div className="p-2">{props.inhoud}</div>
        </Popover>
      );
    },
    [id, handleMouseEnterOverlay, handleMouseLeaveOverlay],
  );

  return (
    <OverlayTrigger
      trigger="hover"
      placement="auto-start"
      overlay={renderOverlay}
      show={showOverlay}
      // popperConfig={
      //   {
      //     modifiers: {
      //       name: 'offset',
      //       options: {
      //         offset: [0, 5],
      //       },
      //     },
      //   } as any
      // }
    >
      <div
        {...props.innerContainerProps}
        onMouseEnter={handleMouseEnterOverlay}
        onMouseLeave={handleMouseLeaveOverlay}
      >
        {props.children ?? (
          <span
            style={{
              backgroundColor: Kleur.LichtGrijs,
              paddingLeft: 6,
              paddingRight: 6,
              paddingTop: 1,
              paddingBottom: 1,
              borderRadius: '50%',
              fontSize: 11,
              cursor: 'help',
              // fontWeight: 'bold',
            }}
          >
            ?
          </span>
        )}
      </div>
    </OverlayTrigger>
  );
};

export default UitlegTooltip;
