import React, { useContext, useEffect, useMemo, useRef } from 'react';
import { IProps } from '@tinymce/tinymce-react/lib/cjs/main/ts/components/Editor';
import { Field, FieldProps } from 'formik';
import VinkVeld from '../../../formulier/VinkVeld';
import { IFormikValues, veldnamen } from '..';
import nameOf from '../../../../core/nameOf';
import Combobox from '../../../formulier/Combobox';
import LoadingSpinner from '../../../Gedeeld/LoadingSpinner';
import { FactuurContext, IContext } from '../Context';
import { FormulierContext, IFormulierContext } from '../Context';
import BedragInput from '../../../formulier/BedragInput';
import DatumKiezer from '../../../formulier/DatumKiezer';
import { addDays } from 'date-fns';
import useBijGewijzigdEffect from '../../../../core/useBijGewijzigdEffect';
import FormikVeldFout from '../../../formulier/FormikVeldFout';
import TekstSuggestieVeld, {
  IExposeData,
  IInputComponentProps,
} from '../../../formulier/TekstSuggestieVeld';

const FactuurTab: React.FC<IProps> = (props) => {
  const { factuur, betaalwijzen } = useContext<IContext>(FactuurContext);

  const { values, setFieldValue } = useContext<IFormulierContext>(FormulierContext);

  useBijGewijzigdEffect(() => {
    if (values.credit) {
      setFieldValue(nameOf<IFormikValues>('betWijzeID'), null);
    }
  }, [values.credit]);

  const factuurnummerTekstSuggestieVeldExposeRef = useRef<IExposeData>();

  const factuurnummerInputComponent = useMemo<
    React.ForwardRefRenderFunction<any, IInputComponentProps>
  >(
    () => (inputProps, ref) => (
      // <textarea {...(inputProps as any)} ref={ref} className="form-control" rows={1} />
      <input type="text" className="form-control" {...(inputProps as any)} ref={ref} />
    ),
    [],
  );

  const factuurreferentieInputComponent = useMemo<
    React.ForwardRefRenderFunction<any, IInputComponentProps>
  >(
    () => (inputProps, ref) => (
      <input
        type="text"
        className="form-control"
        {...(inputProps as any)}
        ref={ref}
        placeholder={
          'B.v. Rel.nr 23001, Fact.nr F509191-01. Indien leeg wordt het factuurnummer aangehouden'
        }
      />
      // <textarea
      //   {...(inputProps as any)}
      //   ref={ref}
      //   className="form-control"
      //   rows={1}
      //   placeholder={
      //     'B.v. Rel.nr 23001, Fact.nr F509191-01. Indien leeg wordt het factuurnummer aangehouden'
      //   }
      // />
    ),
    [],
  );

  return (
    <>
      <div className="p-3">
        <div className="row">
          <div className="col-12">
            <label>{veldnamen.factuurnummer}</label>
            <Field
              name={nameOf<IFormikValues>('factuurnummer')}
              render={(fieldProps: FieldProps<IFormikValues>) => {
                const { field, form } = fieldProps;
                return (
                  <div>
                    <TekstSuggestieVeld
                      waarde={field.value}
                      onChange={(x) => form.setFieldValue(field.name, x)}
                      suggestiesResolver={async (waarde) => {
                        // if (factuurnummers.state === ERemoteDataState.Pending) {
                        //   return [];
                        // }
                        // return factuurnummers.data! ?? [];
                        return [];
                      }}
                      inputComponent={factuurnummerInputComponent}
                      onExpose={(expose) =>
                        (factuurnummerTekstSuggestieVeldExposeRef.current = expose)
                      }
                    />
                    <FormikVeldFout fieldProps={fieldProps} />
                  </div>
                );
              }}
            />
          </div>

          <div className="col-12 mt-3">
            <label>{veldnamen.factuurreferentie}</label>
            <Field
              name={nameOf<IFormikValues>('factuurreferentie')}
              render={(fieldProps: FieldProps<IFormikValues>) => {
                const { field, form } = fieldProps;
                return (
                  <div>
                    <TekstSuggestieVeld
                      waarde={field.value}
                      onChange={(x) => form.setFieldValue(field.name, x)}
                      suggestiesResolver={async (waarde) => {
                        // Stel rijbron samen met bv Factuurnr of Relatienr/Factuurnr

                        // const factuurnummer =
                        //   form.values.factuurnummer !== '' ? form.values.factuurnummer : null;
                        // const relatienummer =
                        //   relatie.state === ERemoteDataState.Ready && relatie.data !== null
                        //     ? relatie.data.Relatienummer
                        //     : null;

                        // const optie1 =
                        //   relatienummer !== null && factuurnummer !== null
                        //     ? 'Rel.nr ' + relatienummer + ', Fact.nr ' + factuurnummer
                        //     : null;
                        // const optie2 =
                        //   relatienummer !== null && factuurnummer === null
                        //     ? 'Rel.nr ' + relatienummer
                        //     : null;
                        // const optie3 =
                        //   relatienummer === null && factuurnummer !== null
                        //     ? 'Fact.nr ' + factuurnummer
                        //     : null;

                        // const opties = [];
                        // if (optie1 !== null) {
                        //   opties.push(optie1);
                        // }
                        // if (optie2 !== null) {
                        //   opties.push(optie2);
                        // }
                        // if (optie3 !== null) {
                        //   opties.push(optie3);
                        // }

                        const opties: any[] = [];

                        return opties;
                      }}
                      inputComponent={factuurreferentieInputComponent}
                      onExpose={(expose) =>
                        (factuurnummerTekstSuggestieVeldExposeRef.current = expose)
                      }
                    />
                    <FormikVeldFout fieldProps={fieldProps} />
                  </div>
                );
              }}
            />
          </div>

          <div className="col-12 mt-2">
            <label>{veldnamen.onderwerp}</label>
            <Field
              name={nameOf<IFormikValues>('onderwerp')}
              render={(fieldProps: FieldProps<IFormikValues>) => {
                const { field, form } = fieldProps;
                return (
                  <div>
                    <TekstSuggestieVeld
                      waarde={field.value}
                      onChange={(x) => form.setFieldValue(field.name, x)}
                      suggestiesResolver={async (waarde) => {
                        // Stel rijbron samen met bv Factuurnr of Relatienr/Factuurnr

                        // const factuurnummer =
                        //   form.values.factuurnummer !== '' ? form.values.factuurnummer : null;
                        // const relatienummer =
                        //   relatie.state === ERemoteDataState.Ready && relatie.data !== null
                        //     ? relatie.data.Relatienummer
                        //     : null;

                        // const optie1 =
                        //   relatienummer !== null && factuurnummer !== null
                        //     ? 'Rel.nr ' + relatienummer + ', Fact.nr ' + factuurnummer
                        //     : null;
                        // const optie2 =
                        //   relatienummer !== null && factuurnummer === null
                        //     ? 'Rel.nr ' + relatienummer
                        //     : null;
                        // const optie3 =
                        //   relatienummer === null && factuurnummer !== null
                        //     ? 'Fact.nr ' + factuurnummer
                        //     : null;

                        // const opties = [];
                        // if (optie1 !== null) {
                        //   opties.push(optie1);
                        // }
                        // if (optie2 !== null) {
                        //   opties.push(optie2);
                        // }
                        // if (optie3 !== null) {
                        //   opties.push(optie3);
                        // }

                        const opties: any[] = [];

                        return opties;
                      }}
                      inputComponent={factuurreferentieInputComponent}
                      onExpose={(expose) =>
                        (factuurnummerTekstSuggestieVeldExposeRef.current = expose)
                      }
                    />
                    <FormikVeldFout fieldProps={fieldProps} />
                  </div>
                );
              }}
            />
          </div>

          <div className="col-4 mt-2">
            <label>{veldnamen.factuurdatum}</label>
            <Field
              name={nameOf('factuurdatum')}
              render={({ field, form }: FieldProps<IFormikValues>) => {
                const minimaleDatum = addDays(new Date(), -365);
                const maximaleDatum = addDays(new Date(), +30);
                return (
                  <div className="d-flex align-items-center">
                    <DatumKiezer
                      onGewijzigd={(x) => {
                        form.setFieldValue(field.name, x);
                      }}
                      waarde={field.value}
                      determineValidDate={(date) => {
                        return date >= minimaleDatum && date <= maximaleDatum;
                      }}
                      determineNextValidDate={(date) => {
                        const nieuweDatum = addDays(date, +1);
                        if (nieuweDatum <= maximaleDatum) {
                          return nieuweDatum;
                        }
                        return null;
                      }}
                      determinePreviousValidDate={(date) => {
                        const nieuweDatum = addDays(date, -1);
                        if (nieuweDatum >= minimaleDatum) {
                          return nieuweDatum;
                        }
                        return null;
                      }}
                    />
                  </div>
                );
              }}
            />
          </div>

          <div className="col-4 mt-2">
            <label>{veldnamen.vervaldatum}</label>
            <Field
              name={nameOf('vervaldatum')}
              render={({ field, form }: FieldProps<IFormikValues>) => {
                const minimaleDatum = addDays(new Date(), -365);
                const maximaleDatum = addDays(new Date(), +30);
                return (
                  <div className="d-flex align-items-center">
                    <DatumKiezer
                      onGewijzigd={(x) => {
                        form.setFieldValue(field.name, x);
                      }}
                      waarde={field.value}
                      determineValidDate={(date) => {
                        return date >= minimaleDatum && date <= maximaleDatum;
                      }}
                      determineNextValidDate={(date) => {
                        const nieuweDatum = addDays(date, +1);
                        if (nieuweDatum <= maximaleDatum) {
                          return nieuweDatum;
                        }
                        return null;
                      }}
                      determinePreviousValidDate={(date) => {
                        const nieuweDatum = addDays(date, -1);
                        if (nieuweDatum >= minimaleDatum) {
                          return nieuweDatum;
                        }
                        return null;
                      }}
                    />
                  </div>
                );
              }}
            />
          </div>

          <div className="col-4 mt-2">
            <label>{veldnamen.vervaldatumKorting}</label>
            <Field
              name={nameOf('vervaldatumKorting')}
              render={({ field, form }: FieldProps<IFormikValues>) => {
                const minimaleDatum = addDays(new Date(), -365);
                const maximaleDatum = addDays(new Date(), +30);
                return (
                  <div className="d-flex align-items-center">
                    <DatumKiezer
                      onGewijzigd={(x) => {
                        form.setFieldValue(field.name, x);
                      }}
                      waarde={field.value}
                      determineValidDate={(date) => {
                        return date >= minimaleDatum && date <= maximaleDatum;
                      }}
                      determineNextValidDate={(date) => {
                        const nieuweDatum = addDays(date, +1);
                        if (nieuweDatum <= maximaleDatum) {
                          return nieuweDatum;
                        }
                        return null;
                      }}
                      determinePreviousValidDate={(date) => {
                        const nieuweDatum = addDays(date, -1);
                        if (nieuweDatum >= minimaleDatum) {
                          return nieuweDatum;
                        }
                        return null;
                      }}
                    />
                  </div>
                );
              }}
            />
          </div>
        </div>

        <div className="row mt-2">
          <div className="col-4 align-items-left">
            <label>{veldnamen.bedrag}</label>
            <Field
              name={nameOf<IFormikValues>('bedrag')}
              render={(fieldProps: FieldProps<IFormikValues>) => {
                const { field, form } = fieldProps;
                return (
                  <>
                    <BedragInput
                      value={Math.abs(field.value) || 0}
                      onChange={(x) => {
                        form.setFieldValue(field.name, x);
                      }}
                    />
                    {/* <FormikVeldFout fieldProps={fieldProps} /> */}
                  </>
                );
              }}
            />
          </div>
          <div className="col-4 align-items-left">
            <label>{veldnamen.bedragBtw}</label>
            <Field
              name={nameOf<IFormikValues>('bedragBtw')}
              render={(fieldProps: FieldProps<IFormikValues>) => {
                const { field, form } = fieldProps;
                return (
                  <>
                    <BedragInput
                      value={Math.abs(field.value) || 0}
                      onChange={(x) => {
                        form.setFieldValue(field.name, x);
                      }}
                    />
                    {/* <FormikVeldFout fieldProps={fieldProps} /> */}
                  </>
                );
              }}
            />
          </div>
          <div className="col-4 align-items-left">
            <label>{veldnamen.bedragKorting}</label>
            <Field
              name={nameOf<IFormikValues>('bedragKorting')}
              render={(fieldProps: FieldProps<IFormikValues>) => {
                const { field, form } = fieldProps;
                return (
                  <>
                    <BedragInput
                      value={Math.abs(field.value) || 0}
                      onChange={(x) => {
                        form.setFieldValue(field.name, x);
                      }}
                    />
                    {/* <FormikVeldFout fieldProps={fieldProps} /> */}
                  </>
                );
              }}
            />
          </div>
        </div>

        <div className="row mt-2">
          <div className="col-6">
            <Field
              name={nameOf<IFormikValues>('credit')}
              render={(fieldProps: FieldProps<IFormikValues>) => {
                const { field, form } = fieldProps;
                return (
                  <div className="d-flex align-items-center">
                    <VinkVeld
                      onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                      aangevinkt={field.value}
                      disabled={values.bedrag === 0}
                    />
                    <span className="ml-2">{veldnamen.credit}</span>
                  </div>
                );
              }}
            />
          </div>
        </div>

        <div className="row mt-2">
          <div className="col-6">
            <label>{veldnamen.betWijzeID}</label>
            <Field
              name={nameOf<IFormikValues>('betWijzeID')}
              render={(fieldProps: FieldProps<IFormikValues>) => {
                const { field, form } = fieldProps;

                if (betaalwijzen === null) {
                  return <LoadingSpinner />;
                }

                return (
                  <div>
                    <Combobox
                      legeOptieTonen
                      geselecteerd={field.value}
                      onSelectieChange={(x) => form.setFieldValue(field.name, x)}
                      opties={betaalwijzen.map((x) => {
                        return {
                          id: x.BetWijzeID,
                          label: x.Naam,
                        };
                      })}
                    />
                    <FormikVeldFout fieldProps={fieldProps} />
                  </div>
                );
              }}
            />
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-5 d-flex">
            <Field
              name={nameOf<IFormikValues>('koppelenProducten')}
              render={(fieldProps: FieldProps<IFormikValues>) => {
                const { field, form } = fieldProps;
                return (
                  <VinkVeld
                    aangevinkt={field.value}
                    onGewijzigd={(x) => {
                      form.setFieldValue(field.name, x);
                    }}
                  />
                );
              }}
            />
            <label className="ml-3">{veldnamen.koppelenProducten}</label>
          </div>

          <div className="col-6 d-flex">
            <Field
              name={nameOf<IFormikValues>('productenGekoppeld')}
              render={(fieldProps: FieldProps<IFormikValues>) => {
                const { field, form } = fieldProps;
                return (
                  <VinkVeld
                    disabled={!form.values.koppelenProducten}
                    aangevinkt={field.value}
                    onGewijzigd={(x) => {
                      form.setFieldValue(field.name, x);
                    }}
                  />
                );
              }}
            />
            <label className="ml-3">{veldnamen.productenGekoppeld}</label>
          </div>
        </div>

        <div className="row mt-1">
          <div className="col-5 d-flex">
            <Field
              name={nameOf<IFormikValues>('koppelenWerkbonnen')}
              render={(fieldProps: FieldProps<IFormikValues>) => {
                const { field, form } = fieldProps;
                return (
                  <VinkVeld
                    aangevinkt={field.value}
                    onGewijzigd={(x) => {
                      form.setFieldValue(field.name, x);
                    }}
                  />
                );
              }}
            />
            <label className="ml-3">{veldnamen.koppelenWerkbonnen}</label>
          </div>

          <div className="col-6 d-flex">
            <Field
              name={nameOf<IFormikValues>('werkbonnenGekoppeld')}
              render={(fieldProps: FieldProps<IFormikValues>) => {
                const { field, form } = fieldProps;
                return (
                  <VinkVeld
                    disabled={!form.values.koppelenWerkbonnen}
                    aangevinkt={field.value}
                    onGewijzigd={(x) => {
                      form.setFieldValue(field.name, x);
                    }}
                  />
                );
              }}
            />
            <label className="ml-3">{veldnamen.werkbonnenGekoppeld}</label>
          </div>
        </div>

        <div className="row mt-1">
          <div className="col-5 d-flex">
            <Field
              name={nameOf<IFormikValues>('koppelenTransportopdrachten')}
              render={(fieldProps: FieldProps<IFormikValues>) => {
                const { field, form } = fieldProps;
                return (
                  <VinkVeld
                    aangevinkt={field.value}
                    onGewijzigd={(x) => {
                      form.setFieldValue(field.name, x);
                    }}
                  />
                );
              }}
            />
            <label className="ml-3">{veldnamen.koppelenTransportopdrachten}</label>
          </div>

          <div className="col-6 d-flex">
            <Field
              name={nameOf<IFormikValues>('transportopdrachtenGekoppeld')}
              render={(fieldProps: FieldProps<IFormikValues>) => {
                const { field, form } = fieldProps;
                return (
                  <VinkVeld
                    disabled={!form.values.koppelenTransportopdrachten}
                    aangevinkt={field.value}
                    onGewijzigd={(x) => {
                      form.setFieldValue(field.name, x);
                    }}
                  />
                );
              }}
            />
            <label className="ml-3">{veldnamen.transportopdrachtenGekoppeld}</label>
          </div>
        </div>

        <div className="row mt-1">
          <div className="col-5 d-flex">
            <Field
              name={nameOf<IFormikValues>('koppelenRetourProducten')}
              render={(fieldProps: FieldProps<IFormikValues>) => {
                const { field, form } = fieldProps;
                return (
                  <VinkVeld
                    aangevinkt={field.value}
                    onGewijzigd={(x) => {
                      form.setFieldValue(field.name, x);
                    }}
                  />
                );
              }}
            />
            <label className="ml-3">{veldnamen.koppelenRetourProducten}</label>
          </div>

          <div className="col-6 d-flex">
            <Field
              name={nameOf<IFormikValues>('retourProductenGekoppeld')}
              render={(fieldProps: FieldProps<IFormikValues>) => {
                const { field, form } = fieldProps;
                return (
                  <VinkVeld
                    disabled={!form.values.koppelenRetourProducten}
                    aangevinkt={field.value}
                    onGewijzigd={(x) => {
                      form.setFieldValue(field.name, x);
                    }}
                  />
                );
              }}
            />
            <label className="ml-3">{veldnamen.retourProductenGekoppeld}</label>
          </div>
        </div>

        <div className="row mt-1">
          <div className="col-5 d-flex">
            <Field
              name={nameOf<IFormikValues>('koppelenSponsorcontracten')}
              render={(fieldProps: FieldProps<IFormikValues>) => {
                const { field, form } = fieldProps;
                return (
                  <VinkVeld
                    aangevinkt={field.value}
                    onGewijzigd={(x) => {
                      form.setFieldValue(field.name, x);
                    }}
                  />
                );
              }}
            />
            <label className="ml-3">{veldnamen.koppelenSponsorcontracten}</label>
          </div>

          <div className="col-6 d-flex">
            <Field
              name={nameOf<IFormikValues>('sponsorcontractenGekoppeld')}
              render={(fieldProps: FieldProps<IFormikValues>) => {
                const { field, form } = fieldProps;
                return (
                  <VinkVeld
                    disabled={!form.values.koppelenSponsorcontracten}
                    aangevinkt={field.value}
                    onGewijzigd={(x) => {
                      form.setFieldValue(field.name, x);
                    }}
                  />
                );
              }}
            />
            <label className="ml-3">{veldnamen.sponsorcontractenGekoppeld}</label>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-5 d-flex">
            <Field
              name={nameOf<IFormikValues>('gefiatteerd')}
              render={(fieldProps: FieldProps<IFormikValues>) => {
                const { field, form } = fieldProps;
                return (
                  <VinkVeld
                    aangevinkt={field.value}
                    onGewijzigd={(x) => {
                      form.setFieldValue(field.name, x);
                    }}
                  />
                );
              }}
            />
            <label className="ml-3">{veldnamen.gefiatteerd}</label>
          </div>

          <div className="col-6 d-flex">
            <Field
              name={nameOf<IFormikValues>('geboekt')}
              render={(fieldProps: FieldProps<IFormikValues>) => {
                const { field, form } = fieldProps;
                return (
                  <VinkVeld
                    aangevinkt={field.value}
                    onGewijzigd={(x) => {
                      form.setFieldValue(field.name, x);
                    }}
                  />
                );
              }}
            />
            <label className="ml-3">{veldnamen.geboekt}</label>
          </div>

          <div className="col-6 d-flex mt-2">
            <Field
              name={nameOf<IFormikValues>('aanhouden')}
              render={(fieldProps: FieldProps<IFormikValues>) => {
                const { field, form } = fieldProps;
                return (
                  <VinkVeld
                    aangevinkt={field.value}
                    onGewijzigd={(x) => {
                      form.setFieldValue(field.name, x);
                    }}
                  />
                );
              }}
            />
            <label className="ml-3">{veldnamen.aanhouden}</label>
          </div>
        </div>

        {/* <div className="row mt-3">
          <div className="col-6 d-flex">
            <Field
              name={nameof<IFormikValues>('gefiatteerd')}
              render={(fieldProps: FieldProps<IFormikValues>) => {
                const { field, form } = fieldProps;
                return (
                  <VinkVeld
                    aangevinkt={field.value}
                    onGewijzigd={(x) => {
                      form.setFieldValue(field.name, x);
                    }}
                  />
                );
              }}
            />
            <label className="ml-3">{veldnamen.gefiatteerd}</label>
          </div>
        </div> */}

        <div className="row">
          <div className="col-12 mt-3">
            <label>{veldnamen.notities}</label>
            <Field
              name={nameOf<IFormikValues>('notities')}
              render={(fieldProps: FieldProps<IFormikValues>) => {
                const { field, form } = fieldProps;
                return <textarea {...field} className="form-control" rows={2} />;
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default FactuurTab;
