import * as React from 'react';
import { Kleur } from '../../../../../bedrijfslogica/constanten';

interface IProps {
  naam: string;
  actief?: boolean;
  onClick?: () => void;
}

const RolItem = (props: IProps) => {
  return (
    <div
      className="pl-3 pr-3"
      style={{
        cursor: 'pointer',
      }}
      onClick={props.onClick}
    >
      <div
        style={{
          backgroundColor: props.actief ? Kleur.Actief : undefined,
          borderRadius: 5,
          padding: '8px 15px',
        }}
      >
        {props.naam}
      </div>
    </div>
  );
};

export default RolItem;
