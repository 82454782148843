import React, { useCallback, useContext, useMemo } from 'react';
import { IProps } from '@tinymce/tinymce-react/lib/cjs/main/ts/components/Editor';
import { IContext, BoekingContext } from '../index';
import LoadingSpinner from '../../../../Gedeeld/LoadingSpinner';
import { format } from 'date-fns';
import FormatteerBedrag from '../../../../MutatieBedrag';
import nameOf from '../../../../../core/nameOf';
import VeldWeergave from '../../../../formulier/VeldWeergave';
import GegevensLayout from '../../../../layout/GegevensLayout';
import { Kleur as EKleur } from '../../../../../bedrijfslogica/constanten';
import InkoopfactuurVisualisatie from '../../../../entiteitVisualisaties/InkoopfactuurVisualisatie';
import FactuurVisualisatie from '../../../../entiteitVisualisaties/FactuurVisualisatie';
import BoekingregelsComp from '../../BoekingregelsComp';
import _ from 'lodash';

const BoekingTab: React.FC<IProps> = (props) => {
  const { boeking, exports, boekingregels, facturen, inkoopfacturen } = useContext<IContext>(
    BoekingContext,
  );

  if (
    boeking === null ||
    exports === null ||
    boekingregels === null ||
    facturen === null ||
    inkoopfacturen === null
  ) {
    return (
      <div className="d-flex flex-fill align-items-center justify-content-center">
        <LoadingSpinner />
      </div>
    );
  }

  const laatstGewijzigdRegels = _.max(
    boekingregels.filter((x) => x.RecordGewijzigd !== null).map((x) => x.RecordGewijzigd),
  );

  const laatstGewijzigd =
    boeking!.RecordGewijzigd !== null
      ? boeking!.RecordGewijzigd > laatstGewijzigdRegels
        ? boeking.RecordGewijzigd
        : laatstGewijzigdRegels
      : null;

  return (
    <>
      <div className="d-flex flex-column">
        <div className="row">
          <div className="col-12 d-flex mt-3">
            <div className="col-6">
              <VeldWeergave>
                <GegevensLayout
                  gegevens={[
                    {
                      label: 'Boekdatum',
                      waarde: format(new Date(boeking.Boekdatum), 'dd-MM-yyyy'),
                    },
                    { label: 'Boeknummer', waarde: boeking.Boeknummer.toString() },
                    {
                      label: 'Definitief',
                      waarde: boeking.Definitief ? (
                        <span>Ja</span>
                      ) : (
                        <span style={{ color: EKleur.Rood }}>Nee</span>
                      ),
                    },
                    null,

                    {
                      label: 'Geregistreerd',
                      waarde:
                        boeking.RecordToegevoegd !== null
                          ? format(new Date(boeking.RecordToegevoegd), 'dd-MM-yyyy HH:mm')
                          : 'Niet bekend',
                    },
                    {
                      label: 'Laatst gewijzigd',
                      waarde:
                        laatstGewijzigd !== null
                          ? format(new Date(laatstGewijzigd), 'dd-MM-yyyy HH:mm')
                          : 'Niet bekend',
                    },
                  ]}
                />
              </VeldWeergave>
            </div>
            <div className="col-6">
              <VeldWeergave>
                <GegevensLayout
                  gegevens={[
                    { label: 'Dagboeksoort', waarde: boeking.dagboek.dagboekSoort.Naam },
                    { label: 'Dagboek', waarde: boeking.dagboek.Naam },
                    { label: 'Dagboeknummer', waarde: boeking.dagboek.Nummer.toString() },
                    null,
                    {
                      label: 'Geexporteerd',
                      waarde: !boeking.NietExporteren
                        ? boeking.BoekingExportID !== null
                          ? format(
                              new Date(
                                exports.find((x) => x.ID === boeking.BoekingExportID)!.Datum,
                              ),
                              'dd-MM-yyyy HH:mm',
                            )
                          : 'Nee'
                        : 'Niet exporteren',
                    },
                  ]}
                />
              </VeldWeergave>
            </div>
          </div>

          <div className="mt-2 pl-3 pr-3 mb-4">
            <BoekingregelsComp
              regels={boekingregels}
              facturen={facturen}
              inkoopfacturen={inkoopfacturen}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default BoekingTab;
