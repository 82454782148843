import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import IDialoogProps from '../../../core/IDialoogProps';
import Dialoog from '../Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../models/IRemoteData';
import { Formik, FormikActions, FormikProps } from 'formik';
import LoadingSpinner from '../../Gedeeld/LoadingSpinner';
import {
  IOphalenContactpersonenResult,
  IOphalenContactpersonenResultElement,
  IOphalenPersonenVanLocatieResult,
} from '../../../../../shared/src/api/v2/relatie/contactpersoon';
import api from '../../../api';
import ModalTitle from 'react-bootstrap/ModalTitle';
import PersonenVanLocatieTabel from './PersonenVanLocatieTabel';
import { Kleur } from '../../../bedrijfslogica/constanten';
import { IconPijlVolgend, IconPijlVorig, IconToevoegen } from '../../Icons';
import OverigePersonenVanRelatieTabel from './OverigePersonenVanRelatieTabel';
import nameOf from '../../../core/nameOf';
import Zoeken from '../../formulier/Zoeken';
import { GlobaleRendererContext } from '../../../one-off-components/GlobaleRenderer';
import PersoonSelectieDialoog, {
  IPersoonSelectieDialoogDialoogResult,
} from '../PersoonSelectieDialoog';
import { RootStoreContext } from '../../../stores/RootStore';

interface IProps extends IDialoogProps<IBeheerPersonenVanLocatieDialoogOutput> {
  relID: number;
  locID: number;
}

export interface IBeheerPersonenVanLocatieDialoogOutput {}

export interface IPersoon {
  relPersID: number;
  service: boolean;
  transport: boolean;
}

interface IFormikValues {
  personen: IPersoon[];
}

const BeheerPersonenVanLocatieDialoog = (props: IProps): JSX.Element => {
  const [personenResult, setPersonenResult] = useState<IRemoteData<IOphalenContactpersonenResult>>(
    createPendingRemoteData(),
  );
  const ophalenPersonen = useCallback(async () => {
    const result = await api.v2.relatie.ophalenContactpersonen({
      filterSchema: {
        filters: [
          {
            naam: 'REL_IDS',
            data: [props.relID],
          },
        ],
      },
    });
    setPersonenResult(createReadyRemoteData(result));
  }, [props.relID]);
  useEffect(() => {
    ophalenPersonen();
  }, [ophalenPersonen]);

  const [personenVanLocatieResult, setPersonenVanLocatieResult] = useState<
    IRemoteData<IOphalenPersonenVanLocatieResult>
  >(createPendingRemoteData());
  const ophalenPersonenVanLocatie = useCallback(async () => {
    const result = await api.v2.relatie.ophalenPersonenVanLocatie({
      filterSchema: {
        filters: [
          {
            naam: 'REL_IDS',
            data: [props.relID],
          },
          {
            naam: 'LOC_IDS',
            data: [props.locID],
          },
        ],
      },
    });
    setPersonenVanLocatieResult(createReadyRemoteData(result));
  }, [props.relID, props.locID]);
  useEffect(() => {
    ophalenPersonenVanLocatie();
  }, [ophalenPersonenVanLocatie]);

  const initialValues = useMemo<IRemoteData<IFormikValues>>(() => {
    if (
      personenResult.state === ERemoteDataState.Pending ||
      personenVanLocatieResult.state === ERemoteDataState.Pending
    ) {
      return createPendingRemoteData();
    }
    const values: IFormikValues = {
      personen: personenVanLocatieResult.data!.elementen.map((x) => ({
        relPersID: x.RelPers_ID,
        service: x.PrimairVoorService,
        transport: x.PrimairVoorTransport,
      })),
    };
    return createReadyRemoteData(values);
  }, [personenResult, personenVanLocatieResult]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      await api.v2.relatie.muterenPersonenVanLocatie({
        locID: props.locID,
        relID: props.relID,
        personen: values.personen.map((x) => ({
          relPersID: x.relPersID,
          primairVoorService: x.service,
          primairVoorTransport: x.transport,
        })),
      });

      props.onSuccess({});
      actions.setSubmitting(false);
    },
    [props.onSuccess],
  );

  const verversen = useCallback(async () => {
    await Promise.all([ophalenPersonen(), ophalenPersonenVanLocatie()]);
  }, [ophalenPersonen, ophalenPersonenVanLocatie]);

  return (
    <Dialoog index={props.dialoogIndex ?? 0} modalProps={{ size: 'xl' }}>
      <ModalHeader>
        <ModalTitle>Beheer personen van locatie</ModalTitle>
      </ModalHeader>
      {initialValues.state === ERemoteDataState.Pending ? (
        <ModalBody>
          <LoadingSpinner />
        </ModalBody>
      ) : (
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialValues.data!}
          render={(formikProps) => (
            <Formulier
              {...props}
              formikProps={formikProps}
              personen={personenResult.data!.contactpersonen}
              verversen={verversen}
            />
          )}
        />
      )}
    </Dialoog>
  );
};

interface IFormulierProps {
  formikProps: FormikProps<IFormikValues>;
  personen: IOphalenContactpersonenResultElement[];
  verversen: () => Promise<void>;
}

const Formulier = (props: IProps & IFormulierProps): JSX.Element => {
  const globaleRenderer = useContext(GlobaleRendererContext);
  const { checkStore } = useContext(RootStoreContext);
  const { formikProps, onAnnuleren, personen } = props;
  const { isSubmitting, isValid, submitForm, values, setFieldValue } = formikProps;

  const [personenVanLocatieSelectie, setPersonenVanLocatieSelectie] = useState<number[]>([]);
  const [overigePersonenVanRelatieSelectie, setOverigePersonenVanRelatieSelectie] = useState<
    number[]
  >([]);

  const [zoektermOverigePersonenVanRelatie, setZoektermOverigePersonenVanRelatie] = useState('');

  const personenVanLocatie = useMemo(
    () => personen.filter((x) => values.personen.some((y) => y.relPersID === x.ID)),
    [values.personen, personen],
  );
  const overigePersonenVanRelatie = useMemo(
    () => personen.filter((x) => !values.personen.some((y) => y.relPersID === x.ID)),
    [values.personen, personen],
  );

  const overigePersonenVanRelatieFiltered = useMemo(() => {
    const zoektermLowercase = zoektermOverigePersonenVanRelatie.trim().toLowerCase();
    return overigePersonenVanRelatie.filter((x) => {
      return (
        x.Achternaam.toLowerCase().includes(zoektermLowercase) ||
        (x.Voornaam !== null && x.Voornaam.toLowerCase().includes(zoektermLowercase))
      );
    });
  }, [overigePersonenVanRelatie, zoektermOverigePersonenVanRelatie]);

  const handleVerplaatsNaarLocatie = useCallback(() => {
    const nieuwePersonen = [
      ...values.personen,
      ...overigePersonenVanRelatieSelectie.map((x) => ({
        relPersID: x,
        service: false,
        transport: false,
      })),
    ];
    setOverigePersonenVanRelatieSelectie([]);
    setFieldValue(nameOf<IFormikValues>('personen'), nieuwePersonen);
  }, [overigePersonenVanRelatieSelectie, values.personen]);

  const handleVerplaatsNaarRelatie = useCallback(() => {
    const personenVanLocatie = values.personen.filter(
      (x) => !personenVanLocatieSelectie.some((y) => y === x.relPersID),
    );
    setPersonenVanLocatieSelectie([]);
    setFieldValue(nameOf<IFormikValues>('personen'), personenVanLocatie);
  }, [personenVanLocatieSelectie, values.personen]);

  const handleVerwijderenPersoonVanLocatie = useCallback(
    async (persoon: IOphalenContactpersonenResultElement) => {
      const personenVanLocatie = values.personen.filter((x) => x.relPersID !== persoon.ID);
      const selectie = personenVanLocatieSelectie.filter((x) => x !== persoon.ID);
      setPersonenVanLocatieSelectie(selectie);
      setFieldValue(nameOf<IFormikValues>('personen'), personenVanLocatie);
    },
    [values.personen, personenVanLocatieSelectie],
  );

  const handleToevoegenPersoonVanRelatie = useCallback(
    (persoon: IOphalenContactpersonenResultElement) => {
      const personenVanLocatie = [
        ...values.personen,
        {
          relPersID: persoon.ID,
          service: false,
          transport: false,
        },
      ];
      const selectie = overigePersonenVanRelatieSelectie.filter((x) => x !== persoon.ID);
      setOverigePersonenVanRelatieSelectie(selectie);
      setFieldValue(nameOf<IFormikValues>('personen'), personenVanLocatie);
    },
    [values.personen, overigePersonenVanRelatieSelectie],
  );

  const personenVanLocatieMap = useMemo<{ [relPersID: number]: IPersoon }>(
    () =>
      values.personen.reduce(
        (acc, curr) => ({
          ...acc,
          [curr.relPersID]: curr,
        }),
        {},
      ),
    [values.personen, personen],
  );

  const handleNieuwPersoonOpvoerenClick = useCallback(async () => {
    const result = await globaleRenderer.render<IPersoonSelectieDialoogDialoogResult | undefined>(
      ({ destroy }) => (
        <PersoonSelectieDialoog
          dialoogIndex={(props.dialoogIndex ?? 0) + 1}
          open
          onSuccess={(result) => destroy(result)}
          onAnnuleren={() => destroy()}
        />
      ),
    );
    if (result === undefined) {
      return;
    }

    const koppelenResult = await api.v2.relatie.koppelenContactpersoon({
      relID: props.relID,
      persID: result.persID,
    });

    await props.verversen();

    await checkStore.bevestigen({
      inhoud: 'Wil je deze persoon ook direct toevoegen aan de locatie?',
      asynchroneActieNaBevestigingFn: async () => {
        setFieldValue(nameOf<IFormikValues>('personen'), [
          ...values.personen,
          {
            relPersID: koppelenResult.relPersID,
            service: false,
            transport: false,
          },
        ]);
      },
      weergavemodus: 'ja_nee',
    });
  }, [props.dialoogIndex, props.verversen, props.relID, values.personen]);

  return (
    <>
      <ModalBody className="d-flex p-0 h-100">
        <div style={{ flex: 3 }}>
          <div className="d-flex align-items-center justify-content-center pt-3">
            <h5>Personen van locatie</h5>
          </div>
          <div
            className="d-flex flex-fill"
            style={{
              height: 500,
            }}
          >
            <PersonenVanLocatieTabel
              personen={personenVanLocatie}
              selectie={personenVanLocatieSelectie}
              onSelectieChange={setPersonenVanLocatieSelectie}
              onVerwijderen={handleVerwijderenPersoonVanLocatie}
              personenVanLocatieMap={personenVanLocatieMap}
              onPersonenGewijzigd={(x) => setFieldValue(nameOf<IFormikValues>('personen'), x)}
            />
          </div>
        </div>
        <div
          style={{
            minHeight: '100%',
            backgroundColor: Kleur.HeelLichtGrijs,
          }}
          className="d-flex flex-column justify-content-center align-items-center p-3"
        >
          <button
            className="btn btn-light"
            style={{
              border: `1px solid ${Kleur.LichtGrijs}`,
              backgroundColor: Kleur.Wit,
            }}
            disabled={overigePersonenVanRelatieSelectie.length === 0}
            onClick={handleVerplaatsNaarLocatie}
          >
            <IconPijlVorig
              style={{
                fill: Kleur.Grijs,
                width: 21,
                height: 21,
              }}
            />
          </button>
          <button
            className="btn btn-light mt-3"
            style={{
              border: `1px solid ${Kleur.LichtGrijs}`,
              backgroundColor: Kleur.Wit,
            }}
            disabled={personenVanLocatieSelectie.length === 0}
            onClick={handleVerplaatsNaarRelatie}
          >
            <IconPijlVolgend
              style={{
                fill: Kleur.Grijs,
                width: 21,
                height: 21,
              }}
            />
          </button>
        </div>
        <div className="flex-fill">
          <div className="d-flex align-items-center justify-content-center pt-3">
            <h5>Overige personen van relatie</h5>
          </div>
          <div
            className="d-flex flex-fill flex-column"
            style={{
              height: 500,
            }}
          >
            <div className="p-2">
              <Zoeken
                input={zoektermOverigePersonenVanRelatie}
                onInputChange={setZoektermOverigePersonenVanRelatie}
                placeholder="Zoek op naam..."
              />
            </div>

            <OverigePersonenVanRelatieTabel
              personen={overigePersonenVanRelatieFiltered}
              selectie={overigePersonenVanRelatieSelectie}
              onSelectieChange={setOverigePersonenVanRelatieSelectie}
              onToevoegen={handleToevoegenPersoonVanRelatie}
            />

            <div className="p-2">
              <button
                className="btn btn-sm btn-light d-flex align-items-center"
                style={{
                  border: `1px solid ${Kleur.LichtGrijs}`,
                }}
              >
                <IconToevoegen
                  style={{
                    fill: Kleur.Grijs,
                    width: 18,
                    height: 18,
                  }}
                />
                <span className="ml-2" onClick={handleNieuwPersoonOpvoerenClick}>
                  Nieuw persoon
                </span>
              </button>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter className="d-flex flex-row justify-content-start">
        <button
          className="btn btn-primary"
          onClick={submitForm}
          style={{ width: 100 }}
          disabled={isSubmitting || !isValid}
        >
          Ok
        </button>
        <button
          className="btn btn-secondary"
          onClick={onAnnuleren}
          style={{ width: 100 }}
          disabled={isSubmitting}
        >
          Annuleren
        </button>
      </ModalFooter>
    </>
  );
};

export default BeheerPersonenVanLocatieDialoog;
