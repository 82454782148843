import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { TableRowDetail } from '@devexpress/dx-react-grid';
import { defaultSysteemDataContext, IRow, SjablonenContext } from '../index';
import Tabblad, { EOrientatie, ITabblad } from '../../../../components/layout/Tabblad';
import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';
import { ICommunicatiekanaal } from '../../../../../../shared/src/api/v2/bericht/Bericht';
import api from '../../../../api';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import { RootStoreContext } from '../../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import { EResultType } from '../../../../stores/CheckStore';
import KanaalFormulier from './KanaalFormulier';
import HorizontaleScheidingslijn from '../../../../components/layout/HorizontaleScheidingslijn';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import {
  ISjabloonCategorie,
  IWijzigenSjabloonParams,
} from '../../../../../../shared/src/api/v2/Sjabloon/sjabloon';
import { IBestandDragAndZoneBestand } from '../../../../components/BestandDragAndDropZone';
import { IOphalenTalenResultElement } from '../../../../../../shared/src/api/v2/taal';

import { v4 as uuidv4 } from 'uuid';

export interface IFormulierInhoud {
  taalID: number;
  onderwerp: string;
  inhoud: string;
  toepassen: boolean;
  systeemDataContext: string | null;
  bestanden: IBestandDragAndZoneBestand[];
}

export interface IFormulierKanaal {
  sjabKanID: number | null;
  sjabBasisID: number | null;
  comKanID: number;
  comKanNaamEnum: string;
  inhouden: IFormulierInhoud[];
}

export interface ITabbladProps {
  comKanNaamEnum: string;
  comKanID: number;
  sjabBasisID: number | null;
  onSjabBasisIDChange: (sjabBasisID: number | null) => void;
  inhouden: IFormulierInhoud[] | null;
  onInhoudenChange: (inhouden: IFormulierInhoud[]) => void;
}

type RowType = IRow['kanalen'][0];

export interface ISjabloonRowDetailContext {
  sjabloon: IRow;
}

export const SjabloonRowDetailContext = React.createContext<ISjabloonRowDetailContext | null>(null);

interface IProps extends TableRowDetail.ContentProps {}

const SjabloonRowDetail = observer((props: IProps) => {
  const { instellingStore, checkStore } = useContext(RootStoreContext);
  const { talen, onVerversenAangevraagd } = useContext(SjablonenContext)!;
  const tabToevoegenRef = useRef(null);
  const [magOpslaan, setMagOpslaan] = useState(true);

  const row: IRow = props.row;
  const initieleFormulierKanalen = useMemo<IFormulierKanaal[]>(
    () =>
      row.kanalen.map((x) => {
        return {
          sjabKanID: x.kanaal.ID,
          sjabBasisID: x.kanaal.SjabBasisID,
          comKanID: x.communicatiekanaal.ComKanID,
          comKanNaamEnum: x.communicatiekanaal.NaamEnum,
          inhouden: x.inhouden.map((inhoud) => {
            return {
              taalID: inhoud.taal.TaalID,
              onderwerp: inhoud.inhoud.Onderwerp || '',
              inhoud: inhoud.inhoud.Inhoud,
              bestanden: inhoud.bestanden.map((x) => ({
                aspDrive: x,
              })),
              toepassen: inhoud.inhoud.Toepassen,
              systeemDataContext: inhoud.inhoud.SysteemDataContext,
            };
          }),
        };
      }),
    [row.kanalen],
  );
  const [formulierKanalen, setFormulierKanalen] = useState<IFormulierKanaal[]>(
    initieleFormulierKanalen,
  );

  const [gekozenComKanID, setGekozenKanaal] = useState<number | null>(
    formulierKanalen.length === 0 ? null : formulierKanalen[0].comKanID,
  );
  const [communicatiekanaalToevoegenTonen, setCommunicatiekanaalToevoegenTonen] = useState(false);
  const popoverId = useMemo(() => uuidv4(), []);
  const [communicatiekanalen, setCommunicatiekanalen] = useState<ICommunicatiekanaal[] | null>(
    null,
  );

  const ophalenCommunicatiekanalen = useCallback(async () => {
    const result = await api.v2.bericht.ophalenCommunicatiekanalen({
      filterSchema: { filters: [] },
    });
    setCommunicatiekanalen(result.kanalen);
  }, [setCommunicatiekanalen]);

  useEffect(() => {
    ophalenCommunicatiekanalen();
  }, []);

  const toevoegbareCommunicatiekanalen = useMemo<ICommunicatiekanaal[] | null>(() => {
    if (communicatiekanalen === null) {
      return null;
    }
    return communicatiekanalen.filter(
      (x) => formulierKanalen.findIndex((y) => y.comKanID === x.ComKanID) === -1,
    );
  }, [formulierKanalen, communicatiekanalen]);

  const tabbladen = useMemo<ITabblad<any>[] | null>(() => {
    if (communicatiekanalen === null) {
      return null;
    }
    return formulierKanalen.map((formulierKanaal) => {
      const kanaal = communicatiekanalen.find((x) => x.ComKanID === formulierKanaal.comKanID)!;
      return {
        id: kanaal.ComKanID,
        label: kanaal.Naam,
        content: KanaalFormulier,
      };
    });
  }, [formulierKanalen, communicatiekanalen]);

  const tabbladComponentProps = useMemo<ITabbladProps | null>(() => {
    if (gekozenComKanID === null || talen === null) {
      return null;
    }
    const kanaal = formulierKanalen.find((x) => x.comKanID === gekozenComKanID)!;
    return {
      comKanID: gekozenComKanID,
      comKanNaamEnum: kanaal.comKanNaamEnum,
      talen,
      sjabBasisID: kanaal.sjabBasisID,
      onSjabBasisIDChange: (sjabBasisID) => {
        setFormulierKanalen(
          formulierKanalen.map((formulierKanaal) => {
            if (formulierKanaal.comKanID === gekozenComKanID) {
              return {
                ...formulierKanaal,
                sjabBasisID,
              };
            }
            return formulierKanaal;
          }),
        );
      },
      inhouden: kanaal.inhouden,
      onInhoudenChange: (inhouden) => {
        setFormulierKanalen(
          formulierKanalen.map((formulierKanaal) => {
            if (formulierKanaal.comKanID === gekozenComKanID) {
              return {
                ...formulierKanaal,
                inhouden,
              };
            }
            return formulierKanaal;
          }),
        );
      },
    };
  }, [gekozenComKanID, formulierKanalen, setFormulierKanalen, talen]);

  const handleOpslaan = useCallback(async () => {
    setMagOpslaan(false);

    const sjab = row.sjabloon;
    const params: IWijzigenSjabloonParams = {
      code: sjab.Code,
      isConcept: sjab.IsConcept,
      isSysteemSjabloon: sjab.IsSysteemSjabloon,
      isHoofdSjabloon: sjab.IsHoofdSjabloon,
      kanalen: await Promise.all(
        formulierKanalen.map(async (formulierKanaal) => {
          return {
            comKanID: formulierKanaal.comKanID,
            sjabBasisID: formulierKanaal.sjabBasisID,
            inhouden: await Promise.all(
              formulierKanaal.inhouden.map(async (formulierInhoud) => {
                const onderwerp =
                  formulierInhoud.onderwerp.length === 0 ? null : formulierInhoud.onderwerp.trim();
                const bestIDs = await Promise.all(
                  formulierInhoud.bestanden.map(async (bestand) => {
                    if (bestand.aspDrive !== undefined) {
                      return bestand.aspDrive.ID;
                    }
                    const upload = await api.v2.bestand.upload(bestand.lokaal!);
                    return upload.ID;
                  }),
                );
                return {
                  onderwerp,
                  inhoud: formulierInhoud.inhoud.trim(),
                  taalID: formulierInhoud.taalID,
                  toepassen: formulierInhoud.toepassen,
                  bestanden: bestIDs.map((x) => ({ bestID: x })),
                  systeemDataContext: sjab.IsSysteemSjabloon
                    ? formulierInhoud.systeemDataContext
                    : null,
                };
              }),
            ),
          };
        }),
      ),
      naam: sjab.Naam,
      omschrijving: sjab.Omschrijving,
      naamEnum: sjab.NaamEnum,
      sjabCatID: sjab.SjabCatID,
      sjabCtxs: sjab.sjabCtxs as any,
      sjabID: sjab.ID,
      trefwoord: sjab.Trefwoord,
    };
    const checkData = await api.v2.sjabloon.checkWijzigenSjabloon(params);
    if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
      setMagOpslaan(true);
      return;
    }
    await api.v2.sjabloon.wijzigenSjabloon(params);
    onVerversenAangevraagd();
    setMagOpslaan(true);
  }, [formulierKanalen, row.sjabloon]);

  return (
    <SjabloonRowDetailContext.Provider
      value={{
        sjabloon: row,
      }}
    >
      {tabbladen === null || talen === null ? (
        <LoadingSpinner />
      ) : (
        <div className="d-flex flex-fill flex-column">
          <div className="d-flex flex-fill">
            <Tabblad<number, ITabbladProps>
              geselecteerd={gekozenComKanID}
              onSelectieChange={(x) => setGekozenKanaal(x)}
              tabbladen={tabbladen}
              onTabToevoegen={() => setCommunicatiekanaalToevoegenTonen(true)}
              orientatie={EOrientatie.Verticaal}
              verticaleBreedte={200}
              onTabVerwijderen={async (comKanID) => {
                const bevestigResult = await checkStore.bevestigen({
                  inhoud: 'Bevestig verwijderen sjabloon kanaal',
                });
                if (bevestigResult.type === EResultType.Annuleren) {
                  return;
                }
                const nieuweFormulierKanalen = formulierKanalen.filter(
                  (x) => x.comKanID !== comKanID,
                );
                if (gekozenComKanID === comKanID) {
                  if (nieuweFormulierKanalen.length === 0) {
                    setGekozenKanaal(null);
                  } else {
                    setGekozenKanaal(nieuweFormulierKanalen[0].comKanID);
                  }
                }
                setFormulierKanalen(nieuweFormulierKanalen);
              }}
              options={{
                // smalleWeergave: true,
                onTabToevoegenKnopRef: tabToevoegenRef,
                tabbladComponentProps,
              }}
            />
            {gekozenComKanID === null && (
              <div className="p-4 d-flex align-items-center justify-content-center flex-fill">
                Er is nog geen kanaal gekozen, klik op een tab of voeg een kanaal toe.
              </div>
            )}
          </div>
          <HorizontaleScheidingslijn color={Kleur.LichtGrijs} />
          <div className="d-flex p-3 pl-4 pr-4">
            <button
              className="btn btn-primary"
              onClick={handleOpslaan}
              style={{ width: 100 }}
              disabled={!magOpslaan}
            >
              Opslaan
            </button>
            <button
              className="btn btn-secondary ml-2"
              onClick={async () => {
                if (
                  (await checkStore.bevestigen({ inhoud: 'Bevestigen herstellen sjabloon' }))
                    .type === EResultType.Annuleren
                ) {
                  return;
                }

                setFormulierKanalen(initieleFormulierKanalen);
              }}
              style={{ width: 100 }}
            >
              Herstellen
            </button>
          </div>
        </div>
      )}
      <Overlay
        target={tabToevoegenRef.current || (undefined as any)}
        show={communicatiekanaalToevoegenTonen}
        rootClose
        placement="right"
        onHide={() => setCommunicatiekanaalToevoegenTonen(false)}
      >
        <Popover id={popoverId}>
          {toevoegbareCommunicatiekanalen === null ? (
            <div className="p-3 align-items-center justify-items-center">
              <LoadingSpinner />
            </div>
          ) : toevoegbareCommunicatiekanalen.length === 0 ? (
            <div className="p-3">Er zijn geen andere communicatiekanalen beschikbaar.</div>
          ) : (
            <div className="list-group">
              {toevoegbareCommunicatiekanalen.map((communicatiekanaal) => (
                <li
                  key={communicatiekanaal.ComKanID}
                  className="list-group-item dx-g-bs4-cursor-pointer"
                  onClick={() => {
                    setCommunicatiekanaalToevoegenTonen(false);
                    setGekozenKanaal(communicatiekanaal.ComKanID);

                    setFormulierKanalen([
                      ...formulierKanalen,
                      {
                        comKanID: communicatiekanaal.ComKanID,
                        comKanNaamEnum: communicatiekanaal.NaamEnum,
                        sjabKanID: null,
                        sjabBasisID: null,
                        inhouden: [
                          {
                            taalID: instellingStore.TaalID!,
                            onderwerp: '',
                            inhoud: '',
                            bestanden: [],
                            toepassen: true,
                            systeemDataContext: defaultSysteemDataContext,
                          },
                        ],
                      },
                    ]);
                  }}
                >
                  {communicatiekanaal.Naam}
                </li>
              ))}
            </div>
          )}
        </Popover>
      </Overlay>
    </SjabloonRowDetailContext.Provider>
  );
});

export default SjabloonRowDetail;
