import React, { useCallback, useEffect, useMemo, useState } from 'react';
// import { ISectieProps } from '../index';
import api from '../../../../../../../../api';
import LoadingSpinner from '../../../../../../../../components/Gedeeld/LoadingSpinner';
import {
  DXTableCheckboxComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../../../../../helpers/dxTableGrid';
import {
  Grid,
  Table,
  VirtualTable,
  TableColumnResizing,
  TableHeaderRow,
  TableSelection,
} from '@devexpress/dx-react-grid-bootstrap4';
import { DataTypeProvider, SelectionState, TableRowDetail } from '@devexpress/dx-react-grid';
import { format } from 'date-fns';
import { IOphalenIncassozakenResultElement } from '../../../../../../../../../../shared/src/api/v2/debiteur/incassozaak';
import FormatteerBedrag from '../../../../../../../../components/MutatieBedrag';

interface IRow {
  factID: number;
  factuurnummer: string;
  factuurdatum: Date;
  vervaldatum: Date;
  bedrag: number;
  bedragInDossier: number;
}

interface IProps extends TableRowDetail.ContentProps {}

const FacturenDetailComp: React.FC<IProps> = (props) => {
  const dossier = useMemo(() => {
    return props.row as IOphalenIncassozakenResultElement;
  }, [props.row]);

  // useEffect(() => {
  //   (async () => {
  //     const result = await api.v2.relatie.account.ophalenLogging({
  //       accID,
  //     });
  //     setLogging(result);
  //   })();
  // }, [accID]);

  const rows = useMemo<IRow[]>(() => {
    return dossier.facturen.map(
      (x): IRow => ({
        factID: x.FactID,
        factuurnummer: x.Factuurnummer!,
        factuurdatum: new Date(x.Factuurdatum),
        vervaldatum: new Date(x.Vervaldatum),
        bedrag: x.Bedrag,
        bedragInDossier: x.Bedrag,
      }),
    );
  }, [dossier.facturen]);

  const keyExtractor = useCallback((row: IRow) => row.factID, []);

  const kolommen = useMemo<TypedColumn<IRow>[]>(() => {
    return [
      {
        name: 'factuurnummer',
        title: 'Fact.nr',
      },
      {
        name: 'factuurdatum',
        title: 'Fact.datum',
      },
      {
        name: 'vervaldatum',
        title: 'Vervaldatum',
      },
      {
        name: 'bedrag',
        title: 'Fact.bedrag',
      },
      {
        name: 'bedragInDossier',
        title: 'In dossier',
      },
    ];
  }, []);

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRow>[]>(() => {
    return [
      {
        columnName: 'factuurnummer',
        width: 100,
      },
      {
        columnName: 'factuurdatum',
        width: 100,
      },
      {
        columnName: 'vervaldatum',
        width: 100,
      },
      {
        columnName: 'bedrag',
        width: 100,
      },
      {
        columnName: 'bedragInDossier',
        width: 100,
      },
    ];
  }, []);

  return (
    <>
      {rows === null ? (
        <LoadingSpinner />
      ) : (
        <GridStyleWrapper maxHeight={500} rowAmount={rows.length}>
          <Grid getRowId={keyExtractor} rows={rows} columns={kolommen}>
            <DataTypeProvider
              for={['factuurdatum']}
              formatterComponent={(formatterProps) => {
                const row: IRow = formatterProps.row;

                return <span>{format(row.factuurdatum, 'dd-MM-yyyy')}</span>;
              }}
            />

            <DataTypeProvider
              for={['vervaldatum']}
              formatterComponent={(formatterProps) => {
                const row: IRow = formatterProps.row;

                return <span>{format(row.vervaldatum, 'dd-MM-yyyy')}</span>;
              }}
            />

            <DataTypeProvider
              for={['bedrag']}
              formatterComponent={(props) => (
                <span>
                  <FormatteerBedrag bedrag={props.value} />
                </span>
              )}
            />

            <DataTypeProvider
              for={['bedragInDossier']}
              formatterComponent={(props) => (
                <span>
                  <FormatteerBedrag bedrag={props.value} />
                </span>
              )}
            />

            <Table />
            <TableColumnResizing />
            <TableHeaderRow />
            <TableColumnResizing defaultColumnWidths={kolomBreedtes as any} />
          </Grid>
        </GridStyleWrapper>
      )}
    </>
  );
};

export default FacturenDetailComp;
