import api from '../../../../..';
import {
  IConverterenExternInventarisatiebestandParams,
  IConverterenExternInventarisatiebestandResult,
  IKoppelenProducttypeParams,
  IKoppelenProducttypeResult,
  ILadenExterneInventarisatieParams,
  ILadenExterneInventarisatieResult,
  IOntkoppelenProducttypeParams,
  IOntkoppelenProducttypeResult,
  IOphalenInventarisatiesParams,
  IOphalenInventarisatiesResult,
  IVerwijderenLijstenParams,
  IVerwijderenLijstenResult,
} from '../../../../../../../../shared/src/api/v2/magazijn/inventarisatie/lijst/extern';

const extern = {
  ophalenInventarisaties: async (
    params: IOphalenInventarisatiesParams,
  ): Promise<IOphalenInventarisatiesResult> => {
    return await api.post(
      '/v2/magazijn/inventarisatie/lijst/extern/ophalen-inventarisaties',
      params,
    );
  },
  ladenExterneInventarisatie: async (
    params: ILadenExterneInventarisatieParams,
  ): Promise<ILadenExterneInventarisatieResult> => {
    return await api.post(
      '/v2/magazijn/inventarisatie/lijst/extern/laden-externe-inventarisatie',
      params,
    );
  },
  converterenExternInventarisatiebestand: async (
    params: IConverterenExternInventarisatiebestandParams,
  ): Promise<IConverterenExternInventarisatiebestandResult> => {
    return await api.post(
      '/v2/magazijn/inventarisatie/lijst/extern/converteren-extern-inventarisatiebestand',
      params,
    );
  },
  verwijderenInventarisaties: async (
    params: IVerwijderenLijstenParams,
  ): Promise<IVerwijderenLijstenResult> => {
    return await api.post(
      '/v2/magazijn/inventarisatie/lijst/extern/verwijderen-inventarisaties',
      params,
    );
  },
  koppelenProducttype: async (
    params: IKoppelenProducttypeParams,
  ): Promise<IKoppelenProducttypeResult> => {
    return await api.post('/v2/magazijn/inventarisatie/lijst/extern/koppelen-producttype ', params);
  },
  ontkoppelenProducttype: async (
    params: IOntkoppelenProducttypeParams,
  ): Promise<IOntkoppelenProducttypeResult> => {
    return await api.post(
      '/v2/magazijn/inventarisatie/lijst/extern/ontkoppelen-producttype ',
      params,
    );
  },
};

export default extern;
