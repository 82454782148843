import _ from 'lodash';
import React from 'react';
import { IOphalenRelatiesResultElementV2 } from '../../../../../shared/src/api/v2/relatie';
import {
  IOphalenOpdrachtenResultElementV2,
  IOphalenOpdrachtregelsResultElementV2,
} from '../../../../../shared/src/api/v2/transport/opdracht';
import { Kleur as EKleur } from '../../../bedrijfslogica/constanten';
import { EContractstatus, ETransportopdrachtRegelsoort } from '../../../bedrijfslogica/enums';
import { berekendLocatieniveau } from '../PlanningTabel';

export interface IRegel extends IOphalenOpdrachtregelsResultElementV2 {
  relatie: IOphalenRelatiesResultElementV2 | null;
}

export interface IOpdracht extends IOphalenOpdrachtenResultElementV2 {
  regels: IRegel[];
}

interface IProps {
  opdracht: IOpdracht;
}

// const PlanningStatus: React.FC<IProps> = (props) => {
//   const opdracht = props.opdracht;

//   let bijzonderheden = '';

//   const bereikbaarheid =
//     opdracht.locatie.BereikbaarVan === null && opdracht.locatie.BereikbaarTot === null
//       ? null
//       : opdracht.locatie.BereikbaarVan !== null && opdracht.locatie.BereikbaarTot === null
//       ? `Vanaf ${opdracht.locatie.BereikbaarVan}`
//       : opdracht.locatie.BereikbaarVan === null && opdracht.locatie.BereikbaarTot !== null
//       ? `Tot ${opdracht.locatie.BereikbaarTot}`
//       : `${opdracht.locatie.BereikbaarVan} - ${opdracht.locatie.BereikbaarTot}`;

//   bijzonderheden += opdracht.locatie.LiftAanwezig ? 'Lift, ' : '';
//   bijzonderheden += bereikbaarheid !== null ? 'Bereikbaar ' + bereikbaarheid + ', ' : '';
//   bijzonderheden += opdracht.locatie.IsMilieuzone ? 'Milieuzone, ' : '';
//   bijzonderheden += opdracht.Ophouden ? 'Ophouden, ' : '';

//   bijzonderheden += opdracht.regels
//     .filter((x) => x.regelsoort.NaamEnum === ETransportopdrachtRegelsoort.Levering)
//     .some((x) => x.contract !== null && x.contract.status.NaamEnum !== EContractstatus.Lopend)
//     ? 'Contract niet lopend, '
//     : '';
//   bijzonderheden += opdracht.regels
//     .filter((x) => x.relatie !== null)
//     .some((x) => x.relatie!.Geblokkeerd)
//     ? 'Geblokkeerd, '
//     : '';

//   //   (row.indicaties.heeftGeblokkeerdeLeveringDoorVervallenSaldo ? ' Vervallen saldo,' : '') +

//   bijzonderheden = bijzonderheden.trim().slice(0, -1);

//   return <span>{bijzonderheden}</span>;
// };

const PlanningStatus: React.FC<IProps> = (props) => {
  const opdracht = props.opdracht;
  const ophouden = opdracht.Ophouden;
  const relatieGeblokkeerd = opdracht.regels.some(
    (x) => x.relatie !== null && x.relatie.Geblokkeerd,
  );
  const contractNogFiatteren = opdracht.regels
    .filter((x) => x.regelsoort.NaamEnum === ETransportopdrachtRegelsoort.Levering)
    .some((x) => x.contract !== null && x.contract.status.NaamEnum === EContractstatus.Concept);

  const vervallenSaldo = opdracht.regels.some(
    (x) =>
      x.relatie !== null &&
      x.relatie.debiteur !== null &&
      // !x.relatie.LeveringToestaanBijVervallenSaldo &&
      x.relatie.debiteur.saldi.openstaandVervallen > 0,
  );

  // Check op (het vermoedelijk) ontbreken op gekoppelde omruil
  const aantalLeveringen = opdracht.regels.filter(
    (x) =>
      x.regelsoort.NaamEnum === ETransportopdrachtRegelsoort.Levering &&
      ((x.type !== null && !x.type.Bulkproduct) || (x.type === null && x.product !== null)),
  ).length;
  const aantalRetouren = opdracht.regels.filter(
    (x) =>
      x.regelsoort.NaamEnum === ETransportopdrachtRegelsoort.Retour &&
      ((x.type !== null && !x.type.Bulkproduct) || (x.type === null && x.product !== null)),
  ).length;
  // Als er 3 Leveringen zijn en 4 Retouren dan zouden er potentieel 3 omruilingen (OmruilID's) mogelijk zijn
  const aantalRegelsWaarvoorOmruilMogelijkIs = Math.min(aantalLeveringen, aantalRetouren) * 2;

  const aantalRegelsMetOmruil = opdracht.regels.filter((x) => x.OmruilID !== null).length;

  const omruilingenNietGekoppeld =
    aantalRegelsWaarvoorOmruilMogelijkIs > 0
      ? aantalRegelsMetOmruil < aantalRegelsWaarvoorOmruilMogelijkIs
        ? true
        : false
      : false;

  // Check of er producten meer dan 1 keer in de opdracht zitten
  const gegroepeerdeProdIDs = _.groupBy(
    opdracht.regels.filter((x) => x.product !== null),
    (x: IRegel) => x.product!.ProdID,
  );
  const gegroepeerdeProducten = Object.keys(gegroepeerdeProdIDs)
    .map(Number)
    .map((ProdID) => {
      return {
        ProdID,
        lengte: gegroepeerdeProdIDs[ProdID].map((x: any) => x.length),
      };
    });
  const duplicaatProductenAanwezig = gegroepeerdeProducten.some((x) => x.lengte.length > 1);

  // Doelstatus opgegeven?
  const doelstatusNietOpgegeven = opdracht.regels
    .filter((x) => x.regelsoort.NaamEnum === ETransportopdrachtRegelsoort.Retour)
    .some((x) => x.product !== null && x.product.retourstatus === null);

  const huisnummerBevatNietToegestaneTekens =
    opdracht.locatie.Bisnummer !== null && opdracht.locatie.Bisnummer.match(/[/]/g) !== null;

  // const bereikbaarheid =
  //   opdracht.locatie.BereikbaarVan === null && opdracht.locatie.BereikbaarTot === null
  //     ? null
  //     : opdracht.locatie.BereikbaarVan !== null && opdracht.locatie.BereikbaarTot === null
  //     ? `Vanaf ${opdracht.locatie.BereikbaarVan}`
  //     : opdracht.locatie.BereikbaarVan === null && opdracht.locatie.BereikbaarTot !== null
  //     ? `Tot ${opdracht.locatie.BereikbaarTot}`
  //     : `${opdracht.locatie.BereikbaarVan} - ${opdracht.locatie.BereikbaarTot}`;

  // bijzonderheden += opdracht.locatie.LiftAanwezig ? 'Lift, ' : '';
  // bijzonderheden += bereikbaarheid !== null ? 'Bereikbaar ' + bereikbaarheid + ', ' : '';
  // bijzonderheden += opdracht.locatie.IsMilieuzone ? 'Milieuzone, ' : '';

  const nietLopendContract = opdracht.regels
    .filter((x) => x.regelsoort.NaamEnum === ETransportopdrachtRegelsoort.Levering)
    .some(
      (x) =>
        x.contract !== null &&
        x.contract.status.NaamEnum !== EContractstatus.Lopend &&
        x.contract.status.NaamEnum !== EContractstatus.Concept,
    );

  const locatieNiveau = berekendLocatieniveau(opdracht);

  // const locatieNiveau =
  //   opdracht.HoogsteVerdieping ??
  //   _.max(
  //     opdracht.regels
  //       .filter(
  //         (x) =>
  //           !(x.OmruilID !== null && x.regelsoort.NaamEnum === ETransportopdrachtRegelsoort.Retour),
  //       )
  //       .filter((x) => x.Verdieping !== null)
  //       .map((x) => x.Verdieping),
  //   ) ??
  //   null;

  const geenLocatieniveaubekend = locatieNiveau === null;

  const discrepantieTussenHoogsteVerdiepingEnContracten =
    locatieNiveau !== null &&
    opdracht.regels
      .filter((x) => x.contract !== null)
      // .filter(
      //   (x) =>
      //     !(x.OmruilID !== null && x.regelsoort.NaamEnum === ETransportopdrachtRegelsoort.Retour) ||
      //     (x.contract!.status.NaamEnum !== EContractstatus.Lopend &&
      //       x.regelsoort.NaamEnum === ETransportopdrachtRegelsoort.Retour),
      // )
      .filter((x) => x.contract!.basis.Verdieping !== locatieNiveau).length > 0;

  // console.log(
  //   `>>>>>${opdracht.Opdrachtnummer}>>>${locatieNiveau}>>>${discrepantieTussenHoogsteVerdiepingEnContracten}`,
  //   opdracht.regels
  //     .filter((x) => x.contract !== null)
  //     .filter(
  //       (x) =>
  //         !(x.OmruilID !== null && x.regelsoort.NaamEnum === ETransportopdrachtRegelsoort.Retour) ||
  //         (x.contract!.status.NaamEnum !== EContractstatus.Lopend &&
  //           x.regelsoort.NaamEnum === ETransportopdrachtRegelsoort.Retour),
  //     )
  //     .filter((x) => x.contract!.basis.Verdieping !== locatieNiveau).length,
  // );

  // .filter((x) => x.Verdieping !== null)
  // .filter((x) => x.Verdieping !== opdracht.HoogsteVerdieping).length > 0;

  if (
    !relatieGeblokkeerd &&
    !vervallenSaldo &&
    !ophouden &&
    !contractNogFiatteren &&
    !duplicaatProductenAanwezig &&
    !doelstatusNietOpgegeven &&
    !geenLocatieniveaubekend &&
    !omruilingenNietGekoppeld &&
    !huisnummerBevatNietToegestaneTekens &&
    !nietLopendContract &&
    !discrepantieTussenHoogsteVerdiepingEnContracten
  ) {
    return <span></span>;
  }

  return (
    <>
      <span></span>
      {relatieGeblokkeerd && (
        <span className="mr-2" style={{ color: EKleur.Rood }} title="Een relatie is geblokkeerd.">
          Geblokkeerd
        </span>
      )}
      {vervallenSaldo &&
        opdracht.regels.some(
          (x) => x.regelsoort.NaamEnum === ETransportopdrachtRegelsoort.Levering,
        ) && (
          <span
            className="mr-2"
            style={{ color: EKleur.Rood }}
            title="Een relatie heeft een openstaand saldo."
          >
            Saldo
          </span>
        )}
      {ophouden && (
        <span
          className="mr-2"
          style={{ color: EKleur.Rood }}
          title="De opdracht is opgehouden voor versturen."
        >
          Ophouden
        </span>
      )}
      {nietLopendContract && (
        <span
          className="mr-2"
          style={{ color: EKleur.Blauw }}
          title="Er is een niet-lopend contract bij een levering."
        >
          Niet lop. cnt
        </span>
      )}
      {contractNogFiatteren && (
        <span
          className="mr-2"
          style={{ color: EKleur.Blauw }}
          title="Er is een nog te fiatteren contract."
        >
          Nog fiatteren
        </span>
      )}
      {omruilingenNietGekoppeld && (
        <span className="mr-2" style={{ color: EKleur.Blauw }} title="">
          Omruil niet gekoppeld?
        </span>
      )}
      {duplicaatProductenAanwezig && (
        <span
          className="mr-2"
          style={{ color: EKleur.Rood }}
          title="Een product komt ook voor bij een andere lopende opdracht."
        >
          Duplicaten
        </span>
      )}
      {doelstatusNietOpgegeven && (
        <span
          className="mr-2"
          style={{ color: EKleur.Blauw }}
          title="Er ontbreekt een doelstatus bij een product."
        >
          Doelstatus?
        </span>
      )}
      {discrepantieTussenHoogsteVerdiepingEnContracten && (
        <span
          className="mr-2"
          style={{ color: EKleur.Blauw }}
          title="Er is een verschil tussen de verdieping op opdrachtniveau en die van bij de contracten."
        >
          Afw. niv.
        </span>
      )}
      {geenLocatieniveaubekend && (
        <span
          className="mr-2"
          style={{ color: EKleur.Blauw }}
          title="Er kan geen verdieping bepaald worden. Geef deze bij de opdracht op."
        >
          Verdieping?
        </span>
      )}
      {huisnummerBevatNietToegestaneTekens && (
        <span
          className="mr-2"
          style={{ color: EKleur.Blauw }}
          title="Bisnummer bevat niet-toegestane tekens."
        >
          Bisnr
        </span>
      )}
    </>
  );
};

export default PlanningStatus;
