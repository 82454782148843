import styled from 'styled-components';
import { Kleur } from '../../../../bedrijfslogica/constanten';

export const Root = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  .formulier {
    flex: 1;
    border-bottom: 1px solid #c7c7c7;
  }

  .acties {
    padding: 12px 15px;

    display: flex;
    align-items: center;
  }
`;

export const TinyMCEWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  div[role='application'] {
    flex: 1;
    height: 100% !important;
    border: 0 !important;
  }

  div[class='tox-statusbar'] {
    display: none;
  }
`;

export const TextInput = styled.input`
  border: 0;
  outline: 0;
  //width: 100%;
  flex: auto;
  min-width: 100px;
  height: 30px;
  font-size: 14px;
  padding: 0 10px;
  border-bottom: 1px solid ${Kleur.LichtGrijs};

  ::placeholder {
    font-style: italic;
    color: ${Kleur.Grijs};
  }
`;
