import React from 'react';
import styled from 'styled-components';
import ContactItem from './ContactItem';

export interface IContactData {
  addresses:
    | {
        street: string | null;
        city: string | null;
        state: string | null;
        zip: string | null;
        country: string | null;
        country_code: string | null;
        type: 'HOME' | 'WORK';
      }[]
    | null;
  birthday: string | null;
  emails:
    | [
        {
          email: string;
          type: 'HOME' | 'WORK';
        },
      ]
    | null;
  name: {
    formatted_name: string;
    first_name: string | null;
    last_name: string | null;
    middle_name: string | null;
    suffix: string | null;
    prefix: string | null;
  };
  org: {
    company: string | null;
    department: string | null;
    title: string | null;
  } | null;
  phones:
    | [
        {
          phone: string | null;
          type: 'CELL' | 'MAIN' | 'IPHONE' | 'HOME' | 'WORK';
          wa_id: string | null;
        },
      ]
    | null;
  urls:
    | {
        url: string | null;
        type: 'HOME' | 'WORK';
      }[]
    | null;
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 5px;
`;

interface IProps {
  data: IContactData[];
}

const ContactWeergave = (props: IProps) => {
  return (
    <Root>
      {props.data.map((item, i) => (
        <ContactItem key={i} data={item} />
      ))}
    </Root>
  );
};

export default ContactWeergave;
