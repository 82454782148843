import React, { useCallback, useMemo } from 'react';
import {
  Grid,
  Table,
  TableColumnResizing,
  TableHeaderRow,
  TableRowDetail,
} from '@devexpress/dx-react-grid-bootstrap4';
import { IFactuur, IRelatie } from '../index';
import {
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../../helpers/dxTableGrid';
import LoadingSpinner from '../../../../../components/Gedeeld/LoadingSpinner';
import MenuLayout from '../../../../../components/MenuLayout';
import TabelInspringBlok from '../../../../../components/layout/TabelInspringBlok';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import nameOf from '../../../../../core/nameOf';
import { format } from 'date-fns';
import FormatteerBedrag from '../../../../../components/MutatieBedrag';
import useUrlState from '../../../../../core/useUrlState';
import { RouteComponentProps, withRouter } from 'react-router';
import { Kleur as EKleur } from '../../../../../bedrijfslogica/constanten';
import { EFunctioneleIcon, functioneleIconMap } from '../../../../../components/Icons';
import FactuurinfoDialoog from '../../../../../components/factuur/FactuurinfoDialoog';

interface IProps extends TableRowDetail.ContentProps, RouteComponentProps {}

export interface IFactuurinfoDialoogState {
  factID: number;
}
export interface IUrlState {
  factuurinfoDialoogState: IFactuurinfoDialoogState | null;
}
const defaultUrlState: IUrlState = {
  factuurinfoDialoogState: null,
};

const IconProductInfo = functioneleIconMap[EFunctioneleIcon.ProductInfo];

const urlStateKey = 'facturendetail';

const RowDetailComp = (props: IProps) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState, urlStateKey);

  const row: IRelatie = props.row;
  const facturen = row.facturen;

  const keyExtractor = useCallback((row: IFactuur) => row.FactID, []);

  const kolommen = useMemo<TypedColumn<IFactuur>[]>(() => {
    return [
      {
        name: 'Factuurnummer',
        title: 'Fact.nr',
      },
      {
        name: 'Factuurdatum',
        title: 'Fact.datum',
      },
      {
        name: 'Vervaldatum',
        title: 'Vervaldatum',
      },
      {
        name: 'Bedrag',
        title: 'Fact.bedrag',
      },
      {
        name: 'Openstaand',
        title: 'Openstaand',
      },
      {
        name: '__factuurInfo' as any,
        title: ' ',
      },
    ];
  }, []);

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IFactuur>[]>(() => {
    return [
      {
        columnName: 'Factuurnummer',
        width: 150,
      },
      {
        columnName: '__factuurInfo' as any,
        width: 75,
      },
      {
        columnName: 'Factuurdatum',
        width: 125,
      },
      {
        columnName: 'Vervaldatum',
        width: 100,
      },
      {
        columnName: 'Bedrag',
        width: 125,
      },
      {
        columnName: 'Openstaand',
        width: 125,
      },
    ];
  }, []);

  return (
    <>
      {facturen === null ? (
        <LoadingSpinner />
      ) : (
        <MenuLayout
          // menu={<></>}
          body={
            <>
              <div className="d-flex">
                <TabelInspringBlok />
                <GridStyleWrapper maxHeight={500} rowAmount={facturen.length}>
                  <Grid getRowId={keyExtractor} rows={facturen} columns={kolommen}>
                    <DataTypeProvider
                      for={[nameOf<IFactuur>('Factuurdatum')]}
                      formatterComponent={(formatterProps) => {
                        const row: IFactuur = formatterProps.row;

                        return <span>{format(new Date(row.Factuurdatum), 'dd-MM-yyyy')}</span>;
                      }}
                    />

                    <DataTypeProvider
                      for={[nameOf<IFactuur>('Vervaldatum')]}
                      formatterComponent={(formatterProps) => {
                        const row: IFactuur = formatterProps.row;

                        return <span>{format(new Date(row.Vervaldatum), 'dd-MM-yyyy')}</span>;
                      }}
                    />

                    <DataTypeProvider
                      for={[nameOf<IFactuur>('Bedrag'), nameOf<IFactuur>('Openstaand')]}
                      formatterComponent={(props) => (
                        <span>
                          <FormatteerBedrag bedrag={props.value} />
                        </span>
                      )}
                    />

                    <DataTypeProvider
                      for={['__factuurInfo']}
                      formatterComponent={(formatterProps) => {
                        const rij: IFactuur = formatterProps.row;
                        return (
                          <a
                            href="#"
                            className="ml-1"
                            style={{ color: EKleur.LichtBlauw, position: 'relative', bottom: 2 }}
                            onClick={() => {
                              setUrlStateSync('factuurinfoDialoogState', {
                                factID: rij.FactID,
                              });
                            }}
                          >
                            <IconProductInfo
                              style={{ width: 15, height: 15, fill: EKleur.Blauw }}
                            />
                          </a>
                        );
                      }}
                    />

                    <Table />
                    <TableColumnResizing />
                    <TableHeaderRow />
                    <TableColumnResizing defaultColumnWidths={kolomBreedtes as any} />
                  </Grid>
                </GridStyleWrapper>
              </div>
            </>
          }
        />
      )}

      {urlState.factuurinfoDialoogState !== null && (
        <FactuurinfoDialoog
          open
          factID={urlState.factuurinfoDialoogState.factID}
          onSuccess={() => {
            setUrlStateSync('factuurinfoDialoogState', null);
          }}
          onAnnuleren={() => setUrlStateSync('factuurinfoDialoogState', null)}
        />
      )}
    </>
  );
};

export default withRouter(RowDetailComp);
