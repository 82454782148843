import React, { useMemo } from 'react';
import { IRegelCompProps } from '../index';
import RegelBasis from '../RegelBasis';
import { IRegelboekstukBankmutatie, ERegelboekstukType } from '../../types';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../../../models/IRemoteData';
import { IOphalenMutatiesResultElement } from '../../../../../../../../shared/src/api/v2/bank/mutaties';
import Skeleton from 'react-loading-skeleton';
import FormatteerBedrag from '../../../../../MutatieBedrag';
import format from 'date-fns/format';
import styled from 'styled-components';
import { IconVink, IconPriorityHigh } from '../../../../../Icons';
import { Kleur } from '../../../../../../bedrijfslogica/constanten';
import { dagDatum } from '../../../../../../helpers/datum';

const Omschrijving = styled.div`
  color: #b4b4b4;
  font-size: 15px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const BankmutatieRegel = (props: IRegelCompProps) => {
  const bankMutID = (props.regel.regelboekstuk as IRegelboekstukBankmutatie).bankMutID;
  const bankmutatie = useMemo<IRemoteData<IOphalenMutatiesResultElement>>(() => {
    const entry = props.bankmutatiesCache[bankMutID];
    if (entry === undefined) {
      return createPendingRemoteData();
    }
    return createReadyRemoteData(entry);
  }, [bankMutID, props.bankmutatiesCache]);

  const nogTeBoeken = useMemo<IRemoteData<number>>(() => {
    if (props.boekstuksaldi.state === ERemoteDataState.Pending) {
      return createPendingRemoteData();
    }
    const boekstuksaldo = props.boekstuksaldi.data!.find(
      (x) => x.type === ERegelboekstukType.Bankmutatie && x.bankMutID === bankMutID,
    );
    if (boekstuksaldo === undefined) {
      return createPendingRemoteData();
    }
    return createReadyRemoteData(boekstuksaldo.saldo);
  }, [props.boekstuksaldi, bankMutID]);

  if (bankmutatie.state === ERemoteDataState.Pending) {
    return <Skeleton height={100} />;
  }

  return (
    <RegelBasis
      isGeselecteerd={props.isGeselecteerd}
      onGeselecteerd={props.onGeselecteerd}
      height={165}
    >
      <div className="d-flex align-items-center">
        <h5 style={{ marginBottom: 0 }}>Bankmutatie</h5>
        <div className="flex-fill" />
        <span className="ml-3" style={{ position: 'relative', top: 1 }} title="Betalingskenmerk">
          {bankmutatie.data!.Betalingskenmerk}
        </span>
      </div>
      <div className="mt-1">
        <span>
          <FormatteerBedrag bedrag={bankmutatie.data!.Bedrag} fontSize={21} />
        </span>
      </div>
      <div className="d-flex flex-fill align-items-center">
        <Omschrijving title={bankmutatie.data!.Omschrijving}>
          {bankmutatie.data!.Omschrijving}
        </Omschrijving>
      </div>
      <table>
        <tbody>
          <tr>
            <td style={{ minWidth: 100 }}>Nog te boeken:</td>
            <td style={{ minWidth: 100 }}>
              {nogTeBoeken.state === ERemoteDataState.Pending ? (
                <Skeleton width={50} />
              ) : (
                <span className="d-flex align-items-center">
                  <FormatteerBedrag bedrag={bankmutatie.data!.Bedrag + nogTeBoeken.data!} />
                  {bankmutatie.data!.Bedrag + nogTeBoeken.data! === 0 ? (
                    <IconVink
                      className="ml-2"
                      style={{
                        width: 18,
                        height: 18,
                        fill: Kleur.Groen,
                      }}
                    />
                  ) : (
                    <IconPriorityHigh
                      className="ml-2"
                      style={{
                        width: 18,
                        height: 18,
                        fill: Kleur.Rood,
                      }}
                    />
                  )}
                </span>
              )}
            </td>
            <td style={{ minWidth: 70 }} title="Batchreferentie">
              Batch.ref:
            </td>
            <td style={{ minWidth: 100 }}>{bankmutatie.data!.BatchReferentie}</td>
            <td style={{ minWidth: 50 }}>IBAN:</td>
            <td style={{ minWidth: 185 }}>{bankmutatie.data!.IBAN}</td>
            <td style={{ width: '99%' }} />
            <td style={{ minWidth: 100 }}>Mutatiedatum:</td>
            <td style={{ minWidth: 80 }}>
              {format(new Date(bankmutatie.data!.Mutatiedatum), 'dd-MM-yyyy')}
            </td>
            {props.controleerBoekdatum && (
              <td style={{ minWidth: 18 }}>
                {props.boekdatum.state === ERemoteDataState.Pending ||
                props.boekdatum.data === null ? (
                  <Skeleton width={18} />
                ) : dagDatum(props.boekdatum.data!).getTime() ===
                  dagDatum(new Date(bankmutatie.data!.Mutatiedatum)).getTime() ? (
                  <span
                    title="De boekdatum is gelijk aan de mutatiedatum"
                    style={{
                      position: 'relative',
                      top: -2,
                    }}
                  >
                    <IconVink
                      style={{
                        width: 18,
                        height: 18,
                        fill: Kleur.Groen,
                      }}
                    />
                  </span>
                ) : (
                  <span
                    title="De boekdatum is niet gelijk aan de mutatiedatum"
                    style={{
                      position: 'relative',
                      top: -2,
                    }}
                  >
                    <IconPriorityHigh
                      style={{
                        width: 18,
                        height: 18,
                        fill: Kleur.Rood,
                      }}
                    />
                  </span>
                )}
              </td>
            )}
          </tr>
          <tr>
            <td>Mutatiesoort:</td>
            <td>{bankmutatie.data!.Mutatiesoort}</td>
            <td title="Returncode (stornocode)">Ret.code:</td>
            <td>{bankmutatie.data!.Returncode}</td>
            <td title="Rekeningnaam">Naam:</td>
            <td colSpan={4}>{bankmutatie.data!.Rekeningnaam}</td>
          </tr>
        </tbody>
      </table>
    </RegelBasis>
  );
};

export default BankmutatieRegel;
