import api from '../../index';
import {
  IOphalenAanmaningenViaIDsParams,
  IOphalenAanmaningenViaIDsResult,
  IVerwijderenAanmaningenParams,
  IVerwijderenAanmaningenResult,
  IDefinitiefMakenParams,
  IDefinitiefMakenResult,
  IVersturenAanmaningenParams,
  IVersturenAanmaningenResult,
  IOphalenAanmaningenParams,
  IOphalenAanmaningenResult,
  ISelecterenFacturenVoorAanmaningParams,
  ISelecterenFacturenVoorAanmaningResult,
  IMakenAanmaningenParams,
  IMakenAanmaningenResult,
  IWijzigenAanmaningdatumParams,
  IWijzigenAanmaningdatumResult,
} from '../../../../../shared/src/api/v2/aanmaning';
import ICheckData from '../../../../../shared/src/models/ICheckData';
import { IAchtergrondProces } from '../../../../../shared/src/api/v2/achtergrondProces';

const aanmaning = {
  ophalenAanmaningen: async (
    params: IOphalenAanmaningenParams,
  ): Promise<IOphalenAanmaningenResult> => {
    return await api.post('v2/aanmaning/ophalen-aanmaningen', params);
  },
  ophalenAanmaningenViaIDs: async (
    params: IOphalenAanmaningenViaIDsParams,
  ): Promise<IOphalenAanmaningenViaIDsResult> => {
    return await api.post('v2/aanmaning/ophalen-aanmaningen-via-ids', params);
  },
  checkVerwijderenAanmaningen: async (
    params: IVerwijderenAanmaningenParams,
  ): Promise<ICheckData> => {
    return await api.post('v2/aanmaning/check-verwijderen-aanmaningen', params);
  },
  verwijderenAanmaningen: async (
    params: IVerwijderenAanmaningenParams,
  ): Promise<IVerwijderenAanmaningenResult> => {
    return await api.post('v2/aanmaning/verwijderen-aanmaningen', params);
  },
  makenAanmaningen: async (params: IMakenAanmaningenParams): Promise<IMakenAanmaningenResult> => {
    return await api.post('v2/aanmaning/maken-aanmaningen', params);
  },
  definitiefMakenAanmaningen: async (
    params: IDefinitiefMakenParams,
  ): Promise<IDefinitiefMakenResult> => {
    return await api.post('v2/aanmaning/definitief-maken', params);
  },
  checkVersturenAanmaningen: async (params: IVersturenAanmaningenParams): Promise<ICheckData> => {
    return await api.post('v2/aanmaning/check-versturen-aanmaningen', params);
  },
  versturenAanmaningen: async (
    params: IVersturenAanmaningenParams,
  ): Promise<IVersturenAanmaningenResult> => {
    return await api.post('v2/aanmaning/versturen-aanmaningen', params);
  },
  versturenAanmaningenAchtergrond: async (
    params: IVersturenAanmaningenParams,
  ): Promise<IAchtergrondProces<IVersturenAanmaningenResult>> => {
    return await api.post('v2/aanmaning/versturen-aanmaningen-achtergrond', params);
  },
  selecterenFacturenVoorAanmaning: async (
    params: ISelecterenFacturenVoorAanmaningParams,
  ): Promise<ISelecterenFacturenVoorAanmaningResult> => {
    return await api.post('v2/aanmaning/selecteren-facturen-voor-aanmaning', params);
  },
  checkWijzigenAanmaningdatum: async (
    params: IWijzigenAanmaningdatumParams,
  ): Promise<ICheckData> => {
    return await api.post('v2/aanmaning/check-wijzigen-aanmaningdatum', params);
  },
  wijzigenAanmaningdatum: async (
    params: IWijzigenAanmaningdatumParams,
  ): Promise<IWijzigenAanmaningdatumResult> => {
    return await api.post('v2/aanmaning/wijzigen-aanmaningdatum', params);
  },
};

export default aanmaning;
