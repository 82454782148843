import api from '../../index';
import {
  IOphalenEigenschapProductEntiteitenParams,
  IOphalenEigenschapProductEntiteitenResult,
  IOphalenEigenschapProductenParams,
  IOphalenEigenschapProductenResult,
  IVerwijderenEigenschapProductEntiteitenParams,
  IVerwijderenEigenschapProductEntiteitenResult,
  IToevoegenEigenschapProductEntiteitParams,
  IToevoegenEigenschapProductEntiteitResult,
  IWijzigenEigenschapProductEntiteitParams,
  IWijzigenEigenschapProductEntiteitResult,
  IKopierenProductEigenschappenNaarProductParams,
  IKopierenProductEigenschappenNaarProductResult,
  IVerwijderenEigenschapProductenParams,
  IVerwijderenEigenschapProductenResult,
  IToevoegenEigenschapProductParams,
  IToevoegenEigenschapProductResult,
  IWijzigenEigenschapProductParams,
  IWijzigenEigenschapProductResult,
  IOphalenEigenschapGroepenParams,
  IOphalenEigenschapGroepenResult,
  IVerwijderenEigenschapGroepenParams,
  IVerwijderenEigenschapGroepenResult,
  IToevoegenEigenschapGroepParams,
  IToevoegenEigenschapGroepResult,
  IWijzigenEigenschapGroepParams,
  IWijzigenEigenschapGroepResult,
  ISorterenEigenschapGroepenParams,
  ISorterenEigenschapGroepenResult,
} from '../../../../../shared/src/api/v2/aanbod/index';
import ICheckData from '../../../../../shared/src/models/ICheckData';

const specificatie = {
  checkKopierenProductEigenschappenNaarProduct: async (
    params: IKopierenProductEigenschappenNaarProductParams,
  ): Promise<ICheckData> => {
    return await api.post(
      '/v2/aanbod/specificatie/check-kopieren-product-eigenschappen-naar-product',
      params,
    );
  },
  kopierenProductEigenschappenNaarProduct: async (
    params: IKopierenProductEigenschappenNaarProductParams,
  ): Promise<IKopierenProductEigenschappenNaarProductResult> => {
    return await api.post(
      '/v2/aanbod/specificatie/kopieren-product-eigenschappen-naar-product',
      params,
    );
  },

  toevoegenEigenschapProductEntiteit: async (
    params: IToevoegenEigenschapProductEntiteitParams,
  ): Promise<IToevoegenEigenschapProductEntiteitResult> => {
    return await api.post('/v2/aanbod/specificatie/toevoegen-eigenschap-product-entiteit', params);
  },
  wijzigenEigenschapProductEntiteit: async (
    params: IWijzigenEigenschapProductEntiteitParams,
  ): Promise<IWijzigenEigenschapProductEntiteitResult> => {
    return await api.post('/v2/aanbod/specificatie/wijzigen-eigenschap-product-entiteit', params);
  },
  verwijderenEigenschapProductEntiteiten: async (
    params: IVerwijderenEigenschapProductEntiteitenParams,
  ): Promise<IVerwijderenEigenschapProductEntiteitenResult> => {
    return await api.post(
      '/v2/aanbod/specificatie/verwijderen-eigenschap-product-entiteiten',
      params,
    );
  },
  ophalenEigenschapProductEntiteiten: async (
    params: IOphalenEigenschapProductEntiteitenParams,
  ): Promise<IOphalenEigenschapProductEntiteitenResult> => {
    return await api.post('/v2/aanbod/specificatie/ophalen-eigenschap-product-entiteiten', params);
  },
  ophalenEigenschapProducten: async (
    params: IOphalenEigenschapProductenParams,
  ): Promise<IOphalenEigenschapProductenResult> => {
    return await api.post('/v2/aanbod/specificatie/ophalen-eigenschap-producten', params);
  },
  checkVerwijderenEigenschapProducten: async (
    params: IVerwijderenEigenschapProductenParams,
  ): Promise<ICheckData> => {
    return await api.post('/v2/aanbod/specificatie/check-verwijderen-eigenschap-producten', params);
  },
  verwijderenEigenschapProducten: async (
    params: IVerwijderenEigenschapProductenParams,
  ): Promise<IVerwijderenEigenschapProductenResult> => {
    return await api.post('/v2/aanbod/specificatie/verwijderen-eigenschap-producten', params);
  },
  toevoegenEigenschapProduct: async (
    params: IToevoegenEigenschapProductParams,
  ): Promise<IToevoegenEigenschapProductResult> => {
    return await api.post('/v2/aanbod/specificatie/toevoegen-eigenschap-product', params);
  },
  checkToevoegenEigenschapProduct: async (
    params: IToevoegenEigenschapProductParams,
  ): Promise<ICheckData> => {
    return await api.post('/v2/aanbod/specificatie/check-toevoegen-eigenschap-product', params);
  },
  wijzigenEigenschapProduct: async (
    params: IWijzigenEigenschapProductParams,
  ): Promise<IWijzigenEigenschapProductResult> => {
    return await api.post('/v2/aanbod/specificatie/wijzigen-eigenschap-product', params);
  },
  checkWijzigenEigenschapProduct: async (
    params: IWijzigenEigenschapProductParams,
  ): Promise<ICheckData> => {
    return await api.post('/v2/aanbod/specificatie/check-wijzigen-eigenschap-product', params);
  },
  ophalenEigenschapGroepen: async (
    params: IOphalenEigenschapGroepenParams,
  ): Promise<IOphalenEigenschapGroepenResult> => {
    return await api.post('/v2/aanbod/specificatie/ophalen-eigenschap-groepen', params);
  },
  verwijderenEigenschapGroepen: async (
    params: IVerwijderenEigenschapGroepenParams,
  ): Promise<IVerwijderenEigenschapGroepenResult> => {
    return await api.post('/v2/aanbod/specificatie/verwijderen-eigenschap-groepen', params);
  },
  checkVerwijderenEigenschapGroepen: async (
    params: IVerwijderenEigenschapGroepenParams,
  ): Promise<ICheckData> => {
    return await api.post('/v2/aanbod/specificatie/check-verwijderen-eigenschap-groepen', params);
  },
  toevoegenEigenschapGroep: async (
    params: IToevoegenEigenschapGroepParams,
  ): Promise<IToevoegenEigenschapGroepResult> => {
    return await api.post('/v2/aanbod/specificatie/toevoegen-eigenschap-groep', params);
  },
  checkToevoegenEigenschapGroep: async (
    params: IToevoegenEigenschapGroepParams,
  ): Promise<ICheckData> => {
    return await api.post('/v2/aanbod/specificatie/check-toevoegen-eigenschap-groep', params);
  },
  wijzigenEigenschapGroep: async (
    params: IWijzigenEigenschapGroepParams,
  ): Promise<IWijzigenEigenschapGroepResult> => {
    return await api.post('/v2/aanbod/specificatie/wijzigen-eigenschap-groep', params);
  },
  checkWijzigenEigenschapGroep: async (
    params: IWijzigenEigenschapGroepParams,
  ): Promise<ICheckData> => {
    return await api.post('/v2/aanbod/specificatie/check-wijzigen-eigenschap-groep', params);
    return await api.post('/v2/aanbod/specificatie/check-wijzigen-eigenschap-groep', params);
  },
  sorterenEigenschapGroepen: async (
    params: ISorterenEigenschapGroepenParams,
  ): Promise<ISorterenEigenschapGroepenResult> => {
    return await api.post('/v2/aanbod/specificatie/sorteren-eigenschap-groepen', params);
  },
};

export default specificatie;
