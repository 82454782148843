import { EFilter, ExportMedium, RapportageType } from './types';

export const actieveFiltersPerRapportageType: Record<
  RapportageType,
  Partial<Record<EFilter, boolean>>
> = {
  OPENSTAANDE_DEBITEUREN: {
    [EFilter.Peildatum]: true,
    [EFilter.AlleenVervallenFacturen]: true,
  },
  OPENSTAANDE_CREDITEUREN: {
    [EFilter.Peildatum]: true,
    [EFilter.AlleenVervallenFacturen]: true,
  },
  GEGEVENS_TEN_BEHOEVE_VAN_JAARWERK: {
    [EFilter.Jaar]: true,
  },
  WINST_EN_VERLIES_REKENING: {
    [EFilter.Jaar]: true,
    [EFilter.Periode]: true,
  },
  LIJST_ACTIVAREGISTER: {
    [EFilter.Jaar]: true,
    [EFilter.Periode]: true,
  },
  LIJST_PRODUCTEN_UIT_BEDRIJF: {
    [EFilter.Jaar]: true,
    [EFilter.Periode]: true,
  },
};

export const actieveExportMediaPerRapportageType: Record<RapportageType, ExportMedium[]> = {
  OPENSTAANDE_DEBITEUREN: ['PDF'],
  OPENSTAANDE_CREDITEUREN: ['PDF'],
  GEGEVENS_TEN_BEHOEVE_VAN_JAARWERK: ['PDF'],
  WINST_EN_VERLIES_REKENING: ['CSV'],
  LIJST_ACTIVAREGISTER: ['CSV'],
  LIJST_PRODUCTEN_UIT_BEDRIJF: ['CSV'],
};
