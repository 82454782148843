import React, { HTMLProps, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';
import LoadingSpinner from '../Gedeeld/LoadingSpinner';
import HorizontaleScheidingslijn from '../layout/HorizontaleScheidingslijn';
import { v4 as uuidv4 } from 'uuid';

export interface IResultaatComponentProps<T> {
  item: T;
}

enum EState {
  Inactief,
  AanHetZoeken,
  ResultatenWeergeven,
}

interface IResultaatState<T> {
  state: EState;
  resultaten: T[] | null;
  totaalAantal: number | null;
}

export interface IZoekVeldInputComponentProps extends HTMLProps<HTMLInputElement> {
  onValueChange: (value: string) => void;
}

export interface IZoekResultaat<T> {
  items: T[] | null;
  totaalAantal: number | null;
}

interface IProps<T> {
  term: string;
  onTermChange: (term: string) => void;
  onZoekenAangevraagd: () => Promise<IZoekResultaat<T>>;
  resultaatComponent: React.ComponentType<IResultaatComponentProps<T>>;
  geenResultatenComponent?: React.ComponentType;
  inputComponent?: React.ForwardRefExoticComponent<IZoekVeldInputComponentProps>;
  breedteResultaat: string | number;
  maximaleHoogte?: string | number;
}

const ZoekVeld = <T extends {}>(props: IProps<T>) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const id = useMemo(() => uuidv4(), []);
  const [inputHasFocus, setInputHasFocus] = useState(false);
  const [state, setState] = useState<IResultaatState<T>>({
    state: EState.Inactief,
    resultaten: null,
    totaalAantal: null,
  });

  const zoekTimeoutRef = useRef<number>(null);
  const zoekenUitvoeren = useCallback(async () => {
    if (props.term.length === 0) {
      setState((_) => ({
        state: EState.Inactief,
        resultaten: null,
        totaalAantal: null,
      }));
      return;
    }
    setState((prevState) => ({
      ...prevState,
      state: EState.AanHetZoeken,
    }));
    const result = await props.onZoekenAangevraagd();
    setState((prevState) => ({
      ...prevState,
      state: EState.ResultatenWeergeven,
      resultaten: result.items,
      totaalAantal: result.totaalAantal,
    }));
  }, [setState, props.term, props.onZoekenAangevraagd]);

  const handleChange = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      props.onTermChange(ev.target.value);
    },
    [props.onTermChange],
  );

  useEffect(() => {
    if (zoekTimeoutRef.current !== null) {
      clearTimeout(zoekTimeoutRef.current);
    }
    // @ts-ignore
    zoekTimeoutRef.current = setTimeout(zoekenUitvoeren, 500);
  }, [props.term]);

  const popupWeergave = useMemo(() => {
    switch (state.state) {
      case EState.Inactief:
        return (
          <div className="p-2 pl-3 pr-3">
            <span>Begin met zoeken door iets in te voeren...</span>
          </div>
        );
      case EState.AanHetZoeken:
        return <LoadingSpinner />;
      case EState.ResultatenWeergeven: {
        if (state.resultaten === null && state.totaalAantal === null) {
          return (
            <div className="p-4 pl-5 pr-5">
              <span className="font-weight-bold" style={{ fontSize: 14 }}>
                Er zijn geen resultaten opgehaald. Probeer de selectie te verfijnen.
              </span>
            </div>
          );
        }
        if (state.resultaten === null && state.totaalAantal !== null) {
          return (
            <div className="p-4 pl-5 pr-5">
              <span className="font-weight-bold" style={{ fontSize: 14 }}>
                Er zijn te veel ({state.totaalAantal}) resultaten gevonden. Probeer de selectie te
                verfijnen.
              </span>
            </div>
          );
        }
        if (
          state.resultaten !== null &&
          state.resultaten.length === 0 &&
          state.totaalAantal === 0
        ) {
          return (
            <div className="p-4 pl-5 pr-5">
              <span className="font-weight-bold" style={{ fontSize: 14 }}>
                Er zijn geen resultaten gevonden voor de selectie.
              </span>
            </div>
          );
        }
        if (state.resultaten !== null) {
          return state.resultaten.map((resultaat, i) => {
            return (
              <div key={i} className="flex-fill" style={{ width: '100%' }}>
                <props.resultaatComponent item={resultaat} />
                <HorizontaleScheidingslijn />
              </div>
            );
          });
        }

        throw new Error('Niet geimplementeerd');
      }
    }
    throw new Error();
  }, [state]);

  const inputProps = useMemo<HTMLProps<HTMLInputElement>>(() => {
    return {
      ref: inputRef,
      type: 'search',
      className: 'form-control',
      value: props.term,
      onChange: handleChange,
      placeholder: 'Zoeken',
      onFocus: () => setInputHasFocus(true),
      onBlur: () => setInputHasFocus(false),
      style: {
        height: 30,
      },
    };
  }, [props.term, handleChange, setInputHasFocus, inputRef, props.inputComponent]);

  const [overlayIsHovered, setOverlayIsHovered] = useState(false);
  const overlayWeergeven = useMemo(
    () => state.state !== EState.Inactief && (inputHasFocus || overlayIsHovered),
    [inputHasFocus, overlayIsHovered, state],
  );

  return (
    <>
      {props.inputComponent === undefined ? (
        <input {...inputProps} />
      ) : (
        <props.inputComponent
          {...inputProps}
          onValueChange={(value) => props.onTermChange(value)}
        />
      )}
      <Overlay target={inputRef.current!} show={overlayWeergeven} placement="bottom-start">
        <Popover
          id={id}
          style={{
            minWidth: props.breedteResultaat,
            maxHeight: props.maximaleHoogte === undefined ? 500 : props.maximaleHoogte,
            overflowY: 'auto',
          }}
          className="d-flex flex-column"
          onMouseEnter={() => setOverlayIsHovered(true)}
          onMouseLeave={() => setOverlayIsHovered(false)}
        >
          <div
            className="d-flex align-items-center justify-content-center flex-column"
            style={{
              minWidth: inputRef.current === null ? undefined : inputRef.current.width,
            }}
          >
            {popupWeergave}
          </div>
        </Popover>
      </Overlay>
    </>
  );
};

export default ZoekVeld;
