import * as React from 'react';
import TegelBasis from '../../../../../../../components/tegel/TegelBasis';
import NavigatiePijl from '../NavigatiePijl';
import { IOphalenInformatieVoorOverzichtBestelling } from '../../../../../../../../../shared/src/api/v2/relatie';
import { format } from 'date-fns';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { genereerUrlStateQueryParam } from '../../../../../../../core/useUrlState';
import {
  IUrlState as IWinkelwagensUrlState,
  defaultUrlState as defaultWinkelwagensUrlState,
} from '../../Winkelwagens';
import FormatteerBedrag from '../../../../../../../components/MutatieBedrag';
import GegevensLayout from '../../../../../../../components/layout/GegevensLayout';
import _ from 'lodash';
import { IRegelProduct } from '../../../../../../../../../shared/src/api/v2/winkelwagen';
import HorizontaleScheidingslijn from '../../../../../../../components/layout/HorizontaleScheidingslijn';
import { Kleur as EKleur } from '../../../../../../../bedrijfslogica/constanten';
import {
  IKEContractenOverzichtUrlState,
  defaultKEContractenOverzichtUrlState,
} from '../../Contracten/Overzicht';

interface IProps extends RouteComponentProps {
  relID: number;
  data: IOphalenInformatieVoorOverzichtBestelling;
}

const Bestellingtegel = (props: IProps) => {
  const bestellingen = props.data.bestellingen;

  const maxAantalWeerTeGeven = 3;

  return (
    <TegelBasis titel="Bestellingen" subtitel={<span>Onderhanden</span>}>
      <div>
        {bestellingen.map((bestelling, i) => {
          const cntIDs = (bestelling.regels as any).map((x: any) => x.GekoppeldContract_CntID);

          const gebruikersAantallen = _.uniq(
            bestelling.regels
              .filter((x) => x.Soort === 1)
              .map((x: any) => x.AantalGebruikers)
              .sort((a, b) => a - b),
          );

          return (
            <>
              <div className="d-flex">
                <div style={{ width: 250 }}>
                  <GegevensLayout
                    gegevens={[
                      {
                        label: 'Besteldatum',
                        waarde:
                          bestelling.Besteldatum !== null ? (
                            <span>
                              {format(new Date(bestelling.Besteldatum), 'dd-MM-yyyy HH:mm')}
                            </span>
                          ) : (
                            <span style={{ color: EKleur.Rood }}>Nog niet afgerond</span>
                          ),
                      },
                      {
                        label: 'Bezoekdatum',
                        waarde:
                          bestelling.Bezoekdatum !== null
                            ? format(new Date(bestelling.Bezoekdatum), 'dd-MM-yyyy')
                            : null,
                      },
                      {
                        label: 'Plaats',
                        waarde: bestelling.locatie.Plaatsnaam,
                      },
                      {
                        label: 'Verdieping',
                        waarde: (
                          (bestelling.Verdieping !== null
                            ? bestelling.Verdieping.toString() + 'e'
                            : '') + (bestelling.locatie.LiftAanwezig ? ' (Lift aanwezig)' : '')
                        ).trim(),
                      },
                    ]}
                  />
                </div>
                <div style={{ width: 375 }}>
                  <GegevensLayout
                    gegevens={[
                      {
                        label: `Producten (${
                          bestelling.regels.filter((x) => x.Soort === 1).length
                        })`,
                        waarde: bestelling.regels
                          .filter((x) => x.Soort === 1)
                          .map((x: any) => x.productmodel.Modelcode)
                          .join(', '),
                      },
                      {
                        label: 'Atl. gebruikers',
                        waarde: gebruikersAantallen.join(','),
                      },
                      {
                        label: 'Atl. afvoer oud',
                        waarde: bestelling.AantalAfvoer,
                      },
                      {
                        label: 'Tel. voorbericht',
                        waarde: bestelling.TelefonischVoorberichtBezoek ? '1/2 half uur vtv' : '',
                      },
                      bestelling.Wervingscode !== null
                        ? {
                            label: 'KwK-code ingevuld',
                            waarde: (
                              <a
                                href="#"
                                className="d-flex align-items-center"
                                onClick={(ev) => {
                                  props.history.push(
                                    `/klant/${props.relID}/klant/klant-werft-klant`,
                                  );
                                }}
                              >
                                {/* <span style={{ color: EKleur.Rood }}> */}
                                {bestelling.Wervingscode}
                                {/* </span> */}
                              </a>
                            ),
                          }
                        : null,
                    ]}
                  />
                </div>

                <div style={{ width: 350 }}>
                  <div className="d-flex">
                    <div>
                      <div>
                        {bestelling.onderwijsinstelling !== null
                          ? bestelling.onderwijsinstelling.Naam
                          : bestelling.OnderwijsinstellingNaam}
                      </div>
                      {bestelling.Opmerking !== null ? (
                        <div>{bestelling.Opmerking}</div>
                      ) : (
                        <div>Geen opmerking</div>
                      )}
                    </div>
                  </div>
                </div>

                <div style={{ width: 150 }}>
                  <div className="row">
                    Contracten
                    <NavigatiePijl
                      relID={props.relID}
                      pathWithStateBuilder={() => {
                        const state = genereerUrlStateQueryParam({
                          selectieCntIDs: cntIDs,
                          uitgeklapteLocIDs: [],
                        });
                        return `contracten/overzicht?state=${state}`;
                      }}
                    />
                    Winkelwagen
                    <NavigatiePijl
                      relID={props.relID}
                      pathWithStateBuilder={() => {
                        const state = genereerUrlStateQueryParam<IWinkelwagensUrlState>({
                          ...defaultWinkelwagensUrlState,
                          selectie: [bestelling.ID],
                        });
                        return `winkelwagens?state=${state}`;
                      }}
                    />
                  </div>
                </div>
              </div>
              {i < bestellingen.length - 1 && (
                <div className="mb-3 mt-3">
                  <HorizontaleScheidingslijn />
                </div>
              )}
            </>
          );
        })}

        {bestellingen.length > maxAantalWeerTeGeven && (
          <div className="mt-2">
            <a
              href="#"
              onClick={(ev) => {
                ev.preventDefault();

                props.history.push(`/klant/${props.relID}/facturen/overzicht`);
              }}
            >
              Er zijn nog {bestellingen.length - maxAantalWeerTeGeven}
              &nbsp;andere bestellingen...
            </a>
          </div>
        )}
      </div>
    </TegelBasis>
  );

  // return (
  //   <TegelBasis titel="Bestelling">
  //     <div className="pl-2 pr-2 pb-2">
  //       <div className="d-flex align-items-center">
  //         {/* <span>Bestelling</span> */}
  //         <span style={{ width: 100 }}>
  //           {bestelling.Besteldatum !== null
  //             ? format(new Date(bestelling.Besteldatum), 'dd-MM-yyyy')
  //             : ''}
  //         </span>
  //         <span style={{ width: 100 }}>
  //           {bestelling.Bezoekdatum !== null
  //             ? format(new Date(bestelling.Bezoekdatum), 'dd-MM-yyyy')
  //             : ''}
  //         </span>
  //         <span style={{ width: 300 }}>{bestelling.Opmerking}</span>
  //         <span className="ml-2">
  //           <NavigatiePijl relID={props.relID} pathWithStateBuilder={() => 'winkelwagens'} />
  //         </span>
  //       </div>
  //     </div>
  //   </TegelBasis>
  // );
};

export default withRouter(Bestellingtegel);
