import { arrayMove } from 'react-sortable-hoc';

enum ESortNrWijzigingsRichting {
  Omlaag,
  Omhoog,
}

interface IHerorderArraySortNrItem {
  SortNr: number;
}
export interface IHerorderArraySortNrResult<T extends IHerorderArraySortNrItem> {
  newArr: T[];
  sortNrWijziging: {
    huidigSortNr: number;
    nieuwSortNr: number;
  };
}

export const herorderArraySortNr = <T extends IHerorderArraySortNrItem>(
  arr: T[],
  oldIndex: number,
  newIndex: number,
): IHerorderArraySortNrResult<T> => {
  const richting =
    oldIndex > newIndex ? ESortNrWijzigingsRichting.Omlaag : ESortNrWijzigingsRichting.Omhoog;

  const movedArr = arrayMove<T>(arr, oldIndex, newIndex);

  const huidigSortNr = arr[oldIndex]!.SortNr;
  const nieuwSortNr = arr[richting === ESortNrWijzigingsRichting.Omhoog ? newIndex : newIndex]!
    .SortNr;

  return {
    newArr: movedArr,
    sortNrWijziging: {
      huidigSortNr,
      nieuwSortNr,
    },
  };
};
