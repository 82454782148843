import React, { useRef, useState } from 'react';
import IDialoogProps from '../../../../../core/IDialoogProps';
import Modal from 'react-bootstrap/Modal';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import ModalHeader from 'react-bootstrap/ModalHeader';
import api from '../../../../../api';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../../../stores/RootStore';
import { addDays } from 'date-fns';
import {
  IOphalenPreviewBatchesResultElement,
  IVerzamelenOpdrachtenFilterResult,
  IVerzamelenOpdrachtenResult,
} from '../../../../../../../shared/src/api/v2/bank/opdracht/opdracht';
import Select from 'react-select';
import { IOptionType } from '../../../../../components/Gedeeld/FormulierVelden';
import * as Yup from 'yup';
import RadioKnop from '../../../../../components/formulier/RadioKnop';
import DatumKiezer from '../../../../../components/formulier/DatumKiezer';
import { dagDatum, vandaagZonderTijd } from '../../../../../helpers/datum';
import { EResultType } from '../../../../../stores/CheckStore';
import VinkVeld from '../../../../../components/formulier/VinkVeld';
import nameof from '../../../../../core/nameOf';
import PreviewBatchDialoog from '../PreviewBatchDialoog';
import { Kleur as EKleur } from '../../../../../bedrijfslogica/constanten';
import { IFilterSchemaFilter } from '../../../../../../../shared/src/models/filter';

interface IProps extends IDialoogProps<IVerzamelenOpdrachtenResult> {}

interface IFormikValues {
  bankRekIDs: IOptionType[];
  soort: number;
  uitvoerdatum: Date | string;
  negeerWaarschuwingen: boolean;
  ookMetLegeUitvoerdatum: boolean;
  toevoegenAanBestaandeBatch: boolean;
  alleenAlsOpdrachtEerderIsVerstuurd: boolean;
}

const vandaag = vandaagZonderTijd();
const maximaleDatum = addDays(vandaag, 30);

export interface IPreviewDialoogState {
  bankOpdIDs: number[];
}

export interface IRegel extends IOphalenPreviewBatchesResultElement {}

const VerzamelModal: React.FC<IProps> = observer((props) => {
  const { bankStore, bankOpdrachtenStore, instellingStore } = useContext(RootStoreContext);
  const { checkStore } = useContext(RootStoreContext);
  const formikRef = useRef<Formik<IFormikValues>>(null);

  const [
    previewBatchesDialoogState,
    setPreviewBatchesDialoogState,
  ] = useState<IPreviewDialoogState | null>(null);

  useEffect(() => {
    if (bankStore.rekeningen !== null) {
      return;
    }

    bankStore.ophalenRekeningen();
  }, [bankStore.rekeningen]);

  // Ophalen preview batches ahv het ingestelde filter
  const [previewBatches, setPreviewBatches] = useState<IRegel[] | null>(null);
  const [totaalbedrag, setTotaalbedrag] = useState<number | null>(null);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);
      if (
        (
          await checkStore.bevestigen({
            inhoud: (
              <>
                Batches maken?
                <br />
                {values.soort === 0 ? (
                  <span>Voor zowel incasso- als betaalopdrachten.</span>
                ) : values.soort === 1 ? (
                  <span>Alleen voor incasso-opdrachten.</span>
                ) : (
                  <span>Alleen voor betaalopdrachten.</span>
                )}
                <br />
                <br />
                Opdrachten die op Ophouden staan worden niet meegenomen.
              </>
            ),
          })
        ).type === EResultType.Annuleren
      ) {
        actions.setSubmitting(false);
        return;
      }

      const opdrachtenResult = await api.v2.bank.opdracht.verzamelenOpdrachtenFilter({
        filterSchema: {
          filters: [
            { naam: 'BANKREK_IDS', data: values.bankRekIDs.map((x) => Number(x.value)) },
            { naam: 'SOORT', data: values.soort },
            { naam: 'VRIJGEVEN', data: false },
            values.ookMetLegeUitvoerdatum
              ? {
                  naam: 'UITVOERDATUM_TOT_EN_MET_OF_LEEG',
                  data: values.uitvoerdatum,
                }
              : {
                  naam: 'UITVOERDATUM_TOT_EN_MET',
                  data: values.uitvoerdatum,
                },
            values.alleenAlsOpdrachtEerderIsVerstuurd
              ? { naam: 'ALLEEN_ALS_OPDRACHT_EERDER_IS_VERSTUURD', data: true }
              : null,
          ].filter((x) => x !== null) as IFilterSchemaFilter[],
        },
        negeerWaarschuwingen: values.negeerWaarschuwingen,
      });

      const result = await api.v2.bank.opdracht.verzamelenOpdrachten({
        opdIDs: opdrachtenResult.bankOpdIDs,
        toevoegenAanBestaandeBatch: values.toevoegenAanBestaandeBatch,
      });

      props.onSuccess(result);

      actions.setSubmitting(false);
    },
    [props.onSuccess],
  );

  const initialValues = useMemo<IFormikValues>(() => {
    return {
      bankRekIDs:
        bankStore.rekeningen === null
          ? []
          : [
              {
                value: String(instellingStore.BankRekID!),
                label: bankStore.rekeningen!.find((x) => x.BankRekID === instellingStore.BankRekID)!
                  .NaamKort,
              },
            ],
      soort: 0,
      uitvoerdatum: dagDatum(addDays(new Date(), 1)),
      negeerWaarschuwingen: false,
      ookMetLegeUitvoerdatum: true,
      toevoegenAanBestaandeBatch: true,
      alleenAlsOpdrachtEerderIsVerstuurd: false,
    };
  }, [instellingStore.BankRekID, bankStore.rekeningen]);

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      bankRekIDs: Yup.array().min(1, 'Er moet minimaal een rekening geselecteerd worden'),
    });
  }, []);

  const renderForm = useCallback(
    (formikProps: FormikProps<IFormikValues>) => {
      if (bankStore.rekeningen === null) {
        return null;
      }

      return (
        <>
          <ModalBody>
            <div className="form-group d-flex align-items-center">
              <span className="control-label" style={{ width: 165 }}>
                Rekeningen
              </span>
              <Field
                name="bankRekIDs"
                render={({ field, form }: FieldProps<IFormikValues>) => {
                  return (
                    <Select<IOptionType>
                      isMulti
                      isClearable
                      onChange={(value: any) => form.setFieldValue(field.name, value || [])}
                      options={(bankStore.rekeningen || []).map((rekening: any) => ({
                        label: rekening.NaamKort,
                        value: String(rekening.RelRekID),
                      }))}
                      value={field.value}
                      styles={{
                        container: (p) => ({
                          ...p,
                          width: '100%',
                        }),
                      }}
                    />
                  );
                }}
              />
            </div>

            <div className="form-group d-flex align-items-center mt-3">
              <span className="control-label" style={{ width: 125 }}>
                Soort
              </span>
              <Field
                name="soort"
                render={({ field, form }: FieldProps<IFormikValues>) => {
                  return (
                    <div className="d-flex" style={{ width: 250 }}>
                      <span className="d-flex align-items-center">
                        <RadioKnop
                          aangevinkt={field.value === 0}
                          onAangevinkt={() => form.setFieldValue(field.name, 0)}
                        />
                        Beide
                      </span>
                      <span className="d-flex align-items-center ml-2">
                        <RadioKnop
                          aangevinkt={field.value === 1}
                          onAangevinkt={() => form.setFieldValue(field.name, 1)}
                        />
                        Incasseren
                      </span>
                      <span className="d-flex align-items-center ml-2">
                        <RadioKnop
                          aangevinkt={field.value === 2}
                          onAangevinkt={() => form.setFieldValue(field.name, 2)}
                        />
                        Betalen
                      </span>
                    </div>
                  );
                }}
              />
            </div>

            <div className="form-group d-flex align-items-center mt-3">
              <span className="control-label" style={{ width: 125 }}>
                Uitvoerdatum t/m
              </span>
              <Field
                name="uitvoerdatum"
                render={({ field, form }: FieldProps<IFormikValues>) => {
                  return (
                    <div className="d-flex justify-content-end">
                      <DatumKiezer
                        onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                        waarde={field.value}
                        determineValidDate={(date) => {
                          return date <= maximaleDatum && date >= addDays(new Date(), 0);
                        }}
                        determineNextValidDate={(date) => {
                          const newDate = addDays(date, 1);
                          if (newDate < maximaleDatum) {
                            return newDate;
                          }
                          return null;
                        }}
                        determinePreviousValidDate={(date) => {
                          const minDate = addDays(new Date(), -1);
                          const newDate = addDays(date, -1);
                          if (newDate >= minDate) {
                            return newDate;
                          }
                          return null;
                        }}
                      />
                    </div>
                  );
                }}
              />
            </div>

            <div className="form-group d-flex align-items-center mt-3">
              <Field
                name={nameof<IFormikValues>('toevoegenAanBestaandeBatch')}
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;
                  return (
                    <VinkVeld
                      aangevinkt={field.value}
                      onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                    />
                  );
                }}
              />
              <span className="ml-2">
                Eventueel toevoegen aan bestaande batches (indien toegestaan)
              </span>
            </div>

            <div className="form-group d-flex align-items-center mt-3">
              <Field
                name={nameof<IFormikValues>('ookMetLegeUitvoerdatum')}
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;
                  return (
                    <VinkVeld
                      aangevinkt={field.value}
                      onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                    />
                  );
                }}
              />
              <span className="ml-2">Ook opdrachten met lege uitvoerdatum selecteren</span>
            </div>

            <div className="form-group d-flex align-items-center mt-3">
              <Field
                name={nameof<IFormikValues>('negeerWaarschuwingen')}
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;
                  return (
                    <VinkVeld
                      aangevinkt={field.value}
                      onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                    />
                  );
                }}
              />
              <span className="ml-2">Ook opdrachten met waarschuwingen selecteren</span>
            </div>

            <div className="form-group d-flex align-items-center mt-3">
              <Field
                name={nameof<IFormikValues>('alleenAlsOpdrachtEerderIsVerstuurd')}
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;
                  return (
                    <VinkVeld
                      aangevinkt={field.value}
                      onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                    />
                  );
                }}
              />
              <span className="ml-2">Alleen als opdracht eerder is verstuurd</span>
            </div>

            <div>
              <a
                href="#"
                style={{ color: EKleur.DonkerBlauw }}
                onClick={async () => {
                  const values = formikProps.values;
                  const result = await api.v2.bank.opdracht.verzamelenOpdrachtenFilter({
                    filterSchema: {
                      filters: [
                        {
                          naam: 'BANKREK_IDS',
                          data: values.bankRekIDs.map((x) => Number(x.value)),
                        },
                        { naam: 'SOORT', data: values.soort },
                        { naam: 'VRIJGEVEN', data: false },
                        values.ookMetLegeUitvoerdatum
                          ? {
                              naam: 'UITVOERDATUM_TOT_EN_MET_OF_LEEG',
                              data: values.uitvoerdatum,
                            }
                          : {
                              naam: 'UITVOERDATUM_TOT_EN_MET',
                              data: values.uitvoerdatum,
                            },
                        values.alleenAlsOpdrachtEerderIsVerstuurd
                          ? { naam: 'ALLEEN_ALS_OPDRACHT_EERDER_IS_VERSTUURD', data: true }
                          : null,
                      ].filter((x) => x !== null) as IFilterSchemaFilter[],
                    },
                    negeerWaarschuwingen: values.negeerWaarschuwingen,
                  });

                  if (result.bankOpdIDs.length === 0) {
                    await checkStore.melden({
                      titel: 'Er zijn geen opdrachten gevonden voor het ingestelde filter',
                    });
                    return;
                  }

                  setPreviewBatchesDialoogState({ bankOpdIDs: result.bankOpdIDs });

                  return;
                }}
              >
                Een preview van het resultaat weergeven
              </a>
            </div>
          </ModalBody>
          <ModalFooter
            style={{
              justifyContent: 'flex-start',
            }}
          >
            <button
              className="btn btn-primary"
              onClick={formikProps.submitForm}
              disabled={formikProps.isSubmitting || !formikProps.isValid}
            >
              Verzamelen
            </button>
            <button className="btn btn-light ml-3" onClick={props.onAnnuleren}>
              Annuleren
            </button>
          </ModalFooter>
        </>
      );
    },
    [bankStore.rekeningen],
  );

  return (
    <>
      <Modal show={props.open}>
        <ModalHeader>
          <ModalTitle>Verzamelen opdrachten</ModalTitle>
        </ModalHeader>
        <Formik<IFormikValues>
          ref={formikRef}
          enableReinitialize
          isInitialValid
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          initialValues={initialValues}
          render={renderForm}
        />
      </Modal>
      {previewBatchesDialoogState !== null && (
        <PreviewBatchDialoog
          dialoogIndex={1000}
          open
          bankOpdIDs={previewBatchesDialoogState.bankOpdIDs}
          onSuccess={() => setPreviewBatchesDialoogState(null)}
          onAnnuleren={() => setPreviewBatchesDialoogState(null)}
        />
      )}
    </>
  );
});

export default VerzamelModal;
