import { useCallback, useMemo, useState } from 'react';
import { IOphalenOpdrachtenResultElementV2 } from '../../../../../shared/src/api/v2/transport/opdracht';
import _ from 'lodash';
import api from '../../../api';
import { setCommunicatieOverlayState } from '../../../one-off-components/CommunicatieOverlay';
import { ECommunicatieTabblad } from '../../../one-off-components/CommunicatieOverlay/MenuHandle';
import { defaultOpstellenFormulierFormikValues } from '../../../components/communicatie/EmailWerkblad/OpstellenFormulier';
import relatie from '../../../api/v2/relatie';

interface IUseTPBVersturenParams {
  opdrachten: IOphalenOpdrachtenResultElementV2[] | null;
  contextRelIDs: number[];
}

interface IUseTPBVersturenOutput {
  versturenTPB?: () => Promise<void>;
  isBezig: boolean;
}

const useTPBVersturen = (params: IUseTPBVersturenParams): IUseTPBVersturenOutput => {
  const [isBezig, setIsBezig] = useState(false);

  const versturenTPB = useMemo(() => {
    if (params.opdrachten === null) {
      return undefined;
    }

    return async () => {
      setIsBezig(true);

      const trsDienstIDs = _.uniq(
        params.opdrachten!.map((opdracht) => opdracht.dienst.ID),
      ) as number[];
      if (trsDienstIDs.length > 1) {
        setIsBezig(false);
        throw new Error('Verschillende transportdiensten geselecteerd');
      }
      const [trsDienstID] = trsDienstIDs;

      const dienstenResult = await api.v2.dienst.transport.ophalenDiensten({
        filterSchema: {
          filters: [
            {
              naam: 'IDS',
              data: [trsDienstID],
            },
          ],
        },
      });
      const [dienst] = dienstenResult.diensten;

      const afdelingen = (
        await api.v2.relatie.afdeling.ophalenAfdelingen({
          filterSchema: { filters: [{ naam: 'REL_IDS', data: [dienst.relatie!.RelID] }] },
        })
      ).afdelingen;
      const afdeling = afdelingen.find((x) => x.Naam === 'Planning') ?? null; // TODO beter maken, via NaamEnum of een instelling op de dienst

      const contextdatasResult = await api.v2.sjabloon.ophalenContextdatas({
        contextBepaalData: [
          {
            naamEnum: 'TRANSPORTDIENST',
            data: {
              trsDienstID,
            },
          },
          {
            naamEnum: 'TRANSPORTOPDRACHTEN',
            data: {
              trsOpdIDs: params.opdrachten!.map((opdracht) => opdracht.TrsOpdID),
            },
          },
        ],
      });

      const resolveResult = await api.v2.sjabloon.resolve({
        communicatieKanaalSelectie: {
          naamEnum: 'EMAIL',
        },
        sjabloonSelectie: {
          naamEnum: 'TRANSPORT.TPB',
        },
        contexten: contextdatasResult.contexten,
      });

      setCommunicatieOverlayState!((curr) => ({
        ...curr,
        geselecteerdTabblad: ECommunicatieTabblad.Email,
        emailContext: {
          ...curr.emailContext,
          formulier: {
            ...defaultOpstellenFormulierFormikValues,

            taalID: resolveResult.taalID,
            subject: resolveResult.onderwerp ?? '',
            recipients:
              dienst.EmailOpdracht === null
                ? []
                : [
                    {
                      emailAdres:
                        afdeling !== null ? afdeling.Email! : dienst.relatie!.organisatie!.Email!,
                      orgID: dienst.relatie?.organisatie?.OrgID ?? null,
                      persID: null,
                    },
                  ],
            body: resolveResult.inhoud ?? '',
            contextRelIDs: params.contextRelIDs,
          },
        },
      }));
      setIsBezig(false);
    };
  }, [params.opdrachten, setIsBezig]);

  return {
    versturenTPB,
    isBezig,
  };
};

export default useTPBVersturen;
