import { useState } from 'react';

const useNotifyChanges = () => {
  const [_, setState] = useState<null | undefined>(null);

  return () => {
    setState((x) => (x === undefined ? null : undefined));
  };
};

export default useNotifyChanges;
