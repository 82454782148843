import React, { useCallback, useContext, useState, useMemo, useEffect } from 'react';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalHeader from 'react-bootstrap/ModalHeader';
import IDialoogProps from '../../../../core/IDialoogProps';
import { observer } from 'mobx-react-lite';
import VinkVeld from '../../../../components/formulier/VinkVeld';
import { RootStoreContext } from '../../../../stores/RootStore';
import { EResultType } from '../../../../stores/CheckStore';
import api from '../../../../api';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { Field, FieldProps, Formik, FormikActions, FormikProps, FormikErrors } from 'formik';
import { IOphalenContractenResultElementV2 } from '../../../../../../shared/src/api/v2/contract';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import nameOf from '../../../../core/nameOf';
import BedragInput from '../../../../components/formulier/BedragInput';
import { Modal } from 'react-bootstrap';
import MultiCombobox, { IKolom } from '../../../../components/formulier/MultiCombobox';
import {
  IOphalenVorderingenResultElement,
  IWijzigenVorderingParams,
} from '../../../../../../shared/src/api/v2/vordering';
import { format } from 'date-fns';
import Combobox, { IOptie } from '../../../../components/formulier/Combobox';
import DatumKiezer from '../../../../components/formulier/DatumKiezer';
import FormikVeldFout from '../../../../components/formulier/FormikVeldFout';
import { ERegelstatusVordering } from '../../../../bedrijfslogica/enums';
import VeldWeergave from '../../../../components/formulier/VeldWeergave';

interface IDialoogResult {}

interface IProps extends IDialoogProps<IDialoogResult> {
  factRegID: number;
}

interface IFormikValues {
  bedrag: number;
  credit: boolean;
  omschrijving: string;
  cntID: number | null;
  regelstatus: number;
  periodeVan: Date | null;
  periodeTot: Date | null;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  bedrag: 'Bedrag',
  credit: 'Credit-vordering',
  omschrijving: 'Omschrijving',
  cntID: 'Contract',
  regelstatus: 'Statustype',
  periodeVan: 'Van',
  periodeTot: 'Tot',
};

const WijzigenVorderingDialoog: React.FC<IProps> = observer((props) => {
  const { dialoogIndex, onAnnuleren, onSuccess, open } = props;
  const { checkStore } = useContext(RootStoreContext);
  const [vordering, setVordering] = useState<IOphalenVorderingenResultElement | null>(null);

  const [regelStatussen, setRegelStatussen] = useState<IOptie<number>[] | null>(null);
  const [bedrag, setBedrag] = useState<number | null>(null);
  const [contracten, setContracten] = useState<IOphalenContractenResultElementV2[] | null>(null);

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (vordering === null) {
      return null;
    }

    return {
      bedrag: Math.abs(vordering.Bedrag),
      credit: vordering.Bedrag <= 0 ? true : false,
      omschrijving: vordering.Omschrijving,
      cntID: vordering.contract !== null ? vordering.contract.CntID : null,
      regelstatus: vordering.regelstatus.Status,
      periodeVan: vordering.periodeVan !== null ? new Date(vordering.periodeVan) : null,
      periodeTot: vordering.periodeTot !== null ? new Date(vordering.periodeTot) : null,
    };
  }, [vordering]);

  const ophalenVordering = useCallback(async () => {
    const vorderingResult = await api.v2.vordering.ophalenVorderingen({
      filterSchema: { filters: [{ naam: 'IDS', data: [props.factRegID] }] },
    });

    setVordering(vorderingResult.vorderingen[0]);
  }, [props.factRegID]);

  useEffect(() => {
    ophalenVordering();
  }, [ophalenVordering]);

  const ophalenRegelStatussen = useCallback(async () => {
    const { statussen: statussenResult } = await api.v2.vordering.ophalenRegelStatussen({});

    const statussen = statussenResult.map((x) => {
      return { id: x.Status, label: x.Naam };
    });

    setRegelStatussen(statussen);
  }, []);

  useEffect(() => {
    ophalenRegelStatussen();
  }, []);

  const ophalenContracten = useCallback(async () => {
    if (vordering === null) {
      return;
    }

    const contracten = (
      await api.v2.contract.ophalenContractenV2({
        filterSchema: { filters: [{ naam: 'REL_IDS', data: [vordering.RelID] }] },
      })
    ).contracten;

    setContracten(contracten);
  }, [vordering]);

  useEffect(() => {
    ophalenContracten();
  }, [ophalenContracten]);

  const handleWijzigen = useCallback(
    async (values: IFormikValues) => {
      // const bedrag = values.credit ? -Math.abs(values.bedrag) : values.bedrag;

      const params: IWijzigenVorderingParams = {
        credit: values.credit,
        factRegID: props.factRegID,
        bedrag: values.bedrag,
        omschrijving: values.omschrijving,
        cntID: values.cntID,
        regelstatus: values.regelstatus,
        periodeVan: values.periodeVan !== null ? new Date(values.periodeVan) : null,
        periodeTot: values.periodeTot !== null ? new Date(values.periodeTot) : null,
      };

      const checkData = await api.v2.vordering.checkWijzigenVordering(params);

      const controleResult = await checkStore.controleren({
        checkData,
      });
      if (controleResult.type === EResultType.Annuleren) {
        return;
      }

      await api.v2.vordering.wijzigenVordering(params);

      onSuccess({});
    },
    [props.factRegID],
  );

  const contractwisselKolommen = useMemo<IKolom<IOphalenContractenResultElementV2>[]>(
    () => [
      {
        key: '__Contractnummer' as any,
        label: 'Cnt.nr',
        breedte: 75,
        formatFabriek: (rij) => rij.basis.Basisnummer + '.' + rij.Volgnummer,
      },
      {
        key: '__Ingangsdatum' as any,
        label: 'Ing.datum',
        breedte: 85,
        formatFabriek: (rij) => {
          const datum = format(new Date(rij.Ingangsdatum), 'dd-MM-yyyy');
          return datum;
        },
      },
      {
        key: '__Einddatum' as any,
        label: 'Einddatum',
        breedte: 85,
        formatFabriek: (rij) => {
          const datum =
            rij.Einddatum !== null ? format(new Date(rij.Einddatum!), 'dd-MM-yyyy') : '';
          return datum;
        },
      },
      {
        key: '__modelcode' as any,
        label: 'Model',
        breedte: 100,
        formatFabriek: (rij) => rij.basis.productmodel.Modelcode,
      },
      {
        key: '__productsoortnaamKort' as any,
        label: 'Soort',
        breedte: 60,
        formatFabriek: (rij) => rij.basis.productmodel.ProductsoortnaamKort,
      },
      {
        key: '__Locatie' as any,
        label: 'Locatie',
        breedte: 250,
        formatFabriek: (rij) => {
          return (
            rij.basis.locatie.Straatnaam +
            ' ' +
            rij.basis.locatie.Huisnummer +
            (rij.basis.locatie.Bisnummer !== null ? ` ${rij.basis.locatie.Bisnummer}` : '')
          );
        },
      },
    ],
    [],
  );

  const handleValidate = useCallback((values: IFormikValues) => {
    const errors: FormikErrors<IFormikValues> = {};

    if (values.bedrag < 0) {
      errors.bedrag = `Ongeldig bedrag`;
    }

    if (values.omschrijving === ``) {
      errors.omschrijving = `Verplicht`;
    }

    if (values.periodeTot !== null && values.periodeVan === null) {
      errors.periodeVan = `Verplicht`;
    }

    // if (values.regelstatus === ERegelstatusVordering.WOP && values.cntID === null) {
    //   errors.cntID = `Bij een WOP-vordering moet een contract opgegeven zijn`;
    // }

    return errors;
  }, []);

  return (
    <Modal show={open}>
      {/* <Dialoog index={dialoogIndex || 0}> */}
      <ModalHeader>
        <ModalTitle>Wijzigen vordering</ModalTitle>
      </ModalHeader>

      {initialValues === null ||
      contracten === null ||
      regelStatussen === null ||
      vordering === null ? (
        <div className="flex-fill d-flex align-items-center justify-content-center p-3">
          <LoadingSpinner />
        </div>
      ) : (
        <Formik<IFormikValues>
          // isInitialValid
          enableReinitialize
          onSubmit={handleWijzigen}
          validate={handleValidate}
          initialValues={initialValues}
          render={(FormikProps) => {
            const { submitForm, isSubmitting, errors } = FormikProps;
            return (
              <>
                <ModalBody>
                  <div className="row">
                    <div className="d-flex justify-content-between col-12 mt-3 pl-0">
                      <div className="col-3 align-items-left">
                        <label>{veldnamen.bedrag}</label>
                        <Field
                          name={nameOf<IFormikValues>('bedrag')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <>
                                <BedragInput
                                  value={Math.abs(field.value) || 0}
                                  onChange={(x) => {
                                    form.setFieldValue(field.name, x);
                                    setBedrag(x);
                                    if (x <= 0) {
                                      form.setFieldValue(nameOf<IFormikValues>('credit'), false);
                                    }
                                  }}
                                  min={0}
                                  // max={+1000}
                                />
                                <FormikVeldFout fieldProps={fieldProps} />
                              </>
                            );
                          }}
                        />
                      </div>
                      <div className="col-3" style={{ paddingRight: 0 }}>
                        <label>{veldnamen.credit}</label>
                        <Field
                          name={nameOf<IFormikValues>('credit')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <VinkVeld
                                onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                aangevinkt={field.value}
                              />
                            );
                          }}
                        />
                      </div>
                      <div className="col-4">
                        <label>{veldnamen.regelstatus}</label>
                        <Field
                          name={nameOf<IFormikValues>('regelstatus')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            return (
                              <Combobox
                                geselecteerd={field.value}
                                onSelectieChange={(x) => form.setFieldValue(field.name, x)}
                                opties={regelStatussen!}
                              />
                            );
                          }}
                        />
                      </div>
                    </div>

                    <div className="w-100 d-flex pt-3">
                      <div className="col-4">
                        <label>{veldnamen.periodeVan}</label>
                        <Field
                          name={nameOf<IFormikValues>('periodeVan')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <>
                                <DatumKiezer
                                  isClearable
                                  onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                  waarde={field.value !== null ? field.value : null}
                                  determineValidDate={(datum) => {
                                    return true;
                                  }}
                                  determineNextValidDate="ONBEGRENST"
                                  determinePreviousValidDate="ONBEGRENST"
                                />
                                <FormikVeldFout fieldProps={fieldProps} />
                              </>
                            );
                          }}
                        />
                      </div>
                      <div className="col-3">
                        <label>{veldnamen.periodeTot}</label>
                        <Field
                          name={nameOf<IFormikValues>('periodeTot')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <DatumKiezer
                                isClearable
                                onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                waarde={field.value !== null ? field.value : null}
                                determineValidDate={(datum) => {
                                  return true;
                                }}
                                determineNextValidDate="ONBEGRENST"
                                determinePreviousValidDate="ONBEGRENST"
                              />
                            );
                          }}
                        />
                      </div>
                    </div>

                    <div className="col-12 mt-3">
                      <label>{veldnamen.cntID}</label>
                      <Field
                        name={nameOf<IFormikValues>('cntID')}
                        render={(fieldProps: FieldProps<IFormikValues>) => {
                          const { field, form } = fieldProps;
                          return (
                            <>
                              <MultiCombobox<number, IOphalenContractenResultElementV2>
                                sleutelExtractor={(row) => row.CntID}
                                representatieFabriek={(row) =>
                                  row.basis.Basisnummer +
                                  '.' +
                                  row.Volgnummer +
                                  ' - ' +
                                  row.basis.productmodel.Modelcode
                                }
                                waarde={field.value}
                                onWaardeChange={(x) => form.setFieldValue(field.name, x)}
                                opties={contracten}
                                kolommen={contractwisselKolommen}
                                isWisbaar
                                options={{
                                  geenWaardeBericht: 'Geen contract',
                                }}
                              />
                              <FormikVeldFout fieldProps={fieldProps} />
                            </>
                          );
                        }}
                      />
                    </div>

                    <div className="col-12 mt-4">
                      <label>{veldnamen.omschrijving}</label>
                      <Field
                        name={nameOf<IFormikValues>('omschrijving')}
                        render={(fieldProps: FieldProps<IFormikValues>) => {
                          const { field, form } = fieldProps;

                          return (
                            <>
                              <textarea {...field} className="form-control" rows={2} />
                              <FormikVeldFout fieldProps={fieldProps} />
                            </>
                          );
                        }}
                      />
                    </div>

                    <div className="mt-2 pl-3 pr-3 pt-2">
                      <VeldWeergave>
                        Vastgelegd op{' '}
                        {vordering.RecordToegevoegd !== null
                          ? format(new Date(vordering.RecordToegevoegd), 'dd-MM-yyyy HH:mm')
                          : ''}
                        {' - '}Gewijzigd op{' '}
                        {vordering.RecordGewijzigd !== null
                          ? format(new Date(vordering.RecordGewijzigd), 'dd-MM-yyyy HH:mm')
                          : ''}
                      </VeldWeergave>
                    </div>
                  </div>
                </ModalBody>
                <ModalFooter className="d-flex flex-row justify-content-start">
                  <button className="btn btn-primary" onClick={submitForm} style={{ width: 100 }}>
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={onAnnuleren}
                    style={{ width: 100 }}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
      {/* </Dialoog> */}
    </Modal>
  );
});

export default WijzigenVorderingDialoog;
