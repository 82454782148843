import React, { useCallback, useState } from 'react';
import IDialoogProps from '../../../../core/IDialoogProps';
import Dialoog from '../../../../components/dialogen/Dialoog';
import ModalFooter from 'react-bootstrap/ModalFooter';
import ModalBody from 'react-bootstrap/ModalBody';
import BestandDragAndDropZone, {
  IBestandDragAndZoneBestand,
  EBestandDragAndDropZoneSelectieModus,
  IUploadVoortgangVanLokaleBestanden,
} from '../../../../components/BestandDragAndDropZone';
import api from '../../../../api';
import { IVoorbereidenImporterenEntiteitenResult } from '../../../../../../shared/src/api/v2/ImportExport';

interface IProps extends IDialoogProps {}

const ImportEntiteitDialoog = (props: IProps) => {
  const [bestanden, setBestanden] = useState<IBestandDragAndZoneBestand[]>([]);
  const [uploaden, setUploaden] = useState(false);
  const [uploadProgresses, setUploadProgresses] = useState<IUploadVoortgangVanLokaleBestanden>({});

  const handleOkClick = useCallback(async () => {
    setUploaden(true);

    let bestandsNaam: string;
    let bestandToken: string;
    const bestand = bestanden[0];
    if (bestand.lokaal !== undefined) {
      const lokaalBestand = bestand.lokaal!;
      bestandsNaam = lokaalBestand.naam;
      const externBestand = await api.v2.bestand.upload(
        {
          naam: lokaalBestand.naam,
          mediaType: lokaalBestand.mediaType,
          url: lokaalBestand.url,
          file: lokaalBestand.file,
          grootte: lokaalBestand.grootte,
        },
        (percentage) => {
          setUploadProgresses({
            ...uploadProgresses,
            [lokaalBestand.url]: percentage,
          });
        },
      );
      bestandToken = externBestand.Token;
    } else {
      const aspDriveBestand = bestand.aspDrive!;
      bestandsNaam = aspDriveBestand.Naam;
      bestandToken = aspDriveBestand.Token;
    }
    const [datestring, signatuurHash, ...naamEnumParts] = bestandsNaam.split('_').reverse();

    let voorbereidenResult: IVoorbereidenImporterenEntiteitenResult;
    try {
      voorbereidenResult = await api.v2.importExport.voorbereidenImporterenEntiteiten({
        entiteiten: [
          {
            naamEnum: naamEnumParts.reverse().join('_'),
            signatuurHash,
            bestToken: bestandToken,
          },
        ],
      });
    } catch (err) {
      alert('Importeren voorbereiden mislukt, zie de Logs');
      setUploaden(false);
      return;
    }

    const resultaat = voorbereidenResult.resultaten[0];
    const importerenResults = await api.v2.importExport.importerenEntiteiten({
      impVrbIDs: [resultaat.ImpVrbID],
    });

    props.onSuccess(null);
    setUploaden(false);
  }, [props.onSuccess, setUploaden, bestanden, setUploadProgresses, uploadProgresses]);

  return (
    <Dialoog index={props.dialoogIndex || 0}>
      <ModalBody>
        <BestandDragAndDropZone
          selectieModus={EBestandDragAndDropZoneSelectieModus.Enkel}
          onBestandenChange={(value) => {
            setBestanden(value);
          }}
          bestanden={bestanden}
          uploadVoortgangen={uploadProgresses}
        />
      </ModalBody>
      <ModalFooter className="d-flex flex-row justify-content-start">
        <button
          className="btn btn-primary"
          onClick={handleOkClick}
          style={{ width: 100 }}
          disabled={uploaden || bestanden.length === 0}
        >
          Ok
        </button>
        <button
          className="btn btn-secondary"
          onClick={props.onAnnuleren}
          style={{ width: 100 }}
          disabled={uploaden}
        >
          Annuleren
        </button>
      </ModalFooter>
    </Dialoog>
  );
};

export default ImportEntiteitDialoog;
