import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import IDialoogProps from '../../../../core/IDialoogProps';
import Dialoog from '../../../../components/dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import * as Yup from 'yup';
import { observer } from 'mobx-react-lite';
import nameOf from '../../../../core/nameOf';
import api from '../../../../api';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import { IOphalenTekstenInAlleTalenResult } from '../../../../../../shared/src/api/v2/tekst';
import IRemoteData, { createPendingRemoteData } from '../../../../models/IRemoteData';
import { IThemaItem } from '../../../../../../shared/src/api/v2/website/basis';
import VinkVeld from '../../../../components/formulier/VinkVeld';

interface IDialoogResult {}

interface IProps extends IDialoogProps<IDialoogResult> {
  id: number | null;
}

interface IFormikValues {
  naamEnum: string;
  naam: string;
  omschrijving: string;
  isActief: boolean;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  naamEnum: 'naamEnum',
  naam: 'naam',
  omschrijving: 'omschrijving',
  isActief: 'actief',
};

const WijzigenDialoog: React.FC<IProps> = observer((props) => {
  const { dialoogIndex, onAnnuleren } = props;
  const [item, setItem] = useState<IThemaItem | undefined>(undefined);

  const ophalenthemaItem = useCallback(async () => {
    const results = await api.v2.website.ophalenThemaItems();
    setItem(results.find((r) => r.ID == props.id));
  }, [props.id]);

  useEffect(() => {
    ophalenthemaItem();
  }, [ophalenthemaItem]);

  const [teksten, setTeksten] = useState<IRemoteData<IOphalenTekstenInAlleTalenResult>>(
    createPendingRemoteData(),
  );

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      const params: IThemaItem = {
        ID: item?.ID,
        naamEnum: values.naamEnum,
        naam: values.naam,
        omschrijving: values.omschrijving,
        isActief: values.isActief,
      };

      await api.v2.website.toevoegenOfWijzigenThemaItem(params);

      actions.setSubmitting(false);
      props.onSuccess({});
    },
    [item],
  );

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (item == null) {
      return {
        naamEnum: '',
        naam: '',
        omschrijving: '',
        isActief: false,
      };
    }

    return {
      naamEnum: item.naamEnum,
      naam: item.naam,
      omschrijving: item.omschrijving,
      isActief: item.isActief,
    };
  }, [item, teksten]);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        naamEnum: Yup.string().required(),
      }),
    [],
  );

  return (
    <Dialoog index={dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>{props.id === null ? 'Nieuw thema' : 'Wijzigen thema'}</ModalTitle>
      </ModalHeader>
      {initialValues === null ? (
        <LoadingSpinner />
      ) : (
        <Formik<IFormikValues>
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize
          isInitialValid
          render={(formikProps: FormikProps<IFormikValues>) => {
            const { submitForm, isValid, isSubmitting } = formikProps;

            return (
              <>
                <ModalBody>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-12">
                        <label>Naam</label>
                        <Field
                          name={nameOf<IFormikValues>('naam')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field } = fieldProps;

                            return (
                              <>
                                <input type="text" className="form-control" {...field} />
                              </>
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <label>Omschrijving</label>
                        <Field
                          name={nameOf<IFormikValues>('omschrijving')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field } = fieldProps;

                            return (
                              <>
                                <textarea {...field} className="form-control" rows={3} />
                                {/* <input type="text" className="form-control" {...field} /> */}
                              </>
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <label>NaamEnum</label>
                        <Field
                          name={nameOf<IFormikValues>('naamEnum')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field } = fieldProps;

                            return (
                              <>
                                <input type="text" className="form-control" {...field} />
                              </>
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <label>Actief</label>
                        <Field
                          name={nameOf<IFormikValues>('isActief')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field } = fieldProps;

                            return (
                              <>
                                <VinkVeld
                                  aangevinkt={field.value}
                                  onGewijzigd={(actief) =>
                                    fieldProps.form.setFieldValue(
                                      nameOf<IFormikValues>('isActief'),
                                      actief,
                                    )
                                  }
                                />
                              </>
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </ModalBody>

                <ModalFooter className="d-flex flex-row justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    style={{ width: 100 }}
                    disabled={!isValid || isSubmitting}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={onAnnuleren}
                    style={{ width: 100 }}
                    disabled={isSubmitting}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Dialoog>
  );
});

export default WijzigenDialoog;
