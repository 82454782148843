import React, { useMemo } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { genereerUrlStateQueryParam } from '../../../core/useUrlState';
import { IUrlState } from '../../Communicatie/Contactverzoek';
import { IActieProps } from '../index';

interface IData {
  id: number;
}

const TRANSPORT_BEZOEKTIJDEN_OPGEHAALD: React.VoidFunctionComponent<IActieProps> = (
  props: IActieProps,
) => {
  const data = useMemo(() => JSON.parse(props.controltaak.Data!) as IData, [
    props.controltaak.Data,
  ]);

  return (
    <a
      href="#"
      onClick={() => {
        // const state = genereerUrlStateQueryParam<IUrlState>({
        //   selectie: [],
        //   uitgeklapt: [data.id],
        //   scrollenNaarID: data.id,
        // });
        props.history.push(`/transport/opdrachten/uitstaand`);
      }}
    >
      Transportopdrachten Uitstaand
    </a>
  );
};

export default TRANSPORT_BEZOEKTIJDEN_OPGEHAALD;
