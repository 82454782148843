import React from 'react';
import { Field, FieldProps, FormikProps } from 'formik';
import AdresFormulier, { IValues } from '../../../AdresFormulier';
import HorizontaleScheidingslijn from '../../../../layout/HorizontaleScheidingslijn';
import { Kleur } from '../../../../../bedrijfslogica/constanten';
import { ELocatieMutatieDialoogType, IFormikValues } from '../index';

export interface IAdresTabbladProps {
  formikProps: FormikProps<IFormikValues>;
  onAdresGewijzigd: (formikProps: FormikProps<IFormikValues>, x: IValues | null) => Promise<void>;
  type: ELocatieMutatieDialoogType;
  bestaandeLocatieBijCorrectie: boolean;
}

const AdresTabblad = (props: IAdresTabbladProps) => {
  const { formikProps, onAdresGewijzigd, type, bestaandeLocatieBijCorrectie } = props;

  return (
    <div className="form-group pl-3 pr-3 pt-2 pb-2">
      <div className="row">
        <div className="col-12">
          <Field
            name="adres"
            render={(fieldProps: FieldProps<IFormikValues>) => {
              const { field, form } = fieldProps;

              return (
                <AdresFormulier
                  values={field.value}
                  onChange={async (x) => {
                    form.setFieldValue(field.name, x);
                    form.setTouched({
                      ...form.touched,
                      [field.name]: true,
                    });

                    await onAdresGewijzigd(formikProps, x);
                  }}
                />
              );
            }}
          />
        </div>

        {type === ELocatieMutatieDialoogType.Corrigeren && (
          <>
            <div className="col-12 mt-3">
              <HorizontaleScheidingslijn />
            </div>

            {bestaandeLocatieBijCorrectie && (
              <div className="col-12 mt-3 d-flex flex-column">
                <span style={{ fontSize: '0.85rem', color: Kleur.Rood }}>
                  Deze locatie kan niet worden gecorrigeerd omdat er al een locatie bestaat met deze
                  exacte adres gegevens.
                  <br />
                  Probeer het onderliggende entiteit te verplaatsen naar deze locatie i.p.v. een
                  correctie uit te voeren.
                </span>
              </div>
            )}

            {formikProps.touched.adres && (
              <div className="col-12 mt-3 d-flex flex-column">
                <span className="font-weight-bold text-danger" style={{ fontSize: '1.05rem' }}>
                  Let op:
                </span>
                <span className="text-danger" style={{ fontSize: '0.95rem' }}>
                  Het corrigeren van een locatie moet alleen gebruikt worden om de gegevens van een
                  bestaande locatie bij te werken.{' '}
                  <b>De wijzigingen worden op elk dezelfde locatie toegepast.</b>
                </span>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default AdresTabblad;
