import {
  IOphalenInfoIndicatiesParams,
  IOphalenInfoIndicatiesResult,
} from '../../../../../shared/src/api/v2/infoIndicatie';
import api from '../../index';

const infoIndicatie = {
  ophalenInfoIndicaties: async (
    params: IOphalenInfoIndicatiesParams,
  ): Promise<IOphalenInfoIndicatiesResult> => {
    return await api.post('v2/info-indicatie/ophalen-info-indicaties', params);
  },
};

export default infoIndicatie;
