import React, { useContext, useEffect, useMemo, useState, useCallback } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import api from '../../../api';
import { IOphalenLeveropdrachtenResultElement } from '../../../../../shared/src/api/v2/opvolging/leveropdracht';
import { IOphalenBeltakenResultElement } from '../../../../../shared/src/api/v2/beltaak/beltaak';
import {
  ASPKolom,
  EAspKolomBreedteType,
  ESortering,
  IAspKolomSorteringItem,
} from '../../../components/tabel/ASPTabel/types';
import { format } from 'date-fns';
import LoadingSpinner from '../../../components/Gedeeld/LoadingSpinner';
import ASPTabel from '../../../components/tabel/ASPTabel';
import MenuLayout from '../../../components/MenuLayout';
import FilterBalkV2, {
  IFilter,
  IFilterData,
  maakFilterSchema,
} from '../../../components/FilterBalkV2';
import useUrlState from '../../../core/useUrlState';
import { IFilterSchema } from '../../../../../shared/src/models/filter';
import RelatieVisualisatie from '../../../components/personalia/RelatieVisualisatie';
import { IconToevoegen, IconVerwijderen } from '../../../components/Icons';
import { Kleur as EKleur } from '../../../bedrijfslogica/constanten';
import { EResultType } from '../../../stores/CheckStore';
import { RootStoreContext } from '../../../stores/RootStore';
import WijzigenDialoog from './WijzigenDialoog';
import TransportopdrachtregelVisualisatie from '../../../components/entiteitVisualisaties/TransportopdrachtregelVisualisatie';
import {
  IOphalenOpdrachtregelsResultElement,
  IOphalenOpdrachtregelsResultElementV2,
} from '../../../../../shared/src/api/v2/transport/opdracht';
import _ from 'lodash';

interface IProps extends RouteComponentProps {}

enum ETabelKolom {
  Registratiedatum,
  IsAfgehandeld,
  NietOpvolgen,
  Notities,
  Relatie,
  RecordToegevoegd,
  Transportopdrachtregels,
  Bezoekdatums,
}

enum EFilter {
  IsAfgehandeld = 'IS_AFGEHANDELD',
  NietOpvolgen = 'NIET_OPVOLGEN',
}

interface IWijzigenDialoogState {
  id: number;
}

interface IUrlState {
  selectie: number[];
  filterDatas: IFilterData<EFilter>[];
  wijzigDialoogState: IWijzigenDialoogState | null;
  sortering: IAspKolomSorteringItem<ETabelKolom>[];
}

const defaultUrlState: IUrlState = {
  selectie: [],
  filterDatas: [
    {
      naam: EFilter.IsAfgehandeld,
      isActief: true,
      data: false,
    },
    {
      naam: EFilter.NietOpvolgen,
      isActief: true,
      data: false,
    },
  ],
  wijzigDialoogState: null,
  sortering: [
    {
      key: ETabelKolom.Registratiedatum,
      sortering: ESortering.Ascending,
    },
  ],
};

const Leveringen: React.FC<IProps> = (props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const { checkStore } = useContext(RootStoreContext);

  const [filterSchema, setFilterSchema] = useState<IFilterSchema>(
    maakFilterSchema(urlState.filterDatas),
  );

  const [opvolgingen, setOpvolgingen] = useState<IOphalenLeveropdrachtenResultElement[] | null>(
    null,
  );
  const [beltaken, setBeltaken] = useState<IOphalenBeltakenResultElement[] | null>(null);
  const [transportopdrachtregels, setTransportopdrachtregels] = useState<
    IOphalenOpdrachtregelsResultElementV2[] | null
  >(null);

  const ophalenOpvolgingen = useCallback(async () => {
    const result = await api.v2.opvolging.leveropdracht.ophalenOpvolgingen({
      filterSchema,
      orderSchema: {
        orders: [
          {
            naam: 'ID',
            richting: 'ASC',
          },
        ],
      },
    });

    setOpvolgingen(result.opvolgingen);
  }, [filterSchema]);

  useEffect(() => {
    ophalenOpvolgingen();
  }, [ophalenOpvolgingen]);

  const ophalenBeltaken = useCallback(async () => {
    if (opvolgingen === null) {
      return;
    }

    const result = await api.v2.beltaak.ophalenBeltaken({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: opvolgingen.filter((x) => x.beltaak !== null).map((x) => x.beltaak!.ID),
          },
        ],
      },
      // orderSchema: {
      //   orders: [
      //     {
      //       naam: 'ID',
      //       richting: 'ASC',
      //     },
      //   ],
      // },
    });

    setBeltaken(result.beltaken);
  }, [opvolgingen]);

  useEffect(() => {
    ophalenBeltaken();
  }, [ophalenBeltaken]);

  const keyExtractor = useCallback((rij: IOphalenLeveropdrachtenResultElement) => rij.ID, []);

  const tabelKolommen = useMemo<Array<ASPKolom<ETabelKolom, IOphalenLeveropdrachtenResultElement>>>(
    () => [
      {
        key: ETabelKolom.Registratiedatum,
        label: 'Vastgelegd op',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 145,
        renderer: (rij) => format(new Date(rij.Registratiedatum), 'dd-MM-yyyy HH:mm'),
      },
      {
        key: ETabelKolom.Relatie,
        label: 'Relatie',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 250,
        renderer: (rij) => <RelatieVisualisatie relID={rij.RelID} />,
      },
      {
        key: ETabelKolom.Bezoekdatums,
        label: 'Bezoekdatums',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 200,
        renderer: (rij) => {
          const opdrachten = rij.regels.map((x) => x.opdracht);

          const bezoekdatums: Date[] = _.uniq(
            opdrachten.map((x: any) => (x.Bezoekdatum !== null ? new Date(x.Bezoekdatum) : null)),
          );
          const datums = bezoekdatums
            .map((x) => (x !== null ? format(x, 'dd-MM-yyyy') : 'Onbekend'))
            .join(', ');

          return <span>{datums}</span>;
        },
      },
      {
        key: ETabelKolom.Transportopdrachtregels,
        label: 'Opdrachtregels',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 300,
        renderer: (rij) => {
          return (
            <span className="d-flex">
              {rij.regels.map((x) => {
                return (
                  <span className="mr-3">
                    <TransportopdrachtregelVisualisatie trsRegID={x.TrsRegID} />
                  </span>
                );
              })}
            </span>
          );
        },
      },
      {
        key: ETabelKolom.NietOpvolgen,
        label: 'Opvolgen',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 125,
        renderer: (rij) => (rij.NietOpvolgen ? 'Nee' : 'Ja'),
      },
      {
        key: ETabelKolom.IsAfgehandeld,
        label: 'Afgehandeld',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 125,
        renderer: (rij) => (rij.IsAfgehandeld ? 'Ja' : 'Nee'),
      },
      {
        key: ETabelKolom.Notities,
        label: 'Notities bij opvolging',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 400,
        renderer: (rij) => rij.Notities,
      },
    ],
    [],
  );

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.IsAfgehandeld,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return <span>Alleen niet-afgehandeld</span>;
        },
      },
      {
        naam: EFilter.NietOpvolgen,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return <span>Alleen op te volgen</span>;
        },
      },
    ],
    [],
  );

  const handleMakenVoorstel = useCallback(async () => {
    const params = {};

    // const params = { IDs: urlState.selectie };

    // const checkData = await api.v2.faq.checkVerwijderenFaq(params);
    // if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
    //   return;
    // }

    if (
      (
        await checkStore.bevestigen({
          inhoud: (
            <span>
              Nieuw voorstel maken?
              <br />
              Bestaande opvolgingen blijven bewaard.
            </span>
          ),
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    await api.v2.opvolging.leveropdracht.makenOpvolgingenVoorstel(params);

    await ophalenOpvolgingen();
  }, []);

  const rijen = useMemo(() => {
    if (opvolgingen === null) {
      return null;
    }

    const rijen = opvolgingen.reduce<Record<number, IOphalenLeveropdrachtenResultElement>>(
      (acc, rij, idx) => {
        acc[idx] = rij;
        return acc;
      },
      {},
    );

    return rijen;
  }, [opvolgingen]);

  const handleWijzigenRij = useCallback(async (rij: IOphalenLeveropdrachtenResultElement) => {
    setUrlStateSync('wijzigDialoogState', {
      id: rij.ID,
    });
  }, []);

  // const handleVerwijderenRij = useCallback(
  //   async (rij: IOphalenLeveropdrachtenResultElement) => {
  //     await api.v2.opvolging.leveropdracht.verwijderenOpvolgingen({
  //       ids: [rij.ID],
  //     });

  //     await ophalenOpvolgingen();
  //   },
  //   [ophalenOpvolgingen],
  // );

  const handleVerwijderen = useCallback(
    async (ids: number[]) => {
      await api.v2.opvolging.leveropdracht.verwijderenOpvolgingen({
        ids,
      });

      await ophalenOpvolgingen();
    },
    [ophalenOpvolgingen],
  );

  // const verwijderenRijConfirmatie = useCallback(
  //   async (rij: IOphalenLeveropdrachtenResultElement, verwijderen: () => Promise<void>) => {
  //     const checkData = await api.v2.transport.opdracht.premium.checkVerwijderenPremiums({
  //       ids: [rij.ID],
  //     });
  //     if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
  //       return;
  //     }
  //     if (
  //       (
  //         await checkStore.bevestigen({
  //           inhoud: (
  //             <span>
  //               Wil je de geselecteerde opvolgingen verwijderen?
  //               <br />
  //               Let op: de opvolging zal bij een nieuw voorstel weer voorgesteld worden. Als dit
  //               niet gewenst is dien je de opvolging op 'Niet opvolgen' te zetten.
  //             </span>
  //           ),
  //         })
  //       ).type === EResultType.Annuleren
  //     ) {
  //       return;
  //     }

  //     await verwijderen();
  //   },
  //   [],
  // );

  return (
    <>
      <div className="d-flex flex-fill flex-column">
        <div className="d-flex flex-column flex-fill">
          {rijen === null ? (
            <div className="d-flex flex-fill align-items-center justify-content-center">
              <LoadingSpinner />
            </div>
          ) : (
            <MenuLayout
              menu={
                <>
                  <div className="d-flex">
                    <div className="d-flex align-items-center">
                      <button
                        className="btn btn-sm btn-light d-flex align-items-center"
                        style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                        onClick={() => handleMakenVoorstel()}
                      >
                        <IconToevoegen style={{ width: 16, height: 16, fill: EKleur.Grijs }} />
                        <span className="ml-2">Toevoegen voorstel</span>
                      </button>

                      <button
                        className="btn btn-sm btn-light d-flex align-items-center ml-3"
                        style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                        onClick={async () => {
                          if (
                            (
                              await checkStore.bevestigen({
                                inhoud: (
                                  <span>
                                    Wil je de geselecteerde opvolgingen verwijderen?
                                    <br />
                                    <br />
                                    Let op: de opvolging zal bij een nieuw voorstel weer voorgesteld
                                    worden. Als dit niet gewenst is dien je de opvolging op 'Niet
                                    opvolgen' te zetten.
                                  </span>
                                ),
                              })
                            ).type === EResultType.Annuleren
                          ) {
                            return;
                          }

                          await handleVerwijderen(urlState.selectie);
                          ophalenOpvolgingen();
                        }}
                        disabled={urlState.selectie.length === 0}
                      >
                        <IconVerwijderen style={{ width: 16, height: 16, fill: EKleur.Grijs }} />
                        <span className="ml-2">Verwijderen</span>
                      </button>
                    </div>

                    <div
                      className="flex-fill pl-2"
                      style={{ borderLeft: `1px solid ${EKleur.LichtGrijs}` }}
                    >
                      <FilterBalkV2
                        filters={filters}
                        filterData={urlState.filterDatas}
                        onFilterDataChange={(x) => setUrlStateSync('filterDatas', x)}
                        onFilterSchemaChange={setFilterSchema}
                        style={{ marginLeft: 20 }}
                      />
                    </div>
                  </div>
                </>
              }
              body={
                <div className="d-flex flex-column flex-fill">
                  <ASPTabel
                    keyExtractor={keyExtractor}
                    rijen={rijen}
                    kolommen={tabelKolommen}
                    onWijzigenRij={handleWijzigenRij}
                    // onVerwijderenRij={handleVerwijderenRij}
                    // verwijderenRijConfirmatie={verwijderenRijConfirmatie}
                    // totaalAantalRijen={rijen.length}
                    selectie={urlState.selectie}
                    onSelectieChange={(selectie) => setUrlStateSync('selectie', selectie)}
                    sortering={urlState.sortering}
                    onSorteringChange={(sortering) => setUrlStateSync('sortering', sortering)}
                    lokaalSorteren
                  />
                </div>
              }
            />
          )}
        </div>
      </div>
      {urlState.wijzigDialoogState !== null && (
        <WijzigenDialoog
          open
          id={urlState.wijzigDialoogState.id}
          onSuccess={() => {
            ophalenOpvolgingen();
            setUrlStateSync('wijzigDialoogState', null);
          }}
          onAnnuleren={() => [setUrlStateSync('wijzigDialoogState', null)]}
        />
      )}
    </>
  );
};

export default withRouter(Leveringen);
