import React, { useMemo } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { IActieProps } from '../index';

interface IData {
  relID: number;
}

const AANBIEDEN_CONTRACT: React.VoidFunctionComponent<IActieProps> = (props: IActieProps) => {
  const data = useMemo(() => JSON.parse(props.controltaak.Data!) as IData, [
    props.controltaak.Data,
  ]);

  return (
    <a
      href="#"
      onClick={() => {
        // const state = genereerUrlStateQueryParam<IUrlState>({
        //   selectie: [],
        //   uitgeklapt: [data.id],
        //   scrollenNaarID: data.id,
        // });

        props.history.push(`/klant/${data.relID}/contracten/aangeboden`);
      }}
    >
      Naar aangeboden contracten
    </a>
  );
};

export default AANBIEDEN_CONTRACT;
