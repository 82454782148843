import React, { useContext, useEffect, useMemo } from 'react';
import api from '../../../../api';
import { observer } from 'mobx-react-lite';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Kleur as EKleur } from '../../../../bedrijfslogica/constanten';
import { EResultType } from '../../../../stores/CheckStore';
import { RootStoreContext } from '../../../../stores/RootStore';
import ContractVisualisatie from '../../../entiteitVisualisaties/ContractVisualisatie';

interface IProps extends RouteComponentProps {
  cntID: number | null;
}

const ContractnummerComp: React.FC<IProps> = observer((props) => {
  const { checkStore } = useContext(RootStoreContext);

  if (props.cntID === null) {
    return <span></span>;
  }
  return (
    <div className="d-flex align-items-center">
      <ContractVisualisatie cntID={props.cntID} />
    </div>
  );
});

export default withRouter(ContractnummerComp);
