import React, { useContext, useEffect, useMemo, useState, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import Tabblad, { ITabblad } from '../../../components/layout/Tabblad';
import { matchPath, Redirect, Route, RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router';
import OverzichtOpenstaand from './OverzichtOpenstaand';

interface IProps extends RouteComponentProps {}

export enum ETabblad {
  OverzichtOpenstaand = 1,
}

const urlTabbladMap: Record<ETabblad, string> = {
  [ETabblad.OverzichtOpenstaand]: 'overzicht-openstaand',
};

const Controle: React.FC<IProps> = observer((props) => {
  const tabbladen = useMemo<ITabblad<ETabblad>[]>(
    () => [
      {
        id: ETabblad.OverzichtOpenstaand,
        label: 'Overzicht openstaand Crediteuren',
        content: OverzichtOpenstaand,
      },
    ],
    [],
  );

  const tabblad = useMemo<ETabblad | null>(() => {
    const paths = Object.keys(urlTabbladMap).map((key) => {
      const tabblad = Number(key) as ETabblad;
      const url = urlTabbladMap[tabblad];
      return {
        tabblad,
        path: `${props.match.path}/${url}`,
      };
    });
    for (const pathObj of paths) {
      const match = matchPath(props.location.pathname, pathObj.path);
      if (match !== null) {
        return pathObj.tabblad;
      }
    }

    return null;
  }, [props.location.pathname]);

  return (
    <>
      <Route
        exact
        path={props.match.path}
        component={() => (
          <Redirect to={`${props.match.path}/${urlTabbladMap[ETabblad.OverzichtOpenstaand]}`} />
        )}
      />
      {tabblad === null ? (
        <Redirect to={`${props.match.path}/${urlTabbladMap[ETabblad.OverzichtOpenstaand]}`} />
      ) : (
        <>
          <Tabblad
            geselecteerd={tabblad}
            onSelectieChange={(identifier) =>
              props.history.push(`${props.match.path}/${urlTabbladMap[identifier]}`)
            }
            tabbladen={tabbladen}
          />
        </>
      )}
    </>
  );
});

export default withRouter(Controle);
