import { Formik, FormikActions, FormikProps, Field, FieldProps } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useContext, useRef, useState, useCallback, useEffect, useMemo } from 'react';
import { ModalTitle, ModalBody, ModalFooter } from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/esm/ModalHeader';
import api from '../../../../../../../api';
import LoadingSpinner from '../../../../../../../components/Gedeeld/LoadingSpinner';
import Dialoog from '../../../../../../../components/dialogen/Dialoog';
import IDialoogProps from '../../../../../../../core/IDialoogProps';
import nameOf from '../../../../../../../core/nameOf';
import { EResultType } from '../../../../../../../stores/CheckStore';
import { RootStoreContext } from '../../../../../../../stores/RootStore';
import {
  IToevoegenDienstAfwezigheidParams,
  IWijzigenDienstAfwezigheidParams,
} from '../../../../../../../../../shared/src/api/v2/dienst/service/afwezigheid';
import * as Yup from 'yup';
import DatumKiezer from '../../../../../../../components/formulier/DatumKiezer';
import { dagDatum } from '../../../../../../../helpers/datum';

interface IDialoogResult {}

interface IProps extends IDialoogProps<IDialoogResult> {
  dienstID: number;
}

interface IFormikValues {
  afwezigVan: Date | string;
  afwezigTem: Date | string;
}

const WijzigenDialoog: React.FC<IProps> = observer((props) => {
  const { dialoogIndex, onAnnuleren, onSuccess } = props;
  const { checkStore } = useContext(RootStoreContext);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      console.log('submit');
      actions.setSubmitting(true);

      const params: IToevoegenDienstAfwezigheidParams = {
        ServDienstID: props.dienstID,
        AfwezigVan: values.afwezigVan,
        AfwezigTem: values.afwezigTem,
      };

      const checkData = await api.v2.dienst.service.afwezigheid.checkToevoegenDienstAfwezigheid(
        params,
      );
      const checkResult = await checkStore.controleren({
        checkData,
      });
      if (checkResult.type === EResultType.Annuleren) {
        actions.setSubmitting(false);
        return;
      }

      await api.v2.dienst.service.afwezigheid.toevoegenDienstAfwezigheid(params);

      actions.setSubmitting(false);
      props.onSuccess({});
    },
    [],
  );

  const initialValues = useMemo<IFormikValues | null>(() => {
    return {
      ServDienstID: props.dienstID,
      afwezigVan: dagDatum(new Date()),
      afwezigTem: dagDatum(new Date()),
    };
  }, []);

  return (
    <Dialoog index={dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Toevoegen Afwezigheidsperiode</ModalTitle>
      </ModalHeader>
      {initialValues === null ? (
        <LoadingSpinner />
      ) : (
        <Formik<IFormikValues>
          onSubmit={handleSubmit}
          initialValues={initialValues}
          enableReinitialize
          isInitialValid
          render={(formikProps: FormikProps<IFormikValues>) => {
            const { submitForm, isValid, isSubmitting } = formikProps;

            return (
              <>
                <ModalBody>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-12 mt-3">
                        <label>Afwezig Van</label>
                        <Field
                          name={nameOf<IFormikValues>('afwezigVan')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <DatumKiezer
                                waarde={new Date(field.value)}
                                onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                determineNextValidDate="ONBEGRENST"
                                determinePreviousValidDate="ONBEGRENST"
                              />
                            );
                          }}
                        />
                      </div>
                      <div className="col-12 mt-3">
                        <label>Afwezig T/m</label>
                        <Field
                          name={nameOf<IFormikValues>('afwezigTem')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <DatumKiezer
                                waarde={new Date(field.value)}
                                onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                determineNextValidDate="ONBEGRENST"
                                determinePreviousValidDate="ONBEGRENST"
                              />
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </ModalBody>

                <ModalFooter className="d-flex flex-row justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    style={{ width: 100 }}
                    disabled={isSubmitting}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={onAnnuleren}
                    style={{ width: 100 }}
                    disabled={isSubmitting}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Dialoog>
  );
});

export default WijzigenDialoog;
