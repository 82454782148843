import React, { useMemo } from 'react';
import { IOphalenOrganisatiesResultElement } from '../../../../../../shared/src/api/v2/organisatie/organisatie';
import { IOphalenPersonenResultElementV2 } from '../../../../../../shared/src/api/v2/persoon/persoon';
import { IOphalenRelatiesResultElementV2 } from '../../../../../../shared/src/api/v2/relatie';
import IRemoteData, { ERemoteDataState } from '../../../../models/IRemoteData';
import Avatar from '../../../personalia/Avatar';
import { EIdentiteitType, Identiteit } from '../types';

interface ILoadingAvatarProps {
  grootte: number;
}

const LoadingAvatar = ({ grootte }: ILoadingAvatarProps) => <Avatar size={`${grootte}px`} />;

const AVATAR_KLEUREN: string[] = [
  '#ff7575',
  '#ff9e75',
  '#54a950',
  '#5bc4a8',
  '#65a1d0',
  '#a372d9',
  '#ea77cc',
];

const strHashCode = (str: string) => {
  let hash = 0;
  let i;
  let chr;
  if (str.length === 0) return hash;
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

interface IProps {
  avatarImageSrc: IRemoteData<string | null>;
  identiteit: IRemoteData<Identiteit | null>;
  relatiesCache: Record<number, IRemoteData<IOphalenRelatiesResultElementV2>>;
  personenCache: Record<number, IRemoteData<IOphalenPersonenResultElementV2>>;
  organisatiesCache: Record<number, IRemoteData<IOphalenOrganisatiesResultElement>>;
  avatarGrootte: number;
  avatarFontSize: number;
}

const EmailAvatar = (props: IProps) => {
  const avatarGrootteStr = useMemo(() => `${props.avatarGrootte}px`, [props.avatarGrootte]);
  const avatarFontSizeStr = useMemo(() => `${props.avatarFontSize}px`, [props.avatarFontSize]);

  if (props.avatarImageSrc.state === ERemoteDataState.Pending) {
    return <LoadingAvatar grootte={props.avatarGrootte} />;
  }

  if (props.avatarImageSrc.data !== null) {
    return <Avatar size={avatarGrootteStr} imageSrc={props.avatarImageSrc.data!} />;
  }

  if (props.identiteit.state === ERemoteDataState.Pending) {
    return <LoadingAvatar grootte={props.avatarGrootte} />;
  }

  const hoofdIdentiteit = props.identiteit.data!;
  if (hoofdIdentiteit === null) {
    return <Avatar size={avatarGrootteStr} />;
  }

  const persoonAvatar = (persoon: IOphalenPersonenResultElementV2, hashCode: number) => {
    const initialen = (
      (persoon.Voornaam?.charAt(0) ?? '') + persoon.Achternaam.charAt(0)
    ).toUpperCase();

    const kleur = AVATAR_KLEUREN[hashCode % AVATAR_KLEUREN.length];

    return (
      <Avatar size={avatarGrootteStr} fontSize={avatarFontSizeStr} backgroundColor={kleur}>
        {initialen}
      </Avatar>
    );
  };

  const organisatieAvatar = (organisatie: IOphalenOrganisatiesResultElement, hashCode: number) => {
    const eersteLetter = organisatie.Naam[0].toUpperCase();

    const kleur = AVATAR_KLEUREN[hashCode % AVATAR_KLEUREN.length];

    return (
      <Avatar size={avatarGrootteStr} fontSize={avatarFontSizeStr} backgroundColor={kleur}>
        {eersteLetter}
      </Avatar>
    );
  };

  switch (hoofdIdentiteit.type) {
    case EIdentiteitType.Relatie: {
      const relatie = props.relatiesCache[hoofdIdentiteit.relID];
      if (relatie === undefined || relatie.state === ERemoteDataState.Pending) {
        return <LoadingAvatar grootte={props.avatarGrootte} />;
      }
      const hashCode = strHashCode(`R_${relatie.data!.RelID}`);
      if (relatie.data!.Relatiesoort === 'P') {
        return persoonAvatar(relatie.data!.persoon!, hashCode);
      } else {
        return organisatieAvatar(relatie.data!.organisatie!, hashCode);
      }
    }
    case EIdentiteitType.Persoon: {
      const persoon = props.personenCache[hoofdIdentiteit.persID];
      if (persoon === undefined || persoon.state === ERemoteDataState.Pending) {
        return <LoadingAvatar grootte={props.avatarGrootte} />;
      }
      const hashCode = strHashCode(`P_${persoon.data!.PersID}`);
      return persoonAvatar(persoon.data!, hashCode);
    }
    case EIdentiteitType.Organisatie: {
      const organisatie = props.organisatiesCache[hoofdIdentiteit.orgID];
      if (organisatie === undefined || organisatie.state === ERemoteDataState.Pending) {
        return <LoadingAvatar grootte={props.avatarGrootte} />;
      }
      const hashCode = strHashCode(`O_${organisatie.data!.OrgID}`);
      return organisatieAvatar(organisatie.data!, hashCode);
    }
    case EIdentiteitType.EmailGeadresseerde: {
      if (hoofdIdentiteit.emailAdres === null || hoofdIdentiteit.emailAdres === undefined) {
        return <Avatar size={avatarGrootteStr} />;
      }
      const [_, domein] = hoofdIdentiteit.emailAdres.split('@');
      if (domein === undefined) {
        return <Avatar size={avatarGrootteStr} />;
      }
      const eersteLetter = domein[0].toUpperCase();

      const hashCode = strHashCode(hoofdIdentiteit.emailAdres);
      const kleur = AVATAR_KLEUREN[hashCode % AVATAR_KLEUREN.length];

      return (
        <Avatar size={avatarGrootteStr} fontSize={avatarFontSizeStr} backgroundColor={kleur}>
          {eersteLetter}
        </Avatar>
      );
    }
  }
};

export default EmailAvatar;
