import React, { HTMLProps, useCallback, useEffect, useRef, useState } from 'react';

interface IProps extends React.HTMLProps<HTMLTextAreaElement> {
  inputRef?: React.RefObject<HTMLTextAreaElement>;
  maxResizeHeight?: number;
  onExposeRef: (ref: React.RefObject<HTMLTextAreaElement>) => void;
  onResize?: (height: number) => void;
  minHeight?: number;
}

const delayedResizeEventNames = ['cut', 'paste', 'drop', 'keydown'];

const AutoResizeTextarea: React.FC<IProps> = (props) => {
  const ref = useRef<HTMLTextAreaElement>(null);
  const [height, setHeight] = useState<number | string>('auto');

  useEffect(() => {
    props.onExposeRef!(ref);
  }, []);

  const resize = useCallback(() => {
    if (ref.current === null) {
      return;
    }
    const textarea = ref.current!;

    setHeight('auto');
    const newHeight = Math.min(textarea.scrollHeight, props.maxResizeHeight || 9999999);
    setHeight(newHeight);
    if (props.onResize !== undefined) {
      props.onResize(newHeight);
    }
    // textarea.style.height = String(textarea.scrollHeight);
    // textarea.style.height = 'auto';
  }, [ref.current, props.onResize]);

  const delayedResize = useCallback(() => setTimeout(resize, 0), [resize]);

  useEffect(() => {
    if (ref.current === null) {
      return;
    }

    const textarea = ref.current!;
    textarea.addEventListener('focus', resize);
    textarea.addEventListener('change', resize);
    delayedResizeEventNames.forEach((x) => textarea.addEventListener(x, delayedResize));
    return () => {
      textarea.removeEventListener('focus', resize);
      textarea.removeEventListener('change', resize);
      delayedResizeEventNames.forEach((x) => textarea.removeEventListener(x, delayedResize));
    };
  }, [ref.current, delayedResize]);

  useEffect(() => {
    delayedResize();
  }, [props.value, delayedResize]);

  return (
    <textarea
      ref={ref}
      {...props}
      rows={1}
      style={{
        ...props.style,
        height,
        minHeight: props.minHeight,
      }}
      className="form-control"
    />
  );
};

export default AutoResizeTextarea;
