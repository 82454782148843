import React, { useCallback, useMemo } from 'react';
import { ESoort, IRow } from '../../index';
import {
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../../helpers/dxTableGrid';
import {
  Grid,
  Table,
  TableColumnResizing,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-bootstrap4';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import RelatieVisualisatie from '../../../../personalia/RelatieVisualisatie';

import {
  IRegelProduct,
  IRegelContract,
  IRegelVerkoop,
} from '../../../../../../../shared/src/api/v2/winkelwagen';
import ContractVisualisatie from '../../../../entiteitVisualisaties/ContractVisualisatie';

interface IProps {
  row: IRow;
}

const Contractbeeindiging: React.FC<IProps> = (props) => {
  const contractbeeindigingregels = (props.row.regels as IRegelContract[]).filter(
    (x) => x.Soort === ESoort.Contractbeeindiging,
  );

  const keyExtractor = useCallback((row: IRegelContract) => row.ID, []);
  const kolommen = useMemo<TypedColumn<IRegelContract>[]>(
    () => [
      {
        name: '__contractnummer' as any,
        title: 'Cnt.nr',
      },
      {
        name: '__productmodelnaam' as any,
        title: 'Productmodel',
      },
      {
        name: '__abonnement' as any,
        title: 'Abon.',
      },
      {
        name: '__relatie',
        title: 'Relatie',
      },
    ],
    [],
  );
  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRegelContract>[]>(
    () => [
      {
        columnName: '__contractnummer' as any,
        width: 100,
      },
      {
        columnName: '__productmodelnaam' as any,
        width: 200,
      },
      {
        columnName: '__abonnement' as any,
        width: 110,
      },
      {
        columnName: '__relatie' as any,
        width: 300,
      },
    ],
    [],
  );

  return (
    <GridStyleWrapper rowAmount={contractbeeindigingregels.length} maxHeight={500}>
      <Grid rows={contractbeeindigingregels} columns={kolommen} getRowId={keyExtractor}>
        <DataTypeProvider
          for={['__contractnummer']}
          formatterComponent={(formatterProps) => {
            const regel: IRegelContract = formatterProps.row;

            if (regel.contract === null) {
              return <span></span>;
            }
            return <ContractVisualisatie cntID={regel.contract.CntID} />;
          }}
        />

        <DataTypeProvider
          for={['__productmodelnaam']}
          formatterComponent={(formatterProps) => {
            const regel: IRegelContract = formatterProps.row;

            return <span>{regel.contract.basis.productmodel.Modelnaam}</span>;
          }}
        />

        <DataTypeProvider
          for={['__abonnement']}
          formatterComponent={(formatterProps) => {
            const regel: IRegelContract = formatterProps.row;

            return <span>{regel.contract.basis.abonnement.Naam}</span>;
          }}
        />

        <DataTypeProvider
          for={['__relatie']}
          formatterComponent={(formatterProps) => {
            const regel: IRegelContract = formatterProps.row;
            return <RelatieVisualisatie relID={regel.contract.RelID} />;
          }}
        />

        <Table
          messages={{
            noData: 'Geen contractbeeindigingen',
          }}
        />
        <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
        <TableHeaderRow />
      </Grid>
    </GridStyleWrapper>
  );
};

export default Contractbeeindiging;
