import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IOphalenRelatieDuplicaatSuggestiesResult } from '../../../../../../shared/src/api/v2/relatie/duplicaat';
import api from '../../../../api';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import SuggestieItem from './SuggestieItem';
import useUrlState from '../../../../core/useUrlState';
import { RouteComponentProps, withRouter } from 'react-router';
import { Helmet } from 'react-helmet';
import MenuLayout from '../../../../components/MenuLayout';

export interface IUrlState {
  uitgeklapt: number[];
}

interface IProps extends RouteComponentProps {}

const RelatieDuplicaten: React.FC<IProps> = (props) => {
  const defaultUrlState = useMemo<IUrlState>(
    () => ({
      uitgeklapt: [],
    }),
    [],
  );
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);

  const [
    suggestiesResult,
    setSuggestiesResult,
  ] = useState<IOphalenRelatieDuplicaatSuggestiesResult | null>(null);

  const ophalenSuggesties = useCallback(async () => {
    const result = await api.v2.relatie.duplicaat.ophalenRelatieDuplicaatSuggesties({});
    setSuggestiesResult(result);
  }, []);
  useEffect(() => {
    ophalenSuggesties();
  }, [ophalenSuggesties]);

  const handleVerversenAangevraagd = useCallback(async () => {
    await ophalenSuggesties();
  }, [ophalenSuggesties]);

  return (
    <MenuLayout
      body={
        suggestiesResult === null ? (
          <div className="d-flex flex-fill align-items-center justify-content-center">
            <LoadingSpinner />
          </div>
        ) : (
          <div
            className="d-flex flex-fill flex-column"
            style={{ overflow: 'auto', maxHeight: '100vh' }}
          >
            {suggestiesResult.suggesties.map((suggestie) => {
              const uitgeklapt = urlState.uitgeklapt.indexOf(suggestie.RelDupSugID) !== -1;
              return (
                <div key={suggestie.RelDupSugID}>
                  <div className="p-3 pl-4 pr-4">
                    <SuggestieItem
                      suggestie={suggestie}
                      uitgeklapt={uitgeklapt}
                      onUitgeklaptChange={(x) => {
                        if (x) {
                          setUrlStateSync('uitgeklapt', [
                            ...urlState.uitgeklapt,
                            suggestie.RelDupSugID,
                          ]);
                        } else {
                          setUrlStateSync(
                            'uitgeklapt',
                            urlState.uitgeklapt.filter((x) => x !== suggestie.RelDupSugID),
                          );
                        }
                      }}
                      onVerversenAangevraagd={handleVerversenAangevraagd}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        )
      }
    />
  );
};

export default withRouter(RelatieDuplicaten);
