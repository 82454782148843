import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import IDialoogProps from '../../../core/IDialoogProps';
import Dialoog from '../../dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { Formik, FormikActions, FormikProps } from 'formik';
import * as Yup from 'yup';
import { ITijdvak } from '../../formulier/TijdvakVeld';
import api from '../../../api';
import {
  IOphalenIndicatiesPremiumResultElement,
  IWijzigenOpdrachtregelParams,
} from '../../../../../shared/src/api/v2/transport/opdracht';
import { IOphalenBezoekdagenResult } from '../../../../../shared/src/api/v2/transport/bezoek';
import LoadingSpinner from '../../Gedeeld/LoadingSpinner';
import Tabblad, { ITabblad } from '../../layout/Tabblad';
import DetailTab from './DetailTab';
import OpdrachtTab from './OpdrachtTab';
import BezoektijdTab from './BezoektijdTab';
import RegelTab from './RegelTab';
import { RootStoreContext } from '../../../stores/RootStore';
import { EResultType } from '../../../stores/CheckStore';
import {
  IOphalenOpdrachtenResultElementV2,
  IOphalenOpdrachtregelsResultElementV2,
  IWijzigenOpdrachtParams,
} from '../../../../../shared/src/api/v2/transport/opdracht';
import nameof from '../../../core/nameOf';
import { IOphalenLocatieNiveausResultElement } from '../../../../../shared/src/api/v2/locatie/locatie';
import { ETransportopdrachtRegelsoort } from '../../../bedrijfslogica/enums';
import { Kleur as EKleur } from '../../../bedrijfslogica/constanten';
import { EOpdrachtStatus } from '../../../paginas/Transport/Opdrachten';
import { format } from 'date-fns';
import { tijdStringNaarDatum } from '../../../bedrijfslogica/datums';
import { StringIterator } from 'lodash';
import { dagDatum } from '../../../helpers/datum';
import { IOphalenMagazijnenResultElement } from '../../../../../shared/src/api/v2/magazijn';

export interface IDialoogResult {}

interface IProps extends IDialoogProps<IDialoogResult> {
  trsOpdID: number;
  trsRegID?: number;
  contextRelID?: number;
}

export interface IFormikValues {
  bezoekDatum: Date | null;
  persID: number | null;
  tijdvak: ITijdvak | null;
  email: string;
  indicatieAAA: boolean;
  telefoon: string | null;
  locID: number | null;
  ophouden: boolean;
  infoPlanning: string;
  bezoekInstructies: string;
  verdiepingOpdracht: number | null;
  bezoektijdenNietAutomatischBijwerken: boolean;
  bezoektijdenActueel: boolean;
  bezoektijdenNietVersturen: boolean;
  aantalAfvoer: number;
  voorkeurTijdvak: ITijdvak | null;
  verdieping: number | null;
  aansluitenTrekschakelaar: boolean | null;
  aansluitenPerilex: boolean | null;
  product: { prodID: number | null; typeID: number | null } | null;
  notities: string | null;
  terugzettenNaarPlanning: boolean;
  instructies: string | null;
  documentatieNietVersturen: boolean;
  indicatiePremium: number;
  nietWeergevenBijPortaal: boolean;
  alleenToepasselijkeBezoekdatums: boolean;
  gedwongenRetour: boolean;
  telefonischVoorberichtBezoek: boolean;
  facturatieBeoordeeldOp: Date | string | null;
  magID: number;
}

export enum ETabblad {
  Opdracht = 1,
  Detail = 2,
  Bezoektijd = 3,
  Regel = 4,
}

export const veldnamen: Record<keyof IFormikValues, string> = {
  bezoekDatum: 'Bezoekdatum',
  persID: 'Contactpersoon',
  telefoon: 'Telefoon',
  locID: 'Locatie',
  email: 'Email',
  indicatieAAA: 'Indicatie AAA',
  tijdvak: 'Tijdvak',
  ophouden: 'Opdracht ophouden',
  bezoekInstructies: 'Bezoekinstructies',
  infoPlanning: 'Info planning',
  verdiepingOpdracht:
    'Verdieping (Indien leeg dan wordt de hoogste verdieping bij de regels aangehouden)',
  verdieping: 'Verdieping',
  bezoektijdenNietAutomatischBijwerken: 'Bezoektijden niet automatisch bijwerken',
  bezoektijdenActueel: 'Bezoektijden zijn actueel',
  bezoektijdenNietVersturen: 'Bezoektijden niet automatisch versturen',
  aantalAfvoer: 'Aantal afvoer',
  voorkeurTijdvak: 'Voorkeurstijden',
  aansluitenTrekschakelaar: 'Trekschakelaar',
  aansluitenPerilex: 'Aansluiten Perilex',
  product: 'Product',
  notities: 'Notities (alleen voor intern gebruik)',
  terugzettenNaarPlanning: 'De opdracht na wijzigen terugzetten naar Planning',
  instructies: 'Instructies voor deze regel',
  documentatieNietVersturen: 'Geen documentatie versturen na levering',
  indicatiePremium: 'Indicatie premium',
  nietWeergevenBijPortaal: 'Niet weergeven in Mijn Splash',
  alleenToepasselijkeBezoekdatums: 'Alleen uit toepasselijke bezoekdatums kiezen',
  gedwongenRetour: 'Gedwongen retour',
  telefonischVoorberichtBezoek: 'Telefonisch voorbericht bezoek',
  facturatieBeoordeeldOp: 'Facturatie is beoordeeld op',
  magID: 'Magazijn',
};

export interface IOpdracht extends IOphalenOpdrachtenResultElementV2 {
  regels: IOphalenOpdrachtregelsResultElementV2[];
}

export interface IRegel extends IOphalenOpdrachtregelsResultElementV2 {
  opdracht: IOphalenOpdrachtenResultElementV2;
}

export interface IWijzigenDialoogContext {
  opdracht: IOpdracht | null;
  bezoekdagen: Array<Date | string> | null;
  regel: IRegel | null;
  verdiepingen: IOphalenLocatieNiveausResultElement[] | null;
  formikProps: FormikProps<IFormikValues>;
  vragenTerugNaarPlanning: boolean | null;
  indicatiesPremium: IOphalenIndicatiesPremiumResultElement[] | null;
  magazijnen: IOphalenMagazijnenResultElement[] | null;
}

export const WijzigenDialoogContext = React.createContext<IWijzigenDialoogContext>(null as any);

export const handleTelefoonnummerEnEmailVoorvullen = async (
  persID: number | null,
  form: FormikProps<IFormikValues>,
) => {
  if (persID === null) {
    return;
  }

  // const result = await api.v2.persoon.ophalenPersoon({
  //   persID,
  // });
  const result = await api.v2.persoon.ophalenPersonen({
    filterSchema: { filters: [{ naam: 'IDS', data: [persID] }] },
  });
  const persoon = result.personen[0];

  form.setFieldValue(nameof<IFormikValues>('telefoon'), persoon.TelefoonMobiel || '');
  form.setFieldValue(nameof<IFormikValues>('email'), persoon.Email || '');
};

const WijzigenDialoog: React.FC<IProps> = (props) => {
  const { children, dialoogIndex, onAnnuleren, onSuccess, open, trsOpdID } = props;
  const { checkStore } = useContext(RootStoreContext);

  const [tabblad, setTabblad] = useState<ETabblad>(
    props.trsRegID !== undefined ? ETabblad.Regel : ETabblad.Opdracht,
  );
  const [opdracht, setOpdracht] = useState<IOpdracht | null>(null);
  const [regel, setRegel] = useState<IRegel | null>(null);
  const [bezoekdagen, setBezoekdagen] = useState<IOphalenBezoekdagenResult | null>(null);
  const [verdiepingen, setVerdiepingen] = useState<IOphalenLocatieNiveausResultElement[] | null>(
    null,
  );
  const [indicatiesPremium, setIndicatiesPremium] = useState<
    IOphalenIndicatiesPremiumResultElement[] | null
  >(null);
  const [magazijnen, setMagazijnen] = useState<IOphalenMagazijnenResultElement[] | null>(null);

  // Alle voorraadmagazijnen ophalen
  const ophalenMagazijnen = useCallback(async () => {
    const result = await api.v2.magazijn.ophalenMagazijnen({
      filterSchema: {
        filters: [{ naam: 'IS_VOORRAAD', data: true }],
      },
    });

    setMagazijnen(result.magazijnen);
  }, []);

  useEffect(() => {
    ophalenMagazijnen();
  }, [ophalenMagazijnen]);

  const vragenTerugNaarPlanning = useMemo(() => {
    if (opdracht === null) {
      return null;
    }
    return (
      opdracht !== null &&
      opdracht.opdrachtstatus.Status === EOpdrachtStatus.Uitstaand &&
      opdracht.DatumVerstuurd !== null
    );
  }, [opdracht]);

  const ophalenVerdiepingen = useCallback(async () => {
    const resultaat = await api.v2.locatie.ophalenLocatieNiveaus({
      filterSchema: {},
    });

    setVerdiepingen(resultaat.locatieniveaus);
  }, []);

  useEffect(() => {
    ophalenVerdiepingen();
  }, []);

  const ophalenIndicatiesPremium = useCallback(async () => {
    const result = await api.v2.transport.opdracht.ophalenIndicatiesPremium({});

    setIndicatiesPremium(result.indicaties);
  }, []);

  useEffect(() => {
    ophalenIndicatiesPremium();
  }, [ophalenIndicatiesPremium]);

  useEffect(() => {
    if (props.trsRegID === undefined) {
      return;
    }

    (async () => {
      const regelResult = (
        await api.v2.transport.opdracht.ophalenOpdrachtregelsV2({
          filterSchema: { filters: [{ naam: 'IDS', data: [props.trsRegID] }] },
        })
      ).regels[0];

      const opdracht = (
        await api.v2.transport.opdracht.ophalenOpdrachtenV2({
          filterSchema: { filters: [{ naam: 'IDS', data: [regelResult.TrsOpdID] }] },
        })
      ).opdrachten[0];

      const regel = { ...regelResult, opdracht };

      setRegel(regel);
    })();
  }, [props.trsRegID]);

  const ophalenOpdracht = useCallback(async () => {
    const opdrachtResult = (
      await api.v2.transport.opdracht.ophalenOpdrachtenV2({
        filterSchema: {
          filters: [{ naam: 'IDS', data: [props.trsOpdID] }],
        },
      })
    ).opdrachten[0];

    const regels = (
      await api.v2.transport.opdracht.ophalenOpdrachtregelsV2({
        filterSchema: {
          filters: [{ naam: 'TRSOPD_IDS', data: [opdrachtResult.TrsOpdID] }],
        },
      })
    ).regels;

    const opdracht = { ...opdrachtResult, regels };

    setOpdracht(opdracht);
  }, [props.trsOpdID]);

  useEffect(() => {
    ophalenOpdracht();
  }, []);

  const ophalenBezoekdagen = useCallback(async () => {
    if (opdracht === null) {
      return;
    }

    const locatie = (
      await api.v2.locatie.ophalenLocaties({
        filterSchema: { filters: [{ naam: 'IDS', data: [opdracht.locatie.LocID] }] },
        paginatie: {
          index: 0,
          aantal: 99999,
        },
      })
    ).locaties[0];

    const bezoekdagen = await api.v2.transport.bezoek.ophalenBezoekdagen({
      postcode: locatie.Postcode,
      landID: locatie.LandID,
      trsDienstID: opdracht.dienst.ID,
      bezoekdagenMaximaal: 180,
    });

    setBezoekdagen(bezoekdagen);
  }, [opdracht]);

  useEffect(() => {
    ophalenBezoekdagen();
  }, [opdracht]);

  const tabbladen = useMemo<ITabblad<ETabblad>[]>(
    () =>
      [
        props.trsRegID !== undefined
          ? {
              id: ETabblad.Regel,
              label: 'Regel',
              content: RegelTab,
            }
          : null,
        {
          id: ETabblad.Opdracht,
          label: 'Opdracht',
          content: OpdrachtTab,
        },
        {
          id: ETabblad.Detail,
          label: 'Detail',
          content: DetailTab,
        },
        {
          id: ETabblad.Bezoektijd,
          label: 'Bezoektijden',
          content: BezoektijdTab,
        },
      ].filter((x) => x !== null) as ITabblad<ETabblad>[],
    [props.trsRegID],
  );

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (opdracht === null || (props.trsRegID !== undefined && regel === null)) {
      return null;
    }

    return {
      bezoekDatum: opdracht.Bezoekdatum !== null ? new Date(opdracht.Bezoekdatum) : null,
      tijdvak:
        opdracht.BezoekdatumVan === null && opdracht.BezoekdatumTot === null
          ? null
          : {
              begin:
                opdracht.BezoekdatumVan !== null
                  ? format(new Date(opdracht.BezoekdatumVan), 'HH:mm')
                  : '',
              eind:
                opdracht.BezoekdatumTot !== null
                  ? format(new Date(opdracht.BezoekdatumTot), 'HH:mm')
                  : '',
            },
      persID: opdracht.persoon !== null ? opdracht.persoon.PersID : null,
      ophouden: opdracht.Ophouden,
      indicatieAAA: opdracht.IndicatieAAA,
      email: opdracht.Email !== null ? opdracht.Email : '',
      locID: opdracht.locatie !== null ? opdracht.locatie.LocID : null,
      telefoon: opdracht.Telefoon,
      infoPlanning: opdracht.InfoPlanning !== null ? opdracht.InfoPlanning : '',
      bezoekInstructies: opdracht.Bezoekinstructies !== null ? opdracht.Bezoekinstructies : '',
      verdiepingOpdracht: opdracht.HoogsteVerdieping,
      bezoektijdenNietAutomatischBijwerken: opdracht.BezoektijdenNietAutomatischBijwerken,
      bezoektijdenActueel: opdracht.BezoektijdenActueel,
      bezoektijdenNietVersturen: opdracht.BezoektijdenNietVersturen,
      aantalAfvoer: opdracht.AantalAfvoer,
      voorkeurTijdvak:
        opdracht.VoorkeurBezoektijdVan === null && opdracht.VoorkeurBezoektijdTot === null
          ? null
          : {
              begin: opdracht.VoorkeurBezoektijdVan !== null ? opdracht.VoorkeurBezoektijdVan : '',
              eind: opdracht.VoorkeurBezoektijdTot !== null ? opdracht.VoorkeurBezoektijdTot : '',
            },
      verdieping: regel !== null ? regel.Verdieping : null,
      aansluitenTrekschakelaar: regel !== null ? regel.AansluitenTrekschakelaar : null,
      aansluitenPerilex: regel !== null ? regel.AansluitenPerilex : null,
      documentatieNietVersturen: opdracht.DocumentatieNietVersturen,
      nietWeergevenBijPortaal: opdracht.NietWeergevenBijPortaal,
      product:
        regel !== null
          ? regel.type !== null
            ? { typeID: regel.type.TypeID, prodID: null }
            : regel.product !== null
            ? { typeID: null, prodID: regel.product.ProdID }
            : null
          : null,
      notities: opdracht.Notities !== null ? opdracht.Notities : '',
      terugzettenNaarPlanning: false,
      instructies: regel !== null ? regel.Instructies : null,
      indicatiePremium: opdracht.IndicatiePremium.ID,
      alleenToepasselijkeBezoekdatums: true,
      gedwongenRetour: opdracht.GedwongenRetour,
      telefonischVoorberichtBezoek: opdracht.TelefonischVoorberichtBezoek,
      facturatieBeoordeeldOp:
        opdracht.FacturatieBeoordeeldOp !== null
          ? dagDatum(new Date(opdracht.FacturatieBeoordeeldOp))
          : null,
      magID: opdracht.magazijn.MagID,
    };
  }, [opdracht, regel]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      const {
        bezoekDatum,
        tijdvak,
        voorkeurTijdvak,
        bezoekInstructies,
        email,
        indicatieAAA,
        infoPlanning,
        locID,
        ophouden,
        persID,
        telefoon,
        verdiepingOpdracht,
        bezoektijdenActueel,
        bezoektijdenNietAutomatischBijwerken,
        aantalAfvoer,
        verdieping,
        aansluitenTrekschakelaar,
        documentatieNietVersturen,
        nietWeergevenBijPortaal,
        product,
        notities,
        terugzettenNaarPlanning,
        bezoektijdenNietVersturen,
      } = values;

      // TODO: Wijzigen opdracht en Wijzigen regel zij nu 2 aparte transacties, nog samenvoegen

      if (bezoekDatum === null && tijdvak !== null) {
        if (
          (
            await checkStore.bevestigen({
              inhoud: (
                <div>
                  <p>
                    Het is niet mogelijk om een tijdvak bij bezoektijden aan te geven als er geen
                    bezoekdatum bekend is.
                  </p>
                  <p>
                    Bevestig om alsnog op te slaan, of annuleer om eventueel als nog een bezoekdatum
                    in te voeren.
                  </p>
                </div>
              ),
            })
          ).type === EResultType.Annuleren
        ) {
          return;
        }
      }

      // Opdrachtniveau
      const opdrachtParams: IWijzigenOpdrachtParams = {
        trsOpdID,
        bezoekdatum: bezoekDatum,
        bezoekdatumVan:
          tijdvak === null || bezoekDatum === null || tijdvak.begin === null
            ? null
            : tijdStringNaarDatum(tijdvak.begin, {
                datum: bezoekDatum,
              }),
        bezoekdatumTot:
          tijdvak === null || bezoekDatum === null || tijdvak.eind === null
            ? null
            : tijdStringNaarDatum(tijdvak.eind, {
                datum: bezoekDatum,
              }),
        voorkeurBezoektijdVan: voorkeurTijdvak === null ? null : voorkeurTijdvak.begin,
        voorkeurBezoektijdTot: voorkeurTijdvak === null ? null : voorkeurTijdvak.eind,
        bezoekinstructies: bezoekInstructies !== '' ? bezoekInstructies : null,
        email: email !== '' ? email : null,
        indicatieAAA,
        infoPlanning: infoPlanning !== '' ? infoPlanning : null,
        locID: locID!,
        ophouden,
        persID,
        telefoon: telefoon !== '' ? telefoon : null,
        bezoektijdenActueel,
        hoogsteVerdieping: verdiepingOpdracht,
        bezoektijdenNietAutomatischBijwerken,
        aantalAfvoer,
        indPremID: values.indicatiePremium,
        notities,
        bezoektijdenNietVersturen,
        documentatieNietVersturen,
        nietWeergevenBijPortaal,
        gedwongenRetour: values.gedwongenRetour,
        telefonischVoorberichtBezoek: values.telefonischVoorberichtBezoek,
        contextRelID: props.contextRelID,
        facturatieBeoordeeldOp: values.facturatieBeoordeeldOp,
        magID: values.magID,
      };

      const checkData = await api.v2.transport.opdracht.checkWijzigenOpdracht(opdrachtParams);

      const controlerenResult = await checkStore.controleren({
        checkData,
      });
      if (controlerenResult.type === EResultType.Annuleren) {
        actions.setSubmitting(false);
        return;
      }

      if (
        vragenTerugNaarPlanning &&
        !terugzettenNaarPlanning &&
        (
          await checkStore.bevestigen({
            inhoud: (
              <span>
                LET OP!!!
                <br />
                Deze opdracht is reeds eerder verstuurd.
                <br />
                De transportdienst heeft nog steeds alleen de niet-gewijzigde gegevens. Verstuur
                deze opdracht eventueel opnieuw, anders kan dit tot een ongewenste situaties leiden.
              </span>
            ),
          })
        ).type === EResultType.Annuleren
      ) {
        actions.setSubmitting(false);
        return;
      }

      if (
        (
          await checkStore.bevestigen({
            inhoud: (
              <span>
                Wijzigingen vastleggen?
                {vragenTerugNaarPlanning && !terugzettenNaarPlanning && (
                  <span>
                    <br />
                    <br />
                    LET OP!!! Deze opdracht is reeds eerder verstuurd.
                    {/* <br />
                    De transportdienst heeft nog steeds alleen de niet-gewijzigde gegevens. Verstuur
                    deze opdracht eventueel opnieuw, anders kan dit tot een ongewenste situaties
                    leiden. */}
                  </span>
                )}
              </span>
            ),
          })
        ).type === EResultType.Annuleren
      ) {
        actions.setSubmitting(false);
        return;
      }

      await api.v2.transport.opdracht.wijzigenOpdracht(opdrachtParams);

      // Optioneel naar Planning terugzetten
      if (vragenTerugNaarPlanning && terugzettenNaarPlanning) {
        await api.v2.transport.opdracht.uitstaand.markerenOpdrachtenPlanning({
          trsOpdIDs: [trsOpdID],
        });
      }

      // Regel
      if (props.trsRegID !== undefined) {
        const regelParams: IWijzigenOpdrachtregelParams = {
          trsRegID: props.trsRegID,
          verdieping,
          // aansluitenTrekschakelaar:
          //   regel!.regelsoort.NaamEnum === ETransportopdrachtRegelsoort.Levering
          //     ? values.aansluitenTrekschakelaar
          //     : null,
          aansluitenTrekschakelaar: values.aansluitenTrekschakelaar,
          aansluitenPerilex:
            regel!.regelsoort.NaamEnum === ETransportopdrachtRegelsoort.Levering
              ? values.aansluitenPerilex
              : null,
          instructies: values.instructies,
          product,
        };

        await api.v2.transport.opdracht.wijzigenOpdrachtregel(regelParams);
      }

      onSuccess({});
      actions.setSubmitting(false);
    },
    [opdracht, regel, onSuccess, props.contextRelID],
  );

  const validationSchema = useMemo(() => {
    // const fields: Record<keyof IFormikValues, any> = {

    const fields = {
      // bezoekDatum: Yup.date().required(
      //   teksten.formulier.E_VERPLICHT_VELD({
      //     veldnaam: veldnamen.bezoekDatum,
      //   }),
      // ),
      bezoekDatum: Yup.date().nullable(),
      locID: Yup.number().required(),
      bezoekInstructies: Yup.string(),
      telefoon: Yup.string().nullable(),
      indicatieAAA: Yup.boolean(),
      infoPlanning: Yup.string(),
      email: Yup.string().email(),
      ophouden: Yup.boolean(),
      tijdvak: Yup.object().nullable(),
      persID: Yup.number(),
      verdiepingOpdracht: Yup.number()
        .nullable()
        .notRequired(),
      bezoektijdenActueel: Yup.boolean(),
      bezoektijdenNietAutomatischBijwerken: Yup.boolean(),
      aantalAfvoer: Yup.number(),
      voorkeurTijdvak: Yup.object().nullable(),
      verdieping: Yup.number()
        .nullable()
        .notRequired(),
      // aansluitenTrekschakelaar: Yup.boolean(),
    };
    return Yup.object().shape(fields);
  }, []);

  return (
    <>
      <Dialoog index={dialoogIndex || 0} modalProps={{ size: 'lg' }}>
        <ModalHeader>
          <ModalTitle>
            Wijzigen opdracht{' '}
            {opdracht !== null &&
              opdracht.opdrachtstatus.Status === EOpdrachtStatus.Uitstaand &&
              opdracht.DatumVerstuurd !== null && (
                <span style={{ color: EKleur.Rood }}>
                  &nbsp;&nbsp; Let op: deze opdracht is reeds verstuurd
                </span>
              )}
          </ModalTitle>
        </ModalHeader>

        {initialValues === null ? (
          <div className="flex-fill d-flex align-items-center justify-content-center p-5">
            <LoadingSpinner />
          </div>
        ) : (
          <Formik<IFormikValues>
            onSubmit={handleSubmit}
            initialValues={initialValues}
            // isInitialValid
            validationSchema={validationSchema}
            render={(formikProps: FormikProps<IFormikValues>) => {
              const { submitForm, isSubmitting, isValid, values } = formikProps;

              const context = {
                opdracht,
                bezoekdagen,
                regel,
                verdiepingen,
                formikProps,
                vragenTerugNaarPlanning,
                indicatiesPremium,
                magazijnen,
              };

              return (
                <WijzigenDialoogContext.Provider value={context}>
                  <Tabblad
                    onSelectieChange={(x) => setTabblad(x)}
                    tabbladen={tabbladen}
                    geselecteerd={tabblad}
                  />

                  <ModalFooter className="d-flex flex-row justify-content-start">
                    <button
                      className="btn btn-primary"
                      onClick={submitForm}
                      style={{ width: 100 }}
                      disabled={!isValid || isSubmitting}
                    >
                      Ok
                    </button>
                    <button
                      className="btn btn-secondary"
                      onClick={onAnnuleren}
                      style={{ width: 100 }}
                      disabled={isSubmitting}
                    >
                      Annuleren
                    </button>
                  </ModalFooter>
                </WijzigenDialoogContext.Provider>
              );
            }}
          />
        )}
      </Dialoog>
    </>
  );
};

export default WijzigenDialoog;
