import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import * as Yup from 'yup';
import api from '../../../../api';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import Dialoog from '../../../../components/dialogen/Dialoog';
import IDialoogProps from '../../../../core/IDialoogProps';
import nameOf from '../../../../core/nameOf';
import {
  ISponsorContract,
  ISponsorContractBijlage,
  ISponsorContractGekoppeldEvent,
} from '../../../../../../shared/src/api/v2/sponsoringv2/contract';
import { dagDatum, dagDatumOfNu } from '../../../../helpers/datum';
import SponsorDienstSelectie from '../../../../components/formulier/SponsorDienstSelectie';
import DatumKiezer from '../../../../components/formulier/DatumKiezer';
import VinkVeld from '../../../../components/formulier/VinkVeld';
import BedragInput from '../../../../components/formulier/BedragInput';
import { addDays } from 'date-fns';

interface IDialoogResult {}

interface IProps extends IDialoogProps<IDialoogResult> {
  id: number | null;
  dienstID: number | null | undefined;
}

interface IFormikValues {
  sponDienstID: number | null;
  isDefinitief: boolean;
  bedrag: number;

  onderwerp?: string;
  uwReferentie?: string;

  bedragBtw?: number;
  bedragVerzoek?: number;
  nietKoppelenAanInkoopfactuur: boolean;

  registratiedatum?: Date;
  contractdatum?: Date;

  events?: ISponsorContractGekoppeldEvent[];
  bijlagen?: ISponsorContractBijlage[];
}

const MuterenDialoog: React.FC<IProps> = observer((props) => {
  const { dialoogIndex, onAnnuleren } = props;
  const [item, setItem] = useState<ISponsorContract | undefined>(undefined);

  const ophalenItem = useCallback(async () => {
    const results = await api.v2.sponsoringv2.contract.ophalenContracten({});
    setItem(results.find((r) => r.ID == props.id));
  }, [props.id]);

  useEffect(() => {
    ophalenItem();
  }, [ophalenItem]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      if (values.sponDienstID === null) return;

      let params: ISponsorContract = {
        ID: item?.ID,
        SponDienstID: values.sponDienstID,
        IsDefinitief: values.isDefinitief,
        Bedrag: values.bedrag,
        Onderwerp: values.onderwerp,
        UwReferentie: values.uwReferentie,
        BedragBtw: values.bedragBtw,
        BedragVerzoek: values.bedragVerzoek,
        NietKoppelenAanInkoopfactuur: values.nietKoppelenAanInkoopfactuur,
        Registratiedatum: values.registratiedatum,
        Contractdatum: values.contractdatum,
      };

      await api.v2.sponsoringv2.contract.toevoegenOfWijzigenContract(params);

      actions.setSubmitting(false);
      props.onSuccess({});
    },
    [item],
  );

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (item == null) {
      return {
        sponDienstID: props.dienstID ?? null,
        isDefinitief: false,
        bedrag: 0,
        onderwerp: '',
        uwReferentie: undefined,
        bedragBtw: 0,
        bedragVerzoek: undefined,
        nietKoppelenAanInkoopfactuur: false,
        registratiedatum: dagDatum(new Date()),
        contractdatum: dagDatum(new Date()),
      };
    }
    return {
      sponDienstID: item.SponDienstID,
      isDefinitief: item.IsDefinitief,
      bedrag: item.Bedrag,
      onderwerp: item.Onderwerp,
      uwReferentie: item.UwReferentie,
      bedragBtw: item.BedragBtw,
      bedragVerzoek: item.BedragVerzoek,
      nietKoppelenAanInkoopfactuur: item.NietKoppelenAanInkoopfactuur,
      registratiedatum: dagDatumOfNu(item.Registratiedatum),
      contractdatum: dagDatumOfNu(item.Contractdatum),
    };
  }, [item]);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        sponDienstID: Yup.number()
          .required()
          .moreThan(0),
        onderwerp: Yup.string().required(),
        bedrag: Yup.string().required(),
      }),
    [],
  );

  return (
    <Dialoog index={dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>{props.id === null ? 'Toevoegen' : 'Wijzigen'} contract</ModalTitle>
      </ModalHeader>
      {initialValues === null ? (
        <LoadingSpinner />
      ) : (
        <Formik<IFormikValues>
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize
          isInitialValid
          render={(formikProps: FormikProps<IFormikValues>) => {
            const { submitForm, isValid, isSubmitting } = formikProps;

            return (
              <>
                <ModalBody>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-12 mt-3">
                        <label>Sponsordienst</label>
                        <Field
                          name={nameOf<IFormikValues>('sponDienstID')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <>
                                <SponsorDienstSelectie
                                  id={field.value}
                                  onChange={(value) => form.setFieldValue(field.name, value)}
                                ></SponsorDienstSelectie>
                              </>
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <label>Bedrag</label>
                        <Field
                          name={nameOf<IFormikValues>('bedrag')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            return (
                              <>
                                <BedragInput
                                  value={field.value}
                                  onChange={(x) => {
                                    form.setFieldValue(field.name, x);
                                  }}
                                />
                              </>
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <label>Bedrag BTW</label>
                        <Field
                          name={nameOf<IFormikValues>('bedragBtw')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            return (
                              <>
                                <BedragInput
                                  value={field.value}
                                  onChange={(x) => {
                                    form.setFieldValue(field.name, x);
                                  }}
                                />
                              </>
                            );
                          }}
                        />
                      </div>
                      <div className="col-12 mt-3">
                        <label>Onderwerp</label>
                        <Field
                          name={nameOf<IFormikValues>('onderwerp')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field } = fieldProps;

                            return (
                              <>
                                <input type="text" className="form-control" {...field} />
                              </>
                            );
                          }}
                        />
                      </div>
                      <div className="col-12 mt-3">
                        <label>Contractdatum</label>
                        <Field
                          name={nameOf<IFormikValues>('contractdatum')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            return (
                              <DatumKiezer
                                waarde={field.value}
                                onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                determinePreviousValidDate={(date) => {
                                  return addDays(date, -1);
                                }}
                                determineNextValidDate={(date) => {
                                  return addDays(date, +1);
                                }}
                                isClearable
                              ></DatumKiezer>
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <Field
                          name={nameOf<IFormikValues>('isDefinitief')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            return (
                              <>
                                <div className="d-flex align-items-center">
                                  <VinkVeld
                                    aangevinkt={field.value}
                                    onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                  />
                                  <span className="ml-2">Definitief</span>
                                </div>
                              </>
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </ModalBody>

                <ModalFooter className="d-flex flex-row justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    style={{ width: 100 }}
                    disabled={!isValid || isSubmitting}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={onAnnuleren}
                    style={{ width: 100 }}
                    disabled={isSubmitting}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Dialoog>
  );
});

export default MuterenDialoog;
