import React, { useMemo, useRef, useState, useCallback, useContext } from 'react';

import styled from 'styled-components';
import { IPersoonIdentiteit } from '../../../../types';
import { IconPersoon } from '../../../../../../Icons';
import { Kleur } from '../../../../../../../bedrijfslogica/constanten';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../../../../models/IRemoteData';
import {
  IOphalenPersonenResultElementV2,
  IOphalenRelatiesVanPersoonResult,
} from '../../../../../../../../../shared/src/api/v2/persoon/persoon';
import useBijGewijzigdEffect from '../../../../../../../core/useBijGewijzigdEffect';
import Skeleton from 'react-loading-skeleton';
import { formatteerPersoonNaam, formatteerRelatieNaam } from '../../../../../../../helpers';
import { v4 as uuidv4 } from 'uuid';
import api from '../../../../../../../api';
import { RouterContext } from '../../../../../../../routers/RouterContextProvider';
import Popover from 'react-bootstrap/esm/Popover';
import LoadingSpinner from '../../../../../../Gedeeld/LoadingSpinner';
import { Overlay, OverlayTrigger } from 'react-bootstrap';
import InfoCard from '../../../../../../personalia/PersoonVisualisatie/InfoCard';

const Root = styled.div`
  display: flex;
  align-items: center;
  background-color: #c9f7cb;
  padding: 1px 5px;
  border-radius: 5px;
  height: 20px;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: #a8f7a8;
  }
`;

interface IOverlayState {
  openenInNieuwTabblad: boolean;
}

interface IProps {
  identiteit: IPersoonIdentiteit;
  personenCache: Record<number, IRemoteData<IOphalenPersonenResultElementV2>>;
  onHerberekenenGrootte: () => void;
}

const PersoonIdentiteit = (props: IProps) => {
  const { navigeerKlantkaartNaarRelID } = useContext(RouterContext);
  const [isBezigMetNavigeren, setIsBezigMetNavigeren] = React.useState(false);

  const persoon = useMemo<IRemoteData<IOphalenPersonenResultElementV2>>(() => {
    const entry = props.personenCache[props.identiteit.persID];
    return entry ?? createPendingRemoteData();
  }, [props.identiteit.persID, props.personenCache[props.identiteit.persID]]);

  useBijGewijzigdEffect(() => {
    props.onHerberekenenGrootte();
  }, [persoon]);

  const persoonNaam = useMemo<IRemoteData<string>>(() => {
    if (persoon.state === ERemoteDataState.Pending) {
      return createPendingRemoteData();
    }
    const p = persoon.data!;
    return createReadyRemoteData(
      formatteerPersoonNaam({
        voornaam: p.Voornaam,
        achternaam: p.Achternaam,
        aanhefKort: p.geslacht.AanhefKort,
        voorletters: p.Voorletters,
        voorvoegsel: p.Voorvoegsel,
      }),
    );
  }, [persoon]);

  const ref = useRef<HTMLDivElement>(null);
  const overlayId = useMemo(() => uuidv4(), []);
  const [overlayState, setOverlayState] = useState<IOverlayState | null>(null);
  const [overlayRelaties, setOverlayRelaties] = useState<IOphalenRelatiesVanPersoonResult | null>(
    null,
  );

  const toonOverlay = useCallback(
    async (openenInNieuwTabblad: boolean) => {
      setOverlayState({
        openenInNieuwTabblad,
      });
      const result = await api.v2.persoon.ophalenRelatiesVanPersoon({
        persID: props.identiteit.persID,
      });
      if (result.relaties.length === 1) {
        if (openenInNieuwTabblad) {
          const link = `/klant/${result.relaties[0].RelID}`;
          window.open(link, '_blank');
        } else {
          await navigeerKlantkaartNaarRelID(result.relaties[0].RelID);
        }
        setOverlayState(null);
        return;
      }
      setOverlayRelaties(result);
    },
    [overlayState, setOverlayState, props.identiteit.persID, navigeerKlantkaartNaarRelID],
  );

  const link = useMemo(() => {
    if (overlayRelaties === null) {
      return null;
    }

    return overlayRelaties!.relaties.map((relatie) => (
      <div
        key={relatie.RelID}
        className="list-group-item list-group-item-action"
        style={{ cursor: 'pointer' }}
        onClick={async () => {
          if (overlayState!.openenInNieuwTabblad) {
            const link = `/klant/${relatie.RelID}`;
            window.open(link!, '_blank');
            return;
          }
          setOverlayState(null);
          await navigeerKlantkaartNaarRelID(relatie.RelID);
        }}
      >
        {formatteerRelatieNaam({
          voornaam: relatie.Voornaam,
          achternaam: relatie.Achternaam,
          voorletters: relatie.Voorletters,
          aanhefKort: relatie.AanhefKort,
          voorvoegsel: relatie.Voorvoegsel,
          relatiesoort: relatie.RelatieSoort,
          organisatienaam: relatie.Organisatienaam,
        })}
      </div>
    ));
  }, [overlayRelaties, overlayState, setOverlayState, navigeerKlantkaartNaarRelID]);

  const [showInfoCardOverlay, setShowInfoCardOverlay] = useState(false);
  const showInfoCardOverlayTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const handleMouseEnterInfoCardOverlay = useCallback(() => {
    if (showInfoCardOverlayTimeoutRef.current !== null) {
      clearTimeout(showInfoCardOverlayTimeoutRef.current);
    }
    showInfoCardOverlayTimeoutRef.current = setTimeout(() => setShowInfoCardOverlay(true), 800);
  }, []);
  const handleMouseLeaveInfoCardOverlay = useCallback(() => {
    if (showInfoCardOverlayTimeoutRef.current !== null) {
      clearTimeout(showInfoCardOverlayTimeoutRef.current);
    }
    showInfoCardOverlayTimeoutRef.current = setTimeout(() => setShowInfoCardOverlay(false), 400);
  }, []);
  const renderInfoCardOverlay = useCallback(
    (p: any) => {
      return (
        <div
          id={`relatie-visualisatie-overlay-${props.identiteit.persID}`}
          {...p}
          onMouseEnter={handleMouseEnterInfoCardOverlay}
          onMouseLeave={handleMouseLeaveInfoCardOverlay}
          style={{
            ...p.style,
            zIndex: 99999,
            border: `1px solid ${Kleur.Persoon}`,
            borderRadius: 5,
          }}
        >
          <InfoCard persID={props.identiteit.persID} />
        </div>
      );
    },
    [props.identiteit.persID, handleMouseEnterInfoCardOverlay, handleMouseLeaveInfoCardOverlay],
  );

  return (
    <OverlayTrigger
      trigger="hover"
      placement="auto-start"
      overlay={renderInfoCardOverlay}
      show={showInfoCardOverlay}
      popperConfig={
        {
          modifiers: {
            name: 'offset',
            options: {
              offset: [0, 5],
            },
          },
        } as any
      }
    >
      <>
        <Root
          ref={ref}
          onClick={
            isBezigMetNavigeren
              ? undefined
              : async (ev) => {
                  ev.stopPropagation();
                  setIsBezigMetNavigeren(true);

                  await toonOverlay(false);

                  setIsBezigMetNavigeren(false);
                }
          }
          onMouseEnter={() => {
            handleMouseEnterInfoCardOverlay();
          }}
          onMouseLeave={() => {
            handleMouseLeaveInfoCardOverlay();
          }}
        >
          <IconPersoon style={{ fill: Kleur.DonkerGrijs, width: 13, height: 13, marginRight: 5 }} />
          {persoonNaam.state === ERemoteDataState.Pending ? (
            <Skeleton width={100} />
          ) : (
            <span style={{ color: Kleur.DonkerGrijs, fontSize: 12 }}>{persoonNaam.data!}</span>
          )}
        </Root>
        <Overlay
          target={ref.current!}
          placement="auto"
          rootClose
          show={overlayState !== null}
          rootCloseEvent="click"
          onHide={() => setOverlayState(null)}
        >
          {(p: any) => (
            <Popover id={overlayId} {...p} onClick={(ev) => ev.stopPropagation()}>
              {overlayRelaties === null ? (
                <LoadingSpinner />
              ) : overlayRelaties!.relaties.length === 0 ? (
                <span className="p-2">Er zijn geen relaties gekoppeld aan dit persoon.</span>
              ) : (
                <div>
                  <div className="p-3">
                    <p className="m-0">Er zijn meerdere relaties gekoppeld aan dit persoon.</p>
                    <p className="m-0">Kies een relatie uit de lijst.</p>
                  </div>
                  <div className="list-group list-group-flush">{link}</div>
                </div>
              )}
            </Popover>
          )}
        </Overlay>
      </>
    </OverlayTrigger>
  );
};

export default PersoonIdentiteit;
