import React, { useContext, useMemo } from 'react';
import { IContext, ITabbladProps, TariefactieContext } from '../index';
import LoadingSpinner from '../../../../../Gedeeld/LoadingSpinner';
import VeldWeergave from '../../../../../formulier/VeldWeergave';
import GegevensLayout from '../../../../../layout/GegevensLayout';

interface IProps extends ITabbladProps {}

const ActieTab: React.FC<IProps> = (props) => {
  const { tariefactie } = useContext<IContext>(TariefactieContext);

  if (tariefactie === null) {
    return <LoadingSpinner />;
  }

  return (
    <div className="d-flex flex-column">
      <div className="mt-2  pt-2 mb-4">
        <div className="mt-2 pl-3 pr-3 pt-2 mb-4">
          <VeldWeergave>
            <GegevensLayout
              gegevens={[
                {
                  label: 'Nummer',
                  waarde: tariefactie.Nummer,
                },
                {
                  label: 'Naam',
                  waarde: tariefactie.Naam,
                },
                {
                  label: 'Hoofdactie',
                  waarde: tariefactie.IsHoofdactie ? 'Ja' : 'Nee',
                },
                {
                  label: 'Actief',
                  waarde: tariefactie.Actief ? 'Ja' : 'Nee',
                },
              ]}
            />
          </VeldWeergave>
        </div>
      </div>
    </div>
  );
};

export default ActieTab;
