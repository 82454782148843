import { makeObservable, observable } from 'mobx';
import { RootStore } from './RootStore';
import {
  INotificatieBevestigdMessageData,
  INotificatieNieuwMessageData,
  IOphalenNotificatieGroepenResultElement,
  IOphalenNotificatiesResultElement,
} from '../../../shared/src/api/v2/notificaties/notificaties';
import api from '../api';
import IPaginatiePositie from '../../../shared/src/models/IPaginatiePositie';
import { EGebruikersStatus } from '../bedrijfslogica/constanten';

interface IOverlayState {
  zichtbaar: boolean;
}

export const signaleringTimeoutMs = 5000;

class NotificatieStore {
  public overlayState: IOverlayState = { zichtbaar: false };
  public notificaties: IOphalenNotificatiesResultElement[] | null = null;
  public mijnNotificaties: IOphalenNotificatiesResultElement[] | null = null;
  public notificatieGroepen: IOphalenNotificatieGroepenResultElement[] | null = null;
  // Notificaties die zichzelf vertonen wanneer ze worden toegevoegd
  public signaleringNotificaties: IOphalenNotificatiesResultElement[] = [];

  // tslint:disable-next-line:variable-name
  private _rootStore: RootStore;

  // tslint:disable-next-line:variable-name
  private _notificatiesPaginatiePositie: IPaginatiePositie = {
    aantal: 50,
    index: 0,
  };

  // tslint:disable-next-line:variable-name
  private _mijnNotificatiesPaginatiePositie: IPaginatiePositie = {
    aantal: 50,
    index: 0,
  };

  constructor(rootStore: RootStore) {
    this._rootStore = rootStore;

    makeObservable(this, {
      overlayState: observable,
      notificaties: observable,
      mijnNotificaties: observable,
      notificatieGroepen: observable,
      signaleringNotificaties: observable,
    });
  }

  public setOverlayZichtbaar = (zichtbaar: boolean) =>
    (this.overlayState = { ...this.overlayState, zichtbaar });

  public ophalenNotificaties = async () => {
    const result = await api.v2.notificatie.ophalenNotificaties({
      paginatie: this._notificatiesPaginatiePositie,
    });

    // Positie ophogen
    this._notificatiesPaginatiePositie = {
      ...this._notificatiesPaginatiePositie,
      index: this._notificatiesPaginatiePositie.index + this._notificatiesPaginatiePositie.aantal,
    };
    this.notificaties = result.notificaties;
  };

  public ophalenMijnNotificaties = async () => {
    const result = await api.v2.notificatie.ophalenNotificaties({
      paginatie: this._mijnNotificatiesPaginatiePositie,
      aspGebrID: this._rootStore.gebruikerStore.gebruiker!.AspGebrID,
    });

    // Positie ophogen
    this._mijnNotificatiesPaginatiePositie = {
      ...this._mijnNotificatiesPaginatiePositie,
      index:
        this._mijnNotificatiesPaginatiePositie.index +
        this._mijnNotificatiesPaginatiePositie.aantal,
    };
    this.mijnNotificaties = result.notificaties;
  };

  public ophalenNotificatieGroepen = async () => {
    const result = await api.v2.notificatie.ophalenNotificatieGroepen({});
    this.notificatieGroepen = result.groepen;
  };

  public handleNotificatieNieuw = async (data: INotificatieNieuwMessageData) => {
    if (
      data.notificatie.aspGebrs.findIndex(
        (aspGebr) => aspGebr.AspGebrID === this._rootStore.gebruikerStore.gebruiker!.AspGebrID,
      ) !== -1
    ) {
      this.mijnNotificaties = [data.notificatie, ...(this.mijnNotificaties || [])];
    }
    this.notificaties = [data.notificatie, ...(this.notificaties || [])];

    const status = this._rootStore.gebruikerStore.gebruiker!.Status as EGebruikersStatus;
    if (status !== EGebruikersStatus.NietStoren) {
      this.signaleringNotificaties = [data.notificatie, ...this.signaleringNotificaties];
      setTimeout(() => this.signaleringVerwijderen(data.notificatie.ID), signaleringTimeoutMs);
    }
  };

  public signaleringVerwijderen = (id: number) => {
    this.signaleringNotificaties = this.signaleringNotificaties.filter((notif) => notif.ID !== id);
  };

  public handleNotificatieBevestigd = async (data: INotificatieBevestigdMessageData) => {
    // const aspGebrID = this._rootStore.gebruikerStore.gebruiker!.AspGebrID;
    //
    const map = (notificatie: IOphalenNotificatiesResultElement) => {
      if (notificatie.ID === data.notificatieID) {
        return {
          ...notificatie,
          aspGebrs: [
            ...notificatie.aspGebrs.filter((x) => x.AspGebrID !== data.aspGebrID),
            {
              AspGebrID: data.aspGebrID,
              DatumBevestigd: data.datumBevestigd,
            },
          ],
        };
      }
      return notificatie;
    };

    if (this.notificaties !== null) {
      this.notificaties = this.notificaties.map(map);
    }

    if (this.mijnNotificaties !== null) {
      this.mijnNotificaties = this.mijnNotificaties.map(map);
    }
  };
}

export default NotificatieStore;
