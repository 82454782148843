import {
  IOphalenSjablonenParams,
  IOphalenSjablonenResult,
  IOphalenSjabloonBasissenParams,
  IOphalenSjabloonBasissenResult,
  IOphalenSjabloonBestandenParams,
  IOphalenSjabloonBestandenResult,
  IOphalenSjabloonCategorieenParams,
  IOphalenSjabloonCategorieenResult,
  IOphalenSjabloonInhoudenParams,
  IOphalenSjabloonInhoudenResult,
  IOphalenSjabloonKanalenParams,
  IOphalenSjabloonKanalenResult,
  IToevoegenSjabloonResult,
  IToevoegenSjabloonParams,
  IVerwijderenSjablonenParams,
  IVerwijderenSjablonenResult,
  IWijzigenSjabloonParams,
  IWijzigenSjabloonResult,
  IOphalenSjabloonContextenParams,
  IOphalenSjabloonContextenResult,
  IResolveParams,
  IResolveResult,
  IOphalenContextDatasParams,
  IOphalenContextDatasResult,
  IResolveRawParams,
  IResolveRawResult,
  IOphalenVoorbeeldContextDatasParams,
  IOphalenVoorbeeldContextDatasResult,
  IResolveSubstitutieParams,
  IResolveSubstitutieResult,
  IResolveSjabloonBasisParams,
  IResolveSjabloonBasisResult,
  IRenderSjabloonResult,
  IRenderSjabloonParams,
  IKopierenSjablonenParams,
  IKopierenSjablonenResult,
} from '../../../../shared/src/api/v2/Sjabloon/sjabloon';
import api from '../index';
import ICheckData from '../../../../shared/src/models/ICheckData';

const sjabloon = {
  ophalenSjablonen: (params: IOphalenSjablonenParams): Promise<IOphalenSjablonenResult> =>
    api.post(`v2/sjabloon/ophalen-sjablonen`, params),
  ophalenSjabloonKanalen: (
    params: IOphalenSjabloonKanalenParams,
  ): Promise<IOphalenSjabloonKanalenResult> =>
    api.post(`v2/sjabloon/ophalen-sjabloon-kanalen`, params),
  ophalenSjabloonInhouden: (
    params: IOphalenSjabloonInhoudenParams,
  ): Promise<IOphalenSjabloonInhoudenResult> =>
    api.post(`v2/sjabloon/ophalen-sjabloon-inhouden`, params),
  ophalenSjabloonBestanden: (
    params: IOphalenSjabloonBestandenParams,
  ): Promise<IOphalenSjabloonBestandenResult> =>
    api.post(`v2/sjabloon/ophalen-sjabloon-bestanden`, params),
  ophalenSjabloonBasissen: (
    params: IOphalenSjabloonBasissenParams,
  ): Promise<IOphalenSjabloonBasissenResult> =>
    api.post(`v2/sjabloon/ophalen-sjabloon-basissen`, params),
  ophalenSjabloonContexten: (
    params: IOphalenSjabloonContextenParams,
  ): Promise<IOphalenSjabloonContextenResult> =>
    api.post(`v2/sjabloon/ophalen-sjabloon-contexten`, params),
  ophalenSjabloonCategorieen: (
    params: IOphalenSjabloonCategorieenParams,
  ): Promise<IOphalenSjabloonCategorieenResult> =>
    api.post(`v2/sjabloon/ophalen-sjabloon-categorieen`, params),
  toevoegenSjabloon: (params: IToevoegenSjabloonParams): Promise<IToevoegenSjabloonResult> =>
    api.post(`v2/sjabloon/toevoegen-sjabloon`, params),
  checkVerwijderenSjablonen: (params: IVerwijderenSjablonenParams): Promise<ICheckData> =>
    api.post(`v2/sjabloon/check-verwijderen-sjablonen`, params),
  verwijderenSjablonen: (
    params: IVerwijderenSjablonenParams,
  ): Promise<IVerwijderenSjablonenResult> => api.post(`v2/sjabloon/verwijderen-sjablonen`, params),
  checkWijzigenSjabloon: (params: IWijzigenSjabloonParams): Promise<ICheckData> =>
    api.post(`v2/sjabloon/check-wijzigen-sjabloon`, params),
  wijzigenSjabloon: (params: IWijzigenSjabloonParams): Promise<IWijzigenSjabloonResult> =>
    api.post(`v2/sjabloon/wijzigen-sjabloon`, params),
  resolve: (params: IResolveParams): Promise<IResolveResult> =>
    api.post('v2/sjabloon/resolve', params),
  resolveRaw: (params: IResolveRawParams): Promise<IResolveRawResult> =>
    api.post('v2/sjabloon/resolve-raw', params),
  resolveSubstitutie: (params: IResolveSubstitutieParams): Promise<IResolveSubstitutieResult> =>
    api.post('v2/sjabloon/resolve-substitutie', params),
  resolveSjabloonBasis: (
    params: IResolveSjabloonBasisParams,
  ): Promise<IResolveSjabloonBasisResult> => api.post('v2/sjabloon/resolve-sjabloon-basis', params),
  ophalenContextdatas: (params: IOphalenContextDatasParams): Promise<IOphalenContextDatasResult> =>
    api.post('v2/sjabloon/ophalen-contextdatas', params),
  ophalenVoorbeeldContextdatas: (
    params: IOphalenVoorbeeldContextDatasParams,
  ): Promise<IOphalenVoorbeeldContextDatasResult> =>
    api.post('v2/sjabloon/ophalen-voorbeeld-contextdatas', params),
  renderSjabloon: (params: IRenderSjabloonParams): Promise<IRenderSjabloonResult> => {
    return api.post('v2/sjabloon/render-sjabloon', params);
  },
  checkKopierenSjablonen: (params: IKopierenSjablonenParams): Promise<ICheckData> =>
    api.post(`v2/sjabloon/check-kopieren-sjablonen`, params),
  kopierenSjablonen: (params: IKopierenSjablonenParams): Promise<IKopierenSjablonenResult> =>
    api.post(`v2/sjabloon/kopieren-sjablonen`, params),
};

export default sjabloon;
