import React, { useContext, useMemo } from 'react';
import MultiCombobox, {
  IKolom,
} from '../../../../../../../../../components/formulier/MultiCombobox';
import { IOphalenProductenResultElementV2 } from '../../../../../../../../../../../shared/src/api/v2/product';
import { formatteerAdresV2 } from '../../../../../../../../../helpers';
import { IFormikValues, veldnamen } from '..';
import { Field, FieldProps } from 'formik';
import nameof from '../../../../../../../../../core/nameOf';
import LoadingSpinner from '../../../../../../../../../components/Gedeeld/LoadingSpinner';
import FormikVeldFout from '../../../../../../../../../components/formulier/FormikVeldFout';
import LocatieSelectie from '../../../../../../../../../components/formulier/LocatieSelectie';
import ContactSelectie, {
  EType,
} from '../../../../../../../../../components/formulier/ContactSelectie';
import VinkVeld from '../../../../../../../../../components/formulier/VinkVeld';
import { FormulierContext } from '../Formulier';
import TelefoonnummerInput from '../../../../../../../../../components/formulier/TelefoonnummerInput';

interface IProps {}

const MeldingTab: React.FC<IProps> = (props) => {
  const { relID, producten } = useContext(FormulierContext);

  const productenKolommen = useMemo<IKolom<IOphalenProductenResultElementV2>[]>(
    () => [
      {
        key: '__merknaam' as any,
        label: 'Merk',
        breedte: 125,
        formatFabriek: (x) => x.producttype.Merknaam,
      },
      {
        key: '__typenaam' as any,
        label: 'Type',
        breedte: 150,
        formatFabriek: (x) => x.producttype.Typenaam,
      },
      {
        key: 'Referentiecode',
        label: 'Ref.code',
        breedte: 100,
      },
      {
        key: '__productsoortnaamKort' as any,
        label: 'Soort',
        breedte: 60,
        formatFabriek: (x) => x.producttype.ProductsoortnaamKort,
      },
      {
        key: 'locatie',
        label: 'Adres',
        breedte: 250,
        formatFabriek: (entity) => {
          const loc = entity.locatie;
          if (!loc) {
            return 'Geen locatie';
          }

          return formatteerAdresV2({
            straatnaam: loc.Straatnaam,
            postcode: loc.Postcode,
            plaatsnaam: loc.Plaatsnaam,
            landnaamKort: loc.LandnaamKort,
            landnaamEnum: loc.LandnaamEnum,
            huisnummer: loc.Huisnummer,
            bisnummer: loc.Bisnummer,
          });
        },
      },
    ],
    [],
  );

  return (
    <>
      <div className="p-3">
        <div className="form-group">
          <div className="row mb-3">
            <div className="col-12 mt-2">
              <label>{veldnamen.prodID}</label>
              <Field
                name={nameof<IFormikValues>('prodID')}
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;
                  if (producten === null) {
                    return <LoadingSpinner />;
                  }
                  return (
                    <>
                      <MultiCombobox<number, IOphalenProductenResultElementV2>
                        sleutelExtractor={(x) => x.ProdID}
                        representatieFabriek={(x) =>
                          `${x.producttype.Merknaam} ${x.producttype.Typenaam}${
                            x.Referentiecode !== null ? `, ${x.Referentiecode}` : ''
                          }`
                        }
                        waarde={field.value}
                        onWaardeChange={(x) => form.setFieldValue(field.name, x)}
                        opties={producten}
                        kolommen={productenKolommen}
                        isWisbaar
                      />
                      <FormikVeldFout fieldProps={fieldProps} />
                    </>
                  );
                }}
              />
            </div>

            <div className="col-12 mt-3">
              <label>{veldnamen.locID}</label>
              <Field
                name={nameof<IFormikValues>('locID')}
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;

                  return (
                    <>
                      <LocatieSelectie
                        relIDs={relID !== null ? [relID] : undefined}
                        locID={field.value}
                        onChange={(x) => form.setFieldValue(field.name, x)}
                      />

                      <FormikVeldFout fieldProps={fieldProps} />
                    </>
                  );
                }}
              />
            </div>

            <div className="col-12 mt-2">
              <label>{veldnamen.melder_PersID}</label>
              <Field
                name={nameof<IFormikValues>('melder_PersID')}
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;

                  return (
                    <>
                      <ContactSelectie
                        alleenVoorRelIDs={relID !== null ? [relID] : undefined}
                        value={
                          field.value === null
                            ? null
                            : {
                                persID: field.value,
                                orgID: null,
                              }
                        }
                        onChange={(x) => form.setFieldValue(field.name, x?.persoon?.PersID ?? null)}
                        options={{
                          types: [EType.Persoon],
                          bijEnkeleDirectVoorselecteren: true,
                        }}
                        isWisbaar
                      />
                      <FormikVeldFout fieldProps={fieldProps} />
                    </>
                  );
                }}
              />
            </div>

            <div className="col-12 mt-2">
              <label>{veldnamen.telefoonnummer}</label>
              <Field
                name={nameof<IFormikValues>('telefoonnummer')}
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;

                  return (
                    <TelefoonnummerInput
                      telefoonnummer={field.value}
                      onChange={(x) => form.setFieldValue(field.name, x)}
                    />
                  );

                  // return (
                  //   <>
                  //     <input {...field} className="form-control" type="text" />

                  //     <FormikVeldFout fieldProps={fieldProps} />
                  //   </>
                  // );
                }}
              />
            </div>

            <div className="col-12 mt-2">
              <label>{veldnamen.email}</label>
              <Field
                name={nameof<IFormikValues>('email')}
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;

                  return (
                    <>
                      <input {...field} className="form-control" type="text" />

                      <FormikVeldFout fieldProps={fieldProps} />
                    </>
                  );
                }}
              />
            </div>

            <div className="col-12 mt-3">
              <label>{veldnamen.omschrijving}</label>
              <Field
                name={nameof<IFormikValues>('omschrijving')}
                render={(fieldProps: FieldProps<IFormikValues>) => {
                  const { field, form } = fieldProps;

                  return (
                    <>
                      <textarea {...field} className="form-control" rows={3} />

                      <FormikVeldFout fieldProps={fieldProps} />
                    </>
                  );
                }}
              />
            </div>

            {/* <div className="col-12 mt-3 d-flex align-items-center">
              <Field
                name="opdrachtMaken"
                render={(fieldProps: FieldProps<IFormikValues>) => (
                  <>
                    <VinkVeld
                      aangevinkt={fieldProps.field.value}
                      onGewijzigd={async (x) => {
                        fieldProps.form.setFieldValue(fieldProps.field.name, x);
                      }}
                    />
                    <FormikVeldFout fieldProps={fieldProps} />
                  </>
                )}
              />
              <span className="ml-2">{veldnamen.opdrachtMaken}</span>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default MeldingTab;
