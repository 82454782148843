import React, { useContext, useState } from 'react';
import IRemoteData, { ERemoteDataState } from '../../../models/IRemoteData';
import { RootStoreContext } from '../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import Chip from '../../Chip';
import api from '../../../api';
import { EResultType } from '../../../stores/CheckStore';
import { IconKruis, IconToevoegen } from '../../Icons';
import { Kleur } from '../../../bedrijfslogica/constanten';
import MultiSelect from '../../formulier/MultiSelect';
import UitlegTooltip from '../../formulier/UitlegTooltip';
import { ICommunicatieOverlayRecentTelefoonOproep } from '../../../stores/TelefoonHistorieStore';
import {
  IOphalenTelefoonoproepContextenResult,
  IOphalenTelefoonoproepXContextResultElement,
} from '../../../../../shared/src/api/v2/telefonie';

interface IProps {
  oproep: ICommunicatieOverlayRecentTelefoonOproep;
  contextenResult: IRemoteData<IOphalenTelefoonoproepContextenResult>;
  telefoonoproepXContexten: IRemoteData<{
    [TelOprID: number]: IOphalenTelefoonoproepXContextResultElement[];
  }>;
  verversenTelefoonoproepXContexten: () => Promise<void>;
  verversenContexten: () => Promise<void>;
}

const GesprekscontextVeld = observer((props: IProps) => {
  const { checkStore } = useContext(RootStoreContext);

  const [isAanHetToevoegen, setIsAanHetToevoegen] = useState(false);

  if (
    props.contextenResult.state === ERemoteDataState.Pending ||
    props.telefoonoproepXContexten.state === ERemoteDataState.Pending
  ) {
    return <span>Laden...</span>;
  }
  const oproep = props.oproep;

  const mapping = props.telefoonoproepXContexten.data![oproep.ID] ?? [];
  const contexten =
    mapping.length === 0
      ? []
      : props.contextenResult.data!.contexten.filter(
          (x) => mapping.findIndex((mapping) => mapping.ContextID === x.ID) !== -1,
        );

  return (
    <div className="d-flex align-items-center" style={{ minWidth: 250, minHeight: 23 }}>
      {contexten.map((context, i) => {
        return (
          <>
            <Chip key={context.ID}>
              <span style={{ fontSize: 11 }}>{context.Context}</span>
              <button
                className="ml-1"
                style={{
                  outline: 0,
                  border: 0,
                  background: 0,
                  padding: 0,
                  position: 'relative',
                  top: -1,
                }}
                onClick={async () => {
                  if (
                    (
                      await checkStore.bevestigen({
                        inhoud: 'Confirmatie verwijderen context',
                        asynchroneActieNaBevestigingFn: async () => {
                          const telefoonoproepXContext = mapping.find(
                            (mapping) => mapping.ContextID === context.ID,
                          )!;
                          await api.v2.telefonie.verwijderenTelefoonoproepXContext({
                            ids: [telefoonoproepXContext.ID],
                          });
                          await props.verversenTelefoonoproepXContexten();
                        },
                      })
                    ).type === EResultType.Annuleren
                  ) {
                    return;
                  }
                }}
              >
                <IconKruis style={{ fill: Kleur.Grijs, width: 14, height: 14 }} />
              </button>
            </Chip>
            {i !== contexten.length - 1 && <span>&nbsp;</span>}
          </>
        );
      })}
      {isAanHetToevoegen ? (
        <span className="ml-1">
          <MultiSelect
            value={[]}
            isMulti={false}
            opties={props.contextenResult
              .data!.contexten.filter((x) => contexten.findIndex((c) => c.ID === x.ID) === -1)
              .map((x) => ({
                key: x.ID,
                weergave: x.Context,
              }))}
            onChange={async ([contextID]) => {
              setIsAanHetToevoegen(false);
              await api.v2.telefonie.toevoegenTelefoonoproepXContext({
                oproepID: oproep.ID,
                contextID,
              });
              await props.verversenTelefoonoproepXContexten();
            }}
            onToegevoegd={async (contextNaam) => {
              setIsAanHetToevoegen(false);

              const result = await api.v2.telefonie.toevoegenTelefoonoproepContext({
                context: contextNaam,
              });

              if (contexten.find((x) => x.ID === result.contextID) !== undefined) {
                await checkStore.melden({
                  titel: 'De context die je probeert toe te voegen komt al voor',
                });
                return;
              }

              await api.v2.telefonie.toevoegenTelefoonoproepXContext({
                oproepID: oproep.ID,
                contextID: result.contextID,
              });
              await props.verversenContexten();
              await props.verversenTelefoonoproepXContexten();
            }}
            autoFocus
            onBlur={() => {
              setIsAanHetToevoegen(false);
            }}
          />
        </span>
      ) : (
        <UitlegTooltip inhoud="Context toevoegen">
          <button
            className="ml-1"
            style={{
              outline: 0,
              border: 0,
              background: 0,
              padding: 0,
              position: 'relative',
              top: -1,
            }}
            onClick={() => {
              setIsAanHetToevoegen(true);
            }}
          >
            <IconToevoegen style={{ fill: Kleur.Grijs, width: 16, height: 16 }} />
          </button>
        </UitlegTooltip>
      )}
    </div>
  );
});

export default GesprekscontextVeld;
