import api from '../../../index';
import {
  IOphalenBestandenParams,
  IOphalenBestandenResult,
  IOphalenRelBestsParams,
  IOphalenRelBestsResult,
  IToevoegenBestandenParams,
  IToevoegenBestandenResult,
  IVerwijderenBestandenParams,
  IVerwijderenBestandenResult,
} from '../../../../../../shared/src/api/v2/relatie/bestand';

const bestand = {
  async ophalenBestanden(params: IOphalenBestandenParams): Promise<IOphalenBestandenResult> {
    return await api.post('/v2/relatie/bestand/ophalen-bestanden', params);
  },
  async toevoegenBestanden(params: IToevoegenBestandenParams): Promise<IToevoegenBestandenResult> {
    return await api.post('/v2/relatie/bestand/toevoegen-bestanden', params);
  },
  async verwijderenBestanden(
    params: IVerwijderenBestandenParams,
  ): Promise<IVerwijderenBestandenResult> {
    return await api.post('/v2/relatie/bestand/verwijderen-bestanden', params);
  },
  async ophalenRelBests(params: IOphalenRelBestsParams): Promise<IOphalenRelBestsResult> {
    return await api.post('/v2/relatie/bestand/ophalen-rel-bests', params);
  },
};

export default bestand;
