import { RootStore } from './RootStore';
import api from '../api';
import { IOphalenOrganisatiesResultElement } from '../../../shared/src/api/v2/organisatie/organisatie';
import { makeObservable, observable } from 'mobx';

class OrganisatieStore {
  public organisaties: { [orgID: number]: IOphalenOrganisatiesResultElement } = {};
  public organisatieOphaalQueue: number[] = [];
  // tslint:disable-next-line:variable-name
  private _orgIDQueueTimeout: number | null = null;
  // tslint:disable-next-line:variable-name
  private _rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this._rootStore = rootStore;

    makeObservable(this, {
      organisaties: observable,
      organisatieOphaalQueue: observable,
    });
  }

  public enqueueOrganisatieOphalen = (orgID: number) => {
    this.organisatieOphaalQueue = [...this.organisatieOphaalQueue, orgID];
    if (this._orgIDQueueTimeout !== null) {
      clearTimeout(this._orgIDQueueTimeout);
    }
    // @ts-ignore
    this._orgIDQueueTimeout = setTimeout(this.organisatiesOphalen, 300);
  };

  private organisatiesOphalen = async () => {
    const queue = this.organisatieOphaalQueue;
    const result = await api.v2.organisatie.ophalenOrganisaties({
      filterSchema: { filters: [{ naam: 'IDS', data: queue }] },
    });
    const organisaties = { ...this.organisaties };
    result.organisaties.forEach((organisatie) => {
      organisaties[organisatie.OrgID] = organisatie;
    });
    this.organisaties = organisaties;
    this.organisatieOphaalQueue = [];
  };
}

export default OrganisatieStore;
