import React from 'react';
import { IWeergaveProps } from '../../../../../components/FilterBalkV2';
import Combobox from '../../../../../components/formulier/Combobox';

const StatusFilter = (props: IWeergaveProps<any>) => {
  return (
    <div className="d-flex align-items-center">
      <span className="mr-2">Status</span>
      <Combobox
        geselecteerd={props.data}
        opties={[
          {
            id: 1,
            label: 'Geactiveerd',
          },
          {
            id: 2,
            label: 'Gedeactiveerd',
          },
        ]}
        onSelectieChange={(x) => {
          props.onDataChange(x!);
          props.setIsActief(true);
          props.toepassen();
        }}
      />
    </div>
  );
};

export default StatusFilter;
