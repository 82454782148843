import TableData, { ITableDataProps } from '../../../tabel/ASPTabel/Body/TableData';
import { EVerkennerItemKolom, EVerkennerItemType } from '../types';
import downloadUrl from '../../../../core/downloadUrl';
import * as React from 'react';
import { useContext } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { toonBevestigVerplaatsenVerkennerItemNaarMap } from '../../helpers';
import { RootStoreContext } from '../../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import { EResultType } from '../../../../stores/CheckStore';
import { ItemTypes } from '../../ItemTypes';
import { IMapVerkennerItem, IVerkennerItem } from '../../types';
import { TabelWeergaveContext } from '../index';

const TdComponent = observer((props: ITableDataProps<EVerkennerItemKolom, IVerkennerItem>) => {
  const { checkStore } = useContext(RootStoreContext);
  const { onNavigerenBestandsmap, onItemVerplaatst } = useContext(TabelWeergaveContext);

  const [{ isDropActive, canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: ItemTypes.VerkennerItem,
      collect: (monitor) => ({
        isDropActive: monitor.canDrop() && monitor.isOver(),
        canDrop: monitor.canDrop(),
        isOver: monitor.isOver(),
      }),
      canDrop: (object, monitor) => {
        const toBeDroppedItem = object as IVerkennerItem;
        if (props.row === undefined || props.row.type === EVerkennerItemType.Bestand) {
          return false;
        }
        const mapItem = props.row! as IMapVerkennerItem;

        return !(
          toBeDroppedItem.type === EVerkennerItemType.Map &&
          toBeDroppedItem.map.ID === mapItem.map.ID
        );
      },
      async drop(object, monitor) {
        const monitorId = monitor.getHandlerId()! as string;
        const n = Number(monitorId.split('T')[1]);
        if (n % 2 === 0) {
          // Is oneven, dus tweede keer, lelijke workaround
          return;
        }

        const droppedItem = object as IVerkennerItem;
        const mapItem = props.row! as IMapVerkennerItem;

        if (
          (await toonBevestigVerplaatsenVerkennerItemNaarMap(checkStore, droppedItem, mapItem))
            .type === EResultType.Annuleren
        ) {
          return;
        }

        await onItemVerplaatst(mapItem.map.ID, droppedItem.id);

        // const files = (object as any).files as File[];
        // await onBestandenDrop(files);
      },
    }),
    [props.row, toonBevestigVerplaatsenVerkennerItemNaarMap, onItemVerplaatst],
  );

  const [{ isDragActive }, drag, dragPreview] = useDrag(
    () => ({
      type: ItemTypes.VerkennerItem,
      collect: (monitor) => ({
        isDragActive: monitor.isDragging(),
      }),
      item: props.row!,
    }),
    [props.row],
  );

  return drop(
    drag(
      <div style={{ display: 'flex', width: '100%', height: '100%' }}>
        <TableData
          {...props}
          isGefocust={isDropActive ? true : props.isGefocust}
          onDoubleClick={async (ev) => {
            ev.preventDefault();
            const item = props.row!;

            if (item.type === EVerkennerItemType.Bestand) {
              await downloadUrl(item.bestand.url, item.bestand.Naam);
            } else if (
              item.type === EVerkennerItemType.Map &&
              onNavigerenBestandsmap !== undefined
            ) {
              const bestandsmapID = Number(item.id.split('M-')[1]);
              onNavigerenBestandsmap(bestandsmapID);
            }
          }}
        />
      </div>,
    ),
  );
});

export default TdComponent;
