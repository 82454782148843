import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  defaultFilterData,
  defaultUrlState,
  IOverzichtV2Data,
  IOverzichtV2Store,
  useOverzichtV2Store,
} from './store';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
  mapRemoteData,
} from '../../../../../../../models/IRemoteData';
import LoadingSpinner from '../../../../../../../components/Gedeeld/LoadingSpinner';
import {
  IAbonnement,
  IContract,
  IContractbasisHuur,
  IContractRedenAfkeuring,
  IContractRedenAnnulering,
  IContractRedenBeeindiging,
  IContractRedenOvername,
  IContractStatus,
  IFactuurKenmerk,
  IIndexering,
  ILocatie,
  ILocatieIntern,
  ILocatieNiveau,
  ILocXRelPers,
  IProdModXType,
  IProduct,
  IProductMerk,
  IProductmodel,
  IProductmodelAfbeelding,
  IProductStatus,
  IProductType,
  IProlongatie,
  IRelatieComplex,
  IRelatieLocatie,
  IRelatieRekening,
  IServiceMelding,
  ITariefActie,
  ITransportOpdrachtRegel,
  ITransportRegelSoort,
  IVerkoopVoorstel,
  IContactpersoon,
  IPersoon,
  ITaal,
  IRelatieBetrekking,
} from '../../../../../../../../../shared/src/api/v2/relatie/contract';
import LocatieTabel from './LocatieTabel';
import MenuLayout from '../../../../../../../components/MenuLayout';
import SelectieVak from '../../../../../../../components/SelectieVak';
import VerticaleScheidingslijn from '../../../../../../../components/layout/VerticaleScheidingslijn';
import { Kleur as EKleur } from '../../../../../../../bedrijfslogica/constanten';
import {
  EFunctioneleIcon,
  functioneleIconMap,
  IconCancel,
  IconDuimOmlaag,
  IconLocatie,
  IconSend,
  IconToevoegen,
  IconTransport,
  IconVernieuwen,
  IconVerwijderen,
  IconVink,
  IconVlag,
} from '../../../../../../../components/Icons';
import InactiefOverlay from '../../../../../../../components/InactiefOverlay';
import { EContractStatus } from '../Overzicht/ContractTegel/ContractStatus';
import ActieMenuKnop from '../../../../../../../components/ActieMenuKnop';
import { GlobaleRendererContext } from '../../../../../../../one-off-components/GlobaleRenderer';
import NieuwContractDialoogV2 from '../Overzicht/NieuwContractDialoogV2';
import api from '../../../../../../../api';
import { EResultType } from '../../../../../../../stores/CheckStore';
import { RootStoreContext } from '../../../../../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import { IVersturenBevestigingsverzoekParams } from '../../../../../../../../../shared/src/api/v2/contract/nieuw/nieuw';
import AnnulerenContractDialoog from '../Overzicht/AnnulerenContractDialoog';
import FiatterenNieuwDialoog from '../Overzicht/FiatterenNieuwDialoog';
import FiatterenOvernameDialoog from '../Overzicht/FiatterenOvernameDialoog';
import BeeindigenContractDialoog from '../Overzicht/BeeindigenContractDialoog';
import { EKanaal, EProductstatus } from '../../../../../../../bedrijfslogica/enums';
import NieuweTransportopdrachtDialoog, {
  ESoortOpdracht,
} from '../../../../../../../components/transport/NieuweOpdrachtDialoog';
import AanbiedenOvernameDialoog from '../Overzicht/AanbiedenOvernameDialoog';
import AfkeurenContractDialoog from '../Overzicht/AfkeurenContractDialoog';
import OvernemenContractDialoog from '../Overzicht/OvernemenContractDialoog';
import { IVersturenBevestigingBeeindigingParams } from '../../../../../../../../../shared/src/api/v2/contract/beeindiging';
import LocatieSelecterenOfOpvoerenDialoog, {
  ILocatieSelecterenOfOpvoerenDialoogFormikValues,
} from '../../../../../../../components/locatie/LocatieSelecterenOfOpvoerenDialoog';
import _ from 'lodash';
import VinkVeld from '../../../../../../../components/formulier/VinkVeld';
import UitlegTooltip from '../../../../../../../components/formulier/UitlegTooltip';
import InterneLocatieSelectieDialoog from '../../../../../../../components/dialogen/InterneLocatieSelectieDialoog';
import ActieSelectieDialoog from '../../../../../../../components/Aanbod/Tarieven/Actie/SelectieDialoog';
import ToevoegenStandaardLeveropdrachtenDialoog from '../../../../../../../components/transport/ToevoegenStandaardLeveropdrachtenDialoog';
import FilterBalkV2, { IFilter, IFilterData } from '../../../../../../../components/FilterBalkV2';
import { EFilter } from '../../../../../../../stores/klantkaart/entiteiten/contracten/overzicht/KEContractenOverzichtStore';
import ZoektermFilter from './ZoektermFilter';
import PersoonFilter from './PersoonFilter';
import LocatieFilter from './LocatieFilter';
import StatusFilter from './StatusFilter';
import VerdiepingFilter from './VerdiepingFilter';
import useUrlState from '../../../../../../../core/useUrlState';
import { IVersturenBevestigingAnnuleringParams } from '../../../../../../../../../shared/src/api/v2/contract/annulering';
import InterneLocatieFilter from './InterneLocatieFilter';
import NieuwRunDialoog, {
  IContractenModus,
  IRelatieModus,
} from '../../../../../../../components/prolongatie/RunTabelV2/NieuwRunDialoog';
import FactuurkenmerkSelectieDialoog from '../../../../../../../components/dialogen/FactuurkenmerkSelectieDialoog';
import FactuurkenmerkFilter from './FactuurkenmerkFilter';
import ComplexFilter from './ComplexFilter';

const Root = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const IconContractwissel = functioneleIconMap[EFunctioneleIcon.Contractwissel];
const IconAanbiedenOvername = functioneleIconMap[EFunctioneleIcon.AanbiedenOvername];
const IconContractovername = functioneleIconMap[EFunctioneleIcon.Contractovername];
const IconVerkoopvoorstel = functioneleIconMap[EFunctioneleIcon.Verkoopvoorstel];
const IconInterneLocatie = functioneleIconMap[EFunctioneleIcon.InterneLocatie];
const IconTariefactie = functioneleIconMap[EFunctioneleIcon.Tariefactie];

export interface IOverzichtV2Context {
  relID: number;
  dataBijID: IRemoteData<IDataBijID>;
}

export const OverzichtV2Context = React.createContext<IOverzichtV2Context | null>(null);

export interface IDataBijID {
  contractenBijID: { [id: number]: IContract };
  contractenBijLocID: { [id: number]: IContract[] };
  locatiesBijID: { [id: number]: ILocatie };
  abonnementenBijID: { [id: number]: IAbonnement };
  productmodellenBijID: { [id: number]: IProductmodel };
  contractStatussenBijID: { [id: number]: IContractStatus };
  productenBijID: { [id: number]: IProduct };
  productenBijCntBasisID: { [id: number]: IProduct[] };
  locatiesInternBijID: { [id: number]: ILocatieIntern };
  locatiesInternBijLocID: { [id: number]: ILocatieIntern[] };
  productmodelAfbeeldingenBijProdModID: { [id: number]: IProductmodelAfbeelding[] };
  // prolongatiesBijID: { [id: number]: IProlongatie };
  // prolongatiesBijCntID: { [id: number]: IProlongatie[] };
  productStatiBijID: { [id: number]: IProductStatus };
  productTypesBijID: { [id: number]: IProductType };
  prodModXTypesBijProdModID: { [id: number]: IProdModXType[] };
  prodModXTypesBijTypeID: { [id: number]: IProdModXType[] };
  productMerkenBijID: { [id: number]: IProductMerk };
  transportOpdrachtRegelsBijID: { [id: number]: ITransportOpdrachtRegel };
  transportOpdrachtRegelsBijCntID: { [id: number]: ITransportOpdrachtRegel[] };
  transportRegelSoortenBijID: { [id: number]: ITransportRegelSoort };
  serviceMeldingenBijID: { [id: number]: IServiceMelding };
  serviceMeldingenBijCntID: { [id: number]: IServiceMelding[] };
  verkoopVoorstellenBijID: { [id: number]: IVerkoopVoorstel };
  verkoopVoorstellenBijCntID: { [id: number]: IVerkoopVoorstel[] };
  relatieRekeningenBijID: { [id: number]: IRelatieRekening };
  tariefActiesBijID: { [id: number]: ITariefActie };
  contractRedenAfkeuringenBijID: { [id: number]: IContractRedenAfkeuring };
  contractRedenAnnuleringenBijID: { [id: number]: IContractRedenAnnulering };
  contractRedenBeeindigingenBijID: { [id: number]: IContractRedenBeeindiging };
  contractRedenOvernamesBijID: { [id: number]: IContractRedenOvername };
  factuurKenmerkenBijID: { [id: number]: IFactuurKenmerk };
  relatieComplexenBijID: { [id: number]: IRelatieComplex };
  locatieNiveausBijVerdieping: { [id: number]: ILocatieNiveau };
  contractBijContractwisselCntID: { [id: number]: IContract };
  relatieLocatieBijLocID: { [locID: number]: IRelatieLocatie };
  relatieComplexBijID: { [id: number]: IRelatieComplex };
  contractbasisHurenBijCntBasisID: { [id: number]: IContractbasisHuur[] };
  indexeringenBijID: { [id: number]: IIndexering };
  actueelContractbasisHuurBijCntBasisID: { [id: number]: IContractbasisHuur };
  contactpersonenBijID: { [id: number]: IContactpersoon };
  locXRelPersBijLocID: { [locID: number]: ILocXRelPers[] };
  personenBijID: { [id: number]: IPersoon };
  talenBijID: { [id: number]: ITaal };
  relatieBetrekkingenBijID: { [id: number]: IRelatieBetrekking };
}

type FiatterenType = 'basis' | 'volg';

interface IProps extends RouteComponentProps {
  relID: number;
}

const OverzichtV2 = observer(
  (props: IProps): JSX.Element => {
    const store = useOverzichtV2Store();
    const globaleRenderer = useContext(GlobaleRendererContext);
    const { checkStore, klantkaartStore } = useContext(RootStoreContext);
    const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);

    useEffect(() => {
      const result = store.initialiseer({
        relID: props.relID,
        urlState,
      });
      if (JSON.stringify(result.urlState) !== JSON.stringify(urlState)) {
        setUrlState(result.urlState);
      }
    }, [props.relID, JSON.stringify(urlState)]);

    useEffect(() => {
      const abortController = new AbortController();
      // noinspection JSIgnoredPromiseFromCall
      store.ophalenContractWeergave({
        relID: props.relID,
        abortSignal: abortController.signal,
      });

      return () => {
        abortController.abort();
      };
    }, [props.relID]);

    const dataBijID = useMemo<IRemoteData<IDataBijID>>(
      () =>
        mapRemoteData(store.data, (data: IOverzichtV2Data) => {
          const contractenBijID: {
            [id: number]: IContract;
          } = data.result.contracten.reduce(
            (acc, curr) => ({
              ...acc,
              [curr.CntID]: curr,
            }),
            {},
          );
          const contractenBijLocID = data.result.contracten.reduce<{
            [id: number]: IContract[];
          }>((acc, curr) => {
            if (curr.LocID === null) {
              return acc;
            }

            return {
              ...acc,
              [curr.LocID]: [...(acc[curr.LocID] ?? []), curr],
            };
          }, {});
          const locatiesBijID: {
            [id: number]: ILocatie;
          } = data.result.locaties.reduce(
            (acc, curr) => ({
              ...acc,
              [curr.LocID]: curr,
            }),
            {},
          );
          const abonnementenBijID: {
            [id: number]: IAbonnement;
          } = data.result.abonnementen.reduce(
            (acc, curr) => ({
              ...acc,
              [curr.AbonID]: curr,
            }),
            {},
          );
          const productmodellenBijID: {
            [id: number]: IProductmodel;
          } = data.result.productmodellen.reduce(
            (acc, curr) => ({
              ...acc,
              [curr.ProdModID]: curr,
            }),
            {},
          );
          const contractStatussenBijID = data.result.contractStatussen.reduce<{
            [id: number]: IContractStatus;
          }>(
            (acc, curr) => ({
              ...acc,
              [curr.CntStatID]: curr,
            }),
            {},
          );
          const productenBijID = data.result.producten.reduce<{
            [id: number]: IProduct;
          }>(
            (acc, curr) => ({
              ...acc,
              [curr.ProdID]: curr,
            }),
            {},
          );
          const productenBijCntBasisID = data.result.producten.reduce<{
            [id: number]: IProduct[];
          }>(
            (acc, curr) => ({
              ...acc,
              [curr.CntBasisID]: [...(acc[curr.CntBasisID] ?? []), curr],
            }),
            {},
          );
          const locatiesInternBijID = data.result.locatiesIntern.reduce<{
            [id: number]: ILocatieIntern;
          }>(
            (acc, curr) => ({
              ...acc,
              [curr.ID]: curr,
            }),
            {},
          );
          const locatiesInternBijLocID = data.result.locatiesIntern.reduce<{
            [id: number]: ILocatieIntern[];
          }>((acc, curr) => {
            acc[curr.LocID] = acc[curr.LocID] ?? [];
            acc[curr.LocID].push(curr);
            return acc;
          }, {});
          const productmodelAfbeeldingenBijProdModID = data.result.productmodelAfbeeldingen.reduce<{
            [id: number]: IProductmodelAfbeelding[];
          }>(
            (acc, curr) => ({
              ...acc,
              [curr.prodModID]: [...(acc[curr.prodModID] ?? []), curr],
            }),
            {},
          );
          // const prolongatiesBijID = data.result.prolongaties.reduce<{
          //   [id: number]: IProlongatie;
          // }>(
          //   (acc, curr) => ({
          //     ...acc,
          //     [curr.ID]: curr,
          //   }),
          //   {},
          // );
          // const prolongatiesBijCntID = data.result.prolongaties.reduce<{
          //   [id: number]: IProlongatie[];
          // }>(
          //   (acc, curr) => ({
          //     ...acc,
          //     [curr.CntID]: [...(acc[curr.CntID] ?? []), curr],
          //   }),
          //   {},
          // );
          const productStatiBijID = data.result.productStati.reduce<{
            [id: number]: IProductStatus;
          }>(
            (acc, curr) => ({
              ...acc,
              [curr.ProdStatID]: curr,
            }),
            {},
          );
          const productTypesBijID = data.result.productTypes.reduce<{
            [id: number]: IProductType;
          }>(
            (acc, curr) => ({
              ...acc,
              [curr.TypeID]: curr,
            }),
            {},
          );
          const prodModXTypesBijProdModID = data.result.prodModXTypes.reduce<{
            [id: number]: IProdModXType[];
          }>(
            (acc, curr) => ({
              ...acc,
              [curr.ProdModID]: [...(acc[curr.ProdModID] ?? []), curr],
            }),
            {},
          );
          const prodModXTypesBijTypeID = data.result.prodModXTypes.reduce<{
            [id: number]: IProdModXType[];
          }>(
            (acc, curr) => ({
              ...acc,
              [curr.TypeID]: [...(acc[curr.TypeID] ?? []), curr],
            }),
            {},
          );
          const productMerkenBijID = data.result.productMerken.reduce<{
            [id: number]: IProductMerk;
          }>(
            (acc, curr) => ({
              ...acc,
              [curr.MerkID]: curr,
            }),
            {},
          );
          const transportOpdrachtRegelsBijID = data.result.transportOpdrachtRegels.reduce<{
            [id: number]: ITransportOpdrachtRegel;
          }>(
            (acc, curr) => ({
              ...acc,
              [curr.TrsRegID]: curr,
            }),
            {},
          );
          const transportOpdrachtRegelsBijCntID = data.result.transportOpdrachtRegels.reduce<{
            [id: number]: ITransportOpdrachtRegel[];
          }>(
            (acc, curr) => ({
              ...acc,
              [curr.CntID]: [...(acc[curr.CntID] ?? []), curr],
            }),
            {},
          );
          const transportRegelSoortenBijID = data.result.transportRegelSoorten.reduce<{
            [id: number]: ITransportRegelSoort;
          }>(
            (acc, curr) => ({
              ...acc,
              [curr.ID]: curr,
            }),
            {},
          );
          const serviceMeldingenBijID = data.result.serviceMeldingen.reduce<{
            [id: number]: IServiceMelding;
          }>(
            (acc, curr) => ({
              ...acc,
              [curr.ID]: curr,
            }),
            {},
          );
          const serviceMeldingenBijCntID = data.result.serviceMeldingen.reduce<{
            [id: number]: IServiceMelding[];
          }>(
            (acc, curr) => ({
              ...acc,
              [curr.CntID]: [...(acc[curr.CntID] ?? []), curr],
            }),
            {},
          );
          const verkoopVoorstellenBijID = data.result.verkoopVoorstellen.reduce<{
            [id: number]: IVerkoopVoorstel;
          }>(
            (acc, curr) => ({
              ...acc,
              [curr.ID]: curr,
            }),
            {},
          );
          const verkoopVoorstellenBijCntID = data.result.verkoopVoorstellen.reduce<{
            [id: number]: IVerkoopVoorstel[];
          }>(
            (acc, curr) => ({
              ...acc,
              [curr.CntID]: [...(acc[curr.CntID] ?? []), curr],
            }),
            {},
          );
          const relatieRekeningenBijID = data.result.relatieRekeningen.reduce<{
            [id: number]: IRelatieRekening;
          }>(
            (acc, curr) => ({
              ...acc,
              [curr.RelRekID]: curr,
            }),
            {},
          );
          const tariefActiesBijID = data.result.tariefActies.reduce<{
            [id: number]: ITariefActie;
          }>(
            (acc, curr) => ({
              ...acc,
              [curr.TarActieID]: curr,
            }),
            {},
          );
          const contractRedenAfkeuringenBijID = data.result.contractRedenAfkeuringen.reduce<{
            [id: number]: IContractRedenAfkeuring;
          }>(
            (acc, curr) => ({
              ...acc,
              [curr.CntRdAfkID]: curr,
            }),
            {},
          );
          const contractRedenAnnuleringenBijID = data.result.contractRedenAnnuleringen.reduce<{
            [id: number]: IContractRedenAnnulering;
          }>(
            (acc, curr) => ({
              ...acc,
              [curr.CntRdAnnID]: curr,
            }),
            {},
          );
          const contractRedenBeeindigingenBijID = data.result.contractRedenBeeindigingen.reduce<{
            [id: number]: IContractRedenBeeindiging;
          }>(
            (acc, curr) => ({
              ...acc,
              [curr.CntRdEndID]: curr,
            }),
            {},
          );
          const contractRedenOvernamesBijID = data.result.contractRedenOvernames.reduce<{
            [id: number]: IContractRedenOvername;
          }>(
            (acc, curr) => ({
              ...acc,
              [curr.CntRdOvdID]: curr,
            }),
            {},
          );
          const factuurKenmerkenBijID = data.result.factuurKenmerken.reduce<{
            [id: number]: IFactuurKenmerk;
          }>(
            (acc, curr) => ({
              ...acc,
              [curr.ID]: curr,
            }),
            {},
          );
          const relatieComplexenBijID = data.result.relatieComplexen.reduce<{
            [id: number]: IRelatieComplex;
          }>(
            (acc, curr) => ({
              ...acc,
              [curr.ID]: curr,
            }),
            {},
          );
          const locatieNiveausBijVerdieping = data.result.locatieNiveaus.reduce<{
            [id: number]: ILocatieNiveau;
          }>(
            (acc, curr) => ({
              ...acc,
              [curr.Verdieping]: curr,
            }),
            {},
          );
          const contractBijContractwisselCntID = data.result.contracten.reduce<{
            [id: number]: IContract;
          }>((acc, curr) => {
            if (curr.Contractwissel_CntID === null) {
              return acc;
            }
            return {
              ...acc,
              [curr.Contractwissel_CntID]: curr,
            };
          }, {});

          const contractenBijLocIDOpVolgorde = Object.keys(contractenBijLocID)
            .map(Number)
            .reduce((acc, locID) => {
              const contracten = contractenBijLocID[locID];
              // Volgnummer laagste prio
              // contracten.sort((a, b) => {
              //   return b.Volgnummer - a.Volgnummer;
              // });
              // Contractnummer middelste prio
              contracten.sort((a, b) => {
                return b.Basisnummer - a.Basisnummer;
              });
              // Contractstatus hoogste prio
              contracten.sort((a, b) => {
                const aCntStat = contractStatussenBijID[a.CntStatID];
                const bCntStat = contractStatussenBijID[b.CntStatID];

                return aCntStat.SortNr - bCntStat.SortNr;
              });
              return {
                ...acc,
                [locID]: contracten,
              };
            }, {});

          const relatieLocatieBijLocID = data.result.relatieLocaties.reduce<{
            [locID: number]: IRelatieLocatie;
          }>((acc, curr) => {
            acc[curr.LocID] = curr;
            return acc;
          }, {});

          const relatieComplexBijID = data.result.relatieComplexen.reduce<{
            [id: number]: IRelatieComplex;
          }>((acc, curr) => {
            acc[curr.ID] = curr;
            return acc;
          }, {});

          const contractbasisHurenBijCntBasisID = data.result.contractbasisHuren.reduce<{
            [id: number]: IContractbasisHuur[];
          }>((acc, curr) => {
            acc[curr.CntBasisID] = acc[curr.CntBasisID] ?? [];
            acc[curr.CntBasisID].push(curr);
            return acc;
          }, {});

          const indexeringenBijID = data.result.indexeringen.reduce<{
            [id: number]: IIndexering;
          }>((acc, curr) => {
            acc[curr.ID] = curr;
            return acc;
          }, {});

          const nu = new Date();
          const actueelContractbasisHuurBijCntBasisID = data.result.contractbasisHuren.reduce<{
            [id: number]: IContractbasisHuur;
          }>((acc, curr) => {
            if (
              acc[curr.CntBasisID] === undefined &&
              new Date(curr.Ingangsdatum).getTime() <= nu.getTime()
            ) {
              acc[curr.CntBasisID] = curr;
            }
            return acc;
          }, {});

          const locXRelPersBijLocID = data.result.locXRelPers.reduce<{
            [locID: number]: ILocXRelPers[];
          }>((acc, curr) => {
            const arr = acc[curr.LocID] ?? [];
            arr.push(curr);
            acc[curr.LocID] = arr;
            return acc;
          }, {});

          const contactpersonenBijID = data.result.contactpersonen.reduce<{
            [id: number]: IContactpersoon;
          }>((acc, curr) => {
            acc[curr.ID] = curr;
            return acc;
          }, {});

          const personenBijID = data.result.personen.reduce<{
            [id: number]: IPersoon;
          }>((acc, curr) => {
            acc[curr.PersID] = curr;
            return acc;
          }, {});

          const talenBijID = data.result.talen.reduce<{
            [id: number]: ITaal;
          }>((acc, curr) => {
            acc[curr.TaalID] = curr;
            return acc;
          }, {});

          const relatieBetrekkingenBijID = data.result.relatieBetrekkingen.reduce<{
            [id: number]: IRelatieBetrekking;
          }>((acc, curr) => {
            acc[curr.ID] = curr;
            return acc;
          }, {});

          return {
            contractenBijID,
            contractenBijLocID: contractenBijLocIDOpVolgorde,
            locatiesBijID,
            abonnementenBijID,
            productmodellenBijID,
            contractStatussenBijID,
            productenBijID,
            productenBijCntBasisID,
            locatiesInternBijID,
            locatiesInternBijLocID,
            productmodelAfbeeldingenBijProdModID,
            // prolongatiesBijID,
            // prolongatiesBijCntID,
            productStatiBijID,
            productTypesBijID,
            prodModXTypesBijProdModID,
            prodModXTypesBijTypeID,
            productMerkenBijID,
            transportOpdrachtRegelsBijID,
            transportOpdrachtRegelsBijCntID,
            transportRegelSoortenBijID,
            serviceMeldingenBijID,
            serviceMeldingenBijCntID,
            verkoopVoorstellenBijID,
            verkoopVoorstellenBijCntID,
            relatieRekeningenBijID,
            tariefActiesBijID,
            contractRedenAfkeuringenBijID,
            contractRedenAnnuleringenBijID,
            contractRedenBeeindigingenBijID,
            contractRedenOvernamesBijID,
            factuurKenmerkenBijID,
            relatieComplexenBijID,
            locatieNiveausBijVerdieping,
            contractBijContractwisselCntID,
            relatieLocatieBijLocID,
            relatieComplexBijID,
            contractbasisHurenBijCntBasisID,
            indexeringenBijID,
            actueelContractbasisHuurBijCntBasisID,
            locXRelPersBijLocID,
            contactpersonenBijID,
            personenBijID,
            talenBijID,
            relatieBetrekkingenBijID,
          };
        }),
      [store.data],
    );

    const heeftSelectie = useMemo(() => store.selectieCntIDs.length > 0, [store.selectieCntIDs]);
    const heeftEnkeleSelectie = useMemo(() => store.selectieCntIDs.length === 1, [
      store.selectieCntIDs,
    ]);

    const heeftConceptContracten = useMemo<IRemoteData<boolean>>(() => {
      if (
        store.data.state === ERemoteDataState.Pending ||
        dataBijID.state === ERemoteDataState.Pending ||
        store.data.data!.relID !== props.relID
      ) {
        return createPendingRemoteData();
      }

      return createReadyRemoteData(
        store.data.data!.result.contracten.some((cnt: IContract) => {
          const cntStat = dataBijID.data!.contractStatussenBijID[cnt.CntStatID];
          return cntStat.NaamEnum === EContractStatus.Concept;
        }),
      );
    }, [store.data, dataBijID, props.relID]);

    const heeftLopendeContracten = useMemo<IRemoteData<boolean>>(() => {
      if (
        store.data.state === ERemoteDataState.Pending ||
        dataBijID.state === ERemoteDataState.Pending ||
        store.data.data!.relID !== props.relID
      ) {
        return createPendingRemoteData();
      }

      return createReadyRemoteData(
        store.data.data!.result.contracten.some((cnt: IContract) => {
          const cntStat = dataBijID.data!.contractStatussenBijID[cnt.CntStatID];
          return cntStat.NaamEnum === EContractStatus.Lopend;
        }),
      );
    }, [store.data, dataBijID, props.relID]);

    const selectieContracten = useMemo<IRemoteData<IContract[]>>(() => {
      if (dataBijID.state === ERemoteDataState.Pending || store.data.data!.relID !== props.relID) {
        return createPendingRemoteData();
      }
      return createReadyRemoteData(
        store.selectieCntIDs.map((cntID: number) => dataBijID.data!.contractenBijID[cntID]),
      );
    }, [store.selectieCntIDs, dataBijID, store.data, props.relID]);

    const fiatterenType = useMemo<IRemoteData<FiatterenType | null>>(() => {
      if (
        store.data.state === ERemoteDataState.Pending ||
        selectieContracten.state === ERemoteDataState.Pending ||
        store.data.data!.relID !== props.relID
      ) {
        return createPendingRemoteData();
      }
      if (selectieContracten.data!.length === 0) {
        return createReadyRemoteData(null);
      }

      const selectieVolgnummers = selectieContracten.data!.map((cnt: IContract) => cnt.Volgnummer);

      if (selectieVolgnummers.every((volgnummer: number) => volgnummer === 0)) {
        return createReadyRemoteData('basis');
      }

      if (selectieVolgnummers.every((volgnummer: number) => volgnummer !== 0)) {
        return createReadyRemoteData('volg');
      }

      return createReadyRemoteData(null);
    }, [store.data, selectieContracten, props.relID]);

    const bevestigingsverzoekKnopWeergeven = useMemo(
      () => heeftConceptContracten.state === ERemoteDataState.Ready && heeftConceptContracten.data!,
      [heeftConceptContracten],
    );
    const annulerenKnopWeergeven = useMemo(
      () => heeftConceptContracten.state === ERemoteDataState.Ready && heeftConceptContracten.data!,
      [heeftConceptContracten],
    );
    const fiatterenKnopWeergeven = useMemo(
      () =>
        heeftConceptContracten.state === ERemoteDataState.Ready &&
        heeftConceptContracten.data! &&
        fiatterenType.state === ERemoteDataState.Ready,
      [heeftConceptContracten, fiatterenType],
    );
    const beeindigenKnopWeergeven = useMemo(
      () => heeftLopendeContracten.state === ERemoteDataState.Ready && heeftLopendeContracten.data!,
      [heeftLopendeContracten],
    );

    // Als de locaties verschillend zijn, dan mag deze knop niet gebruikt worden.
    const selectieContractenHebbenDezelfdeLocatie = useMemo<IRemoteData<boolean>>(() => {
      if (selectieContracten.state === ERemoteDataState.Pending) {
        return createPendingRemoteData();
      }
      if (selectieContracten.data!.length === 0) {
        return createReadyRemoteData(false);
      }
      const eersteContract = selectieContracten.data![0];
      const isOveralDezelfdeLocatie = selectieContracten.data!.every(
        (cnt: IContract) => cnt.LocID !== null && cnt.LocID === eersteContract.LocID,
      );
      return createReadyRemoteData(isOveralDezelfdeLocatie);
    }, [selectieContracten]);

    // Als de locaties verschillend zijn, dan mag deze knop niet gebruikt worden.
    const selectieContractenHebbenDezelfdeFactuurkenmerk = useMemo<IRemoteData<boolean>>(() => {
      if (selectieContracten.state === ERemoteDataState.Pending) {
        return createPendingRemoteData();
      }
      if (selectieContracten.data!.length === 0) {
        return createReadyRemoteData(false);
      }
      const eersteContract = selectieContracten.data![0];
      const isOveralDezelfdeFactuurkenmerken = selectieContracten.data!.every(
        (cnt: IContract) => cnt.FactKenID === eersteContract.FactKenID,
      );
      return createReadyRemoteData(isOveralDezelfdeFactuurkenmerken);
    }, [selectieContracten]);

    const handleNieuwContractToevoegenClick = useCallback(async () => {
      await store.scopeBezigheid(async () => {
        // Gegevens van het meest recente contract
        // const contract =
        //   context.contracten!.length !== 0 ? context.contracten![0] : null;
        //
        // let aantalGebruikers = 0;
        // let verdieping = null;
        // let persID = null;
        // let locID = null;
        // let abonID = null;
        //
        // if (contract !== null) {
        //   aantalGebruikers = contract.basis.AantalGebruikers;
        //   verdieping = contract.basis.Verdieping;
        //   persID = contract.PersID;
        //   locID = contract.basis.locatie.LocID;
        //   abonID = contract.basis.abonnement.AbonID;
        // }
        //
        // return setNieuwContractDialoogTonen({
        //   aantalGebruikers,
        //   verdieping,
        //   persID,
        //   locID,
        //   abonID,
        // });

        await globaleRenderer.render((renderProps) => (
          <NieuwContractDialoogV2
            open
            onSuccess={async (result) => {
              if (result.leveropdrachtMaken) {
                await api.v2.relatie.contract.transportOpdrachtenMaken({
                  relID: props.relID,
                  cntID: result.cntID,
                  wisselcontract_CntID: null,
                  trsOpdID: result.trsOpdID,
                });
              }
              await store.verversenContractWeergave();
              renderProps.destroy();
            }}
            onAnnuleren={() => renderProps.destroy()}
            relID={props.relID}
            // prodModID={21}
            // abonID={context.nieuwContractenDialoogState.abonID}
            // locID={context.nieuwContractenDialoogState.locID}
            // persID={context.nieuwContractenDialoogState.persID}
            // aantalGebruikers={context.nieuwContractenDialoogState.aantalGebruikers}
            // aansluitenTrekschakelaar={null}
            // verdieping={context.nieuwContractenDialoogState.verdieping}
          />
        ));
      });
    }, [props.relID, store.scopeBezigheid]);

    const handleContractwisselClick = useCallback(async () => {
      await store.scopeBezigheid(async () => {
        if (store.selectieCntIDs.length === 0) {
          throw new Error('Geen contract geselecteerd');
        }
        const cntID = store.selectieCntIDs[0];

        const checkData = await api.v2.contract.wissel.checkContractVoorWisselen({
          cntID,
        });
        const checkResult = await checkStore.controleren({
          checkData,
        });
        if (checkResult.type === EResultType.Annuleren) {
          return;
        }

        await globaleRenderer.render((renderProps) => (
          <NieuwContractDialoogV2
            open
            onSuccess={async (result) => {
              if (result.leveropdrachtMaken) {
                await api.v2.relatie.contract.transportOpdrachtenMaken({
                  relID: props.relID,
                  cntID: result.cntID,
                  wisselcontract_CntID: result.wisselcontract_CntID,
                  trsOpdID: result.trsOpdID,
                });
              }
              await store.verversenContractWeergave();
              renderProps.destroy();
            }}
            onAnnuleren={() => renderProps.destroy()}
            wisselcontract_CntID={cntID}
            relID={props.relID}
          />
        ));
      });
    }, [props.relID, store.selectieCntIDs]);

    const handleBevestigingsverzoekClick = useCallback(async () => {
      await store.scopeBezigheid(async () => {
        const params: IVersturenBevestigingsverzoekParams = {
          relID: props.relID,
          cntIDs: store.selectieCntIDs,
          directVersturen: true,
        };
        const checkData = await api.v2.contract.nieuw.checkVersturenBevestigingsverzoek(params);
        if (
          (
            await checkStore.controleren({
              checkData,
            })
          ).type === EResultType.Annuleren
        ) {
          return;
        }

        if (
          (
            await checkStore.bevestigen({
              inhoud: <span>Bevestigingsverzoek sturen?</span>,
            })
          ).type === EResultType.Annuleren
        ) {
          return;
        }

        await api.v2.contract.nieuw.versturenBevestigingsverzoek(params);
      });
    }, [props.relID, store.selectieCntIDs, store.scopeBezigheid]);

    const handleAnnulerenClick = useCallback(async () => {
      await store.scopeBezigheid(async () => {
        // const checkData = await api.v2.contract.annulering.checkAnnulerenContracten({
        //   relID: props.relID,
        //   cntIDs: store.selectieCntIDs,
        //   annuleringsdatum: null,
        // });

        // if (
        //   (
        //     await checkStore.controleren({
        //       checkData,
        //     })
        //   ).type === EResultType.Annuleren
        // ) {
        //   return;
        // }

        await globaleRenderer.render((renderProps) => (
          <AnnulerenContractDialoog
            open
            onSuccess={async () => {
              await store.verversenContractWeergave();
              renderProps.destroy();
            }}
            onAnnuleren={() => renderProps.destroy()}
            relID={props.relID}
            cntIDs={store.selectieCntIDs}
          />
        ));
      });
    }, [props.relID, store.selectieCntIDs, store.scopeBezigheid]);

    const handleFiatterenClick = useCallback(async () => {
      if (fiatterenType.state === ERemoteDataState.Pending || fiatterenType.data === null) {
        throw new Error('Geen fiatteren type bepaald bij fiatteren');
      }

      await store.scopeBezigheid(async () => {
        const checkData = await api.v2.contract.nieuw.checkFiatterenContracten({
          relID: props.relID,
          cntIDs: store.selectieCntIDs,
        });

        if (
          (
            await checkStore.controleren({
              checkData,
            })
          ).type === EResultType.Annuleren
        ) {
          return;
        }

        switch (fiatterenType.data!) {
          case 'basis': {
            await globaleRenderer.render((renderProps) => (
              <FiatterenNieuwDialoog
                open
                relID={props.relID}
                cntIDs={store.selectieCntIDs}
                onSuccess={async () => {
                  await store.verversenContractWeergave();
                  renderProps.destroy();
                }}
                onAnnuleren={() => renderProps.destroy()}
              />
            ));
            break;
          }
          case 'volg': {
            await globaleRenderer.render((renderProps) => (
              <FiatterenOvernameDialoog
                open
                relID={props.relID}
                cntIDs={store.selectieCntIDs}
                onSuccess={async () => {
                  await store.verversenContractWeergave();
                  renderProps.destroy();
                }}
                onAnnuleren={() => renderProps.destroy()}
              />
            ));
            break;
          }
        }
      });
    }, [store.scopeBezigheid, fiatterenType, store.selectieCntIDs, props.relID]);

    const handleBeeindigenClick = useCallback(async () => {
      await store.scopeBezigheid(async () => {
        const checkData = await api.v2.contract.beeindiging.checkSelectieBeeindigenContracten({
          cntIDs: store.selectieCntIDs,
        });

        if (
          (
            await checkStore.controleren({
              checkData,
            })
          ).type === EResultType.Annuleren
        ) {
          return;
        }

        await globaleRenderer.render((renderProps) => (
          <BeeindigenContractDialoog
            open
            relID={props.relID}
            cntIDs={store.selectieCntIDs}
            onSuccess={async () => {
              await store.verversenContractWeergave();
              renderProps.destroy();
            }}
            onAnnuleren={() => renderProps.destroy()}
          />
        ));
      });
    }, [store.scopeBezigheid, store.selectieCntIDs, props.relID]);

    const handleTransportopdrachtClick = useCallback(async () => {
      if (
        selectieContracten.state === ERemoteDataState.Pending ||
        selectieContracten.data!.length === 0 ||
        dataBijID.state === ERemoteDataState.Pending
      ) {
        throw new Error(
          'Geen selectie of selectie is nog bezig met laden, of dataBijID bij transportopdracht',
        );
      }

      await store.scopeBezigheid(async () => {
        // setNieuweTransportopdrachtDialoogTonen({
        //   soortopdracht,
        // });

        const contract = selectieContracten.data![0];
        const productenResult = await api.v2.product.ophalenProductenV2({
          filterSchema: {
            filters: [
              {
                naam: 'CNT_IDS',
                data: [contract.CntID],
              },
              {
                naam: 'PROD_STAT_NAAM_ENUMS',
                data: [EProductstatus.Verhuur],
              },
            ],
          },
        });
        let soortOpdracht: ESoortOpdracht;
        if (productenResult.producten.length === 0) {
          soortOpdracht = ESoortOpdracht.Levering;
        } else {
          const cntStatus = dataBijID.data!.contractStatussenBijID[contract.CntStatID];
          if (
            productenResult.producten.length === 1 &&
            cntStatus.NaamEnum === EContractStatus.Lopend
          ) {
            soortOpdracht = ESoortOpdracht.Omruil;
          } else {
            soortOpdracht = ESoortOpdracht.Retour;
          }
        }

        await globaleRenderer.render((renderProps) => (
          <NieuweTransportopdrachtDialoog
            open
            relID={props.relID}
            contractID={contract.CntID}
            soortOpdracht={soortOpdracht}
            leverProduct={null}
            onSuccess={async () => {
              await store.verversenContractWeergave();
              renderProps.destroy();
            }}
            onAnnuleren={() => renderProps.destroy()}
          />
        ));
      });
    }, [store.scopeBezigheid, selectieContracten, dataBijID, props.relID]);

    const handleAanbiedenVoorOvernameClick = useCallback(async () => {
      await store.scopeBezigheid(async () => {
        const checkData = await api.v2.contract.checkSelectieAanbiedenContracten({
          relID: props.relID,
          cntIDs: store.selectieCntIDs,
        });

        if (
          (
            await checkStore.controleren({
              checkData,
            })
          ).type === EResultType.Annuleren
        ) {
          return;
        }

        await globaleRenderer.render((renderProps) => (
          <AanbiedenOvernameDialoog
            open
            relID={props.relID}
            cntIDs={store.selectieCntIDs}
            onSuccess={async () => {
              await store.verversenContractWeergave();
              renderProps.destroy();
            }}
            onAnnuleren={() => renderProps.destroy()}
          />
        ));
      });
    }, [store.scopeBezigheid, props.relID, store.selectieCntIDs]);

    const handleVerkoopvoorstelenMakenClick = useCallback(async () => {
      await store.scopeBezigheid(async () => {
        // // Haal de producten op die aan de vigerende contracten gekoppeld zijn
        const result = await api.v2.product.verkoop.selecterenVerkoopProducten({
          peildatum: null,
          filterSchema: {
            filters: [
              {
                naam: 'CNT_IDS',
                data: store.selectieCntIDs,
              },
              {
                naam: 'VERKOOP_VOORSTEL_AANWEZIG',
                data: false,
              },
            ],
          },
        });

        if (result.prodIDs.length === 0) {
          await checkStore.melden({
            titel: (
              <span>
                Er zijn geen producten gevonden waarvoor een verkoopvoorstel gemaakt kan worden. Dit
                kan zijn omdat:
                <br />
                <br />
                <ul>
                  <li>Voor één of meer producten is reeds een lopend verkoopvoorstel aanwezig</li>
                  <li>De ingangsdatum voor een contract ligt in de toekomst</li>
                </ul>
              </span>
            ),
          });
          return;
        }

        const params = {
          relID: props.relID,
          persID: null,
          prodIDs: result.prodIDs,
          voorsteldatum: null,
        };

        const checkResult = await api.v2.product.verkoop.checkMakenVerkoopVoorstellen(params);
        const controlerenResult = await checkStore.controleren({
          checkData: checkResult,
        });

        if (controlerenResult.type === EResultType.Annuleren) {
          return;
        }

        if (
          (
            await checkStore.bevestigen({
              inhoud: (
                <span>
                  Verkoopvoorstellen maken voor de geselecteerde contracten?
                  <br /> <br />
                  Na aanmaken kun je het voorstel aanpassen voordat je het naar de klant verstuurt.
                </span>
              ),
            })
          ).type === EResultType.Annuleren
        ) {
          return;
        }

        await api.v2.product.verkoop.makenVerkoopVoorstellen(params);

        props.history.push(`/klant/${props.relID}/contracten/verkoop`);
      });
    }, [store.scopeBezigheid, store.selectieCntIDs, props.relID]);

    const handleAfkeurenContractenClick = useCallback(async () => {
      await store.scopeBezigheid(async () => {
        const checkData = await api.v2.contract.afkeuring.checkSelectieAfkeurenContracten({
          relID: props.relID,
          cntIDs: store.selectieCntIDs,
        });

        if (
          (
            await checkStore.controleren({
              checkData,
            })
          ).type === EResultType.Annuleren
        ) {
          return;
        }

        await globaleRenderer.render((renderProps) => (
          <AfkeurenContractDialoog
            open
            relID={props.relID}
            cntIDs={store.selectieCntIDs}
            onSuccess={async () => {
              await store.verversenContractWeergave();
              renderProps.destroy();
            }}
            onAnnuleren={() => renderProps.destroy()}
          />
        ));
      });
    }, [store.scopeBezigheid, props.relID, store.selectieCntIDs]);

    const handleOvernemenContractenClick = useCallback(async () => {
      if (klantkaartStore.relatie === null || klantkaartStore.relatie.persoon === null) {
        throw new Error(
          'Relatie op klantkaartstore is null of heeft geen persoon overnemen contracten',
        );
      }

      await store.scopeBezigheid(async () => {
        await globaleRenderer.render((renderProps) => (
          <OvernemenContractDialoog
            open
            relID={props.relID}
            persID={klantkaartStore.relatie!.persoon!.PersID}
            onSuccess={async () => {
              await store.verversenContractWeergave();
              renderProps.destroy();
            }}
            onAnnuleren={() => renderProps.destroy()}
          />
        ));
      });
    }, [store.scopeBezigheid, props.relID, klantkaartStore.relatie?.persoon?.PersID]);

    const handleProlongerenContractenClick = useCallback(async () => {
      if (klantkaartStore.relatie === null || klantkaartStore.relatie.persoon === null) {
        throw new Error(
          'Relatie op klantkaartstore is null of heeft geen persoon overnemen contracten',
        );
      }

      if (store.selectieCntIDs.length === 0) {
        await checkStore.melden({
          titel: 'Er dient tenminste 1 contract geselecteerd te worden',
        });
        return;
      }

      await store.scopeBezigheid(async () => {
        await globaleRenderer.render((renderProps) => (
          <NieuwRunDialoog
            open
            onSuccess={(result) => renderProps.destroy(result)}
            onAnnuleren={() => renderProps.destroy(null)}
            modus={
              {
                type: 'contracten',
                cntIDs: store.selectieCntIDs,
              } as IContractenModus
            }
          />
        ));
      });
    }, [store.scopeBezigheid, props.relID, store.selectieCntIDs]);

    const handleBevestigingAnnuleringVersturenClick = useCallback(async () => {
      await store.scopeBezigheid(async () => {
        const params: IVersturenBevestigingAnnuleringParams = {
          relID: props.relID,
          persID: null,
          cntIDs: store.selectieCntIDs,
          taalID: null,
          kanalen: ['EMAIL'],
        };

        const checkData = await api.v2.contract.annulering.checkVersturenBevestigingAnnulering(
          params,
        );
        if (
          (
            await checkStore.controleren({
              checkData,
            })
          ).type === EResultType.Annuleren
        ) {
          return;
        }
        if (
          (
            await checkStore.bevestigen({
              inhoud: <span>Bevestiging annulering contracten versturen?</span>,
            })
          ).type === EResultType.Annuleren
        ) {
          return;
        }

        await api.v2.contract.annulering.versturenBevestigingAnnulering(params);
      });
    }, [store.scopeBezigheid, props.relID, store.selectieCntIDs]);

    const handleBevestigingBeeindigingVersturenClick = useCallback(async () => {
      await store.scopeBezigheid(async () => {
        const params: IVersturenBevestigingBeeindigingParams = {
          relID: props.relID,
          persID: null,
          cntIDs: store.selectieCntIDs,
        };

        const checkData = await api.v2.contract.beeindiging.checkVersturenBevestiging(params);
        if (
          (
            await checkStore.controleren({
              checkData,
            })
          ).type === EResultType.Annuleren
        ) {
          return;
        }
        if (
          (
            await checkStore.bevestigen({
              inhoud: <span>Bevestiging beeindiging contracten versturen?</span>,
            })
          ).type === EResultType.Annuleren
        ) {
          return;
        }

        await api.v2.contract.beeindiging.versturenBevestiging(params);
      });
    }, [store.scopeBezigheid, props.relID, store.selectieCntIDs]);

    const handleBevestigingNieuweContractenVersturenClick = useCallback(async () => {
      await store.scopeBezigheid(async () => {
        const params = {
          relID: props.relID,
          cntIDs: store.selectieCntIDs,
        };
        const checkData = await api.v2.contract.nieuw.checkVersturenFiatteringsbericht(params);

        if (
          (
            await checkStore.controleren({
              checkData,
            })
          ).type === EResultType.Annuleren
        ) {
          return;
        }

        if (
          (
            await checkStore.bevestigen({
              inhoud: (
                <div>Wil je een fiatteringsbericht versturen voor de geselecteerde contracten?</div>
              ),
            })
          ).type === EResultType.Annuleren
        ) {
          return;
        }

        await api.v2.contract.nieuw.versturenFiatteringsbericht(params);
      });
    }, [store.scopeBezigheid, props.relID, store.selectieCntIDs]);

    const handleBevestigingOvernameNieuweHuurderVersturenClick = useCallback(async () => {
      await store.scopeBezigheid(async () => {
        const params = {
          relID: props.relID,
          cntIDs: store.selectieCntIDs,
          persID: null,
          kanaal: EKanaal.Email,
        };

        if (
          (
            await checkStore.bevestigen({
              inhoud: (
                <div>
                  Wil je voor de geselecteerde contracten een overname-bevestiging sturen naar de
                  nieuwe huurder?
                </div>
              ),
            })
          ).type === EResultType.Annuleren
        ) {
          return;
        }

        await api.v2.contract.overname.versturenBevestigingNieuweHuurder(params);
      });
    }, [store.scopeBezigheid, props.relID, store.selectieCntIDs]);

    const handleLocatieWijzigenClick = useCallback(async () => {
      if (
        selectieContracten.state === ERemoteDataState.Pending ||
        selectieContractenHebbenDezelfdeLocatie.state === ERemoteDataState.Pending ||
        dataBijID.state === ERemoteDataState.Pending
      ) {
        throw new Error('Laden van data voor locatie wijzigen nog bezig');
      }

      await store.scopeBezigheid(async () => {
        const contract = selectieContracten.data![0];

        if (contract.LocID === null) {
          await checkStore.melden({
            titel: 'Het gekozen contract heeft geen gekoppelde locatie',
          });
          return;
        }

        const locatie = dataBijID.data!.locatiesBijID[contract.LocID];
        const initialValues: ILocatieSelecterenOfOpvoerenDialoogFormikValues = {
          adres: selectieContractenHebbenDezelfdeLocatie.data!
            ? {
                bisnummer: locatie.Bisnummer ?? '',
                huisnummer: locatie.Huisnummer,
                plaatsnaam: locatie.Plaatsnaam,
                postcode: locatie.Postcode,
                landID: locatie.LandID,
                straatnaam: locatie.Straatnaam,
              }
            : null,
          bezoekinstructies: '',
          bijzonderheden: '',
          liftAanwezig: false,
        };

        await globaleRenderer.render((renderProps) => {
          const [correspondentieadresMeewijzigen, setCorrespondentieadresMeewijzigen] = useState(
            false,
          );
          const [
            locatieVanProductenNietMeewijzigen,
            setLocatieVanProductenNietMeewijzigen,
          ] = useState(false);

          return (
            <LocatieSelecterenOfOpvoerenDialoog
              open
              initialValues={initialValues}
              titel="Locatie wijzigen van contracten"
              uitbreiding={
                <div className="p-3">
                  <div className="d-flex align-items-center">
                    <VinkVeld
                      aangevinkt={correspondentieadresMeewijzigen}
                      onGewijzigd={setCorrespondentieadresMeewijzigen}
                    />
                    <span className="ml-2">Correspondentieadres meewijzigen</span>
                  </div>

                  <div className="d-flex align-items-center mt-2">
                    <VinkVeld
                      aangevinkt={locatieVanProductenNietMeewijzigen}
                      onGewijzigd={setLocatieVanProductenNietMeewijzigen}
                    />
                    <span className="ml-2">Locatie van producten niet mee wijzigen</span>
                    <span className="ml-2">
                      <UitlegTooltip
                        inhoud={
                          <div>
                            <p>
                              Aan ieder contracten zitten producten gekoppeld, de locatie kan in
                              principe niet overeenkomen met de contractlocatie; echter is dit vaak
                              niet het geval.
                            </p>
                            <p>
                              Door dit veld aan te vinken geef je aan dat de locatie van het product
                              niet mee moet veranderen met de locatie van het contract.
                            </p>
                          </div>
                        }
                      />
                    </span>
                  </div>
                </div>
              }
              onSuccess={async (result) => {
                await api.v2.contract.wijzigenLocatieVanContracten({
                  locID: result.locID,
                  cntIDs: store.selectieCntIDs,
                });

                // Locatie van gekopppelde producten ook meewijzigen?
                if (!locatieVanProductenNietMeewijzigen) {
                  const cntBasisIDs: number[] = _.uniq(
                    selectieContracten.data!.map((x) => x.CntBasisID),
                  );

                  const producten: IProduct[] = _.flatten(
                    cntBasisIDs
                      .filter(
                        (cntBasisID) =>
                          dataBijID.data!.productenBijCntBasisID[cntBasisID] !== undefined,
                      )
                      .map((cntBasisID) => {
                        return dataBijID.data!.productenBijCntBasisID[cntBasisID];
                      }),
                  );

                  if (producten.length !== 0) {
                    const prodIDs: number[] = _.uniq(producten.map((x: IProduct) => x.ProdID));

                    await api.v2.product.wijzigenLocatieVanProducten({
                      locID: result.locID,
                      prodIDs,
                    });
                  }
                }

                // Correspondentieadres meewijzigen?
                if (correspondentieadresMeewijzigen) {
                  const locatiesResult = await api.v2.locatie.ophalenLocaties({
                    filterSchema: {
                      filters: [
                        {
                          naam: 'IDS',
                          data: [result.locID],
                        },
                      ],
                    },
                  });
                  const locatie = locatiesResult.locaties[0];

                  await api.v2.relatie.wijzigenCorrespondentieadres({
                    relID: props.relID,
                    adres: {
                      straatnaam: locatie.Straatnaam,
                      huisnummer: locatie.Huisnummer,
                      bisnummer: locatie.Bisnummer,
                      postcode: locatie.Postcode,
                      plaatsnaam: locatie.Plaatsnaam,
                      landID: locatie.LandID,
                    },
                  });
                }

                await store.verversenContractWeergave();
                renderProps.destroy();
              }}
              onAnnuleren={() => renderProps.destroy()}
            />
          );
        });
      });
    }, [
      store.scopeBezigheid,
      selectieContracten,
      selectieContractenHebbenDezelfdeLocatie,
      dataBijID,
      store.selectieCntIDs,
      props.relID,
    ]);

    const handleInterneLocatieWijzigenClick = useCallback(async () => {
      if (
        selectieContractenHebbenDezelfdeLocatie.state === ERemoteDataState.Pending ||
        selectieContractenHebbenDezelfdeLocatie.data === false ||
        dataBijID.state === ERemoteDataState.Pending
      ) {
        throw new Error(
          'Niet alle contracten hebben dezelfde locatie bij interne locatie wijzigen of is nog aan het laden',
        );
      }

      await store.scopeBezigheid(async () => {
        // Elk contract moet dezelfde locatie hebben vandaar dat het eerste contract voldoet
        const contract = selectieContracten.data![0];
        const locatieInternVanContract =
          contract.LocIntID === null
            ? null
            : dataBijID.data!.locatiesInternBijID[contract.LocIntID];
        const alleInterneLocatiesGelijk =
          selectieContracten.data!.length === 1
            ? true
            : selectieContracten.data!.every((cnt) => {
                const locatieIntern =
                  cnt.LocIntID === null ? null : dataBijID.data!.locatiesInternBijID[cnt.LocIntID];
                return locatieIntern?.ID === locatieInternVanContract?.ID;
              });
        const voorTeVullenLocIntID = alleInterneLocatiesGelijk
          ? locatieInternVanContract?.ID ?? null
          : null;

        await globaleRenderer.render((renderProps) => (
          <InterneLocatieSelectieDialoog
            interneLocatiesProvider={async () => {
              const result = await api.v2.locatie.ophalenInterneLocaties({
                filterSchema: {
                  filters: [
                    {
                      naam: 'LOC_IDS',
                      data: [contract.LocID].filter((x) => x !== null),
                    },
                  ],
                },
              });

              return result.interneLocaties;
            }}
            initieelLocIntIDProvider={async () => {
              return voorTeVullenLocIntID;
            }}
            open
            onSuccess={async (result) => {
              await api.v2.contract.wijzigenInterneLocatieVanContracten({
                locIntID: result.locIntID,
                cntIDs: store.selectieCntIDs,
              });
              await store.verversenContractWeergave();

              // TODO vastleggen van de interne locatie voor deze contracten
              // await api.v2.locatie.inte
              renderProps.destroy();
            }}
            onAnnuleren={() => renderProps.destroy()}
          />
        ));
      });
    }, [
      store.scopeBezigheid,
      selectieContractenHebbenDezelfdeLocatie,
      selectieContracten,
      dataBijID,
      store.selectieCntIDs,
      props.relID,
    ]);

    const handleFactuurKenmerkWijzigenClick = useCallback(async () => {
      // if (
      //   selectieContractenHebbenDezelfdeLocatie.state === ERemoteDataState.Pending ||
      //   selectieContractenHebbenDezelfdeLocatie.data === false ||
      //   dataBijID.state === ERemoteDataState.Pending
      // ) {
      //   throw new Error(
      //     'Niet alle contracten hebben dezelfde locatie bij interne locatie wijzigen of is nog aan het laden',
      //   );
      // }

      await store.scopeBezigheid(async () => {
        // Elk contract moet dezelfde locatie hebben vandaar dat het eerste contract voldoet
        const contract = selectieContracten.data![0];
        const factuurKenmerkVanContract =
          contract.FactKenID === null
            ? null
            : dataBijID.data!.factuurKenmerkenBijID[contract.FactKenID];
        const alleFactuurKenmerkenGelijk =
          selectieContracten.data!.length === 1
            ? true
            : selectieContracten.data!.every((cnt) => {
                const factuurKenmerk =
                  cnt.FactKenID === null
                    ? null
                    : dataBijID.data!.factuurKenmerkenBijID[cnt.FactKenID];
                return factuurKenmerk?.ID === factuurKenmerkVanContract?.ID;
              });
        const voorTeVullenFactuurKenmerkID = alleFactuurKenmerkenGelijk
          ? factuurKenmerkVanContract?.ID ?? null
          : null;

        await globaleRenderer.render((renderProps) => (
          <FactuurkenmerkSelectieDialoog
            factuurKenmerkenProvider={async () => {
              const result = await api.v2.factuur.rubriek.factuurkenmerk.ophalenFactuurkenmerken({
                filterSchema: {
                  filters: [
                    {
                      naam: 'REL_IDS',
                      data: [props.relID].filter((x) => x !== null),
                    },
                  ],
                },
              });

              return result.factuurkenmerken;
            }}
            initieelFactuurKenmerkIDProvider={async () => {
              return voorTeVullenFactuurKenmerkID;
            }}
            open
            onSuccess={async (result) => {
              await api.v2.contract.wijzigenFactuurkenmerkVanContracten({
                factKenID: result.factuurKenmerkID,
                cntIDs: store.selectieCntIDs,
              });
              await store.verversenContractWeergave();

              renderProps.destroy();
            }}
            onAnnuleren={() => renderProps.destroy()}
          />
        ));
      });
    }, [
      store.scopeBezigheid,
      selectieContractenHebbenDezelfdeLocatie,
      selectieContracten,
      dataBijID,
      store.selectieCntIDs,
      props.relID,
    ]);

    const handleToepassenActieOpContractenClick = useCallback(async () => {
      await store.scopeBezigheid(async () => {
        await globaleRenderer.render((renderProps) => (
          <ActieSelectieDialoog
            open
            onSuccess={async (data) => {
              const params = {
                tarActieID: data.tarActieID,
                cntIDs: store.selectieCntIDs,
              };
              const checkData = await api.v2.contract.checkToepassenActieOpContracten(params);
              const controleResult = await checkStore.controleren({
                checkData,
              });
              if (controleResult.type === EResultType.Annuleren) {
                return;
              }

              if (
                (
                  await checkStore.bevestigen({
                    inhoud: 'Wil je de actie toepassen op de geselecteerde contracten?',
                  })
                ).type === EResultType.Annuleren
              ) {
                return;
              }

              await api.v2.contract.toepassenActieOpContracten(params);

              await store.verversenContractWeergave();
              renderProps.destroy();
            }}
            onAnnuleren={() => renderProps.destroy()}
          />
        ));
      });
    }, [store.scopeBezigheid, props.relID, store.selectieCntIDs]);

    const handleVerwijderenActieVoorContractenClick = useCallback(async () => {
      await store.scopeBezigheid(async () => {
        const params = {
          cntIDs: store.selectieCntIDs,
        };

        const checkData = await api.v2.contract.checkVerwijderenActieOpContracten(params);
        if (
          (
            await checkStore.controleren({
              checkData,
            })
          ).type === EResultType.Annuleren
        ) {
          return;
        }

        if (
          (
            await checkStore.bevestigen({
              inhoud: `Wil je de acties voor de geselecteerde contracten verwijderen?`,
            })
          ).type === EResultType.Annuleren
        ) {
          return;
        }

        await api.v2.contract.verwijderenActieOpContracten(params);
        await store.verversenContractWeergave();
      });
    }, [store.scopeBezigheid, props.relID, store.selectieCntIDs]);

    const handleVerwijderenContractenClick = useCallback(async () => {
      if (selectieContracten.state === ERemoteDataState.Pending) {
        throw new Error('Contracten zijn nog aan het laden');
      }

      await store.scopeBezigheid(async () => {
        // Maak onderscheid tussen verwijderen van basis- en volgcontracten

        // const contractenBasis_CntIDs = context.contracten
        //   .filter((x) => x.Volgnummer === 0)
        //   .filter((x) => context.selectie.indexOf(x.CntID) !== -1)
        //   .map((x) => x.CntID);
        const contractenBasis_CntIDs = selectieContracten
          .data!.filter((x) => x.Volgnummer === 0)
          .map((x) => x.CntID);

        // const contractenVolg_CntIDs = context.contracten
        //   .filter((x) => x.Volgnummer !== 0)
        //   .filter((x) => context.selectie.indexOf(x.CntID) !== -1)
        //   .map((x) => x.CntID);
        const contractenVolg_CntIDs = selectieContracten
          .data!.filter((x) => x.Volgnummer !== 0)
          .map((x) => x.CntID);

        // Volgcontracten
        if (contractenVolg_CntIDs.length !== 0) {
          const cntIDs = contractenVolg_CntIDs;
          const checkData = await api.v2.contract.overname.checkVerwijderenOvernameContracten({
            cntIDs,
          });
          const controleResult = await checkStore.controleren({
            checkData,
          });
          if (controleResult.type === EResultType.Annuleren) {
            return;
          }

          const resultaat = await checkStore.bevestigen({
            inhoud: `Overnamecontract(en) verwijderen?`,
          });
          if (resultaat.type === EResultType.Annuleren) {
            return;
          }

          await api.v2.contract.overname.verwijderenOvernameContracten({
            cntIDs,
          });
        }

        // Basiscontracten
        if (contractenBasis_CntIDs.length !== 0) {
          const cntIDs = contractenBasis_CntIDs;
          const checkData = await api.v2.contract.nieuw.checkVerwijderenContracten({
            cntIDs,
          });
          const controleResult = await checkStore.controleren({
            checkData,
          });
          if (controleResult.type === EResultType.Annuleren) {
            return;
          }

          const resultaat = await checkStore.bevestigen({
            inhoud: `Contract(en) verwijderen?`,
          });
          if (resultaat.type === EResultType.Annuleren) {
            return;
          }

          await api.v2.contract.nieuw.verwijderenContracten({
            cntIDs,
          });
        }

        store.setSelectieCntIDs((curr: number[]) => []);
        await store.verversenContractWeergave();
      });
    }, [store.scopeBezigheid, props.relID, selectieContracten, store.setSelectieCntIDs]);

    const handleStandaardLeveropdrachtenMakenClick = useCallback(async () => {
      await store.scopeBezigheid(async () => {
        await globaleRenderer.render((renderProps) => (
          <ToevoegenStandaardLeveropdrachtenDialoog
            open
            cntIDs={store.selectieCntIDs}
            onSuccess={() => renderProps.destroy()}
            onAnnuleren={() => renderProps.destroy()}
          />
        ));
      });
    }, [store.scopeBezigheid, store.selectieCntIDs]);

    const handleVerversenClick = useCallback(async () => {
      await store.verversenContractWeergave();
    }, []);

    const filters = useMemo<IFilter<EFilter>[]>(
      () => [
        {
          naam: EFilter.ZOEKTERM,
          altijdWeergevenInBalk: true,
          weergave: ZoektermFilter,
        },
        {
          naam: EFilter.IS_ACTUEEL,
          altijdWeergevenInBalk: true,
          weergave: () => <span>Actueel</span>,
        },
        {
          naam: EFilter.STATUS_NAAMENUM,
          altijdWeergevenInBalk: false,
          weergave: StatusFilter,
        },
        {
          naam: EFilter.REL_PERS_IDS,
          altijdWeergevenInBalk: false,
          weergave: PersoonFilter,
        },
        {
          naam: EFilter.FACTUURKENMERK,
          altijdWeergevenInBalk: false,
          weergave: FactuurkenmerkFilter,
        },
        {
          naam: EFilter.LOC_IDS,
          altijdWeergevenInBalk: true,
          weergave: LocatieFilter,
        },
        {
          naam: EFilter.LOCATIE_VERDIEPINGEN,
          altijdWeergevenInBalk: false,
          weergave: VerdiepingFilter,
        },
        {
          naam: EFilter.INTERNE_LOCATIE_NAMEN,
          altijdWeergevenInBalk: false,
          weergave: InterneLocatieFilter,
        },
        {
          naam: EFilter.COMPLEX,
          altijdWeergevenInBalk: true,
          weergave: ComplexFilter,
        },
      ],
      [],
    );

    const contextValue = useMemo<IOverzichtV2Context>(
      () => ({
        relID: props.relID,
        dataBijID,
      }),
      [props.relID],
    );

    return (
      <OverzichtV2Context.Provider value={contextValue}>
        <Root>
          <MenuLayout
            menu={
              <div className="d-flex flex-column">
                <div className="d-flex align-items-center">
                  <div
                    style={{
                      width: 100,
                    }}
                  >
                    <SelectieVak
                      aantal={store.selectieCntIDs.length}
                      totaalAantal={store.data.data?.result.cntIDsVoorWeergave.length ?? null}
                      onChange={(alles) => {
                        if (alles) {
                          if (store.data.state === ERemoteDataState.Pending) {
                            return;
                          }
                          const alleCntIDs = store.data.data!.result.cntIDsVoorWeergave;

                          store.setSelectieCntIDs((curr: number[]) => alleCntIDs);
                        } else {
                          store.setSelectieCntIDs((curr: number[]) => []);
                        }
                      }}
                      entiteitEnkelvoud="contract"
                      entiteitMeervoud="contracten"
                      disabled={store.isBezig}
                    />
                  </div>

                  <div className="mr-3">
                    <VerticaleScheidingslijn height={20} />
                  </div>

                  <button
                    className="btn btn-sm btn-light d-flex align-items-center"
                    style={{
                      border: `1px solid ${EKleur.LichtGrijs}`,
                    }}
                    onClick={handleNieuwContractToevoegenClick}
                    disabled={store.isBezig}
                  >
                    <IconToevoegen
                      style={{
                        width: 16,
                        height: 16,
                        fill: EKleur.Grijs,
                      }}
                    />
                    <span className="ml-1">Nieuw contract</span>
                  </button>

                  <button
                    className="ml-3 btn btn-sm btn-light d-flex align-items-center"
                    style={{
                      border: `1px solid ${EKleur.LichtGrijs}`,
                    }}
                    onClick={handleContractwisselClick}
                    disabled={store.isBezig || !heeftEnkeleSelectie}
                  >
                    <IconContractwissel
                      style={{
                        width: 16,
                        height: 16,
                        fill: EKleur.Grijs,
                      }}
                    />
                    <span className="ml-2">Contractwissel</span>
                  </button>

                  {bevestigingsverzoekKnopWeergeven && (
                    <button
                      className="ml-3 btn btn-sm btn-light d-flex align-items-center"
                      style={{
                        border: `1px solid ${EKleur.LichtGrijs}`,
                      }}
                      onClick={handleBevestigingsverzoekClick}
                      disabled={store.isBezig || !heeftSelectie}
                    >
                      <span>Bevestingsverzoek</span>
                    </button>
                  )}

                  {annulerenKnopWeergeven && (
                    <button
                      className="ml-3 btn btn-sm btn-light d-flex align-items-center"
                      style={{
                        border: `1px solid ${EKleur.LichtGrijs}`,
                      }}
                      onClick={handleAnnulerenClick}
                      disabled={store.isBezig || !heeftSelectie}
                    >
                      <IconCancel
                        style={{
                          width: 16,
                          height: 16,
                          fill: EKleur.Grijs,
                        }}
                      />
                      <span className="ml-2">Annuleren</span>
                    </button>
                  )}

                  {fiatterenKnopWeergeven && (
                    <button
                      className="ml-3 btn btn-sm btn-light d-flex align-items-center"
                      style={{
                        border: `1px solid ${EKleur.LichtGrijs}`,
                      }}
                      onClick={handleFiatterenClick}
                      disabled={store.isBezig || !heeftSelectie || fiatterenType.data === null}
                    >
                      <IconVink
                        style={{
                          width: 16,
                          height: 16,
                          fill: EKleur.Grijs,
                        }}
                      />
                      <span className="ml-2">Fiatteren</span>
                    </button>
                  )}

                  {beeindigenKnopWeergeven && (
                    <button
                      className="ml-3 btn btn-sm btn-light d-flex align-items-center"
                      style={{
                        border: `1px solid ${EKleur.LichtGrijs}`,
                      }}
                      onClick={handleBeeindigenClick}
                      disabled={store.isBezig || !heeftSelectie}
                    >
                      <IconVlag
                        style={{
                          width: 15,
                          height: 15,
                          fill: EKleur.Grijs,
                        }}
                      />
                      <span className="ml-2">Beëindigen</span>
                    </button>
                  )}

                  <button
                    className="ml-3 btn btn-sm btn-light d-flex align-items-center"
                    style={{
                      border: `1px solid ${EKleur.LichtGrijs}`,
                    }}
                    onClick={handleTransportopdrachtClick}
                    disabled={store.isBezig || !heeftEnkeleSelectie}
                  >
                    <IconTransport
                      style={{
                        width: 16,
                        height: 16,
                        fill: EKleur.Grijs,
                      }}
                    />
                    <span className="ml-2">Transportopdracht</span>
                  </button>

                  <div className="flex-fill" />

                  <ActieMenuKnop
                    acties={[
                      {
                        text: 'Verkoopvoorstellen maken',
                        onClick: handleVerkoopvoorstelenMakenClick,
                        disabled: store.isBezig || !heeftSelectie,
                        icon: (
                          <IconVerkoopvoorstel
                            style={{
                              width: 15,
                              height: 15,
                            }}
                          />
                        ),
                      },
                      'scheiding',
                      {
                        text: 'Annuleren contracten',
                        onClick: handleAnnulerenClick,
                        disabled: store.isBezig || !heeftSelectie,
                        icon: (
                          <IconCancel
                            style={{
                              width: 15,
                              height: 15,
                            }}
                          />
                        ),
                      },
                      {
                        text: 'Afkeuren contracten',
                        onClick: handleAfkeurenContractenClick,
                        disabled: store.isBezig || !heeftSelectie,
                        icon: (
                          <IconDuimOmlaag
                            style={{
                              width: 15,
                              height: 15,
                            }}
                          />
                        ),
                      },
                      {
                        text: 'Aanbieden voor overname',
                        onClick: handleAanbiedenVoorOvernameClick,
                        disabled: store.isBezig || !heeftSelectie,
                        icon: (
                          <IconAanbiedenOvername
                            style={{
                              width: 15,
                              height: 15,
                            }}
                          />
                        ),
                      },
                      {
                        text: 'Overnemen contracten',
                        onClick: handleOvernemenContractenClick,
                        icon: (
                          <IconContractovername
                            style={{
                              width: 15,
                              height: 15,
                            }}
                          />
                        ),
                      },
                      {
                        text: 'Verwijderen contracten',
                        onClick: handleVerwijderenContractenClick,
                        disabled: store.isBezig || !heeftSelectie,
                        icon: (
                          <IconVerwijderen
                            style={{
                              width: 15,
                              height: 15,
                            }}
                          />
                        ),
                      },
                      'scheiding',
                      {
                        text: 'Bevestiging beeïndiging versturen',
                        onClick: handleBevestigingBeeindigingVersturenClick,
                        disabled: store.isBezig || !heeftSelectie,
                        icon: (
                          <IconSend
                            style={{
                              width: 15,
                              height: 15,
                            }}
                          />
                        ),
                      },
                      {
                        text: 'Bevestiging nieuwe contracten versturen',
                        onClick: handleBevestigingNieuweContractenVersturenClick,
                        disabled: store.isBezig || !heeftSelectie,
                        icon: (
                          <IconSend
                            style={{
                              width: 15,
                              height: 15,
                            }}
                          />
                        ),
                      },
                      {
                        text: 'Bevestiging overname nieuwe huurder versturen',
                        onClick: handleBevestigingOvernameNieuweHuurderVersturenClick,
                        disabled: store.isBezig || !heeftSelectie,
                        icon: (
                          <IconSend
                            style={{
                              width: 15,
                              height: 15,
                            }}
                          />
                        ),
                      },
                      {
                        text: 'Bevestiging annulering versturen',
                        onClick: () => handleBevestigingAnnuleringVersturenClick(),
                        disabled: store.isBezig || !heeftSelectie,
                        icon: (
                          <IconSend
                            style={{
                              width: 15,
                              height: 15,
                            }}
                          />
                        ),
                      },
                      // 'scheiding',
                      // {
                      //   text: 'Prolongeren contracten',
                      //   onClick: handleProlongerenContractenClick,
                      //   // icon: (
                      //   //   <IconContractovername
                      //   //     style={{
                      //   //       width: 15,
                      //   //       height: 15,
                      //   //     }}
                      //   //   />
                      //   // ),
                      // },
                      'scheiding',
                      {
                        text: 'Prolongeren contracten',
                        onClick: handleProlongerenContractenClick,
                        // icon: (
                        //   <IconContractovername
                        //     style={{
                        //       width: 15,
                        //       height: 15,
                        //     }}
                        //   />
                        // ),
                      },
                      'scheiding',
                      {
                        text: 'Locatie wijzigen',
                        onClick: handleLocatieWijzigenClick,
                        disabled: store.isBezig || !heeftSelectie,
                        icon: (
                          <IconLocatie
                            style={{
                              width: 15,
                              height: 15,
                            }}
                          />
                        ),
                      },
                      {
                        text: 'Interne locatie wijzigen',
                        onClick: handleInterneLocatieWijzigenClick,
                        disabled:
                          store.isBezig ||
                          !heeftSelectie ||
                          selectieContractenHebbenDezelfdeLocatie.state ===
                            ERemoteDataState.Pending ||
                          selectieContractenHebbenDezelfdeLocatie.data === false,
                        icon: (
                          <IconInterneLocatie
                            style={{
                              width: 15,
                              height: 15,
                            }}
                          />
                        ),
                      },
                      {
                        text: 'Factuurkenmerk wijzigen',
                        onClick: handleFactuurKenmerkWijzigenClick,
                        disabled: store.isBezig || !heeftSelectie,
                        // ||
                        // selectieContractenHebbenDezelfdeFactuurkenmerk.state ===
                        //   ERemoteDataState.Pending ||
                        // selectieContractenHebbenDezelfdeFactuurkenmerk.data === false,
                        // icon: (
                        //   <IconInterneLocatie
                        //     style={{
                        //       width: 15,
                        //       height: 15,
                        //     }}
                        //   />
                        // ),
                      },
                      'scheiding',
                      {
                        text: 'Toepassen actie op contract(en)',
                        onClick: handleToepassenActieOpContractenClick,
                        disabled: store.isBezig || !heeftSelectie,
                        icon: (
                          <IconTariefactie
                            style={{
                              width: 15,
                              height: 15,
                            }}
                          />
                        ),
                      },
                      {
                        text: 'Verwijderen actie voor contract(en)',
                        onClick: handleVerwijderenActieVoorContractenClick,
                        disabled: store.isBezig || !heeftSelectie,
                        icon: (
                          <IconVerwijderen
                            style={{
                              width: 15,
                              height: 15,
                            }}
                          />
                        ),
                      },
                      {
                        text: 'Standaard leveropdrachten maken',
                        onClick: handleStandaardLeveropdrachtenMakenClick,
                        disabled: store.isBezig || !heeftSelectie,
                        icon: (
                          <IconTransport
                            style={{
                              width: 15,
                              height: 15,
                            }}
                          />
                        ),
                      },
                      'scheiding',
                      {
                        text: 'Verversen',
                        onClick: handleVerversenClick,
                        disabled: store.isBezig,
                        icon: (
                          <IconVernieuwen
                            style={{
                              width: 15,
                              height: 15,
                            }}
                          />
                        ),
                      },
                    ]}
                  />
                </div>

                <div
                  className="d-flex align-items-center mt-2"
                  style={{
                    position: 'relative',
                    left: -7,
                  }}
                >
                  <FilterBalkV2
                    filters={filters}
                    filterData={store.filterData}
                    onFilterDataChange={(x) => {
                      store.setFilterData((prev: IFilterData<EFilter>[]) => x);
                      setUrlStateSync('filterData', x);
                    }}
                    onFilterSchemaChange={async () => store.verversenContractWeergave()}
                  />
                </div>
              </div>
            }
            body={<Body {...props} store={store} dataBijID={dataBijID} />}
          />
        </Root>
      </OverzichtV2Context.Provider>
    );
  },
);

interface IBodyProps extends IProps {
  store: IOverzichtV2Store;
  dataBijID: IRemoteData<IDataBijID>;
}

const Body = (props: IBodyProps): JSX.Element => {
  const { store } = props;

  if (
    store.data.state === ERemoteDataState.Pending ||
    props.dataBijID.state === ERemoteDataState.Pending ||
    store.data.data!.relID !== props.relID
  ) {
    return (
      <div className="d-flex flex-fill align-items-center justify-content-center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <LocatieTabel
      relID={props.relID}
      data={store.data.data!}
      dataBijID={props.dataBijID.data!}
      uitgeklapt={store.uitgeklapteLocIDs}
      onUitgeklaptChange={store.setUitgeklaptLocIDs}
      isBezig={store.isBezig}
    />
  );
};

const OverzichtV2WithRouter = withRouter(OverzichtV2);

export default OverzichtV2WithRouter;
