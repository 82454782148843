import React, { Dispatch, SetStateAction, useMemo, useState } from 'react';
import IDialoogProps from '../../../core/IDialoogProps';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import Tabblad, { ITabblad } from '../../layout/Tabblad';
import RecentTabblad from './RecentTabblad';
import OrganisatiesTabblad from './OrganisatiesTabblad';
import ServicemeldingenTabblad from './ServicemeldingenTabblad';
import GepindTabblad from './GepindTabblad';
import SelecterenTabblad, { IFormikValues, ISelecterenSuggesties } from './SelecterenTabblad';
import { IFilterSchema } from '../../../../../shared/src/models/filter';
import { ETekstPrecisie } from '../../formulier/TekstPrecisieSelectie';
import { IOphalenRelatiesResultElementV2 } from '../../../../../shared/src/api/v2/relatie';
import api from '../../../api';
import Dialoog from '../../dialogen/Dialoog';
import { ERelatiehoedanigheid } from '../../../bedrijfslogica/enums';
import SpeciaalTabblad from './SpeciaalTabblad';

export enum ETabblad {
  Recent,
  Gepind,
  Selecteren,
  Organisaties,
  Servicemeldingen,
  Speciaal,
}

export interface IRelatieSelectieDialoogResult {
  relID: number;
  relatie: IOphalenRelatiesResultElementV2;
}

export enum ERelatieSoort {
  Beide,
  Persoon,
  Organisatie,
}

export enum EHoedanigheid {
  Beide,
  Klant,
  Leverancier,
}

export const hoedanigheidMap: Record<EHoedanigheid, string> = {
  [EHoedanigheid.Beide]: 'Beide',
  [EHoedanigheid.Klant]: 'Klant',
  [EHoedanigheid.Leverancier]: 'Leverancier',
};

export const hoedanigheidKeyMap: Partial<Record<EHoedanigheid, string>> = {
  [EHoedanigheid.Klant]: 'KLANT',
  [EHoedanigheid.Leverancier]: 'LEVERANCIER',
};

export interface IKoppelOpties {
  relatieSoort?: ERelatieSoort;
  hoedanigheid?: EHoedanigheid;
  relatieFilterSchema?: IFilterSchema;
  defaultSelecterenValues?: IFormikValues;
  selecterenSuggesties?: ISelecterenSuggesties;
}

interface IProps extends IDialoogProps<IRelatieSelectieDialoogResult> {
  koppelOpties?: IKoppelOpties;
  defaultTabblad?: ETabblad;
}

export interface IRelatieSelectieDialoogContext {
  koppelOpties: IKoppelOpties;
  onGekozen: (relID: number) => void;
  hoedanigheid: EHoedanigheid;
  onHoedanigheidChange: (hoedanigheid: EHoedanigheid) => void;
  selecterenValues: IFormikValues;
  setSelecterenValues: Dispatch<SetStateAction<IFormikValues>>;
  selecterenSuggesties: ISelecterenSuggesties;
}

export const defaultSelecterenValues: IFormikValues = {
  email: '',
  emailTekstprecisie: ETekstPrecisie.Ongeveer,
  iban: '',
  rekeningnaam: '',
  postcode: '',
  naam: '',
  naamTekstprecisie: ETekstPrecisie.Ongeveer,
  woonplaats: '',
  hoedanigheid: 'beide',
  huisnummer: '',
  telefoonnummer: '',
  telefoonnummerTekstprecisie: ETekstPrecisie.Ongeveer,
  straatnaam: '',
  referentiecode: '',
  contractnummer: '',
  factuurnummer: '',
  relatienummer: '',
  actieveContracten: true,
  voornaam: '',
  voornaamTekstprecisie: ETekstPrecisie.Ongeveer,
  transportopdrachtnummer: '',
  meldnummer: '',
  serviceopdrachtnummer: '',
  gebruikersnaam: '',
  geslacht: null,
  kvkNummer: '',
  wervingscode: '',
};

export const RelatieSelectieDialoogContext = React.createContext<IRelatieSelectieDialoogContext>(
  null as any,
);

const RelatieSelectieDialoog: React.FC<IProps> = (props) => {
  const { children, onAnnuleren, onSuccess, open } = props;

  const defaultSelecterenValuesMod: IFormikValues = useMemo<IFormikValues>(
    () => ({
      ...defaultSelecterenValues,
      hoedanigheid:
        props.koppelOpties === undefined ||
        props.koppelOpties.hoedanigheid === undefined ||
        props.koppelOpties.hoedanigheid === EHoedanigheid.Beide
          ? 'beide'
          : props.koppelOpties.hoedanigheid === EHoedanigheid.Klant
          ? ERelatiehoedanigheid.Klant
          : ERelatiehoedanigheid.Leverancier,
    }),
    [props.koppelOpties],
  );

  const defaultKoppelOpties = useMemo<IKoppelOpties>(
    () => ({
      relatieSoort: ERelatieSoort.Beide,
      hoedanigheid: EHoedanigheid.Beide,
      defaultSelecterenValues,
    }),
    [defaultSelecterenValues],
  );

  const koppelOpties = useMemo(() => ({ ...defaultKoppelOpties, ...props.koppelOpties }), [
    props.koppelOpties,
  ]);

  const [tabblad, setTabblad] = useState<ETabblad>(
    props.defaultTabblad === undefined ? ETabblad.Selecteren : props.defaultTabblad,
  );
  const [selecterenValues, setSelecterenValues] = useState(defaultSelecterenValuesMod);

  const tabbladen = useMemo<ITabblad<ETabblad>[]>(
    () => [
      {
        id: ETabblad.Selecteren,
        label: 'Selecteren',
        content: SelecterenTabblad,
      },
      {
        id: ETabblad.Recent,
        label: 'Recent',
        content: RecentTabblad,
      },
      // {
      //   id: ETabblad.Organisaties,
      //   label: 'Klant-organisaties',
      //   content: OrganisatiesTabblad,
      // },
      // {
      //   id: ETabblad.Servicemeldingen,
      //   label: 'Servicemeldingen',
      //   content: ServicemeldingenTabblad,
      // },
      {
        id: ETabblad.Gepind,
        label: 'Gepind',
        content: GepindTabblad,
      },
      {
        id: ETabblad.Speciaal,
        label: 'Speciaal',
        content: SpeciaalTabblad,
      },
    ],
    [onSuccess],
  );
  const [hoedanigheid, setHoedanigheid] = useState(koppelOpties.hoedanigheid!);

  const context = useMemo<IRelatieSelectieDialoogContext>(() => {
    return {
      koppelOpties,
      hoedanigheid,
      onHoedanigheidChange: (x) => setHoedanigheid(x),
      onGekozen: async (relID) => {
        const result = await api.v2.relatie.ophalenRelaties({
          filterSchema: {
            filters: [
              {
                naam: 'IDS',
                data: [relID],
              },
            ],
          },
        });
        const relatie = result.relaties[0]!;
        props.onSuccess({ relID, relatie });
      },
      selecterenValues,
      setSelecterenValues,
      selecterenSuggesties: koppelOpties.selecterenSuggesties ?? {},
    };
  }, [
    koppelOpties,
    props.onSuccess,
    hoedanigheid,
    setHoedanigheid,
    selecterenValues,
    setSelecterenValues,
    koppelOpties.selecterenSuggesties,
  ]);

  return (
    <RelatieSelectieDialoogContext.Provider value={context}>
      <Dialoog
        modalProps={{ size: 'xl' }}
        index={props.dialoogIndex ?? 0}
        onEscape={props.onAnnuleren}
      >
        <ModalHeader>
          <ModalTitle>Relatie selecteren</ModalTitle>
        </ModalHeader>
        <ModalBody className="p-0">
          <Tabblad
            geselecteerd={tabblad}
            onSelectieChange={(id) => setTabblad(id)}
            tabbladen={tabbladen}
          />
        </ModalBody>
        <ModalFooter className="d-flex justify-content-start mt-2">
          <button className="btn btn-secondary" style={{ width: 100 }} onClick={onAnnuleren}>
            Annuleren
          </button>
        </ModalFooter>
      </Dialoog>
    </RelatieSelectieDialoogContext.Provider>
  );
};

export default RelatieSelectieDialoog;
