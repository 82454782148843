import React, { useContext, useEffect, useMemo, useState, useCallback } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import LoadingSpinner from '../../../components/Gedeeld/LoadingSpinner';
import useUrlState from '../../../core/useUrlState';
import api from '../../../api';
import _ from 'lodash';
import { IOphalenDubieuzeAdressenResultElement } from '../../../../../shared/src/api/v2/relatie/acceptatie';
import {
  DXTableCheckboxComponent,
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
  DXTableToggleCellComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../helpers/dxTableGrid';
import {
  Grid,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  DataTypeProvider,
  EditingState,
  RowDetailState,
  SelectionState,
} from '@devexpress/dx-react-grid';
import nameOf from '../../../core/nameOf';
import { RootStoreContext } from '../../../stores/RootStore';
import { EResultType } from '../../../stores/CheckStore';
import ToevoegenDialoog from './ToevoegenDialoog';
import WijzigenDialoog from './WijzigenDialoog';

interface IProps extends RouteComponentProps {}

interface IToevoegenAdresDialoog {}
interface IWijzigenAdresDialoog {
  id: number;
}

interface IUrlState {
  toevoegenAdresDialoog: IToevoegenAdresDialoog | null;
  wijzigenAdresDialoog: IWijzigenAdresDialoog | null;
}

const defaultUrlState: IUrlState = { toevoegenAdresDialoog: null, wijzigenAdresDialoog: null };

const DubieuzeAdressen: React.FC<IProps> = observer((props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const { checkStore } = useContext(RootStoreContext);

  const [adressen, setAdressen] = useState<IOphalenDubieuzeAdressenResultElement[] | null>(null);

  const ophalenAdressen = useCallback(async () => {
    const adressen = (
      await api.v2.relatie.acceptatie.ophalenDubieuzeAdressen({
        // filterSchema: {
        //   filters: [
        //     // { naam: 'STATUS_NAAMENUM', data: [EContractStatus.Geannuleerd] },
        //   ],
        // },
        orderSchema: {
          orders: [
            {
              naam: 'POSTCODE',
              richting: 'ASC',
            },
          ],
        },
      })
    ).adressen;

    setAdressen(adressen);
  }, []);

  useEffect(() => {
    ophalenAdressen();
  }, [ophalenAdressen]);

  const keyExtractor = useCallback((row: IOphalenDubieuzeAdressenResultElement) => row.ID, []);

  const kolommen = useMemo<TypedColumn<IOphalenDubieuzeAdressenResultElement>[]>(
    () => [
      {
        name: 'Postcode',
        title: 'Postcode',
      },
      {
        name: 'Huisnummer',
        title: 'Huisnummer',
      },
      {
        name: 'Bisnummer',
        title: 'Bisnummer',
      },
      {
        name: 'Straatnaam',
        title: 'Straatnaam',
      },
      {
        name: 'Plaatsnaam',
        title: 'Plaatsnaam',
      },
      {
        name: 'Geblokkeerd',
        title: 'Geblokkeerd',
      },
      {
        name: 'Notities',
        title: 'Notities',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IOphalenDubieuzeAdressenResultElement>[]>(
    () => [
      {
        columnName: 'Postcode',
        width: 100,
      },
      {
        columnName: 'Huisnummer',
        width: 100,
      },
      {
        columnName: 'Bisnummer',
        width: 100,
      },
      {
        columnName: 'Straatnaam',
        width: 230,
      },
      {
        columnName: 'Plaatsnaam',
        width: 150,
      },
      {
        columnName: 'Notities',
        width: 400,
      },
      {
        columnName: 'Geblokkeerd',
        width: 100,
      },
    ],
    [],
  );

  return (
    <>
      {adressen === null ? (
        <div className="d-flex flex-fill align-items-center justify-content-center">
          <LoadingSpinner />
        </div>
      ) : (
        <GridStyleWrapper height={'calc(100vh - 150px)'}>
          <Grid rows={adressen} columns={kolommen} getRowId={keyExtractor}>
            {/* <DataTypeProvider
              for={[nameOf<IOphalenDubieuzeAdressenResultElement>('Postcode')]}
              formatterComponent={(formatterProps) => <span>{formatterProps.value}</span>}
            /> */}

            <DataTypeProvider
              for={[nameOf<IOphalenDubieuzeAdressenResultElement>('Geblokkeerd')]}
              formatterComponent={(formatterProps) => (
                <span>{formatterProps.value ? 'Ja' : 'Nee'}</span>
              )}
            />

            {/* <SelectionState
              selection={props.actieclaimsSelectie}
              onSelectionChange={(value) => props.onActieclaimsSelectieChange(value as number[])}
            /> */}

            <RowDetailState defaultExpandedRowIds={[]} />

            <VirtualTable messages={{ noData: 'Geen adressen gevonden' }} />
            <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
            <TableHeaderRow />

            <EditingState
              onCommitChanges={async (changes) => {
                if (changes.deleted === undefined) {
                  return;
                }
                const deleted = changes.deleted;
                const id = deleted[deleted.length - 1] as number;

                if (
                  (
                    await checkStore.bevestigen({
                      inhoud: `Wil je dit adres verwijderen?`,
                    })
                  ).type === EResultType.Annuleren
                ) {
                  return;
                }

                await api.v2.relatie.acceptatie.verwijderenDubieuzeAdressen({ ids: [id] });

                ophalenAdressen();
              }}
              onEditingRowIdsChange={(rowIds) => {
                const id = rowIds[rowIds.length - 1] as number;
                setUrlStateSync('wijzigenAdresDialoog', { id });
              }}
              onAddedRowsChange={() => {
                alert('TODO');
                // return setUrlStateSync('toevoegenAdresDialoog', {});
              }}
            />

            <TableEditColumn
              width={65}
              showDeleteCommand
              showEditCommand
              showAddCommand
              cellComponent={DXTableEditColumnCellComponent}
              commandComponent={DXTableEditColumnCommandComponent}
            />

            {/* <TableSelection cellComponent={DXTableCheckboxComponent} /> */}
          </Grid>
        </GridStyleWrapper>
      )}
      {urlState.toevoegenAdresDialoog && (
        <ToevoegenDialoog
          open
          onSuccess={() => setUrlStateSync('toevoegenAdresDialoog', null)}
          onAnnuleren={() => setUrlStateSync('toevoegenAdresDialoog', null)}
        />
      )}
      {urlState.wijzigenAdresDialoog && (
        <WijzigenDialoog
          open
          id={urlState.wijzigenAdresDialoog.id}
          onSuccess={() => {
            ophalenAdressen();
            setUrlStateSync('wijzigenAdresDialoog', null);
          }}
          onAnnuleren={() => setUrlStateSync('wijzigenAdresDialoog', null)}
        />
      )}
    </>
  );
});

export default withRouter(DubieuzeAdressen);
