import React, { useEffect, useMemo, useState } from 'react';
import IDialoogProps from '../../../core/IDialoogProps';
import Dialoog from '../Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import Modal from 'react-bootstrap/Modal';
import ModalTitle from 'react-bootstrap/ModalTitle';
import { GridStyleWrapper, TypedColumn } from '../../../helpers/dxTableGrid';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import { Column, DataTypeProvider } from '@devexpress/dx-react-grid';
import LoadingSpinner from '../../Gedeeld/LoadingSpinner';

export interface IDialoogResult<TEntity> {
  entiteit: TEntity;
}

interface IKolom<TEntity> {
  key: keyof TEntity;
  label: string | JSX.Element;
  breedte: number;
  formatFabriek?: (entiteit: TEntity) => string | JSX.Element;
}

interface IProps<TEntity> extends IDialoogProps<IDialoogResult<TEntity>> {
  titel?: string | JSX.Element;
  resolveOpties: () => Promise<TEntity[]>;
  kolommen: IKolom<TEntity>[];
  keyExtractor: (entiteit: TEntity) => number | string;
  filterSectie?: React.ComponentType;
}

const SelectieDialoog = <TEntity extends {}>(props: IProps<TEntity>) => {
  const [opties, setOpties] = useState<TEntity[] | null>(null);
  const FilterSectie = props.filterSectie || null;

  useEffect(() => {
    (async () => {
      const result = await props.resolveOpties();
      setOpties(result);
    })();
  }, [props.resolveOpties]);

  const columns = useMemo<Column[]>(
    () => [
      ...props.kolommen.map((kolom) => ({
        name: kolom.key as string,
        title: kolom.label as any,
      })),
      {
        name: '__kies_actie',
        title: ' ',
      },
    ],
    [props.kolommen],
  );

  const kolomBreedtes = useMemo(
    () => [
      ...props.kolommen.map((kolom) => ({
        columnName: kolom.key as string,
        width: kolom.breedte,
      })),
      {
        columnName: '__kies_actie',
        width: 100,
      },
    ],
    [props.kolommen],
  );

  const dataTypeProviders = useMemo(() => {
    return props.kolommen
      .filter((x) => x.formatFabriek !== undefined)
      .map((kolom) => (
        <DataTypeProvider
          key={kolom.key as string}
          for={[kolom.key as string]}
          formatterComponent={(formatterProps) => {
            const entity = formatterProps.row as TEntity;
            return <div>{kolom.formatFabriek!(entity)}</div>;
          }}
        />
      ));
  }, [props.kolommen]);

  return (
    <Dialoog index={props.dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>{props.titel === undefined ? 'Selecteer' : props.titel}</ModalTitle>
      </ModalHeader>
      <ModalBody className="p-0">
        {FilterSectie !== null && (
          <div className="flex-fill d-flex flex-column">
            <FilterSectie />
          </div>
        )}
        <div className="flex-fill">
          {opties === null ? (
            <div className="p-4 d-flex align-items-center justify-content-center">
              <LoadingSpinner />
            </div>
          ) : (
            <GridStyleWrapper maxHeight={500} rowAmount={opties.length}>
              <Grid rows={opties} columns={columns} getRowId={props.keyExtractor}>
                {dataTypeProviders}
                <DataTypeProvider
                  for={['__kies_actie']}
                  formatterComponent={(formatterProps) => {
                    const entiteit = formatterProps.row as TEntity;
                    return (
                      <a
                        href="#"
                        onClick={(ev) => {
                          ev.stopPropagation();
                          props.onSuccess({
                            entiteit,
                          });
                        }}
                      >
                        Kies
                      </a>
                    );
                  }}
                />
                <VirtualTable
                  rowComponent={(rowProps) => (
                    <tr
                      style={{ cursor: 'pointer' }}
                      onDoubleClick={() => {
                        const entiteit: TEntity = rowProps.row;
                        props.onSuccess({ entiteit });
                      }}
                    >
                      {rowProps.children}
                    </tr>
                  )}
                />

                <TableColumnResizing columnWidths={kolomBreedtes} />
                <TableHeaderRow />
              </Grid>
            </GridStyleWrapper>
          )}
        </div>
      </ModalBody>
      <ModalFooter className="d-flex justify-content-start mt-2">
        <button className="btn btn-secondary" style={{ width: 100 }} onClick={props.onAnnuleren}>
          Annuleren
        </button>
      </ModalFooter>
    </Dialoog>
  );
};

export default SelectieDialoog;
