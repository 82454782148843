import styled from 'styled-components';
import * as React from 'react';
import { ESortering } from '../../types';
import { IconPijlOmhoog, IconPijlOmlaag } from '../../../../Icons';
import { Kleur } from '../../../../../bedrijfslogica/constanten';

export const Root = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 15px;
  user-select: none;
`;

interface IProps {
  style?: React.CSSProperties;
  sortering?: ESortering;
  sorteringsnummer?: number;
  sorteringStyle?: React.CSSProperties;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
}

const TableHeading: React.FC<IProps> = (props) => {
  return (
    <Root
      style={{
        cursor: props.onClick ? 'pointer' : 'default',
        ...props.style,
      }}
      onClick={props.onClick}
    >
      {props.children}

      {props.sortering !== undefined && (
        <span
          style={{ marginLeft: 10, display: 'flex', alignItems: 'center', ...props.sorteringStyle }}
        >
          {props.sortering === ESortering.Ascending ? (
            <IconPijlOmhoog style={{ fill: Kleur.ZwembadBlauw, width: 16, height: 16 }} />
          ) : (
            <IconPijlOmlaag style={{ fill: Kleur.ZwembadBlauw, width: 16, height: 16 }} />
          )}
          {props.sorteringsnummer !== undefined && (
            <span
              style={{
                marginLeft: 2,
                display: 'flex',
                alignItems: 'center',
                color: Kleur.ZwembadBlauw,
                fontSize: 12,
              }}
            >
              {props.sorteringsnummer}
            </span>
          )}
        </span>
      )}
    </Root>
  );
};

export default TableHeading;
