import React, { useContext, useEffect, useMemo, useState, useCallback } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import MenuLayout from '../../../components/MenuLayout';
import LoadingSpinner from '../../../components/Gedeeld/LoadingSpinner';
import useUrlState from '../../../core/useUrlState';
import api from '../../../api';
import { IOphalenRelatiesResultElementV1 } from '../../../../../shared/src/api/v2/relatie';
import { IOphalenMutatiesResultElement } from '../../../../../shared/src/api/v2/relatie/mutatie';
import {
  DXTableToggleCellComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../helpers/dxTableGrid';
import { DataTypeProvider, RowDetailState } from '@devexpress/dx-react-grid';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  TableRowDetail,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import { addMonths, format } from 'date-fns';
import nameof from '../../../core/nameOf';
import DetailComp from '../../Klant/Klantkaart/Entiteiten/entiteiten/Klant/Mutaties';
import _ from 'lodash';
import RelatieVisualisatie from '../../../components/personalia/RelatieVisualisatie';
import { IOphalenLoggingResultElement } from '../../../../../shared/src/api/v2/relatie/account';
import { IOphalenAccountsResultElement } from '../../../../../shared/src/api/v2/account/account';
import PersoonVisualisatie from '../../../components/personalia/PersoonVisualisatie';
import { EAccLogSoort } from '../../../bedrijfslogica/enums';

interface IProps extends RouteComponentProps {}

interface IUrlState {}

const defaultUrlState: IUrlState = {};

export interface IRegel extends IOphalenLoggingResultElement {
  ID: number;
}

export interface IAccount extends IOphalenAccountsResultElement {}

const AccountLogging: React.FC<IProps> = observer((props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);

  const [loggingen, setLoggingen] = useState<IRegel[] | null>(null);
  const [accounts, setAccounts] = useState<IAccount[] | null>(null);

  const ophalenLoggingen = useCallback(async () => {
    // const datumVan = addMonths(new Date(), -6); // Laatste 6 maanden

    const inloggingenResult = await api.v2.account.ophalenLogging({
      filterSchema: {
        filters: [].filter((x) => x !== null),
      },
      paginatie: { index: 0, aantal: 250 },
    });

    const loggingenGesorteerd = _.orderBy(loggingen, ['Datum'], ['desc']);
    setLoggingen(inloggingenResult.logging);
  }, []);

  useEffect(() => {
    ophalenLoggingen();
  }, [ophalenLoggingen]);

  const ophalenAccounts = useCallback(async () => {
    if (loggingen === null) {
      return;
    }
    const accountsResult = await api.v2.account.ophalenAccounts({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: loggingen.map((x) => x.AccID),
          },
        ],
      },
    });

    const relaties = (
      await api.v2.relatie.ophalenRelaties({
        filterSchema: {
          filters: [
            {
              naam: 'IDS',
              data: accountsResult.map((x) => x.RelID),
            },
          ],
        },
      })
    ).relaties;

    setAccounts(accountsResult);
  }, [loggingen]);

  useEffect(() => {
    ophalenAccounts();
  }, [ophalenAccounts]);

  const keyExtractor = useCallback((row: IRegel) => row.ID, []);

  const kolommen = useMemo<TypedColumn<IRegel>[]>(
    () => [
      {
        name: 'Registratiedatum',
        title: 'Datum',
      },
      {
        name: '__soort' as any,
        title: 'Soort',
      },
      {
        name: '__gebruikersnaam' as any,
        title: 'Gebruikersnaam',
      },
      {
        name: '__persoon' as any,
        title: 'Persoon',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRegel>[]>(
    () => [
      {
        columnName: 'Registratiedatum',
        width: 150,
      },
      {
        columnName: '__soort' as any,
        width: 200,
      },
      {
        columnName: '__gebruikersnaam' as any,
        width: 325,
      },
      {
        columnName: '__persoon' as any,
        width: 300,
      },
    ],
    [],
  );

  return (
    <>
      {loggingen === null || accounts === null ? (
        <div className="d-flex flex-fill align-items-center justify-content-center">
          <LoadingSpinner />
        </div>
      ) : (
        <MenuLayout
          // menu={<span></span>}
          body={
            <GridStyleWrapper height="calc(100vh - 150px)">
              <Grid rows={loggingen} columns={kolommen} getRowId={keyExtractor}>
                <DataTypeProvider
                  for={[nameof<IRegel>('Registratiedatum')]}
                  formatterComponent={(formatterProps) => {
                    return (
                      <span>{format(new Date(formatterProps.value), 'dd-MM-yyyy HH:mm')}</span>
                    );
                  }}
                />

                <DataTypeProvider
                  for={['__gebruikersnaam']}
                  formatterComponent={(formatterProps) => {
                    const rij: IRegel = formatterProps.row;
                    const account = accounts.find((x) => x.AccID === rij.AccID)!;
                    return <span>{account.Gebruikersnaam}</span>;
                  }}
                />

                <DataTypeProvider
                  for={['__persoon']}
                  formatterComponent={(formatterProps) => {
                    const rij: IRegel = formatterProps.row;
                    const account = accounts.find((x) => x.AccID === rij.AccID)!;
                    return (
                      <PersoonVisualisatie
                        persID={account.PersID}
                        // options={{ geenLinkToepassen: true }}
                      />
                    );
                  }}
                />

                <DataTypeProvider
                  for={['__soort']}
                  formatterComponent={(formatterProps) => {
                    const rij: IRegel = formatterProps.row;

                    let soort;
                    switch (rij.Soort) {
                      case EAccLogSoort.Inloggen:
                        soort = 'Ingelogd';
                        break;
                      case EAccLogSoort.Activeren:
                        soort = 'Geactiveerd';
                        break;
                      case EAccLogSoort.NieuwWachtwoord:
                        soort = 'Nieuw wachtwoord';
                        break;
                      case EAccLogSoort.Uitloggen:
                        soort = 'Uitgelogd';
                        break;
                      case EAccLogSoort.WachtwoordGewijzigd:
                        soort = 'Wachtwoord gewijzigd';
                        break;
                      case EAccLogSoort.WachtwoordVergeten:
                        soort = 'Wachtwoord vergeten';
                        break;
                      default:
                        soort = '';
                        break;
                    }

                    return <span>{soort}</span>;
                  }}
                />

                <VirtualTable
                  messages={{
                    noData: 'Geen loggingen',
                  }}
                />
                <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
                <TableHeaderRow />

                <RowDetailState defaultExpandedRowIds={[]} />
                {/* <TableRowDetail
                  toggleCellComponent={DXTableToggleCellComponent}
                  contentComponent={DetailComp}
                /> */}
              </Grid>
            </GridStyleWrapper>
          }
        />
      )}
    </>
  );
});

export default withRouter(AccountLogging);
