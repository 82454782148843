import React, { HTMLProps, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { clamp } from '../../../helpers/getal';
import { IconInklappen, IconUitklappen } from '../../Icons';
import { Kleur } from '../../../bedrijfslogica/constanten';
import useBijGewijzigdEffect from '../../../core/useBijGewijzigdEffect';

interface IProps {
  waarde: number;
  onChange: (waarde: number) => void;
  min?: number;
  max?: number;
  inactiefRepresentatie?: (input: string) => string;
  rootProps?: HTMLProps<HTMLInputElement>;
  onFocus?: () => void;
  onBlur?: () => void;
  disabled?: boolean;
}

const NumeriekVeld: React.FC<IProps> = (props) => {
  const { children, onChange, waarde, min, max, rootProps } = props;
  const waardeRef = useRef(waarde);

  // Deze waarde wordt intern gebruikt voor state van het veld (aangezien het input veld met strings
  // werkt. Als we dit niet zouden doen dan is het onmogelijk om het veld te legen.
  const [text, setText] = useState(String(waarde));
  const inputHasFocusRef = useRef(false);
  const [inputHasFocusState, setInputHasFocusState] = useState(inputHasFocusRef.current);
  const setInputHasFocus = useCallback((focus: boolean) => {
    inputHasFocusRef.current = focus;
    setInputHasFocusState(focus);
  }, []);
  const verhogenHasFocusRef = useRef(false);
  const [verhogenHasFocusState, setVerhogenHasFocusState] = useState(verhogenHasFocusRef.current);
  const setVerhogenHasFocus = useCallback((focus: boolean) => {
    verhogenHasFocusRef.current = focus;
    setVerhogenHasFocusState(focus);
  }, []);
  const verlagenHasFocusRef = useRef(false);
  const [verlagenHasFocusState, setVerlagenHasFocusState] = useState(verlagenHasFocusRef.current);
  const setVerlagenHasFocus = useCallback((focus: boolean) => {
    verlagenHasFocusRef.current = focus;
    setVerlagenHasFocusState(focus);
  }, []);
  const bepaalHeeftFocus = useCallback(
    () => inputHasFocusRef.current || verhogenHasFocusRef.current || verlagenHasFocusRef.current,
    [],
  );

  const checkFocus = useCallback(async () => {
    let res: (value: boolean) => void;
    const promise = new Promise<boolean>((resolve, reject) => {
      res = resolve;
    });
    const heeftFocusEersteMeetpunt = bepaalHeeftFocus();
    setTimeout(() => {
      const heeftFocusTweedeMeetpunt = bepaalHeeftFocus();
      res(heeftFocusEersteMeetpunt === heeftFocusTweedeMeetpunt);
    }, 1);

    const focusGewijzigd = await promise;
    if (!focusGewijzigd) {
      return;
    }

    if (heeftFocusEersteMeetpunt) {
      props.onFocus?.();
    } else {
      props.onBlur?.();
    }
  }, [inputHasFocusState, verhogenHasFocusState, verlagenHasFocusState, props.onFocus]);

  useBijGewijzigdEffect(() => {
    checkFocus();
  }, [checkFocus]);

  useEffect(() => {
    const textNum = Number(text);
    if (isNaN(textNum)) {
      setText(String(text));
      return;
    }
    if (textNum === waarde) {
      return;
    }
    setText(String(text));
  }, [text]);

  useEffect(() => {
    const replaced = text.replace(',', '.');
    const textNum = Number(replaced);
    if (isNaN(textNum) || waardeRef.current === textNum) {
      return;
    }
    trySetValue(textNum);
  }, [text]);

  const trySetValue = useCallback(
    (value: number, immediate?: boolean) => {
      const newValue = clamp(
        value,
        min === undefined ? -Number.MAX_VALUE : min,
        max === undefined ? Number.MAX_VALUE : max,
      );
      waardeRef.current = newValue;
      onChange(newValue);
      if (immediate) {
        setText(String(newValue));
      }
    },
    [min, max, onChange, setText],
  );

  useEffect(() => {
    if (waardeRef.current === waarde) {
      return;
    }
    waardeRef.current = waarde;
    setText(String(waarde));
  }, [waarde]);

  // useEffect(() => {
  //   setText(String(waarde));
  // }, [focused]);

  const handleVerhogen = useCallback(() => {
    const newValue = waarde + 1;
    trySetValue(newValue, true);
  }, [waarde, trySetValue]);

  const handleVerlagen = useCallback(() => {
    const newValue = waarde - 1;
    trySetValue(newValue, true);
  }, [waarde, trySetValue]);

  const handleChange = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      const trimmedValue = ev.target.value.trim();
      if (trimmedValue === '-') {
        setText('-');
        return;
      }

      const numValue = Number(trimmedValue);
      if (isNaN(numValue)) {
        return;
      }
      setText(trimmedValue);
    },
    [setText],
  );

  const handleBlur = useCallback(() => {
    setInputHasFocus(false);

    const numValue = Number(text);
    if (isNaN(numValue)) {
      setText(String(waarde));
      return;
    }

    trySetValue(numValue, true);
  }, [setInputHasFocus, text, waarde, trySetValue]);

  return (
    <div style={{ position: 'relative' }}>
      <input
        type="text"
        className="form-control"
        value={
          bepaalHeeftFocus()
            ? text
            : props.inactiefRepresentatie !== undefined
            ? props.inactiefRepresentatie(text)
            : text
        }
        onChange={handleChange}
        onFocus={() => setInputHasFocus(true)}
        onBlur={handleBlur}
        onKeyUp={(ev) => {
          if (ev.key === 'ArrowDown') {
            handleVerlagen();
          } else if (ev.key === 'ArrowUp') {
            handleVerhogen();
          }
        }}
        disabled={props.disabled}
        {...rootProps}
      />
      <div
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
          height: '100%',
          width: 20,
        }}
        className="d-flex flex-column"
      >
        <button
          className="btn btn-sm btn-light flex-fill p-0 pl-1 pr-1 d-flex align-items-center justify-content-center"
          style={{
            borderRadius: 0,
            border: `1px solid ${Kleur.LichtGrijs}`,
          }}
          onClick={handleVerhogen}
          onFocus={() => setVerhogenHasFocus(true)}
          onBlur={() => setVerhogenHasFocus(false)}
          disabled={props.disabled || waarde === max}
        >
          <IconInklappen style={{ width: 12, height: 12, fill: Kleur.Grijs }} />
        </button>
        <button
          className="btn btn-sm btn-light flex-fill p-0 pl-1 pr-1 d-flex align-items-center justify-content-center"
          style={{
            borderRadius: 0,
            border: `1px solid ${Kleur.LichtGrijs}`,
          }}
          onClick={handleVerlagen}
          onFocus={() => setVerlagenHasFocus(true)}
          onBlur={() => setVerlagenHasFocus(false)}
          disabled={props.disabled || waarde === min}
        >
          <IconUitklappen style={{ width: 12, height: 12, fill: Kleur.Grijs }} />
        </button>
      </div>
    </div>
  );
};

export default NumeriekVeld;
