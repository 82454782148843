import * as React from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  Index,
  IndexRange,
  InfiniteLoader,
  List,
} from 'react-virtualized';
import { ListRowRenderer } from 'react-virtualized/dist/es/List';
import Bericht from './Bericht';
import { IWhatsappBericht, IWhatsappChatsessie } from '../../../../../api/services/v1/whatsapp';
import { IBerichtenData, useWhatsappV2Store } from '../../store';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
  mapRemoteData,
} from '../../../../../models/IRemoteData';
import Skeleton from 'react-loading-skeleton';
import LoadingSpinner from '../../../../Gedeeld/LoadingSpinner';
import { CellMeasurerChildProps } from 'react-virtualized/dist/es/CellMeasurer';
import { ScrollParams } from 'react-virtualized/dist/es/Grid';
import { IconChevronRight } from '../../../../Icons';
import { Kleur } from '../../../../../bedrijfslogica/constanten';

const Root = styled.div`
  flex: 1;
  position: relative;
`;

const ListContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-bottom: 1px;
`;

const ScrollKnop = styled.button`
  position: absolute;
  bottom: 15px;
  right: 15px;
  z-index: 1;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: rgb(245, 245, 245);
  border: 1px solid #ddd;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  outline: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;

  pointer-events: none;
  opacity: 0;

  &:hover {
    background-color: rgb(235, 235, 235);
  }

  &:focus {
    outline: none;
  }

  &.weergeven {
    pointer-events: all;
    opacity: 100%;
  }
`;

interface IProps {
  chatsessie: IWhatsappChatsessie;
}

const Berichten = (props: IProps) => {
  const store = useWhatsappV2Store();

  const berichtenData = useMemo<IRemoteData<IBerichtenData>>(() => {
    return (
      store.whatsappBerichtenDataBijChatsessieId[props.chatsessie.id] ?? createPendingRemoteData()
    );
  }, [props.chatsessie.id, store.whatsappBerichtenDataBijChatsessieId[props.chatsessie.id]]);

  const listContainerRef = useRef<HTMLDivElement | null>(null);
  const listRef = useRef<List | null>(null);

  const getVirtualizedListDOMElement = useCallback<() => HTMLElement | null>(() => {
    return listContainerRef.current?.querySelector('.ReactVirtualized__Grid') ?? null;
  }, []);

  const caches = useRef<{ [chatsessieId: number]: CellMeasurerCache }>({});
  if (caches.current[props.chatsessie.id] === undefined) {
    caches.current[props.chatsessie.id] = new CellMeasurerCache({
      fixedWidth: true,
      // keyMapper: (idx) =>
      //   berichtenData.state === ERemoteDataState.Pending ||
      //   berichtenData.data!.berichtIDBijIndex[idx] === undefined
      //     ? `${props.chatsessie.id}-idx-${idx}`
      //     : `${props.chatsessie.id}-id-${berichtenData.data!.berichtIDBijIndex[idx].data!}`,
    });
  }
  const cache = caches.current[props.chatsessie.id];

  useEffect(() => {
    if (berichtenData.state === ERemoteDataState.Ready) {
      return;
    }
    store.ophalenBerichtenInitieel(props.chatsessie.id);
  }, [berichtenData]);

  const [scrollNaarHetEindeWeergeven, setScrollNaarHetEindeWeergeven] = useState(false);
  const handleScrollNaarHetEindeClick = useCallback(() => {
    if (berichtenData.state === ERemoteDataState.Pending) {
      return;
    }

    listRef.current?.scrollToRow(berichtenData.data!.totaalAantal - 1);
  }, [berichtenData]);

  const heeftScrollLockRef = useRef(true);
  const handleScroll = useCallback((params: ScrollParams) => {
    // Als we helemaal naar beneden zijn gescrolled, dan kunnen we de scroll lock aanzetten
    const heeftScrollLock = params.scrollTop + params.clientHeight >= params.scrollHeight;
    if (heeftScrollLockRef.current !== heeftScrollLock) {
      heeftScrollLockRef.current = heeftScrollLock;
    }

    // Als we verder dan 100px van het einde zijn, dan kunnen we de knop om naar het einde te scrollen weergeven
    const moetScrollNaarHetEindeWeergeven =
      params.scrollTop + params.clientHeight < params.scrollHeight - 100;
    setScrollNaarHetEindeWeergeven(moetScrollNaarHetEindeWeergeven);
  }, []);

  const bepalenScrollNaarHetEindeWeergeven = useCallback(() => {
    const element = getVirtualizedListDOMElement();
    if (element === null) {
      return;
    }

    handleScroll({
      clientHeight: element.clientHeight,
      scrollHeight: element.scrollHeight,
      scrollTop: element.scrollTop,
      clientWidth: element.clientWidth,
      scrollLeft: element.scrollLeft,
      scrollWidth: element.scrollWidth,
    });
  }, [getVirtualizedListDOMElement, handleScroll]);

  useEffect(() => {
    bepalenScrollNaarHetEindeWeergeven();
  }, [props.chatsessie.id, bepalenScrollNaarHetEindeWeergeven]);

  useEffect(() => {
    // Lelijke manier van controleren, maar er is geen zuivere methode om de scroll hoogte te bepalen
    // via een listener. Daarnaast is dit ook lastig te hangen aan de virtualized list, omdat deze
    // hoogte pas bepaald wordt na de measure call.
    const interval = setInterval(() => {
      bepalenScrollNaarHetEindeWeergeven();
    }, 500);
    return () => {
      clearInterval(interval);
    };
  }, [bepalenScrollNaarHetEindeWeergeven]);

  const rowRenderer = useCallback<ListRowRenderer>(
    ({ index, key, style, parent }) => {
      const wrapWithCellMeasurer = (
        cellMeasurerChild: ((props: CellMeasurerChildProps) => React.ReactNode) | React.ReactNode,
      ): JSX.Element => {
        return (
          <CellMeasurer cache={cache} parent={parent} rowIndex={index} key={key} columnIndex={0}>
            {cellMeasurerChild}
          </CellMeasurer>
        );
      };

      const idData = berichtenData.data!.berichtIDBijIndex[index];
      if (idData === undefined || idData.state === ERemoteDataState.Pending) {
        return wrapWithCellMeasurer(({ measure, registerChild }) => (
          <div
            ref={registerChild as React.RefCallback<any>}
            style={style}
            className="d-flex p-2 pl-4 pr-4"
            onLoad={measure}
          >
            <Skeleton
              className="flex-fill w-100 h-100"
              containerClassName="flex-fill w-100 h-100"
            />
          </div>
        ));
      }
      const bericht = store.berichtBijID[idData.data!];
      const vorigBerichtIDData: IRemoteData<number | null> =
        index - 1 < 0
          ? createReadyRemoteData(null)
          : berichtenData.data!.berichtIDBijIndex[index - 1] ?? createPendingRemoteData();
      const vorigBericht = mapRemoteData<number | null, IWhatsappBericht | null>(
        vorigBerichtIDData,
        (id) => (id === null ? null : store.berichtBijID[id]),
      );

      const isEersteBericht = index === 0;
      const isLaatsteBericht = index === berichtenData.data!.totaalAantal - 1;

      return wrapWithCellMeasurer(({ measure, registerChild }) => (
        <div ref={registerChild as React.RefCallback<any>} style={style} onLoad={measure}>
          <Bericht
            // onLoad={measure}
            isEersteBericht={isEersteBericht}
            isLaatsteBericht={isLaatsteBericht}
            bericht={bericht}
            vorigBericht={vorigBericht}
            onLayoutHerberekenen={() => {
              // console.log('onLayoutHerberekenen', index);
              measure();
              // cache.current.clear(index, 0);
              // listRef.current?.recomputeRowHeights(index);
              if (heeftScrollLockRef.current) {
                listRef.current?.scrollToRow(berichtenData.data!.totaalAantal - 1);
              }
            }}
          />
        </div>
      ));
    },
    [berichtenData, store.berichtBijID, cache, bepalenScrollNaarHetEindeWeergeven],
  );

  const hasScrolledToEnd = useRef(false);
  useEffect(() => {
    if (hasScrolledToEnd.current || berichtenData.state === ERemoteDataState.Pending) {
      return;
    }
    listRef.current?.scrollToRow(berichtenData.data!.totaalAantal - 1);
    hasScrolledToEnd.current = true;
  }, [berichtenData.data]);

  const isRijGeladen = useCallback(
    (index: Index) => {
      return (
        berichtenData.state === ERemoteDataState.Ready &&
        berichtenData.data!.berichtIDBijIndex[index.index] !== undefined
      );
    },
    [berichtenData],
  );
  const laadMeerRijenTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const laadMeerRijen = useCallback(
    async (range: IndexRange) => {
      if (laadMeerRijenTimeoutRef.current !== null) {
        clearTimeout(laadMeerRijenTimeoutRef.current);
      }

      laadMeerRijenTimeoutRef.current = setTimeout(async () => {
        await store.meerBerichtenLaden({
          chatsessieID: props.chatsessie.id,
          index: range.startIndex,
          aantal: range.stopIndex - range.startIndex + 1,
        });

        for (let i = range.startIndex; i <= range.stopIndex; i++) {
          cache.clear(i, 0);
          listRef.current?.recomputeRowHeights(i);
        }
      }, 100);
    },
    [store.meerBerichtenLaden, props.chatsessie.id],
  );

  return (
    <Root>
      <ListContainer ref={listContainerRef}>
        <AutoSizer>
          {({ width, height }) => {
            if (berichtenData.state === ERemoteDataState.Pending) {
              return (
                <div
                  className="d-flex flex-column align-items-center justify-content-center"
                  style={{ width, height }}
                >
                  <LoadingSpinner />
                </div>
              );
            }

            if (berichtenData.data!.totaalAantal === 0) {
              return (
                <div
                  style={{ width, height }}
                  className="d-flex align-items-center justify-content-center"
                >
                  <span className="text-muted">Er zijn geen berichten beschikbaar.</span>
                </div>
              );
            }

            return (
              <InfiniteLoader
                loadMoreRows={laadMeerRijen}
                isRowLoaded={isRijGeladen}
                rowCount={berichtenData.data!.totaalAantal}
                minimumBatchSize={30}
                threshold={30}
              >
                {(ilp) => (
                  <List
                    ref={(ref) => {
                      listRef.current = ref;
                      ilp.registerChild(ref);
                    }}
                    deferredMeasurementCache={cache}
                    rowCount={berichtenData.data!.totaalAantal}
                    height={height}
                    rowHeight={cache.rowHeight}
                    rowRenderer={rowRenderer}
                    width={width}
                    overscanRowCount={10}
                    // scrollToIndex={berichtenData.data!.totaalAantal - 1}
                    onRowsRendered={ilp.onRowsRendered}
                    onScroll={handleScroll}
                  />
                )}
              </InfiniteLoader>
            );
          }}
        </AutoSizer>
      </ListContainer>
      <ScrollKnop
        title="Scroll naar het einde"
        onClick={handleScrollNaarHetEindeClick}
        className={scrollNaarHetEindeWeergeven ? 'weergeven' : ''}
      >
        <IconChevronRight
          style={{ width: 26, height: 26, fill: Kleur.Grijs, transform: 'rotate(90deg)' }}
        />
      </ScrollKnop>
    </Root>
  );
};

export default Berichten;
