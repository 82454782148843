import React from 'react';
import Toetsing from './Toetsing';
import { IOphalenKrediettoetsenResultElement } from '../../../../../../../../../../../shared/src/api/v2/kredietwaardigheid';

interface IProps {
  toetsingen: IOphalenKrediettoetsenResultElement[];
}

const Toetsingen: React.FC<IProps> = (props) => {
  const { children, toetsingen } = props;
  return (
    <div>
      {toetsingen.length === 0 ? (
        <p>Geen toetsingen beschikbaar</p>
      ) : (
        toetsingen.map((toetsing, i) => {
          return (
            <div key={toetsing.ID} className={i === 0 ? '' : 'mt-2'}>
              <Toetsing toetsing={toetsing} />
            </div>
          );
        })
      )}
    </div>
  );
};

export default Toetsingen;
