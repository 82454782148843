import React, { useCallback, useContext, useEffect, useState } from 'react';
import { ITabbladComponentProps } from '..';
import api from '../../../../api';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../models/IRemoteData';
import { IOphalenInventarisatiesResultElement } from '../../../../../../shared/src/api/v2/magazijn/inventarisatie';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import PDFWeergever from '../../../../components/PDFWeergever';
import { IOphalenBestandenResultElement } from '../../../../../../shared/src/api/v2/bestand/bestand';
import MenuLayout from '../../../../components/MenuLayout';
import DownloadKnop, { EDownloadKnopVariant } from '../../../../components/DownloadKnop';
import downloadUrl from '../../../../core/downloadUrl';
import { Kleur as EKleur } from '../../../../bedrijfslogica/constanten';
import { RootStoreContext } from '../../../../stores/RootStore';
import { EResultType } from '../../../../stores/CheckStore';
import toast from 'react-hot-toast';
import BestandenDragAndDropDialoog, {
  IBestandenDragAndDropDialoogResult,
} from '../../../../components/BestandenDragAndDropDialoog';
import { EBestandDragAndDropZoneSelectieModus } from '../../../../components/BestandDragAndDropZone';
import { GlobaleRendererContext } from '../../../../one-off-components/GlobaleRenderer';

const Document = (props: ITabbladComponentProps) => {
  const { checkStore } = useContext(RootStoreContext);
  const globaleRenderer = useContext(GlobaleRendererContext);

  const [inventarisatie, setInventarisatie] = useState<
    IRemoteData<IOphalenInventarisatiesResultElement>
  >(createPendingRemoteData());

  const ophalenInventarisatie = useCallback(async () => {
    const result = await api.v2.magazijn.inventarisatie.ophalenInventarisaties({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: [props.inventarisatieID],
          },
        ],
      },
    });
    const inventarisatie = result.inventarisaties[0];

    setInventarisatie(createReadyRemoteData(inventarisatie));
  }, [props.inventarisatieID]);
  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    ophalenInventarisatie();
  }, [ophalenInventarisatie]);

  const [bestand, setBestand] = useState<IRemoteData<IOphalenBestandenResultElement | null>>(
    createPendingRemoteData(),
  );
  const ophalenBestand = useCallback(async () => {
    if (inventarisatie.state === ERemoteDataState.Pending) {
      setBestand(createPendingRemoteData());
      return;
    }
    if (inventarisatie.data!.Document_BestandID === null) {
      setBestand(createReadyRemoteData(null));
      return;
    }
    const result = await api.v2.bestand.ophalenBestanden({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: [inventarisatie.data!.Document_BestandID],
          },
        ],
      },
    });
    const bestand = result.bestanden[0];
    setBestand(createReadyRemoteData(bestand));
  }, [inventarisatie]);
  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    ophalenBestand();
  }, [ophalenBestand]);

  return (
    <MenuLayout
      menu={
        <div className="d-flex flex-fill">
          <button
            className="btn btn-sm btn-light d-flex align-items-center ml-3"
            style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
            onClick={async () => {
              const bestandenResult = await globaleRenderer.render<
                IBestandenDragAndDropDialoogResult
              >((renderProps) => (
                <BestandenDragAndDropDialoog
                  open
                  onSuccess={(result) => renderProps.destroy(result)}
                  onAnnuleren={() => renderProps.destroy()}
                  toegestaneBestandstypen={[
                    {
                      weergaveNaam: 'PDF',
                      mediaType: 'application/pdf',
                    },
                  ]}
                  selectieModus={EBestandDragAndDropZoneSelectieModus.Enkel}
                />
              ));

              if (bestandenResult === undefined) {
                return;
              }
              const bestand = bestandenResult.bestanden[0];

              await api.v2.magazijn.inventarisatie.toevoegenDocument({
                magInvID: props.inventarisatieID,
                bestandID: bestand.ID,
              });

              ophalenBestand();

              toast.success('Toevoegen document gelukt');

              return;
            }}
          >
            <span className="ml-2">Inlezen (PDF-)document</span>
          </button>
          {/* <DownloadKnop
                onDownloadAangevraagd={async () => {
                  await downloadUrl(bestand.data!.url, bestand.data!.Naam);
                }}
                variant={EDownloadKnopVariant.Groot}
              /> */}
        </div>
      }
      body={
        bestand.state === ERemoteDataState.Pending ? (
          <div className="d-flex flex-fill flex-column align-items-center justify-content-center">
            <LoadingSpinner />
          </div>
        ) : bestand.data === null ? (
          <div className="d-flex flex-fill flex-column align-items-center justify-content-center">
            <h5>Er is geen document gekoppeld aan deze inventarisatie.</h5>
          </div>
        ) : (
          <PDFWeergever url={bestand.data!.url} />
        )
      }
    />
  );
};

export default Document;
