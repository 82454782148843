import React, { useCallback, useEffect, useMemo, useState, useContext } from 'react';
import { RouteComponentProps } from 'react-router';
import api from '../../../api';
import LoadingSpinner from '../../../components/Gedeeld/LoadingSpinner';
import MenuLayout from '../../../components/MenuLayout';
import useUrlState, { genereerUrlStateQueryParam } from '../../../core/useUrlState';
import { IOphalenOpdrachtenResultElement } from '../../../../../shared/src/api/v2/service/opdracht';
import { addDays, format } from 'date-fns';
import {
  DXCommandComponent,
  DXTableCheckboxComponent,
  DXTableToggleCellComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../helpers/dxTableGrid';
import {
  Grid,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  DataTypeProvider,
  EditingState,
  IntegratedSorting,
  RowDetailState,
  SelectionState,
  SortingState,
  VirtualTable as VirtualTableBase,
} from '@devexpress/dx-react-grid';
import nameof from '../../../core/nameOf';
import RelatieVisualisatie from '../../../components/personalia/RelatieVisualisatie';
import WijzigenDialoog from './WijzigenDialoog';
import WerkbonMutatieDialoog from '../../../components/service/WerkbonMutatieDialoog';
import DetailComp from './RowDetailComp';
import { withRouter } from 'react-router-dom';
import { IOphalenDienstenResultElement } from '../../../../../shared/src/api/v2/dienst/service';
import _ from 'lodash';
import FilterBalkV2, {
  IFilter,
  IFilterData,
  maakFilterSchema,
} from '../../../components/FilterBalkV2';
import ZoektermFilter from './ZoektermFilter';
import Combobox from '../../../components/formulier/Combobox';
import {
  IUrlState as IServiceopdrachtUrlState,
  defaultUrlState as serviceopdrachtDefaultUrlState,
} from '../../Klant/Klantkaart/Entiteiten/entiteiten/Service/Opdrachten';
import { EHoedanigheid } from '../../../components/personalia/RelatieSelectieDialoog';
import OpdrachtInfoDialoog from '../../../components/service/OpdrachtInfoDialoog';
import { Kleur as EKleur } from '../../../bedrijfslogica/constanten';
import { IconInformatie } from '../../../components/Icons';
import { Helmet } from 'react-helmet';

enum EFilter {
  IsAfgehandeld = 'IS_AFGEHANDELD',
  Zoekterm = 'ZOEKTERM',
  Servicedienst = 'SERVDIENST_IDS',
}

export interface IServiceopdrachtInfoDialoogState {
  servOpdID: number;
}

interface IUrlState {
  selectie: number[];
  filterData: IFilterData<EFilter>[];
  serviceopdrachtInfoDialoogState: IServiceopdrachtInfoDialoogState | null;
}

export interface IWerkbonToevoegenDialoogState {
  servOpdID: number;
}

const defaultUrlState: IUrlState = {
  selectie: [],
  filterData: [
    {
      naam: EFilter.IsAfgehandeld,
      data: false,
      isActief: true,
    },
    {
      naam: EFilter.Zoekterm,
      data: '',
      isActief: false,
    },
    {
      naam: EFilter.Servicedienst,
      data: [],
      isActief: false,
    },
  ],
  serviceopdrachtInfoDialoogState: null,
};

interface IProps extends RouteComponentProps {}

interface IRow extends IOphalenOpdrachtenResultElement {}

export interface IOpdrachtenContext {
  verversen: () => void;
  // diensten: IOphalenDienstenResultElement[] | null;
}

export const OpdrachtenContext = React.createContext<IOpdrachtenContext>(null as any);

const Opdrachten: React.FC<IProps> = (props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState<IUrlState>(props, defaultUrlState);
  const [opdrachten, setOpdrachten] = useState<IRow[] | null>(null);
  const [wijzigenID, setWijzigenID] = useState<number | null>(null);

  const [
    werkbonToevoegenDialoogState,
    setWerkbonToevoegenDialoogState,
  ] = useState<IWerkbonToevoegenDialoogState | null>(null);
  // const [diensten, setDiensten] = useState<IOphalenDienstenResultElement[] | null>(null);

  const [filterSchema, setFilterSchema] = useState(maakFilterSchema(urlState.filterData));

  const ophalenOpdrachten = useCallback(async () => {
    const datum = format(addDays(new Date(), -180), 'yyyy-MM-dd');
    // const afgehandeld = filterSchema.filters!.find((x) => x.naam === EFilter.IsAfgehandeld)!.data;
    // console.log(afgehandeld);

    const result = await api.v2.service.ophalenOpdrachten({
      filterSchema: {
        filters: [...filterSchema.filters!, { naam: 'OPDRACHTDATUM_VANAF', data: datum }],
      },
      orderSchema: { orders: [{ naam: 'OPDRACHTDATUM', richting: 'DESC' }] },
    });

    setOpdrachten(result.opdrachten);
  }, [filterSchema]);

  useEffect(() => {
    ophalenOpdrachten();
  }, [ophalenOpdrachten]);

  // const ophalenDiensten = useCallback(async () => {
  //   const diensten = (
  //     await api.v2.dienst.service.ophalenDiensten({
  //       filterSchema: { filters: [{ naam: 'IS_ACTIEF', data: true }] },
  //       // orderSchema: { orders: [{ naam: 'NAAM', richting: 'ASC' }] },
  //     })
  //   ).diensten;

  //   const dienstenGesorteerd = _.orderBy(
  //     diensten,
  //     [
  //       (x: IOphalenDienstenResultElement) => {
  //         return x.NaamIdent;
  //       },
  //     ],
  //     ['asc'],
  //   );

  //   setDiensten(dienstenGesorteerd);
  // }, []);

  // useEffect(() => {
  //   ophalenDiensten();
  // }, [ophalenDiensten]);

  const keyExtractor = useCallback((row: IRow) => row.ServOpdID, []);

  const kolommen = useMemo<TypedColumn<IRow>[]>(() => {
    return [
      {
        name: '__opdrachtnummer' as any,
        title: 'Opdr.nr.',
        getCellValue: (x) => {
          return x.melding.Meldnummer + '-' + x.Volgnummer;
        },
      },
      { name: '__opdrachtinfo' as any, title: ' ' },
      {
        name: 'Opdrachtdatum',
        title: 'Opdr.datum',
      },
      {
        name: 'DatumVerstuurd',
        title: 'Verstuurd',
      },
      {
        name: '__adres' as any,
        title: 'Locatie',
        getCellValue: (x) => {
          const locatie = x.melding.locatie;
          return locatie.Straatnaam + locatie.Huisnummer;
        },
      },
      {
        name: '__plaatsnaam' as any,
        title: 'Plaats',
        getCellValue: (x) => {
          const locatie = x.melding.locatie;
          return locatie.Plaatsnaam;
        },
      },
      {
        name: '__relatie' as any,
        title: 'Relatie',
      },
      // {
      //   name: '__contactpersoonOpgegeven' as any,
      //   title: 'Ctp.',
      // },
      // {
      //   name: '__product' as any,
      //   title: 'Product',
      // },
      {
        name: 'Omschrijving',
        title: 'Klacht',
      },
      {
        name: '__leverancier' as any,
        title: 'Rep.dienst',
        getCellValue: (x) => {
          return x.dienst.relatie!.weergavenaam;
        },
      },
      {
        name: '__status' as any,
        title: 'Status',
        getCellValue: (x) => {
          return x.status.Naam;
        },
      },
      // {
      //   name: 'Garantieclaim',
      //   title: 'Gar.',
      // },
      // {
      //   name: '__aantalWerkbonnen',
      //   title: '# Bon.',
      // },
      {
        name: 'Afgehandeld',
        title: 'Afg.',
      },
    ];
  }, []);

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRow>[]>(() => {
    return [
      {
        columnName: '__opdrachtnummer' as any,
        width: 90,
      },
      { columnName: '__opdrachtinfo', width: 65 },
      {
        columnName: 'Opdrachtdatum',
        width: 110,
      },
      {
        columnName: 'DatumVerstuurd',
        width: 110,
      },
      {
        columnName: '__leverancier' as any,
        width: 200,
      },
      {
        columnName: 'Garantieclaim',
        width: 80,
      },
      {
        columnName: '__adres' as any,
        width: 225,
      },
      {
        columnName: '__plaatsnaam' as any,
        width: 165,
      },
      {
        columnName: '__relatie' as any,
        width: 200,
      },
      {
        columnName: '__contactpersoonOpgegeven' as any,
        width: 80,
      },
      {
        columnName: '__product' as any,
        width: 225,
      },
      {
        columnName: 'Omschrijving' as any,
        width: 325,
      },
      {
        columnName: '__status' as any,
        width: 135,
      },
      {
        columnName: '__aantalWerkbonnen' as any,
        width: 90,
      },
      {
        columnName: 'Afgehandeld',
        width: 80,
      },
    ];
  }, []);

  const kolomExtensies: VirtualTableBase.ColumnExtension[] = useMemo(() => {
    return [
      // {
      //   columnName: `__aantalWerkbonnen`,
      //   align: 'right',
      // },
    ];
  }, []);

  const [dienstenOpties, setDienstenOpties] = useState<IOphalenDienstenResultElement[] | null>(
    null,
  );

  const ophalenDienstenOpties = useCallback(async () => {
    const diensten = (
      await api.v2.dienst.service.ophalenDiensten({
        filterSchema: { filters: [{ naam: 'IS_ACTIEF', data: true }] },
        orderSchema: { orders: [{ naam: 'NAAM', richting: 'ASC' }] },
      })
    ).diensten;

    const dienstenGesorteerd = _.orderBy(
      diensten,
      [
        (x: IOphalenDienstenResultElement) => {
          return x.NaamIdent;
        },
      ],
      ['asc'],
    );

    setDienstenOpties(dienstenGesorteerd);
  }, []);

  useEffect(() => {
    ophalenDienstenOpties();
  }, [ophalenDienstenOpties]);

  const context = useMemo<IOpdrachtenContext>(() => {
    return {
      verversen: () => ophalenOpdrachten(),
      // diensten: diensten,
    };
  }, [ophalenOpdrachten]);

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.IsAfgehandeld,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return (
            <span className="d-flex align-items-center">
              <span>Alleen niet afgehandeld</span>
            </span>
          );
        },
      },
      {
        naam: EFilter.Zoekterm,
        altijdWeergevenInBalk: true,
        weergave: ZoektermFilter,
      },
      {
        naam: EFilter.Servicedienst,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          if (dienstenOpties === null) {
            return null;
          }
          const data: number[] = weergaveProps.data;

          return (
            <div className="d-flex align-items-center">
              <span className="mr-2">Servicedienst</span>
              <Combobox
                geselecteerd={data.length === 0 ? null : data[0]}
                onSelectieChange={(x) => {
                  const newData = x === null ? [] : [x];
                  weergaveProps.onDataChange(newData);
                  weergaveProps.setIsActief(true);
                  weergaveProps.toepassen();
                }}
                opties={dienstenOpties.map((x) => {
                  return { id: x.ID, label: x.NaamIdent! };
                })}
              />
            </div>
          );
        },
      },
    ],
    [dienstenOpties],
  );

  return (
    <OpdrachtenContext.Provider value={context}>
      <Helmet>
        <title>Service-opdrachten</title>
      </Helmet>
      <>
        {opdrachten === null ? (
          <div className="flex-fill d-flex align-items-center justify-content-center">
            <LoadingSpinner />
          </div>
        ) : (
          <MenuLayout
            menu={
              <div className="d-flex flex-column">
                <div className="d-flex align-items-center ml-2">
                  <FilterBalkV2
                    filters={filters}
                    filterData={urlState.filterData}
                    onFilterDataChange={(x) => setUrlStateSync('filterData', x)}
                    onFilterSchemaChange={(x) => setFilterSchema(x)}
                  />
                </div>
              </div>
            }
            body={
              <>
                <GridStyleWrapper height="calc(100vh - 150px)">
                  <Grid columns={kolommen} rows={opdrachten} getRowId={keyExtractor}>
                    <DataTypeProvider
                      for={['__opdrachtnummer']}
                      formatterComponent={(formatterProps) => {
                        const rij = formatterProps.row as IRow;
                        return (
                          <span>
                            {rij.melding.Meldnummer}-{rij.Volgnummer}
                          </span>
                        );
                      }}
                    />

                    {/* <DataTypeProvider
                      for={['__opdrachtinfo']}
                      formatterComponent={(formatterProps) => {
                        const rij = formatterProps.row as IRow;
                        return (
                          <a
                            href="#"
                            onClick={() =>
                              setUrlStateSync('serviceopdrachtInfoDialoogState', {
                                servOpdID: rij.ServOpdID,
                              })
                            }
                          >
                            xx
                          </a>
                        );
                      }}
                    /> */}

                    <DataTypeProvider
                      for={['__opdrachtinfo']}
                      formatterComponent={(formatterProps) => {
                        const rij: IRow = formatterProps.row;
                        return (
                          <>
                            <a
                              href="#"
                              className="ml-1"
                              style={{ color: EKleur.LichtBlauw, position: 'relative', bottom: 2 }}
                              onClick={() => {
                                setUrlStateSync('serviceopdrachtInfoDialoogState', {
                                  servOpdID: rij.ServOpdID,
                                });
                              }}
                            >
                              <IconInformatie
                                style={{ width: 15, height: 15, fill: EKleur.Blauw }}
                              />
                            </a>
                          </>
                        );
                      }}
                    />

                    <DataTypeProvider
                      for={[nameof<IRow>('Opdrachtdatum'), nameof<IRow>('DatumVerstuurd')]}
                      formatterComponent={(formatterProps) => {
                        const rij: IRow = formatterProps.row;
                        const datum =
                          formatterProps.value !== null
                            ? format(new Date(formatterProps.value), 'dd-MM-yyyy')
                            : '';
                        return <span>{datum}</span>;
                      }}
                    />

                    <DataTypeProvider
                      for={['__status']}
                      formatterComponent={(formatterProps) => {
                        const rij = formatterProps.row as IRow;
                        return <span>{rij.status.Naam}</span>;
                      }}
                    />

                    <DataTypeProvider
                      for={['__contactpersoonOpgegeven']}
                      formatterComponent={(formatterProps) => {
                        const rij = formatterProps.row as IRow;
                        return <span>{rij.contactpersoon !== null ? 'Ja' : 'Nee'}</span>;
                      }}
                    />

                    <DataTypeProvider
                      for={['__aantalWerkbonnen']}
                      formatterComponent={(formatterProps) => {
                        const rij: IRow = formatterProps.row;

                        return (
                          <span>{rij.werkbonnen.length !== 0 ? rij.werkbonnen.length : ''}</span>
                        );
                      }}
                    />

                    <DataTypeProvider
                      for={['__leverancier']}
                      formatterComponent={(formatterProps) => {
                        const rij = formatterProps.row as IRow;
                        return <RelatieVisualisatie relID={rij.dienst.relatie!.RelID} />;
                        // return <span>{rij.dienst.relatie.weergavenaam}</span>;
                      }}
                    />

                    <DataTypeProvider
                      for={['Garantieclaim']}
                      formatterComponent={(formatterProps) => {
                        const rij = formatterProps.row as IRow;
                        return <span>{rij.Garantieclaim ? 'Ja' : ''}</span>;
                      }}
                    />

                    <DataTypeProvider
                      for={['__adres']}
                      formatterComponent={(formatterProps) => {
                        const rij = formatterProps.row as IRow;
                        const locatie = rij.melding.locatie;
                        return (
                          <span>
                            {locatie.Straatnaam +
                              ' ' +
                              locatie.Huisnummer +
                              (locatie.Bisnummer !== null ? ' ' + locatie.Bisnummer : '')}
                          </span>
                        );
                      }}
                    />
                    <DataTypeProvider
                      for={['__plaatsnaam']}
                      formatterComponent={(formatterProps) => {
                        const rij = formatterProps.row as IRow;
                        const locatie = rij.melding.locatie;
                        return <span>{locatie.Plaatsnaam}</span>;
                      }}
                    />

                    <DataTypeProvider
                      for={['__relatie']}
                      formatterComponent={(formatterProps) => {
                        const rij = formatterProps.row as IRow;

                        const urlState: IServiceopdrachtUrlState = {
                          ...serviceopdrachtDefaultUrlState,
                          selectie: [rij.ServOpdID],
                        };
                        const query = genereerUrlStateQueryParam(urlState);

                        return (
                          <RelatieVisualisatie
                            relID={rij.melding.RelID!}
                            relatieLinkBuilder={(hoedanigheid, relID) =>
                              `/${
                                hoedanigheid === EHoedanigheid.Klant ? 'klant' : 'leverancier'
                              }/${relID}/service/opdracht?state=${query}`
                            }
                          />
                        );
                      }}
                    />

                    <DataTypeProvider
                      for={['__product']}
                      formatterComponent={(formatterProps) => {
                        const rij = formatterProps.row as IRow;
                        const product = rij.melding.product;

                        return (
                          <span>
                            {product !== null
                              ? product.producttype.Merknaam + ' ' + product.producttype.Typenaam
                              : ''}
                          </span>
                        );
                      }}
                    />

                    <DataTypeProvider
                      for={[nameof<IRow>('Afgehandeld')]}
                      formatterComponent={(formatterProps) => {
                        return <span>{formatterProps.value ? 'Ja' : 'Nee'}</span>;
                      }}
                    />

                    <SortingState defaultSorting={[]} />
                    <IntegratedSorting />
                    <VirtualTable
                      estimatedRowHeight={10}
                      columnExtensions={kolomExtensies}
                      messages={{ noData: 'Geen reparatieopdrachten voor het filter' }}
                    />
                    <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
                    <TableHeaderRow showSortingControls />

                    <EditingState
                      onAddedRowsChange={() => {}}
                      onEditingRowIdsChange={(x) => {
                        const id = x[x.length - 1] as number;
                        setWijzigenID(id);
                      }}
                      onCommitChanges={() => null}
                    />
                    <TableEditColumn
                      width={35}
                      // showAddCommand={true}
                      showEditCommand
                      // showDeleteCommand
                      commandComponent={DXCommandComponent}
                    />

                    <RowDetailState defaultExpandedRowIds={[]} />
                    <TableRowDetail
                      contentComponent={DetailComp}
                      toggleCellComponent={DXTableToggleCellComponent}
                    />

                    <SelectionState
                      selection={urlState.selectie}
                      onSelectionChange={(value) => setUrlStateSync('selectie', value as number[])}
                    />
                    <TableSelection cellComponent={DXTableCheckboxComponent} />
                  </Grid>
                </GridStyleWrapper>
              </>
            }
          />
        )}

        {wijzigenID !== null && (
          <WijzigenDialoog
            open
            id={wijzigenID}
            onSuccess={async () => {
              setWijzigenID(null);
              ophalenOpdrachten();
            }}
            onAnnuleren={() => {
              setWijzigenID(null);
            }}
          />
        )}
        {werkbonToevoegenDialoogState !== null && opdrachten !== null && (
          <WerkbonMutatieDialoog
            open={true}
            serviceopdracht={opdrachten.find((x) => x.ServOpdID === urlState.selectie[0])!}
            werkbonID={null}
            onSuccess={() => setWerkbonToevoegenDialoogState(null)}
            onAnnuleren={() => setWerkbonToevoegenDialoogState(null)}
          />
        )}

        {urlState.serviceopdrachtInfoDialoogState !== null && (
          <OpdrachtInfoDialoog
            id={urlState.serviceopdrachtInfoDialoogState.servOpdID}
            open
            onSuccess={() => setUrlStateSync('serviceopdrachtInfoDialoogState', null)}
            onAnnuleren={() => setUrlStateSync('serviceopdrachtInfoDialoogState', null)}
          />
        )}
      </>
    </OpdrachtenContext.Provider>
  );
};

export default withRouter(Opdrachten);
