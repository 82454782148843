import React, { useContext, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { SplashLogo } from '../../components/Icons/splashLogo';
import {
  EFunctioneleIcon,
  functioneleIconMap,
  IconFuncties,
  IconInformatie,
} from '../../components/Icons';
import { Kleur } from '../../bedrijfslogica/constanten';
import Overlay from 'react-bootstrap/Overlay';
import FunctiesOverlay from './FunctiesOverlay';
import UitlegTooltip from '../../components/formulier/UitlegTooltip';
import MemosOverlay from './MemosOverlay';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../stores/RootStore';
import { ERemoteDataState } from '../../models/IRemoteData';
import Badge from '../../components/Badge';
import NotificatieKnop from '../PaginaHeader/NotificatieKnop';
import TechnischeInfoOverlay from './TechnischeInfoOverlay';

const Header = styled.header`
  font-weight: bold;
  margin-top: 15px;
  color: white;
  display: flex;
  //align-items: center;

  justify-content: center;

  flex-direction: column;
  padding: 0.5rem;
  margin-left: 10px;

  a {
    color: red;
    svg {
      fill: white;
    }
  }

  .app-name {
    font-size: 13px;
  }
`;

const IconMemo = functioneleIconMap[EFunctioneleIcon.Memo]!;

const Container = styled.div`
  display: flex;

  div[class='fade show'] {
    z-index: 501;
  }

  .app-env {
    font-size: 13px;
    margin-left: 5px;
    color: #fff44f;
  }

  .app-versie {
    font-size: 13px;
    margin-left: 5px;
  }
`;

const NavigatieHeader = observer(() => {
  const { memoStore, gebruikerStore, technischStore } = useContext(RootStoreContext);

  const memosRef = useRef(null);
  const [memosOpen, setMemosOpen] = useState(false);

  const technisheInfoRef = useRef(null);
  const [technischeInfoOpen, setTechnischeInfoOpen] = useState(false);
  const functiesRef = useRef(null);
  const [functiesOpen, setFunctiesOpen] = useState(false);

  const containerRef = useRef(null);

  const memosAantal = useMemo(() => {
    if (memoStore.memos.state === ERemoteDataState.Pending || gebruikerStore.gebruiker === null) {
      return null;
    }
    return memoStore.memos.data!.filter((x) => x.AspGebrID === gebruikerStore.gebruiker!.AspGebrID)
      .length;
  }, [memoStore.memos, gebruikerStore.gebruiker]);

  return (
    <Container ref={containerRef}>
      <Header className="flex-fill">
        <Link to="/">
          <SplashLogo />
        </Link>
        <div className="d-flex align-items-center" style={{ marginTop: 10 }}>
          <div className="app-name">ASP Systeem</div>
          {process.env.REACT_APP_ENV !== 'PROD' ? (
            <div className="app-env">{process.env.REACT_APP_ENV}</div>
          ) : (
            <div className="app-versie">{process.env.REACT_APP_VERSIE}</div>
          )}
          <div className="flex-fill" />
        </div>
      </Header>

      <div className="d-flex flex-column justify-content-end mb-2" style={{ minWidth: 30 }}>
        <button
          ref={technisheInfoRef}
          onClick={() => setTechnischeInfoOpen(true)}
          style={{
            background: 0,
            outline: 0,
            padding: 0,
            border: 0,
            marginBottom: 5,
          }}
          className="d-flex align-items-start"
        >
          <IconInformatie
            style={{
              width: 19,
              height: 19,
              fill: Kleur.LichtGrijs,
            }}
          />
        </button>

        <div className="mt-3"></div>

        <button
          ref={functiesRef}
          onClick={() => setFunctiesOpen(true)}
          style={{
            background: 0,
            outline: 0,
            padding: 0,
            border: 0,
            marginBottom: 8,
          }}
          className="d-flex align-items-start"
        >
          <IconFuncties
            style={{
              width: 19,
              height: 19,
              fill: Kleur.LichtGrijs,
            }}
          />
        </button>

        <button
          ref={memosRef}
          onClick={() => setMemosOpen(true)}
          style={{
            background: 0,
            outline: 0,
            padding: 0,
            border: 0,
            marginBottom: 5,
          }}
          className="d-flex align-items-start"
        >
          <IconMemo
            style={{
              width: 18,
              height: 18,
              fill: Kleur.LichtGrijs,
            }}
          />
          {memosAantal !== null && memosAantal > 0 && <Badge aantal={memosAantal} />}
        </button>

        {/*<NotificatieKnop />*/}

        <Overlay
          placement="right"
          target={technisheInfoRef.current!}
          show={technischeInfoOpen}
          rootClose
          onHide={() => setTechnischeInfoOpen(false)}
          container={containerRef as any}
        >
          {({ placement, arrowProps, popper, ...props }: any) => (
            <div {...props}>
              <TechnischeInfoOverlay />
            </div>
          )}
        </Overlay>

        <Overlay
          placement="right"
          target={functiesRef.current!}
          show={functiesOpen}
          rootClose
          onHide={() => setFunctiesOpen(false)}
          container={containerRef as any}
        >
          {({ placement, arrowProps, popper, ...props }: any) => (
            <div {...props}>
              <FunctiesOverlay />
            </div>
          )}
        </Overlay>

        <Overlay
          placement="right"
          target={memosRef.current!}
          show={memosOpen}
          rootClose
          onHide={() => setMemosOpen(false)}
          container={containerRef as any}
        >
          {({ placement, arrowProps, popper, ...overlayProps }: any) => (
            <div {...overlayProps}>
              <MemosOverlay />
            </div>
          )}
        </Overlay>
      </div>
    </Container>
  );
});

export default NavigatieHeader;
