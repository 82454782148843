import { string } from 'yup';
import { PERSOON, ORGANISATIE } from './constanten';

// tslint:disable-next-line: max-line-length
// export const REGEX_TELEFOON_VAST = /^(((0)[1-9]{2}[0-9][-]?[1-9][0-9]{5})|((\\+31|0|0031)[1-9][0-9][-]?[1-9][0-9]{6}))$/;
// export const REGEX_TELEFOON_MOBIEL = /^(((\\+31|0|0031)6){1}[1-9]{1}[0-9]{7})$/i;

/**
 * Combineert regexes op basis van een OR expressie, waardoor de nieuwe expressie moet voldoen
 * aan een van de expressies opgegeven in de lijst.
 */
export const combineerRegexesMetORExpressie = (regexes: RegExp[]): RegExp =>
  new RegExp(regexes.map((r) => `(${r.source})`).join('|'));

export const REGEX_TELEFOON_MOBIEL_NL = /(((\+31|0)6){1}-?[1-9]{1}[0-9]{7})/;
export const REGEX_TELEFOON_MOBIEL_DE = /\+49\d{10}/;
export const REGEX_TELEFOON_MOBIEL_FR = /\+33\d{10}/;
export const REGEX_TELEFOON_MOBIEL_IT = /\+39\d{10}/;

export const REGEX_TELEFOON_VAST_NL = /(\+31\d{8,10})|(\d{3}-?\d{7})/;

export const REGEX_EMAIL = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
export const REGEX_TELEFOON_VAST = /.*/;
export const REGEX_TELEFOON_MOBIEL = combineerRegexesMetORExpressie([
  REGEX_TELEFOON_MOBIEL_NL,
  REGEX_TELEFOON_MOBIEL_DE,
  REGEX_TELEFOON_MOBIEL_FR,
  REGEX_TELEFOON_MOBIEL_IT,
]);
export const REGEX_TELEFOON = combineerRegexesMetORExpressie([
  REGEX_TELEFOON_MOBIEL,
  REGEX_TELEFOON_VAST,
]);
export const REGEX_IBAN = /^[a-zA-Z]{2}[0-9]{2}[a-zA-Z0-9]{4}[0-9]{7}([a-zA-Z0-9]?){0,16}$/i;
export const REGEX_KVKNUMMER = /.*/;
export const REGEX_BISNUMMER = /^[a-z0-9.\-\/\(/)]+$/i;
export const REGEX_POSTCODE_NL = /^[1-9][0-9]{3}[\s]?[A-Za-z]{2}$/i;
export const REGEX_POSTCODE_BE = /\d{4}$/g;
export const REGEX_POSTCODE_DE = /\d{5}$/g;
// export const REGEX_IBAN = /^([A-Z]{2}[ \-]?[0-9]{2})(?=(?:[ \-]?[A-Z0-9]){9,30}$)((?:[ \-]?[A-Z0-9]{3,5}){2,7})([ \-]?[A-Z0-9]{1,3})?$/;

interface IErrorObject {
  [key: string]: string;
}

export const valideerBasisGegevens = (values: any | null) => {
  const errors: IErrorObject = {};
  if (!values.voornaam) {
    errors.voornaam = 'Voornaam is vereist';
  }
  if (!values.voorletters) {
    errors.voorletters = 'Voorletter(s) zijn vereist';
  }
  if (!values.achternaam) {
    errors.achternaam = 'Achternaam is vereist';
  }
  if (!values.email) {
    errors.email = 'Email is vereist';
  }
  if (values.email.length > 0 && !REGEX_EMAIL.test(values.email)) {
    errors.email = 'Email is niet geldig';
  }
  return errors;
};

export const valideerOrganisatieGegevens = (values: any | null) => {
  const errors: IErrorObject = {};
  if (values.relatiesoort === ORGANISATIE) {
    if (!values.organisatienaam) {
      errors.organisatienaam = 'Organisatienaam is vereist';
    }
    if (!values.organisatienaamKort) {
      errors.organisatienaamKort = 'Organisatienaam kort is vereist';
    }
  }
  return errors;
};

export const valideerIban = (values: any | null) => {
  const errors: IErrorObject = {};
  if (!values.iban && !values.rekeningnaam) {
    return errors;
  }
  if (!values.iban) {
    errors.iban = 'Iban is vereist';
  } else if (!REGEX_IBAN.test(values.iban)) {
    errors.iban = 'Iban is niet geldig';
  }
  return errors;
};

export const valideerRekeningnaam = (values: any | null) => {
  const errors: IErrorObject = {};
  if (!values.iban && !values.rekeningnaam) {
    return errors;
  }
  if (!values.rekeningnaam) {
    errors.rekeningnaam = 'Rekeningnaam is vereist';
  }
  return errors;
};

export const valideerKvknummer = (values: any | null) => {
  const errors: IErrorObject = {};
  if (!values.kvknummer) {
    return errors;
  } else if (!REGEX_KVKNUMMER.test(values.kvknummer)) {
    errors.kvknummer = 'KVK nummer is niet geldig';
  }
  return errors;
};

export const valideerAdres = (values: any) => {
  const errors: IErrorObject = {};

  let regex;
  switch (values.landAdres) {
    case 'NL':
      regex = REGEX_POSTCODE_NL;
      break;
    case 'BE':
      regex = REGEX_POSTCODE_BE;
      break;
    case 'DE':
      regex = REGEX_POSTCODE_DE;
      break;
    default:
      regex = REGEX_POSTCODE_NL;
      break;
  }

  if (
    !values.postcode &&
    !values.huisnummer &&
    !values.straatnaam &&
    !values.plaatsnaam &&
    !values.bisnummer
  ) {
    return errors;
  } else {
    if (!values.postcode) {
      errors.postcode = 'Postcode is vereist';
    }
    if (!values.huisnummer) {
      errors.huisnummer = 'Huisnummer is vereist';
    }
    if (!values.straatnaam) {
      errors.straatnaam = 'Straatnaam is vereist';
    }
    if (!values.plaatsnaam) {
      errors.plaatsnaam = 'Plaatsnaam is vereist';
    }
    if (values.postcode.length > 0 && !values.postcode.match(regex)) {
      errors.postcode = 'Postcode is niet geldig';
    }
    if (values.bisnummer.length > 0 && !values.bisnummer.match(REGEX_BISNUMMER)) {
      errors.bisnummer = 'Bisnummer is niet geldig';
    }
  }
  return errors;
};

export const valideerBetalingstermijn = (values: any) => {
  const errors: IErrorObject = {};
  if (values.betalingstermijn < 0) {
    errors.betalingstermijn = 'Betalingstermijn is niet geldig';
  }
  return errors;
};

// oud, met Yup
export const telefoonMobielValidatie = {
  telefoonMobiel: string().matches(REGEX_TELEFOON_MOBIEL, {
    message: 'Telefoon mobiel is niet geldig.',
    excludeEmptyString: true,
  }),
};

export const telefoonVastValidatie = {
  telefoonExtra: string().matches(REGEX_TELEFOON_VAST, {
    message: 'Telefoon vast is niet geldig.',
    excludeEmptyString: true,
  }),
};
