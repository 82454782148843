import React, { useMemo, useCallback, useState, useEffect } from 'react';
import IDialoogProps from '../../../../core/IDialoogProps';
import Dialoog from '../../../../components/dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import { FormikActions, Formik, Field, FieldProps } from 'formik';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import Modal from 'react-bootstrap/Modal';
import nameOf from '../../../../core/nameOf';
import * as Yup from 'yup';
import teksten from '../../../../bedrijfslogica/teksten';
import FormikVeldFout from '../../../../components/formulier/FormikVeldFout';
import api from '../../../../api';
import Combobox, { IOptie } from '../../../../components/formulier/Combobox';
import { ISjabloonCategorie } from '../../../../../../shared/src/api/v2/Sjabloon/sjabloon';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';

interface IFormikValues {
  naam: string;
  sjabCatID: number | null;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  naam: 'Naam',
  sjabCatID: 'Categorie',
};

interface IProps extends IDialoogProps {}

const ToevoegenDialoog: React.FC<IProps> = (props) => {
  const [categorieen, setCategorieen] = useState<ISjabloonCategorie[] | null>(null);

  const categorieOpties = useMemo<IOptie<number>[] | null>(() => {
    if (categorieen === null) {
      return null;
    }

    return categorieen.map((categorie) => ({
      id: categorie.SjabCatID,
      label: categorie.Naam,
    }));
  }, [categorieen]);

  useEffect(() => {
    (async () => {
      const result = await api.v2.sjabloon.ophalenSjabloonCategorieen({
        filterSchema: {
          filters: [],
        },
      });
      setCategorieen(result.categorieen);
    })();
  }, []);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        naam: Yup.string()
          .required(teksten.formulier.E_VERPLICHT_VELD({ veldnaam: 'Naam' }))
          .min(
            1,
            teksten.formulier.E_MINIMAAL_AANTAL_KARAKTERS_BEVATTEN({ veldnaam: 'Naam', aantal: 1 }),
          ),
      }),
    [],
  );

  const initialValues = useMemo<IFormikValues>(() => {
    return {
      naam: '',
      sjabCatID: null,
    };
  }, []);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      await api.v2.sjabloon.toevoegenSjabloon({
        naam: values.naam.trim(),
        sjabCatID: values.sjabCatID,
      });
      props.onSuccess(null);
      actions.setSubmitting(false);
    },
    [props.onSuccess],
  );

  return (
    <Dialoog index={props.dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Sjabloon toevoegen</ModalTitle>
      </ModalHeader>
      <Formik<IFormikValues>
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        render={(formikProps) => {
          const { submitForm, isSubmitting, isValid } = formikProps;
          return (
            <>
              <ModalBody>
                <div className="form-group">
                  <div className="row">
                    <div className="col-12">
                      <label>Naam</label>
                      <Field
                        name={nameOf<IFormikValues>('naam')}
                        render={(fieldProps: FieldProps<IFormikValues>) => {
                          return (
                            <>
                              <input type="text" {...fieldProps.field} className="form-control" />
                              <FormikVeldFout fieldProps={fieldProps} />
                            </>
                          );
                        }}
                      />
                    </div>

                    <div className="col-12 mt-2">
                      <label>{veldnamen.sjabCatID}</label>
                      <Field
                        name={nameOf<IFormikValues>('sjabCatID')}
                        render={(fieldProps: FieldProps<IFormikValues>) => {
                          const { field, form } = fieldProps;
                          if (categorieOpties === null) {
                            return <LoadingSpinner />;
                          }
                          return (
                            <div>
                              <Combobox
                                geselecteerd={field.value}
                                opties={categorieOpties}
                                isWisbaar
                                options={{ legeOptieTekst: 'Geen categorie' }}
                                onSelectieChange={(x) => form.setFieldValue(field.name, x)}
                              />
                              <FormikVeldFout fieldProps={fieldProps} />
                            </div>
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
              </ModalBody>
              <ModalFooter className="d-flex flex-row justify-content-start">
                <button
                  className="btn btn-primary"
                  onClick={submitForm}
                  style={{ width: 100 }}
                  disabled={isSubmitting || !isValid}
                >
                  Ok
                </button>
                <button
                  className="btn btn-secondary"
                  onClick={props.onAnnuleren}
                  style={{ width: 100 }}
                  disabled={isSubmitting}
                >
                  Annuleren
                </button>
              </ModalFooter>
            </>
          );
        }}
      />
    </Dialoog>
  );
};

export default ToevoegenDialoog;
