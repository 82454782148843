import React from 'react';
import { Kleur } from '../../bedrijfslogica/constanten';

interface IProps {
  menu?: JSX.Element;
  body?: JSX.Element;
}

const MenuLayout: React.FC<IProps> = (props) => {
  const { menu, body } = props;
  return (
    <div className="d-flex flex-fill flex-column overflow-hidden w-100 h-100">
      {menu !== undefined && (
        <div
          className="d-flex flex-column p-3"
          style={{
            backgroundColor: Kleur.HeelLichtGrijs,
            borderBottom: `1px solid ${Kleur.LichtGrijs}`,
          }}
        >
          {menu}
        </div>
      )}

      <div
        style={{ height: `100%`, width: '100%' }}
        className="overflow-auto flex-fill d-flex flex-column"
      >
        {body}
      </div>
    </div>
  );
};

export default MenuLayout;
