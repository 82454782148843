import * as React from 'react';
import { useCallback, useEffect, useMemo, useRef, useState, useContext } from 'react';
import styled from 'styled-components';
import { IOphalenTerugbelverzoekenResultElement } from '../../../../../shared/src/api/v2/terugbelverzoek';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../models/IRemoteData';
import api, { IPaginatiePositie } from '../../../api';
import { ASPKolom, EAspKolomBreedteType } from '../../../components/tabel/ASPTabel/types';
import { format } from 'date-fns';
import ASPTabel from '../../../components/tabel/ASPTabel';
import LoadingSpinner from '../../../components/Gedeeld/LoadingSpinner';
import MenuLayout from '../../../components/MenuLayout';
import FilterBalkV2, {
  IFilter,
  IFilterData,
  maakFilterSchema,
} from '../../../components/FilterBalkV2';
import TelefoonoproepVisualisatie, {
  EWeergaveModus,
} from '../../../components/communicatie/TelefoonoproepVisualisatie';
import { IOphalenPersonenResultElementV2 } from '../../../../../shared/src/api/v2/persoon/persoon';
import { IOphalenOrganisatiesResultElement } from '../../../../../shared/src/api/v2/organisatie/organisatie';
import PersoonVisualisatie from '../../../components/personalia/PersoonVisualisatie';
import OrganisatieVisualisatie from '../../../components/personalia/OrganisatieVisualisatie';
import TelefoonComponent from '../../../components/communicatie/TelefoonComponent';
import useUrlState from '../../../core/useUrlState';
import { RouteComponentProps } from 'react-router-dom';
import ActieMenuKnop from '../../../components/ActieMenuKnop';
import { IconCheck, IconSend } from '../../../components/Icons';
import { Kleur } from '../../../bedrijfslogica/constanten';
import { RootStoreContext } from '../../../stores/RootStore';
import { EResultType } from '../../../stores/CheckStore';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

interface IData {
  terugbelverzoeken: { [index: number]: IOphalenTerugbelverzoekenResultElement };
  personenBijTelefoonnummer: { [telefoonnummer: string]: IOphalenPersonenResultElementV2[] };
  organisatiesBijTelefoonnummer: { [telefoonnummer: string]: IOphalenOrganisatiesResultElement[] };
  totaalAantal: number;
}

type Kolom =
  | 'datum'
  | 'telefoonnummer'
  | 'persoon_of_organisatie'
  | 'afgehandeld_middels'
  | 'geannuleerd_op'
  | 'acties';
type Filters = 'IS_AFGEHANDELD' | 'IS_GEANNULEERD';

const STANDAARD_PAGINATIE: IPaginatiePositie = { index: 0, aantal: 50 };

export interface ITerugbelverzoekUrlState {
  focusRijID: number | null;
  selectie: number[];
}

interface IProps extends RouteComponentProps {}

const Terugbelverzoek = (props: IProps) => {
  const { checkStore } = useContext(RootStoreContext);

  const defaultUrlState = useMemo<ITerugbelverzoekUrlState>(() => {
    return {
      focusRijID: null,
      selectie: [],
    };
  }, []);
  // tslint:disable-next-line:variable-name
  const [urlState, _setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);

  const dataRef = React.useRef<IRemoteData<IData>>(createPendingRemoteData());
  // tslint:disable-next-line:variable-name
  const [data, _setData] = useState<IRemoteData<IData>>(dataRef.current!);
  const setData = useCallback((data: IRemoteData<IData>) => {
    dataRef.current = data;
    _setData(data);
  }, []);
  const [filterData, setFilterData] = useState<IFilterData<Filters>[]>([
    {
      naam: 'IS_AFGEHANDELD',
      isActief: true,
      data: false,
    },
    {
      naam: 'IS_GEANNULEERD',
      isActief: true,
      data: false,
    },
  ]);
  const filterSchema = useMemo(() => maakFilterSchema(filterData), [filterData]);

  const ophalenTerugbelverzoekenAbortController = useRef<AbortController | null>(null);
  const ophalenTerugbelverzoeken = useCallback(
    async (paginatie: IPaginatiePositie, uitbreiden = true) => {
      if (ophalenTerugbelverzoekenAbortController.current !== null) {
        ophalenTerugbelverzoekenAbortController.current.abort();
      }
      const abortController = new AbortController();
      ophalenTerugbelverzoekenAbortController.current = abortController;

      const result = await api.v2.terugbelverzoek.ophalenTerugbelverzoeken(
        {
          filterSchema,
          orderSchema: {
            orders: [
              {
                naam: 'DATUM',
                richting: 'DESC',
              },
            ],
          },
          paginatie,
        },
        abortController.signal,
      );

      const basis = (uitbreiden ? dataRef.current.data?.terugbelverzoeken : {}) ?? {};
      const terugbelverzoeken = result.verzoeken.reduce<IData['terugbelverzoeken']>(
        (acc, curr, i) => {
          acc[paginatie.index + i] = curr;
          return acc;
        },
        basis,
      );

      let personenBijTelefoonnummer =
        (uitbreiden ? dataRef.current.data?.personenBijTelefoonnummer : {}) ?? {};
      let organisatiesBijTelefoonnummer =
        (uitbreiden ? dataRef.current.data?.organisatiesBijTelefoonnummer : {}) ?? {};
      if (result.verzoeken.length > 0) {
        const telefoonnummers = new Set(result.verzoeken.map((x) => x.Telefoonnummer));
        const [personenResult, organisatiesResult] = await Promise.all([
          api.v2.persoon.ophalenPersonen(
            {
              filterSchema: {
                filters: [
                  {
                    naam: 'TELEFOON_MOBIELS',
                    data: [...telefoonnummers],
                  },
                ],
              },
            },
            abortController.signal,
          ),
          api.v2.organisatie.ophalenOrganisaties(
            {
              filterSchema: {
                filters: [
                  {
                    naam: 'TELEFOONNUMMERS',
                    data: [...telefoonnummers],
                  },
                ],
              },
            },
            abortController.signal,
          ),
        ]);
        personenBijTelefoonnummer = personenResult.personen.reduce<
          IData['personenBijTelefoonnummer']
        >((acc, curr) => {
          const telefoonnummer = curr.TelefoonMobiel;
          if (telefoonnummer === null) {
            return acc;
          }
          const personen = acc[telefoonnummer] ?? [];
          if (personen.some((x) => x.PersID === curr.PersID)) {
            return acc;
          }
          personen.push(curr);
          acc[telefoonnummer] = personen;
          return acc;
        }, personenBijTelefoonnummer);

        organisatiesBijTelefoonnummer = organisatiesResult.organisaties.reduce<
          IData['organisatiesBijTelefoonnummer']
        >((acc, curr) => {
          const telefoonnummer = curr.Telefoonnummer;
          if (telefoonnummer === null) {
            return acc;
          }
          const organisaties = acc[telefoonnummer] ?? [];
          if (organisaties.some((x) => x.OrgID === curr.OrgID)) {
            return acc;
          }
          organisaties.push(curr);
          acc[telefoonnummer] = organisaties;
          return acc;
        }, organisatiesBijTelefoonnummer);
      }

      const data: IData = {
        totaalAantal: result.totaalAantal,
        terugbelverzoeken,
        personenBijTelefoonnummer,
        organisatiesBijTelefoonnummer,
      };
      setData(createReadyRemoteData(data));
    },
    [filterSchema],
  );

  useEffect(() => {
    ophalenTerugbelverzoeken(STANDAARD_PAGINATIE, false);
  }, [ophalenTerugbelverzoeken]);

  const handleExtraRijenAangevraagd = useCallback(
    async (positie: IPaginatiePositie) => {
      await ophalenTerugbelverzoeken(positie);
    },
    [ophalenTerugbelverzoeken],
  );

  const keyExtractor = useCallback((row: IOphalenTerugbelverzoekenResultElement) => row.ID, []);
  const kolommen = useMemo<ASPKolom<Kolom, IOphalenTerugbelverzoekenResultElement>[]>(
    () => [
      {
        key: 'datum',
        label: 'Datum',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 200,
        renderer: (row) => format(new Date(row.Datum), 'dd-MM-yyyy HH:mm'),
      },
      {
        key: 'telefoonnummer',
        label: 'Telefoonnummer',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 200,
        renderer: (row) => <TelefoonComponent telefoonNummer={row.Telefoonnummer} />,
      },
      {
        key: 'persoon_of_organisatie',
        label: 'Persoon / Organisatie',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 275,
        renderer: (row) => {
          const personen =
            dataRef.current!.data!.personenBijTelefoonnummer[row.Telefoonnummer] ?? [];
          const organisaties =
            dataRef.current!.data!.organisatiesBijTelefoonnummer[row.Telefoonnummer] ?? [];
          if (personen.length === 0 && organisaties.length === 0) {
            return '- -';
          }
          const totaalAantal = personen.length + organisaties.length;
          if (totaalAantal > 1) {
            return <span className="font-italic">Niet eenduidig</span>;
          }
          const persoon = personen[0] ?? null;
          if (persoon !== null) {
            return <PersoonVisualisatie persID={persoon.PersID} />;
          }
          const organisatie = organisaties[0]!;
          return <OrganisatieVisualisatie orgID={organisatie.OrgID} />;
        },
      },
      {
        key: 'afgehandeld_middels',
        label: 'Afgehandeld middels',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 225,

        renderer: (row) => {
          if (row.AfgehandeldMiddels_TelOproepID === null && !row.HandmatigAfgehandeld) {
            return '- -';
          }
          if (row.HandmatigAfgehandeld) {
            return 'Handmatig';
          }

          return 'Oproep';

          // return (
          //   <TelefoonoproepVisualisatie
          //     weergaveModus={EWeergaveModus.NummerEnContact}
          //     telOprID={row.AfgehandeldMiddels_TelOproepID!}
          //   />
          // );
        },
      },
      {
        key: 'geannuleerd_op',
        label: 'Geannuleerd op',
        breedteType: EAspKolomBreedteType.Flex,
        flex: 1,
        renderer: (row) => {
          if (row.GeannuleerdOp === null) {
            return '- -';
          }
          return format(new Date(row.GeannuleerdOp), 'dd-MM-yyyy HH:mm');
        },
      },
      {
        key: 'acties',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 100,
        renderer: (rij) => {
          return (
            <ActieMenuKnop
              acties={[
                {
                  text: 'Handmatig afhandelen',
                  icon: <IconCheck style={{ fill: Kleur.Grijs, width: 18, height: 18 }} />,
                  disabled: rij.AfgehandeldMiddels_TelOproepID !== null || rij.HandmatigAfgehandeld,
                  onClick: async () => {
                    await api.v2.terugbelverzoek.handmatigAfhandelenTerugbelverzoek({
                      id: rij.ID,
                    });
                    await ophalenTerugbelverzoeken(STANDAARD_PAGINATIE, false);
                  },
                },
              ]}
            />
          );
        },
      },
    ],
    [ophalenTerugbelverzoeken],
  );

  const handleVerwijderenRij = useCallback(
    async (rij: IOphalenTerugbelverzoekenResultElement) => {
      await api.v2.terugbelverzoek.verwijderenTerugbelverzoeken({ ids: [rij.ID] });
      await ophalenTerugbelverzoeken(STANDAARD_PAGINATIE, false);
    },
    [ophalenTerugbelverzoeken],
  );

  const filters = useMemo<IFilter<Filters>[]>(
    () => [
      {
        naam: 'IS_AFGEHANDELD',
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return <span>Nog niet afgehandeld</span>;
        },
      },
      {
        naam: 'IS_GEANNULEERD',
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return <span>Niet geannuleerd</span>;
        },
      },
    ],
    [],
  );

  const handleVersturenContactverzoeken = useCallback(async () => {
    if (
      (
        await checkStore.bevestigen({
          inhoud: 'Wil je naar de geselecteerde contacten een contactverzoek sturen per SMS?',
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    await api.v2.terugbelverzoek.versturenContactverzoeken({
      ids: urlState.selectie,
    });

    setUrlStateSync('selectie', []);
    // ophalenTerugbelverzoeken();
  }, [urlState.selectie]);

  return (
    <Root>
      <MenuLayout
        menu={
          <div className="d-flex align-items-center">
            <button
              className="btn btn-sm btn-light d-flex align-items-center"
              style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
              disabled={urlState.selectie.length === 0}
              onClick={() => handleVersturenContactverzoeken()}
            >
              {/* <IconSend style={{ width: 16, height: 16, fill: Kleur.Grijs }} /> */}
              <span className="ml-2">Contactverzoek</span>
            </button>
            <div className="d-flex flex-fill ml-3">
              <FilterBalkV2
                filters={filters}
                filterData={filterData}
                onFilterDataChange={setFilterData}
              />
            </div>
          </div>
        }
        body={
          data.state === ERemoteDataState.Pending ? (
            <LoadingSpinner />
          ) : (
            <ASPTabel
              rijen={data.data!.terugbelverzoeken}
              kolommen={kolommen}
              keyExtractor={keyExtractor}
              totaalAantalRijen={data.data!.totaalAantal}
              onExtraRijenAangevraagd={handleExtraRijenAangevraagd}
              onVerwijderenRij={handleVerwijderenRij}
              gefocustRij={urlState.focusRijID ?? undefined}
              selectie={urlState.selectie}
              onSelectieChange={(selectie) => setUrlStateSync('selectie', selectie)}
            />
          )
        }
      />
    </Root>
  );
};

export default Terugbelverzoek;
