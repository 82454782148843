import React, { useCallback, useMemo, useRef } from 'react';
import { CenterContainer, ContactsContainer, NoContactsMessageContainer, Root } from './style';
import LoadingSpinner from '../../../Gedeeld/LoadingSpinner';
import ContactItem from './ContactItem';
import TopBarSection from './TopBarSection';
import { IContact, INewContact, IOptions } from '../index';
import { IAsyncObservableAction } from '../../../../core/asyncObservableAction';

export interface IShouldFetchMoreContactsResult {
  canFetchMore: boolean;
}

interface IProps {
  contacts: (IContact | INewContact)[] | null;
  onSelectedContactNumberChange: (value: string | null) => void;
  selectedContactNumber: string | null;
  onNewContact: (contact: INewContact) => void;
  onContactChange: (contact: IContact | INewContact) => void;
  onRequestFilter: (search: string | null) => void;
  shouldFetchMoreContacts: (
    action: IAsyncObservableAction<IShouldFetchMoreContactsResult, null>,
  ) => void;
  options: IOptions;
}

const defaultOptions: IOptions = {
  contacten: {
    topBar: {
      opstellenVerbergen: false,
    },
  },
};

const ContactsSection: React.FC<IProps> = (props) => {
  const opts = useMemo(
    () => ({
      ...defaultOptions,
      ...props.options,
      contacten: {
        ...defaultOptions.contacten,
        ...props.options.contacten,
        topBar: {
          ...defaultOptions.contacten!.topBar,
          ...(defaultOptions.contacten === undefined ? undefined : defaultOptions.contacten.topBar),
        },
      },
    }),
    [props.options],
  );

  const isFetchingMoreContacts = useRef<boolean>(false);
  const canFetchMoreContacts = useRef<boolean>(true);
  const handleScroll = useCallback(
    (ev: React.UIEvent<HTMLDivElement>) => {
      const canFetchMore = canFetchMoreContacts.current;
      if (!canFetchMore) {
        return;
      }
      const el = ev.currentTarget!;
      const isFetchingMore = isFetchingMoreContacts.current;
      const isFullyScrolled = el.scrollHeight - el.scrollTop === el.clientHeight;

      if (isFetchingMore || !isFullyScrolled) {
        return;
      }
      isFetchingMoreContacts.current = true;
      props.shouldFetchMoreContacts({
        resolver: (data) => {
          canFetchMoreContacts.current = data.canFetchMore;
          isFetchingMoreContacts.current = false;
        },
        rejector: (err) => {
          console.log(err);
        },
      });
    },
    [props.shouldFetchMoreContacts, isFetchingMoreContacts.current, canFetchMoreContacts.current],
  );

  return (
    <Root>
      <TopBarSection
        onNewContact={props.onNewContact}
        onRequestFilter={props.onRequestFilter}
        options={opts}
      />
      {props.contacts === null ? (
        <CenterContainer>
          <LoadingSpinner />
        </CenterContainer>
      ) : props.contacts.length === 0 ? (
        <CenterContainer>
          <NoContactsMessageContainer>Geen contacten beschikbaar.</NoContactsMessageContainer>
        </CenterContainer>
      ) : (
        <ContactsContainer className="list-group list-group-flush" onScroll={handleScroll}>
          {props.contacts.map((contact) => {
            const isSelected = contact.phoneNumber === props.selectedContactNumber;

            const id =
              (contact as IContact).id !== undefined
                ? (contact as IContact).id
                : (contact as INewContact).tempId;
            return (
              <ContactItem
                key={id}
                contact={contact}
                selected={isSelected}
                onClick={() => props.onSelectedContactNumberChange(contact.phoneNumber)}
                onContactChange={props.onContactChange}
              />
            );
          })}
        </ContactsContainer>
      )}
    </Root>
  );
};

export default ContactsSection;
