import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { RootStoreContext } from '../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import { Route, Switch, RouteComponentProps } from 'react-router-dom';
import Klantkaart from './Klantkaart';
import LoadingSpinner from '../../components/Gedeeld/LoadingSpinner';
import api from '../../api';

interface IRouteComponentProps {
  relID?: string;
}

interface IProps extends RouteComponentProps<IRouteComponentProps> {}

export const defaultEntiteitRoute = `/contracten`;

const Klant: React.FC<IProps> = observer((props) => {
  const { gebruikerStore } = useContext(RootStoreContext);
  const relID = useMemo(
    () => (props.match.params.relID === undefined ? null : Number(props.match.params.relID)),
    [props.match.params.relID],
  );

  const [gezochtNaarRelatie, setGezochtNaarRelatie] = useState(false);

  useEffect(() => {
    if (gezochtNaarRelatie) {
      setGezochtNaarRelatie(false);
    }

    if (relID === null) {
      (async () => {
        // Zoeken naar vorige recente relatie
        const lijstRecent = await api.v2.relatieZoeken.ophalenLijstRecent({
          aspGebrID: gebruikerStore.gebruiker!.AspGebrID,
          filterSchema: {
            filters: [
              {
                naam: 'HOEDANIGHEID_NAAM_ENUMS',
                data: ['KLANT'],
              },
            ],
          },
          paginatie: {
            index: 0,
            aantal: 25,
          },
        });

        if (lijstRecent.relaties.length === 0) {
          props.history.push(`/klant/1${defaultEntiteitRoute}`);
          // setGezochtNaarRelatie(true);
          return;
        }

        props.history.push(`/klant/${lijstRecent.relaties[0].RelID}${defaultEntiteitRoute}`);
      })();
    }
  }, [relID]);

  return (
    <div className="d-flex flex-column justify-content-center align-items-center flex-fill">
      <div
        style={{ width: '100%' }}
        className="d-flex flex-column justify-content-center align-items-center flex-fill"
      >
        {gezochtNaarRelatie ? (
          <h1>Er is nog geen relatie geselecteerd.</h1>
        ) : relID === null ? (
          <div className="flex-fill d-flex align-items-center justify-content-center p-2">
            <LoadingSpinner />
          </div>
        ) : (
          <Klantkaart relID={relID} />
        )}
      </div>
    </div>
  );
});

export default Klant;
