import React from 'react';
import RelatieVisualisatie from '../../../../personalia/RelatieVisualisatie';
import { Kleur } from '../../../../../bedrijfslogica/constanten';
import { IconUitklappen, IconInklappen } from '../../../../Icons';

interface IProps {
  relIDs: number[];
  onNavigeerNaarRelatie: (relID: number) => void;
  defaultIsUitgeklapt?: boolean;
}

const RelatiesMetContractenOpDezelfdeLocatie = (props: IProps) => {
  const [isUitgeklapt, setIsUitgeklapt] = React.useState(props.defaultIsUitgeklapt ?? true);
  const Icon = isUitgeklapt ? IconInklappen : IconUitklappen;

  return (
    <div>
      <span className="d-flex align-items-center">
        <span>
          Relaties met contracten op zelfde locatie(s)
          <span className="ml-2 text-muted" style={{ fontSize: 12 }}>
            {props.relIDs.length}
          </span>
        </span>
        <button
          style={{ padding: 0, background: 'none', border: 'none' }}
          className="ml-2"
          onClick={() => setIsUitgeklapt((curr) => !curr)}
        >
          <Icon style={{ width: 21, height: 21, fill: Kleur.Grijs }} />
        </button>
      </span>
      {isUitgeklapt && (
        <div>
          {props.relIDs.map((relID) => (
            <RelatieVisualisatie key={relID} relID={relID} />
          ))}
        </div>
      )}
    </div>
  );
};

export default RelatiesMetContractenOpDezelfdeLocatie;
