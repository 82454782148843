import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import IDialoogProps from '../../../../core/IDialoogProps';
import Dialoog from '../../../../components/dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import * as Yup from 'yup';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../../stores/RootStore';
import { EResultType } from '../../../../stores/CheckStore';
import api from '../../../../api';
import Combobox from '../../../../components/formulier/Combobox';
import nameOf from '../../../../core/nameOf';
import { IOphalenDienstenResultElement } from '../../../../../../shared/src/api/v2/dienst/review';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import { IOphalenInstellingenResult } from '../../../../../../shared/src/api/v2/instelling';

interface IDialoogResult {
  id: number;
}

interface IProps extends IDialoogProps<IDialoogResult> {}

interface IFormikValues {
  reviewdienstID: number | null;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  reviewdienstID: 'Reviewdienst',
};

const VersturenDialoog: React.FC<IProps> = observer((props) => {
  const { dialoogIndex, onAnnuleren, onSuccess, open } = props;
  const { checkStore } = useContext(RootStoreContext);

  const [reviewdiensten, setReviewdiensten] = useState<IOphalenDienstenResultElement[] | null>(
    null,
  );

  const [instelling, setInstelling] = useState<IOphalenInstellingenResult | null>(null);

  useEffect(() => {
    (async () => {
      const instelling = await api.v2.instelling.ophalenInstellingen({});
      setInstelling(instelling);
    })();
  }, []);

  const ophalenReviewdiensten = useCallback(async () => {
    const reviewdienstenResult = await api.v2.dienst.review.ophalenDiensten({
      filterSchema: {
        filters: [
          {
            naam: 'IS_ACTIEF',
            data: true,
          },
        ],
      },
    });
    setReviewdiensten(reviewdienstenResult.diensten);
  }, []);

  useEffect(() => {
    ophalenReviewdiensten();
  }, [ophalenReviewdiensten]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      // if (
      //   (
      //     await checkStore.bevestigen({
      //       inhoud: `Opdracht(en) versturen?`,
      //     })
      //   ).type === EResultType.Annuleren
      // ) {
      //   actions.setSubmitting(false);
      //   return;
      // }

      // const params = {
      //   inkOpdIDs,
      //   opdWijzeID: values.opdrachtwijze!,
      // };
      // const checkData = await api.v2.inkoop.opdracht.checkVersturenOpdrachten(params);

      // const checkResult = await checkStore.controleren({
      //   checkData,
      // });
      // if (checkResult.type === EResultType.Annuleren) {
      //   props.onAnnuleren();
      //   return;
      // }

      // await api.v2.inkoop.opdracht.versturenOpdrachten(params);

      props.onSuccess({ id: values.reviewdienstID! });
      actions.setSubmitting(false);
    },
    [],
  );

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (instelling === null) {
      return null;
    }

    return {
      reviewdienstID: instelling.ReviewDienstID,
    };
  }, [instelling]);

  return (
    <>
      {initialValues === null ? (
        <LoadingSpinner />
      ) : (
        <Dialoog index={dialoogIndex || 0}>
          <ModalHeader>
            <ModalTitle>Versturen review-uitnodigingenn</ModalTitle>
          </ModalHeader>
          <Formik<IFormikValues>
            onSubmit={handleSubmit}
            initialValues={initialValues}
            isInitialValid
            render={(formikProps: FormikProps<IFormikValues>) => {
              const { submitForm, isValid, isSubmitting } = formikProps;

              return (
                <>
                  <ModalBody>
                    <div className="form-group">
                      <div className="row">
                        <div className="col-12 mt-2">
                          <label>{veldnamen.reviewdienstID}</label>
                          <Field
                            name={nameOf<IFormikValues>('reviewdienstID')}
                            render={(fieldProps: FieldProps<IFormikValues>) => {
                              const { field, form } = fieldProps;

                              if (reviewdiensten === null) {
                                return <LoadingSpinner />;
                              }

                              return (
                                <Combobox
                                  geselecteerd={field.value}
                                  opties={reviewdiensten!.map((dienst) => {
                                    return {
                                      id: dienst.ID,
                                      label: dienst.Naam!,
                                    };
                                  })}
                                  onSelectieChange={(x) => form.setFieldValue(field.name, x)}
                                />
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </ModalBody>
                  <ModalFooter className="d-flex flex-row justify-content-start">
                    <button
                      className="btn btn-primary"
                      onClick={submitForm}
                      style={{ width: 100 }}
                      disabled={!isValid || isSubmitting}
                    >
                      Ok
                    </button>
                    <button
                      className="btn btn-secondary"
                      onClick={onAnnuleren}
                      style={{ width: 100 }}
                      disabled={isSubmitting}
                    >
                      Annuleren
                    </button>
                  </ModalFooter>
                </>
              );
            }}
          />
        </Dialoog>
      )}
    </>
  );
});

export default VersturenDialoog;
