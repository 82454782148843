import React, { useCallback, useContext, useState } from 'react';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { observer } from 'mobx-react-lite';
import IDialoogProps from '../../../../core/IDialoogProps';
import { RootStoreContext } from '../../../../stores/RootStore';
import { RealtimeContext } from '../../../../one-off-components/realtime/RealtimeIntegratie';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../models/IRemoteData';
import api from '../../../../api';
import { achtergrondProcesAfwachten } from '../../../../core/achtergrondProces';
import Dialoog from '../../../dialogen/Dialoog';
import ProgressieBalk from '../../../ProgressieBalk';
import {
  IVerwerkenProlongatiesParams,
  IVerwerkenProlongatiesResult,
} from '../../../../../../shared/src/api/v2/prolongatieV2';

interface IProps extends IDialoogProps<null> {
  relID: number | null;
  prolongatieRunIDs: number[];
}

const VerwerkenRunDialoog = observer((props: IProps) => {
  const { open, onSuccess, onAnnuleren, dialoogIndex } = props;
  const realtimeContext = useContext(RealtimeContext);
  const { checkStore } = useContext(RootStoreContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isVerwerkt, setIsVerwerkt] = useState(false);

  const [progressiePercentage, setProgressiePercentage] = useState<IRemoteData<number>>(
    createPendingRemoteData(),
  );

  const handleKnopClick = useCallback(async () => {
    if (isVerwerkt) {
      onSuccess(null);
      return;
    }

    setIsSubmitting(true);

    const params: IVerwerkenProlongatiesParams = {
      relID: props.relID,
      prolongatieRunIDs: props.prolongatieRunIDs,
    };

    const achtergrondProces = await api.v2.prolongatieV2.verwerkenProlongatiesAchtergrondProces(
      params,
    );

    const result = await achtergrondProcesAfwachten<IVerwerkenProlongatiesResult>(
      achtergrondProces.id,
      realtimeContext,
      (percentage) => {
        setProgressiePercentage(createReadyRemoteData(percentage));
      },
    );

    if (result.type === 'ERROR') {
      await checkStore.melden({
        titel:
          'Er heeft een fout plaatsgevonden bij het verwerken van de run(s). Bekijk de console voor meer informatie.',
      });
      console.log('verwerken run error', result.err);
      setIsSubmitting(false);
      return;
    }
    if (result.type === 'TIMEOUT') {
      await checkStore.melden({
        titel:
          'Het verwerken van de run(s) duurt langer dan verwacht. Afgebroken wegens de timeout.',
      });
      setIsSubmitting(false);
      return;
    }

    setIsVerwerkt(true);
    setIsSubmitting(false);
  }, [onSuccess, props.relID, props.prolongatieRunIDs, isVerwerkt]);

  return (
    <Dialoog index={dialoogIndex || 0} modalProps={{}}>
      <ModalBody>
        {progressiePercentage.state === ERemoteDataState.Ready ? (
          <div>
            <span style={{ fontSize: 15 }}>
              De prolongatie wordt verwerkt. Dit kan enige tijd duren.
            </span>
            <div className="mt-2">
              <ProgressieBalk percentage={progressiePercentage.data!} />
            </div>
          </div>
        ) : (
          <div className="p-2">
            <span style={{ fontSize: 15 }}>
              Wil je de prolongatie verwerken?
              <br />
              <br />
              <b>
                Let op: controleer altijd eerst of de parameters (Factuurdatum en Uitvoerdatum
                bankopdrachten) juist ingevuld zijn.
              </b>
            </span>
          </div>
        )}
      </ModalBody>
      <ModalFooter className="d-flex flex-row justify-content-start">
        <button
          className="btn btn-primary"
          onClick={handleKnopClick}
          style={{ width: 100 }}
          disabled={isSubmitting}
        >
          {isVerwerkt ? 'Sluiten' : 'Verwerken'}
        </button>
        {!isVerwerkt && (
          <button
            className="btn btn-secondary"
            onClick={onAnnuleren}
            style={{ width: 100 }}
            disabled={isSubmitting}
          >
            Annuleren
          </button>
        )}
      </ModalFooter>
    </Dialoog>
  );
});

export default VerwerkenRunDialoog;
