import React, { useMemo } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Opdrachten from './Opdrachten';
import Bezoektijden from './Bezoektijden';
import { IEntiteitProps } from '../../EntiteitContainer';
import Tabblad, { ITabblad } from '../../../layout/Tabblad';
// import Opdrachtbestanden from './Opdrachten';

interface IProps extends IEntiteitProps, RouteComponentProps {}

export enum ETabblad {
  Opdrachten = 1,
  Bezoektijden = 2,
  // Opdrachtbestanden = 3,
}

const pathMap: Record<ETabblad, string> = {
  [ETabblad.Opdrachten]: '/opdracht',
  [ETabblad.Bezoektijden]: '/bezoektijden',
  // [ETabblad.Opdrachtbestanden]: '/opdrachtbestanden',
};

export interface ITransportContext {
  relID: number;
}

export const TransportContext = React.createContext<ITransportContext>(null as any);

const Transport: React.FC<IProps> = (props) => {
  const tabblad = useMemo<ETabblad | null>(() => {
    const matchKey = Object.keys(pathMap).find((key) => {
      const path = pathMap[Number(key) as ETabblad]!;
      return props.location.pathname.endsWith(path);
    });
    if (matchKey === undefined) {
      props.history.replace(`${props.location.pathname}${pathMap[ETabblad.Opdrachten]}`);
      return null;
    }
    return Number(matchKey) as ETabblad;
  }, [props.location.pathname]);

  const tabbladen = useMemo<ITabblad<ETabblad>[]>(
    () => [
      {
        id: ETabblad.Opdrachten,
        label: 'Opdrachten',
        content: Opdrachten,
      },
      {
        id: ETabblad.Bezoektijden,
        label: 'Bezoektijden',
        content: Bezoektijden,
      },
      // {
      //   id: ETabblad.Opdrachtbestanden,
      //   label: 'Opdrachtbestanden',
      //   content: Opdrachtbestanden,
      // },
    ],
    [],
  );

  const context = useMemo<ITransportContext>(
    () => ({
      relID: props.relID,
    }),
    [props.relID],
  );

  return (
    <TransportContext.Provider value={context}>
      <div className="d-flex flex-column flex-fill flex-fill">
        {tabblad !== null && (
          <Tabblad<ETabblad>
            geselecteerd={tabblad}
            onSelectieChange={(tab) => {
              props.history.push(
                `${props.location.pathname.replace(pathMap[tabblad], '')}${pathMap[tab]}`,
              );
            }}
            tabbladen={tabbladen}
          />
        )}
      </div>
    </TransportContext.Provider>
  );
};

export default withRouter(Transport);
