import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import MenuLayout from '../../../MenuLayout';
import { IEntiteitProps } from '../../EntiteitContainer';
import api from '../../../../api';
import {
  IAfdelingGewijzigdMessageData,
  IAfdelingToegevoegdMessageData,
  IOphalenAfdelingenResult,
  IOphalenAfdelingenResultElement,
} from '../../../../../../shared/src/api/v2/relatie/afdeling';
import ASPTabel from '../../../tabel/ASPTabel';
import { ASPKolom, EAspKolomBreedteType } from '../../../tabel/ASPTabel/types';
import TelefoonComponent from '../../../communicatie/TelefoonComponent';
import EmailComponent from '../../../communicatie/EmailComponent';
import { IconToevoegen } from '../../../Icons';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import { GlobaleRendererContext } from '../../../../one-off-components/GlobaleRenderer';
import AfdelingMuterenDialoog, { IDialoogResult } from './AfdelingMuterenDialoog';
import { useRealtimeListener } from '../../../../one-off-components/realtime/RealtimeIntegratie';
import { RootStoreContext } from '../../../../stores/RootStore';
import { EResultType } from '../../../../stores/CheckStore';

enum EKolom {
  Naam,
  Telefoonnummer,
  Email,
}

interface IProps extends IEntiteitProps {
  // relID: number;
}

const Afdelingen: React.FC<IProps> = (props) => {
  const { checkStore } = useContext(RootStoreContext);

  const globaleRenderer = useContext(GlobaleRendererContext);
  const [afdelingenResult, setAfdelingenResult] = useState<IOphalenAfdelingenResult | null>(null);

  const ophalenAfdelingen = useCallback(async () => {
    const result = await api.v2.relatie.afdeling.ophalenAfdelingen({
      filterSchema: {
        filters: [
          {
            naam: 'REL_IDS',
            data: [props.relID],
          },
        ],
      },
    });

    setAfdelingenResult(result);
  }, [props.relID]);
  useEffect(() => {
    ophalenAfdelingen();
  }, [ophalenAfdelingen]);

  useRealtimeListener(async (naamEnum, data) => {
    if (naamEnum === 'AFDELING_TOEGEVOEGD' || naamEnum === 'AFDELING_GEWIJZIGD') {
      const d = data as IAfdelingToegevoegdMessageData | IAfdelingGewijzigdMessageData;
      if (d.afdeling.OrgID === props.relatie.data?.organisatie?.OrgID) {
        await ophalenAfdelingen();
      }
    }
  });

  const keyExtractor = useCallback((row: IOphalenAfdelingenResultElement) => row.ID, []);

  const kolommen = useMemo<ASPKolom<EKolom, IOphalenAfdelingenResultElement>[]>(
    () => [
      {
        key: EKolom.Naam,
        label: 'Naam',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 200,
        renderer: (row: IOphalenAfdelingenResultElement) => row.Naam,
      },
      {
        key: EKolom.Telefoonnummer,
        label: 'Telefoonnummer',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 200,
        renderer: (row: IOphalenAfdelingenResultElement) =>
          row.Telefoon === null ? null : (
            <TelefoonComponent
              telefoonNummer={row.Telefoon}
              options={{ icoonWeergeven: false }}
              orgID={props.relatie.data?.organisatie?.OrgID}
              persID={props.relatie.data?.persoon?.PersID}
            />
          ),
      },
      {
        key: EKolom.Email,
        label: 'Email',
        breedteType: EAspKolomBreedteType.Flex,
        flex: 1,
        renderer: (row: IOphalenAfdelingenResultElement) =>
          row.Email === null ? null : (
            <EmailComponent
              emailAdres={row.Email}
              options={{ icoonWeergeven: false }}
              orgID={props.relatie.data?.organisatie?.OrgID}
              persID={props.relatie.data?.persoon?.PersID}
            />
          ),
      },
    ],
    [props.relatie],
  );

  const rijen = useMemo<Record<number, IOphalenAfdelingenResultElement>>(() => {
    return afdelingenResult === null
      ? {}
      : afdelingenResult.afdelingen.reduce((acc, curr, i) => {
          return {
            ...acc,
            [i]: curr,
          };
        }, {});
  }, [afdelingenResult]);

  const handleToevoegen = useMemo(
    () =>
      props.relatie.data?.organisatie?.OrgID === undefined
        ? null
        : async () => {
            await globaleRenderer.render<IDialoogResult>((renderProps) => (
              <AfdelingMuterenDialoog
                orgID={props.relatie.data!.organisatie!.OrgID}
                open
                onSuccess={(result) => renderProps.destroy(result)}
                onAnnuleren={() => renderProps.destroy()}
              />
            ));
          },
    [props.relatie.data?.organisatie?.OrgID],
  );

  const handleWijzigenRij = useMemo(
    () =>
      props.relatie.data?.organisatie?.OrgID === undefined
        ? null
        : async (rij: IOphalenAfdelingenResultElement) => {
            await globaleRenderer.render<IDialoogResult>((renderProps) => (
              <AfdelingMuterenDialoog
                orgID={props.relatie.data!.organisatie!.OrgID}
                open
                onSuccess={(result) => renderProps.destroy(result)}
                onAnnuleren={() => renderProps.destroy()}
                orgAfdID={rij.ID}
              />
            ));
          },
    [props.relatie.data?.organisatie?.OrgID],
  );

  return (
    <MenuLayout
      menu={
        <div className="d-flex align-items-center">
          <button
            className="btn btn-sm btn-light d-flex align-items-center"
            style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
            onClick={handleToevoegen ?? undefined}
            disabled={handleToevoegen === null}
          >
            <IconToevoegen style={{ fill: Kleur.Grijs, width: 18, height: 18 }} />
            <span className="ml-2">Toevoegen</span>
          </button>
        </div>
      }
      body={
        <ASPTabel
          rijen={rijen}
          kolommen={kolommen}
          keyExtractor={keyExtractor}
          onWijzigenRij={handleWijzigenRij ?? undefined}
          onVerwijderenRij={async (x) => {
            const params = {
              ids: [x.ID],
            };
            await api.v2.relatie.afdeling.verwijderenAfdelingen(params);
            await ophalenAfdelingen();
          }}
          verwijderenRijConfirmatie={async (x, verwijderen) => {
            const params = {
              ids: [x.ID],
            };

            const checkData = await api.v2.relatie.afdeling.checkVerwijderenAfdelingen(params);

            const checkResult = await checkStore.controleren({
              checkData,
            });
            if (checkResult.type === EResultType.Annuleren) {
              return;
            }

            if (
              (
                await checkStore.bevestigen({
                  inhoud: 'Bevestig verwijderen afdeling',
                })
              ).type === EResultType.Annuleren
            ) {
              return;
            }

            await verwijderen();
          }}
        />
      }
    />
  );
};

export default Afdelingen;
