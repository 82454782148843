import styled from 'styled-components';

export const Root = styled.div`
  height: 50px;
  background-color: #f3f3f3;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const LeftPaneContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 10px;

  button {
    padding: 0 10px;
  }
`;

export const RightPaneContainer = styled.div<{ opstellenVerborgen: boolean }>`
  ${(props) => (props.opstellenVerborgen ? `margin-left: 10px;` : '')}
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 10px;
`;
