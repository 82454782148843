import React from 'react';
import FillWindowWrapper from '../FillWindowWrapper';
import { IconKruis } from '../Icons';
import { Kleur } from '../../bedrijfslogica/constanten';

interface IProps {
  actief: boolean;
  onSluiten?: () => void;
  containerStyle?: React.CSSProperties;
}

const ElementUitvergrotenWrapper: React.FC<IProps> = (props) => {
  if (!props.actief) {
    return props.children || ((<React.Fragment />) as any);
  }

  return (
    <FillWindowWrapper>
      <div
        style={{
          position: 'relative',
          ...props.containerStyle,
        }}
        className="d-flex flex-column flex-fill"
      >
        <div className="d-flex">
          <div className="flex-fill" />
        </div>
        {props.children}
        {props.onSluiten && (
          <div
            style={{
              position: 'absolute',
              top: -50,
              right: -50,
              width: 100,
              height: 100,
              transform: 'rotate(45deg)',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
            }}
            onClick={props.onSluiten}
          >
            <button
              style={{
                background: 0,
                border: 0,
                outline: 0,
                position: 'relative',
                right: -35,
                top: 63,
                transform: 'rotate(-45deg)',
              }}
            >
              <IconKruis style={{ fill: Kleur.Wit, width: 28, height: 28 }} />
            </button>
          </div>
        )}
      </div>
    </FillWindowWrapper>
  );
};

export default ElementUitvergrotenWrapper;
