import { makeObservable, observable } from 'mobx';

interface ISelecteerContactparams {
  persID: number | null;
  orgID: number | null;
}

// tslint:disable no-console
class ContactenStore {
  public geselecteerdContact: ISelecteerContactparams = { persID: null, orgID: null };

  constructor() {
    makeObservable(this, {
      geselecteerdContact: observable,
    });
  }

  public selecteerContact = async (params: ISelecteerContactparams) => {
    this.geselecteerdContact = params;
  };

  public resetSelectie = () => {
    this.geselecteerdContact = { persID: null, orgID: null };
  };
}

export default ContactenStore;
