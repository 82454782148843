import React, { useMemo } from 'react';
import styled from 'styled-components';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { IOphalenContactverzoekenResultElement } from '../../../../../../../shared/src/api/v2/contactverzoek';

const Root = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

interface IProps extends RouteComponentProps {
  contactverzoek: IOphalenContactverzoekenResultElement;
}

const Data = (props: IProps) => {
  const vraag = useMemo(() => {
    const lines = props.contactverzoek.Vraag.split('\n');
    return lines.map((line, index) => (
      <span key={index}>
        {line}
        <br />
      </span>
    ));
  }, [props.contactverzoek.Vraag]);

  return <Root>{vraag}</Root>;
};

export default withRouter(Data);
