import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import IDialoogProps from '../../../../../core/IDialoogProps';
import Dialoog from '../../../../../components/dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import LoadingSpinner from '../../../../../components/Gedeeld/LoadingSpinner';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import * as Yup from 'yup';
import api from '../../../../../api';
import nameOf from '../../../../../core/nameOf';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../../../stores/RootStore';
import VinkVeld from '../../../../../components/formulier/VinkVeld';
import {
  IOphalenPremiumsResultElement,
  IWijzigenPremiumParams,
} from '../../../../../../../shared/src/api/v2/transport/opdracht/premium';
import Combobox from '../../../../../components/formulier/Combobox';
import { EProductsoort } from '../../../../../bedrijfslogica/enums';
import { IOphalenProducttypenResultElement } from '../../../../../../../shared/src/api/v2/product/type';
import ITaalTekst from '../../../../../../../shared/src/models/talen/ITaalTekst';
import MeertaligTekstveld from '../../../../../components/formulier/MeertaligTekstveld';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../../models/IRemoteData';
import { IOphalenTekstenResultElement } from '../../../../../../../shared/src/api/v2/tekst';

interface IProps extends IDialoogProps<null> {
  id: number;
}

interface IFormikValues {
  naam: string;
  naamKort: string;
  naamEnum: string | null;
  actief: boolean;
  criterium: number;
  typeID: number | null;
  opWebsite: boolean;
  info: ITaalTekst[];
}

const veldnamen: Record<keyof IFormikValues, string> = {
  naam: 'Naam',
  naamKort: 'Naam kort',
  naamEnum: 'Naam enum',
  actief: 'Actief',
  criterium: 'Criterium',
  typeID: 'Producttype',
  opWebsite: 'Op website weergeven',
  info: 'Tekst',
};

const WijzigenDialoog: React.FC<IProps> = observer((props) => {
  const { dialoogIndex, onAnnuleren, onSuccess } = props;
  const { checkStore } = useContext(RootStoreContext);
  const formikRef = useRef<Formik<IFormikValues>>(null);

  const [premium, setPremium] = useState<IOphalenPremiumsResultElement | null>(null);
  const [producttypen, setProducttypen] = useState<IOphalenProducttypenResultElement[] | null>(
    null,
  );
  const [teksten, setTeksten] = useState<IRemoteData<IOphalenTekstenResultElement[] | null>>(
    createPendingRemoteData(),
  );

  const ophalenPremium = useCallback(async () => {
    const result = await api.v2.transport.opdracht.premium.ophalenPremiums({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: [props.id],
          },
        ],
      },
    });

    setPremium(result.premiums[0]);
  }, [props.id]);

  useEffect(() => {
    ophalenPremium();
  }, [ophalenPremium]);

  const ophalenProducttypen = useCallback(async () => {
    const result = await api.v2.product.type.ophalenProducttypen({
      filterSchema: {
        filters: [
          {
            naam: 'PRODUCTSOORT_ENUMS',
            data: [EProductsoort.Premium],
          },
        ],
      },
    });

    setProducttypen(result.producttypen);
  }, []);

  useEffect(() => {
    ophalenProducttypen();
  }, [ophalenProducttypen]);

  const ophalenTeksten = useCallback(async () => {
    if (premium == null) {
      setTeksten(createPendingRemoteData());
      return;
    }
    if (premium.Info_TekstID === null) {
      setTeksten(createReadyRemoteData(null));
      return;
    }

    const result = await api.v2.tekst.ophalenTekstenInAlleTalen({
      tekstIDs: [premium.Info_TekstID],
    });
    setTeksten(createReadyRemoteData(result.teksten));
  }, [premium]);

  useEffect(() => {
    ophalenTeksten();
  }, [ophalenTeksten]);

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (premium === null || teksten.state === ERemoteDataState.Pending) {
      return null;
    }
    return {
      naam: premium.Naam,
      naamKort: premium.NaamKort ?? '',
      actief: premium.Actief,
      naamEnum: premium.NaamEnum ?? '',
      criterium: premium.Criterium,
      typeID: premium.producttype !== null ? premium.producttype.TypeID : null,
      opWebsite: premium.OpWebsite,
      info:
        teksten.data === null
          ? []
          : teksten.data!.map((t) => ({
              taalID: t.TaalID,
              tekst: t.Tekst ?? '',
              toepassen: t.Toepassen,
            })),
    };
  }, [premium, teksten]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      if (premium === null) {
        return;
      }
      actions.setSubmitting(true);

      const params: IWijzigenPremiumParams = {
        id: props.id,
        naam: values.naam,
        naamKort: values.naamKort,
        actief: values.actief,
        naamEnum: values.naamEnum !== '' ? values.naamEnum : null,
        criterium: values.criterium,
        typeID: values.typeID,
        opWebsite: values.opWebsite,
        afbeelding_BestandID:
          premium.Afbeelding_Bestand !== null ? premium.Afbeelding_Bestand.ID : null,
        info: values.info,
      };

      await api.v2.transport.opdracht.premium.wijzigenPremium(params);

      actions.setSubmitting(false);
      onSuccess(null);
    },
    [premium],
  );

  const validationSchema = useMemo(() => {
    const fields: Partial<Record<keyof IFormikValues, any>> = {
      naam: Yup.string().required(),
      naamKort: Yup.string().required(),
      actief: Yup.boolean(),
      naamEnum: Yup.string(),
      criterium: Yup.number(),
      typeID: Yup.number(),
      opWebsite: Yup.boolean(),
    };
    return Yup.object().shape(fields);
  }, []);

  return (
    <Dialoog index={dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Wijzigen premium</ModalTitle>
      </ModalHeader>
      {initialValues === null ? (
        <LoadingSpinner />
      ) : (
        <Formik<IFormikValues>
          ref={formikRef}
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
          render={(formikProps: FormikProps<IFormikValues>) => {
            const { submitForm, isSubmitting, isValid } = formikProps;
            return (
              <>
                <ModalBody>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-12">
                        <label>{veldnamen.naam}</label>
                        <Field
                          name={nameOf<IFormikValues>('naam')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            return (
                              <input type="text" className="form-control" {...fieldProps.field} />
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <label>{veldnamen.naamKort}</label>
                        <Field
                          name={nameOf<IFormikValues>('naamKort')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            return (
                              <input type="text" className="form-control" {...fieldProps.field} />
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <label>{veldnamen.info}</label>
                        <Field
                          name={nameOf<IFormikValues>('info')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <MeertaligTekstveld
                                waarden={field.value}
                                onChange={(value) => form.setFieldValue(field.name, value)}
                              />
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <label>{veldnamen.typeID}</label>
                        <Field
                          name={nameOf<IFormikValues>('typeID')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            if (producttypen === null) {
                              return;
                            }
                            return (
                              <>
                                <Combobox
                                  geselecteerd={field.value}
                                  opties={producttypen.map((x) => {
                                    return {
                                      id: x.TypeID,
                                      label: x.Typenaam,
                                    };
                                  })}
                                  onSelectieChange={(x) => form.setFieldValue(field.name, x)}
                                />
                              </>
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <label>{veldnamen.criterium}</label>
                        <Field
                          name={nameOf<IFormikValues>('criterium')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            return (
                              <>
                                <Combobox
                                  geselecteerd={field.value}
                                  opties={[
                                    {
                                      id: 1,
                                      label: 'Altijd meegeven',
                                    },
                                    {
                                      id: 2,
                                      label: 'Bij bestelling',
                                    },
                                  ]}
                                  onSelectieChange={(x) => form.setFieldValue(field.name, x)}
                                />
                              </>
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <span className="d-flex align-items-center">
                          <Field
                            name={nameOf<IFormikValues>('opWebsite')}
                            render={(x: FieldProps<IFormikValues>) => (
                              <VinkVeld
                                aangevinkt={x.field.value}
                                onGewijzigd={(aangevinkt: boolean) =>
                                  x.form.setFieldValue(x.field.name, aangevinkt)
                                }
                              />
                            )}
                          />
                          <span className="ml-2">{veldnamen.opWebsite}</span>
                        </span>
                      </div>

                      <div className="col-12 mt-3">
                        <span className="d-flex align-items-center">
                          <Field
                            name="actief"
                            render={(x: FieldProps<IFormikValues>) => (
                              <VinkVeld
                                aangevinkt={x.field.value}
                                onGewijzigd={(aangevinkt: boolean) =>
                                  x.form.setFieldValue(x.field.name, aangevinkt)
                                }
                              />
                            )}
                          />
                          <span className="ml-2">{veldnamen.actief}</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </ModalBody>

                <ModalFooter className="d-flex flex-row justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    style={{
                      width: 100,
                    }}
                    disabled={isSubmitting || !isValid}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={onAnnuleren}
                    style={{
                      width: 100,
                    }}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Dialoog>
  );
});

export default WijzigenDialoog;
