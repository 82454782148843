import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  DXTableCheckboxComponent,
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
  DXTableToggleCellComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../../../helpers/dxTableGrid';
import {
  Grid,
  Table,
  VirtualTable,
  TableColumnResizing,
  TableHeaderRow,
  TableSelection,
  TableEditColumn,
  TableRowDetail,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  DataTypeProvider,
  EditingState,
  RowDetailState,
  SelectionState,
  Table as TableBase,
} from '@devexpress/dx-react-grid';
import api from '../../../../../../api';
import TabelInspringBlok from '../../../../../../components/layout/TabelInspringBlok';
import { Kleur as EKleur } from '../../../../../../bedrijfslogica/constanten';
import { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router';
import WijzigenOpdrachtDialoog from '../../../../../../components/transport/WijzigenOpdrachtDialoog';
import MenuLayout from '../../../../../../components/MenuLayout';
import {
  EFunctioneleIcon,
  functioneleIconMap,
  IconCancel,
  IconInformatie,
  IconKruis,
  IconToevoegen,
  IconVerwijderen,
  IconWijzigen,
} from '../../../../../../components/Icons';
import { RootStoreContext } from '../../../../../../stores/RootStore';
// import { EResultType } from '../../../../../../stores/CheckStore';
import { ETabblad } from '../../../../../../paginas/Transport/Opdrachten/reservering/KoppelVoorraadProductDialoog';
import VoorraadTypeInfoDialoog from '../../../../../../components/transport/VoorraadTypeInfoDialoog';
import ActieMenuKnop, { IActie } from '../../../../../../components/ActieMenuKnop';
import ToevoegenMontageRegelDialoog from '../../../../../../components/transport/ToevoegenMontageRegelDialoog';
import AnnulerenOpdrachtregelsDialoog from '../../../../../../components/transport/UitstaandTabel/Regels/AnnulerenDialoog';
import {
  EOpdrachtstatusTransport,
  ERegelstatusTransport,
} from '../../../../../../bedrijfslogica/enums';
import AfmeldenDialoog from '../../../../../../components/transport/UitstaandTabel/AfmeldenDialoog';
import { TransportOpdrachtenContext, IRegel as ITransportOpdrachtenRegel } from '../index';
import MultiSelect from '../../../../../../components/formulier/MultiSelect';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../../../models/IRemoteData';
import {
  IOphalenOpdrachtenResultElementV2,
  IOphalenOpdrachtregelsResultElementV2,
  IOphalenTransportopdrachtBestandenResult,
} from '../../../../../../../../shared/src/api/v2/transport/opdracht';
import { IOphalenBestandenResult } from '../../../../../../../../shared/src/api/v2/bestand/bestand';
import LoadingSpinner from '../../../../../../components/Gedeeld/LoadingSpinner';
import useUpload from '../../../../../../core/useUpload';
import BijlagenContainer, { BestandType } from '../../../../../../components/BijlagenContainer';
import BijlageKnop, { EBronModus, ESelectieModus } from '../../../../../../components/BijlageKnop';
import ContractVisualisatie from '../../../../../../components/entiteitVisualisaties/ContractVisualisatie';
import { format } from 'date-fns';
import ReserveringProductComp from '../../../../../../components/kolomveld/transportopdrachtregel/ReserveringProductComp';
import WijzigenProductDialoog from '../../../../../../paginas/Magazijn/Depot/WijzigenProductDialoog';
import ProductReservering, {
  IKoppelDialoogState,
} from '../../../../../../components/transport/PlanningTabel/ProductReservering';
import KoppelenProductDialoog, {
  EKoppelenProductResultType,
  ILocatieResult,
  IVoorraadResult,
} from '../../../../../../paginas/Transport/Opdrachten/reservering/KoppelenProductDialoog';
import { EVoorraadTabblad } from '../../../../../../paginas/Transport/Opdrachten/reservering/KoppelenProductDialoog/VoorraadTabblad';
import NieuwOfGebruiktComp from '../../../../../../components/kolomveld/transportopdrachtregel/NieuwOfGebruiktComp';
import ReferentieProductComp from '../../../../../../components/kolomveld/transportopdrachtregel/ReferentieProductComp';
import UitlegTooltip from '../../../../../../components/formulier/UitlegTooltip';
import ProductinfoDialoog from '../../../../../../components/product/ProductinfoDialoog';
import KoppelVoorraadDialoog from '../../../../../../paginas/Transport/Opdrachten/reservering/KoppelVoorraadProductDialoog';
import { EResultType } from '../../../../../../stores/CheckStore';
import VerplaatsenOpdrachtregelsDialoog from '../../../../../transport/VerplaatsenOpdrachtregelsDialoog';

interface IProps extends TableRowDetail.ContentProps, RouteComponentProps {}

export interface IRegel extends IOphalenOpdrachtregelsResultElementV2 {}

export interface IVerplaatsenRegelsDialoogState {
  trsRegIDs: number[];
}

export interface IWijzigenRegelDialoogState {
  trsRegID: number;
}
export interface IKoppelVoorraadDialoogState {
  trsRegID: number;
  cntID: number | null;
}

export interface IKoppelLocatieproductDialoogState {
  trsRegID: number;
  locID: number | null;
  cntID: number | null;
}

export interface IVoorraadInfoDialoogState {
  typeID: number;
  magID: number;
}
export interface IToevoegenMontageregelDialoogState {
  trsOpdID: number;
}

export interface IAnnulerenOpdrachtregelsDialoogState {
  trsRegIDs: number[];
}
export interface IAfmeldenDialoogState {
  trsOpdID: number;
  trsRegIDs: number[];
}

export interface IProductInfoDialoogState {
  prodID: number;
}

export interface IOpdrachtState {
  regelsSelectie: number[];
  wijzigenRegelDialoogState: IWijzigenRegelDialoogState | null;
  koppelenVoorraadProductDialoogState: IKoppelVoorraadDialoogState | null;
  voorraadInfoDialoogState: IVoorraadInfoDialoogState | null;
  toevoegenMontageregelDialoogState: IToevoegenMontageregelDialoogState | null;
  annulerenOpdrachtregelsDialoogState: IAnnulerenOpdrachtregelsDialoogState | null;
  afmeldenDialoogState: IAfmeldenDialoogState | null;
  productinfoDialoogState: IProductInfoDialoogState | null;
  verplaatsenRegelsDialoogState: IVerplaatsenRegelsDialoogState | null;
}

export const defaultOpdrachtState: IOpdrachtState = {
  regelsSelectie: [],
  wijzigenRegelDialoogState: null,
  koppelenVoorraadProductDialoogState: null,
  voorraadInfoDialoogState: null,
  toevoegenMontageregelDialoogState: null,
  annulerenOpdrachtregelsDialoogState: null,
  afmeldenDialoogState: null,
  productinfoDialoogState: null,
  verplaatsenRegelsDialoogState: null,
};

const CellComponent = (props: TableBase.DataCellProps) => {
  const isGekoppeldBestandKolom = props.column.name === '__gekoppeldBestand';
  return (
    <td style={{ overflow: isGekoppeldBestandKolom ? 'visible' : undefined }}>{props.children}</td>
  );
};

const IconVerplaatsen = functioneleIconMap[EFunctioneleIcon.Verplaatsen];

export interface IRegelXXX extends IOphalenOpdrachtregelsResultElementV2 {
  opdracht: IOphalenOpdrachtenResultElementV2;
}

const DetailComp: React.FC<IProps> = (props) => {
  const { checkStore } = useContext(RootStoreContext);
  const row: ITransportOpdrachtenRegel = props.row;

  const [koppelDialoogState, setKoppelDialoogState] = useState<IKoppelDialoogState | null>(null);

  const transportOpdrachtenContext = useContext(TransportOpdrachtenContext);
  const opdrachtState: IOpdrachtState = useMemo<IOpdrachtState>(
    () => transportOpdrachtenContext.opdrachtenStates[row.TrsOpdID] || defaultOpdrachtState,
    [row.TrsOpdID, transportOpdrachtenContext],
  );
  const [wijzigenProductDialoogTonen, setWijzigenProductDialoogTonen] = useState<number | null>(
    null,
  );
  const setOpdrachtState = useCallback(
    (opdrachstate: IOpdrachtState) => {
      transportOpdrachtenContext.onOpdrachtenStatesChange({
        ...transportOpdrachtenContext.opdrachtenStates,
        [row.TrsOpdID]: opdrachstate,
      });
    },
    [row.TrsOpdID, transportOpdrachtenContext],
  );

  const opdrachtregels: IRegel[] = row.regels;

  const keyExtractor = useCallback((row: IRegel) => row.TrsRegID, []);

  const kolommen = useMemo<TypedColumn<IRegel>[]>(() => {
    return [
      {
        name: '__soort' as any,
        title: 'Srt.',
      },
      {
        name: '__productreservering' as any,
        title: 'Product',
      },
      // {
      //   name: '__wijzigenProduct' as any,
      //   title: ' ',
      // },
      {
        name: '__productsoort' as any,
        title: 'Cat.',
      },
      {
        name: '__nieuw' as any,
        title: (
          <UitlegTooltip inhoud={'N(ieuw) of G(ebruikt) product'}>
            <span>N/G</span>
          </UitlegTooltip>
        ) as any,
      },
      {
        name: '__referentie' as any,
        title: 'Ref.',
      },
      {
        name: '__voorraadReservering' as any,
        title: 'Res.',
      },
      {
        name: '__reserveringInfo' as any,
        title: ' ',
      },
      {
        name: '__retourstatus' as any,
        title: (
          <UitlegTooltip inhoud={'Retourstatus van het retour te halen product'}>
            <span>Doel</span>
          </UitlegTooltip>
        ) as any,
      },
      {
        name: '__contractnummer' as any,
        title: 'Cnt.nr',
      },
      {
        name: '__afmeldstatus' as any,
        title: 'Afm.status',
      },
      {
        name: '__statusdatum' as any,
        title: 'Datum',
      },
      // {
      //   name: '__gekoppeldBestand' as any,
      //   title: 'Gekop. bestand',
      // },
    ];
  }, []);

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRegel>[]>(() => {
    return [
      {
        columnName: '__soort' as any,
        width: 60,
      },
      {
        columnName: '__wijzigenProduct' as any,
        width: 60,
      },
      {
        columnName: '__productreservering' as any,
        width: 235,
      },
      {
        columnName: '__nieuw' as any,
        width: 50,
      },
      {
        columnName: '__referentie' as any,
        width: 85,
      },
      {
        columnName: '__doelstatus' as any,
        width: 75,
      },
      {
        columnName: '__productsoort' as any,
        width: 50,
      },
      {
        columnName: '__voorraadReservering' as any,
        width: 70,
      },
      {
        columnName: '__reserveringInfo' as any,
        width: 50,
      },
      {
        columnName: '__retourstatus' as any,
        width: 85,
      },
      {
        columnName: '__contractnummer' as any,
        width: 85,
      },
      {
        columnName: '__afmeldstatus' as any,
        width: 110,
      },
      {
        columnName: '__gekoppeldBestand' as any,
        width: 100,
      },
      {
        columnName: '__statusdatum' as any,
        width: 115,
      },
    ];
  }, []);

  const acties = useMemo<IActie[]>(
    () => [
      {
        icon: (
          <IconToevoegen
            style={{
              fill: EKleur.Grijs,
              width: 16,
              height: 16,
            }}
          />
        ),
        text: 'Montage-opdracht toevoegen',
        onClick: () => {
          setOpdrachtState({
            ...opdrachtState,
            toevoegenMontageregelDialoogState: {
              trsOpdID: row.TrsOpdID,
            },
          });
        },
      },
      {
        // icon: <IconToevoegen style={{ fill: Kleur.Grijs, width: 16, height: 16 }} />,
        text: 'Koppelen regels omruil',
        onClick: async () => {
          if (opdrachtState.regelsSelectie.length !== 2) {
            await checkStore.melden({
              titel: `Hiervoor dien je 2 regels te selecteren die je als 1 omruil wilt aanmerken`,
            });
            return;
          }

          const checkData = await api.v2.transport.opdracht.omruil.checkKoppelenOmruil({
            regels: [
              {
                trsRegID: opdrachtState.regelsSelectie[0],
                koppelAan_TrsRegID: opdrachtState.regelsSelectie[1],
              },
            ],
          });
          if (
            (
              await checkStore.controleren({
                checkData,
              })
            ).type === EResultType.Annuleren
          ) {
            return;
          }
          if (
            (
              await checkStore.bevestigen({
                inhoud: `Wil je deze 2 regels als 1 omruil aanmerken?`,
              })
            ).type === EResultType.Annuleren
          ) {
            return;
          }

          await api.v2.transport.opdracht.omruil.koppelenOmruil({
            regels: [
              {
                trsRegID: opdrachtState.regelsSelectie[0],
                koppelAan_TrsRegID: opdrachtState.regelsSelectie[1],
              },
            ],
          });
        },
      },
      {
        // icon: <IconToevoegen style={{ fill: Kleur.Grijs, width: 16, height: 16 }} />,
        text: 'Verwijderen omruilmarkering',
        onClick: async () => {
          if (opdrachtState.regelsSelectie.length !== 1) {
            await checkStore.melden({
              titel: `Hiervoor dien je 1 regel te selecteren waarvoor je de omruil wilt verwijderen`,
            });
            return;
          }

          // const checkData = await api.v2.transport.opdracht.omruil.({
          //   trsRegIDs: opdrachtState.regelsSelectie,
          // });
          // if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
          //   return;
          // }

          if (
            (
              await checkStore.bevestigen({
                inhoud: `Wil je voor deze 2 regels de omruil verwijderen?`,
              })
            ).type === EResultType.Annuleren
          ) {
            return;
          }

          await api.v2.transport.opdracht.omruil.verwijderenOmruil({
            trsRegID: opdrachtState.regelsSelectie[0],
          });
        },
      },
    ],
    [opdrachtState.regelsSelectie],
  );

  const [transportopdrachtBestanden, setTransportopdrachtBestanden] = useState<
    IRemoteData<IOphalenTransportopdrachtBestandenResult>
  >(createPendingRemoteData());
  const ophalenTransportopdrachtBestanden = useCallback(async () => {
    const result = await api.v2.transport.opdracht.ophalenTransportopdrachtBestanden({
      filterSchema: {
        filters: [
          {
            naam: 'TRS_OPD_IDS',
            data: [row.TrsOpdID],
          },
        ],
      },
    });
    setTransportopdrachtBestanden(createReadyRemoteData(result));
  }, [row.TrsOpdID]);
  useEffect(() => {
    ophalenTransportopdrachtBestanden();
  }, [ophalenTransportopdrachtBestanden]);

  const [transportopdrachtBestandinfos, setTransportopdrachtBestandinfos] = useState<
    IRemoteData<IOphalenBestandenResult>
  >(createPendingRemoteData());
  const ophalenTransportopdrachtBestandinfos = useCallback(async () => {
    if (transportopdrachtBestanden.state === ERemoteDataState.Pending) {
      setTransportopdrachtBestandinfos((curr) =>
        curr.state === ERemoteDataState.Pending ? curr : createPendingRemoteData(),
      );
      return;
    }
    if (transportopdrachtBestanden.data!.bestanden.length === 0) {
      setTransportopdrachtBestandinfos(createReadyRemoteData({ bestanden: [], totaalAantal: 0 }));
      return;
    }

    const result = await api.v2.bestand.ophalenBestanden({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: transportopdrachtBestanden.data!.bestanden.map((x) => x.BestandID),
          },
        ],
      },
    });
    setTransportopdrachtBestandinfos(createReadyRemoteData(result));
  }, [transportopdrachtBestanden]);
  useEffect(() => {
    ophalenTransportopdrachtBestandinfos();
  }, [ophalenTransportopdrachtBestandinfos]);

  const [transportregelKoppelenKoppeling, setTransportregelKoppelenKoppeling] = useState<
    Record<number, boolean>
  >({});

  const { isBezigMetUploaden, muteerBestanden, uploadProgresses, bestanden } = useUpload(
    useMemo(
      () => ({
        automatischUploaden: true,
        initieleBestanden: row.bestanden.map((x) => ({
          type: BestandType.ASPDrive,
          bestand: x,
        })),
        onAlleBestandenGeuploaded: async (bestandIDs) => {
          await api.v2.transport.muterenBestandenOpdracht({
            trsOpdID: row.TrsOpdID,
            bestIDs: bestandIDs,
          });
          await ophalenTransportopdrachtBestanden();
        },
      }),
      [row.bestanden, row.TrsOpdID, transportopdrachtBestanden],
    ),
  );

  const handlKoppelenOmruil = useCallback(async (trsRegIDs: number[]) => {
    // De 2 items in de array zijn de 2 regels die gekoppeld moeten worden
    const params = {
      regels: [
        {
          trsRegID: trsRegIDs[0],
          koppelAan_TrsRegID: trsRegIDs[1],
        },
      ],
    };
    const checkData = await api.v2.transport.opdracht.omruil.checkKoppelenOmruil(params);
    if (
      (
        await checkStore.controleren({
          checkData,
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    const bevestigenResult = await checkStore.bevestigen({
      inhoud: 'Koppeling omruil maken?',
    });
    if (bevestigenResult.type === EResultType.Annuleren) {
      return;
    }

    await api.v2.transport.opdracht.omruil.koppelenOmruil(params);
  }, []);

  const handlVerwijderenOmruil = useCallback(async (trsRegID: number) => {
    const bevestigenResult = await checkStore.bevestigen({
      inhoud: 'Koppeling omruil verwijderen?',
    });
    if (bevestigenResult.type === EResultType.Annuleren) {
      return;
    }

    await api.v2.transport.opdracht.omruil.verwijderenOmruil({
      trsRegID,
    });
  }, []);

  // const handlWijzigenProduct = useCallback(async (trsRegID: number) => {
  //   const regelsResult = await api.v2.transport.opdracht.ophalenOpdrachtregels({
  //     filterSchema: {
  //       filters: [
  //         {
  //           naam: 'IDS',
  //           data: [trsRegID],
  //         },
  //       ],
  //     },
  //   });

  //   if (regelsResult.regels[0].product === null) {
  //     return null;
  //   }
  //
  //   setWijzigenProductDialoogTonen(regelsResult.regels[0].product!.ProdID);
  // }, []);

  if (
    transportopdrachtBestanden.state === ERemoteDataState.Pending ||
    transportopdrachtBestandinfos.state === ERemoteDataState.Pending
  ) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <div className="d-flex">
        <TabelInspringBlok />
        <MenuLayout
          menu={
            <div className="d-flex">
              <>
                {/* <button
                  className="btn btn-sm btn-light d-flex align-items-center"
                  style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                  disabled={opdrachtState.regelsSelectie.length !== 2}
                  onClick={() => handlKoppelenOmruil(regelsSelectie)}
                >
                  <span className="ml-2">Koppelen omruil</span>
                </button> */}

                <button
                  className="btn btn-sm btn-light d-flex align-items-center"
                  style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                  disabled={opdrachtState.regelsSelectie.length === 0}
                  onClick={() => {
                    setOpdrachtState({
                      ...opdrachtState,
                      verplaatsenRegelsDialoogState: {
                        trsRegIDs: opdrachtState.regelsSelectie,
                      },
                    });
                  }}
                >
                  <IconVerplaatsen
                    style={{
                      width: 16,
                      height: 16,
                      fill: EKleur.Grijs,
                    }}
                  />
                  <span className="ml-2">Verplaatsen</span>
                </button>

                <button
                  className="btn btn-sm btn-light d-flex align-items-center ml-3"
                  style={{
                    border: `1px solid ${EKleur.LichtGrijs}`,
                  }}
                  disabled={opdrachtState.regelsSelectie.length !== 2}
                  onClick={() => handlKoppelenOmruil(opdrachtState.regelsSelectie)}
                >
                  {/* <IcoonToevoegen style={{ width: 16, height: 16, fill: EKleur.Grijs }} /> */}
                  <span className="ml-2">Koppelen omruil</span>
                </button>

                <button
                  className="btn btn-sm btn-light d-flex align-items-center ml-3"
                  style={{
                    border: `1px solid ${EKleur.LichtGrijs}`,
                  }}
                  disabled={opdrachtState.regelsSelectie.length !== 1}
                  onClick={() => handlVerwijderenOmruil(opdrachtState.regelsSelectie[0])}
                >
                  {/* <IcoonVerwijderen style={{ width: 16, height: 16, fill: EKleur.Grijs }} /> */}
                  <span className="ml-2">Verwijderen omruil</span>
                </button>

                <button
                  className="btn btn-sm btn-light d-flex align-items-center ml-3"
                  style={{
                    border: `1px solid ${EKleur.LichtGrijs}`,
                  }}
                  disabled={opdrachtState.regelsSelectie.length === 0}
                  onClick={async () => {
                    const params = {
                      trsRegIDs: opdrachtState.regelsSelectie,
                    };
                    const checkData = await api.v2.transport.opdracht.uitstaand.checkAnnulerenOpdrachtregels(
                      params,
                    );
                    if (
                      (await checkStore.controleren({ checkData })).type === EResultType.Annuleren
                    ) {
                      return;
                    }

                    setOpdrachtState({
                      ...opdrachtState,
                      annulerenOpdrachtregelsDialoogState: {
                        trsRegIDs: opdrachtState.regelsSelectie,
                      },
                    });
                  }}
                >
                  <IconCancel
                    style={{
                      width: 16,
                      height: 16,
                      fill: EKleur.Grijs,
                    }}
                  />
                  <span className="ml-2">Annuleren</span>
                </button>

                <button
                  className="btn btn-sm btn-light d-flex align-items-center ml-3"
                  style={{
                    border: `1px solid ${EKleur.LichtGrijs}`,
                  }}
                  disabled={opdrachtState.regelsSelectie.length === 0}
                  onClick={async () => {
                    const params = {
                      trsRegIDs: opdrachtState.regelsSelectie,
                    };
                    const checkData = await api.v2.transport.opdracht.checkVerwijderenOpdrachtregels(
                      params,
                    );
                    if (
                      (await checkStore.controleren({ checkData })).type === EResultType.Annuleren
                    ) {
                      return;
                    }

                    const bevestigenResult = await checkStore.bevestigen({
                      inhoud: (
                        <span>
                          Geselecteerde regel(s) verwijderen?
                          <br />
                          <br />
                          Let op: Dit kan niet ongedaan gemaakt worden.
                        </span>
                      ),
                    });
                    if (bevestigenResult.type === EResultType.Annuleren) {
                      return;
                    }

                    await api.v2.transport.opdracht.verwijderenOpdrachtregels(params);

                    transportOpdrachtenContext.onRefreshRequest();
                  }}
                >
                  <IconVerwijderen
                    style={{
                      width: 16,
                      height: 16,
                      fill: EKleur.Grijs,
                    }}
                  />
                  <span className="ml-2">Verwijderen</span>
                </button>
              </>
              <div className="flex-fill" />
              <div className="mr-2">
                <BijlageKnop
                  selectieModus={ESelectieModus.Meerdere}
                  onBijgevoegd={(bijlagen) =>
                    muteerBestanden((bestanden) => [...bestanden, ...bijlagen])
                  }
                  bronModus={EBronModus.Alle}
                  disabled={isBezigMetUploaden}
                />
              </div>
              <ActieMenuKnop acties={acties} />
            </div>
          }
          body={
            <>
              {bestanden !== null && bestanden.length > 0 && (
                <div className="p-2">
                  <BijlagenContainer
                    bestanden={bestanden}
                    uploadProgresses={uploadProgresses}
                    onBestandenChange={(x) => muteerBestanden((_) => x)}
                    bestandenMuterenToegestaan={!isBezigMetUploaden}
                    bevestigenVerwijdering={async (bestandID) => {
                      const bestandenWaarbijTransportopdrachtBestandenNietVoorkomen = transportopdrachtBestanden.data!.bestanden.filter(
                        (x) => x.TrsRegID !== null && x.BestandID === bestandID,
                      );
                      if (bestandenWaarbijTransportopdrachtBestandenNietVoorkomen.length > 0) {
                        const bevestigenResult = await checkStore.bevestigen({
                          inhoud:
                            'Er zijn of meerdere regels die aan een verwijderd bestand gekoppeld zijn. Doorgaan?',
                        });
                        if (bevestigenResult.type === EResultType.Annuleren) {
                          return false;
                        }
                        // Ontkoppelen bestanden die worden verwijderd
                        await api.v2.transport.opdracht.bijwerkenBestandskoppelingenVanTransportopdracht(
                          {
                            trsOpdID: row.TrsOpdID,
                            koppelingen: transportopdrachtBestanden
                              .data!.bestanden.filter(
                                (x) => x.TrsRegID !== null && x.BestandID !== bestandID,
                              )
                              .map((x) => ({
                                bestandID: x.BestandID,
                                trsRegID: x.TrsRegID!,
                              })),
                          },
                        );
                      }

                      return true;
                    }}
                  />
                </div>
              )}
              <GridStyleWrapper maxHeight={600} rowAmount={opdrachtregels.length}>
                <Grid getRowId={keyExtractor} rows={opdrachtregels} columns={kolommen}>
                  <DataTypeProvider
                    for={['__statusdatum']}
                    formatterComponent={(formatterProps) => {
                      const rij: IRegel = formatterProps.row;

                      // Afhankelijk van de afmeldstatus
                      // const bezoekdatum = rij.opdracht.Bezoekdatum;

                      if (rij.status.Status === 0) {
                        return <span></span>;
                      }

                      const statusdatum = rij.status.Statusdatum as Date;
                      return <span>{format(new Date(statusdatum), 'dd-MM-yyyy')}</span>;
                    }}
                  />

                  <DataTypeProvider
                    for={['__soort']}
                    formatterComponent={(formatterProps) => {
                      const rij: IRegel = formatterProps.row;
                      return (
                        <>
                          <span>
                            {rij.regelsoort.Code} {rij.OmruilID !== null ? '/O' : ''}
                          </span>
                          {/* <span>{rij.indicaties.isVermoedelijkOmruil ? ' (/O?)' : ''}</span> */}
                        </>
                      );
                    }}
                  />

                  <DataTypeProvider
                    for={['__productreservering']}
                    formatterComponent={(formatterProps) => {
                      const rij: IRegel = formatterProps.row;

                      const regel: IRegelXXX = {
                        ...rij,
                        opdracht: row,
                      };
                      return (
                        <span className="d-flex">
                          <ProductReservering
                            rij={regel}
                            onRequestRefresh={transportOpdrachtenContext.onRefreshRequest}
                            koppelDialoogState={koppelDialoogState}
                            onKoppelDialoogStateChange={setKoppelDialoogState}
                          />
                          {rij.product !== null && (
                            <div className="d-flex">
                              <span>
                                <a
                                  href="#"
                                  onClick={() =>
                                    setWijzigenProductDialoogTonen(rij.product!.ProdID)
                                  }
                                >
                                  {/* <IconWitgoedApparaat
                                    style={{
                                      width: 14,
                                      height: 14,
                                      fill: EKleur.GrijsScheiding,
                                    }}
                                  /> */}
                                  <IconWijzigen
                                    style={{
                                      width: 14,
                                      height: 14,
                                      fill: EKleur.GrijsScheiding,
                                    }}
                                  />
                                </a>
                              </span>
                              <span className="ml-1">
                                <a
                                  href="#"
                                  onClick={() =>
                                    setOpdrachtState({
                                      ...opdrachtState,
                                      productinfoDialoogState: { prodID: rij.product!.ProdID },
                                    })
                                  }
                                >
                                  <IconInformatie
                                    style={{
                                      width: 14,
                                      height: 14,
                                      fill: EKleur.Grijs,
                                    }}
                                  />
                                </a>
                              </span>
                            </div>
                          )}
                        </span>
                      );
                    }}
                  />

                  <DataTypeProvider
                    for={['__referentie']}
                    formatterComponent={(formatterProps) => {
                      const rij: IRegel = formatterProps.row;
                      return <ReferentieProductComp rij={rij} />;
                    }}
                  />

                  <DataTypeProvider
                    for={['__nieuw']}
                    formatterComponent={(formatterProps) => {
                      const rij: IRegel = formatterProps.row;
                      return <NieuwOfGebruiktComp rij={rij} />;
                    }}
                  />

                  <DataTypeProvider
                    for={['__productsoort']}
                    formatterComponent={(formatterProps) => {
                      const rij: IRegel = formatterProps.row;

                      if (rij.regelsoort.Code === 'M') {
                        return <span></span>;
                      }

                      const product =
                        rij.type !== null ? (
                          <span>{rij.type.ProductsoortnaamKort}</span>
                        ) : rij.product !== null ? (
                          <span>{rij.product.producttype.ProductsoortnaamKort}</span>
                        ) : null;
                      return <span>{product}</span>;
                    }}
                  />

                  <DataTypeProvider
                    for={['__doelstatus']}
                    formatterComponent={(formatterProps) => {
                      const rij: IRegel = formatterProps.row;
                      if (rij.product === null || rij.product.retourstatus === null) {
                        return <span></span>;
                      }

                      return <span>{rij.product.retourstatus.Naam}</span>;
                    }}
                  />

                  <DataTypeProvider
                    for={['__retourstatus']}
                    formatterComponent={(formatterProps) => {
                      const rij: IRegel = formatterProps.row;
                      if (rij.product === null || rij.product.retourstatus === null) {
                        return <span></span>;
                      }

                      // return (
                      //   <div className="mt-1">
                      //     <RetourStatusIndicatie
                      //       status={rij.product.Retourstatus.NaamEnum as ERetourStatus}
                      //     />
                      //   </div>
                      // );

                      return <span>{rij.product.retourstatus.Naam}</span>;
                    }}
                  />

                  <DataTypeProvider
                    for={['__contractnummer']}
                    formatterComponent={(props) => {
                      const rij: IRegel = props.row;
                      if (rij.contract === null) {
                        return <span></span>;
                      }
                      return (
                        <div className="d-flex align-items-center">
                          <ContractVisualisatie cntID={rij.contract.CntID} />
                        </div>
                      );
                    }}
                  />

                  <DataTypeProvider
                    for={['__voorraadReservering']}
                    formatterComponent={(formatterProps) => {
                      const rij: IRegel = formatterProps.row;
                      if (rij.status.Status !== ERegelstatusTransport.NIET_AFGEMELD) {
                        return <span></span>;
                      }

                      return (
                        <ReserveringProductComp
                          rij={formatterProps.row}
                          magID={row.magazijn.MagID}
                          onVernieuwen={transportOpdrachtenContext.onRefreshRequest}
                        />
                      );
                    }}
                  />

                  <DataTypeProvider
                    for={['__reserveringInfo']}
                    formatterComponent={(formatterProps) => {
                      const rij: IRegel = formatterProps.row;

                      if (rij.status.Status !== ERegelstatusTransport.NIET_AFGEMELD) {
                        return <span></span>;
                      }

                      if (rij.type === null || rij.type.Bulkproduct) {
                        return <span></span>;
                      }

                      return (
                        <span>
                          {' '}
                          <a
                            href="#"
                            className="ml-1"
                            style={{
                              color: EKleur.LichtBlauw,
                              position: 'relative',
                              bottom: 2,
                            }}
                            onClick={() => {
                              setOpdrachtState({
                                ...opdrachtState,
                                voorraadInfoDialoogState: {
                                  typeID: rij.type!.TypeID,
                                  magID: props.row.magazijn.MagID,
                                },
                              });
                            }}
                          >
                            <IconInformatie
                              style={{
                                width: 15,
                                height: 15,
                                fill: EKleur.Blauw,
                              }}
                            />
                          </a>
                        </span>
                      );
                    }}
                  />

                  <DataTypeProvider
                    for={['__afmeldstatus']}
                    formatterComponent={(props) => {
                      const rij: IRegel = props.row;
                      const kleur: EKleur =
                        rij.status.Status === 1
                          ? EKleur.Groen
                          : rij.status.Status === 2
                          ? EKleur.Rood
                          : rij.status.Status === 3
                          ? EKleur.Oranje
                          : EKleur.Blauw;
                      //return <span style={{ color: kleur }}>{row.statusnaam}</span>;

                      if (row.opdrachtstatus.Status !== EOpdrachtstatusTransport.Uitstaand) {
                        return <span> {rij.status.Naam}</span>;
                      }

                      return rij.status.Status === 0 ? (
                        <a
                          href="#"
                          // onClick={() => setAfmeldenDialoogTonen(row.trsRegID)}
                          onClick={() => {
                            setOpdrachtState({
                              ...opdrachtState,
                              afmeldenDialoogState: {
                                trsOpdID: rij.TrsOpdID,
                                trsRegIDs: [rij.TrsRegID],
                              },
                            });
                          }}
                          style={{ color: kleur }}
                        >
                          Afmelden
                        </a>
                      ) : (
                        <a
                          href="#"
                          onClick={async () => {
                            alert('Ongedaan maken kan hier niet');
                            // const resultaat = await checkStore.bevestigen({
                            //   titel: `Afmelden ongedaan maken?`,
                            // });
                            // if (resultaat.type === EResultType.Annuleren) {
                            //   return;
                            // }
                            // await api.v2.transport.opdracht.uitstaand.herstellenAfmeldenOpdrachten({
                            //   trsRegIDs: [rij.TrsRegID],
                            // });
                            // uitstaandTabelContext.onRequestRefresh();
                          }}
                          style={{ color: kleur }}
                        >
                          {rij.status.Naam}
                        </a>
                      );
                    }}
                  />

                  <DataTypeProvider
                    for={['__gekoppeldBestand']}
                    formatterComponent={(props) => {
                      const regel: IRegel = props.row;

                      if (transportregelKoppelenKoppeling[regel.TrsRegID]) {
                        return (
                          <MultiSelect
                            value={[]}
                            isMulti={false}
                            opties={transportopdrachtBestandinfos.data!.bestanden.map((info) => ({
                              key: info.ID,
                              weergave: info.Naam,
                            }))}
                            onChange={async ([bestandID]) => {
                              setTransportregelKoppelenKoppeling((curr) => ({
                                [regel.TrsRegID]: false,
                              }));

                              await api.v2.transport.opdracht.bijwerkenBestandskoppelingenVanTransportopdracht(
                                {
                                  trsOpdID: row.TrsOpdID,
                                  koppelingen: [
                                    ...transportopdrachtBestanden
                                      .data!.bestanden.filter((x) => x.TrsRegID !== null)
                                      .map((x) => ({
                                        trsRegID: x.TrsRegID!,
                                        bestandID: x.BestandID,
                                      })),
                                    {
                                      bestandID,
                                      trsRegID: regel.TrsRegID,
                                    },
                                  ],
                                },
                              );
                              await ophalenTransportopdrachtBestanden();
                            }}
                            autoFocus
                            onBlur={() => {
                              setTransportregelKoppelenKoppeling((curr) => ({
                                [regel.TrsRegID]: false,
                              }));
                            }}
                          />
                        );
                      }

                      const koppeling = transportopdrachtBestanden.data!.bestanden.find(
                        (x) => x.TrsRegID === regel.TrsRegID,
                      );

                      if (koppeling === undefined) {
                        return (
                          <a
                            href="#"
                            onClick={(ev) => {
                              ev.preventDefault();

                              setTransportregelKoppelenKoppeling((curr) => ({
                                ...curr,
                                [regel.TrsRegID]: true,
                              }));
                            }}
                          >
                            Koppelen
                          </a>
                        );
                      }
                      const bestand = transportopdrachtBestandinfos.data!.bestanden.find(
                        (x) => x.ID === koppeling.BestandID,
                      );
                      if (bestand === undefined) {
                        return <span>Laden...</span>;
                      }
                      return (
                        <span className="d-flex align-items-center">
                          <span>{bestand.Naam}</span>
                          <button
                            className="ml-1 d-flex align-items-center justify-content-center"
                            style={{
                              border: 0,
                              outline: 0,
                              background: 0,
                              padding: 0,
                            }}
                            onClick={async () => {
                              await api.v2.transport.opdracht.bijwerkenBestandskoppelingenVanTransportopdracht(
                                {
                                  trsOpdID: row.TrsOpdID,
                                  koppelingen: transportopdrachtBestanden
                                    .data!.bestanden.filter(
                                      (x) => x.TrsRegID !== null && x.TrsRegID !== regel.TrsRegID,
                                    )
                                    .map((x) => ({
                                      trsRegID: x.TrsRegID!,
                                      bestandID: x.BestandID,
                                    })),
                                },
                              );
                              await ophalenTransportopdrachtBestanden();
                            }}
                          >
                            <IconKruis
                              style={{
                                width: 16,
                                height: 16,
                                fill: EKleur.Grijs,
                              }}
                            />
                          </button>
                        </span>
                      );
                    }}
                  />

                  {/* <DataTypeProvider
                    for={['__wijzigenProduct']}
                    formatterComponent={(props) => {
                      const rij: IRegel = props.row;

                      if (rij.product === null) {
                        return <span></span>;
                      }

                      return (
                        <>
                          <a
                            href="#"
                            onClick={() =>
                              setOpdrachtState({
                                ...opdrachtState,
                                productinfoDialoogState: { prodID: rij.product!.ProdID },
                              })
                            }
                          >
                            <IconInformatie
                              style={{
                                width: 14,
                                height: 14,
                                fill: EKleur.Grijs,
                              }}
                            />
                          </a>
                        </>
                      );
                    }}
                  /> */}

                  <EditingState
                    onAddedRowsChange={() => {}}
                    onCommitChanges={(changes) => {
                      if (changes.deleted === undefined) {
                        return;
                      }
                      const deleted = changes.deleted;
                      const id = deleted[deleted.length - 1];
                    }}
                    onEditingRowIdsChange={(rowIds) => {
                      const id = rowIds[rowIds.length - 1] as number;

                      const x = opdrachtregels.find((x) => x.TrsRegID === id)!;
                      if (x.regelsoort.Code === 'L' || x.regelsoort.Code === 'R') {
                        setOpdrachtState({
                          ...opdrachtState,
                          wijzigenRegelDialoogState: {
                            trsRegID: id,
                          },
                        });
                      }

                      // Wijzigen Montage
                    }}
                  />

                  {/* <RowDetailState /> */}
                  <VirtualTable
                    messages={{
                      noData: 'Geen opdrachtregels',
                    }}
                    cellComponent={CellComponent}
                  />
                  <TableColumnResizing defaultColumnWidths={kolomBreedtes as any} />

                  <TableEditColumn
                    width={30}
                    showEditCommand
                    // showAddCommand
                    // showDeleteCommand
                    cellComponent={DXTableEditColumnCellComponent}
                    commandComponent={DXTableEditColumnCommandComponent}
                  />
                  <TableHeaderRow />
                  <SelectionState
                    selection={opdrachtState.regelsSelectie}
                    onSelectionChange={(x) =>
                      setOpdrachtState({
                        ...opdrachtState,
                        regelsSelectie: x as number[],
                      })
                    }
                  />
                  {/* <TableRowDetail
                    toggleCellComponent={DXTableToggleCellComponent}
                    contentComponent={Detailregel}
                  /> */}
                  <TableSelection cellComponent={DXTableCheckboxComponent} />
                </Grid>
              </GridStyleWrapper>
            </>
          }
        />
      </div>
      {opdrachtState.wijzigenRegelDialoogState !== null && (
        <WijzigenOpdrachtDialoog
          open
          trsOpdID={row.TrsOpdID}
          trsRegID={opdrachtState.wijzigenRegelDialoogState.trsRegID}
          onSuccess={() => {
            setOpdrachtState({
              ...opdrachtState,
              wijzigenRegelDialoogState: null,
            });
            // planningTabelContext.onRequestRefresh();
          }}
          onAnnuleren={() =>
            setOpdrachtState({
              ...opdrachtState,
              wijzigenRegelDialoogState: null,
            })
          }
        />
      )}
      {opdrachtState.toevoegenMontageregelDialoogState !== null && (
        <ToevoegenMontageRegelDialoog
          open
          trsOpdID={opdrachtState.toevoegenMontageregelDialoogState.trsOpdID}
          onSuccess={async (data) => {
            setOpdrachtState({
              ...opdrachtState,
              toevoegenMontageregelDialoogState: null,
            });
            // planningTabelContext.onRequestRefresh();
          }}
          onAnnuleren={() =>
            setOpdrachtState({
              ...opdrachtState,
              toevoegenMontageregelDialoogState: null,
            })
          }
        />
      )}
      {opdrachtState.voorraadInfoDialoogState !== null && (
        <VoorraadTypeInfoDialoog
          open
          magID={opdrachtState.voorraadInfoDialoogState.magID}
          typeID={opdrachtState.voorraadInfoDialoogState.typeID}
          onSuccess={() => {
            setOpdrachtState({
              ...opdrachtState,
              voorraadInfoDialoogState: null,
            });
          }}
          onAnnuleren={() =>
            setOpdrachtState({
              ...opdrachtState,
              voorraadInfoDialoogState: null,
            })
          }
        />
      )}

      {opdrachtState.annulerenOpdrachtregelsDialoogState !== null && (
        <AnnulerenOpdrachtregelsDialoog
          open
          trsRegIDs={opdrachtState.annulerenOpdrachtregelsDialoogState.trsRegIDs}
          onSuccess={() => {
            setOpdrachtState({
              ...opdrachtState,
              annulerenOpdrachtregelsDialoogState: null,
            });
            transportOpdrachtenContext.onRefreshRequest();
          }}
          onAnnuleren={() =>
            setOpdrachtState({
              ...opdrachtState,
              annulerenOpdrachtregelsDialoogState: null,
            })
          }
        />
      )}
      {opdrachtState.afmeldenDialoogState !== null && (
        <AfmeldenDialoog
          open
          onSuccess={() => {
            setOpdrachtState({
              ...opdrachtState,
              afmeldenDialoogState: null,
            });
          }}
          onAnnuleren={() =>
            setOpdrachtState({
              ...opdrachtState,
              afmeldenDialoogState: null,
            })
          }
          trsOpdID={opdrachtState.afmeldenDialoogState.trsOpdID}
          trsRegIDs={opdrachtState.afmeldenDialoogState.trsRegIDs}
        />
      )}
      {opdrachtState.koppelenVoorraadProductDialoogState && (
        <KoppelVoorraadDialoog
          open
          cntID={
            opdrachtState.koppelenVoorraadProductDialoogState.cntID !== null
              ? opdrachtState.koppelenVoorraadProductDialoogState.cntID
              : undefined
          }
          onSuccess={async (result) => {
            let type = null;
            let product = null;

            switch (result.tabblad) {
              case ETabblad.Ingepakt:
                // Bepaal of er vrije voorraad is
                const voorraad = (
                  await api.v2.voorraad.ophalenVoorraadinfo({
                    magID: row.magazijn.MagID,
                    typeIDs: [result.typeID!],
                  })
                ).voorraad[0];

                type = {
                  typeID: result.typeID!,
                  bulkproduct: false,
                  gereserveerd: voorraad.aantalVrij > 0 ? true : false,
                };
                break;
              case ETabblad.Uitgepakt:
                product = {
                  prodID: result.prodID!,
                };
                break;
              case ETabblad.Bulk:
                type = {
                  typeID: result.bulkTypeID!,
                  bulkproduct: true,
                };
                break;
            }

            const params = {
              trsRegID: opdrachtState.koppelenVoorraadProductDialoogState!.trsRegID,
              type,
              product,
            };

            await api.v2.transport.opdracht.koppelenProductVoorTransportregel(params);

            transportOpdrachtenContext.onRefreshRequest();

            setOpdrachtState({
              ...opdrachtState,
              koppelenVoorraadProductDialoogState: null,
            });
          }}
          onAnnuleren={() =>
            setOpdrachtState({
              ...opdrachtState,
              koppelenVoorraadProductDialoogState: null,
            })
          }
        />
      )}
      {wijzigenProductDialoogTonen !== null && (
        <WijzigenProductDialoog
          open
          prodID={wijzigenProductDialoogTonen}
          onSuccess={(data) => {
            setWijzigenProductDialoogTonen(null);
            transportOpdrachtenContext.onRefreshRequest();
          }}
          onAnnuleren={() => setWijzigenProductDialoogTonen(null)}
        />
      )}
      {/* {koppelLocatieproductDialoog !== null && (
        <KoppelLocatieProductDialoog
          open
          locID={
            koppelLocatieproductDialoog.locID !== null
              ? koppelLocatieproductDialoog.locID
              : undefined
          }
          cntID={
            koppelLocatieproductDialoog.cntID !== null
              ? koppelLocatieproductDialoog.cntID
              : undefined
          }
          onSuccess={async (prodID) => {
            const params = {
              trsRegID: koppelLocatieproductDialoog.trsRegID,
              type: null,
              product: { prodID },
            };
            await api.v2.transport.opdracht.koppelenProductVoorTransportregel(params);
            setKoppelLocatieproductDialoogState(null);
          }}
          onAnnuleren={() => setKoppelLocatieproductDialoogState(null)}
        />
      )} */}

      {opdrachtState.verplaatsenRegelsDialoogState !== null && (
        <VerplaatsenOpdrachtregelsDialoog
          open
          trsRegIDs={opdrachtState.verplaatsenRegelsDialoogState.trsRegIDs}
          onSuccess={() => {
            transportOpdrachtenContext.onRefreshRequest();

            setOpdrachtState({
              ...opdrachtState,
              verplaatsenRegelsDialoogState: null,
              regelsSelectie: [],
            });
          }}
          onAnnuleren={() =>
            setOpdrachtState({
              ...opdrachtState,
              verplaatsenRegelsDialoogState: null,
            })
          }
        />
      )}

      {opdrachtState.productinfoDialoogState !== null && (
        <ProductinfoDialoog
          open
          id={opdrachtState.productinfoDialoogState.prodID}
          onSuccess={() => {
            setOpdrachtState({
              ...opdrachtState,
              productinfoDialoogState: null,
            });
          }}
          onAnnuleren={() =>
            setOpdrachtState({
              ...opdrachtState,
              productinfoDialoogState: null,
            })
          }
        />
      )}

      {koppelDialoogState && (
        <KoppelenProductDialoog
          open
          tabblad={koppelDialoogState.tabblad}
          voorraadTabblad={koppelDialoogState.voorraadTabblad}
          cntID={koppelDialoogState.cntID !== null ? koppelDialoogState.cntID : undefined}
          locID={koppelDialoogState.locID !== null ? koppelDialoogState.locID : undefined}
          onSuccess={async (result) => {
            let type = null;
            let product = null;

            switch (result.result.type) {
              case EKoppelenProductResultType.Locatie: {
                const locatieResult = result.result as ILocatieResult;
                const params = {
                  trsRegID: koppelDialoogState!.trsRegID,
                  type: null,
                  product: {
                    prodID: locatieResult.prodID,
                  },
                };
                await api.v2.transport.opdracht.koppelenProductVoorTransportregel(params);
                setKoppelDialoogState(null);

                break;
              }
              case EKoppelenProductResultType.Voorraad: {
                const voorraadResult = result.result as IVoorraadResult;
                switch (voorraadResult.tabblad) {
                  case EVoorraadTabblad.Ingepakt:
                    // Bepaal of er vrije voorraad is
                    const voorraad = (
                      await api.v2.voorraad.ophalenVoorraadinfo({
                        magID: koppelDialoogState!.magID,
                        typeIDs: [voorraadResult.typeID!],
                      })
                    ).voorraad[0];

                    type = {
                      typeID: voorraadResult.typeID!,
                      bulkproduct: false,
                      gereserveerd: voorraad.aantalVrij > 0 ? true : false,
                    };
                    break;
                  case EVoorraadTabblad.Uitgepakt:
                    product = {
                      prodID: voorraadResult.prodID!,
                    };
                    break;
                  case EVoorraadTabblad.Bulk:
                    type = {
                      typeID: voorraadResult.typeID!,
                      bulkproduct: true,
                    };
                    break;
                }

                const params = {
                  trsRegID: koppelDialoogState!.trsRegID,
                  type,
                  product,
                };

                await api.v2.transport.opdracht.koppelenProductVoorTransportregel(params);

                transportOpdrachtenContext.onRefreshRequest();

                setKoppelDialoogState(null);

                break;
              }
            }
          }}
          onAnnuleren={() => setKoppelDialoogState(null)}
        />
      )}
    </>
  );
};

export default withRouter(DetailComp);
