import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ASPKolom, IAspKolomSorteringItem } from '../../tabel/ASPTabel/types';
import { IPaginatiePositie } from '../../../api';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../models/IRemoteData';
import styled from 'styled-components';
import { Kleur } from '../../../bedrijfslogica/constanten';
import Skeleton from 'react-loading-skeleton';
import Overlay from 'react-bootstrap/Overlay';
import { IconKruis, IconUitklappen } from '../../Icons';
import ASPTabel, { genereerKolomRenderMap } from '../../tabel/ASPTabel';
import TableData, { ITableDataProps } from '../../tabel/ASPTabel/Body/TableData';

export interface IInputProps {
  disabled?: boolean;
}

export const Input = styled.div<IInputProps>`
  width: 100%;
  min-height: 30px;
  background-color: ${(props) => (props.disabled ? '#dedede' : Kleur.Wit)};
  border: 1px solid ${Kleur.LichtGrijs};
  border-radius: 3px;
  display: flex;
  cursor: ${(props) => (props.disabled ? 'inherit' : 'pointer')};
`;

export const Representatie = styled.div`
  padding: 4px 8px;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

interface IOverlayState {}

export interface IProviderParams<TKey extends keyof any, TEntity, TOverlayContainerState> {
  paginatie: IPaginatiePositie;
  sortering: IAspKolomSorteringItem<TKey>[];
  huidigeBron: Record<number, TEntity>;
  overlayContainerState: TOverlayContainerState;
}

export interface IProviderOutput<TEntity, TOverlayContainerState> {
  items: Record<number, TEntity>;
  totaalAantal: number;
  overlayContainerStateUpdate?: TOverlayContainerState;
}

export type Provider<TKey extends keyof any, TEntity, TOverlayContainerState = null> = (
  params: IProviderParams<TKey, TEntity, TOverlayContainerState>,
) => Promise<IProviderOutput<TEntity, TOverlayContainerState>>;

export type EnkeleProvider<TId, TEntity> = (id: TId) => Promise<TEntity>;

export interface IRepresentatieProps<TEntity> {
  entiteit: TEntity;
}

export interface IOverlayOptions<TState, TEntity, TId> {
  zIndex?: number;
  width?: number;
  height?: number;
  overlayContainer?: {
    overlayContainerComponent: React.ComponentType<IOverlayContainerProps<TState, TEntity, TId>>;
    initialState: TState;
  };
}

export const standaardOverlayOptions: IOverlayOptions<any, any, any> = {};

interface IData<TEntity> {
  items: Record<number, TEntity>;
  totaalAantal: number;
}

export interface IOverlayContainerProps<TState, TEntity, TId> {
  state: TState;
  onStateChange: React.Dispatch<React.SetStateAction<TState>>;
  rijbronHerbepalen: () => Promise<void>;
  isAanHetHerbepalen: boolean;
  data: IRemoteData<IData<TEntity>>;
  waarde: TId | null;
  onChange: (value: TId | null) => void;
  sluiten: () => void;
  children: React.ReactNode;
  dialoogIndex?: number;
}

const StandaardOverlayContainer = (props: IOverlayContainerProps<any, any, any>) => {
  return <>{props.children}</>;
};

export interface IProps<
  TId extends string | number,
  TKey extends keyof any,
  TEntity,
  TOverlayContainerState
> {
  provider: Provider<TKey, TEntity, TOverlayContainerState>;
  enkeleProvider: EnkeleProvider<TId, TEntity>;
  keyExtractor: (entity: TEntity) => TId;
  waarde: TId | null;
  onChange: (value: TId | null) => void;
  representatieComponent: React.ComponentType<IRepresentatieProps<TEntity>>;
  kolommen: ASPKolom<TKey, TEntity>[];
  sortering?: IAspKolomSorteringItem<TKey>[];
  wisbaar?: boolean;
  disabled?: boolean;
  geenWaardeRepresentatieComponent?: React.ComponentType;
  overlayOptions?: Partial<IOverlayOptions<TOverlayContainerState, TEntity, TId>>;
  paginatieAantal?: number;
  className?: string;
  style?: React.CSSProperties;
  dialoogIndex?: number;
}

const MultiComboboxV2 = <
  TId extends string | number,
  TKey extends keyof any,
  TEntity,
  TOverlayContainerState
>(
  props: IProps<TId, TKey, TEntity, TOverlayContainerState>,
) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const overlayOptions = useMemo(() => {
    return {
      ...standaardOverlayOptions,
      ...props.overlayOptions,
    };
  }, [props.overlayOptions]);

  const kolomRenderMap = useMemo(() => {
    return genereerKolomRenderMap({
      containerWidth: 0,
      heeftVigerendKolom: false,
      heeftSelectieKolom: false,
      heeftUitklapRij: false,
      heeftWijzigenKolom: false,
      heeftToevoegenKolom: false,
      heeftVerwijderenKolom: false,
      kolommen: props.kolommen,
    });
  }, [props.kolommen]);
  const renderMapTotaalBreedte = useMemo(() => {
    return Object.values(kolomRenderMap).reduce((acc, kolom) => acc + kolom.breedte, 0);
  }, [kolomRenderMap]);

  const overlayZIndex = overlayOptions.zIndex ?? 9999;
  const overlayHeight = overlayOptions.height ?? 300;
  const overlayWidth = overlayOptions.width ?? renderMapTotaalBreedte + 8;

  const [overlayState, _setOverlayState] = useState<IOverlayState | null>(null);
  const [overlayPlacement, setOverlayPlacement] = useState<'bottom-start' | 'auto-start'>(
    'auto-start',
  );
  const setOverlayState = useCallback(
    (state: IOverlayState | null) => {
      if (inputRef.current !== null) {
        const rect = inputRef.current!.getBoundingClientRect();
        const overlayPlacement =
          rect.top + rect.height + overlayHeight > window.innerHeight
            ? 'auto-start'
            : 'bottom-start';
        setOverlayPlacement(overlayPlacement);
      }

      _setOverlayState(state);
    },
    [_setOverlayState, overlayHeight],
  );

  const dataRef = useRef<IRemoteData<IData<TEntity>>>(createPendingRemoteData<IData<TEntity>>());
  const [dataState, setDataState] = useState(dataRef.current);
  const [overlayContainerState, setOverlayContainerState] = useState<TOverlayContainerState | null>(
    props.overlayOptions?.overlayContainer?.initialState ?? null,
  );

  const data = useMemo(() => dataState, [dataState]);
  const setData = useCallback(
    (data: IRemoteData<IData<TEntity>>) => {
      dataRef.current = data;
      setDataState(data);
    },
    [setDataState],
  );
  const [enkelEntiteit, setEnkelEntiteit] = useState<IRemoteData<TEntity | null>>(
    createReadyRemoteData(null),
  );

  const dataBijID = useMemo<IRemoteData<Record<TId, TEntity>>>(() => {
    if (data.state === ERemoteDataState.Pending) {
      return createPendingRemoteData();
    }
    const value = Object.keys(data.data!.items)
      .map(Number)
      .reduce<Record<TId, TEntity>>((acc, idx) => {
        const key = props.keyExtractor(data.data!.items[idx]);
        return {
          ...acc,
          [key]: data.data!.items[idx],
        };
      }, {} as any);
    return createReadyRemoteData(value);
  }, [data]);

  const entiteit = useMemo<IRemoteData<TEntity | null>>(() => {
    if (props.waarde === null) {
      return createReadyRemoteData(null);
    }
    if (dataBijID.state === ERemoteDataState.Pending) {
      return createPendingRemoteData();
    }
    const entiteit = dataBijID.data![props.waarde];
    if (entiteit === undefined) {
      if (
        enkelEntiteit.state === ERemoteDataState.Ready &&
        enkelEntiteit.data !== null &&
        props.keyExtractor(enkelEntiteit.data) === props.waarde
      ) {
        return createReadyRemoteData(enkelEntiteit.data);
      }

      return createPendingRemoteData();
    }
    return createReadyRemoteData(entiteit);
  }, [props.waarde, dataBijID, enkelEntiteit, props.keyExtractor]);

  const enkelEntiteitBezig = useRef<number | string | null>(null);
  useEffect(() => {
    if (props.waarde === null || entiteit.state === ERemoteDataState.Ready) {
      return;
    }
    if (enkelEntiteitBezig.current === props.waarde) {
      return;
    }
    enkelEntiteitBezig.current = props.waarde;
    setEnkelEntiteit(createPendingRemoteData());
    props.enkeleProvider(props.waarde).then((entiteit) => {
      if (enkelEntiteitBezig.current === props.keyExtractor(entiteit)) {
        enkelEntiteitBezig.current = null;
      }
      setEnkelEntiteit(createReadyRemoteData(entiteit));
    });
  }, [
    props.waarde,
    entiteit.state,
    entiteit.data === null ? null : props.keyExtractor(entiteit.data!),
  ]);

  const representatie = useMemo<IRemoteData<React.ReactNode | null>>(() => {
    if (entiteit.state === ERemoteDataState.Pending) {
      return createPendingRemoteData();
    }
    if (entiteit.data === null) {
      return createReadyRemoteData(null);
    }
    const Comp = props.representatieComponent;
    return createReadyRemoteData(<Comp entiteit={entiteit.data} />);
  }, [entiteit]);

  const GeenWaardeRepresentatie = useMemo(() => {
    return (
      props.geenWaardeRepresentatieComponent ??
      (() => {
        return <span>Maak een keuze</span>;
      })
    );
  }, [props.geenWaardeRepresentatieComponent]);

  const bepalenData = useCallback(
    async (
      paginatie: IPaginatiePositie,
      uitbreiden: boolean,
      overlayContainerState: TOverlayContainerState,
    ) => {
      const params: IProviderParams<TKey, TEntity, TOverlayContainerState> = {
        paginatie,
        sortering: [],
        huidigeBron: uitbreiden ? dataRef.current.data?.items ?? {} : {},
        overlayContainerState,
      };
      const result = await props.provider(params);
      setData(createReadyRemoteData({ items: result.items, totaalAantal: result.totaalAantal }));
      if (result.overlayContainerStateUpdate !== undefined) {
        setOverlayContainerState(result.overlayContainerStateUpdate);
      }
    },
    [props.provider, props.sortering],
  );

  useEffect(() => {
    bepalenData(
      {
        index: 0,
        aantal: props.paginatieAantal ?? 50,
      },
      false,
      overlayContainerState!,
    );
  }, [bepalenData]);

  const handleExtraRijenAangevraagd = useCallback(
    async (paginatie) => {
      await bepalenData(paginatie, true, overlayContainerState!);
    },
    [bepalenData, overlayContainerState],
  );

  const TdComponent = useMemo(() => {
    return (tableDataProps: ITableDataProps<TKey, TEntity>) => {
      if (tableDataProps.row === undefined) {
        return <TableData {...tableDataProps} />;
      }

      return (
        <TableData
          {...tableDataProps}
          onClick={(ev) => {
            ev.stopPropagation();

            const id = props.keyExtractor(tableDataProps.row!);
            props.onChange(id);
            setOverlayState(null);
          }}
          style={{
            cursor: 'pointer',
          }}
        />
      );
    };
  }, [props.keyExtractor, props.onChange, setOverlayState]);

  const OverlayContainer = useMemo<
    React.ComponentType<IOverlayContainerProps<TOverlayContainerState, TEntity, TId>>
  >(() => {
    if (props.overlayOptions?.overlayContainer?.overlayContainerComponent !== undefined) {
      return props.overlayOptions.overlayContainer.overlayContainerComponent;
    }

    return StandaardOverlayContainer;
  }, [props.overlayOptions?.overlayContainer?.overlayContainerComponent]);

  const [isAanHetHerbepalen, setIsAanHetHerbepalen] = useState(false);
  const rijbronHerbepalen = useCallback(async () => {
    setIsAanHetHerbepalen(true);
    await bepalenData(
      {
        index: 0,
        aantal: props.paginatieAantal ?? 50,
      },
      false,
      overlayContainerState!,
    );
    setIsAanHetHerbepalen(false);
  }, [bepalenData, props.paginatieAantal, overlayContainerState]);

  return (
    <>
      <Input
        ref={inputRef}
        disabled={props.disabled}
        onClick={() => {
          if (props.disabled) {
            return;
          }

          setOverlayState({});
        }}
        className={props.className}
        style={props.style}
      >
        <Representatie>
          <div className="d-flex align-items-center flex-fill">
            {representatie.state === ERemoteDataState.Pending ? (
              <Skeleton />
            ) : representatie.data === null ? (
              <GeenWaardeRepresentatie />
            ) : (
              representatie.data
            )}
          </div>
          {props.wisbaar && props.waarde !== null && (
            <button
              style={{
                backgroundColor: 'transparent',
                border: 'none',
                outline: 'none',
                margin: 0,
                padding: 0,
                marginLeft: 5,
              }}
              title="Selectie wissen"
              onClick={(ev) => {
                ev.stopPropagation();

                props.onChange(null);
              }}
            >
              <IconKruis style={{ fill: Kleur.Grijs, width: 18, height: 18 }} />
            </button>
          )}
        </Representatie>

        <div
          style={{
            borderLeft: `1px solid ${Kleur.LichtGrijs}`,
            width: 25,
            backgroundColor: props.disabled ? 'rgb(197, 197, 197)' : Kleur.HeelLichtGrijs,
          }}
          className="d-flex justify-content-center align-items-center"
        >
          <IconUitklappen style={{ width: 20, height: 20, fill: Kleur.Grijs }} />
        </div>
      </Input>

      <Overlay
        show={overlayState !== null}
        target={inputRef.current!}
        placement={overlayPlacement}
        onHide={() => setOverlayState(null)}
        rootClose
      >
        {({ placement, arrowProps, show, popper, ...overlayProps }) => (
          <div
            {...overlayProps}
            style={{
              zIndex: overlayZIndex,
              width: overlayWidth,
              height: overlayHeight,
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: 'white',
              border: `1px solid ${Kleur.LichtGrijs}`,
              ...overlayProps.style,
            }}
          >
            <OverlayContainer
              state={overlayContainerState!}
              onStateChange={
                setOverlayContainerState as React.Dispatch<
                  React.SetStateAction<TOverlayContainerState>
                >
              }
              rijbronHerbepalen={rijbronHerbepalen}
              data={data}
              isAanHetHerbepalen={isAanHetHerbepalen}
              waarde={props.waarde}
              onChange={props.onChange}
              sluiten={() => setOverlayState(null)}
              dialoogIndex={props.dialoogIndex}
            >
              <ASPTabel
                rijen={data.state === ERemoteDataState.Pending ? {} : data.data!.items}
                kolommen={props.kolommen}
                keyExtractor={props.keyExtractor}
                totaalAantalRijen={
                  data.state === ERemoteDataState.Pending ? 10 : data.data!.totaalAantal
                }
                onExtraRijenAangevraagd={handleExtraRijenAangevraagd}
                tdComponent={TdComponent}
              />
            </OverlayContainer>
          </div>
        )}
      </Overlay>
    </>
  );
};

export default MultiComboboxV2;
