import {
  IOphalenVoorraadNieuwParams,
  OphalenVoorraadNieuwResult,
  IOphalenVoorraadGebruiktParams,
  OphalenVoorraadGebruiktResult,
} from '../../../../../../shared/src/api/v2/inkoop/voorraad';

import api from '../../../index';

const voorraad = {
  ophalenVoorraadNieuw: async (
    params: IOphalenVoorraadNieuwParams,
  ): Promise<OphalenVoorraadNieuwResult> => {
    return await api.post('v2/transport/voorraad/ophalen-voorraad-nieuw', params);
  },
  ophalenVoorraadGebruikt: async (
    params: IOphalenVoorraadGebruiktParams,
  ): Promise<OphalenVoorraadGebruiktResult> => {
    return await api.post('v2/transport/voorraad/ophalen-voorraad-gebruikt', params);
  },
};

export default voorraad;
