import React, { useCallback, useContext, useMemo, useState } from 'react';
import {
  EFunctioneleIcon,
  functioneleIconMap,
  IconAccounts,
  IconAfdeling,
  IconAssignment,
  IconAttachment,
  IconBoekhouding,
  IconBoeking,
  IconCloud,
  IconEmail,
  IconEuro,
  IconEvents,
  IconGrid,
  IconInkoop,
  IconKrediet,
  IconLev,
  IconLeverancier,
  IconLocatie,
  IconMagazijn,
  IconOverzicht,
  IconPersonen,
  IconService,
  IconSMS,
  IconTransport,
  IconVink,
  IconWinkelwagen,
} from '../../../components/Icons';
import KaartNavigatie from '../../../components/kaart/Navigatie';
import { LeverancierContext } from '../index';
import { ERemoteDataState } from '../../../models/IRemoteData';
import { ELink, IWeergaveProps, Link } from '../../../components/navigatie/VerticaleNavigatieBalk';
import { Kleur } from '../../../bedrijfslogica/constanten';
import { RouteComponentProps, withRouter } from 'react-router-dom';

const IconCommunicatie = functioneleIconMap[EFunctioneleIcon.Communicatie];

export enum EGroepLink {
  Diensten,
  Overige,
  Financieel,
}

interface IProps extends RouteComponentProps {}

const Navigatie: React.FC<IProps> = (props) => {
  const {
    inkoopdienst,
    transportdienst,
    pendeldienst,
    magazijndienst,
    incassodienst,
    smsdienst,
    emaildienst,
    servicedienst,
    reviewdienst,
    sponsordienst,
    creditmanagementdienst,
  } = useContext(LeverancierContext);

  const [uitgeklapteGroepLinks, setUitgeklapteGroepLinks] = useState<
    Partial<Record<EGroepLink, boolean>>
  >({
    [EGroepLink.Diensten]: true,
    [EGroepLink.Overige]: true,
    [EGroepLink.Financieel]: true,
  });

  const linkWeergaveRenderer = useCallback((text: string, icon?: React.ComponentType<any>) => {
    const Icon = icon;
    return (weergaveProps: IWeergaveProps) => (
      <li style={{ backgroundColor: weergaveProps.actief ? Kleur.Actief : undefined }}>
        {Icon !== undefined && (
          <Icon
            style={{
              width: 18,
              height: 18,
              fill: Kleur.Grijs,
            }}
          />
        )}
        <span style={{ color: Kleur.Grijs }}>{text}</span>
      </li>
    );
  }, []);

  const links = useMemo<Link<EGroepLink>[]>(
    () =>
      [
        {
          type: ELink.Link,
          path: '/overzicht',
          weergave: linkWeergaveRenderer('Overzicht', IconOverzicht),
        },
        {
          type: ELink.Link,
          path: '/leverancier/gegevens',
          weergave: linkWeergaveRenderer('Leverancier', IconLeverancier),
        },
        {
          type: ELink.Link,
          path: '/bestanden',
          weergave: linkWeergaveRenderer('Bestanden', IconCloud),
        },
        {
          type: ELink.Link,
          path: '/contactpersonen',
          weergave: linkWeergaveRenderer('Personen', IconPersonen),
        },
        {
          type: ELink.Link,
          path: '/communicatie/overzicht',
          weergave: linkWeergaveRenderer('Communicatie', IconCommunicatie),
        },
        {
          type: ELink.Link,
          path: '/transport',
          weergave: linkWeergaveRenderer('Transport', IconTransport),
        },
        {
          type: ELink.GroepLink,
          id: EGroepLink.Financieel,
          weergave: (weergaveProps: any) => (
            <div className="d-flex" style={{ color: Kleur.Grijs }}>
              Financieel
            </div>
          ),
          sublinks: [
            {
              type: ELink.Link,
              path: '/facturen/overzicht',
              weergave: linkWeergaveRenderer('Facturen', IconGrid),
            },
            // {
            //   type: ELink.Link,
            //   path: '/debiteur/aanmaningen',
            //   weergave: linkWeergaveRenderer('Debiteur', IconBoekhouding),
            // },
            // {
            //   type: ELink.Link,
            //   path: '/bankzaken/bankopdrachten',
            //   weergave: linkWeergaveRenderer('Bankzaken', IconEuro),
            // },
            {
              type: ELink.Link,
              path: '/boekingen',
              weergave: linkWeergaveRenderer('Boekingen', IconBoeking),
            },
            {
              type: ELink.Link,
              path: '/bankzaken/bankopdrachten',
              weergave: linkWeergaveRenderer('Bankzaken', IconEuro),
            },
            {
              type: ELink.Link,
              path: '/financieel/overzicht',
              weergave: linkWeergaveRenderer('Overzicht', IconOverzicht),
            },
            {
              type: ELink.Link,
              path: '/rekeningen',
              weergave: linkWeergaveRenderer('Rekeningen', IconOverzicht),
            },
          ],
        },
        // {
        //   type: ELink.Link,
        //   path: '/facturen',
        //   weergave: linkWeergaveRenderer('Facturen', IconGrid),
        // },
        {
          type: ELink.GroepLink,
          id: EGroepLink.Diensten,
          weergave: (weergaveProps: any) => (
            <div className="d-flex" style={{ color: Kleur.Grijs }}>
              Diensten
            </div>
          ),
          sublinks: [
            inkoopdienst.state === ERemoteDataState.Pending || inkoopdienst.data === null
              ? null
              : {
                  type: ELink.Link,
                  path: '/inkoopdienst',
                  weergave: linkWeergaveRenderer('Inkoopdienst', IconInkoop),
                },
            pendeldienst.state === ERemoteDataState.Pending || pendeldienst.data === null
              ? null
              : {
                  type: ELink.Link,
                  path: '/pendeldienst',
                  weergave: linkWeergaveRenderer('Pendeldienst', IconTransport),
                },
            magazijndienst.state === ERemoteDataState.Pending || magazijndienst.data === null
              ? null
              : {
                  type: ELink.Link,
                  path: '/magazijndienst',
                  weergave: linkWeergaveRenderer('Magazijndienst', IconMagazijn),
                },
            transportdienst.state === ERemoteDataState.Pending || transportdienst.data === null
              ? null
              : {
                  type: ELink.Link,
                  path: '/transportdienst',
                  weergave: linkWeergaveRenderer('Transportdienst', IconTransport),
                },
            incassodienst.state === ERemoteDataState.Pending || incassodienst.data === null
              ? null
              : {
                  type: ELink.Link,
                  path: '/incassodienst',
                  weergave: linkWeergaveRenderer('Incassodienst', IconKrediet),
                },
            smsdienst.state === ERemoteDataState.Pending || smsdienst.data === null
              ? null
              : {
                  type: ELink.Link,
                  path: '/smsdienst',
                  weergave: linkWeergaveRenderer('SMS-dienst', IconSMS),
                },
            emaildienst.state === ERemoteDataState.Pending || emaildienst.data === null
              ? null
              : {
                  type: ELink.Link,
                  path: '/emaildienst',
                  weergave: linkWeergaveRenderer('Email-dienst', IconEmail),
                },
            servicedienst.state === ERemoteDataState.Pending || servicedienst.data === null
              ? null
              : {
                  type: ELink.Link,
                  path: '/servicedienst',
                  weergave: linkWeergaveRenderer('Servicedienst', IconService),
                },
            reviewdienst.state === ERemoteDataState.Pending || reviewdienst.data === null
              ? null
              : {
                  type: ELink.Link,
                  path: '/reviewdienst',
                  weergave: linkWeergaveRenderer('Reviewdienst', IconTransport),
                },
            sponsordienst.state === ERemoteDataState.Pending || sponsordienst.data === null
              ? null
              : {
                  type: ELink.Link,
                  path: '/sponsordienst',
                  weergave: linkWeergaveRenderer('Sponsordienst', IconTransport),
                },
            creditmanagementdienst.state === ERemoteDataState.Pending ||
            creditmanagementdienst.data === null
              ? null
              : {
                  type: ELink.Link,
                  path: '/creditmanagementdienst',
                  weergave: linkWeergaveRenderer('Creditmanagement', IconKrediet),
                },
          ].filter((x) => x !== null) as Link<EGroepLink>[],
        },
        {
          type: ELink.GroepLink,
          id: EGroepLink.Overige,
          weergave: (weergaveProps: any) => (
            <div className="d-flex" style={{ color: Kleur.Grijs }}>
              Overige
            </div>
          ),
          sublinks: [
            {
              type: ELink.Link,
              path: '/afdelingen',
              weergave: linkWeergaveRenderer('Afdelingen', IconAfdeling),
            },
            {
              type: ELink.Link,
              path: '/leveringsvoorwaarden',
              weergave: linkWeergaveRenderer('Voorwaarden', IconAssignment),
            },
          ].filter((x) => x !== null) as Link<EGroepLink>[],
        },
      ].filter((x) => x !== null) as Link<EGroepLink>[],
    [
      linkWeergaveRenderer,
      inkoopdienst,
      transportdienst,
      pendeldienst,
      magazijndienst,
      incassodienst,
      smsdienst,
      emaildienst,
      servicedienst,
      reviewdienst,
      sponsordienst,
    ],
  );

  return (
    <KaartNavigatie
      links={links}
      uitgeklapteGroepLinks={uitgeklapteGroepLinks}
      onUitgeklapteGroepLinksChange={setUitgeklapteGroepLinks}
      pathPrefix={props.match.url.endsWith('/') ? props.match.url.slice(0, -1) : props.match.url}
    />
  );
};

export default withRouter(Navigatie);
