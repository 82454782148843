import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import IDialoogProps from '../../../core/IDialoogProps';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { Field, FieldProps, Formik, FormikActions, FormikErrors, FormikProps } from 'formik';
import Combobox, { IOptie } from '../../formulier/Combobox';
import { RootStoreContext } from '../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import api from '../../../api';
import FormikVeldFout from '../../formulier/FormikVeldFout';
import Dialoog from '../../dialogen/Dialoog';
import LoadingSpinner from '../../Gedeeld/LoadingSpinner';
import nameOf from '../../../core/nameOf';
import { IOphalenBezoekdagenResult } from '../../../../../shared/src/api/v2/transport/bezoek';
import { IOphalenDienstenResultElement } from '../../../../../shared/src/api/v2/dienst/transport';
import { IOphalenLandenVoorLijstResult } from '../../../../../shared/src/api/v2/land';
import { Kleur as EKleur } from '../../../bedrijfslogica/constanten';
import * as Yup from 'yup';
import { format } from 'date-fns';
import nlLocale from 'date-fns/locale/nl';
import { IOphalenInstellingenResult } from '../../../../../shared/src/api/v2/instelling';

interface IProps extends IDialoogProps<{}> {
  postcode?: string;
  landID?: number;
  trsDienstID?: number;
}

interface IFormikValues {
  postcode: string | null;
  landID: number | null;
  trsDienstID: number | null;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  postcode: 'Postcode (cijfers)',
  landID: 'Land',
  trsDienstID: 'Transportdienst',
};

const eersteKarakterNaarHoofdletter = (s: string) =>
  s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();

const BezoekdagenInfoDialoog = observer((props: IProps) => {
  const { onAnnuleren, onSuccess, open } = props;
  const { checkStore } = useContext(RootStoreContext);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const formikRef = useRef<Formik<IFormikValues>>(null);

  const [bezoekdagen, setBezoekdagen] = useState<IOphalenBezoekdagenResult | null>(null);

  const bepaalBezoekdagen = useCallback(
    async (postcode: string, landID: number, trsDienstID: number) => {
      const bezoekdagen = await api.v2.transport.bezoek.ophalenBezoekdagen({
        postcode,
        landID,
        trsDienstID,
      });

      setBezoekdagen(bezoekdagen);
    },
    [],
  );

  const [transportdiensten, setTransportdiensten] = useState<
    IOphalenDienstenResultElement[] | null
  >(null);

  const ophalenTransportdiensten = useCallback(async () => {
    const result = await api.v2.dienst.transport.ophalenDiensten({
      filterSchema: {
        filters: [
          // {
          //   naam: 'REL_IDS',
          //   data: [relID],
          // },
        ],
      },
    });
    setTransportdiensten(result.diensten);
  }, []);

  useEffect(() => {
    ophalenTransportdiensten();
  }, [ophalenTransportdiensten]);

  const [landen, setLanden] = useState<IOphalenLandenVoorLijstResult | null>(null);

  const ophalenLanden = useCallback(async () => {
    const result = await api.v2.land.ophalen({
      filterSchema: {
        filters: [
          // {
          //   naam: 'REL_IDS',
          //   data: [relID],
          // },
        ],
      },
    });
    setLanden(result);
  }, []);

  useEffect(() => {
    ophalenLanden();
  }, [ophalenLanden]);

  const [instelling, setInstelling] = useState<IOphalenInstellingenResult | null>(null);

  const ophalenInstellingen = useCallback(async () => {
    const result = await api.v2.instelling.ophalenInstellingen({});
    setInstelling(result);
  }, []);

  useEffect(() => {
    ophalenInstellingen();
  }, [ophalenInstellingen]);

  const initieleWaarden = useMemo<IFormikValues | null>(() => {
    if (landen === null || transportdiensten === null || instelling === null) {
      return null;
    }

    return {
      postcode: props.postcode !== undefined ? props.postcode : '',
      landID: props.landID !== undefined ? props.landID : landen[0].LandID,
      trsDienstID: props.trsDienstID !== undefined ? props.trsDienstID : instelling.TrsDienstID,
    };
  }, [props.postcode, props.trsDienstID, props.landID, landen, transportdiensten, instelling]);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        postcode: Yup.string().required(),
        landID: Yup.number(),
        trsDienstID: Yup.number(),
      }),
    [],
  );

  useEffect(() => {
    if (initieleWaarden === null || !validationSchema.isValid(initieleWaarden)) {
      return;
    }

    bepaalBezoekdagen(
      initieleWaarden.postcode!,
      initieleWaarden.landID!,
      initieleWaarden.trsDienstID!,
    );
  }, [bepaalBezoekdagen, initieleWaarden]);

  const postcodeRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (postcodeRef.current === null) {
      return;
    }
    setTimeout(() => {
      postcodeRef.current!.focus();
    }, 10);
  }, [postcodeRef.current]);

  const debounceHandle = useRef<number | null>(null);
  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      if (debounceHandle.current !== null) {
        clearInterval(debounceHandle.current);
        debounceHandle.current = null;
      }

      actions.setSubmitting(true);
      if (!validationSchema.isValid(values)) {
        actions.setSubmitting(false);
        return;
      }
      debounceHandle.current = setTimeout(
        () => bepaalBezoekdagen(values.postcode!, values.landID!, values.trsDienstID!),
        500,
      ) as any;

      actions.setSubmitting(false);
    },
    [bepaalBezoekdagen],
  );

  return (
    <Dialoog index={props.dialoogIndex || 0}>
      {initieleWaarden === null || transportdiensten === null || landen === null ? (
        <div className="d-flex flex-fill align-items-center justify-content-center m-5">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          <ModalHeader>
            <ModalTitle>Bezoekdagen</ModalTitle>
          </ModalHeader>
          <Formik<IFormikValues>
            ref={formikRef}
            onSubmit={() => null}
            initialValues={initieleWaarden!}
            isInitialValid
            validationSchema={validationSchema}
            render={(formikProps: FormikProps<IFormikValues>) => {
              const { submitForm, isSubmitting, values, isValid } = formikProps;
              return (
                <>
                  <ModalBody>
                    {/*{JSON.stringify(values)}*/}
                    <div className="form-group">
                      <div className="row">
                        <div className="col-4">
                          <label>{veldnamen.postcode}</label>
                          <Field
                            name={nameOf<IFormikValues>('postcode')}
                            render={(fieldProps: FieldProps<IFormikValues>) => {
                              const { form, field } = fieldProps;

                              return (
                                <>
                                  <input
                                    ref={postcodeRef}
                                    type="text"
                                    className="form-control"
                                    {...field}
                                    onChange={(ev) => {
                                      const newValues = {
                                        ...form.values,
                                        [field.name]: ev.target.value,
                                      };
                                      form.setValues(newValues);
                                      handleSubmit(newValues, form);
                                    }}
                                    placeholder="b.v. 3581"
                                  />
                                  {/* <FormikVeldFout fieldProps={fieldProps} /> */}
                                </>
                              );
                            }}
                          />
                        </div>

                        <div className="col-3">
                          <label>{veldnamen.landID}</label>
                          <Field
                            name={nameOf<IFormikValues>('landID')}
                            render={(fieldProps: FieldProps<IFormikValues>) => {
                              const { field, form } = fieldProps;

                              return (
                                <Combobox
                                  geselecteerd={field.value}
                                  opties={landen.map((land) => {
                                    return {
                                      id: land.LandID,
                                      label: land.Naam,
                                    };
                                  })}
                                  onSelectieChange={(x) => {
                                    const newValues = {
                                      ...form.values,
                                      [field.name]: x,
                                    };
                                    form.setValues(newValues);
                                    handleSubmit(newValues, form);
                                  }}
                                />
                              );
                            }}
                          />
                        </div>

                        <div className="col-5">
                          <label>{veldnamen.trsDienstID}</label>
                          <Field
                            name={nameOf<IFormikValues>('trsDienstID')}
                            render={(fieldProps: FieldProps<IFormikValues>) => {
                              const { field, form } = fieldProps;

                              return (
                                <Combobox
                                  geselecteerd={field.value}
                                  opties={transportdiensten.map((dienst) => {
                                    return {
                                      id: dienst.ID,
                                      label: dienst.NaamIdent!,
                                    };
                                  })}
                                  onSelectieChange={(x) => {
                                    const newValues = {
                                      ...form.values,
                                      [field.name]: x,
                                    };
                                    form.setValues(newValues);
                                    handleSubmit(newValues, form);
                                  }}
                                />
                              );
                            }}
                          />
                        </div>
                      </div>

                      <div className="mt-3">
                        {bezoekdagen === null ? (
                          <p>Vul de velden in om te zoeken...</p>
                        ) : (
                          <div
                            style={{
                              backgroundColor: EKleur.HeelLichtGrijs,
                              color: EKleur.BeetjeDonkerGrijs,
                              height: 250,
                              overflowY: 'auto',
                            }}
                            className="p-2 pr-4"
                          >
                            {/*<IcoonWarning style={{ fill: Kleur.Grijs, width: 18, height: 18 }} />*/}
                            {/* <ul className="p-0 mb-0 ml-3">
                              {bezoekdagen.map((datum, i) => (
                                <li key={i}>
                                  {format(new Date(datum), 'dd-MM-yyyy EEEE', {
                                    locale: nlLocale,
                                  })}
                                </li>
                              ))}
                            </ul> */}

                            <table>
                              <tr>
                                <th style={{ width: 100 }}>Datum</th>
                                <th style={{ width: 100 }}>Dag</th>
                              </tr>
                              {bezoekdagen.map((datum, i) => (
                                <tr key={i}>
                                  <td>{format(new Date(datum), 'dd-MM-yyyy')}</td>
                                  <td>
                                    {eersteKarakterNaarHoofdletter(
                                      format(new Date(datum), 'EEEE', {
                                        locale: nlLocale,
                                      }),
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </table>
                          </div>
                        )}
                      </div>

                      {/* <div>{JSON.stringify(voorraadInfo)}</div> */}
                    </div>
                  </ModalBody>
                  <ModalFooter className="d-flex justify-content-start">
                    {/* <button
                      className="btn btn-primary"
                      onClick={submitForm}
                      disabled={isSubmitting || !isValid}
                      style={{ width: 100 }}
                    >
                      Ok
                    </button> */}
                    <button
                      className="btn btn-secondary"
                      onClick={onAnnuleren}
                      style={{ width: 100 }}
                    >
                      Annuleren
                    </button>
                  </ModalFooter>
                </>
              );
            }}
          />
        </>
      )}
    </Dialoog>
  );
});

export default BezoekdagenInfoDialoog;
