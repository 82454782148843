import React, { useContext, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { LeverancierContext } from '../../..';
import Tabblad, { ITabblad } from '../../../../../components/layout/Tabblad';
import Dienst from './Dienst';
import Commissies from '../../../../Diensten/Beheer/Formulieren/CommissiesFormulier/Commissies';
import { IOphalenDienstenResultElement } from '../../../../../../../shared/src/api/v2/dienst/sponsoring';
import api from '../../../../../api';
import { IDienstenContext } from '../../../../Diensten/Beheer/Sponsoring';
import Events from '../../../../Sponsoringv2/Events';
import Contracten from '../../../../Sponsoringv2/Contracten';
import Verzoeken from '../../../../Sponsoringv2/Verzoeken';

export enum ETabblad {
  Verzoeken,
  Events,
  Contracten,
  Commissies,
}

const pathMap: Record<ETabblad, string> = {
  [ETabblad.Verzoeken]: '/verzoeken',
  [ETabblad.Events]: '/events',
  [ETabblad.Contracten]: '/contracten',
  [ETabblad.Commissies]: '/commissies',
};

export const DienstenContext = React.createContext<IDienstenContext>(null as any);

interface IProps extends RouteComponentProps {}

const Sponsordienst: React.FC<IProps> = (props) => {
  const { relID } = useContext(LeverancierContext);
  const [sponsoringDienst, setSponsoringDienst] = useState<IOphalenDienstenResultElement | null>(
    null,
  );

  const tabblad = useMemo<ETabblad | null>(() => {
    const matchKey = Object.keys(pathMap).find((key) => {
      const path = pathMap[Number(key) as ETabblad]!;
      return props.location.pathname.endsWith(path);
    });
    if (matchKey === undefined) {
      const path1 = `/leverancier/${relID}/sponsordienst${pathMap[ETabblad.Events]}`;
      props.history.push(path1);
      return null;
    }
    return Number(matchKey) as ETabblad;
  }, [props.location.pathname, relID]);

  const ophalenDienst = async () => {
    const resultaat = await api.v2.dienst.sponsoring.ophalenDiensten({
      filterSchema: { filters: [{ naam: 'REL_IDS', data: [relID] }] },
    });

    console.log('dienst');
    console.log(resultaat.diensten[0]);
    setSponsoringDienst(resultaat.diensten[0]);
  };

  useEffect(() => {
    ophalenDienst();
  }, []);

  const tabbladen = useMemo<ITabblad<ETabblad>[]>(
    () => [
      {
        id: ETabblad.Verzoeken,
        label: 'Verzoeken',
        content: () => sponsoringDienst && <Verzoeken dienstID={sponsoringDienst.ID}></Verzoeken>,
      },
      {
        id: ETabblad.Events,
        label: 'Events',
        content: () => sponsoringDienst && <Events dienstID={sponsoringDienst.ID}></Events>,
      },
      {
        id: ETabblad.Contracten,
        label: 'Contracten',
        content: () => sponsoringDienst && <Contracten dienstID={sponsoringDienst.ID}></Contracten>,
      },
      {
        id: ETabblad.Commissies,
        label: 'Commissies',
        content: () =>
          sponsoringDienst && <Commissies id={sponsoringDienst.ID} relID={relID}></Commissies>,
      },
    ],
    [sponsoringDienst],
  );

  return (
    <div className="d-flex flex-column flex-fill flex-fill">
      {tabblad !== null && (
        <Tabblad<ETabblad>
          geselecteerd={tabblad}
          onSelectieChange={(tab) => {
            props.history.push(
              `${props.location.pathname.replace(pathMap[tabblad], '')}${pathMap[tab]}`,
            );
          }}
          tabbladen={tabbladen}
        />
      )}
    </div>
  );
};

export default withRouter(Sponsordienst);
