import * as React from 'react';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import IDialoogProps from '../../../../core/IDialoogProps';
import Dialoog from '../../../dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import LoadingSpinner from '../../../Gedeeld/LoadingSpinner';
import api from '../../../../api';
import {
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import PersoonVisualisatie from '../../../personalia/PersoonVisualisatie';
import OrganisatieVisualisatie from '../../../personalia/OrganisatieVisualisatie';
import nameof from '../../../../core/nameOf';
import { format } from 'date-fns';
import ASPGebruikerVisualisatie, { EModus } from '../../../ASPGebruiker/ASPGebruikerVisualisatie';
import VanMijOfIemandAndersSelectie from '../../../VanMijOfIemandAndersSelectie';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../../stores/RootStore';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import RadioKnop from '../../RadioKnop';
import { IFilterSchemaFilter } from '../../../../../../shared/src/models/filter';
import {
  IOproep,
  IOphalenTelefoonOproepenResult,
} from '../../../../../../shared/src/api/v2/telefonie';

interface IDialoogResult {
  telefoonnummer: string;
}

interface IProps extends IDialoogProps<IDialoogResult> {}

const SelectieDialoog = observer((props: IProps) => {
  const { gebruikerStore } = useContext(RootStoreContext);
  const [aspGebrID, setAspGebrID] = useState<number | null>(gebruikerStore.gebruiker!.AspGebrID);
  const [oproepenResult, setOproepenResult] = useState<IOphalenTelefoonOproepenResult | null>(null);

  useEffect(() => {
    api.v2.telefonie
      .ophalenTelefoonOproepen({
        filterSchema: {
          filters: [
            aspGebrID !== null
              ? {
                  naam: 'ASP_GEBR_IDS_IN',
                  data: [aspGebrID],
                }
              : null,
          ].filter((x) => x !== null) as IFilterSchemaFilter[],
        },
        paginatie: {
          index: 0,
          aantal: 20,
        },
      })
      .then(setOproepenResult);
  }, [aspGebrID]);

  const kolommen = useMemo<TypedColumn<IOproep>[]>(
    () => [
      {
        name: 'Nummer',
        title: 'Nummer',
      },
      {
        name: 'Datum',
        title: 'Datum',
      },
      {
        name: '__contact' as any,
        title: 'Contact',
      },
      {
        name: 'AspGebrID',
        title: 'Afgehandeld door',
      },
      {
        name: '__kies_actie' as any,
        title: ' ',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IOproep>[]>(
    () => [
      {
        columnName: 'Nummer',
        width: 130,
      },
      {
        columnName: 'Datum',
        width: 150,
      },
      {
        columnName: '__contact' as any,
        width: 225,
      },
      {
        columnName: 'AspGebrID',
        width: 175,
      },
      {
        columnName: '__kies_actie' as any,
        width: 75,
      },
    ],
    [],
  );

  const handleVanIedereenClick = useCallback(() => setAspGebrID(null), []);

  return (
    <Dialoog index={props.dialoogIndex ?? 0} modalProps={{ size: 'lg' }}>
      <ModalHeader>
        <ModalTitle>Telefoonnummer selecteren</ModalTitle>
      </ModalHeader>
      <ModalBody style={{ padding: 0 }}>
        <div className="d-flex align-items-center">
          <VanMijOfIemandAndersSelectie
            selectieAspGebrID={aspGebrID} //
            onSelectieAspGebrIDChange={setAspGebrID}
          />

          {/* Van iedereen */}
          <div
            className="d-flex align-items-center p-2 pl-4 pr-4"
            style={{
              borderLeft: `1px solid ${Kleur.LichtGrijs}`,
            }}
          >
            <div className="d-flex align-items-center" onClick={handleVanIedereenClick}>
              <span>
                <RadioKnop aangevinkt={aspGebrID === null} onAangevinkt={handleVanIedereenClick} />
              </span>
              <span className="ml-1" style={{ cursor: 'pointer' }}>
                Van iedereen
              </span>
            </div>
          </div>
        </div>
        {oproepenResult === null ? (
          <LoadingSpinner />
        ) : (
          <GridStyleWrapper maxHeight={500} rowAmount={oproepenResult.oproepen.length}>
            <Grid rows={oproepenResult.oproepen} columns={kolommen}>
              <DataTypeProvider
                for={[nameof<IOproep>('Datum')]}
                formatterComponent={(formatterProps) => {
                  const row: IOproep = formatterProps.row;

                  return <span>{format(new Date(row.Datum), 'dd-MM-yyyy HH:mm')}</span>;
                }}
              />

              <DataTypeProvider
                for={['__contact']}
                formatterComponent={(formatterProps) => {
                  const row: IOproep = formatterProps.row;
                  if (row.PersID !== null) {
                    return (
                      <PersoonVisualisatie
                        persID={row.PersID}
                        options={{ geenLinkToepassen: true }}
                      />
                    );
                  }
                  if (row.OrgID !== null) {
                    return <OrganisatieVisualisatie orgID={row.OrgID} />;
                  }
                  return <span />;
                }}
              />

              <DataTypeProvider
                for={[nameof<IOproep>('AspGebrID')]}
                formatterComponent={(formatterProps) => {
                  const row: IOproep = formatterProps.row;
                  if (row.AspGebrID !== null) {
                    return (
                      <ASPGebruikerVisualisatie aspGebrID={row.AspGebrID} modus={EModus.Volledig} />
                    );
                  }
                  return <span />;
                }}
              />

              <DataTypeProvider
                for={['__kies_actie']}
                formatterComponent={(formatterProps) => {
                  // const entiteit = formatterProps.row as TEntiteit;
                  const row: IOproep = formatterProps.row;

                  if (row.Nummer === null) {
                    return <span />;
                  }

                  return (
                    <a
                      href="#"
                      onClick={(ev) => {
                        ev.preventDefault();
                        ev.stopPropagation();

                        props.onSuccess({
                          telefoonnummer: row.Nummer!,
                        });
                      }}
                    >
                      Kies
                    </a>
                  );
                }}
              />

              <VirtualTable
                rowComponent={(dataRowProps) => (
                  <tr
                    key={dataRowProps.tableRow.rowId}
                    onDoubleClick={(ev) => {
                      ev.stopPropagation();
                      const row: IOproep = dataRowProps.row;

                      if (row.Nummer === null) {
                        return;
                      }

                      props.onSuccess({
                        telefoonnummer: row.Nummer,
                      });
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    {dataRowProps.children}
                  </tr>
                )}
              />
              <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
              <TableHeaderRow />
            </Grid>
          </GridStyleWrapper>
        )}
      </ModalBody>
      <ModalFooter className="d-flex flex-row justify-content-start">
        <button className="btn btn-secondary" onClick={props.onAnnuleren} style={{ width: 100 }}>
          Annuleren
        </button>
      </ModalFooter>
    </Dialoog>
  );
});

export default SelectieDialoog;
