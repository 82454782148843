import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import IDialoogProps from '../../../../../core/IDialoogProps';
import Dialoog from '../../../../../components/dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalFooter from 'react-bootstrap/ModalFooter';
import Modal from 'react-bootstrap/Modal';
import ModalBody from 'react-bootstrap/ModalBody';
import Tabblad, { ITabblad } from '../../../../../components/layout/Tabblad';
import api from '../../../../../api';
import LoadingSpinner from '../../../../../components/Gedeeld/LoadingSpinner';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../../../stores/RootStore';
import Locatie from './LocatieTab';

export enum ETabblad {
  Locatie,
  Recent,
}

interface IProps extends IDialoogProps<number> {
  locID?: number;
  relID?: number;
  cntID?: number;
}

export interface ITabbladProps {
  locID?: number;
  relID?: number;
  cntID?: number;
  prodID: number | null;
  onProdIDChange: (value: number | null) => void;
}

/**
 * Selectie van een product met status VERHUUR dat op een externe locatie staat
 * Hierbij kan een LocID of RelID meegegeven zijn:
 * LocID: Alle producten voor die locatie
 * RelID: Alle producten voor die relatie
 * */

const ProductDialoog: React.FC<IProps> = observer((props) => {
  const { dialoogIndex, onAnnuleren, onSuccess, open } = props;

  const [prodID, setProdID] = useState<number | null>(null);

  const tabbladen = useMemo<ITabblad<ETabblad>[]>(
    () => [
      {
        id: ETabblad.Locatie,
        label: 'Producten op locatie',
        content: Locatie,
      },
      // {
      //   id: ETabblad.Recent,
      //   label: 'Recent',
      //   content: () => <span>Nog niet geimplementeerd</span>,
      // },
    ],
    [],
  );

  const [tabblad, setTabblad] = useState<ETabblad>(ETabblad.Locatie);

  const handleOkClick = useCallback(async () => {
    onSuccess(prodID!);
  }, [onSuccess, prodID, tabblad]);

  return (
    <Dialoog
      index={dialoogIndex || 0}
      modalProps={{
        size: 'lg',
      }}
    >
      <ModalHeader>
        <ModalTitle>Koppelen locatieproduct</ModalTitle>
      </ModalHeader>

      {/* {props.locID === undefined && props.relID === undefined ? ( */}
      {
        <ModalBody className="p-0">
          <Tabblad<ETabblad, ITabbladProps>
            tabbladen={tabbladen}
            geselecteerd={tabblad}
            onSelectieChange={(x) => setTabblad(x)}
            options={{
              tabbladComponentProps: {
                locID: props.locID,
                relID: props.relID,
                cntID: props.cntID,
                onProdIDChange: (x) => setProdID(x),

                prodID,
              },
            }}
          />
        </ModalBody>
      }

      <ModalFooter className="d-flex flex-row justify-content-start">
        <button
          className="btn btn-primary"
          onClick={() => handleOkClick()}
          style={{ width: 100 }}
          disabled={prodID === null}
        >
          Ok
        </button>
        <button className="btn btn-secondary" onClick={onAnnuleren} style={{ width: 100 }}>
          Annuleren
        </button>
      </ModalFooter>
    </Dialoog>
  );
});

export default ProductDialoog;
