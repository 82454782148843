import { format } from 'date-fns';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import {
  IConcurrentAanbodscan,
  IConcurrentProductData,
} from '../../../../../../shared/src/api/v2/concurrent';
import IPaginatiePositie from '../../../../../../shared/src/models/IPaginatiePositie';
import api from '../../../../api';
import { IFilterData } from '../../../../components/FilterBalkV2';
import MenuLayout from '../../../../components/MenuLayout';
import ASPTabel from '../../../../components/tabel/ASPTabel';
import TableData, { ITableDataProps } from '../../../../components/tabel/ASPTabel/Body/TableData';
import { ASPKolom, EAspKolomBreedteType } from '../../../../components/tabel/ASPTabel/types';
import useUrlState from '../../../../core/useUrlState';
import { IconToevoegen } from '../../../../components/Icons';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import { RootStoreContext } from '../../../../stores/RootStore';
import { EResultType } from '../../../../stores/CheckStore';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';

type Kolom = 'id' | 'datum';

export enum EFilter {
  Zoekterm = 'ZOEKTERM',
}

interface IProps extends RouteComponentProps {}

interface IToevoegenDialoogState {}
interface IWijzigenDialoogState {
  id: number;
}
interface IUrlState {
  selectie: number[];
  filterdata: IFilterData<EFilter>[];
  toevoegenDialoogState: IToevoegenDialoogState | null;
  wijzigenDialoogState: IWijzigenDialoogState | null;
}

const defaultUrlState: IUrlState = {
  selectie: [],
  filterdata: [],
  toevoegenDialoogState: null,
  wijzigenDialoogState: null,
};

const Item: React.FC<IProps> = (props) => {
  const { checkStore } = useContext(RootStoreContext);
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);

  const [rijen, setRijen] = useState<IConcurrentAanbodscan[] | null>(null);
  const [scanBezig, setScanBezig] = useState<boolean>(false);

  const handleToevoegen = async () => {
    if (scanBezig) return;
    if (
      (
        await checkStore.bevestigen({
          inhoud: (
            <span>Nieuwe scan starten? Dit zal het aanbod van alle concurrenten nagaan.</span>
          ),
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    setScanBezig(true);
    await api.v2.concurrentie.startenScan({});
    setScanBezig(false);
    ophalenRijen();
  };

  const ophalenRijen = async () => {
    const results = await api.v2.concurrentie.ophalenScans({});
    setRijen(results);
  };

  useEffect(() => {
    ophalenRijen();
  }, []);

  const keyExtractor = useCallback((row: IConcurrentAanbodscan) => (row.ID == null ? 0 : row.ID), [
    rijen,
  ]);

  const kolommen = useMemo<ASPKolom<Kolom, IConcurrentAanbodscan>[]>(
    () => [
      {
        key: 'id',
        label: 'id',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 200,
        renderer: (x) => x.ID,
      },
      {
        key: 'datum',
        label: 'Datum',
        breedteType: EAspKolomBreedteType.Flex,
        flex: 3,
        renderer: (item) => format(new Date(item.Datum), 'dd-MM-yyyy HH:mm'),
      },
    ],
    [],
  );

  return (
    <>
      <Helmet>
        <title>Gescande producten</title>
      </Helmet>
      <MenuLayout
        menu={
          <div className="d-flex align-items-center">
            <button
              className="btn btn-sm btn-light d-flex align-items-center"
              style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
              disabled={scanBezig}
              onClick={() => handleToevoegen()}
            >
              <IconToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
              <span className="ml-2">Nieuwe scan starten</span>
            </button>
          </div>
        }
        body={
          rijen == null || scanBezig ? (
            <LoadingSpinner />
          ) : (
            <ASPTabel
              rijen={rijen}
              kolommen={kolommen}
              keyExtractor={keyExtractor}
              selectie={urlState.selectie}
              tdComponent={TdComponent}
            />
          )
        }
      />
    </>
  );
};

const TdComponent = (props: ITableDataProps<Kolom, IConcurrentAanbodscan>) => {
  return (
    <TableData
      {...props}
      style={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
        display: 'block',
        paddingTop: '8px',
      }}
    />
  );
};

export default withRouter(Item);
