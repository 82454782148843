import React, { useCallback, useEffect, useMemo, useState } from 'react';
import IDialoogProps from '../../../../../core/IDialoogProps';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import LoadingSpinner from '../../../../../components/Gedeeld/LoadingSpinner';
import api from '../../../../../api';
import { IOphalenProductmerkenResultElement } from '../../../../../../../shared/src/api/v2/product/merk';
import nameof from '../../../../../core/nameOf';
import FormikVeldFout from '../../../../../components/formulier/FormikVeldFout';
import VinkVeld from '../../../../../components/formulier/VinkVeld';
import Combobox from '../../../../../components/formulier/Combobox';
import { IOphalenDienstenResultElement } from '../../../../../../../shared/src/api/v2/dienst/service';
import Dialoog from '../../../../../components/dialogen/Dialoog';
import nameOf from '../../../../../core/nameOf';
import NumeriekVeld from '../../../../../components/formulier/NumeriekVeld';

interface IProps extends IDialoogProps<null> {
  merkID: number;
}

export interface IFormikValues {
  Actief: boolean;
  GarantiePeriode: number;
  Merkloos: boolean;
  Merknaam: string;
  ServDienstID_Garantie: number | null;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  Actief: 'Actief',
  GarantiePeriode: 'Garantieperiode (maanden)',
  Merkloos: 'Merkloos',
  Merknaam: 'Merknaam',
  ServDienstID_Garantie: 'Garantieleverancier',
};

const WijzigDialoog: React.FC<IProps> = (props) => {
  const { onAnnuleren, onSuccess, open, dialoogIndex } = props;

  const [productMerk, setProductMerk] = useState<IOphalenProductmerkenResultElement | null>(null);
  const [servicediensten, setServicediensten] = useState<IOphalenDienstenResultElement[] | null>(
    null,
  );

  const ophalenProductmerken = useCallback(async () => {
    const result = await api.v2.product.merk.ophalenProductmerken({
      filterSchema: { filters: [{ naam: 'IDS', data: [props.merkID] }] },
    });

    setProductMerk(result.productmerken[0]);
  }, [props.merkID]);

  useEffect(() => {
    ophalenProductmerken();
  }, [ophalenProductmerken]);

  useEffect(() => {
    (async () => {
      const diensten = (
        await api.v2.dienst.service.ophalenDiensten({
          filterSchema: {
            filters: [
              {
                naam: 'IS_ACTIEF',
                data: true,
              },
            ],
          },
        })
      ).diensten;

      setServicediensten(diensten);
    })();
  }, []);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      const params = {
        merkID: props.merkID,
        merknaam: values.Merknaam,
        merkloos: values.Merkloos,
        garantiePeriode: values.GarantiePeriode,
        servDienstID_Garantie: values.ServDienstID_Garantie,
        actief: values.Actief,
      };

      // const checkresult = await api.v2.product.merk.checkWijzigen({});

      await api.v2.product.merk.wijzigen(params);

      onSuccess(null);
    },
    [],
  );

  const initialValues = useMemo<IFormikValues>(() => {
    return {
      Actief: productMerk !== null ? productMerk.Actief : false,
      Merknaam: productMerk !== null ? productMerk.Merknaam : '',
      GarantiePeriode: productMerk !== null ? productMerk.GarantiePeriode : 0,
      Merkloos: productMerk !== null ? productMerk.Merkloos : false,
      Logo_BestandID: productMerk !== null ? productMerk.Logo_BestandID : null,
      ServDienstID_Garantie: productMerk !== null ? productMerk.ServDienstID_Garantie : null,
    };
  }, [productMerk]);

  return (
    <Dialoog index={dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Wijzigen productmerk</ModalTitle>
      </ModalHeader>
      <ModalBody>
        {productMerk === null || servicediensten === null ? (
          <LoadingSpinner />
        ) : (
          <Formik<IFormikValues>
            enableReinitialize
            initialValues={initialValues}
            onSubmit={handleSubmit}
            render={(formikProps: FormikProps<IFormikValues | null>) => {
              const { submitForm, isSubmitting, isValid, values } = formikProps;
              return (
                <>
                  <div className="row">
                    <div className="col-6">
                      <label>{veldnamen.Merknaam}</label>
                      <Field
                        name={nameof<IFormikValues>('Merknaam')}
                        render={(fieldProps: FieldProps<IFormikValues>) => {
                          const { field, form } = fieldProps;

                          return <input {...field} className="form-control" type="text" />;
                        }}
                      />
                    </div>
                    <div className="col-6">
                      <label>{veldnamen.Merkloos}</label>
                      <Field
                        name={nameof<IFormikValues>('Merkloos')}
                        render={(fieldProps: FieldProps<IFormikValues>) => {
                          const { field, form } = fieldProps;

                          return (
                            <VinkVeld
                              aangevinkt={field.value}
                              onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                            />
                          );
                        }}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 mt-2">
                      <label>{veldnamen.ServDienstID_Garantie}</label>

                      <Field
                        name={nameof<IFormikValues>('ServDienstID_Garantie')}
                        render={(fieldProps: FieldProps<IFormikValues>) => {
                          const { field, form } = fieldProps;

                          return (
                            <Combobox
                              legeOptieTonen
                              geselecteerd={field.value}
                              onSelectieChange={(x) => form.setFieldValue(field.name, x)}
                              opties={servicediensten.map((x) => {
                                return {
                                  id: x.ID,
                                  label: x.NaamIdent!,
                                };
                              })}
                              isWisbaar
                            />
                          );
                        }}
                      />
                    </div>

                    <div className="col-12 mt-3">
                      <div className="row col-6">
                        <label>{veldnamen.GarantiePeriode}</label>
                        <Field
                          name={nameOf<IFormikValues>('GarantiePeriode')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <NumeriekVeld
                                waarde={field.value}
                                onChange={(x) => form.setFieldValue(field.name, x)}
                                min={0}
                                max={120}
                              />
                            );
                          }}
                        />
                      </div>
                    </div>

                    <div className="col-12 mt-3">
                      <label>{veldnamen.Actief}</label>
                      <Field
                        name={nameof<IFormikValues>('Actief')}
                        render={(fieldProps: FieldProps<IFormikValues>) => {
                          const { field, form } = fieldProps;
                          return (
                            <VinkVeld
                              aangevinkt={field.value}
                              onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                            />
                          );
                        }}
                      />
                    </div>
                  </div>
                  <ModalFooter className="d-flex justify-content-start mt-2">
                    <button
                      className="btn btn-primary"
                      onClick={submitForm}
                      style={{ width: 100 }}
                      disabled={false}
                    >
                      Ok
                    </button>
                    <button
                      className="btn btn-secondary"
                      style={{ width: 100 }}
                      onClick={onAnnuleren}
                    >
                      Annuleren
                    </button>
                  </ModalFooter>
                </>
              );
            }}
          />
        )}
      </ModalBody>
    </Dialoog>
  );
};

export default WijzigDialoog;
