import React from 'react';
import VinkVeld from '../../formulier/VinkVeld';
import { Root } from './style';

interface IProps {
  geselecteerd: boolean;
  onGeselecteerdChange: (geselecteerd: boolean) => void;
}

const TegelSelectie: React.FC<IProps> = (props) => {
  const { geselecteerd, onGeselecteerdChange } = props;
  return (
    <Root>
      <VinkVeld aangevinkt={geselecteerd} onGewijzigd={onGeselecteerdChange} />
    </Root>
  );
};

export default TegelSelectie;
