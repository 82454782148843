import * as React from 'react';
import { IOphalenBestandsmappenResultElement } from '../../../../../shared/src/api/v2/bestand/bestand';
import { useMemo } from 'react';
import Breadcrumb from './Breadcrumb';
import { IconChevronRight } from '../../Icons';
import { Kleur } from '../../../bedrijfslogica/constanten';
import VeldWeergave from '../../formulier/VeldWeergave';
import { IVerkennerItem } from '../types';

const Chevron = () => {
  return (
    <div style={{ marginLeft: 3, marginRight: 3, position: 'relative', top: -1 }}>
      <IconChevronRight style={{ fill: Kleur.Grijs, width: 18, height: 18 }} />
    </div>
  );
};

interface IProps {
  onBestandsmapIDChange: (id: number | null) => void;
  bestandsmappenboom: IOphalenBestandsmappenResultElement[] | null;
  onVerkennerItemDrop?: (bestandsmapID: number | null, item: IVerkennerItem) => void;
}

const MapBreadcrumb = (props: IProps) => {
  const links = useMemo(() => {
    let data: any = [
      <Breadcrumb
        naam={'Drive'}
        onClick={(ev) => {
          props.onBestandsmapIDChange(null);
        }}
        onVerkennerItemDrop={(item) => props.onVerkennerItemDrop?.(null, item)}
        mapID={null}
      />,
    ];
    if (props.bestandsmappenboom !== null) {
      data = [
        ...data,
        <Chevron />,
        props.bestandsmappenboom.map((map, i) => {
          const isLast = i === props.bestandsmappenboom!.length - 1;
          return (
            <React.Fragment key={i}>
              <Breadcrumb
                key={i}
                naam={map.Naam}
                onClick={(ev) => props.onBestandsmapIDChange(map.ID)}
                isActive={isLast}
                onVerkennerItemDrop={(item) => props.onVerkennerItemDrop?.(map.ID, item)}
                mapID={map.ID}
                isSysteemmap={map.IsSysteemmap}
              />
              {!isLast && <Chevron />}
            </React.Fragment>
          );
        }),
      ];
    }

    return data;
  }, [props.bestandsmappenboom, props.onBestandsmapIDChange]);

  return (
    <div>
      <VeldWeergave style={{ display: 'flex', alignItems: 'center', padding: '4px 15px' }}>
        {links}
      </VeldWeergave>
    </div>
  );
};

export default MapBreadcrumb;
