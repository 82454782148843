import { makeObservable, observable } from 'mobx';
import { RootStore } from './RootStore';
import api from '../api';

class AutorisatieStore {
  private rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    makeObservable(this, {
      isAdmin: observable,
      permissies: observable,
    });
  }

  public isAdmin: boolean | null = null;
  public permissies: string[] | null = null;

  public setIsAdmin(isAdmin: boolean) {
    this.isAdmin = isAdmin;
  }

  public setPermissies = (permissies: string[]) => {
    this.permissies = permissies;
  };

  public async initialiseren() {
    this.setIsAdmin(this.rootStore.gebruikerStore.gebruiker!.IsAdministrator);
    const rolXGebruikersResult = await api.v2.autorisatie.gebruikers.ophalenRolXGebruikers({
      filterSchema: {
        filters: [
          {
            naam: 'ASP_GEBR_IDS',
            data: [this.rootStore.gebruikerStore.gebruiker!.AspGebrID],
          },
        ],
      },
    });
    if (rolXGebruikersResult.gebruikers.length === 0) {
      this.setPermissies([]);
      return;
    }

    const permissieXRolsResult = await api.v2.autorisatie.permissieXRol.ophalenPermissieXRols({
      filterSchema: {
        filters: [
          {
            naam: 'PERM_ROL_IDS',
            data: rolXGebruikersResult.gebruikers.map((x) => x.PermRolID),
          },
        ],
      },
    });
    if (permissieXRolsResult.permissies.length === 0) {
      this.setPermissies([]);
      return;
    }
    const permissieIDs = [...new Set(permissieXRolsResult.permissies.map((x) => x.PermissieID))];

    const permissiesResult = await api.v2.autorisatie.permissie.ophalenPermissies({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: permissieIDs,
          },
        ],
      },
    });

    this.setPermissies(permissiesResult.permissies.map((x) => x.NaamEnum));
  }
}

export default AutorisatieStore;
