import React from 'react';
import { Route, Link, RouteComponentProps, NavLink, Redirect } from 'react-router-dom';
import PaginaHeader from '../PaginaHeader';
import Indexeringen from './Indexeringen';

const Indexering = ({ match }: RouteComponentProps) => (
  <div className="d-flex flex-column flex-fill">
    <PaginaHeader>
      <ul>
        <li>
          <NavLink
            to={`${match.url}/indexeringen`}
            style={{ paddingLeft: 20, paddingRight: 20 }}
            className="d-flex align-items-center justify-content-center"
          >
            {/* <IconTransport style={{ width: 20, height: 20 }} /> */}
            &nbsp; Indexeringen
          </NavLink>
        </li>
      </ul>
    </PaginaHeader>

    <Route
      exact
      path={match.path}
      component={() => <Redirect to={`${match.path}/indexeringen`} />}
    />

    <Route path={`${match.path}/indexeringen`} component={Indexeringen} />

    <Route exact={true} path={match.path} render={() => <h3>selecteer een subpagina</h3>} />
  </div>
);

export default Indexering;
