import React, { useMemo } from 'react';
import { Regel } from '../../types';
import InkoopfactuurSelectieV2, {
  EInkoopfactuurSelectieKolom,
} from '../../../../../formulier/InkoopfactuurSelectieV2';
import { Provider } from '../../../../../formulier/MultiComboboxV2';
import { IOphalenFacturenResultElement } from '../../../../../../../../shared/src/api/v2/inkoopfactuur';
import { addDays } from 'date-fns';
import api from '../../../../../../api';
import { IFilterSchemaUitgebreideFilter } from '../../../../../../../../shared/src/models/filter';

interface IProps {
  inkFactID: number | null;
  onInkFactIDChange: (inkFactID: number | null) => void;
  regel: Regel;
}

const InkoopfactuurMuteren = (props: IProps) => {
  const provider = useMemo<Provider<EInkoopfactuurSelectieKolom, IOphalenFacturenResultElement>>(
    () => async (params) => {
      // Max aantal dagen terug voor facturen die niet openstaan
      const peildatum = addDays(new Date(), -60);
      const result = await api.v2.inkoopfactuur.ophalenFacturen({
        filterSchema: {
          uitgebreideFilter: {
            and: [
              props.regel.relID === null || props.regel.relID === undefined
                ? null
                : {
                    filter: {
                      naam: 'REL_IDS',
                      data: [props.regel.relID],
                    },
                  },
              {
                or: [
                  {
                    filter: {
                      naam: 'IS_OPENSTAAND',
                      data: true,
                    },
                  },
                  {
                    filter: {
                      naam: 'FACTUURDATUM_VANAF',
                      data: peildatum,
                    },
                  },
                ],
              },
            ].filter((x) => x !== null) as IFilterSchemaUitgebreideFilter[],
          },
        },
        orderSchema: {
          orders: [
            {
              naam: 'FACTUURDATUM',
              richting: 'DESC',
            },
            {
              naam: 'FACTUURNUMMER',
              richting: 'DESC',
            },
          ],
        },
      });

      const items = result.facturen.reduce(
        (acc, curr, i) => ({
          ...acc,
          [params.paginatie.index + i]: curr,
        }),
        params.huidigeBron,
      );

      return {
        items,
        totaalAantal: result.totaalAantal,
      };
    },
    [props.regel.relID],
  );

  return (
    <InkoopfactuurSelectieV2
      inkFactID={props.inkFactID}
      onChange={props.onInkFactIDChange}
      provider={provider}
    />
  );
};

export default InkoopfactuurMuteren;
