import styled from 'styled-components';
import React from 'react';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const CenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const ContactsContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  position: absolute;
  top: 50px;
  width: 100%;
  height: calc(100% - 50px);
  box-sizing: border-box;
`;

export const NoContactsMessageContainer = styled.p``;
