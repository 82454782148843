import React from 'react';
import RelatieVisualisatie from '../../../../../personalia/RelatieVisualisatie';

interface IProps {
  data: { relID: number };
}

const RelatieInhoud = (props: IProps) => {
  return <RelatieVisualisatie relID={props.data.relID} />;
};

export default RelatieInhoud;
