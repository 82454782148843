import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { RootStoreContext } from '../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../models/IRemoteData';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  IUrlState as IFacturenOverzichtUrlState,
  defaultUrlState as facturenOverzichtDefaultUrlState,
} from '../../../paginas/Klant/Klantkaart/Entiteiten/entiteiten/Facturen/Overzicht';
import { genereerUrlStateQueryParam } from '../../../core/useUrlState';
import { IconOpenInNew } from '../../Icons';
import { Kleur } from '../../../bedrijfslogica/constanten';
import {
  defaultUrlState,
  IUrlState,
} from '../../../paginas/Klant/Klantkaart/Entiteiten/entiteiten/Facturen/Overzicht';
import { OverlayTrigger } from 'react-bootstrap';
import InfoCard from './InfoCard';

interface IProps extends RouteComponentProps {
  factID: number;
}

const FactuurVisualisatie: React.FC<IProps> = observer((props) => {
  const { factuurStore } = useContext(RootStoreContext);

  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (
      factuurStore.facturen.state === ERemoteDataState.Pending ||
      factuurStore.facturen.data![props.factID] === undefined
    ) {
      factuurStore.enqueueOphalenFacturen([props.factID]);
    }
  }, [props.factID]);

  const factuur = useMemo(() => {
    if (
      factuurStore.facturen.state === ERemoteDataState.Pending ||
      factuurStore.facturen.data![props.factID] === undefined
    ) {
      return null;
    }
    return factuurStore.facturen.data![props.factID]!;
  }, [props.factID, factuurStore.facturen]);

  const link = useMemo<IRemoteData<string>>(() => {
    if (factuur === null) {
      return createPendingRemoteData();
    }

    const urlState: IUrlState = {
      ...defaultUrlState,
      uitgeklapteFacturen: [],
      selectie: [factuur.FactID],
    };
    const query = genereerUrlStateQueryParam(urlState);
    const link = `/klant/${factuur!.RelID}/facturen/overzicht?state=${query}`;
    return createReadyRemoteData(link);
  }, [factuur]);

  const [showInfoCardOverlay, setShowInfoCardOverlay] = useState(false);
  const showInfoCardOverlayTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const handleMouseEnterInfoCardOverlay = useCallback(() => {
    if (showInfoCardOverlayTimeoutRef.current !== null) {
      clearTimeout(showInfoCardOverlayTimeoutRef.current);
    }
    showInfoCardOverlayTimeoutRef.current = setTimeout(() => setShowInfoCardOverlay(true), 800);
  }, []);
  const handleMouseLeaveInfoCardOverlay = useCallback(() => {
    if (showInfoCardOverlayTimeoutRef.current !== null) {
      clearTimeout(showInfoCardOverlayTimeoutRef.current);
    }
    showInfoCardOverlayTimeoutRef.current = setTimeout(() => setShowInfoCardOverlay(false), 400);
  }, []);
  const renderInfoCardOverlay = useCallback(
    (p: any) => {
      return (
        <div
          id={`factuur-visualisatie-overlay-${props.factID}`}
          {...p}
          onMouseEnter={handleMouseEnterInfoCardOverlay}
          onMouseLeave={handleMouseLeaveInfoCardOverlay}
          style={{
            ...p.style,
            zIndex: 99999,
            border: `1px solid ${Kleur.LichtGrijs}`,
            borderRadius: 5,
          }}
        >
          <InfoCard factID={props.factID} />
        </div>
      );
    },
    [props.factID, handleMouseEnterInfoCardOverlay, handleMouseLeaveInfoCardOverlay],
  );

  if (factuur === null) {
    return <span>Laden...</span>;
  }

  return (
    <OverlayTrigger
      trigger="hover"
      placement="auto-start"
      overlay={renderInfoCardOverlay}
      show={showInfoCardOverlay}
      popperConfig={
        {
          modifiers: {
            name: 'offset',
            options: {
              offset: [0, 5],
            },
          },
        } as any
      }
    >
      <span
        className="d-flex align-items-center"
        onMouseEnter={() => {
          setIsHovered(true);
          handleMouseEnterInfoCardOverlay();
        }}
        onMouseLeave={() => {
          setIsHovered(false);
          handleMouseLeaveInfoCardOverlay();
        }}
      >
        <>
          <a
            href="#"
            className="d-flex align-items-center"
            onClick={(ev) => {
              ev.preventDefault();

              const urlState: IFacturenOverzichtUrlState = {
                ...facturenOverzichtDefaultUrlState,
                selectie: [factuur.FactID],
                uitgeklapteFacturen: [factuur.FactID],
              };
              const query = genereerUrlStateQueryParam(urlState);
              // props.history.push(`/klant/${factuur.RelID}/facturen/overzicht?state=${query}`);

              props.history.push(`/klant/${factuur.RelID}/facturen/overzicht?state=${query}`);
            }}
          >
            <span>{factuur.Factuurnummer}</span>
          </a>
          {isHovered && (
            <IconOpenInNew
              style={{
                width: 16,
                height: 16,
                marginLeft: 5,
                marginTop: 3,
                cursor: 'pointer',
                fill: Kleur.Grijs,
              }}
              onClick={(ev) => {
                if (link.state === ERemoteDataState.Pending) {
                  return;
                }

                window.open(link.data!, '_blank');
              }}
            />
          )}
        </>
      </span>
    </OverlayTrigger>
  );
});

export default withRouter(FactuurVisualisatie);
