import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import RadioKnop from '../../../formulier/RadioKnop';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../../stores/RootStore';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import Combobox, { IOptie } from '../../../formulier/Combobox';
import LoadingSpinner from '../../../Gedeeld/LoadingSpinner';
import {
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import { DataTypeProvider, IntegratedSorting, SortingState } from '@devexpress/dx-react-grid';
import api from '../../../../api';
import { formatteerAdresV2, formatteerRelatieNaam } from '../../../../helpers';
import { IconOrganisatie, IconPersoon } from '../../../Icons';
import { RelatieSelectieDialoogContext } from '../index';
import { IFilterSchemaFilter } from '../../../../../../shared/src/models/filter';
import { IOphalenRelatiesResultElementV2 } from '../../../../../../shared/src/api/v2/relatie';
import _ from 'lodash';
import VinkVeld from '../../../formulier/VinkVeld';

type TEntiteit = IOphalenRelatiesResultElementV2;

interface IProps {}

const RecentTabblad: React.FC<IProps> = observer((props) => {
  const { koppelOpties, onGekozen, hoedanigheid, onHoedanigheidChange } = useContext(
    RelatieSelectieDialoogContext,
  );

  const [relaties, setRelaties] = useState<TEntiteit[] | null>(null);
  const [alleenMetLopendeContracten, setAlleenMetLopendeContracten] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      // const filters: IFilterSchemaFilter[] = [];
      // if (koppelOpties.relatieSoort !== ERelatieSoort.Beide) {
      //   filters.push({
      //     naam: 'RELATIESOORT',
      //     data: koppelOpties.relatieSoort === ERelatieSoort.Persoon ? 'PERSOON' : 'ORGANISATIE',
      //   });
      // }
      // if (hoedanigheid !== EHoedanigheid.Beide) {
      //   filters.push({
      //     naam: 'HOEDANIGHEID_NAAM_ENUMS',
      //     data: [hoedanigheidKeyMap[hoedanigheid]!],
      //   });
      // }

      const relatiesResult = (
        await api.v2.relatie.ophalenRelaties({
          filterSchema: {
            filters: [
              {
                naam: 'RELATIESOORT',
                data: 'O',
              },
              {
                naam: 'HOEDANIGHEID_NAAM_ENUMS',
                data: ['KLANT'],
              },
              alleenMetLopendeContracten
                ? {
                    naam: 'MET_LOPENDE_CONTRACTEN',
                    data: true,
                  }
                : null,
            ].filter((x) => x !== null) as IFilterSchemaFilter[],
          },
        })
      ).relaties;

      if (relatiesResult.length === 0) {
        setRelaties([]);
      }

      const relatiesGesorteerd = _.orderBy(
        relatiesResult,
        [
          (x: any) => {
            return x.weergavenaam;
          },
        ],
        ['asc'],
      );

      setRelaties(relatiesGesorteerd);
    })();
  }, [setRelaties, hoedanigheid, koppelOpties.relatieFilterSchema, alleenMetLopendeContracten]);

  const sleutelExtractor = useCallback((row: TEntiteit) => row.RelID, []);
  const kolommen = useMemo<TypedColumn<TEntiteit>[]>(
    () => [
      {
        name: 'Relatiesoort' as any,
        title: ' ',
      },
      {
        name: '__NAAM' as any,
        title: 'Naam',
        getCellValue: (row, columnName) => {
          const entiteit = row;
          return entiteit.weergavenaam;
        },
      },
      {
        name: 'adres',
        title: 'Adres',
        getCellValue: (row, columnName) => {
          const adres = row.adres;
          if (adres === null) {
            return '';
          }
          return adres.Straatnaam + adres.Huisnummer + adres.Bisnummer;
        },
      },
      {
        name: 'Relatienummer',
        title: 'Rel.nr.',
      },
      {
        name: '__kies_actie' as any,
        title: ' ',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<TEntiteit>[]>(
    () => [
      {
        columnName: 'Relatiesoort' as any,
        width: 40,
      },
      {
        columnName: '__NAAM' as any,
        width: 215,
      },
      {
        columnName: 'adres',
        width: 335,
      },
      {
        columnName: 'Relatienummer',
        width: 80,
      },
      {
        columnName: 'Referentiedatum',
        width: 105,
      },
      {
        columnName: '__kies_actie' as any,
        width: 80,
      },
    ],
    [],
  );

  return (
    <div className="flex-fill d-flex flex-column mt-2">
      <div className="d-flex">
        {/* Van mij */}
        <div
          className="d-flex align-items-center p-2 pl-4 pr-4"
          // style={{
          //   borderRight: `1px solid ${Kleur.LichtGrijs}`,
          // }}
        >
          <div className="d-flex align-items-center" onClick={() => null}>
            <span>
              <VinkVeld
                aangevinkt={alleenMetLopendeContracten}
                onGewijzigd={(x) => setAlleenMetLopendeContracten(x)}
              />
            </span>
            <span className="ml-2" style={{ cursor: 'pointer' }}>
              Alleen met lopende contracten
            </span>
          </div>
        </div>
      </div>

      <div className="p-0 mt-2">
        {relaties === null ? (
          <div className="d-flex flex-fill align-items-center justify-content-center">
            <LoadingSpinner />
          </div>
        ) : (
          <GridStyleWrapper maxHeight={500} rowAmount={relaties.length}>
            <Grid getRowId={sleutelExtractor} columns={kolommen as any} rows={relaties}>
              <DataTypeProvider
                for={['Relatiesoort']}
                formatterComponent={(props) => {
                  const iconStyle: React.CSSProperties = {
                    width: 18,
                    height: 18,
                    fill: Kleur.Grijs,
                  };
                  const entiteit = props.row as TEntiteit;
                  return (
                    <span className="ml-2">
                      {entiteit.Relatiesoort === 'P' ? (
                        <IconPersoon style={iconStyle} />
                      ) : (
                        <IconOrganisatie style={iconStyle} />
                      )}
                    </span>
                  );
                }}
              />

              <DataTypeProvider
                for={['adres']}
                formatterComponent={(props) => {
                  const entiteit = props.row as TEntiteit;
                  const a = entiteit.adres;
                  return a === null ? (
                    <span />
                  ) : (
                    <span>
                      {formatteerAdresV2({
                        plaatsnaam: a.Plaatsnaam,
                        landnaamKort: a.LandnaamKort,
                        landnaamEnum: a.LandnaamEnum,
                        huisnummer: a.Huisnummer,
                        straatnaam: a.Straatnaam,
                        postcode: a.Postcode,
                        bisnummer: a.Bisnummer,
                      })}
                    </span>
                  );
                }}
              />

              <DataTypeProvider
                for={['__kies_actie']}
                formatterComponent={(formatterProps) => {
                  const entiteit = formatterProps.row as TEntiteit;
                  return (
                    <a
                      href="#"
                      onClick={(ev) => {
                        ev.stopPropagation();
                        onGekozen(entiteit.RelID);
                      }}
                    >
                      Kies
                    </a>
                  );
                }}
              />

              <SortingState defaultSorting={[]} />
              <IntegratedSorting />
              <VirtualTable
                rowComponent={(rowProps) => (
                  <tr
                    // className=""
                    style={{
                      cursor: 'pointer',
                    }}
                    onDoubleClick={() => {
                      const entiteit: TEntiteit = rowProps.row;
                      onGekozen(entiteit.RelID);
                    }}
                  >
                    {rowProps.children}
                  </tr>
                )}
              />

              <TableColumnResizing columnWidths={kolomBreedtes} />
              <TableHeaderRow showSortingControls />
            </Grid>
          </GridStyleWrapper>
        )}
      </div>
    </div>
  );
});

export default RecentTabblad;
