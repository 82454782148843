import {
  IBepalenSaldoMutatiesParams,
  IBepalenSaldoMutatiesResult,
  IDoorvoerenMutatiesParams,
  IDoorvoerenMutatiesResult,
  IInlezenBestandImportParams,
  IInlezenBestandImportResult,
  IOphalenBestandImportsParams,
  IOphalenBestandImportsResult,
  IOphalenMutatiesParams,
  IOphalenMutatiesResult,
  IToevoegenBestandImportParams,
  IToevoegenBestandImportResult,
  IVerwijderenBestandImportParams,
  IVerwijderenBestandImportResult,
  IVerwijderenMutatiesParams,
  IVerwijderenMutatiesResult,
  IOngedaanMakenDefinitiefParams,
  IOngedaanMakenDefinitiefResult,
  IBepalenLaatsteInleesdatumParams,
  IBepalenLaatsteInleesdatumResult,
  IOphalenDuplicaatMutatiesParams,
  IOphalenDuplicaatMutatiesResult,
  IOphalenMutatiesMetOnbekendeIBANParams,
  IOphalenMutatiesMetOnbekendeIBANResult,
  IKoppelenIBANAanRelatieParams,
  IKoppelenIBANAanRelatieResult,
  IOphalenRelatiesMetBetalingMetAfwijkendeIBANParams,
  IOphalenRelatiesMetBetalingMetAfwijkendeIBANResult,
  IOphalenMutatieBestandenParams,
  IOphalenMutatieBestandenResult,
  IMuterenMutatieBestandenParams,
  IMuterenMutatieBestandenResult,
  IWijzigenBankmutatieParams,
  IWijzigenBankmutatieResult,
} from '../../../../../shared/src/api/v2/bank/mutaties';
import api from '../../index';
import ICheckData from '../../../../../shared/src/models/ICheckData';

const mutatie = {
  async ophalenMutaties(params: IOphalenMutatiesParams): Promise<IOphalenMutatiesResult> {
    return await api.post('v2/bank/mutatie/ophalen-mutaties', params);
  },
  async bepalenSaldoMutaties(
    params: IBepalenSaldoMutatiesParams,
  ): Promise<IBepalenSaldoMutatiesResult> {
    return await api.post('v2/bank/mutatie/bepalen-saldo-mutaties', params);
  },
  async bepalenLaatsteInleesdatum(
    params: IBepalenLaatsteInleesdatumParams,
  ): Promise<IBepalenLaatsteInleesdatumResult> {
    return await api.post('v2/bank/mutatie/bepalen-laatste-inleesdatum', params);
  },
  async ophalenBestandImports(
    params: IOphalenBestandImportsParams,
  ): Promise<IOphalenBestandImportsResult> {
    return await api.post('v2/bank/mutatie/ophalen-bestand-imports', params);
  },
  async toevoegenBestandImport(
    params: IToevoegenBestandImportParams,
  ): Promise<IToevoegenBestandImportResult> {
    return await api.post('v2/bank/mutatie/toevoegen-bestand-import', params);
  },
  async verwijderenBestandImport(
    params: IVerwijderenBestandImportParams,
  ): Promise<IVerwijderenBestandImportResult> {
    return await api.post('v2/bank/mutatie/verwijderen-bestand-import', params);
  },
  async inlezenBestandImport(
    params: IInlezenBestandImportParams,
  ): Promise<IInlezenBestandImportResult> {
    return await api.post('v2/bank/mutatie/inlezen-bestand-import', params);
  },
  async checkDoorvoerenMutaties(params: IDoorvoerenMutatiesParams): Promise<ICheckData> {
    return await api.post('v2/bank/mutatie/check-doorvoeren-mutaties', params);
  },
  async doorvoerenMutaties(params: IDoorvoerenMutatiesParams): Promise<IDoorvoerenMutatiesResult> {
    return await api.post('v2/bank/mutatie/doorvoeren-mutaties', params);
  },
  async checkVerwijderenMutaties(params: IVerwijderenMutatiesParams): Promise<ICheckData> {
    return await api.post('v2/bank/mutatie/check-verwijderen-mutaties', params);
  },
  async verwijderenMutaties(
    params: IVerwijderenMutatiesParams,
  ): Promise<IVerwijderenMutatiesResult> {
    return await api.post('v2/bank/mutatie/verwijderen-mutaties', params);
  },
  async checkOngedaanMakenDefinitief(params: IOngedaanMakenDefinitiefParams): Promise<ICheckData> {
    return await api.post('v2/bank/mutatie/check-ongedaan-maken-definitief', params);
  },
  async ongedaanMakenDefinitief(params: IOngedaanMakenDefinitiefParams): Promise<ICheckData> {
    return await api.post('v2/bank/mutatie/ongedaan-maken-definitief', params);
  },
  async ophalenDuplicaatMutaties(
    params: IOphalenDuplicaatMutatiesParams,
  ): Promise<IOphalenDuplicaatMutatiesResult> {
    return await api.post('v2/bank/mutatie/ophalen-duplicaat-mutaties', params);
  },
  // async ophalenMutatiesMetOnbekendeIBAN(
  //   params: IOphalenMutatiesMetOnbekendeIBANParams,
  // ): Promise<IOphalenMutatiesMetOnbekendeIBANResult> {
  //   return await api.post('v2/bank/mutatie/ophalen-mutaties-met-Onbekende-iban', params);
  // },
  async koppelenIBANAanRelatie(
    params: IKoppelenIBANAanRelatieParams,
  ): Promise<IKoppelenIBANAanRelatieResult> {
    return await api.post('v2/bank/mutatie/koppelen-iban-aan-relatie', params);
  },
  async ophalenRelatiesMetBetalingMetAfwijkendeIBAN(
    params: IOphalenRelatiesMetBetalingMetAfwijkendeIBANParams,
  ): Promise<IOphalenRelatiesMetBetalingMetAfwijkendeIBANResult> {
    return await api.post(
      'v2/bank/mutatie/ophalen-relaties-met-betaling-met-afwijkende-iban',
      params,
    );
  },
  async ophalenMutatieBestanden(
    params: IOphalenMutatieBestandenParams,
  ): Promise<IOphalenMutatieBestandenResult> {
    return await api.post('v2/bank/mutatie/ophalen-mutatie-bestanden', params);
  },
  async muterenMutatieBestanden(
    params: IMuterenMutatieBestandenParams,
  ): Promise<IMuterenMutatieBestandenResult> {
    return await api.post('v2/bank/mutatie/muteren-mutatie-bestanden', params);
  },
  async wijzigenBankmutatie(
    params: IWijzigenBankmutatieParams,
  ): Promise<IWijzigenBankmutatieResult> {
    return await api.post('v2/bank/mutatie/wijzigen-bankmutatie', params);
  },
};

export default mutatie;
