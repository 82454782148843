import React, { useCallback, useEffect, useState } from 'react';
import IDialoogProps from '../../../core/IDialoogProps';
import Dialoog from '../Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import MeertaligTekstveld from '../../formulier/MeertaligTekstveld';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../models/IRemoteData';
import { IOphalenBestandenResultElement } from '../../../../../shared/src/api/v2/bestand/bestand';
import api from '../../../api';
import ITaalTekst from '../../../../../shared/src/models/talen/ITaalTekst';
import { IOphalenTekstenResultElement } from '../../../../../shared/src/api/v2/tekst';
import LoadingSpinner from '../../Gedeeld/LoadingSpinner';

export interface IDialoogOutput {}

interface IProps extends IDialoogProps<IDialoogOutput> {
  bestandID: number;
}

const KorteBestandsbeschrijvingWijzigenDialoog = (props: IProps) => {
  const [isBezig, setIsBezig] = useState(false);
  const [bestand, setBestand] = useState<IRemoteData<IOphalenBestandenResultElement>>(
    createPendingRemoteData(),
  );
  const ophalenBestand = useCallback(async () => {
    const result = await api.v2.bestand.ophalenBestanden({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: [props.bestandID],
          },
        ],
      },
    });
    setBestand(createReadyRemoteData(result.bestanden[0]));
  }, [props.bestandID]);
  useEffect(() => {
    ophalenBestand();
  }, [ophalenBestand]);

  const [teksten, setTeksten] = useState<IRemoteData<IOphalenTekstenResultElement[] | null>>(
    createPendingRemoteData(),
  );
  const ophalenTeksten = useCallback(async () => {
    if (bestand.state === ERemoteDataState.Pending) {
      return;
    }
    if (bestand.data!.KorteOmschrijving_TekstID === null) {
      setTeksten(createReadyRemoteData(null));
      return;
    }

    const tekstenResult = await api.v2.tekst.ophalenTekstenInAlleTalen({
      tekstIDs: [bestand.data!.KorteOmschrijving_TekstID!],
    });
    setTeksten(createReadyRemoteData(tekstenResult.teksten));
  }, [bestand]);
  useEffect(() => {
    ophalenTeksten();
  }, [ophalenTeksten]);

  const [waarden, setWaarden] = useState<IRemoteData<ITaalTekst[]>>(createPendingRemoteData());
  useEffect(() => {
    if (teksten.state === ERemoteDataState.Pending) {
      return;
    }
    if (teksten.data === null) {
      setWaarden(createReadyRemoteData([]));
      return;
    }
    const data = teksten.data!;
    const nieuweWaarden = data.map(
      (tekst): ITaalTekst => ({
        taalID: tekst.TaalID,
        tekst: tekst.Tekst ?? '',
        toepassen: tekst.Toepassen,
      }),
    );
    setWaarden(createReadyRemoteData(nieuweWaarden));
  }, [teksten]);

  const handleOk = useCallback(async () => {
    setIsBezig(true);
    const data = waarden.data!;

    await api.v2.bestand.wijzigenKorteBeschrijving({
      bestandID: props.bestandID,
      teksten: data,
    });
    props.onSuccess({});

    setIsBezig(false);
  }, [waarden]);

  return (
    <Dialoog index={props.dialoogIndex ?? 0}>
      <ModalHeader>
        <ModalTitle>Korte bestandsomschrijving wijzigen</ModalTitle>
      </ModalHeader>
      {waarden.state === ERemoteDataState.Pending ? (
        <ModalBody>
          <LoadingSpinner />
        </ModalBody>
      ) : (
        <>
          <ModalBody>
            <label>Korte beschrijvingen</label>
            <MeertaligTekstveld
              waarden={waarden.data!}
              onChange={(x) => setWaarden(createReadyRemoteData(x))}
              defaultUitgeklapt
            />
            <div className="mt-2 text-muted">
              Let op:
              <br /> Deze gegevens kunnen publiekelijk worden weergegeven.
            </div>
          </ModalBody>
          <ModalFooter className="d-flex flex-row justify-content-start">
            <button
              className="btn btn-primary d-flex align-items-center justify-content-center"
              onClick={handleOk}
              style={{ width: 100 }}
              disabled={isBezig}
            >
              {isBezig && (
                <span className="mr-2">
                  <LoadingSpinner soort="light" grootte="15px" dikte="2px" />
                </span>
              )}
              Ok
            </button>
            <button
              className="btn btn-secondary"
              onClick={props.onAnnuleren}
              style={{ width: 100 }}
              disabled={isBezig}
            >
              Annuleren
            </button>
          </ModalFooter>
        </>
      )}
    </Dialoog>
  );
};

export default KorteBestandsbeschrijvingWijzigenDialoog;
