import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import api from '../../../../../../../api';
import LoadingSpinner from '../../../../../../../components/Gedeeld/LoadingSpinner';
import { observer } from 'mobx-react-lite';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import useUrlState from '../../../../../../../core/useUrlState';
import { RootStoreContext } from '../../../../../../../stores/RootStore';
import {
  DXTableCheckboxComponent,
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
  DXTableToggleCellComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../../../../helpers/dxTableGrid';
import { Kleur } from '../../../../../../../bedrijfslogica/constanten';
import {
  Grid,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  DataTypeProvider,
  EditingState,
  IntegratedSorting,
  RowDetailState,
  SelectionState,
  SortingState,
  VirtualTable as VirtualTableBase,
} from '@devexpress/dx-react-grid';
import { IconToevoegen } from '../../../../../../../components/Icons';
import * as _ from 'lodash';
// import ToevoegenAdviesDialoog from '../ToevoegenAdviesDialoog';
import { IOphalenVertegenwoordigersResultElement } from '../../../../../../../../../shared/src/api/v2/relatie/vertegenwoordiger';
import RelatieVisualisatie from '../../../../../../../components/personalia/RelatieVisualisatie';
import MenuLayout from '../../../../../../../components/MenuLayout';
import RelatieSelectieDialoog, {
  EHoedanigheid,
} from '../../../../../../../components/personalia/RelatieSelectieDialoog';
import nameof from '../../../../../../../core/nameOf';
import { format } from 'date-fns';
import { EResultType } from '../../../../../../../stores/CheckStore';

interface IProps extends RouteComponentProps {
  relID: number;
}

export interface IToevoegenDialoogState {}

interface IUrlState {
  selectie: number[];
  toevoegenDialoogState: IToevoegenDialoogState | null;
}

const defaultUrlState: IUrlState = {
  selectie: [],
  toevoegenDialoogState: null,
};

const geenData = {
  noData: 'Er worden geen relaties vertegenwoordigd',
};

export interface IRegel extends IOphalenVertegenwoordigersResultElement {}

const Vertegenwoordigden: React.FC<IProps> = observer((props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState<IUrlState>(props, defaultUrlState);
  const { checkStore } = useContext(RootStoreContext);

  const [vertegenwoordigingen, setVertegenwoordigingen] = useState<IRegel[] | null>(null);

  const ophalenVertegenwoordigingen = useCallback(async () => {
    const result = await api.v2.relatie.vertegenwoordiging.ophalenVertegenwoordigers({
      filterSchema: {
        filters: [{ naam: 'VERTEGENWOORDIGER_REL_IDS', data: [props.relID] }],
      },
    });

    setVertegenwoordigingen(result.vertegenwoordigers);
  }, []);

  useEffect(() => {
    ophalenVertegenwoordigingen();
  }, [ophalenVertegenwoordigingen]);

  const keyExtractor = useCallback((row: IRegel) => row.ID, []);

  const kolommen = useMemo<TypedColumn<IRegel>[]>(
    () => [
      {
        name: '__relatie' as any,
        title: 'Vertegenwoordigde relatie',
      },
      {
        name: '__type' as any,
        title: 'Hoedanigheid',
      },
      {
        name: '__facturen' as any,
        title: 'Facturen doorsturen',
      },
      {
        name: 'RecordToegevoegd',
        title: 'Geregistreerd',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRegel>[]>(
    () => [
      {
        columnName: '__relatie' as any,
        width: 250,
      },
      {
        columnName: '__type' as any,
        width: 175,
      },
      {
        columnName: '__facturen' as any,
        width: 175,
      },
      {
        columnName: 'RecordToegevoegd',
        width: 150,
      },
    ],
    [],
  );

  return (
    <>
      {vertegenwoordigingen === null ? (
        <LoadingSpinner />
      ) : (
        <>
          <MenuLayout
            menu={
              <div className="d-flex flex-column">
                <div className="d-flex">
                  <div className="d-flex align-items-center">
                    <button
                      className="btn btn-sm btn-light d-flex align-items-center"
                      style={{
                        border: `1px solid ${Kleur.LichtGrijs}`,
                      }}
                      // disabled={urlState.selectie.length === 0}
                      onClick={async () => {
                        setUrlStateSync('toevoegenDialoogState', {});
                      }}
                    >
                      <IconToevoegen
                        style={{
                          width: 16,
                          height: 16,
                          fill: Kleur.Grijs,
                        }}
                      />
                      <span className="ml-2">Relatie toevoegen</span>
                    </button>
                  </div>
                </div>
              </div>
            }
            body={
              <>
                <GridStyleWrapper height="calc(100vh - 150px)">
                  <Grid rows={vertegenwoordigingen} columns={kolommen} getRowId={keyExtractor}>
                    <DataTypeProvider
                      for={['__relatie']}
                      formatterComponent={(formatterProps) => {
                        const rij: IRegel = formatterProps.row;

                        return <RelatieVisualisatie relID={rij.RelID!} />;
                      }}
                    />

                    <DataTypeProvider
                      for={['__type']}
                      formatterComponent={(formatterProps) => {
                        const rij: IRegel = formatterProps.row;

                        return <span>{rij.hoedanigheid.Naam}</span>;
                      }}
                    />

                    <DataTypeProvider
                      for={['__facturen']}
                      formatterComponent={(formatterProps) => {
                        const rij: IRegel = formatterProps.row;

                        return <span>{rij.FacturenDoorsturen ? 'Ja' : 'Nee'}</span>;
                      }}
                    />

                    <DataTypeProvider
                      for={[nameof<IRegel>('RecordToegevoegd')]}
                      formatterComponent={(formatterProps) => {
                        return <span>{format(new Date(formatterProps.value), 'dd-MM-yyyy')}</span>;
                      }}
                    />

                    {/* <SortingState defaultSorting={[]} />
                    <IntegratedSorting /> */}

                    <VirtualTable messages={geenData} />

                    <RowDetailState defaultExpandedRowIds={[]} />

                    <EditingState
                      onAddedRowsChange={() => {}}
                      onCommitChanges={async (changes) => {
                        if (changes.deleted === undefined) {
                          return;
                        }
                        const deleted = changes.deleted;
                        const id = deleted[deleted.length - 1] as number;

                        const relID = vertegenwoordigingen.find((x) => x.ID === id)!.RelID;

                        const params = { relID, ids: [id] };
                        const checkData = await api.v2.relatie.vertegenwoordiging.checkVerwijderenVertegenwoordigers(
                          params,
                        );
                        if (
                          (await checkStore.controleren({ checkData })).type ===
                          EResultType.Annuleren
                        ) {
                          return;
                        }

                        if (
                          (
                            await checkStore.bevestigen({
                              inhoud: 'De relatie verwijderen uit deze vertegenwoordiging?',
                            })
                          ).type === EResultType.Annuleren
                        ) {
                          return;
                        }

                        await api.v2.relatie.vertegenwoordiging.verwijderenVertegenwoordigers(
                          params,
                        );

                        ophalenVertegenwoordigingen();
                      }}
                      onEditingRowIdsChange={(rowIds) => {
                        const id = rowIds[rowIds.length - 1] as number;
                      }}
                    />

                    <TableEditColumn
                      width={35}
                      // showEditCommand
                      showDeleteCommand
                      cellComponent={DXTableEditColumnCellComponent}
                      commandComponent={DXTableEditColumnCommandComponent}
                    />

                    {/* <SelectionState
                      selection={urlState.selectie}
                      onSelectionChange={(value) => setUrlStateSync('selectie', value as number[])}
                    /> */}
                    {/* <TableRowDetail
                      contentComponent={RowDetailComponent}
                      toggleCellComponent={DXTableToggleCellComponent}
                    /> */}
                    {/* <TableSelection cellComponent={DXTableCheckboxComponent} /> */}

                    <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
                    <TableHeaderRow />
                  </Grid>
                </GridStyleWrapper>
              </>
            }
          />
        </>
      )}

      {urlState.toevoegenDialoogState !== null && (
        <RelatieSelectieDialoog
          open
          onSuccess={async (result) => {
            setUrlStateSync('toevoegenDialoogState', null);

            if (result.relID === props.relID) {
              checkStore.melden({
                titel: 'Je kunt een relatie niet door zichzelf laten vertegenwoordigen',
              });
              return;
            }

            const params = { relID: result.relID, vertegenwoordiger_RelID: props.relID };
            const checkData = await api.v2.relatie.vertegenwoordiging.checkToevoegenVertegenwoordiger(
              params,
            );

            if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
              return;
            }
            await api.v2.relatie.vertegenwoordiging.toevoegenVertegenwoordiger(params);
            // props.onRelIDChange(result.relID);

            ophalenVertegenwoordigingen();
          }}
          onAnnuleren={() => setUrlStateSync('toevoegenDialoogState', null)}
          koppelOpties={{
            hoedanigheid: EHoedanigheid.Klant,
          }}
        />
      )}
    </>
  );
});

export default withRouter(Vertegenwoordigden);
