import React, { useCallback, useContext, useEffect, useMemo, useState, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import IDialoogProps from '../../../../../../../../core/IDialoogProps';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { Field, FieldProps, Formik, FormikActions, FormikErrors, FormikProps } from 'formik';
import Combobox, { IOptie } from '../../../../../../../../components/formulier/Combobox';
import { RootStoreContext } from '../../../../../../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import VinkVeld from '../../../../../../../../components/formulier/VinkVeld';
import api from '../../../../../../../../api';
import { EResultType } from '../../../../../../../../stores/CheckStore';
import * as Yup from 'yup';
import FormikVeldFout from '../../../../../../../../components/formulier/FormikVeldFout';
import DatumKiezer from '../../../../../../../../components/formulier/DatumKiezer';
import nameof from '../../../../../../../../core/nameOf';
import { addDays, format } from 'date-fns';
import { dagDatum } from '../../../../../../../../helpers/datum';

interface IFormikValues {
  uitvoerdatum: Date | null;
}

interface IProps extends IDialoogProps<null> {
  factIDs: number[];
  uitvoerdatum?: Date;
}

const veldnamen = {
  uitvoerdatum: 'Uitvoerdatum',
};

const maximaleDatum = addDays(new Date(), 65);

const NieuwBankopdrachtDialoog = observer((props: IProps) => {
  const { onAnnuleren, onSuccess, open, factIDs, uitvoerdatum } = props;

  const { checkStore } = useContext(RootStoreContext);
  const formikRef = useRef<Formik<IFormikValues>>(null);

  // const [prolongatieDatum, setProlongatieDatum] = useState<Date | null>(null);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      if (
        (
          await checkStore.bevestigen({
            inhoud: `Bankopdracht(en) maken?`,
          })
        ).type === EResultType.Annuleren
      ) {
        actions.setSubmitting(false);
        return;
      }

      const result = await api.v2.factuur.makenBankopdrachten({
        factIDs: props.factIDs,
        uitvoerdatum: format(values.uitvoerdatum!, 'yyyy-MM-dd'),
      });

      onSuccess(null);
      actions.setSubmitting(false);
    },
    [onSuccess],
  );

  const initieleWaarden = useMemo<IFormikValues>(() => {
    const datum = uitvoerdatum !== undefined ? new Date(uitvoerdatum) : addDays(new Date(), 1);
    return {
      uitvoerdatum: datum !== null ? dagDatum(datum) : null,
    };
  }, [uitvoerdatum]);

  // const validatieschema = Yup.object().shape({
  //   uitvoerdatum: Yup.date().required(),
  // });

  return (
    <Modal show={open}>
      <ModalHeader>
        <ModalTitle>Bankopdracht(en) maken</ModalTitle>
      </ModalHeader>
      <Formik<IFormikValues>
        ref={formikRef}
        isInitialValid
        enableReinitialize
        onSubmit={handleSubmit}
        initialValues={initieleWaarden}
        // validationSchema={validatieschema}
        validateOnChange
        validateOnBlur
        render={(formikProps: FormikProps<IFormikValues>) => {
          const { submitForm, isSubmitting, values, isValid } = formikProps;
          return (
            <>
              <ModalBody>
                <div className="form-group">
                  <div className="row">
                    <div className="col-12 mt-3">
                      <label>{veldnamen.uitvoerdatum}</label>
                      <Field
                        name={nameof('uitvoerdatum')}
                        render={({ field, form }: FieldProps<IFormikValues>) => {
                          return (
                            <div className="d-flex align-items-center">
                              <DatumKiezer
                                onGewijzigd={(x) => {
                                  form.setFieldValue(field.name, x);
                                }}
                                waarde={field.value}
                                determineValidDate={(date) => {
                                  return date >= addDays(new Date(), -1) && date < maximaleDatum;
                                }}
                                determineNextValidDate={(date) => {
                                  const newDate = addDays(date, 1);
                                  if (newDate < maximaleDatum) {
                                    return newDate;
                                  }
                                  return null;
                                }}
                                determinePreviousValidDate={(date) => {
                                  const minDate = addDays(new Date(), -1);
                                  const newDate = addDays(date, -1);
                                  if (newDate >= minDate) {
                                    return newDate;
                                  }
                                  return null;
                                }}
                              />
                            </div>
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
              </ModalBody>
              <ModalFooter className="d-flex justify-content-start">
                <button
                  className="btn btn-primary"
                  onClick={submitForm}
                  disabled={isSubmitting || !isValid}
                  style={{ width: 100 }}
                >
                  Ok
                </button>
                <button
                  className="btn btn-secondary"
                  onClick={onAnnuleren}
                  disabled={isSubmitting || !isValid}
                  style={{ width: 100 }}
                >
                  Annuleren
                </button>
              </ModalFooter>
            </>
          );
        }}
      />
    </Modal>
  );
});

export default NieuwBankopdrachtDialoog;
