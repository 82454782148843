import React, { useContext, useMemo } from 'react';
import { IProps } from '@tinymce/tinymce-react/lib/cjs/main/ts/components/Editor';
import { IContext, OpdrachtContext } from '../index';
import VeldWeergave from '../../../formulier/VeldWeergave';
import LoadingSpinner from '../../../Gedeeld/LoadingSpinner';
import { format } from 'date-fns';
import GegevensLayout from '../../../layout/GegevensLayout';
import FormatteerBedrag from '../../../MutatieBedrag';
import { GridStyleWrapper, TypedColumn } from '../../../../helpers/dxTableGrid';
import { Grid, Table, TableHeaderRow } from '@devexpress/dx-react-grid-bootstrap4';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import nameof from '../../../../core/nameOf';

const RegelsTab: React.FC<IProps> = (props) => {
  const { opdracht, regels } = useContext<IContext>(OpdrachtContext);

  return <span></span>;
};

export default RegelsTab;
