import React, { useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

interface IProps {
  isInactief: boolean;
  element: JSX.Element;
  onClick?: () => void;
  rootStyle?: React.CSSProperties;
  inactiefStyle?: React.CSSProperties;
  inactiefTooltip?: React.ReactNode | (() => React.ReactNode) | any;
  inactiefContent?: React.ComponentType;
  rootClassName?: string;
}

const InactiefOverlay = (props: IProps) => {
  const InactiefContent = props.inactiefContent;

  return (
    <OverlayTrigger
      delay={{ show: 250, hide: 400 }}
      overlay={props.inactiefTooltip}
      trigger={props.isInactief && props.inactiefTooltip !== undefined ? 'hover' : []}
    >
      <div
        style={{
          position: 'relative',
          cursor: props.onClick === undefined ? 'inherit' : 'pointer',
          ...props.rootStyle,
        }}
        onClick={props.isInactief ? props.onClick : undefined}
        className={props.rootClassName}
      >
        {props.element}
        {props.isInactief && (
          <div
            style={{
              // position: 'absolute',
              // top: pos.y,
              // left: pos.x,
              // width: size.width,
              // height: size.height,
              position: 'absolute',
              top: 0,
              left: 0,
              height: '100%',
              width: '100%',
              cursor: `not-allowed`,
              backgroundColor: 'rgba(50, 50, 50, 0.1)',
              // backgroundColor: 'rgba(50, 50, 50, 0.25)',
              // borderRadius: 3,
              // borderTopLeftRadius: 0,
              // borderBottomLeftRadius: 0,
              display: 'flex',
              ...props.inactiefStyle,
            }}
          >
            {InactiefContent !== undefined && <InactiefContent />}
          </div>
        )}
      </div>
    </OverlayTrigger>
  );
};

export default InactiefOverlay;
