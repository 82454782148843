import React, { useCallback, useMemo, useState, useContext, useEffect } from 'react';
import Dialoog from '../../dialogen/Dialoog';
import IDialoogProps from '../../../core/IDialoogProps';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import { RootStoreContext } from '../../../stores/RootStore';
import { EResultType } from '../../../stores/CheckStore';
import LoadingSpinner from '../../Gedeeld/LoadingSpinner';
import * as Yup from 'yup';
import api from '../../../api';
import { IOphalenContractenResultElementV2 } from '../../../../../shared/src/api/v2/contract';
import { IToevoegenStandaardLeveropdrachtenParams } from '../../../../../shared/src/api/v2/transport/opdracht/nieuw';
import DatumKiezer from '../../formulier/DatumKiezer';
import ContactSelectie, { EType } from '../../formulier/ContactSelectie';
import _ from 'lodash';
import VinkVeld from '../../formulier/VinkVeld';
import nameOf from '../../../core/nameOf';

export interface IDialoogResult {}

interface IProps extends IDialoogProps<IDialoogResult> {
  cntIDs: number[];
}

interface IFormikValues {
  persID: number | null;
  bezoekdatum: Date | null;
  reserveren: boolean;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  persID: 'Persoon',
  bezoekdatum: 'Bezoekdatum',
  reserveren: 'Product direct reserveren',
};

const ToevoegenStandaardLeveropdrachtenDialoog: React.FC<IProps> = (props) => {
  const { children, dialoogIndex, onAnnuleren, onSuccess, open } = props;
  const { checkStore } = useContext(RootStoreContext);

  const [contracten, setContracten] = useState<IOphalenContractenResultElementV2[] | null>(null);
  const [relIDs, setRelIDs] = useState<number[] | null>(null);

  const ophalenContracten = useCallback(async () => {
    const result = await api.v2.contract.ophalenContractenV2({
      filterSchema: {
        filters: [{ naam: 'IDS', data: props.cntIDs }],
      },
    });

    const contracten = result.contracten;

    const relIDs = _.uniq(contracten.map((x) => x.RelID));

    setRelIDs(relIDs);
    setContracten(contracten);
  }, [props.cntIDs]);

  useEffect(() => {
    ophalenContracten();
  }, [ophalenContracten]);

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (contracten === null) {
      return null;
    }

    return {
      persID: contracten[0].PersID,
      bezoekdatum: null, //dagDatum(new Date()),
      reserveren: false,
    };
  }, [contracten]);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        // bezoekDatum: Yup.date().required(
        //   teksten.formulier.E_VERPLICHT_VELD({
        //     veldnaam: veldnamen.bezoekDatum,
        //   }),
        // ),
        persID: Yup.number().required(),
        bezoekDatum: Yup.date().nullable(),
        reserveren: Yup.boolean().required(),
      }),
    [],
  );

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      const params: IToevoegenStandaardLeveropdrachtenParams = {
        cntIDs: props.cntIDs,
        persID: values.persID!,
        bezoekdatum: values.bezoekdatum,
        reserveren: values.reserveren,
      };

      const checkData = await api.v2.transport.opdracht.nieuw.checkToevoegenStandaardLeveropdrachten(
        params,
      );
      if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
        return;
      }

      if (
        (
          await checkStore.bevestigen({
            inhoud: <span>Standaard leveropdrachten maken?</span>,
          })
        ).type === EResultType.Annuleren
      ) {
        actions.setSubmitting(false);
        return;
      }

      await api.v2.transport.opdracht.nieuw.toevoegenStandaardLeveropdrachten(params);

      onSuccess({});
      actions.setSubmitting(false);
    },
    [props.cntIDs],
  );

  return (
    <Dialoog index={0}>
      {initialValues === null || contracten === null || relIDs === null ? (
        <div className="flex-fill d-flex align-items-center justify-content-center">
          <LoadingSpinner />
        </div>
      ) : (
        <Formik<IFormikValues>
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
          render={(formikProps: FormikProps<IFormikValues>) => {
            const { submitForm, isSubmitting, isValid } = formikProps;
            return (
              <>
                <ModalHeader>
                  <ModalTitle>Toevoegen standaard leveropdrachten</ModalTitle>
                </ModalHeader>
                <ModalBody>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-12">
                        <label>{veldnamen.persID}</label>
                        <Field
                          name="persID"
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <>
                                <ContactSelectie
                                  alleenVoorRelIDs={relIDs}
                                  value={
                                    field.value === null
                                      ? null
                                      : {
                                          persID: field.value,
                                          orgID: null,
                                        }
                                  }
                                  onChange={(x) =>
                                    form.setFieldValue(field.name, x?.persoon?.PersID ?? null)
                                  }
                                  options={{
                                    types: [EType.Persoon],
                                  }}
                                />
                                {/* <FormikVeldFout
                                  fieldProps={fieldProps}
                                  directTonen={hasSubmitted}
                                /> */}
                              </>
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <label>{veldnamen.bezoekdatum}</label>
                        <Field
                          name="bezoekdatum"
                          render={({ field, form }: FieldProps<IFormikValues>) => {
                            return (
                              <div className="d-sflex ">
                                <DatumKiezer
                                  onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                  waarde={field.value}
                                />
                              </div>
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 d-flex align-items-center mt-3">
                        <Field
                          name={nameOf<IFormikValues>('reserveren')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            return (
                              <VinkVeld
                                aangevinkt={field.value}
                                onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                              />
                            );
                          }}
                        />
                        <span className="ml-2">{veldnamen.reserveren}</span>
                      </div>
                    </div>
                  </div>
                </ModalBody>
                <ModalFooter className="d-flex flex-row justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    style={{ width: 100 }}
                    disabled={!isValid || isSubmitting}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={onAnnuleren}
                    style={{ width: 100 }}
                    disabled={isSubmitting}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Dialoog>
  );
};

export default ToevoegenStandaardLeveropdrachtenDialoog;
