import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import MenuLayout from '../../../../../../components/MenuLayout';
import api from '../../../../../../api';
import { IEntiteitProps } from '../../../../../../components/kaart/EntiteitContainer';
import { Kleur } from '../../../../../../bedrijfslogica/constanten';
import { IconKruis, IconToevoegen } from '../../../../../../components/Icons';
import {
  DXTableCheckboxComponent,
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../../../helpers/dxTableGrid';
import {
  Grid,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import LoadingSpinner from '../../../../../../components/Gedeeld/LoadingSpinner';
import {
  DataTypeProvider,
  EditingState,
  SelectionState,
  Table as TableBase,
} from '@devexpress/dx-react-grid';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../../../../stores/RootStore';
import { AutoSizer } from 'react-virtualized';
import useUrlState from '../../../../../../core/useUrlState';
import { RouteComponentProps, withRouter } from 'react-router';
import nameOf from '../../../../../../core/nameOf';
import { IOphalenPartnershipsResultElement } from '../../../../../../../../shared/src/api/v2/partnership';
import { format } from 'date-fns';
import MutatieDialoog from './MutatieDialoog';

const CellComponent = (props: TableBase.DataCellProps) => {
  const isInterneLocatiesKolom = props.column.name === '__interneLocaties';
  return (
    <td style={{ overflow: isInterneLocatiesKolom ? 'visible' : undefined }}>{props.children}</td>
  );
};

interface IProps extends IEntiteitProps, RouteComponentProps {}

export interface IToevoegenDialoogState {}
export interface IWijzigenDialoogState {
  id: number;
}

interface IUrlState {
  selectie: number[];
  toevoegenDialoogState: IToevoegenDialoogState | null;
  wijzigenDialoogState: IWijzigenDialoogState | null;
}

const defaultUrlState: IUrlState = {
  selectie: [],
  toevoegenDialoogState: null,
  wijzigenDialoogState: null,
};

const Partnerships: React.FC<IProps> = observer((props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const { checkStore } = useContext(RootStoreContext);

  const [partnerships, setPartnerships] = useState<IOphalenPartnershipsResultElement[] | null>(
    null,
  );

  const ophalenPartnerships = useCallback(async () => {
    const partnerships = await api.v2.partnership.ophalenPartnerships({
      filterSchema: {
        filters: [
          {
            naam: 'REL_IDS',
            data: [props.relID],
          },
        ],
      },
    });

    setPartnerships(partnerships.partnerships);
  }, [props.relID]);

  useEffect(() => {
    ophalenPartnerships();
  }, [ophalenPartnerships]);

  const keyExtractor = useCallback((row: IOphalenPartnershipsResultElement) => row.ID, []);
  const kolommen = useMemo<TypedColumn<IOphalenPartnershipsResultElement>[]>(
    () => [
      {
        name: 'Ingangsdatum',
        title: 'Ingangsdatum',
      },
      {
        name: 'Einddatum',
        title: 'Einddatum',
      },
      {
        name: 'Identificatiecode',
        title: 'Identificatiecode',
      },
      {
        name: 'Toegangscode',
        title: 'Deelnamecode',
      },
      {
        name: 'Actiecode',
        title: 'Actiecode',
      },
      {
        name: 'Token',
        title: 'Token',
      },
    ],
    [],
  );
  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IOphalenPartnershipsResultElement>[]>(
    () => [
      {
        columnName: 'Ingangsdatum',
        width: 110,
      },
      {
        columnName: 'Einddatum',
        width: 110,
      },
      {
        columnName: 'Identificatiecode',
        width: 140,
      },
      {
        columnName: 'Toegangscode',
        width: 130,
      },
      {
        columnName: 'Actiecode',
        width: 160,
      },
      {
        columnName: 'Token',
        width: 150,
      },
    ],
    [],
  );

  return (
    <>
      <MenuLayout
        menu={
          <div className="d-flex align-items-center">
            <button
              className="btn btn-sm btn-light d-flex align-items-center"
              style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
              onClick={() => {
                setUrlStateSync('toevoegenDialoogState', {});
              }}
            >
              <IconToevoegen style={{ fill: Kleur.Grijs, width: 16, height: 16 }} />
              <span className="ml-2">Toevoegen</span>
            </button>
          </div>
        }
        body={
          partnerships === null ? (
            <div className="flex-fill d-flex align-items-center justify-content-center">
              <LoadingSpinner />
            </div>
          ) : (
            <AutoSizer className="d-flex flex-column flex-fill w-100 h-100">
              {({ height, width }) => (
                <GridStyleWrapper height={height}>
                  <Grid getRowId={keyExtractor} columns={kolommen} rows={partnerships}>
                    <DataTypeProvider
                      for={[nameOf<IOphalenPartnershipsResultElement>('Ingangsdatum')]}
                      formatterComponent={(formatterProps) => {
                        return <span>{format(new Date(formatterProps.value), 'dd-MM-yyyy')}</span>;
                      }}
                    />

                    <DataTypeProvider
                      for={[nameOf<IOphalenPartnershipsResultElement>('Einddatum')]}
                      formatterComponent={(formatterProps) => {
                        return (
                          <span>
                            {formatterProps.value !== null
                              ? format(new Date(formatterProps.value), 'dd-MM-yyyy')
                              : '-'}
                          </span>
                        );
                      }}
                    />

                    <DataTypeProvider
                      for={[nameOf<IOphalenPartnershipsResultElement>('Identificatiecode')]}
                      formatterComponent={(formatterProps) => {
                        return <span>{formatterProps.value}</span>;
                      }}
                    />

                    <DataTypeProvider
                      for={[nameOf<IOphalenPartnershipsResultElement>('Toegangscode')]}
                      formatterComponent={(formatterProps) => {
                        return <span>{formatterProps.value}</span>;
                      }}
                    />

                    <DataTypeProvider
                      for={[nameOf<IOphalenPartnershipsResultElement>('Actiecode')]}
                      formatterComponent={(formatterProps) => {
                        return <span>{formatterProps.value}</span>;
                      }}
                    />

                    <DataTypeProvider
                      for={[nameOf<IOphalenPartnershipsResultElement>('Token')]}
                      formatterComponent={(formatterProps) => {
                        return <span>{formatterProps.value}</span>;
                      }}
                    />

                    <SelectionState
                      selection={urlState.selectie}
                      onSelectionChange={(x) => setUrlStateSync('selectie', x as number[])}
                    />
                    <EditingState
                      onEditingRowIdsChange={(rowIds) => {
                        const id = rowIds[rowIds.length - 1] as number;
                        setUrlStateSync('wijzigenDialoogState', { id });
                      }}
                      onCommitChanges={async (changes) => {
                        if (changes.deleted === undefined) {
                          return;
                        }
                        const deleted = changes.deleted;
                        const id = deleted[deleted.length - 1] as number;
                      }}
                    />

                    <VirtualTable
                      cellComponent={CellComponent}
                      messages={{ noData: 'Geen partnerships' }}
                    />
                    <TableEditColumn
                      width={35}
                      showEditCommand
                      cellComponent={DXTableEditColumnCellComponent}
                      commandComponent={DXTableEditColumnCommandComponent}
                    />
                    <TableSelection cellComponent={DXTableCheckboxComponent} />
                    <TableColumnResizing defaultColumnWidths={kolomBreedtes} />

                    <TableHeaderRow />
                  </Grid>
                </GridStyleWrapper>
              )}
            </AutoSizer>
          )
        }
      />
      {urlState.toevoegenDialoogState !== null && (
        <MutatieDialoog
          open
          onSuccess={() => {
            ophalenPartnerships();
            setUrlStateSync('toevoegenDialoogState', null);
          }}
          onAnnuleren={() => setUrlStateSync('toevoegenDialoogState', null)}
        />
      )}
      {urlState.wijzigenDialoogState !== null && (
        <MutatieDialoog
          open
          id={urlState.wijzigenDialoogState.id}
          onSuccess={() => {
            ophalenPartnerships();
            setUrlStateSync('wijzigenDialoogState', null);
          }}
          onAnnuleren={() => setUrlStateSync('wijzigenDialoogState', null)}
        />
      )}
    </>
  );
});

export default withRouter(Partnerships);
