import React, { useCallback, useMemo } from 'react';
import IDialoogProps from '../../../../../../core/IDialoogProps';
import Dialoog from '../../../../../../components/dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import { ModalBody, ModalTitle } from 'react-bootstrap';
import { Formik, FormikActions, Field, FieldProps } from 'formik';
import ModalFooter from 'react-bootstrap/ModalFooter';
import * as Yup from 'yup';
import teksten from '../../../../../../bedrijfslogica/teksten';
import nameof from '../../../../../../core/nameOf';
import FormikVeldFout from '../../../../../../components/formulier/FormikVeldFout';
import Combobox, { IOptie } from '../../../../../../components/formulier/Combobox';
import { ISjabloonContext } from '../../../../../../../../shared/src/api/v2/Sjabloon/sjabloon';
import _ from 'lodash';
import VinkVeld from '../../../../../../components/formulier/VinkVeld';

export interface IDialoogResult {
  sjabCtxID: number;
  alias?: string;
  isOptioneel: boolean;
}

export interface IFormikValues {
  sjabCtxID: number | null;
  alias: string;
  isOptioneel: boolean;
}

interface IProps extends IDialoogProps<IDialoogResult> {
  initialValues?: IFormikValues;
  contexten: ISjabloonContext[];
}

const MuteerSjabCtxFormValueDialoog: React.FC<IProps> = (props) => {
  const initialValues = useMemo<IFormikValues>(() => {
    if (props.initialValues !== undefined) {
      return props.initialValues;
    }

    return {
      sjabCtxID: null,
      alias: '',
      isOptioneel: false,
    };
  }, [props.initialValues]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);
      const errors = await actions.validateForm(values);
      if (Object.keys(errors).length > 0) {
        actions.setSubmitting(false);
        return;
      }

      props.onSuccess({
        sjabCtxID: values.sjabCtxID!,
        alias: values.alias.trim() === '' ? undefined : values.alias.trim(),
        isOptioneel: values.isOptioneel,
      });
      actions.setSubmitting(false);
    },
    [props.onSuccess],
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        sjabCtxID: Yup.number()
          .nullable(true)
          .required(teksten.formulier.E_VERPLICHT_VELD({ veldnaam: 'Context' })),
      }),
    [],
  );

  const contextOpties = useMemo<IOptie<number>[]>(() => {
    const contexten = _.orderBy(props.contexten, ['Naam'], ['asc']);
    return contexten.map((ctx: any) => ({
      id: ctx.SjabCtxID,
      label: ctx.Naam,
    }));
  }, [props.contexten]);

  return (
    <Dialoog index={props.dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Sjabloon context</ModalTitle>
      </ModalHeader>
      <Formik<IFormikValues>
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        render={(formikProps) => {
          const { submitForm, isSubmitting } = formikProps;
          return (
            <>
              <ModalBody>
                <div className="form-group">
                  <div className="row">
                    <div className="col-12">
                      <label>Context</label>
                      <Field
                        name={nameof<IFormikValues>('sjabCtxID')}
                        render={(fieldProps: FieldProps<IFormikValues>) => {
                          const { form, field } = fieldProps;
                          return (
                            <>
                              <Combobox
                                geselecteerd={field.value}
                                opties={contextOpties}
                                options={{ legeOptieTekst: 'Geen context gekozen' }}
                                onSelectieChange={(x) => form.setFieldValue(field.name, x)}
                              />
                              <FormikVeldFout fieldProps={fieldProps} />
                            </>
                          );
                        }}
                      />
                    </div>

                    <div className="col-12 mt-2">
                      <label>Alias</label>
                      <Field
                        name={nameof<IFormikValues>('alias')}
                        render={(fieldProps: FieldProps<IFormikValues>) => {
                          const { form, field } = fieldProps;
                          return (
                            <>
                              <input type="text" className="form-control" {...field} />
                              <FormikVeldFout fieldProps={fieldProps} />
                            </>
                          );
                        }}
                      />
                    </div>

                    <div className="col-12 mt-3">
                      <Field
                        name={nameof<IFormikValues>('isOptioneel')}
                        render={(fieldProps: FieldProps<IFormikValues>) => {
                          const { form, field } = fieldProps;
                          return (
                            <>
                              <div className="d-flex align-items-center">
                                <VinkVeld
                                  aangevinkt={field.value}
                                  onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                />
                                <span className="ml-2">Optioneel</span>
                              </div>

                              <FormikVeldFout fieldProps={fieldProps} />
                            </>
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
              </ModalBody>
              <ModalFooter className="d-flex flex-row justify-content-start">
                <button
                  className="btn btn-primary"
                  onClick={submitForm}
                  style={{ width: 100 }}
                  disabled={isSubmitting}
                >
                  Ok
                </button>
                <button
                  className="btn btn-secondary"
                  onClick={props.onAnnuleren}
                  style={{ width: 100 }}
                  disabled={isSubmitting}
                >
                  Annuleren
                </button>
              </ModalFooter>
            </>
          );
        }}
      />
    </Dialoog>
  );
};

export default MuteerSjabCtxFormValueDialoog;
