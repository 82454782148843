import { makeObservable, observable } from 'mobx';
import api from '../api';
import {
  IVoipAccount,
  IVoipAccountVigerend,
  IVoipAccountVigerendIngesteldMessageData,
  IVoipAccountsVigerendVerwijderdMessageData,
} from '../../../shared/src/api/v2/telefonie';

class VoipStore {
  public voipAccounts: IVoipAccount[] | null = null;
  public voipAccountsVigerend: IVoipAccountVigerend[] | null = null;

  constructor() {
    makeObservable(this, {
      voipAccounts: observable,
      voipAccountsVigerend: observable,
    });
  }

  public async ophalenVoipAccounts() {
    const result = await api.v2.telefonie.ophalenVoipAccounts({});
    this.voipAccounts = result.voipAccounts;
  }

  public async ophalenVoipAccountsVigerend() {
    const result = await api.v2.telefonie.ophalenVoipAccountsVigerend({});
    this.voipAccountsVigerend = result.voipAccountsVigerend;
  }

  public handleVoipAccountVigerendIngesteld = async (
    messageData: IVoipAccountVigerendIngesteldMessageData,
  ) => {
    await this.ophalenVoipAccountsVigerend();
  };

  public handleVoipAccountsVigerendVerwijderd = async (
    messageData: IVoipAccountsVigerendVerwijderdMessageData,
  ) => {
    await this.ophalenVoipAccountsVigerend();
  };
}

export default VoipStore;
