import React, { useCallback, useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { useRealtimeListener } from '../realtime/RealtimeIntegratie';
import api from '../../api';
import { RootStoreContext } from '../../stores/RootStore';
import {
  IAspGebruikerGebruikersprofielIngesteldMessageData,
  IGebruikersprofielGewijzigdMessageData,
  IAspGebruikersprofiel,
} from '../../../../shared/src/api/v2/aspGebruiker';
import { EResultType } from '../../stores/CheckStore';

const ProfielVoipAccountChecker = observer(() => {
  const { gebruikerStore, voipStore, checkStore } = useContext(RootStoreContext);

  const afhandelenMogelijkGewenstVoipAccount = useCallback(
    async (profiel: IAspGebruikersprofiel) => {
      if (
        gebruikerStore.gebruiker === null ||
        // Het profiel wat is gewijzigd is niet het profiel wat huidig is ingesteld
        profiel.ID !== gebruikerStore.gebruiker.AspGebrProfielID
      ) {
        return;
      }

      const gewenstVoipAccountID = profiel.VoipAccID;
      if (gewenstVoipAccountID === null) {
        const voipAccountVigerendMiddelsGebruiker =
          voipStore.voipAccountsVigerend?.find(
            (x) => x.AspGebrID === gebruikerStore.gebruiker!.AspGebrID,
          ) ?? null;
        if (voipAccountVigerendMiddelsGebruiker !== null) {
          const voipAccount = voipStore.voipAccounts!.find(
            (x) => x.VoipAccID === voipAccountVigerendMiddelsGebruiker.VoipAccID,
          )!;
          const result = await checkStore.bevestigen({
            titel: 'VOIP-account ontkoppelen?',
            inhoud: (
              <div>
                <p>
                  Het profiel '{profiel.Naam}' wat je momenteel in gebruik hebt, heeft geen
                  VOIP-account gekoppeld.
                </p>
                <p>
                  Echter ben je momenteel wel gekoppeld aan het VOIP-account '{voipAccount.Naam}' -{' '}
                  {voipAccount.TelefoonIntern}
                </p>
                <p>Wil je deze ontkoppelen?</p>
              </div>
            ),
          });
          if (result.type === EResultType.Annuleren) {
            return;
          }

          await api.v2.telefonie.instellenVoipAccountVigerend({
            aspGebrID: gebruikerStore.gebruiker!.AspGebrID,
            voipAccountID: null,
          });
        }
        return;
      }
      const voipAccountVigerend =
        voipStore.voipAccountsVigerend?.find((x) => x.VoipAccID === gewenstVoipAccountID) ?? null;

      if (
        // Het ingestelde voip account is al vigerend en ingesteld voor deze gebruiker
        (voipAccountVigerend !== null &&
          voipAccountVigerend.AspGebrID === gebruikerStore.gebruiker.AspGebrID) ||
        // Het profiel is niet voor deze gebruiker bedoelt
        profiel.AspGebrID !== gebruikerStore.gebruiker.AspGebrID
      ) {
        return;
      }

      // Als het voip account nog niet in beslag is genomen door een ander kunnen we vragen of we deze over willen nemen.
      if (voipAccountVigerend === null) {
        const voipAccount = voipStore.voipAccounts!.find(
          (x) => x.VoipAccID === gewenstVoipAccountID,
        )!;
        const result = await checkStore.bevestigen({
          titel: 'VOIP-account instellen?',
          inhoud: (
            <div>
              <p>
                Het profiel '{profiel.Naam}' wat je momenteel in gebruik hebt, heeft het
                VOIP-account '{voipAccount.Naam}' - {voipAccount.TelefoonIntern}.
              </p>
              <p>Wil je deze instellen?</p>
            </div>
          ),
        });
        if (result.type === EResultType.Annuleren) {
          return;
        }

        await api.v2.telefonie.instellenVoipAccountVigerend({
          aspGebrID: gebruikerStore.gebruiker!.AspGebrID,
          voipAccountID: gewenstVoipAccountID,
        });
        return;
      }

      // In dit geval is het voip account is in beslag genomen door een andere gebruiker
      // en moeten we dus aan de gebruiker vragen wat de ze willen doen.

      {
        const voipAccount = voipStore.voipAccounts!.find(
          (x) => x.VoipAccID === gewenstVoipAccountID,
        )!;
        const gebruiker = gebruikerStore.gebruikers!.find(
          (x) => x.AspGebrID === voipAccountVigerend!.AspGebrID,
        )!;
        if (
          (
            await checkStore.bevestigen({
              titel: 'VOIP-account toe-eigenen?',
              inhoud: (
                <div>
                  <p>
                    Het profiel '{profiel.Naam}' wat je momenteel in gebruik hebt, heeft het
                    VOIP-account '{voipAccount.Naam}' - {voipAccount.TelefoonIntern}. Deze is echter
                    al in gebruik genomen door '{gebruiker.GebruikersNaam}'.
                  </p>
                  <p>Wil je deze toe-eigenen aan jezelf?</p>
                </div>
              ),
            })
          ).type === EResultType.Annuleren
        ) {
          return;
        }

        await api.v2.telefonie.instellenVoipAccountVigerend({
          aspGebrID: gebruikerStore.gebruiker!.AspGebrID,
          voipAccountID: gewenstVoipAccountID,
        });
      }
    },
    [voipStore.voipAccountsVigerend, voipStore.voipAccounts, gebruikerStore.gebruiker],
  );

  useRealtimeListener(async (naamEnum, data) => {
    switch (naamEnum) {
      case 'GEBRUIKERSPROFIEL_GEWIJZIGD': {
        const d = data as IGebruikersprofielGewijzigdMessageData;
        await afhandelenMogelijkGewenstVoipAccount(d.profiel);
        break;
      }
      case 'ASP_GEBRUIKER_GEBRUIKERSPROFIEL_INGESTELD': {
        const d = data as IAspGebruikerGebruikersprofielIngesteldMessageData;
        if (d.id === null) {
          return;
        }

        const profielenResult = await api.v2.aspGebruiker.ophalenGebruikersprofielen({
          filterSchema: {
            filters: [
              {
                naam: 'IDS',
                data: [d.id],
              },
            ],
          },
        });
        const profiel = profielenResult.profielen[0];
        await afhandelenMogelijkGewenstVoipAccount(profiel);
        break;
      }
    }
  });

  return null;
});

export default ProfielVoipAccountChecker;
