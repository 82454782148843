import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  ASPKolom,
  EAspKolomBreedteType,
  ESortering,
  ESorteringModus,
  IAspKolomSorteringItem,
} from '../../../../../../components/tabel/ASPTabel/types';
import MenuLayout from '../../../../../../components/MenuLayout';
import api from '../../../../../../api';
import LoadingSpinner from '../../../../../../components/Gedeeld/LoadingSpinner';
import ASPTabel from '../../../../../../components/tabel/ASPTabel';
import useUrlState from '../../../../../../core/useUrlState';
import { LeverancierContext } from '../../../..';
import { format } from 'date-fns';
import { IOphalenOpdrachtenResultElement } from '../../../../../../../../shared/src/api/v2/inkoop/opdracht';
import FilterBalkV2, {
  IFilter,
  IFilterData,
  maakFilterSchema,
} from '../../../../../../components/FilterBalkV2';
import { IFilterSchema } from '../../../../../../../../shared/src/models/filter';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../../../models/IRemoteData';
import { IOphalenDienstenResultElement } from '../../../../../../../../shared/src/api/v2/dienst/inkoop';
import FormatteerBedrag from '../../../../../../components/MutatieBedrag';

interface IProps extends RouteComponentProps {
  relID: number;
}

enum EKolom {
  Opdrachtnummer,
  Opdrachtdatum,
  Productsoort,
  AantalBesteld,
  Productmerk,
  Producttype,
  AantalGeleverd,
  Afgehandeld,
  Bedrag,
}

enum EFilter {
  Afgehandeld = 'IS_AFGEHANDELD',
}

interface IUrlState {
  selection: number[];
  expanded: number[];
  filterDatas: IFilterData<EFilter>[];
  uitgeklapt: number[];
  sortering: IAspKolomSorteringItem<EKolom>[];
}

const defaultUrlState: IUrlState = {
  selection: [],
  expanded: [],
  uitgeklapt: [],
  filterDatas: [
    {
      naam: EFilter.Afgehandeld,
      isActief: false,
      data: false,
    },
  ],
  sortering: [
    {
      key: EKolom.Opdrachtdatum,
      sortering: ESortering.Descending,
    },
  ],
};

const Inkoopopdrachten: React.FC<IProps> = (props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const [filterSchema, setFilterSchema] = useState<IFilterSchema>(
    maakFilterSchema(urlState.filterDatas),
  );

  const { relID } = useContext(LeverancierContext);

  const [inkoopopdrachten, setInkoopopdrachten] = useState<
    IOphalenOpdrachtenResultElement[] | null
  >(null);
  const [dienst, setDienst] = useState<IRemoteData<IOphalenDienstenResultElement>>(
    createPendingRemoteData(),
  );
  const ophalenDienst = useCallback(async () => {
    const dienstenResult = await api.v2.dienst.inkoop.ophalenDiensten({
      filterSchema: { filters: [{ naam: 'REL_IDS', data: [relID] }] },
    });
    setDienst(createReadyRemoteData(dienstenResult.diensten[0]));
  }, [relID]);
  useEffect(() => {
    ophalenDienst();
  }, [ophalenDienst]);

  const ophalenServiceopdrachten = useCallback(async () => {
    if (dienst.state === ERemoteDataState.Pending) {
      setInkoopopdrachten(null);
      return;
    }

    const result = await api.v2.inkoop.opdracht.ophalenOpdrachten({
      filterSchema: {
        filters: [...filterSchema.filters!, { naam: 'INKDIENST_IDS', data: [dienst.data!.ID] }],
      },
      orderSchema: {
        orders:
          // [{ naam: 'ID', richting: 'DESC' }]
          urlState.sortering.map((sortering) => {
            switch (sortering.key) {
              case EKolom.Opdrachtdatum: {
                return {
                  naam: 'DATUM_OPDRACHT',
                  richting: sortering.sortering === ESortering.Ascending ? 'ASC' : 'DESC',
                };
              }
            }
            throw new Error(`Sortering voor ${sortering.key} niet geimplementeerd`);
          }),
      },
    });

    setInkoopopdrachten(result.inkoopopdrachten);
  }, [dienst, filterSchema.filters!, urlState.sortering]);

  useEffect(() => {
    ophalenServiceopdrachten();
  }, [ophalenServiceopdrachten]);

  const opdrachtRijen = useMemo(() => {
    if (inkoopopdrachten === null) {
      return null;
    }

    const rijen = inkoopopdrachten.reduce((acc, rij, idx) => {
      return {
        ...acc,
        [idx]: rij,
      };
    }, {});

    return rijen;
  }, [inkoopopdrachten]);

  const keyExtractor = useCallback((item: IOphalenOpdrachtenResultElement) => item.InkOpdID, []);

  const kolommen = useMemo<ASPKolom<EKolom, IOphalenOpdrachtenResultElement>[]>(
    () => [
      {
        key: EKolom.Opdrachtdatum,
        label: 'Datum',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 125,
        renderer: (item: IOphalenOpdrachtenResultElement) => {
          return item.DatumOpdracht !== null
            ? format(new Date(item.DatumOpdracht), 'dd-MM-yyyy')
            : '';
        },
      },
      {
        key: EKolom.Opdrachtnummer,
        label: 'Opdr.nr',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 115,
        renderer: (item: IOphalenOpdrachtenResultElement) => {
          return item.Referentie;
        },
      },
      {
        key: EKolom.Productsoort,
        label: 'Srt.',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 90,
        renderer: (item: IOphalenOpdrachtenResultElement) => {
          return item.producttype.ProductsoortnaamKort;
        },
      },
      {
        key: EKolom.Productmerk,
        label: 'Merk',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 100,
        renderer: (item: IOphalenOpdrachtenResultElement) => {
          return item.producttype.Merknaam;
        },
      },
      {
        key: EKolom.Producttype,
        label: 'Type',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 200,
        renderer: (item: IOphalenOpdrachtenResultElement) => {
          return item.producttype.Typenaam;
        },
      },
      {
        key: EKolom.AantalBesteld,
        label: 'Aantal',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 90,
        renderer: (item: IOphalenOpdrachtenResultElement) => {
          return item.Aantal;
        },
      },
      {
        key: EKolom.AantalGeleverd,
        label: 'Gelev.',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 85,
        renderer: (item: IOphalenOpdrachtenResultElement) => {
          return item.AantalGeleverdeProducten;
        },
      },
      {
        key: EKolom.Bedrag,
        label: 'Bedrag (Ex)',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 115,
        renderer: (item: IOphalenOpdrachtenResultElement) => {
          return item.InkoopPrijs !== null ? (
            <FormatteerBedrag bedrag={item.Aantal * item.InkoopPrijs} />
          ) : (
            <span></span>
          );
        },
      },
      {
        key: EKolom.Afgehandeld,
        label: 'Afgeh.',
        breedteType: EAspKolomBreedteType.Flex,
        flex: 1,
        renderer: (item: IOphalenOpdrachtenResultElement) => {
          return item.DatumAfgehandeld !== null ? 'Ja' : 'Nee';
        },
      },
    ],
    [],
  );

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.Afgehandeld,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return <span>Alleen niet-afgehandeld</span>;
        },
      },
    ],
    [],
  );

  return (
    <>
      <MenuLayout
        menu={
          <div>
            <FilterBalkV2
              filters={filters}
              filterData={urlState.filterDatas}
              onFilterDataChange={(datas) => setUrlStateSync('filterDatas', datas)}
              onFilterSchemaChange={(schema) => setFilterSchema(schema)}
            />
          </div>
        }
        body={
          <div className="d-flex flex-column flex-fill">
            {opdrachtRijen === null ? (
              <div className="flex-fill d-flex align-items-center justify-content-center">
                <LoadingSpinner />
              </div>
            ) : (
              <div className="d-flex flex-column flex-fill">
                <ASPTabel
                  rijen={opdrachtRijen}
                  kolommen={kolommen}
                  keyExtractor={keyExtractor}
                  // selectie={urlState.selectie}
                  // onSelectieChange={(selectie) => setUrlStateSync('selectie', selectie)}
                  sortering={urlState.sortering}
                  onSorteringChange={(sortering) => setUrlStateSync('sortering', sortering)}
                  // onWijzigenRij={async (x) => {}}
                  // onVerwijderenRij={async (x) => {
                  //   await new Promise((resolve) => setTimeout(resolve, 3000));
                  // }}
                  sorteringOpties={{
                    modus: ESorteringModus.Whitelist,
                    kolomOpties: {
                      [EKolom.Opdrachtdatum]: {
                        magSorteren: true,
                      },
                    },
                  }}
                />
              </div>
            )}
          </div>
        }
      />
    </>
  );
};

export default withRouter(Inkoopopdrachten);
