import api from '../../index';
import {
  IOphalenOnderwijsinstellingenParams,
  IOphalenOnderwijsinstellingenResult,
} from '../../../../../shared/src/api/v2/onderwijsinstelling';
import ICheckData from '../../../../../shared/src/models/ICheckData';

const onderwijsinstelling = {
  ophalenOnderwijsinstellingen: async (
    params: IOphalenOnderwijsinstellingenParams,
  ): Promise<IOphalenOnderwijsinstellingenResult> => {
    return await api.post('v2/onderwijsinstelling/ophalen-onderwijsinstellingen', params);
  },
};

export default onderwijsinstelling;
