import React, { useCallback, useMemo } from 'react';
import Dialoog from '../../../../components/dialogen/Dialoog';
import IDialoogProps from '../../../../core/IDialoogProps';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import { Field, FieldProps, Formik, FormikActions, FormikErrors } from 'formik';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import nameof from '../../../../core/nameOf';
import FormikVeldFout from '../../../../components/formulier/FormikVeldFout';
import ProducttypeSelectie from '../../../../components/formulier/ProducttypeSelectie';
import api from '../../../../api';
import { IFilterSchemaFilter } from '../../../../../../shared/src/models/filter';
import teksten from '../../../../bedrijfslogica/teksten';

interface IDialoogResult {}

interface IProps extends IDialoogProps<IDialoogResult> {
  aspGebrID: number;
}

interface IFormikValues {
  wachtwoord: string | null;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  wachtwoord: 'Wachtwoord',
};

const InstellenWachtwoordDialoog: React.FC<IProps> = (props) => {
  const initialValues = useMemo<IFormikValues>(() => {
    return {
      wachtwoord: null,
    };
  }, []);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      await api.v2.aspGebruiker.instellenWachtwoord({
        aspGebrID: props.aspGebrID,
        wachtwoord: values.wachtwoord!,
      });

      props.onSuccess({});
    },
    [],
  );

  const validate = useCallback((values: IFormikValues): FormikErrors<IFormikValues> => {
    const errors: FormikErrors<IFormikValues> = {};

    if (values.wachtwoord === null) {
      errors.wachtwoord = teksten.formulier.E_VERPLICHT_VELD({ veldnaam: 'Wachtwoord' });
    }
    if (values.wachtwoord !== null && values.wachtwoord.length < 10) {
      errors.wachtwoord = teksten.formulier.E_MINIMAAL_AANTAL_KARAKTERS_BEVATTEN({
        veldnaam: 'Wachtwoord',
        aantal: 10,
      });
    }

    return errors;
  }, []);

  return (
    <Dialoog index={props.dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Wachtwoord instellen</ModalTitle>
      </ModalHeader>
      <Formik<IFormikValues>
        onSubmit={handleSubmit}
        validate={validate}
        initialValues={initialValues}
        render={(formikProps) => {
          const { submitForm, isSubmitting } = formikProps;
          return (
            <>
              <ModalBody>
                <div className="col-12 mt-2">
                  <label>{veldnamen.wachtwoord}</label>
                  <Field
                    name={nameof<IFormikValues>('wachtwoord')}
                    render={(fieldProps: FieldProps<IFormikValues>) => {
                      const { field, form } = fieldProps;
                      return (
                        <div>
                          <input type="password" className="form-control" {...field} />
                          <FormikVeldFout fieldProps={fieldProps} />
                        </div>
                      );
                    }}
                  />
                </div>
              </ModalBody>
              <ModalFooter className="d-flex flex-row justify-content-start">
                <button
                  className="btn btn-primary d-flex align-items-center justify-content-center"
                  onClick={submitForm}
                  style={{ width: 100 }}
                  disabled={isSubmitting}
                >
                  Ok
                </button>
                <button
                  className="btn btn-secondary"
                  onClick={props.onAnnuleren}
                  style={{ width: 100 }}
                  disabled={isSubmitting}
                >
                  Annuleren
                </button>
              </ModalFooter>
            </>
          );
        }}
      />
    </Dialoog>
  );
};

export default InstellenWachtwoordDialoog;
