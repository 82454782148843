import React, { useMemo } from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';
import IRemoteData, { ERemoteDataState } from '../../../../../../models/IRemoteData';
import Skeleton from 'react-loading-skeleton';
import Avatar from '../../../../../personalia/Avatar';

const Root = styled.div`
  margin-top: 2px;
  display: flex;
  align-items: center;
`;

const GeenOnderwerpOpgegeven = styled.span`
  flex: 1;
  font-size: 14px;
  font-style: italic;
`;

const OnderwerpTekst = styled.span`
  flex: 1;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #3a3a3a;
`;

const DatumTekst = styled.span`
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 16px;
`;

interface IProps {
  onderwerp: IRemoteData<string | null>;
  datum: IRemoteData<Date>;
}

const Onderwerp = (props: IProps) => {
  const onderwerp = useMemo(() => {
    if (props.onderwerp.state === ERemoteDataState.Pending) {
      return <Skeleton width={280} style={{ flex: 1 }} />;
    }
    if (props.onderwerp.data === null) {
      return <GeenOnderwerpOpgegeven>Geen onderwerp opgegeven</GeenOnderwerpOpgegeven>;
    }
    return <OnderwerpTekst>{props.onderwerp.data}</OnderwerpTekst>;
  }, [props.onderwerp]);

  const datum = useMemo(() => {
    if (props.datum.state === ERemoteDataState.Pending) {
      return <Skeleton width={60} style={{ marginLeft: 16 }} />;
    }
    const datum = format(props.datum.data!, 'dd-MM-yyyy');
    return <DatumTekst>{datum}</DatumTekst>;
  }, [props.datum]);

  return (
    <Root>
      {onderwerp}
      {datum}
    </Root>
  );
};

export default Onderwerp;
