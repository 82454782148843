import React, { useCallback, useMemo, useState } from 'react';
import IDialoogProps from '../../../core/IDialoogProps';
import Dialoog from '../Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import MultiComboboxV2, {
  EnkeleProvider,
  IRepresentatieProps,
  Provider,
} from '../../formulier/MultiComboboxV2';
import { IOphalenProductmodellenResultElement } from '../../../../../shared/src/api/v2/aanbod/productmodel';
import api from '../../../api';
import { ASPKolom, EAspKolomBreedteType } from '../../tabel/ASPTabel/types';

export type Key = 'naam' | 'code' | 'categorie' | 'kenmerk';

export interface IProductmodelSelectieDialoogOutput {
  prodModID: number;
}

interface IProps extends IDialoogProps<IProductmodelSelectieDialoogOutput> {
  provider: Provider<Key, IOphalenProductmodellenResultElement, null>;
}

const ProductmodelSelectieDialoog = (props: IProps): JSX.Element => {
  const [prodModID, setProdModID] = useState<number | null>(null);

  const keyExtractor = useCallback(
    (model: IOphalenProductmodellenResultElement) => model.ProdModID,
    [],
  );

  const enkeleProvider = useCallback<EnkeleProvider<number, IOphalenProductmodellenResultElement>>(
    async (id) => {
      const result = await api.v2.aanbod.productmodel.ophalenProductmodellen({
        filterSchema: {
          filters: [
            {
              naam: 'IDS',
              data: [id],
            },
          ],
        },
      });

      return result.modellen[0];
    },
    [],
  );

  const representatieComponent = useCallback(
    (p: IRepresentatieProps<IOphalenProductmodellenResultElement>) => (
      <span>{p.entiteit.Modelcode}</span>
    ),
    [],
  );

  const kolommen = useMemo<ASPKolom<Key, IOphalenProductmodellenResultElement>[]>(
    () => [
      {
        key: 'code',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 150,
        label: 'Code',
        renderer: (model) => model.Modelcode,
      },
      {
        key: 'naam',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 250,
        label: 'Naam',
        renderer: (model) => model.Modelnaam,
      },
      {
        key: 'categorie',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 65,
        label: 'Cat.',
        renderer: (model) => model.productsoort.NaamKort,
      },
      {
        key: 'kenmerk',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 250,
        label: 'Kenmerk',
        renderer: (model) => model.Kenmerk,
      },
    ],
    [],
  );

  return (
    <Dialoog index={props.dialoogIndex ?? 0}>
      <ModalHeader>Selecteer productmodel</ModalHeader>
      <ModalBody>
        <MultiComboboxV2<number, Key, IOphalenProductmodellenResultElement, null>
          provider={props.provider}
          enkeleProvider={enkeleProvider}
          keyExtractor={keyExtractor}
          waarde={prodModID}
          onChange={setProdModID}
          representatieComponent={representatieComponent}
          kolommen={kolommen}
        />
      </ModalBody>
      <ModalFooter className="d-flex flex-row justify-content-start">
        <button
          className="btn btn-primary d-flex align-items-center justify-content-center"
          onClick={() => props.onSuccess({ prodModID: prodModID! })}
          style={{ width: 100 }}
          disabled={prodModID === null}
        >
          Ok
        </button>
        <button className="btn btn-secondary" onClick={props.onAnnuleren} style={{ width: 100 }}>
          Annuleren
        </button>
      </ModalFooter>
    </Dialoog>
  );
};

export default ProductmodelSelectieDialoog;
