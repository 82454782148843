import api from '../..';
import {
  IKoppelenProductParams,
  IKoppelenProductResult,
  IOntkoppelenProductenParams,
  IOntkoppelenProductenResult,
  IWijzigenProductParams,
  IWijzigenProductResult,
} from '../../../../../shared/src/api/v2/contract/product';
import ICheckData from '../../../../../shared/src/models/ICheckData';

const product = {
  checkWijzigenProduct: async (params: IWijzigenProductParams): Promise<ICheckData> => {
    return await api.post('/v2/contract/product/check-wijzigen-product', params);
  },
  wijzigenProduct: async (params: IWijzigenProductParams): Promise<IWijzigenProductResult> => {
    return await api.post('/v2/contract/product/wijzigen-product', params);
  },
  checkKoppelenProduct: async (params: IKoppelenProductParams): Promise<ICheckData> => {
    return await api.post('/v2/contract/product/check-koppelen-product', params);
  },
  koppelenProduct: async (params: IKoppelenProductParams): Promise<IKoppelenProductResult> => {
    return await api.post('/v2/contract/product/koppelen-product', params);
  },
  checkOntkoppelenProducten: async (params: IOntkoppelenProductenParams): Promise<ICheckData> => {
    return await api.post('/v2/contract/product/check-ontkoppelen-producten', params);
  },
  ontkoppelenProducten: async (
    params: IOntkoppelenProductenParams,
  ): Promise<IOntkoppelenProductenResult> => {
    return await api.post('/v2/contract/product/ontkoppelen-producten', params);
  },
};

export default product;
