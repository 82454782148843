import { useContext, useMemo } from 'react';
import { RootStoreContext } from '../../../stores/RootStore';
import { ERemoteDataState } from '../../../models/IRemoteData';
import { ETelefooneventStatus } from '../../../bedrijfslogica/enums';
import { IOproep } from '../../../../../shared/src/api/v2/telefonie';

export interface IUseInkomendeOfRinkelendeOproepenParams {}

export interface IUseInkomendeOfRinkelendeOproepenOutput {
  oproepen: IOproep[];
}

const useInkomendeOfRinkelendeOproepen = (
  params?: IUseInkomendeOfRinkelendeOproepenParams,
): IUseInkomendeOfRinkelendeOproepenOutput | null => {
  const { telefoonStore } = useContext(RootStoreContext);

  return useMemo(() => {
    if (telefoonStore.actieveOproepen.state === ERemoteDataState.Pending) {
      return null;
    }
    const oproepen = telefoonStore.actieveOproepen.data!;
    const inkomendeOfRinkelendeOproepen = oproepen.filter((oproep) => {
      // Waarvan het laatste event rinkelend of inkomend is
      const laatsteEvent = oproep.events[oproep.events.length - 1];
      if (laatsteEvent === undefined) {
        return false;
      }
      return (
        oproep.Richting === 'I' &&
        (laatsteEvent.Status === ETelefooneventStatus.Inkomend ||
          laatsteEvent.Status === ETelefooneventStatus.Rinkelt)
      );
    });

    if (inkomendeOfRinkelendeOproepen.length === 0) {
      return null;
    }

    return {
      oproepen: inkomendeOfRinkelendeOproepen,
    };
  }, [telefoonStore.actieveOproepen]);
};

export default useInkomendeOfRinkelendeOproepen;
