import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  IOphalenAfdelingenResult,
  IOphalenAfdelingenResultElement,
} from '../../../../../../shared/src/api/v2/relatie/afdeling';
import api from '../../../../api';
import HorizontaleScheidingslijn from '../../../layout/HorizontaleScheidingslijn';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import TelefoonComponent from '../../../communicatie/TelefoonComponent';
import EmailComponent from '../../../communicatie/EmailComponent';
import { ASPKolom, EAspKolomBreedteType } from '../../../tabel/ASPTabel/types';
import ASPTabel from '../../../tabel/ASPTabel';
import { IOphalenRelatiesResultElementV2 } from '../../../../../../shared/src/api/v2/relatie';
import { IHeaderProps } from '../../../tabel/ASPTabel/Header';
import TableData, { ITableDataProps } from '../../../tabel/ASPTabel/Body/TableData';
import { Card } from 'react-bootstrap';

enum EKolom {
  Naam,
  Telefoonnummer,
  Email,
}

interface IProps {
  orgID: number;
  relatie: IOphalenRelatiesResultElementV2;
}

const OrganisatieUitbreiding: React.FC<IProps> = (props) => {
  const [afdelingenResult, setAfdelingenResult] = useState<IOphalenAfdelingenResult | null>(null);

  const ophalenAfdelingen = useCallback(async () => {
    const result = await api.v2.relatie.afdeling.ophalenAfdelingen({
      filterSchema: {
        filters: [
          {
            naam: 'ORG_IDS',
            data: [props.orgID],
          },
        ],
      },
    });

    setAfdelingenResult(result);
  }, [props.orgID]);
  useEffect(() => {
    ophalenAfdelingen();
  }, [ophalenAfdelingen]);

  const keyExtractor = useCallback((row: IOphalenAfdelingenResultElement) => row.ID, []);

  const kolommen = useMemo<ASPKolom<EKolom, IOphalenAfdelingenResultElement>[]>(
    () => [
      {
        key: EKolom.Naam,
        label: 'Naam',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 145,
        renderer: (row: IOphalenAfdelingenResultElement) => row.Naam,
      },
      {
        key: EKolom.Telefoonnummer,
        label: 'Telefoonnummer',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 100,
        renderer: (row: IOphalenAfdelingenResultElement) =>
          row.Telefoon === null ? null : (
            <TelefoonComponent
              telefoonNummer={row.Telefoon}
              options={{ icoonWeergeven: false }}
              orgID={props.relatie.organisatie?.OrgID}
              persID={props.relatie.persoon?.PersID}
            />
          ),
      },
      {
        key: EKolom.Email,
        label: 'Email',
        breedteType: EAspKolomBreedteType.Flex,
        flex: 1,
        renderer: (row: IOphalenAfdelingenResultElement) =>
          row.Email === null ? null : (
            <EmailComponent
              emailAdres={row.Email}
              options={{ icoonWeergeven: false }}
              orgID={props.relatie.organisatie?.OrgID}
              persID={props.relatie.persoon?.PersID}
            />
          ),
      },
    ],
    [props.relatie],
  );

  const rijen = useMemo<Record<number, IOphalenAfdelingenResultElement>>(() => {
    return afdelingenResult === null
      ? {}
      : afdelingenResult.afdelingen.reduce((acc, curr, i) => {
          return {
            ...acc,
            [i]: curr,
          };
        }, {});
  }, [afdelingenResult]);

  const HeaderComponent = useCallback((headerProps: IHeaderProps) => null, []);

  const hoogsteRijIndex = useMemo<number>(
    () =>
      Object.keys(rijen).reduce((currHighest, key) => {
        const idx = Number(key);
        return Math.max(idx, currHighest);
      }, 0),
    [rijen],
  );

  const TdComponent = useCallback(
    (tdProps: ITableDataProps<EKolom, IOphalenAfdelingenResultElement>) => (
      <TableData
        {...tdProps}
        style={{ borderBottom: tdProps.rowIndex === hoogsteRijIndex ? 0 : undefined }}
      />
    ),
    [hoogsteRijIndex],
  );

  if (afdelingenResult === null || afdelingenResult.afdelingen.length === 0) {
    return null;
  }

  return (
    <div className="d-flex flex-column">
      <HorizontaleScheidingslijn color={Kleur.HeelLichtGrijs} />
      <div>
        <h6 className="pt-2 pl-3 pr-3 font-weight-bold" style={{ marginBottom: 0 }}>
          Afdelingen
        </h6>
        {/*<div style={{ height: Object.keys(rijen).length * 30 }} className="w-100 d-flex flex-fill">*/}
        {/*  <ASPTabel*/}
        {/*    rijen={rijen}*/}
        {/*    kolommen={kolommen}*/}
        {/*    keyExtractor={keyExtractor}*/}
        {/*    rijHoogte={30}*/}
        {/*    headerComponent={HeaderComponent}*/}
        {/*    headerHoogte={0}*/}
        {/*    tdComponent={TdComponent}*/}
        {/*  />*/}
        {/*</div>*/}
        {Object.values(rijen).map((afdeling, i, arr) => {
          return (
            <div
              key={afdeling.ID}
              className="p-2 pl-3 pr-3"
              style={{
                borderBottom: i !== arr.length - 1 ? `1px solid ${Kleur.LichtGrijs}` : undefined,
              }}
            >
              <span>{afdeling.Naam}</span>
              {afdeling.Telefoon !== null || afdeling.Email !== null ? (
                <div className="d-flex align-items-center justify-content-between">
                  <div>
                    {afdeling.Email !== null && <EmailComponent emailAdres={afdeling.Email} />}
                  </div>
                  <div>
                    {afdeling.Telefoon !== null && (
                      <TelefoonComponent telefoonNummer={afdeling.Telefoon} />
                    )}
                  </div>
                </div>
              ) : (
                <div>
                  <span className="text-muted font-italic">Geen contactgegevens bekend</span>
                </div>
              )}
            </div>
          );
        })}
      </div>
      {/*<HorizontaleScheidingslijn color={Kleur.HeelLichtGrijs} />*/}
    </div>
  );
};

export default OrganisatieUitbreiding;
