import React, { useState } from 'react';
import { IFilterSchemaFilter } from '../../../../../shared/src/models/filter';
import { IOphalenRelatiesResultElementV2 } from '../../../../../shared/src/api/v2/relatie';
import RelatieSelectie, { IInitieleRelatiesProvider, IRow } from '../RelatieSelectie';
import { IconZoeken } from '../../Icons';
import { Kleur } from '../../../bedrijfslogica/constanten';
import RelatieSelectieDialoog from '../../personalia/RelatieSelectieDialoog';

interface IProps {
  relID: number | null;
  filters?: IFilterSchemaFilter[];
  onChange: (relID: number | null) => void;
  onRelatieInformatieGedeeld?: (relatie: IOphalenRelatiesResultElementV2 | null) => void;
  isWisbaar?: boolean;
  disabled?: boolean;
  options?: IOptions;
  initieleRelatiesProvider?: IInitieleRelatiesProvider;
}

interface IOptions {
  bijEnkeleDirectVoorselecteren?: boolean;
  geenWaardeBericht?: JSX.Element | string;
  representatieFabriek?: (row: IRow) => JSX.Element | string | null;
}

interface ISelectieDialoogState {}

const RelatieSelectieUitgebreid: React.FC<IProps> = (props) => {
  const [selectieDialoogState, setSelectieDialoogState] = useState<ISelectieDialoogState | null>(
    null,
  );

  return (
    <>
      <div className="d-flex flex-fill">
        <RelatieSelectie
          relID={props.relID}
          onChange={props.onChange}
          onRelatieInformatieGedeeld={props.onRelatieInformatieGedeeld}
          isWisbaar={props.isWisbaar}
          disabled={props.disabled}
          options={props.options}
          filters={props.filters}
          initieleRelatiesProvider={props.initieleRelatiesProvider}
        />
        <button
          style={{
            // border: 0,
            background: 0,
            outline: 0, //
            border: `1px solid ${Kleur.LichtGrijs}`,
            borderTopRightRadius: 5,
            borderBottomRightRadius: 5,
            borderLeft: 0,
            width: 25,
            backgroundColor: props.disabled ? 'rgb(197, 197, 197)' : Kleur.HeelLichtGrijs,
          }}
          className="d-flex align-items-center justify-content-center"
          onClick={() => setSelectieDialoogState({})}
        >
          <IconZoeken
            style={{ fill: Kleur.Grijs, width: 16, height: 16, position: 'relative', top: 1 }}
            className="flex-fill"
          />
        </button>
      </div>
      {selectieDialoogState !== null && (
        <RelatieSelectieDialoog
          open
          onSuccess={(result) => {
            setSelectieDialoogState(null);

            props.onChange(result.relID);
            if (props.onRelatieInformatieGedeeld !== undefined) {
              props.onRelatieInformatieGedeeld(result.relatie);
            }
          }}
          onAnnuleren={() => setSelectieDialoogState(null)}
          koppelOpties={{
            relatieFilterSchema:
              props.filters !== undefined
                ? {
                    filters: props.filters,
                  }
                : undefined,
          }}
        />
      )}
    </>
  );
};

export default RelatieSelectieUitgebreid;
