import * as React from 'react';
import { useRef, useState } from 'react';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import { IconZoeken, IconKruis } from '../../../Icons';
import styled from 'styled-components';

const StyledRoot = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  position: relative;
  background-color: rgba(200, 200, 200, 0.3);
  border-radius: 3px;
  border: 3px solid transparent;
  transition: border 0.2s ease-in-out;

  &.focused {
    border: 3px solid ${Kleur.SelectieBlauw};
  }
`;

const StyledInput = styled.input`
  background: transparent;
  border: 0;
  width: 100%;
  height: 30px;
  margin-left: 3px;
  color: ${Kleur.Zwart};
  outline: 0;
  flex: 1;

  &::placeholder {
    color: ${Kleur.Grijs};
  }
`;

interface IProps {
  waarde: string;
  onChange: (waarde: string) => void;
  disabled?: boolean;
}

const Zoekveld = (props: IProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [hasFocus, setHasFocus] = useState(false);

  return (
    <StyledRoot className={hasFocus ? 'focused' : ''}>
      <div
        className="d-flex flex-column justify-content-center align-items-center"
        style={{
          height: '100%',
          marginLeft: 8,
        }}
      >
        <IconZoeken
          style={{
            fill: Kleur.Grijs,
            width: 18,
            height: 18,
          }}
        />
      </div>
      <StyledInput
        ref={inputRef}
        value={props.waarde}
        onChange={(ev) => props.onChange(ev.target.value)}
        onKeyUp={async (ev) => {
          if (ev.key === 'Enter') {
            // await performSearch();
          }
        }}
        placeholder="Typ hier om te zoeken binnen de inhoud van een sjabloon"
        onFocus={() => setHasFocus(true)}
        onBlur={() => setHasFocus(false)}
        disabled={props.disabled}
      />
      {props.waarde.length !== 0 && (
        <button
          className="d-flex justify-content-center align-items-center"
          style={{
            position: 'absolute',
            right: 0,
            outline: 0,
            border: 0,
            background: 0,
            height: '100%',
          }}
          onClick={() => props.onChange('')}
        >
          <IconKruis
            style={{
              fill: Kleur.Wit,
              height: 18,
              width: 18,
              position: 'relative',
              top: 1,
            }}
          />
        </button>
      )}
    </StyledRoot>
  );
};

export default Zoekveld;
