import {
  IOphalenDashboardFinancieleInfoParams,
  IOphalenDashboardFinancieleInfoResult,
  IOphalenDashboardInfoParams,
  IOphalenDashboardInfoResult,
  IOphalenDashboardServicedienstInfoParams,
  IOphalenDashboardServicedienstInfoResult,
  IOphalenDashboardTransportInfoParams,
  IOphalenDashboardTransportInfoResult,
  IOphalenStandVanZakenParams,
  IOphalenStandVanZakenResult,
} from '../../../../../shared/src/api/v2/dashboard';
import api from '../../index';

const dashboard = {
  ophalenDashboardInfo: async (
    params: IOphalenDashboardInfoParams,
  ): Promise<IOphalenDashboardInfoResult> => {
    return await api.post('/v2/dashboard/ophalen-dashboard-info', params);
  },
  ophalenTransportInfo: async (
    params: IOphalenDashboardTransportInfoParams,
  ): Promise<IOphalenDashboardTransportInfoResult> => {
    return await api.post('/v2/dashboard/ophalen-transport-info', params);
  },
  ophalenFinancieleInfo: async (
    params: IOphalenDashboardFinancieleInfoParams,
  ): Promise<IOphalenDashboardFinancieleInfoResult> => {
    return await api.post('/v2/dashboard/ophalen-financiele-info', params);
  },
  ophalenStandVanZaken: async (
    params: IOphalenStandVanZakenParams,
  ): Promise<IOphalenStandVanZakenResult> => {
    return await api.post('/v2/dashboard/ophalen-stand-van-zaken', params);
  },
  ophalenServicedienstInfo: async (
    params: IOphalenDashboardServicedienstInfoParams,
  ): Promise<IOphalenDashboardServicedienstInfoResult> => {
    return await api.post('/v2/dashboard/ophalen-servicedienst-info', params);
  },
};

export default dashboard;
