import React from 'react';
import TableData from '../TableData';
import { IconWijzigen } from '../../../../Icons';
import { Kleur } from '../../../../../bedrijfslogica/constanten';

export interface IWijzigenTableDataProps<TRow> {
  row?: TRow;
  isGefocust?: boolean;
  isGehovered?: boolean;
  heeftUitgeklapteRijOnderZich: boolean;
  onWijzigenRij: () => void;
  rowIndex: number;
  style?: React.CSSProperties;
}

const StandaardWijzigenTableData = <TRow extends any>(props: IWijzigenTableDataProps<TRow>) => {
  return (
    <TableData
      tableKey={null}
      heeftUitgeklapteRijOnderZich={props.heeftUitgeklapteRijOnderZich}
      style={{ padding: 0, display: 'flex', ...props.style }}
      isGehovered={props.isGehovered}
      isGefocust={props.isGefocust}
      rowIndex={props.rowIndex}
    >
      <button
        title="Wijzigen"
        style={{
          background: 0,
          outline: 0,
          border: 0,
        }}
        onClick={(ev) => {
          ev.preventDefault();
          props.onWijzigenRij();
        }}
      >
        <IconWijzigen
          style={{
            fill: 'rgb(168, 168, 168)',
            width: 16,
            height: 16,
            position: 'relative',
            bottom: 1,
          }}
        />
      </button>
    </TableData>
  );
};

export default StandaardWijzigenTableData;
