import React, { useMemo, useRef, useState } from 'react';
import { IconUitklappen } from '../../Icons';
import { Kleur } from '../../../bedrijfslogica/constanten';
import Overlay from 'react-bootstrap/Overlay';
import { getEnumKeys } from '../../../helpers/enum';
import UitlegTooltip from '../UitlegTooltip';

export enum ETekstPrecisie {
  Exact = 'EXACT',
  Ongeveer = 'ONGEVEER',
  StartMet = 'START_MET',
  EindigtMet = 'EINDIGT_MET',
  Wildcard = 'WILDCARD',
  // Fuzzy = 'FUZZY',
}

const teksten: Record<ETekstPrecisie, string> = {
  [ETekstPrecisie.Exact]: 'Exact',
  [ETekstPrecisie.Ongeveer]: 'Ongeveer',
  [ETekstPrecisie.StartMet]: 'Start met',
  [ETekstPrecisie.EindigtMet]: 'Eindigt met',
  // [ETekstPrecisie.Fuzzy]: 'Fuzzy',
  [ETekstPrecisie.Wildcard]: 'Wildcard',
};

const uitlegTeksten: Record<ETekstPrecisie, string> = {
  [ETekstPrecisie.Exact]: 'Tekst moet exact overeen komen',
  [ETekstPrecisie.Ongeveer]: 'Tekst begint met of eindigt met',
  [ETekstPrecisie.StartMet]: 'Tekst start met',
  [ETekstPrecisie.EindigtMet]: 'Tekst eindigt met',
  // [ETekstPrecisie.Fuzzy]: 'Fuzzy',
  [ETekstPrecisie.Wildcard]: 'Tekst kan wildcards bevatten d.m.v. %',
};

interface IProps {
  precisie: ETekstPrecisie;
  onPrecisieChange: (precisie: ETekstPrecisie) => void;
}

const TekstPrecisieSelectie: React.FC<IProps> = (props) => {
  const { precisie, onPrecisieChange } = props;
  const rootRef = useRef<HTMLAnchorElement>(null);
  const [selectieOpen, setSelectieOpen] = useState(false);

  const enumKeys = getEnumKeys(ETekstPrecisie);

  return (
    <>
      <a
        ref={rootRef}
        href="#"
        style={{ textDecoration: 'none' }}
        onClick={() => setSelectieOpen(true)}
      >
        <span style={{ fontSize: 11 }}>{teksten[precisie]}</span>
        <span>
          <IconUitklappen style={{ fill: Kleur.SelectieBlauw, width: 15, height: 15 }} />
        </span>
      </a>
      <Overlay
        target={rootRef.current!}
        onHide={() => setSelectieOpen(false)}
        show={selectieOpen}
        placement="bottom-start"
        rootClose
      >
        {({ ...overlayProps }) => (
          <div
            {...overlayProps}
            style={{
              ...overlayProps.style,
              background: Kleur.Wit,
              zIndex: 2000,
              border: `1px solid ${Kleur.LichtGrijs}`,
            }}
            className="p-2"
          >
            <div className="d-flex flex-column">
              {enumKeys.map((key, i) => {
                const value = ETekstPrecisie[key];
                return (
                  <>
                    {/*// <UitlegTooltip inhoud={value}>*/}
                    <a
                      key={key}
                      href="#"
                      style={{ fontSize: 11, textDecoration: 'none', color: Kleur.DonkerGrijs }}
                      onClick={() => {
                        onPrecisieChange(value);
                        setSelectieOpen(false);
                      }}
                    >
                      {/*<IconExact style={{ fill: Kleur.SelectieBlauw, width: 15, height: 15 }} />*/}
                      <span className="ml-1">{teksten[value]}</span>
                    </a>
                    {/*// </UitlegTooltip>*/}
                    {i !== enumKeys.length - 1 && <span className="mb-2" />}
                  </>
                );
              })}
            </div>
          </div>
        )}
      </Overlay>
    </>
  );
};

export default TekstPrecisieSelectie;
