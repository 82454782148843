import React, { useCallback, useContext, useState, useMemo } from 'react';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalHeader from 'react-bootstrap/ModalHeader';
import IDialoogProps from '../../../../../../../../core/IDialoogProps';
import { observer } from 'mobx-react-lite';
import VinkVeld from '../../../../../../../../components/formulier/VinkVeld';
import { RootStoreContext } from '../../../../../../../../stores/RootStore';
import { EResultType } from '../../../../../../../../stores/CheckStore';
import Dialoog from '../../../../../../../../components/dialogen/Dialoog';
import api from '../../../../../../../../api';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
// import PersoonSelectie from '../../../../../../../../components/formulier/PersoonSelectie';
import { IOphalenContractenResultElement } from '../../../../../../../../../../shared/src/api/v2/contract';
import { dagDatum } from '../../../../../../../../helpers/datum';
import DatumKiezer from '../../../../../../../../components/formulier/DatumKiezer';
import nameof from '../../../../../../../../core/nameOf';
import FormikVeldFout from '../../../../../../../../components/formulier/FormikVeldFout';

interface IDialoogResult {}

interface IProps extends IDialoogProps<IDialoogResult> {
  relID: number;
  persID: number | null;
  verkVrstIDs: number[];
}

interface IFormikValues {
  bevestigingVersturen: boolean;
  persID: number | null;
  // verkoopdatum: Date | string;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  bevestigingVersturen: 'Bevestiging sturen',
  persID: 'Naar persoon:',
  // verkoopdatum: 'Verkoopdatum',
};

const VerwerkenDialoog: React.FC<IProps> = observer((props) => {
  const { dialoogIndex, onAnnuleren, onSuccess, open, verkVrstIDs, relID } = props;
  const { checkStore } = useContext(RootStoreContext);
  const [persID, setPersID] = useState<number | null>(props.persID);

  const initialValues = useMemo<IFormikValues>(() => {
    return {
      bevestigingVersturen: true,
      persID,
      verkoopdatum: dagDatum(new Date()),
    };
  }, [persID]);

  const handleOk = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      const params = { relID, ids: verkVrstIDs };

      // Onderstaande check wordt al bij de selectie gedaan

      // const checkData = await api.v2.product.verkoop.checkVerwerkenVerkoopVoorstellen(params);
      // const controleResult = await checkStore.controleren({
      //   checkData,
      // });
      // if (controleResult.type === EResultType.Annuleren) {
      //   actions.setSubmitting(false);
      //   return;
      // }

      if (
        (
          await checkStore.bevestigen({
            inhoud: (
              <span>
                Wil je de geselecteerde verkoopvoorstellen verwerken?
                <br />
                Hiermee worden de gekoppelde contracten per direct beeindigd
              </span>
            ),
          })
        ).type === EResultType.Annuleren
      ) {
        actions.setSubmitting(false);
        return;
      }

      await api.v2.product.verkoop.verwerkenVerkoopVoorstellen(params);

      if (values.bevestigingVersturen) {
        await api.v2.product.verkoop.versturenBevestigingVerkoopvoorstellen({ ids: verkVrstIDs });
      }

      onSuccess({});
      actions.setSubmitting(false);
    },
    [relID, verkVrstIDs],
  );

  return (
    <Dialoog index={dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Verwerken verkoopvoorstellen</ModalTitle>
      </ModalHeader>

      <Formik<IFormikValues>
        isInitialValid
        onSubmit={handleOk}
        initialValues={initialValues}
        render={(FormikProps) => {
          const { isSubmitting, submitForm } = FormikProps;
          return (
            <>
              <ModalBody>
                <div className="d-flex flex-column justify-content-center">
                  <div className="d-flex align-items-center ml-3">
                    <Field
                      name="bevestigingVersturen"
                      render={(x: FieldProps<IFormikValues>) => (
                        <VinkVeld
                          aangevinkt={x.field.value}
                          onGewijzigd={(aangevinkt: boolean) =>
                            x.form.setFieldValue(x.field.name, aangevinkt)
                          }
                        />
                      )}
                    />
                    <label className="ml-3">{veldnamen.bevestigingVersturen}</label>
                  </div>

                  {/* <div className="col-12 mt-3">
                    <label>{veldnamen.verkoopdatum}</label>
                    <Field
                      name={nameof<IFormikValues>('verkoopdatum')}
                      render={(fieldProps: FieldProps<IFormikValues>) => {
                        const { field, form } = fieldProps;
                        return (
                          <>
                            <DatumKiezer
                              waarde={field.value}
                              onGewijzigd={(data) => form.setFieldValue(field.name, data)}
                            />
                            <FormikVeldFout fieldProps={fieldProps} />
                          </>
                        );
                      }}
                    />
                  </div> */}

                  {/* {FormikProps.values.bevestigingVersturen && (
                    <div className="col-12 mt-3">
                      <label>{veldnamen.persID}</label>
                      <Field
                        name="persID"
                        render={(fieldProps: FieldProps<IFormikValues>) => (
                          <PersoonSelectie
                            relID={relID}
                            persID={persID}
                            onChange={(x) => {
                              setPersID(x);
                              return fieldProps.form.setFieldValue(fieldProps.field.name, x);
                            }}
                            options={{
                              bijEnkeleDirectVoorselecteren: true,
                            }}
                          />
                        )}
                      />
                    </div>
                  )} */}
                </div>
              </ModalBody>
              <ModalFooter className="d-flex flex-row justify-content-start">
                <button
                  className="btn btn-primary"
                  onClick={submitForm}
                  style={{ width: 100 }}
                  disabled={isSubmitting}
                >
                  Ok
                </button>
                <button
                  className="btn btn-secondary"
                  onClick={onAnnuleren}
                  style={{ width: 100 }}
                  disabled={isSubmitting}
                >
                  Annuleren
                </button>
              </ModalFooter>
            </>
          );
        }}
      />
    </Dialoog>
  );
});

export default VerwerkenDialoog;
