import {
  Grid,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  TableRowDetail,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  DataTypeProvider,
  EditingState,
  RowDetailState,
  VirtualTable as VirtualTableBase,
} from '@devexpress/dx-react-grid';
import React, { useCallback, useMemo, useState, useEffect, useContext } from 'react';
import { IOphalenOpdrachtenResultElement } from '../../../../../../shared/src/api/v2/service/opdracht';
import { IOphalenWerkbonnenResultElement } from '../../../../../../shared/src/api/v2/service/werkbon';
import TabelInspringBlok from '../../../../components/layout/TabelInspringBlok';
import MenuLayout from '../../../../components/MenuLayout';
import {
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
  DXTableToggleCellComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import nameof from '../../../../core/nameOf';
import FormatteerBedrag from '../../../../components/MutatieBedrag';
import { format } from 'date-fns';
import { IconToevoegen } from '../../../../components/Icons';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import WerkbonMutatieDialoog from '../../../../components/service/WerkbonMutatieDialoog';
import api from '../../../../api';
import { RootStoreContext } from '../../../../stores/RootStore';
import { EResultType } from '../../../../stores/CheckStore';
import { OpdrachtenContext } from '../index';
import BijlagenDetailComp from './BijlagenDetailComp';

export interface IWerkbonToevoegenDialoogState {
  servOpdID: number;
}

export interface IMutatieWerkbonDialoogState {
  werkbonID: number;
}

const RowDetailComp = (props: TableRowDetail.ContentProps) => {
  const rij: IOphalenOpdrachtenResultElement = props.row;
  const opdrachtenContext = useContext(OpdrachtenContext);
  // const diensten = opdrachtenContext.diensten;
  const { checkStore } = useContext(RootStoreContext);
  const werkbonnen: IOphalenWerkbonnenResultElement[] = rij.werkbonnen;

  const [
    werkbonToevoegenDialoogState,
    setWerkbonToevoegenDialoogState,
  ] = useState<IWerkbonToevoegenDialoogState | null>(null);
  const [
    mutatieWerkbonDialoogState,
    setMutatieWerkbonDialoogState,
  ] = useState<IMutatieWerkbonDialoogState | null>(null);

  const handleVerwijderen = useCallback(async (id: number) => {
    const checkData = await api.v2.service.checkVerwijderenWerkbonnen({
      ids: [id],
    });
    if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
      return;
    }
    if (
      (
        await checkStore.bevestigen({
          inhoud: 'Werkbon verwijderen?',
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    await api.v2.service.verwijderenWerkbonnen({
      ids: [id],
    });

    // TODO: Verversen
  }, []);

  const kolommen = useMemo<TypedColumn<IOphalenWerkbonnenResultElement>[]>(
    () => [
      {
        name: 'Bezoekdatum',
        title: 'Bezoekdatum',
      },
      {
        name: 'Werkzaamheden',
        title: 'Werkzaamheden',
      },
      {
        name: 'KostenTotaal',
        title: 'Kosten (excl. btw)',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IOphalenWerkbonnenResultElement>[]>(
    () => [
      {
        columnName: 'KostenTotaal',
        width: 125,
      },
      {
        columnName: 'Bezoekdatum',
        width: 115,
      },
      {
        columnName: 'Werkzaamheden',
        width: 450,
      },
    ],
    [],
  );

  const kolomExtensies: VirtualTableBase.ColumnExtension[] = useMemo(() => {
    return [
      {
        columnName: `KostenTotaal`,
        align: 'right',
      },
    ];
  }, []);

  return (
    <div className="d-flex">
      <TabelInspringBlok />
      <MenuLayout
        menu={
          <>
            <div className="mt-2 d-flex align-items-center">
              <button
                className="btn btn-sm btn-light ml-3"
                style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                onClick={() => setWerkbonToevoegenDialoogState({ servOpdID: rij.ServOpdID })}
              >
                <IconToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                Werkbon toevoegen
              </button>
            </div>
          </>
        }
        body={
          <div>
            <GridStyleWrapper maxHeight={500} rowAmount={werkbonnen.length}>
              <Grid columns={kolommen} rows={werkbonnen} getRowId={(x) => x.ID}>
                <DataTypeProvider
                  for={[nameof<IOphalenWerkbonnenResultElement>('KostenTotaal')]}
                  formatterComponent={(formatterprops) => {
                    const rij: IOphalenWerkbonnenResultElement = formatterprops.row;
                    return <FormatteerBedrag bedrag={rij.KostenTotaal} rechtsUitlijnen />;
                  }}
                />

                <DataTypeProvider
                  for={['Bezoekdatum']}
                  formatterComponent={(formatterProps) => {
                    const rij: IOphalenWerkbonnenResultElement = formatterProps.row;

                    if (rij.Bezoekdatum === null) {
                      return <span>Niet opgegeven</span>;
                    }
                    const datum = new Date(rij.Bezoekdatum);
                    return <span>{format(datum, 'dd-MM-yyyy')}</span>;
                  }}
                />

                <EditingState
                  onCommitChanges={async (changes) => {
                    if (changes.deleted === undefined) {
                      return;
                    }
                    const deleted = changes.deleted;
                    const id = deleted[deleted.length - 1] as number;
                    await handleVerwijderen(id);
                    opdrachtenContext.verversen();
                  }}
                  onEditingRowIdsChange={(rowIds) => {
                    const id = rowIds[rowIds.length - 1] as number;
                    setMutatieWerkbonDialoogState({ werkbonID: id });
                  }}
                />

                <VirtualTable
                  columnExtensions={kolomExtensies}
                  messages={{ noData: 'Geen werkbonnen' }}
                />
                <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
                <TableEditColumn
                  width={65}
                  showEditCommand
                  showDeleteCommand
                  cellComponent={DXTableEditColumnCellComponent}
                  commandComponent={DXTableEditColumnCommandComponent}
                />
                <RowDetailState defaultExpandedRowIds={[]} />
                <TableRowDetail
                  contentComponent={BijlagenDetailComp}
                  toggleCellComponent={DXTableToggleCellComponent}
                />

                <TableHeaderRow />
              </Grid>
            </GridStyleWrapper>
          </div>
        }
      />
      {werkbonToevoegenDialoogState !== null && (
        <WerkbonMutatieDialoog
          open={true}
          serviceopdracht={rij}
          werkbonID={null}
          onSuccess={() => {
            setWerkbonToevoegenDialoogState(null);
            opdrachtenContext.verversen();
          }}
          onAnnuleren={() => setWerkbonToevoegenDialoogState(null)}
        />
      )}
      {mutatieWerkbonDialoogState !== null && (
        <WerkbonMutatieDialoog
          open={true}
          serviceopdracht={rij}
          werkbonID={mutatieWerkbonDialoogState.werkbonID}
          onSuccess={() => {
            setMutatieWerkbonDialoogState(null);
            opdrachtenContext.verversen();
          }}
          onAnnuleren={() => setMutatieWerkbonDialoogState(null)}
        />
      )}
    </div>
  );
};

export default RowDetailComp;
