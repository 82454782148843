import React, { useMemo } from 'react';
import Tabblad, { ITabblad } from '../../../../../../components/layout/Tabblad';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Verkoop from './Verkoop';
import Accessoires from './Accessoires';
import { IEntiteitProps } from '../../../../../../components/kaart/EntiteitContainer';
import ProductenDataWrapper from './Producten/ProductenDataWrapper';
import AangebodenOvername from './AangebodenOvername';
import useUrlTabblad from '../../../../../../core/useUrlTabblad';
import Prolongaties from './Prolongaties';
import OverzichtV2 from './OverzichtV2';

interface IProps extends IEntiteitProps, RouteComponentProps {}

export enum ETabblad {
  Overzicht = 1,
  Producten = 2,
  Verkoopmodellen = 3,
  Verkoop = 4,
  AangebodenOvername = 5,
  Beknopt = 6,
  Prolongaties = 7,
}

const pathMap: Record<ETabblad, string> = {
  [ETabblad.Overzicht]: '/overzicht',
  [ETabblad.Producten]: '/producten',
  [ETabblad.Verkoopmodellen]: '/verkoopmodellen',
  [ETabblad.Verkoop]: '/verkoop',
  [ETabblad.AangebodenOvername]: '/aangeboden',
  [ETabblad.Beknopt]: '/beknopt',
  [ETabblad.Prolongaties]: '/prolongaties',
};

export interface IContractenContext {
  relID: number;
}
export const ContractenContext = React.createContext<IContractenContext>(null as any);

const Contracten: React.FC<IProps> = (props) => {
  const { tabblad, onChange: onTabbladChange } = useUrlTabblad<ETabblad>(
    props,
    pathMap,
    ETabblad.Overzicht,
  );

  const tabbladen = useMemo<ITabblad<ETabblad>[]>(
    () => [
      {
        id: ETabblad.Overzicht,
        label: 'Overzicht',
        content: OverzichtV2,
      },
      // {
      //   id: ETabblad.Beknopt,
      //   label: 'Beknopt',
      //   content: Beknopt,
      // },
      {
        id: ETabblad.AangebodenOvername,
        label: 'Aangeboden',
        content: AangebodenOvername,
      },
      {
        id: ETabblad.Producten,
        label: 'Producten',
        content: ProductenDataWrapper,
      },
      {
        id: ETabblad.Verkoopmodellen,
        label: 'Accessoires',
        content: Accessoires,
      },
      {
        id: ETabblad.Verkoop,
        label: 'Verkoopvoorstellen',
        content: Verkoop,
      },
      {
        id: ETabblad.Prolongaties,
        label: 'Prolongaties',
        content: Prolongaties,
      },
    ],
    [],
  );

  const context = useMemo<IContractenContext>(
    () => ({
      relID: props.relID,
    }),
    [props.relID],
  );

  return (
    <ContractenContext.Provider value={context}>
      <div className="d-flex flex-column flex-fill flex-fill">
        {tabblad !== null && (
          <Tabblad<ETabblad>
            geselecteerd={tabblad}
            onSelectieChange={onTabbladChange}
            tabbladen={tabbladen}
            options={{
              tabbladComponentProps: {
                relID: props.relID,
              },
            }}
          />
        )}
      </div>
    </ContractenContext.Provider>
  );
};

export default withRouter(Contracten);
