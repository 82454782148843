import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { defaultSelecterenValues, RelatieSelectieDialoogContext } from '../index';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../models/IRemoteData';
import api from '../../../../api';
import {
  IOphalenRelatiesResult,
  IOphalenRelatiesResultElementV2,
} from '../../../../../../shared/src/api/v2/relatie';
import TekstPrecisieSelectie, { ETekstPrecisie } from '../../../formulier/TekstPrecisieSelectie';
import { IFilterSchemaFilter } from '../../../../../../shared/src/models/filter';
import * as _ from 'lodash';
import { ITekstPrecisieData } from '../../../../../../shared/src/api/sql';
import {
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import { DataTypeProvider, IntegratedSorting, SortingState } from '@devexpress/dx-react-grid';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import { IconInklappen, IconOrganisatie, IconPersoon, IconUitklappen } from '../../../Icons';
import { formatteerAdresV2 } from '../../../../helpers';
import VinkVeld from '../../../formulier/VinkVeld';
import RelatieInfoDialoog from '../../../relatie/MutatieTabel/RelatieInfoDialoog';
import LoadingSpinner from '../../../Gedeeld/LoadingSpinner';
import Combobox from '../../../formulier/Combobox';
import { ERelatiehoedanigheid } from '../../../../bedrijfslogica/enums';
import GeslachtSelectie from '../../../formulier/GeslachtSelectie';
import { IOphalenContractenResultElementV2 } from '../../../../../../shared/src/api/v2/contract';
import RelatieVisualisatie from '../../RelatieVisualisatie';

export interface IFormikValues {
  naam: string;
  naamTekstprecisie: ETekstPrecisie;
  huisnummer: string;
  straatnaam: string;
  telefoonnummer: string;
  telefoonnummerTekstprecisie: ETekstPrecisie;
  woonplaats: string;
  hoedanigheid: ERelatiehoedanigheid | 'beide';
  email: string;
  emailTekstprecisie: ETekstPrecisie;
  iban: string;
  rekeningnaam: string;
  postcode: string;
  referentiecode: string;
  contractnummer: string;
  factuurnummer: string;
  relatienummer: string;
  actieveContracten: boolean;
  voornaam: string;
  voornaamTekstprecisie: ETekstPrecisie;
  transportopdrachtnummer: string;
  meldnummer: string;
  serviceopdrachtnummer: string;
  gebruikersnaam: string;
  geslacht: number | null;
  kvkNummer: string;
  wervingscode: string;
}

export interface ISelecterenSuggesties {
  naam?: string;
  huisnummer?: string;
  straatnaam?: string;
  telefoonnummer?: string;
  woonplaats?: string;
  hoedanigheid?: ERelatiehoedanigheid | 'beide';
  email?: string;
  iban?: string;
  rekeningnaam?: string;
  postcode?: string;
  referentiecode?: string;
  contractnummer?: string;
  factuurnummer?: string;
  relatienummer?: string;
  actieveContracten?: boolean;
  voornaam?: string;
  transportopdrachtnummer?: string;
  meldnummer?: string;
  serviceopdrachtnummer?: string;
  gebruikersnaam?: string;
  geslacht?: number | null;
  kvkNummer?: string;
  wervingscode?: string;
}

interface IProps {}

export interface IRelatieInfoDialoogState {
  id: number;
}

export interface IRelatie extends IOphalenRelatiesResultElementV2 {
  contracten: IOphalenContractenResultElementV2[];
}

export interface ISelectieResult {
  relaties: IRelatie[];
}

const maxAantalResultaten = 250;

interface ISelecterenSuggestieProps<T> {
  value?: T;
  formatter?: (value: T) => JSX.Element | string;
  onGekozen?: (value: T) => void;
}
const SelecterenSuggestie = <T extends any>(props: ISelecterenSuggestieProps<T>) => {
  if (props.value === undefined) {
    return null;
  }

  return (
    <a
      href="#"
      onClick={(ev) => {
        ev.preventDefault();
        props.onGekozen?.(props.value!);
      }}
      style={{ fontSize: 12, marginLeft: 5, color: Kleur.DonkerBlauw }}
    >
      {props.formatter?.(props.value) ?? props.value}
    </a>
  );
};

const SelecterenTabblad: React.FC<IProps> = (props) => {
  const {
    koppelOpties,
    onGekozen,
    hoedanigheid,
    onHoedanigheidChange,
    selecterenValues,
    setSelecterenValues,
    selecterenSuggesties,
  } = useContext(RelatieSelectieDialoogContext);

  const v = selecterenValues;
  const setV = setSelecterenValues;

  const [meerCriteriaOpen, setMeerCriteriaOpen] = useState(false);

  const [suggesties, setSuggesties] = useState<
    IRemoteData<
      | {
          type: 'RESULTAAT';
          result: ISelectieResult;
        }
      | {
          type: 'TE_VEEL_RESULTATEN';
          aantalNumber: number;
        }
    >
  >(createPendingRemoteData());
  const ophalenSuggesties = useCallback(async () => {
    const naam = v.naam.trim();
    const telefoonnummer = v.telefoonnummer.trim();
    const email = v.email.trim();
    const postcode = v.postcode.trim();
    const huisnummerTekst = v.huisnummer.trim();
    const huisnummer = huisnummerTekst.length === 0 ? null : Number(huisnummerTekst);
    const straatnaam = v.straatnaam.trim();
    const woonplaats = v.woonplaats.trim();
    const iban = v.iban.trim();
    const rekeningnaam = v.rekeningnaam.trim();
    const actieveContracten = v.actieveContracten;
    const voornaam = v.voornaam;

    const referentiecode = v.referentiecode.trim();
    const contractnummer = v.contractnummer.trim();
    const factuurnummer = v.factuurnummer.trim();
    const relatienummer = v.relatienummer.trim();
    const transportopdrachtnummer = v.transportopdrachtnummer.trim();
    const serviceopdrachtnummer = v.serviceopdrachtnummer.trim();
    const gebruikersnaam = v.gebruikersnaam.trim();
    const hoedanigheid = v.hoedanigheid;
    const geslacht = v.geslacht;
    const kvkNummer = v.kvkNummer;
    const wervingscode = v.wervingscode;

    // SM- eraf halen omdat alleen het numerieke deel wordt opgeslagen
    const meldnummer = v.meldnummer
      .toLowerCase()
      .replace('sm-', '')
      .trim();

    const filters: Array<IFilterSchemaFilter | null> = [
      geslacht === null || isNaN(geslacht)
        ? null
        : {
            naam: 'GESLACHT',
            data: geslacht,
          },
      naam.length === 0
        ? null
        : {
            naam: 'NAAM_UITGEBREID',
            data: {
              waarde: naam,
              precisie: v.naamTekstprecisie,
            } as ITekstPrecisieData,
          },
      voornaam.length === 0
        ? null
        : {
            naam: 'VOORNAAM_UITGEBREID',
            data: {
              waarde: voornaam,
              precisie: v.voornaamTekstprecisie,
            } as ITekstPrecisieData,
          },
      telefoonnummer.length === 0
        ? null
        : {
            naam: 'TELEFOONNUMMER_UITGEBREID',
            data: {
              waarde: telefoonnummer,
              precisie: v.telefoonnummerTekstprecisie,
            } as ITekstPrecisieData,
          },
      email.length === 0
        ? null
        : {
            naam: 'EMAIL_UITGEBREID',
            data: {
              waarde: email,
              precisie: v.emailTekstprecisie,
            } as ITekstPrecisieData,
          },
      postcode.length === 0
        ? null
        : {
            naam: 'POSTCODE',
            data: postcode,
          },
      huisnummer === null || isNaN(huisnummer)
        ? null
        : {
            naam: 'HUISNUMMER',
            data: huisnummer,
          },
      straatnaam.length === 0
        ? null
        : {
            naam: 'STRAATNAAM',
            data: straatnaam,
          },
      woonplaats.length === 0
        ? null
        : {
            naam: 'PLAATSNAAM',
            data: woonplaats,
          },
      iban.length === 0
        ? null
        : {
            naam: 'IBAN',
            data: iban,
          },
      rekeningnaam.length === 0
        ? null
        : {
            naam: 'REKENINGNAAM',
            data: rekeningnaam,
          },
      referentiecode.length === 0
        ? null
        : {
            naam: 'REFERENTIECODE',
            data: referentiecode,
          },
      contractnummer.length === 0
        ? null
        : {
            naam: 'CONTRACTNUMMER',
            data: contractnummer,
          },
      factuurnummer.length === 0
        ? null
        : {
            naam: 'FACTUURNUMMER',
            data: factuurnummer,
          },
      transportopdrachtnummer.length === 0
        ? null
        : {
            naam: 'TRANSPORTOPDRACHTNUMMER',
            data: transportopdrachtnummer,
          },
      meldnummer.length === 0
        ? null
        : {
            naam: 'MELDNUMMER',
            data: meldnummer,
          },
      relatienummer.length === 0
        ? null
        : {
            naam: 'RELATIENUMMER',
            data: relatienummer,
          },
      hoedanigheid === ERelatiehoedanigheid.Leverancier
        ? null
        : {
            naam: 'MET_ACTIEVE_CONTRACTEN',
            data: actieveContracten,
          },
      serviceopdrachtnummer.length === 0
        ? null
        : {
            naam: 'SERVICEOPDRACHTNUMMER',
            data: serviceopdrachtnummer,
          },
      gebruikersnaam.length === 0
        ? null
        : {
            naam: 'GEBRUIKERSNAAM',
            data: gebruikersnaam,
          },

      wervingscode.length === 0
        ? null
        : {
            naam: 'WERVINGSCODE',
            data: wervingscode,
          },
      kvkNummer.length === 0
        ? null
        : {
            naam: 'KVK_NUMMER',
            data: kvkNummer,
          },
      {
        naam: 'RELATIE_HOEDANIGHEID_NAAM_ENUMS',
        data:
          hoedanigheid === 'beide'
            ? [ERelatiehoedanigheid.Klant, ERelatiehoedanigheid.Leverancier]
            : [hoedanigheid],
      },
    ];

    const f = filters.filter((x) => x !== null) as IFilterSchemaFilter[];
    if (f.length === 0) {
      setSuggesties(createPendingRemoteData());
      return;
    }
    const result = await api.v2.relatie.ophalenRelatiesuggesties({
      paginatie: {
        index: 0,
        aantal: 100,
      },
      filterSchema: {
        filters: f,
      },
    });

    if (result.totaalAantal > maxAantalResultaten) {
      setSuggesties(
        createReadyRemoteData({
          type: 'TE_VEEL_RESULTATEN',
          aantalNumber: result.totaalAantal,
        }),
      );
      return;
    }
    if (result.relatiesuggesties.length === 0) {
      setSuggesties(
        createReadyRemoteData({
          type: 'RESULTAAT',
          result: {
            relaties: [],
          },
        }),
      );
      return;
    }
    const relIDs = result.relatiesuggesties.map((x) => x.RelID);
    const relatiesResult = await api.v2.relatie.ophalenRelaties({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: relIDs,
          },
        ],
      },
    });

    const contractenResult = await api.v2.contract.ophalenContractenV2({
      filterSchema: {
        filters: [
          { naam: 'REL_IDS', data: relIDs },
          { naam: 'IS_ACTUEEL', data: true },
        ],
      },
    });

    // const relaties:ISelectieResult = {relaties: relatiesResult.relaties.map(x => {
    //   return {...x, contracten:[]}
    // })}

    const relaties: ISelectieResult = {
      relaties: relatiesResult.relaties.map((x) => {
        const contracten = contractenResult.contracten.filter((x) => x.RelID === x.RelID);
        return { ...x, contracten };
      }),
    };

    setSuggesties(
      createReadyRemoteData({
        type: 'RESULTAAT',
        result: {
          ...relaties,
        },
      }),
    );

    // setSuggesties(
    //   createReadyRemoteData({
    //     type: 'RESULTAAT',
    //     result: relatiesResult,
    //   }),
    // );
  }, [v]);
  const debouncedOphalenSuggesties = useRef<any>(null);

  useEffect(() => {
    if (debouncedOphalenSuggesties.current !== null) {
      debouncedOphalenSuggesties.current.cancel();
      debouncedOphalenSuggesties.current = null;
    }
    debouncedOphalenSuggesties.current = _.debounce(ophalenSuggesties, 500);
    debouncedOphalenSuggesties.current();
  }, [v]);

  const sleutelExtractor = useCallback((row: IOphalenRelatiesResultElementV2) => row.RelID, []);
  const kolommen = useMemo<TypedColumn<IOphalenRelatiesResultElementV2>[]>(
    () => [
      {
        name: 'Relatiesoort' as any,
        title: ' ',
      },
      {
        name: '__NAAM' as any,
        title: 'Naam',
        getCellValue: (row) => {
          return row.weergavenaam;
        },
      },

      {
        name: '__CORRESPONDENTIEADRES' as any,
        title: 'Correspondentieadres',
      },
      {
        name: '__productmodellen' as any,
        title: 'Actuele contracten',
      },
      {
        name: 'Relatienummer',
        title: 'Nummer',
      },
      {
        name: '__kies_actie',
        title: ' ',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IOphalenRelatiesResultElementV2>[]>(
    () => [
      {
        columnName: 'Relatiesoort',
        width: 40,
      },
      {
        columnName: '__NAAM' as any,
        width: 250,
      },
      {
        columnName: 'Relatienummer',
        width: 100,
      },
      {
        columnName: '__CORRESPONDENTIEADRES' as any,
        width: 325,
      },
      {
        columnName: '__productmodellen' as any,
        width: 225,
      },
      {
        columnName: '__kies_actie' as any,
        width: 40,
      },
    ],
    [],
  );

  const [
    relatieInfoDialoogState,
    setRelatieInfoDialoogState,
  ] = useState<IRelatieInfoDialoogState | null>(null);

  const postcodeRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    setTimeout(() => {
      postcodeRef.current!.focus();
    }, 50);
  }, []);

  return (
    <>
      <div className="flex-fill d-flex flex-column position-relative">
        <div className="p-3">
          <div className="row">
            <div className="col-4">
              <div className="row">
                <div className="col-8 mt-2">
                  <label>Postcode</label>
                  <input
                    ref={postcodeRef}
                    className="form-control"
                    value={v.postcode}
                    onChange={(ev) => {
                      const value = ev.target.value;
                      setV((x) => ({
                        ...x,
                        postcode: value,
                      }));
                    }}
                  />
                  <SelecterenSuggestie
                    value={selecterenSuggesties.postcode}
                    onGekozen={(value) => {
                      setV((x) => ({
                        ...x,
                        postcode: value,
                      }));
                    }}
                  />
                </div>
                <div className="col-4 mt-2">
                  <label>Huisnr</label>
                  <input
                    className="form-control"
                    value={v.huisnummer}
                    onChange={(ev) => {
                      const value = ev.target.value;
                      setV((x) => ({
                        ...x,
                        huisnummer: value,
                      }));
                    }}
                  />
                  <SelecterenSuggestie
                    value={selecterenSuggesties.huisnummer}
                    onGekozen={(value) => {
                      setV((x) => ({
                        ...x,
                        huisnummer: value,
                      }));
                    }}
                  />
                </div>

                <div className="col-12 mt-2">
                  {' '}
                  <label className="d-flex align-items-center justify-content-between">
                    <span>Naam</span>
                    <TekstPrecisieSelectie
                      precisie={v.naamTekstprecisie}
                      onPrecisieChange={(precisie) => {
                        setV((x) => ({
                          ...x,
                          naamTekstprecisie: precisie,
                        }));
                      }}
                    />
                  </label>
                  <input
                    className="form-control"
                    value={v.naam}
                    onChange={(ev) => {
                      const value = ev.target.value;
                      setV((x) => ({
                        ...x,
                        naam: value,
                      }));
                    }}
                  />
                  <SelecterenSuggestie
                    value={selecterenSuggesties.naam}
                    onGekozen={(value) => {
                      setV((x) => ({
                        ...x,
                        naam: value,
                      }));
                    }}
                  />
                </div>

                <div className="col-12 mt-2">
                  <label className="d-flex align-items-center justify-content-between">
                    <span>Telefoonnummer</span>
                    <TekstPrecisieSelectie
                      precisie={v.telefoonnummerTekstprecisie}
                      onPrecisieChange={(precisie) => {
                        setV((x) => ({
                          ...x,
                          telefoonnummerTekstprecisie: precisie,
                        }));
                      }}
                    />
                  </label>
                  <input
                    className="form-control"
                    value={v.telefoonnummer}
                    onChange={(ev) => {
                      const value = ev.target.value;
                      setV((x) => ({
                        ...x,
                        telefoonnummer: value,
                      }));
                    }}
                  />
                  <SelecterenSuggestie
                    value={selecterenSuggesties.telefoonnummer}
                    onGekozen={(value) => {
                      setV((x) => ({
                        ...x,
                        telefoonnummer: value,
                      }));
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="row">
                <div className="col-12 mt-2">
                  <label>Straatnaam</label>
                  <input
                    className="form-control"
                    value={v.straatnaam}
                    onChange={(ev) => {
                      const value = ev.target.value;
                      setV((x) => ({
                        ...x,
                        straatnaam: value,
                      }));
                    }}
                  />
                  <SelecterenSuggestie
                    value={selecterenSuggesties.straatnaam}
                    onGekozen={(value) => {
                      setV((x) => ({
                        ...x,
                        straatnaam: value,
                      }));
                    }}
                  />
                </div>

                <div className="col-12 mt-2">
                  <label className="d-flex align-items-center justify-content-between">
                    <span>Voornaam</span>
                    <TekstPrecisieSelectie
                      precisie={v.voornaamTekstprecisie}
                      onPrecisieChange={(precisie) => {
                        setV((x) => ({
                          ...x,
                          voornaamTekstprecisie: precisie,
                        }));
                      }}
                    />
                  </label>
                  <input
                    className="form-control"
                    value={v.voornaam}
                    onChange={(ev) => {
                      const value = ev.target.value;
                      setV((x) => ({
                        ...x,
                        voornaam: value,
                      }));
                    }}
                  />
                  <SelecterenSuggestie
                    value={selecterenSuggesties.voornaam}
                    onGekozen={(value) => {
                      setV((x) => ({
                        ...x,
                        voornaam: value,
                      }));
                    }}
                  />
                </div>

                <div className="col-12 mt-2">
                  <label className="d-flex align-items-center justify-content-between">
                    <span>Email</span>
                    <TekstPrecisieSelectie
                      precisie={v.emailTekstprecisie}
                      onPrecisieChange={(precisie) => {
                        setV((x) => ({
                          ...x,
                          emailTekstprecisie: precisie,
                        }));
                      }}
                    />
                  </label>
                  <input
                    className="form-control"
                    value={v.email}
                    onChange={(ev) => {
                      const value = ev.target.value;
                      setV((x) => ({
                        ...x,
                        email: value,
                      }));
                    }}
                  />
                  <SelecterenSuggestie
                    value={selecterenSuggesties.email}
                    onGekozen={(value) => {
                      setV((x) => ({
                        ...x,
                        email: value,
                      }));
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="row">
                <div className="col-12 mt-2">
                  <label>Woonplaats</label>
                  <input
                    className="form-control"
                    value={v.woonplaats}
                    onChange={(ev) => {
                      const value = ev.target.value;
                      setV((x) => ({
                        ...x,
                        woonplaats: value,
                      }));
                    }}
                  />
                  <SelecterenSuggestie
                    value={selecterenSuggesties.woonplaats}
                    onGekozen={(value) => {
                      setV((x) => ({
                        ...x,
                        woonplaats: value,
                      }));
                    }}
                  />
                </div>

                <div className="col-12 mt-2">
                  <label>Geslacht</label>
                  <GeslachtSelectie
                    geslacht={v.geslacht}
                    onChange={(x) => {
                      const value = x;
                      setV((x) => ({
                        ...x,
                        geslacht: value,
                      }));
                    }}
                    isWisbaar
                  />
                  {/*TODO*/}
                  {/*<SelecterenSuggestie*/}
                  {/*  value={selecterenSuggesties.geslacht}*/}
                  {/*  formatter={(geslacht) => geslacht === null}*/}
                  {/*  onGekozen={(value) => {*/}
                  {/*    setV((x) => ({*/}
                  {/*      ...x,*/}
                  {/*      geslacht: value,*/}
                  {/*    }));*/}
                  {/*  }}*/}
                  {/*/>*/}
                </div>
              </div>
            </div>

            <div className="col-4">
              <div className="row">
                <div className="col-12 mt-2">
                  <label>Referentiecode</label>
                  <input
                    className="form-control"
                    value={v.referentiecode}
                    onChange={(ev) => {
                      const value = ev.target.value;
                      setV((x) => ({
                        ...x,
                        referentiecode: value,
                      }));
                    }}
                  />
                  <SelecterenSuggestie
                    value={selecterenSuggesties.referentiecode}
                    onGekozen={(value) => {
                      setV((x) => ({
                        ...x,
                        referentiecode: value,
                      }));
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="col-4">
              <div className="row">
                <div className="col-12 mt-2">
                  <label>Relatienummer</label>
                  <input
                    className="form-control"
                    value={v.relatienummer}
                    onChange={(ev) => {
                      const value = ev.target.value;
                      setV((x) => ({
                        ...x,
                        relatienummer: value,
                      }));
                    }}
                    placeholder={'B.v. 851781'}
                  />
                  <SelecterenSuggestie
                    value={selecterenSuggesties.relatienummer}
                    onGekozen={(value) => {
                      setV((x) => ({
                        ...x,
                        relatienummer: value,
                      }));
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="col-4 mt-2">
              <label>Factuurnummer</label>
              <input
                className="form-control"
                value={v.factuurnummer}
                onChange={(ev) => {
                  const value = ev.target.value;
                  setV((x) => ({
                    ...x,
                    factuurnummer: value,
                  }));
                }}
                placeholder={'B.v. 21.0123456'}
              />
              <SelecterenSuggestie
                value={selecterenSuggesties.factuurnummer}
                onGekozen={(value) => {
                  setV((x) => ({
                    ...x,
                    factuurnummer: value,
                  }));
                }}
              />
            </div>
          </div>

          <div className="row mt-2">
            <div className="col-4">
              <label>Hoedanigheid</label>
              <Combobox
                geselecteerd={v.hoedanigheid}
                opties={[
                  {
                    id: 'beide',
                    label: 'Klant en/of Leverancier',
                  },
                  {
                    id: ERelatiehoedanigheid.Klant,
                    label: 'Klant',
                  },
                  {
                    id: ERelatiehoedanigheid.Leverancier,
                    label: 'Leverancier',
                  },
                ]}
                onSelectieChange={(hoedanigheid) => {
                  setV((x) => ({
                    ...x,
                    hoedanigheid: hoedanigheid!,
                  }));
                }}
              />
              <SelecterenSuggestie
                value={selecterenSuggesties.hoedanigheid}
                formatter={(hoedanigheid) =>
                  hoedanigheid === 'beide'
                    ? 'Beide'
                    : hoedanigheid === ERelatiehoedanigheid.Klant
                    ? 'Klant'
                    : 'Leverancier'
                }
                onGekozen={(value) => {
                  setV((x) => ({
                    ...x,
                    hoedanigheid: value,
                  }));
                }}
              />
            </div>

            <div className="col-4 d-flex">
              <div className="flex-fill d-flex align-items-end mb-1">
                <div className="d-flex align-items-center">
                  <VinkVeld
                    aangevinkt={
                      v.hoedanigheid === ERelatiehoedanigheid.Leverancier
                        ? false
                        : v.actieveContracten
                    }
                    onGewijzigd={(aangevinkt: boolean) => {
                      setV((x) => ({
                        ...x,
                        actieveContracten: aangevinkt,
                      }));
                    }}
                    disabled={v.hoedanigheid === ERelatiehoedanigheid.Leverancier}
                  />
                  <span className="ml-2 mt-1">Met actieve contracten</span>
                </div>
                <SelecterenSuggestie
                  value={selecterenSuggesties.actieveContracten}
                  formatter={(actief) => (actief ? 'Ja' : 'Nee')}
                  onGekozen={(value) => {
                    setV((x) => ({
                      ...x,
                      actieveContracten: value,
                    }));
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex flex-column align-items-center">
          <a
            className="d-flex align-items-center text-decoration-none"
            href="#"
            onClick={() => setMeerCriteriaOpen((curr) => !curr)}
          >
            <span>{meerCriteriaOpen ? 'Minder' : 'Meer'} criteria</span>
            {meerCriteriaOpen ? (
              <IconInklappen style={{ fill: Kleur.SelectieBlauw, width: 18, height: 18 }} />
            ) : (
              <IconUitklappen style={{ fill: Kleur.SelectieBlauw, width: 18, height: 18 }} />
            )}
          </a>
        </div>

        {meerCriteriaOpen && (
          <div className="p-3" style={{ paddingTop: 0 }}>
            <div className="row">
              {/* <div className="col-4">
                <div className="row">
                  <div className="col-12">
                    <label>Relatienummer</label>
                    <input
                      className="form-control"
                      value={v.relatienummer}
                      onChange={(ev) => {
                        const value = ev.target.value;
                        setV((x) => ({
                          ...x,
                          relatienummer: value,
                        }));
                      }}
                      placeholder={'B.v. 851781'}
                    />
                  </div>
                </div>
              </div> */}

              <div className="col-4">
                <div className="row">
                  <div className="col-12">
                    <label>Contractnummer</label>
                    <input
                      className="form-control"
                      value={v.contractnummer}
                      onChange={(ev) => {
                        const value = ev.target.value;
                        setV((x) => ({
                          ...x,
                          contractnummer: value,
                        }));
                      }}
                      placeholder={'B.v. 61315.0 of 61315'}
                    />
                    <SelecterenSuggestie
                      value={selecterenSuggesties.contractnummer}
                      onGekozen={(value) => {
                        setV((x) => ({
                          ...x,
                          contractnummer: value,
                        }));
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="col-4">
                <div className="row">
                  <div className="col-12">
                    <label>Transportopdrachtnummer</label>
                    <input
                      className="form-control"
                      value={v.transportopdrachtnummer}
                      onChange={(ev) => {
                        const value = ev.target.value;
                        setV((x) => ({
                          ...x,
                          transportopdrachtnummer: value,
                        }));
                      }}
                      placeholder={'B.v. 65141'}
                    />
                    <SelecterenSuggestie
                      value={selecterenSuggesties.transportopdrachtnummer}
                      onGekozen={(value) => {
                        setV((x) => ({
                          ...x,
                          transportopdrachtnummer: value,
                        }));
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="col-4">
                <div className="row">
                  <div className="col-12">
                    <label>Meldnummer</label>
                    <input
                      className="form-control"
                      value={v.meldnummer}
                      onChange={(ev) => {
                        const value = ev.target.value;

                        if (isNaN(Number(value))) {
                          return;
                        }

                        setV((x) => ({
                          ...x,
                          meldnummer: value,
                        }));
                      }}
                      placeholder={'B.v. 65141'}
                    />
                    <SelecterenSuggestie
                      value={selecterenSuggesties.meldnummer}
                      onGekozen={(value) => {
                        setV((x) => ({
                          ...x,
                          meldnummer: value,
                        }));
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="col-4">
                <div className="row">
                  <div className="col-12 mt-3">
                    <label>IBAN</label>
                    <input
                      className="form-control"
                      value={v.iban}
                      onChange={(ev) => {
                        const value = ev.target.value;
                        setV((x) => ({
                          ...x,
                          iban: value,
                        }));
                      }}
                    />
                    <SelecterenSuggestie
                      value={selecterenSuggesties.iban}
                      onGekozen={(value) => {
                        setV((x) => ({
                          ...x,
                          iban: value,
                        }));
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="col-4">
                <div className="row">
                  <div className="col-12 mt-3">
                    <label>Rekeningnaam</label>
                    <input
                      className="form-control"
                      value={v.rekeningnaam}
                      onChange={(ev) => {
                        const value = ev.target.value;
                        setV((x) => ({
                          ...x,
                          rekeningnaam: value,
                        }));
                      }}
                    />
                    <SelecterenSuggestie
                      value={selecterenSuggesties.rekeningnaam}
                      onGekozen={(value) => {
                        setV((x) => ({
                          ...x,
                          rekeningnaam: value,
                        }));
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="col-4">
                <div className="row">
                  <div className="col-12 mt-3">
                    <label>Gebruikersnaam</label>
                    <input
                      className="form-control"
                      value={v.gebruikersnaam}
                      onChange={(ev) => {
                        const value = ev.target.value;
                        setV((x) => ({
                          ...x,
                          gebruikersnaam: value,
                        }));
                      }}
                    />
                    <SelecterenSuggestie
                      value={selecterenSuggesties.gebruikersnaam}
                      onGekozen={(value) => {
                        setV((x) => ({
                          ...x,
                          gebruikersnaam: value,
                        }));
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="col-4">
                <div className="row">
                  <div className="col-12 mt-3">
                    <label>Klant-Werft-Klant Code (KwK)</label>
                    <input
                      className="form-control"
                      value={v.wervingscode}
                      onChange={(ev) => {
                        const value = ev.target.value;
                        setV((x) => ({
                          ...x,
                          wervingscode: value,
                        }));
                      }}
                    />
                    <SelecterenSuggestie
                      value={selecterenSuggesties.wervingscode}
                      onGekozen={(value) => {
                        setV((x) => ({
                          ...x,
                          wervingscode: value,
                        }));
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="col-4">
                <div className="row">
                  <div className="col-12 mt-3">
                    <label>KvK nummer</label>
                    <input
                      className="form-control"
                      value={v.kvkNummer}
                      onChange={(ev) => {
                        const value = ev.target.value;
                        setV((x) => ({
                          ...x,
                          kvkNummer: value,
                        }));
                      }}
                    />
                    <SelecterenSuggestie
                      value={selecterenSuggesties.kvkNummer}
                      onGekozen={(value) => {
                        setV((x) => ({
                          ...x,
                          kvkNummer: value,
                        }));
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <div>
          {suggesties.state === ERemoteDataState.Pending ? (
            <div className="flex-fill d-flex align-items-center justify-content-center p-2">
              <LoadingSpinner />
            </div>
          ) : suggesties.data!.type === 'TE_VEEL_RESULTATEN' ? (
            <div className="p-2 d-flex flex-column align-items-center justify-content-center">
              <div className="font-weight-bold">
                {(suggesties.data! as any).aantalNumber} resultaten
              </div>
              <div>Vernauw / wijzig de selectiecriteria om resultaten weer te geven</div>
            </div>
          ) : suggesties.data!.type === 'RESULTAAT' ? (
            <div className="d-flex flex-column align-items-center justify-content-center">
              <span style={{ fontSize: 12 }} className="text-muted w-100 p-1 pl-3 pr-3">
                {(suggesties.data! as any).result.relaties.length} resultaten
              </span>
              <GridStyleWrapper
                maxHeight={400}
                rowAmount={
                  ((suggesties.data! as any).result as IOphalenRelatiesResult).relaties.length
                }
              >
                <Grid
                  getRowId={sleutelExtractor}
                  columns={kolommen as any}
                  rows={((suggesties.data! as any).result as ISelectieResult).relaties}
                >
                  <DataTypeProvider
                    for={['__NAAM']}
                    formatterComponent={(props) => {
                      const rij = props.row as IRelatie;

                      return (
                        <RelatieVisualisatie
                          relID={rij.RelID}
                          weergaveNaam={rij.weergavenaam}
                          options={{ geenLinkToepassen: true }}
                        />
                      );
                    }}
                  />

                  <DataTypeProvider
                    for={['Relatiesoort']}
                    formatterComponent={(props) => {
                      const iconStyle: React.CSSProperties = {
                        width: 18,
                        height: 18,
                        fill: Kleur.Grijs,
                      };
                      const entiteit = props.row as IRelatie;
                      return (
                        <span className="ml-2">
                          {entiteit.Relatiesoort === 'P' ? (
                            <IconPersoon style={iconStyle} />
                          ) : (
                            <IconOrganisatie style={iconStyle} />
                          )}
                        </span>
                      );
                    }}
                  />

                  <DataTypeProvider
                    for={['__productmodellen']}
                    formatterComponent={(formatterProps) => {
                      const rij = formatterProps.row as IRelatie;
                      const productmodellen = _.uniq(
                        rij.contracten
                          .filter((x) => x.RelID === rij.RelID)
                          .map((x) => x.basis.productmodel.Modelcode),
                      ).join(', ');
                      return <span>{productmodellen}</span>;
                    }}
                  />

                  <DataTypeProvider
                    for={['__kies_actie']}
                    formatterComponent={(formatterProps) => {
                      const entiteit = formatterProps.row as IOphalenRelatiesResultElementV2;
                      return (
                        <a
                          href="#"
                          onClick={(ev) => {
                            ev.preventDefault();
                            onGekozen(entiteit.RelID);
                          }}
                        >
                          Kies
                        </a>
                      );
                    }}
                  />

                  <DataTypeProvider
                    for={['__CORRESPONDENTIEADRES']}
                    formatterComponent={(props) => {
                      const entiteit = props.row as IOphalenRelatiesResultElementV2;
                      const adres = entiteit.adres;
                      if (adres === null) {
                        return <span />;
                      }
                      return (
                        <span>
                          {formatteerAdresV2({
                            bisnummer: adres.Bisnummer,
                            postcode: adres.Postcode,
                            straatnaam: adres.Straatnaam,
                            huisnummer: adres.Huisnummer,
                            landnaamEnum: adres.LandnaamEnum,
                            landnaamKort: adres.LandnaamKort,
                            plaatsnaam: adres.Plaatsnaam,
                          })}
                        </span>
                      );
                    }}
                  />

                  <SortingState
                    defaultSorting={[
                      {
                        columnName: '__NAAM',
                        direction: 'asc',
                      },
                    ]}
                  />
                  <IntegratedSorting />

                  <VirtualTable
                    rowComponent={(rowProps) => (
                      <tr
                        // className=""
                        style={{ cursor: 'pointer' }}
                        onDoubleClick={() => {
                          const entiteit: IOphalenRelatiesResultElementV2 = rowProps.row;
                          onGekozen(entiteit.RelID);
                        }}
                      >
                        {rowProps.children}
                      </tr>
                    )}
                  />

                  <TableColumnResizing columnWidths={kolomBreedtes} />
                  <TableHeaderRow showSortingControls />
                </Grid>
              </GridStyleWrapper>
            </div>
          ) : (
            <div>Niet geimplementeerd</div>
          )}
        </div>

        <div className="position-absolute" style={{ top: 8, right: 15 }}>
          <a
            href="#"
            onClick={() => {
              setV(defaultSelecterenValues);
            }}
          >
            Wissen
          </a>
        </div>
      </div>
      {relatieInfoDialoogState !== null && (
        <RelatieInfoDialoog
          open
          relID={relatieInfoDialoogState.id}
          onSuccess={() => setRelatieInfoDialoogState(null)}
          onAnnuleren={() => setRelatieInfoDialoogState(null)}
        />
      )}
    </>
  );
};

export default SelecterenTabblad;
