import React, { useContext, useMemo } from 'react';
import { IProps } from '@tinymce/tinymce-react/lib/cjs/main/ts/components/Editor';
import { IContext, MeldingContext } from '../index';
import VeldWeergave from '../../../formulier/VeldWeergave';
import LoadingSpinner from '../../../Gedeeld/LoadingSpinner';
import { format } from 'date-fns';
import GegevensLayout from '../../../layout/GegevensLayout';
import { GridStyleWrapper, TypedColumn } from '../../../../helpers/dxTableGrid';
import { Grid, Table, TableHeaderRow } from '@devexpress/dx-react-grid-bootstrap4';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { formatteerPersoonNaam } from '../../../../helpers';
import OpdrachtenComp from '../../OpdrachtenComp';
import FormatteerBedrag from '../../../MutatieBedrag';
import _ from 'lodash';
import { IOphalenWerkbonnenResultElement } from '../../../../../../shared/src/api/v2/service/werkbon';

const DetailTab: React.FC<IProps> = (props) => {
  const { melding, opdrachten } = useContext<IContext>(MeldingContext);

  const werkbonnen: IOphalenWerkbonnenResultElement[] = _.flatten(
    opdrachten!.map((x) => x.werkbonnen),
  );

  const totaalKosten = _.sum(werkbonnen.map((x) => x.KostenTotaal));

  return (
    <>
      {melding === null || opdrachten === null ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="d-flex flex-column flex-fill p-3">
            <div className="row">
              <div className="col-12 d-flex">
                <VeldWeergave>
                  <GegevensLayout
                    gegevens={[
                      {
                        label: 'Datum',
                        waarde: format(new Date(melding.Melddatum), 'dd-MM-yyyy'),
                      },
                      {
                        label: 'Status',
                        waarde: melding.Afgehandeld ? 'Afgehandeld' : 'Lopend',
                      },
                      {
                        label: 'Melder',
                        waarde:
                          melding.persoon !== null
                            ? formatteerPersoonNaam({
                                voornaam: melding.persoon.Voornaam,
                                achternaam: melding.persoon.Achternaam,
                                voorletters: melding.persoon.Voorletters,
                                aanhefKort: melding.persoon.AanhefKort,
                                voorvoegsel: melding.persoon.Voorvoegsel,
                              })
                            : '',
                      },
                      {
                        label: 'Klacht',
                        waarde: melding.Omschrijving,
                      },
                      {
                        label: 'Totaal rep.kosten',
                        waarde: <FormatteerBedrag bedrag={totaalKosten} />,
                      },
                    ]}
                  />
                </VeldWeergave>
              </div>

              <div className="col-12 mt-3">
                <div></div>
                <VeldWeergave>
                  <GegevensLayout
                    gegevens={[
                      {
                        label: 'Adres',
                        waarde:
                          (
                            melding.locatie.Straatnaam +
                            ' ' +
                            melding.locatie.Huisnummer +
                            ' ' +
                            (melding.locatie.Bisnummer !== null ? melding.locatie.Bisnummer : '')
                          ).trim() +
                          ', ' +
                          melding.locatie.Postcode +
                          ' ' +
                          melding.locatie.Plaatsnaam,
                      },
                      // {
                      //   label: 'Product',
                      //   waarde: '',
                      // },
                    ]}
                  />
                </VeldWeergave>
              </div>

              <div className="col-12 mt-3">
                <div></div>
                <VeldWeergave>
                  <GegevensLayout
                    gegevens={[
                      {
                        label: 'Klacht',
                        waarde: melding.Omschrijving,
                      },
                      // {
                      //   label: 'Product',
                      //   waarde: '',
                      // },
                    ]}
                  />
                </VeldWeergave>
              </div>
            </div>
            <div className="mt-3">
              <OpdrachtenComp opdrachten={opdrachten} />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default DetailTab;
