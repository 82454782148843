import { Field, FieldProps, Formik, FormikActions, FormikErrors, FormikProps } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import * as Yup from 'yup';
import { ISponsorVerzoek } from '../../../../../../shared/src/api/v2/sponsoringv2/verzoek';
import api from '../../../../api';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import Dialoog from '../../../../components/dialogen/Dialoog';
import SponsorCommissieSelectie from '../../../../components/formulier/SponsorCommissieSelectie';
import SponsorDienstSelectie from '../../../../components/formulier/SponsorDienstSelectie';
import IDialoogProps from '../../../../core/IDialoogProps';
import nameOf from '../../../../core/nameOf';
import teksten from '../../../../bedrijfslogica/teksten';
import EnumCombobox from '../../../../components/formulier/EnumCombobox';
import { ESponsorVerzoekStatus } from '..';
import VinkVeld from '../../../../components/formulier/VinkVeld';

interface IDialoogResult {}

interface IProps extends IDialoogProps<IDialoogResult> {
  id: number | null;
  dienstID: number | null | undefined;
}

interface IFormikValues {
  sponDienstID: number | undefined;
  naamAanvrager: string;
  verzoekstatusID: number;
  afgehandeld: boolean;

  commissieID?: number;
  emailAanvrager?: string;
  onderwerp?: string;
  omschrijving?: string;
  notities?: string;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  sponDienstID: 'Sponsordienst',
  emailAanvrager: 'Email aanvrager',
  naamAanvrager: 'Naam aanvrager',
  verzoekstatusID: 'Status',
  afgehandeld: 'Afgehandeld',

  commissieID: 'Commissie',
  onderwerp: 'Onderwerp',
  omschrijving: 'Omschrijving',
  notities: 'Notities',
};

const MuterenDialoog: React.FC<IProps> = observer((props) => {
  const { dialoogIndex, onAnnuleren } = props;
  const [item, setItem] = useState<ISponsorVerzoek | undefined>(undefined);

  const isNieuwItem = (): boolean => {
    return props.id === null;
  };

  const ophalenItem = useCallback(async () => {
    const results = await api.v2.sponsoringv2.verzoek.ophalenVerzoeken({
      filterSchema: {
        filters: [{ naam: 'IDS', data: [props.id] }],
      },
    });
    setItem(results[0]);
  }, [props.id]);

  useEffect(() => {
    ophalenItem();
  }, [ophalenItem]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      const params: ISponsorVerzoek = {
        ID: item?.ID,
        SponDienstID: values.sponDienstID,
        EmailAanvrager: values.emailAanvrager,
        NaamAanvrager: values.naamAanvrager,
        VerzoekstatusID: values.verzoekstatusID,

        CommissieID:
          values?.commissieID != undefined && values.commissieID > 0
            ? values.commissieID
            : undefined,
        Onderwerp: values.onderwerp,
        Omschrijving: values.omschrijving,
        Notities: values.notities,

        LaatsteContactOp: item?.LaatsteContactOp,
        ActieDoor: item?.ActieDoor,
        Contactpersoon_PersID: item?.Contactpersoon_PersID,

        IsAfgewezenOp: item?.IsAfgewezenOp,
        Afgehandeld: values.afgehandeld,
      };

      await api.v2.sponsoringv2.verzoek.toevoegenOfWijzigenVerzoek(params);

      actions.setSubmitting(false);
      props.onSuccess({});
    },
    [item],
  );

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (item == null) {
      const x: IFormikValues = {
        sponDienstID: props.dienstID ?? undefined,
        emailAanvrager: '',
        naamAanvrager: '',
        verzoekstatusID: ESponsorVerzoekStatus.Nieuw,
        afgehandeld: false,

        commissieID: undefined,
        onderwerp: undefined,
        omschrijving: undefined,
        notities: undefined,
      };
      return x;
    }

    const x: IFormikValues = {
      sponDienstID: item.SponDienstID,
      emailAanvrager: item.EmailAanvrager,
      naamAanvrager: item.NaamAanvrager,
      verzoekstatusID: item.VerzoekstatusID,
      afgehandeld: item.Afgehandeld,

      commissieID: item.CommissieID,
      onderwerp: item.Onderwerp,
      omschrijving: item.Omschrijving,
      notities: item.Notities,
    };
    return x;
  }, [item]);

  const handleValidate = useCallback((values: IFormikValues) => {
    const errors: FormikErrors<IFormikValues> = {};
    if (!values.sponDienstID) {
      errors.sponDienstID = teksten.formulier.E_VERPLICHT_VELD({ veldnaam: 'Sponsordienst' });
    }
    if (!values.onderwerp && !values.omschrijving) {
      errors.onderwerp = 'Een onderwerp of omschrijving is verplicht';
      errors.omschrijving = 'Een onderwerp of omschrijving is verplicht';
    }

    return errors;
  }, []);

  return (
    <Dialoog index={dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>{isNieuwItem() ? 'Toevoegen' : 'Wijzigen'} Sponsorverzoek</ModalTitle>
      </ModalHeader>
      {initialValues === null ? (
        <LoadingSpinner />
      ) : (
        <Formik<IFormikValues>
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validate={handleValidate}
          enableReinitialize
          isInitialValid={!isNieuwItem()}
          render={(formikProps: FormikProps<IFormikValues>) => {
            const { submitForm, isValid, isSubmitting } = formikProps;

            return (
              <>
                <ModalBody>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-12 mt-3">
                        <label>{veldnamen.sponDienstID}</label>
                        <Field
                          name={nameOf<IFormikValues>('sponDienstID')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            return (
                              <>
                                <SponsorDienstSelectie
                                  id={field.value}
                                  onChange={(value) => form.setFieldValue(field.name, value)}
                                ></SponsorDienstSelectie>
                              </>
                            );
                          }}
                        />
                      </div>

                      {!isNieuwItem() && (
                        <div className="col-12 mt-3">
                          <label>{veldnamen.commissieID}</label>
                          <Field
                            name={nameOf<IFormikValues>('commissieID')}
                            render={(fieldProps: FieldProps<IFormikValues>) => {
                              const { field, form } = fieldProps;

                              return (
                                <>
                                  <SponsorCommissieSelectie
                                    id={field.value}
                                    dienstID={form.values?.sponDienstID ?? -1}
                                    onChange={(value) => form.setFieldValue(field.name, value)}
                                  ></SponsorCommissieSelectie>
                                </>
                              );
                            }}
                          />
                        </div>
                      )}

                      <div className="col-12 mt-3">
                        <label>{veldnamen.emailAanvrager}</label>
                        <Field
                          name={nameOf<IFormikValues>('emailAanvrager')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field } = fieldProps;

                            return (
                              <>
                                <input type="text" className="form-control" {...field} />
                              </>
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <label>{veldnamen.naamAanvrager}</label>
                        <Field
                          name={nameOf<IFormikValues>('naamAanvrager')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field } = fieldProps;

                            return (
                              <>
                                <input type="text" className="form-control" {...field} />
                              </>
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <label>{veldnamen.onderwerp}</label>
                        <Field
                          name={nameOf<IFormikValues>('onderwerp')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field } = fieldProps;

                            return (
                              <>
                                <input type="text" className="form-control" {...field} />
                              </>
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <label>{veldnamen.omschrijving}</label>
                        <Field
                          name={nameOf<IFormikValues>('omschrijving')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field } = fieldProps;

                            return (
                              <>
                                <textarea {...field} className="form-control" rows={3} />
                              </>
                            );
                          }}
                        />
                      </div>

                      {!isNieuwItem() && (
                        <div className="col-12 mt-3">
                          <label>{veldnamen.notities}</label>
                          <Field
                            name={nameOf<IFormikValues>('notities')}
                            render={(fieldProps: FieldProps<IFormikValues>) => {
                              const { field } = fieldProps;

                              return (
                                <>
                                  <textarea {...field} className="form-control" rows={3} />
                                </>
                              );
                            }}
                          />
                        </div>
                      )}

                      <div className="col-12 mt-3">
                        <label>{veldnamen.verzoekstatusID}</label>
                        <Field
                          name={nameOf<IFormikValues>('verzoekstatusID')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            return (
                              <>
                                <EnumCombobox
                                  enum={ESponsorVerzoekStatus}
                                  geselecteerd={field.value}
                                  onSelectieChange={(x) => form.setFieldValue(field.name, x)}
                                />
                              </>
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <Field
                          name={nameOf<IFormikValues>('afgehandeld')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            return (
                              <>
                                <div className="d-flex align-items-center">
                                  <VinkVeld
                                    aangevinkt={field.value}
                                    onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                  />
                                  <span className="ml-2">Afgehandeld</span>
                                </div>
                              </>
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </ModalBody>

                <ModalFooter className="d-flex flex-row justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    style={{ width: 100 }}
                    disabled={!isValid || isSubmitting}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={onAnnuleren}
                    style={{ width: 100 }}
                    disabled={isSubmitting}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Dialoog>
  );
});

export default MuterenDialoog;
