import api from '../../../..';
import {
  IOphalenTransportregelsParams,
  IOphalenTransportregelsResult,
} from '../../../../../../../shared/src/api/v2/magazijn/inventarisatie';

const transport = {
  ophalenTransportregels: async (
    params: IOphalenTransportregelsParams,
  ): Promise<IOphalenTransportregelsResult> => {
    return await api.post('/v2/magazijn/inventarisatie/transport/ophalen-transportregels', params);
  },
};

export default transport;
