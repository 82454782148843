import { RouteComponentProps, withRouter } from 'react-router-dom';
import React, { useMemo } from 'react';
import api from '../api';

export interface IRouterContext {
  navigeerKlantkaartNaarRelID: (relID: number) => Promise<void>;
  navigeerLeverancierNaarRelID: (relID: number) => Promise<void>;
}

export const RouterContext = React.createContext<IRouterContext>(null as any);

const RouterContextProvider: React.FC<RouteComponentProps> = (props) => {
  const value = useMemo<IRouterContext>(() => {
    return {
      navigeerKlantkaartNaarRelID: async (relID: number) => {
        props.history.push({ pathname: `/klant/${relID}/contracten` });
      },
      navigeerLeverancierNaarRelID: async (relID: number) => {
        props.history.push({
          pathname: `/leverancier/${relID}`,
        });
      },
    };
  }, [props.history.push]);

  return <RouterContext.Provider value={value}>{props.children}</RouterContext.Provider>;
};

export default withRouter(RouterContextProvider);
