import { makeObservable, observable } from 'mobx';
import api from '../api';
import { IOphalenAbonnementenResultElement } from '../../../shared/src/api/v2/aanbod/Abonnement';

class AbonnementenStore {
  public abonnementen: IOphalenAbonnementenResultElement[] | null = null;

  constructor() {
    makeObservable(this, {
      abonnementen: observable,
    });
  }

  public ophalenAbonnementen = async () => {
    const result = await api.v2.abonnement.ophalenAbonnementen({
      filterSchema: {
        filters: [],
      },
    });
    this.abonnementen = result.abonnementen;
  };
}

export default AbonnementenStore;
