import api from '../../..';
import ICheckData from '../../../../../../shared/src/models/ICheckData';
import register from './register';
import facturen from './facturen';
import activeren from './activeren';
import deactiveren from './deactiveren';
import afschrijvingen from './afschrijvingen';

const activaregister = {
  register,
  facturen,
  activeren,
  deactiveren,
  afschrijvingen,
};

export default activaregister;
