import * as React from 'react';
import { useMemo, useState } from 'react';
import { Kolom } from '../';
import { ISponsorEvent } from '../../../../../../shared/src/api/v2/sponsoringv2/event';
import { IUitgeklapteRijProps } from '../../../../components/tabel/ASPTabel/Body/UitgeklapteRij';
import GekoppeldeItems from './GekoppeldeItems';
import Tabblad, { ITabblad } from '../../../../components/layout/Tabblad';
import { observer } from 'mobx-react-lite';
import GekoppeldePersonen from './GekoppeldePersonen';
import GekoppeldePlaatsen from './GekoppeldePlaatsen';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import TabelInspringBlok from '../../../../components/layout/TabelInspringBlok';

export enum ETabblad {
  Items = 1,
  Personen = 2,
  Plaatsen = 3,
}

interface IProps extends IUitgeklapteRijProps<Kolom, ISponsorEvent> {}

const UitgeklapteRij: React.FC<IProps> = (props: IProps) => {
  const [tabblad, setTabblad] = useState<ETabblad>(ETabblad.Items);

  const tabbladen = useMemo<ITabblad<ETabblad>[]>(
    () => [
      {
        id: ETabblad.Items,
        label: 'Items',
        content: ItemsTab,
      },
      {
        id: ETabblad.Personen,
        label: 'Personen',
        content: PersonenTab,
      },
      {
        id: ETabblad.Plaatsen,
        label: 'Plaatsen',
        content: PlaatsenTab,
      },
    ],
    [tabblad],
  );

  const row = props.regel;
  const detail = useMemo(() => {
    return (
      <>
        <div className="d-flex flex-fill">
          <TabelInspringBlok></TabelInspringBlok>
          <Tabblad
            geselecteerd={tabblad}
            onSelectieChange={(id) => {
              setTabblad(id);
            }}
            tabbladen={tabbladen}
            options={{
              tabbladComponentProps: props,
            }}
          />
        </div>
      </>
    );
  }, [row, tabblad]);

  return <>{detail}</>;
};

const ItemsTab: React.FC<IProps> = observer((props) => {
  return <GekoppeldeItems koppelBasisID={props.regel.ID!}></GekoppeldeItems>;
});

const PersonenTab: React.FC<IProps> = observer((props) => {
  return <GekoppeldePersonen koppelBasisID={props.regel.ID!}></GekoppeldePersonen>;
});

const PlaatsenTab: React.FC<IProps> = observer((props) => {
  return <GekoppeldePlaatsen koppelBasisID={props.regel.ID!}></GekoppeldePlaatsen>;
});

export default UitgeklapteRij;
