import {
  IOphalenCommunicatieItemsParams,
  IOphalenCommunicatieItemsResult,
} from '../../../../../shared/src/api/v2/communicatie';
import api from '../../index';

const communicatie = {
  ophalenCommunicatieItems: async (
    params: IOphalenCommunicatieItemsParams,
  ): Promise<IOphalenCommunicatieItemsResult> => {
    return await api.post('v2/communicatie/ophalen-communicatie-items', params);
  },
};

export default communicatie;
