import React, { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { ModalBody, ModalFooter, ModalTitle } from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/ModalHeader';
import IDialoogProps from '../../../core/IDialoogProps';
import Dialoog from '../../dialogen/Dialoog';
import Tabblad, { ITabblad } from '../../layout/Tabblad';
import SelecterenTabblad, { IFormikValues } from './SelecterenTabblad';
import api from '../../../api';
import { ETekstPrecisie } from '../../formulier/TekstPrecisieSelectie';
import RecentTabblad from './RecentTabblad';

export interface IServiceopdrachtSelectieDialoogResult {
  servOpdID: number;
}

export enum ETabblad {
  Selecteren,
  Recent,
}

export interface IKoppelOpties {
  defaultSelecterenValues?: IFormikValues;
}

export const defaultSelecterenValues: IFormikValues = {
  referentiecode: '',
  referentiecodeTekstprecisie: ETekstPrecisie.Exact,
  postcode: '',
  typenaam: '',
  typenaamTekstprecisie: ETekstPrecisie.StartMet,
  alleenNietAfgehandeld: true,
  opdrachtnummer: '',
  opdrachtnummerTekstprecisie: ETekstPrecisie.Ongeveer,
};

const defaultKoppelOpties: IKoppelOpties = {
  defaultSelecterenValues,
};

interface IProps extends IDialoogProps<IServiceopdrachtSelectieDialoogResult> {
  defaultTabblad?: ETabblad;
  koppelOpties?: IKoppelOpties;
}

export interface IServiceopdrachtSelectieDialoogContext {
  koppelOpties: IKoppelOpties;
  selecterenValues: IFormikValues;
  setSelecterenValues: Dispatch<SetStateAction<IFormikValues>>;
  onGekozen: (relID: number) => void;
}

export const ServiceopdrachtSelectieDialoogContext = React.createContext<
  IServiceopdrachtSelectieDialoogContext
>(null as any);

const ServiceopdrachtSelectieDialoog: React.FC<IProps> = (props) => {
  const { children, onAnnuleren, onSuccess, open } = props;

  const [tabblad, setTabblad] = useState<ETabblad>(
    props.defaultTabblad === undefined ? ETabblad.Selecteren : props.defaultTabblad,
  );

  const tabbladen = useMemo<ITabblad<ETabblad>[]>(
    () => [
      {
        id: ETabblad.Selecteren,
        label: 'Selecteren',
        content: SelecterenTabblad,
      },
      {
        id: ETabblad.Recent,
        label: 'Recent',
        content: RecentTabblad,
      },
    ],
    [onSuccess],
  );

  const koppelOpties = useMemo(() => ({ ...defaultKoppelOpties, ...props.koppelOpties }), [
    props.koppelOpties,
  ]);

  const [selecterenValues, setSelecterenValues] = useState(
    useMemo<IFormikValues>(() => koppelOpties.defaultSelecterenValues!, [koppelOpties]),
  );

  const serviceopdrachtSelectieDialoogContext = useMemo<
    IServiceopdrachtSelectieDialoogContext
  >(() => {
    return {
      koppelOpties,
      selecterenValues,
      setSelecterenValues,
      onGekozen: async (servOpdID) => {
        const result = await api.v2.service.ophalenOpdrachten({
          filterSchema: {
            filters: [
              {
                naam: 'IDS',
                data: [servOpdID],
              },
            ],
          },
        });
        const opdracht = result.opdrachten[0]!;
        props.onSuccess({ servOpdID });
      },
    };
  }, [koppelOpties, selecterenValues, setSelecterenValues]);

  return (
    <ServiceopdrachtSelectieDialoogContext.Provider value={serviceopdrachtSelectieDialoogContext}>
      <Dialoog modalProps={{ size: 'xl' }} index={props.dialoogIndex ?? 0}>
        <ModalHeader>
          <ModalTitle>Serviceopdracht selecteren</ModalTitle>
        </ModalHeader>
        <ModalBody className="p-0">
          <Tabblad
            geselecteerd={tabblad}
            onSelectieChange={(id) => setTabblad(id)}
            tabbladen={tabbladen}
          />
        </ModalBody>
        <ModalFooter className="d-flex justify-content-start mt-2">
          <button className="btn btn-secondary" style={{ width: 100 }} onClick={onAnnuleren}>
            Annuleren
          </button>
        </ModalFooter>
      </Dialoog>
    </ServiceopdrachtSelectieDialoogContext.Provider>
  );
};

export default ServiceopdrachtSelectieDialoog;
