import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IOphalenOpdrachtwijzenResultElement } from '../../../../../../shared/src/api/v2/dienst';
import api from '../../../../api';
import { Field, FieldProps } from 'formik';
import nameof from '../../../../core/nameOf';
import FormikVeldFout from '../../../../components/formulier/FormikVeldFout';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import Combobox from '../../../../components/formulier/Combobox';
import VinkVeld from '../../../../components/formulier/VinkVeld';
import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  actief: Yup.boolean(),
  naam: Yup.string().nullable(),
  naamEnum: Yup.string().nullable(),
  opdWijzeID: Yup.number().nullable(),
  parameters: Yup.string().nullable(),
});

export interface IFormikValues {
  actief: boolean;
  naam: string;
  naamEnum: string;
  opdWijzeID: number | null;
  parameters: string;
}

export const veldnamen: Record<keyof IFormikValues, string> = {
  actief: 'Actief',
  naam: 'Naam',
  naamEnum: 'NaamEnum',
  opdWijzeID: 'Standaard opdrachtwijze',
  parameters: 'Parameters',
};

interface IProps {}

const DienstFormulier: React.FC<IProps> = (props) => {
  const [opdrachtWijzen, setOpdrachtWijzen] = useState<
    IOphalenOpdrachtwijzenResultElement[] | null
  >(null);

  const ophalenOpdrachtwijzen = useCallback(async () => {
    const opdrachtwijzenResult = await api.v2.dienst.ophalenOpdrachtwijzen({
      filterSchema: { filters: [] },
    });

    setOpdrachtWijzen(opdrachtwijzenResult.opdrachtwijzen);
  }, []);

  useEffect(() => {
    ophalenOpdrachtwijzen();
  }, []);

  return (
    <div className="p-3">
      <div className="row mb-3">
        <div className="col-6">
          <label>{veldnamen.naam}</label>
          <Field
            name={nameof<IFormikValues>('naam')}
            render={(x: FieldProps<IFormikValues>) => (
              <>
                <input className="form-control" {...x.field} />
                <FormikVeldFout fieldProps={x} />
              </>
            )}
          />
        </div>
        <div className="col-6">
          <label>{veldnamen.naamEnum}</label>
          <Field
            name={nameof<IFormikValues>('naamEnum')}
            render={(x: FieldProps<IFormikValues>) => (
              <>
                <input className="form-control" {...x.field} />
                <FormikVeldFout fieldProps={x} />
              </>
            )}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-12">
          <label>{veldnamen.opdWijzeID}</label>
          <Field
            name={nameof<IFormikValues>('opdWijzeID')}
            render={(fieldProps: FieldProps<IFormikValues>) => {
              const { field, form } = fieldProps;

              if (opdrachtWijzen === null) {
                return <LoadingSpinner />;
              }

              return (
                <div>
                  <Combobox
                    geselecteerd={field.value}
                    opties={opdrachtWijzen.map((opdrachtwijze) => {
                      return {
                        id: opdrachtwijze.OpdWijzeID,
                        label: opdrachtwijze.Naam,
                      };
                    })}
                    onSelectieChange={(x) => form.setFieldValue(field.name, x)}
                  />
                </div>
              );
            }}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-12">
          <label>{veldnamen.parameters}</label>
          <Field
            name={nameof<IFormikValues>('parameters')}
            render={(fieldProps: FieldProps<IFormikValues>) => {
              const { field, form } = fieldProps;
              return <textarea {...field} className="form-control" rows={2} />;
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 d-flex">
          <Field
            name={nameof<IFormikValues>('actief')}
            render={(fieldProps: FieldProps<IFormikValues>) => {
              const { field, form } = fieldProps;
              return (
                <VinkVeld
                  aangevinkt={field.value}
                  onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                />
              );
            }}
          />
          <label className="ml-2">{veldnamen.actief}</label>
        </div>
      </div>
    </div>
  );
};

export default DienstFormulier;
