import React, { useCallback, useEffect, useMemo, useState, useContext } from 'react';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import { ITabbladProps } from '../../Aanbod';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  DXTableCheckboxComponent,
  DXTableToggleCellComponent,
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import useUrlState from '../../../../core/useUrlState';
import api from '../../../../api';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  VirtualTable,
  TableEditColumn,
  Table,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  RowDetailState,
  SelectionState,
  EditingState,
  DataTypeProvider,
  SortingState,
  IntegratedSorting,
} from '@devexpress/dx-react-grid';
import { IconVerwijderen, IconToevoegen } from '../../../../components/Icons';
import { RootStoreContext } from '../../../../stores/RootStore';
import _ from 'lodash';
import DetailComp from '../DetailComp';
import { IOphalenTypenParametersResultElement } from '../../../../../../shared/src/api/v2/inkoop/parameters';
import { IOphalenProducttypenResultElement } from '../../../../../../shared/src/api/v2/product/type';
import { EResultType } from '../../../../stores/CheckStore';
import SelectieVak from '../../../../components/SelectieVak';
import nameof from '../../../../core/nameOf';
import FilterBalkV2, {
  IFilter,
  IFilterData,
  maakFilterSchema,
} from '../../../../components/FilterBalkV2';
import MenuLayout from '../../../../components/MenuLayout';
import {
  IOphalenMutatiesResult,
  IOphalenMutatiesResultElement,
} from '../../../../../../shared/src/api/v2/magazijn/mutatie';
import nameOf from '../../../../core/nameOf';
import { format } from 'date-fns';
import { Helmet } from 'react-helmet';

interface IProps extends ITabbladProps, RouteComponentProps {}

enum EFilter {
  IsActief = 'IS_ACTIEF',
}

const geenData = {
  noData: 'Geen parameters',
};

export interface IRegel extends IOphalenMutatiesResultElement {}

const InslagMutatiesTab: React.FC<IProps> = (props) => {
  const { checkStore, instellingStore } = useContext(RootStoreContext);

  const [mutaties, setMutaties] = useState<IRegel[] | null>(null);

  // const [filterSchema, setFilterSchema] = useState(maakFilterSchema(urlState.filterdata));

  const keyExtractor = useCallback((row: IRegel) => row.ID, []);

  const ophalenMutaties = useCallback(async () => {
    const mutatiesResult = await api.v2.magazijn.mutatie.ophalenMutaties({
      filterSchema: { filters: [{ naam: 'MUTATIESOORT_NAAM_ENUMS', data: ['INKOOP'] }] },
      paginatie: { index: 0, aantal: 100 },
    });

    const mutaties = mutatiesResult.mutaties.map((x) => {
      return { ...x };
    });

    setMutaties(mutaties);
  }, []);

  useEffect(() => {
    ophalenMutaties();
  }, [ophalenMutaties]);

  // const filters = useMemo<IFilter<EFilter>[]>(
  //   () => [
  //     {
  //       naam: EFilter.IsActief,
  //       altijdWeergevenInBalk: true,
  //       weergave: (weergaveProps) => {
  //         return (
  //           <div className="d-flex align-items-center">
  //             <span>Alleen actieve parameters</span>
  //           </div>
  //         );
  //       },
  //     },
  //   ],
  //   [],
  // );

  const kolommen = useMemo<TypedColumn<IRegel>[]>(
    () => [
      {
        name: 'Mutatiedatum',
        title: 'Datum',
      },
      {
        name: 'Aantal',
        title: 'Aantal',
      },
      {
        name: '__merk' as any,
        title: 'Merk',
      },
      {
        name: '__type' as any,
        title: 'Type',
      },
      {
        name: '__besteldType' as any,
        title: 'Besteld type',
      },
      {
        name: '__soort' as any,
        title: 'Categorie',
      },
      {
        name: '__referentie' as any,
        title: 'Ink.referentie',
      },
      {
        name: '__inkoopdienst' as any,
        title: 'Inkoopdienst',
      },
      {
        name: '__magazijn' as any,
        title: 'Magazijn',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRegel>[]>(
    () => [
      {
        columnName: 'Mutatiedatum',
        width: 135,
      },
      {
        columnName: '__referentie' as any,
        width: 130,
      },
      {
        columnName: 'Aantal',
        width: 90,
      },
      {
        columnName: '__merk' as any,
        width: 120,
      },
      {
        columnName: '__type' as any,
        width: 170,
      },
      {
        columnName: '__soort' as any,
        width: 120,
      },
      {
        columnName: '__magazijn' as any,
        width: 120,
      },
      {
        columnName: '__besteldType' as any,
        width: 170,
      },
      {
        columnName: '__inkoopdienst' as any,
        width: 150,
      },
    ],
    [],
  );

  return (
    <>
      <Helmet>
        <title>Inslagmutaties</title>
      </Helmet>
      <MenuLayout
        // menu={
        //   <div className="d-flex flex-fill align-items-center">
        //     {/* <button
        //       className="btn btn-sm btn-light d-flex align-items-center"
        //       style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
        //       onClick={async () => {
        //         // setUrlStateSync('toevoegenDialoogState', {});
        //       }}
        //     >
        //       <IconToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
        //       <span className="ml-2">Toevoegen</span>
        //     </button> */}

        //     {/* <div className="d-flex flex-fill">
        //       <FilterBalkV2
        //         filters={filters}
        //         filterData={urlState.filterdata}
        //         onFilterDataChange={(x) => setUrlStateSync('filterdata', x)}
        //         onFilterSchemaChange={setFilterSchema}
        //       />
        //     </div> */}
        //   </div>
        // }
        body={
          <>
            {mutaties === null ? (
              <div className="d-flex flex-fill align-items-center justify-content-center">
                <LoadingSpinner />
              </div>
            ) : (
              <GridStyleWrapper height="calc(100vh - 150px)">
                <Grid columns={kolommen} getRowId={keyExtractor} rows={mutaties}>
                  <DataTypeProvider
                    for={[nameOf<IRegel>('Mutatiedatum')]}
                    formatterComponent={(formatterProps) => {
                      const rij: IRegel = formatterProps.row;
                      return <span>{format(new Date(rij.Mutatiedatum!), 'dd-MM-yyyy')}</span>;
                    }}
                  />

                  <DataTypeProvider
                    for={['__referentie']}
                    formatterComponent={(formatterProps) => {
                      const rij: IRegel = formatterProps.row;
                      return <span>{rij.inkoopopdracht!.Referentie}</span>;
                    }}
                  />

                  <DataTypeProvider
                    for={['__merk']}
                    formatterComponent={(formatterProps) => {
                      const rij: IRegel = formatterProps.row;
                      return <span>{rij.inkoopopdracht!.producttype.Merknaam}</span>;
                    }}
                  />

                  <DataTypeProvider
                    for={['__type']}
                    formatterComponent={(formatterProps) => {
                      const rij: IRegel = formatterProps.row;
                      return <span>{rij.producttype!.Typenaam}</span>;
                    }}
                  />

                  <DataTypeProvider
                    for={['__besteldType']}
                    formatterComponent={(formatterProps) => {
                      const rij: IRegel = formatterProps.row;
                      const typeID = rij.producttype!.TypeID;
                      const besteldTypeID = rij.inkoopopdracht!.producttype.TypeID;
                      if (typeID === besteldTypeID) {
                        return <span></span>;
                      }
                      return <span>{rij.inkoopopdracht!.producttype.Typenaam}</span>;
                    }}
                  />

                  <DataTypeProvider
                    for={['__soort']}
                    formatterComponent={(formatterProps) => {
                      const rij: IRegel = formatterProps.row;

                      return <span>{rij.inkoopopdracht!.producttype.ProductsoortnaamKort}</span>;
                    }}
                  />

                  <DataTypeProvider
                    for={['__magazijn']}
                    formatterComponent={(formatterProps) => {
                      const rij: IRegel = formatterProps.row;
                      return <span>{rij.magazijn.NaamKort}</span>;
                    }}
                  />

                  <DataTypeProvider
                    for={['__inkoopdienst']}
                    formatterComponent={(formatterProps) => {
                      const rij: IRegel = formatterProps.row;

                      return <span>{rij.inkoopopdracht!.dienst.relatie!.weergavenaam}</span>;
                    }}
                  />

                  <EditingState
                    onCommitChanges={async (changes) => {
                      if (changes.deleted === undefined) {
                        return;
                      }
                    }}
                    onEditingRowIdsChange={(rowIds) => {
                      const id = rowIds[rowIds.length - 1] as number;
                      // setUrlStateSync('wijzigenDialoogState', { id });
                    }}
                  />

                  {/* <SelectionState
                    selection={urlState.selectie}
                    onSelectionChange={(x) => setUrlStateSync('selectie', x as number[])}
                  /> */}
                  <SortingState defaultSorting={[]} />
                  <IntegratedSorting />
                  <VirtualTable messages={geenData} />

                  {/* <TableEditColumn
                    width={35}
                    showEditCommand
                    cellComponent={DXTableEditColumnCellComponent}
                    commandComponent={DXTableEditColumnCommandComponent}
                  /> */}

                  <RowDetailState defaultExpandedRowIds={[]} />
                  {/* <TableRowDetail
                    contentComponent={(props) => <DetailComp {...props} zonderCorrigeren />}
                    toggleCellComponent={DXTableToggleCellComponent}
                  /> */}
                  {/* <TableSelection cellComponent={DXTableCheckboxComponent} /> */}
                  <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
                  <TableHeaderRow showSortingControls />
                  {/* <TableRowDetail contentComponent={opdrachtDetailComp} /> */}
                </Grid>
              </GridStyleWrapper>
            )}
          </>
        }
      />
    </>
  );
};

export default withRouter(InslagMutatiesTab);
