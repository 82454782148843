import {
  DataTypeProvider,
  EditingState,
  IntegratedSorting,
  RowDetailState,
  SelectionState,
  SortingState,
  VirtualTable as VirtualTableBase,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import { format } from 'date-fns';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { IOphalenVoorstellenResultElement } from '../../../../../shared/src/api/v2/sponsoring/voorstel';
import api from '../../../api';
import { Kleur } from '../../../bedrijfslogica/constanten';
import { ETekstPrecisie } from '../../../components/formulier/TekstPrecisieSelectie';
import LoadingSpinner from '../../../components/Gedeeld/LoadingSpinner';
import { IconToevoegen, IconVerwijderen } from '../../../components/Icons';
import MenuLayout from '../../../components/MenuLayout';
import RelatieVisualisatie from '../../../components/personalia/RelatieVisualisatie';
import RelatieKoppelComponent from '../../../components/RelatieKoppelComponent';
import nameof from '../../../core/nameOf';
import useUrlState from '../../../core/useUrlState';
import {
  DXTableCheckboxComponent,
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
  DXTableToggleCellComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../helpers/dxTableGrid';

import NieuwVoorstelDialoog from './NieuwVoorstelDialoog';
import WijzigenVoorstelDialoog from './WijzigenVoorstelDialoog';
import Detail from './Detail';

interface IProps extends RouteComponentProps<{}> {}

interface INieuwVoorstelDialoogState {}
interface IWijzigenVoorstelDialoogState {
  id: number;
}

interface IUrlState {
  selectie: number[];
  nieuwVoorstelDialoogState: INieuwVoorstelDialoogState | null;
  wijzigenVoorstelDialoogState: IWijzigenVoorstelDialoogState | null;
}

const defaultUrlState: IUrlState = {
  selectie: [],
  nieuwVoorstelDialoogState: null,
  wijzigenVoorstelDialoogState: null,
};

export interface IRow extends IOphalenVoorstellenResultElement {}

const Voorstellen: React.FC<IProps> = (props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);

  const [voorstellen, setVoorstellen] = useState<IRow[] | null>(null);

  const [nieuwVerzoekDialoogOpen, setNieuwVerzoekDialoogOpen] = useState<boolean>(false);

  const ophalenVoorstellen = useCallback(async () => {
    const voorstellenResult = await api.v2.sponsoring.voorstel.ophalenVoorstellen({
      filterSchema: {
        filters: [].filter((x) => x !== null),
      },
    });
    const voorstellenGesorteerd = _.orderBy(voorstellenResult.voorstellen, ['Datum'], ['desc']);

    setVoorstellen(voorstellenGesorteerd);
  }, []);

  useEffect(() => {
    ophalenVoorstellen();
  }, [ophalenVoorstellen]);

  const keyExtractor = useCallback((row: IRow) => row.ID, []);
  const kolommen = useMemo<TypedColumn<IRow>[]>(
    () => [
      {
        name: 'Datum',
        title: 'Datum',
      },
      {
        name: 'NaamAanvrager',
        title: 'Aanvrager',
      },
      {
        name: 'Onderwerp',
        title: 'Onderwerp',
      },
      {
        name: 'Periode',
        title: 'Periode',
      },
      {
        name: '__relatie' as any,
        title: 'Relatie',
      },
      {
        name: 'Notities',
        title: 'Notities',
      },
      {
        name: 'InternInitiatief',
        title: 'Ext/Int',
      },
      {
        name: 'Afgehandeld',
        title: 'Afg.',
      },
      {
        name: 'RecordGewijzigd',
        title: 'Laatst gewijzigd',
      },
    ],
    [],
  );
  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRow>[]>(
    () => [
      {
        columnName: 'Datum',
        width: 125,
      },
      {
        columnName: 'Onderwerp',
        width: 300,
      },
      {
        columnName: 'NaamAanvrager',
        width: 200,
      },
      {
        columnName: 'Periode',
        width: 150,
      },
      {
        columnName: '__relatie' as any,
        width: 200,
      },
      {
        columnName: 'Notities',
        width: 350,
      },
      {
        columnName: 'InternInitiatief',
        width: 100,
      },
      {
        columnName: 'Afgehandeld',
        width: 80,
      },
      {
        columnName: 'RecordGewijzigd',
        width: 125,
      },
    ],
    [],
  );
  const kolomExtensies: VirtualTableBase.ColumnExtension[] = useMemo(() => {
    return [
      {
        columnName: 'Bedrag',
        align: 'right',
      },
    ];
  }, []);

  return (
    <>
      <MenuLayout
        menu={
          <>
            <div className="d-flex">
              <button
                className="btn btn-sm btn-light d-flex align-items-center"
                style={{
                  border: `1px solid ${Kleur.LichtGrijs}`,
                }}
                onClick={() => {
                  setUrlStateSync('nieuwVoorstelDialoogState', {});
                }}
              >
                <IconToevoegen
                  style={{
                    width: 16,
                    height: 16,
                    fill: Kleur.Grijs,
                  }}
                />
                <span className="ml-2">Nieuw voorstel</span>
              </button>
              <button
                className="btn btn-sm btn-light d-flex align-items-center ml-2"
                style={{
                  border: `1px solid ${Kleur.LichtGrijs}`,
                }}
                disabled={false}
                onClick={() => {}}
              >
                <IconVerwijderen
                  style={{
                    width: 16,
                    height: 16,
                    fill: Kleur.Grijs,
                  }}
                />
                <span className="ml-2">Verwijderen</span>
              </button>
            </div>
          </>
        }
        body={
          <div>
            {voorstellen === null ? (
              <LoadingSpinner />
            ) : (
              <GridStyleWrapper height="calc(100vh - 88px)">
                <Grid rows={voorstellen} columns={kolommen} getRowId={keyExtractor}>
                  <DataTypeProvider
                    for={[nameof<IRow>('Datum')]}
                    formatterComponent={(formatterProps) => {
                      const rij: IRow = formatterProps.row;
                      return (
                        <span>
                          {rij.Datum !== null ? format(new Date(rij.Datum), 'dd-MM-yyyy') : ''}
                        </span>
                      );
                    }}
                  />

                  <DataTypeProvider
                    for={[nameof<IRow>('InternInitiatief')]}
                    formatterComponent={(formatterProps) => {
                      const rij: IRow = formatterProps.row;
                      return <span>{rij.InternInitiatief ? 'Int' : 'Ext'}</span>;
                    }}
                  />

                  <DataTypeProvider
                    for={[nameof<IRow>('Onderwerp')]}
                    formatterComponent={(formatterProps) => {
                      const rij: IRow = formatterProps.row;
                      return <span>{rij.Onderwerp !== null ? rij.Onderwerp : rij.Inhoud}</span>;
                    }}
                  />

                  <DataTypeProvider
                    for={[nameof<IRow>('Afgehandeld')]}
                    formatterComponent={(formatterProps) => {
                      return <span>{formatterProps.value ? 'Ja' : 'Nee'}</span>;
                    }}
                  />

                  {/* <DataTypeProvider
                  for={['__relatie']}
                  formatterComponent={(formatterProps) => {
                    const rij = formatterProps.row as IRow;
                    if (rij.RelID === null) {
                      return <span></span>;
                    }
                    return <RelatieVisualisatie relID={rij.RelID} />;
                  }}
                /> */}

                  <DataTypeProvider
                    for={['__relatie']}
                    formatterComponent={(formatterProps) => {
                      const rij = formatterProps.row as IRow;
                      return (
                        <RelatieKoppelComponent
                          relID={rij.RelID}
                          onRelIDChange={async (relID) => {
                            await api.v2.sponsoring.voorstel.koppelenRelatie({
                              id: rij.ID,
                              relID,
                            });
                            ophalenVoorstellen();
                          }}
                          // koppelOpties={{
                          //   defaultSelecterenValues: {
                          //     ...defaultSelecterenValues,
                          //     email: rij.Email === null ? defaultSelecterenValues.email : rij.Email,
                          //     emailTekstprecisie:
                          //       rij.Email === null
                          //         ? defaultSelecterenValues.emailTekstprecisie
                          //         : ETekstPrecisie.Exact,
                          //   },
                          // }}
                        />
                      );
                      // return <RelatieVisualisatie relID={rij.RelID} />;
                    }}
                  />

                  <DataTypeProvider
                    for={['RecordGewijzigd']}
                    formatterComponent={(props) => {
                      const rij: IRow = props.row;
                      return (
                        <span>
                          {rij.RecordGewijzigd !== null
                            ? format(new Date(rij.RecordGewijzigd), 'dd-MM-yyyy HH:mm')
                            : null}
                        </span>
                      );
                    }}
                  />

                  <EditingState
                    onCommitChanges={async (changes) => {
                      if (changes.deleted === undefined) {
                        return;
                      }
                      const deleted = changes.deleted;
                      const id = deleted[deleted.length - 1] as number;
                      // await handleVerwijderen(id);
                    }}
                    onEditingRowIdsChange={(rowIds) => {
                      const id = rowIds[rowIds.length - 1] as number;
                      setUrlStateSync('wijzigenVoorstelDialoogState', { id });
                    }}
                  />

                  <VirtualTable columnExtensions={kolomExtensies} />
                  <TableColumnResizing defaultColumnWidths={kolomBreedtes} />

                  <TableEditColumn
                    width={35}
                    showEditCommand
                    // showDeleteCommand
                    cellComponent={DXTableEditColumnCellComponent}
                    commandComponent={DXTableEditColumnCommandComponent}
                  />

                  <SortingState defaultSorting={[]} />
                  <IntegratedSorting />
                  <TableHeaderRow showSortingControls />
                  <RowDetailState defaultExpandedRowIds={[]} />

                  <TableRowDetail
                    contentComponent={Detail}
                    toggleCellComponent={DXTableToggleCellComponent}
                  />

                  <SelectionState
                    selection={urlState.selectie}
                    onSelectionChange={(value) => setUrlStateSync('selectie', value as number[])}
                  />
                  <TableSelection cellComponent={DXTableCheckboxComponent} />
                </Grid>
              </GridStyleWrapper>
            )}
          </div>
        }
      />
      {urlState.nieuwVoorstelDialoogState !== null && (
        <NieuwVoorstelDialoog
          open
          onSuccess={() => {
            ophalenVoorstellen();
            setUrlStateSync('nieuwVoorstelDialoogState', null);
          }}
          onAnnuleren={() => {
            setUrlStateSync('nieuwVoorstelDialoogState', null);
          }}
        />
      )}
      {urlState.wijzigenVoorstelDialoogState !== null && (
        <WijzigenVoorstelDialoog
          open
          id={urlState.wijzigenVoorstelDialoogState.id}
          onSuccess={() => {
            ophalenVoorstellen();
            setUrlStateSync('wijzigenVoorstelDialoogState', null);
          }}
          onAnnuleren={() => {
            setUrlStateSync('wijzigenVoorstelDialoogState', null);
          }}
        />
      )}
    </>
  );
};

export default Voorstellen;
