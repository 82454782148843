import React, { useMemo, useState, useEffect, useCallback } from 'react';
import * as Yup from 'yup';

/**
 * Format van een enkel tijdvak:
 * e.g.
 * WEL:
 *  - 00:00
 *  - 10:00
 *  - 23:59
 * NIET:
 *  - 24:00
 *  - 0:10
 */
const inputValidatie = Yup.string().matches(/(([0-1]\d)|(2[0-3])):([0-5]\d)/, 'Onjuist formaat');

export const defaultValue = '00:00';

export interface ITijdVeldWaarde {
  tekst: string;
  resultaat: string | null;
}

interface IProps {
  waarde: ITijdVeldWaarde;
  onWaardeChange: (waarde: ITijdVeldWaarde) => void;
}

const TijdVeld: React.FC<IProps> = (props) => {
  const { waarde, onWaardeChange } = props;

  const handleVerhogen = useCallback(() => {
    if (waarde.resultaat === null) {
      return;
    }

    const [hoursTekst, minutesTekst] = waarde.resultaat.split(':');
    let hours = Number(hoursTekst);
    let minutes = Number(minutesTekst);

    minutes++;
    if (minutes === 60) {
      minutes = 0;
      hours++;
    }
    if (hours === 24) {
      hours = 0;
    }

    const newValue = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
    onWaardeChange({
      tekst: newValue,
      resultaat: newValue,
    });
  }, [waarde, onWaardeChange]);

  const handleVerlagen = useCallback(() => {
    if (waarde.resultaat === null) {
      return;
    }

    const [hoursTekst, minutesTekst] = waarde.resultaat.split(':');
    let hours = Number(hoursTekst);
    let minutes = Number(minutesTekst);

    minutes--;
    if (minutes === -1) {
      minutes = 59;
      hours--;
    }
    if (hours === -1) {
      hours = 23;
    }

    const newValue = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
    onWaardeChange({
      tekst: newValue,
      resultaat: newValue,
    });
  }, [waarde, onWaardeChange]);

  return (
    <input
      type="text"
      value={waarde.tekst}
      className="form-control"
      style={{ width: 60 }}
      onChange={(ev) => {
        const nieuweWaarde = ev.target.value.trim().replace('.', ':');
        onWaardeChange({
          tekst: nieuweWaarde,
          resultaat: inputValidatie.isValidSync(nieuweWaarde) ? nieuweWaarde : null,
        });
      }}
      onKeyUp={(ev) => {
        if (ev.key === 'ArrowDown') {
          handleVerlagen();
        } else if (ev.key === 'ArrowUp') {
          handleVerhogen();
        }
      }}
      placeholder={defaultValue}
    />
  );
};

export default TijdVeld;
