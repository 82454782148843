import React, { useContext, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { observer } from 'mobx-react-lite';
import Tabblad, { ITabblad } from '../../../components/layout/Tabblad';
import Bank from './Bank';
import Inkoop from './Inkoop';
import Verkoop from './Verkoop';
import Memoriaal from './Memoriaal';
import Export from './Export';
import useUrlTabblad from '../../../core/useUrlTabblad';
import NietGeexporteerd from './NietGeexporteerd';

interface IProps extends RouteComponentProps {}

export enum ETabblad {
  Bank = 1,
  Inkoop = 2,
  Verkoop = 3,
  Memoriaal = 4,
  Export = 5,
  NietGeexporteerd = 6,
}

const pathMap: Record<ETabblad, string> = {
  [ETabblad.Bank]: '/bank',
  [ETabblad.Inkoop]: '/inkoop',
  [ETabblad.Verkoop]: '/verkoop',
  [ETabblad.Memoriaal]: '/memoriaal',
  [ETabblad.Export]: '/export',
  [ETabblad.NietGeexporteerd]: '/niet-geexporteerd',
};

const Boekingen: React.FC<IProps> = observer((props) => {
  const { tabblad, onChange: onChangeTabblad } = useUrlTabblad<ETabblad>(
    props,
    pathMap,
    ETabblad.Inkoop,
  );

  const tabbladen = useMemo<ITabblad<ETabblad>[]>(
    () => [
      {
        id: ETabblad.Inkoop,
        label: 'Inkoop',
        content: Inkoop,
      },
      {
        id: ETabblad.Verkoop,
        label: 'Verkoop',
        content: Verkoop,
      },
      {
        id: ETabblad.Memoriaal,
        label: 'Memoriaal',
        content: Memoriaal,
      },
      {
        id: ETabblad.Bank,
        label: 'Bank',
        content: Bank,
      },
      {
        id: ETabblad.Export,
        label: 'Export',
        content: Export,
      },
      {
        id: ETabblad.NietGeexporteerd,
        label: 'Niet geexporteerd',
        content: NietGeexporteerd,
      },
    ],
    [],
  );

  return (
    <Tabblad
      geselecteerd={tabblad}
      onSelectieChange={(tabblad) => onChangeTabblad(tabblad)}
      tabbladen={tabbladen}
    />
  );
});

export default withRouter(Boekingen);
