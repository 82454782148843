import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  DataTypeProvider,
  EditingState,
  TableRowDetail as TableRowDetailBase,
} from '@devexpress/dx-react-grid';
import TabelInspringBlok from '../../../../../components/layout/TabelInspringBlok';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../../../../models/IRemoteData';
import {
  IOphalenProductmodelAlternatievenResult,
  IProductmodelAlternatief,
} from '../../../../../../../shared/src/api/v2/product/model/alternatieven';
import { IProductmodel } from '../index';
import api from '../../../../../api';
import LoadingSpinner from '../../../../../components/Gedeeld/LoadingSpinner';
import {
  DXCommandComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../../helpers/dxTableGrid';
import {
  Grid,
  Table,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-bootstrap4';
import DragHandle from '../../../../../components/tabel/DragHandle';
import { arrayMove, SortableContainer, SortableElement } from 'react-sortable-hoc';
import { RootStoreContext } from '../../../../../stores/RootStore';
import { GlobaleRendererContext } from '../../../../../one-off-components/GlobaleRenderer';
import ProductmodelSelectieDialoog, {
  IProductmodelSelectieDialoogOutput,
} from '../../../../../components/dialogen/ProductmodelSelectieDialoog';
import {
  IOphalenProductmodellenResult,
  IOphalenProductmodellenResultElement,
} from '../../../../../../../shared/src/api/v2/aanbod/productmodel';
import Skeleton from 'react-loading-skeleton';
import { Provider } from '../../../../../components/formulier/MultiComboboxV2';

const DetailComp = (props: TableRowDetailBase.ContentProps) => {
  const row: IProductmodel = props.row;
  const { checkStore } = useContext(RootStoreContext);
  const globaleRenderer = useContext(GlobaleRendererContext);
  const [alternatievenResult, setAlternatievenResult] = useState<
    IRemoteData<IOphalenProductmodelAlternatievenResult>
  >(createPendingRemoteData());
  const ophalenAlternatieven = useCallback(async () => {
    const result = await api.v2.product.model.alternatief.ophalenProductmodelAlternatieven({
      filterSchema: {
        filters: [
          {
            naam: 'PROD_MOD_IDS',
            data: [row.ProdModID],
          },
        ],
      },
      orderSchema: {
        orders: [
          {
            naam: 'PRIO_NR',
            richting: 'ASC',
          },
        ],
      },
    });
    setAlternatievenResult(createReadyRemoteData(result));
  }, [row.ProdModID]);
  useEffect(() => {
    ophalenAlternatieven();
  }, [ophalenAlternatieven]);

  const [productmodellenResult, setProductmodellenResult] = useState<
    IRemoteData<IOphalenProductmodellenResult>
  >(createPendingRemoteData());
  const ophalenProductmodellen = useCallback(async (prodModIDs: number[]) => {
    const result = await api.v2.product.model.ophalenProductmodellen({
      filterSchema: {
        filters: [
          {
            naam: 'IDS',
            data: prodModIDs,
          },
        ],
      },
    });
    setProductmodellenResult(createReadyRemoteData(result));
  }, []);

  useEffect(() => {
    if (alternatievenResult.state === ERemoteDataState.Pending) {
      return;
    }

    const prodModIDs = alternatievenResult.data!.alternatieven.map((x) => x.Alternatief_ProdModID);
    const moetOphalen =
      productmodellenResult.state === ERemoteDataState.Pending ||
      !prodModIDs.every((x) => productmodellenResult.data!.modellen.some((y) => y.ProdModID === x));
    if (moetOphalen) {
      ophalenProductmodellen(prodModIDs);
    }
  }, [alternatievenResult]);

  const keyExtractor = useCallback((row: IProductmodelAlternatief) => row.ID, []);
  const kolommen = useMemo<TypedColumn<IProductmodelAlternatief>[]>(
    () => [
      {
        name: '__dragHandle' as any,
        title: ' ',
      },
      {
        name: '__code',
        title: 'Code',
      },
      {
        name: '__naam',
        title: 'Naam',
      },
      {
        name: '__kenmerk',
        title: 'Kenmerk',
      },
      {
        name: '__actief',
        title: 'Actief',
      },
      {
        name: 'PrioNr',
        title: 'Prio nr',
      },
    ],
    [],
  );
  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IProductmodelAlternatief>[]>(
    () => [
      {
        columnName: '__dragHandle' as any,
        width: 50,
      },
      {
        columnName: '__code',
        width: 150,
      },
      {
        columnName: '__naam',
        width: 350,
      },
      {
        columnName: '__kenmerk',
        width: 350,
      },
      {
        columnName: '__actief',
        width: 100,
      },
      {
        columnName: 'PrioNr',
        width: 100,
      },
    ],
    [],
  );

  return (
    <div className="flex-fill d-flex bg-white">
      <TabelInspringBlok />
      <div className="flex-fill">
        {alternatievenResult.state === ERemoteDataState.Pending ? (
          <LoadingSpinner />
        ) : (
          <GridStyleWrapper height="300px">
            <Grid
              getRowId={keyExtractor}
              rows={alternatievenResult.data!.alternatieven}
              columns={kolommen}
            >
              <DataTypeProvider
                for={['__dragHandle']}
                formatterComponent={(formatterProps) => {
                  return <DragHandle />;
                }}
              />
              <DataTypeProvider
                for={['__code']}
                formatterComponent={(formatterProps) => {
                  const row: IProductmodelAlternatief = formatterProps.row;
                  if (productmodellenResult.state === ERemoteDataState.Pending) {
                    return <Skeleton />;
                  }
                  const prodMod = productmodellenResult.data!.modellen.find(
                    (x) => x.ProdModID === row.Alternatief_ProdModID,
                  );
                  if (prodMod === undefined) {
                    return <Skeleton />;
                  }
                  return <span>{prodMod.Modelcode}</span>;
                }}
              />

              <DataTypeProvider
                for={['__naam']}
                formatterComponent={(formatterProps) => {
                  const row: IProductmodelAlternatief = formatterProps.row;
                  if (productmodellenResult.state === ERemoteDataState.Pending) {
                    return <Skeleton />;
                  }
                  const prodMod = productmodellenResult.data!.modellen.find(
                    (x) => x.ProdModID === row.Alternatief_ProdModID,
                  );
                  if (prodMod === undefined) {
                    return <Skeleton />;
                  }
                  return <span>{prodMod.Modelnaam}</span>;
                }}
              />

              <DataTypeProvider
                for={['__kenmerk']}
                formatterComponent={(formatterProps) => {
                  const row: IProductmodelAlternatief = formatterProps.row;
                  if (productmodellenResult.state === ERemoteDataState.Pending) {
                    return <Skeleton />;
                  }
                  const prodMod = productmodellenResult.data!.modellen.find(
                    (x) => x.ProdModID === row.Alternatief_ProdModID,
                  );
                  if (prodMod === undefined) {
                    return <Skeleton />;
                  }
                  return <span>{prodMod.Kenmerk}</span>;
                }}
              />

              <DataTypeProvider
                for={['__actief']}
                formatterComponent={(formatterProps) => {
                  const row: IProductmodelAlternatief = formatterProps.row;
                  if (productmodellenResult.state === ERemoteDataState.Pending) {
                    return <Skeleton />;
                  }
                  const prodMod = productmodellenResult.data!.modellen.find(
                    (x) => x.ProdModID === row.Alternatief_ProdModID,
                  );
                  if (prodMod === undefined) {
                    return <Skeleton />;
                  }
                  return <span>{prodMod.Actief ? 'Ja' : 'Nee'}</span>;
                }}
              />

              <EditingState
                onCommitChanges={async (changeset) => {
                  if (changeset.deleted !== undefined && changeset.deleted.length > 0) {
                    await api.v2.product.model.alternatief.muterenProductmodelAlternatief({
                      prodModID: row.ProdModID,
                      alternatieveProdModIDs: alternatievenResult
                        .data!.alternatieven.filter((alt) => !changeset.deleted!.includes(alt.ID))
                        .map((x) => x.Alternatief_ProdModID),
                    });
                    await ophalenAlternatieven();
                  }
                }}
                onAddedRowsChange={async () => {
                  const huidigeAlternatievenProdModIDs = alternatievenResult.data!.alternatieven.map(
                    (alt) => alt.Alternatief_ProdModID,
                  );

                  const provider: Provider<
                    any,
                    IOphalenProductmodellenResultElement,
                    null
                  > = async (params) => {
                    const prodModIDsUitsluiten = [row.ProdModID, ...huidigeAlternatievenProdModIDs];
                    const result = await api.v2.aanbod.productmodel.ophalenProductmodellen({
                      filterSchema: {
                        filters: [
                          {
                            naam: 'IDS',
                            data: prodModIDsUitsluiten,
                            inverteren: true,
                          },
                        ],
                      },
                      paginatie: params.paginatie,
                    });

                    const items = result.modellen.reduce(
                      (acc, curr, i) => ({
                        ...acc,
                        [params.paginatie.index + i]: curr,
                      }),
                      params.huidigeBron,
                    );

                    return {
                      items,
                      totaalAantal: result.totaalAantal,
                    };
                  };

                  const output = await globaleRenderer.render<IProductmodelSelectieDialoogOutput>(
                    (renderProps) => (
                      <ProductmodelSelectieDialoog
                        open
                        onSuccess={(r) => renderProps.destroy(r)}
                        onAnnuleren={() => renderProps.destroy()}
                        provider={provider}
                      />
                    ),
                  );
                  if (output === undefined) {
                    return;
                  }

                  await api.v2.product.model.alternatief.muterenProductmodelAlternatief({
                    prodModID: row.ProdModID,
                    alternatieveProdModIDs: [
                      ...alternatievenResult.data!.alternatieven.map(
                        (x) => x.Alternatief_ProdModID,
                      ),
                      output.prodModID,
                    ],
                  });
                  await ophalenAlternatieven();
                }}
              />

              <Table
                rowComponent={({ row, ...restProps }) => {
                  const TableRow = SortableElement(Table.Row);
                  return (
                    <TableRow
                      {...restProps}
                      row={row}
                      index={alternatievenResult.data!.alternatieven.indexOf(row)}
                    />
                  );
                }}
                bodyComponent={({ row, ...restProps }: any) => {
                  const TableBody = SortableContainer(Table.TableBody);
                  return (
                    <TableBody
                      {...restProps}
                      onSortEnd={async (x) => {
                        const oudeAlternatief = alternatievenResult.data!.alternatieven[
                          x.oldIndex
                        ]!;
                        const nieuweAlternatief = alternatievenResult.data!.alternatieven[
                          x.newIndex
                        ]!;

                        // Lokaal wijzigingen direct reflecteren
                        setAlternatievenResult(
                          createReadyRemoteData({
                            alternatieven: arrayMove(
                              alternatievenResult.data!.alternatieven.map((alt, i) => {
                                if (i === x.oldIndex) {
                                  return {
                                    ...alt,
                                    SortNr: nieuweAlternatief.PrioNr,
                                  };
                                } else if (i === x.newIndex) {
                                  return {
                                    ...alt,
                                    SortNr: alternatievenResult.data!.alternatieven[x.oldIndex]
                                      .PrioNr,
                                  };
                                }

                                return alt;
                              }),
                              x.oldIndex,
                              x.newIndex,
                            ),
                          }),
                        );
                        await api.v2.product.model.alternatief.sorterenProductmodelAlternatief({
                          oudID: oudeAlternatief.ID,
                          nieuwID: nieuweAlternatief.ID,
                        });
                        await ophalenAlternatieven();
                      }}
                      useDragHandle
                    />
                  );
                }}
              />
              <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
              <TableHeaderRow />
              <TableEditColumn
                width={65}
                showAddCommand
                showDeleteCommand
                commandComponent={DXCommandComponent}
              />
            </Grid>
          </GridStyleWrapper>
        )}
      </div>
    </div>
  );
};

export default DetailComp;
