import React, { useContext, useMemo } from 'react';
import { IProps } from '@tinymce/tinymce-react/lib/cjs/main/ts/components/Editor';
import { IContext, RelatieContext } from '../index';
import VeldWeergave from '../../../../formulier/VeldWeergave';
import LoadingSpinner from '../../../../Gedeeld/LoadingSpinner';
import { format } from 'date-fns';
import { Grid, Table, TableHeaderRow } from '@devexpress/dx-react-grid-bootstrap4';
import GegevensLayout from '../../../../layout/GegevensLayout';
import FormatteerBedrag from '../../../../MutatieBedrag';
import { Kleur } from '../../../../../bedrijfslogica/constanten';
import { GridStyleWrapper, TypedColumn } from '../../../../../helpers/dxTableGrid';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import RelatieVisualisatie from '../../../../personalia/RelatieVisualisatie';

const AlgemeenTab: React.FC<IProps> = (props) => {
  const { relatie } = useContext<IContext>(RelatieContext);

  return (
    <>
      {relatie === null ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="d-flex flex-column pl-3 pr-3 pb-3">
            <div className="row mt-3">
              <div className="col-12 d-flex">
                <VeldWeergave>
                  <div className="d-flex">
                    <div>{relatie.Relatienummer}</div>
                    <div className="ml-3">
                      <RelatieVisualisatie relID={relatie.RelID} />
                    </div>
                  </div>
                </VeldWeergave>
              </div>

              <div className="col-12 d-flex mt-3">
                <VeldWeergave>
                  <div style={{ minHeight: 100, maxHeight: 300, overflow: 'scroll' }}>
                    {relatie.Notities}
                  </div>
                </VeldWeergave>
              </div>

              <div className="col-12 d-flex mt-3">
                <VeldWeergave>
                  <GegevensLayout
                    labelBreedte="150px"
                    gegevens={[
                      {
                        label: 'Geblokkeerd',
                        waarde: relatie.Geblokkeerd ? 'Ja' : 'Nee',
                      },
                    ]}
                  />
                </VeldWeergave>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AlgemeenTab;
