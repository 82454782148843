import React, { useCallback, useMemo } from 'react';
import MultiComboboxV2, { EnkeleProvider, IRepresentatieProps, Provider } from '../MultiComboboxV2';
import { IOphalenContactpersonenResultElement } from '../../../../../shared/src/api/v2/relatie/contactpersoon';
import api from '../../../api';
import { formatteerPersoonNaam } from '../../../helpers';
import { ASPKolom, EAspKolomBreedteType } from '../../tabel/ASPTabel/types';

interface IProps {
  relPersID: number | null;
  onChange: (relPersID: number | null) => void;
  provider?: Provider<Key, IOphalenContactpersonenResultElement, IOverlayState>;
}

export type Key = 'naam';

export interface IOverlayState {}

const ContactpersoonSelectie = (props: IProps) => {
  const provider = useMemo<Provider<Key, IOphalenContactpersonenResultElement, IOverlayState>>(
    () =>
      props.provider ??
      (async (params) => {
        const result = await api.v2.relatie.ophalenContactpersonen({
          paginatie: params.paginatie,
        });

        const items = result.contactpersonen.reduce(
          (acc, curr, i) => ({
            ...acc,
            [i + params.paginatie.index]: curr,
          }),
          {},
        );

        return {
          totaalAantal: result.totaalAantal,
          items,
        };
      }),
    [props.provider],
  );
  const enkeleProvider = useMemo<EnkeleProvider<number, IOphalenContactpersonenResultElement>>(
    () => async (id: number) => {
      const result = await api.v2.relatie.ophalenContactpersonen({
        filterSchema: {
          filters: [
            {
              naam: 'IDS',
              data: [id],
            },
          ],
        },
      });

      return result.contactpersonen[0];
    },
    [],
  );
  const keyExtractor = useCallback(
    (item: IOphalenContactpersonenResultElement) => (item == null ? -1 : item.ID),
    [],
  );
  const representatieComponent = useCallback(
    (reprProps: IRepresentatieProps<IOphalenContactpersonenResultElement>) => {
      const x = reprProps.entiteit;

      if (x == null) {
        return <span></span>;
      }

      return (
        <span>
          {formatteerPersoonNaam({
            voornaam: x.Voornaam,
            voorletters: x.Voorletters,
            voorvoegsel: x.Voorvoegsel,
            achternaam: x.Achternaam,
            aanhefKort: x.geslacht.AanhefKort,
          })}
        </span>
      );
    },
    [],
  );
  const kolommen = useMemo<ASPKolom<Key, IOphalenContactpersonenResultElement>[]>(
    () => [
      {
        key: 'naam',
        label: 'Naam',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 350,
        renderer: (x) => {
          return formatteerPersoonNaam({
            voornaam: x.Voornaam,
            voorletters: x.Voorletters,
            voorvoegsel: x.Voorvoegsel,
            achternaam: x.Achternaam,
            aanhefKort: x.geslacht.AanhefKort,
          });
        },
      },
    ],
    [],
  );

  return (
    <MultiComboboxV2
      provider={provider}
      enkeleProvider={enkeleProvider}
      keyExtractor={keyExtractor}
      waarde={props.relPersID}
      onChange={props.onChange}
      representatieComponent={representatieComponent}
      kolommen={kolommen}
    />
  );
};

export default ContactpersoonSelectie;
