import React, { useCallback } from 'react';
import { IContentProps } from '../../shared';
import NavigerenPijl from '../../Rechts/NavigerenPijl';
import PersoonVisualisatie from '../../../../components/personalia/PersoonVisualisatie';

const PersoonContent = (props: IContentProps) => {
  const handleNavigeren = useCallback(() => {}, []);

  return (
    <div style={{ paddingLeft: 35, paddingRight: 20 }} className="d-flex flex-column">
      {props.ids.map((persID, i) => (
        <div key={persID} className="p-2 d-flex align-items-center">
          <span className="flex-fill">
            <PersoonVisualisatie persID={persID} />
          </span>
          <NavigerenPijl onClick={handleNavigeren} />
        </div>
      ))}
    </div>
  );
};

export default PersoonContent;
