import React from 'react';
import { IPotentieelOntvangenWerkbonItem } from '../../../../../../shared/src/api/v2/externeData';
import { IRegelBase } from '../..';
import UitlegTooltip from '../../../../components/formulier/UitlegTooltip';
import ServicedienstVisualisatie from '../../../../components/entiteitVisualisaties/ServicedienstVisualisatie';

interface IProps {
  regel: IRegelBase;
  item: IPotentieelOntvangenWerkbonItem;
}

const WerkbonWeergave = (props: IProps) => {
  return (
    <div
      className="flex-fill d-flex align-items-center"
      style={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
      }}
    >
      <span className="mr-2">
        {props.item.ServDienstID === null ? (
          <span className="text-muted">Dienst onbekend</span>
        ) : (
          <ServicedienstVisualisatie servDienstID={props.item.ServDienstID} />
        )}
      </span>
      <UitlegTooltip inhoud="Het nummer zoals bekend bij het servicebedrijf">
        <span className="mr-2 text-muted">#{props.item.OpdrachtnummerExtern ?? '?'}</span>
      </UitlegTooltip>
      <span className="mr-2">-</span>
      {props.item.NaamMonteur !== null && (
        <>
          <span className="text-muted">{props.item.NaamMonteur}</span>
          <span className="ml-2 mr-2">-</span>
        </>
      )}
      {props.item.Werkzaamheden !== null ? (
        <UitlegTooltip
          inhoud={props.item.Werkzaamheden}
          innerContainerProps={{
            className: 'd-flex align-items-center flex-fill',
            style: { maxWidth: '100%' },
          }}
        >
          <span
            className="flex-fill"
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {props.item.Werkzaamheden}
          </span>
        </UitlegTooltip>
      ) : (
        <span className="flex-fill font-italic">Geen werkzaamheden bekend.</span>
      )}
    </div>
  );
};

export default WerkbonWeergave;
