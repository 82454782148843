import api from '../../..';
import {
  IVersturenStornoberichtenParams,
  IVersturenStornoberichtenResult,
  IMarkerenNietVersturenStornoberichtenParams,
  IMarkerenNietVersturenStornoberichtenResult,
} from '../../../../../../shared/src/api/v2/debiteur/stornering';
import ICheckData from '../../../../../../shared/src/models/ICheckData';

const stornering = {
  checkVersturenStornoberichten: async (
    params: IVersturenStornoberichtenParams,
  ): Promise<ICheckData> => {
    return await api.post('/v2/debiteur/stornering/check-versturen-stornoberichten', params);
  },
  versturenStornoberichten: async (
    params: IVersturenStornoberichtenParams,
  ): Promise<IVersturenStornoberichtenResult> => {
    return await api.post('/v2/debiteur/stornering/versturen-stornoberichten', params);
  },
  markerenNietVersturenStornoberichten: async (
    params: IMarkerenNietVersturenStornoberichtenParams,
  ): Promise<IMarkerenNietVersturenStornoberichtenResult> => {
    return await api.post(
      '/v2/debiteur/stornering/markeren-niet-versturen-stornoberichten',
      params,
    );
  },
};

export default stornering;
