import styled from 'styled-components';
import { Kleur } from '../../../bedrijfslogica/constanten';

export const Root = styled.div`
  .hover-only {
    display: none;
  }

  &:hover {
    .hover-only {
      display: initial;
    }
  }
`;

export const IconButton = styled.button`
  background: 0;
  outline: 0;
  border: 0;
  padding: 0;

  svg {
    fill: ${Kleur.Grijs};
  }

  &:hover {
    svg {
      fill: ${Kleur.Blauw};
    }
  }
`;
