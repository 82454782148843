import { observable, makeObservable } from 'mobx';

interface IModalErrorState {
  isOpen: boolean;
  errors: string[];
  warnings: string[];
}

// tslint:disable no-console
class ContractActiesStore {
  public modalErrorState: IModalErrorState | null = null;
  public modalContractAnnulerenState: any = { isOpen: false, cntIDs: [] };
  public geselecteerdeContractenVoorModal: any = [];

  constructor() {
    makeObservable(this, {
      modalErrorState: observable,
      modalContractAnnulerenState: observable,
    });
  }

  public modalErrorSluiten = () => {
    this.modalErrorState = null;
  };

  public modalContractAnnulerenSluiten = () => {
    this.modalContractAnnulerenState = { isOpen: false, cntIDs: [] };
  };
}

export default ContractActiesStore;
