import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import * as Yup from 'yup';
import { IGroepSorterenParams } from '../../../../../../../shared/src/api/v2/faq';
import {
  IOphalenFaqGroepResultElement,
  IWijzigenFaqGroepParams,
} from '../../../../../../../shared/src/api/v2/faqGroep';
import { IOphalenTekstenInAlleTalenResult } from '../../../../../../../shared/src/api/v2/tekst';
import ITaalTekst from '../../../../../../../shared/src/models/talen/ITaalTekst';
import api from '../../../../../api';
import LoadingSpinner from '../../../../../components/Gedeeld/LoadingSpinner';
import Dialoog from '../../../../../components/dialogen/Dialoog';
import MeertaligTekstveld from '../../../../../components/formulier/MeertaligTekstveld';
import IDialoogProps from '../../../../../core/IDialoogProps';
import nameof from '../../../../../core/nameOf';
import IRemoteData, {
  ERemoteDataState,
  createPendingRemoteData,
  createReadyRemoteData,
} from '../../../../../models/IRemoteData';
import { EResultType } from '../../../../../stores/CheckStore';
import { RootStoreContext } from '../../../../../stores/RootStore';
import VraagSortering from '../../VraagSortering';

interface IDialoogResult {}

interface IProps extends IDialoogProps<IDialoogResult> {
  id: number;
}

interface IFormikValues {
  ID: number;
  naam: ITaalTekst[];
}

const veldnamen: Record<keyof IFormikValues, string> = {
  ID: 'ID',
  naam: 'Naam',
};

const WijzigenDialoog: React.FC<IProps> = observer((props) => {
  const { children, dialoogIndex, onAnnuleren, onSuccess } = props;
  const { checkStore } = useContext(RootStoreContext);
  const formikRef = useRef<Formik<IFormikValues>>(null);

  const [faqGroep, setFaqGroep] = useState<IOphalenFaqGroepResultElement | null>(null);
  const [sortering, setSortering] = useState<IGroepSorterenParams | null>(null);

  const ophalenfaqGroep = useCallback(async () => {
    const result = await api.v2.faqGroep.ophalenFaqGroepen({
      filterSchema: { filters: [{ naam: 'IDS', data: [props.id] }] },
    });
    console.log(result.faqGroepen[0]);

    setFaqGroep(result.faqGroepen[0]);
  }, [props.id]);

  useEffect(() => {
    ophalenfaqGroep();
  }, [ophalenfaqGroep]);

  const [teksten, setTeksten] = useState<IRemoteData<IOphalenTekstenInAlleTalenResult>>(
    createPendingRemoteData(),
  );

  const ophalenTeksten = useCallback(async () => {
    if (faqGroep === null) {
      if (teksten.state === ERemoteDataState.Ready) {
        setTeksten(createPendingRemoteData());
      }
      return;
    }

    const tekstIDs = [faqGroep.Naam_TekstID].filter((x) => x !== null) as number[];

    const result = await api.v2.tekst.ophalenTekstenInAlleTalen({
      tekstIDs,
    });
    setTeksten(createReadyRemoteData(result));
  }, [faqGroep]);

  useEffect(() => {
    ophalenTeksten();
  }, [ophalenTeksten]);

  const handleSorteringVeranderd = (sort: IGroepSorterenParams) => {
    console.log(sort);
    setSortering(sort);
    console.log(sortering);
  };

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      if (faqGroep === null) {
        return;
      }

      actions.setSubmitting(true);

      const params: IWijzigenFaqGroepParams = {
        ID: faqGroep.ID,
        Naam: {
          tekstID: faqGroep.Naam_TekstID,
          taalTeksten: values.naam,
        },
      };

      const checkData = await api.v2.faqGroep.checkWijzigenFaqGroep(params);
      const checkResult = await checkStore.controleren({
        checkData,
      });
      if (checkResult.type === EResultType.Annuleren) {
        onAnnuleren();
        return;
      }

      await api.v2.faqGroep.wijzigenFaqGroep(params);

      console.log(sortering);
      if (sortering !== null) {
        console.log('update sortering');
        await api.v2.faq.sorterenGroepVragen(sortering);
      } else {
        console.log('niet sorteren');
      }

      actions.setSubmitting(false);
      props.onSuccess({});
    },
    [faqGroep, sortering],
  );

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (faqGroep === null || teksten.state === ERemoteDataState.Pending) {
      return null;
    }
    const naamTeksten =
      faqGroep.Naam_TekstID === null
        ? null
        : teksten.data!.teksten.filter((x) => x.TekstID === faqGroep.Naam_TekstID);

    return {
      ID: faqGroep.ID,
      naam:
        naamTeksten === null
          ? []
          : naamTeksten.map((x) => ({
              tekst: x.Tekst ?? '',
              taalID: x.TaalID,
              toepassen: x.Toepassen,
            })),
    };
  }, [faqGroep, teksten]);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        ID: Yup.string().required(),
      }),
    [],
  );

  return (
    <Dialoog index={dialoogIndex || 0} modalProps={{ size: 'lg' }}>
      <ModalHeader>
        <ModalTitle>Wijzigen Faq Groep</ModalTitle>
      </ModalHeader>
      {initialValues === null ? (
        <LoadingSpinner />
      ) : (
        <Formik<IFormikValues>
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize
          isInitialValid
          render={(formikProps: FormikProps<IFormikValues>) => {
            const { submitForm, isValid, isSubmitting } = formikProps;

            return (
              <>
                <ModalBody>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-12 mt-3">
                        <label>Naam</label>
                        <Field
                          name={nameof<IFormikValues>('naam')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            return (
                              <MeertaligTekstveld
                                waarden={fieldProps.field.value}
                                onChange={(x) =>
                                  fieldProps.form.setFieldValue(fieldProps.field.name, x)
                                }
                                defaultUitgeklapt
                                inputComponent={'multiline'}
                                multilineConfig={{ rows: 3 }}
                              />
                            );
                          }}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 mt-3">
                        <label>Sortering Vragen</label>
                        <VraagSortering
                          Groep_ID={faqGroep == null ? 0 : faqGroep.ID}
                          onSorteringVeranderd={handleSorteringVeranderd}
                          onVragenOphalen={api.v2.faq.ophalenGroepVragen}
                        ></VraagSortering>
                      </div>
                    </div>
                  </div>
                </ModalBody>

                <ModalFooter className="d-flex flex-row justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    style={{ width: 100 }}
                    disabled={!isValid || isSubmitting}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={onAnnuleren}
                    style={{ width: 100 }}
                    disabled={isSubmitting}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Dialoog>
  );
});

export default WijzigenDialoog;
