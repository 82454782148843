import React, { useContext, useMemo, useState } from 'react';
import { IProps } from '@tinymce/tinymce-react/lib/cjs/main/ts/components/Editor';
import { IContext, FactuurContext } from '../index';
import VeldWeergave from '../../../formulier/VeldWeergave';
import LoadingSpinner from '../../../Gedeeld/LoadingSpinner';
import { format } from 'date-fns';
import GegevensLayout from '../../../layout/GegevensLayout';
import FormatteerBedrag from '../../../MutatieBedrag';
import {
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import { IOphalenBoekingRegelsResultElement } from '../../../../../../shared/src/api/v2/boekhouding/boeking';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import nameOf from '../../../../core/nameOf';
import { Kleur as EKleur } from '../../../../bedrijfslogica/constanten';
import { IconInformatie } from '../../../Icons';
// import { IBoekingInfoDialoogState } from '../../../debiteuren/BetalingsregelingenTabel';
import BoekingInfoDialoog from '../../../boekhouding/boeking/BoekingInfoDialoog';

export interface IRegel extends IOphalenBoekingRegelsResultElement {}

export interface IBoekingInfoDialoogState {
  boekingID: number;
}

const BoekingTab: React.FC<IProps> = (props) => {
  const { factuur, boekingen, boekingregels } = useContext<IContext>(FactuurContext);

  const [
    boekingInfoDialoogState,
    setBoekingInfoDialoogState,
  ] = useState<IBoekingInfoDialoogState | null>(null);

  const kolommen = useMemo<TypedColumn<IRegel>[]>(
    () => [
      {
        name: '__datum' as any,
        title: 'Datum',
      },
      {
        name: '__grootboekrekeningDB' as any,
        title: 'Grb.rek.',
      },
      {
        name: 'Bedrag',
        title: 'Bedrag',
      },
      {
        name: '__grootboekrekeningCR' as any,
        title: 'Teg.rek.',
      },
      // {
      //   name: '__grootboekrekeningDB' as any,
      //   title: 'DB-rek.',
      // },
      // {
      //   name: '__grootboekrekeningCR' as any,
      //   title: 'CR-rek.',
      // },
      {
        name: '__naamGrootboekrekeningCR' as any,
        title: 'Naam',
      },
      // {
      //   name: '__factuurnummer' as any,
      //   title: 'Factuur',
      // },
      {
        name: 'Omschrijving',
        title: 'Omschrijving',
      },
      {
        name: '__definitief' as any,
        title: 'Def.',
      },
      {
        name: '__boekinginfo' as any,
        title: ' ',
      },
      // {
      //   name: '__dagboek' as any,
      //   title: 'Dagboek',
      // },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRegel>[]>(
    () => [
      {
        columnName: '__datum',
        width: 115,
      },
      {
        columnName: '__grootboekrekeningDB' as any,
        width: 75,
      },
      {
        columnName: '__grootboekrekeningCR' as any,
        width: 75,
      },
      {
        columnName: '__naamGrootboekrekeningCR' as any,
        width: 155,
      },
      {
        columnName: 'Bedrag',
        width: 110,
      },
      // {
      //   columnName: '__factuurnummer' as any,
      //   width: 100,
      // },
      {
        columnName: 'Omschrijving',
        width: 115,
      },
      {
        columnName: '__definitief' as any,
        width: 55,
      },
      // {
      //   columnName: '__dagboek' as any,
      //   width: 100,
      // },
      {
        columnName: '__boekinginfo' as any,
        width: 60,
      },
    ],
    [],
  );

  return (
    <>
      {boekingregels === null || boekingen === null ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="d-flex flex-column flex-fill mt-3">
            <div className="col-12">
              <h6>Boekingen:</h6>
              <GridStyleWrapper maxHeight={500} rowAmount={boekingregels.length}>
                <Grid rows={boekingregels} columns={kolommen}>
                  <DataTypeProvider
                    for={['__grootboekrekeningDB']}
                    formatterComponent={(formatterProps) => {
                      const rij: IRegel = formatterProps.row;
                      return <span>{rij.dbRekening.Nummer}</span>;
                    }}
                  />
                  <DataTypeProvider
                    for={['__grootboekrekeningCR']}
                    formatterComponent={(formatterProps) => {
                      const rij: IRegel = formatterProps.row;
                      return <span>{rij.crRekening.Nummer}</span>;
                    }}
                  />
                  <DataTypeProvider
                    for={['__naamGrootboekrekeningCR']}
                    formatterComponent={(formatterProps) => {
                      const rij: IRegel = formatterProps.row;
                      return <span>{rij.crRekening.Naam}</span>;
                    }}
                  />
                  <DataTypeProvider
                    for={[nameOf<IRegel>('Bedrag')]}
                    formatterComponent={(formatterProps) => {
                      const rij: IRegel = formatterProps.row;
                      return <FormatteerBedrag bedrag={formatterProps.value} />;
                    }}
                  />
                  {/* 
                  <DataTypeProvider
                    for={['__factuurnummer']}
                    formatterComponent={(formatterProps) => {
                      const rij: IRegel = formatterProps.row;

                      if (rij.FactID === null) {
                        return <span></span>;
                      }
                      return <FactuurVisualisatie factID={rij.FactID} />;
                    }}
                  /> */}

                  <DataTypeProvider
                    for={['__datum']}
                    formatterComponent={(formatterProps) => {
                      const rij: IRegel = formatterProps.row;
                      const boeking = boekingen.find((x) => x.ID === rij.BoekingID)!;
                      return <span>{format(new Date(boeking.Boekdatum), 'dd-MM-yyyy')}</span>;
                    }}
                  />

                  <DataTypeProvider
                    for={['__dagboek']}
                    formatterComponent={(formatterProps) => {
                      const rij: IRegel = formatterProps.row;
                      const boeking = boekingen.find((x) => x.ID === rij.BoekingID)!;
                      return <span>{boeking.dagboek.NaamKort}</span>;
                    }}
                  />

                  <DataTypeProvider
                    for={[nameOf<IRegel>('Omschrijving')]}
                    formatterComponent={(formatterProps) => {
                      const rij: IRegel = formatterProps.row;
                      // const omschrijving = rij.Omschrijving ?? factuur!.Onderwerp;
                      return <span>{rij.Omschrijving}</span>;
                    }}
                  />

                  <DataTypeProvider
                    for={['__definitief']}
                    formatterComponent={(formatterProps) => {
                      const rij: IRegel = formatterProps.row;
                      return rij.boeking.Definitief ? (
                        <span>Ja</span>
                      ) : (
                        <span style={{ color: EKleur.Rood }}>Nee</span>
                      );
                    }}
                  />

                  <DataTypeProvider
                    for={['__boekinginfo']}
                    formatterComponent={(formatterProps) => {
                      const rij: IRegel = formatterProps.row;

                      return (
                        <a
                          href="#"
                          onClick={() => {
                            setBoekingInfoDialoogState({ boekingID: rij.BoekingID });
                          }}
                        >
                          <IconInformatie style={{ width: 15, height: 15, fill: EKleur.Blauw }} />
                        </a>
                      );
                    }}
                  />

                  <VirtualTable
                    // columnExtensions={kolomExtensies}
                    messages={{ noData: 'Geen boekingen' }}
                  />
                  <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
                  <TableHeaderRow />
                </Grid>
              </GridStyleWrapper>
            </div>
          </div>
          {boekingInfoDialoogState !== null && (
            <BoekingInfoDialoog
              open
              boekingID={boekingInfoDialoogState.boekingID}
              onSuccess={async () => {
                setBoekingInfoDialoogState(null);
              }}
              onAnnuleren={() => setBoekingInfoDialoogState(null)}
            />
          )}
        </>
      )}
    </>
  );
};

export default BoekingTab;
