import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { IAbonnementActie } from '../../../../../../../../shared/src/api/v2/aanbod/tarieven/acties';
import {
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../../../helpers/dxTableGrid';
import {
  Grid,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  DataTypeProvider,
  EditingState,
  VirtualTable as VirtualTableBase,
} from '@devexpress/dx-react-grid';
import nameOf from '../../../../../../core/nameOf';
import FormatteerBedrag from '../../../../../../components/MutatieBedrag';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../../../../stores/RootStore';
import LoadingSpinner from '../../../../../../components/Gedeeld/LoadingSpinner';
import WijzigenActietariefDialoog from './WijzigenActietariefDialoog';
import { ActiesContext } from '../../index';

interface IProps {
  abonnementActies: IAbonnementActie[];
}

interface IWijzigenActietariefDialoogState {
  id: number;
}

const AbonnementActiesTabel: React.FC<IProps> = observer((props) => {
  const { abonnementenStore } = useContext(RootStoreContext);
  const actiesContext = useContext(ActiesContext);

  const [
    wijzigenActietariefDialoogState,
    setWijzigenActietariefDialoogState,
  ] = useState<IWijzigenActietariefDialoogState | null>(null);

  useEffect(() => {
    if (abonnementenStore.abonnementen !== null) {
      return;
    }
    // noinspection JSIgnoredPromiseFromCall
    abonnementenStore.ophalenAbonnementen();
  }, []);

  const keyExtractor = useCallback((row: IAbonnementActie) => row.ID, []);
  const kolommen = useMemo<TypedColumn<IAbonnementActie>[]>(
    () => [
      {
        name: 'AbonID',
        title: 'Abon.',
      },
      {
        name: 'Actieduur',
        title: 'Actieduur',
      },
      {
        name: 'Maandhuur',
        title: 'Maandhuur',
      },
      {
        name: 'Kortingspercentage',
        title: 'Kort.perc.',
      },
      {
        name: 'EenmaligBedrag',
        title: 'Eenmalig',
      },
    ],
    [],
  );
  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IAbonnementActie>[]>(
    () => [
      {
        columnName: 'AbonID',
        width: 80,
      },
      {
        columnName: 'EenmaligBedrag',
        width: 90,
      },
      {
        columnName: 'Maandhuur',
        width: 110,
      },
      {
        columnName: 'Kortingspercentage',
        width: 90,
      },
      {
        columnName: 'Actieduur',
        width: 90,
      },
    ],
    [],
  );

  // const kolomExtensies: VirtualTableBase.ColumnExtension[] = useMemo(() => {
  //   return [
  //     {
  //       columnName: `EenmaligBedrag`,
  //       align: 'right',
  //     },
  //     {
  //       columnName: `Maandhuur`,
  //       align: 'right',
  //     },
  //     {
  //       columnName: `Kortingspercentage`,
  //       align: 'right',
  //     },
  //   ];
  // }, []);

  return (
    <>
      <GridStyleWrapper maxHeight={500} rowAmount={props.abonnementActies.length}>
        <Grid getRowId={keyExtractor} columns={kolommen} rows={props.abonnementActies}>
          <DataTypeProvider
            for={[nameOf<IAbonnementActie>('AbonID')]}
            formatterComponent={(formatterProps) => {
              if (abonnementenStore.abonnementen === null) {
                return <LoadingSpinner />;
              }
              const abonID: number = formatterProps.value;
              const abonnement = abonnementenStore.abonnementen.find((x) => x.AbonID === abonID)!;
              return <span>{abonnement.Naam}</span>;
            }}
          />
          <DataTypeProvider
            for={[nameOf<IAbonnementActie>('EenmaligBedrag')]}
            formatterComponent={(formatterProps) => {
              const bedrag: number | null = formatterProps.value;
              if (bedrag === null) {
                return <span>-</span>;
              }
              return <FormatteerBedrag bedrag={bedrag} />;
            }}
          />

          <DataTypeProvider
            for={[nameOf<IAbonnementActie>('Maandhuur')]}
            formatterComponent={(formatterProps) => {
              const bedrag: number | null = formatterProps.value;
              if (bedrag === null) {
                return <span>-</span>;
              }
              return <FormatteerBedrag bedrag={bedrag} />;
            }}
          />

          <DataTypeProvider
            for={[nameOf<IAbonnementActie>('Kortingspercentage')]}
            formatterComponent={(formatterProps) => {
              const kortingspercentage: number | null = formatterProps.value;
              if (kortingspercentage === null) {
                return <span>-</span>;
              }
              return <span>{kortingspercentage}%</span>;
            }}
          />

          <DataTypeProvider
            for={[nameOf<IAbonnementActie>('Actieduur')]}
            formatterComponent={(formatterProps) => {
              const actieduur: number | null = formatterProps.value;
              if (actieduur === null) {
                return <span />;
              }
              if (actieduur === 0) {
                return <span>Looptijd</span>;
              }
              return <span>{actieduur} mnd</span>;
            }}
          />

          <EditingState
            onCommitChanges={async (changes) => {
              if (changes.deleted === undefined) {
                return;
              }
              const deleted = changes.deleted;
              const id = deleted[deleted.length - 1] as number;
            }}
            onEditingRowIdsChange={(rowIds) => {
              const id = rowIds[rowIds.length - 1] as number;
              setWijzigenActietariefDialoogState({ id });
            }}
          />

          {/* <VirtualTable columnExtensions={kolomExtensies} messages={{ noData: 'Geen regels' }} /> */}
          <VirtualTable messages={{ noData: 'Geen regels' }} />

          <TableColumnResizing defaultColumnWidths={kolomBreedtes} />

          <TableEditColumn
            width={35}
            showEditCommand
            // showDeleteCommand
            // showAddCommand
            cellComponent={DXTableEditColumnCellComponent}
            commandComponent={DXTableEditColumnCommandComponent}
          />
          <TableHeaderRow />
        </Grid>
      </GridStyleWrapper>
      {wijzigenActietariefDialoogState !== null && (
        <WijzigenActietariefDialoog
          open
          id={wijzigenActietariefDialoogState.id}
          onSuccess={async () => {
            await actiesContext.verversen();
            setWijzigenActietariefDialoogState(null);
          }}
          onAnnuleren={() => setWijzigenActietariefDialoogState(null)}
        />
      )}
    </>
  );
});

export default AbonnementActiesTabel;
