import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import Modal from 'react-bootstrap/Modal';
import { Formik, FormikActions, Field, FieldProps } from 'formik';

import IDialoogProps from '../../../../core/IDialoogProps';
import Dialoog from '../../../../components/dialogen/Dialoog';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';

interface IProps extends IDialoogProps<null> {
  factIDs: number[];
}

interface IFormikValues {}

const ToevoegenIncassozaakDialoog: React.FC<IProps> = (props) => {
  const { open, onSuccess, onAnnuleren, dialoogIndex } = props;

  const initialValues: IFormikValues = useMemo(() => {
    return {};
  }, []);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      actions.setSubmitting(false);

      onSuccess(null);
    },
    [],
  );
  const handleAnnulerenClick = useCallback(async () => {
    onAnnuleren();
  }, []);

  return (
    <Dialoog index={props.dialoogIndex || 0}>
      <Formik<IFormikValues>
        initialValues={initialValues}
        onSubmit={handleSubmit}
        isInitialValid
        render={(formikProps) => {
          const { submitForm, isSubmitting } = formikProps;
          return (
            <>
              <ModalHeader>
                <ModalTitle>Incassozaken maken</ModalTitle>
              </ModalHeader>

              <ModalBody style={{ padding: '2rem' }}></ModalBody>

              <ModalFooter className="d-flex flex-row justify-content-start">
                <button className="btn btn-primary" onClick={submitForm} style={{ width: 100 }}>
                  Ok
                </button>
                <button
                  className="btn btn-secondary"
                  onClick={() => {
                    handleAnnulerenClick();
                  }}
                  style={{ width: 100 }}
                >
                  Annuleren
                </button>
              </ModalFooter>
            </>
          );
        }}
      />
    </Dialoog>
  );
};

export default ToevoegenIncassozaakDialoog;
