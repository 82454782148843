import api from '../../index';
import {
  IOphalenFactuurregelsParams,
  IOphalenFactuurregelsResult,
  IOphalenFacturenBasisParams,
  IOphalenFacturenBasisResult,
  // IOphalenFacturenVoorDebiteurParams,
  IDefinitiefMakenParams,
  IDefinitiefMakenResult,
  IVersturenFacturenParams,
  IVersturenFacturenResult,
  IGenereerFactuurPDFParams,
  IGenereerFactuurPDFResult,
  IWijzigenFactuurParams,
  IWijzigenFactuurResult,
  IMakenBankopdrachtenParams,
  IMakenBankopdrachtenResult,
  IBepalenVerrekingsbedragParams,
  IBepalenVerrekeningsbedragResult,
  IVerwijderenFacturenParams,
  IVerwijderenFacturenResult,
  IVerwerkenFactuurverrekeningParams,
  IVerwerkenFactuurverrekeningResult,
  ICheckSelectieVerrekenenFacturenParams,
  IHerstellenDefinitiefMakenParams,
  IHerstellenDefinitiefMakenResult,
  IOphalenFactuurkenmerkenParams,
  IOphalenFactuurkenmerkenResult,
  IOphalenVerzendwijzenParams,
  IOphalenVerzendwijzenResult,
  IOphalenFacturenDossiersParams,
  IOphalenFacturenDossiersResult,
  IVersturenBetaallinkFacturenParams,
  IVersturenBetaallinkFacturenResult,
  IOphalenFactuurdossiersParams,
  IOphalenFactuurdossiersResult,
  IWijzigenIndicatieVersturenParams,
  IWijzigenIndicatieVersturenResult,
  IZoekenFacturenNaarRelatieParams,
  IZoekenFacturenNaarRelatieResult,
  IGenereerBetaallinkFacturenSjabloonParams,
  IGenereerBetaallinkFacturenSjabloonResult,
  IGenereerFacturenSjabloonResult,
  IGenereerFacturenSjabloonParams,
  ICrediterenFacturenParams,
  IKopierenFactuurParams,
  IKopierenFactuurResult,
} from '../../../../../shared/src/api/v2/factuur';
import {
  IVerwijderenFacturenUitVoorstelParams,
  IVerwijderenFacturenUitVoorstelResult,
} from '../../../../../shared/src/api/v2/factuur/voorstel';
import ICheckData from '../../../../../shared/src/models/ICheckData';
import { IAchtergrondProces } from '../../../../../shared/src/api/v2/achtergrondProces';
import rubriek from './rubriek';

const factuur = {
  rubriek,
  ophalenFactuurregels: async (
    params: IOphalenFactuurregelsParams,
  ): Promise<IOphalenFactuurregelsResult> => {
    return await api.post('v2/factuur/ophalen-factuur-regels', params);
  },
  checkDefinitiefMaken: async (params: IDefinitiefMakenParams): Promise<ICheckData> => {
    return await api.post('v2/factuur/check-definitief-maken', params);
  },
  definitiefMaken: async (params: IDefinitiefMakenParams): Promise<IDefinitiefMakenResult> => {
    return await api.post('v2/factuur/definitief-maken', params);
  },
  definitiefMakenAchtergrondProces: async (
    params: IDefinitiefMakenParams,
  ): Promise<IAchtergrondProces<IDefinitiefMakenResult>> => {
    return await api.post('v2/factuur/definitief-maken-achtergrond-proces', params);
  },
  checkHerstellenDefinitiefMaken: async (
    params: IHerstellenDefinitiefMakenParams,
  ): Promise<ICheckData> => {
    return await api.post('v2/factuur/check-herstellen-definitief-maken', params);
  },
  herstellenDefinitiefMaken: async (
    params: IHerstellenDefinitiefMakenParams,
  ): Promise<IDefinitiefMakenResult> => {
    return await api.post('v2/factuur/herstellen-definitief-maken', params);
  },
  genererenFacturenSjabloon: async (
    params: IGenereerFacturenSjabloonParams,
  ): Promise<IGenereerFacturenSjabloonResult> => {
    return await api.post('v2/factuur/genereren-facturen-sjabloon', params);
  },
  checkVersturenFacturen: async (params: IVersturenFacturenParams): Promise<ICheckData> => {
    return await api.post('v2/factuur/check-versturen-facturen', params);
  },
  versturenFacturen: async (
    params: IVersturenFacturenParams,
  ): Promise<IAchtergrondProces<IVersturenFacturenResult>> => {
    return await api.post('v2/factuur/versturen-facturen', params);
  },
  genereerFactuurPDF: async (
    params: IGenereerFactuurPDFParams,
  ): Promise<IGenereerFactuurPDFResult> => {
    return await api.post('v2/factuur/genereer-factuur-pdf', params);
  },
  ophalenFacturenBasis: async (
    params: IOphalenFacturenBasisParams,
  ): Promise<IOphalenFacturenBasisResult> => {
    return await api.post('v2/factuur/ophalen-facturen-basis', params);
  },
  checkWijzigenFactuur: async (params: IWijzigenFactuurParams): Promise<ICheckData> => {
    return await api.post('v2/factuur/check-wijzigen-factuur', params);
  },
  wijzigenFactuur: async (params: IWijzigenFactuurParams): Promise<IWijzigenFactuurResult> => {
    return await api.post('v2/factuur/wijzigen-factuur', params);
  },
  checkMakenBankopdrachten: async (params: IMakenBankopdrachtenParams): Promise<ICheckData> => {
    return await api.post('v2/factuur/check-maken-bankopdrachten', params);
  },
  makenBankopdrachten: async (
    params: IMakenBankopdrachtenParams,
  ): Promise<IMakenBankopdrachtenResult> => {
    return await api.post('v2/factuur/maken-bankopdrachten', params);
  },
  makenBankopdrachtenAchtergrondProces: async (
    params: IMakenBankopdrachtenParams,
  ): Promise<IAchtergrondProces<IMakenBankopdrachtenResult>> => {
    return await api.post('v2/factuur/maken-bankopdrachten-achtergrond-proces', params);
  },
  bepalenVerrekeningsbedragFacturen: async (
    params: IBepalenVerrekingsbedragParams,
  ): Promise<IBepalenVerrekeningsbedragResult> => {
    return await api.post('v2/factuur/bepalen-verrekeningsbedrag-facturen', params);
  },
  checkVerwijderenFacturenUitVoorstel: async (
    params: IVerwijderenFacturenUitVoorstelParams,
  ): Promise<ICheckData> => {
    return await api.post('v2/factuur/check-verwijderen-facturen-uit-voorstel', params);
  },
  verwijderenFacturenUitVoorstel: async (
    params: IVerwijderenFacturenUitVoorstelParams,
  ): Promise<IVerwijderenFacturenUitVoorstelResult> => {
    return await api.post('v2/factuur/verwijderen-facturen-uit-voorstel', params);
  },
  checkVerwijderenFacturen: async (params: IVerwijderenFacturenParams): Promise<ICheckData> => {
    return await api.post('v2/factuur/check-verwijderen-facturen', params);
  },
  verwijderenFacturen: async (
    params: IVerwijderenFacturenParams,
  ): Promise<IVerwijderenFacturenResult> => {
    return await api.post('v2/factuur/verwijderen-facturen', params);
  },
  verwerkenFactuurverrekening: async (
    params: IVerwerkenFactuurverrekeningParams,
  ): Promise<IVerwerkenFactuurverrekeningResult> => {
    return await api.post('v2/factuur/verwerken-factuurverrekening', params);
  },
  checkSelectieVerrekenenFacturen: async (
    params: ICheckSelectieVerrekenenFacturenParams,
  ): Promise<ICheckData> => {
    return await api.post('v2/factuur/check-selectie-verrekenen-facturen', params);
  },
  ophalenFactuurkenmerken: async (
    params: IOphalenFactuurkenmerkenParams,
  ): Promise<IOphalenFactuurkenmerkenResult> => {
    return await api.post('v2/factuur/rubriek/factuurkenmerk/ophalen-factuurkenmerken', params);
  },
  ophalenFactuurdossiers: async (
    params: IOphalenFactuurdossiersParams,
  ): Promise<IOphalenFactuurdossiersResult> => {
    return await api.post('v2/factuur/ophalen-factuurdossiers', params);
  },
  ophalenVerzendwijzen: async (
    params: IOphalenVerzendwijzenParams,
  ): Promise<IOphalenVerzendwijzenResult> => {
    return await api.post('v2/factuur/ophalen-verzendwijzen', params);
  },
  ophalenFacturenDossiers: async (
    params: IOphalenFacturenDossiersParams,
  ): Promise<IOphalenFacturenDossiersResult> => {
    return await api.post('v2/factuur/ophalen-facturen-dossiers', params);
  },
  checkVersturenBetaallinkFacturen: async (
    params: IVersturenBetaallinkFacturenParams,
  ): Promise<ICheckData> => {
    return await api.post('v2/factuur/check-versturen-betaallink-facturen', params);
  },
  versturenBetaallinkFacturen: async (
    params: IVersturenBetaallinkFacturenParams,
  ): Promise<IVersturenBetaallinkFacturenResult> => {
    return await api.post('v2/factuur/versturen-betaallink-facturen', params);
  },
  checkGenererenBetaallinkFacturenSjabloon: async (
    params: IGenereerBetaallinkFacturenSjabloonParams,
  ): Promise<ICheckData> => {
    return await api.post('v2/factuur/check-genereer-betaallink-facturen-sjabloon', params);
  },
  genereerBetaallinkFacturenSjabloon: async (
    params: IGenereerBetaallinkFacturenSjabloonParams,
  ): Promise<IGenereerBetaallinkFacturenSjabloonResult> => {
    return await api.post('v2/factuur/genereer-betaallink-facturen-sjabloon', params);
  },
  wijzigenIndicatieVersturen: async (
    params: IWijzigenIndicatieVersturenParams,
  ): Promise<IWijzigenIndicatieVersturenResult> => {
    return await api.post('v2/factuur/wijzigen-indicatie-versturen', params);
  },
  checkCrediterenFacturen: async (params: ICrediterenFacturenParams): Promise<ICheckData> => {
    return await api.post('v2/factuur/check-crediteren-facturen', params);
  },
  crediterenFacturen: async (
    params: ICrediterenFacturenParams,
  ): Promise<ICrediterenFacturenParams> => {
    return await api.post('v2/factuur/crediteren-facturen', params);
  },
  kopierenFactuur: async (params: IKopierenFactuurParams): Promise<IKopierenFactuurResult> => {
    return await api.post('v2/factuur/kopieren-factuur', params);
  },
};

export default factuur;
