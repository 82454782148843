import {
  IBepalenDuplicatenParams,
  IBepalenDuplicatenResult,
  IToevoegenAanRelatieParams,
  IToevoegenAanRelatieResult,
  IOphalenToegevoegdeRelatiesParams,
  IOphalenToegevoegdeRelatiesResult,
  IHerstellenToegevoegdeRelatieParams,
  IHerstellenToegevoegdeRelatieResult,
  IOphalenRelatieDuplicaatSuggestiesParams,
  IOphalenRelatieDuplicaatSuggestiesResult,
  IRegistreerDuplicatenParams,
  IRegistreerDuplicatenResult,
  IAfwijzenDuplicaatSuggestieParams,
  IAfwijzenDuplicaatSuggestieResult,
  IOphalenVervangenRelatiesParams,
  IOphalenVervangenRelatiesResult,
  IToevoegenDuplicaatSuggestieParams,
  IToevoegenDuplicaatSuggestieResult,
  IVerwijderenVervangenRelatiesParams,
  IVerwijderenVervangenRelatiesResult,
} from '../../../../../shared/src/api/v2/relatie/duplicaat';
import ICheckData from '../../../../../shared/src/models/ICheckData';
import api from '../../index';

const duplicaat = {
  bepalenDuplicaten: async (
    params: IBepalenDuplicatenParams,
  ): Promise<IBepalenDuplicatenResult> => {
    return await api.post('v2/relatie/duplicaat/bepalen-duplicaten', params);
  },
  checkToevoegenAanRelatie: async (params: IToevoegenAanRelatieParams): Promise<ICheckData> => {
    return await api.post('v2/relatie/duplicaat/check-toevoegen-aan-relatie', params);
  },
  toevoegenAanRelatie: async (
    params: IToevoegenAanRelatieParams,
  ): Promise<IToevoegenAanRelatieResult> => {
    return await api.post('v2/relatie/duplicaat/toevoegen-aan-relatie', params);
  },
  checkHerstellenToegevoegdeRelatie: async (
    params: IHerstellenToegevoegdeRelatieParams,
  ): Promise<ICheckData> => {
    return await api.post('v2/relatie/duplicaat/check-herstellen-toegevoegde-relatie', params);
  },
  herstellenToegevoegdeRelatie: async (
    params: IHerstellenToegevoegdeRelatieParams,
  ): Promise<IHerstellenToegevoegdeRelatieResult> => {
    return await api.post('v2/relatie/duplicaat/herstellen-toegevoegde-relatie', params);
  },
  ophalenToegevoegdeRelaties: async (
    params: IOphalenToegevoegdeRelatiesParams,
  ): Promise<IOphalenToegevoegdeRelatiesResult> => {
    return await api.post('v2/relatie/duplicaat/ophalen-toegevoegde-Relaties', params);
  },
  ophalenRelatieDuplicaatSuggesties: async (
    params: IOphalenRelatieDuplicaatSuggestiesParams,
  ): Promise<IOphalenRelatieDuplicaatSuggestiesResult> => {
    return await api.post('v2/relatie/duplicaat/ophalen-relatie-duplicaat-suggesties', params);
  },
  checkRegistreerDuplicaten: async (params: IRegistreerDuplicatenParams): Promise<ICheckData> => {
    return await api.post('v2/relatie/duplicaat/check-registreer-duplicaten', params);
  },
  registreerDuplicaten: async (
    params: IRegistreerDuplicatenParams,
  ): Promise<IRegistreerDuplicatenResult> => {
    return await api.post('v2/relatie/duplicaat/registreer-duplicaten', params);
  },
  afwijzenDuplicaatSuggestie: async (
    params: IAfwijzenDuplicaatSuggestieParams,
  ): Promise<IAfwijzenDuplicaatSuggestieResult> => {
    return await api.post('v2/relatie/duplicaat/afwijzen-duplicaat-suggestie', params);
  },
  ophalenVervangenRelaties: async (
    params: IOphalenVervangenRelatiesParams,
  ): Promise<IOphalenVervangenRelatiesResult> => {
    return await api.post('v2/relatie/duplicaat/ophalen-vervangen-relaties', params);
  },
  toevoegenDuplicaatSuggestie: async (
    params: IToevoegenDuplicaatSuggestieParams,
  ): Promise<IToevoegenDuplicaatSuggestieResult> => {
    return await api.post('v2/relatie/duplicaat/toevoegen-duplicaat-suggestie', params);
  },
  verwijderenVervangenRelaties: async (
    params: IVerwijderenVervangenRelatiesParams,
  ): Promise<IVerwijderenVervangenRelatiesResult> => {
    return await api.post('v2/relatie/duplicaat/verwijderen-vervangen-relaties', params);
  },
};

export default duplicaat;
