import api from '../../../..';
import {
  IBepalenBoekdataParams,
  IBepalenBoekdataResult,
  IMakenVoorstelBankboekingenParams,
  IMakenVoorstelBankboekingenResult,
} from '../../../../../../../shared/src/api/v2/boekhouding/boeking/bank';
import ICheckData from '../../../../../../../shared/src/models/ICheckData';

const bank = {
  // bepalenBoekdata: async (params: IBepalenBoekdataParams): Promise<IBepalenBoekdataResult> => {
  //   return await api.post('/v2/boeking/bank/bepalen-boekdata', params);
  // },
  checkMakenVoorstelBankboekingen: async (
    params: IMakenVoorstelBankboekingenParams,
  ): Promise<ICheckData> => {
    return await api.post('/v2/boeking/bank/check-maken-voorstel-bankboekingen', params);
  },
  makenVoorstelBankboekingen: async (
    params: IMakenVoorstelBankboekingenParams,
  ): Promise<IMakenVoorstelBankboekingenResult> => {
    return await api.post('/v2/boeking/bank/maken-voorstel-bankboekingen', params);
  },
};

export default bank;
