import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import MenuLayout from '../../../MenuLayout';
import { IEntiteitProps } from '../../EntiteitContainer';
import api from '../../../../api';
import { IOphalenLeveringsvoorwaardenResultElement } from '../../../../../../shared/src/api/v2/relatie/leveringsvoorwaarden';
import ASPTabel from '../../../tabel/ASPTabel';
import { ASPKolom, EAspKolomBreedteType } from '../../../tabel/ASPTabel/types';
import { IconToevoegen } from '../../../Icons';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import { GlobaleRendererContext } from '../../../../one-off-components/GlobaleRenderer';
import MuterenDialoog, { IDialoogResult } from './MuterenDialoog';
import { RootStoreContext } from '../../../../stores/RootStore';
import { EResultType } from '../../../../stores/CheckStore';
import { IOphalenLeveringsvoorwaardenResult } from '../../../../../../shared/src/api/v2/relatie/leveringsvoorwaarden';
import { format } from 'date-fns';
import UitgeklapteRij from './UitgeklapteRij';

export enum ELeveringsvoorwaardenKolom {
  Periode,
  Titel,
  Inhoud,
  Ingangsatum,
  Einddatum,
}

interface IProps extends IEntiteitProps {}

const Leveringsvoorwaarden: React.FC<IProps> = (props) => {
  const { checkStore } = useContext(RootStoreContext);

  const globaleRenderer = useContext(GlobaleRendererContext);
  const [
    leveringsvoorwaardenResult,
    setLeveringsvoorwaardenResult,
  ] = useState<IOphalenLeveringsvoorwaardenResult | null>(null);
  const [bezigMetOphalen, setBezigMetOphalen] = useState(false);
  const [uitgeklapt, setUitgeklapt] = useState<number[]>([]);

  const ophalenLeveringsvoorwaarden = useCallback(async () => {
    setBezigMetOphalen(true);

    const result = await api.v2.relatie.leveringsvoorwaarden.ophalenLeveringsvoorwaarden({
      filterSchema: {
        filters: [
          {
            naam: 'REL_IDS',
            data: [props.relID],
          },
        ],
      },
      orderSchema: {
        orders: [
          { naam: 'INGANGSDATUM', richting: 'DESC' },
          { naam: 'RECORD_TOEGEVOEGD', richting: 'DESC' },
        ],
      },
    });

    setLeveringsvoorwaardenResult(result);
    setBezigMetOphalen(false);
  }, [props.relID]);
  useEffect(() => {
    ophalenLeveringsvoorwaarden();
  }, [ophalenLeveringsvoorwaarden]);

  const keyExtractor = useCallback((row: IOphalenLeveringsvoorwaardenResultElement) => row.ID, []);

  const kolommen = useMemo<
    ASPKolom<ELeveringsvoorwaardenKolom, IOphalenLeveringsvoorwaardenResultElement>[]
  >(
    () => [
      {
        key: ELeveringsvoorwaardenKolom.Ingangsatum,
        label: 'Ingangsdatum',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 115,
        renderer: (row: IOphalenLeveringsvoorwaardenResultElement) =>
          format(new Date(row.Ingangsdatum), 'dd-MM-yyyy'),
      },
      {
        key: ELeveringsvoorwaardenKolom.Einddatum,
        label: 'Einddatum',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 115,
        renderer: (row: IOphalenLeveringsvoorwaardenResultElement) =>
          row.Einddatum !== null ? format(new Date(row.Einddatum), 'dd-MM-yyyy') : '- -',
      },
      {
        key: ELeveringsvoorwaardenKolom.Periode,
        label: 'Periode',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 100,
        renderer: (row: IOphalenLeveringsvoorwaardenResultElement) => row.Periode,
      },
      {
        key: ELeveringsvoorwaardenKolom.Titel,
        label: 'Titel',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 225,
        renderer: (row: IOphalenLeveringsvoorwaardenResultElement) => row.Titel,
      },
      {
        key: ELeveringsvoorwaardenKolom.Inhoud,
        label: 'Inhoud',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 400,
        renderer: (row: IOphalenLeveringsvoorwaardenResultElement) => (
          <span style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}>{row.Inhoud}</span>
        ),
      },
    ],
    [props.relatie],
  );

  const rijen = useMemo<Record<number, IOphalenLeveringsvoorwaardenResultElement>>(() => {
    return leveringsvoorwaardenResult === null
      ? {}
      : leveringsvoorwaardenResult.leveringsvoorwaarden.reduce((acc, curr, i) => {
          return {
            ...acc,
            [i]: curr,
          };
        }, {});
  }, [leveringsvoorwaardenResult]);

  const handleToevoegen = useMemo(
    () =>
      props.relatie.data?.RelID === undefined
        ? null
        : async () => {
            const result = await globaleRenderer.render<IDialoogResult>((renderProps) => (
              <MuterenDialoog
                relID={props.relatie.data!.RelID}
                open
                onSuccess={(result) => renderProps.destroy(result)}
                onAnnuleren={() => renderProps.destroy()}
              />
            ));
            if (result === undefined) {
              return;
            }
            await ophalenLeveringsvoorwaarden();
          },
    [props.relatie.data?.RelID, ophalenLeveringsvoorwaarden],
  );

  const handleWijzigenRij = useMemo(
    () =>
      props.relatie.data?.RelID === undefined
        ? null
        : async (rij: IOphalenLeveringsvoorwaardenResultElement) => {
            await globaleRenderer.render<IDialoogResult>((renderProps) => (
              <MuterenDialoog
                relID={props.relatie.data!.RelID}
                id={rij.ID}
                open
                onSuccess={(result) => renderProps.destroy(result)}
                onAnnuleren={() => renderProps.destroy()}
              />
            ));
            await ophalenLeveringsvoorwaarden();
          },
    [props.relatie.data?.RelID],
  );

  return (
    <MenuLayout
      menu={
        <div className="d-flex align-items-center">
          <button
            className="btn btn-sm btn-light d-flex align-items-center"
            style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
            onClick={handleToevoegen ?? undefined}
            disabled={handleToevoegen === null}
          >
            <IconToevoegen style={{ fill: Kleur.Grijs, width: 18, height: 18 }} />
            <span className="ml-2">Toevoegen</span>
          </button>
        </div>
      }
      body={
        <ASPTabel
          rijen={rijen}
          kolommen={kolommen}
          keyExtractor={keyExtractor}
          onWijzigenRij={handleWijzigenRij ?? undefined}
          onVerwijderenRij={async (x) => {
            const params = {
              ids: [x.ID],
            };
            await api.v2.relatie.leveringsvoorwaarden.verwijderenLeveringsvoorwaarden(params);
          }}
          verwijderenRijConfirmatie={async (x, verwijderen) => {
            const params = {
              ids: [x.ID],
            };

            const checkData = await api.v2.relatie.leveringsvoorwaarden.checkVerwijderenLeveringsvoorwaarden(
              params,
            );

            const checkResult = await checkStore.controleren({
              checkData,
            });
            if (checkResult.type === EResultType.Annuleren) {
              return;
            }

            if (
              (
                await checkStore.bevestigen({
                  inhoud: 'Bevestig verwijderen voorwaarden',
                })
              ).type === EResultType.Annuleren
            ) {
              return;
            }

            await verwijderen();
            await ophalenLeveringsvoorwaarden();
          }}
          isBezig={bezigMetOphalen}
          uitgeklapteRijComponent={UitgeklapteRij}
          uitgeklapt={uitgeklapt}
          onUitgeklaptChange={setUitgeklapt}
          uitgeklapteRijHoogte={220}
        />
      }
    />
  );
};

export default Leveringsvoorwaarden;
