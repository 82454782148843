import React, { useCallback, useContext, useEffect, useMemo, useState, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import IDialoogProps from '../../../../core/IDialoogProps';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { Field, FieldProps, Formik, FormikActions, FormikErrors, FormikProps } from 'formik';
import Combobox, { IOptie } from '../../../../components/formulier/Combobox';
import { RootStoreContext } from '../../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import api from '../../../../api';
import { EResultType } from '../../../../stores/CheckStore';
import * as Yup from 'yup';
import DatumKiezer from '../../../../components/formulier/DatumKiezer';
import nameOf from '../../../../core/nameOf';
import Dialoog from '../../../../components/dialogen/Dialoog';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import { dagDatum } from '../../../../helpers/datum';
import FormikVeldFout from '../../../../components/formulier/FormikVeldFout';
import VeldWeergave from '../../../../components/formulier/VeldWeergave';
import { addMonths, format } from 'date-fns';
import { IOphalenInstellingenResult } from '../../../../../../shared/src/api/v2/instelling';

interface IProps extends IDialoogProps<null> {}

interface IFormikValues {
  indexeringsdatum: Date | string | null;
  percentage: number;
}

const veldnamen = {
  indexeringsdatum: 'Indexeringsdatum',
  percentage: 'Percentage',
};

const ToevoegenDialoog = observer((props: IProps) => {
  const { onAnnuleren, onSuccess, open, dialoogIndex } = props;

  const { checkStore } = useContext(RootStoreContext);
  const formikRef = useRef<Formik<IFormikValues>>(null);
  const [instellingen, setInstellingen] = useState<IOphalenInstellingenResult | null>(null);

  useEffect(() => {
    (async () => {
      const instellingen = await api.v2.instelling.ophalenInstellingen({});
      setInstellingen(instellingen);
    })();
  }, []);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      // Bepaal de te indexeren contract(basissen)
      const cntBasisIDs = (
        await api.v2.indexering.selecterenContractenVoorIndexering({
          indexeringsdatum: values.indexeringsdatum!,
        })
      ).cntBasisIDs;

      if (cntBasisIDs.length === 0) {
        await checkStore.melden({
          titel: `Er zijn geen contracten gevonden die geïndexeerd kunnen worden op ${format(
            new Date(values.indexeringsdatum!),
            'dd-MM-yyyy',
          )}.`,
        });
        actions.setSubmitting(false);
        return;
      }

      const checkParams = {
        cntBasisIDs,
        indexeringsdatum: values.indexeringsdatum,
        percentage: values.percentage,
      };
      const checkData = await api.v2.indexering.checkToevoegenIndexering(checkParams);

      if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
        actions.setSubmitting(false);
        return;
      }

      if (
        (
          await checkStore.bevestigen({
            inhoud: (
              <span>
                Nieuwe indexering maken?
                <br />
                <br />
                Let op, Dit kan even duren.
              </span>
            ),
          })
        ).type === EResultType.Annuleren
      ) {
        actions.setSubmitting(false);
        return;
      }

      // Maak de indexering daarvoor aan
      const result = await api.v2.indexering.toevoegenIndexering({
        cntBasisIDs,
        indexeringsdatum: values.indexeringsdatum,
        percentage: values.percentage,
      });

      onSuccess(null);
      actions.setSubmitting(false);
    },
    [onSuccess],
  );

  const initieleWaarden = useMemo<IFormikValues>(() => {
    const vandaag = new Date();
    const maandVandaag = vandaag.getMonth();

    const indexeringsdatum =
      maandVandaag <= 6 ? dagDatum(new Date(vandaag.getFullYear(), 6, 1)) : null;

    return {
      indexeringsdatum,
      percentage: 2,
    };
  }, []);

  const validatieschema = Yup.object().shape({
    jaar: Yup.number().required(),
    maand: Yup.number().required(),
  });

  return (
    <Dialoog index={dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Nieuwe indexering</ModalTitle>
      </ModalHeader>
      <Formik<IFormikValues>
        isInitialValid
        enableReinitialize
        onSubmit={handleSubmit}
        initialValues={initieleWaarden}
        //isInitialValid
        render={(formikProps: FormikProps<IFormikValues>) => {
          const { submitForm, isSubmitting, isValid } = formikProps;

          if (instellingen === null) {
            return (
              <div className="p-4 d-flex align-items-center justify-content-center m-10">
                <LoadingSpinner />
              </div>
            );
          }

          return (
            <>
              <ModalBody>
                <div className="form-group">
                  <div className="row">
                    <div className="col-12">
                      <label>{veldnamen.indexeringsdatum}</label>
                      <Field
                        name={nameOf<IFormikValues>('indexeringsdatum')}
                        render={(fieldProps: FieldProps<IFormikValues>) => {
                          const { field, form } = fieldProps;

                          return (
                            <DatumKiezer
                              waarde={field.value}
                              onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                              determineNextValidDate={(date) => {
                                const nieuweDatum = addMonths(date, +1);
                                return nieuweDatum;
                              }}
                              determinePreviousValidDate={(date) => {
                                const nieuweDatum = addMonths(date, -1);
                                return nieuweDatum;
                              }}
                            />
                          );
                        }}
                      />
                    </div>

                    <div className="col-3 mt-3">
                      <label>Percentage</label>
                      <Field
                        name={nameOf<IFormikValues>('percentage')}
                        render={(fieldProps: FieldProps<IFormikValues>) => {
                          const { field, form } = fieldProps;

                          return (
                            <>
                              <input
                                type="number"
                                className="form-control"
                                {...fieldProps.field}
                                min={0}
                              />
                              <FormikVeldFout fieldProps={fieldProps} />
                            </>
                          );
                        }}
                      />
                    </div>

                    <div className="col-12 mt-3">
                      <VeldWeergave>
                        Contracten die nu op de indexeringsdatum lopend zijn worden geselecteerd.
                        Relaties waarvoor aangegeven is dat er niet geindexeerd moet worden blijven
                        buiten de selectie.
                      </VeldWeergave>
                    </div>
                  </div>
                </div>
              </ModalBody>
              <ModalFooter className="d-flex flex-row justify-content-start">
                <button
                  className="btn btn-primary"
                  onClick={submitForm}
                  style={{ width: 100 }}
                  disabled={!isValid || isSubmitting}
                >
                  Ok
                </button>
                <button
                  className="btn btn-secondary"
                  onClick={onAnnuleren}
                  style={{ width: 100 }}
                  disabled={isSubmitting}
                >
                  Annuleren
                </button>
              </ModalFooter>
            </>
          );
        }}
      />
    </Dialoog>
  );
});

export default ToevoegenDialoog;
