import React, { useCallback, useMemo, useState, useEffect, useContext } from 'react';
import IDialoogProps from '../../../../../../../../core/IDialoogProps';
import Dialoog from '../../../../../../../../components/dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import api from '../../../../../../../../api';
import { Field, FieldProps, Formik, FormikActions, FormikErrors, FormikProps } from 'formik';
import { RootStoreContext } from '../../../../../../../../stores/RootStore';
import { EResultType } from '../../../../../../../../stores/CheckStore';
import LoadingSpinner from '../../../../../../../../components/Gedeeld/LoadingSpinner';
import { dagDatum } from '../../../../../../../../helpers/datum';
import nameOf from '../../../../../../../../core/nameOf';
import VinkVeld from '../../../../../../../../components/formulier/VinkVeld';
import useUrlState from '../../../../../../../../core/useUrlState';
import { withRouter } from 'react-router-dom';
import { ESjablooncode } from '../../../../../../../../bedrijfslogica/enums';

export interface IDialoogResult {
  ID: number;
}

interface IProps extends IDialoogProps<IDialoogResult> {
  relID: number;
}

interface IFormikValues {
  berichtVersturen: boolean;
}

const veldnamen: Record<keyof IFormikValues, string | null> = {
  berichtVersturen: 'Direct een acceptatiebericht versturen naar de relatie',
};

const ToevoegenDialoog: React.FC<IProps> = (props) => {
  // const [urlState, setUrlState, setUrlStateSync] = useUrlState<IUrlState>(props, defaultUrlState);

  const { children, dialoogIndex, onAnnuleren, onSuccess, open } = props;
  const { checkStore } = useContext(RootStoreContext);

  const [hasSubmitted, setHasSubmitted] = useState(false);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      if (
        values.berichtVersturen &&
        (
          await checkStore.bevestigen({
            inhoud: (
              <>
                <span>
                  Nieuwe procedure maken ?<br />
                  Er zal direct een verzoek voor documenten verstuurd worden.
                </span>
              </>
            ),
          })
        ).type === EResultType.Annuleren
      ) {
        actions.setSubmitting(false);
        return;
      }

      const params = { relID: props.relID };

      const result = await api.v2.relatie.acceptatie.toevoegenProcedure(params);

      if (values.berichtVersturen) {
        await api.v2.relatie.acceptatie.versturenVerzoekDocumenten({
          id: result.ID,
          sjabloonNaamEnum: ESjablooncode.VerzoekDocumentenAccepatie,
        });
      }

      onSuccess({ ID: result.ID });

      actions.setSubmitting(false);
    },
    [setHasSubmitted],
  );

  const initialValues = useMemo<IFormikValues>(() => {
    return {
      berichtVersturen: false,
    };
  }, []);

  // const validationSchema = useMemo(
  //   () =>
  //     Yup.object().shape({
  //       bezoekDatum: Yup.date().required(
  //         teksten.formulier.E_VERPLICHT_VELD({
  //           veldnaam: veldnamen.afmeldDatum!,
  //         }),
  //       ),
  //       referentiecode: Yup.string().notRequired(),
  //     }),
  //   [],
  // );

  return (
    <>
      <Dialoog index={dialoogIndex || 0}>
        <ModalHeader>
          <ModalTitle>Nieuwe acceptatie-procedure</ModalTitle>
        </ModalHeader>
        {initialValues === null ? (
          <LoadingSpinner />
        ) : (
          <Formik<IFormikValues>
            isInitialValid
            enableReinitialize
            onSubmit={handleSubmit}
            initialValues={initialValues}
            // validationSchema={validationSchema}
            render={(formikProps: FormikProps<IFormikValues>) => {
              const { submitForm, isSubmitting, isValid } = formikProps;

              return (
                <>
                  <ModalBody>
                    <div className="form-group">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center pt-2">
                          <Field
                            name={nameOf<IFormikValues>('berichtVersturen')}
                            render={(fieldProps: FieldProps<IFormikValues>) => {
                              const { field, form } = fieldProps;
                              return (
                                <VinkVeld
                                  aangevinkt={field.value}
                                  onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                />
                              );
                            }}
                          />
                          <span className="ml-2">{veldnamen.berichtVersturen}</span>
                        </div>
                      </div>
                    </div>
                  </ModalBody>
                  <ModalFooter className="d-flex flex-row justify-content-start">
                    <button
                      className="btn btn-primary"
                      onClick={submitForm}
                      style={{ width: 100 }}
                      disabled={!isValid || isSubmitting}
                    >
                      Ok
                    </button>
                    <button
                      className="btn btn-secondary"
                      onClick={onAnnuleren}
                      style={{ width: 100 }}
                      disabled={isSubmitting}
                    >
                      Annuleren
                    </button>
                  </ModalFooter>
                </>
              );
            }}
          />
        )}
      </Dialoog>
    </>
  );
};

export default ToevoegenDialoog;
