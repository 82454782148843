import verzoek from './verzoek';
import event from './event';
import item from './item';
import commissie from './commissie';
import contract from './contract';

const sponsoringv2 = {
  verzoek,
  event,
  item,
  commissie,
  contract,
};

export default sponsoringv2;
