import * as React from 'react';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import IDialoogProps from '../../../../../core/IDialoogProps';
import Modal from 'react-bootstrap/Modal';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import api from '../../../../../api';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalHeader from 'react-bootstrap/ModalHeader';
import LoadingSpinner from '../../../../../components/Gedeeld/LoadingSpinner';
import { RootStoreContext } from '../../../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import { EResultType } from '../../../../../stores/CheckStore';
import { Formik, FormikProps, Field, FieldProps } from 'formik';
import { format, addDays } from 'date-fns';
import nameOf from '../../../../../core/nameOf';
import VinkVeld from '../../../../../components/formulier/VinkVeld';
import Combobox from '../../../../../components/formulier/Combobox';
import teksten from '../../../../../bedrijfslogica/teksten';
import * as Yup from 'yup';
import { IOphalenSignaleringstypesResultElement } from '../../../../../../../shared/src/api/v2/control/signalering';

interface IProps extends IDialoogProps {
  id: number;
}

export interface IFormikValues {
  naam: string;
  actief: boolean;
  toelichting: string | null;
}

const veldnamen = {
  naam: 'Naam',
  actief: 'Is actief',
  toelichting: 'Toelichting',
};

const WijzigenDialoog: React.FC<IProps> = observer((props) => {
  const { checkStore } = useContext(RootStoreContext);

  const [
    signaleringstype,
    setSignaleringstype,
  ] = useState<IOphalenSignaleringstypesResultElement | null>(null);

  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

  const ophalenSignaleringstype = useCallback(async () => {
    const signaleringstype = (
      await api.v2.signalering.ophalenSignaleringstypes({
        filterSchema: { filters: [{ naam: 'IDS', data: [props.id] }] },
      })
    ).signaleringstypes[0];
    setSignaleringstype(signaleringstype);
  }, [props.id]);

  useEffect(() => {
    ophalenSignaleringstype();
  }, [ophalenSignaleringstype]);

  const initieleWaarden = useMemo<IFormikValues | null>(() => {
    if (signaleringstype === null) {
      return null;
    }

    return {
      naam: signaleringstype.Naam,
      actief: signaleringstype.Actief,
      toelichting: signaleringstype.Toelichting,
    };
  }, [signaleringstype]);

  const handleWijzigen = useCallback(
    async (values: IFormikValues) => {
      if (signaleringstype === null) {
        return null;
      }

      setIsSubmitting(true);

      const params = {
        id: props.id,
        naam: values.naam,
        toelichting: values.toelichting,
        actief: values.actief,
      };

      // const checkData = await api.v2.signalering.wijzigenSignaleringstype(params);

      // if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
      //   setIsSubmitting(false);
      //   return;
      // }

      await api.v2.signalering.wijzigenSignaleringstype(params);

      setIsSubmitting(false);

      setSignaleringstype(null);
      props.onSuccess({});
    },
    [setIsSubmitting, props.onSuccess, signaleringstype],
  );

  const handleAnnuleren = useCallback(() => {
    props.onAnnuleren();
    setSignaleringstype(null);
  }, [props.onAnnuleren, setSignaleringstype]);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        naam: Yup.string().required(
          teksten.formulier.E_VERPLICHT_VELD({
            veldnaam: veldnamen.naam,
          }),
        ),
      }),
    [],
  );

  return (
    <Modal show={props.open}>
      <ModalHeader>
        <ModalTitle>Wijzigen signaleringstype</ModalTitle>
      </ModalHeader>
      {initieleWaarden === null || signaleringstype === null ? (
        <LoadingSpinner />
      ) : (
        <Formik<IFormikValues>
          isInitialValid
          onSubmit={(values) => handleWijzigen(values)}
          initialValues={initieleWaarden}
          validationSchema={validationSchema}
          validateOnChange
          validateOnBlur
          render={(formikProps: FormikProps<IFormikValues>) => {
            const { submitForm, isSubmitting, values, isValid } = formikProps;
            return (
              <>
                <ModalBody>
                  <div className="form-group">
                    <div className="row">
                      <div className="row col-12">
                        <div className="col-12">
                          <label>{veldnamen.naam}</label>
                          <Field
                            name={nameOf<IFormikValues>('naam')}
                            render={(fieldProps: FieldProps<IFormikValues>) => {
                              const { field, form } = fieldProps;
                              return <input {...field} className="form-control" type="text" />;
                            }}
                          />
                        </div>
                      </div>

                      <div className="row col-12 mt-3">
                        <div className="col-12">
                          <label>{veldnamen.toelichting}</label>
                          <Field
                            name={nameOf<IFormikValues>('toelichting')}
                            render={(fieldProps: FieldProps<IFormikValues>) => {
                              const { field, form } = fieldProps;
                              return <textarea {...field} className="form-control" rows={3} />;
                            }}
                          />
                        </div>
                      </div>

                      <div className="col-12 d-flex align-items-center mt-3">
                        <Field
                          name={nameOf<IFormikValues>('actief')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <VinkVeld
                                aangevinkt={field.value}
                                onGewijzigd={(x) => {
                                  form.setFieldValue(field.name, x);
                                }}
                              />
                            );
                          }}
                        />
                        <span className="ml-2">{veldnamen.actief}</span>
                      </div>
                    </div>
                  </div>
                </ModalBody>
                <ModalFooter className="d-flex justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    disabled={isSubmitting || !isValid}
                    style={{ width: 100 }}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={handleAnnuleren}
                    disabled={isSubmitting || !isValid}
                    style={{ width: 100 }}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Modal>
  );
});

export default WijzigenDialoog;
