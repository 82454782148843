import React from 'react';
import styled from 'styled-components';
import { Kleur } from '../../../bedrijfslogica/constanten';
import { IconChevronRight } from '../../Icons';

const Knop = styled.button`
  outline: 0;
  border: 0;
  background: 0;
  border-radius: 5px;
  padding: 5px;
  transition: background 0.2s ease-in-out;

  &:hover {
    background: ${Kleur.LichtGrijs};
  }
`;

const Root = styled.div`
  padding: 12px 8px;
  border-right: 1px solid ${Kleur.LichtGrijs};
`;

interface IProps {
  isBezig: boolean;
  uitklappen: () => void;
}

const LinkerdeelIngeklapt = (props: IProps) => {
  return (
    <Root>
      <Knop onClick={props.uitklappen} disabled={props.isBezig}>
        <IconChevronRight
          style={{
            fill: props.isBezig ? Kleur.LichtGrijs : Kleur.Grijs,
            width: 25,
            height: 25,
          }}
        />
      </Knop>
    </Root>
  );
};

export default LinkerdeelIngeklapt;
